import { useTranslation } from "@hireroo/i18n";
import ImageIcon from "@mui/icons-material/Image";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type PhotoURLFieldProps = {
  photoUrl: string;
  disabled: boolean;
  onClickFileUpload: (file: File) => void;
};

const PhotoURLField: React.FC<PhotoURLFieldProps> = props => {
  const { t } = useTranslation();

  const onClickFileUploadButton = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];
    props.onClickFileUpload(file);
  };

  return (
    <Stack spacing={2}>
      <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
        {t("アイコン")}
      </Typography>

      <Stack spacing={2} direction="row" alignItems="center">
        <Avatar
          alt="userImage"
          src={props.photoUrl}
          style={{
            height: 64,
            width: 64,
          }}
        />
        <Button component="label" startIcon={<ImageIcon />} color="secondary" disabled={props.disabled}>
          {t("ファイルを選択する")}
          <input
            type="file"
            hidden
            onChange={onClickFileUploadButton}
            onClick={e => ((e.target as HTMLInputElement).value = "")}
            accept="image/jpeg, image/png"
          />
        </Button>
      </Stack>
    </Stack>
  );
};

PhotoURLField.displayName = "PhotoURLField";

export default PhotoURLField;
