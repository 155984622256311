import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { TestSearchForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import AddIcon from "@mui/icons-material/Add";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, SubmitHandler, useFieldArray, useForm } from "react-hook-form";

import SplitButton, { SplitButtonProps } from "./parts/SplitButton/SplitButton";
import VariableField, { VariableFieldProps } from "./parts/VariableField/VariableField";

export type FilledVariablesFieldProps = {
  disabled?: boolean;
  variant?: SplitButtonProps["variant"];
  options: VariableFieldProps["options"];
  onChange: SubmitHandler<TestSearchForm.FilledVariablesSchema>;
};

const FilledVariablesField: React.FC<FilledVariablesFieldProps> = props => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const validateSchema = TestSearchForm.useFilledVariables();
  const methods = useForm<TestSearchForm.FilledVariablesSchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: {
      variables: [],
    },
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });
  const variablesField = useFieldArray({ name: "variables", control: methods.control });

  const [selectedOptions, setSelectedOptions] = React.useState<TestSearchForm.FilledVariableSchema[]>(variablesField.fields);

  const splitButtonProps: SplitButtonProps = {
    title: t("カスタムフォーム"),
    disabled: props.disabled,
    status: selectedOptions.length > 0 ? "ACTIVE" : "INACTIVE",
    label: {
      children:
        selectedOptions.length === 0
          ? t("カスタムフォーム")
          : [t("カスタムフォーム"), t2("AppliedCount", { num: selectedOptions.length })].join(": "),
    },
    onApply: async controller => {
      const result = await methods.trigger("variables");
      if (result) {
        setSelectedOptions(variablesField.fields);
        methods.handleSubmit(props.onChange, console.warn)();
        controller.close();
      }
    },
    onReset: () => {
      methods.reset();
      methods.handleSubmit(props.onChange, console.warn)();
      setSelectedOptions([]);
    },
    variant: props.variant,
    disableClose: true,
  };
  const buttonProps: ButtonProps = {
    children: t("フィルター"),
    variant: "text",
    startIcon: <AddIcon />,
    color: "secondary",
    onClick: () => {
      variablesField.append({
        labelId: "",
        label: "",
        // Temporary. True valueType will be made when labelId is selected
        valueType: "STRING",
        value: "",
      });
    },
  };
  return (
    <FormProvider {...methods}>
      <SplitButton {...splitButtonProps}>
        <Stack direction="column" spacing={2}>
          {variablesField.fields.length === 0 && (
            <Typography fontSize={14} color="textSecondary">
              {t("適用中のフィルターがありません。")}
            </Typography>
          )}
          {variablesField.fields.map((field, index) => {
            return <VariableField key={field.id} index={index} options={props.options} onDelete={() => variablesField.remove(index)} />;
          })}
          <Stack direction="row" alignItems="center" justifyContent="flex-start">
            <Button {...buttonProps} />
          </Stack>
        </Stack>
      </SplitButton>
    </FormProvider>
  );
};

FilledVariablesField.displayName = "FilledVariablesField";

export default FilledVariablesField;
