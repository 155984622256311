import BoxPlot, { BoxPlotProps } from "@hireroo/charts/react/BoxPlot";
import PerformanceGraph, { PerformanceGraphProps } from "@hireroo/charts/react/PerformanceGraph";
import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";

import TitleWithScoreBar from "../../../modules/TitleWithScoreBar/TitleWithScoreBar";

export type LegacyPerformanceGraphsProps = {
  version: "V1";
  data?: PerformanceGraphProps;
};

export type PerformanceGraphsProps = {
  version: "V2";
  data?: BoxPlotProps;
};

export type ReportChallengePerformanceSectionProps = {
  score?: number;
  machineSpec: string;
  graph: LegacyPerformanceGraphsProps | PerformanceGraphsProps;
  targetElementId?: string;
};

const ReportChallengePerformanceSection: React.FC<ReportChallengePerformanceSectionProps> = props => {
  const { t } = useTranslation();
  return (
    <Box id={props.targetElementId}>
      <TitleWithScoreBar title={t("パフォーマンス")} score={props.score} color="info" mb={3} />
      <Box display="flex">
        <Typography variant="subtitle2">
          {t("実行環境")}: {props.machineSpec}
        </Typography>
      </Box>
      <Box height={400}>
        <React.Suspense>
          {props.graph.version === "V1" && props.graph.data && <PerformanceGraph {...props.graph.data} />}
          {props.graph.version === "V2" && props.graph.data && <BoxPlot {...props.graph.data} />}
        </React.Suspense>
      </Box>
    </Box>
  );
};

ReportChallengePerformanceSection.displayName = "ReportChallengePerformanceSection";

export default ReportChallengePerformanceSection;
