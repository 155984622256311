import { proxy } from "valtio";

import type * as Types from "./types";

export const state = proxy<Types.State>({
  redirectUrlAfterSignIn: null,
  afterResetPasswordPayload: null,
  firebaseAuthStatusCode: null,
  signInStatus: "UNKNOWN",
});
