import { App } from "@hireroo/app-store/essential/shared";
import { ExamTransitionVisualizer } from "@hireroo/app-store/widget/shared/ExamTransitionVisualizer";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import ExamTransitionVisualizerContainer, { ExamTransitionVisualizerContainerProps } from "./Container";

export type ExamTransitionVisualizerFetchContainerProps = {
  assessmentId: string;
  selectedExamId: string | null;
  onChangeExamId: (examId: string) => void;
};

const ExamTransitionVisualizerFetchContainer: React.FC<ExamTransitionVisualizerFetchContainerProps> = props => {
  const appStatus = App.useStatus();
  const initialized = ExamTransitionVisualizer.useInitialized();
  const offset = ExamTransitionVisualizer.useOffset();
  const [result, refresh] = Graphql.useListBestExamsByAssessmentIdForExamTransitionVisualizerQuery({
    variables: {
      assessmentId: props.assessmentId,
      withCount: true,
      size: 30,
      offset: offset,
      isDescending: true,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "cache-and-network",
  });
  React.useEffect(() => {
    if (result.data) {
      ExamTransitionVisualizer.initialize(result.data.listBestExamsByAssessmentId);
    }
  }, [result.data]);

  React.useEffect(() => {
    return () => {
      ExamTransitionVisualizer.clear();
    };
  }, []);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus === "INITIALIZING" || !initialized) {
    return <Widget.Loading kind="CENTER_%" color="secondary" />;
  }

  const containerProps: ExamTransitionVisualizerContainerProps = {
    selectedExamId: props.selectedExamId,
    onChangeExamId: props.onChangeExamId,
    refresh: refresh,
  };

  return <ExamTransitionVisualizerContainer {...containerProps} />;
};

ExamTransitionVisualizerFetchContainer.displayName = "ExamTransitionVisualizerFetchContainer";

export default withErrorBoundary(ExamTransitionVisualizerFetchContainer, {});
