import firebase from "firebase/compat/app";
import jwt_decode from "jwt-decode";

export type FirebaseUser = firebase.User;

export function getCurrentUser(): Promise<firebase.User | null> {
  return new Promise<firebase.User | null>(resolve => {
    firebase.auth().onAuthStateChanged(user => {
      resolve(user);
    });
  });
}

type GetCustomClaimResult =
  | {
      signedIn: true;
      claims: { [key: string]: unknown };
    }
  | {
      signedIn: false;
      claims: null;
    };

export const getCustomClaim = (): Promise<GetCustomClaimResult> => {
  return new Promise((resolve, reject) => {
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
      resolve({
        signedIn: false,
        claims: null,
      });
      return;
    }
    currentUser
      .getIdToken(true)
      .then(idToken => {
        try {
          const parsed = jwt_decode(idToken) as { [key: string]: unknown };
          resolve({
            signedIn: true,
            claims: parsed,
          });
        } catch (error) {
          console.error(error);
          resolve({
            signedIn: true,
            claims: {},
          });
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export async function updateProfile(displayName?: string): Promise<void> {
  const currentUser = firebase.auth().currentUser;
  return currentUser?.updateProfile({
    displayName: displayName,
  });
}
