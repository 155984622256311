import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateProjectQuestionDetailPropsArgs, useGenerateProps } from "./useGenerateProps";

export type ProjectQuestionDetailContainerProps = GenerateProjectQuestionDetailPropsArgs;

const ProjectQuestionDetailContainer: React.FC<ProjectQuestionDetailContainerProps> = props => {
  const projectQuestionDetailProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ProjectQuestionDetail {...projectQuestionDetailProps} />
    </ErrorBoundary>
  );
};

ProjectQuestionDetailContainer.displayName = "ProjectQuestionDetailContainer";

export default withErrorBoundary(ProjectQuestionDetailContainer, {});
