import { useSnapshot } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useOpenSidebar = (): boolean => {
  const snapshot = useSnapshotState();
  return snapshot.openSidebar;
};

export const useMode = (): Types.Mode => {
  const snapshot = useSnapshotState();
  return snapshot.openSidebar ? "EXPANDED" : "MINIMIZED";
};
