import { SupportLanguage, useTranslation } from "@hireroo/i18n";
import * as z from "zod";

const useAlgorithmQuestionDetail = () => {
  const { t } = useTranslation();
  return z.object({
    title: z.string().min(1, { message: t("タイトルは必須項目です。") }),
    description: z.string().min(1, { message: t("本文は必須項目です。") }),
    language: z.enum([SupportLanguage.JA, SupportLanguage.EN]),
  });
};

export const useAlgorithmQuestionFormSchema = () => {
  const { t } = useTranslation();
  const questionDetail = useAlgorithmQuestionDetail();
  const timeLimitNumber = z
    .number()
    .min(5, { message: t("制限時間は最低5分以上で設定してください。") })
    .max(10000, {
      message: t("制限時間は10000分以内で設定してください。"),
    });
  const timeLimitString = z
    .string()
    .transform(value => {
      return Number(value);
    })
    .superRefine((value, ctx) => {
      if (value < 5) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_small,
          minimum: 5,
          type: "number",
          inclusive: true,
          message: t("制限時間は最低5分以上で設定してください。"),
        });
      } else if (10000 < value) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          maximum: 10000,
          type: "number",
          inclusive: true,
          message: t("制限時間は10000分以内で設定してください。"),
        });
      }
    });
  return z.object({
    isPublic: z.boolean(),
    variant: z.union([z.literal("UNKNOWN"), z.literal("ALGORITHM"), z.literal("DATABASE"), z.literal("CLASS")]),
    /**
     * FIXME The initial value is treated as a NUMBER, but the changed value is treated as a STRING.
     */
    timeLimit: z.union([timeLimitNumber, timeLimitString]),
    difficulty: z.union([z.literal("UNKNOWN"), z.literal("EASY"), z.literal("MEDIUM"), z.literal("DIFFICULT")]),
    questions: z.array(questionDetail).min(1, {
      message: t("問題は少なくとも1つの言語で入力は必須です。"),
    }),
  });
};

export type AlgorithmQuestionDetailSchema = z.infer<ReturnType<typeof useAlgorithmQuestionDetail>>;

export type AlgorithmQuestionFormSchema = z.infer<ReturnType<typeof useAlgorithmQuestionFormSchema>>;
