import { DeepReadonly } from "@hireroo/app-helper/types";
import { useSnapshot } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useCurrentTab = () => {
  const snapshot = useSnapshotState();
  return snapshot.currentTab;
};

export const useInitialized = () => {
  const snapshot = useSnapshotState();
  return !!snapshot.assessment;
};

export const useAssessment = (): DeepReadonly<Types.Assessment> => {
  const snapshot = useSnapshotState();
  if (!snapshot.assessment) {
    throw new Error("need to initialize");
  }
  return snapshot.assessment;
};

export const useDialogStatus = (): Types.DialogStatus => {
  const snapshot = useSnapshotState();
  return snapshot.dialogStatus;
};

export const useSelectedExamId = (): Types.ExamId | null => {
  const snapshot = useSnapshotState();
  return snapshot.selectedExamId;
};

export const usePopperStatus = (): Types.PopperStatus => {
  const snapshot = useSnapshotState();
  return snapshot.popperStatus;
};

export const useBestExams = () => {
  const snapshot = useSnapshotState();
  return snapshot.bestExams;
};

export const useCurrentExam = () => {
  const snapshot = useSnapshotState();
  if (snapshot.selectedExamId) {
    return snapshot.examMap.get(snapshot.selectedExamId);
  }
};

/**
 * Current selected origin examId which is the root of the exams in current period
 * This will be used for listExamRecursive
 */
export const useCurrentOriginExamId = () => {
  const snapshot = useSnapshotState();
  if (snapshot.selectedExamId) {
    return snapshot.examMap.get(snapshot.selectedExamId)?.originId || null;
  }
  return null;
};

export const useListBestExamsCount = () => {
  const snapshot = useSnapshotState();
  return snapshot.listBestExamsCount;
};

export const useListBestOffset = () => {
  const snapshot = useSnapshotState();
  return snapshot.listBestOffset;
};

export const useTailExamId = () => {
  const snapshot = useSnapshotState();
  return snapshot.tailExamId;
};
