import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateScreeningTestActivityLogPropsArgs, useGenerateProps } from "./useGenerateProps";

export type ScreeningTestActivityLogContainerProps = GenerateScreeningTestActivityLogPropsArgs;

const ScreeningTestActivityLogContainer: React.FC<ScreeningTestActivityLogContainerProps> = props => {
  const screeningTestActivityLogProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ScreeningTestActivityLog {...screeningTestActivityLogProps} />
    </ErrorBoundary>
  );
};

ScreeningTestActivityLogContainer.displayName = "ScreeningTestActivityLogContainer";

export default withErrorBoundary(ScreeningTestActivityLogContainer, {});
