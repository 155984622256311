import { App } from "@hireroo/app-store/essential/employee";
import { ScreeningsTestsIdDetailStore } from "@hireroo/app-store/page/e/screenings_tests_id_detail";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";
import { useParams } from "react-router-dom";

import ErrorPanelContainer from "../../../../../widget/v2/e/ErrorPanel/Container";
import ScreeningTestDetailForEmployeeContainer, { ScreeningTestDetailForEmployeeContainerProps } from "./Container";

export type ScreeningTestDetailForEmployeeFetchContainerProps = {};

const ScreeningTestDetailForEmployeeFetchContainer: React.FC<ScreeningTestDetailForEmployeeFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const { id } = useParams<{ id: string }>();
  const screeningTestId = `${id}`;
  const initialized = ScreeningsTestsIdDetailStore.useInitialized();
  const [result] = Graphql.useGetInterviewDetailQuery({
    variables: {
      id: screeningTestId,
      withAnswers: true,
      ignoreInheritance: false,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "cache-and-network",
  });

  React.useEffect(() => {
    if (result.data?.spot) {
      ScreeningsTestsIdDetailStore.initializeInterview(result.data.spot);
    }
  }, [result.data]);

  if (result.error) {
    return <ErrorPanelContainer mode="PERCENTAGE" graphqlErrors={result.error.graphQLErrors} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: ScreeningTestDetailForEmployeeContainerProps = {
    screeningTestId: screeningTestId,
  };

  return <ScreeningTestDetailForEmployeeContainer {...containerProps} />;
};

ScreeningTestDetailForEmployeeFetchContainer.displayName = "ScreeningTestDetailForEmployeeFetchContainer";

export default withErrorBoundary(ScreeningTestDetailForEmployeeFetchContainer, {});
