import { useTranslation } from "@hireroo/i18n";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DeleteGroupsDialog, { DeleteGroupsDialogProps } from "./parts/DeleteGroupsDialog/DeleteGroupsDialog";
import GroupHeader, { GroupHeaderProps } from "./parts/GroupHeader/GroupHeader";
import GroupTable, { GroupTableProps } from "./parts/GroupTable/GroupTable";

export type GroupSettingsProps = {
  header: GroupHeaderProps;
  table: GroupTableProps;
  deleteGroups: DeleteGroupsDialogProps;
};

const GroupSettings: React.FC<GroupSettingsProps> = props => {
  const { t } = useTranslation();
  return (
    <Stack spacing={1.5}>
      <Typography fontSize={14} fontWeight={700}>
        {t("グループ")}
      </Typography>
      <GroupHeader {...props.header} />
      <GroupTable {...props.table} />
      <DeleteGroupsDialog {...props.deleteGroups} />
    </Stack>
  );
};

GroupSettings.displayName = "GroupSettings";

export default GroupSettings;
