import MUITableHead, { TableHeadProps as MUITableHeadProps } from "@mui/material/TableHead";
import * as React from "react";

export type TableHeadProps = MUITableHeadProps & {};

const TableHead: React.FC<TableHeadProps> = props => {
  const { children } = props;
  return <MUITableHead {...props}>{children}</MUITableHead>;
};

TableHead.displayName = "TableHead";

export default TableHead;
