import { Payment, Role } from "@hireroo/app-store/essential/employee";
import { QuizTestReport } from "@hireroo/app-store/view-domain/QuizTestReport";
import { formatSeconds } from "@hireroo/formatter/time";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

import { useGenerateCheatDetectionSectionProps } from "../../../../props-factory/v2/view-domain/QuizTestReport/useGenerateCheatDetectionSectionProps";
import { useGenerateEntityScoreBoardProps } from "../../../../props-factory/v2/view-domain/QuizTestReport/useGenerateEntityScoreBoardProps";
import { useGenerateQuestionDetailProps } from "../../../../props-factory/v2/view-domain/QuizTestReport/useGenerateQuestionDetailProps";
import { useGenerateSubmissionSectionProps } from "../../../../props-factory/v2/view-domain/QuizTestReport/useGenerateSubmissionSectionProps";
import QuizPlaybackEditorContainer from "../../../../props-factory/v2/view-domain/QuizTestReport/widget/QuizPlaybackEditor/Container";
import TotalScoreRankVisualizerInitialContainer from "../../shared/TotalScoreRankVisualizer/InitialContainer";
import UpsellLockButtonDialogContainer from "../UpsellLockButtonDialog/Container";
import { useGenerateHeaderProps } from "./useGenerateHeaderProps";

export type GenerateQuizTestReportPropsArgs = {
  featureKind: "test" | "exam";
  quizId: number;
  companyId: number;
  uniqueKey: QuizTestReport.UniqueKey;
  canShowCheatDetectionSection: boolean;
};

export const useGenerateProps = (args: GenerateQuizTestReportPropsArgs): Widget.QuizTestReportProps => {
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const hooks = QuizTestReport.useCreateQuizHooks(args.quizId);
  const quiz = hooks.useQuiz();
  const quizPackage = hooks.usePackage();
  const header = useGenerateHeaderProps({ quizId: args.quizId });
  const isAvailableFeature = Payment.useIsAvailableFeature();
  const elapsedTimeSeconds = hooks.useTotalElapsedTimeSeconds();
  const statistics = hooks.useStatistics();
  const isAdminOrManager = Role.useHasRole(["ADMIN", "MANAGER"]);
  const submissionSectionProps = useGenerateSubmissionSectionProps({
    quizId: args.quizId,
    uniqueKey: args.uniqueKey,
    showingTargets: [
      isAvailableFeature(`${args.featureKind}.statics.read`) && ("STATISTIC" as const),
      "EDITOR" as const,
      "ANSWER" as const,
      "PLAYBACK" as const,
    ].flatMap(v => (v ? [v] : [])),
    canEdit: isAdminOrManager,
  });
  const questionDetail = useGenerateQuestionDetailProps({ ...args, showAnswer: true, showArchivedMark: true });
  const entityScoreBoard = useGenerateEntityScoreBoardProps({ quizId: args.quizId, showScore: true });
  const cheatDetectionSection = useGenerateCheatDetectionSectionProps({
    ...args,
    canShowStatistic: isAvailableFeature("test.statics.read"),
  });

  const questionIds = React.useMemo(() => {
    return quizPackage.questions.map(question => question.id);
  }, [quizPackage.questions]);

  return {
    header: header,
    entityScoreBoard: entityScoreBoard,
    answerDetailSection: {
      totalElapsedTime: {
        value: formatSeconds(elapsedTimeSeconds, lang),
        icon: {
          key: elapsedTimeSeconds <= statistics.elapsedAvgTimeSeconds ? "CHECK" : "WARNING",
          title: `${t("平均解答時間")} ${formatSeconds(statistics.elapsedAvgTimeSeconds, lang)}`,
        },
        canShowTooltip: isAvailableFeature(`${args.featureKind}.statics.read`),
      },
    },
    StatisticsContent: isAvailableFeature(`${args.featureKind}.statics.read`) ? (
      quiz.quizStatus === "EVALUATED" && (
        <TotalScoreRankVisualizerInitialContainer uniqueKey={args.uniqueKey} score={quiz.totalScore} enableRank />
      )
    ) : (
      <UpsellLockButtonDialogContainer kind="STATISTICS" />
    ),
    submissionSection: submissionSectionProps,
    cheatDetectionSection: args.canShowCheatDetectionSection ? cheatDetectionSection : undefined,
    playbackSection: isAvailableFeature(`${args.featureKind}.playback.read`)
      ? {
          PlaybackComponent: (
            <QuizPlaybackEditorContainer key={args.quizId} quizId={args.quizId} packageId={quizPackage.packageId} questionIds={questionIds} />
          ),
        }
      : {
          PlaybackComponent: <UpsellLockButtonDialogContainer kind="PLAYBACK" />,
        },
    questionDetail: questionDetail,
  };
};
