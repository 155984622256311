import { proxy } from "valtio";
import { proxyMap } from "valtio/utils";

import type * as Types from "./types";

export const state = proxy<Types.State>({
  initialized: false,
  query: {
    scope: "GLOBAL",
    enableDate: true,
    startDate: null,
    endDate: null,
    tags: [],
  },
  queryResultMap: proxyMap(),
  tags: [],
});
