import { QuestionsPackages } from "@hireroo/app-store/page/e/questions_packages";
import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import * as Subscriber from "./Subscriber";
import { useGenerateProps } from "./useGenerateProps";

export type QuestionPackageListContainerProps = {};

const QuestionPackageListContainer: React.FC<QuestionPackageListContainerProps> = () => {
  const questionPackageListProps = useGenerateProps({});
  React.useEffect(() => {
    const stop = Subscriber.startSubscribeListParams();
    return () => {
      stop();
      QuestionsPackages.clear();
    };
  }, []);
  return (
    <ErrorBoundary>
      <Pages.QuestionPackageList {...questionPackageListProps} />
    </ErrorBoundary>
  );
};

QuestionPackageListContainer.displayName = "QuestionPackageListContainer";

export default withErrorBoundary(QuestionPackageListContainer, {});
