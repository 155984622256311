import { Credential } from "@hireroo/app-store/essential/shared";
import { Company, Security } from "@hireroo/app-store/essential/talent";
import { signOut } from "@hireroo/firebase";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { currentWindowMatchPath, generateHelpCenterUrl } from "@hireroo/router/api";
import routes from "@hireroo/router/config";
import { useMatchedPattern } from "@hireroo/router/hooks";
import { useHelpCenterNavigate, useNavigate } from "@hireroo/router/hooks";

import * as Def from "./definition";

export const useGenerateProps = (): Widget.ErrorPanelProps | undefined => {
  const pattern = useMatchedPattern() as Def.RoutePath | undefined;
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const navigate = useNavigate();
  const lang = useLanguageCode();
  const helpCenterNavigate = useHelpCenterNavigate(lang);
  const companyErrorStatus = Company.useErrorStatus();
  const activeCompanySecurity = Security.useActiveCompanySecurity();
  const providerId = Credential.useCurrentProviderIdFromFirebaseIdToken();
  const planValidateResult = Def.useValidateCurrentPlan(pattern);
  const userType = Credential.useUserType();

  const linksProps: Widget.ErrorPanelProps["links"] = [
    {
      href: generateHelpCenterUrl(lang, "ROOT"),
      children: t("ヘルプセンター"),
      onClick: () => {
        helpCenterNavigate("ROOT", { _blank: true });
      },
    },
    userType === Graphql.UserType.Employee && {
      href: routes["/e/home"],
      children: t("ホームに戻る"),
      onClick: () => {
        navigate(routes["/e/home"]);
      },
    },
    userType === Graphql.UserType.Talent && {
      href: routes["/t/assessments"],
      children: t("ホームに戻る"),
      onClick: () => {
        navigate(routes["/t/assessments"]);
      },
    },
    {
      children: t("サインアウトする"),
      onClick: async () => {
        await signOut();
      },
    },
  ].flatMap(v => (v ? [v] : []));

  const isForcedSSOmode = activeCompanySecurity?.ssoEnabled && activeCompanySecurity?.ssoMode === "STRICT";
  const isExpectedProvider = activeCompanySecurity?.providerId === providerId;

  /**
   * Invitation page passes Validation because the user state is not fixed.
   */
  if (currentWindowMatchPath("/t/invitations/:id")) {
    return;
  }

  /**
   * If company has enabled SSO strict mode, we restrict user's access to the company.
   */
  if (isForcedSSOmode && !isExpectedProvider) {
    return {
      name: t("SSO強制モードが有効です。"),
      message: t(
        "この会社アカウントではSSOでの認証が強制されています。管理者に問い合わせアカウントを作成してもらうか、強制モードを無効にしてください。",
      ),
      links: linksProps,
    };
  }

  if (companyErrorStatus === "DO_NOT_BELONG_TO_ANY_COMPANY") {
    return {
      name: t("会社に所属していません。"),
      message: t("会社に所属することでこれより先の画面に進めます。会社に所属するには、管理者に招待リンクを発行してもらう必要があります。"),
      links: linksProps,
    };
  }

  if (companyErrorStatus === "FAILED_TO_RETRIEVE_COMPANY") {
    return {
      name: t("会社情報の取得に失敗しました。"),
      message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
      links: linksProps,
    };
  }

  if (planValidateResult !== "AVAILABLE") {
    return {
      name: t("お使いのプランではこの機能を利用できません"),
      message: t("詳しいプラン情報はヘルプセンターより担当者にお問い合わせください。"),
      additionalMessage: t2("UpsellRequiredFeature", { featureName: planValidateResult.featureName }),
      links: linksProps,
    };
  }

  if (!pattern) {
    return;
  }
  return undefined;
};
