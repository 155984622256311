import { useTranslation } from "@hireroo/i18n";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledTypography = styled(Typography)(({ theme }) => ({
  padding: "1px 4px",
  borderRadius: 20,
  backgroundColor: theme.palette.error.main,
  color: theme.palette.error.contrastText,
  display: "inline-block",
  fontSize: "10px",
  lineHeight: "14px",
  fontWeight: 400,
  flexGrow: 0,
  flexShrink: 0,
  height: 16,
  width: "fit-content",
}));

export type QuestionArchivedBadgeProps = {};

const QuestionArchivedBadge: React.FC<QuestionArchivedBadgeProps> = () => {
  const { t } = useTranslation();
  const title = t("この問題はアーカイブされています。問題詳細は確認できますが、出題することはできません。");
  return (
    <Tooltip
      title={title}
      componentsProps={{
        popper: {
          sx: {
            zIndex: 3000,
          },
        },
      }}
    >
      <StyledTypography>{t("アーカイブ済み")}</StyledTypography>
    </Tooltip>
  );
};

QuestionArchivedBadge.displayName = "QuestionArchivedBadge";

export default QuestionArchivedBadge;
