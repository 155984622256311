import { useTranslation } from "@hireroo/i18n";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectProps } from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import * as React from "react";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&.MuiMenuItem-root": {
    "&:hover ": {
      backgroundColor: theme.palette["Secondary/Shades"].p8,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette["Secondary/Shades"].p16,
    },
  },
}));

export type MediaDeviceSelectorProps = {
  devices: MediaDeviceInfo[];
  onChange?: (deviceId: string) => void;
  selectedDeviceId?: string;
  label: string;
};

// TODO: Use packages/ui/src/primitive/InputControl/SelectControl.tsx
const MediaDeviceSelector: React.FC<MediaDeviceSelectorProps> = props => {
  const { t } = useTranslation();
  const { selectedDeviceId } = props;
  const [selectedValue, setSelectedValue] = React.useState<string>(props.selectedDeviceId || "");

  /**
   * TODO If you are rewriting values with useEffect, you should not have state in this component. Therefore, remove useEffect from this component.
   */
  React.useEffect(() => {
    if (selectedDeviceId) {
      setSelectedValue(selectedDeviceId);
    }
  }, [selectedDeviceId]);

  const isValidValue = props.devices.findIndex(device => device.deviceId === selectedValue) !== -1;

  const selectProps: SelectProps<string> = {
    value: isValidValue ? selectedValue : "",
    variant: "outlined",
    size: "small",
    color: "secondary",
    label: props.label,
    onChange: event => {
      props.onChange?.(event.target.value);
      setSelectedValue(event.target.value);
    },
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel color="secondary">{props.label}</InputLabel>
      <Select {...selectProps}>
        {props.devices.length === 0 && (
          <StyledMenuItem value="" disabled={true}>
            {t("有効なデバイスがありません。")}
          </StyledMenuItem>
        )}
        {props.devices.map(device => (
          <StyledMenuItem color="secondary" value={device.deviceId} key={device.deviceId}>
            {device.label}
          </StyledMenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

MediaDeviceSelector.displayName = "MediaDeviceSelector";

export default MediaDeviceSelector;
