import { useTranslation } from "@hireroo/i18n";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";

import HelpMenu, { HelpMenuProps } from "../../../../modules/HelpMenu/HelpMenu";
import LanguageDropDownMenu from "../../../../modules/LanguageDropDownMenu/LanguageDropDownMenu";
import PreferencesMenu, { PreferencesMenuProps } from "../../../../modules/PreferencesMenu/PreferencesMenu";
import BaseDialog, { BaseDialogProps } from "../../../../primitive/BaseDialog/BaseDialog";
import ButtonWithTooltip, { ButtonWithTooltipProps } from "../../../../primitive/Button/ButtonWithTooltip/ButtonWithTooltip";

const ActionButton = styled(ButtonWithTooltip)(({ theme }) => ({
  wordBreak: "keep-all",
  ".MuiButton-startIcon": {
    color: theme.palette["Gray/Shades"].p56,
  },
}));

export type MenuProps = {
  finishButton?: ButtonWithTooltipProps;
  help: HelpMenuProps;
  messageDialog: Pick<BaseDialogProps, "open" | "yesButton">;
  messageForCandidate?: string;
  item: { type: "MANY"; content: PreferencesMenuProps } | { type: "LANGUAGE_ONLY" };
};

const Menu: React.FC<MenuProps> = props => {
  const { t } = useTranslation();
  const finishButton: ButtonWithTooltipProps | undefined = (props.finishButton || undefined) && {
    ...props.finishButton,
    color: "info",
    variant: "outlined",
    children: t("テスト終了"),
    startIcon: "LOGOUT",
    size: "medium",
  };
  const messageForCandidateDialog: BaseDialogProps = {
    ...props.messageDialog,
    title: t("企業からのメッセージ"),
    yesButton: {
      ...props.messageDialog.yesButton,
      children: t("閉じる"),
    },
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      {finishButton && <ActionButton {...finishButton} variant="outlined" color="outline-only" />}
      {props.item.type === "LANGUAGE_ONLY" && <LanguageDropDownMenu />}
      <HelpMenu {...props.help} />
      <BaseDialog {...messageForCandidateDialog}>{props.messageForCandidate || ""}</BaseDialog>
      {props.item.type === "MANY" && <PreferencesMenu {...props.item.content} />}
    </Stack>
  );
};

Menu.displayName = "Menu";

export default Menu;
