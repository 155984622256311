import { useTranslation } from "@hireroo/i18n";
import { TextFieldProps } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import InputControlTextField from "../../../../primitive/InputControl/InputControlTextField/InputControlTextField";

export type DisplayNameFieldProps = TextFieldProps & {
  label?: string;
  value?: string;
};

const DisplayNameField: React.FC<DisplayNameFieldProps & { name: string }> = props => {
  const { t } = useTranslation();
  const method = useFormContext();
  return (
    <Stack direction="column" spacing={1}>
      <Typography fontSize={14} fontWeight={700}>
        {t("氏名")}
      </Typography>
      <InputControlTextField {...props} control={method.control} />
    </Stack>
  );
};

DisplayNameField.displayName = "DisplayNameField";

export default DisplayNameField;
