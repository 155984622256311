import { COMMON_PASSED_RATE, EVALUATED_THRESHOLD } from "./constants";
import * as Types from "./types";

export const calculateNotPassedNumFromSpotCountByResult = (spotCountByResult: Types.SpotCountByResult) => {
  const evaluatedCount = spotCountByResult.passed + spotCountByResult.notPassed;
  const totalSpotCount = spotCountByResult.passed + spotCountByResult.notPassed + spotCountByResult.notEvaluated;

  // if the evaluated count is greater than the threshold, calculate not passed rate based on the company's data
  if (evaluatedCount > EVALUATED_THRESHOLD) {
    // calculate the not passed number based on the not passed rate
    return Math.round((spotCountByResult.notPassed / evaluatedCount) * totalSpotCount);
  }

  // if evaluated data is not enough, use the common passed rate
  return Math.round(totalSpotCount * (1 - COMMON_PASSED_RATE));
};

export const isEnoughSpotData = (spotCountByResult: Types.SpotCountByResult) => {
  const totalSpotCount = spotCountByResult.passed + spotCountByResult.notPassed + spotCountByResult.notEvaluated;
  return totalSpotCount > 1;
};

type CalculateReducedHoursArgs = {
  notPassedNum: number;
  testHours: number;
};
export const calculateReducedHours = (args: CalculateReducedHoursArgs) => {
  return Math.round(args.notPassedNum * args.testHours);
};
