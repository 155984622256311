import { useTranslation } from "@hireroo/i18n";
import { Add, AlignHorizontalRight } from "@mui/icons-material";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useFieldArray } from "react-hook-form";

import AcceptButton, { AcceptButtonProps } from "../../../../primitive/Button/AcceptButton";
import InputControlTextField, { InputControlTextFieldProps } from "../../../../primitive/InputControl/InputControlTextField";
import { useAlgorithmTestCasesFormContext } from "../../Context";
import { generateDefaultAlgorithmPerformanceTC, generateDefaultDatabasePerformanceTC } from "../../generateDefaultTestCase";
import TestCaseInput from "./parts/TestCaseInput/TestCaseInput";

export type AlgorithmPerformanceTestCasesFormProps = {};

const AlgorithmPerformanceTestCasesForm: React.FC<AlgorithmPerformanceTestCasesFormProps> = _props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { method, contextProps } = useAlgorithmTestCasesFormContext();

  const performanceTestCases = useFieldArray({
    control: method.control,
    name: "performanceTestCases.data",
  });

  const addPerformanceTestCase = () => {
    contextProps.signatureProps.variant === "ALGORITHM" &&
      performanceTestCases.append(generateDefaultAlgorithmPerformanceTC(contextProps.signatureProps.signature));
    contextProps.signatureProps.variant === "DATABASE" &&
      performanceTestCases.append(generateDefaultDatabasePerformanceTC(contextProps.signatureProps.signature));
  };

  const deletePerformanceTestCase = (index: number) => () => {
    performanceTestCases.remove(index);
  };

  const addTestCaseButton: AcceptButtonProps = {
    onClick: addPerformanceTestCase,
    size: "small",
    startIcon: <Add fontSize="small" />,
    variant: "text",
    children: t("テストケースを追加する").toString(),
  };

  const timeLimitField: InputControlTextFieldProps = {
    control: method.control,
    label: t("テストケースの最大実行時間"),
    InputLabelProps: {
      shrink: true,
    },
    type: "number",
    color: "primary",
    fullWidth: true,
    variant: "outlined",
    InputProps: {
      endAdornment: <InputAdornment position="end">{"ms"}</InputAdornment>,
    },
    helperText: t("テストケースの実行時間がこの値を超えた場合、不正解となります。"),
    required: true,
  };

  return (
    <Box>
      <Stack direction="row" alignItems="center" spacing={1}>
        <AlignHorizontalRight fontSize="small" sx={{ color: theme.palette.common.white }} />
        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
          {t("テストケース（パフォーマンス）を入力する")}
        </Typography>
      </Stack>

      <Box mt={2}>
        <Typography variant="body2">
          {t(
            "テストケース（パフォーマンス）は、提出されたコードのパフォーマンスを計測するのに利用されます。入力負荷が徐々に上がるようにケースを追加すると精度が上がります。",
          )}
        </Typography>
      </Box>

      <Box mt={3}>
        <Box width="50%">
          <InputControlTextField name="performanceTestCases.timeLimit" {...timeLimitField} />
        </Box>

        <Box my={2} display="flex" justifyContent="end">
          <AcceptButton {...addTestCaseButton} />
        </Box>

        {performanceTestCases.fields.map((field, i) => (
          <TestCaseInput key={field.id} index={i} length={performanceTestCases.fields.length} onDelete={deletePerformanceTestCase(i)} />
        ))}
      </Box>
    </Box>
  );
};

AlgorithmPerformanceTestCasesForm.displayName = "AlgorithmPerformanceTestCasesForm";

export default AlgorithmPerformanceTestCasesForm;
