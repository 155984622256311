import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import {
  CardCvcElement,
  CardCvcElementProps,
  CardExpiryElement,
  CardExpiryElementProps,
  CardNumberElement,
  CardNumberElementProps,
  useStripe,
} from "@stripe/react-stripe-js";
import type * as stripeJs from "@stripe/stripe-js";
import * as React from "react";

import ResourceField from "../../../../../modules/ResourceField/ResourceField";

const ReadOnlyTextField = styled(TextField)(({ theme }) => ({
  paddingTop: 8,
  paddingBottom: 8,
  fontSize: 16,
  marginRight: theme.spacing(1.5),
  padding: 0,
}));

const StyledBoxWrapper = styled(Box)(({ theme }) => ({
  ".StripeElement": {
    display: "block",
    maxWidth: "500px",
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    height: 40,
    fontSize: 14,
    fontFamily: theme.typography.body2.fontFamily,
    border: `1px solid ${theme.palette.Other.OutlinedBorder}`,
    outline: 0,
    borderRadius: "4px",
  },
}));

const InnerBox = styled(Box)(() => ({
  width: 400,
}));

const StyledHelperText = styled(FormHelperText)(({ theme }) => ({
  paddingLeft: theme.spacing(1.5),
  paddingBottom: theme.spacing(0.5),
  textAlign: "right",
  color: theme.palette.error.main,
}));

const useOptions = (props: { disabled: boolean }): stripeJs.StripeCardNumberElementOptions => {
  const theme = useTheme();
  const options = React.useMemo(
    (): stripeJs.StripeCardNumberElementOptions => ({
      showIcon: true,
      iconStyle: "solid",
      disabled: props.disabled,
      style: {
        base: {
          fontSize: "14px",
          color: theme.palette.text.primary,
          fontFamily: theme.typography.body2.fontFamily,
          "::placeholder": {
            color: theme.palette.text.disabled,
          },
        },
        invalid: {
          color: theme.palette.error.main,
        },
      },
    }),
    [theme, props.disabled],
  );

  return options;
};

export type StripeSplitCardElementFieldProps = {
  /**
   * @default false
   */
  disabledEditButton?: boolean;
  lastFour: string;
  onSubmit?: (cardElement: stripeJs.StripeCardNumberElement) => void;
  disabled?: boolean;
};

const StripeSplitCardElementField: React.FC<StripeSplitCardElementFieldProps> = props => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const disabled = props.disabled || !stripe;
  const options = useOptions({
    disabled: disabled,
  });
  const [cardNumberErrorMessage, setCardNumberErrorMessage] = React.useState<string | null>(null);
  const [cardExpiryElementErrorMessage, setCardExpiryElementErrorMessage] = React.useState<string | null>(null);
  const [cardCvcElementErrorMessage, setCardCvcElementErrorMessage] = React.useState<string | null>(null);
  const [mode, setMode] = React.useState<"READ" | "EDIT">("READ");

  const reset = React.useCallback(() => {
    setCardNumberErrorMessage(null);
    setCardExpiryElementErrorMessage(null);
    setCardCvcElementErrorMessage(null);
  }, []);

  const cardNumberElementProps: CardNumberElementProps = {
    options,
    onChange: event => {
      setCardNumberErrorMessage(event.error?.message || null);
    },
  };

  const cardExpiryElementProps: CardExpiryElementProps = {
    options: {
      ...options,
      placeholder: t("月 / 年"),
    },
    onChange: event => {
      setCardExpiryElementErrorMessage(event.error?.message || null);
    },
  };

  const cardCvcElementProps: CardCvcElementProps = {
    options: {
      ...options,
      placeholder: t("セキュリティコード"),
    },
    onChange: event => {
      setCardCvcElementErrorMessage(event.error?.message || null);
    },
  };

  const editButtonProps: ButtonProps = {
    variant: "outlined",
    color: "secondary",
    onClick: () => {
      setMode("EDIT");
    },
    children: t("編集"),
  };

  const cancelButtonProps: ButtonProps = {
    variant: "outlined",
    color: "secondary",
    onClick: () => {
      setMode("READ");
      reset();
    },
    children: t("キャンセル"),
  };

  if (mode === "READ" && props.lastFour) {
    return (
      <StyledBoxWrapper alignItems="center" display="flex" flexDirection="row">
        <ReadOnlyTextField size="small" color="secondary" inputProps={{ readOnly: true }} value={`**** **** **** ${props.lastFour}`} />
        {!props.disabledEditButton && <Button {...editButtonProps} />}
      </StyledBoxWrapper>
    );
  }

  return (
    <StyledBoxWrapper>
      <InnerBox>
        <Box width="100%" mb={3}>
          <ResourceField label={t("カード番号")} kind="REQUIRED">
            <CardNumberElement {...cardNumberElementProps} />
            {cardNumberErrorMessage && <StyledHelperText>{cardNumberErrorMessage}</StyledHelperText>}
          </ResourceField>
        </Box>
        <Stack direction="row" spacing={1.5} mb={3}>
          <Box width="100%">
            <ResourceField label={t("有効期限")} kind="REQUIRED">
              <CardExpiryElement {...cardExpiryElementProps} />
              {cardExpiryElementErrorMessage && <StyledHelperText>{cardExpiryElementErrorMessage}</StyledHelperText>}
            </ResourceField>
          </Box>
          <Box width="100%">
            <ResourceField label={"CVC"} kind="REQUIRED">
              <CardCvcElement {...cardCvcElementProps} />
              {cardCvcElementErrorMessage && <StyledHelperText>{cardCvcElementErrorMessage}</StyledHelperText>}
            </ResourceField>
          </Box>
        </Stack>
        {!props.disabledEditButton && <Button {...cancelButtonProps} />}
      </InnerBox>
    </StyledBoxWrapper>
  );
};

StripeSplitCardElementField.displayName = "StripeSplitCardElementField";

export default StripeSplitCardElementField;
