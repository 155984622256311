import { SKIP_OPTION_ID } from "@hireroo/app-definition/quiz";
import { isOptionAction, QuizSyncState } from "@hireroo/app-helper/firepad";
import { useQuizRevisionsV2 } from "@hireroo/app-helper/hooks";
import { scrollToContentForReportV2 } from "@hireroo/app-helper/html-element";
import { QuizTestReport } from "@hireroo/app-store/view-domain/QuizTestReport";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { TotalScoreRankVisualizer } from "@hireroo/app-store/widget/shared/TotalScoreRankVisualizer";
import { formatScore } from "@hireroo/formatter/score";
import * as Time from "@hireroo/formatter/time";
import { formatSeconds, timeFormat } from "@hireroo/formatter/time";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import type * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import { QuizFreeTextEvaluateForm } from "@hireroo/validator";
import * as Sentry from "@sentry/react";
import * as React from "react";

import {
  convertSubmissionsFieldValue,
  diffDefaultSubmissionIDs,
  getSubmissionStatus,
  QUIZ_PLAYBACK_ELEMENT_ID,
  shouldDisplayAdditionalDetail,
  SubmissionStatus,
} from "./privateHelper";

type Mode = "READ" | "EDIT";

type SubmissionSectionProps = Exclude<Widget.QuizTestReportProps["submissionSection"], undefined>;
type AccordionProps = SubmissionSectionProps["allSubmissionAccordions"][0];
type AnswerContent = AccordionProps["answerContent"];
type EvaluationItemContentProps = Exclude<AccordionProps["evaluationItemContent"], undefined>;
type EvaluationItemProps = EvaluationItemContentProps["evaluationItems"][0];
type EvaluationStatus = EvaluationItemProps["status"];

type SubmissionResource = {
  question: QuizTestReport.QuizQuestion;
  submissionIds: number[];
  status: SubmissionStatus;
};

type ShowingTarget = "STATISTIC" | "EDITOR" | "ANSWER" | "PLAYBACK";

export type SubmissionAccordionPropsGeneratorArgs = {
  quizId: number;
  showingTargets: ShowingTarget[];
};

type QuestionId = number;
type QuizSyncStateWithIndex = QuizSyncState & { index: number };

const useSubmissionAccordionPropsGenerator = (args: SubmissionAccordionPropsGeneratorArgs) => {
  const { quizId, showingTargets } = args;
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const hooks = QuizTestReport.useCreateQuizHooks(quizId);
  const submissionMap = hooks.useSubmissionMap();
  const evaluationMap = hooks.useEvaluationMap();
  const quizPackage = hooks.usePackage();
  const questionIds = hooks.useQuestionIds();
  const { revisions, ready } = useQuizRevisionsV2({
    quizId: args.quizId,
    packageId: quizPackage.packageId,
    questionIds: questionIds,
  });

  const revisionByQuestionIdMap = React.useMemo((): Map<QuestionId, QuizSyncStateWithIndex> => {
    const revisionsByQuestionId = new Map<QuestionId, QuizSyncStateWithIndex>();

    for (let i = 0; i < revisions.length; i++) {
      const revision = revisions[i];
      if (isOptionAction(revision) && revision.s === "inq" && !revisionsByQuestionId.has(revision.v)) {
        revisionsByQuestionId.set(revision.v, { ...revision, index: i });
      }
    }
    return revisionsByQuestionId;
  }, [revisions]);

  return React.useCallback(
    (
      resource: SubmissionResource,
      status: "all" | "correct" | "incorrect" | "skip" | "unanswered" | "unevaluated",
      mode: Mode,
    ): SubmissionSectionProps["allSubmissionAccordions"][0] => {
      const submission = hooks.getSelectedSubmission(`${resource.question.id}-${resource.question.version}`);
      const evaluations = evaluationMap.get(`${resource.question.id}-${resource.question.version}`);
      const submissionStatus = getSubmissionStatus(resource.question, submission);

      const optionMap = new Map<number, QuizTestReport.QuizOption>();
      resource.question.options.forEach(option => {
        optionMap.set(option.id, option);
      });

      const getSelectedAdditionalDetail = (optionId: number) => {
        const target = resource.question.options.find(option => option.id === optionId);
        return target ? resolveLanguage(target, lang, "additionalDetail") : "";
      };

      const getEvaluationStatus = (evaluation: QuizTestReport.Evaluation, evaluatedBy?: string): EvaluationStatus => {
        if (evaluatedBy === "") return "NOT_EVALUATED";
        if (evaluation.isPassed) return "CORRECT";
        return "IN_CORRECT";
      };

      const answerContentMap = {
        FREE_TEXT: {
          kind: "FREE_TEXT",
          content: {
            answerText: submission?.answerText,
          },
        },
        MULTI_CHOICE: {
          kind: "MULTI_OPTION",
          content: {
            optionItems: resource.question.options.map(
              (option): Extract<AnswerContent, { kind: "MULTI_OPTION" }>["content"]["optionItems"][0] => {
                return {
                  id: option.id,
                  title: resolveLanguage(option, lang, "title"),
                  status: option.isCorrect ? "CORRECT" : "INCORRECT",
                };
              },
            ),
            submittedOptionIds: submission?.optionIds,
            isSkipped: submission?.optionIds[0] === SKIP_OPTION_ID,
          },
          correctStatus: submission?.isCorrect ? "CORRECT" : "WRONG",
        },
        SINGLE_CHOICE: {
          kind: "SINGLE_OPTION",
          content: {
            optionItems: resource.question.options.map(
              (option): Extract<AnswerContent, { kind: "SINGLE_OPTION" }>["content"]["optionItems"][0] => {
                return {
                  id: option.id,
                  title: resolveLanguage(option, lang, "title"),
                  status: option.isCorrect ? "CORRECT" : "INCORRECT",
                };
              },
            ),
            submittedOptionId: submission?.optionIds[0],
            isSkipped: submission?.optionIds[0] === SKIP_OPTION_ID,
          },
          correctStatus: submission?.isCorrect ? "CORRECT" : "WRONG",
        },
        UNKNOWN: {
          kind: "FREE_TEXT",
          content: {
            answerText: submission?.answerText,
          },
        },
      } satisfies Record<Graphql.MultiChoiceQuestionVariant, AnswerContent>;

      const startTime = revisions[0]?.t;
      const targetRevision = revisionByQuestionIdMap.get(resource.question.id);
      const targetTime = targetRevision?.t ?? 0;
      const passedTime = Time.elapsedTimeFormat(targetTime - startTime);
      const reversedSubmissionIds = [...resource.submissionIds].reverse();

      return {
        id: `${quizId}-${status}-${resource.question.id}-${resource.question.version}`,
        canShowStatistic: showingTargets.includes("STATISTIC"),
        // TODO: It will be fixed when free text evaluate logic implement
        isLoading: false,
        summaryContent: {
          questionTitle: resolveLanguage(resource.question, lang, "title"),
          status: submissionStatus,
          evaluateStatus: (() => {
            if (!submission) {
              return;
            }
            if (submission.isAutomaticEvaluation) {
              return "AUTO_EVALUATED";
            }
            return submission.evaluatedBy ? "EDITED" : undefined;
          })(),
          accuracyRateLabel: (() => {
            if (resource.question.variant === "FREE_TEXT" && evaluations) {
              const result = evaluations.reduce<{ correct: number; total: number }>(
                (current, evaluation) => {
                  return {
                    correct: current.correct + (evaluation.isPassed ? 1 : 0),
                    total: current.total + 1,
                  };
                },
                { correct: 0, total: 0 },
              );
              return `${result.correct}/${result.total}`;
            }
            return;
          })(),
        },
        processContent: {
          activeStep: reversedSubmissionIds.length > 0 ? resource.submissionIds[0] : undefined,
          stepItems: reversedSubmissionIds.map((submissionId, index): AccordionProps["processContent"]["stepItems"][0] => {
            const tmpSubmission = submissionMap.get(submissionId);
            return {
              title: `${t("提出")} ${reversedSubmissionIds.length - index}`,
              completed: submission?.quizSubmissionId === submissionId,
              optionText: tmpSubmission ? timeFormat(new Date((tmpSubmission.createdAtSeconds ?? 0) * 1000)) : t("不明"),
              // Employee can only be evaluated with the latest submission.
              disabled: mode === "EDIT" && resource.submissionIds.length - 1 !== index,
              submittedAnswer: tmpSubmission?.answerText ?? "",
              onClick: () =>
                QuizTestReport.setSelectedSubmissionId(quizId, submissionId, `${resource.question.id}-${resource.question.version}`),
            };
          }),
        },
        questionContent: {
          description: resolveLanguage(resource.question, lang, "description"),
          avgCorrectScore: resource.question.correctAnswerRate ? formatScore(resource.question.correctAnswerRate) : undefined,
        },
        answerContent: (() => {
          if (args.showingTargets.includes("ANSWER")) return answerContentMap[resource.question.variant];
          /** only free text can show regardless of the showing targets */
          if (resource.question.variant === "FREE_TEXT") return answerContentMap["FREE_TEXT"];
          return undefined;
        })(),
        evaluationItemContent:
          resource.question.variant === "FREE_TEXT" && submission && args.showingTargets.includes("ANSWER")
            ? {
                evaluatedBy: submission?.evaluatedUser?.displayName,
                mode: mode,
                canEvaluate: submission?.answerText !== undefined && submission.answerText !== "",
                submissionId: submission.quizSubmissionId,
                evaluationItems: (evaluations || []).map((evaluation, index): EvaluationItemProps => {
                  return {
                    id: evaluation.evaluationItem?.id ? `${evaluation.evaluationItem.id}` : `${index + 1}`,
                    title: resolveLanguage(evaluation.evaluationItem || {}, lang, "title"),
                    status: getEvaluationStatus(evaluation, submission?.evaluatedBy),
                    reason: (() => {
                      if (submission.isAutomaticEvaluation) {
                        return resolveLanguage(evaluation, lang, "reason", "") || undefined;
                      }
                      return undefined;
                    })(),
                  };
                }),
              }
            : undefined,
        elapsedTime: formatSeconds(submission?.elapsedTimeSeconds || 0, lang),
        correctAnswerRate: resource.question.correctAnswerRate ? Math.floor(resource.question.correctAnswerRate * 100) : undefined,
        additionalDetail:
          shouldDisplayAdditionalDetail(resource.question, submission) &&
          args.showingTargets.includes("ANSWER") &&
          submission?.optionIds &&
          submission.optionIds.length > 0 &&
          getSelectedAdditionalDetail(submission.optionIds[0]) !== ""
            ? {
                description: getSelectedAdditionalDetail(submission.optionIds[0]),
              }
            : undefined,
        submittedButton:
          targetTime !== 0
            ? {
                onClick: () => {
                  const element = document.getElementById(QUIZ_PLAYBACK_ELEMENT_ID);
                  if (element) {
                    scrollToContentForReportV2(QUIZ_PLAYBACK_ELEMENT_ID);
                    if (targetRevision?.index) {
                      QuizTestReport.setForceRevisionIndex(quizId, targetRevision.index);
                    }
                  }
                },
                disabled: !ready || !args.showingTargets.includes("PLAYBACK"),
                children: ready ? passedTime : t("読み込み中"),
              }
            : undefined,
      };
    },
    [args.showingTargets, evaluationMap, hooks, lang, quizId, ready, revisionByQuestionIdMap, revisions, showingTargets, submissionMap, t],
  );
};

export type GenerateSubmissionSectionPropsArgs = {
  uniqueKey: QuizTestReport.UniqueKey;
  quizId: number;
  showingTargets: SubmissionAccordionPropsGeneratorArgs["showingTargets"];
  canEdit: boolean;
};

export const useGenerateSubmissionSectionProps = (
  args: GenerateSubmissionSectionPropsArgs,
): Widget.QuizTestReportProps["submissionSection"] => {
  const client = getGraphqlClient();
  const { t } = useTranslation();
  const hooks = QuizTestReport.useCreateQuizHooks(args.quizId);
  const questionMap = hooks.useQuestionMap();
  const submissionMap = hooks.useSubmissionMap();
  const submissionResourceKeys = hooks.useSubmissionResourceKeys();
  const [mode, setMode] = React.useState<Mode>("READ");
  const [evaluateStatus, setEvaluateStatus] = React.useState<"READY" | "PENDING">("READY");
  const canEdit = args.canEdit;

  const handleChangeMode = React.useCallback(
    (mode: Mode) => {
      setMode(mode);
      // Before evaluate, we have to update to latest submissions.
      QuizTestReport.setLatestSubmissions(args.quizId);
    },
    [args.quizId],
  );

  const allSubmissionResources = React.useMemo((): SubmissionResource[] => {
    return submissionResourceKeys
      .map((key): SubmissionResource | undefined => {
        const question = questionMap.get(`${key.questionId}-${key.questionVersion}`);
        const submissionId = key.submissionIds[key.submissionIds.length - 1];
        const submission = submissionMap.get(submissionId);
        if (question) {
          const submissionStatus = getSubmissionStatus(question, submission);
          return {
            question,
            submissionIds: key.submissionIds,
            status: submissionStatus,
          };
        }
      })
      .flatMap(v => (v ? [v] : []));
  }, [questionMap, submissionMap, submissionResourceKeys]);

  const defaultValues = React.useMemo(() => {
    return allSubmissionResources
      .filter(resource => resource.question.variant === "FREE_TEXT" && resource.submissionIds && resource.submissionIds.length > 0)
      .map((resource): QuizFreeTextEvaluateForm.SubmissionResource | undefined => {
        const submission = submissionMap.get(resource.submissionIds[resource.submissionIds.length - 1]);
        // Employee can evaluate only the submission has answerText.
        if (submission && submission.answerText !== "") {
          return {
            submissionId: submission.quizSubmissionId,
            isCorrect: submission.quizSubmissionStatus === "EVALUATED" ? submission.isCorrect : undefined,
            evaluations: submission.evaluations
              .map((evaluation): QuizFreeTextEvaluateForm.SubmissionResource["evaluations"][0] | undefined => {
                if (evaluation.evaluationItem) {
                  return {
                    id: evaluation.evaluationItem?.id,
                    isPassed: submission.quizSubmissionStatus === "EVALUATED" ? evaluation.isPassed : undefined,
                    questionId: resource.question.id,
                    questionVersion: resource.question.version,
                  };
                }
              })
              .flatMap(v => (v ? [v] : [])),
          };
        }
      })
      .flatMap(v => (v ? [v] : []));
  }, [allSubmissionResources, submissionMap]);
  const freeTextSubmissions = allSubmissionResources.filter(
    resource => resource.question.variant === "FREE_TEXT" && resource.submissionIds && resource.submissionIds.length > 0,
  );

  const onEvaluate = React.useCallback(
    (field: QuizFreeTextEvaluateForm.QuizFreeTextEvaluateForm) => {
      setEvaluateStatus("PENDING");
      const diffIds = diffDefaultSubmissionIDs(field, defaultValues);
      if (diffIds.length <= 0) {
        setEvaluateStatus("READY");
        setMode("READ");
        return;
      }

      diffIds.forEach(id => {
        const evaluateTarget = field[id];
        let isPassedCount = 0;

        const evaluations = evaluateTarget.evaluations.map((evaluation): Graphql.QuizEvaluationsInput => {
          if (evaluation.isPassed) {
            isPassedCount++;
          }
          return {
            evaluationItem: {
              id: evaluation.id,
              questionId: evaluation.questionId,
              questionVersion: evaluation.questionVersion,
            },
            isPassed: !!evaluation.isPassed,
            submissionId: evaluateTarget.submissionId,
          };
        });

        client
          .EvaluateQuizSubmission({
            input: {
              submissionId: evaluateTarget.submissionId,
              isCorrect: evaluations.length > 0 ? isPassedCount > 0 : !!evaluateTarget.isCorrect,
              evaluations: evaluations,
            },
          })
          .then(res => {
            QuizTestReport.updateEvaluateSubmission(args.quizId, res.evaluateQuizSubmission);
            /**
             * Clear the cache before revaluation in order to obtain the state after revaluation.
             */
            TotalScoreRankVisualizer.clearQueryResultCache(args.uniqueKey);
            /**
             * The cache is cleared in the previous section, so the latest information is retrieved.
             */
            TotalScoreRankVisualizer.refreshQuery(args.uniqueKey);
            Snackbar.notify({
              severity: "success",
              message: t("クイズ形式の採点の更新に成功しました"),
            });
          })
          .catch(err => {
            Snackbar.notify({
              severity: "error",
              message: t("採点結果の編集に失敗しました。しばらくしてから再度お試し頂くか、運営までお問い合わせください。"),
            });
            Sentry.captureException(err);
          })
          .finally(() => {
            setEvaluateStatus("READY");
            setMode("READ");
          });
      });
    },
    [args.quizId, args.uniqueKey, client, defaultValues, t],
  );

  const correctSubmissionResources = allSubmissionResources.filter(resource => resource.status === "CORRECT");
  const incorrectSubmissionResources = allSubmissionResources.filter(resource => resource.status === "INCORRECT");
  const skipSubmissionResources = allSubmissionResources.filter(resource => resource.status === "SKIP");
  const unansweredSubmissionResources = allSubmissionResources.filter(resource => resource.status === "NO_SUBMISSION");
  const unevaluatedSubmissionResources = allSubmissionResources.filter(resource => resource.status === "NOT_EVALUATED");

  const submissionAccordionGenerator = useSubmissionAccordionPropsGenerator({
    quizId: args.quizId,
    showingTargets: args.showingTargets,
  });

  const allSubmissionAccordionProps = allSubmissionResources.map(
    (resource): AccordionProps => submissionAccordionGenerator(resource, "all", mode),
  );
  const correctSubmissionAccordionProps = correctSubmissionResources.map(
    (resource): AccordionProps => submissionAccordionGenerator(resource, "correct", mode),
  );
  const incorrectSubmissionAccordionProps = incorrectSubmissionResources.map(
    (resource): AccordionProps => submissionAccordionGenerator(resource, "incorrect", mode),
  );
  const skipSubmissionAccordionProps = skipSubmissionResources.map(
    (resource): AccordionProps => submissionAccordionGenerator(resource, "skip", mode),
  );
  const unansweredSubmissionAccordionProps = unansweredSubmissionResources.map(
    (resource): AccordionProps => submissionAccordionGenerator(resource, "unanswered", mode),
  );
  const unevaluatedSubmissionAccordionProps = unevaluatedSubmissionResources.map(
    (resource): AccordionProps => submissionAccordionGenerator(resource, "unevaluated", mode),
  );

  return {
    mode,
    allSubmissionAccordions: allSubmissionAccordionProps,
    correctSubmissionAccordions: correctSubmissionAccordionProps,
    incorrectSubmissionAccordions: incorrectSubmissionAccordionProps,
    skipSubmissionAccordions: skipSubmissionAccordionProps,
    unansweredSubmissionAccordions: unansweredSubmissionAccordionProps,
    unevaluatedSubmissionAccordions: unevaluatedSubmissionAccordionProps,
    formActionButtonGroup: args.showingTargets.includes("EDITOR")
      ? {
          cancelButton: {
            loading: evaluateStatus === "PENDING",
          },
          saveButton: {
            loading: evaluateStatus === "PENDING",
          },
          editButton: {
            disabled: freeTextSubmissions.length === 0 || !canEdit,
          },
        }
      : undefined,
    defaultValues: convertSubmissionsFieldValue(defaultValues),
    onChangeMode: handleChangeMode,
    onSubmit: onEvaluate,
  };
};
