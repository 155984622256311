import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import * as z from "zod";

export const useTestResult = () => {
  return z.enum(["PASSED", "NOT_PASSED"]);
};

export type TestResult = z.infer<ReturnType<typeof useTestResult>>;

export const useTestEvaluationForm = () => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const result = useTestResult();
  return z.object({
    result: result.nullable(),
    comment: z.string().max(1000, { message: t2("ValidateMaxTextSizeMessage", { name: t("評価コメント"), size: 1000 }) }),
    agreement: z.boolean(),
  });
};

export type TestEvaluationFormSchema = z.infer<ReturnType<typeof useTestEvaluationForm>>;
