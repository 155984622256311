import { state } from "./State";
import type * as Types from "./types";

export const clear = (): void => {
  state.targetScreeningId = null;
};

export const updateTargetScreeningId = (spotId: Types.ScreeningId) => {
  state.targetScreeningId = spotId;
};

export const setSubmitValue = <T extends keyof Types.SubmitValues>(stepName: T, value: Types.SubmitValues[T]) => {
  state.submitValues[stepName] = value;
};
