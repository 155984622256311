import { proxy } from "valtio";
import { proxySet } from "valtio/utils";

import * as Def from "./definition";
import type * as Types from "./types";

export const state = proxy<Types.State>({
  questionObjects: null,
  count: 0,
  searchQuery: Def.createDefaultSearchQuery(),
  hiddenQuestionSet: proxySet(),
  loadingStatus: "NONE",
});
