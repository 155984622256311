import * as Def from "@hireroo/app-definition/interview";
import * as Graphql from "@hireroo/graphql/client/urql";

export const convertStringsToTagNames = (values: string[]): string[] => {
  // Get all the valid string values
  const tagNames: string[] = [];
  values.forEach(value => {
    value.split("%").forEach(v => {
      tagNames.push(v);
    });
  });
  return tagNames;
};

export const convertStringsToScreeningIds = (values: string[]): string[] => {
  // Get all the valid string values
  const screeningIds: string[] = [];
  values.forEach(value => {
    value.split("%").forEach(v => {
      screeningIds.push(v);
    });
  });
  return screeningIds;
};

export const convertStringToResult = (value: string | null): boolean | undefined => {
  if (!value) return;
  for (const v of value.split("%")) {
    if (typeof Def.RESULT_REVERSE_MAP[v] !== "undefined") {
      return Def.RESULT_REVERSE_MAP[v];
    }
  }
};

export const convertStringsToStatuses = (values: string[]): Graphql.SpotStatus[] => {
  const newStatuses: Graphql.SpotStatus[] = [];

  for (const value of values) {
    value.split("%").forEach(v => {
      // Check if the string value can be converted to a valid status.
      if (Def.STATUS_REVERSE_MAP[v]) {
        newStatuses.push(Def.STATUS_REVERSE_MAP[v]);
      }
    });
  }
  return newStatuses;
};

export const convertStringsToRankEvaluations = (values: string[]): Graphql.RankEvaluation[] => {
  const newRankEvaluations: Graphql.RankEvaluation[] = [];

  for (const value of values) {
    if (!value) continue;
    value.split("%").forEach(v => {
      if (Def.RANK_EVALUATION_REVERSE_MAP[v]) {
        newRankEvaluations.push(Def.RANK_EVALUATION_REVERSE_MAP[v]);
      }
    });
  }
  return newRankEvaluations;
};

export const convertStringToTotalScore = (value: string | null): number | undefined => {
  if (!value) return undefined;
  const score = Number(value);

  if (0 <= score && score <= 1) {
    return score;
  }
  return undefined;
};

export const convertStringToSortMethod = (value: string | null): Graphql.SpotsByCompanyIdSortMethod => {
  if (value === null) return Graphql.SpotsByCompanyIdSortMethod.CreatedAt;
  try {
    // "sortMethod+isDesc" (e.g.) "CREATED_AT-true"
    const [method] = window.btoa(value).split("-");
    return Def.SORT_METHOD_REVERS_MAP[method] ?? Graphql.SpotsByCompanyIdSortMethod.CreatedAt;
  } catch (_) {
    return Graphql.SpotsByCompanyIdSortMethod.CreatedAt;
  }
};

export const convertStringToIsDesc = (value: string | null): boolean => {
  if (value === null) return true;
  try {
    // "sortMethod+isDesc" (e.g.) "CREATED_AT-true"
    const [, isDesc] = window.btoa(value).split("-");
    return isDesc === "true";
  } catch (_) {
    return false;
  }
};

export const convertStringsToUids = (values: string[]): string[] => {
  // Get all the valid string values
  const uids: string[] = [];
  values.forEach(value => {
    value.split("%").forEach(v => {
      uids.push(v);
    });
  });
  return uids;
};

export const convertStringToPage = (value: string | null): number => {
  if (value === null) return 0;
  const page = Number(value);
  // Set it to 0 if it is invalid (NaN or negative). Note that page > 0 is false if page is NaN.
  // Actual (zero-index) page number = (page number from the query param) - 1
  if (!Number.isInteger(page) || page < 1) {
    return 0;
  }
  return page - 1;
};

const DEFAULT_SIZE = 50;
const SIZES = [20, 30, 50];
export const convertStringToSize = (value: string | null) => {
  if (value === null) return DEFAULT_SIZE;
  const size = Number(value);
  // Set it to the default value if it is invalid.
  if (!SIZES.includes(size)) {
    return DEFAULT_SIZE;
  }
  return size;
};

export const convertStringsToSuspiciousDegrees = (values: string[]): Graphql.SpotSuspiciousDegree[] => {
  const newSuspiciousDegrees: Graphql.SpotSuspiciousDegree[] = [];

  for (const value of values) {
    if (!value) continue;
    value.split("%").forEach(v => {
      if (Def.SUSPICIOUS_DEGREE_REVERSE_MAP[v]) {
        newSuspiciousDegrees.push(Def.SUSPICIOUS_DEGREE_REVERSE_MAP[v]);
      }
    });
  }
  return newSuspiciousDegrees;
};
