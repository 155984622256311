import { useTranslation } from "@hireroo/i18n";

import { ComponentType } from "../features";

export const useComponentTypesMap = (): Record<ComponentType, string> => {
  const { t } = useTranslation();
  return {
    default: t("デフォルト"),
    aws: "AWS",
    gcp: "GCP",
    azure: "Azure",
  } satisfies Record<ComponentType, string>;
};
