// import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
// import { useHelpCenterNavigate } from "@hireroo/router/hooks";

export type GenerateAssessmentTalentHeaderPropsArgs = {};

export const useGenerateProps = (_args: GenerateAssessmentTalentHeaderPropsArgs): Widget.AssessmentTalentHeaderProps => {
  // const { t } = useTranslation();
  // const lang = useLanguageCode();
  // const helpCenterNavigate = useHelpCenterNavigate(lang);
  return {
    //TODO
    endTestDialog: undefined,
    questionSwitcher: undefined,
    Timelimit: null,
    menu: {
      help: {
        options: [],
      },
      messageDialog: {
        open: false,
        yesButton: {
          onClick: () => {
            //
          },
        },
      },
      messageForCandidate: undefined,
      item: { type: "LANGUAGE_ONLY" },
    },
  };
};
