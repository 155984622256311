import { TotalScoreRankVisualizer } from "@hireroo/app-store/widget/shared/TotalScoreRankVisualizer";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import TotalScoreRankVisualizerContainer, { TotalScoreRankVisualizerContainerProps } from "./Container";

export type TotalScoreRankVisualizerInitialContainerProps = TotalScoreRankVisualizerContainerProps;

const TotalScoreRankVisualizerInitialContainer: React.FC<TotalScoreRankVisualizerInitialContainerProps> = props => {
  const initialized = TotalScoreRankVisualizer.useInitialized(props.uniqueKey);
  if (!initialized) {
    return (
      <Widget.RankVisualizer
        status="LOADING"
        queryToolbar={{
          enableTagField: false,
          onChange: () => undefined,
        }}
        scoreHistogram={{
          xAxis: {},
          yAxis: {},
          data: [],
          dataKey: { x: "", y: "" },
          lines: [],
        }}
      />
    );
  }
  return (
    <ErrorBoundary>
      <TotalScoreRankVisualizerContainer {...props} />
    </ErrorBoundary>
  );
};

TotalScoreRankVisualizerInitialContainer.displayName = "TotalScoreRankVisualizerInitialContainer";

export default withErrorBoundary(TotalScoreRankVisualizerInitialContainer, {});
