import { SupportLanguage, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import * as z from "zod";

export const useContent = () => {
  const { t } = useTranslation();
  return z.object({
    description: z.string().min(1, { message: t("詳細は必須です。") }),
    language: z.enum([SupportLanguage.JA, SupportLanguage.EN]),
  });
};
export type HintsContentSchema = z.infer<ReturnType<typeof useContent>>;

const useHintForm = () => {
  const content = useContent();
  return z.object({
    id: z.number().optional(),
    contents: z.array(content),
  });
};

export const useAlgorithmHintsFormSchema = () => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const hint = useHintForm();
  return z.object({
    newVersion: z
      .string()
      .min(1, { message: t("バージョンは必須項目です。") })
      .regex(/^v([0-9]+)\.([0-9]+)\.([0-9]+)$/, { message: t2("ValidateQuestionSetVersion") }),
    hints: z.array(hint),
  });
};

export type AlgorithmHintsFormSchema = z.infer<ReturnType<typeof useAlgorithmHintsFormSchema>>;
