import type { Fields } from "@hireroo/validator";
import { useTheme } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import { styled, Theme } from "@mui/material/styles";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import * as React from "react";
import { useController, useFieldArray } from "react-hook-form";

type OwnerStateThemeType = {
  theme: Theme;
  /**
   * interface is `"true" | undefined` according to the following warning
   *
   * If you want to write it to the DOM, pass a string instead: error="false" or error={value.toString()}.
   * If you used to conditionally omit it with error={condition && value}, pass error={condition ? value : undefined} instead.
   */
  error: "true" | undefined;
};

const SecondaryChip = styled(Chip)(({ theme, error }: OwnerStateThemeType) => ({
  height: 30,
  ".MuiChip-deleteIcon": {
    color: theme.palette.secondary.main,
  },
  color: theme.palette.secondary.main,
  borderColor: error === "true" ? theme.palette.error.main : theme.palette.secondary.main,
}));

const OutlinedChip = styled(Chip)(({ theme, error }: OwnerStateThemeType) => ({
  height: 30,
  ".MuiChip-deleteIcon": {
    color: theme.palette.action.disabled,
  },
  color: theme.palette.text.primary,
  borderColor: error === "true" ? theme.palette.error.main : theme.palette.action.disabled,
}));

const OutlinedTextField = styled(TextField)(() => ({
  border: "none",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
}));

type TemporaryField = {
  /** key is dummy for type safe */
  tags: (Fields.TagField.TagListItemSchema & { id?: number })[];
};

export type ItemProps = {
  id?: number;
  valueId: string;
};
const filter = createFilterOptions<ItemProps>();

type ErrorIndex = number;
type ErrorMap = Record<ErrorIndex, string | undefined>;

type Variant = "OUTLINED" | "SECONDARY";

export type TagFieldProps = Pick<TextFieldProps, "onChange" | "placeholder"> & {
  name: string;
  variant: Variant;
  suggestions: Omit<ItemProps, "id">[];
  loading?: boolean;
  onFocus?: () => void;
};

const TagField: React.FC<TagFieldProps> = props => {
  const theme = useTheme();
  const { suggestions, variant } = props;
  const typeHackFieldName = props.name as "tags";
  const { formState } = useController<TemporaryField>({
    name: typeHackFieldName,
  });

  const StyledChip = variant === "OUTLINED" ? OutlinedChip : SecondaryChip;

  const { fields, append, remove } = useFieldArray<TemporaryField>({
    /**
     * Name of the field array. Note: Do not support dynamic name.
     * @see https://react-hook-form.com/api/usefieldarray
     */
    name: typeHackFieldName,
  });

  const errorIndexMap = React.useMemo((): ErrorMap => {
    const errorMap: ErrorMap = {};
    const maxLength = fields.length;
    for (let i = 0; i < maxLength; i++) {
      const tagError = formState.errors.tags?.[i];
      if (tagError?.value?.name) {
        errorMap[i] = tagError.value.name.message;
      }
    }
    return errorMap;
  }, [fields.length, formState.errors.tags]);

  const hasError = React.useMemo(() => {
    const maxLength = fields.length;
    for (let i = 0; i < maxLength; i++) {
      const tagError = formState.errors.tags?.[i];
      if (tagError && tagError.value) {
        return true;
      }
    }
    return false;
  }, [fields.length, formState.errors.tags]);

  const errorMessages = React.useMemo(() => Object.entries(errorIndexMap).map(([_, v]) => v), [errorIndexMap]);

  const value = React.useMemo((): ItemProps[] => {
    return fields.map((field): ItemProps => {
      return {
        id: field.id,
        valueId: field.value.name,
      };
    });
  }, [fields]);

  return (
    <Autocomplete
      multiple
      fullWidth
      freeSolo
      value={value}
      isOptionEqualToValue={(option, value) => {
        return option.valueId === value.valueId;
      }}
      onChange={(event, items, reason) => {
        if (reason === "selectOption") {
          items
            .filter(item => typeof item !== "string" && !item.id)
            .forEach(item => {
              if (typeof item !== "string") {
                append({
                  value: {
                    name: item.valueId,
                  },
                });
              }
            });
        } else if (reason === "clear") {
          const removeIds = fields.reduce<number[]>((all, field, index) => {
            return all.concat(index);
          }, []);
          remove(removeIds);
        } else if (reason === "createOption") {
          items.forEach(item => {
            if (typeof item === "string") {
              append({
                value: {
                  name: item,
                },
              });
            }
          });
        }
      }}
      onFocus={props.onFocus}
      loading={props.loading}
      options={suggestions as ItemProps[]}
      getOptionLabel={option => {
        if (typeof option !== "string") {
          return option.valueId;
        }
        return option;
      }}
      filterOptions={(options, state) => {
        return filter(options, state);
      }}
      filterSelectedOptions
      renderTags={(items, getTagProps) => {
        return items.map((item, index) => {
          const tagProps = getTagProps({ index });
          const hasError = errorIndexMap[index] ? "true" : undefined;
          return (
            <StyledChip
              theme={theme}
              error={hasError}
              {...tagProps}
              key={item.id ?? item.valueId}
              label={item.valueId}
              variant="outlined"
              onDelete={event => {
                tagProps.onDelete(event);
                remove(index);
              }}
            />
          );
        });
      }}
      renderInput={params => {
        const textFieldProps: TextFieldProps = {
          ...params,
          color: "secondary",
          placeholder: props.placeholder,
          error: hasError ? true : undefined,
          helperText: errorMessages.join(","),
        };
        if (variant === "OUTLINED") {
          return <OutlinedTextField {...textFieldProps} />;
        }
        return <TextField {...textFieldProps} />;
      }}
    />
  );
};

TagField.displayName = "TagField";

export default TagField;
