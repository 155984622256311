import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Box, { BoxProps } from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs, { TabsProps } from "@mui/material/Tabs";
import React from "react";

export type BasicTabItem = {
  /**
   * A value to identify the tab.
   * Do not use variable values such as i18n values.
   */
  id: string;
  Component: React.ReactNode;
  name: string;
  onClickTab?: () => void;
  className?: string;
};

export type BasicTabProps = {
  wrapper?: BoxProps;
  defaultTab?: string;
  tabs?: Omit<TabsProps, "value">;
  items: BasicTabItem[];
  tabBox?: BoxProps;
  tabPanelRoot?: Pick<BoxProps, "sx">;
  tabPanelBox?: BoxProps;
};

const BasicTab: React.FC<BasicTabProps> = props => {
  const defaultTab = props.defaultTab || (props.items.length ? props.items[0].id : "");
  const theme = useTheme();
  const [currentName, updateCurrentSelectedName] = React.useState(defaultTab);
  React.useEffect(() => {
    if (defaultTab) {
      updateCurrentSelectedName(defaultTab);
    }
  }, [defaultTab]);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    updateCurrentSelectedName(newValue);
    props.tabs && props.tabs.onChange?.(event, newValue);
  };

  const TabPanels = props.items.map(item => {
    return (
      <TabPanel key={item.id} value={item.id} sx={{ p: 0, ...props.tabPanelRoot?.sx }}>
        {item.Component}
      </TabPanel>
    );
  });

  return (
    <TabContext value={currentName}>
      <Box {...props.wrapper}>
        <Box sx={{ flexGrow: 1, bgcolor: theme.palette.background.paper }} {...props.tabBox}>
          <Tabs {...props.tabs} value={currentName} onChange={handleChange}>
            {props.items.map(item => (
              <Tab
                key={item.id}
                className={item.className}
                label={item.name}
                aria-labelledby={item.name}
                value={item.id}
                onClick={item.onClickTab}
              />
            ))}
          </Tabs>
        </Box>
      </Box>
      <Box mt={2} {...props.tabPanelBox}>
        {TabPanels}
      </Box>
    </TabContext>
  );
};

BasicTab.displayName = "BasicTab";

export default BasicTab;
