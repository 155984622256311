import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { QuestionSelectFieldForResourceEditor } from "@hireroo/app-store/widget/e/QuestionSelectFieldForResourceEditor";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { getLanguage, getTranslation } from "@hireroo/i18n";
import * as Sentry from "@sentry/browser";

type Target = "remote" | "spot";

type StartSubscribeSelectedQuestionArgs = {
  /**
   * default spot
   */
  target?: Target;
};

export const startSubscribeSelectedQuestions = (args?: StartSubscribeSelectedQuestionArgs) => {
  const target = args?.target ?? "spot";
  const client = getGraphqlClient();
  const cleanup = QuestionSelectFieldForResourceEditor.subscribeCurrentQuestionSearchFilter((currentSearchFilter, pager) => {
    const { t } = getTranslation();
    const lang = getLanguage();
    const variantMap: Record<Target, QuestionSelectFieldForResourceEditor.QuestionSearchFilter["questionVariants"]> = {
      remote: QuestionSelectFieldForResourceEditor.remoteQuestionVariants,
      spot: QuestionSelectFieldForResourceEditor.questionVariantsWithoutFreepad,
    };
    const questionVariants =
      currentSearchFilter.questionVariants.length === 0 ? variantMap[target] : currentSearchFilter.questionVariants.slice();

    const { sortMethod, isDescending } = QuestionSelectFieldForResourceEditor.SortParams[pager.sortFieldValue];

    QuestionSelectFieldForResourceEditor.updateFetchQuestionsStatus("FETCHING");
    client
      .ListQuestionsForQuestionSelectFieldForResourceEditor({
        questionsInput: {
          filters: {
            difficulties: currentSearchFilter.difficulties.slice(),
            questionVariants: questionVariants,
            statuses: currentSearchFilter.statuses.slice(),
            titleEn: lang === "en" && currentSearchFilter.textFilter ? currentSearchFilter.textFilter : "",
            titleJa: lang === "ja" && currentSearchFilter.textFilter ? currentSearchFilter.textFilter : "",
            skillTagIds: currentSearchFilter.skillTags.map(skillTag => skillTag.value),
            leakScoreLevels: currentSearchFilter.leakScoreLevels.slice(),
          },
          isDescending: isDescending,
          offset: pager.offset,
          size: pager.size,
          sortMethod: sortMethod,
          withCount: true,
        },
      })
      .then(res => {
        QuestionSelectFieldForResourceEditor.setListQuestionsResponse(res.questions);
      })
      .catch(error => {
        Sentry.captureException(error);
        const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("問題を取得できませんでした。"));
        Snackbar.notify({
          severity: "error",
          message: errorNotification.message,
        });
      })
      .finally(() => {
        QuestionSelectFieldForResourceEditor.updateFetchQuestionsStatus("READY");
      });
  });

  QuestionSelectFieldForResourceEditor.forceEmitSubscribeQuestionSearchFilterEvent();

  return cleanup;
};

export const startSubscribeQuestionPackages = () => {
  const client = getGraphqlClient();
  const cleanup = QuestionSelectFieldForResourceEditor.subscribeCurrentQuestionPackageSearchFilter((currentSearchFilter, pager) => {
    const { t } = getTranslation();
    const lang = getLanguage();
    QuestionSelectFieldForResourceEditor.updateFetchQuestionPackagesStatus("FETCHING");
    client
      .ListQuestionsPackagesForQuestionSelectFieldForResourceEditor({
        size: pager.size,
        offset: pager.offset,
        isDescending: true,
        sortMethod: "METHOD_CREATED_AT",
        filters: {
          difficulties: currentSearchFilter.difficulties.slice(),
          questionVariants: [],
          titleEn: lang === "en" && currentSearchFilter.textFilter ? currentSearchFilter.textFilter : "",
          titleJa: lang === "ja" && currentSearchFilter.textFilter ? currentSearchFilter.textFilter : "",
        },
      })
      .then(res => {
        QuestionSelectFieldForResourceEditor.setListQuestionPackagesResponse(res.questionPackages, currentSearchFilter);
      })
      .catch(error => {
        Sentry.captureException(error);
        const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("問題集を取得できませんでした。"));
        Snackbar.notify({
          severity: "error",
          message: errorNotification.message,
        });
      })
      .finally(() => {
        QuestionSelectFieldForResourceEditor.updateFetchQuestionPackagesStatus("READY");
      });
  });

  QuestionSelectFieldForResourceEditor.forceEmitSubscribeQuestionPackagesSearchFilterEvent();

  return cleanup;
};
