import { Widget } from "@hireroo/presentation";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type UserProfileContainerProps = {};

const UserProfileContainer: React.FC<UserProfileContainerProps> = () => {
  const props = useGenerateProps();
  return <Widget.UserProfile {...props} />;
};

UserProfileContainer.displayName = "UserProfileContainer";

export default UserProfileContainer;
