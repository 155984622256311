import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import BaseDialog, { BaseDialogProps } from "../../primitive/BaseDialog/BaseDialog";

const FormControlLabelWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette["Gray/Shades"].p8,
  borderRadius: "4px",
  paddingX: theme.spacing(1.5),
}));

type Item = {
  name: string;
};

export type DeleteDialogProps = {
  dialog: Pick<BaseDialogProps, "open" | "yesButton" | "noButton" | "title">;
  name: string;
  description?: string;
  items?: Item[];
};

const DeleteDialog: React.FC<DeleteDialogProps> = props => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const [checked, setChecked] = React.useState(false);
  const needAgreement = React.useMemo(() => {
    return props.items !== undefined && props.items.length > 0;
  }, [props.items]);

  const baseDialogProps: BaseDialogProps = {
    ...props.dialog,
    title: t2("DeleteTemplate", { templateName: props.name }),
    yesButton: {
      ...props.dialog.yesButton,
      color: "error",
      children: t("削除"),
      onClick: () => {
        props.dialog.yesButton?.onClick?.();
      },
      disabled: needAgreement && (!checked || props.dialog.yesButton?.disabled),
      autoFocus: false,
      kind: "NEGATIVE",
    },
    yesButtonTooltip: needAgreement
      ? {
          title: t("削除するには同意が必要です。"),
        }
      : undefined,
    noButton: {
      ...props.dialog.noButton,
      color: "secondary",
      children: t("キャンセル"),
      kind: "POSITIVE",
    },
    disableEnter: true,
    optionalDialog: {
      disableAutoFocus: true,
      disableRestoreFocus: true,
      PaperProps: {
        elevation: 3,
      },
    },
  };

  const handleChange = (_event: React.SyntheticEvent, checked: boolean) => {
    setChecked(checked);
  };

  return (
    <BaseDialog {...baseDialogProps}>
      {props.description && <Typography fontSize="14px">{props.description}</Typography>}
      {props.items !== undefined && props.items.length > 0 && (
        <>
          <List disablePadding={true} sx={{ pb: 1 }}>
            {props.items.map(item => {
              return (
                <ListItem key={item.name} sx={{ px: 0, py: 0.5, fontSize: 14 }}>
                  ・{item.name}
                </ListItem>
              );
            })}
          </List>
          <FormControlLabelWrapper>
            <FormControlLabel
              sx={{ ml: 0 }}
              control={<Checkbox size="small" />}
              onChange={handleChange}
              label={<Typography fontSize={14}>{t("内容を確認し、削除した後に元に戻せないことに同意する")}</Typography>}
            />
          </FormControlLabelWrapper>
        </>
      )}
    </BaseDialog>
  );
};

DeleteDialog.displayName = "DeleteDialog";

export default DeleteDialog;
