import escapeStringRegexp from "escape-string-regexp";
import { useSnapshot } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = () => {
  const snapshot = useSnapshotState();
  return !!snapshot.company;
};

export const useCompany = (): Types.Company => {
  const snapshot = useSnapshotState();
  if (!snapshot.company) {
    throw new Error("Not initialized company");
  }
  return snapshot.company as Types.Company;
};

export const useTalents = () => {
  const company = useCompany();
  return company.talents;
};

export const useFilteredTalents = () => {
  const snapshot = useSnapshotState();
  const company = useCompany();
  const regex = new RegExp(escapeStringRegexp(snapshot.filterText));
  const textFilter = (talent: Types.Employee): boolean => {
    if (snapshot.filterText === "") {
      return true;
    }
    return regex.test(talent.displayName) || regex.test(talent.email);
  };
  return company.talents.filter(textFilter);
};

export const useCompanyTalentMap = () => {
  const company = useCompany();
  return company.companyTalents.reduce<Record<string, Types.CompanyTalent | undefined>>((all, companyTalent) => {
    return { ...all, [companyTalent.talentId]: companyTalent };
  }, {});
};

export const useTalentMap = (): Record<string, Types.Employee | undefined> => {
  const { company } = useSnapshotState();
  if (!company) {
    return {};
  }
  return company.talents.reduce<Record<string, Types.Employee | undefined>>((all, talent) => {
    return { ...all, [talent.id]: talent };
  }, {});
};

export const useTalentInvitation = () => {
  const snapshot = useSnapshotState();
  const invitationState = snapshot.talentInvitation;
  return {
    id: invitationState.id,
    status: invitationState.status,
  };
};

export const useDeleteMember = () => {
  const snapshot = useSnapshotState();
  const deleteMemberState = snapshot.deleteMember;
  const talentMap = useTalentMap();
  const selectedTalent = deleteMemberState.selectedMemberId ? talentMap[deleteMemberState.selectedMemberId] : undefined;

  return {
    talent: selectedTalent,
  };
};

export const useDeleteMembers = () => {
  const snapshot = useSnapshotState();
  const talentMap = useTalentMap();

  return {
    talents: snapshot.selectedMemberIds
      .map(id => {
        return talentMap[id];
      })
      .flatMap(v => (v ? [v] : [])),
  };
};

export const useSelectedMembers = () => {
  const snapshot = useSnapshotState();
  const talentMap = useTalentMap();

  return {
    talents: snapshot.selectedMemberIds.reduce<Types.Employee[]>((all, id) => {
      const talent = talentMap[id];
      if (talent) {
        return [...all, talent];
      }
      return all;
    }, []),
  };
};

export const useDialogStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.dialogStatus;
};
