import { TargetElementIdMap } from "@hireroo/app-helper/challenge";
import { safeJsonParse } from "@hireroo/app-helper/parser";
import { ChallengeTestReport } from "@hireroo/app-store/view-domain/ChallengeTestReport";
import { formatScore } from "@hireroo/formatter/score";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { Language } from "@hireroo/i18n/utils";
import { Widget } from "@hireroo/presentation";
import { AlgorithmTestCaseForm } from "@hireroo/validator";
import * as React from "react";

const complexLinkMap: Record<Language, string> = {
  ja: "https://ja.wikipedia.org/wiki/%E5%BE%AA%E7%92%B0%E7%9A%84%E8%A4%87%E9%9B%91%E5%BA%A6",
  en: "https://en.wikipedia.org/wiki/Cyclomatic_complexity",
};

type ReadabilitySection = Widget.ChallengeTestReportProps["readabilitySection"];

export type GenerateReadabilitySectionArgs = {
  challengeId: number;
  submissionId: number;
  showScore: boolean;
};

export const useGenerateReadabilitySection = (args: GenerateReadabilitySectionArgs): ReadabilitySection => {
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const challengeHooks = ChallengeTestReport.useCreateChallengeHooks(args.challengeId);

  const submissionMap = challengeHooks.useSubmissionMap();

  const submission = submissionMap[args.submissionId];

  const readabilityTestResult = React.useMemo((): AlgorithmTestCaseForm.ReadabilityTestCaseResultSchema | undefined => {
    const result = AlgorithmTestCaseForm.ReadabilityTestCaseResult.safeParse(safeJsonParse(submission.readabilityTestResult));
    if (result.success) {
      return result.data;
    }
  }, [submission.readabilityTestResult]);

  const xAxisTicks = React.useMemo(() => {
    if (!readabilityTestResult) {
      return [];
    }
    if (readabilityTestResult.cyclomatic_complexity_value <= readabilityTestResult.cyclomatic_complexity_min) {
      return [
        readabilityTestResult.cyclomatic_complexity_value,
        readabilityTestResult.cyclomatic_complexity_min,
        readabilityTestResult.cyclomatic_complexity_max,
      ];
    } else if (readabilityTestResult.cyclomatic_complexity_value < readabilityTestResult.cyclomatic_complexity_max) {
      return [
        readabilityTestResult.cyclomatic_complexity_min,
        readabilityTestResult.cyclomatic_complexity_value,
        readabilityTestResult.cyclomatic_complexity_max,
      ];
    } else {
      return [
        readabilityTestResult.cyclomatic_complexity_min,
        readabilityTestResult.cyclomatic_complexity_max,
        readabilityTestResult.cyclomatic_complexity_value,
      ];
    }
  }, [readabilityTestResult]);

  const data = React.useMemo(() => {
    if (!readabilityTestResult) {
      return [];
    }
    if (readabilityTestResult.cyclomatic_complexity_value <= readabilityTestResult.cyclomatic_complexity_min) {
      return [
        { x: 0, y: 100 },
        { x: readabilityTestResult.cyclomatic_complexity_value, y: 100 },
        { x: readabilityTestResult.cyclomatic_complexity_min, y: 100 },
        { x: readabilityTestResult.cyclomatic_complexity_max, y: 0 },
        { x: readabilityTestResult.cyclomatic_complexity_max + 1, y: 0 },
      ];
    } else if (readabilityTestResult.cyclomatic_complexity_value < readabilityTestResult.cyclomatic_complexity_max) {
      return [
        { x: 0, y: 100 },
        { x: readabilityTestResult.cyclomatic_complexity_min, y: 100 },
        { x: readabilityTestResult.cyclomatic_complexity_value, y: Math.floor(submission.readability * 100) },
        { x: readabilityTestResult.cyclomatic_complexity_max, y: 0 },
        { x: readabilityTestResult.cyclomatic_complexity_max + 1, y: 0 },
      ];
    } else {
      return [
        { x: 0, y: 100 },
        { x: readabilityTestResult.cyclomatic_complexity_min, y: 100 },
        { x: readabilityTestResult.cyclomatic_complexity_max, y: 0 },
        { x: readabilityTestResult.cyclomatic_complexity_value, y: 0 },
        { x: readabilityTestResult.cyclomatic_complexity_value + 1, y: 0 },
      ];
    }
  }, [readabilityTestResult, submission.readability]);

  const readabilityPer = formatScore(submission.readability);

  return {
    score: args.showScore ? formatScore(submission?.readability ?? 0) : undefined,
    link: complexLinkMap[lang],
    graph: {
      xAxisTicks: xAxisTicks,
      xAxis: {
        label: t("循環的複雑度"),
      },
      yAxis: {
        label: t("可読性"),
      },
      readabilityPer: readabilityPer,
      cyclomatic_complexity_value: readabilityTestResult?.cyclomatic_complexity_value ?? 0,
      referenceLine: {
        label: t("候補者"),
      },
      data: data,
    },
    targetElementId: TargetElementIdMap.READABILITY_SECTION,
  };
};
