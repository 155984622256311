import type * as Interface from "@hireroo/evaluation-result-interface";
import { useLanguageCode } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import Icon, { IconProps } from "../../subblocks/Icon/Icon";

export type HeaderProps = Interface.Header["value"];

const Header: React.FC<HeaderProps> = props => {
  const lang = useLanguageCode();
  const icon: IconProps | undefined = props.startIcon
    ? {
        kind: props.startIcon,
      }
    : undefined;
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      {icon && <Icon {...icon} />}
      <Typography fontSize={20} fontWeight={700}>
        {resolveLanguage(props.text, lang, "translateText")}
      </Typography>
    </Stack>
  );
};

Header.displayName = "Header";

export default Header;
