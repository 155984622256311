const HELP_CENTER_URL = "https://support.hireroo.io";

export type HelpCenterKey =
  | "ROOT"
  | "INVITE_MEMBERS"
  | "INVITE_TALENTS"
  | "MEMBER_AUTHORIZATION"
  | "DRAWING_TOOL"
  | "SSO"
  | "CHECK_REPORT"
  | "EXPORT_CSV"
  | "ATS_SONAR"
  | "PROJECT_EVALUATION_ERROR"
  | "SSH_KEY"
  | "SCREENING_CREATE"
  | "CUSTOM_SCORE"
  | "TOS_FOR_EMPLOYEE"
  | "TOS_FOR_CANDIDATE"
  | "CANDIDATE_HELP_PAGE"
  | "ASSESSMENT_CREATE_PAGE"
  | "ASSESSMENT_EMPLOYEE_LIST_PAGE"
  | "ASSESSMENT_EMPLOYEE_DETAIL_PAGE"
  | "ASSESSMENT_TALENT_LIST_PAGE"
  | "EVALUATION_METRICS"
  | "PRIVACY_POLICY"
  | "REMOTE_CREATE_PAGE"
  | "RESUME_REPORT_PAGE";

export type UrlLanguageMap = {
  ja: string;
  en: string;
  [key: string]: string;
};

const helpCenterUrlMap: Record<HelpCenterKey, UrlLanguageMap> = {
  ROOT: {
    ja: HELP_CENTER_URL,
    en: `${HELP_CENTER_URL}/en`,
  },
  INVITE_MEMBERS: {
    ja: `${HELP_CENTER_URL}/recruiters/invite-members`,
    en: `${HELP_CENTER_URL}/en/recruiters/invite-members`,
  },
  INVITE_TALENTS: {
    ja: `${HELP_CENTER_URL}/first-time-login-and-inviting-other-users`,
    en: `${HELP_CENTER_URL}/en`,
  },
  MEMBER_AUTHORIZATION: {
    ja: `${HELP_CENTER_URL}/member-authorization`,
    en: `${HELP_CENTER_URL}/en/what-is-the-access-control-function`,
  },
  DRAWING_TOOL: {
    ja: `${HELP_CENTER_URL}/candidates/drawing-tool`,
    en: `${HELP_CENTER_URL}/en/candidates/drawing-tool`,
  },
  SSO: {
    ja: `${HELP_CENTER_URL}/recruiters/sso`,
    en: `${HELP_CENTER_URL}/en/recruiters/sso`,
  },
  CHECK_REPORT: {
    ja: `${HELP_CENTER_URL}/recruiters/check-interview`,
    en: `${HELP_CENTER_URL}/en/recruiters/check-interview`,
  },
  EXPORT_CSV: {
    ja: `${HELP_CENTER_URL}/recruiters/about-csv-export`,
    en: `${HELP_CENTER_URL}/en/recruiters/about-csv-export`,
  },
  ATS_SONAR: {
    ja: `${HELP_CENTER_URL}/recruiters/about-sonar-ats`,
    en: `${HELP_CENTER_URL}/en/recruiters/about-sonar-ats`,
  },
  PROJECT_EVALUATION_ERROR: {
    ja: `${HELP_CENTER_URL}/recruiters/errortype-of-projectreport`,
    en: `${HELP_CENTER_URL}/en/recruiters/errortype-of-projectreport`,
  },
  SSH_KEY: {
    ja: `${HELP_CENTER_URL}/candidates/ssh-key`,
    en: `${HELP_CENTER_URL}/en/candidates/ssh-key`,
  },
  TOS_FOR_EMPLOYEE: {
    ja: `${HELP_CENTER_URL}/recruiters/terms-of-use`,
    en: `${HELP_CENTER_URL}/en/recruiters/terms-of-use`,
  },
  TOS_FOR_CANDIDATE: {
    ja: `${HELP_CENTER_URL}/candidates/terms-of-use`,
    en: `${HELP_CENTER_URL}/en/candidates/terms-of-use`,
  },
  CANDIDATE_HELP_PAGE: {
    ja: `https://support.hireroo.io/受験者向けヘルプ`,
    en: `https://support.hireroo.io/en/candidate-help-page`,
  },
  ASSESSMENT_CREATE_PAGE: {
    ja: `${HELP_CENTER_URL}/creating-and-editing-talentscores`,
    en: `${HELP_CENTER_URL}/en/creating-and-editing-talentscores`,
  },
  ASSESSMENT_EMPLOYEE_LIST_PAGE: {
    ja: `${HELP_CENTER_URL}/talentscore-overview`,
    en: `${HELP_CENTER_URL}/en`,
  },
  ASSESSMENT_TALENT_LIST_PAGE: {
    ja: `${HELP_CENTER_URL}/how-to-use-for-talent`,
    en: `${HELP_CENTER_URL}/en`,
  },
  ASSESSMENT_EMPLOYEE_DETAIL_PAGE: {
    ja: `${HELP_CENTER_URL}/talentscore-detail-and-technical-review `,
    en: `${HELP_CENTER_URL}/en`,
  },
  EVALUATION_METRICS: {
    ja: `${HELP_CENTER_URL}/recruiters/setting-evaluation-criteria`,
    en: `${HELP_CENTER_URL}/en/recruiters/en/recruiters/setting-evaluation-criteria`,
  },
  PRIVACY_POLICY: {
    ja: `https://hireroo.io/privacy`,
    //TODO: please add english privacy policy url when it is ready
    en: `https://hireroo.io/privacy`,
  },
  SCREENING_CREATE: {
    ja: `${HELP_CENTER_URL}/recruiters/creating-candidate-selection-test`,
    en: `${HELP_CENTER_URL}/en/recruiters/creating-candidate-selection-test`,
  },
  REMOTE_CREATE_PAGE: {
    ja: `${HELP_CENTER_URL}/recruiters/create-livecoding`,
    en: `${HELP_CENTER_URL}/en/create-live-coding`,
  },
  CUSTOM_SCORE: {
    ja: `${HELP_CENTER_URL}/custom-score`,
    en: `${HELP_CENTER_URL}/en/custom-score`,
  },
  RESUME_REPORT_PAGE: {
    // TODO: please add the correct url when it is ready
    ja: `${HELP_CENTER_URL}/`,
    en: `${HELP_CENTER_URL}/en/`,
  },
} as const;

export default helpCenterUrlMap;
