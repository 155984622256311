import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { styled, useTheme } from "@mui/material/styles";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { FONT_FAMILY_SET } from "../../../constants/font";
import {
  AdjustPosition,
  CommentElement,
  Coordinate,
  EdgeElement,
  ELEMENT_TYPE,
  ElementLabel,
  ElementType,
  FlowElement,
  FontSize,
  FontSizeMap,
  LabelTypeMap,
  NetworkElement,
  OPERATION_TYPE,
  OperationType,
  Settings,
  UserState,
} from "../../../features";
import {
  adjustorFromPosition,
  generateHashId,
  getInitialSettings,
  getInitialWidthAndHeight,
  isWindows,
  svgTransform,
} from "../../../helpers/flowChart";
import { timeInSeconds } from "../../../helpers/time";
import { useSystemDesignContext } from "../../../store";
import { Cursor } from "../../modules/Cursor/Cursor";
import { DynamicComment } from "../../modules/Dynamic/DynamicComment/DynamicComment";
import { DynamicEdge } from "../../modules/Dynamic/DynamicEdge/DynamicEdge";
import { DynamicNetwork } from "../../modules/Dynamic/DynamicNetwork/DynamicNetwork";
import { DynamicNode } from "../../modules/Dynamic/DynamicNode/DynamicNode";
import { Username } from "../../modules/Username/Username";

export type DrawingAreaProps = {
  snapshot: string;
  fontSize: FontSize;
  outerWidth: number;
  outerHeight: number;
  onDragOver: (e: React.DragEvent) => void;
  onDrop: (e: React.DragEvent) => void;
  isPlacingElement: boolean;
  placingElementLabel: ElementLabel | "text" | undefined;
  addElement: (
    id: string,
    type: ElementType,
    label: ElementLabel,
    x: number,
    y: number,
    w: number,
    h: number,
    initialSettings: Settings,
    operationType: OperationType,
  ) => void;
  addComment: (id: string, content: string, x: number, y: number, fontSize: number, operationType: OperationType) => void;
  editComment: (id: string, content: string, operationType: OperationType) => void;
  deleteElements: (ids: string[], operationType: OperationType) => void;
  moveElements: (ids: string[], dx: number, dy: number, operationType: OperationType) => void;
  shapeElement: (id: string, position: AdjustPosition, dx: number, dy: number, operationType: OperationType) => void;
  connectNodes: (id: string, source: string, target: string, operationType: OperationType) => void;
  reconnectEdge: (id: string, source: string, target: string, operationType: OperationType) => void;
  pasteElements: (destElements: FlowElement[], operationType: OperationType) => void;
  redo: () => void;
  undo: () => void;
  collaborators: UserState[];
  selectElement: (ids: string[]) => void;
  saveEditingCommentId: (id: string | null) => void;
  moveCursor: (x: number, y: number) => void;
};

const StyledTextareaAutosize = styled(TextareaAutosize)(({ theme }) => {
  return {
    border: 0,
    outline: "none",
    backgroundColor: "white",
    color: theme.palette.common.black,
    caretColor: theme.palette.common.black,
    "&:hover": {
      outline: "none",
    },
    whiteSpace: "pre",
    overflowWrap: "normal",
    overflow: "hidden",
    fontFamily: FONT_FAMILY_SET.join(","),
  };
});

const StyledSvg = styled("svg")(({ theme }) => {
  return {
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.grey[50],
    "&:focus": {
      outline: "none",
    },
  };
});

const DrawingArea: React.FC<DrawingAreaProps> = React.memo((props: DrawingAreaProps) => {
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const theme = useTheme();
  const svgRef = useRef<SVGSVGElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const Store = useSystemDesignContext();
  const elementIds = Store.hooks.useElementIds();
  const networkIds = Store.hooks.useNetworkIds();
  const selectedElementIds = Store.hooks.useSelectedElementIds();
  const copiedElementIds = Store.hooks.useCopiedElementIds();
  const selectedElementId = Store.hooks.useSelectedElementId();
  const elementFactory = Store.hooks.useElement();
  const viewbox = Store.hooks.useViewbox();
  const minimumAreaFactory = Store.hooks.useMinimumArea();
  const minimumSelectedArea = useMemo(() => {
    return minimumAreaFactory([...selectedElementIds]);
  }, [minimumAreaFactory, selectedElementIds]);
  const checkIsDoubleEdge = Store.hooks.useIsDoubleEdge();
  const pasteDestElementsFactory = Store.hooks.usePasteDestElements();
  const scale = Store.hooks.useScale();
  const componentType = Store.hooks.useComponentType();

  // Placing element related states
  const [placement, setPlacement] = useState<Coordinate | undefined>(undefined);
  const [viewboxIsCached, setViewareaIsCached] = useState<boolean>(false);

  // Moving element related states
  const [gap, setGap] = useState<Coordinate>({ x: 0, y: 0 });

  // Shaping network related states
  const [isShapingNetwork, setIsShapingNetwork] = useState<boolean>(false);
  const [adjustPosition, setAdjustPosition] = useState<AdjustPosition | undefined>(undefined);

  // Connecting nodes related states
  const [isConnectingNode, setIsConnectingNode] = useState<boolean>(false);
  const [edgeStart, setEdgeStart] = useState<Coordinate | undefined>(undefined);
  const [edgeEnd, setEdgeEnd] = useState<Coordinate | undefined>(undefined);
  const [sourceNodeId, setSourceNodeId] = useState<string | undefined>(undefined);

  // Reconnecting edge related states
  const [isReconnectingSource, setIsReconnectingSource] = useState<boolean>(false);
  const [isReconnectingTarget, setIsReconnectingTarget] = useState<boolean>(false);
  const [reconnectingEdgeId, setReconnectingEdgeId] = useState<string | undefined>(undefined);
  const [prevNodeId, setPrevNodeId] = useState<string | undefined>(undefined);
  const pivotNodeId = useMemo(() => {
    if (reconnectingEdgeId) {
      const edge = elementFactory(reconnectingEdgeId) as EdgeElement;
      if (isReconnectingSource) return edge.target;
      if (isReconnectingTarget) return edge.source;
    }
  }, [elementFactory, isReconnectingSource, isReconnectingTarget, reconnectingEdgeId]);

  // Adding comment related states
  const [textareaPosition, setTextareaPosition] = useState<Coordinate | undefined>(undefined);
  const [text, setText] = useState<string | undefined>(undefined);
  const [textareaFontSize, setTextareaFontSize] = useState<number>(FontSizeMap["medium"]);
  const [textareaHeight, setTextareaHeight] = useState<number>(textareaFontSize);
  const [textareaWidth, setTextareaWidth] = useState<number>(500);

  // Editing comment related states
  const [isEditingComment, setIsEditingComment] = useState<boolean>(false);
  const [editingCommentId, setEditingCommentId] = useState<string | undefined>(undefined);

  // Area selection related states
  const [isSelectingArea, setIsSelectingArea] = useState<boolean>(false);
  const [areaStart, setAreaStart] = useState<Coordinate | undefined>(undefined);
  const [areaEnd, setAreaEnd] = useState<Coordinate | undefined>(undefined);

  // Moving elements related states
  const [isMovingElements, setIsMovingElements] = useState<boolean>(false);

  // Save the move start position to chunk the distance and save it as one history
  const [moveStart, setMoveStart] = useState<Coordinate | undefined>(undefined);
  const [shapeStart, setShapeStart] = useState<Coordinate | undefined>(undefined);

  // Safari pinch zoom related states
  const [pinchFocus, setPinchFocus] = useState<Coordinate | undefined>(undefined);
  const [pinchStartScale, setPinchStartScale] = useState<number | undefined>(undefined);

  // Mouse pan related states
  const [isPanning, setIsPanning] = useState<boolean>(false);

  // Drag-leave event is fired when hovering a child element, hence detect whether leave or not using counter
  // https://stackoverflow.com/questions/7110353/html5-dragleave-fired-when-hovering-a-child-element
  const [dragLeaveCounter, setDragLeaveCounter] = useState<number>(0);

  const saveText = useCallback(() => {
    if (isEditingComment && textareaPosition && editingCommentId) {
      if (text) {
        props.editComment(editingCommentId, text, OPERATION_TYPE.do);
      } else {
        props.deleteElements([editingCommentId], OPERATION_TYPE.do);
      }

      setIsEditingComment(false);
      setEditingCommentId(undefined);
      setTextareaPosition(undefined);
      setText(undefined);
      setTextareaHeight(textareaFontSize);
      setTextareaWidth(500);
    }
  }, [editingCommentId, isEditingComment, props, text, textareaFontSize, textareaPosition]);

  // Event handles on the top svg element
  const handleMouseDown = useCallback(
    (e: React.MouseEvent<SVGElement, MouseEvent>) => {
      saveText();
      if (!svgRef.current) return;

      // Prevent selecting texts
      if (e.detail === 2) {
        e.preventDefault();
      }

      const mousePoint = svgTransform(svgRef.current, { x: e.clientX, y: e.clientY });
      if (!mousePoint) return;

      if (e.button === 1) {
        return setIsPanning(true);
      }

      Store.action.unselectElements();
      setIsSelectingArea(true);
      setAreaStart(mousePoint);
    },
    [Store.action, saveText],
  );

  const handleMouseMove = useCallback(
    (e: React.MouseEvent<SVGElement, MouseEvent>) => {
      if (!svgRef.current) return;

      const mousePoint = svgTransform(svgRef.current, { x: e.clientX, y: e.clientY });
      if (!mousePoint) return;

      const rawX = mousePoint.x - gap.x;
      const rawY = mousePoint.y - gap.y;
      props.moveCursor(rawX, rawY);

      const snappedX = rawX - (rawX % 20);
      const snappedY = rawY - (rawY % 20);

      if (snappedX === 0 || snappedY === 0) return;

      if (isPanning) {
        Store.action.panViewbox({ dx: -e.movementX, dy: -e.movementY });
      }

      if (isConnectingNode || isReconnectingSource || isReconnectingTarget) {
        setEdgeEnd(mousePoint);
      }

      if (isShapingNetwork && selectedElementId && adjustPosition) {
        const network = elementFactory(selectedElementId) as NetworkElement;
        const adjustor = adjustorFromPosition(network, adjustPosition);
        const dx = snappedX - adjustor.x;
        const dy = snappedY - adjustor.y;
        Store.action.shapeElement({ id: selectedElementId, dx, dy, adjustPosition });
      }

      if (isSelectingArea && areaStart) {
        setAreaEnd(mousePoint);
        Store.action.selectArea({
          // Swap areaStart and mousePoint if necessary so that selected area is visible when dragging in any direction
          minX: Math.min(areaStart.x, mousePoint.x),
          minY: Math.min(areaStart.y, mousePoint.y),
          maxX: Math.max(areaStart.x, mousePoint.x),
          maxY: Math.max(areaStart.y, mousePoint.y),
        });
      }

      if (isMovingElements && selectedElementIds.length > 0) {
        const dx = snappedX - minimumSelectedArea.minX;
        const dy = snappedY - minimumSelectedArea.minY;
        Store.action.moveElements({ ids: [...selectedElementIds], dx, dy });
      }
    },
    [
      gap.x,
      gap.y,
      props,
      isPanning,
      isConnectingNode,
      isReconnectingSource,
      isReconnectingTarget,
      isShapingNetwork,
      selectedElementId,
      adjustPosition,
      isSelectingArea,
      areaStart,
      isMovingElements,
      selectedElementIds,
      Store.action,
      elementFactory,
      minimumSelectedArea.minX,
      minimumSelectedArea.minY,
    ],
  );

  const handleMouseUp = useCallback(
    (e: React.MouseEvent<SVGElement, MouseEvent>) => {
      if (!svgRef.current) return;

      const mousePoint = svgTransform(svgRef.current, { x: e.clientX, y: e.clientY });
      if (!mousePoint) return;

      if (isPanning) {
        setIsPanning(false);
      }

      if (isConnectingNode) {
        setIsConnectingNode(false);
        setSourceNodeId(undefined);
        setEdgeStart(undefined);
        setEdgeEnd(undefined);
      }

      if (isSelectingArea) {
        setIsSelectingArea(false);
        setAreaStart(undefined);
        setAreaEnd(undefined);
      }

      if (isMovingElements && moveStart && selectedElementIds.length > 0) {
        const dx = minimumSelectedArea.minX - moveStart.x;
        const dy = minimumSelectedArea.minY - moveStart.y;
        if (dx !== 0 || dy !== 0) {
          props.moveElements([...selectedElementIds], dx, dy, OPERATION_TYPE.do);
        }
      }

      if (isShapingNetwork && selectedElementId && adjustPosition && shapeStart) {
        const element = elementFactory(selectedElementId);
        if (element?.type !== ELEMENT_TYPE.network) return;

        const adjustor = adjustorFromPosition(element, adjustPosition);
        const dx = adjustor.x - shapeStart.x;
        const dy = adjustor.y - shapeStart.y;
        if (dx !== 0 || dy !== 0) {
          props.shapeElement(selectedElementId, adjustPosition, dx, dy, OPERATION_TYPE.do);
        }
      }

      setIsMovingElements(false);
      setIsShapingNetwork(false);
      setGap({ x: 0, y: 0 });
      setMoveStart(undefined);
      setShapeStart(undefined);
    },
    [
      isPanning,
      isConnectingNode,
      isSelectingArea,
      isMovingElements,
      moveStart,
      selectedElementIds,
      isShapingNetwork,
      selectedElementId,
      adjustPosition,
      shapeStart,
      minimumSelectedArea.minX,
      minimumSelectedArea.minY,
      props,
      elementFactory,
    ],
  );

  // ref: https://kenneth.io/post/detecting-multi-touch-trackpad-gestures-in-javascript
  const handlePanAndZoom = useCallback(
    (e: React.WheelEvent<SVGSVGElement>) => {
      if (!svgRef.current) return;

      const mousePoint = svgTransform(svgRef.current, { x: e.clientX, y: e.clientY });
      if (!mousePoint) return;

      // mouse: cmd+wheel or ctr+wheel
      // trackpad: pinch in and out
      if (e.ctrlKey || e.metaKey) {
        const newScale = e.deltaY < 0 ? (Math.round(scale * 10) + 1) / 10 : (Math.round(scale * 10) - 1) / 10;
        Store.action.zoomViewbox({ scale: newScale, focus: mousePoint });
      } else {
        // mouse: normal wheel
        // trackpad: move with two fingers touched
        Store.action.panViewbox({ dx: e.deltaX, dy: e.deltaY });
      }
    },
    [Store.action, scale],
  );

  const handleStartPinchZoom = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any) => {
      e.preventDefault();

      if (!svgRef.current) return;
      const mousePoint = svgTransform(svgRef.current, { x: e.clientX, y: e.clientY });
      if (!mousePoint) return;

      setPinchStartScale(scale);
      setPinchFocus(mousePoint);
    },
    [scale],
  );

  const handlePinchZoom = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any) => {
      e.preventDefault();

      if (!pinchFocus || !pinchStartScale) return;

      const newScale = pinchStartScale + (e.scale - 1);
      Store.action.zoomViewbox({ scale: Math.round(newScale * 10) / 10, focus: pinchFocus });
    },
    [Store.action, pinchFocus, pinchStartScale],
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleEndPinchZoom = useCallback((e: any) => {
    e.preventDefault();

    setPinchFocus(undefined);
    setPinchStartScale(undefined);
  }, []);

  const handleDragElement = useCallback(
    (e: React.DragEvent) => {
      props.onDragOver(e);

      if (!svgRef.current) return;
      const mousePoint = svgTransform(svgRef.current, { x: e.clientX, y: e.clientY });
      if (!mousePoint) return;

      const rawX = mousePoint.x - gap.x;
      const rawY = mousePoint.y - gap.y;
      const x = rawX - (rawX % 20);
      const y = rawY - (rawY % 20);

      setPlacement({ x, y });
      setDragLeaveCounter(0);
    },
    [gap.x, gap.y, props],
  );

  const handleDropElement = useCallback(
    (e: React.DragEvent) => {
      if (!props.onDrop) return;
      props.onDrop(e);

      if (!placement) return;

      const label = e.dataTransfer.getData("application/flowchart");
      if (!label) {
        setPlacement(undefined);
        return;
      }

      if (label === "text") {
        props.addComment(
          generateHashId(),
          t("ダブルクリックでテキストを編集できます。"),
          placement.x,
          placement.y,
          FontSizeMap[props.fontSize],
          OPERATION_TYPE.do,
        );
      } else {
        const { w, h } = getInitialWidthAndHeight(label as ElementLabel);

        props.addElement(
          generateHashId(),
          LabelTypeMap[label as ElementLabel],
          label as ElementLabel,
          placement.x,
          placement.y,
          w,
          h,
          getInitialSettings(label as ElementLabel, componentType, lang),
          OPERATION_TYPE.do,
        );
      }
      setPlacement(undefined);
    },
    [componentType, lang, placement, props, t],
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<SVGSVGElement>) => {
      if (isEditingComment) return;

      if ((e.key === "Delete" || e.key === "Backspace") && selectedElementIds.length > 0) {
        Store.action.unselectElements();
        props.deleteElements([...selectedElementIds], OPERATION_TYPE.do);
      }

      const ctrlOrCmdKey = isWindows ? e.ctrlKey : e.metaKey;

      if (ctrlOrCmdKey && e.key === "a") {
        // Prevent selecting texts
        e.preventDefault();
        Store.action.selectAll();
      }

      if (ctrlOrCmdKey && e.key === "c") {
        Store.action.copyElements();
      }

      if (ctrlOrCmdKey && e.key === "v" && copiedElementIds.length > 0) {
        const destIds = copiedElementIds.map(() => generateHashId());
        const destElements = pasteDestElementsFactory([...copiedElementIds], destIds, timeInSeconds());
        props.pasteElements(destElements, OPERATION_TYPE.do);
      }

      if (ctrlOrCmdKey && e.key === "x" && selectedElementIds.length > 0) {
        Store.action.copyElements();
        props.deleteElements([...selectedElementIds], OPERATION_TYPE.do);
      }

      if (ctrlOrCmdKey && e.key === "z") {
        // Prevent redo and undo of an input field
        e.preventDefault();
        if (e.shiftKey) {
          props.redo();
        } else {
          props.undo();
        }
      }

      if (selectedElementIds.length > 0) {
        if (e.key === "ArrowLeft") {
          const dx = e.shiftKey ? -80 : -20;
          Store.action.moveElements({ ids: [...selectedElementIds], dx, dy: 0 });
          props.moveElements([...selectedElementIds], dx, 0, OPERATION_TYPE.do);
        }

        if (e.key === "ArrowUp") {
          const dy = e.shiftKey ? -80 : -20;
          Store.action.moveElements({ ids: [...selectedElementIds], dx: 0, dy });
          props.moveElements([...selectedElementIds], 0, dy, OPERATION_TYPE.do);
        }

        if (e.key === "ArrowRight") {
          const dx = e.shiftKey ? 80 : 20;
          Store.action.moveElements({ ids: [...selectedElementIds], dx, dy: 0 });
          props.moveElements([...selectedElementIds], dx, 0, OPERATION_TYPE.do);
        }

        if (e.key === "ArrowDown") {
          const dy = e.shiftKey ? 80 : 20;
          Store.action.moveElements({ ids: [...selectedElementIds], dx: 0, dy });
          props.moveElements([...selectedElementIds], 0, dy, OPERATION_TYPE.do);
        }
      }
    },
    [isEditingComment, selectedElementIds, copiedElementIds, Store.action, props, pasteDestElementsFactory],
  );

  // Event handlers on nodes
  const handleSelectNode = useCallback(
    (e: React.MouseEvent<SVGElement, MouseEvent>, nodeId: string) => {
      if (e.button === 1) return;
      e.stopPropagation();
      saveText();

      if (isReconnectingSource && reconnectingEdgeId && pivotNodeId && prevNodeId) {
        const isConnectingOwn = nodeId === pivotNodeId;
        const isConnectingAsBefore = nodeId === prevNodeId;
        const isDoubleEdge = checkIsDoubleEdge(nodeId, pivotNodeId);
        if (isConnectingOwn || (isDoubleEdge && !isConnectingAsBefore)) return;

        props.reconnectEdge(reconnectingEdgeId, nodeId, pivotNodeId, OPERATION_TYPE.do);
        Store.action.unselectElements();
        setIsReconnectingSource(false);
        setPrevNodeId(undefined);
        setReconnectingEdgeId(undefined);
        setEdgeEnd(undefined);
        return;
      }

      if (isReconnectingTarget && reconnectingEdgeId && pivotNodeId && prevNodeId) {
        const isConnectingOwn = nodeId === pivotNodeId;
        const isConnectingAsBefore = nodeId === prevNodeId;
        const isDoubleEdge = checkIsDoubleEdge(nodeId, pivotNodeId);
        if (isConnectingOwn || (isDoubleEdge && !isConnectingAsBefore)) return;

        props.reconnectEdge(reconnectingEdgeId, pivotNodeId, nodeId, OPERATION_TYPE.do);
        Store.action.unselectElements();
        setIsReconnectingTarget(false);
        setPrevNodeId(undefined);
        setReconnectingEdgeId(undefined);
        setEdgeEnd(undefined);
        return;
      }

      if (selectedElementIds.length > 0 && e.shiftKey) {
        Store.action.selectMore({ id: nodeId });
      } else {
        if (!svgRef.current) return;

        const mousePoint = svgTransform(svgRef.current, { x: e.clientX, y: e.clientY });
        if (!mousePoint) return;

        const element = elementFactory(nodeId);
        if (element?.type !== ELEMENT_TYPE.node) return;

        setIsMovingElements(true);
        setMoveStart({ x: element.geometry.minX, y: element.geometry.minY });
        setGap({ x: mousePoint.x - element.geometry.minX, y: mousePoint.y - element.geometry.minY });
        Store.action.selectElements({ ids: [nodeId] });
      }
    },
    [
      Store.action,
      checkIsDoubleEdge,
      elementFactory,
      isReconnectingSource,
      isReconnectingTarget,
      pivotNodeId,
      prevNodeId,
      props,
      reconnectingEdgeId,
      saveText,
      selectedElementIds.length,
    ],
  );

  // Event handlers on networks
  const handleSelectNetwork = useCallback(
    (e: React.MouseEvent<SVGElement, MouseEvent>, networkId: string) => {
      if (e.button === 1) return;
      e.stopPropagation();

      saveText();
      if (!svgRef.current) return;

      const mousePoint = svgTransform(svgRef.current, { x: e.clientX, y: e.clientY });
      if (!mousePoint) return;

      const element = elementFactory(networkId) as NetworkElement;

      if (selectedElementIds.length > 0 && e.shiftKey) {
        Store.action.selectMore({ id: networkId });
      } else {
        setIsMovingElements(true);
        setMoveStart({ x: element.geometry.minX, y: element.geometry.minY });
        setGap({ x: mousePoint.x - element.geometry.minX, y: mousePoint.y - element.geometry.minY });
        Store.action.selectElements({ ids: [networkId] });
      }
    },
    [Store.action, elementFactory, saveText, selectedElementIds.length],
  );

  // Event handlers on edges
  const handleSelectEdge = useCallback(
    (e: React.MouseEvent<SVGElement, MouseEvent>, edgeId: string) => {
      if (e.button === 1) return;
      e.stopPropagation();
      saveText();

      if (selectedElementIds.length > 0 && e.shiftKey) {
        Store.action.selectMore({ id: edgeId });
      } else {
        Store.action.selectElements({ ids: [edgeId] });
      }
    },
    [Store.action, saveText, selectedElementIds.length],
  );

  // Event handlers on node adaptors
  const handleStartConnectNodes = useCallback((e: React.MouseEvent<SVGElement, MouseEvent>, nodeId: string) => {
    e.preventDefault();
    e.stopPropagation();
    setIsConnectingNode(true);
    setSourceNodeId(nodeId);

    if (!svgRef.current) return;
    const circleRect = e.currentTarget.getBoundingClientRect();
    const point = svgTransform(svgRef.current, { x: circleRect.left + circleRect.width / 2, y: circleRect.top + circleRect.height / 2 });
    if (!point) return;
    setEdgeStart(point);
  }, []);

  const handleEndConnectNodes = useCallback(
    (e: React.MouseEvent<SVGElement, MouseEvent>, nodeId: string) => {
      if (isConnectingNode && sourceNodeId) {
        // A mouse-up event of the parent element should be fired when moving element or shaping network,
        // hence stop the event propagation only inside this if statement
        e.stopPropagation();

        const isConnectingOwn = nodeId === sourceNodeId;
        const isDoubleEdge = checkIsDoubleEdge(nodeId, sourceNodeId);

        if (!isConnectingOwn && !isDoubleEdge) {
          props.connectNodes(generateHashId(), sourceNodeId, nodeId, OPERATION_TYPE.do);
        }

        setIsConnectingNode(false);
        setSourceNodeId(undefined);
        setEdgeStart(undefined);
        setEdgeEnd(undefined);
      }
    },
    [checkIsDoubleEdge, isConnectingNode, props, sourceNodeId],
  );

  // Event handlers on edge adaptors
  const handleStartReconnectNodes = useCallback(
    (e: React.MouseEvent<SVGElement, MouseEvent>, edgeId: string, isTarget: boolean) => {
      e.preventDefault();
      e.stopPropagation();

      setReconnectingEdgeId(edgeId);
      const edge = elementFactory(edgeId) as EdgeElement;

      if (isTarget) {
        setIsReconnectingTarget(true);
        setPrevNodeId(edge.target);
      } else {
        setIsReconnectingSource(true);
        setPrevNodeId(edge.source);
      }

      if (!svgRef.current) return;
      const circleRect = e.currentTarget.getBoundingClientRect();
      const point = svgTransform(svgRef.current, { x: circleRect.left + circleRect.width / 2, y: circleRect.top + circleRect.height / 2 });
      if (!point) return;
      setEdgeEnd(point);
    },
    [elementFactory],
  );

  // Event handlers on network adjuster
  const handleStartShapeNetwork = useCallback(
    (e: React.MouseEvent<SVGElement, MouseEvent>, networkId: string, position: AdjustPosition) => {
      e.stopPropagation();

      setIsShapingNetwork(true);
      setAdjustPosition(position);

      const element = elementFactory(networkId);
      if (element?.type !== ELEMENT_TYPE.network) return;
      setShapeStart(adjustorFromPosition(element, position));
    },
    [elementFactory],
  );

  // Event handlers on comments
  const handleSelectComment = useCallback(
    (e: React.MouseEvent<SVGElement, MouseEvent>, commentId: string) => {
      if (e.button === 1) return;
      e.stopPropagation();
      saveText();
      if (!svgRef.current) return;

      const mousePoint = svgTransform(svgRef.current, { x: e.clientX, y: e.clientY });
      if (!mousePoint) return;

      const element = elementFactory(commentId) as CommentElement;

      if (e.detail === 1) {
        if (selectedElementIds.length > 0 && e.shiftKey) {
          Store.action.selectMore({ id: commentId });
        } else {
          setIsMovingElements(true);
          setMoveStart({ x: element.geometry.minX, y: element.geometry.minY });
          setGap({ x: mousePoint.x - element.geometry.minX, y: mousePoint.y - element.geometry.minY });
          Store.action.selectElements({ ids: [commentId] });
        }
      }

      if (e.detail === 2) {
        const comment = elementFactory(commentId) as CommentElement;
        setIsEditingComment(true);
        setEditingCommentId(commentId);
        setTextareaPosition({ x: element.geometry.minX, y: element.geometry.minY + comment.settings.fontSize });
        setTextareaFontSize(comment.settings.fontSize);
        setText(comment.content);
        Store.action.unselectElements();
      }
    },
    [Store.action, elementFactory, saveText, selectedElementIds.length],
  );

  // Event handlers on textareas
  const handleChangeText = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    setText(e.target.value);
  }, []);

  // Event handlers on select areas
  const handleClickSelectedArea = useCallback(
    (e: React.MouseEvent<SVGElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      if (!svgRef.current) return;

      const mousePoint = svgTransform(svgRef.current, { x: e.clientX, y: e.clientY });
      if (!mousePoint) return;

      setIsMovingElements(true);
      setMoveStart({ x: minimumSelectedArea.minX, y: minimumSelectedArea.minY });
      setGap({ x: mousePoint.x - minimumSelectedArea.minX, y: mousePoint.y - minimumSelectedArea.minY });
    },
    [minimumSelectedArea.minX, minimumSelectedArea.minY],
  );

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textareaPosition && textarea) {
      // Resize textarea width and height depending on the text size
      if (text) {
        setTextareaHeight(textarea.scrollHeight);
        setTextareaWidth(textarea.scrollWidth);
      }

      // Use setTimeout since DOM operation not working. This is maybe Chrome bug
      // ref: https://stackoverflow.com/questions/17384464/jquery-focus-not-working-in-chrome
      setTimeout(() => {
        textarea.focus();
      }, 0);
    }
  }, [text, textareaPosition]);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textareaPosition && textarea) {
      if (isEditingComment) {
        setTimeout(() => {
          textarea.setSelectionRange(textarea.value.length, textarea.value.length);
        }, 0);
      }
    }
  }, [isEditingComment, textareaPosition]);

  const handleMouseLeave = useCallback(() => {
    if (isMovingElements && moveStart && selectedElementIds.length > 0) {
      const dx = minimumSelectedArea.minX - moveStart.x;
      const dy = minimumSelectedArea.minY - moveStart.y;
      if (dx !== 0 || dy !== 0) {
        props.moveElements([...selectedElementIds], dx, dy, OPERATION_TYPE.do);
      }
    }

    if (isShapingNetwork && selectedElementId && adjustPosition && shapeStart) {
      const element = elementFactory(selectedElementId);
      if (element?.type !== ELEMENT_TYPE.network) return;

      const adjustor = adjustorFromPosition(element, adjustPosition);
      const dx = adjustor.x - shapeStart.x;
      const dy = adjustor.y - shapeStart.y;
      if (dx !== 0 || dy !== 0) {
        props.shapeElement(selectedElementId, adjustPosition, dx, dy, OPERATION_TYPE.do);
      }
    }

    setIsMovingElements(false);
    setIsSelectingArea(false);
    setAreaEnd(undefined);
    setIsConnectingNode(false);
    setIsReconnectingSource(false);
    setIsReconnectingTarget(false);
    setIsShapingNetwork(false);
    setIsPanning(false);
    Store.action.cacheViewbox(viewbox);
  }, [
    Store.action,
    adjustPosition,
    elementFactory,
    isMovingElements,
    isShapingNetwork,
    minimumSelectedArea.minX,
    minimumSelectedArea.minY,
    moveStart,
    props,
    selectedElementId,
    selectedElementIds,
    shapeStart,
    viewbox,
  ]);

  useEffect(() => {
    setTextareaFontSize(FontSizeMap[props.fontSize]);
  }, [props.fontSize]);

  // Prevent zoom of the entire page when pinching in and out
  useEffect(() => {
    if (svgRef.current) {
      svgRef.current.addEventListener(
        "wheel",
        e => {
          e.preventDefault();
          return;
        },
        { passive: false },
      );
    }

    return () => {
      if (svgRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        svgRef.current.removeEventListener("wheel", e => {
          e.preventDefault();
          return;
        });
      }
    };
  }, []);

  useEffect(() => {
    const svg = svgRef.current;

    if (svg) {
      svg.addEventListener("gesturestart", handleStartPinchZoom);
      svg.addEventListener("gesturechange", handlePinchZoom);
      svg.addEventListener("gestureend", handleEndPinchZoom);
    }

    return () => {
      if (svg) {
        svg.removeEventListener("gesturestart", handleStartPinchZoom);
        svg.removeEventListener("gesturechange", handlePinchZoom);
        svg.removeEventListener("gestureend", handleEndPinchZoom);
      }
    };
  }, [handleEndPinchZoom, handlePinchZoom, handleStartPinchZoom]);

  useEffect(() => {
    if (svgRef.current && !viewboxIsCached) {
      const svgRect = svgRef.current.getBoundingClientRect();
      const svgRightBottom = svgTransform(svgRef.current, {
        x: svgRect.x + svgRect.width,
        y: svgRect.y + props.outerHeight,
      });
      if (!svgRightBottom) return;

      Store.action.cacheViewbox({ ...viewbox, maxY: svgRightBottom.y });
      setViewareaIsCached(true);
    }
  }, [props.outerHeight, viewboxIsCached, viewbox, Store.action]);
  useEffect(() => {
    return () => {
      /**
       * If you don't clear the CACHE when it is unmounted, you will be stuck in an endless loop.
       */
      setViewareaIsCached(false);
    };
  }, []);
  // Remove a placement area when dragging an object outside the drawing area
  useEffect(() => {
    if (dragLeaveCounter < 0) {
      setPlacement(undefined);
    }
  }, [dragLeaveCounter]);

  // Update the remote user state with the selected element ids
  useEffect(() => {
    props.selectElement([...selectedElementIds]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedElementIds]);

  const { saveEditingCommentId } = props;
  // Save an editing comment id in firebase for showing it to the peer
  useEffect(() => {
    if (isEditingComment && editingCommentId) {
      saveEditingCommentId(editingCommentId);
    } else {
      saveEditingCommentId(null);
    }
  }, [editingCommentId, isEditingComment, saveEditingCommentId]);

  return (
    <StyledSvg
      version="1.1"
      viewBox={`${viewbox.minX} ${viewbox.minY} ${viewbox.maxX - viewbox.minX} ${viewbox.maxY - viewbox.minY}`}
      tabIndex={0}
      focusable={false}
      ref={svgRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onWheel={handlePanAndZoom}
      onKeyDown={handleKeyDown}
      onDragOver={handleDragElement}
      onDrop={handleDropElement}
      onContextMenu={e => e.preventDefault()}
      onMouseLeave={handleMouseLeave}
      onDragEnter={() => setDragLeaveCounter(prev => prev + 1)}
      onDragLeave={() => setDragLeaveCounter(prev => prev - 1)}
      cursor={isPanning ? "grabbing" : "default"}
    >
      <defs>
        <pattern id="smallGrid" width="20" height="20" patternUnits="userSpaceOnUse">
          <path d="M 20 0 L 0 0 0 20" fill="none" stroke={theme.palette.grey[500]} strokeWidth="0.5" />
        </pattern>
        <pattern id="grid" width="80" height="80" patternUnits="userSpaceOnUse">
          <rect width="80" height="80" fill="url(#smallGrid)" />
          <path d="M 80 0 L 0 0 0 80" fill="none" stroke={theme.palette.grey[500]} strokeWidth="1" />
        </pattern>
      </defs>

      <rect x={viewbox.minX} y={viewbox.minY} width={viewbox.maxX - viewbox.minX} height={viewbox.maxY - viewbox.minY} fill="url(#grid)" />

      {/* For pointing drawing area when onboarding */}
      <rect
        id="view-area"
        x={viewbox.minX}
        y={viewbox.minY}
        width={viewbox.maxX - viewbox.minX}
        height={viewbox.maxY - viewbox.minY}
        fillOpacity={0}
        strokeOpacity={0}
        style={{ pointerEvents: "none" }}
      />

      {/* Networks should be on the bottom layer, hence render first */}
      {networkIds.map(networkId => {
        const element = elementFactory(networkId);
        return (
          <React.Fragment key={`network-${networkId}`}>
            {element?.type === ELEMENT_TYPE.network && (
              <DynamicNetwork
                network={element}
                componentType={componentType}
                isSelected={selectedElementIds.includes(networkId)}
                isMoving={isMovingElements && selectedElementIds.includes(networkId)}
                isShaping={isShapingNetwork}
                onSelect={handleSelectNetwork}
                onStartShapeNetwork={handleStartShapeNetwork}
              />
            )}
          </React.Fragment>
        );
      })}

      {elementIds.map(elementId => {
        const element = elementFactory(elementId);
        if (element?.type === ELEMENT_TYPE.node) {
          return (
            <React.Fragment key={`node-${element.id}`}>
              <DynamicNode
                node={element}
                componentType={componentType}
                isSelected={selectedElementIds.includes(elementId)}
                isMoving={isMovingElements && selectedElementIds.includes(elementId)}
                isConnecting={isConnectingNode || isReconnectingSource || isReconnectingTarget}
                isConnectingOwn={elementId === sourceNodeId || elementId === pivotNodeId}
                isDuplicateEdge={
                  (sourceNodeId !== undefined && checkIsDoubleEdge(elementId, sourceNodeId)) ||
                  (pivotNodeId !== undefined && checkIsDoubleEdge(elementId, pivotNodeId))
                }
                isReconnectingAsBefore={elementId === prevNodeId}
                onSelect={handleSelectNode}
                onStartConnectNodes={handleStartConnectNodes}
                onEndConnectNodes={handleEndConnectNodes}
              />
            </React.Fragment>
          );
        }

        if (element?.type === ELEMENT_TYPE.edge) {
          return (
            <React.Fragment key={`edge-${element.id}`}>
              <DynamicEdge
                edge={element}
                isSelected={selectedElementIds.includes(elementId)}
                onSelect={handleSelectEdge}
                isReconnectingSource={isReconnectingSource && element.id === reconnectingEdgeId}
                isReconnectingTarget={isReconnectingTarget && element.id === reconnectingEdgeId}
                onStartReconnect={handleStartReconnectNodes}
                edgeEnd={edgeEnd}
              />
            </React.Fragment>
          );
        }

        if (element?.type === ELEMENT_TYPE.comment) {
          return (
            <React.Fragment key={`comment-${elementId}`}>
              <DynamicComment
                comment={element}
                isSelected={selectedElementIds.includes(elementId)}
                isMoving={isMovingElements && elementId === selectedElementId}
                isEditing={isEditingComment && elementId === editingCommentId}
                onSelect={handleSelectComment}
              />
            </React.Fragment>
          );
        }
      })}

      {isConnectingNode && edgeStart && edgeEnd && (
        <path
          d={`M ${edgeStart.x} ${edgeStart.y} L ${edgeEnd.x} ${edgeEnd.y}`}
          stroke={theme.palette.common.black}
          style={{ pointerEvents: "none" }}
        />
      )}

      {textareaPosition && (
        <foreignObject x={textareaPosition.x} y={textareaPosition.y} width={textareaWidth} height={textareaHeight}>
          <StyledTextareaAutosize
            ref={textareaRef}
            onChange={handleChangeText}
            style={{ fontSize: textareaFontSize, width: textareaWidth, lineHeight: "1.5em" }}
            minRows={1}
            defaultValue={text ?? ""}
            onMouseDown={e => e.stopPropagation()}
          />
        </foreignObject>
      )}

      {props.isPlacingElement && placement && props.placingElementLabel && props.placingElementLabel !== "text" && (
        <rect
          width={LabelTypeMap[props.placingElementLabel] === ELEMENT_TYPE.node ? 160 : 640}
          height={LabelTypeMap[props.placingElementLabel] === ELEMENT_TYPE.node ? 160 : 640}
          x={placement.x}
          y={placement.y}
          stroke={theme.palette.common.black}
          strokeWidth={1}
          strokeDasharray={"8 8"}
          fillOpacity={0}
        />
      )}

      {props.isPlacingElement && placement && props.placingElementLabel && props.placingElementLabel === "text" && (
        <rect
          width={340}
          height={FontSizeMap[props.fontSize] + 10}
          x={placement.x}
          y={placement.y}
          stroke={theme.palette.common.black}
          strokeWidth={1}
          strokeDasharray={"8 8"}
          fillOpacity={0}
        />
      )}

      {isSelectingArea && areaStart && areaEnd && (
        <rect
          width={Math.abs(areaEnd.x - areaStart.x)}
          height={Math.abs(areaEnd.y - areaStart.y)}
          x={Math.min(areaStart.x, areaEnd.x)}
          y={Math.min(areaStart.y, areaEnd.y)}
          fill={theme.palette.info.light}
          fillOpacity={0.3}
        />
      )}

      {!isSelectingArea && minimumSelectedArea && selectedElementIds.length > 1 && (
        <rect
          width={minimumSelectedArea.maxX - minimumSelectedArea.minX}
          height={minimumSelectedArea.maxY - minimumSelectedArea.minY}
          x={minimumSelectedArea.minX}
          y={minimumSelectedArea.minY}
          fill={theme.palette.info.light}
          fillOpacity={0.3}
          stroke={theme.palette.common.black}
          strokeDasharray={"8 8"}
          onMouseDown={handleClickSelectedArea}
          cursor={isMovingElements ? "default" : "move"}
        />
      )}

      {props.collaborators.map(user => {
        if (user.edit) {
          if (!elementIds.includes(user.edit)) return;

          const area = minimumAreaFactory([user.edit]);
          const minX = area.minX - 20;
          const minY = area.minY - 20;
          const maxX = area.maxX + 20;
          const maxY = area.maxY + 20;

          return (
            <g key={`collaborator-area-${user.uid}`}>
              <Username name={`${user.name} が編集中`} color={user.color} x={minX} y={minY} textAnchor={"start"} />
              <polygon
                points={`${minX},${minY} ${maxX},${minY} ${maxX},${maxY} ${minX},${maxY}`}
                stroke={user.color}
                strokeWidth={2}
                fill={user.color}
                fillOpacity={0.1}
                strokeDasharray={"8 8"}
              />
            </g>
          );
        } else if (user.select && user.select.length > 0) {
          // Handle the case where a peer is selecting deleted elements immediately after network recovery
          if (user.select.some(id => !elementIds.includes(id))) return;

          const area = minimumAreaFactory(user.select);
          const minX = area.minX - 20;
          const minY = area.minY - 20;
          const maxX = area.maxX + 20;
          const maxY = area.maxY + 20;

          return (
            <g key={`collaborator-area-${user.uid}`}>
              <Username name={user.name} color={user.color} x={minX} y={minY} textAnchor={"start"} />
              <polygon
                points={`${minX},${minY} ${maxX},${minY} ${maxX},${maxY} ${minX},${maxY}`}
                stroke={user.color}
                strokeWidth={2}
                strokeDasharray={"8 8"}
                fill={"none"}
              />
            </g>
          );
        } else if (user.cursor && !user.select) {
          return (
            <g key={`collaborator-cursor-${user.uid}`}>
              <Cursor color={user.color} x={user.cursor.x} y={user.cursor.y} />
              <Username name={user.name} color={user.color} x={user.cursor.x + 30} y={user.cursor.y + 50} textAnchor={"start"} />
            </g>
          );
        }
      })}
    </StyledSvg>
  );
});

DrawingArea.displayName = "DrawingArea";

export default React.memo(DrawingArea);
