import { ChallengeCodingEditor } from "@hireroo/app-store/widget/shared/ChallengeCodingEditor";
import * as Graphql from "@hireroo/graphql/client/urql";
import type { Widget } from "@hireroo/presentation";

type MessageCardsProps = Widget.ChallengeCodingEditorChatGPTRightSidePanelProps["chatGPTSidePanel"]["messageCards"];

const convertRole = (role: Graphql.ChatGptRole): ChallengeCodingEditor.ChatGPTRole => {
  const roleMap: Record<Graphql.ChatGptRole, ChallengeCodingEditor.ChatGPTRole> = {
    USER: "USER",
    ASSISTANT: "ASSISTANT",
    // UNKNOWN in unexpected but when it comes here, it's handled as USER
    UNKNOWN: "USER",
  };
  return roleMap[role];
};

export const generateMessageCards = (messages: Graphql.ChatGptMessage[]) => {
  return messages.map((message): MessageCardsProps[0] => ({
    avatar: {
      role: convertRole(message.role),
    },
    message: message.content,
  }));
};
