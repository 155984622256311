import Markdown from "@hireroo/markdown-v2/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type UnlockedHintProps = {
  disabled: boolean;
  title: string;
  description: string;
};

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: theme.palette["Secondary/Shades"].p12,
  backgroundImage: "none",
  boxShadow: "none",
  borderRadius: "8px",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  "&:expanded": {
    margin: "auto",
  },
  marginBottom: "16px",
  "&:last-child": {
    marginBottom: 0,
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  cursor: "pointer",
  minHeight: "36px",
  height: "36px",
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  "&.Mui-expanded": {
    minHeight: "36px",
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
  "& .Mui-expanded": {
    margin: 0,
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  "& .MuiTypography-root": {
    margin: 0,
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(15),
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.secondary.main,
}));

const UnlockedHint: React.FC<UnlockedHintProps> = props => {
  const theme = useTheme();

  return (
    <StyledAccordion disabled={props.disabled}>
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: theme.palette["Gray/Shades"].p56 }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box display="flex" alignItems="center">
          <Box display="flex" mr={1}>
            <LightbulbOutlinedIcon sx={{ fontSize: "0.9em" }} color="secondary" />
          </Box>
          <Title>{props.title}</Title>
        </Box>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <React.Suspense>
          <Markdown size="middle" children={props.description} />
        </React.Suspense>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

UnlockedHint.displayName = "UnlockedHint";

export default UnlockedHint;
