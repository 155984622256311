import { ProjectCodingEditorV3 } from "@hireroo/app-store/widget/shared/ProjectCodingEditorV3";
import * as Graphql from "@hireroo/graphql/client/urql";
import * as Sentry from "@sentry/react";
import * as React from "react";

export type StartWorkspaceArgs = {
  entityId: number;
  questionId: number;
  questionVersion: string;
  submissionId: number;
};

export type StartWorkspaceErrorStatus = "PENDING" | "ERROR";

export const useStartDevelopmentWorkspace = (args: StartWorkspaceArgs) => {
  const [startDevelopmentWorkspaceResult, startDevelopmentWorkspace] = Graphql.useStartDevelopmentWorkspaceForProjectCodingEditorMutation();
  const { setWorkspace } = ProjectCodingEditorV3.createProjectEntityAction(args.entityId);
  const { useWorkspace } = ProjectCodingEditorV3.useCreateProjectEntityHooks(args.entityId);
  const workspace = useWorkspace();
  React.useEffect(() => {
    if (workspace) {
      return;
    }
    if (startDevelopmentWorkspaceResult.data || startDevelopmentWorkspaceResult.fetching) {
      return;
    }

    startDevelopmentWorkspace({
      input: {
        projectId: args.entityId,
        questionId: args.questionId,
        questionVersion: args.questionVersion,
      },
    })
      .then(res => {
        if (res.data) {
          setWorkspace(res.data.startDevelopmentWorkspace);
        }
      })
      .catch(error => {
        Sentry.captureException(error);
      });
  }, [
    args.entityId,
    args.questionId,
    args.questionVersion,
    setWorkspace,
    startDevelopmentWorkspace,
    startDevelopmentWorkspaceResult,
    startDevelopmentWorkspaceResult.data,
    startDevelopmentWorkspaceResult.error,
    workspace,
  ]);

  return {
    isStartWorkspaceLoading: startDevelopmentWorkspaceResult.fetching,
    startWorkspaceError: startDevelopmentWorkspaceResult.error === undefined ? "PENDING" : "ERROR",
  };
};
