import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import HomeOutlined from "@mui/icons-material/HomeOutlined";
import MonitorOutlined from "@mui/icons-material/MonitorOutlined";
import VideocamOutlined from "@mui/icons-material/VideocamOutlined";
import ListItemButton, { ListItemButtonProps } from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled, useTheme } from "@mui/material/styles";
import Typography, { TypographyProps } from "@mui/material/Typography";
import * as React from "react";

type Icon = "HOME" | "MONITOR" | "VIDEOCAM";
type SubIcon = "EXPAND_LESS" | "EXPAND_MORE";

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  "&:hover": {
    color: theme.palette.secondary.light,
  },
  paddingRight: 0,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  "&:hover": {
    color: theme.palette.secondary.light,
  },
}));

const IconMap: Record<Icon, React.ReactElement> = {
  HOME: <HomeOutlined color="secondary" fontSize="small" />,
  MONITOR: <MonitorOutlined color="secondary" fontSize="small" />,
  VIDEOCAM: <VideocamOutlined color="secondary" fontSize="small" />,
};

const SubIconMap: Record<SubIcon, React.ReactElement> = {
  EXPAND_LESS: <ExpandLess color="secondary" fontSize="small" />,
  EXPAND_MORE: <ExpandMore color="secondary" fontSize="small" />,
};

export type ListButtonProps = {
  id: string;
  button: ListItemButtonProps;
  icon?: Icon;
  text: string;
  listItemText?: TypographyProps;
  subIcon?: SubIcon;
};

const ListButton: React.FC<ListButtonProps> = props => {
  const theme = useTheme();
  return (
    <StyledListItemButton {...props.button}>
      {props.icon && (
        <ListItemIcon sx={{ minWidth: "2rem" }} color={theme.palette.secondary.light}>
          {IconMap[props.icon]}
        </ListItemIcon>
      )}
      <ListItemText
        primary={
          <StyledTypography {...props.listItemText} variant="body2">
            {props.text}
          </StyledTypography>
        }
      />
      {props.subIcon && SubIconMap[props.subIcon]}
    </StyledListItemButton>
  );
};

ListButton.displayName = "ListButton";

export default ListButton;
