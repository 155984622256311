import * as Graphql from "@hireroo/graphql/client/urql";
import { proxyMap, proxySet } from "valtio/utils";

import type * as Types from "./types";
import { State } from "./types";

export const SortFields = {
  ACCURACY_RATE_DESCENDING: "accuracy-rate-descending",
  ACCURACY_RATE_ASCENDING: "accuracy-rate-ascending",
  AVERAGE_ELAPSED_TIME_DESCENDING: "average-elapsed-time-descending",
  AVERAGE_ELAPSED_TIME_ASCENDING: "average-elapsed-time-ascending",
  CREATED_AT_DESCENDING: "created-at-descending",
  CREATED_AT_ASCENDING: "created-at-ascending",
  NUM_USES_DESCENDING: "num-uses-descending",
  NUM_USES_ASCENDING: "num-uses-ascending",
  UNKNOWN_DESCENDING: "unknown-descending",
  UNKNOWN_ASCENDING: "unknown-ascending",
} satisfies Record<string, Types.SortFieldValue>;

export const SortParams = {
  [SortFields.ACCURACY_RATE_DESCENDING]: { sortMethod: "ACCURACY_RATE", isDescending: true },
  [SortFields.ACCURACY_RATE_ASCENDING]: { sortMethod: "ACCURACY_RATE", isDescending: false },
  [SortFields.AVERAGE_ELAPSED_TIME_DESCENDING]: { sortMethod: "AVERAGE_ELAPSED_TIME", isDescending: true },
  [SortFields.AVERAGE_ELAPSED_TIME_ASCENDING]: { sortMethod: "AVERAGE_ELAPSED_TIME", isDescending: false },
  [SortFields.CREATED_AT_DESCENDING]: { sortMethod: "CREATED_AT", isDescending: true },
  [SortFields.CREATED_AT_ASCENDING]: { sortMethod: "CREATED_AT", isDescending: false },
  [SortFields.NUM_USES_DESCENDING]: { sortMethod: "NUM_USES", isDescending: true },
  [SortFields.NUM_USES_ASCENDING]: { sortMethod: "NUM_USES", isDescending: false },
  [SortFields.UNKNOWN_DESCENDING]: { sortMethod: "UNKNOWN", isDescending: true },
  [SortFields.UNKNOWN_ASCENDING]: { sortMethod: "UNKNOWN", isDescending: false },
} satisfies Record<string, { isDescending: boolean; sortMethod: Graphql.SortMethod }>;

export const DefaultPager: Types.Pager = {
  page: 0,
  size: 50,
  offset: 0,
  sortFieldValue: SortFields.CREATED_AT_DESCENDING,
};

export const defaultState: Readonly<State> = {
  loadingStatus: "NONE",
  currentSearchFilter: null,
  pager: DefaultPager,
  questionMap: proxyMap(),
  res: null,
  skillTagState: {
    skillTagSources: proxyMap(),
    currentSkillTagIdsSet: proxySet(),
    nextOffset: 0,
    res: null,
    textFilter: "",
  },
};

export const SKILL_TAG_SIZE = 40;

// The keys of query params

export const QueryKeys = {
  PAGE: "page",
  SIZE: "size",
  TITLE: "title",
  STATUS: "status",
  QUESTION_VARIANT: "questionVariant",
  DIFFICULTY: "difficulty",
  SORT: "sorting",
  SKILL_TAG: "skillTag",
  LEAK_SCORE_LEVEL: "leakScoreLevel",
} as const;
export type QueryType = (typeof QueryKeys)[keyof typeof QueryKeys];
