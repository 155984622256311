import firebase from "firebase/compat/app";

import * as App from "./app";

export type FirebaseDataSnapshot = firebase.database.DataSnapshot;
export type FirebaseDatabaseReference = firebase.database.Reference;

type AppType = "quiz" | "challenge" | "challenge-language" | "project" | "systemDesign" | "interview" | "liveCoding";

export const getDatabaseReference = (appType: AppType): firebase.database.Database => {
  switch (appType) {
    case "quiz":
      return firebase.database(App.quizApp);
    case "project":
      return firebase.database(App.projectApp);
    case "systemDesign":
      return firebase.database(App.systemDesignApp);
    case "interview":
      return firebase.database(App.interviewApp);
    case "liveCoding":
      return firebase.database(App.liveCodingApp);
    case "challenge":
      return firebase.database(App.challengeApp);
    case "challenge-language":
      return firebase.database(App.challengeApp);
    default:
      throw new Error(`EEE ${appType satisfies never}`);
  }
};

export const getRef = (appType: AppType, key: string): firebase.database.Reference => {
  let database = firebase.database(App.challengeApp);
  switch (appType) {
    case "quiz":
      database = firebase.database(App.quizApp);
      break;
    case "project":
      database = firebase.database(App.projectApp);
      break;
    case "systemDesign":
      database = firebase.database(App.systemDesignApp);
      break;
    case "interview":
      database = firebase.database(App.interviewApp);
      break;
    case "liveCoding":
      database = firebase.database(App.liveCodingApp);
      break;
  }

  const separated = key.split("/");
  let ref = database.ref(separated[0]);

  separated.slice(1).forEach(path => {
    ref = ref.child(path);
  });
  return ref;
};
