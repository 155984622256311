import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import * as React from "react";

export type EmptyLayoutProps = {
  loading?: boolean;
  Bottom: React.ReactNode;
};

const EmptyLayout: React.FC<React.PropsWithChildren<EmptyLayoutProps>> = props => {
  return (
    <Box>
      {props.loading && <LinearProgress sx={{ width: "100%", position: "fixed", zIndex: 2000, top: 0 }} />}
      {props.children}
      {props.Bottom}
    </Box>
  );
};

EmptyLayout.displayName = "EmptyLayout";

export default EmptyLayout;
