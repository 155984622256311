import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import * as z from "zod";

export const useTimeLimitTypeFieldSchema = () => {
  return z.enum(["TIGHT", "RECOMMENDED", "RELAX", "CUSTOM"]);
};

export type TimeLimitTypeFieldSchema = z.infer<ReturnType<typeof useTimeLimitTypeFieldSchema>>;

type TimeLimitFieldOptionalArgs = {
  maxMinutes?: number;
};

export const useTimeLimitFieldSchema = (args?: TimeLimitFieldOptionalArgs) => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();

  const maxMinutes = args?.maxMinutes ?? 10000;

  return z.preprocess(
    v => Number(v),
    z
      .number()
      .min(5, {
        message: t2("ValidateMinSizeWithUnitMessage", {
          size: 5,
          unit: t("分"),
          name: t("制限時間"),
        }),
      })
      .max(maxMinutes, {
        message: t2("ValidateMaxSizeWithUnitMessage", {
          size: maxMinutes,
          unit: t("分"),
          name: t("制限時間"),
        }),
      })
      .int(t("整数のみ入力可能です")),
  );
};

export type TimeLimitFieldSchema = z.infer<ReturnType<typeof useTimeLimitFieldSchema>>;

const useTimeLimitFieldsSchema = () => {
  return z.object({
    timeLimitType: useTimeLimitTypeFieldSchema(),
    timeLimitMinutes: useTimeLimitFieldSchema(),
  });
};

export type TimeLimitFieldsSchema = z.infer<ReturnType<typeof useTimeLimitFieldsSchema>>;
