import MicNoneOutlined from "@mui/icons-material/MicNoneOutlined";
import MicOffOutlined from "@mui/icons-material/MicOffOutlined";
import Avatar, { AvatarProps } from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import VideoTrack, { VideoTrackProps } from "./parts/VideoTrack/VideoTrack";

const BASE_Z_INDEX = 1;

/**
 * TODO Dimension information is obtained from Container.
 */
const HEIGHT = 110;
const WIDTH = 180;

const Wrapper = styled(Box)(() => ({
  display: "flex",
  position: "relative",
  overflow: "hidden",
  alignItems: "center",
  border: BASE_Z_INDEX,
  height: HEIGHT,
  width: WIDTH,
  bgcolor: "black",
  borderRadius: 8,
}));

const BottomBanner = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  position: "absolute",
  zIndex: BASE_Z_INDEX + 1,
  bgcolor: "transparent",
  bottom: 0,
}));

const ThumbnailWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.Other.FilledInputBG,
  width: "100%",
  height: "100%",
  padding: 10,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  bgcolor: "black",
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
}));

export type LiveCodingVideoViewProps = {
  id: string;
  title: string;
  avatar: AvatarProps;
  isMicOn: boolean;
  onClick?: () => void;
  videoTrack?: VideoTrackProps;
};

export const LiveCodingVideoView: React.FC<LiveCodingVideoViewProps> = props => {
  return (
    <Wrapper onClick={props?.onClick}>
      {!props.videoTrack && (
        <ThumbnailWrapper>
          <Avatar {...props.avatar} />
        </ThumbnailWrapper>
      )}
      {props.videoTrack && <VideoTrack {...props.videoTrack} />}
      <BottomBanner>
        {props.isMicOn ? <MicNoneOutlined fontSize="small" /> : <MicOffOutlined color="error" fontSize="small" />}
        <Typography variant="body1">{props.title}</Typography>
      </BottomBanner>
    </Wrapper>
  );
};

LiveCodingVideoView.displayName = "LiveCodingVideoView";

export default LiveCodingVideoView;
