import { useTranslation } from "@hireroo/i18n";
import { Fields } from "@hireroo/validator";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useController } from "react-hook-form";

import ButtonWithTooltip, { ButtonWithTooltipProps } from "../../primitive/Button/ButtonWithTooltip/ButtonWithTooltip";
import SelectableButton, { SelectableButtonProps } from "../../primitive/Button/SelectableButton/SelectableButton";

type FieldValue = Fields.EntityTrack.EntityTrack[];

const StyledFormHelperText = styled(FormHelperText)(() => ({
  textAlign: "center",
}));

/**
 * 181.5px = 64px (dialog margin-top/margin-bottom) + 48px (header) + 1px (line) + 68.5px (footer)
 */
const DIALOG_CONTENT_HEIGHT = `calc(100vh - 181.5px)`;

const SelectQuestionButton = styled(ButtonWithTooltip)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: 14,
}));

const StyledDialog = styled(Dialog)(() => ({
  overflow: "hidden",
  "& .MuiDialog-container > .MuiPaper-root": {
    minWidth: "fit-content",
  },
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  backgroundColor: theme.palette["Background/Paper"].p2,
  minWidth: "fit-content",
  height: DIALOG_CONTENT_HEIGHT,
  overflow: "hidden",
  flexShrink: 0,
  padding: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.Other.OutlinedBorder}`,
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  backgroundColor: theme.palette["Background/Paper"].p2,
}));

const CHANGE_BUTTON_CLASS_NAME = "change-button";

const StyledSelectableButton = styled(SelectableButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  ".MuiButtonBase-root": {
    fontSize: 14,
  },
  ".MuiButtonGroup-grouped:not(:last-of-type)": {
    border: "none",
  },
  [`.${CHANGE_BUTTON_CLASS_NAME}`]: {
    padding: 0,
    flexShrink: 0,
    flexGrow: 0,
    minWidth: 0,
    width: 24,
  },
}));

export type QuestionSelectFieldV2Props = {
  name: "entityTracks";
  loading: boolean;
  /**
   * Show `selectSelectableQuestionButton` and `selectRandomQuestionButton`
   */
  enableMultiSelectQuestion: boolean;
  QuestionDetailEditableList: React.ReactNode;
  QuestionSearchAndSortField: React.ReactNode;
  SearchHistory: React.ReactNode;
  selectQuestionButton: Pick<SelectableButtonProps["items"][0], "onClick" | "disabled" | "title">;
  selectSelectableQuestionButton: Pick<SelectableButtonProps["items"][0], "onClick">;
  selectRandomQuestionButton: Pick<SelectableButtonProps["items"][0], "onClick">;
  addQuestionsButton: Pick<ButtonWithTooltipProps, "onClick" | "disabled" | "title" | "children">;
  closeButton: Pick<ButtonProps, "onClick">;
  dialog: Pick<DialogProps, "open" | "onClose">;
};

const QuestionSelectFieldV2: React.FC<QuestionSelectFieldV2Props> = props => {
  const { t } = useTranslation();
  const { field, fieldState } = useController<Record<string, FieldValue>>({
    name: props.name,
  });

  const selectQuestionButton: ButtonWithTooltipProps = {
    ...props.selectQuestionButton,
    color: "secondary",
    startIcon: "ADD_CIRCLE_OUTLINED",
    children: t("問題を選択する"),
  };

  const selectQuestionButtonGroup: SelectableButtonProps = {
    title: "",
    ...props.selectQuestionButton,
    color: "inherit",
    variant: "text",
    items: [
      {
        ...props.selectQuestionButton,
        startIcon: <AddCircleOutlineOutlinedIcon />,
        children: t("問題を選択する"),
      },
      {
        ...props.selectSelectableQuestionButton,
        startIcon: <AddCircleOutlineOutlinedIcon />,
        children: t("受験者が選択できる問題を追加"),
      },
      {
        ...props.selectRandomQuestionButton,
        startIcon: <AddCircleOutlineOutlinedIcon />,
        children: t("ランダムに出題される問題を追加"),
      },
    ],
    ChangeButtonProps: {
      className: CHANGE_BUTTON_CLASS_NAME,
    },
  };
  const addQuestionsButton: ButtonWithTooltipProps = {
    ...props.addQuestionsButton,
    color: "secondary",
    variant: "contained",
  };
  const closeButton: ButtonProps = {
    ...props.closeButton,
    color: "secondary",
    children: t("閉じる"),
    variant: "text",
  };

  if (props.loading) {
    return (
      <Box p={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <CircularProgress size={36} color="secondary" />
      </Box>
    );
  }

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="body2" color="text.secondary">
          {t("追加可能") + ` (${field.value.length}/10)`}
        </Typography>
      </Box>

      {fieldState.error?.message && (
        <Box>
          <StyledFormHelperText error>{fieldState.error?.message}</StyledFormHelperText>
        </Box>
      )}

      <Box mb={2}>{props.QuestionDetailEditableList}</Box>
      <Stack direction="row" sx={{ width: "100%", justifyContent: "center", alignItems: "center" }}>
        <Box sx={{ width: "33%" }} />
        <Box sx={{ width: "34%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          {props.enableMultiSelectQuestion ? (
            <StyledSelectableButton {...selectQuestionButtonGroup} />
          ) : (
            <SelectQuestionButton {...selectQuestionButton} />
          )}
        </Box>
        <Box
          sx={{
            width: "33%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          {props.SearchHistory}
        </Box>
      </Stack>
      <StyledDialog {...props.dialog}>
        <StyledDialogContent>{props.QuestionSearchAndSortField}</StyledDialogContent>
        <StyledDialogActions>
          <ButtonWithTooltip {...addQuestionsButton} />
          <Button {...closeButton} />
        </StyledDialogActions>
      </StyledDialog>
    </Box>
  );
};

QuestionSelectFieldV2.displayName = "QuestionSelectFieldV2";

export default QuestionSelectFieldV2;
