import { state } from "./State";
import type * as Types from "./types";

export const initialize = (
  res: Types.PlansResponse,
  customer: Types.Customer,
  mostExpensivePaymentPurchase: Types.MostExpensivePaymentPurchase | null,
): void => {
  state.plansResponse = res;
  state.customer = customer;
  state.mostExpensivePaymentPurchase = mostExpensivePaymentPurchase;
};

export const setSubmitValue = (submitValue: Types.SubmitValue) => {
  state.submitValue = submitValue;
};

export const setTemporarySubmitValue = (temporarySubmitValue: Types.SubmitValue) => {
  state.temporarySubmitValue = {
    ...state.temporarySubmitValue,
    ...temporarySubmitValue,
  };
};

export const clear = (): void => {
  state.customer = null;
  state.plansResponse = null;
  state.temporarySubmitValue = null;
  state.submitValue = null;
  state.showingTarget = "EDITOR";
};

export const updateShowingTarget = (status: Types.ShowingTarget) => {
  state.showingTarget = status;
};
