import {
  ProjectEditorToolBar,
  type ProjectEditorToolBarProps,
  ProjectTerminalV3,
  type ProjectTerminalV3Props,
  ProjectWorkspace,
  type ProjectWorkspaceProps,
} from "@hireroo/project/react/v2/widget";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";

import Split, { SplitProps } from "../../../primitive/Split/Split";

export type EditorProps = {
  workspace: ProjectWorkspaceProps;
  terminal: ProjectTerminalV3Props;
  toolbar: ProjectEditorToolBarProps;
  enableTerminal: boolean;
};

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`;

const EditorBox = styled(Box)`
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
`;

const NoTerminalBox = styled(Box)`
  /* position: relative; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const NoTerminalWrapper = styled(Box)`
  flex-shrink: 1;
  flex-grow: 1;
`;

const SplitParams = {
  splitId: "EditorBox",
  Contents: {
    Editor: {
      id: "Editor",
    },
    Terminal: {
      id: "Terminal",
    },
  },
};

const Editor: React.FC<EditorProps> = props => {
  const splitProps: SplitProps = {
    splitId: SplitParams.splitId,
    splitDirection: "HORIZONTAL",
    items: [
      {
        id: SplitParams.Contents.Editor.id,
        Content: (
          <EditorBox id="project-editor-pane">
            <ProjectWorkspace key="editor" {...props.workspace} />
          </EditorBox>
        ),
        size: {
          value: 100,
          unit: "%",
        },
      },
      {
        id: SplitParams.Contents.Terminal.id,
        Content: <ProjectTerminalV3 key="terminal" {...props.terminal} />,
        size: {
          value: 150,
          unit: "px",
        },
        minSize: {
          value: 36,
          unit: "px",
        },
        minimizedSize: {
          value: 36,
          unit: "px",
        },
        expandedSize: {
          value: 150,
          unit: "px",
        },
      },
    ],
  };
  return (
    <StyledBox>
      <ProjectEditorToolBar {...props.toolbar} />
      {props.enableTerminal && <Split {...splitProps} />}
      {/* No need to Split if Terminal is not available */}
      {!props.enableTerminal && (
        <NoTerminalBox id="project-editor-pane">
          <EditorBox>
            <ProjectWorkspace key="editor" {...props.workspace} />
          </EditorBox>
          <NoTerminalWrapper>
            <ProjectTerminalV3 key="terminal" {...props.terminal} />
          </NoTerminalWrapper>
        </NoTerminalBox>
      )}
    </StyledBox>
  );
};

Editor.displayName = "Editor";

export default Editor;
