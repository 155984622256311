import * as React from "react";

type PlotData = Record<`plot${number}`, number>;

export type BoxPlotData = PlotData & {
  label: string;
  isPassed: string;
  isCorrect: boolean;
  min: number;
  med: number;
  max: number;
  bottomWhisker: number;
  bottomBox: number;
  topBox: number;
  topWhisker: number;
  size: number; // for average dot size
  "label-min": number;
  "label-med": number;
  "label-max": number;
};

export type BoxPlotRawData = {
  label: string;
  isPassed: boolean;
  isCorrect: boolean;
  min: number;
  max: number;
  med: number;
  p25: number;
  p75: number;
  plots?: number[];
  avg?: number;
};

const generateZeroValue = (label: string): BoxPlotData => {
  return {
    label,
    isPassed: "false",
    isCorrect: false,
    min: 0,
    med: 0,
    max: 0,
    bottomWhisker: 0,
    bottomBox: 0,
    topBox: 0,
    topWhisker: 0,
    size: 0,
    "label-min": 0,
    "label-med": 0,
    "label-max": 0,
  };
};

const converter = (data: BoxPlotRawData): BoxPlotData => {
  if (!data.isCorrect) {
    return generateZeroValue(data.label);
  }

  const plots: PlotData = {};
  data.plots?.forEach((plot, index) => {
    plots[`plot${index + 1}`] = plot;
  });

  const min = data.min;
  const bottomWhisker = data.p25 - data.min;
  const bottomBox = data.med - data.p25;
  const topBox = data.p75 - data.med;
  const topWhisker = data.max - data.p75;
  return {
    label: data.label,
    isPassed: String(data.isPassed),
    isCorrect: data.isCorrect,
    min: min,
    bottomWhisker: bottomWhisker,
    bottomBox: bottomBox,
    med: data.med,
    topBox: topBox,
    topWhisker: topWhisker,
    size: 250,
    max: data.max - topWhisker - topBox - bottomBox - bottomWhisker - min,
    // NOTE: This is for CustomTooltip so it's depends on CustomTooltip implementation.
    "label-min": data.min,
    "label-med": data.med,
    "label-max": data.max,
    ...plots,
  };
};

export const useBoxPlot = (boxPlots: BoxPlotRawData[]): BoxPlotData[] => {
  return React.useMemo(() => boxPlots.map(converter), [boxPlots]);
};
