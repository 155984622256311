import { state } from "./State";
import type * as Types from "./types";

export const clear = () => {
  state.res = null;
  state.invoices.clear();
  state.page = 0;
};

export const initialize = (res: Types.InvoicesResponse): void => {
  state.res = res;
  res.invoices.forEach(invoice => {
    state.invoices.set(invoice.invoiceId, invoice);
  });
};

export const updatePage = (page: number) => {
  state.page = page;
};
