import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const Wrapper = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.Other.Divider}`,
  height: "80px",
  overflowY: "hidden",
  flexShrink: 0,
  position: "relative",
}));

const InnerWrapper = styled(Box)(() => ({
  paddingLeft: "16px",
  paddingRight: "16px",
  height: "80px",
  overflowY: "auto",
}));

const RightSideWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: 0,
  right: "16px",
  height: "80px",
}));

const Heading = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 500,
}));

const StyledTypography = styled(Typography)(() => ({
  fontSize: 14,
}));

export type Item = {
  label: string;
  value: string;
};

export type FilterDisplayProps = {
  items: Item[];
  resultLabel: string;
};

const FilterDisplay: React.FC<FilterDisplayProps> = props => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <InnerWrapper>
        <Stack spacing={0.5} py={1} pr={20}>
          <Heading color="text.secondary">{t("絞り込み条件")}</Heading>
          {props.items.length === 0 && <StyledTypography variant="body2">{t("絞り込み条件は指定されていません")}</StyledTypography>}
          {props.items.map((item, index) => {
            return <StyledTypography key={`item-${index}`} variant="body2">{`${item.label}: ${item.value}`}</StyledTypography>;
          })}
        </Stack>
      </InnerWrapper>
      <RightSideWrapper>
        <Typography sx={{ fontSize: 14, fontWeight: 700 }}>{props.resultLabel}</Typography>
      </RightSideWrapper>
    </Wrapper>
  );
};

FilterDisplay.displayName = "FilterDisplay";

export default FilterDisplay;
