import * as z from "zod";

export const FileStat = z
  .object({
    path: z.string(),
    is_dir: z.boolean(),
    is_read_only: z.boolean(),
  })
  .strict();

export const InitializeRequestSchema = z
  .object({
    id: z.string(),
    type: z.literal("initialize"),
    params: z.object({}).strict(),
  })
  .strict();

export const InitializeResponseSchema = z
  .object({
    id: z.string(),
    type: z.literal("initialize"),
    params: z
      .object({
        cwd: z.string(),
        index: FileStat.array(),
        ignore_patterns: z.string().array(),
      })
      .strict(),
  })
  .strict();

export const AckSchema = z
  .object({
    id: z.string(),
    type: z.literal("ack"),
    params: z.object({}).strict(),
  })
  .strict();

export const FileDidOpenRequestSchema = z
  .object({
    id: z.string(),
    type: z.literal("file/didOpen"),
    params: z
      .object({
        path: z.string(),
      })
      .strict(),
  })
  .strict();

export const FileDidOpenResponseSchema = z
  .object({
    id: z.string(),
    type: z.literal("file/didOpen"),
    params: z
      .object({
        path: z.string(),
        body: z.string(),
      })
      .strict(),
  })
  .strict();

export const FileDidCloseRequestSchema = z
  .object({
    id: z.string(),
    type: z.literal("file/didClose"),
    params: z
      .object({
        path: z.string(),
      })
      .strict(),
  })
  .strict();

export const FileDidCloseResponseSchema = z
  .object({
    id: z.string(),
    type: z.literal("file/didClose"),
    params: z
      .object({
        path: z.string(),
      })
      .strict(),
  })
  .strict();

export const FileDidCreateRequestSchema = z
  .object({
    id: z.string(),
    type: z.literal("file/didCreate"),
    params: z
      .object({
        path: z.string(),
      })
      .strict(),
  })
  .strict();

export const FileDidCreateResponseSchema = z
  .object({
    id: z.string(),
    type: z.literal("file/didCreate"),
    params: z
      .object({
        path: z.string(),
      })
      .strict(),
  })
  .strict();

export const FileDidChangeRequestSchema = z
  .object({
    id: z.string(),
    type: z.literal("file/didChange"),
    params: z
      .object({
        path: z.string(),
        body: z.string(),
      })
      .strict(),
  })
  .strict();

export const FileDidChangeResponseSchema = z
  .object({
    id: z.string(),
    type: z.literal("file/didChange"),
    params: z
      .object({
        path: z.string(),
      })
      .strict(),
  })
  .strict();

export const FileDidDeleteRequestSchema = z
  .object({
    id: z.string(),
    type: z.literal("file/didDelete"),
    params: z
      .object({
        path: z.string(),
      })
      .strict(),
  })
  .strict();

export const FileDidDeleteResponseSchema = z
  .object({
    id: z.string(),
    type: z.literal("file/didDelete"),
    params: z
      .object({
        path: z.string(),
      })
      .strict(),
  })
  .strict();

export const DirDidCreateRequestSchema = z
  .object({
    id: z.string(),
    type: z.literal("dir/didCreate"),
    params: z
      .object({
        path: z.string(),
      })
      .strict(),
  })
  .strict();

export const DirDidCreateResponseSchema = z
  .object({
    id: z.string(),
    type: z.literal("dir/didCreate"),
    params: z
      .object({
        path: z.string(),
      })
      .strict(),
  })
  .strict();

export const DirDidDeleteRequestSchema = z
  .object({
    id: z.string(),
    type: z.literal("dir/didDelete"),
    params: z
      .object({
        path: z.string(),
      })
      .strict(),
  })
  .strict();

export const DirDidDeleteResponseSchema = z
  .object({
    id: z.string(),
    type: z.literal("dir/didDelete"),
    params: z
      .object({
        path: z.string(),
      })
      .strict(),
  })
  .strict();

export const FsMessageSchema = z.union([
  InitializeRequestSchema,
  InitializeResponseSchema,
  AckSchema,
  FileDidOpenRequestSchema,
  FileDidOpenResponseSchema,
  FileDidCloseRequestSchema,
  FileDidCloseResponseSchema,
  FileDidCreateRequestSchema,
  FileDidCreateResponseSchema,
  FileDidChangeRequestSchema,
  FileDidChangeResponseSchema,
  FileDidDeleteRequestSchema,
  FileDidDeleteResponseSchema,
  DirDidCreateRequestSchema,
  DirDidCreateResponseSchema,
  DirDidDeleteRequestSchema,
  DirDidDeleteResponseSchema,
]);

export type FsMessage = z.infer<typeof FsMessageSchema>;
export type InitializeRequest = z.infer<typeof InitializeRequestSchema>;
export type InitializeResponse = z.infer<typeof InitializeResponseSchema>;
export type Ack = z.infer<typeof AckSchema>;
export type FileDidOpenRequest = z.infer<typeof FileDidOpenRequestSchema>;
export type FileDidOpenResponse = z.infer<typeof FileDidOpenResponseSchema>;
export type FileDidCloseRequest = z.infer<typeof FileDidCloseRequestSchema>;
export type FileDidCloseResponse = z.infer<typeof FileDidCloseResponseSchema>;
export type FileDidCreateRequest = z.infer<typeof FileDidCreateRequestSchema>;
export type FileDidCreateResponse = z.infer<typeof FileDidCreateResponseSchema>;
export type FileDidChangeRequest = z.infer<typeof FileDidChangeRequestSchema>;
export type FileDidChangeResponse = z.infer<typeof FileDidChangeResponseSchema>;
export type FileDidDeleteRequest = z.infer<typeof FileDidDeleteRequestSchema>;
export type FileDidDeleteResponse = z.infer<typeof FileDidDeleteResponseSchema>;
export type DirDidCreateRequest = z.infer<typeof DirDidCreateRequestSchema>;
export type DirDidCreateResponse = z.infer<typeof DirDidCreateResponseSchema>;
export type DirDidDeleteRequest = z.infer<typeof DirDidDeleteRequestSchema>;
export type DirDidDeleteResponse = z.infer<typeof DirDidDeleteResponseSchema>;
