import { Credential } from "@hireroo/app-store/essential/shared";
import { App } from "@hireroo/app-store/essential/talent";
import { ExamTabTablesForTalent } from "@hireroo/app-store/widget/t/ExamTabTablesForTalent";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";
import { useSearchParams } from "react-router-dom";

import ExamTabTablesForTalentContainer, { ExamTabTablesForTalentContainerProps } from "./Container";
import * as Subscriber from "./Subscriber";

export type ExamTabTablesForTalentFetchContainerProps = {};

const ExamTabTablesForTalentFetchContainer: React.FC<ExamTabTablesForTalentFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const talentId = Credential.useUid();
  const [searchParams] = useSearchParams();

  const initialized = ExamTabTablesForTalent.useInitialized();
  const [result, refresh] = Graphql.useGetAssessmentsByTalentIdForExamTabTablesForTalentQuery({
    variables: {
      talentId: talentId ?? "",
      withCount: true,
      withDeleted: false,
      size: 100,
      offset: 0,
      isDescending: true,
    },
    pause: appStatus !== "INITIALIZED" || initialized,
  });

  React.useEffect(() => {
    const cleanup = Subscriber.startSubscribeOnChangeTest();
    return () => {
      cleanup();
      ExamTabTablesForTalent.clear();
    };
  }, []);

  React.useEffect(() => {
    if (result.data?.listAssessmentsByTalentId) {
      const defaultAssessmentId = searchParams.get(ExamTabTablesForTalent.QueryKeys.ASSESSMENT_ID);
      ExamTabTablesForTalent.updateAssessmentsResponse(result.data.listAssessmentsByTalentId, defaultAssessmentId);
    }
  }, [result.data?.listAssessmentsByTalentId, searchParams]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }
  const containerProps: ExamTabTablesForTalentContainerProps = {};

  return <ExamTabTablesForTalentContainer {...containerProps} />;
};

ExamTabTablesForTalentFetchContainer.displayName = "ExamTabTablesForTalentFetchContainer";

export default withErrorBoundary(ExamTabTablesForTalentFetchContainer, {});
