import Box from "@mui/material/Box";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import * as React from "react";

import PlaybackToolbar, { PlaybackToolbarProps } from "../../../../modules/PlaybackToolbar/PlaybackToolbar";
import QuizPlayback, { QuizPlaybackProps } from "../QuizPlayback/QuizPlayback";

const StyledDialog = styled(Dialog)({
  root: {
    flexGrow: 1,
  },
  heading: {
    fontWeight: "bold",
  },
  expanded: {
    width: "80vw",
    height: "100vh",
  },
});

export type QuizPlaybackDialogProps = {
  dialog: DialogProps;
  playback: QuizPlaybackProps;
  playbackToolbar: Omit<PlaybackToolbarProps, "screenStatus">;
};

const QuizPlaybackDialog: React.FC<QuizPlaybackDialogProps> = props => {
  const dialogProps: DialogProps = {
    ...props.dialog,
    maxWidth: "xl",
  };

  return (
    <StyledDialog {...dialogProps}>
      <Box display="flex" flexDirection="column" position="relative" justifyContent="space-between" width="80vw">
        <Box mx={4} my={2} height="80vh" overflow="scroll">
          <QuizPlayback {...props.playback} />
        </Box>
        <PlaybackToolbar {...props.playbackToolbar} screenStatus="EXPANDED" />
      </Box>
    </StyledDialog>
  );
};

QuizPlaybackDialog.displayName = "QuizPlaybackDialog";

export default QuizPlaybackDialog;
