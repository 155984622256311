import type * as Types from "./types";

export const ALREADY_RUN_TUTORIAL = "ALREADY_RUN_TUTORIAL";

export const kindMap: Record<Types.Kind, Types.Key> = {
  CHALLENGE: "challenge",
  SYSTEM_DESIGN: "systemDesign",
  PROJECT_V2: "project-v2",
  PROJECT_V3: "project-v3",
  QUIZ: "quiz",
};
