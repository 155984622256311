import { useSnapshot } from "valtio";

import { state } from "./State";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useCurrentMultiChoicePackage = (type: "CREATE" | "UPDATE") => {
  const snapshot = useSnapshotState();
  if (type === "UPDATE") {
    return snapshot.currentUpdatingMultiChoicePackage;
  }
  return snapshot.currentCreatingMultiChoicePackage;
};

export const useCurrentCreateMultiChoicePackage = () => {
  const snapshot = useSnapshotState();
  return snapshot.currentCreatingMultiChoicePackage;
};

export const useCreatingMultiChoicePackageInitialized = () => {
  const snapshot = useSnapshotState();
  return !!snapshot.currentCreatingMultiChoicePackage;
};

export const useCurrentUpdateMultiChoicePackage = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.currentUpdatingMultiChoicePackage) {
    throw new Error("Reference Error");
  }
  return snapshot.currentUpdatingMultiChoicePackage;
};

export const useUpdateMultiChoicePackageInitialized = () => {
  const snapshot = useSnapshotState();
  return !!snapshot.currentUpdatingMultiChoicePackage;
};
