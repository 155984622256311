import { useTranslation } from "@hireroo/i18n";
import Delete from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import * as React from "react";

import IconButton, { IconButtonProps } from "../../../primitive/Button/IconButton";
import InputControlTextField, { InputControlTextFieldProps } from "../../../primitive/InputControl/InputControlTextField";
import SelectControl, { SelectControlProps } from "../../../primitive/InputControl/SelectControl";
import { useDatabaseTableSignatureContext } from "../Context";

export const typeLabelMap: Record<string, string> = {
  string: "String",
  int: "Integer",
  float: "Float",
  bool: "Boolean",
};

export type ColumnInputProps = {
  columnIndex: number;
  deleteColumnButton: Omit<IconButtonProps, "title">;
  fieldPrefix?: `tables.${number}`;
};

const ColumnInput: React.FC<ColumnInputProps> = props => {
  const { t } = useTranslation();
  const { methods } = useDatabaseTableSignatureContext();
  const fieldColumns: `tables.${number}.columns` | "columns" = props.fieldPrefix ? `${props.fieldPrefix}.columns` : "columns";

  const columnDeleteButton: IconButtonProps = {
    ...props.deleteColumnButton,
    title: t("カラムを削除する"),
    color: "default",
    size: "small",
    "aria-label": "remove-type",
    children: <Delete />,
  };
  const columnNameField: InputControlTextFieldProps = {
    label: t("カラム名"),
    type: "text",
    color: "primary",
    size: "small",
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
    required: true,
    variant: "outlined",
    placeholder: "col",
    control: methods.control,
    // Pass space string to make height same when error text shows
    helperText: methods.getFieldState(`${fieldColumns}.${props.columnIndex}.name`)?.error ? " " : undefined,
    onChange: () => {
      methods.clearErrors(`${fieldColumns}.${props.columnIndex}.name`);
    },
  };
  const columnTypeField: SelectControlProps = {
    label: t("カラムの型"),
    menuItems: Object.keys(typeLabelMap).map(typeLabel => {
      return {
        label: typeLabelMap[typeLabel],
        value: typeLabel,
      };
    }),
    control: methods.control,
    select: {
      sx: {
        width: 115,
      },
    },
    // Pass space string to make height same as name field when error text shows
    helperText: methods.getFieldState(`${fieldColumns}.${props.columnIndex}.name`)?.error ? " " : undefined,
  };
  return (
    <Box display="flex" columnGap={2} rowGap={2} mb={1} alignItems="center">
      <Box mt={2} display="inline-flex">
        <Box mr={1}>
          <IconButton {...columnDeleteButton} />
        </Box>
        <InputControlTextField name={`${fieldColumns}.${props.columnIndex}.name`} {...columnNameField} />
      </Box>

      <Box mt={2}>
        <SelectControl name={`${fieldColumns}.${props.columnIndex}.type`} {...columnTypeField} />
      </Box>
    </Box>
  );
};

ColumnInput.displayName = "ColumnInput";

export default ColumnInput;
