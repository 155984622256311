import { QuestionForList } from "@hireroo/validator";

import { safeJsonParse } from "../../parser";

export const useQuestionForListCacheStorage = () => {
  const key = "QuestionForList";
  //No need to use React.useState
  const value = sessionStorage.getItem(key);

  const getUniqueKeysFromSessionStorage = (): string[] => {
    if (value) {
      const result = QuestionForList.UniqueKeys.safeParse(safeJsonParse(value));
      if (result.success) {
        return result.data;
      }
    }
    return [];
  };

  return {
    /**
     * Set created or updated question to sessionStorage
     * @param newValues
     */
    set: (newValues: QuestionForList.QuestionForListSchemaType) => {
      const sessionKeyForQuestion = `${key}${newValues.type}${newValues.id}${newValues.version}`;
      sessionStorage.setItem(`${sessionKeyForQuestion}`, JSON.stringify(newValues));

      const uniqueKeys = getUniqueKeysFromSessionStorage();
      if (uniqueKeys.length > 0) {
        const uniqueKeysSet = new Set(uniqueKeys);
        uniqueKeysSet.add(sessionKeyForQuestion);
        sessionStorage.setItem(key, JSON.stringify(Array.from(uniqueKeysSet)));
      } else {
        sessionStorage.setItem(key, JSON.stringify([sessionKeyForQuestion]));
      }
    },
    restore: (): QuestionForList.QuestionForListSchemaType[] => {
      const uniqueKeys = getUniqueKeysFromSessionStorage();
      sessionStorage.removeItem(key);
      const questions: QuestionForList.QuestionForListSchemaType[] = [];
      uniqueKeys.forEach(uniqueKey => {
        const valueFromStorage = sessionStorage.getItem(`${uniqueKey}`);
        sessionStorage.removeItem(`${uniqueKey}`);
        if (valueFromStorage) {
          const result = QuestionForList.QuestionForListSessionStorageSchema.safeParse(safeJsonParse(valueFromStorage));
          if (result.success) {
            questions.push(result.data);
          }
        }
      });
      return questions;
    },
  };
};
