import { useTranslation } from "@hireroo/i18n";
import { ScreeningTestRetakeForm } from "@hireroo/validator";
import { ErrorMessage } from "@hookform/error-message";
import Box from "@mui/material/Box";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import FormControlLabel, { FormControlLabelProps } from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useFormContext } from "react-hook-form";

const StyledFormGroup = styled(FormGroup)(({ theme }) => ({
  borderRadius: "4px",
  border: "1px solid",
  borderColor: theme.palette.Other.Divider,
  px: 1,
  display: "flex",
}));

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  display: "flex",
  alignItems: "center",
  alignSelf: "stretch",
  marginLeft: 0,
}));

const ReservedFieldName = {
  SELECTED_ENTITY_IDS: "selectedEntityIds",
  TIME_LIMIT_MINUTES: "timeLimitMinutes",
} satisfies Record<string, keyof ScreeningTestRetakeForm.RetakeScreeningTestSchema>;

type EntityOption = {
  entityId: ScreeningTestRetakeForm.EntityIdSchema;
  displayName: string;
};

export type RetakeEntitiesFieldProps = {
  options: EntityOption[];
  maxTimelimitMinutes: number;
};

const RetakeEntitiesField: React.FC<RetakeEntitiesFieldProps> = props => {
  const { t } = useTranslation();
  const methods = useFormContext<ScreeningTestRetakeForm.RetakeScreeningTestSchema>();

  return (
    <Stack direction="column" spacing={1.5}>
      <Typography fontSize={12} color="textSecondary">
        {t("候補者に再度受験してもらう問題を選択してください。")}
      </Typography>
      <StyledFormGroup>
        {props.options.map(option => {
          const uniqueId = `${option.entityId.entityId}-${option.entityId.entityType}`;
          const handleChange: Exclude<CheckboxProps["onChange"], undefined> = (e, innerChecked) => {
            const selectedEntityIds = methods.getValues(ReservedFieldName.SELECTED_ENTITY_IDS);
            if (innerChecked) {
              const entityId: ScreeningTestRetakeForm.EntityIdSchema = {
                entityId: option.entityId.entityId,
                entityType: option.entityId.entityType,
                timeLimitMinutes: option.entityId.timeLimitMinutes,
              };
              methods.setValue(ReservedFieldName.SELECTED_ENTITY_IDS, [...selectedEntityIds, entityId]);
            } else {
              methods.setValue(
                ReservedFieldName.SELECTED_ENTITY_IDS,
                selectedEntityIds.filter(
                  entity => !(entity.entityId === option.entityId.entityId && entity.entityType === option.entityId.entityType),
                ),
              );
            }
            methods.clearErrors(ReservedFieldName.SELECTED_ENTITY_IDS);
          };
          const formControlLabelProps: FormControlLabelProps = {
            control: (
              <Checkbox
                onChange={handleChange}
                checked={
                  methods
                    .watch(ReservedFieldName.SELECTED_ENTITY_IDS)
                    .findIndex(entity => entity.entityId === option.entityId.entityId && entity.entityType === option.entityId.entityType) !==
                  -1
                }
                color="secondary"
                size="small"
              />
            ),
            label: (
              <Box component="span" display="flex">
                <Typography variant="body2">{option.displayName}</Typography>
              </Box>
            ),
            value: uniqueId,
          };
          return <StyledFormControlLabel key={uniqueId} {...formControlLabelProps} />;
        })}
      </StyledFormGroup>
      <ErrorMessage
        errors={methods.formState.errors}
        name={ReservedFieldName.SELECTED_ENTITY_IDS}
        render={({ message }) => (
          <Typography color="error" variant="caption" noWrap>
            {message}
          </Typography>
        )}
      />
      <Typography color="textSecondary" fontSize={12}>
        {t("選択されなかった問題は、すでに提出されたものとみなされ、改めて再試験を作成する際には選択することができません。")}
      </Typography>
    </Stack>
  );
};

RetakeEntitiesField.displayName = "RetakeEntitiesField";

export default RetakeEntitiesField;
