import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateChallengeTestReportPropsArgs, useGenerateProps } from "./useGenerateProps";

export type ChallengeTestReportContainerProps = GenerateChallengeTestReportPropsArgs;

const ChallengeTestReportContainer: React.FC<ChallengeTestReportContainerProps> = props => {
  const challengeTestReportProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ChallengeTestReport {...challengeTestReportProps} />
    </ErrorBoundary>
  );
};

ChallengeTestReportContainer.displayName = "ChallengeTestReportContainer";

export default withErrorBoundary(ChallengeTestReportContainer, {});
