import { QUERY_PARAMS_FOR_DEMO } from "@hireroo/app-definition/demo";
import { QUERY_PARAMS_FOR_NOTIFY } from "@hireroo/app-definition/spot";
import { compress } from "@hireroo/app-helper/lz-string";
import { ScreeningsId } from "@hireroo/app-store/page/c/screenings_id";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import type * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Pages } from "@hireroo/presentation";
import { generateHelpCenterUrl, generatePath, navigate as nativeNavigate } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import { StartDemoForm } from "@hireroo/validator";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import CandidateFooterContainer from "../../../../widget/v2/c/CandidateFooter/Container";
import CandidateNavigationContainer from "../../../../widget/v2/c/CandidateNavigation/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import {
  generateGraphqlVariablesToFormDefaultValuesVariables,
  generateGraphqlVariablesToFormVariables,
  generateSelectableQuestionsDefaultValue,
  generateVariablesFormToGraphqlInput,
  useGenerateSelectableItemParams,
} from "./privateHelper";
import { useGenerateCustomFieldSectionProps } from "./useGenerateCustomFieldSectionProps";
import { useGenerateTestOverviewSectionProps } from "./useGenerateTestOverviewSectionProps";

type SelectQuestionFieldProps = Exclude<Pages.EntryScreeningProps["selectQuestionField"], undefined>;

export type GenerateEntryScreeningPropsArgs = {
  invitationCode: string | null;
};

export const useGenerateProps = (args: GenerateEntryScreeningPropsArgs): Pages.EntryScreeningProps => {
  const { t } = useTranslation();
  const navigate = useTransitionNavigate();
  const lang = useLanguageCode();
  const screening = ScreeningsId.useScreening();
  const client = getGraphqlClient();
  const testOverviewSectionProps = useGenerateTestOverviewSectionProps();
  const customFieldSection = useGenerateCustomFieldSectionProps();
  const [searchParams] = useSearchParams();
  const compoundId = React.useMemo(() => searchParams.get("compoundId"), [searchParams]);
  const sonarIntegrationId = React.useMemo(() => searchParams.get("sonarIntegrationId"), [searchParams]);
  const hasSelectableQuestion = screening.entityTracks.some(entityTrack => entityTrack.__typename !== "ScreeningFixedEntityTrack");
  const generateSelectableItemParams = useGenerateSelectableItemParams();

  const isAllFixedEntityTrack = screening.entityTracks.every(entityTrack => entityTrack.__typename === "ScreeningFixedEntityTrack");
  const selectQuestionFieldItems = screening.entityTracks.reduce<SelectQuestionFieldProps["items"]>((all, entityTrack, index) => {
    const titlePrefix = `Q${index + 1}`;
    switch (entityTrack.__typename) {
      case "ScreeningFixedEntityTrack":
        all.push(generateSelectableItemParams(titlePrefix, entityTrack.entitySourceQuestion, true));
        break;
      case "ScreeningRandomFromQuestionsEntityTrack":
        all.push({
          title: `${titlePrefix} ${t("この問題はランダムに出題されます")}`,
        });
        break;
      case "ScreeningSelectableFromQuestionsEntityTrack":
        all.push({
          title: `${titlePrefix} ${t("以下の問題の中から、1問選択してください")}`,
          name: `selectableQuestions.${entityTrack.trackId}`,
          items: entityTrack.entitySourceQuestions.map((entitySourceQuestion, index) => {
            return generateSelectableItemParams(`${titlePrefix} - ${index + 1}`, entitySourceQuestion, false);
          }),
        });
        break;
      default:
        throw new Error(`Unknown EntityTrack ${entityTrack satisfies never}`);
    }
    return all;
  }, []);
  const demoUrl = React.useMemo((): string => {
    const params: StartDemoForm.StartDemoParams = {
      kind: "SCREENING",
      screeningId: screening.screeningId,
      invitationCode: args.invitationCode,
    };
    const value = compress(JSON.stringify(params));
    return generatePath("/c/demos/start", {
      queryParams: {
        [QUERY_PARAMS_FOR_DEMO.key]: value,
      },
    });
  }, [args.invitationCode, screening.screeningId]);

  const extractSpotIdByAcceptScreeningResponse = React.useCallback(
    (acceptScreening: Graphql.AcceptScreeningResponseForScreeningsIdFragment): { spotId: string | null } => {
      switch (acceptScreening.__typename) {
        case "AcceptScreeningSuccessResponse": {
          return {
            spotId: acceptScreening.spot.spotId,
          };
        }
        case "AcceptScreeningErrorResponse": {
          switch (acceptScreening.errorCode) {
            case "EMAIL_AND_CODE_COMBINATION_ERROR": {
              Snackbar.notify({
                severity: "error",
                message: [
                  t("招待リンクとメールアドレスの組み合わせが間違っています。"),
                  t("招待リンクを受信したメールアドレスとリンクを確認してください。"),
                ].join(""),
              });
              break;
            }
            case "EMAIL_IS_ALREADY_IN_USE": {
              Snackbar.notify({
                severity: "error",
                message: t("入力されたメールアドレスに対して過去にこのテストのリンクが発行されています。メールをお確かめください。"),
              });
              break;
            }
            default:
              throw new Error(`acceptScreening.errorCode is invalid: ${acceptScreening.errorCode satisfies never}`);
          }
          break;
        }
        default:
          throw new Error(`res.acceptScreening is invalid: ${acceptScreening satisfies never}`);
      }
      return {
        spotId: null,
      };
    },
    [t],
  );

  const deleteSpot = React.useCallback(
    (spotId: string) => {
      return client
        .DeleteSpotForScreeningsId({
          spotId: spotId,
        })
        .then(() => {
          // Do nothing
        })
        .catch(error => {
          Snackbar.notify({
            severity: "error",
            message: t("エラーが発生しました。しばらくしてからもう一度お試しください。"),
          });
          Sentry.captureException(error);
        });
    },
    [t, client],
  );

  return {
    layout: {
      NotificationBanner: null,
      Header: <CandidateNavigationContainer />,
      Footer: <CandidateFooterContainer />,
      Snackbar: <SnackbarContainer />,
      Tutorial: null,
    },
    testOverviewSection: testOverviewSectionProps,
    confirmForm: {
      onSubmitAccept: async (fields, controller) => {
        const variablesInput = generateVariablesFormToGraphqlInput(fields.variables);
        controller.setLoading(true);
        let newSpotId: string | null = null;
        try {
          const selectedQuestions = Object.values(fields.selectableQuestions).reduce<Graphql.ScreeningSelectedQuestionByTrack[]>(
            (all, selectedQuestion) => {
              if (selectedQuestion.index !== null) {
                all.push({
                  trackId: selectedQuestion.trackId,
                  selectedIndex: selectedQuestion.index,
                });
              }
              return all;
            },
            [],
          );
          const resAccept = await client.AcceptScreeningForScreeningsId({
            input: {
              screeningId: screening.screeningId,
              filledVariables: variablesInput,
              candidateName: fields.name,
              candidateEmail: fields.email,
              invitationCode: args.invitationCode,
              selectedQuestions: selectedQuestions,
            },
          });
          Snackbar.notify({
            severity: "success",
            message: t("入力されたメールアドレスにテストリンクを送信しました。"),
          });
          const { spotId } = extractSpotIdByAcceptScreeningResponse(resAccept.acceptScreening);
          newSpotId = spotId;
          if (newSpotId && compoundId && sonarIntegrationId) {
            const res = await client.LinkSpotToSonarIntegrationForScreeningsId({
              input: {
                spotId: newSpotId,
                compoundId: compoundId,
                sonarIntegrationId: sonarIntegrationId,
              },
            });
            if (!res) {
              return;
            }
          }
          if (newSpotId) {
            navigate("/c/screenings/tests/:id", {
              pathParams: { id: newSpotId },
              queryParams: {
                [QUERY_PARAMS_FOR_NOTIFY.key]: QUERY_PARAMS_FOR_NOTIFY.value,
              },
            });
          }
        } catch (error) {
          if (newSpotId && compoundId && sonarIntegrationId) {
            // delete spot if link to sonar integration failed
            await deleteSpot(newSpotId);
          }
          Snackbar.notify({
            severity: "error",
            message: t("エラーが発生しました。しばらくしてからもう一度お試しください。"),
          });
          Sentry.captureException(error);
        } finally {
          controller.setLoading(false);
        }
      },
      onSubmitAcceptAndStart: async (fields, controller) => {
        const variablesInput = generateVariablesFormToGraphqlInput(fields.variables);
        controller.setLoading(true);
        let newSpotId: string | null = null;
        try {
          const selectedQuestions = Object.values(fields.selectableQuestions).reduce<Graphql.ScreeningSelectedQuestionByTrack[]>(
            (all, selectedQuestion) => {
              if (selectedQuestion.index !== null) {
                all.push({
                  trackId: selectedQuestion.trackId,
                  selectedIndex: selectedQuestion.index,
                });
              }
              return all;
            },
            [],
          );
          const acceptRes = await client.AcceptScreeningForScreeningsId({
            input: {
              screeningId: screening.screeningId,
              filledVariables: variablesInput,
              candidateName: fields.name,
              candidateEmail: fields.email,
              invitationCode: args.invitationCode,
              selectedQuestions: selectedQuestions,
            },
          });

          const { spotId } = extractSpotIdByAcceptScreeningResponse(acceptRes.acceptScreening);
          newSpotId = spotId;
          if (!newSpotId) {
            // Already show Snackbar in extractSpotIdByAcceptScreeningResponse
            return;
          }
          if (compoundId && sonarIntegrationId) {
            const res = await client.LinkSpotToSonarIntegrationForScreeningsId({
              input: {
                spotId: newSpotId,
                compoundId: compoundId,
                sonarIntegrationId: sonarIntegrationId,
              },
            });
            if (!res) {
              return;
            }
          }
          await client.StartSpotForScreeningsId({
            input: {
              spotId: newSpotId,
              candidateName: fields.name,
              emailAddress: fields.email,
            },
          });

          Snackbar.notify({
            severity: "success",
            message: [t("入力されたメールアドレスにテストリンクを送信しました。"), t("テストを開始します。")].join(""),
          });

          navigate("/c/screenings/tests/:id", { pathParams: { id: newSpotId } });
        } catch (error) {
          Sentry.captureException(error);
          if (newSpotId && compoundId && sonarIntegrationId) {
            // delete spot if link to sonar integration failed
            await deleteSpot(newSpotId);
          }
          Snackbar.notify({
            severity: "error",
            message: t("テストの開始に失敗しました。お手数ですがヘルプセンターより運営にお問い合わせください。"),
          });
        } finally {
          controller.setLoading(false);
        }
      },
      onInputError: () => {
        Snackbar.notify({
          severity: "error",
          message: t("入力内容に不備があります。"),
        });
      },
      startDemoButton: {
        onClick: () => {
          nativeNavigate(demoUrl, { _blank: true });
        },
      },
    },
    customFieldSection: customFieldSection,
    onboarding: {
      tosUrl: generateHelpCenterUrl(lang, "TOS_FOR_CANDIDATE"),
    },
    defaultValues: {
      variables: generateGraphqlVariablesToFormDefaultValuesVariables([...screening.variables]),
      selectableQuestions: generateSelectableQuestionsDefaultValue(screening.entityTracks),
    },
    formArgs: {
      variables: generateGraphqlVariablesToFormVariables([...screening.variables]),
      hasSelectableQuestion: hasSelectableQuestion,
    },
    selectQuestionField: !isAllFixedEntityTrack
      ? {
          items: selectQuestionFieldItems,
        }
      : undefined,
  };
};
