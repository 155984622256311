import { DeepReadonly } from "@hireroo/app-helper/types";
import escapeStringRegexp from "escape-string-regexp";
import { useSnapshot } from "valtio";

import { state } from "./State";
import * as Types from "./types";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = (): boolean => {
  const { company } = useSnapshotState();
  return !!company;
};

export const useEmployeeMap = (): Record<string, Types.Employee | undefined> => {
  const { company } = useSnapshotState();
  if (!company) {
    return {};
  }
  const employeeMap = company.employees.reduce<Record<string, Types.Employee | undefined>>((all, employee) => {
    return { ...all, [employee.id]: employee };
  }, {});
  return employeeMap;
};

export const useGroups = (): DeepReadonly<Types.Company["groups"]> => {
  const { company } = useSnapshotState();
  return company ? company.groups : [];
};

export const useFilteredGroups = () => {
  const snapshot = useSnapshotState();
  const groups = useGroups();
  const regex = new RegExp(escapeStringRegexp(snapshot.filterText));
  const textFilter = (group: DeepReadonly<Types.Company["groups"]>[0]): boolean => {
    if (snapshot.filterText === "") {
      return true;
    }
    return regex.test(group.groupName);
  };
  return groups.filter(textFilter);
};

export const useAddMemberToGroup = () => {
  const snapshot = useSnapshotState();
  const groups = useGroups();
  const group = groups.find(group => group.id === snapshot.addMembers.selectedGroupId);
  const addMembersState = snapshot.addMembers;
  const employeeMap = useEmployeeMap();
  const alreadyExistsEmployeeIds = (group?.companyEmployees || []).map(companyEmployee => companyEmployee.employeeId);
  const selectableEmployeeIds = Object.keys(employeeMap).filter(
    id => !addMembersState.selectedEmployeeIds.includes(id) && !alreadyExistsEmployeeIds.includes(id),
  );
  const regex = new RegExp(escapeStringRegexp(addMembersState.searchText));
  const employeeFilter = (employee: Types.Employee): boolean => {
    if (addMembersState.searchText === "") {
      return true;
    }
    return regex.test(employee.email) || regex.test(employee.displayName);
  };
  return {
    selectedMembers: snapshot.addMembers.selectedEmployeeIds.reduce<Types.Employee[]>((all, id) => {
      const employee = employeeMap[id];
      if (employee) {
        return [...all, employee];
      }
      return all;
    }, []),
    selectableMembers: selectableEmployeeIds
      .reduce<Types.Employee[]>((all, id) => {
        const employee = employeeMap[id];
        if (employee) {
          return [...all, employee];
        }
        return all;
      }, [])
      .filter(employeeFilter),
  };
};

export const useCreateGroup = () => {
  const snapshot = useSnapshotState();
  const createGroupState = snapshot.createGroup;
  const employeeMap = useEmployeeMap();
  const selectableEmployeeIds = Object.keys(employeeMap).filter(id => !createGroupState.selectedEmployeeIds.includes(id));
  return {
    selectedMembers: createGroupState.selectedEmployeeIds.map(id => employeeMap[id]).flatMap(v => (v ? [v] : [])),
    selectableMembers: selectableEmployeeIds.map(id => employeeMap[id]).flatMap(v => (v ? [v] : [])),
  };
};

export const useDeleteGroup = () => {
  const snapshot = useSnapshotState();
  const deleteGroupState = snapshot.deleteGroup;
  const groups = useGroups();
  return {
    group: groups.find(group => group.id === deleteGroupState.selectedGroupId),
  };
};

export const useDeleteGroups = () => {
  const snapshot = useSnapshotState();
  const groups = useGroups();
  return {
    groups: groups.filter(group => snapshot.selectedGroupIds.includes(group.id)),
  };
};
export const useSelectedGroups = () => {
  const snapshot = useSnapshotState();
  const groups = useGroups();
  return {
    groups: groups.filter(group => snapshot.selectedGroupIds.includes(group.id)),
  };
};

export const useEditGroup = () => {
  const snapshot = useSnapshotState();
  const editGroupState = snapshot.editGroup;
  const groups = useGroups();
  return {
    group: groups.find(group => group.id === editGroupState.selectedGroupId),
  };
};

export const useRemoveMembers = () => {
  const snapshot = useSnapshotState();
  const groups = useGroups();
  const removeMembersState = snapshot.removeMembers;
  const employeeMap = useEmployeeMap();
  const group = groups.find(group => group.id === removeMembersState.selectedGroupId);
  const companyEmployeeIds = (group?.companyEmployees || []).map(companyEmployee => companyEmployee.employeeId);
  const regex = new RegExp(escapeStringRegexp(removeMembersState.searchText));
  const employeeFilter = (employee: Types.Employee): boolean => {
    if (removeMembersState.searchText === "") {
      return true;
    }
    return regex.test(employee.email) || regex.test(employee.displayName);
  };
  return {
    group: group,
    selectableMembers: companyEmployeeIds
      .filter(id => !snapshot.removeMembers.selectedEmployeeIds.includes(id))
      .map(companyEmployeeId => employeeMap[companyEmployeeId])
      .flatMap(v => (v ? [v] : []))
      .filter(employeeFilter),
    selectedRemoveMembers: snapshot.removeMembers.selectedEmployeeIds.map(employeeId => employeeMap[employeeId]).flatMap(v => (v ? [v] : [])),
  };
};

export const useDialogStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.dialogStatus;
};
