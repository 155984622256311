import { scrollToContentForReportV2 } from "@hireroo/app-helper/html-element";
import { AssessmentReport } from "@hireroo/app-store/widget/t/AssessmentReport";
import { useCurrentLanguage } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import { updateHashParam } from "@hireroo/router/api";
import * as React from "react";

import { generateSmoothTargetId } from "./privateHelper";

type Item = Widget.AssessmentReportProps["footer"]["switcher"]["items"][0];

export const useGenerateFooterProps = (): Widget.AssessmentReportProps["footer"] => {
  const exam = AssessmentReport.useExam();
  const lang = useCurrentLanguage();
  const selectedEntityId = AssessmentReport.useSelectedEntityId();

  const items = React.useMemo((): Item[] => {
    return exam.entities.map((entity, index): Item => {
      if (entity.__typename === "ChallengeEntity" && entity.challengeQuestion) {
        const question = entity.challengeQuestion;
        const hashKey = generateSmoothTargetId(entity);
        const uniqueEntityId = AssessmentReport.generateUniqueEntityId(entity);
        return {
          value: uniqueEntityId,
          displayText: `Q${index + 1}`,
          disabled: false,
          status: "PENDING",
          tooltipText: resolveLanguage(question, lang, "title"),
          onClick: () => {
            AssessmentReport.updateSelectedEntityId(uniqueEntityId);
            updateHashParam(hashKey);
          },
        };
      } else if (entity.__typename === "QuizEntity" && entity.pb_package) {
        const pkg = entity.pb_package;
        const hashKey = generateSmoothTargetId(entity);
        const uniqueEntityId = AssessmentReport.generateUniqueEntityId(entity);
        return {
          value: uniqueEntityId,
          displayText: `Q${index + 1}`,
          disabled: false,
          status: "PENDING",
          tooltipText: resolveLanguage(pkg, lang, "title"),
          onClick: () => {
            AssessmentReport.updateSelectedEntityId(uniqueEntityId);
            updateHashParam(hashKey);
          },
        };
      } else if (entity.__typename === "ProjectEntity" && entity.question) {
        const question = entity.question;
        const hashKey = generateSmoothTargetId(entity);
        const uniqueEntityId = AssessmentReport.generateUniqueEntityId(entity);
        return {
          value: uniqueEntityId,
          displayText: `Q${index + 1}`,
          disabled: false,
          status: "PENDING",
          tooltipText: resolveLanguage(question, lang, "title"),
          onClick: () => {
            AssessmentReport.updateSelectedEntityId(uniqueEntityId);
            updateHashParam(hashKey);
          },
        };
      } else if (entity.__typename === "SystemDesignEntity" && entity.question) {
        const question = entity.question;
        const hashKey = generateSmoothTargetId(entity);
        const uniqueEntityId = AssessmentReport.generateUniqueEntityId(entity);
        return {
          value: uniqueEntityId,
          displayText: `Q${index + 1}`,
          disabled: false,
          status: "PENDING",
          tooltipText: resolveLanguage(question, lang, "title"),
          onClick: () => {
            AssessmentReport.updateSelectedEntityId(uniqueEntityId);
            updateHashParam(hashKey);
          },
        };
      } else {
        throw new Error("unknown entity type");
      }
    });
  }, [exam.entities, lang]);
  return {
    switcher: {
      items: items,
      onChange: () => {
        // Not necessary
      },
      defaultValue: selectedEntityId || "",
    },
    topButton: {
      onClick: () => {
        scrollToContentForReportV2(AssessmentReport.SUMMARY_ELEMENT_ID);
      },
    },
  };
};
