import { useTheme } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import Select, { SelectProps } from "@mui/material/Select";
import * as React from "react";
import { Control, Controller } from "react-hook-form";

export type SelectMenuItemProps = MenuItemProps & {
  label: string;
};

export type SelectControlProps = {
  className?: string;
  menuItems: SelectMenuItemProps[];
  formControlId?: string;
  label?: string;
  value?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any>;
  helperText?: string;
  disabled?: boolean;
  select?: SelectProps;
  color?: "primary" | "secondary";
};

const SelectControl: React.FC<SelectControlProps & { name: string }> = props => {
  const theme = useTheme();
  const menuItemSecondarySx: MenuItemProps["sx"] = {
    "&.MuiMenuItem-root": {
      "&:hover ": {
        backgroundColor: theme.palette["Secondary/Shades"].p8,
      },
      "&.Mui-selected": {
        backgroundColor: theme.palette["Secondary/Shades"].p16,
      },
    },
  };
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl id={props.formControlId} fullWidth={props.select?.fullWidth ?? true} error={Boolean(error?.message)}>
          <InputLabel size="small" color={props.color}>
            {props.label}
          </InputLabel>
          <Select
            size="small"
            labelId={props.formControlId}
            fullWidth
            {...props.select}
            color={props.color}
            id={props.name}
            label={props.label}
            disabled={props.disabled}
            onChange={onChange}
            value={value}
            className={props.className}
          >
            {props.menuItems.map(menuItem => {
              const menuItemProps: MenuItemProps = {
                color: props.color,
                value: menuItem.value,
                children: menuItem.label,
                sx: props.color === "secondary" ? menuItemSecondarySx : undefined,
                ...menuItem,
              };
              return <MenuItem key={menuItem.label} {...menuItemProps} />;
            })}
          </Select>
          {(props.helperText || error) && (
            <FormHelperText error={Boolean(error?.message)}>{error?.message ? error.message : props.helperText}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

SelectControl.displayName = "SelectControl";

export default SelectControl;
