import { translationLanguageMap } from "@hireroo/app-definition";
import { createInitialCodes, createInitialFlowCharts } from "@hireroo/app-helper/freepad";
import { stringComponentTypeToGraphqlComponentTypeGraphql } from "@hireroo/app-helper/system-design";
import { Auth } from "@hireroo/app-store/essential/employee";
import { FreepadResourceEditor } from "@hireroo/app-store/view-domain/FreepadResourceEditor";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { defaultLanguageMap } from "@hireroo/challenge/definition";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import type * as Graphql from "@hireroo/graphql/client/urql";
import { SupportLanguageValue, useTranslation } from "@hireroo/i18n";
import { Pages, Widget } from "@hireroo/presentation/legacy";
import { FreepadAnswerForm } from "@hireroo/validator";
import * as React from "react";
import { SubmitHandler, useFieldArray } from "react-hook-form";

type UseGenerateAnswersProps = {
  companyId: number;
};

type Answers = {
  props: Pages.FreepadResourceEditorProps["answers"];
  saveButton: Pages.FreepadResourceEditorProps["saveAnswersButton"];
  onDraftSave: () => void;
};

const DEFAULT_LANGUAGE = "python3";

type Item = Pages.FreepadResourceEditorProps["answers"]["details"]["answerEditLanguageTabMenu"]["items"][0];
export const useGenerateAnswersProps = (args: UseGenerateAnswersProps): Answers => {
  const { t } = useTranslation();
  const client = getGraphqlClient();
  const { methods, selectedAnswerIndex } = Widget.useFreepadAnswerFormContext();

  const userId = Auth.useCurrentUid();
  const [currentLanguageTabIndex, setCurrentLanguageTabIndex] = React.useState<string>("0");
  const [isTranslateLoading, setIsTranslateLoading] = React.useState<boolean>(false);
  const question = FreepadResourceEditor.useQuestion();
  const templateCode = FreepadResourceEditor.useTemplateCode();
  const initialLanguage = FreepadResourceEditor.useInitialLanguage();
  const saveStatus = FreepadResourceEditor.useSaveStatus();

  const contentsField = useFieldArray({
    control: methods.control,
    name: `answers.${Number(selectedAnswerIndex)}.contents`,
  });
  const contents = methods.watch(`answers.${Number(selectedAnswerIndex)}.contents`);
  const selectedLanguageSet = React.useMemo(() => {
    if (contents && contents.length) {
      return new Set(contents.map(c => c.language));
    }
    return new Set();
  }, [contents]);

  const translateAnswer = React.useCallback(
    async (answer: FreepadAnswerForm.AnswersContentSchema, to: SupportLanguageValue): Promise<FreepadAnswerForm.AnswersContentSchema> => {
      const res = await Promise.all([
        client.TranslatePlainTextForFreepadResourceEditor({
          source: {
            from: translationLanguageMap[answer?.language],
            to: translationLanguageMap[to],
            body: answer.title,
          },
        }),
        client.TranslateMarkdownForFreepadResourceEditor({
          source: {
            from: translationLanguageMap[answer?.language],
            to: translationLanguageMap[to],
            body: answer.description,
          },
        }),
      ])
        .then(res => {
          Snackbar.notify({
            severity: "success",
            message: t("自動翻訳に成功しました。"),
          });
          return res;
        })
        .catch(() => {
          Snackbar.notify({
            severity: "error",
            message: t("自動翻訳に失敗しました。しばらくしてから再度お試しください。"),
          });
          return [];
        });

      return {
        title: res[0]?.translatePlainText ?? "",
        description: res[1]?.translateMarkdown ?? "",
        language: to,
      };
    },
    [client, t],
  );

  const getFormattedAnswers = (
    fields: FreepadAnswerForm.FreepadAnswersFormSchema,
    questionKey: Pick<Graphql.FreepadAnswerInput, "questionId" | "questionVersion">,
  ): Graphql.FreepadAnswerInput[] => {
    return fields.answers.map((answerField): Graphql.FreepadAnswerInput => {
      const content = {
        titleJa: "",
        titleEn: "",
        descriptionJa: "",
        descriptionEn: "",
      } satisfies Record<string, string>;
      answerField.contents.forEach(l => {
        if (l.language === "ja") {
          content.titleJa = l.title;
          content.descriptionJa = l.description;
        }

        if (l.language === "en") {
          content.titleEn = l.title;
          content.descriptionEn = l.description;
        }
      });
      return {
        id: answerField.id ?? 0,
        titleJa: content.titleJa,
        titleEn: content.titleEn,
        descriptionJa: content.descriptionJa,
        descriptionEn: content.descriptionEn,
        label: answerField.label,
        questionId: questionKey.questionId,
        questionVersion: questionKey.questionVersion,
        answerCodes: answerField.answerCodes.map(code => ({
          answerId: answerField.id ?? 0,
          body: code.body,
          runtime: code.runtime,
          id: code.id ?? 0,
        })),
        answerFlowCharts: answerField.answerFlowCharts.map(
          (chart): Graphql.FreepadAnswerFlowChartInput => ({
            id: chart.id ?? 0,
            body: chart.body,
            componentType: stringComponentTypeToGraphqlComponentTypeGraphql[chart.componentType],
            answerId: answerField.id ?? 0,
          }),
        ),
      };
    });
  };

  const handleSave: (isDraft: boolean) => SubmitHandler<FreepadAnswerForm.FreepadAnswersFormSchema> = React.useCallback(
    isDraft => fields => {
      if (question) {
        const answers = getFormattedAnswers(fields, {
          questionId: question.freepadQuestionId,
          questionVersion: question.version,
        });
        client
          .UpdateQuestionForFreepadResourceEditor({
            input: {
              id: question.freepadQuestionId,
              oldVersion: question.version,
              newVersion: question.version,
              companyId: args.companyId,
              employeeId: userId,
              variant: question.variant,
              difficulty: question.difficulty,
              isPrivate: question.isPrivate,
              status: "DRAFT",
              titleJa: question.titleJa,
              titleEn: question.titleEn,
              descriptionJa: question.descriptionJa,
              descriptionEn: question.descriptionEn,
              answers: answers,
              initialCodes: createInitialCodes(question.initialCodes),
              initialFlowCharts: createInitialFlowCharts(question.initialFlowCharts),
              tags: question.tags.map(t => t.name),
            },
          })
          .then(res => {
            FreepadResourceEditor.updateQuestion(res.updateFreepadQuestion);
            if (!isDraft) {
              FreepadResourceEditor.updateActiveStep(FreepadResourceEditor.STEPS.OPTIONAL);
            }
            Snackbar.notify({
              severity: "success",
              message: t("問題の一時保存が完了しました。"),
            });
          })
          .catch(() => {
            Snackbar.notify({
              severity: "error",
              message: t("問題の一時保存に失敗しました。しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
            });
          })
          .finally(() => {
            FreepadResourceEditor.updateSaveStatus("READY");
          });
      }
    },
    [args.companyId, client, question, t, userId],
  );
  const selectableLanguageItems: Item[] = [
    {
      text: "日本語",
      value: "ja",
      onClick: async () => {
        setIsTranslateLoading(true);
        const answer = methods.getValues(`answers.${Number(selectedAnswerIndex)}.contents.${Number(currentLanguageTabIndex)}`);
        const translated = await translateAnswer(answer, "ja");
        contentsField.append(translated);
        setIsTranslateLoading(false);
      },
      isLoading: isTranslateLoading,
    },
    {
      text: "English",
      value: "en",
      onClick: async () => {
        setIsTranslateLoading(true);
        const answer = methods.getValues(`answers.${Number(selectedAnswerIndex)}.contents.${Number(currentLanguageTabIndex)}`);
        const translated = await translateAnswer(answer, "en");
        contentsField.append(translated);
        setIsTranslateLoading(false);
      },
      isLoading: isTranslateLoading,
    },
  ];

  const nonSelectedLanguageItem = selectableLanguageItems.filter(item => !selectedLanguageSet.has(item.value as SupportLanguageValue));

  return {
    props: {
      flowChartSection: {},
      details: {
        answerEditLanguageTabMenu: {
          items: nonSelectedLanguageItem,
        },
        onLanguageTabChange: value => {
          setCurrentLanguageTabIndex(value);
        },
      },
      codeSection: {
        codeTemplate: templateCode,
      },
      baseLanguage: initialLanguage,
      defaultCodeBody: templateCode[defaultLanguageMap["ALGORITHM"] as keyof typeof templateCode],
      defaultRuntime: DEFAULT_LANGUAGE,
    },
    saveButton: {
      onClick: () => {
        FreepadResourceEditor.updateSaveStatus("LOADING");
        methods.handleSubmit(handleSave(false), () => FreepadResourceEditor.updateSaveStatus("READY"))();
      },
      loading: saveStatus === "LOADING",
    },
    onDraftSave: () => {
      FreepadResourceEditor.updateSaveStatus("LOADING");
      methods.handleSubmit(handleSave(true), () => FreepadResourceEditor.updateSaveStatus("READY"))();
    },
  };
};
