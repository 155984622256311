import * as z from "zod";

const useDifficulties = () => {
  return z.array(z.union([z.literal("EASY"), z.literal("MEDIUM"), z.literal("DIFFICULT")]));
};
export type DifficultySchema = z.infer<ReturnType<typeof useDifficulties>>;

export const useQuestionPackageSearchQuery = () => {
  const difficulties = useDifficulties();
  return z.object({
    difficulties,
    textFilter: z.string(),
  });
};

export type QuestionPackageSearchQuery = z.infer<ReturnType<typeof useQuestionPackageSearchQuery>>;

export const useSortFieldValue = () => {
  return z.union([z.literal("created-at-descending"), z.literal("created-at-ascending")]);
};
export type SortFieldValue = z.infer<ReturnType<typeof useSortFieldValue>>;
