import { App } from "@hireroo/app-store/essential/employee";
import { EmployeeNavigation } from "@hireroo/app-store/widget/e/EmployeeNavigation";
import * as Graphql from "@hireroo/graphql/client/urql";
import React from "react";

export type FetchNotificationListProps = {
  employeeId: string;
  companyId: number;
};

export const useFetchNotificationList = (args: FetchNotificationListProps) => {
  const appStatus = App.useStatus();
  const nextCursor = EmployeeNavigation.useNextCursor();
  const fetchStatus = EmployeeNavigation.useFetchStatus();
  /**
   * If there are a lot of Notifications, a large number of requests will fly in a short period of time,
   * and the re-rendering will run many times when saving them to the state,
   */
  const [isRelaxing, setRelax] = React.useState(false);
  const [result] = Graphql.useListEmployeeNotificationsQuery({
    variables: {
      companyId: args.companyId,
      employeeId: args.employeeId,
      /**
       * When there are more than 11 Notification Count Symbol, it becomes `10+` and does not count any more.
       *
       * In other words, if 11 messages can be retrieved, the user can recognize that there are more than 11 messages.
       */
      size: 11,
      cursor: nextCursor ?? null,
      withCount: true,
    },
    requestPolicy: "cache-and-network",
    pause: appStatus !== "INITIALIZED" || args.companyId === 0 || fetchStatus === "STOP" || isRelaxing,
  });

  React.useEffect(() => {
    if (result.data?.employeeNotifications) {
      setRelax(true);
      EmployeeNavigation.append(result.data?.employeeNotifications);
      window.setTimeout(() => {
        setRelax(false);
      }, 1500);
    }
  }, [result.data?.employeeNotifications]);
};
