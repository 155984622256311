import { secondsToTimeObject, TimeObject } from "@hireroo/formatter/time";
import * as React from "react";

export type CountUpTimerArgs = {
  fromStartAtSeconds: number;
};

export const useCountUpTimer = (args: CountUpTimerArgs): TimeObject => {
  const [currentTimeMilliseconds, setCurrentTimeMilliseconds] = React.useState(new Date().getTime());
  const diffInSeconds = React.useMemo(() => {
    return (currentTimeMilliseconds - args.fromStartAtSeconds * 1000) / 1000;
  }, [args.fromStartAtSeconds, currentTimeMilliseconds]);

  React.useEffect(() => {
    const timer = window.setInterval(() => {
      setCurrentTimeMilliseconds(new Date().getTime());
    }, 1000);
    return () => {
      window.clearInterval(timer);
    };
  }, []);
  return secondsToTimeObject(diffInSeconds, "HOURS");
};
