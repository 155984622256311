import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import * as z from "zod";

import { EvaluationMetricItem } from "./EvaluationMetricsForm";

export const useEvaluationMetricGroupFormSchema = () => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  return z.object({
    title: z
      .string()
      .min(1, { message: t("必須項目です") })
      .max(255, { message: t("最大255文字以内で入力してください。") }),
    description: z
      .string()
      .max(1000, { message: t2("MaxStringInput", { max: 1000 }) })
      .optional(),
    items: z.array(EvaluationMetricItem),
  });
};

export type EvaluationMetricGroupFormSchema = z.infer<ReturnType<typeof useEvaluationMetricGroupFormSchema>>;
