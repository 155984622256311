import { Widget } from "@hireroo/presentation";
import { ErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type NotificationBannerContainerProps = Widget.NotificationBannerProps;

const NotificationBannerContainer: React.FC<NotificationBannerContainerProps> = props => {
  return (
    <ErrorBoundary>
      <Widget.NotificationBanner {...props} />
    </ErrorBoundary>
  );
};

NotificationBannerContainer.displayName = "NotificationBannerContainer";

/**
 * NOTE:
 *
 * You can mount the `NotificationBannerContainer` directly, but it will be converted to a ReactNode, in which case the Layout component will have no way of knowing if there is a notification.
 * Therefore, we made it a function that can explicitly return null.
 */
export const useNotificationBanner = () => {
  const notificationBannerProps = useGenerateProps({});
  if (!notificationBannerProps) {
    return null;
  }
  return <NotificationBannerContainer {...notificationBannerProps} />;
};
