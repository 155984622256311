import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type OrderHistoryContainerProps = {};

const OrderHistoryContainer: React.FC<OrderHistoryContainerProps> = () => {
  const OrderHistoryProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.OrderHistory {...OrderHistoryProps} />
    </ErrorBoundary>
  );
};

OrderHistoryContainer.displayName = "OrderHistoryContainer";

export default withErrorBoundary(OrderHistoryContainer, {});
