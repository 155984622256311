import { useSnapshot } from "valtio";

import { state } from "./State";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = () => {
  const state = useSnapshotState();
  return !!state.entities;
};

export const useEntities = () => {
  const state = useSnapshotState();
  return state?.entities || [];
};

export const userReviewTarget = () => {
  if (!state.reviewTarget) {
    throw new Error("Please set review targe");
  }
  return state.reviewTarget;
};
