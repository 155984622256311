import Button, { ButtonProps } from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions, { DialogActionsProps } from "@mui/material/DialogActions";
import DialogContent, { DialogContentProps } from "@mui/material/DialogContent";
import DialogTitle, { DialogTitleProps } from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import * as React from "react";

type OptionalDialogProps = Omit<DialogProps, "open">;

type Kind = "POSITIVE" | "NEGATIVE";

export type YesButtonProps = Pick<ButtonProps, "disabled" | "children" | "color" | "autoFocus"> & { onClick?: () => void; kind?: Kind };
export type NoButtonProps = Pick<ButtonProps, "onClick" | "disabled" | "children" | "color" | "variant"> & { kind?: Kind };

export type BaseDialogProps = {
  title?: string;
  open: boolean;
  yesButton?: YesButtonProps;
  noButton?: NoButtonProps;
  disableEnter?: boolean;
  optionalDialog?: OptionalDialogProps;
  yesButtonTooltip?: Omit<TooltipProps, "children">;
};

const TooltipContentWrapper = styled("span")`
  margin-left: 8px;
`;

const BaseDialog: React.FC<React.PropsWithChildren<BaseDialogProps>> = props => {
  const { open, title, children } = props;
  const processing = React.useRef(false);

  React.useEffect(() => {
    return () => {
      if (!open && processing.current) {
        processing.current = false;
      }
    };
  }, [open]);

  const yesButton: YesButtonProps | undefined = React.useMemo(() => {
    if (props.yesButton === undefined) {
      return undefined;
    }

    if (props.yesButton.kind === undefined) {
      return { ...props.yesButton, kind: "POSITIVE" };
    }

    return props.yesButton;
  }, [props.yesButton]);

  const noButton: NoButtonProps | undefined = React.useMemo(() => {
    if (props.noButton === undefined) {
      return undefined;
    }

    if (props.noButton.kind === undefined) {
      return { ...props.noButton, kind: "NEGATIVE" };
    }

    return props.noButton;
  }, [props.noButton]);

  const yesButtonTooltip: Omit<TooltipProps, "children"> | undefined = props.yesButtonTooltip
    ? {
        ...props.yesButtonTooltip,
      }
    : undefined;

  const yesButtonProps: ButtonProps | undefined = yesButton
    ? {
        role: "yesbutton",
        variant: "contained",
        color: "secondary",
        autoFocus: true,
        ...props.yesButton,
        onClick: event => {
          event.stopPropagation();
          props.yesButton?.onClick?.();
        },
      }
    : undefined;

  const noButtonProps: ButtonProps | undefined = noButton
    ? {
        color: "secondary",
        role: "nobutton",
        ...props.noButton,
        onClick: event => {
          event.stopPropagation();
          props.noButton?.onClick?.(event);
        },
      }
    : undefined;

  const dialogProps: DialogProps = {
    ...props.optionalDialog,
    onClick: event => {
      event.stopPropagation();
      props.optionalDialog?.onClick?.(event);
    },
    onClose: (event, reason) => {
      // It is true that stopPropagation exists, but the Material UI type definition is wrong.
      (event as { stopPropagation?: () => void }).stopPropagation?.();
      props.optionalDialog?.onClose?.(event, reason);
    },
    onMouseDown: event => {
      event.stopPropagation?.();
    },
    open: open,
    onKeyDown: event => {
      // Disable preventDefault on enter key for element that uses
      // enter key as its functionality, such as textarea.
      if (props.disableEnter === true) {
        event.preventDefault();
        return;
      }

      if (event.key === "Enter") {
        event.preventDefault();
        if (processing.current) return;
        processing.current = true;
        props.yesButton?.onClick?.();
      }
    },
  };

  const dialogContentProps: DialogContentProps = {
    children,
  };

  const dialogTitleProps: DialogTitleProps = {
    children: title,
  };

  const dialogActionsProps: DialogActionsProps = {
    children,
  };

  const NoButton: JSX.Element | undefined = noButtonProps && <Button {...noButtonProps} />;
  const YesButton: JSX.Element | undefined =
    yesButtonProps &&
    (yesButtonTooltip ? (
      <Tooltip {...yesButtonTooltip}>
        <TooltipContentWrapper>
          <Button {...yesButtonProps} />
        </TooltipContentWrapper>
      </Tooltip>
    ) : (
      <Button {...yesButtonProps} />
    ));

  return (
    <Dialog {...dialogProps}>
      <DialogTitle {...dialogTitleProps} />
      <DialogContent {...dialogContentProps} />
      <DialogActions {...dialogActionsProps}>
        <Stack direction="row" spacing={1}>
          {yesButton?.kind === "NEGATIVE" ? (
            <>
              {YesButton}
              {NoButton}
            </>
          ) : (
            <>
              {NoButton}
              {YesButton}
            </>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

BaseDialog.displayName = "BaseDialog";

export default BaseDialog;
