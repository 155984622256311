import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateEmployeeMemberSettingsPropsArgs, useGenerateProps } from "./useGenerateProps";

export type EmployeeMemberSettingsContainerProps = GenerateEmployeeMemberSettingsPropsArgs;

const EmployeeMemberSettingsContainer: React.FC<EmployeeMemberSettingsContainerProps> = props => {
  const employeeMemberSettingsProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.EmployeeMemberSettings {...employeeMemberSettingsProps} />
    </ErrorBoundary>
  );
};

EmployeeMemberSettingsContainer.displayName = "EmployeeMemberSettingsContainer";

export default withErrorBoundary(EmployeeMemberSettingsContainer, {});
