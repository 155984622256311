import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type DetailContentItemProps = {
  id: string;
  title: string;
  Content: React.ReactNode;
};

const DetailContentItem: React.FC<DetailContentItemProps> = props => {
  return (
    <Stack direction="column">
      <Typography variant="caption" color="textSecondary" mb={1}>
        {props.title}
      </Typography>
      {props.Content}
    </Stack>
  );
};

DetailContentItem.displayName = "DetailContentItem";

export default DetailContentItem;
