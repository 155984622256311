import { Credential } from "@hireroo/app-store/essential/shared";
import { authExchange } from "@urql/exchange-auth";
import { makeOperation } from "urql";

export type AuthState = {
  token?: string;
};

export default authExchange<AuthState>({
  getAuth: async ({ authState }) => {
    if (!authState?.token) {
      const token = await Credential.api.getAuthToken();
      if (token) {
        return { token: `Bearer ${token}` };
      }
      return null;
    }
    return null;
  },
  addAuthToOperation: ({ authState, operation }) => {
    if (!authState || !authState.token) {
      return operation;
    }

    /**
     * FIXME addAuthToOperation cannot return a Promise, so if token has expired, it can be successfully retrieved from the next request.
     */
    Credential.getOrRefreshAuthTokenExpire();

    const fetchOptions =
      typeof operation.context.fetchOptions === "function" ? operation.context.fetchOptions() : operation.context.fetchOptions || {};

    return makeOperation(operation.kind, operation, {
      ...operation.context,
      fetchOptions: {
        ...fetchOptions,
        headers: {
          ...fetchOptions.headers,
          Authorization: authState.token,
          "app-version": APP_VERSION,
        },
      },
    });
  },
  didAuthError: error => {
    console.error(error);
    return false;
  },
});
