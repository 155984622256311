import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";

const { ProjectContentsViewerV3 } = ProjectTestReport;

import * as PrivateHelper from "./privateHelper";

export const startSubscribeHistoryCurrentSubmission = (projectId: number) => {
  const action = ProjectContentsViewerV3.createAction(projectId);
  return ProjectContentsViewerV3.subscribeCurrentSubmission(projectId, submission => {
    const tasks = submission.diffs.map(async diff => {
      const task1 = PrivateHelper.getSource(diff.fromLink);
      const task2 = PrivateHelper.getSource(diff.toLink);
      return Promise.all([task1, task2]).then(([fromRes, toRes]) => {
        action.setDiffState(diff, {
          from: fromRes,
          to: toRes,
        });
      });
    });
    Promise.all(tasks);
  });
};
