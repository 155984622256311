import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import type { LiveCoding } from "@hireroo/app-helper/hooks";
import { RemotesId } from "@hireroo/app-store/page/e/remotes_id";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useTranslation } from "@hireroo/i18n";
import { Pages } from "@hireroo/presentation";
import * as Sentry from "@sentry/browser";
import * as React from "react";

import FooterContainer from "../../../../widget/v2/e/Footer/Container";
import RemoteInterviewQuestionSearchAreaContainer from "../../../../widget/v2/e/RemoteInterviewQuestionSearchArea/Container";
import RemoteInterviewTutorialContainer from "../../../../widget/v2/shared/RemoteInterviewTutorial/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import { useAutoStartTutorial } from "./privateHelper";
import { useGenerateLeftSidePanelProps } from "./useGenerateLeftSidePanelProps";
import { useGenerateMainContentProps } from "./useGenerateMainContentProps";
import { useGenerateQuestionsTableFetchContainerProps } from "./useGenerateQuestionsTableFetchContainerProps";
import { useGenerateRightSidePanelProps } from "./useGenerateRightSidePanelProps";
import QuestionsTableFetchContainer from "./widget/QuestionsTable/FetchContainer";
import RemoteInterviewEmployeeHeaderContainer from "./widget/RemoteInterviewEmployeeHeader/Container";

export type GenerateRemoteInterviewForEmployeePropsArgs = {
  remoteId: string;
  collaborativeAction: LiveCoding.CollaborativeAction;
  collaborativeState: LiveCoding.CollaborativeState;
};

export const useGenerateProps = (args: GenerateRemoteInterviewForEmployeePropsArgs): Pages.RemoteInterviewForEmployeeProps => {
  const [forceClose, setForceClose] = React.useState(false);
  const loadingStatus = RemotesId.QuestionsTableStore.useLoadingStatus();
  const liveCodingId = RemotesId.useLiveCodingId();
  const activeSessionVariant = RemotesId.useActiveSessionRemoteQuestionVariant();
  const currentSessionId = RemotesId.useActiveSessionId();
  const client = getGraphqlClient();
  const { t } = useTranslation();
  const { setActiveStep } = Pages.useRemoteInterviewForEmployeeContext();
  const mainContentProps = useGenerateMainContentProps({
    collaborativeState: args.collaborativeState,
    collaborativeAction: args.collaborativeAction,
  });
  useAutoStartTutorial(activeSessionVariant);
  const rightSidePanelProps = useGenerateRightSidePanelProps({
    remoteId: args.remoteId,
  });
  const leftSidePanelProps = useGenerateLeftSidePanelProps();
  const questionsTableFetchContainerProps = useGenerateQuestionsTableFetchContainerProps({
    collaborativeAction: args.collaborativeAction,
    onSelectQuestion: () => {
      setForceClose(prev => !prev);
    },
  });

  const isEmptyQuestionVariant = RemotesId.useIsEmptyQuestionVariant();

  const enableLeftSidePanel = React.useMemo(() => {
    if (mainContentProps.tab.items.length === 0) {
      return false;
    }
    if (isEmptyQuestionVariant) {
      return false;
    }
    return true;
  }, [isEmptyQuestionVariant, mainContentProps.tab.items.length]);

  return {
    mode: activeSessionVariant === "EMPTY_CANVAS" || activeSessionVariant === "SYSTEM_DESIGN" ? "DRAWING" : "OTHER",
    layout: {
      NotificationBanner: null,
      Header: <RemoteInterviewEmployeeHeaderContainer remoteId={args.remoteId} collaborativeAction={args.collaborativeAction} />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      Tutorial: <RemoteInterviewTutorialContainer />,
    },
    leftSidePanel: leftSidePanelProps,
    mainContent: mainContentProps,
    rightSidePanel: rightSidePanelProps,
    enableLeftSidePanel: enableLeftSidePanel,
    questionSearchDialog: {
      forceClose,
      SearchArea: <RemoteInterviewQuestionSearchAreaContainer isLoading={loadingStatus === "LOADING"} />,
      Table: <QuestionsTableFetchContainer {...questionsTableFetchContainerProps} />,
      codeEditorButton: {
        onClick: () => {
          client
            .AddLiveCodingSession({
              input: {
                liveCodingId: liveCodingId,
                questionType: "EMPTY_PAD",
                questionId: 0,
                questionVersion: "",
                questionVariant: "EMPTY_PAD",
              },
            })
            .then(res => {
              setActiveStep("SELECT_QUESTION_TYPE");
              // Session Flows:
              // 1. Create new session
              // 2. Record new session ID to firebase realtime database(FRD) as "adds"
              // 3. Notify snack bar
              // 4. Record current active session ID to FRD as "outs"
              // 5. Record next session ID to FRD as "sels" (this session ID is included in response value)
              RemotesId.addSession(res.addLiveCodingSession);
              args.collaborativeAction.addSession(res.addLiveCodingSession.liveCodingSessionId);
              Snackbar.notify({
                severity: "success",
                message: t("セッションを追加しました。"),
              });
              if (currentSessionId) args.collaborativeAction.setOutSessionWrapper(currentSessionId);
              args.collaborativeAction.setSelectedSessionWrapper(res.addLiveCodingSession.liveCodingSessionId);
              RemotesId.updateActiveSessionId(res.addLiveCodingSession.liveCodingSessionId);
            })
            .catch(error => {
              Sentry.captureException(error);
              const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                error,
                t("セッションの追加に失敗しました。しばらくしてから再度お試しください。"),
              );
              Snackbar.notify({
                severity: "error",
                message: errorNotification.message,
              });
            })
            .finally(() => {
              setForceClose(prev => !prev);
            });
        },
      },
      drawingToolButton: {
        onClick: () => {
          client
            .AddLiveCodingSession({
              input: {
                liveCodingId: liveCodingId,
                questionType: "EMPTY_CANVAS",
                questionId: 0,
                questionVersion: "",
                questionVariant: "EMPTY_CANVAS",
              },
            })
            .then(res => {
              setActiveStep("SELECT_QUESTION_TYPE");
              // Session Flows:
              // 1. Create new session
              // 2. Record new session ID to firebase realtime database(FRD) as "adds"
              // 3. Notify snack bar
              // 4. Record current active session ID to FRD as "outs"
              // 5. Record next session ID to FRD as "sels" (this session ID is included in response value)
              RemotesId.addSession(res.addLiveCodingSession);
              args.collaborativeAction.addSession(res.addLiveCodingSession.liveCodingSessionId);
              Snackbar.notify({
                severity: "success",
                message: t("セッションを追加しました。"),
              });
              if (currentSessionId) args.collaborativeAction.setOutSessionWrapper(currentSessionId);
              args.collaborativeAction.setSelectedSessionWrapper(res.addLiveCodingSession.liveCodingSessionId);
              RemotesId.updateActiveSessionId(res.addLiveCodingSession.liveCodingSessionId);
            })
            .catch(error => {
              Sentry.captureException(error);
              const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                error,
                t("セッションの追加に失敗しました。しばらくしてから再度お試しください。"),
              );
              Snackbar.notify({
                severity: "error",
                message: errorNotification.message,
              });
            })
            .finally(() => {
              setForceClose(prev => !prev);
            });
        },
      },
    },
  };
};
