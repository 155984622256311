import MenuItem from "@mui/material/MenuItem";
import Select, { SelectProps } from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { Control, useController } from "react-hook-form";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&.MuiMenuItem-root": {
    "&.Mui-selected": {
      backgroundColor: theme.palette["Secondary/Shades"].p16,
    },
  },
}));

export type Item = {
  text: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
};

export type SelectFieldProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any>;
  items: Item[];
  defaultValue?: string;
} & Pick<SelectProps, "variant" | "color" | "className" | "onChange">;

const SelectField: React.FC<SelectFieldProps & { name: string }> = props => {
  const defaultValue: string | undefined = props.defaultValue || (props.items.length ? props.items[0].value : undefined);
  const { field } = useController({
    name: props.name,
    control: props.control,
    defaultValue: defaultValue,
  });
  const selectProps: SelectProps = {
    fullWidth: true,
    size: "small",
    ...field,
    variant: props.variant,
    className: props.className,
    color: props.color,
    onChange: (event, child) => {
      props.onChange?.(event, child);
      field.onChange(event, child);
    },
  };
  return (
    <Select {...selectProps}>
      {props.items.map(item => (
        <StyledMenuItem key={item.value} value={item.value} color={props.color}>
          {item.text}
        </StyledMenuItem>
      ))}
    </Select>
  );
};

SelectField.displayName = "SelectField";

export default SelectField;
