import type { AssessmentResourceEditorForm } from "@hireroo/validator";
import * as React from "react";

export type StepName = "TEST_QUESTION_SETUP" | "TEST_SETUP" | "REPORT_SETUP" | "TEST_INVITE_SETUP" | "CONFIRM";

type ActiveStep = number;

type SubmitValues = {
  TEST_QUESTION_SETUP: AssessmentResourceEditorForm.TestQuestionSetupFormSchema;
  TEST_SETUP: AssessmentResourceEditorForm.TestSetupFormSchema;
  REPORT_SETUP: AssessmentResourceEditorForm.ReportSetupFormSchema;
  TEST_INVITE_SETUP: AssessmentResourceEditorForm.TestInviteSetupFormSchema;
};

const StepNameToActiveStepMap: Record<StepName, ActiveStep> = {
  TEST_QUESTION_SETUP: 0,
  TEST_SETUP: 1,
  REPORT_SETUP: 2,
  TEST_INVITE_SETUP: 3,
  CONFIRM: 4,
};

const ActiveStepToStepNameMap: Record<ActiveStep, StepName> = {
  0: "TEST_QUESTION_SETUP",
  1: "TEST_SETUP",
  2: "REPORT_SETUP",
  3: "TEST_INVITE_SETUP",
  4: "CONFIRM",
};

const SortedActiveStepToStepNames: StepName[] = Object.values(ActiveStepToStepNameMap);

type StepStatusMap = Map<StepName, "READY" | "ERROR" | "VALID">;

export type LayoutController = {
  checkGoNextStep: () => Promise<boolean>;
};

type SubmitHandler = (fields: AssessmentResourceEditorForm.CreateAssessmentV2FormSchema) => void;

export type ContextValue = {
  stepName: StepName;
  activeStep: ActiveStep;
  setController: (stepName: StepName, controller: LayoutController) => void;
  goNextStep: () => Promise<void>;
  goPrevStep: () => Promise<void>;
  updateStep: (step: StepName) => Promise<void>;

  stepStatusMap: StepStatusMap;

  title: string;
  setTitle: (title: string) => void;

  submitValues: Partial<SubmitValues>;
  setSubmitValue: <T extends keyof SubmitValues>(stepName: T, value: SubmitValues[T] | undefined) => void;

  setSubmitHandler: (callback: SubmitHandler) => void;
  submit: () => void;
};

const NOOP = () => Promise.resolve();

export const AssessmentResourceEditorContext = React.createContext<ContextValue>({
  stepName: "TEST_QUESTION_SETUP",
  activeStep: 0,
  updateStep: NOOP,
  goPrevStep: NOOP,
  goNextStep: NOOP,
  setController: NOOP,
  stepStatusMap: new Map(),
  title: "",
  setTitle: NOOP,
  submitValues: {},
  setSubmitValue: NOOP,
  setSubmitHandler: NOOP,
  submit: NOOP,
});

export const useAssessmentResourceEditorV2Context = () => React.useContext(AssessmentResourceEditorContext);

export type AssessmentResourceEditorV2ProviderProps = {
  initialStepName?: StepName;
};

export const AssessmentResourceEditorV2Provider: React.FC<React.PropsWithChildren<AssessmentResourceEditorV2ProviderProps>> = props => {
  const [stepName, setStepName] = React.useState<StepName>(props.initialStepName || "TEST_QUESTION_SETUP");
  const controllerMap = React.useRef(new Map<StepName, LayoutController>());
  const [stepStatusMap, setStepStatusMap] = React.useState<StepStatusMap>(new Map());
  const [submitValues, setSubmitValues] = React.useState<Partial<SubmitValues>>({});
  const [title, setTitle] = React.useState("");
  const submitHandlerRef = React.useRef<SubmitHandler | null>(null);
  const updateStep = React.useCallback(async (newStep: StepName) => {
    const end = StepNameToActiveStepMap[newStep];
    const stepNames = SortedActiveStepToStepNames.slice(0, end);
    for await (const stepName of stepNames) {
      const controller = controllerMap.current.get(stepName);
      if (!controller) {
        throw new Error(`Invalid Step Name: ${stepName}`);
      }
      const canGo = await controller.checkGoNextStep();
      if (canGo) {
        setStepStatusMap(prev => {
          prev.set(stepName, "VALID");
          return new Map(prev);
        });
      } else {
        setStepStatusMap(prev => {
          prev.set(stepName, "ERROR");
          return new Map(prev);
        });
        setStepName(stepName);
        return;
      }
    }
    setStepName(newStep);
  }, []);

  const contextValue: ContextValue = {
    title,
    stepName,
    activeStep: StepNameToActiveStepMap[stepName],
    updateStep: updateStep,
    goPrevStep: async () => {
      const prevStepValue = StepNameToActiveStepMap[stepName] - 1;
      const newStep = ActiveStepToStepNameMap[prevStepValue];
      if (newStep) {
        setStepName(newStep);
      }
    },
    goNextStep: async () => {
      const nextStepValue = StepNameToActiveStepMap[stepName] + 1;
      const newStep = ActiveStepToStepNameMap[nextStepValue];
      updateStep(newStep);
    },
    setController: (stepName, controller) => {
      controllerMap.current.set(stepName, controller);
    },
    stepStatusMap,
    setTitle: title => setTitle(title),
    submitValues: submitValues,
    setSubmitValue: (stepName, value) => {
      setSubmitValues(prev => {
        return { ...prev, [stepName]: value };
      });
    },
    submit: () => {
      if (submitValues.TEST_QUESTION_SETUP && submitValues.TEST_SETUP && submitValues.REPORT_SETUP && submitValues.TEST_INVITE_SETUP) {
        submitHandlerRef.current?.({
          testQuestionSetup: submitValues.TEST_QUESTION_SETUP,
          testSetup: submitValues.TEST_SETUP,
          reportSetup: submitValues.REPORT_SETUP,
          testInviteSetup: submitValues.TEST_INVITE_SETUP,
        });
      }
    },
    setSubmitHandler: callback => {
      submitHandlerRef.current = callback;
    },
  };
  return <AssessmentResourceEditorContext.Provider value={contextValue} children={props.children} />;
};
