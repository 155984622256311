import getKeybindingsServiceOverride from "@codingame/monaco-vscode-keybindings-service-override";
import { MonacoEditorProps, MonacoEditorReactComp } from "@typefox/monaco-editor-react";
import { UserConfig } from "monaco-editor-wrapper";
import * as React from "react";

import * as SwapTheme from "../../../helpers/swap-theme";
import * as VscodeHelper from "../vscode-helper";

VscodeHelper.configureMonacoWorkers();

type EditorOptions = Exclude<MonacoEditorProps["userConfig"]["wrapperConfig"]["editorAppConfig"]["editorOptions"], undefined>;

type CodeResource = {
  uri: string;
  value: string;
  fileExt?: string;
};

export type ExtendedMonacoEditorProps = {
  code: CodeResource;
  lspServerUrl?: string;
  style?: MonacoEditorProps["style"];
  onLoad?: MonacoEditorProps["onLoad"];
  editorOptions?: Pick<EditorOptions, "readOnly" | "minimap" | "lineNumbers">;
};

const ExtendedMonacoEditor: React.FC<ExtendedMonacoEditorProps> = props => {
  // Since it has been confirmed to work, keep it as it is without using useEffect.
  SwapTheme.showVscodeTheme();
  const language = VscodeHelper.getLanguage(props.code.uri);
  const currentLanguageConfig = VscodeHelper.getEditorConfig(props.code.uri);
  const isLspSupported = VscodeHelper.isLspSupported(language);
  /**
   * The method of managing using the model cannot be used because the editor is not mounted (the strategy of monaco-editor-react does not apply).
   * The logic for remounting the editor is as follows:
   * @see https://github.com/TypeFox/monaco-languageclient/blob/main/packages/wrapper/src/wrapper.ts#L197
   * It is known that the editor is remounted due to the value, but doing so causes re-rendering on each input, bringing the cursor to the beginning and making it impossible to write code. Therefore, the initial state is maintained with state to prevent re-rendering even if the value is updated.
   *
   * modelを利用して管理する方法はエディタがマウントされないため、利用できない（monaco-editor-reactの戦略は通用しない）
   *
   * editorが再マウントされるロジックは以下の通り
   * @see https://github.com/TypeFox/monaco-languageclient/blob/main/packages/wrapper/src/wrapper.ts#L197
   *
   * value起因で再マウントされることがわかっているが、それを行うと入力のたびに再レンダリングが実行され、入力中のカーソルが先頭に来るため
   * コードがそもそも書けない状態になってしまう。そのため、開始の状態をstateで持ち、valueの更新が行われても再レンダリングされないようにする。
   */
  const [userConfig] = React.useState<UserConfig>({
    loggerConfig: {
      enabled: false,
      debugEnabled: false,
    },
    wrapperConfig: {
      serviceConfig: {
        userServices: {
          ...getKeybindingsServiceOverride(),
        },
        enableExtHostWorker: true,
        debugLogging: false,
      },
      editorAppConfig: {
        $type: "extended",
        codeResources: {
          main: {
            text: props.code.value,
            /**
             * NOTE monaco-editorをデバッグする際、このcodeResources.main.uriが非常に重要ならパメーターになるため、
             * デバッグ時は必ず確認するように。この値がmonaco-editor内ではcache keyとして利用されており、あらゆるデータの参照キーになっている
             */
            uri: props.code.uri,
          },
        },
        awaitExtensionReadiness: VscodeHelper.awaitExtensionReadiness,
        readOnly: props.editorOptions?.readOnly,
        editorOptions: {
          ...props.editorOptions,
          theme: "Monokai",
          language: language,
        },
        userConfiguration: {
          json: JSON.stringify(VscodeHelper.Def.defaultEditorSettingsJson),
        },
      },
    },
    languageClientConfig:
      isLspSupported && props.lspServerUrl
        ? {
            languageId: props.code.uri,
            options: {
              $type: "WebSocketUrl",
              url: props.lspServerUrl,
              startOptions: {
                onCall: () => {
                  console.log("Connected to socket.");
                },
                reportStatus: false,
              },
              stopOptions: {
                onCall: () => {
                  console.log("Disconnected from socket.");
                },
                reportStatus: false,
              },
            },
            clientOptions: {
              documentSelector: currentLanguageConfig.documentSelector,
            },
          }
        : undefined,
  });

  const monacoEditorReactCompProps: MonacoEditorProps = {
    userConfig,
    style: props.style,
    onLoad: wrapper => {
      const editor = wrapper.getEditor();
      const model = editor?.getModel();
      /**
       * LF = 0
       * CRLF = 1
       */
      model?.setEOL(0);
      props.onLoad?.(wrapper);
    },
    onError: console.error,
  };

  return <MonacoEditorReactComp {...monacoEditorReactCompProps} key={props.code.uri} />;
};

ExtendedMonacoEditor.displayName = "ExtendedMonacoEditor";

export default ExtendedMonacoEditor;
