import { useTranslation } from "@hireroo/i18n";
import EndInterview from "@hireroo/ui-assets/images/Interview/EndInterview.svg";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type Kind =
  | "COMPLETE_CANDIDATE_INTERVIEW"
  | "COMPLETE_INTERVIEW"
  | "ALL_QUESTION_SUBMITTED"
  | "FINISH_QUESTION"
  | "FINISH_DEMO"
  | "TEST_HAS_ALREADY_BEEN_COMPLETED"
  | "DEMO_HAS_ALREADY_BEEN_COMPLETED"
  | "TIMELIMIT_HAS_EXPIRED"
  | "FINISH_TEST";

type TextSet = {
  title: string;
  description: string;
};

const useTextSetMap = (kind: Kind): TextSet => {
  const { t } = useTranslation();
  const textMap: Record<Kind, TextSet> = {
    COMPLETE_CANDIDATE_INTERVIEW: {
      title: t("提出お疲れさまでした。"),
      description: t("以上でテストは終了です。企業様からのご連絡をお待ち下さい。"),
    },
    COMPLETE_INTERVIEW: {
      title: t("問題の提出が完了しました。"),
      description: t("お疲れ様でした！未提出の問題がある場合は次に進んでください。"),
    },
    ALL_QUESTION_SUBMITTED: {
      title: t("すべての問題の提出が完了しました。"),
      description: t("すべての解答が終了しました。「テストを終了する」ボタンから退出ください。お疲れさまでした。"),
    },
    FINISH_QUESTION: {
      title: t("問題の提出が完了しました。"),
      description: t(
        "未提出の問題がある場合は次の問題に進んでください。すべての問題の提出が終わった場合や途中で解答を終了する場合は、ヘッダーの「テストを終了する」ボタンから退出してください。",
      ),
    },
    FINISH_DEMO: {
      title: t("テスト（デモ）終了"),
      description: t("これでテスト（デモ）は終わりです。このタブを閉じて終了してください。"),
    },
    FINISH_TEST: {
      title: t("提出お疲れさまでした。"),
      description: t("以上でテストは終了です。企業様からのご連絡をお待ち下さい。"),
    },
    TEST_HAS_ALREADY_BEEN_COMPLETED: {
      title: t("テストにアクセスできませんでした。"),
      description: t("このテストは既に終了しています。お手数ですが不明点がある場合は、ヘルプセンターからお問い合わせください。"),
    },
    DEMO_HAS_ALREADY_BEEN_COMPLETED: {
      title: t("テスト（デモ）にアクセスできませんでした。"),
      description: t("このテスト（デモ）は存在しないか、既に終了している可能性があります。URLをお確かめいただき、再度お試しください。"),
    },
    TIMELIMIT_HAS_EXPIRED: {
      title: t("解答時間が終了しました。"),
      description: t("これでテストは終了となります。お疲れさまでした。企業様からの連絡をお待ち下さい。"),
    },
  };
  return textMap[kind];
};

export type TestAnnouncementProps = {
  kind: Kind;
};

const TestAnnouncement: React.FC<TestAnnouncementProps> = props => {
  const textSet = useTextSetMap(props.kind);
  return (
    <Box width="100vw" height="100%" bgcolor="background.default" justifyContent="center" alignItems="center" display="flex">
      <Box mx={10} justifyContent="center" alignItems="center" display="flex">
        <img src={EndInterview} width={300} alt="finish-notify" />
        <Box ml={2} />
        <Box display="flex" flexDirection="column" mb={1} mx={2}>
          <Typography variant="h4" gutterBottom>
            {textSet.title}
          </Typography>

          <Typography variant="body1" component="p" gutterBottom sx={{ lineHeight: 1.8 }}>
            {textSet.description}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

TestAnnouncement.displayName = "TestAnnouncement";

export default TestAnnouncement;
