import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import * as z from "zod";

import * as TimeLimitField from "./fields/TimeLimitField";

export const useReasonTypeSchema = () => {
  return z.enum(["SYSTEM_ERROR", "INTERVIEW_MISDELIVERY", "INSUFFICIENT_TIME_LIMIT", "OTHER"]);
};

export type ReasonTypeSchema = z.infer<ReturnType<typeof useReasonTypeSchema>>;

export const useEntityIdSchema = () => {
  return z.object({
    entityId: z.number(),
    entityType: z.enum(["CHALLENGE", "PROJECT", "QUIZ", "SYSTEM_DESIGN"]),
    timeLimitMinutes: z.number(),
  });
};
export type EntityIdSchema = z.infer<ReturnType<typeof useEntityIdSchema>>;

const ONE_YEAR_IN_MILLISECONDS = 31536000000; // 1000 * 60 * 60 * 24 * 365

type RetakeScreeningTestOptionalArgs = {
  maxMinutes: number;
};

export const useRetakeScreeningTest = (args?: RetakeScreeningTestOptionalArgs) => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const timelimit = TimeLimitField.useTimeLimitFieldSchema({ maxMinutes: args?.maxMinutes });
  const reasonType = useReasonTypeSchema();
  const entityId = useEntityIdSchema();

  return z.object({
    selectedEntityIds: z.array(entityId).min(1, { message: t("問題は必ず1問以上選択してください。") }),
    timeLimitMinutes: timelimit,
    willEndAt: z
      .date({ required_error: t("提出期限は必須項目です。"), invalid_type_error: t("提出期限は必須項目です。") })
      .min(new Date(), { message: t("テストの終了時間は現在時刻よりも後になるように設定してください。") })
      .max(new Date(Date.now() + ONE_YEAR_IN_MILLISECONDS), { message: t2("cannotSetTestWillEndAtMax", { max: 1, unit: t("年") }) }),
    reasonType: reasonType,
    reasonComment: z
      .string()
      .max(10000, { message: t2("ValidateMaxTextSizeMessage", { name: t("コメント"), size: 10000 }) })
      .optional(),
    agreement: z.boolean(),
  });
};

export type RetakeScreeningTestSchema = z.infer<ReturnType<typeof useRetakeScreeningTest>>;
