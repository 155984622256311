import { z } from "zod";

export const AudioDeviceIdSchema = z.string();

export type AudioDeviceId = z.infer<typeof AudioDeviceIdSchema>;

export const VideoDeviceIdSchema = z.string();

export type VideoDeviceId = z.infer<typeof VideoDeviceIdSchema>;

export const NoiseCancellationStatusSchema = z.enum(["ENABLED", "DISABLED"]);

export type NoiseCancellationStatus = z.infer<typeof NoiseCancellationStatusSchema>;
