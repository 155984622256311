import { proxy } from "valtio";
import { proxyMap } from "valtio/utils";

import type * as Types from "./types";

export const state = proxy<Types.State>({
  formValues: {
    step: 0,
    metricGroupId: null,
    comment: "",
    evaluations: [],
  },
  metricGroups: proxyMap(),
  metricGroupPagination: {
    hasNext: false,
    nextCursor: null,
    cursor: null,
  },
  reviewedExam: null,
  loadingStatus: "READY",
});
