import { App } from "@hireroo/app-store/essential/employee";
import { ScreeningsIdDetailStore } from "@hireroo/app-store/page/e/screenings_id_detail";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";
import { useParams } from "react-router-dom";

import ScreeningDetailContainer, { ScreeningDetailContainerProps } from "./Container";

export type ScreeningDetailFetchContainerProps = {};

const ScreeningDetailFetchContainer: React.FC<ScreeningDetailFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const { id } = useParams<{ id: string }>();
  const screeningId = `${id}`;
  const initialized = ScreeningsIdDetailStore.useInitialized();
  const [result, refresh] = Graphql.useGetScreeningForScreeningDetailQuery({
    variables: {
      screeningId: screeningId,
      invitationCode: null,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "cache-and-network",
  });

  React.useEffect(() => {
    if (result.data?.screening) {
      ScreeningsIdDetailStore.updateScreening(result.data.screening);
    }
  }, [result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: ScreeningDetailContainerProps = {
    refresh: refresh,
  };

  return <ScreeningDetailContainer {...containerProps} />;
};

ScreeningDetailFetchContainer.displayName = "ScreeningDetailFetchContainer";

export default withErrorBoundary(ScreeningDetailFetchContainer, {});
