import { Role } from "@hireroo/app-store/essential/employee";
import { RemoteInterviewOverview } from "@hireroo/app-store/widget/e/RemoteInterviewOverview";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateCurrentOriginUrl } from "@hireroo/router/api";

import { useGenerateAccessPermissionDialogProps } from "./useGenerateAccessPermissionDialogProps";
import { useGenerateTagDialogProps } from "./useGenerateTagDialogProps";

type AuthorizedMemberAndGroup = Widget.RemoteInterviewOverviewProps["info"]["remote"]["authorizedMemberAndGroups"];
export type GenerateInfoPropsArgs = {};

export const useGenerateInfoProps = (_args: GenerateInfoPropsArgs): Widget.RemoteInterviewOverviewProps["info"] => {
  const { t } = useTranslation();
  const matchingRole = Role.useMatchingRole();
  const interview = RemoteInterviewOverview.useInterview();
  const accessPermissionDialogProps = useGenerateAccessPermissionDialogProps({});
  const tagDialog = useGenerateTagDialogProps({});

  const interviewAuthorizedMember = interview.viewers.map((viewer): AuthorizedMemberAndGroup[0] => {
    if (viewer.__typename === "EmployeeGroup") {
      return {
        key: viewer.id,
        kind: "GROUP",
        label: viewer.groupName,
      };
    } else if (viewer.__typename === "User") {
      return {
        key: viewer.uid,
        photoUrl: viewer.photoUrl,
        kind: "MEMBER",
        label: viewer.displayName || viewer.email,
      };
    } else {
      throw new Error("Unknown reviewer type");
    }
  });
  const hasEnded = interview.status !== "CREATED" && interview.status !== "STARTED";

  return {
    remote: {
      candidateName: interview.candidateName,
      employeeName: interview.creator?.displayName || interview.creator?.email || t("不明"),
      invitationLink: generateCurrentOriginUrl("/c/remotes/:id", { pathParams: { id: interview.id } }),
      authorizedMemberAndGroups: interviewAuthorizedMember,
      isPublic: interview.isPublic,
      tags: interview.tags.map(t => t.name),
      hasEnded: hasEnded,
    },
    copyButton: {
      onCopy: () => {
        Snackbar.notify({
          severity: "info",
          message: t("クリップボードにコピーされました"),
        });
      },
      buttonWithTooltip: {
        disabled: hasEnded || !matchingRole.ADMIN_OR_MANAGER.matched,
        title: hasEnded ? t("インタビューはすでに終了しているためコピーできません。") : matchingRole.ADMIN_OR_MANAGER.messageOnUnmatched,
      },
    },
    accessPermissionDialog: accessPermissionDialogProps,
    tagDialog: tagDialog,
  };
};
