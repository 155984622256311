import { useTranslation } from "@hireroo/i18n";
import ForwardIcon from "@mui/icons-material/Forward";
import TrendingUpOutlined from "@mui/icons-material/TrendingUpOutlined";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import ResultItem, { ResultItemProps } from "./parts/ResultItem/ResultItem";

const StyledForwardIcon: React.FC = () => {
  return (
    <>
      <svg width="0" height="0" viewBox="0 0 10 10" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.59332 9.16666L10 5L5.59332 0.833328V3.02657H0V6.68218H5.59332V9.16666Z"
          fill="url(#paint0_linear_6679_13286)"
        />
        <defs>
          <linearGradient id="paint0_linear_6679_13286" x1="4.64286" y1="5.22006" x2="-1.89281e-07" y2="5.22006" gradientUnits="userSpaceOnUse">
            <stop stopColor="#838DFF" stopOpacity="0.32" />
            <stop offset="1" stopColor="#838DFF" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
      <ForwardIcon sx={{ fill: "url(#paint0_linear_6679_13286)" }} />
    </>
  );
};

const StyledListItem = styled(ListItem)(() => ({
  padding: 0,
}));

const StyledStack = styled(Stack)(({ theme }) => ({
  borderRadius: "8px",
  padding: "8px 12px",
  justifyContent: "left",
  alignItems: "center",
  width: "100%",
  overflow: "hidden",
  backgroundColor: theme.palette.grey[800],
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  position: "relative",
  color: theme.palette.text.secondary,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  overflow: "hidden",
  marginRight: "8px",
  minWidth: 0,
  svg: {
    width: 18,
    height: 18,
  },
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.text.secondary,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  overflow: "hidden",
}));

const IconMap = {
  TRENDING_UP: <TrendingUpOutlined />,
} as const;

export type ResultListItemProps = {
  icon: keyof typeof IconMap;
  previous?: ResultItemProps;
  current?: ResultItemProps;
};

const ResultListItem: React.FC<ResultListItemProps> = props => {
  const { t } = useTranslation();
  const Icon = IconMap[props.icon];
  return (
    <StyledListItem alignItems="flex-start">
      <StyledListItemIcon>{Icon}</StyledListItemIcon>
      <StyledListItemText>
        <StyledStack direction="row" divider={<StyledForwardIcon />} spacing={1} alignItems={"flex-start"}>
          {props.previous && <ResultItem {...props.previous} />}
          {props.current && <ResultItem {...props.current} />}
          {!props.previous && !props.current && (
            <Typography variant="body2" noWrap>
              {t("テスト情報がありません。")}
            </Typography>
          )}
        </StyledStack>
      </StyledListItemText>
    </StyledListItem>
  );
};

ResultListItem.displayName = "ResultListItem";

export default ResultListItem;
