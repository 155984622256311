import { useTranslation } from "@hireroo/i18n";
import { ErrorMessage } from "@hookform/error-message";
import { Warning } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { useAlgorithmTestCasesFormContext } from "../Context";

export type TestCaseErrorMessageProps = {};

const useCorrectnessErrorMessages = () => {
  const { t } = useTranslation();
  const { method } = useAlgorithmTestCasesFormContext();
  const { errors } = method.formState;

  const errorMessages: string[] = [];
  if (method.formState.errors.correctnessTestCases) {
    const maxLength = method.getValues("correctnessTestCases").data.length;
    for (let i = 0; i < maxLength; i++) {
      const testCase = errors.correctnessTestCases?.data?.[i];
      if (testCase?.title_ja || testCase?.description_ja) {
        errorMessages.push(t("日本語タブの入力に不備があります。"));
      }
      if (testCase?.title_en || testCase?.description_en) {
        errorMessages.push(t("Englishタブの入力に不備があります。"));
      }
    }
  }
  return errorMessages;
};

const TestCaseErrorMessage: React.FC<TestCaseErrorMessageProps> = _props => {
  const { t } = useTranslation();
  const { method } = useAlgorithmTestCasesFormContext();
  const { errors } = method.formState;
  const correctnessErrorMessages = useCorrectnessErrorMessages();

  return (
    <>
      <ErrorMessage
        errors={errors}
        name="correctnessTestCases"
        render={() => (
          <Box display="flex" flexDirection="column">
            <Box display="flex" gap={1}>
              <Warning color="error" fontSize="small" />
              <Typography color="error" variant="body2">
                {t("テストケース（正解率）の入力に不備があります。")}
              </Typography>
            </Box>

            <Box mt={1}>
              {correctnessErrorMessages.map((errorMessage, i) => (
                <Box key={`${errorMessage}${i}`}>
                  <Typography variant="caption" color="error">
                    {errorMessage}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      />

      <ErrorMessage
        errors={errors}
        name="performanceTestCases"
        render={() => (
          <Box display="flex" gap={1}>
            <Warning color="error" fontSize="small" />
            <Typography color="error" variant="body2">
              {t("テストケース（パフォーマンス）の入力に不備があります。")}
            </Typography>
          </Box>
        )}
      />
    </>
  );
};

TestCaseErrorMessage.displayName = "TestCaseErrorMessage";

export default TestCaseErrorMessage;
