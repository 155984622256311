import { QuestionsPackages } from "@hireroo/app-store/page/e/questions_packages";
import { useLanguageCode, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as React from "react";

export type GenerateQuestionPackageTablePropsArgs = {};

export const useGenerateProps = (_args: GenerateQuestionPackageTablePropsArgs): Widget.QuestionPackageTableProps => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const res = QuestionsPackages.useResponse();
  const pager = QuestionsPackages.usePager();
  const lang = useLanguageCode();
  const navigate = useTransitionNavigate();

  const rows = React.useMemo((): Widget.QuestionPackageTableProps["rows"] => {
    return res.packages.map((questionPackage): Widget.QuestionPackageTableProps["rows"][number] => {
      return {
        id: questionPackage.id,
        href: generatePath("/e/questions/packages/:id/detail", {
          pathParams: {
            id: questionPackage.questionPackageId,
          },
        }),
        onClick: () => {
          navigate("/e/questions/packages/:id/detail", {
            pathParams: {
              id: questionPackage.questionPackageId,
            },
          });
        },
        meta: {
          title: resolveLanguage(questionPackage, lang, "title"),
          description: resolveLanguage(questionPackage, lang, "description"),
        },
        items: [
          {
            label: t("難易度"),
            value: {
              kind: "DIFFICULTY",
              difficulty: questionPackage.difficulty,
            },
          },
          {
            label: t("問題数"),
            value: {
              kind: "NUMBER_WITH_UNIT",
              num: questionPackage.questionCount,
              unit: t("問"),
            },
          },
        ],
      };
    });
  }, [lang, res.packages, t, navigate]);

  const sortOptions = React.useMemo((): Widget.QuestionPackageTableProps["sortField"]["options"] => {
    return [
      {
        displayName: t("作成日時が古い順"),
        value: QuestionsPackages.SortFields.CREATED_AT_ASCENDING,
      },
      {
        displayName: t("作成日時が新しい順"),
        /**
         * @example 2024/02/01 -> 2024/01/31 -> 2024/01/30
         */
        value: QuestionsPackages.SortFields.CREATED_AT_DESCENDING,
      },
    ];
  }, [t]);

  const isValidOptionValue = (value: string): value is QuestionsPackages.SortFieldValue => {
    return sortOptions.map(o => o.value).includes(value);
  };

  return {
    rows: rows,
    resultText: t2("SearchResultCount", { count: res.count }),
    sortField: {
      options: sortOptions,
      defaultValue: pager.sortFieldValue,
      onChange: value => {
        if (isValidOptionValue(value)) {
          QuestionsPackages.updateSortField(value);
        }
      },
    },
    pagination: {
      count: res.count,
      rowsPerPage: pager.size,
      onRowsPerPageChange: event => {
        const newSize = Number(event.target.value);
        QuestionsPackages.updatePageSize(newSize);
      },
      page: pager.page,
      onPageChange: (_, newPage) => {
        QuestionsPackages.updatePage(newPage);
      },
    },
  };
};
