import { type SupportLanguageValue, useTranslation } from "@hireroo/i18n";
import { QuizQuestionForm } from "@hireroo/validator";
import AddCircle from "@mui/icons-material/AddCircle";
import ListIcon from "@mui/icons-material/List";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import EvaluationListItem, { EvaluationListItemProps } from "./EvaluationListItem";

export type EvaluationListProps = {
  language: SupportLanguageValue;
};

const EvaluationList: React.FC<EvaluationListProps> = props => {
  const theme = useTheme();
  const { t } = useTranslation();
  const method = useFormContext<QuizQuestionForm.QuizQuestionFormSchema>();
  const evaluations = useFieldArray({
    control: method.control,
    name: "evaluations",
  });
  const addButtonProps: ButtonProps = {
    onClick: () => {
      const selectedLanguages = method.getValues("selectedLanguages");
      evaluations.append({
        content: {
          ...(selectedLanguages.find(({ value }) => value === "ja") && { ja: { title: "" } }),
          ...(selectedLanguages.find(({ value }) => value === "en") && { en: { title: "" } }),
        },
      });
    },
  };
  return (
    <Box>
      <Stack direction="row" spacing={2} alignItems="center">
        <ListIcon fontSize="small" sx={{ color: theme.palette.common.white }} />
        <Typography mr={0.5} variant="subtitle1" sx={{ fontWeight: "bold" }}>
          {t("評価基準を追加する")}
        </Typography>
      </Stack>
      <Box mt={2} px={2}>
        <List>
          <Stack spacing={4} divider={<Divider />}>
            {evaluations.fields.map((field, index) => {
              const evaluationListItemProps: EvaluationListItemProps = {
                index,
                language: props.language,
                deleteButton: {
                  onClick: () => {
                    evaluations.remove(index);
                  },
                },
              };
              return <EvaluationListItem key={`evaluation-item-${field.id}`} {...evaluationListItemProps} />;
            })}
          </Stack>
        </List>
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={1}>
        <Button {...addButtonProps} startIcon={<AddCircle />}>
          {t("評価基準を追加する")}
        </Button>
      </Box>
    </Box>
  );
};

EvaluationList.displayName = "EvaluationList";

export default EvaluationList;
