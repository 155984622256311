import { useSpotStatusMap } from "@hireroo/app-definition/interview";
import { ScreeningsTestsStore } from "@hireroo/app-store/page/e/screenings_tests";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { formatScore } from "@hireroo/formatter/score";
import * as Graphql from "@hireroo/graphql/client/graphql-request";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useLanguageCode, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateHelpCenterUrl } from "@hireroo/router/api";
import * as Sentry from "@sentry/browser";
import * as React from "react";

export type GenerateCsvDownloadPropsArgs = {};

type FilterDisplayItem = Widget.CsvDownloadProps["content"]["filterDisplay"]["items"][0];
type FieldItem = Widget.CsvDownloadProps["fields"][0];

export const useGenerateProps = (_args: GenerateCsvDownloadPropsArgs): Widget.CsvDownloadProps => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const csvDownloadState = ScreeningsTestsStore.useCsvDownload();
  const creatorMap = ScreeningsTestsStore.useCreatorMap();
  const client = getGraphqlClient();
  const listParams = ScreeningsTestsStore.useListParams();
  const filters = ScreeningsTestsStore.useFilters();
  const spotStatusMap = useSpotStatusMap();
  const count = ScreeningsTestsStore.useCount();
  const languageCode = useLanguageCode();
  const screeningMap = ScreeningsTestsStore.useScreeningMap();
  const variables = ScreeningsTestsStore.useVariables();

  const downloadUrl = csvDownloadState.downloadUrl;

  const downloadStatusMap: Record<"csv" | "excel", Record<ScreeningsTestsStore.DownloadStatus, string>> = {
    excel: {
      READY: t("Excelをダウンロードする"),
      PENDING: t("Excelのダウンロード中"),
      FAILED: t("Excelのダウンロードを再実行する"),
      SUCCESS: t("Excelのダウンロードを再実行する"),
    },
    csv: {
      READY: t("CSVをダウンロードする"),
      PENDING: t("CSVのダウンロード中"),
      FAILED: t("CSVのダウンロードを再実行する"),
      SUCCESS: t("CSVのダウンロードを再実行する"),
    },
  };

  const rankFilterDisplayItem = React.useMemo((): FilterDisplayItem | undefined => {
    if (filters.rankEvaluations.length === 0) {
      return;
    }
    return {
      label: t("ランク"),
      value: filters.rankEvaluations.join(", "),
    };
  }, [filters.rankEvaluations, t]);

  const scoreFilterDisplayItem = React.useMemo((): FilterDisplayItem | undefined => {
    if (typeof filters.minTotalScore === "number" || typeof filters.maxTotalScore === "number") {
      return {
        label: t("スコア"),
        value: `${formatScore(filters.minTotalScore ?? 0)}% ~ ${formatScore(filters.maxTotalScore ?? 1)}%`,
      };
    }
    return;
  }, [filters.maxTotalScore, filters.minTotalScore, t]);

  // TODO: Remove deduplication part when the relation between screening and variable becomes many to many
  // Deduplicate variables by label and then map them to field items
  const variableFieldItems = React.useMemo((): FieldItem[] => {
    const uniqueSet = new Set<ScreeningsTestsStore.ScreeningVariables["variables"][0]["label"]>();
    return variables.reduce<FieldItem[]>((fieldItems, variableField) => {
      if (!uniqueSet.has(variableField.label)) {
        uniqueSet.add(variableField.label);
        fieldItems.push({
          name: variableField.label,
          value: `$.variable-${variableField.label}`,
        });
      }
      return fieldItems;
    }, []);
  }, [variables]);

  const exportData = React.useCallback(
    (exportFileType: Graphql.ExportFileType) => {
      ScreeningsTestsStore.DownloadAction.updateStatus("PENDING");
      ScreeningsTestsStore.DownloadAction.updateExportFileType(exportFileType);
      return client
        .ExportTestResultTable({
          jsonQueries: csvDownloadState.fields.map(field => {
            return {
              columnName: field.name,
              path: field.value,
            };
          }),
          language: {
            en: Graphql.ExportLanguage.En,
            ja: Graphql.ExportLanguage.Ja,
          }[languageCode],
          fileType: exportFileType,
          filters: {
            ...listParams.filters,
            suspiciousDegrees: listParams.filters.suspiciousDegrees.slice(),
            candidateEmail: listParams.filters.name,
            candidateName: listParams.filters.name,
            minTotalScore: null,
            maxTotalScore: null,
            minCustomScore: listParams.filters.minCustomScore,
            maxCustomScore: listParams.filters.maxCustomScore,
            createdByList: [...listParams.filters.createdByList],
            statuses: [...listParams.filters.statuses],
            tagNames: [...listParams.filters.tagNames],
            rankEvaluations: [...listParams.filters.rankEvaluations],
            screeningIds: [...listParams.filters.screeningIds],
          },
        })
        .then(res => {
          ScreeningsTestsStore.DownloadAction.updateDownloadId(res.exportTestResultTable.id);
          const messageMap: Record<Graphql.ExportFileType, string> = {
            [Graphql.ExportFileType.Csv]: t("CSVのダウンロードを開始しました"),
            [Graphql.ExportFileType.Excel]: t("Excelのダウンロードを開始しました"),
          };
          Snackbar.notify({
            severity: "success",
            message: messageMap[exportFileType],
          });
        })
        .catch(error => {
          ScreeningsTestsStore.DownloadAction.updateStatus("FAILED");
          Sentry.captureException(error);
        });
    },
    [client, languageCode, listParams.filters, csvDownloadState.fields, t],
  );

  return {
    open: csvDownloadState.open,
    onCloseDialog: () => {
      ScreeningsTestsStore.DownloadAction.closeDownloadDialog();
    },
    infoButton: {
      href: generateHelpCenterUrl(languageCode, "EXPORT_CSV"),
      target: "_blank",
    },
    downloadButtonGroup: {
      title: ((): string => {
        if (csvDownloadState.status === "PENDING") {
          return t("データの出力処理が実行中です");
        }
        if (csvDownloadState.fields.length === 0) {
          return t("ダウンロードする項目を選んでください");
        }

        return "";
      })(),
      disabled: csvDownloadState.status === "PENDING" || csvDownloadState.fields.length === 0,
      loading: csvDownloadState.status === "PENDING",
      items: [
        {
          children:
            csvDownloadState.exportFileType === Graphql.ExportFileType.Excel
              ? downloadStatusMap.excel[csvDownloadState.status]
              : downloadStatusMap.excel.READY,
          onClick: () => {
            exportData(Graphql.ExportFileType.Excel);
          },
        },
        {
          children:
            csvDownloadState.exportFileType === Graphql.ExportFileType.Csv
              ? downloadStatusMap.csv[csvDownloadState.status]
              : downloadStatusMap.csv.READY,
          onClick: () => {
            exportData(Graphql.ExportFileType.Csv);
          },
        },
      ],
    },
    content: {
      disabled: count === 0 || csvDownloadState.status === "PENDING",
      filterDisplay: {
        resultLabel: t2("SearchResultCount", { count: count }),
        items: [
          filters.name.length && {
            label: t("タイトル"),
            value: filters.name,
          },
          filters.statuses.length && {
            label: t("テストステータス"),
            value: filters.statuses.map(status => spotStatusMap[status]).join(", "),
          },
          filters.result && {
            label: t("結果"),
            value: filters.result ? t("合格") : t("不合格"),
          },
          filters.createdByList.length && {
            label: t("テスト作成者"),
            value: (() => {
              const nameList = filters.createdByList.map(createdBy => creatorMap[createdBy]?.displayName).flatMap(v => (v ? [v] : []));
              if (nameList.length === 1) {
                return nameList[0];
              }
              // If more than 1 person, use abbreviations.
              return `${nameList[0]} ... ${t2("NumberOfOtherPerson", { num: nameList.length - 1 })}`;
            })(),
          },
          filters.tagNames.length && {
            label: t("タグ"),
            value: filters.tagNames.join(", "),
          },
          filters.screeningIds.length && {
            label: t("コーディングテスト"),
            value: filters.screeningIds
              .map(screeningId => {
                return screeningMap.get(screeningId)?.name ?? "";
              })
              .join(", "),
          },
          scoreFilterDisplayItem,
          rankFilterDisplayItem,
        ].flatMap(v => (v ? [v] : [])),
      },
    },
    onChangeSelectedFields: React.useCallback((fields: { name: string; value: string }[]) => {
      ScreeningsTestsStore.DownloadAction.updateExportFields(fields);
    }, []),
    fields: [
      { name: "ID", value: "$.ID" },
      { name: `${t("タイトル")}`, value: "$.Title" },
      { name: `${t("合否")}`, value: "$.IsPassed" },
      { name: `${t("ステータス")}`, value: "$.Status" },
      { name: `${t("トータルスコア")}`, value: "$.TotalScore" },
      { name: `${t("ランク")}`, value: "$.Rank" },
      { name: `${t("分母")}`, value: "$.NumSubset" },
      { name: `${t("評価コメント")}`, value: "$.EvaluationComment" },
      { name: `${t("候補者名")}`, value: "$.CandidateName" },
      { name: `${t("候補者メールアドレス")}`, value: "$.CandidateEmail" },
      { name: `${t("テスト作成者")}`, value: "$.TestCreateUser.DisplayName" },
      { name: `${t("問題数")}`, value: "$.NumberOfQuestion" },
      { name: `${t("制限時間")}`, value: "$.TimeLimit" },
      { name: `${t("提出期限")}`, value: "$.WillEndAt" },
      { name: `${t("面接官メモ")}`, value: "$.InterviewerMemo" },
      { name: `${t("候補者へのメッセージ")}`, value: "$.MessageForCandidate" },
      { name: `${t("公開設定")}`, value: "$.IsPublic" },
      { name: `${t("レビュワー")}`, value: "$.Reviewers" },
      { name: `${t("招待方法")}`, value: "$.InvitationMethod" },
      { name: `${t("招待言語")}`, value: "$.InvitationLanguage" },
      // When selecting, only one "recommended comment" can be selected, but a maximum of three data can be retrieved.
      {
        name: `${t("推薦コメント")}`,
        group: {
          [t2("RecommendationCommentNumber", { num: 1 })]: "$.Recommendations[0].Comment",
          [t2("RecommendationCommentNumber", { num: 2 })]: "$.Recommendations[1].Comment",
          [t2("RecommendationCommentNumber", { num: 3 })]: "$.Recommendations[2].Comment",
        },
      },
      { name: `${t("評価日時")}`, value: "$.EvaluatedAt" },
      { name: `${t("期限切れ日時")}`, value: "$.ExpiredAt" },
      { name: `${t("開始日時")}`, value: "$.WillStartAt" },
      { name: `${t("作成日時")}`, value: "$.CreatedAt" },
      { name: `${t("更新日時")}`, value: "$.UpdatedAt" },
      { name: `${t("終了日時")}`, value: "$.DidEndAt" },
      ...variableFieldItems,
    ],
    defaultSelectedFields: [
      { name: "ID", value: "$.ID" },
      { name: `${t("タイトル")}`, value: "$.Title" },
      { name: `${t("合否")}`, value: "$.IsPassed" },
      { name: `${t("トータルスコア")}`, value: "$.TotalScore" },
      { name: `${t("ランク")}`, value: "$.Rank" },
      { name: `${t("分母")}`, value: "$.NumSubset" },
      { name: `${t("ステータス")}`, value: "$.Status" },
      { name: `${t("評価コメント")}`, value: "$.EvaluationComment" },
      { name: `${t("候補者名")}`, value: "$.CandidateName" },
      { name: `${t("候補者メールアドレス")}`, value: "$.CandidateEmail" },
      { name: `${t("テスト作成者")}`, value: "$.TestCreateUser.DisplayName" },
      { name: `${t("問題数")}`, value: "$.NumberOfQuestion" },
    ],
    downloadResultDialog: downloadUrl
      ? {
          title: t("データの出力処理が完了しました"),
          open: !!downloadUrl,
          url: downloadUrl,
          onClose: () => {
            ScreeningsTestsStore.DownloadAction.updateDownloadUrl(null);
          },
        }
      : undefined,
  };
};
