import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";

export const useRemoteStatusMap = (): Record<Graphql.RemoteStatus, string> => {
  const { t } = useTranslation();
  return {
    [Graphql.RemoteStatus.Unknown]: t("ステータス不明"),
    [Graphql.RemoteStatus.Created]: t("作成済み"),
    [Graphql.RemoteStatus.Started]: t("実施中"),
    [Graphql.RemoteStatus.Completed]: t("実施済み"),
    [Graphql.RemoteStatus.Evaluated]: t("評価済み"),
  };
};
