import {
  extractQuestionTitle,
  generateCompletedEntities,
  generateEntityKey,
  generateNormalizedEntity,
  NormalizedEntity,
} from "@hireroo/app-helper/normalizer";
import { DemosIdStore } from "@hireroo/app-store/page/c/demos_id";
import { InterviewNavigation } from "@hireroo/app-store/widget/c/InterviewNavigation";
import { ChallengeCodingEditor } from "@hireroo/app-store/widget/shared/ChallengeCodingEditor";
import { ProjectCodingEditorV3 } from "@hireroo/app-store/widget/shared/ProjectCodingEditorV3";
import { QuizCodingEditor } from "@hireroo/app-store/widget/shared/QuizCodingEditor";
import { SystemDesignCodingEditor } from "@hireroo/app-store/widget/shared/SystemDesignCodingEditor";
import { TestTimelimit } from "@hireroo/app-store/widget/shared/TestTimelimit";
export const startSubscribeOnChangeDemo = () => {
  return DemosIdStore.subscribeOnChangeDemo(demo => {
    InterviewNavigation.clear();
    InterviewNavigation.initialize();
    TestTimelimit.clear();
    TestTimelimit.initialize({
      didStartAtSeconds: demo.createdAtSeconds ?? 0,
      timeLimitSeconds: demo.timeLimitSeconds ?? 0,
      willEndAtSeconds: (demo.createdAtSeconds ?? 0) + (demo.timeLimitSeconds ?? 0),
    });
    const completedEntities = generateCompletedEntities(demo.entities);
    const completedEntityKeySet = new Set(completedEntities.map(entity => generateEntityKey(entity)));
    demo.entities.forEach(entity => {
      switch (entity.__typename) {
        case "ChallengeEntity": {
          ChallengeCodingEditor.initialize(entity, {
            enableWebSearch: demo.allowWebSearch,
            enableChatGPT: demo.allowChatGPT,
            enableHint: true, // NOTE: demo will always have hint enabled
          });
          break;
        }
        case "SystemDesignEntity": {
          SystemDesignCodingEditor.initialize(entity, {
            enabledHint: true, // NOTE: demo will always have hint enabled
          });
          break;
        }
        case "ProjectEntity": {
          ProjectCodingEditorV3.initialize(entity);
          break;
        }
        case "QuizEntity": {
          QuizCodingEditor.initialize(entity);
          break;
        }
      }
    });
    const entities = demo.entities.map<InterviewNavigation.EntityForNavigation>(entity => {
      const key = generateEntityKey(entity);
      const normalizedEntity = generateNormalizedEntity(entity);
      return {
        ...extractQuestionTitle(entity),
        key,
        completed: completedEntityKeySet.has(key),
        ...normalizedEntity,
      };
    }, []);
    InterviewNavigation.initializeEntities(entities);
    if (entities.length) {
      const entity = entities[0];
      InterviewNavigation.setSelectedEntityKey(entity.key);
    }
  });
};

const goToNotYetSubmittedQuestion = (submittedEntityKey: string): void => {
  const entities = DemosIdStore.getEntities();
  const entitiesFromInterviewNavigation = InterviewNavigation.getEntities();
  const alreadyCompletedKeys = entitiesFromInterviewNavigation.filter(target => target.completed).map(target => target.key);
  const completedEntities = DemosIdStore.getCompletedEntities();
  const completedKeySet = new Set([...completedEntities.map(generateEntityKey), ...alreadyCompletedKeys, submittedEntityKey]);
  const notCompletedEntities = entities.filter(entity => !completedKeySet.has(generateEntityKey(entity)));
  if (notCompletedEntities.length > 0) {
    const nextEntity = notCompletedEntities[0];
    const nextEntityKey = generateEntityKey(nextEntity);
    InterviewNavigation.setSelectedEntityKey(nextEntityKey);
  }
};

type StartSubscribeShowingEntityKeyArgs = {
  onChangeShowingEntity: (entity: NormalizedEntity) => void;
};

export const startSubscribeOnChangeShowingEntity = (args: StartSubscribeShowingEntityKeyArgs) => {
  return InterviewNavigation.subscribeOnChangeShowingEntity((currentSelectedEntityKey, entity) => {
    args.onChangeShowingEntity({
      entityType: entity.entityType,
      entityId: entity.entityId,
      questionId: entity.questionId,
      questionVersion: entity.questionVersion,
    });
  });
};

export const startSubscribeSystemDesignCodingEditor = () => {
  return SystemDesignCodingEditor.subscribeOnSubmitEntity(entity => {
    const submittedEntityKey = generateEntityKey(entity);
    InterviewNavigation.completeEntity(submittedEntityKey);
    goToNotYetSubmittedQuestion(submittedEntityKey);
  });
};

export const startSubscribeChallengeCodingEditor = () => {
  return ChallengeCodingEditor.subscribeOnSubmitEntity(entity => {
    const submittedEntityKey = generateEntityKey(entity);
    InterviewNavigation.completeEntity(submittedEntityKey);
    goToNotYetSubmittedQuestion(submittedEntityKey);
  });
};

export const startSubscribeChallengeCodingEditorLoading = () => {
  return ChallengeCodingEditor.subscribeOnLoadingChange(status => {
    InterviewNavigation.updateLoadingStatus(status);
  });
};

export const startSubscribeQuizCodingEditorLoading = () => {
  return QuizCodingEditor.subscribeOnSubmitStatus(status => {
    InterviewNavigation.updateLoadingStatus(status === "LOADING" ? status : "NONE");
  });
};

export const startSubscribeProjectCodingEditorV3 = () => {
  return ProjectCodingEditorV3.subscribeOnSubmitEntity(entity => {
    const submittedEntityKey = generateEntityKey(entity);
    InterviewNavigation.completeEntity(submittedEntityKey);
    goToNotYetSubmittedQuestion(submittedEntityKey);
  });
};

export const startSubscribeQuizCodingEditor = () => {
  return QuizCodingEditor.subscribeOnSubmitEntity(entity => {
    const submittedEntityKey = generateEntityKey(entity);
    InterviewNavigation.completeEntity(submittedEntityKey);
    goToNotYetSubmittedQuestion(submittedEntityKey);
  });
};

export const startSubscribeTimeoutStatus = () => {
  return TestTimelimit.subscribeTimeoutStatus(() => {
    DemosIdStore.updateEndInterviewStatus("TIMELIMIT_HAS_EXPIRED");
  });
};
