import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import EntryContentPaper from "../../../../modules/EntryContentPaper/EntryContentPaper";
import FieldFactory, { FieldFactoryProps } from "./parts/FieldFactory/FieldFactory";

const Heading = styled(Typography)(() => ({
  textAlign: "center",
  width: "100%",
  fontWeight: 700,
}));

export type CustomFieldsProps = {
  title: string;
  items: FieldFactoryProps[];
};

const CustomFields: React.FC<CustomFieldsProps> = props => {
  if (props.items.length === 0) {
    return null;
  }
  return (
    <EntryContentPaper>
      <Heading variant="h6" mb={2}>
        {props.title}
      </Heading>

      <Stack direction="column" spacing={2}>
        {props.items.map((custom, index) => (
          <FieldFactory key={`custom-${custom.type}-${index}`} {...custom} />
        ))}
      </Stack>
    </EntryContentPaper>
  );
};

CustomFields.displayName = "CustomFields";

export default CustomFields;
