import { useQuestionDifficultyTypeMap } from "@hireroo/app-definition/question";
import { DeepReadonly } from "@hireroo/app-helper/types";
import { ScreeningsIdDetailStore } from "@hireroo/app-store/page/e/screenings_id_detail";
import { languageMapForDisplay } from "@hireroo/challenge/definition";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useComponentTypesMap } from "@hireroo/system-design/hooks";

type QuestionOverviewTableRowProps = Exclude<Widget.ScreeningDetailProps["overview"]["questionOverview"], undefined>["rows"][number];
type MultiRowProps = Extract<QuestionOverviewTableRowProps, { items: unknown[] }>;
type SingleRowProps = Exclude<QuestionOverviewTableRowProps, MultiRowProps>;

const useGenerateSingleRowProps = () => {
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const componentTypeMap = useComponentTypesMap();
  const difficultyTypeMap = useQuestionDifficultyTypeMap();
  return (
    titlePrefix: string,
    entitySourceQuestion: DeepReadonly<Graphql.ScreeningEntitySourceQuestionForScreeningDetailFragment>,
  ): SingleRowProps => {
    const typeName = entitySourceQuestion.__typename;
    switch (typeName) {
      case "ScreeningChallengeEntitySourceQuestion":
        return {
          title: {
            children: `${titlePrefix} ${resolveLanguage(entitySourceQuestion.question, lang, "title")} (${entitySourceQuestion.questionVersion})`,
            href: generatePath("/e/questions/challenge/:id", {
              pathParams: {
                id: entitySourceQuestion.questionId.toString(),
              },
              queryParams: {
                version: entitySourceQuestion.questionVersion,
              },
            }),
          },
          variant: t("コーディング形式"),
          difficultyStars: {
            difficulty: difficultyTypeMap[entitySourceQuestion.question.difficulty],
          },
          selectedContents: entitySourceQuestion.enabledLanguages.map(lang => languageMapForDisplay[lang] ?? lang),
          weight: entitySourceQuestion.questionWeight,
          isArchived: entitySourceQuestion.question.algorithmQuestionStatus === "ARCHIVED",
        };
      case "ScreeningQuizEntitySourcePackage":
        return {
          title: {
            children: `${titlePrefix} ${resolveLanguage(entitySourceQuestion.pb_package, lang, "title")} (${entitySourceQuestion.packageVersion})`,
            href: generatePath("/e/questions/quiz/:id", {
              pathParams: {
                id: entitySourceQuestion.pb_package.packageId.toString(),
              },
              queryParams: {
                version: entitySourceQuestion.pb_package.version,
              },
            }),
          },
          variant: t("クイズ形式"),
          difficultyStars: {
            difficulty: difficultyTypeMap[entitySourceQuestion.pb_package.difficulty],
          },
          weight: entitySourceQuestion.questionWeight,
          isArchived: entitySourceQuestion.pb_package.multiChoicePackageStatus === "ARCHIVED",
        };
      case "ScreeningProjectEntitySourceQuestion":
        return {
          title: {
            children: `${titlePrefix} ${resolveLanguage(entitySourceQuestion.question, lang, "title")}`,
            href: generatePath("/e/questions/project/:id", {
              pathParams: {
                id: entitySourceQuestion.question.questionId.toString(),
              },
            }),
          },
          variant: t("実践形式"),
          difficultyStars: {
            difficulty: difficultyTypeMap[entitySourceQuestion.question.difficulty],
          },
          weight: entitySourceQuestion.questionWeight,
          isArchived: entitySourceQuestion.question.projectQuestionStatus === "ARCHIVED",
        };
      case "ScreeningSystemDesignEntitySourceQuestion":
        return {
          title: {
            children: `${titlePrefix} ${resolveLanguage(entitySourceQuestion.question, lang, "title")}`,
            href: generatePath("/e/questions/systemdesign/:id", {
              pathParams: {
                id: entitySourceQuestion.question.questionId.toString(),
              },
            }),
          },
          variant: t("システムデザイン形式"),
          difficultyStars: {
            difficulty: difficultyTypeMap[entitySourceQuestion.question.difficulty],
          },
          selectedContents: entitySourceQuestion.componentTypes.map(c => componentTypeMap[c]),
          weight: entitySourceQuestion.questionWeight,
          isArchived: entitySourceQuestion.question.systemDesignQuestionStatus === "ARCHIVED",
        };
      default:
        throw new Error(`Unknown entity question ${typeName satisfies never}`);
    }
  };
};

export const useGenerateQuestionOverviewProps = (): Widget.ScreeningDetailProps["overview"]["questionOverview"] => {
  const { t } = useTranslation();
  const screening = ScreeningsIdDetailStore.useScreening();
  const generateRowProps = useGenerateSingleRowProps();

  return {
    rows: screening.entityTracks.map((entityTrack, index): QuestionOverviewTableRowProps => {
      const titlePrefix = `Q${index + 1}`;
      switch (entityTrack.__typename) {
        case "ScreeningFixedEntityTrack": {
          return generateRowProps(titlePrefix, entityTrack.entitySourceQuestion);
        }
        case "ScreeningRandomFromQuestionsEntityTrack": {
          return {
            title: {
              children: `${titlePrefix} ${t("ランダムに問題が出題される")}`,
            },
            items: entityTrack.entitySourceQuestions.map((entitySourceQuestion, childIndex) => {
              return generateRowProps(`${titlePrefix} - ${childIndex + 1}`, entitySourceQuestion);
            }),
            /**
             * when entity track is not FixedEntityTrack, it doesn't have archive status
             */
            isArchived: false,
          };
        }
        case "ScreeningSelectableFromQuestionsEntityTrack": {
          return {
            title: {
              children: `${titlePrefix} ${t("受験者が問題を選択できる")}`,
            },
            items: entityTrack.entitySourceQuestions.map((entitySourceQuestion, childIndex) => {
              return generateRowProps(`${titlePrefix} - ${childIndex + 1}`, entitySourceQuestion);
            }),
            /**
             * when entity track is not FixedEntityTrack, it doesn't have archive status
             */
            isArchived: false,
          };
        }
        default:
          throw new Error(`Unknown entityTrack ${entityTrack satisfies never}`);
      }
    }),
  };
};
