import Logo from "@hireroo/ui-assets/images/Logo/Logo.png";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";

import Link, { LinkProps } from "../../primitive/Link/Link";
import AppBar from "./parts/AppBar/AppBar";

/**
 * !!! CAUTION !!!
 * Some pages have hard-coded heights, so please check all pages that reference this layout if you wish to change it.
 */
const HEADER_HEIGHT = 48;
const FOOTER_HEIGHT = 24;
const NOTIFICATION_BANNER_HEIGHT = 48;

const RootWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "100%",
  width: "100%",
  backgroundColor: theme.palette.background.default,
}));

const StyledLink = styled(Link)(() => ({
  display: "inline-flex",
  alignItems: "stretch",
}));

const MainWrapper = styled(Box)(() => ({
  flexGrow: 1,
  position: "relative",
}));

const NotificationBannerWrapper = styled(Box)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 100,
  width: "100%",
  height: NOTIFICATION_BANNER_HEIGHT,
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  minHeight: `calc(100vh - ${HEADER_HEIGHT + FOOTER_HEIGHT}px)`,
  height: "100%",
}));

const StyledAppBar = styled(AppBar)(() => ({
  height: HEADER_HEIGHT,
  minHeight: HEADER_HEIGHT,
}));

const StyledToolbar = styled(Toolbar)(() => ({
  height: HEADER_HEIGHT,
  minHeight: HEADER_HEIGHT,
  "@media (min-width:600px)": { minHeight: `${HEADER_HEIGHT}px` },
}));

const FooterWrapper = styled(Box)(() => ({
  height: FOOTER_HEIGHT,
}));

const DrawerHeader = styled(Paper)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  height: HEADER_HEIGHT,
  minHeight: HEADER_HEIGHT,
  "@media (min-width:600px)": { minHeight: `${HEADER_HEIGHT}px` },
}));

export type TalentLayoutProps = {
  className?: string;
  NotificationBanner: React.ReactNode;
  loading?: boolean;
  logo?: Pick<LinkProps, "href" | "onClick">;
  HeaderRightContent: React.ReactNode;
  SideBarContent: React.ReactNode;
  Footer: React.ReactNode;
  Snackbar: React.ReactNode;
};

/**
 * Header Height: 48px
 * Footer Height: 24px
 */
const TalentLayout: React.FC<React.PropsWithChildren<TalentLayoutProps>> = props => {
  const topMargin = props.NotificationBanner ? `${NOTIFICATION_BANNER_HEIGHT}px` : `0px`;

  return (
    <RootWrapper className={props.className}>
      {props.loading && <LinearProgress sx={{ width: "100%", position: "fixed", zIndex: 2000, top: 0 }} color="secondary" />}
      {props.NotificationBanner && (
        <NotificationBannerWrapper width="100%" position="fixed">
          {props.NotificationBanner}
        </NotificationBannerWrapper>
      )}
      <StyledAppBar position="fixed" sx={{ marginTop: topMargin }}>
        <StyledToolbar>
          <StyledLink {...props.logo}>
            <img src={Logo} width={100} alt="hireroo" />
          </StyledLink>
          <Box sx={{ flexGrow: 1 }}></Box>
          <Box>{props.HeaderRightContent}</Box>
        </StyledToolbar>
      </StyledAppBar>

      <MainWrapper component="main" sx={{ top: topMargin }}>
        <DrawerHeader />
        <ContentWrapper>{props.children}</ContentWrapper>
        <FooterWrapper>{props.Footer}</FooterWrapper>
      </MainWrapper>
      {props.Snackbar}
    </RootWrapper>
  );
};

TalentLayout.displayName = "TalentLayout";

export default TalentLayout;
