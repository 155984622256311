import { useTranslation } from "@hireroo/i18n";
import { EvaluationMetricsForm } from "@hireroo/validator";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import ListItem from "@mui/material/ListItem";
import Popper from "@mui/material/Popper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useController, useFieldArray } from "react-hook-form";

const StyledPopper = styled(Popper)(({ theme }) => ({
  "& .MuiAutocomplete-groupLabel": {
    backgroundColor: theme.palette["Background/Paper"].p4,
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  height: 30,
  ".MuiChip-deleteIcon": {
    color: theme.palette.secondary.main,
  },
  color: theme.palette.secondary.main,
  borderColor: theme.palette.secondary.main,
}));

const StyledStack = styled(Stack)(({ theme }) => ({
  borderBottom: "1px solid",
  borderColor: theme.palette["Secondary/Shades"].p12,
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  borderColor: theme.palette["Secondary/Shades"].p12,
}));

export type ValueId = string;
type ItemId = string;
type Field = {
  /** key is dummy for type safe */
  items: (EvaluationMetricsForm.MetricItemSchema & { id?: number })[];
};

export type ItemProps = {
  id?: number;
  valueId: ValueId;
  itemId: ItemId;
};

type Source = { optionText: string; optionSubText: string; chipText: string };

export type EvaluationMetricSearchFieldProps = Pick<TextFieldProps, "onChange" | "placeholder"> & {
  suggestions: Omit<ItemProps, "id">[];
  displaySources: Record<ItemId, Source | undefined>;
  loading?: boolean;
  onFocus?: () => void;
  title: string;
};

const EvaluationMetricSearchField: React.FC<EvaluationMetricSearchFieldProps> = props => {
  const { t } = useTranslation();
  const { suggestions, displaySources } = props;
  const { fieldState } = useController<Field>({
    name: "items",
  });
  const { fields, append, remove } = useFieldArray<Field>({
    /**
     * Name of the field array. Note: Do not support dynamic name.
     * @see https://react-hook-form.com/api/usefieldarray
     */
    name: "items",
  });
  const fieldError = fieldState.error;

  const value = React.useMemo((): ItemProps[] => {
    return fields.reduce<ItemProps[]>((all, field) => {
      if (field.name === null) {
        return all;
      }
      return all.concat({
        id: field.id,
        valueId: field.name,
        itemId: field.itemId,
      });
    }, []);
  }, [fields]);

  return (
    <Autocomplete
      multiple
      fullWidth
      disableCloseOnSelect
      value={value}
      isOptionEqualToValue={(option, value) => {
        return option.valueId === value.valueId;
      }}
      PopperComponent={StyledPopper}
      groupBy={() => {
        return "group";
      }}
      renderGroup={params => {
        return (
          <Stack direction="column">
            <StyledStack key={params.key} px={1.5} direction="row" alignItems="center" width="100%">
              <Box width="40%" py={1}>
                <Typography fontSize={12} color="textSecondary">
                  {props.title}
                </Typography>
              </Box>
              <Box width="60%" py={1}>
                <Typography fontSize={12} color="textSecondary">
                  {t("説明")}
                </Typography>
              </Box>
            </StyledStack>
            {params.children}
          </Stack>
        );
      }}
      renderOption={(props, option) => {
        const displaySource = displaySources[option.itemId];
        return (
          <StyledListItem {...props} key={option.valueId} sx={{ p: 1.5 }} divider>
            <Stack direction="row" alignItems="center" width="100%">
              <Box width="40%">
                <Typography fontSize={14}>{displaySource?.optionText ?? ""}</Typography>
              </Box>
              <Box width="60%">
                <Typography fontSize={12} color="textSecondary">
                  {displaySource?.optionSubText ?? ""}
                </Typography>
              </Box>
            </Stack>
          </StyledListItem>
        );
      }}
      onChange={(_, items, reason) => {
        if (reason === "selectOption") {
          items
            .filter(item => !item.id)
            .forEach(item => {
              append({ name: item.valueId, itemId: item.itemId });
            });
        } else if (reason === "clear") {
          const removeIds = fields.reduce<number[]>((all, field, index) => {
            return all.concat(index);
          }, []);
          remove(removeIds);
        }
      }}
      onFocus={props.onFocus}
      loading={props.loading}
      options={suggestions as ItemProps[]}
      getOptionLabel={option => {
        const displayName = displaySources[option.itemId];
        if (!displayName) {
          return "";
        }
        return displayName.optionText;
      }}
      filterSelectedOptions
      renderTags={(items, getTagProps) => {
        return items.map((item, index) => {
          const tagProps = getTagProps({ index });
          const displaySource = displaySources[item.itemId];
          return (
            <Tooltip key={item.id} title={displaySource?.chipText ?? ""}>
              <StyledChip
                {...tagProps}
                key={item.id ?? item.itemId}
                label={displaySource?.chipText}
                variant="outlined"
                onDelete={event => {
                  tagProps.onDelete(event);
                  remove(index);
                }}
              />
            </Tooltip>
          );
        });
      }}
      renderInput={params => (
        <TextField
          {...params}
          color="secondary"
          placeholder={props.placeholder}
          error={!!fieldError?.message}
          helperText={fieldError?.message}
        />
      )}
    />
  );
};

EvaluationMetricSearchField.displayName = "EvaluationMetricSearchField";

export default EvaluationMetricSearchField;
