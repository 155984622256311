import { useTranslation } from "@hireroo/i18n";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import NativeSelect, { type NativeSelectProps } from "@mui/material/NativeSelect";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { languageMapForDisplay } from "../../../definition";
import { useChallengeCodingEditorContext } from "../../../store";

export type RuntimeSelectorProps = {
  onChange?: (language: keyof typeof languageMapForDisplay) => void;
} & Pick<NativeSelectProps, "disabled" | "sx">;

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  borderRadius: 4,
  position: "relative",
  backgroundColor: theme.palette.background.paper,
  fontSize: "1rem",
  padding: "10px 0 10px 10px",
  transition: theme.transitions.create(["border-color", "box-shadow"]),
  ".MuiNativeSelect-select": {
    padding: 0,
  },
  // Use the system font instead of the default Roboto font.
  "&:focus": {
    borderRadius: 4,
    borderColor: "#80bdff",
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
  },
}));

const RuntimeSelector: React.FC<RuntimeSelectorProps> = props => {
  const { onChange } = props;
  const { hooks, action } = useChallengeCodingEditorContext();
  const language = hooks.useCurrentLanguage();
  const enableLanguages = hooks.useEnableLanguages();
  const { t } = useTranslation();
  const nativeSelectProps: NativeSelectProps = {
    ...props,
    className: "runtime-selector",
    onChange: React.useCallback(
      (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (Object.keys(languageMapForDisplay).includes(event.target.value)) {
          onChange?.(event.target.value);
          action.updateLanguage(event.target.value, "ACTIVE");
        }
      },
      [action, onChange],
    ),
    value: language,
    input: <BootstrapInput />,
    style: {
      height: "42px",
    },
  };

  if (enableLanguages.length === 0) {
    return (
      <Stack direction="column" alignItems="center" justifyContent="center">
        <Typography variant="body2">{t("利用可能な言語がありませんでした。")}</Typography>
        <Typography variant="caption">{t("ヘルプより運営にお問い合わせください。")}</Typography>
      </Stack>
    );
  }
  return (
    <FormControl>
      <NativeSelect {...nativeSelectProps}>
        {enableLanguages.map(enabledLanguage => (
          <option key={enabledLanguage} value={enabledLanguage}>
            {languageMapForDisplay[enabledLanguage] ?? enabledLanguage}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );
};

RuntimeSelector.displayName = "RuntimeSelector";

export default RuntimeSelector;
