import {
  DIFFICULTY_REVERS_MAP,
  QUESTION_LEAK_SCORE_LEVEL_REVERSE_MAP,
  QUESTION_VARIANT_REVERSE_MAP,
  SORT_METHOD_REVERS_MAP,
  STATUS_REVERSE_MAP,
} from "@hireroo/app-definition/question";
import * as Graphql from "@hireroo/graphql/client/urql";

const PROJECT_NUMBER_OF_CHILDREN = 3;
const CHALLENGE_NUMBER_OF_CHILDREN = 2;

export const convertStringsToStatuses = (values: string[]): Graphql.QuestionStatus[] => {
  const newStatuses: Graphql.QuestionStatus[] = [];
  for (const value of values) {
    value.split("%").forEach(v => {
      // Check if the string value can be converted to a valid status.
      if (STATUS_REVERSE_MAP[v]) {
        newStatuses.push(STATUS_REVERSE_MAP[v]);
      }
    });
  }
  return newStatuses;
};

export const convertStringsToQuestionVariants = (values: string[]): Graphql.QuestionVariant[] => {
  const newQuestionVariants: Graphql.QuestionVariant[] = [];
  for (const value of values) {
    value.split("%").forEach(v => {
      // Check if the string value can be converted to a valid QuestionVariant.
      if (QUESTION_VARIANT_REVERSE_MAP[v]) {
        newQuestionVariants.push(QUESTION_VARIANT_REVERSE_MAP[v]);
      }
    });
  }
  return newQuestionVariants;
};

export const convertStringsToDifficulties = (values: string[]): Graphql.Difficulty[] => {
  const newDifficulties: Graphql.Difficulty[] = [];
  for (const value of values) {
    value.split("%").forEach(v => {
      // Check if the string value can be converted to a valid difficulty.
      if (DIFFICULTY_REVERS_MAP[v]) {
        newDifficulties.push(DIFFICULTY_REVERS_MAP[v]);
      }
    });
  }
  return newDifficulties;
};

export const convertStringsToQuestionLeakScoreLevel = (values: string[]): Graphql.QuestionLeakScoreLevel[] => {
  const newQuestionLeakScoreLevels: Graphql.QuestionLeakScoreLevel[] = [];
  for (const value of values) {
    value.split("%").forEach(v => {
      // Check if the string value can be converted to a valid difficulty.
      if (QUESTION_LEAK_SCORE_LEVEL_REVERSE_MAP[v]) {
        newQuestionLeakScoreLevels.push(QUESTION_LEAK_SCORE_LEVEL_REVERSE_MAP[v]);
      }
    });
  }
  return newQuestionLeakScoreLevels;
};

export const convertStringsToSkillTagIds = (values: string[]): string[] => {
  // Get all the valid string values
  const tags: string[] = [];
  values.forEach(value => {
    value.split("%").forEach(value => {
      tags.push(value);
    });
  });
  return tags;
};

export const convertStringToSortMethod = (value: string | null): Graphql.SortMethod => {
  if (value === null) return Graphql.SortMethod.Unknown;
  try {
    // "sortMethod+isDesc" (e.g.) "CREATED_AT-true"
    const [method] = value.split("-");
    return SORT_METHOD_REVERS_MAP[method];
  } catch (_) {
    return Graphql.SortMethod.Unknown;
  }
};

export const convertStringToIsDesc = (value: string | null): boolean => {
  if (value === null) return false;
  try {
    // "sortMethod+isDesc" (e.g.) "CREATED_AT-true"
    const [, isDesc] = value.split("-");
    return isDesc === "true";
  } catch (_) {
    return false;
  }
};

const isParentQuestionVariant = (selected: Graphql.QuestionVariant): boolean => {
  return selected === Graphql.QuestionVariant.Challenge || selected === Graphql.QuestionVariant.Project;
};

const isChildQuestionVariant = (selected: Graphql.QuestionVariant): boolean => {
  return (
    selected === Graphql.QuestionVariant.ProjectBackend ||
    selected === Graphql.QuestionVariant.ProjectFrontend ||
    selected === Graphql.QuestionVariant.ProjectOther ||
    selected === Graphql.QuestionVariant.ChallengeAlgorithm ||
    selected === Graphql.QuestionVariant.ChallengeDatabase ||
    selected === Graphql.QuestionVariant.ChallengeClass
  );
};

const noChildIsSelected = (parent: Graphql.QuestionVariant, selectedOptions: readonly Graphql.QuestionVariant[]): boolean => {
  if (parent === Graphql.QuestionVariant.Challenge) {
    return selectedOptions.every(option => {
      return (
        option !== Graphql.QuestionVariant.ChallengeAlgorithm &&
        option !== Graphql.QuestionVariant.ChallengeDatabase &&
        option !== Graphql.QuestionVariant.ChallengeClass
      );
    });
  }

  if (parent === Graphql.QuestionVariant.Project) {
    return selectedOptions.every(option => {
      return (
        option !== Graphql.QuestionVariant.ProjectBackend &&
        option !== Graphql.QuestionVariant.ProjectFrontend &&
        option !== Graphql.QuestionVariant.ProjectOther
      );
    });
  }

  return false;
};

const noParentIsSelected = (child: Graphql.QuestionVariant, selectedOptions: readonly Graphql.QuestionVariant[]): boolean => {
  if (
    child === Graphql.QuestionVariant.ChallengeAlgorithm ||
    child === Graphql.QuestionVariant.ChallengeDatabase ||
    child === Graphql.QuestionVariant.ChallengeClass
  ) {
    return !selectedOptions.includes(Graphql.QuestionVariant.Challenge);
  }

  if (
    child === Graphql.QuestionVariant.ProjectBackend ||
    child === Graphql.QuestionVariant.ProjectFrontend ||
    child === Graphql.QuestionVariant.ProjectOther
  ) {
    return !selectedOptions.includes(Graphql.QuestionVariant.Project);
  }

  return false;
};

const parentOf = (child: Graphql.QuestionVariant): Graphql.QuestionVariant | undefined => {
  if (
    child === Graphql.QuestionVariant.ProjectBackend ||
    child === Graphql.QuestionVariant.ProjectFrontend ||
    child === Graphql.QuestionVariant.ProjectOther
  ) {
    return Graphql.QuestionVariant.Project;
  }

  if (
    child === Graphql.QuestionVariant.ChallengeAlgorithm ||
    child === Graphql.QuestionVariant.ChallengeDatabase ||
    child === Graphql.QuestionVariant.ChallengeClass
  ) {
    return Graphql.QuestionVariant.Challenge;
  }

  return undefined;
};

const isChildOf = (parent: Graphql.QuestionVariant, value: Graphql.QuestionVariant): boolean => {
  if (parent === Graphql.QuestionVariant.Project) {
    return (
      value === Graphql.QuestionVariant.ProjectBackend ||
      value === Graphql.QuestionVariant.ProjectFrontend ||
      value === Graphql.QuestionVariant.ProjectOther
    );
  }

  if (parent === Graphql.QuestionVariant.Challenge) {
    return (
      value === Graphql.QuestionVariant.ChallengeAlgorithm ||
      value === Graphql.QuestionVariant.ChallengeDatabase ||
      value === Graphql.QuestionVariant.ChallengeClass
    );
  }

  return false;
};

export const filterSelectedQuestionVariant = (selectedQuestionVariants: readonly Graphql.QuestionVariant[]): Graphql.QuestionVariant[] => {
  const newQuestionVariants = [...selectedQuestionVariants];

  for (const selected of selectedQuestionVariants) {
    // If a parent question option is selected but no children are selected, delete parent
    if (isParentQuestionVariant(selected) && noChildIsSelected(selected, selectedQuestionVariants)) {
      const indexOfChildlessParent = newQuestionVariants.indexOf(selected);
      newQuestionVariants.splice(indexOfChildlessParent, 1);
    }
    // If a child question option is selected but no parent is selected, delete other children
    if (isChildQuestionVariant(selected) && noParentIsSelected(selected, selectedQuestionVariants)) {
      const parent = parentOf(selected);
      if (parent && !newQuestionVariants.includes(parent)) {
        return newQuestionVariants.filter(value => !isChildOf(parent, value));
      }
    }
  }

  return newQuestionVariants;
};

const allChildrenAreSelected = (parent: Graphql.QuestionVariant, selectedQuestionVariants: readonly Graphql.QuestionVariant[]): boolean => {
  if (parent === Graphql.QuestionVariant.Project) {
    const children = selectedQuestionVariants.filter(q => isChildOf(Graphql.QuestionVariant.Project, q));
    if (children.length === PROJECT_NUMBER_OF_CHILDREN) {
      return true;
    }
  }

  if (parent === Graphql.QuestionVariant.Challenge) {
    const children = selectedQuestionVariants.filter(q => isChildOf(Graphql.QuestionVariant.Challenge, q));
    if (children.length === CHALLENGE_NUMBER_OF_CHILDREN) {
      return true;
    }
  }

  return false;
};

export const filterSelectedForGridChips = (selectedQuestionVariants: readonly Graphql.QuestionVariant[]): Graphql.QuestionVariant[] => {
  let newQuestionVariants = [...selectedQuestionVariants];

  for (const selected of selectedQuestionVariants) {
    // If a parent question option is selected and all its children are selected, filter out children
    if (isParentQuestionVariant(selected) && allChildrenAreSelected(selected, selectedQuestionVariants)) {
      newQuestionVariants = newQuestionVariants.filter(q => !isChildOf(selected, q));
    }
    // If a child question option is selected but no parent is selected, delete other children
    if (isChildQuestionVariant(selected)) {
      const parent = parentOf(selected);
      if (parent && !allChildrenAreSelected(parent, selectedQuestionVariants)) {
        newQuestionVariants = newQuestionVariants.filter(value => value !== parent);
      }
    }
  }

  return newQuestionVariants;
};
