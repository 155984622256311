import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateEmployeeGroupAssignFieldPropsArgs, useGenerateProps } from "./useGenerateProps";

export type EmployeeGroupAssignFieldContainerProps = GenerateEmployeeGroupAssignFieldPropsArgs;

const EmployeeGroupAssignFieldContainer: React.FC<EmployeeGroupAssignFieldContainerProps> = props => {
  const employeeAssignFieldProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.AssignField {...employeeAssignFieldProps} />
    </ErrorBoundary>
  );
};

EmployeeGroupAssignFieldContainer.displayName = "EmployeeGroupAssignFieldContainer";

export default withErrorBoundary(EmployeeGroupAssignFieldContainer, {});
