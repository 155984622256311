import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { useHelpCenterNavigate } from "@hireroo/router/hooks";

export type GenerateErrorPanelPropsArgs = {
  title: string;
  message: string;
  additionalMessage?: string;
};

export const useGenerateProps = (args: GenerateErrorPanelPropsArgs): Widget.ErrorPanelProps => {
  const { t } = useTranslation();
  const languageCode = useLanguageCode();
  const helpCenterNavigate = useHelpCenterNavigate(languageCode);

  return {
    name: args.title,
    message: args.message,
    additionalMessage: args.additionalMessage,
    links: [
      {
        children: t("ヘルプセンター"),
        onClick: () => {
          helpCenterNavigate("ROOT", { _blank: true });
        },
      },
    ],
  };
};
