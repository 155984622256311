import * as React from "react";
import { LocalAudioTrack } from "twilio-video";

import { useIsTrackEnabled } from "./useIsTrackEnabled";

/**
 * This hook enables or disables the local audio track.
 */
export const useLocalAudioToggle = (audioTrack: LocalAudioTrack | undefined, getAudioTrack: () => void) => {
  const isEnabled = useIsTrackEnabled(audioTrack);

  const toggle = React.useCallback(() => {
    if (audioTrack) {
      audioTrack.isEnabled ? audioTrack.disable() : audioTrack.enable();
    } else {
      getAudioTrack();
    }
  }, [audioTrack, getAudioTrack]);

  return { isEnabled, toggle };
};
