import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { useTitle } from "@hireroo/app-helper/react-use";
import { Auth } from "@hireroo/app-store/essential/employee";
import { EmployeeSideBarContent } from "@hireroo/app-store/widget/e/EmployeeSideBarContent";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import { SupportLanguageValue, useTranslation } from "@hireroo/i18n";
import type { Pages } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/react";
import React from "react";
import { useSearchParams } from "react-router-dom";

import EmployeeHeaderRightContentContainer from "../../../../widget/v2/e/EmployeeHeaderRightContent/Container";
import EmployeeSideBarContentContainer from "../../../../widget/v2/e/EmployeeSideBarContent/Container";
import FooterContainer from "../../../../widget/v2/e/Footer/Container";
import { useNotificationBanner } from "../../../../widget/v2/e/NotificationBanner/Container";
import ScreeningResourceEditorContainer, {
  ScreeningResourceEditorContainerProps,
} from "../../../../widget/v2/e/ScreeningResourceEditor/Container";
import ScreeningResourceEditorFetchContainer from "../../../../widget/v2/e/ScreeningResourceEditor/FetchContainer";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import * as PrivateHelper from "./privateHelper";

const invitationLanguageMap: Record<SupportLanguageValue, Graphql.CreateSpotInput["invitationLanguage"]> = {
  en: "EN",
  ja: "JA",
};

export type GenerateScreeningTestResourceEditorPropsArgs = {};

export const useGenerateProps = (_args: GenerateScreeningTestResourceEditorPropsArgs): Pages.ScreeningResourceEditorProps => {
  const { t } = useTranslation();
  const navigate = useTransitionNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id") ?? null;
  const client = getGraphqlClient();
  const currentUid = Auth.useCurrentUid();

  const [status, setStatus] = React.useState<"READY" | "PENDING">("READY");
  const NotificationBannerContainer = useNotificationBanner();

  useTitle(t("コーディングテスト作成"));

  const screeningResourceEditorContainerProps: ScreeningResourceEditorContainerProps = {
    mode: "CREATE",
    loading: status === "PENDING",
    onSubmit: fields => {
      const entityTrackInputs = fields.testQuestionSetup.entityTracks.map((entityTrack): Graphql.CreateScreeningEntityTrackInput => {
        switch (entityTrack.type) {
          case "FIXED": {
            return {
              fixed: {
                entitySource: PrivateHelper.convertEntitySourceToSpotEntitySourceForEntityTrackInput(entityTrack.entitySource),
                questionScoreWeight: entityTrack.questionScoreWeight,
              },
            };
          }
          case "RANDOM_FROM_QUESTIONS":
            return {
              randomFromQuestions: {
                entitySources: entityTrack.entitySources.map(entitySource => {
                  return PrivateHelper.convertEntitySourceToSpotEntitySourceForEntityTrackInput(entitySource);
                }),
                questionScoreWeight: entityTrack.questionScoreWeight,
              },
            };
          case "SELECTABLE_FROM_QUESTIONS":
            return {
              selectableFromQuestions: {
                entitySources: entityTrack.entitySources.map(entitySource => {
                  return PrivateHelper.convertEntitySourceToSpotEntitySourceForEntityTrackInput(entitySource);
                }),
                questionScoreWeight: entityTrack.questionScoreWeight,
              },
            };
          default:
            throw new Error(`Invalid Entity Track: ${entityTrack satisfies never}`);
        }
      });
      setStatus("PENDING");
      client
        .CreateScreeningForScreeningsCreate({
          input: {
            companyId: fields.testQuestionSetup.companyId,
            creatorId: currentUid,
            allowChatGPT: fields.testQuestionSetup.enabledChatGPT,
            allowWebSearch: fields.testQuestionSetup.enabledWebSearch,
            allowHint: fields.testQuestionSetup.enabledHint,
            acceptableLimit: fields.testSetup.acceptableLimit,
            deadlineSeconds: fields.testSetup.deadlineDate ? Math.floor(fields.testSetup.deadlineDate.getTime() / 1000) : undefined,
            candidateAccessPolicy: fields.testInviteSetup.candidateAccessPolicy,
            entityTrackInputs: entityTrackInputs,
            name: fields.testQuestionSetup.name,
            description: fields.testQuestionSetup.description,
            memo: fields.reportSetup.memo,
            messageForCandidate: fields.testInviteSetup.messageForCandidate,
            ownerEmailAddress: fields.testInviteSetup.ownerEmailAddress,
            isActive: fields.testSetup.isActive,
            tagNames: fields.reportSetup.tags.map(t => t.value.name),
            timeLimitSeconds: fields.testQuestionSetup.timeLimitMinutes * 60,
            spotIsPublic: fields.reportSetup.isPublic,
            invitationLanguage: invitationLanguageMap[fields.testInviteSetup.invitationLanguage] ?? "JA",
            variables: fields.testSetup.customFields.map((variable): Graphql.ScreeningVariableInput => {
              switch (variable.valueType) {
                case "STRING": {
                  return {
                    label: variable.label,
                    description: variable.description,
                    formType: variable.formType === "INPUT_TEXT" ? "INPUT_TEXT" : "TEXTAREA",
                    valueType: "STRING",
                    validationRule: {
                      stringValidationRule: {
                        min: variable.validationRule.minLength,
                        max: variable.validationRule.maxLength,
                        required: variable.validationRule.required,
                      },
                    },
                  };
                }
                case "INTEGER": {
                  return {
                    label: variable.label,
                    description: variable.description,
                    formType: "INPUT_NUMBER",
                    valueType: "INTEGER",
                    validationRule: {
                      integerValidationRule: {
                        min: variable.validationRule.min,
                        max: variable.validationRule.max,
                        required: variable.validationRule.required,
                      },
                    },
                  };
                }
                default:
                  throw new Error(`Value type is invalid: ${variable satisfies never}`);
              }
            }),
            viewerIds: fields.reportSetup.viewers.reduce<Graphql.SpotViewerInput[]>((viewerIds, reviewer) => {
              if (reviewer.value === null) {
                return viewerIds;
              }
              switch (reviewer.value.type) {
                case "EMPLOYEE":
                  return viewerIds.concat({
                    type: "EMPLOYEE",
                    employeeId: reviewer.value.employeeId,
                  });
                case "EMPLOYEE_GROUP":
                  return viewerIds.concat({
                    type: "EMPLOYEE_GROUP",
                    groupId: reviewer.value.groupId,
                  });
                default:
                  break;
              }
              return viewerIds;
            }, []),
          },
        })
        .then(res => {
          navigate("/e/screenings/:id/detail", {
            pathParams: {
              id: res.createScreening.screeningId.toString(),
            },
          });
          Snackbar.notify({
            severity: "success",
            message: t("コーディングテストを作成しました。"),
          });
        })
        .catch(error => {
          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(
            error,
            t("コーディングテストの作成に失敗しました。") + t("しばらくしてから再度お試し頂くか、ヘルプボタンよりお問い合わせください。"),
          );
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
        })
        .finally(() => {
          setStatus("READY");
        });
    },
  };

  React.useEffect(() => {
    EmployeeSideBarContent.setOpenSidebar(false);
  }, []);

  return {
    layout: {
      openSidebar: EmployeeSideBarContent.useOpenSidebar(),
      onChangeOpenSidebar: open => {
        EmployeeSideBarContent.setOpenSidebar(open);
      },
      NotificationBanner: NotificationBannerContainer,
      loading: status === "PENDING",
      HeaderRightContent: <EmployeeHeaderRightContentContainer />,
      SideBarContent: <EmployeeSideBarContentContainer />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      sideBarHeader: {
        logo: {
          href: generatePath("/e/home"),
          onClick: () => {
            navigate("/e/home");
          },
        },
      },
      navigationBreadcrumbs: {
        parentLinks: [
          {
            href: generatePath("/e/home"),
            children: t("ホーム"),
            onClick: () => {
              navigate("/e/home");
            },
          },
          {
            href: generatePath("/e/screenings"),
            children: t("コーディングテスト一覧"),
            onClick: () => {
              navigate("/e/screenings");
            },
          },
        ],
        current: t("コーディングテスト作成"),
      },
    },
    children: id ? (
      <ScreeningResourceEditorFetchContainer {...screeningResourceEditorContainerProps} screeningId={id} />
    ) : (
      <ScreeningResourceEditorContainer {...screeningResourceEditorContainerProps} />
    ),
  };
};
