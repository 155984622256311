import { getRef } from "@hireroo/firebase";
import * as React from "react";

import { AccessEvent, fetchAccessEventsRecord } from "../firepad";

export type FirebaseAccessEventArgs = {
  quizId: number;
  packageId: number;
  questionIds: number[];
};

type QuestionId = string;
type RevisionId = string;
export type AccessHistory = Record<RevisionId, AccessEvent>;

export type QuizAccessEvents = {
  accessEventMap: Record<QuestionId, AccessHistory>;
  accessEventsReady: boolean;
};

export const useQuizAccessEvents = (args: FirebaseAccessEventArgs): QuizAccessEvents => {
  const { quizId, packageId, questionIds } = args;
  const [accessEventMap, setAccessEventMap] = React.useState<Record<string, AccessHistory>>({});
  const [accessEventsReady, setAccessEventsReady] = React.useState(false);

  React.useEffect(() => {
    if (accessEventsReady) return;

    const fetchData = () => {
      const promises = questionIds.map(questionId => {
        const id = String(questionId);
        const submissionRef = getRef("quiz", `quizzes/${quizId}/packages/${packageId}/questions/${questionId}`);

        return new Promise<Record<string, AccessHistory>>(resolve => {
          fetchAccessEventsRecord(submissionRef, event => {
            resolve({ [id]: event });
          });
        });
      });

      Promise.all(promises).then(events => {
        const eventMap = events.reduce((prev, curr) => ({ ...prev, ...curr }), {});
        setAccessEventMap(eventMap);
        setAccessEventsReady(true);
      });
    };

    fetchData();
  }, [accessEventsReady, packageId, questionIds, quizId]);

  return {
    accessEventMap: accessEventMap,
    accessEventsReady,
  };
};
