import { proxy } from "valtio";
import { proxyMap } from "valtio/utils";

import type * as Types from "./types";

export const state = proxy<Types.State>({
  assessmentsResponse: null,
  selectedAssessmentId: null,
  pager: {
    page: 0,
    size: 20,
    offset: 0,
    isDescending: true,
  },
  tailExamStatusMap: proxyMap(),
  tailExamIdMap: proxyMap(),
});
