import { useTitle } from "@hireroo/app-helper/react-use";
import { Payment, Role } from "@hireroo/app-store/essential/employee";
import { ScreeningsStore } from "@hireroo/app-store/page/e/screenings";
import { EmployeeSideBarContent } from "@hireroo/app-store/widget/e/EmployeeSideBarContent";
import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { useCurrentLanguage } from "@hireroo/i18n";
import type { Pages } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import EmployeeHeaderRightContentContainer from "../../../../widget/v2/e/EmployeeHeaderRightContent/Container";
import EmployeeSideBarContentContainer from "../../../../widget/v2/e/EmployeeSideBarContent/Container";
import FooterContainer from "../../../../widget/v2/e/Footer/Container";
import { useNotificationBanner } from "../../../../widget/v2/e/NotificationBanner/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import * as PrivateHelper from "./privateHelper";
import ScreeningListFetchContainer from "./widgets/ScreeningList/FetchContainer";
import ScreeningSearchAreaFetchContainer from "./widgets/ScreeningSearchArea/FetchContainer";

export type GenerateScreeningListPropsArgs = {};

export const useGenerateProps = (_args: GenerateScreeningListPropsArgs): Pages.ScreeningListProps => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const navigate = useTransitionNavigate();
  const NotificationBannerContainer = useNotificationBanner();
  const [searchParams] = useSearchParams();
  const language = useCurrentLanguage();
  const customerStatus = Payment.useCustomerStatus();
  const matchingRole = Role.useMatchingRole();
  const currentRoleDisplayText = Role.useCurrentRoleDisplayText();
  const initializedCurrentSearchFilter = ScreeningsStore.useInitializedCurrentSearchFilter();

  useTitle(t("コーディングテスト一覧"));

  const createButton = React.useMemo((): Pages.ScreeningListProps["createButton"] => {
    if (!matchingRole.ADMIN_OR_MANAGER.matched) {
      return {
        disabled: true,
        tooltipText: t2("PermissionTooltip", {
          actualRole: currentRoleDisplayText,
          expectedRole: matchingRole.ADMIN_OR_MANAGER.minimumRoleDisplayText,
        }),
      };
    }
    if (customerStatus === "INVALID") {
      return {
        disabled: true,
        tooltipText: t("会社設定から有効な請求情報を登録ください。テスト発行など一部機能は請求情報登録が必要です。"),
      };
    }
    if (customerStatus === "BEFORE_FETCH") {
      return {
        disabled: true,
        tooltipText: [
          t("請求書情報の取得に失敗しました。"),
          t("しばらくしてから再度お試し頂くか、ヘルプボタンよりお問い合わせください。"),
        ].join(""),
      };
    }

    return {
      onClick: () => {
        navigate("/e/screenings/create");
      },
      disabled: false,
      tooltipText: "",
    };
  }, [
    currentRoleDisplayText,
    customerStatus,
    matchingRole.ADMIN_OR_MANAGER.matched,
    matchingRole.ADMIN_OR_MANAGER.minimumRoleDisplayText,
    navigate,
    t,
    t2,
  ]);

  React.useEffect(() => {
    ScreeningsStore.initializeFilters(
      {
        textFilter: searchParams.get(ScreeningsStore.QueryKeys.NAME) ?? "",
        tags: PrivateHelper.convertStringsToTagNames(searchParams.getAll(ScreeningsStore.QueryKeys.TAG)),
        activeStatus: PrivateHelper.convertStringToActiveStatus(searchParams.get(ScreeningsStore.QueryKeys.ACTIVATE_STATUS)),
      },
      {
        page: PrivateHelper.convertStringsToPage(searchParams.get(ScreeningsStore.QueryKeys.PAGE)),
        size: PrivateHelper.convertStringToSize(searchParams.get(ScreeningsStore.QueryKeys.SIZE)),
        sortFieldValue: PrivateHelper.convertStringToSortFieldValue(searchParams.get(ScreeningsStore.QueryKeys.SORT)),
      },
    );
  }, [language, searchParams]);

  return {
    layout: {
      openSidebar: EmployeeSideBarContent.useOpenSidebar(),
      onChangeOpenSidebar: open => {
        EmployeeSideBarContent.setOpenSidebar(open);
      },
      NotificationBanner: NotificationBannerContainer,
      HeaderRightContent: <EmployeeHeaderRightContentContainer />,
      SideBarContent: <EmployeeSideBarContentContainer />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      sideBarHeader: {
        logo: {
          href: generatePath("/e/home"),
          onClick: () => {
            navigate("/e/home");
          },
        },
      },
    },
    createButton: createButton,
    SearchArea: initializedCurrentSearchFilter ? <ScreeningSearchAreaFetchContainer /> : null,
    ScreeningList: initializedCurrentSearchFilter ? <ScreeningListFetchContainer /> : null,
  };
};
