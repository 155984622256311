import { Area } from "react-easy-crop";

export const getCroppedImage = (src: string, pixelCrop: Area): string => {
  const image = new Image();
  image.src = src;
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const { width, height, x, y } = pixelCrop;

  canvas.width = width;
  canvas.height = height;
  ctx?.drawImage(image, x, y, width, height, 0, 0, width, height);

  const extension = src.split(",")[0] === "data:image/png;base64" ? "image/png" : "image/jpeg";
  return canvas.toDataURL(extension);
};
