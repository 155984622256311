import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateIntegrationsSettingsPropsArgs, useGenerateProps } from "./useGenerateProps";

export type IntegrationsSettingsContainerProps = GenerateIntegrationsSettingsPropsArgs;

const SonarAtsSettingsContainer: React.FC<IntegrationsSettingsContainerProps> = args => {
  const props = useGenerateProps(args);
  return (
    <ErrorBoundary>
      <Widget.SonarAtsSettings {...props} />
    </ErrorBoundary>
  );
};

SonarAtsSettingsContainer.displayName = "SonarAtsSettingsContainer";

export default withErrorBoundary(SonarAtsSettingsContainer, {});
