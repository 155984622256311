import { useSnapshot } from "valtio";

import { state } from "./State";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = () => {
  const snapshot = useSnapshotState();
  return !!snapshot.interview;
};

export const useInterview = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.interview) {
    throw new Error("need to initialize");
  }
  return snapshot.interview;
};

export const useRefresh = () => {
  const snapshot = useSnapshotState();
  return snapshot.refresh;
};
