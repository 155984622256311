import { subscribeKey } from "valtio/utils";

import { state } from "./State";

/**
 * Only the uid of a new, unqueried user can be retrieved.
 */
export type SubscribeOnChangeParticipants = (uids: string[]) => void;

export const subscribeParticipants = (callback: SubscribeOnChangeParticipants) => {
  return subscribeKey(state, "participants", participants => {
    const uids = Array.from(participants.values())
      .filter(participant => {
        const userInfo = state.userInfoMap.get(participant.id);
        return userInfo?.status === "BEFORE_FETCH";
      })
      .map(participant => participant.id);
    if (uids.length > 0) {
      callback(uids);
    }
  });
};
