import { filterMatchedPathsFromGlobPatterns } from "@hireroo/app-helper/files";
import { ProjectCodingEditorV3 } from "@hireroo/app-store/widget/shared/ProjectCodingEditorV3";
import * as ProjectHelpers from "@hireroo/project/helpers/fileTree";
import { HistoriesPathsModel, IndexModel } from "@hireroo/project-shared-utils";
import React from "react";

import { AgentServerFileTreeController } from "./AgentServerFileTreeController";
import { BASE_PATH } from "./definition";
import { FirebaseFileTreeController } from "./FirebaseFileTreeController";
import type * as Types from "./types";

type FileTreeStatus = "CONNECTED_SERVER" | "CONNECTING";
type ConnectedServerType = "WEBSOCKET" | "FIREBASE";

export type ExtendedParams = {
  fileTreeStatus: FileTreeStatus;
  connectedServerType: ConnectedServerType;
  didConnectAgentServer: boolean;
  /**
   * 初期コードにリセットする
   */
  resetAllCodes: () => void;
  /**
   * @初期コードはそのままで、 環境だけリセットする
   */
  resetOnlyDevEnvironment: () => void;
};

type ExtractFileTreeAction = Omit<Types.FileTreeControllerAction, "initialized">;

export type FileTreeControllerProps = {
  entityId: number;
  initialFileIndexes: string[];
  readOnlyFilePatterns: string[];
  historiesPathsModel: HistoriesPathsModel;
  indexModel: IndexModel;
  fileSyncEndpoint: string;
  questionId: number;
  questionVersion: string;
};

/**
 * 実装上の注意点
 * - historiesPathsModelのAPIをCALLする際は、FirebaseFileTreeController内で行うこと
 *   これを徹底することにより、APIがどこで呼ばれているのか明確になり、実装を追いやすくなる
 */
export const useFileTreeController = (props: FileTreeControllerProps): ExtractFileTreeAction & ExtendedParams => {
  const { indexModel, historiesPathsModel, initialFileIndexes, readOnlyFilePatterns } = props;
  const [fileTreeStatus, setFileTreeStatus] = React.useState<FileTreeStatus>("CONNECTING");
  const [connectedServerType, setConnectedServerType] = React.useState<ConnectedServerType>("FIREBASE");
  const [openedFiles, setOpenedFiles] = React.useState<string[]>([]);
  const [selectedFile, setSelectedFile] = React.useState<string | null>(null);
  const [fileTree, setFileTree] = React.useState<Types.Node>(
    ProjectHelpers.createNodeByFileIndexes(BASE_PATH, initialFileIndexes, readOnlyFilePatterns),
  );

  const { useAgentServerHealth } = ProjectCodingEditorV3.useCreateProjectEntityHooks(props.entityId);
  const agentServerHealth = useAgentServerHealth();

  /**
   * テンプレートコードに対してのみReadOnly判定を行う
   */
  const readOnlyFiles = React.useMemo(() => {
    return filterMatchedPathsFromGlobPatterns(initialFileIndexes, readOnlyFilePatterns);
  }, [initialFileIndexes, readOnlyFilePatterns]);

  const agentServerFileTreeController = React.useMemo(() => {
    return new AgentServerFileTreeController(props.fileSyncEndpoint, initialFileIndexes, readOnlyFiles);
  }, [props.fileSyncEndpoint, initialFileIndexes, readOnlyFiles]);

  const firebaseFileTreeController = React.useMemo(() => {
    return new FirebaseFileTreeController(
      initialFileIndexes,
      readOnlyFiles,
      {
        questionId: props.questionId,
        questionVersion: props.questionVersion,
      },
      historiesPathsModel,
      indexModel,
    );
  }, [initialFileIndexes, readOnlyFiles, props.questionId, props.questionVersion, historiesPathsModel, indexModel]);
  const [fileTreeController, setFileTreeController] = React.useState<Types.FileTreeController>(
    firebaseFileTreeController || agentServerFileTreeController,
  );

  React.useEffect(() => {
    const handleConnected = () => {
      setFileTreeStatus("CONNECTED_SERVER");
    };
    const handleInitialized = () => {
      setFileTreeController(agentServerFileTreeController);
      firebaseFileTreeController.recreateFileTree(agentServerFileTreeController.fileTree);
      setConnectedServerType("WEBSOCKET");
    };
    const handleDisconnected = () => {
      setConnectedServerType("FIREBASE");
    };
    const handleSelectedFile = (newSelectedFile: string | null) => {
      if (connectedServerType === "WEBSOCKET") {
        setSelectedFile(newSelectedFile);
      }
    };
    const handleOpenedFiles = (openedFiles: string[]) => {
      if (connectedServerType === "WEBSOCKET") {
        setOpenedFiles(openedFiles);
      }
    };
    const handleCreatedDirectory = (directoryPath: string) => {
      firebaseFileTreeController.createDirectory(directoryPath);
    };
    agentServerFileTreeController.on("connected", handleConnected);
    agentServerFileTreeController.on("initialized", handleInitialized);
    agentServerFileTreeController.on("disconnected", handleDisconnected);
    agentServerFileTreeController.on("selectedFile", handleSelectedFile);
    agentServerFileTreeController.on("openedFiles", handleOpenedFiles);
    agentServerFileTreeController.on("createdDirectory", handleCreatedDirectory);
    return () => {
      agentServerFileTreeController.off("connected", handleConnected);
      agentServerFileTreeController.off("initialized", handleInitialized);
      agentServerFileTreeController.off("disconnected", handleDisconnected);
      agentServerFileTreeController.off("selectedFile", handleSelectedFile);
      agentServerFileTreeController.off("openedFiles", handleOpenedFiles);
      agentServerFileTreeController.off("createdDirectory", handleCreatedDirectory);
    };
  }, [
    agentServerFileTreeController,
    fileTreeController.fileTree,
    connectedServerType,
    historiesPathsModel,
    initialFileIndexes,
    firebaseFileTreeController,
  ]);

  React.useEffect(() => {
    if (agentServerHealth) {
      agentServerFileTreeController.start();
    } else {
      agentServerFileTreeController.stop();
    }
  }, [agentServerFileTreeController, agentServerHealth]);

  React.useEffect(() => {
    const handleConnected = () => {
      /**
       * Firebase側と同期を取る
       */
      if (firebaseFileTreeController) {
        agentServerFileTreeController.recreateFileTree(firebaseFileTreeController.fileTree);
      }
      setFileTreeStatus("CONNECTED_SERVER");
    };
    const handleSelectedFile = (newSelectedFile: string | null) => {
      if (connectedServerType === "FIREBASE") {
        setSelectedFile(newSelectedFile);
      }
    };
    const handleOpenedFiles = (openedFiles: string[]) => {
      if (connectedServerType === "FIREBASE") {
        setOpenedFiles(openedFiles);
      }
    };
    const handleChangedFileTree = () => {
      if (firebaseFileTreeController) {
        /**
         * FileTreeの状態はfirebase経由で整合性をとる
         * これを行うことにより、Agent Serverが切断されたとしても復旧時にFileTreeからデータの整合性を担保することができるため
         *
         * firebaseFileTreeController.fileTreeのrootのobjectの参照が変わらないとReactのsetStateが差分検知せずにdispatchしない
         */
        setFileTree(structuredClone(firebaseFileTreeController.fileTree));
      }
    };

    firebaseFileTreeController.on("connected", handleConnected);
    firebaseFileTreeController.on("selectedFile", handleSelectedFile);
    firebaseFileTreeController.on("openedFiles", handleOpenedFiles);
    firebaseFileTreeController.on("changedFileTree", handleChangedFileTree);
    firebaseFileTreeController.start();
    return () => {
      firebaseFileTreeController.off("connected", handleConnected);
      firebaseFileTreeController.off("selectedFile", handleSelectedFile);
      firebaseFileTreeController.off("openedFiles", handleOpenedFiles);
      firebaseFileTreeController.off("changedFileTree", handleChangedFileTree);
    };
  }, [agentServerFileTreeController, connectedServerType, fileTreeController.fileTree, firebaseFileTreeController, historiesPathsModel]);

  React.useEffect(() => {
    if (!agentServerHealth) {
      /**
       * websocketのdisconnectedイベントで判定したかったがうまく動かないので、
       * pollingの結果を接続が確立されていることの判定条件とする
       */
      setConnectedServerType("FIREBASE");
    }
  }, [agentServerHealth]);

  React.useEffect(() => {
    return () => {
      agentServerFileTreeController.dispose();
      firebaseFileTreeController.dispose();
    };
  }, [firebaseFileTreeController, agentServerFileTreeController]);

  return {
    addFile: (at: string, name: string) => {
      firebaseFileTreeController.addFile(at, name);
      agentServerFileTreeController.addFile(at, name);
    },
    addDir: (at: string, name: string) => {
      firebaseFileTreeController.addDir(at, name);
      agentServerFileTreeController.addDir(at, name);
    },
    remove: (id: string) => {
      firebaseFileTreeController.remove(id);
      agentServerFileTreeController.remove(id);
    },
    update: (_id: string, _value: string) => {
      // firebaseに対してはfirepad経由で更新を行うためfirebaseFileTreeControllerのAPI Callは不要
      // firebase経由で状態の同期を取るため、agentServerFileTreeControllerのAPI Callは不要
    },
    selectFile: (newSelectedFile: string) => {
      firebaseFileTreeController.selectFile(newSelectedFile);
      agentServerFileTreeController.selectFile(newSelectedFile);
    },
    closeFile: (closedFile: string) => {
      firebaseFileTreeController.closeFile(closedFile);
      agentServerFileTreeController.closeFile(closedFile);
    },
    didConnectAgentServer: connectedServerType === "WEBSOCKET",
    cwd: agentServerFileTreeController.cwd,
    reconnect: () => {
      agentServerFileTreeController.reconnect();
    },
    fileTree: fileTree,
    selectedFile,
    filesOpened: openedFiles,
    fileTreeStatus,
    connectedServerType,
    resetAllCodes: () => {
      setConnectedServerType("FIREBASE");

      setOpenedFiles([]);
      setSelectedFile(null);
      firebaseFileTreeController.reset();
      agentServerFileTreeController.reset();
    },
    resetOnlyDevEnvironment: () => {
      setConnectedServerType("FIREBASE");
    },
  };
};
