import "firebase/compat/database";

import { INITIAL_VERSION } from "@hireroo/app-definition/question";
import { getRef, getTimestamp } from "@hireroo/firebase";
import firebase from "firebase/compat/app";
import { useCallback, useEffect, useRef, useState } from "react";

import { revisionFromId, revisionToId, SyncState } from "../firepad";
import { tuple } from "../tuple";

type QuestionEntityType = "challenge" | "project" | "quiz" | "systemDesign";

type QuestionMap = {
  id: number;
  version: string;
};

export type InterviewSyncState = SyncState & {
  q?: {
    i: number;
    v: string;
  };
};

export type InterviewType = "spot" | "demo" | "exam";

export type InterviewArgs = {
  entityId: number;
  interviewId: string;
  interviewType: InterviewType;
  entityType: QuestionEntityType;
  questionId: number;
  version: string;
};

const entityActionMap = {
  challenge: "selc",
  quiz: "selq",
  project: "selp",
  systemDesign: "sels",
} satisfies Record<QuestionEntityType, string>;

export const useInterview = (args: InterviewArgs) => {
  // To write the realtime log at interviews/${interviewId}
  const interviewIndexRef = useRef<number>(0);
  const interviewRef = useRef<firebase.database.Reference | undefined>();
  const [interviewReady, setInterviewReady] = useState<boolean>(false);
  const [isInterviewing, setIsInterviewing] = useState<boolean>(false);

  const [entityId, setEntityId] = useState<number>(args.entityId);
  const [entityType, setEntityType] = useState<QuestionEntityType>(args.entityType);
  const [questionMap, setQuestionMap] = useState<QuestionMap>({ id: args.questionId, version: args.version ?? INITIAL_VERSION });

  const setStartInterview = useCallback(
    async (interviewType: InterviewType) => {
      if (!interviewRef.current) {
        return;
      }
      await interviewRef.current.child(revisionToId(interviewIndexRef.current)).set({
        s: "start",
        v: interviewType,
        t: getTimestamp(),
      });
      await interviewRef.current.child(revisionToId(interviewIndexRef.current)).set({
        s: entityActionMap[entityType],
        v: entityId,
        q: { i: questionMap.id, v: questionMap.version },
        t: getTimestamp(),
      });
    },
    [entityId, entityType, questionMap.id, questionMap.version],
  );

  const setEndInterview = useCallback((interviewType: InterviewType) => {
    interviewRef.current?.child(revisionToId(interviewIndexRef.current)).set({
      s: "end",
      v: interviewType,
      t: getTimestamp(),
    });
  }, []);

  const setChangeQuestion = useCallback(
    (id: number, entity: QuestionEntityType, questionId: number, questionVersion: string) => {
      if (!interviewReady) return;
      const action = entityActionMap[entity];
      setQuestionMap(prev => {
        const allSameValue: boolean = [entityId === id, entityType === entity, prev.id === questionId, prev.version === questionVersion].every(
          value => value,
        );
        /**
         * If the Entity is exactly the same as the currently selected Entity, skip the process.
         */
        if (allSameValue) {
          return prev;
        }
        interviewRef.current?.child(revisionToId(interviewIndexRef.current)).set({
          s: action,
          v: id,
          q: { i: questionId, v: questionVersion },
          t: getTimestamp(),
        });
        return {
          ...prev,
          id: questionId,
          version: questionVersion,
        };
      });
    },
    [entityId, entityType, interviewReady],
  );

  const setStateFromEvent = (state: SyncState) => {
    switch (state.s) {
      case "selc":
        setEntityId(state.v);
        setEntityType("challenge");
        break;
      case "selq":
        setEntityId(state.v);
        setEntityType("quiz");
        break;
      case "selp":
        setEntityId(state.v);
        setEntityType("project");
        break;
      case "sels":
        setEntityId(state.v);
        setEntityType("systemDesign");
        break;
      case "end":
        setIsInterviewing(false);
        break;
    }
  };

  const getLatestState = (data: {
    [key: string]: InterviewSyncState;
  }): { s?: boolean; e?: boolean; q?: { id: number; type: QuestionEntityType; qId: number; qVersion: string }; k?: number } => {
    // s: start interview, e: end interview, q: object(id: entityId, type: entityType: qId: questionId, qVersion: questionVersion ), k: k: latest index,
    const v: { s?: boolean; e?: boolean; q?: { id: number; type: QuestionEntityType; qId: number; qVersion: string }; k?: number } = {};
    Object.keys(data)
      .sort()
      .forEach((key: string) => {
        if (data[key].s === "start") v.s = true;
        if (data[key].s === "end") v.e = true;
        if (data[key].s === "selc")
          v.q = {
            id: data[key].v as number,
            type: "challenge",
            qId: (data[key].q?.i as number) ?? 0,
            qVersion: data[key].q?.v ?? INITIAL_VERSION,
          };
        if (data[key].s === "selq")
          v.q = { id: data[key].v as number, type: "quiz", qId: (data[key].q?.i as number) ?? 0, qVersion: data[key].q?.v ?? INITIAL_VERSION };
        if (data[key].s === "selp")
          v.q = {
            id: data[key].v as number,
            type: "project",
            qId: (data[key].q?.i as number) ?? 0,
            qVersion: data[key].q?.v ?? INITIAL_VERSION,
          };
        if (data[key].s === "sels")
          v.q = {
            id: data[key].v as number,
            type: "systemDesign",
            qId: (data[key].q?.i as number) ?? 0,
            qVersion: data[key].q?.v ?? INITIAL_VERSION,
          };
        v.k = revisionFromId(key);
      });
    return v;
  };

  useEffect(() => {
    // Connect to firebase for realtime sync
    interviewRef.current = getRef("interview", `interviews/${args.interviewId}/state`);
    interviewIndexRef.current = 0;
    let initialLoaded = false;

    interviewRef.current?.on("child_added", snapshot => {
      if (!initialLoaded) return;
      interviewIndexRef.current = revisionFromId(snapshot.key as string) + 1;
      setStateFromEvent(snapshot.val());
    });

    interviewRef.current?.once("value", snapshot => {
      const data: Record<string, SyncState> | null = snapshot.val();

      // Data cannot be inserted at the start of the interview,
      // so if there is no data at the time of fetch, it will be inserted.
      // If you have a better way to do this, please fix it...
      if (!data) {
        setStartInterview(args.interviewType);
        setIsInterviewing(true);
      }

      if (data) {
        // state under interviews/${interviewId}/state holds `start`, `end` and `sel{c|q|p|s}`.
        // only latest.s, latest.e, latest.q and latest.k will be considered, and others will be ignored.
        const latest = getLatestState(data);
        if (latest.s !== undefined) setIsInterviewing(true);
        if (latest.e !== undefined) setIsInterviewing(false);
        if (latest.k !== undefined) interviewIndexRef.current = latest.k + 1;

        // If 'latest.q !== undefined', it is the first time start,
        // and in that case, the initial value is put in to deal with the problem.
        const lastEntity = latest.q;
        if (lastEntity !== undefined) {
          setEntityId(lastEntity.id);
          setEntityType(lastEntity.type);
          setQuestionMap(prev => ({
            ...prev,
            id: lastEntity.qId,
            version: lastEntity.qVersion,
          }));
        } else {
          setChangeQuestion(entityId, entityType, questionMap.id, questionMap.version);
        }
      }
      // Initial sync is done.
      initialLoaded = true;
      setInterviewReady(true);
    });
  }, [args.interviewId, args.interviewType, entityId, entityType, questionMap.id, questionMap.version, setChangeQuestion, setStartInterview]);

  const state = {
    isInterviewing,
    interviewReady,
    entityId,
    entityType,
    questionMap,
  };

  const dispatcher = {
    setStartInterview,
    setEndInterview,
    setChangeQuestion,
  };

  return tuple(state, dispatcher);
};
