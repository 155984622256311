import { App } from "@hireroo/app-store/essential/talent";
import { AssessmentsIdDetailStore } from "@hireroo/app-store/page/t/assessments_id_detail";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import AssessmentDetailForTalentContainer, { AssessmentDetailForTalentContainerProps } from "./Container";

export type AssessmentDetailForTalentFetchContainerProps = {
  assessmentId: string;
};

const AssessmentDetailForTalentFetchContainer: React.FC<AssessmentDetailForTalentFetchContainerProps> = props => {
  const appStatus = App.useStatus();
  const { assessmentId } = props;
  const initialized = AssessmentsIdDetailStore.useInitialized();
  const offset = AssessmentsIdDetailStore.useListBestOffset();

  const [result, refresh] = Graphql.useGetAssessmentForAssessmentsIdDetailTalentQuery({
    variables: {
      assessmentId: assessmentId,
      withCount: true,
      size: 30,
      offset: offset,
      isDescending: true,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "cache-and-network",
  });

  React.useEffect(() => {
    if (result.data?.assessment) {
      AssessmentsIdDetailStore.updateAssessment(result.data.assessment);
    }
    if (result.data?.listBestExamsByAssessmentId) {
      AssessmentsIdDetailStore.initializeListBestExams(
        result.data.listBestExamsByAssessmentId.exams,
        result.data.listBestExamsByAssessmentId.count,
        result.data.listBestExamsByAssessmentId.hasNext,
      );
    }
  }, [result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER" />;
  }

  const containerProps: AssessmentDetailForTalentContainerProps = {
    assessmentId: assessmentId,
  };

  return <AssessmentDetailForTalentContainer {...containerProps} />;
};

AssessmentDetailForTalentFetchContainer.displayName = "AssessmentDetailForTalentFetchContainer";

export default withErrorBoundary(AssessmentDetailForTalentFetchContainer, {});
