import type * as Types from "./types";

export const ALREADY_RUN_TUTORIAL = "ALREADY_RUN_TUTORIAL";

export const kindMap: Record<Types.Kind, Types.Key> = {
  CANDIDATE_SYSTEM_DESIGN: "candidate-system-design",
  CANDIDATE_CHALLENGE: "candidate-challenge",
  EMPLOYEE_DEFAULT: "employee-default",
  EMPLOYEE_SYSTEM_DESIGN: "employee-system-design",
  EMPLOYEE_CHALLENGE: "employee-challenge",
  SKIP: "skip",
};
