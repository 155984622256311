import { useTranslation } from "@hireroo/i18n";
import Markdown from "@hireroo/markdown-v2/react";
import CancelRounded from "@mui/icons-material/CancelRounded";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import { alpha, Palette, styled } from "@mui/material/styles";
import * as React from "react";

type ActionState = keyof Pick<Palette, "success" | "warning" | "error">;
type OptionStatus = "CORRECT" | "INCORRECT" | "SKIP";

const StyledFormControlLabel = styled(FormControlLabel)<{ ownerState: { state?: ActionState } }>(({ theme, ownerState }) => ({
  width: "100%",
  marginTop: "10px",
  marginLeft: "8px",
  paddingTop: "5px",
  paddingBottom: "5px",
  paddingLeft: "5px",
  backgroundColor: ownerState.state ? alpha(theme.palette[ownerState.state].light, 0.5) : "inherit",
}));
const StatusToPaletteMap: Record<OptionStatus, ActionState> = {
  CORRECT: "success",
  INCORRECT: "error",
  SKIP: "warning",
};

const OptionIconMap = {
  CORRECT: <CheckBoxIcon fontSize="small" color="success" sx={{ mr: 1 }} />,
  INCORRECT: <CancelRounded fontSize="small" color="error" sx={{ mr: 1 }} />,
  SKIP: <CancelRounded fontSize="small" color="warning" sx={{ mr: 1 }} />,
};

type OptionItem = {
  id: number;
  title: string;
  status: OptionStatus;
};

export type SubmissionMultiOptionAnswerProps = {
  optionItems: OptionItem[];
  submittedOptionIds?: number[];
  isSkipped: boolean;
};

const SubmissionMultiOptionAnswer: React.FC<SubmissionMultiOptionAnswerProps> = props => {
  const { t } = useTranslation();
  const submittedOptionIdsSet = React.useMemo(() => {
    return new Set<number>(props.submittedOptionIds ? [...props.submittedOptionIds] : []);
  }, [props.submittedOptionIds]);

  return (
    <FormGroup aria-label="options">
      {props.optionItems.map(option => (
        <Stack key={option.id} direction="row" alignItems="center" spacing={1}>
          <StyledFormControlLabel
            // Radio form passed "string" so value is "string"
            key={`id-${option.id}`}
            value={String(option.id)}
            control={
              option.status === "CORRECT" || submittedOptionIdsSet.has(option.id) ? (
                OptionIconMap[option.status]
              ) : (
                <CheckBoxOutlineBlankIcon fontSize="small" sx={{ mr: 1 }} />
              )
            }
            label={
              <React.Suspense>
                <Markdown children={option.title} size="small" />
              </React.Suspense>
            }
            ownerState={{ state: submittedOptionIdsSet.has(option.id) ? StatusToPaletteMap[option.status] : undefined }}
          />
        </Stack>
      ))}
      <Stack direction="row" alignItems="center" spacing={1}>
        <StyledFormControlLabel
          value="skipped"
          control={<CheckBoxOutlineBlankIcon fontSize="small" sx={{ mr: 1 }} />}
          label={
            <React.Suspense>
              <Markdown children={t("この問題をスキップ")} size="small" />
            </React.Suspense>
          }
          ownerState={{ state: props.isSkipped ? StatusToPaletteMap["SKIP"] : undefined }}
        />
      </Stack>
    </FormGroup>
  );
};

SubmissionMultiOptionAnswer.displayName = "SubmissionMultiOptionAnswer";

export default SubmissionMultiOptionAnswer;
