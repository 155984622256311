import Markdown from "@hireroo/markdown-v2/react";
import { Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";

import TestResultItem, { Status, TestResultItemProps } from "./parts/TestResultItem";

type SingleTestResult = {
  kind: "SINGLE";
  value: TestResultItemProps;
};

type MultiTestResult = {
  kind: "MULTI";
  values: TestResultItemProps[];
};

type TestResultProps = SingleTestResult | MultiTestResult;

const generateCommandEndpoint = (path: string, query: string): string | null => {
  if (path === "") return null;
  return path + `${query !== "" ? `?${query}` : ""}`;
};

export type BackendTestCaseV3Props = {
  tcIndex: number;
  status: Status;
  testResult: TestResultProps;
  description?: string;
  alternativeMessage?: string;
};

const BackendTestCaseV3: React.FC<BackendTestCaseV3Props> = props => {
  return (
    <Box width="100%">
      {props.description && (
        <React.Suspense>
          <Markdown size={"small"} children={props.description} />
        </React.Suspense>
      )}
      {props.alternativeMessage ? (
        <Typography variant={"body2"} sx={{ mt: 4 }}>
          {props.alternativeMessage}
        </Typography>
      ) : (
        <Box>
          {props.testResult.kind === "SINGLE" && <TestResultItem key={`test-result-${props.tcIndex}`} {...props.testResult.value} />}
          {props.testResult.kind === "MULTI" && (
            <Stepper orientation="vertical">
              {props.testResult.values.map((testResult, index) => {
                const testResultItemProps: TestResultItemProps = { ...testResult };
                const commandEndpoint = generateCommandEndpoint(testResult.path ?? "", testResult.query ?? "");
                return (
                  <Step key={`tc-${props.tcIndex}-command-${index}`} active={true}>
                    <StepLabel>
                      {testResult.method} {commandEndpoint ?? ""}
                    </StepLabel>
                    <StepContent>
                      <TestResultItem key={`test-result-${index}`} {...testResultItemProps} />
                    </StepContent>
                  </Step>
                );
              })}
            </Stepper>
          )}
        </Box>
      )}
    </Box>
  );
};

BackendTestCaseV3.displayName = "BackendTestCase";

export default BackendTestCaseV3;
