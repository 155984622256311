import ReadabilityGraph, { ReadabilityGraphProps } from "@hireroo/charts/react/ReadabilityGraph";
import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import * as React from "react";

import TitleWithScoreBar from "../../../modules/TitleWithScoreBar/TitleWithScoreBar";
import Link from "../../../primitive/Link/Link";

export type ReportChallengeReadabilitySectionProps = {
  score?: number;
  link: string;
  graph: ReadabilityGraphProps;
  targetElementId?: string;
};

const ReportChallengeReadabilitySection: React.FC<ReportChallengeReadabilitySectionProps> = props => {
  const { t } = useTranslation();
  return (
    <Box id={props.targetElementId}>
      <TitleWithScoreBar title={t("可読性")} score={props.score} color="warning" mb={3} />
      <Box mb={2}>
        <Link href={props.link} target="_blank" color="secondary">
          {t("循環的複雑度とは")}
        </Link>
      </Box>
      <Box height={300}>
        <React.Suspense>
          <ReadabilityGraph {...props.graph} />
        </React.Suspense>
      </Box>
    </Box>
  );
};

ReportChallengeReadabilitySection.displayName = "ReportChallengeReadabilitySection";

export default ReportChallengeReadabilitySection;
