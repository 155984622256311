import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type EmployeeSideBarContentContainerProps = {};

const EmployeeSideBarContentContainer: React.FC<EmployeeSideBarContentContainerProps> = () => {
  const talentSideBarContentProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.TalentSideBarContent {...talentSideBarContentProps} />
    </ErrorBoundary>
  );
};

EmployeeSideBarContentContainer.displayName = "EmployeeSideBarContentContainer";

export default withErrorBoundary(EmployeeSideBarContentContainer, {});
