import { languageMapForDisplay } from "@hireroo/i18n";
import { useTranslation } from "@hireroo/i18n";
import type { QuizPackageForm } from "@hireroo/validator";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, SubmitHandler, useFieldArray } from "react-hook-form";

import InputControlTextField, { InputControlTextFieldProps } from "../../primitive/InputControl/InputControlTextField";
import SelectControl, { SelectControlProps } from "../../primitive/InputControl/SelectControl";
import EditLanguageTab, { EditLanguageTabProps } from "../../usecase/EditLanguageTab/EditLanguageTab";
import { useQuizPackageDetailContext } from "./Context";
import FieldHeader from "./parts/FieldHeader";
import PublicSettingField, { PublicSettingFieldProps } from "./parts/PublicSettingField";
import QuizPackageDetailField, { QuizPackageDetailFieldProps } from "./parts/QuizPackageDetailField";
import VersionDialog, { VersionDialogProps } from "./parts/VersionDialog";

export { QuizPackageDetailProvider, type QuizPackageDetailProviderProps, useQuizPackageDetailContext } from "./Context";

export type QuizPackageDetailProps = {
  debugOnlyOnHandleSubmit?: SubmitHandler<QuizPackageForm.QuizPackageFormSchema>;
  publicSettingField?: PublicSettingFieldProps;
  difficultyField: Pick<SelectControlProps, "menuItems">;
  packageDetailsTabField: Pick<EditLanguageTabProps, "menu">;
  versionDialog: VersionDialogProps;
};

const QuizPackageDetail: React.FC<QuizPackageDetailProps> = props => {
  const { t } = useTranslation();
  const { method } = useQuizPackageDetailContext();
  const selectedLanguageArrayField = useFieldArray({
    control: method.control,
    name: "selectedLanguages",
  });
  const timeLimitFieldProps: InputControlTextFieldProps = {
    label: t("制限時間"),
    type: "number",
    fullWidth: true,
    helperText: t("入力された値は問題の初期値として利用されます。"),
    InputLabelProps: {
      shrink: true,
    },
    InputProps: {
      endAdornment: <InputAdornment position="end">{t("分")}</InputAdornment>,
    },
    required: true,
  };
  const difficultyFieldProps: SelectControlProps = {
    label: t("難易度") + "* ",
    menuItems: props.difficultyField.menuItems,
  };

  const packageDetails = method.getValues("packageDetails");
  const selectedLanguages = method.getValues("selectedLanguages");
  const tabFieldProps: EditLanguageTabProps = {
    menu: props.packageDetailsTabField.menu,
    items: selectedLanguages.map((language, idx) => {
      const packageDetail = packageDetails[language.value];
      const detailFieldProps: QuizPackageDetailFieldProps = {
        titleField: {
          defaultValue: packageDetail?.title ?? "",
          name: `packageDetails.${language.value}.title`,
        },
        MarkdownPreviewEditor: {
          defaultValue: packageDetail?.description ?? "",
          name: `packageDetails.${language.value}.description`,
        },
      };
      return {
        id: language.value,
        name: languageMapForDisplay[language.value],
        tab: {
          closeButton: (selectedLanguages.length > 0 || undefined) && {
            onClick: () => {
              selectedLanguageArrayField.remove(idx);
            },
          },
        },
        Content: <QuizPackageDetailField {...detailFieldProps} />,
      };
    }),
  };

  return (
    <FormProvider {...method}>
      {props.debugOnlyOnHandleSubmit && <Button onClick={method.handleSubmit(props.debugOnlyOnHandleSubmit)}>DEBUG SUBMIT HANDLER</Button>}
      <Box mt={5}>
        <Box display="flex" justifyContent="space-between" gap={4}>
          <Box display="flex" flexDirection="column" width="100%">
            <FieldHeader icon="Settings" title={t("問題の一般設定をする")} />
            {props.publicSettingField && <PublicSettingField {...props.publicSettingField} name="isPublic" />}
            <Box display="flex" gap={2} width="66%" mt={4}>
              <InputControlTextField name="timeLimit" {...timeLimitFieldProps} />
              <SelectControl name="difficulty" {...difficultyFieldProps} />
            </Box>
          </Box>
        </Box>

        <Box mt={5}>
          <Box display="flex" alignItems="center" gap={2}>
            <FieldHeader icon="Article" title={t("問題概要を入力する")} />
          </Box>

          <Box mt={2}>
            <Typography variant="body2">
              {t("問題で利用するタイトルや本文を入力します。問題で利用する言語は複数入力可能です。メインで利用する言語以外の入力は任意です。")}
            </Typography>
          </Box>
        </Box>

        <Box mt={4}>
          <EditLanguageTab {...tabFieldProps} />
        </Box>
      </Box>
      <VersionDialog {...props.versionDialog} />
    </FormProvider>
  );
};

QuizPackageDetail.displayName = "QuizPackageDetail";

export default QuizPackageDetail;
