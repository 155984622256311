import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type HomeContainerProps = {};

const HomeContainer: React.FC<HomeContainerProps> = () => {
  const homeProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Pages.Home {...homeProps} />
    </ErrorBoundary>
  );
};

HomeContainer.displayName = "HomeContainer";

export default withErrorBoundary(HomeContainer, {});
