import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import ToggleButton, { ToggleButtonProps } from "@mui/material/ToggleButton";
import ToggleButtonGroup, { ToggleButtonGroupProps } from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type Item = {
  value: string;
  disabled: boolean;
  status: "PENDING" | "COMPLETED";
  tooltipText: string;
  displayText: string;
  onClick?: () => void;
};

export type QuestionSwitcherProps = {
  className?: string;
  defaultValue: string;
  disabled?: boolean;
  items: Item[];
  onChange: (value: string) => void;
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  justifyContent: "space-between",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette["Secondary/Shades"].p12,
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    border: 0,
  },
  "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
    borderRadius: theme.shape.borderRadius,
  },
  "& .MuiToggleButtonGroup-grouped:not(:last-of-type)": {
    borderRadius: theme.shape.borderRadius,
  },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  "&:hover": {
    color: theme.palette.secondary.light,
  },
  borderRadius: theme.shape.borderRadius * 2,
}));

const QuestionSwitcher: React.FC<QuestionSwitcherProps> = props => {
  const { onChange } = props;
  const defaultValue = props.defaultValue || props.items[0]?.value || "";
  const [value, setValue] = React.useState<string>(defaultValue);
  React.useEffect(() => {
    setValue(props.defaultValue);
  }, [props.defaultValue]);
  const toggleButtonGroupProps: ToggleButtonGroupProps = {
    color: "primary",
    value: value,
    onChange: (_, newValue) => {
      if (newValue !== null) {
        setValue(newValue);
        onChange(newValue);
      }
    },
    exclusive: true,
    disabled: props.disabled,
    className: props.className,
  };
  return (
    <StyledToggleButtonGroup {...toggleButtonGroupProps}>
      {props.items.map(item => {
        const selected = value === item.value;
        const toggleButtonProps: ToggleButtonProps = {
          value: item.value,
          sx: { height: "32px", width: "32px", fontSize: "14px" },
          disabled: item.disabled,
          "aria-label": item.value,
          color: "secondary",
          onClick: item.onClick,
        };
        return (
          <StyledToggleButton key={item.value} {...toggleButtonProps}>
            <Tooltip title={item.tooltipText}>
              <Stack direction="row" spacing={0.2} alignItems="center">
                {item.status === "COMPLETED" && (
                  <CheckCircleOutlineOutlinedIcon color={selected ? undefined : "success"} sx={{ fontSize: "1.1rem" }} />
                )}
                {item.status === "PENDING" && <Typography variant="button">{item.displayText}</Typography>}
              </Stack>
            </Tooltip>
          </StyledToggleButton>
        );
      })}
    </StyledToggleButtonGroup>
  );
};

QuestionSwitcher.displayName = "QuestionSwitcher";

export default QuestionSwitcher;
