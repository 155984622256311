import { formatSeconds, unixToDateFormat } from "@hireroo/formatter/time";
import type * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Pages } from "@hireroo/presentation";

export type GenerateTestOverviewSectionPropsArgs = {
  spot: Graphql.SpotForEntryScreeningsTestsIdFragment;
};

export const useGenerateTestOverviewSectionProps = (
  args: GenerateTestOverviewSectionPropsArgs,
): Pages.EntryScreeningProps["testOverviewSection"] => {
  const { spot } = args;
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const companyName: string = spot.company?.name || t("会社名不明");

  return {
    companyName: companyName,
    submissionDeadline: ((): string => {
      if (spot.willExpireAtSeconds) {
        return unixToDateFormat(spot.willExpireAtSeconds);
      }
      return t("なし");
    })(),
    questionNum: spot.entityCount,
    timeLimit: formatSeconds(spot.remainingTimeSeconds, lang),
    message: spot.messageForCandidate || undefined,
  };
};
