/**
 *
 * The <style /> tag created by @typefox/monaco-editor-react temporarily caches to avoid conflicts with the existing @monaco-editor/react.
 * "@typefox/monaco-editor-react"によって作成される<style />タグが
 * 既存の"@monaco-editor/react"と干渉するため、一時的にキャッシュする。
 *
 * Since the Monokai theme registered as an extension with @typefox/monaco-editor-react is not reloaded, this measure is being taken.
 * "@typefox/monaco-editor-react"時にextensionとして登録されているmonokai themeは再読み込みされないため、
 * この対策を取っている
 */
let element: Node | null = null;

export const hideVscodeTheme = () => {
  const tmpElement = document.querySelector(".vscode-tokens-styles");
  const clonedElement = tmpElement?.cloneNode(true);
  if (clonedElement) {
    /**
     * Ensure that the Element always exists.
     *
     * 常にElementが存在する状態にする
     */
    element = clonedElement;
  }
  tmpElement?.remove();
};

export const showVscodeTheme = () => {
  const target = document.querySelector(".vscode-tokens-styles");
  if (element && !target) {
    document.head.appendChild(element);
    /**
     * To prevent it from being affected by remove, clone the reference to the element and overwrite it.
     *
     * removeによって巻き込まれないようにするため、elementの参照はcloneして上書きする。
     */
    element = element.cloneNode(true);
  }
};
