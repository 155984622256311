import { TestTimelimit } from "@hireroo/app-store/widget/shared/TestTimelimit";
import { remainingTimeFormat } from "@hireroo/formatter/time";
import { useLanguageCode } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

export type GenerateScreeningTestTimelimitPropsArgs = {};

const TIME_LIMIT_THRESHOLD_IN_MINUTES = 10;

export const useGenerateProps = (_args: GenerateScreeningTestTimelimitPropsArgs): Widget.ScreeningTestTimelimitProps => {
  /**
   * Here is the cause of the re-rendering.
   */
  const timer = TestTimelimit.useCountDownTimer();
  const percentageOfTimeRemaining = TestTimelimit.usePercentageOfTimeRemaining();

  const languageCode = useLanguageCode();
  const remainTimeDisplayText = remainingTimeFormat({
    days: timer.days,
    hours: timer.hours,
    minutes: timer.minutes,
    seconds: timer.seconds,
    language: languageCode,
  });

  React.useEffect(() => {
    const isTimeout = timer.days === 0 && timer.hours === 0 && timer.minutes === 0 && timer.seconds === 0;
    if (isTimeout) {
      TestTimelimit.updateStatus("TIMEOUT");
    }
  }, [timer.days, timer.hours, timer.minutes, timer.seconds]);

  const status = React.useMemo((): "GOOD" | "WARNING" => {
    if (timer.days === 0 && timer.hours === 0 && timer.minutes < TIME_LIMIT_THRESHOLD_IN_MINUTES) {
      return "WARNING";
    }
    return "GOOD";
  }, [timer.days, timer.hours, timer.minutes]);

  return {
    status: status,
    displayText: remainTimeDisplayText,
    value: percentageOfTimeRemaining,
  };
};
