import { subscribeKey } from "valtio/utils";

import { state } from "./State";
import * as Types from "./types";

export type SubscribeCurrentQuestionSearchFilterCallback = (searchFilter: Types.QuestionSearchFilter, pager: Types.Pager) => void;

export const subscribeCurrentQuestionSearchFilter = (callback: SubscribeCurrentQuestionSearchFilterCallback) => {
  return subscribeKey(state, "questionSearchQuery", questionSearchQuery => {
    callback(questionSearchQuery.searchFilter, questionSearchQuery.pager);
  });
};

export type SubscribeCurrentQuestionPackageSearchFilterCallback = (
  searchFilter: Types.QuestionPackagesSearchFilter,
  pager: Types.Pager,
) => void;

export const subscribeCurrentQuestionPackageSearchFilter = (callback: SubscribeCurrentQuestionPackageSearchFilterCallback) => {
  return subscribeKey(state, "questionPackageSearchQuery", questionPackageSearchQuery => {
    callback(questionPackageSearchQuery.searchFilter, questionPackageSearchQuery.pager);
  });
};
