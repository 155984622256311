import {
  extractQuestionTitle,
  generateCompletedEntities,
  generateEntityKey,
  generateNormalizedEntity,
  NormalizedEntity,
} from "@hireroo/app-helper/normalizer";
import { AssessmentsIdStore } from "@hireroo/app-store/page/t/assessments_id";
import { ChallengeCodingEditor } from "@hireroo/app-store/widget/shared/ChallengeCodingEditor";
import { ProjectCodingEditorV3 } from "@hireroo/app-store/widget/shared/ProjectCodingEditorV3";
import { QuizCodingEditor } from "@hireroo/app-store/widget/shared/QuizCodingEditor";
import { SystemDesignCodingEditor } from "@hireroo/app-store/widget/shared/SystemDesignCodingEditor";
import { TestTimelimit } from "@hireroo/app-store/widget/shared/TestTimelimit";
import { ExamNavigation } from "@hireroo/app-store/widget/t/ExamNavigation";
export const startSubscribeOnChangeExam = () => {
  return AssessmentsIdStore.subscribeOnChangeExam(exam => {
    ExamNavigation.clear();
    ExamNavigation.initialize();
    TestTimelimit.clear();
    TestTimelimit.initialize({
      didStartAtSeconds: exam.didStartAtSeconds ?? 0,
      timeLimitSeconds: exam.timeLimitSeconds ?? 0,
      willEndAtSeconds: exam.willEndAtSeconds ?? 0,
    });
    const completedEntities = generateCompletedEntities(exam.entities);
    const completedEntityKeySet = new Set(completedEntities.map(entity => generateEntityKey(entity)));
    exam.entities.forEach(entity => {
      switch (entity.__typename) {
        case "ChallengeEntity": {
          ChallengeCodingEditor.initialize(entity, { enableWebSearch: false, enableChatGPT: false, enableHint: true });
          break;
        }
        case "SystemDesignEntity": {
          SystemDesignCodingEditor.initialize(entity, { enabledHint: true });
          break;
        }
        case "ProjectEntity": {
          ProjectCodingEditorV3.initialize(entity);
          break;
        }
        case "QuizEntity": {
          QuizCodingEditor.initialize(entity);
          break;
        }
      }
    });
    const entities = exam.entities.map<ExamNavigation.EntityForNavigation>(entity => {
      const key = generateEntityKey(entity);
      const normalizedEntity = generateNormalizedEntity(entity);
      return {
        ...extractQuestionTitle(entity),
        key,
        completed: completedEntityKeySet.has(key),
        ...normalizedEntity,
      };
    }, []);
    ExamNavigation.initializeEntities(entities);
    if (entities.length) {
      const entity = entities[0];
      ExamNavigation.setSelectedEntityKey(entity.key);
    }
  });
};

const goToNotYetSubmittedQuestion = (submittedEntityKey: string): void => {
  const entities = AssessmentsIdStore.getEntities();
  const entitiesFromInterviewNavigation = ExamNavigation.getEntities();
  const alreadyCompletedKeys = entitiesFromInterviewNavigation.filter(target => target.completed).map(target => target.key);
  const completedEntities = AssessmentsIdStore.getCompletedEntities();
  const completedKeySet = new Set([...completedEntities.map(generateEntityKey), ...alreadyCompletedKeys, submittedEntityKey]);
  const notCompletedEntities = entities.filter(entity => !completedKeySet.has(generateEntityKey(entity)));
  if (notCompletedEntities.length > 0) {
    const nextEntity = notCompletedEntities[0];
    const nextEntityKey = generateEntityKey(nextEntity);
    ExamNavigation.setSelectedEntityKey(nextEntityKey);
  }
};

type StartSubscribeShowingEntityKeyArgs = {
  onChangeShowingEntity: (entity: NormalizedEntity) => void;
};

export const startSubscribeOnChangeShowingEntity = (args: StartSubscribeShowingEntityKeyArgs) => {
  return ExamNavigation.subscribeOnChangeShowingEntity((_, entity) => {
    args.onChangeShowingEntity({
      entityType: entity.entityType,
      entityId: entity.entityId,
      questionId: entity.questionId,
      questionVersion: entity.questionVersion,
    });
  });
};

export const startSubscribeSystemDesignCodingEditor = () => {
  return SystemDesignCodingEditor.subscribeOnSubmitEntity(entity => {
    const submittedEntityKey = generateEntityKey(entity);
    ExamNavigation.completeEntity(submittedEntityKey);
    goToNotYetSubmittedQuestion(submittedEntityKey);
  });
};

export const startSubscribeChallengeCodingEditor = () => {
  return ChallengeCodingEditor.subscribeOnSubmitEntity(entity => {
    const submittedEntityKey = generateEntityKey(entity);
    ExamNavigation.completeEntity(submittedEntityKey);
    goToNotYetSubmittedQuestion(submittedEntityKey);
  });
};

export const startSubscribeChallengeCodingEditorLoading = () => {
  return ChallengeCodingEditor.subscribeOnLoadingChange(status => {
    ExamNavigation.updateLoadingStatus(status);
  });
};

export const startSubscribeQuizCodingEditorLoading = () => {
  return QuizCodingEditor.subscribeOnSubmitStatus(status => {
    ExamNavigation.updateLoadingStatus(status === "LOADING" ? status : "NONE");
  });
};

export const startSubscribeProjectCodingEditorV3 = () => {
  return ProjectCodingEditorV3.subscribeOnSubmitEntity(entity => {
    const submittedEntityKey = generateEntityKey(entity);
    ExamNavigation.completeEntity(submittedEntityKey);
    goToNotYetSubmittedQuestion(submittedEntityKey);
  });
};

export const startSubscribeQuizCodingEditor = () => {
  return QuizCodingEditor.subscribeOnSubmitEntity(entity => {
    const submittedEntityKey = generateEntityKey(entity);
    ExamNavigation.completeEntity(submittedEntityKey);
    goToNotYetSubmittedQuestion(submittedEntityKey);
  });
};

export const startSubscribeTimeoutStatus = () => {
  return TestTimelimit.subscribeTimeoutStatus(() => {
    AssessmentsIdStore.updateEndExamStatus("TIMELIMIT_HAS_EXPIRED");
  });
};
