import { ClipboardEvent, CodeEditorInputEvent } from "@hireroo/app-helper/playback";
import { DeepReadonly } from "@hireroo/app-helper/types";
import * as React from "react";
import { useSnapshot } from "valtio";

import { state } from "./State";
import * as Types from "./types";

export const useInitialized = () => {
  const snapshot = useSnapshot(state);
  return snapshot.playbackManagerMap.size > 0;
};

export const useLatestEncodedPathWithIdMap = () => {
  const snapshot = useSnapshot(state);
  return snapshot.latestEncodedPathWithIdMap;
};

export const useSliderValue = () => {
  const snapshot = useSnapshot(state);
  return snapshot.sliderValue;
};

export const useSelectedFileIndex = () => {
  const snapshot = useSnapshot(state);
  return snapshot.selectedFileIndex;
};

export const useCurrentPlaybackManager = () => {
  const snapshot = useSnapshot(state);
  const playbackManager = snapshot.playbackManagerMap.get(snapshot.selectedFileIndex);
  if (!playbackManager) {
    throw new Error(`Please initialize playbackManager ${snapshot.selectedFileIndex}`);
  }
  return playbackManager;
};

export const useCodeEditorInputEvents = () => {
  const playbackManager = useCurrentPlaybackManager();

  return React.useMemo((): (CodeEditorInputEvent | undefined)[] => {
    return playbackManager.ticks.map((tick): CodeEditorInputEvent | undefined => {
      /**
       * It should also include `undefined` to store the number of tick events.
       */
      const codeEditorInputEvent = tick.events.find(event => event.kind === "CODE_EDITOR") as CodeEditorInputEvent | undefined;
      return codeEditorInputEvent;
    });
  }, [playbackManager]);
};

export const useClipboardEvents = () => {
  const playbackManager = useCurrentPlaybackManager();
  return React.useMemo((): (ClipboardEvent | undefined)[] => {
    return playbackManager.ticks.map((tick): ClipboardEvent | undefined => {
      const clipboardEvent = tick.events.find(
        event => event.kind === "EDITOR_COPY" || event.kind === "EDITOR_CUT" || event.kind === "EDITOR_PASTE",
      ) as ClipboardEvent | undefined;
      return clipboardEvent;
    });
  }, [playbackManager]);
};

export const useHasPasteEvent = () => {
  const playbackManager = useCurrentPlaybackManager();
  return React.useMemo(() => {
    return playbackManager.ticks.some(tick => tick.events.some(event => event.kind === "EDITOR_PASTE"));
  }, [playbackManager]);
};

export const useLastCodeEditorInputEventsIndex = () => {
  const codeEditorInputEvents = useCodeEditorInputEvents();
  return React.useMemo(() => {
    return codeEditorInputEvents.length - 1;
  }, [codeEditorInputEvents]);
};

export const usePlayStatus = (): DeepReadonly<Types.PlayStatus> => {
  const snapshot = useSnapshot(state);
  return snapshot.playStatus;
};

export const usePlaybackSettings = (): DeepReadonly<Types.PlaybackSettings> => {
  const snapshot = useSnapshot(state);
  return snapshot.playbackSettings;
};
