import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type ScreeningTestOverviewContainerProps = {
  companyId: number;
};

const ScreeningTestOverviewContainer: React.FC<ScreeningTestOverviewContainerProps> = props => {
  const screeningTestProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ScreeningTestOverview {...screeningTestProps} />
    </ErrorBoundary>
  );
};

ScreeningTestOverviewContainer.displayName = "ScreeningTestOverviewContainer";

export default withErrorBoundary(ScreeningTestOverviewContainer, {});
