import * as z from "zod";

export const ipAddressSchema = z.object({
  /** ip address of a user */
  ip: z.string(),
  /** city of origin for the IP address */
  /** this field sometimes doesn't return city depending on the IP address range */
  city: z.string().optional(),
  /** country of origin for the IP address */
  country: z.string(),
});
