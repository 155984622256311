/* eslint-disable no-useless-escape */
import "firebase/compat/database";

import { Validator } from "@hireroo/project-shared-utils";
import type { FlowAction } from "@hireroo/system-design/features";
import { FirebaseFieldSchema, PlaybackEvent } from "@hireroo/validator";
import { type IPlainTextOperation, PlainTextOperation, type TPlainTextOperation } from "@otjs/plaintext";
import * as Sentry from "@sentry/react";
import firebase from "firebase/compat/app";

import { CodeEditorInputEvent } from "../playback";

const REP = "rep";
const SET = "set";
const USET = "uset";
const ACCESS_EVENT_TYPE = "access";

export type AliasOptionActionType = typeof REP | typeof SET | typeof USET;

export type QuizSyncState = SyncState & {
  a?: AliasOptionActionType;
};

/**
 * @deprecated Use the Schema defined in Validation below to determine the type.
 *
 * packages/validator/src/external/PlaybackEvent.ts
 */
export const isOptionAction = (revision: MergedRevision): revision is QuizSyncState => {
  return "s" in revision;
};

/**
 * @deprecated Use the Schema defined in Validation below to determine the type.
 *
 * packages/validator/src/external/PlaybackEvent.ts
 */
export const isFreeTextAction = (revision: MergedRevision): revision is SyncOperation => {
  return "o" in revision;
};

/**
 * @deprecated Use the Schema defined in Validation below to determine the type.
 *
 * packages/validator/src/external/PlaybackEvent.ts
 */
export type SyncState = {
  s: string;
  t: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  v: any;
};

/**
 * @deprecated Use the Schema defined in Validation below to determine the type.
 *
 * packages/validator/src/external/PlaybackEvent.ts
 */
export type SyncOperation = {
  a: string;
  o: (string | number)[];
  t: number;
  /**
   * key for paste detection
   */
  k: string;
};

type OffsetValue = {
  text: string;
  position: number;
  selectionEnd: number;
};

type PasteEventValue = (number | string)[];
type CutCopyEventValue = OffsetValue[];

/**
 * @deprecated Use the Schema defined in Validation below to determine the type.
 *
 * packages/validator/src/external/PlaybackEvent.ts
 */
export interface ClipboardEvent {
  a: string;
  /** type of clipboard event, i.e. cut/copy/paste */
  s: "copy" | "cut" | "paste";
  t: number;
  v: PasteEventValue | CutCopyEventValue;
}

export type AccessEvent = {
  a: string;
  /** type of client event, i.e. focus/blur/access */
  s: string;
  /** geolocation, i.e. Tokyo, Japan */
  g?: string;
  /** IP address */
  l?: string;
  t: number;
};

export type MergedRevision = QuizSyncState | SyncOperation;

// Based off ideas from http://www.zanopha.com/docs/elen.pdf
const characters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

export function revisionToId(revision: number): string {
  if (revision === 0) {
    return "A0";
  }

  let str = "";
  while (revision > 0) {
    const digit = revision % characters.length;
    str = characters[digit] + str;
    revision -= digit;
    revision /= characters.length;
  }

  // Prefix with length (starting at 'A' for length 1) to ensure the id's sort lexicographically.
  const prefix = characters[str.length + 9];
  return prefix + str;
}

export function revisionFromId(revisionId: string): number {
  let revision = 0;
  for (let i = 1; i < revisionId.length; i++) {
    revision *= characters.length;
    revision += characters.indexOf(revisionId[i]);
  }
  return revision;
}

// https://github.com/joonhocho/firebase-encode/blob/master/src/index.js
const escapeRegExp = (str: string) => str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");

const create = (chars: string[]) => {
  const charCodes = chars.map(c => `%${c.charCodeAt(0).toString(16).toUpperCase()}`);

  const charToCode: { [key: string]: string } = {};
  const codeToChar: { [key: string]: string } = {};
  chars.forEach((c, i) => {
    charToCode[c] = charCodes[i];
    codeToChar[charCodes[i]] = c;
  });

  const charsRegex = new RegExp(`[${escapeRegExp(chars.join(""))}]`, "g");
  const charCodesRegex = new RegExp(charCodes.join("|"), "g");

  const encode = (str: string) => str.replace(charsRegex, match => charToCode[match]);
  const decode = (str: string) => str.replace(charCodesRegex, match => codeToChar[match]);

  return { encode, decode };
};

// http://stackoverflow.com/a/19148116/692528
const { encode, decode } = create(".$[]#/%".split(""));

// Without `/`.
const { encode: encodeComponents, decode: decodeComponents } = create(".$[]#%".split(""));

export { decode, decodeComponents, encode, encodeComponents };

/**
 * @deprecated DO NOT USE. NOT TYPE SAFE
 */
// TODO Refactoring
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sortObject = (obj: any) => {
  const sorted = Object.keys(obj).sort();
  const sortedObj = [];

  for (let i = 0; i < sorted.length; i++) {
    obj[sorted[i]].k = sorted[i];
    sortedObj.push(obj[sorted[i]]);
  }

  return sortedObj;
};

/**
 * @deprecated Please use fetchRevisionV2
 */
export const fetchRevisions = (firebaseRef: firebase.database.Reference, callback: (x: (SyncState | SyncOperation)[]) => void) => {
  firebaseRef.child("history").once("value", (snapshot: firebase.database.DataSnapshot) => {
    const revisions = snapshot.val();
    if (!revisions) {
      callback([]);
      return;
    }

    const sortedRevisions = sortObject(revisions);

    callback(sortedRevisions);
  });
};

/**
 * /challenges/{challengeId}/questions/{questionId}/languages/{language}/history
 */
export const fetchQuestionLanguageHistory = (firebaseRef: firebase.database.Reference): Promise<PlaybackEvent.Revision[]> => {
  return new Promise(resolve => {
    firebaseRef.child("history").once("value", (snapshot: firebase.database.DataSnapshot) => {
      const revisions = snapshot.val();
      const result = PlaybackEvent.RevisionObject.safeParse(revisions);
      if (!result.success) {
        resolve([]);
        return;
      }
      const revisionKeys = Object.keys(result.data).sort();
      const sortedRevisions = revisionKeys.map((key): PlaybackEvent.Revision => {
        return { ...result.data[key], k: key };
      });
      resolve(sortedRevisions);
    });
  });
};

/**
 * /liveCodings/{liveCodingId}/sessions/{sessionId}/languages
 */
export const fetchLiveCodingSessionChallenge = (firebaseRef: firebase.database.Reference): Promise<PlaybackEvent.RevisionHistories> => {
  return new Promise(resolve => {
    firebaseRef.once("value", (snapshot: firebase.database.DataSnapshot) => {
      const revisions = snapshot.val();
      const result = PlaybackEvent.RevisionHistoriesRecord.safeParse(revisions);
      if (!result.success) {
        Sentry.captureException(result.error);
        resolve({});
        return;
      }
      const histories: PlaybackEvent.RevisionHistories = {};
      Object.keys(result.data)
        .reverse()
        .forEach(runtime => {
          const h = result.data[runtime].history;
          if (!h) return;
          const revisionKeys = Object.keys(h).sort();
          histories[runtime] = revisionKeys.map((key): PlaybackEvent.Revision => {
            return { ...h[key], k: key };
          });
        });
      resolve(histories);
    });
  });
};

/**
 * /liveCodings/{liveCodingId}/sessions/{sessionId}/componentTypes
 */
export const fetchLiveCodingSessionComponentTypes = (firebaseRef: firebase.database.Reference): Promise<Record<string, FlowAction[]>> => {
  return new Promise(resolve => {
    firebaseRef.once("value", (snapshot: firebase.database.DataSnapshot) => {
      const histories: Record<string, FlowAction[]> = {};
      const revisions = snapshot.val();
      if (!revisions || typeof revisions !== "object") {
        resolve({});
        return;
      }
      Object.keys(revisions)
        .reverse()
        .forEach(componentType => {
          const h = revisions[componentType].history;
          if (!h) return;
          const revisionKeys = Object.keys(h).sort();
          histories[componentType] = revisionKeys.map((key): FlowAction => {
            return { ...h[key], k: key };
          });
        });
      resolve(histories);
    });
  });
};

/**
 * /challenges/{challengeId}/questions/{questionId}/languages/{language}/state
 */
export const fetchQuestionLanguageStates = (firebaseRef: firebase.database.Reference): Promise<PlaybackEvent.LanguageState[]> => {
  return new Promise(resolve => {
    firebaseRef.child("state").once("value", (snapshot: firebase.database.DataSnapshot) => {
      const states = snapshot.val();
      const result = PlaybackEvent.LanguageStateObject.safeParse(states);
      if (!result.success) {
        resolve([]);
        return;
      }
      const statesKeys = Object.keys(result.data).sort();
      const sortedSyncStates = statesKeys.map((key): PlaybackEvent.LanguageState => {
        return { ...result.data[key], k: key };
      });
      resolve(sortedSyncStates);
    });
  });
};

/**
 * /challenges/{challengeId}/questions/{questionId}/languages/{language}/event
 */
export const fetchQuestionLanguageEvent = (firebaseRef: firebase.database.Reference): Promise<PlaybackEvent.LanguageEvent[]> => {
  return new Promise(resolve => {
    firebaseRef.child("event").once("value", (snapshot: firebase.database.DataSnapshot) => {
      const states = snapshot.val();
      const result = PlaybackEvent.LanguageEventObject.safeParse(states);
      if (!result.success) {
        resolve([]);
        return;
      }
      const statesKeys = Object.keys(result.data).sort();
      const sortedSyncStates = statesKeys.map((key): PlaybackEvent.LanguageEvent => {
        return { ...result.data[key], k: key };
      });
      resolve(sortedSyncStates);
    });
  });
};

/**
 * @deprecated Please use fetchQuestionEvent
 */
export const fetchAccessEventsRecord = (firebaseRef: firebase.database.Reference, callback: (x: Record<string, AccessEvent>) => void) => {
  firebaseRef.child("event").once("value", (snapshot: firebase.database.DataSnapshot) => {
    const accessEvents = snapshot.val();
    if (!accessEvents) {
      callback({});
      return;
    }

    const result = FirebaseFieldSchema.AccessEvents.safeParse(accessEvents);
    if (result.success) {
      let i = 0;
      const accessEvents: Record<string, AccessEvent> = {};
      for (const key in result.data) {
        if (result.data[key].s === ACCESS_EVENT_TYPE) {
          accessEvents[revisionToId(i)] = result.data[key];
          i++;
        }
      }
      callback(accessEvents);
    } else {
      Sentry.captureException(result.error);
      callback({});
      return;
    }
  });
};

/**
 * @deprecated Please use fetchStatesV2
 */
export const fetchStates = (firebaseRef: firebase.database.Reference, callback: (x: SyncState[]) => void) => {
  firebaseRef.child("state").once("value", (snapshot: firebase.database.DataSnapshot) => {
    const states = snapshot.val();
    if (!states) {
      callback([]);
      return;
    }

    const sortedStates = sortObject(states);

    callback(sortedStates);
  });
};

/**
 * /challenges/{challengeId}/questions/{questionId}/state
 */
export const fetchQuestionState = (firebaseRef: firebase.database.Reference): Promise<PlaybackEvent.QuestionState[]> => {
  return new Promise(resolve => {
    firebaseRef.child("state").once("value", (snapshot: firebase.database.DataSnapshot) => {
      const states = snapshot.val();
      const result = PlaybackEvent.QuestionStateObject.safeParse(states);
      if (!result.success) {
        resolve([]);
        return;
      }
      const statesKeys = Object.keys(result.data).sort();
      const sortedSyncStates = statesKeys.map((key): PlaybackEvent.QuestionState => {
        return { ...result.data[key], k: key };
      });
      resolve(sortedSyncStates);
    });
  });
};

/**
 * /challenges/{challengeId}/questions/{questionId}/event
 */
export const fetchQuestionEvent = (firebaseRef: firebase.database.Reference): Promise<PlaybackEvent.QuestionEvent[]> => {
  return new Promise(resolve => {
    firebaseRef.child("event").once("value", (snapshot: firebase.database.DataSnapshot) => {
      const events = snapshot.val();
      const result = PlaybackEvent.QuestionEventObject.safeParse(events);
      if (!result.success) {
        resolve([]);
        return;
      }
      const statesKeys = Object.keys(result.data).sort();
      const sortedEvents = statesKeys.map((key): PlaybackEvent.QuestionEvent => {
        return { ...result.data[key] };
      });
      resolve(sortedEvents);
    });
  });
};

/**
 * /projects/{projectId}/questions/{questionId}/histories/files
 */
export const fetchQuestionHistoriesFiles = (firebaseRef: firebase.database.Reference): Promise<PlaybackEvent.RevisionHistoriesRecord> => {
  return new Promise(resolve => {
    firebaseRef.child("files").once("value", (snapshot: firebase.database.DataSnapshot) => {
      const states = snapshot.val();
      const result = PlaybackEvent.RevisionHistoriesRecord.safeParse(states);
      if (!result.success) {
        resolve({});
        return;
      }
      resolve(result.data);
    });
  });
};

/**
 * /projects/{projectId}/questions/{questionId}/histories/files/{encodedPath}/history
 */
export const fetchProjectFileHistoryRevisions = (firebaseRef: firebase.database.Reference): Promise<PlaybackEvent.Revision[]> => {
  return new Promise(resolve => {
    firebaseRef.child("history").once("value", (snapshot: firebase.database.DataSnapshot) => {
      const states = snapshot.val();
      const result = PlaybackEvent.RevisionObject.safeParse(states);
      if (!result.success) {
        resolve([]);
        return;
      }
      const revisionKeys = Object.keys(result.data).sort();
      const sortedRevisions = revisionKeys.map((key): PlaybackEvent.Revision => {
        return { ...result.data[key], k: key };
      });
      resolve(sortedRevisions);
    });
  });
};

/**
 * /projects/{projectId}/questions/{questionId}/histories/paths
 */
export const fetchProjectHistoriesPaths = (firebaseRef: firebase.database.Reference): Promise<Validator.PathHistories> => {
  return new Promise(resolve => {
    firebaseRef.child("paths").once("value", (snapshot: firebase.database.DataSnapshot) => {
      const states = snapshot.val();
      const result = Validator.PathHistories.safeParse(states);
      if (!result.success) {
        resolve({});
        return;
      }
      resolve(result.data);
    });
  });
};

/**
 * @deprecated
 * TODO @himenon Delete as it is not used in v2 of Container
 */
export const getNearestIndex = (action: SyncState, revisions: (SyncOperation | FlowAction)[]): number => {
  return revisions.filter(revision => action.t >= revision.t).length - 1;
};

// TODO Type safe
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const textFromFirebase = (at: number, revisions: any[]) => {
  let document: IPlainTextOperation = new PlainTextOperation();

  let i = 0;
  do {
    // TODO: come up with better algorithm here
    try {
      const op = PlainTextOperation.fromJSON(revisions[i].o);
      document = document.compose(op);
    } catch (e) {
      // do nothing
    }
    i += 1;
  } while (i <= at);

  return !document.isNoop() ? document.toJSON().slice(-1).pop() : "";
};

/**
 * @deprecated Please use composeTextOperationV2
 */
export const composeTextOperation = (at: number, revisions: SyncOperation[]): string => {
  let text = "";

  for (let i = 0; i < at + 1; i++) {
    let index = 0;

    revisions[i]?.o?.forEach(textOp => {
      if (typeof textOp === "string") {
        // addition
        text = text.slice(0, index) + textOp + text.slice(index);
        index += textOp.length;
      } else {
        if (textOp >= 0) {
          // retain
          index += textOp;
        } else {
          // deletion
          text = text.slice(0, index) + text.slice(index - textOp);
        }
      }
    });
  }

  return text;
};

export const composeTextOperationV2 = (at: number, revisions: (CodeEditorInputEvent | undefined)[]): string => {
  let text = "";

  for (let i = 0; i < at + 1; i++) {
    let index = 0;
    const revision = revisions.at(i);
    revision?.textOperations.forEach(textOp => {
      if (typeof textOp === "string") {
        // addition
        text = text.slice(0, index) + textOp + text.slice(index);
        index += textOp.length;
      } else {
        if (textOp >= 0) {
          // retain
          index += textOp;
        } else {
          // deletion
          text = text.slice(0, index) + text.slice(index - textOp);
        }
      }
    });
  }

  return text;
};

export const composeTextOperationForQuiz = (at: number, revisions: MergedRevision[]): string => {
  let text = "";

  for (let i = 0; i < at + 1; i++) {
    let index = 0;
    const revision = revisions[i];

    if (isFreeTextAction(revision)) {
      revision.o.forEach(textOp => {
        if (typeof textOp === "string") {
          // addition
          text = text.slice(0, index) + textOp + text.slice(index);
          index += textOp.length;
        } else {
          if (textOp >= 0) {
            // retain
            index += textOp;
          } else {
            // deletion
            text = text.slice(0, index) + text.slice(index - textOp);
          }
        }
      });
    }
  }

  return text;
};

// invertTextOperation converts an operation to inverse
// Note that the second argument content is the text after the inverse operation is applied, not before
// If it is not exist, when inverting delete operation, cannot know what text to restore
export const invertTextOperation = (ops: TPlainTextOperation, content: string): TPlainTextOperation => {
  return PlainTextOperation.fromJSON(ops).invert(content).toJSON();
};
