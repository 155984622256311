import { useTranslation } from "@hireroo/i18n";
import CloseIcon from "@mui/icons-material/Close";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import Button, { type ButtonProps } from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

type Controller = {
  setLoading: (loading: boolean) => void;
};

const StyledButton = styled(Button)(() => ({
  height: 42,
  width: "50%",
}));

export type TestStartDialogProps = {
  open: boolean;
  onClose?: () => void;
  /**
   * If created with the Link component, the `href` would be available, so the DEMO page could be opened from the context menu.
   * To prevent this, allow only Button onClick.
   */
  startDemoButton: Pick<ButtonProps, "onClick">;
  onStart: (controller: Controller) => void;
};

const TestStartDialog: React.FC<TestStartDialogProps> = props => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const controller: Controller = {
    setLoading: newLoading => {
      setLoading(newLoading);
    },
  };
  const startDemoButton: ButtonProps = {
    disabled: loading,
    children: t("デモを確認"),
    color: "secondary",
    variant: "outlined",
    endIcon: <LaunchOutlinedIcon />,
    onClick: event => {
      props.startDemoButton.onClick?.(event);
    },
  };
  const startTestButton: ButtonProps = {
    disabled: loading,
    children: t("本番を開始"),
    variant: "contained",
    color: "primary",
    onClick: () => {
      props.onStart(controller);
    },
  };
  return (
    <Dialog open={props.open} PaperProps={{ sx: { width: 450 } }}>
      <DialogTitle role="title" sx={{ px: 2, py: 1 }}>
        <Typography variant="subtitle2" component="span" sx={{ fontWeight: 500 }}>
          {t("テスト開始")}
        </Typography>
        <IconButton
          onClick={event => {
            event.stopPropagation();
            props.onClose?.();
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent role="content" sx={{ px: 3, pt: 2, pb: 0, mb: 3 }}>
        <Typography variant="body1" mb={1}>
          {t("本番のテストを開始する前に、解答画面の操作性を確認することができます。")}
          {t("操作性を確認した後、テストを開始することをおすすめします。")}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {t("※デモの解答結果は評価の対象に含まれません。")}
        </Typography>
      </DialogContent>
      <DialogActions disableSpacing sx={{ px: 3, pb: 2 }}>
        <Stack spacing={2} alignItems={"center"} justifyContent={"center"} direction="row" sx={{ width: "100%" }}>
          <StyledButton {...startDemoButton} />
          <StyledButton {...startTestButton} />
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

TestStartDialog.displayName = "TestStartDialog";

export default TestStartDialog;
