import * as Graphql from "@hireroo/graphql/client/urql";
import * as React from "react";
import { useSnapshot } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = (): boolean => {
  const snapshot = useSnapshotState();
  return !!snapshot.plansResponse && !!snapshot.customer;
};

export const usePlans = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.plansResponse) {
    throw new Error("Please Initialize Plan Update Form");
  }
  return React.useMemo(() => {
    const sortedList: Graphql.PlanType[] = [
      Graphql.PlanType.Basic,
      Graphql.PlanType.Standard,
      Graphql.PlanType.Professional,
      Graphql.PlanType.Enterprise,
    ];
    return sortedList.reduce<Types.Plan[]>((sortedPlans, planType) => {
      const target = snapshot.plansResponse?.plans.find(plan => plan.planType === planType);
      if (target) {
        return sortedPlans.concat(target);
      }
      return sortedPlans;
    }, []);
  }, [snapshot.plansResponse]);
};

export const useCustomer = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.customer) {
    throw new Error("Please Initialize Plan Update Form");
  }
  return snapshot.customer;
};

export const useSubmitValue = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.submitValue) {
    throw new Error("Please Set submit value");
  }
  return snapshot.submitValue;
};

export const useOptionalSubmitValue = () => {
  const snapshot = useSnapshotState();
  return snapshot.submitValue;
};

export const useTemporarySubmitValue = () => {
  const snapshot = useSnapshotState();
  return snapshot.temporarySubmitValue;
};

export const useShowingTarget = () => {
  const snapshot = useSnapshotState();
  return snapshot.showingTarget;
};

export const usePreviousMostExpensivePurchase = () => {
  const snapshot = useSnapshotState();
  return snapshot.mostExpensivePaymentPurchase;
};
