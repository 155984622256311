import { subscribeKey } from "valtio/utils";

import { state } from "./State";
import * as Types from "./types";

export type SubscribeListParamsCallback = (listParams: Types.ListParams) => void;

export const subscribeListParams = (callback: SubscribeListParamsCallback) => {
  return subscribeKey(state, "listParams", callback);
};

export type SubscribeDownloadId = (downloadId: string, exportFileType: Types.ExportFileType) => void;

export const subscribeDownloadId = (callback: SubscribeDownloadId) => {
  return subscribeKey(state.download, "downloadId", downloadId => {
    if (downloadId === null) {
      return;
    }
    callback(downloadId, state.download.exportFileType);
  });
};
