import ButtonBase from "@mui/material/ButtonBase";
import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

import Link, { LinkProps } from "../../../../../../primitive/Link/Link";
import Assign, { AssignProps } from "./parts/Assign/Assign";
import Evaluation, { EvaluationProps } from "./parts/Evaluation/Evaluation";
import StartInterviewDialog, { StartInterviewDialogProps } from "./parts/StartInterviewDialog/StartInterviewDialog";
import Status, { StatusProps } from "./parts/Status/Status";

const SELECTED_CLASS_NAME = "__selected__";
const EllipsisText = styled(Typography)(() => ({
  textOverflow: "ellipsis",
  fontSize: "14px",
  maxWidth: "150px",
  display: "inline-block",
  overflow: "hidden",
  whiteSpace: "nowrap",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderTop: "1px solid",
  borderBottom: "1px solid",
  borderColor: theme.palette.Other.Divider,
  display: "table-row",
  [`&.${SELECTED_CLASS_NAME}`]: {
    backgroundColor: theme.palette["Secondary/Shades"].p8,
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  border: "none",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  display: "table-cell",
}));

const CustomTableRow: React.FC<LinkProps> = props => {
  // FIXME I would like to ask the mui team how to define this type definition accurately.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <ButtonBase as={Link} {...props} disableRipple />;
};

export type RemoteInterviewListTableRowProps = {
  id: string;
  disabled?: boolean;
  href: string;
  onClick: () => void;
  title: string;
  candidateName: string;
  status: StatusProps;
  tags: string[];
  assign: AssignProps;
  startButton?: StartInterviewDialogProps;
  evaluation: EvaluationProps;
};

const RemoteInterviewListTableRow: React.FC<RemoteInterviewListTableRowProps> = props => {
  return (
    <StyledTableRow
      as={!props.disabled ? CustomTableRow : undefined}
      // FIXME I would like to ask the mui team how to define this type definition accurately.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      component={"a"}
      href={props.href}
      onClick={event => {
        event.stopPropagation();
        event.preventDefault();
        props.onClick?.();
      }}
    >
      <StyledTableCell component="div" align="center" width="70px" sx={{ pl: 2, pr: 0 }}>
        {props.startButton && <StartInterviewDialog {...props.startButton} />}
      </StyledTableCell>
      <StyledTableCell component="div">
        <Tooltip title={props.title.length > 15 ? props.title : ""} placement="bottom-start">
          <Typography maxWidth="350px" sx={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
            {props.title}
          </Typography>
        </Tooltip>
      </StyledTableCell>
      <StyledTableCell component="div">
        <Typography fontSize={12} color="textSecondary">
          {props.candidateName}
        </Typography>
      </StyledTableCell>
      <StyledTableCell component="div">
        <Tooltip title={props.tags.join(", ")}>
          <EllipsisText color="textSecondary">{props.tags.join(" ")}</EllipsisText>
        </Tooltip>
      </StyledTableCell>
      <StyledTableCell component="div">
        <Status {...props.status} />
      </StyledTableCell>
      <StyledTableCell component="div" align="center">
        <Assign {...props.assign} />
      </StyledTableCell>
      <StyledTableCell component="div" align="center">
        <Evaluation {...props.evaluation} />
      </StyledTableCell>
    </StyledTableRow>
  );
};

RemoteInterviewListTableRow.displayName = "ScreeningTestListTableRow";

export default RemoteInterviewListTableRow;
