import { useTranslation } from "@hireroo/i18n";
import { SignInForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import Stack from "@mui/material/Stack";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { MailAddressFieldProps } from "../../modules/MailAddressField/MailAddressField";
import FieldLabel from "../../ms-components/Auth/FieldLabel/FieldLabel";
import FormSubmitButton, { FormSubmitButtonProps } from "../../primitive/Button/FormSubmitButton/FormSubmitButton";
import InputControlTextField from "../../primitive/InputControl/InputControlTextField/InputControlTextField";

export type SingleSignOnProps = {
  mailAddressField: MailAddressFieldProps;
  signInButton: FormSubmitButtonProps;
  onSubmit: SubmitHandler<SignInForm.SignInFormSchema>;
};

const SingleSignOn: React.FC<SingleSignOnProps> = props => {
  const { t } = useTranslation();
  const validateSchema = SignInForm.useSingleSignOnSchema();
  const method = useForm<SignInForm.SignInFormSchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: { email: "" },
  });
  const disabled = !!method.formState.errors.email;
  const mailAddressFieldProps: MailAddressFieldProps = {
    fullWidth: true,
    color: "primary",
    placeholder: "contact@hireroo.io",
  };

  return (
    <FormProvider {...method}>
      <Stack spacing={2}>
        <FieldLabel label={t("メールアドレス")} width="100%">
          <InputControlTextField {...mailAddressFieldProps} name="email" />
        </FieldLabel>
        <FormSubmitButton {...props.signInButton} size="medium" disabled={disabled} onClick={method.handleSubmit(props.onSubmit)} />
      </Stack>
    </FormProvider>
  );
};

SingleSignOn.displayName = "SingleSignOn";

export default SingleSignOn;
