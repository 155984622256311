import { PaymentForm } from "@hireroo/validator";
import Box, { BoxProps } from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import RadioGroup, { RadioGroupProps } from "@mui/material/RadioGroup";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import FeatureTableHeadRow, { FeatureTableHeadRowProps } from "./parts/FeatureTableHeadRow/FeatureTableHeadRow";
import FeatureTableRow, { FeatureTableRowProps } from "./parts/FeatureTableRow/FeatureTableRow";

const BORDER_RADIUS = 20;

/**
 * NOTE: Trial Label extends beyond table due to position absolute, so overflow hidden cannot be used.
 */
const StyledTableContainer = styled(TableContainer)(() => ({
  overflow: "visible",
  borderRadius: BORDER_RADIUS,
  "& thead tr:firsto-of-type": {
    "& th:firsto-of-type, td:firsto-of-type": {
      borderTopLeftRadius: BORDER_RADIUS,
    },
    "& th:last-child, td:last-child": {
      borderTopRightRadius: BORDER_RADIUS,
    },
  },
  "& tbody tr:last-child": {
    "& th:firsto-of-type, & td:firsto-of-type": {
      borderBottomLeftRadius: BORDER_RADIUS,
    },
    "& td:last-child": {
      borderBottomRightRadius: BORDER_RADIUS,
    },
  },
}));

const StyledPaper = styled(Paper)(() => ({
  borderRadius: BORDER_RADIUS,
}));

export type PlanSelectionFieldProps = {
  name: string;
  head: Omit<FeatureTableHeadRowProps, "name">;
  rows: FeatureTableRowProps[];
} & Pick<BoxProps, "mb">;

const PlanSelectionField: React.FC<PlanSelectionFieldProps> = props => {
  const methods = useFormContext<Record<string, PaymentForm.PlanType>>();
  const handleChange: Exclude<RadioGroupProps["onChange"], undefined> = (_, value) => {
    methods.setValue(props.name, value as PaymentForm.PlanType);
  };

  const featureTableHeadRowProps: FeatureTableHeadRowProps = {
    ...props.head,
    name: props.name,
  };

  return (
    <Box mb={props.mb}>
      <StyledPaper>
        <RadioGroup name={props.name} onChange={handleChange}>
          <StyledTableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <FeatureTableHeadRow {...featureTableHeadRowProps} />
              </TableHead>
              <TableBody>
                {props.rows.map((row, index) => {
                  return <FeatureTableRow key={`feature-${index}`} {...row} />;
                })}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </RadioGroup>
      </StyledPaper>
    </Box>
  );
};

PlanSelectionField.displayName = "PlanSelectionField";

export default PlanSelectionField;
