import * as React from "react";

import * as SwapTheme from "../../../helpers/swap-theme";
import ExtendedMonacoEditor, { ExtendedMonacoEditorProps } from "../ExtendedMonacoEditor/ExtendedMonacoEditor";

export type CodeEditorProps = {
  path: string;
  value: string;
  style?: ExtendedMonacoEditorProps["style"];
  readOnly?: boolean;
  minimap?: boolean;
  lineNumbers?: "on" | "off";
  onChange?: (value: string) => void;
};

/**
 * The component to which the v1 code editor is being migrated.
 * Considering compatibility with the theme color, migrate here as much as possible.
 *
 * v1のcode-editorのmigration先のコンポーネント
 * theme colorの互換性を考慮し、可能な限りこちらに移行していく
 */
const CodeEditor: React.FC<CodeEditorProps> = props => {
  // Since it has been confirmed to work, keep it as it is without using useEffect.
  SwapTheme.showVscodeTheme();
  const codeEditorProps: ExtendedMonacoEditorProps = {
    code: {
      uri: props.path,
      value: props.value,
    },
    style: props.style,
    editorOptions: {
      readOnly: props.readOnly,
      minimap: {
        enabled: props.minimap,
      },
      lineNumbers: props.lineNumbers,
    },
    onLoad: wrapper => {
      const editor = wrapper.getEditor();
      editor?.onDidChangeModelContent(() => {
        props.onChange?.(editor.getValue() ?? "");
      });
    },
  };
  return <ExtendedMonacoEditor {...codeEditorProps} />;
};

CodeEditor.displayName = "CodeEditor";

export default CodeEditor;
