import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as ProjectHelpers from "@hireroo/project/helpers/fileTree";
import { FileNode, findNode, findParent } from "@hireroo/project/helpers/fileTree";
import { HistoriesPathsModel, IndexModel } from "@hireroo/project-shared-utils";
import * as Sentry from "@sentry/browser";
import { EventEmitter } from "events";

import { BASE_PATH, IMAGE_EXTENSION_PATTERN, UNWATCHABLE_EXTENSION_PATTERN } from "./definition";
import type * as Types from "./types";

export type QuestionInfo = {
  questionId: number;
  questionVersion: string;
};

export class FirebaseFileTreeController implements Types.FileTreeController {
  #status: Types.Status;
  #fileTree: Types.Node;
  #selectedFile: string | null = null;
  #openedFiles: string[] = [];
  /**
   * 👷‍♀ monaco-editorのuriを作成するのに利用される。
   */
  #cwd: string = BASE_PATH;
  #initialized = false;
  #client = getGraphqlClient();
  #emitter = new EventEmitter<Types.EventMap>();

  constructor(
    private initialFileIndexes: string[],
    private readOnlyFiles: string[],
    private questionInfo: QuestionInfo,
    private readonly historiesPathsModel: HistoriesPathsModel,
    private readonly indexModel: IndexModel,
  ) {
    this.#status = "disconnected";
    this.#fileTree = ProjectHelpers.createNodeByFileIndexes(BASE_PATH, initialFileIndexes, readOnlyFiles);
  }

  private syncFilePaths = async () => {
    const indexes = await this.indexModel.getIndexes();
    /**
     * 変更履歴がない状態のときは、initial file indexesでfirebaseを初期化する
     */
    if (indexes === null || indexes.length === 0) {
      await this.historiesPathsModel.initialize(
        this.initialFileIndexes.filter(filepath => {
          const result1 = !filepath.match(IMAGE_EXTENSION_PATTERN);
          if (!result1) {
            return false;
          }
          const unwatchable = UNWATCHABLE_EXTENSION_PATTERN.test(filepath);
          return !unwatchable;
        }),
      );
      return;
    }
    //
    // indexes = ["src/", "a.md"] のうち、`src/`のように末尾が`/`で終わっているものがディレクトリとして扱われる
    //
    this.#fileTree = ProjectHelpers.createNodeByFileIndexes(BASE_PATH, indexes, this.readOnlyFiles);
    const list = ProjectHelpers.listAllFilePaths(this.#fileTree).filter((filepath: string) => {
      if (!filepath) {
        return false;
      }
      if (filepath === ".") {
        return false;
      }
      const unwatchable = UNWATCHABLE_EXTENSION_PATTERN.test(filepath);
      if (unwatchable) {
        return false;
      }
      return !filepath.match(IMAGE_EXTENSION_PATTERN);
    });
    await this.historiesPathsModel.initialize(list);
  };

  public createDirectory = (path: string) => {
    ProjectHelpers.createNode(this.#fileTree, path, true, false);
    this.#emitter.emit("changedFileTree");
  };

  public reset = async () => {
    await this.indexModel.clear();
    this.#fileTree = ProjectHelpers.createNodeByFileIndexes(BASE_PATH, this.initialFileIndexes, this.readOnlyFiles);
    const list = ProjectHelpers.listAllFilePaths(this.#fileTree).filter((filepath: string) => !!filepath && filepath !== ".");
    await this.historiesPathsModel.reinitialize(list);

    this.#openedFiles = [];
    this.#selectedFile = null;

    this.#emitter.emit("openedFiles", this.#openedFiles);
    this.#emitter.emit("selectedFile", this.#selectedFile);
    this.#emitter.emit("changedFileTree");
  };

  public recreateFileTree = (fileTree: Types.Node) => {
    this.#fileTree = fileTree;
    this.#emitter.emit("changedFileTree");
  };

  public start = (): void => {
    this.indexModel.on("changed", () => {
      this.syncFilePaths().then(() => {
        this.#emitter.emit("changedFileTree");
      });
    });

    this.syncFilePaths().then(() => {
      this.#emitter.emit("connected");
    });

    this.indexModel.start();
  };

  public get status(): Types.Status {
    return this.#status;
  }

  public get selectedFile(): string | null {
    return this.#selectedFile;
  }

  public get cwd(): string {
    return this.#cwd;
  }

  public get fileTree(): FileNode {
    return this.#fileTree;
  }

  public get initialized(): boolean {
    return this.#initialized;
  }

  public get filesOpened(): string[] {
    return this.#openedFiles;
  }

  public addFile = (at: string, name: string): void => {
    const newFilepath = this.generateFilepath(at, name);
    if (newFilepath) {
      this.historiesPathsModel.update(newFilepath, {
        op: "CREATED",
      });
    }
  };

  public addDir = (_at: string, _name: string): void => {
    //
    // firebase上に仮想ディレクトリを作る場合にここを実装する
    //
  };

  public remove = (id: string): void => {
    this.historiesPathsModel.update(id, {
      op: "DELETED",
    });
    this.closeFile(id);
  };

  public update = (_id: string, _value: string): void => {
    console.info("[update] No implemented");
  };

  public selectFile = (newSelectedFile: string): void => {
    const node = findNode(this.#fileTree, newSelectedFile);
    if (node && !node.isDir) {
      this.#client
        .GetProjectQuestionInitialCode({
          questionId: this.questionInfo.questionId,
          questionVersion: this.questionInfo.questionVersion,
          filePath: newSelectedFile,
        })
        .then(res => {
          /**
           * 参照をちゃんと渡して更新する
           */
          this.#fileTree = ProjectHelpers.updateNodeValue(this.#fileTree, newSelectedFile, res.projectQuestionInitialFileBody.fileBody);
          this.#emitter.emit("changedFileTree");
          if (!this.#openedFiles.includes(node.id)) {
            this.#openedFiles.push(node.id);
            this.#emitter.emit("openedFiles", this.#openedFiles);
          }
          this.#selectedFile = node.id;
          this.#emitter.emit("selectedFile", this.#selectedFile);
        })
        .catch(error => {
          // コードを引きついだときに、初期個度のないファイルにアクセスした場合の処理を実装する
          // ファイルの変更状態はfirebaseに保存されているため、ここで実体を取得しなくて良い
          // 実体はfirepad経由で取得するため、ここでは参照先だけ変更する
          node.value = node.value ?? "";
          this.#emitter.emit("changedFileTree");
          if (!this.#openedFiles.includes(node.id)) {
            this.#openedFiles.push(node.id);
            this.#emitter.emit("openedFiles", this.#openedFiles);
          }
          this.#selectedFile = node.id;
          this.#emitter.emit("selectedFile", this.#selectedFile);

          // エラー扱いであるものの、リスタート後はエラー扱いとして見なくて良い
          Sentry.captureException(error);
        });
    }
  };

  public closeFile = (closedFile: string): void => {
    if (!this.#openedFiles.includes(closedFile)) {
      return;
    }
    const node = findNode(this.#fileTree, closedFile);
    if (node && !node.isDir) {
      this.#openedFiles = this.#openedFiles.filter(file => file !== node.id);
      this.#selectedFile = node.id;
      if (this.#selectedFile === node.id) {
        if (this.#openedFiles.length > 0) {
          const index = this.#openedFiles.indexOf(node.id);
          this.#selectedFile = this.#openedFiles[Math.max(0, index - 1)];
        } else {
          this.#selectedFile = null;
        }
      }

      this.#emitter.emit("openedFiles", this.#openedFiles);
      this.#emitter.emit("selectedFile", this.#selectedFile);
      this.#emitter.emit("changedFileTree");
    }
  };

  public reconnect = (): void => {
    console.info("[reconnect] No implemented");
  };

  public generateFilepath = (at: string, name: string): string | undefined => {
    let node = findNode(this.#fileTree, at);
    // If the node is not found, we cannot continue the following process
    if (!node) return;

    // If the node is a file, new file can't be created, hence get the parent dir
    if (!node.isDir) {
      node = findParent(this.#fileTree, node.id);
    }
    // If the parent node is not found, we cannot continue the following process
    if (!node) return;

    return node.id === "." ? name : `${node.id}/${name}`;
  };

  public on: Types.Emitter["on"] = (eventName, callback) => {
    return this.#emitter.on(eventName, callback);
  };

  public off: Types.Emitter["off"] = (eventName, callback) => {
    return this.#emitter.off(eventName, callback);
  };

  public dispose = () => {
    this.#emitter.removeAllListeners();
    this.historiesPathsModel.dispose();
    this.indexModel.dispose();
  };
}
