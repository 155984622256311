import { useTranslation } from "@hireroo/i18n";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";

import TablePagination, { TablePaginationProps } from "../../../../modules/TablePagination/TablePagination";
import MetricsGroupsTableRow, { MetricsGroupsTableRowProps } from "../MetricsGroupsTableRow/MetricsGroupsTableRow";

const ColumnTitle = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  color: theme.palette.text.secondary,
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  "& .MuiTableCell-root": {
    borderColor: theme.palette["Secondary/Shades"].p12,
    padding: theme.spacing(1.5),
  },
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  borderTop: "1px solid",
  borderColor: theme.palette["Secondary/Shades"].p12,
}));

export type MetricsGroupsTableProps = {
  items: MetricsGroupsTableRowProps[];
  pagination: TablePaginationProps;
};

const MetricsGroupsTable: React.FC<MetricsGroupsTableProps> = props => {
  const { t } = useTranslation();
  return (
    <StyledTableContainer>
      <Table>
        <StyledTableHead>
          <TableRow key="company-members-header-row">
            <TableCell padding="checkbox">{/* spacer */}</TableCell>
            <TableCell width="20%">
              <ColumnTitle>{t("評価指標名")}</ColumnTitle>
            </TableCell>
            <TableCell sx={{ minWidth: "200px", maxWidth: "500px" }}>
              <ColumnTitle>{t("説明")}</ColumnTitle>
            </TableCell>
            <TableCell width="20%" sx={{ minWidth: "70px" }}>
              <ColumnTitle>{t("評価項目")}</ColumnTitle>
            </TableCell>
            <TableCell width="140px">
              <ColumnTitle>{t("更新日時")}</ColumnTitle>
            </TableCell>
            <TableCell align="right" width="5%">
              {/* spacer */}
            </TableCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {props.items.map(item => (
            <MetricsGroupsTableRow key={item.id} {...item} />
          ))}
          {props.items.length === 0 && (
            <TableCell colSpan={5}>
              <Typography fontSize="12px" color="textSecondary" textAlign="center">
                {t("評価指標がありません。")}
              </Typography>
            </TableCell>
          )}
        </TableBody>
      </Table>
      <TablePagination {...props.pagination} />
    </StyledTableContainer>
  );
};

MetricsGroupsTable.displayName = "MetricsGroupsTable";

export default MetricsGroupsTable;
