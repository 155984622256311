import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";

import CSVRow, { CSVRowProps } from "./CSVRow";

export type Status = "SUCCESS" | "ERROR";

export type TestResultItemProps = {
  header: string[];
  csvRows: CSVRowProps[];
};

const TestResultItem: React.FC<TestResultItemProps> = props => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label={"collapsible-table"} size={"small"} width={"100%"}>
        <TableHead>
          <TableRow>
            <TableCell key={"icon-header"} width={"10%"} />
            {props.header.map(val => (
              <TableCell key={val} width={"30%"}>
                {val}
              </TableCell>
            ))}
            <TableCell key={"collapse-button-header"} width={"10%"} />
          </TableRow>
        </TableHead>
        {props.csvRows.length > 0 && (
          <TableBody>
            {props.csvRows.map((csvRow, index) => (
              <CSVRow {...csvRow} key={index} />
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

TestResultItem.displayName = "TestResultItem";

export default TestResultItem;
