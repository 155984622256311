import { RemotesId } from "@hireroo/app-store/page/e/remotes_id";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { TechnicalCommentForm } from "@hireroo/validator";
import * as React from "react";

export type GenerateTechnicalCommentEvaluationsFormPropsArgs = {
  uniqueKey: string;
  evaluations: TechnicalCommentForm.EvaluationSchema[];
  onChangeRating: (metricId: number, numStars: number) => void;
  onChangeComment: (metricId: number, comment: string) => void;
  onSelectMetrics: (metricIds: number[]) => void;
  onRemoveMetric: (metricId: number) => void;
};

type EvaluationItem = Widget.TechnicalCommentEvaluationsFormProps["evaluationsList"]["evaluationItems"][0];
type MetricItemMap = Widget.TechnicalCommentEvaluationsFormProps["metricSearchMenu"]["metricList"]["metricItems"];

export const useGenerateProps = (args: GenerateTechnicalCommentEvaluationsFormPropsArgs): Widget.TechnicalCommentEvaluationsFormProps => {
  const { onSelectMetrics, onRemoveMetric, onChangeRating, onChangeComment } = args;
  const { t } = useTranslation();
  const metricMap = RemotesId.useMetricMap();
  const metrics = RemotesId.useMetrics();
  const [, setSyncEvaluationItem] = React.useState<number>();

  const selectableMetrics = RemotesId.useSelectableMetrics();
  const searchText = RemotesId.useSearchText();

  const handleRemoveEvaluation = React.useCallback(
    (metricId: number) => {
      onRemoveMetric(metricId);
    },
    [onRemoveMetric],
  );

  const notifyStoreTemporaryEvaluation = React.useCallback(
    (metricId: number, comment: string) => {
      setSyncEvaluationItem(previousSyncEvaluationItem => {
        if (typeof previousSyncEvaluationItem === "number") {
          window.clearTimeout(previousSyncEvaluationItem);
        }

        onChangeComment(metricId, comment);
        return window.setTimeout(() => {
          Snackbar.notify({
            severity: "success",
            message: t("評価を一時保存しました"),
          });
        }, 3000);
      });
    },
    [onChangeComment, t],
  );

  const handleChangeRatingField = React.useCallback(
    (metricId: number, numStars: number) => {
      onChangeRating(metricId, numStars);
    },
    [onChangeRating],
  );

  const evaluationItemProps: EvaluationItem[] = args.evaluations
    .map((evaluation, index): EvaluationItem | undefined => {
      const metric = metricMap.get(evaluation.metricId);
      if (metric) {
        return {
          id: metric.metricId,
          index: index,
          title: metric.title,
          description: metric.description,
          ratingField: {
            value: evaluation.numStars,
            onChange: (_event: React.SyntheticEvent<Element, Event>, value: number | null) =>
              handleChangeRatingField(metric.metricId, value || 0),
          },
          commentField: {
            defaultValue: evaluation.comment,
            onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
              notifyStoreTemporaryEvaluation(metric.metricId, event.currentTarget.value),
          },
          onRemoveEvaluation: () => handleRemoveEvaluation(metric.metricId),
        };
      }
    })
    .filter(value => value !== undefined);

  return {
    metricSearchMenu: {
      defaultValues: {
        items: selectableMetrics.map(
          (selectableMetric): Widget.TechnicalCommentEvaluationsFormProps["metricSearchMenu"]["defaultValues"]["items"][0] => {
            return {
              metricId: selectableMetric.metricId,
              selected: false,
            };
          },
        ),
      },
      onSubmit: fields => {
        const selectedMetricIds = fields.items.filter(item => item.selected).map(item => item.metricId);
        onSelectMetrics(selectedMetricIds);
        if (selectedMetricIds.length > 0) {
          Snackbar.notify({
            severity: "success",
            message: t("評価項目を追加しました。"),
          });
        }
      },
      metricList: {
        metricItems: metrics.reduce<MetricItemMap>((metricItemMap, metric) => {
          metricItemMap[metric.metricId] = {
            title: metric.title,
            description: metric.description,
          };
          return metricItemMap;
        }, {}),
      },
      textField: {
        text: searchText,
        onChange: text => RemotesId.setSearchText(text),
      },
    },
    evaluationsList: {
      evaluationItems: evaluationItemProps,
    },
    kind: "DURING_INTERVIEW",
  };
};
