import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { type GenerateQuestionSearchForResourceEditorPropsArgs, useGenerateProps } from "./useGenerateProps";

export type QuestionSearchForResourceEditorContainerProps = GenerateQuestionSearchForResourceEditorPropsArgs;

const QuestionSearchForResourceEditorContainer: React.FC<QuestionSearchForResourceEditorContainerProps> = props => {
  const questionSearchForResourceEditorProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.QuestionSearchForResourceEditor {...questionSearchForResourceEditorProps} />
    </ErrorBoundary>
  );
};

QuestionSearchForResourceEditorContainer.displayName = "QuestionSearchForResourceEditorContainer";

export default withErrorBoundary(QuestionSearchForResourceEditorContainer, {});
