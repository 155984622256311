/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable @typescript-eslint/no-explicit-any */
//
// Generated by @himenon/openapi-typescript-code-generator v1.0.9
//
// OpenApi : 3.1.0
//
// License  : UNLICENCED
//

export namespace Schemas {
  export type RequestFormData = FormData;
}
export interface RequestBody$uploadProfile {
  "multipart/form-data": Schemas.RequestFormData;
}
export interface Response$uploadProfile$Status$200 {
  "application/json": {
    status: "UPLOADED" | "SKIPPED";
  };
}
export interface RequestBody$uploadProfiles {
  "multipart/form-data": Schemas.RequestFormData;
}
export interface Response$uploadProfiles$Status$200 {
  "application/json": {
    status: "UPLOADED" | "SKIPPED";
  };
}
export type RequestContentType$uploadProfile = keyof RequestBody$uploadProfile;
export type ResponseContentType$uploadProfile = keyof Response$uploadProfile$Status$200;
export interface Params$uploadProfile {
  requestBody: RequestBody$uploadProfile["multipart/form-data"];
}
export type RequestContentType$uploadProfiles = keyof RequestBody$uploadProfiles;
export type ResponseContentType$uploadProfiles = keyof Response$uploadProfiles$Status$200;
export interface Params$uploadProfiles {
  requestBody: RequestBody$uploadProfiles["multipart/form-data"];
}
export type HttpMethod = "GET" | "PUT" | "POST" | "DELETE" | "OPTIONS" | "HEAD" | "PATCH" | "TRACE";
export interface ObjectLike {
  [key: string]: any;
}
export interface QueryParameter {
  value: any;
  style?: "form" | "spaceDelimited" | "pipeDelimited" | "deepObject";
  explode: boolean;
}
export interface QueryParameters {
  [key: string]: QueryParameter;
}
export type SuccessResponses = Response$uploadProfile$Status$200 | Response$uploadProfiles$Status$200;
export namespace ErrorResponse {
  export type uploadProfile = void;
  export type uploadProfiles = void;
}
export interface Encoding {
  readonly contentType?: string;
  headers?: Record<string, any>;
  readonly style?: "form" | "spaceDelimited" | "pipeDelimited" | "deepObject";
  readonly explode?: boolean;
  readonly allowReserved?: boolean;
}
export interface RequestArgs {
  readonly httpMethod: HttpMethod;
  readonly url: string;
  headers: ObjectLike | any;
  requestBody?: ObjectLike | any;
  requestBodyEncoding?: Record<string, Encoding>;
  queryParameters?: QueryParameters | undefined;
}
export interface ApiClient<RequestOption> {
  request: <T = SuccessResponses>(requestArgs: RequestArgs, options?: RequestOption) => Promise<T>;
}
export const createClient = <RequestOption>(apiClient: ApiClient<RequestOption>, baseUrl: string) => {
  const _baseUrl = baseUrl.replace(/\/$/, "");
  return {
    /** PDFファイルを1つだけアップロードする */
    uploadProfile: (params: Params$uploadProfile, option?: RequestOption): Promise<Response$uploadProfile$Status$200["application/json"]> => {
      const url = _baseUrl + `/upload/profile`;
      const headers = {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      };
      return apiClient.request(
        {
          httpMethod: "POST",
          url,
          headers,
          requestBody: params.requestBody,
        },
        option,
      );
    },
    /** PDFファイルを複数アップロードする */
    uploadProfiles: (
      params: Params$uploadProfiles,
      option?: RequestOption,
    ): Promise<Response$uploadProfiles$Status$200["application/json"]> => {
      const url = _baseUrl + `/upload/profiles`;
      const headers = {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      };
      return apiClient.request(
        {
          httpMethod: "POST",
          url,
          headers,
          requestBody: params.requestBody,
        },
        option,
      );
    },
  };
};
type ClientFunction<RequestOption> = typeof createClient<RequestOption>;
export type Client<RequestOption> = ReturnType<ClientFunction<RequestOption>>;
