import { DndContext } from "@dnd-kit/core";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import UploadContent, { UploadContentProps } from "./parts/UploadContent/UploadContent";

export type SampleUploadContentProps = {
  onSelectedFiles: (files: globalThis.File[]) => void;
};

/**
 * TODO TODO このコンポーネントはファイルアップロードの参考実装用のコンポーネントのため、一つでもファイルアップロードの実装ができたら削除する
 */
const SampleUploadContent: React.FC<SampleUploadContentProps> = props => {
  const [selectedFiles, setSelectedFiles] = React.useState<File[]>([]);
  const uploadFileInput: JSX.IntrinsicElements["input"] = {
    type: "file",
    multiple: true,
    onChange: event => {
      if (event.target.files) {
        const files = Array.from(event.target.files);
        setSelectedFiles(files);
      }
    },
  };

  const uploadContentProps: UploadContentProps = {
    id: "droppable-area",
    onSelectedFiles: selectedFiles => {
      setSelectedFiles(selectedFiles);
    },
  };
  const buttonProps: ButtonProps = {
    variant: "contained",
    color: "secondary",
    sx: {
      px: 1,
      py: 0.5,
    },
    children: "アップロード",
    onClick: () => {
      props.onSelectedFiles(selectedFiles);
    },
  };

  return (
    <DndContext>
      <Stack direction="column" spacing={1.5} p={1}>
        <input {...uploadFileInput} />
        <UploadContent {...uploadContentProps} />
      </Stack>
      {selectedFiles.length > 0 && (
        <Stack direction="column" spacing={0.5}>
          {selectedFiles.map((selectedFile, index) => {
            return (
              <Typography variant="body2" key={`index-${index}`}>
                {index + 1}. {selectedFile.name}
              </Typography>
            );
          })}
          <Button {...buttonProps} />
        </Stack>
      )}
    </DndContext>
  );
};

SampleUploadContent.displayName = "SampleUploadContent";

export default SampleUploadContent;
