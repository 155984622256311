import { createNode, FileNode, findNode, findParent, generateHashString, listChildPaths, removeNode } from "@hireroo/project/helpers/fileTree";
import * as ProjectHelpers from "@hireroo/project/helpers/fileTree";
import { ProjectFileTreeV3 } from "@hireroo/validator";
import * as Sentry from "@sentry/react";
import { EventEmitter } from "events";
import ReconnectingWebSocket from "reconnecting-websocket";

import { BASE_PATH } from "./definition";
import type * as Types from "./types";

export class AgentServerFileTreeController implements Types.FileTreeController {
  #status: Types.Status;
  #fileTree: Types.Node;
  #selectedFile: string | null = null;
  #openedFiles: string[] = [];
  /**
   * 👷‍♀ monaco-editorのuriを作成するのに利用される。
   */
  #cwd: string = BASE_PATH;
  #awaitingBuffer: Record<string, ProjectFileTreeV3.FsMessage> = {};
  #socket: ReconnectingWebSocket;
  /**
   * WebSocket経由でのFileTreeSyncが開始されたかどうか
   */
  #initialized = false;
  /**
   * syncMap will make sure consecutive update doesn't result flashy file update
   * For example, when you update file a.txt 10 times within 1 sec, it only updates once, not 10 times
   */
  #syncMap: Record<string, NodeJS.Timeout> = {};
  #emitter = new EventEmitter<Types.EventMap>();

  constructor(
    private endpoint: string,
    private initialFileIndexes: string[],
    private readOnlyFiles: string[],
  ) {
    this.#status = "disconnected";
    this.#fileTree = ProjectHelpers.createNodeByFileIndexes(BASE_PATH, initialFileIndexes, readOnlyFiles);
    this.#socket = new ReconnectingWebSocket(endpoint, [], {
      minReconnectionDelay: 1000,
      maxReconnectionDelay: 1000,
      reconnectionDelayGrowFactor: 1.0,
      startClosed: true,
    });
  }

  public recreateFileTree = (fileTree: Types.Node) => {
    this.#fileTree = fileTree;
  };

  public start = () => {
    this.#socket.reconnect();
    this.startListen();
  };

  public stop = () => {
    this.dispose();
  };

  private startListen = () => {
    this.#socket.onmessage = event => {
      this.handleFileSyncMessage(event);
    };

    this.#socket.onopen = () => {
      this.#status = "connected";
      this.#emitter.emit("connected");
      this.sendInit();
    };

    this.#socket.onclose = () => {
      this.#status = "disconnected";
      this.#emitter.emit("disconnected");
    };

    this.#socket.onerror = event => {
      /**
       * If the communication is disconnected before the websocket connection is established, the following error occurs
       * """
       * WebSocket connection to '<URL>' failed: WebSocket is closed before the connection is established
       * """
       * This can occur due to the client's network environment and cannot be completely prevented. Therefore, give the client a chance to retry.
       * According to the internal implementation of reconnect-websocket, reconnection is performed after observing onerror.
       */
      if (event.error) {
        Sentry.captureException(event.error);
        Sentry.captureMessage(`Retry Url: ${this.#socket.url}, Retry Count: ${this.#socket.retryCount}`);
      }
    };
  };

  private handleFileSyncMessage = (messageEvent: MessageEvent) => {
    const result = ProjectFileTreeV3.FsMessageSchema.safeParse(JSON.parse(messageEvent.data));
    if (result.success) {
      // const msg = JSON.parse(e.data) as ProjectFileTreeV3.FsMessage;
      if (this.#awaitingBuffer[result.data.id]) {
        // If a response comes back from server,
        // it means that the change sent by client has accepted by server,
        // then apply the change to the editor according to its message type
        this.responseHandler(result.data);
      } else {
        // If a request comes in from server,
        // it means that some file change happened on the server,
        // then apply the change to the editor according to its message type
        this.requestHandler(result.data);
      }
    } else {
      throw new Error(`could not safely parse response: ${JSON.stringify(messageEvent.data)}, zod error: ${JSON.stringify(result.error)}\n`);
    }
  };

  private requestHandler = (msg: ProjectFileTreeV3.FsMessage) => {
    switch (msg.type) {
      case "file/didCreate":
        this.fileDidCreateHandler(msg, false);
        break;
      case "file/didDelete":
        this.fileDidDeleteHandler(msg);
        break;
      case "file/didChange":
        this.fileDidChangeHandler(msg, true);
        break;
      case "dir/didCreate":
        this.dirDidCreateHandler(msg);
        break;
      case "dir/didDelete":
        this.dirDidDeleteHandler(msg);
        break;
    }

    const ackMsg: ProjectFileTreeV3.Ack = {
      id: msg.id,
      type: "ack",
      params: {},
    };

    this.#socket.send(JSON.stringify(ackMsg));
  };

  private responseHandler = (message: ProjectFileTreeV3.FsMessage) => {
    const awaitingMessage = this.#awaitingBuffer[message.id];
    if (!awaitingMessage) return;

    switch (message.type) {
      case "initialize":
        this.initializeFileIndexesAndCurrentWorkDirectory(message);
        break;
      case "file/didOpen":
        this.fileDidOpenHandler(message);
        break;
      case "file/didClose":
        this.fileDidCloseHandler(message);
        break;
      case "file/didCreate":
        this.fileDidCreateHandler(message, true);
        break;
      case "file/didDelete":
        this.fileDidDeleteHandler(message);
        break;
      case "file/didChange":
        this.fileDidChangeHandler(message, false);
        break;
      case "dir/didCreate":
        this.dirDidCreateHandler(message);
        break;
      case "dir/didDelete":
        this.dirDidDeleteHandler(message);
        break;
    }

    delete this.#awaitingBuffer[message.id];
  };

  private initializeFileIndexesAndCurrentWorkDirectory = (
    message: ProjectFileTreeV3.InitializeRequest | ProjectFileTreeV3.InitializeResponse,
  ) => {
    // InitializeRequest doesn't come from the server, hence we just handle InitializeResponse
    const result = ProjectFileTreeV3.InitializeResponseSchema.safeParse(message);
    if (result.success) {
      this.#cwd = result.data.params.cwd;
      this.#fileTree.name = result.data.params.cwd;
      result.data.params.index.forEach(fileStat => {
        // Root directory can be ignored safely
        if (fileStat.path === ".") {
          return;
        }
        createNode(this.#fileTree, fileStat.path, fileStat.is_dir, fileStat.is_read_only);
      });

      this.#initialized = true;
      this.#emitter.emit("initialized");
    } else {
      throw new Error(`could not safely parse response: ${JSON.stringify(message)}, zod error: ${JSON.stringify(result.error)}\n`);
    }
  };

  private fileDidOpenHandler = (msg: ProjectFileTreeV3.FileDidOpenRequest | ProjectFileTreeV3.FileDidOpenResponse) => {
    // FileDidOpenRequest doesn't come from the server, hence we just handle FileDidOpenResponse
    const result = ProjectFileTreeV3.FileDidOpenResponseSchema.safeParse(msg);
    if (result.success) {
      const node = findNode(this.#fileTree, result.data.params.path);
      // If the node is not found, we cannot continue the following process
      if (!node) return;

      const searchedNode = findNode(this.#fileTree, result.data.params.path);
      if (searchedNode) {
        node.value = result.data.params.body;
      }
      // If the file is closed, then open it, otherwise do nothing
      if (!this.#openedFiles.includes(node.id)) {
        this.#openedFiles.push(node.id);
      }
      this.#selectedFile = node.id;
      this.#emitter.emit("changedFileTree");
      this.#emitter.emit("openedFiles", this.#openedFiles);
      this.#emitter.emit("selectedFile", this.#selectedFile);
    } else {
      throw new Error(`could not safely parse response: ${JSON.stringify(msg)}, zod error: ${JSON.stringify(result.error)}\n`);
    }
  };

  private fileDidCloseHandler = (msg: ProjectFileTreeV3.FileDidCloseRequest | ProjectFileTreeV3.FileDidCloseResponse) => {
    // FileDidCloseRequest doesn't come from the server, hence we just handle FileDidCloseResponse
    const result = ProjectFileTreeV3.FileDidCloseResponseSchema.safeParse(msg);
    if (result.success) {
      const node = findNode(this.#fileTree, result.data.params.path);
      // If the node is not found, we cannot continue the following process
      if (!node) return;

      const newFilesOpened = this.#openedFiles.filter(file => file !== node.id);
      // Open the file asynchronously to avoid mui Tab error
      setTimeout(() => {
        this.#openedFiles = newFilesOpened;
        this.#emitter.emit("openedFiles", this.#openedFiles);
      });

      // If currently selected file is closed, then update it with the closest one
      if (this.#selectedFile === node.id) {
        if (newFilesOpened.length > 0) {
          const index = this.#openedFiles.indexOf(node.id);
          this.#selectedFile = newFilesOpened[Math.max(0, index - 1)];
        } else {
          this.#selectedFile = null;
        }
        this.#emitter.emit("selectedFile", this.#selectedFile);
      }
    } else {
      throw new Error(`could not safely parse response: ${JSON.stringify(msg)}, zod error: ${JSON.stringify(result.error)}\n`);
    }
  };

  private fileDidCreateHandler = (msg: ProjectFileTreeV3.FileDidCreateRequest | ProjectFileTreeV3.FileDidCreateResponse, open?: boolean) => {
    // TODO: Separate this handler for request and response
    // FileDidCreateRequest and FileDidCreateResponse is the exact same type, hence we just handle one of them
    const result = ProjectFileTreeV3.FileDidCreateRequestSchema.safeParse(msg);
    if (result.success) {
      createNode(this.#fileTree, result.data.params.path, false, false);

      this.#emitter.emit("changedFileTree");

      // Send a request again to open the newly created file
      if (open) {
        const msgId = generateHashString(16);
        const msg: ProjectFileTreeV3.FsMessage = {
          id: msgId,
          type: "file/didOpen",
          params: {
            path: result.data.params.path,
          },
        };

        this.#awaitingBuffer[msgId] = msg;
        setTimeout(() => {
          this.#socket.send(JSON.stringify(msg));
        }, 10);
      }
    } else {
      throw new Error(`could not safely parse response: ${JSON.stringify(msg)}, zod error: ${JSON.stringify(result.error)}\n`);
    }
  };

  private fileDidDeleteHandler = (msg: ProjectFileTreeV3.FileDidDeleteRequest | ProjectFileTreeV3.FileDidDeleteResponse) => {
    // TODO: Separate this handler for request and response
    // FileDidDeleteRequest and FileDidDeleteResponse is the exact same type, hence we just handle one of them
    const params = msg.params;
    const result = ProjectFileTreeV3.FileDidDeleteResponseSchema.safeParse(msg);
    if (result.success) {
      const node = findNode(this.#fileTree, result.data.params.path);
      // If the node is not found, we cannot continue the following process
      if (!node) return;

      const newFilesOpened = this.#openedFiles.filter(file => file !== node.id);
      // If length differs, it means some opened files are deleted
      if (newFilesOpened.length !== this.#openedFiles.length) {
        this.#openedFiles = newFilesOpened;
        this.#emitter.emit("openedFiles", this.#openedFiles);
      }

      // If currently selected file is deleted, then update it with the closest one
      if (this.#selectedFile === node.id) {
        if (newFilesOpened.length > 0) {
          const index = this.#openedFiles.indexOf(node.id);
          this.#selectedFile = newFilesOpened[Math.max(0, index - 1)] ?? null;
        } else {
          this.#selectedFile = null;
        }
        this.#emitter.emit("selectedFile", this.#selectedFile);
      }

      removeNode(this.#fileTree, node.id);
      this.#emitter.emit("deletedDirectory", node.id);
      this.#emitter.emit("changedFileTree");
    } else {
      throw new Error(`could not safely parse response: ${JSON.stringify(params)}, zod error: ${JSON.stringify(result.error)}\n`);
    }
  };

  private fileDidChangeHandler = (msg: ProjectFileTreeV3.FileDidChangeRequest | ProjectFileTreeV3.FileDidChangeResponse, apply: boolean) => {
    if (!apply) return;

    // If it's a request, already returned, hence we just handle FileDidChangeResponse
    const result = ProjectFileTreeV3.FileDidChangeRequestSchema.safeParse(msg);
    if (result.success) {
      const node = findNode(this.#fileTree, result.data.params.path);
      if (node) {
        node.value = result.data.params.body;
      }
    } else {
      throw new Error(`could not safely parse response: ${JSON.stringify(msg)}, zod error: ${JSON.stringify(result.error)}\n`);
    }
  };

  private dirDidCreateHandler = (msg: ProjectFileTreeV3.DirDidCreateRequest | ProjectFileTreeV3.DirDidCreateResponse) => {
    const result = ProjectFileTreeV3.DirDidCreateResponseSchema.safeParse(msg);
    if (result.success) {
      createNode(this.#fileTree, result.data.params.path, true, false);
      this.#emitter.emit("createdDirectory", result.data.params.path);
    } else {
      throw new Error(`could not safely parse response: ${JSON.stringify(msg)}, zod error: ${JSON.stringify(result.error)}\n`);
    }
  };

  private dirDidDeleteHandler = (msg: ProjectFileTreeV3.DirDidDeleteRequest | ProjectFileTreeV3.DirDidDeleteResponse) => {
    // TODO: Separate this handler for request and response
    // DirDidDeleteRequest and DirDidDeleteResponse is the exact same type, hence we just handle one of them
    const result = ProjectFileTreeV3.DirDidDeleteResponseSchema.safeParse(msg);
    if (result.success) {
      const node = findNode(this.#fileTree, result.data.params.path);
      // node not found. we can't continue following process
      if (!node) return;

      // List child files as they also need to be closed
      const children = listChildPaths(node);
      const newFilesOpened = this.#openedFiles.filter(file => !children.includes(file));

      // If length differs, it means some opened files are deleted
      if (newFilesOpened.length !== this.#openedFiles.length) {
        this.#openedFiles = newFilesOpened;
        this.#emitter.emit("openedFiles", this.#openedFiles);
      }

      // Deleted file was opened previously, hence needs to change the state
      if (this.#selectedFile && children.includes(this.#selectedFile)) {
        if (newFilesOpened.length > 0) {
          const index = this.#openedFiles.indexOf(this.#selectedFile);
          this.#selectedFile = newFilesOpened[Math.min(Math.max(0, index - 1), newFilesOpened.length - 1)] ?? null;
        } else {
          this.#selectedFile = null;
        }
        this.#emitter.emit("selectedFile", this.#selectedFile);
      }

      removeNode(this.#fileTree, node.id);
      this.#emitter.emit("changedFileTree");
    } else {
      throw new Error(`could not safely parse response: ${JSON.stringify(msg)}, zod error: ${JSON.stringify(result.error)}\n`);
    }
  };

  public get status(): Types.Status {
    return this.#status;
  }

  public get selectedFile(): string | null {
    return this.#selectedFile;
  }
  public get cwd(): string {
    return this.#cwd;
  }
  public get fileTree(): FileNode {
    return this.#fileTree;
  }
  public get initialized(): boolean {
    return this.#initialized;
  }
  public get filesOpened(): string[] {
    return Array.from(this.#openedFiles);
  }

  public addFile = (at: string, name: string): void => {
    const path = this.generateFilepath(at, name);
    if (!path) return;

    const msgId = generateHashString(16);
    const msg: ProjectFileTreeV3.FileDidCreateRequest = {
      id: msgId,
      type: "file/didCreate",
      params: {
        path,
      },
    };

    this.#awaitingBuffer[msgId] = msg;
    setTimeout(() => {
      this.#socket.send(JSON.stringify(msg));
    }, 10);
  };

  private sendInit = () => {
    const msgId = generateHashString(16);
    const msg: ProjectFileTreeV3.FsMessage = {
      id: msgId,
      type: "initialize",
      params: {},
    };

    this.#awaitingBuffer[msgId] = msg;

    setTimeout(() => {
      this.#socket?.send(JSON.stringify(msg));
    }, 10);
  };

  public addDir = (at: string, name: string): void => {
    let node = findNode(this.#fileTree, at);
    // If the node is not found, we cannot continue the following process
    if (!node) {
      console.warn(`FileTreeの${at}の位置が見つかりませんでした`);
      return;
    }

    // If the node is a file, new dir can't be created, hence get the parent dir
    if (!node.isDir) {
      node = findParent(this.#fileTree, node.id);
    }
    // If the parent node is not found, we cannot continue the following process
    if (!node) return;

    const path = node.id === "." ? name : `${node.id}/${name}`;
    const msgId = generateHashString(16);
    const msg: ProjectFileTreeV3.DirDidCreateRequest = {
      id: msgId,
      type: "dir/didCreate",
      params: {
        path,
      },
    };

    this.#awaitingBuffer[msgId] = msg;
    setTimeout(() => {
      this.#socket.send(JSON.stringify(msg));
    }, 10);
  };

  public remove = (id: string): void => {
    const node = findNode(this.#fileTree, id);
    // If the node is not found, we cannot continue the following process
    if (!node) return;

    const msgId = generateHashString(16);
    const msg: ProjectFileTreeV3.FileDidDeleteRequest | ProjectFileTreeV3.DirDidDeleteRequest = {
      id: msgId,
      type: node.isDir ? "dir/didDelete" : "file/didDelete",
      params: {
        path: node.id,
      },
    };

    this.#awaitingBuffer[msgId] = msg;
    setTimeout(() => {
      this.#socket.send(JSON.stringify(msg));
    }, 10);
  };

  public update = (id: string, value: string): void => {
    const node = findNode(this.#fileTree, id);
    if (node) {
      node.value = value;
    }

    // Call sync 3 seconds after idling
    if (id in this.#syncMap) {
      const timer = this.#syncMap[id];
      clearTimeout(timer);
    }

    this.#syncMap[id] = setTimeout(() => {
      this.sync(id);
    }, 3000);
  };

  public selectFile = (newSelectedFile: string): void => {
    const node = findNode(this.#fileTree, newSelectedFile);
    // If the node is not found or dir, we cannot continue the following process
    if (!node || node?.isDir) return;

    const msgId = generateHashString(16);
    const msg: ProjectFileTreeV3.FileDidOpenRequest = {
      id: msgId,
      type: "file/didOpen",
      params: {
        path: node.id,
      },
    };

    this.#awaitingBuffer[msgId] = msg;
    setTimeout(() => {
      this.#socket.send(JSON.stringify(msg));
    }, 10);
  };

  public closeFile = (closedFile: string): void => {
    // If closed file isn't included in the opened files, do nothing
    if (!this.#openedFiles.includes(closedFile)) return;

    const node = findNode(this.#fileTree, closedFile);
    // If the node is not found, we cannot continue the following process
    if (!node || node?.isDir) return;

    const msgId = generateHashString(16);
    const msg: ProjectFileTreeV3.FileDidCloseRequest = {
      id: msgId,
      type: "file/didClose",
      params: {
        path: node.id,
      },
    };

    this.#awaitingBuffer[msgId] = msg;
    setTimeout(() => {
      this.#socket.send(JSON.stringify(msg));
    }, 10);
  };

  public reconnect = (): void => {};

  public generateFilepath = (at: string, name: string): string | undefined => {
    let node = findNode(this.#fileTree, at);
    // If the node is not found, we cannot continue the following process
    if (!node) return;

    // If the node is a file, new file can't be created, hence get the parent dir
    if (!node.isDir) {
      node = findParent(this.#fileTree, node.id);
    }
    // If the parent node is not found, we cannot continue the following process
    if (!node) return;

    return node.id === "." ? name : `${node.id}/${name}`;
  };

  private sync = (id: string): void => {
    const node = findNode(this.#fileTree, id);
    // If the node is not found, we cannot continue the following process
    if (!node) return;

    const msgId = generateHashString(16);
    const msg: ProjectFileTreeV3.FileDidChangeRequest = {
      id: msgId,
      type: "file/didChange",
      params: {
        path: node.id,
        body: node.value,
      },
    };

    this.#awaitingBuffer[msgId] = msg;
    setTimeout(() => {
      this.#socket.send(JSON.stringify(msg));
    }, 10);
  };

  public on: Types.Emitter["on"] = (eventName, callback) => {
    return this.#emitter.on(eventName, callback);
  };

  public off: Types.Emitter["off"] = (eventName, callback) => {
    return this.#emitter.off(eventName, callback);
  };

  public reset = () => {
    this.#initialized = false;
    this.#syncMap = {};
    this.#awaitingBuffer = {};
    this.#openedFiles = [];
    this.#selectedFile = null;
    this.#status = "connecting";
    this.#fileTree = ProjectHelpers.createNodeByFileIndexes(BASE_PATH, this.initialFileIndexes, this.readOnlyFiles);
    this.#emitter.emit("openedFiles", this.#openedFiles);
    this.#emitter.emit("selectedFile", this.#selectedFile);
    this.#emitter.emit("changedFileTree");
  };

  public dispose = () => {
    this.#socket.close();
  };
}
