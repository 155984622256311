import { useTranslation } from "@hireroo/i18n";
import DownloadIcon from "@mui/icons-material/Download";
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import ApplicationConfirmationSection, {
  ApplicationConfirmationSectionProps,
} from "../../ms-components/Payment/ApplicationConfirmationSection/ApplicationConfirmationSection";
import Link from "../../primitive/Link/Link";

const TermOfUseWrapper = styled(Box)(({ theme }) => ({
  overflow: "hidden",
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  border: `1px solid ${theme.palette.Other.Divider}`,
  borderRadius: 8,
}));

const TermOfUseBodyWrapper = styled(Box)(() => ({
  overflowY: "scroll",
}));

const StyledIframe = styled("iframe")(() => ({
  width: "100%",
  minHeight: 520,
}));

const StyledButton = styled(LoadingButton)(() => ({
  textTransform: "none",
}));

export type PaymentConfirmationDetailsProps = {
  payNextMonthConfirmSection: ApplicationConfirmationSectionProps;
  payInTwoMonthConfirmSection?: ApplicationConfirmationSectionProps;
  tosIframeUrl?: string;
  downloadUrl?: string;
  submitButton: Pick<LoadingButtonProps, "onClick" | "loading">;
};

const PaymentConfirmationDetails: React.FC<PaymentConfirmationDetailsProps> = props => {
  const { t } = useTranslation();
  const [check, setCheck] = React.useState(false);
  const checkboxProps: CheckboxProps = {
    color: "secondary",
    checked: check,
    onChange: (_, checked) => {
      setCheck(checked);
    },
  };
  const downloadButton: ButtonProps = {
    startIcon: <DownloadIcon />,
    color: "secondary",
    variant: "text",
    children: t("PDFをダウンロード"),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    component: Link,
    href: props.downloadUrl,
    target: "_blank",
  };
  const submitButton: ButtonProps = {
    ...props.submitButton,
    variant: "contained",
    color: "primary",
    children: t("上記の内容で申し込みする"),
    disabled: !check,
  };
  /**
   * Scroll to the top to allow users to read the Terms of Use.
   */
  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <Box>
      <ApplicationConfirmationSection {...props.payNextMonthConfirmSection} mb={2} />
      {props.payInTwoMonthConfirmSection && <ApplicationConfirmationSection {...props.payInTwoMonthConfirmSection} />}
      <Box display="flex" alignItems="center" justifyContent="space-between" mt={3}>
        <Typography variant="body2" fontWeight="bold">
          {t("利用規約")}
        </Typography>
        <Button {...downloadButton} />
      </Box>
      <TermOfUseWrapper mb={3}>
        <TermOfUseBodyWrapper mb={1.5} px={2}>
          {props.tosIframeUrl ? <StyledIframe src={props.tosIframeUrl} /> : t("利用規約が読み込めませんでした。")}
        </TermOfUseBodyWrapper>
        <Box px={2}>
          <FormControlLabel label={t("利用規約に同意する")} control={<Checkbox {...checkboxProps} />} />
        </Box>
      </TermOfUseWrapper>
      <Box textAlign="center">
        <StyledButton {...submitButton} />
      </Box>
    </Box>
  );
};

PaymentConfirmationDetails.displayName = "PaymentConfirmationDetails";

export default PaymentConfirmationDetails;
