import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type ExamTabTablesForTalentContainerProps = {};

const ExamTabTablesForTalentContainer: React.FC<ExamTabTablesForTalentContainerProps> = () => {
  const examTabTablesForTalentProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.ExamTabTablesForTalent {...examTabTablesForTalentProps} />
    </ErrorBoundary>
  );
};

ExamTabTablesForTalentContainer.displayName = "ExamTabTablesForTalentContainer";

export default withErrorBoundary(ExamTabTablesForTalentContainer, {});
