import { Payment } from "@hireroo/app-store/essential/employee";
import { ScreeningsTestsIdDetailStore } from "@hireroo/app-store/page/e/screenings_tests_id_detail";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import InterviewDetailContainer from "./Container";
import * as Subscriber from "./Subscriber";

export type InterviewsDetailInitialContainerProps = {};

const InterviewsDetailInitialContainer: React.FC<InterviewsDetailInitialContainerProps> = () => {
  const [query] = useSearchParams();
  const [initialTab] = React.useState(query.get("tab"));
  const isAvailableFeature = Payment.useIsAvailableFeature();
  const currentTab = ScreeningsTestsIdDetailStore.useCurrentTab();
  React.useEffect(() => {
    if (currentTab) return;
    const canSelectReview = initialTab === "review" && isAvailableFeature("test.technical-comment.create");
    if (initialTab === "summary" || initialTab === "report" || canSelectReview) {
      ScreeningsTestsIdDetailStore.updateCurrentTab(initialTab);
    }
  }, [currentTab, initialTab, isAvailableFeature]);

  React.useEffect(() => {
    const stopSubscribePdfState = Subscriber.startSubscribePdfState();

    return () => {
      ScreeningsTestsIdDetailStore.clear();
      stopSubscribePdfState();
    };
  }, []);

  return (
    <ErrorBoundary>
      <InterviewDetailContainer />
    </ErrorBoundary>
  );
};
InterviewsDetailInitialContainer.displayName = "InterviewsDetailInitialContainer";

export default withErrorBoundary(InterviewsDetailInitialContainer, {});
