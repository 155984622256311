import { AssessmentsIdDetailStore } from "@hireroo/app-store/page/e/assessments_id_detail";
import * as Time from "@hireroo/formatter/time";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

type ExamSelector = Widget.AssessmentDetailForEmployeeProps["detailHeaderPage"]["examSelector"];
type Item = Exclude<ExamSelector, undefined>["items"][0];

export const useGenerateExamSelector = (): ExamSelector => {
  const currentOriginExamId = AssessmentsIdDetailStore.useCurrentOriginExamId();
  const exams = AssessmentsIdDetailStore.useBestExams();
  const [, setParams] = useSearchParams();

  const items = React.useMemo(() => {
    return exams.map((exam): Item => {
      return {
        id: exam.originId,
        startDate: exam.createdAtSeconds ? Time.unixToDatetimeFormat(exam.createdAtSeconds) : "",
        endDate: exam.willEndAtSeconds ? Time.unixToDatetimeFormat(exam.willEndAtSeconds) : "",
        examNumber: exam.numInterval,
        selected: currentOriginExamId === exam.originId,
      };
    });
  }, [exams, currentOriginExamId]);

  return {
    items: items,
    onChange: value => {
      AssessmentsIdDetailStore.updateSelectedExamId(value);
      setParams(prev => {
        return {
          ...Object.fromEntries(prev),
          examId: value,
        };
      });
    },
    value: currentOriginExamId || "",
    onEndReached: () => {
      AssessmentsIdDetailStore.updateOffsetToCurrentMax();
    },
  };
};
