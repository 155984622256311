import i18n from "i18next";

import { NAMESPACE } from "./constants";
import type commonEnWithVariables from "./en/CommonWithVariables.json";
import TranslationJSON from "./en/translation.json" assert { type: "json" };

export interface TranslationAction {
  t: (key: keyof typeof TranslationJSON) => string;
}

export const getTranslation = (): TranslationAction => {
  return {
    t: i18n.t,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TranslationWithVariable = (key: keyof typeof commonEnWithVariables, variableMap?: Record<string, any>) => string;

export interface TranslationWithVariableAction {
  t: TranslationWithVariable;
}

export const getTranslationWithVariable = (): TranslationWithVariableAction => {
  /**
   * @see https://www.i18next.com/overview/api#getfixedt
   */
  return { t: i18n.getFixedT(null, NAMESPACE.COMMON_WITH_VARIABLES) };
};

export type SupportLanguage = "en" | "ja";

export const getLanguage = () => {
  return i18n.language as SupportLanguage;
};

export const getUpperCaseLanguage = () => {
  return i18n.language.toUpperCase() as Uppercase<SupportLanguage>;
};
