import * as React from "react";

import ResultBlocks, { ResultBlocksProps } from "../ResultBlocks/ResultBlocks";
import { testResultData } from "./ResultBlocks.StoryExample";

export type ResultBlocksPlaygroundProps = {};

/**
 * Only for dev
 */
const ResultBlocksPlayground: React.FC<ResultBlocksPlaygroundProps> = _props => {
  const resultBlocks: ResultBlocksProps = testResultData;

  return <ResultBlocks {...resultBlocks} />;
};

ResultBlocksPlayground.displayName = "ResultBlocksPlayground";

export default ResultBlocksPlayground;
