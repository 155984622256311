import { languageMapForDisplay } from "@hireroo/i18n";
import { SupportLanguageValue, useTranslation } from "@hireroo/i18n";
import QuestionAnswer from "@mui/icons-material/QuestionAnswer";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, useFieldArray } from "react-hook-form";

import InputControlTextField, { InputControlTextFieldProps } from "../../primitive/InputControl/InputControlTextField";
import SelectControl, { SelectControlProps } from "../../primitive/InputControl/SelectControl";
import EditLanguageTab, { EditLanguageTabProps } from "../../usecase/EditLanguageTab/EditLanguageTab";
import { useQuizQuestionEditorContext } from "./Context";
import PublicSettingField, { PublicSettingFieldProps } from "./parts/PublicSettingField";
import QuizContent, { QuizContentProps } from "./parts/QuizContent";

export { QuizQuestionEditorProvider, type QuizQuestionEditorProviderProps, useQuizQuestionEditorContext } from "./Context";

export type QuizQuestionEditorProps = {
  versioningField?: InputControlTextFieldProps;
  publicSettingField?: PublicSettingFieldProps;
  difficultyField: SelectControlProps;
  metricTypeField: SelectControlProps;
  variantField: SelectControlProps;
  packagesField: Pick<EditLanguageTabProps, "menu">;
  editLanguageTab: {
    onRemoveLanguageTab?: (language: SupportLanguageValue) => void;
  };
};

const QuizQuestionEditor: React.FC<QuizQuestionEditorProps> = props => {
  const { t } = useTranslation();
  const { method } = useQuizQuestionEditorContext();
  const selectedLanguageArrayField = useFieldArray({
    control: method.control,
    name: "selectedLanguages",
  });
  const versioningField: InputControlTextFieldProps = {
    ...props.versioningField,
    label: t("バージョン"),
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
    required: true,
    helperText: t("編集後の設問のバージョンを設定してください。"),
  };
  const difficultyField: SelectControlProps = {
    ...props.difficultyField,
    label: t("難易度") + "* ",
  };
  const metricTypeField: SelectControlProps = {
    ...props.metricTypeField,
    label: t("設問種別") + "* ",
  };
  const variantField: SelectControlProps = {
    ...props.variantField,
    label: t("設問形式") + "* ",
  };
  const selectedLanguages = method.getValues("selectedLanguages");
  const editLanguageTabProps: EditLanguageTabProps = {
    menu: props.packagesField.menu,
    items: selectedLanguages.map((language, idx, array) => {
      const quizContentProps: QuizContentProps = {
        language: language.value,
      };
      return {
        id: language.value,
        name: languageMapForDisplay[language.value],
        tab: {
          closeButton: (array.length > 1 || undefined) && {
            onClick: () => {
              props.editLanguageTab.onRemoveLanguageTab?.(language.value);
              selectedLanguageArrayField.remove(idx);
            },
          },
        },
        Content: <QuizContent {...quizContentProps} />,
      };
    }),
  };
  const theme = useTheme();
  return (
    <FormProvider {...method}>
      <Box>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2} alignItems="center">
            <QuestionAnswer fontSize="small" sx={{ color: theme.palette.common.white }} />
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              {t("設問の本文を追加する")}
            </Typography>
          </Stack>
          <Box display="flex" flexDirection="column" gap={4}>
            {props.publicSettingField && <PublicSettingField {...props.publicSettingField} name="isPublic" />}
            {props.versioningField && <InputControlTextField {...versioningField} name="newVersion" />}
            <Box display="flex" gap={2}>
              <SelectControl {...difficultyField} name="difficulty" />
              <SelectControl {...metricTypeField} name="metricType" />
              <SelectControl {...variantField} name="variant" />
            </Box>
          </Box>
          <EditLanguageTab {...editLanguageTabProps} />
        </Stack>
      </Box>
    </FormProvider>
  );
};

QuizQuestionEditor.displayName = "QuizQuestionEditor";

export default QuizQuestionEditor;
