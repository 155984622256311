import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type ScreeningTestTutorialContainerProps = {};

const ScreeningTestTutorialContainer: React.FC<ScreeningTestTutorialContainerProps> = () => {
  const screeningTestTutorialProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.ScreeningTestTutorial {...screeningTestTutorialProps} />
    </ErrorBoundary>
  );
};

ScreeningTestTutorialContainer.displayName = "ScreeningTestTutorialContainer";

export default withErrorBoundary(ScreeningTestTutorialContainer, {});
