import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import * as React from "react";

import TestReportEvaluationItem, { TestReportEvaluationItemProps } from "../../../modules/TestReportEvaluationItem/TestReportEvaluationItem";

type Item = Pick<TestReportEvaluationItemProps, "value" | "icon" | "canShowTooltip">;

export type ReportQuizAnswerDetailSectionProps = {
  totalElapsedTime: Item;
};

const ReportQuizAnswerDetailSection: React.FC<ReportQuizAnswerDetailSectionProps> = props => {
  const { t } = useTranslation();
  const leftSections: TestReportEvaluationItemProps[] = [
    {
      ...props.totalElapsedTime,
      title: t("経過時間"),
    },
  ];

  return (
    <Box>
      <Typography variant="subtitle1" sx={theme => ({ fontWeight: "bold", color: theme.palette.text.secondary })} mb={3}>
        {t("解答詳細")}
      </Typography>
      <Grid container columnSpacing={1}>
        <Grid item xs={6}>
          <List component="nav" aria-label="answer-detail-1">
            {leftSections.map(section => (
              <TestReportEvaluationItem key={section.title} {...section} />
            ))}
          </List>
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </Box>
  );
};

ReportQuizAnswerDetailSection.displayName = "ReportQuizAnswerDetailSection";

export default ReportQuizAnswerDetailSection;
