import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineOutlined from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PlayCircleFilledOutlinedIcon from "@mui/icons-material/PlayCircleFilledOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import * as React from "react";

import BaseDialog, { BaseDialogProps } from "../../../../../primitive/BaseDialog/BaseDialog";
import PopupMenu, { PopupMenuProps } from "../../../../../primitive/PopupMenu/PopupMenu";
import AssessmentShareSettingDialog, {
  AssessmentShareSettingDialogProps,
} from "../../../AssessmentShareSettingDialog/AssessmentShareSettingDialog";

type MenuItemProps = PopupMenuProps["items"][0];

export type ActionMenuProps = {
  openShareSettingDialogButton: { disabled: boolean; disabledText: string };
  editButton: Pick<MenuItemProps, "onClick"> & { disabled: boolean; disabledText: string };
  changeActiveStatusButton: { disabled: boolean; disabledText: string };
  deleteButton: { disabled: boolean; disabledText: string };
  activeStatus: "ACTIVE" | "NOT_ACTIVE";
  reportShareSettings: Pick<AssessmentShareSettingDialogProps, "defaultValues" | "onSubmit" | "disabled" | "title">;
  title: string;
  onDelete: () => void;
  onChangeActiveStatus: () => void;
  /**
   * Only for developer
   * TODO Consider whether to remove at the time of release
   */
  onCreateImmediately?: () => void;
};

const ActionMenu: React.FC<ActionMenuProps> = props => {
  const { onCreateImmediately } = props;
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openShareSettingsDialog, setOpenShareSettingsDialog] = React.useState(false);
  const [openActivateDialog, setOpenActivateDialog] = React.useState(false);

  const iconButtonProps: IconButtonProps = {
    onClick: event => {
      event.stopPropagation();
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    },
  };
  const items: MenuItemProps[] = [
    {
      ...props.openShareSettingDialogButton,
      Icon: <ShareOutlinedIcon fontSize="small" />,
      onClick: event => {
        event.stopPropagation();
        setAnchorEl(null);
        setOpenShareSettingsDialog(true);
      },
      displayText: t("共有項目設定"),
    },
    {
      ...props.editButton,
      Icon: <EditOutlinedIcon fontSize="small" />,
      onClick: event => {
        event.stopPropagation();
        setAnchorEl(null);
        props.editButton?.onClick?.(event);
      },
      displayText: t("編集"),
    },
    {
      ...props.changeActiveStatusButton,
      color: props.activeStatus === "ACTIVE" ? "error" : undefined,
      Icon:
        props.activeStatus === "ACTIVE" ? (
          <StopCircleOutlinedIcon fontSize="small" color="error" />
        ) : (
          <PlayCircleFilledOutlinedIcon fontSize="small" />
        ),
      onClick: event => {
        event.stopPropagation();
        setAnchorEl(null);
        setOpenActivateDialog(true);
      },
      displayText: props.activeStatus === "ACTIVE" ? t2("talentScoreSuspend") : t("再開"),
    },
    {
      ...props.deleteButton,
      color: "error",
      Icon: <DeleteOutlineOutlined color="error" fontSize="small" />,
      onClick: event => {
        event.stopPropagation();
        setAnchorEl(null);
        setOpenDeleteDialog(true);
      },
      displayText: t("削除"),
    },
  ];
  if (onCreateImmediately) {
    items.push({
      color: "info",
      Icon: <AddCircleOutlineIcon color="info" fontSize="small" />,
      onClick: event => {
        event.stopPropagation();
        setAnchorEl(null);
        onCreateImmediately();
      },
      displayText: "即時発行",
    });
  }

  const popupMenuProps: PopupMenuProps = {
    open: !!anchorEl,
    anchorEl,
    items: items.flatMap(v => (v ? [v] : [])),
    onClose: event => {
      // It is true that stopPropagation exists, but the Material UI type definition is wrong.
      (event as { stopPropagation?: () => void }).stopPropagation?.();
      setAnchorEl(null);
    },
  };

  const deleteDialogProps: BaseDialogProps = {
    open: openDeleteDialog,
    title: t2("deleteTarget", {
      title: props.title,
    }),
    disableEnter: true,
    yesButton: {
      onClick: () => {
        setOpenDeleteDialog(false);
        props.onDelete();
      },
      color: "error",
      children: t("タレントスコアを削除する"),
    },
    noButton: {
      onClick: () => {
        setOpenDeleteDialog(false);
      },
      children: t("閉じる"),
    },
  };

  const activateDialogProps: BaseDialogProps = {
    open: openActivateDialog,
    title: props.title,
    disableEnter: true,
    yesButton: {
      onClick: () => {
        props.onChangeActiveStatus();
        setOpenActivateDialog(false);
      },
      color: props.activeStatus === "ACTIVE" ? "error" : "secondary",
      children: props.activeStatus === "ACTIVE" ? t("タレントスコアを停止する") : t("タレントスコアを再開する"),
    },
    noButton: {
      onClick: () => {
        setOpenActivateDialog(false);
      },
      children: t("閉じる"),
    },
  };

  const assessmentShareSettingDialogProps: AssessmentShareSettingDialogProps = {
    ...props.reportShareSettings,
    open: openShareSettingsDialog,
    onClose: () => {
      setOpenShareSettingsDialog(false);
    },
  };

  return (
    <>
      <IconButton {...iconButtonProps} component="span">
        <MoreVertIcon />
      </IconButton>
      <PopupMenu {...popupMenuProps} />
      <BaseDialog {...deleteDialogProps} />
      <AssessmentShareSettingDialog {...assessmentShareSettingDialogProps} />
      <BaseDialog {...activateDialogProps} />
    </>
  );
};

ActionMenu.displayName = "ActionMenu";

export default ActionMenu;
