import { App, Company } from "@hireroo/app-store/essential/employee";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import PlanListContainer, { PlanListContainerProps } from "./Container";

export type PlanListFetchContainerProps = {};

const PlanListFetchContainer: React.FC<PlanListFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const companyId = Company.useStrictActiveCompanyId();

  const [result, refresh] = Graphql.useGetPlansForPaymentPlanListQuery({
    variables: {
      customerId: companyId,
    },
    requestPolicy: "network-only",
    pause: appStatus !== "INITIALIZED",
  });

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }

  if (result.fetching || appStatus !== "INITIALIZED" || !result.data) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: PlanListContainerProps = {
    plans: result.data.plans,
    customer: result.data.customer,
  };

  return <PlanListContainer {...containerProps} />;
};

PlanListFetchContainer.displayName = "PlanListFetchContainer";

export default withErrorBoundary(PlanListFetchContainer, {});
