import { useSnapshot } from "valtio";

import { state } from "./State";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

const useSpot = () => {
  const snapshot = useSnapshotState();
  return snapshot.spot;
};

export const useCandidateName = () => {
  const spot = useSpot();
  return spot?.candidateName ?? "";
};

export const useSpotMessageForCandidate = (): string | undefined => {
  const spot = useSpot();
  return spot?.messageForCandidate || undefined;
};

export const useSpotStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.spot?.status;
};

export const useFirstEntity = () => {
  const spot = useSpot();
  return spot?.entities[0];
};

export const useInitialized = () => {
  const spot = useSpot();
  return !!spot;
};

export const useEntities = () => {
  const spot = useSpot();
  return spot?.entities || [];
};

export const useEndInterviewStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.endInterviewStatus;
};

export const useEndInterviewLoadingStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.endInterviewLoadingStatus;
};

export const useEnabledWebSearch = () => {
  const spot = useSpot();
  return spot?.allowWebSearch ?? false;
};

export const useEnabledChatGPT = () => {
  const spot = useSpot();
  return spot?.allowChatGPT ?? false;
};
