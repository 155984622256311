import { INITIAL_VERSION } from "@hireroo/app-definition/question";
import { QuizPackageDetail } from "@hireroo/app-store/widget/e/QuizPackageDetail";
import { SupportLanguage, useLanguageCode } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation/legacy";
import * as React from "react";

export type GenerateQuizPackageDetailPropsArgs = {
  type: "CREATE" | "UPDATE";
};

export const useGenerateProviderProps = (args: GenerateQuizPackageDetailPropsArgs): Widget.QuizPackageDetailProviderProps => {
  const current = QuizPackageDetail.useCurrentMultiChoicePackage(args.type);
  const lang = useLanguageCode();
  const hasJapanese = !!current?.titleJa && !!current?.descriptionJa;
  const hasEnglish = !!current?.titleEn && !!current?.descriptionEn;
  const oneOfHas = hasJapanese || hasEnglish;
  /**
   * This state is only used for initialization and does not need to be updated.
   */
  const [canShowAddJapaneseMenuItem] = React.useState(oneOfHas ? !hasJapanese : lang !== "ja");
  const [canShowAddEnglishMenuItem] = React.useState(oneOfHas ? !hasEnglish : lang !== "en");
  return {
    fields: {
      isPublic: typeof current?.isPrivate === "boolean" ? !current.isPrivate : false,
      timeLimit: (current?.timeLimitSeconds ?? 1800) / 60,
      difficulty: current?.difficulty ?? "MEDIUM",
      packageDetails: {
        ja: {
          title: current?.titleJa ?? "",
          description: current?.descriptionJa ?? "",
        },
        en: {
          title: current?.titleEn ?? "",
          description: current?.descriptionEn ?? "",
        },
      },
      selectedLanguages: [
        !canShowAddJapaneseMenuItem && {
          value: SupportLanguage.JA,
        },
        !canShowAddEnglishMenuItem && {
          value: SupportLanguage.EN,
        },
      ].flatMap(v => (v ? [v] : [])),
      needUpdateVersion: current ? current.version !== INITIAL_VERSION : false,
      oldVersion: current?.version || INITIAL_VERSION,
      newVersion: current?.version.split("-")[0] || INITIAL_VERSION,
    },
  };
};
