import { Payment } from "@hireroo/app-store/essential/talent";
import { AssessmentsIdDetailStore } from "@hireroo/app-store/page/t/assessments_id_detail";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";
import { useParams, useSearchParams } from "react-router-dom";

import AssessmentDetailContainer, { AssessmentDetailContainerProps } from "./Container";

export type AssessmentsDetailInitialContainerProps = {};

const AssessmentsDetailInitialContainer: React.FC<AssessmentsDetailInitialContainerProps> = () => {
  const { id } = useParams<{ id: string }>();
  const assessmentId = `${id}`;
  const [query] = useSearchParams();
  const [initialTab] = React.useState(query.get("tab"));
  const [examId] = React.useState(query.get("examId"));
  const selectedExamId = AssessmentsIdDetailStore.useSelectedExamId();
  const isAvailableFeature = Payment.useIsAvailableFeature();
  const currentTab = AssessmentsIdDetailStore.useCurrentTab();
  React.useEffect(() => {
    if (currentTab) return;
    const canSelectReview = initialTab === "review" && isAvailableFeature("test.technical-comment.create");
    if (initialTab === "summary" || initialTab === "report" || canSelectReview) {
      AssessmentsIdDetailStore.updateCurrentTab(initialTab);
    }
  }, [currentTab, initialTab, isAvailableFeature]);

  React.useEffect(() => {
    if (selectedExamId === null && examId) {
      AssessmentsIdDetailStore.updateSelectedExamId(examId);
    }
  }, [selectedExamId, examId]);

  React.useEffect(() => {
    return () => {
      AssessmentsIdDetailStore.clear();
    };
  }, []);

  const containerProps: AssessmentDetailContainerProps = {
    assessmentId: assessmentId,
  };

  return (
    <ErrorBoundary>
      <AssessmentDetailContainer {...containerProps} />
    </ErrorBoundary>
  );
};
AssessmentsDetailInitialContainer.displayName = "AssessmentsDetailInitialContainer";

export default withErrorBoundary(AssessmentsDetailInitialContainer, {});
