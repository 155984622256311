import { App } from "@hireroo/app-store/essential/employee";
import { ScreeningTestListForDetail } from "@hireroo/app-store/widget/e/ScreeningTestListForDetail";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";
import { useParams } from "react-router-dom";

import ScreeningTestListContainer, { ScreeningTestListContainerProps } from "./Container";

export type ScreeningTestListFetchContainerProps = {};

const ScreeningTestListFetchContainer: React.FC<ScreeningTestListFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const { id } = useParams<{
    id: string;
  }>();
  const screeningId = `${id}`;

  const listParams = ScreeningTestListForDetail.useListParams();
  const initialized = ScreeningTestListForDetail.useInitializedV2();
  const input: Graphql.SpotsByScreeningIdInput = {
    screeningId: screeningId,
    isDescending: listParams.isDescending,
    offset: listParams.page * listParams.size,
    size: listParams.size,
    sortMethod: listParams.sortMethod,
    ignoreInheritance: false,
    withCount: true,
    withDeleted: false,
    filters: {
      name: listParams.filters.name,
      /**
       * using same value as name since the input is the same
       */
      candidateEmail: listParams.filters.name,
      /**
       * using same value as name since the input is the same
       */
      candidateName: listParams.filters.name,
      minTotalScore: null,
      maxTotalScore: null,
      minCustomScore: listParams.filters.minCustomScore,
      maxCustomScore: listParams.filters.maxCustomScore,
      statuses: [...listParams.filters.statuses],
      tagNames: [...listParams.filters.tagNames],
      rankEvaluations: [...listParams.filters.rankEvaluations],
      variableEntries: [...listParams.filters.variableEntries],
      result: listParams.filters.result,
      suspiciousDegrees: listParams.filters.suspiciousDegrees.slice(),
    },
  };
  const [result, refresh] = Graphql.useListSpotsByScreeningIdForScreeningDetailQuery({
    variables: {
      input: input,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "cache-and-network",
  });
  React.useEffect(() => {
    if (result.fetching) {
      ScreeningTestListForDetail.updateListFetchingStatus("FETCHING");
    } else if (result.data) {
      ScreeningTestListForDetail.updateListFetchingStatus("FETCHED");
    }
  }, [result.data, result.fetching]);
  React.useEffect(() => {
    if (result.data) {
      if (result.data.spotsByScreeningId) {
        ScreeningTestListForDetail.updateResponse(result.data.spotsByScreeningId);
        ScreeningTestListForDetail.updateCount(result.data.spotsByScreeningId.count);
        ScreeningTestListForDetail.clampPage();
      }
    }
  }, [result.data]);
  React.useEffect(() => {
    Snackbar.setOpenLoadingSnackbar(result.stale);
  }, [result.stale]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: ScreeningTestListContainerProps = {
    refresh: refresh,
  };

  return <ScreeningTestListContainer {...containerProps} />;
};

ScreeningTestListFetchContainer.displayName = "TestListTableFetchContainer";

export default withErrorBoundary(ScreeningTestListFetchContainer, {});
