import * as ProjectHelper from "@hireroo/app-helper/project-v3";
import { ProjectCodingEditorV3 } from "@hireroo/app-store/widget/shared/ProjectCodingEditorV3";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { ProjectFailureReason } from "@hireroo/graphql/server/types";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/react";
import Papa from "papaparse";
import * as React from "react";

import type { Mode } from "./types";

export type GenerateDataScienceTestCaseDialogArgs = {
  open: boolean;
  entityId: number;
  mode: Mode;
};

type Item = Exclude<Widget.ProjectCodingEditorV3Props["dataScienceTestCaseDialog"], undefined>["items"][0];

type TestResult = Exclude<Item["testResult"], undefined>["testResult"];

type CSVRowProps = TestResult["csvRows"][0];

export const useGenerateDataScienceTestCaseDialogProps = (
  args: GenerateDataScienceTestCaseDialogArgs,
): Widget.ProjectCodingEditorV3Props["dataScienceTestCaseDialog"] => {
  const action = ProjectCodingEditorV3.createProjectEntityAction(args.entityId);
  const hooks = ProjectCodingEditorV3.useCreateProjectEntityHooks(args.entityId);
  const question = hooks.useQuestion();
  const workspace = hooks.useWorkspace();
  const testCaseStateMap = hooks.useTestCaseState();
  const testCaseResultMap = hooks.useDataScienceTestCaseResultMap();
  const testCases = React.useMemo(() => {
    if (!question?.correctnessTestCase) {
      return [];
    }
    // TODO: Parse version first so that we can use parser depending on the version
    return ProjectHelper.DataScienceTestCase.parseDataScienceTestCases(question.correctnessTestCase)?.test_cases ?? [];
  }, [question?.correctnessTestCase]);

  const { t } = useTranslation();
  const client = getGraphqlClient();

  if (!args.open || question?.variant !== "DataScience") {
    return undefined;
  }

  const snapshotFailureMessageMap = {
    WORKSPACE_PREPARATION_FAILED: t("テスト実行用の環境の構築に失敗したため評価に失敗しました。運営までお問合せください。"),
    SERVER_HEALTH_CHECK_FAILED: t("サーバーの起動確認に失敗しました。運営までお問合せください。"),
    EVALUATION_PREPARATION_FAILED: t("評価プロセス中にエラーが発生しました。運営までお問合せください。"),
    UNKNOWN: t("予期しないエラーによりテストケースの実行に失敗しました。運営までお問合せください。"),
  } as const satisfies Record<string, string>;

  const testCaseFailureMessageMap = {
    SERVER_HEALTH_CHECK_FAILED: t("サーバーの起動確認に失敗しました。運営までお問合せください。"),
    EVALUATION_PREPARATION_FAILED: t("評価プロセス中にエラーが発生しました。運営までお問合せください。"),
  } as const satisfies Record<string, string>;

  const getFailureMessage = (
    snapshotIsFailed: boolean,
    testCaseIsFailed: boolean,
    snapshotFailureReason: ProjectFailureReason | undefined,
    testCaseFailureReason: "SERVER_HEALTH_CHECK_FAILED" | "EVALUATION_PREPARATION_FAILED" | undefined,
  ): string => {
    if (snapshotIsFailed) {
      return snapshotFailureReason
        ? snapshotFailureMessageMap[snapshotFailureReason]
        : t("予期しないエラーによりテストケースの実行に失敗しました。運営までお問合せください。");
    } else if (testCaseIsFailed) {
      return testCaseFailureReason
        ? testCaseFailureMessageMap[testCaseFailureReason]
        : t("予期しないエラーによりテストケースの実行に失敗しました。運営までお問合せください。");
    } else {
      return t("予期しないエラーによりテストケースの実行に失敗しました。運営までお問合せください。");
    }
  };

  return {
    onClose: () => {
      action.clearTestResultForDataScience();
    },
    items: testCases
      .filter(testCase => !testCase.is_hidden)
      .map((testCase, tcIndex): Item => {
        const key = `${JSON.stringify(testCase)}-${tcIndex}`;
        const testResult = testCaseResultMap.get(key);
        const testCaseState = testCaseStateMap.get(key);
        const snapshotIsFailed = testCaseState?.snapshotStatus === "FAILED";
        const testCaseIsFailed = testCaseState?.testCaseStatus === "FAILED";
        const testCaseIsPassed = testResult?.num_correct === testResult?.num_rows;

        const parsedActualCSV: Papa.ParseResult<Record<string, string>> = Papa.parse(testResult?.actual ?? "", {
          header: true,
          dynamicTyping: true,
          skipEmptyLines: true,
        });

        const parsedExpectedCSV: Papa.ParseResult<Record<string, string>> = Papa.parse(testResult?.expected ?? "", {
          header: true,
          dynamicTyping: true,
          skipEmptyLines: true,
        });

        const unionHeader = Array.from(new Set([...(parsedActualCSV.meta.fields ?? []), ...(parsedExpectedCSV.meta.fields ?? [])]));

        const csvRows = parsedExpectedCSV.data.map((expectedRow, index): CSVRowProps => {
          return {
            actualRow: ProjectHelper.DataScienceTestCase.formatCSVRow(parsedActualCSV.data.at(index) ?? {}, unionHeader),
            expectedRow: ProjectHelper.DataScienceTestCase.formatCSVRow(expectedRow, unionHeader),
            isCorrect: testResult?.matches.at(index) ?? false,
          };
        });

        return {
          forceOpen: snapshotIsFailed || !!testResult,
          status: testCaseState ? testCaseState.status : "DEFAULT",
          title: `${t("テストケース")} ${tcIndex + 1}`,
          runTestCaseButton: {
            onClick: () => {
              Snackbar.notify({
                severity: "info",
                message: t("実行が開始しました。処理が終わるまでしばらくお待ち下さい。"),
              });
              action.setTestCaseState(key, {
                status: "LOADING",
              });

              if (!workspace) {
                Snackbar.notify({
                  severity: "error",
                  message: t("実行に失敗しました。運営までお問い合わせください。"),
                });
                Sentry.captureException("failed to run project for project since workspace is not found");
                return;
              }

              client
                .RunProjectForProjectCodingEditor({
                  input: {
                    projectId: args.entityId,
                    questionId: question.questionId,
                    questionVersion: question.version,
                    testCase: JSON.stringify(testCase),
                    workspaceId: workspace.id,
                    takeSnapshot: args.mode === "DEVELOPMENT",
                  },
                })
                .then(res => {
                  Snackbar.notify({
                    severity: "success",
                    message: t("実行が完了しました。結果を確認してください。"),
                  });

                  if (res.runProject.status === "FAILED") {
                    return action.setTestCaseState(key, {
                      status: "FAILED",
                      snapshotStatus: res.runProject.status,
                      failureReason: res.runProject.failureReason,
                    });
                  }

                  const testCaseResult = ProjectHelper.DataScienceTestCase.parseDataScienceTestCaseResult(res.runProject.testResult);
                  if (!testCaseResult) {
                    /**
                     * TODO Decide how to behave when TestCase fails to parse successfully.
                     */
                    return;
                  }

                  action.setTestCaseResultForDataScience(key, testCaseResult);
                  action.setTestCaseState(key, {
                    status: testCaseResult.is_passed ? "SUCCEEDED" : "FAILED",
                    snapshotStatus: res.runProject.status,
                    failureReason: res.runProject.failureReason,
                  });
                })
                .catch(() => {
                  action.setTestCaseState(key, {
                    /**
                     * TODO Consider whether to set status to FAILED in case of error after migration
                     */
                    status: "DEFAULT",
                  });
                  Snackbar.notify({
                    severity: "error",
                    message: t("実行に失敗しました。ソースコードを確認してください。"),
                  });
                });
            },
          },
          testResult: !testResult
            ? undefined
            : {
                /**
                 * TODO: consider putting test cases inside of the submission
                 */
                status: testCaseIsPassed ? "SUCCESS" : "ERROR",
                testResult: {
                  header: unionHeader,
                  csvRows: csvRows,
                },
              },
          failureDescription:
            snapshotIsFailed || testCaseIsFailed
              ? getFailureMessage(snapshotIsFailed, testCaseIsFailed, testCaseState?.failureReason, testCaseState?.testCaseFailureReason)
              : undefined,
        };
      }),
  };
};
