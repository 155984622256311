import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type RemoteInterviewResourceEditorContainerProps = {};

const RemoteInterviewResourceEditorContainer: React.FC<RemoteInterviewResourceEditorContainerProps> = () => {
  const remoteInterviewResourceEditorProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Pages.RemoteInterviewResourceEditor {...remoteInterviewResourceEditorProps} />
    </ErrorBoundary>
  );
};

RemoteInterviewResourceEditorContainer.displayName = "RemoteInterviewResourceEditorContainer";

export default withErrorBoundary(RemoteInterviewResourceEditorContainer, {});
