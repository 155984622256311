import * as React from "react";
import { useSnapshot } from "valtio";

import { state } from "./State";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = () => {
  const snapshot = useSnapshotState();
  return !!snapshot.company;
};

export const useCompany = () => {
  const snapshot = useSnapshotState();
  return snapshot.company;
};

export const useStrictCompany = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.company) {
    throw new Error("Not initialized company");
  }
  return snapshot.company;
};

export const useInvitation = () => {
  const snapshot = useSnapshotState();
  return snapshot.invitation;
};

export const useIsExpired = () => {
  const invitation = useInvitation();
  if (!invitation) {
    return false;
  }
  if (invitation.expiresAtSeconds === null) {
    return false;
  }
  return invitation.expiresAtSeconds * 1000 < new Date().getTime();
};

export const useAlreadyMember = (currentUserId: string): boolean => {
  const snapshot = useSnapshotState();
  const company = snapshot.company;
  return React.useMemo(() => {
    if (!company) {
      return false;
    }
    return !!company.companyTalents.find(companyTalent => companyTalent.talentId === currentUserId);
  }, [company, currentUserId]);
};
