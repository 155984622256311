import type * as Graphql from "@hireroo/graphql/client/urql";
import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type EntryScreeningTestContainerProps = {
  spot: Graphql.SpotForEntryScreeningsTestsIdFragment;
};

const EntryScreeningTestContainer: React.FC<EntryScreeningTestContainerProps> = props => {
  const entryScreeningTestProps = useGenerateProps({
    spot: props.spot,
  });
  return (
    <ErrorBoundary>
      <Pages.EntryScreeningTest {...entryScreeningTestProps} />
    </ErrorBoundary>
  );
};

EntryScreeningTestContainer.displayName = "EntryScreeningTestContainer";

export default withErrorBoundary(EntryScreeningTestContainer, {});
