import { App, Company } from "@hireroo/app-store/essential/employee";
import { ScreeningsStore } from "@hireroo/app-store/page/e/screenings";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import * as React from "react";

import ScreeningSearchAreaContainer, { ScreeningSearchAreaContainerProps } from "./Container";

export type ScreeningSearchAreaFetchContainerProps = {};

const ScreeningSearchAreaFetchContainer: React.FC<ScreeningSearchAreaFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const companyId = Company.useStrictActiveCompanyId();
  const initialized = ScreeningsStore.useInitialized();
  const [result, refresh] = Graphql.useGetSpotTagsForScreeningsQuery({
    variables: {
      spotTagsByCompanyId: {
        companyId: companyId,
        size: 100,
        withCount: true,
      },
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "network-only",
  });

  React.useEffect(() => {
    if (result.data?.spotTagsByCompanyId) {
      ScreeningsStore.setSpotTags(result.data.spotTagsByCompanyId);
    }
  }, [result.data?.spotTagsByCompanyId]);

  React.useEffect(() => {
    /**
     * Set tag refresh function to store. This is necessary to refresh the tag list when new tag is created.
     */
    ScreeningsStore.setTagRefresh(refresh);
  }, [refresh]);

  React.useEffect(() => {
    return () => {
      ScreeningsStore.clear();
    };
  }, []);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }

  if (appStatus !== "INITIALIZED") {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: ScreeningSearchAreaContainerProps = {};

  return <ScreeningSearchAreaContainer key={`active-${initialized}`} {...containerProps} />;
};

ScreeningSearchAreaFetchContainer.displayName = "ScreeningSearchAreaFetchContainer";

export default withErrorBoundary(ScreeningSearchAreaFetchContainer, {});
