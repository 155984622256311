import { RemoteInterviewSearchForm as RemoteInterviewSearchFormValidator } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import SearchForm, { SearchFormProps } from "../../modules/SearchForm/SearchForm";
import * as PrivateHelper from "./privateHelper";

export type RemoteInterviewSearchAreaProps = {
  disabled: boolean;
  tagsField: Pick<PrivateHelper.TagFieldProps, "selectableTags">;
  defaultValues: RemoteInterviewSearchFormValidator.RemoteInterviewSearchQuerySchema;
  onChange: SubmitHandler<RemoteInterviewSearchFormValidator.RemoteInterviewSearchQuerySchema>;
  createdByField: Pick<PrivateHelper.MultiChoiceFieldProps, "options">;
};

const RemoteInterviewSearchArea: React.FC<RemoteInterviewSearchAreaProps> = props => {
  const { onChange } = props;
  const validateSchema = RemoteInterviewSearchFormValidator.useRemoteInterviewSearchQuery();
  const methods = useForm<RemoteInterviewSearchFormValidator.RemoteInterviewSearchQuerySchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: props.defaultValues,
  });
  const textFilterField = PrivateHelper.useTextFilterField();
  const tagsField = PrivateHelper.useTagsField(props.tagsField);
  const statusesField = PrivateHelper.useStatusesField();
  const resultsField = PrivateHelper.useResultsField();
  const createdByField = PrivateHelper.useCreatedByField(props.createdByField);

  React.useEffect(() => {
    const subscriber = methods.watch(() => {
      const fields = methods.getValues();
      onChange(fields);
    });
    return () => {
      subscriber.unsubscribe();
    };
  }, [methods, onChange]);
  const searchFormProps: SearchFormProps = {
    kind: "MULTI_LINE",
    disabled: props.disabled,
    textFilter: textFilterField,
    fields: [statusesField, resultsField, createdByField, tagsField],
    onReset: () => {
      const resetValues: RemoteInterviewSearchFormValidator.RemoteInterviewSearchQuerySchema = {
        statuses: [],
        createdBy: [],
        tags: [],
        result: null,
        textFilter: "",
      };
      methods.reset(resetValues);
    },
  };
  return (
    <FormProvider {...methods}>
      <SearchForm {...searchFormProps} />
    </FormProvider>
  );
};

RemoteInterviewSearchArea.displayName = "ScreeningSearchArea";

export default RemoteInterviewSearchArea;
