import { useTranslation } from "@hireroo/i18n";
import { Divider, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import * as React from "react";

import { usePaginator, UsePaginatorArgs } from "../../hooks";

export type Notification = {
  id: number;
  title: string;
  distance: string;
  onClick: () => void;
};

export type NotificationListProps = {
  notifications: Notification[];
  hasNext: boolean;
  onClickReadAll?: () => void;
  intersectCallback?: () => void;
};

const PAGER = {
  rootKey: "notification-item-header",
  moreKey: "notificationLoadMore",
} as const;

const NotificationList: React.FC<NotificationListProps> = props => {
  const { t } = useTranslation();

  const paginatorArgs: UsePaginatorArgs = {
    root: PAGER.rootKey,
    target: PAGER.moreKey,
    callback: () => {
      props.intersectCallback?.();
    },
    deps: [props.intersectCallback],
  };
  usePaginator(paginatorArgs);

  return (
    <List component="nav" sx={{ minWidth: "300px" }} id={PAGER.rootKey}>
      <ListItem
        secondaryAction={
          props.notifications.length > 0 && (
            <Button role="readallbutton" onClick={props.onClickReadAll}>
              <Typography>{t("全て既読にする")}</Typography>
            </Button>
          )
        }
      >
        <ListItemText role="unreadnotification" primary={props.notifications.length > 0 ? t("未読の通知") : t("未読の通知はありません。")} />
      </ListItem>
      {props.notifications.map(notification => (
        <React.Fragment key={notification.id}>
          <Divider />
          <ListItemButton role={`notification${notification.id}`} id={`notification-item-${notification.id}`} onClick={notification.onClick}>
            <ListItemText primary={notification.title} secondary={notification.distance} />
          </ListItemButton>
        </React.Fragment>
      ))}
      {props.hasNext && (
        <>
          <Divider />
          <ListItem id={PAGER.moreKey}>
            <ListItemText role="readmore" primary={t("読み込み中...")} />
          </ListItem>
        </>
      )}
    </List>
  );
};

NotificationList.displayName = "NotificationList";

export default NotificationList;
