import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Box, { BoxProps } from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Tab, { TabProps } from "@mui/material/Tab";
import Tabs, { TabsProps } from "@mui/material/Tabs";
import * as React from "react";

import EditMenu, { EditMenuProps } from "./parts/EditMenu";
import TabItem, { TabItemProps } from "./parts/TabItem";

export type TabFieldItem = {
  /**
   * A value to identify the tab.
   * Do not use variable values such as i18n values.
   */
  id: string;
  name: string;
  tab: Omit<TabItemProps, "children">;
  Content: React.ReactNode;
};

export type AddableTabsProps = {
  defaultTab?: string;
  tabListBox?: BoxProps;
  tabs?: Omit<TabsProps, "value" | "onChange">;
  currentTabValue: string;
  updateCurrentTabValue: (value: string) => void;
  menu: Pick<EditMenuProps, "items">;
  items: TabFieldItem[];
  addTabButton: ButtonProps;
  isInitialTabClosable?: boolean;
  addButtonBox?: BoxProps;
};

const AddableTabs: React.FC<AddableTabsProps> = props => {
  const { items, menu, addTabButton } = props;
  const defaultTab = props.defaultTab || (items.length ? items[0].id : "");
  const { currentTabValue, updateCurrentTabValue } = props;
  const [currentTabIds, setCurrentTabIds] = React.useState(items.map(item => item.id));
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  React.useEffect(() => {
    if (0 < items.length && items.length < currentTabIds.length) {
      // Decrease tab
      updateCurrentTabValue(items[0].id);
      setCurrentTabIds(items.map(item => item.id));
    } else if (items.length > currentTabIds.length) {
      // Increase tab
      const addedItems = items.filter(item => !currentTabIds.includes(item.id));
      if (addedItems.length) {
        updateCurrentTabValue(addedItems[0].id);
      }
      setCurrentTabIds(items.map(item => item.id));
    }
  }, [currentTabIds, items, updateCurrentTabValue]);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    updateCurrentTabValue(newValue);
  };
  const TabPanels = props.items.map(item => {
    return (
      <TabPanel key={item.id} value={item.id} sx={{ paddingX: 0 }}>
        {item.Content}
      </TabPanel>
    );
  });
  const editMenuProps: EditMenuProps = {
    items: menu.items.map(item => {
      return {
        ...item,
        onClick: () => {
          item.onClick?.();
          setAnchorEl(null);
        },
      };
    }),
    anchorEl,
    onClose: () => {
      setAnchorEl(null);
    },
  };
  const addButton: ButtonProps = {
    disabled: editMenuProps.items.length === 0,
    size: "small",
    variant: "text",
    sx: {
      mr: 2,
    },
    ...props.addTabButton,
    onClick: React.useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        addTabButton.onClick?.(event);
        setAnchorEl(event.currentTarget);
      },
      [addTabButton],
    ),
  };
  return (
    <TabContext value={currentTabValue}>
      <Box display="flex" justifyContent="space-between" {...props.tabListBox}>
        <Tabs value={currentTabValue} onChange={handleChange} {...props.tabs}>
          {props.items.map((item, index) => {
            //TODO: @asaki15 refactor is required since the logic below should be on the parent component
            const showInitialTabCloseButton = props.isInitialTabClosable && items.length === 1;
            const showingCloseButton = currentTabValue === item.id && 1 < items.length;
            const tabItemProps: TabItemProps = {
              ...item.tab,
              closeButton:
                showInitialTabCloseButton || showingCloseButton
                  ? {
                      ...item.tab.closeButton,
                      onClick: event => {
                        event.stopPropagation();
                        updateCurrentTabValue(defaultTab);
                        item.tab.closeButton && item.tab.closeButton?.onClick?.(event);
                      },
                    }
                  : undefined,
              children: item.name,
            };
            const tabProps: TabProps = {
              "aria-labelledby": item.name,
              value: item.id,
              label: <TabItem {...tabItemProps} />,
            };
            return <Tab key={`${item.name}-${index}`} {...tabProps} />;
          })}
        </Tabs>
        <Box display="flex" minWidth="140px" {...props.addButtonBox}>
          <Button {...addButton} />
        </Box>
        <EditMenu {...editMenuProps} />
      </Box>
      <Box>{TabPanels}</Box>
    </TabContext>
  );
};

AddableTabs.displayName = "AddableTabs";

export default AddableTabs;
