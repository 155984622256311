import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GeneratePaymentConfirmationDetailsPropsArgs, useGenerateProps } from "./useGenerateProps";

export type PaymentConfirmationDetailsContainerProps = GeneratePaymentConfirmationDetailsPropsArgs;

const PaymentConfirmationDetailsContainer: React.FC<PaymentConfirmationDetailsContainerProps> = props => {
  const paymentConfirmationDetailsProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.PaymentConfirmationDetails {...paymentConfirmationDetailsProps} />
    </ErrorBoundary>
  );
};

PaymentConfirmationDetailsContainer.displayName = "PaymentConfirmationDetailsContainer";

export default withErrorBoundary(PaymentConfirmationDetailsContainer, {});
