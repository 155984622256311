import { state } from "./State";

export const createEntityApi = (entityId: number) => {
  return {
    getLeavePageAction: () => {
      const entityState = state.challenges.get(entityId);
      return entityState?.leavePageAction ?? null;
    },
  };
};
