import type { Widget } from "@hireroo/presentation";

import { useGenerateContentProps } from "./useGenerateContentProps";
import { useGeneratePlaybackToolbarProps } from "./useGeneratePlaybackToolbarProps";

export type GenerateRemoteInterviewEntityPlaybackPropsArgs = {};

export const useGenerateProps = (_args: GenerateRemoteInterviewEntityPlaybackPropsArgs): Widget.RemoteInterviewEntityPlaybackProps => {
  const content = useGenerateContentProps();
  const playbackToolbar = useGeneratePlaybackToolbarProps();
  return {
    content: content,
    playbackToolbar: playbackToolbar,
  };
};
