import { state } from "./State";
import type * as Types from "./types";

export const initialize = (newValue: Types.Test, entityId: Types.UniqueEntityId): void => {
  state.test = newValue;
  state.selectedEntityId = entityId;
};
export const updateTest = (newValue: Types.Test): void => {
  state.test = newValue;
};

export const clear = () => {
  state.test = null;
  state.selectedEntityId = null;
};

export const updateSelectedEntityId = (newValue: Types.UniqueEntityId) => {
  state.selectedEntityId = newValue;
};
