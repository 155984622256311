import { proxy } from "valtio";
import { proxyMap } from "valtio/utils";

import type * as Types from "./types";

export const state = proxy<Types.State>({
  systemDesigns: proxyMap(),
  submittedEntity: null,
  ipAddress: null,
  geolocation: null,
});
