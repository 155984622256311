import { QUESTION_VARIANT_REVERSE_MAP } from "@hireroo/app-definition/question";
import { QuestionsStore } from "@hireroo/app-store/page/e/questions";
import * as Graphql from "@hireroo/graphql/client/urql";
import { QuestionSearchForm } from "@hireroo/validator";

const SIZES = [20, 30, 50];
const DEFAULT_SIZE = QuestionsStore.DefaultPager.size;
const DEFAULT_SORT_FIELD_VALUE = QuestionsStore.DefaultPager.sortFieldValue;

export const convertStringToSize = (value: string | null): number => {
  if (value === null) return DEFAULT_SIZE;
  const size = Number(value);
  // Set it to the default value if it is invalid.
  if (!SIZES.includes(size)) {
    return DEFAULT_SIZE;
  }
  return size;
};

export const convertStringsToPage = (value: string | null): number => {
  if (value === null) return 0;
  const page = Number(value);
  // Set it to 0 if it is invalid (NaN or negative). Note that page > 0 is false if page is NaN.
  // Actual (zero-index) page number = (page number from the query param) - 1
  if (page < 1) {
    return 0;
  }
  return page - 1;
};

const StatusReverseMap: Record<string, QuestionSearchForm.StatusesSchema[0]> = {
  [Graphql.QuestionStatus.Draft]: Graphql.QuestionStatus.Draft,
  [Graphql.QuestionStatus.Reviewing]: Graphql.QuestionStatus.Reviewing,
  [Graphql.QuestionStatus.Published]: Graphql.QuestionStatus.Published,
  [Graphql.QuestionStatus.Rejected]: Graphql.QuestionStatus.Rejected,
};

export const convertStringsToStatuses = (values: string[]): QuestionSearchForm.StatusesSchema => {
  const newStatuses: QuestionSearchForm.StatusesSchema = [];
  for (const value of values) {
    value.split("%").forEach(v => {
      // Check if the string value can be converted to a valid status.
      if (StatusReverseMap[v]) {
        newStatuses.push(StatusReverseMap[v]);
      }
    });
  }
  return newStatuses;
};

export const convertStringsToQuestionVariants = (values: string[]): Graphql.QuestionVariant[] => {
  const newQuestionVariants: Graphql.QuestionVariant[] = [];
  for (const value of values) {
    value.split("%").forEach(v => {
      // Check if the string value can be converted to a valid questionVariant.
      if (QUESTION_VARIANT_REVERSE_MAP[v]) {
        newQuestionVariants.push(QUESTION_VARIANT_REVERSE_MAP[v]);
      }
    });
  }
  return newQuestionVariants;
};

const DifficultyReversMap: Record<string, QuestionSearchForm.DifficultySchema[0]> = {
  [Graphql.Difficulty.Easy]: "EASY",
  [Graphql.Difficulty.Medium]: "MEDIUM",
  [Graphql.Difficulty.Difficult]: "DIFFICULT",
};

const LeakScoreLevelReverseMap: Record<string, QuestionSearchForm.LeakScoreLevelsSchema[0]> = {
  [Graphql.QuestionLeakScoreLevel.Low]: "LOW",
  [Graphql.QuestionLeakScoreLevel.Medium]: "MEDIUM",
  [Graphql.QuestionLeakScoreLevel.High]: "HIGH",
};

export const convertStringsToDifficulties = (values: string[]): QuestionSearchForm.DifficultySchema => {
  const newDifficulties: QuestionSearchForm.DifficultySchema = [];
  for (const value of values) {
    value.split("%").forEach(v => {
      // Check if the string value can be converted to a valid difficulty.
      if (DifficultyReversMap[v]) {
        newDifficulties.push(DifficultyReversMap[v]);
      }
    });
  }
  return newDifficulties;
};

export const SortMethodReverseMap: Record<string, "unknown" | "accuracy-rate" | "average-elapsed-time" | "num-uses" | "created-at"> = {
  [Graphql.SortMethod.Unknown]: "unknown",
  [Graphql.SortMethod.CreatedAt]: "created-at",
  [Graphql.SortMethod.NumUses]: "num-uses",
  [Graphql.SortMethod.AccuracyRate]: "accuracy-rate",
  [Graphql.SortMethod.AverageElapsedTime]: "average-elapsed-time",
};
export const convertStringToSortFieldValue = (value: string | null): QuestionSearchForm.SortFieldValue => {
  if (value === null) return DEFAULT_SORT_FIELD_VALUE;
  try {
    // "sortField+isDesc" (e.g.) "CREATED_AT-true"
    const [field, isDesc] = value.split("-");
    const sortField = SortMethodReverseMap[field] ?? "unknown";
    const isDescString = isDesc === "true" ? "descending" : "ascending";
    return `${sortField}-${isDescString}` as QuestionSearchForm.SortFieldValue;
  } catch (_) {
    return "unknown-ascending";
  }
};

export const convertStringsToSkillTags = (values: string[]): QuestionSearchForm.SkillTags => {
  // Get all the valid string values
  const tags: QuestionSearchForm.SkillTags = [];
  values.forEach(value => {
    value.split("%").forEach(value => {
      tags.push({
        value: value,
      });
    });
  });
  return tags;
};

export const convertStringsToLeakScoreLevels = (values: string[]): QuestionSearchForm.LeakScoreLevelsSchema => {
  const newLeakScoreLevels: QuestionSearchForm.LeakScoreLevelsSchema = [];
  for (const value of values) {
    value.split("%").forEach(v => {
      // Check if the string value can be converted to a valid difficulty.
      if (LeakScoreLevelReverseMap[v]) {
        newLeakScoreLevels.push(LeakScoreLevelReverseMap[v]);
      }
    });
  }
  return newLeakScoreLevels;
};
