import { RemotesStore } from "@hireroo/app-store/page/e/remotes";
import * as Graphql from "@hireroo/graphql/client/urql";
import { RemoteInterviewSearchForm } from "@hireroo/validator";

export const convertStringsToTagNames = (values: string[]): RemoteInterviewSearchForm.RemoteInterviewSearchQuerySchema["tags"] => {
  // Get all the valid string values
  const tags: RemoteInterviewSearchForm.RemoteInterviewSearchQuerySchema["tags"] = [];
  values.forEach(value => {
    value.split("%").forEach(value => {
      tags.push({
        displayName: value,
      });
    });
  });
  return tags;
};

export const SortMethodReverseMap: Record<string, "created-at"> = {
  [Graphql.SortMethod.CreatedAt]: "created-at",
};

export const convertStringToSortFieldValue = (value: string | null): RemoteInterviewSearchForm.SortFieldValue => {
  if (value === null) return "created-at-descending";
  try {
    // "sortField+isDesc" (e.g.) "CREATED_AT-true"
    const [field, isDesc] = value.split("-");
    const sortField = SortMethodReverseMap[field] ?? "unknown";
    const isDescString = isDesc === "true" ? "descending" : "ascending";
    return `${sortField}-${isDescString}` as RemoteInterviewSearchForm.SortFieldValue;
  } catch (_) {
    return "created-at-descending";
  }
};

export const convertStringsToPage = (value: string | null): number => {
  if (value === null) return 0;
  const page = Number(value);
  // Set it to 0 if it is invalid (NaN or negative). Note that page > 0 is false if page is NaN.
  // Actual (zero-index) page number = (page number from the query param) - 1
  if (page < 1) {
    return 0;
  }
  return page - 1;
};

const SIZES = [20, 30, 50];
export const convertStringToSize = (value: string | null) => {
  if (value === null) return RemotesStore.DefaultPager.size;
  const size = Number(value);
  // Set it to the default value if it is invalid.
  if (!SIZES.includes(size)) {
    return RemotesStore.DefaultPager.size;
  }
  return size;
};

const statusMap: Record<string, RemoteInterviewSearchForm.Statuses[number]> = {
  CREATED: "CREATED",
  STARTED: "STARTED",
  COMPLETED: "COMPLETED",
  EVALUATED: "EVALUATED",
};
export const convertStringsToStatuses = (values: string[]): RemoteInterviewSearchForm.Statuses => {
  const newStatuses: RemoteInterviewSearchForm.Statuses = [];
  for (const value of values) {
    value.split("%").forEach(v => {
      // Check if the string value can be converted to a valid status.
      if (statusMap[v]) {
        newStatuses.push(statusMap[v]);
      }
    });
  }
  return newStatuses;
};

export const convertStringsToUids = (values: string[]): string[] => {
  // Get all the valid string values
  const uids: string[] = [];
  values.forEach(value => {
    value.split("%").forEach(v => {
      uids.push(v);
    });
  });
  return uids;
};

export const convertStringToResult = (value: string | null): RemoteInterviewSearchForm.Result => {
  if (!value) return null;
  for (const v of value.split("%")) {
    if (v === "PASSED" || v === "NOT_PASSED") {
      return v;
    }
  }
  return null;
};
