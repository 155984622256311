import { styled } from "@mui/material/styles";
import * as React from "react";

import EmployeeLayout, { EmployeeLayoutProps } from "../../layouts/EmployeeLayout/EmployeeLayout";

const CONTENT_WRAPPER_CLASS_NAME = "content-wrapper";
const SCROLLABLE_CLASS_NAME = "scrollable-content-wrapper";

const StyledEmployeeLayout = styled(EmployeeLayout)(() => ({
  overflow: "hidden",
  height: "100vh",
  [`.${CONTENT_WRAPPER_CLASS_NAME}`]: {
    height: "calc(100vh - 72px)",
  },
  [`.${SCROLLABLE_CLASS_NAME}`]: {
    height: "calc(100vh - 72px)",
    overflow: "auto",
  },
}));

export type QuestionDetailProps = {
  layout: EmployeeLayoutProps;
  children: React.ReactNode;
  /**
   * @default false
   */
  scrollable?: boolean;
};

const QuestionDetail: React.FC<QuestionDetailProps> = props => {
  const layoutProps: EmployeeLayoutProps = {
    ...props.layout,
    headerPosition: "static",
    contentWrapper: {
      className: props.scrollable ? SCROLLABLE_CLASS_NAME : CONTENT_WRAPPER_CLASS_NAME,
    },
  };
  return <StyledEmployeeLayout {...layoutProps}>{props.children}</StyledEmployeeLayout>;
};

QuestionDetail.displayName = "QuestionDetail";

export default QuestionDetail;
