import * as Graphql from "@hireroo/graphql/client/urql";
import { proxyMap } from "valtio/utils";

import type * as Types from "./types";

export const defaultFilters: Readonly<Types.Filters> = {
  createdByList: [],
  name: "",
  /**
   * If this value is `undefined`, it means that the user has not specified it explicitly.
   */
  result: undefined,
  statuses: [],
  tagNames: [],
  rankEvaluations: [],
  /**
   * If this value is `undefined`, it means that the user has not specified it explicitly.
   */
  minTotalScore: undefined,
  /**
   * If this value is `undefined`, it means that the user has not specified it explicitly.
   */
  maxTotalScore: undefined,
  /**
   * If this value is `undefined`, it means that the user has not specified it explicitly.
   */
  minCustomScore: undefined,
  /**
   * If this value is `undefined`, it means that the user has not specified it explicitly.
   */
  maxCustomScore: undefined,
  screeningIds: [],
  suspiciousDegrees: [],
};

export const defaultState: Readonly<Types.State> = {
  spotTags: null,
  screeningVariables: null,
  searchTags: [],
  listParams: {
    isDescending: true,
    page: 0,
    size: 50,
    sortMethod: Graphql.SpotsByCompanyIdSortMethod.CreatedAt,
    filters: defaultFilters,
  },
  count: 0,
  creators: [],
  download: {
    open: false,
    status: "READY",
    fields: [],
    downloadId: null,
    exportFileType: "EXCEL",
    downloadUrl: null,
  },
  listFetchingStatus: "STOP",
  initialized: false,
  screeningMap: proxyMap(),
  res: null,
  spotMap: proxyMap(),
  selectedSpotIds: [],
  tagRefresh: null,
  dialogStatus: "CLOSE",
  refreshKey: "",
};
