import { useTranslation } from "@hireroo/i18n";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

import ActivateDialog, { ActivateDialogProps } from "../../../../ms-components/Spot/ActivateDialog/ActivateDialog";

const StyledButton = styled(Button)(() => ({
  borderRadius: "50px",
  alignItems: "center",
}));

type Status = "ACTIVE" | "NOT_ACTIVE";

type DialogController = {
  setLoading: (loading: boolean) => void;
  close: () => void;
};

export type ActivateSwitchProps = {
  status: Status;
  activateDialog: Pick<ActivateDialogProps, "targetName">;
  onSubmit: (controller: DialogController) => void;
  icon?: {
    message: string;
  };
};

const ActivateSwitch: React.FC<ActivateSwitchProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const chipMap: Record<Status, string> = {
    ACTIVE: t("受付中"),
    NOT_ACTIVE: t("受付停止中"),
  };
  const itemMap: Record<Status, string> = {
    ACTIVE: t("受付再開"),
    NOT_ACTIVE: t("受付停止"),
  };
  const bgColorMap: Record<Status, string> = {
    ACTIVE: theme.palette.primary.main,
    NOT_ACTIVE: theme.palette.text.disabled,
  };
  const hoverBgColorMap: Record<Status, string> = {
    ACTIVE: theme.palette.primary.dark,
    NOT_ACTIVE: theme.palette["Gray/Shades"].p30,
  };

  const dialog: ActivateDialogProps = {
    ...props.activateDialog,
    changeStatus: props.status === "ACTIVE" ? "NOT_ACTIVE" : "ACTIVE",
    dialog: {
      open: open,
      onClose: () => {
        setOpen(false);
      },
      yesButton: {
        disabled: loading,
        onClick: () => {
          const controller: DialogController = {
            close: () => {
              setOpen(false);
            },
            setLoading: (newLoading: boolean) => {
              setLoading(newLoading);
            },
          };
          props.onSubmit(controller);
        },
      },
      noButton: {
        onClick: () => {
          setOpen(false);
        },
        disabled: loading,
      },
    },
  };
  return (
    <Stack direction="row" alignItems="center" spacing={1.25}>
      <StyledButton
        size="small"
        variant="contained"
        sx={{
          backgroundColor: bgColorMap[props.status],
          "&:hover": { backgroundColor: hoverBgColorMap[props.status] },
        }}
        onClick={handleClick}
      >
        <Typography fontSize={14}>{chipMap[props.status]}</Typography>
        <ArrowDropDownOutlinedIcon fontSize="small" />
      </StyledButton>
      <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose} onClick={e => e.stopPropagation()} variant="selectedMenu">
        <MenuItem
          value="ACTIVE"
          onClick={() => {
            if (props.status === "NOT_ACTIVE") {
              setOpen(true);
            }
            handleClose();
          }}
        >
          <Stack direction="row" spacing={2}>
            {props.status === "ACTIVE" ? <CheckOutlinedIcon fontSize="small" color="secondary" /> : <Box width="20px" />}
            <Typography fontSize={14}>{itemMap.ACTIVE}</Typography>
          </Stack>
        </MenuItem>
        <MenuItem
          value="NOT_ACTIVE"
          onClick={() => {
            if (props.status === "ACTIVE") {
              setOpen(true);
            }
            handleClose();
          }}
        >
          <Stack direction="row" spacing={2}>
            {props.status === "NOT_ACTIVE" ? <CheckOutlinedIcon fontSize="small" color="secondary" /> : <Box width="20px" />}
            <Typography fontSize={14}>{itemMap.NOT_ACTIVE}</Typography>
          </Stack>
        </MenuItem>
      </Menu>
      {props.icon && (
        <Tooltip title={props.icon.message}>
          <ErrorRoundedIcon fontSize="small" color="error" />
        </Tooltip>
      )}
      <ActivateDialog {...dialog} />
    </Stack>
  );
};

ActivateSwitch.displayName = "ActivateSwitch";

export default ActivateSwitch;
