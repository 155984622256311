import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateChallengePlaybackEditorPropsArgs, useGenerateProps } from "./useGenerateProps";

export type ChallengePlaybackEditorContainerProps = GenerateChallengePlaybackEditorPropsArgs;

const ChallengePlaybackEditorContainer: React.FC<ChallengePlaybackEditorContainerProps> = props => {
  const challengePlaybackEditorProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ChallengePlaybackEditor {...challengePlaybackEditorProps} />
    </ErrorBoundary>
  );
};

ChallengePlaybackEditorContainer.displayName = "ChallengePlaybackEditorContainer";

export default withErrorBoundary(ChallengePlaybackEditorContainer, {});
