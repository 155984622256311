import { proxy } from "valtio";
import { proxyMap } from "valtio/utils";

import type * as Types from "./types";

export const state = proxy<Types.State>({
  initialized: false,
  playbackManagerMap: proxyMap(),
  latestEncodedPathWithIdMap: proxyMap(),
  selectedFileIndex: "",
  sliderValue: 0,
  playbackSettings: {
    enabledCopyAndPasteDetection: false,
    enabledBehavioralControl: false,
    playbackMode: "EACH_FILE",
  },
  playStatus: "PAUSE",
});
