import { App, Company } from "@hireroo/app-store/essential/employee";
import { Assessments } from "@hireroo/app-store/page/e/assessments";
import * as Graphql from "@hireroo/graphql/client/urql";
import { type Pages } from "@hireroo/presentation";
import { AssessmentSearchForm } from "@hireroo/validator";
import React from "react";
import { useSearchParams } from "react-router-dom";

export const useFetchSearchParams = () => {
  const appStatus = App.useStatus();
  const companyId = Company.useStrictActiveCompanyId();
  const [result] = Graphql.useGetAssessmentSearchParamsForEmployeeAssessmentsSearchParamsQuery({
    variables: {
      companyId: companyId,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "cache-and-network",
  });

  return result;
};

const ViewerStringifyParams = {
  EmployeePrefix: "e",
  GroupPrefix: "g",
  PrefixDelimiter: "-",
  ViewerDelimiter: ",",
};

export const stringifyViewers = (viewers: Assessments.SearchFilter["viewers"]): string => {
  return viewers
    .map(viewer => {
      if (viewer.type === "EMPLOYEE") {
        return [ViewerStringifyParams.EmployeePrefix, viewer.employeeId].join(ViewerStringifyParams.PrefixDelimiter);
      }
      return [ViewerStringifyParams.GroupPrefix, viewer.groupId].join(ViewerStringifyParams.PrefixDelimiter);
    })
    .join(ViewerStringifyParams.ViewerDelimiter);
};

type ViewerOptionFieldValue = Pages.AssessmentListForEmployeeProps["search"]["viewerField"]["options"][0]["value"] &
  Assessments.SearchFilter["viewers"][0];

export const parseFlattenViewers = (input: string): ViewerOptionFieldValue[] => {
  return input.split(ViewerStringifyParams.ViewerDelimiter).reduce<ViewerOptionFieldValue[]>((viewers, splitText) => {
    const [prefix, id] = splitText.split(ViewerStringifyParams.PrefixDelimiter);
    if (typeof id !== "string" || id.length === 0) {
      return viewers;
    }
    const exists = viewers.findIndex(viewer => viewer.id === id) !== -1;
    if (exists) {
      return viewers;
    }
    if (prefix === ViewerStringifyParams.EmployeePrefix) {
      return viewers.concat({
        id: id,
        type: "EMPLOYEE",
        employeeId: id,
      });
    }
    if (prefix === ViewerStringifyParams.GroupPrefix) {
      return viewers.concat({
        id: id,
        type: "EMPLOYEE_GROUP",
        groupId: id,
      });
    }
    return viewers;
  }, []);
};

export type ValidSearchParamsFromQueryParamsArgs = {
  selectableViewerIds: string[];
  selectableTalentIds: string[];
};

export const useValidSearchParamsFromQueryParams = (args: ValidSearchParamsFromQueryParamsArgs) => {
  const [searchParams] = useSearchParams();
  const activeStatusValidator = AssessmentSearchForm.useAssessmentStatus();
  const submitStatusValidator = AssessmentSearchForm.useSubmitStatus();
  const scoreTrendValidator = AssessmentSearchForm.useScoreTrend();
  const sortFieldValidator = AssessmentSearchForm.useSortFieldValue();

  const needValidateQueryParams = React.useMemo(() => {
    return {
      assessmentStatuses: searchParams.get(Assessments.QueryKeys.ASSESSMENT_STATUS) ?? null,
      submitStatus: searchParams.get(Assessments.QueryKeys.SUBMIT_STATUS) ?? null,
      scoreTrend: searchParams.get(Assessments.QueryKeys.SCORE_TREND) ?? null,
      sortMethod: searchParams.get(Assessments.QueryKeys.SORT_METHOD) ?? null,
      page: searchParams.get(Assessments.QueryKeys.PAGE) ?? null,
      size: searchParams.get(Assessments.QueryKeys.SIZE) ?? null,
    };
  }, [searchParams]);

  const validatedSearchFilter = React.useMemo((): AssessmentSearchForm.AssessmentSearchQuerySchema => {
    const viewerIdSet = new Set(args.selectableViewerIds);
    const talentIdSet = new Set(args.selectableTalentIds);
    const viewers = parseFlattenViewers(searchParams.get(Assessments.QueryKeys.VIEWER) ?? "");
    return {
      viewers: viewers.filter(viewer => {
        if (viewer.type === "EMPLOYEE") {
          return viewerIdSet.has(viewer.employeeId);
        }
        return viewerIdSet.has(viewer.groupId);
      }),
      talent: searchParams.getAll(Assessments.QueryKeys.TALENT).filter(id => talentIdSet.has(id)),
      textFilter: searchParams.get(Assessments.QueryKeys.TITLE) ?? "",
      assessmentStatuses: (needValidateQueryParams.assessmentStatuses?.split(",") || []).reduce<
        AssessmentSearchForm.AssessmentSearchQuerySchema["assessmentStatuses"]
      >((all, status) => {
        const result = activeStatusValidator.safeParse(status);
        if (result.success && !all.includes(result.data)) {
          return all.concat(result.data);
        }
        return all;
      }, []),
      submitStatus: (() => {
        const result = submitStatusValidator.safeParse(needValidateQueryParams.submitStatus);
        return result.success ? result.data : null;
      })(),
      scoreTrend: (() => {
        const result = scoreTrendValidator.safeParse(needValidateQueryParams.scoreTrend);
        return result.success ? result.data : null;
      })(),
    };
  }, [args, activeStatusValidator, needValidateQueryParams, scoreTrendValidator, searchParams, submitStatusValidator]);

  const validatedSortField = React.useMemo(() => {
    return {
      sortFieldValue: (() => {
        const result = sortFieldValidator.safeParse(needValidateQueryParams.sortMethod);
        return result.success ? result.data : Assessments.DefaultPager.sortFieldValue;
      })(),
      page: ((): number => {
        return needValidateQueryParams.page ? Math.trunc(Number(needValidateQueryParams.page)) : Assessments.DefaultPager.page;
      })(),
      size: ((): number => {
        const size = needValidateQueryParams.size ? Math.trunc(Number(needValidateQueryParams.size)) : Assessments.DefaultPager.size;
        return [30, 20].includes(size) ? size : Assessments.DefaultPager.size;
      })(),
    };
  }, [needValidateQueryParams.page, needValidateQueryParams.size, needValidateQueryParams.sortMethod, sortFieldValidator]);

  React.useEffect(() => {
    Assessments.updateCurrentSearchFilter(validatedSearchFilter);
    Assessments.initializePager({
      page: validatedSortField.page,
      size: validatedSortField.size,
      sortFieldValue: validatedSortField.sortFieldValue,
    });
  }, [validatedSearchFilter, validatedSortField]);

  return {
    searchFilter: validatedSearchFilter,
    sortField: validatedSortField,
  };
};
