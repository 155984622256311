import { signInWithGoogle } from "@hireroo/firebase";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateHelpCenterUrl } from "@hireroo/router/api";
import { useHelpCenterNavigate, useTransitionNavigate } from "@hireroo/router/hooks";
import * as React from "react";

import SignInMailAddressContainer from "../SignInMailAddress/Container";
import SingleSignOnContainer from "../SignleSignOn/Container";

type SignInMethod = "EMAIL" | "SSO";

export type GenerateSignInPropsArgs = {};

export const useGenerateProps = (_args: GenerateSignInPropsArgs): Widget.SignInProps => {
  const { t } = useTranslation();
  const [signInMethod, setSignInMethod] = React.useState<SignInMethod>("EMAIL");
  const navigate = useTransitionNavigate();
  const lang = useLanguageCode();
  const helpCenterNavigate = useHelpCenterNavigate(lang);

  const SignInComponentMap: Record<SignInMethod, React.ReactNode> = {
    EMAIL: <SignInMailAddressContainer />,
    SSO: <SingleSignOnContainer />,
  };

  const buttonTextMap: Record<SignInMethod, string> = {
    EMAIL: t("シングルサインオン"),
    SSO: t("メールアドレスでサインイン"),
  };

  return {
    googleSignInButton: {
      onClick: () => {
        signInWithGoogle();
      },
    },
    SignInComponent: SignInComponentMap[signInMethod],
    footer: {
      helpCenterLink: {
        href: generateHelpCenterUrl(lang, "ROOT"),
        onClick: () => {
          helpCenterNavigate("ROOT", { _blank: true });
        },
      },
      termsOfUseLink: {
        href: generateHelpCenterUrl(lang, "TOS_FOR_EMPLOYEE"),
        onClick: () => {
          helpCenterNavigate("TOS_FOR_EMPLOYEE", { _blank: true });
        },
      },
      privacyPolicyLink: {
        href: generateHelpCenterUrl(lang, "PRIVACY_POLICY"),
        onClick: () => {
          helpCenterNavigate("PRIVACY_POLICY", { _blank: true });
        },
      },
    },
    signUpButton: {
      onClick: () => {
        navigate("/signup");
      },
    },
    switchButton: {
      onClick: () => {
        setSignInMethod(prev => (prev === "EMAIL" ? "SSO" : "EMAIL"));
      },
      children: buttonTextMap[signInMethod],
      icon: signInMethod === "EMAIL" ? "KEY" : undefined,
    },
  };
};
