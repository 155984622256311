import { App, Company } from "@hireroo/app-store/essential/employee";
import { IntegrationsSettings } from "@hireroo/app-store/widget/e/IntegrationsSettings";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import BatchMenuListContainer, { BatchMenuListContainerProps } from "./Container";

const LIST_SIZE = 20;

export type ScreeningMenuListFetchContainerProps = {
  onClick: () => void;
};

const ScreeningMenuListFetchContainer: React.FC<ScreeningMenuListFetchContainerProps> = props => {
  const companyId = Company.useStrictActiveCompanyId();
  const appStatus = App.useStatus();

  const [result, refresh] = Graphql.useListScreeningsForIntegrationSettingsQuery({
    variables: {
      input: {
        companyId: companyId,
        size: LIST_SIZE,
        withCount: true,
        offset: 0,
        isDescending: true,
      },
    },
    requestPolicy: "network-only",
    pause: appStatus !== "INITIALIZED",
  });

  React.useEffect(() => {
    if (result.data?.screeningsByCompanyId) {
      IntegrationsSettings.setScreeningResponse(result.data.screeningsByCompanyId);
    }
  }, [result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (appStatus !== "INITIALIZED") {
    return (
      <ErrorBoundary>
        <Widget.IntegrationBatchMenuList items={[]} createButton={{}} onEndReached={() => undefined} status="LOADING" />
      </ErrorBoundary>
    );
  }

  const containerProps: BatchMenuListContainerProps = {
    fetching: result.fetching,
    onClick: props.onClick,
  };
  return (
    <ErrorBoundary>
      <BatchMenuListContainer {...containerProps} />
    </ErrorBoundary>
  );
};

ScreeningMenuListFetchContainer.displayName = "ScreeningMenuListFetchContainer";

export default withErrorBoundary(ScreeningMenuListFetchContainer, {});
