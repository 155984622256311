import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type ResetPasswordContainerProps = {};

const ResetPasswordContainer: React.FC<ResetPasswordContainerProps> = () => {
  const resetPasswordProps = useGenerateProps({});

  return (
    <ErrorBoundary>
      <Widget.ResetPassword {...resetPasswordProps} />
    </ErrorBoundary>
  );
};

ResetPasswordContainer.displayName = "ResetPasswordContainer";

export default withErrorBoundary(ResetPasswordContainer, {});
