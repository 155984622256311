import Close from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import React from "react";

export type TabItemProps = {
  children: React.ReactNode;
  closeButton?: Pick<IconButtonProps, "onClick">;
};

const TabItem: React.FC<TabItemProps> = props => {
  const closeButtonProps: IconButtonProps = {
    ...props.closeButton,
    size: "small",
    onClick: event => {
      event.stopPropagation();
      props.closeButton?.onClick?.(event);
    },
    sx: { padding: 0 },
  };
  return (
    <Box justifyContent="space-between" display="flex" gap={2} alignItems="center">
      <Box ml="6px">
        <Typography component="span">{props.children}</Typography>
      </Box>
      {props.closeButton && (
        <IconButton {...closeButtonProps} component="span">
          <Close fontSize="small" />
        </IconButton>
      )}
    </Box>
  );
};

TabItem.displayName = "TabItem";

export default TabItem;
