import { useTranslation } from "@hireroo/i18n";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

import Link, { LinkProps } from "../../primitive/Link/Link";
import AllocationBar, { AllocationBarProps } from "./parts/AllocationBar/AllocationBar";

const StyledWrapperStack = styled(Stack)(({ theme }) => ({
  border: "1px solid",
  borderColor: theme.palette.divider,
  borderRadius: 4,
}));

export type CustomScoreAllocationProps = {
  className?: string;
  allocationBar: AllocationBarProps;
  helpLink?: Pick<LinkProps, "href" | "target">;
  tooltip: string;
};

const CustomScoreAllocation: React.FC<CustomScoreAllocationProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <StyledWrapperStack className={props.className} spacing={1} px={2} py={1}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Typography fontSize={14} fontWeight={500} color="textSecondary">
            {t("配点割合")}
          </Typography>
          <Tooltip title={props.tooltip}>
            <InfoOutlined fontSize="small" sx={{ color: theme.palette.text.secondary, height: "14px", width: "14px" }} />
          </Tooltip>
        </Stack>
        {props.helpLink && (
          <Link color="secondary" target="_blank" underline="hover" {...props.helpLink}>
            <Typography fontSize={14}>{t("ヘルプ")}</Typography>
          </Link>
        )}
      </Stack>
      <Stack width="100%">
        <AllocationBar {...props.allocationBar} />
      </Stack>
    </StyledWrapperStack>
  );
};

CustomScoreAllocation.displayName = "CustomScoreAllocation";

export default CustomScoreAllocation;
