import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import type { FieldItem } from "../../../types";

const Wrapper = styled(Paper)(() => ({
  borderRadius: "4px",
  width: "100%",
  height: "100%",
}));

const Header = styled(Box)(() => ({
  padding: "8px 16px",
  height: "48px",
}));

const Heading = styled(Typography)(() => ({
  paddingTop: "4px",
  paddingBottom: "4px",
  fontWeight: 400,
  fontSize: 16,
}));

const ListWrapper = styled(Box)(() => ({
  overflow: "auto",
  height: "284px",
}));

const StyledList = styled(List)(() => ({
  padding: 0,
}));

const StyledListItemButton = styled(ListItemButton)(() => ({
  padding: 0,
  display: "flex",
  flexDirection: "row",
}));

type ItemProps = {
  id: string;
  onClick: () => void;
  checked: boolean;
  text: string;
  disabled: boolean;
};

export type MultiSelectBoxProps = {
  disabled: boolean;
  fieldName: string;
  items: FieldItem[];
  onChange?: (selected: string[]) => void;
  errorMessage?: string;
};

const MultiSelectBox: React.FC<MultiSelectBoxProps> = props => {
  const { onChange } = props;
  const [selectedSet, updateSelectedSet] = React.useState<Set<string>>(new Set());

  const items = React.useMemo((): ItemProps[] => {
    return props.items.map((item, index): ItemProps => {
      return {
        id: `${item.name}-${index}`,
        text: item.name,
        checked: selectedSet.has(item.name),
        disabled: props.disabled,
        onClick: () => {
          updateSelectedSet(prev => {
            if (prev.has(item.name)) {
              prev.delete(item.name);
            } else {
              prev.add(item.name);
            }
            const newSelectedSt = new Set(prev);
            onChange?.([...newSelectedSt]);
            return newSelectedSt;
          });
        },
      };
    });
  }, [props.items, props.disabled, onChange, selectedSet]);

  React.useEffect(() => {
    updateSelectedSet(new Set());
  }, [props.items]);

  return (
    <Wrapper elevation={1}>
      <FormControl fullWidth error={!!props.errorMessage} variant="standard">
        <Header>
          <Heading variant="body1">{props.fieldName}</Heading>
        </Header>
        <Divider />
        <ListWrapper>
          <StyledList dense>
            {items.map(item => {
              return (
                <StyledListItemButton key={item.id} onClick={item.onClick} disabled={item.disabled}>
                  <ListItemIcon sx={{ minWidth: "unset" }}>
                    <Checkbox checked={item.checked} color="secondary" tabIndex={-1} disableRipple />
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </StyledListItemButton>
              );
            })}
          </StyledList>
        </ListWrapper>
        {props.errorMessage && <FormHelperText>{props.errorMessage}</FormHelperText>}
      </FormControl>
    </Wrapper>
  );
};

MultiSelectBox.displayName = "MultiSelectBox";

export default MultiSelectBox;
