import * as ProjectHelperV3 from "@hireroo/app-helper/project-v3";
import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import { formatScore } from "@hireroo/formatter/score";
import { useLanguageCode } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import Papa from "papaparse";
import * as React from "react";

export type GenerateTestCaseSectionPropsForDataScienceArgs = {
  entityId: number;
  uniqueKey: ProjectTestReport.UniqueKey;
  showScore: boolean;
};

type TestCaseProps = Extract<
  Exclude<Widget.ProjectTestReportProps["testCaseSection"], undefined>["content"],
  {
    kind: "DATA_SCIENCE";
  }
>["testCases"][0];

type CSVRowProps = TestCaseProps["testResult"]["csvRows"][0];

export const useGenerateTestCaseSectionPropsForDataScience = (
  args: GenerateTestCaseSectionPropsForDataScienceArgs,
): Widget.ProjectTestReportProps["testCaseSection"] => {
  const lang = useLanguageCode();
  const hooks = ProjectTestReport.useCreateProjectHooks(args.entityId);
  const submission = hooks.useCurrentSubmission();
  const question = hooks.useQuestion();

  const testCasesResults = React.useMemo(() => {
    if (!submission?.correctnessTestResult) {
      return [];
    }
    return ProjectHelperV3.DataScienceTestCase.parseDataScienceTestCaseResults(submission.correctnessTestResult)?.test_results ?? [];
  }, [submission?.correctnessTestResult]);

  const testCases = React.useMemo(() => {
    if (!question?.correctnessTestCase) {
      return [];
    }
    return ProjectHelperV3.DataScienceTestCase.parseDataScienceTestCases(question.correctnessTestCase)?.test_cases ?? [];
  }, [question?.correctnessTestCase]);

  return {
    scrollTargetElementId: ProjectTestReport.TargetElementIdMap.CORRECT_RATE_SECTION,
    scoreAnswerRateDisplayLabel: {
      numPassed: submission?.numPassed ?? 0,
      numTests: submission?.numTests ?? 0,
    },
    titleWithScoreBar: {
      score: args.showScore ? formatScore(submission?.totalScore ?? 0) : undefined,
    },
    content: {
      kind: "DATA_SCIENCE",
      testCases: testCasesResults.map((testCasesResult, tcIndex): TestCaseProps => {
        const testCase = testCases.at(tcIndex);

        const parsedActualCSV: Papa.ParseResult<Record<string, string>> = Papa.parse(testCasesResult?.actual ?? "", {
          header: true,
          dynamicTyping: true,
          skipEmptyLines: true,
        });

        const parsedExpectedCSV: Papa.ParseResult<Record<string, string>> = Papa.parse(testCasesResult?.expected ?? "", {
          header: true,
          dynamicTyping: true,
          skipEmptyLines: true,
        });

        const unionHeader = Array.from(new Set([...(parsedActualCSV.meta.fields ?? []), ...(parsedExpectedCSV.meta.fields ?? [])]));

        const csvRows = parsedExpectedCSV.data.map((expectedRow, index): CSVRowProps => {
          return {
            actualRow: ProjectHelperV3.DataScienceTestCase.formatCSVRow(parsedActualCSV.data.at(index) ?? {}, unionHeader),
            expectedRow: ProjectHelperV3.DataScienceTestCase.formatCSVRow(expectedRow, unionHeader),
            isCorrect: testCasesResult.matches.at(index) ?? false,
          };
        });

        return {
          status: testCasesResult.is_passed ? "SUCCESS" : "ERROR",
          invisible: testCase?.is_hidden ?? false,
          testResult: {
            header: unionHeader,
            csvRows: csvRows,
          },
          description: testCase ? resolveLanguage(testCase, lang, "description") : undefined,
        };
      }),
    },
  };
};
