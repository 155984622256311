import { AlgorithmSignatureForm } from "@hireroo/validator";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import * as React from "react";

import TestCaseTableSummary from "../../../TestCaseTableSummary/TestCaseTableSummary";

export type DatabaseInputFieldsProps = {
  index: number;
  signature: AlgorithmSignatureForm.DatabaseSignatureSchemaType;
};

const DatabaseInputFields: React.FC<DatabaseInputFieldsProps> = props => {
  return (
    <Stack spacing={4}>
      <Box mb={4}>
        <TestCaseTableSummary signature={props.signature} testCaseIndex={props.index} summaryKind="INPUT" testCaseKind="correctnessTestCases" />
      </Box>

      <TestCaseTableSummary signature={props.signature} testCaseIndex={props.index} summaryKind="OUTPUT" testCaseKind="correctnessTestCases" />
    </Stack>
  );
};

DatabaseInputFields.displayName = "DatabaseInputFields";

export default DatabaseInputFields;
