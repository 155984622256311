import { TAX_RATE_PERCENT } from "@hireroo/app-definition/payment";
import { BuySelections } from "@hireroo/app-store/widget/e/BuySelections";
import { formatPrice } from "@hireroo/formatter/money";
import { unixToDatetimeFormat, yearMonthFormat } from "@hireroo/formatter/time";
import { useLanguageCode, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import addMonths from "date-fns/addMonths";
import differenceInMonths from "date-fns/differenceInMonths";
import * as React from "react";

type PayNextMonthConfirmSectionProps = Widget.BuySelectionFormProps["nextMonthPaymentConfirmSection"];

type ConfirmSectionItemProps = Widget.BuySelectionFormProps["nextMonthPaymentConfirmSection"]["items"][0];

const useCoupons = (args: { subTotal: number }): PayNextMonthConfirmSectionProps["coupons"] => {
  const { subTotal } = args;
  const { t: t2 } = useTranslationWithVariable();
  const lang = useLanguageCode();
  const customer = BuySelections.useCustomer();
  return customer.subscription.subscribedCoupons.reduce<PayNextMonthConfirmSectionProps["coupons"]>((acc, subscribedCoupon) => {
    const { coupon } = subscribedCoupon;
    if (coupon.couponType === "PERCENT_OFF") {
      const billingMonth = addMonths(new Date(), 1);
      /**
       * @example 1
       * - createdAt    = 2023/05/10
       * - billingMonth = 2023/12/15
       * - leftValue    = 12 - 5 = 7 (valid)
       *
       * @example 2
       * - createdAt    = 2023/11/15
       * - billingMonth = 2023/12/15
       * - leftValue    = 12 - 11 = 0 (valid)
       *
       * @example 3
       * - createdAt    = 2023/11/15
       * - billingMonth = 2023/11/15
       * - leftValue    = 11 - 11 = 0 (valid)
       **/
      const leftValue = differenceInMonths(billingMonth, new Date(subscribedCoupon.createdAtSeconds * 1000));
      /**
       * @example 1
       * - expiresAt    = 2023/05/10
       * - billingMonth = 2023/12/15
       * - rightValue   = 5 - 12 = -7 (expired)
       *
       * @example 2
       * - expiresAt    = 2023/12/15
       * - billingMonth = 2023/12/15
       * - rightValue   = 12 - 12 = 0 (valid)
       *
       * @example 3 If the end of the coupon period and the current month are the same, the coupon will not be applied to the next billing
       * - expiresAt    = 2023/11/15
       * - billingMonth = 2023/12/15
       * - rightValue   = 11 - 12 = -1 (expired)
       **/
      const rightValue = differenceInMonths(new Date(subscribedCoupon.expiresAtSeconds * 1000), billingMonth);

      const withinExpiration = 0 <= leftValue && 0 <= rightValue;
      if (!withinExpiration) {
        return acc;
      }

      return acc.concat({
        title: [
          resolveLanguage(coupon, lang, "name"),
          t2("percentDiscountDescription", {
            value: coupon.discountNumber,
            duration: `${unixToDatetimeFormat(subscribedCoupon.createdAtSeconds)} - ${unixToDatetimeFormat(subscribedCoupon.expiresAtSeconds)}`,
          }),
        ].join(" "),
        value: formatPrice(-(subTotal * coupon.discountNumber) / 100),
      });
    }
    return acc;
  }, []);
};

export const usePayMonthConfirmSectionProps = (): PayNextMonthConfirmSectionProps => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const customer = BuySelections.useCustomer();
  const selectionItems = BuySelections.useBuyAbleSelectionItemMap();
  const temporarySelectionItems = BuySelections.useTemporarySelectionItems();

  const willBuyItems = React.useMemo((): Widget.BuySelectionFormProps["nextMonthPaymentConfirmSection"]["items"] => {
    const items = temporarySelectionItems.reduce<ConfirmSectionItemProps[]>((all, temporarySelectionItem) => {
      const interview = selectionItems[temporarySelectionItem.itemId];
      if (!interview || temporarySelectionItem.amount === 0) {
        return all;
      }
      return all.concat({
        title: t2("nSelections", { num: interview.quantity }),
        count: temporarySelectionItem.amount,
        tax: `${TAX_RATE_PERCENT}%`,
        price: { value: formatPrice(temporarySelectionItem.amount * interview.price * interview.quantity) },
      });
    }, []);
    return items;
  }, [selectionItems, temporarySelectionItems, t2]);

  const subTotal = React.useMemo((): number => {
    return temporarySelectionItems.reduce<number>((total, current) => {
      const interview = selectionItems[current.itemId];
      if (!interview) {
        return total;
      }
      return total + current.amount * interview.price * interview.quantity;
    }, 0);
  }, [temporarySelectionItems, selectionItems]);

  const discountValue = React.useMemo((): number => {
    return customer.subscription.subscribedCoupons.reduce<number>((acc, subscribedCoupon) => {
      const { coupon } = subscribedCoupon;
      const discountValue = (subTotal * coupon.discountNumber) / 100;
      return acc + discountValue;
    }, 0);
  }, [customer.subscription.subscribedCoupons, subTotal]);

  const couponsProps = useCoupons({
    subTotal,
  });

  const temporarySubTotalWithCoupon = subTotal - discountValue;
  const subTotalWithCoupon = temporarySubTotalWithCoupon > 0 ? temporarySubTotalWithCoupon : 0;
  const taxPrice = (subTotalWithCoupon * TAX_RATE_PERCENT) / 100;
  const total = subTotalWithCoupon + taxPrice;

  const billingMonth = addMonths(new Date(), 1);

  return {
    title: t2("paymentYearMonth", { yearMonth: yearMonthFormat(billingMonth) }),
    items: willBuyItems,
    coupons: couponsProps,
    taxPrice: {
      title: [t("消費税"), "(", t2("vatRate", { taxRate: TAX_RATE_PERCENT, price: formatPrice(subTotal) }), ")"].join(""),
      value: formatPrice(taxPrice),
    },
    subtotalPrice: {
      value: formatPrice(subTotal),
    },
    totalPriceWithoutTax: {
      value: formatPrice(subTotalWithCoupon),
    },
    billingAmount: {
      value: formatPrice(total),
    },
  };
};
