import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import * as React from "react";

export type LiveCodingLayoutProps = {
  loading?: boolean;
  NotificationBanner: React.ReactNode;
  Header: React.ReactNode;
  Footer: React.ReactNode;
  Snackbar: React.ReactNode;
  Tutorial: React.ReactNode;
};

const HEADER_HEIGHT = 48;
const FOOTER_HEIGHT = 24;
const ADJUST_HEIGHT = 1;

const StyledHeaderWrapper = styled(Box)(() => ({
  overflow: "hidden",
  height: HEADER_HEIGHT,
}));

const LiveCodingLayout: React.FC<React.PropsWithChildren<LiveCodingLayoutProps>> = props => {
  return (
    <Paper elevation={2}>
      {props.loading && <LinearProgress sx={{ width: "100%", position: "fixed", zIndex: 2000, top: 0 }} color="secondary" />}
      <StyledHeaderWrapper>{props.Header}</StyledHeaderWrapper>
      <Box sx={{ height: `calc(100vh - ${HEADER_HEIGHT + FOOTER_HEIGHT + ADJUST_HEIGHT}px)` }} overflow="auto">
        {props.children}
      </Box>
      <Box sx={{ height: FOOTER_HEIGHT }}>{props.Footer}</Box>
      {props.Snackbar}
      {props.Tutorial}
    </Paper>
  );
};

LiveCodingLayout.displayName = "LiveCodingLayout";

export default LiveCodingLayout;
