import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const Wrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: "16px",
  backgroundColor: theme.palette["Background/Paper"].p2,
  justifyContent: "center",
  alignItems: "center",
  alignSelf: "center",
  width: "576px",
}));

const RoleWrapper = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  backgroundColor: theme.palette["Secondary/Shades"].p30,
  borderRadius: "4px",
}));

const MailIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="43" viewBox="0 0 48 43" fill="none">
      <path
        d="M40.3334 19.3337C42.9701 19.3337 45.3734 18.4237 47.3334 16.9537V38.0003C47.3334 40.567 45.2334 42.667 42.6667 42.667H5.33341C2.76675 42.667 0.666748 40.567 0.666748 38.0003V10.0003C0.666748 7.43366 2.76675 5.33366 5.33341 5.33366H28.9001C28.7601 6.08033 28.6667 6.87366 28.6667 7.66699C28.6667 11.1203 30.1834 14.177 32.5634 16.3237L24.0001 21.667L8.36675 11.8903C7.03675 11.0737 5.33341 12.007 5.33341 13.5703C5.33341 14.247 5.68341 14.877 6.26675 15.2503L22.7634 25.5637C23.5101 26.0303 24.4901 26.0303 25.2367 25.5637L36.3667 18.6103C37.6267 19.0537 38.9334 19.3337 40.3334 19.3337ZM33.3334 7.66699C33.3334 11.5403 36.4601 14.667 40.3334 14.667C44.2067 14.667 47.3334 11.5403 47.3334 7.66699C47.3334 3.79366 44.2067 0.666992 40.3334 0.666992C36.4601 0.666992 33.3334 3.79366 33.3334 7.66699Z"
        fill="url(#paint0_linear_10150_18893)"
      />
      <defs>
        <linearGradient id="paint0_linear_10150_18893" x1="24.0001" y1="0.666992" x2="24.0001" y2="42.667" gradientUnits="userSpaceOnUse">
          <stop stopColor="#717CF9" />
          <stop offset="1" stopColor="#FF8ADE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export type InvitationDialogProps = {
  title: string;
  role?: string;
  confirmButton: Pick<ButtonProps, "onClick" | "disabled">;
  rejectButton: Pick<ButtonProps, "onClick" | "disabled">;
};

const InvitationDialog: React.FC<InvitationDialogProps> = props => {
  const { t } = useTranslation();
  const confirmButtonProps: ButtonProps = {
    ...props.confirmButton,
    variant: "contained",
    sx: {
      width: "320px",
    },
    children: t("承諾する"),
  };
  const rejectButtonProps: ButtonProps = {
    ...props.rejectButton,
    variant: "text",
    color: "outline-only",
    sx: {
      width: "320px",
    },
    children: t("拒否する"),
  };
  return (
    <Wrapper spacing={4} direction="column" alignItems="center">
      <MailIcon />
      <Stack spacing={3} direction="column" alignItems="center" justifyContent="center">
        <Stack direction="column" spacing={2} alignItems="center">
          <Typography align="center" fontSize={20}>
            {props.title}
          </Typography>
          {props.role && (
            <RoleWrapper>
              <Typography fontSize={14}>{props.role}</Typography>
            </RoleWrapper>
          )}
          <Typography fontSize={14}>{t("承諾することで会社アカウントへのアクセスが可能となります。")}</Typography>
        </Stack>
        <Stack direction="column" spacing={2}>
          <Button {...confirmButtonProps} />
          <Button {...rejectButtonProps} />
        </Stack>
      </Stack>
    </Wrapper>
  );
};

InvitationDialog.displayName = "InvitationDialog";

export default InvitationDialog;
