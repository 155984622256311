import { useSnapshot } from "valtio";

import { state } from "./State";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useActiveStep = () => {
  const snapshot = useSnapshotState();
  return snapshot.activeStep;
};

export const useVariant = () => {
  const snapshot = useSnapshotState();
  return snapshot.variant;
};

export const useQuestion = () => {
  const snapshot = useSnapshotState();
  return snapshot.question;
};

export const useInitialLanguage = () => {
  const snapshot = useSnapshotState();
  return snapshot.initialLanguage;
};

export const useSaveStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.saveStatus;
};

export const useTemplateCode = () => {
  const snapshot = useSnapshotState();
  return snapshot.templateCode;
};
