import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { SecuritySettingsForm } from "@hireroo/validator";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import CopyTextField from "../../../../modules/CopyTextField/CopyTextField";
import ResourceField from "../../../../modules/ResourceField/ResourceField";
import InputControlTextField, {
  InputControlTextFieldProps,
} from "../../../../primitive/InputControl/InputControlTextField/InputControlTextField";
import SwitchControl, { SwitchControlProps } from "../../../../primitive/InputControl/SwitchControl/SwitchControl";

const StyledBox = styled(Box)(({ theme }) => ({
  borderTop: "1px solid",
  borderBottom: "1px solid",
  borderColor: theme.palette.divider,
}));

const TitleTypography = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 14,
}));

export type SsoEnabledFieldsProps = {
  spEndpoint: string;
  appUrl: string;
  onCopy: () => void;
};

const SsoEnabledFields: React.FC<SsoEnabledFieldsProps> = props => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const methods = useFormContext<SecuritySettingsForm.SecuritySettingsFormSchema>();

  const commonField: InputControlTextFieldProps = {
    required: true,
    fullWidth: false,
    size: "small",
    control: methods.control,
    InputLabelProps: {
      shrink: true,
    },
    sx: {
      width: "70%",
    },
  };

  const idpSsoUrlTextField: InputControlTextFieldProps = {
    ...commonField,
    placeholder: "https://your-company.okta.com/app/hireroo/abcdefg/sso/saml",
  };

  const idpEntityIdTextField: InputControlTextFieldProps = {
    ...commonField,
    placeholder: "http://www.okta.com/abcdefg",
  };

  const idpCertificateTextField: InputControlTextFieldProps = {
    ...commonField,
    multiline: true,
    rows: 4,
    placeholder: "-----BEGIN CERTIFICATE-----\n.....\n-----END CERTIFICATE-----",
    helperText: t("先頭は「-----BEGIN CERTIFICATE-----」、末尾は「-----END CERTIFICATE-----」としてください。"),
  };

  const emailDomainTextField: InputControlTextFieldProps = {
    ...commonField,
    placeholder: "example1.com",
    helperText: t("入力されたメールアドレスが対象ドメインの場合のみSAMLでの認証を求められます。"),
  };

  const ssoStrictModeSwitch: SwitchControlProps = {
    control: methods.control,
    color: "secondary",
    size: "small",
  };

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} mt={3}>
        <TitleTypography mb={1.5}>{t("IdP（Identity Provider）への登録情報")}</TitleTypography>
        <Stack divider={<Divider />} sx={{ borderTop: "1px solid", borderBottom: "1px solid", borderColor: theme.palette.divider }}>
          <Stack direction="row" justifyContent="space-between" p={1.5} alignItems="center">
            <Typography fontSize={14}>{t("SPのエンドポイントURL")}</Typography>
            <CopyTextField text={props.spEndpoint} onCopy={props.onCopy} />
          </Stack>
          <Stack direction="row" justifyContent="space-between" p={1.5} alignItems="center">
            <Typography fontSize={14}>{t("SPエンティティID")}</Typography>
            <CopyTextField text={props.appUrl} onCopy={props.onCopy} />
          </Stack>
        </Stack>
      </Box>
      <Box display="flex" flexDirection="column" mt={3}>
        <TitleTypography>{t("SP（Service Provider）への登録情報")}</TitleTypography>
        <Stack direction="column" spacing={1.5}>
          <ResourceField label={t("IdPのエンドポイントURL（SSO URL）")} kind="REQUIRED" pt={1.5} formLabel={{ sx: { fontWeight: 400 } }}>
            <InputControlTextField name="idpSsoUrl" {...idpSsoUrlTextField} />
          </ResourceField>
          <ResourceField label={t("IdPのエンティティID（issuer）")} kind="REQUIRED" pt={1.5} formLabel={{ sx: { fontWeight: 400 } }}>
            <InputControlTextField name="idpEntityId" {...idpEntityIdTextField} />
          </ResourceField>
          <ResourceField
            label={t2("idpCertificate", { certificate: "X.509" })}
            kind="REQUIRED"
            pt={1.5}
            formLabel={{ sx: { fontWeight: 400 } }}
          >
            <InputControlTextField name="idpCertificate" {...idpCertificateTextField} />
          </ResourceField>
        </Stack>
      </Box>
      <Stack direction="column" spacing={1.5}>
        <TitleTypography>{t("詳細設定")}</TitleTypography>
        <ResourceField label={t("対象ドメイン")} kind="REQUIRED" formLabel={{ sx: { fontWeight: 400 } }}>
          <InputControlTextField name="emailDomain" {...emailDomainTextField} />
        </ResourceField>

        <StyledBox display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" p={1.5}>
          <Box display="flex" flexDirection="column">
            <Typography fontSize={14} mb={1}>
              {t("強制モードの有効化")}
            </Typography>
            <Typography color="textSecondary" fontSize={12}>
              {t("ユーザーがSAML認証以外で会社アカウントへアクセスできないように制限をかけます。管理者権限のユーザーには制限がかかりません。")}
            </Typography>
          </Box>
          <Box>
            <SwitchControl name="ssoStrictMode" {...ssoStrictModeSwitch} />
          </Box>
        </StyledBox>
      </Stack>
    </>
  );
};

SsoEnabledFields.displayName = "SsoEnabledFields";

export default SsoEnabledFields;
