import { useSystemDesignAccessEvents } from "@hireroo/app-helper/hooks";
import { SystemDesignTestReport } from "@hireroo/app-store/view-domain/SystemDesignTestReport";
import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import * as React from "react";

type SystemDesignCheatDetectionProps = Exclude<Widget.SystemDesignTestReportProps["cheatDetectionSection"], undefined>;
type IpAddressSection = SystemDesignCheatDetectionProps["ipAddressSection"];
type CustomStepLabelProps = Exclude<SystemDesignCheatDetectionProps["ipAddressSection"], null>["firstAccessInfo"];

export type GenerateIpAddressSectionArgs = {
  systemDesignId: number;
};

export const useGenerateIpAddressSectionProps = (args: GenerateIpAddressSectionArgs): IpAddressSection => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();

  const hooks = SystemDesignTestReport.useCreateSystemDesignHooks(args.systemDesignId);
  const questionId = hooks.useQuestionId();

  const { accessEvents, ready: accessEventsReady } = useSystemDesignAccessEvents({
    systemDesignId: args.systemDesignId,
    questionId: questionId ?? 0,
  });

  const ipAddressSection = React.useMemo((): IpAddressSection => {
    if (!accessEventsReady) {
      return null;
    }
    /** firebase realtime db event keys are sortable with .sort */
    const keys = Object.keys(accessEvents).slice().sort();
    if (keys.length === 0) {
      /**
       * If IP address is not detected, show this message.
       * TODO: Update this message when the issue with browser extension is fixed
       */
      return {
        firstAccessInfo: {
          mark: "WARNING",
          label: t(
            "IPアドレスが検知されませんでした。受験者が使っているブラウザの拡張機能によって位置情報を取得するために必要なリクエストがブロックされた可能性があります。",
          ),
        },
        extraAccessInfos: [],
      };
    }
    const [firstKey, ...remainKeys] = keys;
    const firstIp = accessEvents[firstKey];
    const d = new Date(firstIp.t);
    const date = d.toLocaleString();
    const firstAccessInfo: CustomStepLabelProps = {
      mark: "COMPUTER",
      label: t2("IpAddressDetectionLabel1", { ipAddress: firstIp.l, geolocation: firstIp.g }),
      date: date,
    };
    const extraAccessInfos = remainKeys.map((key): CustomStepLabelProps => {
      const extraAccessEvent = accessEvents[key];
      const d = new Date(extraAccessEvent.t);
      const date = d.toLocaleString();
      return {
        mark: "WARNING",
        label: t2("IpAddressDetectionLabel2", { ipAddress: extraAccessEvent.l, geolocation: extraAccessEvent.g }),
        date: date,
      };
    });
    return {
      firstAccessInfo,
      extraAccessInfos,
    };
  }, [accessEvents, accessEventsReady, t, t2]);

  return ipAddressSection;
};
