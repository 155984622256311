import { ENTITY_REPORT_TOP, extractUniqueEntity } from "@hireroo/app-helper/entity";
import { scrollToContentForReportV2 } from "@hireroo/app-helper/html-element";
import { SystemDesignTestReport } from "@hireroo/app-store/view-domain/SystemDesignTestReport";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import SystemDesignTestReportContainer, { SystemDesignTestReportContainerProps } from "./Container";

export type SystemDesignTestReportFetchContainerProps = {
  featureKind: "test" | "exam";
  companyId: number;
  systemDesignId: number;
  canShowCheatDetectionSection: boolean;
  showUsedHintSection: boolean;
};

const SystemDesignTestReportFetchContainer: React.FC<SystemDesignTestReportFetchContainerProps> = props => {
  const { companyId, systemDesignId } = props;
  const initialized = SystemDesignTestReport.useInitialized(props.systemDesignId);
  const [result] = Graphql.useGetSystemDesignEntityForSystemDesignTestReportQuery({
    variables: {
      systemDesignId: systemDesignId,
      withAnswers: true,
    },
    requestPolicy: "cache-and-network",
  });

  React.useEffect(() => {
    if (result.data?.systemDesignEntity) {
      SystemDesignTestReport.initialize(result.data.systemDesignEntity);
      const uniqueEntity = extractUniqueEntity(window.location.hash);
      if (uniqueEntity && uniqueEntity.type === "systemDesign" && uniqueEntity.id === result.data.systemDesignEntity.entityId) {
        scrollToContentForReportV2(ENTITY_REPORT_TOP, { delayTimeMilliSeconds: 1 });
      }
    }
  }, [result.data?.systemDesignEntity]);

  if (result.fetching || !initialized) {
    return <Widget.Loading kind="TOP" />;
  }

  const containerProps: SystemDesignTestReportContainerProps = {
    featureKind: props.featureKind,
    entityId: props.systemDesignId,
    canShowCheatDetectionSection: props.canShowCheatDetectionSection,
    companyId,
    showUsedHintSection: props.showUsedHintSection,
  };
  return (
    <ErrorBoundary>
      <SystemDesignTestReportContainer {...containerProps} />
    </ErrorBoundary>
  );
};

SystemDesignTestReportFetchContainer.displayName = "SystemDesignTestReportFetchContainer";

export default withErrorBoundary(SystemDesignTestReportFetchContainer, {});
