import AppBar from "@mui/material/AppBar";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";

import BrandLogo from "../../modules/BrandLogo/BrandLogo";
import HelpMenu, { HelpMenuProps } from "../../modules/HelpMenu/HelpMenu";
import LanguageDropDownMenu from "../../modules/LanguageDropDownMenu/LanguageDropDownMenu";
import PreferencesMenu, { PreferencesMenuProps } from "../../modules/PreferencesMenu/PreferencesMenu";
import SwitchInterfaceField, { SwitchInterfaceFieldProps } from "./parts/SwitchInterfaceField/SwitchInterfaceField";

const HEIGHT = 48;

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  width: "100%",
  height: HEIGHT,
  border: "none",
  borderBottom: `solid 1px ${theme.palette["Secondary/Shades"].p16}`,
  boxShadow: "none",
  flexGrow: 1,
  flexShrink: 0,
}));

const StyledStack = styled(Stack)(() => ({
  flexGrow: 1,
  flexShrink: 0,
  height: HEIGHT,
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  overflow: "hidden",
}));

export type CandidateNavigationProps = {
  item:
    | { type: "MANY"; content: PreferencesMenuProps }
    | { type: "LANGUAGE_ONLY" }
    | {
        type: "BOTH";
        switchInterfaceField: SwitchInterfaceFieldProps;
      };
  helpMenu?: HelpMenuProps;
};

const CandidateNavigation: React.FC<CandidateNavigationProps> = props => {
  return (
    <StyledAppBar>
      <StyledStack direction="row" px={1.5} py={0.5}>
        <BrandLogo />
        <Stack direction="row" alignItems="center" spacing={1.5} height={36}>
          {props.item.type === "LANGUAGE_ONLY" && <LanguageDropDownMenu />}
          {props.item.type === "MANY" && <PreferencesMenu {...props.item.content} />}

          {props.item.type === "BOTH" && (
            <>
              <SwitchInterfaceField {...props.item.switchInterfaceField} />
              <LanguageDropDownMenu />
            </>
          )}
          {props.helpMenu && <HelpMenu {...props.helpMenu} />}
        </Stack>
      </StyledStack>
    </StyledAppBar>
  );
};

CandidateNavigation.displayName = "CandidateNavigation";

export default CandidateNavigation;
