import * as React from "react";
import { RectangleProps } from "recharts";

import { BoxChartColorTheme, BoxChartColorThemeMap } from "../../../../definition";

type VerticalBarProps = RectangleProps & {
  theme?: BoxChartColorTheme;
};

const VerticalBar: React.FC<VerticalBarProps> = props => {
  const { x, y, width, height, theme } = props;
  const colorMap = theme ? BoxChartColorThemeMap[theme] : BoxChartColorThemeMap.lightBlue;

  if (x === undefined || y === undefined || width === undefined || height === undefined) {
    return null;
  }

  return <line x1={x + width / 2} y1={y + height} x2={x + width / 2} y2={y} stroke={colorMap.light} strokeWidth={2} />;
};

VerticalBar.displayName = "VerticalBar";

export default VerticalBar;
