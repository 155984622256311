import { App, Company } from "@hireroo/app-store/essential/employee";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import DashboardRemoteInterviewSectionContainer, { DashboardRemoteInterviewSectionContainerProps } from "./Container";

export type DashboardRemoteInterviewSectionFetchContainerProps = {};

const DashboardRemoteInterviewSectionFetchContainer: React.FC<DashboardRemoteInterviewSectionFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const companyId = Company.useStrictActiveCompanyId();
  const [result, refresh] = Graphql.useListRemotesForDashboardRemoteInterviewSectionQuery({
    variables: {
      companyId: companyId,
      size: 4,
    },
    requestPolicy: "cache-and-network",
    pause: appStatus !== "INITIALIZED",
  });

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED") {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: DashboardRemoteInterviewSectionContainerProps = {
    remotes: result.data?.remotesForSchedule || [],
  };

  return <DashboardRemoteInterviewSectionContainer {...containerProps} />;
};

DashboardRemoteInterviewSectionFetchContainer.displayName = "DashboardRemoteInterviewSectionFetchContainer";

export default withErrorBoundary(DashboardRemoteInterviewSectionFetchContainer, {});
