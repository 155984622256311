import { useTranslation } from "@hireroo/i18n";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DeleteMetricsDialog, { DeleteMetricsDialogProps } from "../../ms-components/Evaluation/DeleteMetricsDialog/DeleteMetricsDialog";
import AddMetricsToGroups, { AddMetricsToGroupsProps } from "./parts/AddMetricsToGroups/AddMetricsToGroups";
import MetricsEditorDialog, { MetricsEditorDialogProps } from "./parts/MetricsEditorDialog/MetricsEditorDialog";
import MetricsHeader, { MetricsHeaderProps } from "./parts/MetricsHeader/MetricsHeader";
import MetricsTable, { MetricsTableProps } from "./parts/MetricsTable/MetricsTable";

export type EvaluationMetricsProps = {
  header: MetricsHeaderProps;
  table: MetricsTableProps;
  editorDialog: MetricsEditorDialogProps;
  deletesDialog: DeleteMetricsDialogProps;
  addDialog: AddMetricsToGroupsProps;
};

const EvaluationMetrics: React.FC<EvaluationMetricsProps> = props => {
  const { t } = useTranslation();
  return (
    <Stack direction="column">
      <Typography fontSize={24} fontWeight={700} mb={3} sx={{ wordBreak: "keep-all" }}>
        {t("評価項目一覧")}
      </Typography>
      <MetricsHeader {...props.header} />
      <MetricsTable {...props.table} />
      <MetricsEditorDialog key={`create-${props.editorDialog.dialog.open}`} {...props.editorDialog} />
      <DeleteMetricsDialog key={`deletes-${props.deletesDialog.dialog.open}`} {...props.deletesDialog} />
      <AddMetricsToGroups key={`add-${props.addDialog.dialog.open}`} {...props.addDialog} />
    </Stack>
  );
};

EvaluationMetrics.displayName = "EvaluationMetrics";

export default EvaluationMetrics;
