import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { TermOfService } from "@hireroo/app-helper/payment";
import { Def } from "@hireroo/app-helper/payment";
import { PlanUpdateForm } from "@hireroo/app-store/widget/e/PlanUpdateForm";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/browser";
import * as React from "react";

import { usePayInTwoMonthConfirmSectionProps, usePayNextMonthConfirmSectionProps } from "../privateHelper";

export type GeneratePaymentConfirmationDetailsPropsArgs = {
  onPlanChanged: () => void;
};

export const useGenerateProps = (args: GeneratePaymentConfirmationDetailsPropsArgs): Widget.PaymentConfirmationDetailsProps => {
  const { t } = useTranslation();
  const customer = PlanUpdateForm.useCustomer();
  const urlItem = TermOfService.useUrlItem(customer.subscription.plan.generation);
  const client = getGraphqlClient();
  const plans = PlanUpdateForm.usePlans();
  const submitValue = PlanUpdateForm.useSubmitValue();
  const [loading, setLoading] = React.useState(false);
  const payNextMonthConfirmSectionProps = usePayNextMonthConfirmSectionProps();
  const payInTwoMonthConfirmSectionProps = usePayInTwoMonthConfirmSectionProps();

  return {
    payNextMonthConfirmSection: payNextMonthConfirmSectionProps,
    payInTwoMonthConfirmSection: payInTwoMonthConfirmSectionProps,
    tosIframeUrl: urlItem?.iframeUrl,
    downloadUrl: urlItem?.downloadUrl,
    submitButton: {
      loading,
      onClick: () => {
        const targetPlan = plans.find(plan => plan.planType === Def.planTypeMapValidatorToGraphql[submitValue.plan]);
        if (!targetPlan) {
          Snackbar.notify({
            severity: "warning",
            message: "変更先のプランが見つかりませんでした。お問い合わせください。",
          });
          return;
        }
        setLoading(true);
        client
          .ChangePlanForPlanUpdateForm({
            input: {
              customerId: customer.subscription.customerId,
              subscriptionId: customer.subscription.subscriptionId,
              planId: targetPlan.planId,
            },
          })
          .then(() => {
            Snackbar.notify({
              severity: "success",
              message: t("プラン変更に成功しました。"),
            });
            args.onPlanChanged();
          })
          .catch(error => {
            Sentry.captureException(error);
            const errorNotification = ErrorHandlingHelper.generateErrorNotification(
              error,
              t("プラン変更に失敗しました。すでにプランが変更されていないかお確かめの上お問い合わせください。"),
            );
            Snackbar.notify({
              severity: "error",
              message: errorNotification.message,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      },
    },
  };
};
