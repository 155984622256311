import { useTranslation } from "@hireroo/i18n";
import { ScreeningResourceEditorForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import ResourceField from "../../../../modules/ResourceFieldV2/ResourceFieldV2";
import ToggleResourceField, { ToggleResourceFieldProps } from "../../../../modules/ToggleResourceField/ToggleResourceField";
import InputControlTextField, {
  InputControlTextFieldProps,
} from "../../../../primitive/InputControl/InputControlTextField/InputControlTextField";
import SelectField, { SelectFieldProps } from "../../../../primitive/InputControl/SelectField/SelectField";
import { type LayoutController, useScreeningResourceEditorContext } from "../../Context";

type FieldName = keyof ScreeningResourceEditorForm.TestInviteSetupFormSchema;

const ReservedFieldName = {
  MESSAGE_FOR_CANDIDATE: "messageForCandidate",
  CANDIDATE_ACCESS_POLICY: "candidateAccessPolicy",
  INVITATION_LANGUAGE: "invitationLanguage",
  OWNER_EMAIL_ADDRESS: "ownerEmailAddress",
} satisfies Record<string, FieldName>;

const Subsection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  boxShadow: "none",
}));

const StyledSelectField = styled(SelectField)(({ theme }) => ({
  color: theme.palette.text.secondary,
  width: "fit-content",
  backgroundColor: theme.palette.Other.FilledInputBG,
  ".MuiSelect-outlined": {
    border: "none",
  },
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
    borderRadius: 8,
  },
}));

export type TestInviteSetupSectionProps = {
  onSubmit: SubmitHandler<ScreeningResourceEditorForm.TestInviteSetupFormSchema>;
  defaultValues: ScreeningResourceEditorForm.TestInviteSetupFormSchema;
  emptyDefaultOwnerEmail: string;
};

const TestInviteSetupSection: React.FC<TestInviteSetupSectionProps> = props => {
  const { t } = useTranslation();
  const { onSubmit } = props;
  const validateCandidateAccessPolicySchema = ScreeningResourceEditorForm.useCandidateAccessPolicy();
  const validateInvitationLanguageSchema = ScreeningResourceEditorForm.useInvitationLanguage();

  const { stepName, setController, submitValues, setSubmitValue } = useScreeningResourceEditorContext();
  const validateSchema = ScreeningResourceEditorForm.useTestInviteSetupForm();
  const methods = useForm<ScreeningResourceEditorForm.TestInviteSetupFormSchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: submitValues.TEST_INVITE_SETUP ?? props.defaultValues,
  });

  const controller = React.useMemo((): LayoutController => {
    return {
      checkGoNextStep: () => {
        return new Promise<boolean>(resolve => {
          methods.handleSubmit(
            fields => {
              onSubmit(fields);
              setSubmitValue("TEST_INVITE_SETUP", fields);
              resolve(true);
            },
            errors => {
              console.warn(errors);
              setSubmitValue("TEST_INVITE_SETUP", undefined);
              resolve(false);
            },
          )();
        });
      },
    };
  }, [methods, onSubmit, setSubmitValue]);

  React.useEffect(() => {
    setController("TEST_INVITE_SETUP", controller);
  }, [setController, controller]);

  const messageForCandidateFieldProps: InputControlTextFieldProps = {
    fullWidth: true,
    minRows: 3,
    maxRows: 10,
    multiline: true,
    placeholder: t("実装中はソースコードの意図が分かるように、コメントをできるだけ書いてください。"),
  };

  const accessModeFieldProps: SelectFieldProps = {
    color: "secondary",
    variant: "outlined",
    items: [
      {
        text: t("リンクを知っている人が受験可能"),
        value: "ALLOW_ALL",
      },
      {
        text: t("パスワード付きリンクを知っている人が受験可能"),
        value: "RESTRICTED_BY_INVITATION_CODE",
      },
    ],
    onChange: event => {
      const result = validateCandidateAccessPolicySchema.safeParse(event.target.value);
      if (result.success) {
        methods.setValue(ReservedFieldName.CANDIDATE_ACCESS_POLICY, result.data);
      }
    },
  };
  const invitationLanguageFieldProps: SelectFieldProps = {
    color: "secondary",
    variant: "outlined",
    items: [
      {
        text: "日本語",
        value: "ja",
      },
      {
        text: "English",
        value: "en",
      },
    ],
    onChange: event => {
      const result = validateInvitationLanguageSchema.safeParse(event.target.value);
      if (result.success) {
        methods.setValue(ReservedFieldName.INVITATION_LANGUAGE, result.data);
      }
    },
  };

  const ownerEmailAddressFieldProps: InputControlTextFieldProps = {
    color: "secondary",
    variant: "outlined",
    sx: {
      minWidth: "362px",
    },
    placeholder: "abc@example.com",
  };

  const ownerEmailAddressOptionalResourceField: ToggleResourceFieldProps = {
    toggleKind: "SWITCH",
    label: t("候補者に共有する連絡先"),
    defaultOpen: !!methods.getValues("ownerEmailAddress"),
    onDiscard: () => {
      methods.setValue("ownerEmailAddress", null);
    },
    onOpen: () => {
      const ownerEmailAddress = methods.getValues("ownerEmailAddress");
      if (!ownerEmailAddress) {
        methods.setValue("ownerEmailAddress", props.emptyDefaultOwnerEmail);
      }
    },
  };

  if (stepName !== "TEST_INVITE_SETUP") {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <Stack py={2} spacing={1.5}>
        <Subsection>
          <ResourceField
            label={t("候補者へのメッセージ")}
            kind="NONE"
            pb={2}
            help={{
              kind: "DESCRIPTION",
              text: t("テストの受験者に対し伝達したい事項がある場合、メッセージを残すことができます。"),
            }}
          >
            <InputControlTextField name={ReservedFieldName.MESSAGE_FOR_CANDIDATE} {...messageForCandidateFieldProps} />
          </ResourceField>
        </Subsection>

        <Subsection>
          <ResourceField label={t("受験可能なユーザーの制限")} kind="NONE" pb={4}>
            <StyledSelectField name={ReservedFieldName.CANDIDATE_ACCESS_POLICY} {...accessModeFieldProps} />
          </ResourceField>

          <ResourceField
            label={t("招待方法")}
            kind="NONE"
            help={{ kind: "DESCRIPTION", text: t("コーディングテスト作成後に招待することが可能となります。") }}
          >
            <Stack direction="row">
              <Box sx={{ width: "100%" }}>
                <Typography variant="body2" mb={0.5}>
                  {t("メール本文の言語")}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {t("受験者に送信する招待メール本文の言語を指定できます。")}
                </Typography>
              </Box>
              <Box sx={{ minWidth: 100 }}>
                <StyledSelectField name={ReservedFieldName.INVITATION_LANGUAGE} {...invitationLanguageFieldProps} />
              </Box>
            </Stack>
          </ResourceField>
        </Subsection>
        <Subsection>
          <ResourceField
            label={t("候補者に共有する連絡先")}
            kind="NONE"
            help={{
              kind: "DESCRIPTION",
              text: t("候補者から質問や問題があった際にやり取りができる連絡先を入力してください。候補者に送られるメールに記載されます。"),
            }}
          >
            <ToggleResourceField {...ownerEmailAddressOptionalResourceField}>
              <InputControlTextField name={ReservedFieldName.OWNER_EMAIL_ADDRESS} {...ownerEmailAddressFieldProps} />
            </ToggleResourceField>
          </ResourceField>
        </Subsection>
      </Stack>
    </FormProvider>
  );
};

TestInviteSetupSection.displayName = "TestInviteSetupSection";

export default TestInviteSetupSection;
