import Markdown from "@hireroo/markdown-v2/react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Box from "@mui/material/Box";
import FormHelperText, { FormHelperTextProps } from "@mui/material/FormHelperText";
import InputBase, { InputBaseProps } from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup, { ToggleButtonGroupProps } from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import * as React from "react";

type ValidateOption = {
  maxLength: number;
  messageForReachingMaxLength: string;
};

/**
 * Specify the same font family as monaco-editor
 */
const FontFamily = `Menlo, Monaco, "Courier New", monospace`;

type ViewMode = "WRITE" | "PREVIEW";

const viewModeMap = {
  WRITE: "WRITE",
  PREVIEW: "PREVIEW",
} satisfies Record<string, ViewMode>;

export type FlexibleMarkdownPreviewEditorProps = Pick<
  InputBaseProps,
  "required" | "placeholder" | "size" | "rows" | "minRows" | "multiline" | "onChange"
> & { disableToggleButton?: boolean; defaultValue?: string; validateOption?: ValidateOption; defaultViewMode?: ViewMode };

const StyledHelperText = styled(FormHelperText)(({ theme }) => ({
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  paddingBottom: theme.spacing(0.5),
  textAlign: "right",
  color: theme.palette.warning.main,
}));

const StyledTextField = styled(InputBase)(({ theme }) => ({
  height: "100%",
  "&.MuiTextField-root": {
    paddingBottom: 0,
  },
  "& .MuiFormControl-root": {
    height: "100%",
    padding: 0,
  },
  "& .MuiInputBase-root": {
    height: "100%",
    paddingRight: "0 !important",
  },
  "& textarea": {
    fontFamily: FontFamily,
    overflowY: "auto !important",
    height: "100% !important",
    resize: "none !important",
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  border: "none",
}));

const LabelWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette["Secondary/Shades"].p12,
  borderStartStartRadius: theme.shape.borderRadius,
  borderStartEndRadius: theme.shape.borderRadius,
  padding: "4px 4px",
  height: "16px",
}));

const StyledPreviewArea = styled(Box)(({ theme }) => ({
  boxSizing: "border-box",
  width: "100%",
  height: "100%",
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  // it's for placeholder
  minHeight: "55px",
  overflowY: "auto",
  wordBreak: "break-word",
  "& *:first-of-type": {
    marginTop: "6px",
  },
}));

const Wrapper = styled(Box)(() => ({
  display: "flex",
  flex: 1,
  scrollbarWidth: "none",
  /* Safari and Chrome */
  "&::-webkit-scrollbar": {
    display: "none",
  },
}));

const FlexibleMarkdownPreviewEditor: React.FC<FlexibleMarkdownPreviewEditorProps> = props => {
  const [value, setValue] = React.useState(props.defaultValue ?? "");
  const { validateOption, defaultViewMode, disableToggleButton, ...rest } = props;
  const [viewMode, setViewMode] = React.useState(defaultViewMode || viewModeMap.WRITE);
  const detailFieldProps: InputBaseProps = {
    ...rest,
    fullWidth: true,
    multiline: true,
    color: "secondary",
    inputProps: {
      maxLength: validateOption?.maxLength,
    },
    onChange: event => {
      props.onChange?.(event);
      setValue(event.target.value);
    },
  };
  const canShowMaxLengthMessage: boolean = !!validateOption && value.length >= validateOption.maxLength;
  const helperText: FormHelperTextProps = {
    children: validateOption?.messageForReachingMaxLength,
  };
  const toggleButtonGroupProps: ToggleButtonGroupProps = {
    color: "primary",
    size: "small",
    value: viewMode,
    exclusive: true,
    onChange: (_e, newValue) => {
      if (Object.values(viewModeMap).includes(newValue)) {
        setViewMode(newValue);
      }
    },
    disabled: disableToggleButton,
  };
  const Preview = React.useMemo(() => {
    return (
      <React.Suspense>
        <Markdown children={value} size="middle" />
      </React.Suspense>
    );
  }, [value]);

  return (
    <Wrapper display="flex" flexDirection="column" overflow="hidden" height="100%">
      <Box overflow={viewMode === viewModeMap.PREVIEW ? "hidden" : undefined} height="100%">
        {viewMode === viewModeMap.WRITE && <StyledTextField {...detailFieldProps} />}
        {viewMode === viewModeMap.PREVIEW && <StyledPreviewArea>{Preview}</StyledPreviewArea>}
      </Box>
      {canShowMaxLengthMessage && <StyledHelperText {...helperText} />}
      <Box display="flex" justifyContent="space-between" alignItems="flex-end">
        <LabelWrapper alignItems="center" display="flex">
          <Typography variant="caption" color="textSecondary" lineHeight={1} fontSize="10px">
            markdown
          </Typography>
        </LabelWrapper>
        <ToggleButtonGroup {...toggleButtonGroupProps}>
          <ToggleButton value={viewModeMap.WRITE} color="secondary" size="small" sx={{ height: "30px", width: "30px" }}>
            <EditOutlinedIcon fontSize="small" />
          </ToggleButton>
          <ToggleButton
            value={viewModeMap.PREVIEW}
            color="secondary"
            size="small"
            sx={{ height: "30px", width: "30px" }}
            disabled={value === undefined || value === ""}
          >
            <VisibilityOutlinedIcon fontSize="small" />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Wrapper>
  );
};

FlexibleMarkdownPreviewEditor.displayName = "FlexibleMarkdownPreviewEditor";

export default FlexibleMarkdownPreviewEditor;
