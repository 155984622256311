import { TAX_RATE_PERCENT } from "@hireroo/app-definition/payment";
import { PaymentContractCreateForm } from "@hireroo/app-store/widget/e/PaymentContractCreateForm";
import { formatPrice } from "@hireroo/formatter/money";
import { unixToDatetimeFormat } from "@hireroo/formatter/time";
import { dateWithYearFormat, yearMonthFormat } from "@hireroo/formatter/time";
import { useLanguageCode, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import addMonths from "date-fns/addMonths";
import differenceInMonths from "date-fns/differenceInMonths";
import * as React from "react";

type PayNextMonthConfirmSectionProps = Widget.PaymentContractCreateFormProps["nextMonthPaymentConfirmSection"];

type ConfirmSectionItemProps = Widget.PaymentContractCreateFormProps["nextMonthPaymentConfirmSection"]["items"][0];

const useCoupons = (args: { subTotal: number }): PayNextMonthConfirmSectionProps["coupons"] => {
  const { subTotal } = args;
  const { t: t2 } = useTranslationWithVariable();
  const lang = useLanguageCode();
  const appliedCoupons = PaymentContractCreateForm.useAppliedCoupons();
  const customer = PaymentContractCreateForm.useCustomer();

  const initialCoupons: PayNextMonthConfirmSectionProps["coupons"] = [];
  appliedCoupons.forEach(appliedCoupon => {
    const now = new Date();
    const startDate = dateWithYearFormat(now);
    const expireDate = dateWithYearFormat(addMonths(now, appliedCoupon.durationMonth));
    if (appliedCoupon.couponType === "AMOUNT_OFF") {
      initialCoupons.push({
        title: [
          resolveLanguage(appliedCoupon, lang, "name"),
          t2("amountDiscountDescription", {
            value: formatPrice(appliedCoupon.discountNumber),
            duration: `${startDate} - ${expireDate}`,
          }),
        ].join(" "),
        value: formatPrice(-appliedCoupon.discountNumber),
      });
    } else if (appliedCoupon.couponType === "PERCENT_OFF") {
      initialCoupons.push({
        title: [
          resolveLanguage(appliedCoupon, lang, "name"),
          t2("percentDiscountDescription", {
            value: appliedCoupon.discountNumber,
            duration: `${startDate} - ${expireDate}`,
          }),
        ].join(" "),
        value: formatPrice(-(subTotal * appliedCoupon.discountNumber) / 100),
      });
    }
  });

  return customer.subscription.subscribedCoupons.reduce<PayNextMonthConfirmSectionProps["coupons"]>((acc, subscribedCoupon) => {
    const { coupon } = subscribedCoupon;
    if (coupon.couponType === "PERCENT_OFF") {
      const billingMonth = addMonths(new Date(), 1);
      /**
       * @example 1
       * - createdAt    = 2023/05/10
       * - billingMonth = 2023/12/15
       * - leftValue    = 12 - 5 = 7 (valid)
       *
       * @example 2
       * - createdAt    = 2023/11/15
       * - billingMonth = 2023/12/15
       * - leftValue    = 12 - 11 = 0 (valid)
       *
       * @example 3
       * - createdAt    = 2023/11/15
       * - billingMonth = 2023/11/15
       * - leftValue    = 11 - 11 = 0 (valid)
       **/
      const leftValue = differenceInMonths(billingMonth, new Date(subscribedCoupon.createdAtSeconds * 1000));
      /**
       * @example 1
       * - expiresAt    = 2023/05/10
       * - billingMonth = 2023/12/15
       * - rightValue   = 5 - 12 = -7 (expired)
       *
       * @example 2
       * - expiresAt    = 2023/12/15
       * - billingMonth = 2023/12/15
       * - rightValue   = 12 - 12 = 0 (valid)
       *
       * @example 3 If the end of the coupon period and the current month are the same, the coupon will not be applied to the next billing
       * - expiresAt    = 2023/11/15
       * - billingMonth = 2023/12/15
       * - rightValue   = 11 - 12 = -1 (expired)
       **/
      const rightValue = differenceInMonths(new Date(subscribedCoupon.expiresAtSeconds * 1000), billingMonth);

      const withinExpiration = 0 <= leftValue && 0 <= rightValue;
      if (!withinExpiration) {
        return acc;
      }

      return acc.concat({
        title: [
          resolveLanguage(coupon, lang, "name"),
          t2("percentDiscountDescription", {
            value: coupon.discountNumber,
            duration: `${unixToDatetimeFormat(subscribedCoupon.createdAtSeconds)} - ${unixToDatetimeFormat(subscribedCoupon.expiresAtSeconds)}`,
          }),
        ].join(" "),
        value: formatPrice(-(subTotal * coupon.discountNumber) / 100),
      });
    }
    return acc;
  }, initialCoupons);
};

export const usePayNextMonthConfirmSectionProps = (): Widget.BuySelectionFormProps["nextMonthPaymentConfirmSection"] => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const selectionItems = PaymentContractCreateForm.useBuyAbleSelectionItemMap();
  const selectedTemporaryPlan = PaymentContractCreateForm.useTemporarySelectedPlan();
  const initialCost = PaymentContractCreateForm.useInitialCost();
  const temporarySelectionItems = PaymentContractCreateForm.useTemporarySelectionItems();
  const customer = PaymentContractCreateForm.useCustomer();
  const plans = PaymentContractCreateForm.usePlans();
  const lang = useLanguageCode();
  const appliedCoupons = PaymentContractCreateForm.useAppliedCoupons();
  const temporaryPaymentPeriod = PaymentContractCreateForm.useTemporaryPaymentPeriod();
  const selectedPlan = React.useMemo(() => {
    return plans.find(plan => {
      return selectedTemporaryPlan?.planType === plan.planType;
    });
  }, [plans, selectedTemporaryPlan]);

  const willBuyItems = React.useMemo((): Widget.BuySelectionFormProps["nextMonthPaymentConfirmSection"]["items"] => {
    if (selectedTemporaryPlan?.planType === "ENTERPRISE") {
      return [];
    }
    const now = new Date();
    const initialCostItem = ((): ConfirmSectionItemProps | undefined => {
      if (!initialCost) {
        return;
      }
      return {
        title: resolveLanguage(initialCost, lang, "name"),
        count: 1,
        tax: `${TAX_RATE_PERCENT}%`,
        price: { value: formatPrice(initialCost.price) },
      };
    })();
    const planItem = ((): ConfirmSectionItemProps | undefined => {
      if (!selectedTemporaryPlan) return;
      const currentYearMonth = yearMonthFormat(now);
      const planEndDate = addMonths(now, temporaryPaymentPeriod === "ONE_MONTHLY" ? 1 : selectedTemporaryPlan.term);
      const planEndYearMonth = yearMonthFormat(planEndDate);
      const planPrice =
        temporaryPaymentPeriod === "ONE_MONTHLY" ? selectedTemporaryPlan.price : selectedTemporaryPlan.price * selectedTemporaryPlan.term;
      return {
        title: resolveLanguage(selectedTemporaryPlan, lang, "name"),
        subTitle: `${currentYearMonth} ~ ${planEndYearMonth}`,
        count: 1,
        tax: `${TAX_RATE_PERCENT}%`,
        price: { value: formatPrice(planPrice) },
      };
    })();
    const items = temporarySelectionItems.reduce<ConfirmSectionItemProps[]>(
      (all, temporarySelectionItem) => {
        const interview = selectionItems[temporarySelectionItem.itemId];
        if (!interview || temporarySelectionItem.amount === 0) {
          return all;
        }
        return all.concat({
          title: t2("nSelections", { num: interview.quantity }),
          count: temporarySelectionItem.amount,
          tax: `${TAX_RATE_PERCENT}%`,
          price: { value: formatPrice(temporarySelectionItem.amount * interview.price * interview.quantity) },
        });
      },
      [initialCostItem, planItem].filter(item => item !== undefined),
    );
    return items;
  }, [temporarySelectionItems, initialCost, lang, selectedTemporaryPlan, temporaryPaymentPeriod, selectionItems, t2]);

  const subTotal = React.useMemo((): number => {
    const initialCostPrice = initialCost?.price ?? 0;
    const planPrice = ((): number => {
      const value = selectedPlan?.price ?? 0;
      if (temporaryPaymentPeriod === "ONE_MONTHLY") {
        return value;
      }
      return value * (selectedPlan?.term ?? 0);
    })();
    return temporarySelectionItems.reduce<number>((total, current) => {
      const interview = selectionItems[current.itemId];
      if (!interview) {
        return total;
      }
      return total + current.amount * interview.price * interview.quantity;
    }, planPrice + initialCostPrice);
  }, [initialCost?.price, selectedPlan?.price, selectedPlan?.term, selectionItems, temporaryPaymentPeriod, temporarySelectionItems]);

  const discountValue = React.useMemo((): number => {
    const appliedCouponDiscountPrice = appliedCoupons.reduce<number>((acc, coupon) => {
      switch (coupon.couponType) {
        case "PERCENT_OFF": {
          const discountValue = subTotal * (coupon.discountNumber / 100);
          return acc + discountValue;
        }
        case "AMOUNT_OFF": {
          return acc + coupon.discountNumber;
        }
        case "UNKNOWN":
        default:
          return acc;
      }
    }, 0);
    return customer.subscription.subscribedCoupons.reduce<number>((acc, subscribedCoupon) => {
      const { coupon } = subscribedCoupon;
      switch (coupon.couponType) {
        case "PERCENT_OFF": {
          const discountValue = subTotal * (coupon.discountNumber / 100);
          return acc + discountValue;
        }
        case "AMOUNT_OFF": {
          return acc + coupon.discountNumber;
        }
        case "UNKNOWN":
        default:
          return acc;
      }
    }, appliedCouponDiscountPrice);
  }, [appliedCoupons, customer.subscription.subscribedCoupons, subTotal]);

  const couponsProps = useCoupons({
    subTotal,
  });

  const temporarySubTotalWithCoupon = subTotal - discountValue;
  const subTotalWithCoupon = temporarySubTotalWithCoupon > 0 ? temporarySubTotalWithCoupon : 0;
  const taxPrice = (subTotalWithCoupon * TAX_RATE_PERCENT) / 100;
  const total = subTotalWithCoupon + taxPrice;

  const billingMonth = addMonths(new Date(), 1);

  return {
    title: t2("paymentYearMonth", { yearMonth: yearMonthFormat(billingMonth) }),
    items: willBuyItems,
    coupons: couponsProps,
    taxPrice: {
      title: [t("消費税"), "(", t2("vatRate", { taxRate: TAX_RATE_PERCENT, price: formatPrice(subTotalWithCoupon) }), ")"].join(""),
      value: formatPrice(taxPrice),
    },
    subtotalPrice: {
      value: formatPrice(subTotal),
    },
    totalPriceWithoutTax: {
      value: formatPrice(subTotalWithCoupon),
    },
    billingAmount: {
      value: formatPrice(total),
    },
  };
};
