import { useTranslation } from "@hireroo/i18n";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import DeleteIcon from "@mui/icons-material/Delete";
import FastForwardIcon from "@mui/icons-material/FastForward";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ReplayIcon from "@mui/icons-material/Replay";
import StopIcon from "@mui/icons-material/Stop";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";

import { COMMAND_IDS } from "../../api";
import { useJupyterContext } from "../../store";
import CellTypeSelector from "./parts/CellTypeSelector";
import KernelStatus from "./parts/KernelStatus";

const HEIGHT = 30;

const NavigationWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItem: "center",
  height: HEIGHT,
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  alignItems: "center",
  justifyItems: "center",
  width: "100%",
}));

const InnerBox = styled(Box)(() => ({
  height: HEIGHT,
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
}));

const StyledIconButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
  width: 30,
  minWidth: 30,
  height: HEIGHT,
  svg: {
    width: 16,
    height: 16,
  },
}));

export type NotebookToolbarProps = {};

const NotebookToolbar: React.FC<NotebookToolbarProps> = _props => {
  const { t } = useTranslation();
  const { adapter } = useJupyterContext();
  const iconButtonList: ButtonProps[] = [
    // {
    //   title: t("保存してチェックポイントを作成"),
    //   children: <SaveIcon />,
    //   onClick: () => {
    //     adapter?.commands.execute(COMMAND_IDS.save);
    //   },
    // },
    {
      title: t("下にセルを挿入"),
      children: <AddOutlinedIcon />,
      onClick: () => {
        adapter?.insert("BELOW");
      },
    },
    {
      title: t("セルの切り取り"),
      children: <ContentCutIcon />,
      onClick: () => {
        adapter?.commands.execute(COMMAND_IDS.cut);
      },
    },
    {
      title: t("セルのコピー"),
      children: <ContentCopyIcon />,
      onClick: () => {
        adapter?.commands.execute(COMMAND_IDS.copy);
      },
    },
    {
      title: t("クリップボードからセルを貼り付け"),
      children: <ContentPasteIcon />,
      onClick: () => {
        adapter?.commands.execute(COMMAND_IDS.paste);
      },
    },
    {
      title: t("セルを実行して次に移動する"),
      children: <PlayArrowIcon />,
      onClick: () => {
        adapter?.commands.execute(COMMAND_IDS.runAndAdvance);
      },
    },
    {
      title: t("カーネルを中断"),
      children: <StopIcon />,
      onClick: () => {
        adapter?.commands.execute(COMMAND_IDS.interrupt);
      },
    },
    {
      title: t("カーネルを再起動"),
      children: <ReplayIcon />,
      onClick: () => {
        adapter?.commands.execute(COMMAND_IDS.restart);
      },
    },
    {
      title: t("カーネルを再起動してすべてのセルを実行"),
      children: <FastForwardIcon />,
      onClick: () => {
        adapter?.commands.execute(COMMAND_IDS.restartAndRun);
      },
    },
    {
      title: t("セルの削除"),
      children: <DeleteIcon />,
      onClick: () => {
        adapter?.commands.execute(COMMAND_IDS.deleteCell);
      },
    },
  ];

  return (
    <NavigationWrapper className="navigation">
      <InnerBox>
        {iconButtonList.map((iconButtonProps, index) => {
          const { title, ...rest } = iconButtonProps;
          if (title) {
            return (
              <Tooltip key={`index-${index}`} title={title}>
                <StyledIconButton {...rest} />
              </Tooltip>
            );
          }
          return <StyledIconButton key={`index-${index}`} {...rest} />;
        })}
        <CellTypeSelector />
      </InnerBox>
      <InnerBox>
        <KernelStatus />
      </InnerBox>
    </NavigationWrapper>
  );
};

NotebookToolbar.displayName = "NotebookToolbar";

export default NotebookToolbar;
