import { useQuizRevisionsV2 } from "@hireroo/app-helper/hooks";
import { QuizTestReport } from "@hireroo/app-store/view-domain/QuizTestReport";
import * as Time from "@hireroo/formatter/time";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

import { useOptionQuizResourcesFromFirebase } from "./privateHelper";
import { useGenerateFreeTextPlaybackProps } from "./useGenerateFreeTextPlaybackProps";
import { useGenerateOptionPlaybackProps } from "./useGenerateOptionPlaybackProps";

type ParentPlaybackProps = Omit<Widget.QuizPlaybackEditorProps, "playback" | "playbackDialog">;

export type GenerateQuizPlaybackEditorPropsArgs = {
  quizId: number;
  packageId: number;
  questionIds: number[];
};

export const useGenerateProps = (args: GenerateQuizPlaybackEditorPropsArgs): Widget.QuizPlaybackEditorProps => {
  const [open, setOpen] = React.useState(false);
  const [revisionIndex, setRevisionIndex] = React.useState<number>(0);
  const { revisions, ready } = useQuizRevisionsV2({
    quizId: args.quizId,
    packageId: args.packageId,
    questionIds: args.questionIds,
  });

  const handleOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const hooks = QuizTestReport.useCreateQuizHooks(args.quizId);
  const lastRevisionIndex = revisions.length - 1;
  const forceRevisionIndex = hooks.useForceRevisionIndex();
  const [prev] = React.useState(forceRevisionIndex);

  /**
   * Since Playback is in its final state when initially displayed, specify the last Revision Index.
   */
  React.useEffect(() => {
    if (ready) {
      setRevisionIndex(lastRevisionIndex);
    }
  }, [ready, lastRevisionIndex]);

  React.useEffect(() => {
    if (forceRevisionIndex && prev !== forceRevisionIndex) {
      setRevisionIndex(forceRevisionIndex);
    }
  }, [forceRevisionIndex, prev]);

  const { questionId, optionIds, optionStatus } = useOptionQuizResourcesFromFirebase({
    firstQuestionId: args.questionIds[0],
    revisions: revisions,
    revisionIndex: revisionIndex,
    validQuestionIds: args.questionIds,
  });

  const question = hooks.useQuestionByQuestionId(questionId);

  const remainTime = React.useMemo(() => {
    const endTime = revisions[lastRevisionIndex]?.t;
    const startTime = revisions[0]?.t;

    return Time.elapsedTimeFormat(endTime - startTime);
  }, [revisions, lastRevisionIndex]);

  const passedTime = React.useMemo(() => {
    const startTime = revisions[0]?.t;
    const currentTime = revisions[revisionIndex]?.t;
    return Time.elapsedTimeFormat(currentTime - startTime);
  }, [revisions, revisionIndex]);

  const playbackStatus = React.useMemo((): Widget.QuizPlaybackEditorProps["status"] => {
    if (!ready) return "LOADING";
    if (revisions.length === 0) return "NO_DATA";
    return "READY";
  }, [revisions.length, ready]);

  const handleOptionPlaybackValue = React.useCallback(
    (newRevisionIndex: number) => {
      if (!ready) return;
      setRevisionIndex(newRevisionIndex);
    },
    [ready],
  );

  const playbackToolbarProps = React.useMemo((): Widget.QuizPlaybackEditorProps["playbackToolbar"] => {
    return {
      value: revisionIndex,
      slider: {
        min: 0,
        max: lastRevisionIndex,
        // Quiz has no marks
        marks: [],
      },
      enableAutoplay: true,
      screenButton: {
        onClick: () => {
          setOpen(true);
        },
      },
      remainTime: remainTime,
      passedTime: passedTime,
    };
  }, [lastRevisionIndex, passedTime, remainTime, revisionIndex]);

  const parentPlaybackProps: ParentPlaybackProps = {
    status: playbackStatus,
    playbackToolbar: playbackToolbarProps,
  };

  const optionPlaybackProps = useGenerateOptionPlaybackProps({
    quizId: args.quizId,
    questionId: questionId,
    optionIds: optionIds,
    status: optionStatus,
    open,
    parentProps: parentPlaybackProps,
    handleOpen,
    handleClose,
    handlePlaybackValue: handleOptionPlaybackValue,
  });

  const freeTextPlaybackProps = useGenerateFreeTextPlaybackProps({
    quizId: args.quizId,
    questionId: questionId,
    revisionIndex: revisionIndex,
    revisions: revisions,
    open,
    parentProps: parentPlaybackProps,
    handleOpen,
    handleClose,
    handlePlaybackValue: handleOptionPlaybackValue,
  });

  if (question?.variant === "FREE_TEXT") {
    return freeTextPlaybackProps;
  }

  return optionPlaybackProps;
};
