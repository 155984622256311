import Add from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Remove from "@mui/icons-material/Remove";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Collapse from "@mui/material/Collapse";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";

import ExpandMoreButton from "../../../../primitive/Button/ExpandMoreButton";
import Tag, { TagProps } from "../../../../primitive/Tag/Tag";
import QuizQuestionCardContent, { QuizQuestionCardContentProps } from "../../../../usecase/QuizQuestionCardContent/QuizQuestionCardContent";

const StyledCardHeader = styled(CardHeader)({
  paddingBottom: 1,
  ".MuiCardHeader-title": {
    fontSize: "1.15rem",
    fontWeight: "bold",
  },
  ".MuiCardHeader-subheader": {
    fontSize: "0.8rem",
  },
});

const StyledCardContent = styled(CardContent)({
  paddingTop: 8,
  paddingBottom: 0,
});

const StyledCardAction = styled(CardActions)({
  paddingTop: 8,
  paddingBottom: 8,
});

export type QuizQuestionCardProps = {
  id: string;
  title: string;
  lastUpdatedAt: string;
  selected: boolean;
  selectButton: Pick<IconButtonProps, "onClick">;
  privateTag: TagProps;
  difficultyTag: TagProps;
  extraTags: TagProps[];
  content: QuizQuestionCardContentProps;
};

const QuizQuestionCard: React.FC<QuizQuestionCardProps> = props => {
  const [expanded, setExpanded] = React.useState<boolean>(false);
  return (
    <Card sx={{ backgroundColor: props.selected ? "transparent" : "background.paper" }} variant="outlined">
      <StyledCardHeader
        action={<IconButton {...props.selectButton}>{props.selected ? <Remove /> : <Add />}</IconButton>}
        title={props.title}
        subheader={props.lastUpdatedAt}
      />
      <StyledCardContent>
        <Stack direction="row" spacing={1} mt={2}>
          <Tag {...props.privateTag} />
          <Tag {...props.difficultyTag} />
          {props.extraTags.map(tag => (
            <Tag key={tag.label} {...tag} color="grey" />
          ))}
        </Stack>
      </StyledCardContent>
      <StyledCardAction disableSpacing>
        <ExpandMoreButton expand={expanded} onClick={() => setExpanded(prev => !prev)} aria-expanded={expanded} aria-label="show more">
          <ExpandMoreIcon />
        </ExpandMoreButton>
      </StyledCardAction>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <QuizQuestionCardContent {...props.content} />
        </CardContent>
      </Collapse>
    </Card>
  );
};

QuizQuestionCard.displayName = "QuestionCard";

export default QuizQuestionCard;
