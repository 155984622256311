import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateChallengeCodingEditorChatGPTRightSidePanelPropsArgs, useGenerateProps } from "./useGenerateProps";

export type ChallengeCodingEditorChatGPTRightSidePanelContainerProps = GenerateChallengeCodingEditorChatGPTRightSidePanelPropsArgs;

const ChallengeCodingEditorChatGPTRightSidePanelContainer: React.FC<ChallengeCodingEditorChatGPTRightSidePanelContainerProps> = props => {
  const chatGPTSidePanelProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ChallengeCodingEditorChatGPTRightSidePanel {...chatGPTSidePanelProps} />
    </ErrorBoundary>
  );
};

ChallengeCodingEditorChatGPTRightSidePanelContainer.displayName = "ChallengeCodingEditorChatGPTRightSidePanelContainer";

export default withErrorBoundary(ChallengeCodingEditorChatGPTRightSidePanelContainer, {});
