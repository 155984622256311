import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "../../../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette["Background/Paper"].p2,
  borderRadius: "16px",
}));

const StyledStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
  borderRadius: "12px",
}));

export type SummaryProps = {
  addButton: Pick<IconButtonWithTooltipProps, "onClick">;
  numAcceptance: number;
  numLimit?: number;
  numRemain?: number;
};

const Summary: React.FC<SummaryProps> = props => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const theme = useTheme();

  const addButton: IconButtonWithTooltipProps = {
    ...props.addButton,
    children: <AddCircleIcon fontSize="small" />,
  };

  return (
    <Box width="100%">
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Typography fontSize={16} sx={{ fontWeight: "bold" }}>
          {t("サマリー")}
        </Typography>
      </Box>
      <StyledBox p={2}>
        <StyledStack direction="column" spacing={0.5} width="25%">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography fontSize={14} noWrap color="textSecondary">{`${t("承諾数")} / ${t("定員数")}`}</Typography>
            <IconButtonWithTooltip {...addButton} />
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1.25}>
            <Typography fontSize={20} fontWeight={700}>{`${props.numAcceptance} / ${props.numLimit ?? t("無制限")} ${
              props.numRemain !== undefined ? `(${t2("numRemain", { num: props.numRemain })})` : ""
            }`}</Typography>
            {props.numRemain === 0 && (
              <Stack direction="row" alignItems="center">
                <WarningRoundedIcon fontSize="small" sx={{ color: theme.palette.warning.light }} />
                <Typography fontSize={12} color={theme.palette.warning.light} sx={{ wordBreak: "keep-all" }}>
                  {t("残枠不足")}
                </Typography>
              </Stack>
            )}
          </Stack>
        </StyledStack>
      </StyledBox>
    </Box>
  );
};

Summary.displayName = "Summary";

export default Summary;
