import { useTranslation } from "@hireroo/i18n";
import { PaymentForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import * as React from "react";
import { FormProvider, type SubmitHandler, useForm } from "react-hook-form";

import AutomaticContractRenewalField, {
  AutomaticContractRenewalFieldProps,
} from "../../../../../../ms-components/Payment/AutomaticContractRenewalField/AutomaticContractRenewalField";
import BaseDialog, { BaseDialogProps } from "../../../../../../primitive/BaseDialog/BaseDialog";

export type EditContractDialogProps = Pick<BaseDialogProps, "open"> & {
  onClose: () => void;
  defaultValues: PaymentForm.AutoUpdateContractConfirmSchema;
  onSubmit: SubmitHandler<PaymentForm.AutoUpdateContractConfirmSchema>;
};

const EditContractDialog: React.FC<EditContractDialogProps> = props => {
  const { t } = useTranslation();
  const validateSchema = PaymentForm.useAutoUpdateContractConfirmSchema();
  const methods = useForm<PaymentForm.AutoUpdateContractConfirmSchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: {
      ...props.defaultValues,
    },
  });

  const baseDialogProps: BaseDialogProps = {
    ...props,
    title: t("契約自動更新"),
    yesButton: {
      children: t("設定を更新する"),
      onClick: () => {
        props.onClose();
        methods.handleSubmit(props.onSubmit, console.error)();
      },
    },
    noButton: {
      children: t("閉じる"),
      onClick: () => {
        props.onClose();
      },
    },
  };

  const automaticContractRenewalFieldProps: AutomaticContractRenewalFieldProps = {
    name: "automaticContractRenewal",
  };

  return (
    <FormProvider {...methods}>
      <BaseDialog {...baseDialogProps}>
        <AutomaticContractRenewalField {...automaticContractRenewalFieldProps} />
      </BaseDialog>
    </FormProvider>
  );
};

EditContractDialog.displayName = "EditContractDialog";

export default EditContractDialog;
