import { generateSubmittedEntityKey } from "@hireroo/app-helper/normalizer";
import { useTitle } from "@hireroo/app-helper/react-use";
import { Credential } from "@hireroo/app-store/essential/shared";
import { TestReview } from "@hireroo/app-store/widget/shared/TestReview";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

import ProjectCodingEditorContainerV3, {
  ProjectCodingEditorInitialContainerV3Props,
} from "../../../../widget/v2/shared/ProjectCodingEditorV3/InitialContainer";

type EntityComponentMap = Record<string, React.ReactNode>;

export type GenerateAnyComponentPropsArgs = {};

export const useGenerateProps = (_args: GenerateAnyComponentPropsArgs): Widget.AnyComponentProps => {
  const { t } = useTranslation();
  const uid = Credential.useUid();
  const entities = TestReview.useEntities();
  const reviewTarget = TestReview.userReviewTarget();
  const currentEntityKey = `${reviewTarget.entityType}-${reviewTarget.entityId}`;
  useTitle(t("レビュー"));
  const ContentMap: EntityComponentMap = React.useMemo<EntityComponentMap>(() => {
    return entities.reduce<EntityComponentMap>((all, entity) => {
      const key = generateSubmittedEntityKey(entity);
      switch (entity.__typename) {
        case "ProjectEntity": {
          const projectCodingEditorInitialContainerProps: ProjectCodingEditorInitialContainerV3Props = {
            mode: "EVALUATION",
            uid: uid ?? "",
            entityId: entity.projectEntityId,
            endpointId: reviewTarget.submissionId,
            questionId: entity.question?.questionId ?? 0,
            questionVersion: entity.question?.version ?? "v2.0.0",
            submissionId: reviewTarget.submissionId,
            isCandidate: false,
            enableBrowserEventDetector: false,
            userName: "Reviewer",
          };
          return { ...all, [key]: <ProjectCodingEditorContainerV3 key={key} {...projectCodingEditorInitialContainerProps} /> };
        }
        default: {
          return all;
        }
      }
    }, {});
  }, [entities, reviewTarget.submissionId, uid]);
  return {
    children: currentEntityKey ? ContentMap[currentEntityKey] : undefined,
  };
};
