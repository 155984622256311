import { createContentIncludedFormData } from "@hireroo/app-helper/form-data";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import type { Widget } from "@hireroo/presentation";
import { getContentApiClient } from "@hireroo/rest-api/client";

export type GenerateSampleUploadContentPropsArgs = {};

export const useGenerateProps = (_args: GenerateSampleUploadContentPropsArgs): Widget.SampleUploadContentProps => {
  const contentApiClient = getContentApiClient();
  return {
    onSelectedFiles: files => {
      contentApiClient
        .uploadProfiles({
          requestBody: createContentIncludedFormData({
            type: "MULTI",
            files,
          }),
        })
        .then(res => {
          console.log(res);
        })
        .catch(error => {
          console.error(error);
          Snackbar.notify({
            message: "アップロードに失敗しました",
            severity: "error",
          });
        });

      const singleFileFormData = new FormData();
      singleFileFormData.append("file", files[0]);
      contentApiClient
        .uploadProfile({
          requestBody: createContentIncludedFormData({
            type: "SINGLE",
            file: files[0],
          }),
        })
        .then(res => {
          console.log(res);
        })
        .catch(error => {
          console.error(error);
          Snackbar.notify({
            message: "アップロードに失敗しました",
            severity: "error",
          });
        });
    },
  };
};
