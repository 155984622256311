import { Company } from "@hireroo/app-store/essential/employee";
import { TalentAssignField } from "@hireroo/app-store/widget/e/TalentAssignField";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

type SuggestionItem = Widget.AssignFieldProps["suggestions"][0];

type DisplaySources = Widget.AssignFieldProps["displaySources"];

export type GenerateAssignFieldPropsArgs = {
  name: string;
};

export const useGenerateProps = (args: GenerateAssignFieldPropsArgs): Widget.AssignFieldProps => {
  const { t } = useTranslation();
  const companyId = Company.useStrictActiveCompanyId();
  const [result, refresh] = Graphql.useGetTalentsForTalentAssignFieldQuery({
    variables: {
      companyId: companyId,
    },
    requestPolicy: "cache-and-network",
  });
  const talents = TalentAssignField.useTalents();

  React.useEffect(() => {
    if (result.data) {
      TalentAssignField.addTalents(result.data.company.talents);
    }
  }, [result.data]);

  const suggestionsProps = React.useMemo((): SuggestionItem[] => {
    const suggestions: SuggestionItem[] = [];
    talents.forEach(talent => {
      suggestions.push({
        type: "TALENT",
        valueId: talent.uid,
        locked: false,
      });
    });
    return suggestions;
  }, [talents]);

  const displaySourcesProps = React.useMemo((): DisplaySources => {
    const displaySources: DisplaySources = {};
    talents.forEach(talent => {
      displaySources[talent.uid] = {
        optionText: `${talent.displayName || "No Name"} (${talent.email})`,
        chipText: talent.displayName || "No Name",
        photoUrl: talent.photoUrl,
      };
    });
    return displaySources;
  }, [talents]);

  return {
    multiple: false,
    name: args.name,
    variant: "OUTLINED",
    placeholder: t("名前・メールアドレスで検索"),
    suggestions: suggestionsProps,
    displaySources: displaySourcesProps,
    loading: result.fetching,
    onFocus: () => {
      refresh();
    },
    clearedValue: {
      type: "TALENT",
      talentId: "",
    },
  };
};
