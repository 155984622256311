import { UNSUPPORTED_READABILITY_RUNTIMES } from "@hireroo/app-definition/challenge";
import { TargetElementIdMap } from "@hireroo/app-helper/challenge";
import { scrollToContentForReportV2 } from "@hireroo/app-helper/html-element";
import { ChallengeTestReport } from "@hireroo/app-store/view-domain/ChallengeTestReport";
import { formatScore } from "@hireroo/formatter/score";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/react";
import * as React from "react";

type ShowingTarget = "SCORE" | "RANK";
export type GenerateEntityScoreBoardPropsArgs = {
  challengeId: number;
  showingTargets: ShowingTarget[];
};
type EntityScoreBoard = Exclude<Widget.ChallengeTestReportProps["entityScoreBoard"], undefined>;
type SubmissionOption = EntityScoreBoard["submissionOptions"][0];
type Status = EntityScoreBoard["status"];

export const useGenerateEntityScoreBoardProps = (
  args: GenerateEntityScoreBoardPropsArgs,
): Widget.ChallengeTestReportProps["entityScoreBoard"] => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const client = getGraphqlClient();
  const challengeHooks = ChallengeTestReport.useCreateChallengeHooks(args.challengeId);
  const challenge = challengeHooks.useChallenge();
  const submissionId = challengeHooks.useCurrentSelectedSubmissionId();
  const submissionStatusMap: Record<Graphql.ChallengeSubmissionStatus, string> = {
    ENQUEUED: `(${t("採点中")})`,
    FAILED: `(${t("採点失敗")})`,
    EVALUATED: "",
    UNKNOWN: "",
  };
  const submissionMap = challengeHooks.useSubmissionMap();

  const statusMap: Record<Graphql.ChallengeStatus, Status> = {
    CREATED: "EVALUATING",
    STARTED: "EVALUATING",
    EVALUATED: "EVALUATED",
    COMPLETED: "EVALUATING",
    UNKNOWN: "ERROR",
  };

  const hasReadability = React.useMemo(() => {
    const submission = submissionMap[submissionId];
    if (!submission) return false;
    if (submission?.readabilityTestResult === "") {
      return false;
    } else {
      return !UNSUPPORTED_READABILITY_RUNTIMES.includes(submission.runtime);
    }
  }, [submissionMap, submissionId]);

  const totalScore = React.useMemo((): number => {
    const submission = submissionMap[submissionId];
    return submission.totalScore;
  }, [submissionId, submissionMap]);
  const submission = submissionMap[submissionId];
  const readability = submission?.readability ? formatScore(submission.readability) : 0;

  const submissionOptions = challenge.submissions.map((submission, index): SubmissionOption => {
    return {
      id: submission.challengeSubmissionId.toString(),
      displayText: args.showingTargets.includes("SCORE")
        ? `${index + 1} ${submission.isBest ? `【${t("最高得点")}】` : ""} ${t("スコア")} ${formatScore(submission.totalScore)}% ${
            submissionStatusMap[submission.challengeSubmissionStatus]
          }`
        : t2("NumberOfSubmission", { number: `${index + 1}` }),
    };
  });
  const difficultyMap: Record<Graphql.Difficulty, EntityScoreBoard["difficulty"]> = {
    EASY: "EASY",
    MEDIUM: "MEDIUM",
    DIFFICULT: "DIFFICULT",
    UNKNOWN: "EASY",
  };

  return {
    difficulty: difficultyMap[challenge.challengeQuestion?.difficulty ?? "UNKNOWN"],
    scoreChart:
      (args.showingTargets.includes("SCORE") && {
        //TODO: refactor add rank
        score: formatScore(totalScore),
      }) ||
      undefined,
    scoreBars: args.showingTargets.includes("SCORE")
      ? [
          {
            title: t("正解率"),
            link: {
              onClick: () => {
                scrollToContentForReportV2(TargetElementIdMap.CORRECT_RATE_SECTION);
              },
              href: "",
            },
            description: t("用意されたテストケース中で通った割合"),
            score: submission?.coverage ? formatScore(submission.coverage) : 0,
            color: "secondary" as const,
          },
          {
            title: t("パフォーマンス"),
            link: {
              onClick: () => {
                scrollToContentForReportV2(TargetElementIdMap.PERFORMANCE_SECTION);
              },
              href: "",
            },
            description: t("実行時の速度やメモリ利用量などから算出された値"),
            score: submission?.performance ? formatScore(submission.performance) : 0,
            color: "info" as const,
          },
          hasReadability && {
            title: t("可読性"),
            link: {
              onClick: () => {
                scrollToContentForReportV2(TargetElementIdMap.READABILITY_SECTION);
              },
              href: "",
            },
            description: t("循環的複雑度から算出された値"),
            score: readability,
            color: "warning" as const,
          },
        ].flatMap(v => (v ? [v] : []))
      : [],
    submissionSelect: {
      onChange: event => {
        const submissionId = Number(event.target.value);
        client
          .GetChallengeSubmissionForChallengeTestReport({
            submissionId: submissionId,
          })
          .then(res => {
            ChallengeTestReport.updateSubmission(args.challengeId, res.challengeSubmission);
          })
          .catch(e => {
            Sentry.captureException(e);
          })
          .finally(() => {
            ChallengeTestReport.setCurrentSelectedSubmissionId(args.challengeId, submissionId);
          });
      },
      value: submissionId.toString(),
    },
    submissionOptions: submissionOptions,
    status: statusMap[challenge.challengeStatus],
  };
};
