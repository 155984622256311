import * as Def from "./definition";
import { state } from "./State";
import type * as Types from "./types";

export const initialize = (listQuestion: Types.ListQuestion): void => {
  state.questionObjects = listQuestion.questions.questions;
  state.count = listQuestion.questions.count;
};

export const updateSearchQuery = (searchQuery: Types.SearchQuery) => {
  state.searchQuery = searchQuery;
};

export const updatePageSize = (size: number) => {
  state.searchQuery.size = size;
};

export const updatePage = (page: number) => {
  state.searchQuery.page = page;
};

export const clear = () => {
  state.questionObjects = null;
  state.searchQuery = Def.createDefaultSearchQuery();
  state.count = 0;
  state.hiddenQuestionSet.clear();
};

export const hideQuestion = (id: Types.QuestionUniqueKey) => {
  state.hiddenQuestionSet.add(id);
};

export const showQuestion = (id: Types.QuestionUniqueKey) => {
  state.hiddenQuestionSet.delete(id);
};

export const updateLoadingStatus = (status: Types.LoadingStatus) => {
  state.loadingStatus = status;
};
