import ListItemButton from "@mui/material/ListItemButton";
import ListItemText, { ListItemTextProps } from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import * as React from "react";

import Link from "../../../../../../../../primitive/Link/Link";
import type * as Types from "../../../../../../types";

const StyledListItemButton = styled(ListItemButton)(() => ({
  padding: "8px",
}));

const StyledListItemText = styled(ListItemText)(() => ({
  paddingLeft: "16px",
  paddingRight: "16px",
  "& .MuiTypography-root": {
    fontSize: 14,
    fontWeight: "inherit",
  },
}));

export type SidePanelNavigationMenuItemProps = Types.MenuItemWithoutChildren & {
  endIcon?: React.ReactNode;
  root?: boolean;
};

const SidePanelNavigationMenuItem: React.FC<SidePanelNavigationMenuItemProps> = props => {
  const listItemTextProps: ListItemTextProps = {
    primary: props.children,
    sx: {
      fontWeight: props.root ? 500 : 400,
    },
  };
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <StyledListItemButton onClick={props.onClick} component={Link} href={props.href}>
      <StyledListItemText {...listItemTextProps} />
    </StyledListItemButton>
  );
};

SidePanelNavigationMenuItem.displayName = "SidePanelNavigationMenuItem";

export default SidePanelNavigationMenuItem;
