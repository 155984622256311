import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateScreeningTestDetailForEmployeePropsArgs, useGenerateProps } from "./useGenerateProps";

export type ScreeningTestDetailForEmployeeContainerProps = GenerateScreeningTestDetailForEmployeePropsArgs;

const ScreeningTestDetailForEmployeeContainer: React.FC<ScreeningTestDetailForEmployeeContainerProps> = props => {
  const screeningTestDetailForEmployeeProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ScreeningTestDetailForEmployee {...screeningTestDetailForEmployeeProps} />
    </ErrorBoundary>
  );
};

ScreeningTestDetailForEmployeeContainer.displayName = "ScreeningTestDetailForEmployeeContainer";

export default withErrorBoundary(ScreeningTestDetailForEmployeeContainer, {});
