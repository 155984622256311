import { useTranslation } from "@hireroo/i18n";
import Search from "@mui/icons-material/Search";
import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Chip, { ChipProps } from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import * as React from "react";

export type TagSearchFieldProps = AutocompleteProps<{ id: number; name: string }, true, true, true>;

export type QuizQuestionSearchFieldProps = {
  searchTextField: TextFieldProps;
  tagSearch: Pick<TagSearchFieldProps, "open" | "value" | "onChange" | "options">;
  selectedTags: ChipProps[];
};

const QuizQuestionSearchField: React.FC<QuizQuestionSearchFieldProps> = props => {
  const { t } = useTranslation();
  const [openTagSearchField, setOpenTagSearchField] = React.useState(false);
  const searchTextField: TextFieldProps = {
    ...props.searchTextField,
    type: "text",
    label: t("文字列検索"),
    variant: "outlined",
    placeholder: t("(例) React"),
    size: "small",
    fullWidth: true,
    InputProps: {
      endAdornment: (
        <InputAdornment position="end">
          <Search />
        </InputAdornment>
      ),
    },
  };
  const tagSearchFieldProps: TagSearchFieldProps = {
    ...props.tagSearch,
    size: "small",
    sx: { width: "100%" },
    multiple: true,
    open: openTagSearchField,
    loadingText: <CircularProgress />,
    renderInput: params => {
      return <TextField label={t("タグ検索")} {...params} />;
    },
    onBlur: () => {
      setOpenTagSearchField(false);
    },
    onFocus: () => {
      setOpenTagSearchField(true);
    },
    renderTags: tags => (tags.length ? <Chip label={`${tags.length}`} size="small" /> : null),
    getOptionLabel: tag => {
      return typeof tag === "string" ? tag : tag.name;
    },
    isOptionEqualToValue: (option, value) => {
      return option.id === value.id;
    },
  };
  return (
    <Box>
      <Stack direction="row" spacing={1}>
        <TextField {...searchTextField} />
        <Box minWidth="150px">
          <Autocomplete {...tagSearchFieldProps} />
        </Box>
      </Stack>
      <Box>
        {props.selectedTags.map((tag, index) => (
          <Box key={`${index}-${tag.label}`} display="inline-block" mr={1} mt={1.5}>
            <Chip {...tag} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

QuizQuestionSearchField.displayName = "QuizQuestionSearchField";

export default QuizQuestionSearchField;
