import { QuizCodingEditor } from "@hireroo/app-store/widget/shared/QuizCodingEditor";

const { QuizFreeTextEditor } = QuizCodingEditor;

export const startSubscribeAnswerText = () => {
  return QuizFreeTextEditor.subscribeAnswerText((quizId, questionId, text) => {
    const { setAnswerText } = QuizCodingEditor.createQuizEntityAction(quizId);
    setAnswerText(questionId, text);
  });
};
