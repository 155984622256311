import { useTranslation } from "@hireroo/i18n";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";

import ButtonWithTooltip, { ButtonWithTooltipProps } from "../../../primitive/Button/ButtonWithTooltip/ButtonWithTooltip";
import MoreButton, { MoreButtonProps } from "../../../primitive/Button/MoreButton/MoreButton";
import ExamRetryButtonDialog, { ExamRetryButtonDialogProps } from "../ExamRetryButtonDialog/ExamRetryButtonDialog";
import ExamSelector, { ExamSelectorProps } from "../ExamSelector/ExamSelector";

const StyledButton = styled(Button)(() => ({
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  overflow: "hidden",
}));

const StyledStack = styled(Stack)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export type AssessmentDetailPageHeaderProps = {
  className?: string;
  backButton: Pick<ButtonProps, "onClick">;
  reviewButton?: Pick<ButtonWithTooltipProps, "onClick" | "disabled">;
  startExamButton?: Pick<ButtonWithTooltipProps, "onClick" | "disabled">;
  retryButton?: ExamRetryButtonDialogProps;
  examSelector?: ExamSelectorProps;
  moreButton?: Pick<MoreButtonProps, "options">;
};

const AssessmentDetailPageHeader: React.FC<AssessmentDetailPageHeaderProps> = props => {
  const { t } = useTranslation();
  const backButton: ButtonProps = {
    ...props.backButton,
    color: "outline-only",
    startIcon: <ArrowBackIcon />,
    children: t("戻る"),
  };
  const moreButton: MoreButtonProps | undefined = props.moreButton && {
    ...props.moreButton,
    variant: "vertical",
  };
  const reviewButton: ButtonWithTooltipProps | undefined = props.reviewButton && {
    ...props.reviewButton,
    startIcon: "RATE_REVIEW",
    variant: "outlined",
    children: t("技術レビュー"),
  };
  const startExamButton: ButtonWithTooltipProps | undefined = props.startExamButton && {
    ...props.startExamButton,
    variant: "outlined",
    startIcon: "RESTART",
    children: t("試験を実施"),
  };
  return (
    <StyledStack direction="row" p={1} className={props.className}>
      <StyledButton {...backButton} />
      <Stack direction="row" spacing={1} display="flex" alignItems="center" justifyContent="center">
        {props.examSelector && <ExamSelector {...props.examSelector} />}
        {startExamButton && <ButtonWithTooltip key="start-exam-button" {...startExamButton} />}
        {props.retryButton && <ExamRetryButtonDialog {...props.retryButton} />}
        {reviewButton && <ButtonWithTooltip key="review-button" {...reviewButton} />}
        {moreButton && <MoreButton {...moreButton} />}
      </Stack>
    </StyledStack>
  );
};

AssessmentDetailPageHeader.displayName = "AssessmentDetailPageHeader";

export default AssessmentDetailPageHeader;
