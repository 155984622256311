import * as Graphql from "@hireroo/graphql/client/urql";

import { DeepReadonly } from "../types";

export const createAnswerRuntimeInput = (
  answerRuntimeFragment: DeepReadonly<Graphql.AlgorithmAnswerRuntimeForAlgorithmResourceEditorFragment>,
): Graphql.UpdateAlgorithmAnswerRuntimeInput => {
  const { __typename, ...rest } = answerRuntimeFragment;
  return {
    ...rest,
  };
};

export const createAnswerInput = (
  answerFragment: DeepReadonly<Graphql.AlgorithmAnswerForAlgorithmResourceEditorFragment>,
): Graphql.UpdateAlgorithmAnswerInput => {
  const { answerRuntimes, __typename, ...rest } = answerFragment;

  const answerRuntimeInputs: Graphql.UpdateAlgorithmAnswerRuntimeInput[] = answerRuntimes.map(r => {
    return createAnswerRuntimeInput(r);
  });

  return {
    ...rest,
    answerRuntimes: answerRuntimeInputs,
  };
};

export const createAnswersInput = (
  answerFragments: DeepReadonly<Array<Graphql.AlgorithmAnswerForAlgorithmResourceEditorFragment>>,
): Graphql.UpdateAlgorithmAnswerInput[] => {
  return answerFragments.map(a => createAnswerInput(a));
};

export const createHintInput = (
  hintFragment: DeepReadonly<Graphql.AlgorithmHintForAlgorithmResourceEditorFragment>,
): Graphql.UpdateAlgorithmHintInput => {
  const { __typename, ...rest } = hintFragment;
  return {
    ...rest,
  };
};
