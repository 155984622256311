import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";

import { useSystemDesignContext } from "../../store";
import EditControlPanel, { EditControlPanelProps } from "../EditorParts/EditControlPanel";
import MiniMap from "../EditorParts/MiniMap";
import ElementSettings, { ElementSettingsProps } from "../modules/ElementSettings/ElementSettings";
import DrawingArea, { DrawingAreaProps } from "./DrawingArea";

const RootBox = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "100%",
  maxWidth: "100vw",
  maxHeight: "100vh",
  backgroundColor: theme.palette.common.white,
}));

const ElementSettingsBox = styled(Box)`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
`;

export type FlowChartProps = {
  drawingArea: Omit<DrawingAreaProps, "outerHeight" | "outerWidth">;
  elementSettings?: ElementSettingsProps;
  editControlPanel: EditControlPanelProps;
};

const FlowChart: React.FC<FlowChartProps> = props => {
  const Store = useSystemDesignContext();
  const resizeDetector = useResizeDetector();
  React.useEffect(() => {
    if (typeof resizeDetector.height === "number" && typeof resizeDetector.width === "number") {
      const aspectRatio = resizeDetector.height / resizeDetector.width;
      if (!aspectRatio) return;
      Store.action.updateAspectRatio({
        aspectRatio: aspectRatio,
      });
    }
  }, [Store.action, resizeDetector.height, resizeDetector.width]);
  const drawingAreaProps: DrawingAreaProps = {
    ...props.drawingArea,
    outerHeight: resizeDetector.height ?? 0,
    outerWidth: resizeDetector.width ?? 0,
  };
  return (
    <RootBox ref={resizeDetector.ref}>
      <DrawingArea {...drawingAreaProps} />
      {props.elementSettings && (
        <ElementSettingsBox>
          <ElementSettings {...props.elementSettings} />
        </ElementSettingsBox>
      )}
      <EditControlPanel {...props.editControlPanel} />
      <MiniMap />
    </RootBox>
  );
};

FlowChart.displayName = "FlowChart";

export default FlowChart;
