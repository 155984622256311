import { useTranslation } from "@hireroo/i18n";
import { RemoteTemplateResourceEditorForm } from "@hireroo/validator";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import Box from "@mui/material/Box";
import Button, { type ButtonProps } from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";

import Link, { LinkProps } from "../../../../primitive/Link/Link";
import { useRemoteTemplateResourceEditorContext } from "../../Context";
import TemplateFields from "../TemplateFields/TemplateFields";

const Wrapper = styled(Box)(() => ({
  height: "100%",
  width: "100%",
  position: "relative",
  display: "flex",
  flexDirection: "column",
}));

const StyledTitle = styled(Typography)(() => ({
  fontSize: 16,
  width: "100%",
  textOverflow: "ellipsis",
}));

const Tag = styled(Typography)(({ theme }) => ({
  padding: "1px 4px",
  backgroundColor: theme.palette.Other.FilledInputBG,
  borderRadius: "20px",
  wordBreak: "keep-all",
}));

const Header = styled(Paper)(() => ({
  height: 56,
  flexShrink: 0,
  width: "100%",
  paddingLeft: "16px",
  paddingRight: "16px",
  boxShadow: "none",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

const Content = styled(Box)(() => ({
  height: "100%",
  width: "100%",
  overflowX: "hidden",
  overflowY: "auto",
  position: "relative",
}));

const SideContent = styled(Paper)(({ theme }) => ({
  boxShadow: "none",
  borderRadius: 0,
  width: "30%",
  overflowX: "hidden",
  overflowY: "auto",
  padding: theme.spacing(2),
}));

const ScrollableArea = styled(Box)(() => ({
  overflowX: "hidden",
  overflowY: "auto",
  width: "800px",
  margin: "0 auto",
}));

const Footer = styled(Paper)(() => ({
  height: "56px",
  width: "100%",
  paddingLeft: "16px",
  paddingRight: "16px",
  boxShadow: "none",
}));

const FooterContentWrapper = styled(Box)(() => ({
  height: "100$",
  paddingTop: "12px",
  paddingBottom: "12px",
  margin: "0 auto",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
}));

const FooterContentSeparator = styled(Box)(() => ({
  width: "100%",
}));

const StyledButton = styled(Button)(() => ({
  textTransform: "none",
  whiteSpace: "nowrap",
  flexShrink: 0,
}));

const HelpWrapper = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

const StyledHelpLink = styled(Link)(({ theme }) => ({
  textTransform: "none",
  whiteSpace: "nowrap",
  color: theme.palette.text.secondary,
  flexShrink: 0,
  marginLeft: 4,
  fontSize: 14,
  textDecoration: "none",
}));

type Mode = "CREATE" | "EDIT";

export type LayoutProps = {
  mode: Mode;
  className?: string;
  helpLink: Pick<LinkProps, "href">;
  children: React.ReactNode;
  onSubmit: SubmitHandler<RemoteTemplateResourceEditorForm.CreateRemoteTemplateSchema>;
};

const Layout: React.FC<LayoutProps> = props => {
  const { title, methods } = useRemoteTemplateResourceEditorContext();
  const { t } = useTranslation();

  const helpButton: LinkProps = {
    ...props.helpLink,
    target: "_blank",
    children: t("ヘルプ"),
  };

  const submitTextMap: Record<Mode, string> = {
    CREATE: t("作成する"),
    EDIT: t("保存する"),
  };

  const submitButton: ButtonProps = {
    children: submitTextMap[props.mode],
    variant: "contained",
    color: "primary",
    onClick: () => {
      methods.handleSubmit(props.onSubmit)();
    },
  };

  return (
    <Wrapper className={props.className}>
      <Header>
        <Stack direction="row" alignItems="center" sx={{ overflow: "hidden" }}>
          <InsertDriveFileOutlinedIcon fontSize="small" sx={{ marginRight: "10px" }} color="secondary" />
          <StyledTitle>{title}</StyledTitle>
          <Tag color="textSecondary" fontSize={10} ml={1}>
            {t("テンプレート")}
          </Tag>
        </Stack>
        <Box sx={{ width: "40%" }}></Box>
        {/* Spacer */}
        <Box width="30%" />
      </Header>
      <Content>
        <Stack direction="row" width="100%" height="100%">
          <ScrollableArea width="70%">{props.children}</ScrollableArea>
          <SideContent>
            <TemplateFields />
          </SideContent>
        </Stack>
      </Content>
      <Footer>
        <FooterContentWrapper>
          <HelpWrapper>
            <HelpOutlineOutlinedIcon fontSize="small" />
            <StyledHelpLink {...helpButton} />
          </HelpWrapper>
          <FooterContentSeparator />
          <Stack spacing={2} direction="row">
            <StyledButton {...submitButton} />
          </Stack>
        </FooterContentWrapper>
      </Footer>
    </Wrapper>
  );
};

Layout.displayName = "Layout";

export default Layout;
