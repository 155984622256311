import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type BuySelectionsContainerProps = {};

const BuySelectionsContainer: React.FC<BuySelectionsContainerProps> = () => {
  const companySettingsProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Pages.CompanySettingsEditor {...companySettingsProps} />
    </ErrorBoundary>
  );
};

BuySelectionsContainer.displayName = "BuySelectionsContainer";

export default withErrorBoundary(BuySelectionsContainer, {});
