import { useTranslation } from "@hireroo/i18n";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import * as React from "react";

import { useAlgorithmAnswersFormContext } from "../../../Context";
import AlgorithmTestCaseHeader from "../parts/AlgorithmTestCaseHeader";
import DBTestCaseHeader from "../parts/DBTestCaseHeader";
import CorrectnessTableRows, { CorrectnessTableRowsProps } from "./CorrectnessTableRows";

export type CorrectnessTableProps = {
  tableRows: CorrectnessTableRowsProps[];
};

const CorrectnessTable: React.FC<CorrectnessTableProps> = props => {
  const { t } = useTranslation();
  const { signatureProps } = useAlgorithmAnswersFormContext();

  return (
    <TableContainer>
      <Table aria-label="collapsible table">
        {signatureProps.variant === "ALGORITHM" && <AlgorithmTestCaseHeader name={t("テストケース名")} signature={signatureProps.signature} />}
        {signatureProps.variant === "DATABASE" && <DBTestCaseHeader name={t("テストケース名")} />}
        <TableBody>
          {props.tableRows.map(tableRow => (
            <CorrectnessTableRows key={tableRow.id} {...tableRow} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

CorrectnessTable.displayName = "CorrectnessTable";

export default CorrectnessTable;
