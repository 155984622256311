import { LocalAudioTrack, LocalTrack, LocalVideoTrack, RemoteAudioTrack, RemoteTrack, RemoteVideoTrack } from "twilio-video";

import { RoomState, roomStates } from "./types";

export const isRemoteAudioTrack = (track: RemoteTrack): track is RemoteAudioTrack => {
  return track.kind === "audio";
};

export const isRemoteVideoTrack = (track: RemoteTrack): track is RemoteVideoTrack => {
  return track.kind === "video";
};

export const isLocalVideoTrack = (track: LocalTrack | undefined): track is LocalVideoTrack => {
  return track?.kind === "video";
};

export const isLocalAudioTrack = (track: LocalTrack | undefined): track is LocalAudioTrack => {
  return track?.kind === "audio";
};

export const isRoomState = (state: string): state is RoomState => {
  return roomStates.some(roomState => roomState === state);
};
