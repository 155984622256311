import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { useHelpCenterNavigate } from "@hireroo/router/hooks";

export type GenerateCustomErrorMessagePanelPropsArgs = {
  title: string;
  message: string;
};

export const useGenerateProps = (args: GenerateCustomErrorMessagePanelPropsArgs): Widget.ErrorPanelProps => {
  const { t } = useTranslation();
  const languageCode = useLanguageCode();
  const helpCenterNavigate = useHelpCenterNavigate(languageCode);

  return {
    name: args.title,
    message: args.message,
    links: [
      {
        children: t("ヘルプセンター"),
        onClick: () => {
          helpCenterNavigate("CANDIDATE_HELP_PAGE", { _blank: true });
        },
      },
    ],
  };
};
