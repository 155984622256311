import { useTranslation } from "@hireroo/i18n";
import { AlgorithmSignatureForm } from "@hireroo/validator";
import Add from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import DialogContentText from "@mui/material/DialogContentText";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, useFieldArray } from "react-hook-form";

import BaseDialog, { BaseDialogProps } from "../../primitive/BaseDialog/BaseDialog";
import AcceptButton from "../../primitive/Button/AcceptButton";
import { useDatabaseTableSignatureContext } from "./Context";
import ColumnInput from "./parts/ColumnInput";
import TableSchemaInput, { defaultColumn } from "./parts/TableSchemaInput";
import TableSignatureErrorMessage from "./parts/TableSignatureErrorMessage";

export { DatabaseTableSignatureProvider, type DatabaseTableSignatureProviderProps, useDatabaseTableSignatureContext } from "./Context";

export const defaultTable: AlgorithmSignatureForm.DatabaseTableSchemaType = {
  name: "",
  columns: [defaultColumn],
};
export type DatabaseTableSignatureProps = {
  TemplatePreview: React.ReactNode;
  breakingChangeDialog: Omit<BaseDialogProps, "title"> & { message?: string };
};

const DatabaseTableSignature: React.FC<DatabaseTableSignatureProps> = props => {
  const { t } = useTranslation();
  const { methods } = useDatabaseTableSignatureContext();

  const tablesField = useFieldArray({
    control: methods.control,
    name: "tables",
  });

  const outputColumnsField = useFieldArray({
    control: methods.control,
    name: "columns",
  });
  const addTable = () => {
    tablesField.append(defaultTable);
  };
  const addOutputColumn = () => {
    outputColumnsField.append(defaultColumn);
  };

  const removeTable = React.useCallback(
    (index: number) => {
      tablesField.remove(index);
    },
    [tablesField],
  );

  const removeOutputColumn = (index: number) => {
    outputColumnsField.remove(index);
  };

  const breakingChangeDialog: BaseDialogProps = {
    ...props.breakingChangeDialog,
    yesButton: {
      ...props.breakingChangeDialog.yesButton,
      children: t("はい"),
    },
    noButton: {
      ...props.breakingChangeDialog.noButton,
      children: t("いいえ"),
    },
    title: t("関数定義に破壊的変更が検知されました。"),
  };

  return (
    <FormProvider {...methods}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box display="flex" alignItems="center" mt={2}>
            <Box mr={2}>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                {t("スキーマ定義")}
              </Typography>
            </Box>
            <AcceptButton size="small" startIcon={<Add fontSize="small" />} onClick={addTable} variant="text">
              {t("テーブルを追加する").toString()}
            </AcceptButton>
          </Box>

          <TableSignatureErrorMessage name="tables" />

          <Box mt={2}>
            {tablesField.fields.map((field, i) => (
              <TableSchemaInput
                key={`table-schema-${i}-${field.id}`}
                tableIndex={i}
                showRemoveButton={tablesField.fields.length > 1}
                deleteTableButton={{
                  onClick: () => removeTable(i),
                }}
              />
            ))}
          </Box>

          <Box display="flex" alignItems="center" mt={2}>
            <Box>
              <Typography>{t("期待するクエリ出力結果のカラム定義")}</Typography>
            </Box>
          </Box>

          <TableSignatureErrorMessage name="columns" />

          <Box mt={2}>
            {outputColumnsField.fields.map((field, i) => (
              <ColumnInput
                key={`output-col-${i}-${field.id}`}
                columnIndex={i}
                deleteColumnButton={{
                  onClick: () => {
                    removeOutputColumn(i);
                  },
                  disabled: outputColumnsField.fields.length <= 1,
                }}
              />
            ))}
          </Box>

          <Box mt={2}>
            <AcceptButton size="small" startIcon={<Add fontSize="small" />} onClick={addOutputColumn} variant="text">
              {t("カラムを追加する").toString()}
            </AcceptButton>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box mt={2}>{props.TemplatePreview}</Box>
        </Grid>
      </Grid>
      <BaseDialog {...breakingChangeDialog}>
        <DialogContentText role="message">{props.breakingChangeDialog.message}</DialogContentText>
      </BaseDialog>
    </FormProvider>
  );
};

DatabaseTableSignature.displayName = "DatabaseTableSignature";

export default DatabaseTableSignature;
