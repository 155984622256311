import { App, Company } from "@hireroo/app-store/essential/employee";
import { GeneralSettings } from "@hireroo/app-store/widget/e/GeneralSettings";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import GeneralSettingsContainer, { CompanyProfileSettingsContainerProps } from "./Container";

export type CompanyProfileSettingsFetchContainerProps = {};

const CompanyProfileSettingsFetchContainer: React.FC<CompanyProfileSettingsFetchContainerProps> = _props => {
  const companyId = Company.useStrictActiveCompanyId();
  const appStatus = App.useStatus();
  const [result, refresh] = Graphql.useGetCompanyForGeneralSettingsQuery({
    variables: {
      companyId,
    },
    requestPolicy: "network-only",
    pause: appStatus !== "INITIALIZED",
  });
  const generalSettingsStateIsInitialized = GeneralSettings.useInitialized();

  React.useEffect(() => {
    if (result.data) {
      GeneralSettings.setCompany(result.data.company);
    }
  }, [result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (!generalSettingsStateIsInitialized || result.fetching || appStatus !== "INITIALIZED") {
    return <Widget.Loading kind="TOP" />;
  }
  const containerProps: CompanyProfileSettingsContainerProps = {};
  return (
    <ErrorBoundary>
      <GeneralSettingsContainer {...containerProps} />
    </ErrorBoundary>
  );
};

CompanyProfileSettingsFetchContainer.displayName = "CompanyProfileSettingsFetchContainer";

export default withErrorBoundary(CompanyProfileSettingsFetchContainer, {});
