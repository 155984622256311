import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type RemoteInterviewEvaluateHeaderContainerProps = {};

const RemoteInterviewEvaluateHeaderContainer: React.FC<RemoteInterviewEvaluateHeaderContainerProps> = () => {
  const remoteInterviewEvaluateHeaderProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.RemoteInterviewEvaluateHeader {...remoteInterviewEvaluateHeaderProps} />
    </ErrorBoundary>
  );
};

RemoteInterviewEvaluateHeaderContainer.displayName = "RemoteInterviewEvaluateHeaderContainer";

export default withErrorBoundary(RemoteInterviewEvaluateHeaderContainer, {});
