import { App } from "@hireroo/app-store/essential/employee";
import { AssessmentsIdDetailStore } from "@hireroo/app-store/page/e/assessments_id_detail";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import ErrorPanelContainer from "../../../../../../widget/v2/e/ErrorPanel/Container";
import AssessmentDetailForEmployeeContainer, { AssessmentDetailForEmployeeContainerProps } from "./Container";

export type AssessmentDetailForEmployeeFetchContainerProps = {
  assessmentId: string;
};

const AssessmentDetailForEmployeeFetchContainer: React.FC<AssessmentDetailForEmployeeFetchContainerProps> = props => {
  const appStatus = App.useStatus();
  const { assessmentId } = props;
  const initialized = AssessmentsIdDetailStore.useInitialized();
  const offset = AssessmentsIdDetailStore.useListBestOffset();

  const [result] = Graphql.useGetAssessmentForAssessmentsIdDetailEmployeeQuery({
    variables: {
      assessmentId: assessmentId,
      withCount: true,
      size: 30,
      offset: offset,
      isDescending: true,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "cache-and-network",
  });
  React.useEffect(() => {
    if (result.data?.assessment) {
      AssessmentsIdDetailStore.updateAssessment(result.data.assessment);
    }
    if (result.data?.listBestExamsByAssessmentId) {
      AssessmentsIdDetailStore.initializeListBestExams(
        result.data.listBestExamsByAssessmentId.exams,
        result.data.listBestExamsByAssessmentId.count,
        result.data.listBestExamsByAssessmentId.hasNext,
      );
    }
  }, [result.data]);

  if (result.error) {
    return <ErrorPanelContainer mode="PERCENTAGE" graphqlErrors={result.error.graphQLErrors} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: AssessmentDetailForEmployeeContainerProps = {
    assessmentId: assessmentId,
  };

  return <AssessmentDetailForEmployeeContainer {...containerProps} />;
};

AssessmentDetailForEmployeeFetchContainer.displayName = "AssessmentDetailForEmployeeFetchContainer";

export default withErrorBoundary(AssessmentDetailForEmployeeFetchContainer, {});
