import { styled } from "@mui/material/styles";
import Tab, { TabProps } from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as React from "react";

import Link from "../../primitive/Link/Link";

const HEIGHT = 42;

const StyledHeaderTabs = styled(Tabs)(({ theme }) => ({
  "&.MuiTabs-root": {
    minHeight: HEIGHT,
  },
  ".MuiTabs-indicator": {
    backgroundColor: theme.palette.secondary.main,
  },
  borderBottom: `1px solid ${theme.palette["Secondary/Shades"].p12}`,
}));

const StyledHeaderLink = styled(Link)(() => ({
  width: "100%",
  padding: "9px 16px",
  display: "block",
  height: HEIGHT,
  color: "inherit",
  fontWeight: "inherit",
}));

const StyledHeaderTab: React.FC<TabProps> = styled(Tab)(({ theme }) => ({
  padding: 0,
  "&.MuiTab-root": {
    minHeight: HEIGHT,
    color: theme.palette.text.secondary,
  },
  "&.Mui-selected": {
    color: theme.palette.secondary.main,
    fontWeight: "bold",
  },
}));

type MenuItemProps = {
  id: string;
  label: string;
  href: string;
  onClick?: () => void;
};

export type UserOrCompanySettingsNavigatorProps = {
  active: string;
  items: MenuItemProps[];
};

const UserOrCompanySettingsNavigator: React.FC<UserOrCompanySettingsNavigatorProps> = props => {
  const [value, setValue] = React.useState<string>(props.active);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <StyledHeaderTabs value={value} onChange={handleChange} color="secondary">
      {props.items.map(item => {
        const tabProps: TabProps = {
          value: item.id,
          label: (
            <StyledHeaderLink href={item.href} onClick={item.onClick} underline="none">
              {item.label}
            </StyledHeaderLink>
          ),
          color: "inherit",
        };
        return <StyledHeaderTab key={item.id} {...tabProps} />;
      })}
    </StyledHeaderTabs>
  );
};

UserOrCompanySettingsNavigator.displayName = "UserOrCompanySettingsNavigator";

export default UserOrCompanySettingsNavigator;
