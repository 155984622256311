import Box, { BoxProps } from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import { useTheme } from "@mui/material/styles";
import Typography, { TypographyProps } from "@mui/material/Typography";
import * as React from "react";

export type FeedbackItemProps = {
  id: string;
  title: string;
  evaluation: {
    numStars: number;
    comment: string;
  };
};

const FeedbackItem: React.FC<FeedbackItemProps> = props => {
  const theme = useTheme();
  const commentProps: TypographyProps = {
    sx: {
      whiteSpace: "pre-line",
      fontSize: "12px",
      color: theme.palette.text.secondary,
    },
  };
  const itemBoxProps: BoxProps = {
    sx: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      bgcolor: theme.palette.grey[800],
      borderRadius: "12px",
      p: 1.5,
    },
  };

  return (
    <Box {...itemBoxProps}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography fontWeight={"bold"}>{props.title}</Typography>
        <Box display={"flex"} flexDirection={"row"}>
          <Rating readOnly max={4} value={props.evaluation.numStars} size={"small"} />
        </Box>
      </Box>
      <Box mt={1.5}>
        <Typography {...commentProps}>{props.evaluation.comment}</Typography>
      </Box>
    </Box>
  );
};

FeedbackItem.displayName = "FeedbackItem";

export default FeedbackItem;
