import * as React from "react";

import MultiLineSearchForm, { MultiLineSearchFormProps } from "./parts/MultiLineSearchForm/MultiLineSearchForm";
import SingleLineSearchForm, { SingleLineSearchFormProps } from "./parts/SingleLineSearchForm/SingleLineSearchForm";
import { SearchFormProvider } from "./PrivateContext";

type SearchFormMultiLineModeProps = MultiLineSearchFormProps & {
  kind: "MULTI_LINE";
};

type SearchFormOneLineModeProps = SingleLineSearchFormProps & {
  kind: "SINGLE_LINE";
};

export type SearchFormProps = SearchFormMultiLineModeProps | SearchFormOneLineModeProps;

const SearchForm: React.FC<SearchFormProps> = props => {
  if (props.kind === "SINGLE_LINE") {
    const { kind: _, ...rest } = props;
    return (
      <SearchFormProvider>
        <SingleLineSearchForm {...rest} />
      </SearchFormProvider>
    );
  }
  const { kind: _, ...rest } = props;
  return (
    <SearchFormProvider>
      <MultiLineSearchForm {...rest} />
    </SearchFormProvider>
  );
};

SearchForm.displayName = "SearchForm";

export default SearchForm;
