import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import * as React from "react";

import TitleWithScoreBar, { TitleWithScoreBarProps } from "../../../../modules/TitleWithScoreBar/TitleWithScoreBar";
import SystemDesignScoringItem, { SystemDesignScoringItemProps } from "./SystemDesignScoringItem";

const AccordionWrapper = styled(Box)(({ theme }) => ({
  borderRadius: "8px",
  border: "1px solid",
  borderColor: theme.palette.divider,
}));

type Item = Omit<SystemDesignScoringItemProps, "forceExpanded">;

export type SystemDesignScoringGroupProps = {
  scrollTargetId: string;
  mode: SystemDesignScoringItemProps["mode"];
  titleWithScoreBar: TitleWithScoreBarProps;
  items: Item[];
  tooltip: Pick<TooltipProps, "title">;
  forceExpanded: boolean;
};

const SystemDesignScoringGroup: React.FC<SystemDesignScoringGroupProps> = props => {
  return (
    <Box id={props.scrollTargetId}>
      <Box display="flex" alignItems="center" mb={1.5}>
        <TitleWithScoreBar {...props.titleWithScoreBar} />
        <Box ml={1} sx={{ lineHeight: 0.5 }}>
          <Tooltip {...props.tooltip}>
            <InfoOutlined fontSize="small" color="secondary" />
          </Tooltip>
        </Box>
      </Box>
      <AccordionWrapper>
        {props.items.map((item, index) => (
          <SystemDesignScoringItem
            key={`scoring-item-${index}`}
            {...item}
            mode={item.mode === "UPDATED" ? "UPDATED" : props.mode}
            forceExpanded={props.forceExpanded}
          />
        ))}
      </AccordionWrapper>
    </Box>
  );
};

SystemDesignScoringGroup.displayName = "SystemDesignScoringGroup";

export default SystemDesignScoringGroup;
