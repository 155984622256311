import { proxy } from "valtio";

import type { State } from "./types";

export const state = proxy<State>({
  initialized: false,
  entityKeys: [],
  entities: {},
  currentSelectedEntityKey: null,
  loadingStatus: "NONE",
  initializedDate: new Date(),
});
