import { SystemDesignTestReport } from "@hireroo/app-store/view-domain/SystemDesignTestReport";
import { useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import * as React from "react";

import { useGenerateIpAddressSectionProps } from "./useGenerateIpAddressSectionProps";

type CheatDetectionSection = Widget.SystemDesignTestReportProps["cheatDetectionSection"];

export type GenerateCheatDetectionSectionPropsArgs = {
  uniqueKey: SystemDesignTestReport.UniqueKey;
  systemDesignId: number;
  canShowCheatDetectionSection: boolean;
  canShowStatistic: boolean;
};
export const useGenerateCheatDetectionSectionProps = (args: GenerateCheatDetectionSectionPropsArgs): CheatDetectionSection => {
  const hooks = SystemDesignTestReport.useCreateSystemDesignHooks(args.systemDesignId);
  const submission = hooks.useCurrentSubmission();
  const { t } = useTranslation();
  const statistics = hooks.useStatistics();
  const ipAddressSection = useGenerateIpAddressSectionProps({ systemDesignId: args.systemDesignId });

  return React.useMemo((): CheatDetectionSection => {
    if (!args.canShowCheatDetectionSection || !submission) {
      return;
    }
    return {
      numTabEvents: {
        value: `${submission.numTabEvents} ${t("回")}`,
        icon: {
          key: (submission.numTabEvents ?? 0) <= statistics.numTabEventAvg ? "CHECK" : "WARNING",
          title: `${t("平均離脱回数")} ${statistics.numTabEventAvg}`,
        },
      },
      numAccessEvents: {
        value: `${submission.numAccessEvents} ${t("回")}`,
        icon: {
          /** expected number of access is 1, otherwise warn */
          key: submission.numAccessEvents === 1 ? "CHECK" : "WARNING",
          title: t("IPアドレス検知回数"),
        },
      },
      ipAddressSection: ipAddressSection,
      canShowTooltip: args.canShowStatistic,
    };
  }, [args.canShowCheatDetectionSection, args.canShowStatistic, ipAddressSection, statistics.numTabEventAvg, submission, t]);
};
