import { TextField, TextFieldProps } from "@mui/material";
import * as React from "react";
import { Control, useController } from "react-hook-form";

export type InputControlTextFieldProps = TextFieldProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any>;
  ignoreErrorMessage?: boolean;
};

const InputControlTextField: React.FC<InputControlTextFieldProps & { name: string }> = ({ defaultValue, ignoreErrorMessage, ...props }) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name: props.name,
    control: props.control,
    defaultValue: defaultValue,
  });

  const helperText = React.useMemo(() => {
    if (ignoreErrorMessage && error?.message) {
      return props.helperText;
    }

    return error?.message ?? props.helperText;
  }, [error?.message, ignoreErrorMessage, props.helperText]);

  return (
    <TextField
      {...props}
      onChange={event => {
        props.onChange?.(event);
        return field.onChange(event);
      }}
      value={field.value}
      inputRef={field.ref}
      size="small"
      error={!ignoreErrorMessage ? Boolean(error?.message) : false}
      helperText={helperText}
    />
  );
};

InputControlTextField.displayName = "InputControlTextField";

export default InputControlTextField;
