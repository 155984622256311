import { DEFAULT_PERFORMANCE_TEST_LIMIT_MILLISECONDS } from "@hireroo/app-definition/algorithm";
import { safeJsonParse } from "@hireroo/app-helper/parser";
import { AlgorithmResourceEditor } from "@hireroo/app-store/view-domain/AlgorithmResourceEditor";
import { AlgorithmTemplatePreview } from "@hireroo/app-store/widget/e/AlgorithmTemplatePreview";
import { languageMapsForDisplay } from "@hireroo/challenge/definition";
import { Pages } from "@hireroo/presentation/legacy";
import { AlgorithmAnswerForm, AlgorithmHintForm, AlgorithmSignatureForm, AlgorithmTestCaseForm } from "@hireroo/validator";
import * as React from "react";

import {
  generateDefaultAlgorithmCorrectnessTC,
  generateDefaultAlgorithmPerformanceTC,
  generateDefaultClassCorrectnessTC,
  generateDefaultClassPerformanceTC,
  generateDefaultDatabaseCorrectnessTC,
  generateDefaultDatabasePerformanceTC,
} from "./privateTestCaseHelper";

type QuestionType = questionDetailDefaultValues["questions"][0];
type questionDetailDefaultValues = Pages.AlgorithmResourceEditorProviderProps["questionDetailProvider"]["defaultValues"];

export const useQuestionDetailDefaultValues = (): questionDetailDefaultValues => {
  const variant = AlgorithmResourceEditor.useVariant();
  const question = AlgorithmResourceEditor.useQuestion();
  const currentLanguage = AlgorithmResourceEditor.useInitialLanguage();

  const defaultQuestions: QuestionType[] = React.useMemo(() => {
    if (!question) {
      return [
        {
          title: "",
          description: "",
          language: currentLanguage,
        },
      ];
    }
    const qFields: QuestionType[] = [];
    if (question.titleJa && question.descriptionJa) {
      const qField: QuestionType = {
        language: "ja",
        title: question.titleJa,
        description: question.descriptionJa,
      };
      qFields.push(qField);
    }

    if (question.titleEn && question.descriptionEn) {
      const qField: QuestionType = {
        language: "en",
        title: question.titleEn,
        description: question.descriptionEn,
      };
      qFields.push(qField);
    }

    return qFields;
  }, [currentLanguage, question]);

  return React.useMemo(
    () => ({
      isPublic: question ? !question.isPrivate : false,
      variant: variant,
      timeLimit: question && question.timeLimitSeconds ? question.timeLimitSeconds / 60 : 30,
      difficulty: question?.difficulty ?? "MEDIUM",
      questions: defaultQuestions,
    }),
    [defaultQuestions, question, variant],
  );
};

export const useAlgorithmSignature = (): AlgorithmSignatureForm.AlgorithmSignatureSchemaType => {
  const question = AlgorithmResourceEditor.useQuestion();
  const signatureSchema = AlgorithmSignatureForm.useAlgorithmSignatureSchema();

  return React.useMemo(() => {
    if (question?.signature) {
      const result = signatureSchema.safeParse(safeJsonParse(question.signature));
      if (result.success) {
        return result.data;
      }
    }
    return {
      function: "solution",
      inputs: [
        {
          name: "",
          type: "string",
        },
      ],
      outputs: [{ type: "string" }],
    };
  }, [question?.signature, signatureSchema]);
};

type FunctionDefinitionDefaultValues = Pages.AlgorithmResourceEditorProviderProps["functionDefinitionProvider"]["defaultValues"];

export const useFunctionDefinitionDefaultValues = (): FunctionDefinitionDefaultValues => {
  const defaultSignature = useAlgorithmSignature();

  return {
    inputs: defaultSignature.inputs,
    outputType: defaultSignature.outputs[0].type,
  };
};

export const useDatabaseSignature = (): AlgorithmSignatureForm.DatabaseSignatureSchemaType => {
  const question = AlgorithmResourceEditor.useQuestion();
  const signatureSchema = AlgorithmSignatureForm.useDatabaseSignatureSchema();

  return React.useMemo(() => {
    if (question?.signature) {
      const result = signatureSchema.safeParse(safeJsonParse(question.signature));
      if (result.success) {
        return result.data;
      }
    }

    return {
      tables: [
        {
          name: "",
          columns: [
            {
              name: "",
              type: "string",
            },
          ],
        },
      ],
      columns: [
        {
          name: "",
          type: "string",
        },
      ],
    };
  }, [question?.signature, signatureSchema]);
};
type TableSignatureDefaultValues = Pages.AlgorithmResourceEditorProviderProps["tableSignature"]["defaultValues"];
export const useTableSignatureDefaultValues = (): TableSignatureDefaultValues => {
  const defaultSignature = useDatabaseSignature();

  return {
    tables: defaultSignature.tables,
    columns: defaultSignature.columns,
  };
};

export const useClassSignature = (): AlgorithmSignatureForm.ClassSignatureSchemaType => {
  const question = AlgorithmResourceEditor.useQuestion();

  const signatureSchema = AlgorithmSignatureForm.useClassSignatureSchema();

  return React.useMemo(() => {
    if (question?.signature) {
      const result = signatureSchema.safeParse(safeJsonParse(question.signature));
      if (result.success) {
        return result.data;
      }
    }
    return {
      class: "Example",
      constructor: {
        inputs: [
          {
            name: "",
            type: "string",
          },
        ],
        outputs: [{ type: "string" }],
      },
      methods: [
        {
          name: "",
          inputs: [
            {
              name: "",
              type: "string",
            },
          ],
          outputs: [{ type: "string" }],
        },
      ],
    };
  }, [question?.signature, signatureSchema]);
};

type SelectedLanguages = AlgorithmTestCaseForm.AlgorithmTestCaseFormSchema["selectedLanguages"];
type TestCasesDefaultValues = Pages.AlgorithmResourceEditorProviderProps["testCasesFormProvider"]["defaultValues"];
export const useTestCasesDefaultValues = (): TestCasesDefaultValues => {
  const question = AlgorithmResourceEditor.useQuestion();
  const variant = AlgorithmResourceEditor.useVariant();
  const lang = AlgorithmResourceEditor.useInitialLanguage();
  const correctness = AlgorithmTestCaseForm.useCorrectnessTestCasesFromJsonDangerous();
  const performance = AlgorithmTestCaseForm.usePerformanceTestCasesFromJsonDangerous();
  const algorithmSignature = useAlgorithmSignature();
  const databaseSignature = useDatabaseSignature();
  const classSignature = useClassSignature();

  const defaultPerformanceTCMap: Record<AlgorithmResourceEditor.QuestionVariant, AlgorithmTestCaseForm.PerformanceTestCaseSchema> =
    React.useMemo(() => {
      return {
        ALGORITHM: generateDefaultAlgorithmPerformanceTC(algorithmSignature),
        DATABASE: generateDefaultDatabasePerformanceTC(databaseSignature),
        CLASS: generateDefaultClassPerformanceTC(classSignature),
      };
    }, [algorithmSignature, classSignature, databaseSignature]);

  const defaultPerformanceTCs = React.useMemo((): AlgorithmTestCaseForm.AlgorithmTestCaseFormSchema["performanceTestCases"] => {
    if (question?.performanceTestCase && question?.signature) {
      const result = performance.safeParse(safeJsonParse(question.performanceTestCase));
      if (result.success) {
        return {
          data: result.data,
          timeLimit: question.performanceTimeLimitMilliSeconds,
        };
      }
    }

    return {
      data: [defaultPerformanceTCMap[variant]],
      timeLimit: DEFAULT_PERFORMANCE_TEST_LIMIT_MILLISECONDS,
    };
  }, [
    question?.performanceTestCase,
    question?.signature,
    question?.performanceTimeLimitMilliSeconds,
    defaultPerformanceTCMap,
    variant,
    performance,
  ]);

  const defaultCorrectnessTCMap: Record<AlgorithmResourceEditor.QuestionVariant, AlgorithmTestCaseForm.CorrectnessTestCaseSchema> =
    React.useMemo(() => {
      return {
        ALGORITHM: generateDefaultAlgorithmCorrectnessTC(algorithmSignature),
        DATABASE: generateDefaultDatabaseCorrectnessTC(databaseSignature),
        CLASS: generateDefaultClassCorrectnessTC(classSignature),
      };
    }, [algorithmSignature, classSignature, databaseSignature]);

  const defaultCorrectnessTCs = React.useMemo((): AlgorithmTestCaseForm.AlgorithmTestCaseFormSchema["correctnessTestCases"] => {
    if (question?.correctnessTestCase && question?.signature) {
      const result = correctness.safeParse(safeJsonParse(question.correctnessTestCase));
      if (result.success) {
        return {
          data: result.data,
        };
      }
    }

    return {
      data: [defaultCorrectnessTCMap[variant]],
    };
  }, [question?.correctnessTestCase, question?.signature, correctness, defaultCorrectnessTCMap, variant]);

  const defaultSelectedLanguages = React.useMemo((): AlgorithmTestCaseForm.AlgorithmTestCaseFormSchema["selectedLanguages"] => {
    if (question?.correctnessTestCase) {
      const result = correctness.safeParse(safeJsonParse(question.correctnessTestCase));
      if (result.success) {
        const parsed = result.data;
        const selectedLanguages: SelectedLanguages = [];
        if (parsed.at(0)?.title_ja !== "" && parsed.at(0)?.description_ja !== "") {
          selectedLanguages.push({ value: "ja" });
        }

        if (parsed.at(0)?.title_en !== "" && parsed.at(0)?.description_en !== "") {
          selectedLanguages.push({ value: "en" });
        }
        if (selectedLanguages.length > 0) {
          return selectedLanguages;
        }
      }
    }
    return [
      {
        value: lang,
      },
    ];
  }, [correctness, lang, question?.correctnessTestCase]);

  return {
    performanceTestCases: defaultPerformanceTCs,
    correctnessTestCases: defaultCorrectnessTCs,
    selectedLanguages: defaultSelectedLanguages,
  };
};

type AnswersDefaultValues = Pages.AlgorithmResourceEditorProviderProps["answersFormProvider"]["defaultValues"];
export const useAnswersDefaultValues = (): AnswersDefaultValues => {
  const question = AlgorithmResourceEditor.useQuestion();
  const lang = AlgorithmResourceEditor.useInitialLanguage();
  const variant = AlgorithmResourceEditor.useVariant();
  const templateCode = AlgorithmTemplatePreview.useTemplate();
  const defaultLanguagesMap = languageMapsForDisplay[variant];
  const defaultRuntime = defaultLanguagesMap[0].value as AlgorithmTemplatePreview.Runtime;

  return React.useMemo((): AnswersDefaultValues => {
    if (question?.answers && question.answers.length > 0) {
      return {
        answers: question.answers.map((answer): AnswersDefaultValues["answers"][0] => {
          const contents: AlgorithmAnswerForm.AnswersContentSchema[] = [];
          if (answer.titleJa && answer.descriptionJa) {
            const content: AlgorithmAnswerForm.AnswersContentSchema = {
              title: answer.titleJa,
              description: answer.descriptionJa,
              language: "ja",
            };
            contents.push(content);
          }

          if (answer.titleEn && answer.descriptionEn) {
            const content: AlgorithmAnswerForm.AnswersContentSchema = {
              title: answer.titleEn,
              description: answer.descriptionEn,
              language: "en",
            };
            contents.push(content);
          }
          return {
            id: answer.id,
            label: answer.label,
            runtimeComplexity: answer.runtimeComplexity,
            spaceComplexity: answer.spaceComplexity,
            contents: contents,
            answerRuntimes: answer.answerRuntimes.map(answerRuntime => {
              return {
                id: answerRuntime.id,
                runtime: answerRuntime.runtime,
                codeBodies: { [answerRuntime.runtime]: answerRuntime.codeBody },
              };
            }),
          };
        }),
      };
    }
    return {
      answers: [
        {
          label: "OPTIMAL",
          runtimeComplexity: "",
          spaceComplexity: "",
          contents: [
            {
              title: "",
              description: "",
              language: lang,
            },
          ],
          answerRuntimes: [
            {
              runtime: defaultRuntime,
              codeBodies: { [defaultRuntime]: templateCode[defaultRuntime] ?? "" },
            },
          ],
        },
      ],
    };
  }, [defaultRuntime, lang, question?.answers, templateCode]);
};

type HintsDefaultValues = Pages.AlgorithmResourceEditorProviderProps["hintFormProvider"]["defaultValues"];
type HintContent = AlgorithmHintForm.AlgorithmHintsFormSchema["hints"][0]["contents"][0];

export const useHintsDefaultValues = (): HintsDefaultValues => {
  const question = AlgorithmResourceEditor.useQuestion();
  const lang = AlgorithmResourceEditor.useInitialLanguage();

  return React.useMemo(() => {
    if (question?.hints && question.hints.length > 0) {
      const hints = question.hints.map((hint, index) => {
        const contents: HintContent[] = [];
        if (hint.descriptionJa) {
          const content: HintContent = {
            description: hint.descriptionJa,
            language: "ja",
          };
          contents.push(content);
        }

        if (hint.descriptionEn) {
          const content: HintContent = {
            description: hint.descriptionEn,
            language: "en",
          };
          contents.push(content);
        }
        return {
          id: question.hints[index]?.id ?? 0,
          contents: contents,
        };
      });
      return {
        newVersion: question.version.split("-")[0],
        hints: hints,
      };
    }
    return {
      newVersion: question?.version?.split("-")[0] ?? "",
      hints: [
        {
          contents: [
            {
              description: "",
              language: lang,
            },
          ],
        },
      ],
    };
  }, [lang, question?.hints, question?.version]);
};
