import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";

import ChatGPTPlaybackRightSidePanel, {
  ChatGPTPlaybackRightSidePanelProps,
} from "../../ms-components/Zeus/ChatGPTPlaybackRightSidePanel/ChatGPTPlaybackRightSidePanel";
import WebSearchPlaybackRightSidePanel, {
  WebSearchPlaybackRightSidePanelProps,
} from "../../ms-components/Zeus/WebSearchPlaybackRightSidePanel/WebSearchPlaybackRightSidePanel";
import Tab from "../../primitive/Tab/Tab";
import Tabs from "../../primitive/Tabs/Tabs";

const TABS_HEIGHT = 36;

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette["Secondary/Shades"].p16,
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

const StyledTabs = styled(Tabs)(() => ({
  height: TABS_HEIGHT,
}));

const StyledTab = styled(Tab)(() => ({
  textTransform: "none",
}));

const StyledContentBox = styled(Box)(() => ({
  height: `calc(100% - ${TABS_HEIGHT}px)`,
  overflowX: "hidden",
  overflowY: "auto",
}));

type Mode = "WEB_SEARCH" | "CHAT_GPT";

export type ReportChallengePlaybackRightSidePanelProps = {
  webSearchPlaybackRightSidePanel?: WebSearchPlaybackRightSidePanelProps;
  chatGPTPlaybackRightSidePanel?: ChatGPTPlaybackRightSidePanelProps;
  mode: Mode;
  onChangeMode: (event: React.SyntheticEvent, newMode: Mode) => void;
  disabledTab: boolean;
  gptModel: "GPT-3.5" | "GPT-4o";
};

const ReportChallengePlaybackRightSidePanel: React.FC<ReportChallengePlaybackRightSidePanelProps> = props => {
  const { webSearchPlaybackRightSidePanel, chatGPTPlaybackRightSidePanel, mode, onChangeMode, disabledTab } = props;
  const { t } = useTranslation();

  return (
    <StyledBox>
      <StyledTabs value={mode} onChange={onChangeMode} variant="fullWidth">
        {webSearchPlaybackRightSidePanel && <StyledTab label={t("Google検索")} value={"WEB_SEARCH"} disabled={disabledTab} />}
        {chatGPTPlaybackRightSidePanel && <StyledTab label={`ChatGPT (${props.gptModel})`} value={"CHAT_GPT"} disabled={disabledTab} />}
      </StyledTabs>
      <StyledContentBox>
        {webSearchPlaybackRightSidePanel && (
          <WebSearchPlaybackRightSidePanel {...{ ...webSearchPlaybackRightSidePanel, hide: mode === "CHAT_GPT" }} />
        )}
        {chatGPTPlaybackRightSidePanel && (
          <ChatGPTPlaybackRightSidePanel {...{ ...chatGPTPlaybackRightSidePanel, hide: mode === "WEB_SEARCH" }} />
        )}
      </StyledContentBox>
    </StyledBox>
  );
};

ReportChallengePlaybackRightSidePanel.displayName = "ReportChallengePlaybackRightSidePanel";

export default ReportChallengePlaybackRightSidePanel;
