const statusCodeMessage: Record<number, string> = {
  400: "BadRequestError",
  401: "UnauthorizedError",
  403: "ForbiddenError",
  404: "NotFoundError",
  500: "InternalServerError",
  503: "ServiceUnavailableError",
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ErrorResponseToJSONValidator<T> = (obj: any) => obj is T;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class ErrorResponse extends Error {
  constructor(private res: Response) {
    const message = statusCodeMessage[res.status];
    super(message);
    this.name = "ErrorResponse";
  }

  public get status() {
    return this.res.status;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async json<U = any>(validate?: ErrorResponseToJSONValidator<U>): Promise<U> {
    const data = await this.res.json();
    if (validate?.(data)) {
      return data;
    }
    return data;
  }
}
