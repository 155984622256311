import { ScreeningInviteeList } from "@hireroo/app-store/widget/e/ScreeningInviteeList";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useQueryParams } from "./privateHelper";
import * as Subscriber from "./Subscriber";
import { useGenerateProps } from "./useGenerateProps";

export type ScreeningInviteeListForDetailContainerProps = {};

const ScreeningInviteeListForDetailContainer: React.FC<ScreeningInviteeListForDetailContainerProps> = () => {
  const screeningInviteeListForDetailProps = useGenerateProps({});
  const queryParams = useQueryParams();
  React.useEffect(() => {
    ScreeningInviteeList.setQueryParams(queryParams);
  }, [queryParams]);
  React.useEffect(() => {
    const stop = Subscriber.startSubscribeListParams();
    return () => {
      stop();
    };
  }, []);
  return (
    <ErrorBoundary>
      <Widget.ScreeningInviteeListForDetail {...screeningInviteeListForDetailProps} />
    </ErrorBoundary>
  );
};

ScreeningInviteeListForDetailContainer.displayName = "ScreeningInviteeListForDetailContainer";

export default withErrorBoundary(ScreeningInviteeListForDetailContainer, {});
