import InfoOutlined from "@mui/icons-material/InfoOutlined";
import LiveHelpOutlined from "@mui/icons-material/LiveHelpOutlined";
import MessageIcon from "@mui/icons-material/Message";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

const IconMap = {
  LIVE_HELP: <LiveHelpOutlined color="disabled" />,
  INFO: <InfoOutlined color="disabled" />,
  MESSAGE: <MessageIcon color="disabled" />,
} satisfies Record<string, React.ReactElement>;
type Icon = keyof typeof IconMap;

export type MenuItemWithTooltipProps = {
  tooltip?: string;
  icon?: Icon;
  text: string;
} & Pick<MenuItemProps, "value" | "onClick" | "disabled" | "divider" | "role">;

const MenuItemWithTooltip: React.FC<MenuItemWithTooltipProps> = props => {
  const { tooltip, icon, text, ...menuItemProps } = props;
  const Item = (
    <MenuItem {...menuItemProps}>
      {icon && IconMap[icon]}
      <Typography ml={icon ? 1 : undefined}>{text}</Typography>
    </MenuItem>
  );

  if (menuItemProps.disabled && tooltip) {
    return (
      <Tooltip title={tooltip} placement="left-end">
        <span>{Item}</span>
      </Tooltip>
    );
  }
  if (tooltip) {
    return (
      <Tooltip title={tooltip} placement="left-end">
        {Item}
      </Tooltip>
    );
  }
  return Item;
};

MenuItemWithTooltip.displayName = "MenuItemWithTooltip";

export default MenuItemWithTooltip;
