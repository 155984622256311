import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import Link from "../../primitive/Link/Link";

const StyledBox = styled(Box)(({ theme }) => ({
  paddingRight: theme.spacing(1),
  borderRight: `1px solid ${theme.palette.divider}`,
}));

export type CandidateFooterProps = {
  tosUrl: string;
  privacyUrl: string;
  helpCenterUrl: string;
};

const Wrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 24px;
  box-sizing: border-box;
  margin-right: 6px;
`;

const CandidateFooter: React.FC<CandidateFooterProps> = props => {
  const year = new Date().getFullYear();
  const { t } = useTranslation();

  return (
    <Wrapper borderTop={1} borderColor="grey.800">
      <StyledBox>
        <Link href={props.tosUrl} target="_blank" underline="hover" color="secondary">
          <Typography display="inline" variant="caption" color="secondary">
            {t("利用規約")}
          </Typography>
        </Link>
      </StyledBox>
      <StyledBox pl={1}>
        <Link href={props.privacyUrl} target="_blank" underline="hover" color="secondary">
          <Typography display="inline" variant="caption" color="secondary">
            {t("プライバシーポリシー")}
          </Typography>
        </Link>
      </StyledBox>
      <StyledBox pl={1}>
        <Link href={props.helpCenterUrl} target="_blank" underline="hover" color="secondary">
          {/* ClassName is used in Tutorial */}
          <Typography className="footer-help-center" display="inline" variant="caption" color="secondary">
            {t("ヘルプセンター")}
          </Typography>
        </Link>
      </StyledBox>

      <Box pl={1}>
        <Typography role="copyright" variant="caption" color="textSecondary">
          Copyright © HireRoo {year}. All Rights Reserved.
        </Typography>
      </Box>
    </Wrapper>
  );
};

CandidateFooter.displayName = "CandidateFooter";

export default CandidateFooter;
