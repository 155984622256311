import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import * as echarts from "echarts";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";

import { createChart, CreateChartArgs } from "./privateHelper";

const StyledBox = styled(Box)(() => ({
  width: "100%",
  height: "100%",
}));

export type EventFrequencyTimelineProps = {
  options: CreateChartArgs["options"];
};

const EventFrequencyTimeline: React.FC<EventFrequencyTimelineProps> = props => {
  const element = React.createRef<HTMLDivElement>();
  const chart = React.useRef<echarts.ECharts | null>(null);
  const theme = useTheme();
  useResizeDetector({
    targetRef: element,
    onResize: () => {
      chart.current?.resize();
    },
  });
  const createChartArgs = React.useMemo((): Omit<CreateChartArgs, "dom"> => {
    return {
      options: props.options,
    };
  }, [props.options]);
  React.useEffect(() => {
    if (element.current) {
      chart.current = createChart({
        ...createChartArgs,
        dom: element.current,
      });
    }
    return () => {
      chart.current?.clear();
    };
  }, [element, createChartArgs]);
  React.useEffect(() => {
    chart.current?.setOption({
      dark: theme.palette.mode === "dark",
    });
  }, [chart, theme.palette.mode]);
  return <StyledBox ref={element} />;
};

EventFrequencyTimeline.displayName = "EventFrequencyTimeline";

export default EventFrequencyTimeline;
