import { BehavioralEvent } from "@hireroo/app-helper/playback";
import { ref } from "valtio";

import * as Def from "./definition";
import { isBehavioralEvent } from "./privateHelper";
import { state } from "./State";
import type * as Types from "./types";

export type InitializeArgs = {
  playbackManager: Types.PlaybackManager;
  submission: Types.Submission;
  sessionIds: Types.ChallengeSessionIds;
  appealMessage: string | null;
  question: Types.AlgorithmQuestion;
};

export const initialize = (args: InitializeArgs): void => {
  const { playbackManager, submission, sessionIds, appealMessage, question } = args;
  state.playbackManager = ref(playbackManager);
  /**
   * Since Playback is in its final state when initially displayed, specify the last Revision Index.
   */
  state.sliderValue = playbackManager.lastTickIndex;

  state.submission = submission;

  state.sessionIds = sessionIds;

  state.question = question;

  state.appealMessage = appealMessage;

  const behavioralEventMatrix = playbackManager.ticks.map((tick): BehavioralEvent[] => {
    return tick.events.filter(isBehavioralEvent);
  });

  state.playbackSettings = {
    enabledCopyAndPasteDetection: playbackManager.ticks.some(tick => tick.events.some(event => event.kind === "EDITOR_PASTE")),
    enabledBehavioralControl: behavioralEventMatrix.some(behavioralEvents => behavioralEvents.length > 0),
  };

  const lastEventKind = behavioralEventMatrix.at(-1)?.at(-1)?.kind;
  state.rightSidePanelMode = lastEventKind === "WEB_SITE_SEARCH" || lastEventKind === "EXTERNAL_WEB_SITE_ACCESS" ? "WEB_SEARCH" : "CHAT_GPT";
};

export const clear = (): void => {
  const defaultState = Def.createDefaultState();
  state.playbackManager = defaultState.playbackManager;
  state.sliderValue = defaultState.sliderValue;
  state.editorMode = defaultState.editorMode;
  state.sessionIds = defaultState.sessionIds;
  state.submission = defaultState.submission;
  state.appealMessage = defaultState.appealMessage;
  state.playStatus = defaultState.playStatus;
  state.playbackSettings = defaultState.playbackSettings;
  state.question = defaultState.question;
};

export const updateSliderValue = (sliderValue: number) => {
  state.playbackManager?.setTickIndex(sliderValue);
  state.sliderValue = sliderValue;
};

export const updateEditorMode = (editorMode: Types.EditorMode) => {
  state.editorMode = editorMode;
};

export const updatePlayStatus = (playStatus: Types.PlayStatus) => {
  state.playStatus = playStatus;
};

export const updatePlaybackSettings = (playbackSettings: Types.PlaybackSettings) => {
  state.playbackSettings = playbackSettings;
};

export const updateRightSidePanelMode = (mode: Types.RightSidePanelMode) => {
  state.rightSidePanelMode = mode;
};
