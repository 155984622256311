import { useTranslation } from "@hireroo/i18n";
import Markdown from "@hireroo/markdown-v2/react";
import CancelRounded from "@mui/icons-material/CancelRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import { alpha, Palette, styled } from "@mui/material/styles";
import * as React from "react";

type ActionState = keyof Pick<Palette, "success" | "warning" | "error">;
type OptionStatus = "CORRECT" | "INCORRECT" | "SKIP";

const StyledFormControlLabel = styled(FormControlLabel)<{ ownerState: { state?: ActionState } }>(({ theme, ownerState }) => ({
  width: "100%",
  marginTop: "10px",
  backgroundColor: ownerState.state ? alpha(theme.palette[ownerState.state].light, 0.5) : "inherit",
}));

const OptionIconMap = {
  CORRECT: <CheckCircleIcon fontSize="small" color="success" />,
  INCORRECT: <CancelRounded fontSize="small" color="error" />,
};

const StatusToPalletteMap: Record<OptionStatus, ActionState> = {
  CORRECT: "success",
  INCORRECT: "error",
  SKIP: "warning",
};

type OptionItem = {
  id: number;
  title: string;
  status: OptionStatus;
};

export type PlaybackContentMultiOptionProps = {
  status: "SELECTING" | "CONFIRM";
  optionItems: OptionItem[];
  skipOptionId: number;
  submittedOptionIds?: number[];
  selectedOptionIds?: number[];
};

const PlaybackContentMultiOption: React.FC<PlaybackContentMultiOptionProps> = props => {
  const { t } = useTranslation();

  const selectedOptionIdsSet = React.useMemo(() => {
    return new Set<number>(props.selectedOptionIds ? [...props.selectedOptionIds] : []);
  }, [props.selectedOptionIds]);

  const submittedOptionIdsSet = React.useMemo(() => {
    return new Set<number>(props.submittedOptionIds ? [...props.submittedOptionIds] : []);
  }, [props.submittedOptionIds]);

  return (
    <FormGroup aria-label="options">
      {props.optionItems.map(option => (
        <Stack key={option.id} direction="row" alignItems="center" spacing={1}>
          {option.status === "CORRECT" ? OptionIconMap["CORRECT"] : OptionIconMap["INCORRECT"]}
          <StyledFormControlLabel
            // Radio form passed "string" so value is "string"
            key={`id-${option.id}`}
            value={String(option.id)}
            control={
              <Checkbox color={props.status === "SELECTING" ? "default" : "secondary"} readOnly checked={selectedOptionIdsSet.has(option.id)} />
            }
            label={
              <React.Suspense>
                <Markdown children={option.title} size="small" />
              </React.Suspense>
            }
            ownerState={{ state: submittedOptionIdsSet.has(option.id) ? StatusToPalletteMap[option.status] : undefined }}
          />
        </Stack>
      ))}
      <Stack direction="row" alignItems="center" spacing={1}>
        {OptionIconMap["INCORRECT"]}
        <StyledFormControlLabel
          value={String(props.skipOptionId)}
          control={
            <Checkbox
              color={props.status === "SELECTING" ? "default" : "secondary"}
              checked={selectedOptionIdsSet.has(props.skipOptionId)}
              readOnly
            />
          }
          label={t("この問題をスキップ")}
          ownerState={{ state: submittedOptionIdsSet.has(props.skipOptionId) ? StatusToPalletteMap["SKIP"] : undefined }}
        />
      </Stack>
    </FormGroup>
  );
};

PlaybackContentMultiOption.displayName = "PlaybackContentMultiOption";

export default PlaybackContentMultiOption;
