import { ScreeningInviteeList } from "@hireroo/app-store/widget/e/ScreeningInviteeList";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

const FieldValues = {
  CREATED_AT_ASCENDING: "CREATED_AT_ASCENDING",
  CREATED_AT_DESCENDING: "CREATED_AT_DESCENDING",
} as const;

type Item = Widget.ScreeningInviteeListProps["table"]["items"][0];

const inviteStatusMap: Record<Graphql.ScreeningInviteeStatus, Item["status"]> = {
  [Graphql.ScreeningInviteeStatus.Accepted]: "ACCEPTED",
  [Graphql.ScreeningInviteeStatus.Invited]: "INVITED",
};

export type GenerateScreeningInviteeListPropsArgs = {};

export const useGenerateProps = (_args: GenerateScreeningInviteeListPropsArgs): Widget.ScreeningInviteeListProps => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const res = ScreeningInviteeList.useRes();
  const page = ScreeningInviteeList.usePage();
  const size = ScreeningInviteeList.useSize();
  const listParams = ScreeningInviteeList.useListParams();

  const items = React.useMemo((): Item[] => {
    return (res?.invitees || []).map((invitee): Item => {
      return {
        email: invitee.email,
        status: inviteStatusMap[invitee.status],
      };
    });
  }, [res?.invitees]);

  return {
    table: {
      items: items,
    },
    searchResultBar: {
      sortField: {
        options: [
          {
            displayName: t("作成日時が古い順"),
            value: FieldValues.CREATED_AT_ASCENDING,
          },
          {
            displayName: t("作成日時が新しい順"),
            value: FieldValues.CREATED_AT_DESCENDING,
          },
        ],
        defaultValue: React.useMemo(() => {
          return listParams.isDescending ? FieldValues.CREATED_AT_DESCENDING : FieldValues.CREATED_AT_ASCENDING;
        }, [listParams.isDescending]),
        onChange: fieldValue => {
          ScreeningInviteeList.updateIsDescending(fieldValue === FieldValues.CREATED_AT_DESCENDING);
        },
      },
      resultText: t2("SearchResultCount", { count: res?.count === undefined ? "-" : res.count }),
    },
    pagination: {
      count: res?.count ?? 0,
      rowsPerPage: size,
      onRowsPerPageChange: event => {
        const newSize = parseInt(event.target.value, 10);
        ScreeningInviteeList.updateSize(newSize);
      },
      page: page,
      onPageChange: (_, newPage) => {
        ScreeningInviteeList.updatePage(newPage);
      },
    },
  };
};
