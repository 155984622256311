import * as z from "zod";

const ScoringItem = z.object({
  id: z.number(),
  isPassed: z.boolean(),
});

const ScoringItems = ScoringItem.array();

export const useSystemDesignScoreEditForm = () => {
  return z.object({
    scoringItems: ScoringItems,
  });
};

export type SystemDesignScoreEditForm = z.infer<ReturnType<typeof useSystemDesignScoreEditForm>>;
