import { SupportLanguage, useTranslation } from "@hireroo/i18n";
import * as z from "zod";

export const useContent = () => {
  const { t } = useTranslation();
  return z.object({
    title: z.string().min(1, { message: t("タイトルは必須項目です。") }),
    description: z.string().min(1, { message: t("本文は必須項目です。") }),
    language: z.enum([SupportLanguage.JA, SupportLanguage.EN]),
  });
};
export type AnswersContentSchema = z.infer<ReturnType<typeof useContent>>;

const useRuntime = () => {
  return z.object({
    id: z.number().optional(),
    runtime: z.string(),
    body: z.string(),
  });
};

export const useFlowChart = () => {
  return z.object({
    id: z.number().optional(),
    componentType: z.union([z.literal("default"), z.literal("aws"), z.literal("gcp"), z.literal("azure")]),
    body: z.string(),
  });
};

export type FlowChartSchema = z.infer<ReturnType<typeof useFlowChart>>;

const useAnswerForm = () => {
  const content = useContent();
  const runtime = useRuntime();
  const flowChart = useFlowChart();
  return z.object({
    id: z.number().optional(),
    label: z.string(),
    contents: z.array(content),
    answerCodes: runtime.array(),
    answerFlowCharts: flowChart.array(),
  });
};

export const useAnswersFormSchema = () => {
  const { t } = useTranslation();
  const answer = useAnswerForm();
  return z
    .object({
      answers: answer.array(),
      variant: z.union([z.literal("UNKNOWN"), z.literal("ALGORITHM"), z.literal("SYSTEM_DESIGN")]),
    })
    .superRefine((arg, ctx) => {
      arg.answers.forEach((answer, answerIndex) => {
        if (arg.variant === "ALGORITHM") {
          answer.answerCodes.forEach((code, codeIndex) => {
            if (code.body.length === 0) {
              ctx.addIssue({
                code: z.ZodIssueCode.too_small,
                path: [`answers.${answerIndex}.answerCodes.${codeIndex}.body`],
                minimum: 1,
                type: "string",
                inclusive: true,
                message: t("解答コードは必須です。"),
              });
            }
          });
        }
        if (arg.variant === "SYSTEM_DESIGN") {
          answer.answerFlowCharts.forEach((chart, codeIndex) => {
            if (chart.body.length === 0) {
              ctx.addIssue({
                code: z.ZodIssueCode.too_small,
                path: [`answers.${answerIndex}.answerFlowCharts.${codeIndex}.body`],
                minimum: 1,
                type: "string",
                inclusive: true,
                message: t("解答フローチャートは必須です。"),
              });
            }
          });
        }
      });
    });
};

export type FreepadAnswersFormSchema = z.infer<ReturnType<typeof useAnswersFormSchema>>;
