import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type Kind = "WAITING_FOR_LAUNCH" | "WAITING_FOR_RESET" | "FAILED_LAUNCH";

export type NotificationPanelProps = {
  kind: Kind;
};

const NotificationPanel: React.FC<NotificationPanelProps> = props => {
  const { t } = useTranslation();
  const displayTextMap: Record<Kind, string> = {
    WAITING_FOR_LAUNCH: t("※初回起動時は最大5分ほど時間がかかります。"),
    WAITING_FOR_RESET: t("※再起動には最大5分ほど時間がかかります。"),
    FAILED_LAUNCH: t("※起動に失敗しました。画面をリロードして再度お試しいただくか、運営までお問い合わせください。"),
  };
  return (
    <Box display={"flex"} flexDirection={"column"} width={"100%"} height={"100%"} justifyContent={"center"} alignItems={"center"}>
      <CircularProgress color="secondary" />
      <Box mt={2}>
        <Typography variant="body2">{displayTextMap[props.kind]}</Typography>
      </Box>
    </Box>
  );
};

NotificationPanel.displayName = "NotificationPanel";

export default NotificationPanel;
