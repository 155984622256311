import { App, Company } from "@hireroo/app-store/essential/employee";
import { ScreeningTestListForDetail } from "@hireroo/app-store/widget/e/ScreeningTestListForDetail";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import ScreeningTestListForDetailContainer, { ScreeningTestListForDetailContainerProps } from "./Container";
import { useQueryParams } from "./privateHelper";

export type ScreeningTestListForDetailFetchContainerProps = ScreeningTestListForDetailContainerProps;

const ScreeningTestListForDetailFetchContainer: React.FC<ScreeningTestListForDetailFetchContainerProps> = props => {
  const appStatus = App.useStatus();
  const activeCompanyId = Company.useStrictActiveCompanyId();
  const queryParams = useQueryParams();
  const [result, refresh] = Graphql.useGetSpotTagsByCompanyIdForScreeningDetailQuery({
    variables: {
      spotTagsByCompanyId: {
        companyId: activeCompanyId,
        size: 100,
        withCount: true,
      },
    },
    pause: appStatus !== "INITIALIZED",
  });

  React.useEffect(() => {
    if (result.data?.spotTagsByCompanyId?.tags) {
      ScreeningTestListForDetail.initialize({
        spotTags: result.data.spotTagsByCompanyId,
        searchTags: [],
        queryParams: queryParams,
      });
    }
  }, [queryParams, result.data?.spotTagsByCompanyId]);

  React.useEffect(() => {
    return () => {
      ScreeningTestListForDetail.clear();
    };
  }, []);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED") {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: ScreeningTestListForDetailContainerProps = {
    ...props,
  };

  return <ScreeningTestListForDetailContainer {...containerProps} />;
};

ScreeningTestListForDetailFetchContainer.displayName = "ScreeningTestListForDetailFetchContainer";

export default withErrorBoundary(ScreeningTestListForDetailFetchContainer, {});
