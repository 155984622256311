import type { AssessmentResourceEditorForm } from "@hireroo/validator";
import * as React from "react";

import { useAssessmentResourceEditorV2Context } from "./Context";
import ConfirmSection, { ConfirmSectionProps } from "./parts/ConfirmSection/ConfirmSection";
import Layout, { LayoutProps } from "./parts/Layout/Layout";
import ReportSetupSection, { ReportSetupSectionProps } from "./parts/ReportSetupSection/ReportSetupSection";
import TestInviteSetupSection, { TestInviteSetupSectionProps } from "./parts/TestInviteSetupSection/TestInviteSetupSection";
import TestQuestionSetupSection, { TestQuestionSetupSectionProps } from "./parts/TestQuestionSetupSection/TestQuestionSetupSection";
import TestSetupSection, { TestSetupSectionProps } from "./parts/TestSetupSection/TestSetupSection";

export {
  AssessmentResourceEditorV2Provider,
  type AssessmentResourceEditorV2ProviderProps,
  useAssessmentResourceEditorV2Context,
} from "./Context";

type Mode = "EDIT" | "CREATE";

export type AssessmentResourceEditorV2Props = {
  className?: string;
  mode: Mode;
  testQuestionSetupSection: TestQuestionSetupSectionProps;
  testSetupSection: TestSetupSectionProps;
  reportSetupSection: ReportSetupSectionProps;
  testInviteSetupSection: TestInviteSetupSectionProps;
  confirmSection: ConfirmSectionProps;
  onSubmit: (fields: AssessmentResourceEditorForm.CreateAssessmentV2FormSchema) => void;
  layout: Pick<LayoutProps, "helpLink" | "loading">;
};

const AssessmentResourceEditorV2: React.FC<AssessmentResourceEditorV2Props> = props => {
  const { setSubmitHandler } = useAssessmentResourceEditorV2Context();

  React.useEffect(() => {
    setSubmitHandler(props.onSubmit);
  }, [setSubmitHandler, props.onSubmit]);

  return (
    <Layout {...props.layout} mode={props.mode} className={props.className}>
      <TestQuestionSetupSection {...props.testQuestionSetupSection} />
      <TestSetupSection {...props.testSetupSection} />
      <ReportSetupSection {...props.reportSetupSection} />
      <TestInviteSetupSection {...props.testInviteSetupSection} />
      <ConfirmSection {...props.confirmSection} />
    </Layout>
  );
};

AssessmentResourceEditorV2.displayName = "AssessmentResourceEditor";

export default AssessmentResourceEditorV2;
