import * as z from "zod";

import * as AssignField from "./fields/AssignField";

const useTalent = () => {
  return z.string().array();
};

export const useSubmitStatus = () => {
  return z.union([z.literal("NOT_SUBMITTED"), z.literal("SUBMITTED"), z.null()]);
};

export const useAssessmentStatus = () => {
  return z.union([z.literal("RUNNING"), z.literal("SUSPENDED"), z.literal("ARCHIVED")]);
};

export const useScoreTrend = () => {
  return z.union([z.literal("UP"), z.literal("DOWN"), z.literal("FLAT"), z.null()]);
};

const useTextFilter = () => {
  return z.string();
};

export const useViewerSchema = () => {
  const employeeGroupAssignee = AssignField.useEmployeeGroupAssignee();
  const employeeAssignee = AssignField.useEmployeeAssignee();
  return z.union([employeeGroupAssignee, employeeAssignee]);
};

export const useAssessmentSearchQuery = () => {
  const talent = useTalent();
  const viewer = useViewerSchema();
  const scoreTrend = useScoreTrend();
  const submitStatus = useSubmitStatus();
  const assessmentStatus = useAssessmentStatus();
  const textFilter = useTextFilter();
  return z.object({
    talent,
    viewers: viewer.array(),
    scoreTrend,
    submitStatus,
    assessmentStatuses: assessmentStatus.array(),
    textFilter,
  });
};

export type AssessmentSearchQuerySchema = z.infer<ReturnType<typeof useAssessmentSearchQuery>>;

export const useSortFieldValue = () => {
  return z.union([
    z.literal("created-at-descending"),
    z.literal("created-at-ascending"),
    z.literal("relative-score-descending"),
    z.literal("relative-score-ascending"),
  ]);
};
export type SortFieldValue = z.infer<ReturnType<typeof useSortFieldValue>>;
