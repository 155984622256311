import { useTranslation } from "@hireroo/i18n";
import { ScreeningTestRetakeForm } from "@hireroo/validator";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";

import ResourceEditorConfirmSubsection, {
  ResourceEditorConfirmSubsectionProps,
} from "../../modules/ResourceEditorConfirmSubsection/ResourceEditorConfirmSubsection";
import ResourceField from "../../modules/ResourceField/ResourceField";

const StyledTypography = styled(Typography)(() => ({
  fontWeight: "bold",
  fontSize: 20,
  textAlign: "center",
}));
const StyledStack = styled(Stack)(({ theme }) => ({
  borderRadius: 24,
  backgroundColor: theme.palette.Other.FilledInputBG,
  padding: 24,
}));

export type ScreeningTestRetakeConfirmProps = {
  sections: Omit<ResourceEditorConfirmSubsectionProps, "p">[];
};

const ScreeningTestRetakeConfirm: React.FC<ScreeningTestRetakeConfirmProps> = props => {
  const { t } = useTranslation();
  const methods = useFormContext<ScreeningTestRetakeForm.RetakeScreeningTestSchema>();
  const items: string[] = [
    t(
      "再試験を発行すると、再試験の対象となった問題の再試験発行以前の提出結果（スコア、プレイバック等）の確認ができなくなりますのでご注意ください。",
    ),
    t("再試験発行前のテストに紐づく技術レビュー・最終評価も削除されます。"),
    t("試験再発行が確定すると、元の状態に戻すことはできません。"),
  ];
  return (
    <Stack direction="column" spacing={3}>
      <StyledTypography>{t("内容確認")}</StyledTypography>
      <StyledStack spacing={3}>
        {props.sections.map((item, index) => {
          return <ResourceEditorConfirmSubsection {...item} titleSize={14} key={index.toString()} p={0} />;
        })}
      </StyledStack>
      <ResourceField label={t("注意事項への同意")} kind="REQUIRED">
        <Stack direction="column" spacing={1.5}>
          <Stack direction="column" spacing={1.5} px={1}>
            {items.map((item, index) => (
              <Typography fontSize={12} key={index}>
                {`${index + 1}. ${item}`}
              </Typography>
            ))}
          </Stack>
          <Controller
            name="agreement"
            control={methods.control}
            render={({ field, fieldState }) => (
              <FormGroup onChange={field.onChange}>
                <Stack direction="column" color="primary">
                  <FormControlLabel
                    sx={{ ml: 0 }}
                    control={<Checkbox size="small" color="secondary" checked={field.value} />}
                    label={
                      <Typography variant="body1" gutterBottom mb={0}>
                        {t("上記に同意する")}
                      </Typography>
                    }
                  />
                  {fieldState.error?.message && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
                </Stack>
              </FormGroup>
            )}
          />
        </Stack>
      </ResourceField>
    </Stack>
  );
};

ScreeningTestRetakeConfirm.displayName = "ScreeningTestRetakeConfirm";

export default ScreeningTestRetakeConfirm;
