import * as z from "zod";

export const useScreeningListItem = () => {
  return z.object({
    itemId: z.string(),
    selected: z.boolean(),
  });
};

export type ScreeningListItem = z.infer<ReturnType<typeof useScreeningListItem>>;

export const useScreeningListForm = () => {
  const screeningTestListItem = useScreeningListItem();
  return z.object({
    items: screeningTestListItem.array(),
  });
};

export type ScreeningListForm = z.infer<ReturnType<typeof useScreeningListForm>>;
