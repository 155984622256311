import Box from "@mui/material/Box";
import * as React from "react";
import { useController } from "react-hook-form";

import BaseMultiChoiceField, {
  MultiChoiceFieldProps as BaseMultiChoiceFieldProps,
} from "../../../../primitive/InputControl/MultiChoiceField/MultiChoiceField";
import { useSearchFormContext } from "../../PrivateContext";
import SplitButton, { SplitButtonProps } from "../SplitButton/SplitButton";

type FieldValue = string[];

type MultipleChoiceOption = {
  value: string;
  displayName: string;
  iconSrc?: string;
};

export type MultiChoiceFieldProps = {
  name: string;
  title: string;
  options: MultipleChoiceOption[];
  onChange?: (value: FieldValue) => void;
  disabled?: boolean;
  variant?: SplitButtonProps["variant"];
};

/**
 * TODO rename MultiChoicePrimitiveValueField
 */
const MultiChoiceField: React.FC<MultiChoiceFieldProps> = props => {
  const { onChange } = props;
  const { subscribeClearAllFields: onClearFields } = useSearchFormContext();
  const { field } = useController<Record<string, FieldValue>>({
    name: props.name,
  });
  const [selectedValue, setSelectedValue] = React.useState<string[]>(field.value);
  const selectedDisplayNames = props.options.reduce<string[]>((all, current) => {
    if (selectedValue.includes(current.value)) {
      return all.concat(current.displayName);
    }
    return all;
  }, []);
  const [resetCount, setResetCount] = React.useState(0);

  const resetField = React.useCallback(() => {
    setSelectedValue([]);
    onChange?.([]);
    field.onChange([]);
    setResetCount(prev => prev + 1);
  }, [field, onChange]);

  React.useEffect(() => {
    const stop = onClearFields(() => {
      setSelectedValue([]);
      onChange?.([]);
      setResetCount(prev => prev + 1);
    });
    return () => {
      stop();
    };
  }, [onChange, onClearFields]);

  const splitButtonProps: SplitButtonProps = {
    title: props.title,
    disabled: props.disabled,
    status: selectedValue.length > 0 ? "ACTIVE" : "INACTIVE",
    label: {
      children: selectedValue.length === 0 ? props.title : [props.title, selectedDisplayNames.join(", ")].join(": "),
    },
    onApply: () => {
      props.onChange?.(selectedValue);
      field.onChange(selectedValue);
    },
    onReset: () => {
      resetField();
    },
    variant: props.variant,
  };

  const baseMultiChoiceFieldProps: BaseMultiChoiceFieldProps = {
    name: props.name,
    options: props.options,
    onChange: value => {
      setSelectedValue(value);
    },
    disabled: props.disabled,
    watch: false,
  };

  return (
    <SplitButton {...splitButtonProps}>
      <Box sx={{ maxHeight: 250, overflow: "auto" }}>
        <BaseMultiChoiceField key={resetCount.toString()} {...baseMultiChoiceFieldProps} />
      </Box>
    </SplitButton>
  );
};

MultiChoiceField.displayName = "MultiChoiceField";

export default MultiChoiceField;
