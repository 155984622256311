import { brightness } from "@hireroo/app-helper/color";
import { CursorWidget } from "@hireroo/code-editor/extensions";
import type { IStandaloneCodeEditor } from "@hireroo/code-editor/react/CodeEditor";
import * as monaco from "monaco-editor";
import * as React from "react";

export const useCursorWidgetManager = () => {
  const cursorWidgetControllers = React.useRef<Map<IStandaloneCodeEditor, CursorWidget.CursorWidgetController>>(new Map());
  const dispose = React.useCallback(() => {
    for (const controller of cursorWidgetControllers.current.values()) {
      controller.dispose();
    }
    cursorWidgetControllers.current.clear();
  }, []);

  React.useEffect(() => {
    return () => {
      dispose();
    };
  }, [dispose]);

  const updateCursor = React.useCallback((clientId: string, range: monaco.Range, userColor: string, userName?: string) => {
    for (const controller of cursorWidgetControllers.current.values()) {
      controller.updateCursor(clientId, range, userColor, userName);
    }
  }, []);

  const initCursorWidgetController = React.useCallback((editor: IStandaloneCodeEditor) => {
    const controller = new CursorWidget.CursorWidgetController(editor, brightness);
    cursorWidgetControllers.current.set(editor, controller);
    editor.onDidDispose(() => {
      controller.dispose();
      cursorWidgetControllers.current.delete(editor);
    });
  }, []);

  return React.useMemo(() => {
    return {
      updateCursor,
      initCursorWidgetController,
    };
  }, [updateCursor, initCursorWidgetController]);
};
