import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/browser";
import * as React from "react";

export type GenerateDashboardRemoteInterviewSectionPropsArgs = {
  remotes: Graphql.RemoteForDashboardRemoteInterviewSectionFragment[];
};

export const useGenerateProps = (args: GenerateDashboardRemoteInterviewSectionPropsArgs): Widget.DashboardRemoteInterviewSectionProps => {
  const { remotes } = args;
  const { t } = useTranslation();
  const navigate = useTransitionNavigate();
  const client = getGraphqlClient();

  const items = React.useMemo((): Widget.DashboardRemoteInterviewSectionProps["items"] => {
    return remotes.map((remote): Widget.DashboardRemoteInterviewSectionProps["items"][number] => {
      return {
        id: remote.id,
        title: {
          text: remote.name,
          onClick: () => {
            navigate("/e/remotes/:id/detail", { pathParams: { id: remote.id } });
          },
        },
        candidateName: remote.candidateName,
        startDialog:
          remote.status === "CREATED" || remote.status === "STARTED"
            ? {
                button: {
                  children: remote.status === "STARTED" ? t("入室") : t("開始"),
                  startIcon: remote.status === "STARTED" ? "LOGIN" : "PLAY",
                  onClick: () => {
                    if (remote.status === "STARTED") {
                      navigate("/e/remotes/:id", {
                        pathParams: {
                          id: remote.remoteId,
                        },
                      });
                    }
                  },
                },
                onSubmit: controller => {
                  controller.setLoading(true);
                  client
                    .StartRemoteForInterviewList({
                      input: {
                        remoteId: remote.remoteId,
                      },
                    })
                    .then(() => {
                      navigate("/e/remotes/:id", {
                        pathParams: {
                          id: remote.remoteId,
                        },
                      });
                      controller.close();
                      Snackbar.notify({
                        severity: "success",
                        message: t("インタビューが開始されました。"),
                      });
                    })
                    .catch(error => {
                      Sentry.captureException(error);
                      const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                        error,
                        t("インタビューの開始に失敗しました。しばらくしてから再度お試しください。"),
                      );
                      Snackbar.notify({
                        severity: "error",
                        message: errorNotification.message,
                      });
                    })
                    .finally(() => {
                      controller.setLoading(false);
                    });
                },
              }
            : undefined,
      };
    });
  }, [client, navigate, remotes, t]);

  return {
    header: {
      titleLink: {
        onClick: () => {
          navigate("/e/remotes");
        },
      },
      createButton: {
        onClick: () => {
          navigate("/e/remotes/create");
        },
      },
    },
    items: items,
  };
};
