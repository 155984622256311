import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";

import SearchResultsStack, { SearchResultsStackProps } from "./parts/SearchResultsStack/SearchResultsStack";

const StyledBox = styled(Box)(({ theme }) => ({
  height: "100%",
  padding: theme.spacing(2),
}));

const QueryWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.Other.FilledInputBG,
  borderRadius: "4px",
  width: "100%",
  padding: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

export type WebSearchPlaybackRightSidePanelProps = {
  query: string;
  searchResultsStack: SearchResultsStackProps;
  hide?: boolean;
};

const WebSearchPlaybackRightSidePanel: React.FC<WebSearchPlaybackRightSidePanelProps> = props => {
  return (
    <StyledBox display={props.hide ? "none" : "block"}>
      <QueryWrapper>
        <Typography fontSize={14} minHeight={20}>
          {props.query}
        </Typography>
      </QueryWrapper>
      {props.query && <SearchResultsStack {...props.searchResultsStack} />}
    </StyledBox>
  );
};

WebSearchPlaybackRightSidePanel.displayName = "WebSearchPlaybackRightSidePanel";

export default WebSearchPlaybackRightSidePanel;
