import { getRef } from "@hireroo/firebase";
import * as React from "react";

import { AccessEvent, fetchAccessEventsRecord } from "../firepad";

export type FirebaseAccessEventArgs = {
  systemDesignId: number;
  questionId: number;
};

export type SystemDesignAccessEvents = {
  accessEvents: Record<string, AccessEvent>;
  ready: boolean;
};

export const useSystemDesignAccessEvents = (args: FirebaseAccessEventArgs): SystemDesignAccessEvents => {
  const { systemDesignId, questionId } = args;
  const [accessEvents, setAccessEvents] = React.useState<Record<string, AccessEvent>>({});
  const [accessEventReady, setRevisionReady] = React.useState<boolean>(false);

  React.useEffect(() => {
    const submissionRef = getRef("systemDesign", `systemDesigns/${systemDesignId}/questions/${questionId}`);
    fetchAccessEventsRecord(submissionRef, event => {
      setAccessEvents(event);
      setRevisionReady(true);
    });
  }, [systemDesignId, questionId]);

  return {
    accessEvents,
    ready: accessEventReady,
  };
};
