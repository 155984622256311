import { type SupportLanguageValue, useTranslation } from "@hireroo/i18n";
import { QuizQuestionForm } from "@hireroo/validator";
import AddCircle from "@mui/icons-material/AddCircle";
import ListIcon from "@mui/icons-material/List";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import MultiChoiceItem, { MultiChoiceItemProps } from "./MultiChoiceItem";

export type MultiChoiceFormProps = {
  language: SupportLanguageValue;
};

const MultiChoiceForm: React.FC<MultiChoiceFormProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const method = useFormContext<QuizQuestionForm.QuizQuestionFormSchema>();
  const multiChoiceOptions = useFieldArray({
    control: method.control,
    name: "multiChoiceQuestion.options",
  });
  const MultiChoiceQuestions = multiChoiceOptions.fields.map((field, idx) => {
    const multiChoiceItemProps: MultiChoiceItemProps = {
      index: idx,
      name: `multiChoiceQuestion.options.${idx}`,
      language: props.language,
      deleteButton: {
        onClick: () => {
          multiChoiceOptions.remove(idx);
        },
      },
    };
    return <MultiChoiceItem key={`question-${field.id}`} {...multiChoiceItemProps} />;
  });
  const addButtonProps: ButtonProps = {
    onClick: () => {
      const selectedLanguages = method.getValues("selectedLanguages");
      multiChoiceOptions.append({
        isCorrect: false,
        content: {
          ...(selectedLanguages.find(({ value }) => value === "ja") && {
            ja: {
              title: "",
              detail: "",
            },
          }),
          ...(selectedLanguages.find(({ value }) => value === "en") && {
            en: {
              title: "",
              detail: "",
            },
          }),
        },
      });
    },
  };
  return (
    <Box>
      <Stack direction="row" spacing={2} alignItems="center">
        <ListIcon fontSize="small" sx={{ color: theme.palette.common.white }} />
        <Typography mr={0.5} variant="subtitle1" sx={{ fontWeight: "bold" }}>
          {t("設問の選択肢を追加する")}
        </Typography>
      </Stack>
      <Stack spacing={4} divider={<Divider />}>
        {MultiChoiceQuestions}
      </Stack>
      <Box display="flex" justifyContent="flex-end" mt={1}>
        <Button {...addButtonProps} startIcon={<AddCircle />}>
          {t("選択肢を追加する")}
        </Button>
      </Box>
    </Box>
  );
};

MultiChoiceForm.displayName = "MultiChoiceForm";

export default MultiChoiceForm;
