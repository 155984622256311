export const QUERY_PARAMS_FOR_NOTIFY = {
  key: "notify",
  value: "publishedTestLink",
};

export type ScreeningResourceEditorQueryParamsValue = "TestQuestionSetup" | "TestSetup" | "ReportSetup" | "TestInviteSetup";

type ScreeningResourceEditorQueryParams = {
  Key: string;
  Value: Record<ScreeningResourceEditorQueryParamsValue, ScreeningResourceEditorQueryParamsValue>;
};

export const ScreeningResourceEditorQueryParams: ScreeningResourceEditorQueryParams = {
  Key: "step",
  Value: {
    TestQuestionSetup: "TestQuestionSetup",
    TestSetup: "TestSetup",
    ReportSetup: "ReportSetup",
    TestInviteSetup: "TestInviteSetup",
  },
} as const;
