import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import { useTheme } from "@mui/material/styles";
import * as React from "react";

import ListButton, { ListButtonProps } from "../ListButton/ListButton";

type Mode = "PLAIN" | "NESTED";

export type MenuListButtonProps = {
  mode: Mode;
  mainButton: Omit<ListButtonProps, "subIcon" | "listItemText">;
  nestedButtons: ListButtonProps[];
};

const MenuListButton: React.FC<MenuListButtonProps> = props => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const mainListButton: ListButtonProps = {
    ...props.mainButton,
    button: {
      ...props.mainButton.button,
      color: theme.palette.secondary.light,
    },
    listItemText: {
      sx: {
        fontWeight: 500,
      },
    },
  };
  if (props.mode === "PLAIN") {
    return <ListButton {...mainListButton} />;
  }

  const listButton: ListButtonProps = {
    ...props.mainButton,
    button: {
      ...props.mainButton.button,
      onClick: e => {
        setOpen(prev => !prev);
        props.mainButton.button?.onClick?.(e);
      },
    },
    subIcon: open ? "EXPAND_MORE" : "EXPAND_LESS",
  };
  const nestedButtons: ListButtonProps[] = props.nestedButtons.map(listButton => {
    return {
      ...listButton,
      button: {
        ...listButton.button,
        sx: {
          ...listButton.button.sx,
          pl: 5,
        },
      },
      listItemText: {
        color: theme.palette.text.secondary,
      },
    };
  });

  return (
    <>
      <ListButton {...listButton} />
      <Collapse in={open}>
        <List component="div">
          {nestedButtons.map(button => (
            <ListButton key={button.id} {...button} />
          ))}
        </List>
      </Collapse>
    </>
  );
};

MenuListButton.displayName = "MenuListButton";

export default MenuListButton;
