import { App } from "@hireroo/app-store/essential/shared";
import { Credential } from "@hireroo/app-store/essential/shared";

export type InitializeUnknownArgs = {};

/**
 * Please do not use redirect within this method. Use redirect outside of this method.
 */
const initialize = async (_args: InitializeUnknownArgs): Promise<void> => {
  App.setStatus("INITIALIZING");
  Credential.setCurrentUserType("UNKNOWN");
  App.setStatus("INITIALIZED");
};

export const Unknown = {
  initialize,
};
