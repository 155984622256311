import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

import SwitchControl from "../../../primitive/InputControl/SwitchControl";

/**
 * After the first version it is not allowed to change from public to private
 */
export type PublicSettingFieldProps = {
  disabled: boolean;
};

const PublicSettingField: React.FC<PublicSettingFieldProps & { name: string }> = props => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box mt={2}>
        <FormGroup>
          <Tooltip title={`${t("一度公開済みになった問題は変更することはできません。")}`} placement="top-start" enterDelay={1000}>
            <Box display="flex" alignItems="center">
              <Typography>{t("非公開")}</Typography>
              <Box>
                <SwitchControl name={props.name} disabled={props.disabled} />
              </Box>
              <Typography>{t("公開")}</Typography>
            </Box>
          </Tooltip>
          <FormHelperText>{t("公開の場合、他の企業も問題を利用できます。")}</FormHelperText>
        </FormGroup>
      </Box>
    </Box>
  );
};

PublicSettingField.displayName = "PublicSettingField";

export default PublicSettingField;
