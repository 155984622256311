import { useTranslation } from "@hireroo/i18n";
import type { FlowElement } from "@hireroo/system-design/features";
import { useSystemDesignContext } from "@hireroo/system-design/react/FlowChart";
import { FlowChartPlayground, FlowChartPlaygroundProps } from "@hireroo/system-design/react/usecase";
import RefreshIcon from "@mui/icons-material/Refresh";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import * as React from "react";

import SystemDesignResetFlowChartDialog, {
  SystemDesignResetFlowChartDialogProps,
} from "../../../SystemDesignResetFlowChartDialog/SystemDesignResetFlowChartDialog";

export type FlowChartInputFieldProps = {
  id?: string;
  onChangeElements?: (elements: FlowElement[]) => void;
};

const FlowChartInputField: React.FC<FlowChartInputFieldProps> = props => {
  const { t } = useTranslation();
  const { onChangeElements, id } = props;
  const { action, hooks } = useSystemDesignContext();
  const elements = hooks.useElementsList();
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    onChangeElements?.(elements);
    if (id) {
      // set global state manually since set on unmount is disabled
      action.setGlobalState(id);
    }
  }, [action, elements, id, onChangeElements]);

  const resetButtonProps: ButtonProps = {
    className: "flow-chart-reset-button",
    variant: "outlined",
    fullWidth: true,
    children: t("フローチャートをリセットする"),
    startIcon: <RefreshIcon color="inherit" fontSize="small" />,
    onClick: () => {
      setOpen(true);
    },
  };

  const flowChartPlaygroundProps: FlowChartPlaygroundProps = {
    flowChartSideBar: {
      BottomComponent: <Button {...resetButtonProps} />,
    },
  };
  const systemDesignResetFlowChartDialogProps: SystemDesignResetFlowChartDialogProps = {
    open,
    yesButton: {
      onClick: () => {
        action.resetElements({
          elements: [],
          timestamp: Date.now(),
        });
        setOpen(false);
      },
    },
    noButton: {
      onClick: () => {
        setOpen(false);
      },
    },
  };
  return (
    <Box sx={{ position: "relative", height: 600, width: 1050 }}>
      <FlowChartPlayground {...flowChartPlaygroundProps} />
      <SystemDesignResetFlowChartDialog {...systemDesignResetFlowChartDialogProps} />
    </Box>
  );
};

FlowChartInputField.displayName = "FlowChartInputField";

export default FlowChartInputField;
