import { useTranslation } from "@hireroo/i18n";
import { useMethods, withSplitProvider } from "@hireroo/react-split";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";

import LiveCodingLayout, { LiveCodingLayoutProps } from "../../layouts/LiveCodingLayout/LiveCodingLayout";
import ActivityBar, { ActivityBarProps } from "../../modules/ActivityBar/ActivityBar";
import RightSidePanel, { RightSidePanelProps } from "../../ms-components/Remote/RightSidePanel/RightSidePanel";
import Split, { SplitProps } from "../../primitive/Split/Split";
import LeftSidePanel, { LeftSidePanelProps } from "./parts/LeftSidePanel/LeftSidePanel";
import MainContent, { MainContentProps } from "./parts/MainContent/MainContent";
import { SplitParams, SplitParamsForRemoteInterviewSystemDesignCodingEditor, useSplitEvent } from "./privateHelper";

type Mode = "DRAWING" | "OTHER";

export type RemoteInterviewForCandidateProps = {
  mode: Mode;
  layout: LiveCodingLayoutProps;
  leftSidePanel: Omit<LeftSidePanelProps, "closePaneButton">;
  rightSidePanel: RightSidePanelProps;
  mainContent: Omit<MainContentProps, "emptyContent">;
  enableLeftSidePanel: boolean;
};

const StyledSplit = styled(Split)`
  height: 100%;
  &[data-direction*="vertical"] {
    width: calc(100vw - 36px);
  }
`;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  max-height: 100vh;
  height: 100%;
`;

const RemoteInterviewForCandidate: React.FC<RemoteInterviewForCandidateProps> = props => {
  const methods = useMethods();
  const { t } = useTranslation();
  const interviewLayoutProps: LiveCodingLayoutProps = {
    ...props.layout,
  };
  const currentVisibleSplitId = props.enableLeftSidePanel ? SplitParams.splitId : SplitParams.splitIdWithoutLeftSidePane;
  const splitEvent = useSplitEvent({
    currentSplitId: currentVisibleSplitId,
  });

  const leftSidePanelProps: LeftSidePanelProps = {
    ...props.leftSidePanel,
  };
  const rightSidePanelProps: RightSidePanelProps = {
    ...props.rightSidePanel,
  };

  const mainContentProps: MainContentProps = {
    emptyContent: {},
    tab: {
      ...props.mainContent.tab,
    },
  };

  const splitItems: (SplitProps["items"][0] | false)[] = [
    props.enableLeftSidePanel && {
      id: SplitParams.Contents.LeftSideBar.id,
      Content: <LeftSidePanel {...leftSidePanelProps} />,
      size: {
        value: 400,
        unit: "px",
      },
      minimizedSize: {
        value: 0,
        unit: "px",
      },
      expandedSize: {
        value: 400,
        unit: "px",
      },
      sidePane: {
        className: "tutorial-pane",
      },
    },
    {
      id: SplitParams.Contents.Main.id,
      Content: <MainContent {...mainContentProps} />,
      size: {
        value: 100,
        unit: "%",
      },
      sidePane: {
        className: "tutorial-pane",
      },
    },
    {
      id: SplitParams.Contents.RightSideBar.id,
      Content: <RightSidePanel {...rightSidePanelProps} />,
      size: {
        value: 400,
        unit: "px",
      },
      minimizedSize: {
        value: 0,
        unit: "px",
      },
      expandedSize: {
        value: 400,
        unit: "px",
      },
    },
  ];

  const splitProps: SplitProps = {
    splitId: props.enableLeftSidePanel ? SplitParams.splitId : SplitParams.splitIdWithoutLeftSidePane,
    splitDirection: "VERTICAL",
    items: splitItems.flatMap(v => (v ? [v] : [])),
  };

  const activityBarItems: (ActivityBarProps["items"][0] | false)[] = [
    props.enableLeftSidePanel && {
      icon: "DESCRIPTION",
      tooltip: t("問題"),
      onClick: () => {
        if (splitEvent.leftSideBarVisibleStatus === "MINIMIZED") {
          methods.expand(currentVisibleSplitId, SplitParams.Contents.LeftSideBar.id);
        } else {
          methods.minimize(currentVisibleSplitId, SplitParams.Contents.LeftSideBar.id);
        }
        splitEvent.setLeftSideBarVisibleStatus(prev => (prev === "MINIMIZED" ? "OPEN" : "MINIMIZED"));
      },
      disabled: !props.enableLeftSidePanel,
      active: props.enableLeftSidePanel && splitEvent.leftSideBarVisibleStatus === "OPEN",
      className: "toggle-pane-button",
    },
    {
      icon: "CHAT",
      tooltip: t("チャット"),
      onClick: () => {
        if (splitEvent.rightSideBarVisibleStatus === "MINIMIZED") {
          methods.expand(currentVisibleSplitId, SplitParams.Contents.RightSideBar.id);
        } else {
          methods.minimize(currentVisibleSplitId, SplitParams.Contents.RightSideBar.id);
        }
        splitEvent.setRightSideBarVisibleStatus(prev => (prev === "MINIMIZED" ? "OPEN" : "MINIMIZED"));
      },
      active: splitEvent.rightSideBarVisibleStatus === "OPEN",
      className: "toggle-pane-button",
    },
    props.mode === "DRAWING" && {
      icon: "APPS",
      tooltip: t("コンポーネント"),
      onClick: () => {
        if (splitEvent.selectComponentAreaVisibleStatus === "MINIMIZED") {
          methods.expand(
            SplitParamsForRemoteInterviewSystemDesignCodingEditor.splitId,
            SplitParamsForRemoteInterviewSystemDesignCodingEditor.Contents.SelectComponentArea.id,
          );
        } else {
          methods.minimize(
            SplitParamsForRemoteInterviewSystemDesignCodingEditor.splitId,
            SplitParamsForRemoteInterviewSystemDesignCodingEditor.Contents.SelectComponentArea.id,
          );
        }
        splitEvent.setSelectComponentAreaVisibleStatus(prev => (prev === "MINIMIZED" ? "OPEN" : "MINIMIZED"));
      },
      active: splitEvent.selectComponentAreaVisibleStatus === "OPEN",
      className: "toggle-pane-button",
    },
  ];

  const activityBarProps: ActivityBarProps = {
    items: activityBarItems.flatMap(v => (v ? [v] : [])),
  };

  return (
    <LiveCodingLayout {...interviewLayoutProps}>
      <Wrapper>
        <ActivityBar {...activityBarProps} />
        <StyledSplit key={splitProps.splitId} {...splitProps} />
      </Wrapper>
    </LiveCodingLayout>
  );
};

RemoteInterviewForCandidate.displayName = "RemoteInterviewForCandidate";

export default withSplitProvider(RemoteInterviewForCandidate);
