import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateSystemDesignQuestionDetailPropsArgs, useGenerateProps } from "./useGenerateProps";

export type SystemDesignQuestionDetailContainerProps = GenerateSystemDesignQuestionDetailPropsArgs;

const SystemDesignQuestionDetailContainer: React.FC<SystemDesignQuestionDetailContainerProps> = props => {
  const systemDesignQuestionDetailProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.SystemDesignQuestionDetail {...systemDesignQuestionDetailProps} />
    </ErrorBoundary>
  );
};

SystemDesignQuestionDetailContainer.displayName = "SystemDesignQuestionDetailContainer";

export default withErrorBoundary(SystemDesignQuestionDetailContainer, {});
