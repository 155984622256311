import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

export type InitializeParams = {
  dsn: string;
  /**
   * Application operating environment identifier
   */
  environment: string;
  /**
   * Used as an identifier to link to the SourceMap to be sent to Sentry
   */
  release: string;
  enabled: boolean;
};

const initialize = (params: InitializeParams): void => {
  if (!params.enabled) {
    return;
  }
  Sentry.init({
    /** Destination of log information, to be retrieved from the WEB UI. */
    dsn: params.dsn,
    /** Tracer used in the browser runtime environment */
    integrations: [new Integrations.BrowserTracing()],
    /** Logging sample rate 1.0 = 100% and send all errors */
    tracesSampleRate: 1.0,
    /** Application operating environment identifier */
    environment: params.environment,
    /** Used as an identifier to link to the SourceMap to be sent to Sentry */
    release: params.release,
    /** Error patterns to ignore. For example, extension errors. */
    ignoreErrors: [
      "TypeError: NetworkError when attempting to fetch resource.",
      // Error when Beacon cannot be sent by GA. Ignore this error as it only occurs in basic Crawler.
      // https://stackoverflow.com/questions/67224859/typeerror-illegal-invocation
      "TypeError: Illegal invocation",
    ],
    /** Maximum number of characters that can be sent */
    maxValueLength: 1000,
  });
};

export default initialize;
