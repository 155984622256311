import { InterviewFeedback } from "@hireroo/app-store/widget/c/InterviewFeedback";
import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";

export type GenerateScreeningTestFeedbackPropsArgs = {};

type Item = Widget.ScreeningTestFeedbackProps["feedbacks"][0]["items"][0];

export const useGenerateProps = (_args: GenerateScreeningTestFeedbackPropsArgs): Widget.ScreeningTestFeedbackProps => {
  const interview = InterviewFeedback.useInterview();
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();

  return {
    emptyMessage: t("技術評価が未入力です。"),
    feedbacks: interview.recommendations.map((recommendation, index) => {
      return {
        id: recommendation.id,
        /**
         * It is Sensitive information, so it is hidden.
         */
        reviewer: {
          displayName: t2("Reviewer", {
            number: index + 1,
          }),
          photoUrl: "",
          email: "",
        },
        items: recommendation.evaluations.map(
          (evaluation): Item => ({
            id: evaluation.id,
            title: evaluation.metric?.title ?? "",
            evaluation: {
              numStars: evaluation.numStars,
              comment: evaluation.comment,
            },
          }),
        ),
        /**
         * It is Sensitive information, so it is hidden.
         */
        status: undefined,
        comment: recommendation.comment,
        /**
         * It won't be necessary in the candidate side.
         */
        reviewedAt: "",
      };
    }),
  };
};
