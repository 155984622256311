import { App, Company } from "@hireroo/app-store/essential/employee";
import { IntegrationsSettings } from "@hireroo/app-store/widget/e/IntegrationsSettings";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import IntegrationsSettingsContainer, { IntegrationsSettingsContainerProps } from "./Container";

export type SonarAtsSettingsFetchContainerProps = {};

const SonarAtsSettingsFetchContainer: React.FC<SonarAtsSettingsFetchContainerProps> = _props => {
  const companyId = Company.useStrictActiveCompanyId();
  const appStatus = App.useStatus();
  const sonarPagination = IntegrationsSettings.useSonarPagination();
  const [result, refresh] = Graphql.useListSonarIntegrationsQuery({
    variables: {
      companyId: companyId,
      withCount: true,
      size: sonarPagination.size,
      cursorSeconds: sonarPagination.cursor ?? null,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "network-only",
  });
  React.useEffect(() => {
    if (result.data) {
      // update your state
      if (result.data.sonarIntegrations.sonarIntegrations) {
        IntegrationsSettings.updateSonarIntegrationsResponse(result.data.sonarIntegrations);
      }
    }
  }, [result.data]);

  React.useEffect(() => {
    return () => {
      IntegrationsSettings.clear();
    };
  }, []);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED") {
    return <Widget.Loading kind="TOP" />;
  }

  const containerProps: IntegrationsSettingsContainerProps = {
    refresh: refresh,
  };
  return (
    <ErrorBoundary>
      <IntegrationsSettingsContainer {...containerProps} />
    </ErrorBoundary>
  );
};

SonarAtsSettingsFetchContainer.displayName = "SonarAtsSettingsFetchContainer";

export default withErrorBoundary(SonarAtsSettingsFetchContainer, {});
