import { ScreeningInviteeList } from "@hireroo/app-store/widget/e/ScreeningInviteeList";
import type { Widget } from "@hireroo/presentation";

import ScreeningInviteeListFetchContainer from "./widgets/ScreeningInviteeList/FetchContainer";

export type GenerateScreeningInviteeListForDetailPropsArgs = {};

export const useGenerateProps = (_args: GenerateScreeningInviteeListForDetailPropsArgs): Widget.ScreeningInviteeListForDetailProps => {
  const initialized = ScreeningInviteeList.useInitialized();
  const filters = ScreeningInviteeList.useFilters();
  return {
    active: initialized,
    search: {
      onChange: fields => {
        ScreeningInviteeList.updateFilters({
          statuses: fields.statuses,
          email: fields.textFilter,
        });
      },
      defaultValues: {
        statuses: filters.statuses.slice(),
        textFilter: filters.email ?? "",
      },
    },
    List: <ScreeningInviteeListFetchContainer />,
  };
};
