import { subscribeKey } from "valtio/utils";

import { state } from "./State";

export type SubscribeActiveCompanyIdCallback = (activeCompanyId: number, previousActiveCompanyId: number | null) => Promise<void>;

export const subscribeActiveCompanyId = (callback: SubscribeActiveCompanyIdCallback) => {
  return subscribeKey(state, "activeCompanyId", activeCompanyId => {
    if (activeCompanyId !== null && activeCompanyId !== 0) {
      callback(activeCompanyId, state.previousActiveCompanyId);
    }
  });
};

export type SubscribeActiveCompanyIdZeroCallback = () => void;

export const subscribeActiveCompanyIdZero = (callback: SubscribeActiveCompanyIdZeroCallback) => {
  return subscribeKey(state, "activeCompanyId", activeCompanyId => {
    if (activeCompanyId === 0) {
      callback();
    }
  });
};
