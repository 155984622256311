import { ChallengeCodingEditor } from "@hireroo/app-store/widget/shared/ChallengeCodingEditor";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { getTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/browser";
import * as React from "react";

import { makeSearchCallbacks } from "./privateHelper";
import * as Subscriber from "./Subscriber";

type HandleOnSearchWebArgs = {
  query: string;
  page: number | null;
};

type StoreWebAccessEventArgs = {
  url: string;
  title: string;
  description: string;
  searchEventId: string;
};

export type GenerateChallengeCodingEditorWebSearchRightSidePanelPropsArgs = {
  webSession: ChallengeCodingEditor.ChallengeWebSession;
};

export const useGenerateProps = (
  args: GenerateChallengeCodingEditorWebSearchRightSidePanelPropsArgs,
): Widget.ChallengeCodingEditorWebSearchRightSidePanelProps => {
  const { webSession } = args;
  const { t } = getTranslation();
  const client = getGraphqlClient();
  const { useWebSearchEventId } = ChallengeCodingEditor.useCreateChallengeEntityHooks(webSession.challengeId);
  const searchEventId = useWebSearchEventId();
  const { updateLatestWebSearchedLog, setLeavePageAction: setWebSearchAction } = ChallengeCodingEditor.createChallengeEntityAction(
    webSession.challengeId,
  );
  const query = webSession.latestWebSearchedLog?.query ?? null;
  const page = webSession.latestWebSearchedLog?.page ?? null;

  React.useEffect(() => {
    const stopSubscribeWebSearchParams = Subscriber.startSubscribeWebSearchParams();
    return () => {
      stopSubscribeWebSearchParams();
    };
  }, []);

  React.useEffect(() => {
    if (webSession.latestWebSearchedLog) {
      ChallengeCodingEditor.updateWebSearchParameters(webSession.latestWebSearchedLog.query, webSession.latestWebSearchedLog.page);
    } else {
      ChallengeCodingEditor.resetWebSearchParameters();
    }
  }, [webSession]);

  const createHandleOnSearchWeb = React.useCallback(() => {
    // handler is not updated after rendering google search components.
    // so, use closure to check whether this is first call or not.
    let isFirstCall = true;
    return async (args: HandleOnSearchWebArgs) => {
      // When user reload or change question, search event shouldn't be stored.
      // "query" and "page" won't be updated here because handler won't be updated after rendering google search components.
      if (isFirstCall && args.query === query && args.page === page && searchEventId) {
        isFirstCall = false;
        return searchEventId;
      }

      isFirstCall = false;
      return await client
        .StoreWebSearchEventForChallengeCodingEditor({
          input: {
            ...args,
            sessionId: webSession.sessionId,
          },
        })
        .then(res => {
          updateLatestWebSearchedLog(res.storeWebSearchEvent);
          // return search event id to use it in google search results' event handler
          return res.storeWebSearchEvent.eventId;
        })
        .catch(error => {
          Sentry.captureException(error);

          Snackbar.notify({
            severity: "error",
            message: t("Google検索に失敗しました。しばらくしてから再度お試しください。"),
          });
        });
    };
  }, [t, client, query, page, searchEventId, updateLatestWebSearchedLog, webSession.sessionId]);

  const handleOnClickWebSearchResult = React.useCallback(
    async (args: StoreWebAccessEventArgs) => {
      setWebSearchAction({
        type: "GOOGLE_SEARCH",
        url: args.url,
        createdAtMilliseconds: Date.now(),
      });
      await client
        .StoreWebAccessEventForChallengeCodingEditor({
          input: {
            ...args,
            sessionId: webSession.sessionId,
            // searchEventId is given from google search results' event handler.
            // we can't use this value as state because google search results are rendered before state is updated.
            searchEventId: args.searchEventId,
          },
        })
        .catch(error => {
          Sentry.captureException(error);

          Snackbar.notify({
            severity: "error",
            message: t("Google検索に失敗しました。しばらくしてから再度お試しください。"),
          });
        });
    },
    [t, client, webSession.sessionId, setWebSearchAction],
  );

  React.useEffect(() => {
    const searchCallbacks = makeSearchCallbacks({
      onSearchWeb: createHandleOnSearchWeb(),
      onClickWebSearchResult: handleOnClickWebSearchResult,
    });
    // specify __gcse property to open web page in new tab
    // document: https://developers.google.com/custom-search/docs/more_examples?hl=ja#two-part-callback-example
    if (!window.__gcse) {
      window.__gcse = { searchCallbacks: searchCallbacks };
    } else {
      window.__gcse.searchCallbacks = searchCallbacks;
    }
  }, [createHandleOnSearchWeb, handleOnClickWebSearchResult]);

  return {
    webSearchSidePanel: {
      googleSearchForm: {
        searchEngineId: import.meta.env.VITE_GOOGLE_SEARCH_ENGINE_ID,
      },
    },
  };
};
