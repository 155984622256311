import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";
import Split, { SplitProps } from "react-split";

export type SplitterProps = Omit<Pick<SplitProps, "sizes" | "direction" | "collapsed" | "minSize">, "children"> & {
  children: React.ReactNode[];
};

const StyledSplit = styled(Split)<SplitterProps>(({ theme, direction }) => ({
  height: "100%",
  width: "100%",
  display: direction === "horizontal" ? "flex" : undefined,
  flexDirection: direction === "horizontal" ? "row" : undefined,
  boxSizing: "border-box",
  ".gutter": {
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.grey[800],
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      transition: "all .25s ease",
    },
  },
  ".gutter.gutter-horizontal": {
    backgroundImage: `url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==')`,
    cursor: "col-resize",
    "&:hover": {
      transition: "all .25s ease",
    },
  },
  ".gutter.gutter-vertical": {
    backgroundImage: `url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=')`,
    cursor: "col-resize",
    "&:hover": {
      transition: "all .25s ease",
    },
  },
}));

const Splitter: React.FC<SplitterProps> = props => {
  const theme = useTheme();
  const splitProps: SplitProps = {
    gutterSize: 5,
    expandToMin: false,
    minSize: 30,
    gutterAlign: "center",
    snapOffset: 30,
    dragInterval: 1,
    ...props,
    gutter: (index, direction) => {
      const gutterElement = document.createElement("div");
      gutterElement.className = `gutter gutter-${direction}`;
      let mouseDown = false;
      gutterElement.addEventListener("mouseover", () => {
        gutterElement.style.backgroundColor = theme.palette.primary.main;
      });
      gutterElement.addEventListener("mouseleave", () => {
        if (!mouseDown) {
          gutterElement.style.backgroundColor = theme.palette.grey[800];
        }
      });
      gutterElement.addEventListener("mousedown", () => {
        mouseDown = true;
        gutterElement.style.backgroundColor = theme.palette.primary.main;
      });
      /**
       * When mouseup, it is possible that the element is not directly above the element.
       */
      document.addEventListener("mouseup", () => {
        mouseDown = false;
        if (gutterElement) {
          gutterElement.style.backgroundColor = theme.palette.grey[800];
        }
      });
      return gutterElement;
    },
  };
  return <StyledSplit {...splitProps}>{props.children}</StyledSplit>;
};

Splitter.displayName = "Splitter";

export default Splitter;
