import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import * as React from "react";

import EntryContentPaper from "../../../../modules/EntryContentPaper/EntryContentPaper";
import TestStartDialog, { TestStartDialogProps } from "../../../../ms-components/Spot/TestStartDialog/TestStartDialog";

const StyledButton = styled(Button)(() => ({
  width: "300px",
  height: "42px",
  flexShrink: 0,
  flexGrow: 0,
}));

export type StartSectionProps = {} & Pick<TestStartDialogProps, "onStart" | "startDemoButton">;

const StartSection: React.FC<StartSectionProps> = props => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const startButton: ButtonProps = {
    size: "small",
    color: "primary",
    variant: "contained",
    children: t("テストまたはデモを受ける"),
    onClick: () => {
      setOpen(true);
    },
  };

  const testStartDialogProps: TestStartDialogProps = {
    open,
    onStart: props.onStart,
    startDemoButton: props.startDemoButton,
    onClose: () => {
      setOpen(false);
    },
  };

  return (
    <EntryContentPaper>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <StyledButton {...startButton} />
      </Box>

      <TestStartDialog {...testStartDialogProps} />
    </EntryContentPaper>
  );
};

StartSection.displayName = "StartSection";

export default StartSection;
