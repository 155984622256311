import { useTranslation } from "@hireroo/i18n";
import LinkIcon from "@mui/icons-material/Link";
import ReplayIcon from "@mui/icons-material/Replay";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import { type ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import * as React from "react";

import CopyToClipboardButton, { CopyToClipboardButtonProps } from "../../../../primitive/Button/CopyToClipboardButton/CopyToClipboardButton";
import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "../../../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";

export type ShareLinkProps = {
  createLinkbutton: Pick<ButtonProps, "onClick"> & { loading: boolean };
  copyLinkButton: Pick<CopyToClipboardButtonProps, "onCopy">;
  invitationLink?: string;
  refreshButton: Pick<IconButtonWithTooltipProps, "onClick">;
};

const ShareLink: React.FC<ShareLinkProps> = props => {
  const { t } = useTranslation();
  if (props.invitationLink) {
    const copyToClipboardButtonProps: CopyToClipboardButtonProps = {
      ...props.copyLinkButton,
      url: props.invitationLink,
      name: t("コピーする"),
      buttonWithTooltip: {
        sx: {
          width: "30%",
        },
        variant: "outlined",
      },
    };
    const textFieldProps: TextFieldProps = {
      disabled: true,
      sx: { width: "70%" },
      size: "small",
      value: props.invitationLink,
    };
    const refreshButton: IconButtonWithTooltipProps = {
      ...props.refreshButton,
      children: <ReplayIcon fontSize="small" />,
      title: t("リンクを再発行する"),
    };
    return (
      <Stack direction="row" spacing={1}>
        <TextField {...textFieldProps} />
        <CopyToClipboardButton {...copyToClipboardButtonProps} />
        <Box display="flex" alignItems="center">
          <IconButtonWithTooltip {...refreshButton} />
        </Box>
      </Stack>
    );
  }
  return (
    <LoadingButton color="secondary" {...props.createLinkbutton} startIcon={<LinkIcon />}>
      {t("招待リンクの発行")}
    </LoadingButton>
  );
};

ShareLink.displayName = "ShareLink";

export default ShareLink;
