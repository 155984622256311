import DownloadIcon from "@mui/icons-material/Download";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import BulkListActivityBar, { BulkListActivityBarProps } from "../../../../modules/BulkListActivityBar/BulkListActivityBar";
import SortField, { SortFieldProps } from "../../../../modules/SortField/SortField";

const Wrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "space-between",
}));

const StyledTypography = styled(Typography)(() => ({
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  overflow: "hidden",
}));

const iconMap = {
  LOADING: <CircularProgress size="1rem" sx={{ mr: "0.75em" }} />,
  READY: <DownloadIcon />,
} as const;

type Mode = "SELECTED" | "NONE";

export type SearchResultBarProps = {
  mark: keyof typeof iconMap;
  resultText: string;
  bulkListActivityBar: BulkListActivityBarProps;
  mode: Mode;
  sortField: SortFieldProps;
};

const SearchResultBar: React.FC<SearchResultBarProps> = props => {
  return (
    <Wrapper px={2} py={0.5}>
      <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
        {props.mode === "NONE" && <StyledTypography variant="body2">{props.resultText}</StyledTypography>}
        {props.mode === "SELECTED" && <BulkListActivityBar {...props.bulkListActivityBar} />}
      </Stack>
      <SortField {...props.sortField} />
    </Wrapper>
  );
};

SearchResultBar.displayName = "SearchResultBar";

export default SearchResultBar;
