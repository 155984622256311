import MuiMenuItem, { MenuItemProps as MuiMenuItemProps } from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import * as React from "react";

export type MenuItemProps = MuiMenuItemProps;

const StyledMuiMenuItem = styled(MuiMenuItem)(({ theme }) => ({
  "&.MuiMenuItem-root": {
    "&:hover ": {
      backgroundColor: theme.palette["Secondary/Shades"].p8,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette["Secondary/Shades"].p16,
    },
  },
}));

const MenuItem: React.FC<MenuItemProps> = props => {
  return <StyledMuiMenuItem {...props} />;
};

MenuItem.displayName = "MenuItem";

export default MenuItem;
