import { useTranslation } from "@hireroo/i18n";
import LinkIcon from "@mui/icons-material/Link";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Box from "@mui/material/Box";
import Button, { type ButtonProps } from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import CopyToClipboard from "react-copy-to-clipboard";

import InviteCandidateDialogV2, { InviteCandidateDialogV2Props } from "../InviteCandidateDialogV2/InviteCandidateDialogV2";

const StyledButton = styled(Button)(() => ({
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
}));
type Mode = "ALL" | "MAIL_ONLY";

export type InviteCandidateButtonWithDialogProps = {
  variant: ButtonProps["variant"];
  disabled?: boolean;
  mode: Mode;
  dialog: Omit<InviteCandidateDialogV2Props, "dialog">;
  onOpen?: () => void;
};

const InviteCandidateButtonWithDialog: React.FC<InviteCandidateButtonWithDialogProps> = props => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const dialogButton: ButtonProps = {
    startIcon: <PersonAddIcon fontSize="small" />,
    children: t("招待"),
    size: "small",
    onClick: event => {
      event.stopPropagation();
      event.preventDefault();
      setOpen(true);
      props.onOpen?.();
    },
  };

  const copyButton: ButtonProps = {
    size: "small",
    color: "secondary",
    variant: props.variant,
    children: <LinkIcon fontSize="small" />,
    onClick: event => {
      event.preventDefault();
      event.stopPropagation();
    },
  };

  const ButtonMap: Record<Mode, React.ReactNode> = {
    ALL: (
      <Tooltip title={props.disabled ? t("受付停止中のため、招待することはできません。") : ""}>
        <span>
          <ButtonGroup color="secondary" variant={props.variant} disabled={props.disabled} onClick={event => event.stopPropagation()}>
            <StyledButton {...dialogButton} />
            {props.dialog.copyButton && (
              <CopyToClipboard text={props.dialog.copyButton.url} onCopy={props.dialog.copyButton.onCopy}>
                <Button {...copyButton} />
              </CopyToClipboard>
            )}
          </ButtonGroup>
        </span>
      </Tooltip>
    ),
    MAIL_ONLY: <StyledButton color="secondary" {...dialogButton} variant={props.variant} />,
  };

  const dialogV2: InviteCandidateDialogV2Props = {
    ...props.dialog,
    dialog: {
      open,
      onClose: () => {
        setOpen(false);
      },
    },
    invitedListButton: {
      onClick: event => {
        props.dialog?.invitedListButton?.onClick?.(event);
        setOpen(false);
      },
    },
  };
  return (
    <Box>
      {ButtonMap[props.mode]}
      <InviteCandidateDialogV2 key={`dialog-key-${dialogV2.dialog.open}`} {...dialogV2} />
    </Box>
  );
};

InviteCandidateButtonWithDialog.displayName = "InviteCandidateButtonWithDialog";

export default InviteCandidateButtonWithDialog;
