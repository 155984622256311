import { state } from "./State";
import type * as Types from "./types";

export const clear = () => {
  state.endExamStatus = null;
  state.exam = null;
};

export const setExam = (exam: Types.Exam): void => {
  state.exam = exam;
};

export const updateEndExamStatus = (status: Types.EndExamStatus) => {
  if (state.endExamStatus === null) {
    state.endExamStatus = status;
  }
};

export const updateEndExamLoadingStatus = (status: Types.EndExamLoadingStatus) => {
  state.endExamLoadingStatus = status;
};
