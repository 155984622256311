import { proxy } from "valtio";

import type * as Types from "./types";

export const state = proxy<Types.State>({
  activeStep: 0,
  variant: "ALGORITHM",
  initialLanguage: "ja",
  question: undefined,
  saveStatus: "READY",
  templateCode: {
    c: "",
    cpp: "",
    csharp: "",
    dart: "",
    go: "",
    java: "",
    javascript: "",
    kotlin: "",
    mysql: "",
    perl: "",
    pgsql: "",
    php: "",
    python3: "",
    ruby: "",
    rust: "",
    scala: "",
    sqlite: "",
    swift: "",
    typescript: "",
  },
});
