import { proxy } from "valtio";
import { proxyMap } from "valtio/utils";

import * as PrivateHelper from "./privateHelper";
import { state } from "./State";
import type * as Types from "./types";

export const createAction = (projectId: Types.ProjectId) => {
  const projectState =
    state.projectMap.get(projectId) ||
    proxy<Types.ProjectState>({
      currentSelectFilename: null,
      diffsByFilename: proxyMap(),
      diffStateMap: proxyMap(),
      currentSubmission: null,
      sourceFileFetchStatus: "BEFORE_FETCH",
    });
  if (!state.projectMap.has(projectId)) {
    state.projectMap.set(projectId, projectState);
  }

  const setCurrentSelectFileName = (filename: string) => {
    projectState.currentSelectFilename = filename;
  };

  const setDiffs = (diffs: Types.ProjectDiff[]) => {
    projectState.diffsByFilename.clear();
    diffs.forEach(diff => {
      projectState.diffsByFilename.set(PrivateHelper.generateDisplayFilename(diff), diff);
    });
    if (diffs.length > 0) {
      projectState.currentSelectFilename = PrivateHelper.generateDisplayFilename(diffs[0]);
    }
  };

  const setDiffState = (diff: Types.ProjectDiff, sources: { from: Types.Source | null; to: Types.Source | null }) => {
    const filename = PrivateHelper.generateDisplayFilename(diff);
    projectState.diffStateMap.set(filename, {
      fromSource: sources.from,
      toSource: sources.to,
    });
  };

  const setSubmission = (submission: Types.Submission) => {
    projectState.currentSubmission = submission;
    setDiffs(submission.diffs);
    projectState.sourceFileFetchStatus = "BEFORE_FETCH";
  };

  const setSourceFileFetchStatus = (status: Types.FetchStatus) => {
    projectState.sourceFileFetchStatus = status;
  };

  return {
    setCurrentSelectFileName,
    setDiffState,
    setSubmission,
    setSourceFileFetchStatus,
  };
};
