import { useTranslation } from "@hireroo/i18n";
import ArrowBackOutlined from "@mui/icons-material/ArrowBackOutlined";
import ArrowForwardOutlined from "@mui/icons-material/ArrowForwardOutlined";
import Box from "@mui/material/Box";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import PairEditor, { PairEditorProps } from "./parts/PairEditor/PairEditor";

const StyledHeader = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.action.focus,
  flex: 1,
  height: "100%",
  textAlign: "center",
}));

const StyledTypography = styled(Typography)(() => ({
  minWidth: 50,
  alignContent: "center",
  height: "100%",
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  flexShrink: 0,
  borderRadius: 0,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

export type SimilarCodesProps =
  | {
      kind: "SHOW_CONTENT";
      pairEditor: PairEditorProps;
      similarity: string;
      decrementButton: Pick<IconButtonProps, "disabled" | "onClick">;
      incrementButton: Pick<IconButtonProps, "disabled" | "onClick">;
    }
  | { kind: "NOTHING_TO_SHOW" };

const SimilarCodesSection: React.FC<SimilarCodesProps> = props => {
  const { t } = useTranslation();

  if (props.kind === "NOTHING_TO_SHOW") {
    return (
      <Typography minWidth={50} padding={5} textAlign={"center"}>
        {t("類似コードは検出されませんでした。")}
      </Typography>
    );
  }

  const { pairEditor, decrementButton, incrementButton } = props;

  return (
    <Box>
      <Box sx={{ height: "30px", display: "flex", justifyContent: "center", width: "100%", padding: 0 }}>
        <StyledHeader>
          <StyledTypography variant="body2">{t("提出された解答")}</StyledTypography>
        </StyledHeader>
        <StyledHeader display="flex" justifyContent="space-between">
          <StyledIconButton {...decrementButton}>
            <ArrowBackOutlined color="inherit" />
          </StyledIconButton>
          <Box sx={{ display: "flex" }}>
            <StyledTypography paddingRight={2} variant="body2">
              {t("類似コード")}
            </StyledTypography>
          </Box>
          <StyledIconButton {...incrementButton}>
            <ArrowForwardOutlined color="inherit" />
          </StyledIconButton>
        </StyledHeader>
      </Box>
      <Box>
        <PairEditor {...pairEditor} />
      </Box>
    </Box>
  );
};

SimilarCodesSection.displayName = "SimilarCodesSection";

export default SimilarCodesSection;
