import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const LabelBox = styled(Box)(() => ({
  width: 236,
  flexShrink: 0,
}));

const ContentBox = styled(Box)(() => ({
  width: "100%",
}));

export type ConfirmFieldProps = {
  label: string;
  children?: React.ReactNode;
};

const ConfirmField: React.FC<ConfirmFieldProps> = props => {
  const { t } = useTranslation();
  const isValidValue = !!props.children;
  return (
    <Stack spacing={2} direction="row">
      <LabelBox>
        <Typography fontSize={14} fontWeight={700}>
          {props.label}
        </Typography>
      </LabelBox>
      <ContentBox>
        {typeof props.children === "string" || typeof props.children === "number" || !props.children ? (
          <Typography fontSize={14} color={isValidValue ? "text.primary" : "text.secondary"}>
            {props.children || t("指定なし")}
          </Typography>
        ) : (
          props.children
        )}
      </ContentBox>
    </Stack>
  );
};

ConfirmField.displayName = "ConfirmField";

export default ConfirmField;
