import ExtendedMonacoEditor, { ExtendedMonacoEditorProps } from "@hireroo/code-editor/react/v2/ExtendedMonacoEditor";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import * as React from "react";

export type MonacoEditorPlaygroundProps = {};

const MonacoEditorPlayground: React.FC<MonacoEditorPlaygroundProps> = _props => {
  const [open, setOpen] = React.useState(false);
  const extendedMonacoEditorProps: ExtendedMonacoEditorProps = {
    code: {
      uri: "/App.tsx",
      value: `const sum = (...values: number[]): number => values.reduce((total, current) => total + current, 0);`,
    },
    style: {
      width: "100vw",
      height: "80vh",
    },
  };
  const openButtonProps: ButtonProps = {
    children: open ? "CLOSE" : "OPEN",
    onClick: () => {
      setOpen(prev => !prev);
    },
  };
  return (
    <Box>
      <Button {...openButtonProps} />
      {open && <ExtendedMonacoEditor {...extendedMonacoEditorProps} />}
    </Box>
  );
};

MonacoEditorPlayground.displayName = "MonacoEditorPlayground";

export default MonacoEditorPlayground;
