import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";

import Link, { LinkProps } from "../../primitive/Link/Link";

export type EmployeeMenuListProps = {
  displayName: string;
  email: string;
  links: LinkProps[];
};

const EmployeeMenuList: React.FC<EmployeeMenuListProps> = props => {
  return (
    <List component="nav" aria-label="common-header-list">
      <ListItem divider>
        <ListItemText role="userinfo" primary={props.displayName} secondary={props.email} />
      </ListItem>
      {props.links.map((link, index) => {
        return (
          <ListItemButton key={`menu-${index}`} {...link} underline="none" component={Link}>
            <ListItemText primary={link.children} />
          </ListItemButton>
        );
      })}
    </List>
  );
};

EmployeeMenuList.displayName = "EmployeeMenuList";

export default EmployeeMenuList;
