import ExtendedMonacoEditor, { type ExtendedMonacoEditorProps } from "@hireroo/code-editor/react/v2/ExtendedMonacoEditor";
import React from "react";

export type ExtendedCodeEditorProps = ExtendedMonacoEditorProps;

const ExtendedCodeEditor: React.FC<ExtendedCodeEditorProps> = props => {
  return <ExtendedMonacoEditor {...props} />;
};

ExtendedCodeEditor.displayName = "ExtendedCodeEditor";

export default ExtendedCodeEditor;
