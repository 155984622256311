import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import * as React from "react";

import RemoteInterviewEmptyContent, {
  RemoteInterviewEmptyContentProps,
} from "../../../../ms-components/Remote/RemoteInterviewEmptyContent/RemoteInterviewEmptyContent";
import RemoteInterviewTab, { RemoteInterviewTabProps } from "../../../../ms-components/Remote/RemoteInterviewTab/RemoteInterviewTab";

const ContentWrapper = styled(Paper)(() => ({
  height: "100%",
  width: "100%",
}));

export type MainContentProps = {
  emptyContent: RemoteInterviewEmptyContentProps;
  tab: Pick<RemoteInterviewTabProps, "items" | "plusTab" | "currentTab" | "onChangeTab" | "Extra">;
};

const MainContent: React.FC<MainContentProps> = props => {
  const remoteInterviewTabProps: RemoteInterviewTabProps = {
    ...props.tab,
  };

  if (props.tab.items.length === 0) {
    const remoteInterviewEmptyContentProps: RemoteInterviewEmptyContentProps = {
      ...props.emptyContent,
    };
    return <RemoteInterviewEmptyContent {...remoteInterviewEmptyContentProps} />;
  }

  return (
    <ContentWrapper elevation={2}>
      <RemoteInterviewTab {...remoteInterviewTabProps} />
    </ContentWrapper>
  );
};

MainContent.displayName = "MainContent";

export default MainContent;
