import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type EvaluationsMetricGroupsContainerProps = {};

const EvaluationsMetricGroupsContainer: React.FC<EvaluationsMetricGroupsContainerProps> = () => {
  const evaluationMetricGroupsProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Pages.EvaluationMetrics {...evaluationMetricGroupsProps} />
    </ErrorBoundary>
  );
};

EvaluationsMetricGroupsContainer.displayName = "EvaluationsMetricGroupsContainer";

export default withErrorBoundary(EvaluationsMetricGroupsContainer, {});
