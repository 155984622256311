import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type EventFrequencyTimelinePanelContainerProps = {};

const EventFrequencyTimelinePanelContainer: React.FC<EventFrequencyTimelinePanelContainerProps> = () => {
  const eventFrequencyTimelinePanelProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.EventFrequencyTimelinePanel {...eventFrequencyTimelinePanelProps} />
    </ErrorBoundary>
  );
};

EventFrequencyTimelinePanelContainer.displayName = "EventFrequencyTimelinePanelContainer";

export default withErrorBoundary(React.memo(EventFrequencyTimelinePanelContainer), {});
