import { TwilioConversationsProvider, TwilioVideoProvider } from "@hireroo/app-helper/hooks";
import { RemotesId } from "@hireroo/app-store/page/e/remotes_id";
import { ChallengeCodingEditorProvider } from "@hireroo/challenge/store";
import { useTranslation } from "@hireroo/i18n";
import { Pages, Widget } from "@hireroo/presentation";
import { SystemDesignProvider } from "@hireroo/system-design/react/FlowChart";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import PageErrorPanelContainer from "../../../../widget/e/PageErrorPanel/Container";
import RemoteInterviewForEmployeeContainer, { RemoteInterviewForEmployeeContainerProps } from "./Container";
import * as PrivateHelper from "./privateHelper";
import { useGenerateWaitingRoomProps } from "./useGenerateWaitingRoomProps";

export type RemoteInterviewForEmployeeMiddleContainerProps = RemoteInterviewForEmployeeContainerProps;

const challengeAcceptableVariantMap: Record<RemotesId.LiveCodingQuestionVariant, "ALGORITHM" | "DATABASE" | "CLASS" | "EMPTY_PAD"> = {
  ALGORITHM: "ALGORITHM",
  DATABASE: "DATABASE",
  CLASS: "CLASS",
  SYSTEM_DESIGN: "ALGORITHM",
  EMPTY_PAD: "EMPTY_PAD",
  EMPTY_CANVAS: "ALGORITHM",
  UNKNOWN: "ALGORITHM",
};

const RemoteInterviewForEmployeeMiddleContainer: React.FC<RemoteInterviewForEmployeeMiddleContainerProps> = props => {
  const { t } = useTranslation();
  const algorithmId = RemotesId.useAlgorithmId();
  const systemDesignId = RemotesId.useSystemDesignId();
  const status = RemotesId.useInterviewStatus();
  const variant = RemotesId.useLiveCodingQuestionVariant();
  const waitingRoomProps = useGenerateWaitingRoomProps({
    remoteId: props.remoteId,
  });
  const liveCodingJoinStatus = PrivateHelper.useJoinLiveCoding();

  switch (status) {
    case "COMPLETED":
    case "EVALUATED":
      return (
        <PageErrorPanelContainer
          title={t("インタビューはすでに終了しているためアクセスできません。")}
          message={t("インタビューの結果はレポート画面にて確認することができます。")}
        />
      );
    case "CREATED":
      return (
        <ErrorBoundary>
          <Pages.WaitingRoom {...waitingRoomProps} />
        </ErrorBoundary>
      );
    case "UNKNOWN":
    case undefined:
      return <PageErrorPanelContainer title={t("ページが見つかりませんでした。")} message={t("URLをご確認の上、再度お試し下さい。")} />;
    case "STARTED":
      break;
    default:
      throw new Error(`Unknown type: ${(status as { type: "__invalid__" }).type}`);
  }

  if (liveCodingJoinStatus !== "JOINED") {
    return <Widget.Loading kind="CENTER" color="secondary" />;
  }

  return (
    <ErrorBoundary>
      <Pages.RemoteInterviewForEmployeeProvider>
        <ChallengeCodingEditorProvider
          id={algorithmId}
          variant={challengeAcceptableVariantMap[variant]}
          options={{
            // When switching tabs, the variant changes before unmounting
            disableSetStateOnUnmount: true,
          }}
        >
          <SystemDesignProvider id={systemDesignId}>
            <TwilioVideoProvider>
              <TwilioConversationsProvider>
                <RemoteInterviewForEmployeeContainer {...props} />
              </TwilioConversationsProvider>
            </TwilioVideoProvider>
          </SystemDesignProvider>
        </ChallengeCodingEditorProvider>
      </Pages.RemoteInterviewForEmployeeProvider>
    </ErrorBoundary>
  );
};

RemoteInterviewForEmployeeMiddleContainer.displayName = "RemoteInterviewForEmployeeFetchContainer";

export default withErrorBoundary(RemoteInterviewForEmployeeMiddleContainer, {});
