import { ENTITY_REPORT_TOP, extractUniqueEntity } from "@hireroo/app-helper/entity";
import { scrollToContentForReportV2 } from "@hireroo/app-helper/html-element";
import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import ProjectTestReportContainer, { ProjectTestReportContainerProps } from "./Container";

export type ProjectTestReportFetchContainerProps = {
  featureKind: "test" | "exam";
  companyId: number;
  projectId: number;
  testId: string;
  kindOfReview: ProjectTestReportContainerProps["kindOfReview"];
  canShowCheatDetectionSection: boolean;
};

const ProjectTestReportFetchContainer: React.FC<ProjectTestReportFetchContainerProps> = props => {
  const { projectId, companyId } = props;

  const initialized = ProjectTestReport.useInitialized(projectId);
  const [result] = Graphql.useGetProjectEntityForProjectTestReportQuery({
    variables: {
      projectId: projectId,
      withAnswers: true,
    },
    requestPolicy: "cache-and-network",
  });
  React.useEffect(() => {
    if (result.data?.projectEntity) {
      ProjectTestReport.initialize(result.data.projectEntity);
      const uniqueEntity = extractUniqueEntity(window.location.hash);
      if (uniqueEntity && uniqueEntity.type === "project" && uniqueEntity.id === result.data.projectEntity.entityId) {
        scrollToContentForReportV2(ENTITY_REPORT_TOP, { delayTimeMilliSeconds: 1 });
      }
    }
  }, [result.data?.projectEntity]);

  React.useEffect(() => {
    return () => {
      /**
       * need to clear entity on unmount because the diff editor will mount again unintentionally
       */
      ProjectTestReport.clearEntity(projectId);
    };
  }, [projectId]);

  if (result.fetching || !initialized) {
    return <Widget.Loading kind="TOP" />;
  }

  const containerProps: ProjectTestReportContainerProps = {
    featureKind: props.featureKind,
    entityId: projectId,
    testId: props.testId,
    kindOfReview: props.kindOfReview,
    companyId: companyId,
    canShowCheatDetectionSection: props.canShowCheatDetectionSection,
  };

  return (
    <ErrorBoundary>
      <ProjectTestReportContainer {...containerProps} />
    </ErrorBoundary>
  );
};

ProjectTestReportFetchContainer.displayName = "ProjectTestReportFetchContainer";

export default withErrorBoundary(ProjectTestReportFetchContainer, {});
