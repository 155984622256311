import { NO_RESPONSE_STATUS_CODE } from "@hireroo/app-definition/project";
import * as ProjectHelperV3 from "@hireroo/app-helper/project-v3";
import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import * as JSONFormatter from "@hireroo/formatter/json";
import { formatScore } from "@hireroo/formatter/score";
import * as Time from "@hireroo/formatter/time";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

type TestCaseProps = Extract<
  Exclude<Widget.ProjectTestReportProps["testCaseSection"], undefined>["content"],
  { kind: "BACKEND" }
>["testCases"][0];
type TestResultValue = Extract<TestCaseProps["testResult"], { kind: "MULTI" }>["values"][0];

export type GenerateTestCaseSectionPropsForBackendArgs = {
  entityId: number;
  uniqueKey: ProjectTestReport.UniqueKey;
  ignore: boolean;
  showScore: boolean;
};

export const useGenerateTestCaseSectionPropsForBackendV3 = (
  args: GenerateTestCaseSectionPropsForBackendArgs,
): Widget.ProjectTestReportProps["testCaseSection"] => {
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const hooks = ProjectTestReport.useCreateProjectHooks(args.entityId);
  const submission = hooks.useCurrentSubmission();
  const question = hooks.useQuestion();
  const testCasesResults = React.useMemo(() => {
    if (args.ignore || !submission?.correctnessTestResult) {
      return [];
    }
    return ProjectHelperV3.BackendTestCase.parseBackendTestCaseResults(submission.correctnessTestResult)?.test_results;
  }, [args.ignore, submission?.correctnessTestResult]);

  const testCases = React.useMemo(() => {
    if (args.ignore || !question?.correctnessTestCase) {
      return [];
    }
    return ProjectHelperV3.BackendTestCase.parseBackendTestCases(question.correctnessTestCase)?.test_cases ?? [];
  }, [args.ignore, question?.correctnessTestCase]);

  const testCaseFailureMessageMap: Record<"SERVER_HEALTH_CHECK_FAILED" | "EVALUATION_PREPARATION_FAILED", string> = {
    SERVER_HEALTH_CHECK_FAILED: t(
      "サーバーの起動が確認できなかったため評価に失敗しました。ソースコードのビルドに失敗したか、候補者がヘルスチェックの実装に変更を加えた可能性があります。ソースコードをご確認ください。",
    ),
    EVALUATION_PREPARATION_FAILED: t(
      "評価プロセス中にエラーが発生したため評価に失敗しました。候補者が評価用に用意されている既存のエンドポイントの実装に変更を加えた可能性があります。ソースコードをご確認ください。",
    ),
  };

  return {
    scrollTargetElementId: ProjectTestReport.TargetElementIdMap.CORRECT_RATE_SECTION,
    scoreAnswerRateDisplayLabel: {
      numPassed: submission?.numPassed ?? 0,
      numTests: submission?.numTests ?? 0,
    },
    titleWithScoreBar: {
      score: args.showScore ? formatScore(submission?.coverage ?? 0) : undefined,
    },
    content: {
      kind: "BACKEND",
      testCases: (testCasesResults || []).map((testCasesResult, tcIndex): TestCaseProps => {
        const testCase = testCases.at(tcIndex);

        return {
          tcIndex,
          invisible: testCase?.is_hidden ?? false,
          description: resolveLanguage(
            { descriptionJa: testCase?.description_ja, descriptionEn: testCase?.description_en },
            lang,
            "description",
          ),
          status: testCasesResult.is_passed ? "SUCCESS" : "ERROR",
          testResult: {
            kind: "MULTI",
            values: testCasesResult.results.reduce<TestResultValue[]>((all, result, commandIndex) => {
              const testCase = testCases.at(tcIndex);
              if (!testCase) {
                return all;
              }
              const testCaseCommand = testCase.test_case_commands.at(commandIndex);
              if (!testCaseCommand) {
                return all;
              }
              return all.concat({
                index: commandIndex + 1,
                method: testCaseCommand.method,
                path: testCaseCommand.path,
                query: testCaseCommand.query,
                status: result.is_passed ? "SUCCESS" : "ERROR",
                httpStatusCode: result.status.toString(),
                latency: Time.getTimeUnitText(result.latency, Time.Unit.MILLISECOND),
                codeEditor: {
                  value: JSONFormatter.formatJson(testCaseCommand.body ?? "", "{}"),
                  /**
                   * @see https://github.com/suren-atoyan/monaco-react#multi-model-editor
                   */
                  path: `tc-${tcIndex}-command-${commandIndex}`,
                },
                diffEditor: {
                  originalModelPath: `original-test-result-${tcIndex}-command-${commandIndex}`,
                  original: JSONFormatter.prettyJsonString(result.output, "{}"),
                  modifiedModelPath: `modified-test-result-${tcIndex}-command-${commandIndex}`,
                  modified: JSONFormatter.prettyJsonString(result.expected, "{}"),
                },
                alternativeMessage:
                  result.status === NO_RESPONSE_STATUS_CODE
                    ? t(
                        "レスポンスの取得に失敗しました。リクエストの処理中にサーバーが停止した可能性があります。ソースコードをご確認ください。",
                      )
                    : undefined,
              });
            }, []),
          },
          alternativeMessage:
            testCasesResult.status === "FAILED" && testCasesResult.failure_reason !== ""
              ? testCaseFailureMessageMap[testCasesResult.failure_reason]
              : undefined,
        };
      }),
    },
  };
};
