import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateTestRankVisualizerPropsArgs, useGenerateProps } from "./useGenerateProps";

export type ExamRankVisualizerContainerProps = GenerateTestRankVisualizerPropsArgs;

const ExamRankVisualizerContainer: React.FC<ExamRankVisualizerContainerProps> = props => {
  const testRankVisualizerProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.TestRankVisualizer {...testRankVisualizerProps} />
    </ErrorBoundary>
  );
};

ExamRankVisualizerContainer.displayName = "ExamRankVisualizerContainer";

export default withErrorBoundary(ExamRankVisualizerContainer, {});
