type SingleFileFormData = {
  type: "SINGLE";
  file: globalThis.File;
};

type MultiFileFormData = {
  type: "MULTI";
  files: globalThis.File[];
};

export type CreateContentIncludedFormDataArgs = SingleFileFormData | MultiFileFormData;

/**
 * Create a FormData that contains the content.
 * The field name will be “file” when it’s a single file and “files” when there are multiple files.
 */
export const createContentIncludedFormData = (args: CreateContentIncludedFormDataArgs): FormData => {
  const formData = new FormData();
  switch (args.type) {
    case "SINGLE": {
      /** The Field Name is fixed. */
      formData.append("file", args.file);
      return formData;
    }
    case "MULTI": {
      args.files.forEach(file => {
        /** The Field Name is fixed. */
        formData.append("files", file);
      });
      return formData;
    }
    default:
      throw new Error(`Invalid argument: ${args satisfies never}`);
  }
};
