import { useTranslation } from "@hireroo/i18n";
import {
  BackendTestCaseV3,
  type BackendTestCaseV3Props,
  DataScienceTestCase,
  type DataScienceTestCaseProps,
  FrontendTestCase,
  type FrontendTestCaseProps,
} from "@hireroo/project/react/v2/modules";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import * as React from "react";
import Carousel from "react-material-ui-carousel";
import type { CarouselProps } from "react-material-ui-carousel/dist/components/types";

import BlockBuilder, { BlockBuilderProps } from "../../../modules/BlockBuilder/BlockBuilder";
import DetailSwitch, { DetailSwitchProps } from "../../../modules/DetailSwitch/DetailSwitch";
import ReportScoreAnswerRateDisplayLabel, {
  ReportScoreAnswerRateDisplayLabelProps,
} from "../../../modules/ReportScoreAnswerRateDisplayLabel/ReportScoreAnswerRateDisplayLabel";
import TitleWithScoreBar, { TitleWithScoreBarProps } from "../../../modules/TitleWithScoreBar/TitleWithScoreBar";
import ProjectTestCaseAccordion from "../ProjectTestCaseAccordion/ProjectTestCaseAccordion";

type BackendTestCaseContent = {
  kind: "BACKEND";
  testCases: (BackendTestCaseV3Props & { invisible: boolean })[];
};

type FrontendTestCaseContent = {
  kind: "FRONTEND";
  testCases: FrontendTestCaseProps[];
};

type DataScienceTestCaseContent = {
  kind: "DATA_SCIENCE";
  testCases: (DataScienceTestCaseProps & { invisible: boolean })[];
};

type DefaultTestCaseContent = {
  kind: "DEFAULT";
  testCases: BlockBuilderProps;
};

export type ReportProjectTestCaseSectionProps = {
  content: BackendTestCaseContent | FrontendTestCaseContent | DataScienceTestCaseContent | DefaultTestCaseContent;
  titleWithScoreBar: Pick<TitleWithScoreBarProps, "score">;
  scoreAnswerRateDisplayLabel: ReportScoreAnswerRateDisplayLabelProps;
  scrollTargetElementId: string;
};

const useDefaultCarouselPropsForFrontendTestCases = (args: { navButtonsAlwaysVisible: boolean }): CarouselProps => {
  const theme = useTheme();
  return {
    autoPlay: false,
    animation: "slide",
    navButtonsAlwaysVisible: args.navButtonsAlwaysVisible,
    cycleNavigation: false,
    indicatorIconButtonProps: {
      style: {
        marginTop: theme.spacing(4),
        padding: 2,
        cursor: "pointer",
        transition: "200ms",
        color: "#afafaf",
      },
    },
    activeIndicatorIconButtonProps: {
      style: {
        color: theme.palette.secondary.main,
      },
    },
  };
};

const ReportProjectTestCaseSection: React.FC<ReportProjectTestCaseSectionProps> = props => {
  const { t } = useTranslation();
  const { content } = props;
  const [navButtonsAlwaysVisible, setNavButtonsAlwaysVisible] = React.useState(false);
  const carouselPropsForFrontendTestCases = useDefaultCarouselPropsForFrontendTestCases({
    navButtonsAlwaysVisible: !navButtonsAlwaysVisible,
  });
  const [expandAll, setExpandAll] = React.useState(false);
  const detailSwitch: DetailSwitchProps = {
    checked: expandAll,
    onClick: () => {
      setExpandAll(prev => !prev);
    },
  };
  const Content = React.useMemo(() => {
    switch (content.kind) {
      case "BACKEND": {
        const TestCases = content.testCases.map((testCase, index) => {
          const title = `${t("テストケース")} ${index + 1}`;

          return (
            <ProjectTestCaseAccordion
              key={`${title}-${testCase.status}`}
              title={title}
              status={testCase.status}
              forceExpanded={expandAll}
              invisible={testCase.invisible}
            >
              <BackendTestCaseV3 {...testCase} />
            </ProjectTestCaseAccordion>
          );
        });
        return <Box>{TestCases}</Box>;
      }
      case "FRONTEND": {
        const TestCases = content.testCases.map((testCase, index) => {
          return (
            /**
             * When Carousel is nested, css interferes and Backdrop does not function properly.
             * Therefore, use a portal to render the DOM containing the backdrop in a non-interfering location.
             */
            <FrontendTestCase
              key={`frontend-test-case-${index}`}
              {...testCase}
              renderingUnderBody
              onOpenCarousel={open => setNavButtonsAlwaysVisible(open)}
            />
          );
        });
        return (
          <React.Suspense>
            <Carousel {...carouselPropsForFrontendTestCases} indicators={content.testCases.length > 1}>
              {TestCases}
            </Carousel>
          </React.Suspense>
        );
      }
      case "DATA_SCIENCE": {
        const TestCases = content.testCases.map((testCase, index) => {
          const title = `${t("テストケース")} ${index + 1}`;
          return (
            <ProjectTestCaseAccordion
              key={`${title}-${testCase.status}`}
              title={title}
              status={testCase.status}
              invisible={testCase.invisible}
              forceExpanded={expandAll}
            >
              <DataScienceTestCase {...testCase} />
            </ProjectTestCaseAccordion>
          );
        });
        return <Box>{TestCases}</Box>;
      }
      case "DEFAULT": {
        return <BlockBuilder {...content.testCases} />;
      }
      default: {
        return ((): never => content)();
      }
    }
  }, [carouselPropsForFrontendTestCases, content, expandAll, t]);

  const titleWithScoreBarProps: TitleWithScoreBarProps = {
    ...props.titleWithScoreBar,
    title: t("正解率"),
    color: "secondary",
  };

  /**
   * BlockBuilder also contains information on labels
   */
  if (content.kind === "DEFAULT") {
    return <Box>{Content}</Box>;
  }

  return (
    <Box id={props.scrollTargetElementId}>
      <TitleWithScoreBar {...titleWithScoreBarProps} mb={3} />
      <Box display="flex" justifyContent="space-between" mb={2}>
        <ReportScoreAnswerRateDisplayLabel {...props.scoreAnswerRateDisplayLabel} />
        <DetailSwitch {...detailSwitch} />
      </Box>
      {Content}
    </Box>
  );
};

ReportProjectTestCaseSection.displayName = "ReportProjectTestCaseSection";

export default ReportProjectTestCaseSection;
