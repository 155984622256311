import { SKILL_TAG_SIZE } from "./constants";
import { state } from "./State";
import type * as Types from "./types";

export const setResponse = (res: Types.Res): void => {
  state.res = res;
  res.questionObjects.forEach(questionObject => {
    const question = questionObject.question;
    if (question) {
      state.questionMap.set(questionObject.id, question);
      // Initialize skillTagSources in order to detect skillTag duplicated name
      if (question.__typename === "ProjectQuestion") {
        question.skillTags.forEach(skillTag => {
          state.skillTagState.skillTagSources.set(skillTag.skillTagNodeId, skillTag);
        });
      }
    }
  });
};

export const initializeFilters = (filter: Types.SearchFilter, pager: Omit<Types.Pager, "offset">) => {
  state.currentSearchFilter = filter;
  state.pager = {
    ...pager,
    offset: pager.page * pager.size,
  };
};

export const updateLoadingStatus = (status: Types.LoadingStatus) => {
  state.loadingStatus = status;
};

export const updateCurrentSearchFilter = (filter: Types.SearchFilter) => {
  state.currentSearchFilter = filter;
  /** Reset page when search criteria are changed */
  state.pager = {
    ...state.pager,
    page: 0,
    offset: 0,
  };
};

export const updateSortField = (sortFieldValue: Types.SortFieldValue) => {
  state.pager = {
    ...state.pager,
    sortFieldValue: sortFieldValue,
    page: 0,
    offset: 0,
  };
};

export const updatePage = (page: number) => {
  state.pager = {
    ...state.pager,
    page,
    offset: page * state.pager.size,
  };
};

export const updatePageSize = (size: number) => {
  state.pager = {
    ...state.pager,
    page: 0,
    offset: 0,
    size: size,
  };
};

export const deleteQuestion = (questionObjectId: Types.UniqueKey) => {
  state.questionMap.delete(questionObjectId);
  if (state.res) {
    state.res = {
      ...state.res,
      count: state.res.count - 1,
      questionObjects: state.res.questionObjects.filter(questionObject => questionObject.questionObjectId !== questionObjectId),
    };
  }
};

export const updateSkillTags = (res: Types.SkillTagRes) => {
  state.skillTagState.res = res;

  res.skillTagNodes.forEach(skillTag => {
    state.skillTagState.currentSkillTagIdsSet.add(skillTag.skillTagNodeId);
    state.skillTagState.skillTagSources.set(skillTag.skillTagNodeId, skillTag);
  });
};

export const updateSkillTagTextFilter = (textFilter: string) => {
  state.skillTagState.textFilter = textFilter;
};

export const updateSkillTagNextOffset = () => {
  state.skillTagState.nextOffset += SKILL_TAG_SIZE;
};

export const clearCurrentSkillTagState = () => {
  state.skillTagState.res = null;
  state.skillTagState.currentSkillTagIdsSet.clear();
  state.skillTagState.nextOffset = 0;
  state.skillTagState.textFilter = "";
};
