import { useSnapshot } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useListParams = () => {
  return useSnapshot(state).listParams;
};

export const useSize = () => {
  const snapshot = useSnapshotState();
  return snapshot.listParams.size;
};

export const usePage = () => {
  const snapshot = useSnapshotState();
  return snapshot.listParams.page;
};

export const useScreenings = () => {
  const snapshot = useSnapshotState();
  return Array.from(snapshot.screeningMap.values());
};

export const useScreeningMap = () => {
  const snapshot = useSnapshotState();
  return snapshot.screeningMap;
};

export const useSpotsIds = (): Types.SpotId[] => {
  const snapshot = useSnapshotState();
  return snapshot.res?.spots.map(({ id }) => id) ?? [];
};

export const useSpots = () => {
  const snapshot = useSnapshotState();
  const spotIds = useSpotsIds();
  return spotIds.map(id => {
    const spot = snapshot.spotMap.get(id);
    if (!spot) throw new Error(`spot not found: ${id}`);
    return spot;
  });
};

export const useSelectedSpotIds = () => {
  const snapshot = useSnapshotState();
  return snapshot.selectedSpotIds;
};

export const useSelectedSpots = () => {
  const snapshot = useSnapshotState();
  const spotIds = useSelectedSpotIds();
  return spotIds.map(id => {
    const spot = snapshot.spotMap.get(id);
    if (!spot) throw new Error(`spot not found: ${id}`);
    return spot;
  });
};

export const useRefreshKey = () => {
  const snapshot = useSnapshotState();
  return snapshot.refreshKey;
};

export const useFilters = () => {
  const snapshot = useSnapshotState();
  return snapshot.listParams.filters;
};

export const useTags = () => {
  const snapshot = useSnapshotState();
  return snapshot.spotTags?.tags || [];
};

export const useVariables = () => {
  const snapshot = useSnapshotState();
  return snapshot.screeningVariables?.variables || [];
};

export const useSearchTags = () => {
  const snapshot = useSnapshotState();
  return snapshot.searchTags;
};

export const useCount = () => {
  const snapshot = useSnapshotState();
  return snapshot.count;
};

export const useCreators = () => {
  const snapshot = useSnapshotState();
  return snapshot.creators;
};

export const useCreatorMap = () => {
  const creators = useCreators();
  return creators.reduce<Record<string, Types.Creator | undefined>>((all, creator) => {
    return { ...all, [creator.id]: creator };
  }, {});
};

export const useCsvDownload = () => {
  const snapshot = useSnapshotState();
  return snapshot.download;
};

export const useListFetchingStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.listFetchingStatus;
};

export const useInitialized = () => {
  const snapshot = useSnapshotState();
  return snapshot.initialized;
};

export const useResponse = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.res) {
    throw new Error("Please Initialize response");
  }
  return snapshot.res;
};

export const useInitializedV2 = () => {
  const snapshot = useSnapshotState();
  return snapshot.res !== null;
};
