import { useQuestionVariantLabelMap } from "@hireroo/app-helper/question";
import { RemoteInterviewResourceEditorStore } from "@hireroo/app-store/view-domain/RemoteInterviewResourceEditor";
import { QuestionSelectFieldForResourceEditor } from "@hireroo/app-store/widget/e/QuestionSelectFieldForResourceEditor";
import { ViewerAssignField } from "@hireroo/app-store/widget/e/ViewerAssignField";
import { useLanguageCode } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import { Widget } from "@hireroo/presentation";
import * as React from "react";

type Viewer = Widget.RemoteInterviewResourceEditorProps["confirmSection"]["viewers"][0];
type ItemProps = Widget.RemoteInterviewResourceEditorProps["confirmSection"]["selectedQuestions"][0];

export const useGenerateConfirmSectionProps = (): Widget.RemoteInterviewResourceEditorProps["confirmSection"] => {
  const lang = useLanguageCode();
  const submitValues = RemoteInterviewResourceEditorStore.useSubmitValues();
  const remoteQuestionSetup = submitValues.REMOTE_QUESTION_SETUP;
  const reportSetup = submitValues.REPORT_SETUP;
  const employees = ViewerAssignField.useEmployees();
  const employeeGroups = ViewerAssignField.useEmployeeGroups();
  const questions = QuestionSelectFieldForResourceEditor.useQuestionMap();
  const questionVariantLabelMap = useQuestionVariantLabelMap();

  const viewerNames = React.useMemo((): Viewer[] => {
    const viewersField = reportSetup.viewers;
    return viewersField.reduce<Viewer[]>((all, viewer) => {
      if (viewer.value?.type === "EMPLOYEE") {
        const employee = employees.get(viewer.value.employeeId);
        if (!employee) {
          return all;
        }
        return all.concat({
          kind: "USER",
          iconSrc: employee.photoUrl,
          name: employee.displayName || employee.email,
        });
      }
      if (viewer.value?.type === "EMPLOYEE_GROUP") {
        const employeeGroup = employeeGroups.get(viewer.value.groupId);
        if (!employeeGroup) {
          return all;
        }
        return all.concat({
          kind: "GROUP",
          name: employeeGroup.groupName,
        });
      }
      return all;
    }, []);
  }, [employeeGroups, employees, reportSetup.viewers]);

  return {
    name: remoteQuestionSetup.name,
    selectedQuestions: remoteQuestionSetup.entityTracks.reduce<ItemProps[]>((all, entityTrack, index) => {
      /** Currently, Remote Interview does not support anything other than FIXED */
      if (entityTrack.type !== "FIXED") {
        return all;
      }
      const entitySource = entityTrack.entitySource;
      const question = questions.get(entitySource.uniqueKey);
      if (!question) {
        return all;
      }
      const title = [
        `Q${index + 1}`,
        resolveLanguage(question, lang, "title"),
        `(${QuestionSelectFieldForResourceEditor.getVersion(question)})`,
      ].join(" ");
      switch (question.__typename) {
        case "SystemDesignQuestion": {
          if (entitySource.type !== "REMOTE_SYSTEM_DESIGN") {
            return all;
          }
          return all.concat({
            title,
            variant: `${questionVariantLabelMap.SYSTEM_DESIGN}`,
          });
        }
        case "AlgorithmQuestion": {
          if (entitySource.type !== "REMOTE_ALGORITHM") {
            return all;
          }
          return all.concat({
            title,
            variant: `${questionVariantLabelMap.CHALLENGE}`,
          });
        }
        case "FreepadQuestion": {
          if (entitySource.type !== "REMOTE_FREEPAD") {
            return all;
          }
          return all.concat({
            title,
            variant: `${questionVariantLabelMap.FREEPAD}`,
          });
        }
      }
      return all;
    }, []),
    candidateName: remoteQuestionSetup.candidateName,

    memo: reportSetup.memo || undefined,
    viewers: viewerNames,
    tags: reportSetup.tags.map(t => t.value.name),
  };
};
