import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DeleteMetricDialog, { DeleteMetricDialogProps } from "../../../../ms-components/Evaluation/DeleteMetricDialog/DeleteMetricDialog";
import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "../../../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";
import MetricsGroupsEditorDialog, { MetricsGroupsEditorDialogProps } from "../MetricsGroupsEditorDialog/MetricsGroupsEditorDialog";

const StyledTypography = styled(Typography)(() => ({
  fontSize: "14px",
  whitespace: "wrap",
}));

export type MetricsGroupsTableRowProps = {
  id: string;
  checkbox: Pick<CheckboxProps, "disabled" | "title" | "checked" | "onChange">;
  title: string;
  description: string;
  updatedAt: string;
  items: string[];
  editButton: Pick<IconButtonWithTooltipProps, "disabled" | "onClick">;
  editDialog: MetricsGroupsEditorDialogProps;
  deleteButton: Pick<IconButtonWithTooltipProps, "disabled" | "onClick">;
  deleteDialog: DeleteMetricDialogProps;
};

const MetricsGroupsTableRow: React.FC<MetricsGroupsTableRowProps> = props => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const checkboxProps: CheckboxProps = {
    ...props.checkbox,
    color: "secondary",
    size: "small",
  };
  const editButton: IconButtonWithTooltipProps = {
    ...props.editButton,
    title: t("編集"),
    children: <EditOutlinedIcon fontSize="small" />,
  };
  const deleteButton: IconButtonWithTooltipProps = {
    ...props.deleteButton,
    title: t("削除"),
    children: <DeleteOutlineIcon fontSize="small" />,
  };

  return (
    <TableRow>
      <TableCell padding={"checkbox"}>
        <Tooltip title={props.checkbox.title ?? ""}>
          <span>
            <Checkbox {...checkboxProps} />
          </span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <StyledTypography
          fontWeight={700}
          sx={{
            overflowWrap: "break-word",
            overflowY: "auto",
            maxHeight: "150px",
          }}
        >
          {props.title}
        </StyledTypography>
      </TableCell>
      <TableCell sx={{ minWidth: "200px", maxWidth: "600px" }}>
        <Typography color="textSecondary" fontSize={14} sx={{ overflowWrap: "break-word", overflowY: "auto", maxHeight: "150px" }}>
          {props.description}
        </Typography>
      </TableCell>
      <TableCell sx={{ minWidth: "150px" }}>
        {props.items.length === 0 && <StyledTypography>{t("評価項目がありません。")}</StyledTypography>}
        {props.items.length === 1 && <StyledTypography>{props.items[0]}</StyledTypography>}
        {props.items.length > 1 && (
          <Tooltip title={props.items.join(t("、"))}>
            <StyledTypography>
              {t2("ShowOneWithOther", {
                item: props.items[0],
                num: props.items.length - 1,
              })}
            </StyledTypography>
          </Tooltip>
        )}
      </TableCell>
      <TableCell>
        <StyledTypography minWidth="115px">{props.updatedAt}</StyledTypography>
      </TableCell>
      <TableCell align="right">
        <Stack direction="row" spacing={1.5}>
          <IconButtonWithTooltip key="edit-button" {...editButton} />
          <IconButtonWithTooltip key="delete-button" {...deleteButton} />
        </Stack>
      </TableCell>
      <MetricsGroupsEditorDialog key={`${props.id}-edit-${props.editDialog.dialog.open}`} {...props.editDialog} />
      <DeleteMetricDialog {...props.deleteDialog} />
    </TableRow>
  );
};

MetricsGroupsTableRow.displayName = "MetricsGroupsTableRow";

export default MetricsGroupsTableRow;
