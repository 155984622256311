import { ExtendedCodeEditor, ExtendedCodeEditorProps } from "@hireroo/project/react/v2/widget";
import Box from "@mui/material/Box";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";

export type ProjectExtendedCodeEditorProps = Omit<ExtendedCodeEditorProps, "style">;

const ProjectExtendedCodeEditor: React.FC<ProjectExtendedCodeEditorProps> = props => {
  const resizeDetector = useResizeDetector({
    handleHeight: true,
    handleWidth: true,
  });
  const extendedCodeEditorProps: ExtendedCodeEditorProps = {
    ...props,
    style: {
      height: resizeDetector.height,
      width: resizeDetector.width,
    },
  };

  return (
    <Box width={"100%"} height={"100%"} ref={resizeDetector.ref} zIndex={2000}>
      <ExtendedCodeEditor {...extendedCodeEditorProps} />
    </Box>
  );
};

ProjectExtendedCodeEditor.displayName = "ProjectExtendedCodeEditor";

export default ProjectExtendedCodeEditor;
