import { Widget } from "@hireroo/presentation/legacy";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import { useGenerateProviderProps } from "./useGenerateProviderProps";

export type QuizPackageDetailProviderProps = {
  type: "CREATE" | "UPDATE";
};

const QuizPackageDetailProvider: React.FC<React.PropsWithChildren<QuizPackageDetailProviderProps>> = props => {
  const providerProps = useGenerateProviderProps({
    type: props.type,
  });
  return (
    <ErrorBoundary>
      <Widget.QuizPackageDetailProvider {...providerProps} children={props.children} />
    </ErrorBoundary>
  );
};

QuizPackageDetailProvider.displayName = "QuizPackageDetailProvider";

export default withErrorBoundary(QuizPackageDetailProvider, {});
