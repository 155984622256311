import { proxy } from "valtio";
import { proxyMap } from "valtio/utils";

import type * as Types from "./types";

export const state = proxy<Types.State>({
  challenges: proxyMap(),
  submittedEntity: null,
  loadingStatus: "NONE",
  ipAddress: null,
  geolocation: null,
  enableWebSearch: false,
  enableChatGPT: false,
  enableHint: true,
  webSearchParameters: {
    query: null,
    page: null,
  },
  chatGPTLoadingStatus: "NONE",
});
