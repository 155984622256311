import { composeTextOperation, useProjectRevisions } from "@hireroo/app-helper/hooks";
import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import * as Time from "@hireroo/formatter/time";
import type { Widget } from "@hireroo/presentation";
import * as monaco from "monaco-editor";
import * as React from "react";

import * as PrivateHelper from "./privateHelper";

const { ProjectContentsViewerV1 } = ProjectTestReport;
type ProjectPlaybackProps = Extract<Widget.ProjectContentsViewerV1Props, { mode: "PLAYBACK" }>["content"];

type ItemProps = ProjectPlaybackProps["items"][0];

export type GenerateProjectPlaybackPropsArgs = {
  projectId: number;
  questionId: number;
  active: boolean;
};

export const useGenerateProjectPlaybackProps = (args: GenerateProjectPlaybackPropsArgs): ProjectPlaybackProps => {
  const hooks = ProjectContentsViewerV1.createHooks(args.projectId);
  const file = hooks.useCurrentSelectFilename();
  // editorRef refers original playback editor and expandedEditorRef refers expanded playback editor.
  // During playback, text is applied only to the model of the editor which is displayed,
  // and when editor is expanded or shrunk, text of the previous editor is copied to the new one.
  const editorRef = React.useRef<monaco.editor.IStandaloneCodeEditor | null>(null);
  const expandedEditorRef = React.useRef<monaco.editor.IStandaloneCodeEditor | null>(null);
  const monacoRef = React.useRef<typeof monaco>();

  const [revisions] = useProjectRevisions(args.projectId, args.questionId);
  const [revisionIndex, setRevisionIndex] = React.useState(0);

  const hasHistoryFiles = React.useMemo(() => {
    return Object.keys(revisions);
  }, [revisions]);

  const syncOperations = React.useMemo(() => {
    return file in revisions ? revisions[file] : undefined;
  }, [file, revisions]);

  // When playback text operations should be applied to the editor one by one
  // since if the entire text are applied to the editor, syntax highlight will flush every time

  // e.g. Assume that there are 0 to 9 operations.
  // When playing forward from the beginning to the end, 1 to 9 operations are applied one by one
  // and when playing backward from the end to the beginning, 9 to 1 inverse operations are applied one by one.
  // It starts with the last index initially, there is no need to apply the 0th operation.
  const handlePlaybackValue = React.useCallback(
    (newIndex: number) => {
      setRevisionIndex(newIndex);
      const model = editorRef.current?.getModel();
      const modelInDialog = expandedEditorRef.current?.getModel();
      if (!monacoRef.current || newIndex === revisionIndex || !syncOperations) return;
      if (model) {
        PrivateHelper.updateEditorValue({
          model: model,
          newIndex: newIndex,
          sliderIndex: revisionIndex,
          monaco: monacoRef.current,
          syncOperations,
        });
      }
      if (modelInDialog) {
        PrivateHelper.updateEditorValue({
          model: modelInDialog,
          newIndex: newIndex,
          sliderIndex: revisionIndex,
          monaco: monacoRef.current,
          syncOperations,
        });
      }
    },
    [revisionIndex, syncOperations],
  );

  const timeParameter = React.useMemo(() => {
    const current = syncOperations && syncOperations.length > revisionIndex ? syncOperations[revisionIndex].t : 0;
    const start = syncOperations && syncOperations.length > 0 ? syncOperations[0].t : 0;
    const end = syncOperations && syncOperations.length > 0 ? syncOperations[syncOperations.length - 1].t : 0;
    const passedTimeMilliseconds = current - start;
    const remainTimeMilliSeconds = end - start;
    return {
      passedTimeMilliseconds: passedTimeMilliseconds > 0 ? passedTimeMilliseconds : 0,
      remainTimeMilliSeconds: remainTimeMilliSeconds > 0 ? remainTimeMilliSeconds : 0,
    };
  }, [revisionIndex, syncOperations]);

  const playbackToolbarProps = React.useMemo((): ProjectPlaybackProps["items"][0]["playbackToolbar"] => {
    return {
      value: revisionIndex,
      onChangePlaybackValue: handlePlaybackValue,
      slider: {
        min: 0,
        max: syncOperations ? syncOperations.length - 1 : 0,
        marks: [],
      },
      enableAutoplay: true,
      screenButton: {},
      passedTime: Time.elapsedTimeFormat(timeParameter.passedTimeMilliseconds),
      remainTime: timeParameter.remainTimeMilliSeconds > 0 ? Time.elapsedTimeFormat(timeParameter.remainTimeMilliSeconds) : "",
    };
  }, [handlePlaybackValue, revisionIndex, syncOperations, timeParameter.passedTimeMilliseconds, timeParameter.remainTimeMilliSeconds]);

  const editorValueInDialog = React.useMemo(() => {
    const target = file in revisions ? revisions[file] : undefined;
    return target ? composeTextOperation(target.length - 1, target) : "";
  }, [file, revisions]);

  React.useEffect(() => {
    const model = editorRef.current?.getModel();
    const expandedModel = expandedEditorRef.current?.getModel();
    // Copy the expanded editor value to the original one when the expanded one is unmounted
    if (model && expandedModel && !open) {
      model.setValue(expandedModel.getValue());
    }
  }, []);

  const items = React.useMemo(() => {
    return hasHistoryFiles.map((filename, index): ItemProps => {
      const target = filename in revisions ? revisions[filename] : undefined;
      const finalPlaybackValue = target && target.length > 0 ? composeTextOperation(target.length - 1, target) : "";
      return {
        value: filename,
        editor: {
          path: `tab-${index}-${filename}`,
          onMount: (editor, monaco) => {
            editorRef.current = editor;
            monacoRef.current = monaco;
            target && setRevisionIndex(target.length - 1);
          },
          defaultValue: finalPlaybackValue,
        },
        playbackToolbar: playbackToolbarProps,
      };
    });
  }, [hasHistoryFiles, playbackToolbarProps, revisions]);

  return {
    items: items,
    typeDefs: {
      react: TYPE_DEF_REACT,
    },
    playbackDialog: {
      editor: {
        path: `dialog-${file}`,
        defaultValue: editorValueInDialog,
        onMount: editor => {
          expandedEditorRef.current = editor;
          const expandedModel = editor.getModel();
          const model = editorRef.current?.getModel();
          // Copy the original editor value to the expanded one when the expanded one is mounted
          if (expandedModel && model) {
            expandedModel.setValue(model.getValue());
          }
        },
      },
      playbackToolbar: playbackToolbarProps,
    },
  };
};
