import { state } from "./State";
import type * as Types from "./types";

export const clear = () => {
  state.currentCreatingMultiChoicePackage = null;
  state.currentUpdatingMultiChoicePackage = null;
};

export const setCreatingMultiChoicePackage = (targetPackage: Types.CreatingMultiChoicePackage) => {
  state.currentCreatingMultiChoicePackage = targetPackage;
};

export const setUpdatingMultiChoicePackage = (targetPackage: Types.UpdatingMultiChoicePackage) => {
  state.currentUpdatingMultiChoicePackage = targetPackage;
};
