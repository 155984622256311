import { state } from "./State";
import type * as Types from "./types";

export const clear = () => {
  state.initializeError = null;
};

export const setError = (initializeError: Types.InitializeError) => {
  state.initializeError = initializeError;
};

export const setUser = (user: Types.User): void => {
  state.currentUser = user;
};
