import { matchPath as matchPathApi } from "react-router";

import { RouteKey } from "../config/app";

export const currentWindowMatchPath = (pattern: RouteKey, pathname = window.location.pathname) => {
  return matchPathApi(
    {
      path: pattern,
      caseSensitive: true,
    },
    pathname,
  );
};
