import { useFirepad } from "@hireroo/app-helper/hooks";
import { languageMapForHighlight } from "@hireroo/challenge/definition";
import type { Widget } from "@hireroo/presentation";

type OnMount = Widget.ChallengePlaybackEditorProps["onMount"];

export type GenerateChallengePlaybackEditorPropsArgs = {
  firepad: {
    entityId: number;
    questionId: number;
    language: string;
    uid: string;
    displayName: string;
  };
  defaultValue?: string;
  editorValueDidChange?: (value: string) => void;
  editorDidMount?: OnMount;
};

export const useGenerateProps = (args: GenerateChallengePlaybackEditorPropsArgs): Widget.ChallengePlaybackEditorProps => {
  const { uid, entityId, questionId, language, displayName } = args.firepad;
  const { defaultValue } = args;
  const [, padAction] = useFirepad({
    db: "challenge",
    key: `challenges/${entityId}/questions/${questionId}/languages/${language}`,
    userName: displayName,
    readOnly: false,
  });

  return {
    height: "100vh",
    language: languageMapForHighlight[language],

    onChange: value => {
      args.editorValueDidChange?.(value || "");
    },
    options: {
      readOnly: false,
    },
    onMount: (editor, monaco) => {
      //defaultValue
      padAction.initPad(uid, editor, defaultValue);

      // User defined logic goes below
      args.editorDidMount?.(editor, monaco);

      // Following lines fix an issue between different platform syncs.
      // https://github.com/interviewstreet/firepad-x/issues/41
      // https://github.com/FirebaseExtended/firepad/issues/315
      const model = editor.getModel();
      model?.setEOL(monaco.editor.EndOfLineSequence.LF);
      model?.applyEdits([
        {
          range: model?.getFullModelRange(),
          text: model?.getLinesContent().join("\n"),
        },
      ]);
    },
  };
};
