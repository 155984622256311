import { useTranslation } from "@hireroo/i18n";
import WarningIcon from "@mui/icons-material/Warning";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import BankField, { BankFieldProps } from "../../../../../../ms-components/Payment/BankField/BankField";
import CreditCardIcon from "../../../../../../primitive/CreditCardIcon/CreditCardIcon";

const Wrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: 4,
  backgroundColor: theme.palette["Secondary/Shades"].p8,
  width: "100%",
}));

const MainWrapper = styled(Wrapper)(() => ({
  minWidth: 500,
}));

const LabelField = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  display: "inline-block",
  color: theme.palette.text.secondary,
  fontWeight: 500,
}));

const ValueField = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  display: "inline-block",
  color: theme.palette.text.primary,
  fontWeight: 400,
}));

const CreditCardBox = styled(Box)(() => ({
  fontSize: 14,
  display: "inline-block",
  width: "100%",
}));

const WarningMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.warning.main,
  display: "inline",
  svg: {
    position: "relative",
    top: 3,
    width: 18,
    height: 18,
    marginRight: "4px",
  },
}));

type CreditCardItem = {
  lastFour: string;
  warningMessage?: string;
};

export type BillingInformationProps = {
  companyName: string;
  mailAddress: string;
  address: string;
  paymentMethod?: { kind: "CREDIT_CARD"; field: CreditCardItem } | { kind: "BANK"; field: BankFieldProps };
};

const BillingInformation: React.FC<BillingInformationProps> = props => {
  const { t } = useTranslation();
  return (
    <MainWrapper>
      <Stack justifyContent="space-between" direction="column" width="100%" mb={1.5}>
        {props.paymentMethod?.kind === "CREDIT_CARD" && (
          <CreditCardBox>
            <Stack direction="row" alignItems="center">
              <CreditCardIcon />
              <Typography variant="body2" fontWeight="bold" noWrap ml={2}>
                **** **** **** {props.paymentMethod.field.lastFour}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              {props.paymentMethod.field.warningMessage && (
                <WarningMessage variant="body2">
                  <WarningIcon />
                  {props.paymentMethod.field.warningMessage}
                </WarningMessage>
              )}
            </Stack>
          </CreditCardBox>
        )}
        {props.paymentMethod?.kind === "BANK" && (
          <Box width="100%">
            <BankField {...props.paymentMethod.field} />
          </Box>
        )}
      </Stack>

      <Wrapper>
        <Stack direction="row" spacing={1.5}>
          <Box width="100%">
            <LabelField mr={1} noWrap>
              {t("請求情報")}
            </LabelField>
            <Stack direction="row" justifyContent="space-between" mb={1}>
              <LabelField mr={1} noWrap>
                {t("企業名")}
              </LabelField>
              <ValueField>{props.companyName}</ValueField>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <LabelField mr={1} noWrap>
                {t("請求先メールアドレス")}
              </LabelField>
              <ValueField noWrap>{props.mailAddress}</ValueField>
            </Stack>
          </Box>
          <Box width="100%">
            <LabelField noWrap>{t("住所")}</LabelField>
            <Box>
              <ValueField>{props.address}</ValueField>
            </Box>
          </Box>
        </Stack>
      </Wrapper>
    </MainWrapper>
  );
};

BillingInformation.displayName = "BillingInformation";

export default BillingInformation;
