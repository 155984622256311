import { QUERY_PARAMS_FOR_DEMO } from "@hireroo/app-definition/demo";
import { Auth } from "@hireroo/app-store/essential/candidate";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useTranslation } from "@hireroo/i18n";
import { Pages } from "@hireroo/presentation";
import { updateQueryParams } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import { StartDemoForm } from "@hireroo/validator";
import * as Sentry from "@sentry/react";
import * as React from "react";

import CandidateFooterContainer from "../../../../widget/v2/c/CandidateFooter/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import * as PrivateHelper from "./privateHelper";

type Status = "PENDING" | "VALID_DEMO_ID_EXISTS" | "NEED_A_NEW_DEMO";

export type GenerateDemoStartPropsArgs = {
  demoParamsByQueryParams: StartDemoForm.StartDemoParams | null;
};

export const useGenerateProps = (args: GenerateDemoStartPropsArgs): Pages.ScreeningTestProps => {
  const { demoParamsByQueryParams } = args;
  const uid = Auth.useCurrentUid();
  const { t } = useTranslation();
  const navigate = useTransitionNavigate();
  const client = getGraphqlClient();
  const [status, setStatus] = React.useState<Status>("PENDING");

  React.useEffect(() => {
    if (!demoParamsByQueryParams) {
      // At this time, ErrorPanel is displayed and does not handle.
      return;
    }
    const storageKeyParams: PrivateHelper.GenerateStorageKeyParams = (() => {
      switch (demoParamsByQueryParams.kind) {
        case "SCREENING":
          return {
            kind: "SCREENING",
            id: demoParamsByQueryParams.screeningId,
          };
        case "SPOT":
          return {
            kind: "SPOT",
            id: demoParamsByQueryParams.spotId,
          };
        default:
          throw new Error(`demoParamsByQueryParams is invalid: ${demoParamsByQueryParams satisfies never}`);
      }
    })();
    const params = PrivateHelper.restoreDemoParams(storageKeyParams);
    if (!params) {
      setStatus("NEED_A_NEW_DEMO");
      return;
    }
    client
      .GetDemoForCandidateDemosStart({
        demoId: params.demoId,
        candidateId: uid ?? "",
      })
      .then(res => {
        if (res.demo.status === "STARTED") {
          setStatus("VALID_DEMO_ID_EXISTS");
          PrivateHelper.saveDemoParams(storageKeyParams, {
            demoId: res.demo.demoId,
          });
          navigate("/c/demos/:id", {
            pathParams: {
              id: res.demo.demoId,
            },
          });
        }
      })
      .catch(() => {
        // Error handling is not needed.
        setStatus("NEED_A_NEW_DEMO");
      });
  }, [client, demoParamsByQueryParams, navigate, uid]);

  React.useEffect(() => {
    if (demoParamsByQueryParams === null) {
      Snackbar.notify({
        severity: "error",
        message: t("無効なURLです"),
      });
      return;
    }
    if (status !== "NEED_A_NEW_DEMO") {
      // Snackbar is not needed
      return;
    }
    switch (demoParamsByQueryParams.kind) {
      case "SCREENING": {
        client
          .CreateDemoFromScreeningForScreeningId({
            input: {
              screeningId: demoParamsByQueryParams.screeningId,
              invitationCode: demoParamsByQueryParams.invitationCode,
            },
          })
          .then(res => {
            PrivateHelper.saveDemoParams(
              {
                kind: "SCREENING",
                id: demoParamsByQueryParams.screeningId,
              },
              {
                demoId: res.createDemoFromScreening.demoId,
              },
            );
            navigate("/c/demos/:id", {
              pathParams: {
                id: res.createDemoFromScreening.demoId,
              },
            });
          })
          .catch(error => {
            Snackbar.notify({
              severity: "error",
              message: t("デモの開始に失敗しました"),
            });
            Sentry.captureException(error);
          });
        return;
      }
      case "SPOT": {
        client
          .CreateDemoFromSpot({
            spotId: demoParamsByQueryParams.spotId,
            candidateId: uid ?? "",
          })
          .then(res => {
            PrivateHelper.saveDemoParams(
              {
                kind: "SPOT",
                id: demoParamsByQueryParams.spotId,
              },
              {
                demoId: res.createDemoFromSpot.demoId,
              },
            );
            navigate("/c/demos/:id", {
              pathParams: {
                id: res.createDemoFromSpot.demoId,
              },
            });
          })
          .catch(error => {
            Snackbar.notify({
              severity: "error",
              message: t("デモの開始に失敗しました"),
            });
            Sentry.captureException(error);
          });
        return;
      }
    }
  }, [client, demoParamsByQueryParams, navigate, status, t, uid]);

  React.useEffect(() => {
    updateQueryParams(QUERY_PARAMS_FOR_DEMO.key, []);
  }, []);

  return {
    layout: {
      loading: true,
      NotificationBanner: null,
      Header: null,
      Footer: <CandidateFooterContainer />,
      Snackbar: <SnackbarContainer />,
      Tutorial: null,
    },
    Content: null,
  };
};
