import { startTransition, useCallback } from "react";
import { useLocation, useNavigate as useNavigateApi } from "react-router-dom";

import { currentWindowMatchPath, generatePath } from "../api";
import type { PathParam } from "../api/types";
import routes, { RouteKey } from "../config/app";
import helpCenterUrlMap, { HelpCenterKey } from "../config/helpCenter";
import type { WindowOpenOption } from "../types";

/**
 * Transition in the state containing React's startTransition
 */
export const useTransitionNavigate = () => {
  const navigate = useNavigateApi();
  return async <T extends RouteKey>(
    routeKey: T,
    options?: {
      pathParams?: { [key in PathParam<T>]: string };
      queryParams?: Record<string, string | number>;
    },
  ) => {
    const nextPath = generatePath<T>(routeKey, options);
    new Promise<void>(resolve => {
      startTransition(() => {
        navigate(nextPath);
        resolve();
      });
    });
  };
};

/**
 * Transition in the state containing React's startTransition
 */
export const useTransitionNavigateWithRawUri = () => {
  const navigate = useNavigateApi();
  return async (uri: string) => {
    new Promise<void>(resolve => {
      startTransition(() => {
        navigate(uri);
        resolve();
      });
    });
  };
};

/**
 * Go back a specified number of pages
 */
export const useGo = () => {
  const navigate = useNavigateApi();
  return (value: number) => {
    navigate(value);
  };
};

/**
 * Back only 1 page
 */
export const useGoBack = () => {
  const go = useGo();
  return () => go(-1);
};

export const useHelpCenterNavigate = (lang: string) => {
  const navigate = useCallback(
    (key: HelpCenterKey, options?: WindowOpenOption) => {
      const url = helpCenterUrlMap[key][lang];
      const args: string[] = [url, options?._blank ? "_blank" : "_self"];
      window.open(...args);
    },
    [lang],
  );
  return navigate;
};

export const useNavigate = () => {
  return (url: string, options?: WindowOpenOption) => {
    const args: string[] = [url, options?._blank ? "_blank" : "_self"];
    window.open(...args);
  };
};

export const useNavigatePath = () => {
  return <T extends RouteKey>(
    routeKey: T,
    options?: { pathParams?: { [key in PathParam<T>]: string }; queryParams?: Record<string, string | number> },
  ) => {
    return generatePath<T>(routeKey, options);
  };
};

/**
 * The more path params with `:` in them, the more abstract they are treated.
 *
 * @example
 *
 * IN  : ["/e/questions/:entityType/:id/update", "/e/questions/project/:id", "/e/questions/:entityType/:id"]
 * OUT : ["/e/questions/project/:id", "/e/questions/:entityType/:id", "/e/questions/:entityType/:id/update"]
 */
const sortedPatterns = (a: string, b: string) => {
  const left = a.split("/").filter(p => p.startsWith(":")).length;
  const right = b.split("/").filter(p => p.startsWith(":")).length;
  if (left === right) {
    /**
     * Compare by string length
     */
    return a.length - b.length;
  }
  return left - right;
};

export const useMatchedPattern = (): RouteKey | null => {
  const location = useLocation();
  const sortedRouteKeys = Object.keys(routes).sort(sortedPatterns) as RouteKey[];
  return sortedRouteKeys.find(key => !!currentWindowMatchPath(key, location.pathname)) as RouteKey | null;
};
