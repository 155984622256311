import { useSplitContext } from "../Context";
import type * as Types from "../types";
import { useResizeByMinimizeMethod } from "./useResizeByMinimizeMethod";

type MinimizeOption = {
  freezeContentSize?: boolean;
};

export const useMethods = () => {
  const { store } = useSplitContext();
  const resize = useResizeByMinimizeMethod();
  return {
    minimize: (splitId: string, contentId: string, options?: MinimizeOption) => {
      const { freezeContentSize = false } = options || {};
      const id: Types.ContentId = `${splitId}/Content/${contentId}`;
      const contentState = store.getContentStateRequired(id);
      if (!contentState) {
        return;
      }
      if (!contentState.minimizedSize) {
        console.warn(`It does not work because minimizedSize is undefined. contentId=${contentId}`);
        return;
      }
      store.setContentSize(id, contentState.minimizedSize);
      store.setContentState(id, {
        mode: "FIXED",
        freeze: freezeContentSize,
      });
      resize({
        splitId,
      });
    },
    expand: (splitId: string, contentId: string) => {
      const id: Types.ContentId = `${splitId}/Content/${contentId}`;
      const contentState = store.getContentStateRequired(id);
      if (!contentState) {
        console.warn(`Not Found Content: splitId=${splitId}, contentId=${contentId}`);
        return;
      }
      if (!contentState.expandedSize) {
        console.warn(`It does not work because expandedSize is undefined. contentId=${contentId}`);
        return;
      }
      const splitSize = store.getSplitSize(contentState.splitId);
      if (contentState.expandedSize.unit === "%") {
        store.setContentSize(id, {
          value: (contentState.expandedSize.value / 100) * splitSize,
          unit: "px",
        });
        store.setContentState(id, {
          mode: "FLEXIBLE",
          freeze: false,
        });
      } else {
        store.setContentSize(id, contentState.expandedSize);
        store.setContentState(id, {
          mode: "FIXED",
          freeze: false,
        });
      }
      resize({
        splitId,
      });
    },
    subscribeChangeEvent: (splitId: Types.SplitId, callback: Types.ChangeEventHandler) => {
      store.registerChangeEventHandler(splitId, callback);
      return () => {
        store.unregisterChangeEventHandler(splitId, callback);
      };
    },
  };
};
