import { useTranslation } from "@hireroo/i18n";
import Markdown from "@hireroo/markdown-v2/react";
import CloseIcon from "@mui/icons-material/Close";
import OpenInNewOutlined from "@mui/icons-material/OpenInNewOutlined";
import Box from "@mui/material/Box";
import { ButtonProps } from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DifficultyStars, { DifficultyStarsProps } from "../../../modules/DifficultyStars/DifficultyStars";
import RestrictableTab, { RestrictableTabProps } from "../../../modules/RestrictableTab/RestrictableTab";
import IconButtonWithTooltip from "../../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";
import QuestionArchivedBadge from "../QuestionArchivedBadge/QuestionArchivedBadge";
import QuestionHintStack, { QuestionHintStackProps } from "../QuestionHintStack/QuestionHintStack";
import QuestionInformationSidePanel, { QuestionInformationSidePanelProps } from "../QuestionInformationSidePanel/QuestionInformationSidePanel";

const StyledQuestionInformation = styled(QuestionInformationSidePanel)(({ theme }) => ({
  backgroundColor: theme.palette["Background/Paper"].p8,
  borderRadius: "8px",
}));

const StyledInfoBox = styled(Box)(({ theme }) => ({
  margin: "24px",
  borderRadius: "8px",
  border: "1px solid",
  borderColor: theme.palette.divider,
}));

export type QuestionDetailDrawerProps = {
  open: boolean;
  onClose: () => void;
  openButton?: Pick<ButtonProps, "href">;
  title: string;
  description: string;
  variantText: string;
  difficulty: DifficultyStarsProps["difficulty"];
  info: QuestionInformationSidePanelProps;
  hintStack?: QuestionHintStackProps;
  answerContents: RestrictableTabProps["items"];
  isArchived: boolean;
};

const QuestionDetailDrawer: React.FC<QuestionDetailDrawerProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  /**
   * Cache because it may be re-rendered due to changes in other props
   */
  const MarkdownCache = React.useMemo(() => {
    return (
      <React.Suspense>
        <Markdown size="middle" children={props.description} />
      </React.Suspense>
    );
  }, [props.description]);

  const handleClose = () => {
    props.onClose();
  };
  const tab: RestrictableTabProps = {
    variant: "OUTLINED",
    tabBox: {
      sx: {
        paddingLeft: "24px",
        paddingRight: "24px",
      },
    },
    tabPanelBox: {
      sx: {
        pt: 0,
      },
    },
    items: [
      {
        id: "description",
        Component: (
          <Box p={3}>
            {MarkdownCache}
            {props.hintStack && (
              <Box mt={3}>
                <Typography variant="h6" fontWeight={700}>
                  {t("ヒント")}
                </Typography>
                <QuestionHintStack {...props.hintStack} />
              </Box>
            )}
          </Box>
        ),
        label: {
          name: t("説明"),
        },
      },
      ...props.answerContents,
      {
        id: "info",
        Component: (
          <StyledInfoBox>
            <StyledQuestionInformation {...props.info} />
          </StyledInfoBox>
        ),
        label: {
          name: t("この問題について"),
        },
      },
    ].flatMap(v => (v ? [v] : [])),
  };
  return (
    <Drawer
      anchor="right"
      sx={{ zIndex: theme.zIndex.drawer + theme.zIndex.appBar }}
      PaperProps={{
        sx: {
          width: "640px",
          minWidth: "640px",
          background:
            "var(--dark-background-paper-elevation-4, linear-gradient(180deg, rgba(255, 255, 255, 0.09) 0%, rgba(255, 255, 255, 0.09) 100%), #090909)",
        },
      }}
      open={props.open}
      onClose={handleClose}
    >
      <Stack direction="column" width="100%">
        <Box display="flex" justifyContent="space-between" px={2} mb={1.5} width="100%">
          <Stack direction="column" mt={1.5} spacing={1.5} width="100%">
            <Stack direction="row" justifyContent="space-between" width="100%">
              <Stack direction="row" spacing={0.5} display="flex" alignItems="center">
                {props.openButton && (
                  <IconButton href={props.openButton.href ?? ""} target="_blank" rel="noreferrer">
                    <OpenInNewOutlined color="secondary" sx={{ fontSize: "0.8em" }} />
                  </IconButton>
                )}
                <Typography variant="h6">{props.title}</Typography>
              </Stack>
              <IconButtonWithTooltip onClick={handleClose}>
                <CloseIcon />
              </IconButtonWithTooltip>
            </Stack>
            <Stack direction="row" display="flex" alignItems="center" divider={<Divider orientation="vertical" />} spacing={2}>
              <Typography variant="caption" fontSize={12} color={theme.palette.text.primary}>
                {props.variantText}
              </Typography>
              <DifficultyStars difficulty={props.difficulty} />
            </Stack>
            {props.isArchived && <QuestionArchivedBadge />}
          </Stack>
        </Box>
        <RestrictableTab {...tab} />
      </Stack>
    </Drawer>
  );
};

QuestionDetailDrawer.displayName = "QuestionDetailDrawer";

export default QuestionDetailDrawer;
