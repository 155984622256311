import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { PaymentForm } from "@hireroo/validator";
import Box, { BoxProps } from "@mui/material/Box";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useController } from "react-hook-form";

import SelectionItem, { SelectionItemProps } from "./parts/SelectionItem/SelectionItem";

const BORDER_RADIUS = 8;

const StyledTableContainer = styled(TableContainer)(() => ({}));

const StyledTable = styled(Table)(({ theme }) => ({
  overflow: "hidden",
  borderRadius: BORDER_RADIUS,
  border: `1px solid ${theme.palette.Other.Divider}`,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.Other.Divider}`,
}));

const StyledTableFooter = styled(TableFooter)(({ theme }) => ({
  backgroundColor: theme.palette["Secondary/Shades"].p8,
  paddingTop: 8,
  paddingBottom: 8,
}));

const StyledText = styled("span")(({ theme }) => ({
  fontWeight: "bold",
  fontSize: 16,
  color: theme.palette.text.primary,
}));

const StyledListItemText = styled(ListItemText)(() => ({
  flex: "none",
  ".MuiListItemText-primary": {
    whiteSpace: "nowrap",
    wordBreak: "keep-all",
    textAlign: "right",
  },
  ".MuiListItemText-secondary": {
    whiteSpace: "nowrap",
    wordBreak: "keep-all",
    textAlign: "right",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: 12,
  color: theme.palette.text.secondary,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
}));

type Item = {
  /**
   * 3000000
   */
  price: number;

  fieldLabel: string;
  /**
   * @example "¥3,000,000"
   */
  priceLabel: string;
  /**
   * @example "¥2,000"
   */
  unitPriceLabel: string;
  /**
   * @example "¥2,00,000"
   */
  totalPriceLabel: string;
};

type ItemMap = Record<PaymentForm.SelectionItem["itemId"], Item>;

export type SelectNumberOfSelectionFieldProps = {
  name: string;
  onChange?: (fields: PaymentForm.SelectionItems) => void;
  items: ItemMap;
  shotInterviewPriceLabel: string;
  calculatedUnitPriceLabel: string;
} & Pick<BoxProps, "mb">;

const SelectNumberOfSelectionField: React.FC<SelectNumberOfSelectionFieldProps> = props => {
  const { onChange } = props;
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const methods = useController<Record<string, PaymentForm.SelectionItems>>({
    name: props.name,
  });

  const selectionItems = Object.entries(methods.field.value).reduce<SelectionItemProps[]>((acc, [_, selectionItem], index) => {
    const target = props.items[selectionItem.itemId];
    if (!target) {
      return acc;
    }
    return acc.concat({
      name: `${props.name}.${index}`,
      label: target.fieldLabel,
      priceLabel: target.priceLabel,
      unitPriceLabel: target.unitPriceLabel,
      onChange: () => {
        onChange?.(methods.field.value);
      },
      totalPriceLabel: target.totalPriceLabel,
    });
  }, []);

  return (
    <Box mb={props.mb}>
      <Typography variant="body2" color="text.secondary" mb={1.5}>
        {t("残り選考数が0のとき、候補者がテストを実施すると1選考あたり¥5,000で従量課金されます。")}
        <br />
        {t("採用計画に応じてあらかじめまとまった選考数を購入することで費用を抑えることが可能です。")}
      </Typography>
      <StyledTableContainer>
        <StyledTable sx={{ minWidth: 650 }}>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell align="left" width="100%">
                {t("購入単位")}
              </StyledTableCell>

              <StyledTableCell align="right" sx={{ minWidth: 250 }}>
                {t("金額")}
              </StyledTableCell>

              <StyledTableCell align="center">{t("個数")}</StyledTableCell>

              <StyledTableCell align="right" sx={{ minWidth: 170 }}>
                {t("小計")}
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {selectionItems.map(selectionItem => {
              return <SelectionItem key={selectionItem.name} {...selectionItem} />;
            })}
          </TableBody>
          <StyledTableFooter>
            <TableRow>
              <StyledTableCell sx={{ padding: 0 }} />
              <StyledTableCell sx={{ padding: 0 }} />
              <StyledTableCell sx={{ padding: 0 }} />
              <StyledTableCell align="right" sx={{ paddingTop: "8px", paddingBottom: "8px" }}>
                <StyledListItemText
                  primary={
                    <Typography variant="body2">
                      {t("およそ")} <StyledText>{t2("unitPriceForSelection", { value: props.calculatedUnitPriceLabel })}</StyledText>
                    </Typography>
                  }
                  secondary={[t("従量課金時"), t2("unitPriceForSelection", { value: props.shotInterviewPriceLabel })].join(": ")}
                />
              </StyledTableCell>
            </TableRow>
          </StyledTableFooter>
        </StyledTable>
      </StyledTableContainer>
    </Box>
  );
};

SelectNumberOfSelectionField.displayName = "SelectNumberOfSelectionField";

export default SelectNumberOfSelectionField;
