import { state } from "./State";
import type * as Types from "./types";

export const initialize = (questionPackage: Types.QuestionPackage, selectedQuestionObjectIds: string[]): void => {
  state.questionPackage = questionPackage;
  state.selectedQuestionObjectIds = selectedQuestionObjectIds;
};

export const clear = (): void => {
  state.questionPackage = null;
  state.selectedQuestionObjectIds = [];
  state.selectedKind = "test";
};

export const updateQuestionObjectIds = (questionObjectIds: string[]): void => {
  state.selectedQuestionObjectIds = questionObjectIds;
};

export const updateSelectedKind = (kind: Types.Kind): void => {
  state.selectedKind = kind;
};
