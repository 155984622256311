import { MAX_SELECTABLE_QUESTIONS } from "@hireroo/app-definition/remote";
import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import type { LiveCoding } from "@hireroo/app-helper/hooks";
import { RemotesId } from "@hireroo/app-store/page/e/remotes_id";
import { QuestionSearchArea } from "@hireroo/app-store/widget/e/QuestionSearchArea";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useTranslation } from "@hireroo/i18n";
import { Pages } from "@hireroo/presentation";
import * as Sentry from "@sentry/react";

import { QuestionsTableFetchContainerProps } from "./widget/QuestionsTable/FetchContainer";

type GenerateQuestionsTableFetchContainerPropsArgs = {
  onSelectQuestion: () => void;
  collaborativeAction: LiveCoding.CollaborativeAction;
};

export const useGenerateQuestionsTableFetchContainerProps = (
  args: GenerateQuestionsTableFetchContainerPropsArgs,
): QuestionsTableFetchContainerProps => {
  const { collaborativeAction } = args;
  const { setActiveStep } = Pages.useRemoteInterviewForEmployeeContext();
  const { t } = useTranslation();
  const client = getGraphqlClient();
  const liveCodingId = RemotesId.useLiveCodingId();
  const activeSessionCount = RemotesId.useActiveSessionCount();
  const currentSessionId = RemotesId.useActiveSessionId();
  return {
    onSelectQuestion: question => {
      args.onSelectQuestion();
      if (activeSessionCount >= MAX_SELECTABLE_QUESTIONS) {
        Snackbar.notify({
          severity: "error",
          message: t("1回のインタビューで利用可能な問題数は10件です。"),
        });
        return;
      }
      if (!question.liveCodingVariant || !question.liveCodingQuestionType) {
        return;
      }
      client
        .AddLiveCodingSession({
          input: {
            liveCodingId: liveCodingId,
            questionType: question.liveCodingQuestionType,
            questionVariant: question.liveCodingVariant,
            questionId: question.id,
            questionVersion: question.version ?? "",
          },
        })
        .then(res => {
          setActiveStep("SELECT_QUESTION_TYPE");
          RemotesId.addSession(res.addLiveCodingSession);
          collaborativeAction.addSession(res.addLiveCodingSession.liveCodingSessionId);
          Snackbar.notify({
            severity: "success",
            message: t("セッションを追加しました。"),
          });
          RemotesId.QuestionsTableStore.hideQuestion(`${question.liveCodingQuestionType}-${question.id}-${question.version}`);
          if (currentSessionId) collaborativeAction.setOutSessionWrapper(currentSessionId);
          collaborativeAction.setSelectedSessionWrapper(res.addLiveCodingSession.liveCodingSessionId);
          RemotesId.updateActiveSessionId(res.addLiveCodingSession.liveCodingSessionId);
          QuestionSearchArea.resetFilters();
        })
        .catch(error => {
          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(
            error,
            t("セッションの追加に失敗しました。しばらくしてから再度お試しください。"),
          );
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
        });
    },
  };
};
