import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateScreeningTestHeaderPropsArgs, useGenerateProps } from "./useGenerateProps";

export type ScreeningTestHeaderContainerProps = GenerateScreeningTestHeaderPropsArgs;

const ScreeningTestHeaderContainer: React.FC<ScreeningTestHeaderContainerProps> = props => {
  const screeningTestHeaderProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ScreeningTestHeader {...screeningTestHeaderProps} />
    </ErrorBoundary>
  );
};

ScreeningTestHeaderContainer.displayName = "ScreeningTestHeaderContainer";

export default withErrorBoundary(ScreeningTestHeaderContainer, {});
