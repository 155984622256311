import TableHead, { TableHeadProps } from "@mui/material/TableHead";
import * as React from "react";

export type DivTableHeadProps = TableHeadProps;

const DivTableHead: React.FC<DivTableHeadProps> = props => {
  return <TableHead {...props} component="div" sx={{ display: "table-header-group" }} />;
};

DivTableHead.displayName = "DivTableHead";

export default DivTableHead;
