import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateChallengeCodingEditorRightSidePanelPropsArgs, useGenerateProps } from "./useGenerateProps";

export type ChallengeCodingEditorRightSidePanelContainerProps = GenerateChallengeCodingEditorRightSidePanelPropsArgs;

const ChallengeCodingEditorRightSidePanelContainer: React.FC<ChallengeCodingEditorRightSidePanelContainerProps> = props => {
  const challengeCodingEditorRightSidePanelProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ChallengeCodingEditorRightSidePanel {...challengeCodingEditorRightSidePanelProps} />
    </ErrorBoundary>
  );
};

ChallengeCodingEditorRightSidePanelContainer.displayName = "ChallengeCodingEditorRightSidePanelContainer";

export default withErrorBoundary(ChallengeCodingEditorRightSidePanelContainer, {});
