import { RemotesStore } from "@hireroo/app-store/page/e/remotes";
import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import * as Subscriber from "./Subscriber";
import { useGenerateProps } from "./useGenerateProps";

export type RemoteInterviewListContainerProps = {};

const RemoteInterviewListContainer: React.FC<RemoteInterviewListContainerProps> = () => {
  const remoteInterviewListProps = useGenerateProps({});
  React.useEffect(() => {
    const stop = Subscriber.startSubscribeListParams();
    return () => {
      stop();
      RemotesStore.clear();
    };
  }, []);
  return (
    <ErrorBoundary>
      <Pages.RemoteInterviewList {...remoteInterviewListProps} />
    </ErrorBoundary>
  );
};

RemoteInterviewListContainer.displayName = "RemoteInterviewListContainer";

export default withErrorBoundary(RemoteInterviewListContainer, {});
