import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import SignInFooter, { SignInFooterProps } from "../../ms-components/Auth/SignInFooter/SignInFooter";
import SignInHeader from "../../ms-components/Auth/SignInHeader/SignInHeader";
import PasswordForm, { PasswordFormProps } from "./parts/PasswordForm/PasswordForm";
import RequestResetPasswordForm, { RequestResetPasswordFormProps } from "./parts/RequestResetPasswordForm/RequestResetPasswordForm";

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "100vh",
  width: "100vw",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.background.default,
}));

const WrapperStack = styled(Stack)(({ theme }) => ({
  width: "416px",
  padding: theme.spacing(3),
  borderRadius: "16px",
  backgroundColor: theme.palette["Background/Paper"].p2,
}));

type Form =
  | {
      kind: "RESET_REQUEST";
      props: RequestResetPasswordFormProps;
    }
  | {
      kind: "RESET_REQUEST_SENT";
      sentMessage: string;
    }
  | {
      kind: "RESET_PASSWORD";
      props: PasswordFormProps;
    };

export type ResetPasswordProps = {
  form: Form;
  footer: SignInFooterProps;
  backButton: Pick<ButtonProps, "onClick">;
};

const ResetPassword: React.FC<ResetPasswordProps> = props => {
  const { t } = useTranslation();
  const backButton: ButtonProps = {
    ...props.backButton,
    children: t("サインインページに戻る"),
    variant: "text",
    color: "secondary",
    sx: {
      textTransform: "none",
    },
  };
  const descriptionMap = React.useMemo((): Record<Form["kind"], string> => {
    return {
      RESET_REQUEST: t("再設定用のURLを登録メールアドレスに送信します"),
      RESET_REQUEST_SENT: props.form.kind === "RESET_REQUEST_SENT" ? props.form.sentMessage : "",
      RESET_PASSWORD: t("新しいパスワードを入力してください"),
    };
  }, [props.form, t]);

  return (
    <Wrapper>
      <Stack direction="column" alignItems="center" width="416px" spacing={2}>
        <SignInHeader />
        <WrapperStack spacing={3}>
          <Stack direction="column" spacing={2}>
            <Typography align="left" variant="h6" fontSize={20} fontWeight={700}>
              {t("パスワードリセット")}
            </Typography>
            <Typography align="left" fontSize={14}>
              {descriptionMap[props.form.kind]}
            </Typography>
          </Stack>
          {props.form.kind === "RESET_REQUEST" && <RequestResetPasswordForm {...props.form.props} />}
          {props.form.kind === "RESET_PASSWORD" && <PasswordForm {...props.form.props} />}
        </WrapperStack>
        <Button {...backButton} />
        <SignInFooter {...props.footer} />
      </Stack>
    </Wrapper>
  );
};

ResetPassword.displayName = "ResetPassword";

export default ResetPassword;
