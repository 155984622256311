import { useTranslation } from "@hireroo/i18n";
import { ConfirmInterviewForm } from "@hireroo/validator";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { type SubmitHandler, useFormContext } from "react-hook-form";

import EntryContentPaper from "../../../../modules/EntryContentPaper/EntryContentPaper";
import InputControlTextFieldWithChip, {
  InputControlTextFieldWithChipProps,
} from "../../../../modules/InputControlTextFieldWithChip/InputControlTextFieldWithChip";
import AcceptButton, { AcceptButtonProps } from "../../../../primitive/Button/AcceptButton/AcceptButton";

export type ConfirmFormProps = {
  loading: boolean;
  onSubmit: SubmitHandler<ConfirmInterviewForm.ConfirmInterviewFormSchema>;
};

const ConfirmForm: React.FC<ConfirmFormProps> = props => {
  const { t } = useTranslation();
  const methods = useFormContext<ConfirmInterviewForm.ConfirmInterviewFormSchema>();
  const usernameField: InputControlTextFieldWithChipProps = {
    name: "name",
    type: "text",
    fullWidth: true,
    placeholder: t("灰屋 太郎"),
    InputLabelProps: {
      shrink: true,
    },
    required: true,
    label: t("名前"),
    chip: {
      label: t("必須"),
      color: "primary",
    },
    helperText: methods.formState.errors.name?.message
      ? `${methods.formState.errors.name.message}`
      : `${t("入力された内容は選考中の企業に共有されます。")}${t("企業から指定があった場合はIDを入力しても構いません。")}`,
  };
  const acceptButtonProps: AcceptButtonProps = {
    /** Do not change  */
    type: "submit",
    disabled: !methods.watch("agreement") || props.loading,
    children: t("インタビューに参加する"),
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLFormElement> = event => {
    if (event.key === "Enter") event.preventDefault();
  };

  return (
    <EntryContentPaper>
      <Typography variant="h6" style={{ fontWeight: "bold", textAlign: "center" }}>
        {t("インタビュー開始")}
      </Typography>

      <form onSubmit={methods.handleSubmit(props.onSubmit)} onKeyDown={handleKeyDown}>
        <Box mt={4}>
          <Box my={2}>
            <Typography variant="body1" gutterBottom>
              {t("インタビューに必要な以下の情報を入力して開始してください。")}
            </Typography>
          </Box>
          <Stack spacing={4}>
            <InputControlTextFieldWithChip {...usernameField} />
          </Stack>
          <Stack direction="row" justifyContent="center" mt={2} spacing={2}>
            <AcceptButton {...acceptButtonProps} />
          </Stack>
        </Box>
      </form>
    </EntryContentPaper>
  );
};

ConfirmForm.displayName = "ConfirmForm";

export default ConfirmForm;
