import Add from "@mui/icons-material/Add";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CheckIcon from "@mui/icons-material/Check";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import DownloadIcon from "@mui/icons-material/Download";
import EditOutlined from "@mui/icons-material/EditOutlined";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import LinkIcon from "@mui/icons-material/Link";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PlayArrow from "@mui/icons-material/PlayArrow";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ReplayIcon from "@mui/icons-material/Replay";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import ShareIcon from "@mui/icons-material/Share";
import UploadIcon from "@mui/icons-material/Upload";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import Button, { type ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";

const StyledButton = styled(Button)(() => ({
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
}));

const iconMap = {
  ADD: <Add />,
  CLIP_BOARD: <ContentPasteIcon />,
  PERSON_ADD: <PersonAddIcon />,
  DELETE: <DeleteIcon />,
  EDIT: <EditOutlined fontSize="small" />,
  KEY: <VpnKeyIcon />,
  ASSIGNMENT: <AssignmentIcon />,
  PLAY: <PlayArrow />,
  REPLAY: <ReplayIcon />,
  DOWNLOAD: <DownloadIcon />,
  DESCRIPTION: <DescriptionIcon />,
  SHARE: <ShareIcon />,
  FILE_COPY: <FileCopyIcon />,
  CONTENT_COPY: <ContentCopyOutlinedIcon />,
  LOGOUT: <LogoutIcon />,
  LINK: <LinkIcon />,
  CHECK: <CheckIcon />,
  INSERT_LINK: <InsertLinkOutlinedIcon />,
  RATE_REVIEW: <RateReviewOutlinedIcon />,
  RESTART: <RestartAltOutlinedIcon />,
  REMOVE_CIRCLE: <RemoveCircleOutlineIcon />,
  ADD_CIRCLE: <AddCircleOutlineIcon />,
  LOGIN: <LoginIcon />,
  PLAY_ARROW: <PlayArrowRoundedIcon />,
  ADD_CIRCLE_OUTLINED: <AddCircleOutlineOutlinedIcon />,
  SETTING: <SettingsIcon />,
  UPLOAD: <UploadIcon />,
} satisfies Record<string, React.ReactElement>;

type ButtonStatus = "NORMAL" | "DISABLED";

export type ButtonWithTooltipProps = Omit<ButtonProps, "startIcon" | "hasRole"> & {
  title?: string;
  startIcon?: keyof typeof iconMap;
};

const ButtonWithTooltip: React.FC<ButtonWithTooltipProps> = props => {
  const { children, title, startIcon, disabled, ...rest } = props;

  const buttonStatus: ButtonStatus = disabled ? "DISABLED" : "NORMAL";

  const ButtonMap: Record<ButtonStatus, React.ReactElement> = {
    DISABLED: (
      <span>
        <StyledButton role="disabled-button" disabled size="small" {...rest} startIcon={startIcon ? iconMap[startIcon] : undefined}>
          {children}
        </StyledButton>
      </span>
    ),
    NORMAL: (
      <StyledButton role="button" size="small" {...rest} startIcon={startIcon ? iconMap[startIcon] : undefined}>
        {children}
      </StyledButton>
    ),
  };

  return (
    <Tooltip role="tooltip" title={title ?? ""}>
      {ButtonMap[buttonStatus] ?? ButtonMap["NORMAL"]}
    </Tooltip>
  );
};

ButtonWithTooltip.displayName = "ButtonWithTooltip";

export default ButtonWithTooltip;
