import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateAssessmentReportPropsArgs, useGenerateProps } from "./useGenerateProps";

export type AssessmentReportContainerProps = GenerateAssessmentReportPropsArgs;

const AssessmentReportContainer: React.FC<AssessmentReportContainerProps> = props => {
  const assessmentReportProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.AssessmentReport {...assessmentReportProps} />
    </ErrorBoundary>
  );
};

AssessmentReportContainer.displayName = "AssessmentReportContainer";

export default withErrorBoundary(AssessmentReportContainer, {});
