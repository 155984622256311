import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import * as React from "react";
import { Outlet } from "react-router-dom";

import OopsContainer from "../Oops/Container";
import { useGenerateProps } from "./useGenerateProps";

const PermittedRouteForCandidateContainer: React.FC = () => {
  const errorPanelProps = useGenerateProps();

  if (errorPanelProps) {
    return <Widget.ErrorPanel {...errorPanelProps} />;
  }
  return (
    <React.Suspense>
      <Outlet />
    </React.Suspense>
  );
};

PermittedRouteForCandidateContainer.displayName = "PermittedRouteForCandidateContainer";

export default withErrorBoundary(PermittedRouteForCandidateContainer, { fallback: <OopsContainer /> });
