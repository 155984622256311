import { useSnapshot } from "valtio";

import * as Types from "./types";

export const createHooks = (privateState: Types.State) => {
  const useSnapshotState = () => {
    return useSnapshot(privateState);
  };

  const useHasSocket = (): boolean => {
    const snapshot = useSnapshotState();
    return !!snapshot.socket;
  };

  return {
    useHasSocket,
  };
};
