import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { ErrorCode, FirebaseError, signUpWithPassword } from "@hireroo/firebase";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/react";

import { afterSignUpProcess } from "./privateHelper";

export type GenerateSignInMailAddressPropsArgs = {};

export const useGenerateProps = (_args: GenerateSignInMailAddressPropsArgs): Widget.SignInMailAddressProps => {
  const { t } = useTranslation();
  return {
    passwordField: {
      enableVisibilityButton: true,
    },
    signInButton: {
      children: t("サインアップ"),
    },
    onSubmit: async field => {
      await signUpWithPassword(field.email, field.password)
        .then(res => {
          if (!res.user) {
            return;
          }
          afterSignUpProcess({
            user: res.user,
            emailAddress: field.email,
          });
        })
        .catch((error: FirebaseError) => {
          if (error.code === ErrorCode.EMAIL_EXISTS) {
            Snackbar.notify({
              severity: "error",
              message: t("このメールアドレスは、すでに他のアカウントで使用されています。"),
            });
          } else {
            Sentry.captureException(error);
          }
        });
    },
  };
};
