import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import * as React from "react";

import TestSearchForm, { TestSearchFormProps } from "../../ms-components/Spot/TestSearchForm/TestSearchForm";

export type ScreeningTestListForDetailProps = {
  active: boolean;
  search: Omit<TestSearchFormProps, "enabledScreeningFeature">;
  List: React.ReactNode;
};

const ScreeningTestListForDetail: React.FC<ScreeningTestListForDetailProps> = props => {
  return (
    <Box p={3}>
      <Stack spacing={2}>
        <TestSearchForm key={props.active ? "ACTIVE" : "INACTIVE"} {...props.search} enabledScreeningFeature={true} />
        {props.List}
      </Stack>
    </Box>
  );
};

ScreeningTestListForDetail.displayName = "ScreeningTestListForDetail";

export default ScreeningTestListForDetail;
