import { useTranslation } from "@hireroo/i18n";
import * as z from "zod";

export const useCorrectness = () => {
  return z.enum(["CORRECT", "NOT_CORRECT"]);
};

export type Correctness = z.infer<ReturnType<typeof useCorrectness>>;

export const useFeedbackSuspiciousDegreeFormSchema = () => {
  const { t } = useTranslation();
  const correctness = useCorrectness();
  return z.object({
    correctness: correctness,
    comment: z.string().min(1, { message: t("必須項目です") }),
  });
};

export type FeedbackSuspiciousDegreeFormSchema = z.infer<ReturnType<typeof useFeedbackSuspiciousDegreeFormSchema>>;
