import { state } from "./State";
import type * as Types from "./types";

export const setEntities = (entities: Types.Entity[]): void => {
  state.entities = entities;
};

export const setReviewTarget = (target: Types.ReviewTarget) => {
  state.reviewTarget = target;
};
