import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import LinearProgress from "@mui/material/LinearProgress";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";

import Heading, { HeadingProps } from "./Heading/Heading";

const HeaderHeight = 64;
const HeaderMargin = 40;
const FooterHeight = 20;
const FooterMargin = 20;
const Spaces = HeaderHeight + HeaderMargin + FooterHeight + FooterMargin;

const Wrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
}));

export type CommonLayoutProps = {
  loading?: boolean;
  Header: React.ReactNode;
  Banner: React.ReactNode;
  Footer: React.ReactNode;
  Bottom: React.ReactNode;
  heading?: HeadingProps;
  maxSize?: "xl" | "lg" | "md";
};

const CommonLayout: React.FC<React.PropsWithChildren<CommonLayoutProps>> = props => {
  const bannerRef = useResizeDetector();
  return (
    <Wrapper>
      {props.loading && <LinearProgress sx={{ width: "100%", position: "fixed", zIndex: 2000, top: 0 }} />}
      {props.Header}
      <Box ref={bannerRef.ref}>{props.Banner}</Box>
      <Container maxWidth={props.maxSize ?? "lg"}>
        <Box height="100%" minHeight={window.innerHeight - (Spaces + (bannerRef?.height ? bannerRef.height : 0))}>
          {props.heading && <Heading {...props.heading} />}
          {props.children || (
            <Box mt={2}>
              <Stack spacing={2}>
                <Skeleton key="skelton-1" height="200px" variant="rectangular" />
                <Skeleton key="skelton-2" height="200px" variant="rectangular" />
              </Stack>
            </Box>
          )}
        </Box>
      </Container>
      {props.Bottom}
      <Box mt={2}>{props.Footer}</Box>
    </Wrapper>
  );
};

CommonLayout.displayName = "CommonLayout";

export default CommonLayout;
