import { useSnapshot } from "valtio";

import { state } from "./State";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = () => {
  const snapshot = useSnapshotState();
  return !!snapshot.test;
};

export const useTest = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.test) {
    throw new Error("need to initialize test");
  }
  return snapshot.test;
};

export const useSelectedEntityId = () => {
  const snapshot = useSnapshotState();
  return snapshot.selectedEntityId;
};
