import { useTranslation } from "@hireroo/i18n";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

const EllipsisText = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
  overflow: hidden;
  max-width: 400px;
`;

type Status = "ACTIVE" | "NOT_ACTIVE";

type CandidateAccessPolicy = "ALLOW_ALL" | "RESTRICTED_BY_INVITATION_CODE";
export type MetaProps = {
  title: string;
  status: Status;
  candidateAccessPolicy: CandidateAccessPolicy;
};

const Meta: React.FC<MetaProps> = props => {
  const { t } = useTranslation();
  return (
    <Stack spacing={1} direction="row" alignItems="center">
      {props.candidateAccessPolicy === "RESTRICTED_BY_INVITATION_CODE" && (
        <Tooltip title={t("パスワード付きリンクを知っている人が受験可能")}>
          <KeyOutlinedIcon color="primary" fontSize="small" />
        </Tooltip>
      )}
      <EllipsisText fontWeight={400} fontSize="16px">
        {props.title}
      </EllipsisText>
    </Stack>
  );
};

Meta.displayName = "Meta";

export default Meta;
