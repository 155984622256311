import { useTranslation } from "@hireroo/i18n";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import OwnerEmailAddressEditor, {
  OwnerEmailAddressEditorProps,
} from "../../../../ms-components/Spot/OwnerEmailAddressEditor/OwnerEmailAddressEditor";
import { ButtonWithTooltipProps } from "../../../../primitive/Button/ButtonWithTooltip/ButtonWithTooltip";
import CopyToClipboardButton, { CopyToClipboardButtonProps } from "../../../../primitive/Button/CopyToClipboardButton/CopyToClipboardButton";
import TextLinkify from "../../../../primitive/TextLinkify/TextLinkify";
import ListContent from "../../../ScreeningTestOverview/parts/ListContent";
import { ListContentProps } from "../ListContent/ListContent";

const StyledTypography = styled(Typography)(() => ({
  fontSize: "14px",
  variant: "subtitle2",
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette["Background/Paper"].p2,
  borderRadius: "16px",
}));

const WrapTypography = styled(Typography)({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});

type CandidateAccessPolicy = "ALL" | "INVITATION_CODE_ONLY";

export type InviteInfoProps = {
  data: {
    messageForCandidate?: string;
    invitationLink: string;
    candidateAccessPolicy: CandidateAccessPolicy;
    acceptableLimit?: number;
    invitationLanguage: string;
    ownerEmailAddress: string;
  };
  inviteButton: Pick<ButtonProps, "onClick">;
  copyButton: Pick<CopyToClipboardButtonProps, "onCopy" | "buttonWithTooltip">;
  ownerEmailEditor: OwnerEmailAddressEditorProps;
};

const InviteInfo: React.FC<InviteInfoProps> = props => {
  const { t } = useTranslation();
  const { data } = props;

  const basicListContents: ListContentProps[] = [
    {
      title: t("候補者へのメッセージ"),
      children: (
        <StyledTypography sx={{ whiteSpace: "pre-wrap" }}>
          {data.messageForCandidate ? <TextLinkify>{data.messageForCandidate}</TextLinkify> : t("候補者へのメッセージはありません。")}
        </StyledTypography>
      ),
    },
  ];
  const invitationListContents = React.useMemo(() => {
    const accessModeLabelMap: Record<CandidateAccessPolicy, string> = {
      ALL: t("コーディングテストのリンクを知っている誰もが受験可能"),
      INVITATION_CODE_ONLY: t("メールで送信される受験用の招待コードを知っている人のみ受験可能"),
    };
    const list: ListContentProps[] = [
      {
        title: t("受験可能なユーザーの制限"),
        children: <StyledTypography>{accessModeLabelMap[data.candidateAccessPolicy]}</StyledTypography>,
      },
      {
        title: t("招待メール本文の言語"),
        children: <StyledTypography>{props.data.invitationLanguage}</StyledTypography>,
      },
      {
        title: t("候補者に共有する連絡先"),
        children: (
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <StyledTypography>{props.data.ownerEmailAddress}</StyledTypography>
            <OwnerEmailAddressEditor {...props.ownerEmailEditor} />
          </Stack>
        ),
      },
    ];
    if (data.candidateAccessPolicy === "ALL") {
      const copyButton: ButtonWithTooltipProps = {
        ...props.copyButton.buttonWithTooltip,
        size: "small",
        variant: "outlined",
      };
      list.push({
        title: t("招待リンク"),
        children: (
          <Box display="flex" alignItems="center">
            <WrapTypography fontSize={14}>{data.invitationLink}</WrapTypography>
            <Box flexGrow={1} />
            <Box ml={1}>
              <CopyToClipboardButton
                name={t("コピー")}
                url={data.invitationLink}
                onCopy={props.copyButton.onCopy}
                buttonWithTooltip={copyButton}
              />
            </Box>
          </Box>
        ),
      });
    } else if (data.candidateAccessPolicy === "INVITATION_CODE_ONLY") {
      const inviteButton: ButtonProps = {
        ...props.inviteButton,
        children: t("招待"),
        variant: "outlined",
        color: "secondary",
        size: "small",
        startIcon: <PersonAddIcon />,
      };
      list.push({
        title: t("招待方法"),
        children: (
          <Box display="flex" alignItems="center">
            <WrapTypography fontSize={14}>{t("メールで候補者を招待する")}</WrapTypography>
            <Box flexGrow={1} />
            <Box ml={1}>
              <Button {...inviteButton} />
            </Box>
          </Box>
        ),
      });
    }
    return list;
  }, [
    data.candidateAccessPolicy,
    data.invitationLink,
    props.copyButton.buttonWithTooltip,
    props.copyButton.onCopy,
    props.data.invitationLanguage,
    props.data.ownerEmailAddress,
    props.inviteButton,
    props.ownerEmailEditor,
    t,
  ]);

  const listContents: ListContentProps[] = [...basicListContents, ...invitationListContents];
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Typography fontSize={16} sx={{ fontWeight: "bold" }}>
          {t("招待情報")}
        </Typography>
      </Box>
      <StyledBox>
        <List component="nav" aria-label="coding-test-summary" sx={{ px: 2 }}>
          {listContents.map((content, index) => (
            <ListContent key={content.title} {...content} divider={index !== listContents.length - 1} />
          ))}
        </List>
      </StyledBox>
    </Box>
  );
};

InviteInfo.displayName = "ReportInfo";

export default InviteInfo;
