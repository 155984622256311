import { useTranslation } from "@hireroo/i18n";
import { Fields } from "@hireroo/validator";
import { ErrorMessage } from "@hookform/error-message";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import RadioControlField, { RadioControlFieldProps } from "../../primitive/InputControl/RadioControlField/RadioControlField";
import CustomInputField, { CustomInputFieldProps } from "./parts/CustomInputField/CustomInputField";
import Label from "./parts/Label/Label";

type RadioValueMap = Record<Fields.TimeLimitField.TimeLimitTypeFieldSchema, number>;

const ReservedFieldName = {
  TIME_LIMIT_TYPE: "timeLimitType",
  TIME_LIMIT_MINUTES: "timeLimitMinutes",
} as const;

const TimeLimitTypeFieldValueMap = {
  TIGHT: "TIGHT",
  RECOMMENDED: "RECOMMENDED",
  RELAX: "RELAX",
  CUSTOM: "CUSTOM",
} satisfies Record<string, Fields.TimeLimitField.TimeLimitTypeFieldSchema>;

type Version = "V1" | "V2";

/**
 * FieldName
 * - timeLimitType
 * - timeLimitMinutes
 */
export type TimeLimitSettingFieldProps = {
  /**
   * @default "V1"
   */
  version?: Version;
  defaultValue?: Fields.TimeLimitField.TimeLimitTypeFieldSchema;
  disabled?: boolean;
  radioValueMap: RadioValueMap;
};

const TimeLimitSettingField: React.FC<TimeLimitSettingFieldProps> = props => {
  const { t } = useTranslation();
  const { radioValueMap } = props;
  const methods = useFormContext<Fields.TimeLimitField.TimeLimitFieldsSchema>();
  const validateSchema = Fields.TimeLimitField.useTimeLimitTypeFieldSchema();

  const tightRadioLabelProps: RadioControlFieldProps["options"][0] = {
    name: "tight",
    value: TimeLimitTypeFieldValueMap.TIGHT,
    label:
      props.version === "V2" ? (
        <Label text={radioValueMap.TIGHT + t("分")} suffix={{ text: t("厳しめ"), color: "PINK" }} />
      ) : (
        radioValueMap.TIGHT + t("分") + `（${t("厳しめ")}）`
      ),
    disabled: props.disabled,
  };

  const recommendedRadioLabelProps: RadioControlFieldProps["options"][0] = {
    name: "recommended",
    value: TimeLimitTypeFieldValueMap.RECOMMENDED,
    label:
      props.version === "V2" ? (
        <Label text={radioValueMap.RECOMMENDED + t("分")} suffix={{ text: t("おすすめ"), color: "GREEN" }} />
      ) : (
        radioValueMap.RECOMMENDED + t("分") + `（${t("おすすめ")}）`
      ),
    disabled: props.disabled,
  };

  const relaxRadioLabelProps: RadioControlFieldProps["options"][0] = {
    name: "relax",
    value: TimeLimitTypeFieldValueMap.RELAX,
    label:
      props.version === "V2" ? (
        <Label text={radioValueMap.RELAX + t("分")} suffix={{ text: t("ゆったりめ"), color: "BLUE" }} />
      ) : (
        radioValueMap.RELAX + t("分") + `（${t("ゆったりめ")}）`
      ),
    disabled: props.disabled,
  };

  const customInputFieldProps: CustomInputFieldProps = {
    name: ReservedFieldName.TIME_LIMIT_TYPE,
    disabled: props.disabled,
  };

  const customRadioLabelProps: RadioControlFieldProps["options"][0] = {
    name: "custom",
    value: TimeLimitTypeFieldValueMap.CUSTOM,
    label: <Label text={t("カスタム")} />,
    SuffixComponent: <CustomInputField {...customInputFieldProps} />,
  };

  const timeLimitRadioFieldProps: RadioControlFieldProps = {
    color: "secondary",
    row: true,
    defaultValue: props.defaultValue,
    name: ReservedFieldName.TIME_LIMIT_TYPE,
    control: methods.control,
    options: [tightRadioLabelProps, recommendedRadioLabelProps, relaxRadioLabelProps, customRadioLabelProps],
    onChange: value => {
      const result = validateSchema.safeParse(value);
      if (result.success) {
        const validatedTimelimitMinutes = result.data;
        methods.setValue(ReservedFieldName.TIME_LIMIT_TYPE, validatedTimelimitMinutes);
        if (validatedTimelimitMinutes !== TimeLimitTypeFieldValueMap.CUSTOM) {
          methods.setValue(ReservedFieldName.TIME_LIMIT_MINUTES, radioValueMap[validatedTimelimitMinutes]);
        }
      }
    },
  };

  /**
   * The `radioValueMap` changes so that the time displayed is consistent with the actual state managed by the react-hook-form in this useEffect.
   */
  React.useEffect(() => {
    const timeLimitMinutes = methods.getValues("timeLimitMinutes");
    const timeLimitType = methods.getValues("timeLimitType");
    const correctTimelimitMinutes = radioValueMap[timeLimitType];
    if (timeLimitMinutes !== correctTimelimitMinutes && timeLimitType !== TimeLimitTypeFieldValueMap.CUSTOM) {
      methods.setValue("timeLimitMinutes", correctTimelimitMinutes);
    }
  }, [methods, radioValueMap]);

  return (
    <Box>
      <Box display="flex" alignItems="flex-end">
        <RadioControlField {...timeLimitRadioFieldProps} />
      </Box>
      <ErrorMessage
        errors={methods.formState.errors}
        name={ReservedFieldName.TIME_LIMIT_MINUTES}
        render={({ message }) => (
          <Typography color="error" variant="caption" noWrap>
            {message}
          </Typography>
        )}
      />
    </Box>
  );
};

TimeLimitSettingField.displayName = "TimeLimitSettingField";

export default TimeLimitSettingField;
