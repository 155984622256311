import Cancel from "@mui/icons-material/Cancel";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedOutlined from "@mui/icons-material/RadioButtonUncheckedOutlined";
import Refresh from "@mui/icons-material/Refresh";
import * as React from "react";

export type OutputStatusType = "LOADING" | "REJECTED" | "COMPLETED";

export const OutputStatusMap: Record<OutputStatusType, React.ReactNode> = {
  LOADING: <Refresh color="warning" fontSize="small" />,
  COMPLETED: <CheckCircleOutline color="success" fontSize="small" />,
  REJECTED: <Cancel color="error" fontSize="small" />,
};

export type ChallengeOutputStatusIconProps = {
  outputStatus?: OutputStatusType;
};

const ChallengeOutputStatusIcon: React.FC<ChallengeOutputStatusIconProps> = props => {
  return <>{props.outputStatus ? OutputStatusMap[props.outputStatus] : <RadioButtonUncheckedOutlined color="disabled" fontSize="small" />}</>;
};

ChallengeOutputStatusIcon.displayName = "ChallengeOutputStatusIcon";

export default ChallengeOutputStatusIcon;
