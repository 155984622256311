import { Credential } from "@hireroo/app-store/essential/shared";
import * as Graphql from "@hireroo/graphql/client/request";
import * as RestClient from "@hireroo/rest-api/client";

export const initializeGraphqlClientSdkAndRestApiClient = (authToken: string): void => {
  Graphql.initialize(import.meta.env.VITE_GRAPHQL_SERVER_ADDRESS, {
    authToken,
    getOrRefreshAuthTokenExpire: Credential.getOrRefreshAuthTokenExpire,
    appVersion: APP_VERSION,
  });
  RestClient.initialize(import.meta.env.VITE_REST_API_SERVER_ADDRESS, authToken);
};
