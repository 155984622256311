import { DndContext, KeyboardSensor, MouseSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { Coordinates } from "@dnd-kit/utilities";
import * as React from "react";

export type ContextValue = {
  coordinatesRef: React.MutableRefObject<Coordinates>;
  updateCurrentCoordinates: (coordinates: Coordinates) => void;
};

const defaultCoordinates = {
  x: 0,
  y: 0,
};

export const DraggablePromptContext = React.createContext<ContextValue>({} as ContextValue);

export const useDraggablePromptContext = () => React.useContext(DraggablePromptContext);

export type DraggablePromptProviderProps = {
  defaultCoordinates?: Coordinates;
};

export const DraggablePromptProvider: React.FC<React.PropsWithChildren<DraggablePromptProviderProps>> = props => {
  const coordinatesRef = React.useRef<Coordinates>(props.defaultCoordinates ?? defaultCoordinates);

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 1, // Dragging threshold
    },
  });

  const keyboardSensor = useSensor(KeyboardSensor);
  const sensors = useSensors(mouseSensor, keyboardSensor);

  const contextValue: ContextValue = {
    coordinatesRef: coordinatesRef,
    updateCurrentCoordinates: React.useCallback(coordinates => {
      coordinatesRef.current.x = coordinates.x;
      coordinatesRef.current.y = coordinates.y;
    }, []),
  };
  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToWindowEdges]}
      onDragEnd={({ delta }) => {
        coordinatesRef.current.x += delta.x;
        coordinatesRef.current.y += delta.y;
      }}
    >
      <DraggablePromptContext.Provider value={contextValue} children={props.children} />
    </DndContext>
  );
};
