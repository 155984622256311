import * as React from "react";

import OnboardingGuide, { ACTIONS, OnboardingGuideProps, STATUS } from "../../modules/OnboardingGuide/OnboardingGuide";
import { useGenerateChallengeOnboardingGuideProps } from "./useGenerateChallengeOnboardingGuideProps";
import { useGenerateProjectV2OnboardingGuideProps } from "./useGenerateProjectV2OnboardingGuideProps";
import { useGenerateProjectV3OnboardingGuideProps } from "./useGenerateProjectV3OnboardingGuideProps";
import { useGenerateQuizOnboardingGuideProps } from "./useGenerateQuizOnboardingGuideProps";
import { useGenerateSystemDesignOnboardingGuideProps } from "./useGenerateSystemDesignOnboardingGuideProps";

type Kind = "SYSTEM_DESIGN" | "CHALLENGE" | "PROJECT_V2" | "PROJECT_V3" | "QUIZ";

export type ScreeningTestTutorialProps = {
  kind: Kind | null;
  runTutorial: boolean;
  onChangeRunTutorial: (runTutorial: boolean) => void;
};

const useSteps = (kind: Kind | null): OnboardingGuideProps => {
  const systemDesign = useGenerateSystemDesignOnboardingGuideProps();
  const challenge = useGenerateChallengeOnboardingGuideProps();
  const quiz = useGenerateQuizOnboardingGuideProps();
  const projectV2 = useGenerateProjectV2OnboardingGuideProps();
  const projectV3 = useGenerateProjectV3OnboardingGuideProps();
  return React.useMemo(() => {
    switch (kind) {
      case "SYSTEM_DESIGN":
        return systemDesign;
      case "CHALLENGE":
        return challenge;
      case "QUIZ":
        return quiz;
      case "PROJECT_V2":
        return projectV2;
      case "PROJECT_V3":
        return projectV3;
      case null: {
        return { steps: [] };
      }
      default: {
        throw new Error(`Not support type: ${kind}`);
      }
    }
  }, [kind, systemDesign, challenge, quiz, projectV2, projectV3]);
};

const ScreeningTestTutorial: React.FC<ScreeningTestTutorialProps> = props => {
  const { runTutorial } = props;
  const { steps } = useSteps(props.kind);
  const onboardingGuideProps: OnboardingGuideProps = {
    disableScrolling: true,
    steps: steps,
    run: props.kind !== null ? runTutorial : false,
    callback: data => {
      switch (data.status) {
        case STATUS.FINISHED:
        case STATUS.SKIPPED: {
          props.onChangeRunTutorial(false);
          break;
        }
      }
      switch (data.action) {
        case ACTIONS.CLOSE: {
          props.onChangeRunTutorial(false);
          break;
        }
      }
    },
  };
  /**
   * If you don't change the instance when the `kind` changes, it won't work properly.
   */
  return <OnboardingGuide key={props.kind} {...onboardingGuideProps} />;
};

ScreeningTestTutorial.displayName = "ScreeningTestTutorial";

export default React.memo(ScreeningTestTutorial);
