import { useTranslation } from "@hireroo/i18n";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel, { FormControlLabelProps } from "@mui/material/FormControlLabel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";

const StyledStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette["Gray/Shades"].p8,
  borderRadius: "4px",
  paddingX: theme.spacing(1.5),
}));

const StyledTypography = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 16,
}));

const StyledList = styled(List)(({ theme }) => ({
  margin: 0,
  padding: 0,
  borderRadius: "4px",
  border: "1px solid",
  borderColor: theme.palette.Other.Divider,
  maxHeight: "600px",
  overflowY: "auto",
  ".MuiListItem-root:last-child": {
    borderBottom: "none",
  },
}));

const StyledListItemText = styled(ListItemText)(() => ({
  margin: 0,
  ".MuiListItemText-primary": {
    fontSize: 14,
    fontWeight: 700,
  },
}));

type DialogController = {
  setLoading: (loading: boolean) => void;
  close: () => void;
};

type Item = {
  id: string;
  name: string;
  description?: string;
};

export type ArchiveQuestionDialogProps = {
  open: boolean;
  items: Item[];
  onArchive: (controller: DialogController) => void;
  onClose?: () => void;
};

const ArchiveQuestionDialog: React.FC<ArchiveQuestionDialogProps> = props => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [checked, setChecked] = React.useState(false);

  const handleClose = () => {
    props.onClose?.();
    setLoading(false);
    setChecked(false);
  };

  const dialogController: DialogController = {
    setLoading: newLoading => {
      setLoading(newLoading);
    },
    close: () => {
      handleClose();
    },
  };

  const dialog: DialogWithCloseIconProps = {
    open: props.open,
    title: t("選択した問題をアーカイブする"),
    yesButton: {
      children: t("アーカイブ"),
      color: "error",
      onClick: () => {
        props.onArchive(dialogController);
      },
      disabled: !checked || loading,
      autoFocus: false,
    },
    yesButtonTooltip: {
      title: t("アーカイブするには同意が必要です。"),
    },
    noButton: {
      children: t("キャンセル"),
      variant: "outlined",
      color: "outline-only",
      disabled: loading,
      onClick: () => {
        handleClose();
      },
    },
    disableEnter: true,
    optionalDialog: {
      disableAutoFocus: true,
    },
    onClose: handleClose,
  };

  const formControlLabel: FormControlLabelProps = {
    sx: { ml: 0 },
    control: <Checkbox size="small" />,
    onChange: (_, checked) => {
      setChecked(checked);
    },
    label: <Typography fontSize={14}>{t("内容を確認し、アーカイブした後に元に戻せないことに同意する")}</Typography>,
  };

  const messages: string[] = [
    t("一度アーカイブすると元に戻すことはできません。"),
    t("問題をアーカイブするとコーディングテストからは削除されます。"),
    t("アーカイブした問題を含むテストを候補者が既に受けている場合は、引き続きレポートを閲覧できます。"),
  ];

  return (
    <DialogWithCloseIcon {...dialog}>
      <Stack spacing={3}>
        <Stack spacing={2}>
          <StyledTypography>{t("注意事項")}</StyledTypography>
          <Stack direction="column" spacing={1.5} px={1}>
            {messages.map((message, index) => (
              <Typography fontSize={12} key={index}>
                {`${index + 1}. ${message}`}
              </Typography>
            ))}
          </Stack>
        </Stack>
        <Stack spacing={2}>
          <StyledTypography>{t("アーカイブ項目")}</StyledTypography>
          <StyledList>
            {props.items.map(item => (
              <ListItem key={item.id} divider>
                <StyledListItemText
                  primary={item.name}
                  secondary={item.description}
                  primaryTypographyProps={{ mb: "4px" }}
                  secondaryTypographyProps={{ fontSize: 12 }}
                />
              </ListItem>
            ))}
          </StyledList>
        </Stack>
        <StyledStack>
          <FormControlLabel {...formControlLabel} />
        </StyledStack>
      </Stack>
    </DialogWithCloseIcon>
  );
};

ArchiveQuestionDialog.displayName = "ArchiveQuestionDialog";

export default ArchiveQuestionDialog;
