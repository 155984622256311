import { subscribeKey } from "valtio/utils";

import { state } from "./State";
import * as Types from "./types";

export type SubscribeSelectedAssessmentIdCallback = (assessmentId: Types.AssessmentId) => void;

export const subscribeChangeSelectedAssessmentId = (callback: SubscribeSelectedAssessmentIdCallback) => {
  return subscribeKey(state, "selectedAssessmentId", assessmentId => {
    if (assessmentId) {
      callback(assessmentId);
    }
  });
};
