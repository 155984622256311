import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type NotificationSettingsContainerProps = {};

const SlackNotificationSettingsContainer: React.FC<NotificationSettingsContainerProps> = args => {
  const props = useGenerateProps(args);
  return (
    <ErrorBoundary>
      <Widget.SlackNotificationSettings {...props} />
    </ErrorBoundary>
  );
};

SlackNotificationSettingsContainer.displayName = "SlackNotificationSettingsContainer";

export default withErrorBoundary(SlackNotificationSettingsContainer, {});
