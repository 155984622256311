import { getRef } from "@hireroo/firebase";
import * as React from "react";

import { AccessEvent, fetchAccessEventsRecord } from "../firepad";

export type FirebaseAccessEventArgs = {
  challengeId: number;
  questionId: number;
};

export type ChallengeAccessEvents = {
  accessEvents: Record<string, AccessEvent>;
  ready: boolean;
};

export const useChallengeAccessEvents = (args: FirebaseAccessEventArgs): ChallengeAccessEvents => {
  const { challengeId, questionId } = args;
  const [accessEvents, setAccessEvents] = React.useState<Record<string, AccessEvent>>({});
  const [accessEventReady, setRevisionReady] = React.useState<boolean>(false);

  React.useEffect(() => {
    const submissionRef = getRef("challenge", `challenges/${challengeId}/questions/${questionId}`);
    fetchAccessEventsRecord(submissionRef, event => {
      setAccessEvents(event);
      setRevisionReady(true);
    });
  }, [challengeId, questionId]);

  return {
    accessEvents,
    ready: accessEventReady,
  };
};
