import { ThemeProvider } from "@hireroo/ui-theme/theme-v2";
import Box, { BoxProps } from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";

import { useSystemDesignContext } from "../../../store";
import EditControlPanel, { EditControlPanelProps } from "../EditControlPanel/EditControlPanel";
import ElementSettings, { ElementSettingsV2Props } from "../ElementSettingsV2/ElementSettingsV2";
import MiniMap from "../MiniMap/MiniMap";
import MiniMapToggleControl from "../MiniMapToggleControl/MiniMapToggleControl";
import RedoUndoGroup, { RedoUndoGroupProps } from "../RedoUndoGroup/RedoUndoGroup";
import ResetControl, { ResetControlProps } from "../ResetControl/ResetControl";
import ScaleControl from "../ScaleControl/ScaleControl";
import DrawingArea, { DrawingAreaProps } from "./DrawingArea";

const ELEMENT_SETTINGS_WIDTH = 200;

const RootBox = styled(Box)(() => ({
  position: "relative",
  height: "100%",
  width: "100%",
  maxWidth: "100vw",
  maxHeight: "100vh",
}));

const ElementSettingsBox = styled(Box)`
  height: 100%;
  width: ${ELEMENT_SETTINGS_WIDTH}px;
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
`;

const FloatingTopRightBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(1.5),
  top: theme.spacing(1.5),
  display: "flex",
  alignItems: "center",
}));

const FloatingBottomRightBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(1.5),
  bottom: theme.spacing(1.5),
  display: "flex",
  alignItems: "center",
}));

export type FlowChartProps = {
  drawingArea: Omit<DrawingAreaProps, "outerHeight" | "outerWidth">;
  elementSettings?: ElementSettingsV2Props;
  editControlPanel?: EditControlPanelProps;
  redoUndoGroup: RedoUndoGroupProps;
  resetControl: ResetControlProps;
};

const FlowChart: React.FC<FlowChartProps> = props => {
  const theme = useTheme();
  const Store = useSystemDesignContext();
  const openMiniMap = Store.hooks.useOpenMiniMap();
  const resizeDetector = useResizeDetector();
  React.useEffect(() => {
    if (typeof resizeDetector.height === "number" && typeof resizeDetector.width === "number") {
      const aspectRatio = resizeDetector.height / resizeDetector.width;
      Store.action.updateAspectRatio({
        aspectRatio: aspectRatio,
      });
    }
  }, [Store.action, resizeDetector.height, resizeDetector.width]);
  const drawingAreaProps: DrawingAreaProps = {
    ...props.drawingArea,
    outerHeight: resizeDetector.height ?? 0,
    outerWidth: resizeDetector.width ?? 0,
  };
  const shiftEditControlBoxProps: BoxProps | undefined = props.elementSettings
    ? {
        sx: {
          right: `calc(${ELEMENT_SETTINGS_WIDTH}px + ${theme.spacing(1.5)})`,
        },
      }
    : undefined;
  const shiftFloatingRightBoxProps: BoxProps | undefined = props.elementSettings
    ? {
        sx: {
          right: `calc(${ELEMENT_SETTINGS_WIDTH}px + ${theme.spacing(1.5)})`,
        },
      }
    : undefined;

  return (
    <RootBox>
      <Box ref={resizeDetector.ref} position="relative" overflow="hidden" height="100%">
        <DrawingArea {...drawingAreaProps} />
        <ThemeProvider defaultColorMode="LIGHT">
          {props.editControlPanel && (
            <FloatingTopRightBox {...shiftEditControlBoxProps}>
              <EditControlPanel {...props.editControlPanel} />
            </FloatingTopRightBox>
          )}
          <FloatingBottomRightBox {...shiftFloatingRightBoxProps}>
            <Stack spacing={1} direction="column" alignItems={"flex-end"}>
              {openMiniMap && (
                <Paper elevation={2} sx={{ width: 160 }}>
                  <MiniMap />
                </Paper>
              )}
              <Stack spacing={1} direction="row">
                <ResetControl {...props.resetControl} />
                <RedoUndoGroup {...props.redoUndoGroup} />
                <ScaleControl />
                <MiniMapToggleControl />
              </Stack>
            </Stack>
          </FloatingBottomRightBox>
        </ThemeProvider>
        {props.elementSettings && (
          <ElementSettingsBox>
            <ElementSettings {...props.elementSettings} />
          </ElementSettingsBox>
        )}
      </Box>
    </RootBox>
  );
};

FlowChart.displayName = "FlowChart";

export default FlowChart;
