import { componentTypeGraphqlToLowerComponentType } from "@hireroo/app-helper/system-design";
import { App, Auth, Company, Payment } from "@hireroo/app-store/essential/employee";
import { QuestionSelectFieldForResourceEditor } from "@hireroo/app-store/widget/e/QuestionSelectFieldForResourceEditor";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { AssessmentResourceEditorForm, Fields } from "@hireroo/validator";
import { withErrorBoundary } from "@sentry/react";
import * as React from "react";

import AssessmentResourceEditorContainer, { AssessmentResourceEditorContainerProps } from "./Container";

type EntityTrack = AssessmentResourceEditorForm.CreateAssessmentFormSchema["entityTracks"][0];

export type AssessmentResourceEditorFetchContainerProps = {
  assessmentId: string;
} & AssessmentResourceEditorContainerProps;

const AssessmentResourceEditorFetchContainer: React.FC<AssessmentResourceEditorFetchContainerProps> = props => {
  const appStatus = App.useStatus();
  const uid = Auth.useCurrentUid();
  const companyId = Company.useStrictActiveCompanyId();
  const { mode } = props;
  const isAvailableQuestionByPayment = Payment.useIsAvailableQuestionToUse("exam");
  const enableTalentScore = App.useEnableTalentScore();
  const isAvailableQuestion = React.useCallback(
    (variant: Graphql.QuestionVariant): boolean => {
      if (enableTalentScore) {
        return true;
      }
      return isAvailableQuestionByPayment(variant);
    },
    [enableTalentScore, isAvailableQuestionByPayment],
  );
  const [result] = Graphql.useGetAssessmentForAssessmentResourceEditorQuery({
    variables: {
      assessmentId: props.assessmentId,
    },
    pause: appStatus !== "INITIALIZED",
    /**
     * Always get the latest information when updating.
     */
    requestPolicy: "network-only",
  });
  React.useEffect(() => {
    if (result.data) {
      QuestionSelectFieldForResourceEditor.setQuestion(result.data.assessment.questions.slice());
    }
  }, [result.data]);

  if (result.fetching || appStatus !== "INITIALIZED") {
    return <Widget.Loading kind="CENTER" color="secondary" />;
  }

  const { data } = result;
  if (!data) {
    return null;
  }
  const assessmentResourceEditorContainerProps: AssessmentResourceEditorContainerProps = {
    mode: mode,
    loading: props.loading,
    onSubmit: props.onSubmit,
    defaultValues: {
      testQuestionSetup: {
        name: data.assessment.name,
        companyId: companyId,
        entityTracks: data.assessment.entitySources.reduce<EntityTrack[]>((all, entitySource) => {
          if (entitySource.challengeQuestion) {
            const entity = entitySource.challengeQuestion;
            const question = data.assessment.questions.find(
              question =>
                question.__typename === "AlgorithmQuestion" &&
                question.questionId === entity.questionId &&
                question.version === entity.questionVersion,
            );
            if (!question) {
              return all;
            }
            const entityTrack: EntityTrack = {
              type: "FIXED",
              entitySource: {
                type: "CHALLENGE",
                uniqueKey: question.key,
                questionId: entity.questionId,
                questionVersion: entity.questionVersion,
                enabledLanguages: entity.enabledLanguages,
                isSelectable: isAvailableQuestion("CHALLENGE"),
              },
              questionScoreWeight: 1,
            };
            return all.concat(entityTrack);
          }
          if (entitySource.quizPackage) {
            const entity = entitySource.quizPackage;
            const question = data.assessment.questions.find(
              question =>
                question.__typename === "MultiChoicePackage" &&
                question.packageId === entity.packageId &&
                question.version === entity.packageVersion,
            );
            if (!question) {
              return all;
            }
            const entityTrack: EntityTrack = {
              type: "FIXED",
              questionScoreWeight: 1,
              entitySource: {
                type: "QUIZ",
                uniqueKey: question.key,
                packageId: entity.packageId,
                packageVersion: entity.packageVersion,
                isSelectable: isAvailableQuestion("QUIZ"),
              },
            };
            return all.concat(entityTrack);
          }
          if (entitySource.projectQuestion) {
            const entity = entitySource.projectQuestion;
            const question = data.assessment.questions.find(
              question =>
                question.__typename === "ProjectQuestion" &&
                question.questionId === entity.questionId &&
                question.version === entity.questionVersion,
            );
            if (!question) {
              return all;
            }
            const entityTrack: EntityTrack = {
              type: "FIXED",
              questionScoreWeight: 1,
              entitySource: {
                type: "PROJECT",
                uniqueKey: question.key,
                questionId: entity.questionId,
                questionVersion: entity.questionVersion,
                isSelectable: isAvailableQuestion("PROJECT"),
              },
            };
            return all.concat(entityTrack);
          }
          if (entitySource.systemDesignQuestion) {
            const entity = entitySource.systemDesignQuestion;
            const question = data.assessment.questions.find(
              question => question.__typename === "SystemDesignQuestion" && question.questionId === entity.questionId,
            );
            if (!question) {
              return all;
            }
            const entityTrack: EntityTrack = {
              type: "FIXED",
              questionScoreWeight: 1,
              entitySource: {
                type: "SYSTEM_DESIGN",
                uniqueKey: question.key,
                questionId: entity.questionId,
                componentTypes: entity.componentTypes.map(
                  (componentType): Fields.EntitySource.SystemDesignComponentType => componentTypeGraphqlToLowerComponentType[componentType],
                ),
                isSelectable: isAvailableQuestion("SYSTEM_DESIGN"),
              },
            };
            return all.concat(entityTrack);
          }
          return all;
        }, []),
        timeLimitMinutes: Math.round((data.assessment.timeLimitSeconds ?? 0) / 60) || 5,
        timeLimitType: "CUSTOM",
      },
      testSetup: {
        examInterval: data.assessment.examInterval === "UNKNOWN" ? "ONE_MONTH" : data.assessment.examInterval,
        /**
         * For ease of understanding by the user, nextScheduleAtSeconds should be the default value.
         * Because startScheduleAt is the starting date, if this value is changed, nextScheduleAtSeconds will also be changed.
         *
         * nextScheduleAt = examInterval x (numTry - adjustCount) + startScheduleAt
         *
         * `adjustCount` should be such that `numTry - adjustCount` is 0 when startScheduleAt is changed.
         */
        nextStartScheduleAt: data.assessment.nextScheduleAtSeconds ? new Date(data.assessment.nextScheduleAtSeconds * 1000) : new Date(),
        remindBeforeDays: data.assessment.remindBeforeSeconds ? Math.ceil(data.assessment.remindBeforeSeconds / 86400) : NaN,
      },
      reportSetup: {
        editorUid: uid,
        memo: data.assessment.memo ?? undefined,
        viewers: data.assessment.viewers.reduce<Fields.AssignField.AssignListItemSchema[]>((all, viewer) => {
          switch (viewer.__typename) {
            case "EmployeeGroup": {
              return all.concat({
                value: {
                  type: "EMPLOYEE_GROUP",
                  groupId: viewer.employeeGroupId,
                },
              });
            }
            case "User": {
              return all.concat({
                value: {
                  type: "EMPLOYEE",
                  employeeId: viewer.uid,
                  locked: data.assessment.employee?.uid === viewer.uid,
                },
              });
            }
            default:
              throw new Error(`Invalid type: ${viewer satisfies never}`);
          }
          return all;
        }, []),
        isPublic: data.assessment.isPublic,
        reportSettings: {
          showAnswer: data.assessment.reportSettings?.showAnswer ?? false,
          showPlayback: data.assessment.reportSettings?.showPlayback ?? false,
          showRelativeEvaluation: data.assessment.reportSettings?.showRelativeEvaluation ?? false,
        },
      },
      testInviteSetup: {
        talent: {
          type: "TALENT",
          talentId: data.assessment.talent.uid,
          locked: true,
        },
        messageForTalent: data.assessment.messageForTalent ?? undefined,
      },
    },
  };
  return <AssessmentResourceEditorContainer {...assessmentResourceEditorContainerProps} />;
};

AssessmentResourceEditorFetchContainer.displayName = "AssessmentResourceEditorFetchContainer";

export default withErrorBoundary(AssessmentResourceEditorFetchContainer, {});
