import React from "react";
import { LocalAudioTrack } from "twilio-video";

import { SelectedNoiseCancellationStatus } from "../../media-device";

/**
 * This hook enables or disables the noise cancellation of the audio track.
 */
export const useNoiseCancellation = (audioTrack?: LocalAudioTrack, getAudioTrack?: () => void) => {
  const noiseCancellation = audioTrack && audioTrack.noiseCancellation;
  const selectedStatus = SelectedNoiseCancellationStatus.get();
  const [isEnabled, setIsEnabled] = React.useState(selectedStatus === "ENABLED");

  React.useEffect(() => {
    if (!noiseCancellation) {
      return;
    }
    if (!isEnabled) {
      noiseCancellation.disable().finally(() => {
        SelectedNoiseCancellationStatus.save("DISABLED");
      });
    } else {
      try {
        noiseCancellation
          .enable()
          .then(() => {
            SelectedNoiseCancellationStatus.save("ENABLED");
          })
          .catch(err => {
            console.warn("NoiseCancellation plugin error happened. Get audio track", err);
            // Failed to enable noise cancellation, so we need to get audio track again.
            getAudioTrack?.();
          });
      } catch (err) {
        // Workaround for Krisp is not connected to any valid stream,
        audioTrack.restart();
      }
    }
  }, [isEnabled, noiseCancellation, audioTrack, getAudioTrack]);

  return {
    isEnabled,
    toggleNoiseCancellation: () => {
      setIsEnabled(prev => !prev);
    },
  };
};
