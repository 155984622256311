import { useTitle } from "@hireroo/app-helper/react-use";
import { EmployeeSideBarContent } from "@hireroo/app-store/widget/e/EmployeeSideBarContent";
import { useTranslation } from "@hireroo/i18n";
import type { Pages } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import React from "react";

import EmployeeHeaderRightContentContainer from "../../../../widget/v2/e/EmployeeHeaderRightContent/Container";
import EmployeeSideBarContentContainer from "../../../../widget/v2/e/EmployeeSideBarContent/Container";
import FooterContainer from "../../../../widget/v2/e/Footer/Container";
import { useNotificationBanner } from "../../../../widget/v2/e/NotificationBanner/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import ScreeningDetailFetchContainer from "./widgets/ScreeningDetail/FetchContainer";

export type GenerateScreeningDetailPropsArgs = {};

export const useGenerateProps = (_args: GenerateScreeningDetailPropsArgs): Pages.ScreeningDetailProps => {
  const navigate = useTransitionNavigate();
  const { t } = useTranslation();
  const NotificationBannerContainer = useNotificationBanner();

  useTitle(t("コーディングテスト詳細"));

  return {
    layout: {
      openSidebar: EmployeeSideBarContent.useOpenSidebar(),
      onChangeOpenSidebar: open => {
        EmployeeSideBarContent.setOpenSidebar(open);
      },
      NotificationBanner: NotificationBannerContainer,
      HeaderRightContent: <EmployeeHeaderRightContentContainer />,
      SideBarContent: <EmployeeSideBarContentContainer />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      sideBarHeader: {
        logo: {
          href: generatePath("/e/home"),
          onClick: () => {
            navigate("/e/home");
          },
        },
      },
      navigationBreadcrumbs: {
        parentLinks: [
          {
            href: generatePath("/e/home"),
            children: t("ホーム"),
            onClick: () => {
              navigate("/e/home");
            },
          },
          {
            href: generatePath("/e/screenings"),
            children: t("コーディングテスト一覧"),
            onClick: () => {
              navigate("/e/screenings");
            },
          },
        ],
        current: t("コーディングテスト詳細"),
      },
    },
    children: <ScreeningDetailFetchContainer />,
  };
};
