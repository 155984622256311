import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type AssessmentTalentHeaderContainerProps = {};

const AssessmentTalentHeaderContainer: React.FC<AssessmentTalentHeaderContainerProps> = () => {
  const assessmentTalentHeaderProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.AssessmentTalentHeader {...assessmentTalentHeaderProps} />
    </ErrorBoundary>
  );
};

AssessmentTalentHeaderContainer.displayName = "AssessmentTalentHeaderContainer";

export default withErrorBoundary(AssessmentTalentHeaderContainer, {});
