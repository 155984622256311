import { useTitle } from "@hireroo/app-helper/react-use";
import { Credential } from "@hireroo/app-store/essential/shared";
import { signOut } from "@hireroo/firebase";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import { generateHelpCenterUrl } from "@hireroo/router/api";
import routes from "@hireroo/router/config";
import { useHelpCenterNavigate, useNavigate } from "@hireroo/router/hooks";

type GenerateNotFoundProps = {};

export const useGenerateProps = (_args: GenerateNotFoundProps): Widget.ErrorPanelProps => {
  const { t } = useTranslation();
  const userType = Credential.useUserType();
  const isSignedIn = Credential.useSignedIn();
  const navigate = useNavigate();
  const lang = useLanguageCode();
  const helpCenterNavigate = useHelpCenterNavigate(lang);

  const messageMap: Record<Exclude<Credential.UserType, null>, string> = {
    [Graphql.UserType.Candidate]: t(
      "現在のアカウントは候補者としてサインインされているため、面接官のページを閲覧する場合は一度サインアウトしてください。",
    ),
    [Graphql.UserType.Employee]: t(
      "現在のアカウントは面接官としてサインインされています。しばらく経っても画面が切り替わらない場合はお手数ですが以下のホーム戻るをクリックしてください。",
    ),
    [Graphql.UserType.Talent]: t(
      "現在のアカウントはタレントとしてサインインされています。しばらく経っても画面が切り替わらない場合はお手数ですが以下のホーム戻るをクリックしてください。",
    ),
    [Graphql.UserType.Unknown]: [
      t("会社に所属することでこれより先の画面に進めます。会社に所属するには、管理者に招待リンクを発行してもらう必要があります。"),
      t("テストを受験する場合はお手数ですが、一度サインアウトして企業から受け取ったリンクに直接アクセスしてください。"),
    ].join(""),
  };

  const defaultMessage: string = [
    t("ユーザータイプの取得に失敗したため、アプリケーションを正常に実行することができませんでした。"),
    t("お手数ですがヘルプセンターより運営にお問い合わせください。"),
  ].join("");

  useTitle(t("ページが見つかりませんでした。"));

  return {
    name: t("ページが見つかりませんでした。"),
    message: t("URLをご確認の上、再度お試し下さい。"),
    additionalMessage: userType ? messageMap[userType] : defaultMessage,
    links: [
      {
        href: generateHelpCenterUrl(lang, "ROOT"),
        children: t("ヘルプセンター"),
        onClick: () => {
          helpCenterNavigate("ROOT", { _blank: true });
        },
      },
      userType === Graphql.UserType.Employee && {
        href: routes["/e/home"],
        children: t("ホームに戻る"),
        onClick: () => {
          navigate(routes["/e/home"]);
        },
      },
      userType === Graphql.UserType.Talent && {
        href: routes["/t/assessments"],
        children: t("ホームに戻る"),
        onClick: () => {
          navigate(routes["/t/assessments"]);
        },
      },
      (userType === null || userType === Graphql.UserType.Unknown) && {
        href: routes["/"],
        children: t("ホームに戻る"),
        onClick: () => {
          navigate(routes["/"]);
        },
      },
      isSignedIn && {
        children: t("サインアウトする"),
        onClick: async () => {
          await signOut();
        },
      },
    ].flatMap(v => (v ? [v] : [])),
  };
};
