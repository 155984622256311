import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type TestReportSectionMessageProps = {
  message: string;
};

const TestReportSectionMessage: React.FC<TestReportSectionMessageProps> = props => {
  return (
    <Box p={2}>
      <Typography>{props.message}</Typography>
    </Box>
  );
};

TestReportSectionMessage.displayName = "TestReportSectionMessage";

export default TestReportSectionMessage;
