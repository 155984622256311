import { useTranslation } from "@hireroo/i18n";
import Code from "@mui/icons-material/Code";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import FlowChartsInputControl, { FlowChartsInputControlProps } from "../../../../usecase/FlowChartsInputControl/FlowChartsInputControl";

export type FlowChartSectionProps = {
  answerIndex: number;
};

const FlowChartSection: React.FC<FlowChartSectionProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const flowChartsInputControlProps: FlowChartsInputControlProps = {
    name: `answers.${props.answerIndex}.answerFlowCharts` as "flowCharts",
    addableTabs: {},
  };
  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Box display="flex" alignItems="center">
          <Box mr={1}>
            <Code fontSize="small" sx={{ color: theme.palette.common.white }} />
          </Box>

          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            {t("解答フローチャートを入力する")}
          </Typography>
        </Box>
      </Box>

      <Box mt={2}>
        <Typography variant="body2">
          {t("解答フローチャートを追加します。面接官は解答を参考にして候補者とコミュニケーションを取ることが出来ます。")}
        </Typography>
      </Box>

      <Box mt={3}>
        <FlowChartsInputControl {...flowChartsInputControlProps} />
      </Box>
    </Box>
  );
};

FlowChartSection.displayName = "FlowChartSection";

export default FlowChartSection;
