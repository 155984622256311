import { generateCompletedEntities } from "@hireroo/app-helper/normalizer";

import { state } from "./State";

export const getEntities = () => {
  return state.spot?.entities || [];
};

export const getCompletedEntities = () => {
  return generateCompletedEntities(state.spot?.entities);
};
