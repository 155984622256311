import * as z from "zod";

export const useRole = () => {
  return z.enum(["ADMIN", "MANAGER", "ENGINEER"]);
};
export const useMemberEditRoleForm = () => {
  const role = useRole();
  return z.object({
    role: role,
  });
};

export type MemberEditRoleFormSchema = z.infer<ReturnType<typeof useMemberEditRoleForm>>;
