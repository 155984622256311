import { useTranslation } from "@hireroo/i18n";
import * as z from "zod";

export type ConfirmInterviewFormSchemaArgs = {
  enableEmailField: boolean;
};

export const useConfirmInterviewFormSchema = (args: ConfirmInterviewFormSchemaArgs) => {
  const { t } = useTranslation();
  const email = z.string().email(t("メールアドレスの形式が間違っています。"));

  return z.object({
    name: z
      .string()
      .min(1, { message: t("名前は1文字以上で入力してください。") })
      .max(100, { message: t("名前は100文字以内で入力してください。") })
      .regex(/^[^\cA-\cZ]+$/, { message: t("利用できない文字列が含まれています。") }),
    agreement: z.boolean(),
    email: args.enableEmailField ? email : email.optional(),
  });
};

export type ConfirmInterviewFormSchema = z.infer<ReturnType<typeof useConfirmInterviewFormSchema>>;
