import { useTranslation } from "@hireroo/i18n";
import EvaluationA from "@hireroo/ui-assets/images/Evaluation/EvaluationA.svg";
import EvaluationB from "@hireroo/ui-assets/images/Evaluation/EvaluationB.svg";
import EvaluationC from "@hireroo/ui-assets/images/Evaluation/EvaluationC.svg";
import EvaluationD from "@hireroo/ui-assets/images/Evaluation/EvaluationD.svg";
import EvaluationS from "@hireroo/ui-assets/images/Evaluation/EvaluationS.svg";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

const MarkMap = {
  S: EvaluationS,
  A: EvaluationA,
  B: EvaluationB,
  C: EvaluationC,
  D: EvaluationD,
  UNKNOWN: null,
} as const;

export type RankMarkProps = {
  className?: string;
  rank: keyof typeof MarkMap;
  tooltip?: string;
};

const RankMark: React.FC<RankMarkProps> = ({ className, rank, tooltip }) => {
  const { t } = useTranslation();
  if (rank === "UNKNOWN") {
    return (
      <Tooltip title={tooltip ?? ""}>
        <Typography noWrap variant="body2" color="text.secondary">
          {t("不明")}
        </Typography>
      </Tooltip>
    );
  }
  return <img className={className} src={MarkMap[rank]} alt={rank} />;
};

RankMark.displayName = "RankMark";

export default RankMark;
