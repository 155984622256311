import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";

const StyledStack = styled(Stack)`
  height: 24px;
`;

const StyledIconButton = styled(IconButton)`
  width: 24px;
  height: 24px;
`;

type ButtonProps = {
  tooltipText: string;
  disabled: boolean;
  onClick: IconButtonProps["onClick"];
};

export type ArrowSwitchButtonGroupProps = {
  leftButton: ButtonProps;
  rightButton: ButtonProps;
};

const ArrowSwitchButtonGroup: React.FC<ArrowSwitchButtonGroupProps> = props => {
  const leftIconButtonProps: IconButtonProps = {
    disabled: props.leftButton.disabled,
    onClick: props.leftButton.onClick,
    children: <ArrowBackIosNewIcon fontSize="small" color={props.leftButton.disabled ? "disabled" : "secondary"} />,
  };
  const rightIconButtonProps: IconButtonProps = {
    disabled: props.rightButton.disabled,
    onClick: props.rightButton.onClick,
    children: <ArrowForwardIosIcon fontSize="small" color={props.rightButton.disabled ? "disabled" : "secondary"} />,
  };
  return (
    <StyledStack direction="row" spacing={"10px"}>
      <Tooltip role="tooltip" title={props.leftButton.tooltipText}>
        <span>
          <StyledIconButton {...leftIconButtonProps} />
        </span>
      </Tooltip>
      <Tooltip role="tooltip" title={props.rightButton.tooltipText}>
        <span>
          <StyledIconButton {...rightIconButtonProps} />
        </span>
      </Tooltip>
    </StyledStack>
  );
};

ArrowSwitchButtonGroup.displayName = "ArrowSwitchButtonGroup";

export default ArrowSwitchButtonGroup;
