import { useTranslation } from "@hireroo/i18n";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import * as React from "react";

import { useSystemDesignContext } from "../../../store";
import ControlButtonGroup from "../primitive/ControlButtonGroup/ControlButtonGroup";
import SquareButton, { SquareButtonProps } from "../primitive/SquareButton/SquareButton";

const StyledPaper = styled(Paper)`
  display: inline-block;
  height: 32px;
`;

export type MiniMapToggleControlProps = {};

const MiniMapToggleControl: React.FC<MiniMapToggleControlProps> = () => {
  const Store = useSystemDesignContext();
  const { t } = useTranslation();
  const miniMapToggleButton: SquareButtonProps = {
    tooltipText: t("ミニマップ"),
    onClick: () => {
      Store.action.toggleMiniMap();
    },
  };
  return (
    <StyledPaper>
      <ControlButtonGroup>
        <SquareButton {...miniMapToggleButton}>
          <MapOutlinedIcon />
        </SquareButton>
      </ControlButtonGroup>
    </StyledPaper>
  );
};

MiniMapToggleControl.displayName = "MiniMapToggleControl";

export default MiniMapToggleControl;
