import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation/legacy";
import { generateHelpCenterUrl } from "@hireroo/router/api";
export type GenerateErrorPanelPropsArgs = {};

export const useGenerateProps = (_args: GenerateErrorPanelPropsArgs): Widget.ErrorPanelProps => {
  const { t } = useTranslation();
  const languageCode = useLanguageCode();
  return {
    name: t("エラーが発生しました。"),
    message: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
    additionalMessage: "",
    links: [
      {
        href: generateHelpCenterUrl(languageCode, "ROOT"),
        children: t("ヘルプセンター"),
        target: "_blank",
      },
    ],
  };
};
