import { useLanguageCode } from "@hireroo/i18n";
import type { Pages } from "@hireroo/presentation";
import { generateHelpCenterUrl, generatePath, navigate as navigateApi } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";

import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import ExamTabTablesForTalentFetchContainer from "../../../../widget/v2/t/ExamTabTablesForTalent/FetchContainer";
import FooterContainer from "../../../../widget/v2/t/Footer/Container";
import { useNotificationBanner } from "../../../../widget/v2/t/NotificationBanner/Container";
import TalentHeaderRightContentContainer from "../../../../widget/v2/t/TalentHeaderRightContent/Container";
import TalentSideBarContentContainer from "../../../../widget/v2/t/TalentSideBarContent/Container";

export type GenerateAssessmentListForTalentPropsArgs = {};

export const useGenerateProps = (_args: GenerateAssessmentListForTalentPropsArgs): Pages.AssessmentListForTalentProps => {
  const lang = useLanguageCode();
  const navigate = useTransitionNavigate();
  const NotificationBannerContainer = useNotificationBanner();
  return {
    layout: {
      loading: false,
      NotificationBanner: NotificationBannerContainer,
      HeaderRightContent: <TalentHeaderRightContentContainer />,
      SideBarContent: <TalentSideBarContentContainer />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      logo: {
        href: generatePath("/t/assessments"),
        onClick: () => {
          navigate("/t/assessments");
        },
      },
    },
    helpButton: {
      onClick: () => {
        const url = generateHelpCenterUrl(lang, "ASSESSMENT_TALENT_LIST_PAGE");
        navigateApi(url, { _blank: true });
      },
    },
    children: <ExamTabTablesForTalentFetchContainer />,
  };
};
