import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";

type Kind = "CHALLENGE_PLAYBACK";

export type UpsellWithVideoPropsArgs = {
  kind: Kind;
};

export const useGenerateProps = (args: UpsellWithVideoPropsArgs): Widget.UpsellWithVideoProps => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();

  const valueMap: Record<Kind, Widget.UpsellWithVideoProps> = {
    CHALLENGE_PLAYBACK: {
      title: t("プレイバック機能"),
      description: t2("FeatureCanBeUsedWithPlanUpgrade", { plan: t("スタンダード") }),
      video: {
        // TODO: Replace with actual video URL
        src: "",
        onPlay: () => {
          // TODO: request to hubspot
        },
      },
    },
  };

  return valueMap[args.kind];
};
