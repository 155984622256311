import * as Graphql from "@hireroo/graphql/client/urql";
import { RemoteInterviewSearchForm } from "@hireroo/validator";

export type Creator = Graphql.GetSearchItemsForRemotesQuery["issuersForRemoteByFields"]["creators"][0];
export type Tags = Graphql.GetSearchItemsForRemotesQuery["remoteTagsByCompanyId"]["tags"];

export type Filters = Graphql.RemoteFilterInput;

export type LoadingStatus = "LOADING" | "NONE";

export type SearchFilter = RemoteInterviewSearchForm.RemoteInterviewSearchQuerySchema;
export type SortFieldValue = RemoteInterviewSearchForm.SortFieldValue;
export type Pager = {
  page: number;
  size: number;
  offset: number;
  sortFieldValue: SortFieldValue;
};

export type ListParams = {
  isDescending: boolean;
  page: number;
  size: number;
  sortMethod: Graphql.RemoteSortMethod;
  filters: Filters;
};
export type Res = Graphql.ListRemotesByCompanyIdForRemotesInterviewListQuery["remotesByCompanyId"];

export type State = {
  remoteTags: Tags | null;
  searchTags: string[];
  /**
   * @deprecated use currentSearchFilter instead
   */
  listParams: ListParams;
  count: number;
  creators: Creator[];
  initialized: boolean;
  loadingStatus: LoadingStatus;
  /**
   * v2 search filter
   */
  currentSearchFilter: SearchFilter | null;
  pager: Pager;
  res: Res | null;
};

export const QueryKeys = {
  PAGE: "page",
  SIZE: "size",
  TITLE: "title",
  STATUS: "status",
  RESULT: "result",
  ISSUER: "issuer",
  TAG: "tag",
  SORT: "sorting",
} as const;

export type QueryType = (typeof QueryKeys)[keyof typeof QueryKeys];
