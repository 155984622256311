import Box from "@mui/material/Box";
import Chip, { ChipProps } from "@mui/material/Chip";
import InputLabel from "@mui/material/InputLabel";
import { useTheme } from "@mui/material/styles";
import * as React from "react";

import InputControlTextField, { InputControlTextFieldProps } from "../../primitive/InputControl/InputControlTextField/InputControlTextField";

export type InputControlTextFieldWithChipProps = Omit<InputControlTextFieldProps, "label"> & {
  name: string;
  label: string;
  chip: Pick<ChipProps, "label" | "color">;
};

const InputControlTextFieldWithChip: React.FC<InputControlTextFieldWithChipProps> = props => {
  const { name, label, chip, ...rest } = props;
  const theme = useTheme();

  return (
    <Box>
      <Box display={"flex"} alignItems={"center"} sx={{ mb: 1 }}>
        <InputLabel htmlFor={name} color="primary" sx={{ fontWeight: 700, color: theme.palette.text.primary }}>
          {label}
        </InputLabel>
        <Chip sx={{ ml: 1, fontSize: theme.typography.body2.fontSize }} size="small" {...chip} />
      </Box>
      <InputControlTextField name={name} {...rest} />
    </Box>
  );
};

InputControlTextFieldWithChip.displayName = "InputControlTextFieldWithChip";

export default InputControlTextFieldWithChip;
