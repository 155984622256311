import InfoOutlined from "@mui/icons-material/InfoOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

type IconKey = "CHECK" | "WARNING";

const iconMap: Record<IconKey, React.ReactElement> = {
  CHECK: <InfoOutlined color="success" />,
  WARNING: <WarningAmberOutlinedIcon color="warning" />,
};

export type TestReportEvaluationItemProps = {
  title: string;
  value: string;
  AdditionalContent?: React.ReactNode;
  /**
   * When this flag is true, additional information will be displayed by means of tooltips.
   */
  canShowTooltip: boolean;
  icon: {
    key: IconKey;
  } & Pick<TooltipProps, "title">;
};

const TestReportEvaluationItem: React.FC<TestReportEvaluationItemProps> = props => {
  return (
    <ListItem divider>
      <ListItemText
        primary={
          <Grid container>
            <Grid item xs={6} alignItems="center" display="flex">
              <Typography variant="subtitle2" color="textSecondary">
                {props.title}
              </Typography>
              {props.AdditionalContent}
            </Grid>
            <Grid item xs={6}>
              <Box display={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
                <Typography variant="subtitle1">{props.value}</Typography>
                {props.canShowTooltip && (
                  <>
                    <Box ml={2} />
                    <Tooltip title={props.icon.title}>{iconMap[props.icon.key]}</Tooltip>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        }
      />
    </ListItem>
  );
};

TestReportEvaluationItem.displayName = "TestReportEvaluationItem";

export default TestReportEvaluationItem;
