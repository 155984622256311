import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";

import TablePagination, { TablePaginationProps } from "../../../modules/TablePagination/TablePagination";
import ExamTableRow, { ExamTableRowProps } from "./parts/ExamTableRow/ExamTableRow";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: "normal",
  border: "none",
  py: 1.5,
  wordBreak: "keep-all",
  whiteSpace: "nowrap",
}));

export type ExamTableProps = {
  tablePagination: TablePaginationProps;
  items: ExamTableRowProps[];
};

const ExamTable: React.FC<ExamTableProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Box>
      <Table component="div" sx={{ display: "table" }}>
        <TableHead component="div" sx={{ display: "table-header-group" }}>
          <TableRow sx={{ borderTop: "1px solid", borderBottom: "1px solid", borderColor: theme.palette["Gray/Shades"].p8 }} component="div">
            <StyledTableCell align="left" component="div">
              {t("ステータス")}
            </StyledTableCell>
            <StyledTableCell align="left" component="div">
              {"NO."}
            </StyledTableCell>
            <StyledTableCell align="left" component="div">
              {t("開始日時")}
            </StyledTableCell>
            <StyledTableCell align="left" component="div">
              {t("提出期限")}
            </StyledTableCell>
            <StyledTableCell align="right" component="div">
              {t("スコア")}
            </StyledTableCell>
            <StyledTableCell align="right" component="div">
              {t("ランク")}
            </StyledTableCell>
            <StyledTableCell component="div">{/* for Action Button */}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody component="div">
          {props.items.map(props => (
            <ExamTableRow key={props.id} {...props} />
          ))}
        </TableBody>
      </Table>
      {props.items.length === 0 && (
        <Box py={6} sx={{ width: "100%" }}>
          <Typography variant="body1" textAlign="center">
            {t("該当のテストはありません")}
          </Typography>
        </Box>
      )}
      <Box display="flex" justifyContent="flex-end">
        <TablePagination {...props.tablePagination} />
      </Box>
    </Box>
  );
};

ExamTable.displayName = "ExamTable";

export default ExamTable;
