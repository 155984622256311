import {
  ArrayStringFormat,
  ColumnType,
  OutputFormat,
  OutputRecordsSchema,
  OutputRecordsSchemaType,
  parseTableRecords,
} from "@hireroo/challenge/definition";
import { AlgorithmSignatureForm } from "@hireroo/validator";

const EMPTY_RECORD_STRING = "[]";

export const castColumnType = (val: ColumnType, columnType: string): ColumnType => {
  if (val === null || val === "NULL") {
    return null;
  }
  if (val === "") {
    //NULL can be any type hence return itself
    return val;
  }
  let castedVal;
  switch (columnType) {
    case "int":
      castedVal = Math.trunc(Number(val));
      if (Number.isNaN(castedVal)) {
        castedVal = val;
      }
      break;
    case "float":
      castedVal = Number(val.toString());
      if (Number.isNaN(castedVal)) {
        castedVal = val;
      }
      break;
    case "bool":
      // 0 or 1 is the bool value
      if (val === "0") {
        castedVal = 0;
      } else if (val === "1") {
        castedVal = 1;
      } else {
        castedVal = val;
      }
      break;
    default:
      return val;
  }
  return castedVal;
};

// Build output format from ArrayStringFormat and signature
// e.g.
//   output: "[[1]]",
//   return: "[{\"num_employees_without_insurance\": 1}]"
export const buildOutputFormat = (output: ArrayStringFormat, signature: AlgorithmSignatureForm.DatabaseSignatureSchemaType): OutputFormat => {
  const parsedOutputs = parseTableRecords(output);
  const map = new Map();
  const outputRecords: OutputRecordsSchemaType = [];
  // if the value is empty string it means no record hence return empty array in string
  if (parsedOutputs[0]?.length === 1 && parsedOutputs[0][0] === "") {
    return EMPTY_RECORD_STRING;
  }
  parsedOutputs.forEach(record => {
    signature.columns.forEach((col, index) => {
      const val = castColumnType(record[index] || null, col.type);

      map.set(col.name, val);
    });
    outputRecords.push(Object.fromEntries(map));
  });

  return JSON.stringify(outputRecords);
};

//   outputs: "[{\"num_employees_without_insurance\": 1}]"
//   return: ["[[1]]"],
export const outputsFormatToArray = (outputs: OutputFormat[]): ArrayStringFormat[] => {
  let parsedOutputs: OutputRecordsSchemaType;
  try {
    // The outputs have only one table, hence parse the first element
    parsedOutputs = OutputRecordsSchema.parse(JSON.parse(outputs[0]));
  } catch (e) {
    parsedOutputs = [{}];
  }
  const arr = parsedOutputs.map(obj => {
    return Object.values(obj);
  });
  return [`${JSON.stringify(arr)}`];
};

// This function cast type by signature table schema for each column and rows
export const buildInputFormat = (input: ArrayStringFormat, tableSchema: AlgorithmSignatureForm.DatabaseTableSchemaType): ArrayStringFormat => {
  const parsedInput = parseTableRecords(input);
  if (parsedInput.length === 1 && parsedInput[0]?.length === 1) {
    return EMPTY_RECORD_STRING;
  }
  const formattedInput = parsedInput.map(row => {
    return tableSchema.columns.map((col, i) => {
      return castColumnType(row[i], col.type);
    });
  });
  return JSON.stringify(formattedInput);
};
