import { useTranslation } from "@hireroo/i18n";
import { useTheme } from "@mui/material";
import { green } from "@mui/material/colors";
import * as React from "react";
import { Bar, BarChart, BarProps, CartesianGrid, Label, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { CustomReferenceLineProps, useCreateCustomReferenceLine } from "../../privateHelpers/CustomReferenceLine";

type Axis = {
  label?: string;
  minTickGap?: number;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ScoreHistogramProps<Data extends { value: string | number } = any> = {
  xAxis: Axis;
  yAxis: Axis;
  data: Data[];
  dataKey: {
    x: string;
    y: string;
  };
  lines: CustomReferenceLineProps[];
  bar?: Pick<BarProps, "barSize">;
};

const ScoreHistogram: React.FC<ScoreHistogramProps> = props => {
  const theme = useTheme();
  const { t } = useTranslation();
  const bins = props.data.map(bin => ({ ...bin, value: Number(bin.value) }));
  const createCustomReferenceLine = useCreateCustomReferenceLine();
  return (
    <ResponsiveContainer>
      <BarChart
        data={bins}
        barGap={0}
        barCategoryGap={0}
        margin={{
          top: props.lines.length ? 60 : 0, // because benchmark label comes on top of the plot
          left: props.yAxis.label ? 30 : 0, // because we need some space on the left for y axis label
          right: props.lines.length ? 70 : 0, // because we need some space on the left for benchmark label
          bottom: props.xAxis.label ? 50 : 0, // because we need some space on the bottom, in addition to the ticks
        }}
      >
        <CartesianGrid stroke={theme.palette.text.disabled} strokeDasharray="3 3" vertical={false} />
        {props.yAxis.label && (
          <YAxis
            width={40}
            axisLine={false}
            tickLine={false}
            minTickGap={props.yAxis.minTickGap}
            tick={{ fontSize: 14, fill: theme.palette.text.primary }}
          >
            <Label style={{ fontSize: 14, fill: theme.palette.text.primary }} offset={10} position={"left"} angle={-90} dy={-30}>
              {props.yAxis.label}
            </Label>
          </YAxis>
        )}
        {props.xAxis.label && (
          <XAxis
            dataKey={"name"}
            minTickGap={props.xAxis.minTickGap ?? -30}
            axisLine={false}
            tickLine={false}
            angle={-45}
            dy={20}
            tick={{ fontSize: 14, fill: theme.palette.text.primary }}
          >
            <Label style={{ fontSize: 14, fill: theme.palette.text.primary }} offset={35} position={"bottom"}>
              {props.xAxis.label}
            </Label>
          </XAxis>
        )}

        <Bar dataKey={"value"} fill={green[400]} fillOpacity={1} isAnimationActive={false} {...props.bar} />
        {props.lines?.map(benchmark => createCustomReferenceLine(benchmark))}
        <Tooltip
          labelStyle={{
            color: theme.palette.text.secondary,
          }}
          formatter={(v: string) => {
            return [v, t("候補者数")];
          }}
          cursor={{ fillOpacity: 0.5 }}
          contentStyle={{
            borderColor: theme.palette.background.paper,
            backgroundColor: theme.palette.background.paper,
          }}
          itemStyle={{
            color: theme.palette.text.secondary,
          }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

ScoreHistogram.displayName = "ScoreHistogram";

export default ScoreHistogram;
