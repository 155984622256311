import { styled, useTheme } from "@mui/material/styles";
import React from "react";

import { FONT_FAMILY_SET } from "../../../constants/font";
import { FontSizeMap } from "../../../features";

const TextSvg = styled("text")(() => ({
  WebkitTouchCallout: "none",
  WebkitUserSelect: "none",
  msUserSelect: "none",
  userSelect: "none",
  pointerEvents: "none",
  fontFamily: FONT_FAMILY_SET.join(","),
  whiteSpace: "pre-wrap",
}));

export type UsernameProps = {
  color: string;
  x: number;
  y: number;
  name: string;
  textAnchor: "start" | "middle";
};

export const Username: React.FC<UsernameProps> = React.memo((props: UsernameProps) => {
  const theme = useTheme();
  return (
    <g>
      <defs>
        <filter x="0" y="0" width="1" height="1" id="solid">
          <feFlood floodColor={props.color} result="bg" />
          <feMerge>
            <feMergeNode in="bg" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <TextSvg
        style={{ textAnchor: props.textAnchor }}
        x={props.x}
        y={props.y}
        fontSize={FontSizeMap["medium"]}
        fill={theme.palette.common.white}
        filter="url(#solid)"
      >
        {` ${props.name ?? ""} `}
      </TextSvg>
    </g>
  );
});

Username.displayName = "Username";

export default Username;
