import { useTranslation } from "@hireroo/i18n";
import Badge, { BadgeProps } from "@mui/material/Badge";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";

import CustomLabelTab, { CustomLabelTabProps } from "./parts/CustomLabelTab/CustomLabelTab";

export type RightSidePanelProps = {
  Evaluation?: React.ReactNode;
  Chat: React.ReactNode;
  hasChatBadge: boolean;
};

const SidebarWrapper = styled(Box)`
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
`;

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  "& .MuiBadge-badge": {
    top: 0,
    right: -8,
  },
}));

const RightSidePanel: React.FC<RightSidePanelProps> = props => {
  const theme = useTheme();
  const { t } = useTranslation();
  const wrapperDetector = useResizeDetector();
  const customLabelTabProps: CustomLabelTabProps = {
    items: [
      props.Evaluation && {
        id: "evaluation",
        Component: props.Evaluation,
        name: t("評価"),
        icon: "COMMENT" as const,
      },
      {
        id: "chat",
        Component: props.Chat,
        label: props.hasChatBadge && (
          <StyledBadge color="primary" variant={"dot"}>
            <Typography textTransform="none" variant="body2" fontSize={14}>
              {t("チャット")}
            </Typography>
          </StyledBadge>
        ),
        icon: "CHAT" as const,
        name: t("チャット"),
        // This is for tutorial
        className: "chat-tab",
      },
    ].flatMap(v => (v ? [v] : [])),
    tabBox: {
      sx: { padding: 0 },
    },
    tabPanelBox: {
      sx: { marginTop: 0, overflowY: "auto", padding: 0 },
    },
  };
  return (
    <SidebarWrapper ref={wrapperDetector.ref} pb={1} bgcolor={theme.palette["Secondary/Shades"].p8}>
      <CustomLabelTab {...customLabelTabProps} />
    </SidebarWrapper>
  );
};

RightSidePanel.displayName = "RightSidePanel";

export default RightSidePanel;
