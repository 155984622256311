import FormControlLabel, { FormControlLabelProps } from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";

import SwitchControl from "../../../../primitive/InputControl/SwitchControl/SwitchControl";

const StyledSwitchControl = styled(SwitchControl)`
  margin-left: 8px;
  width: 42px;
`;

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  "& .MuiFormControlLabel-label": {
    wordBreak: "keep-all",
    whiteSpace: "nowrap",
    marginLeft: "4px",
    fontSize: 14,
  },
}));

export type ShareSettingsItemProps = {
  label: string;
  name: "showScore" | "showRank" | "showAnswer" | "showPlayback" | "showReview" | "showRelativeEvaluation";
  tooltipText?: string;
  disabled?: boolean;
};

const ShareSettingsItem: React.FC<ShareSettingsItemProps> = props => {
  const formControlLabelProps: FormControlLabelProps = {
    control: <StyledSwitchControl color="secondary" size="small" name={props.name} />,
    label: props.label,
    disabled: props.disabled,
  };
  return (
    <Grid item xs={6}>
      {props.disabled && (
        <Tooltip role="tooltip" title={props.tooltipText ?? ""}>
          <span>
            <StyledFormControlLabel {...formControlLabelProps} />
          </span>
        </Tooltip>
      )}
      {!props.disabled && <StyledFormControlLabel {...formControlLabelProps} />}
    </Grid>
  );
};

ShareSettingsItem.displayName = "ShareSettingsItem";

export default ShareSettingsItem;
