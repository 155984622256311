import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import * as React from "react";

const StyledBox = styled(Box)`
  width: 100%;
  text-align: center;
`;

const StyledIconButtonWithTooltip = styled(Button)`
  width: 100%;
  height: 100%;
  &:hover {
    background-color: transparent;
    cursor: grab;
  }
  &:active {
    cursor: grabbing;
  }
`;

export type DragIndicatorProps = ButtonProps;

const DragIndicator = React.forwardRef((props: DragIndicatorProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
  const theme = useTheme();
  return (
    <StyledBox>
      <StyledIconButtonWithTooltip ref={ref} {...props} disableRipple>
        <DragIndicatorIcon htmlColor={theme.palette.text.secondary} sx={{ transform: "rotate(90deg)" }} />
      </StyledIconButtonWithTooltip>
    </StyledBox>
  );
});

DragIndicator.displayName = "DragIndicator";

export default DragIndicator;
