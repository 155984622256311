import { translationLanguageMap } from "@hireroo/app-definition";
import { AlgorithmVariantMap } from "@hireroo/app-definition/algorithm";
import { INITIAL_VERSION } from "@hireroo/app-definition/question";
import { createAnswersInput, createHintInput } from "@hireroo/app-helper/algorithm";
import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Auth, Company } from "@hireroo/app-store/essential/employee";
import { AlgorithmResourceEditor } from "@hireroo/app-store/view-domain/AlgorithmResourceEditor";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { SupportLanguageValue, useTranslation } from "@hireroo/i18n";
import { Pages, Widget } from "@hireroo/presentation/legacy";
import { updateQueryParams } from "@hireroo/router/api";
import { AlgorithmQuestionForm } from "@hireroo/validator";
import * as Sentry from "@sentry/browser";
import * as React from "react";
import { SubmitHandler, useFieldArray } from "react-hook-form";

type UseGenerateQuestionDetailsPropsArgs = {
  companyId: number;
};

type QuestionDetailsProps = Pages.AlgorithmResourceEditorProps["questionDetails"];
type QuestionDetails = {
  props: QuestionDetailsProps;
  saveButton: Pages.AlgorithmResourceEditorProps["saveQuestionDetailsButton"];
};
export const useGenerateQuestionDetailsProps = (args: UseGenerateQuestionDetailsPropsArgs): QuestionDetails => {
  const { t } = useTranslation();
  const client = getGraphqlClient();
  const userId = Auth.useCurrentUid();
  const isHireroo = Company.useIsHireroo();
  const questionDetailsContext = Widget.useAlgorithmQuestionDetailFormContext();
  const [currentTabValue, setCurrentTabValue] = React.useState<string>("0");
  const question = AlgorithmResourceEditor.useQuestion();
  const [isTranslateLoading, setIsTranslateLoading] = React.useState<boolean>(false);
  const saveStatus = AlgorithmResourceEditor.useSaveStatus();

  const handleQuestionDetailsSubmit: SubmitHandler<AlgorithmQuestionForm.AlgorithmQuestionFormSchema> = React.useCallback(
    fields => {
      const content = {
        titleJa: "",
        descriptionJa: "",
        titleEn: "",
        descriptionEn: "",
      };
      fields.questions.forEach(q => {
        if (q.language === "ja") {
          content.descriptionJa = q.description;
          content.titleJa = q.title;
        }
        if (q.language === "en") {
          content.descriptionEn = q.description;
          content.titleEn = q.title;
        }
      });
      if (question) {
        const now = Date.now();
        const published = question.status === "PUBLISHED";

        client
          .UpdateAlgorithmQuestion({
            updateQuestionInput: {
              questionId: question.questionId,
              oldVersion: question.version,
              newVersion: published ? `${question?.version}-${now}` : question.version,
              companyId: args.companyId,
              employeeId: userId,
              variant: fields.variant,
              difficulty: fields.difficulty,
              timeLimitMin: fields.timeLimit,
              isPrivate: !fields.isPublic,
              status: "DRAFT",
              titleJa: content.titleJa,
              titleEn: content.titleEn,
              descriptionJa: content.descriptionJa,
              descriptionEn: content.descriptionEn,
              correctnessTestCase: question.correctnessTestCase,
              performanceTestCase: question.performanceTestCase,
              correctnessTestCaseIds: null,
              performanceTestCaseIds: null,
              performanceTimeLimitMilliSeconds: question.performanceTimeLimitMilliSeconds,
              signature: question.signature,
              answers: createAnswersInput(question.answers),
              hints: question.hints.map(h => createHintInput(h)),
            },
          })
          .then(res => {
            AlgorithmResourceEditor.updateQuestion(res.updateAlgorithmQuestion);
            Snackbar.notify({
              severity: "success",
              message: t("問題の一時保存が完了しました。"),
            });
            if (published) {
              updateQueryParams("version", [res.updateAlgorithmQuestion.version]);
            }
            // same number when database or algorithm
            AlgorithmResourceEditor.updateActiveStep(AlgorithmResourceEditor.STEPS.FUNCTION_DEFINITION);
          })
          .catch(error => {
            Sentry.captureException(error);
            const errorNotification = ErrorHandlingHelper.generateErrorNotification(
              error,
              t("問題の一時保存に失敗しました。しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
            );
            Snackbar.notify({
              severity: "error",
              message: errorNotification.message,
            });
          })
          .finally(() => {
            AlgorithmResourceEditor.updateSaveStatus("READY");
          });
      } else {
        client
          .CreateAlgorithmQuestion({
            createQuestionInput: {
              version: INITIAL_VERSION,
              companyId: args.companyId,
              employeeId: userId,
              variant: fields.variant,
              difficulty: fields.difficulty,
              timeLimitMin: fields.timeLimit,
              isPrivate: !fields.isPublic,
              status: "DRAFT",
              titleJa: content.titleJa,
              titleEn: content.titleEn,
              descriptionJa: content.descriptionJa,
              descriptionEn: content.descriptionEn,
              correctnessTestCaseIds: [],
              performanceTestCaseIds: [],
            },
          })
          .then(res => {
            AlgorithmResourceEditor.updateQuestion(res.createAlgorithmQuestion);
            AlgorithmResourceEditor.updateVariant(AlgorithmVariantMap[res.createAlgorithmQuestion.variant]);
            Snackbar.notify({
              severity: "success",
              message: t("問題の一時保存が完了しました。"),
            });
            updateQueryParams("id", [res.createAlgorithmQuestion.questionId.toString()]);
            // same number when database or algorithm
            AlgorithmResourceEditor.updateActiveStep(AlgorithmResourceEditor.STEPS.FUNCTION_DEFINITION);
          })
          .catch(error => {
            Sentry.captureException(error);
            const errorNotification = ErrorHandlingHelper.generateErrorNotification(
              error,
              t("問題の一時保存に失敗しました。しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
            );
            Snackbar.notify({
              severity: "error",
              message: errorNotification.message,
            });
          })
          .finally(() => {
            AlgorithmResourceEditor.updateSaveStatus("READY");
          });
      }
    },
    [args.companyId, client, question, t, userId],
  );

  const translateQuestion = React.useCallback(
    async (
      question: AlgorithmQuestionForm.AlgorithmQuestionDetailSchema,
      to: SupportLanguageValue,
    ): Promise<AlgorithmQuestionForm.AlgorithmQuestionDetailSchema> => {
      const res = await Promise.all([
        client.TranslatePlainTextForAlgorithmResourceEditor({
          source: {
            from: translationLanguageMap[question.language],
            to: translationLanguageMap[to],
            body: question.title,
          },
        }),
        client.TranslateMarkdownForAlgorithmResourceEditor({
          source: {
            from: translationLanguageMap[question.language],
            to: translationLanguageMap[to],
            body: question.description,
          },
        }),
      ])
        .then(res => {
          Snackbar.notify({
            severity: "success",
            message: t("自動翻訳に成功しました。"),
          });
          return res;
        })
        .catch(error => {
          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(
            error,
            t("自動翻訳に失敗しました。しばらくしてから再度お試しください。"),
          );
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
          return [];
        });
      return {
        title: res[0]?.translatePlainText ?? "",
        description: res[1]?.translateMarkdown ?? "",
        language: to,
      };
    },
    [client, t],
  );

  const questionsField = useFieldArray({
    control: questionDetailsContext.methods.control,
    name: "questions",
  });

  const questions = questionDetailsContext.methods.watch("questions");

  const selectedLanguagesSet = React.useMemo(() => {
    if (questions) {
      return new Set(questions.map(q => q.language));
    }
    return new Set();
  }, [questions]);

  return {
    props: {
      generalSettingFields: {
        canCreatePublic: isHireroo,
        isPublicSwitch: {
          //After the first version it is not allowed to change from public to private
          disabled: question && !question.isPrivate && (question.version !== INITIAL_VERSION || question.status === "PUBLISHED"),
        },
      },
      questionTextFields: {
        languageTab: {
          onTabsChange: value => {
            setCurrentTabValue(value);
          },
          menu: {
            items: [
              {
                text: "日本語",
                value: "ja",
                onClick: async () => {
                  setIsTranslateLoading(true);
                  const question = questionDetailsContext.methods.getValues(`questions.${Number(currentTabValue)}`);
                  const translated = await translateQuestion(question, "ja");
                  questionsField.append(translated);
                  setIsTranslateLoading(false);
                },
                isLoading: isTranslateLoading,
              },
              {
                text: "English",
                value: "en",
                onClick: async () => {
                  setIsTranslateLoading(true);
                  const question = questionDetailsContext.methods.getValues(`questions.${Number(currentTabValue)}`);
                  const translated = await translateQuestion(question, "en");
                  questionsField.append(translated);
                  setIsTranslateLoading(false);
                },
                isLoading: isTranslateLoading,
              },
            ].filter(item => !selectedLanguagesSet.has(item.value as SupportLanguageValue)),
          },
        },
      },
    },
    saveButton: {
      onClick: () => {
        AlgorithmResourceEditor.updateSaveStatus("LOADING");
        questionDetailsContext.methods.handleSubmit(handleQuestionDetailsSubmit, () => AlgorithmResourceEditor.updateSaveStatus("READY"))();
      },
      loading: saveStatus === "LOADING",
    },
  };
};
