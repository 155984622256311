import { BrowserWindowEventDetect, QuizRealtimeDB, useFirebaseSender } from "@hireroo/app-helper/hooks";
import { QuizCodingEditor } from "@hireroo/app-store/widget/shared/QuizCodingEditor";
import { getTimestamp } from "@hireroo/firebase";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type QuizCodingEditorContainerProps = {
  entityId: number;
  interviewKind: "INTERVIEW" | "DEMO";
  uid: string;
  packageId: number;
  firstQuestionId: number;
  enableBrowserEventDetector: boolean;
};

const QuizCodingEditorContainer: React.FC<QuizCodingEditorContainerProps> = props => {
  const quizEntityHooks = QuizCodingEditor.useCreateQuizEntityHooks(props.entityId);
  const entity = quizEntityHooks.useEntity();
  const ipAddress = quizEntityHooks.useIpAddress();
  const geolocation = quizEntityHooks.useGeolocation();

  const [state, action] = QuizRealtimeDB.useQuizRealtimeDatabase({
    quizId: props.entityId,
    packageId: props.packageId,
    firstQuestionId: props.firstQuestionId,
    isCandidate: true,
    isInterviewing: props.interviewKind === "INTERVIEW",
  });
  const quizCodingEditorProps = useGenerateProps({
    uid: props.uid,
    entityId: props.entityId,
    selectedQuestionId: state.selectedQuestionId,
    action,
    state,
  });
  const { pushEventHistory, pushAccessEvent } = useFirebaseSender({
    uid: props.uid,
    appType: "quiz",
    quizId: props.entityId,
    packageId: props.packageId,
    questionId: state.selectedQuestionId,
  });
  const browserWindowEventDetector = React.useRef(
    new BrowserWindowEventDetect({
      callback: pushEventHistory,
    }),
  );

  React.useEffect(() => {
    if (!props.enableBrowserEventDetector) {
      return;
    }
    if (entity.quizStatus !== "STARTED") {
      return;
    }
    const stop = browserWindowEventDetector.current.subscribe();
    return () => {
      stop();
    };
  }, [entity.quizStatus, geolocation, ipAddress, props.enableBrowserEventDetector, pushAccessEvent]);

  React.useEffect(() => {
    const shouldDetectEvents = props.enableBrowserEventDetector && entity.quizStatus === "STARTED";

    if (!shouldDetectEvents) {
      return;
    }

    if (ipAddress !== null && geolocation !== null) {
      pushAccessEvent({
        l: ipAddress,
        g: geolocation,
        t: getTimestamp(),
      });
    }
  }, [props.enableBrowserEventDetector, entity.quizStatus, ipAddress, geolocation, pushAccessEvent, state.selectedQuestionId]);

  if (!state.ready) {
    return <Widget.Loading kind="CENTER_%" color="secondary" />;
  }

  return (
    <ErrorBoundary>
      <Widget.QuizCodingEditor {...quizCodingEditorProps} />
    </ErrorBoundary>
  );
};

QuizCodingEditorContainer.displayName = "QuizCodingEditorContainer";

export default withErrorBoundary(QuizCodingEditorContainer, {});
