import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    paddingX: theme.spacing(3),
    paddingY: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export type NoActionDialogProps = {
  heading: string;
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
};

const NoActionDialog: React.FC<NoActionDialogProps> = props => {
  const theme = useTheme();
  const { open, onClose } = props;
  return (
    <BootstrapDialog onClose={props.onClose} open={open} PaperProps={{ sx: { width: "640px" } }}>
      <DialogTitle fontSize={theme.typography.subtitle1.fontSize} sx={{ m: 0, px: 2, py: 1.5 }}>
        {props.heading}
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{ fontSize: "20px" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>{props.children}</DialogContent>
    </BootstrapDialog>
  );
};

NoActionDialog.displayName = "NoActionDialog";

export default NoActionDialog;
