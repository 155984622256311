import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateProjectContentsViewerV4PropsArgs, useGenerateProps } from "./useGenerateProps";

export type ProjectContentsViewerV4ContainerProps = GenerateProjectContentsViewerV4PropsArgs;

const ProjectContentsViewerV4Container: React.FC<ProjectContentsViewerV4ContainerProps> = props => {
  const projectContentsViewerV4Props = useGenerateProps(props);

  return (
    <ErrorBoundary>
      <Widget.ProjectContentsViewerV4 {...projectContentsViewerV4Props} />
    </ErrorBoundary>
  );
};

ProjectContentsViewerV4Container.displayName = "ProjectContentsViewerV4Container";

export default withErrorBoundary(ProjectContentsViewerV4Container, {});
