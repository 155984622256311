import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type CandidateNavigationContainerProps = {};

const CandidateNavigationContainer: React.FC<CandidateNavigationContainerProps> = () => {
  const candidateNavigationProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.CandidateNavigation {...candidateNavigationProps} />
    </ErrorBoundary>
  );
};

CandidateNavigationContainer.displayName = "CandidateNavigationContainer";

export default withErrorBoundary(CandidateNavigationContainer, {});
