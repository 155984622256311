import { ChallengeTestReport } from "@hireroo/app-store/view-domain/ChallengeTestReport";
import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import { QuizTestReport } from "@hireroo/app-store/view-domain/QuizTestReport";
import { SystemDesignTestReport } from "@hireroo/app-store/view-domain/SystemDesignTestReport";
import { AssessmentReport } from "@hireroo/app-store/widget/t/AssessmentReport";

export const startSubscribeOnChangeTest = () => {
  return AssessmentReport.subscribeOnChangeExam(exam => {
    exam.entities.forEach(entity => {
      switch (entity.__typename) {
        case "ChallengeEntity": {
          ChallengeTestReport.initialize(entity);
          break;
        }
        case "SystemDesignEntity": {
          SystemDesignTestReport.initialize(entity);
          break;
        }
        case "ProjectEntity": {
          ProjectTestReport.initialize(entity);
          break;
        }
        case "QuizEntity": {
          QuizTestReport.initialize(entity);
          break;
        }
      }
    });
  });
};
