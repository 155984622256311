import { FeedbackLinkFactory, QuestionStats } from "@hireroo/app-helper/question";
import { useTitle } from "@hireroo/app-helper/react-use";
import { Credential } from "@hireroo/app-store/essential/shared";
import { formateRateToIntegerPercent } from "@hireroo/formatter/rate";
import { secondsToTimeObject } from "@hireroo/formatter/time";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslationWithVariable } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import { parseFlowChartSnapshot } from "@hireroo/system-design/helpers/flowChart";
import * as React from "react";

type AnswerSectionProps = Widget.SystemDesignQuestionDetailProps["answerSections"][0];

export type GenerateSystemDesignQuestionDetailPropsArgs = {
  questionDetail: Graphql.SystemDesignQuestionDetailFragment;
};

export const useGenerateProps = (args: GenerateSystemDesignQuestionDetailPropsArgs): Widget.SystemDesignQuestionDetailProps => {
  const { questionDetail } = args;
  const currentUserMailAddress = Credential.useCurrentUserMailAddress();
  const { scoringItems, answers, hints } = questionDetail;
  const [answerId, setAnswerId] = React.useState(0);
  const lang = useLanguageCode();
  const { t: t2 } = useTranslationWithVariable();
  const selectedAnswer = answers[answerId];
  const { result } = parseFlowChartSnapshot(selectedAnswer?.snapshot || "");
  useTitle(resolveLanguage(questionDetail, lang, "title"));
  const averageElapsedTimeObject = secondsToTimeObject(questionDetail.averageElapsedTimeSeconds, "MINUTES");
  const timelimitObject =
    questionDetail.timeLimitSeconds !== null ? secondsToTimeObject(questionDetail.timeLimitSeconds, "MINUTES") : undefined;
  return {
    questionAndAnswerTab: {
      questionSection: {
        title: resolveLanguage(questionDetail, lang, "title"),
        description: resolveLanguage(questionDetail, lang, "description"),
        isArchived: questionDetail.status === "ARCHIVED",
      },
      questionHintStack: {
        items: hints.map((hint, index) => {
          return {
            mode: "unlocked",
            value: {
              title: t2("ConfirmHint", { num: index + 1 }),
              description: resolveLanguage(hint, lang, "description"),
              disabled: false,
            },
          };
        }),
      },
    },
    answerSections: answers.map((answer, index): AnswerSectionProps => {
      return {
        onSelect: () => {
          setAnswerId(index);
        },
        title: resolveLanguage(answer, lang, "title"),
        description: resolveLanguage(answer, lang, "description"),
        answer: {
          value: answerId.toString(),
          items: answers.map((answer, index) => {
            return {
              text: resolveLanguage(answer, lang, "title"),
              value: index.toString(),
            };
          }),
        },
        availability: {
          items: scoringItems
            .filter(item => item.category === "AVAILABILITY")
            .map(item => ({
              title: resolveLanguage(item, lang, "title"),
              description: resolveLanguage(item, lang, "description"),
            })),
        },
        scalability: {
          items: scoringItems
            .filter(item => item.category === "SCALABILITY")
            .map(item => ({
              title: resolveLanguage(item, lang, "title"),
              description: resolveLanguage(item, lang, "description"),
            })),
        },
        consistency: {
          items: scoringItems
            .filter(item => item.category === "CONSISTENCY")
            .map(item => ({
              title: resolveLanguage(item, lang, "title"),
              description: resolveLanguage(item, lang, "description"),
            })),
        },
      };
    }),
    staticFlowChart: {
      ...result,
      componentType: "default",
    },
    information: {
      feedbackLink: FeedbackLinkFactory.generateFeedbackLink({
        mailAddress: currentUserMailAddress,
        targetUrl: window.location.href,
      }),
      scoreHistogram: {
        data: QuestionStats.generateScoreDistributionBinsForHistogram(questionDetail.scoreDistributionBins),
        dataKey: {
          x: "name",
          y: "value",
        },
      },
      stats: {
        avgCorrectIntegerRate:
          questionDetail.numUses > 0
            ? {
                kind: "VALUE",
                value: formateRateToIntegerPercent(questionDetail.accuracyRate),
              }
            : {
                kind: "NONE",
              },
        numOfUsage: questionDetail.numUses.toLocaleString(),
        avgElapseTime:
          questionDetail.numUses > 0
            ? {
                kind: "VALUE",
                minutes: averageElapsedTimeObject.minutes,
                seconds: averageElapsedTimeObject.seconds,
              }
            : { kind: "NONE" },
      },
      detailInfo: {
        difficultyStars: {
          difficulty: questionDetail.difficulty,
        },
        timelimitMinutes: timelimitObject?.minutes,
        createdBy: questionDetail.isOfficial
          ? {
              kind: "OFFICIAL",
            }
          : {
              kind: "PRIVATE",
              //TODO: implement when the user can create private question
              displayName: "",
            },
      },
    },
  };
};
