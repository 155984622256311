import { useTranslation } from "@hireroo/i18n";
import { EntryScreeningForm } from "@hireroo/validator";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import EntryContentPaper from "../../../../modules/EntryContentPaper/EntryContentPaper";
import ResourceField from "../../../../modules/ResourceField/ResourceField";
import TestStartDialog, { TestStartDialogProps } from "../../../../ms-components/Spot/TestStartDialog/TestStartDialog";
import ButtonWithTooltip, { ButtonWithTooltipProps } from "../../../../primitive/Button/ButtonWithTooltip/ButtonWithTooltip";
import InputControlTextField, {
  InputControlTextFieldProps,
} from "../../../../primitive/InputControl/InputControlTextField/InputControlTextField";
import ValidationErrorList from "./parts/ValidationErrorList/ValidationErrorList";

const Heading = styled(Typography)(() => ({
  fontWeight: "bold",
  textAlign: "center",
}));

const StyledButtonWithTooltip = styled(ButtonWithTooltip)(() => ({
  padding: "8px 22px",
  fontSize: 15,
  height: 54,
  display: "inline-flex",
  flexDirection: "column",
}));

const ButtonMainText = styled("span")(() => ({
  fontSize: 15,
  lineHeight: "21px",
}));

const ButtonSubText = styled("span")(() => ({
  fontSize: 12,
  lineHeight: "16.8px",
}));

type Controller = {
  setLoading: (loading: boolean) => void;
};

export type ConfirmFormProps = {
  onSubmitAccept: (fields: EntryScreeningForm.EntryScreeningFormSchema, controller: Controller) => void;
  onSubmitAcceptAndStart: (fields: EntryScreeningForm.EntryScreeningFormSchema, controller: Controller) => void;
  startDemoButton: TestStartDialogProps["startDemoButton"];
  onInputError: (error: unknown) => void;
};

const ConfirmForm: React.FC<ConfirmFormProps> = props => {
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const controller: Controller = {
    setLoading: newLoading => {
      setLoading(newLoading);
    },
  };
  const { t } = useTranslation();
  const methods = useFormContext<EntryScreeningForm.EntryScreeningFormSchema>();

  const usernameField: InputControlTextFieldProps = {
    name: "name",
    type: "text",
    fullWidth: true,
    placeholder: t("灰屋 太郎"),
    InputLabelProps: {
      shrink: true,
    },
    control: methods.control,
    required: true,
    helperText: methods.formState.errors?.name?.message,
  };
  const emailField: InputControlTextFieldProps = {
    name: "email",
    type: "text",
    fullWidth: true,
    placeholder: "hireroo@hireroo.io",
    InputLabelProps: {
      shrink: true,
    },
    control: methods.control,
    required: true,
    helperText: methods.formState.errors?.email?.message
      ? methods.formState.errors.email.message
      : t("テスト中誤って閉じてしまった際に再開できるように、入力されたメールアドレス宛にテストURLをお送り致します。"),
  };
  const acceptButtonProps: ButtonWithTooltipProps = {
    variant: "outlined",
    title: !methods.watch("agreement") ? t("この操作を行うには、利用規約に同意する必要があります。") : undefined,
    disabled: loading || !methods.watch("agreement"),
    children: (
      <>
        <ButtonMainText>{t("あとでテストを受ける")}</ButtonMainText>
        <ButtonSubText>{`(${t("テストリンクのみ発行")})`}</ButtonSubText>
      </>
    ),
    onClick: () => {
      methods.handleSubmit(fields => {
        props.onSubmitAccept(fields, controller);
      }, props.onInputError)();
    },
  };
  const acceptButtonAndStartProps: ButtonWithTooltipProps = {
    variant: "contained",
    title: !methods.watch("agreement") ? t("この操作を行うには、利用規約に同意する必要があります。") : undefined,
    disabled: loading || !methods.watch("agreement"),
    children: t("今すぐテストまたはデモを受ける"),
    onClick: () => {
      setOpen(true);
    },
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLFormElement> = event => {
    if (event.key === "Enter") event.preventDefault();
  };

  const handleSubmit = () => {
    methods.handleSubmit(fields => {
      props.onSubmitAccept(fields, controller);
    }, props.onInputError);
  };

  const testStartDialogProps: TestStartDialogProps = {
    open,
    onStart: controller => {
      methods.handleSubmit(
        fields => {
          props.onSubmitAcceptAndStart(fields, controller);
        },
        error => {
          setOpen(false);
          props.onInputError(error);
        },
      )();
    },
    startDemoButton: props.startDemoButton,
    onClose: () => {
      setOpen(false);
    },
  };

  return (
    <EntryContentPaper>
      <Stack spacing={2}>
        <Heading variant="h6">{t("テスト開始")}</Heading>

        <Typography variant="body2">{t("テストに必要な以下の情報を入力して開始してください。")}</Typography>

        <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
          <Stack spacing={2}>
            <Stack spacing={2}>
              <ResourceField label={t("名前")} kind="REQUIRED">
                <InputControlTextField name="name" {...usernameField} />
              </ResourceField>
              <ResourceField label={t("メールアドレス")} kind="REQUIRED">
                <InputControlTextField name="email" {...emailField} />
              </ResourceField>
            </Stack>
            <ValidationErrorList />
            <Stack direction="row" justifyContent="center" mt={4} spacing={2}>
              <StyledButtonWithTooltip {...acceptButtonProps} />
              <StyledButtonWithTooltip {...acceptButtonAndStartProps} />
            </Stack>
          </Stack>
        </form>

        <TestStartDialog {...testStartDialogProps} />
      </Stack>
    </EntryContentPaper>
  );
};

ConfirmForm.displayName = "ConfirmForm";

export default ConfirmForm;
