import { downloadFile } from "@hireroo/app-helper/download";
import { ScreeningsTestsIdDetailStore } from "@hireroo/app-store/page/e/screenings_tests_id_detail";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { getTranslation, getTranslationWithVariable } from "@hireroo/i18n";
import * as Sentry from "@sentry/browser";
import { backOff } from "exponential-backoff";

const fetchDownloadUrl = async (pdfState: ScreeningsTestsIdDetailStore.ExportPdfState): Promise<string | null> => {
  const client = getGraphqlClient();
  const { t } = getTranslation();
  try {
    ScreeningsTestsIdDetailStore.updatePdfExportState(pdfState.spotId, {
      status: "PENDING",
      downloadUrl: null,
    });
    return backOff(
      async () => {
        const res = await client.TestReportPdfForInterviewsIdDetail({
          id: pdfState.id,
        });
        if (res.testReportPdf.status === "COMPLETE") {
          return res.testReportPdf.downloadUrl;
        }
        return Promise.reject();
      },
      {
        delayFirstAttempt: true,
        timeMultiple: 1,
        /**
         * 20 x 2000 = 40sec (max)
         */
        numOfAttempts: 20,
        /**
         * You have to wait 4000ms to output PDF.
         */
        maxDelay: 6000,
        /**
         * Polling interval milli seconds
         */
        startingDelay: 2000,
      },
    );
  } catch (error) {
    ScreeningsTestsIdDetailStore.updatePdfExportState(pdfState.spotId, {
      status: "FAILED",
      downloadUrl: null,
    });
    Snackbar.notify({
      severity: "error",
      message: t("PDFのダウンロードに失敗しました。"),
    });
    Sentry.captureException(error);
    return null;
  }
};

export const startSubscribePdfState = () => {
  return ScreeningsTestsIdDetailStore.subscribeExportPdfState(async pdfState => {
    const { t } = getTranslation();
    const { t: t2 } = getTranslationWithVariable();
    const downloadUrl = await fetchDownloadUrl(pdfState);
    if (!downloadUrl) {
      return;
    }
    ScreeningsTestsIdDetailStore.updatePdfExportState(pdfState.spotId, { status: "SUCCESS", downloadUrl });
    try {
      await downloadFile(downloadUrl, pdfState.outputFilename);
      Snackbar.notify({
        severity: "success",
        message: t("PDFのダウンロードに成功しました"),
      });
    } catch (error) {
      Snackbar.notify({
        severity: "error",
        message: t2("FailedAutomaticDownloadContent", { target: "PDF" }),
      });
      Sentry.captureException(error);
    }
  });
};
