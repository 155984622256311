import { variantMap } from "@hireroo/app-definition/freepad";
import { FeedbackLinkFactory } from "@hireroo/app-helper/question";
import { useTitle } from "@hireroo/app-helper/react-use";
import { Credential } from "@hireroo/app-store/essential/shared";
import { secondsToTimeObject } from "@hireroo/formatter/time";
import type * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import { Widget } from "@hireroo/presentation";
import { ComponentType } from "@hireroo/system-design/features";
import { parseFlowChartSnapshot } from "@hireroo/system-design/helpers/flowChart";
import { StaticFlowChartProps } from "@hireroo/system-design/react/StaticFlowChart";
import * as React from "react";

type Props = Extract<Widget.FreepadQuestionDetailProps, { kind: "SYSTEM_DESIGN" }>;

export type GenerateFreepadQuestionDetailPropsArgs = {
  question: Graphql.FreepadQuestionDetailFragment;
};

const emptyFlowChart = { elements: [], componentType: "default" } satisfies StaticFlowChartProps;

export const useGenerateProps = (args: GenerateFreepadQuestionDetailPropsArgs): Widget.FreepadQuestionDetailProps => {
  const lang = useLanguageCode();
  const currentUserMailAddress = Credential.useCurrentUserMailAddress();
  const variant = variantMap[args.question.variant];
  const [answerIndex, setAnswerIndex] = React.useState(0);
  const answer = args.question.answers.at(answerIndex);
  const answerFlowChartElementComponentTypeMap = React.useMemo((): Record<string, StaticFlowChartProps | undefined> => {
    if (variant === "SYSTEM_DESIGN" && answer) {
      return answer.answerFlowCharts.reduce((previousValue, currentValue) => {
        const { ok, result } = parseFlowChartSnapshot(currentValue.body);
        return { ...previousValue, [currentValue.componentType]: ok ? result : emptyFlowChart };
      }, {});
    }
    return {};
  }, [answer, variant]);
  const enabledComponentTypes = (answer?.answerFlowCharts || []).map(chart => chart.componentType as ComponentType);
  const firstComponentType = enabledComponentTypes?.at(0);
  const [selectedComponentType, setSelectedComponentType] = React.useState<ComponentType>(firstComponentType ?? "default");
  const [open, setOpen] = React.useState<boolean>(args.question.status !== "PUBLISHED" && args.question.status !== "ARCHIVED");

  useTitle(resolveLanguage(args.question, lang, "title"));

  const averageElapsedTimeObject = secondsToTimeObject(args.question.averageElapsedTimeSeconds, "MINUTES");

  const answerSections = args.question.answers.map((answer, index): Props["content"]["answerSections"][0] => {
    return {
      title: resolveLanguage(answer, lang, "title"),
      description: resolveLanguage(answer, lang, "description"),
      onSelect: () => {
        const nextAnswer = args.question.answers.at(index);
        const nextEnabledComponentTypes = (nextAnswer?.answerFlowCharts || []).map(chart => chart.componentType as ComponentType);
        const nextFirstComponentType = nextEnabledComponentTypes?.at(0);
        setAnswerIndex(index);
        setSelectedComponentType(nextFirstComponentType ?? "default");
      },
    };
  });

  return {
    kind: "SYSTEM_DESIGN",
    content: {
      questionAndAnswerTab: {
        questionSection: {
          title: resolveLanguage(args.question, lang, "title"),
          description: resolveLanguage(args.question, lang, "description"),
          isArchived: args.question.status === "ARCHIVED",
        },
      },
      staticFlowChart: {
        ...(answerFlowChartElementComponentTypeMap[selectedComponentType] ?? emptyFlowChart),
      },
      selector: {
        value: selectedComponentType,
        selectableComponentTypes: enabledComponentTypes,
        onChange: componentType => {
          setSelectedComponentType(componentType);
        },
      },
      answerSections: answerSections,
      information: {
        feedbackLink: FeedbackLinkFactory.generateFeedbackLink({
          mailAddress: currentUserMailAddress,
          targetUrl: window.location.href,
        }),
        stats: {
          numOfUsage: args.question.numUses.toLocaleString(),
          avgElapseTime:
            args.question.numUses > 0
              ? {
                  kind: "VALUE",
                  minutes: averageElapsedTimeObject.minutes,
                  seconds: averageElapsedTimeObject.seconds,
                }
              : { kind: "NONE" },
        },
        detailInfo: {
          difficultyStars: {
            difficulty: args.question.difficulty,
          },
          createdBy: args.question.isOfficial
            ? {
                kind: "OFFICIAL",
              }
            : {
                kind: "PRIVATE",
                displayName: args.question.employee.displayName,
              },
        },
      },
    },
    notificationDialog: {
      open: open,
      onClose: () => {
        setOpen(false);
      },
    },
  };
};
