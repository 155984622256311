import { useTranslation } from "@hireroo/i18n";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import SubmissionAccordionEvaluationButtonGroup from "../SubmissionAccordionEvaluationButtonGroup/SubmissionAccordionEvaluationButtonGroup";
import SubmissionAccordionItemLayout, {
  SubmissionAccordionItemLayoutProps,
} from "../SubmissionAccordionItemLayout/SubmissionAccordionItemLayout";

type EvaluationStatus = "NOT_EVALUATED" | "CORRECT" | "IN_CORRECT";

type EvaluationItem = {
  id: string;
  title: string;
  reason?: string;
  status: EvaluationStatus;
};

export type SubmissionAccordionEvaluationItemContentProps = {
  evaluatedBy?: string;
  canEvaluate?: boolean;
  mode: "EDIT" | "READ";
  submissionId: number;
  evaluationItems: EvaluationItem[];
};

const SubmissionAccordionEvaluationItemContent: React.FC<SubmissionAccordionEvaluationItemContentProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();

  const IconMap = {
    NOT_EVALUATED: <CircleIcon color="disabled" fontSize="small" />,
    CORRECT: <CheckCircleIcon fontSize="small" sx={{ color: theme.palette.success.light }} />,
    IN_CORRECT: <CancelIcon fontSize="small" sx={{ color: theme.palette.error.light }} />,
  };

  const contentProps: SubmissionAccordionItemLayoutProps = {
    title: t("評価項目"),
    child: {
      kind: "COMPONENT",
      content: (
        <>
          <List>
            {!props.canEvaluate && (
              <Box mb={1}>
                <Typography variant="body2" color="inherit">{`※${t("この問題は未提出のため評価ができません。")}`}</Typography>
              </Box>
            )}

            {props.evaluationItems.length > 0 ? (
              props.evaluationItems.map((item, index) => (
                <ListItem sx={{ px: 0 }} key={item.id}>
                  <Box display="inline-flex" justifyContent="space-between" alignItems="center" width="100%">
                    <Box display="flex" alignItems="center">
                      {props.mode === "READ" && (
                        <Box mr={1} display="flex" alignItems="center">
                          {IconMap[item.status]}
                        </Box>
                      )}
                      {props.canEvaluate && props.mode === "EDIT" && (
                        <Box display="flex" alignItems="center">
                          <SubmissionAccordionEvaluationButtonGroup name={`${props.submissionId}.evaluations.${index}.isPassed`} />
                        </Box>
                      )}
                      <Box>
                        <Typography variant="body2" flexGrow={1}>
                          {item.title}
                        </Typography>
                        {item.reason && (
                          <Typography variant="body2" color="text.secondary" flexGrow={1}>
                            {t("採点理由")}
                            {": "}
                            {item.reason}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </ListItem>
              ))
            ) : (
              <Box>
                <Typography variant="body2">{t("評価項目が存在しません。")}</Typography>
                <Typography variant="caption">{t("評価項目が無い問題の場合は、正解不正解のみの評価が可能です。")}</Typography>
                {props.canEvaluate && props.mode === "EDIT" && (
                  <Box display="flex" alignItems="center">
                    <SubmissionAccordionEvaluationButtonGroup name={`${props.submissionId}.isCorrect`} />
                  </Box>
                )}
              </Box>
            )}
          </List>
          {props.evaluatedBy && (
            <Box textAlign="end" mb={-1}>
              <Typography variant="caption">{`${t("最終編集者")}: ${props.evaluatedBy}`}</Typography>
            </Box>
          )}
        </>
      ),
    },
  };

  return <SubmissionAccordionItemLayout {...contentProps} />;
};

SubmissionAccordionEvaluationItemContent.displayName = "SubmissionAccordionEvaluationItemContent";

export default SubmissionAccordionEvaluationItemContent;
