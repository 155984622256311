import { ProjectCodingEditorV3 } from "@hireroo/app-store/widget/shared/ProjectCodingEditorV3";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { ProjectBackendTestCaseSchemaV3, ProjectFrontendTestCaseSchemaV3 } from "@hireroo/validator";
import * as Sentry from "@sentry/browser";
import * as React from "react";
import * as z from "zod";

import { Mode } from "./types";

const PROJECT_DOMAIN = import.meta.env.VITE_PROJECT_DOMAIN;

if (typeof PROJECT_DOMAIN !== "string") {
  throw new Error("Please Setup");
}

export type EndpointsArgs = {
  id: number;
  mode: Mode;
};

type Endpoints = {
  fileSync: string;
  build: string;
  lsp: string;
  httpBase: string;
  wsBase: string;
  agentHealthCheck: string;
  appHealthCheck: string;
};

export const useEndpoints = (args: EndpointsArgs): Endpoints => {
  const { id, mode } = args;

  return React.useMemo(() => {
    const endpointsMap: Record<Mode, Endpoints> = {
      DEVELOPMENT: {
        fileSync: `wss://development-${id}.${PROJECT_DOMAIN}:8080/v3/ws/file_sync`,
        build: `wss://development-${id}.${PROJECT_DOMAIN}:8080/v3/ws/build`,
        lsp: `wss://development-${id}.${PROJECT_DOMAIN}:8080/v3/ws/lsp`,
        agentHealthCheck: `https://development-${id}.${PROJECT_DOMAIN}:8080/v3/health_check/agent`,
        appHealthCheck: `https://development-${id}.${PROJECT_DOMAIN}:8080/v3/health_check/app`,
        httpBase: `https://development-${id}.${PROJECT_DOMAIN}`,
        wsBase: `wss://development-${id}.${PROJECT_DOMAIN}`,
      },
      EVALUATION: {
        fileSync: `wss://evaluation-${id}.${PROJECT_DOMAIN}:8080/v3/ws/file_sync`,
        build: `wss://evaluation-${id}.${PROJECT_DOMAIN}:8080/v3/ws/build`,
        lsp: `wss://evaluation-${id}.${PROJECT_DOMAIN}:8080/v3/ws/lsp`,
        agentHealthCheck: `https://evaluation-${id}.${PROJECT_DOMAIN}:8080/v3/health_check/agent`,
        appHealthCheck: `https://evaluation-${id}.${PROJECT_DOMAIN}:8080/v3/health_check/app`,
        httpBase: `https://evaluation-${id}.${PROJECT_DOMAIN}`,
        wsBase: `wss://evaluation-${id}.${PROJECT_DOMAIN}`,
      },
    };
    return endpointsMap[mode];
  }, [id, mode]);
};

export const useAgentServerHealthCheck = (entityId: number, endpoint: string) => {
  const failedCount = React.useRef<number>(0);
  const { setAgentServerHealth } = ProjectCodingEditorV3.createProjectEntityAction(entityId);
  const { useWorkspace, useAgentServerHealth } = ProjectCodingEditorV3.useCreateProjectEntityHooks(entityId);
  const workspace = useWorkspace();
  const agentServerHealth = useAgentServerHealth();
  const { t } = useTranslation();

  const request = React.useCallback(async () => {
    if (workspace === null) {
      return;
    }
    return fetch(endpoint)
      .then(response => {
        const statusOK = response.status >= 200 && response.status < 300;
        if (!statusOK) {
          if (failedCount.current > 100) {
            // 300 sec >=
            Sentry.captureMessage(
              `NEED ACTION: [EntityId=${entityId}] Agent server health check for v3 failed at ${endpoint} with status ${response.status} and body ${response.body}`,
              "error",
            );
            Snackbar.notify({
              severity: "error",
              message: t("環境の準備が長時間要しております。運営までお問い合わせください。"),
            });
            /**
             * Health Checkの失敗回数は上限に到達したら一度リセットして再カウントする
             */
            failedCount.current = 0;
          } else if (failedCount.current === 20) {
            // 60 sec
            Sentry.captureMessage(
              `WARNING: EntityId=${entityId}] Agent server health check for v3 failed at ${endpoint} with status ${response.status} and body ${response.body}`,
              "warning",
            );
          }
        }
        setAgentServerHealth(statusOK);
      })
      .catch(err => {
        if (failedCount.current > 100) {
          // TODO: Remove this logger after the bug where initialize request isn't sent is fixed
          Sentry.captureMessage(
            `NEED ACTION: EntityId=${entityId}] Agent server health check for v3 failed at ${endpoint} with err ${err.message}`,
            "error",
          );
          Snackbar.notify({
            severity: "error",
            message: t("環境の準備が長時間要しております。運営までお問い合わせください。"),
          });
          /**
           * Health Checkの失敗回数は上限に到達したら一度リセットして再カウントする
           */
          failedCount.current = 0;
        } else if (failedCount.current === 20) {
          Sentry.captureMessage(
            `WARNING: EntityId=${entityId}] Agent server health check for v3 failed at ${endpoint} with err ${err.message}`,
            "warning",
          );
        }
        setAgentServerHealth(false);
      });
  }, [t, endpoint, entityId, setAgentServerHealth, workspace]);

  React.useLayoutEffect(() => {
    request(); // Send the request once before executing setInterval.
    const polling = setInterval(() => {
      failedCount.current++;
      request();
    }, 3000);
    return () => polling && clearInterval(polling);
  }, [request, endpoint]);

  return agentServerHealth;
};

const AppHealthCheckResponseSchema = z.object({
  health: z.boolean(),
  message: z.string(),
});

export const useApplicationServerHealthCheck = (entityId: number, endpoint: string, agentServerHealth: boolean) => {
  const failedCount = React.useRef<number>(0);
  const { setApplicationServerHealth } = ProjectCodingEditorV3.createProjectEntityAction(entityId);
  const { useWorkspace, useApplicationServerHealth } = ProjectCodingEditorV3.useCreateProjectEntityHooks(entityId);
  const workspace = useWorkspace();
  const applicationServerHealth = useApplicationServerHealth();

  const request = React.useCallback(async () => {
    if (workspace === null || applicationServerHealth) {
      return;
    }
    return fetch(endpoint)
      .then(response => {
        response.json().then(data => {
          const parsed = AppHealthCheckResponseSchema.parse(data);
          if (!parsed.health && failedCount.current > 100) {
            // TODO: Remove this logger after the bug where initialize request isn't sent is fixed
            Sentry.captureMessage(`application server health check for v3 failed at ${endpoint}`, "error");
          }
          setApplicationServerHealth(parsed.health);
        });
      })
      .catch(err => {
        if (failedCount.current > 100) {
          // TODO: Remove this logger after the bug where initialize request isn't sent is fixed
          Sentry.captureMessage(`application server health check for v3 failed at ${endpoint} with err ${err.message}`, "error");
        }
        setApplicationServerHealth(false);
      });
  }, [applicationServerHealth, endpoint, setApplicationServerHealth, workspace]);
  React.useLayoutEffect(() => {
    if (!agentServerHealth) return;
    request(); // Send the request once before executing setInterval.
    const polling = setInterval(() => {
      failedCount.current++;
      request();
    }, 3000);
    return () => polling && clearInterval(polling);
  }, [request, endpoint, agentServerHealth]);

  return applicationServerHealth;
};

export type GenerateProblemTextArgsForBackend = {
  command: ProjectBackendTestCaseSchemaV3.BackendCommandSchema;
  resultPerCommand?: ProjectBackendTestCaseSchemaV3.BackendResultPerCommand;
};

export const useGetTestResultDescriptionGeneratorForBackend = () => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  return ({ command, resultPerCommand }: GenerateProblemTextArgsForBackend): string => {
    if (!resultPerCommand) return t("テストケースの実行前です。");

    const problems: string[] = [];
    // For each command in the test case, check if id might be a problem
    if (resultPerCommand.status !== command.status) {
      problems.push(
        "- " +
          t2("BackendStatusWarningText", {
            status: command.status,
          }),
      );
    }

    if (resultPerCommand.latency > command.timeout) {
      problems.push(
        "- " +
          t2("BackendDeadlineExceededWarningText", {
            deadline: resultPerCommand.latency,
          }),
      );
    }

    if (resultPerCommand.expected !== "ANY") {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let x: any = {};
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let y: any = {};
      try {
        x = JSON.parse(resultPerCommand.output) ?? {};
      } catch (err) {
        // user value could be invalid json.
        // ignore an error as it's not critical
      }

      try {
        y = JSON.parse(resultPerCommand.expected) ?? {};
      } catch (err) {
        // expected value could be invalid json.
        // ignore an error as it's not critical
      }

      // Deep required equal
      for (const key of Object.keys(y)) {
        if (!x[key]) {
          const displayKey = Array.isArray(y) ? `\`${key}\`` + t("番目の要素") : `\`${key}\``;

          problems.push(
            "- " +
              t2("BackendMissingKeyWarningText", {
                key: displayKey,
              }),
          );
        } else {
          if (x[key] !== y[key]) {
            const displayKey = Array.isArray(y) ? `\`${key}\`` + t("番目の要素") : `\`${key}\``;

            problems.push(
              "- " +
                t2("BackendValueDifferWarningText", {
                  key: displayKey,
                  expected: `\`${JSON.stringify(y[key])}\``,
                }),
            );
          }
        }
      }
    }

    if (problems.length === 0) {
      return t("実行結果に問題がありました。実装されているコードを確認してください。");
    }
    return t("以下の問題が発生しています。コードを確認してください") + ":\n" + problems.join("\n");
  };
};

export type GenerateProblemTextArgsForFrontend = {
  externalIp: string;
  testCase: ProjectFrontendTestCaseSchemaV3.FrontendCorrectnessTestCaseSchema;
  testResult?: ProjectFrontendTestCaseSchemaV3.FrontendCorrectnessTestCaseResultSchema;
};

export const useGetTestResultDescriptionGeneratorForFrontend = () => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  return ({ testCase, testResult, externalIp }: GenerateProblemTextArgsForFrontend): string => {
    if (!testResult) return t("テストケースの実行前です。");

    const problems: string[] = [];
    // For each command in the test case, check if id might be a problem
    for (let i = 0; i < testCase.test_case_commands.length; i++) {
      if (testResult.is_passed) continue;
      if (testCase.test_case_commands[i].action === "LOAD") {
        // If loading is the problem, let the user know server should be started
        problems.push(
          "- " +
            t2("FrontendLoadWarningText", {
              applicationUrl: `http://${externalIp}:8000${testCase.test_case_commands[i].body}`,
            }),
        );
      } else if (testCase.test_case_commands[i].action === "ERR") {
        // If error is the problem, let the user know error was expected
        problems.push("- " + t2("FrontendErrorWarningText"));
      } else {
        // If ID is the problem, let the user know, otherwise ignore it
        problems.push(
          "- " +
            t2("FrontendIDWarningText", {
              componentID: `\`${testCase.test_case_commands[i].id}\``,
            }),
        );
      }
    }

    if (problems.length === 0) {
      return t("実行結果に問題がありました。実装されているコードを確認してください。");
    }

    return t("以下の問題が発生しています。コードを確認してください") + ":\n" + problems.join("\n");
  };
};
