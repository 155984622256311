/* eslint-disable @typescript-eslint/ban-ts-comment */
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { createTheme, ThemeProvider as MUIThemeProvider } from "@mui/material/styles";
import * as React from "react";

import colors from "./ui-colors";

declare module "@mui/material/styles/createPalette" {}

const theme = createTheme({
  /**
   * The required one has increased due to the influence of theme-v2 type definitions, but the old UI is irrelevant and should be ignored
   */
  // @ts-ignore
  palette: {
    mode: "dark",
    primary: {
      main: colors.dark.primary.main,
      dark: colors.dark.primary.dark,
      light: colors.dark.primary.light,
    },
    secondary: {
      main: colors.dark.secondary.main,
      dark: colors.dark.secondary.dark,
      light: colors.dark.secondary.light,
    },
    background: {
      default: colors.dark.background.default,
      paper: colors.dark.background.paper,
    },
  },
  typography: {
    allVariants: {
      color: colors.dark.text.primary,
    },
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiTabs: {
      defaultProps: {
        textColor: "inherit",
        indicatorColor: "secondary",
      },
    },
    MuiPaper: {
      styleOverrides: { root: { backgroundImage: "unset" } },
    },
  },
});

/**
 * scrollbar view become dark theme.
 */
const DarkThemeGlobalCss = <GlobalStyles styles={{ html: { colorScheme: "dark" } }} />;

export const ThemeProvider = ({ children }: { children?: React.ReactNode }) => {
  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        {DarkThemeGlobalCss}
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
};

export const withThemeProvider = <P extends Record<string, unknown>>(WrappedComponent: React.ComponentType<P>) => {
  const Wrapped: React.FC<P> = (props: P) => (
    <ThemeProvider>
      <WrappedComponent {...props} />
    </ThemeProvider>
  );
  return Wrapped;
};
