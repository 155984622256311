import MuiLink, { LinkProps as MuiLinkProps } from "@mui/material/Link";
import * as React from "react";

type Mode = Exclude<LinkProps["underline"], undefined>;

export type LinkProps = {
  href?: string;
  target?: "_blank";
  disablePreventDefault?: boolean;
} & Pick<MuiLinkProps, "onClick" | "children" | "className" | "underline" | "color">;

import { useKeyPress } from "./useKeyPress";

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values
 */
const keyTargets = ["Meta", "Eisu"];

const styleMap: Record<Mode, Pick<MuiLinkProps, "variant" | "color" | "sx">> = {
  none: {
    variant: "body2",
    color: "text.primary",
    sx: { textDecoration: "none" },
  },
  always: {
    sx: { cursor: "pointer" },
  },
  hover: {
    sx: { cursor: "pointer" },
  },
};

export type RefType = React.RefObject<HTMLAnchorElement> | ((instance: HTMLAnchorElement | null) => void) | null | undefined;

/**
 * This Link component is a component that is intended for use with React.startTransition.
 * If `onClick` is specified, `preventDefault` is executed and the anchor tag will not cause the original page transition.
 *
 * @see https://react.dev/reference/react/startTransition
 */
const Link = React.forwardRef((props: LinkProps, ref: RefType) => {
  const { onClick, disablePreventDefault, ...rest } = props;

  const pressed = useKeyPress(keyTargets);
  const handleClick: Exclude<MuiLinkProps["onClick"], undefined> = React.useCallback(
    event => {
      /**
       * When a key press is performed, another window is opened by the original function of the anchor tag,
       * so there is no need to trigger an event in the original window.
       */
      if (pressed) {
        return;
      }
      if (!disablePreventDefault) {
        event.preventDefault();
      }
      onClick?.(event);
    },
    [pressed, disablePreventDefault, onClick],
  );
  const underline = props.underline ?? "always";
  const styleProps = styleMap[underline];
  const muiLinkProps: MuiLinkProps = {
    ...rest,
    ...styleProps,
    underline,
    onClick: onClick && handleClick,
    /**
     * @see https://developer.chrome.com/docs/lighthouse/best-practices/external-anchors-use-rel-noopener/
     */
    rel: props.target === "_blank" ? "noreferrer" : undefined,
  };
  return <MuiLink {...muiLinkProps} ref={ref} />;
});

Link.displayName = "Link";

export default Link;
