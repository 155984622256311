import { RANK_MAP } from "@hireroo/app-definition/interview";
import { ENTITY_REPORT_TOP, ShowingTarget } from "@hireroo/app-helper/entity";
import { App } from "@hireroo/app-store/essential/candidate";
import { TestReport } from "@hireroo/app-store/widget/c/TestReport";
import { formatScore } from "@hireroo/formatter/score";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useCurrentLanguage, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { useHelpCenterNavigate } from "@hireroo/router/hooks";
import * as React from "react";

import TestRankVisualizerFetchContainer from "../../shared/TestRankVisualizer/FetchContainer";
import ChallengeTestReportFetchContainer from "../ChallengeTestReport/FetchContainer";
import ProjectTestReportFetchContainer from "../ProjectTestReport/FetchContainer";
import QuizTestReportFetchContainer from "../QuizTestReport/FetchContainer";
import SystemDesignTestReportFetchContainer from "../SystemDesignTestReport/FetchContainer";
import { generateSmoothTargetId } from "./privateHelper";
import { useGenerateEntityScoreSelector } from "./useGenerateEntityScoreSelector";
import { useGenerateFooterProps } from "./useGenerateFooterProps";

export type GenerateTestReportPropsArgs = {
  sharedLink: Graphql.InterviewDetailSharedLinkFragment;
};

type DisplayScoreArea = Exclude<Widget.ScreeningTestReportProps["summary"]["scorePieChart"], undefined>["displayScoreArea"];

type Report = Widget.ScreeningTestReportProps["Reports"][0];

export const useGenerateProps = (args: GenerateTestReportPropsArgs): Widget.ScreeningTestReportProps => {
  const appStatus = App.useStatus();
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const test = TestReport.useTest();
  const lang = useCurrentLanguage();
  const helpCenterNavigate = useHelpCenterNavigate(lang);
  const footer = useGenerateFooterProps();
  const entityScoreSelector = useGenerateEntityScoreSelector(args);
  const selectedEntityId = TestReport.useSelectedEntityId();

  const showingTarget = React.useMemo((): ShowingTarget[] => {
    return [
      args.sharedLink.showRank && ("RANK" as const),
      args.sharedLink.showPlayback && ("PLAYBACK" as const),
      args.sharedLink.showScore && ("SCORE" as const),
      args.sharedLink.showRelativeEvaluation && ("STATISTICS" as const),
      args.sharedLink.showAnswer && ("ANSWER" as const),
    ].flatMap(v => (v ? [v] : []));
  }, [
    args.sharedLink.showAnswer,
    args.sharedLink.showPlayback,
    args.sharedLink.showRank,
    args.sharedLink.showRelativeEvaluation,
    args.sharedLink.showScore,
  ]);

  const Reports = React.useMemo(() => {
    const companyId = test.company?.companyId;
    return test.entities.map((entity): Report => {
      const smoothScrollTargetId = generateSmoothTargetId(entity);
      if (entity.__typename === "ChallengeEntity") {
        return {
          id: TestReport.generateUniqueEntityId(entity),
          smoothScrollTargetId,
          Content: companyId && (
            <ChallengeTestReportFetchContainer
              showingTargets={showingTarget}
              canShowCheatDetectionSection={args.sharedLink.showCheatDetect}
              canShowPasteAndTabStatistics={args.sharedLink.showCheatDetect}
              companyId={companyId}
              challengeId={entity.challengeEntityId}
            />
          ),
        };
      } else if (entity.__typename === "QuizEntity") {
        return {
          id: TestReport.generateUniqueEntityId(entity),
          smoothScrollTargetId,
          Content: companyId && (
            <QuizTestReportFetchContainer
              showingTargets={showingTarget}
              key={entity.quizEntityId}
              quizId={entity.quizEntityId}
              companyId={companyId}
            />
          ),
        };
      } else if (entity.__typename === "ProjectEntity") {
        return {
          id: TestReport.generateUniqueEntityId(entity),
          smoothScrollTargetId,
          Content: companyId && (
            <ProjectTestReportFetchContainer
              showingTargets={showingTarget}
              projectId={entity.projectEntityId}
              companyId={companyId}
              testId={test.id}
              kindOfReview="EMPLOYEE_REVIEW_INTERVIEW"
              appInitialized={appStatus === "INITIALIZED"}
            />
          ),
        };
      } else if (entity.__typename === "SystemDesignEntity") {
        return {
          id: TestReport.generateUniqueEntityId(entity),
          smoothScrollTargetId,
          Content: companyId && (
            <SystemDesignTestReportFetchContainer
              showingTargets={showingTarget}
              systemDesignId={entity.systemDesignEntityId}
              companyId={companyId}
            />
          ),
        };
      } else {
        throw new Error("unknown entity type");
      }
    });
  }, [test.company?.companyId, test.entities, test.id, showingTarget, args.sharedLink.showCheatDetect, appStatus]);

  const displayScoreAreaProps = React.useMemo((): DisplayScoreArea => {
    if (test.status !== "FINALIZED" && test.status !== "EVALUATED") {
      return { kind: "SCORING" };
    } else if (test.rankEvaluation === "UNKNOWN" || !args.sharedLink.showRank) {
      return {
        kind: "SCORE_ONLY",
      };
    } else if (!test.isReliableRank) {
      return {
        kind: "WITH_RANK",
        rankLabel: {
          rank: "UNKNOWN",
          reason: t("提出時点のランクを表示するためのデータ数が足りないため表示できません。"),
        },
      };
    } else {
      return {
        kind: "WITH_RANK",
        rankLabel: {
          rank: RANK_MAP[test.rankEvaluation],
          reason: t2("RelativeScoreLabel", { score: formatScore(test.relativeScore) }),
        },
      };
    }
  }, [args.sharedLink, test.rankEvaluation, test.relativeScore, test.status, test.isReliableRank, t, t2]);

  return {
    summary: {
      scorePieChart: args.sharedLink.showScore
        ? {
            score: formatScore(test.customScore),
            displayScoreArea: displayScoreAreaProps,
          }
        : undefined,
      status: test.status !== "FINALIZED" && test.status !== "EVALUATED" ? "NOT_READY" : "READY",
      entityScoreSelector: entityScoreSelector,
      enableStaticContent: args.sharedLink.showRelativeEvaluation,
      StatisticsContent: test.company?.companyId && (
        <TestRankVisualizerFetchContainer
          companyId={test.company.companyId}
          spotId={test.id}
          finalizedAtSeconds={test.finalizedAtSeconds}
          pause={appStatus !== "INITIALIZED"}
          enableTagField
          enableRank={test.isReliableRank}
        />
      ),
      helpLink: {
        onClick: () => {
          helpCenterNavigate("CHECK_REPORT", { _blank: true });
        },
      },
      showSuspiciousBehaviorText: false,
    },
    footer: footer,
    Reports: Reports,
    selectedEntityId: selectedEntityId || "",
    targetElementId: ENTITY_REPORT_TOP,
  };
};
