import { SupportLanguageValue } from "@hireroo/i18n";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import SelectControl, { SelectControlProps, SelectMenuItemProps } from "../../primitive/InputControl/SelectControl/SelectControl";

export type LanguageSelectorProps = {
  value?: SupportLanguageValue;
  label?: string;
  disabled?: boolean;
  helperText?: string;
  color?: SelectControlProps["color"];
  className?: string;
};

const LanguageSelector: React.FC<LanguageSelectorProps & { name: string }> = props => {
  const { name } = props;
  const method = useFormContext();
  const menuItems: SelectMenuItemProps[] = [
    {
      value: "ja",
      label: "日本語",
    },
    {
      value: "en",
      label: "English",
    },
  ];
  const selectControlProps: SelectControlProps = {
    ...props,
    formControlId: "language-selector-label",
    control: method.control,
    menuItems: menuItems,
  };
  return <SelectControl {...selectControlProps} name={name} />;
};

LanguageSelector.displayName = "LanguageSelector";

export default LanguageSelector;
