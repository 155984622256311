import type { Identifier, XYCoord } from "dnd-core";
import * as React from "react";
import { useDrag, useDrop } from "react-dnd";

import SelectedQuestionTableRow, { SelectedQuestionTableRowProps } from "./SelectedQuestionTableRow";

type DragItem = {
  index: number;
  id: number;
};

export type DragProps = {
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  onEndDrop?: () => void;
};

export type DraggableSelectedQuestionTableRowProps = Omit<SelectedQuestionTableRowProps, "ref" | "dragStatus">;

const DROP_TYPE = "QuizQuestion";

const DraggableSelectedQuestionTableRow: React.FC<DraggableSelectedQuestionTableRowProps & DragProps> = props => {
  const ref = React.useRef<HTMLTableRowElement>(null);

  const [, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: DROP_TYPE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = props.index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      props.moveItem(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    drop() {
      props.onEndDrop?.();
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: DROP_TYPE,
    item: () => {
      return { index: props.index };
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return <SelectedQuestionTableRow {...props} ref={ref} dragStatus={isDragging ? "DRAGGING" : "UNDRAGGED"} />;
};

DraggableSelectedQuestionTableRow.displayName = "DraggableSelectedQuestionTableRow";

export default DraggableSelectedQuestionTableRow;
