import * as Utility from "../calculator";
import { useSplitContext } from "../Context";
import { useResizeMethod } from "../hooks/useResizeMethod";
import type * as Types from "../types";
import { convertPairPropsToSizeInputs } from "./utility";

export type InitializeArgs = {
  direction: Types.SplitDirection;
  height: number;
  width: number;
  items: Types.PairProps[];
  snapOffset?: number;
};

export type UseSplitControllerProps = {
  splitId: Types.SplitId;
  defaultPanePixelSize: number;
};

export const useSplitController = (props: UseSplitControllerProps) => {
  const methods = useSplitContext();
  const resize = useResizeMethod();
  return {
    initialize: (args: InitializeArgs) => {
      const { direction, snapOffset } = args;
      methods.store.setSplitState(props.splitId, { direction, snapOffset });
      const inputs = convertPairPropsToSizeInputs({
        items: args.items,
        defaultPanePixelSize: props.defaultPanePixelSize,
      });
      const totalPercent = inputs.reduce<number>((total, input) => {
        if (input.type === "CONTENT" && input.size.unit === "%") {
          return total + input.size.value;
        }
        return total;
      }, 0);
      if (totalPercent !== 100) {
        const message = `The total value of Percent must be 100 for Content. CurrentValue: ${totalPercent}`;
        /**
         * FIXME For some reason, error messages are sometimes not displayed, so I use console.error to output them.
         */
        console.error(message);
        throw new Error(message);
      }
      args.items.forEach(child => {
        const contentId: Types.ContentId = `${props.splitId}/Content/${child.id}`;
        methods.store.setContentState(contentId, {
          minSize: child.minSize,
          maxSize: child.maxSize,
          defaultSize: child.size,
          minimizedSize: child.minimizedSize,
          expandedSize: child.expandedSize,
        });
      });

      methods.store.setSplitState(props.splitId, {
        width: args.width,
        height: args.height,
      });
      const parentSize = direction === "HORIZONTAL" ? args.height : args.width;
      const results = Utility.calculateInitialSizes({
        parentSize: parentSize,
        inputs: inputs,
      });

      results.outputs.forEach((output, index) => {
        if (output.type === "CONTENT") {
          const contentId: Types.ContentId = `${props.splitId}/Content/${output.id}`;
          const prevPane = index - 1 > 0 ? results.outputs[index - 1] : undefined;
          const nextPane = index + 1 <= results.outputs.length ? results.outputs[index + 1] : undefined;
          methods.store.setContentState(contentId, {
            splitId: props.splitId,
            direction: direction,
            size: output.size,
            mode: output.mode,
            prevPaneId: prevPane ? `${props.splitId}/Pane/${prevPane.id}` : undefined,
            nextPaneId: nextPane ? `${props.splitId}/Pane/${nextPane.id}` : undefined,
          });
          methods.store.setContentSize(contentId, output.size);
        } else if (output.type === "PANE") {
          /**
           * 必ず存在する
           */
          const prevContent = results.outputs[index - 1];
          const nextContent = results.outputs[index + 1];
          const paneId: Types.PaneId = `${props.splitId}/Pane/${output.id}`;
          methods.store.setPaneState(paneId, {
            splitId: props.splitId,
            splitDirection: direction,
            size: output.size,
            prevContentId: `${props.splitId}/Content/${prevContent.id}`,
            nextContentId: `${props.splitId}/Content/${nextContent.id}`,
          });
        }
      });
    },
    resize: resize,
  };
};
