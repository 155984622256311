import { useDraggable } from "@dnd-kit/core";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Paper, { PaperProps } from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import * as React from "react";

import DragIndicator, { DragIndicatorProps } from "../../../primitive/DragIndicator/DragIndicator";
import { useVideoChatDraggablePromptContext } from "./Context";

export { useVideoChatDraggablePromptContext, VideoChatDraggablePromptProvider, type VideoChatDraggablePromptProviderProps } from "./Context";

const StyledWrapper = styled(Paper)(({ theme }) => ({
  position: "fixed",
  borderRadius: 16,
  width: 196,
  zIndex: theme.zIndex.appBar + 1,
}));

export type VideoChatDraggablePromptProps = {};

/**
 * TODO @himenon impl Zoom In/ Zoom Out
 */
const VideoChatDraggablePrompt: React.FC<React.PropsWithChildren<VideoChatDraggablePromptProps>> = props => {
  const { listeners, setNodeRef, transform, isDragging } = useDraggable({ id: "draggable-prompt" });
  const { coordinatesRef } = useVideoChatDraggablePromptContext();

  const dragIndicator: DragIndicatorProps = {
    color: "outline-only",
    ...listeners,
  };

  const baseBox: PaperProps = {
    elevation: 24,
    sx: {
      top: coordinatesRef.current.y,
      left: coordinatesRef.current.x,
      cursor: isDragging ? "grabbing" : "inherit",
      transform: `translate3d(${transform?.x ?? 0}px, ${transform?.y ?? 0}px, 0)`,
    },
  };

  return (
    <StyledWrapper {...baseBox} ref={setNodeRef}>
      <DragIndicator {...dragIndicator} />
      <Divider />
      <Box>{props.children}</Box>
    </StyledWrapper>
  );
};

VideoChatDraggablePrompt.displayName = "VideoChatDraggablePrompt";

export default VideoChatDraggablePrompt;
