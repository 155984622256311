import { useTranslation } from "@hireroo/i18n";
import Alert, { AlertProps } from "@mui/material/Alert";
import Box, { BoxProps } from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";

const Wrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: 12,
  /**
   * TODO Check for design
   */
  backgroundColor: theme.palette["Gray/Shades"].p8,
}));

const SectionLabel = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: "bold",
}));

const StyledTableHeader = styled(TableHead)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.Other.Divider}`,
}));

const CorrectionTableRow = styled(TableRow)(() => ({
  border: "none",
}));

const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 12,
  padding: 0,
  color: theme.palette.text.secondary,
  borderBottom: `1px solid ${theme.palette.Other.Divider}`,
}));

const BodyTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 14,
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  borderBottom: `1px solid ${theme.palette.Other.Divider}`,
}));

const NonBorderTableCell = styled(BodyTableCell)(() => ({
  border: "none",
}));

type Item = {
  title: string;
  subTitle?: string;
  count: number;
  tax: string;
  price: {
    value: string;
  };
};

type CorrectionItem = {
  title: string;
  value: string;
  color?: string;
};

export type ApplicationConfirmationSectionProps = {
  title: string;
  items: Item[];
  coupons: Omit<CorrectionItem, "color">[];
  /**
   * 消費税
   *
   * @example | 消費税(¥1,560,000の10%) | ¥156,100 |
   * */
  taxPrice: Omit<CorrectionItem, "color">;
  /** 小計  */
  subtotalPrice: Pick<CorrectionItem, "value">;
  /** 合計(税抜) */
  totalPriceWithoutTax: {
    /**
     * @example ¥1,716,000
     */
    value: string;
  };
  /** 請求額(税込) */
  billingAmount: {
    value: string;
  };
  importantMessage?: Pick<AlertProps, "children">;
} & Pick<BoxProps, "mb">;

const ApplicationConfirmationSection: React.FC<ApplicationConfirmationSectionProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const importantMessage: AlertProps | undefined = props.importantMessage
    ? {
        ...props.importantMessage,
        variant: "outlined",
        severity: "error",
      }
    : undefined;

  if (props.items.length === 0) {
    return (
      <Box mb={props.mb} py={2}>
        <Typography variant="body2">{t("購入可能な商品が選択されていません。")}</Typography>
      </Box>
    );
  }
  const correctionItems: CorrectionItem[] = [
    {
      title: t("小計"),
      value: props.subtotalPrice.value,
    },
    ...props.coupons.map(item => {
      return {
        ...item,
        color: theme.palette.success.main,
      };
    }),
    {
      title: [t("合計"), `(${t("税抜き")})`].join(""),
      value: props.totalPriceWithoutTax.value,
    },
    props.taxPrice,
  ];
  return (
    <Wrapper mb={props.mb}>
      <SectionLabel mb={2}>{props.title}</SectionLabel>
      <TableContainer>
        <Table sx={{ minWidth: 650 }}>
          <StyledTableHeader>
            <TableRow>
              <HeaderTableCell width="50%">{t("説明")}</HeaderTableCell>
              <HeaderTableCell width="15%" align="left">
                {t("数量")}
              </HeaderTableCell>
              <HeaderTableCell width="16%" align="left">
                {t("税金")}
              </HeaderTableCell>
              <HeaderTableCell width="19%" align="right">
                {t("金額")}
              </HeaderTableCell>
            </TableRow>
          </StyledTableHeader>
          <TableBody>
            {props.items.map((item, index) => (
              <TableRow
                key={`${item.title}-${index}`}
                sx={{ borderBottom: index === props.items.length - 1 ? `1px solid ${theme.palette.Other.Divider}` : undefined }}
              >
                <NonBorderTableCell component="td" scope="row">
                  {item.title}
                  {item.subTitle && <br />}
                  {item.subTitle}
                </NonBorderTableCell>
                <NonBorderTableCell component="td" scope="row" align="left">
                  {item.count}
                </NonBorderTableCell>
                <NonBorderTableCell component="td" scope="row" align="left">
                  {item.tax}
                </NonBorderTableCell>
                <NonBorderTableCell align="right">
                  <Typography variant="body2">{item.price.value}</Typography>
                </NonBorderTableCell>
              </TableRow>
            ))}
            {correctionItems.map((item, index) => (
              <CorrectionTableRow key={`correction-item-${index}`}>
                <NonBorderTableCell />
                <BodyTableCell align="left" colSpan={2}>
                  <Typography variant="body2" color={item.color}>
                    {item.title}
                  </Typography>
                </BodyTableCell>
                <BodyTableCell align="right">
                  <Typography variant="body2" color={item.color}>
                    {item.value}
                  </Typography>
                </BodyTableCell>
              </CorrectionTableRow>
            ))}
            <CorrectionTableRow>
              <NonBorderTableCell />
              <NonBorderTableCell align="left" colSpan={2}>
                <Typography fontWeight="bold" fontSize={16}>
                  {t("請求金額")}
                </Typography>
              </NonBorderTableCell>
              <NonBorderTableCell align="right">{props.billingAmount.value}</NonBorderTableCell>
            </CorrectionTableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {importantMessage && (
        <Box mt={2}>
          <Alert {...importantMessage} />
        </Box>
      )}
    </Wrapper>
  );
};

ApplicationConfirmationSection.displayName = "ApplicationConfirmationSection";

export default ApplicationConfirmationSection;
