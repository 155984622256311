import { useTranslation } from "@hireroo/i18n";
import { ArrowRightOutlined, ContentPasteOutlined } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "../../../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";

const Wrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  // TODO: ask designer
  backgroundColor: theme.palette["Background/Paper"].p4,
  border: "1px solid",
  borderColor: theme.palette["Other"]["Divider"],
  borderRadius: "8px",
}));

const IconBox = styled(Box)(({ theme }) => ({
  width: "40px",
  height: "40px",
  borderRadius: "48px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette["Secondary/Shades"].p12,
}));

const IconMap = {
  CHECK: <CheckIcon color="success" />,
  REPORT: <ContentPasteOutlined color="secondary" />,
} as const satisfies Record<string, React.ReactElement>;

type Content =
  | {
      type: "NUMBER";
      value: number;
      unit: string;
    }
  | {
      type: "NOT_ENOUGH";
      additionalMessage?: string;
    };

export type StatsCardItemProps = {
  icon: keyof typeof IconMap;
  title: string;
  content: Content;
  detailButton?: Pick<IconButtonWithTooltipProps, "onClick">;
};

const StatsCardItem: React.FC<StatsCardItemProps> = props => {
  const { t } = useTranslation();
  const detailButton: IconButtonWithTooltipProps | undefined = props.detailButton
    ? {
        ...props.detailButton,
        title: t("詳細"),
        children: <ArrowRightOutlined />,
      }
    : undefined;
  return (
    <Wrapper direction="row" spacing={2}>
      <IconBox>{IconMap[props.icon]}</IconBox>
      <Stack spacing={0.5}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography color="textSecondary" fontSize={14}>
            {props.title}
          </Typography>
          {detailButton && <IconButtonWithTooltip {...detailButton} />}
        </Stack>

        <Stack direction="row" alignItems="center">
          {props.content.type === "NUMBER" && (
            <Stack direction="row" alignItems="end">
              <Typography fontSize={34} fontWeight={700} lineHeight="110%">
                {props.content.value}
              </Typography>
              <Typography fontSize={16} lineHeight="110%">
                {props.content.unit}
              </Typography>
            </Stack>
          )}
          {props.content.type === "NOT_ENOUGH" && <Typography color="textSecondary">{t("データが不十分です")}</Typography>}
        </Stack>
      </Stack>
    </Wrapper>
  );
};

StatsCardItem.displayName = "StatsCardItem";

export default StatsCardItem;
