import { languageMapForDisplay } from "@hireroo/i18n";
import { useTranslation } from "@hireroo/i18n";
import { Adjust } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useFieldArray } from "react-hook-form";

import EditLanguageTab, { EditLanguageTabProps } from "../../../../usecase/EditLanguageTab/EditLanguageTab";
import { useAlgorithmTestCasesFormContext } from "../../Context";
import TestCaseInputTabContext from "./parts/TestCaseInputTabContext/TestCaseInputTabContext";

export type AlgorithmCorrectnessTestCasesFormProps = {
  languageTab: Pick<EditLanguageTabProps, "menu" | "onTabsChange">;
};

const AlgorithmCorrectnessTestCasesForm: React.FC<AlgorithmCorrectnessTestCasesFormProps> = props => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { method } = useAlgorithmTestCasesFormContext();

  const correctnessTestCases = useFieldArray({
    control: method.control,
    name: "correctnessTestCases.data",
  });
  const selectedLanguagesFields = useFieldArray({
    control: method.control,
    name: "selectedLanguages",
  });
  const selectedLanguages = method.getValues("selectedLanguages");

  const editLanguageTab: EditLanguageTabProps = {
    menu: props.languageTab.menu,
    onTabsChange: props.languageTab.onTabsChange,
    items: selectedLanguages.map((lang, langIndex) => {
      return {
        id: lang.value,
        name: languageMapForDisplay[lang.value],
        Content: <TestCaseInputTabContext key={lang.value} lang={lang.value} />,
        tab: {
          closeButton: {
            onClick: () => {
              correctnessTestCases.fields.forEach((field, i) => {
                method.setValue(`correctnessTestCases.data.${i}.title_${lang.value}`, "");
                method.setValue(`correctnessTestCases.data.${i}.description_${lang.value}`, "");
              });
              selectedLanguagesFields.remove(langIndex);
            },
          },
        },
      };
    }),
  };
  return (
    <Box>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Adjust fontSize="small" sx={{ color: theme.palette.common.white }} />
        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
          {t("テストケース（正解率）を入力する")}
        </Typography>
      </Stack>

      <Box mt={2}>
        <Typography variant="body2">{t("テストケース（正解率）は、提出されたコードのケースの網羅率を計測するのに利用されます。")}</Typography>
      </Box>

      <Box mt={3}>
        <EditLanguageTab {...editLanguageTab} />
      </Box>
    </Box>
  );
};

AlgorithmCorrectnessTestCasesForm.displayName = "AlgorithmCorrectnessTestCasesForm";

export default AlgorithmCorrectnessTestCasesForm;
