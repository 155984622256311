import { TechnicalCommentForm } from "@hireroo/validator";
import * as React from "react";

import { safeJsonParse } from "../parser";

type TechnicalCommentStepSchema = TechnicalCommentForm.TechnicalCommentStepSchema;
type TechnicalCommentStepForPromptSchema = TechnicalCommentForm.TechnicalCommentStepForPromptSchema;
type TechnicalCommentEvaluationSchema = TechnicalCommentForm.RemoteEvaluationsSchema;
type TechnicalCommentFeedbackSchema = TechnicalCommentForm.FeedbackSchema;

const InitialStep: TechnicalCommentStepSchema = "Evaluations";
const InitialStepForPrompt: TechnicalCommentStepForPromptSchema = 0;
const InitialEvaluation: TechnicalCommentEvaluationSchema = {};
const InitialFeedback: TechnicalCommentFeedbackSchema = {
  comment: "",
  isRecommended: true,
};

const updateTechnicalCommentToLocalStorage = (
  uniqueKey: string,
  step: TechnicalCommentStepSchema,
  stepForPrompt: TechnicalCommentStepForPromptSchema,
  evaluations: TechnicalCommentEvaluationSchema,
  feedback: TechnicalCommentFeedbackSchema,
) => {
  const technicalComment: TechnicalCommentForm.RemoteTechnicalCommentFormSchema = {
    stepForPrompt: stepForPrompt,
    step: step,
    schemas: {
      evaluations,
      feedback,
    },
  };
  localStorage.setItem(uniqueKey, JSON.stringify(technicalComment));
};

export type RemoteTechnicalCommentRepositoryPayload = {
  technicalComment: TechnicalCommentForm.RemoteTechnicalCommentFormSchema;
  updateStep: (step: TechnicalCommentStepSchema) => void;
  updateStepForPrompt: (step: TechnicalCommentStepForPromptSchema) => void;
  addEvaluation: (metricId: number) => void;
  addEvaluations: (metricIds: number[]) => void;
  removeEvaluation: (metricId: number) => void;
  updateEvaluation: (metricId: number, evaluation: Partial<TechnicalCommentForm.EvaluationSchema>) => void;
  updateFeedback: (feedback: Partial<TechnicalCommentFeedbackSchema>) => void;
  forceRestore: () => void;
  removeFromStorage: (uniqueKey: string) => void;
};

/** The number of steps for post remote review comments and review comments in the report are different
  so we have to separate.
  Therefore, only data excluding steps will be restored. */
export const useRemoteTechnicalCommentRepository = (uniqueKey: string): RemoteTechnicalCommentRepositoryPayload => {
  const technicalCommentFormForLocalStorageSchema = TechnicalCommentForm.useRemoteTechnicalCommentFormSchemaForLocalStorage();
  const [restored, setRestored] = React.useState(false);

  const [step, setStep] = React.useState<TechnicalCommentStepSchema>(InitialStep);

  const [stepForPrompt, setStepForPrompt] = React.useState<TechnicalCommentStepForPromptSchema>(InitialStepForPrompt);
  const [evaluations, setEvaluations] = React.useState<TechnicalCommentEvaluationSchema>(InitialEvaluation);
  const [feedback, setFeedback] = React.useState<TechnicalCommentFeedbackSchema>(InitialFeedback);

  const restoreFromLocalStorage = React.useCallback(() => {
    const data = localStorage.getItem(uniqueKey);
    if (data) {
      const parseResult = technicalCommentFormForLocalStorageSchema.safeParse(safeJsonParse(data));
      if (parseResult.success) {
        if (parseResult.data.step) setStep(parseResult.data.step);
        if (parseResult.data.stepForPrompt) setStepForPrompt(parseResult.data.stepForPrompt);
        if (parseResult.data.schemas?.evaluations) {
          setEvaluations(parseResult.data.schemas.evaluations);
        }
        if (parseResult.data.schemas?.feedback) {
          setFeedback(parseResult.data.schemas.feedback);
        }
      }
    }
  }, [technicalCommentFormForLocalStorageSchema, uniqueKey]);

  React.useEffect(() => {
    if (restored) return;
    restoreFromLocalStorage();
    setRestored(true);
  }, [restoreFromLocalStorage, restored]);

  React.useEffect(() => {
    if (!restored) return;
    updateTechnicalCommentToLocalStorage(uniqueKey, step, stepForPrompt, evaluations, feedback);
  }, [evaluations, feedback, restored, step, stepForPrompt, uniqueKey]);

  return {
    technicalComment: {
      step: step,
      stepForPrompt,
      schemas: {
        evaluations: evaluations,
        feedback: feedback,
      },
    },
    updateStep: React.useCallback(step => {
      setStep(step);
    }, []),
    updateStepForPrompt: React.useCallback(step => {
      setStepForPrompt(step);
    }, []),
    addEvaluation: React.useCallback(
      (metricId: number) => {
        const newEvaluations = { ...evaluations };
        newEvaluations[metricId] = {
          comment: "",
          metricId: metricId,
          numStars: 0,
        };
        setEvaluations(newEvaluations);
      },
      [evaluations],
    ),
    addEvaluations: React.useCallback(
      (metricIds: number[]) => {
        const newEvaluations = { ...evaluations };
        metricIds.forEach(metricId => {
          newEvaluations[metricId] = {
            comment: "",
            metricId: metricId,
            numStars: 0,
          };
        });
        setEvaluations(newEvaluations);
      },
      [evaluations],
    ),
    removeEvaluation: React.useCallback(
      (metricId: number) => {
        const newEvaluations = { ...evaluations };
        delete newEvaluations[metricId];
        setEvaluations(newEvaluations);
      },
      [evaluations],
    ),
    updateEvaluation: React.useCallback(
      (metricId, evaluation) => {
        const newEvaluations = { ...evaluations };
        const updateTarget = newEvaluations[metricId];

        newEvaluations[metricId] = {
          ...updateTarget,
          ...evaluation,
        };

        setEvaluations(newEvaluations);
      },
      [evaluations],
    ),
    updateFeedback: React.useCallback(feedback => {
      setFeedback(draft => {
        draft = {
          ...draft,
          ...feedback,
        };
        return draft;
      });
    }, []),
    forceRestore: React.useCallback(() => {
      restoreFromLocalStorage();
    }, [restoreFromLocalStorage]),
    removeFromStorage: React.useCallback(uniqueKey => {
      localStorage.removeItem(uniqueKey);
    }, []),
  };
};
