import * as Graphql from "@hireroo/graphql/client/urql";

export type SpotTags = Graphql.SpotTagsByCompanyIdResponseForScreeningDetailFragment;
export type Filters = Graphql.SpotFiltersByScreeningInput;
export type Spot = Graphql.SpotForScreeningDetailFragment;
export type SpotsByScreeningIdResponse = Graphql.SpotsByScreeningIdResponseForScreeningDetailFragment;

export type ListFetchingStatus = "STOP" | "FETCHING" | "FETCHED";
export type SpotId = string;
export type DialogStatus = "DELETES_OPEN" | "CLOSE";

export type State = {
  spotTags: SpotTags | null;
  searchTags: string[];
  listParams: ListParams;
  count: number;
  listFetchingStatus: ListFetchingStatus;
  initialized: boolean;
  res: SpotsByScreeningIdResponse | null;
  spotMap: Map<SpotId, Spot>;
  selectedSpotIds: SpotId[];
  tagRefresh: (() => void) | null;
  dialogStatus: DialogStatus;
  /**
   * Random string to force refresh the list
   */
  refreshKey: string;
};

export type ListParams = {
  isDescending: boolean;
  page: number;
  size: number;
  sortMethod: Graphql.SpotsByCompanyIdSortMethod;
  filters: Graphql.SpotFiltersByScreeningInput;
};

export const QueryKeys = {
  PAGE: "page",
  SIZE: "size",
  TITLE: "title",
  STATUS: "status",
  RESULT: "result",
  TAG: "tag",
  RANK_EVALUATION: "rank",
  MIN_SCORE: "minScore",
  MAX_SCORE: "maxScore",
  SORT: "sorting",
  VARIABLES: "variables",
  SUSPICIOUS_DEGREES: "suspiciousDegrees",
} as const;

export type QueryType = (typeof QueryKeys)[keyof typeof QueryKeys];
