import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateRemoteTemplateResourceEditorPropsArgs, useGenerateProps } from "./useGenerateProps";

export type RemoteTemplateResourceEditorContainerProps = GenerateRemoteTemplateResourceEditorPropsArgs;

const RemoteTemplateResourceEditorContainer: React.FC<RemoteTemplateResourceEditorContainerProps> = props => {
  const remoteTemplateResourceEditorProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.RemoteTemplateResourceEditor {...remoteTemplateResourceEditorProps} />
    </ErrorBoundary>
  );
};

RemoteTemplateResourceEditorContainer.displayName = "RemoteTemplateResourceEditorContainer";

export default withErrorBoundary(RemoteTemplateResourceEditorContainer, {});
