import { App } from "@hireroo/app-store/essential/candidate";
import { Payment, Role } from "@hireroo/app-store/essential/employee";
import { Credential } from "@hireroo/app-store/essential/shared";
import { getCurrentUser, getExpireDate, getIdToken } from "@hireroo/firebase";
import type * as Graphql from "@hireroo/graphql/client/urql";
import { LastVisitedRoute, safeRedirect } from "@hireroo/router/api";

import { initializeGraphqlClientSdkAndRestApiClient } from "./helper";

export type InitializeEmployeeArgs = {
  res: Graphql.InitializeParamsForInvitationEmployeeFragment;
};

const initialize = async (args: InitializeEmployeeArgs) => {
  App.setStatus("INITIALIZING");

  Credential.setCurrentUserType(args.res.user?.userType ?? "UNKNOWN");

  Role.initialize();
  Payment.initialize();
  const currentUser = await getCurrentUser();
  const authToken = await currentUser?.getIdToken(true);
  if (!currentUser || !authToken) {
    LastVisitedRoute.save();
    await safeRedirect("/signup");
    return;
  }
  const idToken = await currentUser.getIdTokenResult(true);
  Credential.setProviderId(idToken.signInProvider);
  Credential.setUid(currentUser.uid);
  Credential.setMailAddress(currentUser.email);
  initializeGraphqlClientSdkAndRestApiClient(authToken);
  const expireDate = await getExpireDate();
  Credential.setAuthToken({
    authToken: authToken || null,
    expireDate: expireDate || null,
    getRefreshAuthToken: async () => {
      const authToken = await getIdToken(true);
      const expireDate = await getExpireDate();
      if (authToken) {
        initializeGraphqlClientSdkAndRestApiClient(authToken);
      }
      return {
        authToken,
        expireDate,
      };
    },
  });

  App.setStatus("INITIALIZED");
};

export const InviteEmployee = {
  initialize,
};
