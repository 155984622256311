import * as React from "react";

import type { FieldItem, SingleFieldItem } from "./parts/types";

export interface CsvDownloadState {
  allFields: FieldItem[];
  selectableFields: FieldItem[];
  downloadTargetFields: FieldItem[];
  fieldItems: SingleFieldItem[];
  willAddList: string[];
  willRemoveList: string[];
}

export type ContextValue = {
  state: CsvDownloadState;

  updateAddList: (list: string[]) => void;
  updateRemoveList: (list: string[]) => void;

  remove: () => void;
  add: () => void;
};

export const CsvDownloadContext = React.createContext<ContextValue>({
  state: {
    allFields: [],
    selectableFields: [],
    downloadTargetFields: [],
    fieldItems: [],
    willAddList: [],
    willRemoveList: [],
  },
  updateAddList: () => undefined,
  updateRemoveList: () => undefined,
  remove: () => undefined,
  add: () => undefined,
});

export const useCsvDownloadContext = () => React.useContext(CsvDownloadContext);

export type CsvDownloadProviderProps = {
  fields: FieldItem[];
  defaultSelectedFields: FieldItem[];
  onChange?: (fields: SingleFieldItem[]) => void;
};

const convertSingleFieldItems = (fieldItems: FieldItem[]): SingleFieldItem[] => {
  const singleFieldItems: SingleFieldItem[] = [];
  fieldItems.forEach(fieldItem => {
    if (fieldItem.group) {
      Object.entries(fieldItem.group).forEach(([name, value]) => {
        singleFieldItems.push({
          name: name,
          value: value,
        });
      });
    } else {
      singleFieldItems.push(fieldItem);
    }
  });
  return singleFieldItems;
};

export const CsvDownloadProvider: React.FC<React.PropsWithChildren<CsvDownloadProviderProps>> = props => {
  const { onChange } = props;
  const [state, updateState] = React.useState<CsvDownloadState>({
    allFields: props.fields,
    selectableFields: props.fields.filter(field => {
      return !props.defaultSelectedFields.find(item => item.name === field.name);
    }),
    downloadTargetFields: props.defaultSelectedFields,
    fieldItems: convertSingleFieldItems(props.defaultSelectedFields),
    willAddList: [],
    willRemoveList: [],
  });
  // Initialize if props are changed
  React.useEffect(() => {
    updateState(prev => {
      // Simple re-rendering prevention
      const changed = JSON.stringify(props.fields) !== JSON.stringify(prev.allFields);
      if (!changed) {
        return prev;
      }
      return {
        allFields: props.fields,
        selectableFields: props.fields.filter(field => {
          return !props.defaultSelectedFields.find(item => item.name === field.name);
        }),
        downloadTargetFields: props.defaultSelectedFields,
        fieldItems: convertSingleFieldItems(props.defaultSelectedFields),
        willAddList: [],
        willRemoveList: [],
      };
    });
  }, [props.defaultSelectedFields, props.fields]);

  React.useEffect(() => {
    onChange?.(state.fieldItems);
  }, [state.fieldItems, onChange]);

  const defaultContextValue: ContextValue = {
    state: state,
    updateAddList: list => {
      updateState(prev => ({ ...prev, willAddList: list }));
    },
    updateRemoveList: list => {
      updateState(prev => ({ ...prev, willRemoveList: list }));
    },
    remove: () => {
      updateState(prevState => {
        const downloadTargetFields = prevState.downloadTargetFields.filter(target => {
          return !prevState.willRemoveList.includes(target.name);
        });
        const downloadTargetFieldNames = downloadTargetFields.map(target => target.name);
        const selectableFields = props.fields.filter(field => {
          return !downloadTargetFieldNames.includes(field.name);
        });
        return {
          ...prevState,
          selectableFields,
          downloadTargetFields,
          willAddList: [],
          willRemoveList: [],
          fieldItems: convertSingleFieldItems(downloadTargetFields),
        };
      });
    },
    add: () => {
      updateState(prevState => {
        const selectableFields = prevState.selectableFields.filter(target => {
          return !prevState.willAddList.includes(target.name);
        });
        const selectableFieldsValues = selectableFields.map(target => target.name);
        const downloadTargetFields = props.fields.filter(field => {
          return !selectableFieldsValues.includes(field.name);
        });
        return {
          ...prevState,
          selectableFields,
          downloadTargetFields,
          willAddList: [],
          willRemoveList: [],
          fieldItems: convertSingleFieldItems(downloadTargetFields),
        };
      });
    },
  };
  return <CsvDownloadContext.Provider value={defaultContextValue}>{props.children}</CsvDownloadContext.Provider>;
};
