import { state } from "./State";
import type * as Types from "./types";

export const initialize = (tab: Types.TabValue): void => {
  state.currentTab = tab;
};

export const clear = () => {
  state.currentTab = null;
  state.interview = null;
  state.loadingStatus = "NONE";
};

export const updateCurrentTab = (newValue: Types.TabValue): void => {
  state.currentTab = newValue;
};

export const updateInterview = (newValue: Types.Interview): void => {
  state.interview = newValue;
};

export const updateLoadingStatus = (newValue: Types.LoadingStatus): void => {
  state.loadingStatus = newValue;
};
