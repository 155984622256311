import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import SortField, { SortFieldProps } from "../../../modules/SortField/SortField";
import DivTableCell from "../../../primitive/DivTableCell/DivTableCell";
import DivTableHead from "../../../primitive/DivTableHead/DivTableHead";
import DivTableRow from "../../../primitive/DivTableRow/DivTableRow";

const StyledTableCell = styled(DivTableCell)(({ theme }) => ({
  borderBottom: "1px solid",
  borderTop: "1px solid",
  borderColor: theme.palette["Gray/Shades"].p8,
  "&.MuiTableCell-root": {
    height: "40px",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: "14px",
  wordBreak: "keep-all",
}));

type HeaderSize = "small" | "medium";

const HeaderSizeMap: Record<HeaderSize, number> = {
  small: 10,
  medium: 20,
};

export type QuestionTableHeaderProps = {
  resultText: string;
  sortField: SortFieldProps;
  hasAction?: boolean;
  hasAverageTimeToAnswer?: boolean;
  /**
   * default: medium
   */
  size?: HeaderSize;
  showLeakScore: boolean;
  showDifficulty: boolean;
};

const QuestionTableHeader: React.FC<QuestionTableHeaderProps> = props => {
  const { t } = useTranslation();
  const size = props.size || "medium";
  const sortFieldProps: SortFieldProps = {
    ...props.sortField,
  };
  const headerCount = React.useMemo(() => {
    if (props.hasAverageTimeToAnswer && props.showLeakScore) {
      return 4;
    }
    if (props.hasAverageTimeToAnswer || props.showLeakScore) {
      return 3;
    }
    return 2;
  }, [props.hasAverageTimeToAnswer, props.showLeakScore]);

  return (
    <DivTableHead sx={{ py: 0.5 }}>
      <DivTableRow key="result-row" sx={{ py: 0 }}>
        <StyledTableCell size="small">
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Typography fontSize={14} fontWeight={400}>
              {props.resultText}
            </Typography>
          </Box>
        </StyledTableCell>
        {Boolean(props.hasAction && props.hasAverageTimeToAnswer) && <StyledTableCell />}
        {Boolean(props.hasAction || props.hasAverageTimeToAnswer) && <StyledTableCell />}
        {props.showDifficulty && <StyledTableCell />}
        {props.showLeakScore && <StyledTableCell />}
        <StyledTableCell />
        <StyledTableCell />
        <StyledTableCell>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <SortField {...sortFieldProps} />
          </Box>
        </StyledTableCell>
      </DivTableRow>

      <DivTableRow key="header">
        <StyledTableCell width={`${100 - HeaderSizeMap[size] * headerCount}%`} />

        {props.showDifficulty && (
          <StyledTableCell width={`${HeaderSizeMap[size]}%`} align="center">
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
              <StyledTypography>{t("難易度")}</StyledTypography>
            </Stack>
          </StyledTableCell>
        )}

        {props.showLeakScore && (
          <StyledTableCell width={`${HeaderSizeMap[size]}%`} align="center">
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
              <StyledTypography>{t("リークスコア")}</StyledTypography>
            </Stack>
          </StyledTableCell>
        )}

        <StyledTableCell width={`${HeaderSizeMap[size]}%`} align="center">
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
            <StyledTypography>{t("想定制限時間")}</StyledTypography>
          </Stack>
        </StyledTableCell>

        {props.hasAverageTimeToAnswer && (
          <StyledTableCell width={`${HeaderSizeMap[size]}%`} align="center">
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
              <StyledTypography>{t("平均解答時間")}</StyledTypography>
            </Stack>
          </StyledTableCell>
        )}

        <StyledTableCell width={`${HeaderSizeMap[size]}%`} align="center">
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
            <StyledTypography>{t("平均スコア")}</StyledTypography>
          </Stack>
        </StyledTableCell>
        <StyledTableCell width={`${HeaderSizeMap[size]}%`} align="center">
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
            <StyledTypography>{t("使用回数")}</StyledTypography>
          </Stack>
        </StyledTableCell>
        {props.hasAction && <StyledTableCell />}
      </DivTableRow>
    </DivTableHead>
  );
};

QuestionTableHeader.displayName = "QuestionTableHeader";

export default QuestionTableHeader;
