import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateQuizTestReportPropsArgs, useGenerateProps } from "./useGenerateProps";

export type QuizTestReportContainerProps = GenerateQuizTestReportPropsArgs;

const QuizTestReportContainer: React.FC<QuizTestReportContainerProps> = props => {
  const quizTestReportProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.QuizTestReport {...quizTestReportProps} />
    </ErrorBoundary>
  );
};

QuizTestReportContainer.displayName = "QuizTestReportContainer";

export default withErrorBoundary(QuizTestReportContainer, {});
