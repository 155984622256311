import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";

import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "../../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";
import OwnerEmailAddressDialog, { OwnerEmailAddressDialogProps } from "../OwnerEmailAddressDialog/OwnerEmailAddressDialog";

const StyledModeEditOutlinedIcon = styled(ModeEditOutlinedIcon)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export type OwnerEmailAddressEditorProps = Pick<OwnerEmailAddressDialogProps, "onSubmit" | "defaultValues" | "emptyDefaultOwnerEmail">;

const OwnerEmailAddressEditor: React.FC<OwnerEmailAddressEditorProps> = props => {
  const [open, setOpen] = React.useState(false);
  const editButton: IconButtonWithTooltipProps = {
    children: <StyledModeEditOutlinedIcon fontSize="small" />,
    onClick: () => {
      setOpen(true);
    },
  };
  const dialog: OwnerEmailAddressDialogProps = {
    ...props,
    open,
    onClose: () => {
      setOpen(false);
    },
  };

  return (
    <Box>
      <IconButtonWithTooltip {...editButton} />
      <OwnerEmailAddressDialog key={`email-dialog-${open}`} {...dialog} />
    </Box>
  );
};

OwnerEmailAddressEditor.displayName = "OwnerEmailAddressEditor";

export default OwnerEmailAddressEditor;
