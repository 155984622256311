import * as Graphql from "@hireroo/graphql/client/urql";

import { initialFilters, SKILL_TAG_SIZE } from "./constants";
import { state } from "./State";
import type * as Types from "./types";

export const initialize = (newState: Partial<Types.State>): void => {
  if (newState.isDescending) state.isDescending = newState.isDescending;
  if (newState.page) state.page = newState.page;
  if (newState.size) state.size = newState.size;
  if (newState.sortMethod) state.sortMethod = newState.sortMethod;
  if (newState.filters) state.filters = newState.filters;
};

export const updateIsDescending = (newIsDescending: boolean): void => {
  state.isDescending = newIsDescending;
};

export const updatePage = (newPage: number): void => {
  state.page = newPage;
};

export const updateSize = (newSize: number): void => {
  state.size = newSize;
};

export const updateSortMethod = (newSortMethod: Graphql.SortMethod): void => {
  state.sortMethod = newSortMethod;
};

export const updateFilters = (partialFilters: Partial<Graphql.Filters>): void => {
  state.filters = {
    ...state.filters,
    ...partialFilters,
  };
};

export const resetFilters = () => {
  state.filters = initialFilters;
  state.sortMethod = Graphql.SortMethod.Unknown;
};

export const updateSkillTags = (res: Types.SkillTagRes) => {
  state.skillTagState.res = res;

  res.skillTagNodes.forEach(skillTag => {
    state.skillTagState.currentSkillTagIdsSet.add(skillTag.skillTagNodeId);
    state.skillTagState.skillTagSources.set(skillTag.skillTagNodeId, skillTag);
  });
};

export const updateSkillTagTextFilter = (textFilter: string) => {
  state.skillTagState.textFilter = textFilter;
};

export const updateSkillTagNextOffset = () => {
  state.skillTagState.nextOffset += SKILL_TAG_SIZE;
};

export const clearCurrentSkillTagState = () => {
  state.skillTagState.res = null;
  state.skillTagState.currentSkillTagIdsSet.clear();
  state.skillTagState.nextOffset = 0;
  state.skillTagState.textFilter = "";
};
