import { useTranslation } from "@hireroo/i18n";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import Link, { LinkProps } from "../../primitive/Link/Link";
import BatchMenu, { BatchMenuProps } from "./parts/BatchMenu/BatchMenu";
import CreateDialog, { CreateDialogProps } from "./parts/CreateDialog/CreateDialog";
import SonarIntegrationTable, { SonarIntegrationTableProps } from "./parts/SonarIntegrationTable/SonarIntegrationTable";

const StyledTypography = styled(Typography)({
  fontWeight: 700,
  fontSize: 14,
});

export type SonarAtsSettingsProps = {
  helpCenterLink: Pick<LinkProps, "onClick">;
  table: Omit<SonarIntegrationTableProps, "enableScreeningFeature">;
  batchMenu: BatchMenuProps;
  createDialog: CreateDialogProps;
};

const SonarAtsSettings: React.FC<SonarAtsSettingsProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Stack direction="column" spacing={1.5}>
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <StyledTypography>{t("sonar ATS連携")}</StyledTypography>
        <Link onClick={props.helpCenterLink.onClick} color={theme.palette.info.main} target="_blank" underline="hover">
          <Box display="flex" alignItems="center">
            <InfoOutlinedIcon fontSize="small" sx={{ width: 16, height: 16 }} color="info" />
            <Typography color="info" sx={{ ml: 1 }} fontSize={14}>
              {t("連携方法")}
            </Typography>
          </Box>
        </Link>
      </Box>
      <Typography fontSize={14} color="textSecondary">
        {t("ATSトークンの発行は対象のコーディングテストが「コーディングテストのリンクを知っている誰もが受験可能」の場合のみ生成可能です。")}
        {t("ATSトークンを生成した直後のみ値をコピーすることが可能です。")}
      </Typography>
      <BatchMenu {...props.batchMenu} />
      <SonarIntegrationTable {...props.table} />
      <CreateDialog {...props.createDialog} />
    </Stack>
  );
};

SonarAtsSettings.displayName = "SonarAtsSettings";

export default SonarAtsSettings;
