import "katex/dist/katex.min.css";

import { styled } from "@mui/material/styles";
import { Variant } from "@mui/material/styles/createTypography";
import * as React from "react";
import ReactMarkdown from "react-markdown";
import rehypeMath from "rehype-katex";
import gfm from "remark-gfm";
import math from "remark-math";

import { Code, Heading, Image, Link, List, ListItem, Paragraph, Table, TableBody, TableCell, TableHead, TableRow } from "./parts";

type Size = "small" | "middle" | "large";

const variantMap: Record<Size, Variant> = {
  small: "body2",
  middle: "body1",
  large: "subtitle1",
};

const Wrapper = styled("div")`
  *:last-child {
    margin-bottom: 0;
  }
`;
export type MarkdownProps = {
  className?: string;
  children: string;
  size: "small" | "middle" | "large";
};

const Markdown: React.FC<MarkdownProps> = props => {
  const variant = variantMap[props.size];
  return (
    <Wrapper className={props.className}>
      <ReactMarkdown
        children={props.children}
        remarkPlugins={[gfm, math]}
        /**
         * rehypMath is using katex and the default output is "htmlAndMathml" https://katex.org/docs/options.html
         * htmlAndMathml renders both html and mathml, but that would cause design breakdown when long string is given including math expression like `O(n log n)`.
         * Hence, options with output: "mathml" is given.
         * https://github.com/hireroo/frontend/pull/2255
         */
        rehypePlugins={[[rehypeMath, { output: "mathml" }]]}
        components={{
          a: ({ node: _node, ...childProps }) => <Link {...childProps} variant={variant} children={childProps.children} />,
          code: ({ node: _node, ...childProps }) => (
            <Code children={childProps.children} inline={childProps.inline} className={childProps.className} />
          ),
          p: ({ node: _node, ...childProps }) => <Paragraph {...childProps} variant={variant} children={childProps.children} />,
          ul: ({ node: _node, ...childProps }) => <List variant={variant} children={childProps.children} />,
          li: ({ node: _node, ...childProps }) => <ListItem variant={variant} children={childProps.children} />,
          table: ({ node: _node, ...childProps }) => <Table children={childProps.children} />,
          thead: ({ node: _node, ...childProps }) => <TableHead children={childProps.children} />,
          tbody: ({ node: _node, ...childProps }) => <TableBody children={childProps.children} />,
          tr: ({ node: _node, ...childProps }) => <TableRow children={childProps.children} />,
          th: ({ node: _node, ...childProps }) => <TableCell children={childProps.children} />,
          td: ({ node: _node, ...childProps }) => <TableCell children={childProps.children} />,
          h1: ({ node: _node, ...childProps }) => <Heading {...childProps} variant={variant} children={childProps.children} level={1} />,
          h2: ({ node: _node, ...childProps }) => <Heading {...childProps} variant={variant} children={childProps.children} level={2} />,
          h3: ({ node: _node, ...childProps }) => <Heading {...childProps} variant={variant} children={childProps.children} level={3} />,
          h4: ({ node: _node, ...childProps }) => <Heading {...childProps} variant={variant} children={childProps.children} level={4} />,
          img: ({ node: _node, ...childProps }) => <Image {...childProps} />,
          /**
           * Ignore pre tags because they are generated in Code tags.
           */
          pre: ({ node: _node, ...childProps }) => <React.Fragment children={childProps.children} />,
        }}
      />
    </Wrapper>
  );
};

Markdown.displayName = "Markdown";

export default React.memo(Markdown);
