import { useTranslation } from "@hireroo/i18n";
import { EntryScreeningForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";

import ScreeningTestLayout, { ScreeningTestLayoutProps } from "../../layouts/ScreeningTestLayout/ScreeningTestLayout";
import EntryTestOverviewSection, {
  EntryTestOverviewSectionProps,
} from "../../ms-components/Spot/EntryTestOverviewSection/EntryTestOverviewSection";
import ConfirmForm, { ConfirmFormProps } from "./parts/ConfirmForm/ConfirmForm";
import CustomFields, { CustomFieldsProps } from "./parts/CustomFields/CustomFields";
import Onboarding, { OnboardingProps } from "./parts/Onboarding/Onboarding";
import SelectQuestionField, { SelectQuestionFieldProps } from "./parts/SelectQuestionField/SelectQuestionField";

const Heading = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  fontWeight: 700,
  textAlign: "center",
  marginBottom: theme.spacing(1.5),
}));

export type EntryScreeningProps = {
  layout: Omit<ScreeningTestLayoutProps, "children">;
  testOverviewSection: EntryTestOverviewSectionProps;
  onboarding: OnboardingProps;
  defaultValues: Pick<EntryScreeningForm.EntryScreeningFormSchema, "variables" | "selectableQuestions">;
  customFieldSection: CustomFieldsProps;
  confirmForm: ConfirmFormProps;
  formArgs: EntryScreeningForm.EntryScreeningFormSchemaArgs;
  selectQuestionField?: SelectQuestionFieldProps;
};

const EntryScreening: React.FC<EntryScreeningProps> = props => {
  const { layout } = props;
  const { t } = useTranslation();
  const validateSchema = EntryScreeningForm.useEntryScreeningFormSchema(props.formArgs);
  const methods = useForm<EntryScreeningForm.EntryScreeningFormSchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: {
      ...props.defaultValues,
      name: "",
      agreement: false,
      email: "",
    },
  });

  const testOverviewSection: EntryTestOverviewSectionProps = {
    ...props.testOverviewSection,
    SelectableQuestionField: props.selectQuestionField && <SelectQuestionField {...props.selectQuestionField} />,
  };

  return (
    <ScreeningTestLayout {...layout}>
      <Container sx={{ paddingY: "1em" }}>
        <Heading variant="h5">{t("エントリー")}</Heading>
        <Stack spacing={2} m={"auto"} sx={{ minWidth: "640px", maxWidth: "800px" }}>
          <FormProvider {...methods}>
            <EntryTestOverviewSection {...testOverviewSection} />
            <Onboarding {...props.onboarding} />
            <CustomFields {...props.customFieldSection} />
            <ConfirmForm {...props.confirmForm} />
          </FormProvider>
        </Stack>
      </Container>
    </ScreeningTestLayout>
  );
};

EntryScreening.displayName = "EntryScreening";

export default EntryScreening;
