import * as monaco from "monaco-editor/esm/vs/editor/editor.api";

import { CursorWidget } from "./CursorWidget";
import * as Types from "./types";

/**
 * Control Monaco editor's cursor widget
 * Ref: https://github.com/interviewstreet/firepad-x/blob/4d171763afb2434d8adb0354dd8409d7077b9a04/src/cursor-widget-controller.ts
 */
export class CursorWidgetController {
  private readonly cursors: Map<string, CursorWidget>;
  private decorations: string[] = [];

  constructor(private readonly editor: monaco.editor.IStandaloneCodeEditor) {
    this.cursors = new Map<string, CursorWidget>();
  }

  public addCursor(options: Types.AddCursorOptions): void {
    const cursorWidget = new CursorWidget(this.editor, {
      cursorId: options.cursorId,
      backgroundColor: options.cursorColor,
      label: options.cursorLabel,
      autoHideDurationMilliseconds: options.autoHideDurationMilliseconds,
      afterDisposed: () => {
        this.removeCursor({
          cursorId: options.cursorId,
        });
      },
    });

    this.editor.addContentWidget(cursorWidget);

    cursorWidget.updateCursorPosition(options.range);

    if (!this.cursors.has(options.cursorId)) {
      this.cursors.set(options.cursorId, cursorWidget);
    }
  }

  public removeCursor(options: Types.RemoveCursorOptions): void {
    const cursorWidget = this.cursors.get(options.cursorId);

    if (!cursorWidget) {
      // Already disposed, nothing to do here.
      return;
    }

    cursorWidget.dispose();
    this.cursors.delete(options.cursorId);
  }

  public updateCursor(options: Types.UpdateCursorOptions): void {
    const cursorWidget = this.cursors.get(options.cursorId);

    if (cursorWidget) {
      // Widget already present, simple update should suffice.
      cursorWidget.updateCursorPosition(options.range);
      cursorWidget.updateContent(options.cursorLabel);

      this.updateCursorDecorations(cursorWidget);

      return;
    }

    this.addCursor({
      cursorId: options.cursorId,
      range: options.range,
      cursorColor: options.cursorColor,
      cursorLabel: options.cursorLabel,
      autoHideDurationMilliseconds: options.autoHideDurationMilliseconds,
    });
  }

  dispose(): void {
    this.cursors.forEach(cursorWidget => cursorWidget.dispose());
    this.cursors.clear();
  }

  private updateCursorDecorations(cursorWidget: CursorWidget): void {
    const position = cursorWidget.getPosition()?.position;
    if (!position) {
      return;
    }
    const newDecorations: monaco.editor.IModelDeltaDecoration[] = [
      {
        range: new monaco.Range(position.lineNumber, position.column, position.lineNumber, position.column),
        options: {
          className: cursorWidget.decorationName,
          isWholeLine: false,
          stickiness: monaco.editor.TrackedRangeStickiness.NeverGrowsWhenTypingAtEdges,
        },
      },
    ];
    this.decorations = this.editor.getModel()?.deltaDecorations(this.decorations, newDecorations) ?? [];
  }
}
