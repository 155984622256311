import { useTranslation } from "@hireroo/i18n";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LockIcon from "@mui/icons-material/Lock";
import { useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import InputControlTextField, { InputControlTextFieldProps } from "../../primitive/InputControl/InputControlTextField/InputControlTextField";

export type MailAddressFieldProps = InputControlTextFieldProps & {
  value?: string;
  onChange?: (text: string) => void;
  verified?: boolean;
};

const MailAddressField: React.FC<MailAddressFieldProps & { name: string }> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const method = useFormContext();
  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" spacing={1} alignItems="center">
        <LockIcon sx={{ width: "16px", height: "16px" }} />
        <Typography fontSize={14} fontWeight={700}>
          {t("メールアドレス")}
        </Typography>

        {props.verified && (
          <Stack direction="row" spacing={0.5} alignItems="center">
            <CheckCircleIcon color="success" sx={{ width: "16px", height: "16px" }} />
            <Typography fontSize={12} color={theme.palette.success.main}>
              {t("認証済み")}
            </Typography>
          </Stack>
        )}
      </Stack>
      <InputControlTextField
        {...props}
        control={method.control}
        type="email"
        size="small"
        helperText={props.helperText}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
      />
    </Stack>
  );
};

MailAddressField.displayName = "MailAddressField";

export default MailAddressField;
