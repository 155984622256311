import { AutocompleteProps } from "@mui/material/Autocomplete";
import * as React from "react";

import QuestionList, { QuestionListProps } from "./parts/QuestionList";

export type TagSearchFieldProps = AutocompleteProps<{ name: string }, true, true, true>;

export type SearchQuizQuestionProps = {
  questionList: QuestionListProps;
};

const SearchQuizQuestion: React.FC<SearchQuizQuestionProps> = props => {
  return <QuestionList {...props.questionList} />;
};

SearchQuizQuestion.displayName = "SearchQuizQuestion";

export default SearchQuizQuestion;
