import { AlgorithmResourceEditor } from "@hireroo/app-store/view-domain/AlgorithmResourceEditor";
import { useTranslation } from "@hireroo/i18n";
import { Pages } from "@hireroo/presentation/legacy";

import {
  useAlgorithmSignature,
  useAnswersDefaultValues,
  useClassSignature,
  useDatabaseSignature,
  useFunctionDefinitionDefaultValues,
  useHintsDefaultValues,
  useQuestionDetailDefaultValues,
  useTableSignatureDefaultValues,
  useTestCasesDefaultValues,
} from "./defaultValuesHooks";

type SignatureProps = Pages.AlgorithmResourceEditorProviderProps["testCasesFormProvider"]["contextProps"]["signatureProps"];

export type GenerateAlgorithmResourceEditorProviderPropsArgs = {
  type: "CREATE" | "UPDATE";
};
export const useGenerateProviderProps = (
  _args: GenerateAlgorithmResourceEditorProviderPropsArgs,
): Pages.AlgorithmResourceEditorProviderProps => {
  const { t } = useTranslation();
  const variant = AlgorithmResourceEditor.useVariant();
  const questionDetailsDefaultValues = useQuestionDetailDefaultValues();
  const algorithmSignature = useAlgorithmSignature();
  const databaseSignature = useDatabaseSignature();
  const classSignature = useClassSignature();
  const tableSignatureDefaultValues = useTableSignatureDefaultValues();
  const functionDefinitionDefaultValues = useFunctionDefinitionDefaultValues();
  const testCasesDefaultValues = useTestCasesDefaultValues();
  const answersDefaultValues = useAnswersDefaultValues();
  const question = AlgorithmResourceEditor.useQuestion();
  const hintsDefaultValues = useHintsDefaultValues();

  const signaturePropsMap: Record<AlgorithmResourceEditor.QuestionVariant, SignatureProps> = {
    ALGORITHM: {
      variant: "ALGORITHM",
      signature: algorithmSignature,
    },
    DATABASE: {
      variant: "DATABASE",
      signature: databaseSignature,
    },
    // TODO: Please change the signature to class signature
    CLASS: {
      variant: "CLASS",
      signature: classSignature,
    },
  };

  return {
    questionDetailProvider: {
      defaultValues: questionDetailsDefaultValues,
      contextProps: {
        variantField: {
          menuItems: [
            { label: t("アルゴリズム"), value: "ALGORITHM", disabled: Boolean(question && question.variant !== "ALGORITHM") },
            { label: t("データベース"), value: "DATABASE", disabled: Boolean(question && question.variant !== "DATABASE") },
          ],
        },
      },
    },
    functionDefinitionProvider: {
      defaultValues: functionDefinitionDefaultValues,
    },
    testCasesFormProvider: {
      contextProps: {
        signatureProps: signaturePropsMap[variant],
      },
      defaultValues: testCasesDefaultValues,
    },
    answersFormProvider: {
      defaultValues: answersDefaultValues,
      signatureProps: signaturePropsMap[variant],
      correctnessTestCases: testCasesDefaultValues.correctnessTestCases,
      performanceTestCases: testCasesDefaultValues.performanceTestCases,
    },
    hintFormProvider: {
      defaultValues: hintsDefaultValues,
    },
    tableSignature: {
      defaultValues: tableSignatureDefaultValues,
    },
  };
};
