import { useFirepad } from "@hireroo/app-helper/hooks";
import { QuizCodingEditor } from "@hireroo/app-store/widget/shared/QuizCodingEditor";
import { Widget } from "@hireroo/presentation";
import * as monaco from "monaco-editor";
import * as React from "react";

const { QuizFreeTextEditor } = QuizCodingEditor;

export type GenerateQuizFreeTextEditorContainerPropsArgs = {
  firepad: {
    entityId: number;
    packageId: number;
    questionId: number;
    uid: string;
    displayName: string;
  };
  description: string;
  defaultValue?: string;
  onEditorValueDidChange?: (value: string) => void;
};

export const useGenerateProps = (args: GenerateQuizFreeTextEditorContainerPropsArgs): Widget.QuizFreeTextEditorProps => {
  const editorRef = React.useRef<monaco.editor.IStandaloneCodeEditor | null>(null);
  const { uid, entityId, packageId, questionId } = args.firepad;
  const answerText = QuizFreeTextEditor.useAnswerText(questionId);

  const handleAnswerTextChange = React.useCallback(
    (value: string | undefined) => {
      QuizFreeTextEditor.changeAnswerText(questionId, value || "");
    },
    [questionId],
  );

  const [, padAction] = useFirepad({
    db: "quiz",
    key: `quizzes/${entityId}/packages/${packageId}/questions/${questionId}`,
    readOnly: false,
  });

  return {
    description: args.description,
    previewEditor: {
      codeEditor: {
        theme: "hirerooDark",
        path: `${args.firepad.entityId}-${args.firepad.packageId}-${args.firepad.questionId}`,
        onChange: handleAnswerTextChange,
        onMount: (editor, _monaco) => {
          editorRef.current = editor;
          // To get firebase realtime database reference and set this for the monaco editor path to change a model automatically.
          padAction.initPad(uid, editor);
        },
      },
      value: answerText || "",
    },
  };
};
