import { ExamRankVisualizer } from "@hireroo/app-store/widget/shared/ExamRankVisualizer";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import ExamRankVisualizerContainer, { ExamRankVisualizerContainerProps } from "./Container";
import * as Subscriber from "./Subscriber";

export type ExamRankVisualizerInitialContainerProps = {
  pause: boolean;
  companyId: number;
  finalizedAtSeconds: number | null;
  enableTagField: boolean;
  readOnly?: boolean;
  enableRank: boolean;
  isReliableExamRank: boolean;
  examId: string;
};

const ExamRankVisualizerInitialContainer: React.FC<ExamRankVisualizerInitialContainerProps> = props => {
  const initialized = ExamRankVisualizer.useInitialized();

  React.useEffect(() => {
    const stop = Subscriber.startSubscribeQueryKey({
      companyId: props.companyId,
      examId: props.examId,
    });
    return () => {
      stop();
    };
  }, [props.companyId, props.examId]);

  React.useEffect(() => {
    ExamRankVisualizer.initialize({
      finalizedAtSeconds: props.finalizedAtSeconds,
      tags: [],
    });
  }, [props.finalizedAtSeconds]);

  if (props.pause || !initialized) {
    return (
      <ErrorBoundary>
        <Widget.TestRankVisualizer
          status="LOADING"
          queryToolbar={{
            enableTagField: true,
            onChange: () => undefined,
          }}
          areaHistogram={{
            data: [],
            lines: [],
          }}
        />
      </ErrorBoundary>
    );
  }
  const testRankVisualizerProps: ExamRankVisualizerContainerProps = {
    enableTagField: props.enableTagField,
    enableRank: props.enableRank,
    readOnly: props.readOnly,
    isReliableExamRank: props.isReliableExamRank,
  };
  return (
    <ErrorBoundary>
      <ExamRankVisualizerContainer {...testRankVisualizerProps} />
    </ErrorBoundary>
  );
};

ExamRankVisualizerInitialContainer.displayName = "ExamRankVisualizerInitialContainer";

export default withErrorBoundary(ExamRankVisualizerInitialContainer, {});
