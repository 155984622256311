import { App } from "@hireroo/app-store/essential/employee";
import { RemotesIdEvaluateStore } from "@hireroo/app-store/page/e/remotes_id_evaluate";
import * as Graphql from "@hireroo/graphql/client/urql";
import { GraphQLError } from "graphql";
import * as React from "react";

export type FetchResult = {
  isLoading: boolean;
  errors: GraphQLError[];
};

export type FetchMetricGroupsArgs = {
  companyId: number;
};

// TODO: please fix it to use pagination.
const FETCH_SIZE = 100;

export const useFetchMetrics = (args: FetchMetricGroupsArgs): FetchResult => {
  const appStatus = App.useStatus();
  const pagination = RemotesIdEvaluateStore.useMetricPagination();
  const [result] = Graphql.useListEvaluationMetricsForRemotesEvaluateQuery({
    variables: {
      companyId: args.companyId,
      withCount: false,
      size: FETCH_SIZE,
      cursor: pagination.cursor,
    },
    pause: appStatus !== "INITIALIZED",
  });

  React.useEffect(() => {
    if (result.data?.evaluationMetricsByCompany) {
      RemotesIdEvaluateStore.addMetrics(result.data.evaluationMetricsByCompany.metrics);
      RemotesIdEvaluateStore.updateMetricPagination({
        nextCursor: result.data.evaluationMetricsByCompany.pager?.cursor || null,
        hasNext: result.data.evaluationMetricsByCompany.pager?.hasNext || false,
      });
    }
  }, [result.data?.evaluationMetricsByCompany]);

  return {
    isLoading: result.fetching || appStatus !== "INITIALIZED",
    errors: result.error?.graphQLErrors ? result.error.graphQLErrors : [],
  };
};
