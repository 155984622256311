import { useTranslation } from "@hireroo/i18n";
import { ThemeProvider } from "@hireroo/ui-theme/theme-v2";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import * as React from "react";

import { useSystemDesignContext } from "../../../store";
import ControlButtonGroup from "../primitive/ControlButtonGroup/ControlButtonGroup";
import SquareButton, { SquareButtonProps } from "../primitive/SquareButton/SquareButton";
import RemoveElementConfirmDialog, { RemoveElementConfirmDialogProps } from "./parts/RemoveElementConfirmDialog/RemoveElementConfirmDialog";

const StyledPaper = styled(Paper)`
  display: inline-block;
  height: 32px;
`;

export type ResetControlProps = {
  onResetFlowChart: () => void;
};

const ResetControl: React.FC<ResetControlProps> = props => {
  const Store = useSystemDesignContext();
  const { t } = useTranslation();
  const resetButton: SquareButtonProps = {
    tooltipText: t("リセット"),
    /**
     * Using Tutorial
     */
    id: "system-design-reset-button",
    onClick: () => {
      Store.action.toggleResetDialog();
    },
  };

  const openResetFlowChartConfirmDialog = Store.hooks.useOpenResetDialog();
  const removeElementConfirmDialogProps: RemoveElementConfirmDialogProps = {
    open: openResetFlowChartConfirmDialog,
    onClose: () => {
      Store.action.toggleResetDialog();
    },
    onResetFlowChart: () => {
      Store.action.toggleResetDialog();
      props.onResetFlowChart();
    },
  };

  return (
    <StyledPaper>
      <ThemeProvider defaultColorMode="DARK">
        <RemoveElementConfirmDialog {...removeElementConfirmDialogProps} />
      </ThemeProvider>
      <ControlButtonGroup>
        <SquareButton {...resetButton}>
          <RefreshOutlinedIcon />
        </SquareButton>
      </ControlButtonGroup>
    </StyledPaper>
  );
};

ResetControl.displayName = "ResetControl";

export default ResetControl;
