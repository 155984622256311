import { useTranslation } from "@hireroo/i18n";
import type { SelectChangeEvent } from "@mui/material";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import React from "react";

import {
  CACHE_POLICY,
  CachePolicy,
  LabelCache,
  NodeElement,
  OPERATION_TYPE,
  OperationType,
  UnionSettingsFields,
} from "../../../../../features";

export type CacheSettingsFormProps = {
  element: NodeElement<LabelCache>;
  updateSettings: (id: string, updates: UnionSettingsFields, operationType: OperationType) => void;
};

export const CacheSettingsForm: React.FC<CacheSettingsFormProps> = (props: CacheSettingsFormProps) => {
  const { t } = useTranslation();

  const handleSelectCachePolicy = (e: SelectChangeEvent<unknown>) => {
    props.updateSettings(props.element.id, { cachePolicy: e.target.value as CachePolicy }, OPERATION_TYPE.do);
  };

  return (
    <Box display={"flex"} flexDirection={"column"} mt={2}>
      <Typography>{t("キャッシュポリシー")}</Typography>
      <Box display={"flex"} flexDirection={"column"} mt={1}>
        <Typography variant={"caption"}>{`TTL: ${t("一定時間経過後に破棄")}`}</Typography>
        <Typography variant={"caption"}>{`LRU: ${t("最も長く使われなかったものから破棄")}`}</Typography>
        <Typography variant={"caption"}>{`LFU: ${t("使用頻度が最も低いものから破棄")}`}</Typography>
        <Typography variant={"caption"}>{`FIFO: ${t("最も古いものから破棄")}`}</Typography>
      </Box>
      <Box mt={1} />
      <Select size={"small"} variant={"outlined"} onChange={handleSelectCachePolicy} value={props.element.settings.cachePolicy}>
        <MenuItem value={CACHE_POLICY.ttl}>{"TTL"}</MenuItem>
        <MenuItem value={CACHE_POLICY.lru}>{"LRU"}</MenuItem>
        <MenuItem value={CACHE_POLICY.lfu}>{"LFU"}</MenuItem>
        <MenuItem value={CACHE_POLICY.fifo}>{"FIFO"}</MenuItem>
      </Select>
      <Typography mt={1} variant={"caption"}>
        ※ {t("デフォルト設定のTTLは変更後も引き継がれます。")}
      </Typography>
    </Box>
  );
};

CacheSettingsForm.displayName = "CacheSettingsForm";

export default CacheSettingsForm;
