import { useTranslation } from "@hireroo/i18n";
import RefreshIcon from "@mui/icons-material/Refresh";
import Button, { type ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import CustomScoreAllocation, { CustomScoreAllocationProps } from "../CustomScoreAllocation/CustomScoreAllocation";
import ScoreWeightInput, { ScoreWeightInputProps } from "./parts/ScoreWeightInput/ScoreWeightInput";

export type CustomScoreInputFieldsProps = {
  description: string;
  customScoreAllocation?: CustomScoreAllocationProps;
  resetButton: Pick<ButtonProps, "onClick">;
  scoreWeightInputs: ScoreWeightInputProps[];
};

const CustomScoreInputFields: React.FC<CustomScoreInputFieldsProps> = props => {
  const { t } = useTranslation();

  const resetButton: ButtonProps = {
    ...props.resetButton,
    startIcon: <RefreshIcon />,
    children: t("重み付けを全て1にする"),
    color: "outline-only",
    variant: "text",
    size: "small",
  };
  return (
    <Stack spacing={1} width="100%">
      <Stack mb={1}>
        <Typography fontSize={14}>{props.description}</Typography>
      </Stack>
      {props.customScoreAllocation && props.scoreWeightInputs.length > 0 && (
        <Stack width="100%">
          <CustomScoreAllocation {...props.customScoreAllocation} />
        </Stack>
      )}
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography fontSize={14}>{t("スコアの重み付けの設定")}</Typography>
        <Button {...resetButton} />
      </Stack>
      <Stack spacing={1}>
        {props.scoreWeightInputs.length === 0 && (
          <Typography fontSize={14} color="textSecondary" alignSelf="center">
            {t("まずは問題を選択してください。")}
          </Typography>
        )}
        {props.scoreWeightInputs.map(customScoreInput => (
          <ScoreWeightInput key={`${customScoreInput.id}`} {...customScoreInput} />
        ))}
      </Stack>
    </Stack>
  );
};

CustomScoreInputFields.displayName = "CustomScoreInputFields";

export default CustomScoreInputFields;
