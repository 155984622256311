import { proxy } from "valtio";

import type { State } from "./types";

export const state = proxy<State>({
  common: {
    kind: "NOTIFY",
    open: false,
    message: "",
    severity: null,
    action: null,
  },
  loading: {
    kind: "LOADING",
    open: false,
  },
});
