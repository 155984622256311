import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import * as z from "zod";

import { useEmailListItem } from "./FieldValidator";

export const useCandidateInviteFormSchema = () => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const emailListItem = useEmailListItem();
  return z.object({
    emails: z
      .array(emailListItem)
      .min(1, {
        message: t("必須項目です"),
      })
      .max(1000, {
        message: t2("MaxInviteCandidateErrorMessage", { max: 1000 }),
      })
      .refine(
        emails => {
          const uniqueEmails = new Set(emails.map(email => email.value));
          return uniqueEmails.size === emails.length;
        },
        {
          message: t("重複しているメールアドレスがあります"),
        },
      ),
  });
};

export type CandidateInviteFormSchema = z.infer<ReturnType<typeof useCandidateInviteFormSchema>>;
