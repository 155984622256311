import { proxyMap, proxySet } from "valtio/utils";

import { state } from "./State";
import type * as Types from "./types";

export const initialize = (entity: Types.QuizEntity) => {
  const submittedQuestionId = proxySet<number>();
  entity.submissions.forEach(submission => {
    submittedQuestionId.add(submission.questionId);
  });

  const submittedQuestionIdStatusMap = proxyMap<number, number[]>();
  entity.submissions.forEach(submission => {
    submittedQuestionIdStatusMap.set(submission.questionId, submission.optionIds);
    submittedQuestionId.add(submission.questionId);
  });

  const questions = proxyMap<Types.QuestionKey, Types.Question>();
  const questionIdVersionMap = proxyMap<number, string>();
  if (entity.pb_package) {
    entity.pb_package.questions.forEach(question => {
      questions.set(`${question.id}-${question.version}`, question);
      questionIdVersionMap.set(question.id, question.version);
    });
  }

  const entityState: Types.QuizEntityState = {
    entity,
    package: entity.pb_package,
    questions,
    submittedQuestionId,
    submittedQuestionIdOptionMap: submittedQuestionIdStatusMap,
    questionIdVersionMap,
    answerText: proxyMap(),
  };
  state.quizzes.set(entity.quizEntityId, entityState);
  state.currentId = entity.quizEntityId;
};

export const setIpAddress = (ipAddress: string) => {
  state.ipAddress = ipAddress;
};

export const setGeolocation = (geolocation: string) => {
  state.geolocation = geolocation;
};

export const createQuizEntityAction = (entityId: number) => {
  const quiz = state.quizzes.get(entityId);
  const entityState = state.quizzes.get(entityId);
  if (!entityState || !quiz) {
    throw new Error(`Please initialize entityId=${entityId}`);
  }

  // TODO: You can remove after design renewal.
  const setSubmittedQuestionId = (questionId: number) => {
    quiz.submittedQuestionId.add(questionId);

    const questions = entityState.package?.questions || [];
    const lastQuestion = questions.length > 0 ? questions[questions.length - 1] : undefined;
    if (!lastQuestion) {
      return;
    }
    const isLastQuestionSubmission = lastQuestion.id === questionId;
    /**
     * If the number of submissions equals the number of questions, the solution is considered complete.
     */
    if (isLastQuestionSubmission && quiz.submittedQuestionId.size === entityState.questionIdVersionMap.size) {
      state.submittedEntityOfLastQuestion = entityState.entity;
    }
  };

  const setSubmittedQuestionIdWithOptionIds = (questionId: number, optionIds: number[]) => {
    quiz.submittedQuestionIdOptionMap.set(questionId, optionIds);

    const questions = entityState.package?.questions || [];
    const lastQuestion = questions.length > 0 ? questions[questions.length - 1] : undefined;
    if (!lastQuestion) {
      return;
    }
    const isLastQuestionSubmission = lastQuestion.id === questionId;
    /**
     * If the number of submissions equals the number of questions, the solution is considered complete.
     */
    if (isLastQuestionSubmission && quiz.submittedQuestionIdOptionMap.size === quiz.questions.size) {
      state.submittedEntityOfLastQuestion = entityState.entity;
    }
  };

  const setAnswerText = (questionId: number, text: string) => {
    quiz.answerText.set(questionId, text);
  };

  return {
    setAnswerText,
    setSubmittedQuestionId,
    setSubmittedQuestionIdWithOptionIds,
  };
};

export const setSubmitStatus = (status: Types.QuizStatus) => {
  state.submitStatus = status;
};

export const clear = (entityId: Types.QuizEntityId) => {
  state.quizzes.delete(entityId);
};

export const setSubmittedEntity = (entity: Types.QuizEntity) => {
  state.submittedEntityOfLastQuestion = entity;
};

export const clearSubmittedEntity = () => {
  state.submittedEntityOfLastQuestion = null;
};
