import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import * as React from "react";

import TablePagination, { TablePaginationProps } from "../../modules/TablePagination/TablePagination";
import ScreeningTestListTable, { ScreeningTestListTableProps } from "./parts/ScreeningTestListTable/ScreeningTestListTable";
import SearchResultBar, { SearchResultBarProps } from "./parts/SearchResultBar/SearchResultBar";

export type ScreeningTestListProps = {
  tablePagination: TablePaginationProps;
  screeningTestListTable: ScreeningTestListTableProps;
  searchResultBar: SearchResultBarProps;
  refreshKey: string;
};

const ScreeningTestList: React.FC<ScreeningTestListProps> = props => {
  return (
    <Paper sx={{ boxShadow: "none", borderRadius: "16px" }} elevation={2}>
      <SearchResultBar {...props.searchResultBar} />
      <ScreeningTestListTable key={`${props.refreshKey}`} {...props.screeningTestListTable} />
      <Box display="flex" justifyContent="flex-end">
        <TablePagination {...props.tablePagination} />
      </Box>
    </Paper>
  );
};

ScreeningTestList.displayName = "ScreeningTestList";

export default ScreeningTestList;
