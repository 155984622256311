import { useTranslation } from "@hireroo/i18n";
import ErrorIcon from "@mui/icons-material/Error";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import StepConnector from "@mui/material/StepConnector";
import Stepper from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";
import * as React from "react";

import { useScreeningResourceEditorContext } from "../../../../Context";

type CustomStepProps = {
  label: string;
  onClick: () => void;
  hasError: boolean;
};

const StyledStepper = styled(Stepper)(({ theme }) => ({
  width: "100%",
  maxWidth: 600,
  margin: "0 auto",
  "& .Mui-active": {
    color: theme.palette.secondary.main,
  },
  ".MuiStepper-root": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
  },
}));

const StyledStepConnector = styled(StepConnector)(() => ({
  margin: "0 16px",
}));

const StyledStep = styled(Step)(({ theme }) => ({
  padding: theme.spacing(1),
  display: "flex",
  alignItems: "center",
}));

const StyledStepButton = styled(StepButton)(({ theme }) => ({
  padding: theme.spacing(1),
  height: 24,
  borderRadius: 40,
  flexShrink: 0,
  width: "fit-content",
  textTransform: "none",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: theme.palette["Secondary/Shades"].p16,
  },
  marginTop: "auto",
  marginBottom: "auto",
  '&[data-active="true"]': {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette["Secondary/Shades"].p16,
  },
  "& .Mui-active": {
    color: theme.palette.secondary.main,
  },
  "& .MuiSvgIcon-root.Mui-completed": {
    color: theme.palette.secondary.main,
  },
  "& .MuiStepLabel-root .Mui-active": {
    color: theme.palette.secondary.main,
  },
  ".label-text": {
    verticalAlign: "middle",
    display: "inline-block",
  },
  ".end-icon": {
    paddingLeft: "8px",
    verticalAlign: "middle",
    display: "inline-block",
  },
}));

export type EditStepperProps = {};

const EditStepper: React.FC<EditStepperProps> = _props => {
  const { t } = useTranslation();
  const { updateStep, activeStep, stepStatusMap } = useScreeningResourceEditorContext();
  const steps: CustomStepProps[] = [
    {
      label: t("テスト設定"),
      onClick: () => {
        updateStep("TEST_QUESTION_SETUP");
      },
      hasError: stepStatusMap.get("TEST_QUESTION_SETUP") === "ERROR",
    },
    {
      label: t("受験設定"),
      onClick: () => {
        updateStep("TEST_SETUP");
      },
      hasError: stepStatusMap.get("TEST_SETUP") === "ERROR",
    },
    {
      label: t("レポート設定"),
      onClick: () => {
        updateStep("REPORT_SETUP");
      },
      hasError: stepStatusMap.get("REPORT_SETUP") === "ERROR",
    },
    {
      label: t("招待設定"),
      onClick: () => {
        updateStep("TEST_INVITE_SETUP");
      },
      hasError: stepStatusMap.get("TEST_INVITE_SETUP") === "ERROR",
    },
  ];

  return (
    <StyledStepper nonLinear activeStep={activeStep} connector={<StyledStepConnector />} sx={{ width: "100%" }}>
      {steps.map((stepProps, index) => (
        <StyledStep key={`step-${index}`} completed={index < activeStep}>
          <StyledStepButton onClick={stepProps.onClick} data-active={activeStep === index ? "true" : "false"}>
            <span className="label-text">{stepProps.label}</span>
            {stepProps.hasError && <ErrorIcon className="end-icon" fontSize="small" color="error" />}
          </StyledStepButton>
        </StyledStep>
      ))}
    </StyledStepper>
  );
};

EditStepper.displayName = "EditStepper";

export default EditStepper;
