import { Chip, styled } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import * as React from "react";

const StyledChip = styled(Chip)({
  borderRadius: 3,
});

export type Color = "green" | "yellow" | "red" | "grey" | "grey-500" | "primary";

export type TagProps = {
  label: string;
  color?: Color;
};

const Tag: React.FC<TagProps> = props => {
  const theme = useTheme();
  const colorMap: Record<Color, string> = {
    green: theme.palette.success.main,
    yellow: theme.palette.warning.main,
    red: theme.palette.error.main,
    grey: theme.palette.grey["A700"],
    "grey-500": theme.palette.grey["500"],
    primary: theme.palette.primary.dark,
  };
  const backgroundColor = props.color && colorMap[props.color];

  return <StyledChip role="chip" size="small" label={props.label} style={{ backgroundColor: backgroundColor }} />;
};

Tag.displayName = "Tag";

export default Tag;
