import { useTheme } from "@mui/material";
import * as React from "react";

type AxisTickProps = {
  x?: number;
  y?: number;
  payload?: {
    value: string;
  };
};

const AxisTick: React.FC<AxisTickProps> = props => {
  const { x, y, payload } = props;
  const spitted = payload?.value.split(":");
  const theme = useTheme();

  if (x === undefined || y === undefined || !spitted || spitted.length < 2) {
    return null;
  }

  const isPassed = JSON.parse(spitted.at(0) ?? "false");
  const label = spitted.at(1);

  return (
    <g transform={`translate(${props.x},${props.y})`}>
      <text x={50} y={0} dy={16} textAnchor="end" fill={isPassed ? theme.palette.success.main : theme.palette.error.main}>
        {isPassed ? `✔ ${label}` : `✖ ${label}`}
      </text>
    </g>
  );
};

AxisTick.displayName = "AxisTick";

export default AxisTick;
