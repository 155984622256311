import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { PaymentForm } from "@hireroo/validator";
import Box from "@mui/material/Box";
import Button, { type ButtonProps } from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useController } from "react-hook-form";

import ContactUsDialog, { ContactUsDialogProps } from "../../../../../../Payment/ContactUsDialog/ContactUsDialog";

const TrialLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  borderRadius: 20,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  display: "inline",
  fontSize: 10,
  position: "absolute",
  top: 0,
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: theme.zIndex.appBar,
  boxShadow: `0 4 4 0 ${theme.palette["Primary/Shades"].p16}`,
}));

const PlanName = styled(Typography)(() => ({
  fontWeight: "bold",
  fontSize: 16,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  textAlign: "center",
  position: "relative",
  "&.selected-plan": {
    background: `linear-gradient(${theme.palette["Primary/Shades"].p16}, ${theme.palette["Primary/Shades"].p4})`,
  },
}));

const SubText = styled("span")(() => ({
  fontSize: 12,
  fontWeight: "normal",
  paddingTop: 16,
  paddingBottom: 16,
}));

type MonthlyPrice = { kind: "MONTHLY"; value: string };
type YearlyPrice = { kind: "YEARLY"; value: string };
type EstimatePrice = { kind: "ESTIMATE" };
type Action = { kind: "CONTACT_US" } | { kind: "RADIO"; value: PaymentForm.PlanType };

export type FeatureTableHeadCellProps = {
  /**
   * Form Field name
   */
  name: string;
  kind: "TRIAL" | "SELECTED" | "NONE";
  planName: string;
  price: MonthlyPrice | YearlyPrice | EstimatePrice;
  action: Action;
};

const FeatureTableHeadCell: React.FC<FeatureTableHeadCellProps> = props => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const methods = useController<Record<string, PaymentForm.PlanType>>({
    name: props.name,
  });
  const [open, setOpen] = React.useState(false);
  const contactUsDialogProps: ContactUsDialogProps = {
    open,
    title: t("エンタープライズプランに興味がありますか?"),
    onClose: () => {
      setOpen(false);
    },
  };
  const contactButton: ButtonProps | undefined =
    props.action.kind === "CONTACT_US"
      ? {
          variant: "contained",
          color: "secondary",
          children: t("お問い合わせ"),
          onClick: () => {
            setOpen(true);
          },
        }
      : undefined;

  return (
    <StyledTableCell className={props.kind !== "NONE" ? "selected-plan" : undefined}>
      <ContactUsDialog {...contactUsDialogProps} />
      {props.kind === "TRIAL" && <TrialLabel variant="caption">{t("現在トライアル利用中")}</TrialLabel>}
      {props.kind === "SELECTED" && <TrialLabel variant="caption">{t("現在のプラン")}</TrialLabel>}
      <PlanName mb={0.5}>{props.planName}</PlanName>
      {props.price.kind !== "ESTIMATE" && (
        <Box mb={0.5}>
          <Typography fontWeight="bold">
            {props.price.value}
            <SubText> / {props.price.kind === "MONTHLY" ? t2("paymentMonthly") : t2("paymentYearly")}</SubText>
          </Typography>
        </Box>
      )}
      {props.price.kind === "ESTIMATE" && (
        <Typography mb={0.5} variant="body2">
          {t("お見積り")}
        </Typography>
      )}
      {props.action.kind === "RADIO" && (
        <Box mb={0.5}>
          <Radio
            color="secondary"
            size="small"
            name={props.name}
            value={props.action.value}
            checked={methods.field.value === props.action.value}
          />
        </Box>
      )}
      {contactButton && <Button {...contactButton} />}
    </StyledTableCell>
  );
};

FeatureTableHeadCell.displayName = "FeatureTableHeadCell";

export default FeatureTableHeadCell;
