import { Company } from "@hireroo/app-store/essential/employee";
import { QuestionQuizPackageResourceEditor } from "@hireroo/app-store/view-domain/QuestionQuizPackageResourceEditor";
import { QuizQuestionEditor } from "@hireroo/app-store/widget/e/QuizQuestionEditor";
import { SearchQuizQuestion } from "@hireroo/app-store/widget/e/SearchQuizQuestion";
import { useLanguageCode } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Pages } from "@hireroo/presentation/legacy";
import * as React from "react";

export type QuizResourcePreviewDialogProps = Pages.QuizResourceEditorProps["selectQuestions"]["quizResourcePreviewDialog"];

export type GenerateQuizResourcePreviewDialogArgs = {};

export const useGenerateQuizResourcePreviewDialog = (_args: GenerateQuizResourcePreviewDialogArgs): QuizResourcePreviewDialogProps => {
  const companyId = Company.useActiveCompanyId();
  const languageCode = useLanguageCode();
  const selectedQuestionMap = SearchQuizQuestion.useSelectedQuestionMap();
  const previewQuestionId = QuestionQuizPackageResourceEditor.usePreviewQuestionId();
  const question = previewQuestionId ? selectedQuestionMap.get(previewQuestionId) : null;
  const selfCompanyQuestion = question?.companyId === companyId;
  const handleClickYes = React.useCallback(() => {
    if (!question) {
      return;
    }
    QuestionQuizPackageResourceEditor.previewToEdit();
    QuizQuestionEditor.setQuestion(question);
    QuestionQuizPackageResourceEditor.setOpenNewQuestionEditor(true);
  }, [question]);
  if (!question) {
    return {
      open: false,
      yesButton: {
        disabled: true,
      },
    };
  }
  const content = ((): QuizResourcePreviewDialogProps["content"] => {
    switch (question.variant) {
      case "SINGLE_CHOICE": {
        const correctOptionValue = question.options.find(option => option.isCorrect);
        return {
          kind: "SINGLE_CHOICE",
          quizContent: {
            id: `${question.id}-${question.version}`,
            selected: correctOptionValue?.id.toString() || "",
            description: resolveLanguage(question, languageCode, "description"),
            items: question.options.map(option => {
              return {
                title: resolveLanguage(option, languageCode, "title", { ja: "", en: "" }),
                value: option.id.toString(),
                description: resolveLanguage(option, languageCode, "additionalDetail", { ja: "", en: "" }),
              };
            }),
          },
        };
      }
      case "MULTI_CHOICE": {
        return {
          kind: "MULTI_CHOICE",
          quizContent: {
            id: `${question.id}-${question.version}`,
            description: resolveLanguage(question, languageCode, "description"),
            skipped: false,
            items: question.options.map(option => {
              return {
                title: resolveLanguage(option, languageCode, "title", { ja: "", en: "" }),
                checked: option.isCorrect,
                description: resolveLanguage(option, languageCode, "additionalDetail", { ja: "", en: "" }),
              };
            }),
          },
        };
      }
      case "FREE_TEXT": {
        return {
          kind: "FREE_TEXT",
          quizContent: {
            id: `${question.id}-${question.version}`,
            description: resolveLanguage(question, languageCode, "description"),
            items: question.evaluationItems.map(evaluation => {
              return {
                title: resolveLanguage(evaluation, languageCode, "title", { ja: "", en: "" }),
              };
            }),
          },
        };
      }
      default:
        throw new Error(`Unknown type`);
    }
  })();

  return {
    open: !!question,
    yesButton: {
      disabled: !selfCompanyQuestion,
      onClick: handleClickYes,
    },
    title: [resolveLanguage(question, languageCode, "title"), `(${question.version})`].join(" "),
    content,
    noButton: {
      onClick: () => {
        QuestionQuizPackageResourceEditor.setPreviewQuestionId(null);
      },
    },
  };
};
