import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import * as React from "react";

import TablePagination, { TablePaginationProps } from "../../modules/TablePagination/TablePagination";
import QuestionTableHeader, { QuestionTableHeaderProps } from "../../ms-components/Question/QuestionTableHeader/QuestionTableHeader";
import DivTable from "../../primitive/DivTable/DivTable";
import DivTableBody from "../../primitive/DivTableBody/DivTableBody";
import QuestionTableRow, { QuestionTableRowProps } from "./parts/QuestionTableRow/QuestionTableRow";

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  border: "1px solid",
  borderColor: theme.palette.divider,
  borderRadius: "8px",
  bgcolor: "inherit",
}));

const StyledTable = styled(DivTable)(({ theme }) => ({
  borderColor: theme.palette["Gray/Shades"].p8,
}));

export type QuestionTableForRemoteProps = {
  rows: QuestionTableRowProps[];
  sortField: QuestionTableHeaderProps["sortField"];
  resultText: QuestionTableHeaderProps["resultText"];
  showLeakScore: QuestionTableHeaderProps["showLeakScore"];
  showDifficulty: QuestionTableHeaderProps["showDifficulty"];
  pagination?: TablePaginationProps;
};

const QuestionTableForRemote: React.FC<QuestionTableForRemoteProps> = props => {
  const tablePaginationProps: TablePaginationProps | undefined = props.pagination
    ? {
        ...props.pagination,
      }
    : undefined;

  const questionTableHeaderProps: QuestionTableHeaderProps = {
    resultText: props.resultText,
    sortField: props.sortField,
    showLeakScore: props.showLeakScore,
    hasAction: false,
    showDifficulty: props.showDifficulty,
  };

  return (
    <StyledTableContainer>
      <StyledTable>
        <QuestionTableHeader {...questionTableHeaderProps} />
        <DivTableBody>
          {props.rows.map(tableRow => (
            <QuestionTableRow key={tableRow.id} {...tableRow} />
          ))}
        </DivTableBody>
      </StyledTable>
      {tablePaginationProps && <TablePagination {...tablePaginationProps} />}
    </StyledTableContainer>
  );
};

QuestionTableForRemote.displayName = "QuestionTableForRemote";

export default QuestionTableForRemote;
