import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import QuizPackageDetailProvider from "../../../widget/e/QuizPackageDetail/Provider";
import QuizQuestionEditorProvider from "../../../widget/e/QuizQuestionEditor/Provider";
import Container, { QuizResourceEditorContainerProps } from "./Container";

export type QuizResourceEditorMiddleContainerProps = {};

const QuizResourceEditorMiddleContainer: React.FC<QuizResourceEditorMiddleContainerProps> = () => {
  const containerProps: QuizResourceEditorContainerProps = {};
  return (
    <ErrorBoundary>
      <QuizPackageDetailProvider type="UPDATE">
        <QuizQuestionEditorProvider>
          <Container {...containerProps} />
        </QuizQuestionEditorProvider>
      </QuizPackageDetailProvider>
    </ErrorBoundary>
  );
};

QuizResourceEditorMiddleContainer.displayName = "QuizResourceEditorMiddleContainer";

export default withErrorBoundary(QuizResourceEditorMiddleContainer, {});
