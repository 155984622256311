import { BillingUsages } from "@hireroo/app-store/widget/e/BillingUsages";
import { formatPrice } from "@hireroo/formatter/money";
import { dateFormat, unixToDateFormat } from "@hireroo/formatter/time";
import type * as Graphql from "@hireroo/graphql/client/urql";
import type { Widget } from "@hireroo/presentation";

const interviewTypeMap: Record<Graphql.InterviewType, Widget.UsagesProps["rows"][0]["interviewType"]> = {
  SPOT: "SCREENING_TEST",
  REMOTE: "LIVE_CODING",
  /** Logically, this interview type will not be returned from GraphQL. */
  UNKNOWN: "SCREENING_TEST",
};

export type GenerateUsagesPropsArgs = {};

export const useGenerateProps = (_args: GenerateUsagesPropsArgs): Widget.UsagesProps => {
  const usages = BillingUsages.useSubscribeUsages();
  const count = BillingUsages.useUsageCount();
  const usagePeriodStartSeconds = BillingUsages.useUsagePeriodStartSeconds();
  const periods = BillingUsages.usePeriods();
  const fetchSize = BillingUsages.useFetchSize();
  const page = BillingUsages.usePage();

  return {
    rows: usages.map((usage): Widget.UsagesProps["rows"][0] => {
      return {
        amountOfMoney: formatPrice(usage.price ?? 0),
        testId: usage.interviewId,
        usedDate: unixToDateFormat(usage.createdAtSeconds ?? 0),
        interviewType: interviewTypeMap[usage.interviewType],
      };
    }),
    selectBox: {
      value: usagePeriodStartSeconds,
      onChange: event => {
        BillingUsages.updateUsagePeriodStartSeconds(Number(event.target.value));
      },
      items: periods.map(period => {
        return {
          text: [dateFormat(period.from), dateFormat(period.to)].join(" 〜 "),
          value: period.fromSeconds,
        };
      }),
    },
    pagination: {
      count: count,
      page: page,
      rowsPerPage: fetchSize,
      rowsPerPageOptions: [fetchSize],
      onPageChange: (_, value) => {
        BillingUsages.setPage(value);
      },
    },
  };
};
