import { Widget } from "@hireroo/presentation";

export type GenerateCandidateNavigationPropsArgs = {};

export const useGenerateProps = (_args: GenerateCandidateNavigationPropsArgs): Widget.CandidateNavigationProps => {
  return {
    item: {
      type: "LANGUAGE_ONLY",
    },
  };
};
