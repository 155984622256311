import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledListItem = styled(ListItem)(() => ({
  padding: 0,
  marginBottom: "8px",
}));

const StyledListItemText = styled(ListItemText)(() => ({
  margin: 0,
}));

export type ListContentProps = {
  title: string;
  children: string;
};

const ListContent: React.FC<ListContentProps> = props => {
  return (
    <StyledListItem>
      <StyledListItemText
        primary={
          <Grid container wrap="nowrap" alignItems="center">
            <Grid item xs={4}>
              <Typography variant="subtitle1" color="textSecondary" fontSize={14} noWrap>
                {props.title}
              </Typography>
            </Grid>
            <Grid item xs={8} sx={{ overflowWrap: "anywhere" }}>
              <Typography fontSize={14} noWrap>
                {props.children}
              </Typography>
            </Grid>
          </Grid>
        }
      />
    </StyledListItem>
  );
};

ListContent.displayName = "ListContent";

export default ListContent;
