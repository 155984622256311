import { state } from "./State";
import type * as Types from "./types";

export const addEmployees = (employees: Types.Employee[]): void => {
  employees.forEach(employee => {
    state.employees.set(employee.uid, employee);
  });
};

export const addEmployeeGroups = (employeeGroups: Types.EmployeeGroup[]): void => {
  employeeGroups.forEach(employeeGroup => {
    state.employeeGroups.set(employeeGroup.employeeGroupId, employeeGroup);
  });
};

export const clear = (): void => {
  state.employeeGroups.clear();
  state.employees.clear();
};
