/* eslint no-prototype-builtins: 0 */
import { resolveLanguage } from "@hireroo/i18n/utils";
import * as Sentry from "@sentry/react";
import * as uuid from "uuid";

import { NETWORK_SIDE_LENGTH, NODE_SIDE_LENGTH } from "../constants/flowChart";
import {
  AdjustPosition,
  Area,
  CACHE_POLICY,
  CacheSettings,
  CommentElement,
  CommentSettings,
  COMPONENT_TYPE,
  ComponentType,
  Coordinate,
  DefaultSettings,
  DIRECTION,
  EdgeElement,
  EdgeSettings,
  ELEMENT_LABEL,
  ELEMENT_TYPE,
  ElementDataKeyMap,
  ElementLabel,
  ElementMasterData,
  ElementType,
  FLOW_ACTION,
  FlowAction,
  FlowElement,
  FlowSnapshot,
  GraphPattern,
  NetworkElement,
  NodeElement,
  QUEUE_TYPE,
  QueueSettings,
  REPLICATION,
  Settings,
  SqlSettings,
  TypeNetwork,
  TypeNode,
  UserState,
  VmSettings,
} from "../features";

// Convert absolute coordinates to relative coordinates in svg viewbox
export const svgTransform = (svgElm: SVGSVGElement, absoluteCoord: Coordinate): Coordinate | undefined => {
  const ctm = svgElm.getScreenCTM?.();
  if (!ctm) {
    return undefined;
  }

  const p = svgElm.createSVGPoint();
  p.x = absoluteCoord.x;
  p.y = absoluteCoord.y;
  const transformedCoord = p.matrixTransform(ctm.inverse());

  return { x: transformedCoord.x, y: transformedCoord.y };
};

export const adjustorsFromElement = (
  element: NodeElement | NetworkElement,
): {
  leftTop: Coordinate;
  topMid: Coordinate;
  rightTop: Coordinate;
  rightMid: Coordinate;
  rightBottom: Coordinate;
  bottomMid: Coordinate;
  leftBottom: Coordinate;
  leftMid: Coordinate;
} => {
  const {
    geometry: { minX, minY, maxX, maxY },
  } = element;

  return {
    leftTop: { x: minX, y: minY },
    topMid: { x: (minX + maxX) / 2, y: minY },
    rightTop: { x: maxX, y: minY },
    rightMid: { x: maxX, y: (minY + maxY) / 2 },
    rightBottom: { x: maxX, y: maxY },
    bottomMid: { x: (minX + maxX) / 2, y: maxY },
    leftBottom: { x: minX, y: maxY },
    leftMid: { x: minX, y: (minY + maxY) / 2 },
  };
};

export const adjustorFromPosition = (element: NodeElement | NetworkElement, position: AdjustPosition): Coordinate => {
  const adjustors = adjustorsFromElement(element);

  switch (position) {
    case "leftTop":
      return adjustors.leftTop;
    case "topMid":
      return adjustors.topMid;
    case "rightTop":
      return adjustors.rightTop;
    case "rightMid":
      return adjustors.rightMid;
    case "rightBottom":
      return adjustors.rightBottom;
    case "bottomMid":
      return adjustors.bottomMid;
    case "leftBottom":
      return adjustors.leftBottom;
    case "leftMid":
      return adjustors.leftMid;
  }
};

export const geometryFromElement = (element: NodeElement | NetworkElement | CommentElement): Area => element.geometry;

export const getInitialSettings = (label: ElementLabel, componentType: ComponentType, lang: string): Settings => {
  switch (label) {
    case ELEMENT_LABEL.vpc:
      return {
        name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
      };
    case ELEMENT_LABEL.subnet:
      return {
        name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
      };
    case ELEMENT_LABEL.availabilityZone:
      return {
        name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
      };
    case ELEMENT_LABEL.region:
      return {
        name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
      };
    case ELEMENT_LABEL.user:
      return {
        name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
      };
    case ELEMENT_LABEL.dns:
      return {
        name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
      };
    case ELEMENT_LABEL.vm:
      return {
        name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
        autoScale: false,
      };
    case ELEMENT_LABEL.serverless:
      return {
        name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
      };
    case ELEMENT_LABEL.loadBalancer:
      return {
        name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
      };
    case ELEMENT_LABEL.cache:
      return {
        name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
        cachePolicy: CACHE_POLICY.ttl,
      };
    case ELEMENT_LABEL.sql:
      return {
        name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
        enableReplication: false,
        replication: REPLICATION.primary,
        enableSharding: false,
      };
    case ELEMENT_LABEL.nosql:
      return {
        name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
      };
    case ELEMENT_LABEL.elasticsearch:
      return {
        name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
      };
    case ELEMENT_LABEL.storage:
      return {
        name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
      };
    case ELEMENT_LABEL.imageRegistry:
      return {
        name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
      };
    case ELEMENT_LABEL.cdn:
      return {
        name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
      };
    case ELEMENT_LABEL.queue:
      return {
        name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
        queueType: QUEUE_TYPE.standard,
      };
    case ELEMENT_LABEL.pubsub:
      return {
        name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
      };
    case ELEMENT_LABEL.scheduler:
      return {
        name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
      };
  }
};

export const getInitialWidthAndHeight = (label: ElementLabel): { w: number; h: number } => {
  switch (label) {
    case ELEMENT_LABEL.vpc:
      return { w: NETWORK_SIDE_LENGTH, h: NETWORK_SIDE_LENGTH };
    case ELEMENT_LABEL.subnet:
      return { w: NETWORK_SIDE_LENGTH, h: NETWORK_SIDE_LENGTH };
    case ELEMENT_LABEL.availabilityZone:
      return { w: NETWORK_SIDE_LENGTH, h: NETWORK_SIDE_LENGTH };
    case ELEMENT_LABEL.region:
      return { w: NETWORK_SIDE_LENGTH, h: NETWORK_SIDE_LENGTH };
    case ELEMENT_LABEL.user:
      return { w: NODE_SIDE_LENGTH, h: NODE_SIDE_LENGTH };
    case ELEMENT_LABEL.dns:
      return { w: NODE_SIDE_LENGTH, h: NODE_SIDE_LENGTH };
    case ELEMENT_LABEL.vm:
      return { w: NODE_SIDE_LENGTH, h: NODE_SIDE_LENGTH };
    case ELEMENT_LABEL.serverless:
      return { w: NODE_SIDE_LENGTH, h: NODE_SIDE_LENGTH };
    case ELEMENT_LABEL.loadBalancer:
      return { w: NODE_SIDE_LENGTH, h: NODE_SIDE_LENGTH };
    case ELEMENT_LABEL.cache:
      return { w: NODE_SIDE_LENGTH, h: NODE_SIDE_LENGTH };
    case ELEMENT_LABEL.sql:
      return { w: NODE_SIDE_LENGTH, h: NODE_SIDE_LENGTH };
    case ELEMENT_LABEL.nosql:
      return { w: NODE_SIDE_LENGTH, h: NODE_SIDE_LENGTH };
    case ELEMENT_LABEL.elasticsearch:
      return { w: NODE_SIDE_LENGTH, h: NODE_SIDE_LENGTH };
    case ELEMENT_LABEL.storage:
      return { w: NODE_SIDE_LENGTH, h: NODE_SIDE_LENGTH };
    case ELEMENT_LABEL.imageRegistry:
      return { w: NODE_SIDE_LENGTH, h: NODE_SIDE_LENGTH };
    case ELEMENT_LABEL.cdn:
      return { w: NODE_SIDE_LENGTH, h: NODE_SIDE_LENGTH };
    case ELEMENT_LABEL.queue:
      return { w: NODE_SIDE_LENGTH, h: NODE_SIDE_LENGTH };
    case ELEMENT_LABEL.pubsub:
      return { w: NODE_SIDE_LENGTH, h: NODE_SIDE_LENGTH };
    case ELEMENT_LABEL.scheduler:
      return { w: NODE_SIDE_LENGTH, h: NODE_SIDE_LENGTH };
  }
};

export type AddElementArgs = {
  id: string;
  type: ElementType;
  label: ElementLabel;
  initialSettings: Settings;
  geometry: Area;
  timestamp: number;
};

export const createElement = ({ id, type, label, geometry, initialSettings, timestamp }: AddElementArgs): NodeElement | NetworkElement => {
  switch (label) {
    case ELEMENT_LABEL.vpc:
      return {
        id,
        type: type as TypeNetwork,
        label,
        geometry,
        settings: initialSettings as DefaultSettings,
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.subnet:
      return {
        id,
        type: type as TypeNetwork,
        label,
        geometry,
        settings: initialSettings as DefaultSettings,
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.availabilityZone:
      return {
        id,
        type: type as TypeNetwork,
        label,
        geometry,
        settings: initialSettings as DefaultSettings,
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.region:
      return {
        id,
        type: type as TypeNetwork,
        label,
        geometry,
        settings: initialSettings as DefaultSettings,
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.user:
      return {
        id,
        type: type as TypeNode,
        label,
        geometry,
        settings: initialSettings as DefaultSettings,
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.dns:
      return {
        id,
        type: type as TypeNode,
        label,
        geometry,
        settings: initialSettings as DefaultSettings,
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.vm:
      return {
        id,
        type: type as TypeNode,
        label,
        geometry,
        settings: initialSettings as VmSettings,
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.serverless:
      return {
        id,
        type: type as TypeNode,
        label,
        geometry,
        settings: initialSettings as DefaultSettings,
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.loadBalancer:
      return {
        id,
        type: type as TypeNode,
        label,
        geometry,
        settings: initialSettings as DefaultSettings,
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.cache:
      return {
        id,
        type: type as TypeNode,
        label,
        geometry,
        settings: initialSettings as CacheSettings,
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.sql:
      return {
        id,
        type: type as TypeNode,
        label,
        geometry,
        settings: initialSettings as SqlSettings,
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.nosql:
      return {
        id,
        type: type as TypeNode,
        label,
        geometry,
        settings: initialSettings as DefaultSettings,
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.elasticsearch:
      return {
        id,
        type: type as TypeNode,
        label,
        geometry,
        settings: initialSettings as DefaultSettings,
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.storage:
      return {
        id,
        type: type as TypeNode,
        label,
        geometry,
        settings: initialSettings as DefaultSettings,
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.imageRegistry:
      return {
        id,
        type: type as TypeNode,
        label,
        geometry,
        settings: initialSettings as DefaultSettings,
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.cdn:
      return {
        id,
        type: type as TypeNode,
        label,
        geometry,
        settings: initialSettings as DefaultSettings,
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.queue:
      return {
        id,
        type: type as TypeNode,
        label,
        geometry,
        settings: initialSettings as QueueSettings,
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.pubsub:
      return {
        id,
        type: type as TypeNode,
        label,
        geometry,
        settings: initialSettings as DefaultSettings,
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.scheduler:
      return {
        id,
        type: type as TypeNode,
        label,
        geometry,
        settings: initialSettings as DefaultSettings,
        updatedAt: timestamp,
      };
  }
};

export const createComment = (id: string, content: string, minX: number, minY: number, fontSize: number, timestamp: number): CommentElement => {
  return {
    id,
    type: ELEMENT_TYPE.comment,
    content,
    geometry: { minX, minY, maxX: 0, maxY: 0 },
    settings: {
      fontSize,
    },
    updatedAt: timestamp,
  };
};

export const createEdge = (id: string, source: string, target: string, timestamp: number): EdgeElement => {
  return {
    id,
    type: ELEMENT_TYPE.edge,
    source,
    target,
    settings: {
      direction: DIRECTION.undirectional,
    },
    updatedAt: timestamp,
  };
};

const isValidHistory = (history: FlowAction): boolean => {
  switch (history.s) {
    case FLOW_ACTION.addElement:
      return (
        history.hasOwnProperty("v") &&
        history.v.hasOwnProperty("id") &&
        history.v.hasOwnProperty("l") &&
        history.v.hasOwnProperty("x") &&
        history.v.hasOwnProperty("y") &&
        history.v.hasOwnProperty("w") &&
        history.v.hasOwnProperty("h") &&
        history.v.hasOwnProperty("s")
      );
    case FLOW_ACTION.addComment:
      return (
        history.hasOwnProperty("v") &&
        history.v.hasOwnProperty("id") &&
        history.v.hasOwnProperty("c") &&
        history.v.hasOwnProperty("x") &&
        history.v.hasOwnProperty("y") &&
        history.v.hasOwnProperty("f")
      );
    case FLOW_ACTION.editComment:
      return history.hasOwnProperty("v") && history.v.hasOwnProperty("id") && history.v.hasOwnProperty("c");
    case FLOW_ACTION.moveElements:
      return history.hasOwnProperty("v") && history.v.hasOwnProperty("ids");
    case FLOW_ACTION.shapeElement:
      return (
        history.hasOwnProperty("v") &&
        history.v.hasOwnProperty("id") &&
        history.v.hasOwnProperty("p") &&
        history.v.hasOwnProperty("dx") &&
        history.v.hasOwnProperty("dy")
      );
    case FLOW_ACTION.deleteElements:
      return history.hasOwnProperty("v") && history.v.hasOwnProperty("ids");
    case FLOW_ACTION.reviveElements:
      return history.hasOwnProperty("v") && history.v.hasOwnProperty("ids");
    case FLOW_ACTION.connectNodes:
      return history.hasOwnProperty("v") && history.v.hasOwnProperty("id") && history.v.hasOwnProperty("s") && history.v.hasOwnProperty("t");
    case FLOW_ACTION.reconnectEdge:
      return history.hasOwnProperty("v") && history.v.hasOwnProperty("id") && history.v.hasOwnProperty("s") && history.v.hasOwnProperty("t");
    case FLOW_ACTION.pasteElements:
      return history.hasOwnProperty("v") && history.v.hasOwnProperty("dst");
    case FLOW_ACTION.updateSettings:
      return history.hasOwnProperty("v") && history.v.hasOwnProperty("id") && history.v.hasOwnProperty("u");
    // in case of 'useh' or 'subq'
    default:
      return true;
  }
};

export const elementsFromFirebase = (
  histories: FlowAction[],
  componentType: ComponentType,
  initialElements: FlowElement[] = [],
  at?: number,
): { elements: Record<string, FlowElement>; elementIds: string[] } => {
  let elements: Record<string, FlowElement> = {};
  let elementIds: string[] = [];

  if (initialElements) {
    initialElements.forEach(element => {
      elements[element.id] = element;
      elementIds.push(element.id);
    });
  }

  const duplicateEdgeIds: string[] = [];
  const sliced = at !== undefined ? histories.slice(0, at + 1) : histories;

  sliced.forEach(history => {
    // Skip the process when the history is invalid, otherwise app crashes
    if (!isValidHistory(history)) {
      Sentry.captureException(`failed to parse history ${history.s}`);
      return;
    }

    switch (history.s) {
      case FLOW_ACTION.addElement: {
        const element = createElement({
          id: history.v.id,
          type: history.v.t,
          label: history.v.l,
          geometry: { minX: history.v.x, minY: history.v.y, maxX: history.v.x + history.v.w, maxY: history.v.y + history.v.h },
          initialSettings: history.v.s,
          timestamp: history.t,
        });
        elements[element.id] = element;
        elementIds.push(element.id);
        return;
      }
      case FLOW_ACTION.addComment: {
        const comment = createComment(history.v.id, history.v.c, history.v.x, history.v.y, history.v.f, history.t);
        elements[comment.id] = comment;
        elementIds.push(comment.id);
        return;
      }
      case FLOW_ACTION.editComment: {
        const comment = elements[history.v.id] as CommentElement;
        elements[history.v.id] = { ...comment, content: history.v.c, updatedAt: history.t };
        return;
      }
      case FLOW_ACTION.moveElements: {
        elementIds.forEach(id => {
          const element = elements[id];
          if (history.v.ids.includes(element.id) && element.type !== ELEMENT_TYPE.edge) {
            elements[id] = {
              ...element,
              geometry: {
                minX: element.geometry.minX + history.v.dx,
                minY: element.geometry.minY + history.v.dy,
                maxX: element.geometry.maxX + history.v.dx,
                maxY: element.geometry.maxY + history.v.dy,
              },
            };
          }
        });
        return;
      }
      case FLOW_ACTION.shapeElement: {
        const element = elements[history.v.id];
        if (element.type !== ELEMENT_TYPE.network) return;

        let geometry = element.geometry;

        switch (history.v.p) {
          case "leftTop":
            geometry = { ...geometry, minX: geometry.minX + history.v.dx, minY: geometry.minY + history.v.dy };
            break;
          case "rightTop":
            geometry = { ...geometry, maxX: geometry.maxX + history.v.dx, minY: geometry.minY + history.v.dy };
            break;
          case "rightBottom":
            geometry = { ...geometry, maxX: geometry.maxX + history.v.dx, maxY: geometry.maxY + history.v.dy };
            break;
          case "leftBottom":
            geometry = { ...geometry, minX: geometry.minX + history.v.dx, maxY: geometry.maxY + history.v.dy };
            break;
          case "leftMid":
            geometry = { ...geometry, minX: geometry.minX + history.v.dx };
            break;
          case "topMid":
            geometry = { ...geometry, minY: geometry.minY + history.v.dy };
            break;
          case "rightMid":
            geometry = { ...geometry, maxX: geometry.maxX + history.v.dx };
            break;
          case "bottomMid":
            geometry = { ...geometry, maxY: geometry.maxY + history.v.dy };
            break;
        }

        elements[history.v.id] = { ...element, geometry };
        return;
      }
      case FLOW_ACTION.deleteElements: {
        elementIds = elementIds.filter(elementId => {
          const element = elements[elementId];
          const isRemovedElement = history.v.ids.includes(elementId);
          const isNeighborEdge =
            element.type === ELEMENT_TYPE.edge && (history.v.ids.includes(element.source) || history.v.ids.includes(element.target));
          return !isRemovedElement && !isNeighborEdge;
        });
        return;
      }
      case FLOW_ACTION.reviveElements: {
        history.v.ids.forEach(id => {
          if (!elementIds.includes(id) && elements[id]) {
            const concatIds = [...history.v.ids, ...elementIds];
            const element = elements[id];
            // Skip the operation when trying to revive the edge with nodes deleted by a peer
            if (element.type === ELEMENT_TYPE.edge && (!concatIds.includes(element.source) || !concatIds.includes(element.target))) {
              return;
            }
            elementIds.push(id);
          }
        });
        return;
      }
      case FLOW_ACTION.connectNodes: {
        elements[history.v.id] = createEdge(history.v.id, history.v.s, history.v.t, history.t);

        // When the source or target node does not exist, skip the subsequent process
        if (!elementIds.includes(history.v.s) || !elementIds.includes(history.v.t)) return;

        // Check if the duplicate edge exists or not
        let duplicateEdge: EdgeElement | undefined;
        elementIds.forEach(elementId => {
          const element = elements[elementId];
          if (element.type === ELEMENT_TYPE.edge) {
            if (
              (element.source === history.v.s && element.target === history.v.t) ||
              (element.source === history.v.t && element.target === history.v.s)
            ) {
              duplicateEdge = element;
            }
          }
        });

        // If the duplicate node exists, last one wins
        if (duplicateEdge) {
          // Discard the incoming change since the existing edge is the latest
          if (duplicateEdge.updatedAt > history.t) {
            duplicateEdgeIds.push(history.v.id);
            return;
          } else {
            // Swap the old edge and the new edge since the incoming change is the latest
            const duplicateEdgeId = duplicateEdge.id;
            elementIds = elementIds.filter(id => id !== duplicateEdgeId);
            duplicateEdgeIds.push(duplicateEdgeId);
            elementIds.push(history.v.id);
          }
        } else {
          elementIds.push(history.v.id);
        }
        return;
      }
      case FLOW_ACTION.reconnectEdge: {
        const edge = elements[history.v.id] as EdgeElement;

        // Discard the incoming change since the local edge is the latest
        if (edge.updatedAt > history.t) return;

        // If the duplicate edge is reconnected, it will not be duplicate, hence revive the edge id
        if (duplicateEdgeIds.includes(edge.id)) {
          duplicateEdgeIds.filter(id => id !== edge.id);
          elementIds.push(edge.id);
        }

        elements[history.v.id] = {
          ...edge,
          source: history.v.s,
          target: history.v.t,
          updatedAt: history.t,
        };

        // When the source or target node does not exist, delete the reconnecting edge id from the array
        if (!elementIds.includes(history.v.s) || !elementIds.includes(history.v.t)) {
          elementIds = elementIds.filter(id => id !== edge.id);
          return;
        }

        // Check if a duplicate edge exists or not
        let duplicateEdge: EdgeElement | undefined;
        elementIds.forEach(elementId => {
          const element = elements[elementId];
          if (element.type === ELEMENT_TYPE.edge) {
            if (
              element.id !== edge.id &&
              ((element.source === history.v.s && element.target === history.v.t) ||
                (element.source === history.v.t && element.target === history.v.s))
            ) {
              duplicateEdge = element;
            }
          }
        });

        // If a duplicate edge exists, last one wins
        if (duplicateEdge) {
          // Delete the reconnecting edge from the array since the connected one is the latest
          if (duplicateEdge.updatedAt > history.t) {
            elementIds = elementIds.filter(id => id !== history.v.id);
            duplicateEdgeIds.push(history.v.id);
            return;
          } else {
            // Delete the connected edge from the array since the reconnecting one is the latest
            const duplicateEdgeId = duplicateEdge.id;
            elementIds = elementIds.filter(id => id !== duplicateEdgeId);
            duplicateEdgeIds.push(duplicateEdgeId);
          }
        }
        return;
      }
      case FLOW_ACTION.pasteElements: {
        history.v.dst.forEach(element => {
          elementIds.push(element.id);
          elements[element.id] = element;
        });
        return;
      }
      case FLOW_ACTION.updateSettings: {
        const updatedElement = elements[history.v.id];
        if (updatedElement.type === ELEMENT_TYPE.comment) {
          const updates = history.v.u as Partial<CommentSettings>;
          elements[history.v.id] = { ...updatedElement, settings: { ...updatedElement.settings, ...updates }, updatedAt: history.t };
        } else if (updatedElement.type === ELEMENT_TYPE.edge) {
          const updates = history.v.u as Partial<EdgeSettings>;
          elements[history.v.id] = { ...updatedElement, settings: { ...updatedElement.settings, ...updates }, updatedAt: history.t };
        } else if (updatedElement.type === ELEMENT_TYPE.node && updatedElement.label === ELEMENT_LABEL.vm) {
          const updates = history.v.u as Partial<VmSettings>;
          let geometry = updatedElement.geometry;
          if (updates.hasOwnProperty("autoScale")) {
            if (updates.autoScale) {
              geometry = {
                ...geometry,
                minX: geometry.minX - 20,
                minY: geometry.minY - 20,
                maxX: geometry.maxX + 20,
                maxY: geometry.maxY + 20,
              };
            } else {
              geometry = {
                ...geometry,
                minX: geometry.minX + 20,
                minY: geometry.minY + 20,
                maxX: geometry.maxX - 20,
                maxY: geometry.maxY - 20,
              };
            }
          }
          elements[history.v.id] = { ...updatedElement, geometry, settings: { ...updatedElement.settings, ...updates }, updatedAt: history.t };
        } else if (updatedElement.type === ELEMENT_TYPE.node && updatedElement.label === ELEMENT_LABEL.sql) {
          const updates = history.v.u as Partial<SqlSettings>;
          elements[history.v.id] = { ...updatedElement, settings: { ...updatedElement.settings, ...updates }, updatedAt: history.t };
        } else if (updatedElement.type === ELEMENT_TYPE.node && updatedElement.label === ELEMENT_LABEL.queue) {
          const updates = history.v.u as Partial<QueueSettings>;
          elements[history.v.id] = { ...updatedElement, settings: { ...updatedElement.settings, ...updates }, updatedAt: history.t };
        } else if (updatedElement.type === ELEMENT_TYPE.node && updatedElement.label === ELEMENT_LABEL.cache) {
          const updates = history.v.u as Partial<CacheSettings>;
          elements[history.v.id] = { ...updatedElement, settings: { ...updatedElement.settings, ...updates }, updatedAt: history.t };
        } else {
          const updates = history.v.u as Partial<DefaultSettings>;
          elements[history.v.id] = { ...updatedElement, settings: { ...updatedElement.settings, ...updates }, updatedAt: history.t };
        }
        return;
      }
      case FLOW_ACTION.resetElements: {
        const initialElementMap: Record<string, FlowElement> = {};
        const initialElementIds: string[] = [];
        initialElements.forEach(element => {
          initialElementMap[element.id] = element;
          initialElementIds.push(element.id);
        });
        elements = initialElementMap;
        elementIds = initialElementIds;
        return;
      }
      default:
        return;
    }
  });

  return { elements, elementIds };
};

export const userStateFromFirebase = (history: FlowAction): UserState => {
  const userState = {
    uid: "",
    edit: "",
    color: "",
    cursor: { x: 0, y: 0 },
    name: "",
    select: [],
  } as UserState;

  // Skip the process when the history is invalid, otherwise app crashes
  if (!isValidHistory(history)) {
    return userState;
  }

  userState.uid = history.a;
  switch (history.s) {
    case FLOW_ACTION.addElement:
    case FLOW_ACTION.addComment:
    case FLOW_ACTION.editComment:
    case FLOW_ACTION.shapeElement:
    case FLOW_ACTION.connectNodes:
    case FLOW_ACTION.reconnectEdge:
    case FLOW_ACTION.updateSettings: {
      userState.edit = history.v.id;
      break;
    }
    case FLOW_ACTION.moveElements:
    case FLOW_ACTION.deleteElements:
    case FLOW_ACTION.reviveElements: {
      userState.select = history.v.ids;
      break;
    }
  }

  return userState;
};

export const viewboxFromElements = (elements: FlowElement[], aspectRatio: number, viewarea?: Area, padding = 1): Area => {
  let minX = Number.MAX_SAFE_INTEGER;
  let minY = Number.MAX_SAFE_INTEGER;
  let maxX = Number.MIN_SAFE_INTEGER;
  let maxY = Number.MIN_SAFE_INTEGER;

  // Calculated the minimum area
  elements.forEach(element => {
    if (element.type === ELEMENT_TYPE.node || element.type === ELEMENT_TYPE.network) {
      minX = Math.min(minX, element.geometry.minX);
      minY = Math.min(minY, element.geometry.minY);
      maxX = Math.max(maxX, element.geometry.maxX);
      maxY = Math.max(maxY, element.geometry.maxY);
    }

    // Comment has only minX and minY since it's box-size is calculated by its length or fontSize dynamically,
    // hence ignore maxX and maxY here
    if (element.type === ELEMENT_TYPE.comment) {
      minX = Math.min(minX, element.geometry.minX);
      minY = Math.min(minY, element.geometry.minY);
    }
  });

  if (viewarea) {
    minX = Math.min(minX, viewarea.minX);
    minY = Math.min(minY, viewarea.minY);
    maxX = Math.max(maxX, viewarea.maxX);
    maxY = Math.max(maxY, viewarea.maxY);
  }

  // Return a fixed value as viewbox when no element exists
  if (maxX - minX < 0) {
    return { minX: 0, minY: 0, maxX: 2000, maxY: 1500 };
  }

  // Extend width to avoid over-expansion when only a single element exists
  if (maxX - minX < 640) {
    const buffer = (640 - (maxX - minX)) / 2;
    minX -= buffer;
    maxX += buffer;
  }

  let calculatedViewbox = { minX: 0, minY: 0, maxX: 0, maxY: 0 };

  // Calculate whether width is over or height is over based on the aspect ratio
  const isWide = maxX - minX - (maxY - minY) / aspectRatio > 0;

  if (isWide) {
    const addedHeight = (maxX - minX) * aspectRatio - (maxY - minY);
    calculatedViewbox = {
      minX: minX,
      minY: minY - addedHeight / 2,
      maxX: maxX,
      maxY: maxY + addedHeight / 2,
    };
  } else {
    const addedWidth = (maxY - minY) / aspectRatio - (maxX - minX);
    calculatedViewbox = {
      minX: minX - addedWidth / 2,
      minY: minY,
      maxX: maxX + addedWidth / 2,
      maxY: maxY,
    };
  }

  if (padding) {
    calculatedViewbox.minX -= (maxX - minX) * padding * 0.1;
    calculatedViewbox.minY -= (maxX - minX) * aspectRatio * padding * 0.1;
    calculatedViewbox.maxX += (maxX - minX) * padding * 0.1;
    calculatedViewbox.maxY += (maxX - minX) * aspectRatio * padding * 0.1;
  }

  return calculatedViewbox;
};

export const edgeCoordinatesFromNodes = (source: NodeElement, target: NodeElement): { from: Coordinate; to: Coordinate } => {
  const sourceAdaptors = adjustorsFromElement(source);
  const targetAdaptors = adjustorsFromElement(target);

  const sourceCenter = {
    x: (source.geometry.minX + source.geometry.maxX) / 2,
    y: (source.geometry.minY + source.geometry.maxY) / 2,
  };
  const targetCenter = {
    x: (target.geometry.minX + target.geometry.maxX) / 2,
    y: (target.geometry.minY + target.geometry.maxY) / 2,
  };
  // In the svg coordinates, y gets smaller as you go up, hence reverse source and target order
  const radian = Math.atan2(sourceCenter.y - targetCenter.y, targetCenter.x - sourceCenter.x);

  let from: Coordinate;
  let to: Coordinate;

  if (Math.PI / 8 <= radian && radian < (Math.PI * 3) / 8) {
    from = sourceAdaptors.rightTop;
    to = targetAdaptors.leftBottom;
  } else if ((Math.PI * 3) / 8 <= radian && radian < (Math.PI * 5) / 8) {
    from = sourceAdaptors.topMid;
    to = targetAdaptors.bottomMid;
  } else if ((Math.PI * 5) / 8 <= radian && radian < (Math.PI * 7) / 8) {
    from = sourceAdaptors.leftTop;
    to = targetAdaptors.rightBottom;
  } else if ((Math.PI * 7) / 8 <= radian || radian < -(Math.PI * 7) / 8) {
    from = sourceAdaptors.leftMid;
    to = targetAdaptors.rightMid;
  } else if (-(Math.PI * 7) / 8 <= radian && radian < -(Math.PI * 5) / 8) {
    from = sourceAdaptors.leftBottom;
    to = targetAdaptors.rightTop;
  } else if (-(Math.PI * 5) / 8 <= radian && radian < -(Math.PI * 3) / 8) {
    from = sourceAdaptors.bottomMid;
    to = targetAdaptors.topMid;
  } else if (-(Math.PI * 3) / 8 <= radian && radian < -Math.PI / 8) {
    from = sourceAdaptors.rightBottom;
    to = targetAdaptors.leftTop;
  } else {
    from = sourceAdaptors.rightMid;
    to = targetAdaptors.leftMid;
  }

  return { from, to };
};

export const isWindows = /^Win/.test(window.navigator.platform);

export const generateHashId = (): string => {
  return uuid.v4().replace(/-/g, "").slice(0, 6);
};

export const isValidComponentType = (v: string): v is ComponentType => {
  return ["default", "aws", "gcp", "azure"].includes(v);
};

// WARNING: Be careful to change this function because destructive changes could make it impossible to restore past snapshots
// TODO: Define snapshot schema using zod to be type-safe
export const parseFlowChartSnapshot = (snapshot: string): { ok: boolean; result: FlowSnapshot } => {
  const defaultValue = { ok: false, result: { version: "", componentType: COMPONENT_TYPE.default, elements: [] } };

  // handle empty string to avoid JSON.parse SyntaxError
  if (snapshot === "") {
    return defaultValue;
  }

  try {
    const result = JSON.parse(snapshot) as FlowSnapshot;

    // Old snapshots don't have component type, hence we need to regard it as default type to keep backward compatibility
    if (!result.componentType) {
      result.componentType = COMPONENT_TYPE.default;
    } else {
      const componentType = result.componentType.toLowerCase();
      if (!isValidComponentType(componentType)) {
        result.componentType = COMPONENT_TYPE.default;
      }
    }

    return { ok: true, result };
  } catch (err) {
    Sentry.captureException(err);
    return defaultValue;
  }
};

export const parseGraphlets = (graphlets: string): { ok: boolean; result: GraphPattern } => {
  try {
    return { ok: true, result: JSON.parse(graphlets) as GraphPattern };
  } catch (err) {
    Sentry.captureException(err);
    return { ok: false, result: {} };
  }
};
