import { useTranslation } from "@hireroo/i18n";
import Markdown from "@hireroo/markdown-v2/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette["Secondary/Shades"].p12,
  height: 36,
  minHeight: 36,
  "&.Mui-expanded": {
    height: 36,
    minHeight: 36,
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: theme.palette["Secondary/Shades"].p8,
}));

export type AppealMessageInReportProps = {
  title: string;
  body: string;
};

const AppealMessageInReport: React.FC<AppealMessageInReportProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Accordion defaultExpanded>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Tooltip title={props.title}>
            <InsertDriveFileOutlinedIcon htmlColor={theme.palette["Gray/Shades"].p56} sx={{ fontSize: "16px" }} />
          </Tooltip>
          <Typography variant="body2">{t("説明文")}</Typography>
        </Stack>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <Box height="100%" overflow="auto" p={1} maxHeight={300}>
          <React.Suspense>
            <Markdown children={props.body} size="small" />
          </React.Suspense>
        </Box>
      </StyledAccordionDetails>
    </Accordion>
  );
};

AppealMessageInReport.displayName = "AppealMessageInReport";

export default AppealMessageInReport;
