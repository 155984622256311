import { Participant, RemoteTrack } from "twilio-video";

/**
 * This class subscribes to a participant and calls the onTrackSubscribed callback when the publication is subscribed to
 */
export class ParticipantTrackSubscriber {
  private subscribed = false;

  constructor(
    private participant: Participant,
    private onSubscribed: (track: RemoteTrack) => void,
    private onUnpublished: (track: RemoteTrack) => void,
  ) {
    this.subscribe();
  }

  subscribe() {
    if (this.subscribed) {
      return;
    }
    this.participant.on("trackSubscribed", this.onSubscribed);
    this.participant.on("trackUnsubscribed", this.onUnpublished);

    this.subscribed = true;
  }

  unsubscribe() {
    if (!this.subscribed) {
      return;
    }
    this.participant.off("trackSubscribed", this.onSubscribed);
    this.participant.off("trackUnsubscribed", this.onUnpublished);

    this.subscribed = false;
  }
}
