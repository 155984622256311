import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { ExamTabTablesForTalent } from "@hireroo/app-store/widget/t/ExamTabTablesForTalent";
import { formatScore } from "@hireroo/formatter/score";
import { formatDurationSeconds, unixToDateFormat } from "@hireroo/formatter/time";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import { generatePath, navigate } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/browser";
import * as React from "react";

type TableRowProps = Widget.ExamTabTablesForTalentProps["examTable"]["items"][0];

const examStatusToKind: Record<Graphql.ExamStatus, TableRowProps["status"]["kind"]> = {
  UNKNOWN: "NOT_SUBMITTED",
  CREATED: "NOT_SUBMITTED",
  STARTED: "NOT_SUBMITTED",
  COMPLETED: "UNDER_EVALUATION",
  FINALIZED: "SUBMITTED",
  EXPIRED: "EXPIRED",
};

const SortFieldValue = {
  CREATED_AT_DESCENDING: "created-at-descending",
  CREATED_AT_ASCENDING: "created-at-ascending",
};

export type GenerateExamTabTablesForTalentPropsArgs = {};

export const useGenerateProps = (_args: GenerateExamTabTablesForTalentPropsArgs): Widget.ExamTabTablesForTalentProps => {
  const assessments = ExamTabTablesForTalent.useAssessments();
  const lang = useLanguageCode();
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const transitionNavigate = useTransitionNavigate();
  const currentSelectedAssessmentId = ExamTabTablesForTalent.useCurrentSelectedAssessmentId();
  const currentSelectedAssessment = ExamTabTablesForTalent.useCurrentSelectedAssessment();
  const pager = ExamTabTablesForTalent.usePager();
  const notYetSubmittedExamCount = ExamTabTablesForTalent.useNotYetSubmittedExamCount();
  const client = getGraphqlClient();
  const [status, setStatus] = React.useState<"READY" | "DISABLED_ALL">("READY");
  const [result] = Graphql.useListBestExamsByAssessmentIdForExamListForTalentQuery({
    variables: {
      assessmentId: currentSelectedAssessmentId ?? "",
      withCount: true,
      size: pager.size,
      offset: pager.offset,
      isDescending: pager.isDescending,
    },
    pause: currentSelectedAssessmentId === null,
  });
  const exams = result.data?.listBestExamsByAssessmentId.exams || [];
  const tailExamStatusMap = ExamTabTablesForTalent.useGetTailExamStatusMap();
  const tailExamIdMap = ExamTabTablesForTalent.useTailExamMap();
  const [forceClose, setForceClose] = React.useState(false);

  return {
    notification:
      notYetSubmittedExamCount > 0
        ? {
            count: notYetSubmittedExamCount,
          }
        : undefined,
    showHeader: !!currentSelectedAssessment,
    sortField: {
      disabled: status === "DISABLED_ALL",
      options: [
        {
          displayName: t("作成日時が新しい順"),
          value: SortFieldValue.CREATED_AT_DESCENDING,
        },
        {
          displayName: t("作成日時が古い順"),
          value: SortFieldValue.CREATED_AT_ASCENDING,
        },
      ],
      onChange: value => {
        ExamTabTablesForTalent.updateSortField(value === SortFieldValue.CREATED_AT_DESCENDING);
      },
    },
    meta: {
      status: currentSelectedAssessment?.status === "RUNNING" ? "ACTIVE" : "NOT_ACTIVE",
      nextDateLabel:
        currentSelectedAssessment?.status === "RUNNING" && currentSelectedAssessment?.nextScheduleAtSeconds
          ? unixToDateFormat(currentSelectedAssessment.nextScheduleAtSeconds)
          : undefined,
    },
    assessmentSelect: {
      defaultValue: currentSelectedAssessmentId ?? undefined,
      items: assessments.map(assessment => {
        return {
          showAlert: !assessment.isSubmittedAtLatestInterval && assessment.rootExamIds.length > 0,
          status: assessment.status === "RUNNING" ? "ACTIVE" : "NOT_ACTIVE",
          value: assessment.assessmentId,
          name: assessment.name,
        };
      }),
      onChange: assessmentId => {
        ExamTabTablesForTalent.updateSelectedAssessmentId(assessmentId);
      },
    },
    Loading: result.fetching ? <Widget.Loading kind="CENTER_%" /> : null,
    examTable: {
      tablePagination: {
        count: result.data?.listBestExamsByAssessmentId.count ?? 0,
        page: pager.page,
        onPageChange: (_, nextPage) => {
          ExamTabTablesForTalent.updatePage(nextPage);
        },
        rowsPerPage: pager.size,
        onRowsPerPageChange: event => {
          const newSize = Math.trunc(Number(event.target.value));
          ExamTabTablesForTalent.updatePageSize(newSize);
        },
        isLoading: result.fetching,
      },
      items: exams.map((exam): TableRowProps => {
        const timeRemainingBeforeDeadlineSeconds: number = exam.willEndAtSeconds ? exam.willEndAtSeconds - Date.now() / 1000 : -1;
        return {
          id: exam.examId,
          href: generatePath("/t/assessments/:id/detail", {
            pathParams: {
              id: currentSelectedAssessmentId ?? "",
            },
            queryParams: {
              examId: exam.examId,
            },
          }),
          onClick: () => {
            transitionNavigate("/t/assessments/:id/detail", {
              pathParams: {
                id: currentSelectedAssessmentId ?? "",
              },
              queryParams: {
                examId: exam.examId,
              },
            });
          },
          status: {
            kind: examStatusToKind[exam.status],
          },
          no: t2("numOfExam", {
            num: exam.numInterval,
          }),
          scoreLabel: (() => {
            if (exam.status === "FINALIZED") {
              return `${formatScore(exam.totalScore)}%`;
            }
            return "-";
          })(),
          rank: ((): TableRowProps["rank"] => {
            if (!exam.isReliableRank) {
              return {
                rank: "UNKNOWN",
                tooltip: t("提出時点のランクを表示するためのデータ数が足りないため表示できません。"),
              };
            }
            if (exam.status === "FINALIZED") {
              return {
                rank: exam.rankEvaluation,
              };
            }
            return;
          })(),
          deadlineDateLabel: exam.willEndAtSeconds ? unixToDateFormat(exam.willEndAtSeconds) : "",
          startDateLabel: exam.didStartAtSeconds ? unixToDateFormat(exam.didStartAtSeconds) : "",
          beforeDateLabel:
            timeRemainingBeforeDeadlineSeconds > 0
              ? t2("remainDays", {
                  days: formatDurationSeconds(timeRemainingBeforeDeadlineSeconds, lang),
                })
              : "",
          tryStatus: (() => {
            if (exam.status === "EXPIRED" || (!!timeRemainingBeforeDeadlineSeconds && timeRemainingBeforeDeadlineSeconds <= 0)) {
              return "EXPIRED";
            }
            if (exam.status === "CREATED" || exam.status === "STARTED") {
              return "NOT_TRY";
            }
            const tailExamTryStatus = tailExamStatusMap.get(exam.examId);
            return tailExamTryStatus || "CHECKING_RETRY";
          })(),
          retryButton: {
            status: (() => {
              const tailExamTryStatus = tailExamStatusMap.get(exam.examId);
              return tailExamTryStatus || "CHECKING_RETRY";
            })(),
            onRetry: () => {
              setStatus("DISABLED_ALL");
              client
                .ReCreateExamForExamListForTalent({
                  input: {
                    examId: exam.examId,
                  },
                })
                .then(res => {
                  Snackbar.notify({
                    message: t("再試験の作成に成功しました。"),
                    severity: "success",
                  });
                  setForceClose(true);
                  const url = generatePath("/t/exams/:id", {
                    pathParams: {
                      id: res.recreateExam.examId,
                    },
                  });
                  navigate(url, {
                    _blank: true,
                  });
                })
                .catch(() => {
                  Snackbar.notify({
                    message: t("再試験の作成に失敗しました。しばらくしてから再度お試しください。"),
                    severity: "error",
                  });
                })
                .finally(() => {
                  setStatus("READY");
                });
            },
            onAlreadyExistsRetry: () => {
              const tailExamId = tailExamIdMap.get(exam.examId);
              if (!tailExamId) {
                return;
              }
              const url = generatePath("/t/exams/:id", {
                pathParams: {
                  id: tailExamId,
                },
              });
              navigate(url, {
                _blank: true,
              });
              setForceClose(true);
            },
            onOpenDialog: () => {
              setForceClose(false);
              client
                .GetTailExamForExamListForTalent({
                  examId: exam.examId,
                })
                .then(({ tailExam }) => {
                  ExamTabTablesForTalent.updateTailExamMap(exam.examId, tailExam.examId);
                  if (tailExam.examId === exam.examId) {
                    ExamTabTablesForTalent.updateTailExamStatus(exam.examId, "CAN_RETRY");
                  } else {
                    ExamTabTablesForTalent.updateTailExamStatus(exam.examId, "ALREADY_EXISTS_RETRY");
                  }
                })
                .catch(error => {
                  Sentry.captureException(error);
                });
            },
            onCloseDialog: () => {
              ExamTabTablesForTalent.deleteTailExamStatus(exam.examId);
              ExamTabTablesForTalent.deleteTailExamMap(exam.examId);
            },
            forceClose: forceClose,
          },
          onTry: () => {
            const url = generatePath("/t/exams/:id", {
              pathParams: {
                id: exam.examId,
              },
            });
            navigate(url, {
              _blank: true,
            });
          },
        };
      }),
    },
  };
};
