import { useTranslation } from "@hireroo/i18n";
import { LiveCodingChatInputFormSchema } from "@hireroo/validator";
import SendIcon from "@mui/icons-material/Send";
import { TextFieldProps } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import * as React from "react";

import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "../../../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";
import { useLiveCodingChatWindowContext } from "../../Context";
import { Payload } from "../../types";

const StyledTextField = styled(TextField)(({ theme }) => {
  return {
    ".MuiOutlinedInput-root": {
      paddingLeft: 0,
      paddingRight: 0,
    },
    ".MuiOutlinedInput-input": {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    borderRadius: "4px",
    outline: "none",
    backgroundColor: theme.palette["Secondary/Shades"].p16,
    color: theme.palette.common.white,
    caretColor: theme.palette.common.white,
    "&:hover": {
      outline: "none",
    },
    whiteSpace: "pre",
    overflowWrap: "normal",
    overflow: "hidden",
    width: "100%",
    lineHeight: "2em",
    resize: "none",
  };
});

export type ChatInputProps = {
  onInput: (payload: Payload) => void;
};

const ChatInput: React.FC<ChatInputProps> = props => {
  const { t } = useTranslation();
  const { methods } = useLiveCodingChatWindowContext();
  const [compositionState, setCompositionState] = React.useState<"PROCESSING" | "END">("END");

  const onSubmit = React.useCallback(
    (fields: LiveCodingChatInputFormSchema.LiveCodingChatInputFormSchema) => {
      props.onInput({ message: fields.message });
      methods.reset();
    },
    [methods, props],
  );

  // ensures pressing enter + shift creates a new line, so that enter on its own only sends the message:
  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Enter" && !event.shiftKey) {
        event.preventDefault();
        // If composition is processing, wait for end composition before handle keydown
        if (compositionState !== "END") {
          return;
        }
        methods.handleSubmit(onSubmit)();
      }
    },
    [compositionState, methods, onSubmit],
  );

  const sendButton: IconButtonWithTooltipProps = {
    size: "small",
    title: t("送信"),
    type: "submit",
    disabled: !methods.formState.isValid,
    children: <SendIcon />,
  };

  const textField: TextFieldProps = {
    required: true,
    multiline: true,
    InputProps: {
      endAdornment: (
        <InputAdornment position="end" sx={{ position: "absolute", right: 10, bottom: 20 }}>
          <IconButtonWithTooltip {...sendButton} />
        </InputAdornment>
      ),
      sx: {
        py: 1,
      },
    },
    sx: {
      height: "100%",
      position: "relative",
    },
    size: "small",
    minRows: 1,
    maxRows: 15,
    color: "secondary",
    "aria-label": "chat input",
    placeholder: t("メッセージを送信"),
    ...methods.register("message"),
    onKeyDown: handleKeyDown,
    onCompositionStart: () => {
      setCompositionState("PROCESSING");
    },
    onCompositionEnd: () => {
      // For Safari, wait for end composition before handle keydown
      // TODO: @valbeat note it somewhere document.
      window.setTimeout(() => {
        setCompositionState("END");
      }, 100);
    },
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <StyledTextField {...textField} />
    </form>
  );
};

ChatInput.displayName = "ChatInput";

export default ChatInput;
