import { useTranslation } from "@hireroo/i18n";
import NotificationsNoneOutlined from "@mui/icons-material/NotificationsNoneOutlined";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Popover, { PopoverProps } from "@mui/material/Popover";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Virtuoso, VirtuosoProps } from "react-virtuoso";

import SymbolButton, { SymbolButtonProps } from "../../primitive/Button/SymbolButton/SymbolButton";

const MAX_HEIGHT = 400;
const MAX_WIDTH = 400;
const HEADER_HEIGHT = 32;
const FOOTER_HEIGHT = 50;
const MIN_ITEM_HEIGHT = 98;

const StyledWrapper = styled(Paper)(() => ({
  borderRadius: 8,
  maxWidth: MAX_WIDTH,
  width: MAX_WIDTH,
}));

const AllReadButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 400,
  height: 32,
  minHeight: 32,
}));

const StyledListItemButton = styled(ListItemButton)(() => ({
  paddingTop: 12,
  paddingBottom: 12,
}));

const NoUnreadNotificationTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 16,
  textAlign: "center",
}));

export type ItemProps = {
  id: string;
  title: string;
  distance: string;
  onClick: () => void;
};

type InfiniteScrollableListProps = VirtuosoProps<ItemProps, unknown>;

export type NotificationListProps = {
  count: number;
  items: ItemProps[];
  hasNext: boolean;
  intersectCallback?: () => void;
  allReadButton: Pick<ButtonProps, "onClick">;
  onEndReached: InfiniteScrollableListProps["endReached"];
};

const NotificationList: React.FC<NotificationListProps> = props => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const notificationOpenButtonProps: SymbolButtonProps = {
    onClick: event => {
      setAnchorEl(event.currentTarget);
    },
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const allReadButtonProps: ButtonProps = {
    ...props.allReadButton,
    children: t("全て既読にする"),
    disabled: props.items.length === 0,
  };

  const contentHeight = Math.min(MIN_ITEM_HEIGHT * props.items.length + FOOTER_HEIGHT, MAX_HEIGHT);

  const virtuosoProps: InfiniteScrollableListProps = {
    data: props.items,
    style: { height: contentHeight, maxHeight: MAX_HEIGHT, overflow: "scroll" },
    size: props.count,
    totalCount: props.items.length,
    defaultItemHeight: MIN_ITEM_HEIGHT,
    overscan: {
      main: MIN_ITEM_HEIGHT,
      reverse: MIN_ITEM_HEIGHT,
    },
    increaseViewportBy: {
      top: MIN_ITEM_HEIGHT,
      bottom: MIN_ITEM_HEIGHT,
    },
    itemContent: (index, item) => {
      return (
        <StyledListItemButton key={item.id} onClick={item.onClick} divider={index < props.items.length - 1}>
          <ListItemText primary={item.title} primaryTypographyProps={{ marginBottom: "4px" }} secondary={item.distance} />
        </StyledListItemButton>
      );
    },
    endReached: props.onEndReached,
    components: {
      Footer: () => {
        const displayText = props.hasNext ? t("読み込み中...") : t("通知は以上です。");
        if (props.items.length === 0) {
          return null;
        }
        return (
          <>
            <Divider />
            <ListItem sx={{ paddingBottom: 0 }}>
              <ListItemText primary={displayText} primaryTypographyProps={{ color: "textSecondary", fontSize: 14, textAlign: "right" }} />
            </ListItem>
          </>
        );
      },
    },
  };

  const popoverProps: PopoverProps = {
    open: Boolean(anchorEl),
    anchorEl: anchorEl,
    onClose: handleClose,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "right",
    },
    PaperProps: {
      style: {
        maxHeight: MAX_HEIGHT + HEADER_HEIGHT + FOOTER_HEIGHT,
        overflow: "hidden",
      },
    },
  };

  return (
    <>
      <SymbolButton {...notificationOpenButtonProps}>
        <Badge badgeContent={props.count} max={10} color="secondary">
          <NotificationsNoneOutlined color="secondary" />
        </Badge>
      </SymbolButton>
      <Popover {...popoverProps}>
        <StyledWrapper elevation={24}>
          <List component="nav" sx={{ minWidth: MAX_WIDTH }}>
            <ListItem>
              <ListItemText primary={t("通知")} primaryTypographyProps={{ fontSize: 14 }} />
              <ListItemSecondaryAction>
                <AllReadButton {...allReadButtonProps} />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            {props.items.length > 0 && <Virtuoso {...virtuosoProps} />}
          </List>
          {props.items.length === 0 && (
            <Box p={2}>
              <NoUnreadNotificationTypography>{t("未読の通知はありません")}</NoUnreadNotificationTypography>
            </Box>
          )}
        </StyledWrapper>
      </Popover>
    </>
  );
};

NotificationList.displayName = "NotificationList";

export default NotificationList;
