import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Box, { BoxProps } from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import * as React from "react";

import Tabs, { TabsProps } from "../../../../primitive/Tabs/Tabs";
import Tab, { TabProps } from "./parts/Tab/Tab";

export type TabItem = {
  /**
   * A value to identify the tab.
   * Do not use variable values such as i18n values.
   */
  id: string;
  Component: React.ReactNode;
  label: Omit<TabProps, "value">;
};

export type ReportTabProps = {
  defaultTab?: string;
  tabs?: Omit<TabsProps, "value">;
  items: TabItem[];
};

const ReportTab: React.FC<ReportTabProps> = props => {
  const defaultTab = props.defaultTab || (props.items.length ? props.items[0].id : "");
  const theme = useTheme();
  const [currentName, updateCurrentSelectedName] = React.useState(defaultTab);
  React.useEffect(() => {
    if (defaultTab) {
      updateCurrentSelectedName(defaultTab);
    }
  }, [defaultTab]);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    updateCurrentSelectedName(newValue);
    props.tabs && props.tabs.onChange?.(event, newValue);
  };

  const TabPanels = props.items.map(item => {
    return (
      <TabPanel key={item.id} value={item.id} sx={{ p: 0 }}>
        {item.Component}
      </TabPanel>
    );
  });

  const tabPanelBox: BoxProps = {
    sx: {
      bgcolor: "default",
    },
  };

  const tabsSx: TabsProps["sx"] = {
    "&.MuiTabs-root": {
      minHeight: "43px",
      height: "43px",
    },
    "& .MuiTab-root": {
      minHeight: "43px",
      height: "43px",
      textTransform: "uppercase",
      fontWeight: "400px",
      padding: "8px 16px",
    },
    "& .MuiTab-root.Mui-selected": {
      color: theme.palette.secondary.main,
      backgroundColor: "inherit",
    },
    "& .MuiButtonBase-root": {
      "&.Mui-selected": {
        fontWeight: 400,
        textTransform: "uppercase",
      },
    },
    backgroundColor: "inherit",
  };

  const tabs: TabsProps = {
    ...props.tabs,
    sx: tabsSx,
  };

  const tabBoxSx: BoxProps["sx"] = {
    backgroundColor: theme.palette.grey["800"],
    borderBottom: "1px solid",
    borderColor: theme.palette.divider,
  };

  const tabBox: BoxProps = {
    width: "100%",
    display: "inline-flex",
    justifyContent: "space-between",
    sx: {
      ...tabBoxSx,
    },
  };

  return (
    <TabContext value={currentName}>
      <Box>
        <Box {...tabBox}>
          <Tabs {...tabs} value={currentName} onChange={handleChange}>
            {props.items.map(item => {
              const tab: TabProps = {
                ...item.label,
                value: item.id,
                onClick: event => {
                  item.label?.onClick?.(event);
                  updateCurrentSelectedName(item.id);
                },
              };
              return <Tab key={item.id} {...tab} />;
            })}
          </Tabs>
        </Box>
      </Box>
      <Box {...tabPanelBox}>{TabPanels}</Box>
    </TabContext>
  );
};

ReportTab.displayName = "ReportTab";

export default ReportTab;
