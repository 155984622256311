import { useTranslation } from "@hireroo/i18n";
import Code from "@mui/icons-material/Code";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import FlowChartsInputControl, { FlowChartsInputControlProps } from "../../../../usecase/FlowChartsInputControl/FlowChartsInputControl";

export type FlowChartSectionProps = {};

const FlowChartSection: React.FC<FlowChartSectionProps> = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const flowChartsInputControlProps: FlowChartsInputControlProps = {
    name: `initialFlowCharts` as "flowCharts",
    addableTabs: {
      isInitialTabClosable: true,
    },
  };
  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Box display="flex" alignItems="center">
          <Box mr={1}>
            <Code fontSize="small" sx={{ color: theme.palette.common.white }} />
          </Box>

          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            {t("初期フローチャートを入力する")}
          </Typography>
        </Box>
      </Box>

      <Box mt={2}>
        <Typography variant="body2">
          {t("初期フローチャートを追加します。候補者は設定された初期フローチャートを参考にして解答が可能です。")}
        </Typography>
      </Box>

      <Box mt={3}>
        <FlowChartsInputControl {...flowChartsInputControlProps} />
      </Box>
    </Box>
  );
};

FlowChartSection.displayName = "FlowChartSection";

export default FlowChartSection;
