import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { UpsellWithVideoPropsArgs, useGenerateProps } from "./useGenerateProps";

export type UpsellWithVideoContainerProps = {
  kind: UpsellWithVideoPropsArgs["kind"];
};

const UpsellWithVideoContainer: React.FC<UpsellWithVideoContainerProps> = (props: UpsellWithVideoContainerProps) => {
  const upsellWithVideoProps = useGenerateProps({
    kind: props.kind,
  });
  return (
    <ErrorBoundary>
      <Widget.UpsellWithVideo {...upsellWithVideoProps} />
    </ErrorBoundary>
  );
};

UpsellWithVideoContainer.displayName = "UpsellWithVideoContainer";

export default withErrorBoundary(UpsellWithVideoContainer, {});
