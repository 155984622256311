import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { StatisticsForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import FilterAltOffOutlinedIcon from "@mui/icons-material/FilterAltOffOutlined";
import LocalOffer from "@mui/icons-material/LocalOffer";
import Box from "@mui/material/Box";
import Chip, { ChipProps } from "@mui/material/Chip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, SubmitHandler, useFieldArray, useForm } from "react-hook-form";

import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";
import DateTimeControl, { DateTimeControlProps } from "../../primitive/InputControl/DateTimeControl/DateTimeControl";
import SelectControl, { SelectControlProps } from "../../primitive/InputControl/SelectControl/SelectControl";
import SwitchControl from "../../primitive/InputControl/SwitchControl/SwitchControl";
import TestTagSelectMenu, { TestTagSelectMenuProps } from "../TestTagSelectMenu/TestTagSelectMenu";

const HEIGHT = 40;

const StyledSelectControl = styled(SelectControl)(() => ({
  height: HEIGHT,
  width: "120px",
}));

const StyledTestTagSelectMenu = styled(TestTagSelectMenu)(() => ({
  height: HEIGHT,
}));

const StyledDateTimeControl = styled(DateTimeControl)(() => ({
  ".MuiOutlinedInput-input": {
    height: HEIGHT,
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

export type StatisticsQueryToolbarProps = {
  enableTagField: boolean;
  defaultValues?: Partial<StatisticsForm.StatisticsQuerySchema>;
  onChange: SubmitHandler<StatisticsForm.StatisticsQuerySchema>;
  startDatePicker?: Pick<DateTimeControlProps["dateTimePicker"], "min" | "max" | "datePicker">;
  endDatePicker?: Pick<DateTimeControlProps["dateTimePicker"], "min" | "max" | "datePicker">;
  readOnly?: boolean;
};

const StatisticsQueryToolbar: React.FC<StatisticsQueryToolbarProps> = props => {
  const { onChange } = props;
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const validateSchema = StatisticsForm.useStatisticsQuery();
  const methods = useForm<StatisticsForm.StatisticsQuerySchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: {
      tags: [],
      endDate: null,
      startDate: null,
      scope: "GLOBAL",
      enableDate: false,
      ...props.defaultValues,
    },
  });
  const tagsFieldArray = useFieldArray({
    control: methods.control,
    name: "tags",
  });
  const changed = methods.watch();
  const [, setCachedValue] = React.useState(JSON.stringify(changed));
  React.useEffect(() => {
    const newValue = JSON.stringify(changed);
    setCachedValue(prev => {
      // Prevent double dispatching
      if (prev !== newValue) {
        methods.handleSubmit(onChange)();
      }
      return newValue;
    });
  }, [methods, onChange, changed]);

  const startDate: DateTimeControlProps = {
    dateTimePicker: {
      ...props.startDatePicker,
      lang: lang,
      customInput: {
        label: t("集計開始時刻"),
        disabled: !props.readOnly ? !methods.getValues("enableDate") : true,
        size: "small",
        variant: "outlined",
        InputLabelProps: {
          shrink: true,
        },
        sx: {
          width: "150px",
        },
        color: "secondary",
      },
      datePicker: {
        ...props.startDatePicker?.datePicker,
        disabled: !props.readOnly ? !methods.getValues("enableDate") : true,
        placeholderText: t("指定なし"),
      },
    },
  };

  const endDate: DateTimeControlProps = {
    dateTimePicker: {
      ...props.endDatePicker,
      lang: lang,
      customInput: {
        label: t("集計終了時刻"),
        disabled: !methods.getValues("enableDate"),
        size: "small",
        variant: "outlined",
        InputLabelProps: {
          shrink: true,
        },
        sx: {
          width: "150px",
        },
        color: "secondary",
      },
      datePicker: {
        ...props.endDatePicker?.datePicker,
        disabled: !props.readOnly ? !methods.getValues("enableDate") : true,
        placeholderText: t("指定なし"),
      },
    },
  };
  const selectedTags = React.useMemo(() => {
    return tagsFieldArray.fields.filter(field => field.selected);
  }, [tagsFieldArray.fields]);
  const tagNames = React.useMemo(() => {
    return tagsFieldArray.fields.map(field => field.value);
  }, [tagsFieldArray.fields]);
  const testTagSelectMenu: TestTagSelectMenuProps = {
    selected: selectedTags.map(tag => tag.value),
    onSelect: React.useCallback(
      tagName => {
        const index = tagsFieldArray.fields.findIndex(field => field.value === tagName);
        const prevValue = tagsFieldArray.fields[index];
        if (index >= 0 && prevValue) {
          tagsFieldArray.update(index, { ...prevValue, selected: !prevValue.selected });
        }
      },
      [tagsFieldArray],
    ),
    onReset: React.useCallback(() => {
      tagsFieldArray.replace([]);
    }, [tagsFieldArray]),
    tagNames: tagNames,
  };

  const scopeSelectControl: SelectControlProps = {
    disabled: props.readOnly,
    label: t("母集団"),
    menuItems: [
      {
        label: t("全体"),
        value: "GLOBAL",
      },
      {
        label: t("自社"),
        value: "COMPANY",
      },
    ],
    color: "secondary",
  };
  const resetButton: IconButtonWithTooltipProps = {
    title: t("全ての条件をリセット"),
    onClick: () => {
      methods.reset();
    },
    sx: {
      ml: 2,
    },
    children: <FilterAltOffOutlinedIcon />,
  };

  return (
    <FormProvider {...methods}>
      <Box>
        <Box display={"flex"} alignItems={"center"}>
          <Stack direction="row" spacing={2}>
            <Box>
              <StyledSelectControl name="scope" {...scopeSelectControl} />
            </Box>

            {props.enableTagField && <StyledTestTagSelectMenu {...testTagSelectMenu} />}
          </Stack>

          <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
            <Box display={"flex"} alignItems={"center"}>
              <Box>
                <FormControlLabel
                  control={<SwitchControl size="small" name="enableDate" disabled={props.readOnly} color="secondary" />}
                  label={<Typography sx={{ wordBreak: "keep-all" }}>{t("集計期間")}</Typography>}
                  sx={{ ml: 0.5 }}
                />
              </Box>
              <StyledDateTimeControl name="startDate" {...startDate} />
              <Typography sx={{ mx: 2 }}>~</Typography>
              <StyledDateTimeControl name="endDate" {...endDate} />
              {!props.readOnly && <IconButtonWithTooltip {...resetButton} />}
            </Box>
          </Box>
        </Box>

        {props.enableTagField && (
          <Stack direction={"row"} sx={{ flexWrap: "wrap", gap: "8px", mt: 2 }}>
            {selectedTags.map(tag => {
              const chipProps: ChipProps = {
                label: tag.value,
                icon: <LocalOffer fontSize="small" />,
                onDelete: () => {
                  const targetIndex = tagsFieldArray.fields.findIndex(target => target.value === tag.value);
                  if (targetIndex >= 0) {
                    tagsFieldArray.update(targetIndex, { ...tag, selected: false });
                  }
                },
              };
              return <Chip {...chipProps} key={`tag-chip-${tag.value}`} />;
            })}
          </Stack>
        )}
      </Box>
    </FormProvider>
  );
};

StatisticsQueryToolbar.displayName = "StatisticsQueryToolbar";

export default StatisticsQueryToolbar;
