import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import * as React from "react";

import NativeSelector, { NativeSelectorProps } from "../NativeSelector/NativeSelector";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-root": {
    borderRadius: "40px",
  },
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(2),
    border: 0,
    borderRadius: "40px",
  },
  "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
    borderRadius: "40px",
  },
  "& .MuiToggleButtonGroup-grouped:not(:last-of-type)": {
    borderRadius: "40px",
  },
  "&.Mui-disabled": {
    border: 0,
  },
  "& .Mui-selected": {
    backgroundColor: theme.palette["Secondary/Shades"].p50,
  },
  borderRadius: "40px",
  "& :not(:first-of-type)": {
    borderRadius: "40px",
  },
  "& :not(:last-of-type)": {
    borderRadius: "40px",
  },
  "& :first-of-type": {
    borderRadius: "40px",
  },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  borderRadius: "40px",
  "&.Mui-selected": {
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  textTransform: "none",
  height: "28px",
}));

type ValueId = string;

type QuestionButton = {
  id: ValueId;
  displayName: string;
  onClick?: () => void;
};

export type HeaderProps = {
  questions: QuestionButton[];
  onChange?: (id: ValueId) => void;
  selector: NativeSelectorProps;
};

const Header: React.FC<HeaderProps> = props => {
  const [valueId, setValueId] = React.useState<ValueId>(props.questions.at(0)?.id ?? "");

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" height="28px">
      <StyledToggleButtonGroup
        value={valueId}
        exclusive
        onChange={(e, value) => {
          // Enforce selection: https://mui.com/material-ui/react-toggle-button/#enforce-value-set
          if (value !== null) {
            setValueId(value);
            props.onChange?.(value);
          }
        }}
      >
        {props.questions.map(question => (
          <StyledToggleButton key={question.id} value={question.id} onClick={question.onClick}>
            <Typography fontSize={12}>{question.displayName}</Typography>
          </StyledToggleButton>
        ))}
      </StyledToggleButtonGroup>
      <NativeSelector {...props.selector} />
    </Stack>
  );
};

Header.displayName = "Header";

export default Header;
