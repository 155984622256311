import * as React from "react";

import { FileNode } from "../../../../helpers/fileTree";
import TreeItem from "../../primitive/TreeItem/TreeItem";
import TreeItemLabel, { TreeItemLabelProps } from "../../primitive/TreeItemLabel/TreeItemLabel";

export type RenderTreeV3Props = {
  node: FileNode;
  onSelectDirectory: (directory: string) => void;
  onSelectFile: (sourceFile: string) => void;
  label: Pick<TreeItemLabelProps, "createDirectoryButton" | "createFileButton" | "deleteNodeButton">;
};

const RenderTreeV3 = React.forwardRef((props: RenderTreeV3Props, ref: React.Ref<unknown>) => {
  const { node } = props;
  const nodeChildren = node.children;

  const sortedNodeChildren =
    nodeChildren &&
    Object.keys(nodeChildren).toSorted((a: string, b: string) => {
      if ((nodeChildren[a].isDir && nodeChildren[b].isDir) || (!nodeChildren[a].isDir && !nodeChildren[b].isDir)) {
        // Node is both directory of file, hence determine the order by its name
        return a <= b ? -1 : 1;
      } else if (nodeChildren[a].isDir) {
        // Directory should come before file, hence return -1
        return -1;
      }
      return 1;
    });

  return (
    <TreeItem
      key={node.id}
      nodeId={node.id}
      ref={ref}
      label={<TreeItemLabel {...props.label} node={node} />}
      onClick={() => {
        if (!node.isDir) {
          props.onSelectFile(node.id);
        } else {
          props.onSelectDirectory?.(node.id);
        }
      }}
    >
      {sortedNodeChildren &&
        sortedNodeChildren.map(key => {
          return (
            <RenderTreeV3
              key={key}
              node={nodeChildren[key]}
              onSelectFile={props.onSelectFile}
              onSelectDirectory={props.onSelectDirectory}
              label={props.label}
            />
          );
        })}
    </TreeItem>
  );
});

RenderTreeV3.displayName = "RenderTree";

export default RenderTreeV3;
