import { state } from "./State";
import type * as Types from "./types";

export const initialize = (security: Types.Security): void => {
  state.security = security;
};

export const clear = (): void => {
  state.security = null;
};
