import { state } from "./State";
import type * as Types from "./types";

export const initialize = (questions: Types.Question[]): void => {
  state.questions = questions;
  state.initialized = true;
};

export const clear = () => {
  state.questions = [];
  state.initialized = false;
};

export const addQuestions = (questions: Types.Question[]) => {
  state.questions = questions.concat(state.questions);
};
