import { generatePath } from "@hireroo/router/api";
import { RouteKey } from "@hireroo/router/config";
import * as React from "react";
import { Navigate, useParams, useSearchParams } from "react-router-dom";

export type RedirectProps = {
  from: RouteKey;
};

export const Redirect: React.FC<RedirectProps> = props => {
  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const linkHash = searchParams.get("share") ?? "";
  if (!id) {
    return null;
  }
  switch (props.from) {
    case "/e/interviews/:id/detail": {
      const to = generatePath("/e/screenings/tests/:id/detail", {
        pathParams: {
          id,
        },
      });
      return <Navigate to={to} />;
    }
    case "/e/interviews/:id/review": {
      const to = generatePath("/e/screenings/tests/:id/review", {
        pathParams: {
          id,
        },
      });
      return <Navigate to={to} />;
    }
    case "/e/interviews/:id/update": {
      /**
       * The edit page for each test will be gone, so redirect to the detail page
       */
      const to = generatePath("/e/screenings/tests/:id/detail", {
        pathParams: {
          id,
        },
      });
      return <Navigate to={to} />;
    }
    case "/c/interviews/:id": {
      const to = generatePath("/c/screenings/tests/:id", {
        pathParams: {
          id,
        },
      });
      return <Navigate to={to} />;
    }
    case "/c/interviews/:id/detail": {
      const to = generatePath("/c/screenings/tests/:id/detail", {
        pathParams: {
          id,
        },
        queryParams: {
          share: linkHash,
        },
      });
      return <Navigate to={to} />;
    }
    case "/c/interview-groups/:id": {
      const to = generatePath("/c/screenings/:id", {
        pathParams: {
          id,
        },
      });
      return <Navigate to={to} />;
    }
  }

  return null;
};

export default Redirect;
