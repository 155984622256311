import { useTranslation } from "@hireroo/i18n";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Divider from "@mui/material/Divider";
import Grow from "@mui/material/Grow";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Popper, { PopperProps } from "@mui/material/Popper";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
const StyledIconButton = styled(IconButton)(() => ({
  width: 20,
  height: 20,
  flexShrink: 0,
}));

const ActionButton = styled(Button)(() => ({
  height: 30,
  padding: "4px 10px",
  fontSize: 13,
}));

const BADGE_SIZE = 14;

const StyledBadge = styled(Badge)(() => ({
  ".MuiBadge-badge": {
    minWidth: BADGE_SIZE,
    width: BADGE_SIZE,
    height: BADGE_SIZE,
    maxHeight: BADGE_SIZE,
    fontSize: 10,
  },
}));

export type PopupSearchFilterProps = {
  count: number;
  disabled?: boolean;
  title: string;
  onReset: () => void;
  onApply: () => void;
  children?: React.ReactNode;
};

const PopupSearchFilter: React.FC<PopupSearchFilterProps> = props => {
  const { t } = useTranslation();
  const { onReset, onApply } = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const theme = useTheme();

  const openPopupButton: IconButtonProps = {
    ref: anchorRef,
    children: <FilterAltOutlinedIcon />,
    onClick: () => {
      setOpen(prevOpen => !prevOpen);
    },
  };

  const resetButtonProps: ButtonProps = {
    children: t("リセット"),
    variant: "outlined",
    color: "outline-only",
    onClick: () => {
      onReset();
      setOpen(false);
    },
  };

  const applyButtonProps: ButtonProps = {
    children: t("適用する"),
    variant: "contained",
    color: "secondary",
    onClick: () => {
      onApply();
      setOpen(false);
    },
  };

  const popperProps: PopperProps = {
    sx: {
      zIndex: theme.zIndex.modal,
      minWidth: "250px",
      maxWidth: "500px",
    },
    open: open,
    anchorEl: anchorRef.current,
    transition: true,
    disablePortal: false,
    /**
     * TODO @himenon Consult with Designer
     */
    placement: "bottom-end",
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
    onApply();
  };

  return (
    <>
      <StyledBadge badgeContent={props.count} color="secondary">
        <StyledIconButton {...openPopupButton}>
          <FilterAltOutlinedIcon fontSize="small" />
        </StyledIconButton>
      </StyledBadge>
      <Popper {...popperProps}>
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "top center",
            }}
          >
            <Paper elevation={8}>
              <ClickAwayListener onClickAway={handleClose}>
                <Box>
                  <Box py={1} px={2} display="flex" justifyContent={"space-between"} justifyItems={"center"}>
                    <Typography variant="subtitle2" alignItems={"center"} fontSize={14}>
                      {props.title}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box py={2} px={2}>
                    {props.children}
                  </Box>
                  <Box display={"flex"} justifyContent={"flex-end"} py={1} px={2}>
                    <Stack direction="row" spacing={1.5}>
                      <ActionButton {...resetButtonProps} />
                      <ActionButton {...applyButtonProps} />
                    </Stack>
                  </Box>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

PopupSearchFilter.displayName = "PopupSearchFilter";

export default PopupSearchFilter;
