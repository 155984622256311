import { SupportLanguage, SupportLanguageValue, useTranslation } from "@hireroo/i18n";
import { Delete, ExpandMore } from "@mui/icons-material";
import Accordion from "@mui/material/Accordion/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary/AccordionSummary";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";
import * as React from "react";

import IconButton, { IconButtonProps } from "../../../../../../primitive/Button/IconButton";
import InputControlTextField, { InputControlTextFieldProps } from "../../../../../../primitive/InputControl/InputControlTextField";
import SwitchControl from "../../../../../../primitive/InputControl/SwitchControl";
import MarkdownPreviewEditor, { MarkdownPreviewEditorProps } from "../../../../../../usecase/MarkdownPreviewEditor/MarkdownPreviewEditor";
import { useAlgorithmTestCasesFormContext } from "../../../../Context";
import AlgorithmInputFields from "../AlgorithmInputFields/AlgorithmInputFields";
import DatabaseInputFields from "../DatabaseInputFields/DatabaseInputFields";

const titlePlaceholderMap: Record<SupportLanguageValue, string> = {
  [SupportLanguage.JA]: "XXのケース",
  [SupportLanguage.EN]: "Case when XX",
};
const descriptionPlaceholderMap: Record<SupportLanguageValue, string> = {
  [SupportLanguage.JA]: "XXのケースでは、YYが必要なため、返り値がZZとなる。",
  [SupportLanguage.EN]: "In the XX case, YY is needed, so the return value is ZZ.",
};

const titleMap: Record<SupportLanguageValue, string> = {
  [SupportLanguage.JA]: "タイトル",
  [SupportLanguage.EN]: "title",
};
const descriptionMap: Record<SupportLanguageValue, string> = {
  [SupportLanguage.JA]: "詳細",
  [SupportLanguage.EN]: "description",
};

export type TestCaseInputProps = {
  index: number;
  language: "ja" | "en";
  length: number;
  onDelete: IconButtonProps["onClick"];
};

const TestCaseInput: React.FC<TestCaseInputProps> = props => {
  const { t } = useTranslation();
  const { method, contextProps } = useAlgorithmTestCasesFormContext();
  const [expanded, setExpanded] = React.useState<number | false>(props.index);
  const handleChange = (index: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? index : false);
  };

  const errors = method.formState.errors;

  const hasError = (): boolean => {
    return (
      Boolean(errors.correctnessTestCases?.data?.[props.index]?.[`title_${props.language}`]) ||
      Boolean(errors.correctnessTestCases?.data?.[props.index]?.[`description_${props.language}`])
    );
  };

  const deleteButton: IconButtonProps = {
    onClick: props.onDelete,
    title: t("テストケースを削除する"),
    "aria-label": "reset-type",
    color: "default",
    size: "small",
    children: <Delete />,
  };
  const titleTextFiled: InputControlTextFieldProps = {
    variant: "outlined",
    placeholder: titlePlaceholderMap[props.language],
    type: "text",
    label: titleMap[props.language],
    fullWidth: true,
    color: "primary",
    InputLabelProps: {
      shrink: true,
    },
    required: true,
    control: method.control,
    onChange: () => {
      method.clearErrors(`correctnessTestCases.data.${props.index}.title_${props.language}`);
    },
  };
  const descriptionEditor: MarkdownPreviewEditorProps = {
    required: true,
    label: descriptionMap[props.language],
    placeholder: descriptionPlaceholderMap[props.language],
    minRows: 3,
  };
  return (
    <Accordion expanded={expanded === props.index} onChange={handleChange(props.index)} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel-content" id={`panel-${props.index}-header`}>
        <Box display="flex" alignItems="center" gap={2}>
          <Box display="flex" gap={2} alignItems="center">
            {props.length > 1 && <IconButton {...deleteButton} />}

            <Typography>{`${t("テストケース")} ${props.index + 1}`}</Typography>
          </Box>
          <Typography color="error" variant="body2">
            {hasError() && t("入力項目に不正な項目があります。")}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" flexDirection="column" gap={4}>
          <Box display="flex" gap={4} justifyContent="space-between" width="100%">
            <FormGroup>
              <Box display="flex" alignItems="center">
                <Typography width="300px">{t("テスト中は表示しない")}</Typography>
                <SwitchControl name={`correctnessTestCases.data.${props.index}.is_hidden`} control={method.control} />
              </Box>
            </FormGroup>
          </Box>
          <Box display="flex" gap={4} justifyContent="space-between" width="100%">
            <Box width="100%" display="flex" flexDirection="column" gap={4}>
              <InputControlTextField name={`correctnessTestCases.data.${props.index}.title_${props.language}`} {...titleTextFiled} />

              <MarkdownPreviewEditor name={`correctnessTestCases.data.${props.index}.description_${props.language}`} {...descriptionEditor} />
            </Box>
          </Box>

          <Box mt={1}>
            {contextProps.signatureProps.variant === "ALGORITHM" && (
              <AlgorithmInputFields index={props.index} signature={contextProps.signatureProps.signature} />
            )}
            {contextProps.signatureProps.variant === "DATABASE" && (
              <DatabaseInputFields signature={contextProps.signatureProps.signature} index={props.index} />
            )}
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

TestCaseInput.displayName = "TestCaseInput";

export default TestCaseInput;
