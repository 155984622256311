import { useTranslation } from "@hireroo/i18n";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DialogWithCloseIcon from "../../../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";
import AdmissionDecisionIcon, { AdmissionDecisionIconProps } from "../AdmissionDecisionIcon/AdmissionDecisionIcon";
import FeedbackItem, { FeedbackItemProps } from "../FeedbackItem/FeedbackItem";

type SummaryProps = {
  reviewer: {
    displayName: string;
    photoUrl: string;
  };
  reviewedAt: string;
  items: FeedbackItemProps[];
  status?: AdmissionDecisionIconProps["status"];
  comment: string;
};

export type ReviewDetailDialogProps = {
  isOpen: boolean;
  summary: SummaryProps;
  onClose: () => void;
};

const ReviewDetailDialog: React.FC<ReviewDetailDialogProps> = ({ isOpen, summary, onClose }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <DialogWithCloseIcon open={isOpen} onClose={onClose} title={t("レビュー詳細")}>
      <Box mt={1} display={"flex"} flexDirection={"row"} alignItems={"center"}>
        <Box>
          <Avatar alt={summary.reviewer?.displayName ?? ""} src={summary.reviewer?.photoUrl} />
        </Box>
        <Box mr={2} />
        <Box>
          <Typography variant="body1" fontWeight={"bold"}>
            {summary.reviewer?.displayName ?? t("名前情報がありませんでした。")}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} textAlign={"right"}>
          <Typography variant="body2" color={"textSecondary"}>
            {summary.reviewedAt}
          </Typography>
        </Box>
      </Box>
      {summary.status && (
        <Box display={"flex"} mt={2}>
          <Box mr={1}>
            <Typography variant={"subtitle2"} color={theme.palette.text.secondary} sx={{ whiteSpace: "nowrap" }}>
              {t("合否判定")}：
            </Typography>
          </Box>
          <Box>
            <AdmissionDecisionIcon status={summary.status} />
          </Box>
        </Box>
      )}
      <Box display={"flex"} mt={2}>
        <Box mr={1}>
          <Typography variant={"subtitle2"} color={theme.palette.text.secondary} sx={{ whiteSpace: "nowrap" }}>
            {t("合否理由")}：
          </Typography>
        </Box>
        <Box>
          <Typography variant={"body2"} sx={{ whiteSpace: "pre-line" }}>
            {summary.comment}
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={1} mt={2.5}>
        {summary.items.map(item => (
          <Grid item xs={6} key={item.id}>
            <FeedbackItem {...item} />
          </Grid>
        ))}
      </Grid>
    </DialogWithCloseIcon>
  );
};

ReviewDetailDialog.displayName = "ReviewDetailDialog";

export default ReviewDetailDialog;
