import { App, Company } from "@hireroo/app-store/essential/employee";
import { NotificationSettings } from "@hireroo/app-store/widget/e/NotificationSettings";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import NotificationSettingsContainer, { NotificationSettingsContainerProps } from "./Container";

export type NotificationSettingsFetchContainerProps = {};

const SlackNotificationSettingsFetchContainer: React.FC<NotificationSettingsFetchContainerProps> = _props => {
  const appStatus = App.useStatus();
  const companyId = Company.useStrictActiveCompanyId();
  const [result, refresh] = Graphql.useGetSlackSettingsQuery({
    variables: {
      companyId: companyId,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "network-only",
  });

  const initialized = NotificationSettings.useInitialized();

  React.useEffect(() => {
    if (result.data) {
      // update your state
      if (result.data.slackSettings) {
        NotificationSettings.updateIsAuthorized(result.data.slackSettings.isAuthorized);
        if (result.data.slackSettings.spotSettings) NotificationSettings.updateSpotSettings(result.data.slackSettings.spotSettings);
        if (result.data.slackSettings.remoteSettings) NotificationSettings.updateRemoteSettings(result.data.slackSettings.remoteSettings);
        if (result.data.slackSettings.incomingWebHook) NotificationSettings.updateIncomingWebHook(result.data.slackSettings.incomingWebHook);
        NotificationSettings.updateLanguage(result.data.slackSettings.language);
        NotificationSettings.updateStatus("INITIALIZED");
      }
    }
  }, [result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || !initialized || appStatus !== "INITIALIZED") {
    return <Widget.Loading kind="TOP" />;
  }

  const containerProps: NotificationSettingsContainerProps = {};
  return (
    <ErrorBoundary>
      <NotificationSettingsContainer {...containerProps} />
    </ErrorBoundary>
  );
};

SlackNotificationSettingsFetchContainer.displayName = "SlackNotificationSettingsFetchContainer";

export default withErrorBoundary(SlackNotificationSettingsFetchContainer, {});
