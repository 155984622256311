import { useTwilioConversationsContext } from "@hireroo/app-helper/hooks";
import { RemotesId } from "@hireroo/app-store/page/c/remotes_id";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import * as Sentry from "@sentry/react";
import * as React from "react";

export const useStartVideoChatConversation = (props: { liveCodingId: number; displayName: string }) => {
  const { t } = useTranslation();
  const { connect } = useTwilioConversationsContext();

  const [addConversationResult, addConversation] = Graphql.useAddLiveCodingVideoChatConversationForCandidateMutation();
  const [joinConversationResult, joinConversation] = Graphql.useJoinVideoChatConversationForCandidateMutation();

  const [status, setStatus] = React.useState<"STOP" | "START" | "CONNECTED" | "FAILED">("STOP");

  const [fetchStatus, setFetchStatus] = React.useState<"BEFORE_FETCH" | "FETCHED">("BEFORE_FETCH");
  const conversationId = RemotesId.useLiveCodingVideoChatConversationId();
  const token = joinConversationResult.data?.joinVideoChatConversation.token;

  const connectionCleanupRef = React.useRef<() => void>();

  // refresh Conversation
  const [getLiveCodingResult] = Graphql.useGetLiveCodingForCandidateQuery({
    variables: {
      liveCodingId: props.liveCodingId,
    },
    requestPolicy: "network-only",
    pause: status !== "START",
  });

  React.useEffect(() => {
    if (getLiveCodingResult.error) {
      Sentry.captureException(getLiveCodingResult.error);
      setStatus("FAILED");
      return;
    }

    const conversation = getLiveCodingResult.data?.liveCoding.videoChatConversation;
    if (conversation) {
      RemotesId.updateLiveCodingVideoChatConversationId(conversation.conversationId);
    }
    setFetchStatus("FETCHED");
  }, [getLiveCodingResult]);

  React.useEffect(() => {
    if (status !== "START") {
      return;
    }

    if (fetchStatus !== "FETCHED") {
      return;
    }

    if (getLiveCodingResult.fetching || addConversationResult.fetching || joinConversationResult.fetching) {
      return;
    }

    if (!conversationId) {
      addConversation({
        input: {
          liveCodingId: props.liveCodingId,
        },
      })
        .then(res => {
          if (res.data?.addLiveCodingVideoChatConversation) {
            RemotesId.updateLiveCodingVideoChatConversationId(res.data.addLiveCodingVideoChatConversation.id);
          }
        })
        .catch(err => {
          Sentry.captureException(err);
          setStatus("FAILED");
        });
      return;
    }

    if (!token) {
      joinConversation({
        input: {
          conversationId: conversationId,
          displayName: props.displayName,
        },
      }).catch(err => {
        Sentry.captureException(err);
        setStatus("FAILED");
      });
      return;
    }

    connect(conversationId, token)
      .then(c => {
        connectionCleanupRef.current = c;
        setStatus("CONNECTED");
      })
      .catch(err => {
        Sentry.captureException(err);
        setStatus("FAILED");
      });
  }, [
    addConversation,
    addConversationResult.error,
    addConversationResult.fetching,
    connect,
    conversationId,
    fetchStatus,
    getLiveCodingResult.error,
    getLiveCodingResult.fetching,
    joinConversation,
    joinConversationResult.error,
    joinConversationResult.fetching,
    props.displayName,
    props.liveCodingId,
    status,
    t,
    token,
  ]);

  // Cleanup with unmount
  React.useEffect(() => {
    return () => {
      connectionCleanupRef.current?.();
    };
  }, []);

  return {
    start: () => {
      setStatus("START");
    },
    status,
  };
};
