import { LiveCodingChatWindow } from "@hireroo/app-store/widget/e/LiveCodingChatWindow";
import * as Graphql from "@hireroo/graphql/client/request";

export const startParticipants = () => {
  const client = Graphql.getGraphqlClient();
  return LiveCodingChatWindow.subscribeParticipants((newUids: string[]) => {
    client
      .GetMultiEmployees({
        uids: newUids,
      })
      .then(res => {
        LiveCodingChatWindow.updateEmployees(res.employees);
      })
      .catch(() => {
        /**
         * TODO: If necessary, Retry if unable to retrieve.
         *
         * Even if it is not possible to retrieve it, the participants will be displayed as string avatars,
         * so the Snackbar will not notify user.
         */
      });
  });
};
