import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Company, Role } from "@hireroo/app-store/essential/employee";
import { SecuritySettings } from "@hireroo/app-store/widget/e/SecuritySettings";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import type * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { useHelpCenterNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/browser";

export type GenerateSecuritySettingsPropsArgs = {};

export const useGenerateProps = (_args: GenerateSecuritySettingsPropsArgs): Widget.SecuritySettingsProps => {
  const { t } = useTranslation();
  const security = SecuritySettings.useSecurity();
  const client = getGraphqlClient();
  const matchingRole = Role.useMatchingRole();
  const languageCode = useLanguageCode();
  const helpCenterNavigate = useHelpCenterNavigate(languageCode);
  const companyId = Company.useStrictActiveCompanyId();

  return {
    loading: false,
    ssoHelpCenterLink: {
      onClick: () => helpCenterNavigate("SSO", { _blank: true }),
    },
    saveButton: {
      disabled: !matchingRole.ADMIN_ONLY.matched,
      title: matchingRole.ADMIN_ONLY.messageOnUnmatched,
    },
    defaultValues: {
      ssoEnabled: security.ssoEnabled,
      ssoStrictMode: security.ssoMode === "STRICT",
      idpSsoUrl: security.idpSsoUrl,
      idpEntityId: security.idpEntityId,
      idpCertificate: security.idpCertificate,
      emailDomain: security.emailDomain,
    },
    onSubmit: async field => {
      const securityInput: Graphql.UpdateSecurityInput = {
        companyId: companyId,
        emailDomain: field.emailDomain,
        idpCertificate: field.idpCertificate,
        idpEntityId: field.idpEntityId,
        idpSsoUrl: field.idpSsoUrl,
        ssoEnabled: field.ssoEnabled,
        ssoMode: field.ssoStrictMode ? "STRICT" : "LOOSE",
      };
      await client
        .UpdateSecurity({
          updateSecurityInput: securityInput,
        })
        .then(res => {
          Snackbar.notify({
            severity: "success",
            message: t("SSOに必要な情報の更新に成功しました。"),
          });
          if (res.updateSecurity) {
            SecuritySettings.updateSecurity(res.updateSecurity);
          }
        })
        .catch(error => {
          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("SSOに必要な情報の更新に失敗しました。"));
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
        });
    },
    ssoEnabledFields: {
      spEndpoint: `${import.meta.env.VITE_URL}/__/auth/handler`,
      appUrl: import.meta.env.VITE_URL,
      onCopy: () => {
        Snackbar.notify({
          severity: "info",
          message: t("クリップボードにコピーされました"),
        });
      },
    },
  };
};
