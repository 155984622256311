import { useTranslation } from "@hireroo/i18n";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderColor: theme.palette.divider,
  padding: "6px 12px",
}));

// TODO: refactor using zod
const outputJsonParse = (value: string, emptyValue: string): Record<string, string>[] => {
  try {
    // TODO hashMapの問題が出たら変更する必要がある
    return JSON.parse(/\[{.+}\]/.exec(value) !== null ? value : `[${[emptyValue]}]`);
  } catch (e) {
    return [{ message: emptyValue }];
  }
};

export type ChallengeIDEConsoleOutputTableProps = {
  label: string;
  value: string;
};

const ChallengeIDEConsoleOutputTable: React.FC<ChallengeIDEConsoleOutputTableProps> = props => {
  const { t } = useTranslation();
  const emptyMessage = `{"message": "${t("該当するデータがありません")}"}`;
  const output = outputJsonParse(props.value, emptyMessage);

  return (
    <Stack direction="column" bgcolor="inherit" spacing={1.5}>
      <Typography variant="body2">{props.label}</Typography>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {Object.keys(output[0]).map((column, index) => (
                <StyledTableCell key={`key-header-cell-${index}`}>{column}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {output.map((record, rowIndex: number) => (
              <TableRow key={`key-body-row-${rowIndex}`}>
                {Object.values(record).map((v, colIndex: number) => (
                  <StyledTableCell key={`key-body-cell-${rowIndex}-${colIndex}`}>{v}</StyledTableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

ChallengeIDEConsoleOutputTable.displayName = "ChallengeIDEConsoleOutputTable";

export default ChallengeIDEConsoleOutputTable;
