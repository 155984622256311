import { INITIAL_VERSION } from "@hireroo/app-definition/question";
import { createAnswersInput } from "@hireroo/app-helper/freepad";
import { stringComponentTypeToGraphqlComponentTypeGraphql } from "@hireroo/app-helper/system-design";
import { Auth } from "@hireroo/app-store/essential/employee";
import { FreepadResourceEditor } from "@hireroo/app-store/view-domain/FreepadResourceEditor";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import { Pages, Widget } from "@hireroo/presentation/legacy";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import { FreepadOptionalForm } from "@hireroo/validator";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";

type UseGenerateOptionalProps = {
  companyId: number;
};

type QuestionDetails = {
  props: Pages.FreepadResourceEditorProps["optional"];
  saveButton: Pages.FreepadResourceEditorProps["saveOptionalButton"];
  onDraftSave: () => void;
};

export const useGenerateOptionalProps = (args: UseGenerateOptionalProps): QuestionDetails => {
  const { t } = useTranslation();
  const question = FreepadResourceEditor.useQuestion();
  const client = getGraphqlClient();
  const userId = Auth.useCurrentUid();
  const navigate = useTransitionNavigate();
  const saveStatus = FreepadResourceEditor.useSaveStatus();
  const { methods } = Widget.useFreepadOptionalFormContext();

  const [open, setOpen] = React.useState<boolean>(false);

  const handleSave: (isDraft: boolean) => SubmitHandler<FreepadOptionalForm.FreepadOptionalFormSchema> = React.useCallback(
    isDraft => fields => {
      if (question) {
        FreepadResourceEditor.updateSaveStatus("LOADING");
        client
          .UpdateQuestionForFreepadResourceEditor({
            input: {
              id: question.freepadQuestionId,
              oldVersion: question.version,
              newVersion: isDraft ? question.version : fields.newVersion,
              companyId: args.companyId,
              employeeId: userId,
              variant: question.variant,
              difficulty: question.difficulty,
              isPrivate: question.isPrivate,
              status: isDraft ? "DRAFT" : "REVIEWING",
              titleJa: question.titleJa,
              titleEn: question.titleEn,
              descriptionJa: question.descriptionJa,
              descriptionEn: question.descriptionEn,
              answers: createAnswersInput(question.answers),
              initialCodes: fields.initialCodes.map(code => ({
                id: code.id ?? 0,
                runtime: code.runtime,
                body: code.body,
                questionId: question.freepadQuestionId,
                questionVersion: question.version,
              })),
              initialFlowCharts: fields.initialFlowCharts.map(
                (chart): Graphql.FreepadInitialFlowChartInput => ({
                  id: chart.id ?? 0,
                  componentType: stringComponentTypeToGraphqlComponentTypeGraphql[chart.componentType],
                  body: chart.body,
                  questionId: question.freepadQuestionId,
                  questionVersion: question.version,
                }),
              ),
              tags: question.tags.map(t => t.name),
            },
          })
          .then(res => {
            FreepadResourceEditor.updateQuestion(res.updateFreepadQuestion);
            if (isDraft) {
              Snackbar.notify({
                severity: "success",
                message: t("問題の一時保存が完了しました。"),
              });
            } else {
              navigate("/e/questions").then(() => {
                FreepadResourceEditor.clear();
                Snackbar.notify({ severity: "success", message: t("問題の保存が完了しました。") });
              });
            }
          })
          .catch(() => {
            Snackbar.notify({
              severity: "error",
              message: t("問題の一時保存に失敗しました。しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
            });
          })
          .finally(() => {
            FreepadResourceEditor.updateSaveStatus("READY");
          });
      }
    },
    [args.companyId, client, navigate, question, t, userId],
  );

  return {
    props: {
      versionDialog: {
        open: open,
        oldVersion: question?.version ?? INITIAL_VERSION,
        closeButton: {
          onClick: () => {
            setOpen(false);
          },
          disabled: saveStatus === "LOADING",
        },
        saveButton: {
          onClick: () => {
            methods.setValue("needUpdateVersion", question ? question?.version !== INITIAL_VERSION : false);
            methods.handleSubmit(handleSave(false), () => FreepadResourceEditor.updateSaveStatus("READY"))();
          },
          loading: saveStatus === "LOADING",
          disabled: saveStatus === "LOADING",
        },
      },
      codeSection: {},
      flowChartSection: {},
    },
    saveButton: {
      onClick: () => {
        setOpen(true);
      },
      loading: saveStatus === "LOADING",
    },
    onDraftSave: () => {
      methods.setValue("needUpdateVersion", false);
      methods.handleSubmit(handleSave(true), () => FreepadResourceEditor.updateSaveStatus("READY"))();
    },
  };
};
