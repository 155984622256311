import { TechnicalCommentForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import * as React from "react";
import { useForm, UseFormReturn } from "react-hook-form";

export type ContextValue = {
  methods: UseFormReturn<TechnicalCommentForm.TechnicalCommentFormSchema>;
};

export const TechnicalCommentPopperContext = React.createContext<ContextValue>({} as ContextValue);

export const useTechnicalCommentPopperContext = () => React.useContext(TechnicalCommentPopperContext);

export type TechnicalCommentPopperProviderProps = {
  defaultValues: TechnicalCommentForm.TechnicalCommentFormSchema;
};

export const TechnicalCommentPopperProvider: React.FC<React.PropsWithChildren<TechnicalCommentPopperProviderProps>> = props => {
  const schema = TechnicalCommentForm.useTechnicalCommentFormSchema();
  const methods = useForm<TechnicalCommentForm.TechnicalCommentFormSchema>({
    resolver: zodResolver(schema),
    mode: "onSubmit",
    shouldUnregister: false,
    defaultValues: props.defaultValues,
  });
  const contextValue: ContextValue = {
    methods,
  };
  return <TechnicalCommentPopperContext.Provider value={contextValue} children={props.children} />;
};
