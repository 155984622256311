import { SupportLanguage, SupportLanguageValue, useChangeLanguage, useCurrentLanguage, useTranslation } from "@hireroo/i18n";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import Link, { LinkProps } from "../../../primitive/Link/Link";
import NativeDropDown, { NativeDropDownProps } from "../NativeDropDown/NativeDropDown";

export type SignInFooterProps = {
  onChangeLanguage?: (language: SupportLanguageValue) => void;
  helpCenterLink: Pick<LinkProps, "href" | "onClick">;
  privacyPolicyLink?: Pick<LinkProps, "href" | "onClick">;
  termsOfUseLink?: Pick<LinkProps, "href" | "onClick">;
};

const SignInFooter: React.FC<SignInFooterProps> = props => {
  const { t } = useTranslation();
  const changeLanguage = useChangeLanguage();
  const defaultLanguage = useCurrentLanguage();
  const dropDown: NativeDropDownProps = {
    options: [
      {
        text: "日本語",
        value: SupportLanguage.JA,
        default: defaultLanguage === SupportLanguage.JA,
      },
      {
        text: "English",
        value: SupportLanguage.EN,
        default: defaultLanguage === SupportLanguage.EN,
      },
    ],
    variant: "outlined",
    onChange: event => {
      if (event.target.value === SupportLanguage.JA) {
        changeLanguage(SupportLanguage.JA);
        props.onChangeLanguage?.(SupportLanguage.JA);
      } else if (event.target.value === SupportLanguage.EN) {
        changeLanguage(SupportLanguage.EN);
        props.onChangeLanguage?.(SupportLanguage.EN);
      }
    },
  };

  return (
    <Stack direction="row" display="flex" justifyContent="space-between" width="100%" alignItems="center">
      <NativeDropDown {...dropDown} />
      <Stack direction="row" alignItems="center" spacing={1.5}>
        <Typography fontSize={14}>
          <Link target="_blank" {...props.helpCenterLink} underline="hover" color="secondary">
            {t("ヘルプ")}
          </Link>
        </Typography>
        {props.privacyPolicyLink && (
          <Typography fontSize={14}>
            <Link target="_blank" {...props.privacyPolicyLink} underline="hover" color="secondary">
              {t("プライバシー")}
            </Link>
          </Typography>
        )}
        {props.termsOfUseLink && (
          <Typography fontSize={14}>
            <Link target="_blank" {...props.termsOfUseLink} underline="hover" color="secondary">
              {t("規約")}
            </Link>
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

SignInFooter.displayName = "SignInFooter";

export default SignInFooter;
