import { styled } from "@mui/material/styles";
import * as React from "react";

import EmployeeLayout, { EmployeeLayoutProps } from "../../layouts/EmployeeLayout/EmployeeLayout";

const StyledEmployeeLayout = styled(EmployeeLayout)`
  overflow: hidden;
`;

export type ScreeningDetailProps = {
  layout: EmployeeLayoutProps;
  children: React.ReactNode;
};

const ScreeningDetail: React.FC<ScreeningDetailProps> = props => {
  return <StyledEmployeeLayout {...props.layout}>{props.children}</StyledEmployeeLayout>;
};

ScreeningDetail.displayName = "ScreeningDetail";

export default ScreeningDetail;
