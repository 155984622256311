import { invertTextOperation } from "@hireroo/app-helper/firepad";
import { SyncOperation } from "@hireroo/app-helper/firepad";
import { composeTextOperation } from "@hireroo/app-helper/hooks";
import { applyOperation } from "@hireroo/app-helper/monaco";
import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import type { ITextModel, Monaco } from "@hireroo/code-editor/react/CodeEditor";
import type { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/browser";

type Source = ProjectTestReport.ProjectContentsViewerV1.Source;

export const getSource = async (url: string): Promise<Source> => {
  if (!url) {
    return {
      type: "EMPTY",
      value: "",
    };
  }
  return new Promise<Source>(resolve => {
    fetch(url).then(res => {
      const contentType = res.headers.get("Content-Type");
      if (contentType && contentType.startsWith("text/")) {
        return res
          .text()
          .then(text => {
            return resolve({
              type: "TEXT",
              value: text,
            });
          })
          .catch(error => {
            Sentry.captureException(error);
            return resolve({
              type: "ERROR",
              value: "",
            });
          });
      } else if (contentType && contentType.startsWith("image/")) {
        return res
          .blob()
          .then(blob => {
            return resolve({
              type: "IMAGE",
              value: URL.createObjectURL(blob),
            });
          })
          .catch(error => {
            Sentry.captureException(error);
            return resolve({
              type: "ERROR",
              value: "",
            });
          });
      }
      return resolve({
        type: "EMPTY",
        value: "",
      });
    });
  });
};

type TabMark = Widget.ProjectContentsViewerV1Props["tabs"][0]["op"];

export const OperationMap: Record<ProjectTestReport.ProjectContentsViewerV1.ProjectDiff["operation"], TabMark> = {
  ADDITION: "ADDITION",
  DELETION: "DELETION",
  MODIFICATION: "MODIFICATION",
  RENAME: "RENAME",
  UNKNOWN: "UNKNOWN",
};

export type UpdateEditorValueArgs = {
  model: ITextModel;
  syncOperations: SyncOperation[];
  newIndex: number;
  monaco: Monaco;
  sliderIndex: number;
};

export const updateEditorValue = (args: UpdateEditorValueArgs) => {
  const { syncOperations, model, newIndex, sliderIndex, monaco } = args;
  if (newIndex > sliderIndex) {
    // move forward
    for (let i = 0; i < newIndex - sliderIndex; i++) {
      const forwardIndex = sliderIndex + i + 1;
      if (forwardIndex < syncOperations.length) {
        applyOperation(syncOperations[forwardIndex].o, monaco, model);
      }
    }
  } else if (newIndex < sliderIndex) {
    // move backward
    for (let i = 0; i < sliderIndex - newIndex; i++) {
      const backwardIndex = sliderIndex - i;
      if (backwardIndex < syncOperations.length) {
        const inverseOp = invertTextOperation(syncOperations[backwardIndex].o, composeTextOperation(backwardIndex - 1, syncOperations));
        applyOperation(inverseOp, monaco, model);
      }
    }
  }
};
