import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import FormSubmitButton, { FormSubmitButtonProps } from "../Button/FormSubmitButton";

export type StepContentLayoutProps = {
  title: string;
  description: string;
  previousButton?: ButtonProps;

  middleActionButton?: FormSubmitButtonProps;
  nextButton: FormSubmitButtonProps;
};

const StepContentLayout: React.FC<React.PropsWithChildren<StepContentLayoutProps>> = props => {
  const commonFormSubmitButton: FormSubmitButtonProps = {
    sx: {
      height: "36px",
      whiteSpace: "nowrap",
    },
    size: "small",
  };
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start" pt={4}>
        <Box display="flex" flexDirection="column">
          <Box mt={2} mb={2}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {props.title}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1">{props.description}</Typography>
          </Box>
        </Box>
        <Stack direction="row" spacing={2}>
          {props.previousButton && <Button size="small" {...props.previousButton} />}
          {props.middleActionButton && <FormSubmitButton variant="text" {...commonFormSubmitButton} {...props.middleActionButton} />}
          <FormSubmitButton variant="contained" {...commonFormSubmitButton} {...props.nextButton} />
        </Stack>
      </Box>
      <Box mt={5}>{props.children}</Box>
    </Box>
  );
};

StepContentLayout.displayName = "StepContentLayout";

export default StepContentLayout;
