import { useTranslation } from "@hireroo/i18n";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";
import Link from "../../primitive/Link/Link";

export type DownloadResultDialogProps = {
  title: string;
  open: boolean;
  url: string;
  onClose: DialogWithCloseIconProps["onClose"];
};

const DownloadResultDialog: React.FC<DownloadResultDialogProps> = props => {
  const { t } = useTranslation();
  const dialogWithCloseIconProps: DialogWithCloseIconProps = {
    title: props.title,
    open: props.open,
    onClose: props.onClose,
    optionalContent: {
      sx: { py: 3, px: 2 },
    },
  };
  return (
    <DialogWithCloseIcon {...dialogWithCloseIconProps}>
      <Typography variant="body2" mb={2}>
        {t("ダウンロードは自動的に始まります。始まらない場合は、")}{" "}
        <Link href={props.url} target="_blank" color="secondary">
          {t("手動でダウンロードしてください。")}
        </Link>
      </Typography>
      {/* @see https://github.com/hireroo/hireroo/blob/main/microservices/exporter/app/tasks/packages/uploader/src/index.ts#L27 */}
      <Typography variant="body2" color="text.secondary">
        {t("※ ダウンロードリンクは発行されてから15分間だけ有効です。")}
      </Typography>
    </DialogWithCloseIcon>
  );
};

DownloadResultDialog.displayName = "DownloadResultDialog";

export default DownloadResultDialog;
