import { useTranslation } from "@hireroo/i18n";
import Dehaze from "@mui/icons-material/Dehaze";
import Remove from "@mui/icons-material/Remove";
import Box from "@mui/material/Box";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow, { TableRowProps } from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";

export type SelectedQuestionTableRowProps = {
  id: string;
  isPrivate: boolean;
  order?: number;
  title: string;
  difficulty: string;
  onClick: TableRowProps["onClick"];
  deleteButton: Pick<IconButtonProps, "onClick">;
  dragStatus: "DRAGGING" | "UNDRAGGED";
};

const StyledCell = styled(TableCell)({
  maxWidth: "100px",
  whiteSpace: "nowrap",
});

const StyledCircleBox = styled(Box)`
  width: 15px;
  height: 15px;
  border-radius: 50%;
`;

export type QuestionRowRefType = React.RefObject<HTMLTableRowElement> | ((instance: HTMLTableRowElement | null) => void) | null | undefined;

const SelectedQuestionTableRow = React.forwardRef((props: SelectedQuestionTableRowProps, ref: QuestionRowRefType) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isShown, setIsShown] = React.useState<boolean>(false);
  const tableRowProps: TableRowProps = {
    hover: true,
    sx: { "&:last-child td, &:last-child th": { border: 0 }, opacity: props.dragStatus === "DRAGGING" ? 0 : 1, cursor: "pointer" },
    ref: ref,
    onMouseEnter: () => setIsShown(true),
    onMouseLeave: () => setIsShown(false),
    onClick: props.onClick,
  };
  return (
    <TableRow {...tableRowProps}>
      <TableCell width="24px">
        {isShown && (
          <Box display="flex" alignItems="center" justifyContent="center" sx={{ cursor: "move" }}>
            <Dehaze sx={{ fontSize: "15px" }} />
          </Box>
        )}
      </TableCell>
      <TableCell component="th" scope="row" width="30px" align="right">
        {props.order}
      </TableCell>
      <StyledCell>
        <Box width="100%" display="flex">
          <Tooltip title={props.isPrivate ? t("非公開に設定されている設問です。") : t("公開に設定されている設問です。")}>
            <StyledCircleBox
              sx={{ backgroundColor: props.isPrivate ? theme.palette.grey[500] : theme.palette.primary.dark }}
              display="flex"
              alignItems="center"
              m="auto"
              mr={1}
            />
          </Tooltip>
          <Box width="90%" overflow="hidden" textOverflow="ellipsis">
            {props.title}
          </Box>
        </Box>
      </StyledCell>
      <TableCell>{props.difficulty}</TableCell>
      <TableCell>
        <IconButton {...props.deleteButton}>
          <Remove sx={{ fontSize: "15px" }} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
});

SelectedQuestionTableRow.displayName = "SelectedQuestionTableRow";

export default SelectedQuestionTableRow;
