import { useEffect } from "react";
import { Room } from "twilio-video";

/**
 * This hook allows components to reliably use the 'disconnected' event of the Room object.
 */
export const useHandleRoomDisconnection = (room: Room | null, removeLocalAudioTrack: () => void, removeLocalVideoTrack: () => void) => {
  useEffect(() => {
    if (room) {
      const onDisconnected = (_: Room) => {
        removeLocalAudioTrack();
        removeLocalVideoTrack();
      };

      room.on("disconnected", onDisconnected);
      return () => {
        room.off("disconnected", onDisconnected);
      };
    }
  }, [room, removeLocalAudioTrack, removeLocalVideoTrack]);
};
