import { useTranslation } from "@hireroo/i18n";
import { RemoteInterviewResourceEditor } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import ResourceField from "../../../../modules/ResourceFieldV2/ResourceFieldV2";
import InputControlTextField, {
  InputControlTextFieldProps,
} from "../../../../primitive/InputControl/InputControlTextField/InputControlTextField";
import { LayoutController, useRemoteInterviewResourceEditorContext } from "../../Context";

type FieldName = keyof RemoteInterviewResourceEditor.RemoteQuestionSetupFormSchema;

const ReservedFieldName = {
  TITLE: "name",
  CANDIDATE_NAME: "candidateName",
} satisfies Record<string, FieldName>;

const Subsection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  boxShadow: "none",
}));

export type QuestionSetupSectionProps = {
  mode: "CREATE" | "EDIT";
  QuestionSelectField: React.ReactNode;
  onSubmit: SubmitHandler<RemoteInterviewResourceEditor.RemoteQuestionSetupFormSchema>;
  defaultValues: RemoteInterviewResourceEditor.RemoteQuestionSetupFormSchema;
};

const QuestionSetupSection: React.FC<QuestionSetupSectionProps> = props => {
  const { t } = useTranslation();
  const { onSubmit } = props;
  const { setTitle, stepName, setController, submitValues, setSubmitValue, setResetHandler } = useRemoteInterviewResourceEditorContext();
  const schema = RemoteInterviewResourceEditor.useRemoteQuestionSetupFormSchema();
  const methods = useForm({
    resolver: zodResolver(schema),
    defaultValues: submitValues["REMOTE_QUESTION_SETUP"] ?? props.defaultValues,
  });

  const controller = React.useMemo((): LayoutController => {
    return {
      checkGoNextStep: () => {
        return new Promise<boolean>(resolve => {
          methods.handleSubmit(
            fields => {
              onSubmit(fields);
              setSubmitValue("REMOTE_QUESTION_SETUP", fields);
              resolve(true);
            },
            errors => {
              setSubmitValue("REMOTE_QUESTION_SETUP", undefined);
              console.warn(errors);
              resolve(false);
            },
          )();
        });
      },
    };
  }, [methods, onSubmit, setSubmitValue]);

  React.useEffect(() => {
    setController("REMOTE_QUESTION_SETUP", controller);
  }, [setController, controller]);

  React.useEffect(() => {
    setResetHandler("REMOTE_QUESTION_SETUP", methods.reset);
  }, [setResetHandler, methods.reset]);
  const titleFieldProps: InputControlTextFieldProps = {
    fullWidth: true,
    placeholder: t("フロントエンジニア採用向け試験"),
  };
  const candidateNameFieldProps: InputControlTextFieldProps = {
    fullWidth: true,
    placeholder: t("灰屋 太郎"),
  };

  const name = methods.watch("name");

  React.useEffect(() => {
    setTitle(name);
  }, [name, setTitle]);

  if (stepName !== "REMOTE_QUESTION_SETUP") {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <Stack py={2} spacing={1.5}>
        <Subsection>
          <ResourceField label={t("タイトル")} kind="REQUIRED" pb={2}>
            <InputControlTextField name={ReservedFieldName.TITLE} {...titleFieldProps} />
          </ResourceField>
          <ResourceField label={t("候補者名")} kind="REQUIRED" pb={2}>
            <InputControlTextField name={ReservedFieldName.CANDIDATE_NAME} {...candidateNameFieldProps} />
          </ResourceField>
        </Subsection>

        <Subsection>
          <ResourceField label={t("出題問題")} kind="NONE" pb={2}>
            {props.QuestionSelectField}
          </ResourceField>
        </Subsection>
      </Stack>
    </FormProvider>
  );
};

QuestionSetupSection.displayName = "QuestionSetupSection";

export default QuestionSetupSection;
