import Box from "@mui/material/Box";
import * as React from "react";

import QuizQuestionMultiChoice, { QuizQuestionMultiChoiceProps } from "../../../QuizQuestionMultiChoice/QuizQuestionMultiChoice";
import QuizQuestionSingleChoice, { QuizQuestionSingleChoiceProps } from "../../../QuizQuestionSingleChoice/QuizQuestionSingleChoice";

export type SingleChoiceContentProps = {
  kind: "SINGLE_CHOICE";
  content: QuizQuestionSingleChoiceProps;
};

export type MultiChoiceContentProps = {
  kind: "MULTI_CHOICE";
  content: QuizQuestionMultiChoiceProps;
};

export type EvaluationContentProps = {
  kind: "FREE_TEXT";
  content: React.ReactNode;
};

export type QuizQuestionCardContentProps = SingleChoiceContentProps | MultiChoiceContentProps | EvaluationContentProps;

const QuizQuestionCardContent: React.FC<QuizQuestionCardContentProps> = props => {
  return (
    <Box pt={2}>
      {props.kind === "SINGLE_CHOICE" && <QuizQuestionSingleChoice {...props.content} />}
      {props.kind === "MULTI_CHOICE" && <QuizQuestionMultiChoice {...props.content} />}
      {props.kind === "FREE_TEXT" && props.content}
    </Box>
  );
};

QuizQuestionCardContent.displayName = "QuizQuestionCardContent";

export default QuizQuestionCardContent;
