import { useTranslation } from "@hireroo/i18n";
import Circle from "@mui/icons-material/Circle";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const ActiveStack = styled(Stack)(({ theme }) => ({
  borderRadius: "20px",
  border: "1px solid",
  borderColor: theme.palette.primary.main,
  paddingLeft: theme.spacing(0.5),
  paddingRight: theme.spacing(0.5),
}));
const InactiveStack = styled(Stack)(({ theme }) => ({
  borderRadius: "20px",
  border: "1px solid",
  borderColor: theme.palette.text.disabled,
  paddingLeft: theme.spacing(0.5),
  paddingRight: theme.spacing(0.5),
}));
type ActiveStatus = "ACTIVE" | "INACTIVE";

export type ActiveStatusChipProps = {
  status: ActiveStatus;
};

const ActiveStatusChip: React.FC<ActiveStatusChipProps> = props => {
  const { t } = useTranslation();
  const ChipMap: Record<ActiveStatus, React.ReactNode> = {
    ACTIVE: (
      <ActiveStack direction="row" alignItems="center" spacing={0.5}>
        <Circle color="primary" sx={{ height: "8px", width: "8px" }} />
        <Typography lineHeight="166%" color="primary" noWrap fontSize={12}>
          {t("受付中")}
        </Typography>
      </ActiveStack>
    ),
    INACTIVE: (
      <InactiveStack direction="row" alignItems="center" spacing={0.5}>
        <Circle color="disabled" sx={{ height: "8px", width: "8px" }} />
        <Typography lineHeight="166%" color="text.disabled" noWrap fontSize={12}>
          {t("受付停止中")}
        </Typography>
      </InactiveStack>
    ),
  };
  return ChipMap[props.status];
};

ActiveStatusChip.displayName = "ActiveStatusChip";

export default ActiveStatusChip;
