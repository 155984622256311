import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type RemoteInterviewOverviewContainerProps = {};

const RemoteInterviewOverviewContainer: React.FC<RemoteInterviewOverviewContainerProps> = () => {
  const remoteInterviewOverviewProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.RemoteInterviewOverview {...remoteInterviewOverviewProps} />
    </ErrorBoundary>
  );
};

RemoteInterviewOverviewContainer.displayName = "RemoteInterviewOverviewContainer";

export default withErrorBoundary(RemoteInterviewOverviewContainer, {});
