import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateScreeningTestRetakeDialogPropsArgs, useGenerateProps } from "./useGenerateProps";

export type ScreeningTestRetakeDialogContainerProps = GenerateScreeningTestRetakeDialogPropsArgs;

const ScreeningTestRetakeDialogContainer: React.FC<ScreeningTestRetakeDialogContainerProps> = props => {
  const screeningTestRetakeDialogProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ScreeningTestRetakeDialog {...screeningTestRetakeDialogProps} />
    </ErrorBoundary>
  );
};

ScreeningTestRetakeDialogContainer.displayName = "ScreeningTestRetakeDialogContainer";

export default withErrorBoundary(ScreeningTestRetakeDialogContainer, {});
