import type * as Interface from "@hireroo/evaluation-result-interface";
import { useLanguageCode } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import MuiStepper from "@mui/material/Stepper";
import * as React from "react";

import TestCaseResult from "../TestCaseResult/TestCaseResult";

export type StepperProps = Interface.Stepper["value"];

const Stepper: React.FC<StepperProps> = props => {
  const lang = useLanguageCode();
  return (
    <Box>
      <MuiStepper orientation="vertical">
        {props.contents.map((content, index) => (
          <Step key={`step-${index}`} active>
            <StepLabel>{resolveLanguage(content.value.header, lang, "translateText")}</StepLabel>
            <StepContent>
              <TestCaseResult {...content.value.testCaseResult} />
            </StepContent>
          </Step>
        ))}
      </MuiStepper>
    </Box>
  );
};

Stepper.displayName = "Stepper";

export default Stepper;
