import { languageMapForDisplay } from "@hireroo/challenge/definition";
import { useTranslation } from "@hireroo/i18n";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectProps } from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import BaseDialog, { BaseDialogProps } from "../../../../../primitive/BaseDialog/BaseDialog";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&.MuiMenuItem-root": {
    "&:hover ": {
      backgroundColor: theme.palette["Secondary/Shades"].p8,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette["Secondary/Shades"].p16,
    },
  },
}));

export type RuntimeSelectorDialogProps = {
  defaultValue: Exclude<SelectProps<string>["value"], undefined> | null;
  baseDialog: Pick<BaseDialogProps, "open">;
  enabledLanguages: string[];
  onSubmit: (value: string) => void;
};

const RuntimeSelectorDialog: React.FC<RuntimeSelectorDialogProps> = props => {
  const { enabledLanguages } = props;
  const { t } = useTranslation();
  const [language, setLanguage] = React.useState<string>(props.defaultValue || "");

  const baseDialogProps: BaseDialogProps = {
    title: t("言語指定"),
    disableEnter: true,
    yesButton: {
      disabled: !language,
      children: t("確定"),
      onClick: () => {
        if (enabledLanguages.includes(language)) {
          props.onSubmit(language);
        }
      },
    },
    optionalDialog: {
      PaperProps: {
        sx: {
          width: 1,
          maxWidth: 600,
        },
      },
    },
    ...props.baseDialog,
  };

  const handleSelectEvent: SelectProps<string>["onChange"] = event => {
    setLanguage(event.target.value);
  };

  return (
    <BaseDialog {...baseDialogProps}>
      <Typography>
        {t("解答に使用する言語を選択してください。")}
        <br />
        {t("※ 解答する言語は後から変更することが可能です。")}
      </Typography>
      <Select
        value={language}
        sx={{ mt: 2 }}
        fullWidth
        size={"small"}
        color="secondary"
        variant={"outlined"}
        displayEmpty
        onChange={handleSelectEvent}
      >
        <StyledMenuItem disabled value="">
          <Typography sx={{ color: "text.secondary" }}>{t("選択してください")}</Typography>
        </StyledMenuItem>
        {props.enabledLanguages.map(enabledLanguage => (
          <StyledMenuItem key={enabledLanguage} value={enabledLanguage}>
            {languageMapForDisplay[enabledLanguage] ?? enabledLanguage}
          </StyledMenuItem>
        ))}
      </Select>
    </BaseDialog>
  );
};

RuntimeSelectorDialog.displayName = "RuntimeSelectorDialog";

export default RuntimeSelectorDialog;
