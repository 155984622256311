import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type ScreeningSearchAreaContainerProps = {};

const ScreeningSearchAreaContainer: React.FC<ScreeningSearchAreaContainerProps> = () => {
  const screeningSearchAreaProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.ScreeningSearchArea {...screeningSearchAreaProps} />
    </ErrorBoundary>
  );
};

ScreeningSearchAreaContainer.displayName = "ScreeningSearchAreaContainer";

export default withErrorBoundary(ScreeningSearchAreaContainer, {});
