import { useTranslation } from "@hireroo/i18n";
import { ButtonProps } from "@mui/material/Button";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import * as React from "react";

import CommonLayout, { CommonLayoutProps } from "../../layout/CommonLayout/CommonLayout";
import StepContentLayout, { StepContentLayoutProps } from "../../primitive/StepContentLayout/StepContentLayout";
import AlgorithmAnswersForm, { AlgorithmAnswersFormProps } from "../../widget/AlgorithmAnswersForm/AlgorithmAnswersForm";
import AlgorithmFunctionDefinition, {
  AlgorithmFunctionDefinitionProps,
} from "../../widget/AlgorithmFunctionDefinition/AlgorithmFunctionDefinition";
import AlgorithmHintsForm, { AlgorithmHintsFormProps } from "../../widget/AlgorithmHintsForm/AlgorithmHintsForm";
import AlgorithmQuestionDetailForm, {
  AlgorithmQuestionDetailFormProps,
} from "../../widget/AlgorithmQuestionDetailForm/AlgorithmQuestionDetailForm";
import AlgorithmTestCasesForm, { AlgorithmTestCasesFormProps } from "../../widget/AlgorithmTestCasesForm/AlgorithmTestCasesForm";
import DatabaseTableSignatureForm, { DatabaseTableSignatureProps } from "../../widget/DatabaseTableSignature/DatabaseTableSignature";

export { AlgorithmResourceEditorProvider, type AlgorithmResourceEditorProviderProps, useAlgorithmResourceEditorContext } from "./Context";

type QuestionVariant = "ALGORITHM" | "DATABASE" | "CLASS";
export type AlgorithmResourceEditorProps = {
  layout: Omit<CommonLayoutProps, "heading">;
  /** greater than or equal to 0 */
  activeStep: number;
  variant: QuestionVariant;

  questionDetails: AlgorithmQuestionDetailFormProps;

  saveQuestionDetailsButton: Pick<StepContentLayoutProps["nextButton"], "disabled" | "loading" | "onClick">;

  draftSaveButton: StepContentLayoutProps["middleActionButton"];
  functionDefinition: AlgorithmFunctionDefinitionProps;
  saveFunctionDefinitionButton: Pick<StepContentLayoutProps["nextButton"], "disabled" | "loading" | "onClick">;
  testCases: AlgorithmTestCasesFormProps;
  saveTestCasesButton: Pick<StepContentLayoutProps["nextButton"], "disabled" | "loading" | "onClick">;
  answers: AlgorithmAnswersFormProps;
  saveAnswersButton: Pick<StepContentLayoutProps["nextButton"], "disabled" | "loading" | "onClick">;

  hints: AlgorithmHintsFormProps;
  saveHintsButton: Pick<StepContentLayoutProps["nextButton"], "disabled" | "loading" | "onClick">;
  tableSignature: DatabaseTableSignatureProps;
  saveTableSignatureButton: Pick<StepContentLayoutProps["nextButton"], "disabled" | "loading" | "onClick">;

  previousButton: ButtonProps;
};

const AlgorithmResourceEditor: React.FC<AlgorithmResourceEditorProps> = props => {
  const { t } = useTranslation();

  const layoutProps: CommonLayoutProps = {
    ...props.layout,
    heading: {
      text: t("問題作成"),
    },
  };

  /*
   * QuestionDetailInput 問題概要入力
   * */
  const questionDetailLayout: StepContentLayoutProps = {
    title: t("問題概要入力"),
    description: t("問題の概要を入力します。候補者が問題を把握しやすいようにわかりやすく入力ください。"),
    nextButton: {
      ...props.saveQuestionDetailsButton,
      children: t("保存して次へ"),
    },
  };

  /*
   * FunctionDefinition 関数の定義
   * */
  const functionDefinition: StepContentLayoutProps = {
    title: t("関数の定義"),
    description: t("問題で使う関数の定義をします。引数名と引数と返り値の型を決めます。"),
    previousButton: {
      ...props.previousButton,
      children: t("前に戻る"),
    },
    middleActionButton: {
      ...props.draftSaveButton,
      children: t("一時保存する"),
    },
    nextButton: {
      ...props.saveFunctionDefinitionButton,
      children: t("保存して次へ"),
    },
  };
  /*
   * TableSignature スキーマ定義
   * */
  const tableSignature: StepContentLayoutProps = {
    title: t("スキーマ定義"),
    description: t("問題で使うテーブルのスキーマの定義をします。入力するテーブル名、カラム名、型と返り値のカラムを設定します。"),
    previousButton: {
      ...props.previousButton,
      children: t("前に戻る"),
    },
    middleActionButton: {
      ...props.draftSaveButton,
      children: t("一時保存する"),
    },
    nextButton: {
      ...props.saveTableSignatureButton,
      children: t("保存して次へ"),
    },
  };

  /*
   * TestCases テストケースの追加
   * */
  const testCases: StepContentLayoutProps = {
    title: t("テストケースの追加"),
    description: t("定義した関数に基づきテストケースを追加します。自動採点の精度を上げるために複数入力がおすすめです。"),
    previousButton: {
      ...props.previousButton,
      children: t("前に戻る"),
    },
    middleActionButton: {
      ...props.draftSaveButton,
      children: t("一時保存する"),
    },
    nextButton: {
      ...props.saveTestCasesButton,
      children: t("保存して次へ"),
    },
  };

  /*
   * AnswersInput 解答の入力
   * */
  const answers: StepContentLayoutProps = {
    title: t("解答の入力"),
    description: t("解答を入力します。評価時に利用する解答文や、候補者の提出したコードと比較する解答コードを追加します。"),
    previousButton: {
      ...props.previousButton,
      children: t("前に戻る"),
    },
    middleActionButton: {
      ...props.draftSaveButton,
      children: t("一時保存する"),
    },
    nextButton: {
      ...props.saveAnswersButton,
      children: t("保存して次へ"),
    },
  };

  /*
   * HintInput ヒント(任意）その他項目
   * */
  const hints: StepContentLayoutProps = {
    title: t("（任意）その他項目"),
    description: t("ヒントなど問題で設定できる項目を入力します。後からでも追加が可能です。"),
    previousButton: {
      ...props.previousButton,
      children: t("前に戻る"),
    },
    middleActionButton: {
      ...props.draftSaveButton,
      children: t("一時保存する"),
    },
    nextButton: {
      ...props.saveHintsButton,
      children: t("保存して次へ"),
    },
  };

  const algorithmSteps = [
    {
      title: questionDetailLayout.title,
      Content: (
        <StepContentLayout {...questionDetailLayout}>
          <AlgorithmQuestionDetailForm {...props.questionDetails} />
        </StepContentLayout>
      ),
    },
    {
      title: functionDefinition.title,
      Content: (
        <StepContentLayout {...functionDefinition}>
          <AlgorithmFunctionDefinition {...props.functionDefinition} />
        </StepContentLayout>
      ),
    },
    {
      title: testCases.title,
      Content: (
        <StepContentLayout {...testCases}>
          <AlgorithmTestCasesForm {...props.testCases} />
        </StepContentLayout>
      ),
    },
    {
      title: answers.title,
      Content: (
        <StepContentLayout {...answers}>
          <AlgorithmAnswersForm {...props.answers} />
        </StepContentLayout>
      ),
    },
    {
      title: hints.title,
      Content: (
        <StepContentLayout {...hints}>
          <AlgorithmHintsForm {...props.hints} />
        </StepContentLayout>
      ),
    },
  ];

  const databaseSteps = [
    {
      title: questionDetailLayout.title,
      Content: (
        <StepContentLayout {...questionDetailLayout}>
          <AlgorithmQuestionDetailForm {...props.questionDetails} />
        </StepContentLayout>
      ),
    },
    {
      title: tableSignature.title,
      Content: (
        <StepContentLayout {...tableSignature}>
          <DatabaseTableSignatureForm {...props.tableSignature} />
        </StepContentLayout>
      ),
    },
    {
      title: testCases.title,
      Content: (
        <StepContentLayout {...testCases}>
          <AlgorithmTestCasesForm {...props.testCases} />
        </StepContentLayout>
      ),
    },
    {
      title: answers.title,
      Content: (
        <StepContentLayout {...answers}>
          <AlgorithmAnswersForm {...props.answers} />
        </StepContentLayout>
      ),
    },
    {
      title: hints.title,
      Content: (
        <StepContentLayout {...hints}>
          <AlgorithmHintsForm {...props.hints} />
        </StepContentLayout>
      ),
    },
  ];

  const stepsMap: Record<QuestionVariant, { title: string; Content: React.ReactNode }[]> = {
    ALGORITHM: algorithmSteps,
    DATABASE: databaseSteps,
    // TODO: Please change when we have a new variant class
    CLASS: algorithmSteps,
  };

  const steps = stepsMap[props.variant];

  return (
    <CommonLayout {...layoutProps}>
      <Stepper orientation="vertical" activeStep={props.activeStep} sx={{ mt: 3 }}>
        {steps.map(step => {
          return (
            <Step key={step.title}>
              <StepLabel>{step.title}</StepLabel>
              <StepContent>{step.Content}</StepContent>
            </Step>
          );
        })}
      </Stepper>
    </CommonLayout>
  );
};

AlgorithmResourceEditor.displayName = "AlgorithmResourceEditor";

export default AlgorithmResourceEditor;
