import { ErrorMessage } from "@hookform/error-message";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useController } from "react-hook-form";

import InputControlTextField, {
  InputControlTextFieldProps,
} from "../../../../primitive/InputControl/InputControlTextField/InputControlTextField";

const StyledInputControlTextField = styled(InputControlTextField)(() => ({
  width: "80px",
  "& input": {
    textAlign: "center",
  },
}));

const WrapperStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: "8px",
  padding: theme.spacing(2),
}));

export type ScoreWeightInputProps = {
  id: string;
  title: string;
  name: string;
  questionVariant?: string;
};

const ScoreWeightInput: React.FC<ScoreWeightInputProps> = props => {
  const theme = useTheme();
  const {
    fieldState: { error },
  } = useController({
    name: props.name,
  });

  const textField: InputControlTextFieldProps = {
    type: "number",
    inputProps: {
      min: 1,
      max: 100,
      step: 1,
    },
    ignoreErrorMessage: "IGNORE_ERROR_MESSAGE_HELPER_TEXT",
  };
  return (
    <WrapperStack direction="column" width="100%" spacing={0.5}>
      <Stack direction="row" justifyContent="space-between" width="100%">
        <Stack direction="column" spacing={0.5} sx={{ justifyContent: "center" }}>
          <Typography fontSize={16} fontWeight={700}>
            {props.title}
          </Typography>
          {props.questionVariant && (
            <Stack direction="row" alignItems="center">
              <LabelOutlinedIcon fontSize="small" sx={{ color: theme.palette.text.secondary }} />
              <Typography fontSize={14} color="textSecondary">
                {props.questionVariant}
              </Typography>
            </Stack>
          )}
        </Stack>
        <Stack>
          <StyledInputControlTextField name={props.name} {...textField} />
        </Stack>
      </Stack>
      {error && (
        <Stack direction="row" justifyContent="end">
          <ErrorMessage
            name={props.name}
            render={({ message }) => (
              <Typography fontSize={12} color="error">
                {message}
              </Typography>
            )}
          />
        </Stack>
      )}
    </WrapperStack>
  );
};

ScoreWeightInput.displayName = "ScoreWeightInput";

export default ScoreWeightInput;
