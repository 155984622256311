import { subscribe } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

export type SubscribeSelectedQuestionsCallback = (questions: Types.State["selectedQuestions"]) => void;

export const subscribeSelectedQuestions = (callback: SubscribeSelectedQuestionsCallback) => {
  return subscribe(state.selectedQuestions, () => {
    callback(state.selectedQuestions);
  });
};
