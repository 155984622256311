import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import * as z from "zod";

import * as AssignField from "./fields/AssignField";
import * as EntityTrack from "./fields/EntityTrack";
import * as TimeLimitField from "./fields/TimeLimitField";

type Mode = "EDIT" | "CREATE";

type Option = {
  mode: Mode;
  defaultNextScheduledAt: Date;
};

export type TimeLimitTypeFieldSchema = TimeLimitField.TimeLimitTypeFieldSchema;

export const useAssessmentShareSettingsFormSchema = () => {
  return z.object({
    showAnswer: z.boolean(),
    showPlayback: z.boolean(),
    showRelativeEvaluation: z.boolean(),
  });
};

export type AssessmentShareSettingsFormSchema = z.infer<ReturnType<typeof useAssessmentShareSettingsFormSchema>>;

export const useExamInterval = () => {
  return z.union([z.literal("ONE_WEEK"), z.literal("TWO_WEEKS"), z.literal("ONE_MONTH"), z.literal("TWO_MONTHS"), z.literal("THREE_MONTHS")]);
};

export type ExamIntervalSchema = z.infer<ReturnType<typeof useExamInterval>>;

const useNextStartScheduleAt = () => {
  const { t } = useTranslation();
  const minDate = new Date();
  return z.date().min(minDate, { message: t("次の試験実施予定日時は現在時刻よりも後になるように設定してください。") });
};

export const InvalidDateList = [29, 30, 31];

export const UNEDITABLE_NEXT_START_SCHEDULE_DATE_FIELD_TIME_MINUTES = 15;

export const InvalidNextStartScheduleDateMap: Record<ExamIntervalSchema, number[]> = {
  ONE_WEEK: [],
  TWO_WEEKS: InvalidDateList,
  ONE_MONTH: InvalidDateList,
  TWO_MONTHS: InvalidDateList,
  THREE_MONTHS: InvalidDateList,
};

const useExamScheduleSchema = (options: Option) => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const nextStartScheduleAt = useNextStartScheduleAt();
  const examInterval = useExamInterval();
  const examIntervalMap: Record<ExamIntervalSchema, string> = {
    ONE_WEEK: t("1週間"),
    TWO_WEEKS: t("2週間"),
    ONE_MONTH: t("1ヶ月"),
    TWO_MONTHS: t("2ヶ月"),
    THREE_MONTHS: t("3ヶ月"),
  };
  return z
    .object({
      nextStartScheduleAt: nextStartScheduleAt,
      examInterval,
      /**
       * For `Number.NaN`, remind is treated as unspecified
       */
      remindBeforeDays: z.preprocess(
        v => Math.trunc(Number(v)),
        z.union([z.number().min(1, { message: t("1日以上の値を指定してください。") }), z.nan()]),
      ),
    })
    .superRefine((obj, ctx) => {
      if (!Number.isNaN(obj.remindBeforeDays)) {
        switch (obj.examInterval) {
          case "THREE_MONTHS": {
            const valid = obj.remindBeforeDays < 3 * MAXIMUM_ONE_MONTH;
            if (!valid) {
              ctx.addIssue({
                code: z.ZodIssueCode.too_big,
                path: ["remindBeforeDays"],
                maximum: 7,
                type: "number",
                inclusive: true,
                message: t2("validateMessageForRemindDays", { num: 3 * MAXIMUM_ONE_MONTH }),
              });
            }
            break;
          }
          case "TWO_MONTHS": {
            const valid = obj.remindBeforeDays < 2 * MAXIMUM_ONE_MONTH;
            if (!valid) {
              ctx.addIssue({
                code: z.ZodIssueCode.too_big,
                path: ["remindBeforeDays"],
                maximum: 7,
                type: "number",
                inclusive: true,
                message: t2("validateMessageForRemindDays", { num: 2 * MAXIMUM_ONE_MONTH }),
              });
            }
            break;
          }
          case "ONE_MONTH": {
            const valid = obj.remindBeforeDays < MAXIMUM_ONE_MONTH;
            if (!valid) {
              ctx.addIssue({
                code: z.ZodIssueCode.too_big,
                path: ["remindBeforeDays"],
                maximum: 7,
                type: "number",
                inclusive: true,
                message: t2("validateMessageForRemindDays", { num: MAXIMUM_ONE_MONTH }),
              });
            }
            break;
          }
          case "TWO_WEEKS": {
            const valid = obj.remindBeforeDays < 14;
            if (!valid) {
              ctx.addIssue({
                code: z.ZodIssueCode.too_big,
                path: ["remindBeforeDays"],
                maximum: 7,
                type: "number",
                inclusive: true,
                message: t2("validateMessageForRemindDays", { num: 14 }),
              });
            }
            break;
          }
          case "ONE_WEEK": {
            const valid = obj.remindBeforeDays < 7;
            if (!valid) {
              ctx.addIssue({
                code: z.ZodIssueCode.too_big,
                path: ["remindBeforeDays"],
                maximum: 7,
                type: "number",
                inclusive: true,
                message: t2("validateMessageForRemindDays", { num: 7 }),
              });
            }
            break;
          }
        }
      }
      const day = obj.nextStartScheduleAt.getDate(); // 1 ~ 31
      const invalidDateList = InvalidNextStartScheduleDateMap[obj.examInterval];
      if (invalidDateList.includes(day)) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          path: ["nextStartScheduleAt"],
          maximum: 28,
          type: "number",
          inclusive: true,
          message: t2("nextStartScheduleAtIsTooBig", {
            interval: examIntervalMap[obj.examInterval],
          }),
        });
      }
      if (options.mode === "EDIT") {
        const defaultNextScheduledAtMilliSeconds = options.defaultNextScheduledAt.getTime();
        const now = new Date();
        const nextStartScheduleAtMilliSeconds = obj.nextStartScheduleAt.getTime();
        const uneditableTimeMilliSeconds = UNEDITABLE_NEXT_START_SCHEDULE_DATE_FIELD_TIME_MINUTES * 60 * 1000;
        const hasChange = defaultNextScheduledAtMilliSeconds !== nextStartScheduleAtMilliSeconds;
        const cannotEdit = nextStartScheduleAtMilliSeconds - now.getTime() <= uneditableTimeMilliSeconds;
        if (hasChange && cannotEdit) {
          ctx.addIssue({
            code: z.ZodIssueCode.too_small,
            path: ["nextStartScheduleAt"],
            minimum: UNEDITABLE_NEXT_START_SCHEDULE_DATE_FIELD_TIME_MINUTES,
            type: "number",
            inclusive: true,
            message: t2("nextStartScheduleAtIsTooSmall", {
              minutes: UNEDITABLE_NEXT_START_SCHEDULE_DATE_FIELD_TIME_MINUTES,
            }),
          });
        }
      }
    });
};

/**
 * The shortest month is the 28th of February
 */
const MAXIMUM_ONE_MONTH = 28;

export const useCreateAssessment = (options: Option) => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const viewer = AssignField.useAssignListItem();
  const reportSettings = useAssessmentShareSettingsFormSchema();
  const timelimit = TimeLimitField.useTimeLimitFieldSchema();
  const talent = AssignField.useTalentAssignee();
  const entityTrack = EntityTrack.useEntityTrack();
  const timeLimitType = TimeLimitField.useTimeLimitTypeFieldSchema();
  const scheduleSchema = useExamScheduleSchema(options);

  const schema = z.object({
    name: z
      .string()
      .min(1, { message: t("タレントスコアタイトルは必須です。") })
      .max(100, {
        message: t2("ValidateMaxTextSizeMessage", {
          size: 100,
          name: t("タイトル"),
        }),
      })
      .regex(/^[^\cA-\cZ]+$/, t("利用できない文字列が含まれています。")),
    companyId: z.number(),
    talent: talent.refine(arg => (!!arg && arg.talentId !== "") || !arg, { message: t("タレントのアサインは必須項目です。") }),
    employeeId: z.string(),
    entityTracks: entityTrack
      .array()
      .max(10, { message: t("問題は必ず1問以上、10問以下で選択してください。") })
      .min(1, { message: t("問題は必ず1問以上、10問以下で選択してください。") }),
    memo: z
      .string()
      .max(10000, {
        message: t2("ValidateMaxTextSizeMessage", {
          size: 10000,
          name: t("メモ"),
        }),
      })
      .optional(),
    messageForTalent: z
      .string()
      .max(10000, {
        message: t2("ValidateMaxTextSizeMessage", {
          size: 10000,
          name: t("メッセージ"),
        }),
      })
      .optional(),
    timeLimitMinutes: timelimit,
    timeLimitType: timeLimitType,
    viewers: viewer.array(),
    isPublic: z.boolean(),
    reportSettings: reportSettings,
  });

  return z.intersection(schema, scheduleSchema).superRefine((obj, ctx) => {
    if (obj.isPublic === false && obj.viewers.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_small,
        path: ["viewers"],
        minimum: 1,
        type: "number",
        inclusive: true,
        message: t("閲覧できるユーザーまたはグループを1つ以上選択してください。"),
      });
    }
  });
};

export type CreateAssessmentFormSchema = z.infer<ReturnType<typeof useCreateAssessment>>;

/**
 * v2 assessment resource editor form
 */

export type ReservedFieldName =
  | "name"
  | "timeLimitMinutes"
  | "timeLimitType"
  | "viewers"
  | "entitySources"
  | "companyId"
  | "employeeId"
  | "memo"
  | "messageForTalent"
  | "isPublic"
  | "nextStartScheduleAt"
  | "examInterval"
  | "remindBeforeDays"
  | "reportSettings";

export const useTestQuestionSetupForm = () => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const entityTrack = EntityTrack.useEntityTrack();
  const timelimit = TimeLimitField.useTimeLimitFieldSchema();
  const timeLimitType = TimeLimitField.useTimeLimitTypeFieldSchema();

  return z.object({
    companyId: z.number(),
    name: z
      .string()
      .min(1, { message: t("タイトルは必須です。") })
      .max(100, {
        message: t2("ValidateMaxTextSizeMessage", {
          size: 100,
          name: t("タイトル"),
        }),
      })
      .regex(/^[^\cA-\cZ]+$/, t("利用できない文字列が含まれています。")),
    entityTracks: entityTrack
      .array()
      .max(10, { message: t("問題は必ず1問以上、10問以下で選択してください。") })
      .min(1, { message: t("問題は必ず1問以上、10問以下で選択してください。") }),
    timeLimitMinutes: timelimit,
    timeLimitType: timeLimitType,
  });
};
export type TestQuestionSetupFormSchema = z.infer<ReturnType<typeof useTestQuestionSetupForm>>;

export const useTestSetupForm = (options: Option) => {
  const scheduleSchema = useExamScheduleSchema(options);

  return scheduleSchema;
};
export type TestSetupFormSchema = z.infer<ReturnType<typeof useTestSetupForm>>;

export const useReportSetupForm = () => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const viewer = AssignField.useAssignListItem();
  const reportSetting = useAssessmentShareSettingsFormSchema();

  return z.object({
    memo: z
      .string()
      .max(10000, {
        message: t2("ValidateMaxTextSizeMessage", {
          size: 10000,
          name: t("メモ"),
        }),
      })
      .optional(),
    isPublic: z.boolean(),
    /**
     * User ID to be set when viewing privileges are changed to private.
     */
    editorUid: z.string().nullable(),
    viewers: viewer.array(),
    reportSettings: reportSetting,
  });
};

export type ReportSetupFormSchema = z.infer<ReturnType<typeof useReportSetupForm>>;

export const useTestInviteSetupForm = () => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const talent = AssignField.useTalentAssignee();

  return z.object({
    talent: talent.refine(arg => (!!arg && arg.talentId !== "") || !arg, { message: t("タレントのアサインは必須項目です。") }),
    messageForTalent: z
      .string()
      .max(10000, {
        message: t2("ValidateMaxTextSizeMessage", {
          size: 10000,
          name: t("メッセージ"),
        }),
      })
      .optional(),
  });
};

export type TestInviteSetupFormSchema = z.infer<ReturnType<typeof useTestInviteSetupForm>>;

type CreateAssessmentArgs = {
  options: Option;
};
export const useCreateAssessmentV2 = (args: CreateAssessmentArgs) => {
  const testQuestionSetup = useTestQuestionSetupForm();
  const testSetup = useTestSetupForm(args.options);
  const reportSetup = useReportSetupForm();
  const testInviteSetup = useTestInviteSetupForm();
  return z.object({
    testQuestionSetup,
    testSetup,
    reportSetup,
    testInviteSetup,
  });
};

export type CreateAssessmentV2FormSchema = z.infer<ReturnType<typeof useCreateAssessmentV2>>;
