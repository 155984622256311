import copy from "copy-to-clipboard";

export const copyToClipboard = async (text: string): Promise<void> => {
  return new Promise<void>(resolve => {
    copy(text, {
      format: "text/plain",
      onCopy: () => {
        resolve();
      },
    });
  });
};
