import { SupportLanguage, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import * as z from "zod";

import { CorrectnessTestCaseResult, useCorrectnessTestCase, usePerformanceTestCase } from "../external/AlgorithmTestCase";

export {
  CorrectnessTestCaseFromJson,
  CorrectnessTestCaseResult,
  CorrectnessTestCaseResults,
  PerformanceTestCaseResult,
  useCorrectnessTestCase,
  useCorrectnessTestCasesFromJsonDangerous,
  usePerformanceTestCase,
  usePerformanceTestCasesFromJsonDangerous,
} from "../external/AlgorithmTestCase";

export type CorrectnessTestCaseSchema = z.infer<ReturnType<typeof useCorrectnessTestCase>>;
export type CorrectnessTestCasesSchema = z.infer<ReturnType<typeof useCorrectnessTestCases>>;

export type CorrectnessTestCaseResultSchema = z.infer<typeof CorrectnessTestCaseResult>;

export const ReadabilityTestCaseResult = z.object({
  coverage_factor: z.number().optional(),
  cyclomatic_complexity_value: z.number(),
  cyclomatic_complexity_max: z.number(),
  cyclomatic_complexity_min: z.number(),
  cyclomatic_complexity_reference_max: z.number().optional(),
  cyclomatic_complexity_reference_min: z.number().optional(),
  source_lines_of_code_total: z.number().optional(),
  source_lines_of_code_source: z.number().optional(),
  source_lines_of_code_comment: z.number().optional(),
  source_lines_of_code_single: z.number().optional(),
  source_lines_of_code_block: z.number().optional(),
  source_lines_of_code_mixed: z.number().optional(),
  source_lines_of_code_empty: z.number().optional(),
  source_lines_of_code_todo: z.number().optional(),
  source_lines_of_code_block_empty: z.number().optional(),
});
export type ReadabilityTestCaseResultSchema = z.infer<typeof ReadabilityTestCaseResult>;

export const useCorrectnessTestCases = () => {
  const correctness = useCorrectnessTestCase();
  return z.object({
    data: z.array(correctness),
  });
};

const useTimeLimitFieldSchema = () => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();

  return z.preprocess(
    v => Math.round(Number(v)),
    z
      .number()
      .min(100, {
        message: t2("ValidateMinSizeWithUnitMessage", {
          size: 100,
          unit: "ms",
          name: t("テストケースの最大実行時間"),
        }),
      })
      .max(60000, {
        message: t2("ValidateMaxSizeWithUnitMessage", {
          size: 60000,
          unit: "ms",
          name: t("テストケースの最大実行時間"),
        }),
      }),
  );
};

export const usePerformanceTestCases = () => {
  const timeLimit = useTimeLimitFieldSchema();
  const performance = usePerformanceTestCase();

  return z.object({
    timeLimit: timeLimit,
    data: z.array(performance),
  });
};
export type PerformanceTestCaseSchema = z.infer<ReturnType<typeof usePerformanceTestCase>>;
export type PerformanceTestCasesSchema = z.infer<ReturnType<typeof usePerformanceTestCases>>;
export const useTestCaseFormSchema = () => {
  const { t } = useTranslation();
  const correctness = useCorrectnessTestCases();
  const performance = usePerformanceTestCases();
  return z
    .object({
      correctnessTestCases: correctness,
      performanceTestCases: performance,
      selectedLanguages: z.array(z.object({ value: z.enum([SupportLanguage.JA, SupportLanguage.EN]) })),
    })
    .superRefine((val, ctx) => {
      const selectedLanguagesSet = new Set(val.selectedLanguages.map(s => s.value));
      val.correctnessTestCases.data.forEach((c, index) => {
        if (c.title_ja.length === 0 && selectedLanguagesSet.has("ja")) {
          ctx.addIssue({
            path: [`correctnessTestCases.data.${index}.title_ja`],
            code: "custom",
            message: t("タイトルは必須です。"),
          });
        }
        if (c.title_en.length === 0 && selectedLanguagesSet.has("en")) {
          ctx.addIssue({
            path: [`correctnessTestCases.data.${index}.title_en`],
            code: "custom",
            message: t("タイトルは必須です。"),
          });
        }
        if (c.description_ja.length === 0 && selectedLanguagesSet.has("ja")) {
          ctx.path;
          ctx.addIssue({
            path: [`correctnessTestCases.data.${index}.description_ja`],
            code: "custom",
            message: t("詳細は必須です。"),
          });
        }
        if (c.description_en.length === 0 && selectedLanguagesSet.has("en")) {
          ctx.addIssue({
            path: [`correctnessTestCases.data.${index}.description_en`],
            code: "custom",
            message: t("詳細は必須です。"),
          });
        }
      });
    });
};

export type AlgorithmTestCaseFormSchema = z.infer<ReturnType<typeof useTestCaseFormSchema>>;

export const useTestCaseFormSchemaV2 = () => {
  const timeLimit = useTimeLimitFieldSchema();

  return z.object({
    correctnessTestCaseIds: z.array(z.string()),
    performanceTestCaseIds: z.array(z.string()),
    performanceTimelimit: timeLimit,
  });
};
export type AlgorithmTestCaseFormSchemaV2 = z.infer<ReturnType<typeof useTestCaseFormSchemaV2>>;

export const useCorrectnessTestCaseFormV2 = () => {
  const correctness = useCorrectnessTestCase();
  return z.object({
    correctness: correctness,
    selectedLanguages: z.array(z.object({ value: z.enum([SupportLanguage.JA, SupportLanguage.EN]) })),
  });
};
export type CorrectnessTestCaseFormSchemaV2 = z.infer<ReturnType<typeof useCorrectnessTestCaseFormV2>>;

export const usePerformanceTestCaseFormV2 = () => {
  const timeLimit = useTimeLimitFieldSchema();
  const performance = usePerformanceTestCase();

  return z.object({
    timeLimit: timeLimit,
    performance: performance,
  });
};
export type PerformanceTestCaseFormSchemaV2 = z.infer<ReturnType<typeof usePerformanceTestCaseFormV2>>;
