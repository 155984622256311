import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import FlexibleMarkdownPreviewEditor, {
  FlexibleMarkdownPreviewEditorProps,
} from "../FlexibleMarkdownPreviewEditor/FlexibleMarkdownPreviewEditor";

export type AppealMessageFormProps = {
  showing: boolean;
  placeholder?: string;
  value: string;
  onChange: (appealMessage: string) => void;
  previewEditor?: Pick<FlexibleMarkdownPreviewEditorProps, "disableToggleButton" | "defaultViewMode">;
};

const HeaderBox = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette["Secondary/Shades"].p12,
  alignItems: "center",
  flexWrap: "wrap",
  height: 36,
}));

const MAX_LENGTH = 10000;

const AppealMessageForm: React.FC<AppealMessageFormProps> = props => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const timer = React.useRef(0);
  const rows = 12;
  const appealMessage: FlexibleMarkdownPreviewEditorProps = {
    ...props.previewEditor,
    /** @see https://stackoverflow.com/questions/64837884/material-ui-too-many-re-renders-the-layout-is-unstable-textareaautosize-limit */
    rows: props.showing ? undefined : rows,
    minRows: props.showing ? rows : undefined,
    multiline: props.showing,
    placeholder: props.placeholder || "",
    defaultValue: props.value,
    validateOption: {
      maxLength: MAX_LENGTH,
      messageForReachingMaxLength: t2("MaximumNumberOfCharactersEnteredReached", {
        count: MAX_LENGTH,
      }),
    },
    onChange: event => {
      window.clearTimeout(timer.current);
      timer.current = window.setTimeout(() => {
        props.onChange(event.target.value);
      }, 300);
    },
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <HeaderBox px={2} py={1} spacing={1} direction="row">
        <InsertDriveFileOutlinedIcon sx={{ fontSize: "16px", color: theme.palette.action.active }} />
        <Typography variant="body2">{t("説明文")}</Typography>
      </HeaderBox>
      <FlexibleMarkdownPreviewEditor {...appealMessage} />
    </Box>
  );
};

AppealMessageForm.displayName = "AppealMessageForm";

export default AppealMessageForm;
