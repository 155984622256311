import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { SwitchProps } from "@mui/material/Switch/Switch";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type DetailSwitchProps = {
  checked: boolean;
  onClick: SwitchProps["onClick"];
};

const DetailSwitch: React.FC<DetailSwitchProps> = props => {
  const { t } = useTranslation();
  return (
    <Box display="flex" justifyContent="end" mb={1}>
      <FormControlLabel
        control={<Switch size="small" color="secondary" checked={props.checked} onClick={props.onClick} />}
        label={<Typography fontSize={14}>{t("詳細をすべて表示")}</Typography>}
      />
    </Box>
  );
};

DetailSwitch.displayName = "DetailSwitch";

export default DetailSwitch;
