import { styled } from "@mui/material/styles";
import * as React from "react";

import EmployeeLayout, { EmployeeLayoutProps } from "../../layouts/EmployeeLayout/EmployeeLayout";

const StyledEmployeeLayout = styled(EmployeeLayout)`
  overflow: hidden;
`;

export type RemoteInterviewDetailProps = {
  layout: EmployeeLayoutProps;
  children: React.ReactNode;
};

const RemoteInterviewDetail: React.FC<RemoteInterviewDetailProps> = props => {
  const layoutProps: EmployeeLayoutProps = {
    ...props.layout,
    headerPosition: "static",
  };
  return <StyledEmployeeLayout {...layoutProps}>{props.children}</StyledEmployeeLayout>;
};

RemoteInterviewDetail.displayName = "RemoteInterviewDetail";

export default RemoteInterviewDetail;
