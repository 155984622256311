import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SearchIcon from "@mui/icons-material/Search";
import WidgetsIcon from "@mui/icons-material/Widgets";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import escapeStringRegexp from "escape-string-regexp";
import React, { useCallback, useRef, useState } from "react";

import {
  CategoryLabelsMap,
  CategoryMasterData,
  ELEMENT_CATEGORIES,
  ELEMENT_CATEGORY,
  ELEMENT_KEY,
  ELEMENT_LABELS,
  ElementDataKeyMap,
  ElementLabel,
  ElementMasterData,
  LabelCategoryMap,
} from "../../features";
import { useSystemDesignContext } from "../../store";
import DraggableObjectItem from "./parts/DraggableObjectItem/DraggableObjectItem";

export type FlowChartSideBarProps = {
  onClickElement: (label: ElementLabel | "text") => void;
  onDragElementStart: (e: React.DragEvent<HTMLDivElement>, label: ElementLabel | "text") => void;
  helpCenterUrl: string;
  BottomComponent?: React.ReactNode;
};

const Wrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
  maxHeight: "100vh",
  position: "relative",
  alignContent: "space-between",
}));

const TopArea = styled(Box)`
  flex-grow: 1;
`;

const ElementListArea = styled(Box)`
  overflow-y: scroll;
`;

const BottomAreaWrapper = styled(Box)`
  flex-grow: 1;
`;

export const FlowChartSideBar: React.FC<FlowChartSideBarProps> = React.memo((props: FlowChartSideBarProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const lang = useLanguageCode();
  const [searchText, setSearchText] = useState<string>("");
  const objectListRef = useRef<HTMLDivElement>(null);
  const [canTooltipOpen, setCanTooltipOpen] = useState<boolean>(true);
  const Store = useSystemDesignContext();
  const componentType = Store.hooks.useComponentType();

  const getImage = useCallback(
    (label: ElementLabel | "text"): string => {
      return label === "text"
        ? ElementMasterData[ELEMENT_KEY.defaultText].iconImage
        : ElementMasterData[ElementDataKeyMap[componentType][label]].iconImage;
    },
    [componentType],
  );

  const getTitle = useCallback(
    (label: ElementLabel | "text"): string => {
      return label === "text"
        ? resolveLanguage(ElementMasterData[ELEMENT_KEY.defaultText], lang, "title")
        : resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title");
    },
    [lang, componentType],
  );

  const getDescription = useCallback(
    (label: ElementLabel | "text"): string => {
      return label === "text"
        ? resolveLanguage(ElementMasterData[ELEMENT_KEY.defaultText], lang, "description")
        : resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "description");
    },
    [lang, componentType],
  );

  const matchedLabels = (searchText: string): ElementLabel[] =>
    ELEMENT_LABELS.filter(label => {
      const reg = new RegExp(escapeStringRegexp(searchText), "i");
      return (
        reg.test(ElementMasterData[ElementDataKeyMap[componentType][label]].titleJa) ||
        reg.test(ElementMasterData[ElementDataKeyMap[componentType][label]].titleEn) ||
        reg.test(CategoryMasterData[LabelCategoryMap[label]].titleJa) ||
        reg.test(CategoryMasterData[LabelCategoryMap[label]].titleEn)
      );
    });

  const handleChangeText = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchText(e.target.value);
    objectListRef?.current?.scrollTo({ top: 0 });
  };

  const handleScroll = () => {
    setCanTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setCanTooltipOpen(true);
  };

  return (
    <Wrapper spacing={3}>
      <TopArea>
        <Box display={"flex"} alignItems={"center"}>
          <WidgetsIcon />
          <Box mr={1} />
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }} flexGrow={1}>
            {t("コンポーネントを配置する")}
          </Typography>
        </Box>

        <Box mt={1}>
          <Typography variant={"caption"}>{t("アイコンをドラッグ&ドロップすることでコンポーネントを配置することができます。")}</Typography>
        </Box>

        <Box mt={1}>
          <Link underline={"hover"} href={props.helpCenterUrl} target={"_blank"} sx={{ cursor: "pointer" }}>
            <Box display={"flex"} alignItems={"center"}>
              <Typography color={"primary"} sx={{ mr: 2 }}>
                {t("ドローイングツールの使い方")}
              </Typography>
              <OpenInNewIcon fontSize="small" color="primary" />
            </Box>
          </Link>
        </Box>
        <Box mt={2}>
          <TextField
            fullWidth
            placeholder={t("コンポーネントの名前で検索")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleChangeText}
            size={"small"}
          />
        </Box>
      </TopArea>
      <ElementListArea ref={objectListRef} onScroll={handleScroll}>
        {searchText && (
          <Box>
            <Typography>{t("検索結果")}</Typography>
            <Grid container spacing={2} mt={1} sx={{ backgroundColor: theme.palette.background.paper }} px={0.5}>
              {matchedLabels(searchText).map(label => (
                <DraggableObjectItem
                  key={`result-${label}`}
                  label={label}
                  displayLabel={getTitle(label)}
                  image={getImage(label)}
                  onClickElement={props.onClickElement}
                  onDragElementStart={props.onDragElementStart}
                  onTooltipOpen={handleTooltipOpen}
                  canTooltipOpen={canTooltipOpen}
                  tooltipText={getDescription(label)}
                />
              ))}
            </Grid>
            <Divider sx={{ mt: 2 }} />
          </Box>
        )}

        <Box>
          {ELEMENT_CATEGORIES.map(category => (
            <Box mt={2} key={category}>
              <Typography>{resolveLanguage(CategoryMasterData[category], lang, "title")}</Typography>
              {category === ELEMENT_CATEGORY.general ? (
                <Grid container spacing={2} mt={1} px={0.5}>
                  <DraggableObjectItem
                    label={"text"}
                    displayLabel={getTitle("text")}
                    image={getImage("text")}
                    onClickElement={props.onClickElement}
                    onDragElementStart={props.onDragElementStart}
                    onTooltipOpen={handleTooltipOpen}
                    canTooltipOpen={canTooltipOpen}
                    tooltipText={getDescription("text")}
                  />
                  {CategoryLabelsMap[category].map(label => (
                    <DraggableObjectItem
                      key={label}
                      label={label}
                      displayLabel={getTitle(label)}
                      image={getImage(label)}
                      onClickElement={props.onClickElement}
                      onDragElementStart={props.onDragElementStart}
                      onTooltipOpen={handleTooltipOpen}
                      canTooltipOpen={canTooltipOpen}
                      tooltipText={getDescription(label)}
                    />
                  ))}
                </Grid>
              ) : (
                <Grid container spacing={2} mt={1} px={0.5}>
                  {CategoryLabelsMap[category].map(label => (
                    <DraggableObjectItem
                      key={label}
                      label={label}
                      displayLabel={getTitle(label)}
                      image={getImage(label)}
                      onClickElement={props.onClickElement}
                      onDragElementStart={props.onDragElementStart}
                      onTooltipOpen={handleTooltipOpen}
                      canTooltipOpen={canTooltipOpen}
                      tooltipText={getDescription(label)}
                    />
                  ))}
                </Grid>
              )}
              <Divider sx={{ mt: 2 }} />
            </Box>
          ))}
        </Box>
      </ElementListArea>
      {props.BottomComponent && <BottomAreaWrapper>{props.BottomComponent}</BottomAreaWrapper>}
    </Wrapper>
  );
});

FlowChartSideBar.displayName = "FlowChartSideBar";

export default FlowChartSideBar;
