import { Auth, Company } from "@hireroo/app-store/essential/talent";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { useTransitionNavigate } from "@hireroo/router/hooks";

export type GenerateTalentSideBarContentPropsArgs = {};

type Menu = Widget.TalentSideBarContentProps["menuList"][0];

const MENU_MODE_MAP: Record<Menu["mode"], Menu["mode"]> = {
  PLAIN: "PLAIN",
  NESTED: "NESTED",
};

export const useGenerateProps = (_args: GenerateTalentSideBarContentPropsArgs): Widget.TalentSideBarContentProps => {
  const { t } = useTranslation();
  const client = getGraphqlClient();
  const uid = Auth.useCurrentUid();

  const navigate = useTransitionNavigate();
  const companies = Company.useCompanies();
  const activeCompanyId = Company.useActiveCompanyId();

  const menuList: Menu[] = [
    {
      mainButton: {
        id: "HOME",
        button: {
          onClick: () => {
            navigate("/t/assessments");
          },
        },
        text: t("ホーム"),
        icon: "HOME" as const,
      },
      mode: MENU_MODE_MAP.PLAIN,
      nestedButtons: [],
    },
    {
      mainButton: {
        id: "SCREENING_TEST",
        button: {},
        text: t("コーディングテスト"),
        icon: "MONITOR" as const,
      },
      mode: MENU_MODE_MAP.NESTED,
      nestedButtons: [],
    },
  ].flatMap(v => (v ? [v] : []));

  return {
    topMenu: {
      options: companies.map(company => ({
        default: company.companyId === activeCompanyId,
        text: company.name,
        value: company.companyId.toString(),
        onClick: async () => {
          const res = await client.SwitchActiveCompanyForTalentV2({
            input: {
              companyId: company.companyId,
              talentId: uid,
            },
          });
          if (res.switchActiveCompanyForTalentV2) {
            Company.updateActiveCompanyId(company.companyId);
          }
        },
      })),
      role: "company",
      menuId: "select-company-menu",
    },
    menuList: menuList,
    createButton: {
      onClick: () => {
        navigate("/e/interviews/create");
      },
    },
  };
};
