/**
 * 0.00 < scoreInFloat < 1.00
 * If  0.00 <= scoreInFloat < 0.01 -> 0
 * If  0.99 <= scoreInFloat < 0.999 -> 99
 * If  scoreInFloat === 1.00 -> 100
 */
export const formatScore = (scoreInFloat: number): number => {
  /**
   * Truncate 99.5 points so that they do not become 100 points.
   */
  return Math.floor(scoreInFloat * 100);
};
