import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";

type Status = "ACTIVE" | "NOT_ACTIVE";
export type ActivateDialogProps = {
  dialog: Pick<DialogWithCloseIconProps, "open" | "onClose" | "yesButton" | "noButton">;
  targetName: string;
  changeStatus: Status;
};

const ActivateDialog: React.FC<ActivateDialogProps> = props => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const statusTitleMap: Record<Status, string> = {
    ACTIVE: t2("ActiveTarget", { targetName: props.targetName }),
    NOT_ACTIVE: t2("DeactivateTarget", { targetName: props.targetName }),
  };
  const statusDescriptionMap: Record<Status, string> = {
    ACTIVE: t("コーディングテストを受付中にすると過去に共有されたリンクからもアクセスできます。"),
    NOT_ACTIVE: t("コーディングテストを停止すると共有されたリンクからもアクセスができなくなります。"),
  };
  const buttonTextMap: Record<Status, string> = {
    ACTIVE: t("受付"),
    NOT_ACTIVE: t("停止"),
  };
  const baseDialogProps: DialogWithCloseIconProps = {
    ...props.dialog,
    title: statusTitleMap[props.changeStatus],
    yesButton: {
      ...props.dialog.yesButton,
      color: "secondary",
      children: buttonTextMap[props.changeStatus],
      onClick: () => {
        props.dialog.yesButton?.onClick?.();
      },
      autoFocus: false,
    },
    noButton: {
      ...props.dialog.noButton,
      variant: "outlined",
      color: "outline-only",
      children: t("キャンセル"),
    },
    disableEnter: true,
    optionalDialog: {
      disableAutoFocus: true,
      disableRestoreFocus: true,
    },
  };
  return (
    <DialogWithCloseIcon {...baseDialogProps}>
      <Typography fontSize={14}>{statusDescriptionMap[props.changeStatus]}</Typography>
    </DialogWithCloseIcon>
  );
};

ActivateDialog.displayName = "ActivateDialog";

export default ActivateDialog;
