import { state } from "./State";
import type * as Types from "./types";

export const updateResponse = (res: Types.ListBestExamsByAssessmentIdForEmployeeAssessments): void => {
  state.res = res;
  state.deletedAssessmentIdSet.clear();
};

export const clear = (): void => {
  state.res = null;
};

export const updateCurrentSearchFilter = (filter: Types.SearchFilter) => {
  state.currentSearchFilter = filter;
  /** Reset page when search criteria are changed */
  state.pager = {
    ...state.pager,
    page: 0,
    offset: 0,
  };
};

export const updateSortField = (sortFieldValue: Types.SortFieldValue) => {
  state.pager = {
    ...state.pager,
    sortFieldValue: sortFieldValue,
    page: 0,
    offset: 0,
  };
};

export const updatePage = (page: number) => {
  state.pager = {
    ...state.pager,
    page,
    offset: page * state.pager.size,
  };
};

export const updatePageSize = (size: number) => {
  state.pager = {
    ...state.pager,
    page: 0,
    offset: 0,
    size: size,
  };
};

export const addDeletedAssessmentId = (assessmentId: string) => {
  state.deletedAssessmentIdSet.add(assessmentId);
};

export const initializePager = (pager: Omit<Types.Pager, "offset">) => {
  state.pager = {
    ...pager,
    offset: pager.page * pager.size,
  };
};

export const updateReportShareSettings = (res: Types.UpdateAssessmentReportShareSettingsResponse) => {
  if (!state.res?.assessments) {
    return;
  }
  const index = state.res.assessments.findIndex(assessment => assessment.assessmentId === res.assessmentId);
  state.res.assessments[index].reportSettings = res.reportSettings;
};
