import { Block, CumulativeLayoutShift, ImageInputOutputExpected, TestResult } from "@hireroo/evaluation-result-interface";

const tableBlock: Block = {
  type: "table",
  value: {
    headers: ["column1", "column2"],
    bodies: [
      ["cell1", "cell2"],
      ["cell3", "cell4"],
    ],
  },
};

const tableDiffBlock: Block = {
  type: "table_diff",
  value: {
    headers: ["column1", "column2"],
    bodies: [
      {
        actualRow: ["cell1", "cell2"],
        expectedRow: ["cell1", "cell3"],
        isCorrect: false,
      },
      {
        actualRow: ["cell3", "cell4"],
        expectedRow: ["cell3", "cell4"],
        isCorrect: true,
      },
      {
        actualRow: ["cell5", "cell6"],
        expectedRow: ["cell5", "cell7"],
        isCorrect: true,
      },
      {
        actualRow: ["cell8", "cell9"],
        expectedRow: ["cell8", "cell9"],
        isCorrect: true,
      },
      {
        actualRow: ["cell10", "cell11"],
        expectedRow: ["cell10", "cell12"],
        isCorrect: false,
      },
      {
        actualRow: ["cell13", "cell14"],
        expectedRow: ["cell13", "cell15"],
        isCorrect: true,
      },
      {
        actualRow: ["cell16", "cell17"],
        expectedRow: ["cell16", "cell18"],
        isCorrect: false,
      },
      {
        actualRow: ["cell19", "cell20"],
        expectedRow: ["cell19", "cell21"],
        isCorrect: true,
      },
      {
        actualRow: ["cell22", "cell23"],
        expectedRow: ["cell22", "cell24"],
        isCorrect: false,
      },
      {
        actualRow: ["cell25", "cell26"],
        expectedRow: ["cell25", "cell27"],
        isCorrect: true,
      },
    ],
  },
};

const cls: CumulativeLayoutShift = {
  type: "cumulative_layout_shift",
  value: {
    line: {
      value: 0.05,
      label: "here",
    },
    needsImprovement: {
      start: 0.1,
      end: 0.25,
    },
    poor: {
      start: 0.25,
      end: 0.5,
    },
  },
};

const imgInputOutputExpected: ImageInputOutputExpected = {
  type: "image_input_output_expected",
  value: {
    description: {
      type: "markdown",
      value: {
        text: {
          ja: "description",
          en: "description",
        },
      },
    },
    input: {
      src: "https://via.placeholder.com/150",
      attr: "",
    },
    results: [
      {
        output: {
          src: "https://via.placeholder.com/150?text=1",
          attr: "",
        },
        expected: {
          src: "https://via.placeholder.com/150?text=1",
          attr: "",
        },
        mark: "CHECK",
      },
      {
        output: {
          src: "https://via.placeholder.com/150?text=2",
          attr: "",
        },
        expected: {
          src: "https://via.placeholder.com/150?text=2",
          attr: "",
        },
        mark: "CHECK",
      },
      {
        output: {
          src: "https://via.placeholder.com/160?text=three",
          attr: "",
        },
        expected: {
          src: "https://via.placeholder.com/160?text=3",
          attr: "",
        },
        mark: "CROSS",
      },
      {
        output: {
          src: "https://via.placeholder.com/160?text=4",
          attr: "",
        },
        expected: {
          src: "https://via.placeholder.com/160?text=4",
          attr: "",
        },
        mark: "CHECK",
      },
    ],
  },
};

export const testResultData: TestResult = {
  version: "v1.0",
  blocks: [
    {
      type: "header",
      value: {
        text: {
          ja: "Header",
          en: "Header",
        },
        startIcon: "CHECK",
      },
    },
    {
      type: "divider",
    },
    {
      type: "markdown",
      value: {
        text: {
          ja: "## markdown header\n\n- list item 1\n- list item 2\n\n```typescript\nconst a = 1;\n```",
          en: "## markdown header\n\n- list item 1\n- list item 2\n\n```typescript\nconst a = 1;\n```",
        },
      },
    },
    {
      type: "stepper",
      value: {
        title: {
          ja: "Stepper",
          en: "Stepper",
        },
        contents: [
          {
            type: "test_case_stepper_content",
            value: {
              mark: "CHECK",
              header: {
                ja: "Step 1",
                en: "Step 1",
              },
              testCaseResult: {
                input: "{}",
                output: "{}",
                expected: "{ 'test': 1 }",
              },
            },
          },
          {
            type: "test_case_stepper_content",
            value: {
              mark: "CHECK",
              header: {
                ja: "Step 2",
                en: "Step 2",
              },
              testCaseResult: {
                input: "{}",
                output: "{}",
                expected: "{ 'test': 2 }",
              },
            },
          },
        ],
      },
    },
    tableBlock,
    tableDiffBlock,
    {
      type: "test_case_result",
      value: {
        input: "{}",
        output: "{}",
        expected: "{ 'test': 1 }",
      },
    },
    {
      type: "text_diff_viewer",
      value: {
        original: "{}",
        modified: "{ 'test': 1 }",
      },
    },
    cls,
    imgInputOutputExpected,
  ],
  is_passed: true,
};

export const testResultsData: TestResult[] = [testResultData, { ...testResultData, is_passed: false }];
