import { useTranslation } from "@hireroo/i18n";
import type { SelectChangeEvent } from "@mui/material";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";

import { LabelSql, NodeElement, OPERATION_TYPE, OperationType, REPLICATION, Replication, UnionSettingsFields } from "../../../../../features";

export type SqlSettingsFormProps = {
  element: NodeElement<LabelSql>;
  updateSettings: (id: string, updates: UnionSettingsFields, operationType: OperationType) => void;
};

export const SqlSettingsForm: React.FC<SqlSettingsFormProps> = (props: SqlSettingsFormProps) => {
  const { t } = useTranslation();
  // To show a replication selector dynamically
  const [enableReplication, setEnableReplication] = useState<boolean>(props.element.settings.enableReplication);

  const handleSwitchEnableReplication = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.updateSettings(props.element.id, { enableReplication: e.target.checked, replication: REPLICATION.primary }, OPERATION_TYPE.do);
    setEnableReplication(e.target.checked);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelectReplication = (e: SelectChangeEvent<any>) => {
    props.updateSettings(props.element.id, { replication: e.target.value as Replication }, OPERATION_TYPE.do);
  };

  const handleSwitchEnableSharding = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.updateSettings(props.element.id, { enableSharding: e.target.checked }, OPERATION_TYPE.do);
  };

  useEffect(() => {
    setEnableReplication(props.element.settings.enableReplication);
  }, [props.element.settings.enableReplication]);

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} mt={2}>
        <Typography>{t("レプリケーション有効化")}</Typography>
        <Box mt={1} />
        <FormControlLabel
          control={<Switch checked={props.element.settings.enableReplication} onChange={handleSwitchEnableReplication} />}
          label={props.element.settings.enableReplication ? "ON" : "OFF"}
        />
      </Box>
      {enableReplication && (
        <Box mt={2}>
          <Typography>{t("レプリケーション構成")}</Typography>
          <Box display={"flex"} flexDirection={"column"} mt={1}>
            <Typography variant={"caption"}>{`${t("プライマリー")}: ${t("常時処理を行う複製元のDB")}`}</Typography>
            <Typography variant={"caption"}>{`${t("レプリカ")}: ${t("主に参照に使用される複製先のDB")}`}</Typography>
          </Box>
          <Box mt={1} />
          <Select size={"small"} variant={"outlined"} onChange={handleSelectReplication} value={props.element.settings.replication}>
            <MenuItem value={REPLICATION.primary}>{t("プライマリー")}</MenuItem>
            <MenuItem value={REPLICATION.replica}>{t("レプリカ")}</MenuItem>
          </Select>
        </Box>
      )}
      <Box display={"flex"} flexDirection={"column"} mt={2}>
        <Typography>{t("シャーディング有効化")}</Typography>
        <Box mt={1} />
        <FormControlLabel
          control={<Switch checked={props.element.settings.enableSharding} onChange={handleSwitchEnableSharding} />}
          label={props.element.settings.enableSharding ? "ON" : "OFF"}
        />
      </Box>
    </>
  );
};

SqlSettingsForm.displayName = "SqlSettingsForm";

export default SqlSettingsForm;
