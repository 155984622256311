import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type NotFoundContainerProps = {};

const NotFoundContainer: React.FC<NotFoundContainerProps> = () => {
  const props = useGenerateProps({});
  return <Widget.ErrorPanel {...props} />;
};
NotFoundContainer.displayName = "NotFoundContainer";

export default withErrorBoundary(NotFoundContainer, {});
