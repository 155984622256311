import { useSnapshot } from "valtio";

import * as Def from "./definitions";
import { state } from "./State";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = (): boolean => {
  const snapshot = useSnapshotState();
  return !!snapshot.res;
};

export const useNumberOfAssessment = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.res) {
    throw new Error("Response is not found");
  }
  return snapshot.res.count - snapshot.deletedAssessmentIdSet.size;
};

export const useAssessments = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.res) {
    throw new Error("Response is not found");
  }
  return snapshot.res.assessments.filter(assessment => {
    return !snapshot.deletedAssessmentIdSet.has(assessment.assessmentId);
  });
};

export const useCurrentSearchFilter = () => {
  const snapshot = useSnapshotState();
  return snapshot.currentSearchFilter;
};

export const usePager = () => {
  const snapshot = useSnapshotState();
  return snapshot.pager;
};

export const useSortParams = () => {
  const snapshot = useSnapshotState();
  return Def.SortParams[snapshot.pager.sortFieldValue];
};
