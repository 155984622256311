import { subscribeKey } from "valtio/utils";

import { state } from "./State";
import * as Types from "./types";

export type SubscribeOnChangeSpotCallback = (spot: Types.Spot) => void;
export const subscribeOnChangeSpot = (callback: SubscribeOnChangeSpotCallback) => {
  return subscribeKey(state, "spot", spot => {
    if (spot) {
      callback(spot);
    }
  });
};
