import * as z from "zod";

export type FileContentHistoryStoredPath = `${string}/${number}/histories/files/${string}`;

/**
 * @example `${string}/histories/files/${string}`
 */
export const validateFileContentHistoryStoredPath = (path: string): path is FileContentHistoryStoredPath => {
  const regex = /histories\/files\/[^/]+$/;
  return regex.test(path);
};

export type PathHistoryStoredPath = `${string}/histories/paths/${string}`;

/**
 * @example `${string}/histories/paths/${string}`
 */
export const validatePathHistoryStoredPath = (path: string): path is PathHistoryStoredPath => {
  const regex = /histories\/paths\/[^/]+$/;
  return regex.test(path);
};

export const PathHistoryValue = z.object({
  /** operation */
  op: z.enum(["EXISTS", "DELETED", "CREATED"]),
  /** unique id */
  id: z.string(),
  /** timestamp */
  ts: z.number(),
});

export type PathHistoryValue = z.infer<typeof PathHistoryValue>;

export const PathHistoryValues = PathHistoryValue.array().min(1);

export type PathHistoryValues = z.infer<typeof PathHistoryValues>;

/**
 * @example { "/a/b/new.md": { "0": { "op": "EXISTS", ts: 0 }, "1": { "op": "EXISTS", ts: 0 } }  }
 */
export const PathHistories = z.record(z.string(), PathHistoryValues);

export type PathHistories = z.infer<typeof PathHistories>;
