import { Button } from "@mui/material";
import { ButtonProps } from "@mui/material/Button";
import * as React from "react";

export type AcceptButtonProps = ButtonProps & {
  children: NonNullable<React.ReactNode>;
};

/**
 * @deprecated Abolished because the abstraction is of little value.
 */
const AcceptButton: React.FC<AcceptButtonProps> = props => {
  const { size = "large", ...rest } = props;

  return <Button role="acceptbutton" size={size} variant="contained" color="primary" {...rest} />;
};

AcceptButton.displayName = "AcceptButton";

export default AcceptButton;
