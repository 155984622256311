import { useSnapshot } from "valtio";

import { state } from "./State";
import * as Types from "./types";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useDialogStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.dialogStatus;
};

export const useMetricsIdsForPagination = () => {
  const snapshot = useSnapshotState();
  const { page, size } = snapshot.pagination;
  const metricsIds = Array.from(snapshot.metricMap.keys());
  return metricsIds.slice(page * size, (page + 1) * size);
};

export const useMetrics = (): Types.Metric[] => {
  const ids = useMetricsIdsForPagination();
  const snapshot = useSnapshotState();

  return ids.reduce<Types.Metric[]>((all, id) => {
    const metric = snapshot.metricMap.get(id);
    if (!metric) return all;
    return all.concat(metric);
  }, []);
};

export const useSelectedMetricIds = () => {
  const snapshot = useSnapshotState();
  return snapshot.selectedItemIds;
};

export const useSelectedMetrics = () => {
  const snapshot = useSnapshotState();
  return snapshot.selectedItemIds.reduce<Types.Metric[]>((all, id) => {
    const metric = snapshot.metricMap.get(id);
    if (!metric) return all;
    return all.concat(metric);
  }, []);
};

export const usePagination = () => {
  const snapshot = useSnapshotState();
  return snapshot.pagination;
};

export const useNextPager = () => {
  const snapshot = useSnapshotState();
  return snapshot.pagination.nextPager;
};
