import { AlgorithmTemplatePreview } from "@hireroo/app-store/widget/e/AlgorithmTemplatePreview";
import type { Widget } from "@hireroo/presentation/legacy";

import { useFetchTemplate } from "./useFetchTemplate";

export type GenerateAlgorithmTemplatePreviewPropsArgs = {
  variant: "ALGORITHM" | "DATABASE" | "CLASS";
  signature: string;
};

export const useGenerateProps = (args: GenerateAlgorithmTemplatePreviewPropsArgs): Widget.AlgorithmTemplatePreviewProps => {
  useFetchTemplate(args);
  const template = AlgorithmTemplatePreview.useTemplate();
  return {
    variant: args.variant,
    template: template,
  };
};
