import { Payment } from "@hireroo/app-store/essential/employee";
import { ScreeningTestListForDetail } from "@hireroo/app-store/widget/e/ScreeningTestListForDetail";
import { formatScore } from "@hireroo/formatter/score";
import type { Widget } from "@hireroo/presentation";
import { TestSearchForm } from "@hireroo/validator";
import { useParams } from "react-router-dom";

import * as PrivateHelper from "./privateHelper";
import FilledVariablesFieldFetchContainer from "./widgets/FilledVariablesField/FetchContainer";
import ScreeningTestListFetchContainer from "./widgets/ScreeningTestList/FetchContainer";

type SearchProps = Exclude<Widget.ScreeningTestListForDetailProps["search"], undefined>;
type SelectableTag = SearchProps["tagsField"]["selectableTags"][0];

type Statuses = TestSearchForm.TestSearchQuerySchema["statuses"];
type RankList = TestSearchForm.TestSearchQuerySchema["rank"];
type SuspiciousDegrees = TestSearchForm.TestSearchQuerySchema["suspiciousDegrees"];

export type GenerateScreeningTestListForDetailPropsArgs = {
  hasVariables: boolean;
};

export const useGenerateProps = (args: GenerateScreeningTestListForDetailPropsArgs): Widget.ScreeningTestListForDetailProps => {
  const initialized = ScreeningTestListForDetail.useInitialized();
  const filters = ScreeningTestListForDetail.useFilters();
  const tags = ScreeningTestListForDetail.useTags();
  const isAvailableFeature = Payment.useIsAvailableFeature();
  const { id } = useParams<{
    id: string;
  }>();
  const screeningId = `${id}`;

  return {
    active: initialized,
    search: {
      disabled: false,
      enabledStatisticsFilter: isAvailableFeature("test.rank.read"),
      enabledCreatedByFilter: false,
      enabledScreeningFilter: false,
      enabledSuspiciousDegreeFilter: isAvailableFeature("test.suspicious-degree.read"),
      createdByField: {
        options: [],
      },
      screeningField: {
        options: [],
      },
      AdditionalFilter: args.hasVariables ? <FilledVariablesFieldFetchContainer screeningId={screeningId} /> : null,
      tagsField: {
        selectableTags: tags.map((tag): SelectableTag => {
          return {
            displayName: tag.name,
          };
        }),
      },
      defaultValues: {
        textFilter: filters.name,
        score: {
          min: typeof filters.minTotalScore === "number" ? formatScore(filters.minTotalScore) : 0,
          max: typeof filters.maxTotalScore === "number" ? formatScore(filters.maxTotalScore) : 100,
        },
        tags: filters.tagNames.map(tag => {
          return {
            displayName: tag,
          };
        }),
        screeningIds: [],
        questionVariants: [],
        statuses: filters.statuses.reduce<Statuses>((all, status) => {
          const converted = PrivateHelper.statusToUi[status];
          if (converted !== null) {
            return all.concat(converted);
          }
          return all;
        }, []),
        createdBy: [],
        result: (() => {
          if (filters.result === undefined) {
            return null;
          }
          return filters.result ? "PASSED" : "NOT_PASSED";
        })(),
        rank: filters.rankEvaluations.reduce<RankList>((all, rank) => {
          const converted = PrivateHelper.rankToUi[rank];
          if (converted) {
            return all.concat(converted);
          }
          return all;
        }, []),
        suspiciousDegrees: (filters.suspiciousDegrees ?? []).reduce<SuspiciousDegrees>((all, suspiciousDegree) => {
          const converted = PrivateHelper.suspiciousDegreeToUi[suspiciousDegree];
          if (converted) {
            return all.concat(converted);
          }
          return all;
        }, []),
      },
      onReset: () => {
        ScreeningTestListForDetail.updateFilters({
          variableEntries: [],
        });
      },
      onChange: field => {
        /**
         * clear selected spot ids to refresh state inside the list
         */
        ScreeningTestListForDetail.clearSelectedSpotIds();
        ScreeningTestListForDetail.updateFilters({
          name: field.textFilter,
          minTotalScore: field.score.min !== 0 ? field.score.min / 100 : undefined,
          maxTotalScore: field.score.max !== 100 ? field.score.max / 100 : undefined,
          minCustomScore: field.score.min !== 0 ? field.score.min / 100 : undefined,
          maxCustomScore: field.score.max !== 100 ? field.score.max / 100 : undefined,
          rankEvaluations: field.rank,
          statuses: field.statuses.map(status => PrivateHelper.statusToGraphql[status]),
          tagNames: field.tags.map(tag => tag.displayName),
          result: field.result ? PrivateHelper.resultMap[field.result] : undefined,
          suspiciousDegrees: field.suspiciousDegrees,
        });
        ScreeningTestListForDetail.updateSearchTags(field.tags.map(tag => tag.displayName));
      },
    },
    List: <ScreeningTestListFetchContainer />,
  };
};
