import "./style.css";

import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";

const StyledBox = styled(Box)(() => ({
  ".gsc-control-cse": {
    backgroundColor: "inherit",
    border: "none",
  },
  ".gsc-input": {
    color: "black",
  },
}));

const googleSearchEngineEndpoint = (searchEngineId: string) => {
  return `https://cse.google.com/cse.js?cx=${searchEngineId}`;
};

const SCRIPT_ELEMENT_ID = "google-search-form-script";

export type GoogleSearchFormProps = {
  searchEngineId: string;
};

const GoogleSearchForm: React.FC<GoogleSearchFormProps> = props => {
  const { searchEngineId } = props;
  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = googleSearchEngineEndpoint(searchEngineId);
    script.id = SCRIPT_ELEMENT_ID;
    script.async = true;
    document.body.append(script);

    return () => {
      const elem = document.getElementById(SCRIPT_ELEMENT_ID);
      if (elem !== null) {
        // When re-rendering start, script element should be appended newly to show Google search form.
        // So, script element will be removed here when it is unmounted.
        elem.remove();
      }
    };
  }, [searchEngineId]);

  return (
    <StyledBox>
      {/* Google search box's document: https://developers.google.com/custom-search/docs/tutorial/implementingsearchbox?hl=ja */}
      <div className="gcse-search" />
    </StyledBox>
  );
};

GoogleSearchForm.displayName = "GoogleSearchForm";

export default GoogleSearchForm;
