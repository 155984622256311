import { Client, Conversation, Message, State } from "@twilio/conversations";
import * as React from "react";

export const useChat = () => {
  const clientRef = React.useRef<Client | null>(null);
  const conversationRef = React.useRef<Conversation | null>(null);
  const isReadAllMessagesRef = React.useRef(false);

  const [messages, setMessages] = React.useState<Message[]>([]);
  const [isReadAllMessages, setIsReadAllMessages] = React.useState(false);
  const [hasUnreadMessages, setHasUnreadMessages] = React.useState(false);

  const initializeClient = React.useCallback(async (token: string): Promise<() => void> => {
    // Return a promise that resolves when the client is initialized
    return new Promise<() => void>((resolve, reject) => {
      // Create a new client
      const client = new Client(token);

      // Resolve the promise when emitted "stateChanged" event
      const handleClientInitialized = (state: State) => {
        if (state === "initialized") {
          resolve(() => {
            client.off("stateChanged", handleClientInitialized);
          });
        } else {
          reject(new Error(`Failed to initialize Twilio client: ${state}`));
        }
      };

      // Before setting the new client, remove the listener from the old client
      clientRef.current?.off("stateChanged", handleClientInitialized);
      // Set the new client
      clientRef.current = client;
      clientRef.current.on("stateChanged", handleClientInitialized);
    });
  }, []);

  const initializeConversation = React.useCallback(async (conversationId: string): Promise<() => void> => {
    const handleMessageAdded = (newMessage: Message) => {
      setMessages(oldMessages => {
        // Remove duplicates for strict
        const ids = new Set<Message["sid"]>();
        const uniqueMessages: Message[] = [];
        for (const message of oldMessages.concat(newMessage)) {
          if (!ids.has(message.sid)) {
            ids.add(message.sid);
            uniqueMessages.push(message);
          }
        }
        return uniqueMessages;
      });
      // If the message is not from the current user, mark the conversation as unread
      if (clientRef.current?.user?.identity !== newMessage.author) {
        setIsReadAllMessages(false);
      }
    };

    if (!clientRef.current) {
      // If the client is not initialized, return a cleanup function that does nothing
      return () => {
        conversationRef.current?.off("messageAdded", handleMessageAdded);
      };
    }

    // Before setting the new conversation, remove the listener from the old conversation
    conversationRef.current?.off("messageAdded", handleMessageAdded);
    // Get the conversation
    conversationRef.current = await clientRef.current.getConversationByUniqueName(conversationId);
    // newConversation is null if the conversation does not exist
    conversationRef.current.on("messageAdded", handleMessageAdded);
    // Get initial messages
    const messages = await conversationRef.current.getMessages();
    setMessages(messages.items);

    return () => {
      conversationRef.current?.off("messageAdded", handleMessageAdded);
    };
  }, []);

  // Connect to the conversation and return a cleanup function
  const connect = React.useCallback(
    async (conversationId: string, token: string): Promise<() => void> => {
      const cleanupClient = await initializeClient(token);
      const cleanupConversation = await initializeConversation(conversationId);

      return () => {
        cleanupClient();
        cleanupConversation();
      };
    },
    [initializeClient, initializeConversation],
  );

  // Store the current value of isReadAllMessages in a ref
  React.useEffect(() => {
    isReadAllMessagesRef.current = isReadAllMessages;
  }, [isReadAllMessages]);

  // If the user has read all messages, set hasUnreadMessages to false
  React.useEffect(() => {
    if (isReadAllMessages) {
      setHasUnreadMessages(false);
    }
  }, [isReadAllMessages]);

  // If there are new messages and the user has not read them, set hasUnreadMessages to true
  React.useEffect(() => {
    if (!isReadAllMessagesRef.current && messages.length > 0) {
      setHasUnreadMessages(true);
    }
  }, [messages]);

  const sendMessage = React.useCallback(async (message: string) => {
    return conversationRef.current?.sendMessage(message);
  }, []);

  return {
    connect,
    messages,
    sendMessage,
    readMessages: () => setIsReadAllMessages(true),
    hasUnreadMessages,
  };
};
