import { Widget } from "@hireroo/presentation/legacy";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type SearchQuizQuestionContainerProps = {};

const SearchQuizQuestionContainer: React.FC<SearchQuizQuestionContainerProps> = () => {
  const props = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.SearchQuizQuestion {...props} />
    </ErrorBoundary>
  );
};

SearchQuizQuestionContainer.displayName = "SearchQuizQuestionContainer";

export default withErrorBoundary(SearchQuizQuestionContainer, {});
