import { SystemDesignTestReport } from "@hireroo/app-store/view-domain/SystemDesignTestReport";
import { TotalScoreRankVisualizer } from "@hireroo/app-store/widget/shared/TotalScoreRankVisualizer";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateHeaderProps } from "../../../../props-factory/v2/view-domain/SystemDesignTestReport/useGenerateHeaderProps";
import { useGenerateQuestionDetailProps } from "../../../../props-factory/v2/view-domain/SystemDesignTestReport/useGenerateQuestionDetailProps";
import { useStatusMessageMap } from "./privateHelper";
import { startSubscribeQueryKey } from "./Subscriber";
import { GenerateSystemDesignTestReportPropsArgs, useGenerateProps } from "./useGenerateProps";

export type SystemDesignTestReportContainerProps = Omit<GenerateSystemDesignTestReportPropsArgs, "uniqueKey">;

const SystemDesignTestReportContainer: React.FC<SystemDesignTestReportContainerProps> = props => {
  const { companyId, entityId: systemDesignId } = props;
  const hooks = SystemDesignTestReport.useCreateSystemDesignHooks(systemDesignId);
  const systemDesign = hooks.useSystemDesign();
  const question = hooks.useQuestion();
  const currentSubmission = hooks.useCurrentSubmission();
  const header = useGenerateHeaderProps({ entityId: systemDesignId });
  const messageMap = useStatusMessageMap();
  const showNotStartedMessage = systemDesign.systemDesignStatus === "CREATED" || systemDesign.systemDesignStatus === "STARTED";
  const questionDetail = useGenerateQuestionDetailProps({ entityId: systemDesignId, showAnswer: false, showArchivedMark: false });
  const uniqueKey = React.useMemo((): SystemDesignTestReport.UniqueKey | null => {
    if (!currentSubmission) {
      return null;
    }
    /**
     * Multiple submissions and unique by EntityId
     */
    return `SystemDesign-${systemDesignId}-${currentSubmission.systemDesignSubmissionId}`;
  }, [currentSubmission, systemDesignId]);
  const systemDesignTestReportProps = useGenerateProps({
    ...props,
    uniqueKey: uniqueKey || `SystemDesign-${systemDesignId}`,
  });

  React.useEffect(() => {
    if (!currentSubmission || !uniqueKey || showNotStartedMessage) {
      return;
    }
    TotalScoreRankVisualizer.createTotalScoreStatisticsState({
      uniqueKey: uniqueKey,
      query: {
        endDate: currentSubmission?.evaluatedAtSeconds ? new Date(currentSubmission.evaluatedAtSeconds * 1000) : null,
      },
    });
    const stop = startSubscribeQueryKey({
      uniqueKey,
      entityId: systemDesign.systemDesignEntityId,
      companyId: companyId,
      submissionId: currentSubmission.systemDesignSubmissionId,
      questionId: question.questionId,
    });
    TotalScoreRankVisualizer.refreshQuery(uniqueKey);
    return () => {
      stop?.();
    };
  }, [companyId, currentSubmission, question.questionId, showNotStartedMessage, systemDesign.systemDesignEntityId, uniqueKey]);

  if (!currentSubmission || !uniqueKey || showNotStartedMessage) {
    const systemDesignProps: Widget.SystemDesignTestReportProps = {
      header: header,
      questionDetail: questionDetail,
      announcement: messageMap[systemDesign.systemDesignStatus],
    };
    return <Widget.SystemDesignTestReport {...systemDesignProps} />;
  }
  return (
    <ErrorBoundary>
      <Widget.SystemDesignTestReport {...systemDesignTestReportProps} />
    </ErrorBoundary>
  );
};

SystemDesignTestReportContainer.displayName = "SystemDesignTestReportContainer";

export default withErrorBoundary(SystemDesignTestReportContainer, {});
