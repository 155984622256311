import { App, Company, Payment } from "@hireroo/app-store/essential/employee";
import { BillingUsages } from "@hireroo/app-store/widget/e/BillingUsages";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import UsagesContainer, { UsagesContainerProps } from "./Container";

export type UsagesFetchContainerProps = {};

const UsagesFetchContainer: React.FC<UsagesFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const customer = Payment.useCustomerV2();
  const pager = BillingUsages.usePager();
  const companyId = Company.useStrictActiveCompanyId();
  const fetchSize = BillingUsages.useFetchSize();
  const usagePeriodStartSeconds = BillingUsages.useUsagePeriodStartSeconds();
  const fetchable = BillingUsages.useFetchable();
  React.useEffect(() => {
    BillingUsages.setStartSeconds(customer?.createdAtSeconds ?? 0);
  }, [customer?.createdAtSeconds]);
  const [result, refresh] = Graphql.useGetSubscribeUsagesForSubscriptionUsageV2Query({
    variables: {
      customerId: companyId,
      usagePeriodStartSeconds: usagePeriodStartSeconds,
      cursorSeconds: pager?.cursorSeconds ?? null,
      cursorNanoSeconds: pager?.cursorNanoSeconds ?? null,
      size: fetchSize,
    },
    pause: appStatus !== "INITIALIZED" || !fetchable,
    requestPolicy: "network-only",
  });
  React.useEffect(() => {
    if (result.data) {
      BillingUsages.initialize(result.data.usagesV2);
    }
  }, [result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }

  if (result.fetching || appStatus !== "INITIALIZED") {
    return <Widget.Loading kind="TOP" />;
  }

  const containerProps: UsagesContainerProps = {};

  return <UsagesContainer {...containerProps} />;
};

UsagesFetchContainer.displayName = "UsagesFetchContainer";

export default withErrorBoundary(UsagesFetchContainer, {});
