import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import * as z from "zod";

const MAX_SIZE = 30;
export const useTag = () => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();

  return z.object({
    name: z
      .string()
      .min(1, {
        message: t("タグを追加するには1文字以上の入力が必要です。"),
      })
      .max(MAX_SIZE, {
        message: t2("ValidateMaxTextSizeMessage", {
          size: MAX_SIZE,
          name: t("タグ"),
        }),
      }),
  });
};
export type TagSchema = z.infer<ReturnType<typeof useTag>>;

export const useTagItem = () => {
  const target = useTag();
  return z.object({
    value: target,
  });
};

export type TagListItemSchema = z.infer<ReturnType<typeof useTagItem>>;

export const CheckStatus = z.enum(["CHECKED", "INDETERMINATE", "UNCHECKED"]);
export type CheckStatus = z.infer<typeof CheckStatus>;

export const useBulkEditTag = () => {
  return z.object({
    name: z.string(),
    status: CheckStatus,
  });
};
export const useBulkEditTags = () => {
  const target = useBulkEditTag();
  return z.object({
    tags: z.array(target),
  });
};
export type BulkEditTagsSchema = z.infer<ReturnType<typeof useBulkEditTags>>;
