import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";

export type DeleteMetricDialogProps = {
  dialog: Pick<DialogWithCloseIconProps, "open" | "onClose" | "yesButton" | "noButton" | "title">;
  name: string;
  onSubmit?: () => void;
  description: string;
};

const DeleteMetricDialog: React.FC<DeleteMetricDialogProps> = props => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();

  const baseDialogProps: DialogWithCloseIconProps = {
    ...props.dialog,
    yesButton: {
      ...props.dialog.yesButton,
      color: "error",
      children: t("削除"),
      onClick: () => {
        props.dialog.yesButton?.onClick?.();
        props.onSubmit?.();
      },
      autoFocus: false,
    },
    noButton: {
      ...props.dialog.noButton,
      variant: "outlined",
      color: "outline-only",
      children: t("キャンセル"),
    },
    disableEnter: true,
    optionalDialog: {
      disableAutoFocus: true,
      disableRestoreFocus: true,
    },
  };
  return (
    <DialogWithCloseIcon {...baseDialogProps}>
      <Typography fontSize="14px" mb={1}>
        {t2("DeleteName", {
          name: props.name,
        })}
      </Typography>
      <Typography fontSize="14px">{props.description}</Typography>
    </DialogWithCloseIcon>
  );
};

DeleteMetricDialog.displayName = "DeleteMetricDialog";

export default DeleteMetricDialog;
