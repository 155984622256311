import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import FormControlLabel, { FormControlLabelProps } from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup, { RadioGroupProps } from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useController } from "react-hook-form";

import { useSearchFormContext } from "../../PrivateContext";
import SplitButton, { SplitButtonProps } from "../SplitButton/SplitButton";

type SingleChoiceOption = {
  value: string;
  displayName: string;
  iconSrc?: string;
};

type FieldValue = string | null;

export type SingleChoiceFieldProps = {
  name: string;
  title: string;
  options: SingleChoiceOption[];
  onChange?: (value: FieldValue) => void;
  disabled?: boolean;
  variant?: SplitButtonProps["variant"];
};

const SingleChoiceField: React.FC<SingleChoiceFieldProps> = props => {
  const { onChange } = props;
  const { subscribeClearAllFields: onClearFields } = useSearchFormContext();
  const { field } = useController<Record<string, FieldValue>>({
    name: props.name,
  });
  const [selectedValue, setSelectedValue] = React.useState<string | null>(field.value);
  const selectedDisplayName = props.options.find(option => option.value === selectedValue)?.displayName;

  const resetField = React.useCallback(() => {
    setSelectedValue(null);
    onChange?.(null);
    field.onChange(null);
  }, [field, onChange]);

  React.useEffect(() => {
    const stop = onClearFields(() => {
      setSelectedValue(null);
      onChange?.(null);
    });
    return () => {
      stop();
    };
  }, [onChange, onClearFields]);

  const splitButtonProps: SplitButtonProps = {
    title: props.title,
    disabled: props.disabled,
    status: selectedValue !== null ? "ACTIVE" : "INACTIVE",
    label: {
      children: selectedValue === null || !selectedDisplayName ? props.title : [props.title, selectedDisplayName].join(": "),
    },
    onApply: () => {
      onChange?.(selectedValue);
      field.onChange(selectedValue);
    },
    onReset: () => {
      resetField();
    },
    variant: props.variant,
  };

  const handleChange: Exclude<RadioGroupProps["onChange"], undefined> = (_, value) => {
    setSelectedValue(value);
  };

  return (
    <SplitButton {...splitButtonProps}>
      <Box sx={{ maxHeight: 250, overflow: "auto" }}>
        <RadioGroup onChange={handleChange}>
          {props.options.map(option => {
            const Icon = typeof option.iconSrc === "string" ? <Avatar sx={{ width: 20, height: 20, mr: 1 }} src={option.iconSrc} /> : null;
            const checked = option.value === selectedValue;
            const formControlLabelProps: FormControlLabelProps = {
              control: <Radio color="secondary" checked={checked} />,
              label: (
                <Box component="span" display="flex">
                  {Icon}
                  <Typography variant="body2">{option.displayName}</Typography>
                </Box>
              ),
              value: option.value,
            };
            return <FormControlLabel key={option.value} {...formControlLabelProps} />;
          })}
        </RadioGroup>
      </Box>
    </SplitButton>
  );
};

SingleChoiceField.displayName = "SingleChoiceField";

export default SingleChoiceField;
