import { App } from "@hireroo/app-store/essential/employee";
import { DashboardScreeningsStatsSection } from "@hireroo/app-store/widget/e/DashboardScreeningsStatsSection";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import DashboardScreeningStatsSectionContainer, { DashboardScreeningStatsSectionContainerProps } from "./Container";

export type DashboardScreeningStatsSectionFetchContainerProps = {
  selectedScreeningId: string;
};

const DashboardScreeningStatsSectionFetchContainer: React.FC<DashboardScreeningStatsSectionFetchContainerProps> = props => {
  const appStatus = App.useStatus();
  const screeningInitialized = DashboardScreeningsStatsSection.useScreeningInitialized();
  const [result, refresh] = Graphql.useGetScreeningForDashboardScreeningStatsSectionQuery({
    variables: {
      screeningId: props.selectedScreeningId,
      withSpotStats: true,
    },
    requestPolicy: "cache-and-network",
    pause: appStatus !== "INITIALIZED",
  });

  React.useEffect(() => {
    if (result.data) {
      DashboardScreeningsStatsSection.initializeSelectedScreening(result.data.screening);
    }
  }, [result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !screeningInitialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: DashboardScreeningStatsSectionContainerProps = {
    selectedScreeningId: props.selectedScreeningId,
  };

  return <DashboardScreeningStatsSectionContainer {...containerProps} />;
};

DashboardScreeningStatsSectionFetchContainer.displayName = "DashboardScreeningStatsSectionFetchContainer";

export default withErrorBoundary(DashboardScreeningStatsSectionFetchContainer, {});
