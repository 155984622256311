import { useTranslation } from "@hireroo/i18n";
import { ScreeningTestRetakeForm } from "@hireroo/validator";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import InputControlTextField, {
  InputControlTextFieldProps,
} from "../../../../primitive/InputControl/InputControlTextField/InputControlTextField";
import RadioControlField, { RadioControlFieldProps } from "../../../../primitive/InputControl/RadioControlField/RadioControlField";

const ReservedFieldName = {
  REASON_TYPE: "reasonType",
  REASON_COMMENT: "reasonComment",
} satisfies Record<string, keyof ScreeningTestRetakeForm.RetakeScreeningTestSchema>;

const ReasonTypeValueMap = {
  SYSTEM_ERROR: "SYSTEM_ERROR",
  INTERVIEW_MISDELIVERY: "INTERVIEW_MISDELIVERY",
  INSUFFICIENT_TIME_LIMIT: "INSUFFICIENT_TIME_LIMIT",
  OTHER: "OTHER",
} as const satisfies Record<string, ScreeningTestRetakeForm.RetakeScreeningTestSchema["reasonType"]>;

export type RetakeReasonFieldProps = {};

const RetakeReasonField: React.FC<RetakeReasonFieldProps> = _props => {
  const { t } = useTranslation();
  const methods = useFormContext<ScreeningTestRetakeForm.RetakeScreeningTestSchema>();
  const validateSchema = ScreeningTestRetakeForm.useReasonTypeSchema();
  const systemErrorLabelProps: RadioControlFieldProps["options"][0] = {
    name: "systemError",
    value: ReasonTypeValueMap.SYSTEM_ERROR,
    label: t("バグが発生した"),
  };
  const interviewMisdeliveryLabelProps: RadioControlFieldProps["options"][0] = {
    name: "interviewMisdelivery",
    value: ReasonTypeValueMap.INTERVIEW_MISDELIVERY,
    label: t("操作ミス"),
  };
  const insufficientTimeLimitLabelProps: RadioControlFieldProps["options"][0] = {
    name: "insufficientTimeLimit",
    value: ReasonTypeValueMap.INSUFFICIENT_TIME_LIMIT,
    label: t("期日を過ぎた"),
  };
  const otherLabelProps: RadioControlFieldProps["options"][0] = {
    name: "other",
    value: ReasonTypeValueMap.OTHER,
    label: t("その他"),
  };
  const reasonTypeRadioLabelProps: RadioControlFieldProps = {
    color: "secondary",
    row: true,
    name: ReservedFieldName.REASON_TYPE,
    size: "small",
    control: methods.control,
    options: [systemErrorLabelProps, interviewMisdeliveryLabelProps, insufficientTimeLimitLabelProps, otherLabelProps],
    onChange: value => {
      const result = validateSchema.safeParse(value);
      if (result.success) {
        methods.setValue("reasonType", result.data);
      }
    },
  };
  const detailProps: InputControlTextFieldProps = {
    multiline: true,
    minRows: 3,
    maxRows: 10,
    placeholder: t("選択した理由を詳細にご記入ください。"),
    control: methods.control,
  };
  return (
    <Stack spacing={1.5}>
      <Typography fontSize={14} color="textSecondary">
        {t("サービス品質向上のため、再試験の理由を聞かせてください。")}
      </Typography>
      <RadioControlField {...reasonTypeRadioLabelProps} />
      <InputControlTextField name={ReservedFieldName.REASON_COMMENT} {...detailProps} />
    </Stack>
  );
};

RetakeReasonField.displayName = "RetakeReasonField";

export default RetakeReasonField;
