import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";

const StyledButton = styled(Button)(({ theme }) => ({
  height: 32,
  width: 32,
  color: theme.palette.text.primary,
  border: "none",
  borderColor: "transparent",
  "&:hover": {
    backgroundColor: theme.palette["Gray/Shades"].p8,
    borderColor: "transparent",
  },
  svg: {
    width: "0.75em",
    height: "0.75em",
  },
}));

export type SquareButtonProps = Pick<ButtonProps, "id" | "onClick" | "disabled" | "children"> & {
  tooltipText: string;
};

const SquareButton: React.FC<SquareButtonProps> = props => {
  const { tooltipText, ...rest } = props;
  const tooltipProps: Omit<TooltipProps, "children"> = {
    title: tooltipText,
    placement: "top",
    componentsProps: {
      popper: {
        sx: {
          ".MuiTooltip-tooltipPlacementTop": {
            /**
             * FIXME remove !important flag
             */
            marginBottom: `0 !important`,
          },
        },
      },
    },
  };
  if (rest.disabled) {
    return <StyledButton {...rest} />;
  }
  return (
    <Tooltip {...tooltipProps}>
      <StyledButton {...rest} />
    </Tooltip>
  );
};

SquareButton.displayName = "SquareButton";

export default SquareButton;
