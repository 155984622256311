import { cacheExchange, createClient as createUrqlClient, dedupExchange, fetchExchange } from "urql";

import authExchange from "./firebase-exchange";

export const createClient = () => {
  return createUrqlClient({
    url: import.meta.env.VITE_GRAPHQL_SERVER_ADDRESS,
    exchanges: [dedupExchange, cacheExchange, authExchange, fetchExchange],
  });
};
