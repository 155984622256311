import { RemoteInterviewTutorial } from "@hireroo/app-store/widget/shared/RemoteInterviewTutorial";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { useHelpCenterNavigate } from "@hireroo/router/hooks";
import React from "react";

import RemoteInterviewParticipantsContainer from "../../../../../../widget/v2/shared/RemoteInterviewParticipants/Container";
import LiveCodingPreJoinScreenContainer from "../LiveCodingPreJoinScreen/Container";

type Mode = "WAITING_ROOM" | "LIVE_CODING" | "COMPLETE";

export type GenerateRemoteInterviewCandidateHeaderPropsArgs = {
  mode: Mode;
};

export const useGenerateProps = (args: GenerateRemoteInterviewCandidateHeaderPropsArgs): Widget.RemoteInterviewCandidateHeaderProps => {
  const { mode } = args;
  const ModeMap = {
    LIVE_CODING: <LiveCodingPreJoinScreenContainer />,
    WAITING_ROOM: null,
    COMPLETE: null,
  } satisfies Record<Mode, React.ReactNode>;
  const lang = useLanguageCode();
  const helpCenterNavigate = useHelpCenterNavigate(lang);

  const { t } = useTranslation();
  return {
    Participants: mode === "LIVE_CODING" && <RemoteInterviewParticipantsContainer />,
    VideoButton: React.useMemo(() => ModeMap[mode], [ModeMap, mode]),
    helpMenu: {
      options: [
        mode === "LIVE_CODING" && {
          text: t("チュートリアル再開"),
          value: "tutorial",
          icon: "LIVE_HELP" as const,
          onClick: () => {
            RemoteInterviewTutorial.updateRunTutorial(true);
          },
        },
        {
          text: t("ヘルプ"),
          value: "help",
          icon: "INFO" as const,
          onClick: () => {
            helpCenterNavigate("CANDIDATE_HELP_PAGE", { _blank: true });
          },
        },
      ].flatMap(v => (v ? [v] : [])),
    },
    item:
      mode !== "LIVE_CODING"
        ? {
            type: "BOTH",
          }
        : {
            type: "MANY",
            content: {},
          },
  };
};
