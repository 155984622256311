import * as React from "react";

const KEY_NAME = "CODE_NAME";
const FEATURE_NAME = "eclipse";

export const useReDesignFeatureForDevelopment = () => {
  const [codeName] = React.useState(window.localStorage.getItem(KEY_NAME));
  return codeName === FEATURE_NAME;
};

export const useEnabledLeakScore = () => {
  return React.useMemo(() => {
    return window.localStorage.getItem("ENABLE_LEAK_SCORE") === "true";
  }, []);
};

export const useEnabledPlaybackUpsell = () => {
  return React.useMemo(() => {
    return window.localStorage.getItem("ENABLE_PLAYBACK_UPSELL") === "true";
  }, []);
};

export const useEnabledDevOnlyPage = () => {
  return React.useMemo(() => {
    return window.localStorage.getItem("ENABLED_DEV_ONLY_PAGE") === "true";
  }, []);
};

export const useEnableSampleUploadContent = () => {
  return React.useMemo(() => {
    return window.localStorage.getItem("ENABLE_SAMPLE_UPLOAD_CONTENT") === "true";
  }, []);
};

export const useEnabledQuestionSkillTagSearch = () => {
  return React.useMemo(() => {
    return window.localStorage.getItem("ENABLED_QUESTION_SKILL_TAG_SEARCH") === "true";
  }, []);
};

export const getEnabledProjectLog = (): boolean => {
  return window.localStorage.getItem("ENABLED_PROJECT_LOG") === "true";
};

export const useEnabledProjectPlayback = () => {
  return React.useMemo(() => {
    return window.localStorage.getItem("ENABLED_PROJECT_PLAYBACK") === "true";
  }, []);
};
