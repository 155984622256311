import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import * as Subscriber from "./Subscriber";
import { GenerateProjectContentsViewerV3PropsArgs, useGenerateProps } from "./useGenerateProps";

export type ProjectContentsViewerV3ContainerProps = GenerateProjectContentsViewerV3PropsArgs;

const ProjectContentsViewerV3Container: React.FC<ProjectContentsViewerV3ContainerProps> = props => {
  const initialized = ProjectTestReport.ProjectContentsViewerV3.useInitialized(props.projectId);
  const action = ProjectTestReport.ProjectContentsViewerV3.createAction(props.projectId);
  const { useSourceFileFetchStatus: useDiffFileFetchStatus } = ProjectTestReport.ProjectContentsViewerV3.createHooks(props.projectId);
  const status = useDiffFileFetchStatus();
  const projectContentsViewerV3Props = useGenerateProps(props);
  React.useEffect(() => {
    const stop = Subscriber.startSubscribeHistoryCurrentSubmission(props.projectId);
    return () => {
      stop();
    };
  }, [props.projectId]);
  React.useEffect(() => {
    if (status === "BEFORE_FETCH") {
      action.setSourceFileFetchStatus("CAN_FETCH");
    }
  }, [action, status]);
  if (!initialized) {
    return <Widget.Loading kind="TOP" />;
  }
  return (
    <ErrorBoundary>
      <Widget.ProjectContentsViewerV3 {...projectContentsViewerV3Props} />
    </ErrorBoundary>
  );
};

ProjectContentsViewerV3Container.displayName = "ProjectContentsViewerV3Container";

export default withErrorBoundary(ProjectContentsViewerV3Container, {});
