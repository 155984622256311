import { useRemoteStatusMap } from "@hireroo/app-definition/remote";
import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { RemotesStore } from "@hireroo/app-store/page/e/remotes";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/browser";

type Item = Widget.RemoteInterviewListProps["table"]["items"][0];

export type GenerateRemoteInterviewListPropsArgs = {};

export const useGenerateProps = (_args: GenerateRemoteInterviewListPropsArgs): Widget.RemoteInterviewListProps => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const client = getGraphqlClient();
  const res = RemotesStore.useResponse();
  const displayStatusMap = useRemoteStatusMap();
  const navigate = useTransitionNavigate();
  const pager = RemotesStore.usePager();

  const items: Item[] = (res.remotes ?? []).map((remote): Item => {
    return {
      id: remote.id,
      title: remote.name,
      href: generatePath("/e/remotes/:id/detail", { pathParams: { id: remote.remoteId } }),
      onClick: () => {
        navigate("/e/remotes/:id/detail", { pathParams: { id: remote.remoteId } });
      },
      candidateName: remote.candidateName,
      status: {
        text: displayStatusMap[remote.status],
        color: remote.status === "CREATED" || remote.status === "COMPLETED" ? "PRIMARY" : "GRAY",
      },
      tags: remote.tags.map(tag => tag.name),
      assign: {
        creator: {
          label: remote.creator?.displayName || remote.creator?.email || t("不明"),
          src: remote.creator?.photoUrl,
        },
      },
      startButton:
        remote.status === "CREATED" || remote.status === "STARTED"
          ? {
              title: t2("StartTarget", { target: remote.name }),
              button: {
                children: remote.status === "STARTED" ? t("入室") : t("開始"),
                startIcon: remote.status === "STARTED" ? "LOGIN" : "PLAY",
                onClick: () => {
                  if (remote.status === "STARTED") {
                    navigate("/e/remotes/:id", {
                      pathParams: {
                        id: remote.remoteId,
                      },
                    });
                  }
                },
              },
              onSubmit: controller => {
                controller.setLoading(true);
                client
                  .StartRemoteForInterviewList({
                    input: {
                      remoteId: remote.remoteId,
                    },
                  })
                  .then(() => {
                    navigate("/e/remotes/:id", {
                      pathParams: {
                        id: remote.remoteId,
                      },
                    });
                    controller.close();
                    Snackbar.notify({
                      severity: "success",
                      message: t("インタビューが開始されました。"),
                    });
                  })
                  .catch(error => {
                    Sentry.captureException(error);
                    const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                      error,
                      t("インタビューの開始に失敗しました。しばらくしてから再度お試しください。"),
                    );
                    Snackbar.notify({
                      severity: "error",
                      message: errorNotification.message,
                    });
                  })
                  .finally(() => {
                    controller.setLoading(false);
                  });
              },
            }
          : undefined,
      evaluation:
        remote.status === "EVALUATED"
          ? {
              status: remote.isPassed ? "PASSED" : "NOT_PASSED",
            }
          : {
              status: "CANNOT_EVALUATE",
            },
    };
  });

  return {
    table: {
      items: items,
    },
    refreshKey: `${items.length}`,
    tablePagination: {
      count: res.count,
      rowsPerPage: pager.size,
      onRowsPerPageChange: event => {
        const newSize = parseInt(event.target.value, 10);
        RemotesStore.updateSize(newSize);
      },
      page: pager.page,
      onPageChange: (_, newPage) => {
        RemotesStore.updatePage(newPage);
      },
    },
    searchResultBar: {
      resultText: t2("SearchResultCount", { count: res.count }),
      defaultValue: pager.sortFieldValue,
      onChangeSortMethod: value => {
        RemotesStore.updateSortField(value);
      },
    },
  };
};
