import { currentWindowMatchPath } from "./matchPath";

export type RoutingType =
  | "EMPLOYEE"
  | "CANDIDATE"
  | "ROOT"
  | "TALENT"
  | "SIGN_IN_EMPLOYEE_OR_TALENT"
  | "SIGN_UP_EMPLOYEE_OR_TALENT"
  | "NOT_FOUND"
  | "RESET_PASSWORD"
  | "AUTH"
  | "INVITATION_FOR_EMPLOYEE"
  | "INVITATION_FOR_TALENT";

export const getCurrentRoutingType = (): RoutingType => {
  const pathname = window.location.pathname;
  if (currentWindowMatchPath("/")) {
    return "ROOT";
  }
  if (currentWindowMatchPath("/signin")) {
    return "SIGN_IN_EMPLOYEE_OR_TALENT";
  }
  if (currentWindowMatchPath("/signup")) {
    return "SIGN_UP_EMPLOYEE_OR_TALENT";
  }
  if (pathname.startsWith("/e")) {
    if (currentWindowMatchPath("/e/invitations/:id")) {
      return "INVITATION_FOR_EMPLOYEE";
    }
    return "EMPLOYEE";
  }
  if (pathname.startsWith("/t")) {
    if (currentWindowMatchPath("/t/invitations/:id")) {
      return "INVITATION_FOR_TALENT";
    }
    return "TALENT";
  }
  if (pathname.startsWith("/c")) {
    return "CANDIDATE";
  }
  if (currentWindowMatchPath("/auth")) {
    return "AUTH";
  }
  if (currentWindowMatchPath("/reset/password")) {
    return "RESET_PASSWORD";
  }
  return "NOT_FOUND";
};
