import { generateKey } from "@hireroo/app-helper/parser";
import type { AreaHistogramProps } from "@hireroo/charts/react/AreaHistogram";
import { useTranslation } from "@hireroo/i18n";
import * as React from "react";
import { useSnapshot } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = () => {
  const snapshot = useSnapshotState();
  return snapshot.initialized;
};

export const useTags = () => {
  const snapshot = useSnapshotState();
  return snapshot.tags;
};

export const useQuery = () => {
  const snapshot = useSnapshotState();
  return snapshot.query;
};

const useQueryKey = () => {
  const snapshot = useSnapshotState();
  return React.useMemo(() => {
    return generateKey(snapshot.query);
  }, [snapshot.query]);
};

export const useBins = () => {
  const snapshot = useSnapshotState();
  const queryKey = useQueryKey();
  const result = snapshot.queryResultMap.get(queryKey);
  const statistics = result?.statistics;
  return React.useMemo(() => {
    const bins = statistics?.bins || [];
    if (bins.length > 0) {
      const binWidth = 100 / bins.length;

      const a = bins.map((v, i) => {
        const middle = Math.floor(binWidth * i * 100) / 100 + binWidth / 2;
        return {
          score: middle,
          value: v,
        };
      });
      return [{ score: 0, value: 0 }, ...a, { score: 100, value: 0 }];
    }
    return [];
  }, [statistics]);
};

export const useBenchMarks = (showRank: boolean) => {
  const { t } = useTranslation();
  const snapshot = useSnapshotState();
  const queryKey = useQueryKey();
  const result = snapshot.queryResultMap.get(queryKey);
  const statistics = result?.statistics;
  const rank = result?.rank;
  return React.useMemo((): AreaHistogramProps["lines"] => {
    const stats = statistics?.relativeScore;
    const lines: AreaHistogramProps["lines"] = stats
      ? [
          {
            x: stats.p25 * 100,
            label: "25",
            strokeType: "dash",
          },
          {
            x: stats.p50 * 100,
            label: "50",
            strokeType: "dash",
          },
          {
            x: stats.p75 * 100,
            label: "75",
            strokeType: "dash",
          },
          {
            x: stats.p95 * 100,
            label: "95",
            strokeType: "dash",
          },
        ]
      : [];
    if (rank && stats) {
      return [
        {
          x: rank.relativeScore * 100,
          label: showRank
            ? {
                kind: "WITH_RANK",
                rank: {
                  rank: rank.rank,
                  numSubset: rank.numSubset,
                },
              }
            : {
                kind: "COMMON",
                text: t("受験者"),
              },
          strokeType: "solid",
        },
        ...lines,
      ];
    }
    return [];
  }, [rank, showRank, statistics?.relativeScore, t]);
};

export const useGraphStatus = (): Types.GraphStatus => {
  const snapshot = useSnapshotState();
  const queryKey = useQueryKey();
  const result = snapshot.queryResultMap.get(queryKey);
  if (result) {
    return result.status;
  }
  return "LOADING";
};
