import { ScreeningResourceEditorQueryParams, type ScreeningResourceEditorQueryParamsValue } from "@hireroo/app-definition/spot";
import { useAlgorithmVariantLabelMap, useProjectVariantLabelMap, useQuestionTypeLabelMap } from "@hireroo/app-helper/question";
import { DeepReadonly } from "@hireroo/app-helper/types";
import { QuestionSelectFieldForResourceEditor } from "@hireroo/app-store/widget/e/QuestionSelectFieldForResourceEditor";
import { languageMapForDisplay } from "@hireroo/challenge/definition";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { useComponentTypesMap } from "@hireroo/system-design/hooks";
import { Fields, ScreeningResourceEditorForm } from "@hireroo/validator";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

export const languageMap: Record<Graphql.InvitationLanguage, Fields.InvitationField.EmailInviteSchema["invitationLanguage"]> = {
  JA: "ja",
  EN: "en",
  UNKNOWN: "en",
};

export const candidateAccessPolicy: Record<Graphql.ScreeningCandidateAccessPolicy, ScreeningResourceEditorForm.CandidateAccessPolicy> = {
  ALLOW_ALL: "ALLOW_ALL",
  RESTRICTED_BY_INVITATION_CODE: "RESTRICTED_BY_INVITATION_CODE",
};

export const convertGraphqlEntitySourceToEntitySourceToValidator = (
  entitySource: Graphql.ScreeningEntitySourceQuestionForScreeningResourceEditorFragment,
  isAvailableQuestion: (variant: Graphql.QuestionVariant) => boolean,
): Fields.EntitySource.EntitySource => {
  switch (entitySource.__typename) {
    case "ScreeningChallengeEntitySourceQuestion": {
      return {
        type: "CHALLENGE",
        uniqueKey: entitySource.question.key,
        questionId: entitySource.question.questionId,
        questionVersion: entitySource.question.version,
        enabledLanguages: entitySource.enabledLanguages.slice(),
        isSelectable: isAvailableQuestion("CHALLENGE"),
      };
    }
    case "ScreeningQuizEntitySourcePackage": {
      return {
        type: "QUIZ",
        uniqueKey: entitySource.pb_package.key,
        packageId: entitySource.pb_package.packageId,
        packageVersion: entitySource.pb_package.version,
        isSelectable: isAvailableQuestion("QUIZ"),
      };
    }
    case "ScreeningProjectEntitySourceQuestion": {
      return {
        type: "PROJECT",
        uniqueKey: entitySource.question.key,
        questionId: entitySource.question.questionId,
        questionVersion: entitySource.question.version,
        isSelectable: isAvailableQuestion("PROJECT"),
      };
    }
    case "ScreeningSystemDesignEntitySourceQuestion": {
      return {
        type: "SYSTEM_DESIGN",
        uniqueKey: entitySource.question.key,
        questionId: entitySource.question.questionId,
        componentTypes: entitySource.componentTypes,
        isSelectable: isAvailableQuestion("SYSTEM_DESIGN"),
      };
    }
  }
};

type StepName = Exclude<Widget.ScreeningResourceEditorProviderProps["initialStepName"], undefined>;

const stepNameMap: Record<ScreeningResourceEditorQueryParamsValue, Exclude<StepName, undefined>> = {
  TestQuestionSetup: "TEST_QUESTION_SETUP",
  TestSetup: "TEST_SETUP",
  ReportSetup: "REPORT_SETUP",
  TestInviteSetup: "TEST_INVITE_SETUP",
};

export const isExpectedStepName = (stepName?: string | null): stepName is ScreeningResourceEditorQueryParamsValue => {
  return Object.keys(stepNameMap).includes(stepName as unknown as ScreeningResourceEditorQueryParamsValue);
};

export const useInitialStepName = (): StepName | undefined => {
  const [searchParams] = useSearchParams();
  const [initialStep] = React.useState(searchParams.get(ScreeningResourceEditorQueryParams.Key));
  return isExpectedStepName(initialStep) ? stepNameMap[initialStep] : undefined;
};

type ConfirmSectionItemProps = Widget.ScreeningResourceEditorProps["confirmSection"]["selectedQuestions"][0];

export const useGenerateConfirmSectionItemProps = () => {
  const { t } = useTranslation();

  const projectVariantLabelMap = useProjectVariantLabelMap();
  const questionTypeLabelMap = useQuestionTypeLabelMap();
  const algorithmVariantLabelMap = useAlgorithmVariantLabelMap();
  const componentTypeMap = useComponentTypesMap();

  return (
    title: string,
    entitySource: DeepReadonly<Fields.EntitySource.EntitySource>,
    question: QuestionSelectFieldForResourceEditor.Question,
  ): ConfirmSectionItemProps => {
    switch (question.__typename) {
      case "SystemDesignQuestion": {
        if (entitySource.type !== "SYSTEM_DESIGN") {
          console.error(entitySource);
          throw new Error("Invalid EntitySource");
        }
        return {
          title,
          variant: questionTypeLabelMap[question.__typename],
          description: [
            t("選択可能なリソース種別") + ":",
            entitySource.componentTypes.map(componentType => componentTypeMap[componentType]).join(", "),
          ].join(" "),
        };
      }
      case "MultiChoicePackage": {
        if (entitySource.type !== "QUIZ") {
          console.error(entitySource);
          throw new Error("Invalid EntitySource");
        }
        return {
          title,
          variant: questionTypeLabelMap[question.__typename],
        };
      }
      case "AlgorithmQuestion": {
        if (entitySource.type !== "CHALLENGE") {
          console.error(entitySource);
          throw new Error("Invalid EntitySource");
        }
        const variantText = [questionTypeLabelMap[question.__typename], algorithmVariantLabelMap[question.algorithmQuestionVariant]]
          .filter(Boolean)
          .join(" - ");
        return {
          title,
          variant: variantText,
          description: [
            t("選択可能な言語") + ":",
            entitySource.enabledLanguages.map(language => languageMapForDisplay[language]).join(", "),
          ].join(" "),
        };
      }
      case "ProjectQuestion": {
        if (entitySource.type !== "PROJECT") {
          console.error(entitySource);
          throw new Error("Invalid EntitySource");
        }
        const variantText = [questionTypeLabelMap[question.__typename], projectVariantLabelMap[question.projectQuestionVariant]]
          .filter(Boolean)
          .join(" - ");
        return {
          title,
          variant: variantText,
        };
      }
      case "FreepadQuestion": {
        if (entitySource.type !== "PROJECT") {
          console.error(entitySource);
          throw new Error("Invalid EntitySource");
        }
        return {
          title,
          variant: t("ライブコーディング"),
        };
      }
      default:
        throw new Error(`Invalid Question : ${question satisfies never}`);
    }
  };
};
