import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Box from "@mui/material/Box";
import LinearProgress, { LinearProgressProps } from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

import Link, { LinkProps } from "../Link/Link";

export type ScoreBarProps = {
  title: string;
  link?: Pick<LinkProps, "onClick" | "href">;

  /**
   * Value between 0 and 100
   */
  score: number;
  description?: string;
  color: LinearProgressProps["color"];
};

const ScoreBar: React.FC<ScoreBarProps> = props => {
  const theme = useTheme();
  return (
    <Stack direction="row" display="flex" alignItems="center" width="100%">
      <Box display="flex" alignItems="center" justifyContent="flex-end" mr={1} width="20%">
        <Box mr={1} alignItems="center">
          {props.link ? (
            <Link {...props.link} color="secondary">
              <Typography sx={{ wordBreak: "keep-all" }} variant="caption">
                {props.title}
              </Typography>
            </Link>
          ) : (
            <Typography sx={{ wordBreak: "keep-all" }} variant="caption">
              {props.title}
            </Typography>
          )}
        </Box>
        {props.description && (
          <Tooltip title={props.description}>
            <InfoOutlinedIcon sx={{ fontSize: "0.8rem", color: theme.palette.secondary.light }} />
          </Tooltip>
        )}
      </Box>
      <Box mr={1} width="76%">
        <LinearProgress sx={{ height: "10px" }} variant="determinate" value={props.score} role="progressbar" color={props.color} />
      </Box>
      <Box width="4%">
        <Typography variant="body2" color="textSecondary">
          {`${props.score}%`}
        </Typography>
      </Box>
    </Stack>
  );
};

ScoreBar.displayName = "ScoreBar";

export default ScoreBar;
