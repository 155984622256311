import { useTranslation } from "@hireroo/i18n";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import Box, { BoxProps } from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import FeedbackSummary, { FeedbackSummaryProps } from "../../modules/FeedbackSummary/FeedbackSummary";

export type RemoteInterviewFeedbackProps = {
  feedbacks: FeedbackSummaryProps[];
  emptyMessage: string;
};

const RemoteInterviewFeedback: React.FC<RemoteInterviewFeedbackProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();

  if (props.feedbacks.length === 0) {
    return (
      <Box p={3} height="100%">
        <Typography fontSize={16} fontWeight="bold">
          {t("技術レビュー")}
        </Typography>
        <Box mt={3}>
          <Typography variant="body1">{props.emptyMessage}</Typography>
        </Box>
      </Box>
    );
  }

  const resultCountBoxProps: BoxProps = {
    display: "flex",
    px: 2,
    py: 0.75,
    borderRadius: "100vh",
    border: "solid 1px",
    bgcolor: theme.palette["Secondary/Shades"].p12,
    borderColor: theme.palette["Secondary/Shades"].p16,
  };

  const recommendedCount = props.feedbacks.filter(feedback => feedback.status === "RECOMMENDED").length;
  const notRecommendedCount = props.feedbacks.length - recommendedCount;

  return (
    <Box p={3} height="100%">
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
        <Box width={"100%"}>
          <Typography fontSize={16} fontWeight="bold" display="inline">
            {t("技術レビュー")}
          </Typography>
          <Typography color={theme.palette.grey[400]} component="span" pl={1}>
            ({props.feedbacks.length})
          </Typography>
        </Box>
        <Box {...resultCountBoxProps}>
          <Box display={"flex"} alignItems={"center"} pr={1.25} borderRight={"solid 1px"} borderColor={theme.palette["Secondary/Shades"].p16}>
            <ThumbUpIcon sx={{ color: theme.palette.success.main, fontSize: "20px" }} />
            <Typography component="span" ml={1.25}>
              {recommendedCount}
            </Typography>
          </Box>
          <Box display={"flex"} alignItems={"center"} pl={1.25}>
            <ThumbDownIcon sx={{ color: theme.palette.error.main, fontSize: "20px" }} />
            <Typography component="span" ml={1.25}>
              {notRecommendedCount}
            </Typography>
          </Box>
        </Box>
      </Box>
      {props.feedbacks.map(feedback => (
        <Box mt={3} key={feedback.id}>
          <FeedbackSummary {...feedback} />
        </Box>
      ))}
    </Box>
  );
};

RemoteInterviewFeedback.displayName = "RemoteInterviewFeedback";

export default RemoteInterviewFeedback;
