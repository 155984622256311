import { usePlanNameMap } from "@hireroo/app-definition/payment";
import { Def } from "@hireroo/app-helper/payment";
import { PlanUpdateForm } from "@hireroo/app-store/widget/e/PlanUpdateForm";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { formatPrice } from "@hireroo/formatter/money";
import { unixToDatetimeFormat } from "@hireroo/formatter/time";
import { useLanguageCode, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import addMonths from "date-fns/addMonths";
import differenceInMonths from "date-fns/differenceInMonths";
import * as React from "react";

import { useGenerateRowPropsList } from "../../../../../../props-factory/v2/e/PaymentContractUpdateFormProps/useGenerateRowPropsList";
import { usePayInTwoMonthConfirmSectionProps, usePayNextMonthConfirmSectionProps } from "../privateHelper";

type CouponFieldProps = Exclude<Widget.PlanUpdateFormProps["couponField"], undefined>;

export type GeneratePlanUpdateFormPropsArgs = {};

export const useGenerateProps = (_args: GeneratePlanUpdateFormPropsArgs): Widget.PlanUpdateFormProps => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const plans = PlanUpdateForm.usePlans();
  const customer = PlanUpdateForm.useCustomer();
  const lang = useLanguageCode();
  const optionalSubmitValue = PlanUpdateForm.useOptionalSubmitValue();
  const initialPlan = Def.planTypeMapGraphqlToValidator[customer.subscription.plan.planType];
  const planNameMap = usePlanNameMap(customer.subscription.plan.generation);

  const head = React.useMemo((): Widget.PlanUpdateFormProps["planSelectionField"]["head"] => {
    const items = plans.map((plan): Widget.PlanUpdateFormProps["planSelectionField"]["head"]["items"][0] => {
      const usingPlan = customer.subscription.plan.planType === plan.planType;
      const price = ((): number => {
        if (customer.isTrial) {
          return 0;
        }
        return plan.price;
      })();
      return {
        kind: (() => {
          if (customer.isTrial && usingPlan) {
            return "TRIAL";
          } else if (usingPlan) {
            return "SELECTED";
          }
          return "NONE";
        })(),
        planName: planNameMap[plan.planType],
        price:
          plan.planType !== "ENTERPRISE"
            ? {
                kind: customer.subscription.periodType === "YEAR" ? "YEARLY" : "MONTHLY",
                value: formatPrice(price),
              }
            : {
                kind: "ESTIMATE",
              },
        action:
          plan.planType !== "ENTERPRISE"
            ? { kind: "RADIO", value: Def.planTypeMapGraphqlToValidator[plan.planType] }
            : {
                kind: "CONTACT_US",
              },
      };
    });
    return {
      items: items,
    };
  }, [plans, customer.isTrial, customer.subscription.periodType, customer.subscription.plan.planType, planNameMap]);

  const rows = useGenerateRowPropsList(plans.map(plan => plan.planType));

  const defaultValues = React.useMemo((): PlanUpdateForm.SubmitValue => {
    if (optionalSubmitValue) {
      return optionalSubmitValue;
    }
    return {
      plan: Def.planTypeMapGraphqlToValidator[customer.subscription.plan.planType],
      paymentPeriod: customer.subscription.periodType === "YEAR" ? "YEARLY" : "ONE_MONTHLY",
    };
  }, [optionalSubmitValue, customer.subscription.plan.planType, customer.subscription.periodType]);

  const applyResultsProps = React.useMemo((): CouponFieldProps["applyResults"] => {
    return customer.subscription.subscribedCoupons.reduce<CouponFieldProps["applyResults"]>((acc, subscribedCoupon) => {
      const { coupon } = subscribedCoupon;
      if (coupon.couponType === "PERCENT_OFF") {
        const billingMonth = addMonths(new Date(), 1);
        const leftValue = differenceInMonths(billingMonth, new Date(subscribedCoupon.createdAtSeconds * 1000));
        const rightValue = differenceInMonths(new Date(subscribedCoupon.expiresAtSeconds * 1000), billingMonth);
        const withinExpiration = 0 <= leftValue && 0 <= rightValue;
        if (!withinExpiration) {
          return acc;
        }
        return acc.concat({
          text: [
            resolveLanguage(coupon, lang, "name"),
            t2("percentDiscountDescription", {
              value: coupon.discountNumber,
              duration: `${unixToDatetimeFormat(subscribedCoupon.createdAtSeconds)} - ${unixToDatetimeFormat(
                subscribedCoupon.expiresAtSeconds,
              )}`,
            }),
          ].join(" "),
        });
      }
      return acc;
    }, []);
  }, [t2, lang, customer.subscription.subscribedCoupons]);

  const payNextMonthConfirmSectionProps = usePayNextMonthConfirmSectionProps();
  const payInTwoMonthConfirmSectionProps = usePayInTwoMonthConfirmSectionProps();

  return {
    onChange: fields => {
      PlanUpdateForm.setTemporarySubmitValue(fields);
    },
    couponField:
      applyResultsProps.length > 0
        ? {
            showCouponInputField: false,
            applyResults: applyResultsProps,
          }
        : undefined,
    onSubmit: fields => {
      if (initialPlan === fields.plan) {
        Snackbar.notify({
          severity: "warning",
          message: t("現在規約中のプランと異なるプランを選択する必要があります。"),
        });
        return;
      }
      PlanUpdateForm.setSubmitValue(fields);
      PlanUpdateForm.updateShowingTarget("CONFIRM_AND_TOS");
    },
    planSelectionField: {
      head: head,
      rows: rows,
    },
    payNextMonthConfirmSection: payNextMonthConfirmSectionProps,
    payInTwoMonthConfirmSection: payInTwoMonthConfirmSectionProps,
    defaultValues: defaultValues,
  };
};
