import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Virtuoso, VirtuosoProps } from "react-virtuoso";

import TemplateListItem, { TemplateListItemProps } from "./parts/TemplateListItem/TemplateListItem";

const MAX_HEIGHT = 650;
const MIN_ITEM_HEIGHT = 68;

const PAGER = {
  rootKey: "template-root",
  moreKey: "template-more",
} as const;

const StyledList = styled(List)(() => ({
  borderRadius: "8px",
  maxHeight: `${MAX_HEIGHT}px`,
  overflow: "auto",
}));
type InfiniteScrollableListProps = VirtuosoProps<TemplateListItemProps, unknown>;

export type FillTemplateListProps = {
  items: TemplateListItemProps[];
  loading?: boolean;
  hasNext?: boolean;
  onEndReached: InfiniteScrollableListProps["endReached"];
};

const FillTemplateList: React.FC<FillTemplateListProps> = props => {
  const { t } = useTranslation();
  const contentHeight = Math.min(MIN_ITEM_HEIGHT * props.items.length, MAX_HEIGHT);

  const virtuosoProps: InfiniteScrollableListProps = {
    data: props.items,
    style: { height: contentHeight, maxHeight: MAX_HEIGHT, overflow: "scroll" },
    totalCount: props.items.length,
    overscan: {
      main: MIN_ITEM_HEIGHT,
      reverse: MIN_ITEM_HEIGHT,
    },
    increaseViewportBy: {
      top: MIN_ITEM_HEIGHT,
      bottom: MIN_ITEM_HEIGHT,
    },
    itemContent: (index, item) => {
      return <TemplateListItem key={item.id} {...item} divider={index !== props.items.length - 1} />;
    },
    defaultItemHeight: MIN_ITEM_HEIGHT,
    endReached: props.onEndReached,
    components: {
      Footer: () => {
        if (props.items.length === 0) {
          return null;
        }

        if (!props.loading && props.hasNext) {
          return (
            <Box id={PAGER.moreKey} display="flex" justifyContent="center">
              <CircularProgress color="secondary" size={25} />
            </Box>
          );
        }
        return null;
      },
    },
  };

  return (
    <Box>
      <StyledList disablePadding id={PAGER.rootKey}>
        {props.items.length === 0 && (
          <ListItem disablePadding>
            <Typography fontSize={14}>{t("テンプレートが存在しません。")}</Typography>
          </ListItem>
        )}
        {props.items.length > 0 && <Virtuoso {...virtuosoProps} />}
        {props.loading && (
          <Box display="flex" justifyContent="center">
            <CircularProgress color="secondary" size={25} />
          </Box>
        )}
      </StyledList>
    </Box>
  );
};

FillTemplateList.displayName = "FillTemplateList";

export default FillTemplateList;
