import { TestRankVisualizer } from "@hireroo/app-store/widget/shared/TestRankVisualizer";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import TestRankVisualizerContainer, { TestRankVisualizerContainerProps } from "./Container";
import * as Subscriber from "./Subscriber";

export type TestRankVisualizerFetchContainerProps = {
  pause: boolean;
  companyId: number;
  spotId: string;
  finalizedAtSeconds: number | null;
  enableTagField: boolean;
  readOnly?: boolean;
  enableRank: boolean;
};

const TestRankVisualizerFetchContainer: React.FC<TestRankVisualizerFetchContainerProps> = props => {
  const [result] = Graphql.useListSpotTagsForTestRankVisualizerQuery({
    variables: {
      spotTagsByCompanyId: {
        companyId: props.companyId,
        size: props.enableTagField ? 100 : 0,
        withCount: false,
      },
    },
    pause: props.pause,
  });

  const initialized = TestRankVisualizer.useInitialized();

  React.useEffect(() => {
    const stop = Subscriber.startSubscribeQueryKey({
      companyId: props.companyId,
      spotId: props.spotId,
    });
    return () => {
      stop();
    };
  }, [props.companyId, props.spotId]);

  React.useEffect(() => {
    if (result.data) {
      TestRankVisualizer.initialize({
        finalizedAtSeconds: props.finalizedAtSeconds,
        tags: result.data.spotTagsByCompanyId?.tags || [],
      });
    }
  }, [props.finalizedAtSeconds, result.data]);

  if (props.pause || result.fetching || !initialized) {
    return (
      <ErrorBoundary>
        <Widget.TestRankVisualizer
          status="LOADING"
          queryToolbar={{
            enableTagField: true,
            onChange: () => undefined,
          }}
          areaHistogram={{
            data: [],
            lines: [],
          }}
        />
      </ErrorBoundary>
    );
  }
  const testRankVisualizerProps: TestRankVisualizerContainerProps = {
    enableTagField: props.enableTagField,
    enableRank: props.enableRank,
    readOnly: props.readOnly,
  };
  return (
    <ErrorBoundary>
      <TestRankVisualizerContainer {...testRankVisualizerProps} />
    </ErrorBoundary>
  );
};

TestRankVisualizerFetchContainer.displayName = "TestRankVisualizerFetchContainer";

export default withErrorBoundary(TestRankVisualizerFetchContainer, {});
