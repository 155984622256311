import { QuizFreeTextEvaluateForm } from "@hireroo/validator";
import * as React from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";

import CorrectToggleButtons, { CorrectToggleButtonsProps } from "../../../../../modules/CorrectToggleButtons/CorrectToggleButtons";

export type SubmissionAccordionEvaluationButtonGroupProps = {
  name: `${string}.isCorrect` | `${string}.evaluations.${number}.isPassed`;
};

const SubmissionAccordionEvaluationButtonGroup: React.FC<SubmissionAccordionEvaluationButtonGroupProps> = props => {
  const method = useFormContext<QuizFreeTextEvaluateForm.QuizFreeTextEvaluateForm>();

  const isCorrect = useWatch({
    control: method.control,
    name: props.name,
  });

  const defaultValue = React.useMemo((): CorrectToggleButtonsProps["defaultValue"] => {
    if (isCorrect === undefined) return undefined;
    return isCorrect ? "CORRECT" : "WRONG";
  }, [isCorrect]);

  return (
    <Controller
      name={props.name}
      control={method.control}
      render={({ field: { onChange } }) => (
        <CorrectToggleButtons onChange={value => onChange(value === "CORRECT")} defaultValue={defaultValue} />
      )}
    />
  );
};

SubmissionAccordionEvaluationButtonGroup.displayName = "SubmissionAccordionEvaluationButtonGroup";

export default SubmissionAccordionEvaluationButtonGroup;
