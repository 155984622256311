import { useTranslation } from "@hireroo/i18n";
import { Adjust } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import AddTestCase, { AddTestCaseProps } from "./parts/AddTestCase/AddTestCase";
import ReadonlyTestCase, { ReadonlyTestCaseProps } from "./parts/ReadonlyTestCase/ReadonlyTestCase";

export type AlgorithmCorrectnessTestCasesFormProps = {
  testCases: ReadonlyTestCaseProps[];
  addTestCase: AddTestCaseProps;
};

const AlgorithmCorrectnessTestCasesForm: React.FC<AlgorithmCorrectnessTestCasesFormProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Box>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Adjust fontSize="small" sx={{ color: theme.palette.common.white }} />
        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
          {t("テストケース（正解率）を入力する")}
        </Typography>
      </Stack>

      <Box mt={2}>
        <Typography variant="body2">{t("テストケース（正解率）は、提出されたコードのケースの網羅率を計測するのに利用されます。")}</Typography>
      </Box>

      <Box mt={3}>
        <Box my={2} display="flex" justifyContent="end">
          <AddTestCase {...props.addTestCase} />
        </Box>
        {props.testCases.map((testCase, index) => (
          <ReadonlyTestCase key={`${testCase.accordion.id}-${index}`} {...testCase} />
        ))}
      </Box>
    </Box>
  );
};

AlgorithmCorrectnessTestCasesForm.displayName = "AlgorithmCorrectnessTestCasesForm";

export default AlgorithmCorrectnessTestCasesForm;
