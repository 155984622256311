import Box from "@mui/material/Box";
import * as React from "react";

import RankMark, { RankMarkProps } from "../../../../../../../../modules/RankMark/RankMark";

export type RankProps = {
  value: RankMarkProps["rank"];
};

const Rank: React.FC<RankProps> = props => {
  return (
    <Box sx={{ textAlign: "center" }}>
      <RankMark rank={props.value} />
    </Box>
  );
};

Rank.displayName = "Rank";

export default Rank;
