import { subscribeKey } from "valtio/utils";

import { state } from "./State";
import * as Types from "./types";

export type SubscribeListParamsCallback = (listParams: Types.ListParams) => void;

export const subscribeListParams = (callback: SubscribeListParamsCallback) => {
  return subscribeKey(state, "listParams", callback);
};
