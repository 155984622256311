import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

const RootContainer: React.FC = () => {
  const props = useGenerateProps();

  return (
    <ErrorBoundary>
      <Widget.ErrorPanel {...props} />
    </ErrorBoundary>
  );
};

RootContainer.displayName = "RootContainer";

export default withErrorBoundary(RootContainer, {});
