import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { App, Company, Role } from "@hireroo/app-store/essential/employee";
import { GeneralSettings } from "@hireroo/app-store/widget/e/GeneralSettings";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/browser";

export type GenerateCompanyProfileSettingsPropsArgs = {};

export const useGenerateProps = (_args: GenerateCompanyProfileSettingsPropsArgs): Widget.CompanyProfileSettingsProps => {
  const { t } = useTranslation();
  const companyId = Company.useStrictActiveCompanyId();
  const client = getGraphqlClient();
  const appStatus = App.useStatus();
  const company = GeneralSettings.useCompany();
  const matchingRole = Role.useMatchingRole();

  return {
    onSubmit: async field => {
      await client
        .UpdateCompanyForGeneralSettings({
          companyId: companyId,
          name: field.companyName,
          websiteUrl: field.websiteUrl,
          logo: "",
        })
        .then(res => {
          Snackbar.notify({
            severity: "success",
            message: t("会社情報が更新されました。"),
          });

          if (res.updateCompany) {
            GeneralSettings.setCompany(res.updateCompany);
          }
        })
        .catch(error => {
          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(
            error,
            t("会社情報の更新に失敗しました。しばらくしてから再度お試しください。"),
          );
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
        });
    },
    loading: appStatus !== "INITIALIZED",
    defaultValues: {
      companyName: company?.name ?? "",
      websiteUrl: company?.websiteUrl ?? "",
    },
    saveButton: {
      disabled: !matchingRole.ADMIN_ONLY.matched,
      title: matchingRole.ADMIN_ONLY.messageOnUnmatched,
    },
  };
};
