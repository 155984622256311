import { ScreeningsStore } from "@hireroo/app-store/page/e/screenings";
import { updateQueryParamsByObject } from "@hireroo/router/api";

export const startSubscribeListParams = () => {
  return ScreeningsStore.subscribeAllState(state => {
    if (!state.currentSearchFilter) {
      return;
    }
    const { currentSearchFilter, pager } = state;

    /**
     * Do not update query parameters if they are different from the Default Value when the page is visited
     * (i.e., the user has not manipulated them).
     */
    updateQueryParamsByObject({
      [ScreeningsStore.QueryKeys.TAG]: currentSearchFilter.tags.map(tag => tag.displayName),
      [ScreeningsStore.QueryKeys.NAME]:
        currentSearchFilter.textFilter && currentSearchFilter.textFilter.length > 0 ? [currentSearchFilter.textFilter] : [],
      [ScreeningsStore.QueryKeys.ACTIVATE_STATUS]: currentSearchFilter.activeStatus ? [currentSearchFilter.activeStatus] : [],
      [ScreeningsStore.QueryKeys.SORT]: pager.sortFieldValue === "created-at-ascending" ? [`CREATED_AT-false`] : [],
      /**
       * Set page number to "1" if it is not a page change
       */
      [ScreeningsStore.QueryKeys.PAGE]: pager.page > 0 ? [(pager.page + 1).toString()] : [],
      [ScreeningsStore.QueryKeys.SIZE]: pager.size !== ScreeningsStore.DefaultPager.size ? [pager.size.toString()] : [],
    });
  });
};
