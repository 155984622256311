import Box, { BoxProps } from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import * as React from "react";

import { useLoadStyles } from "../../../../styles/useLoadStyles";

export type StyledWrapperProps = {
  cellSidebarMargin?: number;
  children?: React.ReactNode;
};

const StyledWrapper = React.forwardRef((props: StyledWrapperProps, ref) => {
  useLoadStyles();
  const { cellSidebarMargin = 0 } = props;
  const theme = useTheme();
  const boxProps: BoxProps = {
    sx: {
      colorScheme: "light",
      position: "relative",
      width: "100%",
      height: "100%",
      overflow: "hidden",
      "& .hireroo-Jupyter-Notebook": {
        height: "100%",
      },
      "& .jp-Notebook": {
        flex: "1 1 auto !important",
        height: "100%",
        overflowY: "scroll",
      },
      "& .jp-NotebookPanel": {
        height: "100% !important",
        width: "100% !important",
        position: "relative",
      },
      "& .jp-Cell": {
        width: `calc(100% - ${cellSidebarMargin}px)`,
      },
      "& .jp-Notebook-footer": {
        width: `calc(100% - ${cellSidebarMargin + 82}px)`,
      },
      "& .jp-Cell .jp-CellHeader": {
        position: "absolute",
        top: "-5px",
        left: `${cellSidebarMargin + 10}px`,
        height: "auto",
      },
      "& .jp-InputArea-editor": {
        /** For some reason, the layout is broken with table-cell. */
        display: "block",
      },
      /** When creating a Cell Sidebar, this CSS is applied. */
      "& .jp-Cell .dla-CellHeader-Container": {
        padding: "4px 8px",
        width: `${cellSidebarMargin + 10}px`,
        marginLeft: "auto",
      },
      "& .jp-CodeMirrorEditor": {
        cursor: "text !important",
      },
      /** CSS for UI displayed when typing command + F */
      "& .jp-DocumentSearch-search-filter": {
        color: theme.palette.text.secondary,
      },
      "& .jp-DocumentSearch-index-counter": {
        color: theme.palette.text.secondary,
      },
      "& .jp-DocumentSearch-overlay": {
        position: "absolute",
        top: 0,
        right: 0,
        zIndex: 7,
      },
    },
    children: props.children,
  };

  return <Box {...boxProps} ref={ref} />;
});

StyledWrapper.displayName = "StyledWrapper";

export default StyledWrapper;
