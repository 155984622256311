import { getTimeUnitText, Unit } from "@hireroo/formatter/time";
import { useTranslation } from "@hireroo/i18n";
import { useTheme } from "@mui/material/styles";
import * as React from "react";
import { Bar, CartesianGrid, ComposedChart, Label, ReferenceLine, ResponsiveContainer, Scatter, Tooltip, XAxis, YAxis, ZAxis } from "recharts";

import { BoxChartColorTheme, BoxChartColorThemeMap } from "../../definition";
import AxisTick from "./parts/AxisTick/AxisTick";
import CustomTooltip from "./parts/CustomTooltip/CustomTooltip";
import HorizontalBar from "./parts/HorizontalBar/HorizontalBar";
import VerticalBar from "./parts/VerticalBar/VerticalBar";
import { BoxPlotData, BoxPlotRawData, useBoxPlot } from "./useBoxPlot";

const LineColor = "#CD5C5C";
// It's for plot limit on graphs.
const PlotLimit = 10;

const formatDataKey = (data: BoxPlotData) => {
  return data.isPassed + ":" + data.label;
};

type LineType = {
  data: number;
  label: string;
};

export type BoxPlotProps = {
  data: BoxPlotRawData[];
  line?: LineType;
  theme?: BoxChartColorTheme;
};

const BoxPlot: React.FC<BoxPlotProps> = props => {
  const { t } = useTranslation();
  const data = useBoxPlot(props.data);
  const theme = useTheme();
  const colorMap = props.theme ? BoxChartColorThemeMap[props.theme] : BoxChartColorThemeMap.lightBlue;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart data={data} margin={{ right: 50, left: 50, top: 50, bottom: 50 }}>
        <CartesianGrid strokeDasharray="2" />
        <Bar stackId="a" dataKey="min" fill="none" barSize={50} />
        <Bar stackId="a" dataKey="bar" barSize={50} shape={<HorizontalBar theme={props.theme} />} />
        <Bar stackId="a" dataKey="bottomWhisker" barSize={50} shape={<VerticalBar theme={props.theme} />} />
        <Bar stackId="a" dataKey="bottomBox" fill={colorMap.main} barSize={50} />
        <Bar stackId="a" dataKey="bar" barSize={100} shape={<HorizontalBar theme="white" strokeWidth={5} />} />
        <Bar stackId="a" dataKey="topBox" fill={colorMap.main} barSize={50} />
        <Bar stackId="a" dataKey="topWhisker" shape={<VerticalBar theme={props.theme} />} barSize={50} />
        <Bar stackId="a" dataKey="bar" shape={<HorizontalBar theme={props.theme} />} barSize={50} />
        <Bar stackId="a" dataKey="max" fill="none" barSize={50} />

        <Scatter fill={colorMap.main} dataKey="med" />

        {new Array(PlotLimit).fill(1).map((_val, index) => (
          <Scatter key={index} fill={colorMap.contrast} dataKey={`plot${index + 1}`} />
        ))}

        {props.line && (
          <ReferenceLine
            strokeWidth={2.5}
            y={props.line.data}
            label={
              <Label position="right" fill={theme.palette.text.primary} angle={90} dy={-50} dx={15}>
                {props.line.label}
              </Label>
            }
            stroke={LineColor}
          />
        )}

        <Tooltip content={<CustomTooltip />} />
        <ZAxis type="number" dataKey="size" range={[0, 250]} />
        <XAxis dataKey={formatDataKey} fill="#FFF" tick={<AxisTick />}>
          <Label style={{ fontSize: 14, fill: theme.palette.text.primary }} offset={35} position="bottom">
            {t("テストケース")}
          </Label>
        </XAxis>
        <YAxis
          type="number"
          domain={([_min, max]) => [_min, Math.round(max * 1.1)]}
          tickFormatter={value => getTimeUnitText(value, Unit.NANOSECOND)}
        >
          <Label style={{ fontSize: 14, fill: theme.palette.text.primary }} offset={10} position="left" angle={-90} dy={-30}>
            {t("実行時間")}
          </Label>
        </YAxis>
      </ComposedChart>
    </ResponsiveContainer>
  );
};

BoxPlot.displayName = "BoxPlot";

export default BoxPlot;
