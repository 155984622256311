import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { useTitle } from "@hireroo/app-helper/react-use";
import { Auth, Company } from "@hireroo/app-store/essential/employee";
import { EmployeeSideBarContent } from "@hireroo/app-store/widget/e/EmployeeSideBarContent";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import type { Pages } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/react";
import React from "react";
import { useSearchParams } from "react-router-dom";

import EmployeeHeaderRightContentContainer from "../../../../widget/v2/e/EmployeeHeaderRightContent/Container";
import EmployeeSideBarContentContainer from "../../../../widget/v2/e/EmployeeSideBarContent/Container";
import FooterContainer from "../../../../widget/v2/e/Footer/Container";
import { useNotificationBanner } from "../../../../widget/v2/e/NotificationBanner/Container";
import RemoteTemplateResourceEditorInitialContainer from "../../../../widget/v2/e/RemoteTemplateResourceEditor/IntialContainer";
import RemoteTemplateResourceEditorMiddleContainer, {
  RemoteTemplateResourceEditorMiddleContainerProps,
} from "../../../../widget/v2/e/RemoteTemplateResourceEditor/MiddleContainer";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";

export type GenerateRemoteTemplateResourceEditorPropsArgs = {};

export const useGenerateProps = (_args: GenerateRemoteTemplateResourceEditorPropsArgs): Pages.RemoteInterviewResourceEditorProps => {
  const { t } = useTranslation();
  const navigate = useTransitionNavigate();
  const NotificationBannerContainer = useNotificationBanner();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id") ?? null;
  const client = getGraphqlClient();
  const activeCompanyId = Company.useStrictActiveCompanyId();
  const currentUid = Auth.useCurrentUid();

  const [status, setStatus] = React.useState<"READY" | "PENDING">("READY");

  useTitle(t("インタビューテンプレート作成"));

  const remoteTemplateResourceEditorProps: RemoteTemplateResourceEditorMiddleContainerProps = {
    mode: "CREATE",
    onSubmit: fields => {
      setStatus("PENDING");
      client
        .CreateRemoteInterviewTemplate({
          CreateRemoteInterviewTemplateInput: {
            title: fields.templateTitle,
            description: fields.templateDescription || "",
            remoteName: fields.remote.name || "",
            candidateName: fields.remote.candidateName || "",
            companyId: activeCompanyId,
            createdBy: currentUid,
            isPublic: fields.remote.isPublic,
            memo: fields.remote.memo,
            tagNames: fields.remote.tags.map(t => t.value.name),
            questions: fields.entityTracks.reduce<Graphql.RemoteEntitySourceInput[]>((all, entityTrack) => {
              if (entityTrack.type !== "FIXED") {
                return all;
              }
              const entitySource = entityTrack.entitySource;
              const type = entitySource.type;
              switch (type) {
                case "REMOTE_ALGORITHM":
                  return all.concat({
                    entityType: Graphql.RemoteEntityType.LiveCoding,
                    variant: Graphql.RemoteQuestionVariant.Algorithm,
                    algorithmQuestion: {
                      questionId: entitySource.questionId,
                      questionVersion: entitySource.questionVersion,
                    },
                  });
                case "REMOTE_SYSTEM_DESIGN":
                  return all.concat({
                    entityType: Graphql.RemoteEntityType.LiveCoding,
                    variant: Graphql.RemoteQuestionVariant.SystemDesign,
                    systemDesignQuestion: {
                      questionId: entitySource.questionId,
                    },
                  });
                case "REMOTE_FREEPAD":
                  return all.concat({
                    entityType: Graphql.RemoteEntityType.LiveCoding,
                    variant: "EMPTY_PAD",
                    freePadQuestion: {
                      questionId: entitySource.questionId,
                      questionVersion: entitySource.questionVersion,
                    },
                  });
                case "CHALLENGE":
                case "PROJECT":
                case "QUIZ":
                case "SYSTEM_DESIGN":
                  break;
                default:
                  throw new Error(`Invalid type: ${type satisfies never}`);
              }
              return all;
            }, []),
            variables: (fields.variables || []).map(variable => ({
              templateId: variable.templateId,
              name: variable.name,
              description: variable.description || "",
            })),
            reviewerIds: fields.remote.viewers.reduce<Graphql.ReviewerIdInput[]>((viewerIds, reviewer) => {
              if (reviewer.value === null) {
                return viewerIds;
              }
              const type = reviewer.value.type;
              switch (type) {
                case "EMPLOYEE":
                  return viewerIds.concat({
                    type: "EMPLOYEE",
                    employeeId: reviewer.value.employeeId,
                  });
                case "EMPLOYEE_GROUP":
                  return viewerIds.concat({
                    type: "EMPLOYEE_GROUP",
                    groupId: reviewer.value.groupId,
                  });
                case "TALENT":
                  break;
                default:
                  throw new Error(`Invalid type: ${type satisfies never}`);
              }
              return viewerIds;
            }, []),
          },
        })
        .then(res => {
          Snackbar.notifyWithAction({
            severity: "success",
            message: t("インタビューテンプレートの作成が完了しました。"),
            action: {
              message: t("インタビューテンプレートからインタビューを作成する"),
              callback: () => {
                navigate("/e/remotes/create", {
                  queryParams: {
                    templateId: res.createRemoteInterviewTemplate.templateId,
                  },
                });
              },
            },
          });
          navigate("/e/remotes");
        })
        .catch(error => {
          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(
            error,
            t("インタビューテンプレートの作成に失敗しました。しばらくしてから再度お試し頂くか、ヘルプボタンよりお問い合わせください。"),
          );
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
        })
        .finally(() => {
          setStatus("READY");
        });
    },
  };

  return {
    layout: {
      openSidebar: EmployeeSideBarContent.useOpenSidebar(),
      onChangeOpenSidebar: open => {
        EmployeeSideBarContent.setOpenSidebar(open);
      },
      NotificationBanner: NotificationBannerContainer,
      loading: status === "PENDING",
      HeaderRightContent: <EmployeeHeaderRightContentContainer />,
      SideBarContent: <EmployeeSideBarContentContainer />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      sideBarHeader: {
        logo: {
          href: generatePath("/e/home"),
          onClick: () => {
            navigate("/e/home");
          },
        },
      },
      navigationBreadcrumbs: {
        parentLinks: [
          {
            href: generatePath("/e/home"),
            children: t("ホーム"),
            onClick: () => {
              navigate("/e/home");
            },
          },
          {
            href: generatePath("/e/remotes/create"),
            children: t("インタビュー作成"),
            onClick: () => {
              navigate("/e/remotes/create");
            },
          },
        ],
        current: t("インタビューテンプレート作成"),
      },
    },
    children: id ? (
      <RemoteTemplateResourceEditorInitialContainer {...remoteTemplateResourceEditorProps} remoteId={id} />
    ) : (
      <RemoteTemplateResourceEditorMiddleContainer {...remoteTemplateResourceEditorProps} />
    ),
  };
};
