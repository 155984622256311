import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import Typography from "@mui/material/Typography";
import * as React from "react";

import BaseDialog, { BaseDialogProps } from "../../../../primitive/BaseDialog/BaseDialog";
import { DialogWithCloseIconProps } from "../../../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";

export type DeleteMemberProps = {
  dialog: Pick<DialogWithCloseIconProps, "open" | "onClose" | "yesButton" | "noButton">;
  employeeName: string;
  onSubmit?: () => void;
  description: string;
};

const DeleteMember: React.FC<DeleteMemberProps> = props => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();

  const baseDialogProps: BaseDialogProps = {
    ...props.dialog,
    title: t2("DeleteEmployee", {
      employeeName: props.employeeName,
    }),
    yesButton: {
      ...props.dialog.yesButton,
      disabled: false,
      color: "error",
      children: t("削除"),
      onClick: () => {
        props.dialog.yesButton?.onClick?.();
        props.onSubmit?.();
      },
      autoFocus: false,
    },
    noButton: {
      ...props.dialog.noButton,
      disabled: false,
      variant: "outlined",
      color: "outline-only",
      children: t("キャンセル"),
    },
    disableEnter: true,
    optionalDialog: {
      disableAutoFocus: true,
      disableRestoreFocus: true,
    },
  };
  return (
    <BaseDialog {...baseDialogProps}>
      <Typography fontSize="14px">{props.description}</Typography>
    </BaseDialog>
  );
};

DeleteMember.displayName = "DeleteMember";

export default DeleteMember;
