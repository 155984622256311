import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateProjectCodingEditorV3PropsArgs, useGenerateProps } from "./useGeneratePropsForEvaluationWorkspace";

export type EvaluationWorkspaceContainerProps = GenerateProjectCodingEditorV3PropsArgs;

const EvaluationWorkspaceContainer: React.FC<EvaluationWorkspaceContainerProps> = props => {
  const projectCodingEditorV3Props = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ProjectCodingEditorV3 {...projectCodingEditorV3Props} />
    </ErrorBoundary>
  );
};

EvaluationWorkspaceContainer.displayName = "ProjectCodingEditorV3Container";

export default withErrorBoundary(EvaluationWorkspaceContainer, {});
