export const INITIAL_VERSION = "v1.0.0";
export const LATEST_VERSION = "v1.1.1";
export const COMPONENT_TYPE_RELEASED_VERSION = "v1.1.0";
export const CATEGORY_REMOVED_VERSION = "v1.1.1";

/*
Version update history

v1.0.0:
- Added componentType field to the snapshot

V1.1.1:
- Removed category field from the snapshot
- Added some fields below to the AddElement action
    - t (type)
    - w (width)
    - h (height)
    - s (initial settings)

 */

export const NETWORK_SIDE_LENGTH = 640;
export const NODE_SIDE_LENGTH = 160;

export const MIN_SCALE = 0.1;

export const MAX_SCALE = Infinity;
