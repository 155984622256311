import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateIntegrationBatchMenuListPropsArgs, useGenerateProps } from "./useGenerateProps";

export type BatchMenuListContainerProps = GenerateIntegrationBatchMenuListPropsArgs;

const BatchMenuListContainer: React.FC<BatchMenuListContainerProps> = props => {
  const batchMenuListProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.IntegrationBatchMenuList {...batchMenuListProps} />
    </ErrorBoundary>
  );
};

BatchMenuListContainer.displayName = "BatchMenuListContainer";

export default withErrorBoundary(BatchMenuListContainer, {});
