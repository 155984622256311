import { Auth, Company, Role } from "@hireroo/app-store/essential/employee";
import { EmployeeSideBarContent } from "@hireroo/app-store/widget/e/EmployeeSideBarContent";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

export type GenerateCompanySelectPropsArgs = {};

export const useGenerateProps = (_args: GenerateCompanySelectPropsArgs): Widget.CompanySelectProps => {
  const mode = EmployeeSideBarContent.useMode();

  const client = getGraphqlClient();
  const uid = Auth.useCurrentUid();
  const companyPager = Company.useCompaniesPager();
  const companies = Company.useCompanies();
  const activeCompany = Company.useStrictActiveCompany();
  const role = Role.useCurrentRole();

  const [fetchable, setFetchable] = React.useState(false);
  const pause = React.useMemo(() => {
    if (!companyPager?.hasNext) {
      return true;
    }
    if (!fetchable) {
      return true;
    }
    return false;
  }, [companyPager?.hasNext, fetchable]);

  const [result] = Graphql.useGetCompaniesByEmployeeIdForEmployeeSideBarContentQuery({
    variables: {
      uid,
      size: 50,
      cursorSeconds: companyPager?.cursorSeconds ?? null,
    },
    pause: pause,
  });

  React.useEffect(() => {
    if (result.data?.companiesByEmployeeId) {
      setFetchable(false);
      Company.addCompanies(result.data?.companiesByEmployeeId.companies, result.data?.companiesByEmployeeId.pager);
    }
  }, [result.data?.companiesByEmployeeId]);

  const items = React.useMemo((): Widget.CompanySelectProps["items"] => {
    return companies.map((company): Widget.CompanySelectProps["items"][0] => ({
      default: company.companyId === activeCompany.companyId,
      text: company.name,
      value: company.companyId.toString(),
      onClick: async () => {
        const res = await client.SwitchActiveCompanyForEmployeeV2({
          input: {
            companyId: company.companyId,
            employeeId: uid,
            role,
          },
        });
        if (res.switchActiveCompanyForEmployeeV2) {
          Company.updateActiveCompanyId(company.companyId);
        }
      },
    }));
  }, [activeCompany.companyId, role, client, companies, uid]);

  return {
    mode: mode,
    items: items,
    onEndReached: () => {
      setFetchable(true);
    },
  };
};
