import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";

import EmployeeLayout, { EmployeeLayoutProps } from "../../layouts/EmployeeLayout/EmployeeLayout";

const StyledEmployeeLayout = styled(EmployeeLayout)`
  overflow: hidden;
  height: 100vh;
`;

const StyledBox = styled(Box)`
  height: calc(100vh - 48px);
  overflow: hidden;
`;

export type ScreeningResourceEditorProps = {
  layout: EmployeeLayoutProps;
  children: React.ReactNode;
};

const ScreeningResourceEditor: React.FC<ScreeningResourceEditorProps> = props => {
  const layoutProps: EmployeeLayoutProps = {
    ...props.layout,
    headerPosition: "static",
  };
  return (
    <StyledEmployeeLayout {...layoutProps}>
      <StyledBox>{props.children}</StyledBox>
    </StyledEmployeeLayout>
  );
};

ScreeningResourceEditor.displayName = "ScreeningResourceEditor";

export default ScreeningResourceEditor;
