import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Menu as BaseMenu, MenuItem, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import * as React from "react";

type Option = {
  text: string;
  value: string;
  onClick?: () => void;
  default?: boolean;
};

export type DropDownMenuProps = {
  options: Option[];
  role: string;
  menuId: string;
};

const DropDownMenu: React.FC<DropDownMenuProps> = props => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const defaultOption: Option = React.useMemo(() => {
    return (
      props.options.find(o => !!o.default) ||
      props.options[0] || {
        text: "",
        value: "",
      }
    );
  }, [props.options]);
  const [selectedOption, updateOption] = React.useState<Option>(defaultOption);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnClick = React.useCallback((newOption: Option) => {
    updateOption(newOption);
    handleClose();
  }, []);

  if (props.options.length === 0) {
    return <Skeleton width="100px" height="36px" />;
  }

  return (
    <>
      <Button role="openner" endIcon={<ArrowDropDownIcon sx={{ color: "#FFF" }} />} onClick={handleClick}>
        <Typography role={`current${props.role}`} sx={{ wordBreak: "keep-all", whiteSpace: "nowrap" }}>
          {selectedOption.text}
        </Typography>
      </Button>
      <BaseMenu role={`${props.role}menu`} id={props.menuId} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {props.options.map((option, index) => (
          <MenuItem
            role={`menu${option.text}`}
            key={`menu-${option.text}-${index}`}
            value={option.value}
            selected={selectedOption.value === option.value}
            onClick={() => {
              option.onClick?.();
              handleOnClick(option);
            }}
          >
            {option.text}
          </MenuItem>
        ))}
      </BaseMenu>
    </>
  );
};

DropDownMenu.displayName = "DropDownMenu";

export default DropDownMenu;
