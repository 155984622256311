import { App, Company } from "@hireroo/app-store/essential/employee";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

type SuggestionItem = Widget.EvaluationMetricSearchFieldProps["suggestions"][0];

type DisplaySources = Widget.EvaluationMetricSearchFieldProps["displaySources"];

export type GenerateEvaluationMetricSearchFieldPropsArgs = {};

export const useGenerateProps = (_args: GenerateEvaluationMetricSearchFieldPropsArgs): Widget.EvaluationMetricSearchFieldProps => {
  const { t } = useTranslation();
  const companyId = Company.useStrictActiveCompanyId();
  const appStatus = App.useStatus();

  const [result, refresh] = Graphql.useListEvaluationMetricsForEvaluationMetricSearchFieldQuery({
    variables: {
      companyId: companyId,
      size: 50,
      cursor: null,
      withCount: false,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "network-only",
  });

  const suggestionsProps = React.useMemo((): SuggestionItem[] => {
    const suggestions: SuggestionItem[] = [];
    result.data?.evaluationMetricsByCompany?.metrics?.forEach(metric => {
      suggestions.push({
        itemId: metric.id,
        valueId: metric.title,
      });
    });
    return suggestions;
  }, [result.data?.evaluationMetricsByCompany?.metrics]);

  const displaySourcesProps = React.useMemo((): DisplaySources => {
    const displayNames: DisplaySources = {};

    result.data?.evaluationMetricsByCompany?.metrics?.forEach(metric => {
      displayNames[metric.metricId.toString()] = {
        optionText: metric.title,
        optionSubText: metric.description,
        chipText: metric.title,
      };
    });
    return displayNames;
  }, [result.data?.evaluationMetricsByCompany?.metrics]);

  return {
    placeholder: t("評価項目を検索"),
    suggestions: suggestionsProps,
    displaySources: displaySourcesProps,
    loading: result.fetching,
    onFocus: () => {
      refresh();
    },
    title: t("評価項目名"),
  };
};
