import { useTranslation } from "@hireroo/i18n";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import { useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type QuestionVariantsProps = {
  text: string;
};

const QuestionVariants: React.FC<QuestionVariantsProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Stack spacing={2}>
      <Typography fontWeight={700}>{t("問題形式")}</Typography>
      <Typography fontSize={14}>{t("この問題集に含まれる問題形式")}</Typography>
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <LabelOutlinedIcon htmlColor={theme.palette.action.disabled} fontSize="small" />
        <Typography fontSize={14} color="textSecondary">
          {props.text}
        </Typography>
      </Stack>
    </Stack>
  );
};

QuestionVariants.displayName = "QuestionVariants";

export default QuestionVariants;
