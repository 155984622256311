import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateQuestionsTablePropsArgs, useGenerateProps } from "./useGenerateProps";

export type QuestionsTableContainerProps = GenerateQuestionsTablePropsArgs;

const QuestionsTableContainer: React.FC<QuestionsTableContainerProps> = props => {
  const questionsTableProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.QuestionTableForRemote {...questionsTableProps} />
    </ErrorBoundary>
  );
};

QuestionsTableContainer.displayName = "QuestionsTableContainer";

export default withErrorBoundary(QuestionsTableContainer, {});
