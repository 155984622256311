import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateScreeningDetailPropsArgs, useGenerateProps } from "./useGenerateProps";

export type ScreeningDetailContainerProps = GenerateScreeningDetailPropsArgs;

const ScreeningDetailContainer: React.FC<ScreeningDetailContainerProps> = props => {
  const screeningDetailProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ScreeningDetail {...screeningDetailProps} />
    </ErrorBoundary>
  );
};

ScreeningDetailContainer.displayName = "ScreeningDetailContainer";

export default withErrorBoundary(ScreeningDetailContainer, {});
