import { App, Company } from "@hireroo/app-store/essential/employee";
import { MemberSettings } from "@hireroo/app-store/widget/e/MemberSettings";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import EmployeeMemberSettingsContainer, { EmployeeMemberSettingsContainerProps } from "./Container";

export type EmployeeMemberSettingsFetchContainerProps = {};

const EmployeeMemberSettingsFetchContainer: React.FC<EmployeeMemberSettingsFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const companyId = Company.useStrictActiveCompanyId();
  const [result, refresh] = Graphql.useGetCompanyForMemberSettingsQuery({
    variables: {
      companyId: companyId,
    },
    requestPolicy: "network-only",
    pause: appStatus !== "INITIALIZED",
  });
  const initialized = MemberSettings.useInitialized();
  React.useEffect(() => {
    if (result.data) {
      MemberSettings.setCompany(result.data.company);
    }
  }, [result.data]);

  React.useEffect(() => {
    return () => {
      MemberSettings.clear();
    };
  }, []);
  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: EmployeeMemberSettingsContainerProps = {
    reload: refresh,
  };

  return <EmployeeMemberSettingsContainer {...containerProps} />;
};

EmployeeMemberSettingsFetchContainer.displayName = "EmployeeMemberSettingsFetchContainer";

export default withErrorBoundary(EmployeeMemberSettingsFetchContainer, {});
