import { useSnapshot } from "valtio";

import { state } from "./State";
import * as Types from "./types";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = () => {
  const snapshot = useSnapshotState();
  return !!snapshot.test;
};

export const useTest = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.test) {
    throw new Error("need to initialize test");
  }
  return snapshot.test;
};

export const useSelectedEntityId = () => {
  const snapshot = useSnapshotState();
  return snapshot.selectedEntityId;
};

export const useEntityMap = (): Record<Types.UniqueEntityId, Types.Test> => {
  const snapshot = useSnapshotState();
  if (!snapshot.test) {
    throw new Error("need to initialize test");
  }

  return snapshot.test.entities.reduce((prev, current): Record<Types.UniqueEntityId, Types.Test> => {
    return { ...prev, [current.id]: current };
  }, {});
};

export const useEnabledHint = () => {
  // TODO: check when backend is ready because the state is looking at GraphQL data and it's not ready yet
  return true;
};
