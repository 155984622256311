import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Button, { type ButtonProps } from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { usePaginator, UsePaginatorArgs } from "../../../../hooks";
import TechnicalCommentListItemButton, {
  TechnicalCommentListItemButtonProps,
} from "../TechnicalCommentListItemButton/TechnicalCommentListItemButton";

export type SelectItemSectionProps = {
  title: string;
  description: string;
  itemTitle: string;
  backButton: Pick<ButtonProps, "onClick">;
  nextButton: Pick<ButtonProps, "onClick" | "disabled">;
  intersectCallback?: () => void;
  items: (TechnicalCommentListItemButtonProps & { id: string })[];
  hasNext: boolean;
  status: "LOADING" | "NONE";
  pager: {
    rootKey: string;
    moreKey: string;
  };
};

const SelectItemSection: React.FC<SelectItemSectionProps> = props => {
  const { t } = useTranslation();

  const paginatorArgs: UsePaginatorArgs = {
    root: props.pager.rootKey,
    target: props.pager.moreKey,
    callback: () => {
      props.intersectCallback?.();
    },
    deps: [props.intersectCallback],
  };
  usePaginator(paginatorArgs);

  return (
    <Box id={props.pager.rootKey} textAlign={"left"} height="100%" display={"flex"} flexDirection={"column"} justifyContent="space-between">
      <Box mb={2}>
        <Typography align={"center"} variant={"subtitle1"}>
          <Box fontWeight="fontWeightBold">{props.title}</Box>
        </Typography>
        <Box mt={2} />
        <Typography color="textSecondary">{props.description}</Typography>
        <Box mt={2} />
        <List dense style={{ maxHeight: "360px", overflow: "auto" }}>
          <ListItem>
            <Grid container>
              <Grid item xs={5}>
                <Typography fontSize={12} lineHeight="266%">
                  {props.itemTitle}
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography fontSize={12} lineHeight="266%">
                  {t("説明")}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
          <Divider orientation="horizontal" />
          {props.items.map(item => (
            <TechnicalCommentListItemButton key={item.id} {...item} />
          ))}
          {(props.hasNext || props.status === "LOADING") && (
            <ListItem id={props.pager.moreKey} divider>
              <ListItemText primary={t("読み込み中...")} />
            </ListItem>
          )}
        </List>
      </Box>
      <Box flexDirection={"row"} justifyContent={"space-between"} display={"flex"} width={"100%"}>
        <Button variant="outlined" color="outline-only" {...props.backButton}>
          {t("戻る")}
        </Button>
        <Button variant={"contained"} {...props.nextButton}>
          {t("次へ")}
        </Button>
      </Box>
    </Box>
  );
};

SelectItemSection.displayName = "SelectItemSection";

export default SelectItemSection;
