import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import SystemDesignScoringItem, { SystemDesignScoringItemProps } from "../SystemDesignScoringItem/SystemDesignScoringItem";

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  color: theme.palette.text.secondary,
}));

export type SystemDesignScoringGroupProps = {
  title: string;
  items: SystemDesignScoringItemProps[];
};

const SystemDesignScoringGroup: React.FC<SystemDesignScoringGroupProps> = props => {
  return (
    <Box>
      <TitleTypography gutterBottom mb={1}>
        {props.title}
      </TitleTypography>
      {props.items.map((item, index) => (
        <SystemDesignScoringItem key={`scoring-item-${index}`} {...item} />
      ))}
    </Box>
  );
};

SystemDesignScoringGroup.displayName = "SystemDesignScoringGroup";

export default SystemDesignScoringGroup;
