import { signInWithGoogle } from "@hireroo/firebase";
import { useLanguageCode } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateHelpCenterUrl } from "@hireroo/router/api";
import { useHelpCenterNavigate, useTransitionNavigate } from "@hireroo/router/hooks";

import SignUpMailAddressContainer from "../SignUpMailAddress/Container";

export type GenerateSignUpPropsArgs = {};

export const useGenerateProps = (_args: GenerateSignUpPropsArgs): Widget.SignUpProps => {
  const navigate = useTransitionNavigate();
  const lang = useLanguageCode();
  const helpCenterNavigate = useHelpCenterNavigate(lang);
  return {
    googleSignInButton: {
      onClick: () => {
        signInWithGoogle();
      },
    },
    SignUpComponent: <SignUpMailAddressContainer />,
    footer: {
      helpCenterLink: {
        href: generateHelpCenterUrl(lang, "ROOT"),
        onClick: () => {
          helpCenterNavigate("ROOT", { _blank: true });
        },
      },
    },
    tosUrl: generateHelpCenterUrl(lang, "TOS_FOR_EMPLOYEE"),
    privacyPolicyUrl: generateHelpCenterUrl(lang, "PRIVACY_POLICY"),
    signInButton: {
      onClick: () => {
        navigate("/signin");
      },
    },
  };
};
