import { subscribeKey } from "valtio/utils";

import { state } from "./State";
import type * as Types from "./types";

export type SubscribeReviewedRemoteCallback = (reviewedRemote: Types.ReviewedRemote) => void;

export const subscribeReviewedRemoteKey = (callback: SubscribeReviewedRemoteCallback) => {
  return subscribeKey(state, "reviewedRemote", reviewRemote => {
    if (reviewRemote) {
      callback(reviewRemote);
    }
  });
};
