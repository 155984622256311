import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { MemberEditRoleForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";
import RadioControlField, { RadioControlFieldProps } from "../../../../primitive/InputControl/RadioControlField/RadioControlField";
import Link, { LinkProps } from "../../../../primitive/Link/Link";

const StyledStack = styled(Stack)(({ theme }) => ({
  borderRadius: "4px",
  backgroundColor: theme.palette["Gray/Shades"].p8,
  padding: theme.spacing(2),
}));

type Option = {
  id: string;
  label: string;
  description: string;
  value: MemberEditRoleForm.MemberEditRoleFormSchema["role"];
};

export type EditRoleProps = {
  dialog: Pick<DialogWithCloseIconProps, "open" | "onClose" | "yesButton" | "noButton">;
  employeeName: string;
  helpLink: Pick<LinkProps, "onClick" | "href">;
  defaultValues: MemberEditRoleForm.MemberEditRoleFormSchema;
  onSubmit: SubmitHandler<MemberEditRoleForm.MemberEditRoleFormSchema>;
};

const EditRole: React.FC<EditRoleProps> = props => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const theme = useTheme();
  const validateSchema = MemberEditRoleForm.useMemberEditRoleForm();
  const methods = useForm<MemberEditRoleForm.MemberEditRoleFormSchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: props.defaultValues,
  });
  const roleSchema = MemberEditRoleForm.useRole();
  const dialog: DialogWithCloseIconProps = {
    ...props.dialog,
    title: t("メンバーの権限"),
    yesButton: {
      onClick: React.useCallback(() => {
        props.dialog.yesButton?.onClick?.();
        methods.handleSubmit(props.onSubmit, console.warn)();
      }, [methods, props.dialog.yesButton, props.onSubmit]),
      children: t("保存"),
      color: "secondary",
    },
    noButton: {
      ...props.dialog.noButton,
      children: t("キャンセル"),
      color: "outline-only",
      variant: "outlined",
    },
    disableEnter: true,
    optionalContent: {
      sx: {
        py: 2,
        px: 3,
      },
    },
  };
  const options: Option[] = [
    {
      id: "ADMIN",
      value: "ADMIN",
      label: t("管理者"),
      description: t("管理者権限ではクレジットカードの登録や、メンバーの管理などが行なえます。"),
    },
    {
      id: "MANAGER",
      value: "MANAGER",
      label: t("マネージャー"),
      description: t("マネージャー権限ではテストの作成や削除、合否判定などが行なえます。"),
    },
    {
      id: "ENGINEER",
      value: "ENGINEER",
      label: t("エンジニア"),
      description: t("エンジニア権限では技術評価や、問題作成が行なえます。"),
    },
  ];

  const radioFieldProps: RadioControlFieldProps = {
    name: "role",
    options: options,
    color: "secondary",
    size: "small",
    onChange: value => {
      const result = roleSchema.safeParse(value);
      if (result.success) {
        methods.setValue("role", result.data);
      }
    },
  };

  return (
    <DialogWithCloseIcon {...dialog}>
      <Stack direction="row" spacing={3}>
        <Stack direction="column">
          <Typography fontSize={14} color="textSecondary" mb={3}>
            {t2("EditRoleTitle", {
              employeeName: props.employeeName,
            })}
          </Typography>
          <FormProvider {...methods}>
            <RadioControlField {...radioFieldProps} />
          </FormProvider>
        </Stack>
        <StyledStack direction="column" spacing={2}>
          <Stack direction="column" spacing={1} borderBottom="1px solid" borderColor={theme.palette.divider} pb={1.5}>
            <Typography fontSize={14} color="textSecondary" fontWeight={700}>
              {t("権限の説明")}
            </Typography>
            <Stack direction="row" spacing={1} alignSelf="stretch" alignItems="center">
              <InfoOutlinedIcon htmlColor={theme.palette.text.secondary} sx={{ fontSize: "0.8rem" }} />
              <Typography fontSize={12} color="textSecondary">
                {t("権限を管理することで誤った操作を防ぐことができます。")}
              </Typography>
            </Stack>
            <Link
              color="secondary"
              {...props.helpLink}
              children={
                <Typography color="secondary" fontSize={12}>
                  {t("各権限で行える操作について")}
                </Typography>
              }
            />
          </Stack>
          {options.map(item => (
            <Stack key={item.label} direction="column">
              <Typography fontSize={14} mb={1} color="textSecondary" fontWeight={700}>
                {item.label}
              </Typography>
              <Typography fontSize={12} color="textSecondary">
                {item.description}
              </Typography>
            </Stack>
          ))}
        </StyledStack>
      </Stack>
    </DialogWithCloseIcon>
  );
};

EditRole.displayName = "EditRole";

export default EditRole;
