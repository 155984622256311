import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import * as z from "zod";

export const useMemoEditor = () => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  return z.object({
    memo: z.string().max(10000, {
      message: t2("ValidateMaxTextSizeMessage", {
        size: 10000,
        name: t("メモ"),
      }),
    }),
  });
};
export type MemoEditorFormSchema = z.infer<ReturnType<typeof useMemoEditor>>;
