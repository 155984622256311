import { QuestionQuizPackageResourceEditor } from "@hireroo/app-store/view-domain/QuestionQuizPackageResourceEditor";
import { QuizPackageDetail } from "@hireroo/app-store/widget/e/QuizPackageDetail";
import { SearchQuizQuestion } from "@hireroo/app-store/widget/e/SearchQuizQuestion";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import FetchContainer from "./FetchContainer";
import * as Subscriber from "./Subscriber";
export type QuizResourceEditorInitialContainerProps = {};

const QuizResourceEditorInitialContainer: React.FC<QuizResourceEditorInitialContainerProps> = () => {
  React.useEffect(() => {
    const stopSubscribeSelectedQuestions = Subscriber.startSubscribeSelectedQuestions();
    QuestionQuizPackageResourceEditor.clear();
    QuizPackageDetail.clear();
    SearchQuizQuestion.clear();
    return () => {
      stopSubscribeSelectedQuestions();
    };
  }, []);

  return (
    <ErrorBoundary>
      <FetchContainer />
    </ErrorBoundary>
  );
};

QuizResourceEditorInitialContainer.displayName = "QuizResourceEditorInitialContainer";

export default withErrorBoundary(QuizResourceEditorInitialContainer, {});
