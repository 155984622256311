import { proxy } from "valtio";
import { proxyMap } from "valtio/utils";

import type { State } from "./types";

export const state = proxy<State>({
  res: null,
  usagesTimestampMap: proxyMap(),
  countTimestampMap: proxyMap(),
  pagers: proxyMap(),
  periods: [],
  page: 0,
  usagePeriodStartSeconds: 0,
});
