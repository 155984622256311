import { InterviewOverview } from "@hireroo/app-store/widget/e/InterviewOverview";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateHelpCenterUrl } from "@hireroo/router/api";

type Score = Exclude<Widget.ScreeningTestOverviewProps["customScoreAllocation"], undefined>["allocationBar"]["scores"][0];

export const useGenerateCustomScoreAllocationProps = (): Widget.ScreeningTestOverviewProps["customScoreAllocation"] => {
  const { t } = useTranslation();
  const interview = InterviewOverview.useInterview();
  const lang = useLanguageCode();

  return {
    allocationBar: {
      scores: interview.issuedEntities.map(
        (issuedEntity, index): Score => ({
          label: `Q${index + 1}`,
          weight: issuedEntity.questionWeight,
        }),
      ),
    },
    helpLink: {
      href: generateHelpCenterUrl(lang, "CUSTOM_SCORE"),
    },
    tooltip: t("トータルスコアに対しての配点割合を表示します。"),
  };
};
