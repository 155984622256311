import { useSnapshot } from "valtio";

import { state } from "./State";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

const useDemo = () => {
  const snapshot = useSnapshotState();
  return snapshot.demo;
};

export const useDemoStatus = () => {
  const demo = useDemo();
  return demo?.status;
};

export const useCandidateName = () => {
  const demo = useDemo();
  return demo?.candidate?.displayName ?? "";
};

export const useFirstEntity = () => {
  const demo = useDemo();
  const firstEntity = demo?.entities[0];
  if (!firstEntity) {
    throw new Error("Not found first entity");
  }
  return firstEntity;
};

export const useInitialized = () => {
  const demo = useDemo();
  return !!demo;
};

export const useEntities = () => {
  const demo = useDemo();
  return demo?.entities || [];
};

export const useEndInterviewStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.endInterviewStatus;
};

export const useEndInterviewLoadingStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.endInterviewLoadingStatus;
};

export const useEnabledWebSearch = () => {
  const demo = useDemo();
  return demo?.allowWebSearch ?? false;
};

export const useEnabledChatGPT = () => {
  const demo = useDemo();
  return demo?.allowChatGPT ?? false;
};
