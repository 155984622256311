import type * as Graphql from "@hireroo/graphql/client/urql";

import { state } from "./State";
import * as Types from "./types";

export const updateIsAuthorized = (newValue: boolean): void => {
  state.isAuthorized = newValue;
};

export const updateStatus = (newValue: Types.Status): void => {
  state.status = newValue;
};

export const updateSpotSettings = (newValue: Types.SpotSettingsSubscribeSlack): void => {
  state.spotSettings = newValue;
};

export const updateRemoteSettings = (newValue: Types.RemoteSettingsSubscribeSlack): void => {
  state.remoteSettings = newValue;
};

export const updateIncomingWebHook = (newValue: Graphql.SlackIncomingWebhook): void => {
  state.incomingWebHook = newValue;
};

export const updateLanguage = (newValue: Graphql.Language): void => {
  state.language = newValue;
};
