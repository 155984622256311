import { generateTimestampFromDate } from "@hireroo/app-helper/parser";
import { QuizTestReport } from "@hireroo/app-store/view-domain/QuizTestReport";
import { TotalScoreRankVisualizer } from "@hireroo/app-store/widget/shared/TotalScoreRankVisualizer";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Sentry from "@sentry/browser";

export type SubscribeQueryKeyArgs = {
  uniqueKey: QuizTestReport.UniqueKey;
  entityId: number;
  companyId: number;
  packageId: number;
  packageVersion: string;
};

const NUM_BINS = 20;
const COMPARISON_THRESHOLD = 30;

export const startSubscribeQueryKey = (args: SubscribeQueryKeyArgs) => {
  const client = getGraphqlClient();
  const { entityId, uniqueKey } = args;
  return TotalScoreRankVisualizer.subscribeQueryKey(uniqueKey, async (query, shouldRefresh) => {
    if (!shouldRefresh && QuizTestReport.Api.hasStatistics(entityId, query)) {
      QuizTestReport.setQuery(entityId, query);
      return;
    }
    client
      .GetQuizStatistics({
        statisticsSource: {
          category: "QUIZ",
          query: {
            quiz: {
              companyId: query.scope === "COMPANY" ? args.companyId : undefined,
              minTimestamp: query.enableDate && query.startDate ? generateTimestampFromDate(query.startDate) : undefined,
              maxTimestamp: query.enableDate && query.endDate ? generateTimestampFromDate(query.endDate) : undefined,
              numBins: NUM_BINS,
              packageId: args.packageId,
              packageVersion: args.packageVersion,
            },
          },
        },
        rankSource: {
          category: "QUIZ",
          query: {
            quiz: {
              companyId: query.scope === "COMPANY" ? args.companyId : undefined,
              minTimestamp: query.enableDate && query.startDate ? generateTimestampFromDate(query.startDate) : undefined,
              maxTimestamp: query.enableDate && query.endDate ? generateTimestampFromDate(query.endDate) : undefined,
              quizId: args.entityId,
            },
          },
        },
      })
      .then(({ statistics, rank }) => {
        TotalScoreRankVisualizer.setResult(uniqueKey, query, {
          status: rank.numSubset < COMPARISON_THRESHOLD ? "NOT_ENOUGH" : "SATISFY",
          statistics: statistics.quizStatistics,
          rank: rank,
        });
        QuizTestReport.setStatistics(entityId, query, statistics.quizStatistics);
      })
      .catch(error => {
        Sentry.captureException(error);
        TotalScoreRankVisualizer.setResult(uniqueKey, query, {
          status: "ERROR",
          statistics: null,
          rank: null,
        });
      });
  });
};
