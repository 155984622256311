import { useEnabledQuestionSkillTagSearch } from "@hireroo/app-helper/feature";
import { QuestionSearchArea } from "@hireroo/app-store/widget/e/QuestionSearchArea";
import { useCurrentLanguage } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { QuestionSearchForm } from "@hireroo/validator";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";

import { useGenerateSkillTagsFieldProps } from "./useGenerateSkillTagsFieldProps";

export type GenerateRemoteInterviewQuestionSearchAreaPropsArgs = {
  isLoading?: boolean;
};

export const useGenerateProps = (args: GenerateRemoteInterviewQuestionSearchAreaPropsArgs): Widget.RemoteInterviewQuestionSearchAreaProps => {
  const lang = useCurrentLanguage();

  const filters = QuestionSearchArea.useFilters();
  const filterText = lang === "en" ? filters.titleEn : filters.titleJa;
  const enabledQuestionSkillTagSearch = useEnabledQuestionSkillTagSearch();
  const skillTagsFieldProps = useGenerateSkillTagsFieldProps();

  const handleChange: SubmitHandler<QuestionSearchForm.RemoteInterviewQuestionSearchQuerySchema> = React.useCallback(
    fields => {
      QuestionSearchArea.updateFilters({
        titleEn: lang === "en" ? fields.textFilter : "",
        titleJa: lang === "ja" ? fields.textFilter : "",
        difficulties: fields.difficulties,
        questionVariants: fields.questionVariants,
        skillTagIds: fields.skillTags.map(skillTag => skillTag.value),
      });
    },
    [lang],
  );

  return {
    onChange: handleChange,
    disabled: Boolean(args.isLoading),
    enableSkillTagFilter: enabledQuestionSkillTagSearch,
    skillTagsField: skillTagsFieldProps,
    defaultValues: {
      questionVariants: filters.questionVariants as QuestionSearchForm.RemoteInterviewQuestionSchema,
      difficulties: filters.difficulties.filter(d => d !== "UNKNOWN") as QuestionSearchForm.DifficultySchema,
      textFilter: filterText,
      skillTags: filters.skillTagIds.map(skillTagId => ({ value: skillTagId })),
    },
  };
};
