import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Auth, Company, Role } from "@hireroo/app-store/essential/employee";
import { TalentMemberSettings } from "@hireroo/app-store/widget/e/TalentMemberSettings";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import * as Graphql from "@hireroo/graphql/client/graphql-request";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateCurrentOriginUrl, generateHelpCenterUrl } from "@hireroo/router/api";
import { useHelpCenterNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/react";
import * as React from "react";

type Item = Widget.EmployeeMemberSettingsProps["memberTable"]["items"][number];

export type GenerateTalentMemberSettingsPropsArgs = {
  reload: () => void;
};

export const useGenerateProps = (args: GenerateTalentMemberSettingsPropsArgs): Widget.EmployeeMemberSettingsProps => {
  const { reload } = args;
  const companyId = Company.useStrictActiveCompanyId();
  const talents = TalentMemberSettings.useFilteredTalents();
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const client = getGraphqlClient();
  const matchingRole = Role.useMatchingRole();
  const currentUserId = Auth.useCurrentUid();
  const dialogStatus = TalentMemberSettings.useDialogStatus();
  const invitationState = TalentMemberSettings.useTalentInvitation();
  const helpCenterNavigate = useHelpCenterNavigate(lang);
  const selectedMembers = TalentMemberSettings.useSelectedMembers();

  const items = React.useMemo(() => {
    return talents.map((talent): Item => {
      return {
        checkbox: {
          disabled: !matchingRole.ADMIN_ONLY.matched || talent.id === currentUserId,
          title: matchingRole.ADMIN_ONLY.messageOnUnmatched,
          checked: selectedMembers.talents.some(({ id }) => id === talent.id),
        },
        memberId: talent.id,
        photoUrl: talent.photoUrl,
        displayName: talent.displayName,
        email: talent.email,
        onSelect: selected => {
          TalentMemberSettings.selectMember(selected, talent.id);
        },
        editMenuButton: {
          disabled: !matchingRole.ADMIN_ONLY.matched || talent.id === currentUserId,
          title: matchingRole.ADMIN_ONLY.messageOnUnmatched,
        },
        items: [
          {
            value: "DELETE",
            startIcon: "DELETE",
            color: "error",
            displayName: t("タレントの削除"),
            onClick: () => {
              TalentMemberSettings.updateDialogStatus(`DELETE_TALENT_OPEN_${talent.id}`);
            },
          },
        ],
        deleteMember: {
          dialog: {
            open: dialogStatus === `DELETE_TALENT_OPEN_${talent.id}`,
            onClose: () => {
              TalentMemberSettings.updateDialogStatus("CLOSE");
            },
            noButton: {
              onClick: () => {
                TalentMemberSettings.updateDialogStatus("CLOSE");
              },
            },
          },
          employeeName: talent.displayName || talent.email,
          onSubmit: async () => {
            await client
              .DeleteCompanyTalentForTalentMemberSettings({
                input: {
                  companyId: companyId,
                  talentId: talent.id,
                },
              })
              .then(() => {
                Snackbar.notify({
                  severity: "success",
                  message: t("タレントの削除に成功しました。"),
                });
                TalentMemberSettings.updateDialogStatus("CLOSE");
                TalentMemberSettings.clear();
                reload();
              })
              .catch(error => {
                Sentry.captureException(error);
                const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                  error,
                  t("タレントの削除に失敗しました。しばらくしてから再度お試しください。"),
                );
                Snackbar.notify({
                  severity: "error",
                  message: errorNotification.message,
                });
              });
          },
          description: t(
            "削除されたタレントは会社アカウントにアクセスできなくなります。また削除されたタレントに紐づくタレントスコアはアーカイブされます。",
          ),
        },
      };
    });
  }, [
    talents,
    matchingRole.ADMIN_ONLY.matched,
    matchingRole.ADMIN_ONLY.messageOnUnmatched,
    currentUserId,
    selectedMembers.talents,
    t,
    dialogStatus,
    client,
    companyId,
    reload,
  ]);

  return {
    title: t("タレント"),
    memberTable: {
      title: t("タレント"),
      items: items,
      showingTargets: [],
      emptyMessage: t("タレントが見つかりません。"),
    },
    header: {
      unselectButton: {
        onClick: () => {
          TalentMemberSettings.clearSelectedMemberIds();
        },
        disabled: selectedMembers.talents.length === 0,
      },
      deleteButton: {
        onClick: () => {
          TalentMemberSettings.updateDialogStatus("DELETE_TALENTS_OPEN");
        },
        disabled: selectedMembers.talents.length === 0,
      },
      searchTextField: {
        placeholder: t("メールアドレス、名前を検索"),
        onSubmit: field => {
          TalentMemberSettings.updateFilterText(field.textFilter);
        },
      },
      inviteButton: {
        onClick: () => {
          TalentMemberSettings.updateDialogStatus("INVITATION_OPEN");
        },
      },
      inviteTalent: {
        dialog: {
          open: dialogStatus === "INVITATION_OPEN",
          onClose: () => {
            TalentMemberSettings.updateDialogStatus("CLOSE");
            TalentMemberSettings.TalentInvitationAction.clear();
          },
          noButton: {
            onClick: () => {
              TalentMemberSettings.updateDialogStatus("CLOSE");
              TalentMemberSettings.TalentInvitationAction.clear();
            },
          },
        },
        onSubmit: async field => {
          TalentMemberSettings.TalentInvitationAction.setStatus("PENDING");
          await client
            .CreateTalentInvitation({
              input: {
                companyId,
                employeeId: currentUserId,
                invitationMethod: "EMAIL",
                emailAddresses: field.emails.map(({ value }) => value),
                invitationLanguage: (
                  {
                    ja: Graphql.InvitationLanguage.Ja,
                    en: Graphql.InvitationLanguage.En,
                  } as const
                )[field.language],
              },
            })
            .then(({ createTalentInvitation: invitation }) => {
              TalentMemberSettings.TalentInvitationAction.setStatus("PUBLISHED");
              TalentMemberSettings.updateDialogStatus("CLOSE");
              TalentMemberSettings.TalentInvitationAction.setInvitationId(invitation.talentInvitationId);
              Snackbar.notify({
                severity: "success",
                message: t("招待リンクの送信に成功しました。"),
              });
            })
            .catch(error => {
              TalentMemberSettings.TalentInvitationAction.setStatus("PUBLISHED");
              Sentry.captureException(error);
              const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                error,
                t("招待リンクの送信に失敗しました。しばらくしてから再度お試しください。"),
              );
              Snackbar.notify({
                severity: "error",
                message: errorNotification.message,
              });
            });
        },
        onCreateInviteLink: async field => {
          TalentMemberSettings.TalentInvitationAction.setStatus("PENDING");
          await client
            .CreateTalentInvitation({
              input: {
                companyId,
                employeeId: currentUserId,
                invitationMethod: "LINK",
                emailAddresses: [],
                invitationLanguage: (
                  {
                    ja: Graphql.InvitationLanguage.Ja,
                    en: Graphql.InvitationLanguage.En,
                  } as const
                )[field.language],
              },
            })
            .then(({ createTalentInvitation: invitation }) => {
              TalentMemberSettings.TalentInvitationAction.setStatus("PUBLISHED");
              if (!invitation) {
                return;
              }
              TalentMemberSettings.TalentInvitationAction.setInvitationId(invitation.talentInvitationId);
              Snackbar.notify({
                severity: "success",
                message: t("招待リンクの生成に成功しました。リンクをコピーして共有してください。"),
              });
            })
            .catch(error => {
              TalentMemberSettings.TalentInvitationAction.setStatus("PUBLISHED");
              Sentry.captureException(error);
              const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                error,
                t("招待リンクの生成に失敗しました。しばらくしてから再度お試しください。"),
              );
              Snackbar.notify({
                severity: "error",
                message: errorNotification.message,
              });
            });
        },
        onReCreateInviteLink: async field => {
          TalentMemberSettings.TalentInvitationAction.setStatus("PENDING");
          await client
            .CreateTalentInvitation({
              input: {
                companyId,
                employeeId: currentUserId,
                invitationMethod: "LINK",
                emailAddresses: [],
                invitationLanguage: (
                  {
                    ja: Graphql.InvitationLanguage.Ja,
                    en: Graphql.InvitationLanguage.En,
                  } as const
                )[field.language],
              },
            })
            .then(({ createTalentInvitation: invitation }) => {
              TalentMemberSettings.TalentInvitationAction.setStatus("PUBLISHED");
              if (!invitation) {
                return;
              }
              TalentMemberSettings.TalentInvitationAction.setInvitationId(invitation.talentInvitationId);
              Snackbar.notify({
                severity: "success",
                message: t("招待リンクの生成に成功しました。リンクをコピーして共有してください。"),
              });
            })
            .catch(error => {
              TalentMemberSettings.TalentInvitationAction.setStatus("PUBLISHED");
              Sentry.captureException(error);
              const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                error,
                t("招待リンクの生成に失敗しました。しばらくしてから再度お試しください。"),
              );
              Snackbar.notify({
                severity: "error",
                message: errorNotification.message,
              });
            });
        },
        helpLink: {
          onClick: () => {
            helpCenterNavigate("INVITE_TALENTS", {
              _blank: true,
            });
          },
          href: generateHelpCenterUrl(lang, "INVITE_TALENTS"),
        },
        shareLink: {
          createLinkbutton: {
            loading: invitationState.status === "PENDING",
          },
          copyLinkButton: {
            onCopy: () => {
              Snackbar.notify({
                severity: "success",
                message: t("クリップボードにコピーされました"),
              });
            },
          },
          invitationLink: invitationState.id
            ? generateCurrentOriginUrl("/t/invitations/:id", {
                pathParams: {
                  id: invitationState.id,
                },
              })
            : undefined,
        },
      },
    },
    deleteMembers: {
      dialog: {
        open: dialogStatus === "DELETE_TALENTS_OPEN",
        onClose: () => {
          TalentMemberSettings.updateDialogStatus("CLOSE");
        },
        noButton: {
          onClick: () => {
            TalentMemberSettings.updateDialogStatus("CLOSE");
          },
        },
        title: t("タレントを削除する"),
      },
      description: `${t("以下のタレントを削除します。")}${t(
        "削除されたタレントは会社アカウントにアクセスできなくなります。また削除されたタレントに紐づくタレントスコアはアーカイブされます。",
      )}`,
      onSubmit: async () => {
        await client
          .DeleteMultiCompanyTalentsForTalentMemberSettings({
            companyId,
            talentIds: selectedMembers.talents.map(talent => talent.id),
          })
          .then(() => {
            Snackbar.notify({
              severity: "success",
              message: t("タレントの削除に成功しました。"),
            });
            TalentMemberSettings.updateDialogStatus("CLOSE");
            TalentMemberSettings.clear();
            reload();
          })
          .catch(error => {
            Sentry.captureException(error);
            const errorNotification = ErrorHandlingHelper.generateErrorNotification(
              error,
              t("タレントの削除に失敗しました。しばらくしてから再度お試しください。"),
            );
            Snackbar.notify({
              severity: "error",
              message: errorNotification.message,
            });
          });
      },
      members: selectedMembers.talents.map(talent => {
        return {
          displayName: talent.displayName,
          src: talent.photoUrl,
          email: talent.email,
        };
      }),
    },
  };
};
