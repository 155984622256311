import { DeviceInfo } from "./definition";

// This function will return a list of all available audio and video input devices.
export const getDeviceInfo = async (): Promise<DeviceInfo> => {
  const devices = await navigator.mediaDevices.enumerateDevices();

  return {
    audioInputDevices: devices.filter(device => device.kind === "audioinput" && device.deviceId !== "" && device.label !== ""),
    videoInputDevices: devices.filter(device => device.kind === "videoinput" && device.deviceId !== "" && device.label !== ""),
    hasAudioInputDevices: devices.some(device => device.kind === "audioinput"),
    hasVideoInputDevices: devices.some(device => device.kind === "videoinput"),
  };
};
