import { state } from "./State";
import * as Types from "./types";

export const setCustomerV2 = (customer: Types.CustomerV2 | null): void => {
  state.customerV2 = customer;
};

export const initialize = () => {
  state.initialized = true;
};

export const clear = () => {
  state.customerV2 = null;
  state.initialized = false;
};
