import { SupportLanguage, SupportLanguageValue } from "@hireroo/i18n";
import { QuizPackageForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import * as React from "react";
import { useFieldArray, useForm, UseFormReturn } from "react-hook-form";

export type ContextValue = {
  method: UseFormReturn<QuizPackageForm.QuizPackageFormSchema>;
  addLanguage: (language: SupportLanguageValue) => void;
  prepareSubmit: () => void;
};

export const QuizPackageDetailContext = React.createContext<ContextValue>({} as ContextValue);

export const useQuizPackageDetailContext = () => React.useContext(QuizPackageDetailContext);

export type QuizPackageDetailProviderProps = {
  fields: QuizPackageForm.QuizPackageFormSchema;
};

export const QuizPackageDetailProvider: React.FC<React.PropsWithChildren<QuizPackageDetailProviderProps>> = props => {
  const { fields } = props;
  const validateSchema = QuizPackageForm.useQuizPackageForm();
  const method = useForm<QuizPackageForm.QuizPackageFormSchema>({
    defaultValues: props.fields,
    resolver: zodResolver(validateSchema),
  });
  const selectedLanguageController = useFieldArray({
    control: method.control,
    name: "selectedLanguages",
  });

  React.useEffect(() => {
    method.setValue("isPublic", fields.isPublic);
    method.setValue("timeLimit", fields.timeLimit);
    method.setValue("difficulty", fields.difficulty);
    method.setValue("needUpdateVersion", fields.needUpdateVersion);
    method.setValue("oldVersion", fields.oldVersion);
    method.setValue("newVersion", fields.newVersion);
  }, [fields, method]);

  const contextValue: ContextValue = {
    method,
    addLanguage: lang => {
      const selectedLanguages = method.getValues("selectedLanguages");
      if (selectedLanguages.find(language => language.value === lang)) {
        return;
      }
      selectedLanguageController.append({
        value: lang,
      });
    },
    prepareSubmit: () => {
      const unusedLanguages = new Set([SupportLanguage.EN, SupportLanguage.JA]);
      const selectedLanguages = new Set(method.getValues("selectedLanguages").map(({ value }) => value));
      selectedLanguages.forEach(lang => {
        unusedLanguages.delete(lang);
      });
      unusedLanguages.forEach(language => {
        method.setValue(`packageDetails.${language}`, undefined);
      });
    },
  };
  return <QuizPackageDetailContext.Provider value={contextValue} children={props.children} />;
};
