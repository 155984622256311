import { useTranslation } from "@hireroo/i18n";
import { ErrorMessage } from "@hookform/error-message";
import Warning from "@mui/icons-material/Warning";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { useDatabaseTableSignatureContext } from "../Context";

const useTablesErrorMessages = () => {
  const { methods } = useDatabaseTableSignatureContext();
  const { errors } = methods.formState;

  const tableErrorMessages: string[] = [];
  if (errors.tables) {
    const maxLength = methods.getValues("tables").length;
    for (let i = 0; i < maxLength; i++) {
      const table = methods.getValues(`tables.${i}`);
      const tableError = errors.tables?.[i];
      if (tableError && table.name) {
        tableErrorMessages.push(table.name);
      }
    }
  }
  return tableErrorMessages.join(" ");
};

type FieldName = "tables" | "columns";
export type TableSignatureErrorMessageProps = {
  name: FieldName;
};

const TableSignatureErrorMessage: React.FC<TableSignatureErrorMessageProps> = props => {
  const { t } = useTranslation();
  const { methods } = useDatabaseTableSignatureContext();
  const { errors } = methods.formState;

  const tableErrorMessage = useTablesErrorMessages();

  const errorMessageMap: Record<FieldName, React.ReactNode> = {
    tables: (
      <ErrorMessage
        errors={errors}
        name="tables"
        render={() => (
          <Box display="flex" flexDirection="column" mt={1}>
            <Box display="flex" gap={1}>
              <Warning color="error" fontSize="small" />
              <Typography color="error" variant="body2">
                {t("テーブルの入力に不備があります。")}
                {tableErrorMessage}
              </Typography>
            </Box>
          </Box>
        )}
      />
    ),
    columns: (
      <ErrorMessage
        errors={errors}
        name="columns"
        render={() => (
          <Box display="flex" flexDirection="column" mt={1}>
            <Box display="flex" gap={1}>
              <Warning color="error" fontSize="small" />
              <Typography color="error" variant="body2">
                {t("出力結果のカラム定義の入力に不備があります。")}
              </Typography>
            </Box>
          </Box>
        )}
      />
    ),
  };

  return <>{errorMessageMap[props.name]}</>;
};

TableSignatureErrorMessage.displayName = "TableSignatureErrorMessage";

export default TableSignatureErrorMessage;
