import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateScreeningTestDetailForCandidatePropsArgs, useGenerateProps } from "./useGenerateProps";

export type ScreeningTestDetailForCandidateContainerProps = GenerateScreeningTestDetailForCandidatePropsArgs;

const ScreeningTestDetailForCandidateContainer: React.FC<ScreeningTestDetailForCandidateContainerProps> = props => {
  const screeningTestDetailForCandidateProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ScreeningTestDetailForCandidate {...screeningTestDetailForCandidateProps} />
    </ErrorBoundary>
  );
};

ScreeningTestDetailForCandidateContainer.displayName = "ScreeningTestDetailForCandidateContainer";

export default withErrorBoundary(ScreeningTestDetailForCandidateContainer, {});
