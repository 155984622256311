import * as z from "zod";

const useTags = () => {
  return z
    .object({
      displayName: z.string(),
    })
    .array();
};

const useStatus = () => {
  return z.array(z.union([z.literal("CREATED"), z.literal("STARTED"), z.literal("COMPLETED"), z.literal("EVALUATED")]));
};
export type Statuses = z.infer<ReturnType<typeof useStatus>>;

const useCreatedBy = () => {
  return z.string().array();
};

const useResult = () => {
  return z.union([z.literal("PASSED"), z.literal("NOT_PASSED"), z.null()]);
};
export type Result = z.infer<ReturnType<typeof useResult>>;

export const useRemoteInterviewSearchQuery = () => {
  const tags = useTags();
  const statuses = useStatus();
  const createdBy = useCreatedBy();
  const result = useResult();
  return z.object({
    tags,
    statuses,
    createdBy,
    result,
    textFilter: z.string(),
  });
};

export type RemoteInterviewSearchQuerySchema = z.infer<ReturnType<typeof useRemoteInterviewSearchQuery>>;

export const useSortFieldValue = () => {
  return z.union([z.literal("created-at-descending"), z.literal("created-at-ascending")]);
};

export type SortFieldValue = z.infer<ReturnType<typeof useSortFieldValue>>;
