import { ChallengePlayback } from "@hireroo/app-store/widget/shared/ChallengePlayback";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

export type GenerateTimeReportPanelPropsArgs = {};

export const useGenerateProps = (_args: GenerateTimeReportPanelPropsArgs): Widget.TimeReportPanelProps => {
  const { t } = useTranslation();
  const playbackManager = ChallengePlayback.usePlaybackManager();
  const eventTimelineMap = ChallengePlayback.useEventTimelineMap();
  const leavingPageIntervals = ChallengePlayback.useLeavingPageIntervals();

  const items = React.useMemo((): Widget.TimeReportPanelProps["graph"]["items"] => {
    const calculateTotalTimeMilliseconds = (intervals: ChallengePlayback.EventInterval[]): number => {
      return intervals.reduce<number>((totalMilliseconds, interval) => {
        return totalMilliseconds + (interval.endTs - interval.startTs);
      }, 0);
    };
    const endTime = playbackManager.timeStamps.at(-1) ?? 0;
    const startTime = playbackManager.timeStamps.at(0) ?? 0;
    const totalTimeMilliseconds = endTime - startTime;
    const chatGPTTimeMilliseconds = calculateTotalTimeMilliseconds(eventTimelineMap.CHATGPT_REQUEST?.intervals || []);
    const webSearchTimeMilliseconds = calculateTotalTimeMilliseconds(eventTimelineMap.WEB_SITE_SEARCH?.intervals || []);
    const webSiteAccessTimeMilliseconds = calculateTotalTimeMilliseconds(eventTimelineMap.EXTERNAL_WEB_SITE_ACCESS?.intervals || []);
    const leavePageTimeMilliseconds = calculateTotalTimeMilliseconds(leavingPageIntervals);
    const webSearchRatio = Math.floor(((webSearchTimeMilliseconds + webSiteAccessTimeMilliseconds) / totalTimeMilliseconds) * 100);
    const chatGPTRatio = Math.floor((chatGPTTimeMilliseconds / totalTimeMilliseconds) * 100);
    const leavingPageRatio = Math.floor((leavePageTimeMilliseconds / totalTimeMilliseconds) * 100);
    return [
      /** Adjustment so that the total is always 100 */
      { name: t("コーディング"), value: 100 - webSearchRatio - chatGPTRatio - leavingPageRatio },
      { name: t("Google検索"), value: webSearchRatio },
      { name: "ChatGPT", value: chatGPTRatio },
      { name: t("ページ離脱時間"), value: leavingPageRatio },
    ]
      .filter(item => item.value !== 0 && !Number.isNaN(item.value))
      .sort((a, b) => b.value - a.value);
  }, [t, playbackManager, eventTimelineMap, leavingPageIntervals]);

  return {
    graph: {
      items,
    },
  };
};
