import { useTheme } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";

import { CommentElement } from "../../../../features";
import { useSystemDesignContext } from "../../../../store";
import { Comment } from "../../Comment/Comment";

export type DynamicCommentProps = {
  comment: CommentElement;
  isSelected: boolean;
  isMoving: boolean;
  isEditing: boolean;
  onSelect: (e: React.MouseEvent<SVGElement, MouseEvent>, commentId: string) => void;
  isStatic?: boolean;
};

export const DynamicComment: React.FC<DynamicCommentProps> = React.memo((props: DynamicCommentProps) => {
  const theme = useTheme();
  const {
    content,
    geometry: { minX, minY, maxX, maxY },
    settings: { fontSize },
  } = props.comment;
  const ref = useRef<SVGTextElement>(null);
  const [areaReady, setAreaReady] = useState<boolean>(false);
  const Store = useSystemDesignContext();

  // Calculate size of the selection area from the text length and update comment element
  useEffect(() => {
    if (ref.current) {
      const bBox = ref.current.getBBox();
      const width = bBox.width + (20 - (bBox.width % 20));
      const height = bBox.height + (20 - (bBox.height % 20));

      Store.action.setCommentArea({
        id: props.comment.id,
        maxX: minX + width,
        maxY: minY + height,
      });

      setAreaReady(true);
    }
    // eslint-disable-next-line
  }, [props.comment.content, props.comment.settings.fontSize]);

  if (props.isEditing) return <></>;

  return (
    <g cursor={props.isMoving ? "default" : "move"} onMouseDown={e => props.onSelect(e, props.comment.id)}>
      <Comment content={content} minX={minX} minY={minY} fontSize={fontSize} ref={ref} />
      {props.isSelected && areaReady && (
        <rect
          x={minX}
          y={minY}
          width={maxX - minX}
          height={maxY - minY}
          stroke={theme.palette.common.black}
          strokeWidth={1}
          strokeDasharray="8 8"
          fill={"none"}
        />
      )}
    </g>
  );
});

DynamicComment.displayName = "DynamicComment";

export default DynamicComment;
