import { useTranslation } from "@hireroo/i18n";
import type { Fields } from "@hireroo/validator";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import Autocomplete from "@mui/material/Autocomplete";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Popper from "@mui/material/Popper";
import { styled } from "@mui/material/styles";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import * as React from "react";
import { useController, useFieldArray } from "react-hook-form";

import { BaseAssignFieldProps, ItemProps, Type } from "../../types";

type Field = {
  /** key is dummy for type safe */
  assignees: (Fields.AssignField.AssignListItemSchema & { id?: number })[];
};

const StyledPopper = styled(Popper)(({ theme }) => ({
  "& .MuiAutocomplete-groupLabel": {
    backgroundColor: theme.palette["Background/Paper"].p4,
  },
}));

const SecondaryChip = styled(Chip)(({ theme }) => ({
  height: 30,
  ".MuiChip-deleteIcon": {
    color: theme.palette.secondary.main,
  },
  color: theme.palette.secondary.main,
  borderColor: theme.palette.secondary.main,
}));

const OutlinedChip = styled(Chip)(({ theme }) => ({
  height: 30,
  ".MuiChip-deleteIcon": {
    color: theme.palette.action.disabled,
  },
  color: theme.palette.text.primary,
  borderColor: theme.palette.action.disabled,
}));

const OutlinedTextField = styled(TextField)(() => ({
  border: "none",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
}));

const GroupAvatarComponent = (
  <Avatar>
    <GroupIcon />
  </Avatar>
);

const PersonAvatarComponent = (
  <Avatar>
    <PersonIcon />
  </Avatar>
);

export type MultiAssignFieldProps = BaseAssignFieldProps;

const MultiAssignField: React.FC<MultiAssignFieldProps> = props => {
  const { t } = useTranslation();
  const { suggestions, displaySources, variant } = props;
  const typeHackFieldName = props.name as "assignees";
  const StyledChip = variant === "OUTLINED" ? OutlinedChip : SecondaryChip;

  const { fieldState } = useController<Field>({
    name: typeHackFieldName,
  });
  const { fields, append, remove } = useFieldArray<Field>({
    /**
     * Name of the field array. Note: Do not support dynamic name.
     * @see https://react-hook-form.com/api/usefieldarray
     */
    name: typeHackFieldName,
  });
  const fieldError = fieldState.error;
  const lockedEmployeeIds = React.useMemo(() => {
    return fields.reduce<string[]>((all, field) => {
      if (field.value?.type === "EMPLOYEE" && field.value.locked === true) {
        return all.concat(field.value.employeeId);
      }
      return all;
    }, []);
  }, [fields]);
  const groupNameMap: Record<Type, string> = {
    EMPLOYEE_GROUP: t("グループ"),
    EMPLOYEE: t("メンバー"),
    TALENT: t("タレント"),
  };

  const getAvatarComponent = React.useCallback(
    (item: ItemProps) => {
      const displaySource = displaySources[item.valueId];
      if (item.type === "EMPLOYEE_GROUP") {
        return GroupAvatarComponent;
      }
      if (displaySource?.photoUrl) {
        return <Avatar src={displaySource?.photoUrl} />;
      }
      return PersonAvatarComponent;
    },
    [displaySources],
  );

  const value = React.useMemo((): ItemProps[] => {
    return fields.reduce<ItemProps[]>((all, field) => {
      if (field.value === null) {
        return all;
      }
      switch (field.value.type) {
        case "EMPLOYEE": {
          return all.concat({
            id: field.id,
            valueId: field.value.employeeId,
            type: "EMPLOYEE",
            locked: !!field.value.locked,
          });
        }
        case "TALENT": {
          return all.concat({
            id: field.id,
            valueId: field.value.talentId,
            type: "TALENT",
            locked: !!field.value.locked,
          });
        }
        case "EMPLOYEE_GROUP": {
          return all.concat({
            id: field.id,
            valueId: field.value.groupId,
            type: "EMPLOYEE_GROUP",
            locked: !!field.value.locked,
          });
        }
        default:
          throw new Error(`Invalid Value: ${field.value satisfies never}`);
      }
    }, []);
  }, [fields]);

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      fullWidth
      value={value}
      isOptionEqualToValue={(option, value) => {
        return option.valueId === value.valueId;
      }}
      PopperComponent={StyledPopper}
      groupBy={option => {
        return groupNameMap[option.type];
      }}
      renderOption={(props, option) => {
        const displaySource = displaySources[option.valueId];
        const AvatarComponent = getAvatarComponent(option);
        return (
          <ListItem {...props} key={option.valueId}>
            <ListItemAvatar>{AvatarComponent}</ListItemAvatar>
            {displaySource?.optionText ?? ""}
          </ListItem>
        );
      }}
      onChange={(_, items, reason) => {
        if (reason === "selectOption") {
          items
            .filter(item => !item.id)
            .forEach(item => {
              switch (item.type) {
                case "EMPLOYEE": {
                  append({
                    value: {
                      type: "EMPLOYEE",
                      employeeId: item.valueId,
                    },
                  });
                  break;
                }
                case "TALENT": {
                  append({
                    value: {
                      type: "TALENT",
                      talentId: item.valueId,
                    },
                  });
                  break;
                }
                case "EMPLOYEE_GROUP": {
                  append({
                    value: {
                      type: "EMPLOYEE_GROUP",
                      groupId: item.valueId,
                    },
                  });
                  break;
                }
                default:
                  item.type satisfies never;
              }
            });
        } else if (reason === "clear") {
          const removeIds = fields.reduce<number[]>((all, field, index) => {
            if (field.value?.type === "EMPLOYEE" && field.value.locked === true) {
              return all;
            }
            return all.concat(index);
          }, []);
          remove(removeIds);
        }
      }}
      onFocus={props.onFocus}
      loading={props.loading}
      options={suggestions as ItemProps[]}
      getOptionLabel={option => {
        const displayName = displaySources[option.valueId];
        if (!displayName) {
          return "";
        }
        return displayName.optionText;
      }}
      filterSelectedOptions
      renderTags={(items, getTagProps) => {
        return items.map((item, index) => {
          const tagProps = getTagProps({ index });
          const locked = lockedEmployeeIds.includes(item.valueId);
          const displaySource = displaySources[item.valueId];
          const AvatarComponent = getAvatarComponent(item);
          return (
            <StyledChip
              {...tagProps}
              key={item.id ?? item.valueId}
              label={displaySource?.chipText}
              avatar={AvatarComponent}
              variant="outlined"
              onDelete={
                locked
                  ? undefined
                  : event => {
                      tagProps.onDelete(event);
                      remove(index);
                    }
              }
            />
          );
        });
      }}
      renderInput={params => {
        const textFieldProps: TextFieldProps = {
          ...params,
          color: "secondary",
          placeholder: props.placeholder,
          error: !!fieldError?.message,
          helperText: fieldError?.message,
        };
        if (variant === "OUTLINED") {
          return <OutlinedTextField {...textFieldProps} />;
        }
        return (
          <TextField
            {...params}
            color="secondary"
            placeholder={props.placeholder}
            error={!!fieldError?.message}
            helperText={fieldError?.message}
          />
        );
      }}
    />
  );
};

MultiAssignField.displayName = "MultiAssignField";

export default MultiAssignField;
