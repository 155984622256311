import { useTranslation } from "@hireroo/i18n";
import { ScreeningResourceEditorForm } from "@hireroo/validator";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import ResourceField from "../../../../../../modules/ResourceFieldV2/ResourceFieldV2";
import SwitchControl from "../../../../../../primitive/InputControl/SwitchControl/SwitchControl";

const ReservedFieldName = {
  ENABLED_CHAT_GPT: "enabledChatGPT",
  ENABLED_WEB_SEARCH: "enabledWebSearch",
} satisfies Record<string, keyof ScreeningResourceEditorForm.TestQuestionSetupFormSchema>;

type ReservedFieldName = (typeof ReservedFieldName)[keyof typeof ReservedFieldName];

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  marginLeft: 0,
  marginRight: 0,
  width: "100%",
  display: "flex",
  flexDirection: "row",
  ".MuiFormControl-root": {
    width: "fit-content",
    marginRight: "8px",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const StyledSwitchControl = styled(SwitchControl)(() => ({
  ".MuiSwitch-input": {
    left: 0,
  },
}));

export type BehavioralControlFieldProps = {};

const BehavioralControlField: React.FC<BehavioralControlFieldProps> = _props => {
  const { t } = useTranslation();
  const methods = useFormContext<ScreeningResourceEditorForm.TestQuestionSetupFormSchema>();
  const entityTracks = methods.watch("entityTracks");
  const hasChallengeEntitySource = entityTracks.some(entityTrack => {
    switch (entityTrack.type) {
      case "FIXED":
        return entityTrack.entitySource.type === "CHALLENGE";
      case "RANDOM_FROM_QUESTIONS":
      case "SELECTABLE_FROM_QUESTIONS":
        return entityTrack.entitySources.some(entitySource => entitySource.type === "CHALLENGE");
      default:
        throw new Error(`EntityTrack (${entityTrack satisfies never}) is not supported`);
    }
  });

  return (
    <ResourceField
      label={t("アシスト機能")}
      kind="NONE"
      pb={3}
      help={{
        kind: "DESCRIPTION",
        text: `${t("このテストに対して許可する受験者の行動を支援することができます。")}${t("コーディング形式のみ支援機能の指定が可能です。")}`,
      }}
    >
      <Stack direction="row" spacing={2}>
        <Box>
          <Tooltip title={!hasChallengeEntitySource ? t("コーディング形式の問題が含まれている場合に利用できます。") : ""}>
            <StyledFormControlLabel
              disabled={!hasChallengeEntitySource}
              control={
                <StyledSwitchControl
                  name={ReservedFieldName.ENABLED_WEB_SEARCH}
                  disabled={!hasChallengeEntitySource}
                  size="small"
                  color="secondary"
                />
              }
              label={
                <Typography variant="body2" noWrap width="100%">
                  {t("Google検索")}
                </Typography>
              }
            />
          </Tooltip>
        </Box>
        <Box>
          <Tooltip title={!hasChallengeEntitySource ? t("コーディング形式の問題が含まれている場合に利用できます。") : ""}>
            <StyledFormControlLabel
              disabled={!hasChallengeEntitySource}
              control={
                <StyledSwitchControl
                  name={ReservedFieldName.ENABLED_CHAT_GPT}
                  disabled={!hasChallengeEntitySource}
                  size="small"
                  color="secondary"
                />
              }
              label={
                <Typography variant="body2" noWrap width="100%">
                  ChatGPT
                </Typography>
              }
            />
          </Tooltip>
        </Box>
      </Stack>
    </ResourceField>
  );
};

BehavioralControlField.displayName = "BehavioralControlField";

export default BehavioralControlField;
