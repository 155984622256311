import { useTranslation } from "@hireroo/i18n";
import { AssessmentResourceEditorForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import ResourceField from "../../../../modules/ResourceFieldV2/ResourceFieldV2";
import TimeLimitSettingField, { TimeLimitSettingFieldProps } from "../../../../modules/TimeLimitSettingField/TimeLimitSettingField";
import InputControlTextField, {
  InputControlTextFieldProps,
} from "../../../../primitive/InputControl/InputControlTextField/InputControlTextField";
import { type LayoutController, useAssessmentResourceEditorV2Context } from "../../Context";

type FieldName = keyof AssessmentResourceEditorForm.TestQuestionSetupFormSchema;

const ReservedFieldName = {
  TITLE: "name",
  ENTITY_TRACKS: "entityTracks",
} satisfies Record<string, FieldName>;

type ReservedFieldName = (typeof ReservedFieldName)[keyof typeof ReservedFieldName];

const Subsection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  boxShadow: "none",
}));

type UniqueKey = string;
type EntityTrackExtractedData = {
  uniqueKeys: UniqueKey[];
};

export type TestQuestionSetupSectionProps = {
  mode: "CREATE" | "EDIT";
  timeLimitSettingField: TimeLimitSettingFieldProps;
  QuestionSelectField: React.ReactNode;
  onSubmit: SubmitHandler<AssessmentResourceEditorForm.TestQuestionSetupFormSchema>;
  defaultValues: AssessmentResourceEditorForm.TestQuestionSetupFormSchema;
  onChangeEntityTackExtractedDataList: (entityTrackExtractedDataList: EntityTrackExtractedData[]) => void;
  disableFields: Record<FieldName, boolean>;
};

const TestQuestionSetupSection: React.FC<TestQuestionSetupSectionProps> = props => {
  const { t } = useTranslation();
  const { onSubmit, onChangeEntityTackExtractedDataList } = props;
  const { stepName, setController, setTitle, submitValues, setSubmitValue } = useAssessmentResourceEditorV2Context();
  const validateSchema = AssessmentResourceEditorForm.useTestQuestionSetupForm();
  const methods = useForm<AssessmentResourceEditorForm.TestQuestionSetupFormSchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: submitValues["TEST_QUESTION_SETUP"] ?? props.defaultValues,
  });

  const controller = React.useMemo((): LayoutController => {
    return {
      checkGoNextStep: () => {
        return new Promise<boolean>(resolve => {
          methods.handleSubmit(
            fields => {
              onSubmit(fields);
              setSubmitValue("TEST_QUESTION_SETUP", fields);
              resolve(true);
            },
            errors => {
              setSubmitValue("TEST_QUESTION_SETUP", undefined);
              console.warn(errors);
              resolve(false);
            },
          )();
        });
      },
    };
  }, [methods, onSubmit, setSubmitValue]);

  const entityTracks = methods.watch("entityTracks");
  const selectedEntitySourceCount = entityTracks.length;
  React.useEffect(() => {
    const entityTrackExtractedDataList = entityTracks.reduce<EntityTrackExtractedData[]>((all, entityTrack) => {
      switch (entityTrack.type) {
        case "FIXED": {
          all.push({
            uniqueKeys: [entityTrack.entitySource.uniqueKey],
          });
          break;
        }
        case "RANDOM_FROM_QUESTIONS":
        case "SELECTABLE_FROM_QUESTIONS": {
          all.push({
            uniqueKeys: entityTrack.entitySources.map(entitySource => entitySource.uniqueKey),
          });
          break;
        }
      }
      return all;
    }, []);
    onChangeEntityTackExtractedDataList(entityTrackExtractedDataList);
  }, [onChangeEntityTackExtractedDataList, entityTracks]);

  React.useEffect(() => {
    setController("TEST_QUESTION_SETUP", controller);
  }, [setController, controller]);

  const name = methods.watch("name");
  React.useEffect(() => {
    setTitle(name);
  }, [name, setTitle]);

  const titleFieldProps: InputControlTextFieldProps = {
    fullWidth: true,
    placeholder: t("フロントエンジニア採用向け試験"),
  };
  if (stepName !== "TEST_QUESTION_SETUP") {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <Stack py={2} spacing={1.5}>
        <Subsection>
          <ResourceField label={t("タイトル")} kind="REQUIRED" pb={2}>
            <InputControlTextField name={ReservedFieldName.TITLE} {...titleFieldProps} />
          </ResourceField>
        </Subsection>

        <Subsection>
          <ResourceField label={t("出題問題")} kind="REQUIRED" pb={2}>
            {props.QuestionSelectField}
          </ResourceField>
        </Subsection>

        <Subsection>
          <ResourceField label={t("制限時間")} kind="NONE" pb={3}>
            {selectedEntitySourceCount > 0 && <TimeLimitSettingField {...props.timeLimitSettingField} version="V2" />}
            {selectedEntitySourceCount === 0 && (
              <Typography color="text.secondary" textAlign="center" variant="body2">
                {t("まずは問題を選択してください。")}
              </Typography>
            )}
          </ResourceField>
        </Subsection>
      </Stack>
    </FormProvider>
  );
};

TestQuestionSetupSection.displayName = "TestQuestionSetupSection";

export default TestQuestionSetupSection;
