import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import * as React from "react";

import Link from "../../../primitive/Link/Link";
import FrontendPerformanceItem, { FrontendPerformanceItemProps } from "./parts/FrontendPerformanceItem";

export type ReportProjectFrontendPerformanceDetailSectionProps = {
  fcp: Omit<FrontendPerformanceItemProps, "title">;
  si: Omit<FrontendPerformanceItemProps, "title">;
  lcp: Omit<FrontendPerformanceItemProps, "title">;
  tti: Omit<FrontendPerformanceItemProps, "title">;
  tbt: Omit<FrontendPerformanceItemProps, "title">;
  cls: Omit<FrontendPerformanceItemProps, "title">;
};

const ReportProjectFrontendPerformanceDetailSection: React.FC<ReportProjectFrontendPerformanceDetailSectionProps> = props => {
  const { t } = useTranslation();
  const leftSections: FrontendPerformanceItemProps[] = [
    {
      ...props.fcp,
      title: "First Contentful Paint",
    },
    {
      ...props.si,
      title: "Speed Index",
    },
    {
      ...props.lcp,
      title: "Largest Contentful Paint",
    },
  ];

  const rightSections: FrontendPerformanceItemProps[] = [
    {
      ...props.tti,
      title: "Time to Interactive",
    },
    {
      ...props.tbt,
      title: "Total Blocking Time",
    },
    {
      ...props.cls,
      title: "Cumulative Layout Shift",
    },
  ];
  return (
    <Box>
      <Grid container columnSpacing={1}>
        <Grid item xs={6}>
          <List component="nav" aria-label="answer-detail-1">
            {leftSections.map(section => (
              <FrontendPerformanceItem key={section.title} {...section} />
            ))}
          </List>
        </Grid>
        <Grid item xs={6}>
          <List component="nav" aria-label="answer-detail-1">
            {rightSections.map(section => (
              <FrontendPerformanceItem key={section.title} {...section} />
            ))}
          </List>
        </Grid>
      </Grid>

      <Box>
        <Typography variant="caption">
          {`※${t("これらの指標はlighthouseの指標をもとに算出しています。")}`}
          <Link href="https://web.dev/performance-scoring/" target="_blank" underline="hover" color="secondary">
            {t("詳細はこちら")}
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

ReportProjectFrontendPerformanceDetailSection.displayName = "ReportProjectFrontendPerformanceDetailSection";

export default ReportProjectFrontendPerformanceDetailSection;
