import { Widget } from "@hireroo/presentation/legacy";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateErrorPanelPropsArgs, useGenerateProps } from "./useGenerateProps";

export type ErrorPanelContainerProps = GenerateErrorPanelPropsArgs;

const ErrorPanelContainer: React.FC<ErrorPanelContainerProps> = props => {
  const errorPanelProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ErrorPanel {...errorPanelProps} />
    </ErrorBoundary>
  );
};

ErrorPanelContainer.displayName = "ErrorPanelContainer";

export default withErrorBoundary(ErrorPanelContainer, {});
