import * as React from "react";
import { Control, useController } from "react-hook-form";

type BaseProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, "name" | "type">;

export type HideValueFieldProps = BaseProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any>;
};

const HideValueField: React.FC<HideValueFieldProps & { name: string }> = props => {
  const { control: propsControl, ...defaultInputProps } = props;
  const { field } = useController({
    name: props.name,
    control: propsControl,
  });
  const inputProps: React.InputHTMLAttributes<HTMLInputElement> = {
    ...defaultInputProps,
    type: "hidden",
    value: field.value,
    onChange: event => {
      inputProps.onChange?.(event);
      return field.onChange(event);
    },
  };
  return <input {...inputProps} />;
};

HideValueField.displayName = "HideValueField";

export default HideValueField;
