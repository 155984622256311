import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledScoreTypography = styled(Typography)(() => ({
  fontFamily: "Roboto",
  fontWeight: 700,
}));

const Label = styled("span")(() => ({
  fontSize: 40,
}));

const PercentLabel = styled("span")(() => ({
  fontSize: 16,
}));

export type ScoreLabelProps = {
  /** 0 ~ 100 */
  score: number;
};

const ScoreLabel: React.FC<ScoreLabelProps> = props => {
  return (
    <StyledScoreTypography>
      <Label>{props.score}</Label>
      <PercentLabel>%</PercentLabel>
    </StyledScoreTypography>
  );
};

ScoreLabel.displayName = "ScoreLabel";

export default ScoreLabel;
