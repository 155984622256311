import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateTotalScoreRankVisualizerPropsArgs, useGenerateProps } from "./useGenerateProps";

export type TotalScoreRankVisualizerContainerProps = GenerateTotalScoreRankVisualizerPropsArgs;

const TotalScoreRankVisualizerContainer: React.FC<TotalScoreRankVisualizerContainerProps> = props => {
  const rankVisualizerProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.RankVisualizer {...rankVisualizerProps} />
    </ErrorBoundary>
  );
};

TotalScoreRankVisualizerContainer.displayName = "TotalScoreRankVisualizerContainer";

export default withErrorBoundary(TotalScoreRankVisualizerContainer, {});
