import { useTranslation } from "@hireroo/i18n";

import { OnboardingGuideProps } from "../../modules/OnboardingGuide/OnboardingGuide";

export const useGenerateProjectV2OnboardingGuideProps = (): OnboardingGuideProps => {
  const { t } = useTranslation();
  return {
    steps: [
      {
        title: t("チュートリアルへようこそ！"),
        content: t("こちらでは実践形式の画面操作を説明します。このチュートリアルは何度でも再開できますので、操作に迷った際にご確認ください。"),
        placement: "center",
        target: "body",
      },
      {
        title: t("ファイル・ディレクトリ"),
        content: t(
          "ファイルやディレクトリを選択し、アイコンからファイルやディレクトリを作成・削除できます。アイコンのボタンで右からファイル作成、ディレクトリ作成、選択したファイル削除ができます。",
        ),
        placement: "right",
        styles: {
          options: {
            width: 500,
          },
        },
        target: "#project-file-nav-tree",
      },
      {
        title: t("ペーンの調整"),
        content: t("こちらをクリックし、動かすことでペーンの幅を調整することができます。コンソールも同様に幅の調整が可能です。"),
        placement: "right",
        styles: {
          options: {
            width: 300,
          },
        },
        target: ".tutorial-pane",
      },
      {
        title: t("ファイル編集"),
        content: t("ファイルを選択してファイルを開き、コードを編集することが可能です。ファイルを複数開くことも可能です。"),
        placement: "left",
        styles: {
          options: {
            width: 300,
          },
        },
        target: "#project-editor-pane",
      },
      {
        title: t("ターミナル操作"),
        content: t(
          "下のコンソールからターミナルでのcdなどLinuxコマンドが可能です。また、アイコンボタンを押すことで新たにターミナルタブを追加できます",
        ),
        placement: "top",
        styles: {
          options: {
            width: 300,
          },
        },
        target: ".terminal-panel",
      },
      {
        title: t("コードの実行"),
        content: t("書いたコードで実際のテストケースを走らせます。期待値通りになるように調整してください。"),
        placement: "top",
        styles: {
          options: {
            width: 300,
          },
        },
        target: "#project-run-button",
      },
      {
        title: t("コードの提出"),
        content: t("問題に沿ったコードが書けたら、コードを提出して次に進みます。"),
        placement: "top",
        styles: {
          options: {
            width: 300,
          },
        },
        target: "#project-submit-button",
      },
      {
        title: t("制限時間"),
        content: t("問題には制限時間があります。制限時間を過ぎるとそれ以降の問題は解答することはできませんのでご注意ください。"),
        placement: "bottom",
        styles: {
          options: {
            width: 300,
          },
        },
        target: "#timelimit-box",
      },
      {
        title: t("ヘルプセンター"),
        content: t(
          "こちらのボタンを押すとヘルプセンターが開かれます。分からないことがありましたらこちらにアクセスし、記事を参照するか、右下のチャットからお問い合わせください。",
        ),
        placement: "left",
        styles: {
          options: {
            width: 300,
          },
        },
        target: "#help-button",
      },
      {
        title: t("チュートリアル終了"),
        content: t("これでチュートリアルは終了です。再度確認したい場合は、もう一度ボタンを押しチュートリアルを開始してください。"),
        placement: "center",
        target: "body",
      },
    ],
  };
};
