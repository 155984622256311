import type { Pages } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";

import EmployeeNavigationContainer from "../../../../widget/e/EmployeeNavigation/Container";
import FooterContainer from "../../../../widget/shared/Footer/Container";
import SnackbarContainer from "../../../../widget/shared/Snackbar/Container";

export type GenerateWaitingRoomPropsArgs = {
  remoteId: string;
};

export const useGenerateWaitingRoomProps = (args: GenerateWaitingRoomPropsArgs): Pages.WaitingRoomProps => {
  const navigate = useTransitionNavigate();
  return {
    content: {
      type: "FOR_EMPLOYEE",
      props: {
        detailPageLink: {
          href: generatePath("/e/remotes/:id/detail", {
            pathParams: {
              id: args.remoteId,
            },
          }),
          onClick: () => {
            navigate("/e/remotes/:id/detail", { pathParams: { id: args.remoteId } });
          },
        },
      },
    },
    layout: {
      NotificationBanner: null,
      Header: <EmployeeNavigationContainer />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      Tutorial: <></>,
    },
  };
};
