import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateTestListTablePropsArgs, useGenerateProps } from "./useGenerateProps";

export type ScreeningTestListContainerProps = GenerateTestListTablePropsArgs;

const ScreeningTestListContainer: React.FC<ScreeningTestListContainerProps> = props => {
  const testListTableProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ScreeningTestList {...testListTableProps} />
    </ErrorBoundary>
  );
};

ScreeningTestListContainer.displayName = "ScreeningTestListContainer";

export default withErrorBoundary(ScreeningTestListContainer, {});
