import { state } from "./State";
import type * as Types from "./types";

export const clear = () => {
  state.endInterviewStatus = null;
  state.spot = null;
};

export const setSpot = (spot: Types.Spot): void => {
  state.spot = spot;
};

export const updateEndInterviewStatus = (status: Types.EndInterviewStatus) => {
  if (state.endInterviewStatus === null) {
    state.endInterviewStatus = status;
  }
};

export const updateEndInterviewLoadingStatus = (status: Types.EndInterviewLoadingStatus) => {
  state.endInterviewLoadingStatus = status;
};
