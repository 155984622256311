import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Popover, { PopoverProps } from "@mui/material/Popover";
import * as React from "react";

import ButtonWithTooltip, { ButtonWithTooltipProps } from "../../../../primitive/Button/ButtonWithTooltip/ButtonWithTooltip";

export type BatchMenuProps = {
  generateButton: ButtonWithTooltipProps;
  BatchList: React.ReactNode;
  forceClose?: boolean;
};

const BatchMenu: React.FC<BatchMenuProps> = props => {
  const { t } = useTranslation();
  const [anchorElForBatchMenu, setAnchorElForBatchMenu] = React.useState<null | HTMLElement>(null);

  React.useEffect(() => {
    if (props.forceClose) {
      setAnchorElForBatchMenu(null);
    }
  }, [props.forceClose, setAnchorElForBatchMenu]);

  const sonarGenerateButton: ButtonWithTooltipProps = {
    ...props.generateButton,
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorElForBatchMenu(event.currentTarget);
      props.generateButton.onClick?.(event);
    },
    variant: "contained",
    color: "secondary",
    size: "small",
  };

  const handleClose = () => {
    setAnchorElForBatchMenu(null);
  };

  const popover: PopoverProps = {
    open: Boolean(anchorElForBatchMenu),
    anchorEl: anchorElForBatchMenu,
    onClose: handleClose,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "right",
    },
  };

  return (
    <Box>
      <ButtonWithTooltip {...sonarGenerateButton} startIcon="KEY">
        {t("トークンを生成する")}
      </ButtonWithTooltip>
      <Popover {...popover}>{props.BatchList}</Popover>
    </Box>
  );
};

BatchMenu.displayName = "BatchMenu";

export default BatchMenu;
