import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateTestAnnouncementPropsArgs, useGenerateProps } from "./useGenerateProps";

export type TestAnnouncementContainerProps = GenerateTestAnnouncementPropsArgs;

const TestAnnouncementContainer: React.FC<TestAnnouncementContainerProps> = props => {
  const testAnnouncementProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.TestAnnouncement {...testAnnouncementProps} />
    </ErrorBoundary>
  );
};

TestAnnouncementContainer.displayName = "TestAnnouncementContainer";

export default withErrorBoundary(TestAnnouncementContainer, {});
