import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import NativeSelect, { NativeSelectProps } from "@mui/material/NativeSelect";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { languageMapForDisplay } from "../../../../definition";

export const FONT_FAMILY_SET = [
  "-apple-system",
  "BlinkMacSystemFont",
  "Segoe UI",
  "Roboto",
  "Helvetica Neue",
  "Arial",
  "sans-serif",
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
];

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  borderRadius: 4,
  position: "relative",
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.paper,
  fontSize: "0.9rem",
  height: "2.4em",
  padding: "10px 0px 10px 12px",
  transition: theme.transitions.create(["border-color", "box-shadow"]),
  // Use the system font instead of the default Roboto font.
  fontFamily: FONT_FAMILY_SET.join(","),
  "&:focus": {
    borderRadius: 4,
    borderColor: "#80bdff",
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
  },
}));

export type RuntimeSelectorProps = {
  selectedLanguage: string;
  disabled?: boolean;
  enabledLanguages?: string[];
  onChange?: NativeSelectProps["onChange"];
};

const RuntimeSelector: React.FC<RuntimeSelectorProps> = props => {
  const { selectedLanguage } = props;
  const { t } = useTranslation();

  const handleSelectEvent = (event: React.ChangeEvent<HTMLSelectElement>) => {
    props.onChange?.(event);
  };

  if (selectedLanguage.length <= 0 || !props.enabledLanguages || props.enabledLanguages.length <= 0) {
    return (
      <Stack direction="column" alignItems="center" justifyContent="center">
        <Typography variant="body2">{t("利用可能な言語がありませんでした。")}</Typography>
        <Typography variant="caption">{t("ヘルプより運営にお問い合わせください。")}</Typography>
      </Stack>
    );
  }

  return (
    <Box minWidth={100} alignItems="center" display="flex" justifyContent="center" height={36}>
      <FormControl>
        <NativeSelect
          disabled={props.disabled}
          id="language-select-native"
          value={selectedLanguage}
          onChange={handleSelectEvent}
          input={<BootstrapInput />}
          sx={{
            bgcolor: "inherit",
          }}
        >
          {Array.from(props.enabledLanguages)
            .sort()
            .map(enabledLanguage => (
              <option key={enabledLanguage} value={enabledLanguage} data-testid={`programming-select-${enabledLanguage}`}>
                {languageMapForDisplay[enabledLanguage] ?? enabledLanguage}
              </option>
            ))}
        </NativeSelect>
      </FormControl>
    </Box>
  );
};

RuntimeSelector.displayName = "RuntimeSelector";

export default RuntimeSelector;
