import { Payment } from "@hireroo/app-store/essential/employee";
import { BuySelections } from "@hireroo/app-store/widget/e/BuySelections";
import { Widget } from "@hireroo/presentation";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateBuySelectionFormPropsArgs, useGenerateProps } from "./useGenerateProps";

export type BuySelectionFormContainerProps = GenerateBuySelectionFormPropsArgs;

const BuySelectionFormContainer: React.FC<BuySelectionFormContainerProps> = props => {
  const buySelectionFormProps = useGenerateProps(props);
  const navigate = useTransitionNavigate();
  const availableForPurchase = Payment.useAvailableForPurchase();
  /** Not available with FetchContainer because it can only be used after initialization.  */
  const customer = BuySelections.useCustomer();
  const [redirectCheckCompleted, setRedirectCheckCompleted] = React.useState(false);

  React.useEffect(() => {
    /**
     * - Users in the trial trunk do not need to use this page as there is no limit to the number of selections.
     * - Users whose payment information cannot be verified will not be able to purchase the number of selections.
     * - Cannot be purchased outside the contract period
     * - Only paid users can purchase
     */
    if (
      customer.isTrial ||
      !availableForPurchase ||
      !customer.subscription.plan.enabledPurchasePack ||
      customer.subscription.status === "INACTIVE" ||
      customer.paymentStatus !== "PAID"
    ) {
      navigate("/e/settings/billing/overview");
    } else {
      setRedirectCheckCompleted(true);
    }
  }, [
    navigate,
    customer.isTrial,
    availableForPurchase,
    customer.subscription.plan.enabledPurchasePack,
    customer.paymentType,
    customer.subscription.status,
    customer.paymentStatus,
  ]);

  if (!redirectCheckCompleted) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  return (
    <ErrorBoundary>
      <Widget.BuySelectionForm {...buySelectionFormProps} />
    </ErrorBoundary>
  );
};

BuySelectionFormContainer.displayName = "BuySelectionFormContainer";

export default withErrorBoundary(BuySelectionFormContainer, {});
