import { parseFlowChartSnapshot } from "@hireroo/system-design/helpers/flowChart";
import StaticFlowChart from "@hireroo/system-design/react/StaticFlowChart";
import { styled } from "@mui/material/styles";
import * as React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { monokaiSublime } from "react-syntax-highlighter/dist/cjs/styles/hljs";

/**
 * Specify the same font family as monaco-editor
 */
const FontFamily = `Menlo, Monaco, "Courier New", monospace`;

const CodeWrapper = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
}));

const StyleCode = styled("code")(() => ({
  fontFamily: FontFamily,
}));

const StylePre = styled("pre")(({ theme }) => ({
  borderRadius: theme.shape.borderRadius / 2,
  border: `1px solid ${theme.palette.Other.Divider}`,
  padding: theme.spacing(1),
  margin: 0,
}));

const InlineCode = styled("span")(({ theme }) => ({
  fontFamily: FontFamily,
  backgroundColor: theme.palette["Secondary/Shades"].p16,
  borderRadius: theme.shape.borderRadius / 2,
  padding: "0.1em 0.2em",
  margin: "0.1em",
  lineHeight: "1.8",
}));

const getLang = (lang: string): string => {
  if (lang === "html") return "htmlbars";
  return lang;
};

export type CodeProps = {
  children: React.ReactNode[];
  inline?: boolean;
  className?: string;
};

const Code: React.FC<CodeProps> = props => {
  // TODO: Please system design snapshot
  const { inline, className } = props;

  if (inline) {
    return <InlineCode>{props.children[0]}</InlineCode>;
  }

  // Get language from className. ex language-text -> text
  const match = /language-(\w+)/.exec(className || "");
  const value = props.children[0] as string;

  let lang = null;
  if (match !== null) {
    lang = getLang(match[1]);
  }

  if (lang === "draw") {
    const {
      result: { componentType, elements },
    } = parseFlowChartSnapshot(value);
    return (
      <React.Suspense>
        <StaticFlowChart elements={elements} componentType={componentType} disableZoom={true} />
      </React.Suspense>
    );
  }

  return (
    <CodeWrapper>
      <SyntaxHighlighter language={lang ?? "text"} PreTag={StylePre} CodeTag={StyleCode} style={monokaiSublime}>
        {value.replace(/\n$/i, "")}
      </SyntaxHighlighter>
    </CodeWrapper>
  );
};

Code.displayName = "Code";

export default Code;
