import { languageMapForHighlight, languageMapsForDisplay } from "@hireroo/challenge/definition";
import CodeEditor, { type CodeEditorProps } from "@hireroo/code-editor/react/CodeEditor";
import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";

import BasicTab, { BasicTabProps } from "../../primitive/Tab/BasicTab/BasicTab";

export type LanguageMap = {
  value: string;
  displayName: string;
};

export type AlgorithmTemplatePreviewProps = {
  variant: "ALGORITHM" | "DATABASE" | "CLASS";
  template: Record<string, string>;
};

const AlgorithmTemplatePreview: React.FC<AlgorithmTemplatePreviewProps> = props => {
  const { t } = useTranslation();
  const codeEditor: CodeEditorProps = {
    width: "100%",
    height: "100%",
    options: {
      readOnly: true,
    },
  };
  const languagesMap = languageMapsForDisplay[props.variant];

  const codeLanguageTabs: BasicTabProps = {
    tabs: {
      variant: "scrollable",
    },
    tabBox: {
      //overwrite paper theme
      sx: {},
    },
    items: languagesMap.map((languageMap, index) => {
      return {
        id: index.toString(),
        name: languageMap.displayName,
        Component: (
          <Box key={languageMap.value} mt={2} display="flex" justifyContent="center" height="460px">
            {props.template[languageMap.value] && (
              <React.Suspense>
                <CodeEditor {...codeEditor} language={languageMapForHighlight[languageMap.value]} value={props.template[languageMap.value]} />
              </React.Suspense>
            )}
          </Box>
        ),
      };
    }),
  };
  return (
    <Box>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
        {t("プレビュー")}
      </Typography>
      <Box mt={2} width="100%">
        <BasicTab {...codeLanguageTabs} />
      </Box>
    </Box>
  );
};

AlgorithmTemplatePreview.displayName = "AlgorithmTemplatePreview";

export default AlgorithmTemplatePreview;
