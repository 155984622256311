import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";

import InvitationLayout, { InvitationLayoutProps } from "../../layouts/InvitationLayout/InvitationLayout";
import InvitationDialog, { InvitationDialogProps } from "./parts/InvitationDialog/InvitationDialog";

const HEADER_HEIGHT = 48;
const ADJUST_HEIGHT = 1;

const Wrapper = styled(Stack)(({ theme }) => ({
  width: "100vw",
  height: `calc(100vh - ${HEADER_HEIGHT + ADJUST_HEIGHT}px)`,
  backgroundColor: theme.palette.background.default,
  justifyContent: "center",
}));

export type InvitationProps = {
  layout: InvitationLayoutProps;
  dialog: InvitationDialogProps;
};

const Invitation: React.FC<InvitationProps> = props => {
  const invitationDialogProps: InvitationDialogProps = {
    ...props.dialog,
  };
  return (
    <InvitationLayout {...props.layout}>
      <Wrapper direction="column">
        <InvitationDialog {...invitationDialogProps} />
      </Wrapper>
    </InvitationLayout>
  );
};

Invitation.displayName = "Invitation";

export default Invitation;
