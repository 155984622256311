import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type ChallengeQuestionDetailContainerProps = {};

const ChallengeQuestionDetailContainer: React.FC<ChallengeQuestionDetailContainerProps> = props => {
  const challengeQuestionDetailProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Pages.QuestionDetail {...challengeQuestionDetailProps} />
    </ErrorBoundary>
  );
};

ChallengeQuestionDetailContainer.displayName = "ChallengeQuestionDetailContainer";

export default withErrorBoundary(ChallengeQuestionDetailContainer, {});
