import { TechnicalCommentPopperForExam } from "@hireroo/app-store/widget/e/TechnicalCommentPopperForExam";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type TechnicalCommentPopperForExamContainerProps = {
  companyId: number;
  examId: string;
  onClose: () => void;
};

const TechnicalCommentPopperForExamContainer: React.FC<TechnicalCommentPopperForExamContainerProps> = props => {
  React.useEffect(() => {
    return () => {
      TechnicalCommentPopperForExam.clearFormValues();
    };
  }, []);
  const technicalCommentPopperProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.TechnicalCommentPopper {...technicalCommentPopperProps} />
    </ErrorBoundary>
  );
};

TechnicalCommentPopperForExamContainer.displayName = "TechnicalCommentPopperForExamContainer";

export default withErrorBoundary(TechnicalCommentPopperForExamContainer, {});
