import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateCompanySettingsNavigatorPropsArgs, useGenerateProps } from "./useGenerateProps";

export type CompanySettingsNavigatorContainerProps = GenerateCompanySettingsNavigatorPropsArgs;

const CompanySettingsNavigatorContainer: React.FC<CompanySettingsNavigatorContainerProps> = props => {
  const companySettingsNavigatorProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.UserOrCompanySettingsNavigator {...companySettingsNavigatorProps} />
    </ErrorBoundary>
  );
};

CompanySettingsNavigatorContainer.displayName = "CompanySettingsNavigatorContainer";

export default withErrorBoundary(CompanySettingsNavigatorContainer, {});
