import { TwilioVideoErrorCodes, useTwilioVideoRoomContext } from "@hireroo/app-helper/hooks";
import { RemotesId } from "@hireroo/app-store/page/c/remotes_id";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import * as Sentry from "@sentry/react";
import * as React from "react";

/**
 * Manage the connection process to the video chat room.
 * includes determining whether to create a new room or join an existing one, attempting connection and error handling.
 */
export const useStartVideoChatRoom = (props: { liveCodingId: number; displayName: string }) => {
  const { t } = useTranslation();
  const { connect } = useTwilioVideoRoomContext();
  const roomId = RemotesId.useLiveCodingVideoChatRoomId();

  const [connectStatus, setConnectStatus] = React.useState<"STOP" | "PENDING" | "START">("STOP");

  const [getLiveCodingResult] = Graphql.useGetLiveCodingForEmployeeQuery({
    variables: {
      liveCodingId: props.liveCodingId,
    },
    requestPolicy: "network-only",
    pause: connectStatus === "STOP",
  });
  const [addRoomResult, addRoom] = Graphql.useAddLiveCodingVideoChatRoomForEmployeeRemotesIdMutation();
  const [joinRoomResult, joinRoom] = Graphql.useJoinVideoChatRoomForEmployeeMutation();

  const latestRoomId = React.useMemo((): string => {
    const rooms = getLiveCodingResult.data?.liveCoding.videoChatRooms.flatMap(v => (v ? [v] : [])) ?? [];
    const sortedRooms = rooms.sort((a, b) => (b.createdAtSeconds ?? 0) - (a.createdAtSeconds ?? 0));
    if (sortedRooms.length > 0) {
      return sortedRooms[0].roomId;
    }
    return "";
  }, [getLiveCodingResult]);

  const [token, setToken] = React.useState<string>("");
  const updateRoomAndResetToken = React.useCallback(
    (liveCodingId: number) => {
      if (latestRoomId !== roomId) {
        // Update room id if it is different from the latest one
        RemotesId.updateLiveCodingVideoChatRoomId(latestRoomId);
        // Reset Token
        setToken("");
        return;
      }
      // Update room
      addRoom({
        input: {
          liveCodingId: liveCodingId,
        },
      })
        .then(res => {
          const newRoomId = res.data?.addLiveCodingVideoChatRoom.id ?? "";
          if (newRoomId) {
            RemotesId.updateLiveCodingVideoChatRoomId(newRoomId);
          }
        })
        .catch(err => {
          Sentry.captureException(err);
        })
        .finally(() => {
          // Reset Token
          setToken("");
        });
    },
    [addRoom, latestRoomId, roomId],
  );

  const joinRoomAndUpdateToken = React.useCallback(
    (roomId: string, displayName: string) => {
      // Join and Update token
      joinRoom({
        input: {
          roomId: roomId,
          displayName: displayName,
        },
      })
        .then(res => {
          setToken(res.data?.joinVideoChatRoom.token ?? "");
        })
        .catch(err => {
          Sentry.captureException(err);
          setToken("");
        });
    },
    [joinRoom],
  );

  React.useEffect(() => {
    // clean up
    if (connectStatus === "STOP") {
      RemotesId.updateLiveCodingVideoChatRoomId("");
      setToken("");
    }
  }, [connectStatus]);

  // connect to room while connecting status is not STOP or ERROR
  React.useEffect(() => {
    if (connectStatus !== "START") {
      return;
    }
    // Wait for fetching to finish
    if (addRoomResult.fetching || joinRoomResult.fetching || getLiveCodingResult.fetching) {
      return;
    }
    // If there is an error, stop connecting
    if (addRoomResult.error) {
      setConnectStatus("STOP");
      Snackbar.notify({
        severity: "error",
        message: t("ビデオ通話の接続失敗しました。"),
      });
      Sentry.captureException(addRoomResult.error);
      return;
    }
    if (getLiveCodingResult.error) {
      setConnectStatus("STOP");
      Snackbar.notify({
        severity: "error",
        message: t("ビデオ通話の接続失敗しました。"),
      });
      Sentry.captureException(getLiveCodingResult.error);
      return;
    }

    // If joinRoom is failed, retry to add room and join room
    if (joinRoomResult.error) {
      updateRoomAndResetToken(props.liveCodingId);
      joinRoomAndUpdateToken(roomId, props.displayName);
      return;
    }

    // If there is no room, set latest the room or add room
    if (!roomId) {
      if (latestRoomId) {
        RemotesId.updateLiveCodingVideoChatRoomId(latestRoomId);
        // Reset Token
        setToken("");
      } else {
        updateRoomAndResetToken(props.liveCodingId);
      }
      return;
    }

    if (!token) {
      joinRoomAndUpdateToken(roomId, props.displayName);
      return;
    }

    if (token) {
      setConnectStatus("PENDING");
      connect(token)
        .then(() => {
          setConnectStatus("STOP");
        })
        .catch(err => {
          switch (err.code) {
            case TwilioVideoErrorCodes.RoomNotFound:
              // add a new room and reconnect if the room is not found or expired
              updateRoomAndResetToken(props.liveCodingId);
              setConnectStatus("START");
              break;
            case TwilioVideoErrorCodes.ParticipantDuplicateIdentity:
              // Reconnect to room if participant duplicate identity
              joinRoomAndUpdateToken(roomId, props.displayName);
              setConnectStatus("START");
              break;
            default:
              setConnectStatus("STOP");
              Snackbar.notify({
                severity: "error",
                message: t("ビデオ通話の接続失敗しました。"),
              });
              Sentry.captureException(err);
          }
        });
    }
  }, [
    connectStatus,
    connect,
    joinRoomAndUpdateToken,
    joinRoomResult.error,
    joinRoomResult.fetching,
    updateRoomAndResetToken,
    addRoomResult.error,
    addRoomResult.fetching,
    roomId,
    token,
    props.liveCodingId,
    props.displayName,
    t,
    getLiveCodingResult.fetching,
    getLiveCodingResult.error,
    latestRoomId,
  ]);

  return {
    connectStatus,
    start: () => {
      setConnectStatus("START");
    },
  };
};
