import { useTranslation } from "@hireroo/i18n";
import * as React from "react";

import EntryOverviewSection, { EntryOverviewSectionProps } from "../../../modules/EntryOverviewSection/EntryOverviewSection";

export type EntryTestOverviewSectionProps = {
  companyName: string;
  submissionDeadline: string;
  questionNum: number;
  timeLimit: string;
  message?: string;
  SelectableQuestionField?: EntryOverviewSectionProps["SelectableQuestionField"];
};

const EntryTestOverviewSection: React.FC<EntryTestOverviewSectionProps> = props => {
  const { t } = useTranslation();
  const testEntryOverviewSection = React.useMemo((): EntryOverviewSectionProps => {
    return {
      title: t("テスト概要"),
      mode: "BORDER",
      emphasizedItems: [
        {
          title: t("提出期限"),
          content: `${props.submissionDeadline}`,
          underline: true,
          strong: true,
        },
        {
          title: t("問題数"),
          content: `${props.questionNum} ${t("問")}`,
          strong: true,
        },
        {
          title: t("制限時間"),
          content: props.timeLimit,
          strong: true,
        },
      ].reduce<Exclude<EntryOverviewSectionProps["emphasizedItems"], undefined>>((items, current) => {
        if (current) {
          items.push(current);
        }
        return items;
      }, []),
      items: [
        {
          title: t("企業名"),
          content: props.companyName,
        },
        !!props.message && {
          title: t("企業からのメッセージ"),
          content: props.message,
        },
      ].reduce<EntryOverviewSectionProps["items"]>((items, current) => {
        if (current) {
          items.push(current);
        }
        return items;
      }, []),
      SelectableQuestionField: props.SelectableQuestionField,
    };
  }, [t, props]);

  return <EntryOverviewSection {...testEntryOverviewSection} />;
};

EntryTestOverviewSection.displayName = "EntryTestOverviewSection";

export default EntryTestOverviewSection;
