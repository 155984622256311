import appRoutes from "@hireroo/router/config";

/**
 * Extract Pattern: `/t/*`
 */
export type RoutePath = Extract<keyof typeof appRoutes, `/t/${string}`>;

type RequirePlanMessage = {
  featureName: string;
  isAvailable: boolean;
};

type RoutePathPlan = { [key in RoutePath]?: RequirePlanMessage };

export const useValidateCurrentPlan = (pattern?: RoutePath): RequirePlanMessage | "AVAILABLE" => {
  const routePathPlan: RoutePathPlan = {};
  if (!pattern) {
    return "AVAILABLE";
  }
  const requirePattern = routePathPlan[pattern];
  if (!requirePattern || requirePattern?.isAvailable) {
    return "AVAILABLE";
  }
  return requirePattern;
};
