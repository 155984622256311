import { generateKey } from "@hireroo/app-helper/parser";
import { proxyMap } from "valtio/utils";

import { state } from "./State";
import type * as Types from "./types";

export const initialize = (entity: Types.SystemDesignEntity): void => {
  const bestSubmission = entity.submissions.find(submission => submission.isBest);
  state.systemDesigns.set(entity.systemDesignEntityId, {
    queryKey: "",
    statisticsMap: proxyMap(),
    entity,
    currentSelectedSubmissionId: bestSubmission?.systemDesignSubmissionId ?? 0,
  });
};

export const setQuery = (entityId: Types.SystemDesignEntityId, query: Types.Query) => {
  const systemDesignState = state.systemDesigns.get(entityId);
  if (!systemDesignState) {
    return;
  }
  systemDesignState.queryKey = generateKey(query);
};

export const setStatistics = (entityId: Types.SystemDesignEntityId, query: Types.Query, result: Types.Statistics | null) => {
  const systemDesignState = state.systemDesigns.get(entityId);
  if (!systemDesignState || !result) {
    return;
  }
  systemDesignState.statisticsMap.set(generateKey(query), result);
  setQuery(entityId, query);
};

export const reevaluate = (entityId: Types.SystemDesignEntityId, newSystemDesign: Types.SystemDesignEntity) => {
  const entityState = state.systemDesigns.get(entityId);
  if (entityState) {
    state.systemDesigns.set(entityId, {
      ...entityState,
      entity: newSystemDesign,
    });
  }
};

export const setCurrentSelectedSubmissionId = (
  entityId: Types.SystemDesignEntityId,
  currentSelectedSubmissionId: Types.Submission["systemDesignSubmissionId"],
) => {
  const entityState = state.systemDesigns.get(entityId);
  if (entityState) {
    entityState.currentSelectedSubmissionId = currentSelectedSubmissionId;
  }
};

export const setSubmission = (entityId: number, newSubmission: Types.Submission) => {
  const entityState = state.systemDesigns.get(entityId);
  if (entityState) {
    const index = entityState.entity.submissions.findIndex(
      submission => submission.systemDesignSubmissionId === newSubmission.systemDesignSubmissionId,
    );
    if (index > 0) {
      entityState.entity.submissions[index] = newSubmission;
    }
  }
};
