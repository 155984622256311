import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type EvaluationsMetricGroupsContainerProps = {};

const EvaluationsMetricsContainer: React.FC<EvaluationsMetricGroupsContainerProps> = () => {
  const evaluationMetricGroupsProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Pages.EvaluationMetrics {...evaluationMetricGroupsProps} />
    </ErrorBoundary>
  );
};

EvaluationsMetricsContainer.displayName = "EvaluationsMetricsContainer";

export default withErrorBoundary(EvaluationsMetricsContainer, {});
