import { AlgorithmSignatureForm, AlgorithmTestCaseForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import * as React from "react";
import { useForm, UseFormReturn } from "react-hook-form";

type AlgorithmSignatureProps = {
  variant: "ALGORITHM";
  signature: AlgorithmSignatureForm.AlgorithmSignatureSchemaType;
};
type DatabaseSignatureProps = {
  variant: "DATABASE";
  signature: AlgorithmSignatureForm.DatabaseSignatureSchemaType;
};

type ClassSignatureProps = {
  variant: "CLASS";
  signature: AlgorithmSignatureForm.ClassSignatureSchemaType;
};
type SignatureProps = AlgorithmSignatureProps | ClassSignatureProps | DatabaseSignatureProps;

export type AlgorithmTestCasesFormContextProps = {
  signatureProps: SignatureProps;
};

export type ContextValue = {
  method: UseFormReturn<AlgorithmTestCaseForm.AlgorithmTestCaseFormSchema>;
  contextProps: AlgorithmTestCasesFormContextProps;
};

export const AlgorithmTestCasesFormContext = React.createContext<ContextValue>({} as ContextValue);

export const useAlgorithmTestCasesFormContext = () => React.useContext(AlgorithmTestCasesFormContext);

export type AlgorithmTestCasesFormProviderProps = {
  defaultValues: AlgorithmTestCaseForm.AlgorithmTestCaseFormSchema;
  contextProps: AlgorithmTestCasesFormContextProps;
};

export const AlgorithmTestCasesFormProvider: React.FC<React.PropsWithChildren<AlgorithmTestCasesFormProviderProps>> = props => {
  const { defaultValues } = props;
  const schema = AlgorithmTestCaseForm.useTestCaseFormSchema();
  const method = useForm<AlgorithmTestCaseForm.AlgorithmTestCaseFormSchema>({
    resolver: zodResolver(schema),
    mode: "onSubmit",
    shouldUnregister: false,
    defaultValues: props.defaultValues,
  });

  React.useEffect(() => {
    method.setValue("correctnessTestCases", defaultValues.correctnessTestCases);
    method.setValue("performanceTestCases", defaultValues.performanceTestCases);
    method.setValue("selectedLanguages", defaultValues.selectedLanguages);
  }, [method, defaultValues]);

  const contextValue: ContextValue = {
    method,
    contextProps: props.contextProps,
  };
  return <AlgorithmTestCasesFormContext.Provider value={contextValue} children={props.children} />;
};
