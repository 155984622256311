import { useTranslation } from "@hireroo/i18n";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const WrapperStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3),
}));

export type HomeProps = {
  Onboarding: React.ReactNode;
  ScreeningStats: React.ReactNode;
  ScreeningSection: React.ReactNode;
  InterviewSection: React.ReactNode;
};

const Home: React.FC<HomeProps> = props => {
  const { t } = useTranslation();
  return (
    <WrapperStack spacing={2}>
      <Typography variant="h5" fontSize={24} py={2}>
        {t("ホーム")}
      </Typography>
      {props.Onboarding}
      {props.ScreeningStats}
      {props.ScreeningSection}
      {props.InterviewSection}
    </WrapperStack>
  );
};

Home.displayName = "Home";

export default Home;
