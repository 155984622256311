/**
 * TODO Move this to @hireroo/project
 */
import * as z from "zod";

export const generateDataScienceCorrectnessTestCase = () => {
  return z.object({
    description_ja: z.string(),
    description_en: z.string(),
    input: z.string(),
    output: z.string(),
    is_hidden: z.boolean(),
  });
};

export type DataScienceCorrectnessTestCaseSchema = z.infer<ReturnType<typeof generateDataScienceCorrectnessTestCase>>;

export const generateDataScienceCorrectnessTestCases = () => {
  const testCase = generateDataScienceCorrectnessTestCase();
  return z.object({
    version: z.string(),
    test_cases: testCase.array(),
  });
};

export type DataScienceCorrectnessTestCasesSchema = z.infer<ReturnType<typeof generateDataScienceCorrectnessTestCases>>;

export const generateDataScienceCorrectnessTestResultSchema = () => {
  return z.object({
    actual: z.string(),
    expected: z.string(),
    num_correct: z.number(),
    num_rows: z.number(),
    matches: z.boolean().array(),
    is_passed: z.boolean(),
  });
};

export type DataScienceCorrectnessTestResultSchema = z.infer<ReturnType<typeof generateDataScienceCorrectnessTestResultSchema>>;

export const generateDataScienceCorrectnessTestResultsSchema = () => {
  const result = generateDataScienceCorrectnessTestResultSchema();
  return z.object({
    version: z.string(),
    test_results: result.array(),
  });
};

export type DataScienceCorrectnessTestResultsSchema = z.infer<ReturnType<typeof generateDataScienceCorrectnessTestResultsSchema>>;
