import { useTranslation } from "@hireroo/i18n";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import * as React from "react";

import FrontendTestCase, { FrontendTestCaseProps } from "../../modules/FrontendTestCase/FrontendTestCase";
import TestCaseDialog, { TestCaseDialogProps } from "../../primitive/TestCaseDialog/TestCaseDialog";
import TestCaseTableRowV2, { TestCaseTableRowProps } from "../../primitive/TestCaseTableRow/TestCaseTableRow";

export type RowItemProps = Omit<TestCaseTableRowProps, "TestResult"> & {
  testResult?: FrontendTestCaseProps;
};

export type FrontendTestCaseDialogProps = {
  open: boolean;
  onClose: TestCaseDialogProps["onClose"];
  items: RowItemProps[];
};

const FrontendTestCaseDialog: React.FC<FrontendTestCaseDialogProps> = props => {
  const { t } = useTranslation();
  const testCaseDialogProps: Omit<TestCaseDialogProps, "children"> = {
    open: props.open,
    title: t("テストケースの実行"),
    description: t(
      "各テストケースは独立して実行することができます。テストケースが落ちている場合は指定されたIDを付与しているかご確認ください。",
    ),
    onClose: props.onClose,
  };
  return (
    <TestCaseDialog {...testCaseDialogProps}>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableBody>
            {props.items.map((item, index) => {
              const { testResult, ...rowProps } = item;
              const testCaseTableRowProps: TestCaseTableRowProps = {
                ...rowProps,
                TestResult: testResult && <FrontendTestCase {...testResult} />,
              };
              return <TestCaseTableRowV2 key={`testcase-${index}`} {...testCaseTableRowProps} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </TestCaseDialog>
  );
};

FrontendTestCaseDialog.displayName = "FrontendTestCaseDialog";

export default FrontendTestCaseDialog;
