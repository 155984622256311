import { useTranslation } from "@hireroo/i18n";
import Markdown from "@hireroo/markdown-v2/react";
import * as React from "react";

import SubmissionAccordionItemLayout, {
  SubmissionAccordionItemLayoutProps,
} from "../SubmissionAccordionItemLayout/SubmissionAccordionItemLayout";

export type SubmissionAccordionAdditionalDetailContentProps = {
  description: string;
};

const SubmissionAccordionAdditionalDetailContent: React.FC<SubmissionAccordionAdditionalDetailContentProps> = props => {
  const { t } = useTranslation();
  const contentProps: SubmissionAccordionItemLayoutProps = {
    title: t("解説"),
    child: {
      kind: "COMPONENT",
      content: (
        <React.Suspense>
          <Markdown size="small" children={props.description} />
        </React.Suspense>
      ),
    },
  };

  return <SubmissionAccordionItemLayout {...contentProps} />;
};

SubmissionAccordionAdditionalDetailContent.displayName = "SubmissionAccordionAdditionalDetailContent";

export default SubmissionAccordionAdditionalDetailContent;
