import { boolDisplay, parseTableRecords } from "@hireroo/challenge/definition";
import { useTranslation } from "@hireroo/i18n";
import { AlgorithmSignatureForm } from "@hireroo/validator";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import AlgorithmTestCaseTableSummaryPreview, {
  AlgorithmTestCaseTableSummaryPreviewProps,
} from "../../../../../usecase/AlgorithmTestCaseTableSummaryPreview/AlgorithmTestCaseTableSummaryPreview";
import { outputsFormatToArray } from "../../../../AlgorithmTestCasesForm/parts/Database/databaseUtils";

type SummaryRows = AlgorithmTestCaseTableSummaryPreviewProps["summaryRows"];

export type DBTestCasePreviewDialogProps = {
  signature: AlgorithmSignatureForm.DatabaseSignatureSchemaType;

  testCase: {
    title: string;
    inputs: string[];
    outputs: string[];
  };

  open: boolean;
  onClose: () => void;
};

const DBTestCasePreviewDialog: React.FC<DBTestCasePreviewDialogProps> = props => {
  const { t } = useTranslation();
  const { signature } = props;

  const inputSummaryRows: SummaryRows = signature.tables.map((table, index) => {
    const tableColumns = table.columns.map(column => {
      return {
        name: column.name,
        type: column.type,
      };
    });
    return {
      tableName: table.name,
      outputTable: {
        tableColumns: tableColumns,
        displayRecords: parseTableRecords(props.testCase.inputs[index]).map(record => {
          return table.columns.map((column, columnIndex) => {
            return column.type === "bool" ? boolDisplay[`${record[columnIndex]}`] : `${record[columnIndex] ?? ""}`;
          });
        }),
      },
    };
  });
  const outputColumns = signature.columns.map(column => {
    return {
      name: column.name,
      type: column.type,
    };
  });
  const outputSummaryRows: SummaryRows = [
    {
      tableName: "output",
      outputTable: {
        tableColumns: outputColumns,
        displayRecords: parseTableRecords(outputsFormatToArray(props.testCase.outputs)[0]).map(record => {
          return signature.columns.map((column, columnIndex) => {
            return column.type === "bool" ? boolDisplay[`${record[columnIndex]}`] : `${record[columnIndex] ?? ""}`;
          });
        }),
      },
    },
  ];

  return (
    <Dialog maxWidth="xl" open={props.open} onClose={props.onClose}>
      <DialogTitle>{t("テストケースの確認")}</DialogTitle>
      <DialogContent sx={{ minWidth: 800 }}>
        <Typography variant="subtitle2" mb={2}>
          {`${t("テストケース名")}: ${props.testCase.title}`}
        </Typography>
        <Stack direction="column" spacing={4}>
          <AlgorithmTestCaseTableSummaryPreview columnName={t("テーブル名")} summaryRows={inputSummaryRows} />
          <AlgorithmTestCaseTableSummaryPreview columnName={t("期待するクエリの出力結果")} summaryRows={outputSummaryRows} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={props.onClose}>
          {t("閉じる")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DBTestCasePreviewDialog.displayName = "DBTestCasePreviewDialog";

export default DBTestCasePreviewDialog;
