import { state } from "./State";
import type * as Types from "./types";

export const updateInterview = (newValue: Types.Interview): void => {
  state.interview = newValue;
};

export const clear = () => {
  state.interview = null;
};
