import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import ChallengeTestReportContainer, { ChallengeTestReportContainerProps } from "./Container";
import { GenerateChallengeTestReportPropsArgs } from "./useGenerateProps";

export type ChallengeTestReportMiddleContainerProps = GenerateChallengeTestReportPropsArgs;

const ChallengeTestReportMiddleContainer: React.FC<ChallengeTestReportMiddleContainerProps> = props => {
  const containerProps: ChallengeTestReportContainerProps = props;
  return (
    <ErrorBoundary>
      <ChallengeTestReportContainer key={props.uniqueKey} {...containerProps} />
    </ErrorBoundary>
  );
};

ChallengeTestReportMiddleContainer.displayName = "ChallengeTestReportMiddleContainer";

export default withErrorBoundary(ChallengeTestReportMiddleContainer, {});
