import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type QuestionPackageSearchAreaContainerProps = {};

const QuestionPackageSearchAreaContainer: React.FC<QuestionPackageSearchAreaContainerProps> = () => {
  const questionPackageSearchAreaProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.QuestionPackageSearchArea {...questionPackageSearchAreaProps} />
    </ErrorBoundary>
  );
};

QuestionPackageSearchAreaContainer.displayName = "QuestionPackageSearchAreaContainer";

export default withErrorBoundary(QuestionPackageSearchAreaContainer, {});
