import * as Graphql from "@hireroo/graphql/client/urql";
import { ComponentType } from "@hireroo/system-design/features";

export const componentTypeGraphqlToLowerComponentType: Record<Graphql.SystemDesignComponentType, ComponentType> = {
  [Graphql.SystemDesignComponentType.Default]: "default",
  [Graphql.SystemDesignComponentType.Gcp]: "gcp",
  [Graphql.SystemDesignComponentType.Aws]: "aws",
  [Graphql.SystemDesignComponentType.Azure]: "azure",
};

export const stringComponentTypeToGraphqlComponentTypeGraphql: Record<
  ComponentType | Uppercase<ComponentType>,
  Graphql.SystemDesignComponentType
> = {
  default: Graphql.SystemDesignComponentType.Default,
  gcp: Graphql.SystemDesignComponentType.Gcp,
  aws: Graphql.SystemDesignComponentType.Aws,
  azure: Graphql.SystemDesignComponentType.Azure,
  DEFAULT: Graphql.SystemDesignComponentType.Default,
  GCP: Graphql.SystemDesignComponentType.Gcp,
  AWS: Graphql.SystemDesignComponentType.Aws,
  AZURE: Graphql.SystemDesignComponentType.Azure,
};
