import { ScreeningTestListForDetail } from "@hireroo/app-store/widget/e/ScreeningTestListForDetail";
import * as Graphql from "@hireroo/graphql/client/urql";
import { TestSearchForm } from "@hireroo/validator";
import { useSearchParams } from "react-router-dom";

export const useQueryParams = (): ScreeningTestListForDetail.QueryParams => {
  const [searchParams] = useSearchParams();
  return {
    size: searchParams.get(ScreeningTestListForDetail.QueryKeys.SIZE),
    page: searchParams.get(ScreeningTestListForDetail.QueryKeys.PAGE),
    tagNames: searchParams.getAll(ScreeningTestListForDetail.QueryKeys.TAG),
    name: searchParams.get(ScreeningTestListForDetail.QueryKeys.TITLE),
    result: searchParams.get(ScreeningTestListForDetail.QueryKeys.RESULT),
    statuses: searchParams.getAll(ScreeningTestListForDetail.QueryKeys.STATUS),
    rankEvaluations: searchParams.getAll(ScreeningTestListForDetail.QueryKeys.RANK_EVALUATION),
    minScore: searchParams.get(ScreeningTestListForDetail.QueryKeys.MIN_SCORE),
    maxScore: searchParams.get(ScreeningTestListForDetail.QueryKeys.MAX_SCORE),
    sortMethod: searchParams.get(ScreeningTestListForDetail.QueryKeys.SORT),
    isDescending: searchParams.get(ScreeningTestListForDetail.QueryKeys.SORT),
    suspiciousDegrees: searchParams.getAll(ScreeningTestListForDetail.QueryKeys.SUSPICIOUS_DEGREES),
  };
};

export const statusToGraphql: Record<TestSearchForm.TestSearchQuerySchema["statuses"][0], Graphql.SpotStatus> = {
  CREATED: Graphql.SpotStatus.Created,
  STARTED: Graphql.SpotStatus.Started,
  COMPLETED: Graphql.SpotStatus.Completed,
  FINALIZED: Graphql.SpotStatus.Finalized,
  EVALUATED: Graphql.SpotStatus.Evaluated,
  EXPIRED: Graphql.SpotStatus.Expired,
  DECLINED: Graphql.SpotStatus.Declined,
};

export const statusToUi: Record<Graphql.SpotStatus, TestSearchForm.TestSearchQuerySchema["statuses"][0] | null> = {
  [Graphql.SpotStatus.Created]: "CREATED",
  [Graphql.SpotStatus.Started]: "STARTED",
  [Graphql.SpotStatus.Completed]: "COMPLETED",
  [Graphql.SpotStatus.Finalized]: "FINALIZED",
  [Graphql.SpotStatus.Evaluated]: "EVALUATED",
  [Graphql.SpotStatus.Declined]: "DECLINED",
  [Graphql.SpotStatus.Expired]: null,
  [Graphql.SpotStatus.Accepted]: null,
  [Graphql.SpotStatus.Reviewed]: null,
  [Graphql.SpotStatus.Unknown]: null,
};

export const rankToUi: Record<Graphql.RankEvaluation, TestSearchForm.TestSearchQuerySchema["rank"][0] | null> = {
  [Graphql.RankEvaluation.A]: "A",
  [Graphql.RankEvaluation.B]: "B",
  [Graphql.RankEvaluation.C]: "C",
  [Graphql.RankEvaluation.D]: "D",
  [Graphql.RankEvaluation.S]: "S",
  [Graphql.RankEvaluation.Unknown]: null,
};

export const resultMap: Record<Exclude<TestSearchForm.TestSearchQuerySchema["result"], null>, boolean> = {
  PASSED: true,
  NOT_PASSED: false,
};

export const suspiciousDegreeToUi: Record<Graphql.SpotSuspiciousDegree, TestSearchForm.TestSearchQuerySchema["suspiciousDegrees"][0] | null> = {
  [Graphql.SpotSuspiciousDegree.Clean]: "CLEAN",
  [Graphql.SpotSuspiciousDegree.Suspicious]: "SUSPICIOUS",
  [Graphql.SpotSuspiciousDegree.Uncalculated]: null,
};
