import { BuySelections } from "@hireroo/app-store/widget/e/BuySelections";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { formatPrice } from "@hireroo/formatter/money";
import { unixToDatetimeFormat } from "@hireroo/formatter/time";
import { useLanguageCode, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import addMonths from "date-fns/addMonths";
import differenceInMonths from "date-fns/differenceInMonths";
import * as React from "react";

import { usePayMonthConfirmSectionProps } from "../privateHelper";

type ItemMap = Widget.BuySelectionFormProps["selectNumberOfSelectionField"]["items"];

type CouponFieldProps = Exclude<Widget.BuySelectionFormProps["couponField"], undefined>;

export type GenerateBuySelectionFormPropsArgs = {};

export const useGenerateProps = (_args: GenerateBuySelectionFormPropsArgs): Widget.BuySelectionFormProps => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const customer = BuySelections.useCustomer();
  const optionalSubmitValue = BuySelections.useOptionalSubmitValue();
  const selectionItemsMap = BuySelections.useBuyAbleSelectionItemMap();
  const buyAbleInterviews = BuySelections.useBuyAbleInterviews();
  const lang = useLanguageCode();
  const temporarySelectionItems = BuySelections.useTemporarySelectionItems();
  const itemMap = buyAbleInterviews.reduce<ItemMap>((all, interview) => {
    const selectionItem = temporarySelectionItems.find(item => item.itemId === interview.interviewId.toString());
    const item: ItemMap[""] = {
      fieldLabel: t2("nSelections", { num: interview.quantity }),
      price: interview.price * interview.quantity,
      priceLabel: formatPrice(interview.price * interview.quantity),
      unitPriceLabel: [t("単価"), t2("unitPriceForSelection", { value: formatPrice(interview.price) })].join(": "),
      totalPriceLabel: formatPrice(interview.price * interview.quantity * (selectionItem?.amount ?? 0)),
    };
    return {
      ...all,
      [interview.interviewId]: item,
    };
  }, {});

  const defaultValues = React.useMemo((): BuySelections.SubmitValue => {
    if (optionalSubmitValue) {
      return {
        ...optionalSubmitValue,
        selectionItems: optionalSubmitValue.selectionItems.slice(),
      };
    }
    return {
      selectionItems: buyAbleInterviews.map(interview => {
        return {
          itemId: interview.interviewId.toString(),
          amount: 0,
        };
      }),
    };
  }, [optionalSubmitValue, buyAbleInterviews]);

  const confirmSection = usePayMonthConfirmSectionProps();

  const calculatedUnitPriceLabel = React.useMemo((): string => {
    const result = temporarySelectionItems.reduce<{ total: number; count: number }>(
      (acc, current) => {
        if (current.amount === 0) {
          return acc;
        }
        const selectionItem = selectionItemsMap[current.itemId];
        if (!selectionItem) {
          return acc;
        }
        return {
          count: acc.count + current.amount * selectionItem.quantity,
          total: acc.total + current.amount * selectionItem.quantity * selectionItem.price,
        };
      },
      { total: 0, count: 0 },
    );
    if (result.count === 0) {
      return "-";
    }
    return formatPrice(Math.round(result.total / result.count));
  }, [temporarySelectionItems, selectionItemsMap]);

  const applyResultsProps = React.useMemo((): CouponFieldProps["applyResults"] => {
    return customer.subscription.subscribedCoupons.reduce<CouponFieldProps["applyResults"]>((acc, subscribedCoupon) => {
      const { coupon } = subscribedCoupon;
      if (coupon.couponType === "PERCENT_OFF") {
        const billingMonth = addMonths(new Date(), 1);
        const leftValue = differenceInMonths(billingMonth, new Date(subscribedCoupon.createdAtSeconds * 1000));
        const rightValue = differenceInMonths(new Date(subscribedCoupon.expiresAtSeconds * 1000), billingMonth);
        const withinExpiration = 0 <= leftValue && 0 <= rightValue;
        if (!withinExpiration) {
          return acc;
        }
        return acc.concat({
          text: [
            resolveLanguage(coupon, lang, "name"),
            t2("percentDiscountDescription", {
              value: coupon.discountNumber,
              duration: `${unixToDatetimeFormat(subscribedCoupon.createdAtSeconds)} - ${unixToDatetimeFormat(
                subscribedCoupon.expiresAtSeconds,
              )}`,
            }),
          ].join(" "),
        });
      }
      return acc;
    }, []);
  }, [t2, lang, customer.subscription.subscribedCoupons]);

  return {
    onSubmit: fields => {
      const amountOfSelectionItem = fields.selectionItems.reduce((total, selectionItem) => {
        return total + selectionItem.amount;
      }, 0);

      if (amountOfSelectionItem <= 0) {
        Snackbar.notify({
          severity: "warning",
          message: t("購入可能な商品が選択されていません。"),
        });
        return;
      }

      BuySelections.setSubmitValue(fields);
      BuySelections.updateShowingTarget("CONFIRM_AND_TOS");
    },
    couponField:
      applyResultsProps.length > 0
        ? {
            showCouponInputField: false,
            applyResults: applyResultsProps,
          }
        : undefined,
    selectNumberOfSelectionField: {
      shotInterviewPriceLabel: formatPrice(customer.subscription.shotInterview.price),
      onChange: fields => {
        BuySelections.setTemporarySelectionItems(fields);
      },
      items: itemMap,
      calculatedUnitPriceLabel: calculatedUnitPriceLabel,
    },
    nextMonthPaymentConfirmSection: confirmSection,
    defaultValues: defaultValues,
  };
};
