import { App } from "@hireroo/app-store/essential/talent";
import { AssessmentOverview } from "@hireroo/app-store/widget/t/AssessmentOverview";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import AssessmentOverviewContainer, { AssessmentOverviewContainerProps } from "./Container";

export type AssessmentOverviewFetchContainerProps = {
  assessmentId: string;
  companyId: number;
  examId: string;
};

const AssessmentOverviewFetchContainer: React.FC<AssessmentOverviewFetchContainerProps> = props => {
  const appStatus = App.useStatus();
  const [result, refresh] = Graphql.useGetAssessmentOverviewQuery({
    variables: {
      assessmentId: props.assessmentId,
      examId: props.examId,
    },
    requestPolicy: "cache-and-network",
    pause: appStatus !== "INITIALIZED",
  });
  const initialized = AssessmentOverview.useInitialized();

  React.useEffect(() => {
    if (result.data?.assessment) {
      // update your state
      AssessmentOverview.updateAssessment(result.data.assessment);
      AssessmentOverview.setRefresh(() => refresh({ requestPolicy: "network-only" }));
    }
    if (result.data?.exam) {
      AssessmentOverview.updateExam(result.data.exam);
    }
  }, [refresh, result.data]);

  React.useEffect(() => {
    return () => {
      AssessmentOverview.clear();
    };
  }, []);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="TOP" color="secondary" />;
  }
  const containerProps: AssessmentOverviewContainerProps = {
    companyId: props.companyId,
  };
  return (
    <ErrorBoundary>
      <AssessmentOverviewContainer {...containerProps} />
    </ErrorBoundary>
  );
};

AssessmentOverviewFetchContainer.displayName = "AssessmentOverviewFetchContainer";

export default withErrorBoundary(AssessmentOverviewFetchContainer, {});
