import { useMethods } from "@hireroo/react-split";
import * as React from "react";

export const SplitParams = {
  splitId: "SystemDesignCodingEditor",
  Contents: {
    CodingEditorSideBar: {
      id: "CodingEditorSideBar",
    },
    FlowChart: {
      id: "FlowChart",
    },
    SelectComponentArea: {
      id: "SelectComponentArea",
    },
    DrawingArea: {
      id: "DrawingArea",
    },
  },
};

export const useSplitEvent = () => {
  const methods = useMethods();
  const [sidebarVisibleStatus, setSidebarVisibleStatus] = React.useState<"MINIMIZED" | "OPEN">("OPEN");
  const [selectComponentAreaVisibleStatus, setSelectComponentAreaVisibleStatus] = React.useState<"MINIMIZED" | "OPEN">("OPEN");

  React.useEffect(() => {
    const cleanup = methods.subscribeChangeEvent(SplitParams.splitId, event => {
      if (event.type === "MINIMIZED") {
        if (event.contentId === SplitParams.Contents.CodingEditorSideBar.id) {
          setSidebarVisibleStatus("MINIMIZED");
        }
        if (event.contentId === SplitParams.Contents.SelectComponentArea.id) {
          setSelectComponentAreaVisibleStatus("MINIMIZED");
        }
        return;
      }
      if (event.type === "RELEASE_MINIMIZED") {
        if (event.contentId === SplitParams.Contents.CodingEditorSideBar.id) {
          setSidebarVisibleStatus("OPEN");
        }
        if (event.contentId === SplitParams.Contents.SelectComponentArea.id) {
          setSelectComponentAreaVisibleStatus("OPEN");
        }
        return;
      }
    });
    return () => {
      cleanup();
    };
  }, [methods]);

  return {
    sidebarVisibleStatus,
    setSidebarVisibleStatus,
    selectComponentAreaVisibleStatus,
    setSelectComponentAreaVisibleStatus,
  };
};
