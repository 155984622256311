import { proxy } from "valtio";
import { proxyMap } from "valtio/utils";

import type { State } from "./types";

export const state = proxy<State>({
  registerSonar: {
    selectedScreening: null,
  },
  deleteSonar: {
    selectedSonarIntegrationId: null,
  },
  sonarIntegrations: [],
  sonarPagination: {
    size: 30,
    page: 0,
    count: 0,
  },
  screeningMap: proxyMap(),
  screeningPagination: null,
  sonarIntegrationMap: proxyMap(),
});
