import { useTranslation } from "@hireroo/i18n";
import { AlgorithmSignatureForm } from "@hireroo/validator";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";

export type AlgorithmTestCaseHeaderProps = {
  name: string;
  signature: AlgorithmSignatureForm.AlgorithmSignatureSchemaType;
};

const AlgorithmTestCaseHeader: React.FC<AlgorithmTestCaseHeaderProps> = props => {
  const { t } = useTranslation();
  const signature = props.signature;

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        <TableCell>{props.name}</TableCell>
        {signature.inputs.map((input, i) => (
          <TableCell key={input.name}>{`${t("引数")}${i + 1} ${input.name} (${input.type})`}</TableCell>
        ))}
        {signature.outputs.map(output => (
          <TableCell key={output.type}>{`${t("出力")} ${output.type}`}</TableCell>
        ))}
        <TableCell>{t("ステータス")}</TableCell>
        <TableCell align="right" />
      </TableRow>
    </TableHead>
  );
};

AlgorithmTestCaseHeader.displayName = "AlgorithmTestCaseHeader";

export default AlgorithmTestCaseHeader;
