import { RemoteInterviewOverview } from "@hireroo/app-store/widget/e/RemoteInterviewOverview";
import * as TimeFormatter from "@hireroo/formatter/time";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";

export type GenerateTimelinePropsArgs = {};

type TimelineItem = "STARTED" | "ENDED" | "EVALUATED";
const statusToTimelineStatus: Record<Graphql.RemoteStatus, Record<TimelineItem, "DOING" | "DONE">> = {
  UNKNOWN: {
    STARTED: "DOING",
    ENDED: "DOING",
    EVALUATED: "DOING",
  },
  CREATED: {
    STARTED: "DOING",
    ENDED: "DOING",
    EVALUATED: "DOING",
  },
  STARTED: {
    STARTED: "DONE",
    ENDED: "DOING",
    EVALUATED: "DOING",
  },
  COMPLETED: {
    STARTED: "DONE",
    ENDED: "DONE",
    EVALUATED: "DOING",
  },
  EVALUATED: {
    STARTED: "DONE",
    ENDED: "DONE",
    EVALUATED: "DONE",
  },
};
export const useGenerateTimelineProps = (_args: GenerateTimelinePropsArgs): Widget.RemoteInterviewOverviewProps["timeline"] => {
  const { t } = useTranslation();
  const interview = RemoteInterviewOverview.useInterview();
  return {
    items: [
      {
        title: t("テスト作成"),
        status: "DONE",
        dateTime: interview.createdAtSeconds ? TimeFormatter.unixToDateFormat(interview.createdAtSeconds) : undefined,
      },
      {
        title: t("テスト開始"),
        status: statusToTimelineStatus[interview.status].STARTED,
        dateTime: interview.didStartAtSeconds ? TimeFormatter.unixToDateFormat(interview.didStartAtSeconds) : undefined,
      },
      {
        title: t("テスト終了"),
        status: statusToTimelineStatus[interview.status].ENDED,
        dateTime:
          statusToTimelineStatus[interview.status].ENDED === "DONE" && interview.didEndAtSeconds
            ? TimeFormatter.unixToDateFormat(interview.didEndAtSeconds)
            : undefined,
      },
      {
        title: t("テスト評価"),
        status: statusToTimelineStatus[interview.status].EVALUATED,
        dateTime:
          statusToTimelineStatus[interview.status].EVALUATED === "DONE" && interview.evaluatedAtSeconds
            ? TimeFormatter.unixToDateFormat(interview.evaluatedAtSeconds)
            : undefined,
      },
    ],
  };
};
