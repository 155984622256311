import { proxy } from "valtio";
import { proxyMap } from "valtio/utils";

import type * as Types from "./types";

export const state = proxy<Types.State>({
  initialized: false,
  examIds: [],
  examMap: proxyMap(),
  currentPage: 0,
  count: 0,
  hasNext: false,
  offset: 0,
});
