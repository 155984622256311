import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import * as React from "react";
import { FormProvider } from "react-hook-form";

import { useAlgorithmTestCasesFormContext } from "./Context";
import AlgorithmCorrectnessTestCasesForm, {
  AlgorithmCorrectnessTestCasesFormProps,
} from "./parts/AlgorithmCorrectnessTestCasesForm/AlgorithmCorrectnessTestCasesForm";
import AlgorithmPerformanceTestCasesForm, {
  AlgorithmPerformanceTestCasesFormProps,
} from "./parts/AlgorithmPerformanceTestCasesForm/AlgorithmPerformanceTestCasesForm";
import TestCaseErrorMessage from "./parts/TestCaseErrorMessage";
import AlgorithmCorrectnessTestCasesFormV2, {
  AlgorithmCorrectnessTestCasesFormProps as AlgorithmCorrectnessTestCasesFormV2Props,
} from "./parts/v2/AlgorithmCorrectnessTestCasesForm/AlgorithmCorrectnessTestCasesForm";
import AlgorithmPerformanceTestCasesFormV2, {
  AlgorithmPerformanceTestCasesFormProps as AlgorithmPerformanceTestCasesFormV2Props,
} from "./parts/v2/AlgorithmPerformanceTestCasesForm/AlgorithmPerformanceTestCasesForm";

export { AlgorithmTestCasesFormProvider, type AlgorithmTestCasesFormProviderProps, useAlgorithmTestCasesFormContext } from "./Context";

export type AlgorithmTestCasesFormProps = {
  correctness: AlgorithmCorrectnessTestCasesFormProps;
  correctnessV2?: AlgorithmCorrectnessTestCasesFormV2Props;
  performanceV2?: AlgorithmPerformanceTestCasesFormV2Props;
};

const AlgorithmTestCasesForm: React.FC<AlgorithmTestCasesFormProps> = props => {
  const { method } = useAlgorithmTestCasesFormContext();
  const correctness: AlgorithmCorrectnessTestCasesFormProps = {
    ...props.correctness,
  };
  const performance: AlgorithmPerformanceTestCasesFormProps = {};
  return (
    <FormProvider {...method}>
      <Box display="flex" flexDirection="column" rowGap={1}>
        <TestCaseErrorMessage />
      </Box>
      <Box display="flex" flexDirection="column" gap={4}>
        {props.correctnessV2 ? (
          <AlgorithmCorrectnessTestCasesFormV2 {...props.correctnessV2} />
        ) : (
          <AlgorithmCorrectnessTestCasesForm {...correctness} />
        )}
        <Divider />
        {props.performanceV2 ? (
          <AlgorithmPerformanceTestCasesFormV2 {...props.performanceV2} />
        ) : (
          <AlgorithmPerformanceTestCasesForm {...performance} />
        )}
      </Box>
    </FormProvider>
  );
};

AlgorithmTestCasesForm.displayName = "AlgorithmTestCasesForm";

export default AlgorithmTestCasesForm;
