import FormControl from "@mui/material/FormControl";
import FormControlLabel, { FormControlLabelProps } from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Radio, { RadioProps } from "@mui/material/Radio";
import RadioGroup, { RadioGroupProps } from "@mui/material/RadioGroup";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { Control, Controller } from "react-hook-form";

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  wordBreak: "keep-all",
  whiteSpace: "nowrap",
}));

type OptionProps = Omit<FormControlLabelProps, "control"> & {
  SuffixComponent?: React.ReactNode;
};

export type RadioControlFieldProps = {
  className?: string;
  row?: boolean;
  color?: RadioProps["color"];
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any>;
  defaultValue?: string | number;
  options: OptionProps[];
  onChange?: (value: string) => void;
  size?: RadioProps["size"];
  fullWidth?: boolean;
};

const RadioControlField: React.FC<RadioControlFieldProps> = props => {
  const { onChange } = props;
  const handleChange: Exclude<RadioGroupProps["onChange"], undefined> = React.useCallback(
    (_, value) => {
      onChange?.(value);
    },
    [onChange],
  );
  return (
    <FormControl fullWidth={props.fullWidth}>
      <Controller
        name={props.name}
        control={props.control}
        render={({ field, fieldState }) => (
          <RadioGroup
            color={props.color}
            row={props.row}
            className={props.className}
            value={field.value}
            defaultValue={props.defaultValue}
            onChange={handleChange}
          >
            {props.options.map(option => (
              <React.Fragment key={option.id ?? option.name}>
                <StyledFormControlLabel
                  key={option.id ?? option.name}
                  label={option.label}
                  control={<Radio size={props.size} color={props.color} disabled={option.disabled} />}
                  value={option.value}
                />
                {option.SuffixComponent}
              </React.Fragment>
            ))}
            {fieldState.error?.message && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
};

RadioControlField.displayName = "RadioControlField";

export default RadioControlField;
