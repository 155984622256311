import { useTranslation } from "@hireroo/i18n";
import OpenInNew from "@mui/icons-material/OpenInNew";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

type PairEditorFooterAccessible = {
  kind: "ACCESSIBLE";
  url: string;
};

type PairEditorFooterOtherCompany = {
  kind: "OTHER_COMPANY";
};

type PairEditorFooterNotFound = {
  kind: "NOT_FOUND";
};

export type PairMatchCodeEditorFooterProps = PairEditorFooterAccessible | PairEditorFooterOtherCompany | PairEditorFooterNotFound;

const PairMatchCodeEditorFooter = React.forwardRef((props: PairMatchCodeEditorFooterProps, ref: React.ForwardedRef<HTMLElement>) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const Content = React.useMemo((): React.ReactNode => {
    switch (props.kind) {
      case "ACCESSIBLE":
        return (
          <Link href={props.url} target="_blank" sx={{ color: theme.palette.text.secondary, textDecoration: "none" }}>
            {props.url} <OpenInNew fontSize="inherit" sx={{ verticalAlign: "middle" }} />
          </Link>
        );
      case "OTHER_COMPANY":
        return t("他社企業の候補者が提出したコードです。");
      case "NOT_FOUND":
        return t("提出コードのリンクはみつかりませんでした");
      default:
        throw new Error(`invalid kind ${props satisfies never}`);
    }
  }, [t, props, theme]);

  return (
    <Typography
      sx={{
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "166%",
        letterSpacing: "0.4px",
        textAlign: "center",
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.action.hover,
      }}
      ref={ref}
    >
      {Content}
    </Typography>
  );
});

PairMatchCodeEditorFooter.displayName = "PairMatchCodeEditorFooter";

export default PairMatchCodeEditorFooter;
