import * as Graphql from "@hireroo/graphql/client/urql";
import { withErrorBoundary } from "@sentry/react";
import * as React from "react";

import AlgorithmContainer from "./variant/FreepadQuestionDetailForAlgorithm/Container";
import SystemDesignInitialContainer from "./variant/FreepadQuestionDetailForSystemDesign/Container";

export type FreepadQuestionDetailContainerProps = {
  question: Graphql.FreepadQuestionDetailFragment;
};

const FreepadQuestionDetailContainer: React.FC<FreepadQuestionDetailContainerProps> = props => {
  if (props.question.variant === "ALGORITHM" || props.question.variant === "DATABASE" || props.question.variant === "CLASS") {
    return <AlgorithmContainer {...props} />;
  }
  if (props.question.variant === "SYSTEM_DESIGN") {
    return <SystemDesignInitialContainer {...props} />;
  }
  return null;
};

FreepadQuestionDetailContainer.displayName = "FreepadQuestionDetailContainer";

export default withErrorBoundary(FreepadQuestionDetailContainer, {});
