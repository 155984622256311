import { Output } from "@hireroo/challenge/definition";
import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Input, { InputProps } from "@mui/material/Input";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import ChallengeIDEConsoleOutputTable from "../../../../ChallengeIDEConsoleOutputTable/ChallengeIDEConsoleOutputTable";
import { useChallengeCodingEditorContext } from "../../../Context";

const StyledInput = styled(Input)(({ theme }) => ({
  color: theme.palette.text.primary,
  backgroundColor: theme.palette["Secondary/Shades"].p16,
  borderRadius: "4px",
  border: "none",
  fontFamily: 'Menlo, Monaco, "Courier New", monospace',
  paddingLeft: "8px",
  outline: "none",
  minHeight: "36px",
  width: "100%",
}));

const StyledTextField = styled(Typography)<{ ownerState: { isCorrect: boolean } }>(({ ownerState, theme }) => ({
  color: ownerState.isCorrect ? theme.palette.success.main : theme.palette.error.main,
  fontWeight: "bold",
}));

const ErrorWrapper = styled(Box)(({ theme }) => ({
  borderRadius: "4px",
  backgroundColor: theme.palette["Secondary/Shades"].p16,
}));

type DisplayOutput = {
  performanceNanoSeconds: string;
  maxMemoryMb: number;
};

export type IDEConsoleOutputProps = {
  output?: Omit<Output, "performance" | "maxMemory"> & DisplayOutput;
  isLoading?: boolean;
};

const IDEConsoleOutput: React.FC<IDEConsoleOutputProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { contextProps } = useChallengeCodingEditorContext();

  if (props.isLoading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  if (!props.output || props.output.status === "RUNNING") {
    return <Typography variant="body2">{t("実行結果がありません。テストケースを実行することで結果を見ることができます。")}</Typography>;
  }

  const outputValues = [
    {
      label: t("出力"),
      value: props.output.output,
    },
    {
      label: t("期待値"),
      value: props.output.expected ?? "",
    },
    {
      label: t("ログ"),
      value: props.output.log ?? "",
    },
  ];
  const input: InputProps = {
    readOnly: true,
    multiline: true,
    disableUnderline: true,
    sx: { fontSize: "13px" },
  };

  const OutputVariantMap = {
    ALGORITHM: (
      <Grid container spacing={2}>
        {outputValues.map(
          output =>
            output.value && (
              <Grid key={output.label} item xs={output.label === t("ログ") ? 12 : 6}>
                <Stack direction="column" spacing={1}>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    {output.label}
                  </Typography>
                  <StyledInput {...input} value={output.value} />
                </Stack>
              </Grid>
            ),
        )}
      </Grid>
    ),
    DATABASE: (
      <>
        {outputValues.map(
          output =>
            output.value && (
              <Box mt={2} key={output.label}>
                <ChallengeIDEConsoleOutputTable label={output.label} value={output.value} />
              </Box>
            ),
        )}
      </>
    ),
    CLASS: (
      <Grid container spacing={2}>
        {outputValues.map(
          output =>
            output.value && (
              <Grid key={output.label} item xs={output.label === t("ログ") ? 12 : 6}>
                <Stack direction="column" spacing={1}>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    {output.label}
                  </Typography>
                  <StyledInput {...input} value={output.value} />
                </Stack>
              </Grid>
            ),
        )}
      </Grid>
    ),
  } satisfies Record<"ALGORITHM" | "DATABASE" | "CLASS", React.ReactNode>;

  if (props.output.status === "EXECUTED") {
    return (
      <Box bgcolor="inherit">
        <Stack direction="row" alignItems="baseline" height="30px" spacing={2}>
          <StyledTextField ownerState={{ isCorrect: Boolean(props.output.isAccepted) }} variant="subtitle2">
            {props.output.isAccepted ? t("正解") : t("不正解")}
          </StyledTextField>

          <Stack direction="row" spacing={2}>
            <Typography variant="subtitle2" sx={{ color: theme.palette.text.primary }}>
              {`${t("実行速度")}: ${props.output.performanceNanoSeconds}`}
            </Typography>
            <Typography variant="subtitle2" sx={{ color: theme.palette.text.primary }}>
              {`${t("メモリ")}: ${props.output.maxMemoryMb}MB`}
            </Typography>
          </Stack>
        </Stack>
        <Box mt={1}>{OutputVariantMap[contextProps.signatureProps.variant]}</Box>
      </Box>
    );
  }

  return (
    <ErrorWrapper p={1}>
      <Typography variant="body2" sx={{ color: theme.palette.error.dark, whiteSpace: "pre-wrap" }}>
        {props.output.log}
      </Typography>
    </ErrorWrapper>
  );
};

IDEConsoleOutput.displayName = "IDEConsoleOutput";

export default IDEConsoleOutput;
