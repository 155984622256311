import { useTranslation } from "@hireroo/i18n";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "46px",
  padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
}));

const SummaryContentWrapper = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  height: "22px",
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  borderColor: theme.palette.divider,
  "&:first-of-type": {
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
  },
  "&:last-of-type": {
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
  },
}));

const PassedIconWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginRight: theme.spacing(2),
}));

const InvisibleIconWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  color: theme.palette.text.secondary,
  marginRight: theme.spacing(1),
  alignItems: "center",
  "& svg": { width: 16, height: 16 },
}));

type Status = "SUCCESS" | "ERROR";

export type TestCaseDetailProps = {
  title: string;
  invisible: boolean;
  status: Status;
  expandAll: boolean;
  onChange?: () => void;
};

const TestCaseDetail: React.FC<React.PropsWithChildren<TestCaseDetailProps>> = props => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState<boolean>(props.expandAll);
  React.useEffect(() => {
    setExpanded(props.expandAll);
  }, [props.expandAll]);
  const handleClick = () => {
    setExpanded(draft => !draft);
    props.onChange?.();
  };
  const statusContentMap: Record<Status, React.ReactNode> = {
    SUCCESS: (
      <Tooltip title={t("正解")}>
        <CheckCircleIcon color="success" />
      </Tooltip>
    ),
    ERROR: (
      <Tooltip title={t("不正解")}>
        <CancelIcon color="error" />
      </Tooltip>
    ),
  };
  return (
    <StyledAccordion TransitionProps={{ unmountOnExit: true }} expanded={expanded}>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" onClick={handleClick}>
        <SummaryContentWrapper>
          <PassedIconWrapper>{statusContentMap[props.status]}</PassedIconWrapper>
          {props.invisible && (
            <Tooltip title={t("受験者には見えない隠しテストケースです")}>
              <InvisibleIconWrapper>
                <VisibilityOffOutlinedIcon fontSize="small" />
              </InvisibleIconWrapper>
            </Tooltip>
          )}
          <Typography variant="subtitle2">{props.title}</Typography>
        </SummaryContentWrapper>
      </StyledAccordionSummary>
      <AccordionDetails>{props.children}</AccordionDetails>
    </StyledAccordion>
  );
};

TestCaseDetail.displayName = "TestCaseDetail";

export default TestCaseDetail;
