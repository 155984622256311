/**
 * active step number
 * */
export const STEPS = {
  QUESTION_DETAILS: 0,
  FUNCTION_DEFINITION: 1,
  TABLE_SIGNATURE: 1,
  TEST_CASES: 2,
  ANSWERS: 3,
  HINTS: 4,
} as const;
