import { FreepadQuestionForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import * as React from "react";
import { useForm, UseFormReturn } from "react-hook-form";

export type ContextValue = {
  methods: UseFormReturn<FreepadQuestionForm.FreepadQuestionFormSchema>;
};

export const FreepadQuestionDetailFormContext = React.createContext<ContextValue>({} as ContextValue);

export const useFreepadQuestionDetailFormContext = () => React.useContext(FreepadQuestionDetailFormContext);

export type FreepadQuestionDetailFormProviderProps = {
  defaultValues: FreepadQuestionForm.FreepadQuestionFormSchema;
};

export const FreepadQuestionDetailFormProvider: React.FC<React.PropsWithChildren<FreepadQuestionDetailFormProviderProps>> = props => {
  const { defaultValues } = props;
  const validateSchema = FreepadQuestionForm.useFreepadQuestionFormSchema();
  const methods = useForm<FreepadQuestionForm.FreepadQuestionFormSchema>({
    resolver: zodResolver(validateSchema),
    mode: "onSubmit",
    shouldUnregister: false,
    defaultValues,
  });
  React.useEffect(() => {
    methods.reset(defaultValues);
  }, [methods, defaultValues]);

  const contextValue: ContextValue = {
    methods: methods,
  };
  return <FreepadQuestionDetailFormContext.Provider value={contextValue} children={props.children} />;
};
