import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";

export const usePlanNameMap = (generation: number): Record<Graphql.PlanType, string> => {
  const { t } = useTranslation();

  return {
    /**
     * If generation is 6 or less and the response is `Graphql.Basic`, it is expressed as "Basic" to the user;
     * If generation is 7 or more, it is expressed as "Starter" to the user.
     */
    [Graphql.PlanType.Basic]: generation <= 6 ? t("ベーシック") : t("スターター"),
    [Graphql.PlanType.Standard]: t("スタンダード"),
    [Graphql.PlanType.Professional]: t("プロフェッショナル"),
    [Graphql.PlanType.Enterprise]: t("エンタープライズ"),
  };
};

export const usePaymentItemTypeMap = (): Record<Graphql.PaymentItemType, string> => {
  const { t } = useTranslation();
  return {
    [Graphql.PaymentItemType.Basic]: t("ベーシック"),
    [Graphql.PaymentItemType.BasicV2]: t("ベーシック"),
    [Graphql.PaymentItemType.BasicV3]: t("ベーシック"),
    [Graphql.PaymentItemType.BasicV4]: t("ベーシック"),
    [Graphql.PaymentItemType.BasicV5]: t("ベーシック"),
    [Graphql.PaymentItemType.BasicV6]: t("ベーシック"),
    /**
     * If generation is 6 or less and the response is `Graphql.Basic`, it is expressed as "Basic" to the user;
     * If generation is 7 or more, it is expressed as "Starter" to the user.
     */
    [Graphql.PaymentItemType.BasicV7]: t("スターター"),
    [Graphql.PaymentItemType.BasicV8]: t("スターター"),
    [Graphql.PaymentItemType.BasicV9]: t("スターター"),

    [Graphql.PaymentItemType.Standard]: t("スタンダード"),
    [Graphql.PaymentItemType.StandardV2]: t("スタンダード"),
    [Graphql.PaymentItemType.StandardV3]: t("スタンダード"),
    [Graphql.PaymentItemType.StandardV4]: t("スタンダード"),
    [Graphql.PaymentItemType.StandardV5]: t("スタンダード"),
    [Graphql.PaymentItemType.StandardV6]: t("スタンダード"),
    [Graphql.PaymentItemType.StandardV7]: t("スタンダード"),
    [Graphql.PaymentItemType.StandardV8]: t("スタンダード"),
    [Graphql.PaymentItemType.StandardV9]: t("スタンダード"),

    [Graphql.PaymentItemType.Professional]: t("プロフェッショナル"),
    [Graphql.PaymentItemType.ProfessionalV2]: t("プロフェッショナル"),
    [Graphql.PaymentItemType.ProfessionalV3]: t("プロフェッショナル"),
    [Graphql.PaymentItemType.ProfessionalV4]: t("プロフェッショナル"),
    [Graphql.PaymentItemType.ProfessionalV5]: t("プロフェッショナル"),
    [Graphql.PaymentItemType.ProfessionalV6]: t("プロフェッショナル"),

    [Graphql.PaymentItemType.Enterprise]: t("エンタープライズ"),
    [Graphql.PaymentItemType.EnterpriseV6]: t("エンタープライズ"),
    [Graphql.PaymentItemType.EnterpriseV7]: t("エンタープライズ"),
    [Graphql.PaymentItemType.EnterpriseV8]: t("エンタープライズ"),
    [Graphql.PaymentItemType.EnterpriseV9]: t("エンタープライズ"),

    [Graphql.PaymentItemType.CodingInterview]: t("不明"),
    [Graphql.PaymentItemType.Pack]: t("不明"),
    [Graphql.PaymentItemType.Unknown]: t("不明"),
  };
};
