import { proxyMap } from "valtio/utils";

import { state } from "./State";

type InitializeArgs = {
  quizId: number;
  packageId: number;
  questionId: number;
};

export const initialize = (args: InitializeArgs) => {
  state.quizId = args.quizId;
  state.packageId = args.packageId;
  state.questionId = args.questionId;
};

export const changeAnswerText = (questionId: number, text: string) => {
  state.answerTextMap.set(questionId, text);
  /**
   * Rework references to dispatch Subscribe.
   */
  state.answerTextMap = proxyMap(state.answerTextMap);
};

export const clear = () => {
  state.quizId = null;
  state.packageId = null;
  state.questionId = null;
  state.answerTextMap = proxyMap();
};
