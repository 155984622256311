import { Widget } from "@hireroo/presentation/legacy";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateQuizPackageDetailPropsArgs, useGenerateProps } from "./useGenerateProps";

export type QuizPackageDetailContainerProps = GenerateQuizPackageDetailPropsArgs;

const QuizPackageDetailContainer: React.FC<QuizPackageDetailContainerProps> = props => {
  const quizPackageDetailProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.QuizPackageDetail {...quizPackageDetailProps} />
    </ErrorBoundary>
  );
};

QuizPackageDetailContainer.displayName = "QuizPackageDetailContainer";

export default withErrorBoundary(QuizPackageDetailContainer, {});
