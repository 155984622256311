import { useTranslation } from "@hireroo/i18n";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";

import { useCsvDownloadContext } from "../../PrivateContext";
import FilterDisplay, { FilterDisplayProps } from "./parts/FilterDisplay/FilterDisplay";
import MultipleSelectBox, { MultiSelectBoxProps } from "./parts/MultiSelectBox/MultiSelectBox";

const StyledCenterColumn = styled(Stack)(() => ({
  width: "64px",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
}));

const StyledButton = styled(Button)(() => ({
  textTransform: "none",
}));

export type DownloadDialogContentProps = {
  disabled: boolean;
  errorMessage?: MultiSelectBoxProps["errorMessage"];
  filterDisplay: FilterDisplayProps;
};

const DownloadDialogContent: React.FC<DownloadDialogContentProps> = props => {
  const { t } = useTranslation();
  const { state, updateAddList, updateRemoveList, remove, add } = useCsvDownloadContext();
  const addButton: ButtonProps = {
    disabled: props.disabled || state.willAddList.length === 0,
    variant: "outlined",
    color: "secondary",
    size: "small",
    children: ">",
    onClick: () => {
      add();
    },
  };
  const removeButton: ButtonProps = {
    disabled: props.disabled || state.willRemoveList.length === 0,
    variant: "outlined",
    color: "secondary",
    size: "small",
    children: "<",
    onClick: () => {
      remove();
    },
  };
  const selectableSelectBox: MultiSelectBoxProps = {
    fieldName: t("選択できる項目"),
    items: state.selectableFields,
    onChange: newList => {
      updateAddList(newList);
    },
    disabled: props.disabled,
  };
  const selectedSelectBox: MultiSelectBoxProps = {
    fieldName: t("選択中の項目"),
    items: state.downloadTargetFields,
    onChange: newList => {
      updateRemoveList(newList);
    },
    errorMessage: props.errorMessage,
    disabled: props.disabled,
  };
  return (
    <Stack direction="column" spacing={3} sx={{ height: "100%" }}>
      <FilterDisplay {...props.filterDisplay} />

      <Stack direction="row" spacing={2} sx={{ height: "100%" }}>
        <MultipleSelectBox {...selectableSelectBox} />

        <StyledCenterColumn spacing={1.5}>
          <StyledButton {...addButton} />
          <StyledButton {...removeButton} />
        </StyledCenterColumn>

        <MultipleSelectBox {...selectedSelectBox} />
      </Stack>
    </Stack>
  );
};

DownloadDialogContent.displayName = "DownloadDialogContent";

export default DownloadDialogContent;
