import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";

import Tab from "../../primitive/Tab/Tab";
import Tabs from "../../primitive/Tabs/Tabs";

const TABS_HEIGHT = 36;

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette["Secondary/Shades"].p16,
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

const StyledTabs = styled(Tabs)(() => ({
  height: TABS_HEIGHT,
}));

const StyledTab = styled(Tab)(() => ({
  textTransform: "none",
}));

const StyledContentBox = styled(Box)(() => ({
  height: `calc(100% - ${TABS_HEIGHT}px)`,
  overflow: "hidden",
}));

type TabValue = "WEB_SEARCH" | "CHAT_GPT";

export type ChallengeCodingEditorRightSidePanelProps = {
  WebSearchSidePanel: React.ReactNode;
  ChatGPTSidePanel: React.ReactNode;
  gptModel: "GPT-3.5" | "GPT-4o";
};

const ChallengeCodingEditorRightSidePanel: React.FC<ChallengeCodingEditorRightSidePanelProps> = props => {
  const { WebSearchSidePanel, ChatGPTSidePanel } = props;
  const { t } = useTranslation();

  const initialValue: TabValue = WebSearchSidePanel ? "WEB_SEARCH" : "CHAT_GPT";
  const [value, setValue] = React.useState<TabValue>(initialValue);

  const handleChange = (event: React.SyntheticEvent, newValue: TabValue) => {
    setValue(newValue);
  };

  return (
    <StyledBox>
      <StyledTabs value={value} onChange={handleChange} variant="fullWidth">
        {WebSearchSidePanel && <StyledTab label={t("Google検索")} value={"WEB_SEARCH"} />}
        {ChatGPTSidePanel && <StyledTab label={`ChatGPT (${props.gptModel})`} value={"CHAT_GPT"} />}
      </StyledTabs>
      <StyledContentBox>
        {value === "WEB_SEARCH" && WebSearchSidePanel}
        {value === "CHAT_GPT" && ChatGPTSidePanel}
      </StyledContentBox>
    </StyledBox>
  );
};

ChallengeCodingEditorRightSidePanel.displayName = "ChallengeCodingEditorRightSidePanel";

export default ChallengeCodingEditorRightSidePanel;
