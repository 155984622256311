import { useTranslation } from "@hireroo/i18n";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import * as React from "react";

import { useAlgorithmAnswersFormContext } from "../../../Context";
import AlgorithmTestCaseHeader from "../parts/AlgorithmTestCaseHeader";
import DBTestCaseHeader from "../parts/DBTestCaseHeader";
import PerformanceTableRow, { PerformanceTableRowProps } from "./PerformanceTableRow";

export type PerformanceTableProps = {
  tableRows: Omit<PerformanceTableRowProps, "index">[];
};

const PerformanceTable: React.FC<PerformanceTableProps> = props => {
  const { t } = useTranslation();
  const { signatureProps } = useAlgorithmAnswersFormContext();
  return (
    <TableContainer>
      <Table aria-label="collapsible table">
        {signatureProps.variant === "ALGORITHM" && <AlgorithmTestCaseHeader name={t("ラベル")} signature={signatureProps.signature} />}
        {signatureProps.variant === "DATABASE" && <DBTestCaseHeader name={t("ラベル")} />}

        <TableBody>
          {props.tableRows.map((tableRow, index) => (
            <PerformanceTableRow key={tableRow.id} {...tableRow} index={index} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

PerformanceTable.displayName = "PerformanceTable";

export default PerformanceTable;
