export type EntityType = "challenge" | "project" | "quiz" | "systemDesign";

export * from "./elementScroll";

export const ENTITY_TYPE_MAP = {
  CHALLENGE: "challenge",
  PROJECT: "project",
  QUIZ: "quiz",
  SYSTEM_DESIGN: "systemDesign",
} as const satisfies Record<string, EntityType>;

const entityTypes: EntityType[] = Object.values(ENTITY_TYPE_MAP);

const isEntityType = (entityType: string): entityType is EntityType => {
  return entityTypes.includes(entityType as unknown as EntityType);
};

export type ExtractResult = {
  entityId: number;
  entityType: EntityType | undefined;
};

export const extractEntityFromEncodedString = (key: string): ExtractResult => {
  try {
    const decoded = window.atob(key);
    const [entityType, entityIdString] = decoded.split("-");
    const entityId = Math.trunc(Number(entityIdString));
    return {
      entityId: entityId,
      entityType: isEntityType(entityType) ? entityType : undefined,
    };
  } catch (error) {
    // If it goes into CATCH, the combination of id and entity_type (like: challenge-1000) is invalid,
    // and the user is accessing with an invalid URL and is therefore clenched.
    return { entityId: 0, entityType: undefined };
  }
};

type EntityId = number;
export type UniqueEntity = {
  type: EntityType;
  id: EntityId;
};
export const extractUniqueEntity = (hash: string): UniqueEntity | undefined => {
  let result: UniqueEntity | undefined = undefined;
  entityTypes.forEach(entityType => {
    const regex = new RegExp(String.raw`${entityType}-(\d+)`);
    const match = hash.match(regex);
    if (match) {
      const id = Number(match[1]);
      result = {
        id: id,
        type: entityType,
      };
    }
  });
  return result;
};

export type ShowingTarget = "STATISTICS" | "PLAYBACK" | "ANSWER" | "RANK" | "SCORE";
