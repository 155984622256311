import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Company, Payment } from "@hireroo/app-store/essential/employee";
import { PaymentMethodUpdateForm } from "@hireroo/app-store/widget/e/PaymentMethodUpdateForm";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import { getUpperCaseLanguage, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import { PaymentForm } from "@hireroo/validator";
import * as Sentry from "@sentry/browser";
import { CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import type * as stripeJs from "@stripe/stripe-js";
import * as React from "react";

const PaymentMethodMap: Record<Graphql.PaymentType, PaymentForm.PaymentMethod> = {
  [Graphql.PaymentType.Card]: "CREDIT_CARD",
  [Graphql.PaymentType.Invoice]: "BANK",
  [Graphql.PaymentType.Unknown]: "BANK",
};

export type GeneratePaymentMethodUpdateFormPropsArgs = {};

export const useGenerateProps = (_args: GeneratePaymentMethodUpdateFormPropsArgs): Widget.PaymentMethodUpdateFormProps => {
  const { t } = useTranslation();
  const customer = PaymentMethodUpdateForm.useCustomer();
  const company = Company.useStrictActiveCompany();
  const navigate = useTransitionNavigate();
  const client = getGraphqlClient();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = React.useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState<PaymentForm.PaymentMethod>(PaymentMethodMap[customer.paymentType]);
  const accountTypeDisplayTextMap = Payment.useAccountTypeDisplayTextMap();
  const updateCreditCard = async (
    fields: PaymentForm.PaymentMethodUpdateForm["billingInformation"],
  ): Promise<stripeJs.PaymentMethodResult | undefined> => {
    const card = elements?.getElement(CardNumberElement);
    if (!card || !stripe) {
      return;
    }
    const res = await stripe.createPaymentMethod({
      type: "card",
      card: card,
      billing_details: {
        address: {
          line1: fields.line1,
          city: fields.city,
          postal_code: fields.postalCode,
          state: fields.state,
          /**
           * Default value is Very Important !
           */
          country: customer.country || Graphql.Country.Jp,
        },
        email: fields.mailAddress,
        name: fields.companyName,
      },
    });
    return res;
  };
  const language = React.useMemo(() => {
    if (customer.language === "UNKNOWN") {
      return getUpperCaseLanguage();
    }
    return customer.language;
  }, [customer.language]);

  const needRetryPayment = React.useMemo((): boolean => {
    if (selectedPaymentMethod !== "CREDIT_CARD") {
      return false;
    }
    return customer.paymentStatus === "UNPAID" || customer.paymentStatus === "SUSPENDED";
  }, [customer.paymentStatus, selectedPaymentMethod]);

  return {
    defaultValues: {
      paymentMethod: PaymentMethodMap[customer.paymentType],
      billingInformation: {
        companyName: company.name,
        mailAddress: customer.email,
        postalCode: customer.postalCode,
        state: customer.state,
        city: customer.city,
        line1: customer.line1,
        language: language,
      },
    },
    billingInformationField: {
      companyName: {
        settingPageLink: {
          children: t("一般情報"),
          href: generatePath("/e/settings/company/profile"),
          onClick: () => {
            navigate("/e/settings/company/profile");
          },
        },
      },
    },
    paymentMethod: {
      cardField: {
        lastFour: customer.lastFour,
      },
      bankField: {
        bankName: customer.bankName,
        branchName: `${customer.branchName} (${customer.branchCode})`,
        accountNumber: customer.accountNumber,
        accountHolderName: customer.accountHolderName,
        accountType: accountTypeDisplayTextMap[customer.accountType],
      },
      onChange: paymentMethod => {
        setSelectedPaymentMethod(paymentMethod);
      },
    },
    submitButton: {
      loading,
      children: needRetryPayment ? t("お支払い情報の更新と支払いの再試行をする") : t("更新"),
    },
    onSubmit: async (fields: PaymentForm.PaymentMethodUpdateForm) => {
      setLoading(true);
      let res: stripeJs.PaymentMethodResult | undefined;
      if (fields.paymentMethod === "CREDIT_CARD") {
        res = await updateCreditCard(fields.billingInformation);
        if (res?.error) {
          setLoading(false);
          Snackbar.notify({
            severity: "error",
            message: t("入力内容に不備があります。"),
          });
          return;
        }
      }
      await client
        .UpdatePaymentV2CustomerForPaymentMethodUpdateForm({
          input: {
            customerId: customer.customerId,
            email: fields.billingInformation.mailAddress,
            country: customer.country,
            postalCode: fields.billingInformation.postalCode,
            state: fields.billingInformation.state,
            city: fields.billingInformation.city,
            line1: fields.billingInformation.line1,
            line2: "",
            paymentMethod: res?.paymentMethod?.id || customer.paymentMethod,
            lastFour: res?.paymentMethod?.card?.last4 || customer.lastFour,
            language: fields.billingInformation.language,
            paymentType: fields.paymentMethod === "CREDIT_CARD" ? "CARD" : "INVOICE",
          },
        })
        .then(res => {
          if (res.updatePaymentCustomer.errorCode === "NOT_ALLOWED_FOR_UPDATING_PAYMENT_TYPE") {
            Snackbar.notify({
              severity: "error",
              message: t("登録中のお支払い方法に紐づく請求が含まれるため、お支払い方法の変更は現在できません。"),
            });
          } else {
            PaymentMethodUpdateForm.setCustomer(res.updatePaymentCustomer.customer);
            Snackbar.notify({
              severity: "success",
              message: t("請求情報の保存が完了しました。"),
            });
          }
        })
        .catch(error => {
          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(
            error,
            t("請求情報の更新に失敗しました。しばらくしてから再度お試し頂くか、お問い合わせください。"),
          );
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
        });

      if (!needRetryPayment) {
        setLoading(false);
        return;
      }

      await client
        .RetryPaymentForPaymentMethodUpdateForm({
          input: {
            customerId: customer.customerId,
          },
        })
        .then(() => {
          Snackbar.notify({
            severity: "success",
            message: t("お支払いの再試行に成功しました。"),
          });
        })
        .catch(error => {
          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("お支払いの再試行に失敗しました。"));
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
  };
};
