import { useTranslation } from "@hireroo/i18n";
import * as React from "react";

import RemoteInterviewEmptyContent, {
  RemoteInterviewEmptyContentProps,
} from "../../../../ms-components/Remote/RemoteInterviewEmptyContent/RemoteInterviewEmptyContent";
import RemoteInterviewTab, { RemoteInterviewTabProps } from "../../../../ms-components/Remote/RemoteInterviewTab/RemoteInterviewTab";

export type MainContentProps = {
  emptyContent: RemoteInterviewEmptyContentProps;
  tab: Pick<RemoteInterviewTabProps, "items" | "plusTab" | "currentTab" | "onChangeTab" | "Extra">;
};

const MainContent: React.FC<MainContentProps> = props => {
  const { t } = useTranslation();
  const remoteInterviewTabProps: RemoteInterviewTabProps = {
    ...props.tab,
    plusTab: undefined,
  };

  if (props.tab.items.length === 0) {
    const remoteInterviewEmptyContentProps: RemoteInterviewEmptyContentProps = {
      ...props.emptyContent,
      notificationText: t("面接官が問題を選択するまでお待ち下さい。"),
    };
    return <RemoteInterviewEmptyContent {...remoteInterviewEmptyContentProps} />;
  }

  return <RemoteInterviewTab {...remoteInterviewTabProps} />;
};
MainContent.displayName = "MainContent";

export default MainContent;
