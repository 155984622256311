import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";

import SideBar, { SideBarProps } from "./parts/SideBar/SideBar";

const Wrapper = styled(Box)(({ theme }) => ({
  borderRadius: 8,
  backgroundColor: theme.palette["Background/Paper"].p2,
}));

const StyledContentWrapper = styled(Box)(() => ({
  width: "100%",
  /**
   * このコンポーネント ( StyledContentWrapper ) が親要素の flex container からはみ出て表示されないように、 min-width を明示的に指定。
   * ref: https://zenn.dev/bicstone/articles/flex-item-overflow-hidden
   *      https://www.w3.org/TR/2018/CR-css-flexbox-1-20181119/#min-size-auto
   */
  minWidth: 0,
  overflowX: "clip",
}));

export type UserOrCompanySettingsLayoutProps = {
  HeaderMenu: React.ReactNode;
  sideBar?: SideBarProps;
  children?: React.ReactNode;
};

const UserOrCompanySettingsLayout: React.FC<UserOrCompanySettingsLayoutProps> = props => {
  return (
    <Wrapper p={3}>
      <Box mb={3}>{props.HeaderMenu}</Box>
      <Stack direction="row" spacing={3}>
        {props.sideBar && <SideBar {...props.sideBar} />}
        <StyledContentWrapper>{props.children}</StyledContentWrapper>
      </Stack>
    </Wrapper>
  );
};

UserOrCompanySettingsLayout.displayName = "UserOrCompanySettingsLayout";

export default UserOrCompanySettingsLayout;
