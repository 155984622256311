import { subscribe } from "valtio";
import { subscribeKey } from "valtio/utils";

import { state } from "./State";
import * as Types from "./types";

export type SubscribeListParamsCallback = (listParams: Types.ListParams) => void;

export const subscribeListParams = (callback: SubscribeListParamsCallback) => {
  return subscribeKey(state, "listParams", callback);
};

export type SubscribeStateCallback = (state: Types.State) => void;
export const subscribeAllState = (callback: SubscribeStateCallback) => {
  return subscribe(state, () => {
    return callback(state);
  });
};
