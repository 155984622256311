import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import * as Subscriber from "./Subscriber";
import { useGenerateProps } from "./useGenerateProps";

export type LiveCodingChatWindowContainerProps = {};

const LiveCodingChatWindowContainer: React.FC<LiveCodingChatWindowContainerProps> = () => {
  const liveCodingChatWindowProps = useGenerateProps({});

  React.useEffect(() => {
    const stop = Subscriber.startParticipants();
    return () => {
      stop();
    };
  }, []);

  return (
    <ErrorBoundary>
      <Widget.LiveCodingChatWindow {...liveCodingChatWindowProps} />
    </ErrorBoundary>
  );
};

LiveCodingChatWindowContainer.displayName = "LiveCodingChatWindowContainer";

export default withErrorBoundary(LiveCodingChatWindowContainer, {});
