import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";

import ResourceField from "../../../../../../../../modules/ResourceFieldV2/ResourceFieldV2";
import InputControlTextField, {
  InputControlTextFieldProps,
} from "../../../../../../../../primitive/InputControl/InputControlTextField/InputControlTextField";

const StyledStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: "8px",
}));

export type NumberCustomFieldProps = {
  isRequired: boolean;
  label: string;
  name: string;
  description?: string;
  helperText?: string;
  valueType: "INTEGER" | "FLOAT";
};

const NumberCustomField: React.FC<NumberCustomFieldProps> = props => {
  const inputProps: InputControlTextFieldProps = {
    fullWidth: true,
    variant: "outlined",
    size: "small",
    type: "number",
    helperText: props.helperText,
    inputProps: {
      step: props.valueType === "FLOAT" ? "0.01" : "1",
    },
  };
  return (
    <StyledStack>
      <ResourceField
        kind={props.isRequired ? "REQUIRED" : "OPTIONAL"}
        label={props.label}
        help={{ kind: "DESCRIPTION", text: props.description }}
      >
        <InputControlTextField name={props.name} {...inputProps} />
      </ResourceField>
    </StyledStack>
  );
};

NumberCustomField.displayName = "NumberCustomField";

export default NumberCustomField;
