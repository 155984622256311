import { useSnapshot } from "valtio";

import * as Def from "./constants";
import { state } from "./State";
import * as Types from "./types";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useListParams = () => {
  const snapshot = useSnapshotState();
  return snapshot.listParams;
};

export const useSize = () => {
  const snapshot = useSnapshotState();
  return snapshot.listParams.size;
};

export const usePage = () => {
  const snapshot = useSnapshotState();
  return snapshot.listParams.page;
};

export const useResponse = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.res) {
    throw new Error("Response not found");
  }
  return snapshot.res;
};

export const useFilters = () => {
  const snapshot = useSnapshotState();
  return snapshot.listParams.filters;
};

export const useTags = () => {
  const snapshot = useSnapshotState();
  return snapshot.remoteTags || [];
};
export const useSearchTags = () => {
  const snapshot = useSnapshotState();
  return snapshot.searchTags;
};

export const useCount = () => {
  const snapshot = useSnapshotState();
  return snapshot.count;
};

export const useCreators = () => {
  const snapshot = useSnapshotState();
  return snapshot.creators;
};

export const useCreatorMap = () => {
  const creators = useCreators();
  return creators.reduce<Record<string, Types.Creator | undefined>>((all, creator) => {
    return { ...all, [creator.id]: creator };
  }, {});
};

/**
 *@deprecated use useResponseInitialized instead
 */
export const useInitialized = () => {
  const snapshot = useSnapshotState();
  return snapshot.initialized;
};
export const useResponseInitialized = () => {
  const snapshot = useSnapshotState();
  return snapshot.res !== null;
};

export const useInitializedCurrentSearchFilter = () => {
  const snapshot = useSnapshotState();
  return snapshot.currentSearchFilter !== null;
};

export const useLoadingStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.loadingStatus;
};

export const useCurrentSearchFilter = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.currentSearchFilter) {
    throw new Error("CurrentSearchFilter is not initialized");
  }
  return snapshot.currentSearchFilter;
};

export const usePager = () => {
  const snapshot = useSnapshotState();
  return snapshot.pager;
};
export const useSortParams = () => {
  const snapshot = useSnapshotState();
  return Def.SortParams[snapshot.pager.sortFieldValue];
};
