import { QuizTestReport } from "@hireroo/app-store/view-domain/QuizTestReport";
import { formatScore } from "@hireroo/formatter/score";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";

export type GenerateEntityScoreBoardPropsArgs = {
  quizId: number;
  showScore: boolean;
};
type EntityScoreBoard = Exclude<Widget.QuizTestReportProps["entityScoreBoard"], undefined>;
type Status = EntityScoreBoard["status"];

export const useGenerateEntityScoreBoardProps = (args: GenerateEntityScoreBoardPropsArgs): Widget.QuizTestReportProps["entityScoreBoard"] => {
  const { t } = useTranslation();
  const hooks = QuizTestReport.useCreateQuizHooks(args.quizId);
  const quiz = hooks.useQuiz();

  const statusMap: Record<Graphql.QuizStatus, Status> = {
    CREATED: "EVALUATING",
    STARTED: "EVALUATING",
    EVALUATED: "EVALUATED",
    COMPLETED: "EVALUATING",
    UNKNOWN: "ERROR",
  };

  const difficultyMap: Record<Graphql.Difficulty, EntityScoreBoard["difficulty"]> = {
    EASY: "EASY",
    MEDIUM: "MEDIUM",
    DIFFICULT: "DIFFICULT",
    UNKNOWN: "EASY",
  };

  return {
    difficulty: difficultyMap[quiz.pb_package?.difficulty ?? "UNKNOWN"],
    scoreChart: args.showScore
      ? {
          score: formatScore(quiz.totalScore),
        }
      : undefined,
    scoreBars: args.showScore
      ? [
          {
            score: formatScore(quiz.totalScore),
            title: t("正解率"),
            description: t("提出された設問の正解の割合"),
            color: "secondary",
          },
        ]
      : [],
    submissionOptions: [],
    status: statusMap[quiz.quizStatus],
  };
};
