import { useTranslation } from "@hireroo/i18n";
import { QuestionPackageSearchForm } from "@hireroo/validator";

import { SearchFormProps } from "../../modules/SearchForm/SearchForm";

type FieldProps = SearchFormProps["fields"][0];
export type MultiChoiceFieldProps = Extract<FieldProps, { kind: "MULTI_CHOICE" }>;
export type SingleChoiceFieldProps = Extract<FieldProps, { kind: "SINGLE_CHOICE" }>;
export type GroupMultiChoiceFieldProps = Extract<FieldProps, { kind: "GROUP_MULTI_CHOICE" }>;
export type RangeFieldProps = Extract<FieldProps, { kind: "RANGE" }>;
export type TagFieldProps = Extract<FieldProps, { kind: "TAG" }>;

type FieldKeyName = keyof QuestionPackageSearchForm.QuestionPackageSearchQuery;

const FieldName = {
  TEXT_FILTER: "textFilter",
  DIFFICULTIES: "difficulties",
} satisfies Record<string, FieldKeyName>;

export const useTextFilterField = (): SearchFormProps["textFilter"] => {
  const { t } = useTranslation();
  return {
    name: FieldName.TEXT_FILTER,
    placeholder: t("タイトルを検索"),
  };
};

type DifficultiesOption = {
  displayName: string;
  value: QuestionPackageSearchForm.QuestionPackageSearchQuery["difficulties"][0];
};
export const useDifficultyField = (): MultiChoiceFieldProps => {
  const { t } = useTranslation();
  const options: DifficultiesOption[] = [
    { value: "EASY", displayName: t("易しい") },
    { value: "MEDIUM", displayName: t("ふつう") },
    { value: "DIFFICULT", displayName: t("難しい") },
  ];
  return {
    kind: "MULTI_CHOICE",
    title: t("難易度"),
    name: FieldName.DIFFICULTIES,
    options: options,
  };
};
