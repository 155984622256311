import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type SelectQuestionFromHistoryContainerProps = {};

const SelectQuestionFromAssessmentHistoryContainer: React.FC<SelectQuestionFromHistoryContainerProps> = () => {
  const selectQuestionFromHistoryProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.SelectQuestionFromHistory {...selectQuestionFromHistoryProps} />
    </ErrorBoundary>
  );
};

SelectQuestionFromAssessmentHistoryContainer.displayName = "SelectQuestionFromAssessmentHistoryContainer";

export default withErrorBoundary(SelectQuestionFromAssessmentHistoryContainer, {});
