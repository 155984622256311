import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateEmployeeHeaderRightContentPropsArgs, useGenerateProps } from "./useGenerateProps";

export type EmployeeHeaderRightContentContainerProps = GenerateEmployeeHeaderRightContentPropsArgs;

const EmployeeHeaderRightContentContainer: React.FC<EmployeeHeaderRightContentContainerProps> = props => {
  const employeeHeaderRightContentProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.EmployeeHeaderRightContent {...employeeHeaderRightContentProps} />
    </ErrorBoundary>
  );
};

EmployeeHeaderRightContentContainer.displayName = "EmployeeHeaderRightContentContainer";

export default withErrorBoundary(EmployeeHeaderRightContentContainer, {});
