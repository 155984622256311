import { useTranslation } from "@hireroo/i18n";
import { ScreeningResourceEditorForm } from "@hireroo/validator";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import ResourceField from "../../../../../../modules/ResourceFieldV2/ResourceFieldV2";
import SwitchControl from "../../../../../../primitive/InputControl/SwitchControl/SwitchControl";

const ReservedFieldName = {
  ENABLED_HINT: "enabledHint",
} satisfies Record<string, keyof ScreeningResourceEditorForm.TestQuestionSetupFormSchema>;

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  marginLeft: 0,
  marginRight: 0,
  width: "100%",
  display: "flex",
  flexDirection: "row",
  ".MuiFormControl-root": {
    width: "fit-content",
    marginRight: "8px",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const StyledSwitchControl = styled(SwitchControl)(() => ({
  ".MuiSwitch-input": {
    left: 0,
  },
}));

export type HintControlFieldProps = {};

const HintControlField: React.FC<HintControlFieldProps> = _props => {
  const { t } = useTranslation();

  return (
    <ResourceField
      label={t("ヒント機能")}
      kind="NONE"
      pb={3}
      help={{
        kind: "DESCRIPTION",
        text: t("ヒントを追加すると候補者がコーディング中に詰まったとき、解答に近づく手助けになります。"),
      }}
    >
      <Stack direction="row">
        <Box>
          <StyledFormControlLabel
            control={<StyledSwitchControl name={ReservedFieldName.ENABLED_HINT} size="small" color="secondary" />}
            label={
              <Typography variant="body2" noWrap width="100%">
                {t("ヒントの利用")}
              </Typography>
            }
          />
        </Box>
      </Stack>
    </ResourceField>
  );
};

HintControlField.displayName = "HintControlField";

export default HintControlField;
