import { RemotesId } from "@hireroo/app-store/page/e/remotes_id";
import { QuestionSearchArea } from "@hireroo/app-store/widget/e/QuestionSearchArea";

const { QuestionsTableStore } = RemotesId;

export const startSubscribeQuestionSearchArea = () => {
  const initialState = QuestionSearchArea.Api.getState();
  /**
   * Set up-to-date information prior to the start of Subscribe
   */
  QuestionsTableStore.updateSearchQuery({
    filters: initialState.filters,
    isDescending: initialState.isDescending,
    sortMethod: initialState.sortMethod,
    size: initialState.size,
    page: initialState.page,
  });
  return QuestionSearchArea.subscribeAllState(state => {
    QuestionsTableStore.updateSearchQuery({
      filters: state.filters,
      isDescending: state.isDescending,
      sortMethod: state.sortMethod,
      size: state.size,
      page: state.page,
    });
  });
};
