import { useTranslation } from "@hireroo/i18n";
import Group from "@mui/icons-material/Group";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Chip, { ChipProps } from "@mui/material/Chip";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import TagDialog, { TagDialogProps } from "../../../../modules/TagDialog/TagDialog";
import { ButtonWithTooltipProps } from "../../../../primitive/Button/ButtonWithTooltip/ButtonWithTooltip";
import CopyToClipboardButton, { CopyToClipboardButtonProps } from "../../../../primitive/Button/CopyToClipboardButton/CopyToClipboardButton";
import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "../../../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";
import AccessPermissionDialog, { AccessPermissionDialogProps } from "../../../AccessPermissionDialog/AccessPermissionDialog";
import ListContent, { ListContentProps } from "../ListContent/ListContent";

const StyledTypography = styled(Typography)(() => ({
  fontSize: "14px",
  variant: "subtitle2",
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette["Background/Paper"].p2,
  borderRadius: "16px",
}));

const StyledModeEditOutlinedIcon = styled(ModeEditOutlinedIcon)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const WrapTypography = styled(Typography)({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});

const StyledChip = styled(Chip)(({ theme }) => ({
  height: 30,
  ".MuiChip-deleteIcon": {
    color: theme.palette.secondary.main,
  },
  color: theme.palette.secondary.main,
  borderColor: theme.palette.secondary.main,
}));

type MemberAnGroupChip = ChipProps & {
  photoUrl?: string;
  kind: "MEMBER" | "GROUP";
};

export type RemoteInfoProps = {
  remote: {
    candidateName: string;
    employeeName: string;
    invitationLink: string;
    authorizedMemberAndGroups: MemberAnGroupChip[];
    isPublic: boolean;
    tags: string[];
    hasEnded: boolean;
  };
  copyButton: Pick<CopyToClipboardButtonProps, "onCopy" | "buttonWithTooltip">;
  accessPermissionDialog: AccessPermissionDialogProps;
  tagDialog: TagDialogProps;
};

const RemoteInfo: React.FC<RemoteInfoProps> = props => {
  const { t } = useTranslation();
  const { remote } = props;
  const copyButton: ButtonWithTooltipProps = {
    ...props.copyButton.buttonWithTooltip,
    size: "small",
    variant: "outlined",
    disabled: remote.hasEnded,
  };

  const editTagButton: IconButtonWithTooltipProps = {
    children: <StyledModeEditOutlinedIcon fontSize="small" />,
    onClick: () => {
      props.tagDialog.onOpen?.();
    },
  };
  const editAccessPermissionButton: IconButtonWithTooltipProps = {
    children: <StyledModeEditOutlinedIcon fontSize="small" />,
    onClick: () => {
      props.accessPermissionDialog.onOpen?.();
    },
  };

  const listContents: ListContentProps[] = [
    {
      title: t("候補者名"),
      children: <StyledTypography>{remote?.candidateName ? remote.candidateName : t("候補者が開始前です")}</StyledTypography>,
    },
    {
      title: t("招待者"),
      children: <StyledTypography>{remote.employeeName}</StyledTypography>,
    },
    {
      title: t("招待リンク"),
      children: (
        <Box display="flex" alignItems="center">
          <WrapTypography fontSize={14}>{remote.invitationLink}</WrapTypography>
          <Box flexGrow={1} />
          <Box ml={1}>
            <CopyToClipboardButton
              name={remote.hasEnded ? t("招待済み") : t("コピー")}
              url={remote.invitationLink}
              onCopy={props.copyButton.onCopy}
              buttonWithTooltip={copyButton}
            />
          </Box>
        </Box>
      ),
    },
    {
      title: t("タグ"),
      children: (
        <Box display="flex">
          {remote?.tags.length > 0 ? (
            <Stack direction={"row"} sx={{ flexWrap: "wrap", gap: "8px" }}>
              {remote?.tags.map(tagName => <StyledChip label={tagName} variant="outlined" key={`tag-chip-${tagName}`} />)}
            </Stack>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center">
              <StyledTypography>{t("タグが指定されていません。")}</StyledTypography>
            </Box>
          )}
          <Box flexGrow={1} />
          <IconButtonWithTooltip {...editTagButton} />
        </Box>
      ),
    },
    {
      title: t("閲覧権限"),
      children: (
        <Box display="flex" alignItems="center">
          {remote?.isPublic ? (
            <StyledTypography>{t("メンバー全員に許可する。")}</StyledTypography>
          ) : (
            <Stack direction={"row"} sx={{ flexWrap: "wrap", gap: "8px" }}>
              {props.remote.authorizedMemberAndGroups.map(authorized => {
                const { kind, photoUrl, ...rest } = authorized;
                return <Chip key={authorized.key} {...rest} avatar={kind === "GROUP" ? <Group /> : <Avatar src={photoUrl} />} />;
              })}
            </Stack>
          )}
          <Box flexGrow={1} />
          <IconButtonWithTooltip {...editAccessPermissionButton} />
        </Box>
      ),
    },
  ];

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Typography fontSize={16} sx={{ fontWeight: "bold" }}>
          {t("テスト情報")}
        </Typography>
      </Box>
      <StyledBox>
        <List component="nav" aria-label="coding-test-summary" sx={{ px: 2 }}>
          {listContents.map((content, index) => (
            <ListContent key={content.title} {...content} divider={index !== listContents.length - 1} />
          ))}
        </List>
      </StyledBox>
      <TagDialog key={`tag-dialog-open-${props.tagDialog.open.toString()}`} {...props.tagDialog} />
      <AccessPermissionDialog
        key={`access-permission-dialog-open-${props.accessPermissionDialog.open.toString()}`}
        {...props.accessPermissionDialog}
      />
    </Box>
  );
};

RemoteInfo.displayName = "RemoteInfo";

export default RemoteInfo;
