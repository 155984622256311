import { generateTimestampFromDate } from "@hireroo/app-helper/parser";
import { SystemDesignTestReport } from "@hireroo/app-store/view-domain/SystemDesignTestReport";
import { TotalScoreRankVisualizer } from "@hireroo/app-store/widget/shared/TotalScoreRankVisualizer";
import { getGraphqlClient } from "@hireroo/graphql/client/request";

export type SubscribeQueryKeyArgs = {
  uniqueKey: SystemDesignTestReport.UniqueKey;
  companyId: number;
  entityId: number;
  submissionId: number;
  questionId: number;
};

const NUM_BINS = 20;
const COMPARISON_THRESHOLD = 30;
export const startSubscribeQueryKey = (args: SubscribeQueryKeyArgs) => {
  const client = getGraphqlClient();
  const { uniqueKey, entityId } = args;
  return TotalScoreRankVisualizer.subscribeQueryKey(uniqueKey, async (query, shouldRefresh) => {
    if (!shouldRefresh && SystemDesignTestReport.Api.hasStatistics(entityId, query)) {
      SystemDesignTestReport.setQuery(entityId, query);
      return;
    }
    client
      .GetSystemDesignStatistics({
        statisticsSource: {
          category: "SYSTEM_DESIGN",
          query: {
            systemDesign: {
              companyId: query.scope === "COMPANY" ? args.companyId : undefined,
              minTimestamp: query.enableDate && query.startDate ? generateTimestampFromDate(query.startDate) : undefined,
              maxTimestamp: query.enableDate && query.endDate ? generateTimestampFromDate(query.endDate) : undefined,
              numBins: NUM_BINS,
              questionId: args.questionId,
            },
          },
        },
        rankSource: {
          category: "SYSTEM_DESIGN",
          query: {
            systemDesign: {
              companyId: query.scope === "COMPANY" ? args.companyId : undefined,
              minTimestamp: query.enableDate && query.startDate ? generateTimestampFromDate(query.startDate) : undefined,
              maxTimestamp: query.enableDate && query.endDate ? generateTimestampFromDate(query.endDate) : undefined,
              submissionId: args.submissionId,
            },
          },
        },
      })
      .then(res => {
        const numSubset = res.statistics.systemDesignStatistics?.bins.reduce((a, b) => a + b, 0) ?? 0;
        TotalScoreRankVisualizer.setResult(uniqueKey, query, {
          status: numSubset < COMPARISON_THRESHOLD ? "NOT_ENOUGH" : "SATISFY",
          statistics: res.statistics.systemDesignStatistics,
          rank: res.rank,
        });
        SystemDesignTestReport.setStatistics(entityId, query, res.statistics.systemDesignStatistics);
      })
      .catch(() => {
        TotalScoreRankVisualizer.setResult(uniqueKey, query, {
          status: "ERROR",
          statistics: null,
          rank: null,
        });
      });
  });
};
