import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import * as React from "react";

export type CreditCardIconProps = {};

const CreditCardIcon: React.FC<CreditCardIconProps> = () => {
  return (
    <>
      <svg fill="none" width="0" height="0">
        <defs>
          <linearGradient id="creditCardLinearGradient" x1="31" y1="17" x2="3" y2="17" gradientUnits="userSpaceOnUse">
            <stop stopColor="#504BD3" />
            <stop offset="1" stopColor="#AA26FC" />
          </linearGradient>
        </defs>
      </svg>
      <CreditCardOutlinedIcon sx={{ fill: "url(#creditCardLinearGradient)", width: 34, height: 34 }} />
    </>
  );
};

CreditCardIcon.displayName = "CreditCardIcon";

export default CreditCardIcon;
