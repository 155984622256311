import { INITIAL_VERSION } from "@hireroo/app-definition/question";
import { FeedbackLinkFactory } from "@hireroo/app-helper/question";
import { Credential } from "@hireroo/app-store/essential/shared";
import { QuizTestReport } from "@hireroo/app-store/view-domain/QuizTestReport";
import { formateRateToIntegerPercent } from "@hireroo/formatter/rate";
import * as Time from "@hireroo/formatter/time";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import { generateCurrentOriginUrl, generatePath } from "@hireroo/router/api";
import * as React from "react";

type Item = Widget.QuizTestReportProps["questionDetail"]["questionItems"][0];

export type GenerateQuestionDetailPropsArgs = {
  quizId: number;
  showAnswer: boolean;
  showArchivedMark: boolean;
};

export const useGenerateQuestionDetailProps = (args: GenerateQuestionDetailPropsArgs): Widget.QuizTestReportProps["questionDetail"] => {
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const currentUserMailAddress = Credential.useCurrentUserMailAddress();
  const hooks = QuizTestReport.useCreateQuizHooks(args.quizId);
  const quiz = hooks.useQuiz();
  const elapseTimeObject = React.useMemo(() => {
    if (!quiz.pb_package?.averageElapsedTimeSeconds) return undefined;
    return Time.secondsToTimeObject(quiz.pb_package?.averageElapsedTimeSeconds, "MINUTES");
  }, [quiz.pb_package?.averageElapsedTimeSeconds]);

  return {
    title: quiz.pb_package ? resolveLanguage(quiz.pb_package, lang, "title") : "",
    openButton: args.showAnswer
      ? {
          href: generatePath("/e/questions/quiz/:id", {
            pathParams: { id: quiz?.pb_package?.packageId.toString() ?? "" },
            queryParams: { version: quiz?.pb_package?.version ?? INITIAL_VERSION },
          }),
        }
      : undefined,
    isArchived: ((): boolean => {
      if (!args.showArchivedMark) {
        return false;
      }
      return quiz.pb_package?.multiChoicePackageStatus === "ARCHIVED";
    })(),
    showAnswer: args.showAnswer,
    questionItems: (quiz.pb_package?.questions || []).map((q, index): Item => {
      const contentMap = {
        SINGLE_CHOICE: {
          kind: "SINGLE_CHOICE",
          content: {
            id: q.id.toString(),
            header: {
              index: index + 1,
              title: resolveLanguage(q, lang, "title"),
              description: resolveLanguage(q, lang, "description"),
              correctIntegerRate: formateRateToIntegerPercent(q.correctAnswerRate),
            },
            items: q.options.map((option): Extract<Item, { kind: "SINGLE_CHOICE" }>["content"]["items"][0] => ({
              value: option.id.toString(),
              title: resolveLanguage(option, lang, "title"),
              description: args.showAnswer ? resolveLanguage(option, lang, "additionalDetail") : undefined,
            })),
            selected: args.showAnswer ? q.options.find(o => o.isCorrect)?.id.toString() ?? "" : undefined,
          },
        },
        MULTI_CHOICE: {
          kind: "MULTI_CHOICE",
          content: {
            id: q.id.toString(),
            header: {
              index: index + 1,
              title: resolveLanguage(q, lang, "title"),
              description: resolveLanguage(q, lang, "description"),
              correctIntegerRate: formateRateToIntegerPercent(q.correctAnswerRate),
            },
            items: q.options.map((option): Extract<Item, { kind: "MULTI_CHOICE" }>["content"]["items"][0] => ({
              title: resolveLanguage(option, lang, "title"),
              description: args.showAnswer ? resolveLanguage(option, lang, "additionalDetail") : undefined,
              checked: args.showAnswer ? option.isCorrect : false,
            })),
            skipped: false,
          },
        },
        FREE_TEXT: {
          kind: "FREE_TEXT",
          content: {
            id: q.id.toString(),
            header: {
              index: index + 1,
              title: resolveLanguage(q, lang, "title"),
              description: resolveLanguage(q, lang, "description"),
              correctIntegerRate: formateRateToIntegerPercent(q.correctAnswerRate),
            },
            items: args.showAnswer
              ? q.evaluationItems.map(
                  (
                    option,
                  ): Exclude<
                    Extract<
                      Item,
                      {
                        kind: "FREE_TEXT";
                      }
                    >["content"]["items"],
                    undefined
                  >[0] => ({
                    title: resolveLanguage(option, lang, "title"),
                  }),
                )
              : undefined,
          },
        },
        UNKNOWN: {
          kind: "FREE_TEXT",
          content: {
            id: q.id.toString(),
            header: {
              index: index,
              title: resolveLanguage(q, lang, "title"),
              description: resolveLanguage(q, lang, "description"),
              correctIntegerRate: undefined,
            },
            items: [],
          },
        },
      } satisfies Record<Graphql.MultiChoiceQuestionVariant, Item>;
      return contentMap[q.variant];
    }),
    description: quiz.pb_package ? resolveLanguage(quiz.pb_package, lang, "description") : "",
    difficulty: quiz.pb_package?.difficulty ?? "UNKNOWN",
    info: {
      feedbackLink: FeedbackLinkFactory.generateFeedbackLink({
        mailAddress: currentUserMailAddress,
        targetUrl: generateCurrentOriginUrl("/e/questions/quiz/:id", {
          pathParams: { id: quiz?.pb_package?.packageId.toString() ?? "" },
          queryParams: { version: quiz?.pb_package?.version ?? INITIAL_VERSION },
        }),
      }),
      stats: {
        avgCorrectIntegerRate:
          quiz.pb_package && quiz.pb_package.numUses > 0
            ? {
                kind: "VALUE",
                value: formateRateToIntegerPercent(quiz.pb_package.accuracyRate),
              }
            : { kind: "NONE" },
        numOfUsage: quiz.pb_package?.numUses?.toLocaleString() ?? "-",
        avgElapseTime:
          quiz.pb_package && elapseTimeObject && quiz.pb_package.numUses > 0
            ? {
                kind: "VALUE",
                minutes: elapseTimeObject.minutes,
                seconds: elapseTimeObject.seconds,
              }
            : { kind: "NONE" },
      },
      detailInfo: {
        difficultyStars: {
          difficulty: quiz.pb_package?.difficulty ?? "UNKNOWN",
        },
        timelimitMinutes: 0,
        createdBy: quiz.pb_package?.isOfficial
          ? { kind: "OFFICIAL" }
          : {
              kind: "PRIVATE",
              displayName: quiz.pb_package?.employee.displayName ?? t("不明"),
            },
        numOfQuestions: { value: quiz.pb_package?.questions.length ?? 0 },
      },
    },
  };
};
