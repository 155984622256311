import { useTranslation } from "@hireroo/i18n";
import { PaymentForm } from "@hireroo/validator";
import Box, { BoxProps } from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup, { RadioGroupProps } from "@mui/material/RadioGroup";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import BankField, { BankFieldProps } from "../BankField/BankField";
import StripeSplitCardElementField, { StripeSplitCardElementFieldProps } from "./parts/StripeSplitCardElementField/StripeSplitCardElementField";

const LEFT_WIDTH = 38;

const PaddingWrapper = styled(Box)(() => ({
  paddingLeft: LEFT_WIDTH,
  width: "100%",
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.Other.OutlinedBorder}`,
  borderRadius: 8,
  padding: theme.spacing(2),
  width: "100%",
  overflow: "hidden",
}));

const PaymentMethodValues: Record<PaymentForm.PaymentMethod, PaymentForm.PaymentMethod> = {
  BANK: "BANK",
  CREDIT_CARD: "CREDIT_CARD",
};

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  "&.MuiFormControlLabel-root": {
    marginLeft: 0,
  },
}));

export type PaymentMethodFieldProps = {
  name: string;
  cardField: Omit<StripeSplitCardElementFieldProps, "disabled">;
  mb?: BoxProps["mb"];
  bankField: BankFieldProps;
  onChange?: (paymentMethod: PaymentForm.PaymentMethod) => void;
};

/**
 * @example https://stripe.com/docs/stripe-js/react
 */
const PaymentMethodField: React.FC<PaymentMethodFieldProps> = props => {
  const { t } = useTranslation();
  const methods = useFormContext<Record<string, PaymentForm.PaymentMethod>>();
  const paymentMethod = methods.watch(props.name);
  const paymentMethodValidator = PaymentForm.usePaymentMethod();
  const handleChange: Exclude<RadioGroupProps["onChange"], undefined> = (_, value) => {
    const result = paymentMethodValidator.safeParse(value);
    if (result.success) {
      methods.setValue(props.name, result.data);
      props.onChange?.(result.data);
    }
  };
  const stripeSplitCardElementFieldProps: StripeSplitCardElementFieldProps = {
    ...props.cardField,
    disabled: paymentMethod !== PaymentMethodValues.CREDIT_CARD,
  };
  return (
    <Box mb={props.mb}>
      <RadioGroup name={props.name} onChange={handleChange}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <StyledFormControlLabel
            label={t("クレジットカード")}
            checked={paymentMethod === PaymentMethodValues.CREDIT_CARD}
            value={PaymentMethodValues.CREDIT_CARD}
            control={<Radio color="secondary" size="small" />}
          />
        </Box>
        {paymentMethod === PaymentMethodValues.CREDIT_CARD && (
          <PaddingWrapper>
            <ContentWrapper>
              <StripeSplitCardElementField {...stripeSplitCardElementFieldProps} />
            </ContentWrapper>
          </PaddingWrapper>
        )}

        <Box display="flex" flexDirection="row" alignItems="center" mt={2}>
          <StyledFormControlLabel
            label={t("銀行振込")}
            checked={paymentMethod === PaymentMethodValues.BANK}
            value={PaymentMethodValues.BANK}
            control={<Radio color="secondary" size="small" />}
          />
        </Box>
        {paymentMethod === PaymentMethodValues.BANK && (
          <PaddingWrapper>
            <ContentWrapper>
              <BankField {...props.bankField} />
            </ContentWrapper>
          </PaddingWrapper>
        )}
      </RadioGroup>
    </Box>
  );
};

PaymentMethodField.displayName = "PaymentMethodField";

export default PaymentMethodField;
