import { INITIAL_VERSION } from "@hireroo/app-definition/question";
import type { QuestionSelectFieldForResourceEditorSchema } from "@hireroo/validator";
import { useSnapshot } from "valtio";

import { state } from "./State";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = (): boolean => {
  const snapshot = useSnapshotState();
  return !!snapshot.questionPackage;
};

export const useQuestionPackage = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.questionPackage) throw new Error("questionPackage is not initialized");
  return snapshot.questionPackage;
};

export const useSelectedQuestionObjectIds = () => {
  const snapshot = useSnapshotState();
  return snapshot.selectedQuestionObjectIds;
};

export const useSelectedQuestionIdentities = (): QuestionSelectFieldForResourceEditorSchema.QuestionCompositePrimaryKeys => {
  const snapshot = useSnapshotState();
  if (!snapshot.questionPackage) {
    return [];
  }

  return snapshot.selectedQuestionObjectIds.reduce<QuestionSelectFieldForResourceEditorSchema.QuestionCompositePrimaryKeys>(
    (all, selectedQuestionObjectId) => {
      const questionObject = snapshot.questionPackage?.questionObjects.find(
        questionObject => questionObject.questionObjectId === selectedQuestionObjectId,
      );
      if (questionObject) {
        const questionId: number = ((): number => {
          const { question } = questionObject;
          switch (question.__typename) {
            case "AlgorithmQuestion":
            case "ProjectQuestion":
            case "SystemDesignQuestion":
              return question.questionId;
            case "FreepadQuestion":
              return question.freepadQuestionId;
            case "MultiChoicePackage":
              return question.packageId;
            default:
              throw new Error(`Question Type is invalid: ${question satisfies never}`);
          }
        })();
        all.push({
          questionId: questionId,
          version: questionObject.version ?? INITIAL_VERSION,
          questionVariant: questionObject.questionVariant,
        });
      }
      return all;
    },
    [],
  );
};

export const useSelectedKind = () => {
  const snapshot = useSnapshotState();
  return snapshot.selectedKind;
};
