import { useTranslation } from "@hireroo/i18n";
import Markdown from "@hireroo/markdown-v2/react";
import CancelRounded from "@mui/icons-material/CancelRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import { alpha, Palette, styled } from "@mui/material/styles";
import * as React from "react";

type ActionState = keyof Pick<Palette, "success" | "warning" | "error">;
type OptionStatus = "CORRECT" | "INCORRECT" | "SKIP";

const StyledFormControlLabel = styled(FormControlLabel)<{ ownerState: { state?: ActionState } }>(({ theme, ownerState }) => ({
  width: "100%",
  marginTop: "10px",
  backgroundColor: ownerState.state ? alpha(theme.palette[ownerState.state].light, 0.5) : "inherit",
}));

const OptionIconMap = {
  CORRECT: <CheckCircleIcon fontSize="small" color="success" />,
  INCORRECT: <CancelRounded fontSize="small" color="error" />,
};

const StatusToPalletteMap: Record<OptionStatus, ActionState> = {
  CORRECT: "success",
  INCORRECT: "error",
  SKIP: "warning",
};

type OptionItem = {
  id: number;
  title: string;
  status: OptionStatus;
};

export type PlaybackContentSingleOptionProps = {
  status: "SELECTING" | "CONFIRM";
  submittedOptionId?: number;
  selectedOptionId?: number;
  optionItems: OptionItem[];
  skipOptionId: number;
};

const PlaybackContentSingleOption: React.FC<PlaybackContentSingleOptionProps> = props => {
  const { t } = useTranslation();

  return (
    <RadioGroup aria-label="options" name="quiz" value={props.selectedOptionId !== undefined ? String(props.selectedOptionId) : ""}>
      {props.optionItems.map(option => (
        <Stack key={`playback-optionId-${option.id}`} direction="row" alignItems="center" spacing={1}>
          {option.status === "CORRECT" ? OptionIconMap["CORRECT"] : OptionIconMap["INCORRECT"]}
          <StyledFormControlLabel
            // Radio form passed "string" so value is "string"
            key={option.id}
            value={String(option.id)}
            control={<Radio color={props.status === "SELECTING" ? "default" : "secondary"} readOnly />}
            label={
              <React.Suspense>
                <Markdown children={option.title} size="small" />
              </React.Suspense>
            }
            ownerState={{
              state: props.submittedOptionId && option.id === props.submittedOptionId ? StatusToPalletteMap[option.status] : undefined,
            }}
          />
        </Stack>
      ))}
      <Stack direction="row" alignItems="center" spacing={1}>
        {OptionIconMap["INCORRECT"]}
        <StyledFormControlLabel
          value={String(props.skipOptionId)}
          control={<Radio color={props.status === "CONFIRM" ? "default" : "secondary"} readOnly />}
          label={t("この問題をスキップ")}
          ownerState={{
            state: String(props.skipOptionId) === String(props.submittedOptionId) ? StatusToPalletteMap["SKIP"] : undefined,
          }}
        />
      </Stack>
    </RadioGroup>
  );
};

PlaybackContentSingleOption.displayName = "PlaybackContentSingleOption";

export default PlaybackContentSingleOption;
