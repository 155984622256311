import { INITIAL_VERSION } from "@hireroo/app-definition/question";
import { RemoteInterviewOverview } from "@hireroo/app-store/widget/e/RemoteInterviewOverview";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";

type Question = Widget.RemoteInterviewOverviewProps["questions"][0];

export type GenerateQuestionsPropsArgs = {};

export const useGenerateQuestionsProps = (_args: GenerateQuestionsPropsArgs): Widget.RemoteInterviewOverviewProps["questions"] => {
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const interview = RemoteInterviewOverview.useInterview();

  return (interview.entity?.liveCoding?.sessions || []).map((session): Question => {
    if (session && session.questionType === "ALGORITHM" && session.algorithmQuestion) {
      const question = session.algorithmQuestion;
      return {
        id: session.id,
        variantText: t("コーディング形式"),
        version: question.version ?? INITIAL_VERSION,
        title: resolveLanguage(question, lang, "title"),
        difficultyStars: {
          difficulty: question.difficulty,
        },
        detailButton: {
          href: generatePath("/e/questions/challenge/:id", {
            pathParams: {
              id: question.questionId.toString(),
            },
            queryParams: {
              version: question.version,
            },
          }),
        },
        isArchived: question.status === "ARCHIVED",
      };
    } else if (session && session.questionType === "SYSTEM_DESIGN" && session.systemDesignQuestion) {
      const question = session.systemDesignQuestion;
      return {
        id: session.id,
        variantText: t("システムデザイン形式"),
        version: INITIAL_VERSION,
        title: resolveLanguage(question, lang, "title"),
        difficultyStars: {
          difficulty: question.difficulty,
        },
        detailButton: {
          href: generatePath("/e/questions/systemdesign/:id", {
            pathParams: {
              id: question.questionId.toString(),
            },
          }),
        },
        isArchived: question.status === "ARCHIVED",
      };
    } else if (session && session?.questionType === "FREEPAD" && session.freepadQuestion) {
      const question = session.freepadQuestion;
      return {
        id: session.id,
        variantText: t("ライブコーディング"),
        version: question.version ?? INITIAL_VERSION,
        title: resolveLanguage(question, lang, "title"),
        difficultyStars: {
          difficulty: question.difficulty,
        },
        detailButton: {
          href: generatePath("/e/questions/freepad/:id", {
            pathParams: {
              id: question.freepadQuestionId.toString(),
            },
            queryParams: {
              version: question.version,
            },
          }),
        },
        isArchived: question.status === "ARCHIVED",
      };
    } else if (session && session.questionType === "EMPTY_PAD") {
      return {
        id: session.id,
        variantText: t("ドローイングツール"),
        version: INITIAL_VERSION,
        title: t("無題のコーディング問題"),
        /**
         * No archive status exists in the drawing tool.
         */
        isArchived: false,
      };
    } else if (session && session.questionType === "EMPTY_CANVAS") {
      return {
        id: session.id,
        variantText: t("コーディングエディタ"),
        version: INITIAL_VERSION,
        title: t("無題のシステムデザイン問題"),
        /**
         * No archive status exists in the Coding Editor.
         */
        isArchived: false,
      };
    } else {
      throw new Error("Unknown Session question type");
    }
  });
};
