import { useSnapshot } from "valtio";

import { state } from "./State";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useTemplates = () => {
  const snapshot = useSnapshotState();
  return Array.from(snapshot.templates.values());
};

export const useCurrentPager = () => {
  const snapshot = useSnapshotState();
  return snapshot.currentPager;
};

export const useNextPager = () => {
  const snapshot = useSnapshotState();
  return snapshot.nextPager;
};

export const useFetchable = () => {
  const snapshot = useSnapshotState();
  if (snapshot.res === null) {
    return true;
  }
  return snapshot.nextPager?.hasNext === true;
};

export const useTemplate = (templateId: number | null) => {
  const snapshot = useSnapshotState();
  if (templateId !== null) {
    return snapshot.templates.get(templateId);
  }
};

export const useSelectedTemplate = () => {
  const snapshot = useSnapshotState();
  if (snapshot.selectedTemplateId === null) return null;
  return snapshot.templates.get(snapshot.selectedTemplateId);
};

export const useStep = () => {
  const snapshot = useSnapshotState();
  return snapshot.step;
};
export const useTemplateVariables = () => {
  const snapshot = useSnapshotState();
  return Array.from(snapshot.variableWithValue.entries()).map(([key, value]) => ({ key, value }));
};
