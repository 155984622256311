import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateProjectPlaybackSettingsMenuPropsArgs, useGenerateProps } from "./useGenerateProps";

export type ProjectPlaybackSettingsMenuContainerProps = GenerateProjectPlaybackSettingsMenuPropsArgs;

const ProjectPlaybackSettingsMenuContainer: React.FC<ProjectPlaybackSettingsMenuContainerProps> = props => {
  const projectPlaybackSettingsMenuProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ProjectPlaybackSettingsMenu {...projectPlaybackSettingsMenuProps} />
    </ErrorBoundary>
  );
};

ProjectPlaybackSettingsMenuContainer.displayName = "ProjectPlaybackSettingsMenuContainer";

export default withErrorBoundary(ProjectPlaybackSettingsMenuContainer, {});
