import { Role } from "@hireroo/app-store/essential/employee";
import { RemotesStore } from "@hireroo/app-store/page/e/remotes";
import { EmployeeSideBarContent } from "@hireroo/app-store/widget/e/EmployeeSideBarContent";
import type { Pages } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import EmployeeHeaderRightContentContainer from "../../../../widget/v2/e/EmployeeHeaderRightContent/Container";
import EmployeeSideBarContentContainer from "../../../../widget/v2/e/EmployeeSideBarContent/Container";
import FooterContainer from "../../../../widget/v2/e/Footer/Container";
import { useNotificationBanner } from "../../../../widget/v2/e/NotificationBanner/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import * as PrivateHelper from "./privateHelper";
import RemoteInterviewListFetchContainer from "./widgets/RemoteInterviewList/FetchContainer";
import RemoteInterviewSearchAreaFetchContainer from "./widgets/RemoteInterviewSearchArea/FetchContainer";

export type GenerateRemoteInterviewListPropsArgs = {};

export const useGenerateProps = (_args: GenerateRemoteInterviewListPropsArgs): Pages.RemoteInterviewListProps => {
  const navigate = useTransitionNavigate();
  const NotificationBannerContainer = useNotificationBanner();
  const matchingRole = Role.useMatchingRole();
  const [searchParams] = useSearchParams();
  const initializedCurrentSearchFilter = RemotesStore.useInitializedCurrentSearchFilter();

  React.useEffect(() => {
    RemotesStore.initializeFilters(
      {
        tags: PrivateHelper.convertStringsToTagNames(searchParams.getAll(RemotesStore.QueryKeys.TAG)),
        statuses: PrivateHelper.convertStringsToStatuses(searchParams.getAll(RemotesStore.QueryKeys.STATUS)),
        createdBy: PrivateHelper.convertStringsToUids(searchParams.getAll(RemotesStore.QueryKeys.ISSUER)),
        result: PrivateHelper.convertStringToResult(searchParams.get(RemotesStore.QueryKeys.RESULT)),
        textFilter: searchParams.get(RemotesStore.QueryKeys.TITLE) ?? "",
      },
      {
        page: PrivateHelper.convertStringsToPage(searchParams.get(RemotesStore.QueryKeys.PAGE)),
        size: PrivateHelper.convertStringToSize(searchParams.get(RemotesStore.QueryKeys.SIZE)),
        sortFieldValue: PrivateHelper.convertStringToSortFieldValue(searchParams.get(RemotesStore.QueryKeys.SORT)),
      },
    );
  }, [searchParams]);

  return {
    layout: {
      openSidebar: EmployeeSideBarContent.useOpenSidebar(),
      onChangeOpenSidebar: open => {
        EmployeeSideBarContent.setOpenSidebar(open);
      },
      NotificationBanner: NotificationBannerContainer,
      HeaderRightContent: <EmployeeHeaderRightContentContainer />,
      SideBarContent: <EmployeeSideBarContentContainer />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      sideBarHeader: {
        logo: {
          href: generatePath("/e/home"),
          onClick: () => {
            navigate("/e/home");
          },
        },
      },
    },
    createButton: {
      onClick: () => {
        navigate("/e/remotes/create");
      },
      disabled: !matchingRole.ADMIN_OR_MANAGER.matched,
      title: matchingRole.ADMIN_OR_MANAGER.messageOnUnmatched || "",
    },
    SearchArea: initializedCurrentSearchFilter ? <RemoteInterviewSearchAreaFetchContainer /> : null,
    List: initializedCurrentSearchFilter ? <RemoteInterviewListFetchContainer /> : null,
  };
};
