import { useTranslation } from "@hireroo/i18n";
import ArrowBackOutlined from "@mui/icons-material/ArrowBackOutlined";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";
import FillConfirmContent, { FillConfirmContentProps } from "./parts/FillConfirmContent/FillConfirmContent";
import StepDialog, { StepDialogProps } from "./parts/StepDialog/StepDialog";

type Step = "CHOICE" | "CONFIRM";

export type FillTemplateDialogProps = {
  step: Step;
  ChoiceContent: React.ReactNode;
  createButton: Pick<ButtonProps, "onClick">;
  backButton: Pick<IconButtonWithTooltipProps, "onClick">;
  confirmContent: FillConfirmContentProps;
  onSubmit: () => void;
  open: StepDialogProps["open"];
  onClose: StepDialogProps["onClose"];
};

const FillTemplateDialog: React.FC<FillTemplateDialogProps> = props => {
  const { t } = useTranslation();
  const createButton: ButtonProps = {
    ...props.createButton,
    size: "small",
    variant: "contained",
    color: "secondary",
    children: t("テンプレート新規作成"),
  };
  const stepMap: Record<Step, Omit<React.PropsWithChildren<StepDialogProps>, "open" | "onClose">> = {
    CHOICE: {
      TitleContent: (
        <Typography fontSize={14} fontWeight={500}>
          {t("テンプレート選択")}
        </Typography>
      ),
      children: (
        <Stack px={3} pt={2} spacing={2}>
          <Box>
            <Button {...createButton} />
          </Box>
          {props.ChoiceContent}
        </Stack>
      ),
    },
    CONFIRM: {
      TitleContent: (
        <IconButtonWithTooltip title={t("戻る")} sx={{ width: "36px", height: "36px" }} {...props.backButton}>
          <ArrowBackOutlined fontSize="small" />
        </IconButtonWithTooltip>
      ),
      children: <FillConfirmContent {...props.confirmContent} />,
      yesButton: {
        children: t("テンプレート適用"),
        onClick: props.onSubmit,
        color: "secondary",
      },
    },
  };
  const stepDialogProps: StepDialogProps = {
    ...stepMap[props.step],
    open: props.open,
    onClose: props.onClose,
  };
  return <StepDialog {...stepDialogProps} />;
};

FillTemplateDialog.displayName = "FillTemplateDialog";

export default FillTemplateDialog;
