const hexToRgb = (hex: string): number[] => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (!result) {
    return [0, 0, 0];
  }

  return [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)];
};

const rgb2hex = (r: number, g: number, b: number) => {
  const digits = (n: number) => {
    const m = Math.round(255 * n).toString(16);
    return m.length === 1 ? "0" + m : m;
  };
  return "#" + digits(r) + digits(g) + digits(b);
};

const hsl2hex = (h: number, s: number, l: number): string => {
  if (s === 0) {
    return rgb2hex(l, l, l);
  }
  const var2 = l < 0.5 ? l * (1 + s) : l + s - s * l;
  const var1 = 2 * l - var2;
  const hue2rgb = function (hue: number) {
    if (hue < 0) {
      hue += 1;
    }
    if (hue > 1) {
      hue -= 1;
    }
    if (6 * hue < 1) {
      return var1 + (var2 - var1) * 6 * hue;
    }
    if (2 * hue < 1) {
      return var2;
    }
    if (3 * hue < 2) {
      return var1 + (var2 - var1) * 6 * (2 / 3 - hue);
    }
    return var1;
  };
  return rgb2hex(hue2rgb(h + 1 / 3), hue2rgb(h), hue2rgb(h - 1 / 3));
};

// colorFromUserId generates a color code given the user ID
// it's the same logic as firepad
// ref: https://github.com/FirebaseExtended/firepad/blob/f34c003bc76210b61c24ae8a326d4bd24a9ae4d0/lib/firepad.js#L524
export const colorFromUserId = (userId: string): string => {
  let a = 1;
  for (let i = 0; i < userId.length; i++) {
    a = (17 * (a + userId.charCodeAt(i))) % 360;
  }
  const hue = a / 360;

  return hsl2hex(hue, 1, 0.75);
};

export const brightness = (hex: string): number => {
  const rgb = hexToRgb(hex);
  return Math.round((rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000);
};
