import { ScreeningsStore } from "@hireroo/app-store/page/e/screenings";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

export type GenerateScreeningSearchAreaPropsArgs = {};

export const useGenerateProps = (_args: GenerateScreeningSearchAreaPropsArgs): Widget.ScreeningSearchAreaProps => {
  const tags = ScreeningsStore.useTags();
  const currentSearchFilter = ScreeningsStore.useCurrentSearchFilter();
  const initialized = ScreeningsStore.useInitialized();

  const defaultValues = React.useMemo((): Widget.ScreeningSearchAreaProps["defaultValues"] => {
    return {
      activeStatus: currentSearchFilter.activeStatus,
      tags: currentSearchFilter.tags.slice(),
      textFilter: currentSearchFilter.textFilter,
    };
  }, [currentSearchFilter]);

  return {
    disabled: !initialized,
    tagsField: {
      selectableTags: tags.map(tag => ({ displayName: tag.name })),
    },
    defaultValues: defaultValues,
    onChange: React.useCallback(fields => {
      ScreeningsStore.updateCurrentSearchFilter(fields);
      /**
       * clear selected spot ids to refresh state inside the list
       */
      ScreeningsStore.clearSelectedScreeningIds();
    }, []),
  };
};
