import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateEvaluationMetricsGroupsPropsArgs, useGenerateProps } from "./useGenerateProps";

export type EvaluationMetricsGroupsContainerProps = GenerateEvaluationMetricsGroupsPropsArgs;

const EvaluationMetricsGroupsContainer: React.FC<EvaluationMetricsGroupsContainerProps> = props => {
  const evaluationMetricsGroupsProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.EvaluationMetricsGroups {...evaluationMetricsGroupsProps} />
    </ErrorBoundary>
  );
};

EvaluationMetricsGroupsContainer.displayName = "EvaluationMetricsGroupsContainer";

export default withErrorBoundary(EvaluationMetricsGroupsContainer, {});
