import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";

import TalentLayout, { TalentLayoutProps } from "../../layouts/TalentLayout/TalentLayout";
import UserOrCompanySettingsLayout, {
  UserOrCompanySettingsLayoutProps,
} from "../../layouts/UserOrCompanySettingsLayout/UserOrCompanySettingsLayout";

const StyledTalentLayout = styled(TalentLayout)`
  height: calc(100vh - 72px);
`;

export type UserSettingsForTalentProps = {
  layout: TalentLayoutProps;
  settingsLayout: Omit<UserOrCompanySettingsLayoutProps, "children">;
  children: React.ReactNode;
};

const UserSettingsForTalent: React.FC<UserSettingsForTalentProps> = props => {
  return (
    <StyledTalentLayout {...props.layout}>
      <Box p={3}>
        <UserOrCompanySettingsLayout {...props.settingsLayout}>{props.children}</UserOrCompanySettingsLayout>
      </Box>
    </StyledTalentLayout>
  );
};

UserSettingsForTalent.displayName = "UserSettings";

export default UserSettingsForTalent;
