import { ENTITY_REPORT_TOP, extractUniqueEntity, ShowingTarget } from "@hireroo/app-helper/entity";
import { scrollToContentForReportV2 } from "@hireroo/app-helper/html-element";
import { QuizTestReport } from "@hireroo/app-store/view-domain/QuizTestReport";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import QuizTestReportContainer, { QuizTestReportContainerProps } from "./Container";

export type QuizTestReportFetchContainerProps = {
  showingTargets: ShowingTarget[];
  companyId: number;
  quizId: number;
};

const QuizTestReportFetchContainer: React.FC<QuizTestReportFetchContainerProps> = props => {
  const { quizId } = props;
  const initialized = QuizTestReport.useInitialized(quizId);

  const [result] = Graphql.useGetQuizEntityForQuizTestReportQuery({
    variables: {
      quizId: quizId,
      withAnswers: false,
    },
    requestPolicy: "cache-and-network",
  });

  React.useEffect(() => {
    if (result.data?.quizEntity) {
      QuizTestReport.initialize(result.data.quizEntity);
      const uniqueEntity = extractUniqueEntity(window.location.hash);
      if (uniqueEntity && uniqueEntity.type === "quiz" && uniqueEntity.id === result.data.quizEntity.entityId) {
        scrollToContentForReportV2(ENTITY_REPORT_TOP, { delayTimeMilliSeconds: 1 });
      }
    }
  }, [result.data?.quizEntity]);

  if (result.fetching || !initialized) {
    return <Widget.Loading kind="TOP" />;
  }

  const containerProps: QuizTestReportContainerProps = {
    ...props,
  };
  return (
    <ErrorBoundary>
      <QuizTestReportContainer {...containerProps} />
    </ErrorBoundary>
  );
};

QuizTestReportFetchContainer.displayName = "QuizTestReportFetchContainer";

export default withErrorBoundary(QuizTestReportFetchContainer, {});
