import { useTranslation } from "@hireroo/i18n";
import { ValidateAlgorithmClassVariantInput } from "@hireroo/validator";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@mui/material/styles";
import * as React from "react";

import { ChallengeCodingEditorContextProps, useChallengeCodingEditorContext } from "../../../Context";
import IDEConsoleInput from "../IDEConsoleInput/IDEConsoleInput";

type TestCaseIndex = string;

export type IDEConsoleInputDialogProps = {
  open: boolean;
  defaultValue?: string[];
  onClose: () => void;
};

const IDEConsoleInputDialog: React.FC<IDEConsoleInputDialogProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [values, setValues] = React.useState<string[]>(props.defaultValue ?? []);
  const [errorMap, setErrorMap] = React.useState<Record<TestCaseIndex, boolean>>({});
  const [errorCode, setErrorCode] = React.useState<string>("");

  const { testCases, updateTestCases, updateSelectedTestCaseIndex, contextProps } = useChallengeCodingEditorContext();

  const handleSetError = React.useCallback((key: string, hasError: boolean) => {
    setErrorMap(draft => {
      return { ...draft, [key]: hasError };
    });
  }, []);

  const handleChangeValue = React.useCallback(
    (index: number, text: string) => {
      const newValues = [...values];
      newValues[index] = text;
      setValues(newValues);

      switch (contextProps.signatureProps.variant) {
        case "ALGORITHM":
          break;
        case "CLASS":
          // clear on change
          setErrorCode("");
          break;
        case "DATABASE":
          // clear on change
          handleSetError(String(index), false);
          break;
        default:
          break;
      }
    },
    [contextProps.signatureProps.variant, handleSetError, values],
  );

  const hasError = Object.values(errorMap).some(value => value);

  const validateTestCase = (values: string[]) => {
    switch (contextProps.signatureProps.variant) {
      case "ALGORITHM":
        return validateFunctionVariantTestCase(values);
      case "CLASS":
        return validateClassVariantTestCase(values, contextProps.signatureProps);
      case "DATABASE":
        return validateDatabaseVariantTestCase(values);
      default:
        return validateFunctionVariantTestCase(values);
    }
  };

  const validateFunctionVariantTestCase = (values: string[]) => {
    if (values.length === 0) {
      handleSetError(String(0), true);
      return false;
    }

    let isValid = true;
    values.forEach((value, idx) => {
      isValid = value !== "";
      if (!isValid) {
        handleSetError(String(idx), true);
      }
    });

    return isValid;
  };

  const validateClassVariantTestCase = (
    values: string[],
    classSignature: Extract<ChallengeCodingEditorContextProps["signatureProps"], { variant: "CLASS" }>,
  ) => {
    if (values.length === 0) {
      setErrorCode("INVALID_INPUT_LENGTH_IS_NOT_TWO");
      return false;
    }

    const validationResult = ValidateAlgorithmClassVariantInput.validateClassVariantInput(values, classSignature.signature);
    if (!validationResult.isValid) {
      setErrorCode(validationResult.returnCode);

      return false;
    }
    return true;
  };

  const validateDatabaseVariantTestCase = (values: string[]) => {
    if (values.length === 0) {
      handleSetError(String(0), true);
      return false;
    }

    let isValid = true;
    values.forEach(value => {
      isValid = value !== "";
    });

    return isValid;
  };

  const addTestCase = React.useCallback(
    (testcase: string[]) => {
      updateTestCases(testCases.concat([{ inputs: testcase, isHidden: false }]));
      updateSelectedTestCaseIndex(testCases.length);
    },
    [testCases, updateSelectedTestCaseIndex, updateTestCases],
  );

  const handleAddButton = () => {
    const isValid = validateTestCase(values);
    if (!isValid) return;

    addTestCase(values);
    setValues(props.defaultValue ?? []);
    props.onClose();
  };

  const testCaseAddSubmitButton: ButtonProps = {
    onClick: handleAddButton,
    disabled: hasError,
  };
  return (
    <Dialog PaperProps={{ elevation: 24 }} open={props.open} onClose={props.onClose} fullWidth>
      <DialogTitle>{t("テストケースを追加する")}</DialogTitle>
      <DialogContent>
        <DialogContentText color="textPrimary">{t("引数の型に合わせて入力してください。")}</DialogContentText>
        <Box mt={2}>
          <IDEConsoleInput
            consoleValue={values}
            errorMap={errorMap}
            errorCode={errorCode}
            handleErrorChange={handleSetError}
            consoleValueDidChange={handleChangeValue}
            inputStyle={{ border: `1px solid ${theme.palette.divider}}` }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={props.onClose} variant="outlined">
          {t("キャンセル")}
        </Button>
        <Button {...testCaseAddSubmitButton} color="secondary" variant="contained">
          {t("追加")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

IDEConsoleInputDialog.displayName = "IDEConsoleInputDialog";

export default IDEConsoleInputDialog;
