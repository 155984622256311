import { useLanguageCode } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateHelpCenterUrl } from "@hireroo/router/api";

export type GenerateFooterPropsArgs = {};

export const useGenerateProps = (_args: GenerateFooterPropsArgs): Widget.EmployeeFooterProps => {
  const lang = useLanguageCode();
  return {
    helpCenterUrl: generateHelpCenterUrl(lang, "ROOT"),
    tosUrl: generateHelpCenterUrl(lang, "TOS_FOR_EMPLOYEE"),
    privacyUrl: `${import.meta.env.VITE_LP_URL}/privacy`,
  };
};
