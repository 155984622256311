import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { signInWithSAML } from "@hireroo/firebase";
import { createTemporallyGraphqlClient } from "@hireroo/graphql/client/request";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";

export type GenerateSingleSignOnPropsArgs = {};

export const useGenerateProps = (_args: GenerateSingleSignOnPropsArgs): Widget.SingleSignOnProps => {
  const { t } = useTranslation();
  return {
    mailAddressField: {},
    signInButton: {
      children: t("SSO（シングルサインオン）でサインイン"),
    },
    onSubmit: async field => {
      const client = createTemporallyGraphqlClient(import.meta.env.VITE_GRAPHQL_SERVER_ADDRESS, {
        appVersion: APP_VERSION,
      });
      await client
        .GetSecurityByEmail({
          email: field.email,
        })
        .then(async res => {
          const providerId = res.securityByEmail.providerId;
          // Set the signIn information to the local storage so that we can update
          // custom claims after redirected to the signin page from providers.
          localStorage.setItem("signin/claims", "employee");
          await signInWithSAML(providerId);
        })
        .catch(() => {
          Snackbar.notify({
            severity: "error",
            message: t("このメールアドレスではSSOが有効になっていません。"),
          });
        });
    },
  };
};
