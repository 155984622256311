import * as ContentApi from "./content-api";
import * as RestClient from "./fetcher";
export type * as ContentApi from "./content-api";
export { ErrorResponse } from "./error/ErrorResponse";

let contentApiClient: ContentApi.Client<RestClient.RequestOption> | null = null;

export const initialize = (baseUrl: string, authToken: string) => {
  const apiClient = RestClient.create({
    authToken,
  });
  contentApiClient = ContentApi.createClient<RestClient.RequestOption>(apiClient, `${baseUrl}/content`);
};

export const getContentApiClient = () => {
  if (contentApiClient === null) {
    throw new Error("Please initialize Content API");
  }
  return contentApiClient;
};
