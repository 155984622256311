import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Typography from "@mui/material/Typography";
import * as React from "react";

import TablePagination, { TablePaginationProps } from "../../modules/TablePagination/TablePagination";
import { QuestionTableHeaderProps } from "../../ms-components/Question";
import QuestionPackageTableHeader, { QuestionPackageTableHeaderProps } from "./parts/QuestionPackageTableHeader/QuestionPackageTableHeader";
import QuestionTableRow, { QuestionTableRowProps } from "./parts/QuestionTableRow/QuestionTableRow";

const StyledTableContainer = styled(TableContainer)(() => ({
  borderRadius: "8px",
  bgcolor: "inherit",
  height: "100%",
}));

export type QuestionPackageTableProps = {
  rows: QuestionTableRowProps[];
  resultText: QuestionTableHeaderProps["resultText"];
  sortField: QuestionTableHeaderProps["sortField"];
  pagination?: TablePaginationProps;
};

const QuestionPackageTable: React.FC<QuestionPackageTableProps> = props => {
  const { t } = useTranslation();
  const tablePaginationProps: TablePaginationProps | undefined = props.pagination
    ? {
        ...props.pagination,
      }
    : undefined;

  const questionTableHeaderProps: QuestionPackageTableHeaderProps = {
    resultText: props.resultText,
    sortField: props.sortField,
  };

  return (
    <Paper sx={{ boxShadow: "none", borderRadius: "16px" }} elevation={2}>
      {props.rows.length > 0 && (
        <StyledTableContainer>
          <Table component="div">
            <QuestionPackageTableHeader {...questionTableHeaderProps} />
            <TableBody component="div">
              {props.rows.map(row => (
                <QuestionTableRow key={row.id} {...row} />
              ))}
            </TableBody>
          </Table>
          {tablePaginationProps && <TablePagination {...tablePaginationProps} />}
        </StyledTableContainer>
      )}
      {props.rows.length === 0 && (
        <Box sx={{ width: "100%" }} p={2}>
          <Typography variant="body2" textAlign="center" color="textSecondary">
            {t("該当するデータがありません")}
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

QuestionPackageTable.displayName = "QuestionPackageTable";

export default QuestionPackageTable;
