import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type ListContentProps = {
  divider?: boolean;
  title: string;
  children: React.ReactNode;
};

const ListContent: React.FC<ListContentProps> = props => {
  return (
    <ListItem style={{ paddingRight: 0 }} divider={props.divider}>
      <ListItemText
        primary={
          <Grid container spacing={2} wrap="nowrap" alignItems="center">
            <Grid item xs={3}>
              <Typography variant="subtitle1" color="textSecondary" sx={{ wordBreak: "keep-all" }}>
                {props.title}
              </Typography>
            </Grid>
            <Grid item xs={9} sx={{ overflowWrap: "anywhere" }}>
              {props.children}
            </Grid>
          </Grid>
        }
      />
    </ListItem>
  );
};

ListContent.displayName = "ListContent";

export default ListContent;
