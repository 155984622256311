import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import * as React from "react";

import InputControlTextField, { InputControlTextFieldProps } from "../../../../primitive/InputControl/InputControlTextField";
import { useAlgorithmAnswersFormContext } from "../../Context";

export type ComplexityFieldsProps = {
  index: number;
};

const ComplexityFields: React.FC<ComplexityFieldsProps> = props => {
  const { t } = useTranslation();
  const { methods } = useAlgorithmAnswersFormContext();
  const commonFiled: InputControlTextFieldProps = {
    control: methods.control,
    type: "text",
    color: "primary",
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
    variant: "outlined",
    placeholder: "O(1)",
    required: true,
  };
  const runtimeField: InputControlTextFieldProps = {
    ...commonFiled,
    label: t("時間計算量"),
  };
  const spaceField: InputControlTextFieldProps = {
    ...commonFiled,
    label: t("空間計算量"),
  };
  return (
    <Box mt={2} display="flex" columnGap={4}>
      <InputControlTextField name={`answers.${props.index}.runtimeComplexity`} {...runtimeField} />
      <InputControlTextField name={`answers.${props.index}.spaceComplexity`} {...spaceField} />
    </Box>
  );
};

ComplexityFields.displayName = "ComplexityFields";

export default ComplexityFields;
