import * as React from "react";
import { FormProvider } from "react-hook-form";

import { useAlgorithmQuestionDetailFormContext } from "./Context";
import GeneralSettingFields, { GeneralSettingFieldsProps } from "./parts/GeneralSettingFields";
import QuestionDetailsErrorMessage from "./parts/QuestionDetailsErrorMessage";
import QuestionTextFields, { QuestionTextFieldsProps } from "./parts/QuestionTextFields";

export {
  AlgorithmQuestionDetailFormProvider,
  type AlgorithmQuestionDetailFormProviderProps,
  useAlgorithmQuestionDetailFormContext,
} from "./Context";

export type AlgorithmQuestionDetailFormProps = {
  generalSettingFields: GeneralSettingFieldsProps;
  questionTextFields: QuestionTextFieldsProps;
};

const AlgorithmQuestionDetailForm: React.FC<AlgorithmQuestionDetailFormProps> = props => {
  const { methods } = useAlgorithmQuestionDetailFormContext();
  return (
    <FormProvider {...methods}>
      <QuestionDetailsErrorMessage />
      <GeneralSettingFields {...props.generalSettingFields} />
      <QuestionTextFields {...props.questionTextFields} />
    </FormProvider>
  );
};

AlgorithmQuestionDetailForm.displayName = "AlgorithmQuestionDetailForm";

export default AlgorithmQuestionDetailForm;
