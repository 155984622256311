import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { useHelpCenterNavigate } from "@hireroo/router/hooks";

export type GenerateCandidateNavigationPropsArgs = {};

export const useGenerateProps = (_args: GenerateCandidateNavigationPropsArgs): Widget.CandidateNavigationProps => {
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const helpCenterNavigate = useHelpCenterNavigate(lang);
  return {
    item: {
      type: "LANGUAGE_ONLY",
    },
    helpMenu: {
      options: [
        {
          text: t("ヘルプ"),
          value: "help",
          icon: "INFO" as const,
          onClick: () => {
            helpCenterNavigate("CANDIDATE_HELP_PAGE", { _blank: true });
          },
        },
      ],
    },
  };
};
