import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type RemoteInterviewTimerProps = {
  displayText: string;
};

const RemoteInterviewTimer: React.FC<RemoteInterviewTimerProps> = props => {
  return (
    <Stack id="timelimit-box" role="timelimit" alignItems="center" spacing={1} direction="row" color="text.secondary">
      <AccessTimeIcon />
      <Typography fontWeight="bold" whiteSpace="nowrap">
        {props.displayText}
      </Typography>
    </Stack>
  );
};

RemoteInterviewTimer.displayName = "RemoteInterviewTimer";

export default RemoteInterviewTimer;
