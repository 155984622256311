import { Widgets } from "@hireroo/evaluation-result-ui";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type EvaluationResultPlaygroundContainerProps = {};

const EvaluationResultPlayground: React.FC<EvaluationResultPlaygroundContainerProps> = () => {
  const evaluationResultProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widgets.ResultBlocksPlayground {...evaluationResultProps} />
      <Widgets.TestResultPlayground />
    </ErrorBoundary>
  );
};

EvaluationResultPlayground.displayName = "EvaluationResultPlayground";

export default withErrorBoundary(EvaluationResultPlayground, {});
