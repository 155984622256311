import { QuestionTableForm } from "@hireroo/validator";
import LocalFireDepartmentOutlinedIcon from "@mui/icons-material/LocalFireDepartmentOutlined";
import LockIcon from "@mui/icons-material/Lock";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow, { TableRowProps } from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useController } from "react-hook-form";

import LeakScoreChip, { LeakScoreChipProps } from "../../../../ms-components/Question/LeakScoreChip/LeakScoreChip";
import QuestionMeta, { QuestionMetaProps } from "../../../../ms-components/Question/QuestionMeta/QuestionMeta";
import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "../../../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";
import MoreButton, { MoreButtonProps } from "../../../../primitive/Button/MoreButton/MoreButton";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderTop: "1px solid",
  borderBottom: "1px solid",
  borderColor: theme.palette.Other.Divider,
  "&.Mui-selected": {
    backgroundColor: theme.palette["Secondary/Shades"].p30,
    ":hover": {
      backgroundColor: theme.palette["Secondary/Shades"].p16,
    },
  },
  "&:last-child": {
    borderBottom: "unset",
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  border: "none",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  display: "table-cell",
}));

const StyledCheckbox = styled(Checkbox)(() => ({
  width: "38px",
  height: "38px",
  padding: "9px",
}));

type Mode =
  | {
      kind: "DEFAULT";
    }
  | {
      kind: "LOCKED";
      description: string;
    };

/**
 * Column
 * 0. [Optional] Checkbox
 * 1. [Required] Meta
 * 2. [Optional] Average Time To Answer
 * 2. [Required] Correctness Rate
 * 3. [Required] Number of User
 * 4. [Optional] ActionComponent
 */
export type QuestionTableRowProps = {
  id: string;
  meta: QuestionMetaProps;
  correctnessRate: number;
  averageTimeToAnswer?: string;
  numUses: number;
  onClick?: TableRowProps["onClick"];
  selected?: boolean;
  name: string;
  mode: Mode;
  options: MoreButtonProps["options"];
  leakScoreChip?: LeakScoreChipProps;
  showLeakScore: boolean;
};

const QuestionTableRow: React.FC<QuestionTableRowProps> = props => {
  const { field } = useController<Record<string, QuestionTableForm.QuestionTableItem>>({
    name: props.name,
  });

  const checkboxProps: CheckboxProps = {
    checked: field?.value?.selected,
    color: "secondary",
    size: "small",
    onClick: event => {
      event.stopPropagation();
    },
    onChange: (_event, checked) => {
      const newFieldValue: QuestionTableForm.QuestionTableItem = {
        ...field.value,
        selected: checked,
      };
      field.onChange(newFieldValue);
    },
  };

  const lockButton: IconButtonWithTooltipProps = {
    disabled: true,
    title: props.mode.kind === "LOCKED" ? props.mode.description : undefined,
    children: <LockIcon fontSize="small" />,
  };

  const moreButtonProps: MoreButtonProps = {
    options: props.options,
    variant: "vertical",
  };

  return (
    <StyledTableRow
      hover
      onClick={event => {
        event.stopPropagation();
        event.preventDefault();
        const newFieldValue: QuestionTableForm.QuestionTableItem = {
          ...field.value,
          selected: !field.value.selected,
        };
        props.onClick?.(event);
        field.onChange(newFieldValue);
      }}
      selected={props.selected}
      color="secondary"
    >
      <StyledTableCell align="center" sx={{ width: "38px" }}>
        {props.mode.kind === "DEFAULT" && <StyledCheckbox {...checkboxProps} />}
        {props.mode.kind === "LOCKED" && <IconButtonWithTooltip {...lockButton} />}
      </StyledTableCell>
      <StyledTableCell>
        <QuestionMeta {...props.meta} />
      </StyledTableCell>
      {props.showLeakScore && (
        <StyledTableCell align="center">{props.leakScoreChip && <LeakScoreChip {...props.leakScoreChip} />}</StyledTableCell>
      )}
      {typeof props.averageTimeToAnswer === "string" && (
        <StyledTableCell align="center">
          <Typography fontSize={14} color="text.secondary">
            {props.averageTimeToAnswer}
          </Typography>
        </StyledTableCell>
      )}
      <StyledTableCell align="center">
        <Typography fontSize={14} color="secondary">{`${props.correctnessRate}%`}</Typography>
      </StyledTableCell>
      <StyledTableCell align="center">
        <Stack direction="row" alignItems="center" justifyContent="center">
          <LocalFireDepartmentOutlinedIcon color="primary" />
          <Typography fontSize={14} color="primary">
            {props.numUses?.toLocaleString()}
          </Typography>
        </Stack>
      </StyledTableCell>
      <StyledTableCell align="center">
        <MoreButton {...moreButtonProps} />
      </StyledTableCell>
    </StyledTableRow>
  );
};

QuestionTableRow.displayName = "QuestionTableRow";

export default QuestionTableRow;
