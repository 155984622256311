import React from "react";

export type CursorProps = {
  color: string;
  x: number;
  y: number;
};

export const Cursor: React.FC<CursorProps> = React.memo((props: CursorProps) => {
  return (
    <g>
      <defs>
        <marker id="m_atr" markerUnits="strokeWidth" markerWidth="3" markerHeight="3" viewBox="0 0 40 40" refX="40" refY="20" orient="225">
          <polygon points="0,0 10,20 0,40 40,20 " fill={props.color} />
        </marker>
      </defs>
      <g stroke="black" strokeWidth="10" fill="none">
        <path d={`M ${props.x} ${props.y} L ${props.x} ${props.y}`} markerEnd="url(#m_atr)" />
      </g>
    </g>
  );
});

Cursor.displayName = "Cursor";

export default Cursor;
