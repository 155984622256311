
import { registerExtension } from 'vscode/extensions'

const manifest = {"name":"Kotlin","displayName":"Kotlin Language","description":"Kotlin language support for VS Code","version":"1.7.1","publisher":"mathiasfrohlich","license":"Apache-2.0","scripts":{"package":"./node_modules/.bin/vsce package"},"engines":{"vscode":"^1.8.0"},"icon":"artwork/icon.png","categories":["Programming Languages"],"bugs":{"url":"https://github.com/mathiasfrohlich/vscode-kotlin/issues"},"homepage":"https://github.com/mathiasfrohlich/vscode-kotlin","repository":{"type":"git","url":"https://github.com/mathiasfrohlich/vscode-kotlin.git"},"keywords":["Kotlin","kotlin","kt","kts"],"contributes":{"languages":[{"id":"kotlin","aliases":["Kotlin","kotlin"],"extensions":[".kt"],"configuration":"./kotlin.configuration.json"},{"id":"kotlinscript","aliases":["Kotlinscript","kotlinscript"],"extensions":[".kts"],"configuration":"./kotlin.configuration.json"}],"grammars":[{"language":"kotlin","scopeName":"source.kotlin","path":"./syntaxes/Kotlin.tmLanguage"},{"language":"kotlinscript","scopeName":"source.kotlin","path":"./syntaxes/Kotlin.tmLanguage"},{"scopeName":"markdown.kotlin.codeblock","path":"./syntaxes/codeblock.json","injectTo":["text.html.markdown"],"embeddedLanguages":{"meta.embedded.block.kotlin":"kotlin"}}],"snippets":[{"language":"kotlin","path":"./snippets/kotlin.json"},{"language":"kotlinscript","path":"./snippets/kotlin.json"}]},"devDependencies":{"vsce":"^1.36.2","vscode":"0.11.x","vso-node-api":"6.1.2-preview"},"__metadata":{"id":"d36bad53-910d-481a-a7ee-8992450665f6","publisherId":"6c0520a7-c10a-45a4-b172-d75ca7dca2c1","publisherDisplayName":"mathiasfrohlich"}}

const { registerFileUrl, whenReady } = registerExtension(manifest, undefined, {"system":true,"readmePath":"README.md","changelogPath":"CHANGELOG.md"})


registerFileUrl('README.md', import.meta.ROLLUP_FILE_URL_B38Jaiel, {"mimeType":"text/markdown","size":1731})

registerFileUrl('CHANGELOG.md', import.meta.ROLLUP_FILE_URL_CV70cocY, {"mimeType":"text/markdown","size":1571})

registerFileUrl('syntaxes/Kotlin.tmLanguage', import.meta.ROLLUP_FILE_URL_DrBmAPHk, {"size":31782})

registerFileUrl('syntaxes/codeblock.json', import.meta.ROLLUP_FILE_URL_BLNelW8e, {"mimeType":"application/json","size":414})

registerFileUrl('kotlin.configuration.json', import.meta.ROLLUP_FILE_URL_BzP0h6YT, {"mimeType":"application/json","size":795})

registerFileUrl('snippets/kotlin.json', import.meta.ROLLUP_FILE_URL_De0mX_xc, {"mimeType":"application/json","size":2360})

registerFileUrl('package.json', import.meta.ROLLUP_FILE_URL_NqfDtF0b, {"mimeType":"application/json","size":2015})

registerFileUrl('artwork/icon.png', import.meta.ROLLUP_FILE_URL_DZTCyTlZ, {"mimeType":"image/png","size":4870})

export { whenReady }
