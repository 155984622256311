import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import DialogContentText from "@mui/material/DialogContentText";
import * as React from "react";

import BaseDialog, { BaseDialogProps } from "../../../../primitive/BaseDialog/BaseDialog";

export type DeleteDialogProps = {
  open: boolean;
  templateName: string;
  yesButton?: BaseDialogProps["yesButton"];
  noButton?: BaseDialogProps["noButton"];
};

const DeleteDialog: React.FC<DeleteDialogProps> = props => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const baseDialog: BaseDialogProps = {
    open: props.open,
    title: t2("DeleteTemplate", {
      templateName: props.templateName,
    }),
    yesButton: {
      ...props.yesButton,
      color: "error",
      children: t("削除"),
    },
    noButton: {
      ...props.noButton,
      children: t("キャンセル"),
    },
  };
  return (
    <BaseDialog {...baseDialog}>
      <Box mb={2} display="grid">
        <DialogContentText>{t("この動作は復元できません。よろしいですか？")}</DialogContentText>
      </Box>
    </BaseDialog>
  );
};

DeleteDialog.displayName = "DeleteDialog";

export default DeleteDialog;
