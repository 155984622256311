import ErrorIcon from "@mui/icons-material/Error";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledBox = styled(Box)(() => ({
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
}));

const StyledIconWrapper = styled("span")(() => ({
  lineHeight: "12px",
  marginRight: 2,
  svg: {
    width: 16,
    height: 16,
  },
}));

type Kind = "ERROR" | "INFO";

export type StatusMessageProps = {
  kind: Kind;
  message: string;
};

const StatusMessage: React.FC<StatusMessageProps> = props => {
  const iconMap: Record<Kind, React.ReactNode> = {
    ERROR: <ErrorIcon fontSize="small" color="error" />,
    INFO: <ErrorOutlineOutlinedIcon fontSize="small" />,
  };
  return (
    <StyledBox>
      <StyledIconWrapper>{iconMap[props.kind]}</StyledIconWrapper>
      <Typography variant="caption" sx={{ fontSize: 12 }}>
        {props.message}
      </Typography>
    </StyledBox>
  );
};

StatusMessage.displayName = "StatusMessage";

export default StatusMessage;
