import { Language } from "@hireroo/i18n/utils";

type ContentObj = {
  title_ja?: string;
  title_en?: string;
  description_ja?: string;
  description_en?: string;
};

export type ContentType = "title" | "description";
type Key = `${ContentType}_${Language}`;

export const resolveTestCaseLanguage = (obj: ContentObj, lang: Language, contentType: ContentType): string | undefined => {
  const map: Record<Key, string | undefined> = {
    title_ja: obj.title_ja ?? obj.title_en,
    title_en: obj.title_en ?? obj.title_ja,
    description_ja: obj.description_ja ?? obj.description_en,
    description_en: obj.description_en ?? obj.description_ja,
  };
  const key = `${contentType}_${lang}` as Key;
  return map[key];
};
