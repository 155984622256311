import { proxyMap } from "valtio/utils";

import { state } from "./State";
import type * as Types from "./types";

export const initialize = (entity: Types.SystemDesignEntity, options: { enabledHint: boolean }) => {
  const entityState: Types.SystemDesignEntityState = {
    appealMessage: entity.appealMessage,
    entity,
    testCases: proxyMap(),
    enabledHint: options.enabledHint,
  };

  /**
   * FIXME This implementation is a work-around
   * Because startSystemDesign returns a different value for scoringItems than getSpot, the value cannot be overwritten as is.
   */
  const prevState = state.systemDesigns.get(entity.systemDesignEntityId);
  if (prevState) {
    const prevScoringItems = prevState.entity.question?.scoringItems ?? [];
    const newScoringItems = entity.question?.scoringItems ?? [];
    const scoringItems = newScoringItems.length === 0 ? prevScoringItems : newScoringItems;
    state.systemDesigns.set(entity.systemDesignEntityId, {
      ...entityState,
      entity: {
        ...entityState.entity,
        question: entity.question && {
          ...entity.question,
          scoringItems: scoringItems,
        },
      },
    });
  } else {
    state.systemDesigns.set(entity.systemDesignEntityId, entityState);
  }
};

export const setSubmittedEntity = (entityId: number) => {
  const entityState = state.systemDesigns.get(entityId);
  if (entityState) {
    state.submittedEntity = entityState.entity;
  }
};

export const clearSubmittedEntity = () => {
  state.submittedEntity = null;
};

export const setIpAddress = (ipAddress: string) => {
  state.ipAddress = ipAddress;
};

export const setGeolocation = (geolocation: string) => {
  state.geolocation = geolocation;
};

export const createSystemDesignEntityAction = (entityId: number) => {
  const systemDesign = state.systemDesigns.get(entityId);
  const setTestcaseResult = (scoringItemId: Types.SystemDesignScoringItemId, testCase: Types.TestCaseResult) => {
    systemDesign?.testCases.set(scoringItemId, testCase);
  };
  const clearTestcaseResult = () => {
    systemDesign?.testCases.clear();
  };
  const updateAppealMessage = (appealMessage: string) => {
    if (systemDesign) {
      systemDesign.appealMessage = appealMessage;
    }
  };
  return {
    setTestcaseResult,
    clearTestcaseResult,
    updateAppealMessage,
  };
};
