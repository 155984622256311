import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import * as React from "react";

export type CSVRowProps = {
  actualRow: Record<string, string>;
  expectedRow: Record<string, string>;
  isCorrect: boolean;
};

const CSVRow: React.FC<CSVRowProps> = props => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow
        sx={{
          background: props.isCorrect ? theme.palette["Success/Shades"].p30 : theme.palette["Error/Shades"].p30,
        }}
      >
        <TableCell width={"10%"}>
          {props.isCorrect ? <CheckOutlinedIcon color="success" fontSize="small" /> : <CloseOutlinedIcon color="error" fontSize="small" />}
        </TableCell>
        {Object.entries(props.actualRow).map(([col, cell]) => (
          <TableCell key={col} width={"30%"}>
            {cell}
          </TableCell>
        ))}
        {!props.isCorrect ? (
          <TableCell width={"10%"}>
            <IconButton aria-label={"expanded-row"} onClick={() => setOpen(!open)} sx={{ height: 20, width: 20 }}>
              {open ? <KeyboardArrowUpOutlinedIcon fontSize={"small"} /> : <KeyboardArrowDownOutlinedIcon fontSize={"small"} />}
            </IconButton>
          </TableCell>
        ) : (
          <TableCell width={"10%"} />
        )}
      </TableRow>

      <TableRow sx={{ visibility: open ? "visible" : "collapse", background: theme.palette.grey[800] }}>
        <TableCell width={"10%"} />
        {Object.entries(props.expectedRow).map(([col, cell]) => (
          <TableCell key={col} width={"30%"}>
            {cell}
          </TableCell>
        ))}
        <TableCell width={"10%"} />
      </TableRow>
    </>
  );
};

CSVRow.displayName = "CSVRow";

export default CSVRow;
