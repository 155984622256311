import getKeybindingsServiceOverride from "@codingame/monaco-vscode-keybindings-service-override";
import { MonacoEditorProps, MonacoEditorReactComp } from "@typefox/monaco-editor-react";
import { UserConfig } from "monaco-editor-wrapper";
import * as React from "react";

import * as SwapTheme from "../../../helpers/swap-theme";
import * as VscodeHelper from "../vscode-helper";

VscodeHelper.configureMonacoWorkers();

export type DiffEditorProps = {
  modified: string;
  modifiedModelPath: string;
  original: string;
  originalModelPath: string;
  style?: MonacoEditorProps["style"];
  onLoad?: MonacoEditorProps["onLoad"];
};

const DiffEditor: React.FC<DiffEditorProps> = props => {
  // Since it has been confirmed to work, keep it as it is without using useEffect.
  SwapTheme.showVscodeTheme();
  const language = VscodeHelper.getLanguage(props.modifiedModelPath);
  const userConfig: UserConfig = {
    loggerConfig: {
      enabled: false,
      debugEnabled: false,
    },
    wrapperConfig: {
      serviceConfig: {
        userServices: {
          ...getKeybindingsServiceOverride(),
        },
        enableExtHostWorker: true,
        debugLogging: false,
      },
      editorAppConfig: {
        $type: "extended",
        useDiffEditor: true,
        codeResources: {
          main: {
            text: props.modified,
            uri: props.modifiedModelPath,
            fileExt: VscodeHelper.getExtension(props.modifiedModelPath),
          },
          original: {
            text: props.original,
            uri: props.originalModelPath,
            fileExt: VscodeHelper.getExtension(props.originalModelPath),
          },
        },
        awaitExtensionReadiness: VscodeHelper.awaitExtensionReadiness,
        readOnly: true,
        editorOptions: {
          theme: "Monokai",
          readOnly: true,
          language: language,
        },
        diffEditorOptions: {
          theme: "Monokai",
          readOnly: true,
          useInlineViewWhenSpaceIsLimited: false,
          renderValidationDecorations: "off",
          renderOverviewRuler: false,
        },
        userConfiguration: {
          json: JSON.stringify(VscodeHelper.Def.defaultEditorSettingsJson),
        },
      },
    },
  };

  const monacoEditorReactCompProps: MonacoEditorProps = {
    userConfig,
    style: props.style,
    onLoad: props.onLoad,
    onError: console.error,
  };

  return <MonacoEditorReactComp {...monacoEditorReactCompProps} key={props.modifiedModelPath} />;
};

DiffEditor.displayName = "DiffEditor";

export default DiffEditor;
