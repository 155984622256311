import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import Tab from "../../primitive/Tab/Tab";
import Tabs, { TabsProps } from "../../primitive/Tabs/Tabs";

const TabsWrapper = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const HeaderWrapper = styled(Box)`
  height: 36px;
  display: flex;
  flex-direction: row;
`;

const ContentWrapper = styled(Box)`
  height: 100%;
  overflow-y: auto;
`;

export type ReadonlyTabItem = {
  /**
   * A value to identify the tab.
   * Do not use variable values such as i18n values.
   */
  id: string;
  icon?: string | React.ReactElement;
  Component: React.ReactNode;
  name: string;
  onClickTab?: () => void;
};

export type ReadonlyTabsProps = {
  className?: string;
  defaultTab?: string;
  tabs?: Omit<TabsProps, "value" | "sx">;
  items: ReadonlyTabItem[];
};

const ReadonlyTabs: React.FC<ReadonlyTabsProps> = props => {
  const defaultTab = props.defaultTab || (props.items.length ? props.items[0].id : "");
  const [currentName, updateCurrentSelectedName] = React.useState(defaultTab);
  React.useEffect(() => {
    if (defaultTab) {
      updateCurrentSelectedName(defaultTab);
    }
  }, [defaultTab]);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    updateCurrentSelectedName(newValue);
    props.tabs && props.tabs.onChange?.(event, newValue);
  };

  const TabPanels = props.items.map(item => {
    return (
      <TabPanel key={item.id} value={item.id}>
        {item.Component}
      </TabPanel>
    );
  });

  return (
    <TabContext value={currentName}>
      <TabsWrapper className={props.className}>
        <HeaderWrapper>
          <Tabs sx={{ width: "100%" }} value={currentName} onChange={handleChange}>
            {props.items.map(item => (
              <Tab
                key={item.id}
                label={
                  <Typography textTransform="none" variant="body2" fontSize={14}>
                    {item.name}
                  </Typography>
                }
                aria-labelledby={item.name}
                value={item.id}
                onClick={item.onClickTab}
                icon={item.icon}
                iconPosition="start"
              />
            ))}
          </Tabs>
        </HeaderWrapper>
        <ContentWrapper>{TabPanels}</ContentWrapper>
      </TabsWrapper>
    </TabContext>
  );
};

ReadonlyTabs.displayName = "ReadonlyTabs";

export default ReadonlyTabs;
