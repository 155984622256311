import { Widget } from "@hireroo/presentation";
import * as React from "react";

import { GenerateUpsellLockButtonDialogPropsArgs, useGenerateProps } from "./useGenerateProps";

export type FooterContainerProps = GenerateUpsellLockButtonDialogPropsArgs;

const UpsellLockButtonDialogContainer: React.FC<FooterContainerProps> = props => {
  const dialogProps = useGenerateProps(props);
  return <Widget.UpsellLockButtonDialog {...dialogProps} />;
};

UpsellLockButtonDialogContainer.displayName = "UpsellLockButtonDialogContainer";

export default UpsellLockButtonDialogContainer;
