import { INITIAL_VERSION } from "@hireroo/app-definition/question";
import { InterviewOverview } from "@hireroo/app-store/widget/e/InterviewOverview";
import { languageMapForDisplay } from "@hireroo/challenge/definition";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";

type Question = Widget.ScreeningTestOverviewProps["questions"][0];

export const useGenerateQuestionsProps = (): Question[] => {
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const interview = InterviewOverview.useInterview();

  const difficultyStarsMap: Record<Graphql.Difficulty, Question["difficultyStars"]> = {
    [Graphql.Difficulty.Easy]: {
      difficulty: "EASY",
    },
    [Graphql.Difficulty.Medium]: {
      difficulty: "MEDIUM",
    },
    [Graphql.Difficulty.Difficult]: {
      difficulty: "DIFFICULT",
    },
    [Graphql.Difficulty.Unknown]: {
      difficulty: "EASY",
    },
  };

  return interview.entities.map((entity): Question => {
    if (entity.__typename === "ChallengeEntity" && entity.challengeQuestion) {
      const question = entity.challengeQuestion;
      return {
        id: entity.id,
        title: `${resolveLanguage(question, lang, "title")} （${t("コーディング形式")}）`,
        version: question.version ?? INITIAL_VERSION,
        variantText: t("コーディング形式"),
        difficultyStars: difficultyStarsMap[question.difficulty],
        selectedContent: {
          label: t("言語指定"),
          items: entity.enabledLanguages.map(lang => languageMapForDisplay[lang] ?? lang),
        },
        detailButton: {
          href: generatePath("/e/questions/challenge/:id", {
            pathParams: {
              id: question.questionId.toString(),
            },
            queryParams: {
              version: question.version,
            },
          }),
        },
        isArchived: question.algorithmQuestionStatus === "ARCHIVED",
      };
    } else if (entity.__typename === "QuizEntity" && entity.pb_package) {
      const pkg = entity.pb_package;
      return {
        id: entity.id,
        title: `${resolveLanguage(pkg, lang, "title")} （${t("クイズ形式")}）`,
        version: pkg.version ?? INITIAL_VERSION,
        variantText: t("クイズ形式"),
        difficultyStars: difficultyStarsMap[pkg.difficulty],
        detailButton: {
          href: generatePath("/e/questions/quiz/:id", {
            pathParams: {
              id: pkg.packageId.toString(),
            },
            queryParams: {
              version: pkg.version,
            },
          }),
        },
        isArchived: pkg.multiChoicePackageStatus === "ARCHIVED",
      };
    } else if (entity.__typename === "ProjectEntity" && entity.question) {
      const question = entity.question;
      return {
        id: entity.id,
        title: `${resolveLanguage(question, lang, "title")} （${t("実践形式")}）`,
        version: INITIAL_VERSION,
        variantText: t("実践形式"),
        difficultyStars: difficultyStarsMap[question.difficulty],
        detailButton: {
          href: generatePath("/e/questions/project/:id", {
            pathParams: {
              id: question.questionId.toString(),
            },
          }),
        },
        isArchived: question.projectQuestionStatus === "ARCHIVED",
      };
    } else if (entity.__typename === "SystemDesignEntity" && entity.question) {
      const question = entity.question;
      return {
        id: entity.id,
        title: `${resolveLanguage(question, lang, "title")}（${t("システムデザイン形式")}）`,
        version: INITIAL_VERSION,
        variantText: t("システムデザイン形式"),
        difficultyStars: difficultyStarsMap[question.difficulty],
        selectedContent: {
          label: t("リソース指定"),
          items: entity.componentTypes.map(c => c.toLocaleUpperCase()),
        },
        detailButton: {
          href: generatePath("/e/questions/systemdesign/:id", {
            pathParams: {
              id: question.questionId.toString(),
            },
          }),
        },
        isArchived: question.systemDesignQuestionStatus === "ARCHIVED",
      };
    } else {
      throw new Error("Unknown entity question");
    }
  });
};
