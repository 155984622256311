import { SupportLanguage, useTranslation } from "@hireroo/i18n";
import * as z from "zod";

const useFreepadQuestionDetail = () => {
  const { t } = useTranslation();
  return z.object({
    title: z.string().min(1, { message: t("タイトルは必須項目です。") }),
    description: z.string().min(1, { message: t("本文は必須項目です。") }),
    language: z.enum([SupportLanguage.JA, SupportLanguage.EN]),
  });
};

export const useFreepadQuestionFormSchema = () => {
  const { t } = useTranslation();
  const questionDetail = useFreepadQuestionDetail();
  return z.object({
    isPublic: z.boolean(),
    variant: z.union([z.literal("UNKNOWN"), z.literal("ALGORITHM"), z.literal("SYSTEM_DESIGN")]),
    difficulty: z.union([z.literal("UNKNOWN"), z.literal("EASY"), z.literal("MEDIUM"), z.literal("DIFFICULT")]),
    questions: z.array(questionDetail).min(1, {
      message: t("問題は少なくとも1つの言語で入力は必須です。"),
    }),
  });
};

export type FreepadQuestionDetailSchema = z.infer<ReturnType<typeof useFreepadQuestionDetail>>;

export type FreepadQuestionFormSchema = z.infer<ReturnType<typeof useFreepadQuestionFormSchema>>;
