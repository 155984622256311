import { algorithmLanguageListMap, languageMapForHighlight } from "@hireroo/challenge/definition";
import { CodeEditorProps } from "@hireroo/code-editor/react/CodeEditor";
import { useTranslation } from "@hireroo/i18n";
import { Add, History } from "@mui/icons-material";
import Code from "@mui/icons-material/Code";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useFieldArray } from "react-hook-form";

import AcceptButton, { AcceptButtonProps } from "../../../../primitive/Button/AcceptButton";
import InputControlCodeEditor from "../../../../primitive/InputControl/InputControlCodeEditor/InputControlCodeEditor";
import AddableTabs, { AddableTabsProps } from "../../../../primitive/Tab/AddableTabs/AddableTabs";
import { useFreepadOptionalFormContext } from "../../Context";

export type CodeSectionProps = {};

const CodeSection: React.FC<CodeSectionProps> = _props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { methods } = useFreepadOptionalFormContext();
  const [selectedRuntimeIndex, setSelectedRuntimeIndex] = React.useState<string>("0");

  const defaultLanguagesMap = algorithmLanguageListMap;

  const initialCodes = useFieldArray({
    control: methods.control,
    name: `initialCodes`,
  });

  const selectableLanguages = React.useMemo(() => {
    const s = new Set();
    initialCodes.fields.forEach(runtime => {
      s.add(runtime.runtime);
    });
    return defaultLanguagesMap.filter(l => !s.has(l.value));
  }, [initialCodes, defaultLanguagesMap]);
  const getDisplayName = (lang: string) => {
    const displayLang = defaultLanguagesMap.find(l => l.value === lang);
    return displayLang?.displayName ?? "";
  };

  const addAnswerRuntime = React.useCallback(
    async (runtime: string, tabIndex: number) => {
      const isValid = await methods.trigger(`initialCodes.${tabIndex}.body`);

      if (isValid) {
        initialCodes.append({ runtime, body: "" });
      }
    },
    [methods, initialCodes],
  );

  const codeEditor: CodeEditorProps = {
    width: "100%",
    height: innerHeight / 2,
  };

  const resetCode = React.useCallback(
    (runtime: string, tabIndex: number) => () => {
      methods.setValue(`initialCodes.${tabIndex}.body`, "");
    },
    [methods],
  );

  const resetButton: AcceptButtonProps = {
    startIcon: <History fontSize="small" />,
    variant: "text",
    size: "small",
    children: t("リセット"),
  };

  const addableTab: AddableTabsProps = {
    addTabButton: {
      size: "small",
      variant: "text",
      startIcon: <Add fontSize="small" />,
      children: t("コードを追加する"),
    },
    tabListBox: {
      sx: {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
    isInitialTabClosable: true,
    currentTabValue: selectedRuntimeIndex,
    updateCurrentTabValue: setSelectedRuntimeIndex,
    items: initialCodes.fields.map((initialCode, index) => {
      return {
        id: index.toString(),
        name: getDisplayName(initialCode.runtime),
        tab: {
          closeButton: {
            onClick: () => {
              initialCodes.remove(index);
            },
          },
        },
        Content: (
          <Box>
            <Box mb={1}>
              <AcceptButton {...resetButton} onClick={resetCode(initialCode.runtime, index)} />
            </Box>
            <InputControlCodeEditor
              {...codeEditor}
              language={languageMapForHighlight[initialCode.runtime]}
              name={`initialCodes.${index}.body`}
            />
          </Box>
        ),
      };
    }),
    menu: {
      items: selectableLanguages.map(selectableLang => {
        return {
          text: selectableLang.displayName,
          value: selectableLang.value,
          onClick: async () => {
            await addAnswerRuntime(selectableLang.value, initialCodes.fields.length - 1);
          },
        };
      }),
    },
  };
  return (
    <Box>
      <Box display="flex" alignItems="center" mb={2}>
        <Box display="flex" alignItems="center">
          <Box mr={1}>
            <Code fontSize="small" sx={{ color: theme.palette.common.white }} />
          </Box>

          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            {t("初期コードを入力する")}
          </Typography>
        </Box>
      </Box>

      <Box mb={3}>
        <Typography variant="body2">{t("初期コードを追加します。候補者は設定された初期コードを参考にして解答が可能です。")}</Typography>
      </Box>
      <Box>
        <AddableTabs {...addableTab} />
      </Box>
    </Box>
  );
};

CodeSection.displayName = "CodeSection";

export default CodeSection;
