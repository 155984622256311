import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type NotificationListContainerProps = {};

const NotificationListContainer: React.FC<NotificationListContainerProps> = () => {
  const notificationListProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.NotificationList {...notificationListProps} />
    </ErrorBoundary>
  );
};

NotificationListContainer.displayName = "NotificationListContainer";

export default withErrorBoundary(NotificationListContainer, {});
