import { QuizTestReport } from "@hireroo/app-store/view-domain/QuizTestReport";
import { useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import * as React from "react";

import { useGenerateIpAddressSectionProps } from "./useGenerateIpAddressSectionProps";

type CheatDetectionSection = Widget.QuizTestReportProps["cheatDetectionSection"];

export type GenerateCheatDetectionSectionPropsArgs = {
  uniqueKey: QuizTestReport.UniqueKey;
  quizId: number;
  canShowCheatDetectionSection: boolean;
  canShowStatistic: boolean;
};
export const useGenerateCheatDetectionSectionProps = (args: GenerateCheatDetectionSectionPropsArgs): CheatDetectionSection => {
  const { quizId } = args;
  const hooks = QuizTestReport.useCreateQuizHooks(quizId);
  const selectedPackage = hooks.usePackage();
  const submissions = hooks.useSubmissions();

  const { t } = useTranslation();
  const statistics = hooks.useStatistics();
  const ipAddressSection = useGenerateIpAddressSectionProps({ quizId, packageId: selectedPackage.packageId });

  return React.useMemo((): CheatDetectionSection => {
    if (!args.canShowCheatDetectionSection) {
      return;
    }

    const numTabEvents = submissions.reduce((total, submission) => {
      return total + submission.numTabEvents;
    }, 0);

    return {
      numTabEvents: {
        value: `${numTabEvents} ${t("回")}`,
        icon: {
          key: (numTabEvents ?? 0) <= Math.round(statistics?.avgNumTabEvent) ? "CHECK" : "WARNING",
          title: `${t("平均離脱回数")} ${Math.round(statistics?.avgNumTabEvent)}`,
        },
      },
      numAccessEvents: {
        value: `${ipAddressSection?.totalNumAccess ?? 0} ${t("回")}`,
        icon: {
          /** Expected number of access is 1, otherwise warn */
          key: (ipAddressSection?.totalNumAccess ?? 0) === 1 ? "CHECK" : "WARNING",
          title: t("IPアドレス検知回数"),
        },
      },
      ipAddressSection: ipAddressSection,
      canShowTooltip: args.canShowStatistic,
    };
  }, [args.canShowCheatDetectionSection, args.canShowStatistic, ipAddressSection, statistics?.avgNumTabEvent, submissions, t]);
};
