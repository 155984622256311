import { useSnapshot } from "valtio";

import { state } from "./State";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = (): boolean => {
  const snapshot = useSnapshotState();
  return !!snapshot.screening;
};

export const useScreening = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.screening) throw new Error("Screening is not initialized");

  return snapshot.screening;
};
