import { createDefaultState } from "./definitions";
import { state } from "./State";
import type * as Types from "./types";

export const clear = () => {
  const defaultState = createDefaultState();
  state.temporarySelectedQuestions.clear();
  state.searchQuery = defaultState.searchQuery;
  state.questions.clear();
  state.pageQuestions.clear();
  state.pager = defaultState.pager;
  state.page = defaultState.page;
  state.selectedQuestions.clear();
};

export const setTemporallySelectedQuestions = (questions: Types.Question[]) => {
  questions.forEach(question => {
    state.temporarySelectedQuestions.set(`${question.id}-${question.version}`, question);
    state.selectedQuestions.set(`${question.id}-${question.version}`, question);
  });
};

export const updateQuestion = (question: Types.Question) => {
  state.questions.set(`${question.id}-${question.version}`, question);
  state.selectedQuestions.set(`${question.id}-${question.version}`, question);
};

export const updateQuestions = (questions: Types.Question[]) => {
  questions.forEach(question => {
    state.questions.set(`${question.id}-${question.version}`, question);
    if (state.temporarySelectedQuestions.has(`${question.id}-${question.version}`)) {
      state.temporarySelectedQuestions.delete(`${question.id}-${question.version}`);
    }
  });
  state.pageQuestions.set(
    state.page,
    questions.map(question => `${question.id}-${question.version}` as Types.UniqueKey),
  );
};

export const setRecordCount = (count: number) => {
  state.recordCount = count;
};

export const updatePager = (pager: Types.Pager | null) => {
  state.searchQuery.cursorSeconds = pager?.cursorSeconds;
  state.searchQuery.cursorNanoSeconds = pager?.cursorNanoSeconds;
};

export const updatePage = (page: number) => {
  state.page = page;
};

export const removeSelectQuestion = (questionId: Types.UniqueKey) => {
  state.selectedQuestions.delete(questionId);
};

export const toggleSelectQuestion = (question: Types.Question) => {
  if (state.selectedQuestions.has(`${question.id}-${question.version}`)) {
    state.selectedQuestions.delete(`${question.id}-${question.version}`);
  } else {
    state.selectedQuestions.set(`${question.id}-${question.version}`, question);
  }
};

const resetSearchResult = () => {
  const defaultState = createDefaultState();
  state.pager = defaultState.pager;
  state.page = defaultState.page;
  state.pageQuestions.clear();
};

export const updateSearchText = (text: string) => {
  if (state.searchQuery.queryString === text) {
    return;
  }
  resetSearchResult();
  state.searchQuery.queryString = text;
};

export const updateSearchTags = (tagIds: number[]) => {
  resetSearchResult();
  state.searchQuery.tagIds = tagIds;
};

export const removeSearchTag = (tagId: number) => {
  resetSearchResult();
  state.searchQuery.tagIds = state.searchQuery.tagIds.filter(id => id !== tagId);
};
