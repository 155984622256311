import { proxy } from "valtio";

import type * as Types from "./types";

export const state = proxy<Types.State>({
  customer: null,
  submitValue: null,
  temporarySelectionItems: [],
  showingTarget: "EDITOR",
  interviews: [],
});
