import { useTranslation } from "@hireroo/i18n";
import Language from "@mui/icons-material/Language";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import Tab, { TabProps } from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as React from "react";

import EditMenu, { EditMenuProps } from "./parts/EditMenu";
import TabItem, { TabItemProps } from "./parts/TabItem";

export type TabFieldItem = {
  /**
   * A value to identify the tab.
   * Do not use variable values such as i18n values.
   */
  id: string;
  name: string;
  tab: Omit<TabItemProps, "children">;
  Content: React.ReactNode;
};

export type EditLanguageTabProps = {
  onTabsChange?: (currentValue: string) => void;
  defaultTab?: string;
  menu: Pick<EditMenuProps, "items">;
  items: TabFieldItem[];
  addButton?: Pick<ButtonProps, "disabled">;
};

const EditLanguageTab: React.FC<EditLanguageTabProps> = props => {
  const theme = useTheme();
  const { menu, items, onTabsChange } = props;
  const defaultTab = React.useMemo(() => {
    return props.defaultTab || (items.length ? items[0].id : "");
  }, [items, props.defaultTab]);
  const [currentName, updateCurrentSelectedName] = React.useState(defaultTab);
  const [currentTabIds, setCurrentTabIds] = React.useState(items.map(item => item.id));
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const { t } = useTranslation();
  React.useEffect(() => {
    if (defaultTab) {
      updateCurrentSelectedName(defaultTab);
    }
  }, [defaultTab]);
  React.useEffect(() => {
    if (0 < items.length && items.length < currentTabIds.length) {
      // Decrease tab
      updateCurrentSelectedName(items[0].id);
      setCurrentTabIds(items.map(item => item.id));
    } else if (items.length > currentTabIds.length) {
      // Increase tab
      const addedItems = items.filter(item => !currentTabIds.includes(item.id));
      if (addedItems.length) {
        updateCurrentSelectedName(addedItems[0].id);
      }
      setCurrentTabIds(items.map(item => item.id));
    }
  }, [currentTabIds, items]);
  React.useEffect(() => {
    if (menu.items.length === 0) {
      setAnchorEl(null);
    }
  }, [menu.items.length]);
  React.useEffect(() => {
    onTabsChange?.(currentName);
  }, [currentName, onTabsChange]);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    props.onTabsChange?.(newValue);
    updateCurrentSelectedName(newValue);
  };
  const TabPanels = props.items.map(item => {
    return (
      <TabPanel key={item.id} value={item.id} sx={{ paddingX: 0 }}>
        {item.Content}
      </TabPanel>
    );
  });
  const editMenuProps: EditMenuProps = {
    items: menu.items,
    anchorEl,
    onClose: () => {
      setAnchorEl(null);
    },
  };
  const addLanguageButton: ButtonProps = {
    disabled: editMenuProps ? editMenuProps.items.length === 0 || props.addButton?.disabled : true,
    variant: "text",
    startIcon: <Language fontSize="small" />,
    children: t("言語を追加する"),
    onClick: event => {
      setAnchorEl(event.currentTarget);
    },
  };
  return (
    <TabContext value={currentName}>
      <Box display="flex" justifyContent="space-between" sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
        <Tabs value={currentName} onChange={handleChange}>
          {items.map(item => {
            const showingCloseButton = currentName === item.id && 1 < items.length;
            const tabItemProps: TabItemProps = {
              ...item.tab,
              closeButton: showingCloseButton
                ? {
                    ...item.tab.closeButton,
                    onClick: event => {
                      event.stopPropagation();
                      updateCurrentSelectedName(defaultTab);
                      item.tab.closeButton?.onClick?.(event);
                    },
                  }
                : undefined,
              children: item.name,
            };
            const tabProps: TabProps = {
              "aria-labelledby": item.name,
              value: item.id,
              label: <TabItem {...tabItemProps} />,
            };
            return <Tab key={item.name} {...tabProps} />;
          })}
        </Tabs>
        <Button {...addLanguageButton} />
        <EditMenu {...editMenuProps} />
      </Box>
      <Box>{TabPanels}</Box>
    </TabContext>
  );
};

EditLanguageTab.displayName = "EditLanguageTab";

export default EditLanguageTab;
