import type * as Interface from "@hireroo/evaluation-result-interface";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";

import CollapseTableRow, { CollapseTableRowProps } from "./parts/CollapseTableRow/CollapseTableRow";

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  borderRadius: "4px",
  border: "1px solid",
  borderColor: theme.palette.Other.Divider,
  overflow: "auto",
  maxHeight: "360px",
}));

export type TableDiffProps = Interface.TableDiff["value"];

const TableDiff: React.FC<TableDiffProps> = props => {
  return (
    <StyledTableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell key="icon-header" width="10%" />
            {props.headers.map((header, index) => (
              <TableCell key={`${header}-${index}`} width="30%">
                {header}
              </TableCell>
            ))}
            <TableCell key="collapse-button-header" width="10%" />
          </TableRow>
        </TableHead>
        <TableBody>
          {props.bodies.map((row, index) => {
            const collapseTableRowProps: CollapseTableRowProps = {
              actualRow: row.actualRow,
              expectedRow: row.expectedRow,
              isCorrect: row.isCorrect,
            };
            return <CollapseTableRow key={`row-${index}`} {...collapseTableRowProps} />;
          })}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

TableDiff.displayName = "TableDiff";

export default TableDiff;
