/**
 * TODO Move this to @hireroo/project
 */
import * as z from "zod";

export const generateBackendCorrectnessTestResultSchema = () => {
  return z.object({
    is_passed: z.boolean(),
    status: z.number(),
    expected: z.string(),
    output: z.string(),
    latency: z.number(),
  });
};

export type BackendCorrectnessTestResultSchema = z.infer<ReturnType<typeof generateBackendCorrectnessTestResultSchema>>;

export const generateBackendCorrectnessTestResultsSchema = () => {
  const result = generateBackendCorrectnessTestResultSchema();
  return result.array();
};

export type BackendCorrectnessTestResultsSchema = z.infer<ReturnType<typeof generateBackendCorrectnessTestResultsSchema>>;

export const generateBackendCorrectnessTestCase = () => {
  return z.object({
    path: z.string(),
    query: z.string().optional(),
    headers: z.record(z.string(), z.string()),
    method: z.string(),
    body: z.string(),
    expected: z.string(),
    status: z.number(),
    timeout: z.number(),
    is_hidden: z.boolean().optional(),
  });
};

export type BackendCorrectnessTestCaseSchema = z.infer<ReturnType<typeof generateBackendCorrectnessTestCase>>;

export const generateBackendCorrectnessTestCases = () => {
  const testCase = generateBackendCorrectnessTestCase();
  return testCase.array();
};

export type BackendCorrectnessTestCasesSchema = z.infer<ReturnType<typeof generateBackendCorrectnessTestCases>>;

export const generatePerformanceTestCaseResult = () => {
  return z.object({
    is_passed: z.boolean(),
    avg: z.number(),
    min: z.number(),
    max: z.number(),
    p50: z.number(),
    p90: z.number(),
    p95: z.number(),
    p99: z.number(),
    rps: z.number(),
    sla: z.number(),
    s100: z.number(),
    s200: z.number(),
    s300: z.number(),
    s400: z.number(),
    s500: z.number(),
  });
};

export type PerformanceTestCaseResultSchema = z.infer<ReturnType<typeof generatePerformanceTestCaseResult>>;

export const generatePerformanceTestCaseResults = () => {
  const result = generatePerformanceTestCaseResult();
  return result.array();
};

export type PerformanceTestCaseResultsSchema = z.infer<ReturnType<typeof generatePerformanceTestCaseResults>>;
