import Logo from "@hireroo/ui-assets/images/Logo/Logo_w200.png";
import AppBar from "@mui/material/AppBar";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  border: "none",
  borderBottom: `solid 1px ${theme.palette["Secondary/Shades"].p16}`,
  backgroundColor: theme.palette["Background/Paper"].p2,
}));

export type InvitationHeaderProps = {};

const InvitationHeader: React.FC<InvitationHeaderProps> = _props => {
  return (
    <StyledAppBar elevation={0} sx={{ width: "100%", py: 0.5, px: 1.5, height: "48px" }}>
      <Stack direction="row" alignItems="center" height="100%">
        <img src={Logo} width={100} alt="hireroo" />
      </Stack>
    </StyledAppBar>
  );
};

InvitationHeader.displayName = "InvitationHeader";

export default InvitationHeader;
