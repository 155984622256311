import AccessTimeOutlined from "@mui/icons-material/AccessTimeOutlined";
import GroupOutlined from "@mui/icons-material/GroupOutlined";
import PersonOutlineOutlined from "@mui/icons-material/PersonOutlineOutlined";
import TrendingUpOutlined from "@mui/icons-material/TrendingUpOutlined";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";

const StyledListItem = styled(ListItem)(() => ({
  padding: 0,
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  overflow: "hidden",
  marginRight: "8px",
  minWidth: 0,
  svg: {
    width: 18,
    height: 18,
  },
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  overflow: "hidden",
  ".MuiTypography-root": {
    fontSize: 12,
  },
}));

const IconMap = {
  ACCESS_TIME: <AccessTimeOutlined />,
  GROUP: <GroupOutlined />,
  PERSON: <PersonOutlineOutlined />,
  TRENDING_UP: <TrendingUpOutlined />,
} as const;

export type TextListItemProps = {
  icon: keyof typeof IconMap;
  text: string;
  tooltipText: string;
};

const TextListItem: React.FC<TextListItemProps> = props => {
  const Icon = IconMap[props.icon];
  return (
    <Tooltip title={props.tooltipText}>
      <StyledListItem>
        <StyledListItemIcon>{Icon}</StyledListItemIcon>
        <StyledListItemText>{props.text}</StyledListItemText>
      </StyledListItem>
    </Tooltip>
  );
};

TextListItem.displayName = "TextListItem";

export default TextListItem;
