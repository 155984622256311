import * as React from "react";
import CopyToClipboard from "react-copy-to-clipboard";

import ButtonWithTooltip, { ButtonWithTooltipProps } from "../ButtonWithTooltip/ButtonWithTooltip";

export type CopyToClipboardButtonProps = {
  name: string;
  url: string;
  buttonWithTooltip: Omit<ButtonWithTooltipProps, "children">;
  onCopy?: () => void;
  startIcon?: ButtonWithTooltipProps["startIcon"];
};

const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = props => {
  const startIcon = props.startIcon ?? "CONTENT_COPY";
  return (
    <CopyToClipboard text={props.url} onCopy={props.onCopy}>
      <ButtonWithTooltip startIcon={startIcon} color="secondary" {...props.buttonWithTooltip} children={props.name} />
    </CopyToClipboard>
  );
};

CopyToClipboardButton.displayName = "CopyToClipboardButton";

export default CopyToClipboardButton;
