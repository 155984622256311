import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";

type Variant = "OUTLINE" | "STANDARD";

export type SymbolButtonProps = Omit<IconButtonProps, "title"> & {
  title?: string | undefined | false;
  /** default is STANDARD */
  variant?: Variant;
};
type ButtonStatus = "NORMAL" | "DISABLED";

const SymbolButton: React.FC<SymbolButtonProps> = props => {
  const theme = useTheme();
  const { children, variant, title, disabled, ...rest } = props;
  const buttonStatus: ButtonStatus = disabled ? "DISABLED" : "NORMAL";
  const buttonVariant = variant ?? "STANDARD";

  const buttonVariantSxMap = {
    OUTLINE: {
      border: "1px solid",
      borderColor: theme.palette.divider,
    },
    STANDARD: {},
  } satisfies Record<Variant, IconButtonProps["sx"]>;

  const commonIconButtonSx: IconButtonProps["sx"] = {
    width: "40px",
    height: "40px",
    ...buttonVariantSxMap[buttonVariant],
    ...rest.sx,
    borderRadius: "8px",
  };

  const ButtonMap: Record<ButtonStatus, React.ReactElement> = {
    DISABLED: (
      <span>
        <IconButton role="disabled-button" disabled size="small" {...rest} sx={commonIconButtonSx}>
          {children}
        </IconButton>
      </span>
    ),
    NORMAL: (
      <IconButton role="button" size="small" {...rest} sx={commonIconButtonSx}>
        {children}
      </IconButton>
    ),
  };

  return (
    <Tooltip role="tooltip" title={title ?? ""}>
      {ButtonMap[buttonStatus] ?? ButtonMap["NORMAL"]}
    </Tooltip>
  );
};

SymbolButton.displayName = "SymbolButton";

export default SymbolButton;
