import { useAnswerLabelMap } from "@hireroo/challenge/definition";
import { SupportLanguageValue, useTranslation } from "@hireroo/i18n";
import { FreepadAnswerForm as AnswerForm } from "@hireroo/validator";
import { ErrorMessage } from "@hookform/error-message";
import { Add } from "@mui/icons-material";
import QuestionAnswer from "@mui/icons-material/QuestionAnswer";
import Warning from "@mui/icons-material/Warning";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, useFieldArray } from "react-hook-form";

import AddableTabs, { AddableTabsProps } from "../../primitive/Tab/AddableTabs/AddableTabs";
import { useFreepadAnswerFormContext } from "./Context";
import AnswerInputDetails, { AnswerInputDetailsProps } from "./parts/AnswerInputDetails/AnswerInputDetails";
import CodeSection, { CodeSectionProps } from "./parts/CodeSection/CodeSection";
import FlowChartSection, { FlowChartSectionProps } from "./parts/FlowChartSection/FlowChartSection";

export { FreepadAnswerFormProvider, type FreepadAnswerFormProviderProps, useFreepadAnswerFormContext } from "./Context";

const useAnswersErrorMessages = () => {
  const { t } = useTranslation();
  const { methods } = useFreepadAnswerFormContext();

  const { errors } = methods.formState;
  const answerLabelMap = useAnswerLabelMap();

  const errorMessages: string[] = [];
  if (errors) {
    const maxLength = methods.getValues("answers").length;
    for (let i = 0; i < maxLength; i++) {
      const answer = errors.answers?.[i];
      if (answer) {
        const label = methods.getValues(`answers.${i}.label`);
        errorMessages.push(`${t("タブの入力に不備があります。")} (${answerLabelMap[label]})`);
      }
    }
  }
  return errorMessages;
};

export type FreepadAnswerFormProps = {
  details: Omit<AnswerInputDetailsProps, "index">;
  codeSection: Omit<CodeSectionProps, "answerIndex">;
  flowChartSection: Omit<FlowChartSectionProps, "answerIndex">;
  baseLanguage: SupportLanguageValue;
  defaultCodeBody?: string;
  defaultRuntime?: string;
};

const FreepadAnswerForm: React.FC<FreepadAnswerFormProps> = props => {
  const { t } = useTranslation();
  const { methods, selectedAnswerIndex, updateSelectedAnswerIndex, variant } = useFreepadAnswerFormContext();
  const theme = useTheme();
  const answerLabelMap = useAnswerLabelMap();

  const errorMessages = useAnswersErrorMessages();
  const initRuntime = props.defaultRuntime ?? "python3";

  const answers = useFieldArray({
    control: methods.control,
    name: `answers`,
  });

  const addAnswer = (label: string) => {
    const runtimes: AnswerForm.FreepadAnswersFormSchema["answers"][0]["answerCodes"] =
      variant === "ALGORITHM" ? [{ runtime: initRuntime, body: props.defaultCodeBody ?? "" }] : [];
    const flowCharts: AnswerForm.FreepadAnswersFormSchema["answers"][0]["answerFlowCharts"] =
      variant === "SYSTEM_DESIGN" ? [{ componentType: "default", body: "" }] : [];
    answers.append({
      label: label,
      contents: [
        {
          title: "",
          description: "",
          language: props.baseLanguage,
        },
      ],
      answerCodes: runtimes,
      answerFlowCharts: flowCharts,
    });
  };

  const answerTabs: AddableTabsProps = {
    defaultTab: "0",
    addTabButton: {
      size: "small",
      variant: "text",
      startIcon: <Add fontSize="small" />,
      children: t("解答を追加する"),
    },
    currentTabValue: selectedAnswerIndex,
    updateCurrentTabValue: updateSelectedAnswerIndex,
    tabListBox: {
      sx: {
        bgcolor: theme.palette.background.paper,
      },
    },
    items: answers.fields.map((answer, index) => {
      return {
        id: index.toString(),
        //TODO: show number when label is duplicate
        name: answerLabelMap[answer.label],
        tab: {
          closeButton: {
            onClick: () => {
              answers.remove(index);
            },
          },
        },
        Content: (
          <Box justifyContent="space-between" alignItems="center" p={1} px={2}>
            <AnswerInputDetails index={index} {...props.details} />
            {variant === "ALGORITHM" && <CodeSection {...props.codeSection} answerIndex={index} />}
            {variant === "SYSTEM_DESIGN" && <FlowChartSection {...props.flowChartSection} answerIndex={index} />}
          </Box>
        ),
      };
    }),
    menu: {
      items: Object.keys(answerLabelMap).map(answerLabel => {
        return {
          text: answerLabelMap[answerLabel],
          value: answerLabel,
          onClick: async () => {
            const tabIndex = Number(selectedAnswerIndex);
            const isValid = await methods.trigger([
              `answers.${tabIndex}`,
              `answers.${tabIndex}.contents`,
              `answers.${tabIndex}.answerCodes`,
              `answers.${tabIndex}.answerFlowCharts`,
            ]);
            if (!isValid) return;
            addAnswer(answerLabel);
          },
        };
      }),
    },
  };

  return (
    <FormProvider {...methods}>
      <ErrorMessage
        errors={methods.formState.errors}
        name="answers"
        render={() => (
          <Box display="flex" flexDirection="column" mb={2}>
            <Box display="flex" gap={1}>
              <Warning color="error" fontSize="small" />
              <Typography color="error" variant="body2">
                {t("解答の入力に不備があります。")}
              </Typography>
            </Box>

            <Box mt={1}>
              {errorMessages.map(errorMessage => (
                <Box key={errorMessage}>
                  <Typography variant="caption" color="error">
                    {errorMessage}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      />
      <Box display="flex" alignItems="center" mb={2}>
        <Box display="flex" alignItems="center">
          <Box mr={1}>
            <QuestionAnswer fontSize="small" sx={{ color: theme.palette.common.white }} />
          </Box>
          <Box mr={2}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              {t("解答を入力する")}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box mb={2}>
        <Typography variant="body2">
          {t("解答のタイトルや解答文を入力します。解答文で利用する言語は複数入力可能です。メインで利用する言語以外の入力は任意です。")}
        </Typography>
      </Box>

      <AddableTabs {...answerTabs} />
    </FormProvider>
  );
};

FreepadAnswerForm.displayName = "FreepadAnswerForm";

export default FreepadAnswerForm;
