import * as React from "react";

import NumberCustomField, { NumberCustomFieldProps } from "./parts/NumberCustomField/NumberCustomField";
import StringCustomField, { StringCustomFieldProps } from "./parts/StringCustomField/StringCustomField";

export type FieldFactoryProps =
  | {
      type: "TEXT";
      field: StringCustomFieldProps;
    }
  | {
      type: "INTEGER";
      field: Omit<NumberCustomFieldProps, "valueType">;
    }
  | {
      type: "FLOAT";
      field: Omit<NumberCustomFieldProps, "valueType">;
    };

const FieldFactory: React.FC<FieldFactoryProps> = props => {
  const { type } = props;
  switch (type) {
    case "TEXT":
      return <StringCustomField {...props.field} />;
    case "INTEGER":
      return <NumberCustomField {...props.field} valueType="INTEGER" />;
    case "FLOAT":
      return <NumberCustomField {...props.field} valueType="FLOAT" />;
    default:
      throw new Error(`Invalid type ${type satisfies never}`);
  }
};

FieldFactory.displayName = "FieldFactory";

export default FieldFactory;
