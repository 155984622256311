import { ChallengeCodingEditor } from "@hireroo/app-store/widget/shared/ChallengeCodingEditor";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { getTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { ChallengeCodingEditorForm } from "@hireroo/validator";
import * as Sentry from "@sentry/browser";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";

import { generateMessageCards } from "./privateHelper";

export type GenerateChallengeCodingEditorChatGPTRightSidePanelPropsArgs = {
  chatGPTSession: ChallengeCodingEditor.ChallengeChatGPTSession;
};

export const useGenerateProps = (
  args: GenerateChallengeCodingEditorChatGPTRightSidePanelPropsArgs,
): Widget.ChallengeCodingEditorChatGPTRightSidePanelProps => {
  const { chatGPTSession } = args;
  const { t } = getTranslation();
  const client = getGraphqlClient();
  const chatId = chatGPTSession.currentChatId;
  const messages = chatGPTSession.currentChatGPTMessages;
  const { appendUserChatMessage, resetChatMessages, setChatGPTResponse } = ChallengeCodingEditor.createChallengeEntityAction(
    chatGPTSession.challengeId,
  );
  const chatGPTLoadingStatus = ChallengeCodingEditor.useChatGPTLoadingStatus();

  const askChatGPT: SubmitHandler<ChallengeCodingEditorForm.ChatGPTFormSchema> = React.useCallback(
    async data => {
      if (data.prompt === "") {
        return;
      }

      ChallengeCodingEditor.updateChatGPTLoadingStatus("LOADING");
      appendUserChatMessage(data.prompt);

      await client
        .AskChatGPTForChallengeCodingEditor({
          input: {
            chatId: chatId,
            sessionId: chatGPTSession.sessionId,
            prompt: data.prompt,
            gptModel: "GPT_4o",
          },
        })
        .then(res => {
          setChatGPTResponse(res.askChatGPT);
        })
        .catch(error => {
          Sentry.captureException(error);

          Snackbar.notify({
            severity: "error",
            message: t("メッセージの送信に失敗しました。しばらくしてから再度お試しください。"),
          });
        })
        .finally(() => {
          ChallengeCodingEditor.updateChatGPTLoadingStatus("NONE");
        });
    },
    [t, client, chatId, chatGPTSession, appendUserChatMessage, setChatGPTResponse],
  );

  const handleClickResetButton = chatId
    ? async () => {
        ChallengeCodingEditor.updateChatGPTLoadingStatus("RESETTING");

        await client
          .ResetChatGPTConversationForChallengeCodingEditor({
            input: {
              sessionId: chatGPTSession.sessionId,
              chatId: chatId,
            },
          })
          .then(() => {
            resetChatMessages();
          })
          .catch(error => {
            Sentry.captureException(error);

            Snackbar.notify({
              severity: "error",
              message: t("チャットの履歴のリセットに失敗しました。しばらくしてから再度お試しください。"),
            });
          })
          .finally(() => {
            ChallengeCodingEditor.updateChatGPTLoadingStatus("NONE");
          });
      }
    : undefined;

  return {
    chatGPTSidePanel: {
      loadingStatus: chatGPTLoadingStatus,
      messageCards: generateMessageCards([...messages]),
      promptTextField: {
        onSubmit: askChatGPT,
        resetButton: {
          onClick: handleClickResetButton,
          disabled: !chatId,
        },
      },
      gptModel: "GPT-4o",
    },
  };
};
