import escapeStringRegexp from "escape-string-regexp";
import { useSnapshot } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = () => {
  const snapshot = useSnapshotState();
  return !!snapshot.company;
};

export const useCompany = (): Types.Company => {
  const snapshot = useSnapshotState();
  if (!snapshot.company) {
    throw new Error("Not initialized company");
  }
  return snapshot.company as Types.Company;
};

export const useGroups = (): Types.Group[] => {
  const company = useCompany();
  return company.groups;
};

export const useGroupMap = (): Record<string, Types.Group | undefined> => {
  const groups = useGroups();
  return groups.reduce((all, group) => {
    return { ...all, [group.id]: group };
  }, {});
};

export const useEmployees = () => {
  const company = useCompany();
  return company.employees;
};

export const useFilteredEmployees = () => {
  const snapshot = useSnapshotState();
  const company = useCompany();
  const regex = new RegExp(escapeStringRegexp(snapshot.filterText));
  const textFilter = (employee: Types.Employee): boolean => {
    if (snapshot.filterText === "") {
      return true;
    }
    return regex.test(employee.displayName) || regex.test(employee.email);
  };
  return company.employees.filter(textFilter);
};

export const useCompanyEmployeeMap = () => {
  const company = useCompany();
  return company.companyEmployees.reduce<Record<string, Types.CompanyEmployee | undefined>>((all, companyEmployee) => {
    return { ...all, [companyEmployee.employeeId]: companyEmployee };
  }, {});
};

export const useEmployeeMap = (): Record<string, Types.Employee | undefined> => {
  const { company } = useSnapshotState();
  if (!company) {
    return {};
  }
  const employeeMap = company.employees.reduce<Record<string, Types.Employee | undefined>>((all, employee) => {
    return { ...all, [employee.id]: employee };
  }, {});
  return employeeMap;
};

export const useEmployeeInvitation = () => {
  const snapshot = useSnapshotState();
  const invitationState = snapshot.employeeInvitation;
  return {
    id: invitationState.id,
    status: invitationState.status,
  };
};

export const useTalentInvitation = () => {
  const snapshot = useSnapshotState();
  const invitationState = snapshot.talentInvitation;
  return {
    id: invitationState.id,
    status: invitationState.status,
  };
};

export const useEditRole = () => {
  const snapshot = useSnapshotState();
  const editRoleState = snapshot.editRole;
  const employeeMap = useEmployeeMap();
  const companyEmployeeMap = useCompanyEmployeeMap();
  const selectedEmployee = editRoleState.selectedMemberId ? employeeMap[editRoleState.selectedMemberId] : undefined;
  const companyEmployee = editRoleState.selectedMemberId ? companyEmployeeMap[editRoleState.selectedMemberId] : undefined;

  return {
    employee: selectedEmployee,
    role: companyEmployee?.role,
  };
};

export const useDeleteMember = () => {
  const snapshot = useSnapshotState();
  const deleteMemberState = snapshot.deleteMember;
  const employeeMap = useEmployeeMap();
  const selectedEmployee = deleteMemberState.selectedMemberId ? employeeMap[deleteMemberState.selectedMemberId] : undefined;

  return {
    employee: selectedEmployee,
  };
};

export const useDeleteMembers = () => {
  const snapshot = useSnapshotState();
  const employeeMap = useEmployeeMap();

  return {
    employees: snapshot.selectedMemberIds
      .map(id => {
        return employeeMap[id];
      })
      .flatMap(v => (v ? [v] : [])),
  };
};

export const useSelectedMembers = () => {
  const snapshot = useSnapshotState();
  const employeeMap = useEmployeeMap();

  return {
    employees: snapshot.selectedMemberIds.reduce<Types.Employee[]>((all, id) => {
      const employee = employeeMap[id];
      if (employee) {
        return [...all, employee];
      }
      return all;
    }, []),
  };
};

export const useAddMemberToGroup = () => {
  const snapshot = useSnapshotState();
  const addMemberToGroupsState = snapshot.addMemberToGroups;
  const groups = useGroups();
  const groupMap = useGroupMap();
  const addMembersState = snapshot.addMemberToGroups;
  const notBelongsGroups = groups.filter(group => {
    if (!addMemberToGroupsState.selectedMemberId) {
      return false;
    }
    const alreadyBelongs = group.companyEmployees.find(
      companyEmployee => companyEmployee.employeeId === addMemberToGroupsState.selectedMemberId,
    );
    return !alreadyBelongs;
  });
  const regex = new RegExp(escapeStringRegexp(addMembersState.searchText));
  const groupNameFilter = (group: Types.Group): boolean => {
    if (addMembersState.searchText === "") {
      return true;
    }
    return regex.test(group.groupName);
  };

  return {
    selectableGroups: notBelongsGroups
      .filter(group => {
        return !addMemberToGroupsState.selectedGroupIds.includes(group.id);
      })
      .filter(groupNameFilter),
    selectedGroups: addMemberToGroupsState.selectedGroupIds
      .map(id => {
        return groupMap[id] || undefined;
      })
      .flatMap(v => (v ? [v] : [])),
  };
};

export const useAddMembersToGroups = () => {
  const snapshot = useSnapshotState();
  const selectedMembers = useSelectedMembers();
  const groups = useGroups();
  const notBelongsGroups = groups.filter(group => {
    if (snapshot.selectedMemberIds.length === 0) {
      return false;
    }
    const alreadyBelongs = group.companyEmployees.find(companyEmployee => snapshot.selectedMemberIds.includes(companyEmployee.employeeId));
    return !alreadyBelongs;
  });

  return {
    selectedMembers: selectedMembers.employees,
    selectableGroups: notBelongsGroups,
  };
};

export const useDialogStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.dialogStatus;
};
