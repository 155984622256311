import { useTranslation } from "@hireroo/i18n";
import * as z from "zod";

import * as AssignField from "./fields/AssignField";

export const useEmployeeGroupSettingsSchema = () => {
  const { t } = useTranslation();
  const employee = AssignField.useAssignListItem();
  return z.object({
    name: z.string().min(1, { message: t("グループ名は必須です。") }),
    employees: employee.array(),
  });
};

export type EmployeeGroupSettingsSchema = z.infer<ReturnType<typeof useEmployeeGroupSettingsSchema>>;

export const useGroupSearchForm = () => {
  return z.object({
    textFilter: z.string(),
  });
};

export type GroupSearchFormSchema = z.infer<ReturnType<typeof useGroupSearchForm>>;
