import { useTranslation as useTranslationApi } from "react-i18next";

import { CONTEXT_SEPARATOR, NAMESPACE } from "./constants";
import { TranslationContext } from "./definition";
import commonEn from "./en/CommonWithVariables.json" assert { type: "json" };
import TranslationJSON from "./en/translation.json" assert { type: "json" };

type TranslationKey = Exclude<keyof typeof TranslationJSON, `${string}${CONTEXT_SEPARATOR}${string}`>;

export interface TranslationAction {
  t: (key: TranslationKey, options?: { context?: TranslationContext }) => string;
}

export const useTranslation = (): TranslationAction => {
  const { t } = useTranslationApi();
  return { t };
};

export interface TranslationWithVariableAction {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  t: (key: keyof typeof commonEn, variableMap?: Record<string, any>) => string;
}

export const useTranslationWithVariable = (): TranslationWithVariableAction => {
  const { t } = useTranslationApi(NAMESPACE.COMMON_WITH_VARIABLES);
  return { t };
};

export type SupportLanguage = "en" | "ja";

export const useCurrentLanguage = (): string => {
  const { i18n } = useTranslationApi();
  return i18n.language;
};

export const useLanguageCode = (): SupportLanguage => {
  const { i18n } = useTranslationApi();
  // TODO If a case other than SupportLanguage comes, summarize it in SupportLanguage.
  return i18n.language as SupportLanguage;
};

type ChangeLanguage = (lang: SupportLanguage) => void;

export const useChangeLanguage = (): ChangeLanguage => {
  const { i18n } = useTranslationApi();
  return lang => {
    i18n.changeLanguage(lang);
  };
};
