import { OrderHistory } from "@hireroo/app-store/widget/e/OrderHistory";
import { formatPrice } from "@hireroo/formatter/money";
import * as Time from "@hireroo/formatter/time";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

type RowProps = Widget.OrderHistoryProps["rows"][0];

export type GenerateOrderHistoryPropsArgs = {};

export const useGenerateProps = (_args: GenerateOrderHistoryPropsArgs): Widget.OrderHistoryProps => {
  const { t } = useTranslation();
  const purchases = OrderHistory.usePurchases();
  const lang = useLanguageCode();
  const fetchSize = OrderHistory.useFetchSize();
  const page = OrderHistory.usePage();
  const count = OrderHistory.useCount();
  const list = OrderHistory.useSelectablePurchasePeriodStart();
  const purchasePeriod = OrderHistory.usePurchasePeriod();
  const periods = React.useMemo<{ text: string; startSeconds: number }[]>(() => {
    return list.map(item => {
      return {
        text: Time.yearMonthFormat(item),
        startSeconds: item.getTime() / 1000,
      };
    });
  }, [list]);

  return {
    selectBox: {
      value: purchasePeriod.startSeconds,
      onChange: event => {
        OrderHistory.setPurchasePeriodStart(Number(event.target.value));
      },
      items: periods.map(period => {
        return {
          text: period.text,
          value: period.startSeconds,
        };
      }),
    },
    rows: purchases.reduce<RowProps[]>((all, purchase) => {
      const { plan, interview, seat } = purchase;
      if (interview) {
        return all.concat({
          itemId: purchase.purchaseId.toString(),
          name: resolveLanguage(interview, lang, "name"),
          unitPrice: formatPrice(purchase.unitPrice),
          amount: formatPrice(purchase.totalPrice),
          count: purchase.quantity.toString(),
          orderedDate: purchase.createdAtSeconds ? Time.unixToDateFormat(purchase.createdAtSeconds) : null,
        });
      }
      if (plan) {
        return all.concat({
          itemId: purchase.purchaseId.toString(),
          name: resolveLanguage(plan, lang, "name"),
          unitPrice: formatPrice(purchase.unitPrice),
          amount: formatPrice(purchase.totalPrice),
          orderedDate: purchase.createdAtSeconds ? Time.unixToDateFormat(purchase.createdAtSeconds) : null,
          count: purchase.quantity.toString(),
          description: t("プランの請求金額は利用月の中で最も高い料金のプランが翌月請求対象です。"),
        });
      }
      if (seat) {
        return all.concat({
          itemId: purchase.purchaseId.toString(),
          name: resolveLanguage(seat, lang, "name"),
          unitPrice: formatPrice(purchase.unitPrice),
          amount: formatPrice(purchase.totalPrice),
          orderedDate: purchase.createdAtSeconds ? Time.unixToDateFormat(purchase.createdAtSeconds) : null,
          count: purchase.quantity.toString(),
        });
      }
      return all;
    }, []),
    pagination: {
      count: count,
      page: page,
      rowsPerPage: fetchSize,
      rowsPerPageOptions: [20, 30, 50],
      onPageChange: (_, newPage) => {
        OrderHistory.setPage(newPage);
      },
      onRowsPerPageChange: event => {
        OrderHistory.updateFetchSize(Math.trunc(Number(event.target.value)));
      },
    },
  };
};
