import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";

import QuestionSection, { QuestionSectionProps } from "../../../../ms-components/Question/QuestionSection/QuestionSection";

export type LeftSidePanelProps = {
  questionSection: QuestionSectionProps;
};

const SidebarWrapper = styled(Paper)(() => ({
  position: "relative",
  overflow: "hidden",
  height: "100%",
  width: "100%",
  borderRadius: 0,
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  overflowY: "auto",
  height: "100%",
  width: "100%",
  padding: theme.spacing(1.5),
  backgroundColor: theme.palette["Secondary/Shades"].p12,
}));

const LeftSidePanel: React.FC<LeftSidePanelProps> = props => {
  const wrapperDetector = useResizeDetector();
  const questionProps: QuestionSectionProps = {
    ...props.questionSection,
  };
  return (
    <SidebarWrapper ref={wrapperDetector.ref} elevation={2}>
      <ContentWrapper>
        <QuestionSection {...questionProps} />
      </ContentWrapper>
    </SidebarWrapper>
  );
};

LeftSidePanel.displayName = "LeftSidePanel";

export default LeftSidePanel;
