import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import { TotalScoreRankVisualizer } from "@hireroo/app-store/widget/shared/TotalScoreRankVisualizer";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateHeaderProps } from "../../../../props-factory/v2/view-domain/ProjectTestReport/useGenerateHeaderProps";
import { useGenerateQuestionDetailProps } from "../../../../props-factory/v2/view-domain/ProjectTestReport/useGenerateQuestionDetailProps";
import { useStatusMessageMap } from "./privateHelper";
import * as Subscriber from "./Subscriber";
import { GenerateProjectTestReportPropsArgs, useGenerateProps } from "./useGenerateProps";

export type ProjectTestReportContainerProps = Omit<GenerateProjectTestReportPropsArgs, "uniqueKey"> & { companyId: number };

const ProjectTestReportContainer: React.FC<ProjectTestReportContainerProps> = props => {
  const { entityId: projectId, companyId } = props;

  const hooks = ProjectTestReport.useCreateProjectHooks(projectId);
  const question = hooks.useQuestion();
  const currentSubmission = hooks.useCurrentSubmission();
  const submissionStatus = hooks.useCurrentSubmissionStatus();
  const header = useGenerateHeaderProps({ entityId: projectId });
  const messageMap = useStatusMessageMap();
  const status = hooks.useStatus();
  const questionDetail = useGenerateQuestionDetailProps({ entityId: projectId, showAnswer: true, showArchivedMark: true });

  const uniqueKey = React.useMemo((): ProjectTestReport.UniqueKey | null => {
    if (!currentSubmission) {
      return null;
    }
    /**
     * Multiple submissions and unique by EntityId
     */
    return `Project-${projectId}-${currentSubmission.projectSubmissionId}`;
  }, [currentSubmission, projectId]);
  const projectTestReportProps = useGenerateProps({ ...props, uniqueKey: uniqueKey || `Project-${projectId}` });

  React.useEffect(() => {
    if (!currentSubmission || !uniqueKey || !question || (submissionStatus !== "EVALUATED" && submissionStatus !== "FAILED")) {
      return;
    }
    TotalScoreRankVisualizer.createTotalScoreStatisticsState({
      uniqueKey: uniqueKey,
      query: {
        endDate: currentSubmission?.evaluatedAtSeconds ? new Date(currentSubmission.evaluatedAtSeconds * 1000) : null,
      },
    });
    const stopSubscribeQueryKey = Subscriber.startSubscribeQueryKey({
      uniqueKey,
      entityId: projectId,
      companyId: companyId,
      submissionId: currentSubmission.projectSubmissionId,
      questionId: question.questionId,
    });
    const stopSubscribeProjectSubmission = Subscriber.startSubscribeProjectSubmission(projectId);
    TotalScoreRankVisualizer.refreshQuery(uniqueKey);
    return () => {
      stopSubscribeQueryKey?.();
      stopSubscribeProjectSubmission();
    };
  }, [companyId, currentSubmission, projectId, question, submissionStatus, uniqueKey]);

  if (!currentSubmission || !uniqueKey) {
    const projectProps: Widget.ProjectTestReportProps = {
      header: header,
      questionDetail: questionDetail,
      announcement: messageMap[status],
    };
    return <Widget.ProjectTestReport {...projectProps} />;
  }

  return (
    <ErrorBoundary>
      <Widget.ProjectTestReport {...projectTestReportProps} />
    </ErrorBoundary>
  );
};

ProjectTestReportContainer.displayName = "ProjectTestReportContainer";

export default withErrorBoundary(ProjectTestReportContainer, {});
