import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import * as React from "react";

import ButtonWithTooltip, { ButtonWithTooltipProps } from "../../../../../primitive/Button/ButtonWithTooltip/ButtonWithTooltip";
import FormSubmitButton, { FormSubmitButtonProps } from "../../../../../primitive/Button/FormSubmitButton/FormSubmitButton";

type Mode = "EDITING" | "READY";

export type FormActionButtonGroupProps = {
  cancelButton: FormSubmitButtonProps;
  saveButton: FormSubmitButtonProps;
  editButton: Pick<ButtonWithTooltipProps, "title" | "onClick" | "disabled">;
};

const FormActionButtonGroup: React.FC<FormActionButtonGroupProps> = props => {
  const { t } = useTranslation();
  const [mode, setMode] = React.useState<Mode>("READY");
  const formSubmitButtons: FormSubmitButtonProps[] = [
    {
      ...props.cancelButton,
      fullWidth: false,
      variant: "text",
      children: t("キャンセル"),
      onClick: event => {
        setMode("READY");
        props.cancelButton.onClick?.(event);
      },
      color: "secondary",
    },
    {
      ...props.saveButton,
      fullWidth: false,
      variant: "contained",
      children: t("保存"),
      onClick: event => {
        setMode("READY");
        props.saveButton.onClick?.(event);
      },
      color: "secondary",
    },
  ];
  const editButtonProps: ButtonWithTooltipProps = {
    ...props.editButton,
    startIcon: "EDIT",
    title: t("解答済み自由記述の設問のみ採点結果の編集が可能です"),
    children: t("採点結果を編集する"),
    onClick: event => {
      setMode("EDITING");
      props.editButton?.onClick?.(event);
    },
    color: "secondary",
  };
  const modeContentsMap: Record<Mode, React.ReactNode> = {
    EDITING: (
      <Stack direction="row" spacing={2}>
        {formSubmitButtons.map((formSubmitButton, index) => (
          <FormSubmitButton key={`action-${index}`} {...formSubmitButton} />
        ))}
      </Stack>
    ),
    READY: <ButtonWithTooltip {...editButtonProps} />,
  };
  return <Box>{modeContentsMap[mode]}</Box>;
};

FormActionButtonGroup.displayName = "FormActionButtonGroup";

export default FormActionButtonGroup;
