import { proxy } from "valtio";
import { proxyMap } from "valtio/utils";

import type * as Types from "./types";

export const state = proxy<Types.State>({
  interview: null,
  activeSessionId: 0,
  activeSessionMap: proxyMap(),
  activeVariant: "UNKNOWN",
  selectedRuntime: "",
  selectedComponentType: "default",
  participantMap: proxyMap(),
  refresh: () => undefined,
  editorOptions: [],
  selectedEditorOption: "",
  sliderValue: 0,
  playStatus: "PAUSE",

  challenge: {
    histories: {},
    playbackManagerMap: proxyMap(),
    historiesStatus: "NOT_INITIALIZED",
  },
  systemDesign: {
    histories: {},
    historiesStatus: "NOT_INITIALIZED",
  },
});
