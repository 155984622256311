import { useTranslation } from "@hireroo/i18n";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";

import TablePagination, { TablePaginationProps } from "../../../../modules/TablePagination/TablePagination";
import MetricsTableRow, { MetricsTableRowProps } from "../MetricsTableRow/MetricsTableRow";

const ColumnTitle = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  color: theme.palette.text.secondary,
  wordBreak: "keep-all",
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  "& .MuiTableCell-root": {
    borderColor: theme.palette["Secondary/Shades"].p12,
    padding: theme.spacing(1.5),
  },
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  borderTop: "1px solid",
  borderColor: theme.palette["Secondary/Shades"].p12,
}));

export type MetricsTableProps = {
  items: MetricsTableRowProps[];
  pagination: TablePaginationProps;
};

const MetricsTable: React.FC<MetricsTableProps> = props => {
  const { t } = useTranslation();
  return (
    <StyledTableContainer>
      <Table>
        <StyledTableHead>
          <TableRow key="company-members-header-row">
            <TableCell padding="checkbox">{/* spacer */}</TableCell>
            <TableCell width="30%">
              <ColumnTitle>{t("評価項目名")}</ColumnTitle>
            </TableCell>
            <TableCell sx={{ minWidth: "200px", maxWidth: "500px" }}>
              <ColumnTitle>{t("説明")}</ColumnTitle>
            </TableCell>
            <TableCell width="15%">
              <ColumnTitle>{t("更新日時")}</ColumnTitle>
            </TableCell>
            <TableCell align="right" width="5%">
              {/* spacer */}
            </TableCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {props.items.map(item => (
            <MetricsTableRow key={item.id} {...item} />
          ))}
          {props.items.length === 0 && (
            <TableCell colSpan={3}>
              <Typography fontSize="12px" color="textSecondary" textAlign="center">
                {t("評価項目がありません。")}
              </Typography>
            </TableCell>
          )}
        </TableBody>
      </Table>
      <TablePagination {...props.pagination} />
    </StyledTableContainer>
  );
};

MetricsTable.displayName = "MetricsTable";

export default MetricsTable;
