import { useTranslation } from "@hireroo/i18n";
import { CheckCircle } from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DivTableCell from "../../../../primitive/DivTableCell/DivTableCell";
import DivTableRow from "../../../../primitive/DivTableRow/DivTableRow";

const StyledTableRow = styled(DivTableRow)(({ theme }) => ({
  borderTop: "1px solid",
  borderBottom: "1px solid",
  borderColor: theme.palette["Gray/Shades"].p8,
  display: "table-row",
}));
const StyledTableCell = styled(DivTableCell)(({ theme }) => ({
  border: "none",
  align: "center",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  display: "table-cell",
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
}));

type Status = "INVITED" | "ACCEPTED";

export type InviteeTableRowProps = {
  email: string;
  status: Status;
};

const InviteeTableRow: React.FC<InviteeTableRowProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const statusMap: Record<Status, React.ReactNode> = {
    INVITED: (
      <Typography fontSize={14} color="textSecondary">
        {t("招待済み")}
      </Typography>
    ),
    ACCEPTED: (
      <Stack direction="row" spacing={0.5} alignItems="center" justifyContent="end">
        <CheckCircle color="success" fontSize="small" sx={{ width: "16px", height: "16px" }} />
        <Typography color={theme.palette.success.main} fontSize={14}>
          {t("テストリンク受領済み")}
        </Typography>
      </Stack>
    ),
  };
  return (
    <StyledTableRow>
      <StyledTableCell>{props.email}</StyledTableCell>
      <StyledTableCell sx={{ textAlign: "right" }}>{statusMap[props.status]}</StyledTableCell>
    </StyledTableRow>
  );
};

InviteeTableRow.displayName = "InviteeTableRow";

export default InviteeTableRow;
