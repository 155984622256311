import { RemotesIdDetailStore } from "@hireroo/app-store/page/e/remotes_id_detail";
import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type RemoteInterviewDetailContainerProps = {};

const RemoteInterviewDetailContainer: React.FC<RemoteInterviewDetailContainerProps> = () => {
  const remoteInterviewDetailProps = useGenerateProps({});
  React.useEffect(() => {
    return () => {
      RemotesIdDetailStore.clear();
    };
  }, []);

  return (
    <ErrorBoundary>
      <Pages.RemoteInterviewDetail {...remoteInterviewDetailProps} />
    </ErrorBoundary>
  );
};

RemoteInterviewDetailContainer.displayName = "RemoteInterviewDetailContainer";

export default withErrorBoundary(RemoteInterviewDetailContainer, {});
