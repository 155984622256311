import { AlgorithmSignatureForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import * as React from "react";
import { useForm, UseFormReturn } from "react-hook-form";

export type ContextValue = {
  methods: UseFormReturn<AlgorithmSignatureForm.AlgorithmSignatureFormSchema>;
};

export const AlgorithmFunctionDefinitionContext = React.createContext<ContextValue>({} as ContextValue);

export const useAlgorithmFunctionDefinitionContext = () => React.useContext(AlgorithmFunctionDefinitionContext);

export type AlgorithmFunctionDefinitionProviderProps = {
  defaultValues: AlgorithmSignatureForm.AlgorithmSignatureFormSchema;
};

export const AlgorithmFunctionDefinitionProvider: React.FC<React.PropsWithChildren<AlgorithmFunctionDefinitionProviderProps>> = props => {
  const { defaultValues } = props;
  const schema = AlgorithmSignatureForm.useAlgorithmSignatureFormSchema();
  const methods = useForm<AlgorithmSignatureForm.AlgorithmSignatureFormSchema>({
    resolver: zodResolver(schema),
    mode: "onSubmit",
    shouldUnregister: false,
    defaultValues,
  });

  const contextValue: ContextValue = {
    methods: methods,
  };
  return <AlgorithmFunctionDefinitionContext.Provider value={contextValue} children={props.children} />;
};
