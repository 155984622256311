export const getStartOfMonthDateUnixTimeSeconds = () => {
  const date = new Date();
  date.setDate(1); // Set the day to the first day of the month
  date.setHours(0, 0, 0, 0);
  return Math.floor(date.getTime() / 1000);
};

export const getEndOfMonthDateUnixTimeSeconds = () => {
  const date = new Date();
  date.setMonth(date.getMonth() + 1); // Move to the next month
  date.setDate(0); // Set the day to the last day of the current month
  date.setHours(23, 59, 59, 999); // Set time to 23:59:59.999 hours
  return Math.floor(date.getTime() / 1000);
};
