import * as Graphql from "@hireroo/graphql/client/urql";

import { DeepReadonly } from "../types";

export const createAnswerCodeInput = (
  answerCode: DeepReadonly<Graphql.AnswerCodeForFreepadResourceEditorFragment>,
): Graphql.FreepadAnswerCodeInput => {
  const { __typename, id: _id, freepadAnswerCodeId: _codeId, ...rest } = answerCode;
  return {
    ...rest,
    id: answerCode.freepadAnswerCodeId,
  };
};

export const createAnswerFlowChartInput = (
  flowChart: DeepReadonly<Graphql.AnswerFlowChartForFreepadResourceEditorFragment>,
): Graphql.FreepadAnswerFlowChartInput => {
  const { __typename, id: _id, freepadAnswerFlowChartId: _flowChartId, ...rest } = flowChart;
  return {
    ...rest,
    id: flowChart.freepadAnswerFlowChartId,
  };
};

export const createAnswerInput = (answerFragment: DeepReadonly<Graphql.AnswerForFreepadResourceEditorFragment>): Graphql.FreepadAnswerInput => {
  const { answerCodes, answerFlowCharts, __typename, id: _id, freepadAnswerId: _answerId, ...rest } = answerFragment;

  const answerCodesInput = answerCodes.map(r => {
    return createAnswerCodeInput(r);
  });
  const answerFlowChartInput = answerFlowCharts.map(f => {
    return createAnswerFlowChartInput(f);
  });

  return {
    ...rest,
    id: answerFragment.freepadAnswerId,
    answerCodes: answerCodesInput,
    answerFlowCharts: answerFlowChartInput,
  };
};

export const createAnswersInput = (
  answerFragments: DeepReadonly<Array<Graphql.AnswerForFreepadResourceEditorFragment>>,
): Graphql.FreepadAnswerInput[] => {
  return answerFragments.map(a => createAnswerInput(a));
};

export const createInitialCode = (
  initialCode: DeepReadonly<Graphql.InitialCodesForFreepadResourceEditorFragment>,
): Graphql.FreepadInitialCodeInput => {
  const { __typename, id: _id, freepadInitialCodeId: _initialId, ...rest } = initialCode;
  return {
    ...rest,
    id: initialCode.freepadInitialCodeId,
  };
};

export const createInitialCodes = (
  initialCodes: DeepReadonly<Array<Graphql.InitialCodesForFreepadResourceEditorFragment>>,
): Graphql.FreepadInitialCodeInput[] => {
  return initialCodes.map(i => createInitialCode(i));
};

export const createInitialFlowChart = (
  initialCode: DeepReadonly<Graphql.InitialFlowChartsForFreepadResourceEditorFragment>,
): Graphql.FreepadInitialFlowChartInput => {
  const { __typename, id: _id, freepadInitialFlowChartId: _initialId, ...rest } = initialCode;
  return {
    ...rest,
    id: initialCode.freepadInitialFlowChartId,
  };
};

export const createInitialFlowCharts = (
  initialFlowCharts: DeepReadonly<Array<Graphql.InitialFlowChartsForFreepadResourceEditorFragment>>,
): Graphql.FreepadInitialFlowChartInput[] => {
  return initialFlowCharts.map(i => createInitialFlowChart(i));
};
