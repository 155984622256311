import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type PaymentCompletionContainerProps = {};

const PaymentCompletionContainer: React.FC<PaymentCompletionContainerProps> = () => {
  const paymentCompletionProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.PaymentCompletion {...paymentCompletionProps} />
    </ErrorBoundary>
  );
};

PaymentCompletionContainer.displayName = "PaymentCompletionContainer";

export default withErrorBoundary(PaymentCompletionContainer, {});
