import { colorFromUserId } from "@hireroo/app-helper/color";
import { useRoomState, useTwilioVideoRoomContext } from "@hireroo/app-helper/hooks";
import { Auth } from "@hireroo/app-store/essential/employee";
import { RemotesId } from "@hireroo/app-store/page/e/remotes_id";
import { RemoteInterviewParticipants } from "@hireroo/app-store/widget/shared/RemoteInterviewParticipants";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/browser";
import * as React from "react";

import LiveCodingDeviceSettingsContainer from "../LiveCodingDeviceSettings/Container";
import LiveCodingVideoChatPopperContainer from "../LiveCodingVideoChatPopper/Container";
import * as PrivateHelper from "./privateHelper";
import { useGenerateDevicePermissionState } from "./useGenerateDevicePermissionState";

export type GenerateLiveCodingPreJoinScreenPropsArgs = {};

export const useGenerateProps = (_args: GenerateLiveCodingPreJoinScreenPropsArgs): Widget.LiveCodingPreJoinScreenProps => {
  const { t } = useTranslation();
  const liveCodingId = RemotesId.useLiveCodingId();
  const uid = Auth.useCurrentUid();
  const userInfoMap = RemoteInterviewParticipants.useUserInfoMap();

  const user = RemotesId.useEmployeeUser(uid ?? "");
  const userInfo = userInfoMap.get(user?.userId ?? "");
  const { connectStatus, start } = PrivateHelper.useStartVideoChatRoom({ liveCodingId: liveCodingId, displayName: user.userName });
  const {
    room,
    localVideoTrack,
    getAudioAndVideoTracks,
    isAudioEnabled,
    toggleAudio,
    isVideoEnabled,
    toggleVideo,
    removeLocalVideoTrack,
    removeLocalAudioTrack,
    isAcquiringLocalTracks,
  } = useTwilioVideoRoomContext();
  // TODO: better to include useRoomState in Context
  const videoRoomState = useRoomState(room);

  const [isLoading, setIsLoading] = React.useState(false);
  const [hasOpened, setHasOpened] = React.useState(false);
  const device = useGenerateDevicePermissionState({ opened: hasOpened });

  const handleClickYes = React.useCallback(() => {
    start();
  }, [start]);

  const videoViewProps: Widget.LiveCodingVideoViewProps = React.useMemo(() => {
    return {
      avatar: {
        sx: { bgcolor: colorFromUserId(user.userId) },
        src: userInfo?.employee?.photoUrl || user.userName,
        alt: user.userName,
      },
      id: "local",
      title: t("あなた"),
      videoTrack: localVideoTrack && {
        track: localVideoTrack,
        isFrontFacing: true,
        isPortrait: false,
      },
      isMicOn: isAudioEnabled,
    };
  }, [isAudioEnabled, localVideoTrack, t, user.userId, user.userName, userInfo?.employee?.photoUrl]);

  const handleEndVideo = React.useCallback(() => {
    // wait acquire
    if (isAcquiringLocalTracks) {
      return;
    }

    removeLocalAudioTrack();
    removeLocalVideoTrack();

    setTimeout(() => {
      // after popper close and remove devices
      room?.disconnect();
    });
  }, [isAcquiringLocalTracks, removeLocalAudioTrack, removeLocalVideoTrack, room]);

  // Check connectionState and roomState to show loading
  React.useEffect(() => {
    if (connectStatus === "START") {
      setIsLoading(true);
      return;
    }
    if (connectStatus === "STOP") {
      setIsLoading(prevState => {
        if (prevState) {
          return videoRoomState === "connected";
        }
        return false;
      });
    }
  }, [connectStatus, videoRoomState]);

  return {
    toggleVideoButton:
      videoRoomState !== "connected"
        ? {
            type: "SETUP_VIDEO",
          }
        : {
            type: "END_VIDEO",
            onClick: () => {
              handleEndVideo();
            },
          },
    onOpen: () => {
      getAudioAndVideoTracks().catch(err => {
        Snackbar.notify({
          severity: "error",
          message: t("デバイス設定が取得できませんでした。"),
        });
        Sentry.captureException(err);
      });
      // enable audio and video
      if (!isVideoEnabled) toggleVideo();
      if (!isAudioEnabled) toggleAudio();
      setHasOpened(true);
    },
    dialog: {
      isLoading: isLoading,
      /**
       * TODO Containerize
       * If you refer to the Widget directly, there is no point in making it a Widget.
       */
      VideoContent: <Widget.LiveCodingVideoView {...videoViewProps} />,
      deviceToggleButtons: {
        audioToggleButton: {
          isEnabled: isAudioEnabled,
          onClick: () => {
            toggleAudio();
          },
        },
        videoToggleButton: {
          isEnabled: isVideoEnabled,
          onClick: () => {
            toggleVideo();
          },
        },
      },
      yesButton: {
        loading: isAcquiringLocalTracks || isLoading,
        onClick: () => {
          handleClickYes();
        },
      },
      hasWarning: device.permissionState !== "granted",
      DeviceSettings: device.permissionState === "granted" && <LiveCodingDeviceSettingsContainer />,
      forceClose: videoRoomState === "connected",
    },
    //TODO: change how to convert
    status: videoRoomState === "connected" ? "JOINED" : "NOT_JOINED",
    VideoChat: <LiveCodingVideoChatPopperContainer />,
  };
};
