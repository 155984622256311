import { useTranslation } from "@hireroo/i18n";
import AddOutlined from "@mui/icons-material/AddOutlined";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import ReplayOutlined from "@mui/icons-material/ReplayOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import ToggleButton, { ToggleButtonProps } from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

import IconButtonWithTooltip, {
  IconButtonWithTooltipProps,
} from "../../../../../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";
import { useChallengeCodingEditorContext } from "../../../Context";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  width: "100%",
  flexWrap: "wrap",
  "& .MuiToggleButtonGroup-root": {
    borderRadius: "40px",
  },
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5, 1),
    border: 0,
    borderRadius: "40px",
  },
  "& .MuiToggleButtonGroup-grouped:first-of-type": {
    marginLeft: 0,
  },
  "& .MuiToggleButtonGroup-grouped:last-of-type": {
    marginRight: 0,
  },
  "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
    borderRadius: "40px",
  },
  "& .MuiToggleButtonGroup-grouped:not(:last-of-type)": {
    borderRadius: "40px",
  },
  "&.Mui-disabled": {
    border: 0,
  },
  "& .Mui-selected": {
    backgroundColor: theme.palette["Secondary/Shades"].p50,
  },
  borderRadius: "40px",
  "& :not(:first-of-type)": {
    borderRadius: "40px",
  },
  "& :not(:last-of-type)": {
    borderRadius: "40px",
  },
  "& :first-of-type": {
    borderRadius: "40px",
  },
}));

const InvisibleIconWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  color: theme.palette.text.secondary,
  alignItems: "center",
  "& svg": { width: 16, height: 16 },
}));

const StyledIconButtonWithTooltip = styled(IconButtonWithTooltip)(() => ({
  height: "36px",
  width: "36px",
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  borderRadius: "40px",
  "&.Mui-selected": {
    backgroundColor: theme.palette["Secondary/Shades"].p50,
  },
  "&:hover": {
    backgroundColor: theme.palette["Secondary/Shades"].p50,
  },
  height: "24px",
}));

const StyledTypography = styled(Typography)(() => ({
  wordBreak: "keep-all",
  whiteSpace: "nowrap",
  alignItems: "center",
}));

type Mode = "VIEW" | "ACTION";

type OutputStatus = "SUCCESS" | "FAILED";

const OutputIconMap = {
  SUCCESS: <CheckIcon color="success" sx={{ fontSize: "14px" }} />,
  FAILED: <CloseIcon color="error" sx={{ fontSize: "14px" }} />,
} satisfies Record<OutputStatus, React.ReactElement>;

export type TestCaseButtonGroupsProps = {
  mode: Mode;
  addButton: IconButtonWithTooltipProps;
  resetButton: IconButtonWithTooltipProps;
  deleteButton: IconButtonWithTooltipProps;
  outputResultMap?: Record<number, OutputStatus>;
  isLoading: boolean;
};

const TestCaseButtonGroups: React.FC<TestCaseButtonGroupsProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { selectedTestCaseIndex, updateSelectedTestCaseIndex, testCases, kind } = useChallengeCodingEditorContext();

  const addButton: IconButtonWithTooltipProps = {
    ...props.addButton,
    size: "small",
    title: t("テストケースを追加する"),
    children: <AddOutlined fontSize="small" sx={{ color: theme.palette["Gray/Shades"].p56 }} />,
  };
  const resetButton: IconButtonWithTooltipProps = {
    ...props.resetButton,
    size: "small",
    title: t("初期値へ戻す"),
    children: <ReplayOutlined fontSize="small" sx={{ color: theme.palette["Gray/Shades"].p56 }} />,
  };
  const deleteButton: IconButtonWithTooltipProps = {
    ...props.deleteButton,
    size: "small",
    title: t("テストケースを削除する"),
    children: <DeleteOutlined fontSize="small" sx={{ color: theme.palette["Gray/Shades"].p56 }} />,
  };
  const toggleButtons = testCases.map((testCase, index): ToggleButtonProps => {
    const outputResultStatus = props.outputResultMap?.[index];
    return {
      value: index.toString(),
      onClick: (_, value) => {
        updateSelectedTestCaseIndex(Number(value));
      },
      size: "small",
      children: (
        <Stack direction="row" alignItems="center" spacing={0.5}>
          {kind === "QUESTION_SCREEN" && testCase.isHidden && (
            <Tooltip title={t("受験者には見えない隠しテストケースです")}>
              <InvisibleIconWrapper>
                <VisibilityOffOutlinedIcon fontSize="small" />
              </InvisibleIconWrapper>
            </Tooltip>
          )}
          <StyledTypography variant="caption">{`${t("ケース")}${index + 1}`}</StyledTypography>
          {props.mode === "VIEW" && outputResultStatus === undefined && props.isLoading && (
            <Box alignItems="center" display="flex">
              <CircularProgress color="secondary" size="14px" />
            </Box>
          )}
          {props.mode === "VIEW" && outputResultStatus !== undefined && (
            <Box alignItems="center" display="flex">
              {OutputIconMap[outputResultStatus]}
            </Box>
          )}
        </Stack>
      ),
    };
  });
  return (
    <Stack direction="row" justifyContent="space-between" width="100%">
      <Stack spacing={1} direction="row">
        <StyledToggleButtonGroup value={selectedTestCaseIndex.toString()} exclusive>
          {toggleButtons.map(toggleButton => (
            <StyledToggleButton key={toggleButton.value.toString()} {...toggleButton} />
          ))}
        </StyledToggleButtonGroup>
        <Box>
          <StyledIconButtonWithTooltip key="add-button" {...addButton} />
        </Box>
      </Stack>
      {props.mode === "ACTION" && (
        <Stack direction="row" spacing={1}>
          <Box>
            <StyledIconButtonWithTooltip key="reset-button" {...resetButton} />
          </Box>
          <Box>
            <StyledIconButtonWithTooltip key="delete-button" {...deleteButton} />
          </Box>
        </Stack>
      )}
    </Stack>
  );
};

TestCaseButtonGroups.displayName = "TestCaseButtonGroups";

export default TestCaseButtonGroups;
