import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateChallengePlaybackPropsArgs, useGenerateProps } from "./useGenerateProps";

export type ChallengePlaybackContainerProps = GenerateChallengePlaybackPropsArgs;

const ChallengePlaybackContainer: React.FC<ChallengePlaybackContainerProps> = props => {
  const challengePlaybackProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ChallengePlayback {...challengePlaybackProps} />
    </ErrorBoundary>
  );
};

ChallengePlaybackContainer.displayName = "ChallengePlaybackContainer";

export default withErrorBoundary(ChallengePlaybackContainer, {});
