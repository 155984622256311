import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import QuestionDetailEditableListItem, {
  QuestionDetailEditableListItemProps,
} from "../QuestionDetailEditableListItem/QuestionDetailEditableListItem";
import SelectedQuestionControlBar, { SelectedQuestionControlBarProps } from "../SelectedQuestionControlBar/SelectedQuestionControlBar";

const StyledStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  borderRadius: "8px",
  position: "relative",
}));

const Label = styled(Typography)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.info.dark,
  fontSize: 10,
  padding: "1px 4px",
  borderStartStartRadius: "8px",
  borderEndEndRadius: "8px",
}));

const StyledList = styled(List)(({ theme }) => ({
  padding: 0,
  "& > .MuiListItem-root": {
    marginBottom: theme.spacing(1),
    "&:last-child": {
      marginBottom: 0,
    },
  },
}));

export type QuestionDetailEditableGroupListItemProps = {
  className?: string;
  label: string;
  title: string;
  description: string;
  items: Omit<QuestionDetailEditableListItemProps, "controlBar">[];
  controlBar: SelectedQuestionControlBarProps;
};

/**
 * entityTracks[].entitySources[]
 */
const QuestionDetailEditableGroupListItem: React.FC<QuestionDetailEditableGroupListItemProps> = props => {
  return (
    <StyledStack spacing={2} className={props.className}>
      <Label>{props.label}</Label>
      <Typography noWrap fontWeight={700}>
        {props.title}
      </Typography>
      <Typography color="text.secondary" variant="body2">
        {props.description}
      </Typography>
      <StyledList>
        {props.items.map(itemProps => {
          return <QuestionDetailEditableListItem key={itemProps.uniqueKey} {...itemProps} isChild />;
        })}
      </StyledList>
      <SelectedQuestionControlBar {...props.controlBar} mt={2} />
    </StyledStack>
  );
};

QuestionDetailEditableGroupListItem.displayName = "QuestionDetailEditableGroupListItem";

export default QuestionDetailEditableGroupListItem;
