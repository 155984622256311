import { InterviewsIdDetailStore } from "@hireroo/app-store/page/c/interviews_id_detail";
import { ErrorBoundary } from "@sentry/react";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import InterviewDetailContainer from "./Container";

export type InterviewsDetailInitialContainerProps = {};

const InterviewsDetailInitialContainer: React.FC<InterviewsDetailInitialContainerProps> = () => {
  const [query] = useSearchParams();
  const [initialTab] = React.useState(query.get("tab"));

  React.useEffect(() => {
    if (initialTab === "OVERVIEW" || initialTab === "REPORT" || initialTab === "FEED_BACK") {
      InterviewsIdDetailStore.updateCurrentTab(initialTab);
    }
  }, [initialTab]);

  return (
    <ErrorBoundary>
      <InterviewDetailContainer />
    </ErrorBoundary>
  );
};
InterviewsDetailInitialContainer.displayName = "InterviewsDetailInitialContainer";

export default InterviewsDetailInitialContainer;
