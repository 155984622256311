import * as React from "react";
import { useSnapshot } from "valtio";

import { Area, CommentElement, EdgeElement, ELEMENT_TYPE, FlowElement, NetworkElement, NodeElement } from "../features/types";
import { edgeCoordinatesFromNodes, geometryFromElement } from "../helpers/flowChart";
import * as Types from "./types";

export const createHooks = (privateState: Types.State) => {
  const useSnapshotState = () => {
    return useSnapshot(privateState);
  };

  const useComponentType = () => {
    const state = useSnapshotState();
    return state.componentType;
  };

  const useSelectableComponentTypes = () => {
    const state = useSnapshotState();
    return state.selectableComponentTypes;
  };

  const useStartSystemDesign = () => {
    const state = useSnapshotState();
    return {
      isStartSystemDesignLoading: state.isStartSystemDesignLoading,
      startSystemDesignError: state.startSystemDesignError,
    };
  };
  const useGetSubmission = () => {
    const state = useSnapshotState();
    return {
      isGetSubmissionLoading: state.isGetSubmissionLoading,
      getSubmissionError: state.getSubmissionError,
    };
  };
  const useGetQuestion = () => {
    const state = useSnapshotState();
    return {
      isGetQuestionLoading: state.isGetQuestionLoading,
      getQuestionError: state.getQuestionError,
    };
  };
  const useSubmitSystemDesign = () => {
    const state = useSnapshotState();
    return {
      isSubmitSystemDesignLoading: state.isSubmitSystemDesignLoading,
      submitSystemDesignError: state.submitSystemDesignError,
    };
  };
  const useReevaluateSystemDesign = () => {
    const state = useSnapshotState();
    return {
      isReevaluateSystemDesignLoading: state.isReevaluateSystemDesignLoading,
      reevaluateSystemDesignError: state.reevaluateSystemDesignError,
    };
  };
  const useRunSystemDesign = () => {
    const state = useSnapshotState();
    return {
      isRunSystemDesignLoading: state.isRunSystemDesignLoading,
      runSystemDesignError: state.runSystemDesignError,
    };
  };
  const useSystemDesign = () => {
    const state = useSnapshotState();
    return (id: number) => state.systemDesigns[id];
  };
  // const useQuestions = () => {
  //   const state = useSnapshotState();
  // }
  // const useQuestion = () => {
  //   const state = useSnapshotState();
  // }
  // const useAnswer = () => {
  //   const state = useSnapshotState();
  // }
  // const useScoringItem = () => {
  //   const state = useSnapshotState();
  // }
  // const useHint = () => {
  //   const state = useSnapshotState();
  // }
  const useComponentTypes = (systemDesignId: number) => {
    const state = useSnapshotState();
    const systemDesign = state.systemDesigns[systemDesignId];
    if (!systemDesign) return [];

    return systemDesign.componentTypesList ?? [];
  };
  const useElement = () => {
    const state = useSnapshotState();
    return (id: string) => {
      return id in state.elements ? state.elements[id] : undefined;
    };
  };
  const useElements = () => {
    const state = useSnapshotState();
    return state.elements;
  };
  const useElementsList = () => {
    const state = useSnapshotState();
    return state.elementIds.map(id => state.elements[id]);
  };
  const useElementIds = () => {
    const state = useSnapshotState();
    return state.elementIds;
  };
  const useSelectedElementIds = () => {
    const state = useSnapshotState();
    return React.useMemo(() => {
      return state.selectedElementIds;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(state.selectedElementIds)]);
  };
  const useSelectedElementId = () => {
    const selectedElementIds = useSelectedElementIds();
    return selectedElementIds.length === 1 ? selectedElementIds[0] : undefined;
  };
  const useNetworkIds = () => {
    const state = useSnapshotState();
    return state.elementIds.filter(elementId => state.elements[elementId].type === ELEMENT_TYPE.network);
  };
  const useCopiedElementIds = () => {
    const state = useSnapshotState();
    return state.copiedElementIds;
  };
  const useViewbox = () => {
    const state = useSnapshotState();
    return state.viewbox;
  };
  const useCachedViewbox = () => {
    const state = useSnapshotState();
    return state.cachedViewbox;
  };
  const useScale = () => {
    const state = useSnapshotState();
    return state.scale;
  };
  const useMinimumArea = () => {
    const state = useSnapshotState();
    return (elementIds: string[]) => {
      const minimumArea: Area = {
        minX: Number.MAX_SAFE_INTEGER,
        minY: Number.MAX_SAFE_INTEGER,
        maxX: Number.MIN_SAFE_INTEGER,
        maxY: Number.MIN_SAFE_INTEGER,
      };

      elementIds.forEach(elementId => {
        // Basically the element exists in the map state, but on the remote interview,
        // some action could come in from the peer after resetting of the flowchart,
        // hence confirm its existence just in case.
        if (!(elementId in state.elements)) return;

        const element = state.elements[elementId];

        if (element.type === ELEMENT_TYPE.edge) {
          const { from, to } = edgeCoordinatesFromNodes(
            state.elements[element.source] as NodeElement,
            state.elements[element.target] as NodeElement,
          );

          const minX = Math.min(from.x, to.x);
          const maxX = Math.max(from.x, to.x);
          const minY = Math.min(from.y, to.y);
          const maxY = Math.max(from.y, to.y);

          if (minX < minimumArea.minX) {
            minimumArea.minX = minX;
          }
          if (minY < minimumArea.minY) {
            minimumArea.minY = minY;
          }
          if (maxX > minimumArea.maxX) {
            minimumArea.maxX = maxX;
          }
          if (maxY > minimumArea.maxY) {
            minimumArea.maxY = maxY;
          }
        } else {
          const geometry = geometryFromElement(element);

          if (geometry.minX < minimumArea.minX) {
            minimumArea.minX = geometry.minX;
          }
          if (geometry.minY < minimumArea.minY) {
            minimumArea.minY = geometry.minY;
          }
          if (geometry.maxX > minimumArea.maxX) {
            minimumArea.maxX = geometry.maxX;
          }
          if (geometry.maxY > minimumArea.maxY) {
            minimumArea.maxY = geometry.maxY;
          }
        }
      });

      return minimumArea;
    };
  };
  // const useSubmissions = () => {
  //   const state = useSnapshotState();
  //   return state.submissions;
  // }
  // const useSubmission = () => {
  //   const state = useSnapshotState();
  // }
  // const useSubmissionByKeys = () => {
  //   const state = useSnapshotState();
  // }
  const useIsDoubleEdge = () => {
    const state = useSnapshotState();

    return (node1: string, node2: string) => {
      let isDoubleEdge = false;

      state.elementIds.forEach(elementId => {
        // Basically the element exists in the map state, but on the remote interview,
        // some action could come in from the peer after resetting of the flowchart,
        // hence confirm its existence just in case.
        if (!(elementId in state.elements)) return;

        const element = state.elements[elementId];
        if (element.type === ELEMENT_TYPE.edge) {
          if ([element.source, element.target].includes(node1) && [element.source, element.target].includes(node2)) {
            isDoubleEdge = true;
          }
        }
      });

      return isDoubleEdge;
    };
  };
  const useWithNeighborEdge = () => {
    const state = useSnapshotState();
    return (ids: string[]) => {
      const elementIds = state.elementIds;
      const elements = state.elements;
      return elementIds.filter(elementId => {
        const element = elements[elementId];
        const isSelected = ids.includes(elementId);
        const isNeighborEdge = element.type === ELEMENT_TYPE.edge && (ids.includes(element.source) || ids.includes(element.target));
        return isSelected || isNeighborEdge;
      });
    };
  };
  const usePasteDestElements = () => {
    const state = useSnapshotState();
    return (srcIds: string[], destIds: string[], timestamp: number) => {
      const elements = state.elements;
      const pasteCount = state.pasteCount;
      const destElements: FlowElement[] = [];
      const srcEdgeIds: string[] = [];
      const idMap: Record<string, string> = {};
      srcIds.forEach((srcId, i) => {
        idMap[srcId] = destIds[i];
      });

      // Move all elements by the calculated delta x and y
      srcIds.forEach(srcId => {
        const element = elements[srcId];
        if (element.type === ELEMENT_TYPE.edge) {
          srcEdgeIds.push(srcId);
        } else {
          const geometry = geometryFromElement(element);
          const newElement = {
            ...elements[srcId],
            id: idMap[srcId],
            geometry: {
              minX: geometry.minX + 20 * pasteCount,
              minY: geometry.minY + 20 * pasteCount,
              maxX: geometry.maxX + 20 * pasteCount,
              maxY: geometry.maxY + 20 * pasteCount,
            },
            updatedAt: timestamp,
          } as NodeElement | NetworkElement | CommentElement;
          destElements.push(newElement);
        }
      });

      // Connect nodes from the correspondence between the original node and the new one stored in the map
      srcEdgeIds.forEach(edgeId => {
        const srcEdge = elements[edgeId] as EdgeElement;
        const newEdge = {
          ...srcEdge,
          id: idMap[edgeId],
          source: idMap[srcEdge.source],
          target: idMap[srcEdge.target],
          updatedAt: timestamp,
        } as EdgeElement;

        destElements.push(newEdge);
      });

      return destElements;
    };
  };
  const usePasteCount = () => {
    const state = useSnapshotState();
    return state.pasteCount;
  };
  const useOpenMiniMap = () => {
    const state = useSnapshotState();
    return state.openMiniMap;
  };
  const useOpenResetDialog = () => {
    const state = useSnapshotState();
    return state.openResetConfirmDialog;
  };
  return {
    useComponentType,
    useSelectableComponentTypes,
    useSnapshotState,
    useStartSystemDesign,
    useGetSubmission,
    useGetQuestion,
    useSubmitSystemDesign,
    useReevaluateSystemDesign,
    useRunSystemDesign,
    useSystemDesign,
    useOpenMiniMap,
    // useQuestions,
    // useQuestion,
    // useAnswer,
    // useScoringItem,
    // useHint,
    useComponentTypes,
    useElement,
    useElementsList,
    useElementIds,
    useSelectedElementIds,
    useSelectedElementId,
    useNetworkIds,
    useCopiedElementIds,
    useViewbox,
    useCachedViewbox,
    useScale,
    useMinimumArea,
    // useSubmissions,
    // useSubmission,
    // useSubmissionByKeys,
    useIsDoubleEdge,
    useWithNeighborEdge,
    usePasteDestElements,
    usePasteCount,
    useOpenResetDialog,
    useElements,
  };
};
