import { styled, useTheme } from "@mui/material/styles";
import React from "react";

import { ComponentType, ElementDataKeyMap, ElementMasterData, NodeElement } from "../../../features";
import { SettingsFlag } from "../SettingsFlag/SettingsFlag";

const TextSvg = styled("text")(() => ({
  WebkitTouchCallout: "none",
  WebkitUserSelect: "none",
  msUserSelect: "none",
  userSelect: "none",
  dominantBaseline: "middle",
  textAnchor: "middle",
  pointerEvents: "none",
}));

const RectSvg = styled("rect")(() => ({
  filter: "drop-shadow(3px 3px 10px rgba(0, 0, 0, 0.3))",
}));

export type NodeProps = {
  node: NodeElement;
  componentType: ComponentType;
  simpleView?: boolean;
  dashedLine?: boolean;
  isMatch?: boolean;
};

const lightGreen = "#76ff03"; // lightGreen A400

export const Node: React.FC<NodeProps> = React.memo((props: NodeProps) => {
  const theme = useTheme();
  const {
    geometry: { minX, minY, maxX, maxY },
  } = props.node;

  return (
    <g>
      <SettingsFlag node={props.node} />
      <RectSvg
        width={maxX - minX}
        height={maxY - minY}
        x={minX}
        y={minY}
        rx={5}
        ry={5}
        fill={theme.palette.common.white}
        stroke={props.isMatch ? lightGreen : undefined}
        strokeWidth={props.isMatch ? 10 : undefined}
        strokeDasharray={props.dashedLine ? "8 8" : "0 0"}
      />
      <image
        xlinkHref={ElementMasterData[ElementDataKeyMap[props.componentType][props.node.label]].iconImage}
        x={minX + 20}
        y={minY + 20}
        width={maxY - minY - 40}
        height={maxY - minY - 40}
      />
      {props.node.label && !props.simpleView && (
        <TextSvg x={(minX + maxX) / 2} y={maxY + 30} fontSize={24}>
          {props.node.settings.name}
        </TextSvg>
      )}
    </g>
  );
});

Node.displayName = "Node";

export default Node;
