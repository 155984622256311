import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Auth } from "@hireroo/app-store/essential/employee";
import { ScreeningsIdDetailStore } from "@hireroo/app-store/page/e/screenings_id_detail";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/react";
import React from "react";

import ViewerAssignFieldContainer from "../../../../../../widget/v2/e/ViewerAssignField/Container";

type AuthorizedMembersAndGroup = Widget.AccessPermissionDialogProps["defaultValues"]["viewers"][0];

export type UseGenerateAccessPermissionDialogPropsArgs = {
  refresh: () => void;
};
export const useGenerateAccessPermissionDialogProps = (
  args: UseGenerateAccessPermissionDialogPropsArgs,
): Widget.ScreeningDetailProps["overview"]["reportInfo"]["accessPermissionDialog"] => {
  const { t } = useTranslation();
  const client = getGraphqlClient();
  const currentUid = Auth.useCurrentUid();
  const dialogStatus = ScreeningsIdDetailStore.useDialogStatus();
  const screening = ScreeningsIdDetailStore.useScreening();

  const defaultViewers = React.useMemo(() => {
    const viewers: AuthorizedMembersAndGroup[] = screening.viewers.reduce<AuthorizedMembersAndGroup[]>((all, viewer) => {
      switch (viewer.__typename) {
        case "User":
          return all.concat({
            value: {
              type: "EMPLOYEE",
              employeeId: viewer.uid,
              locked: viewer.uid === currentUid,
            },
          });
        case "EmployeeGroup":
          return all.concat({
            value: {
              type: "EMPLOYEE_GROUP",
              groupId: viewer.employeeGroupId,
            },
          });
        default:
          throw Error(`invalid viewer type ${viewer satisfies never}`);
      }
    }, []);

    if (viewers.some(viewer => viewer.value?.type === "EMPLOYEE" && viewer.value.employeeId === currentUid)) {
      return viewers;
    }

    viewers.push({
      value: {
        type: "EMPLOYEE",
        employeeId: currentUid,
        locked: true,
      },
    });
    return viewers;
  }, [screening, currentUid]);

  return {
    open: dialogStatus === "OPEN_ACCESS_PERMISSION",
    description: t("閲覧権限の適用対象はコーディングテスト詳細ページ、候補者詳細ページ（レポート）が対象になります。"),
    onSubmit: fields => {
      client
        .UpdateScreeningForScreeningDetail({
          input: {
            screeningId: screening.screeningId,
            spotIsPublic: fields.isPublic,
            viewerIds: fields.viewers.reduce<Graphql.SpotViewerInput[]>((viewerIds, reviewer) => {
              if (reviewer.value === null) {
                return viewerIds;
              }
              switch (reviewer.value.type) {
                case "EMPLOYEE":
                  return viewerIds.concat({
                    type: "EMPLOYEE",
                    employeeId: reviewer.value.employeeId,
                  });
                case "EMPLOYEE_GROUP":
                  return viewerIds.concat({
                    type: "EMPLOYEE_GROUP",
                    groupId: reviewer.value.groupId,
                  });
                default:
                  break;
              }
              return viewerIds;
            }, []),
          },
        })
        .then(() => {
          args.refresh();
          Snackbar.notify({
            severity: "success",
            message: t("閲覧権限を更新しました。"),
          });
        })
        .catch(error => {
          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("閲覧権限の更新に失敗しました。"));
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
        })
        .finally(() => {
          ScreeningsIdDetailStore.updateDialogStatus("CLOSE");
        });
    },
    defaultValues: {
      isPublic: screening.spotIsPublic,
      viewers: defaultViewers,
    },
    onOpen: () => {
      ScreeningsIdDetailStore.updateDialogStatus("OPEN_ACCESS_PERMISSION");
    },
    onClose: () => {
      ScreeningsIdDetailStore.updateDialogStatus("CLOSE");
    },
    editorId: currentUid,
    AccessPermissionField: <ViewerAssignFieldContainer name="viewers" variant="SECONDARY" />,
  };
};
