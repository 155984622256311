import { useTranslation } from "@hireroo/i18n";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import SubmissionAccordionAdditionalContentDetail, {
  SubmissionAccordionAdditionalDetailContentProps,
} from "./parts/SubmissionAccordionAdditionalDetailContent/SubmissionAccordionAdditionalDetailContent";
import SubmissionAccordionAnswerContent, {
  SubmissionAccordionAnswerContentProps,
} from "./parts/SubmissionAccordionAnswerContent/SubmissionAccordionAnswerContent";
import SubmissionAccordionEvaluationItemContent, {
  SubmissionAccordionEvaluationItemContentProps,
} from "./parts/SubmissionAccordionEvaluationItemContent/SubmissionAccordionEvaluationItemContent";
import SubmissionAccordionItemLayout, {
  SubmissionAccordionItemLayoutProps,
} from "./parts/SubmissionAccordionItemLayout/SubmissionAccordionItemLayout";
import SubmissionAccordionProcessContent, {
  SubmissionAccordionProcessContentProps,
} from "./parts/SubmissionAccordionProcessContent/SubmissionAccordionProcessContent";
import SubmissionAccordionQuestionContent, {
  SubmissionAccordionQuestionContentProps,
} from "./parts/SubmissionAccordionQuestionContent/SubmissionAccordionQuestionContent";
import SubmissionAccordionSummaryContent, {
  SubmissionAccordionSummaryContentProps,
} from "./parts/SubmissionAccordionSummaryContent/SubmissionAccordionSummaryContent";

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  borderColor: theme.palette.divider,
  "&:first-of-type": {
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
  },
  "&:last-of-type": {
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
  },
}));

const StyledStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette["Gray/Shades"].p8,
  borderRadius: "8px",
  padding: "12px",
}));

export type ReportQuizSubmissionAccordionProps = {
  id: string;
  isLoading: boolean;
  canShowStatistic: boolean;
  summaryContent: SubmissionAccordionSummaryContentProps;
  processContent: SubmissionAccordionProcessContentProps;
  questionContent: SubmissionAccordionQuestionContentProps;
  answerContent?: SubmissionAccordionAnswerContentProps;
  evaluationItemContent?: SubmissionAccordionEvaluationItemContentProps;
  elapsedTime?: string;
  correctAnswerRate?: number;
  additionalDetail?: SubmissionAccordionAdditionalDetailContentProps;
  defaultExpanded: boolean;
  submittedButton?: Pick<ButtonProps, "children" | "onClick" | "disabled">;
};

const ReportQuizSubmissionAccordion: React.FC<ReportQuizSubmissionAccordionProps> = props => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState<boolean>(props.defaultExpanded);
  React.useEffect(() => {
    setExpanded(props.defaultExpanded);
  }, [props.defaultExpanded]);

  const handleClick = () => {
    setExpanded(draft => !draft);
  };

  const elapsedTimeSectionProps: SubmissionAccordionItemLayoutProps = {
    title: t("回答時間"),
    child: {
      kind: "STRING",
      content: props.elapsedTime ? props.elapsedTime : "-",
    },
  };

  const submittedProcess: SubmissionAccordionItemLayoutProps = {
    title: t("提出時刻"),
    RightTitleContent: <SubmissionAccordionProcessContent {...props.processContent} />,
    child: {
      kind: "COMPONENT",
      content: (
        <Box>
          {props.submittedButton ? (
            <Button {...props.submittedButton} startIcon={<PlayArrowIcon />} variant="text" size="small" color="secondary" />
          ) : (
            <Typography variant="body2">{t("未到達")}</Typography>
          )}
        </Box>
      ),
    },
  };

  return (
    <StyledAccordion TransitionProps={{ unmountOnExit: true }} expanded={expanded}>
      <AccordionSummary sx={{ borderRadius: "inherit" }} expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" onClick={handleClick}>
        <SubmissionAccordionSummaryContent {...props.summaryContent} />
      </AccordionSummary>
      <AccordionDetails>
        {props.isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight={200}>
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <Stack width="100%" spacing={2}>
            <SubmissionAccordionQuestionContent {...props.questionContent} />
            {props.answerContent && <SubmissionAccordionAnswerContent {...props.answerContent} />}
            {props.evaluationItemContent && <SubmissionAccordionEvaluationItemContent {...props.evaluationItemContent} />}
            <StyledStack divider={<Divider />} spacing={1}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <SubmissionAccordionItemLayout {...elapsedTimeSectionProps} />
                </Grid>
                <Grid item xs={6}>
                  <SubmissionAccordionItemLayout {...submittedProcess} />
                </Grid>
              </Grid>
              {props.additionalDetail && <SubmissionAccordionAdditionalContentDetail {...props.additionalDetail} />}
            </StyledStack>
          </Stack>
        )}
      </AccordionDetails>
    </StyledAccordion>
  );
};

ReportQuizSubmissionAccordion.displayName = "ReportQuizSubmissionAccordion";

export default ReportQuizSubmissionAccordion;
