import * as z from "zod";

const useStatus = () => {
  return z.array(z.union([z.literal("INVITED"), z.literal("ACCEPTED")]));
};

export const useInviteeSearchQuery = () => {
  const statuses = useStatus();
  return z.object({
    statuses,
    textFilter: z.string(),
  });
};

export type InviteeSearchQuerySchema = z.infer<ReturnType<typeof useInviteeSearchQuery>>;
