import { state } from "./State";
import type * as Types from "./types";

export const updateRegisterSonar = (newValue: Types.RegisterSonarState): void => {
  state.registerSonar = newValue;
};

export const updateDeleteSonar = (newValue: Types.DeleteSonarState): void => {
  state.deleteSonar = newValue;
};

export const clear = () => {
  state.screeningMap.clear();
  state.registerSonar = {
    selectedScreening: null,
  };
  state.sonarIntegrationMap.clear();
};

export const updateSonarIntegrationsResponse = (res: Types.SonarIntegrationsResponse): void => {
  res.sonarIntegrations.forEach(sonarIntegration => {
    state.sonarIntegrationMap.set(sonarIntegration.id, sonarIntegration);
  });
  state.sonarPagination.count = res.count;
  if (res.pager) {
    state.sonarPagination.cursor = res.pager.cursor;
  }
};

export const addSonarIntegration = (newValue: Types.ListSonarIntegrationsSonar): void => {
  state.sonarIntegrations = [newValue, ...state.sonarIntegrations];
};
export const removeSonarIntegration = (sonarIntegrationId: string): void => {
  state.sonarIntegrations = state.sonarIntegrations.filter(s => s.id !== sonarIntegrationId);
  state.sonarIntegrationMap.delete(sonarIntegrationId);
};

export const updateSonarPagination = (newValue: Partial<Types.SonarPagination>): void => {
  state.sonarPagination = {
    ...state.sonarPagination,
    ...newValue,
  };
};

export const updateSonarPaginationPage = (newValue: number) => {
  state.sonarPagination.page = newValue;
  if (state.sonarPagination.nextCursor) {
    state.sonarPagination.cursor = state.sonarPagination.nextCursor;
  }
};
export const goNextBatchPage = (): void => {
  if (state.screeningPagination) {
    state.screeningPagination.nextCursor = state.screeningPagination.cursor;
  }
};

export const setScreeningResponse = (res: Types.ScreeningsResponse) => {
  res.screenings.forEach(screening => {
    state.screeningMap.set(screening.screeningId, screening);
  });
};
