import { proxy } from "valtio";
import { proxyMap, proxySet } from "valtio/utils";

import type * as Types from "./types";

const initPagination: Types.MetricPagination = {
  cursor: null,
  hasNext: false,
  nextCursor: null,
  count: 0,
  size: 10,
  page: 0,
};

export const state = proxy<Types.State>({
  currentEntityIndex: 0,
  activeSessionId: null,
  waitingActiveSessionId: null,
  remote: null,
  activeSessionMap: proxyMap(),
  liveCodingQuestionVariant: "UNKNOWN",
  liveCodingTemplateCodesMap: proxyMap(),
  liveCodingVideoChatRoomId: "",
  liveCodingVideoChatConversationId: "",
  fetchStatus: "BEFORE_FETCH",

  searchText: "",
  selectedMetricIds: proxySet(),
  metrics: proxyMap(),
  metricPagination: initPagination,
});
