import { useTranslation } from "@hireroo/i18n";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";

export type DBTestCaseHeaderProps = {
  name: string;
};

const DBTestCaseHeader: React.FC<DBTestCaseHeaderProps> = props => {
  const { t } = useTranslation();
  return (
    <TableHead>
      <TableRow>
        <TableCell />
        <TableCell>{props.name}</TableCell>
        <TableCell>{t("入出力")}</TableCell>
        <TableCell>{t("ステータス")}</TableCell>
        <TableCell align="right" />
      </TableRow>
    </TableHead>
  );
};

DBTestCaseHeader.displayName = "DBTestCaseHeader";

export default DBTestCaseHeader;
