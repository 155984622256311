import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import DialogContentText from "@mui/material/DialogContentText";
import * as React from "react";

import BaseDialog, { BaseDialogProps, NoButtonProps, YesButtonProps } from "../../../../primitive/BaseDialog/BaseDialog";

export type ConfirmEvaluationDialogProps = {
  open: boolean;
  yesButton: Omit<YesButtonProps, "children">;
  noButton: Omit<NoButtonProps, "children">;
};

const ConfirmEvaluationDialog: React.FC<ConfirmEvaluationDialogProps> = props => {
  const { t } = useTranslation();
  const baseDialog: BaseDialogProps = {
    title: t("評価を確定します"),
    open: props.open,
    yesButton: {
      ...props.yesButton,
      children: t("確定"),
      color: "primary",
    },
    noButton: {
      ...props.noButton,
      color: "primary",
      children: t("キャンセル"),
    },
  };
  return (
    <BaseDialog {...baseDialog}>
      <Box mb={2} display="grid">
        <DialogContentText>
          {t(
            "評価を確定後は変更できませんのでご注意ください。なお、このまま評価を確定せずページを移動したとしても、同一ブラウザ上であればいつでもレポート画面から確定できます。",
          )}
        </DialogContentText>
      </Box>
    </BaseDialog>
  );
};

ConfirmEvaluationDialog.displayName = "ConfirmEvaluationDialog";

export default ConfirmEvaluationDialog;
