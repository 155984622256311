import { Auth } from "@hireroo/app-store/essential/candidate";
import { Credential } from "@hireroo/app-store/essential/shared";
import { signOut } from "@hireroo/firebase";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateHelpCenterUrl } from "@hireroo/router/api";
import routes from "@hireroo/router/config";
import { useHelpCenterNavigate, useNavigate } from "@hireroo/router/hooks";

export const useGenerateProps = (): Widget.ErrorPanelProps | undefined => {
  const initializeError = Auth.useInitializeError();
  const lang = useLanguageCode();
  const navigate = useNavigate();
  const helpCenterNavigate = useHelpCenterNavigate(lang);
  const { t } = useTranslation();
  const userType = Credential.useUserType();

  const linksProps: Widget.ErrorPanelProps["links"] = [
    {
      href: generateHelpCenterUrl(lang, "ROOT"),
      children: t("ヘルプセンター"),
      onClick: () => {
        helpCenterNavigate("ROOT", { _blank: true });
      },
    },
    userType === Graphql.UserType.Employee && {
      href: routes["/e/home"],
      children: t("ホームに戻る"),
      onClick: () => {
        navigate(routes["/e/home"]);
      },
    },
    userType === Graphql.UserType.Talent && {
      href: routes["/t/assessments"],
      children: t("ホームに戻る"),
      onClick: () => {
        navigate(routes["/t/assessments"]);
      },
    },
    {
      children: t("サインアウトする"),
      onClick: async () => {
        await signOut();
      },
    },
  ].flatMap(v => (v ? [v] : []));

  if (initializeError === "DIFFERENT_USER_TYPE") {
    return {
      name: t("エラーが発生しました。"),
      message: t("現在面接官アカウントとしてサインインしています。一度サインアウトするか、別ブラウザで再度アクセスしてください。"),
      links: linksProps,
    };
  }

  if (initializeError === "INVALID_USER") {
    return {
      name: t("エラーが発生しました。"),
      message: t("ページをリロードするか、それでも治らない場合は運営までお問い合わせ下さい。"),
      links: [
        {
          children: t("ページをリロードをする"),
          onClick: () => {
            window.location.reload();
          },
        },
        {
          href: generateHelpCenterUrl(lang, "ROOT"),
          children: t("ヘルプセンター"),
          onClick: () => {
            helpCenterNavigate("ROOT", { _blank: true });
          },
        },
        {
          children: t("サインアウトする"),
          onClick: async () => {
            await signOut();
          },
        },
      ],
    };
  }
};
