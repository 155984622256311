import { useTranslation } from "@hireroo/i18n";
import { SupportLanguageValue } from "@hireroo/i18n";
import { QuizQuestionForm } from "@hireroo/validator";
import Delete from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import InputControlTextField, { InputControlTextFieldProps } from "../../../../primitive/InputControl/InputControlTextField";

export type EvaluationListItemProps = {
  index: number;
  language: SupportLanguageValue;
  deleteButton: Pick<IconButtonProps, "onClick">;
};

const EvaluationListItem: React.FC<EvaluationListItemProps> = props => {
  const { t } = useTranslation();
  const method = useFormContext<QuizQuestionForm.QuizQuestionFormSchema>();
  const evaluations = method.getValues("evaluations");
  const target = evaluations[props.index];
  const content = target.content[props.language];

  const deleteButtonProps: IconButtonProps = {
    ...props.deleteButton,
    color: "default",
    "aria-label": "remove-type",
    size: "small",
  };

  const textFieldProps: InputControlTextFieldProps = {
    name: `evaluationItems.${props.index}.${props.language}.title`,
    label: t("タイトル"),
    fullWidth: true,
    value: content?.title,
    InputLabelProps: {
      shrink: true,
    },
    required: true,
    placeholder: t("XXXの項目について"),
  };

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box display="flex" alignItems="center" gap={2}>
        <Tooltip title={t("評価基準を削除する").toString()}>
          <IconButton component="span" {...deleteButtonProps}>
            <Delete />
          </IconButton>
        </Tooltip>
        <InputControlTextField {...textFieldProps} name={`evaluations.${props.index}.content.${props.language}.title`} />
      </Box>
    </Box>
  );
};

EvaluationListItem.displayName = "EvaluationListItem";

export default EvaluationListItem;
