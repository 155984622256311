import { withThemeProvider } from "@hireroo/ui-theme/theme";

import QuestionsChallengeCreate from "../pages/e/questions_challenge_create/InitialContainer.js";
import QuestionsChallengeIdUpdate from "../pages/e/questions_challenge_id_update/InitialContainer.js";
import QuestionsFreepadCreate from "../pages/e/questions_freepad_create/InitialContainer";
import QuestionsFreepadIdUpdate from "../pages/e/questions_freepad_id_update/InitialContainer";
import QuestionsQuizCreate from "../pages/e/questions_quiz_create/InitialContainer.js";
import QuestionsQuizIdUpdate from "../pages/e/questions_quiz_id_update/InitialContainer.js";

export default {
  QuestionsChallengeCreate: withThemeProvider(QuestionsChallengeCreate),
  QuestionsChallengeIdUpdate: withThemeProvider(QuestionsChallengeIdUpdate),
  QuestionsFreepadCreate: withThemeProvider(QuestionsFreepadCreate),
  QuestionsFreepadIdUpdate: withThemeProvider(QuestionsFreepadIdUpdate),
  QuestionsQuizCreate: withThemeProvider(QuestionsQuizCreate),
  QuestionsQuizIdUpdate: withThemeProvider(QuestionsQuizIdUpdate),
};
