import { AssessmentOverview } from "@hireroo/app-store/widget/e/AssessmentOverview";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import * as TimeFormatter from "@hireroo/formatter/time";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import * as React from "react";

import { useGenerateQuestionsProps } from "./useGenerateQuestionsProps";

export type GenerateAssessmentOverviewPropsArgs = {
  companyId: number;
};

export const useGenerateProps = (_args: GenerateAssessmentOverviewPropsArgs): Widget.AssessmentOverviewProps => {
  const lang = useLanguageCode();

  const assessment = AssessmentOverview.useAssessment();
  const exam = AssessmentOverview.useExam();
  const questions = useGenerateQuestionsProps();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [result, updateAssessment] = Graphql.useUpdateAssessmentForAssessmentOverviewMutation();

  const examStatusMap = {
    CREATED: t("作成済み"),
    STARTED: t("実施中"),
    COMPLETED: t("実施済み"),
    FINALIZED: t("採点済み"),
    EXPIRED: t("期限切れ"),
    UNKNOWN: t("不明"),
  } as const satisfies Record<Graphql.ExamStatus, string>;

  return {
    summary: {
      terms: {
        startDate: exam.createdAtSeconds ? TimeFormatter.unixToDatetimeFormat(exam.createdAtSeconds) : "",
        endDate: exam.willEndAtSeconds ? TimeFormatter.unixToDatetimeFormat(exam.willEndAtSeconds) : "",
      },
      nextTerms: undefined,
      completedDate: exam.didEndAtSeconds ? TimeFormatter.unixToDatetimeFormat(exam.didEndAtSeconds) : "",
      status: examStatusMap[exam.status],
      timeLimit: exam.timeLimitSeconds ? TimeFormatter.formatMinutes(exam.timeLimitSeconds / 60, lang) : "0",
      messageForTalent: exam.messageForTalent || undefined,
      isPublic: assessment.isPublic,
      authorizedMemberAndGroups: assessment.viewers.map((viewer): Widget.AssessmentOverviewProps["summary"]["authorizedMemberAndGroups"][0] => {
        if (viewer.__typename === "User") {
          return {
            key: viewer.uid,
            label: viewer.displayName || viewer.email,
            kind: "MEMBER",
            photoUrl: viewer.photoUrl,
          };
        } else if (viewer.__typename === "EmployeeGroup") {
          return {
            key: viewer.groupName,
            label: viewer.groupName,
            kind: "GROUP",
          };
        } else {
          throw new Error(`Invalid Viewer ${viewer satisfies never}`);
        }
      }),
    },
    memoEditor: {
      memo: assessment.memo || "",
      onSubmit: fields => {
        updateAssessment({
          input: {
            assessmentId: assessment.id,
            memo: fields.memo,
          },
        }).then(res => {
          if (res.error) {
            Snackbar.notify({
              message: t("タレントスコアの更新に失敗しました。しばらくしてから再度お試し下さい。"),
              severity: "error",
            });
          } else {
            Snackbar.notify({
              message: t("タレントスコアの更新に成功しました。"),
              severity: "success",
            });
            setOpen(false);
          }
        });
      },
      open: open,
      onOpen: () => {
        setOpen(true);
      },
      onClose: () => {
        setOpen(false);
      },
      disabled: result.fetching,
    },
    questions: questions,
  };
};
