import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type TechnicalCommentPopperContainerProps = {
  remoteId: string;
  onClose: () => void;
};

const TechnicalCommentPopperContainer: React.FC<TechnicalCommentPopperContainerProps> = props => {
  const technicalCommentPopperProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.TechnicalCommentPopper {...technicalCommentPopperProps} />
    </ErrorBoundary>
  );
};

TechnicalCommentPopperContainer.displayName = "TechnicalCommentPopperContainer";

export default withErrorBoundary(TechnicalCommentPopperContainer, {});
