import { RemoteInterviewResourceEditorStore } from "@hireroo/app-store/view-domain/RemoteInterviewResourceEditor";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import RemoteInterviewResourceEditorContainer, { RemoteInterviewResourceEditorContainerProps } from "./Container";

export type RemoteInterviewResourceEditorMiddleContainerProps = RemoteInterviewResourceEditorContainerProps;

const RemoteInterviewResourceEditorMiddleContainer: React.FC<RemoteInterviewResourceEditorMiddleContainerProps> = props => {
  React.useEffect(() => {
    return () => {
      RemoteInterviewResourceEditorStore.clear();
    };
  }, []);
  return (
    <ErrorBoundary>
      <Widget.RemoteInterviewResourceEditorProvider>
        <RemoteInterviewResourceEditorContainer {...props} />
      </Widget.RemoteInterviewResourceEditorProvider>
    </ErrorBoundary>
  );
};

RemoteInterviewResourceEditorMiddleContainer.displayName = "RemoteInterviewResourceEditorMiddleContainer";

export default withErrorBoundary(RemoteInterviewResourceEditorMiddleContainer, {});
