import { EmployeeGroupChips } from "@hireroo/app-store/widget/e/EmployeeGroupChips";
import { InterviewOverview } from "@hireroo/app-store/widget/e/InterviewOverview";

export const startSubscribeSelectedEmployees = () => {
  return EmployeeGroupChips.subscribeSelectedEmployees(employeeIdsSet => {
    InterviewOverview.updateSelectedEmployeeIds(Array.from(employeeIdsSet.values()));
  });
};

export const startSubscribeSelectedGroups = () => {
  return EmployeeGroupChips.subscribeSelectedGroups(groupIdsSet => {
    InterviewOverview.updateSelectedGroupIds(Array.from(groupIdsSet.values()));
  });
};
