import { useStringDebouncedCallback } from "@hireroo/app-helper/react-use";
import * as SkillTagHelper from "@hireroo/app-helper/skill-tag";
import { QuestionsStore } from "@hireroo/app-store/page/e/questions";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

type DisplaySources = Widget.QuestionSearchAreaProps["skillTagsField"]["selector"]["displaySources"];

export const useGenerateSkillTagsFieldProps = (): Widget.QuestionSearchAreaProps["skillTagsField"] => {
  const { t } = useTranslation();
  const skillTagRes = QuestionsStore.useSkillTagRes();
  const currentSkillTags = QuestionsStore.useCurrentSkillTags();
  const skillTagSources = QuestionsStore.useSkillTagSources();
  const textFilter = QuestionsStore.useSkillTagTextFilter();
  const nextOffset = QuestionsStore.useSkillTagNextOffset();
  const debounced = useStringDebouncedCallback({
    callback: React.useCallback(
      value => {
        if (value !== textFilter) {
          QuestionsStore.clearCurrentSkillTagState();
        }
        QuestionsStore.updateSkillTagTextFilter(value);
      },
      [textFilter],
    ),
    delayInMicroSeconds: 500,
  });

  const [result, refresh] = Graphql.useGetHierarchicalSkillTagsForQuestionsQuery({
    variables: {
      input: {
        name: textFilter,
        depth: -1,
        offset: nextOffset,
        limit: QuestionsStore.SKILL_TAG_SIZE,
        withCount: false,
      },
    },
    requestPolicy: "network-only",
  });

  React.useEffect(() => {
    if (result.data) {
      QuestionsStore.updateSkillTags(result.data.hierarchicalSkillTags);
    }
  }, [result.data]);

  return {
    selector: {
      displaySources: React.useMemo(() => {
        const skillTagUniqueNameMap = SkillTagHelper.generateUniqueNameMapFromPath(
          skillTagSources.map(skillTag => ({
            id: skillTag.skillTagNodeId,
            name: skillTag.name,
            path: skillTag.path,
          })),
        );
        return skillTagSources.reduce<DisplaySources>((all, skillTag) => {
          all[skillTag.skillTagNodeId] = {
            optionText: skillTagUniqueNameMap.get(skillTag.skillTagNodeId) || skillTag.name,
            tooltip: skillTag.path,
          };
          return all;
        }, {});
      }, [skillTagSources]),
      suggestions: currentSkillTags.map(skillTag => ({ valueId: skillTag.skillTagNodeId })),
      loading: result.fetching,
      placeholder: t("スキルタグを検索"),
      onReachedEnd: () => {
        if (skillTagRes?.hasNext && !result.fetching) {
          QuestionsStore.updateSkillTagNextOffset();
        }
      },
      onInputChange: text => {
        debounced(text);
      },
    },
    onChange: fields => {
      // Clear skillTagState fields if the field is empty
      if (fields.length === 0) {
        QuestionsStore.clearCurrentSkillTagState();
      }
    },
    splitButton: {
      onOpen: () => {
        if (!result.fetching) {
          refresh();
        }
      },
      onClose: () => {
        QuestionsStore.clearCurrentSkillTagState();
      },
    },
  };
};
