import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import BaseMultiChoiceField, {
  MultiChoiceFieldProps as BaseMultiChoiceFieldProps,
} from "../../../../primitive/InputControl/MultiChoiceField/MultiChoiceField";
import { usePopupSearchFilterContext } from "../../PrivateContext";

const Label = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 500,
}));

const StyledMultiChoiceField = styled(BaseMultiChoiceField)(() => ({
  fontSize: 16,
  paddingLeft: "9px",
  ".MuiCheckbox-root": {
    padding: "9px",
    svg: {
      width: 24,
      height: 24,
    },
  },
}));

type FieldValue = string[];

type MultipleChoiceOption = {
  value: string;
  displayName: string;
  iconSrc?: string;
};

export type MultiChoicePrimitiveValueFieldProps = {
  name: string;
  title: string;
  options: MultipleChoiceOption[];
  onChange?: (value: FieldValue) => void;
  disabled?: boolean;
};

const MultiChoiceField: React.FC<MultiChoicePrimitiveValueFieldProps> = props => {
  const { onChange } = props;
  const { subscribeClearAllFields } = usePopupSearchFilterContext();
  const [resetCount, setResetCount] = React.useState(0);

  React.useEffect(() => {
    const stop = subscribeClearAllFields(() => {
      onChange?.([]);
      setResetCount(prev => prev + 1);
    });
    return () => {
      stop();
    };
  }, [onChange, subscribeClearAllFields]);

  const baseMultiChoiceFieldProps: BaseMultiChoiceFieldProps = {
    name: props.name,
    options: props.options,
    onChange: value => {
      props.onChange?.(value);
    },
    disabled: props.disabled,
    watch: false,
  };

  return (
    <Box sx={{ maxHeight: 250, overflow: "auto" }}>
      <Label>{props.title}</Label>
      <StyledMultiChoiceField key={resetCount.toString()} {...baseMultiChoiceFieldProps} />
    </Box>
  );
};

MultiChoiceField.displayName = "MultiChoiceField";

export default MultiChoiceField;
