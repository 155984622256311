import AreaHistogram, { AreaHistogramProps } from "@hireroo/charts/react/AreaHistogram";
import { useTranslation } from "@hireroo/i18n";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { grey } from "@mui/material/colors";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

import StatisticsQueryToolbar, { StatisticsQueryToolbarProps } from "../../modules/StatisticsQueryToolbar/StatisticsQueryToolbar";

const StyledBox = styled(Box)(({ theme }) => ({
  borderRadius: "16px",
  backgroundColor: theme.palette["Background/Paper"].p2,
  padding: "16px",
}));

type GraphStatus = "NOT_ENOUGH" | "SATISFY" | "ERROR" | "LOADING";

export type TestRankVisualizerProps = {
  status: GraphStatus;
  queryToolbar: StatisticsQueryToolbarProps;
  areaHistogram: Omit<AreaHistogramProps, "xAxis" | "yAxis" | "range" | "dataKey">;
};

const TestRankVisualizer: React.FC<TestRankVisualizerProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();

  const areaHistogram: AreaHistogramProps = {
    ...props.areaHistogram,
    xAxis: {
      label: t("パーセンタイル（%）"),
    },
    yAxis: {
      label: t("受験者数（人）"),
    },
    range: {
      fill: grey[100],
    },
    dataKey: {
      x: "score",
      y: "value",
    },
  };
  const ContentMap: Record<GraphStatus, React.ReactNode> = {
    ERROR: (
      <Box width={"100%"} mt={2}>
        <Typography>{t("データの取得に失敗しました。しばらくしてから再度お試しいただくか、運営までお問合せください。")}</Typography>
      </Box>
    ),
    NOT_ENOUGH: (
      <Box width={"100%"} mt={2}>
        <Typography>{t("データが足りません。提出の数が一定数を超えると相対評価が可能になります。")}</Typography>
      </Box>
    ),
    SATISFY: (
      <Box height={350} width={"100%"} mt={6}>
        <React.Suspense>
          <AreaHistogram {...areaHistogram} />
        </React.Suspense>
      </Box>
    ),
    LOADING: (
      <Box height={350} width={"100%"} mt={6} display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <CircularProgress />
      </Box>
    ),
  };
  return (
    <StyledBox>
      <Stack direction="row" mb={2} alignItems="center">
        <Typography variant="subtitle2" color="textSecondary">
          {t("相対評価")}
        </Typography>
        <Tooltip title={t("問題の形式や難易度を横断して算出された相対スコアに基づいて候補者の相対位置を表示しています。")} placement="top">
          <InfoOutlinedIcon sx={{ ml: 0.5, fontSize: "0.8rem", color: theme.palette.text.secondary }} />
        </Tooltip>
      </Stack>
      <StatisticsQueryToolbar {...props.queryToolbar} />
      {ContentMap[props.status]}
    </StyledBox>
  );
};

TestRankVisualizer.displayName = "TestRankVisualizer";

export default TestRankVisualizer;
