export interface PrimitiveEvent {
  onFocus?: () => void;
  onBlur?: () => void;
  onVisibilityChange?: (state: "hidden" | "visible") => void;
}

export interface RemoveEventHandler {
  focus: () => void;
  blur: () => void;
  visibilityChange: () => void;
}

export interface RegisterEventHandler {
  focus: () => void;
  blur: () => void;
  visibilityChange: () => void;
}

export interface Dispatcher {
  removeHandler: RemoveEventHandler;
  registerHandler: RegisterEventHandler;
}

export const registerEventListener = (handlers: PrimitiveEvent): Dispatcher => {
  const target = window;
  const { onFocus, onBlur, onVisibilityChange } = handlers;

  const emitFocusEvent = onFocus
    ? () => {
        onFocus();
      }
    : undefined;
  const emitBlurEvent = onBlur
    ? () => {
        onBlur();
      }
    : undefined;
  const emitVisibilityChange = onVisibilityChange
    ? () => {
        const visibilityState = document.visibilityState;
        onVisibilityChange(visibilityState);
      }
    : undefined;

  const removeHandler: RemoveEventHandler = {
    focus: () => {
      if (emitFocusEvent) target.removeEventListener("focus", emitFocusEvent);
    },
    blur: () => {
      if (emitBlurEvent) target.removeEventListener("blur", emitBlurEvent);
    },
    visibilityChange: () => {
      if (emitVisibilityChange) target.removeEventListener("visibilitychange", emitVisibilityChange);
    },
  };

  const registerHandler: RegisterEventHandler = {
    focus: () => {
      if (emitFocusEvent) {
        removeHandler.focus();
        target.addEventListener("focus", emitFocusEvent);
      }
    },
    blur: () => {
      if (emitBlurEvent) {
        removeHandler.blur();
        target.addEventListener("blur", emitBlurEvent);
      }
    },
    visibilityChange: () => {
      if (emitVisibilityChange) {
        removeHandler.visibilityChange();
        target.addEventListener("visibilitychange", emitVisibilityChange);
      }
    },
  };

  return {
    removeHandler,
    registerHandler,
  };
};
