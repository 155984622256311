import { SignInOrSignUp } from "@hireroo/app-store/essential/shared";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { confirmPasswordReset } from "@hireroo/firebase";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateHelpCenterUrl, redirect } from "@hireroo/router/api";
import { useHelpCenterNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/react";
import { useSearchParams } from "react-router-dom";

import * as PrivateHelper from "./privateHelper";

export type GenerateResetPasswordPropsArgs = {};

export const useGenerateProps = (_args: GenerateResetPasswordPropsArgs): Widget.ResetPasswordProps => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const oobCode = searchParams.get("oobCode") ?? "";

  const lang = useLanguageCode();
  const helpCenterNavigate = useHelpCenterNavigate(lang);
  const afterResetPasswordPayload = SignInOrSignUp.useAfterResetPasswordPayload();
  return {
    form: {
      kind: "RESET_PASSWORD",
      props: {
        onSubmit: async field => {
          await confirmPasswordReset(oobCode, field.password)
            .then(() => {
              Snackbar.notify({
                severity: "success",
                message: t("パスワードの変更に成功しました。"),
              });
              if (afterResetPasswordPayload?.emailAddress) {
                PrivateHelper.signInAfterResetPassword({
                  emailAddress: afterResetPasswordPayload.emailAddress,
                  password: field.password,
                });
              }
            })
            .catch(error => {
              Snackbar.notify({
                severity: "error",
                message: t("パスワードの変更に失敗しました。"),
              });
              Sentry.captureException(error);
            });
        },
      },
    },
    backButton: {
      onClick: () => {
        /**
         * redirect to ensure that the page is initialized
         */
        redirect("/signin");
      },
    },
    footer: {
      helpCenterLink: {
        href: generateHelpCenterUrl(lang, "ROOT"),
        onClick: () => {
          helpCenterNavigate("ROOT", { _blank: true });
        },
      },
    },
  };
};
