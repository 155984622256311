import { useSnapshot } from "valtio";

import { state } from "./State";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useActiveCompanySecurity = () => {
  const snapshot = useSnapshotState();
  return snapshot.security;
};

export const useInitialized = () => {
  const snapshot = useSnapshotState();
  return snapshot.security !== null;
};
