import { useSnapshot } from "valtio";

import { state } from "./State";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useUsages = () => {
  const state = useSnapshotState();
  return state.usagesTimestampMap.get(state.usagePeriodStartSeconds) || null;
};

export const useUsageCount = () => {
  const snapshot = useSnapshotState();
  return snapshot.countTimestampMap.get(state.usagePeriodStartSeconds) || 0;
};

export const useUsagePeriodStartSeconds = () => {
  const snapshot = useSnapshotState();
  return snapshot.usagePeriodStartSeconds;
};

export const usePeriods = () => {
  const snapshot = useSnapshotState();
  return snapshot.periods;
};

export const usePage = () => {
  const snapshot = useSnapshotState();
  return snapshot.page;
};

export const usePager = () => {
  const snapshot = useSnapshotState();
  return snapshot.pagers.get(snapshot.usagePeriodStartSeconds);
};

export const useFetchSize = () => {
  return 20;
};

export const useSubscribeUsages = () => {
  const fetchSize = useFetchSize();
  const usages = useUsages();
  const page = usePage();
  const start = page * fetchSize;
  const stop = (page + 1) * fetchSize;
  return Array.from(usages?.values() || []).slice(start, stop);
};

export const useFetchable = (): boolean => {
  const fetchSize = useFetchSize();
  const usages = useUsages();
  const currentSize = usages?.size ?? 0;
  const page = usePage();
  const expectedTotal = fetchSize * page + 1;
  if (!usages) {
    return true;
  }
  return currentSize < expectedTotal;
};
