import { languageMapForHighlight } from "@hireroo/challenge/definition";
import CodeEditor, { CodeEditorProps } from "@hireroo/code-editor/react/CodeEditor";
import DiffEditor, { DiffEditorProps } from "@hireroo/code-editor/react/DiffEditor";
import { useTranslation } from "@hireroo/i18n";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import Box from "@mui/material/Box";
import * as React from "react";

import ChallengePlaybackToolbar, { ChallengePlaybackToolbarProps } from "./parts/ChallengePlaybackToolbar/ChallengePlaybackToolbar";

const EDITOR_HEIGHT = 500;

const ToolValueMap = {
  FILE_DIFF: "file-diff",
} as const;

export type ChallengePlaybackSubmitCodeViewProps = {
  runtime: string;
  initialCode: string;
  submitCode: string;
};

const ChallengePlaybackSubmitCodeView: React.FC<ChallengePlaybackSubmitCodeViewProps> = props => {
  const { t } = useTranslation();
  const [selectedTools, setSelectedTools] = React.useState<string[]>([]);
  const diffEditorProps: DiffEditorProps = {
    height: EDITOR_HEIGHT,
    language: languageMapForHighlight[props.runtime],
    original: props.initialCode,
    modified: props.submitCode,
    options: {
      fontSize: 13,
      scrollBeyondLastLine: false,
      readOnly: true,
    },
  };
  const codeEditorProps: CodeEditorProps = {
    height: EDITOR_HEIGHT,
    language: languageMapForHighlight[props.runtime],
    defaultValue: props.submitCode,
    options: {
      fontSize: 13,
      scrollBeyondLastLine: false,
      readOnly: true,
    },
  };
  const showFileChanges = selectedTools.includes(ToolValueMap.FILE_DIFF);
  const challengePlaybackToolbarProps: ChallengePlaybackToolbarProps = {
    onChange: values => {
      setSelectedTools(values);
    },
    items: [
      {
        title: showFileChanges ? t("ファイルの変更を非表示") : t("ファイルの変更を表示"),
        children: <DifferenceOutlinedIcon />,
        value: ToolValueMap.FILE_DIFF,
      },
    ],
  };
  return (
    <Box>
      <ChallengePlaybackToolbar {...challengePlaybackToolbarProps} />
      <Box>
        <Box sx={{ display: showFileChanges ? "none" : "block" }}>
          <React.Suspense>
            <CodeEditor {...codeEditorProps} />
          </React.Suspense>
        </Box>
        <Box sx={{ display: showFileChanges ? "block" : "none" }}>
          <React.Suspense>
            <DiffEditor {...diffEditorProps} />
          </React.Suspense>
        </Box>
      </Box>
    </Box>
  );
};

ChallengePlaybackSubmitCodeView.displayName = "ChallengePlaybackSubmitCodeView";

export default ChallengePlaybackSubmitCodeView;
