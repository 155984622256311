import { FreepadOptionalForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import * as React from "react";
import { useForm, UseFormReturn } from "react-hook-form";

type Variant = "ALGORITHM" | "SYSTEM_DESIGN";

export type ContextValue = {
  methods: UseFormReturn<FreepadOptionalForm.FreepadOptionalFormSchema>;
  variant: Variant;
};

export const FreepadOptionalFormContext = React.createContext<ContextValue>({} as ContextValue);

export const useFreepadOptionalFormContext = () => React.useContext(FreepadOptionalFormContext);

export type FreepadOptionalFormProviderProps = {
  defaultValues: FreepadOptionalForm.FreepadOptionalFormSchema;
  variant: Variant;
};

export const FreepadOptionalFormProvider: React.FC<React.PropsWithChildren<FreepadOptionalFormProviderProps>> = props => {
  const { defaultValues, variant } = props;
  const validateSchema = FreepadOptionalForm.useOptionalFormSchema();
  const methods = useForm<FreepadOptionalForm.FreepadOptionalFormSchema>({
    resolver: zodResolver(validateSchema),
    mode: "onSubmit",
    shouldUnregister: false,
    defaultValues,
  });
  React.useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues, methods]);

  const contextValue: ContextValue = {
    methods: methods,
    variant: variant,
  };
  return <FreepadOptionalFormContext.Provider value={contextValue} children={props.children} />;
};
