import { useQuestionDifficultyMap } from "@hireroo/app-definition/question";
import { FeedbackLinkFactory, QuestionStats } from "@hireroo/app-helper/question";
import { useTitle } from "@hireroo/app-helper/react-use";
import { Credential } from "@hireroo/app-store/essential/shared";
import { formateRateToIntegerPercent } from "@hireroo/formatter/rate";
import * as Time from "@hireroo/formatter/time";
import type * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import { Widget } from "@hireroo/presentation";
import * as React from "react";

import { generateQuizQuestionCardContentProps } from "./privateHelper";

export type GenerateQuizPackageDetailPropsArgs = {
  packageDetail: Graphql.GetMultiChoicePackageForQuestionQuizIdPackageFragment;
};

export const useGenerateProps = (args: GenerateQuizPackageDetailPropsArgs): Widget.QuizPackageDetailProps => {
  const lang = useLanguageCode();
  const currentUserMailAddress = Credential.useCurrentUserMailAddress();
  const difficultyMap = useQuestionDifficultyMap();
  const quizQuestionCardContentsProps = args.packageDetail.questions.map((question, index) => {
    return generateQuizQuestionCardContentProps(question, lang, index);
  });
  useTitle(resolveLanguage(args.packageDetail, lang, "title"));
  const elapseTimeObject = React.useMemo(() => {
    return Time.secondsToTimeObject(args.packageDetail.averageElapsedTimeSeconds, "MINUTES");
  }, [args.packageDetail.averageElapsedTimeSeconds]);
  return {
    packageTitle: resolveLanguage(args.packageDetail, lang, "title"),
    packageDescription: resolveLanguage(args.packageDetail, lang, "description"),
    difficulty: difficultyMap[args.packageDetail.difficulty],
    quizQuestionCardContents: quizQuestionCardContentsProps,
    isArchived: args.packageDetail.status === "ARCHIVED",
    info: {
      feedbackLink: FeedbackLinkFactory.generateFeedbackLink({
        mailAddress: currentUserMailAddress,
        targetUrl: window.location.href,
      }),
      detailInfo: {
        difficultyStars: {
          difficulty: args.packageDetail.difficulty,
        },
        timelimitMinutes: Time.secondsToTimeObject(args.packageDetail.timeLimitSeconds || 0, "MINUTES").minutes,
        createdBy: args.packageDetail.isOfficial
          ? {
              kind: "OFFICIAL",
            }
          : {
              kind: "PRIVATE",
              displayName: args.packageDetail.employee.displayName,
            },
        numOfQuestions: {
          value: args.packageDetail.questions.length,
        },
      },
      scoreHistogram: {
        data: QuestionStats.generateScoreDistributionBinsForHistogram(args.packageDetail.scoreDistributionBins),
        dataKey: {
          x: "name",
          y: "value",
        },
      },
      stats: {
        avgCorrectIntegerRate:
          args.packageDetail.numUses > 0
            ? {
                kind: "VALUE",
                value: formateRateToIntegerPercent(args.packageDetail.accuracyRate),
              }
            : { kind: "NONE" },
        numOfUsage: args.packageDetail.numUses.toLocaleString(),
        avgElapseTime:
          args.packageDetail.numUses > 0
            ? {
                kind: "VALUE",
                minutes: elapseTimeObject.minutes,
                seconds: elapseTimeObject.seconds,
              }
            : { kind: "NONE" },
      },
    },
  };
};
