import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

type Color = "primary" | "secondary";

const StyledStack = styled(Stack)(() => ({
  svg: {
    width: "16px",
    height: "16px",
  },
}));

const StyledTypography = styled(Typography)(() => ({
  fontSize: "12px",
  fontWeight: "400",
}));

export type LabelItemProps = {
  noWrap?: boolean;
  /** @default primary */
  color?: Color;
  StartIcon?: React.ReactNode;
  label: React.ReactNode;
  tooltipText?: string;
};

const LabelItem: React.FC<LabelItemProps> = props => {
  const theme = useTheme();
  const colorMap: Record<Color, string> = {
    primary: theme.palette.primary.main,
    secondary: theme.palette.text.secondary,
  };
  const color = colorMap[props.color ?? "primary"];
  return (
    <StyledStack direction="row" alignItems="center" color={color}>
      {props.StartIcon}
      <Tooltip title={props.tooltipText ?? ""}>
        <StyledTypography ml={0.5} color={color} noWrap={props.noWrap}>
          {props.label}
        </StyledTypography>
      </Tooltip>
    </StyledStack>
  );
};

LabelItem.displayName = "LabelItem";

export default LabelItem;
