/* eslint-disable no-prototype-builtins */
import "firebase/compat/database";

import { getRef } from "@hireroo/firebase";
import firebase from "firebase/compat/app";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { revisionFromId, revisionToId, SyncState } from "../firepad";
import { tuple } from "../tuple";

export const getTimestamp = () => {
  return firebase.database.ServerValue.TIMESTAMP;
};

export type CollaborativeState = {
  selectedQuestion: number;
  selectedLanguage: string;
  isLanguageChanged: boolean;
  usedHints: Set<number>;
  ready: boolean;
};

export type CollaborativeAction = {
  setSelectedQuestionWrapper: (selectedQuestionId: number) => void;
  setSelectedLanguageWrapper: (selectedLanguage: string) => void;
  submitQuestion: (questionId: number) => void;
  runCode: (snapshotId: number) => void;
  useHint: (hintId: number) => void;
};

export type ChallengeRealtimeDatabaseArgs = {
  challengeId: number;
  isCandidate: boolean;
  isInterviewing?: boolean;
  selectedQuestionId: number;
  selectedLanguage: string;
  setSelectedLanguage: (language: string) => void;
  isLanguageChanged: boolean;
  setIsLanguageChanged: (changed: boolean) => void;
};

export const useChallengeRealtimeDatabase = (args: ChallengeRealtimeDatabaseArgs): [CollaborativeState, CollaborativeAction] => {
  const {
    challengeId,
    isInterviewing,
    isCandidate,
    selectedQuestionId,
    selectedLanguage,
    setSelectedLanguage,
    isLanguageChanged,
    setIsLanguageChanged,
  } = args;
  // To write the realtime log at challenges/${challengeId}
  const challengeIndexRef = useRef<number>(0);
  const challengeRef = useRef<firebase.database.Reference | undefined>();
  const challengeLastStateRef = useRef<SyncState>();
  const [challengeReady, setChallengeReady] = useState<boolean>(false);

  // To write the realtime log at challenges/${challengeId}/questions/${questionId}
  const questionIndexRef = useRef<number>(0);
  const questionRef = useRef<firebase.database.Reference | undefined>();
  const [questionReady, setQuestionReady] = useState<boolean>(false);

  // To write the realtime log at challenges/${challengeId}/questions/${questionId}/languages/${language}
  const languageIndexRef = useRef<number>(0);
  const languageRef = useRef<firebase.database.Reference | undefined>();
  const [languageReady, setLanguageReady] = useState<boolean>(false);

  const [selectedQuestion, setSelectedQuestion] = useState<number>(selectedQuestionId);
  const [usedHints, setUsedHints] = useState<Set<number>>(new Set());

  useEffect(() => {
    setSelectedQuestion(selectedQuestionId);
  }, [selectedQuestionId]);

  const ready = useMemo<boolean>(() => {
    return challengeReady && questionReady && languageReady;
  }, [challengeReady, questionReady, languageReady]);

  const setSelectedQuestionWrapper = useCallback(
    (newSelectedQuestion: number) => {
      if (!challengeReady) return;
      challengeRef.current?.child(revisionToId(challengeIndexRef.current)).set({
        s: "selq",
        v: newSelectedQuestion,
        t: getTimestamp(),
      });
    },
    [challengeReady],
  );

  const setInQuestionWrapper = useCallback(
    (newSelectedQuestion: number) => {
      if (!challengeReady) return;
      challengeRef.current?.child(revisionToId(challengeIndexRef.current)).set({
        s: "inq",
        v: newSelectedQuestion,
        t: getTimestamp(),
      });
    },
    [challengeReady],
  );

  const setOutQuestionWrapper = useCallback(
    (newSelectedQuestion: number) => {
      if (!challengeReady) return;
      challengeRef.current?.child(revisionToId(challengeIndexRef.current)).set({
        s: "outq",
        v: newSelectedQuestion,
        t: getTimestamp(),
      });
    },
    [challengeReady],
  );

  const setSelectedLanguageWrapper = useCallback(
    (newSelectedLanguage: string) => {
      if (!questionReady) return;
      questionRef.current?.child(revisionToId(questionIndexRef.current)).set({
        s: "sell",
        v: newSelectedLanguage,
        t: getTimestamp(),
      });
    },
    [questionReady],
  );

  // Not sure if this is the best way to do...
  const submitQuestion = useCallback(
    (questionId: number) => {
      if (!languageReady) return;
      languageRef.current?.child(revisionToId(languageIndexRef.current)).set({
        s: "subq",
        v: questionId,
        t: getTimestamp(),
      });
    },
    [languageReady],
  );

  const runCode = useCallback(
    (snapshotId: number) => {
      if (!languageReady) return;
      languageRef.current?.child(revisionToId(languageIndexRef.current)).set({
        s: "runc",
        v: snapshotId,
        t: getTimestamp(),
      });
    },
    [languageReady],
  );

  const useHint = useCallback(
    (hintId: number) => {
      if (!questionReady) return;
      questionRef.current?.child(revisionToId(questionIndexRef.current)).set({
        s: "useh",
        v: hintId,
        t: getTimestamp(),
      });
    },
    [questionReady],
  );

  const isOutState = useCallback((state?: SyncState) => {
    return state?.s === "outq";
  }, []);

  const setStateFromEvent = useCallback(
    (state: SyncState) => {
      switch (state.s) {
        case "selq":
          setSelectedQuestion(state.v);
          break;
        case "sell": {
          setSelectedLanguage(state.v);
          setIsLanguageChanged(true);
          break;
        }
        case "useh":
          setUsedHints(prev => prev.add(state.v));
          break;
      }
    },
    [setSelectedLanguage, setIsLanguageChanged],
  );

  const getLatestState = (data: { [key: string]: SyncState }): { q?: number; l?: string; h?: Set<number>; i?: boolean; k?: number } => {
    // q: questionId, l: last selected runtime, h: used hint set, i: last state is "inq" or not, k: latest index,
    const v: { q?: number; l?: string; h?: Set<number>; i?: boolean; k?: number } = {};
    const inqOutq = new Set<string>();

    Object.keys(data)
      .sort()
      .forEach((key: string) => {
        if (data[key].s === "selq") v.q = data[key].v as number;
        if (data[key].s === "sell") v.l = data[key].v as string;
        if (data[key].s === "useh") {
          if (!v.h) v.h = new Set();
          v.h.add(data[key].v as number);
        }

        if (data[key].s === "inq") inqOutq.add("inq");
        if (data[key].s === "outq") inqOutq.clear();
        v.k = revisionFromId(key);
      });

    v.i = inqOutq.size > 0;
    return v;
  };

  useEffect(() => {
    // Connect to firebase for realtime sync
    challengeRef.current = getRef("challenge", `challenges/${challengeId}/state`);
    challengeIndexRef.current = 0;
    let initialLoaded = false;

    challengeRef.current?.on("child_added", snapshot => {
      if (!initialLoaded) return;

      const latest = snapshot.val() as SyncState;
      challengeLastStateRef.current = latest;
      challengeIndexRef.current = revisionFromId(snapshot.key as string) + 1;
      setStateFromEvent(latest);
    });

    challengeRef.current?.once("value", snapshot => {
      const data: Record<string, SyncState> | null = snapshot.val();
      if (data) {
        // state under challenges/${challengeId}/state holds `selq` only.
        // only latest.q and latest.k will be considered, and others will be ignored.
        const latest = getLatestState(data);
        if (latest.q !== undefined) setSelectedQuestion(latest.q);
        if (latest.k !== undefined) challengeIndexRef.current = latest.k + 1;

        // The interviewer's log is unnecessary and will not go through.
        // And, No values are recorded, even for tests that have already been completed.
        if (isCandidate && isInterviewing) {
          // eslint-disable-next-line no-irregular-whitespace
          // If "inq" already exists　as latest, do not add "inq"
          if (latest.i === undefined || latest.i === false) {
            setInQuestionWrapper(selectedQuestion);
          }
        }
      } else {
        if (isCandidate && isInterviewing) {
          // If this is the first landing where data does not exist, enter an initial value.
          setInQuestionWrapper(selectedQuestion);
        }
      }

      // Initial sync is done.
      initialLoaded = true;
      setChallengeReady(true);
    });
  }, [challengeId, isCandidate, isInterviewing, selectedQuestion, setInQuestionWrapper, setStateFromEvent]);

  useEffect(() => {
    if (!challengeReady) return;
    // Connect to firebase for realtime sync
    questionRef.current = getRef("challenge", `challenges/${challengeId}/questions/${selectedQuestion}/state`);
    questionIndexRef.current = 0;
    let initialLoaded = false;

    questionRef.current?.on("child_added", snapshot => {
      if (!initialLoaded) return;
      questionIndexRef.current = revisionFromId(snapshot.key as string) + 1;
      setStateFromEvent(snapshot.val());
    });

    questionRef.current?.once("value", snapshot => {
      const data: Record<string, SyncState> | null = snapshot.val();
      if (data) {
        // state under challenges/${challengeId}/questions/${questionId}/state holds `sell` `useh` only.
        // only latest.l, latest.h, and latest.k will be considered, and others will be ignored.
        const latest = getLatestState(data);
        if (latest.l !== undefined) {
          setSelectedLanguage(latest.l);
          setIsLanguageChanged(true);
        }
        if (latest.h !== undefined) setUsedHints(latest.h);
        if (latest.k !== undefined) questionIndexRef.current = latest.k + 1;
      }

      // Initial sync is done.
      initialLoaded = true;
      setQuestionReady(true);
    });
  }, [challengeId, challengeReady, selectedQuestion, setSelectedLanguage, setIsLanguageChanged, setStateFromEvent]);

  useEffect(() => {
    if (!questionReady) return;
    // Connect to firebase for realtime sync
    languageRef.current = getRef("challenge", `challenges/${challengeId}/questions/${selectedQuestion}/languages/${selectedLanguage}/state`);
    languageIndexRef.current = 0;
    let initialLoaded = false;

    languageRef.current?.on("child_added", snapshot => {
      if (!initialLoaded) return;
      languageIndexRef.current = revisionFromId(snapshot.key as string) + 1;
      setStateFromEvent(snapshot.val());
    });

    languageRef.current?.once("value", snapshot => {
      const data: Record<string, SyncState> | null = snapshot.val();
      if (data) {
        // state under challenges/${challengeId}/questions/${questionId}/languages/${language}/state doesn't hold anything.
        // only latest.k will be considered, and others will be ignored.
        const latest = getLatestState(data);
        if (latest.k !== undefined) languageIndexRef.current = latest.k + 1;
      }

      // Initial sync is done.
      initialLoaded = true;
      setLanguageReady(true);
    });
  }, [challengeId, questionReady, selectedQuestion, selectedLanguage, setStateFromEvent]);

  // If the cleanup function that throws 'outq' is called after IndexRef.current,
  // IT MUST BE CALLED FIRST because the value will be after initialization.
  useEffect(() => {
    if (!isInterviewing) return;
    // Set challenge out event to firebase.
    // This works only when the question switched or submitted.
    return () => {
      if (challengeReady && !isOutState(challengeLastStateRef.current)) {
        setOutQuestionWrapper(selectedQuestion);
      }
    };
  }, [challengeReady, isInterviewing, selectedQuestion, setOutQuestionWrapper, isOutState]);

  useEffect(() => {
    return () => {
      challengeRef.current = undefined;
      challengeIndexRef.current = 0;
      setChallengeReady(false);

      questionRef.current = undefined;
      questionIndexRef.current = 0;
      setQuestionReady(false);

      languageRef.current = undefined;
      languageIndexRef.current = 0;
      setLanguageReady(false);
    };
  }, []);

  const state = {
    selectedQuestion,
    selectedLanguage,
    isLanguageChanged,
    usedHints,
    ready,
  };

  const dispatcher = {
    setSelectedQuestionWrapper,
    setSelectedLanguageWrapper,
    submitQuestion,
    runCode,
    useHint,
  };

  return tuple(state, dispatcher);
};
