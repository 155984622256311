import { ScreeningResourceEditorQueryParams } from "@hireroo/app-definition/spot";
import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Auth, Role } from "@hireroo/app-store/essential/employee";
import { ScreeningsStore } from "@hireroo/app-store/page/e/screenings";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateCurrentOriginUrl, generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/react";
import * as React from "react";

import { useGenerateBulkListActivityBarProps } from "./useGenerateBulkListActivityBarProps";

type Item = Widget.ScreeningListProps["table"]["items"][number];

export type GenerateScreeningListPropsArgs = {};

export const useGenerateProps = (_args: GenerateScreeningListPropsArgs): Widget.ScreeningListProps => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const screenings = ScreeningsStore.useScreenings();
  const client = getGraphqlClient();
  const navigate = useTransitionNavigate();
  const pager = ScreeningsStore.usePager();
  const res = ScreeningsStore.useResponse();
  const dialogStatus = ScreeningsStore.useDialogStatus();
  const selectableTags = ScreeningsStore.useTags().map(tag => tag.name);
  const [loadingStatus, setLoadingStatus] = React.useState<"LOADING" | "READY">("READY");
  const selectedScreeningIds = ScreeningsStore.useSelectedScreeningIds();
  const bulkListActivityBar = useGenerateBulkListActivityBarProps({});
  const currentUid = Auth.useCurrentUid();
  const refreshKey = ScreeningsStore.useRefreshKey();
  const matchingRole = Role.useMatchingRole();

  const items = React.useMemo((): Item[] => {
    return screenings.map((screening): Item => {
      const actionMenu: Item["actionMenu"] = {
        disabled: false,
        options: [
          {
            startIcon: "GROUP",
            value: "screenings",
            displayName: t("候補者一覧に移動"),
            onClick: () => {
              navigate("/e/screenings/tests", { queryParams: { screeningIds: screening.screeningId } });
            },
            disabled: screening.numAccepted === 0,
            disabledText: t("候補者がいません"),
            divider: true,
          },
          {
            startIcon: "EDIT",
            value: "edit",
            displayName: t("編集"),
            onClick: () => {
              navigate("/e/screenings/:id/update", { pathParams: { id: screening.screeningId } });
            },
          },
          {
            startIcon: "CONTENT_COPY",
            value: "copy",
            displayName: t("複製"),
            onClick: () => {
              client
                .ReplicateScreeningForScreenings({
                  input: {
                    screeningId: screening.screeningId,
                    replicatedBy: currentUid,
                  },
                })
                .then(res => {
                  ScreeningsStore.addScreening(res.replicateScreening);
                  Snackbar.notify({
                    severity: "success",
                    message: t("コーディングテストを複製しました。"),
                  });
                })
                .catch(error => {
                  Sentry.captureException(error);
                  const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("コーディングテストを複製に失敗しました。"));
                  Snackbar.notify({
                    severity: "error",
                    message: errorNotification.message,
                  });
                });
            },
          },
          {
            startIcon: "LABEL",
            value: "tag-setting",
            displayName: t("タグ設定"),
            onClick: () => {
              ScreeningsStore.updateDialogStatus(`TAG_OPEN_${screening.screeningId}`);
            },
            divider: true,
          },
          {
            startIcon: "DELETE",
            value: "delete",
            displayName: t("削除"),
            color: "error",
            onClick: () => {
              ScreeningsStore.updateDialogStatus(`DELETE_OPEN_${screening.screeningId}`);
            },
            disabled: !matchingRole.ADMIN_OR_MANAGER.matched,
            disabledText: matchingRole.ADMIN_OR_MANAGER.messageOnUnmatched,
          },
        ],
        tooltipText: t("複数選択中はこの操作はできません。"),
        tagDialog: {
          open: dialogStatus === `TAG_OPEN_${screening.screeningId}`,
          onClose: () => {
            ScreeningsStore.updateDialogStatus("CLOSE");
          },
          tagInputField: {
            disabled: loadingStatus === "LOADING",
            options: selectableTags,
          },
          onSubmit: field => {
            setLoadingStatus("LOADING");
            client
              .UpdateScreeningForScreenings({
                input: {
                  screeningId: screening.screeningId,
                  tagNames: field.tags,
                },
              })
              .then(res => {
                Snackbar.notify({
                  severity: "success",
                  message: t("タグを更新しました。"),
                });
                ScreeningsStore.updateScreening(res.updateScreening);
              })
              .catch(error => {
                Sentry.captureException(error);
                const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("タグの更新に失敗しました。"));
                Snackbar.notify({
                  severity: "error",
                  message: errorNotification.message,
                });
              })
              .finally(() => {
                ScreeningsStore.updateDialogStatus("CLOSE");
                setLoadingStatus("READY");
              });
          },
          defaultValues: {
            tags: screening.tags.map(tag => tag.name),
          },
          disabled: loadingStatus === "LOADING",
        },
        deleteDialog: {
          dialog: {
            open: dialogStatus === `DELETE_OPEN_${screening.screeningId}`,
            yesButton: {
              disabled: loadingStatus === "LOADING",
              onClick: () => {
                setLoadingStatus("LOADING");
                client
                  .DeleteScreeningForScreenings({
                    input: {
                      screeningId: screening.screeningId,
                    },
                  })
                  .then(() => {
                    Snackbar.notify({
                      severity: "success",
                      message: t("コーディングテストを削除しました。"),
                    });
                    ScreeningsStore.deleteScreening(screening.screeningId);
                  })
                  .catch(error => {
                    Sentry.captureException(error);
                    const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                      error,
                      t("コーディングテストの削除に失敗しました。"),
                    );
                    Snackbar.notify({
                      severity: "error",
                      message: errorNotification.message,
                    });
                  })
                  .finally(() => {
                    ScreeningsStore.updateDialogStatus("CLOSE");
                    setLoadingStatus("READY");
                  });
              },
            },
            noButton: {
              disabled: loadingStatus === "LOADING",
              onClick: () => {
                ScreeningsStore.updateDialogStatus("CLOSE");
              },
            },
          },
          items: [
            {
              name: t("一度削除すると元に戻すことはできません。"),
            },
            {
              name: t("コーディングテストへの招待リンクが利用できなくなります。"),
            },
            {
              name: t("コーディングテスト詳細ページに含まれる機能が利用できなくなります。"),
            },
            {
              name: t("受験者がすでにテストリンクを発行している場合、そのテストリンクは利用できます。"),
            },
          ],
          name: screening.name,
        },
      };

      const modeMap: Record<ScreeningsStore.Screening["candidateAccessPolicy"], Item["inviteCandidate"]["mode"]> = {
        ALLOW_ALL: "ALL",
        RESTRICTED_BY_INVITATION_CODE: "MAIL_ONLY",
      };

      return {
        id: screening.id,
        disabled: false,
        mode: "SELECTABLE",
        href: generatePath("/e/screenings/:id/detail", { pathParams: { id: screening.screeningId } }),
        onClick: () => {
          navigate("/e/screenings/:id/detail", { pathParams: { id: screening.screeningId } });
        },
        meta: {
          title: screening.name,
          status: screening.isActive ? "ACTIVE" : "NOT_ACTIVE",
          candidateAccessPolicy: screening.candidateAccessPolicy,
        },
        tags: screening.tags.map(tag => tag.name),
        numCandidates: screening.numAccepted,
        numRemain: screening?.acceptableLimit ? screening.acceptableLimit - screening.numAccepted : undefined,
        creator: {
          label: screening.createdBy?.displayName || screening.createdBy?.email || t("不明"),
          src: screening.createdBy?.photoUrl || undefined,
        },
        candidateButton: {
          onClick: () => {
            navigate("/e/screenings/tests", { queryParams: { screeningIds: screening.screeningId } });
          },
        },
        actionMenu: actionMenu,
        activateSwitch: {
          icon:
            screening.acceptanceStatus === "SUBMIT_DEADLINE_EXCEEDED"
              ? {
                  message: t("提出期限が過ぎています。再開したい場合は提出期限を設定し直してください。"),
                }
              : undefined,
          status: screening.isActive ? "ACTIVE" : "NOT_ACTIVE",
          activateDialog: {
            targetName: screening.name,
          },
          onSubmit: controller => {
            controller.setLoading(true);
            client
              .UpdateScreeningForScreenings({
                input: {
                  screeningId: screening.screeningId,
                  isActive: !screening.isActive,
                },
              })
              .then(res => {
                Snackbar.notify({
                  severity: "success",
                  message: screening.isActive ? t("コーディングテストを停止しました。") : t("コーディングテストを受付中にしました。"),
                });
                ScreeningsStore.updateScreening(res.updateScreening);
              })
              .catch(error => {
                Sentry.captureException(error);
                const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                  error,
                  screening.isActive ? t("コーディングテストの停止に失敗しました。") : t("コーディングテストの受付に失敗しました。"),
                );
                Snackbar.notify({
                  severity: "error",
                  message: errorNotification.message,
                });
              })
              .finally(() => {
                controller.close();
                controller.setLoading(false);
              });
          },
        },
        inviteCandidate: {
          disabled: !screening.isActive,
          onOpen: () => {
            // retrieve the screening since list rpc does not return invitees
            client
              .GetScreeningForScreenings({
                screeningId: screening.screeningId,
                invitationCode: null,
              })
              .then(res => {
                ScreeningsStore.updateScreening(res.screening);
              })
              .catch(error => {
                Sentry.captureException(error);
                const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("コーディングテストの取得に失敗しました。"));
                Snackbar.notify({
                  severity: "error",
                  message: errorNotification.message,
                });
              });
          },
          dialog: {
            name: screening.name,
            copyButton:
              screening.candidateAccessPolicy === "ALLOW_ALL"
                ? {
                    url: generateCurrentOriginUrl("/c/screenings/:id", { pathParams: { id: screening.screeningId } }),
                    onCopy: () => {
                      Snackbar.notify({
                        severity: "info",
                        message: t("クリップボードにコピーされました"),
                      });
                    },
                  }
                : undefined,
            onSubmit: fields => {
              setLoadingStatus("LOADING");
              client
                .InviteCandidateToScreeningForScreenings({
                  input: {
                    screeningId: screening.screeningId,
                    candidateEmails: fields.emails.map(email => email.value),
                  },
                })
                .then(res => {
                  Snackbar.notify({
                    severity: "success",
                    message: t("招待メールを候補者に送信しました。"),
                  });
                  ScreeningsStore.updateScreeningInvitees(res.inviteCandidateToScreening.invitees);
                })
                .catch(error => {
                  Sentry.captureException(error);
                  const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("招待メールの送信に失敗しました。"));
                  Snackbar.notify({
                    severity: "error",
                    message: errorNotification.message,
                  });
                })
                .finally(() => {
                  setLoadingStatus("READY");
                });
            },
            language: screening.invitationLanguage !== "UNKNOWN" ? screening.invitationLanguage : "JA",
            editButton: {
              onClick: () => {
                navigate("/e/screenings/:id/update", {
                  pathParams: { id: screening.screeningId },
                  queryParams: {
                    [ScreeningResourceEditorQueryParams.Key]: ScreeningResourceEditorQueryParams.Value.TestInviteSetup,
                  },
                });
              },
            },
            invitedListButton: {
              onClick: () => {
                navigate("/e/screenings/:id/detail", {
                  pathParams: { id: screening.screeningId },
                  queryParams: {
                    tab: "INVITEES",
                  },
                });
              },
            },
          },
          mode: modeMap[screening.candidateAccessPolicy],
        },
      };
    });
  }, [
    screenings,
    t,
    matchingRole.ADMIN_OR_MANAGER.matched,
    matchingRole.ADMIN_OR_MANAGER.messageOnUnmatched,
    dialogStatus,
    loadingStatus,
    selectableTags,
    navigate,
    client,
    currentUid,
  ]);

  const sortOptions = React.useMemo((): Widget.ScreeningListProps["searchResultBar"]["sortField"]["options"] => {
    return [
      {
        displayName: t("作成日時が新しい順"),
        value: ScreeningsStore.SortFields.CREATED_AT_DESCENDING,
      },
      {
        displayName: t("作成日時が古い順"),
        value: ScreeningsStore.SortFields.CREATED_AT_ASCENDING,
      },
    ];
  }, [t]);

  const isValidOptionValue = React.useCallback(
    (value: string): value is ScreeningsStore.SortFieldValue => {
      return sortOptions.map(o => o.value).includes(value);
    },
    [sortOptions],
  );

  const screeningRefreshKey = React.useMemo((): string => {
    return `${items.length}-${refreshKey}`;
  }, [items, refreshKey]);

  return {
    refreshKey: screeningRefreshKey,
    table: {
      items: items,
      onChange: fields => {
        const selectedItems = fields.items.filter(item => item.selected);
        ScreeningsStore.updateSelectedScreeningIds(selectedItems.map(({ itemId }) => itemId));
      },
    },
    searchResultBar: {
      mark: "READY",
      resultText: t2("SearchResultCount", { count: res.count }),
      sortField: {
        options: sortOptions,
        onChange: value => {
          if (isValidOptionValue(value)) {
            ScreeningsStore.updateSortField(value);
          }
        },
        defaultValue: pager.sortFieldValue,
      },
      mode: React.useMemo(() => (selectedScreeningIds.length > 0 ? "SELECTED" : "NONE"), [selectedScreeningIds.length]),
      bulkListActivityBar: bulkListActivityBar,
    },
    pagination: {
      count: res.count,
      rowsPerPage: pager.size,
      onRowsPerPageChange: event => {
        const newSize = Number(event.target.value);
        ScreeningsStore.updatePageSize(newSize);
        /**
         * clear selected spot ids to refresh state inside the list
         */
        ScreeningsStore.clearSelectedScreeningIds();
      },
      page: pager.page,
      onPageChange: (_, newPage) => {
        ScreeningsStore.updatePage(newPage);
        /**
         * clear selected spot ids to refresh state inside the list
         */
        ScreeningsStore.clearSelectedScreeningIds();
      },
    },
  };
};
