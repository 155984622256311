import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import type { Widget } from "@hireroo/presentation";

import * as PrivateHelper from "./privateHelper";
import { useGenerateProjectDiffViewerProps } from "./useGenerateProjectDiffViewerProps";
import { useGenerateProjectPlaybackProps } from "./useGenerateProjectPlaybackProps";

const { ProjectContentsViewerV1 } = ProjectTestReport;

export type GenerateProjectContentsViewerV1PropsArgs = {
  projectId: number;
  questionId: number;
  mode: "HISTORY" | "PLAYBACK";
};

export const useGenerateProps = (args: GenerateProjectContentsViewerV1PropsArgs): Widget.ProjectContentsViewerV1Props => {
  const action = ProjectContentsViewerV1.createAction(args.projectId);
  const hooks = ProjectContentsViewerV1.createHooks(args.projectId);
  const diffMap = hooks.useDiffMap();
  const selectFileName = hooks.useCurrentSelectFilename();

  const projectDiffViewerProps = useGenerateProjectDiffViewerProps({
    projectId: args.projectId,
  });
  const projectPlaybackProps = useGenerateProjectPlaybackProps({
    projectId: args.projectId,
    questionId: args.questionId,
    active: args.mode === "PLAYBACK",
  });

  const tabProps: Widget.ProjectContentsViewerV1Props["tabs"] = Array.from(diffMap.keys()).map(filename => {
    const diff = diffMap.get(filename);
    return {
      value: filename,
      op: diff ? PrivateHelper.OperationMap[diff.operation] : "UNKNOWN",
      displayText: filename,
      disabled: false,
      tooltipText: "",
    };
  });

  const baseProps: Omit<Widget.ProjectContentsViewerV1Props, "mode" | "content"> = {
    value: selectFileName || (tabProps[0] ? tabProps[0].value : ""),
    onChange: (_, filename) => {
      if (typeof filename === "string") {
        action.setCurrentSelectFileName(filename);
      }
    },
    tabs: tabProps,
  };

  if (args.mode === "PLAYBACK") {
    return {
      ...baseProps,
      mode: "PLAYBACK",
      content: projectPlaybackProps,
    };
  }
  return {
    ...baseProps,
    mode: "DIFF",
    content: projectDiffViewerProps,
  };
};
