import { useTranslation } from "@hireroo/i18n";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import * as React from "react";

import BackendTestCaseV3, { BackendTestCaseV3Props } from "../../modules/BackendTestCaseV3/BackendTestCaseV3";
import TestCaseDialog, { TestCaseDialogProps } from "../../primitive/TestCaseDialog/TestCaseDialog";
import TestCaseTableRow, { TestCaseTableRowProps } from "../../primitive/TestCaseTableRow/TestCaseTableRow";

export type RowItemProps = Omit<TestCaseTableRowProps, "TestResult"> & {
  testResult?: BackendTestCaseV3Props;
};

export type BackendTestCaseDialogV3Props = {
  open: boolean;
  onClose: TestCaseDialogProps["onClose"];
  items: RowItemProps[];
};

const BackendTestCaseDialogV3: React.FC<BackendTestCaseDialogV3Props> = props => {
  const { t } = useTranslation();
  const testCaseDialogProps: Omit<TestCaseDialogProps, "children"> = {
    open: props.open,
    title: t("テストケースの実行"),
    description: [
      t("各テストケースは独立して実行することができます。テストケースが落ちている場合は、問題文を確認しレスポンスを再定義してください。"),
    ].join(""),
    onClose: props.onClose,
  };
  return (
    <TestCaseDialog {...testCaseDialogProps}>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableBody>
            {props.items.map((item, index) => {
              const { testResult, ...rowProps } = item;
              const testCaseTableRowProps: TestCaseTableRowProps = {
                ...rowProps,
                TestResult: testResult && <BackendTestCaseV3 {...testResult} />,
              };
              return <TestCaseTableRow key={`testcase-${index}`} {...testCaseTableRowProps} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </TestCaseDialog>
  );
};

BackendTestCaseDialogV3.displayName = "BackendTestCaseDialog";

export default BackendTestCaseDialogV3;
