import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import QuestionPackageShortDetail, {
  QuestionPackageShortDetailProps,
} from "../../ms-components/Question/QuestionPackageShortDetail/QuestionPackageShortDetail";
import QuestionSelector, { QuestionSelectorProps } from "./parts/QuestionSelector/QuestionSelector";
import QuestionVariants, { QuestionVariantsProps } from "./parts/QuestionVariants/QuestionVariants";

const StyledBox = styled(Box)(({ theme }) => ({
  height: "100%",
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(3),
  minWidth: 900,
}));

const StyledStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette["Background/Paper"].p2,
  overflow: "auto",
  borderRadius: "16px",
}));

const ContentStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3),
}));

export type QuestionPackageDetailProps = {
  title: string;
  description: string;
  shortDetail: QuestionPackageShortDetailProps;
  variants: QuestionVariantsProps;
  questionSelector: QuestionSelectorProps;
};

const QuestionPackageDetail: React.FC<QuestionPackageDetailProps> = props => {
  return (
    <StyledBox>
      <StyledStack direction="column">
        <ContentStack direction="column" spacing={3}>
          <Typography fontSize={20} fontWeight={700}>
            {props.title}
          </Typography>
          <Typography fontSize={14}>{props.description}</Typography>
          <QuestionPackageShortDetail {...props.shortDetail} />
          <QuestionVariants {...props.variants} />
          <QuestionSelector {...props.questionSelector} />
        </ContentStack>
      </StyledStack>
    </StyledBox>
  );
};

QuestionPackageDetail.displayName = "QuestionPackageDetail";

export default QuestionPackageDetail;
