import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";

import { useSystemDesignContext } from "../../../store";
import StaticFlowChart from "../StaticFlowChart/StaticFlowChart";

const MiniMapBox = styled(Box)(({ theme }) => ({
  position: "relative",
  textAlign: "center",
  margin: "0 auto",
  maxHeight: 144,
  [theme.breakpoints.up("lg")]: {
    width: 144,
    maxHeight: 144,
  },
  [theme.breakpoints.up("xl")]: {
    width: 144,
    maxHeight: 144,
  },
}));

export type MiniMapProps = {};

const MiniMap: React.FC<MiniMapProps> = _props => {
  const Store = useSystemDesignContext();
  const viewbox = Store.hooks.useViewbox();
  const componentType = Store.hooks.useComponentType();
  const elementsList = Store.hooks.useElementsList();

  return (
    <MiniMapBox>
      <StaticFlowChart
        elements={elementsList}
        componentType={componentType}
        viewarea={viewbox}
        disableZoom={true}
        disableGrid={true}
        simpleView={true}
      />
    </MiniMapBox>
  );
};

MiniMap.displayName = "MiniMap";

export default MiniMap;
