import { QUERY_PARAMS_FOR_DEFAULT_QUESTIONS } from "@hireroo/app-definition/question";
import { decompress } from "@hireroo/app-helper/lz-string";
import * as Graphql from "@hireroo/graphql/client/urql";
import { updateQueryParams } from "@hireroo/router/api";
import { type Fields, QuestionSelectFieldForResourceEditorSchema } from "@hireroo/validator";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

export const MAX_SELECTABLE_ENTITY_TRACK_COUNT = 10;

export const useRestoreCompositePrimaryKeysByQueryParams = (): QuestionSelectFieldForResourceEditorSchema.QuestionCompositePrimaryKeys => {
  const [searchParams] = useSearchParams();
  const questions = searchParams.get(QUERY_PARAMS_FOR_DEFAULT_QUESTIONS.key);
  const compositePrimaryKeys = React.useMemo((): QuestionSelectFieldForResourceEditorSchema.QuestionCompositePrimaryKeys => {
    if (questions === null) {
      return [];
    }
    try {
      const parsedQuestions = JSON.parse(decompress(questions));
      const parsedResult = QuestionSelectFieldForResourceEditorSchema.QuestionCompositePrimaryKeys.safeParse(parsedQuestions);
      if (parsedResult.success) {
        return parsedResult.data;
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  }, [questions]);

  React.useEffect(() => {
    updateQueryParams(QUERY_PARAMS_FOR_DEFAULT_QUESTIONS.key, []);
  }, []);

  return compositePrimaryKeys;
};

type EntitySource = Fields.EntitySource.EntitySource;

type EntityTrack = Fields.EntityTrack.EntityTrack;

export const convertEntitySourcesValidatorFromGraphql = (
  questions: Graphql.QuestionObjectForQuestionSelectFieldForResourceEditorFragment[],
  isAvailableQuestion: (variant: Graphql.QuestionVariant) => boolean,
): EntityTrack[] => {
  return questions.reduce<EntityTrack[]>((all, question) => {
    if (!question.spotQuestion) {
      return all;
    }
    const { spotQuestion } = question;
    switch (spotQuestion.__typename) {
      case "AlgorithmQuestion": {
        const entitySource: EntitySource = {
          type: "CHALLENGE",
          uniqueKey: spotQuestion.key,
          questionId: spotQuestion.questionId,
          questionVersion: spotQuestion.version,
          enabledLanguages: spotQuestion.supportedLanguages,
          isSelectable: isAvailableQuestion("CHALLENGE"),
        };
        const entityTrack: EntityTrack = {
          type: "FIXED",
          entitySource,
          questionScoreWeight: 1,
        };
        return all.concat(entityTrack);
      }
      case "MultiChoicePackage": {
        const entitySource: EntitySource = {
          type: "QUIZ",
          uniqueKey: spotQuestion.key,
          packageId: spotQuestion.packageId,
          packageVersion: spotQuestion.version,
          isSelectable: isAvailableQuestion("QUIZ"),
        };
        const entityTrack: EntityTrack = {
          type: "FIXED",
          entitySource,
          questionScoreWeight: 1,
        };
        return all.concat(entityTrack);
      }
      case "ProjectQuestion": {
        const entitySource: EntitySource = {
          type: "PROJECT",
          uniqueKey: spotQuestion.key,
          questionId: spotQuestion.questionId,
          questionVersion: spotQuestion.version,
          isSelectable: isAvailableQuestion("PROJECT"),
        };
        const entityTrack: EntityTrack = {
          type: "FIXED",
          entitySource,
          questionScoreWeight: 1,
        };
        return all.concat(entityTrack);
      }
      case "SystemDesignQuestion": {
        const entitySource: EntitySource = {
          type: "SYSTEM_DESIGN",
          uniqueKey: spotQuestion.key,
          questionId: spotQuestion.questionId,
          componentTypes: ["default", "gcp", "aws", "azure"],
          isSelectable: isAvailableQuestion("SYSTEM_DESIGN"),
        };
        const entityTrack: EntityTrack = {
          type: "FIXED",
          entitySource,
          questionScoreWeight: 1,
        };
        return all.concat(entityTrack);
      }
      default:
        throw new Error(`Question is unknown: ${spotQuestion satisfies never}`);
    }
  }, []);
};
