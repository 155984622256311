import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import Typography, { TypographyProps } from "@mui/material/Typography";
import * as React from "react";

type HeadingClass = "h5" | "h6" | "subtitle1" | "subtitle2";

const StyledHeading = styled(Typography)<{ ownerState: { variant: HeadingClass } }>(({ ownerState, theme }) => ({
  marginTop: ownerState.variant === "h5" || ownerState.variant === "h6" ? theme.spacing(4) : theme.spacing(2),
  color: theme.palette.text.primary,
  fontWeight: "bold",
}));

export type HeadingProps = TypographyProps & {
  level: number;
  children: React.ReactNode[];
};

const Heading: React.FC<HeadingProps> = props => {
  const { level } = props;
  let variant: HeadingClass;
  switch (level) {
    case 1:
      variant = "h5";
      break;
    case 2:
      variant = "h6";
      break;
    case 3:
      variant = "subtitle1";
      break;
    default:
      variant = "h6";
      break;
  }

  return (
    <>
      <StyledHeading gutterBottom variant={variant} ownerState={{ variant }}>
        {props.children}
      </StyledHeading>
      {variant === "h6" && <Divider />}
    </>
  );
};

Heading.displayName = "Heading";

export default Heading;
