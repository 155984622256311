import { Company } from "@hireroo/app-store/essential/employee";
import { QuizQuestionEditor } from "@hireroo/app-store/widget/e/QuizQuestionEditor";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import type * as Graphql from "@hireroo/graphql/client/graphql-request";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation/legacy";
import * as Sentry from "@sentry/browser";

export type GenerateQuizQuestionEditorPropsArgs = {};

type TranslateTarget = {
  text: string;
  setText: (text: string) => void;
};

const useTranslate = () => {
  const { t } = useTranslation();
  const client = getGraphqlClient();
  const translate = async (translateTexts: TranslateTarget[], from: Graphql.TranslatableLanguage, to: Graphql.TranslatableLanguage) => {
    const tasks = translateTexts.map<Promise<void>>(async target => {
      if (target.text === "") {
        return target.setText("");
      }
      return await client
        .TranslateMarkdownForQuizResourceEditor({
          source: {
            from: from,
            to: to,
            body: target.text,
          },
        })
        .then(res => {
          target.setText(res.translateMarkdown);
        })
        .catch(error => {
          Sentry.captureException(error);
          target.setText("");
        });
    });
    try {
      await Promise.allSettled(tasks);
      Snackbar.notify({
        severity: "success",
        message: t("自動翻訳に成功しました。"),
      });
    } catch {
      Snackbar.notify({
        severity: "error",
        message: t("自動翻訳に失敗しました。しばらくしてから再度お試しください。"),
      });
    }
  };
  return translate;
};

type MetricTypeFieldMenuItem = Widget.QuizQuestionEditorProps["metricTypeField"]["menuItems"][0];

const useAvailableMetricTypeFieldItems = (): MetricTypeFieldMenuItem[] => {
  const { t } = useTranslation();
  const itemMap: Record<Exclude<Graphql.MultiChoiceMetricType, "UNKNOWN">, MetricTypeFieldMenuItem> = {
    FRONTEND: { label: t("フロントエンド"), value: "FRONTEND" },
    BACKEND: { label: t("バックエンド"), value: "BACKEND" },
    MOBILE: { label: t("モバイル"), value: "MOBILE" },
    MACHINE_LEARNING: { label: "ML", value: "MACHINE_LEARNING" },
    INFRASTRUCTURE: { label: t("インフラ"), value: "INFRASTRUCTURE" },
    SECURITY: { label: t("セキュリティ"), value: "SECURITY" },
    COMPUTE_SCIENCE: { label: t("コンピューターサイエンス"), value: "COMPUTE_SCIENCE" },
    OPERATING_SYSTEM: { label: "OS", value: "OPERATING_SYSTEM" },
    PROJECT_MANAGEMENT: { label: t("プロジェクトマネジメント"), value: "PROJECT_MANAGEMENT" },
    OTHER: { label: t("その他"), value: "OTHER" },
  };
  return Object.values(itemMap);
};

export const useGenerateProps = (_args: GenerateQuizQuestionEditorPropsArgs): Widget.QuizQuestionEditorProps => {
  const { t } = useTranslation();
  const translate = useTranslate();
  const { method, addLanguage } = Widget.useQuizQuestionEditorContext();
  const question = QuizQuestionEditor.useQuestion();
  const selectedLanguages = method.watch("selectedLanguages");
  const hasJapanese = !!selectedLanguages.find(({ value }) => value === "ja");
  const hasEnglish = !!selectedLanguages.find(({ value }) => value === "en");
  const oneOfHas = hasJapanese || hasEnglish;
  const isHireroo = Company.useIsHireroo();
  const lang = useLanguageCode();
  const canShowAddJapaneseMenuItem: boolean = oneOfHas ? !hasJapanese : lang !== "ja";
  const canShowAddEnglishMenuItem: boolean = oneOfHas ? !hasEnglish : lang !== "en";
  const availableMetricTypeFieldItems = useAvailableMetricTypeFieldItems();
  return {
    packagesField: {
      menu: {
        items: [
          canShowAddJapaneseMenuItem && {
            text: "日本語",
            value: "ja",
            onClick: async () => {
              const translateTexts: TranslateTarget[] = [
                { text: method.getValues("questionDetails.en.title"), setText: text => method.setValue("questionDetails.ja.title", text) },
                { text: method.getValues("questionDetails.en.detail"), setText: text => method.setValue("questionDetails.ja.detail", text) },
                ...(method.getValues("multiChoiceQuestion.options") || []).reduce<TranslateTarget[]>((all, current, index) => {
                  return [
                    ...all,
                    {
                      text: current.content?.en?.title || "",
                      setText: text => method.setValue(`multiChoiceQuestion.options.${index}.content.ja.title`, text),
                    },
                    {
                      text: current.content?.en?.detail || "",
                      setText: text => method.setValue(`multiChoiceQuestion.options.${index}.content.ja.detail`, text),
                    },
                  ];
                }, []),
                ...(method.getValues("singleChoiceQuestions") || []).reduce<TranslateTarget[]>((all, current, index) => {
                  return [
                    ...all,
                    {
                      text: current.content.en?.title || "",
                      setText: text => method.setValue(`singleChoiceQuestions.${index}.content.ja.title`, text),
                    },
                    {
                      text: current.content.en?.additionalDetail || "",
                      setText: text => method.setValue(`singleChoiceQuestions.${index}.content.ja.additionalDetail`, text),
                    },
                  ];
                }, []),
                ...(method.getValues("evaluations") || []).reduce<TranslateTarget[]>((all, current, index) => {
                  return [
                    ...all,
                    {
                      text: current.content.en?.title || "",
                      setText: text => method.setValue(`evaluations.${index}.content.ja.title`, text),
                    },
                  ];
                }, []),
              ];
              await translate(translateTexts, "EN", "JA");
              addLanguage("ja");
            },
          },
          canShowAddEnglishMenuItem && {
            text: "English",
            value: "en",
            onClick: async () => {
              const translateTexts: TranslateTarget[] = [
                { text: method.getValues("questionDetails.ja.title"), setText: text => method.setValue("questionDetails.en.title", text) },
                { text: method.getValues("questionDetails.ja.detail"), setText: text => method.setValue("questionDetails.en.detail", text) },
                ...(method.getValues("multiChoiceQuestion.options") || []).reduce<TranslateTarget[]>((all, current, index) => {
                  return [
                    ...all,
                    {
                      text: current.content?.ja?.title || "",
                      setText: text => method.setValue(`multiChoiceQuestion.options.${index}.content.en.title`, text),
                    },
                    {
                      text: current.content?.ja?.detail || "",
                      setText: text => method.setValue(`multiChoiceQuestion.options.${index}.content.en.detail`, text),
                    },
                  ];
                }, []),
                ...(method.getValues("singleChoiceQuestions") || []).reduce<TranslateTarget[]>((all, current, index) => {
                  return [
                    ...all,
                    {
                      text: current.content.ja?.title || "",
                      setText: text => method.setValue(`singleChoiceQuestions.${index}.content.en.title`, text),
                    },
                    {
                      text: current.content.ja?.additionalDetail || "",
                      setText: text => method.setValue(`singleChoiceQuestions.${index}.content.en.additionalDetail`, text),
                    },
                  ];
                }, []),
                ...(method.getValues("evaluations") || []).reduce<TranslateTarget[]>((all, current, index) => {
                  return [
                    ...all,
                    {
                      text: current.content.ja?.title || "",
                      setText: text => method.setValue(`evaluations.${index}.content.en.title`, text),
                    },
                  ];
                }, []),
              ];

              await translate(translateTexts, "JA", "EN");
              addLanguage("en");
            },
          },
        ].flatMap(v => (v ? [v] : [])),
      },
    },
    editLanguageTab: {
      onRemoveLanguageTab: language => {
        method.setValue(`questionDetails.${language}`, undefined);
      },
    },
    publicSettingField: isHireroo ? {} : undefined,
    versioningField: question?.status === "PUBLISHED" ? {} : undefined,
    difficultyField: {
      menuItems: [
        {
          label: t("易しい"),
          value: "EASY",
        },
        {
          label: t("ふつう"),
          value: "MEDIUM",
        },
        {
          label: t("難しい"),
          value: "DIFFICULT",
        },
      ],
    },
    metricTypeField: {
      menuItems: availableMetricTypeFieldItems,
    },
    variantField: {
      menuItems: [
        {
          label: t("単数選択"),
          value: "SINGLE_CHOICE",
        },
        {
          label: t("複数選択"),
          value: "MULTI_CHOICE",
        },
        {
          label: t("自由記入"),
          value: "FREE_TEXT",
        },
      ],
    },
  };
};
