import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import * as React from "react";

import ExecutableTestCaseTableRow, { ExecutableTestCaseTableRowProps } from "./parts/ExecutableTestCaseTableRow";

export type ExecutableTestCaseTableProps = {
  rows: ExecutableTestCaseTableRowProps[];
};

const ExecutableTestCaseTable: React.FC<ExecutableTestCaseTableProps> = props => {
  return (
    <TableContainer>
      <Table aria-label="collapsible table">
        <TableBody>
          {props.rows.map((row, index) => (
            <ExecutableTestCaseTableRow key={`row-${index}`} {...row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ExecutableTestCaseTable.displayName = "ExecutableTestCaseTable";

export default ExecutableTestCaseTable;
