import { QuestionsStore } from "@hireroo/app-store/page/e/questions";
import { updateQueryParams } from "@hireroo/router/api";

import { filterToCondensedQuestionVariantGroups, SortFieldValueSortingMap } from "./privateHelper";

export const startSubscribe = () => {
  return QuestionsStore.subscribeAllState(state => {
    if (!state.currentSearchFilter) {
      return;
    }
    if (window.location.pathname.includes("/e/questions")) {
      updateQueryParams(
        QuestionsStore.QueryKeys.QUESTION_VARIANT,
        filterToCondensedQuestionVariantGroups(state.currentSearchFilter.questionVariants),
      );

      const titleValues: string[] = state.currentSearchFilter.textFilter.length > 0 ? [state.currentSearchFilter.textFilter] : [];
      updateQueryParams(QuestionsStore.QueryKeys.TITLE, titleValues);

      updateQueryParams(QuestionsStore.QueryKeys.STATUS, state.currentSearchFilter.statuses);
      updateQueryParams(QuestionsStore.QueryKeys.DIFFICULTY, state.currentSearchFilter.difficulties);
      updateQueryParams(
        QuestionsStore.QueryKeys.SKILL_TAG,
        state.currentSearchFilter.skillTags.map(skillTag => skillTag.value),
      );

      const sorting = SortFieldValueSortingMap[state.pager.sortFieldValue];
      const isSortingValid = sorting !== undefined && sorting !== "UNKNOWN-true" && sorting !== "UNKNOWN-false";
      updateQueryParams(QuestionsStore.QueryKeys.SORT, isSortingValid ? [sorting] : []);

      if (state.pager.page > 0) {
        updateQueryParams(QuestionsStore.QueryKeys.PAGE, [(state.pager.page + 1).toString()]);
      } else {
        // Set empty if it is not a page change
        updateQueryParams(QuestionsStore.QueryKeys.PAGE, []);
      }
      if (state.pager.size !== QuestionsStore.DefaultPager.size) {
        updateQueryParams(QuestionsStore.QueryKeys.SIZE, [state.pager.size.toString()]);
      } else {
        updateQueryParams(QuestionsStore.QueryKeys.SIZE, []);
      }
    }
  });
};
