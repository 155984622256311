import { useTranslation } from "@hireroo/i18n";
import { ProjectTextInputDialogForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import Button, { ButtonProps } from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import InputControlTextField, { InputControlTextFieldProps } from "../../../primitive/InputControl/InputControlTextField/InputControlTextField";

export type TextDialogProps = {
  existsFiles: string[];
  open: boolean;
  label: string;
  onClose: DialogProps["onClose"];
  cancelButton: Omit<ButtonProps, "children">;
  onSubmit: SubmitHandler<ProjectTextInputDialogForm.ProjectTextInputDialogFormSchema>;
};

const TextDialog: React.FC<TextDialogProps> = props => {
  const { t } = useTranslation();
  const validateSchema = ProjectTextInputDialogForm.useProjectTextInputDialogForm({
    existsList: props.existsFiles,
  });
  const method = useForm({
    resolver: zodResolver(validateSchema),
    defaultValues: {
      value: "",
    },
  });
  const dialogProps: DialogProps = {
    open: props.open,
    onClose: props.onClose,
    onKeyDown: e => {
      if (e.key === "Enter") {
        e.preventDefault();
        method.handleSubmit(fields => {
          props.onSubmit(fields);
          method.reset();
        })();
      }
    },
  };
  const inputControlTextFieldProps: InputControlTextFieldProps = {
    label: props.label,
    autoFocus: true,
    InputLabelProps: {
      shrink: true,
    },
  };
  const acceptButtonProps: ButtonProps = {
    children: t("作成"),
    fullWidth: true,
    variant: "contained",
    color: "secondary",
    onClick: () => {
      method.handleSubmit(fields => {
        props.onSubmit(fields);
        method.reset();
      })();
    },
  };
  const cancelButtonProps: ButtonProps = {
    ...props.cancelButton,
    fullWidth: true,
    color: "secondary",
  };
  React.useEffect(() => {
    return () => {
      method.clearErrors();
    };
  });
  return (
    <Dialog {...dialogProps}>
      <DialogContent>
        <FormProvider {...method}>
          <InputControlTextField {...inputControlTextFieldProps} name="value" />
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button {...cancelButtonProps} children={t("キャンセル")} />
        <Button {...acceptButtonProps} />
      </DialogActions>
    </Dialog>
  );
};

TextDialog.displayName = "TextDialog";

export default TextDialog;
