import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateFillTemplateDialogPropsArgs, useGenerateProps } from "./useGenerateProps";

export type FillTemplateDialogContainerProps = GenerateFillTemplateDialogPropsArgs;

const FillTemplateDialogContainer: React.FC<FillTemplateDialogContainerProps> = props => {
  const fillTemplateDialogProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.FillTemplateDialog {...fillTemplateDialogProps} />
    </ErrorBoundary>
  );
};

FillTemplateDialogContainer.displayName = "FillTemplateDialogContainer";

export default withErrorBoundary(FillTemplateDialogContainer, {});
