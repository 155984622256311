import { proxy } from "valtio";
import { proxySet } from "valtio/utils";

import * as Def from "./definitions";
import type * as Types from "./types";

export const state = proxy<Types.State>({
  res: null,
  currentSearchFilter: null,
  pager: Def.DefaultPager,
  deletedAssessmentIdSet: proxySet(),
});
