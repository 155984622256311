import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import AddMembersToGroups, { AddMembersToGroupsProps } from "./parts/AddMembersToGroups/AddMembersToGroups";
import DeleteMembers, { DeleteMembersProps } from "./parts/DeleteMembers/DeleteMembers";
import MemberHeader, { MemberHeaderProps } from "./parts/MemberHeader/MemberHeader";
import MemberTable, { MemberTableProps } from "./parts/MemberTable/MemberTable";

export type EmployeeMemberSettingsProps = {
  title: string;
  memberTable: MemberTableProps;
  header: MemberHeaderProps;
  deleteMembers: DeleteMembersProps;
  addMembersToGroups?: AddMembersToGroupsProps;
};

const EmployeeMemberSettings: React.FC<EmployeeMemberSettingsProps> = props => {
  return (
    <Stack direction="column" spacing={1.5}>
      <Typography fontSize={14} fontWeight={700}>
        {props.title}
      </Typography>
      <MemberHeader {...props.header} />
      <MemberTable {...props.memberTable} />
      <DeleteMembers {...props.deleteMembers} />
      {props.addMembersToGroups && <AddMembersToGroups {...props.addMembersToGroups} />}
    </Stack>
  );
};

EmployeeMemberSettings.displayName = "EmployeeMemberSettings";

export default EmployeeMemberSettings;
