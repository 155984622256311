import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";

import ResourceField from "../../../../../../../../modules/ResourceFieldV2/ResourceFieldV2";
import InputControlTextField, {
  InputControlTextFieldProps,
} from "../../../../../../../../primitive/InputControl/InputControlTextField/InputControlTextField";

const StyledStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: "8px",
}));

export type StringCustomFieldProps = {
  isRequired: boolean;
  label: string;
  name: string;
  description?: string;
  helperText: string | undefined;
  formType: "TEXTAREA" | "INPUT";
};

const StringCustomField: React.FC<StringCustomFieldProps> = props => {
  const textareaProps: InputControlTextFieldProps = {
    multiline: true,
    minRows: 3,
  };

  const inputProps: InputControlTextFieldProps = {
    ...(props.formType === "TEXTAREA" ? textareaProps : {}),
    fullWidth: true,
    variant: "outlined",
    type: "text",
    size: "small",
    helperText: props.helperText,
  };

  return (
    <StyledStack>
      <ResourceField
        kind={props.isRequired ? "REQUIRED" : "OPTIONAL"}
        label={props.label}
        help={{ kind: "DESCRIPTION", text: props.description }}
      >
        <InputControlTextField name={props.name} {...inputProps} />
      </ResourceField>
    </StyledStack>
  );
};

StringCustomField.displayName = "StringCustomField";

export default StringCustomField;
