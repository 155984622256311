import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import NativeSelect, { type NativeSelectProps } from "@mui/material/NativeSelect";
import { styled } from "@mui/material/styles";
import * as React from "react";

export const FONT_FAMILY_SET = [
  "-apple-system",
  "BlinkMacSystemFont",
  "Segoe UI",
  "Roboto",
  "Helvetica Neue",
  "Arial",
  "sans-serif",
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
];

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  borderRadius: 4,
  position: "relative",
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.paper,
  height: "2.4em",
  fontSize: 13,
  padding: "10px 0px 10px 12px",
  transition: theme.transitions.create(["border-color", "box-shadow"]),
  // Use the system font instead of the default Roboto font.
  fontFamily: FONT_FAMILY_SET.join(","),
  "&:focus": {
    borderRadius: 4,
    borderColor: "#80bdff",
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
  },
}));

type Item = {
  title: string;
  value: string;
};

export type EditorToolbarSelectProps = {
  defaultValue: string;
  onChange?: (values: string) => void;
  items: Item[];
};

const EditorToolbarSelect: React.FC<EditorToolbarSelectProps> = props => {
  const nativeSelectProps: NativeSelectProps = {
    ...props,
    onChange: React.useCallback(
      (event: React.ChangeEvent<HTMLSelectElement>) => {
        props.onChange?.(event.target.value);
      },
      [props],
    ),
    defaultValue: props.defaultValue,
    input: <BootstrapInput />,
    sx: {
      bgcolor: "inherit",
    },
  };

  return (
    <FormControl>
      <NativeSelect {...nativeSelectProps}>
        {props.items.map((item, index) => (
          <option key={`${item.value}-${index}`} value={item.value}>
            {item.title}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );
};

EditorToolbarSelect.displayName = "EditorToolbarSelect";

export default EditorToolbarSelect;
