import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Company, Role } from "@hireroo/app-store/essential/employee";
import { IntegrationsSettings } from "@hireroo/app-store/widget/e/IntegrationsSettings";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { unixToDateFormat } from "@hireroo/formatter/time";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { useHelpCenterNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/react";
import * as React from "react";

import ScreeningMenuListFetchContainer from "./widget/IntegrationScreeningMenuList/FetchContainer";

export type GenerateIntegrationsSettingsPropsArgs = {
  refresh: () => void;
};

export const useGenerateProps = (args: GenerateIntegrationsSettingsPropsArgs): Widget.SonarAtsSettingsProps => {
  const { t } = useTranslation();
  const companyId = Company.useStrictActiveCompanyId();
  const registerSonarState = IntegrationsSettings.useRegisterSonar();
  const sonarIntegrations = IntegrationsSettings.useCurrentPageSonarIntegrationsV2();
  const client = getGraphqlClient();
  const sonarPagination = IntegrationsSettings.useSonarPagination();
  const languageCode = useLanguageCode();
  const helpCenterNavigate = useHelpCenterNavigate(languageCode);
  const matchingRole = Role.useMatchingRole();
  const [forceClose, setForceClose] = React.useState(false);
  const [createDialogOpen, setCreateDialogOpen] = React.useState(false);

  const sonarIntegrationTableRows = React.useMemo((): Widget.SonarAtsSettingsProps["table"]["items"] => {
    return sonarIntegrations.map(sonar => {
      return {
        sonarIntegrationId: sonar.id,
        title: sonar.screening?.name ?? "",
        createdAt: unixToDateFormat(sonar.createdAtSeconds ?? 0),
        deleteButton: {
          disabled: !matchingRole.ADMIN_OR_MANAGER.matched,
          title: matchingRole.ADMIN_OR_MANAGER.messageOnUnmatched,
          onClick: () => {
            IntegrationsSettings.updateDeleteSonar({ selectedSonarIntegrationId: sonar.id });
          },
        },
        copyButton: {
          onCopy: () => {
            Snackbar.notify({
              severity: "info",
              message: t("クリップボードにコピーされました"),
            });
          },
        },
        onDelete: async () => {
          await client
            .DeleteSonar({
              id: sonar.id,
            })
            .then(() => {
              IntegrationsSettings.removeSonarIntegration(sonar.id);
              Snackbar.notify({
                severity: "success",
                message: t("sonar ATSトークンの削除に成功しました。"),
              });
              args.refresh();
            })
            .catch(error => {
              Sentry.captureException(error);
              const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("sonar ATSトークンの削除に失敗しました。"));
              Snackbar.notify({
                severity: "error",
                message: errorNotification.message,
              });
            });
        },
        loading: false,
      };
    });
  }, [args, client, matchingRole.ADMIN_OR_MANAGER.matched, matchingRole.ADMIN_OR_MANAGER.messageOnUnmatched, sonarIntegrations, t]);
  return {
    batchMenu: {
      generateButton: {
        disabled: !matchingRole.ADMIN_OR_MANAGER.matched,
        title: matchingRole.ADMIN_OR_MANAGER.messageOnUnmatched,
        onClick: () => {
          setForceClose(false);
        },
      },
      BatchList: (
        <ScreeningMenuListFetchContainer
          onClick={() => {
            setCreateDialogOpen(true);
            setForceClose(true);
          }}
        />
      ),
      forceClose: forceClose,
    },
    helpCenterLink: {
      onClick: () => {
        helpCenterNavigate("ATS_SONAR", { _blank: true });
      },
    },
    table: {
      items: sonarIntegrationTableRows,
      pagination: {
        count: sonarPagination.count,
        page: sonarPagination.page,
        rowsPerPage: sonarPagination.size,
        onRowsPerPageChange: e => {
          IntegrationsSettings.updateSonarPagination({ size: parseInt(e.target.value, 10), page: 0, cursor: null });
        },
        onPageChange: (_, page) => {
          IntegrationsSettings.updateSonarPaginationPage(page);
        },
      },
    },
    createDialog: {
      open: createDialogOpen,
      batch: {
        title: registerSonarState.selectedScreening?.name ?? "",
        description: registerSonarState.selectedScreening?.description,
      },
      yesButton: {
        onClick: async () => {
          if (registerSonarState.selectedScreening?.screeningId === undefined) {
            Sentry.captureMessage("Failed to create ats token. registerSonarState.selectedBatch is undefined");
            Snackbar.notify({
              severity: "error",
              message: t("sonar ATSトークンの生成に失敗しました。"),
            });
            return;
          }

          await client
            .RegisterSonar({
              input: {
                companyId: companyId,
                screeningId: registerSonarState.selectedScreening?.screeningId ?? "",
              },
            })
            .then(res => {
              if (res.registerSonar) {
                args.refresh();
                setCreateDialogOpen(false);
              }
              Snackbar.notify({
                severity: "success",
                message: t("sonar ATSトークンの生成に成功しました。"),
              });
            })
            .catch(error => {
              Sentry.captureException(error);
              const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("sonar ATSトークンの生成に失敗しました。"));
              Snackbar.notify({
                severity: "error",
                message: errorNotification.message,
              });
            });
        },
      },
      noButton: {
        onClick: () => {
          setCreateDialogOpen(false);
        },
      },
    },
  };
};
