import { useTranslation } from "@hireroo/i18n";
import Markdown from "@hireroo/markdown-v2/react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import RateChip from "../../../../MultiChoice/RateChip/RateChip";

export type SubmissionAccordionQuestionContentProps = {
  description: string;
  avgCorrectScore?: number;
};

const SubmissionAccordionQuestionContent: React.FC<SubmissionAccordionQuestionContentProps> = props => {
  const { t } = useTranslation();

  return (
    <Stack direction="column">
      <Stack direction="row" display="flex" justifyContent="space-between">
        <Typography>{t("問題")}</Typography>
        {!!props.avgCorrectScore && <RateChip title={t("平均スコア")} integerValue={props.avgCorrectScore} />}
      </Stack>
      <React.Suspense>
        <Markdown size="small" children={props.description} />
      </React.Suspense>
    </Stack>
  );
};

SubmissionAccordionQuestionContent.displayName = "SubmissionAccordionQuestionContent";

export default SubmissionAccordionQuestionContent;
