import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import NativeSelect, { NativeSelectProps } from "@mui/material/NativeSelect";
import { styled } from "@mui/material/styles";
import * as React from "react";

import { CellType } from "../../../api";
import { useJupyterContext } from "../../../store";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  borderRadius: 4,
  position: "relative",
  color: theme.palette.text.primary,
  backgroundColor: "inherit",
  height: "2.4em",
  padding: "10px 0px 10px 12px",
  transition: theme.transitions.create(["border-color", "box-shadow"]),
  fontSize: "var(--jp-ui-font-size1)",
  fontFamily: "var(--jp-ui-font-family)",
}));

const validCellTypes: CellType[] = ["-", "code", "markdown", "raw"];

export type CellTypeSelectorProps = {};

/**
 * @see https://github.com/jupyterlab/jupyterlab/blob/main/packages/notebook/src/default-toolbar.tsx#L316
 */
const CellTypeSelector: React.FC<CellTypeSelectorProps> = () => {
  const { adapter } = useJupyterContext();
  const getCurrentCellType = React.useCallback((): CellType => {
    const type = adapter?.notebook?.activeCell?.model.type;
    if (type && validCellTypes.includes(type)) {
      return type;
    }
    return "-";
  }, [adapter?.notebook?.activeCell?.model.type]);

  const [value, setValue] = React.useState(getCurrentCellType());

  const update = React.useCallback(() => {
    setValue(getCurrentCellType());
  }, [getCurrentCellType]);

  const selectProps: NativeSelectProps = {
    onChange: event => {
      const cellType = event.target.value;
      if (validCellTypes.includes(cellType)) {
        adapter?.changeCellType(event.target.value);
        setValue(cellType);
      }
    },
    input: <BootstrapInput />,
    value,
    color: "outline-only",
    IconComponent: KeyboardArrowDownOutlinedIcon,
  };

  React.useEffect(() => {
    if (!adapter) {
      return;
    }
    let initialValue: CellType = "-";
    if (adapter.notebook?.activeCell) {
      initialValue = adapter.notebook.activeCell.model.type;
    }
    const widgets = adapter?.notebook?.widgets || [];
    for (const widget of widgets) {
      if (adapter.isSelectedOrActive(widget)) {
        if (widget.model.type !== initialValue) {
          initialValue = "-";
          break;
        }
      }
    }
    setValue(initialValue);
  }, [adapter, update]);

  React.useEffect(() => {
    adapter?.notebook?.selectionChanged.connect(update);
    adapter?.notebook?.activeCellChanged.connect(update);
    return () => {
      adapter?.notebook?.selectionChanged.disconnect(update);
      adapter?.notebook?.activeCellChanged.disconnect(update);
    };
  }, [adapter, update]);

  return (
    <FormControl>
      <NativeSelect {...selectProps}>
        {validCellTypes.map(cellType => (
          <option key={cellType} value={cellType}>
            {cellType}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );
};

CellTypeSelector.displayName = "CellTypeSelector";

export default CellTypeSelector;
