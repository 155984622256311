import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  height: "24px",
  width: "24px",
  margin: "0 auto",
  "&.MuiChip-avatarColorSecondary": {
    backgroundColor: theme.palette.action.active,
    color: theme.palette.action.active,
  },
}));

export type AssignProps = {
  creator: {
    label: string;
    src?: string;
  };
};

const Assign: React.FC<AssignProps> = props => {
  return (
    <Box>
      <Tooltip title={props.creator.label}>
        <StyledAvatar src={props.creator.src} />
      </Tooltip>
    </Box>
  );
};

Assign.displayName = "Assign";

export default Assign;
