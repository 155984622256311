import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

const Wrapper = styled(Box)(({ theme }) => ({
  height: "8px",
  backgroundColor: theme.palette.Other.Divider,
  marginBottom: theme.spacing(1.25),
}));

const ActiveBar = styled(Box)(({ theme }) => ({
  height: "8px",
  position: "absolute",
  backgroundColor: theme.palette.secondary.light,
  borderLeft: `1px solid ${theme.palette.secondary.main}`,
  borderRight: `1px solid ${theme.palette.secondary.main}`,
}));

const StyledTimeLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
  fontSize: "10px",
}));

type ActiveRange = {
  start: `${string}%`;
  width: `${string}%`;
  display: string;
};

export type TimeTrackerReportProps = {
  activeRanges: ActiveRange[];
  timeLabels: string[];
};

const TimeTrackerReport: React.FC<TimeTrackerReportProps> = props => {
  const { activeRanges, timeLabels } = props;
  return (
    <Box sx={{ position: "relative" }}>
      <Wrapper>
        {activeRanges.map((range, index) => (
          <Tooltip title={range.display} key={`${range.display}-${index}`}>
            <ActiveBar sx={{ left: range.start, width: range.width }} />
          </Tooltip>
        ))}
      </Wrapper>
      <Box display="flex" justifyContent="space-between">
        {timeLabels.map((timeLabel, index) => (
          <StyledTimeLabel key={`${timeLabel}-${index}`}>{timeLabel}</StyledTimeLabel>
        ))}
      </Box>
    </Box>
  );
};

TimeTrackerReport.displayName = "TimeTrackerReport";

export default TimeTrackerReport;
