import { useSnapshot } from "valtio";

import { state } from "./State";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useActiveStep = () => {
  const snapshot = useSnapshotState();
  return snapshot.activeStep;
};
export const useVariant = () => {
  const snapshot = useSnapshotState();
  return snapshot.variant;
};

export const useQuestion = () => {
  const snapshot = useSnapshotState();
  return snapshot.question;
};

export const useQuestionInitialize = () => {
  const question = useQuestion();
  return !!question;
};

export const useRunCodeOutput = () => {
  const snapshot = useSnapshotState();
  return snapshot.runCodeOutput;
};

export const useIsRunCodeLoading = () => {
  const snapshot = useSnapshotState();
  return snapshot.isRunCodeLoading;
};

export const useProfileCodeOutput = () => {
  const snapshot = useSnapshotState();
  return snapshot.profileCodeOutput;
};
export const useIsProfileCodeLoading = () => {
  const snapshot = useSnapshotState();
  return snapshot.isProfileCodeLoading;
};

export const useSaveStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.saveStatus;
};

export const useInitialLanguage = () => {
  const snapshot = useSnapshotState();
  return snapshot.initialLanguage;
};
