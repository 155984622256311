import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type MonacoEditorPlaygroundContainerProps = {};

const MonacoEditorPlaygroundContainer: React.FC<MonacoEditorPlaygroundContainerProps> = () => {
  const monacoEditorPlaygroundProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Pages.MonacoEditorPlayground {...monacoEditorPlaygroundProps} />
    </ErrorBoundary>
  );
};

MonacoEditorPlaygroundContainer.displayName = "MonacoEditorPlaygroundContainer";

export default withErrorBoundary(MonacoEditorPlaygroundContainer, {});
