import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { NetworkElement, NodeElement, OPERATION_TYPE, OperationType, UnionSettingsFields } from "../../../../../features";

export type DefaultSettingsFormProps = {
  element: NodeElement | NetworkElement;
  updateSettings: (id: string, updates: UnionSettingsFields, operationType: OperationType) => void;
};

const StyledHeading = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  color: "text.secondary",
}));

export const DefaultSettingsForm: React.FC<DefaultSettingsFormProps> = (props: DefaultSettingsFormProps) => {
  const { t } = useTranslation();
  const [name, setName] = useState<string>(props.element.settings.name);
  const nameRef = useRef<string>();
  const [ready, setReady] = useState<boolean>(false);

  const handleSaveName = useCallback(() => {
    if (name !== props.element.settings.name) {
      props.updateSettings(props.element.id, { name }, OPERATION_TYPE.do);
    }
  }, [name, props]);

  const handleChangeName = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setName(e.target.value);
  };

  // We use the same form when the element passed by props has changed, hence update the initial form value
  useEffect(() => {
    setName(props.element.settings.name);
    nameRef.current = props.element.settings.name;
    setReady(true);
  }, [props.element]);

  // Sync state and ref
  useEffect(() => {
    nameRef.current = name;
  }, [name]);

  // Save the element name when settings popup is unmounted
  useEffect(() => {
    return () => {
      if (ready && nameRef.current !== props.element.settings.name) {
        // DO NOT use name state. If we add name state to deps, infinite loop happens.
        // Use name ref instead, since changes in ref can always be tracked without deps.
        props.updateSettings(props.element.id, { name: nameRef.current }, OPERATION_TYPE.do);
      }
    };
  }, [props, ready]);

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <StyledHeading>{t("名前")}</StyledHeading>
      {ready && <TextField color="secondary" fullWidth size={"small"} value={name} onChange={handleChangeName} onBlur={handleSaveName} />}
    </Box>
  );
};

DefaultSettingsForm.displayName = "DefaultSettingsForm";

export default DefaultSettingsForm;
