import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import * as React from "react";

import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";
import ScoreBoardDetailItem, { ScoreBoardDetailItemProps } from "../ScoreBoardDetailItem/ScoreBoardDetailItem";

const StyledButton = styled(Button)(() => ({
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
}));

export type DetailSelectorDialogProps = {
  items: ScoreBoardDetailItemProps[];
  forceClose?: boolean;
  onClose?: () => void;
};

const DetailSelectorDialog: React.FC<DetailSelectorDialogProps> = props => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const { onClose } = props;
  React.useEffect(() => {
    if (props.forceClose) {
      setOpen(false);
      onClose?.();
    }
  }, [onClose, props.forceClose]);

  const dialog: DialogWithCloseIconProps = {
    open,
    onClose: () => {
      setOpen(false);
      onClose?.();
    },
    optionalDialog: {
      PaperProps: {
        sx: {
          width: "900px",
          maxWidth: "900px",
        },
      },
    },
    title: t("問題別スコア"),
  };
  return (
    <Box>
      <StyledButton onClick={() => setOpen(true)} color="secondary" variant="outlined">
        {t("スコア詳細を見る")}
      </StyledButton>
      <DialogWithCloseIcon {...dialog}>
        <Grid container spacing={1}>
          {props.items.map(item => (
            <Grid item xs={6} key={item.id}>
              <ScoreBoardDetailItem {...item} />
            </Grid>
          ))}
        </Grid>
      </DialogWithCloseIcon>
    </Box>
  );
};

DetailSelectorDialog.displayName = "DetailSelectorDialog";

export default DetailSelectorDialog;
