import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";

type Kind = "PLAYBACK" | "STATISTICS";
export type GenerateUpsellLockButtonDialogPropsArgs = {
  kind: Kind;
};

export const useGenerateProps = (args: GenerateUpsellLockButtonDialogPropsArgs): Widget.UpsellLockButtonDialogProps => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const buttonMap = {
    PLAYBACK: t("プレイバック"),
    STATISTICS: t("相対評価"),
  } as const satisfies Record<Kind, string>;
  const titleMap = {
    PLAYBACK: t2("restrictFeature", { feature: t("プレイバック") }),
    STATISTICS: t2("restrictFeature", { feature: t("相対評価") }),
  } as const satisfies Record<Kind, string>;
  const descriptionMap = {
    PLAYBACK: t(
      "プレイバックとは試験実施後に受験者のコーディング過程を再生する機能です。提出したコードの入力過程が確認でき、受験者の思考過程の分析やコピペ対策ができます。",
    ),
    STATISTICS: t("問題の難易度や点数を加味して、他社を含めた他の候補と実力を比較する機能です。"),
  } as const satisfies Record<Kind, string>;
  return {
    button: {
      onClick: () => {},
      children: buttonMap[args.kind],
    },
    title: titleMap[args.kind],
    onClose: () => {},
    Content: <>{descriptionMap[args.kind]}</>,
  };
};
