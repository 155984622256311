import { useTranslation } from "@hireroo/i18n";
import { QuestionTableForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";

import QuestionTableRow, { QuestionTableRowProps } from "../QuestionTableRow/QuestionTableRow";

const StyledCheckbox = styled(Checkbox)(() => ({
  width: "38px",
  height: "38px",
  padding: "9px",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderColor: theme.palette["Gray/Shades"].p8,
  "&.MuiTableCell-root": {
    height: "40px",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  border: "1px solid",
  borderColor: theme.palette["Gray/Shades"].p8,
  borderRadius: "8px",
  height: "100%",
}));
const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: "14px",
  wordBreak: "keep-all",
}));

const StyledTableBody = styled(TableBody)`
  height: 100%;
`;

const StyledTable = styled(Table)(() => ({
  height: "fit-content",
}));

const SIZE = 10;

export type QuestionTableProps = {
  items: Omit<QuestionTableRowProps, "name">[];
  showLeakScore: boolean;
  onChange?: (fields: QuestionTableForm.QuestionTableForm) => void;
};

const QuestionTable: React.FC<QuestionTableProps> = props => {
  const { onChange } = props;
  const { t } = useTranslation();
  const validateSchema = QuestionTableForm.useQuestionTableForm();
  const defaultValues = React.useMemo((): QuestionTableForm.QuestionTableForm => {
    return {
      items: props.items.map((item): QuestionTableForm.QuestionTableItem => {
        return {
          itemId: item.id,
          /**
           * default selected is true when it is not locked
           */
          selected: item.mode.kind !== "LOCKED",
          locked: item.mode.kind === "LOCKED",
        };
      }),
    };
  }, [props.items]);

  const methods = useForm<QuestionTableForm.QuestionTableForm>({
    resolver: zodResolver(validateSchema),
    defaultValues: defaultValues,
  });

  React.useEffect(() => {
    const subscription = methods.watch(() => {
      const fields = methods.getValues();
      onChange?.(fields);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [methods, onChange]);

  const isAllSelected = methods.watch("items").every(item => item.selected || (item.locked && !item.selected));

  const checkboxProps: CheckboxProps = {
    checked: isAllSelected,
    color: "secondary",
    size: "small",
    onClick: event => {
      event.stopPropagation();
      const newFieldValue = methods.getValues().items.map(item => {
        return {
          itemId: item.itemId,
          selected: item.locked ? false : !isAllSelected,
          locked: item.locked,
        };
      });
      methods.reset({ items: newFieldValue });
    },
  };

  return (
    <FormProvider {...methods}>
      <StyledTableContainer>
        <StyledTable>
          <TableHead sx={{ border: "unset" }}>
            <TableRow key="header">
              <StyledTableCell>
                <StyledCheckbox {...checkboxProps} />
              </StyledTableCell>
              <StyledTableCell width={`${100 - SIZE * 4}%`} />
              {props.showLeakScore && (
                <StyledTableCell width={`${SIZE}%`} align="center">
                  <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                    <StyledTypography>{t("リークスコア")}</StyledTypography>
                  </Stack>
                </StyledTableCell>
              )}
              <StyledTableCell width={`${SIZE}%`} align="center">
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                  <StyledTypography>{t("平均解答時間")}</StyledTypography>
                </Stack>
              </StyledTableCell>

              <StyledTableCell width={`${SIZE}%`} align="center">
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                  <StyledTypography>{t("平均スコア")}</StyledTypography>
                </Stack>
              </StyledTableCell>
              <StyledTableCell width={`${SIZE}%`} align="center">
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                  <StyledTypography>{t("使用回数")}</StyledTypography>
                </Stack>
              </StyledTableCell>
              <StyledTableCell />
            </TableRow>
          </TableHead>
          <StyledTableBody>
            {props.items.map((row, index) => (
              <QuestionTableRow key={row.id} {...row} name={`items.${index}`} />
            ))}
          </StyledTableBody>
        </StyledTable>
      </StyledTableContainer>
    </FormProvider>
  );
};

QuestionTable.displayName = "QuestionTable";

export default QuestionTable;
