import { TextFieldProps } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import * as React from "react";

import CopyToClipboardButton, { CopyToClipboardButtonProps } from "../../../../primitive/Button/CopyToClipboardButton/CopyToClipboardButton";

export type CopyLinkFieldProps = {
  textField: Pick<TextFieldProps, "value">;
  copyToClipboardButton: CopyToClipboardButtonProps;
};

const CopyLinkField: React.FC<CopyLinkFieldProps> = props => {
  const textFieldProps: TextFieldProps = {
    variant: "outlined",
    disabled: true,
    type: "text",
    margin: "none",
    fullWidth: true,
    InputProps: {
      readOnly: true,
    },
    size: "small",
    ...props.textField,
  };

  return (
    <Box display={"flex"} alignItems={"center"} mt={1}>
      <TextField {...textFieldProps} />
      <Box width={120} ml={2}>
        <CopyToClipboardButton {...props.copyToClipboardButton} />
      </Box>
    </Box>
  );
};

CopyLinkField.displayName = "CopyLinkField";

export default CopyLinkField;
