import { initializeApplication } from "@hireroo/initializer/app";
import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";

const main = async () => {
  await initializeApplication();
  ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(<App />);
};

main().catch(error => {
  console.error(error);
});
