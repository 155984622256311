import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import * as React from "react";

import { type FileNode, findNode } from "../../../../helpers/fileTree";
import Tab from "../../primitive/Tab/Tab";
import Tabs, { TabsProps } from "../../primitive/Tabs/Tabs";
import TabLabel from "./parts/TabLabel/TabLabel";
import TabScrollButton from "./parts/TabScrollButton/TabScrollButton";

export type ProjectEditorToolBarProps = {
  fileTree: FileNode;
  selectedFile?: string;
  openedFiles: string[];
  onSelectFile?: (file: string) => void;
  onCloseFile?: (file: string) => void;
};

const getFileName = (openedFiles: ProjectEditorToolBarProps["openedFiles"], node: FileNode | null) => {
  if (!node) {
    return null;
  }
  for (let i = 0; i < openedFiles.length; i++) {
    if (openedFiles[i] === node.id) {
      continue;
    }
    if (openedFiles[i].indexOf(node.name) !== -1) {
      // Get parent dir name
      const splited = node.id.split("/");
      const lastIndex = splited.lastIndexOf(node.name);
      // TODO: When same parent name same file name
      return `${node.name} .../${splited[lastIndex - 1]}`;
    }
  }

  return node.name;
};

const HEIGHT = 36;

const ProjectEditorToolBar: React.FC<ProjectEditorToolBarProps> = props => {
  const theme = useTheme();
  const handleTabsChange = (_event: React.SyntheticEvent, value: string) => {
    props.onSelectFile?.(value);
  };
  const tabsProps: TabsProps = {
    variant: "scrollable",
    scrollButtons: "auto",
    value: props.selectedFile,
    onChange: handleTabsChange,
    style: { padding: 0 },
    ScrollButtonComponent: TabScrollButton,
    TabScrollButtonProps: {
      id: `project-editor-tool-bar-tab`,
      sx: {
        height: HEIGHT,
      },
    },
  };
  return (
    <AppBar position={"static"} style={{ height: HEIGHT }} color="default">
      <Box width={"100%"} height={"100%"}>
        <Box
          display={"flex"}
          height={"100%"}
          bgcolor={theme.palette["Secondary/Shades"].p12}
          flexDirection={"column"}
          justifyContent={"center"}
        >
          {props.selectedFile && (
            <Tabs {...tabsProps}>
              {props.openedFiles.map((sourceFile: string, id: number) => {
                const node = findNode(props.fileTree, sourceFile);
                return (
                  <Tab
                    label={
                      <TabLabel
                        name={getFileName(props.openedFiles, node) ?? ""}
                        onClose={() => {
                          props.onCloseFile?.(sourceFile);
                        }}
                      />
                    }
                    value={sourceFile}
                    key={`${sourceFile}-${id}`}
                    style={{ padding: 0, height: HEIGHT, maxWidth: "none" }}
                    sx={{ textTransform: "none", minWidth: 150 }}
                  />
                );
              })}
            </Tabs>
          )}
        </Box>
      </Box>
    </AppBar>
  );
};

ProjectEditorToolBar.displayName = "ProjectEditorToolBar";

export default ProjectEditorToolBar;
