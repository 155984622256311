import { LiveCodingChatInputFormSchema } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import * as React from "react";
import { useForm, UseFormReturn } from "react-hook-form";

export type ContextValue = {
  methods: UseFormReturn<LiveCodingChatInputFormSchema.LiveCodingChatInputFormSchema>;
};

export const LiveCodingChatWindowContext = React.createContext<ContextValue>({} as ContextValue);

export const useLiveCodingChatWindowContext = () => React.useContext(LiveCodingChatWindowContext);

export type LiveCodingChatWindowProviderProps = {};

export const LiveCodingChatWindowProvider: React.FC<React.PropsWithChildren<LiveCodingChatWindowProviderProps>> = props => {
  const validateSchema = LiveCodingChatInputFormSchema.useLiveCodingChatFormSchema();
  const methods = useForm<LiveCodingChatInputFormSchema.LiveCodingChatInputFormSchema>({
    defaultValues: { message: "" },
    resolver: zodResolver(validateSchema),
    mode: "onChange",
  });

  const contextValue: ContextValue = {
    methods,
  };
  return <LiveCodingChatWindowContext.Provider value={contextValue} children={props.children} />;
};
