import type { Widget } from "@hireroo/presentation";

export type GenerateCandidateFooterPropsArgs = {};

export const useGenerateProps = (_args: GenerateCandidateFooterPropsArgs): Widget.CandidateFooterProps => {
  return {
    tosUrl: "https://support.hireroo.io/recruiters/terms-of-use",
    helpCenterUrl: "https://support.hireroo.io/%E5%8F%97%E9%A8%93%E8%80%85%E5%90%91%E3%81%91%E3%83%98%E3%83%AB%E3%83%97",
    privacyUrl: "https://hireroo.io/privacy",
  };
};
