import { useTranslation } from "@hireroo/i18n";
import { Fields } from "@hireroo/validator";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useController } from "react-hook-form";

import MultiChoiceField, { MultiChoiceFieldProps } from "../../../../primitive/InputControl/MultiChoiceField/MultiChoiceField";
import SelectedQuestionControlBar, { SelectedQuestionControlBarProps } from "../SelectedQuestionControlBar/SelectedQuestionControlBar";

type FieldValue = Fields.EntitySource.EntitySource;

const StyledRootListItem = styled(ListItem)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: 8,
  padding: theme.spacing(2),
}));

const StyledChildListItem = styled(ListItem)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderLeft: `3px solid ${theme.palette.Other.Divider}`,
  padding: theme.spacing(2),
}));

const StyledVariantLabelBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  color: theme.palette.text.secondary,
}));

const ActionButton = styled(Button)(() => ({
  minWidth: 0,
  padding: 0,
  marginRight: "5px",
  marginLeft: "5px",
  height: 22,
  fontSize: 13,
}));

const StyledMultiChoiceField = styled(MultiChoiceField)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  ".MuiCheckbox-root": {
    height: 20,
    width: 20,
    marginLeft: 9,
    marginRight: 9,
  },
  ".MuiFormControlLabel-root": {
    marginRight: 16,
    marginBottom: 8,
    "&:last-child": {
      marginRight: 0,
    },
  },
}));

type FieldError = {
  enabledLanguages?: { message?: string };
  componentTypes?: { message?: string };
};

export type QuestionDetailEditableListItemProps = {
  className?: string;
  index: number;
  name: string;
  uniqueKey: string;
  title: string;
  variantLabel?: string;
  options?: MultiChoiceFieldProps["options"];
  disabledAll?: boolean;
  isChild?: boolean;
  controlBar?: SelectedQuestionControlBarProps;
};

const QuestionDetailEditableListItem: React.FC<QuestionDetailEditableListItemProps> = props => {
  const StyledListItem = props.isChild ? StyledChildListItem : StyledRootListItem;
  const { t } = useTranslation();
  const { field, fieldState } = useController<Record<string, FieldValue>>({
    name: props.name,
  });

  const fieldError = fieldState.error as FieldError | undefined;

  /**
   * Prevent MultiChoice field.value from being changed when selecting/unselecting all
   */
  const [forceUpdate, setForceUpdate] = React.useState(false);

  const ErrorMessage = fieldError ? (
    <FormHelperText error component="span">
      {fieldError?.enabledLanguages?.message}
      {fieldError?.componentTypes?.message}
    </FormHelperText>
  ) : undefined;

  const multiChoiceFieldProps = React.useMemo((): MultiChoiceFieldProps => {
    if (field.value.type === "CHALLENGE") {
      return {
        name: `${props.name}.enabledLanguages`,
        options: props.options ?? [],
        watch: true,
      };
    }
    if (field.value.type === "SYSTEM_DESIGN") {
      return {
        name: `${props.name}.componentTypes`,
        options: props.options ?? [],
        watch: true,
      };
    }
    return {
      name: `${props.name}.options`,
      options: props.options ?? [],
      watch: true,
    };
  }, [field.value.type, props.name, props.options]);

  const allSelectButton: ButtonProps = {
    variant: "text",
    color: "secondary",
    disabled: props.disabledAll,
    children: t("全選択"),
    onClick: () => {
      switch (field.value.type) {
        case "CHALLENGE": {
          const newFieldValue: Extract<FieldValue, { type: "CHALLENGE" }> = {
            ...field.value,
            enabledLanguages: (props.options || []).map(({ value }) => value),
          };
          field.onChange(newFieldValue);
          setForceUpdate(prev => !prev);
          break;
        }
        case "SYSTEM_DESIGN": {
          const newFieldValue: Extract<FieldValue, { type: "SYSTEM_DESIGN" }> = {
            ...field.value,
            componentTypes: ["default", "aws", "gcp", "azure"],
          };
          field.onChange(newFieldValue);
          setForceUpdate(prev => !prev);
          break;
        }
        case "PROJECT":
        case "QUIZ": {
          break;
        }
      }
    },
  };

  const unSelectAllButton: ButtonProps = {
    variant: "text",
    color: "secondary",
    disabled: props.disabledAll,
    children: t("全解除"),
    onClick: () => {
      switch (field.value.type) {
        case "CHALLENGE": {
          const newFieldValue: Extract<FieldValue, { type: "CHALLENGE" }> = {
            ...field.value,
            enabledLanguages: [],
          };
          field.onChange(newFieldValue);
          setForceUpdate(prev => !prev);
          break;
        }
        case "SYSTEM_DESIGN": {
          const newFieldValue: Extract<FieldValue, { type: "SYSTEM_DESIGN" }> = {
            ...field.value,
            componentTypes: [],
          };
          field.onChange(newFieldValue);
          setForceUpdate(prev => !prev);
          break;
        }
        case "PROJECT":
        case "QUIZ": {
          break;
        }
      }
    },
  };

  const PrimaryComponent = (
    <Box>
      <Typography noWrap fontWeight={700} mb={0.5}>
        {props.title}
      </Typography>
      <StyledVariantLabelBox>
        <LabelOutlinedIcon fontSize="small" />
        <Typography noWrap color="text.secondary" variant="body2" fontSize={14} ml={0.5}>
          {props.variantLabel}
        </Typography>
      </StyledVariantLabelBox>
      {(field.value.type === "CHALLENGE" || field.value.type === "SYSTEM_DESIGN") && (
        <Box mt={2}>
          <Typography fontSize={14} color="text.secondary" mb={1}>
            {field.value.type === "CHALLENGE" && t("選択可能な言語") + ":"}
            {field.value.type === "SYSTEM_DESIGN" && t("選択可能なリソース種別") + ":"}
          </Typography>
          <Stack direction="row" spacing={1} mb={1}>
            <ActionButton {...allSelectButton} />
            <ActionButton {...unSelectAllButton} />
          </Stack>
          <StyledMultiChoiceField key={String(forceUpdate)} {...multiChoiceFieldProps} disabled={props.disabledAll} />
        </Box>
      )}
      {props.controlBar && <SelectedQuestionControlBar {...props.controlBar} mt={2} />}
    </Box>
  );

  return (
    <StyledListItem className={props.className}>
      <ListItemText primary={PrimaryComponent} secondary={ErrorMessage} />
    </StyledListItem>
  );
};

QuestionDetailEditableListItem.displayName = "QuestionDetailEditableListItem";

export default QuestionDetailEditableListItem;
