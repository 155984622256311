import CloseIcon from "@mui/icons-material/Close";
import MapIcon from "@mui/icons-material/Map";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";

import { useSystemDesignContext } from "../../store";
import StaticFlowChart from "../StaticFlowChart/StaticFlowChart";

const Wrapper = styled(Box)`
  position: absolute;
  right: 0;
  bottom: 0;
`;

const MiniMapBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    width: 150,
    height: 150,
  },
  [theme.breakpoints.up("xl")]: {
    width: 200,
    height: 200,
  },
}));

const MiniMapOpenFab = styled(Fab)`
  right: 0;
  bottom: 0;
`;

const MiniMapCloseBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  [theme.breakpoints.up("lg")]: {
    right: 110,
    bottom: 110,
  },
  [theme.breakpoints.up("xl")]: {
    right: 160,
    bottom: 160,
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.black,
}));

export type MiniMapProps = {};

const MiniMap: React.FC<MiniMapProps> = _props => {
  const theme = useTheme();
  const Store = useSystemDesignContext();
  const viewbox = Store.hooks.useViewbox();
  const componentType = Store.hooks.useComponentType();
  const elementsList = Store.hooks.useElementsList();
  const [minimapOpen, setMinimapOpen] = React.useState<boolean>(true);

  if (!minimapOpen) {
    return (
      <Wrapper>
        <MiniMapOpenFab
          size="medium"
          color="primary"
          onClick={() => {
            setMinimapOpen(true);
          }}
        >
          <MapIcon />
        </MiniMapOpenFab>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <MiniMapBox>
        <Paper elevation={4} sx={{ backgroundColor: theme.palette.common.white }}>
          <StaticFlowChart
            elements={elementsList}
            componentType={componentType}
            viewarea={viewbox}
            aspectRatio={1}
            disableZoom={true}
            disableGrid={true}
            simpleView={true}
          />
        </Paper>
      </MiniMapBox>
      <MiniMapCloseBox>
        <StyledIconButton size={"small"} onClick={() => setMinimapOpen(false)}>
          <CloseIcon />
        </StyledIconButton>
      </MiniMapCloseBox>
    </Wrapper>
  );
};

MiniMap.displayName = "MiniMap";

export default MiniMap;
