import type { Widget } from "@hireroo/presentation";

export type GenerateTestReportContentNotAvailablePropsArgs = {
  title: string;
  description: string;
};

export const useGenerateProps = (args: GenerateTestReportContentNotAvailablePropsArgs): Widget.FeatureNotAvailableProps => {
  const { title, description } = args;
  return {
    title,
    description,
    // TODO: Implement updatePlanButton and checkDetailButton when the page to jump to is decided
    // updatePlanButton: {},
    // checkDetailButton: {},
  };
};
