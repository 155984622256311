import { App, Company } from "@hireroo/app-store/essential/employee";
import { BuySelections } from "@hireroo/app-store/widget/e/BuySelections";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import BuySelectionFormContainer, { BuySelectionFormContainerProps } from "./Container";

export type BuySelectionFormFetchContainerProps = BuySelectionFormContainerProps;

const BuySelectionFormFetchContainer: React.FC<BuySelectionFormFetchContainerProps> = props => {
  const appStatus = App.useStatus();
  const companyId = Company.useStrictActiveCompanyId();
  const initialized = BuySelections.useInitialized();
  const [result, refresh] = Graphql.useGetCustomerForBuySelectionsQuery({
    variables: {
      customerId: companyId,
    },
    pause: appStatus !== "INITIALIZED",
  });

  React.useEffect(() => {
    if (result.data) {
      BuySelections.initialize(result.data.customer, result.data.buyAblePacks.interviews);
    }
  }, [result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: BuySelectionFormContainerProps = {
    ...props,
  };

  return <BuySelectionFormContainer {...containerProps} />;
};

BuySelectionFormFetchContainer.displayName = "BuySelectionFormFetchContainer";

export default withErrorBoundary(BuySelectionFormFetchContainer, {});
