import { isFreeTextAction, MergedRevision } from "@hireroo/app-helper/firepad";
import { QuizTestReport } from "@hireroo/app-store/view-domain/QuizTestReport";
import type { IStandaloneCodeEditor, Monaco } from "@hireroo/code-editor/react/CodeEditor";
import { useLanguageCode } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import { Widget } from "@hireroo/presentation";
import * as React from "react";

import { applyEditorValue } from "./privateHelper";

type FreeTextPlaybackContent = Extract<Widget.QuizPlaybackEditorProps["playback"]["playbackContent"], { kind: "FREE_TEXT" }>;

const FREE_TEXT_INTERVAL = 150;

type GenerateFreeTextPlaybackPropsArgs = {
  quizId: number;
  questionId: number;
  revisionIndex: number;
  revisions: MergedRevision[];
  open: boolean;
  parentProps: Omit<Widget.QuizPlaybackEditorProps, "playback">;
  handleOpen: () => void;
  handleClose: () => void;
  handlePlaybackValue: (newIndex: number) => void;
};

export const useGenerateFreeTextPlaybackProps = (args: GenerateFreeTextPlaybackPropsArgs): Widget.QuizPlaybackEditorProps => {
  const { open, questionId, parentProps, revisionIndex, handlePlaybackValue, handleOpen, handleClose } = args;
  const lang = useLanguageCode();

  // editorRef refers original playback editor and expandedEditorRef refers expanded playback editor.
  // During playback, text is applied only to the model of the editor which is displayed,
  // and when editor is expanded or shrunk, text of the previous editor is copied to the new one.
  const editorRef = React.useRef<IStandaloneCodeEditor | null>(null);
  const expandedEditorRef = React.useRef<IStandaloneCodeEditor | null>(null);
  const monacoRef = React.useRef<Monaco>();

  const lastSubmittedQuestionId = React.useMemo(() => {
    const lastRevision = args.revisions && args.revisions.length > 0 ? args.revisions[args.revisions.length - 1] : null;
    if (lastRevision === null || isFreeTextAction(lastRevision)) return;
    if (lastRevision.s === "subq" || lastRevision.s === "outq") {
      return Number(lastRevision.v);
    }
  }, [args.revisions]);

  const hooks = QuizTestReport.useCreateQuizHooks(args.quizId);
  const question = hooks.useQuestionByQuestionId(questionId);
  const lastQuestion = hooks.useQuestionByQuestionId(lastSubmittedQuestionId || 0);
  const lastSubmission = hooks.getSelectedSubmission(lastQuestion ? `${lastQuestion.id}-${lastQuestion.version}` : `${0}-${""}`);

  React.useEffect(() => {
    const model = editorRef.current?.getModel();
    const expandedModel = expandedEditorRef.current?.getModel();
    // Copy the expanded editor value to the original one when the expanded one is unmounted
    if (model && expandedModel && !open) {
      model.setValue(expandedModel.getValue());
    }
  }, [open]);

  React.useEffect(() => {
    if (revisionIndex === 0) {
      editorRef.current?.getModel()?.setValue("");
      expandedEditorRef.current?.getModel()?.setValue("");
    }

    if (revisionIndex === args.revisions.length - 1) {
      editorRef.current?.getModel()?.setValue(lastSubmission?.answerText || "");
      expandedEditorRef.current?.getModel()?.setValue(lastSubmission?.answerText || "");
    }
  }, [revisionIndex, question, args.revisions.length, lastSubmission?.answerText]);

  const handleFreeTextPlaybackValue = React.useCallback(
    (newIndex: number) => {
      handlePlaybackValue(newIndex);
      const model = editorRef.current?.getModel();
      const modelInDialog = expandedEditorRef.current?.getModel();
      if (!monacoRef.current || !args.revisions || newIndex === revisionIndex) return;
      if (model) {
        applyEditorValue({
          model: model,
          monaco: monacoRef.current,
          revisions: args.revisions,
          newIndex: newIndex,
          sliderIndex: revisionIndex,
        });
      }

      if (modelInDialog) {
        applyEditorValue({
          model: modelInDialog,
          monaco: monacoRef.current,
          revisions: args.revisions,
          newIndex: newIndex,
          sliderIndex: revisionIndex,
        });
      }
    },
    [handlePlaybackValue, revisionIndex, args.revisions],
  );

  const playbackToolbarProps = React.useMemo((): Widget.QuizPlaybackEditorProps["playbackToolbar"] => {
    return {
      ...parentProps.playbackToolbar,
      autoPlayIntervalMilliseconds: FREE_TEXT_INTERVAL,
      onChangePlaybackValue: handleFreeTextPlaybackValue,
      screenButton: {
        onClick: handleOpen,
      },
    };
  }, [handleFreeTextPlaybackValue, handleOpen, parentProps.playbackToolbar]);

  const playbackContentProps: FreeTextPlaybackContent = React.useMemo(() => {
    return {
      kind: "FREE_TEXT",
      content: {
        editor: {
          path: `freeText-${questionId}`,
          // We have to display default value only when last question of playback is selected.
          defaultValue: lastQuestion?.id === questionId ? lastSubmission?.answerText : "",
          onMount: (editor, monaco) => {
            editorRef.current = editor;
            monacoRef.current = monaco;
          },
        },
      },
    };
  }, [lastQuestion?.id, lastSubmission?.answerText, questionId]);

  const playbackProps: Widget.QuizPlaybackEditorProps["playback"] = {
    description: resolveLanguage(question || {}, lang, "description"),
    playbackContent: playbackContentProps,
  };

  return {
    ...parentProps,
    playback: playbackProps,
    playbackToolbar: playbackToolbarProps,
    playbackDialog: {
      playbackToolbar: {
        ...playbackToolbarProps,
        screenButton: {
          onClick: handleClose,
        },
      },
      dialog: {
        open: args.open,
        onClose: handleClose,
      },
      playback: {
        ...playbackProps,
        playbackContent: {
          kind: "FREE_TEXT",
          content: {
            editor: {
              path: `dialog-freeText-${question?.id}`,
              // We have to display default value only when last question of playback is selected.
              defaultValue: lastQuestion?.id === questionId ? lastSubmission?.answerText : "",
              onMount: editor => {
                expandedEditorRef.current = editor;
                const expandedModel = editor.getModel();
                const model = editorRef.current?.getModel();
                // Copy the original editor value to the expanded one when the expanded one is mounted
                if (expandedModel && model) {
                  expandedModel.setValue(model.getValue());
                }
              },
            },
          },
        },
      },
    },
  };
};
