import { useTranslation } from "@hireroo/i18n";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import SkipNext from "@mui/icons-material/SkipNext";
import SkipPrevious from "@mui/icons-material/SkipPrevious";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Slider, { SliderProps } from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "../../../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";
import ParticipantSlider, { ParticipantSliderProps } from "../ParticipantSlider/ParticipantSlider";
import { findBiggerClosestIndex, findSmallerClosestIndex } from "./privateHelper";

const HEIGHT = 40;

const StyledPaper = styled(Paper)(() => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  boxShadow: "none",
}));

const PlaybackSlider = styled(Slider)(({ theme }) => ({
  "&.MuiSlider-root": {
    height: 4,
    marginBottom: 0,
  },
  "& .MuiSlider-thumb": {
    height: 16,
    width: 16,
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    "&:focus, &:hover, &:active": {
      boxShadow: "inherit",
    },
  },
  "& .MulSlider-active": {},
  "& .MuiSlider-markLabel": {
    fontSize: 10,
    top: 20,
    color: theme.palette.grey[400],
  },
  "& .MuiSlider-track": {
    height: 2,
    borderRadius: 3,
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  "& .MuiSlider-label": {
    height: 6,
    borderRadius: 3,
  },
  "& .MuiSlider-mark": {
    backgroundColor: theme.palette["Background/Paper"].p8,
    height: 3,
    width: 3,
  },
  "& .MuiSlider-markActive": {
    backgroundColor: theme.palette.primary.contrastText,
    height: 3,
    width: 3,
  },
}));
const TableRowBox = styled(Box)(() => ({
  display: "table-row",
  borderCollapse: "collapse",
}));

const SquareTableCellBox = styled(Box)(({ theme }) => ({
  display: "table-cell",
  width: HEIGHT,
  height: HEIGHT,
  borderLeft: `1px solid ${theme.palette.Other.Divider}`,
  borderRight: `1px solid ${theme.palette.Other.Divider}`,
}));

const TimeLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[400],
  fontSize: 12,
}));

const SquareButton = styled(IconButtonWithTooltip)(({ theme }) => ({
  color: theme.palette.text.secondary,
  width: HEIGHT,
  height: HEIGHT,
  minWidth: HEIGHT,
  minHeight: HEIGHT,
  borderRadius: 0,
}));
type OptionalPlaybackChangeParams = {
  isTouched: boolean;
  playStatus: PlayStatus;
};
type PlayStatus = "PLAY" | "PAUSE";
type ExpandStatus = "EXPAND_MORE" | "EXPAND_LESS";

export type MultiLinePlaybackToolbarProps = {
  /**
   * This value can be used to externally manipulate the value of slider.
   */
  value: number;
  disabledAll?: boolean;
  onChangePlaybackValue?: (value: number, params?: OptionalPlaybackChangeParams) => void;
  slider: Pick<SliderProps, "marks" | "disabled" | "valueLabelDisplay" | "valueLabelFormat"> & {
    max: number;
    min: number;
  };
  onChangePlayStatus?: (playStatus: PlayStatus) => void;
  /**
   * Default 100ms
   */
  autoPlayIntervalMilliseconds?: number;
  /**
   * true when slider autoplay
   */
  enableAutoplay: boolean;
  remainTime: string;
  passedTime: string;
  defaultExpandStatus: ExpandStatus;
  participantSliders: ParticipantSliderProps[];
};

const MultiLinePlaybackToolbar: React.FC<MultiLinePlaybackToolbarProps> = props => {
  const { t } = useTranslation();
  const { autoPlayIntervalMilliseconds = 100, enableAutoplay } = props;
  const { onChangePlaybackValue, onChangePlayStatus } = props;
  const { max, min, marks } = props.slider;
  const [playStatus, setPlayStatus] = React.useState<PlayStatus>("PAUSE");
  const [expandStatus, setExpandStatus] = React.useState<ExpandStatus>(props.defaultExpandStatus);
  const sliderValue = props.value;
  const markValues = React.useMemo(() => {
    if (typeof marks === "boolean" || marks === undefined) {
      return [];
    }
    return marks.map(m => m.value);
  }, [marks]);

  React.useEffect(() => {
    /**
     * TODO: use requestAnimationFrame
     * `sliderValue` is changed by useEffect and setTimeout is repeatedly executed
     * but this side effect should NOT be used.
     */
    const timer = setTimeout(() => {
      if (sliderValue >= max && playStatus === "PLAY") {
        setPlayStatus("PAUSE");
        return;
      }
      if (playStatus === "PLAY" && enableAutoplay) {
        onChangePlaybackValue?.(sliderValue + 1);
      }
    }, autoPlayIntervalMilliseconds);
    return () => timer && clearInterval(timer);
  }, [autoPlayIntervalMilliseconds, max, playStatus, enableAutoplay, sliderValue, onChangePlaybackValue]);

  React.useEffect(() => {
    onChangePlayStatus?.(playStatus);
  }, [onChangePlayStatus, playStatus]);

  const playStatusTitleMap: Record<PlayStatus, string> = {
    PAUSE: t("再生"),
    PLAY: t("一時停止"),
  };
  const playStatusIconMap: Record<PlayStatus, React.ReactElement> = {
    PAUSE: <PlayArrowRoundedIcon />,
    PLAY: <PauseRoundedIcon />,
  };
  const expandStatusTitleMap: Record<ExpandStatus, string> = {
    EXPAND_LESS: t("縮小する"),
    EXPAND_MORE: t("拡大する"),
  };
  const expandStatusIconMap: Record<ExpandStatus, React.ReactElement> = {
    EXPAND_MORE: <ExpandMore />,
    EXPAND_LESS: <ExpandLess />,
  };

  const handlePlayDidClick = React.useCallback(() => {
    // If the value is at the end, start from the beginning
    if (sliderValue === max) {
      onChangePlaybackValue?.(min);
    }

    // Switch play and pause
    setPlayStatus(draft => {
      return draft === "PLAY" ? "PAUSE" : "PLAY";
    });
  }, [max, min, onChangePlaybackValue, sliderValue]);

  const expandButton: IconButtonWithTooltipProps = {
    onClick: React.useCallback(() => {
      setExpandStatus(draft => (draft === "EXPAND_LESS" ? "EXPAND_MORE" : "EXPAND_LESS"));
    }, []),
    disabled: props.disabledAll,
    size: "small",
    title: expandStatusTitleMap[expandStatus],
    children: expandStatusIconMap[expandStatus],
  };

  const skipPrevious: IconButtonWithTooltipProps = {
    onClick: React.useCallback(() => {
      const closestIndex = findSmallerClosestIndex(markValues, sliderValue);
      if (closestIndex < 0) {
        onChangePlaybackValue?.(min);
      } else {
        onChangePlaybackValue?.(markValues[closestIndex]);
      }
    }, [markValues, min, onChangePlaybackValue, sliderValue]),
    disabled: props.disabledAll,
    size: "small",
    title: t("前のイベントに戻る"),
    children: <SkipPrevious />,
  };
  const playOrPause: IconButtonWithTooltipProps = {
    onClick: handlePlayDidClick,
    disabled: props.disabledAll,
    size: "small",
    title: playStatusTitleMap[playStatus],
    children: playStatusIconMap[playStatus],
  };
  const skipNext: IconButtonWithTooltipProps = {
    onClick: React.useCallback(() => {
      const closestIndex = findBiggerClosestIndex(markValues, sliderValue);
      if (closestIndex < 0) {
        onChangePlaybackValue?.(max);
      } else {
        onChangePlaybackValue?.(markValues[closestIndex]);
      }
    }, [markValues, max, onChangePlaybackValue, sliderValue]),
    disabled: props.disabledAll,
    size: "small",
    title: t("次のイベントに進む"),
    children: <SkipNext />,
  };

  const sliderProps: SliderProps = {
    "aria-labelledby": "non-linear-slider",
    ...props.slider,
    onChange: (event, value) => {
      /**
       * check if the slider is touched by user while playStatus is PLAY.
       * Toughing the slider while playing will cause the slider value to be different value then expected
       */
      const isTouched = Boolean(event.target);
      const params: OptionalPlaybackChangeParams = {
        isTouched,
        playStatus,
      };
      if (typeof value === "number") {
        onChangePlaybackValue?.(value, params);
      }
    },
  };

  return (
    <StyledPaper elevation={8}>
      <Stack direction="column" width="100%">
        <Stack direction="row" width="100%">
          <TableRowBox>
            <SquareTableCellBox sx={{ borderLeft: "none" }}>
              <SquareButton key={"expandButton"} {...expandButton} />
            </SquareTableCellBox>
            <SquareTableCellBox>
              <SquareButton key={"skipPrevious"} {...skipPrevious} />
            </SquareTableCellBox>
            <SquareTableCellBox>
              <SquareButton key={"playOrPause"} {...playOrPause} />
            </SquareTableCellBox>
            <SquareTableCellBox>
              <SquareButton key={"skipNext"} {...skipNext} />
            </SquareTableCellBox>
          </TableRowBox>
          <Stack spacing={1.5} direction="row" width="100%" alignItems="center" px={3}>
            <TimeLabel>{props.passedTime}</TimeLabel>
            <Box flexGrow={1} display="flex">
              <PlaybackSlider {...sliderProps} value={sliderValue} />
            </Box>
            <TimeLabel>{props.remainTime}</TimeLabel>
          </Stack>
        </Stack>
        {expandStatus === "EXPAND_MORE" && (
          <Stack direction="column">
            {props.participantSliders.map((participantSlider, index) => (
              <ParticipantSlider key={`${index}-${participantSlider.id}`} {...participantSlider} />
            ))}
          </Stack>
        )}
      </Stack>
    </StyledPaper>
  );
};

MultiLinePlaybackToolbar.displayName = "MultiLinePlaybackToolbar";

export default MultiLinePlaybackToolbar;
