import { AlgorithmSignatureForm } from "@hireroo/validator";
import * as React from "react";
import { useFieldArray } from "react-hook-form";

import { useAlgorithmTestCasesFormContext } from "../../Context";
import { buildInputFormat, buildOutputFormat, outputsFormatToArray } from "./databaseUtils";
import { SummaryKind, TestCaseKind } from "./types";

const keyMap: Record<SummaryKind, "inputs" | "outputs"> = {
  OUTPUT: "outputs",
  INPUT: "inputs",
};
export const useTableSummaries = (
  signature: AlgorithmSignatureForm.DatabaseSignatureSchemaType,
  testCaseIndex: number,
  summaryType: SummaryKind,
  testCaseType: TestCaseKind,
) => {
  const { method } = useAlgorithmTestCasesFormContext();

  const key = keyMap[summaryType];

  const testcaseValues = useFieldArray({
    control: method.control,
    // FIXME: fix type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    name: `${testCaseType}.data.${testCaseIndex}.${key}` as any,
  });

  //TODO: implement array wrapper
  const watched = method.watch(`${testCaseType}.data.${testCaseIndex}.${key}`);
  const defaultValues = React.useMemo((): string[] => {
    if (summaryType === "INPUT") {
      return watched || [];
    }
    return outputsFormatToArray(watched);
  }, [summaryType, watched]);

  const [values, setValues] = React.useState<string[]>(defaultValues);

  const handleValueChange = React.useCallback(
    (tableIndex: number) => (value: string) => {
      const newValues = [...values];

      if (summaryType === "INPUT") {
        newValues[tableIndex] = buildInputFormat(value, signature.tables[tableIndex]);
        testcaseValues.replace(newValues);
      } else {
        newValues[tableIndex] = value;
        testcaseValues.replace([buildOutputFormat(value, signature)]);
      }

      setValues(newValues);
      method.clearErrors([`${testCaseType}.data.${testCaseIndex}.${key}`]);
    },
    [values, summaryType, method, testCaseType, testCaseIndex, key, signature, testcaseValues],
  );

  const state = {
    values,
  };

  const action = {
    handleValueChange,
  };

  return { state, action };
};
