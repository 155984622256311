export type CreateTooltipElementArgs = {
  classNames?: string[];
  text: string;
  textColor: string;
  opacity: string;
  backgroundColor: string;
  borderColor: string;
};

export const createTooltipElement = (args: CreateTooltipElementArgs): HTMLElement => {
  const node = document.createElement("div");
  node.style.borderColor = args.borderColor;
  node.style.backgroundColor = args.backgroundColor;
  node.style.color = args.textColor;
  node.style.opacity = args.opacity;
  node.style.borderRadius = "2px";
  node.style.fontSize = "12px";
  node.style.padding = "2px 8px";
  node.style.whiteSpace = "nowrap";
  node.textContent = args.text;
  if (args.classNames) {
    node.classList.add(...args.classNames);
  }
  return node;
};

export type CreateSquareElementArgs = {
  classNames?: string[];
  opacity: string;
  backgroundColor: string;
  borderColor: string;
};

export const createSquareElement = (args: CreateSquareElementArgs) => {
  const node = document.createElement("div");
  node.style.borderColor = args.borderColor;
  node.style.backgroundColor = args.backgroundColor;
  node.style.opacity = args.opacity;
  node.style.width = "5px";
  node.style.height = "4px";
  if (args.classNames) {
    node.classList.add(...args.classNames);
  }
  return node;
};

export type CreateCursorElementArgs = {
  classNames?: string[];
  backgroundColor: string;
};

export const createCursorElement = (args: CreateCursorElementArgs): HTMLElement => {
  const node = document.createElement("div");
  node.style.position = "relative";
  node.style.borderColor = args.backgroundColor;
  if (args.classNames) {
    node.classList.add(...args.classNames);
  }
  return node;
};

export type CreateStyleElementArgs = {
  className: string;
  backgroundColor: string;
};

export const createStyleElement = (args: CreateStyleElementArgs) => {
  const style = `
    .${args.className} {
      position: relative;
      border-left: 3px solid ${args.backgroundColor};
    }
  `;
  const styleTextNode = document.createTextNode(style);
  const styleElement = document.createElement("style");
  styleElement.appendChild(styleTextNode);
  return styleElement;
};
