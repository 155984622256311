import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { CONTEXT_SEPARATOR, NAMESPACE } from "./constants";
import { SupportLanguage } from "./definition";
import commonEn from "./en/CommonWithVariables.json" assert { type: "json" };
import enJson from "./en/translation.json" assert { type: "json" };
import commonJa from "./ja/CommonWithVariables.json" assert { type: "json" };

export const resources = {
  en: {
    translation: enJson,
  },
} as const;

export const initialize = (): void => {
  i18n
    .use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init(
      {
        resources,
        // change false to true if you want to use debug mode
        debug: false,
        supportedLngs: Object.values(SupportLanguage),
        detection: {
          lookupLocalStorage: "i18nextLng",
          lookupSessionStorage: "i18nextLng",
          order: ["localStorage", "sessionStorage", "navigator", "querystring", "cookie", "htmlTag"],
        },
        react: {
          bindI18n: "loaded languageChanged",
          bindI18nStore: "added",
          useSuspense: true,
        },

        // keySeparator: false, // we do not use keys in form messages.welcome
        keySeparator: ".",
        fallbackLng: false,
        returnEmptyString: false,
        contextSeparator: CONTEXT_SEPARATOR,
        interpolation: {
          escapeValue: false, // react already safes from xss
          // skipOnVariables: true,
        },
      },
      () => {
        i18n.addResources("en", NAMESPACE.COMMON_WITH_VARIABLES, commonEn);
        i18n.addResources("ja", NAMESPACE.COMMON_WITH_VARIABLES, commonJa);
      },
    );

  document.documentElement.lang = i18n.language;
};
