import { TargetElementIdMap } from "@hireroo/app-helper/challenge";
import { safeJsonParse } from "@hireroo/app-helper/parser";
import { ChallengeTestReport } from "@hireroo/app-store/view-domain/ChallengeTestReport";
import { formatScore } from "@hireroo/formatter/score";
import { Widget } from "@hireroo/presentation";
import { AlgorithmTestCase } from "@hireroo/validator";
import * as React from "react";

import { adapterForLegacyPerformanceResult, adapterForPerformanceResult } from "./privateHelper";

type PerformanceSection = Widget.ChallengeTestReportProps["performanceSection"];

export type GeneratePerformanceSectionArgs = {
  challengeId: number;

  submissionId: number;
  showScore: boolean;
};

export const useGeneratePerformanceSection = (args: GeneratePerformanceSectionArgs): PerformanceSection => {
  const challengeHooks = ChallengeTestReport.useCreateChallengeHooks(args.challengeId);
  const challenge = challengeHooks.useChallenge();

  const submissionMap = challengeHooks.useSubmissionMap();

  const machineSpec = React.useMemo((): string => {
    const submission = submissionMap[args.submissionId];
    const machineSpecItems = [
      `${submission?.machineOs && submission?.machineOs !== "" ? submission.machineOs : "-"} OS`,
      `${submission?.machineCpu && submission?.machineCpu !== 0 ? submission.machineCpu : "-"} CPU /`,
      `${submission?.machineMemory && submission?.machineMemory !== "" ? submission.machineMemory : "-"}`,
    ];
    return machineSpecItems.join(" ");
  }, [args.submissionId, submissionMap]);

  const performanceTestCaseResult = React.useMemo(() => {
    const submission = submissionMap[args.submissionId];
    if (submission?.performanceTestResult && submission.performanceTestResult !== "") {
      const result = AlgorithmTestCase.PerformanceTestCaseResultV2.safeParse(safeJsonParse(submission.performanceTestResult));
      if (result.success) {
        return adapterForPerformanceResult(result.data, challenge.challengeQuestion?.performanceTimeLimitMilliSeconds);
      }

      const legacyResult = AlgorithmTestCase.PerformanceTestCaseResults.safeParse(safeJsonParse(submission.performanceTestResult));
      if (legacyResult.success) {
        return adapterForLegacyPerformanceResult(legacyResult.data);
      }
    }
  }, [args.submissionId, challenge.challengeQuestion?.performanceTimeLimitMilliSeconds, submissionMap]);

  if (performanceTestCaseResult === undefined) {
    return {
      score: submissionMap[args.submissionId]?.performance ? formatScore(submissionMap[args.submissionId].performance) : 0,
      machineSpec: machineSpec,
      graph: {
        version: "V2",
        data: undefined,
      },
      targetElementId: TargetElementIdMap.PERFORMANCE_SECTION,
    };
  }

  return {
    score: args.showScore ? formatScore(submissionMap[args.submissionId]?.performance ?? 0) : undefined,
    machineSpec: machineSpec,
    graph: performanceTestCaseResult,
    targetElementId: TargetElementIdMap.PERFORMANCE_SECTION,
  };
};
