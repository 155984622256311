import { getRef } from "@hireroo/firebase";
import { COMPONENT_TYPE_RELEASED_VERSION } from "@hireroo/system-design/constants/flowChart";
import { ComponentType, FlowAction } from "@hireroo/system-design/features";
import { useEffect, useState } from "react";
import semver from "semver";

import { fetchRevisions, fetchStates, SyncState } from "../firepad";
import { tuple } from "../tuple";

export const useSystemDesignRevisions = (
  systemDesignId: number,
  questionId: number,
  componentType: ComponentType,
  version: string,
): [SyncState[], FlowAction[], boolean] => {
  const [actions, setActions] = useState<SyncState[]>([]);
  const [histories, setHistories] = useState<FlowAction[]>([]);
  const [actionReady, setActionReady] = useState<boolean>(false);
  const [historyReady, setHistoryReady] = useState<boolean>(false);

  useEffect(() => {
    const questionRef = getRef("systemDesign", `systemDesigns/${systemDesignId}/questions/${questionId}`);
    fetchStates(questionRef, fetchedStates => {
      setActions(fetchedStates);
      setActionReady(true);
    });

    // TODO: Remove this condition after client cache is cleared
    // History path has changed, so keep backward compatibility temporarily
    // After a while, remove this condition and users won't be able to see the old version of playback
    if (semver.gte(version, COMPONENT_TYPE_RELEASED_VERSION)) {
      const componentTypeRef = getRef(
        "systemDesign",
        `systemDesigns/${systemDesignId}/questions/${questionId}/componentTypes/${componentType}`,
      );
      fetchRevisions(componentTypeRef, fetchedHistories => {
        setHistories(fetchedHistories as FlowAction[]);
        setHistoryReady(true);
      });
    } else {
      fetchRevisions(questionRef, fetchedHistories => {
        setHistories(fetchedHistories as FlowAction[]);
        setHistoryReady(true);
      });
    }
  }, [componentType, questionId, systemDesignId, version]);

  useEffect(() => {
    return () => {
      setActionReady(false);
      setHistoryReady(false);
    };
  }, []);

  return tuple(actions, histories, actionReady && historyReady);
};
