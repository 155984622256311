import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { type SubmitHandler } from "react-hook-form";

import EntryContentPaper from "../../../../modules/EntryContentPaper/EntryContentPaper";
import AcceptButton, { AcceptButtonProps } from "../../../../primitive/Button/AcceptButton/AcceptButton";
import { useEntryTestContext } from "../EntryContents/Context";

export type ConfirmFormProps = {
  acceptButton: Pick<AcceptButtonProps, "disabled">;
  onSubmit: SubmitHandler<{ agreement: boolean }>;
};

const ConfirmForm: React.FC<ConfirmFormProps> = props => {
  const { t } = useTranslation();
  const { methods } = useEntryTestContext();
  const acceptButtonProps: AcceptButtonProps = {
    type: "submit",
    disabled: props.acceptButton.disabled || !methods.watch("agreement"),
    children: t("テストを開始する"),
  };

  return (
    <EntryContentPaper>
      <Typography variant="h6" style={{ fontWeight: "bold", textAlign: "center" }}>
        {t("テスト開始")}
      </Typography>

      <form onSubmit={methods.handleSubmit(props.onSubmit)}>
        <Box mt={4} mb={2}>
          <Stack direction="row" justifyContent="center" mt={4}>
            <AcceptButton {...acceptButtonProps} />
          </Stack>
        </Box>
      </form>
    </EntryContentPaper>
  );
};

ConfirmForm.displayName = "ConfirmForm";

export default ConfirmForm;
