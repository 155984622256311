import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Button, { type ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";

import ThumbSwitch, { ThumbSwitchProps } from "../../../../modules/ThumbSwitch/ThumbSwitch";
import EvaluationItem, { EvaluationItemProps } from "../EvaluationItem/EvaluationItem";

export type ReviewSectionProps = {
  commentTextField: TextFieldProps;
  evaluationItems: EvaluationItemProps[];
  backButton: Pick<ButtonProps, "onClick" | "disabled">;
  confirmButton: Pick<ButtonProps, "onClick" | "disabled">;
  thumbSwitch?: ThumbSwitchProps;
};

const ReviewSection: React.FC<ReviewSectionProps> = props => {
  const { t } = useTranslation();

  return (
    <Box height="100%" display="flex" justifyContent="space-between" flexDirection="column">
      <Stack direction="column" spacing={2} mb={2} overflow="auto" height="90%">
        <Stack spacing={2} direction="column">
          {props.thumbSwitch ? (
            <Box flexDirection="row" display="flex" justifyContent="space-between">
              <Typography variant="subtitle1">{t("合否判定")}</Typography>
              <ThumbSwitch {...props.thumbSwitch} />
            </Box>
          ) : (
            <Typography variant="subtitle1">{t("コメント")}</Typography>
          )}
          <TextField
            multiline
            color="secondary"
            rows={4}
            variant={"outlined"}
            placeholder={
              props.thumbSwitch
                ? t("合否判定の理由を任意で残すことで、最終判断が容易となります。")
                : t("技術レビューのコメントを書くことができます。")
            }
            {...props.commentTextField}
          />
        </Stack>
        <Typography variant="subtitle1">{t("評価項目")}</Typography>
        <Stack direction="column">
          {props.evaluationItems.map(evaluationItem => (
            <EvaluationItem key={evaluationItem.id} {...evaluationItem} />
          ))}
        </Stack>
      </Stack>
      <Box flexDirection={"row"} justifyContent={"space-between"} display={"flex"} width={"100%"}>
        <Button variant="outlined" color="outline-only" {...props.backButton}>
          {t("戻る")}
        </Button>
        <Button variant={"contained"} {...props.confirmButton}>
          {t("確定")}
        </Button>
      </Box>
    </Box>
  );
};

ReviewSection.displayName = "ReviewSection";

export default ReviewSection;
