import { QuizCodingEditor } from "@hireroo/app-store/widget/shared/QuizCodingEditor";

export const shuffleOptions = (options: QuizCodingEditor.Option[]): QuizCodingEditor.Option[] => {
  const shuffledArray = [...options];
  for (let i = options.length; 1 < i; i--) {
    const k = Math.floor(Math.random() * i);
    const tmp = shuffledArray[i - 1];
    shuffledArray[i - 1] = shuffledArray[k];
    shuffledArray[k] = tmp;
  }
  return shuffledArray;
};
