import { proxyMap, proxySet } from "valtio/utils";

import type * as Types from "./types";
import { State } from "./types";

const initPagination: Types.MetricPagination = {
  cursor: null,
  hasNext: false,
  nextCursor: null,
  count: 0,
  size: 10,
  page: 0,
};

export const createDefaultState = (): Readonly<State> => ({
  selectedMetricIds: proxySet(),
  searchText: "",

  metrics: proxyMap(),
  metricPagination: initPagination,
});
