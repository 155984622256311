import Markdown from "@hireroo/markdown-v2/react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import Avatar, { AvatarProps } from "./parts/Avatar/Avatar";

const StyledCard = styled(Card)(() => ({
  backgroundColor: "inherit",
  backgroundImage: "none",
  boxShadow: "none",
  display: "flex",
  width: "100%",
  overflow: "hidden",
  flexShrink: 0,
}));

const StyledCardContent = styled(Box)(() => ({
  width: "100%",
  overflow: "hidden",
  minWidth: 256,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
  fontSize: 12,
  fontWeight: "bold",
  color: theme.palette.action.active,
}));

const StyledMarkdown = styled(Markdown)(() => ({
  fontSize: "13px",
  "& .MuiTypography-root": {
    fontSize: "13px",
  },
}));

export type MessageCardProps = {
  avatar: AvatarProps;
  message: string;
};

const MessageCard: React.FC<MessageCardProps> = props => {
  return (
    <StyledCard>
      <Box mr={1.25}>
        <Avatar {...props.avatar} />
      </Box>
      <StyledCardContent>
        <StyledTypography>{props.avatar.role === "USER" ? "YOU" : "ChatGPT"}</StyledTypography>
        <StyledMarkdown size="small" children={props.message} />
      </StyledCardContent>
    </StyledCard>
  );
};

MessageCard.displayName = "MessageCard";

export default MessageCard;
