import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircle from "@mui/icons-material/CheckCircle";
import * as React from "react";

const IconMap = {
  CHECK: <CheckCircle fontSize="small" color="success" />,
  CROSS: <CancelIcon fontSize="small" color="error" />,
} as const satisfies Record<string, React.ReactNode>;

export type IconProps = {
  kind: keyof typeof IconMap;
};

const Icon: React.FC<IconProps> = props => {
  return <>{IconMap[props.kind]}</>;
};

Icon.displayName = "Icon";

export default Icon;
