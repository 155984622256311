import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import * as Subscriber from "./Subscriber";
import { GenerateRemoteInterviewParticipantsPropsArgs, useGenerateProps } from "./useGenerateProps";

export type RemoteInterviewParticipantsContainerProps = GenerateRemoteInterviewParticipantsPropsArgs;

const RemoteInterviewParticipantsContainer: React.FC<RemoteInterviewParticipantsContainerProps> = () => {
  const remoteInterviewParticipantsProps = useGenerateProps();
  React.useEffect(() => {
    const stop = Subscriber.startParticipants();
    return () => {
      stop();
    };
  }, []);
  return (
    <ErrorBoundary>
      <Widget.RemoteInterviewParticipants {...remoteInterviewParticipantsProps} />
    </ErrorBoundary>
  );
};

RemoteInterviewParticipantsContainer.displayName = "RemoteInterviewParticipantsContainer";

export default withErrorBoundary(RemoteInterviewParticipantsContainer, {});
