import { useTranslation } from "@hireroo/i18n";
import List from "@mui/material/List";
import * as React from "react";

import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";
import ListItemWithHeader, { ListItemWithHeaderProps } from "../ListItemWithHeader/ListItemWithHeader";

export type CreateDialogProps = {
  dialog: DialogWithCloseIconProps;
  items: ListItemWithHeaderProps[];
};

const CreateDialog: React.FC<CreateDialogProps> = props => {
  const { t } = useTranslation();
  const dialog: DialogWithCloseIconProps = {
    ...props.dialog,
    title: t("作成する問題の種類を選択"),
  };
  return (
    <DialogWithCloseIcon {...dialog}>
      <List disablePadding>
        {props.items.map(item => (
          <ListItemWithHeader key={item.headerText} {...item} />
        ))}
      </List>
    </DialogWithCloseIcon>
  );
};

CreateDialog.displayName = "CreateDialog";

export default CreateDialog;
