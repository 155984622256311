import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";

const { ProjectContentsViewerV4 } = ProjectTestReport;

export type GenerateProjectPlaybackSettingsMenuPropsArgs = {
  canShowPasteAndTabStatistics: boolean;
};

export const useGenerateProps = (args: GenerateProjectPlaybackSettingsMenuPropsArgs): Widget.ProjectPlaybackSettingsMenuProps => {
  const { canShowPasteAndTabStatistics } = args;
  const { t } = useTranslation();
  const playbackSettings = ProjectContentsViewerV4.usePlaybackSettings();
  const hasPasteEvent = ProjectContentsViewerV4.useHasPasteEvent();

  return {
    items: {
      copyAndPasteDetection: canShowPasteAndTabStatistics
        ? {
            disabled: !hasPasteEvent,
            disabledTooltipText: t("ペーストイベントが検出されなかったため無効化されています。"),
          }
        : undefined,
      // TODO: Uncomment this block
      // behavioralControl: isSessionIssued
      //   ? {
      //       disabled: !hasBehavioralEvent,
      //       disabledTooltipText: t("Google検索やChatGPTの利用履歴がないため無効化されています。"),
      //     }
      //   : undefined,
    },
    defaultValues: playbackSettings,
    onSubmit: fields => {
      ProjectContentsViewerV4.updatePlaybackSettings({ ...fields });
    },
  };
};
