import { AlgorithmHintForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import * as React from "react";
import { useForm, UseFormReturn } from "react-hook-form";

export type ContextValue = {
  methods: UseFormReturn<AlgorithmHintForm.AlgorithmHintsFormSchema>;
  selectedHintIndex: string;
  updateSelectedHintIndex: (value: string) => void;
};

export const AlgorithmHintsFormContext = React.createContext<ContextValue>({} as ContextValue);

export const useAlgorithmHintsFormContext = () => React.useContext(AlgorithmHintsFormContext);

export type AlgorithmHintsFormProviderProps = {
  defaultValues: AlgorithmHintForm.AlgorithmHintsFormSchema;
};

export const AlgorithmHintsFormProvider: React.FC<React.PropsWithChildren<AlgorithmHintsFormProviderProps>> = props => {
  const [selectedTabValue, updateSelectedTabValue] = React.useState<string>("0");
  const { defaultValues } = props;
  const schema = AlgorithmHintForm.useAlgorithmHintsFormSchema();
  const methods = useForm<AlgorithmHintForm.AlgorithmHintsFormSchema>({
    resolver: zodResolver(schema),
    mode: "onSubmit",
    shouldUnregister: false,
    defaultValues,
  });

  React.useEffect(() => {
    methods.setValue("newVersion", defaultValues.newVersion);
    methods.setValue("hints", defaultValues.hints);
  }, [methods, defaultValues]);

  const contextValue: ContextValue = {
    methods,
    selectedHintIndex: selectedTabValue,
    updateSelectedHintIndex: React.useCallback(value => {
      updateSelectedTabValue(value);
    }, []),
  };
  return <AlgorithmHintsFormContext.Provider value={contextValue} children={props.children} />;
};
