import { useQuestionTypeLabelMap } from "@hireroo/app-helper/question";
import { Company } from "@hireroo/app-store/essential/employee";
import { Payment } from "@hireroo/app-store/essential/employee";
import { QuestionSelectFieldForResourceEditor } from "@hireroo/app-store/widget/e/QuestionSelectFieldForResourceEditor";
import { ScreeningResourceEditor } from "@hireroo/app-store/widget/e/ScreeningResourceEditor";
import { useLanguageCode } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import { Widget } from "@hireroo/presentation";
import { generateHelpCenterUrl } from "@hireroo/router/api";
import * as React from "react";

import QuestionSelectFieldV2InitialContainer from "../QuestionSelectFieldV2/InitialContainer";

type EntitySources = Widget.ScreeningResourceEditorProps["testQuestionSetupSection"]["entitySources"];

export type GenerateTestQuestionSetupSectionArgs = {
  mode: Widget.ScreeningResourceEditorProps["mode"];
  defaultValues?: Widget.ScreeningResourceEditorProps["testQuestionSetupSection"]["defaultValues"];
};

export const useGenerateTestQuestionSetupSectionProps = (
  args: GenerateTestQuestionSetupSectionArgs,
): Widget.ScreeningResourceEditorProps["testQuestionSetupSection"] => {
  const companyId = Company.useStrictActiveCompanyId();
  const recommendTestTime = QuestionSelectFieldForResourceEditor.useRecommendTestTime();
  const lang = useLanguageCode();
  const questionMap = QuestionSelectFieldForResourceEditor.useQuestionMap();
  const questionTypeLabelMap = useQuestionTypeLabelMap();
  const isAvailableFeature = Payment.useIsAvailableFeature();
  const canCreateBehavioralControl = isAvailableFeature("behavioral-control.create");

  const entitySources = React.useMemo((): EntitySources => {
    return Array.from(questionMap.keys()).reduce<EntitySources>((entitySources, uniqueKey) => {
      const question = questionMap.get(uniqueKey);
      if (!question) {
        return entitySources;
      }
      const title = [resolveLanguage(question, lang, "title"), `(${QuestionSelectFieldForResourceEditor.getVersion(question)})`].join(" ");

      switch (question.__typename) {
        case "SystemDesignQuestion":
          entitySources[uniqueKey] = {
            title: title,
            variant: questionTypeLabelMap.SystemDesignQuestion,
          };
          break;
        case "AlgorithmQuestion":
          entitySources[uniqueKey] = {
            title: title,
            variant: questionTypeLabelMap.AlgorithmQuestion,
          };
          break;
        case "MultiChoicePackage":
          entitySources[uniqueKey] = {
            title: title,
            variant: questionTypeLabelMap.MultiChoicePackage,
          };
          break;
        case "ProjectQuestion":
          entitySources[uniqueKey] = {
            title: title,
            variant: questionTypeLabelMap.ProjectQuestion,
          };
          break;
      }
      return entitySources;
    }, {});
  }, [lang, questionTypeLabelMap, questionMap]);

  return {
    mode: args.mode,
    onSubmit: fields => {
      ScreeningResourceEditor.setSubmitValue("TEST_QUESTION_SETUP", fields);
    },
    timeLimitSettingField: {
      defaultValue: undefined,
      disabled: false,
      radioValueMap: {
        TIGHT: recommendTestTime.tight,
        RECOMMENDED: recommendTestTime.recommend,
        RELAX: recommendTestTime.relax,
        CUSTOM: recommendTestTime.recommend,
      },
    },
    QuestionSelectField: (
      <QuestionSelectFieldV2InitialContainer
        name="entityTracks"
        target="test"
        enabledRestoreQuestionFromQueryParams={args.mode === "CREATE"}
        enableMultiSelectQuestion
      />
    ),
    defaultValues: args.defaultValues || {
      name: "",
      companyId: companyId,
      entityTracks: [],
      timeLimitType: "RECOMMENDED",
      timeLimitMinutes: 30,
      enabledChatGPT: false,
      enabledWebSearch: false,
      enabledHint: false,
    },
    onChangeEntityTackExtractedDataList: extractDataList => {
      QuestionSelectFieldForResourceEditor.updateSelectedUniqueKeys(
        extractDataList.map(extractData => {
          return {
            uniqueKeys: extractData.uniqueKeys,
          };
        }),
      );
    },
    disableFields: {
      companyId: false,
      name: false,
      description: false,
      entityTracks: false,
      timeLimitMinutes: false,
      timeLimitType: false,
      enabledChatGPT: false,
      enabledWebSearch: false,
      enabledHint: false,
    },
    customScoreHelpLink: {
      href: generateHelpCenterUrl(lang, "CUSTOM_SCORE"),
    },
    entitySources: entitySources,
    enableBehavioralControl: canCreateBehavioralControl,
  };
};
