import { SystemDesignTestReport } from "@hireroo/app-store/view-domain/SystemDesignTestReport";
import { TotalScoreRankVisualizer } from "@hireroo/app-store/widget/shared/TotalScoreRankVisualizer";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import { useGenerateHeaderProps } from "../../../../props-factory/v2/view-domain/SystemDesignTestReport/useGenerateHeaderProps";
import { useGenerateQuestionDetailProps } from "../../../../props-factory/v2/view-domain/SystemDesignTestReport/useGenerateQuestionDetailProps";
import SystemDesignTestReportContainer, { SystemDesignTestReportContainerProps } from "./Container";
import { useStatusMessageMap } from "./privateHelper";
import { startSubscribeQueryKey } from "./Subscriber";

export type SystemDesignTestReportFetchContainerProps = {
  featureKind: "test" | "exam";
  companyId: number;
  systemDesignId: number;
  reportSettings: Graphql.AssessmentReportSettings | null;
};

const SystemDesignTestReportFetchContainer: React.FC<SystemDesignTestReportFetchContainerProps> = props => {
  //TODO: @himenon implement get challenge when server side JoinForSpot is removed
  const { companyId, systemDesignId } = props;
  const initialized = SystemDesignTestReport.useInitialized(props.systemDesignId);
  const hooks = SystemDesignTestReport.useCreateSystemDesignHooks(props.systemDesignId);
  const systemDesign = hooks.useSystemDesign();
  const question = hooks.useQuestion();
  const currentSubmission = hooks.useCurrentSubmission();
  const header = useGenerateHeaderProps({ entityId: props.systemDesignId });
  const messageMap = useStatusMessageMap();
  const showNotStartedMessage = systemDesign.systemDesignStatus === "CREATED" || systemDesign.systemDesignStatus === "STARTED";
  const questionDetail = useGenerateQuestionDetailProps({
    entityId: systemDesignId,
    showAnswer: props.reportSettings?.showAnswer ?? false,
    showArchivedMark: false,
  });

  const uniqueKey = React.useMemo((): SystemDesignTestReport.UniqueKey | null => {
    if (!currentSubmission) {
      return null;
    }
    /**
     * Multiple submissions and unique by EntityId
     */
    return `SystemDesign-${systemDesignId}-${currentSubmission.systemDesignSubmissionId}`;
  }, [currentSubmission, systemDesignId]);

  React.useEffect(() => {
    if (!currentSubmission || !uniqueKey || showNotStartedMessage) {
      return;
    }
    TotalScoreRankVisualizer.createTotalScoreStatisticsState({
      uniqueKey: uniqueKey,
      query: {
        endDate: currentSubmission?.evaluatedAtSeconds ? new Date(currentSubmission.evaluatedAtSeconds * 1000) : null,
      },
    });
    const stop = startSubscribeQueryKey({
      uniqueKey,
      entityId: systemDesign.systemDesignEntityId,
      companyId: companyId,
      submissionId: currentSubmission.systemDesignSubmissionId,
      questionId: question.questionId,
    });
    TotalScoreRankVisualizer.refreshQuery(uniqueKey);
    return () => {
      stop?.();
    };
  }, [companyId, currentSubmission, question.questionId, showNotStartedMessage, systemDesign.systemDesignEntityId, uniqueKey]);

  if (!initialized) {
    return <Widget.Loading kind="TOP" />;
  }

  if (!currentSubmission || !uniqueKey || showNotStartedMessage) {
    const systemDesignProps: Widget.SystemDesignTestReportProps = {
      header: header,
      questionDetail: questionDetail,
      announcement: messageMap[systemDesign.systemDesignStatus],
    };
    return <Widget.SystemDesignTestReport {...systemDesignProps} />;
  }

  const containerProps: SystemDesignTestReportContainerProps = {
    featureKind: props.featureKind,
    entityId: props.systemDesignId,
    companyId,
    uniqueKey,
    reportSettings: props.reportSettings,
  };
  return (
    <ErrorBoundary>
      <SystemDesignTestReportContainer {...containerProps} />
    </ErrorBoundary>
  );
};

SystemDesignTestReportFetchContainer.displayName = "SystemDesignTestReportFetchContainer";

export default withErrorBoundary(SystemDesignTestReportFetchContainer, {});
