export const findBiggerClosestIndex = (marks: number[], targetValue: number) => {
  const result = marks.reduce(
    (acc, current, index) => {
      if (current > targetValue && (current - targetValue < acc.difference || acc.difference === -1)) {
        return { difference: current - targetValue, index: index };
      }
      return acc;
    },
    { difference: -1, index: -1 },
  );
  return result.index;
};

export const findSmallerClosestIndex = (marks: number[], targetValue: number) => {
  const result = marks.reduce(
    (acc, current, index) => {
      if (current < targetValue && (targetValue - current < acc.difference || acc.index === -1)) {
        return { difference: targetValue - current, index: index };
      }
      return acc;
    },
    { difference: Infinity, index: -1 },
  );
  return result.index;
};
