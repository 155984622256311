import { DeepReadonly } from "../types";
import { BehavioralEvent, PlaybackTickEvent } from "./types";

export const isBehavioralEvent = (tickEvent: DeepReadonly<PlaybackTickEvent>): tickEvent is BehavioralEvent => {
  return (
    tickEvent.kind === "CHATGPT_REQUEST" ||
    tickEvent.kind === "CHATGPT_RESPOND" ||
    tickEvent.kind === "CHATGPT_RESET" ||
    tickEvent.kind === "WEB_SITE_SEARCH" ||
    tickEvent.kind === "EXTERNAL_WEB_SITE_ACCESS"
  );
};
