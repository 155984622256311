import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type ListContentProps = {
  title: string;
  content: React.ReactNode;
};

const ListContent: React.FC<ListContentProps> = props => {
  return (
    <ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
      <ListItemText
        primary={
          <Grid container wrap="nowrap" alignItems="start">
            <Grid item xs={2} p={0}>
              <Typography variant="body2">{props.title}</Typography>
            </Grid>
            <Grid item xs={10} sx={{ overflowWrap: "anywhere" }}>
              {typeof props.content === "string" && (
                <Typography variant="body2" whiteSpace="pre-wrap" fontWeight={700}>
                  {props.content}
                </Typography>
              )}
              {typeof props.content !== "string" && props.content}
            </Grid>
          </Grid>
        }
        sx={{ margin: 0 }}
      />
    </ListItem>
  );
};

ListContent.displayName = "ListContent";

export default ListContent;
