import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import * as z from "zod";

export const useTagsFormSchema = () => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  return z.object({
    /**
     * superRefine is used intentionally not string().max()
     * because tags is an array and error message will be an array too.
     * To avoid that, superRefine is used.
     */
    tags: z.array(z.string()).superRefine((val, ctx) => {
      const MAX_SIZE = 30;
      const hasIssue = val.some(tag => tag.length > MAX_SIZE);
      if (hasIssue) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t2("ValidateMaxTextSizeMessage", {
            size: MAX_SIZE,
            name: t("タグ"),
          }),
        });
      }
    }),
  });
};

export type TagsSchema = z.infer<ReturnType<typeof useTagsFormSchema>>;
