import { AlgorithmSignatureForm } from "@hireroo/validator";
import * as React from "react";

type AlgorithmSignatureProps = {
  variant: "ALGORITHM";
  signature: AlgorithmSignatureForm.AlgorithmSignatureSchemaType;
};
type DatabaseSignatureProps = {
  variant: "DATABASE";
  signature: AlgorithmSignatureForm.DatabaseSignatureSchemaType;
};
type ClassSignatureProps = {
  variant: "CLASS";
  signature: AlgorithmSignatureForm.ClassSignatureSchemaType;
};

type SignatureProps = AlgorithmSignatureProps | DatabaseSignatureProps | ClassSignatureProps;

export type ChallengeCodingEditorContextProps = {
  signatureProps: SignatureProps;
};
export type Kind = "ANSWER_SCREEN" | "QUESTION_SCREEN";

type TestCaseForConsole = {
  inputs: string[];
  isHidden?: boolean;
};

export type ContextValue = {
  contextProps: ChallengeCodingEditorContextProps;
  selectedTestCaseIndex: number;
  updateSelectedTestCaseIndex: (index: number) => void;
  testCases: TestCaseForConsole[];
  updateTestCases: (testcases: TestCaseForConsole[]) => void;
  resetTestCases: () => void;
  selectedLanguage: string;
  updateSelectedLanguage: (language: string) => void;
  isLanguageChanged: boolean;
  setIsLanguageChanged: (changed: boolean) => void;
  kind: Kind;
};

export const ChallengeCodingEditorContext = React.createContext<ContextValue>({} as ContextValue);

export const useChallengeCodingEditorContext = () => React.useContext(ChallengeCodingEditorContext);

export type ChallengeCodingEditorProviderProps = {
  contextProps: ChallengeCodingEditorContextProps;
  testCasesForConsole: TestCaseForConsole[];
  /**
   * default runtime language
   */
  defaultLanguage: string;
  kind: Kind;
};

export const ChallengeCodingEditorProvider: React.FC<React.PropsWithChildren<ChallengeCodingEditorProviderProps>> = props => {
  const { testCasesForConsole } = props;
  const [selectedTestCaseIndex, setSelectedTestCaseIndex] = React.useState<number>(0);
  const [testcasesForConsole, setTestcasesForConsole] = React.useState<TestCaseForConsole[]>(testCasesForConsole);
  // For runtime language
  const [selectedLanguage, setSelectedLanguage] = React.useState<string>(props.defaultLanguage);
  const [isLanguageChanged, setIsLanguageChanged] = React.useState<boolean>(false);

  const contextValue: ContextValue = {
    contextProps: props.contextProps,
    selectedTestCaseIndex: selectedTestCaseIndex,
    updateSelectedTestCaseIndex: React.useCallback(index => setSelectedTestCaseIndex(index), []),
    testCases: testcasesForConsole,
    updateTestCases: React.useCallback(testcases => setTestcasesForConsole(testcases), []),
    resetTestCases: React.useCallback(() => {
      setTestcasesForConsole(testCasesForConsole);
    }, [testCasesForConsole]),
    selectedLanguage: selectedLanguage,
    updateSelectedLanguage: React.useCallback(language => {
      setSelectedLanguage(language);
    }, []),
    isLanguageChanged: isLanguageChanged,
    setIsLanguageChanged: setIsLanguageChanged,
    kind: props.kind,
  };
  return <ChallengeCodingEditorContext.Provider value={contextValue} children={props.children} />;
};
