import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type EntryScreeningContainerProps = {
  invitationCode: string | null;
};

const EntryScreeningContainer: React.FC<EntryScreeningContainerProps> = props => {
  const entryScreeningProps = useGenerateProps({
    invitationCode: props.invitationCode,
  });
  return (
    <ErrorBoundary>
      <Pages.EntryScreening {...entryScreeningProps} />
    </ErrorBoundary>
  );
};

EntryScreeningContainer.displayName = "EntryScreeningContainer";

export default withErrorBoundary(EntryScreeningContainer, {});
