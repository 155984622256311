import { AssessmentsIdStore } from "@hireroo/app-store/page/t/assessments_id";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { calculateTimeLimit, formatMinutes, unixToDateFormat } from "@hireroo/formatter/time";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import type * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Pages } from "@hireroo/presentation";
import { generateHelpCenterUrl } from "@hireroo/router/api";
import * as React from "react";

import SnackbarContainer from "../../../../../../widget/v2/shared/Snackbar/Container";
import FooterContainer from "../../../../../../widget/v2/t/Footer/Container";
import AssessmentTalentHeaderContainer from "../AssessmentTalentHeader/Container";

export type GenerateEntryAssessmentPropsArgs = {
  exam: Graphql.ExamForEntryAssessmentsIdFragment;
};

//TODO: change to assessment
export const useGenerateProps = ({ exam }: GenerateEntryAssessmentPropsArgs): Pages.EntryAssessmentProps => {
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const client = getGraphqlClient();
  const [startExamFetchStatus, setStartExamFetchStatus] = React.useState<"READY" | "PENDING">("READY");
  return {
    layout: {
      Header: <AssessmentTalentHeaderContainer />,
      //TODO: fix
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      Tutorial: null,
    },
    entryAssessmentOverviewSection: {
      /** TODO */
      name: "",
      companyName: exam.company?.name || t("会社名不明"),
      willEndAt: unixToDateFormat(exam.willEndAtSeconds ?? 0),
      questionNum: exam.entityCount,
      timeLimit: formatMinutes(calculateTimeLimit(exam.willEndAtSeconds ?? 0, exam.timeLimitSeconds ?? 0), lang),
      message: exam.messageForTalent || "",
    },
    enableEmailField: false,
    onboarding: {
      tosUrl: generateHelpCenterUrl(lang, "TOS_FOR_CANDIDATE"),
    },
    confirmForm: {
      acceptButton: {
        disabled: startExamFetchStatus === "PENDING",
      },
      onSubmit: _fields => {
        setStartExamFetchStatus("READY");
        client
          .StartExam({
            input: {
              examId: exam.examId,
            },
          })
          .then(res => {
            if (res.startExam) {
              AssessmentsIdStore.setExam(res.startExam);
            }
          })
          .catch(() => {
            Snackbar.notify({
              severity: "error",
              message: t("テストの開始に失敗しました。お手数ですがヘルプセンターより運営にお問い合わせください。"),
            });
          })
          .finally(() => {
            setStartExamFetchStatus("READY");
          });
      },
    },
  };
};
