import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type InvoicesContainerProps = {};

const InvoicesContainer: React.FC<InvoicesContainerProps> = () => {
  const invoicesProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.Invoices {...invoicesProps} />
    </ErrorBoundary>
  );
};

InvoicesContainer.displayName = "InvoicesContainer";

export default withErrorBoundary(InvoicesContainer, {});
