import { useTranslation } from "@hireroo/i18n";
import { AssessmentResourceEditorForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import BaseDialog, { BaseDialogProps } from "../../../primitive/BaseDialog/BaseDialog";
import AssessmentReportShareSettingField from "../AssessmentReportShareSettingField/AssessmentReportShareSettingField";

export type AssessmentShareSettingDialogProps = {
  title: string;
  open: boolean;
  onSubmit: SubmitHandler<AssessmentResourceEditorForm.AssessmentShareSettingsFormSchema>;
  onClose: () => void;
  disabled: boolean;
  defaultValues: AssessmentResourceEditorForm.AssessmentShareSettingsFormSchema;
};

const AssessmentShareSettingDialog: React.FC<AssessmentShareSettingDialogProps> = props => {
  const { t } = useTranslation();
  const validateSchema = AssessmentResourceEditorForm.useAssessmentShareSettingsFormSchema();
  const methods = useForm({
    resolver: zodResolver(validateSchema),
    defaultValues: props.defaultValues,
  });

  React.useEffect(() => {
    /**
     * Update only when it is opened, because it is unnatural if the state changes while it is open.
     */
    if (props.open) {
      return;
    }
    methods.reset(props.defaultValues);
  }, [props.open, props.defaultValues, methods]);

  const dialog: BaseDialogProps = {
    open: props.open,
    title: props.title,
    yesButton: {
      onClick: () => {
        methods.handleSubmit(props.onSubmit)();
      },
      disabled: props.disabled || !methods.formState.isDirty,
      children: t("更新"),
    },
    noButton: {
      children: t("閉じる"),
      onClick: () => {
        props.onClose();
      },
      disabled: props.disabled,
    },
  };

  return (
    <FormProvider {...methods}>
      <BaseDialog {...dialog}>
        <Box mb={1.5}>
          <Typography color="textSecondary">{t("共有する項目を設定します。")}</Typography>
        </Box>
        <AssessmentReportShareSettingField />
      </BaseDialog>
    </FormProvider>
  );
};

AssessmentShareSettingDialog.displayName = "AssessmentShareSettingDialog";

export default AssessmentShareSettingDialog;
