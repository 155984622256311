import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

const stripe = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

export type PaymentUpdateContainerProps = {};

const PaymentUpdateContainer: React.FC<PaymentUpdateContainerProps> = () => {
  const companySettingsEditorProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Elements stripe={stripe}>
        <Pages.CompanySettingsEditor {...companySettingsEditorProps} />
      </Elements>
    </ErrorBoundary>
  );
};

PaymentUpdateContainer.displayName = "PaymentUpdateContainer";

export default withErrorBoundary(PaymentUpdateContainer, {});
