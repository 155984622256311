import { state } from "./State";
import type * as Types from "./types";

export const addScreenings = (screeningsRes: Types.ScreeningsRes) => {
  screeningsRes.screenings.map(screening => {
    state.screeningMap.set(screening.screeningId, screening);
  });
};

type InitializeArgs = {
  companySpotStats: Types.CompanySpotStats;
  screeningsRes: Types.ScreeningsRes | null;
};

export const initialize = (args: InitializeArgs): void => {
  state.companySpotStats = args.companySpotStats;
  state.screeningsRes = args.screeningsRes;
  if (!args.screeningsRes) return;
  addScreenings(args.screeningsRes);
  const firstScreening = args.screeningsRes.screenings.at(0);
  if (firstScreening) {
    state.selectedScreeningId = firstScreening.screeningId;
  }
};

export const clear = (): void => {
  state.companySpotStats = null;
  state.screeningMap.clear();
  state.selectedScreeningId = null;
  state.selectedScreening = null;
};

export const setSelectedId = (screeningId: Types.ScreeningId) => {
  state.selectedScreeningId = screeningId;
};

export const initializeSelectedScreening = (screening: Types.Screening) => {
  state.selectedScreening = screening;
};
