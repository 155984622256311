import { App } from "@hireroo/app-store/essential/employee";
import { EmployeeSideBarContent } from "@hireroo/app-store/widget/e/EmployeeSideBarContent";
import type { Pages } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as React from "react";

import EmployeeHeaderRightContentContainer from "../../../../widget/v2/e/EmployeeHeaderRightContent/Container";
import EmployeeSideBarContentContainer from "../../../../widget/v2/e/EmployeeSideBarContent/Container";
import EvaluationMetricsGroupsFetchContainer from "../../../../widget/v2/e/EvaluationMetricsGroups/FetchContainer";
import FooterContainer from "../../../../widget/v2/e/Footer/Container";
import { useNotificationBanner } from "../../../../widget/v2/e/NotificationBanner/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";

export type GenerateEvaluationMetricGroupsPropsArgs = {};

export const useGenerateProps = (_args: GenerateEvaluationMetricGroupsPropsArgs): Pages.EvaluationMetricsProps => {
  const appStatus = App.useStatus();
  const navigate = useTransitionNavigate();
  const NotificationBannerContainer = useNotificationBanner();

  return {
    layout: {
      openSidebar: EmployeeSideBarContent.useOpenSidebar(),
      onChangeOpenSidebar: open => {
        EmployeeSideBarContent.setOpenSidebar(open);
      },
      loading: appStatus === "INITIALIZING",
      NotificationBanner: NotificationBannerContainer,
      HeaderRightContent: <EmployeeHeaderRightContentContainer />,
      SideBarContent: <EmployeeSideBarContentContainer />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      sideBarHeader: {
        logo: {
          href: generatePath("/e/home"),
          onClick: () => {
            navigate("/e/home");
          },
        },
      },
    },
    children: <EvaluationMetricsGroupsFetchContainer />,
  };
};
