import { Company } from "@hireroo/app-store/essential/employee";
import { Pages } from "@hireroo/presentation/legacy";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type QuizResourceEditorContainerProps = {};

const QuizResourceEditorContainer: React.FC<QuizResourceEditorContainerProps> = () => {
  const companyId = Company.useActiveCompanyId();
  const props = useGenerateProps({
    companyId: companyId ?? 0,
  });

  return (
    <ErrorBoundary>
      <Pages.QuizResourceEditor {...props} />
    </ErrorBoundary>
  );
};

QuizResourceEditorContainer.displayName = "QuizResourceEditorContainer";

export default withErrorBoundary(QuizResourceEditorContainer, {});
