import { state } from "./State";
import type * as Types from "./types";

export const setScreening = (screening: Types.Screening): void => {
  state.screening = screening;
};

export const clear = (): void => {
  state.screening = null;
};
