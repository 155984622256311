import { useTranslation } from "@hireroo/i18n";
import { Fields } from "@hireroo/validator";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useController, useFormContext } from "react-hook-form";

import SelectField, { SelectFieldProps } from "../../../../../primitive/InputControl/SelectField/SelectField";
import NumberCustomField, { NumberCustomFieldProps } from "./parts/NumberCustomField/NumberCustomField";
import StringCustomField, { StringCustomFieldProps } from "./parts/StringCustomField/StringCustomField";

const StyledSelectField = styled(SelectField)(({ theme }) => ({
  height: 40,
  width: 220,
  color: theme.palette.text.secondary,
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&.MuiOutlinedInput-root": {
    backgroundColor: theme.palette.Other.FilledInputBG,
  },
}));

type SelectFieldValue = "INPUT_TEXT-STRING" | "TEXTAREA-STRING" | "INPUT_NUMBER-INTEGER";

type SelectFieldItem = {
  text: string;
  value: SelectFieldValue;
};

export type FieldFactoryProps = {
  name: string;
  onDelete: () => void;
  defaultFieldValue: SelectFieldValue;
};

const FieldFactory: React.FC<FieldFactoryProps> = props => {
  const { name, onDelete } = props;
  const { t } = useTranslation();
  const methods = useFormContext();
  const { field } = useController<Record<string, Fields.CustomField.CustomField>>({
    name: props.name,
  });

  const [value, setSelectValue] = React.useState<SelectFieldValue>(props.defaultFieldValue);
  const items: SelectFieldItem[] = [
    {
      text: t("短文回答"),
      value: "INPUT_TEXT-STRING",
    },
    {
      text: t("長文回答"),
      value: "TEXTAREA-STRING",
    },
    {
      text: t("数値（整数）"),
      value: "INPUT_NUMBER-INTEGER",
    },
  ];

  const selectFieldProps: SelectFieldProps = {
    defaultValue: props.defaultFieldValue,
    color: "secondary",
    variant: "outlined",
    items: items,
    onChange: event => {
      const selectFieldValue = event.target.value as SelectFieldValue;
      setSelectValue(selectFieldValue);
      const currentFieldValue = methods.getValues(props.name) as Fields.CustomField.CustomField;
      switch (selectFieldValue) {
        case "INPUT_NUMBER-INTEGER": {
          const newValue: Fields.CustomField.NumberValueField = {
            label: currentFieldValue.label,
            description: currentFieldValue.description,
            formType: "INPUT_NUMBER",
            valueType: "INTEGER",
            validationRule: {
              required: false,
              min: 0,
              max: 100,
            },
          };
          field.onChange(newValue);
          return;
        }
        case "INPUT_TEXT-STRING":
        case "TEXTAREA-STRING": {
          const newValue: Fields.CustomField.StringValueField = {
            label: currentFieldValue.label,
            description: currentFieldValue.description,
            formType: selectFieldValue === "INPUT_TEXT-STRING" ? "INPUT_TEXT" : "TEXTAREA",
            valueType: "STRING",
            validationRule: {
              required: false,
              minLength: 0,
              maxLength: 10000,
            },
          };
          field.onChange(newValue);
          return;
        }
        default:
          throw new Error(`SelectFieldValue is invalid value: ${selectFieldValue satisfies never}`);
      }
    },
  };

  const FieldSelect = <StyledSelectField {...selectFieldProps} name={`${name}.type`} />;

  switch (value) {
    case "INPUT_TEXT-STRING": {
      const stringCustomFieldProps: StringCustomFieldProps = {
        name: props.name,
        FieldSelect: FieldSelect,
        formType: "INPUT",
        deleteButton: {
          onClick: () => {
            onDelete();
          },
        },
      };
      return <StringCustomField key={`${name}-${value}`} {...stringCustomFieldProps} />;
    }
    case "TEXTAREA-STRING": {
      const stringCustomFieldProps: StringCustomFieldProps = {
        name: props.name,
        FieldSelect: FieldSelect,
        formType: "TEXTAREA",
        deleteButton: {
          onClick: () => {
            onDelete();
          },
        },
      };
      return <StringCustomField key={`${name}-${value}`} {...stringCustomFieldProps} />;
    }
    case "INPUT_NUMBER-INTEGER": {
      const numberCustomFieldProps: NumberCustomFieldProps = {
        name: props.name,
        FieldSelect: FieldSelect,
        valueType: "INTEGER",
        deleteButton: {
          onClick: () => {
            onDelete();
          },
        },
      };
      return <NumberCustomField key={`${name}-${value}`} {...numberCustomFieldProps} />;
    }
    default:
      throw new Error(`Invalid value: ${value satisfies never}`);
  }
};

FieldFactory.displayName = "FieldFactory";

export default FieldFactory;
