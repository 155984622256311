import { Switch } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { SwitchProps } from "@mui/material/Switch/Switch";
import * as React from "react";
import { Control, Controller } from "react-hook-form";

export type SwitchControlProps = SwitchProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any>;
};

const SwitchControl: React.FC<SwitchControlProps & { name: string }> = props => {
  const { control, name, ...rest } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth error={Boolean(error?.message)}>
          <Switch {...rest} checked={value} value={value} onChange={onChange} />
        </FormControl>
      )}
    />
  );
};

SwitchControl.displayName = "SwitchControl";

export default SwitchControl;
