import { INITIAL_VERSION } from "@hireroo/app-definition/question";
import { App } from "@hireroo/app-store/essential/employee";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

import ErrorPanelContainer from "../../../../../../widget/v2/e/ErrorPanel/Container";
import FreepadQuestionDetailContainer, { FreepadQuestionDetailContainerProps } from "./Container";

export type FreepadQuestionDetailFetchContainerProps = {};

const FreepadQuestionDetailFetchContainer: React.FC<FreepadQuestionDetailFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const { id } = useParams<{ id: string }>();
  const [query] = useSearchParams();
  const questionVersion = query.get("version") ?? INITIAL_VERSION;

  const [result] = Graphql.useFreepadQuestionForQuestionDetailQuery({
    variables: {
      questionId: Number(id),
      questionVersion: questionVersion,
    },
    pause: appStatus !== "INITIALIZED",
  });

  if (result.error) {
    return <ErrorPanelContainer graphqlErrors={result.error.graphQLErrors} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !result.data?.freepadQuestion) {
    return <Widget.Loading kind="CENTER" />;
  }

  const containerProps: FreepadQuestionDetailContainerProps = {
    question: result.data.freepadQuestion,
  };

  return <FreepadQuestionDetailContainer {...containerProps} />;
};

FreepadQuestionDetailFetchContainer.displayName = "FreepadQuestionDetailFetchContainer";

export default withErrorBoundary(FreepadQuestionDetailFetchContainer, {});
