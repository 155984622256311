import { SupportLanguage, SupportLanguageValue, useTranslation } from "@hireroo/i18n";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import * as React from "react";

import InputControlTextField, { InputControlTextFieldProps } from "../../../../primitive/InputControl/InputControlTextField";
import MarkdownPreviewEditor, { MarkdownPreviewEditorProps } from "../../../../usecase/MarkdownPreviewEditor/MarkdownPreviewEditor";
import { useAlgorithmAnswersFormContext } from "../../Context";

const titleMap: Record<SupportLanguageValue, string> = {
  [SupportLanguage.JA]: "タイトル",
  [SupportLanguage.EN]: "title",
};
const descriptionMap: Record<SupportLanguageValue, string> = {
  [SupportLanguage.JA]: "詳細",
  [SupportLanguage.EN]: "description",
};

export type AnswerSummaryTabContextProps = {
  index: number;
  lang: SupportLanguageValue;
  contentIndex: number;
};

const AnswerSummaryTabContext: React.FC<AnswerSummaryTabContextProps> = props => {
  const { t } = useTranslation();
  const { methods } = useAlgorithmAnswersFormContext();

  const titleTextField: InputControlTextFieldProps = {
    variant: "outlined",
    type: "text",
    label: titleMap[props.lang],
    fullWidth: true,
    color: "primary",
    InputLabelProps: {
      shrink: true,
    },
    placeholder: "Fizz Buzz",
    required: true,
    control: methods.control,
    onChange: () => {
      methods.clearErrors(`answers.${props.index}.contents.${props.contentIndex}.title`);
    },
  };
  const descriptionEditor: MarkdownPreviewEditorProps = {
    label: descriptionMap[props.lang],
    helperText: t("問題の本文を入力します。本文はMarkdown形式で入力できます。"),
    placeholder: `ex) ${t("XXX型の引数YYYを受け取り、返り値ZZZを返すような関数を実装します。")}`,
    required: true,
  };

  return (
    <TabContext value={props.lang}>
      <TabPanel key={props.lang} value={props.lang} sx={{ paddingX: 0 }}>
        <Box mt={2} display="flex" flexDirection="column" gap={4}>
          <Box display="flex" gap={2}>
            <InputControlTextField name={`answers.${props.index}.contents.${props.contentIndex}.title`} {...titleTextField} />
          </Box>
          <MarkdownPreviewEditor
            name={`answers.${props.index}.contents.${props.contentIndex}.description`}
            areaSize="md"
            {...descriptionEditor}
          />
        </Box>
      </TabPanel>
    </TabContext>
  );
};

AnswerSummaryTabContext.displayName = "AnswerSummaryTabContext";

export default AnswerSummaryTabContext;
