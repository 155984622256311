import type { LiveCoding } from "@hireroo/app-helper/hooks";
import { Auth } from "@hireroo/app-store/essential/candidate";
import { RemotesId } from "@hireroo/app-store/page/c/remotes_id";
import { RuntimeSelector, RuntimeSelectorProps } from "@hireroo/challenge/react/usecase";
import type * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Pages } from "@hireroo/presentation";
import { Widget } from "@hireroo/presentation";
import * as React from "react";

import RemoteInterviewChallengeCodingEditorContainer, {
  RemoteInterviewChallengeCodingEditorContainerProps,
} from "../../../../widget/v2/shared/RemoteInterviewChallengeCodingEditor/Container";
import RemoteInterviewSystemDesignCodingEditorContainer, {
  RemoteInterviewSystemDesignCodingEditorContainerProps,
} from "../../../../widget/v2/shared/RemoteInterviewSystemDesignCodingEditor/Container";

type TabItem = Pages.RemoteInterviewForCandidateProps["mainContent"]["tab"]["items"][0];

export type GenerateMainContentPropsArgs = {
  collaborativeState: LiveCoding.CollaborativeState;
  collaborativeAction: LiveCoding.CollaborativeAction;
};

export const useGenerateMainContentProps = (args: GenerateMainContentPropsArgs): Pages.RemoteInterviewForCandidateProps["mainContent"] => {
  const uid = Auth.useCurrentUid();
  const { collaborativeAction, collaborativeState } = args;
  const sessions = RemotesId.useNormalizedSessions();
  const activeSessionVariant = RemotesId.useActiveSessionRemoteQuestionVariant();
  const activeSession = RemotesId.useActiveSession();
  const currentSessionId = RemotesId.useActiveSessionId();
  const liveCodingId = RemotesId.useLiveCodingId();
  const liveCodingTemplateCodes = RemotesId.useLiveCodingTemplateCodes();
  const isLiveCodingTemplateCodesFetched = RemotesId.useLiveCodingTemplateCodeFetched();
  const initialCodeMap = RemotesId.useInitialCodeMapForFreepad();
  const initialFlowChartMap = RemotesId.useInitialFlowChartMap();
  const candidateUser = RemotesId.useCandidateUser(uid ?? "");
  const lang = useLanguageCode();

  const defaultValue = React.useMemo((): string => {
    if (activeSession?.questionType === "FREEPAD") {
      return initialCodeMap[collaborativeState.selectedLanguage] ?? "";
    }
    if (!liveCodingTemplateCodes) {
      return "";
    }
    return liveCodingTemplateCodes[collaborativeState.selectedLanguage as RemotesId.LiveCodingTemplateCodeLanguage] || "";
  }, [activeSession?.questionType, collaborativeState.selectedLanguage, initialCodeMap, liveCodingTemplateCodes]);

  const initialFlowChart = React.useMemo((): string => {
    return initialFlowChartMap[collaborativeState.selectedComponentType] || "";
  }, [collaborativeState.selectedComponentType, initialFlowChartMap]);

  const handleChangeLanguage = React.useCallback<Exclude<RuntimeSelectorProps["onChange"], undefined>>(
    language => {
      collaborativeAction.setSelectedLanguageWrapper(language);
    },
    [collaborativeAction],
  );

  const remoteInterviewChallengeCodingEditorContainerProps: RemoteInterviewChallengeCodingEditorContainerProps = {
    collaborativeState,
    uid: candidateUser.userId,
    displayName: candidateUser.userName,
    liveCodingId: liveCodingId,
    defaultValue: defaultValue,
    /**
     * The first user among the participants is allowed to update the initialization
     */
    allowSetInitialValue: collaborativeState.sortedCurrentParticipantUids[0] === uid,
  };

  const remoteInterviewSystemDesignCodingEditorContainerProps: RemoteInterviewSystemDesignCodingEditorContainerProps = {
    collaborativeState,
    collaborativeAction,
    uid: candidateUser.userId,
    displayName: candidateUser.userName,
    liveCodingId: liveCodingId,
    initialFlowChart: initialFlowChart,
  };

  const variantComponentMap: Record<Graphql.RemoteQuestionVariant, React.ReactNode> = {
    ALGORITHM:
      isLiveCodingTemplateCodesFetched && collaborativeState.ready ? (
        // Re-create Instance for each sessionId
        <RemoteInterviewChallengeCodingEditorContainer
          key={currentSessionId?.toString()}
          {...remoteInterviewChallengeCodingEditorContainerProps}
        />
      ) : (
        <Widget.Loading kind="CENTER_%" color="secondary" />
      ),
    DATABASE:
      isLiveCodingTemplateCodesFetched && collaborativeState.ready ? (
        // Re-create Instance for each sessionId
        <RemoteInterviewChallengeCodingEditorContainer
          key={currentSessionId?.toString()}
          {...remoteInterviewChallengeCodingEditorContainerProps}
        />
      ) : (
        <Widget.Loading kind="CENTER_%" color="secondary" />
      ),
    CLASS:
      isLiveCodingTemplateCodesFetched && collaborativeState.ready ? (
        // Re-create Instance for each sessionId
        <RemoteInterviewChallengeCodingEditorContainer
          key={currentSessionId?.toString()}
          {...remoteInterviewChallengeCodingEditorContainerProps}
        />
      ) : (
        <Widget.Loading kind="CENTER_%" color="secondary" />
      ),
    SYSTEM_DESIGN: collaborativeState.ready ? (
      <RemoteInterviewSystemDesignCodingEditorContainer {...remoteInterviewSystemDesignCodingEditorContainerProps} />
    ) : (
      <Widget.Loading kind="CENTER_%" color="secondary" />
    ),
    EMPTY_PAD: collaborativeState.ready ? ( // Re-create Instance for each sessionId
      <RemoteInterviewChallengeCodingEditorContainer
        key={currentSessionId?.toString()}
        {...remoteInterviewChallengeCodingEditorContainerProps}
      />
    ) : (
      <Widget.Loading kind="CENTER_%" color="secondary" />
    ),
    EMPTY_CANVAS: collaborativeState.ready ? (
      <RemoteInterviewSystemDesignCodingEditorContainer {...remoteInterviewSystemDesignCodingEditorContainerProps} />
    ) : (
      <Widget.Loading kind="CENTER_%" color="secondary" />
    ),
    UNKNOWN: null,
  };

  const extraComponentMap: Record<Graphql.RemoteQuestionVariant, React.ReactNode> = {
    ALGORITHM: <RuntimeSelector onChange={handleChangeLanguage} sx={{ bgcolor: "inherit" }} />,
    DATABASE: <RuntimeSelector onChange={handleChangeLanguage} sx={{ bgcolor: "inherit" }} />,
    CLASS: <RuntimeSelector onChange={handleChangeLanguage} sx={{ bgcolor: "inherit" }} />,
    SYSTEM_DESIGN: null,
    EMPTY_PAD: <RuntimeSelector onChange={handleChangeLanguage} sx={{ bgcolor: "inherit" }} />,
    EMPTY_CANVAS: null,
    UNKNOWN: null,
  };

  return {
    tab: {
      items: sessions.map((session): TabItem => {
        return {
          id: session.liveCodingSessionId.toString(),
          label: {
            title: resolveLanguage(session, lang, "title"),
          },
          children: variantComponentMap[session.variant],
          onClick: () => {
            if (currentSessionId) collaborativeAction.setOutSessionWrapper(currentSessionId);
            collaborativeAction.setSelectedSessionWrapper(session.liveCodingSessionId);
          },
        };
      }),
      Extra: extraComponentMap[activeSessionVariant],
      currentTab: (currentSessionId ?? "").toString(),
    },
  };
};
