import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateChallengeCodingEditorWithDetailPropsArgs, useGenerateProps } from "./useGenerateProps";

export type ChallengeCodingEditorContainerProps = GenerateChallengeCodingEditorWithDetailPropsArgs;

const ChallengeCodingEditorWithDetailContainer: React.FC<ChallengeCodingEditorContainerProps> = props => {
  const challengeCodingEditorProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ChallengeCodingEditorWithDetail {...challengeCodingEditorProps} />
    </ErrorBoundary>
  );
};

ChallengeCodingEditorWithDetailContainer.displayName = "ChallengeCodingEditorWithDetailContainer";

export default withErrorBoundary(ChallengeCodingEditorWithDetailContainer, {});
