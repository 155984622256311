import { App } from "@hireroo/app-store/essential/candidate";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import ErrorPanelContainer from "../../../../../../widget/v2/c/ErrorPanel/Container";
import EntryScreeningTestContainer, { EntryScreeningTestContainerProps } from "./Container";

export type EntryInterviewFetchContainerProps = {
  spotId: string;
};

const EntryInterviewFetchContainer: React.FC<EntryInterviewFetchContainerProps> = props => {
  const appStatus = App.useStatus();
  const [spot, setSpot] = React.useState<Graphql.SpotForEntryScreeningsTestsIdFragment | null>(null);
  const [result] = Graphql.useGetSpotForEntryScreeningsTestsIdQuery({
    variables: {
      id: props.spotId,
    },
    pause: appStatus !== "INITIALIZED",
  });
  React.useEffect(() => {
    if (result.data) {
      setSpot(result.data.spot);
    }
  }, [result.data]);

  if (result.error) {
    return <ErrorPanelContainer graphqlErrors={result.error.graphQLErrors} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !spot) {
    return <Widget.Loading kind="TOP" color="secondary" />;
  }

  const containerProps: EntryScreeningTestContainerProps = {
    spot,
  };
  return (
    <ErrorBoundary>
      <EntryScreeningTestContainer {...containerProps} />
    </ErrorBoundary>
  );
};

EntryInterviewFetchContainer.displayName = "EntryInterviewFetchContainer";

export default withErrorBoundary(EntryInterviewFetchContainer, {});
