import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import TablePagination, { TablePaginationProps } from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";
import * as React from "react";

import QuizQuestionCard, { QuizQuestionCardProps } from "./QuizQuestionCard/QuizQuestionCard";

export type QuestionListProps = {
  questionCards: QuizQuestionCardProps[];
  tablePagination: Pick<TablePaginationProps, "page" | "onPageChange" | "count" | "rowsPerPageOptions" | "rowsPerPage">;
};

const ScrollableBox = styled(Box)({
  overflow: "scroll",
  height: "50vh",
});

const QuestionList: React.FC<QuestionListProps> = props => {
  const { t } = useTranslation();
  return (
    <Box>
      <ScrollableBox width="100%">
        {props.questionCards.length > 0 ? (
          <Stack spacing={2}>
            {props.questionCards.map(questionCard => (
              <QuizQuestionCard key={questionCard.id} {...questionCard} />
            ))}
          </Stack>
        ) : (
          <Box p={2}>
            <Typography>{t("検索対象の設問は存在しませんでした。")}</Typography>
          </Box>
        )}
      </ScrollableBox>
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <TablePagination
          {...props.tablePagination}
          component="div"
          labelRowsPerPage={`${t("ページあたりの行数")}:`}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count !== -1 ? count : `${to} ${t("以上")}`}`}
        />
      </Box>
    </Box>
  );
};

QuestionList.displayName = "QuestionList";

export default QuestionList;
