import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Box, { BoxProps } from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type Status = "SUCCESS" | "FAIL";

const StyledCheckCircleIcon = styled(CheckCircleIcon)(({ theme }) => ({
  color: theme.palette.success.light,
  position: "absolute",
  top: 0,
  left: 0,
}));

const StyledCancelIcon = styled(CancelIcon)(({ theme }) => ({
  color: theme.palette.error.light,
  position: "absolute",
  top: 0,
  left: 0,
}));

const StyledBox = styled(Box)(() => ({
  cursor: "pointer",
  position: "relative",
}));

const StyledImage = styled("img")`
  border-radius: 4px;
  width: 100%;
`;

const statusIconMap: Record<Status, React.ReactNode> = {
  SUCCESS: <StyledCheckCircleIcon />,
  FAIL: <StyledCancelIcon />,
};

export type TestResultItemProps = {
  index: number;
  status: Status;
  screenshotUrl: string;
  onClick?: BoxProps["onClick"];
  message: string;
};

const TestResultItem: React.FC<TestResultItemProps> = props => {
  return (
    <Grid item xs={3} minHeight={213}>
      <StyledBox onClick={props.onClick}>
        <StyledImage src={props.screenshotUrl} />
        {statusIconMap[props.status]}
      </StyledBox>

      <Box textAlign="center">
        <Typography variant="body1">{props.message}</Typography>
      </Box>
    </Grid>
  );
};

TestResultItem.displayName = "TestResultItem";

export default TestResultItem;
