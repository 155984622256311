import { state } from "./State";
import type * as Types from "./types";

export const initialize = (res: Types.Response): void => {
  const newMetricGroupsIdsSet: Set<Types.MetricGroupId> = new Set(state.metricGroupsIds);
  res.metricGroups.forEach(metricGroup => {
    state.metricGroupsMap.set(metricGroup.metricGroupId, metricGroup);
    newMetricGroupsIdsSet.add(metricGroup.metricGroupId);
  });
  state.metricGroupsIds = Array.from(newMetricGroupsIdsSet);
  state.pagination = {
    ...state.pagination,
    count: res.count,
    pager: res.pager,
  };
};

export const clear = (): void => {
  state.dialogStatus = "CLOSE";
  state.selectedItemIds = [];
  state.metricGroupsMap.clear();
  state.metricGroupsIds = [];
  state.pagination.page = 0;
  state.pagination.pager = null;
  state.pagination.nextPager = null;
};

export const updateMetricGroup = (metricGroup: Types.MetricGroup): void => {
  state.metricGroupsMap.set(metricGroup.metricGroupId, metricGroup);
  if (!state.metricGroupsIds.includes(metricGroup.metricGroupId)) {
    state.metricGroupsIds.unshift(metricGroup.metricGroupId);
  }
};
export const removeMetricGroup = (metricGroupId: Types.MetricGroupId): void => {
  state.metricGroupsIds = state.metricGroupsIds.filter(id => id !== metricGroupId);
  state.metricGroupsMap.delete(metricGroupId);
  state.pagination.count -= 1;
};
export const removeMetricGroups = (metricGroupIds: Types.MetricGroupId[]): void => {
  state.metricGroupsIds = state.metricGroupsIds.filter(id => !metricGroupIds.includes(id));
  metricGroupIds.forEach(metricGroupId => {
    state.metricGroupsMap.delete(metricGroupId);
  });
  state.pagination.count -= metricGroupIds.length;
};

export const updateDialogStatus = (dialogStatus: Types.DialogStatus): void => {
  state.dialogStatus = dialogStatus;
};

export const clearPage = (): void => {
  state.pagination.page = 0;
};
export const clearSelectedItemIds = (): void => {
  state.selectedItemIds = [];
};

export const addSelectedItemId = (metricGroupId: Types.MetricGroupId) => {
  const selectedSet = new Set(state.selectedItemIds);
  selectedSet.add(metricGroupId);
  state.selectedItemIds = Array.from(selectedSet);
};

export const removeSelectedItemId = (metricGroupId: Types.MetricGroupId) => {
  const selectedSet = new Set(state.selectedItemIds);
  selectedSet.delete(metricGroupId);
  state.selectedItemIds = Array.from(selectedSet);
};

export const updatePager = (): void => {
  if (state.pagination.pager?.hasNext) {
    state.pagination.nextPager = state.pagination.pager;
  }
};

export const updatePage = (page: number) => {
  state.pagination.page = page;
  updatePager();
};

export const updateSize = (size: number) => {
  state.pagination.size = size;
  /**
   * reset page on size change
   */
  state.pagination.page = 0;
};
