// extend permission name for devices
// workaround for https://github.com/microsoft/TypeScript/issues/33923
export const devicePermissionNames = ["camera", "microphone"] as const;
export type DevicePermissionName = (typeof devicePermissionNames)[number];

export type DeviceInfo = {
  audioInputDevices: MediaDeviceInfo[];
  videoInputDevices: MediaDeviceInfo[];
  hasAudioInputDevices: boolean;
  hasVideoInputDevices: boolean;
};
