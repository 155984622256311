import { useSplitContext } from "../Context";

export const useResizeByMinimizeMethod = () => {
  const context = useSplitContext();

  return (params: { splitId: string }) => {
    const currentWrapperSize = context.store.getSplitSize(params.splitId);
    const paneStates = context.store.getPaneStatesBySplitId(params.splitId);
    const contentStates = context.store.getContentStatesBySplitId(params.splitId);
    const totalPaneSize = paneStates.matched.reduce<number>((size, paneState) => {
      return size + paneState.size.value;
    }, 0);
    const remainWidth = contentStates.matched.reduce<number>((width, contentState) => {
      if (contentState.mode === "FIXED") {
        return width - contentState.size.value;
      }
      return width;
    }, currentWrapperSize - totalPaneSize);

    const flexibleContentState = contentStates.matched.reduce<{ totalValue: number; count: number }>(
      (acc, contentState) => {
        if (contentState.mode === "FLEXIBLE") {
          return { totalValue: acc.totalValue + contentState.size.value, count: acc.count + 1 };
        }
        return acc;
      },
      { totalValue: 0, count: 0 },
    );

    let flexibleContentCounter = 0;
    const totalRatio = 0;
    contentStates.matched.forEach(contentState => {
      if (contentState.mode === "FIXED") {
        context.store.setContentSize(contentState.contentId, contentState.size);
      } else {
        flexibleContentCounter += 1;
        const isLastFlexibleContent = flexibleContentCounter === flexibleContentState.count;
        /**
         * To avoid creating margins.
         */
        const ratio = isLastFlexibleContent ? 1 - totalRatio : contentState.size.value / flexibleContentState.totalValue;
        const pixelValue = remainWidth * ratio;
        context.store.setContentSize(contentState.contentId, {
          value: pixelValue,
          unit: "px",
        });
      }
    });
  };
};
