import { SKIP_OPTION_ID } from "@hireroo/app-definition/quiz";
import { QuizRealtimeDB } from "@hireroo/app-helper/hooks";
import { QuizCodingEditor } from "@hireroo/app-store/widget/shared/QuizCodingEditor";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import * as GraphqlClientRequest from "@hireroo/graphql/client/request";
import { useLanguageCode, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/react";
import * as React from "react";

import { shuffleOptions } from "./privateHelper";
import QuizFreeTextEditorContainer, { QuizFreeTextEditorContainerProps } from "./widget/QuizFreeTextEditorContainer/Container";

type QuizCardContentPropsArgs = {
  uid: string;
  quizId: number;
  packageId: number;
  question?: QuizCodingEditor.Question;
  action: QuizRealtimeDB.Action;
  state: QuizRealtimeDB.State;
};

const useQuizCardContentProps = (
  args: QuizCardContentPropsArgs,
): Widget.QuizCodingEditorProps["quizQuestionCard"]["quizQuestionCardContent"] => {
  const lang = useLanguageCode();
  const { question, action: firebaseAction, state: firebaseState } = args;
  const shuffledOptions = React.useMemo(() => {
    return shuffleOptions(question?.options || []);
  }, [question?.options]);

  if (question) {
    if (question.variant === "SINGLE_CHOICE") {
      return {
        kind: "SINGLE_CHOICE",
        content: {
          selectedValue: `${Array.from(firebaseState.selectedOptions)[0]}`,
          description: resolveLanguage(question, lang, "description"),
          items: shuffledOptions.map(option => {
            return {
              title: resolveLanguage(option, lang, "title"),
              description: resolveLanguage(option, lang, "description"),
              value: `${option.id}`,
            };
          }),
          onChange: value => firebaseAction.selectSingleOption(Number(value)),
        },
      };
    }

    if (question.variant === "MULTI_CHOICE") {
      return {
        kind: "MULTI_CHOICE",
        content: {
          description: resolveLanguage(question, lang, "description"),
          items: shuffledOptions.map(option => {
            return {
              id: option.id,
              title: resolveLanguage(option, lang, "title"),
              description: resolveLanguage(option, lang, "description"),
              checked: firebaseState.selectedOptions.has(option.id),
            };
          }),
          skipped: firebaseState.selectedOptions.has(SKIP_OPTION_ID),
          onSelect: value => firebaseAction.selectMultiOption(Number(value)),
          onUnselect: value => firebaseAction.unselectMultiOption(Number(value)),
        },
      };
    }

    if (question.variant === "FREE_TEXT") {
      const editorProps: QuizFreeTextEditorContainerProps = {
        firepad: {
          entityId: args.quizId,
          packageId: args.packageId,
          questionId: question.id,
          uid: args.uid,
          displayName: "",
        },
        description: resolveLanguage(question, lang, "description"),
      };
      return {
        kind: "FREE_TEXT",
        content: <QuizFreeTextEditorContainer key={`${args.quizId}-${args.packageId}-${question.id}`} {...editorProps} />,
      };
    }
  }

  // TODO: Please handle when question is undefined
  Sentry.captureException("Please check the kind of quiz question");
  return {
    kind: "SINGLE_CHOICE",
    content: {
      selectedValue: "",
      description: "",
      items: [],
      onChange: () => void 0,
    },
  };
};

type SubmissionStatus = "NONE" | "SUBMITTED" | "SKIP";

const SUBMISSION_OPTION_MAP: Record<SubmissionStatus, SubmissionStatus> = {
  NONE: "NONE",
  SKIP: "SKIP",
  SUBMITTED: "SUBMITTED",
};

export type GenerateQuizCodingEditorPropsArgs = {
  uid: string;
  entityId: number;
  selectedQuestionId: number;
  action: QuizRealtimeDB.Action;
  state: QuizRealtimeDB.State;
};

export const useGenerateProps = (args: GenerateQuizCodingEditorPropsArgs): Widget.QuizCodingEditorProps => {
  const { action: firebaseAction, state: firebaseState } = args;
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const lang = useLanguageCode();
  const client = GraphqlClientRequest.getGraphqlClient();
  const quizStatus = QuizCodingEditor.useQuizStatus();
  const { useSelectedQuestion, usePackage, useAnswerText, useSubmissionQuestionIdMap } = QuizCodingEditor.useCreateQuizEntityHooks(
    args.entityId,
  );
  const answerText = useAnswerText(args.selectedQuestionId);
  const quizEntityAction = QuizCodingEditor.createQuizEntityAction(args.entityId);

  const quizPackage = usePackage();
  const submittedQuestionIdMap = useSubmissionQuestionIdMap();

  const question = useSelectedQuestion(args.selectedQuestionId);
  const quizCardContentProps = useQuizCardContentProps({
    uid: args.uid,
    quizId: args.entityId,
    packageId: quizPackage?.packageId || 0,
    question: question,
    action: args.action,
    state: firebaseState,
  });

  const nextQuestionId = React.useMemo(() => {
    const questions = quizPackage?.questions;
    if (questions) {
      const index = (questions || []).findIndex(question => question.id === args.selectedQuestionId);
      if (index >= 0 && questions.length - 1 > index) {
        return questions[index + 1].id;
      }
    }
  }, [args.selectedQuestionId, quizPackage?.questions]);

  const isFirstQuestion = React.useMemo(() => {
    const questions = quizPackage?.questions;
    if (!questions || questions.length <= 0) return false;
    return questions[0].id === args.selectedQuestionId;
  }, [args.selectedQuestionId, quizPackage?.questions]);

  const handleBackQuestion = React.useCallback(() => {
    const questions = quizPackage?.questions;
    const index = (questions || []).findIndex(question => question.id === args.selectedQuestionId);
    if (index > 0 && questions) {
      firebaseAction.clearOption();
      const prevQuestion = questions[index - 1];
      firebaseAction.selectQuestion(prevQuestion.id);
    }
  }, [firebaseAction, args.selectedQuestionId, quizPackage?.questions]);

  const calculateQuestionOrderText = React.useMemo(() => {
    if (quizPackage?.questions && question) {
      const nowQuestionIndex = quizPackage.questions.findIndex(all => {
        return `${all.id}-${all.version}` === `${question.id}-${question.version}`;
      });
      return `${t2("CurrentQuestionNumber", {
        number: nowQuestionIndex + 1,
      })} / ${t2("QuizQuestionNumbers", {
        number: quizPackage.questions.length,
      })}`;
    }
    return t("不明");
  }, [question, quizPackage?.questions, t, t2]);

  const submissionStatus = React.useCallback(
    (questionId: number, variant: QuizCodingEditor.Question["variant"]): SubmissionStatus => {
      const optionIds = submittedQuestionIdMap.get(questionId);
      if (!optionIds) return SUBMISSION_OPTION_MAP.NONE;
      if (optionIds.length === 0 && variant === "FREE_TEXT") return SUBMISSION_OPTION_MAP.SUBMITTED;
      if (optionIds.length === 1 && optionIds[0] === SKIP_OPTION_ID) return SUBMISSION_OPTION_MAP.SKIP;
      return SUBMISSION_OPTION_MAP.SUBMITTED;
    },
    [submittedQuestionIdMap],
  );

  const optionIds = React.useMemo(() => {
    const storedOptionIds = Array.from(firebaseState.selectedOptions);
    switch (question?.variant) {
      case "SINGLE_CHOICE":
        return [storedOptionIds[storedOptionIds.length - 1]];
      case "MULTI_CHOICE":
        return storedOptionIds;
      case "FREE_TEXT":
        return [];
      default:
        return [];
    }
  }, [firebaseState.selectedOptions, question]);

  const submitButtonDisabled = React.useMemo(() => {
    if (quizStatus.submit === "LOADING") {
      return true;
    }

    if (question?.variant === "SINGLE_CHOICE" && (firebaseState.selectedOptions.size <= 0 || firebaseState.selectedOptions.size > 1)) {
      return true;
    }

    if (question?.variant === "MULTI_CHOICE" && firebaseState.selectedOptions.size <= 0) {
      return true;
    }

    return false;
  }, [firebaseState.selectedOptions.size, question?.variant, quizStatus.submit]);

  const submitQuestion = React.useCallback(() => {
    if (quizPackage && question) {
      QuizCodingEditor.setSubmitStatus("LOADING");
      client
        .SubmitQuizQuestionForQuizCodingEditor({
          input: {
            quizId: args.entityId,
            packageId: quizPackage?.packageId,
            packageVersion: quizPackage.version,
            questionId: args.selectedQuestionId,
            questionVersion: question.version,
            optionIds: question.variant !== "FREE_TEXT" ? optionIds : [],
            answerText: question.variant === "FREE_TEXT" ? answerText : "",
          },
        })
        .then(res => {
          firebaseAction.submitQuestion(res.submitQuizQuestion.questionId);
          quizEntityAction.setSubmittedQuestionIdWithOptionIds(
            res.submitQuizQuestion.questionId,
            question.variant !== "FREE_TEXT" ? optionIds : [],
          );

          if (nextQuestionId) {
            // Clear option only when there is a screen transition
            firebaseAction.clearOption();
            firebaseAction.selectQuestion(nextQuestionId);
          } else {
            // When there are no nextQuestionId, it is going to move to different entity
            // and finally process won't subscribe change
            QuizCodingEditor.setSubmitStatus("READY");
          }
        })
        .catch(err => {
          Snackbar.notify({
            severity: "error",
            message: t("問題の提出が失敗しました。再度お試しください。"),
          });
          Sentry.captureException(err);
        })
        .finally(() => {
          QuizCodingEditor.setSubmitStatus("READY");
        });
    }
  }, [
    quizPackage,
    question,
    client,
    args.entityId,
    args.selectedQuestionId,
    optionIds,
    answerText,
    firebaseAction,
    quizEntityAction,
    nextQuestionId,
    t,
  ]);

  const questionAnswerInfo = React.useMemo((): Widget.QuizCodingEditorProps["questionAnswerInfo"] => {
    const count = {
      notSubmitted: 0,
      skip: 0,
      submitted: 0,
    };

    for (const question of quizPackage?.questions || []) {
      const status = submissionStatus(question.id, question.variant);
      switch (status) {
        case "NONE": {
          count.notSubmitted += 1;
          break;
        }
        case "SKIP": {
          count.skip += 1;
          break;
        }
        case "SUBMITTED": {
          count.submitted += 1;
          break;
        }
        default:
          throw new Error(`Invalid submit status: ${status satisfies never}`);
      }
    }

    return {
      status: (() => {
        if (count.notSubmitted === 0 && count.skip === 0) {
          return "ALL_SUBMITTED";
        }
        if (count.skip > 0 && count.notSubmitted === 0) {
          return "REMAIN_SKIPPED_QUESTION";
        }
        return "NOT_SUBMITTED";
      })(),
      items: [
        {
          title: t("未提出"),
          value: count.notSubmitted.toString(),
        },
        {
          title: "SKIP",
          value: count.skip.toString(),
        },
        {
          title: t("提出済", {
            context: "quiz",
          }),
          value: count.submitted.toString(),
        },
      ],
    };
  }, [quizPackage?.questions, submissionStatus, t]);

  return {
    status: !firebaseState.ready || !question ? "LOADING" : "READY",
    codingToolbar: {},
    packageTitle: resolveLanguage(quizPackage || {}, lang, "title"),
    quizQuestionCard: {
      remainQuestions: calculateQuestionOrderText,
      quizQuestionCardContent: quizCardContentProps,
    },
    buttonGroup: {
      submitButton: {
        onClick: submitQuestion,
        disabled: submitButtonDisabled,
        children: t("提出して次の問題"),
        size: "medium",
      },
      backButton: {
        onClick: handleBackQuestion,
        disabled: quizStatus.submit === "LOADING" || isFirstQuestion,
        children: t("前の問題"),
        size: "medium",
      },
    },
    questionListSelector: {
      totalNum: quizPackage?.questions.length || 0,
      submittedNum: React.useMemo(() => {
        return (quizPackage?.questions || []).filter(question => submissionStatus(question.id, question.variant) === "SUBMITTED").length;
      }, [quizPackage?.questions, submissionStatus]),
      questionListItems: (quizPackage?.questions || []).map((question, index) => {
        return {
          id: `${question.id}-${question.version}`,
          questionId: args.selectedQuestionId,
          title: resolveLanguage(question, lang, "title"),
          subTitle: t2("CurrentQuestionNumber", {
            number: index + 1,
          }),
          selected: question.id === args.selectedQuestionId,
          status: submissionStatus(question.id, question.variant),
          onClick: () => {
            firebaseAction.clearOption();
            firebaseAction.selectQuestion(question.id);
          },
        };
      }),
    },
    questionAnswerInfo: questionAnswerInfo,
  };
};
