import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import Box, { BoxProps } from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import FormControlLabel, { FormControlLabelProps } from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import * as React from "react";

import BaseDialog, { BaseDialogProps } from "../../primitive/BaseDialog/BaseDialog";

type Kind = "SWITCH" | "BUTTON";

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 32,
  height: 30,
  padding: "4px 10px",
  lineHeight: 1.2,
  textTransform: "none",
  borderColor: theme.palette.divider,
}));

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  ".MuiTypography-root": {
    fontSize: 14,
  },
  "&.MuiFormControlLabel-root": {
    marginLeft: 0,
  },
}));

export type ToggleResourceFieldProps = {
  toggleKind: Kind;
  defaultOpen?: boolean;
  className?: string;
  pt?: BoxProps["pt"];
  pb?: BoxProps["pb"];
  label: string;
  onDiscard?: () => void;
  onOpen?: () => void;
  disabled?: boolean;
};

const ToggleResourceField: React.FC<React.PropsWithChildren<ToggleResourceFieldProps>> = props => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const [open, setOpen] = React.useState(props.defaultOpen);
  const [openRemoveDialog, setOpenRemoveDialog] = React.useState(false);
  const buttonProps: ButtonProps = {
    disabled: props.disabled,
    variant: "outlined",
    color: "outline-only",
    endIcon: open ? <CloseIcon /> : <AddIcon />,
    children: open ? props.label : t2("addField", { fieldLabel: props.label }),
    onClick: () => {
      if (!open) {
        setOpen(true);
      } else {
        setOpenRemoveDialog(true);
      }
    },
  };

  const switchProps: SwitchProps = {
    checked: open,
    color: "secondary",
    size: "small",
    onChange: () => {
      if (!open) {
        setOpen(true);
        props.onOpen?.();
      } else {
        setOpenRemoveDialog(true);
      }
    },
  };
  const formControlLabelProps: FormControlLabelProps = {
    label: open ? props.label : t2("addField", { fieldLabel: props.label }),
    control: <Switch {...switchProps} />,
    disabled: props.disabled,
  };

  const baseDialogProps: BaseDialogProps = {
    title: t("確認"),
    open: openRemoveDialog,
    yesButton: {
      color: "error",
      children: t2("discardField", { fieldLabel: props.label }),
      onClick: () => {
        setOpen(false);
        setOpenRemoveDialog(false);
        props.onDiscard?.();
      },
    },
    noButton: {
      color: "secondary",
      children: t("キャンセル"),
      onClick: () => {
        setOpenRemoveDialog(false);
      },
    },
  };

  return (
    <Box className={props.className} pt={props.pt} pb={props.pb}>
      <Box>
        {props.toggleKind === "BUTTON" && <StyledButton {...buttonProps} />}
        {props.toggleKind === "SWITCH" && <StyledFormControlLabel {...formControlLabelProps} />}
      </Box>
      {open && <Box mt={1.5}>{props.children}</Box>}
      <BaseDialog {...baseDialogProps}>{t2("confirmClearField", { fieldLabel: props.label })}</BaseDialog>
    </Box>
  );
};

ToggleResourceField.displayName = "ToggleResourceField";

export default ToggleResourceField;
