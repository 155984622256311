import { useQuestionVariantLabelMap } from "@hireroo/app-helper/question";
import { DeepReadonly } from "@hireroo/app-helper/types";
import { ScreeningsId } from "@hireroo/app-store/page/c/screenings_id";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useCurrentLanguage } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import { EntryScreeningForm, Fields } from "@hireroo/validator";

export const generateVariablesFormToGraphqlInput = (
  variables: EntryScreeningForm.EntryScreeningFormSchema["variables"],
): Array<Graphql.ScreeningFilledVariableInput> => {
  return Object.values(variables).map((variable): Graphql.ScreeningFilledVariableInput => {
    const type = variable.type;
    switch (type) {
      case "STRING":
        return {
          variableId: variable.variableId,
          value: {
            stringValue: variable.value,
          },
        };
      case "INTEGER":
        return {
          variableId: variable.variableId,
          value: {
            integerValue: variable.value,
          },
        };
      case "FLOAT":
        return {
          variableId: variable.variableId,
          value: {
            floatValue: variable.value,
          },
        };
      case "BOOLEAN":
        return {
          variableId: variable.variableId,
          value: {
            booleanValue: variable.value,
          },
        };
      default:
        throw new Error(`Unknown variable type: ${type satisfies never}`);
    }
  });
};

export const generateGraphqlVariablesToFormDefaultValuesVariables = (
  variables: DeepReadonly<ScreeningsId.Screening["variables"]>,
): EntryScreeningForm.EntryScreeningFormSchema["variables"] => {
  return variables.reduce<EntryScreeningForm.EntryScreeningFormSchema["variables"]>((all, variable) => {
    switch (variable.variableField.__typename) {
      case "ScreeningStringFieldVariable": {
        const stringValue: EntryScreeningForm.DynamicStringValueFieldSchema = {
          variableId: variable.variableId,
          label: variable.label,
          type: "STRING",
          value: "",
        };
        return {
          ...all,
          [variable.label]: stringValue,
        };
      }
      case "ScreeningIntegerFieldVariable": {
        const integerValue: EntryScreeningForm.DynamicIntegerValueFieldSchema = {
          variableId: variable.variableId,
          label: variable.label,
          type: "INTEGER",
          value: undefined,
        };
        return {
          ...all,
          [variable.label]: integerValue,
        };
      }
      case "ScreeningBooleanFieldVariable": {
        const booleanValue: EntryScreeningForm.DynamicBooleanValueFieldSchema = {
          variableId: variable.variableId,
          label: variable.label,
          type: "BOOLEAN",
          value: false,
        };
        return {
          ...all,
          [variable.label]: booleanValue,
        };
      }
      default:
        return all;
    }
  }, {});
};

export const generateGraphqlVariablesToFormVariables = (
  variables: DeepReadonly<ScreeningsId.Screening["variables"]>,
): EntryScreeningForm.VariablesFormSchemaArgs["variables"] => {
  return variables.reduce<Fields.EntryScreeningField.VariableMap>((all, variable) => {
    switch (variable.variableField.__typename) {
      case "ScreeningStringFieldVariable": {
        const stringValue: Fields.EntryScreeningField.StringValueType = {
          label: variable.label,
          type: "STRING",
          value: "",
          validationField: {
            required: variable.variableField.validationRule?.required ?? false,
            min: variable.variableField.validationRule?.min ?? undefined,
            max: variable.variableField.validationRule?.max ?? undefined,
          },
        };
        return {
          ...all,
          [variable.label]: stringValue,
        };
      }
      case "ScreeningIntegerFieldVariable": {
        const integerValue: Fields.EntryScreeningField.IntegerValueType = {
          label: variable.label,
          type: "INTEGER",
          value: 0,
          validationField: {
            required: variable.variableField.validationRule?.required ?? false,
            min: variable.variableField.validationRule?.min ?? undefined,
            max: variable.variableField.validationRule?.max ?? undefined,
          },
        };
        return {
          ...all,
          [variable.label]: integerValue,
        };
      }
      case "ScreeningBooleanFieldVariable": {
        const booleanValue: Fields.EntryScreeningField.BooleanValueType = {
          label: variable.label,
          type: "BOOLEAN",
          value: false,
          validationField: {
            required: variable.variableField.validationRule?.required ?? false,
          },
        };
        return {
          ...all,
          [variable.label]: booleanValue,
        };
      }
      default:
        return all;
    }
  }, {});
};

export const generateSelectableQuestionsDefaultValue = (
  entityTracks: DeepReadonly<Graphql.ScreeningForScreeningsIdFragment["entityTracks"]>,
): EntryScreeningForm.EntryScreeningFormSchema["selectableQuestions"] => {
  return entityTracks.reduce<EntryScreeningForm.EntryScreeningFormSchema["selectableQuestions"]>((all, entityTrack) => {
    switch (entityTrack.__typename) {
      case "ScreeningFixedEntityTrack":
      case "ScreeningRandomFromQuestionsEntityTrack":
        break;
      case "ScreeningSelectableFromQuestionsEntityTrack":
        all[entityTrack.trackId] = {
          trackId: entityTrack.trackId,
          index: null,
        };
        break;
      default: {
        throw new Error(`Invalid EntityTrack: ${entityTrack satisfies never}`);
      }
    }
    return all;
  }, {});
};

type SelectableItemParams = {
  title: string;
  description: string;
};

export const useGenerateSelectableItemParams = () => {
  const lang = useCurrentLanguage();
  const questionVariantLabelMap = useQuestionVariantLabelMap();
  return (
    titlePrefix: string,
    entitySource: Graphql.ScreeningEntitySourceQuestionForScreeningsIdFragment,
    hideTitle: boolean,
  ): SelectableItemParams => {
    switch (entitySource.__typename) {
      case "ScreeningChallengeEntitySourceQuestion": {
        const variant = `CHALLENGE_${entitySource.question.variant}` as const;
        const titleContent = hideTitle ? questionVariantLabelMap.CHALLENGE : resolveLanguage(entitySource.question, lang, "title");
        return {
          title: `${titlePrefix} ${titleContent}`,
          description: variant === "CHALLENGE_UNKNOWN" ? questionVariantLabelMap.CHALLENGE : questionVariantLabelMap[variant],
        };
      }
      case "ScreeningSystemDesignEntitySourceQuestion": {
        const titleContent = hideTitle ? questionVariantLabelMap.SYSTEM_DESIGN : resolveLanguage(entitySource.question, lang, "title");
        return {
          title: `${titlePrefix} ${titleContent}`,
          description: questionVariantLabelMap.SYSTEM_DESIGN,
        };
      }
      case "ScreeningProjectEntitySourceQuestion": {
        const titleContent = hideTitle ? questionVariantLabelMap.PROJECT : resolveLanguage(entitySource.question, lang, "title");
        return {
          title: `${titlePrefix} ${titleContent}`,
          description: ((): string => {
            switch (entitySource.question.projectQuestionVariant) {
              case "Backend":
                return questionVariantLabelMap.PROJECT_BACKEND;
              case "DataScience":
                return questionVariantLabelMap.PROJECT_DATA_SCIENCE;
              case "Default":
                return questionVariantLabelMap.PROJECT;
              case "Frontend":
                return questionVariantLabelMap.PROJECT_FRONTEND;
              case "UNKNOWN":
                return questionVariantLabelMap.PROJECT;
              default:
                throw new Error(`Unsupported Project Variant: ${entitySource.question.projectQuestionVariant satisfies never}`);
            }
          })(),
        };
      }
      case "ScreeningQuizEntitySourcePackage": {
        const titleContent = hideTitle ? questionVariantLabelMap.QUIZ : resolveLanguage(entitySource.pb_package, lang, "title");
        return {
          title: `${titlePrefix} ${titleContent}`,
          description: questionVariantLabelMap.QUIZ,
        };
      }
      default:
        throw new Error(`Invalid EntitySource : ${entitySource satisfies never}`);
    }
  };
};
