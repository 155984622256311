import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import * as React from "react";
import { useSnapshot } from "valtio";

import * as Def from "./definition";
import { state } from "./State";
import type * as Types from "./types";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = () => {
  const snapshot = useSnapshotState();
  return snapshot.initialized;
};

export const useCurrentRole = (): Types.Role => {
  const snapshot = useSnapshotState();
  return snapshot.currentRole;
};

export const useRoleToTextConverter = () => {
  const { t } = useTranslation();
  const displayText: Record<Types.Role, string> = {
    UNKNOWN: t("不明"),
    EMPLOYEE: "",
    ADMIN: t("管理者"),
    MANAGER: t("マネージャー"),
    ENGINEER: t("エンジニア"),
    CANDIDATE: t("候補者"),
  };
  return (role: Types.Role) => displayText[role];
};

export const useMatchingRole = () => {
  const currentRole = useCurrentRole();
  const convert = useRoleToTextConverter();
  const { t: t2 } = useTranslationWithVariable();
  return React.useMemo((): Record<Types.MatchingRoleKey, Types.MatchingRoleValue> => {
    const createMatchingRoleValue = (permission: Def.Permission): Types.MatchingRoleValue => ({
      matched: permission.acceptedRoles.includes(currentRole),
      minimum: permission.minimumRequireRole,
      minimumRoleDisplayText: convert(permission.minimumRequireRole),
      messageOnUnmatched: permission.acceptedRoles.includes(currentRole)
        ? undefined
        : t2("PermissionTooltip", {
            actualRole: convert(currentRole),
            expectedRole: convert(permission.minimumRequireRole),
          }),
    });
    return {
      ADMIN_ONLY: createMatchingRoleValue(Def.ADMIN_ONLY_PERMISSION),
      ADMIN_OR_MANAGER: createMatchingRoleValue(Def.ADMIN_MANAGER_PERMISSION),
      ADMIN_OR_MANAGER_OR_ENGINEER: createMatchingRoleValue(Def.ADMIN_MANAGER_ENGINEER_PERMISSION),
      ADMIN_OR_MANAGER_OR_ENGINEER_OR_EMPLOYEE: createMatchingRoleValue(Def.ADMIN_MANAGER_ENGINEER_EMPLOYEE_PERMISSION),
    };
  }, [convert, currentRole, t2]);
};

export const useHasRole = (roles: Types.Role[]): boolean => {
  const currentRole = useCurrentRole();
  return roles.includes(currentRole);
};

export const useRoleToText = (role: Types.Role) => {
  const convert = useRoleToTextConverter();
  return convert(role);
};

export const useCurrentRoleDisplayText = (): string => {
  const currentRole = useCurrentRole();
  return useRoleToText(currentRole);
};
