import { ITextModel } from "@hireroo/code-editor/react/CodeEditor";
import type * as monaco from "monaco-editor";

const CLASS_NAME = "matchedTextDecorator";
const CLASS_NAME_SELECTED = "selectedMatchedTextDecorator";

export type Side = "left" | "right";

/** Selection's position starts from 0 */
export interface Selection {
  startRow: number;
  startCol: number;
  endRow: number;
  endCol: number;
}

/** TargetPosition starts from 1 */
interface TargetPosition {
  lineNumber: number;
  column: number;
}

type MonacoType = typeof monaco;

export type MatchFragment = {
  left: Selection | null;
  right: Selection | null;
};

export function isPositionBetween(selection: Selection | null, targetPos: TargetPosition) {
  if (!selection) {
    return false;
  }

  const { startRow, startCol, endRow, endCol } = selection;

  /** Add 1 to selection from detection service in order to match with monaco's range */
  // when selection is on one row
  if (targetPos.lineNumber === startRow + 1 && targetPos.lineNumber === endRow + 1) {
    if (targetPos.column >= startCol + 1 && targetPos.column <= endCol + 1) {
      return true;
    }
    return false;
  }

  // when selection is multi rows
  // between start row and end row
  if (targetPos.lineNumber > startRow + 1 && targetPos.lineNumber < endRow + 1) {
    return true;
  }
  // selection is on start row
  if (targetPos.lineNumber === startRow + 1 && targetPos.column >= startCol + 1) {
    return true;
  }
  // selection is on end row
  if (targetPos.lineNumber === endRow + 1 && targetPos.column <= endCol + 1) {
    return true;
  }

  return false;
}

export function decorateFragments(
  monaco: MonacoType,
  model: ITextModel,
  oldDecorations: string[],
  side: Side,
  fragments: MatchFragment[],
  selectedSelection: MatchFragment[] | null,
): string[] {
  /** reset decoration */
  model.deltaDecorations(oldDecorations, [
    {
      range: new monaco.Range(0, 0, 10000, 10000),
      options: {},
    },
  ]);

  let newDecorations: string[] = [];

  /** Add 1 to selection from detection service in order to match with monaco's range */
  selectedSelection?.forEach(selection => {
    const selectionFromSide = selection[side];
    if (selectionFromSide) {
      const range = new monaco.Range(
        selectionFromSide.startRow + 1,
        selectionFromSide.startCol + 1,
        selectionFromSide.endRow + 1,
        selectionFromSide.endCol + 1,
      );
      const d = model.deltaDecorations(
        [],
        [
          {
            range: range,
            options: {
              className: CLASS_NAME_SELECTED,
              stickiness: monaco.editor.TrackedRangeStickiness.NeverGrowsWhenTypingAtEdges,
            },
          },
        ],
      );
      newDecorations = newDecorations.concat(d).filter((value, index, array) => {
        return array.indexOf(value) === index;
      });
    }
  });

  /** move initialize logic here to decorate all fragments */
  fragments.forEach(fragment => {
    const fragmentFromSide = fragment[side];
    if (fragmentFromSide) {
      const d = model.deltaDecorations(
        [],
        [
          {
            /** Add 1 to selection from detection service in order to match with monaco's range */
            range: new monaco.Range(
              fragmentFromSide.startRow + 1,
              fragmentFromSide.startCol + 1,
              fragmentFromSide.endRow + 1,
              fragmentFromSide.endCol + 1,
            ),
            options: {
              className: CLASS_NAME,
              stickiness: monaco.editor.TrackedRangeStickiness.NeverGrowsWhenTypingAtEdges,
            },
          },
        ],
      );
      newDecorations = newDecorations.concat(d).filter((value, index, array) => {
        return array.indexOf(value) === index;
      });
    }
  });

  return newDecorations;
}
