import type { FlowSnapshot, GraphPattern } from "../features";

export type { FlowSnapshot, GraphPattern };

export const checkIsPattern = (input: GraphPattern | FlowSnapshot): input is GraphPattern => {
  return "and" in input || "or" in input || "not" in input;
};

export type BooleValue = "and" | "or" | "not";

export const getBool = (input: GraphPattern): BooleValue => {
  return input.and ? "and" : input.or ? "or" : "not";
};

export const getChild = (input: GraphPattern): (GraphPattern | FlowSnapshot)[] => {
  return input.and ?? input.or ?? input.not ?? [];
};
