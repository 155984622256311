import { ChallengeCodingEditor } from "@hireroo/app-store/widget/shared/ChallengeCodingEditor";
import { ChallengeQuestionVariant, initialLanguageMap } from "@hireroo/challenge/definition";
import * as Graphql from "@hireroo/graphql/client/urql";
import type { Widget } from "@hireroo/presentation";

import { useAlgorithmSignature, useClassSignature, useDatabaseSignature, useDefaultCorrectnessTestCases } from "./privateHelper";

const QuestionVariantMap: Record<Graphql.AlgorithmQuestionVariant, ChallengeQuestionVariant> = {
  ALGORITHM: "ALGORITHM",
  DATABASE: "DATABASE",
  CLASS: "CLASS",
  UNKNOWN: "ALGORITHM",
};

export type GenerateChallengeCodingEditorContainerPropsArgs = {
  entityId: number;
  kind: "CANDIDATE" | "EMPLOYEE";
};
type SignatureProps = Widget.ChallengeCodingEditorProviderProps["contextProps"]["signatureProps"];

export const useGenerateChallengeProviderProps = (
  args: GenerateChallengeCodingEditorContainerPropsArgs,
): Widget.ChallengeCodingEditorProviderProps => {
  const challengeEntityHooks = ChallengeCodingEditor.useCreateChallengeEntityHooks(args.entityId);
  const entity = challengeEntityHooks.useEntity();
  const algorithmSignature = useAlgorithmSignature(args.entityId);
  const databaseSignature = useDatabaseSignature(args.entityId);
  const classSignature = useClassSignature(args.entityId);
  const question = challengeEntityHooks.useQuestion();
  const correctnessTestCases = useDefaultCorrectnessTestCases(args.entityId);
  const questionVariant = QuestionVariantMap[question?.variant ?? "ALGORITHM"];

  const signaturePropsMap: Record<ChallengeQuestionVariant, SignatureProps> = {
    ALGORITHM: {
      variant: "ALGORITHM",
      signature: algorithmSignature,
    },
    DATABASE: {
      variant: "DATABASE",
      signature: databaseSignature,
    },
    CLASS: {
      variant: "CLASS",
      signature: classSignature,
    },
  };

  return {
    contextProps: {
      signatureProps: signaturePropsMap[questionVariant],
    },
    testCasesForConsole: correctnessTestCases.reduce<Widget.ChallengeCodingEditorProviderProps["testCasesForConsole"]>((all, testCase) => {
      // Filter hidden test cases
      // TODO: do this process in backend side once the test cases are separated
      if (testCase.is_hidden) return all;
      all.push({
        inputs: testCase.inputs,
        isHidden: testCase.is_hidden,
      });
      return all;
    }, []),
    defaultLanguage: entity?.enabledLanguages[0] ?? initialLanguageMap[questionVariant],
    kind: "ANSWER_SCREEN",
  };
};
