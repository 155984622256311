import { RemoteInterviewOverview } from "@hireroo/app-store/widget/e/RemoteInterviewOverview";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import * as TimeFormatter from "@hireroo/formatter/time";
import { formatTimeDistance } from "@hireroo/formatter/time";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/react";
import React from "react";

import { useGenerateInfoProps } from "./useGenerateInfoProps";
import { useGenerateQuestionsProps } from "./useGenerateQuestionsProps";
import { useGenerateTimelineProps } from "./useGenerateTimelineProps";

export type GenerateRemoteInterviewOverviewPropsArgs = {};

export const useGenerateProps = (_args: GenerateRemoteInterviewOverviewPropsArgs): Widget.RemoteInterviewOverviewProps => {
  const { t } = useTranslation();
  const timeline = useGenerateTimelineProps({});
  const questions = useGenerateQuestionsProps({});
  const info = useGenerateInfoProps({});
  const [openMemoEditor, setOpenMemoEditor] = React.useState(false);
  const interview = RemoteInterviewOverview.useInterview();
  const [resultUpdateRemote, updateRemote] = Graphql.useUpdateRemoteForRemoteInterviewOverviewMutation();
  const lang = useLanguageCode();
  const [now] = React.useState<Date>(new Date());

  const statusInfoProps = React.useMemo((): Widget.RemoteInterviewOverviewProps["statusInfo"] => {
    const interviewStatusMap: Record<Graphql.RemoteStatus, Widget.RemoteInterviewOverviewProps["statusInfo"]> = {
      EVALUATED: {
        severity: interview.isPassed ? "success" : "error",
        message: interview.evaluationComment,
        additionalInfo: {
          text: `${t("評価者")}: ${interview.evaluatedBy?.displayName ?? t("不明")} ${
            interview.evaluatedAtSeconds ? TimeFormatter.unixToDateFormat(interview.evaluatedAtSeconds) : ""
          }`,
        },
      },
      COMPLETED: {
        severity: "info",
        message: t("テストが終了しました。評価を進めましょう。"),
        additionalInfo: {
          text: `${t("終了日")}: ${interview.didEndAtSeconds ? TimeFormatter.unixToDateFormat(interview.didEndAtSeconds) : ""}`,
          passedTime: `${interview.didEndAtSeconds ? formatTimeDistance(new Date(interview.didEndAtSeconds * 1000), now, lang) : ""}`,
        },
      },
      STARTED: undefined,
      CREATED: undefined,
      UNKNOWN: undefined,
    };

    return interviewStatusMap[interview.status];
  }, [interview, t, now, lang]);

  return {
    timeline: timeline,
    info: info,
    questions: questions,
    statusInfo: statusInfoProps,
    memoEditor: {
      open: openMemoEditor,
      onOpen: () => {
        setOpenMemoEditor(true);
      },
      onClose: () => {
        setOpenMemoEditor(false);
      },
      memo: interview.memo ?? "",
      onSubmit: fields => {
        updateRemote({
          input: {
            remoteId: interview.id,
            memo: fields.memo,
          },
        }).then(result => {
          if (result.data?.updateRemote) {
            RemoteInterviewOverview.updateInterview(result.data.updateRemote);
            setOpenMemoEditor(false);
            Snackbar.notify({
              severity: "success",
              message: t("テスト情報を更新しました。"),
            });
          } else if (result.error) {
            Snackbar.notify({
              severity: "error",
              message: t("テストの更新に失敗しました。"),
            });
            Sentry.captureException(result.error);
          }
        });
      },
      disabled: resultUpdateRemote.fetching,
    },
  };
};
