import { useLanguageCode } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import { generateHelpCenterUrl } from "@hireroo/router/api";
import type { ScreeningResourceEditorForm } from "@hireroo/validator";

import { useGenerateConfirmSectionProps } from "./useGenerateConfirmSectionProps";
import { useGenerateReportSetupSectionProps } from "./useGenerateReportSetupSectionProps";
import { useGenerateTestInviteSetupSectionProps } from "./useGenerateTestInviteSetupSectionProps";
import { useGenerateTestQuestionSetupSectionProps } from "./useGenerateTestQuestionSetupSection";
import { useGenerateTestSetupSectionProps } from "./useGenerateTestSetupSectionProps";

export type GenerateScreeningResourceEditorPropsArgs = {
  minAcceptableLimit?: number;
  mode: Widget.ScreeningResourceEditorProps["mode"];
  defaultValues?: ScreeningResourceEditorForm.CreateScreeningV2FormSchema;
  loading: boolean;
  onSubmit: (fields: ScreeningResourceEditorForm.CreateScreeningV2FormSchema) => void;
};

export const useGenerateProps = (args: GenerateScreeningResourceEditorPropsArgs): Widget.ScreeningResourceEditorProps => {
  const lang = useLanguageCode();
  const testQuestionSetupSectionProps = useGenerateTestQuestionSetupSectionProps({
    mode: args.mode,
    defaultValues: args.defaultValues?.testQuestionSetup,
  });
  const testSetupSectionProps = useGenerateTestSetupSectionProps({
    mode: args.mode,
    defaultValues: args.defaultValues?.testSetup,
    minAcceptableLimit: args.minAcceptableLimit,
  });
  const reportSetupSectionProps = useGenerateReportSetupSectionProps({
    mode: args.mode,
    defaultValues: args.defaultValues?.reportSetup,
  });
  const testInviteSetupSectionProps = useGenerateTestInviteSetupSectionProps({
    mode: args.mode,
    defaultValues: args.defaultValues?.testInviteSetup,
  });
  const confirmSectionProps = useGenerateConfirmSectionProps({
    mode: args.mode,
  });
  return {
    mode: args.mode,
    testQuestionSetupSection: testQuestionSetupSectionProps,
    testSetupSection: testSetupSectionProps,
    reportSetupSection: reportSetupSectionProps,
    testInviteSetupSection: testInviteSetupSectionProps,
    confirmSection: confirmSectionProps,
    onSubmit: args.onSubmit,
    layout: {
      loading: args.loading,
      helpLink: {
        href: generateHelpCenterUrl(lang, "SCREENING_CREATE"),
      },
    },
  };
};
