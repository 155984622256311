import { BehavioralEvent, BrowserHiddenEvent, PlaybackTick, PlaybackTickEvent } from "@hireroo/app-helper/playback";
import { DeepReadonly } from "@hireroo/app-helper/types";

import type * as Types from "./types";

export const isBehavioralEvent = (tickEvent: DeepReadonly<PlaybackTickEvent>): tickEvent is BehavioralEvent => {
  return (
    tickEvent.kind === "CHATGPT_REQUEST" ||
    tickEvent.kind === "CHATGPT_RESPOND" ||
    tickEvent.kind === "CHATGPT_RESET" ||
    tickEvent.kind === "WEB_SITE_SEARCH" ||
    tickEvent.kind === "EXTERNAL_WEB_SITE_ACCESS"
  );
};

export type EventTimelineMap = Record<string, Types.EventTimeline | undefined>;

export const generateEventTimelineMapByTicks = (ticks: DeepReadonly<PlaybackTick[]>): EventTimelineMap => {
  const eventTimelineMap: EventTimelineMap = {};
  const lastTickIndex = ticks.length - 1;
  let previousWebSearchHiddenEvent: BrowserHiddenEvent | null = null;
  ticks.forEach((tick, currentTickIndex) => {
    const isFinalIndex = lastTickIndex === currentTickIndex;
    /**
     * Fill in the stop time of one previous event.
     */
    Object.keys(eventTimelineMap).forEach(key => {
      const intervals = eventTimelineMap[key]?.intervals;
      if (!intervals) {
        return;
      }
      const lastInterval = intervals.at(intervals.length - 1);
      if (lastInterval && Number.isNaN(lastInterval.endTs)) {
        intervals[intervals.length - 1] = {
          ...lastInterval,
          endTs: tick.ts,
        };
      }
    });
    /**
     * Exit loop because there is no closing tick for the last tick event
     */
    if (isFinalIndex) {
      return;
    }
    /** Always Ignore CHATGPT_RESPOND */
    const visitedKindSet = new Set<PlaybackTickEvent["kind"]>(["CHATGPT_RESPOND"]);
    /**
     * Record events that occurred at the current tick
     */
    tick.events.forEach(event => {
      if (visitedKindSet.has(event.kind)) {
        return;
      }
      visitedKindSet.add(event.kind);

      /**
       * BROWSER_HIDDEN event whose kind is GOOGLE_SEARCH is handled as web search event
       * That's why intervals is not updated here if condition is satisfied.
       * */
      if (event.kind === "BROWSER_HIDDEN" && event.reason === "GOOGLE_SEARCH") {
        previousWebSearchHiddenEvent = event;
        return;
      }
      if (event.kind === "BROWSER_FOCUS" && previousWebSearchHiddenEvent) {
        const lastWebAccessInterval = eventTimelineMap.EXTERNAL_WEB_SITE_ACCESS?.intervals.at(-1);
        if (lastWebAccessInterval) {
          lastWebAccessInterval.endTs = event.ts;
        }
        previousWebSearchHiddenEvent = null;
      }

      const intervals = eventTimelineMap[event.kind]?.intervals || [];
      intervals.push({
        startTs: event.ts,
        endTs: NaN,
      });
      eventTimelineMap[event.kind] = {
        intervals: intervals,
      };
    });
  });
  return eventTimelineMap;
};
