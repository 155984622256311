import { useTranslation } from "@hireroo/i18n";
import * as z from "zod";

export const useGeneralSettingsFormSchema = () => {
  const { t } = useTranslation();
  return z.object({
    companyName: z.string().min(1, { message: t("必須項目です") }),
    websiteUrl: z.string().min(1, { message: t("必須項目です") }),
  });
};

export type GeneralSettingsFormSchema = z.infer<ReturnType<typeof useGeneralSettingsFormSchema>>;
