import { ScreeningsStore } from "@hireroo/app-store/page/e/screenings";
import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import * as Subscriber from "./Subscriber";
import { useGenerateProps } from "./useGenerateProps";

export type ScreeningListContainerProps = {};

const ScreeningListContainer: React.FC<ScreeningListContainerProps> = () => {
  const screeningListProps = useGenerateProps({});
  React.useEffect(() => {
    const stop = Subscriber.startSubscribeListParams();
    return () => {
      stop();
      ScreeningsStore.clear();
    };
  }, []);
  return (
    <ErrorBoundary>
      <Pages.ScreeningList {...screeningListProps} />
    </ErrorBoundary>
  );
};

ScreeningListContainer.displayName = "ScreeningListContainer";

export default withErrorBoundary(ScreeningListContainer, {});
