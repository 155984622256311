import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";

import RuntimeSelector, { RuntimeSelectorProps } from "../RuntimeSelector";
import RuntimeSelectorDialog, { RuntimeSelectorDialogProps } from "../RuntimeSelectorDialog/RuntimeSelectorDialog";
import EditorToolbarSelect, { EditorToolbarSelectProps } from "./parts/EditorToolbarSelect/EditorToolbarSelect";

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette["Secondary/Shades"].p12,
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette["Secondary/Shades"].p12,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

type ShowingCodeKind = "SHOW_INITIAL_CODE" | "SHOW_ANSWER_CODE";

const ShowingCodeKindMap: Record<ShowingCodeKind, ShowingCodeKind> = {
  SHOW_INITIAL_CODE: "SHOW_INITIAL_CODE",
  SHOW_ANSWER_CODE: "SHOW_ANSWER_CODE",
};

export type EditorToolbarProps = {
  runtimeSelector?: RuntimeSelectorProps;
  runtimeSelectorDialog?: RuntimeSelectorDialogProps;
  onChangeShowingCode?: (value: ShowingCodeKind) => void;
};

const EditorToolbar: React.FC<EditorToolbarProps> = props => {
  const { t } = useTranslation();
  const showingCodeSelectProps = React.useMemo((): EditorToolbarSelectProps | undefined => {
    if (!props.onChangeShowingCode) {
      return;
    }
    return {
      defaultValue: ShowingCodeKindMap.SHOW_ANSWER_CODE,
      onChange: value => {
        if (Object.values(ShowingCodeKindMap).includes(value as ShowingCodeKind)) {
          props.onChangeShowingCode?.(value as ShowingCodeKind);
        }
      },
      items: [
        {
          title: t("解答コード"),
          value: ShowingCodeKindMap.SHOW_ANSWER_CODE,
        },
        {
          title: t("初期コード"),
          value: ShowingCodeKindMap.SHOW_INITIAL_CODE,
        },
      ],
    };
  }, [props, t]);
  return (
    <Wrapper>
      <ContentWrapper>
        {props.runtimeSelector && <RuntimeSelector {...props.runtimeSelector} />}
        {props.runtimeSelectorDialog && <RuntimeSelectorDialog {...props.runtimeSelectorDialog} />}
        {showingCodeSelectProps && <EditorToolbarSelect {...showingCodeSelectProps} />}
      </ContentWrapper>
    </Wrapper>
  );
};

EditorToolbar.displayName = "EditorToolbar";

export default EditorToolbar;
