import { useTranslation } from "@hireroo/i18n";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import * as React from "react";

import DivTable from "../../../../primitive/DivTable/DivTable";
import DivTableBody from "../../../../primitive/DivTableBody/DivTableBody";
import DivTableCell from "../../../../primitive/DivTableCell/DivTableCell";
import DivTableHead from "../../../../primitive/DivTableHead/DivTableHead";
import DivTableRow from "../../../../primitive/DivTableRow/DivTableRow";
import InviteeTableRow, { InviteeTableRowProps } from "../InviteeTableRow/InviteeTableRow";

const StyledTableHeaderCell = styled(DivTableCell)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: "normal",
  fontSize: "12px",
  border: "none",
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  height: 40,
  wordBreak: "keep-all",
  whiteSpace: "nowrap",
}));

const StyledTableRow = styled(DivTableRow)(({ theme }) => ({
  borderTop: "1px solid",
  borderBottom: "1px solid",
  borderColor: theme.palette["Gray/Shades"].p8,
}));

export type InviteeTableProps = {
  items: InviteeTableRowProps[];
};

const InviteeTable: React.FC<InviteeTableProps> = props => {
  const { t } = useTranslation();
  return (
    <TableContainer sx={{ overflow: "auto" }}>
      <DivTable>
        <DivTableHead>
          <StyledTableRow>
            <StyledTableHeaderCell>{t("招待メールアドレス")}</StyledTableHeaderCell>
            <StyledTableHeaderCell sx={{ textAlign: "right" }}>{t("ステータス")}</StyledTableHeaderCell>
          </StyledTableRow>
        </DivTableHead>
        <DivTableBody>
          {props.items.map((item, index) => (
            <InviteeTableRow key={`${item.email}-${index}`} {...item} />
          ))}
          {props.items.length === 0 && (
            <StyledTableRow>
              <StyledTableHeaderCell sx={{ display: "table-cell", width: "100%", textAlign: "center", alignItems: "center" }}>
                {t("招待者が見つかりません。")}
              </StyledTableHeaderCell>
            </StyledTableRow>
          )}
        </DivTableBody>
      </DivTable>
    </TableContainer>
  );
};

InviteeTable.displayName = "InviteeTable";

export default InviteeTable;
