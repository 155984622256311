import "./xterm.css";

import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";
import { ITerminalOptions } from "xterm";

import { useProjectContext } from "../../../../../store-v3";

const StyledBox = styled(Box)(() => ({
  "& .terminal.xterm": {
    height: "100%",
  },
  width: "100%",
  height: "100%",
  overflow: "hidden",
}));

const CenteredBox100Percent = styled(Box)`
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export type ProjectConsoleProps = {
  endpoint?: string;
  loading: boolean;
};

const BUFFER_SPACE = 20;

const ProjectConsole: React.FC<ProjectConsoleProps> = props => {
  const theme = useTheme();
  const { endpoint } = props;
  const Store = useProjectContext();
  const { t } = useTranslation();

  const parentRef = React.useRef<HTMLDivElement>(null);
  const terminalRef = React.useRef<HTMLDivElement | null>(null);
  const wrapper = useResizeDetector({
    targetRef: parentRef,
  });

  React.useEffect(() => {
    Store.action.handleResize();
  }, [Store.action, wrapper.height, wrapper.width]);

  React.useEffect(() => {
    if (!endpoint || !terminalRef.current) return;

    const terminalOption: ITerminalOptions = {
      theme: {
        background: theme.palette["Secondary/Shades"].p12,
      },
      allowTransparency: true,
    };
    Store.action.initializeTerminal(endpoint, terminalRef.current, terminalOption);

    return () => {
      Store.action.cleanUpTerminal();
    };
  }, [Store.action, endpoint, theme.palette]);

  const adjustHeight: number = React.useMemo(() => {
    if (!parentRef.current) {
      return 0;
    }
    const rect = parentRef.current.getBoundingClientRect();
    const overhangHeight = rect.y + (wrapper.height ?? 0) - window.innerHeight;
    return Math.ceil(overhangHeight + BUFFER_SPACE);
  }, [wrapper.height]);

  const height = React.useMemo(() => {
    return Math.floor((wrapper.height ?? 0) - adjustHeight);
  }, [adjustHeight, wrapper.height]);

  // specify background color by the time terminal is initialized
  const boxBgColor = endpoint && terminalRef.current ? undefined : theme.palette["Secondary/Shades"].p12;

  return (
    <StyledBox className="terminal-panel" bgcolor={boxBgColor} ref={parentRef}>
      {props.loading && (
        <CenteredBox100Percent>
          <Stack spacing={2} direction="row" alignItems={"center"} justifyContent={"center"}>
            <CircularProgress color="secondary" size="24px" />
            <Typography variant="body2">{t("サーバーとの接続を確立中です。しばらくお待ち下さい。")}</Typography>
          </Stack>
        </CenteredBox100Percent>
      )}
      {!props.loading && (
        <div
          id="terminal"
          ref={terminalRef}
          style={{
            height: "100%",
            width: wrapper.width,
            maxHeight: height,
          }}
        />
      )}
    </StyledBox>
  );
};

ProjectConsole.displayName = "ProjectConsole";

export default ProjectConsole;
