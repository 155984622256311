import { SupportLanguageValue } from "@hireroo/i18n";
import { proxyMap } from "valtio/utils";

import { state } from "./State";
import type * as Types from "./types";

export const initialize = (lang: SupportLanguageValue, initialVariant?: Types.QuestionVariant): void => {
  state.activeStep = 0;
  state.variant = initialVariant ?? "ALGORITHM";
  state.question = undefined;
  state.runCodeOutput = proxyMap();
  state.isRunCodeLoading = proxyMap();
  state.profileCodeOutput = proxyMap();
  state.isProfileCodeLoading = proxyMap();
  state.initialLanguage = lang;
};

export const clear = (): void => {
  state.activeStep = 0;
  state.variant = "ALGORITHM";
  state.question = undefined;
  state.runCodeOutput = proxyMap();
  state.isRunCodeLoading = proxyMap();
  state.profileCodeOutput = proxyMap();
  state.isProfileCodeLoading = proxyMap();
};

export const clearOutput = () => {
  state.runCodeOutput = proxyMap();
  state.isRunCodeLoading = proxyMap();
  state.profileCodeOutput = proxyMap();
  state.isProfileCodeLoading = proxyMap();
};

export const updateQuestion = (newState: Types.Question): void => {
  state.question = {
    ...state.question,
    ...newState,
  };
};

export const updateActiveStep = (step: number) => {
  state.activeStep = step;
};

export const goPrevious = () => {
  if (state.activeStep <= 0) {
    state.activeStep = 0;
  }
  state.activeStep -= 1;
};

export const updateVariant = (newValue: Types.QuestionVariant) => {
  state.variant = newValue;
};

export const updateRunCodeOutput = (requestId: string, newValue: Types.RunCode) => {
  state.runCodeOutput.set(requestId, newValue);
};

export const updateIsRunCodeLoading = (requestId: string, newValue: boolean) => {
  state.isRunCodeLoading.set(requestId, newValue);
};

export const updateProfileCodeOutput = (requestId: string, newValue: Types.ProfileCode) => {
  state.profileCodeOutput.set(requestId, newValue);
};

export const updateIsProfileCodeLoading = (requestId: string, newValue: boolean) => {
  state.isProfileCodeLoading.set(requestId, newValue);
};

export const updateSaveStatus = (status: Types.SaveStatus) => {
  state.saveStatus = status;
};
