import { useEnabledProjectPlayback } from "@hireroo/app-helper/feature";
import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

import ProjectContentsViewerV1Container from "./widget/ProjectContentsViewerV1/Container";
import ProjectContentsViewerV3Container from "./widget/ProjectContentsViewerV3/Container";
import ProjectContentsViewerV4InitialContainer from "./widget/ProjectContentsViewerV4/InitialContainer";

type Mode = "HISTORY" | "PLAYBACK" | "SUBMIT_RESULT";

export type GeneratePlaybackSectionProps = {
  entityId: number;
  uniqueKey: ProjectTestReport.UniqueKey;
  pause: boolean;
  canShowPasteAndTabStatistics: boolean;
};

export const useGeneratePlaybackSectionProps = (args: GeneratePlaybackSectionProps): Widget.ProjectTestReportProps["playbackSection"] => {
  const hooks = ProjectTestReport.useCreateProjectHooks(args.entityId);
  const projectMajorVersion = hooks.useProjectVersion();
  const questionId = hooks.useQuestionId();
  const enabledProjectPlayback = useEnabledProjectPlayback();
  // TODO: add v4 when it is ready
  const isPlaybackAvailable = projectMajorVersion === "V3" && enabledProjectPlayback;
  const [mode, setMode] = React.useState<Mode>(isPlaybackAvailable ? "SUBMIT_RESULT" : "HISTORY");
  const appealMessage = hooks.useAppealMessage();
  const submission = hooks.useCurrentSubmission();

  const ContentModeMap: Record<Mode, React.ReactNode> = {
    HISTORY: typeof questionId === "number" ? <ProjectContentsViewerV3Container projectId={args.entityId} questionId={questionId} /> : null,
    SUBMIT_RESULT:
      typeof questionId === "number" ? <ProjectContentsViewerV3Container projectId={args.entityId} questionId={questionId} /> : null,
    PLAYBACK: enabledProjectPlayback && submission && (
      // TODO: Temporary name. Change name when the scope is clear
      <ProjectContentsViewerV4InitialContainer projectId={args.entityId} canShowPasteAndTabStatistics={args.canShowPasteAndTabStatistics} />
    ),
  };

  const ContentViewerMap: Record<"V1" | "V2" | "V3" | "V4", React.ReactNode> = {
    V1:
      typeof questionId === "number" && mode !== "SUBMIT_RESULT" ? (
        <ProjectContentsViewerV1Container mode={mode} projectId={args.entityId} questionId={questionId} />
      ) : null,
    V2: null,
    V3: ContentModeMap[mode],
    V4: ContentModeMap[mode],
  };

  return {
    playbackModeSwitcher:
      // TODO: Remove this condition when playback v3 has implemented
      // TODO: Add a condition to check created date if the project playback is available
      projectMajorVersion === "V3" && !enabledProjectPlayback
        ? undefined
        : {
            onChangeMode: mode => {
              if (mode === "HISTORY" || mode === "PLAYBACK" || mode === "SUBMIT_RESULT") {
                setMode(mode);
              }
            },
            enableMode: isPlaybackAvailable ? ["SUBMIT_RESULT", "PLAYBACK"] : ["HISTORY", "PLAYBACK"],
          },
    ContentsViewer: ContentViewerMap[projectMajorVersion],
    appealMessageArea: appealMessage
      ? {
          body: appealMessage,
        }
      : undefined,
  };
};
