import * as Graphql from "@hireroo/graphql/client/urql";

export const ResultKeys = {
  PASSED: "passed",
  FAILED: "failed",
} as const;

export const RESULT_REVERSE_MAP: Record<string, boolean> = {
  [ResultKeys.PASSED]: true,
  [ResultKeys.FAILED]: false,
};

export const STATUS_REVERSE_MAP: Record<string, Graphql.RemoteStatus> = {
  [Graphql.RemoteStatus.Unknown]: Graphql.RemoteStatus.Unknown,
  [Graphql.RemoteStatus.Started]: Graphql.RemoteStatus.Started,
  [Graphql.RemoteStatus.Created]: Graphql.RemoteStatus.Created,
  [Graphql.RemoteStatus.Completed]: Graphql.RemoteStatus.Completed,
  [Graphql.RemoteStatus.Evaluated]: Graphql.RemoteStatus.Evaluated,
};

export const SORT_METHOD_REVERS_MAP: Record<string, Graphql.RemoteSortMethod> = {
  [Graphql.RemoteSortMethod.Unknown]: Graphql.RemoteSortMethod.Unknown,
  [Graphql.RemoteSortMethod.CreatedAt]: Graphql.RemoteSortMethod.CreatedAt,
};

/**
 * Maximum number of questions that can be selected
 */
export const MAX_SELECTABLE_QUESTIONS = 10;

export const TAB_PARAMS = {
  OVERVIEW: "overview",
  PLAYBACK: "playback",
  REVIEW: "review",
} as const;
