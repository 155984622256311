import { TAB_PARAMS } from "@hireroo/app-definition/remote";
import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { useTitle } from "@hireroo/app-helper/react-use";
import { Auth, Role } from "@hireroo/app-store/essential/employee";
import { RemotesIdDetailStore } from "@hireroo/app-store/page/e/remotes_id_detail";
import { RemoteInterviewOverview } from "@hireroo/app-store/widget/e/RemoteInterviewOverview";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateCurrentOriginUrl } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/browser";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import RemoteInterviewFeedbackFetchContainer from "../RemoteInterviewFeedback/FetchContainer";
import RemoteInterviewOverviewFetchContainer from "../RemoteInterviewOverview/FetchContainer";
import RemoteInterviewPlaybackFetchContainer from "../RemoteInterviewPlayback/FetchContainer";
import TechnicalCommentPopperContainer from "../TechnicalCommentPopper/Container";
import { isValidReviewStatus } from "./privateHelper";

export type GenerateRemoteInterviewDetailPropsArgs = {};

export const useGenerateProps = (_args: GenerateRemoteInterviewDetailPropsArgs): Widget.RemoteInterviewDetailProps => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const client = getGraphqlClient();
  const currentUid = Auth.useCurrentUid();
  const remote = RemotesIdDetailStore.useInterview();
  const currentRoleDisplayText = Role.useCurrentRoleDisplayText();
  const matchingRole = Role.useMatchingRole();
  const managerRole = Role.useRoleToText("MANAGER");
  const navigate = useTransitionNavigate();
  const currentTab = RemotesIdDetailStore.useCurrentTab();
  const loadingStatus = RemotesIdDetailStore.useLoadingStatus();
  const [isAlertStart, setIsAlertStart] = React.useState<boolean>(false);
  const [isAlertEvaluation, setIsAlertEvaluation] = React.useState<boolean>(false);
  const [isAlertDeletion, setIsAlertDeletion] = React.useState<boolean>(false);
  const [promptOpen, setPromptOpen] = React.useState<boolean>(false);
  const [, setParams] = useSearchParams();
  useTitle(remote.name);
  const refreshOverview = RemoteInterviewOverview.useRefresh();
  const canReview = isValidReviewStatus(remote.status);

  const evaluateButtonTooltipMap: Record<Graphql.RemoteStatus, string> = {
    STARTED: t("試験が終了すると利用可能になります。"),
    CREATED: t("試験が終了すると利用可能になります。"),
    COMPLETED: "",
    EVALUATED: "",
    UNKNOWN: "",
  };

  const handlePromptClose = React.useCallback(() => {
    setPromptOpen(false);
  }, []);

  const disableDelete = remote.status === "STARTED";

  return {
    defaultTabId: currentTab ?? undefined,
    scrollable: currentTab !== TAB_PARAMS.PLAYBACK,
    header: {
      title: remote.name,
      startButton:
        remote.status === "STARTED" || remote.status === "CREATED"
          ? {
              onClick: () => {
                if (remote.status === "STARTED") {
                  navigate("/e/remotes/:id", { pathParams: { id: remote.id } });
                  return;
                }
                setIsAlertStart(true);
              },
              children: remote.status === "STARTED" ? t("入室") : t("開始"),
              startIcon: remote.status === "STARTED" ? "LOGIN" : "PLAY",
            }
          : undefined,
      reviewButton:
        remote.status === "COMPLETED" || remote.status === "EVALUATED"
          ? {
              onClick: () => {
                setPromptOpen(true);
              },
              disabled: !matchingRole.ADMIN_OR_MANAGER_OR_ENGINEER.matched,
              title: matchingRole.ADMIN_OR_MANAGER_OR_ENGINEER.messageOnUnmatched,
            }
          : undefined,
      evaluateButton:
        remote.status === "COMPLETED"
          ? {
              title: evaluateButtonTooltipMap[remote.status]
                ? evaluateButtonTooltipMap[remote.status]
                : matchingRole.ADMIN_OR_MANAGER.messageOnUnmatched,
              disabled: remote.status !== "COMPLETED" || !matchingRole.ADMIN_OR_MANAGER.matched,
              onClick: () => {
                setIsAlertEvaluation(true);
              },
            }
          : undefined,
      moreButton: {
        options: [
          {
            value: "edit",
            displayName: t("編集"),
            onClick: () => {
              navigate("/e/remotes/:id/update", {
                pathParams: {
                  id: remote.id,
                },
              });
            },
            disabled: !matchingRole.ADMIN_OR_MANAGER.matched,
            disabledText: t2("PermissionTooltip", {
              actualRole: currentRoleDisplayText,
              expectedRole: managerRole,
            }),
            divider: true,
            startIcon: "EDIT",
          },
          {
            value: "from-interview",
            displayName: t("インタビューから作成する"),
            onClick: () => {
              navigate("/e/remotes/create", {
                queryParams: {
                  id: remote.id,
                },
              });
            },
            disabled: !matchingRole.ADMIN_OR_MANAGER.matched,
            disabledText: t2("PermissionTooltip", {
              actualRole: currentRoleDisplayText,
              expectedRole: managerRole,
            }),
            divider: true,
            startIcon: "CONTENT_COPY",
          },
          {
            value: "remote-interview-template",
            displayName: t("インタビューテンプレートを作成する"),
            onClick: () => {
              navigate("/e/templates/remote/create", {
                queryParams: {
                  id: remote.id,
                },
              });
            },
            disabled: !matchingRole.ADMIN_OR_MANAGER.matched,
            disabledText: t2("PermissionTooltip", {
              actualRole: currentRoleDisplayText,
              expectedRole: managerRole,
            }),
            divider: true,
            startIcon: "DESCRIPTION",
          },
          {
            value: "delete",
            displayName: t("削除する"),
            color: "error",
            onClick: () => {
              setIsAlertDeletion(true);
            },
            disabled: !matchingRole.ADMIN_OR_MANAGER.matched || disableDelete,
            disabledText: !matchingRole.ADMIN_OR_MANAGER.matched
              ? t2("PermissionTooltip", {
                  actualRole: currentRoleDisplayText,
                  expectedRole: managerRole,
                })
              : t("インタビューを終了するまで削除できません。"),
            divider: true,
            startIcon: "DELETE",
          },
        ],
      },
    },
    items: [
      {
        id: TAB_PARAMS.OVERVIEW,
        Component: <RemoteInterviewOverviewFetchContainer />,
        label: {
          name: t("テスト概要"),
          onClick: () => {
            RemotesIdDetailStore.updateCurrentTab(TAB_PARAMS.OVERVIEW);
            setParams({ tab: TAB_PARAMS.OVERVIEW });
          },
          icon: "DESCRIPTION",
        },
      },
      {
        id: TAB_PARAMS.PLAYBACK,
        Component: <RemoteInterviewPlaybackFetchContainer />,
        label: {
          name: t("プレイバック"),
          onClick: () => {
            RemotesIdDetailStore.updateCurrentTab(TAB_PARAMS.PLAYBACK);
            setParams({ tab: TAB_PARAMS.PLAYBACK });
          },
          icon: "TRENDING_UP",
          disabled: !canReview,
          disabledTooltip: evaluateButtonTooltipMap[remote.status],
        },
      },
      {
        id: TAB_PARAMS.REVIEW,
        Component: <RemoteInterviewFeedbackFetchContainer />,
        label: {
          name: t("技術レビュー"),
          onClick: () => {
            RemotesIdDetailStore.updateCurrentTab(TAB_PARAMS.REVIEW);
            setParams({ tab: TAB_PARAMS.REVIEW });
          },
          icon: "COMMENT",
          disabled: !canReview,
          disabledTooltip: evaluateButtonTooltipMap[remote.status],
        },
      },
    ],
    startDialog: {
      title: t2("StartTarget", { target: remote.name }),
      open: isAlertStart,
      onClose: () => {
        setIsAlertStart(false);
      },
      yesButton: {
        disabled: loadingStatus === "LOADING",
        onClick: async () => {
          RemotesIdDetailStore.updateLoadingStatus("LOADING");
          await client
            .StartRemoteForRemoteInterviewDetail({
              input: {
                remoteId: remote.id,
              },
            })
            .then(() => {
              navigate("/e/remotes/:id", { pathParams: { id: remote.id } });
            })
            .catch(error => {
              Sentry.captureException(error);
              const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                error,
                t("テストの開始に失敗しました。お手数ですがヘルプセンターより運営にお問い合わせください。"),
              );
              Snackbar.notify({
                severity: "error",
                message: errorNotification.message,
              });
            })
            .finally(() => {
              RemotesIdDetailStore.updateLoadingStatus("NONE");
            });
        },
      },
    },
    evaluationDialog: {
      testName: remote.name,
      candidateName: remote.candidateName,
      url: generateCurrentOriginUrl("/e/remotes/:id/detail", { pathParams: { id: remote.id } }),
      open: isAlertEvaluation,
      onClose: () => {
        setIsAlertEvaluation(false);
      },
      onSubmit: async fields => {
        RemotesIdDetailStore.updateLoadingStatus("LOADING");
        await client
          .EvaluateRemoteForRemoteInterviewDetail({
            input: {
              remoteId: remote.id,
              employeeId: currentUid,
              isPassed: fields.result === "PASSED",
              evaluationComment: fields.comment,
            },
          })
          .then(res => {
            if (res.evaluateRemote) {
              RemotesIdDetailStore.updateInterview(res.evaluateRemote);
              refreshOverview();
            }
            Snackbar.notify({
              severity: "success",
              message: t("インタビューの評価を完了しました。"),
            });
            setIsAlertEvaluation(false);
          })
          .catch(error => {
            Sentry.captureException(error);
            const errorNotification = ErrorHandlingHelper.generateErrorNotification(
              error,
              t("インタビューの評価に失敗しました。しばらくしてから再度お試し頂くか、ヘルプボタンよりお問い合わせください。"),
            );
            Snackbar.notify({
              severity: "error",
              message: errorNotification.message,
            });
          })
          .finally(() => {
            RemotesIdDetailStore.updateLoadingStatus("NONE");
          });
      },
      yesButton: {
        disabled: loadingStatus === "LOADING",
      },
      noButton: {
        disabled: loadingStatus === "LOADING",
      },
    },
    deletionDialog: {
      targetName: remote.name,
      open: isAlertDeletion,
      onClose: () => {
        setIsAlertDeletion(false);
      },
      yesButton: {
        onClick: async () => {
          RemotesIdDetailStore.updateLoadingStatus("LOADING");
          await client
            .DeleteRemoteForRemoteInterviewDetail({
              input: {
                remoteId: remote.id,
              },
            })
            .then(() => {
              navigate("/e/remotes");
              Snackbar.notify({
                severity: "success",
                message: t("インタビューを削除しました。"),
              });
            })
            .catch(error => {
              Sentry.captureException(error);
              const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                error,
                t("インタビューの削除に失敗しました。しばらくしてから再度お試し頂くか、ヘルプボタンよりお問い合わせください。"),
              );
              Snackbar.notify({
                severity: "error",
                message: errorNotification.message,
              });
            })
            .finally(() => {
              RemotesIdDetailStore.updateLoadingStatus("NONE");
            });
        },
        disabled: loadingStatus === "LOADING",
      },
      noButton: {
        disabled: loadingStatus === "LOADING",
      },
    },
    Prompt: promptOpen && <TechnicalCommentPopperContainer remoteId={remote.id} onClose={handlePromptClose} />,
  };
};
