import { LanguageMap, languageMap } from "@hireroo/app-definition";
import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { App, Company, Payment, Role } from "@hireroo/app-store/essential/employee";
import { NotificationSettings } from "@hireroo/app-store/widget/e/NotificationSettings";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { navigate } from "@hireroo/router/api";
import * as Sentry from "@sentry/browser";

export type GenerateNotificationSettingsPropsArgs = {};

export const useGenerateProps = (_args: GenerateNotificationSettingsPropsArgs): Widget.SlackNotificationSettingsProps => {
  const client = getGraphqlClient();
  const companyId = Company.useStrictActiveCompanyId();
  const spotSettings = NotificationSettings.useSpotSettings();
  const remoteSettings = NotificationSettings.useRemoteSettings();
  const isAuthorized = NotificationSettings.useIsAuthorized();
  const language = NotificationSettings.useLanguage();
  const webhook = NotificationSettings.useWebhook();
  const appStatus = App.useStatus();
  const matchingRole = Role.useMatchingRole();
  const { t } = useTranslation();
  const isAvailableFeature = Payment.useIsAvailableFeature();

  return {
    loading: appStatus !== "INITIALIZED",
    isAuthorized: isAuthorized,
    slackIntegrateButton: {
      showAddSlackButton: matchingRole.ADMIN_ONLY.matched,
      slackOauthLink: import.meta.env.VITE_SLACK_OAUTH_LINK_V2,
    },
    defaultValues: {
      spotSettings: {
        subscribeSpotCreated: spotSettings.subscribeSpotCreated,
        subscribeSpotStarted: spotSettings.subscribeSpotStarted,
        subscribeSpotCompleted: spotSettings.subscribeSpotCompleted,
        subscribeSpotReviewed: spotSettings.subscribeSpotReviewed,
        subscribeSpotFinalized: spotSettings.subscribeSpotFinalized,
        subscribeSpotEvaluated: spotSettings.subscribeSpotEvaluated,
        subscribeSpotRecreated: spotSettings.subscribeSpotRecreated,
        subscribeSpotDeleted: spotSettings.subscribeSpotDeleted,
        subscribeSpotExpired: spotSettings.subscribeSpotExpired,
      },
      remoteSettings: {
        subscribeRemoteCreated: remoteSettings.subscribeRemoteCreated,
        subscribeRemoteAwaiting: remoteSettings.subscribeRemoteAwaiting,
        subscribeRemoteStarted: remoteSettings.subscribeRemoteStarted,
        subscribeRemoteCompleted: remoteSettings.subscribeRemoteCompleted,
        subscribeRemoteDeleted: remoteSettings.subscribeRemoteDeleted,
        subscribeRemoteReviewed: remoteSettings.subscribeRemoteReviewed,
        subscribeRemoteEvaluated: remoteSettings.subscribeRemoteEvaluated,
      },
      language: LanguageMap[language],
    },
    slackNotification: {
      slackChannel: webhook?.channel ?? "",
      interviewFields: isAvailableFeature("interview.notifications.slack.create") ? {} : undefined,
      reIntegrateButton: {
        onClick: () => {
          navigate(import.meta.env.VITE_SLACK_OAUTH_LINK_V2);
        },
      },
    },
    onSubmit: async field => {
      await client
        .UpdateSlackSettings({
          companyId: companyId,
          spotSetting: {
            subscribeSpotCompleted: field.spotSettings.subscribeSpotCompleted,
            subscribeSpotCreated: field.spotSettings.subscribeSpotCreated,
            subscribeSpotDeleted: field.spotSettings.subscribeSpotDeleted,
            subscribeSpotEvaluated: field.spotSettings.subscribeSpotEvaluated,
            subscribeSpotExpired: field.spotSettings.subscribeSpotExpired,
            subscribeSpotFinalized: field.spotSettings.subscribeSpotFinalized,
            subscribeSpotRecreated: field.spotSettings.subscribeSpotRecreated,
            subscribeSpotReviewed: field.spotSettings.subscribeSpotReviewed,
            subscribeSpotStarted: field.spotSettings.subscribeSpotStarted,
          },
          remoteSetting: field.remoteSettings ?? {
            subscribeRemoteStarted: false,
            subscribeRemoteCreated: false,
            subscribeRemoteEvaluated: false,
            subscribeRemoteAwaiting: false,
            subscribeRemoteCompleted: false,
            subscribeRemoteDeleted: false,
            subscribeRemoteReviewed: false,
          },
          language: languageMap[field.language],
        })
        .then(res => {
          Snackbar.notify({
            severity: "success",
            message: t("Slackとの連携情報を更新しました。"),
          });
          if (res.updateSlackSettings?.spotSettings) {
            NotificationSettings.updateSpotSettings(res.updateSlackSettings.spotSettings);
          }
          if (res.updateSlackSettings?.remoteSettings) {
            NotificationSettings.updateRemoteSettings(res.updateSlackSettings.remoteSettings);
          }
          if (res.updateSlackSettings?.language) {
            NotificationSettings.updateLanguage(res.updateSlackSettings.language);
          }
        })
        .catch(error => {
          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(
            error,
            t("Slackとの連携情報の更新に失敗しました。しばらくしてから再度お試し下さい。"),
          );
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
        });
    },
  };
};
