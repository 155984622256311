import { useTranslation } from "@hireroo/i18n";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import PlaylistAddOutlinedIcon from "@mui/icons-material/PlaylistAddOutlined";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DeleteMetricDialog, { DeleteMetricDialogProps } from "../../../../ms-components/Evaluation/DeleteMetricDialog/DeleteMetricDialog";
import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "../../../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";
import MetricsEditorDialog, { MetricsEditorDialogProps } from "../MetricsEditorDialog/MetricsEditorDialog";

const StyledTypography = styled(Typography)(() => ({
  fontSize: "14px",
  whitespace: "wrap",
}));

export type MetricsTableRowProps = {
  id: string;
  checkbox: Pick<CheckboxProps, "disabled" | "title" | "checked" | "onChange">;
  title: string;
  description: string;
  updatedAt: string;
  editButton: Pick<IconButtonWithTooltipProps, "disabled" | "onClick">;
  editDialog: MetricsEditorDialogProps;
  addButton: Pick<IconButtonWithTooltipProps, "disabled" | "onClick">;
  deleteButton: Pick<IconButtonWithTooltipProps, "disabled" | "onClick">;
  deleteDialog: DeleteMetricDialogProps;
};

const MetricsTableRow: React.FC<MetricsTableRowProps> = props => {
  const { t } = useTranslation();
  const checkboxProps: CheckboxProps = {
    ...props.checkbox,
    color: "secondary",
    size: "small",
  };
  const editButton: IconButtonWithTooltipProps = {
    ...props.editButton,
    title: t("編集"),
    children: <EditOutlinedIcon fontSize="small" />,
  };
  const addButton: IconButtonWithTooltipProps = {
    ...props.addButton,
    title: t("評価指標に追加"),
    children: <PlaylistAddOutlinedIcon fontSize="small" />,
  };
  const deleteButton: IconButtonWithTooltipProps = {
    ...props.deleteButton,
    title: t("削除"),
    children: <DeleteOutlineIcon fontSize="small" />,
  };

  return (
    <TableRow>
      <TableCell padding={"checkbox"}>
        <Tooltip title={props.checkbox.title ?? ""}>
          <span>
            <Checkbox {...checkboxProps} />
          </span>
        </Tooltip>
      </TableCell>
      <TableCell sx={{ minWidth: "200px" }}>
        <StyledTypography fontWeight={700} sx={{ overflowWrap: "break-word", overflowY: "auto", maxHeight: "150px" }}>
          {props.title}
        </StyledTypography>
      </TableCell>
      <TableCell sx={{ minWidth: "200px", maxWidth: "500px" }}>
        <Typography color="textSecondary" fontSize={14} sx={{ overflowWrap: "break-word", overflowY: "auto", maxHeight: "150px" }}>
          {props.description}
        </Typography>
      </TableCell>
      <TableCell>
        <StyledTypography minWidth="115px">{props.updatedAt}</StyledTypography>
      </TableCell>
      <TableCell align="right">
        <Stack direction="row" spacing={1.5}>
          <IconButtonWithTooltip key="edit-button" {...editButton} />
          <IconButtonWithTooltip key="add-button" {...addButton} />
          <IconButtonWithTooltip key="delete-button" {...deleteButton} />
        </Stack>
      </TableCell>
      <DeleteMetricDialog {...props.deleteDialog} />
      <MetricsEditorDialog key={`${props.id}-edit-${props.editDialog.dialog.open}`} {...props.editDialog} />
    </TableRow>
  );
};

MetricsTableRow.displayName = "MetricsTableRow";

export default MetricsTableRow;
