import { RANK_MAP } from "@hireroo/app-definition/interview";
import { ENTITY_REPORT_TOP } from "@hireroo/app-helper/entity";
import { App, Company, Payment } from "@hireroo/app-store/essential/talent";
import { AssessmentReport } from "@hireroo/app-store/widget/t/AssessmentReport";
import { formatScore } from "@hireroo/formatter/score";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useCurrentLanguage, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { useHelpCenterNavigate } from "@hireroo/router/hooks";
import * as React from "react";

import ExamRankVisualizerInitialContainer from "../../shared/ExamRankVisualizer/InitalContainer";
import ChallengeTestReportFetchContainer from "../ChallengeTestReport/FetchContainer";
import ProjectTestReportFetchContainer from "../ProjectTestReport/FetchContainer";
import QuizTestReportFetchContainer from "../QuizTestReport/FetchContainer";
import SystemDesignTestReportFetchContainer from "../SystemDesignTestReport/FetchContainer";
import { generateSmoothTargetId } from "./privateHelper";
import { useGenerateEntityScoreSelector } from "./useGenerateEntityScoreSelector";
import { useGenerateFooterProps } from "./useGenerateFooterProps";

export type GenerateAssessmentReportPropsArgs = {
  reportSettings: Graphql.AssessmentReportSettings | null;
};

type DisplayScoreArea = Exclude<Widget.AssessmentReportProps["summary"]["scorePieChart"], undefined>["displayScoreArea"];

type Report = Widget.AssessmentReportProps["Reports"][0];

export const useGenerateProps = (args: GenerateAssessmentReportPropsArgs): Widget.AssessmentReportProps => {
  const appStatus = App.useStatus();
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const activeCompanyId = Company.useActiveCompanyId();
  const exam = AssessmentReport.useExam();
  const lang = useCurrentLanguage();
  const helpCenterNavigate = useHelpCenterNavigate(lang);
  const isAvailableFeature = Payment.useIsAvailableFeature();
  const entityScoreSelector = useGenerateEntityScoreSelector(args);
  const selectedEntityId = AssessmentReport.useSelectedEntityId();
  const footer = useGenerateFooterProps();

  const Reports = React.useMemo(() => {
    return exam.entities.map((entity): Report => {
      const smoothScrollTargetId = generateSmoothTargetId(entity);
      if (entity.__typename === "ChallengeEntity") {
        /**
         * Release date 2023/5/1 0:00
         */
        const tabAndPasteStatisticsReleaseDateSeconds = new Date(2023, 4, 1, 0, 0).getTime() / 1000;
        const canShowPasteAndTabStatistics = (exam.didStartAtSeconds ?? 0) >= tabAndPasteStatisticsReleaseDateSeconds;
        /**
         * Release date 2023/7/6 16:00
         */
        const canShowCheatDetectSection = (exam.didStartAtSeconds ?? 0) >= new Date(2023, 6, 5, 16, 0).getTime() / 1000;

        return {
          id: AssessmentReport.generateUniqueEntityId(entity),
          smoothScrollTargetId,
          Content: activeCompanyId && (
            <ChallengeTestReportFetchContainer
              featureKind="exam"
              canShowCheatDetectionSection={canShowCheatDetectSection}
              canShowPasteAndTabStatistics={canShowPasteAndTabStatistics}
              companyId={activeCompanyId}
              challengeId={entity.challengeEntityId}
              reportSettings={args.reportSettings}
            />
          ),
        };
      } else if (entity.__typename === "QuizEntity") {
        return {
          id: AssessmentReport.generateUniqueEntityId(entity),
          smoothScrollTargetId,
          Content: activeCompanyId && (
            <QuizTestReportFetchContainer
              key={entity.quizEntityId}
              featureKind="exam"
              quizId={entity.quizEntityId}
              companyId={activeCompanyId}
              reportSettings={args.reportSettings}
            />
          ),
        };
      } else if (entity.__typename === "ProjectEntity") {
        return {
          id: AssessmentReport.generateUniqueEntityId(entity),
          smoothScrollTargetId,
          Content: activeCompanyId && (
            <ProjectTestReportFetchContainer
              featureKind="exam"
              projectId={entity.projectEntityId}
              companyId={activeCompanyId}
              testId={exam.id}
              reportSettings={args.reportSettings}
            />
          ),
        };
      } else if (entity.__typename === "SystemDesignEntity") {
        return {
          id: AssessmentReport.generateUniqueEntityId(entity),
          smoothScrollTargetId,
          Content: activeCompanyId && (
            <SystemDesignTestReportFetchContainer
              featureKind="exam"
              systemDesignId={entity.systemDesignEntityId}
              companyId={activeCompanyId}
              reportSettings={args.reportSettings}
            />
          ),
        };
      } else {
        throw new Error("unknown entity type");
      }
    });
  }, [activeCompanyId, args.reportSettings, exam.didStartAtSeconds, exam.entities, exam.id]);

  const displayScoreAreaProps = React.useMemo((): DisplayScoreArea => {
    if (exam.status !== "FINALIZED") {
      return { kind: "SCORING" };
    } else if (exam.rankEvaluation === "UNKNOWN" || !isAvailableFeature("test.rank.read")) {
      return {
        kind: "SCORE_ONLY",
      };
    } else if (!exam.isReliableRank) {
      return {
        kind: "WITH_RANK",
        rankLabel: { rank: "UNKNOWN", reason: t("提出時点のランクを表示するためのデータ数が足りないため表示できません。") },
      };
    } else {
      return {
        kind: "WITH_RANK",
        rankLabel: { rank: RANK_MAP[exam.rankEvaluation], reason: t2("RelativeScoreLabel", { score: formatScore(exam.relativeScore) }) },
      };
    }
  }, [exam.status, exam.rankEvaluation, exam.relativeScore, isAvailableFeature, exam.isReliableRank, t, t2]);

  return {
    summary: {
      scorePieChart: {
        score: formatScore(exam.totalScore),
        displayScoreArea: displayScoreAreaProps,
      },
      status: exam.status !== "FINALIZED" ? "NOT_READY" : "READY",
      entityScoreSelector: entityScoreSelector,
      enableStaticContent: isAvailableFeature("test.statics.read"),
      StatisticsContent: activeCompanyId && args.reportSettings?.showRelativeEvaluation && (
        <ExamRankVisualizerInitialContainer
          companyId={activeCompanyId}
          examId={exam.id}
          finalizedAtSeconds={exam?.finalizedAtSeconds}
          pause={appStatus !== "INITIALIZED"}
          enableTagField={false}
          enableRank
          isReliableExamRank={exam.isReliableRank}
        />
      ),
      helpLink: {
        onClick: () => {
          helpCenterNavigate("CHECK_REPORT", { _blank: true });
        },
      },
      showSuspiciousBehaviorText: false,
    },
    targetElementId: ENTITY_REPORT_TOP,
    footer: footer,
    Reports: Reports,
    selectedEntityId: selectedEntityId || "",
  };
};
