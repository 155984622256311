import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import SortField, { SortFieldProps } from "../../modules/SortField/SortField";
import AssessmentCardList, { AssessmentCardListProps } from "../../ms-components/Assessment/AssessmentCardList/AssessmentCardList";

const StyledBox = styled(Box)(({ theme }) => ({
  boxShadow: "none",
  borderRadius: "16px",
  pb: 4,
  backgroundColor: theme.palette["Background/Paper"].p2,
}));

const ContentHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  borderBottom: "1px solid",
  borderColor: theme.palette.Other.Divider,
  alignItems: "center",
}));

export type AssessmentListForEmployeeProps = AssessmentCardListProps & {
  sortField: SortFieldProps;
  countText: string;
};

/**
 * There are plans to add a Table View mode in the future.
 */
const AssessmentListForEmployee: React.FC<AssessmentListForEmployeeProps> = props => {
  const { sortField, countText, ...rest } = props;
  return (
    <StyledBox>
      <ContentHeader px={2} mb={2}>
        <Typography variant="body2">{countText}</Typography>
        <SortField {...sortField} />
      </ContentHeader>
      <Box px={3}>
        <AssessmentCardList {...rest} />
      </Box>
    </StyledBox>
  );
};

AssessmentListForEmployee.displayName = "AssessmentListForEmployee";

export default AssessmentListForEmployee;
