import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import EmployeeLayout, { EmployeeLayoutProps } from "../../layouts/EmployeeLayout/EmployeeLayout";

const StyledButton = styled(Button)(() => ({
  height: 36,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  flexShrink: 0,
  textTransform: "none",
}));

const StyledHeaderStack = styled(Stack)(() => ({
  alignItems: "center",
  justifyContent: "space-between",
}));

export type QuestionPackageListProps = {
  layout: EmployeeLayoutProps;
  SearchArea: React.ReactNode;
  Table: React.ReactNode;
  goQuestionsButton: Pick<ButtonProps, "onClick">;
};

const QuestionPackageList: React.FC<QuestionPackageListProps> = props => {
  const { t } = useTranslation();
  const layoutProps: EmployeeLayoutProps = {
    ...props.layout,
  };

  const questionsButton: ButtonProps = {
    ...props.goQuestionsButton,
    variant: "outlined",
    color: "secondary",
    children: t("すべての問題一覧"),
  };

  return (
    <EmployeeLayout {...layoutProps}>
      <Box p={3}>
        <StyledHeaderStack direction="row" spacing={2} alignItems="center" justifyContent="space-between" mb={2}>
          <Stack spacing={1.5}>
            <Typography variant="h5" fontWeight={700} noWrap>
              {t("問題集一覧")}
            </Typography>
            <Typography fontSize={14} color="textSecondary">
              {t(
                "問題集は出題する問題に迷った場合に活用することが可能です。どの問題を出題すれば、受験者のどのようなスキルが図れるか目的別で探すことが可能です。もし、目的の問題集が見つからない場合はフィードバックをお寄せください。",
              )}
            </Typography>
          </Stack>
          <StyledButton {...questionsButton} />
        </StyledHeaderStack>

        <Box>
          <Stack spacing={2}>
            {props.SearchArea}
            {props.Table}
          </Stack>
        </Box>
      </Box>
    </EmployeeLayout>
  );
};

QuestionPackageList.displayName = "QuestionPackageList";

export default QuestionPackageList;
