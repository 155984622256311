import { Auth } from "@hireroo/app-store/essential/employee";
import { signOut } from "@hireroo/firebase";
import { useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation/legacy";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";

type LinkProps = Widget.EmployeeMenuProps["employeeMenuList"]["links"][0];

export type GenerateEmployeeMenuPropsArgs = {};

export const useGenerateProps = (_args: GenerateEmployeeMenuPropsArgs): Widget.EmployeeMenuProps => {
  const { t } = useTranslation();
  const currentUser = Auth.useCurrentUser();
  const navigate = useTransitionNavigate();

  const links: (LinkProps | false)[] = [
    { children: t("個人設定"), href: generatePath("/e/settings/user"), onClick: () => navigate("/e/settings/user") },
    {
      children: t("会社設定"),
      href: generatePath("/e/settings/company/profile"),
      onClick: () => navigate("/e/settings/company/profile"),
    },
    {
      children: t("サインアウト"),
      onClick: async () => {
        await signOut();
      },
    },
  ];
  return {
    photoUrl: currentUser.photoUrl,
    employeeMenuList: {
      displayName: currentUser.displayName,
      email: currentUser.email,
      links: links.flatMap(v => (v ? [v] : [])),
    },
  };
};
