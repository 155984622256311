import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type AssessmentFeedbackContainerProps = {};

const AssessmentFeedbackContainer: React.FC<AssessmentFeedbackContainerProps> = () => {
  const assessmentFeedbackProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.AssessmentFeedback {...assessmentFeedbackProps} />
    </ErrorBoundary>
  );
};

AssessmentFeedbackContainer.displayName = "AssessmentFeedbackContainer";

export default withErrorBoundary(AssessmentFeedbackContainer, {});
