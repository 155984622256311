import { proxy } from "valtio";

import type * as Types from "./types";

export const state = proxy<Types.State>({
  currentTab: null,
  interview: null,
  pdfExports: {},
  sharedLink: null,
  dialogStatus: "CLOSE",
  spotForRetake: null,
  isSpotForRetakeLoading: false,
});
