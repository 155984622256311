import { App, Company } from "@hireroo/app-store/essential/employee";
import { PlanUpdateForm } from "@hireroo/app-store/widget/e/PlanUpdateForm";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import PlanUpdateFormContainer, { PlanUpdateFormContainerProps } from "./Container";
import { getEndOfMonthDateUnixTimeSeconds, getStartOfMonthDateUnixTimeSeconds } from "./privateHelper";

export type PlanUpdateFormFetchContainerProps = {};

const PlanUpdateFormFetchContainer: React.FC<PlanUpdateFormFetchContainerProps> = _props => {
  const appStatus = App.useStatus();
  const companyId = Company.useStrictActiveCompanyId();
  const initialized = PlanUpdateForm.useInitialized();

  const [result, refresh] = Graphql.useGetPlansForPaymentPlanUpdateFormQuery({
    variables: {
      customerId: companyId,
      purchasePeriodStartSeconds: getStartOfMonthDateUnixTimeSeconds(),
      purchasePeriodEndSeconds: getEndOfMonthDateUnixTimeSeconds(),
      cursorSeconds: null,
    },
    requestPolicy: "network-only",
    pause: appStatus !== "INITIALIZED",
  });

  React.useEffect(() => {
    if (result.data) {
      PlanUpdateForm.initialize(result.data.plans, result.data.customer, result.data.mostExpensivePurchase);
    }
  }, [result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }

  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: PlanUpdateFormContainerProps = {};

  return <PlanUpdateFormContainer {...containerProps} />;
};

PlanUpdateFormFetchContainer.displayName = "PlanUpdateFormFetchContainer";

export default withErrorBoundary(PlanUpdateFormFetchContainer, {});
