import { generateTimestampFromDate } from "@hireroo/app-helper/parser";
import { ChallengeTestReport } from "@hireroo/app-store/view-domain/ChallengeTestReport";
import { TotalScoreRankVisualizer } from "@hireroo/app-store/widget/shared/TotalScoreRankVisualizer";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Sentry from "@sentry/browser";

export type SubscribeQueryKeyArgs = {
  uniqueKey: ChallengeTestReport.UniqueKey;
  companyId: number;
  challengeId: number;
  submissionId: number;
  questionId: number;
  questionVersion: string;
};

const NUM_BINS = 20;
const COMPARISON_THRESHOLD = 30;

export const startSubscribeQueryKey = (args: SubscribeQueryKeyArgs) => {
  const client = getGraphqlClient();
  const { uniqueKey, challengeId } = args;
  return TotalScoreRankVisualizer.subscribeQueryKey(uniqueKey, async (query, shouldRefresh) => {
    if (!shouldRefresh && ChallengeTestReport.Api.hasStatistics(challengeId, query)) {
      ChallengeTestReport.setQuery(challengeId, query);
      return;
    }
    client
      .GetChallengeStatisticsAndRankForRankVisualizer({
        statisticsSource: {
          category: "CHALLENGE",
          query: {
            challenge: {
              questionId: args.questionId,
              questionVersion: args.questionVersion,
              companyId: query.scope === "COMPANY" ? args.companyId : undefined,
              numBins: NUM_BINS,
              minTimestamp: query.enableDate && query.startDate ? generateTimestampFromDate(query.startDate) : undefined,
              maxTimestamp: query.enableDate && query.endDate ? generateTimestampFromDate(query.endDate) : undefined,
            },
          },
        },
        rankSource: {
          category: "CHALLENGE",
          query: {
            challenge: {
              submissionId: args.submissionId,
              companyId: query.scope === "COMPANY" ? args.companyId : undefined,
              minTimestamp: query.enableDate && query.startDate ? generateTimestampFromDate(query.startDate) : undefined,
              maxTimestamp: query.enableDate && query.endDate ? generateTimestampFromDate(query.endDate) : undefined,
            },
          },
        },
      })
      .then(({ statistics, rank }) => {
        TotalScoreRankVisualizer.setResult(uniqueKey, query, {
          rank: rank,
          statistics: statistics.challengeStatistics,
          status: rank.numSubset < COMPARISON_THRESHOLD ? "NOT_ENOUGH" : "SATISFY",
        });
        ChallengeTestReport.setStatistics(challengeId, query, statistics.challengeStatistics);
      })
      .catch(error => {
        Sentry.captureException(error);
        TotalScoreRankVisualizer.setResult(uniqueKey, query, {
          rank: null,
          statistics: null,
          status: "ERROR",
        });
      });
  });
};
