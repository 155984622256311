import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import * as z from "zod";

export const ValueType = z.enum(["STRING", "INTEGER", "FLOAT", "BOOLEAN"]);

export type ValueType = z.infer<typeof ValueType>;

const convertValidNumberValue = (value: unknown): number | undefined => {
  if (value === "") {
    return undefined;
  }
  const maybeNumber = Number(value);
  if (Number.isNaN(maybeNumber)) {
    return undefined;
  }
  return maybeNumber;
};

export const useLabel = () => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  return z
    .string()
    .min(1, {
      message: t("必須項目です"),
    })
    .max(30, {
      message: t2("ValidateMaxTextSizeMessage", {
        size: 30,
        name: t("ラベル"),
      }),
    });
};

export const useDescription = () => {
  return z.string().max(1000);
};

export const useStringValueField = () => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const label = useLabel();
  const description = useDescription();

  return z.object({
    label: label,
    description: description,
    formType: z.enum(["INPUT_TEXT", "TEXTAREA"]),
    valueType: z.enum(["STRING"]),
    validationRule: z
      .object({
        minLength: z
          .preprocess(
            convertValidNumberValue,
            z
              .number()
              .min(0, {
                message: t2("ValidateMinSizeMessage", {
                  size: 0,
                  name: t("文字列の入力項目"),
                }),
              })
              .optional(),
          )
          .optional(),
        maxLength: z
          .preprocess(
            convertValidNumberValue,
            z
              .number()
              .max(10000, {
                message: t2("ValidateMaxTextSizeMessage", {
                  size: 10000,
                  name: t("文字列の入力項目"),
                }),
              })
              .optional(),
          )
          .optional(),
        required: z.boolean(),
      })
      .superRefine((value, ctx) => {
        if (typeof value.minLength === "number" && typeof value.maxLength === "number") {
          if (value.maxLength < value.minLength) {
            ctx.addIssue({
              path: ["maxLength"],
              code: z.ZodIssueCode.too_small,
              minimum: value.minLength,
              type: "number",
              inclusive: true,
              message: t("最大文字数は最小文字数よりも大きく指定してください。"),
            });
          }
        }
      }),
  });
};

export type StringValueField = z.infer<ReturnType<typeof useStringValueField>>;

export const useNumberValueField = () => {
  const { t } = useTranslation();
  const label = useLabel();
  const description = useDescription();

  return z.object({
    label: label,
    description: description,
    formType: z.enum(["INPUT_NUMBER"]),
    valueType: z.enum(["INTEGER"]),
    validationRule: z
      .object({
        min: z.preprocess(convertValidNumberValue, z.number().optional()),
        max: z.preprocess(convertValidNumberValue, z.number().optional()),
        required: z.boolean(),
      })
      .superRefine((value, ctx) => {
        if (typeof value.min === "number" && typeof value.max === "number") {
          if (value.max < value.min) {
            ctx.addIssue({
              path: ["max"],
              code: z.ZodIssueCode.too_small,
              minimum: value.min,
              type: "number",
              inclusive: true,
              message: t("最大値は最小値よりも大きく指定してください。"),
            });
          }
        }
      }),
  });
};

export type NumberValueField = z.infer<ReturnType<typeof useNumberValueField>>;

export const useCustomField = () => {
  const stringField = useStringValueField();
  const numberField = useNumberValueField();

  return z.union([stringField, numberField]);
};

export type CustomField = z.infer<ReturnType<typeof useCustomField>>;

export type FormType = CustomField["formType"];

export const useCustomFormForStorybook = () => {
  const customField = useCustomField();
  return z.object({
    customFields: customField.array(),
  });
};
