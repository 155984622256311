import { useSnapshot } from "valtio";

import { state } from "./State";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = (): boolean => {
  const snapshot = useSnapshotState();
  if (snapshot.assessmentsResponse === null) {
    return false;
  }
  if (snapshot.assessmentsResponse.assessments.length === 0) {
    return true;
  }
  return snapshot.selectedAssessmentId !== null;
};

export const useCurrentSelectedAssessmentId = () => {
  const snapshot = useSnapshotState();
  return snapshot.selectedAssessmentId;
};

export const useAssessments = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.assessmentsResponse) {
    throw new Error("Please Initialize assessments");
  }
  if (snapshot.assessmentsResponse.assessments.length === 0) {
    return [];
  }
  return snapshot.assessmentsResponse.assessments;
};

export const useCurrentSelectedAssessment = () => {
  const snapshot = useSnapshotState();
  return snapshot.assessmentsResponse?.assessments.find(assessment => assessment.assessmentId === snapshot.selectedAssessmentId);
};

export const usePager = () => {
  const snapshot = useSnapshotState();
  return snapshot.pager;
};

export const useNotYetSubmittedExamCount = (): number => {
  const assessments = useAssessments();
  return assessments.reduce<number>((total, assessment) => {
    if (assessment.isSubmittedAtLatestInterval || assessment.rootExamIds.length === 0) {
      return total;
    }
    return total + 1;
  }, 0);
};

export const useGetTailExamStatusMap = () => {
  const snapshot = useSnapshotState();
  return snapshot.tailExamStatusMap;
};

export const useTailExamMap = () => {
  const snapshot = useSnapshotState();
  return snapshot.tailExamIdMap;
};
