import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import * as z from "zod";

export const useEvaluationMetricsFormSchema = () => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  return z.object({
    title: z
      .string()
      .min(1, { message: t("必須項目です") })
      .max(255, { message: t("最大255文字以内で入力してください。") }),
    description: z
      .string()
      .max(1000, { message: t2("MaxStringInput", { max: 1000 }) })
      .optional(),
  });
};

export type EvaluationMetricsFormSchema = z.infer<ReturnType<typeof useEvaluationMetricsFormSchema>>;

export const EvaluationMetricItem = z.object({
  itemId: z.string(),
  name: z.string(),
});

export type MetricItemSchema = z.infer<typeof EvaluationMetricItem>;

export const useEvaluationMetricsSearchFormSchema = () => {
  const { t } = useTranslation();
  return z.object({
    items: EvaluationMetricItem.array().min(1, { message: t("必須項目です") }),
  });
};

export type EvaluationMetricsSearchFormSchema = z.infer<ReturnType<typeof useEvaluationMetricsSearchFormSchema>>;
