import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type TimeReportPanelContainerProps = {};

const TimeReportPanelContainer: React.FC<TimeReportPanelContainerProps> = () => {
  const timeReportPanelProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.TimeReportPanel {...timeReportPanelProps} />
    </ErrorBoundary>
  );
};

TimeReportPanelContainer.displayName = "TimeReportPanelContainer";

export default withErrorBoundary(React.memo(TimeReportPanelContainer), {});
