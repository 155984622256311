import * as React from "react";
import { useSnapshot } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = (entityId: number) => {
  const snapshot = useSnapshotState();
  return snapshot.quizzes.has(entityId);
};

export const useCreateQuizHooks = (entityId: Types.QuizId) => {
  const snapshot = useSnapshotState();
  const entityState = snapshot.quizzes.get(entityId);

  if (!entityState) {
    throw new Error(`Not found ${entityId}`);
  }

  const { entity, submissionMap, questionMap, submissionResourceKeys, questionBySubmissionMap, evaluationByQuestionMap, forceRevisionIndex } =
    entityState;

  const useQuiz = () => {
    return entity;
  };

  const usePackage = () => {
    if (!entity.pb_package) {
      throw new Error("Not found Package");
    }
    return entity.pb_package;
  };

  const useQuestionId = () => {
    const quizPackage = usePackage();
    return quizPackage.packageId;
  };

  const useQuestions = () => {
    const quizPackage = usePackage();
    return quizPackage.questions;
  };

  const useQuestionIds = () => {
    const quizPackage = usePackage();
    return quizPackage.questions.map(q => q.id);
  };

  const useQuestionMap = () => {
    return questionMap;
  };

  const useQuizId = () => {
    return entity.quizEntityId;
  };

  const useSubmissions = () => {
    return entity.submissions;
  };

  const useSubmissionMap = () => {
    return submissionMap;
  };

  const useSubmissionResourceKeys = () => {
    return submissionResourceKeys;
  };

  const useEvaluationMap = () => {
    return evaluationByQuestionMap;
  };

  const getSelectedSubmission = (questionKey: Types.QuizQuestionKey) => {
    const submissionId = questionBySubmissionMap.get(questionKey);
    if (submissionId) {
      return submissionMap.get(submissionId);
    }
  };

  const useTotalElapsedTimeSeconds = (): number => {
    return React.useMemo((): number => {
      return entity.elapsedTimeSeconds ?? 0;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entity.elapsedTimeSeconds]);
  };

  const useQuestionByQuestionId = (questionId: number) => {
    const version = entityState.questionIdVersionMap.get(questionId);
    if (version) {
      return entityState.questionMap.get(`${questionId}-${version}`);
    }
  };

  const useStatistics = () => {
    const statistics = entityState.statisticsMap.get(entityState.queryKey);
    return {
      elapsedAvgTimeSeconds: statistics?.elapsedTime?.avg ?? 0,
      avgNumTabEvent: statistics?.numTabEvents?.avg ?? 0,
    };
  };
  const useForceRevisionIndex = () => {
    return forceRevisionIndex;
  };

  return {
    useQuizId,
    useQuiz,
    usePackage,
    useQuestionId,
    useQuestions,
    useQuestionMap,
    useQuestionByQuestionId,
    useSubmissions,
    useSubmissionMap,
    useSubmissionResourceKeys,
    useEvaluationMap,
    getSelectedSubmission,
    useTotalElapsedTimeSeconds,
    useStatistics,
    useForceRevisionIndex,
    useQuestionIds,
  };
};
