// !!! CAUTION !!!
// DO NOT USE `LoadingButtonProps`
// Because, the tsc build will not finish.
import LoadingButton from "@mui/lab/LoadingButton";
import type { ButtonProps } from "@mui/material/Button";
import * as React from "react";

export type FormSubmitButtonProps = ButtonProps & {
  loading?: boolean;
};

const FormSubmitButton: React.FC<FormSubmitButtonProps> = props => {
  const { loading, ...rest } = props;
  return (
    <LoadingButton color="primary" variant="contained" size="small" {...rest} loading={loading}>
      {rest.children}
    </LoadingButton>
  );
};

FormSubmitButton.displayName = "FormSubmitButton";

export default FormSubmitButton;
