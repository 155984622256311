import { ref } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

export const initialize = (latestPathMap: Types.LatestPathMap): void => {
  state.latestEncodedPathWithIdMap = ref(latestPathMap);
};

export const clear = () => {
  state.initialized = false;
  state.latestEncodedPathWithIdMap.clear();
  state.playbackManagerMap.clear();
  state.playStatus = "PAUSE";
};

export type InitializePlaybackManagerArgs = {
  selectedFileIndex: Types.SelectedFileIndex;
  playbackManager: Types.PlaybackManager;
};

export const initializePlaybackManager = (args: InitializePlaybackManagerArgs) => {
  const { playbackManager, selectedFileIndex } = args;
  state.playbackManagerMap.set(selectedFileIndex, ref(playbackManager));

  state.selectedFileIndex = selectedFileIndex;

  state.sliderValue = playbackManager.lastTickIndex;

  state.playbackSettings = {
    ...state.playbackSettings,
    enabledCopyAndPasteDetection: playbackManager.ticks.some(tick => tick.events.some(event => event.kind === "EDITOR_PASTE")),
  };
};

export const updateSliderValue = (sliderValue: number) => {
  state.sliderValue = sliderValue;
};

export const updatePlayStatus = (playStatus: Types.PlayStatus) => {
  state.playStatus = playStatus;
};

export const updateSelectedFileIndex = (selectedFileIndex: Types.SelectedFileIndex) => {
  state.selectedFileIndex = selectedFileIndex;
};

export const updatePlaybackSettings = (playbackSettings: Types.PlaybackSettings) => {
  state.playbackSettings = playbackSettings;
};
