import { useTranslation } from "@hireroo/i18n";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import HideValueField from "../../../../../../../primitive/InputControl/HideValueField/HideValueField";
import InputControlTextField, {
  InputControlTextFieldProps,
} from "../../../../../../../primitive/InputControl/InputControlTextField/InputControlTextField";
import SwitchControl from "../../../../../../../primitive/InputControl/SwitchControl/SwitchControl";

const StyledInputControlTextField = styled(InputControlTextField)(({ theme }) => ({
  minHeight: 40,
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.action.disabled,
  },
}));

/**
 * ${name}.label
 * ${name}.description
 * ${name}.formType
 * ${name}.validationRule.minLength
 * ${name}.validationRule.maxLength
 * ${name}.validationRule.required
 */
export type StringCustomFieldProps = {
  name: string;
  FieldSelect: React.ReactNode;
  formType: "TEXTAREA" | "INPUT";
  deleteButton: Pick<IconButtonProps, "onClick">;
};

const StringCustomField: React.FC<StringCustomFieldProps> = props => {
  const { name } = props;
  const { t } = useTranslation();
  const labelField: InputControlTextFieldProps = {
    placeholder: t("質問タイトル"),
    fullWidth: true,
  };
  const descriptionField: InputControlTextFieldProps = {
    multiline: true,
    fullWidth: true,
    placeholder: t("質問の説明"),
  };
  const minLengthField: InputControlTextFieldProps = {
    type: "number",
    label: t("最小文字数"),
    inputProps: {
      min: 0,
      step: "1",
    },
  };
  const maxLengthField: InputControlTextFieldProps = {
    type: "number",
    label: t("最大文字数"),
    inputProps: {
      min: 0,
      step: "1",
    },
  };
  const deleteButton: IconButtonProps = {
    ...props.deleteButton,
    children: <DeleteOutlined />,
  };
  return (
    <Stack spacing={2} direction="column" p={2}>
      <Stack spacing={2} direction="row">
        <StyledInputControlTextField {...labelField} name={`${name}.label`} />
        {props.FieldSelect}
      </Stack>

      <StyledInputControlTextField {...descriptionField} name={`${name}.description`} />

      <Stack direction="row" spacing={1} divider={<Typography>{"~"}</Typography>} sx={{ alignItems: "flex-start" }}>
        <StyledInputControlTextField {...minLengthField} name={`${name}.validationRule.minLength`} />
        <StyledInputControlTextField {...maxLengthField} name={`${name}.validationRule.maxLength`} />
      </Stack>

      <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
        <IconButton {...deleteButton} />
        <FormControlLabel
          control={<SwitchControl size="small" name={`${name}.validationRule.required`} color="secondary" />}
          label={<Typography sx={{ wordBreak: "keep-all", fontSize: 14 }}>{t("必須")}</Typography>}
          sx={{ ml: 0.5 }}
        />
        <HideValueField name={`${props.name}.valueType`} value={"STRING"} />
        <HideValueField name={`${props.name}.formType`} value={props.formType} />
      </Box>
    </Stack>
  );
};

StringCustomField.displayName = "StringCustomField";

export default StringCustomField;
