import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { App, Company } from "@hireroo/app-store/essential/employee";
import { NotificationSettings } from "@hireroo/app-store/widget/e/NotificationSettings";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/react";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";
import { useSearchParams } from "react-router-dom";

import SlackNotificationSettingsFetchContainer, { NotificationSettingsFetchContainerProps } from "./FetchContainer";

export type NotificationSettingsInitialContainerProps = {};

const SlackNotificationSettingsInitialContainer: React.FC<NotificationSettingsInitialContainerProps> = _props => {
  const appStatus = App.useStatus();
  const companyId = Company.useStrictActiveCompanyId();
  const navigate = useTransitionNavigate();
  const [searchParams] = useSearchParams();
  const client = getGraphqlClient();
  const { t } = useTranslation();

  const handleAuthorizeSlack = React.useCallback(
    (authorizeCode: string) => {
      client
        .AuthorizeSlackForSlackNotificationSettings({
          input: {
            companyId: companyId,
            authorizeCode,
            redirectUri: `${import.meta.env.VITE_URL}${generatePath("/e/settings/company/notification/slack")}`,
          },
        })
        .then(res => {
          if (res.authorizeSlack?.isAuthorized) {
            NotificationSettings.updateIsAuthorized(res.authorizeSlack.isAuthorized);
          }
          if (res.authorizeSlack?.incomingWebHook) {
            NotificationSettings.updateIncomingWebHook(res.authorizeSlack.incomingWebHook);
          }
          // once authorization is done, redirect back to the original url
          navigate("/e/settings/company/notification/slack");
        })
        .catch(error => {
          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(
            error,
            t("Slackとの連携に失敗しました。しばらくしてから再度お試し下さい。"),
          );
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
        });
    },
    [t, client, companyId, navigate],
  );

  React.useEffect(() => {
    const authorizeCode = searchParams.get("code");
    if (authorizeCode) {
      handleAuthorizeSlack(authorizeCode);
    }
  }, [handleAuthorizeSlack, searchParams]);

  if (appStatus !== "INITIALIZED") {
    return <Widget.Loading kind="TOP" />;
  }

  const containerProps: NotificationSettingsFetchContainerProps = {};
  return (
    <ErrorBoundary>
      <SlackNotificationSettingsFetchContainer {...containerProps} />
    </ErrorBoundary>
  );
};

SlackNotificationSettingsInitialContainer.displayName = "SlackNotificationSettingsFetchContainer";

export default withErrorBoundary(SlackNotificationSettingsInitialContainer, {});
