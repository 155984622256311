import { useTranslation } from "@hireroo/i18n";
import { QuestionSearchForm } from "@hireroo/validator";

import { SearchFormProps } from "../../modules/SearchForm/SearchForm";

type FieldProps = SearchFormProps["fields"][0];
export type MultiChoiceFieldProps = Extract<FieldProps, { kind: "MULTI_CHOICE" }>;
export type SingleChoiceFieldProps = Extract<FieldProps, { kind: "SINGLE_CHOICE" }>;
export type GroupMultiChoiceFieldProps = Extract<FieldProps, { kind: "GROUP_MULTI_CHOICE" }>;
export type RangeFieldProps = Extract<FieldProps, { kind: "RANGE" }>;
export type TagFieldProps = Extract<FieldProps, { kind: "TAG" }>;
export type TagSearchFieldProps = Extract<FieldProps, { kind: "TAG_SEARCH" }>;

type FieldKeyName = keyof QuestionSearchForm.RemoteInterviewQuestionSearchQuerySchema;

const FieldName = {
  TEXT_FILTER: "textFilter",
  QUESTION_VARIANTS: "questionVariants",
  DIFFICULTIES: "difficulties",
  SKILL_TAGS: "skillTags",
} satisfies Record<string, FieldKeyName>;

type QuestionTypeOption = {
  displayName: string;
  value: QuestionSearchForm.RemoteInterviewQuestionSearchQuerySchema["questionVariants"][0];
  children?: QuestionTypeOption[];
};

export const useQuestionTypesField = (): GroupMultiChoiceFieldProps => {
  const { t } = useTranslation();
  const options: QuestionTypeOption[] = [
    {
      value: "CHALLENGE",
      displayName: t("コーディング形式"),
      children: [
        { value: "CHALLENGE_ALGORITHM", displayName: t("アルゴリズム") },
        { value: "CHALLENGE_DATABASE", displayName: t("データベース") },
        { value: "CHALLENGE_CLASS", displayName: t("クラス") },
      ],
    },
    { value: "SYSTEM_DESIGN", displayName: t("システムデザイン") },
    { value: "FREEPAD", displayName: t("ライブコーディング") },
  ];
  return {
    name: FieldName.QUESTION_VARIANTS,
    kind: "GROUP_MULTI_CHOICE",
    title: t("問題形式"),
    options: options,
  };
};

export const useTextFilterField = (): SearchFormProps["textFilter"] => {
  const { t } = useTranslation();
  return {
    name: FieldName.TEXT_FILTER,
    placeholder: t("タイトルを検索"),
  };
};

type DifficultiesOption = {
  displayName: string;
  value: QuestionSearchForm.QuestionSearchQuerySchema["difficulties"][0];
};
export const useDifficultyField = (): MultiChoiceFieldProps => {
  const { t } = useTranslation();
  const options: DifficultiesOption[] = [
    { value: "EASY", displayName: t("易しい") },
    { value: "MEDIUM", displayName: t("ふつう") },
    { value: "DIFFICULT", displayName: t("難しい") },
  ];
  return {
    kind: "MULTI_CHOICE",
    title: t("難易度"),
    name: FieldName.DIFFICULTIES,
    options: options,
  };
};

export type SkillTagsFiledArgs = Pick<TagSearchFieldProps, "selector" | "onChange" | "splitButton">;

export const useSkillTagsField = (props: SkillTagsFiledArgs): TagSearchFieldProps => {
  const { t } = useTranslation();
  return {
    ...props,
    kind: "TAG_SEARCH",
    name: FieldName.SKILL_TAGS,
    title: t("スキルタグ"),
  };
};
