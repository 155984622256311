import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import { type ButtonProps } from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type TabLabelProps = {
  title: string;
  closeButton?: Pick<ButtonProps, "onClick">;
};

const TabLabel: React.FC<TabLabelProps> = props => {
  const handleClick = React.useCallback<Exclude<ButtonProps["onClick"], undefined>>(
    event => {
      props.closeButton?.onClick?.(event);
      event.stopPropagation();
    },
    [props.closeButton],
  );
  return (
    <Box justifyContent="space-between" display="flex" alignItems="center">
      <Typography variant="body2" textTransform="none">
        {props.title}
      </Typography>
      {props.closeButton && (
        <Box flexDirection="column" justifyContent="center" display="flex" component="span">
          {/* This className is used in the tutorial. */}
          <IconButton className={"close-tab"} size="small" style={{ marginLeft: 8 }} onClick={handleClick} component="span">
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

TabLabel.displayName = "TabLabel";

export default TabLabel;
