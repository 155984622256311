import { DeepReadonly } from "@hireroo/app-helper/types";
import { useSnapshot } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = () => {
  const snapshot = useSnapshotState();
  return !!snapshot.interview;
};

export const useRefresh = (): Types.RefreshCallback => {
  const snapshot = useSnapshotState();
  return snapshot.refresh;
};

export const useInterview = (): DeepReadonly<Types.Interview> => {
  const snapshot = useSnapshotState();
  if (!snapshot.interview) {
    throw new Error("need to initialize");
  }

  return snapshot.interview;
};
export const useSelectedEmployeeIds = () => {
  const snapshot = useSnapshotState();
  return snapshot.selectedEmployeeIds;
};

export const useSelectedGroupIds = () => {
  const snapshot = useSnapshotState();
  return snapshot.selectedGroupIds;
};

export const useTags = () => {
  const snapshot = useSnapshotState();
  return snapshot.tags;
};

export const useDefaultEmployeeIds = () => {
  const snapshot = useSnapshotState();
  return snapshot.defaultSelectedEmployeeIds;
};

export const useDefaultGroupIds = () => {
  const snapshot = useSnapshotState();
  return snapshot.defaultSelectedGroupIds;
};
