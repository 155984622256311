import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

// TODO: refactor using zod
const outputJsonParse = (value: string, emptyValue: string): Record<string, string>[] => {
  try {
    // TODO hashMapの問題が出たら変更する必要がある
    return JSON.parse(/\[{.+}\]/.exec(value) !== null ? value : `[${[emptyValue]}]`);
  } catch (e) {
    return [{ message: emptyValue }];
  }
};

export type ChallengeIDEConsoleOutputTableProps = {
  label: string;
  value: string;
};

const ChallengeIDEConsoleOutputTable: React.FC<ChallengeIDEConsoleOutputTableProps> = props => {
  const { t } = useTranslation();
  const emptyMessage = `{"message": "${t("該当するデータがありません")}"}`;
  const output = outputJsonParse(props.value, emptyMessage);

  return (
    <Box display="flex" alignItems="center" key="console-output-table" mb={2}>
      <Box width={"10%"}>
        <Typography>{props.label}</Typography>
      </Box>
      <Box width={"90%"} boxShadow={3}>
        <TableContainer component={Paper}>
          <Table sx={{ backgroundColor: "background.paper" }} size={"small"}>
            <TableHead>
              <TableRow sx={{ backgroundColor: "background.default" }}>
                {Object.keys(output[0]).map((column, index) => (
                  <TableCell key={`key-header-cell-${index}`}>{column}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {output.map((record, rowIndex: number) => (
                <StyledTableRow key={`key-body-row-${rowIndex}`}>
                  {Object.values(record).map((v, colIndex: number) => (
                    <TableCell key={`key-body-cell-${rowIndex}-${colIndex}`}>{`${v}`}</TableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

ChallengeIDEConsoleOutputTable.displayName = "ChallengeIDEConsoleOutputTable";

export default ChallengeIDEConsoleOutputTable;
