import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import RestrictableTab, { RestrictableTabProps, TabItem } from "../../modules/RestrictableTab/RestrictableTab";

const PAGE_HEADER_HEIGHT = 48;
const TAB_HEIGHT = 43;

const ContentWrapper = styled(Box)(() => ({
  overflowY: "auto",
  overflowX: "hidden",
  height: `calc(100% - ${PAGE_HEADER_HEIGHT}px)`,
}));

/**
 * 23px was calculated from the non-content height of EmployeeLayout
 */
const StyledBox = styled(Box)`
  height: calc(100vh - 23px);
  overflow: hidden;
  min-width: 900;
`;

export type ScreeningTestDetailForCandidateProps = {
  title: string;
  candidate?: {
    name?: string;
    email: string;
  };
  defaultTabId?: string;
  items: TabItem[];
};

const ScreeningTestDetailForCandidate: React.FC<ScreeningTestDetailForCandidateProps> = props => {
  const theme = useTheme();
  const ref = React.useRef<HTMLDivElement>(null);
  const restrictableTabProps: RestrictableTabProps = {
    defaultTab: props.defaultTabId,
    items: props.items,
    tabs: {
      "aria-label": "test-report-tabs",
      sx: {
        "&.MuiTabs-root": {
          minHeight: `${TAB_HEIGHT}px`,
          height: `${TAB_HEIGHT}px`,
        },
        "& .MuiTab-root": {
          minHeight: `${TAB_HEIGHT}px`,
          height: `${TAB_HEIGHT}px`,
        },
        "& .MuiTab-root.Mui-selected": {
          color: theme.palette.secondary.main,
          backgroundColor: "inherit",
        },
      },
    },
    tabBox: {
      sx: {
        paddingLeft: "24px",
        paddingRight: "24px",
      },
    },
    tabPanelBox: {
      pt: 0,
      sx: {
        bgcolor: theme.palette.background.default,
        minHeight: `calc(100vh - ${PAGE_HEADER_HEIGHT + (ref.current?.clientHeight || 0) + TAB_HEIGHT}px)`,
      },
    },
    wrapper: {},
    variant: "OUTLINED",
  };
  return (
    <StyledBox>
      <ContentWrapper id="report-content-wrapper">
        <Box m={2} ref={ref}>
          <Stack direction="row" spacing={0.5}>
            <Typography variant="h5" fontSize={20} fontWeight={700} noWrap>
              {props.title}
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              {props.candidate && (
                <Typography fontSize={14} color="textSecondary">
                  {props.candidate.name && `${props.candidate.name} (${props.candidate.email})`}
                  {!props.candidate.name && `${props.candidate.email}`}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Box>
        <RestrictableTab {...restrictableTabProps} />
      </ContentWrapper>
    </StyledBox>
  );
};

ScreeningTestDetailForCandidate.displayName = "ScreeningTestDetailForCandidate";

export default ScreeningTestDetailForCandidate;
