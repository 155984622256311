import { useTranslation } from "@hireroo/i18n";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import RuleIcon from "@mui/icons-material/Rule";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import ReadonlyTabs, { ReadonlyTabItem, ReadonlyTabsProps } from "../../../modules/ReadonlyTabs/ReadonlyTabs";
import QuestionHintStack, { QuestionHintStackProps } from "../QuestionHintStack/QuestionHintStack";
import QuestionSection, { QuestionSectionProps } from "../QuestionSection/QuestionSection";

export type AnswerSection = Pick<ReadonlyTabItem, "Component" | "name" | "onClickTab">;

export type QuestionAndAnswerTabProps = {
  className?: string;
  /**
   * key to re-create instance
   */
  uniqueKey?: string;
  questionSection: QuestionSectionProps;
  questionHintStack?: QuestionHintStackProps;
  answerSections: AnswerSection[];
};

const QuestionAndAnswerTab: React.FC<QuestionAndAnswerTabProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const readonlyTabs: ReadonlyTabsProps = {
    className: props.className,
    defaultTab: "question",
    tabs: {
      /**
       * Fixed value because display collapse occurs when scrolling when the display area is narrowed by Split.
       */
      variant: "standard",
      scrollButtons: "auto",
    },
    items: [
      {
        id: "question",
        name: t("問題"),
        icon: <DescriptionOutlinedIcon sx={{ fontSize: "16px" }} />,
        Component: (
          <QuestionSection {...props.questionSection}>
            {props.questionHintStack && (
              <Box>
                <Typography variant="body1" fontWeight="bold" mb={0.75}>
                  {t("ヒント")}
                </Typography>
                <QuestionHintStack {...props.questionHintStack} />
              </Box>
            )}
          </QuestionSection>
        ),
      },
      ...props.answerSections.map((answerSection, index) => {
        const name = answerSection.name.length > 0 ? answerSection.name : t("データが登録されていません");
        return {
          ...answerSection,
          name: name,
          id: `answer/${index}`,
          icon: <RuleIcon sx={{ fontSize: "16px" }} htmlColor={theme.palette.text.secondary} />,
        };
      }),
    ],
  };
  return <ReadonlyTabs key={props.uniqueKey} {...readonlyTabs} />;
};

QuestionAndAnswerTab.displayName = "QuestionAndAnswerTab";

export default QuestionAndAnswerTab;
