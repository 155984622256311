import { useTranslation } from "@hireroo/i18n";
import Box, { BoxProps } from "@mui/material/Box";
import * as React from "react";

import { useChallengeCodingEditorContext } from "../../Context";
import IDEConsoleInput from "./IDEConsoleInput/IDEConsoleInput";
import IDEConsoleInputDialog from "./IDEConsoleInputDialog/IDEConsoleInputDialog";
import IDEConsoleOutput, { IDEConsoleOutputProps } from "./IDEConsoleOutput/IDEConsoleOutput";
import IDEConsoleToolbar, { IDEConsoleToolbarProps } from "./IDEConsoleToolbar/IDEConsoleToolbar";
import TestCaseButtonGroups, { TestCaseButtonGroupsProps } from "./TestCaseButtonGroups/TestCaseButtonGroups";

export type IDEConsoleProps = {
  testCaseButtonGroups: Pick<TestCaseButtonGroupsProps, "resetButton" | "outputResultMap" | "isLoading">;
  toolbar: Pick<IDEConsoleToolbarProps, "runButton" | "submitButton">;
  outputConsole: IDEConsoleOutputProps;
};

const IDEConsole: React.FC<IDEConsoleProps> = props => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const { selectedTestCaseIndex, updateSelectedTestCaseIndex, resetTestCases, testCases, updateTestCases } = useChallengeCodingEditorContext();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const removeTestCase = React.useCallback(
    (index: number) => {
      const newTestCasesForConsole = [...testCases];
      newTestCasesForConsole.splice(index, 1);
      updateTestCases(newTestCasesForConsole);
      updateSelectedTestCaseIndex(0);
    },
    [testCases, updateSelectedTestCaseIndex, updateTestCases],
  );

  const testCaseButtonGroups: Omit<TestCaseButtonGroupsProps, "mode"> = {
    ...props.testCaseButtonGroups,
    resetButton: {
      onClick: e => {
        props.testCaseButtonGroups.resetButton?.onClick?.(e);
        resetTestCases();
      },
    },
    deleteButton: {
      onClick: () => removeTestCase(selectedTestCaseIndex),
    },
    addButton: {
      onClick: handleClickOpen,
    },
  };
  const commonBox: BoxProps = {
    mx: 1.5,
    pb: 2,
    overflow: "auto",
    height: "100%",
  };

  const toolbar: IDEConsoleToolbarProps = {
    ...props.toolbar,
    items: [
      {
        id: "tests",
        name: t("テストケース"),
        Component: (
          <Box {...commonBox}>
            <Box mb={1.5}>
              <TestCaseButtonGroups {...testCaseButtonGroups} mode="ACTION" />
            </Box>
            <IDEConsoleInput consoleValue={testCases[selectedTestCaseIndex]?.inputs} readonly />
          </Box>
        ),
        /** used in tutorial  */
        tabId: "challenge-console-input",
      },
      {
        id: "result",
        name: t("結果"),
        Component: (
          <Box {...commonBox}>
            <Box mb={1.5}>
              <TestCaseButtonGroups {...testCaseButtonGroups} mode="VIEW" />
            </Box>
            <IDEConsoleOutput {...props.outputConsole} />
          </Box>
        ),
        /** used in tutorial  */
        tabId: "challenge-console-output",
      },
    ],
  };

  return (
    <Box width="100%" height="100%">
      <IDEConsoleToolbar {...toolbar} />
      <IDEConsoleInputDialog
        open={open}
        onClose={handleClose}
        defaultValue={testCases[selectedTestCaseIndex]?.inputs}
        key={`testcase-${selectedTestCaseIndex}`}
      />
    </Box>
  );
};

IDEConsole.displayName = "IDEConsole";

export default IDEConsole;
