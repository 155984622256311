import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type SettingsOrderHistoryContainerProps = {};

const SettingsOrderHistoryContainer: React.FC<SettingsOrderHistoryContainerProps> = () => {
  const companySettingsProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Pages.CompanySettings {...companySettingsProps} />
    </ErrorBoundary>
  );
};

SettingsOrderHistoryContainer.displayName = "SettingsOrderHistoryContainer";

export default withErrorBoundary(SettingsOrderHistoryContainer, {});
