import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type ProjectQuestionDetailContainerProps = {};

const ProjectQuestionDetailContainer: React.FC<ProjectQuestionDetailContainerProps> = () => {
  const projectQuestionDetailProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Pages.QuestionDetail {...projectQuestionDetailProps} />
    </ErrorBoundary>
  );
};

ProjectQuestionDetailContainer.displayName = "ProjectQuestionDetailContainer";

export default withErrorBoundary(ProjectQuestionDetailContainer, {});
