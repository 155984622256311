import { App } from "@hireroo/app-store/essential/employee";
import { AlgorithmTemplatePreview } from "@hireroo/app-store/widget/e/AlgorithmTemplatePreview";
import * as Graphql from "@hireroo/graphql/client/urql";
import React from "react";
import { CombinedError } from "urql";

export type FetchResult = {
  isLoading: boolean;
  error?: CombinedError;
};
export type UseFetchTemplateArgs = {
  variant: "ALGORITHM" | "DATABASE" | "CLASS";
  signature: string;
};

export const useFetchTemplate = (args: UseFetchTemplateArgs): FetchResult => {
  const appStatus = App.useStatus();
  const [result] = Graphql.useAlgorithmTemplateCodeQuery({
    variables: {
      signature: args.signature,
      variant: args.variant,
    },
    pause: appStatus !== "INITIALIZED",
  });
  React.useEffect(() => {
    if (result.data) {
      AlgorithmTemplatePreview.updateTemplate(result.data.algorithmTemplateCode);
    }
  }, [result.data]);

  return {
    isLoading: result.fetching || appStatus !== "INITIALIZED",
    error: result.error,
  };
};
