import Box from "@mui/material/Box";
import MuiDivider, { DividerProps as MuiDividerProps } from "@mui/material/Divider";
import * as React from "react";

export type DividerProps = {};

const Divider: React.FC<DividerProps> = _props => {
  const divider: MuiDividerProps = {
    orientation: "horizontal",
  };
  return (
    <Box>
      <MuiDivider {...divider} />
    </Box>
  );
};

Divider.displayName = "Divider";

export default Divider;
