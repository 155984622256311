import { useTranslation } from "@hireroo/i18n";
import { SignInForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { MailAddressFieldProps } from "../../modules/MailAddressField/MailAddressField";
import FieldLabel from "../../ms-components/Auth/FieldLabel/FieldLabel";
import PasswordField, { PasswordFieldProps } from "../../ms-components/Auth/PasswordField/PasswordField";
import FormSubmitButton, { FormSubmitButtonProps } from "../../primitive/Button/FormSubmitButton/FormSubmitButton";
import InputControlTextField from "../../primitive/InputControl/InputControlTextField/InputControlTextField";
import Link, { LinkProps } from "../../primitive/Link/Link";
import TermsOfUseLink, { TermsOfUseLinkProps } from "./parts/TermsOfUseLink/TermsOfUseLink";

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.secondary.main,
  textDecorationColor: theme.palette.secondary.main,
  fontSize: "14px",
}));

export type SignInMailAddressProps = {
  termsOfUseLink?: TermsOfUseLinkProps;
  passwordField: PasswordFieldProps;
  signInButton: FormSubmitButtonProps;
  passwordReset?: Pick<LinkProps, "onClick">;
  onSubmit: SubmitHandler<SignInForm.MailAddressSchema>;
};

const SignInMailAddress: React.FC<SignInMailAddressProps> = props => {
  const { t } = useTranslation();
  const validateSchema = SignInForm.useMailAddressSchema();
  const method = useForm<SignInForm.MailAddressSchema>({
    resolver: zodResolver(validateSchema),
    reValidateMode: "onSubmit",
    defaultValues: { email: "", password: "" },
  });
  const disabled = !!method.formState.errors.email || !!method.formState.errors.password;

  React.useEffect(() => {
    const subscriber = method.watch(() => {
      method.clearErrors();
    });

    return () => {
      subscriber.unsubscribe();
    };
  }, [method]);

  const mailAddressFieldProps: MailAddressFieldProps = {
    fullWidth: true,
    color: "primary",
    placeholder: "contact@hireroo.io",
  };

  const passwordFieldProps: PasswordFieldProps = {
    ...props.passwordField,
    color: "primary",
  };
  const passwordResetLinkProps: LinkProps | undefined = props.passwordReset && {
    ...props.passwordReset,
    underline: "hover",
    children: t("パスワードをリセット"),
  };
  const submitButtonProps: FormSubmitButtonProps = {
    ...props.signInButton,
    size: "medium",
    fullWidth: true,
    disabled: disabled,
    loading: method.formState.isSubmitting,
    sx: {
      textTransform: "none",
    },
    onClick: () => {
      method.handleSubmit(props.onSubmit)();
    },
  };
  return (
    <FormProvider {...method}>
      <Stack spacing={2} justifyContent="center" direction="column" alignItems="flex-start">
        <FieldLabel label={t("メールアドレス")} width="100%">
          <InputControlTextField {...mailAddressFieldProps} name="email" />
        </FieldLabel>
        <FieldLabel
          label={t("パスワード")}
          RightSideComponent={passwordResetLinkProps && <StyledLink {...passwordResetLinkProps} />}
          width="100%"
        >
          <PasswordField name="password" {...passwordFieldProps} />
        </FieldLabel>
        {props.termsOfUseLink && <TermsOfUseLink {...props.termsOfUseLink} />}
        <FormSubmitButton {...submitButtonProps} />
      </Stack>
    </FormProvider>
  );
};

SignInMailAddress.displayName = "SignInMailAddress";

export default SignInMailAddress;
