import { useTranslation } from "@hireroo/i18n";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import PlayArrow from "@mui/icons-material/PlayArrow";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import * as React from "react";

import IconButton, { IconButtonProps } from "../../../../../primitive/Button/IconButton";
import ChallengeOutputStatusIcon from "../../../../../usecase/ChallengeOutputStatusIcon/ChallengeOutputStatusIcon";
import { OutputStatusType } from "./types";

const StyledTableCell = styled(TableCell)({
  overflow: "auto",
  whiteSpace: "nowrap",
  maxWidth: 300,
});

export type TestCaseRunnerRowProps = {
  testCase: {
    id: string;
    title: string;
    inputs: string[];
    outputs: string[];
  };
  outputStatus?: OutputStatusType;
  isOpen: boolean;
  expandButton: Pick<IconButtonProps, "onClick">;
  runButton: Pick<IconButtonProps, "onClick">;
};

const TestCaseRunnerRow: React.FC<TestCaseRunnerRowProps> = props => {
  const { t } = useTranslation();
  const runButton: IconButtonProps = {
    title: t("このテストケースでコードを実行します。"),
    size: "small",
    onClick: props.runButton.onClick,
    children: <PlayArrow />,
  };
  return (
    <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
      <TableCell key={`open-cell-${props.testCase.id}`}>
        <IconButton aria-label="expand row" size="small" onClick={props.expandButton.onClick}>
          {props.isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </TableCell>
      <TableCell component="th" scope="row" key={`title-${props.testCase.title}`}>
        {props.testCase.title}
      </TableCell>
      {/*using index as key because the testCase inputs order won't change or filtered*/}
      {props.testCase.inputs.map((input, index) => (
        <StyledTableCell key={`inputs-${input}-${props.testCase.id}-${index}`}>{input}</StyledTableCell>
      ))}
      {props.testCase.outputs.map((output, index) => (
        <StyledTableCell key={`output-${output}-${props.testCase.id}-${index}`}>{output}</StyledTableCell>
      ))}
      <TableCell key={`outputIcon-${props.testCase.title}`}>
        <ChallengeOutputStatusIcon outputStatus={props.outputStatus} />
      </TableCell>
      <TableCell key={`runbutton-${props.testCase.title}`}>
        <Box display="inline-block" alignItems="center" width={20}>
          {props.outputStatus === "LOADING" ? <CircularProgress size={20} color="inherit" /> : <IconButton {...runButton} />}
        </Box>
      </TableCell>
    </TableRow>
  );
};

TestCaseRunnerRow.displayName = "TestCaseRunnerRow";

export default TestCaseRunnerRow;
