import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import Box from "@mui/material/Box";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";

import QuestionMeta, { QuestionMetaProps } from "../../../../../../ms-components/Question/QuestionMeta/QuestionMeta";

const StyledStack = styled(Stack)(() => ({
  padding: "8px 12px",
  overflow: "hidden",
}));

const LeftWrapper = styled(Box)(() => ({
  width: "24px",
  flexShrink: 0,
  display: "flex",
  justifyContent: "center",
}));

const CenterWrapper = styled(Box)(() => ({
  width: "100%",
}));

const RightActionWrapper = styled(Stack)(() => ({
  width: "24px",
  flexShrink: 0,
  display: "flex",
  justifyContent: "center",
}));

const SquareButton = styled(IconButton)(() => ({
  minWidth: "24px",
  width: "24px",
  height: "24px",
  minHeight: "24px",
}));

const RemoveButton = styled(SquareButton)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

const MoveButton = styled(SquareButton)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export type SortableQuestionListItemProps = {
  index: number;
  num: number;
  uniqueKey: string;

  questionMeta: QuestionMetaProps;

  upButton: Pick<IconButtonProps, "onClick" | "disabled">;
  downButton: Pick<IconButtonProps, "onClick" | "disabled">;
  removeButton: Pick<IconButtonProps, "onClick" | "disabled">;
  disabledAll?: boolean;

  className?: string;
};

const SortableQuestionListItem: React.FC<SortableQuestionListItemProps> = props => {
  const unselectButtonProps: IconButtonProps = {
    ...props.removeButton,
    children: <RemoveOutlinedIcon fontSize="small" />,
  };
  const moveBackForwardButton: IconButtonProps = {
    ...props.upButton,
    children: <ArrowDropUpOutlinedIcon fontSize="small" />,
  };
  const moveForwardButton: IconButtonProps = {
    ...props.downButton,
    children: <ArrowDropDownOutlinedIcon fontSize="small" />,
  };
  return (
    <StyledStack direction="row" className={props.className} spacing={1}>
      <LeftWrapper>
        <RemoveButton {...unselectButtonProps} />
      </LeftWrapper>
      <CenterWrapper>
        <QuestionMeta {...props.questionMeta} />
      </CenterWrapper>
      <RightActionWrapper direction="column">
        <MoveButton {...moveBackForwardButton} />
        <MoveButton {...moveForwardButton} />
      </RightActionWrapper>
    </StyledStack>
  );
};

SortableQuestionListItem.displayName = "SortableQuestionListItem";

export default SortableQuestionListItem;
