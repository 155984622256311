import type * as Types from "./types";

export const generateScoreTarget = (): Types.ScoreTarget => {
  return {
    numOfPassed: 0,
    numOfQuestions: 0,
    items: [],
  };
};

export const categoryMap = {
  AVAILABILITY: "availability",
  CONSISTENCY: "consistency",
  SCALABILITY: "scalability",
} as const;

/**
 * HashKey Element Target Id for scroll
 */
export const TargetElementIdMap = {
  CONSISTENCY_SECTION: "system-design-consistency",
  CORRECT_RATE_SECTION: "system-design-correct-rate",
  AVAILABILITY_SECTION: "system-design-availability",
  SCALABILITY_SECTION: "system-design-scalability",
} as const satisfies Record<string, string>;
