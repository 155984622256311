import { proxyMap } from "valtio/utils";

import { state } from "./State";
import type * as Types from "./types";

export const initialize = (tab: Types.TabValue): void => {
  state.currentTab = tab;
};

export const updateCurrentTab = (newValue: Types.TabValue): void => {
  state.currentTab = newValue;
};

export const updateAssessment = (newValue: Types.Assessment): void => {
  state.assessment = newValue;
};

export const addExams = (exams: Types.Exam[]) => {
  exams.forEach(exam => {
    state.examMap.set(exam.examId, exam);
  });
};
export const addBestExams = (exams: Types.Exam[]) => {
  const bestExamIdSet = new Set(state.bestExams.map(e => e.examId));
  exams.forEach(exam => {
    if (!bestExamIdSet.has(exam.examId)) {
      state.bestExams = [...state.bestExams, exam];
    }
  });
};
export const initializeListBestExams = (exams: Types.Exam[], count: number, hasNext: boolean) => {
  addExams(exams);
  addBestExams(exams);
  state.listBestExamsCount = count;
  state.listBestHasNext = hasNext;
  /** if valid selected exam id exists early return */
  if (state.selectedExamId && exams.some(e => e.examId === state.selectedExamId)) {
    return;
  }
  const latestExam = exams.at(0);
  if (latestExam) {
    updateSelectedExamId(latestExam.examId);
  }
};

export const updateOffsetToCurrentMax = () => {
  if (state.listBestHasNext) {
    state.listBestOffset = state.bestExams.length;
  }
};

export const clear = () => {
  state.currentTab = null;
  state.assessment = null;
  state.bestExams = [];
  state.examMap = proxyMap();
  state.selectedExamId = null;
  state.listBestHasNext = false;
  state.listBestOffset = 0;
  state.tailExamId = null;
};

export const updateDialogStatus = (newStatus: Types.DialogStatus) => {
  state.dialogStatus = newStatus;
};

export const updateSelectedExamId = (examId: Types.ExamId) => {
  state.selectedExamId = examId;
};

export const updatePopperStatus = (newValue: Types.PopperStatus) => {
  state.popperStatus = newValue;
};

export const updateTailExamId = (examId: Types.ExamId) => {
  state.tailExamId = examId;
};
