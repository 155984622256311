import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import ChallengeCodingEditorWithDetailContainer from "./Container";
import { useGenerateChallengeProviderProps } from "./useGenerateChallengeProviderProps";

export type ChallengeQuestionDetailMiddleContainerProps = {
  questionUid: string;
};

const ChallengeCodingEditorWithDetailMiddleContainer: React.FC<ChallengeQuestionDetailMiddleContainerProps> = props => {
  const challengeProviderProps = useGenerateChallengeProviderProps(props);

  return (
    <ErrorBoundary>
      <Widget.ChallengeCodingEditorWithDetailProvider {...challengeProviderProps}>
        <ChallengeCodingEditorWithDetailContainer questionUid={props.questionUid} />
      </Widget.ChallengeCodingEditorWithDetailProvider>
    </ErrorBoundary>
  );
};

ChallengeCodingEditorWithDetailMiddleContainer.displayName = "ChallengeQuestionDetailMiddleContainer";

export default withErrorBoundary(ChallengeCodingEditorWithDetailMiddleContainer, {});
