import { styled, useTheme } from "@mui/material/styles";
import React from "react";

import { ComponentType, ElementDataKeyMap, ElementMasterData, LabelVm, NodeElement } from "../../../features";

const TextSvg = styled("text")(() => ({
  WebkitTouchCallout: "none",
  WebkitUserSelect: "none",
  msUserSelect: "none",
  userSelect: "none",
  dominantBaseline: "middle",
  textAnchor: "middle",
  pointerEvents: "none",
}));

const RectSvg = styled("rect")(() => ({
  filter: "drop-shadow(3px 3px 10px rgba(0, 0, 0, 0.3))",
}));

export type ScaledNodeProps = {
  node: NodeElement<LabelVm>;
  componentType: ComponentType;
  dashedLine?: boolean;
  simpleView?: boolean;
  isMatch?: boolean;
};

const lightGreen = "#76ff03"; // lightGreen A400

export const ScaledNode: React.FC<ScaledNodeProps> = React.memo((props: ScaledNodeProps) => {
  const theme = useTheme();
  const {
    geometry: { minX, minY, maxX, maxY },
  } = props.node;

  return (
    <g>
      <g>
        <RectSvg
          width={maxX - minX - 40}
          height={maxY - minY - 40}
          x={minX + 40}
          y={minY + 40}
          rx={5}
          ry={5}
          fill={theme.palette.common.white}
          strokeWidth={1}
          strokeDasharray={props.dashedLine ? "8 8" : "0 0"}
        />
        <RectSvg
          width={maxX - minX - 40}
          height={maxY - minY - 40}
          x={minX + 20}
          y={minY + 20}
          rx={5}
          ry={5}
          fill={theme.palette.common.white}
          strokeWidth={1}
          strokeDasharray={props.dashedLine ? "8 8" : "0 0"}
        />
        <RectSvg
          width={maxX - minX - 40}
          height={maxY - minY - 40}
          x={minX}
          y={minY}
          rx={5}
          ry={5}
          fill={theme.palette.common.white}
          strokeWidth={1}
          strokeDasharray={props.dashedLine ? "8 8" : "0 0"}
        />
        <image
          xlinkHref={ElementMasterData[ElementDataKeyMap[props.componentType][props.node.label]].iconImage}
          x={minX + 20}
          y={minY + 20}
          width={maxY - minY - 80}
          height={maxY - minY - 80}
        />
      </g>

      {props.node.label && !props.simpleView && (
        <g>
          <TextSvg x={(minX + maxX) / 2} y={maxY + 30} fontSize={24}>
            {props.node.settings.name}
          </TextSvg>
        </g>
      )}

      {props.isMatch && (
        <rect width={maxX - minX} height={maxY - minY} x={minX} y={minY} rx={10} ry={10} fill={"none"} stroke={lightGreen} strokeWidth={10} />
      )}
    </g>
  );
});

ScaledNode.displayName = "ScaledNode";

export default ScaledNode;
