import { useFirepad } from "@hireroo/app-helper/hooks";
import { type ExtendedMonacoEditorProps, type MonacoEditorLanguageClientWrapper } from "@hireroo/code-editor/react/v2/ExtendedMonacoEditor";
import { Widget } from "@hireroo/presentation";
import * as React from "react";

export type GenerateExtendedCodingEditorPropsArgs = {
  uid: string;
  value: string;
  /**
   * @example file:///home/project/app/package.json?w=${workspaceId}
   */
  uri: string;
  /**
   * @example /home/project/app/package.json
   */
  filepath: string;
  readOnly: boolean;
  userName: string;
  rdbKey: string;
  lspServerUrl: string | undefined;
};

export const useGenerateProps = (props: GenerateExtendedCodingEditorPropsArgs): Widget.ProjectExtendedCodeEditorProps => {
  const { uid } = props;
  const [, firepadAction] = useFirepad({
    db: "project",
    key: props.rdbKey,
    userName: props.userName,
    readOnly: props.readOnly,
  });
  const monacoEditorLanguageClientWrapper = React.useRef<MonacoEditorLanguageClientWrapper | null>(null);
  /**
   * In multi-model-editor, when a path is switched, model is automatically created or retrieved
   * See more at: https://github.com/suren-atoyan/monaco-react#multi-model-editor
   */
  const codeEditorProps: ExtendedMonacoEditorProps = {
    code: {
      uri: props.uri,
      value: props.value,
    },
    lspServerUrl: props.lspServerUrl,
    editorOptions: {
      readOnly: props.readOnly,
    },
    onLoad: wrapper => {
      monacoEditorLanguageClientWrapper.current = wrapper;
      const editor = wrapper.getEditor();
      if (!editor) {
        return;
      }
      firepadAction.initPad(uid, editor);
    },
  };
  React.useEffect(() => {
    return () => {
      monacoEditorLanguageClientWrapper.current?.dispose();
    };
  }, []);

  return codeEditorProps;
};
