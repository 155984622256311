import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Auth } from "@hireroo/app-store/essential/employee";
import { FillRemoteTemplateList } from "@hireroo/app-store/widget/e/FillRemoteTemplateList";
import { QuestionSelectFieldForResourceEditor } from "@hireroo/app-store/widget/e/QuestionSelectFieldForResourceEditor";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import { Fields } from "@hireroo/validator";
import * as Sentry from "@sentry/react";
import * as React from "react";

import FillTemplateListContainer from "../FillTemplateList/Container";

type VariableField = Widget.FillTemplateDialogProps["confirmContent"]["variableFields"][0];

export type GenerateFillTemplateDialogPropsArgs = {
  open: Widget.FillTemplateDialogProps["open"];
  onClose: Widget.FillTemplateDialogProps["onClose"];
};

export const useGenerateProps = (args: GenerateFillTemplateDialogPropsArgs): Widget.FillTemplateDialogProps => {
  const { t } = useTranslation();
  const { reset } = Widget.useRemoteInterviewResourceEditorContext();
  const navigate = useTransitionNavigate();
  const step = FillRemoteTemplateList.useStep();
  const selectedTemplate = FillRemoteTemplateList.useSelectedTemplate();
  const client = getGraphqlClient();
  const templateVariables = FillRemoteTemplateList.useTemplateVariables();
  const currentUid = Auth.useCurrentUid();

  const variableFields = React.useMemo((): VariableField[] => {
    return (selectedTemplate?.variables || []).map((variable): VariableField => {
      return {
        id: variable.id,
        label: variable.name,
        onChange: event => {
          FillRemoteTemplateList.updateVariablesWithValue(variable.key, event.target.value);
        },
        helperText: variable.description,
      };
    });
  }, [selectedTemplate?.variables]);
  return {
    open: args.open,
    onClose: args.onClose,
    step: step,
    ChoiceContent: <FillTemplateListContainer />,
    createButton: {
      onClick: () => {
        navigate("/e/templates/remote/create");
      },
    },
    backButton: {
      onClick: () => {
        FillRemoteTemplateList.goBackChoiceStep();
      },
    },
    confirmContent: {
      templateName: selectedTemplate?.templateName ?? "",
      variableFields: variableFields,
    },
    onSubmit: () => {
      if (!selectedTemplate) return;
      client
        .FillTemplateForRemoteInterviewResourceEditor({
          input: {
            id: selectedTemplate.templateId,
            variableInput: templateVariables,
          },
        })
        .then(res => {
          const selfViewer: Fields.AssignField.AssignListItemSchema = {
            value: {
              type: "EMPLOYEE",
              employeeId: currentUid,
              locked: true,
            },
          };
          const viewers = res.fillRemoteInterviewTemplate.reviewers.reduce<Fields.AssignField.AssignListItemSchema[]>((all, viewer) => {
            if (viewer.type === "EMPLOYEE_GROUP" && viewer.group) {
              return all.concat({
                value: {
                  type: "EMPLOYEE_GROUP",
                  groupId: viewer.group.id,
                },
              });
            } else if (viewer.type === "EMPLOYEE" && viewer.employee) {
              // skip self because self viewer is already included
              if (viewer.employee.id === currentUid) {
                return all;
              }
              return all.concat({
                value: {
                  type: "EMPLOYEE",
                  employeeId: viewer.employee.id,
                },
              });
            }
            return all;
          }, []);
          QuestionSelectFieldForResourceEditor.setQuestionByEntitySourceQuestions(res.fillRemoteInterviewTemplate.templateQuestions);
          reset("REMOTE_QUESTION_SETUP", {
            name: res.fillRemoteInterviewTemplate.remoteName,
            candidateName: res.fillRemoteInterviewTemplate.candidateName,
            entityTracks: res.fillRemoteInterviewTemplate.questions.reduce<Fields.EntityTrack.EntityTrack[]>((all, questionSource) => {
              if (questionSource.entityType === "CHALLENGE" && questionSource.challengeQuestion) {
                const question = questionSource.challengeQuestion;
                const entitySource: Fields.EntitySource.EntitySource = {
                  type: "REMOTE_ALGORITHM",
                  uniqueKey: question.uniqueKey,
                  questionId: question.questionId,
                  questionVersion: question.questionVersion,
                  isSelectable: true,
                };
                const entityTrack: Fields.EntityTrack.EntityTrack = {
                  type: "FIXED",
                  questionScoreWeight: 1,
                  entitySource,
                };
                return all.concat(entityTrack);
              } else if (questionSource.entityType === "SYSTEM_DESIGN" && questionSource.systemDesignQuestion) {
                const question = questionSource.systemDesignQuestion;
                const entitySource: Fields.EntitySource.EntitySource = {
                  type: "REMOTE_SYSTEM_DESIGN",
                  uniqueKey: question.uniqueKey,
                  questionId: question.questionId,
                  isSelectable: true,
                };
                const entityTrack: Fields.EntityTrack.EntityTrack = {
                  type: "FIXED",
                  questionScoreWeight: 1,
                  entitySource,
                };
                return all.concat(entityTrack);
              } else if (questionSource.entityType === "FREEPAD" && questionSource.freepadQuestion) {
                const question = questionSource.freepadQuestion;
                const entitySource: Fields.EntitySource.EntitySource = {
                  type: "REMOTE_FREEPAD",
                  uniqueKey: question.uniqueKey,
                  questionId: question.questionId,
                  questionVersion: question.questionVersion,
                  isSelectable: true,
                };
                const entityTrack: Fields.EntityTrack.EntityTrack = {
                  type: "FIXED",
                  questionScoreWeight: 1,
                  entitySource,
                };
                return all.concat(entityTrack);
              }
              return all;
            }, []),
          });
          reset("REPORT_SETUP", {
            memo: res.fillRemoteInterviewTemplate.memo || undefined,
            isPublic: res.fillRemoteInterviewTemplate.isPublic,
            editorUid: currentUid,
            viewers: [selfViewer, ...viewers],
            tags: res.fillRemoteInterviewTemplate.tagNames.map(t => ({ value: { name: t } })),
          });
          Snackbar.notify({
            severity: "success",
            message: t("テストテンプレートからテスト情報を埋めました。確認の上テストを作成してください。"),
          });
          args.onClose?.();
          FillRemoteTemplateList.clearSelectedTemplate();
        })
        .catch(error => {
          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(
            error,
            t(
              "テストテンプレートの情報の埋め込みに失敗しました。一度リロードしてから再度お試しください。それでも問題が発生する場合は、お手数ですが運営にお問い合わせください。",
            ),
          );
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
        });
    },
  };
};
