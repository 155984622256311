import HomeIcon from "@mui/icons-material/Home";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import Link, { LinkProps } from "../../../../primitive/Link/Link";

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 12,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 12,
}));

const StyledBreadcrumbs = styled(Breadcrumbs)(() => ({
  fontSize: 12,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginLeft: "4px",
  minWidth: 400,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  svg: {
    color: theme.palette.text.secondary,
    height: 16,
    width: 16,
  },
}));

export type NavigationBreadcrumbsProps = {
  parentLinks: Pick<LinkProps, "href" | "onClick" | "children">[];
  current: string;
};

const NavigationBreadcrumbs: React.FC<NavigationBreadcrumbsProps> = props => {
  return (
    <StyledBox>
      <HomeIcon fontSize="small" />
      <StyledBreadcrumbs aria-label="breadcrumb">
        {props.parentLinks.map((link, index) => {
          return <StyledLink {...link} key={`${index}-${link.href}`} color="inherit" underline="none" />;
        })}
        <StyledTypography>{props.current}</StyledTypography>
      </StyledBreadcrumbs>
    </StyledBox>
  );
};

NavigationBreadcrumbs.displayName = "NavigationBreadcrumbs";

export default NavigationBreadcrumbs;
