import AwsCloudFrontSvgIcon from "@hireroo/system-design-assets/images/aws/aws_cloud_front.svg";
import AwsCloudWatchSvgIcon from "@hireroo/system-design-assets/images/aws/aws_cloud_watch.svg";
import AwsDynamoDbSvgIcon from "@hireroo/system-design-assets/images/aws/aws_dynamodb.svg";
import AwsEc2SvgIcon from "@hireroo/system-design-assets/images/aws/aws_ec2.svg";
import AwsEcrSvgIcon from "@hireroo/system-design-assets/images/aws/aws_ecr.svg";
import AwsElasticCacheSvgIcon from "@hireroo/system-design-assets/images/aws/aws_elastic_cache.svg";
import AwsElbSvgIcon from "@hireroo/system-design-assets/images/aws/aws_elb.svg";
import AwsLambdaSvgIcon from "@hireroo/system-design-assets/images/aws/aws_lambda.svg";
import AwsRdsSvgIcon from "@hireroo/system-design-assets/images/aws/aws_rds.svg";
import AwsRoute53SvgIcon from "@hireroo/system-design-assets/images/aws/aws_route53.svg";
import AwsS3SvgIcon from "@hireroo/system-design-assets/images/aws/aws_s3.svg";
import AwsSnsSvgIcon from "@hireroo/system-design-assets/images/aws/aws_sns.svg";
import AwsSqsSvgIcon from "@hireroo/system-design-assets/images/aws/aws_sqs.svg";
import AzureCacheForRedisSvgIcon from "@hireroo/system-design-assets/images/azure/azure_cache_for_redis.svg";
import AzureContainerRegistrySvgIcon from "@hireroo/system-design-assets/images/azure/azure_container_registry.svg";
import AzureContentDeliveryNetworkSvgIcon from "@hireroo/system-design-assets/images/azure/azure_content_delivery_network.svg";
import AzureCosmosDbSvgIcon from "@hireroo/system-design-assets/images/azure/azure_cosmos_db.svg";
import AzureDnsSvgIcon from "@hireroo/system-design-assets/images/azure/azure_dns.svg";
import AzureFunctionsSvgIcon from "@hireroo/system-design-assets/images/azure/azure_functions.svg";
import AzureLoadBalancerSvgIcon from "@hireroo/system-design-assets/images/azure/azure_load_balancer.svg";
import AzureQueueStorageSvgIcon from "@hireroo/system-design-assets/images/azure/azure_queue_storage.svg";
import AzureSchedulerSvgIcon from "@hireroo/system-design-assets/images/azure/azure_scheduler.svg";
import AzureServiceBusSvgIcon from "@hireroo/system-design-assets/images/azure/azure_service_bus.svg";
import AzureSqlDatabaseSvgIcon from "@hireroo/system-design-assets/images/azure/azure_sql_database.svg";
import AzureStorageSvgIcon from "@hireroo/system-design-assets/images/azure/azure_storage.svg";
import AzureVirtualMachinesSvgIcon from "@hireroo/system-design-assets/images/azure/azure_virtual_machines.svg";
import AvailabilityZoneSvgIcon from "@hireroo/system-design-assets/images/default/availability_zone.svg";
import CacheTtlSvgIcon from "@hireroo/system-design-assets/images/default/cache.svg";
import CdnSvgIcon from "@hireroo/system-design-assets/images/default/cdn.svg";
import DnsSvgIcon from "@hireroo/system-design-assets/images/default/dns.svg";
import ElasticsearchSvgIcon from "@hireroo/system-design-assets/images/default/elasticsearch.svg";
import ImageRegistrySvgIcon from "@hireroo/system-design-assets/images/default/image_registory.svg";
import LoadBalancerSvgIcon from "@hireroo/system-design-assets/images/default/load_balancer.svg";
import NosqlSvgIcon from "@hireroo/system-design-assets/images/default/nosql.svg";
import PubsubSvgIcon from "@hireroo/system-design-assets/images/default/pubsub.svg";
import QueueSvgIcon from "@hireroo/system-design-assets/images/default/queue.svg";
import RegionSvgIcon from "@hireroo/system-design-assets/images/default/region.svg";
import SchedulerSvgIcon from "@hireroo/system-design-assets/images/default/scheduler.svg";
import ServerlessSvgIcon from "@hireroo/system-design-assets/images/default/serverless.svg";
import SqlSvgIcon from "@hireroo/system-design-assets/images/default/sql.svg";
import StorageSvgIcon from "@hireroo/system-design-assets/images/default/storage.svg";
import SubnetSvgIcon from "@hireroo/system-design-assets/images/default/subnet.svg";
import TextSvgIcon from "@hireroo/system-design-assets/images/default/text.svg";
import UserSvgIcon from "@hireroo/system-design-assets/images/default/user.svg";
import VmSvgIcon from "@hireroo/system-design-assets/images/default/vm.svg";
import VpcSvgIcon from "@hireroo/system-design-assets/images/default/vpc.svg";
import GcpCloudCdnSvgIcon from "@hireroo/system-design-assets/images/gcp/gcp_cloud_cdn.svg";
import GcpCloudDnsSvgIcon from "@hireroo/system-design-assets/images/gcp/gcp_cloud_dns.svg";
import GcpCloudFunctionSvgIcon from "@hireroo/system-design-assets/images/gcp/gcp_cloud_functions.svg";
import GcpCloudLoadBalancingSvgIcon from "@hireroo/system-design-assets/images/gcp/gcp_cloud_load_balancing.svg";
import GcpCloudSchedulerSvgIcon from "@hireroo/system-design-assets/images/gcp/gcp_cloud_scheduler.svg";
import GcpCloudSqlSvgIcon from "@hireroo/system-design-assets/images/gcp/gcp_cloud_sql.svg";
import GcpCloudStorageSvgIcon from "@hireroo/system-design-assets/images/gcp/gcp_cloud_storage.svg";
import GcpCloudTasksSvgIcon from "@hireroo/system-design-assets/images/gcp/gcp_cloud_tasks.svg";
import GcpComputeEngineSvgIcon from "@hireroo/system-design-assets/images/gcp/gcp_compute_engine.svg";
import GcpContainerRegistrySvgIcon from "@hireroo/system-design-assets/images/gcp/gcp_container_registry.svg";
import GcpFirestoreSvgIcon from "@hireroo/system-design-assets/images/gcp/gcp_firestore.svg";
import GcpMemoryStoreSvgIcon from "@hireroo/system-design-assets/images/gcp/gcp_memorystore.svg";
import GcpPubsubSvgIcon from "@hireroo/system-design-assets/images/gcp/gcp_pubsub.svg";
import CacheFifoIcon from "@hireroo/system-design-assets/images/settings/cache_fifo.svg";
import CacheLfuIcon from "@hireroo/system-design-assets/images/settings/cache_lfu.svg";
import CacheLruIcon from "@hireroo/system-design-assets/images/settings/cache_lru.svg";
import CacheTtlIcon from "@hireroo/system-design-assets/images/settings/cache_ttl.svg";
import QueueFifoIcon from "@hireroo/system-design-assets/images/settings/queue_fifo.svg";
import SqlPrimaryIcon from "@hireroo/system-design-assets/images/settings/sql_primary.svg";
import SqlReplicaIcon from "@hireroo/system-design-assets/images/settings/sql_replica.svg";
import SqlShardingIcon from "@hireroo/system-design-assets/images/settings/sql_sharding.svg";
// import * as google_protobuf_duration_pb from "google-protobuf/google/protobuf/duration_pb";
// import * as google_protobuf_timestamp_pb from "google-protobuf/google/protobuf/timestamp_pb";

export interface NormalizedAnswer {
  id: number;
  questionId: number;
  titleJa: string;
  titleEn: string;
  descriptionJa: string;
  descriptionEn: string;
  snapshot: string;
  scoringItemsList: Array<number>;
  // createdAt?: google_protobuf_timestamp_pb.Timestamp.AsObject;
  // updatedAt?: google_protobuf_timestamp_pb.Timestamp.AsObject;
  // deletedAt?: google_protobuf_timestamp_pb.Timestamp.AsObject;
}

export interface NormalizedSystemDesign {
  id: number;
  companyId: number;
  candidateId: string;
  employeeId: string;
  // question: QuestionCompositeKey;
  submissionsList: Array<number>;
  totalScore: number;
  // willStartAt?: google_protobuf_timestamp_pb.Timestamp.AsObject;
  // willEndAt?: google_protobuf_timestamp_pb.Timestamp.AsObject;
  // didStartAt?: google_protobuf_timestamp_pb.Timestamp.AsObject;
  // didEndAt?: google_protobuf_timestamp_pb.Timestamp.AsObject;
  // evaluatedAt?: google_protobuf_timestamp_pb.Timestamp.AsObject;
  // status: SystemDesignStatus;
  // timeLimit?: google_protobuf_duration_pb.Duration.AsObject;
  componentTypesList: string[];
  // createdAt?: google_protobuf_timestamp_pb.Timestamp.AsObject;
  // updatedAt?: google_protobuf_timestamp_pb.Timestamp.AsObject;
  // deletedAt?: google_protobuf_timestamp_pb.Timestamp.AsObject;
}

export interface NormalizedQuestion {
  id: number;
  // TODO: 追加時にOptionalを外す。
  version?: string;
  titleJa: string;
  titleEn: string;
  descriptionJa: string;
  descriptionEn: string;
  // difficulty: Difficulty;
  initialSnapshot: string;
  answersList: number[];
  scoringItemsList: number[];
  hintsList: number[];
  companyId: number;
  isPrivate: boolean;
  // timeLimit?: google_protobuf_duration_pb.Duration.AsObject;
  supportedComponentTypesList?: string[];
  // createdAt?: google_protobuf_timestamp_pb.Timestamp.AsObject;
  // updatedAt?: google_protobuf_timestamp_pb.Timestamp.AsObject;
  // deletedAt?: google_protobuf_timestamp_pb.Timestamp.AsObject;
}

export interface TestResult {
  scoring_item_id: number;
  is_passed: boolean;
  matched_ids: string[];
  updated: boolean;
}

// Element keys
const DEFAULT_TEXT = "default-text";
const DEFAULT_VPC = "default-vpc";
const DEFAULT_SUBNET = "default-subnet";
const DEFAULT_AVAILABILITY_ZONE = "default-availabilityZone";
const DEFAULT_REGION = "default-region";
const DEFAULT_USER = "default-user";
const DEFAULT_DNS = "default-dns";
const DEFAULT_LOAD_BALANCER = "default-loadBalancer";
const DEFAULT_VM = "default-vm";
const DEFAULT_SERVERLESS = "default-serverless";
const DEFAULT_SQL = "default-sql";
const DEFAULT_NOSQL = "default-nosql";
const DEFAULT_CACHE = "default-cache";
const DEFAULT_STORAGE = "default-storage";
const DEFAULT_IMAGE_REGISTRY = "default-imageRegistry";
const DEFAULT_CDN = "default-cdn";
const DEFAULT_QUEUE = "default-queue";
const DEFAULT_PUBSUB = "default-pubsub";
const DEFAULT_SCHEDULER = "default-scheduler";
const DEFAULT_ELASTICSEARCH = "default-elasticSearch";
const AWS_ROUTE53 = "aws-route53";
const AWS_ELB = "aws-elb";
const AWS_EC2 = "aws-ec2";
const AWS_LAMBDA = "aws-lambda";
const AWS_RDS = "aws-rds";
const AWS_DYNAMODB = "aws-dynamodb";
const AWS_ELASTIC_CACHE = "aws-elasticCache";
const AWS_S3 = "aws-s3";
const AWS_ECR = "aws-ecr";
const AWS_CLOUDFRONT = "aws-cloudfront";
const AWS_SQS = "aws-sqs";
const AWS_SNS = "aws-sns";
const AWS_CLOUDWATCH = "aws-cloudwatch";
const GCP_CLOUD_DNS = "gcp-cloudDns";
const GCP_CLOUD_LOAD_BALANCING = "gcp-cloudLoadBalancing";
const GCP_COMPUTE_ENGINE = "gcp-computeEngine";
const GCP_CLOUD_FUNCTIONS = "gcp-cloudFunctions";
const GCP_CLOUD_SQL = "gcp-cloudSql";
const GCP_FIRESTORE = "gcp-firestore";
const GCP_MEMORYSTORE = "gcp-memorystore";
const GCP_CLOUD_STORAGE = "gcp-cloudStorage";
const GCP_CONTAINER_REGISTRY = "gcp-containerRegistry";
const GCP_CLOUD_CDN = "gcp-cloudCdn";
const GCP_CLOUD_TASKS = "gcp-cloudTasks";
const GCP_PUBSUB = "gcp-pubsub";
const GCP_CLOUD_SCHEDULER = "gcp-cloudScheduler";
const AZURE_DNS = "azure-dns";
const AZURE_LOAD_BALANCER = "azure-loadBalancer";
const AZURE_VIRTUAL_MACHINES = "azure-virtualMachines";
const AZURE_FUNCTIONS = "azure-functions";
const AZURE_SQL_DATABASE = "azure-sqlDatabase";
const AZURE_COSMOS_DB = "azure-cosmosDb";
const AZURE_CACHE_FOR_REDIS = "azure-cacheForRedis";
const AZURE_STORAGE = "azure-storage";
const AZURE_CONTAINER_REGISTRY = "azure-containerRegistry";
const AZURE_CONTENT_DELIVERY_NETWORK = "azure-contentDeliveryNetwork";
const AZURE_QUEUE_STORAGE = "azure-queueStorage";
const AZURE_WEB_PUB_SUB = "azure-webPubSub";
const AZURE_SCHEDULER = "azure-scheduler";

export const ELEMENT_KEY = {
  defaultText: DEFAULT_TEXT,
  defaultVpc: DEFAULT_VPC,
  defaultSubnet: DEFAULT_SUBNET,
  defaultAvailabilityZone: DEFAULT_AVAILABILITY_ZONE,
  defaultRegion: DEFAULT_REGION,
  defaultUser: DEFAULT_USER,
  defaultDns: DEFAULT_DNS,
  defaultLoadBalancer: DEFAULT_LOAD_BALANCER,
  defaultVm: DEFAULT_VM,
  defaultServerless: DEFAULT_SERVERLESS,
  defaultSql: DEFAULT_SQL,
  defaultNosql: DEFAULT_NOSQL,
  defaultCache: DEFAULT_CACHE,
  defaultStorage: DEFAULT_STORAGE,
  defaultImageRegistry: DEFAULT_IMAGE_REGISTRY,
  defaultCdn: DEFAULT_CDN,
  defaultQueue: DEFAULT_QUEUE,
  defaultPubsub: DEFAULT_PUBSUB,
  defaultScheduler: DEFAULT_SCHEDULER,
  defaultElasticsearch: DEFAULT_ELASTICSEARCH,
  awsRoute53: AWS_ROUTE53,
  awsElb: AWS_ELB,
  awsEc2: AWS_EC2,
  awsLambda: AWS_LAMBDA,
  awsRds: AWS_RDS,
  awsDynamodb: AWS_DYNAMODB,
  awsElasticCache: AWS_ELASTIC_CACHE,
  awsS3: AWS_S3,
  awsEcr: AWS_ECR,
  awsCloudFront: AWS_CLOUDFRONT,
  awsSqs: AWS_SQS,
  awsSns: AWS_SNS,
  awsCloudwatch: AWS_CLOUDWATCH,
  gcpCloudDns: GCP_CLOUD_DNS,
  gcpCloudLoadBalancing: GCP_CLOUD_LOAD_BALANCING,
  gcpComputeEngine: GCP_COMPUTE_ENGINE,
  gcpCloudFunctions: GCP_CLOUD_FUNCTIONS,
  gcpCloudSql: GCP_CLOUD_SQL,
  gcpFirestore: GCP_FIRESTORE,
  gcpMemorystore: GCP_MEMORYSTORE,
  gcpCloudStorage: GCP_CLOUD_STORAGE,
  gcpContainerRegistry: GCP_CONTAINER_REGISTRY,
  gcpCloudCdn: GCP_CLOUD_CDN,
  gcpCloudTasks: GCP_CLOUD_TASKS,
  gcpPubsub: GCP_PUBSUB,
  gcpCloudScheduler: GCP_CLOUD_SCHEDULER,
  azureDns: AZURE_DNS,
  azureLoadBalancer: AZURE_LOAD_BALANCER,
  azureVirtualMachines: AZURE_VIRTUAL_MACHINES,
  azureFunctions: AZURE_FUNCTIONS,
  azureSqlDatabase: AZURE_SQL_DATABASE,
  azureCosmosDb: AZURE_COSMOS_DB,
  azureCacheForRedis: AZURE_CACHE_FOR_REDIS,
  azureStorage: AZURE_STORAGE,
  azureContainerRegistry: AZURE_CONTAINER_REGISTRY,
  azureContentDeliveryNetwork: AZURE_CONTENT_DELIVERY_NETWORK,
  azureQueueStorage: AZURE_QUEUE_STORAGE,
  azureWebPubSub: AZURE_WEB_PUB_SUB,
  azureScheduler: AZURE_SCHEDULER,
} as const;

export type ElementKey = (typeof ELEMENT_KEY)[keyof typeof ELEMENT_KEY];

// element types
export type TypeNode = "node";
export type TypeNetwork = "network";
export type TypeEdge = "edge";
export type TypeComment = "comment";
export type ElementType = (typeof ELEMENT_TYPE)[keyof typeof ELEMENT_TYPE];

const TYPE_NODE = "node";
const TYPE_EDGE = "edge";
const TYPE_NETWORK = "network";
const TYPE_COMMENT = "comment";

export const ELEMENT_TYPE = {
  node: TYPE_NODE,
  edge: TYPE_EDGE,
  network: TYPE_NETWORK,
  comment: TYPE_COMMENT,
} as const;

// element labels
export type LabelUser = "user";
export type LabelVpc = "vpc";
export type LabelAvailabilityZone = "availabilityZone";
export type LabelRegion = "region";
export type LabelSubnet = "subnet";
export type LabelDns = "dns";
export type LabelLoadBalancer = "loadBalancer";
export type LabelVm = "vm";
export type LabelServerless = "serverless";
export type LabelSql = "sql";
export type LABELNosql = "nosql";
export type LabelCache = "cache";
export type LabelStorage = "storage";
export type LabelImageRegistry = "imageRegistry";
export type LabelCdn = "cdn";
export type LabelQueue = "queue";
export type LabelPubsub = "pubsub";
export type LabelScheduler = "scheduler";
export type LabelElasticsearch = "elasticsearch";

const LABEL_VPC = "vpc";
const LABEL_SUBNET = "subnet";
const LABEL_AVAILABILITY_ZONE = "availabilityZone";
const LABEL_REGION = "region";
const LABEL_USER = "user";
const LABEL_DNS = "dns";
const LABEL_LOAD_BALANCER = "loadBalancer";
const LABEL_VM = "vm";
const LABEL_SERVERLESS = "serverless";
const LABEL_SQL = "sql";
const LABEL_NOSQL = "nosql";
const LABEL_CACHE = "cache";
const LABEL_STORAGE = "storage";
const LABEL_IMAGE_REGISTRY = "imageRegistry";
const LABEL_CDN = "cdn";
const LABEL_QUEUE = "queue";
const LABEL_PUBSUB = "pubsub";
const LABEL_SCHEDULER = "scheduler";
const LABEL_ELASTICSEARCH = "elasticsearch";

export const ELEMENT_LABEL = {
  vpc: LABEL_VPC,
  subnet: LABEL_SUBNET,
  availabilityZone: LABEL_AVAILABILITY_ZONE,
  region: LABEL_REGION,
  user: LABEL_USER,
  dns: LABEL_DNS,
  loadBalancer: LABEL_LOAD_BALANCER,
  vm: LABEL_VM,
  serverless: LABEL_SERVERLESS,
  sql: LABEL_SQL,
  nosql: LABEL_NOSQL,
  cache: LABEL_CACHE,
  storage: LABEL_STORAGE,
  imageRegistry: LABEL_IMAGE_REGISTRY,
  cdn: LABEL_CDN,
  queue: LABEL_QUEUE,
  pubsub: LABEL_PUBSUB,
  scheduler: LABEL_SCHEDULER,
  elasticsearch: LABEL_ELASTICSEARCH,
} as const;

export type ElementLabel = (typeof ELEMENT_LABEL)[keyof typeof ELEMENT_LABEL];

export const ELEMENT_LABELS = [
  LABEL_VPC,
  LABEL_SUBNET,
  LABEL_AVAILABILITY_ZONE,
  LABEL_REGION,
  LABEL_USER,
  LABEL_DNS,
  LABEL_LOAD_BALANCER,
  LABEL_VM,
  LABEL_SERVERLESS,
  LABEL_SQL,
  LABEL_NOSQL,
  LABEL_CACHE,
  LABEL_STORAGE,
  LABEL_IMAGE_REGISTRY,
  LABEL_CDN,
  LABEL_QUEUE,
  LABEL_PUBSUB,
  LABEL_SCHEDULER,
  LABEL_ELASTICSEARCH,
] as const;

// element categories
const CATEGORY_GENERAL = "general";
const CATEGORY_COMPUTE_RESOURCE = "computeResource";
const CATEGORY_NETWORK = "network";
const CATEGORY_DATASTORE = "database";
const CATEGORY_CACHE = "cache";
const CATEGORY_STORAGE = "storage";
const CATEGORY_MESSAGING = "messaging";

export const ELEMENT_CATEGORY = {
  general: CATEGORY_GENERAL,
  computeResource: CATEGORY_COMPUTE_RESOURCE,
  network: CATEGORY_NETWORK,
  datastore: CATEGORY_DATASTORE,
  cache: CATEGORY_CACHE,
  storage: CATEGORY_STORAGE,
  messaging: CATEGORY_MESSAGING,
} as const;

export type ElementCategory = (typeof ELEMENT_CATEGORY)[keyof typeof ELEMENT_CATEGORY];

export const ELEMENT_CATEGORIES = [
  CATEGORY_GENERAL,
  CATEGORY_COMPUTE_RESOURCE,
  CATEGORY_NETWORK,
  CATEGORY_DATASTORE,
  CATEGORY_CACHE,
  CATEGORY_STORAGE,
  CATEGORY_MESSAGING,
] as const;

// label-to-type
export const LabelTypeMap: Record<ElementLabel, ElementType> = {
  [LABEL_VPC]: TYPE_NETWORK,
  [LABEL_SUBNET]: TYPE_NETWORK,
  [LABEL_AVAILABILITY_ZONE]: TYPE_NETWORK,
  [LABEL_REGION]: TYPE_NETWORK,
  [LABEL_DNS]: TYPE_NODE,
  [LABEL_USER]: TYPE_NODE,
  [LABEL_LOAD_BALANCER]: TYPE_NODE,
  [LABEL_VM]: TYPE_NODE,
  [LABEL_SERVERLESS]: TYPE_NODE,
  [LABEL_SQL]: TYPE_NODE,
  [LABEL_NOSQL]: TYPE_NODE,
  [LABEL_CACHE]: TYPE_NODE,
  [LABEL_STORAGE]: TYPE_NODE,
  [LABEL_IMAGE_REGISTRY]: TYPE_NODE,
  [LABEL_CDN]: TYPE_NODE,
  [LABEL_QUEUE]: TYPE_NODE,
  [LABEL_PUBSUB]: TYPE_NODE,
  [LABEL_SCHEDULER]: TYPE_NODE,
  [LABEL_ELASTICSEARCH]: TYPE_NODE,
} as const;

// label-to-category
export const LabelCategoryMap: Record<ElementLabel, ElementCategory> = {
  [LABEL_VPC]: CATEGORY_COMPUTE_RESOURCE,
  [LABEL_SUBNET]: CATEGORY_NETWORK,
  [LABEL_AVAILABILITY_ZONE]: CATEGORY_GENERAL,
  [LABEL_REGION]: CATEGORY_GENERAL,
  [LABEL_DNS]: CATEGORY_NETWORK,
  [LABEL_USER]: CATEGORY_GENERAL,
  [LABEL_LOAD_BALANCER]: CATEGORY_NETWORK,
  [LABEL_VM]: CATEGORY_COMPUTE_RESOURCE,
  [LABEL_SERVERLESS]: CATEGORY_COMPUTE_RESOURCE,
  [LABEL_SQL]: CATEGORY_DATASTORE,
  [LABEL_NOSQL]: CATEGORY_DATASTORE,
  [LABEL_ELASTICSEARCH]: CATEGORY_DATASTORE,
  [LABEL_CACHE]: CATEGORY_CACHE,
  [LABEL_STORAGE]: CATEGORY_STORAGE,
  [LABEL_IMAGE_REGISTRY]: CATEGORY_STORAGE,
  [LABEL_CDN]: CATEGORY_CACHE,
  [LABEL_QUEUE]: CATEGORY_MESSAGING,
  [LABEL_PUBSUB]: CATEGORY_MESSAGING,
  [LABEL_SCHEDULER]: CATEGORY_MESSAGING,
} as const;

// category-to-labels
export const CategoryLabelsMap: Record<ElementCategory, ElementLabel[]> = {
  [CATEGORY_GENERAL]: [LABEL_USER, LABEL_AVAILABILITY_ZONE, LABEL_REGION],
  [CATEGORY_NETWORK]: [LABEL_VPC, LABEL_SUBNET, LABEL_DNS, LABEL_LOAD_BALANCER],
  [CATEGORY_DATASTORE]: [LABEL_SQL, LABEL_NOSQL, LABEL_ELASTICSEARCH],
  [CATEGORY_CACHE]: [LABEL_CACHE, LABEL_CDN],
  [CATEGORY_COMPUTE_RESOURCE]: [LABEL_VM, LABEL_SERVERLESS],
  [CATEGORY_MESSAGING]: [LABEL_QUEUE, LABEL_PUBSUB, LABEL_SCHEDULER],
  [CATEGORY_STORAGE]: [LABEL_STORAGE, LABEL_IMAGE_REGISTRY],
};

// element settings
export type DefaultSettings = { name: string };
export type SqlSettings = { enableReplication: boolean; replication: Replication; enableSharding: boolean } & DefaultSettings;
export type VmSettings = { autoScale: boolean } & DefaultSettings;
export type CacheSettings = { cachePolicy: CachePolicy } & DefaultSettings;
export type QueueSettings = { queueType: QueueType } & DefaultSettings;
export type EdgeSettings = { direction: Direction };
export type CommentSettings = { fontSize: number };
export type Settings = DefaultSettings | SqlSettings | VmSettings | CacheSettings | QueueSettings;

type NodeElementCommonFields = {
  id: string;
  type: TypeNode;
  geometry: Area;
  updatedAt: number;
};

// elements
export type NodeElement<T = ElementLabel> = T extends LabelSql
  ? NodeElementCommonFields & {
      label: LabelSql;
      settings: SqlSettings;
    }
  : T extends LabelVm
    ? NodeElementCommonFields & {
        label: LabelVm;
        settings: VmSettings;
      }
    : T extends LabelCache
      ? NodeElementCommonFields & {
          label: LabelCache;
          settings: CacheSettings;
        }
      : T extends LabelQueue
        ? NodeElementCommonFields & {
            label: LabelQueue;
            settings: QueueSettings;
          }
        : NodeElementCommonFields & {
            label: T;
            settings: DefaultSettings;
          };

export type NetworkElement<T = ElementLabel> = {
  id: string;
  type: TypeNetwork;
  label: T;
  geometry: Area;
  settings: DefaultSettings;
  updatedAt: number;
};

export type EdgeElement = {
  id: string;
  type: TypeEdge;
  source: string;
  target: string;
  settings: EdgeSettings;
  updatedAt: number;
};

export type CommentElement = {
  id: string;
  type: TypeComment;
  geometry: Area;
  content: string;
  settings: CommentSettings;
  updatedAt: number;
};

export type FlowElement = NodeElement | NetworkElement | EdgeElement | CommentElement;

// A schema which is used to restore the snapshot
// WARNING: Be careful to change this schema because destructive changes may could make it impossible to restore past snapshots
export type FlowSnapshot = {
  version: string;
  componentType: ComponentType;
  elements: FlowElement[];
};

export type GraphPattern = {
  and?: (GraphPattern | FlowSnapshot)[];
  or?: (GraphPattern | FlowSnapshot)[];
  not?: (GraphPattern | FlowSnapshot)[];
};

export interface Coordinate {
  x: number;
  y: number;
}

export interface Area {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
}

// master data
export interface ElementData {
  type: ElementType;
  label?: ElementLabel;
  category?: ElementCategory;
  titleJa: string;
  titleEn: string;
  descriptionJa: string;
  descriptionEn: string;
  initialSettings?: Settings;
  iconImage: string;
}

export const ElementMasterData: Record<ElementKey, ElementData> = {
  [DEFAULT_TEXT]: {
    type: TYPE_COMMENT,
    titleJa: "テキスト",
    titleEn: "Text",
    descriptionJa: "システム構成図の補足説明などに使用できます。",
    descriptionEn: "It can be used for supplementary explanations of system configuration diagrams, etc.",
    iconImage: TextSvgIcon,
  },
  [DEFAULT_VPC]: {
    type: TYPE_NETWORK,
    label: LABEL_VPC,
    category: CATEGORY_NETWORK,
    titleJa: "VPC",
    titleEn: "VPC",
    descriptionJa: "仮想的に閉じたネットワーク空間。",
    descriptionEn: "A virtually closed network space.",
    iconImage: VpcSvgIcon,
  },
  [DEFAULT_SUBNET]: {
    type: TYPE_NETWORK,
    label: LABEL_SUBNET,
    category: CATEGORY_NETWORK,
    titleJa: "サブネット",
    titleEn: "Subnet",
    descriptionJa: "ネットワーク空間を論理的に細分化したもの。",
    descriptionEn: "Logical subdivision of network space.",
    iconImage: SubnetSvgIcon,
  },
  [DEFAULT_AVAILABILITY_ZONE]: {
    type: TYPE_NETWORK,
    label: LABEL_AVAILABILITY_ZONE,
    category: CATEGORY_GENERAL,
    titleJa: "AZ",
    titleEn: "AZ",
    descriptionJa: "アベイラビリティゾーン。物理的に分断されたネットワーク空間。",
    descriptionEn: "Availability zone. A physically fragmented network space.",
    iconImage: AvailabilityZoneSvgIcon,
  },
  [DEFAULT_REGION]: {
    type: TYPE_NETWORK,
    label: LABEL_REGION,
    category: CATEGORY_GENERAL,
    titleJa: "リージョン",
    titleEn: "Region",
    descriptionJa: "地理的に分断されたネットワーク空間。",
    descriptionEn: "A geographically fragmented network space.",
    iconImage: RegionSvgIcon,
  },
  [DEFAULT_USER]: {
    type: TYPE_NODE,
    label: LABEL_USER,
    category: CATEGORY_GENERAL,
    titleJa: "ユーザー",
    titleEn: "User",
    descriptionJa: "Webまたはネイティブアプリなどユーザの操作によってリクエストを行うアプリケーション。",
    descriptionEn: "An application that requests based on user interaction, regardless of whether it is a web or native application.",
    iconImage: UserSvgIcon,
  },
  [DEFAULT_DNS]: {
    type: TYPE_NODE,
    label: LABEL_DNS,
    category: CATEGORY_NETWORK,
    titleJa: "DNS",
    titleEn: "DNS",
    descriptionJa: "ドメインの名前解決を行うシステム。",
    descriptionEn: "A system that provides name resolution for domains.",
    iconImage: DnsSvgIcon,
  },
  [DEFAULT_LOAD_BALANCER]: {
    type: TYPE_NODE,
    label: LABEL_LOAD_BALANCER,
    category: CATEGORY_NETWORK,
    titleJa: "ロードバランサー",
    titleEn: "Load Balancer",
    descriptionJa: "外部からのトラフィックを複数のサーバーに分散するための装置。",
    descriptionEn: "A device used to distribute external traffic to multiple servers.",
    iconImage: LoadBalancerSvgIcon,
  },
  [DEFAULT_VM]: {
    type: TYPE_NODE,
    label: LABEL_VM,
    category: CATEGORY_COMPUTE_RESOURCE,
    titleJa: "VMインスタンス",
    titleEn: "VM instance",
    descriptionJa: "クラウド上に構築されている仮想マシン。APIやバッチ処理といった様々な用途に使用されます。",
    descriptionEn: "A virtual machine built on the cloud service, which can be used in various ways such as API and batch job.",
    iconImage: VmSvgIcon,
  },
  [DEFAULT_SERVERLESS]: {
    type: TYPE_NODE,
    label: LABEL_SERVERLESS,
    category: CATEGORY_COMPUTE_RESOURCE,
    titleJa: "サーバーレス",
    titleEn: "Serverless",
    descriptionJa:
      "クラウドプロバイダーによって必要なリソースが動的に割り当てられ、開発者がサーバーを管理することなくプログラムを実行できるコンピューティングサービス。",
    descriptionEn:
      "A computing service in which the required resources are dynamically allocated by the cloud provider, allowing developers to run programs without managing servers.",
    iconImage: ServerlessSvgIcon,
  },
  [DEFAULT_SQL]: {
    type: TYPE_NODE,
    label: LABEL_SQL,
    category: CATEGORY_DATASTORE,
    titleJa: "SQL",
    titleEn: "SQL",
    descriptionJa: "関係モデルのデータを管理することができるデータベース。",
    descriptionEn: "A database that can manage data of relational models.",
    iconImage: SqlSvgIcon,
  },
  [DEFAULT_NOSQL]: {
    type: TYPE_NODE,
    label: LABEL_NOSQL,
    category: CATEGORY_DATASTORE,
    titleJa: "NoSQL",
    titleEn: "NoSQL",
    descriptionJa: "関係モデル以外の様々なデータ構造を管理することができるデータベース。",
    descriptionEn: "A database that can manage a variety of data structures other than relational models.",
    iconImage: NosqlSvgIcon,
  },
  [DEFAULT_ELASTICSEARCH]: {
    type: TYPE_NODE,
    label: LABEL_ELASTICSEARCH,
    category: CATEGORY_DATASTORE,
    titleJa: "Elasticsearch",
    titleEn: "Elasticsearch",
    descriptionJa: "分散型の検索・分析エンジン。ロブ分析やビジネス分析など多様な用途に使用されます。",
    descriptionEn: "Distributed search and analysis engine. It is used for a variety of use cases, such as lob analysis or business analysis.",
    iconImage: ElasticsearchSvgIcon,
  },
  [DEFAULT_CACHE]: {
    type: TYPE_NODE,
    label: LABEL_CACHE,
    category: CATEGORY_CACHE,
    titleJa: "キャッシュ",
    titleEn: "Cache",
    descriptionJa: "使用頻度の高いデータを高速な記憶装置に一時保存しておくことでデータ処理を高速化するための仕組み。",
    descriptionEn: "A mechanism to speed up data processing by temporarily storing frequently used data in a high-speed storage device.",
    iconImage: CacheTtlSvgIcon,
  },
  [DEFAULT_STORAGE]: {
    type: TYPE_NODE,
    label: LABEL_STORAGE,
    category: CATEGORY_STORAGE,
    titleJa: "ストレージ",
    titleEn: "Storage",
    descriptionJa: "オブジェクトデータストア。静的ファイルを保存する際に利用されます。",
    descriptionEn: "This is Object data store used for saving static files.",
    iconImage: StorageSvgIcon,
  },
  [DEFAULT_IMAGE_REGISTRY]: {
    type: TYPE_NODE,
    label: LABEL_IMAGE_REGISTRY,
    category: CATEGORY_STORAGE,
    titleJa: "イメージレジストリ",
    titleEn: "Image Registry",
    descriptionJa: "Dockerなどのコンテナエンジンが扱うイメージファイルを保管する場所。",
    descriptionEn: "A location where image files handled by container engines such as Docker are stored.",
    iconImage: ImageRegistrySvgIcon,
  },
  [DEFAULT_CDN]: {
    type: TYPE_NODE,
    label: LABEL_CDN,
    category: CATEGORY_CACHE,
    titleJa: "CDN",
    titleEn: "CDN",
    descriptionJa: "Webコンテンツをインターネット経由で配信するために最適化されたネットワーク。",
    descriptionEn: "A network optimized for distributing Web content via the Internet.",
    iconImage: CdnSvgIcon,
  },
  [DEFAULT_QUEUE]: {
    type: TYPE_NODE,
    label: LABEL_QUEUE,
    category: CATEGORY_MESSAGING,
    titleJa: "キュー",
    titleEn: "Queue",
    descriptionJa: "キューイング方式でメッセージ処理を行うサービス。",
    descriptionEn: "A service that processes messages using a queuing formula.",
    iconImage: QueueSvgIcon,
  },
  [DEFAULT_PUBSUB]: {
    type: TYPE_NODE,
    label: LABEL_PUBSUB,
    category: CATEGORY_MESSAGING,
    titleJa: "Pub/Sub",
    titleEn: "Pub/Sub",
    descriptionJa: "Publish-Subscribe方式でメッセージを送受信するサービス。",
    descriptionEn: "A service that sends and receives messages using Publish-Subscribe formula.",
    iconImage: PubsubSvgIcon,
  },
  [DEFAULT_SCHEDULER]: {
    type: TYPE_NODE,
    label: LABEL_SCHEDULER,
    category: CATEGORY_MESSAGING,
    titleJa: "スケジューラー",
    titleEn: "Scheduler",
    descriptionJa: "バッチ処理などのジョブを指定した間隔でスケジューリングして実行することができます。",
    descriptionEn: "Jobs such as batch processing can be scheduled and executed at specified intervals.",
    iconImage: SchedulerSvgIcon,
  },
  [AWS_ROUTE53]: {
    type: TYPE_NODE,
    label: LABEL_DNS,
    category: CATEGORY_NETWORK,
    titleJa: "Route53",
    titleEn: "Route53",
    descriptionJa: "可用性と拡張性に優れたクラウドのドメインネームシステム (DNS) ウェブサービス。",
    descriptionEn: "A highly available and scalable Domain Name System (DNS) web service.",
    iconImage: AwsRoute53SvgIcon,
  },
  [AWS_ELB]: {
    type: TYPE_NODE,
    label: LABEL_LOAD_BALANCER,
    category: CATEGORY_NETWORK,
    titleJa: "ELB",
    titleEn: "ELB",
    descriptionJa: "アプリケーションへのトラフィックを1つまたは複数のアベイラビリティゾーン（AZ）内の複数のサーバーに自動的に分散する仕組み。",
    descriptionEn:
      "A system which automatically distributes incoming application traffic across multiple servers in one or more Availability Zones (AZs).",
    iconImage: AwsElbSvgIcon,
  },
  [AWS_EC2]: {
    type: TYPE_NODE,
    label: LABEL_VM,
    category: CATEGORY_COMPUTE_RESOURCE,
    titleJa: "EC2",
    titleEn: "EC2",
    descriptionJa: "多様なワークロードのニーズに最適に対応できるコンピューティングプラットフォーム。",
    descriptionEn: "The broadest and deepest compute platform　to help you best match the needs of your workload.",
    iconImage: AwsEc2SvgIcon,
  },
  [AWS_LAMBDA]: {
    type: TYPE_NODE,
    label: LABEL_SERVERLESS,
    category: CATEGORY_COMPUTE_RESOURCE,
    titleJa: "Lambda",
    titleEn: "Lambda",
    descriptionJa:
      "サーバーレスでイベント駆動型のコンピューティングサービスであり、サーバーのプロビジョニングや管理をすることなく、事実上あらゆるタイプのアプリケーションやバックエンドサービスのコードを実行することができます。",
    descriptionEn:
      "A serverless, event-driven compute service that lets you run code for virtually any type of application or backend service without provisioning or managing servers.",
    iconImage: AwsLambdaSvgIcon,
  },
  [AWS_RDS]: {
    type: TYPE_NODE,
    label: LABEL_SQL,
    category: CATEGORY_DATASTORE,
    titleJa: "RDS",
    titleEn: "RDS",
    descriptionJa: "クラウド内でデータベースのセットアップ、運用、およびスケールを簡単に行うことのできるマネージド型サービスの集合体。",
    descriptionEn:
      "A collection of managed services that makes it simple to set up, operate, and scale databases in the cloud. Choose from seven popular engines.",
    iconImage: AwsRdsSvgIcon,
  },
  [AWS_DYNAMODB]: {
    type: TYPE_NODE,
    label: LABEL_NOSQL,
    category: CATEGORY_DATASTORE,
    titleJa: "DynamoDB",
    titleEn: "DynamoDB",
    descriptionJa: "フルマネージドでサーバーレスの key-value NoSQL データベース。",
    descriptionEn: "A fully managed, serverless, key-value NoSQL database.",
    iconImage: AwsDynamoDbSvgIcon,
  },
  [AWS_ELASTIC_CACHE]: {
    type: TYPE_NODE,
    label: LABEL_CACHE,
    category: CATEGORY_CACHE,
    titleJa: "ElasticCache",
    titleEn: "ElasticCache",
    descriptionJa: "柔軟なリアルタイムのユースケースをサポートするフルマネージドのインメモリキャッシングサービス。",
    descriptionEn: "A fully managed, in-memory caching service supporting flexible, real-time use cases.",
    iconImage: AwsElasticCacheSvgIcon,
  },
  [AWS_S3]: {
    type: TYPE_NODE,
    label: LABEL_STORAGE,
    category: CATEGORY_STORAGE,
    titleJa: "S3",
    titleEn: "S3",
    descriptionJa: "スケーラビリティ、データ可用性、セキュリティ、およびパフォーマンスを提供するオブジェクトストレージサービス。",
    descriptionEn: "An object storage service offering industry-leading scalability, data availability, security, and performance.",
    iconImage: AwsS3SvgIcon,
  },
  [AWS_ECR]: {
    type: TYPE_NODE,
    label: LABEL_IMAGE_REGISTRY,
    category: CATEGORY_STORAGE,
    titleJa: "ECR",
    titleEn: "ECR",
    descriptionJa: "ハイパフォーマンスなホスティングを提供するフルマネージドコンテナレジストリ。",
    descriptionEn: "A fully managed container registry offering high-performance hosting.",
    iconImage: AwsEcrSvgIcon,
  },
  [AWS_CLOUDFRONT]: {
    type: TYPE_NODE,
    label: LABEL_CDN,
    category: CATEGORY_CACHE,
    titleJa: "CloudFront",
    titleEn: "CloudFront",
    descriptionJa: "高いパフォーマンス、セキュリティ、デベロッパーの利便性のために構築されたコンテンツ配信ネットワーク (CDN) サービス。",
    descriptionEn: "A content delivery network (CDN) service built for high performance, security, and developer convenience",
    iconImage: AwsCloudFrontSvgIcon,
  },
  [AWS_SQS]: {
    type: TYPE_NODE,
    label: LABEL_QUEUE,
    category: CATEGORY_MESSAGING,
    titleJa: "SQS",
    titleEn: "SQS",
    descriptionJa: "フルマネージド型のメッセージキューイングサービス。",
    descriptionEn: "A fully managed message queuing service.",
    iconImage: AwsSqsSvgIcon,
  },
  [AWS_SNS]: {
    type: TYPE_NODE,
    label: LABEL_PUBSUB,
    category: CATEGORY_MESSAGING,
    titleJa: "SNS",
    titleEn: "SNS",
    descriptionJa:
      "アプリケーション対アプリケーション（A2A）間と、アプリケーション対個人（A2P）間の両方の通信に使用できる、フルマネージド型メッセージングサービス。",
    descriptionEn:
      "A fully managed messaging service that can be used for both application-to-application (A2A) and application-to-individual (A2P) communications.",
    iconImage: AwsSnsSvgIcon,
  },
  [AWS_CLOUDWATCH]: {
    type: TYPE_NODE,
    label: LABEL_SCHEDULER,
    category: CATEGORY_MESSAGING,
    titleJa: "CloudWatch",
    titleEn: "CloudWatch",
    descriptionJa: "アプリケーションをモニタリングし、環境内における異常動作の検知や自動化されたアクションの実行を行うサービス。",
    descriptionEn: "A service that monitors applications, detects abnormal behavior in the environment, and executes automated actions.",
    iconImage: AwsCloudWatchSvgIcon,
  },
  [GCP_CLOUD_DNS]: {
    type: TYPE_NODE,
    label: LABEL_DNS,
    category: CATEGORY_NETWORK,
    titleJa: "Cloud DNS",
    titleEn: "Cloud DNS",
    descriptionJa: "ドメインの登録、管理、提供に必要なすべてを備えた信頼性と復元性に優れたレイテンシの低いDNSサービス。",
    descriptionEn: "Reliable, resilient and low-latency DNS service with everything you need to register, manage, and serve domains.",
    iconImage: GcpCloudDnsSvgIcon,
  },
  [GCP_CLOUD_LOAD_BALANCING]: {
    type: TYPE_NODE,
    label: LABEL_LOAD_BALANCER,
    category: CATEGORY_NETWORK,
    titleJa: "Cloud Load Balancing",
    titleEn: "Cloud Load Balancing",
    descriptionJa: "パフォーマンスとスケーラビリティに優れた負荷分散を実現するサービス。",
    descriptionEn: "A service that provides load balancing with excellent performance and scalability.",
    iconImage: GcpCloudLoadBalancingSvgIcon,
  },
  [GCP_COMPUTE_ENGINE]: {
    type: TYPE_NODE,
    label: LABEL_VM,
    category: CATEGORY_COMPUTE_RESOURCE,
    titleJa: "Compute Engine",
    titleEn: "Compute Engine",
    descriptionJa: "Googleのインフラストラクチャで仮想マシンを作成して実行できる、安全性の高いカスタマイズ可能なコンピューティングサービス",
    descriptionEn: "Secure and customizable compute service that lets you create and run virtual machines on Google’s infrastructure.",
    iconImage: GcpComputeEngineSvgIcon,
  },
  [GCP_CLOUD_FUNCTIONS]: {
    type: TYPE_NODE,
    label: LABEL_SERVERLESS,
    category: CATEGORY_COMPUTE_RESOURCE,
    titleJa: "Cloud Functions",
    titleEn: "Cloud Functions",
    descriptionJa: "サーバーやコンテナによる管理なしでクラウド上でコードを実行できるサービス。",
    descriptionEn: "A service that allows code to run in the cloud without managing servers or containers",
    iconImage: GcpCloudFunctionSvgIcon,
  },
  [GCP_CLOUD_SQL]: {
    type: TYPE_NODE,
    label: LABEL_SQL,
    category: CATEGORY_DATASTORE,
    titleJa: "Cloud SQL",
    titleEn: "Cloud SQL",
    descriptionJa: "MySQL、PostgreSQL、SQL Server向けのフルマネージドリレーショナルデータベースサービス。",
    descriptionEn: "Fully managed relational database service for MySQL, PostgreSQL, and SQL Server.",
    iconImage: GcpCloudSqlSvgIcon,
  },
  [GCP_FIRESTORE]: {
    type: TYPE_NODE,
    label: LABEL_NOSQL,
    category: CATEGORY_DATASTORE,
    titleJa: "Firestore",
    titleEn: "Firestore",
    descriptionJa: "FirebaseとGoogle Cloudからのモバイル、ウェブ、サーバー開発に対応した、柔軟でスケーラブルなデータベース。",
    descriptionEn: "A flexible, scalable database for mobile, web, and server development from Firebase and Google Cloud.",
    iconImage: GcpFirestoreSvgIcon,
  },
  [GCP_MEMORYSTORE]: {
    type: TYPE_NODE,
    label: LABEL_CACHE,
    category: CATEGORY_CACHE,
    titleJa: "Memorystore",
    titleEn: "Memorystore",
    descriptionJa: "スケーラビリティ、セキュリティ、可用性に優れたRedisとMemcached向けのインメモリサービス。",
    descriptionEn: "Reduce latency with scalable, secure, and highly available in-memory service for Redis and Memcached.",
    iconImage: GcpMemoryStoreSvgIcon,
  },
  [GCP_CLOUD_STORAGE]: {
    type: TYPE_NODE,
    label: LABEL_STORAGE,
    category: CATEGORY_STORAGE,
    titleJa: "Cloud Storage",
    titleEn: "Cloud Storage",
    descriptionJa: "あらゆる規模の企業に対応するオブジェクトストレージ。",
    descriptionEn: "Object storage for companies of all sizes. Store any amount of data.",
    iconImage: GcpCloudStorageSvgIcon,
  },
  [GCP_CONTAINER_REGISTRY]: {
    type: TYPE_NODE,
    label: LABEL_IMAGE_REGISTRY,
    category: CATEGORY_STORAGE,
    titleJa: "Container Registry",
    titleEn: "Container Registry",
    descriptionJa: "Dockerコンテナイメージの保存、管理、保護を行うサービス。",
    descriptionEn: "A service that stores, manages, and protects Docker container images.",
    iconImage: GcpContainerRegistrySvgIcon,
  },
  [GCP_CLOUD_CDN]: {
    type: TYPE_NODE,
    label: LABEL_CDN,
    category: CATEGORY_CACHE,
    titleJa: "Cloud CDN",
    titleEn: "Cloud CDN",
    descriptionJa: "世界中のユーザーにウェブコンテンツや動画コンテンツをすばやく確実に配信するサービス。",
    descriptionEn: "A service that quickly and reliably delivers web and video content to users around the world.",
    iconImage: GcpCloudCdnSvgIcon,
  },
  [GCP_CLOUD_TASKS]: {
    type: TYPE_NODE,
    label: LABEL_QUEUE,
    category: CATEGORY_MESSAGING,
    titleJa: "Cloud Tasks",
    titleEn: "Cloud Tasks",
    descriptionJa: "大量の分散タスクの実行、ディスパッチ、配信を管理できるフルマネージドサービス。",
    descriptionEn:
      "A fully managed service that allows you to manage the execution, dispatch, and delivery of a large number of distributed tasks.",
    iconImage: GcpCloudTasksSvgIcon,
  },
  [GCP_PUBSUB]: {
    type: TYPE_NODE,
    label: LABEL_PUBSUB,
    category: CATEGORY_MESSAGING,
    titleJa: "Pub/Sub",
    titleEn: "Pub/Sub",
    descriptionJa: "データを取り込んで配布するためのストリーミング分析とデータ統合パイプラインに使用されるサービス。",
    descriptionEn: "A service used for streaming analytics and data integration pipeline to capture and distribute data.",
    iconImage: GcpPubsubSvgIcon,
  },
  [GCP_CLOUD_SCHEDULER]: {
    type: TYPE_NODE,
    label: LABEL_SCHEDULER,
    category: CATEGORY_MESSAGING,
    titleJa: "Cloud Scheduler",
    titleEn: "Cloud Scheduler",
    descriptionJa: "エンタープライズクラスのフルマネージドなcronジョブスケジューラ。",
    descriptionEn: "A fully managed enterprise-grade cron job scheduler.",
    iconImage: GcpCloudSchedulerSvgIcon,
  },
  [AZURE_DNS]: {
    type: TYPE_NODE,
    label: LABEL_DNS,
    category: CATEGORY_NETWORK,
    titleJa: "Azure DNS",
    titleEn: "Azure DNS",
    descriptionJa: "Microsoft Azureインフラストラクチャを使用した名前解決を提供するDNSドメインのホスティングサービス。",
    descriptionEn: "A hosting service for DNS domains that provides name resolution by using Microsoft Azure infrastructure.",
    iconImage: AzureDnsSvgIcon,
  },
  [AZURE_LOAD_BALANCER]: {
    type: TYPE_NODE,
    label: LABEL_LOAD_BALANCER,
    category: CATEGORY_NETWORK,
    titleJa: "Azure Load Balancer",
    titleEn: "Azure Load Balancer",
    descriptionJa: "外部トラフィックをバックエンドリソースまたはサーバーのグループ全体に均等に分散するサービス。",
    descriptionEn: "A service that evenly distributes external traffic across a group of back-end resources or servers.",
    iconImage: AzureLoadBalancerSvgIcon,
  },
  [AZURE_VIRTUAL_MACHINES]: {
    type: TYPE_NODE,
    label: LABEL_VM,
    category: CATEGORY_COMPUTE_RESOURCE,
    titleJa: "Azure Virtual Machines",
    titleEn: "Azure Virtual Machines",
    descriptionJa: "Azureで提供されるオンデマンドでスケーラブルなコンピューティングリソース。",
    descriptionEn: "One of several types of on-demand, scalable computing resources that Azure offers",
    iconImage: AzureVirtualMachinesSvgIcon,
  },
  [AZURE_FUNCTIONS]: {
    type: TYPE_NODE,
    label: LABEL_SERVERLESS,
    category: CATEGORY_COMPUTE_RESOURCE,
    titleJa: "Azure Functions",
    titleEn: "Azure Functions",
    descriptionJa: "記述するコードと管理するインフラストラクチャを減らし、コストを節約できるサーバーレスソリューション。",
    descriptionEn: "A serverless solution that allows you to write less code, maintain less infrastructure, and save on costs.",
    iconImage: AzureFunctionsSvgIcon,
  },
  [AZURE_SQL_DATABASE]: {
    type: TYPE_NODE,
    label: LABEL_SQL,
    category: CATEGORY_DATASTORE,
    titleJa: "Azure SQL Database",
    titleEn: "Azure SQL Database",
    descriptionJa: "クラウド向けにビルドされたフルマネージドリレーショナルデータベースサービス。",
    descriptionEn: "Fully managed relational database service built for the cloud.",
    iconImage: AzureSqlDatabaseSvgIcon,
  },
  [AZURE_COSMOS_DB]: {
    type: TYPE_NODE,
    label: LABEL_NOSQL,
    category: CATEGORY_DATASTORE,
    titleJa: "Azure Cosmos DB",
    titleEn: "Azure Cosmos DB",
    descriptionJa: "フルマネージドでサーバーレスのNoSQLデータベース。",
    descriptionEn: "Fully managed, serverless NoSQL database.",
    iconImage: AzureCosmosDbSvgIcon,
  },
  [AZURE_CACHE_FOR_REDIS]: {
    type: TYPE_NODE,
    label: LABEL_CACHE,
    category: CATEGORY_CACHE,
    titleJa: "Azure Cache for Redis",
    titleEn: "Azure Cache for Redis",
    descriptionJa: "Redisソフトウェアを基にしたインメモリデータストア。",
    descriptionEn: "An in-memory data store based on the Redis.",
    iconImage: AzureCacheForRedisSvgIcon,
  },
  [AZURE_STORAGE]: {
    type: TYPE_NODE,
    label: LABEL_STORAGE,
    category: CATEGORY_STORAGE,
    titleJa: "Azure Storage",
    titleEn: "Azure Storage",
    descriptionJa: "可用性が高く、スケーラビリティ、持続性、安全性に優れたクラウドストレージ。",
    descriptionEn: "Highly available, scalable, persistent, and secure cloud storage.",
    iconImage: AzureStorageSvgIcon,
  },
  [AZURE_CONTAINER_REGISTRY]: {
    type: TYPE_NODE,
    label: LABEL_IMAGE_REGISTRY,
    category: CATEGORY_STORAGE,
    titleJa: "Azure Container Registry",
    titleEn: "Azure Container Registry",
    descriptionJa: "コンテナイメージや成果物をビルド、保存、管理できるプライベートレジストリ。",
    descriptionEn: "A private registry where container images and artifacts can be built, stored, and managed.",
    iconImage: AzureContainerRegistrySvgIcon,
  },
  [AZURE_CONTENT_DELIVERY_NETWORK]: {
    type: TYPE_NODE,
    label: LABEL_CDN,
    category: CATEGORY_CACHE,
    titleJa: "Azure Content Delivery Network",
    titleEn: "Azure Content Delivery Network",
    descriptionJa: "ユーザーにWebコンテンツを効率的に配信できるサーバーの分散ネットワーク。",
    descriptionEn: "A distributed network of servers that can efficiently deliver web content to users.",
    iconImage: AzureContentDeliveryNetworkSvgIcon,
  },
  [AZURE_QUEUE_STORAGE]: {
    type: TYPE_NODE,
    label: LABEL_QUEUE,
    category: CATEGORY_MESSAGING,
    titleJa: "Azure Queue Storage",
    titleEn: "Azure Queue Storage",
    descriptionJa: "ストレージを利用したキューメッセージングサービス。",
    descriptionEn: "Storage-based queue messaging services.",
    iconImage: AzureQueueStorageSvgIcon,
  },
  [AZURE_WEB_PUB_SUB]: {
    type: TYPE_NODE,
    label: LABEL_PUBSUB,
    category: CATEGORY_MESSAGING,
    titleJa: "Azure Service Bus",
    titleEn: "Azure Service Bus",
    descriptionJa: "パブリッシュとサブスクライブトピックを備えたフルマネージドなエンタープライズ統合メッセージブローカー。",
    descriptionEn: "Fully managed enterprise integrated message broker with publish and subscribe topics.",
    iconImage: AzureServiceBusSvgIcon,
  },
  [AZURE_SCHEDULER]: {
    type: TYPE_NODE,
    label: LABEL_SCHEDULER,
    category: CATEGORY_MESSAGING,
    titleJa: "Azure Scheduler",
    titleEn: "Azure Scheduler",
    descriptionJa:
      "HTTP/Sエンドポイントの呼び出し、Azure Storageキューへのメッセージの送信などのジョブを任意のスケジュールで実行できるサービス。",
    descriptionEn:
      "A service that allows jobs such as calling HTTP/S endpoints, sending messages to Azure Storage queues, etc. to run on an arbitrary schedule.",
    iconImage: AzureSchedulerSvgIcon,
  },
};

// label-to-defaultKey
export const defaultLabelKeyMap: Record<ElementLabel, ElementKey> = {
  [ELEMENT_LABEL.vpc]: ELEMENT_KEY.defaultVpc,
  [ELEMENT_LABEL.subnet]: ELEMENT_KEY.defaultSubnet,
  [ELEMENT_LABEL.availabilityZone]: ELEMENT_KEY.defaultAvailabilityZone,
  [ELEMENT_LABEL.region]: ELEMENT_KEY.defaultRegion,
  [ELEMENT_LABEL.user]: ELEMENT_KEY.defaultUser,
  [ELEMENT_LABEL.dns]: ELEMENT_KEY.defaultDns,
  [ELEMENT_LABEL.loadBalancer]: ELEMENT_KEY.defaultLoadBalancer,
  [ELEMENT_LABEL.vm]: ELEMENT_KEY.defaultVm,
  [ELEMENT_LABEL.serverless]: ELEMENT_KEY.defaultServerless,
  [ELEMENT_LABEL.sql]: ELEMENT_KEY.defaultSql,
  [ELEMENT_LABEL.nosql]: ELEMENT_KEY.defaultNosql,
  [ELEMENT_LABEL.elasticsearch]: ELEMENT_KEY.defaultElasticsearch,
  [ELEMENT_LABEL.cache]: ELEMENT_KEY.defaultCache,
  [ELEMENT_LABEL.storage]: ELEMENT_KEY.defaultStorage,
  [ELEMENT_LABEL.imageRegistry]: ELEMENT_KEY.defaultImageRegistry,
  [ELEMENT_LABEL.cdn]: ELEMENT_KEY.defaultCdn,
  [ELEMENT_LABEL.queue]: ELEMENT_KEY.defaultQueue,
  [ELEMENT_LABEL.pubsub]: ELEMENT_KEY.defaultPubsub,
  [ELEMENT_LABEL.scheduler]: ELEMENT_KEY.defaultScheduler,
};

// label-to-awsKey
export const awsLabelKeyMap: Record<ElementLabel, ElementKey> = {
  [ELEMENT_LABEL.vpc]: ELEMENT_KEY.defaultVpc,
  [ELEMENT_LABEL.subnet]: ELEMENT_KEY.defaultSubnet,
  [ELEMENT_LABEL.availabilityZone]: ELEMENT_KEY.defaultAvailabilityZone,
  [ELEMENT_LABEL.region]: ELEMENT_KEY.defaultRegion,
  [ELEMENT_LABEL.user]: ELEMENT_KEY.defaultUser,
  [ELEMENT_LABEL.dns]: ELEMENT_KEY.awsRoute53,
  [ELEMENT_LABEL.loadBalancer]: ELEMENT_KEY.awsElb,
  [ELEMENT_LABEL.vm]: ELEMENT_KEY.awsEc2,
  [ELEMENT_LABEL.serverless]: ELEMENT_KEY.awsLambda,
  [ELEMENT_LABEL.sql]: ELEMENT_KEY.awsRds,
  [ELEMENT_LABEL.nosql]: ELEMENT_KEY.awsDynamodb,
  [ELEMENT_LABEL.elasticsearch]: ELEMENT_KEY.defaultElasticsearch,
  [ELEMENT_LABEL.cache]: ELEMENT_KEY.awsElasticCache,
  [ELEMENT_LABEL.storage]: ELEMENT_KEY.awsS3,
  [ELEMENT_LABEL.imageRegistry]: ELEMENT_KEY.awsEcr,
  [ELEMENT_LABEL.cdn]: ELEMENT_KEY.awsCloudFront,
  [ELEMENT_LABEL.queue]: ELEMENT_KEY.awsSqs,
  [ELEMENT_LABEL.pubsub]: ELEMENT_KEY.awsSns,
  [ELEMENT_LABEL.scheduler]: ELEMENT_KEY.awsCloudwatch,
};

// label-to-gcpKey
export const gcpLabelKeyMap: Record<ElementLabel, ElementKey> = {
  [ELEMENT_LABEL.vpc]: ELEMENT_KEY.defaultVpc,
  [ELEMENT_LABEL.subnet]: ELEMENT_KEY.defaultSubnet,
  [ELEMENT_LABEL.availabilityZone]: ELEMENT_KEY.defaultAvailabilityZone,
  [ELEMENT_LABEL.region]: ELEMENT_KEY.defaultRegion,
  [ELEMENT_LABEL.user]: ELEMENT_KEY.defaultUser,
  [ELEMENT_LABEL.dns]: ELEMENT_KEY.gcpCloudDns,
  [ELEMENT_LABEL.loadBalancer]: ELEMENT_KEY.gcpCloudLoadBalancing,
  [ELEMENT_LABEL.vm]: ELEMENT_KEY.gcpComputeEngine,
  [ELEMENT_LABEL.serverless]: ELEMENT_KEY.gcpCloudFunctions,
  [ELEMENT_LABEL.sql]: ELEMENT_KEY.gcpCloudSql,
  [ELEMENT_LABEL.nosql]: ELEMENT_KEY.gcpFirestore,
  [ELEMENT_LABEL.elasticsearch]: ELEMENT_KEY.defaultElasticsearch,
  [ELEMENT_LABEL.cache]: ELEMENT_KEY.gcpMemorystore,
  [ELEMENT_LABEL.storage]: ELEMENT_KEY.gcpCloudStorage,
  [ELEMENT_LABEL.imageRegistry]: ELEMENT_KEY.gcpContainerRegistry,
  [ELEMENT_LABEL.cdn]: ELEMENT_KEY.gcpCloudCdn,
  [ELEMENT_LABEL.queue]: ELEMENT_KEY.gcpCloudTasks,
  [ELEMENT_LABEL.pubsub]: ELEMENT_KEY.gcpPubsub,
  [ELEMENT_LABEL.scheduler]: ELEMENT_KEY.gcpCloudScheduler,
};

// label-to-azureKey
export const azureLabelKeyMap: Record<ElementLabel, ElementKey> = {
  [ELEMENT_LABEL.vpc]: ELEMENT_KEY.defaultVpc,
  [ELEMENT_LABEL.subnet]: ELEMENT_KEY.defaultSubnet,
  [ELEMENT_LABEL.availabilityZone]: ELEMENT_KEY.defaultAvailabilityZone,
  [ELEMENT_LABEL.region]: ELEMENT_KEY.defaultRegion,
  [ELEMENT_LABEL.user]: ELEMENT_KEY.defaultUser,
  [ELEMENT_LABEL.dns]: ELEMENT_KEY.azureDns,
  [ELEMENT_LABEL.loadBalancer]: ELEMENT_KEY.azureLoadBalancer,
  [ELEMENT_LABEL.vm]: ELEMENT_KEY.azureVirtualMachines,
  [ELEMENT_LABEL.serverless]: ELEMENT_KEY.azureFunctions,
  [ELEMENT_LABEL.sql]: ELEMENT_KEY.azureSqlDatabase,
  [ELEMENT_LABEL.nosql]: ELEMENT_KEY.azureCosmosDb,
  [ELEMENT_LABEL.elasticsearch]: ELEMENT_KEY.defaultElasticsearch,
  [ELEMENT_LABEL.cache]: ELEMENT_KEY.azureCacheForRedis,
  [ELEMENT_LABEL.storage]: ELEMENT_KEY.azureStorage,
  [ELEMENT_LABEL.imageRegistry]: ELEMENT_KEY.azureContainerRegistry,
  [ELEMENT_LABEL.cdn]: ELEMENT_KEY.azureContentDeliveryNetwork,
  [ELEMENT_LABEL.queue]: ELEMENT_KEY.azureQueueStorage,
  [ELEMENT_LABEL.pubsub]: ELEMENT_KEY.azureWebPubSub,
  [ELEMENT_LABEL.scheduler]: ELEMENT_KEY.azureScheduler,
};

export type ComponentType = "default" | "aws" | "gcp" | "azure";
export const COMPONENT_TYPE = {
  default: "default",
  aws: "aws",
  gcp: "gcp",
  azure: "azure",
} as const;

// componentType-to-labelKeyMap
export const ElementDataKeyMap: Record<ComponentType, Record<ElementLabel, ElementKey>> = {
  [COMPONENT_TYPE.default]: defaultLabelKeyMap,
  [COMPONENT_TYPE.aws]: awsLabelKeyMap,
  [COMPONENT_TYPE.gcp]: gcpLabelKeyMap,
  [COMPONENT_TYPE.azure]: azureLabelKeyMap,
};

const SQL_PRIMARY = "sqlPrimary";
const SQL_REPLICA = "sqlReplica";
const SQL_SHARDING = "sqlSharding";
const CACHE_TTL = "cacheTtl";
const CACHE_LRU = "cacheLru";
const CACHE_LFU = "cacheLfu";
const CACHE_FIFO = "cacheFifo";
const QUEUE_STANDARD = "queueStandard";
const QUEUE_FIFO = "queueFifo";

export const SETTINGS_KEY = {
  sqlPrimary: SQL_PRIMARY,
  sqlReplica: SQL_REPLICA,
  sqlSharding: SQL_SHARDING,
  cacheTtl: CACHE_TTL,
  cacheLru: CACHE_LRU,
  cacheLfu: CACHE_LFU,
  cacheFifo: CACHE_FIFO,
  queueStandard: QUEUE_STANDARD,
  queueFifo: QUEUE_FIFO,
} as const;

export type SettingsDataKey = (typeof SETTINGS_KEY)[keyof typeof SETTINGS_KEY];

export type SettingsData = {
  titleJa: string;
  titleEn: string;
  descriptionJa: string;
  descriptionEn: string;
  iconImage: string;
};

export const SettingsMasterData: Record<SettingsDataKey, SettingsData> = {
  [SQL_PRIMARY]: {
    titleJa: "プライマリー",
    titleEn: "Primary",
    descriptionJa: "常時処理を行う複製元のDB",
    descriptionEn: "Always-processing DB from which to replicate",
    iconImage: SqlPrimaryIcon,
  },
  [SQL_REPLICA]: {
    titleJa: "レプリカ",
    titleEn: "Replica",
    descriptionJa: "主に参照に使用される複製先のDB",
    descriptionEn: "Replicated DB which is mainly used for reference",
    iconImage: SqlReplicaIcon,
  },
  [SQL_SHARDING]: {
    titleJa: "シャーディング",
    titleEn: "Sharding",
    descriptionJa: "テーブルを複数のDBに水平分割すること",
    descriptionEn: "Horizontal partitioning of tables into multiple DBs",
    iconImage: SqlShardingIcon,
  },
  [CACHE_TTL]: {
    titleJa: "TTL",
    titleEn: "TTL",
    descriptionJa: "一定時間経過後に破棄",
    descriptionEn: "Time to live",
    iconImage: CacheTtlIcon,
  },
  [CACHE_LRU]: {
    titleJa: "LRU",
    titleEn: "LRU",
    descriptionJa: "最も長く使われなかったものから破棄",
    descriptionEn: "Least recently used",
    iconImage: CacheLruIcon,
  },
  [CACHE_LFU]: {
    titleJa: "LFU",
    titleEn: "LFU",
    descriptionJa: "使用頻度が最も低いものから破棄",
    descriptionEn: "Least frequently used",
    iconImage: CacheLfuIcon,
  },
  [CACHE_FIFO]: {
    titleJa: "FIFO",
    titleEn: "FIFO",
    descriptionJa: "最も古いものから破棄",
    descriptionEn: "First In, First Out",
    iconImage: CacheFifoIcon,
  },
  [QUEUE_STANDARD]: {
    titleJa: "標準",
    titleEn: "Standard",
    descriptionJa: "実行順序が保証されない",
    descriptionEn: "Order is not guaranteed",
    iconImage: "",
  },
  [QUEUE_FIFO]: {
    titleJa: "FIFO",
    titleEn: "FIFO",
    descriptionJa: "先入れ先出し方式",
    descriptionEn: "First In, First Out",
    iconImage: QueueFifoIcon,
  },
};

export interface CategoryData {
  titleJa: string;
  titleEn: string;
}

export const CategoryMasterData: Record<ElementCategory, CategoryData> = {
  [CATEGORY_GENERAL]: { titleJa: "一般", titleEn: "General" },
  [CATEGORY_NETWORK]: { titleJa: "ネットワーク", titleEn: "Network" },
  [CATEGORY_DATASTORE]: { titleJa: "データストア", titleEn: "Datastore" },
  [CATEGORY_CACHE]: { titleJa: "キャッシュ", titleEn: "Cache" },
  [CATEGORY_COMPUTE_RESOURCE]: { titleJa: "計算資源", titleEn: "Compute" },
  [CATEGORY_MESSAGING]: { titleJa: "メッセージング", titleEn: "Messaging" },
  [CATEGORY_STORAGE]: { titleJa: "ストレージ", titleEn: "Storage" },
};

export type AdjustPosition = "leftTop" | "rightTop" | "rightBottom" | "leftBottom" | "leftMid" | "topMid" | "rightMid" | "bottomMid";

export type FontSize = "small" | "medium" | "large";

export const FontSizeMap: Record<FontSize, number> = {
  ["small"]: 20,
  ["medium"]: 24,
  ["large"]: 36,
};

export type Primary = "primary";
export type Replica = "replica";
export type Replication = Primary | Replica;
export const REPLICATION = {
  primary: "primary",
  replica: "replica",
} as const;

export type Standard = "standard";
export type Fifo = "fifo";
export type QueueType = Standard | Fifo;
export const QUEUE_TYPE = {
  standard: "standard",
  fifo: "fifo",
} as const;

export type Ttl = "ttl";
export type Lru = "lru";
export type Lfu = "lfu";
export type CachePolicy = Ttl | Lru | Lfu | Fifo;
export const CACHE_POLICY = {
  ttl: "ttl",
  lru: "lru",
  lfu: "lfu",
  fifo: "fifo",
} as const;

export type Undirectinal = "undirectional";
export type Bidirectional = "bidirectional";
export type Direction = Undirectinal | Bidirectional;
export const DIRECTION = {
  undirectional: "undirectional",
  bidirectional: "bidirectional",
} as const;

export const sqlSettingsMap = {
  [REPLICATION.primary]: SettingsMasterData[SETTINGS_KEY.sqlPrimary],
  [REPLICATION.replica]: SettingsMasterData[SETTINGS_KEY.sqlReplica],
} as const;

export const cacheSettingsMap = {
  [CACHE_POLICY.ttl]: SettingsMasterData[SETTINGS_KEY.cacheTtl],
  [CACHE_POLICY.lru]: SettingsMasterData[SETTINGS_KEY.cacheLru],
  [CACHE_POLICY.lfu]: SettingsMasterData[SETTINGS_KEY.cacheLfu],
  [CACHE_POLICY.fifo]: SettingsMasterData[SETTINGS_KEY.cacheFifo],
} as const;

export const queueSettingsMap = {
  [QUEUE_TYPE.standard]: SettingsMasterData[SETTINGS_KEY.queueStandard],
  [QUEUE_TYPE.fifo]: SettingsMasterData[SETTINGS_KEY.queueFifo],
};

// Histories in firebase realtime database
export type FlowAction =
  | AddElement
  | AddComment
  | EditComment
  | DeleteElements
  | ReviveElements
  | MoveElements
  | ShapeElement
  | ConnectNodes
  | ReconnectEdge
  | PasteElements
  | UpdateSettings
  | ResetElements;

export type AddElement = {
  s: "adde";
  v: {
    id: string;
    t: ElementType; // type
    l: ElementLabel; // label
    x: number;
    y: number;
    w: number; // width
    h: number; // height
    s: Settings; // initial settings
  };
  a: string; // author
  t: number;
};

export type AddComment = {
  s: "addc";
  v: {
    id: string;
    c: string; // content
    x: number;
    y: number;
    f: number; // fontSize
  };
  a: string;
  t: number;
};

export type EditComment = {
  s: "edic";
  v: {
    id: string;
    c: string; // content
  };
  a: string;
  t: number;
};

export type DeleteElements = {
  s: "del";
  v: {
    ids: string[];
  };
  a: string;
  t: number;
};

export type ReviveElements = {
  s: "rev";
  v: {
    ids: string[];
  };
  a: string;
  t: number;
};

export type MoveElements = {
  s: "mov";
  v: {
    ids: string[];
    dx: number;
    dy: number;
  };
  a: string;
  t: number;
};

export type ShapeElement = {
  s: "sha";
  v: {
    id: string;
    p: AdjustPosition; // position
    dx: number;
    dy: number;
  };
  a: string;
  t: number;
};

export type ConnectNodes = {
  s: "con";
  v: {
    id: string;
    s: string; // source
    t: string; // target
  };
  a: string;
  t: number;
};

export type ReconnectEdge = {
  s: "rec";
  v: {
    id: string;
    s: string; // source
    t: string; // target
  };
  a: string;
  t: number;
};

export type PasteElements = {
  s: "pas";
  v: {
    dst: FlowElement[];
  };
  a: string;
  t: number;
};

export type UpdateSettings = {
  s: "upds";
  v: {
    id: string;
    u: UnionSettingsFields; // updates
  };
  a: string;
  t: number;
};

export type ResetElements = {
  s: "res";
  a: string;
  t: number;
};

export const FLOW_ACTION = {
  addElement: "adde",
  addComment: "addc",
  editComment: "edic",
  deleteElements: "del",
  reviveElements: "rev",
  moveElements: "mov",
  shapeElement: "sha",
  connectNodes: "con",
  reconnectEdge: "rec",
  pasteElements: "pas",
  updateSettings: "upds",
  resetElements: "res",
} as const;

export type UserState = {
  uid: string; // uid is stored as key in firebase
  name: string;
  color: string;
  select: string[];
  edit: string;
  cursor: {
    x: number;
    y: number;
  };
};

export type UnionSettingsFields = {
  name?: string;
  autoScale?: boolean;
  enableReplication?: boolean;
  replication?: Replication;
  enableSharding?: boolean;
  cachePolicy?: CachePolicy;
  queueType?: QueueType;
  direction?: Direction;
  fontSize?: number;
};

export type OperationType = "do" | "undo" | "redo";
export const OPERATION_TYPE = {
  do: "do",
  undo: "undo",
  redo: "redo",
} as const;
