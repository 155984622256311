import * as ProjectHelper from "@hireroo/app-helper/project-v3";
import { ProjectCodingEditorV3 } from "@hireroo/app-store/widget/shared/ProjectCodingEditorV3";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

type BlockNode = Exclude<Widget.ProjectCodingEditorV3Props["defaultTestCaseDialog"], undefined>["blockBuilder"]["blocks"][0]["node"];

type DefaultTestCaseSchema = {
  blocks: BlockNode[];
};

export type GenerateDefaultTestCaseDialogArgs = {
  open: boolean;
  entityId: number;
  testCaseResult: string;
};

export const useGenerateDefaultTestCaseDialogProps = (
  args: GenerateDefaultTestCaseDialogArgs,
): Widget.ProjectCodingEditorV3Props["defaultTestCaseDialog"] => {
  const { t } = useTranslation();
  const action = ProjectCodingEditorV3.createProjectEntityAction(args.entityId);
  const hooks = ProjectCodingEditorV3.useCreateProjectEntityHooks(args.entityId);
  const question = hooks.useQuestion();
  const testCaseState = hooks.useDefaultTestCaseState();
  const snapshotIsFailed = testCaseState?.snapshotStatus === "FAILED";

  const parsedValue = React.useMemo((): DefaultTestCaseSchema => {
    if (!args.open) {
      return {
        blocks: [],
      };
    }
    return ProjectHelper.DefaultTestCase.parseDefaultTestCase(args.testCaseResult);
  }, [args.open, args.testCaseResult]);
  if (!args.open || question?.variant !== "Default") {
    return undefined;
  }

  const failureMessageMap = {
    WORKSPACE_PREPARATION_FAILED: t("テスト実行用の環境の構築に失敗したため評価に失敗しました。運営までお問合せください。"),
    SERVER_HEALTH_CHECK_FAILED: t(
      "サーバーの起動確認に失敗しました。ソースコードがビルドできているか、また既存のヘルスチェックの実装に変更を加えていないかをご確認ください。",
    ),
    EVALUATION_PREPARATION_FAILED: t(
      "評価プロセス中にエラーが発生しました。評価用に用意されている既存のエンドポイントの実装に変更を加えていないかをご確認ください。",
    ),
    UNKNOWN: t("予期しないエラーによりテストケースの実行に失敗しました。運営までお問合せください。"),
  } as const satisfies Record<string, string>;

  return {
    blockBuilder: {
      blocks: parsedValue.blocks.map(node => {
        return { node };
      }),
    },
    onClose: () => {
      action.clearTestResultForDefault();
    },
    failureMessage: snapshotIsFailed && testCaseState.failureReason ? failureMessageMap[testCaseState.failureReason] : undefined,
  };
};
