import { useTranslation } from "@hireroo/i18n";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import AssessmentLayout, { AssessmentLayoutProps } from "../../layouts/AssessmentLayout/AssessmentLayout";
import { EntryTestProvider } from "./parts/EntryContents/Context";
import EntryContents, { EntryContentsProps } from "./parts/EntryContents/EntryContents";

const Heading = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  fontWeight: 700,
  textAlign: "center",
  marginBottom: theme.spacing(1.5),
}));

export type EntryAssessmentProps = {
  layout: Omit<AssessmentLayoutProps, "children">;
  enableEmailField?: boolean;
} & Pick<EntryContentsProps, "entryAssessmentOverviewSection" | "confirmForm" | "onboarding">;

const EntryAssessment: React.FC<EntryAssessmentProps> = props => {
  const { t } = useTranslation();
  return (
    <AssessmentLayout {...props.layout}>
      <Container sx={{ paddingY: "1em" }}>
        <Heading variant="h5">{t("エントリー")}</Heading>
        <EntryTestProvider enableEmailField={Boolean(props.enableEmailField)}>
          <EntryContents
            entryAssessmentOverviewSection={props.entryAssessmentOverviewSection}
            confirmForm={props.confirmForm}
            onboarding={props.onboarding}
          />
        </EntryTestProvider>
      </Container>
    </AssessmentLayout>
  );
};

EntryAssessment.displayName = "EntryAssessment";

export default EntryAssessment;
