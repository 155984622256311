import { useSnapshot } from "valtio";

import { state } from "./State";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = (): boolean => {
  const snapshot = useSnapshotState();
  return !!snapshot.customer;
};

export const useCustomer = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.customer) {
    throw new Error("Please Initialize PaymentMethodUpdateForm");
  }
  return snapshot.customer;
};
