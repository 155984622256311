import { ProjectDataScienceTestCaseSchema } from "@hireroo/validator";

import { safeJsonParse } from "../parser";

const dataScienceCorrectnessTestResultSchema = ProjectDataScienceTestCaseSchema.generateDataScienceCorrectnessTestResultSchema();

export const parseDataScienceTestCaseResult = (
  data: string,
): ProjectDataScienceTestCaseSchema.DataScienceCorrectnessTestResultSchema | undefined => {
  const parsedValue = safeJsonParse(data);
  const result = dataScienceCorrectnessTestResultSchema.safeParse(parsedValue);
  if (result.success) {
    return result.data;
  } else {
    return;
  }
};

const dataScienceCorrectnessTestResultsSchema = ProjectDataScienceTestCaseSchema.generateDataScienceCorrectnessTestResultsSchema();

export const parseDataScienceTestCaseResults = (
  data: string,
): ProjectDataScienceTestCaseSchema.DataScienceCorrectnessTestResultsSchema | undefined => {
  const parsedValue = safeJsonParse(data);
  const result = dataScienceCorrectnessTestResultsSchema.safeParse(parsedValue);
  if (result.success) {
    return result.data;
  } else {
    return;
  }
};

const dataScienceTestCaseSchema = ProjectDataScienceTestCaseSchema.generateDataScienceCorrectnessTestCase();

export const parseDataScienceTestCase = (data: string): ProjectDataScienceTestCaseSchema.DataScienceCorrectnessTestCaseSchema | undefined => {
  const parsedValue = safeJsonParse(data);
  const result = dataScienceTestCaseSchema.safeParse(parsedValue);
  if (result.success) {
    return result.data;
  } else {
    return;
  }
};

const dataScienceTestCasesSchema = ProjectDataScienceTestCaseSchema.generateDataScienceCorrectnessTestCases();

export const parseDataScienceTestCases = (data: string): ProjectDataScienceTestCaseSchema.DataScienceCorrectnessTestCasesSchema | undefined => {
  const parsedValue = safeJsonParse(data);
  const result = dataScienceTestCasesSchema.safeParse(parsedValue);
  if (result.success) {
    return result.data;
  } else {
    return undefined;
  }
};

export const formatCSVRow = (row: Record<string, string>, header: string[]): Record<string, string> => {
  const res: Record<string, string> = {};
  header.forEach(col => {
    res[col] = col in row && row[col] !== null ? row[col] : "-";
  });
  return res;
};
