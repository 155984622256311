import { App } from "@hireroo/app-store/essential/employee";
import { QuestionQuizPackageResourceEditor } from "@hireroo/app-store/view-domain/QuestionQuizPackageResourceEditor";
import { SearchQuizQuestion } from "@hireroo/app-store/widget/e/SearchQuizQuestion";
import * as Graphql from "@hireroo/graphql/client/urql";
import type { Pages } from "@hireroo/presentation/legacy";
import * as React from "react";
export type GenerateQuizResourceEditorPropsArgs = {};

export type QuizQuestionSearchFieldProps = Pages.QuizResourceEditorProps["selectQuestions"]["quizQuestionSearchField"];

export type GenerateQuizQuestionSearchFieldPropsArgs = {};

export const useGenerateQuizQuestionSearchFieldProps = (_args: GenerateQuizQuestionSearchFieldPropsArgs): QuizQuestionSearchFieldProps => {
  const [searchText, updateSearchText] = React.useState("");
  const appStatus = App.useStatus();
  const searchQueryForSearchQuizQuestion = SearchQuizQuestion.useSearchQuery();
  const searchQuery = QuestionQuizPackageResourceEditor.useMultiQuestionTagsSearchQuery();
  const selectedTags = QuestionQuizPackageResourceEditor.useSelectedTags();
  const [result] = Graphql.useListMultiQuestionTagsQuery({
    variables: {
      multiChoiceTagsInput: {
        cursorSeconds: searchQuery.cursorSeconds,
        isDescending: searchQuery.isDescending,
        queryString: searchQuery.queryString,
        size: searchQuery.size,
        withCount: searchQuery.withCount,
      },
    },
    pause: appStatus !== "INITIALIZED",
  });

  const selectedTagsProps = React.useMemo(() => {
    const list = [...selectedTags].map(tag => {
      return {
        label: `#${tag.name}`,
        onDelete: () => {
          SearchQuizQuestion.removeSearchTag(tag.id);
          QuestionQuizPackageResourceEditor.TagSearch.removeTag(tag.id);
        },
      };
    });
    if (searchQueryForSearchQuizQuestion.queryString) {
      return list.concat({
        label: searchQueryForSearchQuizQuestion.queryString,
        onDelete: () => {
          SearchQuizQuestion.updateSearchText("");
          updateSearchText("");
        },
      });
    }

    return list;
  }, [searchQueryForSearchQuizQuestion.queryString, selectedTags]);

  return {
    searchTextField: {
      value: searchText,
      onChange: event => {
        updateSearchText(event.target.value);
      },
      onKeyDown: event => {
        if (event.key === "Enter") {
          SearchQuizQuestion.updateSearchText(searchText);
        }
      },
    },
    tagSearch: {
      options: (result.data?.multiChoiceTags.tags || []).map(tag => {
        return {
          id: tag.id,
          name: tag.name,
        };
      }),
      value: [...selectedTags],
      onChange: (_, tags) => {
        const formattedTags = tags.flatMap(tag => (typeof tag === "string" ? [] : [tag]));
        QuestionQuizPackageResourceEditor.TagSearch.updateSelectedTags(formattedTags);
        SearchQuizQuestion.updateSearchTags(formattedTags.map(tag => tag.id));
      },
    },
    selectedTags: selectedTagsProps,
  };
};
