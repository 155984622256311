import { useTranslation } from "@hireroo/i18n";
import { Delete } from "@mui/icons-material";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";

import IconButton, { IconButtonProps } from "../../../primitive/Button/IconButton";
import { InputControlTextFieldProps } from "../../../primitive/InputControl/InputControlTextField";
import { useAlgorithmFunctionDefinitionContext } from "../Context";
import FunctionTypeSelect, { FunctionTypeSelectProps } from "./FunctionTypeSelect";

export type FunctionArgumentsInputProps = {
  index: number;
  functionTypeSelect: Omit<FunctionTypeSelectProps, "name">;
  deleteButton: Pick<IconButtonProps, "onClick" | "disabled">;
};

const FunctionArgumentsInput: React.FC<FunctionArgumentsInputProps> = props => {
  const { t } = useTranslation();
  const { methods } = useAlgorithmFunctionDefinitionContext();
  const [name, setName] = React.useState<string>(methods.getValues(`inputs.${props.index}.name`));

  const nameFieldState = methods.getFieldState(`inputs.${props.index}.name`);
  const deleteIconButton: IconButtonProps = {
    ...props.deleteButton,
    title: t("引数を削除する"),
    color: "default",
    "aria-label": "remove-type",
    size: "small",
    children: <Delete />,
  };
  const inputNameField: InputControlTextFieldProps = {
    type: "text",
    label: t("引数名"),
    fullWidth: true,
    required: true,
    InputLabelProps: {
      shrink: true,
    },
    color: "primary",
    variant: "outlined",
    placeholder: "str",
  };
  return (
    <Box display="flex" columnGap={2} rowGap={4} mb={3} alignItems="center">
      <Box width="50px" height="50px">
        <IconButton {...deleteIconButton} />
      </Box>

      <FunctionTypeSelect
        name={`inputs.${props.index}.type`}
        {...props.functionTypeSelect}
        hasNameFieldError={Boolean(nameFieldState.error?.message)}
      />

      {/*Needed to be setValue onBlur because the set value will be used to fetch template*/}
      {/*If I use InputControlTextField the change will affect during the input and keep re-rendering*/}
      <TextField
        {...inputNameField}
        {...methods.register(`inputs.${props.index}.name`)}
        onChange={event => {
          setName(event.target.value);
          methods.clearErrors();
        }}
        onBlur={() => methods.setValue(`inputs.${props.index}.name`, name)}
        value={name}
        size="small"
        error={Boolean(nameFieldState?.error?.message)}
        helperText={nameFieldState?.error?.message}
      />
    </Box>
  );
};

FunctionArgumentsInput.displayName = "FunctionArgumentsInput";

export default FunctionArgumentsInput;
