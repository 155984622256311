import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type RemoteInterviewChallengePlaybackContainerProps = {};

const RemoteInterviewChallengePlaybackContainer: React.FC<RemoteInterviewChallengePlaybackContainerProps> = () => {
  const remoteInterviewEntityPlaybackProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.RemoteInterviewEntityPlayback {...remoteInterviewEntityPlaybackProps} />
    </ErrorBoundary>
  );
};

RemoteInterviewChallengePlaybackContainer.displayName = "RemoteInterviewChallengePlaybackContainer";

export default withErrorBoundary(RemoteInterviewChallengePlaybackContainer, {});
