import { useTranslation } from "@hireroo/i18n";
import type { ComponentType } from "@hireroo/system-design/features";
import {
  type BooleValue,
  checkIsPattern,
  type FlowSnapshot,
  getBool,
  getChild,
  type GraphPattern,
} from "@hireroo/system-design/helpers/judgmentCondition";
import StaticFlowChart from "@hireroo/system-design/react/StaticFlowChart";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type DisplayJudgmentConditionProps = {
  componentType: ComponentType;
  patterns: (GraphPattern | FlowSnapshot)[];
  parentBool: BooleValue;
  isRoot: boolean;
};

const DisplayJudgmentCondition: React.FC<DisplayJudgmentConditionProps> = props => {
  const { isRoot, parentBool, patterns } = props;
  const { t } = useTranslation();
  const suffix = parentBool === "and" ? t("を含む") : parentBool === "or" ? t("のいずれかを含む") : t("のいずれも含まない");
  const hasNoChild = patterns.every(pattern => !checkIsPattern(pattern));

  const conj = React.useMemo(() => {
    if (hasNoChild) {
      return parentBool === "or" ? t("または") : t("と");
    } else {
      return parentBool === "and" ? t("かつ") : parentBool === "or" ? t("または") : ""; // notの下にandやorがネストするケースは考えなくてよいのでブランクにしている
    }
  }, [hasNoChild, parentBool, t]);

  const Left = <Typography mx={1} children="(" />;
  const Right = <Typography mx={1} children=")" />;

  return (
    <Box display={"flex"} alignItems={"center"} flexWrap={"wrap"}>
      {!isRoot && Left}
      {patterns.map((pattern, i) => {
        return (
          <Box key={i} display={"flex"} alignItems={"center"}>
            {checkIsPattern(pattern) ? (
              <DisplayJudgmentCondition
                componentType={props.componentType}
                patterns={getChild(pattern)}
                parentBool={getBool(pattern)}
                isRoot={false}
              />
            ) : (
              <React.Suspense>
                <StaticFlowChart elements={pattern.elements} componentType={props.componentType} width={150} disableZoom={true} padding={0} />
              </React.Suspense>
            )}
            {i !== patterns.length - 1 && <Typography mx={1}>{conj}</Typography>}
          </Box>
        );
      })}
      {hasNoChild && <Typography mx={1}>{suffix}</Typography>}
      {!isRoot && Right}
    </Box>
  );
};

DisplayJudgmentCondition.displayName = "DisplayJudgmentCondition";

export default DisplayJudgmentCondition;
