import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type AssessmentResourceEditorContainerProps = {};

const AssessmentResourceEditorContainer: React.FC<AssessmentResourceEditorContainerProps> = () => {
  const assessmentResourceEditorProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Pages.AssessmentResourceEditor {...assessmentResourceEditorProps} />
    </ErrorBoundary>
  );
};

AssessmentResourceEditorContainer.displayName = "AssessmentResourceEditorContainer";

export default withErrorBoundary(AssessmentResourceEditorContainer, {});
