import { state } from "./State";
import type * as Types from "./types";

export const append = (res: Types.EmployeeNotificationsResponse): void => {
  res.employeeNotifications.forEach(employeeNotification => {
    state.notification.set(employeeNotification.notificationId, employeeNotification);
  });
  state.pager = res.pager;
  if (!res.pager?.hasNext) {
    state.fetchStatus = "STOP";
  }
};

export const read = (readNotificationId: number) => {
  state.readNotificationIdSet.add(readNotificationId);
};

export const readAll = (readNotificationIds: number[]) => {
  readNotificationIds.forEach(readNotificationId => {
    state.readNotificationIdSet.add(readNotificationId);
  });
  state.fetchStatus = "READY";
  state.pager = null;
};

export const goNextPage = () => {
  if (state.pager?.hasNext) {
    state.fetchStatus = "READY";
  }
};

export const requestRetryFetch = () => {
  state.fetchStatus = "READY";
  state.pager = null;
};
