import { useTranslationWithVariable } from "@hireroo/i18n";
import { useTheme } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const MAX_HEIGHT = 400;

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&.MuiMenuItem-root": {
    "&:hover ": {
      backgroundColor: theme.palette["Secondary/Shades"].p8,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette["Secondary/Shades"].p16,
    },
  },
}));

type Item = {
  id: string;
  startDate: string;
  endDate: string;
  examNumber: number;
  selected?: boolean;
};

export type ExamSelectorProps = {
  items: Item[];
  onChange: (value: string) => void;
  value: string;
  onEndReached: () => void;
};

const ExamSelector: React.FC<ExamSelectorProps> = props => {
  const { t: t2 } = useTranslationWithVariable();
  const theme = useTheme();
  const handleChange = (event: SelectChangeEvent<string>) => {
    props.onChange(event.target.value);
  };

  return (
    <Select
      onChange={handleChange}
      value={props.value}
      size="small"
      color="secondary"
      MenuProps={{
        MenuListProps: {
          sx: {
            maxHeight: MAX_HEIGHT,
            overflow: "scroll",
          },
          onScroll: (e: React.UIEvent<HTMLUListElement>) => {
            const target = e.currentTarget;
            if (target.scrollHeight - target.scrollTop === target.clientHeight) {
              props.onEndReached();
            }
          },
        },
      }}
      SelectDisplayProps={{ style: { paddingTop: 4.5, paddingBottom: 4.5 } }}
      sx={{ backgroundColor: theme.palette["Other"]["FilledInputBG"] }}
    >
      {props.items.map(item => {
        return (
          <StyledMenuItem key={item.id} value={item.id} color="secondary">
            <Stack direction="row" alignItems="center">
              <Typography fontSize={16} ml={1}>{`${t2("numOfExam", { num: item.examNumber })} ${item.startDate}-${item.endDate}`}</Typography>
            </Stack>
          </StyledMenuItem>
        );
      })}
    </Select>
  );
};

ExamSelector.displayName = "ExamSelector";

export default ExamSelector;
