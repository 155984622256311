import CircleIcon from "@mui/icons-material/Circle";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectProps } from "@mui/material/Select";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

type Status = "ACTIVE" | "NOT_ACTIVE";

const StyledPrefixBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const StyledCircleIcon = styled(CircleIcon)`
  font-size: 12px;
  top: 3px;
  position: relative;
`;

const StyledSelect = styled(Select)(() => ({
  ".MuiOutlinedInput-notchedOutline": {
    border: "1px solid transparent",
  },
  ".hide-target": {
    display: "none",
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&.MuiMenuItem-root": {
    "&:hover ": {
      backgroundColor: theme.palette["Secondary/Shades"].p8,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette["Secondary/Shades"].p16,
    },
  },
}));

const ItemWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

type ItemProps = {
  status: Status;
  value: string;
  name: string;
  showAlert: boolean;
};

export type AssessmentSelectProps = {
  open?: boolean;
  defaultValue?: string;
  onOpen?: () => void;
  onClose?: () => void;
  items: ItemProps[];
  onChange?: (value: string) => void;
};

const AssessmentSelect: React.FC<AssessmentSelectProps> = props => {
  const theme = useTheme();
  const statusColor: Record<Status, string> = {
    ACTIVE: theme.palette.success.light,
    NOT_ACTIVE: theme.palette.error.light,
  };

  const [value, setValue] = React.useState(props.defaultValue ?? props.items.at(0)?.value ?? "");
  const selectProps: SelectProps = {
    value,
    open: props.open,
    variant: "outlined",
    size: "small",
    color: "secondary",
    onOpen: props.onOpen,
    onClose: props.onClose,
    onChange: event => {
      if (typeof event.target.value === "string") {
        setValue(event.target.value);
        props.onChange?.(event.target.value);
      }
    },
  };

  return (
    <StyledSelect {...selectProps}>
      {props.items.map((itemProps, index) => (
        <StyledMenuItem key={`${itemProps.value}-${index}`} value={itemProps.value}>
          <ItemWrapper>
            <StyledPrefixBox className="hide-target">
              <Typography noWrap fontSize={12} mr={1} top={2} position="relative" width={24} height={28}>
                {itemProps.showAlert && <WarningAmberOutlinedIcon color="warning" />}
              </Typography>
              <Typography noWrap sx={{ color: statusColor[itemProps.status] }} fontSize={12} mr={1} mb={0.5}>
                <StyledCircleIcon />
              </Typography>
            </StyledPrefixBox>

            <Typography noWrap>{itemProps.name}</Typography>
          </ItemWrapper>
        </StyledMenuItem>
      ))}
    </StyledSelect>
  );
};

AssessmentSelect.displayName = "AssessmentSelect";

export default AssessmentSelect;
