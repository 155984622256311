import { generateKey } from "@hireroo/app-helper/parser";

import { state } from "./State";
import type * as Types from "./types";

export const clear = () => {
  state.initialized = false;
  state.queryResultMap.clear();
  state.tags = [];
};

export type InitializeArgs = {
  finalizedAtSeconds: number | null;
  tags: Types.Tag[];
};

export const updateQuery = (query: Types.Query): void => {
  state.query = query;
};

export const initialize = (args: InitializeArgs) => {
  if (args.finalizedAtSeconds) {
    state.query.endDate = new Date(args.finalizedAtSeconds * 1000);
  }
  // dispatch subscribe
  state.query = { ...state.query, tags: [] };
  state.tags = args.tags;
  state.initialized = true;
};

export const setResult = (query: Types.Query, result: Types.Result) => {
  const queryKey = generateKey(query);
  state.queryResultMap.set(queryKey, result);
};
