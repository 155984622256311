import { useTranslation } from "@hireroo/i18n";
import MuiTablePagination, { TablePaginationProps as MuiTablePaginationProps } from "@mui/material/TablePagination/TablePagination";
import * as React from "react";

const DEFAULT_SIZES = [20, 30, 50];

export type TablePaginationProps = {
  className?: string;
  /**
   * @default [20, 30, 50]
   */
  rowsPerPageOptions?: number[];
  count: number;
  isLoading?: boolean;
  page: number;
  rowsPerPage: number;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
};

const TablePagination: React.FC<TablePaginationProps> = props => {
  const { rowsPerPageOptions = DEFAULT_SIZES } = props;
  const { t } = useTranslation();
  const paginationProps: MuiTablePaginationProps = {
    className: props.className,
    count: props.count,
    page: props.isLoading ? 0 : props.page,
    onPageChange: props.onPageChange,
    rowsPerPage: props.rowsPerPage,
    rowsPerPageOptions: rowsPerPageOptions,
    onRowsPerPageChange: props.onRowsPerPageChange,
    labelRowsPerPage: `${t("ページあたりの行数")}:`,
    labelDisplayedRows: ({ from, to, count }) => `${from}-${to} / ${count !== -1 ? count : `${to} ${t("以上")}`}`,
    SelectProps: {
      native: true,
    },
  };

  return <MuiTablePagination component="div" {...paginationProps} />;
};

TablePagination.displayName = "TablePagination";

export default TablePagination;
