import { useSnapshot } from "valtio";

import { state } from "./State";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = (): boolean => {
  const snapshot = useSnapshotState();
  return !!snapshot.res;
};

export const useRes = () => {
  const snapshot = useSnapshotState();
  return snapshot.res;
};

export const useListParams = () => {
  const snapshot = useSnapshotState();
  return snapshot.listParams;
};

export const useSize = () => {
  const snapshot = useSnapshotState();
  return snapshot.listParams.size;
};

export const usePage = () => {
  const snapshot = useSnapshotState();
  return snapshot.listParams.page;
};

export const useFilters = () => {
  const snapshot = useSnapshotState();
  return snapshot.listParams.filters;
};
