import { useTranslation } from "@hireroo/i18n";
import { PaymentForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import ApplicationConfirmationSection, {
  ApplicationConfirmationSectionProps,
} from "../../ms-components/Payment/ApplicationConfirmationSection/ApplicationConfirmationSection";
import BillingInformationField, {
  BillingInformationFieldProps,
} from "../../ms-components/Payment/BillingInformationField/BillingInformationField";
import CouponField, { CouponFieldProps } from "../../ms-components/Payment/CouponField/CouponField";
import PaymentMethodField, { PaymentMethodFieldProps } from "../../ms-components/Payment/PaymentMethodField/PaymentMethodField";
import PaymentPeriodField from "../../ms-components/Payment/PaymentPeriodField/PaymentPeriodField";
import PlanSelectionField, { PlanSelectionFieldProps } from "../../ms-components/Payment/PlanSelectionField/PlanSelectionField";
import PlanSelectionTips, { PlanSelectionTipsProps } from "../../ms-components/Payment/PlanSelectionTips/PlanSelectionTips";
import SelectNumberOfSelectionField, {
  SelectNumberOfSelectionFieldProps,
} from "../../ms-components/Payment/SelectNumberOfSelectionField/SelectNumberOfSelectionField";
import ButtonWithTooltip, { ButtonWithTooltipProps } from "../../primitive/Button/ButtonWithTooltip/ButtonWithTooltip";

const Footer = styled(Box)(() => ({
  textAlign: "center",
}));

const SectionLabel = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  marginBottom: theme.spacing(2),
}));

export type PaymentContractCreateFormProps = {
  onSubmit: SubmitHandler<PaymentForm.PaymentContractCreateForm>;
  onChange: (fields: PaymentForm.PaymentContractCreateForm) => void;
  planSelectionField: Omit<PlanSelectionFieldProps, "name">;
  planSelectionTips?: PlanSelectionTipsProps;
  billingInformationField: Pick<BillingInformationFieldProps, "companyName">;
  paymentMethod: Omit<PaymentMethodFieldProps, "name">;
  couponField: Omit<CouponFieldProps, "name">;
  nextMonthPaymentConfirmSection: ApplicationConfirmationSectionProps;
  monthAfterNextPaymentConfirmSection?: ApplicationConfirmationSectionProps;
  selectNumberOfSelectionField?: Omit<SelectNumberOfSelectionFieldProps, "name">;
  defaultValues: PaymentForm.PaymentContractCreateForm;
  confirmButton: Pick<ButtonWithTooltipProps, "disabled" | "title">;
};

const PaymentContractCreateForm: React.FC<PaymentContractCreateFormProps> = props => {
  const { t } = useTranslation();
  const { onChange } = props;
  const validateSchema = PaymentForm.usePaymentContractCreateForm();
  const methods = useForm<PaymentForm.PaymentContractCreateForm>({
    resolver: zodResolver(validateSchema),
    defaultValues: props.defaultValues,
  });
  const confirmButton: ButtonWithTooltipProps = {
    ...props.confirmButton,
    variant: "contained",
    children: t("申込内容の確認をする"),
    onClick: () => {
      methods.handleSubmit(props.onSubmit, console.error)();
    },
    sx: {
      padding: "6px 16px",
    },
  };

  React.useEffect(() => {
    const subscription = methods.watch(() => {
      onChange(methods.getValues());
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [methods, onChange]);

  return (
    <FormProvider {...methods}>
      <Box>
        <SectionLabel>{t("お支払い回数")}</SectionLabel>
        <PaymentPeriodField name="paymentPeriod" mb={3} />
        <SectionLabel>{t("プラン選択")}</SectionLabel>
        <PlanSelectionField {...props.planSelectionField} name="plan" mb={3} />
        {props.planSelectionTips && <PlanSelectionTips {...props.planSelectionTips} mb={3} />}
        {props.selectNumberOfSelectionField && (
          <>
            <SectionLabel>{t("選考数")}</SectionLabel>
            <SelectNumberOfSelectionField {...props.selectNumberOfSelectionField} name="selectionItems" mb={3} />
          </>
        )}
        <SectionLabel>{t("クーポン")}</SectionLabel>
        <CouponField {...props.couponField} name="coupon" mb={3} />
        <SectionLabel>{t("請求情報")}</SectionLabel>
        <BillingInformationField {...props.billingInformationField} name="billingInformation" mb={3} />
        <SectionLabel>{t("支払い方法")}</SectionLabel>
        <PaymentMethodField {...props.paymentMethod} name="paymentMethod" mb={3} />
        <SectionLabel>{t("お申し込み内容")}</SectionLabel>
        <ApplicationConfirmationSection {...props.nextMonthPaymentConfirmSection} mb={3} />
        {props.monthAfterNextPaymentConfirmSection && <ApplicationConfirmationSection {...props.monthAfterNextPaymentConfirmSection} mb={3} />}
        <Footer>
          <ButtonWithTooltip {...confirmButton} />
        </Footer>
      </Box>
    </FormProvider>
  );
};

PaymentContractCreateForm.displayName = "PaymentContractCreateForm";

export default PaymentContractCreateForm;
