import { DeepReadonly } from "@hireroo/app-helper/types";
import * as Time from "@hireroo/formatter/time";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import { ScreeningTestRetakeForm } from "@hireroo/validator";
import * as React from "react";
import { useFormContext } from "react-hook-form";

type Item = Widget.ScreeningTestRetakeConfirmProps["sections"][0]["items"][0];

type EntityUniqueId = `${ScreeningTestRetakeForm.EntityIdSchema["entityId"]}-${ScreeningTestRetakeForm.EntityIdSchema["entityType"]}`;

export type GenerateScreeningTestRetakeConfirmPropsArgs = {
  interview: DeepReadonly<Graphql.SpotForRetakeInterviewsIdDetailFragment>;
};

export const useGenerateProps = (args: GenerateScreeningTestRetakeConfirmPropsArgs): Widget.ScreeningTestRetakeConfirmProps => {
  const { t } = useTranslation();
  const methods = useFormContext<ScreeningTestRetakeForm.RetakeScreeningTestSchema>();
  const lang = useLanguageCode();

  const entityTitleMap = React.useMemo(() => {
    return args.interview.entities.reduce<Record<EntityUniqueId, string>>((all, entity) => {
      if (entity.__typename === "ChallengeEntity" && entity.challengeQuestion) {
        return {
          ...all,
          [`${entity.challengeEntityId}-CHALLENGE`]: `${resolveLanguage(entity.challengeQuestion, lang, "title")} (${t("コーディング形式")})`,
        };
      } else if (entity.__typename === "QuizEntity" && entity.pb_package) {
        return {
          ...all,
          [`${entity.quizEntityId}-QUIZ`]: `${resolveLanguage(entity.pb_package, lang, "title")} (${t("クイズ形式")})`,
        };
      } else if (entity.__typename === "ProjectEntity" && entity.question) {
        return {
          ...all,
          [`${entity.projectEntityId}-PROJECT`]: `${resolveLanguage(entity.question, lang, "title")} (${t("実践形式")})`,
        };
      } else if (entity.__typename === "SystemDesignEntity" && entity.question) {
        return {
          ...all,
          [`${entity.systemDesignEntityId}-SYSTEM_DESIGN`]: `${resolveLanguage(entity.question, lang, "title")} (${t("システムデザイン形式")})`,
        };
      }
      return all;
    }, {});
  }, [args.interview.entities, lang, t]);

  const entityItems = React.useMemo((): Item[] => {
    return methods.getValues("selectedEntityIds").map((entityId, index): Item => {
      const uniqueId: EntityUniqueId = `${entityId.entityId}-${entityId.entityType}`;
      return {
        title: `${t("問題")}${index + 1}`,
        description: entityTitleMap[uniqueId],
      };
    });
  }, [entityTitleMap, methods, t]);

  const reasonTypeMap: Record<ScreeningTestRetakeForm.ReasonTypeSchema, string> = {
    SYSTEM_ERROR: t("バグが発生した"),
    INSUFFICIENT_TIME_LIMIT: t("期日を過ぎた"),
    INTERVIEW_MISDELIVERY: t("操作ミス"),
    OTHER: t("その他"),
  };
  return {
    sections: [
      {
        title: t("出題する問題"),
        items: entityItems,
      },
      {
        title: t("基本情報"),
        items: [
          {
            title: t("制限時間"),
            description: methods.getValues("timeLimitMinutes") + t("分"),
          },
          {
            title: t("テストの提出期限"),
            description: Time.datetimeFormat(methods.getValues("willEndAt")),
          },
        ],
      },
      {
        title: t("再試験の理由"),
        items: [
          {
            title: t("理由"),
            description: reasonTypeMap[methods.getValues("reasonType")],
          },
          {
            title: t("詳細"),
            description: methods.getValues("reasonComment") || t("入力がありません。"),
          },
        ],
      },
    ],
  };
};
