import { useTheme } from "@mui/material/styles";
import * as React from "react";

import TreeItem from "../../../../primitive/TreeItem/TreeItem";
import { DiffKind, FileNodeDiff } from "../../fileTree";
import TreeItemLabel from "../TreeItemLabel/TreeItemLabel";

export type RenderTreeV3Props = {
  node: FileNodeDiff;
  onSelectDirectory: (directory: string) => void;
  onSelectFile: (sourceFile: string) => void;
};

const RenderTreeV3 = React.forwardRef((props: RenderTreeV3Props, ref: React.Ref<unknown>) => {
  const theme = useTheme();
  const { node } = props;
  const nodeChildren = node.children;

  const sortedNodeChildren =
    nodeChildren &&
    Object.keys(nodeChildren).toSorted((a: string, b: string) => {
      if ((nodeChildren[a].isDir && nodeChildren[b].isDir) || (!nodeChildren[a].isDir && !nodeChildren[b].isDir)) {
        // Node is both directory of file, hence determine the order by its name
        return a <= b ? -1 : 1;
      } else if (nodeChildren[a].isDir) {
        // Directory should come before file, hence return -1
        return -1;
      }
      return 1;
    });
  const bgColorMap: Record<DiffKind, string | undefined> = {
    ADD: theme.palette["Success/Shades"].p12,
    REMOVE: theme.palette["Error/Shades"].p12,
    NONE: undefined,
  };

  return (
    <TreeItem
      key={node.id}
      nodeId={node.id}
      ref={ref}
      label={<TreeItemLabel node={node} />}
      onClick={() => {
        if (!node.isDir) {
          props.onSelectFile(node.id);
        } else {
          props.onSelectDirectory?.(node.id);
        }
      }}
      sx={{ backgroundColor: bgColorMap[node.diffKind] }}
    >
      {sortedNodeChildren &&
        sortedNodeChildren.map(key => {
          return (
            <RenderTreeV3 key={key} node={nodeChildren[key]} onSelectFile={props.onSelectFile} onSelectDirectory={props.onSelectDirectory} />
          );
        })}
    </TreeItem>
  );
});

RenderTreeV3.displayName = "RenderTree";

export default RenderTreeV3;
