import { QuestionSelectFieldForResourceEditor } from "@hireroo/app-store/widget/e/QuestionSelectFieldForResourceEditor";
import { Widget } from "@hireroo/presentation";
import { Fields } from "@hireroo/validator";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import QuestionSelectFieldV2Container, { QuestionSelectFieldV2ContainerProps } from "./Container";
import { MAX_SELECTABLE_ENTITY_TRACK_COUNT } from "./privateHelper";

export type QuestionSelectFieldV2InitialContainerProps = QuestionSelectFieldV2ContainerProps;

const QuestionSelectFieldV2InitialContainer: React.FC<QuestionSelectFieldV2InitialContainerProps> = props => {
  const methods = useFormContext<{ entityTracks: Fields.EntityTrack.EntityTrack[] }>();
  const selectedListToDisplay = QuestionSelectFieldForResourceEditor.useSelectedListToDisplay();
  const questionSelectState = QuestionSelectFieldForResourceEditor.useQuestionSelectState();
  const maxAddableQuestionCount = React.useMemo((): number => {
    if (questionSelectState.mode !== "ADD_FIXED") {
      return MAX_SELECTABLE_ENTITY_TRACK_COUNT;
    }
    return MAX_SELECTABLE_ENTITY_TRACK_COUNT - methods.watch("entityTracks").length;
  }, [questionSelectState.mode, methods]);
  const providerProps: Widget.QuestionSearchAndSelectableAreaProviderProps = {
    maxAddableQuestionCount: maxAddableQuestionCount,
    mode: selectedListToDisplay.kind === "ALL_QUESTIONS" ? "SELECT_FROM_ALL_QUESTIONS" : "SELECT_FROM_QUESTION_PACKAGE",
  };
  return (
    <ErrorBoundary>
      <Widget.QuestionSearchAndSelectableAreaProvider {...providerProps}>
        <QuestionSelectFieldV2Container {...props} />
      </Widget.QuestionSearchAndSelectableAreaProvider>
    </ErrorBoundary>
  );
};

QuestionSelectFieldV2InitialContainer.displayName = "QuestionSelectFieldV2InitialContainer";

export default withErrorBoundary(QuestionSelectFieldV2InitialContainer, {});
