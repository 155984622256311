import * as z from "zod";

export const useQuestionTableItem = () => {
  return z.object({
    itemId: z.string(),
    selected: z.boolean(),
    locked: z.boolean().optional(),
  });
};

export type QuestionTableItem = z.infer<ReturnType<typeof useQuestionTableItem>>;

export const useQuestionTableForm = () => {
  const item = useQuestionTableItem();
  return z.object({
    items: item.array(),
  });
};

export type QuestionTableForm = z.infer<ReturnType<typeof useQuestionTableForm>>;
