import { RemoteTemplateResourceEditorForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import * as React from "react";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";

export type ContextValue = {
  title: string;
  setTitle: (title: string) => void;
  methods: UseFormReturn<RemoteTemplateResourceEditorForm.CreateRemoteTemplateSchema>;
};

const NOOP = () => Promise.resolve();

export const RemoteTemplateResourceEditorContext = React.createContext<ContextValue>({
  title: "",
  setTitle: NOOP,
  methods: {} as UseFormReturn<RemoteTemplateResourceEditorForm.CreateRemoteTemplateSchema>,
});

export const useRemoteTemplateResourceEditorContext = () => React.useContext(RemoteTemplateResourceEditorContext);

export type RemoteTemplateResourceEditorProviderProps = {
  defaultValues: RemoteTemplateResourceEditorForm.CreateRemoteTemplateSchema;
};

export const RemoteTemplateResourceEditorProvider: React.FC<React.PropsWithChildren<RemoteTemplateResourceEditorProviderProps>> = props => {
  const schema = RemoteTemplateResourceEditorForm.useCreateRemoteTemplate();
  const methods = useForm<RemoteTemplateResourceEditorForm.CreateRemoteTemplateSchema>({
    resolver: zodResolver(schema),
    defaultValues: props.defaultValues,
  });
  const [title, setTitle] = React.useState("");

  const contextValue: ContextValue = {
    title,
    setTitle: title => setTitle(title),
    methods,
  };
  return (
    <FormProvider {...methods}>
      <RemoteTemplateResourceEditorContext.Provider value={contextValue} children={props.children} />
    </FormProvider>
  );
};
