import { state } from "./State";
import type * as Types from "./types";

export const initialize = (res: Types.Response): void => {
  res.metrics.forEach(metric => {
    state.metricMap.set(metric.metricId, metric);
  });
  state.pagination = {
    ...state.pagination,
    count: res.count,
    pager: res.pager,
  };
};

export const clear = (): void => {
  state.dialogStatus = "CLOSE";
  state.selectedItemIds = [];
  state.pagination.page = 0;
};

export const updateMetric = (metric: Types.Metric): void => {
  state.metricMap.set(metric.metricId, metric);
};

export const removeMetric = (metricId: Types.MetricId): void => {
  state.metricMap.delete(metricId);
};

export const removeMetrics = (metricIds: Types.MetricId[]): void => {
  metricIds.forEach(metricId => {
    state.metricMap.delete(metricId);
  });
};

export const updateDialogStatus = (dialogStatus: Types.DialogStatus): void => {
  state.dialogStatus = dialogStatus;
};

export const clearPage = (): void => {
  state.pagination.page = 0;
};
export const clearSelectedItemIds = (): void => {
  state.selectedItemIds = [];
};

export const addSelectedItemId = (metricId: Types.MetricId) => {
  const selectedSet = new Set(state.selectedItemIds);
  selectedSet.add(metricId);
  state.selectedItemIds = Array.from(selectedSet);
};
export const removeSelectedItemId = (metricId: Types.MetricId) => {
  const selectedSet = new Set(state.selectedItemIds);
  selectedSet.delete(metricId);
  state.selectedItemIds = Array.from(selectedSet);
};

export const updatePager = (): void => {
  if (state.pagination.pager?.hasNext) {
    state.pagination.nextPager = state.pagination.pager;
  }
};

export const updatePage = (page: number) => {
  state.pagination.page = page;
  updatePager();
};

export const updateSize = (size: number) => {
  state.pagination.size = size;
  /**
   * reset page on size change
   */
  state.pagination.page = 0;
};
