import { useLanguageCode } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import { styled } from "@mui/material/styles";
import React from "react";

import {
  cacheSettingsMap,
  ELEMENT_LABEL,
  NodeElement,
  queueSettingsMap,
  SETTINGS_KEY,
  SettingsData,
  SettingsMasterData,
  sqlSettingsMap,
} from "../../../features";

const ImageSvg = styled("image")(() => ({
  filter: "drop-shadow(3px 3px 10px rgba(0, 0, 0, 0.3))",
}));

export type SettingsFlagProps = {
  node: NodeElement;
};

const generateSettingList = (node: NodeElement): SettingsData[] => {
  if (node.label === ELEMENT_LABEL.sql) {
    return [
      node.settings.enableReplication && sqlSettingsMap[node.settings.replication],
      node.settings.enableSharding && SettingsMasterData[SETTINGS_KEY.sqlSharding],
    ].filter((v): v is SettingsData => !!v);
  }
  if (node.label === ELEMENT_LABEL.cache) {
    return [cacheSettingsMap[node.settings.cachePolicy]].filter((v): v is SettingsData => !!v);
  }
  if (node.label === ELEMENT_LABEL.queue) {
    return [queueSettingsMap[node.settings.queueType]].filter((v): v is SettingsData => !!v);
  }
  return [];
};

export const SettingsFlag: React.FC<SettingsFlagProps> = (props: SettingsFlagProps) => {
  const lang = useLanguageCode();
  const sideLength = (props.node.geometry.maxX - props.node.geometry.minX) / 4;

  const settingsList = generateSettingList(props.node);

  return (
    <g>
      {settingsList.map((settings, i) => (
        <g key={settings.titleEn}>
          <title>
            {resolveLanguage(settings, lang, "title")}: {resolveLanguage(settings, lang, "description")}
          </title>
          <ImageSvg
            xlinkHref={settings.iconImage}
            x={props.node.geometry.maxX}
            y={props.node.geometry.minY + sideLength * i}
            width={sideLength}
            height={sideLength}
          />
        </g>
      ))}
    </g>
  );
};

SettingsFlag.displayName = "SettingsFlag";

export default SettingsFlag;
