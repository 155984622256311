import { state } from "./State";
import type * as Types from "./types";

export const setRes = (res: Types.Res): void => {
  state.res = res;
};
export const initializeFilters = (filter: Types.SearchFilter, pager: Omit<Types.Pager, "offset">) => {
  state.currentSearchFilter = filter;
  state.pager = {
    ...pager,
    offset: pager.page * pager.size,
  };
};

export const clear = (): void => {
  state.res = null;
  state.currentSearchFilter = null;
};

export const updateCurrentSearchFilter = (filter: Types.SearchFilter) => {
  state.currentSearchFilter = filter;
  /** Reset page when search criteria are changed */
  state.pager = {
    ...state.pager,
    page: 0,
    offset: 0,
  };
};

export const updateSortField = (sortFieldValue: Types.SortFieldValue) => {
  state.pager = {
    ...state.pager,
    sortFieldValue: sortFieldValue,
    page: 0,
    offset: 0,
  };
};

export const updatePage = (page: number) => {
  state.pager = {
    ...state.pager,
    page,
    offset: page * state.pager.size,
  };
};

export const updatePageSize = (size: number) => {
  state.pager = {
    ...state.pager,
    page: 0,
    offset: 0,
    size: size,
  };
};
