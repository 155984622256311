import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledTypography = styled(Typography)(() => ({
  whiteSpace: "pre-wrap",
  wordBreak: "break-all",
  maxHeight: "40px",
  textOverflowY: "ellipsis",
  overflow: "hidden",
  display: "-webkit-box",
  "--webkitBoxOrient": "vertical",
  "--webkitLineClamp": 2,
}));

export type MetaProps = {
  title: string;
  description: string;
  width?: string;
};

const Meta: React.FC<MetaProps> = props => {
  return (
    <Stack spacing={1.5} width={props.width}>
      <Typography fontSize={20} fontWeight={700}>
        {props.title}
      </Typography>
      <StyledTypography fontSize={12} color="textSecondary">
        {props.description}
      </StyledTypography>
    </Stack>
  );
};

Meta.displayName = "Meta";

export default Meta;
