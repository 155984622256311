import { useTranslation } from "@hireroo/i18n";
import Button, { ButtonProps } from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import * as React from "react";

export type IncludePrivatePackageWarningDialogProps = {
  open: boolean;
  unpublishedQuestions: { title: string }[];
  /**
   * When package version is v1.0.0 and use this component, it has not been published yet. So, users can change a public package to private.
   */
  additionalMessage: string;
  closeButton: Pick<ButtonProps, "onClick">;
};

const IncludePrivatePackageWarningDialog: React.FC<IncludePrivatePackageWarningDialogProps> = props => {
  const { t } = useTranslation();
  return (
    <Dialog open={props.open}>
      <DialogContent>
        <DialogContentText component="div">
          {t("以下の非公開の設問が含まれているため問題を作成できません。")}
          <ul>
            {props.unpublishedQuestions.map(question => {
              return <li key={question.title}>{question.title}</li>;
            })}
          </ul>
          {props.additionalMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button children={t("閉じる")} {...props.closeButton} />
      </DialogActions>
    </Dialog>
  );
};

IncludePrivatePackageWarningDialog.displayName = "IncludePrivatePackageWarningDialog";

export default IncludePrivatePackageWarningDialog;
