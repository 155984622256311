import { useTranslation } from "@hireroo/i18n";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";

import HelpMenu, { HelpMenuProps } from "../../../../modules/HelpMenu/HelpMenu";
import ButtonWithTooltip, { ButtonWithTooltipProps } from "../../../../primitive/Button/ButtonWithTooltip/ButtonWithTooltip";

const ActionButton = styled(ButtonWithTooltip)(({ theme }) => ({
  wordBreak: "keep-all",
  ".MuiButton-startIcon": {
    color: theme.palette["Gray/Shades"].p56,
  },
}));

export type InterviewNavigationMenuProps = {
  helpButton: HelpMenuProps;
  finishButton?: Pick<ButtonWithTooltipProps, "onClick" | "disabled" | "children">;
};

const InterviewNavigationMenu: React.FC<InterviewNavigationMenuProps> = props => {
  const { t } = useTranslation();
  const finishButtonProps: ButtonWithTooltipProps | false = !!props.finishButton && {
    ...props.finishButton,
    size: "small",
    children: props.finishButton.children ?? t("テストを終了する"),
    startIcon: "LOGOUT",
    variant: "outlined",
    color: "outline-only",
  };
  return (
    <Stack direction="row" spacing={2} height="100%">
      <HelpMenu {...props.helpButton} />
      <ActionButton {...finishButtonProps} />
    </Stack>
  );
};

InterviewNavigationMenu.displayName = "InterviewNavigationMenu";

export default InterviewNavigationMenu;
