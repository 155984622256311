import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GeneratePlanListPropsArgs, useGenerateProps } from "./useGenerateProps";

export type PlanListContainerProps = GeneratePlanListPropsArgs;

const PlanListContainer: React.FC<PlanListContainerProps> = props => {
  const planListProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.PlanList {...planListProps} />
    </ErrorBoundary>
  );
};

PlanListContainer.displayName = "PlanListContainer";

export default withErrorBoundary(PlanListContainer, {});
