import { useTranslation } from "@hireroo/i18n";
import { SupportLanguage } from "@hireroo/i18n";
import * as z from "zod";

import { useEmailListItem } from "./FieldValidator";

export const useMemberInviteByLinkSchema = () => {
  return z.object({
    kind: z.literal("LINK"),
    role: z.string().min(1),
    language: z.enum([SupportLanguage.EN, SupportLanguage.JA]).default(SupportLanguage.JA),
  });
};

export type MemberInviteSchemaByLink = z.infer<ReturnType<typeof useMemberInviteByLinkSchema>>;

export const useMemberInviteByEmailSchema = () => {
  const { t } = useTranslation();
  const emailListItem = useEmailListItem();
  return z.object({
    kind: z.literal("EMAIL"),
    emails: z.array(emailListItem).min(1, {
      message: t("必須項目です"),
    }),
    role: z.string().min(1),
    language: z.enum([SupportLanguage.EN, SupportLanguage.JA]).default(SupportLanguage.JA),
  });
};

export type MemberInviteSchemaByEmail = z.infer<ReturnType<typeof useMemberInviteByEmailSchema>>;

export const useMemberInviteSchema = () => {
  const linkSchema = useMemberInviteByLinkSchema();
  const emailSchema = useMemberInviteByEmailSchema();
  return z.union([linkSchema, emailSchema]);
};

export type MemberInviteSchema = z.infer<ReturnType<typeof useMemberInviteSchema>>;
