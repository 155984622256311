import { useTranslation } from "@hireroo/i18n";
import Group from "@mui/icons-material/Group";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Chip, { ChipProps } from "@mui/material/Chip";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import TagDialog, { TagDialogProps } from "../../../../modules/TagDialog/TagDialog";
import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "../../../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";
import TextLinkify from "../../../../primitive/TextLinkify/TextLinkify";
import AccessPermissionDialog, { AccessPermissionDialogProps } from "../../../AccessPermissionDialog/AccessPermissionDialog";
import ListContent, { ListContentProps } from "../ListContent/ListContent";

const StyledTypography = styled(Typography)(() => ({
  fontSize: "14px",
  variant: "subtitle2",
}));
const StyledChip = styled(Chip)(({ theme }) => ({
  height: 30,
  ".MuiChip-deleteIcon": {
    color: theme.palette.secondary.main,
  },
  color: theme.palette.secondary.main,
  borderColor: theme.palette.secondary.main,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette["Background/Paper"].p2,
  borderRadius: "16px",
}));

const StyledModeEditOutlinedIcon = styled(ModeEditOutlinedIcon)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

type MemberAndGroupChip = ChipProps & {
  photoUrl?: string;
  kind: "MEMBER" | "GROUP";
};
export type ReportInfoProps = {
  data: {
    isPublic: boolean;
    memo?: string;
    tags: string[];
    authorizedMemberAndGroups: MemberAndGroupChip[];
  };
  tagDialog: TagDialogProps;
  accessPermissionDialog: AccessPermissionDialogProps;
};

const ReportInfo: React.FC<ReportInfoProps> = props => {
  const { t } = useTranslation();
  const { data } = props;
  const editTagButton: IconButtonWithTooltipProps = {
    children: <StyledModeEditOutlinedIcon fontSize="small" />,
    onClick: () => {
      props.tagDialog.onOpen?.();
    },
  };
  const editAccessPermissionButton: IconButtonWithTooltipProps = {
    children: <StyledModeEditOutlinedIcon fontSize="small" />,
    onClick: () => {
      props.accessPermissionDialog.onOpen?.();
    },
  };

  const basicListContents: ListContentProps[] = [
    {
      title: t("社内用メモ"),
      children: (
        <StyledTypography whiteSpace="pre-wrap" sx={{ wordBreak: "break-all" }}>
          {data.memo && data.memo.length > 0 ? <TextLinkify>{data.memo}</TextLinkify> : t("メモはありません。")}
        </StyledTypography>
      ),
    },
    {
      title: t("タグ"),
      children: (
        <Box display="flex">
          {data?.tags.length > 0 ? (
            <Stack direction={"row"} sx={{ flexWrap: "wrap", gap: "8px" }}>
              {data?.tags.map(tagName => <StyledChip label={tagName} variant="outlined" key={`tag-chip-${tagName}`} />)}
            </Stack>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center">
              <StyledTypography>{t("タグが指定されていません。")}</StyledTypography>
            </Box>
          )}
          <Box flexGrow={1} />
          <IconButtonWithTooltip {...editTagButton} />
        </Box>
      ),
    },
    {
      title: t("閲覧権限"),
      children: (
        <Box display="flex" alignItems="center">
          {data?.isPublic ? (
            <StyledTypography>{t("メンバー全員に許可する。")}</StyledTypography>
          ) : (
            <Stack direction={"row"} sx={{ flexWrap: "wrap", gap: "8px" }}>
              {props.data.authorizedMemberAndGroups.map(authorized => {
                const { kind, photoUrl, ...rest } = authorized;
                return <Chip key={authorized.key} {...rest} avatar={kind === "GROUP" ? <Group /> : <Avatar src={photoUrl} />} />;
              })}
            </Stack>
          )}
          <Box flexGrow={1} />
          <IconButtonWithTooltip {...editAccessPermissionButton} />
        </Box>
      ),
    },
  ];

  const listContents: ListContentProps[] = [...basicListContents];
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Typography fontSize={16} sx={{ fontWeight: "bold" }}>
          {t("レポート設定")}
        </Typography>
      </Box>
      <StyledBox>
        <List component="nav" aria-label="coding-test-summary" sx={{ px: 2 }}>
          {listContents.map((content, index) => (
            <ListContent key={content.title} {...content} divider={index !== listContents.length - 1} />
          ))}
        </List>
      </StyledBox>
      <TagDialog key={`tag-dialog-open-${props.tagDialog.open.toString()}`} {...props.tagDialog} />
      <AccessPermissionDialog
        key={`access-permission-dialog-open-${props.accessPermissionDialog.open.toString()}`}
        {...props.accessPermissionDialog}
      />
    </Box>
  );
};

ReportInfo.displayName = "ReportInfo";

export default ReportInfo;
