import { getTimeUnitText, Unit } from "@hireroo/formatter/time";
import { useTranslation } from "@hireroo/i18n";
import { useTheme } from "@mui/material/styles";
import * as React from "react";
import { CartesianGrid, Label, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

export type PerformanceLineProps = {
  name: string;
  dataKey: string;
  color: string;
};

export type Size = {
  width: number;
  height: number;
};

export type PerformanceGraphProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>[];
  lines: PerformanceLineProps[];
  xAxisLabelKey: string;
};

const PerformanceGraph: React.FC<PerformanceGraphProps> = props => {
  const theme = useTheme();
  const labelFontFamily = theme.typography.body2.fontFamily;
  const labelFontSize = theme.typography.body2.fontSize;
  const { t } = useTranslation();
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        id="performance-graph-line-chart"
        width={500}
        height={300}
        data={props.data}
        margin={{
          top: 0,
          left: 30,
          bottom: 30,
          right: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <YAxis
          tickFormatter={(v: unknown) => {
            return getTimeUnitText(v as number, Unit.NANOSECOND);
          }}
          axisLine={false}
          tick={{ fontSize: 14, fill: theme.palette.text.primary }}
        >
          <Label style={{ fontSize: 14, fill: theme.palette.text.primary }} offset={10} position={"left"} angle={-90} dy={-30}>
            {t("実行速度")}
          </Label>
        </YAxis>
        <XAxis dataKey={"label"} dy={8} tick={{ fontSize: 14, fill: theme.palette.text.primary }} axisLine={false}>
          <Label style={{ fontSize: 14, fill: theme.palette.text.primary }} offset={10} position={"bottom"}>
            {t("入力サイズ")}
          </Label>
        </XAxis>
        <Tooltip
          formatter={(v: unknown) => {
            return getTimeUnitText(v as number, Unit.NANOSECOND);
          }}
          contentStyle={{
            borderColor: theme.palette.background.paper,
            backgroundColor: theme.palette.background.paper,
            minWidth: "5vw",
          }}
          labelStyle={{
            display: "none",
          }}
        />
        <Legend
          verticalAlign={"top"}
          iconSize={14}
          wrapperStyle={{
            paddingBottom: 12,
            paddingLeft: 30,
            fontSize: 14,
          }}
        />
        {props.lines.map(lineProps => {
          return (
            <Line
              key={lineProps.dataKey}
              type="monotone"
              stroke={lineProps.color}
              strokeWidth={2}
              dataKey={lineProps.dataKey}
              name={lineProps.name}
              strokeDasharray="2 2"
              isAnimationActive={false}
              style={{ fontSize: labelFontSize, fontFamily: labelFontFamily }}
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
};

PerformanceGraph.displayName = "PerformanceGraph";

export default PerformanceGraph;
