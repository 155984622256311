import { useTranslation } from "@hireroo/i18n";
import { PaymentForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import ApplicationConfirmationSection, {
  ApplicationConfirmationSectionProps,
} from "../../ms-components/Payment/ApplicationConfirmationSection/ApplicationConfirmationSection";
import CouponField, { CouponFieldProps } from "../../ms-components/Payment/CouponField/CouponField";
import SelectNumberOfSelectionField, {
  SelectNumberOfSelectionFieldProps,
} from "../../ms-components/Payment/SelectNumberOfSelectionField/SelectNumberOfSelectionField";

const Footer = styled(Box)(() => ({
  textAlign: "center",
}));

const SectionLabel = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  marginBottom: theme.spacing(2),
}));

export type BuySelectionFormProps = {
  onSubmit: SubmitHandler<PaymentForm.BuySelectionForm>;
  nextMonthPaymentConfirmSection: ApplicationConfirmationSectionProps;
  selectNumberOfSelectionField: Omit<SelectNumberOfSelectionFieldProps, "name">;
  defaultValues: PaymentForm.BuySelectionForm;
  couponField?: Omit<CouponFieldProps, "name">;
};

const BuySelectionForm: React.FC<BuySelectionFormProps> = props => {
  const { t } = useTranslation();
  const validateSchema = PaymentForm.useBuySelectionForm();
  const methods = useForm<PaymentForm.BuySelectionForm>({
    resolver: zodResolver(validateSchema),
    defaultValues: props.defaultValues,
  });

  const count = methods.watch("selectionItems").reduce((total, current) => {
    const item = props.selectNumberOfSelectionField.items[current.itemId];
    if (!item) {
      return total;
    }
    return total + item.price;
  }, 0);
  const confirmButton: ButtonProps = {
    disabled: count === 0,
    variant: "contained",
    children: t("申込内容の確認をする"),
    onClick: () => {
      methods.handleSubmit(props.onSubmit, console.error)();
    },
  };

  return (
    <FormProvider {...methods}>
      <Box>
        <SectionLabel>{t("選考数の追加")}</SectionLabel>
        <SectionLabel>{t("選考数")}</SectionLabel>
        <SelectNumberOfSelectionField {...props.selectNumberOfSelectionField} name="selectionItems" mb={3} />
        {props.couponField && (
          <>
            <SectionLabel>{t("クーポン")}</SectionLabel>
            <CouponField {...props.couponField} name="coupon" mb={3} />
          </>
        )}
        <SectionLabel>{t("お申し込み内容")}</SectionLabel>
        <ApplicationConfirmationSection {...props.nextMonthPaymentConfirmSection} mb={3} />
        <Footer>
          <Button {...confirmButton} />
        </Footer>
      </Box>
    </FormProvider>
  );
};

BuySelectionForm.displayName = "BuySelectionForm";

export default BuySelectionForm;
