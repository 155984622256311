import { Pages } from "@hireroo/presentation/legacy";

import { useAnswersDefaultValues, useOptionalDefaultValues, useQuestionDetailDefaultValues } from "./privateHelper";

export const useGenerateProviderProps = (): Pages.FreepadResourceEditorProviderProps => {
  const questionDetail = useQuestionDetailDefaultValues();
  const answers = useAnswersDefaultValues();
  const optional = useOptionalDefaultValues();

  return {
    questionDetailProvider: questionDetail,
    answerProvider: answers,
    optionalProvider: optional,
  };
};
