import { useTranslation } from "@hireroo/i18n";
import {
  ProjectEditorToolBar,
  type ProjectEditorToolBarProps,
  ProjectFileNavigationV3,
  type ProjectFileNavigationV3Props,
  ProjectWorkspace,
  type ProjectWorkspaceProps,
} from "@hireroo/project/react/v2/widget";
import { useMethods, withSplitProvider } from "@hireroo/react-split";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";

import ActivityBar, { ActivityBarProps } from "../../modules/ActivityBar/ActivityBar";
import AnswerSection, { AnswerSectionProps } from "../../ms-components/Question/AnswerSection/AnswerSection";
import QuestionAndAnswerTab, { QuestionAndAnswerTabProps } from "../../ms-components/Question/QuestionAndAnswerTab/QuestionAndAnswerTab";
import QuestionInformationSidePanel, {
  QuestionInformationSidePanelProps,
} from "../../ms-components/Question/QuestionInformationSidePanel/QuestionInformationSidePanel";
import Split, { SplitProps } from "../../primitive/Split/Split";
import { SplitParams, useSplitEvent } from "./privateHelper";

const StyledSplit = styled(Split)`
  height: calc(100vh - 73px);
  width: 100%;
  overflow: hidden;
  position: relative;
`;

const StyledQuestionAndAnswerTab = styled(QuestionAndAnswerTab)(({ theme }) => ({
  backgroundColor: theme.palette["Secondary/Shades"].p8,
}));

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  max-width: 100vw;
`;

export type ProjectQuestionDetailProps = {
  information: QuestionInformationSidePanelProps;
  questionAndAnswerTab: Omit<QuestionAndAnswerTabProps, "answerSections">;
  toolbar: ProjectEditorToolBarProps;
  workspace: ProjectWorkspaceProps;
  answerSections: AnswerSectionProps[];
  fileNavigation: Omit<ProjectFileNavigationV3Props, "resetButton" | "createFileButton" | "createDirectoryButton" | "deleteNodeButton">;
};

const ProjectQuestionDetail: React.FC<ProjectQuestionDetailProps> = props => {
  const methods = useMethods();
  const { t } = useTranslation();
  const splitEvent = useSplitEvent();

  const questionAndAnswerTabProps: QuestionAndAnswerTabProps = {
    ...props.questionAndAnswerTab,
    answerSections: props.answerSections.map((answerSection, index): QuestionAndAnswerTabProps["answerSections"][0] => {
      return {
        name: [t("解答"), index + 1].join(" "),
        Component: <AnswerSection {...answerSection} />,
      };
    }),
  };

  const fileNavigationProps: ProjectFileNavigationV3Props = {
    ...props.fileNavigation,
    resetButton: {
      disabled: true,
    },
    createFileButton: undefined,
    createDirectoryButton: undefined,
    deleteNodeButton: undefined,
  };

  const activityBarProps: ActivityBarProps = {
    items: [
      {
        icon: "DESCRIPTION",
        tooltip: t("問題"),
        onClick: () => {
          if (splitEvent.questionAndAnswerVisibleStatus === "MINIMIZED") {
            methods.expand(SplitParams.splitId, SplitParams.Contents.QuestionAndAnswer.id);
          } else {
            methods.minimize(SplitParams.splitId, SplitParams.Contents.QuestionAndAnswer.id);
          }
          splitEvent.setQuestionAndAnswerVisibleStatus(prev => (prev === "MINIMIZED" ? "OPEN" : "MINIMIZED"));
        },
        active: splitEvent.questionAndAnswerVisibleStatus === "OPEN",
      },
      {
        icon: "FOLDER",
        tooltip: t("ファイルツリー"),
        onClick: () => {
          if (splitEvent.fileTreeVisibleStatus === "MINIMIZED") {
            methods.expand(SplitParams.splitId, SplitParams.Contents.FileNavigation.id);
          } else {
            methods.minimize(SplitParams.splitId, SplitParams.Contents.FileNavigation.id);
          }
          splitEvent.setFileTreeVisibleStatus(prev => (prev === "MINIMIZED" ? "OPEN" : "MINIMIZED"));
        },
        active: splitEvent.fileTreeVisibleStatus === "OPEN",
      },
      {
        icon: "INFO",
        tooltip: t("この問題について"),
        onClick: () => {
          if (splitEvent.informationVisibleStatus === "MINIMIZED") {
            methods.expand(SplitParams.splitId, SplitParams.Contents.Information.id);
          } else {
            methods.minimize(SplitParams.splitId, SplitParams.Contents.Information.id);
          }
          splitEvent.setInformationVisibleStatus(prev => (prev === "MINIMIZED" ? "OPEN" : "MINIMIZED"));
        },
        active: splitEvent.informationVisibleStatus === "OPEN",
      },
    ],
  };

  const splitProps: SplitProps = {
    splitId: SplitParams.splitId,
    splitDirection: "VERTICAL",
    items: [
      {
        id: SplitParams.Contents.QuestionAndAnswer.id,
        Content: <StyledQuestionAndAnswerTab {...questionAndAnswerTabProps} />,
        size: {
          value: 400,
          unit: "px",
        },
        expandedSize: {
          value: 400,
          unit: "px",
        },
        minimizedSize: {
          value: 0,
          unit: "px",
        },
      },
      {
        id: SplitParams.Contents.FileNavigation.id,
        Content: <ProjectFileNavigationV3 key="file-navigation" {...fileNavigationProps} />,
        size: {
          value: 300,
          unit: "px",
        },
        expandedSize: {
          value: 300,
          unit: "px",
        },
        minimizedSize: {
          value: 0,
          unit: "px",
        },
      },
      {
        id: SplitParams.Contents.Editor.id,
        Content: (
          <Box key="editor" height="100%" width="100%" overflow="hidden">
            <ProjectEditorToolBar {...props.toolbar} />
            <ProjectWorkspace key="editor" {...props.workspace} />
          </Box>
        ),
        size: {
          value: 100,
          unit: "%",
        },
      },
      {
        id: SplitParams.Contents.Information.id,
        Content: <QuestionInformationSidePanel {...props.information} />,
        size: {
          value: 400,
          unit: "px",
        },
        expandedSize: {
          value: 400,
          unit: "px",
        },
        minimizedSize: {
          value: 0,
          unit: "px",
        },
      },
    ],
  };

  return (
    <Wrapper>
      <ActivityBar {...activityBarProps} />
      <StyledSplit {...splitProps} />
    </Wrapper>
  );
};

ProjectQuestionDetail.displayName = "ProjectQuestionDetail";

export default withSplitProvider(ProjectQuestionDetail);
