import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type CompanyProfileSettingsContainerProps = {};

const CompanyProfileSettingsContainer: React.FC<CompanyProfileSettingsContainerProps> = () => {
  const companyProfileSettingsProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Pages.CompanySettings {...companyProfileSettingsProps} />
    </ErrorBoundary>
  );
};

CompanyProfileSettingsContainer.displayName = "CompanyProfileSettingsContainer";

export default withErrorBoundary(CompanyProfileSettingsContainer, {});
