import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type RemoteInterviewEvaluationContainerProps = {
  remoteId: string;
};

const RemoteInterviewEvaluationContainer: React.FC<RemoteInterviewEvaluationContainerProps> = props => {
  const RemoteInterviewEvaluationProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.RemoteInterviewEvaluation {...RemoteInterviewEvaluationProps} />
    </ErrorBoundary>
  );
};

RemoteInterviewEvaluationContainer.displayName = "RemoteInterviewEvaluationContainer";

export default withErrorBoundary(RemoteInterviewEvaluationContainer, {});
