import Editor, { Monaco, SwappableCodeEditorProps as EditorProps } from "@hireroo/code-editor/react/SwappableCodeEditor";
import StyledWrapper from "@hireroo/code-editor/react/v2/StyledWrapper";
import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import type * as monaco from "monaco-editor";
import * as React from "react";

import { OptionalPlaybackChangeParams } from "../../../../modules/PlaybackToolbar/PlaybackToolbar";
import PlaybackToolbar, { PlaybackToolbarProps } from "../../../../modules/PlaybackToolbar/PlaybackToolbar";
import ChallengeDetailPlayback, { ChallengeDetailPlaybackProps } from "./parts/ChallengeDetailPlayback/ChallengeDetailPlayback";

const CODE_EDITOR_HEIGHT = 450;

export type ReportChallengePlaybackProps = {
  monaco?: Monaco | null;
  modelForDefaultScreen?: monaco.editor.ITextModel | null;
  modelForFullScreen?: monaco.editor.ITextModel | null;
  toolbar: Omit<PlaybackToolbarProps, "screenButton" | "screenStatus" | "enableAutoplay" | "onChangePlaybackValue">;
  status: "READY" | "LOADING" | "NO_DATA";
  showPasteRange: boolean;
  showRightSidePanel: boolean;
  RightSidePanel: React.ReactNode;
  ActivityTimelineLog: ChallengeDetailPlaybackProps["ActivityTimelineLog"];
  StatisticsContents: ChallengeDetailPlaybackProps["StatisticsContents"];
  appealMessageInReport: ChallengeDetailPlaybackProps["appealMessageInReport"];
  onChangeSliderValue: (value: number, isTouchedPlay?: boolean) => void;
  onEditorMount?: EditorProps["onMount"];
};

const ReportChallengePlayback: React.FC<ReportChallengePlaybackProps> = props => {
  const { t } = useTranslation();
  const {
    onEditorMount,
    RightSidePanel,
    ActivityTimelineLog,
    StatisticsContents,
    onChangeSliderValue,
    modelForDefaultScreen,
    modelForFullScreen,
    showRightSidePanel,
  } = props;
  const [screenStatus, setScreenStatus] = React.useState<"DEFAULT" | "EXPANDED">("DEFAULT");

  const handleExpandedEditor = React.useCallback(() => {
    setScreenStatus(draft => {
      return draft === "EXPANDED" ? "DEFAULT" : "EXPANDED";
    });
  }, []);

  const handleCloseExpandedEditor = React.useCallback(() => {
    setScreenStatus("DEFAULT");
  }, []);

  const handleChangePlaybackValue: PlaybackToolbarProps["onChangePlaybackValue"] = React.useCallback(
    (value: number, params?: OptionalPlaybackChangeParams) => {
      const isTouchedPlay = (params && params.isTouched && params.playStatus === "PLAY") ?? false;
      onChangeSliderValue(value, isTouchedPlay);
    },
    [onChangeSliderValue],
  );

  const playbackToolbar = React.useMemo((): Omit<PlaybackToolbarProps, "enableAutoplay"> => {
    return {
      ...props.toolbar,
      onChangePlaybackValue: handleChangePlaybackValue,
      slider: {
        ...props.toolbar.slider,
        min: 0,
      },
      screenButton: {
        onClick: handleExpandedEditor,
      },
      screenStatus: screenStatus,
    };
  }, [props.toolbar, screenStatus, handleExpandedEditor, handleChangePlaybackValue]);

  const codeEditor = React.useMemo((): EditorProps | undefined => {
    if (!modelForDefaultScreen) {
      return undefined;
    }
    return {
      height: CODE_EDITOR_HEIGHT,
      options: {
        fontSize: 13,
        model: modelForDefaultScreen,
        readOnly: true,
        minimap: {
          enabled: false,
        },
      },
      keepCurrentModel: true,
    };
  }, [modelForDefaultScreen]);

  const challengeDetailPlaybackProps = React.useMemo((): ChallengeDetailPlaybackProps | undefined => {
    if (!modelForFullScreen) {
      return undefined;
    }
    return {
      showRightSidePanel: showRightSidePanel,
      model: modelForFullScreen,
      showPasteRange: props.showPasteRange,
      toolbar: playbackToolbar,
      RightSidePanel: props.RightSidePanel,
      onClose: handleCloseExpandedEditor,
      ActivityTimelineLog: ActivityTimelineLog,
      StatisticsContents: StatisticsContents.filter(Boolean),
      appealMessageInReport: props.appealMessageInReport,
      onEditorMount: onEditorMount,
    };
  }, [
    showRightSidePanel,
    onEditorMount,
    handleCloseExpandedEditor,
    modelForFullScreen,
    StatisticsContents,
    ActivityTimelineLog,
    props.appealMessageInReport,
    props.RightSidePanel,
    props.showPasteRange,
    playbackToolbar,
  ]);

  const codeEditorSize = RightSidePanel && showRightSidePanel ? 8 : 12;
  const rightSidePanelDisplay = RightSidePanel && showRightSidePanel ? "block" : "none";

  if (props.status === "LOADING") {
    return (
      <Box minHeight={500} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  }

  if (props.status === "NO_DATA") {
    return (
      <Box width={"100%"} height={"100%"} py={2}>
        <Typography variant="body2" color="text.secondary" fontSize={14} textAlign="center">
          {t("提出過程がありませんでした。")}
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Box display={"flex"} flexDirection={"column"}>
        <Grid container display={"flex"}>
          <Grid item xs={codeEditorSize}>
            <StyledWrapper theme={props.showPasteRange ? "RED" : "TRANSPARENT"}>
              {codeEditor && (
                <React.Suspense>
                  <Editor key="editor-1" {...codeEditor} onMount={onEditorMount} theme="monokai" />
                </React.Suspense>
              )}
            </StyledWrapper>
          </Grid>
          <Grid item xs={12 - codeEditorSize} display={rightSidePanelDisplay} height={CODE_EDITOR_HEIGHT}>
            {RightSidePanel}
          </Grid>
        </Grid>
        <PlaybackToolbar {...playbackToolbar} enableAutoplay={screenStatus === "DEFAULT"} key={screenStatus} />
      </Box>

      <Dialog onClose={handleCloseExpandedEditor} open={screenStatus === "EXPANDED"} maxWidth={false} fullScreen>
        {challengeDetailPlaybackProps && <ChallengeDetailPlayback {...challengeDetailPlaybackProps} />}
      </Dialog>
    </Box>
  );
};

ReportChallengePlayback.displayName = "ReportChallengePlayback";

export default ReportChallengePlayback;
