import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import * as React from "react";

import TestReportEvaluationItem, { TestReportEvaluationItemProps } from "../../../modules/TestReportEvaluationItem/TestReportEvaluationItem";

type Item = Pick<TestReportEvaluationItemProps, "value" | "icon">;

export type ReportProjectAnswerDetailSectionProps = {
  totalElapsedTime: Item;
  runNum: Item;
  latency: Item;
  sla?: Item | false;
  /**
   * When this flag is true, additional information will be displayed by means of tooltips.
   */
  canShowTooltip: boolean;
};

const ReportProjectAnswerDetailSection: React.FC<ReportProjectAnswerDetailSectionProps> = props => {
  const { t } = useTranslation();
  const leftSections: TestReportEvaluationItemProps[] = [
    {
      ...props.totalElapsedTime,
      title: t("経過時間"),
      canShowTooltip: props.canShowTooltip,
    },
    {
      ...props.runNum,
      title: t("実行回数"),
      canShowTooltip: props.canShowTooltip,
    },
  ];

  const rightSections: TestReportEvaluationItemProps[] = [
    {
      ...props.latency,
      title: t("レイテンシー"),
      canShowTooltip: props.canShowTooltip,
    },
    props.sla && {
      ...props.sla,
      title: "SLA",
      canShowTooltip: props.canShowTooltip,
    },
  ].flatMap(v => (v ? [v] : []));
  return (
    <Box>
      <Typography variant="subtitle1" sx={theme => ({ fontWeight: "bold", color: theme.palette.text.secondary })} mb={3}>
        {t("解答詳細")}
      </Typography>
      <Grid container columnSpacing={1}>
        <Grid item xs={6}>
          <List component="nav" aria-label="answer-detail-1">
            {leftSections.map(section => (
              <TestReportEvaluationItem key={section.title} {...section} />
            ))}
          </List>
        </Grid>
        <Grid item xs={6}>
          <List component="nav" aria-label="answer-detail-1">
            {rightSections.map(section => (
              <TestReportEvaluationItem key={section.title} {...section} />
            ))}
          </List>
        </Grid>
      </Grid>
    </Box>
  );
};

ReportProjectAnswerDetailSection.displayName = "ReportProjectAnswerDetailSection";

export default ReportProjectAnswerDetailSection;
