import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateExtendedCodingEditorPropsArgs, useGenerateProps } from "./useGenerateProps";

export type ExtendedCodingEditorContainerProps = GenerateExtendedCodingEditorPropsArgs;

const ExtendedCodingEditorContainer: React.FC<ExtendedCodingEditorContainerProps> = props => {
  const projectExtendedCodeEditorProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ProjectExtendedCodeEditor {...projectExtendedCodeEditorProps} key={props.filepath} />
    </ErrorBoundary>
  );
};

ExtendedCodingEditorContainer.displayName = "ExtendedCodingEditorContainer";

export default withErrorBoundary(ExtendedCodingEditorContainer, {});
