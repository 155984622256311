import { useTranslation } from "@hireroo/i18n";
import * as React from "react";

import BaseDialog, { BaseDialogProps } from "../../primitive/BaseDialog/BaseDialog";

export type SystemDesignResetFlowChartDialogProps = Pick<BaseDialogProps, "open" | "yesButton" | "noButton">;

const SystemDesignResetFlowChartDialog: React.FC<SystemDesignResetFlowChartDialogProps> = props => {
  const { t } = useTranslation();
  const baseDialogProps: BaseDialogProps = {
    ...props,
    title: t("フローチャートを初期状態に戻します。よろしいですか？"),
    yesButton: {
      ...props.yesButton,
      children: t("はい"),
    },
    noButton: {
      ...props.noButton,
      children: t("いいえ"),
    },
  };
  return <BaseDialog {...baseDialogProps}>{t("破棄された変更内容は復元できませんのでご注意ください。")}</BaseDialog>;
};

SystemDesignResetFlowChartDialog.displayName = "SystemDesignResetFlowChartDialog";

export default SystemDesignResetFlowChartDialog;
