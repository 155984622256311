import { useSnapshot } from "valtio";

import { state } from "./State";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = (entityId: number) => {
  const snapshot = useSnapshotState();
  return snapshot.systemDesigns.has(entityId);
};

export const useCreateSystemDesignEntityHooks = (entityId: number) => {
  const snapshot = useSnapshotState();
  const entityState = snapshot.systemDesigns.get(entityId);

  if (!entityState) {
    throw new Error(`Please initialize entityId=${entityId}`);
  }

  const useTestCase = () => {
    return entityState.testCases;
  };

  const useEntity = () => {
    return entityState.entity;
  };

  const useQuestion = () => {
    return entityState.entity.question;
  };

  const useStatus = () => {
    return entityState.entity.status;
  };

  const useAppealMessage = () => {
    return entityState.appealMessage;
  };

  const useIpAddress = () => {
    const snapshot = useSnapshotState();
    return snapshot.ipAddress;
  };

  const useGeolocation = () => {
    const snapshot = useSnapshotState();
    return snapshot.geolocation;
  };

  const useEnabledHint = () => {
    return entityState.enabledHint;
  };

  return {
    useEnabledHint,
    useEntity,
    useTestCase,
    useQuestion,
    useStatus,
    useAppealMessage,
    useIpAddress,
    useGeolocation,
  };
};
