import { useLanguageCode, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { ScreeningTestRetakeForm } from "@hireroo/validator";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import ResourceField from "../../../../modules/ResourceField/ResourceField";
import DateTimeControl, { DateTimeControlProps } from "../../../../primitive/InputControl/DateTimeControl/DateTimeControl";
import InputControlTextField, {
  InputControlTextFieldProps,
} from "../../../../primitive/InputControl/InputControlTextField/InputControlTextField";
import RetakeEntitiesField, { RetakeEntitiesFieldProps } from "../RetakeEntitiesField/RetakeEntitiesField";
import RetakeReasonField from "../RetakeReasonField/RetakeReasonField";

const StyledDateTimeControl = styled(DateTimeControl)(() => ({
  ".MuiOutlinedInput-input": {
    paddingLeft: 12,
    paddingRight: 12,
  },
}));
const StyledStack = styled(Stack)(({ theme }) => ({
  borderRadius: "4px",
  backgroundColor: theme.palette.Other.FilledInputBG,
}));

const ReservedFieldName = {
  SELECTED_ENTITY_IDS: "selectedEntityIds",
  TIME_LIMIT_MINUTES: "timeLimitMinutes",
  WILL_END_AT: "willEndAt",
  REASON_TYPE: "reasonType",
  REASON_COMMENT: "reasonComment",
  AGREEMENT: "agreement",
} satisfies Record<string, keyof ScreeningTestRetakeForm.RetakeScreeningTestSchema>;

export type ScreeningTestRetakeEditorProps = {
  maxTimelimitMinutes: number;
  retakeEntitiesField: Omit<RetakeEntitiesFieldProps, "maxTimelimitMinutes">;
};

const ScreeningTestRetakeEditor: React.FC<ScreeningTestRetakeEditorProps> = props => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const theme = useTheme();
  const methods = useFormContext<ScreeningTestRetakeForm.RetakeScreeningTestSchema>();
  const lang = useLanguageCode();
  const selectedTimeLimitMinutes = methods
    .watch(ReservedFieldName.SELECTED_ENTITY_IDS)
    .reduce((all, entity) => all + entity.timeLimitMinutes, 0);

  const timelimitProps: InputControlTextFieldProps = {
    id: "timelimit",
    color: "secondary",
    type: "number",
    InputLabelProps: {
      shrink: true,
    },
    InputProps: {
      inputProps: {
        min: 5,
      },
      endAdornment: (
        <Box>
          <Typography fontSize={16}>{t("分")}</Typography>
        </Box>
      ),
    },
    variant: "outlined",
    placeholder: "10",
    helperText: `${t2("MaxMinMessage", { maxMin: props.maxTimelimitMinutes })} ${t2("EstimatedMinutes", {
      time: Math.min(selectedTimeLimitMinutes, props.maxTimelimitMinutes),
    })}`,
  };

  const willEndAtDateProps: DateTimeControlProps = {
    dateTimePicker: {
      filterDateFn: date => {
        const min = new Date();
        min.setHours(0);
        min.setMinutes(0);
        min.setSeconds(0);
        min.setMilliseconds(0);
        return date.getTime() >= min.getTime();
      },
      lang,
      customInput: {
        id: ReservedFieldName.WILL_END_AT,
        name: ReservedFieldName.WILL_END_AT,
        color: "secondary",
        required: true,
        InputLabelProps: {
          shrink: true,
        },
        InputProps: {
          endAdornment: <CalendarTodayOutlinedIcon fontSize="small" htmlColor={theme.palette.text.secondary} />,
        },
        fullWidth: true,
        variant: "outlined",
      },
      datePicker: {
        disabled: false,
        required: true,
        placeholderText: t("指定なし"),
        timeInputLabel: t("時間"),
        showTimeSelect: true,
        timeFormat: "p",
      },
    },
  };

  return (
    <Stack direction="column" spacing={3}>
      <ResourceField
        label={t("出題する問題")}
        kind="REQUIRED"
        help={{
          kind: "TOOLTIP",
          text: t("候補者に再試験してもらう問題を選びます。"),
        }}
      >
        <RetakeEntitiesField {...props.retakeEntitiesField} maxTimelimitMinutes={props.maxTimelimitMinutes} />
      </ResourceField>
      <ResourceField
        label={t("制限時間")}
        kind="REQUIRED"
        help={{
          kind: "TOOLTIP",
          text: t("制限時間の最大値は、元の試験（再試験を含む）の制限時間になります。"),
        }}
      >
        <InputControlTextField name={ReservedFieldName.TIME_LIMIT_MINUTES} {...timelimitProps} />
      </ResourceField>
      <ResourceField label={t("テストの提出期限")} kind="REQUIRED">
        <Stack direction="column" spacing="3px">
          <StyledDateTimeControl name={ReservedFieldName.WILL_END_AT} {...willEndAtDateProps} />
          {!methods.getFieldState(ReservedFieldName.WILL_END_AT).error && (
            <Box pl={1.5}>
              <Typography color="textSecondary" fontSize={12}>
                {t("候補者は期限内であればいつでも提出可能です。")}
              </Typography>
            </Box>
          )}
        </Stack>
      </ResourceField>
      <StyledStack p={2}>
        <ResourceField label={t("再試験の理由")} kind="REQUIRED">
          <RetakeReasonField />
        </ResourceField>
      </StyledStack>
    </Stack>
  );
};

ScreeningTestRetakeEditor.displayName = "ScreeningTestRetakeEditor";

export default ScreeningTestRetakeEditor;
