import { App } from "@hireroo/app-store/essential/employee";
import { RemoteInterviewPlayback } from "@hireroo/app-store/widget/e/RemoteInterviewPlayback";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";
import { useParams } from "react-router-dom";

import RemoteInterviewPlaybackContainer, { RemoteInterviewPlaybackContainerProps } from "./Container";

export type RemoteInterviewPlaybackFetchContainerProps = {};

const RemoteInterviewPlaybackFetchContainer: React.FC<RemoteInterviewPlaybackFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const { id } = useParams<{ id: string }>();
  const remoteId = `${id}`;
  const initialized = RemoteInterviewPlayback.useInitialized();
  const [result, refresh] = Graphql.useGetRemoteForRemoteInterviewPlaybackQuery({
    variables: {
      remoteId: remoteId,
    },
    pause: appStatus !== "INITIALIZED",
    /**
     * This request policy should be network-only.
     * Otherwise, RemoteInterviewChallengePlaybackEditorContainer will be error(maximum call stack) when there is unexpected re-rendering.
     * Please fix the monaco editor model maximum call stack problem before changing this request policy.
     */
    requestPolicy: "network-only",
  });
  React.useEffect(() => {
    if (result.data?.remote) {
      RemoteInterviewPlayback.initialize(result.data.remote);
    }
  }, [refresh, result.data]);

  React.useEffect(() => {
    return () => {
      RemoteInterviewPlayback.clear();
    };
  }, []);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: RemoteInterviewPlaybackContainerProps = {};

  return <RemoteInterviewPlaybackContainer {...containerProps} />;
};

RemoteInterviewPlaybackFetchContainer.displayName = "RemoteInterviewPlaybackFetchContainer";

export default withErrorBoundary(RemoteInterviewPlaybackFetchContainer, {});
