import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import Typography from "@mui/material/Typography";
import * as React from "react";

import Link from "../../../../primitive/Link/Link";

export type TermsOfUseLinkProps = {
  href: string;
};

const TermsOfUseLink: React.FC<TermsOfUseLinkProps> = props => {
  const { t } = useTranslation();
  const lang = useLanguageCode();

  if (lang === "en") {
    /**
     * The trailing SPACE is required
     */
    const text = `By signing up, you agree to HireRoo's `;
    return (
      <Typography variant="caption">
        {text}
        <Link underline="hover" href={props.href} target="_blank">
          <Typography display="inline" color="primary" variant="caption">
            {t("利用規約")}
          </Typography>
        </Link>
      </Typography>
    );
  }

  return (
    <Typography variant="caption">
      サインアップすることによって、ハイヤールーの
      <Link underline="hover" href={props.href} target="_blank">
        <Typography display="inline" color="primary" variant="caption">
          利用規約
        </Typography>
      </Link>
      に同意します。
    </Typography>
  );
};

TermsOfUseLink.displayName = "TermsOfUseLink";

export default TermsOfUseLink;
