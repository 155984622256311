import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type RemoteInterviewFeedbackContainerProps = {};

const RemoteInterviewFeedbackContainer: React.FC<RemoteInterviewFeedbackContainerProps> = () => {
  const remoteInterviewFeedbackProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.RemoteInterviewFeedback {...remoteInterviewFeedbackProps} />
    </ErrorBoundary>
  );
};

RemoteInterviewFeedbackContainer.displayName = "RemoteInterviewFeedbackContainer";

export default withErrorBoundary(RemoteInterviewFeedbackContainer, {});
