import Card from "@mui/material/Card";
import CardActionArea, { CardActionAreaProps } from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import * as React from "react";

import Link from "../../../primitive/Link/Link";
import ActionMenu, { ActionMenuProps } from "./parts/ActionMenu/ActionMenu";
import AssessmentCardTitle, { AssessmentCardTitleProps } from "./parts/AssessmentCardTitle/AssessmentCardTitle";
import ResultListItem, { ResultListItemProps } from "./parts/ResultListItem/ResultListItem";
import TextListItem, { TextListItemProps } from "./parts/TextListItem/TextListItem";

const StyledCard = styled(Card)(({ theme }) => ({
  width: "300px",
  borderRadius: "8px",
  backgroundColor: theme.palette["Background/Paper"].p2,
}));

const StyledCardActionArea = styled(CardActionArea)`
  padding: 16px;
`;

const StyledCardContent = styled(CardContent)`
  padding: 0;
`;

export type AssessmentCardProps = {
  title: string;
  status: AssessmentCardTitleProps["status"];
  schedule: Omit<TextListItemProps, "icon">;
  viewer: Omit<TextListItemProps, "icon">;
  talent: Omit<TextListItemProps, "icon">;
  previousResult?: ResultListItemProps["previous"];
  currentResult?: ResultListItemProps["current"];
  actionMenu: ActionMenuProps;
  href: string;
  onClick: () => void;
};

const AssessmentCard: React.FC<AssessmentCardProps> = props => {
  const cardActionAreaProps: CardActionAreaProps & { href: string } = {
    LinkComponent: Link,
    href: props.href,
    onClick: props.onClick,
  };
  const TextListItemPropsList: TextListItemProps[] = [
    {
      ...props.schedule,
      icon: "ACCESS_TIME",
    },
    {
      ...props.viewer,
      icon: "GROUP",
    },
    {
      ...props.talent,
      icon: "PERSON",
    },
  ];
  return (
    <StyledCard>
      <StyledCardActionArea {...cardActionAreaProps}>
        <CardHeader
          action={<ActionMenu {...props.actionMenu} />}
          title={<AssessmentCardTitle name={props.title} status={props.status} />}
          sx={{ p: 0, mb: 0 }}
        />
        <StyledCardContent>
          <List sx={{ padding: 0 }}>
            {TextListItemPropsList.map((textListItemProps, index) => (
              <TextListItem key={`${textListItemProps.icon}-${index}`} {...textListItemProps} />
            ))}
            <ResultListItem icon="TRENDING_UP" previous={props.previousResult} current={props.currentResult} />
          </List>
        </StyledCardContent>
      </StyledCardActionArea>
    </StyledCard>
  );
};

AssessmentCard.displayName = "AssessmentCard";

export default AssessmentCard;
