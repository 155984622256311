import { grey } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import * as React from "react";
import { Area, AreaChart, CartesianGrid, Dot, Label, ReferenceArea, ResponsiveContainer, XAxis, YAxis } from "recharts";

import { CustomReferenceLineProps, useCreateCustomReferenceLine } from "../../privateHelpers/CustomReferenceLine";

type Axis = {
  label?: string;
};

export type Data = {
  score: number;
  value: number;
};

export type AreaHistogramProps = {
  xAxis: Axis;
  yAxis: Axis;
  data: Data[];
  dataKey: {
    x: string;
    y: string;
  };
  lines: CustomReferenceLineProps[];
  range: {
    startX?: number;
    endX?: number;
    fill: string;
  };
};

const AreaHistogram: React.FC<AreaHistogramProps> = props => {
  const theme = useTheme();
  const createCustomReferenceLine = useCreateCustomReferenceLine();
  return (
    <ResponsiveContainer width={"100%"} height={"100%"}>
      <AreaChart
        id="barchart-score-histogram"
        data-testid="test"
        data={props.data}
        barGap={0}
        barCategoryGap={0}
        margin={{
          top: props.lines.length ? 60 : 0, // because benchmark label comes on top of the plot
          left: props.yAxis.label ? 30 : 0, // because we need some space on the left for y axis label
          right: props.lines.length ? 70 : 0, // because we need some space on the left for benchmark label
          bottom: props.xAxis.label ? 50 : 0, // because we need some space on the bottom, in addition to the ticks
        }}
      >
        <CartesianGrid stroke={theme.palette.text.disabled} strokeDasharray="3 3" vertical={false} />
        <YAxis width={40} axisLine={false} tickLine={false} tick={{ fontSize: 14, fill: theme.palette.text.primary }}>
          <Label style={{ fontSize: 14, fill: theme.palette.text.primary }} offset={10} position={"left"} angle={-90} dy={-30}>
            {props.yAxis.label}
          </Label>
        </YAxis>
        <XAxis type={"number"} dataKey={"score"} minTickGap={-30} axisLine={true} angle={-45} dy={20} tick={false} stroke={"white"}>
          <Label style={{ fontSize: 14, fill: theme.palette.text.primary }} offset={35} position={"bottom"}>
            {props.xAxis.label}
          </Label>
        </XAxis>
        <Area
          type="monotone"
          dataKey={"value"}
          stroke={grey[100]}
          strokeOpacity={0.7}
          fill={grey[100]}
          fillOpacity={0.1}
          isAnimationActive={false}
        />
        {props.lines?.map(benchmark => createCustomReferenceLine(benchmark))}
        {props.range.startX !== undefined && props.range.endX !== undefined && (
          <ReferenceArea x1={props.range.startX} x2={props.range.endX} fill={props.range.fill} fillOpacity={0.3} />
        )}
        <Dot cx={10} cy={10} r={100} stroke="black" strokeWidth={3} fill="red" />
      </AreaChart>
    </ResponsiveContainer>
  );
};

AreaHistogram.displayName = "AreaHistogram";

export default AreaHistogram;
