import { INITIAL_VERSION } from "@hireroo/app-definition/question";
import { DeepReadonly } from "@hireroo/app-helper/types";
import * as Graphql from "@hireroo/graphql/client/urql";

import type * as Types from "./types";

export const SortFields = {
  ACCURACY_RATE_DESCENDING: "accuracy-rate-descending",
  ACCURACY_RATE_ASCENDING: "accuracy-rate-ascending",
  AVERAGE_ELAPSED_TIME_DESCENDING: "average-elapsed-time-descending",
  AVERAGE_ELAPSED_TIME_ASCENDING: "average-elapsed-time-ascending",
  CREATED_AT_DESCENDING: "created-at-descending",
  CREATED_AT_ASCENDING: "created-at-ascending",
  NUM_USES_DESCENDING: "num-uses-descending",
  NUM_USES_ASCENDING: "num-uses-ascending",
  UNKNOWN_DESCENDING: "unknown-descending",
  UNKNOWN_ASCENDING: "unknown-ascending",
} satisfies Record<string, Types.SortFieldValue>;

export const SortParams = {
  [SortFields.ACCURACY_RATE_DESCENDING]: { sortMethod: "ACCURACY_RATE", isDescending: true },
  [SortFields.ACCURACY_RATE_ASCENDING]: { sortMethod: "ACCURACY_RATE", isDescending: false },
  [SortFields.AVERAGE_ELAPSED_TIME_DESCENDING]: { sortMethod: "AVERAGE_ELAPSED_TIME", isDescending: true },
  [SortFields.AVERAGE_ELAPSED_TIME_ASCENDING]: { sortMethod: "AVERAGE_ELAPSED_TIME", isDescending: false },
  [SortFields.CREATED_AT_DESCENDING]: { sortMethod: "CREATED_AT", isDescending: true },
  [SortFields.CREATED_AT_ASCENDING]: { sortMethod: "CREATED_AT", isDescending: false },
  [SortFields.NUM_USES_DESCENDING]: { sortMethod: "NUM_USES", isDescending: true },
  [SortFields.NUM_USES_ASCENDING]: { sortMethod: "NUM_USES", isDescending: false },
  [SortFields.UNKNOWN_DESCENDING]: { sortMethod: "UNKNOWN", isDescending: true },
  [SortFields.UNKNOWN_ASCENDING]: { sortMethod: "UNKNOWN", isDescending: false },
} satisfies Record<string, { isDescending: boolean; sortMethod: Graphql.SortMethod }>;

export const DefaultListQuestionsPager: Types.Pager = {
  page: 0,
  size: 20,
  offset: 0,
  sortFieldValue: SortFields.UNKNOWN_ASCENDING,
};

export const DefaultListQuestionPackagesPager: Types.Pager = {
  page: 0,
  size: 20,
  offset: 0,
  sortFieldValue: SortFields.UNKNOWN_ASCENDING,
};

export const QuestionTypeMap: Record<Types.SpotQuestion["__typename"], string> = {
  AlgorithmQuestion: "challenge",
  ProjectQuestion: "project",
  MultiChoicePackage: "quiz",
  SystemDesignQuestion: "systemdesign",
};

export const LiveCodingQuestionTypeMap: Record<Types.LiveCodingQuestion["__typename"], string> = {
  AlgorithmQuestion: "challenge",
  SystemDesignQuestion: "systemdesign",
  FreepadQuestion: "freepad",
};

export const questionVariantsWithoutFreepad: Types.QuestionSearchFilter["questionVariants"] = [
  "CHALLENGE",
  "CHALLENGE_ALGORITHM",
  "CHALLENGE_DATABASE",
  "CHALLENGE_CLASS",
  "QUIZ",
  "PROJECT",
  "PROJECT_FRONTEND",
  "PROJECT_BACKEND",
  "PROJECT_DATA_SCIENCE",
  "PROJECT_OTHER",
  "SYSTEM_DESIGN",
];
export const remoteQuestionVariants: Types.QuestionSearchFilter["questionVariants"] = [
  "CHALLENGE",
  "CHALLENGE_ALGORITHM",
  "CHALLENGE_DATABASE",
  "CHALLENGE_CLASS",
  "SYSTEM_DESIGN",
  "FREEPAD",
];

export const getVersion = (question: DeepReadonly<Types.Question>): string => {
  switch (question.__typename) {
    case "AlgorithmQuestion":
    case "MultiChoicePackage":
    case "ProjectQuestion":
    case "FreepadQuestion":
      return question.version;
    case "SystemDesignQuestion":
      return INITIAL_VERSION;
    default:
      throw new Error(`Unknown type: ${question satisfies never}`);
  }
};

export const SKILL_TAG_SIZE = 40;
