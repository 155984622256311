import { DeepReadonly } from "@hireroo/app-helper/types";
import { useSnapshot } from "valtio";

import { state } from "./State";
import * as Types from "./types";

const useSnapshotState = () => {
  return useSnapshot(state);
};
export const useInitialized = () => {
  const snapshot = useSnapshotState();
  return !!snapshot.interview;
};

export const useRefresh = (): Types.RefreshCallback => {
  const snapshot = useSnapshotState();
  return snapshot.refresh;
};

export const useInterview = (): DeepReadonly<Types.Interview> => {
  const snapshot = useSnapshotState();
  if (!snapshot.interview) {
    throw new Error("need to initialize");
  }

  return snapshot.interview;
};

export const useTags = () => {
  const snapshot = useSnapshotState();
  return snapshot.tags;
};
