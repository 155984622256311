import * as z from "zod";

const Evaluation = z.object({
  id: z.number(),
  isPassed: z.boolean().optional(),
  questionId: z.number(),
  questionVersion: z.string(),
});

const Evaluations = Evaluation.array();

const SubmissionResource = z.object({
  submissionId: z.number(),
  isCorrect: z.boolean().optional(),
  evaluations: Evaluations,
});

export type SubmissionResource = z.infer<typeof SubmissionResource>;

export const useQuizFreeTextEvaluateForm = () => {
  return z.record(SubmissionResource);
};

export type QuizFreeTextEvaluateForm = z.infer<ReturnType<typeof useQuizFreeTextEvaluateForm>>;
