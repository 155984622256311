import { QuestionsPackages } from "@hireroo/app-store/page/e/questions_packages";
import type { Widget } from "@hireroo/presentation";

export type GenerateQuestionPackageSearchAreaPropsArgs = {};

export const useGenerateProps = (_args: GenerateQuestionPackageSearchAreaPropsArgs): Widget.QuestionPackageSearchAreaProps => {
  const currentSearchFilter = QuestionsPackages.useCurrentSearchFilter();
  const initialized = QuestionsPackages.useInitialized();
  return {
    disabled: !initialized,
    defaultValues: {
      difficulties: currentSearchFilter.difficulties.slice(),
      textFilter: currentSearchFilter.textFilter,
    },
    onChange: fields => {
      QuestionsPackages.updateCurrentSearchFilter(fields);
    },
  };
};
