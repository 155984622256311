import type * as Types from "./types";

const omitUndefinedValue = (obj: Record<string, unknown>): Record<string, unknown> => {
  Object.entries(obj).forEach(([key, value]) => {
    if (value === undefined) {
      delete obj[key];
    }
  });
  return obj;
};

class ManageStore {
  private paneStateMap: Types.PaneStateMap = new Map();
  private contentStateMap: Types.ContentStateMap = new Map();
  private splitStateMap: Types.SplitStateMap = new Map();
  private splitEventEmitterMap: Types.SplitEventEmitterMap = new Map();

  public getSplitState = (splitId: string) => {
    const state = this.splitStateMap.get(splitId);
    if (!state) {
      throw new Error(`Not found split:${splitId}`);
    }
    return state;
  };

  public getSplitDirectionRequired = (splitId: string) => {
    const state = this.getSplitState(splitId);
    return state.direction;
  };

  public getSplitSize = (splitId: string): number => {
    const state = this.getSplitState(splitId);
    return state.direction === "HORIZONTAL" ? state.height : state.width;
  };

  public getPaneStateRequired = (paneId: Types.PaneId) => {
    const paneState = this.paneStateMap.get(paneId);
    if (!paneState) {
      const paneIds = JSON.stringify(Array.from(this.paneStateMap.keys()));
      throw new Error(`Not found PaneState paneId=${paneId}, paneIds=${paneIds}`);
    }
    return paneState;
  };

  public getContentStateRequired = (contentId: Types.ContentId) => {
    const contentState = this.contentStateMap.get(contentId);
    if (!contentState) {
      throw new Error(`Not found ContentState contentId=${contentId}`);
    }
    return contentState;
  };

  public getPaneStatesBySplitId = (splitId: Types.SplitId) => {
    const matched: Types.PaneState[] = [];
    const unmatched: Types.PaneState[] = [];
    for (const [key, value] of this.paneStateMap.entries()) {
      if (key.startsWith(`${splitId}/`)) {
        matched.push(value);
      } else {
        unmatched.push(value);
      }
    }
    return { matched, unmatched };
  };

  public getContentStatesBySplitId = (splitId: Types.SplitId) => {
    const matched: Types.ContentState[] = [];
    const unmatched: Types.ContentState[] = [];
    for (const [key, value] of this.contentStateMap.entries()) {
      if (key.startsWith(`${splitId}/`)) {
        matched.push(value);
      } else {
        unmatched.push(value);
      }
    }
    return { matched, unmatched };
  };

  public setContentSize = (contentId: Types.ContentId, size: Types.ContentState["size"]) => {
    const contentState = this.getContentStateRequired(contentId);
    contentState.setSize(size);
    this.setContentState(contentState.contentId, {
      size: size,
    });
  };

  public setContentState = (contentId: Types.ContentId, state: Partial<Types.ContentState>) => {
    const prev = this.contentStateMap.get(contentId);
    this.contentStateMap.set(contentId, {
      contentId,
      splitId: "",
      direction: "HORIZONTAL",
      size: {
        value: 0,
        unit: "px",
      },
      defaultSize: {
        value: 0,
        unit: "px",
      },
      onDragEnd: () => undefined,
      onDragStart: () => undefined,
      getBoundingClientRect: () => ({
        width: 0,
        height: 0,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      }),
      setSize: () => undefined,
      mode: "FLEXIBLE",
      freeze: false,
      ...prev,
      ...omitUndefinedValue(state),
    });
  };

  public setPaneState = (paneId: Types.PaneId, state: Partial<Types.PaneState>) => {
    const prev = this.paneStateMap.get(paneId);
    this.paneStateMap.set(paneId, {
      splitDirection: "HORIZONTAL",
      size: {
        value: 0,
        unit: "px",
      },
      prevContentId: "SplitId/Content/Prev",
      nextContentId: "SplitId/Content/Next",
      splitId: "",
      ...prev,
      ...omitUndefinedValue(state),
    });
  };

  public setSplitState = (splitId: Types.SplitId, state: Partial<Types.SplitState>) => {
    const prev = this.splitStateMap.get(splitId);
    const current = omitUndefinedValue(state) as Partial<Types.SplitState>;
    this.splitStateMap.set(splitId, {
      width: 0,
      height: 0,
      snapOffset: 5,
      direction: "HORIZONTAL",
      ...prev,
      ...current,
    });
  };

  public registerChangeEventHandler = (splitId: Types.SplitId, callback: Types.ChangeEventHandler) => {
    const prevEventEmitter = this.splitEventEmitterMap.get(splitId);
    const eventEmitter: Types.SplitEventEmitter = prevEventEmitter
      ? {
          ...prevEventEmitter,
          changeEventHandler: prevEventEmitter.changeEventHandler.concat(callback),
        }
      : {
          changeEventHandler: [callback],
        };
    this.splitEventEmitterMap.set(splitId, eventEmitter);
  };

  public unregisterChangeEventHandler = (splitId: Types.SplitId, callback: Types.ChangeEventHandler) => {
    const prevEventEmitter = this.splitEventEmitterMap.get(splitId);
    if (prevEventEmitter) {
      this.splitEventEmitterMap.set(splitId, {
        ...prevEventEmitter,
        changeEventHandler: prevEventEmitter.changeEventHandler.filter(value => value !== callback),
      });
    }
  };

  public emitChangeEvent = (splitId: Types.SplitId, event: Types.ChangeEvent) => {
    const eventEmitter = this.splitEventEmitterMap.get(splitId);
    if (eventEmitter) {
      eventEmitter.changeEventHandler.forEach(callback => {
        callback(event);
      });
    }
  };
}

export default ManageStore;
