import { ThemeProvider } from "@hireroo/ui-theme/theme-v2";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import React, { useCallback, useEffect, useReducer, useState } from "react";

import { Area, ComponentType, FlowElement, UserState } from "../../../features";
import { svgTransform } from "../../../helpers/flowChart";
import ScaleControlPaper from "../ScaleControlPaper/ScaleControlPaper";
import { initialState, reducer } from "./reducer";
import { StaticViewbox } from "./StaticViewbox";

export type StaticFlowChartProps = {
  elements: FlowElement[];
  componentType: ComponentType;
  aspectRatio?: number;
  width?: number | string;
  viewarea?: Area;
  padding?: number;
  disableZoom?: boolean;
  disableGrid?: boolean;
  simpleView?: boolean;
  viewbox?: Area;
  disableAutoResize?: boolean;
  matchedIds?: string[];
  authors?: UserState[];
};

const RootBox = styled(Box)(() => ({
  position: "relative",
}));

const MiniBarBox = styled(Box)`
  display: flex;
  align-items: center;
  position: absolute;
  right: 8px;
  bottom: 12px;
`;

const StaticFlowChart: React.FC<StaticFlowChartProps> = props => {
  const [ready, setReady] = useState<boolean>(false);
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleZoomIn = useCallback(() => {
    const newScale = (Math.round(state.scale * 10) + 1) / 10;
    dispatch({ type: "zoomViewbox", payload: { scale: newScale } });
  }, [state.scale]);

  const handleZoomOut = useCallback(() => {
    const newScale = (Math.round(state.scale * 10) - 1) / 10;
    dispatch({ type: "zoomViewbox", payload: { scale: newScale } });
  }, [state.scale]);

  const handlePanAndZoom = useCallback(
    (e: React.WheelEvent<SVGSVGElement>, svgElement: SVGSVGElement) => {
      const mousePoint = svgTransform(svgElement, { x: e.clientX, y: e.clientY });
      if (!mousePoint) return;

      const newScale = e.deltaY < 0 ? (Math.round(state.scale * 10) + 1) / 10 : (Math.round(state.scale * 10) - 1) / 10;

      if (e.ctrlKey || e.metaKey) {
        dispatch({ type: "zoomViewbox", payload: { scale: newScale, focus: mousePoint } });
      } else {
        dispatch({ type: "panViewbox", payload: { dx: e.deltaX, dy: e.deltaY } });
      }
    },
    [state.scale],
  );

  useEffect(() => {
    dispatch({
      type: "restoreElements",
      payload: {
        elements: props.elements,
        aspectRatio: props.aspectRatio ?? 0.75,
        padding: props.padding,
        viewarea: props.viewarea,
        disableAutoResize: props.disableAutoResize,
      },
    });
    setReady(true);
  }, [dispatch, props.elements, props.viewarea, props.aspectRatio, props.padding, props.disableAutoResize]);

  useEffect(() => {
    if (props.viewbox && !state.scaleChanged) {
      dispatch({
        type: "initializeViewbox",
        payload: props.viewbox,
      });
    }
    setReady(true);
  }, [props.viewbox, state.scaleChanged]);

  return (
    <RootBox width={props.width}>
      {ready && (
        <StaticViewbox
          viewbox={state.viewbox}
          viewarea={props.viewarea}
          elementIds={state.elementIds}
          componentType={props.componentType}
          elements={state.elements}
          onWheel={props.disableZoom ? undefined : handlePanAndZoom}
          disableGrid={props.disableGrid}
          simpleView={props.simpleView}
          matchedIds={props.matchedIds}
          authors={props.authors}
        />
      )}
      {!props.disableZoom && (
        <ThemeProvider defaultColorMode="LIGHT">
          <MiniBarBox>
            <ScaleControlPaper paperProps={{ elevation: 24 }} handleZoomIn={handleZoomIn} handleZoomOut={handleZoomOut} scale={state.scale} />
          </MiniBarBox>
        </ThemeProvider>
      )}
    </RootBox>
  );
};

StaticFlowChart.displayName = "StaticFlowChart";

export default StaticFlowChart;
