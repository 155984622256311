import firebase from "firebase/compat/app";

export type User = firebase.User;

export async function listenForRedirect() {
  return await firebase.auth().getRedirectResult();
}

export async function signInAnonymously(): Promise<firebase.auth.UserCredential> {
  return await firebase.auth().signInAnonymously();
}

// Please check at: https://firebase.google.com/docs/reference/js/firebase.auth.GoogleAuthProvider#setCustomParameters
export async function signInWithGoogle() {
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.addScope("profile");
  provider.addScope("email");
  return await firebase.auth().signInWithRedirect(provider);
}

export async function signInWithPassword(email: string, password: string) {
  return await firebase.auth().signInWithEmailAndPassword(email, password);
}

export async function signInWithSAML(providerId: string) {
  const provider = new firebase.auth.SAMLAuthProvider(providerId);
  return await firebase.auth().signInWithRedirect(provider);
}

export async function signUpWithPassword(email: string, password: string) {
  return await firebase.auth().createUserWithEmailAndPassword(email, password);
}

export async function sendEmailVerification(user: firebase.User, lang: string): Promise<void> {
  firebase.auth().languageCode = lang;
  return await user.sendEmailVerification();
}

export async function confirmPasswordReset(actionCode: string, password: string): Promise<void> {
  return await firebase.auth().confirmPasswordReset(actionCode, password);
}
