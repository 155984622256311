import type { LiveCoding } from "@hireroo/app-helper/hooks";
import { RemotesId } from "@hireroo/app-store/page/c/remotes_id";
import type { Pages } from "@hireroo/presentation";
import * as React from "react";

import CandidateFooterContainer from "../../../../widget/v2/c/CandidateFooter/Container";
import RemoteInterviewTutorialContainer from "../../../../widget/v2/shared/RemoteInterviewTutorial/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import { useAutoStartTutorial } from "./privateHelper";
// TODO: Create for candidate or move to shared
import { useGenerateLeftSidePanelProps } from "./useGenerateLeftSidePanelProps";
import { useGenerateMainContentProps } from "./useGenerateMainContentProps";
import { useGenerateRightSidePanelProps } from "./useGenerateRightSidePanelProps";
import RemoteInterviewCandidateHeaderContainer from "./widget/RemoteInterviewCandidateHeader/Container";

export type GenerateRemoteInterviewForCandidatePropsArgs = {
  remoteId: string;
  collaborativeAction: LiveCoding.CollaborativeAction;
  collaborativeState: LiveCoding.CollaborativeState;
};

export const useGenerateProps = (args: GenerateRemoteInterviewForCandidatePropsArgs): Pages.RemoteInterviewForCandidateProps => {
  const mainContentProps = useGenerateMainContentProps({
    collaborativeState: args.collaborativeState,
    collaborativeAction: args.collaborativeAction,
  });
  const activeSessionVariant = RemotesId.useActiveSessionRemoteQuestionVariant();
  const isEmptyQuestionVariant = RemotesId.useIsEmptyQuestionVariant();
  // const remoteCodingToolbarProps = useGenerateRemoteCodingToolbarProps({
  //   remoteId: args.remoteId,
  // });
  const leftSidePanelProps = useGenerateLeftSidePanelProps();
  const rightSidePanelProps = useGenerateRightSidePanelProps();
  useAutoStartTutorial(activeSessionVariant);
  const enableLeftSidePanel = React.useMemo((): boolean => {
    if (mainContentProps.tab.items.length === 0) {
      return false;
    }
    if (isEmptyQuestionVariant) {
      return false;
    }
    return true;
  }, [isEmptyQuestionVariant, mainContentProps.tab.items.length]);

  return {
    mode: activeSessionVariant === "EMPTY_CANVAS" || activeSessionVariant === "SYSTEM_DESIGN" ? "DRAWING" : "OTHER",
    layout: {
      NotificationBanner: null,
      Header: <RemoteInterviewCandidateHeaderContainer mode="LIVE_CODING" />,
      Footer: <CandidateFooterContainer />,
      Snackbar: <SnackbarContainer />,
      Tutorial: <RemoteInterviewTutorialContainer />,
    },
    // remoteCodingToolbar: remoteCodingToolbarProps,
    leftSidePanel: leftSidePanelProps,
    rightSidePanel: rightSidePanelProps,
    mainContent: mainContentProps,
    enableLeftSidePanel: enableLeftSidePanel,
  };
};
