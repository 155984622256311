import { ResultKeys } from "@hireroo/app-definition/interview";
import { ScreeningTestListForDetail } from "@hireroo/app-store/widget/e/ScreeningTestListForDetail";
import * as Graphql from "@hireroo/graphql/client/graphql-request";
import { updateQueryParamsByObject } from "@hireroo/router/api";

export const startSubscribeListParams = () => {
  return ScreeningTestListForDetail.subscribeListParams(listParams => {
    const result = typeof listParams.filters.result === "boolean" ? [listParams.filters.result ? ResultKeys.PASSED : ResultKeys.FAILED] : [];

    const sortValueMap: Record<Graphql.SpotsByCompanyIdSortMethod, string> = {
      [Graphql.SpotsByCompanyIdSortMethod.CreatedAt]: `${Graphql.SpotsByCompanyIdSortMethod.CreatedAt}-${listParams.isDescending}`,
      [Graphql.SpotsByCompanyIdSortMethod.TotalScore]: `${Graphql.SpotsByCompanyIdSortMethod.TotalScore}-${listParams.isDescending}`,
      [Graphql.SpotsByCompanyIdSortMethod.Rank]: `${Graphql.SpotsByCompanyIdSortMethod.Rank}-${listParams.isDescending}`,
      [Graphql.SpotsByCompanyIdSortMethod.DidStartAt]: `${Graphql.SpotsByCompanyIdSortMethod.DidStartAt}-${listParams.isDescending}`,
      [Graphql.SpotsByCompanyIdSortMethod.EvaluatedAt]: `${Graphql.SpotsByCompanyIdSortMethod.EvaluatedAt}-${listParams.isDescending}`,
      [Graphql.SpotsByCompanyIdSortMethod.Unknown]: "",
    };
    const sortValues = sortValueMap[listParams.sortMethod] ? [window.btoa(sortValueMap[listParams.sortMethod])] : [];
    const sameSortValues =
      listParams.sortMethod === ScreeningTestListForDetail.defaultState.listParams.sortMethod &&
      listParams.isDescending === ScreeningTestListForDetail.defaultState.listParams.isDescending;
    const sameResultAndDefault = listParams.filters.result === ScreeningTestListForDetail.defaultFilters.result;
    const sameMaxTotalScoreAndDefault = listParams.filters.maxTotalScore === ScreeningTestListForDetail.defaultFilters.maxTotalScore;
    const sameMinTotalScoreAndDefault = listParams.filters.minTotalScore === ScreeningTestListForDetail.defaultFilters.minTotalScore;
    /**
     * Do not update query parameters if they are different from the Default Value when the page is visited
     * (i.e., the user has not manipulated them).
     */
    updateQueryParamsByObject({
      [ScreeningTestListForDetail.QueryKeys.TAG]: listParams.filters.tagNames,
      [ScreeningTestListForDetail.QueryKeys.TITLE]: listParams.filters.name.length > 0 ? [listParams.filters.name] : [],
      [ScreeningTestListForDetail.QueryKeys.STATUS]: listParams.filters.statuses,
      [ScreeningTestListForDetail.QueryKeys.RESULT]: !sameResultAndDefault ? result : [],
      [ScreeningTestListForDetail.QueryKeys.RANK_EVALUATION]: listParams.filters.rankEvaluations,
      [ScreeningTestListForDetail.QueryKeys.MIN_SCORE]:
        typeof listParams.filters.minTotalScore === "number" && !sameMinTotalScoreAndDefault
          ? [listParams.filters.minTotalScore.toFixed(2)]
          : [],
      [ScreeningTestListForDetail.QueryKeys.MAX_SCORE]:
        typeof listParams.filters.maxTotalScore === "number" && !sameMaxTotalScoreAndDefault
          ? [listParams.filters.maxTotalScore.toFixed(2)]
          : [],
      [ScreeningTestListForDetail.QueryKeys.SORT]: !sameSortValues ? sortValues : [],
      /**
       * Set page number to "1" if it is not a page change
       */
      [ScreeningTestListForDetail.QueryKeys.PAGE]: listParams.page > 0 ? [(listParams.page + 1).toString()] : [],
      [ScreeningTestListForDetail.QueryKeys.SIZE]:
        listParams.size !== ScreeningTestListForDetail.defaultState.listParams.size ? [listParams.size.toString()] : [],
      [ScreeningTestListForDetail.QueryKeys.SUSPICIOUS_DEGREES]: listParams.filters.suspiciousDegrees,
    });
  });
};
