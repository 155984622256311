import { proxy } from "valtio";

import type { State } from "./types";

export const state = proxy<State>({
  template: {
    c: "",
    cpp: "",
    csharp: "",
    dart: "",
    go: "",
    java: "",
    javascript: "",
    kotlin: "",
    mysql: "",
    perl: "",
    pgsql: "",
    php: "",
    python3: "",
    ruby: "",
    rust: "",
    scala: "",
    sqlite: "",
    swift: "",
    typescript: "",
  },
});
