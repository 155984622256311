import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";

import EmployeeLayout, { EmployeeLayoutProps } from "../../layouts/EmployeeLayout/EmployeeLayout";

const StyledEmployeeLayout = styled(EmployeeLayout)`
  overflow: hidden;
`;

const StyledBox = styled(Box)`
  height: calc(100vh - 72px);
  overflow: hidden;
`;

export type RemoteInterviewResourceEditorProps = {
  layout: EmployeeLayoutProps;
  children: React.ReactNode;
};

const RemoteInterviewResourceEditor: React.FC<RemoteInterviewResourceEditorProps> = props => {
  const layoutProps: EmployeeLayoutProps = {
    ...props.layout,
    headerPosition: "static",
  };
  return (
    <StyledEmployeeLayout sx={{ height: "100vh" }} {...layoutProps}>
      <StyledBox sx={{ height: "calc(100vh - 48px)" }}>{props.children}</StyledBox>
    </StyledEmployeeLayout>
  );
};

RemoteInterviewResourceEditor.displayName = "RemoteInterviewResourceEditor";

export default RemoteInterviewResourceEditor;
