import { ChallengePlayback } from "@hireroo/app-store/widget/shared/ChallengePlayback";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import React from "react";

export type GenerateChallengePlaybackSettingsMenuPropsArgs = {
  canShowPasteAndTabStatistics: boolean;
};

export const useGenerateProps = (args: GenerateChallengePlaybackSettingsMenuPropsArgs): Widget.ChallengePlaybackSettingsMenuProps => {
  const { canShowPasteAndTabStatistics } = args;
  const { t } = useTranslation();
  const hasPasteEvent = ChallengePlayback.useHasPasteEvent();
  const hasBehavioralEvent = ChallengePlayback.useHasBehavioralEvent();
  const isSessionIssued = ChallengePlayback.useIsSessionIssued();
  const playbackSettings = ChallengePlayback.usePlaybackSettings();

  const challengePlaybackSettingsMenuProps: Widget.ChallengePlaybackSettingsMenuProps = React.useMemo(
    () => ({
      defaultValues: playbackSettings,
      items: {
        copyAndPasteDetection: canShowPasteAndTabStatistics
          ? {
              disabled: !hasPasteEvent,
              disabledTooltipText: t("ペーストイベントが検出されなかったため無効化されています。"),
            }
          : undefined,
        behavioralControl: isSessionIssued
          ? {
              disabled: !hasBehavioralEvent,
              disabledTooltipText: t("Google検索やChatGPTの利用履歴がないため無効化されています。"),
            }
          : undefined,
      },
      onSubmit: fields => {
        ChallengePlayback.updatePlaybackSettings({ ...fields });
      },
    }),
    [playbackSettings, canShowPasteAndTabStatistics, hasPasteEvent, hasBehavioralEvent, isSessionIssued, t],
  );

  return challengePlaybackSettingsMenuProps;
};
