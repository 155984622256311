import { useTranslation } from "@hireroo/i18n";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import SettingsOutlined from "@mui/icons-material/SettingsOutlined";
import Box from "@mui/material/Box";
import Button, { type ButtonProps } from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

import EditContractDialog, { EditContractDialogProps } from "./parts/EditContractDialog/EditContractDialog";

const Wrapper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  minWidth: 296,
  width: "100%",
}));

const TextWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
}));

const HeaderWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
}));

const ActionWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
}));

const EditButton = styled(Button)(({ theme }) => ({
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  fontWeight: "bold",
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.action.selected,
  textTransform: "none",
}));

const UpdateFrequencyButton = styled(Button)(() => ({
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  textTransform: "none",
  ".MuiButton-startIcon": {
    marginRight: 4,
  },
  ".MuiButtonBase-root": {
    userSelect: "text",
    cursor: "default",
  },
  "&.MuiButton-root": {
    userSelect: "text",
    "&:hover": {
      userSelect: "text",
      cursor: "auto",
    },
  },
}));

const Body = styled(Typography)(() => ({
  fontSize: 16,
}));

const TrialLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 14,
  fontWeight: 400,
}));

export type ContractProps = {
  showAutoRenewalLabel: boolean;
  editButton: Pick<ButtonProps, "disabled">;
  contractPeriodLabel:
    | {
        kind: "TEXT";
        value: string;
      }
    | {
        kind: "TRIAL";
      };
  editContractDialog: Pick<EditContractDialogProps, "defaultValues" | "onSubmit">;
};

const Contract: React.FC<ContractProps> = props => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const updateFrequencyButton: ButtonProps = {
    startIcon: <CachedOutlinedIcon />,
    children: t("自動更新"),
    variant: "text",
    color: "info",
    disableRipple: true,
  };

  const editButton: ButtonProps = {
    ...props.editButton,
    startIcon: <SettingsOutlined />,
    children: t("管理"),
    variant: "text",
    onClick: () => {
      setOpen(true);
    },
  };

  const editContractDialog: EditContractDialogProps = {
    ...props.editContractDialog,
    open,
    onClose: () => {
      setOpen(false);
    },
  };

  return (
    <Wrapper elevation={4}>
      <HeaderWrapper mb={1.5}>
        <TextWrapper>
          <Typography variant="subtitle2" color="text.secondary">
            {t("契約期間")}
          </Typography>
          {props.showAutoRenewalLabel && <UpdateFrequencyButton {...updateFrequencyButton} />}
          <EditContractDialog {...editContractDialog} />
        </TextWrapper>
        <ActionWrapper>
          <Tooltip title={editButton.disabled ? t("正式契約後、利用可能になります。") : ""}>
            <span>
              <EditButton {...editButton} />
            </span>
          </Tooltip>
        </ActionWrapper>
      </HeaderWrapper>
      <Box>
        {props.contractPeriodLabel.kind === "TEXT" && <Body variant="subtitle2">{props.contractPeriodLabel.value}</Body>}
        {props.contractPeriodLabel.kind === "TRIAL" && <TrialLabel variant="subtitle2">{t("無料トライアル期間中")}</TrialLabel>}
      </Box>
    </Wrapper>
  );
};

Contract.displayName = "Contract";

export default Contract;
