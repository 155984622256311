import { subscribeKey } from "valtio/utils";

import { state } from "./State";

export type SubscribeAnswerTextCallback = (quizId: number, questionId: number, answerText: string) => void;
export const subscribeAnswerText = (callback: SubscribeAnswerTextCallback) => {
  return subscribeKey(state, "answerTextMap", answerTextMap => {
    const answerText = answerTextMap.get(state.questionId || 0);
    if (answerText !== undefined && state.quizId !== null && state.questionId !== null) {
      callback(state.quizId, state.questionId, answerText);
    }
  });
};
