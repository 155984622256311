import { encodeFirebaseKey } from "./rdbKey";

export type GenerateFileContentHistoryStoredPathArgs = {
  filepath: string;
  fileId: string;
};

export const HISTORIES_FILES = "/histories/files" as const;

export type FileContentHistoryStoredPath = `/histories/files/${string}`;

export const generateFileContentHistoryStoredPath = (args: GenerateFileContentHistoryStoredPathArgs): FileContentHistoryStoredPath => {
  const key = encodeFirebaseKey(`${args.filepath}:${args.fileId}`);
  return `${HISTORIES_FILES}/${key}`;
};

export type GeneratePathHistoryStoredPathArgs = {
  filepath: string;
};

export const HISTORIES_PATHS = "/histories/paths" as const;

export type PathHistoryStoredPath = `/histories/paths/${string}`;

export const generatePathHistoryStoredPath = (args: GeneratePathHistoryStoredPathArgs): PathHistoryStoredPath => {
  return `${HISTORIES_PATHS}/${args.filepath}`;
};

export const INDEX_MODEL_PATH = "/current/indexes" as const;
