import { WebSiteSearchEvent } from "@hireroo/app-helper/playback";
import { ChallengePlayback } from "@hireroo/app-store/widget/shared/ChallengePlayback";
import * as Time from "@hireroo/formatter/time";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

type ActiveRange = Widget.ActivityTimeBarChartPanelProps["timeTrackerReport"]["activeRanges"][0];

export type GenerateSearchHistoryPanelPropsArgs = {};

export const useGenerateProps = (_args: GenerateSearchHistoryPanelPropsArgs): Widget.ActivityTimeBarChartPanelProps => {
  const playbackManager = ChallengePlayback.usePlaybackManager();
  const { t } = useTranslation();

  const startTs = React.useMemo(() => {
    return playbackManager.timeStamps.at(0) ?? 0;
  }, [playbackManager]);

  const searchEvents = React.useMemo(() => {
    return playbackManager.ticks.reduce<WebSiteSearchEvent[]>((all, tick) => {
      const events = tick.events.filter(event => event.kind === "WEB_SITE_SEARCH") as WebSiteSearchEvent[];
      return [...all, ...events];
    }, []);
  }, [playbackManager]);

  const activeRanges = React.useMemo((): ActiveRange[] => {
    const endTs = playbackManager.timeStamps.at(-1) ?? 0;
    const width = endTs - startTs;

    return searchEvents.map((searchEvent): ActiveRange => {
      const leftPos = searchEvent.ts - startTs;
      return {
        start: `${Math.floor((leftPos / width) * 100)}%`,
        width: `0%`,
        display: searchEvent.query,
      };
    });
  }, [startTs, playbackManager, searchEvents]);

  const timeLabels = React.useMemo((): string[] => {
    const endTime = playbackManager.timeStamps.at(-1) ?? 0;
    const startTime = playbackManager.timeStamps.at(0) ?? 0;
    const list = ["00:00", Time.elapsedTimeFormat(endTime - startTime)];
    return Array.from(new Set(list));
  }, [playbackManager]);

  return {
    title: t("検索クエリ"),
    timeFieldLabel: t("検索時刻"),
    timeTrackerReport: {
      activeRanges: activeRanges,
      timeLabels: timeLabels,
    },
    items: searchEvents.map(event => {
      return {
        name: event.query,
        description: Time.elapsedTimeFormat(event.ts - startTs),
        onClick: () => {
          const index = playbackManager.getIndexByTimeStamp(event.ts);
          if (index) {
            ChallengePlayback.updateSliderValue(index);
          }
        },
      };
    }),
  };
};
