import * as React from "react";

import Layout, { LayoutProps } from "./parts/Layout/Layout";
import RemoteSetupSection, { RemoteSetupSectionProps } from "./parts/RemoteSetupSection/RemoteSetupSection";

export {
  RemoteTemplateResourceEditorProvider,
  type RemoteTemplateResourceEditorProviderProps,
  useRemoteTemplateResourceEditorContext,
} from "./Context";

export type RemoteTemplateResourceEditorProps = {
  layout: Omit<LayoutProps, "mode" | "children">;
  mode: LayoutProps["mode"];
  remoteSetupSection: Omit<RemoteSetupSectionProps, "mode">;
};

const RemoteTemplateResourceEditor: React.FC<RemoteTemplateResourceEditorProps> = props => {
  return (
    <Layout {...props.layout} mode={props.mode}>
      <RemoteSetupSection {...props.remoteSetupSection} mode={props.mode} />
    </Layout>
  );
};

RemoteTemplateResourceEditor.displayName = "RemoteTemplateResourceEditor";

export default RemoteTemplateResourceEditor;
