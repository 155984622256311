import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { useQuestionSearchAndSelectableAreaContext } from "../../Context";

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
  height: "40px",
  flexShrink: 0,
}));

type PrefixLabel = "ADDABLE" | "SELECTABLE_QUESTIONS_FOR_TEST_TAKERS" | "RANDOMLY_SELECTED_QUESTIONS";

export type SortableQuestionListHeaderProps = {
  prefixLabel: PrefixLabel;
};

const SortableQuestionListHeader: React.FC<SortableQuestionListHeaderProps> = props => {
  const { t } = useTranslation();
  const { selectedEntitySources: fieldValue, maxAddableQuestionCount } = useQuestionSearchAndSelectableAreaContext();

  const prefixLabelMap: Record<PrefixLabel, string> = {
    ADDABLE: t("追加可能"),
    SELECTABLE_QUESTIONS_FOR_TEST_TAKERS: [t("選択済"), t("受験者が選択できる問題")].join(": "),
    RANDOMLY_SELECTED_QUESTIONS: [t("選択済"), t("ランダムに出題される問題")].join(": "),
  };
  return (
    <Wrapper>
      <Box sx={{ width: "100%", flexGrow: 1 }}>
        <Typography fontWeight={700} fontSize={16}>
          {prefixLabelMap[props.prefixLabel] + ` (${fieldValue.length}/${maxAddableQuestionCount})`}
        </Typography>
      </Box>
    </Wrapper>
  );
};

SortableQuestionListHeader.displayName = "SortableQuestionListHeader";

export default SortableQuestionListHeader;
