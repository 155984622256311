import firebase from "firebase/compat/app";

/**
 * @see https://firebase.google.com/docs/reference/js/v8/firebase.database.ServerValue
 *
 * A placeholder value for auto-populating the current timestamp (time since the Unix epoch, in milliseconds) as determined by the Firebase servers.
 *
 * TypeScript type definition is `Object`, but the actual value to be entered is `number`, so it should be cast.
 */
export const getTimestamp = (): number => {
  return firebase.database.ServerValue.TIMESTAMP as number;
};

export type TimeStamp = ReturnType<typeof getTimestamp>;
