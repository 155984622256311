import { App, Auth, Company } from "@hireroo/app-store/essential/employee";
import { EmployeeNavigation } from "@hireroo/app-store/widget/e/EmployeeNavigation";
import { formatTimeDistance } from "@hireroo/formatter/time";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import { useTransitionNavigateWithRawUri } from "@hireroo/router/hooks";
import React from "react";

export type GenerateNotificationListPropsArgs = {};

export const useGenerateProps = (_args: GenerateNotificationListPropsArgs): Widget.NotificationListProps => {
  const [now] = React.useState(new Date());
  const appStatus = App.useStatus();
  const companyId = Company.useStrictActiveCompanyId();
  const employeeNotifications = EmployeeNavigation.useUnreadEmployeeNotifications();
  const hasNext = EmployeeNavigation.useHasNext();
  const employeeId = Auth.useCurrentUid();
  const lang = useLanguageCode();
  const client = getGraphqlClient();
  const navigate = useTransitionNavigateWithRawUri();
  const count = EmployeeNavigation.useUnreadNotificationCount();
  const nextCursor = EmployeeNavigation.useNextCursor();
  const fetchStatus = EmployeeNavigation.useFetchStatus();

  const [result] = Graphql.useListEmployeeNotificationsQuery({
    variables: {
      companyId: companyId,
      employeeId: employeeId,
      /**
       * When there are more than 11 Notification Count Symbol, it becomes `10+` and does not count any more.
       *
       * In other words, if 11 messages can be retrieved, the user can recognize that there are more than 11 messages.
       */
      size: 11,
      cursor: nextCursor ?? null,
      withCount: true,
    },
    requestPolicy: "cache-and-network",
    pause: appStatus !== "INITIALIZED" || fetchStatus === "STOP",
  });

  React.useEffect(() => {
    if (result.data?.employeeNotifications) {
      EmployeeNavigation.append(result.data?.employeeNotifications);
    }
  }, [result.data?.employeeNotifications]);

  return {
    count,
    items: employeeNotifications.map(employeeNotification => {
      const distance = employeeNotification.createdAtSeconds
        ? formatTimeDistance(new Date(employeeNotification.createdAtSeconds * 1000), now, lang)
        : "";
      return {
        id: employeeNotification.notificationId.toString(),
        title: resolveLanguage(employeeNotification, lang, "title"),
        distance: distance,
        onClick: async () => {
          await client
            .ReadEmployeeNotifications({
              companyId: employeeNotification.companyId,
              employeeId: employeeNotification.employeeId,
              employeeNotificationIds: [employeeNotification.notificationId],
            })
            .then(() => {
              EmployeeNavigation.read(employeeNotification.notificationId);
              // Parse the path from the action link, excluding the domain
              const url = new URL(employeeNotification.actionLink);
              navigate(url.pathname);
            });
        },
      };
    }),
    hasNext: hasNext,
    allReadButton: {
      onClick: () => {
        client
          .ReadEmployeeNotifications({
            companyId: companyId,
            employeeId: employeeId,
            employeeNotificationIds: employeeNotifications.map(e => e.notificationId),
          })
          .then(res => {
            const readNotificationIds = res.readEmployeeNotifications.map(notification => notification.notificationId);
            EmployeeNavigation.readAll(readNotificationIds);
          });
      },
    },
    onEndReached: () => {
      EmployeeNavigation.goNextPage();
    },
  };
};
