import { Company } from "@hireroo/app-store/essential/employee";
import { Widget } from "@hireroo/presentation/legacy";
import { withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type EmployeeNavigationContainerProps = {};

const EmployeeNavigationContainer: React.FC<EmployeeNavigationContainerProps> = () => {
  const props = useGenerateProps({
    companyId: Company.useActiveCompanyId() || 0,
  });
  return <Widget.EmployeeNavigation {...props} />;
};

EmployeeNavigationContainer.displayName = "EmployeeNavigationContainer";

export default withErrorBoundary(React.memo(EmployeeNavigationContainer), {});
