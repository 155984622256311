import * as Graphql from "@hireroo/graphql/client/urql";

import { defaultState } from "./constants";
import * as Helper from "./helper";
import { state } from "./State";
import type * as Types from "./types";

export type QueryParams = {
  size: string | null;
  page: string | null;
  tagNames: string[];
  name: string | null;
  result: string | null;
  createdByList: string[];
  statuses: string[];
  isDescending: string | null;
  sortMethod: string | null;
};

export type InitializeParams = {
  creators: Types.Creator[];
  remoteTags: Types.Tags;
  queryParams: QueryParams;
};

export const initialize = (params: InitializeParams): void => {
  const { queryParams } = params;
  state.creators = params.creators;
  state.remoteTags = params.remoteTags;
  state.listParams = {
    isDescending: Helper.convertStringToIsDesc(queryParams.isDescending) ?? true,
    sortMethod: Helper.convertStringToSortMethod(queryParams.sortMethod),
    page: Helper.convertStringsToPage(queryParams.page),
    size: Helper.convertStringToSize(queryParams.size),
    filters: {
      tagNames: Helper.convertStringsToTagNames(queryParams.tagNames),
      name: queryParams.name ?? "",
      result: Helper.convertStringToResult(queryParams.result),
      createdByList: Helper.convertStringsToUids(queryParams.createdByList),
      statuses: Helper.convertStringsToStatuses(queryParams.statuses),
    },
  };
  state.initialized = true;
};

export const updateSize = (newValue: number): void => {
  state.listParams = {
    ...state.listParams,
    size: newValue,
  };
  state.pager = {
    ...state.pager,
    page: 0,
    offset: 0,
    size: newValue,
  };
};

export const updatePage = (newValue: number): void => {
  state.listParams = {
    ...state.listParams,
    page: newValue,
  };
  state.pager = {
    ...state.pager,
    page: newValue,
    offset: newValue * state.pager.size,
  };
};

export const initializeFilters = (filter: Types.SearchFilter, pager: Omit<Types.Pager, "offset">) => {
  state.currentSearchFilter = filter;
  state.pager = {
    ...pager,
    offset: pager.page * pager.size,
  };
};

export const updateCurrentSearchFilter = (filter: Types.SearchFilter) => {
  state.currentSearchFilter = filter;
  /** Reset page when search criteria are changed */
  state.pager = {
    ...state.pager,
    page: 0,
    offset: 0,
  };
};

export const setRes = (res: Types.Res) => {
  state.res = res;
};

export const setRemoteTags = (tags: Types.Tags) => {
  state.remoteTags = tags;
};

export const clear = () => {
  state.res = null;
  state.currentSearchFilter = null;
};

export const clampPage = (): void => {
  if (state.count <= state.listParams.page * state.listParams.size) {
    // (e.g.) count=60, size=20 => the maximum page number is 2
    // (e.g.) count=57, size=20 => the maximum page number is 2
    // N.B. Page number starts with 0. (https://mui.com/material-ui/api/table-pagination/)
    // page should be gte 1, so take max compared to 1
    updatePage(Math.max(1, Math.ceil(state.count / state.listParams.size)) - 1);
  }
};

export const updateIsDescending = (newValue: boolean): void => {
  state.listParams = {
    ...state.listParams,
    isDescending: newValue,
  };
};

export const updateSortMethod = (newValue: Graphql.RemoteSortMethod): void => {
  state.listParams = {
    ...state.listParams,
    sortMethod: newValue,
  };
};

export const updateSortField = (sortFieldValue: Types.SortFieldValue) => {
  state.pager = {
    ...state.pager,
    sortFieldValue: sortFieldValue,
    page: 0,
    offset: 0,
  };
};

export const updateFilters = (newFilters: Partial<Types.Filters>): void => {
  state.listParams = {
    ...state.listParams,
    filters: {
      ...state.listParams.filters,
      ...newFilters,
    },
  };
};

export const updateSearchTags = (newSearchTags: string[]): void => {
  state.searchTags = newSearchTags;
};

export const updateCount = (newValue: number) => {
  state.count = newValue;
};

export const updateCreators = (newValue: Types.Creator[]) => {
  state.creators = newValue;
};

export const resetFilters = () => {
  state.listParams = {
    ...defaultState.listParams,
  };
  state.searchTags = [];
};

export const updateLoadingStatus = (status: Types.LoadingStatus) => {
  state.loadingStatus = status;
};
