import { useTranslation } from "@hireroo/i18n";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledStack = styled(Stack)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingRight: theme.spacing(1.5),
  paddingLeft: theme.spacing(1.5),
  backgroundColor: theme.palette.background.paper,
  borderRadius: "8px",
}));

const StyledTypography = styled(Typography)(() => ({
  fontSize: "14px",
}));

const DescriptionTypography = styled(Typography)(() => ({
  fontSize: "14px",
  whiteSpace: "pre-wrap",
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  height: 20,
  marginLeft: 8,
  position: "relative",
  top: -1,
  ".MuiChip-label": {
    fontSize: 10,
    lineHeight: 1.2,
    padding: "1px 4px",
  },
  "&.MuiChip-colorDefault": {
    color: theme.palette.text.secondary,
  },
}));

type Kind = "OPTIONAL" | "REQUIRED";

export type ReadonlyCustomFormVariableProps = {
  label: string;
  description: string;
  formType: string;
  kind: Kind;
  validationRule: {
    min?: string;
    max?: string;
  };
};

const ReadonlyCustomFormVariable: React.FC<ReadonlyCustomFormVariableProps> = props => {
  const { t } = useTranslation();
  const ChipMap: Record<Kind, React.ReactNode> = {
    REQUIRED: <StyledChip label={t("必須")} color="primary" size="small" />,
    OPTIONAL: <StyledChip label={t("任意")} color="default" size="small" />,
  };

  return (
    <StyledStack direction="column" spacing={0.5}>
      <Stack direction="row" spacing={0.5} alignItems="center">
        <StyledTypography fontWeight={400}>{props.label}</StyledTypography>
        {ChipMap[props.kind]}
      </Stack>
      <DescriptionTypography color="textSecondary">{props.description}</DescriptionTypography>
      <Stack direction="row" spacing={0.5}>
        <StyledTypography color="textSecondary">{props.formType}</StyledTypography>
        {props.validationRule.min && <StyledTypography color="textSecondary">{props.validationRule.min}</StyledTypography>}
        {props.validationRule.max !== undefined && props.validationRule.min !== undefined && (
          <StyledTypography color="textSecondary">~</StyledTypography>
        )}
        {props.validationRule.max && <StyledTypography color="textSecondary">{props.validationRule.max}</StyledTypography>}
      </Stack>
    </StyledStack>
  );
};

ReadonlyCustomFormVariable.displayName = "ReadonlyCustomFormVariable";

export default ReadonlyCustomFormVariable;
