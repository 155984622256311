import { useTranslation } from "@hireroo/i18n";
import { ScreeningInviteeSearchForm as ScreeningInviteeSearchFormValidator } from "@hireroo/validator";

import type { SearchFormProps } from "../../../modules/SearchForm/SearchForm";

type FieldProps = SearchFormProps["fields"][0];
export type MultiChoiceFieldProps = Extract<
  FieldProps,
  {
    kind: "MULTI_CHOICE";
  }
>;
export type SingleChoiceFieldProps = Extract<
  FieldProps,
  {
    kind: "SINGLE_CHOICE";
  }
>;
export type GroupMultiChoiceFieldProps = Extract<
  FieldProps,
  {
    kind: "GROUP_MULTI_CHOICE";
  }
>;
export type RangeFieldProps = Extract<
  FieldProps,
  {
    kind: "RANGE";
  }
>;
export type TagFieldProps = Extract<
  FieldProps,
  {
    kind: "TAG";
  }
>;

type FieldKeyName = keyof ScreeningInviteeSearchFormValidator.InviteeSearchQuerySchema;

const FieldName = {
  TEXT_FILTER: "textFilter",
  STATUSES: "statuses",
} satisfies Record<string, FieldKeyName>;

type StatusesOption = {
  displayName: string;
  value: ScreeningInviteeSearchFormValidator.InviteeSearchQuerySchema["statuses"][0];
};

export const useStatusesField = (): MultiChoiceFieldProps => {
  const { t } = useTranslation();
  const options: StatusesOption[] = [
    { value: "INVITED", displayName: t("招待済み") },
    { value: "ACCEPTED", displayName: t("テストリンク受領済み") },
  ];
  return {
    kind: "MULTI_CHOICE",
    title: t("ステータス"),
    name: FieldName.STATUSES,
    options: options,
  };
};

export const useTextFilterField = (): SearchFormProps["textFilter"] => {
  const { t } = useTranslation();
  return {
    name: FieldName.TEXT_FILTER,
    placeholder: t("招待メールアドレスを検索"),
  };
};
