import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateEmployeeGroupSettingsPropsArgs, useGenerateProps } from "./useGenerateProps";

export type EmployeeGroupSettingsContainerProps = GenerateEmployeeGroupSettingsPropsArgs;

const EmployeeGroupSettingsContainer: React.FC<EmployeeGroupSettingsContainerProps> = props => {
  const employeeGroupSettingsProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.GroupSettings {...employeeGroupSettingsProps} />
    </ErrorBoundary>
  );
};

EmployeeGroupSettingsContainer.displayName = "EmployeeGroupSettingsContainer";

export default withErrorBoundary(EmployeeGroupSettingsContainer, {});
