import { FreepadAnswerForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import * as React from "react";
import { useForm, UseFormReturn } from "react-hook-form";

type Variant = "ALGORITHM" | "SYSTEM_DESIGN";
export type ContextValue = {
  methods: UseFormReturn<FreepadAnswerForm.FreepadAnswersFormSchema>;
  selectedAnswerIndex: string;
  updateSelectedAnswerIndex: (value: string) => void;
  variant: Variant;
};

export const FreepadAnswerFormContext = React.createContext<ContextValue>({} as ContextValue);

export const useFreepadAnswerFormContext = () => React.useContext(FreepadAnswerFormContext);

export type FreepadAnswerFormProviderProps = {
  defaultValues: FreepadAnswerForm.FreepadAnswersFormSchema;
  variant: Variant;
};

const defaultIndex = "0";

export const FreepadAnswerFormProvider: React.FC<React.PropsWithChildren<FreepadAnswerFormProviderProps>> = props => {
  const { defaultValues, variant } = props;
  const validateSchema = FreepadAnswerForm.useAnswersFormSchema();
  const methods = useForm<FreepadAnswerForm.FreepadAnswersFormSchema>({
    resolver: zodResolver(validateSchema),
    mode: "onSubmit",
    shouldUnregister: false,
    defaultValues,
  });
  const [selectedAnswer, updateSelectedAnswer] = React.useState<string>(defaultIndex);

  React.useEffect(() => {
    methods.reset(defaultValues);
  }, [methods, defaultValues]);

  const contextValue: ContextValue = {
    methods: methods,
    selectedAnswerIndex: selectedAnswer,
    updateSelectedAnswerIndex: React.useCallback(value => {
      updateSelectedAnswer(value);
    }, []),
    variant: variant,
  };
  return <FreepadAnswerFormContext.Provider value={contextValue} children={props.children} />;
};
