import { ScreeningInviteeList } from "@hireroo/app-store/widget/e/ScreeningInviteeList";
import { updateQueryParamsByObject } from "@hireroo/router/api";

export const startSubscribeListParams = () => {
  return ScreeningInviteeList.subscribeListParams(listParams => {
    const sortValues = [window.btoa(`CREATED_AT-${listParams.isDescending}`)];
    const sameSortValues = listParams.isDescending === ScreeningInviteeList.defaultState.listParams.isDescending;
    /**
     * Do not update query parameters if they are different from the Default Value when the page is visited
     * (i.e., the user has not manipulated them).
     */
    updateQueryParamsByObject({
      [ScreeningInviteeList.QueryKeys.EMAIL]: listParams.filters.email ? [listParams.filters.email] : [],
      [ScreeningInviteeList.QueryKeys.STATUS]: listParams.filters.statuses,
      [ScreeningInviteeList.QueryKeys.SORT]: !sameSortValues ? sortValues : [],
      /**
       * Set page number to "1" if it is not a page change
       */
      [ScreeningInviteeList.QueryKeys.PAGE]: listParams.page > 0 ? [(listParams.page + 1).toString()] : [],
      [ScreeningInviteeList.QueryKeys.SIZE]:
        listParams.size !== ScreeningInviteeList.defaultState.listParams.size ? [listParams.size.toString()] : [],
    });
  });
};
