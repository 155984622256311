import { App, Company, Payment } from "@hireroo/app-store/essential/employee";
import { PaymentContractCreateForm } from "@hireroo/app-store/widget/e/PaymentContractCreateForm";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import PaymentContractCreateFormContainer, { PaymentContractCreateFormContainerProps } from "./Container";

export type PaymentContractCreateFormFetchContainerProps = {};

const PaymentContractCreateFormFetchContainer: React.FC<PaymentContractCreateFormFetchContainerProps> = _props => {
  const appStatus = App.useStatus();
  const companyId = Company.useStrictActiveCompanyId();
  const initialized = PaymentContractCreateForm.useInitialized();
  const essentialCustomer = Payment.useCustomerV2();
  const [result, refresh] = Graphql.useGetPlansForPaymentContractCreateFormQuery({
    variables: {
      customerId: companyId,
      planGeneration: essentialCustomer.subscription.plan.generation,
    },
    pause: appStatus !== "INITIALIZED" && essentialCustomer.isTrial,
  });

  React.useEffect(() => {
    if (result.data) {
      PaymentContractCreateForm.initialize(
        result.data.plans,
        result.data.customer,
        result.data.buyAblePacks.interviews,
        result.data.paymentInitialCost,
      );
    }
  }, [result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: PaymentContractCreateFormContainerProps = {};

  return <PaymentContractCreateFormContainer {...containerProps} />;
};

PaymentContractCreateFormFetchContainer.displayName = "PaymentContractCreateFormFetchContainer";

export default withErrorBoundary(PaymentContractCreateFormFetchContainer, {});
