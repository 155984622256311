import { subscribe } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

export type SubscribeSelectedEmployeesCallback = (employeeIds: Types.State["selectedEmployeeIds"]) => void;

export const subscribeSelectedEmployees = (callback: SubscribeSelectedEmployeesCallback) => {
  return subscribe(state, () => callback(state.selectedEmployeeIds));
};

export type SubscribeSelectedGroupsCallback = (groups: Types.State["selectedGroupIds"]) => void;

export const subscribeSelectedGroups = (callback: SubscribeSelectedGroupsCallback) => {
  return subscribe(state, () => callback(state.selectedGroupIds));
};
