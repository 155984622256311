import { useTranslation } from "@hireroo/i18n";
import { ErrorMessage } from "@hookform/error-message";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider } from "react-hook-form";

import { useAlgorithmHintsFormContext } from "./Context";
import HintHeader, { HintHeaderProps } from "./parts/HintHeader";
import HintTabs, { HintTabsProps } from "./parts/HintTabs";
import VersionDialog, { VersionDialogProps } from "./parts/VersionDialog";

export { AlgorithmHintsFormProvider, type AlgorithmHintsFormProviderProps, useAlgorithmHintsFormContext } from "./Context";

const useHintsErrorMessages = () => {
  const { t } = useTranslation();
  const { methods } = useAlgorithmHintsFormContext();

  const { errors } = methods.formState;

  const errorMessages: string[] = [];
  if (errors) {
    const maxLength = methods.getValues("hints").length;
    for (let i = 0; i < maxLength; i++) {
      const hint = errors.hints?.[i];
      if (hint) {
        errorMessages.push(`${t("タブの入力に不備があります。")} (${t("ヒント")}${i + 1})`);
      }
    }
  }
  return errorMessages;
};

export type AlgorithmHintsFormProps = {
  versionDialog: VersionDialogProps;

  hintHeader: HintHeaderProps;
  hintTabs: HintTabsProps;
};

const AlgorithmHintsForm: React.FC<AlgorithmHintsFormProps> = props => {
  const { methods } = useAlgorithmHintsFormContext();
  const errorMessages = useHintsErrorMessages();

  const hintHeader: HintHeaderProps = {
    ...props.hintHeader,
  };
  return (
    <FormProvider {...methods}>
      <VersionDialog {...props.versionDialog} />
      <Box mb={2}>
        <HintHeader {...hintHeader} />
        <ErrorMessage
          errors={methods.formState.errors}
          name="hints"
          render={() => (
            <Box display="flex" flexDirection="column">
              <Box mt={1}>
                {errorMessages.map(errorMessage => (
                  <Box key={errorMessage}>
                    <Typography variant="caption" color="error">
                      {errorMessage}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        />
      </Box>
      {props.hintTabs.tabItems.length > 0 && <HintTabs {...props.hintTabs} />}
    </FormProvider>
  );
};

AlgorithmHintsForm.displayName = "AlgorithmHintsForm";

export default AlgorithmHintsForm;
