import { useTranslation } from "@hireroo/i18n";
import Markdown from "@hireroo/markdown/react";
import Box, { BoxProps } from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup, { ToggleButtonGroupProps } from "@mui/material/ToggleButtonGroup";
import * as React from "react";
import { useFormContext } from "react-hook-form";
import { useResizeDetector } from "react-resize-detector";

import InputControlTextField, { InputControlTextFieldProps } from "../../primitive/InputControl/InputControlTextField";

export type Size = "xs" | "sm" | "md";

type SizeMap = {
  min: number;
  max: number;
};

export type MarkdownPreviewEditorProps = Pick<
  InputControlTextFieldProps,
  "defaultValue" | "required" | "label" | "placeholder" | "size" | "helperText" | "rows" | "minRows" | "multiline"
>;
/**
 * Specify the same font family as monaco-editor
 */
const FontFamily = `Menlo, Monaco, "Courier New", monospace`;

const StyledTextField = styled(InputControlTextField)<{ ownerState: { min: number; max: number } }>(({ ownerState }) => ({
  "& textarea": {
    fontFamily: FontFamily,
    resize: "vertical",
    minHeight: ownerState.min,
    maxHeight: ownerState.max,
    overflow: "scroll !important",
  },
}));

const HeightMap: Record<Size, SizeMap> = {
  xs: {
    min: 50,
    max: 150,
  },
  sm: {
    min: 100,
    max: 300,
  },
  md: {
    min: 300,
    max: 600,
  },
};

const StyledPreviewArea = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: "1",
  border: "1px solid grey",
  boxSizing: "content-box",
  overflow: "scroll",
}));

const alignmentMap = {
  WRITE: "WRITE",
  PREVIEW: "PREVIEW",
};

const MarkdownPreviewEditor: React.FC<MarkdownPreviewEditorProps & { name: string; areaSize?: Size }> = ({ areaSize = "xs", ...props }) => {
  const { t } = useTranslation();
  const [alignment, updateAlignment] = React.useState(alignmentMap.WRITE);
  const method = useFormContext();
  const detector = useResizeDetector();
  const [inputSize, setWriterModeHeight] = React.useState(0);
  const detailFieldProps: InputControlTextFieldProps = {
    fullWidth: true,
    multiline: true,
    InputLabelProps: {
      shrink: true,
    },
    variant: "outlined",
    sx: { resize: "vertical" },
    onChange: () => {
      method.clearErrors(props.name);
    },
    ...props,
  };
  const toggleButtonGroupProps: ToggleButtonGroupProps = {
    color: "primary",
    size: "small",
    value: alignment,
    exclusive: true,
    onChange: (_e, newValue) => {
      if (Object.values(alignmentMap).includes(newValue)) {
        updateAlignment(newValue);
      }
    },
  };
  /**
   * Write Mode height is taken over
   */
  const writeModeHeight = alignment === alignmentMap.PREVIEW ? inputSize : 0;
  const previewMinHeight = Math.max(HeightMap[areaSize].min, writeModeHeight);
  const previewBoxProps: BoxProps = {
    p: 1,
    borderRadius: 1,
    minHeight: Math.min(previewMinHeight, HeightMap[areaSize].max),
    maxHeight: HeightMap[areaSize].max,
  };
  React.useEffect(() => {
    if (alignment === alignmentMap.WRITE && typeof detector.height === "number") {
      setWriterModeHeight(detector.height);
    }
  }, [alignment, detector.height]);
  const Preview = (
    <StyledPreviewArea {...previewBoxProps}>
      <React.Suspense>
        <Markdown children={method.getValues(props.name)} size="middle" />
      </React.Suspense>
    </StyledPreviewArea>
  );
  return (
    <Box>
      <Box display="flex" justifyContent="right">
        <ToggleButtonGroup {...toggleButtonGroupProps}>
          <ToggleButton value={alignmentMap.WRITE}>{t("入力")}</ToggleButton>
          <ToggleButton value={alignmentMap.PREVIEW}>{t("プレビュー")}</ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box mt={2}>
        {alignment === alignmentMap.WRITE && (
          <Box ref={detector.ref}>
            <StyledTextField
              name={props.name}
              {...detailFieldProps}
              ownerState={{
                min: HeightMap[areaSize].min,
                max: HeightMap[areaSize].max,
              }}
            />
          </Box>
        )}
        {alignment === alignmentMap.PREVIEW && Preview}
      </Box>
    </Box>
  );
};

MarkdownPreviewEditor.displayName = "MarkdownPreviewEditor";

export default MarkdownPreviewEditor;
