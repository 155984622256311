import MUITableCell, { TableCellProps as MUITableCellProps } from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type TableCellProps = MUITableCellProps & {};

const TableCell: React.FC<TableCellProps> = props => {
  const { children } = props;
  return (
    <MUITableCell {...props}>
      <Typography>{children}</Typography>
    </MUITableCell>
  );
};

TableCell.displayName = "TableCell";

export default TableCell;
