import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import { useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import * as React from "react";

import { useGenerateIpAddressSectionProps } from "./useGenerateIpAddressSectionProps";

type CheatDetectionSection = Widget.ProjectTestReportProps["cheatDetectionSection"];

export type GenerateCheatDetectionSectionPropsArgs = {
  uniqueKey: ProjectTestReport.UniqueKey;
  projectId: number;
  canShowCheatDetectionSection: boolean;
  canShowStatistic: boolean;
};
export const useGenerateCheatDetectionSectionProps = (args: GenerateCheatDetectionSectionPropsArgs): CheatDetectionSection => {
  const hooks = ProjectTestReport.useCreateProjectHooks(args.projectId);
  const submission = hooks.useCurrentSubmission();

  const { t } = useTranslation();
  const statistics = hooks.useStatistics();
  const ipAddressSection = useGenerateIpAddressSectionProps({ projectId: args.projectId });

  return React.useMemo((): CheatDetectionSection => {
    if (!args.canShowCheatDetectionSection || !submission) {
      return;
    }

    return {
      numTabEvents: args.canShowCheatDetectionSection && {
        value: `${submission.numTabEvents} ${t("回")}`,
        icon: {
          key: (submission.numTabEvents ?? 0) <= Math.round(statistics.averageNumTabEvents) ? "CHECK" : "WARNING",
          title: `${t("平均離脱回数")} ${Math.round(statistics.averageNumTabEvents)}`,
        },
      },
      numAccessEvents: {
        value: `${submission.numAccessEvents} ${t("回")}`,
        icon: {
          /** expected number of access is 1, otherwise warn */
          key: submission.numAccessEvents === 1 ? "CHECK" : "WARNING",
          title: t("IPアドレス検知回数"),
        },
      },
      ipAddressSection: ipAddressSection,
      canShowTooltip: args.canShowStatistic,
    };
  }, [args.canShowCheatDetectionSection, args.canShowStatistic, ipAddressSection, statistics.averageNumTabEvents, submission, t]);
};
