import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import React from "react";

import { CommentElement, FontSizeMap, OPERATION_TYPE, OperationType, UnionSettingsFields } from "../../../../../features";

export type CommentSettingsFormProps = {
  element: CommentElement;
  updateSettings: (id: string, updates: UnionSettingsFields, operationType: OperationType) => void;
};

export const CommentSettingsForm: React.FC<CommentSettingsFormProps> = (props: CommentSettingsFormProps) => {
  const { t } = useTranslation();

  const handleToggleFontSize = (e: React.MouseEvent<HTMLElement>, value: number) => {
    props.updateSettings(props.element.id, { fontSize: value }, OPERATION_TYPE.do);
  };

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Box mt={2}>
        <Typography>{t("フォントサイズ")}</Typography>
        <Box mt={1} />
        <ToggleButtonGroup color="primary" value={props.element.settings.fontSize} exclusive onChange={handleToggleFontSize}>
          <ToggleButton value={FontSizeMap["small"]}>S</ToggleButton>
          <ToggleButton value={FontSizeMap["medium"]}>M</ToggleButton>
          <ToggleButton value={FontSizeMap["large"]}>L</ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
};

CommentSettingsForm.displayName = "CommentSettingsForm";

export default CommentSettingsForm;
