import { useTranslation } from "@hireroo/i18n";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import WarningIcon from "@mui/icons-material/Warning";
import Box from "@mui/material/Box";
import Button, { type ButtonProps } from "@mui/material/Button";
import { styled, useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledWarningIcon = styled(WarningIcon)(({ theme }) => ({
  color: theme.palette.warning.main,
  paddingRight: theme.spacing(1),
}));

const StyledCheckCircleIcon = styled(CheckCircleIcon)(({ theme }) => ({
  color: theme.palette.text.disabled,
  paddingRight: theme.spacing(1),
}));

type SuspiciousDegree = "CLEAN" | "SUSPICIOUS";

export type SuspiciousDegreeMessageForQuestionProps = {
  suspiciousDegree: SuspiciousDegree;
  feedbackButton: Pick<ButtonProps, "onClick" | "disabled">;
};

const SuspiciousDegreeMessageForQuestion: React.FC<SuspiciousDegreeMessageForQuestionProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { suspiciousDegree, feedbackButton } = props;
  const messageMap: Record<SuspiciousDegree, string> = {
    CLEAN: t("この問題の解答時に不審な行動はみつかりませんでした。"),
    SUSPICIOUS: t("この問題の解答時に不審な行動がみつかりました。"),
  };

  const SuspiciousDegreeIcon = React.useMemo(() => {
    switch (suspiciousDegree) {
      case "CLEAN":
        return (
          <Tooltip title={t("不審な行動はみつかりませんでした。")}>
            <StyledCheckCircleIcon />
          </Tooltip>
        );
      case "SUSPICIOUS":
        return (
          <Tooltip title={t("不審な行動がみつかりました。プレイバックなどを確認し、候補者の行動に問題がないか確認してください。")}>
            <StyledWarningIcon />
          </Tooltip>
        );
      default:
        throw Error(`unexpected suspicious degree ${suspiciousDegree satisfies never}`);
    }
  }, [suspiciousDegree, t]);

  const button = React.useMemo(
    () => (
      <Button
        {...feedbackButton}
        variant="outlined"
        color="inherit"
        startIcon={<FlagOutlinedIcon />}
        sx={{
          borderColor: theme.palette.divider,
        }}
      >
        {t("フィードバック")}
      </Button>
    ),
    [t, feedbackButton, theme.palette.divider],
  );

  const feedbackSuspiciousDegreeButton = React.useMemo(() => {
    if (!feedbackButton.disabled) {
      return button;
    }

    return (
      <Tooltip title={t("この問題に対する不審度のフィードバックは既に送信されています。")} placement="top">
        <span>{button}</span>
      </Tooltip>
    );
  }, [t, button, feedbackButton.disabled]);

  return (
    <Box display="flex" alignItems="center">
      {SuspiciousDegreeIcon}
      <Typography mr={1}>{messageMap[suspiciousDegree]}</Typography>
      {feedbackSuspiciousDegreeButton}
    </Box>
  );
};

SuspiciousDegreeMessageForQuestion.displayName = "SuspiciousDegreeMessageForQuestion";

export default SuspiciousDegreeMessageForQuestion;
