import { useTranslation } from "@hireroo/i18n";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { GoogleLoginButton } from "react-social-login-buttons";

import SignInFooter, { SignInFooterProps } from "../../ms-components/Auth/SignInFooter/SignInFooter";
import SignInHeader from "../../ms-components/Auth/SignInHeader/SignInHeader";

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "100vh",
  width: "100vw",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.background.default,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  textTransform: "none",
  fontWeight: 400,
  color: theme.palette.text.primary,
}));

const WrapperStack = styled(Stack)(({ theme }) => ({
  width: "416px",
  padding: theme.spacing(3),
  borderRadius: "16px",
  backgroundColor: theme.palette["Background/Paper"].p2,
}));

type Icon = "KEY";

const IconMap: Record<Icon, React.ReactNode> = {
  KEY: <KeyOutlinedIcon />,
};
const BUTTON_WIDTH = 189;

export type SignInProps = {
  googleSignInButton: {
    onClick: () => void;
  };
  SignInComponent: React.ReactNode;
  footer: SignInFooterProps;
  signUpButton: Pick<ButtonProps, "onClick">;
  switchButton: Pick<ButtonProps, "onClick" | "children"> & {
    icon?: Icon;
  };
};

const SignIn: React.FC<SignInProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();

  const signUpButton: ButtonProps = {
    ...props.signUpButton,
    color: "secondary",
    sx: {
      textTransform: "none",
    },
    children: t("アカウントを作成"),
  };
  const ssoButton: ButtonProps = {
    ...props.switchButton,
    variant: "outlined",
    color: "outline-only",
    sx: {
      height: "40px",
      minWidth: BUTTON_WIDTH,
      textTransform: "none",
    },
    startIcon: props.switchButton.icon ? IconMap[props.switchButton.icon] : undefined,
  };
  return (
    <Wrapper>
      <Stack direction="column" alignItems="center" width="416px" spacing={2}>
        <SignInHeader />
        <WrapperStack spacing={3}>
          <Typography variant="h6" fontSize={20} fontWeight={700}>
            {t("サインイン")}
          </Typography>
          {props.SignInComponent}
          <Box>
            <Divider>
              <Typography variant="body2" color="textSecondary">
                {t("または")}
              </Typography>
            </Divider>
          </Box>
          <Stack spacing={2} alignItems="center">
            <GoogleLoginButton
              align="center"
              activeStyle={{
                backgroundColor: theme.palette["Background/Paper"].p2,
                border: `1px solid ${theme.palette.text.primary}`,
                fontWeight: 400,
              }}
              style={{
                width: `${BUTTON_WIDTH}px`,
                height: 40,
                margin: 0,
                fontSize: "14px",
                fontWeight: 400,
                backgroundColor: theme.palette["Background/Paper"].p2,
                border: `1px solid ${theme.palette.divider}`,
                color: theme.palette.text.primary,
              }}
              onClick={props.googleSignInButton.onClick}
              text="Sign in with Google"
            />
            <StyledButton {...ssoButton} />
          </Stack>
        </WrapperStack>
        <Box display="flex" justifyContent="center">
          <Button {...signUpButton} />
        </Box>
        <SignInFooter {...props.footer} />
      </Stack>
    </Wrapper>
  );
};

SignIn.displayName = "SignIn";

export default SignIn;
