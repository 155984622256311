import { useTranslation } from "@hireroo/i18n";
import * as z from "zod";

export const useShareSettingsFormSchema = () => {
  const { t } = useTranslation();

  return z.object({
    showScore: z.boolean(),
    showRank: z.boolean(),
    showAnswer: z.boolean(),
    showPlayback: z.boolean(),
    showReview: z.boolean(),
    showRelativeEvaluation: z.boolean(),
    periodDays: z.preprocess(
      v => Number(v),
      z.number().max(30, {
        message: t("有効期間は最大30日以内で設定してください。"),
      }),
    ),
  });
};

export type ShareSettingsFormSchema = z.infer<ReturnType<typeof useShareSettingsFormSchema>>;
