import { RemotesStore } from "@hireroo/app-store/page/e/remotes";
import { updateQueryParamsByObject } from "@hireroo/router/api";

export const startSubscribeListParams = () => {
  return RemotesStore.subscribeAllState(state => {
    if (!state.currentSearchFilter) {
      return;
    }
    const { currentSearchFilter, pager } = state;

    /**
     * Do not update query parameters if they are different from the Default Value when the page is visited
     * (i.e., the user has not manipulated them).
     */
    updateQueryParamsByObject({
      [RemotesStore.QueryKeys.TAG]: currentSearchFilter.tags.map(tag => tag.displayName),
      [RemotesStore.QueryKeys.TITLE]:
        currentSearchFilter.textFilter && currentSearchFilter.textFilter.length > 0 ? [currentSearchFilter.textFilter] : [],
      [RemotesStore.QueryKeys.STATUS]: currentSearchFilter?.statuses ? currentSearchFilter.statuses : [],
      [RemotesStore.QueryKeys.ISSUER]: currentSearchFilter?.createdBy ? currentSearchFilter.createdBy : [],
      [RemotesStore.QueryKeys.RESULT]: currentSearchFilter?.result ? [currentSearchFilter.result] : [],
      [RemotesStore.QueryKeys.SORT]: pager.sortFieldValue === "created-at-ascending" ? [`CREATED_AT-false`] : [],
      /**
       * Set page number to "1" if it is not a page change
       */
      [RemotesStore.QueryKeys.PAGE]: pager.page > 0 ? [(pager.page + 1).toString()] : [],
      [RemotesStore.QueryKeys.SIZE]: pager.size !== RemotesStore.DefaultPager.size ? [pager.size.toString()] : [],
    });
  });
};
