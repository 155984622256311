import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import * as React from "react";

import type * as Types from "../../../../types";
import NavigationMenuItem, { NavigationMenuItemProps } from "../NavigationMenuItem/NavigationMenuItem";

const ClassNames = {
  popupMenu: "__popup_menu__",
};

const Wrapper = styled(Box)(() => ({
  position: "relative",
  [`.${ClassNames.popupMenu}`]: {
    display: "none",
  },
  [`&:hover .${ClassNames.popupMenu}`]: {
    display: "block",
    position: "absolute",
  },
}));

const PopupWrapper = styled(Box)(() => ({
  position: "absolute",
  top: 0,
  /**
   * This space is needed to allow the cursor to move continuously on the Wrapper.
   */
  paddingLeft: "6px",
  left: "100%",
  width: "256px",
}));

export type ChildNavigationMenuFactoryProps = Types.MenuItemWithChildren & {
  NavigationMenu: React.FC<{ items: Types.MenuItem[] }>;
};

const ChildNavigationMenuFactory: React.FC<ChildNavigationMenuFactoryProps> = props => {
  const { NavigationMenu, ...rest } = props;
  const navigationMenuItemProps: NavigationMenuItemProps = {
    ...rest,
    root: true,
    children: props.title,
  };

  return (
    <Wrapper>
      <NavigationMenuItem {...navigationMenuItemProps} />
      <PopupWrapper className={ClassNames.popupMenu}>
        <Paper elevation={8}>
          <NavigationMenu items={rest.children} />
        </Paper>
      </PopupWrapper>
    </Wrapper>
  );
};

ChildNavigationMenuFactory.displayName = "ChildNavigationMenuFactory";

export default ChildNavigationMenuFactory;
