import { QuestionSelectFieldForResourceEditor } from "@hireroo/app-store/widget/e/QuestionSelectFieldForResourceEditor";
import { ScreeningResourceEditor } from "@hireroo/app-store/widget/e/ScreeningResourceEditor";
import { ViewerAssignField } from "@hireroo/app-store/widget/e/ViewerAssignField";
import * as Time from "@hireroo/formatter/time";
import { useLanguageCode, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import { Widget } from "@hireroo/presentation";
import type { ScreeningResourceEditorForm } from "@hireroo/validator";
import * as React from "react";

import { useGenerateConfirmSectionItemProps } from "./privateHelper";

type ItemProps = Widget.ScreeningResourceEditorProps["confirmSection"]["selectedQuestions"][0];
type Viewer = Widget.ScreeningResourceEditorProps["confirmSection"]["viewers"][0];
type CustomFieldProps = Widget.ScreeningResourceEditorProps["confirmSection"]["customFields"][0];

export type GenerateConfirmSectionArgs = {
  mode: Widget.ScreeningResourceEditorProps["mode"];
};

export const useGenerateConfirmSectionProps = (_args: GenerateConfirmSectionArgs): Widget.ScreeningResourceEditorProps["confirmSection"] => {
  const lang = useLanguageCode();
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const employees = ViewerAssignField.useEmployees();
  const employeeGroups = ViewerAssignField.useEmployeeGroups();
  const submitValues = ScreeningResourceEditor.useSubmitValues();
  const questions = QuestionSelectFieldForResourceEditor.useQuestionMap();

  const testQuestionSetup = submitValues.TEST_QUESTION_SETUP;
  const testSetup = submitValues.TEST_SETUP;
  const reportSetup = submitValues.REPORT_SETUP;
  const testInviteSetup = submitValues.TEST_INVITE_SETUP;

  const candidateAccessPolicyMap: Record<ScreeningResourceEditorForm.CandidateAccessPolicy, string> = {
    ALLOW_ALL: t("リンクを知っているすべての人が受験可能"),
    RESTRICTED_BY_INVITATION_CODE: t("パスワード付きリンクを知っている人が受験可能"),
  };

  const invitationEmailLanguageMap: Record<ScreeningResourceEditorForm.InvitationLanguage, string> = {
    en: t("英語"),
    ja: t("日本語"),
  };

  const assistFeatures = [testQuestionSetup.enabledWebSearch && t("Google検索"), testQuestionSetup.enabledChatGPT && "ChatGPT"].filter(Boolean);

  const viewerNames = React.useMemo((): Viewer[] => {
    const viewersField = reportSetup.viewers;
    return viewersField.reduce<Viewer[]>((all, viewer) => {
      if (viewer.value?.type === "EMPLOYEE") {
        const employee = employees.get(viewer.value.employeeId);
        if (!employee) {
          return all;
        }
        return all.concat({
          kind: "USER",
          iconSrc: employee.photoUrl,
          name: employee.displayName || employee.email,
        });
      }
      if (viewer.value?.type === "EMPLOYEE_GROUP") {
        const employeeGroup = employeeGroups.get(viewer.value.groupId);
        if (!employeeGroup) {
          return all;
        }
        return all.concat({
          kind: "GROUP",
          name: employeeGroup.groupName,
        });
      }
      return all;
    }, []);
  }, [employeeGroups, employees, reportSetup.viewers]);

  const generateConfirmSectionItemProps = useGenerateConfirmSectionItemProps();

  return {
    title: testQuestionSetup.name,
    timelimit: Time.formatMinutes(testQuestionSetup.timeLimitMinutes, lang),
    assistFeature: assistFeatures.length > 0 ? assistFeatures.join(", ") : undefined,
    hintFeature: testQuestionSetup.enabledHint ? t("許可する") : t("許可しない"),
    acceptableLimit: typeof testSetup.acceptableLimit === "number" ? `${testSetup.acceptableLimit} ${t("人")}` : undefined,
    deadline: testSetup.deadlineDate ? Time.datetimeFormat(testSetup.deadlineDate) : undefined,
    memo: reportSetup.memo,
    selectedQuestions: testQuestionSetup.entityTracks.reduce<ItemProps[]>((all, entityTrack, index) => {
      if (entityTrack.type !== "FIXED") {
        const subItems = entityTrack.entitySources.reduce<Exclude<ItemProps["subItems"], undefined>>((all2, entitySource, childIndex) => {
          const question = questions.get(entitySource.uniqueKey);
          if (!question) {
            return all2;
          }
          const subTitle = [
            `Q${index + 1} - ${childIndex + 1}`,
            resolveLanguage(question, lang, "title"),
            `(${QuestionSelectFieldForResourceEditor.getVersion(question)})`,
          ].join(" ");
          const item = generateConfirmSectionItemProps(subTitle, entitySource, question);
          all2.push(item);
          return all2;
        }, []);

        switch (entityTrack.type) {
          case "RANDOM_FROM_QUESTIONS": {
            const title = [`Q${index + 1}`, t("ランダムに問題が出題される")].join(" ");
            all.push({
              title,
              subItems,
            });
            break;
          }
          case "SELECTABLE_FROM_QUESTIONS": {
            const title = [`Q${index + 1}`, t("受験者が問題を選択できる")].join(" ");
            all.push({
              title,
              subItems,
            });
            break;
          }
          default:
            throw new Error(`Unsupported Entity Track: ${entityTrack satisfies never}`);
        }

        return all;
      }
      const entitySource = entityTrack.entitySource;
      const question = questions.get(entitySource.uniqueKey);
      if (!question) {
        return all;
      }
      const title = [
        `Q${index + 1}`,
        resolveLanguage(question, lang, "title"),
        `(${QuestionSelectFieldForResourceEditor.getVersion(question)})`,
      ].join(" ");
      const item = generateConfirmSectionItemProps(title, entitySource, question);
      all.push(item);
      return all;
    }, []),
    customScoreAllocation: {
      allocationBar: {
        scores: testQuestionSetup.entityTracks.map((entityTrack, index) => {
          return {
            label: `Q${index + 1}`,
            weight: entityTrack.questionScoreWeight,
          };
        }),
      },
      tooltip: t("トータルスコアに対しての配点割合を表示します。"),
    },
    tags: reportSetup.tags.map(tag => tag.value.name),
    viewers: viewerNames,
    accessPolicy: candidateAccessPolicyMap[testInviteSetup.candidateAccessPolicy],
    invitationEmailLanguage: invitationEmailLanguageMap[testInviteSetup.invitationLanguage],
    messageForCandidate: testInviteSetup.messageForCandidate || undefined,
    ownerEmailAddress: testInviteSetup.ownerEmailAddress || undefined,
    customFields: testSetup.customFields.map((customField): CustomFieldProps => {
      switch (customField.valueType) {
        case "INTEGER": {
          return {
            label: customField.label,
            description: customField.description,
            formType: t("数値（整数）"),
            kind: customField.validationRule.required ? "REQUIRED" : "OPTIONAL",
            validationRule: {
              min: t2("minNumberValue", { value: customField.validationRule.min ?? t("指定なし") }),
              max: t2("maxNumberValue", { value: customField.validationRule.max ?? t("指定なし") }),
            },
          };
        }
        case "STRING": {
          return {
            label: customField.label,
            description: customField.description,
            formType: customField.formType === "INPUT_TEXT" ? t("短文回答") : t("長文回答"),
            kind: customField.validationRule.required ? "REQUIRED" : "OPTIONAL",
            validationRule: {
              min: t2("minCharacters", { value: customField.validationRule.minLength ?? t("指定なし") }),
              max: t2("maxCharacters", { value: customField.validationRule.maxLength ?? t("指定なし") }),
            },
          };
        }
        default:
          throw new Error(`CustomField is invalid: ${customField satisfies never}`);
      }
    }),
  };
};
