import { useTranslation } from "@hireroo/i18n";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledBox = styled(Box)(() => ({
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
}));

const StyledTypography = styled(Typography)(() => ({
  fontWeight: 500,
}));

const StyledIconWrapper = styled("span")(({ theme }) => ({
  lineHeight: "12px",
  marginLeft: 2,
  color: theme.palette.action.active,
  svg: {
    width: 16,
    height: 16,
  },
}));

export type HeadingProps = {};

const Heading: React.FC<HeadingProps> = () => {
  const { t } = useTranslation();
  return (
    <StyledBox>
      <StyledTypography variant="subtitle2">{t("トータルスコア")}</StyledTypography>
      <Tooltip title={t("トータルスコアはテストに含まれるすべての問題のスコアの配点割合によって算出されます。")}>
        <StyledIconWrapper>
          <InfoOutlinedIcon fontSize="small" color="inherit" />
        </StyledIconWrapper>
      </Tooltip>
    </StyledBox>
  );
};

Heading.displayName = "Heading";

export default Heading;
