import { useTwilioConversationsContext } from "@hireroo/app-helper/hooks";
import type { Pages } from "@hireroo/presentation";
import * as React from "react";

import LiveCodingChatWindowFetchContainer from "./widget/LiveCodingChatWindow/FetchContainer";
import RemoteInterviewEvaluationFetchContainer from "./widget/RemoteInterviewEvaluation/FetchContainer";

type GenerateRightSidePanelPropsArgs = {
  remoteId: string;
};

export const useGenerateRightSidePanelProps = (
  args: GenerateRightSidePanelPropsArgs,
): Pages.RemoteInterviewForEmployeeProps["rightSidePanel"] => {
  const { hasUnreadMessages } = useTwilioConversationsContext();
  return {
    Evaluation: <RemoteInterviewEvaluationFetchContainer uniqueKey={args.remoteId} />,
    Chat: <LiveCodingChatWindowFetchContainer />,
    hasChatBadge: hasUnreadMessages,
  };
};
