import { App, Company } from "@hireroo/app-store/essential/employee";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import DashboardScreeningsSectionContainer, { DashboardScreeningsSectionContainerProps } from "./Container";

export type DashboardScreeningsSectionFetchContainerProps = {};

const DashboardScreeningsSectionFetchContainer: React.FC<DashboardScreeningsSectionFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const companyId = Company.useStrictActiveCompanyId();

  const [result, refresh] = Graphql.useListRecentScreeningsForDashboardScreeningSectionQuery({
    variables: {
      companyId: companyId,
      size: 4,
    },
    requestPolicy: "cache-and-network",
    pause: appStatus !== "INITIALIZED",
  });

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED") {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: DashboardScreeningsSectionContainerProps = {
    screenings: result.data?.recentScreenings || [],
  };

  return <DashboardScreeningsSectionContainer {...containerProps} />;
};

DashboardScreeningsSectionFetchContainer.displayName = "DashboardScreeningsSectionFetchContainer";

export default withErrorBoundary(DashboardScreeningsSectionFetchContainer, {});
