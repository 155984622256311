import { ListSubheader } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";

import QuestionVariantButton, { QuestionVariantButtonProps } from "../QuestionVariantButton/QuestionVariantButton";

const StyledListSubheader = styled(ListSubheader)(() => ({
  fontSize: "14px",
  backgroundColor: "inherit",
}));

export type ListItemWithHeaderProps = {
  headerText: string;
  questionVariantButton: QuestionVariantButtonProps[];
};

const ListItemWithHeader: React.FC<ListItemWithHeaderProps> = props => {
  return (
    <Box mb={2}>
      <StyledListSubheader>{props.headerText}</StyledListSubheader>
      {props.questionVariantButton.map(content => (
        <QuestionVariantButton key={content.title} {...content} />
      ))}
    </Box>
  );
};

ListItemWithHeader.displayName = "ListItemWithHeader";

export default ListItemWithHeader;
