import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React, { useMemo } from "react";

import {
  ComponentType,
  ELEMENT_LABEL,
  ELEMENT_TYPE,
  ElementDataKeyMap,
  ElementMasterData,
  OperationType,
  UnionSettingsFields,
} from "../../../features";
import { useSystemDesignContext } from "../../../store";
import { CacheSettingsForm } from "./parts/CacheSettingsForm/CacheSettingsForm";
import { CommentSettingsForm } from "./parts/CommentSettingsForm/CommentSettingsForm";
import { DefaultSettingsForm } from "./parts/DefaultSettingsForm/DefaultSettingsForm";
import { EdgeSettingsForm } from "./parts/EdgeSettingsForm/EdgeSettingsForm";
import { QueueSettingsForm } from "./parts/QueueSettingsForm/QueueSettingsForm";
import { SqlSettingsForm } from "./parts/SqlSettingsForm/SqlSettingsForm";
import { VmSettingsForm } from "./parts/VmSettingsForm/VmSettingsForm";

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  width: "100%",
}));

const InnerStyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette["Secondary/Shades"].p12,
  width: "100%",
  height: "100%",
}));

export type ElementSettingsV2Props = {
  elementId: string;
  componentType: ComponentType;
  updateSettings: (id: string, updates: UnionSettingsFields, operationType: OperationType) => void;
  deleteElementButton: Pick<ButtonProps, "onClick">;
  closeButton: Pick<IconButtonProps, "onClick">;
};

const FieldBox = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
}));

const StyledHeading = styled(Typography)(() => ({
  fontWeight: "bold",
  color: "text.secondary",
}));

const ElementSettingsV2: React.FC<ElementSettingsV2Props> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const lang = useLanguageCode();
  const Store = useSystemDesignContext();
  const elementFactory = Store.hooks.useElement();
  const element = useMemo(() => {
    return elementFactory(props.elementId);
  }, [elementFactory, props.elementId]);

  const Header = React.useMemo((): Exclude<React.ReactNode, null> => {
    switch (element?.type) {
      case "edge": {
        return (
          <FieldBox>
            <StyledHeading mb={2}>{t("データフロー")}</StyledHeading>
            <EdgeSettingsForm element={element} updateSettings={props.updateSettings} />
          </FieldBox>
        );
      }
      case "comment": {
        return (
          <FieldBox>
            <StyledHeading mb={2}>{t("テキスト")}</StyledHeading>
            <CommentSettingsForm element={element} updateSettings={props.updateSettings} />
          </FieldBox>
        );
      }
      case "network":
      case "node":
        return (
          <FieldBox>
            <StyledHeading>
              {resolveLanguage(ElementMasterData[ElementDataKeyMap[props.componentType][element.label]], lang, "title")}
            </StyledHeading>
          </FieldBox>
        );
    }
  }, [element, lang, props.componentType, props.updateSettings, t]);

  return (
    <StyledBox sx={{ boxShadow: 1 }}>
      <InnerStyledBox>
        <Stack divider={<Divider />}>
          <Box my={1.5} display="flex" justifyContent="space-between">
            <Typography variant="subtitle2">{Header}</Typography>
            <IconButton
              size="small"
              sx={{
                position: "absolute",
                right: 8,
                top: 14,
                padding: theme.spacing(0.25),
                color: theme.palette["Gray/Shades"].p56,
              }}
              {...props.closeButton}
            >
              <CloseIcon sx={{ fontSize: "18px" }} />
            </IconButton>
          </Box>

          {element?.type === ELEMENT_TYPE.network && (
            <FieldBox my={1.5}>
              <DefaultSettingsForm element={element} updateSettings={props.updateSettings} />
            </FieldBox>
          )}

          {element?.type === ELEMENT_TYPE.node && (
            <FieldBox my={1.5}>
              <DefaultSettingsForm key={element.id} element={element} updateSettings={props.updateSettings} />
              {element.label === ELEMENT_LABEL.vm && <VmSettingsForm element={element} updateSettings={props.updateSettings} />}
              {element.label === ELEMENT_LABEL.sql && <SqlSettingsForm element={element} updateSettings={props.updateSettings} />}
              {element.label === ELEMENT_LABEL.cache && <CacheSettingsForm element={element} updateSettings={props.updateSettings} />}
              {element.label === ELEMENT_LABEL.queue && <QueueSettingsForm element={element} updateSettings={props.updateSettings} />}
            </FieldBox>
          )}
          <FieldBox my={1.5}>
            <Button
              {...props.deleteElementButton}
              sx={{ paddingRight: 0.5, paddingLeft: 0.5 }}
              color="error"
              variant="outlined"
              fullWidth
              startIcon={<DeleteOutlineIcon color="error" fontSize="small" />}
            >
              {t("コンポーネントを削除")}
            </Button>
          </FieldBox>
        </Stack>
      </InnerStyledBox>
    </StyledBox>
  );
};

ElementSettingsV2.displayName = "ElementSettingsV2";

export default ElementSettingsV2;
