import { TrackType } from "./types";

/**
 * This class allows components to reliably use the 'enabled' property of the Track object.
 */
export class TrackEnabledSubscriber {
  private subscribed = false;

  constructor(
    private track: TrackType,
    private onSubscribed: () => void,
    private onUnpublished: () => void,
  ) {
    this.subscribe();
  }

  subscribe() {
    if (this.subscribed) {
      return;
    }
    this.track.on("enabled", this.onSubscribed);
    this.track.on("disabled", this.onUnpublished);

    this.subscribed = true;
  }

  unsubscribe() {
    if (!this.subscribed) {
      return;
    }
    this.track.off("enabled", this.onSubscribed);
    this.track.off("disabled", this.onUnpublished);

    this.subscribed = false;
  }
}
