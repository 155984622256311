import * as Def from "./definition";
import { state } from "./State";
import type * as Types from "./types";

export const clear = (): void => {
  state.runTutorial = false;
  state.kind = null;
};

export const updateRunTutorial = (runTutorial: boolean) => {
  state.runTutorial = runTutorial;
};

/**
 * Check the SessionStorage before deciding whether to start the tutorial.
 */
export const autoStartTutorial = (kind: Types.Kind) => {
  const storageKey: `guide/v2/${Types.Key}` = `guide/v2/${Def.kindMap[kind]}`;
  const cachedValue = sessionStorage.getItem(storageKey);
  const haveAlreadyAutoRunTutorial = cachedValue === Def.ALREADY_RUN_TUTORIAL;
  state.kind = kind;
  if (haveAlreadyAutoRunTutorial) {
    state.runTutorial = false;
    return;
  } else {
    sessionStorage.setItem(storageKey, Def.ALREADY_RUN_TUTORIAL);
    state.runTutorial = true;
  }
};
