import * as React from "react";

import { FileNode, findNode, findParent } from "./fileTree";

export const useFocusNode = (defaultValue: string, fileTree: FileNode) => {
  const [focusedNode, setFocusNode] = React.useState(defaultValue);

  // In case focused is deleted via terminal, update focused with its parent
  // This ensures the focused always exists
  React.useEffect(() => {
    const node = findNode(fileTree, focusedNode);
    if (node === null) {
      const parent = findParent(fileTree, focusedNode);
      setFocusNode(parent?.id ?? ".");
    }
  }, [focusedNode, fileTree]);

  return {
    focusedNode,
    setFocusNode,
  };
};
