import { useTranslation } from "@hireroo/i18n";
import { AlgorithmSignatureForm } from "@hireroo/validator";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";

import InputControlTextField, { InputControlTextFieldProps } from "../../../../../../primitive/InputControl/InputControlTextField";
import { useAlgorithmTestCasesFormContext } from "../../../../Context";
import { generatePlaceholder } from "../../../../generatePlaceHolder";

export type AlgorithmInputProps = {
  index: number;
  signature: AlgorithmSignatureForm.AlgorithmSignatureSchemaType;
};

const AlgorithmInputFields: React.FC<AlgorithmInputProps> = props => {
  const { t } = useTranslation();
  const { method } = useAlgorithmTestCasesFormContext();

  const inputTextField: InputControlTextFieldProps = {
    type: "text",
    color: "primary",
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
    multiline: true,
    required: true,
    minRows: 5,
    control: method.control,
  };

  const outputTextField: InputControlTextFieldProps = {
    type: "text",
    color: "primary",
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
    required: true,
    multiline: true,
    minRows: 5,
    control: method.control,
    label: `${t("返り値")} (${props.signature.outputs[0].type})`,
    placeholder: generatePlaceholder(props.signature.outputs[0].type),
  };
  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
          {t("引数の入力")}
        </Typography>

        <Box mt={2}>
          <Grid container spacing={4}>
            {props.signature.inputs.map((input, i) => (
              <Grid key={input.name} item xs={12}>
                <InputControlTextField
                  name={`performanceTestCases.data.${props.index}.inputs.${i}`}
                  label={`${t("引数")} ${i + 1} (${input.type})`}
                  placeholder={generatePlaceholder(input.type)}
                  {...inputTextField}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>

      <Grid item xs={6}>
        <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
          {t("返り値の入力")}
        </Typography>

        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputControlTextField name={`performanceTestCases.data.${props.index}.outputs.0`} {...outputTextField} />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

AlgorithmInputFields.displayName = "AlgorithmInputFields";

export default AlgorithmInputFields;
