import { useTheme } from "@mui/material/styles";
import React from "react";

import { AdjustPosition, ComponentType, NetworkElement } from "../../../../features";
import { adjustorsFromElement } from "../../../../helpers/flowChart";
import Network from "../../Network/Network";

const adjustPositionMap: Record<number, AdjustPosition> = {
  0: "leftTop",
  1: "topMid",
  2: "rightTop",
  3: "rightMid",
  4: "rightBottom",
  5: "bottomMid",
  6: "leftBottom",
  7: "leftMid",
};

const directionCursorMap: Record<number, string> = {
  0: "nwse-resize",
  1: "ns-resize",
  2: "nesw-resize",
  3: "ew-resize",
  4: "nwse-resize",
  5: "ns-resize",
  6: "nesw-resize",
  7: "ew-resize",
};

export type DynamicNetworkProps = {
  network: NetworkElement;
  componentType: ComponentType;
  isSelected: boolean;
  isMoving: boolean;
  isShaping: boolean;
  onSelect: (e: React.MouseEvent<SVGElement, MouseEvent>, networkId: string) => void;
  onStartShapeNetwork: (e: React.MouseEvent<SVGElement, MouseEvent>, networkId: string, corner: AdjustPosition) => void;
};

export const DynamicNetwork: React.FC<DynamicNetworkProps> = React.memo((props: DynamicNetworkProps) => {
  const theme = useTheme();
  const {
    geometry: { minX, minY, maxX, maxY },
  } = props.network;
  const { leftTop, topMid, rightTop, rightMid, rightBottom, bottomMid, leftBottom, leftMid } = adjustorsFromElement(props.network);

  return (
    <g>
      <Network network={props.network} componentType={props.componentType} dashedLine={props.isSelected} />
      <rect
        x={minX}
        y={minY}
        width={maxX - minX}
        height={maxY - minY}
        fillOpacity={0}
        cursor={props.isMoving || props.isShaping ? "default" : "move"}
        onMouseDown={e => props.onSelect(e, props.network.id)}
      />
      {props.isSelected &&
        [leftTop, topMid, rightTop, rightMid, rightBottom, bottomMid, leftBottom, leftMid].map((adaptor, i) => (
          <rect
            key={`adjustor-${adjustPositionMap[i]}`}
            width={16}
            height={16}
            x={adaptor.x - 10}
            y={adaptor.y - 10}
            stroke={theme.palette.common.black}
            strokeWidth={1}
            fillOpacity={0}
            onMouseDown={e => props.onStartShapeNetwork(e, props.network.id, adjustPositionMap[i])}
            cursor={props.isShaping ? "default" : directionCursorMap[i]}
          />
        ))}
    </g>
  );
});

DynamicNetwork.displayName = "DynamicNetwork";

export default DynamicNetwork;
