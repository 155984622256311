// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as React from "react";

export const useLoadStyles = () => {
  React.useEffect(() => {
    import("./@lumino/widgets/style/index.css");
    import("./@jupyterlab/apputils/style/base.css");
    import("./@jupyterlab/codeeditor/style/base.css");
    import("./@jupyterlab/documentsearch/style/base.css");
    import("./@jupyterlab/outputarea/style/base.css");
    import("./@jupyterlab/completer/style/base.css");
    import("./@jupyterlab/codemirror/style/base.css");
    import("./@jupyterlab/cells/style/base.css");
    import("./@jupyterlab/notebook/style/base.css");
    import("./@jupyterlab/filebrowser/style/base.css");
    import("./@jupyterlab/theme-light-extension/style/theme.css");
    import("./@jupyterlab/theme-light-extension/style/variables.css");
    import("./@jupyterlab/ui-components/style/base.css");
    import("./@jupyter-widgets/base/css/index.css");
    import("./@jupyter-widgets/controls/css/widgets.css");
    import("./@jupyterlab/rendermime/style/base.css");
    import("./@jupyterlab/application/style/index.css");
  }, []);
};
