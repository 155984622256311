import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type EmphasizedTypographyProps = {
  emphasizedText: string;
  subtext: string;
};

const EmphasizedTypography: React.FC<EmphasizedTypographyProps> = props => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography fontSize={24} fontWeight={700} lineHeight="133.4%">
        {props.emphasizedText}
      </Typography>
      <Typography fontSize={14}>{props.subtext}</Typography>
    </Stack>
  );
};

EmphasizedTypography.displayName = "EmphasizedTypography";

export default EmphasizedTypography;
