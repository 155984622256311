import { useTranslation } from "@hireroo/i18n";
import * as React from "react";

import EntryOverviewSection, { EntryOverviewSectionProps } from "../../../../modules/EntryOverviewSection/EntryOverviewSection";

export type EntryAssessmentOverviewSectionProps = {
  name: string;
  companyName: string;
  willEndAt?: string;
  questionNum: number;
  timeLimit: string;
  message?: string;
};

const EntryAssessmentOverviewSection: React.FC<EntryAssessmentOverviewSectionProps> = props => {
  const { t } = useTranslation();
  const testEntryOverviewSection = React.useMemo((): EntryOverviewSectionProps => {
    return {
      title: t("テスト概要"),
      mode: "BORDER",
      emphasizedItems: [
        !!props.willEndAt && {
          title: t("提出期限"),
          content: `${props.willEndAt}`,
          strong: true,
          underline: true,
        },
        {
          title: t("問題数"),
          content: `${props.questionNum} ${t("問")}`,
          strong: true,
        },
        {
          title: t("制限時間"),
          content: props.timeLimit,
          strong: true,
        },
      ].reduce<Exclude<EntryOverviewSectionProps["emphasizedItems"], undefined>>((items, current) => {
        if (current) {
          items.push(current);
        }
        return items;
      }, []),
      items: [
        !!props.message && {
          title: t("企業からのメッセージ"),
          content: props.message,
        },
      ].reduce<EntryOverviewSectionProps["items"]>((items, current) => {
        if (current) {
          items.push(current);
        }
        return items;
      }, []),
    };
  }, [t, props]);

  return <EntryOverviewSection {...testEntryOverviewSection} />;
};

EntryAssessmentOverviewSection.displayName = "EntryAssessmentOverviewSection";

export default EntryAssessmentOverviewSection;
