export type Language = "ja" | "en";

export type TranslationMap<T extends string> = {
  [key in T]: Record<Language, string>;
};

export const translation: TranslationMap<"day" | "hours" | "minutes" | "seconds"> = {
  day: {
    ja: "日",
    en: "d",
  },
  hours: {
    ja: "時間",
    en: "h",
  },
  minutes: {
    ja: "分",
    en: "m",
  },
  seconds: {
    ja: "秒",
    en: "s",
  },
};
