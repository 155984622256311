/**
 * TODO Move this to @hireroo/project
 */
import * as z from "zod";

export const generateFrontendCommand = () => {
  return z.object({
    id: z.string(),
    action: z.string(),
    body: z.string(),
    condition: z.string(),
    expected: z.string(),
  });
};

export type FrontendCommandSchema = z.infer<ReturnType<typeof generateFrontendCommand>>;

export const generateFrontendFixtureCommand = () => {
  return z.object({
    id: z.string(),
    action: z.string(),
    body: z.string(),
  });
};

export type FrontendFixtureCommandSchema = z.infer<ReturnType<typeof generateFrontendCommand>>;

export const generateFrontendCorrectnessTestCase = () => {
  const command = generateFrontendCommand();
  const fixtureCommand = generateFrontendFixtureCommand();
  return z.object({
    test_case_commands: command.array(),
    setup_commands: fixtureCommand.array(),
    teardown_commands: fixtureCommand.array(),
    description_ja: z.string(),
    description_en: z.string(),
    is_hidden: z.boolean(),
  });
};

export type FrontendCorrectnessTestCaseSchema = z.infer<ReturnType<typeof generateFrontendCorrectnessTestCase>>;

export const generateFrontendCorrectnessTestCases = () => {
  const testCase = generateFrontendCorrectnessTestCase();
  return z.object({
    version: z.string(),
    test_cases: testCase.array(),
  });
};

export type FrontendCorrectnessTestCasesSchema = z.infer<ReturnType<typeof generateFrontendCorrectnessTestCases>>;

export const generateFrontendResultPerCommand = () => {
  return z.object({
    latency: z.number(),
    is_passed: z.boolean(),
    screenshot: z.string(),
  });
};

export type FrontendResultPerCommand = z.infer<ReturnType<typeof generateFrontendResultPerCommand>>;

export const generateFrontendCorrectnessTestCaseResult = () => {
  const result = generateFrontendResultPerCommand();
  return z.object({
    results: result.array(),
    is_passed: z.boolean(),
    is_hidden: z.boolean().optional(),
    status: z.enum(["EVALUATED", "FAILED"]),
    failure_reason: z.enum(["", "SERVER_HEALTH_CHECK_FAILED", "EVALUATION_PREPARATION_FAILED"]),
  });
};

export type FrontendCorrectnessTestCaseResultSchema = z.infer<ReturnType<typeof generateFrontendCorrectnessTestCaseResult>>;

export const generateFrontendCorrectnessTestCaseResults = () => {
  const result = generateFrontendCorrectnessTestCaseResult();
  return z.object({
    version: z.string(),
    test_results: result.array(),
  });
};

export type FrontendCorrectnessTestCaseResultsSchema = z.infer<ReturnType<typeof generateFrontendCorrectnessTestCaseResults>>;

export const generatePerformanceMetric = () => {
  return z.object({
    value: z.number(),
    score: z.number(),
  });
};

export type PerformanceMetricSchema = z.infer<ReturnType<typeof generatePerformanceMetric>>;

export const generateFrontendPerformanceTestCaseResult = () => {
  const performanceMetric = generatePerformanceMetric();
  return z.object({
    fcp: performanceMetric,
    si: performanceMetric,
    lcp: performanceMetric,
    tti: performanceMetric,
    tbt: performanceMetric,
    cls: performanceMetric,
  });
};

export type FrontendPerformanceTestCaseResultSchema = z.infer<ReturnType<typeof generateFrontendPerformanceTestCaseResult>>;

export const generateFrontendPerformanceTestCaseResults = () => {
  const result = generateFrontendPerformanceTestCaseResult();
  return z.object({
    version: z.string(),
    test_results: result.array(),
  });
};

export type FrontendPerformanceTestCaseResultsSchema = z.infer<ReturnType<typeof generateFrontendPerformanceTestCaseResults>>;
