import { App, Company, Payment } from "@hireroo/app-store/essential/employee";
import { BillingOverview } from "@hireroo/app-store/widget/e/BillingOverview";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import BillingOverviewContainer, { BillingOverviewContainerProps } from "./Container";

export type BillingOverviewFetchContainerProps = {};

const BillingOverviewFetchContainer: React.FC<BillingOverviewFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const activeCompanyId = Company.useStrictActiveCompanyId();
  const customer = Payment.useCustomerV2();
  const [result, refresh] = Graphql.useGetBillingOverviewQuery({
    variables: {
      customerId: activeCompanyId,
      startedAtSeconds: customer?.subscription?.didStartAtSeconds ?? 0,
      /**
       * Assume 12 since there is currently no place to specify and there is no such thing as a two-year contract.
       */
      contractPeriod: 12,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "cache-and-network",
  });
  const initialized = BillingOverview.useInitialized();

  React.useEffect(() => {
    if (result.data?.customer) {
      BillingOverview.initialize(result.data.customer, result.data.contractPeriodUsagesV2.contractPeriodUsages);
    }
  }, [result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: BillingOverviewContainerProps = {};

  return <BillingOverviewContainer {...containerProps} />;
};

BillingOverviewFetchContainer.displayName = "BillingOverviewFetchContainer";

export default withErrorBoundary(BillingOverviewFetchContainer, {});
