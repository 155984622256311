import { useTranslation } from "@hireroo/i18n";
import Cancel from "@mui/icons-material/Cancel";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import PlayArrow from "@mui/icons-material/PlayArrow";
import RadioButtonUncheckedOutlined from "@mui/icons-material/RadioButtonUncheckedOutlined";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type Status = "LOADING" | "SUCCEEDED" | "FAILED" | "DEFAULT";

const statusIconMap: Record<Status, React.ReactNode> = {
  LOADING: <CircularProgress color="secondary" size={20} />,
  FAILED: <Cancel color="error" fontSize="small" />,
  SUCCEEDED: <CheckCircleOutline color="success" fontSize="small" />,
  DEFAULT: <RadioButtonUncheckedOutlined color="disabled" fontSize="inherit" />,
};

export type TestCaseTableRowProps = {
  status: Status;
  title: string;
  runTestCaseButton: Pick<ButtonProps, "onClick">;
  failureDescription?: string;
  TestResultDescription?: React.ReactNode;
  forceOpen?: boolean;
  TestResult?: React.ReactNode;
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&.details:not(:last-child)": {
    borderBottom: "1px solid",
    borderColor: theme.palette.Other.Divider,
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  borderBottom: "unset",
}));

const TestCaseTableRow: React.FC<TestCaseTableRowProps> = props => {
  const { status } = props;
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    if (props.forceOpen) {
      setOpen(true);
    }
  }, [props.forceOpen]);
  const expandRowButton: IconButtonProps = {
    "aria-label": "expand row",
    size: "small",
    onClick: () => setOpen(prev => !prev),
    disabled: status === "DEFAULT",
    children: open ? <KeyboardArrowUp /> : <KeyboardArrowDown />,
  };
  const runTestCaseButton: ButtonProps = {
    ...props.runTestCaseButton,
    color: "secondary",
    disabled: props.status === "LOADING",
    startIcon: <PlayArrow />,
    sx: {
      whiteSpace: "nowrap",
    },
  };

  return (
    <>
      <StyledTableRow>
        <StyledTableCell>
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            {statusIconMap[props.status]}
          </Box>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" width="100%" sx={{ paddingLeft: 0 }}>
          <Typography variant={"subtitle1"}>{props.title}</Typography>
        </StyledTableCell>
        <StyledTableCell align={"right"} sx={{ paddingLeft: 0 }}>
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Button {...runTestCaseButton}>{t("実行")}</Button>
          </Box>
        </StyledTableCell>
        <StyledTableCell sx={{ paddingLeft: 0 }}>
          <IconButton {...expandRowButton} />
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow className="details">
        <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box m={1} p={1}>
              {props.TestResult && (
                <Box mt={1} maxWidth={800}>
                  {props.TestResult}
                </Box>
              )}
              {props.failureDescription && (
                <Box mt={2}>
                  <Typography>{props.failureDescription}</Typography>
                </Box>
              )}
            </Box>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

TestCaseTableRow.displayName = "TestCaseTableRow";

export default TestCaseTableRow;
