import * as Graphql from "@hireroo/graphql/client/urql";

import * as Types from "../questions/types";

export const SortFields = {
  CREATED_AT_DESCENDING: "created-at-descending",
  CREATED_AT_ASCENDING: "created-at-ascending",
} satisfies Record<string, Types.SortFieldValue>;

export const SortParams = {
  [SortFields.CREATED_AT_DESCENDING]: { sortMethod: "METHOD_CREATED_AT", isDescending: true },
  [SortFields.CREATED_AT_ASCENDING]: { sortMethod: "METHOD_CREATED_AT", isDescending: false },
} satisfies Record<
  string,
  {
    isDescending: boolean;
    sortMethod: Graphql.QuestionPackageSortMethod;
  }
>;

export const DefaultPager: Types.Pager = {
  page: 0,
  size: 20,
  offset: 0,
  sortFieldValue: SortFields.CREATED_AT_DESCENDING,
};

// The keys of query params
export const QueryKeys = {
  PAGE: "page",
  SIZE: "size",
  TITLE: "title",
  DIFFICULTY: "difficulty",
  SORT: "sorting",
} as const;
export type QueryType = (typeof QueryKeys)[keyof typeof QueryKeys];
