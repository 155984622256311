import * as z from "zod";

export const useEvaluationSchema = () => {
  return z.object({
    comment: z.string(),
    metricId: z.preprocess(v => Number(v), z.number()),
    numStars: z.preprocess(v => Number(v), z.number()),
  });
};

export type EvaluationSchema = z.infer<ReturnType<typeof useEvaluationSchema>>;

export const useFeedbackSchema = () => {
  return z.object({
    comment: z.string(),
    isRecommended: z.boolean(),
  });
};

export type FeedbackSchema = z.infer<ReturnType<typeof useFeedbackSchema>>;

export const useTechnicalCommentFormSchema = () => {
  const evaluationSchema = useEvaluationSchema();
  return z.object({
    step: z.number(),
    metricGroupId: z.number().optional(),
    comment: z.string(),
    isRecommended: z.boolean().optional(),
    evaluations: z.array(evaluationSchema),
  });
};

export type TechnicalCommentFormSchema = z.infer<ReturnType<typeof useTechnicalCommentFormSchema>>;

export const useTechnicalCommentStepForPromptSchema = () => {
  return z.number();
};
export type TechnicalCommentStepForPromptSchema = z.infer<ReturnType<typeof useTechnicalCommentStepForPromptSchema>>;

export const useTechnicalCommentStepSchema = () => {
  return z.union([z.literal("Evaluations"), z.literal("Feedback"), z.literal("Confirmation")]);
};
export type TechnicalCommentStepSchema = z.infer<ReturnType<typeof useTechnicalCommentStepSchema>>;

export const useRemoteEvaluationsSchema = () => {
  const evaluationSchema = useEvaluationSchema();
  return z.record(z.string(), evaluationSchema);
};

export type RemoteEvaluationsSchema = z.infer<ReturnType<typeof useRemoteEvaluationsSchema>>;

export const useRemoteTechnicalCommentFormSchemaForLocalStorage = () => {
  const stepSchema = useTechnicalCommentStepSchema();
  const stepForPromptSchema = useTechnicalCommentStepForPromptSchema();
  const evaluationsSchema = useRemoteEvaluationsSchema();
  const feedbackSchema = useFeedbackSchema();
  return z.object({
    stepForPrompt: stepForPromptSchema.optional(),
    step: stepSchema.optional(),
    schemas: z
      .object({
        evaluations: evaluationsSchema.optional(),
        feedback: feedbackSchema.optional(),
      })
      .optional(),
  });
};

export const useRemoteTechnicalCommentFormSchema = () => {
  const stepSchema = useTechnicalCommentStepSchema();
  const stepForPromptSchema = useTechnicalCommentStepForPromptSchema();
  const evaluationsSchema = useRemoteEvaluationsSchema();
  const feedbackSchema = useFeedbackSchema();
  return z.object({
    stepForPrompt: stepForPromptSchema,
    step: stepSchema,
    schemas: z.object({
      evaluations: evaluationsSchema,
      feedback: feedbackSchema,
    }),
  });
};

export type RemoteTechnicalCommentFormSchema = z.infer<ReturnType<typeof useRemoteTechnicalCommentFormSchema>>;
