import { calculateRemainingTime } from "@hireroo/formatter/calculator";
import * as Sentry from "@sentry/browser";

import { state } from "./State";
import type * as Types from "./types";

export type InitializeParams = {
  /** duration */
  timeLimitSeconds: number;
  /** DateTime */
  didStartAtSeconds: number;
  /** DateTime */
  willEndAtSeconds: number;
};

export const clear = () => {
  state.status = "STOP";
};

/** @deprecated */
export const initialize = (params: InitializeParams): void => {
  const now = new Date();
  state.remainTimeSeconds = calculateRemainingTime(
    now.getTime() / 1000,
    params.didStartAtSeconds,
    params.timeLimitSeconds,
    params.willEndAtSeconds,
  );
  state.status = "RUNNING";
  state.debugParams = params;
  state.timeLimitSeconds = params.timeLimitSeconds;
};

export const initialize2 = (params: Pick<Types.State, "remainTimeSeconds" | "timeLimitSeconds">): void => {
  state.status = "RUNNING";
  state.remainTimeSeconds = params.remainTimeSeconds;
  state.timeLimitSeconds = params.timeLimitSeconds;
};

export const updateStatus = (status: Types.Status): void => {
  state.status = status;
  if (state.status === "TIMEOUT" && state.debugParams) {
    const now = new Date();
    const verityRemainTime = calculateRemainingTime(
      now.getTime() / 1000,
      state.debugParams.didStartAtSeconds,
      state.debugParams.timeLimitSeconds,
      state.debugParams.willEndAtSeconds,
    );
    if (verityRemainTime > 5) {
      Sentry.captureMessage([`[TIME LIMIT WARNING]`, `Time is off by ${verityRemainTime} seconds.`].join("\n"), "warning");
    }
  }
};
