import { useTranslation } from "@hireroo/i18n";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import ScoreBar, { ScoreBarProps } from "../../../../primitive/ScoreBar/ScoreBar";
import DifficultyStars, { DifficultyStarsProps } from "../../../DifficultyStars/DifficultyStars";
import ScorePieChart, { ScorePieChartProps } from "../../../ScorePieChart/ScorePieChart";

type Status = "EVALUATED" | "ERROR" | "EVALUATING";
const StyledWrapper = styled(Stack)(({ theme }) => ({
  width: "100%",
  display: "flex",
  borderRadius: "12px",
  backgroundColor: theme.palette["Background/Paper"].p16,
  border: "solid 1px",
  borderColor: theme.palette.divider,
  padding: "16px",
}));

export type ScoreBoardDetailItemProps = {
  id: string;
  title: string;
  variantText: string;
  difficulty: DifficultyStarsProps["difficulty"];
  scoreChart?: ScorePieChartProps;
  scoreBars?: ScoreBarProps[];
  status: Status;
  detailButton: Pick<ButtonProps, "onClick">;
};

const ScoreBoardDetailItem: React.FC<ScoreBoardDetailItemProps> = props => {
  const { t } = useTranslation();
  const StatusMap = {
    EVALUATING: (
      <Alert severity="info" variant="outlined">
        {t("提出された問題の採点中です。")}
      </Alert>
    ),
    ERROR: (
      <Alert severity="error" variant="outlined">
        {t("採点失敗")}
      </Alert>
    ),
    EVALUATED: (
      <Stack display="flex" direction="column" width="70%" justifyItems="center" m="0 auto" spacing={1}>
        {props.scoreBars?.map(scoreBar => <ScoreBar key={`${scoreBar.title}-key`} {...scoreBar} />)}
      </Stack>
    ),
  } satisfies Record<Status, React.ReactNode>;
  return (
    <StyledWrapper direction="column" spacing={2}>
      <Stack direction="row" width="100%" display="flex" justifyContent="space-between">
        <Stack direction="column">
          <Box>
            <Typography fontWeight={700} maxWidth="300px">
              {props.title}
            </Typography>
          </Box>

          <Stack
            display="flex"
            direction="row"
            height="30px"
            divider={<Divider orientation="vertical" variant="middle" />}
            spacing={2}
            alignItems="center"
          >
            <Typography variant="caption" noWrap>
              {props.variantText}
            </Typography>
            <DifficultyStars difficulty={props.difficulty} />
          </Stack>
        </Stack>
        <Box mr={2}>{props.status === "EVALUATED" && props.scoreChart && <ScorePieChart {...props.scoreChart} />}</Box>
      </Stack>
      <Box>{StatusMap[props.status]}</Box>
      <Stack justifyItems="center">
        <Button {...props.detailButton} color="secondary" endIcon={<ArrowForwardIosIcon />}>
          {t("詳細を見る")}
        </Button>
      </Stack>
    </StyledWrapper>
  );
};

ScoreBoardDetailItem.displayName = "ScoreBoardDetailItem";

export default ScoreBoardDetailItem;
