import OpenAILogo from "@hireroo/ui-assets/images/OpenAI/Logo.png";
import PersonIcon from "@mui/icons-material/Person";
import MuiAvatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import * as React from "react";

export type AvatarProps = {
  role: "USER" | "ASSISTANT";
};

const UserAvatar = styled(MuiAvatar)(({ theme }) => ({
  color: "white",
  backgroundColor: theme.palette.text.secondary,
  width: "24px",
  height: "24px",
}));

const ChatGPTAvatar = styled(MuiAvatar)(() => ({
  backgroundColor: "#10a37f",
  width: "24px",
  height: "24px",
  ".MuiAvatar-img": {
    width: "55%",
    height: "55%",
  },
}));

const Avatar: React.FC<AvatarProps> = props => {
  switch (props.role) {
    case "USER":
      return (
        <UserAvatar>
          <PersonIcon sx={{ fontSize: 20 }} />
        </UserAvatar>
      );
    case "ASSISTANT":
      return <ChatGPTAvatar src={OpenAILogo}></ChatGPTAvatar>;
    default:
      throw new Error(`invalid type: ${props.role satisfies never}`);
  }
};

Avatar.displayName = "Avatar";

export default Avatar;
