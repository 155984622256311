import { state } from "./State";
import * as Types from "./types";

export const setCompany = (company: Types.Company): void => {
  state.company = company;
};

export const setSelectedGroupIds = (groupIds: string[]) => {
  state.selectedGroupIds = groupIds;
};

export const selectGroup = (groupId: string) => {
  state.selectedGroupIds.push(groupId);
};
export const unselectGroup = (groupId: string) => {
  state.selectedGroupIds = state.selectedGroupIds.filter(id => id !== groupId);
};

export const EditGroupAction = {
  setGroupId: (groupId: string | null): void => {
    state.editGroup.selectedGroupId = groupId;
  },
};

export const DeleteGroupAction = {
  setGroupId: (groupId: string | null): void => {
    state.deleteGroup.selectedGroupId = groupId;
  },
};

export const RemoveMemberAction = {
  setGroupId: (groupId: string | null): void => {
    state.removeMembers.selectedGroupId = groupId;
  },
  clear: (): void => {
    state.removeMembers.selectedEmployeeIds = [];
    state.removeMembers.searchText = "";
  },
  removeSelectedId: (employeeId: string) => {
    state.removeMembers.selectedEmployeeIds = state.removeMembers.selectedEmployeeIds.filter(id => id !== employeeId);
  },
  updateSearchText: (searchText: string) => {
    state.removeMembers.searchText = searchText;
  },
  addId: (addId: string) => {
    state.removeMembers.selectedEmployeeIds.push(addId);
  },
};

export const AddMemberToGroupAction = {
  setGroupId: (groupId: string | null): void => {
    state.addMembers.selectedGroupId = groupId;
  },
  clear: (): void => {
    state.addMembers.selectedEmployeeIds = [];
  },
  addId: (employeeId: string): void => {
    state.addMembers.selectedEmployeeIds.push(employeeId);
  },
  removeId: (employeeId: string): void => {
    state.addMembers.selectedEmployeeIds = state.addMembers.selectedEmployeeIds.filter(id => id !== employeeId);
  },
  updateSearchText: (searchText: string) => {
    state.addMembers.searchText = searchText;
  },
};

export const CreateGroupAction = {
  addSelectedId: (employeeId: string): void => {
    state.createGroup.selectedEmployeeIds.push(employeeId);
  },
  removeId: (employeeId: string): void => {
    state.createGroup.selectedEmployeeIds = state.createGroup.selectedEmployeeIds.filter(id => id !== employeeId);
  },
  clear: (): void => {
    state.createGroup.selectedEmployeeIds = [];
  },
};

export const updateDialogStatus = (status: Types.DialogStatus): void => {
  state.dialogStatus = status;
};

export const updateFilterText = (text: string): void => {
  state.filterText = text;
};

export const clear = () => {
  state.filterText = "";
  state.selectedGroupIds = [];
};
