import { useTranslation } from "@hireroo/i18n";
import AddOutlined from "@mui/icons-material/AddOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PlaylistAddOutlinedIcon from "@mui/icons-material/PlaylistAddOutlined";
import Box from "@mui/material/Box";
import Button, { type ButtonProps } from "@mui/material/Button";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import * as React from "react";

import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "../../../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";

export type MetricsHeaderProps = {
  createButton: Pick<ButtonProps, "onClick">;
  checkbox: Pick<CheckboxProps, "disabled" | "title" | "checked" | "onChange" | "indeterminate">;
  addButton: Pick<IconButtonWithTooltipProps, "onClick" | "disabled">;
  deleteButton: Pick<IconButtonWithTooltipProps, "onClick" | "disabled">;
};

const MetricsHeader: React.FC<MetricsHeaderProps> = props => {
  const { t } = useTranslation();
  const checkbox: CheckboxProps = {
    ...props.checkbox,
    sx: {
      width: "36px",
      height: "36px",
    },
    color: "secondary",
    size: "small",
  };
  const addButton: IconButtonWithTooltipProps = {
    ...props.addButton,
    title: t("評価指標に追加"),
    children: <PlaylistAddOutlinedIcon fontSize="small" />,
  };
  const deleteButton: IconButtonWithTooltipProps = {
    ...props.deleteButton,
    title: t("削除"),
    children: <DeleteOutlineIcon fontSize="small" />,
  };
  const createButton: ButtonProps = {
    ...props.createButton,
    variant: "contained",
    color: "secondary",
    sx: {
      wordBreak: "keep-all",
    },
    startIcon: <AddOutlined fontSize="small" />,
    children: t("作成する"),
  };
  return (
    <Stack direction="row" spacing={1.5} px={1.5} alignItems="center" mb={1.5}>
      <Checkbox {...checkbox} />
      <Box display="flex" alignItems="center">
        <IconButtonWithTooltip key="add-button" {...addButton} />
      </Box>
      <Box display="flex" alignItems="center">
        <IconButtonWithTooltip key="delete-button" {...deleteButton} />
      </Box>
      <Button {...createButton} />
    </Stack>
  );
};

MetricsHeader.displayName = "MetricsHeader";

export default MetricsHeader;
