import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import ReadonlyCustomFormVariable, {
  ReadonlyCustomFormVariableProps,
} from "../../../../ms-components/Spot/ReadonlyCustomFormVariable/ReadonlyCustomFormVariable";
import ListContent from "../../../ScreeningTestOverview/parts/ListContent";
import { ListContentProps } from "../ListContent/ListContent";

const StyledTypography = styled(Typography)(() => ({
  fontSize: "14px",
  variant: "subtitle2",
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette["Background/Paper"].p2,
  borderRadius: "16px",
}));

export type ScreeningInfoProps = {
  data: {
    submissionDeadline: string;
    allowHint: string;
    timeLimit: string;
    description?: string;
    behavioralControl: string[] | null;
    createdAt: string;
    createdBy: string;
  };
  customFormVariables: ReadonlyCustomFormVariableProps[];
};

const ScreeningInfo: React.FC<ScreeningInfoProps> = props => {
  const { t } = useTranslation();
  const { data } = props;

  const basicListContents: ListContentProps[] = [
    {
      title: t("説明文"),
      children: <StyledTypography>{data.description || t("未入力")}</StyledTypography>,
    },
    ...(data.behavioralControl
      ? ([
          {
            title: t("アシスト機能"),
            children: <StyledTypography>{data.behavioralControl.length > 0 ? data.behavioralControl.join(", ") : t("なし")}</StyledTypography>,
          },
        ] satisfies ListContentProps[])
      : []),
    {
      title: t("ヒント機能"),
      children: <StyledTypography>{data.allowHint}</StyledTypography>,
    },
    {
      title: t("制限時間"),
      children: <StyledTypography>{data.timeLimit}</StyledTypography>,
    },
    {
      title: t("カスタムフォーム"),
      children: (
        <Stack direction="column" spacing={1.5}>
          {props.customFormVariables.length === 0 && <StyledTypography>{t("なし")}</StyledTypography>}
          {props.customFormVariables.map((variable, index) => (
            <ReadonlyCustomFormVariable key={`${variable.label}-${index}`} {...variable} />
          ))}
        </Stack>
      ),
    },
    {
      title: t("提出期限"),
      children: <StyledTypography>{data.submissionDeadline}</StyledTypography>,
    },
    {
      title: t("コーディングテスト作成者"),
      children: <StyledTypography>{data.createdBy}</StyledTypography>,
    },
    {
      title: t("コーディングテスト作成日"),
      children: <StyledTypography>{data.createdAt}</StyledTypography>,
    },
  ];

  const listContents: ListContentProps[] = [...basicListContents];
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Typography fontSize={16} sx={{ fontWeight: "bold" }}>
          {t("コーディングテスト情報")}
        </Typography>
      </Box>
      <StyledBox>
        <List component="nav" aria-label="coding-test-summary" sx={{ px: 2 }}>
          {listContents.map((content, index) => (
            <ListContent key={content.title} {...content} divider={index !== listContents.length - 1} />
          ))}
        </List>
      </StyledBox>
    </Box>
  );
};

ScreeningInfo.displayName = "ScreeningInfo";

export default ScreeningInfo;
