import { AlgorithmResourceEditor } from "@hireroo/app-store/view-domain/AlgorithmResourceEditor";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import * as React from "react";

export type CodeAction = {
  runCode: (variables: Graphql.AlgorithmRunCodeQueryVariables, requestId: string) => Promise<Graphql.AlgorithmRunCodeQuery>;
  profileCode: (variables: Graphql.AlgorithmRunCodeQueryVariables, requestId: string) => Promise<Graphql.AlgorithmProfileCodeQuery>;
};
export const useCodeAction = (): CodeAction => {
  const client = getGraphqlClient();
  const runCode = React.useCallback(
    async (variables: Graphql.AlgorithmRunCodeQueryVariables, requestId: string) => {
      AlgorithmResourceEditor.updateIsRunCodeLoading(requestId, true);
      return await client
        .AlgorithmRunCode(variables)
        .then(res => {
          AlgorithmResourceEditor.updateRunCodeOutput(requestId, res.algorithmRunCode);
          return res;
        })
        .finally(() => {
          AlgorithmResourceEditor.updateIsRunCodeLoading(requestId, false);
        });
    },
    [client],
  );
  const profileCode = React.useCallback(
    async (variables: Graphql.AlgorithmRunCodeQueryVariables, requestId: string) => {
      AlgorithmResourceEditor.updateIsProfileCodeLoading(requestId, true);
      return await client
        .AlgorithmProfileCode(variables)
        .then(res => {
          AlgorithmResourceEditor.updateProfileCodeOutput(requestId, res.algorithmProfileCode);
          return res;
        })
        .finally(() => {
          AlgorithmResourceEditor.updateIsProfileCodeLoading(requestId, false);
        });
    },
    [client],
  );

  return {
    runCode,
    profileCode,
  };
};
