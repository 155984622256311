import { LiveCoding } from "@hireroo/app-helper/hooks";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type RemoteInterviewEmployeeHeaderContainerProps = {
  remoteId: string;
  collaborativeAction: LiveCoding.CollaborativeAction;
};

const RemoteInterviewEmployeeHeaderContainer: React.FC<RemoteInterviewEmployeeHeaderContainerProps> = props => {
  const remoteInterviewEmployeeHeaderProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.RemoteInterviewEmployeeHeader {...remoteInterviewEmployeeHeaderProps} />
    </ErrorBoundary>
  );
};

RemoteInterviewEmployeeHeaderContainer.displayName = "RemoteInterviewEmployeeHeaderContainer";

export default withErrorBoundary(RemoteInterviewEmployeeHeaderContainer, {});
