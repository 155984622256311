import MuiTreeItem, { TreeItemProps as MuiTreeItemProps } from "@mui/lab/TreeItem";
import { styled } from "@mui/material/styles";
import * as React from "react";

export type TreeItemProps = MuiTreeItemProps;

const StyledTreeItem = styled(MuiTreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  ".MuiTreeItem-content.Mui-selected": {
    backgroundColor: theme.palette["Secondary/Shades"].p8,
    color: theme.palette.text.primary,
  },
  ".MuiTreeItem-content.Mui-selected:hover": {
    backgroundColor: theme.palette["Secondary/Shades"].p16,
  },
  ".MuiTreeItem-content.Mui-selected.Mui-focused": {
    backgroundColor: theme.palette["Secondary/Shades"].p16,
  },
  ".MuiTreeItem-content:hover": {
    backgroundColor: theme.palette["Secondary/Shades"].p8,
  },
  ".Mui-selected": {
    backgroundColor: theme.palette["Secondary/Shades"].p16,
  },
  ".Mui-selected:hover": {
    backgroundColor: theme.palette["Secondary/Shades"].p16,
  },
  ".Mui-focused": {
    backgroundColor: theme.palette["Secondary/Shades"].p16,
  },
  ".Mui-focused:hover": {
    backgroundColor: theme.palette["Secondary/Shades"].p16,
  },
}));

const TreeItem = React.forwardRef((props: TreeItemProps, ref: React.Ref<unknown>) => {
  return <StyledTreeItem {...props} ref={ref} />;
});

TreeItem.displayName = "TreeItem";

export default TreeItem;
