import { proxy } from "valtio";
import { proxyMap } from "valtio/utils";

import type * as Types from "./types";

export const state = proxy<Types.State>({
  metricMap: proxyMap(),
  dialogStatus: "CLOSE",
  pagination: {
    size: 20,
    page: 0,
    count: 0,
    pager: null,
    nextPager: null,
  },
  selectedItemIds: [],
});
