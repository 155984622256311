import * as Graphql from "@hireroo/graphql/client/urql";

import { defaultState } from "./constants";

export const STATUS_REVERSE_MAP: Record<string, Graphql.ScreeningInviteeStatus> = {
  [Graphql.ScreeningInviteeStatus.Accepted]: Graphql.ScreeningInviteeStatus.Accepted,
  [Graphql.ScreeningInviteeStatus.Invited]: Graphql.ScreeningInviteeStatus.Invited,
};

export const convertStringsToStatuses = (values: string[]): Graphql.ScreeningInviteeStatus[] => {
  const newStatuses: Graphql.ScreeningInviteeStatus[] = [];

  for (const value of values) {
    value.split("%").forEach(v => {
      // Check if the string value can be converted to a valid status.
      if (STATUS_REVERSE_MAP[v]) {
        newStatuses.push(STATUS_REVERSE_MAP[v]);
      }
    });
  }
  return newStatuses;
};

export const convertStringToIsDesc = (value: string | null): boolean => {
  if (value === null) return true;
  try {
    // "sortMethod+isDesc" (e.g.) "CREATED_AT-true"
    const [, isDesc] = window.btoa(value).split("-");
    return isDesc === "true";
  } catch (_) {
    return false;
  }
};
export const convertStringToPage = (value: string | null): number => {
  if (value === null) return 0;
  const page = Number(value);
  // Set it to 0 if it is invalid (NaN or negative). Note that page > 0 is false if page is NaN.
  // Actual (zero-index) page number = (page number from the query param) - 1
  if (!Number.isInteger(page) || page < 1) {
    return 0;
  }
  return page - 1;
};

const DEFAULT_SIZE = defaultState.listParams.size;
const SIZES = [20, 30, 50];
export const convertStringToSize = (value: string | null) => {
  if (value === null) return DEFAULT_SIZE;
  const size = Number(value);
  // Set it to the default value if it is invalid.
  if (!SIZES.includes(size)) {
    return DEFAULT_SIZE;
  }
  return size;
};
