import { App, Company } from "@hireroo/app-store/essential/employee";
import { EvaluationMetrics } from "@hireroo/app-store/widget/e/EvaluationMetrics";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import EvaluationMetricsContainer, { EvaluationMetricsContainerProps } from "./Container";

export type EvaluationMetricsFetchContainerProps = {};

const EvaluationMetricsFetchContainer: React.FC<EvaluationMetricsFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const companyId = Company.useStrictActiveCompanyId();
  const pagination = EvaluationMetrics.usePagination();
  const nextPager = EvaluationMetrics.useNextPager();
  const [result, refresh] = Graphql.useListEvaluationMetricsForEvaluationMetricsQuery({
    variables: {
      companyId: companyId,
      size: pagination.size,
      cursor: nextPager?.cursor ?? null,
      withCount: true,
    },
    requestPolicy: "network-only",
    pause: appStatus !== "INITIALIZED",
  });

  React.useEffect(() => {
    if (result.data?.evaluationMetricsByCompany?.metrics) {
      EvaluationMetrics.initialize(result.data.evaluationMetricsByCompany);
    }
  }, [result.data]);

  React.useEffect(() => {
    return () => {
      EvaluationMetrics.clear();
    };
  }, []);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED") {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: EvaluationMetricsContainerProps = {
    refresh: refresh,
  };

  return <EvaluationMetricsContainer {...containerProps} />;
};

EvaluationMetricsFetchContainer.displayName = "EvaluationMetricsFetchContainer";

export default withErrorBoundary(EvaluationMetricsFetchContainer, {});
