import ScorePieChart, { ScorePieChartProps } from "@hireroo/charts/react/v2/ScorePieChart";
import { useTranslation } from "@hireroo/i18n";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import WarningIcon from "@mui/icons-material/Warning";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import CopyToClipboard from "react-copy-to-clipboard";

import SymbolButton, { SymbolButtonProps } from "../../primitive/Button/SymbolButton/SymbolButton";
import Link, { LinkProps } from "../../primitive/Link/Link";
import EntityScoreSelector, { EntityScoreSelectorProps } from "../EntityScoreSelector/EntityScoreSelector";

const StyledWarningIcon = styled(WarningIcon)(({ theme }) => ({
  color: theme.palette.warning.main,
  paddingRight: theme.spacing(1),
}));

export type ReportDetailSummaryProps = {
  scorePieChart?: ScorePieChartProps;
  status: "READY" | "NOT_READY";
  entityScoreSelector: EntityScoreSelectorProps;
  enableStaticContent: boolean;
  StatisticsContent: React.ReactNode;
  helpLink: Pick<LinkProps, "onClick">;
  linkButton?: Pick<SymbolButtonProps, "onClick"> & { url: string };
  showSuspiciousBehaviorText: boolean;
};

const ReportDetailSummary: React.FC<ReportDetailSummaryProps> = props => {
  const { t } = useTranslation();
  return (
    <Box>
      <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography id="summary" fontSize={16} sx={{ fontWeight: "bold" }}>
          {t("サマリー")}
        </Typography>
        {props.linkButton && (
          <CopyToClipboard text={props.linkButton.url}>
            <SymbolButton size="small" {...props.linkButton} variant="OUTLINE">
              <InsertLinkOutlinedIcon />
            </SymbolButton>
          </CopyToClipboard>
        )}
      </Stack>
      {props.showSuspiciousBehaviorText && (
        <Box pb={3} alignItems="center" display="flex">
          <StyledWarningIcon />
          <Typography>{t("この候補者から不審な行動がみつかりました。")}</Typography>
        </Box>
      )}
      <Box>
        <Stack direction="column" spacing={2} width="100%">
          <Stack direction="row" spacing={2} width="100%">
            {props.scorePieChart && (
              <Box>
                <React.Suspense>
                  <ScorePieChart {...props.scorePieChart} />
                </React.Suspense>
              </Box>
            )}
            <EntityScoreSelector {...props.entityScoreSelector} />
          </Stack>
          {props.enableStaticContent && (
            <Box width="100%">
              {props.status === "NOT_READY" && (
                <Box mt={1}>
                  <Typography>{t("採点が完了していないため相対評価を表示できません。")}</Typography>
                </Box>
              )}
              {props.status === "READY" && <>{props.StatisticsContent}</>}
            </Box>
          )}
        </Stack>
        <Box textAlign="right" my={1}>
          <Typography variant="caption">
            <Link {...props.helpLink} underline={"hover"} color="secondary">{`${t("レポートの見方はこちら")}`}</Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

ReportDetailSummary.displayName = "ReportDetailSummary";

export default ReportDetailSummary;
