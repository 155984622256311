/* eslint no-prototype-builtins: 0 */
import { MAX_SCALE, MIN_SCALE } from "../constants/flowChart";
import {
  AdjustPosition,
  Area,
  CacheSettings,
  CommentElement,
  CommentSettings,
  ComponentType,
  Coordinate,
  DefaultSettings,
  EdgeElement,
  EdgeSettings,
  ELEMENT_LABEL,
  ELEMENT_TYPE,
  ElementLabel,
  ElementType,
  FlowElement,
  NetworkElement,
  NodeElement,
  QueueSettings,
  Settings,
  SqlSettings,
  UnionSettingsFields,
  VmSettings,
} from "../features/types";
import { createComment, createEdge, createElement, geometryFromElement, viewboxFromElements } from "../helpers/flowChart";
import { createDefaultState } from "./constants";
import { multiState } from "./State";
import type * as Types from "./types";

export type RestoreElementsArgs = { elements: Record<string, FlowElement>; elementIds: string[] };
export type ResetElementsArgs = { elements: FlowElement[]; timestamp: number };
export type AddElementArgs = {
  id: string;
  type: ElementType;
  label: ElementLabel;
  initialSettings: Settings;
  geometry: Area;
  timestamp: number;
};
export type UpdateSettingsArgs = { id: string; updates: UnionSettingsFields; timestamp: number };
export type SelectElementArgs = { ids: string[] };
export type SelectMoreArgs = { id: string };
export type MoveElementsArgs = { ids: string[]; dx: number; dy: number };
export type DeleteElementArgs = { ids: string[] };
export type ReviveElementArgs = { ids: string[] };
export type ShapeElementArgs = { id: string; dx: number; dy: number; adjustPosition: AdjustPosition };
export type PasteElementArgs = { elements: FlowElement[] };
export type ConnectNodesArgs = { id: string; source: string; target: string; timestamp: number };
export type ReconnectEdgeArg = { id: string; source: string; target: string; timestamp: number };
export type AddCommentArgs = { id: string; content: string; geometry: Area; fontSize: number; timestamp: number };
export type EditCommentArgs = { id: string; content: string; timestamp: number };
export type SetCommentAreaArgs = { id: string; maxX: number; maxY: number };
export type ZoomViewboxArgs = { scale: number; focus?: Coordinate };
export type PanViewboxArg = { dx: number; dy: number };
export type UpdateAspectRatioArgs = { aspectRatio: number };

export const createAction = (state: Types.State) => {
  const initialState = createDefaultState({});
  return {
    /**
     * set current state for external action
     * @param id: state id
     */
    setGlobalState: (id: string) => {
      multiState.set(id, state);
    },
    updateComponentType: (componentType: ComponentType) => {
      state.componentType = componentType;
    },
    updateSelectableComponentType: (componentTypes: ComponentType[]) => {
      componentTypes.forEach(componentType => {
        state.selectableComponentTypes.add(componentType);
      });
    },
    // Restore the flowchart from histories retrieved in bulk from firebase
    restoreElements: (actionPayload: RestoreElementsArgs) => {
      state.elements = actionPayload.elements;
      state.elementIds = actionPayload.elementIds;
      state.selectedElementIds = initialState.selectedElementIds;
      state.copiedElementIds = initialState.copiedElementIds;
      state.pasteCount = initialState.pasteCount;
      state.scale = initialState.scale;
      if (actionPayload.elementIds.length > 0) {
        state.viewbox = viewboxFromElements(
          actionPayload.elementIds.map(id => actionPayload.elements[id]),
          state.aspectRatio,
          undefined,
          5,
        );
      }
    },
    // Reset the flowchart with the initial elements
    resetElements: (actionPayload: ResetElementsArgs) => {
      const initialElements: Record<string, FlowElement> = {};
      const initialElementIds: string[] = [];
      actionPayload.elements.forEach(element => {
        initialElements[element.id] = element;
        initialElementIds.push(element.id);
      });
      state.elements = initialElements;
      state.elementIds = initialElementIds;
      state.selectedElementIds = initialState.selectedElementIds;
      state.copiedElementIds = initialState.copiedElementIds;
      state.pasteCount = initialState.pasteCount;
      state.scale = initialState.scale;
      if (actionPayload.elements.length > 0) {
        state.viewbox = viewboxFromElements(actionPayload.elements, state.aspectRatio, undefined, 5);
      }
    },
    // Clear the flowchart state when the system design has ended
    clearElements: () => {
      state.elements = initialState.elements;
      state.elementIds = initialState.elementIds;
      state.selectedElementIds = initialState.selectedElementIds;
      state.copiedElementIds = initialState.copiedElementIds;
      state.viewbox = initialState.viewbox;
      state.pasteCount = initialState.pasteCount;
      state.scale = initialState.scale;
    },
    addElement: (actionPayload: AddElementArgs) => {
      const element = createElement(actionPayload);
      state.elementIds = [...state.elementIds, actionPayload.id];
      state.elements[actionPayload.id] = element;
    },
    updateSettings: (actionPayload: UpdateSettingsArgs) => {
      // Basically the element exists in the map state, but on the remote interview,
      // some action could come in from the peer after resetting of the flowchart,
      // hence confirm its existence just in case.
      if (!(actionPayload.id in state.elements)) return;

      const element = state.elements[actionPayload.id];

      // Last one wins since the states diverge when changes between peers conflict
      if (element.updatedAt > actionPayload.timestamp) return;

      if (element.type === ELEMENT_TYPE.comment) {
        state.elements[actionPayload.id] = {
          ...element,
          settings: { ...element.settings, ...(actionPayload.updates as Partial<CommentSettings>) },
          updatedAt: actionPayload.timestamp,
        };
      } else if (element.type === ELEMENT_TYPE.edge) {
        state.elements[actionPayload.id] = {
          ...element,
          settings: { ...element.settings, ...(actionPayload.updates as Partial<EdgeSettings>) },
          updatedAt: actionPayload.timestamp,
        };
      } else if (element.type === ELEMENT_TYPE.node) {
        if (element.label === ELEMENT_LABEL.vm) {
          const updates = actionPayload.updates as Partial<VmSettings>;
          let geometry = element.geometry;
          if (updates.hasOwnProperty("autoScale")) {
            if (updates.autoScale && !element.settings.autoScale) {
              geometry = {
                ...geometry,
                minX: geometry.minX - 20,
                minY: geometry.minY - 20,
                maxX: geometry.maxX + 20,
                maxY: geometry.maxY + 20,
              };
            }
            if (!updates.autoScale && element.settings.autoScale) {
              geometry = {
                ...geometry,
                minX: geometry.minX + 20,
                minY: geometry.minY + 20,
                maxX: geometry.maxX - 20,
                maxY: geometry.maxY - 20,
              };
            }
          }
          state.elements[actionPayload.id] = {
            ...element,
            geometry,
            settings: { ...element.settings, ...updates },
            updatedAt: actionPayload.timestamp,
          };
        } else if (element.label === ELEMENT_LABEL.sql) {
          state.elements[actionPayload.id] = {
            ...element,
            settings: { ...element.settings, ...(actionPayload.updates as Partial<SqlSettings>) },
            updatedAt: actionPayload.timestamp,
          };
        } else if (element.label === ELEMENT_LABEL.queue) {
          state.elements[actionPayload.id] = {
            ...element,
            settings: { ...element.settings, ...(actionPayload.updates as Partial<QueueSettings>) },
            updatedAt: actionPayload.timestamp,
          };
        } else if (element.label === ELEMENT_LABEL.cache) {
          state.elements[actionPayload.id] = {
            ...element,
            settings: { ...element.settings, ...(actionPayload.updates as Partial<CacheSettings>) },
            updatedAt: actionPayload.timestamp,
          };
        } else {
          state.elements[actionPayload.id] = {
            ...element,
            settings: { ...element.settings, ...(actionPayload.updates as Partial<DefaultSettings>) },
            updatedAt: actionPayload.timestamp,
          };
        }
      } else {
        state.elements[actionPayload.id] = {
          ...element,
          settings: { ...element.settings, ...(actionPayload.updates as Partial<DefaultSettings>) },
          updatedAt: actionPayload.timestamp,
        };
      }
    },
    selectElements: (actionPayload: SelectElementArgs) => {
      state.selectedElementIds = actionPayload.ids;
    },
    // Select multiple elements with shift key
    selectMore: (actionPayload: SelectMoreArgs) => {
      state.selectedElementIds = [...state.selectedElementIds, actionPayload.id];

      const edgeIds: string[] = [];

      state.elementIds.forEach(elementId => {
        // Basically the element exists in the map state, but on the remote interview,
        // some action could come in from the peer after resetting of the flowchart,
        // hence confirm its existence just in case.
        if (!(actionPayload.id in state.elements)) return;

        const element = state.elements[elementId];
        if (element.type === ELEMENT_TYPE.edge) {
          edgeIds.push(elementId);
        }
      });

      edgeIds.forEach(edgeId => {
        const edge = state.elements[edgeId] as EdgeElement;
        if (state.selectedElementIds.includes(edge.source) && state.selectedElementIds.includes(edge.target)) {
          state.selectedElementIds = [...state.selectedElementIds, edgeId];
        }
      });
    },
    selectAll: () => {
      /**
       * If you do not create a new array, the reference to `state.elementIds` is inherited,
       * and after Copy & Paste, `state.elementIds` is increased, and after Copy & Paste again,
       * the increased `state.elementIds` is copied and If you Copy & Paste again,
       * the increased state of `state.elementIds` becomes the target of Copy & Paste.
       */
      state.selectedElementIds = [...state.elementIds];
    },
    unselectElements: () => {
      state.selectedElementIds = [];
    },
    // Select an area by dragging. Elements contained in the dragged area will be selected
    selectArea: (actionPayload: Area) => {
      const selectedElementIds: string[] = [];
      const selectedNodeIds: string[] = [];
      const edgeIds: string[] = [];

      state.elementIds.forEach(elementId => {
        // Basically the element exists in the map state, but on the remote interview,
        // some action could come in from the peer after resetting of the flowchart,
        // hence confirm its existence just in case.
        if (!(elementId in state.elements)) return;

        const element = state.elements[elementId];
        if (element.type === ELEMENT_TYPE.node || element.type === ELEMENT_TYPE.network || element.type === ELEMENT_TYPE.comment) {
          const { minX, minY, maxX, maxY } = geometryFromElement(element);

          if (actionPayload.minX <= minX && actionPayload.minY <= minY && actionPayload.maxX >= maxX && actionPayload.maxY >= maxY) {
            selectedElementIds.push(elementId);
            if (element.type === ELEMENT_TYPE.node) {
              selectedNodeIds.push(elementId);
            }
          }
        }

        if (element.type === ELEMENT_TYPE.edge) {
          edgeIds.push(elementId);
        }
      });

      edgeIds.forEach(edgeId => {
        const edge = state.elements[edgeId] as EdgeElement;
        if (selectedNodeIds.includes(edge.source) && selectedNodeIds.includes(edge.target)) {
          selectedElementIds.push(edgeId);
        }
      });

      state.selectedElementIds = selectedElementIds;
    },
    moveElements: (actionPayload: MoveElementsArgs) => {
      actionPayload.ids.forEach(elementId => {
        const element = Object.hasOwn(state.elements, elementId) ? state.elements[elementId] : undefined;
        if (element?.type === ELEMENT_TYPE.node || element?.type === ELEMENT_TYPE.network || element?.type === ELEMENT_TYPE.comment) {
          state.elements[elementId] = {
            ...element,
            geometry: {
              minX: element.geometry.minX + actionPayload.dx,
              minY: element.geometry.minY + actionPayload.dy,
              maxX: element.geometry.maxX + actionPayload.dx,
              maxY: element.geometry.maxY + actionPayload.dy,
            },
          };
        }
      });
    },
    deleteElements: (actionPayload: DeleteElementArgs) => {
      state.elementIds = state.elementIds.filter(elementId => {
        // Basically the element exists in the map state, but on the remote interview,
        // some action could come in from the peer after resetting of the flowchart,
        // hence confirm its existence just in case.
        if (!(elementId in state.elements)) return false;

        const element = state.elements[elementId];
        const isSelected = actionPayload.ids.includes(elementId);
        const isNeighborEdge =
          element.type === ELEMENT_TYPE.edge && (actionPayload.ids.includes(element.source) || actionPayload.ids.includes(element.target));
        return !isSelected && !isNeighborEdge;
      });
      state.selectedElementIds = state.selectedElementIds.filter(id => !actionPayload.ids.includes(id));
    },
    // Restore deleted element ids on undo
    reviveElements: (actionPayload: ReviveElementArgs) => {
      actionPayload.ids.forEach(id => {
        // Basically the element exists in the map state, but on the remote interview,
        // some action could come in from the peer after resetting of the flowchart,
        // hence confirm its existence just in case.
        if (!(id in state.elements)) return;

        // Check if the element is deleted to avoid reviving the same element when operations conflict between peers
        if (!state.elementIds.includes(id)) {
          const element = state.elements[id];
          const concatIds = [...actionPayload.ids, ...state.elementIds];
          // Skip the operation when trying to revive the edge which does not have the source or target node
          if (element.type === ELEMENT_TYPE.edge && (!concatIds.includes(element.source) || !concatIds.includes(element.target))) {
            return;
          }

          state.elementIds.push(id);
        }
      });
    },
    // Adjust the network size
    shapeElement: (actionPayload: ShapeElementArgs) => {
      // Basically the element exists in the map state, but on the remote interview,
      // some action could come in from the peer after resetting of the flowchart,
      // hence confirm its existence just in case.
      if (!(actionPayload.id in state.elements)) return;

      const element = state.elements[actionPayload.id] as NodeElement | NetworkElement;
      let geometry = element.geometry;

      const newMinX = geometry.minX + actionPayload.dx;
      const newMinY = geometry.minY + actionPayload.dy;
      const newMaxX = geometry.maxX + actionPayload.dx;
      const newMaxY = geometry.maxY + actionPayload.dy;

      switch (actionPayload.adjustPosition) {
        case "leftTop":
          if (newMinX >= geometry.maxX || newMinY >= geometry.maxY) return;
          geometry = { ...geometry, minX: newMinX, minY: newMinY };
          break;
        case "rightTop":
          if (newMaxX <= geometry.minX || newMinY >= geometry.maxY) return;
          geometry = { ...geometry, maxX: geometry.maxX + actionPayload.dx, minY: geometry.minY + actionPayload.dy };
          break;
        case "rightBottom":
          if (newMaxX <= geometry.minX || newMaxY <= geometry.minY) return;
          geometry = { ...geometry, maxX: geometry.maxX + actionPayload.dx, maxY: geometry.maxY + actionPayload.dy };
          break;
        case "leftBottom":
          if (newMinX >= geometry.maxX || newMaxY <= geometry.minY) return;
          geometry = { ...geometry, minX: geometry.minX + actionPayload.dx, maxY: geometry.maxY + actionPayload.dy };
          break;
        case "leftMid":
          if (newMinX >= geometry.maxX) return;
          geometry = { ...geometry, minX: geometry.minX + actionPayload.dx };
          break;
        case "topMid":
          if (newMinY >= geometry.maxY) return;
          geometry = { ...geometry, minY: geometry.minY + actionPayload.dy };
          break;
        case "rightMid":
          if (newMaxX <= geometry.minX) return;
          geometry = { ...geometry, maxX: geometry.maxX + actionPayload.dx };
          break;
        case "bottomMid":
          if (newMaxY <= geometry.minY) return;
          geometry = { ...geometry, maxY: geometry.maxY + actionPayload.dy };
          break;
      }

      state.elements[actionPayload.id] = {
        ...element,
        geometry,
      };
    },
    copyElements: () => {
      state.copiedElementIds = Array.from(new Set(state.selectedElementIds));
      state.pasteCount = 1;
    },
    pasteElements: (actionPayload: PasteElementArgs) => {
      actionPayload.elements.forEach(element => {
        if (state.elementIds.includes(element.id)) {
          return;
        }
        state.elementIds.push(element.id);
        state.elements[element.id] = element;
      });
    },
    incrementPasteCount: () => {
      state.pasteCount++;
    },
    connectNodes: (actionPayload: ConnectNodesArgs) => {
      const edge = createEdge(actionPayload.id, actionPayload.source, actionPayload.target, actionPayload.timestamp);
      state.elements[actionPayload.id] = edge;

      // When the source or target node does not exist, skip the subsequent process
      if (!state.elementIds.includes(actionPayload.source) || !state.elementIds.includes(actionPayload.target)) return;

      // Check if the duplicate edge exists or not
      let duplicateEdge: EdgeElement | undefined;
      state.elementIds.forEach(elementId => {
        // Basically the element exists in the map state, but on the remote interview,
        // some action could come in from the peer after resetting of the flowchart,
        // hence confirm its existence just in case.
        if (!(elementId in state.elements)) return;

        const element = state.elements[elementId];
        if (element.type === ELEMENT_TYPE.edge) {
          if (
            (element.source === actionPayload.source && element.target === actionPayload.target) ||
            (element.source === actionPayload.target && element.target === actionPayload.source)
          ) {
            duplicateEdge = element;
          }
        }
      });

      // If the duplicate node exists, last one wins
      if (duplicateEdge) {
        // Discard the incoming change since the existing edge is the latest
        if (duplicateEdge.updatedAt > actionPayload.timestamp) {
          state.duplicateEdgeIds.push(actionPayload.id);
          return;
        } else {
          // Swap the old edge and the new edge since the incoming change is the latest
          const duplicateEdgeId = duplicateEdge.id;
          state.elementIds = state.elementIds.filter(id => id !== duplicateEdgeId);
          state.duplicateEdgeIds.push(duplicateEdgeId);
          state.elementIds.push(actionPayload.id);
        }
      } else {
        state.elementIds.push(actionPayload.id);
      }
    },
    // Called when reconnecting an already existing edge to another node
    reconnectEdge: (actionPayload: ReconnectEdgeArg) => {
      const edge = state.elements[actionPayload.id] as EdgeElement;

      // Discard the incoming change since the local edge is the latest
      if (edge.updatedAt > actionPayload.timestamp) return;

      // If the duplicate edge is reconnected, it will not be duplicate, hence revive the edge id
      if (state.duplicateEdgeIds.includes(edge.id)) {
        state.duplicateEdgeIds.filter(id => id !== edge.id);
        state.elementIds.push(edge.id);
      }

      state.elements[actionPayload.id] = {
        ...edge,
        source: actionPayload.source,
        target: actionPayload.target,
        updatedAt: actionPayload.timestamp,
      };

      // When the source or target node does not exist, delete the reconnecting edge id from the array
      if (!state.elementIds.includes(actionPayload.source) || !state.elementIds.includes(actionPayload.target)) {
        state.elementIds = state.elementIds.filter(id => id !== edge.id);
        return;
      }

      // Check if a duplicate edge exists or not
      let duplicateEdge: EdgeElement | undefined;
      state.elementIds.forEach(elementId => {
        // Basically the element exists in the map state, but on the remote interview,
        // some action could come in from the peer after resetting of the flowchart,
        // hence confirm its existence just in case.
        if (!(elementId in state.elements)) return;

        const element = state.elements[elementId];
        if (element.type === ELEMENT_TYPE.edge) {
          if (
            element.id !== edge.id &&
            ((element.source === actionPayload.source && element.target === actionPayload.target) ||
              (element.source === actionPayload.target && element.target === actionPayload.source))
          ) {
            duplicateEdge = element;
          }
        }
      });

      // If a duplicate edge exists, last one wins
      if (duplicateEdge) {
        // Delete the reconnecting edge from the array since the connected one is the latest
        if (duplicateEdge.updatedAt > actionPayload.timestamp) {
          state.elementIds = state.elementIds.filter(id => id !== actionPayload.id);
          state.duplicateEdgeIds.push(actionPayload.id);
        } else {
          // Delete the connected edge from the array since the reconnecting one is the latest
          const duplicateEdgeId = duplicateEdge.id;
          state.elementIds = state.elementIds.filter(id => id !== duplicateEdgeId);
          state.duplicateEdgeIds.push(duplicateEdgeId);
        }
      }
    },
    addComment: (actionPayload: AddCommentArgs) => {
      const comment = createComment(
        actionPayload.id,
        actionPayload.content,
        actionPayload.geometry.minX,
        actionPayload.geometry.minY,
        actionPayload.fontSize,
        actionPayload.timestamp,
      );
      state.elementIds.push(comment.id);
      state.elements[actionPayload.id] = comment;
    },
    editComment: (actionPayload: EditCommentArgs) => {
      const comment = state.elements[actionPayload.id] as CommentElement;
      // Last one wins since the states diverge when changes conflict between peers
      if (comment.updatedAt > actionPayload.timestamp) return;
      state.elements[actionPayload.id] = { ...comment, content: actionPayload.content, updatedAt: actionPayload.timestamp };
    },
    // Called when calculating and updating the comment area from the length of the text
    setCommentArea: (actionPayload: SetCommentAreaArgs) => {
      const comment = state.elements[actionPayload.id] as CommentElement | undefined;
      if (!comment) return;
      state.elements[actionPayload.id] = {
        ...comment,
        geometry: { ...comment.geometry, maxX: actionPayload.maxX, maxY: actionPayload.maxY },
      };
    },
    zoomViewbox: (actionPayload: ZoomViewboxArgs) => {
      if (actionPayload.scale === state.scale || actionPayload.scale > MAX_SCALE || actionPayload.scale < MIN_SCALE) {
        return;
      }

      const zoomedWidth = (initialState.viewbox.maxX - initialState.viewbox.minX) / actionPayload.scale;
      const scaleFromCurrent = (state.viewbox.maxX - state.viewbox.minX) / zoomedWidth;

      const focus = actionPayload.focus ?? {
        x: (state.viewbox.minX + state.viewbox.maxX) / 2,
        y: (state.viewbox.minY + state.viewbox.maxY) / 2,
      };

      const zoomedMinX = focus.x + (state.viewbox.minX - focus.x) / scaleFromCurrent;
      const zoomedMinY = focus.y + (state.viewbox.minY - focus.y) / scaleFromCurrent;
      const zoomedMaxX = focus.x + (state.viewbox.maxX - focus.x) / scaleFromCurrent;
      const zoomedMaxY = focus.y + (state.viewbox.maxY - focus.y) / scaleFromCurrent;

      state.viewbox = { minX: zoomedMinX, minY: zoomedMinY, maxX: zoomedMaxX, maxY: zoomedMaxY };
      state.scale = Math.round(actionPayload.scale * 10) / 10;
    },
    panViewbox: (actionPayload: PanViewboxArg) => {
      state.viewbox = {
        ...state.viewbox,
        minX: state.viewbox.minX + actionPayload.dx,
        minY: state.viewbox.minY + actionPayload.dy,
        maxX: state.viewbox.maxX + actionPayload.dx,
        maxY: state.viewbox.maxY + actionPayload.dy,
      };
    },
    updateViewbox: (actionPayload: Area) => {
      state.viewbox = { ...actionPayload };
    },
    cacheViewbox: (actionPayload: Area) => {
      state.cachedViewbox = { ...actionPayload };
    },
    updateAspectRatio: (actionPayload: UpdateAspectRatioArgs) => {
      state.aspectRatio = actionPayload.aspectRatio;
      state.viewbox = { ...state.viewbox, maxY: state.viewbox.minY + (state.viewbox.maxX - state.viewbox.minX) * state.aspectRatio };
    },
    toggleMiniMap: () => {
      state.openMiniMap = !state.openMiniMap;
    },
    toggleResetDialog: () => {
      state.openResetConfirmDialog = !state.openResetConfirmDialog;
    },
  };
};

/**
 * This Method is controller for global state.
 */
export const ExternalAction = {
  clear: (id: string) => {
    multiState.delete(id);
  },
};
