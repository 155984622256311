import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledTable = styled(Table)(({ theme }) => ({
  width: "fit-content",
  tr: {
    marginBottom: theme.spacing(1),
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  paddingLeft: 0,
  paddingRight: 8,
  paddingTop: 4,
  paddingBottom: 4,
  borderBottom: "none",
}));

const StyledTableRow = styled(TableRow)(() => ({
  padding: 0,
}));

type Row = {
  title: string;
  value: string;
};

export type BankFieldProps = {
  className?: string;
  bankName: string;
  branchName: string;
  accountNumber: string;
  accountType: string;
  accountHolderName: string;
};

const BankField: React.FC<BankFieldProps> = props => {
  const { t } = useTranslation();
  const rows: Row[] = [
    {
      title: t("金融機関名"),
      value: props.bankName,
    },
    {
      title: t("支店名"),
      value: props.branchName,
    },
    {
      title: t("口座種類"),
      value: props.accountType,
    },
    {
      title: t("口座番号"),
      value: props.accountNumber,
    },
    {
      title: t("口座名義"),
      value: props.accountHolderName,
    },
  ];
  return (
    <Box className={props.className}>
      <Typography fontSize={14} fontWeight={700} mb={0.5}>
        {t("お振込先")}
      </Typography>
      <StyledTable>
        <TableBody>
          {rows.map((row, index) => (
            <StyledTableRow key={`row-${index}`}>
              <StyledTableCell component="th" scope="row" align="right">
                <Typography variant="body2" color="text.secondary">
                  {row.title} :
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography variant="body2" fontWeight={700}>
                  {row.value}
                </Typography>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </StyledTable>
    </Box>
  );
};

BankField.displayName = "BankField";

export default BankField;
