/**
 * @param rate 0 ~ 1
 */
export const formateRateToIntegerPercent = (rate: number): number => {
  if (rate < 0 || 1 < rate) {
    console.warn(`Invalid Input Value Error. Specify between 0~1. Current Value: "${rate}"`);
  }
  const value = 100 * rate;
  if (99 <= value && value < 100) {
    return 99;
  }
  if (0 < value && value <= 1) {
    return 1;
  }
  return Math.round(value);
};
