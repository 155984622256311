import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import DialogContentText from "@mui/material/DialogContentText";
import * as React from "react";

import BaseDialog, { BaseDialogProps, NoButtonProps, YesButtonProps } from "../../../../primitive/BaseDialog/BaseDialog";

export type DeletionDialogProps = {
  targetName: string;
  open: boolean;
  onClose: () => void;
  yesButton: Pick<YesButtonProps, "disabled" | "onClick">;
  noButton?: Pick<NoButtonProps, "disabled">;
};

const DeletionDialog: React.FC<DeletionDialogProps> = props => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const { onClose } = props;

  const deleteDialog: BaseDialogProps = {
    title: t2("DeleteDialogAlert", { targetName: props.targetName }),
    open: props.open,
    yesButton: {
      ...props.yesButton,
      color: "error",
      children: t("削除"),
    },
    noButton: {
      ...props.noButton,
      onClick: () => {
        onClose();
      },
      children: t("キャンセル"),
    },
  };

  return (
    <BaseDialog {...deleteDialog}>
      <DialogContentText>
        {t("削除されたテストは一覧から消えます。")}
        <br />
        {t("この操作は復旧不可能なためお気をつけください。")}
      </DialogContentText>
    </BaseDialog>
  );
};

DeletionDialog.displayName = "DeletionDialog";

export default DeletionDialog;
