import { QuizTestReport } from "@hireroo/app-store/view-domain/QuizTestReport";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import { Widget } from "@hireroo/presentation";

export type GenerateHeaderPropsArgs = {
  quizId: number;
};

export const useGenerateHeaderProps = (args: GenerateHeaderPropsArgs): Widget.QuizTestReportProps["header"] => {
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const hooks = QuizTestReport.useCreateQuizHooks(args.quizId);
  const quizPackage = hooks.usePackage();

  return {
    title: `${resolveLanguage(quizPackage, lang, "title")} ${quizPackage.version}`,
    linkButton: {
      onClick: () => {
        Snackbar.notify({
          severity: "info",
          message: t("クリップボードにコピーされました"),
        });
      },
      url: window.location.href,
    },
  };
};
