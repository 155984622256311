import { useTranslation } from "@hireroo/i18n";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import * as React from "react";

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: 14,
  wordBreak: "keep-all",
  whiteSpace: "nowrap",
  height: 24,
  minHeight: 24,
  minWidth: "unset",
}));

type Controller = {
  setLoading: (loading: boolean) => void;
  close: () => void;
};

type Item = {
  id: string;
  label: string;
  onClick: (controller: Controller) => void;
};

export type SelectQuestionFromHistoryProps = {
  items: Item[];
  loading?: boolean;
};

const SelectQuestionFromHistory: React.FC<SelectQuestionFromHistoryProps> = props => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [loading, setLoading] = React.useState(false);
  const controller = React.useMemo((): Controller => {
    return {
      close: () => {
        setAnchorEl(null);
      },
      setLoading: newLoading => {
        setLoading(newLoading);
      },
    };
  }, []);
  const button: ButtonProps = {
    variant: "text",
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    children: t("履歴から選択する"),
    endIcon: <ArrowDropDownIcon fontSize="small" />,
  };
  const menuProps: MenuProps = {
    open: Boolean(anchorEl),
    anchorEl: anchorEl,
    onClose: () => {
      setAnchorEl(null);
    },
  };

  return (
    <Box>
      <StyledButton {...button} />
      <Menu {...menuProps}>
        {props.items.length === 0 && (
          <Typography variant="body2" color="text.secondary" px={2}>
            {t("履歴がありません")}
          </Typography>
        )}
        {props.items.map(item => {
          const itemProps: MenuItemProps = {
            disabled: loading,
            onClick: () => {
              item.onClick(controller);
            },
            children: item.label,
          };
          return <MenuItem {...itemProps} key={item.id} />;
        })}
        {props.loading && (
          <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress size={23} color="secondary" />
          </Box>
        )}
      </Menu>
    </Box>
  );
};

SelectQuestionFromHistory.displayName = "SelectQuestionFromHistory";

export default SelectQuestionFromHistory;
