import { CollaborativeAction, CollaborativeState } from "@hireroo/app-helper/hooks";
import type { Widget } from "@hireroo/presentation";
import type { FlowChartProps } from "@hireroo/system-design/react/FlowChart";
import React from "react";

export type PlacingElementLabel = FlowChartProps["drawingArea"]["placingElementLabel"];

export type GenerateFlowChartPropsArgs = {
  snapshot: string;
  fontSize: FlowChartProps["drawingArea"]["fontSize"];
  onDragOver: (e: React.DragEvent) => void;
  onDrop: (e: React.DragEvent) => void;
  isPlacingElement: boolean;
  placingElementLabel?: PlacingElementLabel;
  collaborativeState: CollaborativeState;
  collaborativeAction: CollaborativeAction;
};

export const generateFlowChartProps = ({
  snapshot,
  fontSize,
  onDragOver,
  onDrop,
  isPlacingElement,
  placingElementLabel,
  collaborativeState,
  collaborativeAction,
}: GenerateFlowChartPropsArgs): Widget.SystemDesignCodingEditorProps["flowChart"]["flowChart"]["drawingArea"] => {
  return {
    snapshot: snapshot,
    fontSize: fontSize,
    isPlacingElement: isPlacingElement,
    placingElementLabel: placingElementLabel,
    onDragOver,
    onDrop,
    addElement: collaborativeAction.addElement,
    addComment: collaborativeAction.addComment,
    editComment: collaborativeAction.editComment,
    deleteElements: collaborativeAction.deleteElements,
    moveElements: collaborativeAction.moveElements,
    shapeElement: collaborativeAction.shapeElement,
    connectNodes: collaborativeAction.connectNodes,
    reconnectEdge: collaborativeAction.reconnectEdge,
    pasteElements: collaborativeAction.pasteElements,
    undo: collaborativeAction.undo,
    redo: collaborativeAction.redo,
    collaborators: collaborativeState.collaborators,
    selectElement: collaborativeAction.selectElement,
    saveEditingCommentId: collaborativeAction.saveEditingCommentId,
    moveCursor: collaborativeAction.moveCursor,
  };
};
