import { getPermissionState } from "@hireroo/app-helper/media-device";
import React from "react";

export type UseGenerateDevicePermissionState = {
  opened: boolean;
};

export type DevicePermissionState = {
  permissionState: PermissionState;
};
export const useGenerateDevicePermissionState = (args: UseGenerateDevicePermissionState): DevicePermissionState => {
  const { opened } = args;
  const [permissionState, setPermissionState] = React.useState<PermissionState>("prompt");

  const checkPermission = React.useCallback(async () => {
    // If permission is already granted any times, close dialog
    if (permissionState === "granted") {
      return;
    }
    const cameraState = await getPermissionState("camera");
    const micState = await getPermissionState("microphone");
    if (cameraState === "granted" && micState === "granted") {
      setPermissionState("granted");
      return;
    }
  }, [permissionState]);

  // Check permission and handle permission change
  React.useEffect(() => {
    //If dialog is not opened skip checking
    if (!opened) {
      return;
    }
    // Safari doesn't support onchange event. so we check permission every 100ms.
    const intervalId = setInterval(checkPermission, 100);
    if (permissionState === "granted") {
      clearInterval(intervalId);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [checkPermission, opened, permissionState]);

  return {
    permissionState: permissionState,
  };
};
