import { QuestionQuizPackageResourceEditor } from "@hireroo/app-store/view-domain/QuestionQuizPackageResourceEditor";
import { SearchQuizQuestion } from "@hireroo/app-store/widget/e/SearchQuizQuestion";

export const startSubscribeSelectedQuestions = () => {
  return SearchQuizQuestion.subscribeSelectedQuestions(questions => {
    const questionList = Array.from(questions.values());
    QuestionQuizPackageResourceEditor.appendOrderOfQuestionIds(
      questionList.map<`${number}-${string}`>(question => `${question.id}-${question.version}`),
    );
    QuestionQuizPackageResourceEditor.setSelectedQuestions(
      questionList.map(question => {
        return question;
      }),
    );
  });
};
