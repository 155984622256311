import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { RemoteTemplateResourceEditorForm } from "@hireroo/validator";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { DeepPartial } from "react-hook-form";

import ResourceField from "../../../../modules/ResourceFieldV2/ResourceFieldV2";
import InputControlTextField, {
  InputControlTextFieldProps,
} from "../../../../primitive/InputControl/InputControlTextField/InputControlTextField";
import SelectField, { SelectFieldProps } from "../../../../primitive/InputControl/SelectField/SelectField";
import { useRemoteTemplateResourceEditorContext } from "../../Context";

type FieldName = keyof RemoteTemplateResourceEditorForm.CreateRemoteInterviewSchema;

type TemplateFieldName = keyof RemoteTemplateResourceEditorForm.CreateRemoteTemplateSchema;

const ReservedFieldName = {
  TITLE: "remote.name",
  CANDIDATE_NAME: "remote.candidateName",
  MEMO: "remote.memo",
  TAGS: "remote.tags",
  VIEWERS: "remote.viewers",
  IS_PUBLIC: "remote.isPublic",
  EDITOR_UID: "remote.editorUid",
} satisfies Record<string, `${TemplateFieldName}.${FieldName}`>;

const Subsection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  boxShadow: "none",
}));
const StyledSelectField = styled(SelectField)(({ theme }) => ({
  color: theme.palette.text.secondary,
  width: "fit-content",
  backgroundColor: theme.palette.Other.FilledInputBG,
  ".MuiSelect-outlined": {
    border: "none",
  },
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
    borderRadius: 8,
  },
  minWidth: 360,
}));

export type RemoteSetupSectionProps = {
  mode: "CREATE" | "EDIT";
  QuestionSelectField: React.ReactNode;
  TagField: React.ReactNode;
  ViewerField: React.ReactNode;
  onBlur: (fields: DeepPartial<RemoteTemplateResourceEditorForm.CreateRemoteInterviewSchema>) => void;
};

const RemoteSetupSection: React.FC<RemoteSetupSectionProps> = props => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const { onBlur } = props;
  const { methods } = useRemoteTemplateResourceEditorContext();

  const handleInputBlur = () => {
    const remote = methods.getValues("remote");
    onBlur(remote);
  };
  const titleFieldProps: InputControlTextFieldProps = {
    fullWidth: true,
    placeholder: t2("CandidateCodingInterview"),
    helperText: t2("InputTestInformation"),
    onBlur: handleInputBlur,
  };
  const candidateNameFieldProps: InputControlTextFieldProps = {
    fullWidth: true,
    placeholder: t2("CandidateName"),
    helperText: t2("InputTestInformation"),
    onBlur: handleInputBlur,
  };

  const isPublic = methods.watch(ReservedFieldName.IS_PUBLIC);
  const memoFieldProps: InputControlTextFieldProps = {
    fullWidth: true,
    minRows: 3,
    maxRows: 10,
    multiline: true,
    helperText: t2("InputTestInformation"),
    onBlur: handleInputBlur,
  };

  const selectFieldProps: SelectFieldProps = {
    color: "secondary",
    variant: "outlined",
    items: [
      {
        text: t("リンクを知っているメンバーが閲覧可能"),
        value: true,
      },
      {
        text: t("指定されたメンバーのみ閲覧可能"),
        value: false,
      },
    ],
    onChange: event => {
      if (event.target.value === true) {
        methods.setValue(ReservedFieldName.VIEWERS, []);
      } else if (event.target.value === false) {
        const editorUid = methods.getValues(ReservedFieldName.EDITOR_UID);
        if (editorUid) {
          methods.setValue(ReservedFieldName.VIEWERS, [
            {
              value: {
                type: "EMPLOYEE",
                employeeId: editorUid,
                locked: true,
              },
            },
          ]);
        }
      }
    },
  };

  return (
    <Stack py={2} spacing={1.5}>
      <Subsection>
        <ResourceField label={t("タイトル")} kind="NONE" pb={2}>
          <InputControlTextField name={ReservedFieldName.TITLE} {...titleFieldProps} />
        </ResourceField>
        <ResourceField label={t("候補者名")} kind="NONE" pb={2}>
          <InputControlTextField name={ReservedFieldName.CANDIDATE_NAME} {...candidateNameFieldProps} />
        </ResourceField>
      </Subsection>

      <Subsection>
        <ResourceField label={t("出題問題")} kind="NONE" pb={2}>
          {props.QuestionSelectField}
        </ResourceField>
      </Subsection>

      <Subsection>
        <Stack spacing={4}>
          <ResourceField
            label={t("社内用メモ")}
            kind="NONE"
            pb={2}
            help={{
              kind: "DESCRIPTION",
              text: (
                <>
                  {t("求人媒体や採用管理システム(ATS)および次の面接官への引継ぎする目的でメモを残すことが可能です。")}
                  <br />
                  {t("※") + t("この内容は受験者に共有されません")}
                </>
              ),
            }}
          >
            <InputControlTextField name={ReservedFieldName.MEMO} {...memoFieldProps} />
          </ResourceField>
          <ResourceField
            label={t("タグ設定")}
            kind="NONE"
            help={{
              kind: "DESCRIPTION",
              text: t(
                "レポート作成時に自動付与されるタグを指定することができます。一覧画面での絞り込み検索やレポート画面で母集団を絞り込む際に利用することができます。",
              ),
            }}
          >
            {props.TagField}
          </ResourceField>

          <ResourceField
            label={t("閲覧権限")}
            kind="NONE"
            help={{
              kind: "DESCRIPTION",
              text: t("閲覧権限の適用対象はインタビュー詳細ページが対象になります。"),
            }}
          >
            <Stack spacing={1}>
              <StyledSelectField name={ReservedFieldName.IS_PUBLIC} {...selectFieldProps} />
              {isPublic === false && props.ViewerField}
            </Stack>
          </ResourceField>
        </Stack>
      </Subsection>
    </Stack>
  );
};

RemoteSetupSection.displayName = "RemoteSetupSection";

export default RemoteSetupSection;
