import { ChallengePlayback } from "@hireroo/app-store/widget/shared/ChallengePlayback";
import * as Time from "@hireroo/formatter/time";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

type ActiveRange = Widget.ActivityTimeBarChartPanelProps["timeTrackerReport"]["activeRanges"][0];
type Item = Widget.ActivityTimeBarChartPanelProps["items"][0];

export type GeneratePageLeaveHistoryPanelPropsArgs = {};

export const useGenerateProps = (_args: GeneratePageLeaveHistoryPanelPropsArgs): Widget.ActivityTimeBarChartPanelProps => {
  const { t } = useTranslation();
  const playbackManager = ChallengePlayback.usePlaybackManager();
  const isEnabledWebSearch = ChallengePlayback.useEnabledWebSearch();
  const leavingPageIntervals = ChallengePlayback.useLeavingPageIntervals();
  const startTs = React.useMemo(() => {
    return playbackManager.timeStamps.at(0) ?? 0;
  }, [playbackManager]);

  const activeRanges = React.useMemo((): ActiveRange[] => {
    const endTs = playbackManager.timeStamps.at(-1) ?? 0;
    const width = endTs - startTs;

    return leavingPageIntervals.map((interval): ActiveRange => {
      const leftPos = interval.startTs - startTs;
      const intervalWidth = interval.endTs - interval.startTs;
      return {
        start: `${Math.floor((leftPos / width) * 100)}%`,
        width: `${Math.floor((intervalWidth / width) * 100)}%`,
        display: Time.elapsedTimeFormat(interval.endTs - interval.startTs),
      };
    });
  }, [startTs, leavingPageIntervals, playbackManager]);

  const timeLabels = React.useMemo((): string[] => {
    const endTime = playbackManager.timeStamps.at(-1) ?? 0;
    const startTime = playbackManager.timeStamps.at(0) ?? 0;
    const list = ["00:00", Time.elapsedTimeFormat(endTime - startTime)];
    return Array.from(new Set(list));
  }, [playbackManager]);

  return {
    title: t("ページ離脱時間"),
    icon: isEnabledWebSearch
      ? {
          title: t(
            "Google検索が有効の場合、reCAPTCHAの入力が促されたり、検索したページをタブ移動で再度開いたりしたときにも、ページ離脱として扱われます。",
          ),
        }
      : undefined,
    timeFieldLabel: t("離脱時間"),
    timeTrackerReport: {
      activeRanges: activeRanges,
      timeLabels: timeLabels,
    },
    items: leavingPageIntervals.map((interval, index): Item => {
      const timeMilliseconds = interval.endTs - interval.startTs;
      const isOneSeconds = 0 < timeMilliseconds && timeMilliseconds <= 1000;
      const label = `${Time.elapsedTimeFormat(interval.startTs - startTs)} ~ ${Time.elapsedTimeFormat(interval.endTs - startTs)}`;
      const durationTime = isOneSeconds ? "1s" : Time.formatMillisecondToHumanTime(interval.endTs - interval.startTs);
      return {
        name: `${t("ページ離脱")} ${index + 1}`,
        description: `${durationTime} (${label})`,
        onClick: () => {
          const index = playbackManager.getIndexByTimeStamp(interval.startTs);
          if (index) {
            ChallengePlayback.updateSliderValue(index);
          }
        },
      };
    }),
  };
};
