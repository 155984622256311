import * as Def from "../definition/definition";
import type * as Types from "./types";

export const defaultLanguageListMap: Record<Types.Variant, string[]> = {
  ALGORITHM: Def.algorithmLanguageListMap.map(({ value }) => value).sort(),
  DATABASE: Def.sqlMapForDisplay.map(({ value }) => value).sort(),
  CLASS: Def.classLanguageForDisplay.map(({ value }) => value).sort(),
  EMPTY_PAD: Def.algorithmLanguageListMap
    .map(({ value }) => value)
    .sort()
    .concat(Def.sqlMapForDisplay.map(({ value }) => value).sort()),
};

const defaultLanguageMap: Record<Types.Variant, string> = {
  ALGORITHM: Def.defaultLanguageMap["ALGORITHM"],
  DATABASE: Def.defaultLanguageMap["DATABASE"],
  CLASS: Def.defaultLanguageMap["CLASS"],
  EMPTY_PAD: Def.defaultLanguageMap["ALGORITHM"], // set algorithm's default language
};

export const createDefaultState = (state?: Partial<Types.State>): Types.State => {
  const variant = state?.variant ?? "ALGORITHM";
  return {
    currentLanguage: state?.currentLanguage ?? defaultLanguageMap[variant],
    enableLanguages: state?.enableLanguages ?? defaultLanguageListMap[variant],
    variant: variant,
    updateMethod: "INITIAL",
  };
};
