import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type ScreeningTestReviewNavigationContainerProps = {};

const ScreeningTestReviewNavigationContainer: React.FC<ScreeningTestReviewNavigationContainerProps> = () => {
  const screeningTestReviewNavigationProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.ScreeningTestReviewNavigation {...screeningTestReviewNavigationProps} />
    </ErrorBoundary>
  );
};

ScreeningTestReviewNavigationContainer.displayName = "ScreeningTestReviewNavigationContainer";

export default withErrorBoundary(ScreeningTestReviewNavigationContainer, {});
