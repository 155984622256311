import Box from "@mui/material/Box";
import Button, { type ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import * as React from "react";

import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";
import LockIcon from "../../primitive/Icon/LockIcon/LockIcon";

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: "40px",
  backgroundColor: theme.palette["Gray/Shades"].p16,
  borderColor: theme.palette["Gray/Shades"].p16,
  "&:hover": {
    backgroundColor: theme.palette["Gray/Shades"].p30,
    borderColor: theme.palette["Gray/Shades"].p30,
  },
  height: "28px",
}));

export type UpsellLockButtonDialogProps = {
  button: Pick<ButtonProps, "onClick" | "children">;
  title: string;
  onClose?: () => void;
  Content: React.ReactNode;
};

const UpsellLockButtonDialog: React.FC<UpsellLockButtonDialogProps> = props => {
  const [open, setOpen] = React.useState(false);
  const button: ButtonProps = {
    ...props.button,
    onClick: event => {
      setOpen(true);
      props.button.onClick?.(event);
    },
    startIcon: <LockIcon />,
    variant: "outlined",
    color: "secondary",
  };
  const dialog: DialogWithCloseIconProps = {
    open: open,
    title: props.title,
    onClose: () => {
      setOpen(false);
      props.onClose?.();
    },
  };
  return (
    <Box>
      <StyledButton {...button} />
      <DialogWithCloseIcon {...dialog}>{props.Content}</DialogWithCloseIcon>
    </Box>
  );
};

UpsellLockButtonDialog.displayName = "UpsellLockButtonDialog";

export default UpsellLockButtonDialog;
