import { proxy } from "valtio";

import type * as Types from "./types";

export const state = proxy<Types.State>({
  status: "STOP",
  remainTimeSeconds: null,
  debugParams: null,
  percentageOfTimeRemaining: 0,
  timeLimitSeconds: 0,
});
