import type { Monaco } from "@monaco-editor/react";
import type { TPlainTextOperation, TPlainTextOperationValue } from "@otjs/plaintext";
import * as monacoEditor from "monaco-editor";
import * as path from "path";

import extensionMap from "../definition/extensionMap";

/**
 * Editor上で入力エラーを発生させないための設定
 * @see https://github.com/microsoft/monaco-editor/issues/264#issuecomment-286226099
 */
export const updateEditorValidationMessage = (monaco: typeof monacoEditor) => {
  monaco.languages.typescript.typescriptDefaults.setDiagnosticsOptions({
    noSemanticValidation: true,
    noSyntaxValidation: true, // This line disables errors in jsx tags like <div>, etc.
    noSuggestionDiagnostics: false,
  });
  // tsconfig.jsonのcompilerOptionsと同等の設定項目
  monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
    jsx: monaco.languages.typescript.JsxEmit.React,
    jsxFactory: "React.createElement",
    reactNamespace: "React",
    allowNonTsExtensions: true,
    allowJs: true,
    strict: true,
    noLib: false,
    preserveConstEnums: true,
    target: monaco.languages.typescript.ScriptTarget.Latest,
  });
};

export type TypeDefs = Record<"react", string>;

/**
 * Reactの型定義をサポートする
 */
export const supportReactTypeDefinition = (monaco: typeof monacoEditor, typeDefs: TypeDefs) => {
  /**
   * サードパーティの型定義を読み込ませる
   *
   * Stack OverflowのQ&A
   * @see https://stackoverflow.com/questions/43058191/how-to-use-addextralib-in-monaco-with-an-external-type-definition
   *
   * 実装例
   * @see https://github.com/Microsoft/monaco-editor/blob/017c5028090b0eb571c9c47c4cf5a1d6f0a0fdc3/website/playground/new-samples/extending-language-services/configure-javascript-defaults/sample.js#L19
   */
  monaco.languages.typescript.typescriptDefaults.addExtraLib(typeDefs.react, "node_modules/@types/react/index.d.ts");
};

export const getLanguageFromFile = (file: string): string => {
  const extension = path.extname(file);

  if (!extension) {
    const filename = path.basename(file);
    if (filename === "Dockerfile") return extensionMap[filename];
  }

  if (extension in extensionMap) {
    return extensionMap[extension];
  }

  return "text";
};

// matrixFromIdx converts cursor position stored as zero-based index to row and column for applying changes to monaco editor
export const matrixFromIdx = (model: monacoEditor.editor.ITextModel, index: number): { lineNumber: number; column: number } => {
  return model.getPositionAt(index);
};

// applyOperation applies a single operation (which means one history in firebase) to the current text in the monaco editor
export const applyOperation = (ops: TPlainTextOperation, monaco: Monaco, model: monacoEditor.editor.ITextModel): void => {
  let cursor = 0;

  // ref: https://github.com/FirebaseExtended/firepad/blob/master/lib/monaco-adapter.js#L447
  ops.forEach((textOp: TPlainTextOperationValue) => {
    if (typeof textOp === "string") {
      // addition
      const pos = matrixFromIdx(model, cursor);
      model.applyEdits([
        {
          range: new monaco.Range(pos.lineNumber, pos.column, pos.lineNumber, pos.column),
          text: textOp,
          forceMoveMarkers: true,
        },
      ]);
      cursor += textOp.length;
    } else if (typeof textOp === "number") {
      if (textOp > 0) {
        // retain
        cursor += textOp;
      } else {
        // deletion
        const from = matrixFromIdx(model, cursor);
        const to = matrixFromIdx(model, cursor - textOp);
        model.applyEdits([
          {
            range: new monaco.Range(from.lineNumber, from.column, to.lineNumber, to.column),
            text: null,
          },
        ]);
      }
    }
  });
};

const transformIndex = (ops: TPlainTextOperation): number => {
  let base = 0;
  let cursor = 0;
  ops.forEach((textOp: TPlainTextOperationValue) => {
    if (typeof textOp === "string") {
      // addition
      cursor += textOp.length;
      base += textOp.length;
    } else if (typeof textOp === "number") {
      if (base < 0) {
        return;
      }
      if (textOp > 0) {
        // retain
        cursor = textOp;
        base -= textOp;
      } else {
        // deletion
        cursor -= Math.min(base, textOp);
        base -= textOp;
      }
    }
  });
  return cursor;
};

export const getCursorRange = (ops: TPlainTextOperation, monaco: Monaco, model: monacoEditor.editor.ITextModel): monacoEditor.Range => {
  const newPosition = transformIndex(ops);
  const selection = matrixFromIdx(model, newPosition);
  return new monaco.Range(selection.lineNumber, selection.column, selection.lineNumber, selection.column);
};
