import { useTranslation } from "@hireroo/i18n";
import { useSnapshot } from "valtio";

import { state } from "./State";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

const useExam = () => {
  const snapshot = useSnapshotState();
  return snapshot.exam;
};

export const useAssessmentName = (): string => {
  const { t } = useTranslation();
  // TODO
  return t("テスト");
};

export const useExamMessageForTalent = (): string | undefined => {
  const spot = useExam();
  return spot?.messageForTalent || undefined;
};

export const useExamStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.exam?.status;
};

export const useFirstEntity = () => {
  const spot = useExam();
  return spot?.entities.at(0);
};

export const useInitialized = () => {
  const spot = useExam();
  return !!spot;
};

export const useEntities = () => {
  const spot = useExam();
  return spot?.entities || [];
};

export const useEndExamStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.endExamStatus;
};

export const useEndExamLoadingStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.endExamLoadingStatus;
};
