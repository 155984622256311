import { Assessments } from "@hireroo/app-store/page/e/assessments";
import { updateQueryParamsByObject } from "@hireroo/router/api";

import { stringifyViewers } from "./privateHelper";

export const startSubscribeSearchFilter = () => {
  return Assessments.subscribeCurrentSearchFilter(searchFilter => {
    if (!searchFilter) {
      // clear
      updateQueryParamsByObject({
        [Assessments.QueryKeys.ASSESSMENT_STATUS]: [],
        [Assessments.QueryKeys.VIEWER]: [],
        [Assessments.QueryKeys.SCORE_TREND]: [],
        [Assessments.QueryKeys.SUBMIT_STATUS]: [],
        [Assessments.QueryKeys.TALENT]: [],
        [Assessments.QueryKeys.TITLE]: [],
      });
      return;
    }
    updateQueryParamsByObject({
      [Assessments.QueryKeys.ASSESSMENT_STATUS]: searchFilter.assessmentStatuses.length ? [searchFilter.assessmentStatuses.join(",")] : [],
      [Assessments.QueryKeys.VIEWER]: searchFilter.viewers.length ? [stringifyViewers(searchFilter.viewers)] : [],
      [Assessments.QueryKeys.SCORE_TREND]: searchFilter.scoreTrend !== null ? [searchFilter.scoreTrend] : [],
      [Assessments.QueryKeys.SUBMIT_STATUS]: searchFilter.submitStatus !== null ? [searchFilter.submitStatus] : [],
      [Assessments.QueryKeys.TALENT]: searchFilter.talent !== null ? searchFilter.talent : [],
      [Assessments.QueryKeys.TITLE]: searchFilter.textFilter.length ? [searchFilter.textFilter] : [],
    });
  });
};

export const startSubscribePager = () => {
  return Assessments.subscribePager(pager => {
    updateQueryParamsByObject({
      [Assessments.QueryKeys.SORT_METHOD]: pager.sortFieldValue !== Assessments.DefaultPager.sortFieldValue ? [pager.sortFieldValue] : [],
      [Assessments.QueryKeys.SIZE]: pager.size !== Assessments.DefaultPager.size ? [pager.size.toString()] : [],
      [Assessments.QueryKeys.PAGE]: pager.page !== Assessments.DefaultPager.page ? [pager.page.toString()] : [],
    });
  });
};
