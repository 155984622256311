import Markdown from "@hireroo/markdown-v2/react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import QuizQuestionCardContent, {
  QuizQuestionCardContentProps,
} from "../../ms-components/MultiChoice/QuizQuestionCardContent/QuizQuestionCardContent";
import QuestionArchivedBadge from "../../ms-components/Question/QuestionArchivedBadge/QuestionArchivedBadge";
import QuestionInformationSidePanel, {
  QuestionInformationSidePanelProps,
} from "../../ms-components/Question/QuestionInformationSidePanel/QuestionInformationSidePanel";

export type QuizPackageDetailProps = {
  packageTitle: string;
  packageDescription: string;
  difficulty: string;
  quizQuestionCardContents: QuizQuestionCardContentProps[];
  info: QuestionInformationSidePanelProps;
  isArchived: boolean;
};

const QuizPackageDetail: React.FC<QuizPackageDetailProps> = props => {
  return (
    <Paper elevation={2}>
      <Stack direction="row" sx={{ display: "flex", height: "auto", position: "relative" }} p={2}>
        <Box width="100%" mr={2}>
          <Box my={2}>
            <Typography variant="h5">{props.packageTitle}</Typography>
            <Box my={2}>{props.isArchived && <QuestionArchivedBadge />}</Box>
            <Box>
              <React.Suspense>
                <Markdown size="middle" children={props.packageDescription} />
              </React.Suspense>
            </Box>
          </Box>

          <Stack sx={{ height: "100%", width: "100%", flexGrow: 1 }} spacing={4}>
            {props.quizQuestionCardContents.map(quizQuestionCardContent => (
              <Box display="flex" justifyContent="center" key={quizQuestionCardContent.content.id}>
                <Paper elevation={5} sx={{ borderRadius: "8px", width: "100%", flexShrink: 0 }}>
                  <Box sx={{ width: "100%", flexShrink: 0 }} p={4}>
                    <QuizQuestionCardContent {...quizQuestionCardContent} />
                  </Box>
                </Paper>
              </Box>
            ))}
          </Stack>
        </Box>

        <Box sx={{ height: "auto", minWidth: "320px", flexShrink: 0 }}>
          <Box position="sticky" top="6%">
            <QuestionInformationSidePanel {...props.info} />
          </Box>
        </Box>
      </Stack>
    </Paper>
  );
};

QuizPackageDetail.displayName = "QuizPackageDetail";

export default QuizPackageDetail;
