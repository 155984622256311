import Alert from "@mui/material/Alert";
import Slide, { SlideProps } from "@mui/material/Slide";
import MuiSnackbar, { SnackbarProps as MuiSnackbarProps } from "@mui/material/Snackbar";
import * as React from "react";

type TransitionProps = Omit<SlideProps, "direction">;

export type LoadingSnackbarProps = {
  open: boolean;
  message: string;
  onClose: MuiSnackbarProps["onClose"];
};

const TransitionRight = (props: TransitionProps) => {
  return <Slide {...props} direction="right" />;
};

const LoadingSnackbar: React.FC<LoadingSnackbarProps> = props => {
  const muiSnackbarProps: MuiSnackbarProps = {
    role: "snackbar",
    open: props.open,
    autoHideDuration: 6000,
    onClose: props.onClose,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    TransitionComponent: TransitionRight,
  };
  return (
    <MuiSnackbar {...muiSnackbarProps}>
      <Alert severity="info">{props.message}</Alert>
    </MuiSnackbar>
  );
};

LoadingSnackbar.displayName = "LoadingSnackbar";

export default LoadingSnackbar;
