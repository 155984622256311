import { App } from "@hireroo/app-store/essential/employee";
import { RemoteInterviewOverview } from "@hireroo/app-store/widget/e/RemoteInterviewOverview";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";
import { useParams } from "react-router-dom";

import RemoteInterviewOverviewContainer, { RemoteInterviewOverviewContainerProps } from "./Container";

export type RemoteInterviewOverviewFetchContainerProps = {};

const RemoteInterviewOverviewFetchContainer: React.FC<RemoteInterviewOverviewFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const { id } = useParams<{ id: string }>();
  const remoteId = `${id}`;
  const initialized = RemoteInterviewOverview.useInitialized();
  const [result, refresh] = Graphql.useGetRemoteForRemoteInterviewOverviewQuery({
    variables: {
      remoteId: remoteId,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "network-only",
  });
  React.useEffect(() => {
    if (result.data?.remote) {
      RemoteInterviewOverview.updateInterview(result.data.remote);
      RemoteInterviewOverview.setRefresh(() => refresh({ requestPolicy: "network-only" }));
    }
  }, [refresh, result.data]);

  React.useEffect(() => {
    return () => {
      RemoteInterviewOverview.clear();
    };
  }, []);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: RemoteInterviewOverviewContainerProps = {};

  return <RemoteInterviewOverviewContainer {...containerProps} />;
};

RemoteInterviewOverviewFetchContainer.displayName = "RemoteInterviewOverviewFetchContainer";

export default withErrorBoundary(RemoteInterviewOverviewFetchContainer, {});
