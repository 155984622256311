import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TreeView, { TreeViewProps } from "@mui/lab/TreeView";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import * as React from "react";

import RenderTreeV3, { RenderTreeV3Props } from "./parts/RenderTreeV3/RenderTreeV3";

export type ReadonlyProjectFileTreeDiffProps = {
  focused?: string;
  renderTree: RenderTreeV3Props;
};

const ReadonlyProjectFileTreeDiff: React.FC<ReadonlyProjectFileTreeDiffProps> = props => {
  const theme = useTheme();
  const [focused, setFocused] = React.useState(".");
  React.useEffect(() => {
    if (props.focused) {
      setFocused(props.focused);
    }
  }, [props.focused]);

  const renderTreeProps: RenderTreeV3Props = {
    ...props.renderTree,
    onSelectFile: sourceFile => {
      props.renderTree.onSelectFile(sourceFile);
      setFocused(sourceFile);
    },
    onSelectDirectory: sourceDirectory => {
      props.renderTree.onSelectDirectory(sourceDirectory);
      setFocused(sourceDirectory);
    },
  };
  const treeViewProps: TreeViewProps = {
    selected: focused,
    defaultCollapseIcon: <ExpandMoreIcon />,
    defaultExpandIcon: <ChevronRightIcon />,
    defaultExpanded: ["."],
  };

  return (
    <Box width="100%" height="100%" display="flex" flexDirection={"column"} bgcolor={theme.palette["Secondary/Shades"].p12}>
      <Box id="project-file-nav-tree" width="100%" height={"100%"} sx={{ overflowY: "auto" }}>
        <TreeView {...treeViewProps}>
          <RenderTreeV3 {...renderTreeProps} />
        </TreeView>
      </Box>
    </Box>
  );
};

ReadonlyProjectFileTreeDiff.displayName = "ProjectFileNavigation";

export default ReadonlyProjectFileTreeDiff;
