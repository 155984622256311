import { App } from "@hireroo/app-store/essential/employee";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";
import { useParams } from "react-router-dom";

import SystemDesignQuestionDetailContainer, { SystemDesignQuestionDetailContainerProps } from "./Container";

export type SystemDesignQuestionDetailFetchContainerProps = {};

const SystemDesignQuestionDetailFetchContainer: React.FC<SystemDesignQuestionDetailFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const { id } = useParams<{ id: string }>();
  const [result, refresh] = Graphql.useSystemDesignQuestionForQuestionDetailQuery({
    variables: {
      questionId: Number(id),
      // This query called by employee side so we can return answers.
      withAnswers: true,
    },
    pause: appStatus !== "INITIALIZED",
  });
  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !result.data?.systemDesignQuestion) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: SystemDesignQuestionDetailContainerProps = {
    questionDetail: result.data.systemDesignQuestion,
  };

  return <SystemDesignQuestionDetailContainer {...containerProps} />;
};

SystemDesignQuestionDetailFetchContainer.displayName = "SystemDesignQuestionDetailFetchContainer";

export default withErrorBoundary(SystemDesignQuestionDetailFetchContainer, {});
