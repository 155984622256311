import { useSnapshot } from "valtio";

import { state } from "./State";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useSubmitValues = () => {
  const snapshot = useSnapshotState();
  return snapshot.submitValues;
};
