import { useWorkerFactory } from "monaco-editor-wrapper/workerFactory";
import * as path from "path";

import * as Def from "./definitions";

export const configureMonacoWorkers = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useWorkerFactory({
    ignoreMapping: true,
    workerLoaders: {
      editorWorkerService: () => new Worker(new URL("monaco-editor/esm/vs/editor/editor.worker.js", import.meta.url), { type: "module" }),
      javascript: () => new Worker(new URL("monaco-editor-wrapper/workers/module/ts", import.meta.url), { type: "module" }),
    },
  });
};

export const getExtension = (p: string) => {
  return path.extname(p);
};

export const getLanguage = (p: string): Def.Language => {
  const ext = getExtension(p);
  const language = Def.ExtensionToLanguage[ext];
  return language || "plaintext";
};

export const getEditorConfig = (p: string): Def.EditorConfig => {
  return Def.LanguageToEditorConfig[getLanguage(p)];
};

export const isLspSupported = (language: Def.Language) => {
  return Def.LspSupportedMap[language];
};
