import { useTranslation } from "@hireroo/i18n";
import * as React from "react";

import BaseDialog, { BaseDialogProps, NoButtonProps, YesButtonProps } from "../../../primitive/BaseDialog/BaseDialog";
import QuizQuestionCardContent, { QuizQuestionCardContentProps } from "../../../usecase/QuizQuestionCardContent/QuizQuestionCardContent";

export type QuizResourcePreviewDialogProps = {
  open: boolean;
  title?: string;
  yesButton: Pick<YesButtonProps, "disabled" | "onClick">;
  noButton?: Pick<NoButtonProps, "onClick">;
  content?: QuizQuestionCardContentProps;
};

const QuizResourcePreviewDialog: React.FC<QuizResourcePreviewDialogProps> = props => {
  const { t } = useTranslation();
  const baseDialogProps: BaseDialogProps = {
    open: props.open,
    title: props.title,
    yesButton: {
      ...props.yesButton,
      children: t("編集する"),
    },
    noButton: {
      ...props.noButton,
      disabled: false,
      children: t("キャンセル"),
    },
    yesButtonTooltip: props.yesButton.disabled
      ? {
          title: t("自作した設問以外は編集できません。"),
        }
      : undefined,
    optionalDialog: {
      sx: {
        "& .MuiDialog-paper": {
          width: "50vw",
        },
      },
    },
  };
  return <BaseDialog {...baseDialogProps}>{props.content && <QuizQuestionCardContent {...props.content} />}</BaseDialog>;
};

QuizResourcePreviewDialog.displayName = "QuizResourcePreviewDialog";

export default QuizResourcePreviewDialog;
