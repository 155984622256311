import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import SelectableQuestion, { SelectableQuestionProps } from "./parts/SelectableQuestion/SelectableQuestion";

const TitleOnlyHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: 16,
  fontWeight: 400,
}));

type TextOnlyQuestion = {
  title: string;
};

export type QuestionItemProps = TextOnlyQuestion | SelectableQuestionProps;

const QuestionItem: React.FC<QuestionItemProps> = props => {
  if (!("items" in props)) {
    return (
      <Box>
        <TitleOnlyHeading>{props.title}</TitleOnlyHeading>
      </Box>
    );
  }
  return <SelectableQuestion {...props} />;
};

QuestionItem.displayName = "QuestionItem";

export default QuestionItem;
