import { BillingInvoices } from "@hireroo/app-store/widget/e/BillingInvoices";
import { formatPrice } from "@hireroo/formatter/money";
import { unixToDateFormat } from "@hireroo/formatter/time";
import type { Widget } from "@hireroo/presentation";

export type GenerateInvoicesPropsArgs = {};

export const useGenerateProps = (_args: GenerateInvoicesPropsArgs): Widget.InvoicesProps => {
  const invoices = BillingInvoices.useInvoices();
  const page = BillingInvoices.usePage();
  const count = BillingInvoices.useInvoiceCount();
  const fetchSize = BillingInvoices.useFetchSize();
  return {
    rows: invoices.map((invoice): Widget.InvoicesProps["rows"][0] => {
      return {
        price: formatPrice(invoice.totalPrice ?? 0),
        status: invoice.status === "PAID" ? "PAID" : "NOT_PAID",
        billingDate: unixToDateFormat(invoice.createdAtSeconds ?? 0),
        dueDate: unixToDateFormat(invoice.dueDateSeconds ?? 0),
        downloadUrl: invoice.invoiceUrl,
      };
    }),
    pagination: {
      count: count,
      rowsPerPage: fetchSize,
      page: page,
      rowsPerPageOptions: [fetchSize],
      onPageChange: (_event, value) => {
        BillingInvoices.updatePage(value);
      },
    },
  };
};
