import * as React from "react";

import { useSystemDesignContext } from "../../../store";
import ScaleControlPaper from "../ScaleControlPaper/ScaleControlPaper";

export type ScaleControlProps = {};

const ScaleControl: React.FC<ScaleControlProps> = () => {
  const Store = useSystemDesignContext();
  const scale = Store.hooks.useScale();
  const handleZoomOut = React.useCallback(() => {
    const newScale = (Math.round(scale * 10) - 1) / 10;
    Store.action.zoomViewbox({ scale: newScale });
  }, [Store.action, scale]);

  const handleZoomIn = React.useCallback(() => {
    const newScale = (Math.round(scale * 10) + 1) / 10;
    Store.action.zoomViewbox({ scale: newScale });
  }, [Store.action, scale]);

  return <ScaleControlPaper scale={scale} handleZoomIn={handleZoomIn} handleZoomOut={handleZoomOut} />;
};

ScaleControl.displayName = "ScaleControl";

export default ScaleControl;
