import { RemoteInterviewPlayback } from "@hireroo/app-store/widget/e/RemoteInterviewPlayback";
import * as Graphql from "@hireroo/graphql/client/urql";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

import RemoteInterviewChallengePlaybackInitialContainer from "./RemoteInterviewChallengePlayback/InitialContainer";
import RemoteInterviewSystemDesignPlaybackInitialContainer from "./RemoteInterviewSystemDesignPlayback/InitialContainer";
import { useGenerateHeaderProps } from "./useGenerateHeaderProps";

type SelectorType = "languages" | "componentTypes";

const selectorTypeMap: Record<Graphql.RemoteQuestionVariant, SelectorType> = {
  ALGORITHM: "languages",
  DATABASE: "languages",
  CLASS: "languages",
  SYSTEM_DESIGN: "componentTypes",
  EMPTY_CANVAS: "componentTypes",
  EMPTY_PAD: "languages",
  // TODO: omit unknown
  UNKNOWN: "languages",
};

export type GenerateRemoteInterviewPlaybackPropsArgs = {};

export const useGenerateProps = (_args: GenerateRemoteInterviewPlaybackPropsArgs): Widget.RemoteInterviewPlaybackProps => {
  const headerProps = useGenerateHeaderProps();
  const activeVariant = RemoteInterviewPlayback.useActiveVariant();
  const selectorType = selectorTypeMap[activeVariant];

  const ContentMap = React.useMemo((): Record<SelectorType, React.ReactNode> => {
    return {
      languages: <RemoteInterviewChallengePlaybackInitialContainer />,
      componentTypes: <RemoteInterviewSystemDesignPlaybackInitialContainer />,
    };
  }, []);

  return {
    header: headerProps,
    Content: React.useMemo(() => ContentMap[selectorType], [ContentMap, selectorType]),
  };
};
