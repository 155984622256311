import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import { ProjectFailureReason } from "@hireroo/graphql/server/types";
import { useCurrentLanguage, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { useHelpCenterNavigate } from "@hireroo/router/hooks";
import * as React from "react";

import * as PrivateHelper from "./privateHelper";

export type GenerateWorkspaceNotificationSectionPropsArgs = {
  kindOfReview: PrivateHelper.Kind;
  entityId: number;
  uniqueKey: ProjectTestReport.UniqueKey;
  testId: string;
};

type SingleMessageProps = Exclude<Widget.ProjectTestReportProps["workspaceNotificationSection"], undefined>["singleMessage"];

export const useGenerateWorkspaceNotificationSectionProps = (
  args: GenerateWorkspaceNotificationSectionPropsArgs,
): Widget.ProjectTestReportProps["workspaceNotificationSection"] => {
  const lang = useCurrentLanguage();
  const helpCenterNavigate = useHelpCenterNavigate(lang);
  const hooks = ProjectTestReport.useCreateProjectHooks(args.entityId);
  const submissionId = hooks.useCurrentSelectedSubmissionId();
  const submissionStatus = hooks.useCurrentSubmissionStatus();
  const failureReason = hooks.useCurrentSubmissionFailureReason();

  const { t } = useTranslation();

  const singleMessageProps = React.useMemo((): SingleMessageProps => {
    switch (submissionStatus) {
      case "ENQUEUED": {
        return {
          text: t("提出されたコードは採点中です。"),
        };
      }
      case "UNKNOWN": {
        return {
          text: t("提出されたコードは採点に失敗しました。お手数ですが運営側にお問い合わせください、"),
        };
      }
    }
  }, [submissionStatus, t]);

  const failureMessageMap: Record<ProjectFailureReason, string> = {
    WORKSPACE_PREPARATION_FAILED: t("評価用の環境の構築に失敗したため評価に失敗しました。運営までお問合せください。"),
    SERVER_HEALTH_CHECK_FAILED: t(
      "サーバーの起動が確認できなかったため評価に失敗しました。ソースコードのビルドに失敗したか、候補者がヘルスチェックの実装に変更を加えた可能性があります。ソースコードをご確認ください。",
    ),
    EVALUATION_PREPARATION_FAILED: t(
      "評価プロセス中にエラーが発生したため評価に失敗しました。候補者が評価用に用意されている既存のエンドポイントの実装に変更を加えた可能性があります。ソースコードをご確認ください。",
    ),
    UNKNOWN: t("予期しないエラーによりテストケースの実行に失敗しました。運営までお問合せください。"),
  };

  return {
    additionalMessages: submissionStatus === "FAILED" && failureReason ? [failureMessageMap[failureReason]] : [],
    helpLink: {
      onClick: () => {
        helpCenterNavigate("PROJECT_EVALUATION_ERROR", { _blank: true });
      },
    },
    singleMessage: singleMessageProps,
    url: PrivateHelper.getWorkspaceUrl({
      kind: args.kindOfReview,
      projectId: args.entityId,
      submissionId,
      testId: args.testId,
    }),
  };
};
