import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Role } from "@hireroo/app-store/essential/employee";
import { Assessments } from "@hireroo/app-store/page/e/assessments";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { formatScore } from "@hireroo/formatter/score";
import * as Time from "@hireroo/formatter/time";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/react";

type ItemProps = Widget.AssessmentListForEmployeeProps["items"][0];

export type GenerateAssessmentListForEmployeePropsArgs = {
  defaultSortFieldValue: string;
  onRefresh: () => void;
};

export const useGenerateProps = (args: GenerateAssessmentListForEmployeePropsArgs): Widget.AssessmentListForEmployeeProps => {
  const assessments = Assessments.useAssessments();
  const numOfAssessment = Assessments.useNumberOfAssessment();
  const pager = Assessments.usePager();
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const navigate = useTransitionNavigate();
  const client = getGraphqlClient();
  const [resultOfUpdateAssessmentReportShareSettings, updateAssessmentReportShareSettings] =
    Graphql.useUpdateAssessmentReportShareSettingsForEmployeeAssessmentsMutation();
  const matchingRole = Role.useMatchingRole();
  const currentRoleDisplayText = Role.useCurrentRoleDisplayText();
  const expectedRole = Role.useRoleToText("MANAGER");

  return {
    countText: t2("SearchResultCount", { count: numOfAssessment }),
    sortField: {
      defaultValue: args.defaultSortFieldValue,
      options: [
        {
          displayName: t("作成日時が新しい順"),
          value: Assessments.SortFields.CREATED_AT_DESCENDING,
        },
        {
          displayName: t("作成日時が古い順"),
          value: Assessments.SortFields.CREATED_AT_ASCENDING,
        },
        {
          displayName: t("スコアが高い順"),
          value: Assessments.SortFields.RELATIVE_SCORE_DESCENDING,
        },
        {
          displayName: t("スコアが低い順"),
          value: Assessments.SortFields.RELATIVE_SCORE_ASCENDING,
        },
      ],
      disabled: false,
      onChange: value => {
        Assessments.updateSortField(value as Assessments.SortFieldValue);
      },
    },
    items: assessments.map((assessment): ItemProps => {
      const { previousBestExam: previousExam, latestBestExam: currentExam, talent, viewers } = assessment;
      const previousExamCount = previousExam?.numInterval ?? -1;
      const currentExamCount = currentExam?.numInterval ?? 0;
      return {
        title: assessment.name,
        status: assessment.status === "RUNNING" ? "ACTIVE" : "NOT_ACTIVE",
        schedule: {
          text: currentExam
            ? [
                t2("numOfExam", {
                  num: currentExamCount,
                }),
                "・",
                currentExam.createdAtSeconds !== null ? Time.unixToDatetimeFormat(currentExam.createdAtSeconds) : "",
                " - ",
                currentExam.willEndAtSeconds !== null ? Time.unixToDatetimeFormat(currentExam.willEndAtSeconds) : "",
              ].join("")
            : "-",
          tooltipText: t("テスト可能期間"),
        },
        viewer: {
          text: viewers.length
            ? t2("numViewers", {
                num: viewers.length,
              })
            : t("全員閲覧可能"),
          tooltipText: viewers.length
            ? t("閲覧者") +
              " " +
              viewers
                .map(viewer => {
                  if (viewer.__typename === "User") return viewer.displayName || `No Name(${viewer.email})`;
                  if (viewer.__typename === "EmployeeGroup") return viewer.groupName;
                  return "";
                })
                .filter(Boolean)
                .join(", ")
            : "",
        },
        talent: {
          text: talent.displayName || talent.email,
          tooltipText: t("タレント"),
        },
        previousResult:
          previousExamCount >= 1
            ? {
                no: t2("numOfExam", {
                  num: previousExamCount,
                }),
                value: (() => {
                  if (!previousExam) {
                    return { kind: "NOT_SUBMITTED" };
                  }
                  if (previousExam.status === "COMPLETED") {
                    return { kind: "UNDER_EVALUATION" };
                  }
                  if (previousExam.status === "EXPIRED") {
                    return { kind: "EXPIRED" };
                  }
                  if (previousExam.rankEvaluation !== "UNKNOWN") {
                    if (previousExam.isReliableRank) {
                      return {
                        kind: "RANK",
                        rank: previousExam.rankEvaluation,
                        score: formatScore(previousExam.relativeScore).toString(),
                      };
                    }
                    return {
                      kind: "RANK",
                      rank: "UNKNOWN",
                      score: formatScore(previousExam.relativeScore).toString(),
                      tooltip: t("提出時点のランクを表示するためのデータ数が足りないため表示できません。"),
                    };
                  }
                  return { kind: "NOT_SUBMITTED" };
                })(),
              }
            : undefined,
        currentResult:
          currentExamCount >= 1
            ? {
                no: t2("numOfExam", {
                  num: currentExamCount,
                }),
                value: (() => {
                  if (!currentExam) {
                    return { kind: "NOT_SUBMITTED" };
                  }
                  if (currentExam.status === "COMPLETED") {
                    return { kind: "UNDER_EVALUATION" };
                  }
                  if (currentExam.status === "EXPIRED") {
                    return { kind: "EXPIRED" };
                  }
                  if (currentExam.rankEvaluation !== "UNKNOWN") {
                    if (currentExam.isReliableRank) {
                      return {
                        kind: "RANK",
                        rank: currentExam.rankEvaluation,
                        score: formatScore(currentExam.relativeScore).toString(),
                      };
                    }
                    return {
                      kind: "RANK",
                      rank: "UNKNOWN",
                      score: formatScore(currentExam.relativeScore).toString(),
                      tooltip: t("提出時点のランクを表示するためのデータ数が足りないため表示できません。"),
                    };
                  }
                  return { kind: "NOT_SUBMITTED" };
                })(),
                mark: (() => {
                  if (assessment.scoreTrend === "UP") return "UP";
                  if (assessment.scoreTrend === "DOWN") return "DOWN";
                  return undefined;
                })(),
              }
            : undefined,
        actionMenu: {
          title: assessment.name,
          openShareSettingDialogButton: {
            disabled: !matchingRole.ADMIN_OR_MANAGER.matched,
            disabledText: t2("PermissionTooltip", {
              actualRole: currentRoleDisplayText,
              expectedRole: expectedRole,
            }),
          },
          editButton: {
            disabled: !matchingRole.ADMIN_OR_MANAGER.matched,
            disabledText: t2("PermissionTooltip", {
              actualRole: currentRoleDisplayText,
              expectedRole: expectedRole,
            }),
            onClick: () => {
              navigate("/e/assessments/:id/update", {
                pathParams: {
                  id: assessment.assessmentId,
                },
              });
            },
          },
          changeActiveStatusButton: {
            disabled: !matchingRole.ADMIN_OR_MANAGER.matched,
            disabledText: t2("PermissionTooltip", {
              actualRole: currentRoleDisplayText,
              expectedRole: expectedRole,
            }),
          },
          deleteButton: {
            disabled: !matchingRole.ADMIN_OR_MANAGER.matched,
            disabledText: t2("PermissionTooltip", {
              actualRole: currentRoleDisplayText,
              expectedRole: expectedRole,
            }),
          },
          activeStatus: assessment.status === "RUNNING" ? "ACTIVE" : "NOT_ACTIVE",
          onDelete: () => {
            client
              .DeleteAssessmentForAssessmentsIdDetail({
                input: {
                  assessmentId: assessment.assessmentId,
                },
              })
              .then(() => {
                Snackbar.notify({
                  message: t("タレントスコアの削除に成功しました。"),
                  severity: "success",
                });
                args.onRefresh();
                Assessments.addDeletedAssessmentId(assessment.assessmentId);
              })
              .catch(error => {
                Sentry.captureException(error);
                const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                  error,
                  t("タレントスコアの削除に失敗しました。しばらくしてから再度お試し下さい。"),
                );
                Snackbar.notify({
                  severity: "error",
                  message: errorNotification.message,
                });
              });
          },
          onChangeActiveStatus: () => {
            client
              .UpdateAssessmentForEmployeeAssessments({
                input: {
                  assessmentId: assessment.assessmentId,
                  assessmentStatus: assessment.status === "RUNNING" ? "SUSPENDED" : "RUNNING",
                },
              })
              .then(() => {
                Snackbar.notify({
                  message: t("タレントスコアの更新に成功しました。"),
                  severity: "success",
                });
                args.onRefresh();
              })
              .catch(error => {
                Sentry.captureException(error);
                const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                  error,
                  t("タレントスコアの更新に失敗しました。しばらくしてから再度お試し下さい。"),
                );
                Snackbar.notify({
                  severity: "error",
                  message: errorNotification.message,
                });
              });
          },
          reportShareSettings: {
            title: t2("reportPublicScopeSettings", { name: assessment.name }),
            disabled: resultOfUpdateAssessmentReportShareSettings.fetching,
            onSubmit: fields => {
              updateAssessmentReportShareSettings({
                input: {
                  assessmentId: assessment.assessmentId,
                  reportSettings: {
                    ...fields,
                  },
                },
              }).then(res => {
                if (res.error) {
                  Sentry.captureException(res.error);
                  Snackbar.notify({
                    message: t("タレントスコアの更新に失敗しました。"),
                    severity: "error",
                  });
                } else {
                  if (res.data?.updateAssessment) {
                    Assessments.updateReportShareSettings(res.data.updateAssessment);
                  }
                  Snackbar.notify({
                    message: t("タレントスコアの更新に成功しました。"),
                    severity: "success",
                  });
                }
              });
            },
            defaultValues: {
              showAnswer: assessment.reportSettings?.showAnswer ?? true,
              showPlayback: assessment.reportSettings?.showPlayback ?? true,
              showRelativeEvaluation: assessment.reportSettings?.showRelativeEvaluation ?? true,
            },
          },
          /**
           * Only for Devloper
           */
          onCreateImmediately: undefined,
        },
        href: generatePath("/e/assessments/:id/detail", {
          pathParams: {
            id: assessment.assessmentId,
          },
        }),
        onClick: () => {
          navigate("/e/assessments/:id/detail", {
            pathParams: {
              id: assessment.assessmentId,
            },
          });
        },
      };
    }),
    tablePagination: {
      /** If setting 50, check the size of the response. */
      rowsPerPageOptions: [20, 30],
      count: numOfAssessment,
      page: pager.page,
      onPageChange: (_, nextPage) => {
        Assessments.updatePage(nextPage);
      },
      rowsPerPage: pager.size,
      onRowsPerPageChange: event => {
        const newSize = Math.trunc(Number(event.target.value));
        Assessments.updatePageSize(newSize);
      },
    },
  };
};
