import { useTranslation } from "@hireroo/i18n";
import { alpha } from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import * as React from "react";

const SELECTED_PLAN_CLASS_NAME = "selected-plan";

const TrialLabel = styled(Typography)(({ theme }) => ({
  height: 17,
  lineHeight: "16px",
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  borderRadius: 20,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  display: "inline",
  fontSize: 10,
  position: "absolute",
  top: 0,
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: theme.zIndex.appBar,
  boxShadow: `0 4px 4px 0 ${theme.palette["Primary/Shades"].p16}`,
}));

const PlanName = styled(Typography)(() => ({
  fontWeight: "bold",
  fontSize: 16,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  textAlign: "center",
  position: "relative",
  borderBottom: "none",
  [`&.${SELECTED_PLAN_CLASS_NAME}`]: {
    background: `linear-gradient(${alpha(theme.palette.primary.main, 0.12)}, transparent)`,
  },
}));

export type FeatureTableHeadCellProps = {
  kind: "TRIAL" | "SELECTED" | "NONE";
  planName: string;
};

const FeatureTableHeadCell: React.FC<FeatureTableHeadCellProps> = props => {
  const { t } = useTranslation();
  return (
    <StyledTableCell className={props.kind !== "NONE" ? SELECTED_PLAN_CLASS_NAME : undefined}>
      {props.kind === "TRIAL" && <TrialLabel variant="caption">{t("現在トライアル利用中")}</TrialLabel>}
      {props.kind === "SELECTED" && <TrialLabel variant="caption">{t("現在のプラン")}</TrialLabel>}
      <PlanName mb={0.5}>{props.planName}</PlanName>
    </StyledTableCell>
  );
};

FeatureTableHeadCell.displayName = "FeatureTableHeadCell";

export default FeatureTableHeadCell;
