import { QUESTION_VARIANT_REVERSE_MAP } from "@hireroo/app-definition/question";
import * as Graphql from "@hireroo/graphql/client/urql";
import { QuestionPackageSearchForm } from "@hireroo/validator";

const DEFAULT_SIZE = 20;
const SIZES = [20, 30, 50];

export const convertStringToSize = (value: string | null): number => {
  if (value === null) return DEFAULT_SIZE;
  const size = Number(value);
  // Set it to the default value if it is invalid.
  if (!SIZES.includes(size)) {
    return DEFAULT_SIZE;
  }
  return size;
};

export const convertStringsToPage = (value: string | null): number => {
  if (value === null) return 0;
  const page = Number(value);
  // Set it to 0 if it is invalid (NaN or negative). Note that page > 0 is false if page is NaN.
  // Actual (zero-index) page number = (page number from the query param) - 1
  if (page < 1) {
    return 0;
  }
  return page - 1;
};

export const convertStringsToQuestionVariants = (values: string[]): Graphql.QuestionVariant[] => {
  const newQuestionVariants: Graphql.QuestionVariant[] = [];
  for (const value of values) {
    value.split("%").forEach(v => {
      // Check if the string value can be converted to a valid questionVariant.
      if (QUESTION_VARIANT_REVERSE_MAP[v]) {
        newQuestionVariants.push(QUESTION_VARIANT_REVERSE_MAP[v]);
      }
    });
  }
  return newQuestionVariants;
};

const DifficultyReversMap: Record<string, QuestionPackageSearchForm.DifficultySchema[0]> = {
  [Graphql.Difficulty.Easy]: "EASY",
  [Graphql.Difficulty.Medium]: "MEDIUM",
  [Graphql.Difficulty.Difficult]: "DIFFICULT",
};

export const convertStringsToDifficulties = (values: string[]): QuestionPackageSearchForm.DifficultySchema => {
  const newDifficulties: QuestionPackageSearchForm.DifficultySchema = [];
  for (const value of values) {
    value.split("%").forEach(v => {
      // Check if the string value can be converted to a valid difficulty.
      if (DifficultyReversMap[v]) {
        newDifficulties.push(DifficultyReversMap[v]);
      }
    });
  }
  return newDifficulties;
};

export const SortMethodReverseMap: Record<string, "created-at"> = {
  [Graphql.QuestionPackageSortMethod.MethodCreatedAt]: "created-at",
};
export const convertStringToSortFieldValue = (value: string | null): QuestionPackageSearchForm.SortFieldValue => {
  if (value === null) return "created-at-descending";
  try {
    // "sortField+isDesc" (e.g.) "CREATED_AT-true"
    const [field, isDesc] = value.split("-");
    const sortField = SortMethodReverseMap[field] ?? "created-at";
    const isDescString = isDesc === "true" ? "descending" : "ascending";
    return `${sortField}-${isDescString}` as QuestionPackageSearchForm.SortFieldValue;
  } catch (_) {
    return "created-at-descending";
  }
};
