import { LanguageMap, languageMap } from "@hireroo/app-definition";
import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { LiveCoding } from "@hireroo/app-helper/hooks";
import { Auth } from "@hireroo/app-store/essential/employee";
import { RemoteInterviewTutorial } from "@hireroo/app-store/widget/shared/RemoteInterviewTutorial";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/server/types";
import { useChangeLanguage, useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateCurrentOriginUrl, generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import { useHelpCenterNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/browser";
import React from "react";

import RemoteInterviewParticipantsContainer from "../../../../../../widget/v2/shared/RemoteInterviewParticipants/Container";
import RemoteInterviewTimerContainer from "../../../../../../widget/v2/shared/RemoteInterviewTimer/Container";
import LiveCodingPreJoinScreenContainer from "../LiveCodingPreJoinScreen/Container";

export type GenerateRemoteInterviewEmployeeHeaderPropsArgs = {
  remoteId: string;
  collaborativeAction: LiveCoding.CollaborativeAction;
};

export const useGenerateProps = (args: GenerateRemoteInterviewEmployeeHeaderPropsArgs): Widget.RemoteInterviewEmployeeHeaderProps => {
  const { collaborativeAction } = args;
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const helpCenterNavigate = useHelpCenterNavigate(lang);
  const [status, setStatus] = React.useState<"READY" | "PENDING">("READY");

  const url = generateCurrentOriginUrl("/c/remotes/:id", {
    pathParams: {
      id: args.remoteId,
    },
  });
  const navigate = useTransitionNavigate();
  const client = getGraphqlClient();
  const currentUser = Auth.useCurrentUser();
  const changeLanguage = useChangeLanguage();
  const updateLanguage = React.useCallback(
    async (lang: Graphql.Language): Promise<void> => {
      await client
        .UpdateUserForUserProfile({
          updateUserInput: {
            id: currentUser.uid,
            displayName: currentUser.displayName,
            language: lang,
            photoUrl: currentUser.photoUrl,
          },
        })
        .then(res => {
          changeLanguage(LanguageMap[res.updateUser.language]);
          Snackbar.notify({
            severity: "success",
            message: t("ユーザ情報を更新しました。"),
          });
          Auth.setUser(res.updateUser);
        })
        .catch(error => {
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(
            error,
            t("ユーザ情報の更新に失敗しました。しばらくしてから再度お試し頂くか、ヘルプボタンよりお問い合わせください。"),
          );
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
        });
    },
    [changeLanguage, client, currentUser.displayName, currentUser.photoUrl, currentUser.uid, t],
  );

  return {
    brandLogo: {
      href: generatePath("/e/home"),
      target: "_blank",
    },
    endTestDialog: {
      yesButton: {
        disabled: status === "PENDING",
        onClick: () => {
          setStatus("PENDING");
          client
            .EndRemoteInterview({
              input: {
                remoteId: args.remoteId,
              },
            })
            .then(() => {
              collaborativeAction.finishLiveCoding();
              Snackbar.notify({
                severity: "success",
                message: t("インタビューが終了しました。"),
              });
              navigate("/e/remotes/:id/evaluate", {
                pathParams: {
                  id: args.remoteId,
                },
              });
            })
            .catch(error => {
              Sentry.captureException(error);
              const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                error,
                t("インタビューの終了に失敗しました。しばらくしてから再度お試しください。"),
              );
              Snackbar.notify({
                severity: "error",
                message: errorNotification.message,
              });
            })
            .finally(() => {
              setStatus("READY");
            });
        },
      },
      noButton: {
        disabled: status === "PENDING",
      },
    },
    copyShareLinkButton: {
      url: url,
      onCopy: () => {
        Snackbar.notify({
          severity: "info",
          message: t("クリップボードにコピーされました"),
        });
      },
      buttonWithTooltip: {
        title: t("候補者向けの共有リンクをコピーできます"),
      },
    },
    finishInterviewButton: {
      disabled: false,
    },
    VideoButton: <LiveCodingPreJoinScreenContainer />,
    helpMenu: {
      options: [
        {
          text: t("チュートリアル再開"),
          value: "tutorial",
          icon: "LIVE_HELP",
          onClick: () => {
            RemoteInterviewTutorial.updateRunTutorial(true);
          },
        },
        {
          text: t("ヘルプ"),
          value: "caution",
          icon: "INFO",
          onClick: () => {
            helpCenterNavigate("ROOT", { _blank: true });
          },
        },
      ],
    },
    preferences: {
      languageDropDownMenu: {
        onChangeLanguage: language => {
          updateLanguage(languageMap[language]);
        },
      },
    },
    Participants: <RemoteInterviewParticipantsContainer />,
    Timelimit: <RemoteInterviewTimerContainer />,
  };
};
