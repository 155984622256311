import { languageMapForDisplay, useTranslation } from "@hireroo/i18n";
import { AlgorithmQuestionForm } from "@hireroo/validator";
import Article from "@mui/icons-material/Article";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import EditLanguageTab, { EditLanguageTabProps } from "../../../usecase/EditLanguageTab/EditLanguageTab";
import QuestionTabContent from "./QuestionTabContent";

export type QuestionTextFieldsProps = {
  languageTab: Pick<EditLanguageTabProps, "menu" | "onTabsChange">;
};

const QuestionTextFields: React.FC<QuestionTextFieldsProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const methods = useFormContext<AlgorithmQuestionForm.AlgorithmQuestionFormSchema>();

  const questionsField = useFieldArray({
    control: methods.control,
    name: "questions",
  });

  const questions = methods.getValues("questions");

  const editLanguageTab: EditLanguageTabProps = {
    menu: props.languageTab.menu,
    onTabsChange: props.languageTab.onTabsChange,
    addButton: {
      disabled: questions?.[0]?.title === "" || questions?.[0]?.description === "",
    },
    items: (questions || []).map((question, index) => {
      return {
        id: index.toString(),
        name: languageMapForDisplay[question.language],
        Content: <QuestionTabContent key={question.language} index={index} lang={question.language} />,
        tab: {
          closeButton: {
            onClick: () => {
              questionsField.remove(index);
            },
          },
        },
      };
    }),
  };

  return (
    <>
      <Box mt={5}>
        <Box display="flex" alignItems="center">
          <Box display="flex" alignItems="center">
            <Article fontSize="small" sx={{ color: theme.palette.common.white }} />
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }} ml={1}>
              {t("問題概要を入力する")}
            </Typography>
          </Box>
        </Box>

        <Box mt={2}>
          <Typography variant="body2">
            {t("問題で利用するタイトルや本文を入力します。問題で利用する言語は複数入力可能です。メインで利用する言語以外の入力は任意です。")}
          </Typography>
        </Box>
      </Box>

      <Box mt={4}>
        <EditLanguageTab {...editLanguageTab} />
      </Box>
    </>
  );
};

QuestionTextFields.displayName = "QuestionTextFields";

export default QuestionTextFields;
