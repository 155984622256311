import { useTheme } from "@mui/material";
import Link, { LinkProps } from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type AddToSlackButtonProps = {
  slackOauthLink: string;
};

const AddToSlackButton: React.FC<AddToSlackButtonProps> = props => {
  const theme = useTheme();
  const link: LinkProps = {
    href: props.slackOauthLink,
    style: {
      alignItems: "center",
      color: theme.palette.text.primary,
      border: "1px solid",
      borderColor: theme.palette.divider,
      borderRadius: "4px",
      display: "inline-flex",
      fontSize: "14px",
      fontWeight: 600,
      height: "36px",
      justifyContent: "center",
      textDecoration: "none",
      width: "150px",
    },
  };
  return (
    <Link {...link}>
      <Typography fontSize={14}>ADD TO SLACK</Typography>
    </Link>
  );
};

AddToSlackButton.displayName = "AddToSlackButton";

export default AddToSlackButton;
