import { proxy } from "valtio";
import { proxyMap } from "valtio/utils";

import { createDefaultState } from "./definition";
import type * as Types from "./types";

export const createState = (initialState?: Partial<Types.State>) => {
  const defaultState = createDefaultState(initialState);
  return proxy<Types.State>(defaultState);
};

export const multiState = proxyMap<string, Types.State>();
