import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";

type Difficulty = "EASY" | "MEDIUM" | "DIFFICULT" | "UNKNOWN";

const StyledRating = styled(Rating)(({ theme }) => ({
  fontSize: "1em",
  ".MuiRating-iconFilled": {
    color: theme.palette.warning.light,
  },
}));

const difficultyStars: Record<Difficulty, number> = {
  EASY: 1,
  MEDIUM: 2,
  DIFFICULT: 3,
  UNKNOWN: 1,
};

const MAX_STAR_COUNT = 3;

export type DifficultyStarsProps = {
  className?: string;
  difficulty: Difficulty;
};

const DifficultyStars: React.FC<DifficultyStarsProps> = props => {
  const { t } = useTranslation();
  const displayStarCount = difficultyStars[props.difficulty] ?? 1;
  const difficultyMap: Record<Difficulty, string> = {
    EASY: t("易しい"),
    MEDIUM: t("ふつう"),
    DIFFICULT: t("難しい"),
    UNKNOWN: t("不明"),
  };
  return (
    <Tooltip title={difficultyMap[props.difficulty] || t("不明")} placement="bottom-start">
      <Box display="flex" alignItems="center" className={props.className}>
        <StyledRating name="difficulty" size="small" readOnly value={displayStarCount} max={MAX_STAR_COUNT} />
      </Box>
    </Tooltip>
  );
};

DifficultyStars.displayName = "DifficultyStars";

export default DifficultyStars;
