export const removeDuplicatedDirectories = (indexes: string[]): string[] => {
  const files: string[] = [];
  const directories: string[] = [];
  for (const index of indexes) {
    const isDirectory = index.endsWith("/");
    if (isDirectory) {
      directories.push(index);
    } else {
      files.push(index);
    }
  }

  return directories
    .filter(directory => {
      return !files.some(
        file =>
          file.startsWith(directory) &&
          !directories.some(otherDirectory => otherDirectory !== directory && otherDirectory.startsWith(directory)),
      );
    })
    .concat(files);
};
