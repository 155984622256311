import { DeepReadonly } from "@hireroo/app-helper/types";
import { useSnapshot } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useCurrentTab = () => {
  const snapshot = useSnapshotState();
  return snapshot.currentTab;
};

export const useInitialized = () => {
  const snapshot = useSnapshotState();
  return !!snapshot.interview;
};

export const useInterview = (): DeepReadonly<Types.Interview> => {
  const snapshot = useSnapshotState();
  if (!snapshot.interview) {
    throw new Error("need to initialize");
  }
  return snapshot.interview;
};

export const usePdfExport = (spotId: Types.SpotId) => {
  const snapshot = useSnapshotState();
  return snapshot.pdfExports[spotId];
};

export const useSharedLink = (): Types.SharedLink | null => {
  const snapshot = useSnapshotState();
  return snapshot.sharedLink;
};

export const useDialogStatus = (): Types.DialogStatus => {
  const snapshot = useSnapshotState();
  return snapshot.dialogStatus;
};

export const useSpotForRetake = (): DeepReadonly<Types.SpotForRetake> | null => {
  const snapshot = useSnapshotState();
  return snapshot.spotForRetake;
};

export const useIsSpotForRetakeLoading = (): boolean => {
  const snapshot = useSnapshotState();
  return snapshot.isSpotForRetakeLoading;
};
