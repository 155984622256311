import { useTranslation } from "@hireroo/i18n";
import DescriptionOutlined from "@mui/icons-material/DescriptionOutlined";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import QuestionListItem, { QuestionListItemProps } from "./parts/QuestionListItem/QuestionListItem";

const CardWrapper = styled(Box)(({ theme }) => ({
  borderStartStartRadius: theme.shape.borderRadius * 2,
  borderStartEndRadius: theme.shape.borderRadius * 2,
  borderEndStartRadius: theme.shape.borderRadius * 2,
  borderEndEndRadius: theme.shape.borderRadius * 2,
  border: `solid 1px ${theme.palette["Secondary/Shades"].p16}`,
  backgroundColor: theme.palette["Secondary/Shades"].p12,
}));

const CardTopWrapper = styled(Box)(({ theme }) => ({
  borderStartStartRadius: theme.shape.borderRadius * 2,
  borderStartEndRadius: theme.shape.borderRadius * 2,
  backgroundColor: theme.palette["Secondary/Shades"].p12,
}));

const CardBottomWrapper = styled(Box)(({ theme }) => ({
  borderEndStartRadius: theme.shape.borderRadius * 2,
  borderEndEndRadius: theme.shape.borderRadius * 2,
}));

export type QuestionListSelectorProps = {
  questionListItems: QuestionListItemProps[];
  submittedNum: number;
  totalNum: number;
};

const QuestionListSelector: React.FC<QuestionListSelectorProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <CardWrapper>
      <CardTopWrapper px={2} py={1.5} display="flex" alignItems="center" gap={1} justifyContent="space-between">
        <Stack direction="row" spacing={1} alignItems="center">
          <DescriptionOutlined fontSize="small" sx={{ color: theme.palette.grey[400] }} />
          <Typography variant="subtitle2">{t("問題一覧")}</Typography>
        </Stack>
        <Typography fontSize={12} color="textSecondary">
          {t("提出済")} {props.submittedNum}/{props.totalNum}
        </Typography>
      </CardTopWrapper>
      <CardBottomWrapper>
        <List dense sx={{ overflowY: "auto", height: "60vh" }}>
          {props.questionListItems.map(listItem => (
            <QuestionListItem key={listItem.id} {...listItem} />
          ))}
        </List>
      </CardBottomWrapper>
    </CardWrapper>
  );
};

QuestionListSelector.displayName = "QuestionListSelector";

export default QuestionListSelector;
