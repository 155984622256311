import { useTranslation } from "@hireroo/i18n";
import { AssessmentResourceEditorForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import ResourceField from "../../../../modules/ResourceFieldV2/ResourceFieldV2";
import InputControlTextField, {
  InputControlTextFieldProps,
} from "../../../../primitive/InputControl/InputControlTextField/InputControlTextField";
import { type LayoutController, useAssessmentResourceEditorV2Context } from "../../Context";

type FieldName = keyof AssessmentResourceEditorForm.TestInviteSetupFormSchema;

const ReservedFieldName = {
  TALENT: "talent",
  MESSAGE_FOR_TALENT: "messageForTalent",
} satisfies Record<string, FieldName>;

const Subsection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  boxShadow: "none",
}));

export type TestInviteSetupSectionProps = {
  onSubmit: SubmitHandler<AssessmentResourceEditorForm.TestInviteSetupFormSchema>;
  defaultValues: AssessmentResourceEditorForm.TestInviteSetupFormSchema;
  TalentField: React.ReactNode;
};

const TestInviteSetupSection: React.FC<TestInviteSetupSectionProps> = props => {
  const { t } = useTranslation();
  const { onSubmit } = props;

  const { stepName, setController, submitValues, setSubmitValue } = useAssessmentResourceEditorV2Context();
  const validateSchema = AssessmentResourceEditorForm.useTestInviteSetupForm();
  const methods = useForm<AssessmentResourceEditorForm.TestInviteSetupFormSchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: submitValues.TEST_INVITE_SETUP ?? props.defaultValues,
  });

  const controller = React.useMemo((): LayoutController => {
    return {
      checkGoNextStep: () => {
        return new Promise<boolean>(resolve => {
          methods.handleSubmit(
            fields => {
              onSubmit(fields);
              setSubmitValue("TEST_INVITE_SETUP", fields);
              resolve(true);
            },
            errors => {
              console.warn(errors);
              setSubmitValue("TEST_INVITE_SETUP", undefined);
              resolve(false);
            },
          )();
        });
      },
    };
  }, [methods, onSubmit, setSubmitValue]);

  React.useEffect(() => {
    setController("TEST_INVITE_SETUP", controller);
  }, [setController, controller]);

  const messageForCandidateFieldProps: InputControlTextFieldProps = {
    fullWidth: true,
    minRows: 3,
    maxRows: 10,
    multiline: true,
    placeholder: t("実装中はソースコードの意図が分かるように、コメントをできるだけ書いてください。"),
  };

  if (stepName !== "TEST_INVITE_SETUP") {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <Stack py={2} spacing={1.5}>
        <Subsection>
          <ResourceField
            label={t("タレント招待")}
            kind="REQUIRED"
            pb={2}
            help={{
              kind: "DESCRIPTION",
              text: t("このタレントスコアを受けるタレントを招待してください。（※あとから編集することはできません。）"),
            }}
          >
            {props.TalentField}
          </ResourceField>
        </Subsection>
        <Subsection>
          <ResourceField
            label={t("タレントへのメッセージ")}
            kind="NONE"
            pb={2}
            help={{
              kind: "DESCRIPTION",
              text: t("タレントに対し伝達したい事項がある場合、メッセージを残すことができます。"),
            }}
          >
            <InputControlTextField name={ReservedFieldName.MESSAGE_FOR_TALENT} {...messageForCandidateFieldProps} />
          </ResourceField>
        </Subsection>
      </Stack>
    </FormProvider>
  );
};

TestInviteSetupSection.displayName = "TestInviteSetupSection";

export default TestInviteSetupSection;
