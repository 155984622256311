import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import ReplayIcon from "@mui/icons-material/ReplayOutlined";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { styled, useTheme } from "@mui/material/styles";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import * as React from "react";

const Wrapper = styled(Box)`
  z-index: 10;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const SearchBarWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette["Secondary/Shades"].p12,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: `4px 12px 4px 12px`,
  height: 36,
}));

const SearchBar = styled(TextField)(({ theme }) => ({
  height: 24,
  p: 0,
  backgroundColor: theme.palette.Other.FilledInputBG,
  borderRadius: 20,
  fontSize: 14,
  marginRight: 8,
  ".MuiOutlinedInput-root": {
    color: theme.palette.text.disabled,
    padding: 0,
    height: 24,
  },
  ".MuiInputBase-input": {
    paddingLeft: 8,
    paddingRight: 8,
  },
  ".MuiOutlinedInput-notchedOutline": {
    backgroundColor: theme.palette["Secondary/Shades"].p12,
    border: "none",
    borderRadius: 8,
    padding: 0,
  },
}));

const ICON_SIZE = 20;

export type ProjectUIFrameProps = {
  url: string;
  openWindowButton: Pick<IconButtonProps, "onClick">;
  loading: boolean;
};

const ProjectUIFrame: React.FC<ProjectUIFrameProps> = props => {
  const [iframeKey, setIframeKey] = React.useState<string>("");
  const theme = useTheme();

  const reloadButtonProps: IconButtonProps = {
    onClick: () => {
      setIframeKey(Math.random().toString());
    },
    size: "small",
    sx: {
      width: ICON_SIZE,
      height: ICON_SIZE,
      mr: 1,
    },
    children: <ReplayIcon sx={{ width: ICON_SIZE, height: ICON_SIZE, color: theme.palette.action.disabled }} />,
  };

  const openWindowButtonProps: IconButtonProps = {
    ...props.openWindowButton,
    size: "small",
    sx: {
      width: ICON_SIZE,
      height: ICON_SIZE,
    },
    children: <OpenInNewOutlinedIcon sx={{ width: ICON_SIZE, height: ICON_SIZE, color: theme.palette.action.disabled }} />,
  };

  const textFieldProps: TextFieldProps = {
    value: props.url,
    size: "small",
    variant: "outlined",
    InputProps: {
      endAdornment: <IconButton {...reloadButtonProps} />,
    },
    inputProps: { readOnly: true },
    fullWidth: true,
  };

  return (
    <Wrapper>
      <SearchBarWrapper>
        <SearchBar {...textFieldProps} />
        <IconButton {...openWindowButtonProps} />
      </SearchBarWrapper>
      {props.loading ? (
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"} height={"100%"}>
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <iframe key={iframeKey} id={"ui-frame"} title={"ui-frame"} width={"100%"} height={"100%"} src={props.url}></iframe>
      )}
    </Wrapper>
  );
};

ProjectUIFrame.displayName = "ProjectUIFrame";

export default ProjectUIFrame;
