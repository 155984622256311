import { proxy } from "valtio";
import { proxyMap } from "valtio/utils";

import type * as Types from "./types";

export const state = proxy<Types.State>({
  quizId: null,
  packageId: null,
  questionId: null,
  answerTextMap: proxyMap(),
});
