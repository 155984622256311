import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";

import ReportDetailSummary, { ReportDetailSummaryProps } from "../../modules/ReportDetailSummary/ReportDetailSummary";
import ReportPageFooter, { ReportPageFooterProps } from "../../modules/ReportPageFooter/ReportPageFooter";

const Wrapper = styled(Box)(() => ({
  minHeight: "100%",
}));

type EntityId = string;

type Report = {
  id: EntityId;
  smoothScrollTargetId: string;
  Content: React.ReactNode;
};

export type ScreeningTestReportProps = {
  summary: ReportDetailSummaryProps;
  Reports: Report[];
  footer: ReportPageFooterProps;
  selectedEntityId: string;
  targetElementId: string;
};

const ScreeningTestReport: React.FC<ScreeningTestReportProps> = props => {
  const ReportMap = React.useMemo((): Record<EntityId, React.ReactNode> => {
    return props.Reports.reduce((prev, current) => {
      return {
        ...prev,
        [current.id]: current.Content,
      } satisfies Record<EntityId, React.ReactNode>;
    }, {});
  }, [props.Reports]);
  return (
    <Box position="relative">
      <Wrapper p={3}>
        <ReportDetailSummary {...props.summary} />

        <Box id={props.targetElementId}>{ReportMap[props.selectedEntityId]}</Box>
      </Wrapper>
      <ReportPageFooter {...props.footer} />
    </Box>
  );
};

ScreeningTestReport.displayName = "ScreeningTestReport";

export default ScreeningTestReport;
