import { useTranslation } from "@hireroo/i18n";
import { Add } from "@mui/icons-material";
import Box from "@mui/material/Box";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, useFieldArray } from "react-hook-form";

import BaseDialog, { BaseDialogProps } from "../../primitive/BaseDialog/BaseDialog";
import AcceptButton from "../../primitive/Button/AcceptButton";
import { useAlgorithmFunctionDefinitionContext } from "./Context";
import FunctionArgumentsInput from "./parts/FunctionArgumentsInput";
import FunctionTypeSelect, { FunctionTypeSelectProps } from "./parts/FunctionTypeSelect";

export {
  AlgorithmFunctionDefinitionProvider,
  type AlgorithmFunctionDefinitionProviderProps,
  useAlgorithmFunctionDefinitionContext,
} from "./Context";

export type AlgorithmFunctionDefinitionProps = {
  TemplatePreview: React.ReactNode;
  breakingChangeDialog: Omit<BaseDialogProps, "title"> & { message?: string };
};

const AlgorithmFunctionDefinition: React.FC<AlgorithmFunctionDefinitionProps> = props => {
  const { t } = useTranslation();
  const { methods } = useAlgorithmFunctionDefinitionContext();

  const inputsField = useFieldArray({
    control: methods.control,
    name: "inputs",
  });
  const inputs = methods.getValues("inputs");
  const addParams = () => {
    inputsField.append({ name: "", type: "string" });
  };
  const removeParams = (index: number) => () => {
    inputsField.remove(index);
  };
  const outputType: FunctionTypeSelectProps = {
    value: methods.getValues("outputType"),
    name: "outputType",
    label: t("返り値の型"),
  };
  const breakingChangeDialog: BaseDialogProps = {
    ...props.breakingChangeDialog,
    yesButton: {
      ...props.breakingChangeDialog.yesButton,
      children: t("はい"),
    },
    noButton: {
      ...props.breakingChangeDialog.noButton,
      children: t("いいえ"),
    },
    title: t("関数定義に破壊的変更が検知されました。"),
  };
  return (
    <FormProvider {...methods}>
      <Box display="flex" justifyContent="space-between" gap={6}>
        <Box display="flex" flexDirection="column" width="100%">
          <Box mb={2}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              {t("関数の基本定義")}
            </Typography>
          </Box>

          <Box>
            <Box mb={2} display="flex" alignItems="center">
              <Box mr={2}>
                <Typography>{t("引数の定義")}</Typography>
              </Box>
              <AcceptButton size="small" startIcon={<Add fontSize="small" />} onClick={addParams} variant="text">
                {t("引数を追加する").toString()}
              </AcceptButton>
            </Box>

            <Box>
              {inputsField.fields.map((field, index) => (
                <FunctionArgumentsInput
                  key={field.id}
                  index={index}
                  deleteButton={{
                    onClick: removeParams(index),
                    disabled: inputs.length <= 1,
                  }}
                  functionTypeSelect={{ label: t("引数の型"), value: field.type }}
                />
              ))}
            </Box>
            <Box mt={2}>
              <Box display="flex" alignItems="center">
                <Typography>{t("返り値の定義")}</Typography>
              </Box>

              <Box width="50%" mt={2}>
                <FunctionTypeSelect {...outputType} />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box height="100%" width="50%">
          {props.TemplatePreview}
        </Box>
      </Box>
      <BaseDialog {...breakingChangeDialog}>
        <DialogContentText role="message">{props.breakingChangeDialog.message}</DialogContentText>
      </BaseDialog>
    </FormProvider>
  );
};

AlgorithmFunctionDefinition.displayName = "AlgorithmFunctionDefinition";

export default AlgorithmFunctionDefinition;
