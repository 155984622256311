import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import React from "react";

import { CommentElement, FontSizeMap, OPERATION_TYPE, OperationType, UnionSettingsFields } from "../../../../../features";

export type CommentSettingsFormProps = {
  element: CommentElement;
  updateSettings: (id: string, updates: UnionSettingsFields, operationType: OperationType) => void;
};

const StyledHeading = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  color: "text.secondary",
}));

export const CommentSettingsForm: React.FC<CommentSettingsFormProps> = (props: CommentSettingsFormProps) => {
  const { t } = useTranslation();

  const handleToggleFontSize = (e: React.MouseEvent<HTMLElement>, value: number) => {
    props.updateSettings(props.element.id, { fontSize: value }, OPERATION_TYPE.do);
  };

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <StyledHeading>{t("フォントサイズ")}</StyledHeading>
      <ToggleButtonGroup color="secondary" value={props.element.settings.fontSize} exclusive onChange={handleToggleFontSize}>
        <ToggleButton value={FontSizeMap["small"]}>S</ToggleButton>
        <ToggleButton value={FontSizeMap["medium"]}>M</ToggleButton>
        <ToggleButton value={FontSizeMap["large"]}>L</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

CommentSettingsForm.displayName = "CommentSettingsForm";

export default CommentSettingsForm;
