import { ReDesign } from "@hireroo/validator";
import * as React from "react";

const EXPERIMENTAL_KEY = "ui/experimental";
export const setExperimentalUiVersion = (version: "v1" | "alpha") => {
  const data: ReDesign.ReDesignExperimentalSchema = {
    version: version,
  };
  window.localStorage.setItem(EXPERIMENTAL_KEY, JSON.stringify(data));
  window.location.reload();
};

export const getExperimentalUiInfo = () => {
  const data = window.localStorage.getItem(EXPERIMENTAL_KEY);
  if (data === null) {
    return null;
  }
  try {
    const parsedValue = ReDesign.ReDesignExperimental.safeParse(JSON.parse(data));
    if (parsedValue.success) {
      return parsedValue.data;
    }
    // clean up
    window.localStorage.removeItem(EXPERIMENTAL_KEY);
    return null;
  } catch {
    window.localStorage.removeItem(EXPERIMENTAL_KEY);
    return null;
  }
};

export type PageKey = "";

export const setUiPageVersion = (pageKey: PageKey, version: "v1" | "v2") => {
  const data: ReDesign.ReDesignSchema = {
    version: version,
  };
  const KEY = `ui/page/${pageKey}`;
  window.localStorage.setItem(KEY, JSON.stringify(data));
  window.location.reload();
};

export const getUiPageInfo = (pageKey: PageKey) => {
  const KEY = `ui/page/${pageKey}`;
  const data = window.localStorage.getItem(KEY);
  if (data === null) {
    return null;
  }
  try {
    const parsedValue = ReDesign.ReDesign.safeParse(JSON.parse(data));
    if (parsedValue.success) {
      return parsedValue.data;
    }
    // clean up
    window.localStorage.removeItem(KEY);
    return null;
  } catch {
    window.localStorage.removeItem(KEY);
    return null;
  }
};

export const useEnabledHomeRedesignPhaseTwo = () => {
  return React.useMemo(() => {
    return window.localStorage.getItem("ENABLED_HOME_REDESIGN_PHASE_TWO") === "true";
  }, []);
};
