import { Widget } from "@hireroo/presentation/legacy";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type NotificationBannerContainerProps = {};

const NotificationBannerContainer: React.FC<NotificationBannerContainerProps> = () => {
  const props = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.NotificationBanner {...props} />
    </ErrorBoundary>
  );
};

NotificationBannerContainer.displayName = "NotificationBannerContainer";

export default withErrorBoundary(NotificationBannerContainer, {});
