/**
 * TODO Move this to @hireroo/project
 */
import * as z from "zod";

export const generateBackendCommand = () => {
  return z.object({
    path: z.string(),
    query: z.string().optional(),
    headers: z.record(z.string(), z.string()),
    method: z.string(),
    body: z.string(),
    expected: z.string(),
    status: z.number(),
    timeout: z.number(),
  });
};

export type BackendCommandSchema = z.infer<ReturnType<typeof generateBackendCommand>>;

export const generateBackendFixtureCommand = () => {
  return z.object({
    path: z.string(),
    query: z.string().optional(),
    headers: z.record(z.string(), z.string()),
    method: z.string(),
    body: z.string(),
    timeout: z.number(),
  });
};

export type BackendFixtureCommandSchema = z.infer<ReturnType<typeof generateBackendFixtureCommand>>;

export const generateBackendCorrectnessTestCase = () => {
  const command = generateBackendCommand();
  const fixtureCommand = generateBackendFixtureCommand();
  return z.object({
    test_case_commands: command.array(),
    setup_commands: fixtureCommand.array(),
    teardown_commands: fixtureCommand.array(),
    description_ja: z.string(),
    description_en: z.string(),
    is_hidden: z.boolean(),
  });
};

export type BackendCorrectnessTestCaseSchema = z.infer<ReturnType<typeof generateBackendCorrectnessTestCase>>;

export const generateBackendCorrectnessTestCases = () => {
  const testCase = generateBackendCorrectnessTestCase();
  return z.object({
    version: z.string(),
    test_cases: testCase.array(),
  });
};

export type BackendCorrectnessTestCasesSchema = z.infer<ReturnType<typeof generateBackendCorrectnessTestCases>>;

export const generateBackendResultPerCommand = () => {
  return z.object({
    latency: z.number(),
    is_passed: z.boolean(),
    status: z.number(),
    expected: z.string(),
    output: z.string(),
  });
};

export type BackendResultPerCommand = z.infer<ReturnType<typeof generateBackendResultPerCommand>>;

export const generateBackendCorrectnessTestResultSchema = () => {
  const result = generateBackendResultPerCommand();
  return z.object({
    results: result.array(),
    is_passed: z.boolean(),
    status: z.enum(["EVALUATED", "FAILED"]),
    failure_reason: z.enum(["", "SERVER_HEALTH_CHECK_FAILED", "EVALUATION_PREPARATION_FAILED"]),
  });
};

export type BackendCorrectnessTestResultSchema = z.infer<ReturnType<typeof generateBackendCorrectnessTestResultSchema>>;

export const generateBackendCorrectnessTestResultsSchema = () => {
  const result = generateBackendCorrectnessTestResultSchema();
  return z.object({
    version: z.string(),
    test_results: result.array(),
  });
};

export type BackendCorrectnessTestResultsSchema = z.infer<ReturnType<typeof generateBackendCorrectnessTestResultsSchema>>;

export const generateBackendPerformanceTestCaseResult = () => {
  return z.object({
    is_passed: z.boolean(),
    avg: z.number(),
    min: z.number(),
    max: z.number(),
    p50: z.number(),
    p90: z.number(),
    p95: z.number(),
    p99: z.number(),
    rps: z.number(),
    sla: z.number(),
    s100: z.number(),
    s200: z.number(),
    s300: z.number(),
    s400: z.number(),
    s500: z.number(),
    status: z.enum(["EVALUATED", "FAILED"]),
    failure_reason: z.enum(["", "SERVER_HEALTH_CHECK_FAILED", "EVALUATION_PREPARATION_FAILED"]),
  });
};

export type BackendPerformanceTestCaseResultSchema = z.infer<ReturnType<typeof generateBackendPerformanceTestCaseResult>>;

export const generateBackendPerformanceTestCaseResults = () => {
  const result = generateBackendPerformanceTestCaseResult();
  return z.object({
    version: z.string(),
    test_results: result.array(),
  });
};

export type BackendPerformanceTestCaseResultsSchema = z.infer<ReturnType<typeof generateBackendPerformanceTestCaseResults>>;
