import { App, Company } from "@hireroo/app-store/essential/employee";
import { BillingInvoices } from "@hireroo/app-store/widget/e/BillingInvoices";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import InvoicesContainer, { InvoicesContainerProps } from "./Container";

export type InvoicesFetchContainerProps = {};

const InvoicesFetchContainer: React.FC<InvoicesFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const pager = BillingInvoices.usePager();
  const companyId = Company.useStrictActiveCompanyId();
  const fetchSize = BillingInvoices.useFetchSize();
  const fetchable = BillingInvoices.useFechable();

  const [result, refresh] = Graphql.useGetInvoicesForSubscriptionInvoiceV2Query({
    variables: {
      customerId: companyId,
      cursorSeconds: pager?.cursorSeconds ?? null,
      cursorNanoSeconds: pager?.cursorNanoSeconds ?? null,
      size: fetchSize,
    },
    pause: appStatus !== "INITIALIZED" || !fetchable,
    requestPolicy: "network-only",
  });
  React.useEffect(() => {
    BillingInvoices.clear();
    return () => {
      BillingInvoices.clear();
    };
  }, []);
  React.useEffect(() => {
    if (result.data) {
      BillingInvoices.initialize(result.data.invoicesV2);
    }
  }, [result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }

  if (!result.data || result.fetching || appStatus !== "INITIALIZED") {
    return <Widget.Loading kind="TOP" />;
  }

  const containerProps: InvoicesContainerProps = {};

  return <InvoicesContainer {...containerProps} />;
};

InvoicesFetchContainer.displayName = "InvoicesFetchContainer";

export default withErrorBoundary(InvoicesFetchContainer, {});
