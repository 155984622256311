import { useMethods } from "@hireroo/react-split";
import * as React from "react";

export const SplitParams = {
  splitId: "ChallengeCodingEditor",
  Contents: {
    LeftSideBar: {
      id: "LeftSideBar",
    },
    Editor: {
      id: "Content",
    },
    RightSideBar: {
      id: "RightSideBar",
    },
  },
};

export const SplitParamsInEditorAndTestCasesSection = {
  splitId: "EditorAndTestCasesSection",
  Contents: {
    Editor: {
      id: "Editor",
    },
    Terminal: {
      id: "Terminal",
    },
  },
};

export const useSplitEvent = () => {
  const methods = useMethods();
  const [sidebarVisibleStatus, setSidebarVisibleStatus] = React.useState<"MINIMIZED" | "OPEN">("OPEN");
  const [terminalVisibleStatus, setTerminalVisibleStatus] = React.useState<"MINIMIZED" | "OPEN">("OPEN");
  const [detailInfoVisibleStatus, setDetailInfoVisibleStatus] = React.useState<"MINIMIZED" | "OPEN">("OPEN");
  React.useEffect(() => {
    const cleanup1 = methods.subscribeChangeEvent(SplitParams.splitId, event => {
      if (event.type === "MINIMIZED") {
        if (event.contentId === SplitParams.Contents.LeftSideBar.id) {
          setSidebarVisibleStatus("MINIMIZED");
        }
        if (event.contentId === SplitParamsInEditorAndTestCasesSection.Contents.Terminal.id) {
          setTerminalVisibleStatus("MINIMIZED");
        }
        if (event.contentId === SplitParams.Contents.RightSideBar.id) {
          setDetailInfoVisibleStatus("MINIMIZED");
        }
        return;
      }
      if (event.type === "RELEASE_MINIMIZED") {
        if (event.contentId === SplitParams.Contents.LeftSideBar.id) {
          setSidebarVisibleStatus("OPEN");
        }
        if (event.contentId === SplitParamsInEditorAndTestCasesSection.Contents.Terminal.id) {
          setTerminalVisibleStatus("OPEN");
        }
        if (event.contentId === SplitParams.Contents.RightSideBar.id) {
          setDetailInfoVisibleStatus("OPEN");
        }
        return;
      }
    });
    const cleanup2 = methods.subscribeChangeEvent(SplitParamsInEditorAndTestCasesSection.splitId, event => {
      if (event.type === "MINIMIZED") {
        if (event.contentId === SplitParamsInEditorAndTestCasesSection.Contents.Terminal.id) {
          setTerminalVisibleStatus("MINIMIZED");
        }
        return;
      }
      if (event.type === "RELEASE_MINIMIZED") {
        if (event.contentId === SplitParamsInEditorAndTestCasesSection.Contents.Terminal.id) {
          setTerminalVisibleStatus("OPEN");
        }
        return;
      }
    });
    return () => {
      cleanup1();
      cleanup2();
    };
  }, [methods]);

  return {
    sidebarVisibleStatus,
    setSidebarVisibleStatus,
    terminalVisibleStatus,
    setTerminalVisibleStatus,
    detailInfoVisibleStatus,
    setDetailInfoVisibleStatus,
  };
};
