import { App } from "@hireroo/app-store/essential/candidate";
import { InterviewsIdDetailStore } from "@hireroo/app-store/page/c/interviews_id_detail";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

import ScreeningTestDetailForCandidateContainer, { ScreeningTestDetailForCandidateContainerProps } from "./Container";

export type ScreeningTestDetailForCandidateFetchContainerProps = {};

const ScreeningTestDetailForCandidateFetchContainer: React.FC<ScreeningTestDetailForCandidateFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const linkHash = searchParams.get("share") ?? "";
  const interviewId = `${id}`;
  const initialized = InterviewsIdDetailStore.useInitialized();

  const [result, refresh] = Graphql.useGetInterviewDetailFromSharedLinkQuery({
    variables: {
      spotId: interviewId,
      linkHash: searchParams.get("share") ?? "",
    },
    pause: appStatus !== "INITIALIZED",
  });

  React.useEffect(() => {
    if (result.data?.spotFromSharedLink) {
      InterviewsIdDetailStore.updateInterview(result.data.spotFromSharedLink);

      result.data?.spotFromSharedLink.sharedLinks.forEach(sharedLink => {
        if (sharedLink.linkHash === linkHash) {
          InterviewsIdDetailStore.updateSharedLink(sharedLink);
        }
      });
    }
  }, [linkHash, result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: ScreeningTestDetailForCandidateContainerProps = {
    interviewId: interviewId,
    linkHash: linkHash,
  };

  return <ScreeningTestDetailForCandidateContainer {...containerProps} />;
};

ScreeningTestDetailForCandidateFetchContainer.displayName = "ScreeningTestDetailForCandidateFetchContainer";

export default withErrorBoundary(ScreeningTestDetailForCandidateFetchContainer, {});
