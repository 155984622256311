import FiberManualRecord from "@mui/icons-material/FiberManualRecord";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import CustomSVGSlider, { CustomSVGSliderProps } from "../CustomSVGSlider/CustomSVGSlider";

const TOOL_BAR_SIDE_WIDTH = 164;
const HEIGHT = 40;
const TimeLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[400],
  fontSize: 12,
}));

export type ParticipantSliderProps = {
  id: string;
  iconColor: string;
  name: string;
  slider: CustomSVGSliderProps;
  remainTime: string;
  passedTime: string;
};

const ParticipantSlider: React.FC<ParticipantSliderProps> = props => {
  return (
    <Box flexDirection="row" display="flex" height={HEIGHT}>
      <Box width={TOOL_BAR_SIDE_WIDTH} minWidth={TOOL_BAR_SIDE_WIDTH} overflow={"hidden"} display="flex" alignItems="center">
        <Stack direction={"row"} alignItems={"center"} pl={1} spacing={1} width={TOOL_BAR_SIDE_WIDTH}>
          <FiberManualRecord sx={{ fontSize: 14, color: props.iconColor }} />
          <Typography fontSize={14} textOverflow="ellipsis">
            {props.name}
          </Typography>
        </Stack>
      </Box>
      <Stack spacing={1.5} direction="row" width="100%" alignItems="center" px={3}>
        <TimeLabel>{props.passedTime}</TimeLabel>
        <CustomSVGSlider {...props.slider} />
        <TimeLabel>{props.remainTime}</TimeLabel>
      </Stack>
    </Box>
  );
};

ParticipantSlider.displayName = "ParticipantSlider";

export default ParticipantSlider;
