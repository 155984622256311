import { useTranslation } from "@hireroo/i18n";
import * as z from "zod";

export const useSingleSignOnSchema = () => {
  const { t } = useTranslation();
  return z.object({
    email: z
      .string({
        required_error: t("メールアドレスは必須項目です。"),
      })
      .regex(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, { message: t("メールアドレスが有効な形式ではありません。") }),
  });
};

export type SignInFormSchema = z.infer<ReturnType<typeof useSingleSignOnSchema>>;

export const useMailAddressSchema = () => {
  const { t } = useTranslation();
  return z.object({
    email: z
      .string()
      .min(1, { message: t("メールアドレスは必須項目です。") })
      .regex(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, { message: t("メールアドレスが有効な形式ではありません。") }),
    password: z.string().min(6, { message: t("パスワードは最低6文字以上で入力してください。") }),
  });
};

export type MailAddressSchema = z.infer<ReturnType<typeof useMailAddressSchema>>;
