import * as z from "zod";

export const StartDemoFromScreening = z.object({
  kind: z.literal("SCREENING"),
  screeningId: z.string(),
  invitationCode: z.string().nullable(),
});

export const StartDemoFromSpot = z.object({
  kind: z.literal("SPOT"),
  spotId: z.string(),
});

export const StartDemoParams = z.union([StartDemoFromScreening, StartDemoFromSpot]);

export type StartDemoParams = z.infer<typeof StartDemoParams>;
