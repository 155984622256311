import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";

import TitleWithScoreBar, { TitleWithScoreBarProps } from "../../../modules/TitleWithScoreBar/TitleWithScoreBar";
import BackendPerformanceDetailSection, {
  ReportProjectBackendPerformanceDetailSectionProps,
} from "../ReportProjectBackendPerformanceDetailSection/ReportProjectBackendPerformanceDetailSection";
import FrontendPerformanceDetailSection, {
  ReportProjectFrontendPerformanceDetailSectionProps,
} from "../ReportProjectFrontendPerformanceDetailSection/ReportProjectFrontendPerformanceDetailSection";

export type ReportProjectPerformanceDetailSectionProps =
  | {
      kind: "BACKEND";
      scrollTargetElementId: string;
      content: ReportProjectBackendPerformanceDetailSectionProps;
      titleWithScoreBar: Pick<TitleWithScoreBarProps, "score">;
      alternativeMessage?: string;
    }
  | {
      kind: "FRONTEND";
      scrollTargetElementId: string;
      content: ReportProjectFrontendPerformanceDetailSectionProps;
      titleWithScoreBar: Pick<TitleWithScoreBarProps, "score">;
      alternativeMessage?: string;
    };

const ReportProjectPerformanceDetailSection: React.FC<ReportProjectPerformanceDetailSectionProps> = props => {
  const { t } = useTranslation();
  const titleWithScoreBarProps: TitleWithScoreBarProps = {
    ...props.titleWithScoreBar,
    title: t("パフォーマンス"),
    color: "info",
  };

  return (
    <Box id={props.scrollTargetElementId}>
      <TitleWithScoreBar {...titleWithScoreBarProps} mb={3} />

      {props.alternativeMessage ? (
        <Typography>{props.alternativeMessage}</Typography>
      ) : (
        <Box>
          {props.kind === "BACKEND" && <BackendPerformanceDetailSection {...props.content} />}
          {props.kind === "FRONTEND" && <FrontendPerformanceDetailSection {...props.content} />}
        </Box>
      )}
    </Box>
  );
};

ReportProjectPerformanceDetailSection.displayName = "ReportProjectPerformanceDetailSection";

export default ReportProjectPerformanceDetailSection;
