import { Auth } from "@hireroo/app-store/essential/employee";
import { Credential } from "@hireroo/app-store/essential/shared";
import { signOut } from "@hireroo/firebase";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation/legacy";
import { generateHelpCenterUrl } from "@hireroo/router/api";
import routes from "@hireroo/router/config";
import { useHelpCenterNavigate, useNavigate } from "@hireroo/router/hooks";

export type GeneratePageErrorPanelPropsArgs = {
  title: string;
  message: string;
  additionalMessage?: string;
};

export const useGenerateProps = (args: GeneratePageErrorPanelPropsArgs): Widget.ErrorPanelProps => {
  const { t } = useTranslation();
  const languageCode = useLanguageCode();
  const helpCenterNavigate = useHelpCenterNavigate(languageCode);
  const isSignIn = Auth.useIsSignedIn();
  const navigate = useNavigate();
  const userType = Credential.useUserType();

  const links = [
    {
      href: generateHelpCenterUrl(languageCode, "ROOT"),
      children: t("ヘルプセンター"),
      onClick: () => {
        helpCenterNavigate("ROOT", { _blank: true });
      },
    },
    userType === Graphql.UserType.Employee && {
      href: routes["/e/home"],
      children: t("ホームに戻る"),
      onClick: () => {
        navigate(routes["/e/home"]);
      },
    },
    userType === Graphql.UserType.Talent && {
      href: routes["/t/assessments"],
      children: t("ホームに戻る"),
      onClick: () => {
        navigate(routes["/t/assessments"]);
      },
    },
    isSignIn && {
      children: t("サインアウトする"),
      onClick: async () => {
        await signOut();
      },
    },
  ].flatMap(item => (item ? [item] : []));

  return {
    name: args.title,
    message: args.message,
    additionalMessage: args.additionalMessage,
    links: links,
  };
};
