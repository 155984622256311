import { DeepReadonly } from "@hireroo/app-helper/types";
import { useSnapshot } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = () => {
  const snapshot = useSnapshotState();
  return !!snapshot.assessment;
};

export const useRefresh = (): Types.RefreshCallback => {
  const snapshot = useSnapshotState();
  return snapshot.refresh;
};

export const useAssessment = (): DeepReadonly<Types.Assessment> => {
  const snapshot = useSnapshotState();
  if (!snapshot.assessment) {
    throw new Error("need to initialize");
  }

  return snapshot.assessment;
};

export const useExam = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.exam) {
    throw new Error("need to initialize");
  }

  return snapshot.exam;
};
