import { useTitle } from "@hireroo/app-helper/react-use";
import { App } from "@hireroo/app-store/essential/employee";
import { BuySelections } from "@hireroo/app-store/widget/e/BuySelections";
import { EmployeeSideBarContent } from "@hireroo/app-store/widget/e/EmployeeSideBarContent";
import { useTranslation } from "@hireroo/i18n";
import { Pages } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as React from "react";

import EmployeeHeaderRightContentContainer from "../../../../widget/v2/e/EmployeeHeaderRightContent/Container";
import EmployeeSideBarContentContainer from "../../../../widget/v2/e/EmployeeSideBarContent/Container";
import FooterContainer from "../../../../widget/v2/e/Footer/Container";
import { useNotificationBanner } from "../../../../widget/v2/e/NotificationBanner/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import BuySelectionFormFetchContainer, { BuySelectionFormFetchContainerProps } from "./widgets/BuySelectionForm/FetchContainer";
import PaymentCompletionContainer from "./widgets/PaymentCompletion/Container";
import PaymentConfirmationDetailsContainer, { PaymentConfirmationDetailsContainerProps } from "./widgets/PaymentConfirmationDetails/Container";

type ShowTarget = "EDITOR" | "CONFIRM_AND_TOS" | "END";

export type GenerateBuySelectionsPropsArgs = {};

export const useGenerateProps = (_args: GenerateBuySelectionsPropsArgs): Pages.CompanySettingsEditorProps => {
  const { t } = useTranslation();
  const navigate = useTransitionNavigate();
  const loading = App.useStatus() === "INITIALIZING";
  const showingTarget = BuySelections.useShowingTarget();
  const NotificationBannerContainer = useNotificationBanner();

  useTitle(t("選考数の購入"));

  const paymentContractCreateFormFetchContainerProps: BuySelectionFormFetchContainerProps = {};

  const paymentConfirmationDetailsContainerProps: PaymentConfirmationDetailsContainerProps = {
    onAccept: () => {
      BuySelections.updateShowingTarget("END");
    },
  };

  React.useEffect(() => {
    return () => {
      BuySelections.clear();
    };
  }, []);

  const ShowElementMap: Record<ShowTarget, React.ReactNode> = {
    EDITOR: <BuySelectionFormFetchContainer {...paymentContractCreateFormFetchContainerProps} />,
    CONFIRM_AND_TOS: <PaymentConfirmationDetailsContainer {...paymentConfirmationDetailsContainerProps} />,
    END: <PaymentCompletionContainer />,
  };

  return {
    layout: {
      openSidebar: EmployeeSideBarContent.useOpenSidebar(),
      onChangeOpenSidebar: open => {
        EmployeeSideBarContent.setOpenSidebar(open);
      },
      loading: loading,
      NotificationBanner: NotificationBannerContainer,
      HeaderRightContent: <EmployeeHeaderRightContentContainer />,
      SideBarContent: <EmployeeSideBarContentContainer />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      sideBarHeader: {
        logo: {
          href: generatePath("/e/home"),
          onClick: () => {
            navigate("/e/home");
          },
        },
      },
    },
    children: ShowElementMap[showingTarget],
    backButton: {
      onClick: () => {
        switch (showingTarget) {
          case "EDITOR": {
            BuySelections.clear();
            navigate("/e/settings/billing/overview");
            break;
          }
          case "CONFIRM_AND_TOS": {
            BuySelections.updateShowingTarget("EDITOR");
            break;
          }
          case "END": {
            BuySelections.clear();
            navigate("/e/settings/billing/overview");
            break;
          }
        }
      },
    },
  };
};
