import { App, Company } from "@hireroo/app-store/essential/employee";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

type SuggestionItem = Widget.EvaluationMetricSearchFieldProps["suggestions"][0];

type DisplaySources = Widget.EvaluationMetricSearchFieldProps["displaySources"];

export type GenerateEvaluationMetricSearchFieldPropsArgs = {};

export const useGenerateProps = (_args: GenerateEvaluationMetricSearchFieldPropsArgs): Widget.EvaluationMetricSearchFieldProps => {
  const { t } = useTranslation();
  const companyId = Company.useStrictActiveCompanyId();
  const appStatus = App.useStatus();

  const [result, refresh] = Graphql.useListEvaluationMetricsGroupsForEvaluationMetricGroupSearchFieldQuery({
    variables: {
      companyId: companyId,
      size: 30,
      cursor: null,
      withCount: false,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "network-only",
  });

  const suggestionsProps = React.useMemo((): SuggestionItem[] => {
    const suggestions: SuggestionItem[] = [];
    result.data?.evaluationMetricGroupsByCompany?.metricGroups?.forEach(metricGroup => {
      suggestions.push({
        itemId: metricGroup.id,
        valueId: metricGroup.title,
      });
    });
    return suggestions;
  }, [result.data?.evaluationMetricGroupsByCompany?.metricGroups]);

  const displaySourcesProps = React.useMemo((): DisplaySources => {
    const displayNames: DisplaySources = {};

    result.data?.evaluationMetricGroupsByCompany?.metricGroups?.forEach(metricGroup => {
      displayNames[metricGroup.metricGroupId.toString()] = {
        optionText: metricGroup.title,
        optionSubText: metricGroup.description,
        chipText: metricGroup.title,
      };
    });
    return displayNames;
  }, [result.data?.evaluationMetricGroupsByCompany?.metricGroups]);

  return {
    placeholder: t("評価指標を検索"),
    suggestions: suggestionsProps,
    displaySources: displaySourcesProps,
    loading: result.fetching,
    onFocus: () => {
      refresh();
    },
    title: t("評価指標名"),
  };
};
