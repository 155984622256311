import { useTranslation } from "@hireroo/i18n";
import type { TagForm } from "@hireroo/validator";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";

import TagDialog, { TagDialogProps } from "../../../../../../../../modules/TagDialog/TagDialog";
import MoreButton, { MoreButtonProps } from "../../../../../../../../primitive/Button/MoreButton/MoreButton";

type DialogController = {
  setLoading: (loading: boolean) => void;
  close: () => void;
};

export type ActionMenuProps = {
  disabled?: boolean;
  tooltipText?: string;
  tagDialog: Pick<TagDialogProps, "defaultValues" | "tagInputField"> & {
    onSubmit: (fields: TagForm.TagsSchema, controller: DialogController) => void;
  };
};

const ActionMenu: React.FC<ActionMenuProps> = props => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const tagDialogProps: TagDialogProps = {
    ...props.tagDialog,
    open,
    disabled: loading,
    onSubmit: fields => {
      const controller: DialogController = {
        close: () => {
          setOpen(false);
          setLoading(false);
        },
        setLoading: (newLoading: boolean) => {
          setLoading(newLoading);
        },
      };
      props.tagDialog.onSubmit(fields, controller);
    },
    onOpen: () => {
      setOpen(true);
    },
    onClose: () => {
      setOpen(false);
    },
  };
  const moreButtonProps: MoreButtonProps = {
    iconButton: {
      disabled: props.disabled,
    },
    variant: "vertical",
    options: [
      {
        startIcon: "EDIT",
        value: "tag-setting",
        displayName: t("タグ設定"),
        onClick: () => {
          setOpen(true);
        },
      },
    ],
  };
  const tooltipText = props.disabled ? props.tooltipText ?? "" : "";
  return (
    <Box>
      <Tooltip title={tooltipText}>
        <span>
          <MoreButton {...moreButtonProps}></MoreButton>
        </span>
      </Tooltip>
      <TagDialog key={`open-${open.toString()}`} {...tagDialogProps} />
    </Box>
  );
};

ActionMenu.displayName = "ActionMenu";

export default ActionMenu;
