import React from "react";

import { addPermissionChangeCallback, DeviceInfo, getDeviceInfo } from "../../media-device";

/**
 * This hook returns the list of available audio and video input devices.
 */
export const useDevices = () => {
  const [deviceInfo, setDeviceInfo] = React.useState<DeviceInfo>({
    audioInputDevices: [],
    videoInputDevices: [],
    hasAudioInputDevices: false,
    hasVideoInputDevices: false,
  });

  const getDevices = React.useCallback(async () => {
    const deviceInfo = await getDeviceInfo();
    setDeviceInfo(deviceInfo);
  }, []);

  React.useEffect(() => {
    void getDevices();

    // Workaround for first time loading on Firefox.
    // Firefox doesn't support the "camera" and "microphone" permission
    // https://developer.mozilla.org/en-US/docs/Web/API/Permissions_API#browser_compatibility
    const intervalId = setInterval(getDevices, 1000);
    if (deviceInfo.hasAudioInputDevices && deviceInfo.hasVideoInputDevices) {
      clearInterval(intervalId);
    }

    // Refresh the list of devices when the list of permissions changes.
    void addPermissionChangeCallback("camera", getDevices);
    void addPermissionChangeCallback("microphone", getDevices);

    // Refresh the list of devices when the list of devices changes.
    navigator.mediaDevices.addEventListener("devicechange", getDevices);
    return () => {
      clearInterval(intervalId);
      navigator.mediaDevices.removeEventListener("devicechange", getDevices);
    };
  }, [deviceInfo.hasAudioInputDevices, deviceInfo.hasVideoInputDevices, getDevices]);

  return deviceInfo;
};
