import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import * as React from "react";

import PlaybackToolbar, { PlaybackToolbarProps } from "../../modules/PlaybackToolbar/PlaybackToolbar";
import QuizPlayback, { QuizPlaybackProps } from "./parts/QuizPlayback/QuizPlayback";
import QuizPlaybackDialog, { QuizPlaybackDialogProps } from "./parts/QuizPlaybackDialog/QuizPlaybackDialog";

type Status = "READY" | "LOADING" | "NO_DATA";

export type QuizPlaybackEditorProps = {
  status: Status;
  playback: QuizPlaybackProps;
  playbackToolbar: Omit<PlaybackToolbarProps, "screenStatus">;
  playbackDialog?: QuizPlaybackDialogProps;
};

const QuizPlaybackEditor: React.FC<QuizPlaybackEditorProps> = props => {
  const { t } = useTranslation();

  const statusMap: Record<Status, React.ReactNode> = {
    LOADING: (
      <Box minHeight={500} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    ),
    NO_DATA: (
      <Box width="100%" height="100%">
        <Typography>{t("提出過程がありませんでした。")}</Typography>
      </Box>
    ),
    READY: (
      <Box width="100%">
        {/*id is used for target element scroll*/}
        <Paper id="quiz-playback" elevation={3} sx={{ borderRadius: "4px 4px 0 0", height: 400, overflow: "auto" }}>
          <Box p={4} height="100%">
            <QuizPlayback {...props.playback} />
          </Box>
        </Paper>
        <PlaybackToolbar {...props.playbackToolbar} screenStatus="DEFAULT" />

        {props.playbackDialog && <QuizPlaybackDialog {...props.playbackDialog} />}
      </Box>
    ),
  };

  return <>{statusMap[props.status]}</>;
};

QuizPlaybackEditor.displayName = "QuizPlaybackEditor";

export default QuizPlaybackEditor;
