import { generateTimestampFromDate } from "@hireroo/app-helper/parser";
import { TestRankVisualizer } from "@hireroo/app-store/widget/shared/TestRankVisualizer";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";

export type SubscribeQueryKeyArgs = {
  companyId: number;
  spotId: string;
};

type Spot = Graphql.SpotForTestRankVisualizerFragment;
type RequestParams = Graphql.ListSpotsByTagsForTestRankVisualizerQueryVariables;

/**
 * The fetch size is specified to match the maximum number of issues published in the past.
 *
 * @see https://hireroo.slack.com/archives/CSF6X18SE/p1719988318051659
 */
const FETCH_SIZE = 4000;

export const startSubscribeQueryKey = (args: SubscribeQueryKeyArgs) => {
  const client = getGraphqlClient();
  const fetchSpots = async (requestParams: RequestParams, spots: Spot[] = [], count = 0, page = 0): Promise<Spot[]> => {
    const firstPage = page === 0;
    if (!firstPage && count <= spots.length) {
      return spots;
    }
    const { spotsByCompanyId } = await client.ListSpotsByTagsForTestRankVisualizer({
      ...requestParams,
      spotsByCompanyId: {
        ...requestParams.spotsByCompanyId,
        withCount: page === 0,
        offset: page * FETCH_SIZE,
      },
    });
    if (!spotsByCompanyId) {
      throw new Error("No spots could be retrieved");
    }
    if (!spotsByCompanyId.hasNext || spotsByCompanyId.count === 0) {
      return spots.concat(spotsByCompanyId.spots);
    }
    return fetchSpots(requestParams, spots.concat(spotsByCompanyId.spots), firstPage ? spotsByCompanyId.count : count, page + 1);
  };

  return TestRankVisualizer.subscribeQueryKey(async query => {
    const requestParams: RequestParams = {
      spotsByCompanyId: {
        companyId: args.companyId,
        size: FETCH_SIZE,
        offset: 0,
        filters: {
          createdByList: [],
          name: "",
          statuses: [],
          tagNames: query.tags.filter(tag => tag.selected).map(tag => tag.value),
          rankEvaluations: [],
          minTotalScore: 0,
          maxTotalScore: 1,
          screeningIds: [],
          suspiciousDegrees: [],
        },
        sortMethod: Graphql.SpotsByCompanyIdSortMethod.CreatedAt,
        isDescending: false,
        withAnswers: false,
        withCount: true,
        withDeleted: true,
        // TODO: This is deprecated message so if delete from proto, please delete too.
        status: Graphql.SpotStatus.Unknown,
      },
    };
    const queryTagLength = query.tags.filter(tag => tag.selected).length;
    const spots = queryTagLength
      ? await fetchSpots(requestParams).catch(() => {
          TestRankVisualizer.setResult(query, {
            rank: null,
            statistics: null,
            status: "ERROR",
          });
          return null;
        })
      : [];
    if (spots === null) {
      return;
    }
    if (queryTagLength && spots.length === 0) {
      TestRankVisualizer.setResult(query, {
        rank: null,
        statistics: null,
        status: "NOT_ENOUGH",
      });
      return;
    }
    const spotIds: string[] = queryTagLength ? spots.map(spot => spot.id) : [];
    await client
      .GetSpotStatisticsAndRankForTestRankVisualizer({
        statisticsSource: {
          category: "SPOT",
          query: {
            spot: {
              minTimestamp: query.enableDate && query.startDate ? generateTimestampFromDate(query.startDate) : undefined,
              maxTimestamp: query.enableDate && query.endDate ? generateTimestampFromDate(query.endDate) : undefined,
              included: spotIds,
              companyId: query.scope === "COMPANY" ? args.companyId : undefined,
              numBins: 20,
            },
          },
        },
        rankSource: {
          category: "SPOT",
          query: {
            spot: {
              minTimestamp: query.enableDate && query.startDate ? generateTimestampFromDate(query.startDate) : undefined,
              maxTimestamp: query.enableDate && query.endDate ? generateTimestampFromDate(query.endDate) : undefined,
              included: spotIds,
              companyId: query.scope === "COMPANY" ? args.companyId : undefined,
              spotId: args.spotId,
            },
          },
        },
      })
      .then(({ statistics, rank }) => {
        TestRankVisualizer.setResult(query, {
          rank: rank,
          statistics: statistics.spotStatistics,
          status: rank.numSubset < 30 ? "NOT_ENOUGH" : "SATISFY",
        });
      })
      .catch(() => {
        TestRankVisualizer.setResult(query, {
          rank: null,
          statistics: null,
          status: "ERROR",
        });
      });
  });
};
