import FormHelperText from "@mui/material/FormHelperText";
import * as React from "react";
import { Control, Controller } from "react-hook-form";

import DateTimePicker, { DateTimePickerProps } from "../../DateTimePicker/DateTimePicker";

export type DateTimeControlProps = {
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any>;
  dateTimePicker: Omit<DateTimePickerProps, "datePicker"> & {
    datePicker: Omit<DateTimePickerProps["datePicker"], "onChange">;
  };
};

const DateTimeControl: React.FC<DateTimeControlProps & { name: string }> = props => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <DateTimePicker
            {...props.dateTimePicker}
            customInput={{ ...props.dateTimePicker.customInput, className: props.className, error: Boolean(error?.message) }}
            datePicker={{ ...props.dateTimePicker.datePicker, onChange: onChange, selected: value }}
          />
          {error?.message && <FormHelperText error>{error.message}</FormHelperText>}
        </>
      )}
    />
  );
};

DateTimeControl.displayName = "DateTimeControl";

export default DateTimeControl;
