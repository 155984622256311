import { ChallengeTestReport } from "@hireroo/app-store/view-domain/ChallengeTestReport";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import { Widget } from "@hireroo/presentation";

export type GenerateHeaderPropsArgs = {
  challengeId: number;
  showDetail: boolean;
};

export const useGenerateHeaderProps = (args: GenerateHeaderPropsArgs): Widget.ChallengeTestReportProps["header"] => {
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const challengeHooks = ChallengeTestReport.useCreateChallengeHooks(args.challengeId);
  const challenge = challengeHooks.useChallenge();

  return {
    title: [
      `${challenge?.challengeQuestion && resolveLanguage(challenge.challengeQuestion, lang, "title")} ${
        challenge?.challengeQuestion?.version ?? ""
      }`,
    ].join(" "),
    linkButton: {
      onClick: () => {
        Snackbar.notify({
          severity: "info",
          message: t("クリップボードにコピーされました"),
        });
      },
      url: window.location.href,
    },
    mode: (!args.showDetail && "WITHOUT_DETAIL") || undefined,
  };
};
