import { colorFromUserId } from "@hireroo/app-helper/color";
import { useTwilioVideoRoomContext } from "@hireroo/app-helper/hooks";
import { Auth } from "@hireroo/app-store/essential/employee";
import { RemotesId } from "@hireroo/app-store/page/e/remotes_id";
import { RemoteInterviewParticipants } from "@hireroo/app-store/widget/shared/RemoteInterviewParticipants";
import { useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import * as React from "react";

export type GenerateLiveCodingVideoChatGalleryLayoutPropsArgs = {};

export const useGenerateProps = (_: GenerateLiveCodingVideoChatGalleryLayoutPropsArgs): Widget.LiveCodingGalleryLayoutProps => {
  const { t } = useTranslation();
  const uid = Auth.useCurrentUid();
  const userInfoMap = RemoteInterviewParticipants.useUserInfoMap();
  const user = RemotesId.useEmployeeUser(uid ?? "");
  const userInfo = userInfoMap.get(user?.userId ?? "");
  const participantsSource = RemoteInterviewParticipants.useParticipants();
  const { localVideoTrack, isAudioEnabled, remoteParticipants, remoteAudioTracks, remoteAudioTracksMicOn, remoteVideoTracks } =
    useTwilioVideoRoomContext();

  const audioSpeakerProps = React.useMemo((): Widget.LiveCodingAudioSpeakerProps[] => {
    return remoteParticipants.map(participant => {
      const track = remoteAudioTracks[participant.identity] || undefined;
      return {
        id: participant.identity,
        audioTrack: track,
      };
    });
  }, [remoteAudioTracks, remoteParticipants]);

  const videoViewsProps = React.useMemo((): Widget.LiveCodingVideoViewProps[] => {
    // Add local video view
    const videoView: Widget.LiveCodingVideoViewProps = {
      avatar: {
        sx: { bgcolor: colorFromUserId(user.userId) },
        src: userInfo?.employee?.photoUrl || user.userName,
        alt: user.userName,
      },
      id: "local",
      title: t("あなた"),
      videoTrack: localVideoTrack && {
        track: localVideoTrack,
        isFrontFacing: true,
        isPortrait: false,
      },
      isMicOn: isAudioEnabled,
    };
    const participantVideoViews = remoteParticipants.map(participant => {
      const user = participantsSource.find(p => p.id === participant.identity);
      const userInfo = userInfoMap.get(participant.identity);
      const videoTrack = remoteVideoTracks[participant.identity] || undefined;
      const isVideoEnabled = videoTrack && videoTrack.isEnabled;
      const audioTrack = remoteAudioTracks[participant.identity] || undefined;
      const isMicOn = (audioTrack && remoteAudioTracksMicOn[audioTrack.sid]) || false;

      const displayName = user?.displayName || t("匿名ユーザー");

      return {
        id: participant.identity,
        avatar: {
          sx: { bgcolor: colorFromUserId(participant.identity) },
          src: userInfo?.employee?.photoUrl || displayName,
          alt: displayName,
        },
        title: displayName,
        videoTrack: isVideoEnabled
          ? {
              track: videoTrack,
              isFrontFacing: false,
              isPortrait: false,
            }
          : undefined,
        isMicOn: isMicOn,
      };
    });
    return [videoView, ...participantVideoViews];
  }, [
    user.userId,
    user.userName,
    userInfo?.employee?.photoUrl,
    t,
    localVideoTrack,
    isAudioEnabled,
    remoteParticipants,
    participantsSource,
    remoteVideoTracks,
    remoteAudioTracks,
    remoteAudioTracksMicOn,
    userInfoMap,
  ]);

  return {
    AudioComponents: audioSpeakerProps.map(audioSpeakerProps => {
      return <Widget.LiveCodingAudioSpeaker key={audioSpeakerProps.id} {...audioSpeakerProps} />;
    }),
    VideoComponents: videoViewsProps.map(videoViewProps => {
      return <Widget.LiveCodingVideoView key={videoViewProps.id} {...videoViewProps} />;
    }),
  };
};
