import { EventEmitter } from "events";

import { PlaybackTickManager } from "./PlaybackTickManager";
import type * as Types from "./types";

type ReceiveTickCallback = (tick: Types.PlaybackTick) => void;

const EventName = {
  RECEIVE_TICK: "receive:tick",
  MOVE: "move",
  MOVE_FORWARD: "move:forward",
  MOVE_BACKWARD: "move:backward",
} as const;

type MovePayload = { nextIndex: number };
type MoveCallback = (payload: MovePayload) => void;
type MoveForwardPayload = { currentIndex: number; nextIndex: number };
type MoveForwardCallback = (payload: MoveForwardPayload) => void;
type MoveBackwardPayload = { currentIndex: number; nextIndex: number };
type MoveBackwardCallback = (payload: MoveBackwardPayload) => void;

export class PlaybackManager {
  #timeStampList: Types.TimeStamp[] = [];
  #timeStampToIndexMap = new Map<Types.TimeStamp, number>();
  #currentTickIndex = 0;
  #emitter = new EventEmitter();

  constructor(private readonly playbackTickManager: PlaybackTickManager) {}

  public get currentTickIndex() {
    return this.#currentTickIndex;
  }

  public get lastTickIndex() {
    return this.#timeStampList.length - 1;
  }

  public get currentTimeStamp() {
    return this.#timeStampList.at(this.#currentTickIndex);
  }

  public get timeStamps() {
    return this.#timeStampList;
  }

  public get ticks() {
    return this.playbackTickManager.ticks;
  }

  public initialize() {
    this.playbackTickManager.mergeTickEvents();
    this.playbackTickManager.sortByTimeStamp();
    this.#timeStampList = this.playbackTickManager.getTimeStampList();
    this.#timeStampList.forEach((ts, index) => {
      this.#timeStampToIndexMap.set(ts, index);
    });
  }

  public reset = () => {
    this.#currentTickIndex = 0;
  };

  public onReceiveTickEvent(callback: ReceiveTickCallback) {
    this.#emitter.on(EventName.RECEIVE_TICK, callback);
    return () => {
      this.#emitter.off(EventName.RECEIVE_TICK, callback);
    };
  }

  public onMove(callback: MoveCallback) {
    this.#emitter.on(EventName.MOVE, callback);
    return () => {
      this.#emitter.off(EventName.MOVE, callback);
    };
  }

  public onMoveForward(callback: MoveForwardCallback) {
    this.#emitter.on(EventName.MOVE_FORWARD, callback);
    return () => {
      this.#emitter.off(EventName.MOVE_FORWARD, callback);
    };
  }

  public onMoveBackward(callback: MoveBackwardCallback) {
    this.#emitter.on(EventName.MOVE_BACKWARD, callback);
    return () => {
      this.#emitter.off(EventName.MOVE_BACKWARD, callback);
    };
  }

  private emitTickEvent() {
    if (this.#currentTickIndex === undefined) {
      return;
    }
    const ts = this.#timeStampList.at(this.#currentTickIndex);
    if (ts === undefined) {
      return;
    }
    const tick = this.playbackTickManager.findLatestTickBefore(ts);
    if (!tick) {
      return;
    }
    this.#emitter.emit(EventName.RECEIVE_TICK, tick);
  }

  public setTickIndex(newTickIndex: number) {
    const oldTickIndex = this.#currentTickIndex;
    if (newTickIndex === oldTickIndex) {
      return;
    }
    if (newTickIndex > oldTickIndex) {
      const payload: MoveForwardPayload = {
        currentIndex: oldTickIndex,
        nextIndex: newTickIndex,
      };
      this.#emitter.emit(EventName.MOVE_FORWARD, payload);
    } else if (newTickIndex < oldTickIndex) {
      const payload: MoveBackwardPayload = {
        currentIndex: oldTickIndex,
        nextIndex: newTickIndex,
      };
      this.#emitter.emit(EventName.MOVE_BACKWARD, payload);
    }
    const payload: MovePayload = {
      nextIndex: newTickIndex,
    };
    this.#emitter.emit(EventName.MOVE, payload);
    this.#currentTickIndex = newTickIndex;
    this.emitTickEvent();
  }

  public refresh() {
    this.emitTickEvent();
  }

  public debug() {
    this.playbackTickManager.debug();
  }

  public getIndexByTimeStamp(ts: number): number | undefined {
    return this.#timeStampToIndexMap.get(ts);
  }
}
