import { styled } from "@mui/material/styles";
import React, { forwardRef } from "react";

import { FONT_FAMILY_SET } from "../../../constants/font";

const TextSvg = styled("text")(() => ({
  WebkitTouchCallout: "none",
  WebkitUserSelect: "none",
  msUserSelect: "none",
  userSelect: "none",
  fontFamily: FONT_FAMILY_SET.join(","),
}));

export type CommentProps = {
  content: string;
  minX: number;
  minY: number;
  fontSize: number;
};

// https://ja.reactjs.org/docs/forwarding-refs.html#forwarding-refs-to-dom-components
export const Comment = React.memo(
  forwardRef<SVGTextElement, CommentProps>((props: CommentProps, ref) => {
    return (
      <TextSvg x={props.minX} y={props.minY} fontSize={props.fontSize} ref={ref}>
        {props.content.split("\n").map((line, i) => (
          <tspan x={props.minX} dy={"1.5em"} key={`line-{${i}}`}>
            {line !== "" ? line : " "}
          </tspan>
        ))}
      </TextSvg>
    );
  }),
);

Comment.displayName = "Comment";

export default Comment;
