import { useTranslation } from "@hireroo/i18n";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import ActivityDetail, { ActivityDetailProps } from "./parts/ActivityDetail/ActivityDetail";
import ActivityTimeline, { ActivityTimelineProps } from "./parts/ActivityTimeline/ActivityTimeline";

const Wrapper = styled(Box)(({ theme }) => ({
  maxWidth: 320,
  backgroundColor: theme.palette.background.default,
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  height: "100vh",
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  boxShadow: "none",
  borderRadius: 0,
  borderBottom: `1px solid ${theme.palette.Other.WeakDivider}`,
}));

const StyledStack = styled(Stack)(() => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

const IconWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  svg: {
    width: 16,
    height: 16,
  },
}));

export type ScreeningTestActivityLogProps = {
  detail: ActivityDetailProps;
  timeline: ActivityTimelineProps;
};

const ScreeningTestActivityLog: React.FC<ScreeningTestActivityLogProps> = props => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <StyledPaper>
        <Stack px={2} py={1.5} direction="row" spacing={1}>
          <IconWrapper>
            <InfoOutlinedIcon fontSize="small" />
          </IconWrapper>
          <Typography fontSize={14}>{t("詳細情報")}</Typography>
        </Stack>
      </StyledPaper>
      <StyledStack px={1} py={1} spacing={1.5}>
        <ActivityDetail {...props.detail} />
        <ActivityTimeline {...props.timeline} />
      </StyledStack>
    </Wrapper>
  );
};

ScreeningTestActivityLog.displayName = "ScreeningTestActivityLog";

export default ScreeningTestActivityLog;
