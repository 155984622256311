import { useTranslation } from "@hireroo/i18n";
import { EvaluationMetricsForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import ResourceField from "../../../../modules/ResourceField/ResourceField";
import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";
import InputControlTextField, {
  InputControlTextFieldProps,
} from "../../../../primitive/InputControl/InputControlTextField/InputControlTextField";

const StyledStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette["Gray/Shades"].p8,
  borderRadius: "4px",
}));

type Kind = "CREATE" | "EDIT";

export type MetricsEditorDialogProps = {
  dialog: Pick<DialogWithCloseIconProps, "open" | "onClose" | "noButton" | "yesButton">;
  onSubmit: SubmitHandler<EvaluationMetricsForm.EvaluationMetricsFormSchema>;
  kind: Kind;
  defaultValues: EvaluationMetricsForm.EvaluationMetricsFormSchema;
  creator: {
    displayName: string;
    src?: string;
  };
};

const MetricsEditorDialog: React.FC<MetricsEditorDialogProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const validateSchema = EvaluationMetricsForm.useEvaluationMetricsFormSchema();
  const methods = useForm<EvaluationMetricsForm.EvaluationMetricsFormSchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: props.defaultValues,
  });
  const titleMap: Record<Kind, string> = {
    CREATE: t("評価項目の作成"),
    EDIT: t("評価項目の編集"),
  };
  const descriptionMap: Record<Kind, string> = {
    CREATE: t("評価項目は受験者を定性的に評価するための項目です。"),
    EDIT: t("評価項目は受験者を定性的に評価するための項目です。"),
  };
  const saveButtonMap: Record<Kind, string> = {
    CREATE: t("作成"),
    EDIT: t("保存"),
  };
  const dialog: DialogWithCloseIconProps = {
    ...props.dialog,
    title: titleMap[props.kind],
    yesButton: {
      ...props.dialog.yesButton,
      onClick: () => {
        methods.handleSubmit(props.onSubmit, console.warn)();
      },
      children: saveButtonMap[props.kind],
      color: "secondary",
    },
    onClose: () => {
      props.dialog.onClose?.();
      methods.clearErrors();
    },
    noButton: {
      ...props.dialog.noButton,
      children: t("キャンセル"),
      color: "outline-only",
      variant: "outlined",
      onClick: event => {
        props.dialog.noButton?.onClick?.(event);
        methods.clearErrors();
      },
    },
    disableEnter: true,
    optionalContent: {
      sx: {
        px: 3,
        py: 2,
      },
    },
  };
  const nameFieldProps: InputControlTextFieldProps = {
    placeholder: t("コーディングスキル"),
    fullWidth: true,
    type: "text",
  };
  const descriptionFieldProps: InputControlTextFieldProps = {
    placeholder: t("基本的な文法を正しく理解して、コードを記述できるか。"),
    fullWidth: true,
    type: "text",
    multiline: true,
    rows: 3,
  };
  return (
    <FormProvider {...methods}>
      <DialogWithCloseIcon {...dialog}>
        <Stack direction="column" spacing={3}>
          <Stack direction="row" spacing={1.5} alignItems="center">
            <InfoOutlinedIcon htmlColor={theme.palette.text.secondary} sx={{ fontSize: "0.8rem" }} />
            <Typography fontSize={14} color="textSecondary">
              {descriptionMap[props.kind]}
            </Typography>
          </Stack>
          <ResourceField label={t("タイトル")} kind="REQUIRED">
            <InputControlTextField name="title" {...nameFieldProps} />
          </ResourceField>
          <ResourceField label={t("説明")} kind="OPTIONAL">
            <InputControlTextField name="description" {...descriptionFieldProps} />
          </ResourceField>
          <StyledStack direction="row" alignItems="center" px={2} py={1} spacing={5}>
            <Typography color="textSecondary" fontSize={12}>
              {t("作成者")}
            </Typography>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Avatar src={props.creator.src} sx={{ height: "24px", width: "24px" }} />
              <Typography color="textSecondary" fontSize={12}>
                {props.creator.displayName}
              </Typography>
            </Stack>
          </StyledStack>
        </Stack>
      </DialogWithCloseIcon>
    </FormProvider>
  );
};

MetricsEditorDialog.displayName = "MetricsEditorDialog";

export default MetricsEditorDialog;
