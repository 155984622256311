import { RemoteInterviewPlayback } from "@hireroo/app-store/widget/e/RemoteInterviewPlayback";
import { languageMapForDisplay } from "@hireroo/challenge/definition";
import { useLanguageCode } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import { isValidComponentType } from "@hireroo/system-design/helpers/flowChart";
import { useComponentTypesMap } from "@hireroo/system-design/hooks";

export const useGenerateHeaderProps = (): Widget.RemoteInterviewPlaybackProps["header"] => {
  const normalizedSessions = RemoteInterviewPlayback.useNormalizedSessions();
  const lang = useLanguageCode();
  const editorOptions = RemoteInterviewPlayback.useEditorOptions();
  const selectedEditorOption = RemoteInterviewPlayback.useSelectedEditorOption();
  const componentTypeMap = useComponentTypesMap();
  const activeSessionId = RemoteInterviewPlayback.useActiveSessionId();

  return {
    questions: normalizedSessions.map((session): Widget.RemoteInterviewPlaybackProps["header"]["questions"][number] => ({
      id: session.liveCodingSessionId.toString(),
      displayName: resolveLanguage(session, lang, "title"),
      onClick: () => {
        if (activeSessionId !== session.liveCodingSessionId) {
          RemoteInterviewPlayback.updateActiveSession(session.liveCodingSessionId, session.variant);
        }
      },
    })),
    selector: {
      onChange: event => {
        RemoteInterviewPlayback.updateSelectedEditorOption(event.target.value);
      },
      options: editorOptions.map((option): Widget.RemoteInterviewPlaybackProps["header"]["selector"]["options"][number] => {
        if (Object.keys(languageMapForDisplay).includes(option)) {
          return {
            value: option,
            displayName: languageMapForDisplay[option],
          };
        } else if (isValidComponentType(option)) {
          return {
            value: option,
            displayName: componentTypeMap[option],
          };
        }
        return {
          value: option,
          displayName: option,
        };
      }),
      value: selectedEditorOption,
    },
  };
};
