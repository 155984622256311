import * as React from "react";

import ManageStore from "./ManageStore";

export type ContextValue = {
  store: ManageStore;
};

export const SplitContext = React.createContext<ContextValue>({} as ContextValue);

export const useSplitContext = () => React.useContext(SplitContext);

export type SplitProviderProps = {};

export const SplitProvider: React.FC<React.PropsWithChildren<SplitProviderProps>> = props => {
  const store = React.useRef(new ManageStore());
  const contextValue: ContextValue = {
    store: store.current,
  };
  return <SplitContext.Provider value={contextValue} children={props.children} />;
};

export const withSplitProvider = <P extends Record<string, unknown>>(WrappedComponent: React.ComponentType<P>) => {
  const Wrapped: React.FC<P> = (props: P) => (
    <SplitProvider>
      <WrappedComponent {...props} />
    </SplitProvider>
  );
  return Wrapped;
};
