import { useTranslation } from "@hireroo/i18n";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";

import MoreMarkdown from "../../../../../modules/MoreMarkdown/MoreMarkdown";

export type SubmissionAccordionFreeTextAnswerProps = {
  answerText?: string;
};

const SubmissionAccordionFreeTextAnswer: React.FC<SubmissionAccordionFreeTextAnswerProps> = props => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} rowGap={1}>
      <Grid item xs={12}>
        <React.Suspense>
          {props.answerText ? (
            <MoreMarkdown description={props.answerText} maxLines={5} />
          ) : (
            <Typography variant="subtitle2" paragraph>
              {t("未解答")}
            </Typography>
          )}
        </React.Suspense>
      </Grid>
    </Grid>
  );
};

SubmissionAccordionFreeTextAnswer.displayName = "SubmissionAccordionFreeTextAnswer";

export default SubmissionAccordionFreeTextAnswer;
