import { useTranslation } from "@hireroo/i18n";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Box, { BoxProps } from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import FormLabel, { FormLabelProps } from "@mui/material/FormLabel";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

type HelpProps =
  | {
      kind: "TOOLTIP";
      text: string;
    }
  | {
      kind: "DESCRIPTION";
      text: string | React.ReactNode;
      /** @default 2 */
      mb?: number;
    };

type FieldKind = "REQUIRED" | "OPTIONAL" | "NONE";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  wordBreak: "keep-all",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  fontWeight: "bold",
  fontSize: "16px",
  color: theme.palette.text.primary,
}));

const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: 14,
  whiteSpace: "pre-wrap",
  wordBreak: "break-all",
}));

const IconWrap = styled("span")(({ theme }) => ({
  position: "relative",
  top: "3px",
  marginLeft: "4px",
  color: theme.palette.action.active,
  svg: {
    width: "16px",
    height: "16px",
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  height: 20,
  marginLeft: 8,
  position: "relative",
  top: -1,
  ".MuiChip-label": {
    fontSize: 10,
    lineHeight: 1.2,
    padding: "1px 4px",
  },
  "&.MuiChip-colorDefault": {
    color: theme.palette.text.secondary,
  },
}));

export type ResourceFieldV2Props = {
  label: string;
  kind: FieldKind;
  help?: HelpProps;
  children: React.ReactNode;
  className?: string;
  formLabel?: FormLabelProps;
} & Pick<BoxProps, "pt" | "pb" | "width" | "height">;

const ResourceFieldV2: React.FC<ResourceFieldV2Props> = props => {
  const { t } = useTranslation();
  const TooltipHelp =
    props.help && props.help.kind === "TOOLTIP" ? (
      <Tooltip title={props.help.text}>
        <IconWrap>
          <InfoOutlinedIcon fontSize="small" />
        </IconWrap>
      </Tooltip>
    ) : null;

  const ChipMap: Record<FieldKind, React.ReactNode> = {
    REQUIRED: <StyledChip label={t("必須")} color="primary" size="small" />,
    OPTIONAL: <StyledChip label={t("任意")} color="default" size="small" />,
    NONE: null,
  };
  const hasDescription = props.help && props.help.kind === "DESCRIPTION";
  return (
    <Box className={props.className} pt={props.pt} pb={props.pb} height={props.height} width={props.width}>
      <StyledFormLabel as="div" {...props.formLabel} sx={{ marginBottom: hasDescription ? "8px" : "16px" }}>
        {props.label}
        {TooltipHelp}
        {ChipMap[props.kind]}
      </StyledFormLabel>
      {props.help && props.help.kind === "DESCRIPTION" && (
        <Description variant="body2" mb={props.help.mb ?? 2}>
          {props.help.text}
        </Description>
      )}
      <Box>{props.children}</Box>
    </Box>
  );
};

ResourceFieldV2.displayName = "ResourceFieldV2";

export default ResourceFieldV2;
