import { App } from "@hireroo/app-store/essential/employee";
import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import TestReportContentNotAvailableContainer, { TestReportContentNotAvailableContainerProps } from "./Container";

type Kind = "SYSTEM_DESIGN" | "PROJECT";

export type TestReportContentNotAvailableFetchContainerProps = {
  kind: Kind;
};

const TestReportContentNotAvailableFetchContainer: React.FC<TestReportContentNotAvailableFetchContainerProps> = props => {
  const appStatus = App.useStatus();
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  if (appStatus !== "INITIALIZED") {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const titles: Record<Kind, string> = {
    SYSTEM_DESIGN: t("システムデザイン形式のレポート"),
    PROJECT: t("実践形式のレポート"),
  };

  const containerProps: TestReportContentNotAvailableContainerProps = {
    title: titles[props.kind] || "",
    description: t2("FeatureCanBeUsedWithPlanUpgrade", { plan: t("スタンダード") }),
  };

  return <TestReportContentNotAvailableContainer {...containerProps} />;
};

TestReportContentNotAvailableFetchContainer.displayName = "TestReportContentNotAvailableFetchContainer";

export default withErrorBoundary(TestReportContentNotAvailableFetchContainer, {});
