import Button, { type ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";

const HEIGHT = 40;

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
  width: HEIGHT,
  height: HEIGHT,
  minWidth: HEIGHT,
  minHeight: HEIGHT,
  borderRadius: 0,
}));

const StyledSpan = styled("span")(() => ({
  display: "inline-block",
  width: "fit-content",
  height: "fit-content",
}));

export type SquareButtonProps = ButtonProps & {
  title?: string | false | null;
};

const SquareButton: React.FC<SquareButtonProps> = ({ title, ...props }) => {
  if (!title) {
    return <StyledButton {...props} />;
  }
  return (
    <Tooltip role="tooltip" title={title}>
      <StyledSpan>
        <StyledButton {...props} />
      </StyledSpan>
    </Tooltip>
  );
};

SquareButton.displayName = "SquareButton";

export default SquareButton;
