import { proxy } from "valtio";
import { proxyMap } from "valtio/utils";

import type { State } from "./types";

export const state = proxy<State>({
  activeStep: 0,
  variant: "ALGORITHM",
  runCodeOutput: proxyMap(),
  isRunCodeLoading: proxyMap(),
  profileCodeOutput: proxyMap(),
  isProfileCodeLoading: proxyMap(),
  saveStatus: "READY",
  initialLanguage: "ja",
});
