import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type ScreeningTestTimelimitContainerProps = {};

const ScreeningTestTimelimitContainer: React.FC<ScreeningTestTimelimitContainerProps> = () => {
  const screeningTestTimelimitProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.ScreeningTestTimelimit {...screeningTestTimelimitProps} />
    </ErrorBoundary>
  );
};

ScreeningTestTimelimitContainer.displayName = "ScreeningTestTimelimitContainer";

export default withErrorBoundary(ScreeningTestTimelimitContainer, {});
