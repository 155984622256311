import { Minimatch } from "minimatch";

const matchPathFromGlobPatterns = (path: string, searchPatterns: string[]): boolean => {
  return searchPatterns.some(searchPattern => {
    const regex = new Minimatch(searchPattern, {
      dot: true,
    }).makeRe();
    if (!regex) {
      console.error(`Invalid search pattern: ${searchPattern}`);
      return false;
    }

    return regex.test(path);
  });
};

export const filterMatchedPathsFromGlobPatterns = (paths: string[], searchPatterns: string[]): string[] => {
  return paths.filter(path => matchPathFromGlobPatterns(path, searchPatterns));
};
