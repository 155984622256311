import { INITIAL_VERSION } from "@hireroo/app-definition/question";
import { App } from "@hireroo/app-store/essential/employee";
import { QuestionQuizPackageResourceEditor } from "@hireroo/app-store/view-domain/QuestionQuizPackageResourceEditor";
import { QuizPackageDetail } from "@hireroo/app-store/widget/e/QuizPackageDetail";
import { SearchQuizQuestion } from "@hireroo/app-store/widget/e/SearchQuizQuestion";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation/legacy";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

import ErrorPanelContainer from "../../../widget/e/ErrorPanel/Container";
import QuizResourceEditorMiddleContainer, { QuizResourceEditorMiddleContainerProps } from "./MiddleContainer";

const QuizResourceEditorFetchContainer: React.FC = () => {
  const params = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const appStatus = App.useStatus();
  const initialized = QuizPackageDetail.useUpdateMultiChoicePackageInitialized();
  const { t } = useTranslation();

  const [result] = Graphql.useGetMultiChoicePackageQuery({
    variables: {
      id: Number(params.id),
      version: searchParams.get("version") || INITIAL_VERSION,
      withAnswers: true,
    },
    requestPolicy: "network-only",
    pause: appStatus !== "INITIALIZED" || initialized,
  });
  React.useEffect(() => {
    if (result.data) {
      const { multiChoicePackage } = result.data;
      if (!multiChoicePackage) {
        return;
      }
      QuizPackageDetail.setUpdatingMultiChoicePackage(multiChoicePackage);
      QuestionQuizPackageResourceEditor.updateQuestionSequence(multiChoicePackage.questions.map(q => `${q.id}-${q.version}`));
      const { questions } = multiChoicePackage;
      if (questions) {
        QuestionQuizPackageResourceEditor.setSelectedQuestions(questions);
        SearchQuizQuestion.setTemporallySelectedQuestions(questions);
      }
    }
  }, [result.data]);

  if (result.error) {
    return <ErrorPanelContainer graphqlErrors={result.error.graphQLErrors} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER" />;
  }
  if (result.data?.multiChoicePackage?.status === "ARCHIVED") {
    return <ErrorPanelContainer title={t("この問題はアーカイブされています。")} message={t("アーカイブした問題は編集できません。")} />;
  }

  const middleContainerProps: QuizResourceEditorMiddleContainerProps = {};
  return (
    <ErrorBoundary>
      <QuizResourceEditorMiddleContainer {...middleContainerProps} />
    </ErrorBoundary>
  );
};

QuizResourceEditorFetchContainer.displayName = "QuizResourceEditorFetchContainer";

export default withErrorBoundary(QuizResourceEditorFetchContainer, {});
