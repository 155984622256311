import { App, Payment } from "@hireroo/app-store/essential/employee";
import { QuestionSelectFieldForResourceEditor } from "@hireroo/app-store/widget/e/QuestionSelectFieldForResourceEditor";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import type { Fields } from "@hireroo/validator";
import * as React from "react";
import { useController, useFormContext } from "react-hook-form";

import {
  convertEntitySourcesValidatorFromGraphql,
  MAX_SELECTABLE_ENTITY_TRACK_COUNT,
  useRestoreCompositePrimaryKeysByQueryParams,
} from "./privateHelper";
import QuestionDetailEditableListContainer from "./widget/QuestionDetailEditableList/Container";
import QuestionSearchAndSelectableAreaFetchContainer, {
  QuestionSearchAndSelectableAreaFetchContainerProps,
} from "./widget/QuestionSearchAndSelectableArea/FetchContainer";
import SelectQuestionFromAssessmentHistoryContainer from "./widget/SelectQuestionFromAssessmentHistory/Container";

type FieldValue = Fields.EntityTrack.EntityTrack[];

type Target = "exam" | "test" | "remote";

export type GenerateQuestionSelectFieldV2PropsArgs = {
  enableMultiSelectQuestion: boolean;
  enabledRestoreQuestionFromQueryParams: boolean;
  name: QuestionSearchAndSelectableAreaFetchContainerProps["name"];
  target: Target;
};

export const useGenerateProps = (args: GenerateQuestionSelectFieldV2PropsArgs): Widget.QuestionSelectFieldV2Props => {
  const { t } = useTranslation();
  const methods = useFormContext<{ entityTracks: Fields.EntityTrack.EntityTrack[] }>();
  const isAvailableQuestionByPayment = Payment.useIsAvailableQuestionToUse(args.target);
  const enableTalentScore = App.useEnableTalentScore();
  const questionSelectState = QuestionSelectFieldForResourceEditor.useQuestionSelectState();
  const selectedEntityTrackCount = methods.watch("entityTracks").length;
  const isAvailableQuestion = React.useCallback(
    (variant: Graphql.QuestionVariant): boolean => {
      if (args.target === "exam" && enableTalentScore) {
        return true;
      }
      return isAvailableQuestionByPayment(variant);
    },
    [args.target, enableTalentScore, isAvailableQuestionByPayment],
  );
  const questionSearchAndSelectableAreaContext = Widget.useQuestionSearchAndSelectableAreaContext();
  const compositePrimaryKeys = useRestoreCompositePrimaryKeysByQueryParams();
  const canAddAdditionalEntitySources = React.useRef(args.enabledRestoreQuestionFromQueryParams);
  /**
   * Do not imitate other Container Component box implementations.
   * It is an irregular writing style and not very desirable.
   */
  const { field } = useController<Record<string, FieldValue>>({
    name: args.name,
  });

  const [result] = Graphql.useGetMultiQuestionsForQuestionSelectFieldForResourceEditorQuery({
    variables: {
      compositePrimaryKeys: compositePrimaryKeys.map((compositePrimaryKey): Graphql.QuestionCompositePrimaryKey => {
        return {
          questionId: compositePrimaryKey.questionId,
          version: compositePrimaryKey.version,
          questionVariant: compositePrimaryKey.questionVariant,
        };
      }),
      withAnswers: false,
    },
    pause: compositePrimaryKeys.length === 0,
  });

  React.useEffect(() => {
    if (!canAddAdditionalEntitySources.current) {
      return;
    }
    if (result.fetching) {
      return;
    }
    if (result.data?.multiQuestions) {
      const entityTracks = convertEntitySourcesValidatorFromGraphql(result.data.multiQuestions, isAvailableQuestion);
      const newFieldValue: FieldValue = entityTracks.filter(entityTrack => {
        /**
         * Implement here once the question package supports choice questions
         */
        if (entityTrack.type !== "FIXED") {
          return false;
        }
        return entityTrack.entitySource.isSelectable;
      });
      field.onChange(newFieldValue);
      canAddAdditionalEntitySources.current = false;
      Snackbar.notify({
        severity: "info",
        message: t("問題を選択しました。"),
      });
    }
  }, [t, field, result.fetching, result.data?.multiQuestions, isAvailableQuestion]);

  React.useEffect(() => {
    if (result.fetching) {
      return;
    }
    if (result.data?.multiQuestions) {
      QuestionSelectFieldForResourceEditor.addQuestions(result.data.multiQuestions);
    }
  }, [result.fetching, result.data?.multiQuestions]);

  const SelectQuestionFromHistoryComponentMap: Record<Target, React.ReactNode> = {
    exam: <SelectQuestionFromAssessmentHistoryContainer />,
    test: null,
    remote: null,
  };

  const addQuestionsButtonTextMap: Record<QuestionSelectFieldForResourceEditor.AddQuestionsMode, string> = {
    ADD_FIXED: t("追加"),
    ADD_RANDOM: t("追加"),
    ADD_SELECTABLE: t("追加"),
    EDIT_RANDOM: t("編集"),
    EDIT_SELECTABLE: t("編集"),
  };

  const disabledAddQuestionsButton = React.useMemo((): boolean => {
    switch (questionSelectState.mode) {
      case "ADD_FIXED":
        return questionSearchAndSelectableAreaContext.selectedEntitySources.length === 0;
      case "ADD_RANDOM":
      case "ADD_SELECTABLE":
      case "EDIT_RANDOM":
      case "EDIT_SELECTABLE":
        return questionSearchAndSelectableAreaContext.selectedEntitySources.length < 2;
      default:
        throw new Error(`addQuestionsMode is invalid: ${questionSelectState satisfies never}`);
    }
  }, [questionSelectState, questionSearchAndSelectableAreaContext.selectedEntitySources.length]);

  const disabledAddQuestionsButtonDisableTitle = React.useMemo((): string | undefined => {
    switch (questionSelectState.mode) {
      case "ADD_FIXED":
        if (questionSearchAndSelectableAreaContext.selectedEntitySources.length === 0) {
          return t("問題を選択してください");
        }
        break;
      case "ADD_RANDOM":
      case "ADD_SELECTABLE":
      case "EDIT_RANDOM":
      case "EDIT_SELECTABLE":
        if (questionSearchAndSelectableAreaContext.selectedEntitySources.length < 2) {
          return t("少なくとも2問以上選択してください");
        }
        break;
      default:
        throw new Error(`addQuestionsMode is invalid: ${questionSelectState satisfies never}`);
    }
    return undefined;
  }, [questionSelectState, questionSearchAndSelectableAreaContext.selectedEntitySources.length, t]);

  return {
    loading: result.fetching,
    name: args.name,
    QuestionDetailEditableList: <QuestionDetailEditableListContainer name={args.name} />,
    QuestionSearchAndSortField: <QuestionSearchAndSelectableAreaFetchContainer name={args.name} target={args.target} />,
    SearchHistory: SelectQuestionFromHistoryComponentMap[args.target],
    enableMultiSelectQuestion: args.enableMultiSelectQuestion,
    selectQuestionButton: {
      disabled: selectedEntityTrackCount === MAX_SELECTABLE_ENTITY_TRACK_COUNT,
      title: selectedEntityTrackCount === MAX_SELECTABLE_ENTITY_TRACK_COUNT ? t("出題可能な問題数の上限に達しました。") : "",
      onClick: () => {
        QuestionSelectFieldForResourceEditor.setOpenQuestionSelectDialog({
          mode: "ADD_FIXED",
          openQuestionSelectDialog: true,
          selectedEntityTrackFieldIndex: null,
        });
      },
    },
    selectSelectableQuestionButton: {
      onClick: () => {
        QuestionSelectFieldForResourceEditor.setOpenQuestionSelectDialog({
          mode: "ADD_SELECTABLE",
          openQuestionSelectDialog: true,
          selectedEntityTrackFieldIndex: null,
        });
      },
    },
    selectRandomQuestionButton: {
      onClick: () => {
        QuestionSelectFieldForResourceEditor.setOpenQuestionSelectDialog({
          mode: "ADD_RANDOM",
          openQuestionSelectDialog: true,
          selectedEntityTrackFieldIndex: null,
        });
      },
    },
    addQuestionsButton: {
      disabled: disabledAddQuestionsButton,
      title: disabledAddQuestionsButtonDisableTitle,
      children: addQuestionsButtonTextMap[questionSelectState.mode],
      onClick: () => {
        switch (questionSelectState.mode) {
          case "ADD_FIXED": {
            const entityTracks = methods.getValues("entityTracks");
            const newEntityTracks: Fields.EntityTrack.FixedEntityTrack[] = questionSearchAndSelectableAreaContext.selectedEntitySources.map(
              (entitySource): Fields.EntityTrack.FixedEntityTrack => {
                return {
                  type: "FIXED",
                  entitySource: entitySource,
                  questionScoreWeight: 1,
                };
              },
            );
            methods.setValue("entityTracks", [...entityTracks, ...newEntityTracks]);
            break;
          }
          case "ADD_RANDOM":
          case "EDIT_RANDOM": {
            const entityTracks = methods.getValues("entityTracks");
            const newEntityTrack: Fields.EntityTrack.RandomFromQuestionsEntityTrack = {
              type: "RANDOM_FROM_QUESTIONS",
              entitySources: questionSearchAndSelectableAreaContext.selectedEntitySources,
              questionScoreWeight: 1,
            };
            if (questionSelectState.mode === "ADD_RANDOM") {
              methods.setValue("entityTracks", [...entityTracks, newEntityTrack]);
            } else if (questionSelectState.mode === "EDIT_RANDOM") {
              const previousEntityTrack = entityTracks[questionSelectState.selectedEntityTrackFieldIndex];
              entityTracks[questionSelectState.selectedEntityTrackFieldIndex] = {
                ...newEntityTrack,
                questionScoreWeight: previousEntityTrack.questionScoreWeight,
              };
              methods.setValue(`entityTracks`, entityTracks);
            }
            break;
          }
          case "ADD_SELECTABLE":
          case "EDIT_SELECTABLE": {
            const entityTracks = methods.getValues("entityTracks");
            const newEntityTrack: Fields.EntityTrack.SelectableFromQuestionsEntityTrack = {
              type: "SELECTABLE_FROM_QUESTIONS",
              entitySources: questionSearchAndSelectableAreaContext.selectedEntitySources,
              questionScoreWeight: 1,
            };
            if (questionSelectState.mode === "ADD_SELECTABLE") {
              methods.setValue("entityTracks", [...entityTracks, newEntityTrack]);
            } else if (questionSelectState.mode === "EDIT_SELECTABLE") {
              const previousEntityTrack = entityTracks[questionSelectState.selectedEntityTrackFieldIndex];
              entityTracks[questionSelectState.selectedEntityTrackFieldIndex] = {
                ...newEntityTrack,
                questionScoreWeight: previousEntityTrack.questionScoreWeight,
              };
              methods.setValue(`entityTracks`, entityTracks);
            }
            break;
          }
          default:
            throw new Error(`Mode is invalid value: ${questionSelectState satisfies never}`);
        }
        QuestionSelectFieldForResourceEditor.setOpenQuestionSelectDialog({
          ...questionSelectState,
          openQuestionSelectDialog: false,
        });
        questionSearchAndSelectableAreaContext.updateSelectedEntitySources([]);
      },
    },
    closeButton: {
      onClick: () => {
        QuestionSelectFieldForResourceEditor.setOpenQuestionSelectDialog({
          ...questionSelectState,
          openQuestionSelectDialog: false,
        });
        questionSearchAndSelectableAreaContext.updateSelectedEntitySources([]);
      },
    },
    dialog: {
      open: questionSelectState.openQuestionSelectDialog,
      onClose: () => {
        QuestionSelectFieldForResourceEditor.setOpenQuestionSelectDialog({
          ...questionSelectState,
          openQuestionSelectDialog: false,
        });
      },
    },
  };
};
