import { useTranslation } from "@hireroo/i18n";
import { PaymentForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import BillingInformationField, {
  BillingInformationFieldProps,
} from "../../ms-components/Payment/BillingInformationField/BillingInformationField";
import PaymentMethodField, { PaymentMethodFieldProps } from "../../ms-components/Payment/PaymentMethodField/PaymentMethodField";

const SectionLabel = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  marginBottom: theme.spacing(2),
}));

export type PaymentMethodUpdateFormProps = {
  defaultValues: PaymentForm.PaymentMethodUpdateForm;
  billingInformationField: Pick<BillingInformationFieldProps, "companyName">;
  paymentMethod: Pick<PaymentMethodFieldProps, "cardField" | "bankField" | "onChange">;
  submitButton: Pick<LoadingButtonProps, "loading" | "children">;
  onSubmit: SubmitHandler<PaymentForm.PaymentMethodUpdateForm>;
};

const PaymentMethodUpdateForm: React.FC<PaymentMethodUpdateFormProps> = props => {
  const { t } = useTranslation();
  const validateSchema = PaymentForm.usePaymentMethodUpdateForm();
  const methods = useForm<PaymentForm.PaymentMethodUpdateForm>({
    resolver: zodResolver(validateSchema),
    defaultValues: props.defaultValues,
  });
  const paymentMethodFieldProps: PaymentMethodFieldProps = {
    name: "paymentMethod",
    ...props.paymentMethod,
  };

  const submitButton: LoadingButtonProps = {
    ...props.submitButton,
    variant: "contained",
    color: "secondary",
    onClick: () => {
      methods.handleSubmit(props.onSubmit)();
    },
  };

  return (
    <FormProvider {...methods}>
      <SectionLabel>{t("請求情報")}</SectionLabel>
      <BillingInformationField {...props.billingInformationField} name="billingInformation" mb={3} />
      <SectionLabel>{t("支払い方法")}</SectionLabel>
      <PaymentMethodField {...paymentMethodFieldProps} mb={3} />
      <Box textAlign="center">
        <LoadingButton {...submitButton} />
      </Box>
    </FormProvider>
  );
};

PaymentMethodUpdateForm.displayName = "PaymentMethodUpdateForm";

export default PaymentMethodUpdateForm;
