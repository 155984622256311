import { IEditorLanguageRegistry } from "@jupyterlab/codemirror";
import { marked } from "marked";

export const initializeMarked = (languages: IEditorLanguageRegistry) => {
  return marked.setOptions({
    gfm: true,
    sanitize: false,
    // breaks: true; We can't use GFM breaks as it causes problems with tables
    langPrefix: `language-`,
    highlight: (code, lang, callback) => {
      const cb = (err: Error | null, code: string) => {
        if (callback) {
          callback(err, code);
        }
        return code;
      };
      if (!lang) {
        // no language, no highlight
        return cb(null, code);
      }
      const el = document.createElement("div");
      try {
        languages
          .highlight(code, languages.findBest(lang), el)
          .then(() => {
            return cb(null, el.innerHTML);
          })
          .catch(reason => {
            return cb(reason, code);
          });
      } catch (error) {
        console.error(`Failed to highlight ${lang} code`, error);
        return cb(error as Error, code);
      }
    },
  });
};
