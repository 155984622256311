import { useTranslation } from "@hireroo/i18n";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardHeader from "@mui/material/CardHeader";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DifficultyStars, { DifficultyStarsProps } from "../../../modules/DifficultyStars/DifficultyStars";
import QuestionArchivedBadge from "../QuestionArchivedBadge/QuestionArchivedBadge";

const StyledCardHeader = styled(CardHeader)(() => ({
  ".MuiCardHeader-content": {
    width: "100%",
  },
  ".MuiCardHeader-title": {
    fontSize: "1rem",
    fontWeight: "bold",
  },
  padding: 0,
}));

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: "8px",
  border: "1px solid",
  borderColor: theme.palette["Other"]["Divider"],
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  padding: "12px",
  backgroundImage: "unset",
  backgroundColor: "inherit",
}));

export type QuestionCardProps = {
  id: string;
  variantText: string;
  title: string;
  version?: string;
  detailButton?: {
    href: string;
  };
  difficultyStars?: DifficultyStarsProps;
  selectedContent?: {
    label: string;
    items: string[];
  };
  isArchived: boolean;
};

const QuestionCard: React.FC<QuestionCardProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <StyledCard>
      <StyledCardHeader
        title={
          <Stack spacing={1.5}>
            {props.isArchived && <QuestionArchivedBadge />}
            <Typography variant="subtitle2" fontSize={16} fontWeight={700} sx={{ mb: 1 }}>
              {props.title}
            </Typography>
          </Stack>
        }
        subheader={
          <Stack direction="column" spacing={0.5} mb={1}>
            <Stack direction="row" spacing={3} mt={1}>
              <Typography sx={{ wordBreak: "keep-all" }} fontSize={12} color={theme.palette.text.primary}>
                {props.variantText}
              </Typography>
              {props.difficultyStars && (
                <Box>
                  <DifficultyStars {...props.difficultyStars} />
                </Box>
              )}
            </Stack>
            {props.selectedContent && props.selectedContent.items.length > 0 && (
              <Typography fontSize={12} color="textPrimary">
                {props.selectedContent.label}: {props.selectedContent.items.join(", ")}
              </Typography>
            )}
          </Stack>
        }
        sx={{ pb: 1 }}
      />
      {props.detailButton?.href && (
        <CardActions sx={{ height: "40px", p: 0, display: "flex", justifyContent: "space-between" }}>
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            fullWidth
            sx={{ wordBreak: "keep-all" }}
            endIcon={<OpenInNewIcon color="secondary" />}
            target="_blank"
            rel="noreferrer"
            href={props.detailButton.href}
          >
            {t("問題詳細")}
          </Button>
        </CardActions>
      )}
    </StyledCard>
  );
};

QuestionCard.displayName = "QuestionCard";

export default QuestionCard;
