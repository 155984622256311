import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type PageLeaveHistoryPanelContainerProps = {};

const PageLeaveHistoryPanelContainer: React.FC<PageLeaveHistoryPanelContainerProps> = () => {
  const pageLeaveHistoryPanelProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.ActivityTimeBarChartPanel {...pageLeaveHistoryPanelProps} />
    </ErrorBoundary>
  );
};

PageLeaveHistoryPanelContainer.displayName = "PageLeaveHistoryPanelContainer";

export default withErrorBoundary(React.memo(PageLeaveHistoryPanelContainer), {});
