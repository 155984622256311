import { Company } from "@hireroo/app-store/essential/employee";
import { InvitationsIdStore } from "@hireroo/app-store/page/e/invitations_id";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getIdToken } from "@hireroo/firebase";
import { createTemporallyGraphqlClient, getGraphqlClient } from "@hireroo/graphql/client/request";
import { getTranslation } from "@hireroo/i18n";
import { redirect } from "@hireroo/router/api";

type AcceptInvitationArgs = {
  companyId: number;
  uid: string;
  invitationId: string;
  invitationRole: InvitationsIdStore.Role;
};

export const acceptInvitation = async (args: AcceptInvitationArgs) => {
  const { t } = getTranslation();
  /**
   * Get a new client to update Credential information
   */
  const client = getGraphqlClient();
  const { acceptEmployeeInvitation } = await client.AcceptEmployeeInvitation({
    employeeId: args.uid,
    invitationId: args.invitationId,
  });

  if (!acceptEmployeeInvitation) {
    return;
  }

  Snackbar.notify({
    severity: "success",
    message: t("招待を承諾しました。数秒後にリダイレクトされます。"),
  });

  await client.UpdateClaimsV2({
    input: {
      companyId: args.companyId,
      uid: args.uid,
      role: args.invitationRole,
      userType: "EMPLOYEE",
    },
  });

  const authToken = await getIdToken(true);
  const newClient = createTemporallyGraphqlClient(import.meta.env.VITE_GRAPHQL_SERVER_ADDRESS, {
    authToken,
    appVersion: APP_VERSION,
  });

  await newClient.SwitchActiveCompanyForEmployeeV2({
    input: {
      companyId: acceptEmployeeInvitation.companyId,
      employeeId: args.uid,
      role: acceptEmployeeInvitation.role,
      userType: "EMPLOYEE",
    },
  });

  Company.updateActiveCompanyId(acceptEmployeeInvitation.companyId);

  return new Promise<void>(resolve => {
    setTimeout(() => {
      redirect("/e/home").then(() => {
        resolve();
      });
      /**
       * Provide a 1 second delay because transitions prior to the execution of Subscriber
       * due to a change in ActiveCompanyId may not work properly.
       */
    }, 1000);
  });
};
