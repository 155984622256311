import { useTitle } from "@hireroo/app-helper/react-use";
import { signOut } from "@hireroo/firebase";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import { generateHelpCenterUrl } from "@hireroo/router/api";
import { useHelpCenterNavigate } from "@hireroo/router/hooks";

type GenerateNotFoundProps = {};

export const useGenerateErrorPanelProps = (_args: GenerateNotFoundProps): Widget.ErrorPanelProps => {
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const helpCenterNavigate = useHelpCenterNavigate(lang);

  useTitle(t("エラーが発生しました。"));

  return {
    name: t("エラーが発生しました。"),
    message: t("ページをリロードするか、それでも治らない場合は運営までお問い合わせ下さい。"),
    links: [
      {
        children: t("ページをリロードをする"),
        onClick: () => {
          window.location.reload();
        },
      },
      {
        href: generateHelpCenterUrl(lang, "ROOT"),
        children: t("ヘルプセンター"),
        onClick: () => {
          helpCenterNavigate("ROOT", { _blank: true });
        },
      },
      {
        children: t("サインアウトする"),
        onClick: async () => {
          await signOut();
        },
      },
    ],
  };
};
