import { useSnapshot } from "valtio";

import { state } from "./State";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useFormValues = () => {
  const snapshot = useSnapshotState();
  return snapshot.formValues;
};

export const useMetrics = () => {
  const snapshot = useSnapshotState();
  return Array.from(snapshot.metrics.values());
};

export const useMetricsMap = () => {
  const snapshot = useSnapshotState();
  return snapshot.metrics;
};

export const useMetricPagination = () => {
  const snapshot = useSnapshotState();
  return snapshot.metricPagination;
};

export const useIsValidFormValues = () => {
  const snapshot = useSnapshotState();
  return snapshot.formValues.metricIds.length > 0 && snapshot.formValues.evaluations.every(evaluation => evaluation.numStars > 0);
};
