import NativeSelect, { NativeSelectProps } from "@mui/material/NativeSelect";
import { styled } from "@mui/material/styles";
import * as React from "react";

const StyledNativeSelect = styled(NativeSelect)(() => ({
  "&.MuiInputBase-root": {
    fontSize: "14px",
    border: 0,
    "&:before": {
      border: 0,
    },
    "&:hover": {
      border: 0,
    },
    "&:hover:not(.Mui-disabled):before": {
      border: 0,
    },
    "&:after": {
      border: 0,
    },
  },
}));

type Option = {
  text: string;
  value: string;
  default?: boolean;
};

export type NativeDropDownProps = NativeSelectProps & {
  options: Option[];
};

const NativeDropDown: React.FC<NativeDropDownProps> = props => {
  const { options, ...nativeSelectProps } = props;
  const defaultOption: Option = React.useMemo(() => {
    return (
      options.find(o => !!o.default) ||
      options[0] || {
        text: "",
        value: "",
      }
    );
  }, [options]);
  const [selectedOption, updateOption] = React.useState<Option>(defaultOption);

  return (
    <StyledNativeSelect
      {...nativeSelectProps}
      value={selectedOption.value}
      onChange={event => {
        const selectedOption = options.find(o => o.value === event.target.value);
        if (selectedOption) {
          updateOption(selectedOption);
        }
        props.onChange?.(event);
      }}
    >
      {options.map((option, index) => (
        <option
          key={`option-${option.text}-${index}`}
          value={option.value}
          onClick={() => {
            updateOption(option);
          }}
        >
          {option.text}
        </option>
      ))}
    </StyledNativeSelect>
  );
};

NativeDropDown.displayName = "NativeDropDown";

export default NativeDropDown;
