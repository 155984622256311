import { subscribeKey } from "valtio/utils";

import { state } from "./State";
import * as Types from "./types";

export type SubscribeCurrentSearchFilterCallback = (searchFilter: Types.SearchFilter | null) => void;

export const subscribeCurrentSearchFilter = (callback: SubscribeCurrentSearchFilterCallback) => {
  return subscribeKey(state, "currentSearchFilter", callback);
};

export type SubscribePagerCallback = (pager: Types.Pager) => void;

export const subscribePager = (callback: SubscribePagerCallback) => {
  return subscribeKey(state, "pager", callback);
};
