import * as z from "zod";

export const useStatisticsQuery = () => {
  const tag = z.object({
    value: z.string(),
    selected: z.boolean(),
  });
  return z.object({
    tags: tag.array(),
    endDate: z.union([z.date(), z.null()]),
    startDate: z.union([z.date(), z.null()]),
    scope: z.union([z.literal("COMPANY"), z.literal("GLOBAL")]),
    enableDate: z.boolean(),
  });
};

export type StatisticsQuerySchema = z.infer<ReturnType<typeof useStatisticsQuery>>;
