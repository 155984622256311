import React from "react";

import { useSplitContext } from "../Context";
import { useDrag } from "../hooks/useDrag";
import type * as Types from "../types";
import { extractContentId } from "../utils";

type LimitSize = {
  minSize: Types.PairProps["minSize"];
  maxSize: Types.PairProps["maxSize"];
};

type CalculateAdjustedSizeArgs = {
  offset: number;
  snapOffset: number;
  prev: {
    minSize: number;
  };
  next: {
    estimateSize: number;
    minSize: number;
  };
};

const calculateAdjustedSize = (args: CalculateAdjustedSizeArgs) => {
  const { snapOffset, prev, next, offset } = args;
  const suggestPrevPixelSize = offset;
  const suggestNextPixelSize = args.next.estimateSize;

  const prevCanSnap = suggestPrevPixelSize - snapOffset <= prev.minSize;
  const nextCanSnap = suggestNextPixelSize - snapOffset <= next.minSize;

  let prevContentPixelSize = prevCanSnap ? prev.minSize : suggestPrevPixelSize;
  let nextContentPixelSize = nextCanSnap ? next.minSize : suggestNextPixelSize;
  if (prevCanSnap) {
    nextContentPixelSize += suggestPrevPixelSize - prev.minSize;
  }
  if (nextCanSnap) {
    prevContentPixelSize += suggestNextPixelSize - next.minSize;
  }
  return {
    nextContentPixelSize,
    prevContentPixelSize,
  };
};

export type UsePaneControllerProps = {
  paneId: Types.PaneId;
  draggingClassName?: string;
};

export const usePaneController = (props: UsePaneControllerProps) => {
  const context = useSplitContext();
  const dragOffset = React.useRef(0);
  const sizeObject = React.useRef({
    size: 0,
    start: 0,
    end: 0,
  });
  const splitSize = React.useRef<number>(0);
  const prevContentLimitSize = React.useRef<LimitSize>({
    minSize: undefined,
    maxSize: undefined,
  });
  const nextContentLimitSize = React.useRef<LimitSize>({
    minSize: undefined,
    maxSize: undefined,
  });
  const status = React.useRef<"DRAGGABLE" | "FREEZE">("DRAGGABLE");

  const isValidSize = (nextPixelSize: number, limit: LimitSize): boolean => {
    if (limit.minSize) {
      if (limit.minSize.unit === "%") {
        const nextPct = (100 * nextPixelSize) / splitSize.current;
        if (nextPct < limit.minSize.value) {
          return false;
        }
      } else {
        if (nextPixelSize < limit.minSize.value) {
          return false;
        }
      }
    }
    if (limit.maxSize) {
      if (limit.maxSize.unit === "%") {
        const nextPct = (100 * nextPixelSize) / splitSize.current;
        if (nextPct > limit.maxSize.value) {
          return false;
        }
      } else {
        if (nextPixelSize > limit.maxSize.value) {
          return false;
        }
      }
    }
    return true;
  };

  const adjust = (offset: number) => {
    const paneState = context.store.getPaneStateRequired(props.paneId);
    const splitId = paneState.splitId;
    const snapOffset = context.store.getSplitState(paneState.splitId).snapOffset;
    const prevContentState = context.store.getContentStateRequired(paneState.prevContentId);
    const nextContentState = context.store.getContentStateRequired(paneState.nextContentId);

    const { prevContentPixelSize, nextContentPixelSize } = calculateAdjustedSize({
      offset,
      snapOffset,
      prev: {
        minSize: prevContentState.minSize?.value ?? 0,
      },
      next: {
        estimateSize: sizeObject.current.size - paneState.size.value - offset,
        minSize: nextContentState.minSize?.value ?? 0,
      },
    });

    if (prevContentPixelSize < 0 || nextContentPixelSize < 0) {
      return;
    }

    if (prevContentState.size.value === prevContentPixelSize || nextContentState.size.value === nextContentPixelSize) {
      return;
    }

    if (!isValidSize(prevContentPixelSize, prevContentLimitSize.current) || !isValidSize(nextContentPixelSize, nextContentLimitSize.current)) {
      return;
    }

    if (prevContentPixelSize === 0 || prevContentPixelSize === prevContentState.minimizedSize?.value) {
      context.store.emitChangeEvent(splitId, {
        type: "MINIMIZED",
        contentId: extractContentId(paneState.prevContentId),
      });
    } else if (prevContentState.size.value === 0 || prevContentState.size.value === prevContentState.minimizedSize?.value) {
      context.store.emitChangeEvent(splitId, {
        type: "RELEASE_MINIMIZED",
        contentId: extractContentId(paneState.prevContentId),
      });
    }

    if (nextContentPixelSize === 0 || nextContentPixelSize === nextContentState.minimizedSize?.value) {
      context.store.emitChangeEvent(splitId, {
        type: "MINIMIZED",
        contentId: extractContentId(paneState.nextContentId),
      });
    } else if (nextContentState.size.value === 0 || nextContentState.size.value === nextContentState.minimizedSize?.value) {
      context.store.emitChangeEvent(splitId, {
        type: "RELEASE_MINIMIZED",
        contentId: extractContentId(paneState.nextContentId),
      });
    }

    context.store.setContentSize(paneState.prevContentId, { value: prevContentPixelSize, unit: "px" });
    context.store.setContentSize(paneState.nextContentId, { value: nextContentPixelSize, unit: "px" });
  };

  const { subscribe } = useDrag({
    /**
     * offsetの計算を実施
     */
    onDragStart: (mousePosition, elem) => {
      const paneState = context.store.getPaneStateRequired(props.paneId);
      const prevContent = context.store.getContentStateRequired(paneState.prevContentId);
      const nextContent = context.store.getContentStateRequired(paneState.nextContentId);
      splitSize.current = context.store.getSplitSize(paneState.splitId);

      if (prevContent.freeze || nextContent.freeze) {
        status.current = "FREEZE";
        return;
      } else {
        status.current = "DRAGGABLE";
      }

      prevContentLimitSize.current = {
        minSize: prevContent.minSize,
        maxSize: prevContent.maxSize,
      };
      nextContentLimitSize.current = {
        minSize: nextContent.minSize,
        maxSize: nextContent.maxSize,
      };

      const prevRect = prevContent.getBoundingClientRect();
      const nextRect = nextContent.getBoundingClientRect();

      prevContent.onDragStart();
      nextContent.onDragStart();

      if (elem && props?.draggingClassName) {
        elem.classList.add(props.draggingClassName);
      }

      if (paneState.splitDirection === "HORIZONTAL") {
        sizeObject.current = {
          size: prevRect.height + paneState.size.value + nextRect.height,
          start: prevRect.top,
          end: prevRect.bottom,
        };
        dragOffset.current = mousePosition.y - sizeObject.current.end;
        window.document.body.style.cursor = "row-resize";
      } else {
        sizeObject.current = {
          size: prevRect.width + paneState.size.value + nextRect.width,
          start: prevRect.left,
          end: prevRect.right,
        };
        dragOffset.current = mousePosition.x - sizeObject.current.end;
        window.document.body.style.cursor = "col-resize";
      }
    },
    /**
     * mousePosition, offset, getBoundingRectからPaneの両サイドのwidthを計算
     */
    onDrag: mousePosition => {
      if (status.current === "FREEZE") {
        return;
      }
      const paneState = context.store.getPaneStateRequired(props.paneId);

      if (paneState.splitDirection === "HORIZONTAL") {
        const offset = mousePosition.y - sizeObject.current.start + (paneState.size.value - dragOffset.current);
        adjust(offset);
      } else {
        const offset = mousePosition.x - sizeObject.current.start + (paneState.size.value - dragOffset.current);
        adjust(offset);
      }
    },
    onDragEnd: (_, elem) => {
      if (status.current === "FREEZE") {
        return;
      }
      if (elem && props?.draggingClassName) {
        elem.classList.remove(props.draggingClassName);
      }
      const paneState = context.store.getPaneStateRequired(props.paneId);
      const prevContent = context.store.getContentStateRequired(paneState.prevContentId);
      const nextContent = context.store.getContentStateRequired(paneState.nextContentId);

      prevContent.onDragEnd();
      nextContent.onDragEnd();

      window.document.body.style.cursor = "";
    },
  });
  return {
    startSubscribe: (elem: HTMLDivElement) => {
      return subscribe(elem);
    },
  };
};
