import { ProjectBackendTestCaseSchemaV3 } from "@hireroo/validator";

import { safeJsonParse } from "../parser";

export const withinRange = (expected: number, output: number): boolean => {
  const firstNum = Math.floor(expected / 100);
  const lower = firstNum * 100;
  const upper = lower + 100;

  return lower <= output && output < upper;
};

const backendCorrectnessTestResultSchema = ProjectBackendTestCaseSchemaV3.generateBackendCorrectnessTestResultSchema();

export const parseBackendTestCaseResult = (data: string): ProjectBackendTestCaseSchemaV3.BackendCorrectnessTestResultSchema | undefined => {
  const parsedValue = safeJsonParse(data);
  const result = backendCorrectnessTestResultSchema.safeParse(parsedValue);
  if (result.success) {
    return result.data;
  } else {
    return;
  }
};

const backendCorrectnessTestResultsSchema = ProjectBackendTestCaseSchemaV3.generateBackendCorrectnessTestResultsSchema();

export const parseBackendTestCaseResults = (data: string): ProjectBackendTestCaseSchemaV3.BackendCorrectnessTestResultsSchema | undefined => {
  const parsedValue = safeJsonParse(data);
  const result = backendCorrectnessTestResultsSchema.safeParse(parsedValue);
  if (result.success) {
    return result.data;
  } else {
    return;
  }
};

const backendTestCaseSchema = ProjectBackendTestCaseSchemaV3.generateBackendCorrectnessTestCase();

export const parseBackendTestCase = (data: string): ProjectBackendTestCaseSchemaV3.BackendCorrectnessTestCaseSchema | undefined => {
  const parsedValue = safeJsonParse(data);
  const result = backendTestCaseSchema.safeParse(parsedValue);
  if (result.success) {
    return result.data;
  } else {
    return;
  }
};

const backendTestCasesSchema = ProjectBackendTestCaseSchemaV3.generateBackendCorrectnessTestCases();

export const parseBackendTestCases = (data: string): ProjectBackendTestCaseSchemaV3.BackendCorrectnessTestCasesSchema | undefined => {
  const parsedValue = safeJsonParse(data);
  const result = backendTestCasesSchema.safeParse(parsedValue);
  if (result.success) {
    return result.data;
  } else {
    return undefined;
  }
};

const performanceTestCaseResultsSchema = ProjectBackendTestCaseSchemaV3.generateBackendPerformanceTestCaseResults();

export const parsePerformanceTestCaseResults = (
  data: string,
): ProjectBackendTestCaseSchemaV3.BackendPerformanceTestCaseResultsSchema | undefined => {
  const parsedValue = safeJsonParse(data);
  const result = performanceTestCaseResultsSchema.safeParse(parsedValue);
  if (result.success) {
    return result.data;
  } else {
    return undefined;
  }
};
