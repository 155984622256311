import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type NextPaymentDateProps = {
  description: string;
  label: string;
  tooltipText?: string;
};

const NextPaymentDate: React.FC<NextPaymentDateProps> = props => {
  const theme = useTheme();

  return (
    <Box>
      <Box display={"flex"}>
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle2" color="text.secondary" mr={1}>
            {props.label}
          </Typography>
          {props.tooltipText && (
            <Tooltip title={props.tooltipText} placement="top">
              <InfoOutlinedIcon sx={{ color: theme.palette.text.secondary, height: "15px", width: "15px" }} />
            </Tooltip>
          )}
        </Box>
      </Box>
      <Typography variant="body2" fontSize={16}>
        {props.description}
      </Typography>
    </Box>
  );
};

NextPaymentDate.displayName = "NextPaymentDate";

export default NextPaymentDate;
