import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import * as React from "react";

export type Status = "PAID" | "NOT_PAID";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  width: "100%",
  height: 56,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  borderBottom: theme.palette["Secondary/Shades"].p12,
  "& .MuiTableCell-root": {
    borderColor: theme.palette["Secondary/Shades"].p12,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  height: 32,
  padding: theme.spacing(1.5),
}));

export type OrderHistoryTableRowProps = {
  itemId: string;
  name: string;
  unitPrice: string;
  count: string;
  amount: string;
  orderedDate?: string | null;
  description?: string;
};

const OrderHistoryTableRow: React.FC<OrderHistoryTableRowProps> = props => {
  return (
    <StyledTableRow>
      <StyledTableCell align="left">{props.orderedDate}</StyledTableCell>
      <StyledTableCell align="left">{props.name}</StyledTableCell>
      <StyledTableCell align="left">{props.unitPrice}</StyledTableCell>
      <StyledTableCell align="right">{props.count}</StyledTableCell>
      <StyledTableCell align="left">{props.amount}</StyledTableCell>
      <StyledTableCell align="left">{props.description}</StyledTableCell>
    </StyledTableRow>
  );
};

OrderHistoryTableRow.displayName = "OrderHistoryTableRow";

export default OrderHistoryTableRow;
