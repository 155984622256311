import * as React from "react";

import { FreepadAnswerFormProvider, FreepadAnswerFormProviderProps } from "../../widget/FreepadAnswerForm/FreepadAnswerForm";
import { FreepadOptionalFormProvider, FreepadOptionalFormProviderProps } from "../../widget/FreepadOptionalForm/FreepadOptionalForm";
import {
  FreepadQuestionDetailFormProvider,
  FreepadQuestionDetailFormProviderProps,
} from "../../widget/FreepadQuestionDetailForm/FreepadQuestionDetailForm";

export type ContextValue = {};

export const FreepadResourceEditorContext = React.createContext<ContextValue>({});

export const useFreepadResourceEditorContext = () => React.useContext(FreepadResourceEditorContext);

export type FreepadResourceEditorProviderProps = {
  questionDetailProvider: FreepadQuestionDetailFormProviderProps;
  answerProvider: FreepadAnswerFormProviderProps;
  optionalProvider: FreepadOptionalFormProviderProps;
};

export const FreepadResourceEditorProvider: React.FC<React.PropsWithChildren<FreepadResourceEditorProviderProps>> = props => {
  const contextValue: ContextValue = {};
  return (
    <FreepadQuestionDetailFormProvider {...props.questionDetailProvider}>
      <FreepadAnswerFormProvider {...props.answerProvider}>
        <FreepadOptionalFormProvider {...props.optionalProvider}>
          <FreepadResourceEditorContext.Provider value={contextValue} children={props.children} />
        </FreepadOptionalFormProvider>
      </FreepadAnswerFormProvider>
    </FreepadQuestionDetailFormProvider>
  );
};
