import { INITIAL_VERSION } from "@hireroo/app-definition/question";
import { App } from "@hireroo/app-store/essential/employee";
import { FreepadResourceEditor } from "@hireroo/app-store/view-domain/FreepadResourceEditor";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation/legacy";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import ErrorPanelContainer from "../../../widget/e/ErrorPanel/Container";
import FreepadResourceEditorMiddleContainer, {
  FreepadResourceEditorMiddleContainerProps,
} from "../questions_freepad_id_update/MiddleContainer";

export type FreepadResourceEditorFetchContainerProps = {
  questionId: number;
};

const FreepadResourceEditorFetchContainer: React.FC<FreepadResourceEditorFetchContainerProps> = props => {
  const appStatus = App.useStatus();
  const [result] = Graphql.useGetQuestionForFreepadResourceEditorQuery({
    variables: {
      questionId: props.questionId,
      questionVersion: INITIAL_VERSION,
    },
    pause: appStatus !== "INITIALIZED",
  });
  React.useEffect(() => {
    if (result.data?.freepadQuestion) {
      FreepadResourceEditor.initializeQuestion(result.data.freepadQuestion);
      // Exclude Database since there is noway to create database variant
      if (
        result.data.freepadQuestion.variant !== "UNKNOWN" &&
        result.data.freepadQuestion.variant !== "DATABASE" &&
        result.data.freepadQuestion.variant !== "CLASS"
      ) {
        FreepadResourceEditor.updateVariant(result.data.freepadQuestion.variant);
      }
    }
  }, [result.data]);

  if (result.error) {
    return <ErrorPanelContainer graphqlErrors={result.error.graphQLErrors} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED") {
    return <Widget.Loading kind="CENTER" />;
  }

  const containerProps: FreepadResourceEditorMiddleContainerProps = {};
  return (
    <ErrorBoundary>
      <FreepadResourceEditorMiddleContainer {...containerProps} />
    </ErrorBoundary>
  );
};

FreepadResourceEditorFetchContainer.displayName = "FreepadResourceEditorFetchContainer";

export default withErrorBoundary(FreepadResourceEditorFetchContainer, {});
