import { useTitle as useTitleByReactUse } from "react-use";
import { useDebouncedCallback as useDebouncedCallbackByUseDebounce } from "use-debounce";

export const useTitle = (text: string) => {
  return useTitleByReactUse(`${text} | HireRoo`);
};

export type UseStringDebouncedCallback = {
  callback: (value: string) => void;
  delayInMicroSeconds: number;
};
export const useStringDebouncedCallback = (args: UseStringDebouncedCallback) => {
  return useDebouncedCallbackByUseDebounce(args.callback, args.delayInMicroSeconds);
};
