import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import type { Widget } from "@hireroo/presentation";
const { ProjectContentsViewerV3 } = ProjectTestReport;

type JupyterNotebookViewerProps = Extract<Widget.ProjectContentsViewerV3Props["viewer"], { mode: "JUPYTER_NOTEBOOK_VIEWER" }>["content"];

export type GenerateProjectDiffViewerPropsArgs = {
  projectId: number;
};

export const useGenerateJupyterNotebookViewerProps = (args: GenerateProjectDiffViewerPropsArgs): JupyterNotebookViewerProps => {
  const hooks = ProjectContentsViewerV3.createHooks(args.projectId);
  const diffSources = hooks.useDiffSources();
  return {
    source: diffSources.to?.value ?? "",
  };
};
