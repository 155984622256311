import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type TalentTestReviewContainerProps = {};

const TalentTestReviewContainer: React.FC<TalentTestReviewContainerProps> = () => {
  const talentTestReviewProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Pages.TalentTestReview {...talentTestReviewProps} />
    </ErrorBoundary>
  );
};

TalentTestReviewContainer.displayName = "TalentTestReviewContainer";

export default withErrorBoundary(TalentTestReviewContainer, {});
