import { proxy } from "valtio";
import { proxyMap } from "valtio/utils";

import type * as Types from "./types";

export const state = proxy<Types.State>({
  currentEntityIndex: 0,
  activeSessionId: null,
  waitingActiveSessionId: null,
  remote: null,
  activeSessionMap: proxyMap(),
  liveCodingQuestionVariant: "UNKNOWN",
  liveCodingTemplateCodesMap: proxyMap(),
  liveCodingVideoChatRoomId: "",
  liveCodingVideoChatConversationId: "",
  fetchStatus: "BEFORE_FETCH",
});
