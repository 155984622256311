import { DeepReadonly } from "@hireroo/app-helper/types";
import { useSnapshot } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = () => {
  const snapshot = useSnapshotState();
  return !!snapshot.screening;
};

export const useScreening = (): DeepReadonly<Types.Screening> => {
  const snapshot = useSnapshotState();
  if (!snapshot.screening) {
    throw new Error("need to initialize");
  }
  return snapshot.screening;
};

export const useDialogStatus = (): Types.DialogStatus => {
  const snapshot = useSnapshotState();
  return snapshot.dialogStatus;
};

export const useTags = (): DeepReadonly<string[]> => {
  const snapshot = useSnapshotState();
  return snapshot.tags;
};
