import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type SignInContainerProps = {};

const SignInContainer: React.FC<SignInContainerProps> = () => {
  const signInProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.SignIn {...signInProps} />
    </ErrorBoundary>
  );
};

SignInContainer.displayName = "SignInContainer";

export default withErrorBoundary(SignInContainer, {});
