import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import * as Graphql from "@hireroo/graphql/client/urql";
import type { Widget } from "@hireroo/presentation";

import { useGeneratePerformanceDetailSectionPropsForBackend } from "./useGeneratePerformanceDetailSectionPropsForBackend";
import { useGeneratePerformanceDetailSectionPropsForFrontend } from "./useGeneratePerformanceDetailSectionPropsForFrontend";

export type GeneratePerformanceDetailSectionPropsArgs = {
  entityId: number;
  uniqueKey: ProjectTestReport.UniqueKey;
  showScore: boolean;
};

export const useGeneratePerformanceDetailSectionProps = (
  args: GeneratePerformanceDetailSectionPropsArgs,
): Widget.ProjectTestReportProps["performanceDetailSection"] => {
  const hooks = ProjectTestReport.useCreateProjectHooks(args.entityId);
  const variant = hooks.useQuestionVariant();
  const performanceDetailSectionPropsForBackend = useGeneratePerformanceDetailSectionPropsForBackend(args);
  const performanceDetailSectionPropsForFrontend = useGeneratePerformanceDetailSectionPropsForFrontend(args);
  switch (variant) {
    case Graphql.ProjectQuestionVariant.Backend:
      return performanceDetailSectionPropsForBackend;
    case Graphql.ProjectQuestionVariant.Frontend:
      return performanceDetailSectionPropsForFrontend;
    case Graphql.ProjectQuestionVariant.DataScience:
      return undefined;
    case Graphql.ProjectQuestionVariant.Default:
      return undefined;
    default:
      throw new Error(`Invalid project question variant: ${variant}`);
  }
};
