import { Payment } from "@hireroo/app-store/essential/talent";
import { QuizTestReport } from "@hireroo/app-store/view-domain/QuizTestReport";
import { formatSeconds } from "@hireroo/formatter/time";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

import { useGenerateEntityScoreBoardProps } from "../../../../props-factory/v2/view-domain/QuizTestReport/useGenerateEntityScoreBoardProps";
import { useGenerateQuestionDetailProps } from "../../../../props-factory/v2/view-domain/QuizTestReport/useGenerateQuestionDetailProps";
import { useGenerateSubmissionSectionProps } from "../../../../props-factory/v2/view-domain/QuizTestReport/useGenerateSubmissionSectionProps";
import QuizPlaybackEditorContainer from "../../../../props-factory/v2/view-domain/QuizTestReport/widget/QuizPlaybackEditor/Container";
import TotalScoreRankVisualizerInitialContainer from "../../shared/TotalScoreRankVisualizer/InitialContainer";
import { useGenerateHeaderProps } from "./useGenerateHeaderProps";

export type GenerateQuizTestReportPropsArgs = {
  featureKind: "test" | "exam";
  quizId: number;
  companyId: number;
  uniqueKey: QuizTestReport.UniqueKey;
  reportSettings: Graphql.AssessmentReportSettings | null;
};

export const useGenerateProps = (args: GenerateQuizTestReportPropsArgs): Widget.QuizTestReportProps => {
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const hooks = QuizTestReport.useCreateQuizHooks(args.quizId);
  const quiz = hooks.useQuiz();
  const quizPackage = hooks.usePackage();
  const header = useGenerateHeaderProps({ quizId: args.quizId });
  const isAvailableFeature = Payment.useIsAvailableFeature();
  const elapsedTimeSeconds = hooks.useTotalElapsedTimeSeconds();
  const statistics = hooks.useStatistics();
  const submissionSectionProps = useGenerateSubmissionSectionProps({
    quizId: args.quizId,
    uniqueKey: args.uniqueKey,
    showingTargets: [
      isAvailableFeature(`${args.featureKind}.statics.read`) && args.reportSettings?.showRelativeEvaluation && ("STATISTIC" as const),
      args.reportSettings?.showAnswer && ("ANSWER" as const),
      args.reportSettings?.showPlayback && ("PLAYBACK" as const),
    ].flatMap(v => (v ? [v] : [])),
    canEdit: false,
  });
  const questionDetail = useGenerateQuestionDetailProps({
    ...args,
    showAnswer: args.reportSettings?.showAnswer ?? false,
    showArchivedMark: false,
  });
  const entityScoreBoard = useGenerateEntityScoreBoardProps({ quizId: args.quizId, showScore: true });

  const questionIds = React.useMemo(() => {
    return quizPackage.questions.map(question => question.id);
  }, [quizPackage.questions]);

  return {
    header: header,
    entityScoreBoard: entityScoreBoard,
    answerDetailSection: {
      totalElapsedTime: {
        value: formatSeconds(elapsedTimeSeconds, lang),
        icon: {
          key: elapsedTimeSeconds <= statistics.elapsedAvgTimeSeconds ? "CHECK" : "WARNING",
          title: `${t("平均解答時間")} ${formatSeconds(statistics.elapsedAvgTimeSeconds, lang)}`,
        },
        canShowTooltip: isAvailableFeature(`${args.featureKind}.statics.read`),
      },
    },
    StatisticsContent: isAvailableFeature(`${args.featureKind}.statics.read`) &&
      quiz.quizStatus === "EVALUATED" &&
      args.reportSettings?.showRelativeEvaluation && (
        <TotalScoreRankVisualizerInitialContainer uniqueKey={args.uniqueKey} score={quiz.totalScore} enableRank readOnly />
      ),
    submissionSection: submissionSectionProps,
    playbackSection:
      isAvailableFeature(`${args.featureKind}.playback.read`) && args.reportSettings?.showPlayback
        ? {
            PlaybackComponent: (
              <QuizPlaybackEditorContainer key={args.quizId} quizId={args.quizId} packageId={quizPackage.packageId} questionIds={questionIds} />
            ),
          }
        : undefined,
    /**
     * talent will always hide open button
     */
    questionDetail: { ...questionDetail, openButton: undefined },
  };
};
