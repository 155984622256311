import { useTranslation } from "@hireroo/i18n";
import type { TestEvaluationForm } from "@hireroo/validator";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button, { type ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

import TestEvaluationDialog, { TestEvaluationDialogProps } from "../../../../../../../../modules/TestEvaluationDialog/TestEvaluationDialog";

const Wrapper = styled(Box)(() => ({
  textAlign: "center",
  width: "100%",
}));

const StyledButton = styled(Button)(() => ({
  padding: "4px 10px",
}));

const BaseLabelBox = styled(Box)(() => ({
  display: "flex",
  borderRadius: "20px",
  textAlign: "center",
  height: 24,
  paddingLeft: "8px",
  paddingRight: "8px",
  width: "fit-content",
  margin: "0 auto",
}));

const PassedLabelBox = styled(BaseLabelBox)(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.palette.success.main,
  color: theme.palette.success.contrastText,
}));

const NotPassedLabelBox = styled(BaseLabelBox)(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.palette.error.main,
  color: theme.palette.error.contrastText,
}));

type DialogController = {
  setLoading: (loading: boolean) => void;
  close: () => void;
};

type EvaluateDialogProps = {
  testName: string;
  candidateName: string;
  url: string;
  status: "CAN_EVALUATE";
  onSubmit: (fields: TestEvaluationForm.TestEvaluationFormSchema, controller: DialogController) => void;
};

type CannotEvaluateProps = {
  status: "CANNOT_EVALUATE";
};

type ResultDisplayProps = {
  status: "PASSED" | "NOT_PASSED";
};

export type EvaluationProps = EvaluateDialogProps | ResultDisplayProps | CannotEvaluateProps;

type EvaluateStatus = EvaluationProps["status"];

const Evaluation: React.FC<EvaluationProps> = props => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const theme = useTheme();
  const { t } = useTranslation();
  const evaluateButtonProps: ButtonProps = {
    startIcon: <EditOutlinedIcon />,
    children: t("評価"),
    sx: {
      color: theme.palette.text.secondary,
    },
    variant: "text",
    onClick: event => {
      event.stopPropagation();
      event.preventDefault();
      setOpen(true);
    },
  };
  const componentMap: Record<EvaluateStatus, React.ReactElement> = {
    CAN_EVALUATE: <StyledButton {...evaluateButtonProps} />,
    CANNOT_EVALUATE: (
      <Tooltip title={t("実施済でないため評価できません")}>
        <Typography variant="caption">{"-"}</Typography>
      </Tooltip>
    ),
    PASSED: (
      <PassedLabelBox>
        <Typography variant="caption">{t("合格")}</Typography>
      </PassedLabelBox>
    ),
    NOT_PASSED: (
      <NotPassedLabelBox>
        <Typography variant="caption">{t("不合格")}</Typography>
      </NotPassedLabelBox>
    ),
  };

  const testEvaluationDialogProps = React.useMemo((): TestEvaluationDialogProps | undefined => {
    if (props.status !== "CAN_EVALUATE") {
      return;
    }
    return {
      testName: props.testName,
      candidateName: props.candidateName,
      url: props.url,
      open,
      onClose: () => {
        setOpen(false);
      },
      onSubmit: fields => {
        if (props.status === "CAN_EVALUATE") {
          const dialogController: DialogController = {
            close: () => {
              setOpen(false);
              setLoading(false);
            },
            setLoading: (newLoading: boolean) => {
              setLoading(newLoading);
            },
          };
          props.onSubmit(fields, dialogController);
        }
      },
      yesButton: { disabled: loading },
      noButton: { disabled: loading },
    };
  }, [props, open, loading]);

  return (
    <Wrapper>
      {componentMap[props.status]}
      {testEvaluationDialogProps && (
        <TestEvaluationDialog key={`open-${testEvaluationDialogProps.open.toString()}`} {...testEvaluationDialogProps} />
      )}
    </Wrapper>
  );
};

Evaluation.displayName = "Evaluation";

export default Evaluation;
