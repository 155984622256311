import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import EntryContentPaper from "../EntryContentPaper/EntryContentPaper";
import ListContent, { ListContentProps } from "./parts/ListContent/ListContent";

const FrameBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.Other.Divider}`,
  borderRadius: 12,
}));

const EmphasizedList = styled(List)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1.5),
  borderRadius: "8px",
}));

const CommonList = styled(List)(({ theme }) => ({
  padding: theme.spacing(1.5),
}));

export type EntryOverviewSectionProps = {
  /**
   * @default "DEFAULT"
   */
  mode?: "DEFAULT" | "BORDER";
  title: string;
  emphasizedItems?: ListContentProps[];
  items: ListContentProps[];
  SelectableQuestionField?: React.ReactNode;
};

const EntryOverviewSection: React.FC<EntryOverviewSectionProps> = props => {
  const Wrapper = props.mode === "BORDER" ? FrameBox : Box;
  const { emphasizedItems } = props;
  return (
    <EntryContentPaper>
      <Typography variant="h6" style={{ fontWeight: "bold", textAlign: "center" }} mb={2}>
        {props.title}
      </Typography>
      <Wrapper>
        <Stack>
          {emphasizedItems && emphasizedItems.length > 0 && (
            <EmphasizedList>
              {emphasizedItems.map((content, index) => (
                <ListContent key={`${content.title}-${index}`} {...content} alignItems="center" />
              ))}
            </EmphasizedList>
          )}
          <CommonList sx={{ paddingBottom: 0 }}>
            {props.items.map((content, index) => (
              <ListContent key={`${content.title}-${index}`} {...content} />
            ))}
          </CommonList>
        </Stack>
      </Wrapper>
      {props.SelectableQuestionField}
    </EntryContentPaper>
  );
};

EntryOverviewSection.displayName = "EntryOverviewSection";

export default EntryOverviewSection;
