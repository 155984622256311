export const downloadFile = async (targetUrl: string, filename: string): Promise<void> => {
  const resp = await fetch(targetUrl);
  const blob = await resp.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};
