import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import { generateHelpCenterUrl } from "@hireroo/router/api";
import { RemoteTemplateResourceEditorForm } from "@hireroo/validator";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { DeepPartial, useFieldArray } from "react-hook-form";

import QuestionSelectFieldV2InitialContainer from "../QuestionSelectFieldV2/InitialContainer";
import SpotTagFieldContainer from "../SpotTagField/Container";
import ViewerAssignFieldContainer from "../ViewerAssignField/Container";

export type GenerateRemoteTemplateResourceEditorPropsArgs = {
  mode: Widget.RemoteTemplateResourceEditorProps["mode"];
  onSubmit: Widget.RemoteTemplateResourceEditorProps["layout"]["onSubmit"];
};

export const useGenerateProps = (args: GenerateRemoteTemplateResourceEditorPropsArgs): Widget.RemoteTemplateResourceEditorProps => {
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const client = getGraphqlClient();
  const { methods } = Widget.useRemoteTemplateResourceEditorContext();
  const variablesField = useFieldArray({ control: methods.control, name: "variables" });
  const handleAnalyzeRemoteInterviewTemplate = React.useCallback(
    (fields: DeepPartial<RemoteTemplateResourceEditorForm.CreateRemoteInterviewSchema>) => {
      return client
        .AnalyzeRemoteInterviewTemplateResourceEditor({
          analyzeTemplateInput: {
            remoteName: fields.name || "",
            candidateName: fields.candidateName || "",
            questions: [],
            reviewerIds: [],
            tagNames: [],
            memo: fields.memo,
            isPublic: fields.isPublic ?? true,
            variables: [],
          },
        })
        .then(res => {
          if (res.analyzeRemoteInterviewTemplate) {
            const templateNameSet = new Set<string>();
            const resTemplateNameSet = new Set<string>();

            variablesField.fields?.forEach(variable => {
              templateNameSet.add(variable.name);
            });

            res.analyzeRemoteInterviewTemplate.forEach(variable => {
              if (!templateNameSet.has(variable.name)) {
                variablesField.append({
                  templateId: variable.templateId,
                  name: variable.name,
                  description: variable.description,
                });
              }
              resTemplateNameSet.add(variable.name);
            });

            const latestVariables = methods.getValues("variables");
            /**
             * remove variable if it is unmatched with the response
             */
            if (latestVariables && latestVariables.length !== res.analyzeRemoteInterviewTemplate.length) {
              const unmatchedIndex = latestVariables.findIndex(variableField => !resTemplateNameSet.has(variableField.name));
              if (unmatchedIndex >= 0) {
                variablesField.remove(unmatchedIndex);
              }
            }
          }
        })
        .catch(error => {
          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(
            error,
            t("不正な変数が利用されている可能性があります。変数の設定を見直して、それでも直らない場合は運営までお問い合わせ下さい。"),
          );
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
        });
    },
    [client, methods, t, variablesField],
  );
  return {
    layout: {
      helpLink: {
        href: generateHelpCenterUrl(lang, "REMOTE_CREATE_PAGE"),
      },
      onSubmit: fields => {
        const remote = methods.getValues("remote");
        handleAnalyzeRemoteInterviewTemplate(remote).then(() => {
          args.onSubmit(fields);
        });
      },
    },
    mode: args.mode,
    remoteSetupSection: {
      QuestionSelectField: (
        <QuestionSelectFieldV2InitialContainer
          name="entityTracks"
          target="remote"
          enabledRestoreQuestionFromQueryParams={args.mode === "CREATE"}
          enableMultiSelectQuestion={false}
        />
      ),
      ViewerField: <ViewerAssignFieldContainer name="remote.viewers" variant="OUTLINED" />,
      TagField: <SpotTagFieldContainer name="remote.tags" variant="OUTLINED" />,
      onBlur: handleAnalyzeRemoteInterviewTemplate,
    },
  };
};
