import * as React from "react";

type DragStatus = "READY" | "DRAG_START" | "DRAGGING" | "DRAG_END";

type Position = { x: number; y: number };

export type UseDragProps = {
  onDragStart: (params: Position, elem: HTMLElement | null) => void;
  onDrag: (params: Position, elem: HTMLElement | null) => void;
  onDragEnd: (params: Position, elem: HTMLElement | null) => void;
};

const isTouchEvent = (event: MouseEvent | TouchEvent): event is TouchEvent => {
  return "touches" in event;
};

const isRightClickEvent = (event: MouseEvent | TouchEvent): boolean => {
  return "button" in event && event.button !== 0;
};

const getMousePosition = (event: MouseEvent | TouchEvent): Position => {
  if (isTouchEvent(event)) {
    return {
      x: event.touches[0]["clientX"],
      y: event.touches[0]["clientY"],
    };
  } else {
    return {
      x: event.clientX,
      y: event.clientY,
    };
  }
};

export const useDrag = (props: UseDragProps) => {
  const elemRef = React.useRef<HTMLElement | null>(null);
  const dragStatus = React.useRef<DragStatus>("READY");

  const handleDrag = (event: MouseEvent | TouchEvent) => {
    if (dragStatus.current === "DRAG_START" || dragStatus.current === "DRAGGING") {
      dragStatus.current = "DRAGGING";
      props.onDrag(getMousePosition(event), elemRef.current);
    }
  };
  const handleDragStop = (event: MouseEvent | TouchEvent) => {
    dragStatus.current = "DRAG_END";
    props.onDragEnd(getMousePosition(event), elemRef.current);
    window.removeEventListener("mouseup", handleDragStop);
    window.removeEventListener("mousemove", handleDrag);
  };

  const startDragging = (event: MouseEvent | TouchEvent) => {
    if (isRightClickEvent(event)) {
      return;
    }
    event.preventDefault();
    dragStatus.current = "DRAG_START";
    // Drag Start
    window.addEventListener("mousemove", handleDrag);
    window.addEventListener("touchmove", handleDrag);
    // Drag Stop
    window.addEventListener("mouseup", handleDragStop);
    window.addEventListener("touchend", handleDragStop);
    window.addEventListener("touchcancel", handleDragStop);

    props.onDragStart(getMousePosition(event), elemRef.current);
  };

  return {
    subscribe: (elem: HTMLDivElement) => {
      elemRef.current = elem;
      elem.addEventListener("mousedown", startDragging);
      /**
       * @see https://chromestatus.com/feature/5745543795965952
       */
      elem.addEventListener("touchstart", startDragging, { passive: true });

      return () => {
        elem.removeEventListener("mousedown", startDragging);
        elem.removeEventListener("touchstart", startDragging);
      };
    },
  };
};
