import { subscribeKey } from "valtio/utils";

import { clearSubmittedEntity } from "./Action";
import { state } from "./State";
import * as Types from "./types";

export type SubscribeOnSubmitEntityCallback = (entity: Types.ChallengeEntity) => void;
export const subscribeOnSubmitEntity = (callback: SubscribeOnSubmitEntityCallback) => {
  return subscribeKey(state, "submittedEntity", submittedEntity => {
    if (submittedEntity) {
      callback(submittedEntity);
      clearSubmittedEntity();
    }
  });
};

export type SubscribeOnLoadingChangeCallback = (status: Types.LoadingStatus) => void;
export const subscribeOnLoadingChange = (callback: SubscribeOnLoadingChangeCallback) => {
  return subscribeKey(state, "loadingStatus", status => {
    callback(status);
  });
};

export type SubscribeWebSearchParametersCallback = (params: Types.WebSearchParameters) => void;
export const subscribeWebSearchParameters = (callback: SubscribeWebSearchParametersCallback) => {
  return subscribeKey(state, "webSearchParameters", callback);
};
