import { SignInOrSignUp } from "@hireroo/app-store/essential/shared";
import type { Pages } from "@hireroo/presentation";
import * as React from "react";

import SnackbarContainer from "../../../widget/v2/shared/Snackbar/Container";
import ErrorPanelContainer from "./widgets/ErrorPanel/Container";
import ResetPasswordContainer from "./widgets/ResetPassword/Container";

export type GenerateAuthPropsArgs = {};

export const useGenerateProps = (_args: GenerateAuthPropsArgs): Pages.SignInSignUpProps => {
  const firebaseAuthStatusCode = SignInOrSignUp.useFirebaseAuthStatusCode();

  const ContentMap = React.useMemo((): Record<SignInOrSignUp.FirebaseAuthStatusCode, React.ReactNode> => {
    return {
      EMAIL_SUCCESSFULLY_AUTHENTICATED: null,
      PASSWORD_RESET: <ResetPasswordContainer />,
      INVALID_OOB_CODE_FOR_PASSWORD_RESET: <ErrorPanelContainer />,
      CODE_EXPIRED: <ErrorPanelContainer />,
      INVALID_OOB_CODE: <ErrorPanelContainer />,
    };
  }, []);

  return {
    layout: {
      loading: firebaseAuthStatusCode === null,
      Bottom: <SnackbarContainer />,
    },
    children: ContentMap[firebaseAuthStatusCode || "INVALID_OOB_CODE"],
  };
};
