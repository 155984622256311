import * as React from "react";

export type AnyComponentProps = {
  children: React.ReactNode;
};

const AnyComponent: React.FC<AnyComponentProps> = props => {
  return <>{props.children}</>;
};

AnyComponent.displayName = "AnyComponent";

export default AnyComponent;
