import * as Graphql from "@hireroo/graphql/client/urql";
import { proxyMap } from "valtio/utils";

import { state } from "./State";
import type * as Types from "./types";

export const clear = () => {
  state.initialized = false;
  state.count = 0;
  state.hasNext = false;
  state.examMap = proxyMap();
  state.examIds = [];
  state.offset = 0;
};

export const initialize = (res: Graphql.ListBestExamsResponseForExamTransitionVisualizerFragment) => {
  const newExamIds: Types.ExamId[] = [];
  res.exams.forEach(e => {
    if (!state.examMap.has(e.examId)) newExamIds.push(e.examId);
    state.examMap.set(e.examId, e);
  });
  state.examIds = state.examIds.concat(newExamIds);
  state.count = res.count;
  state.hasNext = res.hasNext;
  state.initialized = true;
};

/**
 * next means new data (currentPage: latest 0 <--> n older)
 */
export const goNextPage = () => {
  if (state.currentPage === 0) return;
  state.currentPage -= 1;
};

/**
 * previous means older data (currentPage: latest 0 <--> n older)
 */
export const goPreviousPage = () => {
  state.currentPage += 1;
};

export const updateOffsetToCurrentMax = () => {
  state.offset = state.examIds.length;
};
