import { App } from "@hireroo/app-store/essential/employee";
import { RemotesId } from "@hireroo/app-store/page/e/remotes_id";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import QuestionsTableContainer, { QuestionsTableContainerProps } from "./Container";
import * as Subscriber from "./Subscriber";

const { QuestionsTableStore } = RemotesId;

const availableQuestionVariants: Graphql.QuestionVariant[] = [
  Graphql.QuestionVariant.Challenge,
  Graphql.QuestionVariant.ChallengeAlgorithm,
  Graphql.QuestionVariant.ChallengeDatabase,
  Graphql.QuestionVariant.ChallengeClass,
  Graphql.QuestionVariant.Freepad,
  Graphql.QuestionVariant.SystemDesign,
];

export type QuestionsTableFetchContainerProps = QuestionsTableContainerProps;

const QuestionsTableFetchContainer: React.FC<QuestionsTableFetchContainerProps> = props => {
  const appStatus = App.useStatus();
  const searchQuery = QuestionsTableStore.useSearchQuery();
  React.useEffect(() => {
    const stop = Subscriber.startSubscribeQuestionSearchArea();
    return () => {
      stop();
    };
  }, []);
  /**
   * Make sure to adjust to only the currently available Question Variants
   */
  const questionVariants = React.useMemo(() => {
    const filteredQuestionVariants = [...searchQuery.filters.questionVariants].filter(questionVariant => {
      return availableQuestionVariants.includes(questionVariant);
    });
    if (filteredQuestionVariants.length > 0) {
      return filteredQuestionVariants;
    }
    return availableQuestionVariants;
  }, [searchQuery.filters.questionVariants]);
  const [result, refresh] = Graphql.useListQuestionsQuery({
    variables: {
      questionsInput: {
        isDescending: searchQuery.isDescending,
        offset: searchQuery.size * searchQuery.page,
        size: searchQuery.size,
        sortMethod: searchQuery.sortMethod,
        withCount: true,
        filters: {
          titleJa: searchQuery.filters.titleJa,
          titleEn: searchQuery.filters.titleEn,
          statuses: ["PUBLISHED"], // Questions should be status, PUBLISHED in this table.
          questionVariants: questionVariants,
          difficulties: [...searchQuery.filters.difficulties],
          skillTagIds: searchQuery.filters.skillTagIds.slice(),
          leakScoreLevels: [...searchQuery.filters.leakScoreLevels],
        },
      },
    },
    requestPolicy: "cache-and-network",
    pause: appStatus !== "INITIALIZED",
  });

  React.useEffect(() => {
    return () => {
      QuestionsTableStore.clear();
    };
  }, []);

  React.useEffect(() => {
    QuestionsTableStore.updateLoadingStatus(result.fetching ? "LOADING" : "NONE");
    if (result.data) {
      QuestionsTableStore.initialize(result.data);
    }
  }, [result.data, result.fetching]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED") {
    return <Widget.Loading kind="TOP" color="secondary" />;
  }

  const containerProps: QuestionsTableContainerProps = props;
  return (
    <ErrorBoundary>
      <QuestionsTableContainer {...containerProps} />
    </ErrorBoundary>
  );
};

QuestionsTableFetchContainer.displayName = "QuestionsTableFetchContainer";

export default withErrorBoundary(QuestionsTableFetchContainer, {});
