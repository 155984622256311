import { useTranslation } from "@hireroo/i18n";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button, { type ButtonProps } from "@mui/material/Button";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "../../../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";
import Link, { LinkProps } from "../../../../primitive/Link/Link";

export type MetricsGroupsHeaderProps = {
  createButton: Pick<ButtonProps, "onClick">;
  helpLink: Pick<LinkProps, "href" | "onClick">;
  checkbox: Pick<CheckboxProps, "disabled" | "title" | "checked" | "onChange" | "indeterminate">;
  deleteButton: Pick<IconButtonWithTooltipProps, "onClick" | "disabled">;
};

const MetricsGroupsHeader: React.FC<MetricsGroupsHeaderProps> = props => {
  const theme = useTheme();
  const { t } = useTranslation();
  const checkbox: CheckboxProps = {
    ...props.checkbox,
    sx: {
      width: "36px",
      height: "36px",
    },

    color: "secondary",
    size: "small",
  };
  const deleteButton: IconButtonWithTooltipProps = {
    ...props.deleteButton,
    title: t("削除"),
    children: <DeleteOutlineIcon fontSize="small" />,
  };

  const createButton: ButtonProps = {
    ...props.createButton,
    children: t("作成する"),
    sx: {
      wordBreak: "keep-all",
    },
    startIcon: <AddIcon fontSize="small" />,
    color: "secondary",
    variant: "contained",
  };
  return (
    <Stack direction="row" spacing={1.5} px={1.5}>
      <Checkbox {...checkbox} />
      <Box display="flex" alignItems="center">
        <IconButtonWithTooltip key="delete-button" {...deleteButton} />
      </Box>
      <Button {...createButton} />
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <InfoOutlinedIcon color="info" sx={{ fontSize: "0.8rem" }} />
        <Link {...props.helpLink} color={theme.palette.info.main} target="_blank">
          <Typography fontSize={14} color="info" sx={{ wordBreak: "keep-all" }}>
            {t("ヘルプ")}
          </Typography>
        </Link>
      </Stack>
    </Stack>
  );
};

MetricsGroupsHeader.displayName = "MetricsGroupsHeader";

export default MetricsGroupsHeader;
