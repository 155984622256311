import { ISharedAttachmentsCell, IYText } from "@jupyter/ydoc";
import { SessionContextDialogs } from "@jupyterlab/apputils";
import { Cell, MarkdownCell } from "@jupyterlab/cells";
import {
  CodeMirrorEditorFactory,
  CodeMirrorMimeTypeService,
  EditorExtensionRegistry,
  EditorLanguageRegistry,
  EditorThemeRegistry,
  ybinding,
} from "@jupyterlab/codemirror";
import { DocumentManager, IDocumentWidgetOpener } from "@jupyterlab/docmanager";
import { DocumentRegistry } from "@jupyterlab/docregistry";
import { MathJaxTypesetter } from "@jupyterlab/mathjax-extension";
import { CellType, IAttachments } from "@jupyterlab/nbformat";
import { NotebookModelFactory, NotebookPanel, NotebookWidgetFactory } from "@jupyterlab/notebook";
import { RenderMimeRegistry, standardRendererFactories as initialFactories } from "@jupyterlab/rendermime";
import { CommandRegistry } from "@lumino/commands";
import { AttachedProperty } from "@lumino/properties";
import { BoxPanel, Widget } from "@lumino/widgets";

import { initializeMarked } from "../utils/markdown";
import { setupCommands } from "./commands";
import { Config, ConfigManager } from "./ConfigManager";
import { NotebookPanelController } from "./NotebookPanelController";

export type { CellType, Config };

class JupyterNotebookAdapter {
  private isDisposed = false;
  #configManager: ConfigManager;
  #commandRegistry = new CommandRegistry();
  #editorLanguageRegistry = new EditorLanguageRegistry();
  #documentRegistry = new DocumentRegistry();
  #notebookModelFactory = new NotebookModelFactory({});
  disposeCallbacks: Array<() => void> = [];
  #boxPanel: BoxPanel | undefined;
  #notebookPanelController: NotebookPanelController | undefined;
  #sessionContextDialogs: SessionContextDialogs | undefined;

  constructor(
    readonly config: Config,
    private filename: string,
  ) {
    this.#configManager = new ConfigManager(config);
  }

  public initialize = (): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      this.#configManager.serviceManager.ready
        .then(() => {
          this.createApp();
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  public onDispose = (callback: () => void) => {
    this.disposeCallbacks.push(callback);
  };

  public dispose = () => {
    if (this.isDisposed) {
      return;
    }
    this.isDisposed = true;
    /**
     * DO NOT exec sessions.dispose();
     *
     * Jupyter Lab also does not dispose of open files when they are changed.
     */
    this.#configManager.serviceManager.events.dispose();
    this.#configManager.serviceManager.contents.dispose();
    this.#configManager.serviceManager.terminals.dispose();
    this.#configManager.serviceManager.kernels.dispose();
    this.#configManager.serviceManager.kernelspecs.dispose();
    this.#configManager.serviceManager.user.dispose();
    this.disposeCallbacks.forEach(callback => {
      callback();
    });
    this.disposeCallbacks = [];
  };

  public resize = () => {
    this.#boxPanel?.update();
  };

  public mount = (target: HTMLDivElement) => {
    Widget.attach(this.boxPanel, target);
    Widget.attach(this.widgetManager.completer, target);
    const dispose = setupCommands(
      this.#commandRegistry,
      this.widgetManager.commandPalette,
      this.widgetManager.notebookPanel,
      this.widgetManager.completionHandler,
      this.sessionContextDialogs,
    );
    this.disposeCallbacks.push(dispose);
  };

  public changeCellType = (cellType: CellType) => {
    const notebook = this.widgetManager.notebookPanel.content;
    notebook.widgets.forEach((child, index) => {
      if (!notebook.isSelectedOrActive(child)) {
        return;
      }
      if (child.model.type !== cellType) {
        const raw = child.model.toJSON();
        const notebook = this.widgetManager.notebookPanel.content.model?.sharedModel;
        notebook?.transact(() => {
          notebook?.deleteCell(index);
          const newCell = notebook?.insertCell(index, {
            cell_type: cellType,
            source: raw.source,
            metadata: raw.metadata,
          });
          if (raw.attachments && ["markdown", "raw"].includes(cellType)) {
            (newCell as ISharedAttachmentsCell).attachments = raw.attachments as IAttachments;
          }
        });
      }
      if (cellType === "markdown") {
        // Fetch the new widget and unrender it.
        child = notebook.widgets[index];
        (child as MarkdownCell).rendered = false;
      }
    });
    notebook.deselectAll();

    notebook.activate();
  };

  public insert = (direction: "ABOVE" | "BELOW", source?: string) => {
    const notebook = this.widgetManager.notebookPanel.content;
    const model = this.widgetManager.notebookPanel.context.model;
    const buffer = direction === "ABOVE" ? 0 : 1;
    const newIndex = notebook.activeCell ? notebook.activeCellIndex + buffer : 0;
    model.sharedModel.insertCell(newIndex, {
      cell_type: notebook.notebookConfig.defaultCell,
      source,
      metadata:
        notebook.notebookConfig.defaultCell === "code"
          ? {
              // This is an empty cell created by user, thus is trusted
              trusted: true,
            }
          : {},
    });
    // Make the newly inserted cell active.
    notebook.activeCellIndex = newIndex;
    notebook.deselectAll();
  };

  public get notebook() {
    return this.#notebookPanelController?.notebookPanel.content;
  }

  public get sessionContext() {
    return this.#notebookPanelController?.notebookPanel.context?.sessionContext;
  }

  public get commands() {
    return this.#commandRegistry;
  }

  public isSelectedOrActive(cell: Cell) {
    if (cell === this?.notebook?.activeCell) {
      return true;
    }
    const selectedProperty = new AttachedProperty<Cell, boolean>({
      name: "selected",
      create: () => false,
    });
    return selectedProperty.get(cell);
  }

  private get opener(): IDocumentWidgetOpener {
    return {
      open: (_widget: Widget) => {
        //
      },
      get opened() {
        return {
          connect: () => {
            return false;
          },
          disconnect: () => {
            return false;
          },
        };
      },
    };
  }

  private get sessionContextDialogs() {
    if (!this.#sessionContextDialogs) {
      throw new Error("Not initialized sessionContextDialogs");
    }
    return this.#sessionContextDialogs;
  }

  private get widgetManager() {
    if (!this.#notebookPanelController) {
      throw new Error("Not initialized widgetManager");
    }
    return this.#notebookPanelController;
  }

  private get boxPanel() {
    if (!this.#boxPanel) {
      throw new Error("Not initialized split Panel");
    }
    return this.#boxPanel;
  }

  private createApp = () => {
    this.disposeCallbacks.push(this.createEventListener());
    const documentManager = new DocumentManager({
      registry: this.#documentRegistry,
      manager: this.#configManager.serviceManager,
      opener: this.opener,
    });
    this.setupEditorLanguage();
    const notebookWidgetFactory = this.createNotebookWidgetFactory();
    /**
     * File conflicts occur at unexpected times when AutoSave is implemented.
     * Need to investigate the cause to resolve this.
     *
     * Slack Log: https://hireroo.slack.com/archives/CSF6X18SE/p1702011518274909
     *
     * Right now, we are trying to make sure that the save is performed as much as possible due to user actions.
     */
    documentManager.autosave = false;

    this.#documentRegistry.addModelFactory(this.#notebookModelFactory);
    this.#documentRegistry.addWidgetFactory(notebookWidgetFactory);
    const notebookPanel = documentManager.open(this.filename) as NotebookPanel;
    this.#notebookPanelController = new NotebookPanelController(notebookPanel, this.#commandRegistry);
    this.attachWidget();
  };

  private attachWidget = () => {
    const notebookPanelWidget = this.widgetManager;
    this.#boxPanel = new BoxPanel();
    this.#boxPanel.addClass("hireroo-Jupyter-Notebook");
    this.#boxPanel.spacing = 0;

    this.#boxPanel.addWidget(notebookPanelWidget.commandPalette);
    this.#boxPanel.addWidget(notebookPanelWidget.notebookPanel);

    BoxPanel.setStretch(notebookPanelWidget.commandPalette, 0);
    BoxPanel.setStretch(notebookPanelWidget.notebookPanel, 1);
  };

  private setupEditorLanguage = () => {
    EditorLanguageRegistry.getDefaultLanguages()
      .filter(language => ["ipython", "julia", "python"].includes(language.name.toLowerCase()))
      .forEach(language => {
        this.#editorLanguageRegistry.addLanguage(language);
      });
    // Language for Markdown cells
    this.#editorLanguageRegistry.addLanguage({
      name: "ipythongfm",
      mime: "text/x-ipythongfm",
      load: async () => {
        const m = await import("@codemirror/lang-markdown");
        return m.markdown({
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          codeLanguages: (info: string) => this.#editorLanguageRegistry.findBest(info) as any,
        });
      },
    });
  };

  private createNotebookWidgetFactory = () => {
    this.#sessionContextDialogs = new SessionContextDialogs();
    const codeMirrorEditorFactory = this.createCodeMirrorEditorFactory();
    const editorFactory = codeMirrorEditorFactory.newInlineEditor;
    const mimeTypeService = new CodeMirrorMimeTypeService(this.#editorLanguageRegistry);
    const contentFactory = new NotebookPanel.ContentFactory({ editorFactory });
    const renderMimeRegistry = new RenderMimeRegistry({
      initialFactories: initialFactories,
      latexTypesetter: new MathJaxTypesetter(),
      markdownParser: {
        render: source => {
          const marked = initializeMarked(this.#editorLanguageRegistry);
          return new Promise<string>((resolve, reject) => {
            marked(source, (error, parsedResult) => {
              if (error) {
                reject(error);
              } else {
                resolve(parsedResult);
              }
            });
          });
        },
      },
    });

    return new NotebookWidgetFactory({
      name: "Notebook",
      modelName: "notebook",
      fileTypes: ["notebook"],
      defaultFor: ["notebook"],
      preferKernel: true,
      autoStartDefault: false,
      canStartKernel: true,
      shutdownOnClose: false,
      rendermime: renderMimeRegistry,
      contentFactory,
      mimeTypeService,
    });
  };

  private createCodeMirrorEditorFactory = () => {
    const editorExtensions = () => {
      const themes = new EditorThemeRegistry();
      EditorThemeRegistry.getDefaultThemes().forEach(theme => {
        themes.addTheme(theme);
      });
      const registry = new EditorExtensionRegistry();

      EditorExtensionRegistry.getDefaultExtensions({ themes }).forEach(extensionFactory => {
        registry.addExtension(extensionFactory);
      });
      registry.addExtension({
        name: "shared-model-binding",
        factory: options => {
          const sharedModel = options.model.sharedModel as IYText;
          return EditorExtensionRegistry.createImmutableExtension(
            ybinding({
              ytext: sharedModel.ysource,
              undoManager: sharedModel.undoManager ?? undefined,
            }),
          );
        },
      });
      return registry;
    };
    return new CodeMirrorEditorFactory({
      extensions: editorExtensions(),
      languages: this.#editorLanguageRegistry,
    });
  };

  private createEventListener = () => {
    const callback = (event: KeyboardEvent) => {
      this.#commandRegistry.processKeydownEvent(event);
    };
    document.addEventListener("keydown", callback, true);

    return () => {
      document.removeEventListener("keydown", callback);
    };
  };
}

export { JupyterNotebookAdapter };
