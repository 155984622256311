import { AlgorithmResourceEditor } from "@hireroo/app-store/view-domain/AlgorithmResourceEditor";
import { getLanguage } from "@hireroo/i18n";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import FetchContainer from "./FetchContainer";
import AlgorithmResourceEditorMiddleContainer from "./MiddleContainer";

export type AlgorithmResourceEditorInitialContainerProps = {};

const variantMap: Record<string, "ALGORITHM" | "DATABASE" | "CLASS"> = {
  algorithm: "ALGORITHM",
  database: "DATABASE",
};

const AlgorithmResourceEditorInitialContainer: React.FC<AlgorithmResourceEditorInitialContainerProps> = () => {
  const [query] = useSearchParams();
  const initVariant: string | null = query.get("type");

  const draftQuestionId: number | null = query.get("id") !== null ? Number(query.get("id")) : null;

  React.useEffect(() => {
    if (initVariant && draftQuestionId === null) {
      AlgorithmResourceEditor.initialize(getLanguage(), variantMap[initVariant]);
    }
  }, [draftQuestionId, initVariant]);

  React.useEffect(() => {
    return () => {
      AlgorithmResourceEditor.clear();
    };
  });

  if (draftQuestionId === null) {
    return (
      <ErrorBoundary>
        <AlgorithmResourceEditorMiddleContainer />
      </ErrorBoundary>
    );
  }
  return (
    <ErrorBoundary>
      <FetchContainer questionId={draftQuestionId} />
    </ErrorBoundary>
  );
};
AlgorithmResourceEditorInitialContainer.displayName = "AlgorithmResourceEditorInitialContainer";

export default withErrorBoundary(AlgorithmResourceEditorInitialContainer, {});
