import { proxy } from "valtio";
import { proxyMap } from "valtio/utils";

import type * as Types from "./types";

export const state = proxy<Types.State>({
  formValues: {
    step: 0,
    metricIds: [],
    comment: "",
    isRecommended: true,
    evaluations: [],
  },
  metrics: proxyMap(),
  metricPagination: {
    hasNext: false,
    nextCursor: null,
    cursor: null,
  },
  reviewedRemote: null,
});
