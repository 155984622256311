import { KeyboardArrowRightOutlined } from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "../../../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";

export type HeaderProps = {
  Icon?: React.ReactNode;
  title: string;
  titleLink?: Pick<IconButtonWithTooltipProps, "onClick" | "title">;
  RightSideComponent?: React.ReactNode;
};

const Header: React.FC<HeaderProps> = props => {
  const linkButton: IconButtonWithTooltipProps | undefined = props.titleLink
    ? {
        ...props.titleLink,
        children: <KeyboardArrowRightOutlined fontSize="small" />,
      }
    : undefined;

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" alignItems="center" spacing={1}>
        {props.Icon}
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Typography fontSize={20} fontWeight={700}>
            {props.title}
          </Typography>
          {linkButton && <IconButtonWithTooltip {...linkButton} />}
        </Stack>
      </Stack>
      {props.RightSideComponent}
    </Stack>
  );
};

Header.displayName = "Header";

export default Header;
