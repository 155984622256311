import { proxy } from "valtio";

import type { State } from "./types";

export const state = proxy<State>({
  isAuthorized: false,
  spotSettings: {
    subscribeSpotCreated: false,
    subscribeSpotStarted: false,
    subscribeSpotCompleted: false,
    subscribeSpotReviewed: false,
    subscribeSpotFinalized: false,
    subscribeSpotEvaluated: false,
    subscribeSpotRecreated: false,
    subscribeSpotDeleted: false,
    subscribeSpotExpired: false,
  },
  remoteSettings: {
    subscribeRemoteCreated: false,
    subscribeRemoteAwaiting: false,
    subscribeRemoteStarted: false,
    subscribeRemoteCompleted: false,
    subscribeRemoteDeleted: false,
    subscribeRemoteReviewed: false,
    subscribeRemoteEvaluated: false,
  },
  incomingWebHook: null,
  language: "UNKNOWN",
  status: "NOT_INITIALIZED",
});
