import { useEffect, useState } from "react";
import { Room } from "twilio-video";

import { isRoomState } from "./privateHelper";
import { RoomState } from "./types";
/**
 * This hook allows components to reliably use the 'state' property of the Room object.
 * TODO: better to call in Context
 */
export const useRoomState = (room: Room | null) => {
  const [state, setState] = useState<RoomState>("disconnected");

  useEffect(() => {
    if (room) {
      const handleRoomState = () => {
        if (!isRoomState(room.state)) {
          throw new Error(`Unknown room state: ${room.state}`);
        }
        setState(room.state);
      };

      handleRoomState();

      room.on("disconnected", handleRoomState).on("reconnected", handleRoomState).on("reconnecting", handleRoomState);
      return () => {
        room.off("disconnected", handleRoomState).off("reconnected", handleRoomState).off("reconnecting", handleRoomState);
      };
    }
  }, [room]);

  return state;
};
