import { scrollToContentForReportV2 } from "@hireroo/app-helper/html-element";
import { SystemDesignTestReport } from "@hireroo/app-store/view-domain/SystemDesignTestReport";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { formatScore } from "@hireroo/formatter/score";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useCurrentLanguage, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/react";

type EntityScoreBoard = Exclude<Widget.SystemDesignTestReportProps["scoreBoard"], undefined>;
type ScoreBarProps = EntityScoreBoard["scoreBars"][0];
type ScoreBoardStatus = EntityScoreBoard["status"];
type ShowingTarget = "SCORE" | "RANK";

export type GenerateEntityScoreBoardProps = {
  entityId: number;
  showingTargets: ShowingTarget[];
};

export const useGenerateEntityScoreBoardProps = (args: GenerateEntityScoreBoardProps): Widget.SystemDesignTestReportProps["scoreBoard"] => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const client = getGraphqlClient();
  const hooks = SystemDesignTestReport.useCreateSystemDesignHooks(args.entityId);
  const submission = hooks.useCurrentSubmission();
  const submissions = hooks.useSubmissions();
  const submissionId = hooks.useCurrentSelectedSubmissionId();
  const aggScoringItemMap = hooks.useCalculatedScoringItemMap();
  const question = hooks.useQuestion();
  const lang = useCurrentLanguage();
  const submissionStatusMap: Record<Graphql.ProjectSubmissionStatus, string> = {
    ENQUEUED: `(${t("採点中")})`,
    FAILED: `(${t("採点失敗")})`,
    EVALUATED: "",
    UNKNOWN: "",
  };
  const statusMap: Record<Graphql.SystemDesignSubmissionStatus, ScoreBoardStatus> = {
    ENQUEUED: "EVALUATING",
    EVALUATED: "EVALUATED",
    FAILED: "ERROR",
    UNKNOWN: "EVALUATING",
  };

  const scoreBars: (ScoreBarProps | false)[] = [
    aggScoringItemMap.availability !== null && {
      title: t("可用性"),
      score: aggScoringItemMap.availability.score,
      description: t("可用性の評価項目の達成率から算出された値"),
      color: "secondary",
      link: {
        onClick: () => {
          scrollToContentForReportV2(SystemDesignTestReport.TargetElementIdMap.AVAILABILITY_SECTION);
        },
      },
    },
    aggScoringItemMap.scalability !== null && {
      title: t("スケーラビリティ"),
      score: aggScoringItemMap.scalability.score,
      description: t("スケーラビリティの評価項目の達成率から算出された値"),
      color: "info",
      link: {
        onClick: () => {
          scrollToContentForReportV2(SystemDesignTestReport.TargetElementIdMap.SCALABILITY_SECTION);
        },
      },
    },
    aggScoringItemMap.consistency !== null && {
      title: t("一貫性"),
      score: aggScoringItemMap.consistency.score,
      description: t("一貫性の評価項目の達成率から算出された値"),
      color: "warning",
      link: {
        onClick: () => {
          scrollToContentForReportV2(SystemDesignTestReport.TargetElementIdMap.CONSISTENCY_SECTION);
        },
      },
    },
  ];
  const difficultyMap: Record<Graphql.Difficulty, EntityScoreBoard["difficulty"]> = {
    EASY: "EASY",
    MEDIUM: "MEDIUM",
    DIFFICULT: "DIFFICULT",
    UNKNOWN: "EASY",
  };

  return {
    difficulty: difficultyMap[question?.difficulty ?? "UNKNOWN"],
    title: resolveLanguage(question, lang, "title"),
    scoreChart:
      (args.showingTargets.includes("SCORE") && {
        score: formatScore(submission?.totalScore ?? 0),
      }) ||
      undefined,
    scoreBars: args.showingTargets.includes("SCORE") ? scoreBars.flatMap(v => (v ? [v] : [])) : [],
    submissionSelect: {
      onChange: event => {
        const submissionId = Number(event.target.value);
        SystemDesignTestReport.setCurrentSelectedSubmissionId(args.entityId, submissionId);
        client
          .GetSystemDesignSubmissionForSystemDesignTestReport({
            submissionId: submissionId,
          })
          .then(res => {
            SystemDesignTestReport.setSubmission(args.entityId, res.systemDesignSubmission);
          })
          .catch(error => {
            Sentry.captureException(error);
            Snackbar.notify({
              message: t("テストの取得に失敗しました。"),
              severity: "error",
            });
          })
          .finally(() => {
            SystemDesignTestReport.setCurrentSelectedSubmissionId(args.entityId, submissionId);
          });
      },
      value: submissionId,
    },
    submissionOptions: submissions.map((submission, index) => {
      const totalScore = formatScore(submission.totalScore);
      return {
        id: submission.systemDesignSubmissionId.toString(),
        displayText: args.showingTargets.includes("SCORE")
          ? `${index + 1} ${submission.isBest ? `【${t("最高得点")}】` : ""} ${t("スコア")} ${totalScore}% ${
              submissionStatusMap[submission.status]
            }`
          : t2("NumberOfSubmission", { number: `${index + 1}` }),
      };
    }),
    status: statusMap[submission?.status ?? "UNKNOWN"],
  };
};
