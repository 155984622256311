import { AlgorithmSignatureForm } from "@hireroo/validator";
import Stack from "@mui/material/Stack";
import * as React from "react";

import TestCaseTableSummary from "../../../TestCaseTableSummary/TestCaseTableSummary";

export type DatabaseTestCaseInputFieldsProps = {
  index: number;
  signature: AlgorithmSignatureForm.DatabaseSignatureSchemaType;
};

const DatabaseInputFields: React.FC<DatabaseTestCaseInputFieldsProps> = props => {
  return (
    <Stack spacing={4}>
      <TestCaseTableSummary signature={props.signature} testCaseIndex={props.index} summaryKind="INPUT" testCaseKind="performanceTestCases" />
      <TestCaseTableSummary signature={props.signature} testCaseIndex={props.index} summaryKind="OUTPUT" testCaseKind="performanceTestCases" />
    </Stack>
  );
};

DatabaseInputFields.displayName = "DatabaseInputFields";

export default DatabaseInputFields;
