import { Pages } from "@hireroo/presentation/legacy";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProviderProps } from "./useGenerateProviderProps";

export type AlgorithmResourceProviderProps = {
  type: "CREATE" | "UPDATE";
};

const AlgorithmResourceProvider: React.FC<React.PropsWithChildren<AlgorithmResourceProviderProps>> = props => {
  const providerProps = useGenerateProviderProps({
    type: props.type,
  });
  return (
    <ErrorBoundary>
      <Pages.AlgorithmResourceEditorProvider {...providerProps} children={props.children} />
    </ErrorBoundary>
  );
};

AlgorithmResourceProvider.displayName = "AlgorithmResourceProvider";

export default withErrorBoundary(AlgorithmResourceProvider, {});
