import { useTranslation } from "@hireroo/i18n";
import { Fields } from "@hireroo/validator";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useWatch } from "react-hook-form";

import InputControlTextField, {
  InputControlTextFieldProps,
} from "../../../../primitive/InputControl/InputControlTextField/InputControlTextField";

type FieldValue = {
  timeLimitType: Fields.TimeLimitField.TimeLimitTypeFieldSchema;
};

const StyledInputControlTextField = styled(InputControlTextField)`
  width: 75px;
  margin-right: 10px;
`;

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export type CustomInputFieldProps = {
  name: string;
  disabled?: boolean;
  defaultValue?: number;
};

const CustomInputField: React.FC<CustomInputFieldProps> = props => {
  const { t } = useTranslation();
  const name = props.name as "timeLimitType";
  const timeLimitType = useWatch<FieldValue>({
    name: name,
  });

  const customFieldProps: InputControlTextFieldProps = {
    color: "secondary",
    type: "number",
    InputLabelProps: {
      shrink: true,
    },
    InputProps: {
      inputProps: {
        min: 5,
        step: "1",
      },
    },
    fullWidth: true,
    variant: "outlined",
    size: "small",
    disabled: props.disabled || timeLimitType !== "CUSTOM",
    ignoreErrorMessage: "IGNORE_ERROR",
    defaultValue: props.defaultValue,
  };

  if (timeLimitType !== "CUSTOM") {
    return null;
  }

  return (
    <StyledBox>
      <StyledInputControlTextField name={"timeLimitMinutes"} {...customFieldProps} />
      {t("分")}
    </StyledBox>
  );
};

CustomInputField.displayName = "CustomInputField";

export default CustomInputField;
