import { ExamRankVisualizer } from "@hireroo/app-store/widget/shared/ExamRankVisualizer";
import type { Widget } from "@hireroo/presentation";

export type GenerateTestRankVisualizerPropsArgs = {
  enableTagField: boolean;
  enableRank: boolean;
  isReliableExamRank: boolean;
  readOnly?: boolean;
};

export const useGenerateProps = (args: GenerateTestRankVisualizerPropsArgs): Widget.TestRankVisualizerProps => {
  const binsData = ExamRankVisualizer.useBins();
  const benchMarks = ExamRankVisualizer.useBenchMarks(args.enableRank);
  const query = ExamRankVisualizer.useQuery();
  const graphStatus = ExamRankVisualizer.useGraphStatus();
  const tags = ExamRankVisualizer.useTags();
  const now = new Date();

  return {
    status: args.isReliableExamRank ? graphStatus : "NOT_ENOUGH",
    queryToolbar: {
      readOnly: args.readOnly,
      enableTagField: args.enableTagField,
      defaultValues: {
        ...query,
        tags: tags.map(tag => {
          return {
            value: tag.name,
            selected: false,
          };
        }),
      },
      onChange: fields => {
        ExamRankVisualizer.updateQuery({
          ...fields,
          tags: fields.tags.filter(tag => tag.selected),
        });
      },
      startDatePicker: {
        max: query.endDate || now,
        datePicker: {
          maxDate: query.endDate || now,
        },
      },
      endDatePicker: {
        min: query.startDate ?? undefined,
        max: now,
        datePicker: {
          minDate: query.startDate ?? undefined,
          maxDate: now,
        },
      },
    },
    areaHistogram: {
      data: binsData,
      lines: benchMarks,
    },
  };
};
