import { App, Company } from "@hireroo/app-store/essential/employee";
import { ScreeningsIdDetailStore } from "@hireroo/app-store/page/e/screenings_id_detail";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import ScreeningDetailContainer, { ScreeningDetailContainerProps } from "./Container";

const FETCH_LIMIT = 100;

export type ScreeningDetailFetchContainerProps = {};

const ScreeningDetailFetchContainer: React.FC<ScreeningDetailFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const companyId = Company.useStrictActiveCompanyId();
  const [result, refresh] = Graphql.useListSpotTagsForScreeningDetailQuery({
    variables: {
      input: {
        companyId: companyId,
        size: FETCH_LIMIT,
        // TODO: please change when create pagination.
        withCount: false,
        cursorSeconds: undefined,
      },
    },
    pause: appStatus !== "INITIALIZED",
  });

  React.useEffect(() => {
    if (result.data?.spotTagsByCompanyId?.tags) {
      ScreeningsIdDetailStore.updateTags(result.data.spotTagsByCompanyId.tags.map(t => t.name));
    }
  }, [result.data?.spotTagsByCompanyId?.tags]);

  React.useEffect(() => {
    return () => {
      ScreeningsIdDetailStore.clear();
    };
  }, []);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED") {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: ScreeningDetailContainerProps = {};

  return <ScreeningDetailContainer {...containerProps} />;
};

ScreeningDetailFetchContainer.displayName = "ScreeningDetailFetchContainer";

export default withErrorBoundary(ScreeningDetailFetchContainer, {});
