import { useTranslation } from "@hireroo/i18n";
import { RemoteInterviewResourceEditor as RemoteInterviewResourceEditorValidator } from "@hireroo/validator";
import * as React from "react";

import { useRemoteInterviewResourceEditorContext } from "./Context";
import ConfirmSection, { ConfirmSectionProps } from "./parts/ConfirmSection/ConfirmSection";
import { HoverButtonProps } from "./parts/HoverButton/HoverButton";
import Layout, { LayoutProps } from "./parts/Layout/Layout";
import QuestionSetupSection, { QuestionSetupSectionProps } from "./parts/QuestionSetupSection/QuestionSetupSection";
import ReportSetupSection, { ReportSetupSectionProps } from "./parts/ReportSetupSection/ReportSetupSection";

export {
  RemoteInterviewResourceEditorProvider,
  type RemoteInterviewResourceEditorProviderProps,
  useRemoteInterviewResourceEditorContext,
} from "./Context";

type Mode = "EDIT" | "CREATE";

export type RemoteInterviewResourceEditorProps = {
  className?: string;
  mode: Mode;
  questionSetupSection: QuestionSetupSectionProps;
  reportSection: ReportSetupSectionProps;
  confirmSection: ConfirmSectionProps;
  layout: Pick<LayoutProps, "helpLink" | "loading">;
  templateButton?: Pick<HoverButtonProps, "onClick">;
  onSubmit: (fields: RemoteInterviewResourceEditorValidator.CreateRemoteInterviewSchema) => void;
  Dialog?: React.ReactNode;
};

const RemoteInterviewResourceEditor: React.FC<RemoteInterviewResourceEditorProps> = props => {
  const { t } = useTranslation();
  const { setSubmitHandler } = useRemoteInterviewResourceEditorContext();
  const hoverButton: HoverButtonProps | undefined = props.templateButton
    ? {
        ...props.templateButton,
        text: t("テンプレートから作成"),
      }
    : undefined;

  React.useEffect(() => {
    setSubmitHandler(props.onSubmit);
  }, [setSubmitHandler, props.onSubmit]);
  return (
    <Layout {...props.layout} mode={props.mode} className={props.className} hoverButton={hoverButton}>
      <QuestionSetupSection {...props.questionSetupSection} />
      <ReportSetupSection {...props.reportSection} />
      <ConfirmSection {...props.confirmSection} />
      {props.Dialog}
    </Layout>
  );
};

RemoteInterviewResourceEditor.displayName = "RemoteInterviewResourceEditor";

export default RemoteInterviewResourceEditor;
