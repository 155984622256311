import { useTranslation } from "@hireroo/i18n";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";

const StyledListItemText = styled(ListItemText)(() => ({
  margin: 0,
}));

type Member = {
  displayName: string;
  src?: string;
  email: string;
};

export type DeleteMembersProps = {
  dialog: Pick<DialogWithCloseIconProps, "open" | "onClose" | "yesButton" | "noButton" | "title">;
  description: string;
  members: Member[];
  onSubmit?: () => void;
};

const DeleteMembers: React.FC<DeleteMembersProps> = props => {
  const { t } = useTranslation();

  const dialog: DialogWithCloseIconProps = {
    ...props.dialog,
    yesButton: {
      children: t("削除"),
      color: "error",
      onClick: () => {
        props.onSubmit?.();
        props.dialog.yesButton?.onClick?.();
      },
      autoFocus: false,
    },
    noButton: {
      ...props.dialog.noButton,
      children: t("キャンセル"),
      variant: "outlined",
      color: "outline-only",
    },
    disableEnter: true,
    optionalDialog: {
      disableAutoFocus: true,
    },
  };
  return (
    <DialogWithCloseIcon {...dialog}>
      <Stack>
        <Typography fontSize="14px">{props.description}</Typography>
        <List>
          {props.members.map(member => (
            <ListItem key={member.email} divider>
              <ListItemAvatar>
                <Avatar src={member.src} sx={{ height: "32px", width: "32px" }} />
              </ListItemAvatar>
              <StyledListItemText
                primary={member.displayName}
                secondary={member.email}
                primaryTypographyProps={{ mb: "4px" }}
                secondaryTypographyProps={{ fontSize: 12 }}
              />
            </ListItem>
          ))}
        </List>
      </Stack>
    </DialogWithCloseIcon>
  );
};

DeleteMembers.displayName = "DeleteMembers";

export default DeleteMembers;
