import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import * as React from "react";

import FeatureTableHeadCell, { FeatureTableHeadCellProps } from "./parts/FeatureTableHeadCell/FeatureTableHeadCell";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "& th, & td": {
    borderRight: `4px solid ${theme.palette.mode === "light" ? theme.palette.common.white : theme.palette.background.default}`,
  },
  "& th:last-child, & td:last-child": {
    borderRight: "none",
  },
}));

export type FeatureTableHeadRowProps = {
  items: FeatureTableHeadCellProps[];
};

const FeatureTableHeadRow: React.FC<FeatureTableHeadRowProps> = props => {
  return (
    <StyledTableRow>
      <TableCell />
      {props.items.map((item, index) => {
        return <FeatureTableHeadCell key={`index-${index}`} {...item} />;
      })}
    </StyledTableRow>
  );
};

FeatureTableHeadRow.displayName = "FeatureTableHeadRow";

export default FeatureTableHeadRow;
