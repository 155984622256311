import { getRef } from "@hireroo/firebase";
import { useEffect, useState } from "react";

import { SyncOperation } from "../firepad";

export const useProjectRevisions = (projectId: number, questionId: number): [Record<string, SyncOperation[]>, boolean] => {
  const [revisions, setRevisions] = useState<Record<string, SyncOperation[]>>({});
  const [ready, setReady] = useState<boolean>(false);

  // Collect all modified files inside of node directory, then update modifiedFiles
  useEffect(() => {
    const nodesRef = getRef("project", `projects/${projectId}/questions/${questionId}/nodes`);
    const revisions: Record<string, SyncOperation[]> = {};

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const recursiveGet = (x: any, path: string[]) => {
      if (x === undefined || x === null || typeof x !== "object") return;
      for (const key of Object.keys(x).sort()) {
        if (key === "history") {
          const sorted = Object.keys(x[key]).sort();
          const sortedRevisions: SyncOperation[] = [];

          for (let i = 0; i < sorted.length; i++) {
            sortedRevisions.push(x[key][sorted[i]]);
          }

          // Ignore the case where only template code is shown or the empty revision
          if (sortedRevisions.length > 1) {
            revisions[decodeURI(path.join("/"))] = sortedRevisions;
          }
          continue;
        }
        recursiveGet(x[key], [...path, key]);
      }
    };

    nodesRef.once("value", snapshot => {
      recursiveGet(snapshot.val(), []);
      setRevisions(revisions);
      setReady(true);
    });
  }, [projectId, questionId]);

  return [revisions, ready];
};
