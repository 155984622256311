import { state } from "./State";
import type * as Types from "./types";

export const updateExam = (newValue: Types.Exam): void => {
  state.exam = newValue;
};

export const clear = () => {
  state.exam = null;
};
