import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type CompanySelectContainerProps = {};

const CompanySelectContainer: React.FC<CompanySelectContainerProps> = () => {
  const companySelectProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.CompanySelect {...companySelectProps} />
    </ErrorBoundary>
  );
};

CompanySelectContainer.displayName = "CompanySelectContainer";

export default withErrorBoundary(CompanySelectContainer, {});
