import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

type Mark = "UP" | "DOWN" | "FLAT";

type Rank = "S" | "A" | "B" | "C" | "D" | "UNKNOWN";

const StyledBox = styled(Box)``;

const StyledTypography = styled(Typography)(() => ({
  '[data-kind="rank"]': {
    fontSize: 14,
    fontWeight: "bold",
  },
  '[data-kind="score"]': {
    fontSize: 12,
  },
}));

export type ResultItemProps = {
  no: string;
  value: { kind: "RANK"; rank: Rank; score: string; tooltip?: string } | { kind: "NOT_SUBMITTED" | "UNDER_EVALUATION" | "EXPIRED" };
  mark?: Mark;
};

const ResultItem: React.FC<ResultItemProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colorMap: Record<Rank, string> = {
    S: theme.palette.Rank.S,
    A: theme.palette.Rank.A,
    B: theme.palette.Rank.B,
    C: theme.palette.Rank.C,
    D: theme.palette.Rank.D,
    UNKNOWN: theme.palette.grey[400],
  };
  return (
    <StyledBox>
      <Typography sx={{ fontSize: 10 }}>{props.no}</Typography>
      {props.value.kind === "RANK" && (
        <Tooltip title={props.value.tooltip ?? ""}>
          <StyledTypography color={colorMap[props.value.rank]}>
            <span data-kind="rank">
              {props.value.rank === "UNKNOWN" && ` - `}
              {props.value.rank !== "UNKNOWN" && `${props.value.rank} ${t("ランク")}`}
            </span>

            <span data-kind="score">({props.value.score})</span>
          </StyledTypography>
        </Tooltip>
      )}
      {props.value.kind === "NOT_SUBMITTED" && <Typography>{t("未提出")}</Typography>}
      {props.value.kind === "UNDER_EVALUATION" && <Typography>{t("採点中")}</Typography>}
      {props.value.kind === "EXPIRED" && <Typography>{t("期限切れ")}</Typography>}
    </StyledBox>
  );
};

ResultItem.displayName = "ResultItem";

export default ResultItem;
