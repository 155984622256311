import { App, Auth } from "@hireroo/app-store/essential/employee";
import { QuestionSelectFieldForResourceEditor } from "@hireroo/app-store/widget/e/QuestionSelectFieldForResourceEditor";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { Fields } from "@hireroo/validator";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import { RemoteTemplateResourceEditorContainerProps } from "./Container";
import RemoteTemplateResourceEditorMiddleContainer, { RemoteTemplateResourceEditorMiddleContainerProps } from "./MiddleContainer";
import * as PrivateHelper from "./privateHelper";

export type RemoteTemplateResourceEditorFetchContainerProps = {
  templateId: number;
} & RemoteTemplateResourceEditorContainerProps;

const RemoteTemplateResourceEditorFetchContainer: React.FC<RemoteTemplateResourceEditorFetchContainerProps> = props => {
  const appStatus = App.useStatus();
  const { templateId, ...restProps } = props;
  const currentUid = Auth.useCurrentUid();

  const [result, refresh] = Graphql.useGetRemoteInterviewTemplateForRemoteTemplateResourceEditorQuery({
    variables: {
      templateId: templateId,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "network-only",
  });

  React.useEffect(() => {
    if (result.data?.remoteInterviewTemplate) {
      QuestionSelectFieldForResourceEditor.setQuestionByEntitySourceQuestions(result.data.remoteInterviewTemplate.templateQuestions ?? []);
    }
  }, [result.data?.remoteInterviewTemplate]);

  const viewers = React.useMemo((): Fields.AssignField.AssignListItemSchema[] => {
    const { data } = result;
    const defaultViewers = (data?.remoteInterviewTemplate.reviewers ?? []).reduce<Fields.AssignField.AssignListItemSchema[]>((all, viewer) => {
      if (viewer.type === "EMPLOYEE" && viewer.employee) {
        return all.concat({
          value: {
            type: "EMPLOYEE",
            employeeId: viewer.employee.id,
            locked: viewer.employee.id === currentUid,
          },
        });
      } else if (viewer.type === "EMPLOYEE_GROUP" && viewer.group) {
        return all.concat({
          value: {
            type: "EMPLOYEE_GROUP",
            groupId: viewer.group.id,
          },
        });
      }
      return all;
    }, []);
    const hasCurrentUser = defaultViewers.some(viewer => viewer.value?.type === "EMPLOYEE" && viewer.value.employeeId === currentUid);

    return hasCurrentUser
      ? defaultViewers
      : [
          {
            value: {
              type: "EMPLOYEE",
              employeeId: currentUid,
              locked: true,
            },
          },
          ...defaultViewers,
        ];
  }, [currentUid, result]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED") {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: RemoteTemplateResourceEditorMiddleContainerProps = {
    ...restProps,
    defaultValues: result.data?.remoteInterviewTemplate
      ? {
          remote: {
            name: result.data.remoteInterviewTemplate.remoteName,
            candidateName: result.data.remoteInterviewTemplate.candidateName,
            memo: result.data.remoteInterviewTemplate.memo,
            isPublic: result.data.remoteInterviewTemplate.isPublic,
            editorUid: currentUid,
            viewers: viewers,
            tags: result.data.remoteInterviewTemplate.tagNames.map(t => ({ value: { name: t } })),
          },
          entityTracks: PrivateHelper.convertEntityTracksValidatorFromGraphql(result.data.remoteInterviewTemplate.templateQuestions),
          templateTitle: result.data.remoteInterviewTemplate.templateName,
          templateDescription: result.data.remoteInterviewTemplate.description,
          variables: (result.data.remoteInterviewTemplate.variables || []).map(variable => ({
            templateId: variable.templateId,
            name: variable.name,
            description: variable.description || "",
          })),
        }
      : undefined,
  };

  return <RemoteTemplateResourceEditorMiddleContainer {...containerProps} />;
};

RemoteTemplateResourceEditorFetchContainer.displayName = "RemoteTemplateResourceEditorFetchContainer";

export default withErrorBoundary(RemoteTemplateResourceEditorFetchContainer, {});
