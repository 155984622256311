import { getLanguageFromFile, supportReactTypeDefinition, TypeDefs, updateEditorValidationMessage } from "@hireroo/code-editor/helpers/monaco";
import CodeEditor, { type CodeEditorProps } from "@hireroo/code-editor/react/CodeEditor";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import * as React from "react";

import PlaybackToolbar, { PlaybackToolbarProps } from "../../../modules/PlaybackToolbar/PlaybackToolbar";
import ProjectPlaybackDialog, { ProjectPlaybackDialogProps } from "./ProjectPlaybackDialog";

type Item = {
  value: string;
  editor: CodeEditorProps;
  playbackToolbar: Omit<PlaybackToolbarProps, "screenStatus">;
};

export type ProjectPlaybackProps = {
  items: Item[];
  playbackDialog: Pick<ProjectPlaybackDialogProps, "editor" | "playbackToolbar">;
  typeDefs: TypeDefs;
};

const ProjectPlayback: React.FC<ProjectPlaybackProps> = props => {
  const [open, setOpen] = React.useState(false);
  const playbackDialogProps: ProjectPlaybackDialogProps = {
    ...props.playbackDialog,
    open,
    playbackToolbar: {
      ...props.playbackDialog.playbackToolbar,
      screenButton: {
        onClick: () => {
          setOpen(false);
        },
      },
    },
    typeDefs: props.typeDefs,
    onClose: () => {
      setOpen(false);
    },
  };
  return (
    <Box>
      {props.items.map((item, tabIndex: number) => {
        const innerPlaybackDialogProps: PlaybackToolbarProps = {
          ...item.playbackToolbar,
          screenStatus: "DEFAULT",
          screenButton: {
            onClick: () => {
              setOpen(true);
            },
          },
        };
        return (
          <TabPanel key={`item-${tabIndex}`} value={item.value} sx={{ padding: 0 }}>
            <React.Suspense>
              <CodeEditor
                {...item.editor}
                options={{ readOnly: true }}
                height={400}
                key={item.editor.path}
                beforeMount={monaco => {
                  updateEditorValidationMessage(monaco);
                  supportReactTypeDefinition(monaco, props.typeDefs);
                }}
                language={item.editor.path ? getLanguageFromFile(item.editor.path) : ""}
              />
            </React.Suspense>
            <PlaybackToolbar {...innerPlaybackDialogProps} />
          </TabPanel>
        );
      })}
      <ProjectPlaybackDialog {...playbackDialogProps} />
    </Box>
  );
};

ProjectPlayback.displayName = "ProjectPlayback";

export default ProjectPlayback;
