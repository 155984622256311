import { useTranslation } from "@hireroo/i18n";
import OnlineCollaboration from "@hireroo/ui-assets/images/Remote/OnlineCollaboration.svg";
import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import Button, { type ButtonProps } from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import * as React from "react";

// NOTE: title and description must be translated in the parent component if necessary
export type FeatureNotAvailableProps = {
  title: string;
  description: string;
  updatePlanButton?: Pick<ButtonProps, "onClick">;
  checkDetailButton?: Pick<ButtonProps, "onClick">;
};

const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "32px",
  alignItems: "center",
  gap: "32px",
  alignSelf: "stretch",
  borderRadius: "16px",
  backgroundColor: theme.palette["Background/Paper"].p2,
}));

const StyledImgContainer = styled(Box)(() => ({
  display: "flex",
  padding: "0px 16px",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
}));

const StyledDescriptionContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "16px",
  flex: "1 0 0",
}));

const StyledButtonContainer = styled(Box)(() => ({
  display: "flex",
  paddingTop: "16px",
  alignItems: "flex-start",
  gap: "16px",
}));

const StyledButton = styled(Button)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const FeatureNotAvailable: React.FC<FeatureNotAvailableProps> = props => {
  const { t } = useTranslation();
  const { title, description } = props;
  const updatePlanButton: ButtonProps = {
    ...props.updatePlanButton,
    variant: "contained",
    color: "secondary",
    sx: {
      wordBreak: "keep-all",
    },
    children: t("プランを更新"),
  };

  const checkDetailButton: ButtonProps = {
    ...props.checkDetailButton,
    variant: "outlined",
    color: "secondary",
    children: t("詳細を確認"),
  };

  return (
    <StyledContainer>
      <StyledImgContainer>
        <img src={OnlineCollaboration} width={267} height={170} alt={t("待合室の画像")} />
      </StyledImgContainer>
      <StyledDescriptionContainer>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body1">{description}</Typography>
        <StyledButtonContainer>
          {props.updatePlanButton && <StyledButton {...updatePlanButton} />}
          {props.checkDetailButton && <StyledButton {...checkDetailButton} />}
        </StyledButtonContainer>
      </StyledDescriptionContainer>
    </StyledContainer>
  );
};

FeatureNotAvailable.displayName = "FeatureNotAvailable";

export default FeatureNotAvailable;
