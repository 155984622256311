import { useTranslation } from "@hireroo/i18n";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import * as React from "react";

import IconButtonWithTooltip from "../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";
import TestReportEvaluationItem, { TestReportEvaluationItemProps } from "../TestReportEvaluationItem/TestReportEvaluationItem";
import IpAddressDetectionReport, { IpAddressDetectionReportProps } from "./parts/IpAddressDetectionReport/IpAddressDetectionReport";
import ReportTab, { ReportTabProps } from "./parts/ReportTab/ReportTab";
import SimilarCodes, { SimilarCodesProps } from "./parts/SimilarCodes/SimilarCodes";

type Item = Pick<TestReportEvaluationItemProps, "value" | "icon">;

export type ReportCheatDetectionSectionProps = {
  numTabEvents?: Item;
  numPasteEvents?: Item & { onClick?: () => void };
  numAccessEvents?: Item;
  similarityScore?: Item;
  similarCodes?: SimilarCodesProps;
  ipAddressSection: IpAddressDetectionReportProps | null;
  canShowTooltip: boolean;
};

const ReportCheatDetectionSection: React.FC<ReportCheatDetectionSectionProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();

  const pastedEventsItem: TestReportEvaluationItemProps | undefined = props.numPasteEvents && {
    ...props.numPasteEvents,
    title: t("ペースト回数"),
    canShowTooltip: props.canShowTooltip,
    AdditionalContent: props.numPasteEvents.onClick && (
      <IconButtonWithTooltip onClick={props.numPasteEvents.onClick} title={t("提出コードからどこでペーストがあったか確認することができます。")}>
        <PlayCircleOutlinedIcon fontSize="small" color="secondary" />
      </IconButtonWithTooltip>
    ),
  };

  const leftSections: TestReportEvaluationItemProps[] = [
    pastedEventsItem,
    props.numAccessEvents && {
      ...props.numAccessEvents,
      title: t("IPアドレス検知回数"),
      canShowTooltip: props.canShowTooltip,
    },
  ].flatMap(v => (v ? [v] : []));

  const rightSections: TestReportEvaluationItemProps[] = [
    props.numTabEvents && {
      ...props.numTabEvents,
      title: t("離脱回数"),
      canShowTooltip: props.canShowTooltip,
    },
    props.similarityScore && {
      ...props.similarityScore,
      title: t("類似コード"),
      canShowTooltip: props.canShowTooltip,
    },
  ].flatMap(v => (v ? [v] : []));

  const reportTabItems: ReportTabProps["items"] = [
    {
      id: "ip-detection",
      label: {
        name: t("IPアドレス検知"),
      },
      Component: props.ipAddressSection && (
        <Box p={2} bgcolor={theme.palette.grey["800"]}>
          <IpAddressDetectionReport {...props.ipAddressSection} />
        </Box>
      ),
    },
    props.similarCodes && {
      id: "similar-codes",
      label: {
        name: t("類似コード"),
      },
      Component: props.similarCodes && (
        // TODO: @ksrnnb specify color considered light theme
        <Box bgcolor="#272822">
          <SimilarCodes {...props.similarCodes} />
        </Box>
      ),
      disablePadding: true,
    },
  ].flatMap(v => (v ? [v] : []));

  const reportTabProps: ReportTabProps = {
    defaultTab: "ip-detection",
    tabs: {
      variant: "scrollable",
      scrollButtons: "auto",
    },
    items: reportTabItems,
  };

  return (
    <Box>
      <Typography variant="subtitle1" sx={theme => ({ fontWeight: "bold", color: theme.palette.text.secondary })} mb={"16px"}>
        {t("行動検知")}
      </Typography>
      <Grid container columnSpacing={1} mb={"8px"}>
        <Grid item xs={6}>
          <List component="nav" aria-label="answer-detail-1">
            {leftSections.map(section => (
              <TestReportEvaluationItem key={section.title} {...section} />
            ))}
          </List>
        </Grid>
        <Grid item xs={6}>
          <List component="nav" aria-label="answer-detail-1">
            {rightSections.map(section => (
              <TestReportEvaluationItem key={section.title} {...section} />
            ))}
          </List>
        </Grid>
      </Grid>
      <Box>
        <ReportTab key={"cheat-detection-tab"} {...reportTabProps} />
      </Box>
    </Box>
  );
};

ReportCheatDetectionSection.displayName = "ReportCheatDetectionSection";

export default ReportCheatDetectionSection;
