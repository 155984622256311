import { EventEmitter } from "events";
import type firebase from "firebase/compat/app";
import type firebaseAdmin from "firebase-admin";

import { removeDuplicatedDirectories } from "./removeDuplicatedDirectories";

export interface Logger {
  error: (...args: unknown[]) => void;
  debug: (...args: unknown[]) => void;
  info: (...args: unknown[]) => void;
}

export type FirebaseDataSnapshot = firebase.database.DataSnapshot | firebaseAdmin.database.DataSnapshot;
export type FirebaseDatabaseReference = firebase.database.Reference | firebaseAdmin.database.Reference;
export type FirebaseDatabase = firebase.database.Database | firebaseAdmin.database.Database;

type EventMap = {
  changed: [];
};

export type IndexModelConfig = {
  getTimestamp: () => number;
  key: string;
  logger: Logger;
  rdbBasePath: string;
  database: () => FirebaseDatabase;
};

/**
 * ファイル・ディレクトリを管理する場所
 */
export class IndexModel {
  private ref: firebaseAdmin.database.Reference | firebase.database.Reference;

  #emitter = new EventEmitter<EventMap>();
  #indexes: string[] = [];

  constructor(private config: IndexModelConfig) {
    const db = this.config.database();
    const rdbBasePath = this.config.rdbBasePath;
    const delimiter = rdbBasePath.match(/\/$/) ? "" : "/";
    this.ref = db.ref(`${rdbBasePath}${delimiter}${this.config.key}`);
  }

  public start = () => {
    this.ref.on("child_changed", (snapshot: FirebaseDataSnapshot) => {
      const value = snapshot.val() as string[] | null;
      if (!value) {
        return;
      }
      this.#indexes = value;
      this.#emitter.emit("changed");
    });
  };

  public get indexes(): string[] {
    return this.#indexes;
  }

  public getIndexes = async (): Promise<string[] | null> => {
    const snapshot: FirebaseDataSnapshot = await this.ref.once("value");
    const indexes = snapshot.val() as string[] | null;
    if (indexes === null) {
      return null;
    }
    return indexes;
  };

  public updateCurrentIndexes = async (indexes: string[]): Promise<void> => {
    const results = removeDuplicatedDirectories(indexes);
    await this.ref.set(results);
  };

  public clear = async (): Promise<void> => {
    await this.ref.set(null);
  };

  public on: EventEmitter<EventMap>["on"] = (eventName, callback) => {
    return this.#emitter.on(eventName, callback);
  };

  public off: EventEmitter<EventMap>["off"] = (eventName, callback) => {
    return this.#emitter.off(eventName, callback);
  };

  public dispose = () => {
    this.#emitter.removeAllListeners();
  };
}
