import { useTranslation } from "@hireroo/i18n";
import * as React from "react";

import BaseDialog, { BaseDialogProps } from "../../../primitive/BaseDialog/BaseDialog";

export type QuizQuestionEditorDialogProps = Pick<BaseDialogProps, "open" | "title" | "yesButton" | "noButton">;

const QuizQuestionEditorDialog: React.FC<React.PropsWithChildren<QuizQuestionEditorDialogProps>> = props => {
  const { t } = useTranslation();
  const baseDialogProps: BaseDialogProps = {
    ...props,
    disableEnter: true,
    yesButton: {
      ...props.yesButton,
      disabled: false,
      children: t("保存する"),
    },
    noButton: {
      ...props.noButton,
      disabled: false,
      children: t("キャンセル"),
    },
    optionalDialog: {
      sx: {
        "& .MuiDialog-paper": {
          width: "50vw",
        },
      },
    },
  };
  return <BaseDialog {...baseDialogProps}>{props.children}</BaseDialog>;
};

QuizQuestionEditorDialog.displayName = "QuizQuestionEditorDialog";

export default QuizQuestionEditorDialog;
