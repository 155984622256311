import { ExamTabTablesForTalent } from "@hireroo/app-store/widget/t/ExamTabTablesForTalent";
import { updateQueryParamsByObject } from "@hireroo/router/api";

export const startSubscribeOnChangeTest = () => {
  return ExamTabTablesForTalent.subscribeChangeSelectedAssessmentId(assessmentId => {
    updateQueryParamsByObject({
      [ExamTabTablesForTalent.QueryKeys.ASSESSMENT_ID]: [assessmentId],
    });
  });
};
