import { useTheme } from "@mui/material/styles";
import * as React from "react";
import { CartesianGrid, Dot, DotProps, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const FONT_SIZE = {
  TICK: 12,
  AXIS: 10,
  LEGEND: 12,
  LINE: 12,
};

const CustomizedXAxisTick = (props: { x: number; y: number; payload: { value: string } }) => {
  const { payload, x, y } = props;
  const splitted = payload.value.split(" - ");
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} fill="#666" fontSize={11}>
        <tspan textAnchor="middle" x="0">
          {splitted.at(0)} -
        </tspan>
        <tspan textAnchor="middle" x="0" dy="20">
          {splitted.at(1)}
        </tspan>
      </text>
    </g>
  );
};

const CustomizedDot = (props: DotProps & { payload: Data }) => {
  const theme = useTheme();
  const strokeColor = props.payload.isActive ? theme.palette.common.white : theme.palette.secondary.main;
  return (
    <Dot
      strokeWidth={2}
      stroke={strokeColor}
      cx={props.cx}
      cy={props.cy}
      r={props.payload.isActive ? 6 : 4}
      fill={props.payload.isActive ? theme.palette.primary.main : theme.palette.primary.contrastText}
      className={props.className}
    />
  );
};

type Data = {
  /**
   * expect 'yyyy/MM/DD - yyyy/MM/DD' as value
   */
  xValue: string;
  yValue?: number;
  isActive?: boolean;
  id: string;
};

export type ScoreTransitionGraphProps = {
  data: Data[];
  yLabel: string;
  onClickY?: (payload: Data) => void;
};

const ScoreTransitionGraph: React.FC<ScoreTransitionGraphProps> = props => {
  const theme = useTheme();
  const handleClick = (value: React.MouseEvent<SVGCircleElement> & { payload?: Data }) => {
    if (value.payload) {
      props.onClickY?.(value.payload);
    }
  };
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={438}
        height={138}
        data={props.data}
        margin={{
          top: 0,
          right: 40,
          bottom: 20,
          left: -20,
        }}
      >
        <CartesianGrid strokeDasharray="2 2" vertical={false} stroke={theme.palette.divider} />
        <XAxis dataKey="xValue" fontSize={FONT_SIZE.AXIS} interval={0} tick={props => <CustomizedXAxisTick {...props} />} />
        <YAxis type="number" dataKey="yValue" fontSize={FONT_SIZE.AXIS} ticks={[0, 25, 50, 75, 100]} axisLine={false} />
        <Legend align="right" verticalAlign="top" fontSize={"10px"} wrapperStyle={{ fontSize: FONT_SIZE.LEGEND }} />
        <Tooltip
          itemStyle={{
            color: theme.palette.text.primary,
          }}
          contentStyle={{
            borderColor: theme.palette.background.paper,
            backgroundColor: theme.palette.background.paper,
            minWidth: "5vw",
          }}
        />
        <Line
          dataKey="yValue"
          name={props.yLabel}
          fontSize={FONT_SIZE.LINE}
          isAnimationActive={false}
          stroke={theme.palette.secondary.main}
          activeDot={{ cursor: "pointer", onClick: (_, e) => handleClick(e) }}
          dot={props => <CustomizedDot {...props} />}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

ScoreTransitionGraph.displayName = "ScoreTransitionGraph";

export default ScoreTransitionGraph;
