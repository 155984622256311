import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Box, { BoxProps } from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import * as React from "react";

import Tabs, { TabsProps } from "../../primitive/Tabs/Tabs";
import TabWithTooltip, { TabWithTooltipProps } from "./parts/TabWithToolTip/TabWithTooltip";

export type TabItem = {
  /**
   * A value to identify the tab.
   * Do not use variable values such as i18n values.
   */
  id: string;
  Component: React.ReactNode;
  label: Omit<TabWithTooltipProps, "value">;
};

type Variant = "STANDARD" | "OUTLINED";

export type RestrictableTabProps = {
  wrapper?: BoxProps;
  defaultTab?: string;
  tabs?: Omit<TabsProps, "value">;
  items: TabItem[];
  tabBox?: BoxProps;
  tabPanelRoot?: Pick<BoxProps, "sx">;
  tabPanelBox?: BoxProps;
  variant: Variant;
  RightSideAdditional?: React.ReactNode;
};

const RestrictableTab: React.FC<RestrictableTabProps> = props => {
  const defaultTab = props.defaultTab || (props.items.length ? props.items[0].id : "");
  const theme = useTheme();
  const [currentName, updateCurrentSelectedName] = React.useState(defaultTab);
  React.useEffect(() => {
    if (defaultTab) {
      updateCurrentSelectedName(defaultTab);
    }
  }, [defaultTab]);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    updateCurrentSelectedName(newValue);
    props.tabs && props.tabs.onChange?.(event, newValue);
  };

  const TabPanels = props.items.map(item => {
    return (
      <TabPanel key={item.id} value={item.id} sx={{ p: 0, ...props.tabPanelRoot?.sx }}>
        {item.Component}
      </TabPanel>
    );
  });

  const bgColorMap = {
    OUTLINED: "default",
    STANDARD: theme.palette["Secondary/Shades"].p12,
  } satisfies Record<Variant, string>;

  const tabPanelBox: BoxProps = {
    ...props.tabPanelBox,
    sx: {
      bgcolor: bgColorMap[props.variant],
      ...props.tabPanelBox?.sx,
    },
  };

  const outlinedTabsSx: TabsProps["sx"] = {
    "&.MuiTabs-root": {
      minHeight: "43px",
      height: "43px",
    },
    "& .MuiTab-root": {
      minHeight: "43px",
      height: "43px",
    },
    "& .MuiTab-root.Mui-selected": {
      color: theme.palette.secondary.main,
      backgroundColor: "inherit",
      fontWeight: "700px",
    },
    "& .MuiButtonBase-root": {
      "&.Mui-selected": {
        fontWeight: 700,
      },
    },
    backgroundColor: "inherit",
  };

  const tabs: TabsProps = {
    ...props.tabs,
    sx: props.variant === "OUTLINED" ? outlinedTabsSx : undefined,
  };

  const outlinedTabBoxSx: BoxProps["sx"] = {
    backgroundColor: "default",
    borderBottom: "1px solid",
    borderColor: theme.palette.divider,
  };

  const tabBox: BoxProps = {
    ...props.tabBox,
    width: "100%",
    display: "inline-flex",
    justifyContent: "space-between",
    sx: {
      ...(props.variant === "OUTLINED"
        ? outlinedTabBoxSx
        : {
            backgroundColor: bgColorMap[props.variant],
          }),
      ...props.tabBox?.sx,
    },
  };

  return (
    <TabContext value={currentName}>
      <Box {...props.wrapper}>
        <Box {...tabBox}>
          <Tabs {...tabs} value={currentName} onChange={handleChange}>
            {props.items.map(item => {
              const tab: TabWithTooltipProps = {
                ...item.label,
                value: item.id,
                onClick: event => {
                  item.label?.onClick?.(event);
                  updateCurrentSelectedName(item.id);
                },
              };
              return <TabWithTooltip key={item.id} {...tab} />;
            })}
          </Tabs>
          {props.RightSideAdditional}
        </Box>
      </Box>
      <Box pt={2} {...tabPanelBox}>
        {TabPanels}
      </Box>
    </TabContext>
  );
};

RestrictableTab.displayName = "RestrictableTab";

export default RestrictableTab;
