import Markdown from "@hireroo/markdown-v2/react";
import Box from "@mui/material/Box";
import * as React from "react";

import PlaybackContent, { PlaybackContentProps } from "../PlaybackContent/PlaybackContent";

export type QuizPlaybackProps = {
  description: string;
  playbackContent: PlaybackContentProps;
};

const QuizPlayback: React.FC<QuizPlaybackProps> = props => {
  return (
    <Box>
      <React.Suspense>
        <Markdown children={props.description} size="middle" />
      </React.Suspense>
      <Box display="flex" flexDirection="column">
        <PlaybackContent {...props.playbackContent} />
      </Box>
    </Box>
  );
};

QuizPlayback.displayName = "QuizPlayback";

export default QuizPlayback;
