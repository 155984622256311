import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateAssessmentDetailForEmployeePropsArgs, useGenerateProps } from "./useGenerateProps";

export type AssessmentDetailForEmployeeContainerProps = GenerateAssessmentDetailForEmployeePropsArgs;

const AssessmentDetailForEmployeeContainer: React.FC<AssessmentDetailForEmployeeContainerProps> = props => {
  const assessmentDetailForEmployeeProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.AssessmentDetailForEmployee {...assessmentDetailForEmployeeProps} />
    </ErrorBoundary>
  );
};

AssessmentDetailForEmployeeContainer.displayName = "AssessmentDetailForEmployeeContainer";

export default withErrorBoundary(AssessmentDetailForEmployeeContainer, {});
