import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type SecuritySettingsContainerProps = {};

const SecuritySettingsContainer: React.FC<SecuritySettingsContainerProps> = () => {
  const companySettings = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Pages.CompanySettings {...companySettings} />
    </ErrorBoundary>
  );
};

SecuritySettingsContainer.displayName = "SecuritySettingsContainer";

export default withErrorBoundary(SecuritySettingsContainer, {});
