import { useTranslation } from "@hireroo/i18n";
import { GeneralSettingForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import InputControlTextField, { InputControlTextFieldProps } from "../../primitive/InputControl/InputControlTextField/InputControlTextField";

const StyledTypography = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 14,
}));

export type CompanyProfileSettingsProps = {
  onSubmit: SubmitHandler<GeneralSettingForm.GeneralSettingsFormSchema>;
  loading: boolean;
  defaultValues: GeneralSettingForm.GeneralSettingsFormSchema;
  saveButton?: {
    disabled: boolean;
    title?: string;
  };
};

const CompanyProfileSettings: React.FC<CompanyProfileSettingsProps> = props => {
  const { t } = useTranslation();
  const validateSchema = GeneralSettingForm.useGeneralSettingsFormSchema();
  const methods = useForm({
    resolver: zodResolver(validateSchema),
    shouldUnregister: true,
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: props.defaultValues,
  });

  React.useEffect(() => {
    methods.reset(props.defaultValues);
  }, [methods, props.defaultValues]);

  const commonTextFieldProps: InputControlTextFieldProps = {
    fullWidth: false,
    control: methods.control,
    sx: {
      width: "480px",
    },
  };

  const saveButton: ButtonProps = {
    variant: "contained",
    color: "secondary",
    disabled: !methods.formState.isDirty || props.loading || props.saveButton?.disabled,
    children: t("変更内容を保存"),
    onClick: () => {
      methods.handleSubmit(fields => {
        props.onSubmit(fields);
      })();
    },
  };

  return (
    <FormProvider {...methods}>
      <Stack direction="column" spacing={3}>
        <Stack direction="column" spacing={1.5}>
          <StyledTypography>{t("会社名")}</StyledTypography>
          <InputControlTextField name="companyName" {...commonTextFieldProps} />
        </Stack>
        <Stack direction="column" spacing={1.5}>
          <StyledTypography>{t("ウェブサイト")}</StyledTypography>
          <InputControlTextField name="websiteUrl" {...commonTextFieldProps} />
        </Stack>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Tooltip title={props.saveButton?.title ?? ""}>
            <span>
              <Button {...saveButton} />
            </span>
          </Tooltip>
        </Box>
      </Stack>
    </FormProvider>
  );
};

CompanyProfileSettings.displayName = "CompanyProfileSettings";

export default CompanyProfileSettings;
