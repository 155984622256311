import { useTranslation } from "@hireroo/i18n";
import Markdown from "@hireroo/markdown/react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { useAlgorithmTestCasesFormContext } from "../../../../../Context";
import AccordionWithLoading, { AccordionWithLoadingProps } from "../../../AccordionWithLoading/AccordionWithLoading";
import CorrectnessTestCaseDialog, { CorrectnessTestCaseDialogProps } from "../CorrectnessTestCaseDialog/CorrectnessTestCaseDialog";
import ReadonlyAlgorithmFields from "../ReadonlyAlgorithmFields/ReadonlyAlgorithmFields";

const StyledTypography = styled(Typography)(() => ({
  fontSize: "14px",
}));

const StyledPreviewArea = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: "8px",
  border: "1px solid grey",
  overflow: "auto",
  padding: theme.spacing(1),
  maxHeight: "350px",
}));

export type ReadonlyTestCaseProps = {
  accordion: Omit<AccordionWithLoadingProps, "Content" | "editButton">;
  data: {
    title: string;
    description: string;
    inputs: string[];
    outputs: string[];
    isHidden: boolean;
  };
  correctnessDialog: Omit<CorrectnessTestCaseDialogProps, "dialog" | "kind">;
};

const ReadonlyTestCase: React.FC<ReadonlyTestCaseProps> = props => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState<boolean>(false);
  const { contextProps } = useAlgorithmTestCasesFormContext();

  const correctnessDialog: CorrectnessTestCaseDialogProps = {
    ...props.correctnessDialog,
    kind: "EDIT",
    dialog: {
      open: open,
      noButton: {
        onClick: () => {
          setOpen(false);
        },
      },
    },
  };
  const accordion: AccordionWithLoadingProps = {
    ...props.accordion,
    editButton: {
      onClick: () => {
        setOpen(true);
      },
    },
    Content: (
      <Stack direction="column" width="100%" spacing={2}>
        {props.data.isHidden && (
          <Stack direction="row">
            <StyledTypography>{t("テスト中は表示しない")}</StyledTypography>
          </Stack>
        )}
        <Stack direction="column" width="100%">
          <Typography fontSize={12}>{t("詳細")}</Typography>
          <StyledPreviewArea width="100%">
            <React.Suspense>
              <Markdown children={props.data.description} size="middle" />
            </React.Suspense>
          </StyledPreviewArea>
        </Stack>
        <Stack>
          {contextProps.signatureProps.variant === "ALGORITHM" && (
            <ReadonlyAlgorithmFields
              signature={contextProps.signatureProps.signature}
              inputs={props.data.inputs}
              outputs={props.data.outputs}
            />
          )}
        </Stack>
      </Stack>
    ),
  };
  return (
    <>
      <AccordionWithLoading {...accordion} />
      <CorrectnessTestCaseDialog {...correctnessDialog} />
    </>
  );
};

ReadonlyTestCase.displayName = "ReadonlyTestCase";

export default ReadonlyTestCase;
