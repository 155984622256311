import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import AlgorithmResourceProvider from "../questions_challenge_create/Provider";
import Container from "./Container";

export type QuizResourceEditorMiddleContainerProps = {};

const AlgorithmResourceEditorMiddleContainer: React.FC<QuizResourceEditorMiddleContainerProps> = _props => {
  return (
    <ErrorBoundary>
      <AlgorithmResourceProvider type={"UPDATE"}>
        <Container />
      </AlgorithmResourceProvider>
    </ErrorBoundary>
  );
};

AlgorithmResourceEditorMiddleContainer.displayName = "AlgorithmResourceEditorMiddleContainer";

export default withErrorBoundary(AlgorithmResourceEditorMiddleContainer, {});
