import { useTranslation } from "@hireroo/i18n";
import { SecuritySettingsForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import SwitchControl from "../../primitive/InputControl/SwitchControl/SwitchControl";
import Link, { LinkProps } from "../../primitive/Link/Link";
import SsoEnabledFields, { SsoEnabledFieldsProps } from "./parts/SsoEnabledFields/SsoEnabledFields";

const StyledTypography = styled(Typography)({
  fontWeight: 700,
  fontSize: 14,
});

const StyledBox = styled(Box)(({ theme }) => ({
  borderTop: "1px solid",
  borderBottom: "1px solid",
  borderColor: theme.palette.divider,
}));

export type SecuritySettingsProps = {
  defaultValues: SecuritySettingsForm.SecuritySettingsFormSchema;
  onSubmit: SubmitHandler<SecuritySettingsForm.SecuritySettingsFormSchema>;
  ssoHelpCenterLink: Pick<LinkProps, "onClick">;
  loading: boolean;
  ssoEnabledFields: SsoEnabledFieldsProps;
  saveButton?: {
    disabled: boolean;
    title?: string;
  };
};

const SecuritySettings: React.FC<SecuritySettingsProps> = props => {
  const { t } = useTranslation();
  const validateSchema = SecuritySettingsForm.useSecuritySettingsFormSchema();
  const { defaultValues } = props;
  const methods = useForm({
    resolver: zodResolver(validateSchema),
    shouldUnregister: true,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues,
  });
  const ssoEnabled = methods.watch("ssoEnabled");
  React.useEffect(() => {
    methods.reset(defaultValues);
  }, [methods, defaultValues]);

  React.useEffect(() => {
    if (!ssoEnabled) {
      methods.setValue("ssoStrictMode", defaultValues.ssoStrictMode);
      methods.setValue("idpSsoUrl", defaultValues.idpSsoUrl);
      methods.setValue("idpEntityId", defaultValues.idpEntityId);
      methods.setValue("idpCertificate", defaultValues.idpCertificate);
      methods.setValue("emailDomain", defaultValues.emailDomain);
    }
  }, [methods, ssoEnabled, defaultValues]);

  const saveButton: ButtonProps = {
    variant: "contained",
    color: "secondary",
    disabled: !methods.formState.isDirty || props.loading || props.saveButton?.disabled,
    children: t("変更内容を保存"),
    onClick: () => {
      methods.handleSubmit(fields => {
        props.onSubmit(fields);
      })();
    },
  };

  return (
    <FormProvider {...methods}>
      <Stack spacing={3}>
        <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <StyledTypography>{t("SSO（シングルサインオン）")}</StyledTypography>
          <Link onClick={props.ssoHelpCenterLink.onClick} color="secondary" target="_blank" underline="hover">
            <Box display={"flex"} alignItems={"center"}>
              <InfoOutlinedIcon fontSize="small" sx={{ width: 16, height: 16 }} color="secondary" />
              <Typography color="secondary" sx={{ ml: 1 }} fontSize={14}>
                {t("SSOの設定方法")}
              </Typography>
            </Box>
          </Link>
        </Box>

        <StyledBox display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" p={1.5}>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Typography fontSize={14} mb={1}>
              {t("SSOの有効化")}
            </Typography>
            <Typography color="textSecondary" fontSize={12}>
              {t("SSOが有効化されると、登録されたメールアドレスを持つユーザーはサインイン時にSAMLを使用できます。")}
            </Typography>
          </Box>
          <Box>
            <SwitchControl name="ssoEnabled" size="small" color="secondary" control={methods.control} />
          </Box>
        </StyledBox>
        {ssoEnabled && <SsoEnabledFields {...props.ssoEnabledFields} />}
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Tooltip title={props.saveButton?.title ?? ""}>
            <span>
              <Button {...saveButton} />
            </span>
          </Tooltip>
        </Box>
      </Stack>
    </FormProvider>
  );
};

SecuritySettings.displayName = "SecuritySettings";

export default SecuritySettings;
