import { Credential } from "@hireroo/app-store/essential/shared";
import { signOut } from "@hireroo/firebase";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateHelpCenterUrl } from "@hireroo/router/api";
import routes from "@hireroo/router/config";
import { useHelpCenterNavigate, useNavigate } from "@hireroo/router/hooks";

export const useGenerateProps = (): Widget.ErrorPanelProps => {
  const userType = Credential.useUserType();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const lang = useLanguageCode();
  const helpCenterNavigate = useHelpCenterNavigate(lang);

  if (userType === Graphql.UserType.Candidate) {
    return {
      name: t("ページが見つかりませんでした。"),
      message: t("現在のアカウントは候補者としてサインインされているため、面接官のページを閲覧する場合は一度サインアウトしてください。"),
      links: [
        {
          children: t("サインアウトする"),
          onClick: async () => {
            await signOut();
          },
        },
      ],
    };
  }

  if (userType === Graphql.UserType.Employee) {
    return {
      name: t("ページが見つかりませんでした。"),
      message: t(
        "現在のアカウントは面接官としてサインインされています。しばらく経っても画面が切り替わらない場合はお手数ですが以下のホーム戻るをクリックしてください。",
      ),
      links: [
        {
          href: generateHelpCenterUrl(lang, "ROOT"),
          children: t("ヘルプセンター"),
          onClick: () => {
            helpCenterNavigate("ROOT", { _blank: true });
          },
        },
        {
          href: routes["/e/home"],
          children: t("ホームに戻る"),
          onClick: () => {
            navigate(routes["/e/home"]);
          },
        },
        {
          children: t("サインアウトする"),
          onClick: async () => {
            await signOut();
          },
        },
      ].flatMap(v => (v ? [v] : [])),
    };
  }

  if (userType === Graphql.UserType.Talent) {
    return {
      name: t("ページが見つかりませんでした。"),
      message: t(
        "現在のアカウントはタレントとしてサインインされています。しばらく経っても画面が切り替わらない場合はお手数ですが以下のホーム戻るをクリックしてください。",
      ),
      links: [
        {
          href: generateHelpCenterUrl(lang, "ROOT"),
          children: t("ヘルプセンター"),
          onClick: () => {
            helpCenterNavigate("ROOT", { _blank: true });
          },
        },
        {
          href: routes["/t/assessments"],
          children: t("ホームに戻る"),
          onClick: () => {
            navigate(routes["/t/assessments"]);
          },
        },
        {
          children: t("サインアウトする"),
          onClick: async () => {
            await signOut();
          },
        },
      ].flatMap(v => (v ? [v] : [])),
    };
  }

  if (userType === Graphql.UserType.Unknown) {
    return {
      name: t("会社に所属していません。"),
      message: [
        t("会社に所属することでこれより先の画面に進めます。会社に所属するには、管理者に招待リンクを発行してもらう必要があります。"),
        t("テストを受験する場合はお手数ですが、一度サインアウトして企業から受け取ったリンクに直接アクセスしてください。"),
      ].join(""),
      links: [
        {
          children: t("サインアウトする"),
          onClick: async () => {
            await signOut();
          },
        },
      ],
    };
  }

  if (userType === null) {
    return {
      name: t("エラーが発生しました。"),
      message: [
        t("ユーザータイプの取得に失敗したため、アプリケーションを正常に実行することができませんでした。"),
        t("お手数ですがヘルプセンターより運営にお問い合わせください。"),
      ].join(""),
      links: [
        {
          children: t("サインアウトする"),
          onClick: async () => {
            await signOut();
          },
        },
      ],
    };
  }

  return {
    name: t("ページが見つかりませんでした。"),
    message: t("サービスを利用するにはサインインしてください"),
    links: [
      {
        children: t("サインイン"),
        onClick: async () => {
          navigate(routes["/signin"]);
        },
      },
    ],
  };
};
