import { RemoteInterviewTimelimit } from "@hireroo/app-store/widget/shared/RemoteInterviewTimelimit";
import { remainingTimeFormat } from "@hireroo/formatter/time";
import { useLanguageCode } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";

import { useCountUpTimer } from "./useCountUpTimer";

export type GenerateRemoteInterviewTimerPropsArgs = {};

export const useGenerateProps = (_args: GenerateRemoteInterviewTimerPropsArgs): Widget.RemoteInterviewTimerProps => {
  const startAtSeconds = RemoteInterviewTimelimit.useStartAtSeconds();
  /**
   * Here is the cause of the re-rendering.
   */
  const timer = useCountUpTimer({
    fromStartAtSeconds: startAtSeconds,
  });
  const languageCode = useLanguageCode();
  const remainTimeDisplayText = remainingTimeFormat({
    days: timer.days,
    hours: timer.hours,
    minutes: timer.minutes,
    seconds: timer.seconds,
    language: languageCode,
  });
  return {
    displayText: remainTimeDisplayText,
  };
};
