import { LATEST_VERSION } from "@hireroo/app-definition/question";
import { App } from "@hireroo/app-store/essential/employee";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

import ProjectQuestionDetailContainer, { ProjectQuestionDetailContainerProps } from "./Container";

export type ProjectQuestionDetailFetchContainerProps = {};

const ProjectQuestionDetailFetchContainer: React.FC<ProjectQuestionDetailFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const { id } = useParams<{ id: string }>();
  const [query] = useSearchParams();
  const questionVersion = query.get("version") ?? LATEST_VERSION;
  const [result, refresh] = Graphql.useProjectQuestionDetailQuery({
    variables: {
      questionId: Number(id),
      questionVersion: questionVersion,
      withAnswers: true,
    },
    pause: appStatus !== "INITIALIZED",
  });
  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !result.data?.projectQuestion) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: ProjectQuestionDetailContainerProps = {
    questionDetail: result.data.projectQuestion,
  };

  return <ProjectQuestionDetailContainer {...containerProps} />;
};

ProjectQuestionDetailFetchContainer.displayName = "ProjectQuestionDetailFetchContainer";

export default withErrorBoundary(ProjectQuestionDetailFetchContainer, {});
