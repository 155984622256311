import { useLanguageCode } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";

import { ComponentType, ElementDataKeyMap, ElementMasterData, NetworkElement } from "../../../features";

const TextSvg = styled("text")(() => ({
  WebkitTouchCallout: "none",
  WebkitUserSelect: "none",
  msUserSelect: "none",
  userSelect: "none",
  dominantBaseline: "middle",
  textAnchor: "middle",
  pointerEvents: "none",
}));

const TextLabelSvg = styled("text")(() => ({
  WebkitTouchCallout: "none",
  WebkitUserSelect: "none",
  msUserSelect: "none",
  userSelect: "none",
  dominantBaseline: "middle",
  textAnchor: "middle",
  pointerEvents: "none",
  opacity: 0.1,
}));

const colorMap: Record<string, string> = {
  vpc: "#e3f2fd", // blue[50]
  subnet: "#fffde7", // yellow[50]
  availabilityZone: "#ffebee", // red[50]
  region: "#e8f5e9", // green[50]
};

const lightGreen = "#76ff03"; // lightGreen A400

export type NetworkProps = {
  network: NetworkElement;
  componentType: ComponentType;
  simpleView?: boolean;
  dashedLine?: boolean;
  isMatch?: boolean;
};

const Network: React.FC<NetworkProps> = React.memo((props: NetworkProps) => {
  const lang = useLanguageCode();
  const theme = useTheme();
  const {
    geometry: { minX, minY, maxX, maxY },
  } = props.network;
  const ref = useRef<SVGTextElement>(null);
  const [labelBoxWidth, setLabelBoxWidth] = useState<number>(200);

  // Stretch a label box width according to the text length
  useEffect(() => {
    if (ref.current) {
      const bBox = ref.current.getBBox?.();
      const bboxWidth = bBox?.width ?? 0;
      const width = bboxWidth + (20 - (bboxWidth % 20));
      setLabelBoxWidth(Math.min(Math.max(200, width + 100), maxX - minX));
    }
  }, [maxX, minX, props.network.settings.name]);

  return (
    <g>
      {props.network.label && (
        <rect width={maxX - minX} height={maxY - minY} x={minX} y={minY} rx={5} ry={5} fill={colorMap[props.network.label]} fillOpacity={0.9} />
      )}
      <polygon
        points={`${minX},${minY} ${maxX},${minY} ${maxX},${maxY} ${minX},${maxY}`}
        stroke={props.isMatch ? lightGreen : theme.palette.common.black}
        strokeWidth={props.isMatch ? 10 : 1}
        fill={"none"}
        strokeDasharray={props.dashedLine ? "8 8" : "0 0"}
      />
      <polygon
        points={`${minX},${minY} ${maxX},${minY} ${maxX},${maxY} ${minX},${maxY}`}
        stroke={theme.palette.common.black}
        strokeWidth={40}
        strokeOpacity={0}
        fill={"none"}
      />
      {props.network.settings.name && !props.simpleView && (
        <g>
          <rect
            x={minX}
            y={minY}
            width={labelBoxWidth}
            height={30}
            stroke={theme.palette.common.black}
            strokeWidth={1}
            fill={theme.palette.common.white}
          />
          <TextSvg x={minX + labelBoxWidth / 2} y={minY + 15} fontSize={24} ref={ref}>
            {props.network.settings.name}
          </TextSvg>
          <TextLabelSvg x={(minX + maxX) / 2} y={(minY + maxY) / 2} fontSize={100}>
            {resolveLanguage(ElementMasterData[ElementDataKeyMap[props.componentType][props.network.label]], lang, "title")}
          </TextLabelSvg>
        </g>
      )}
    </g>
  );
});

Network.displayName = "Network";

export default Network;
