import { ScreeningSearchForm as ScreeningSearchFormValidator } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import SearchForm, { SearchFormProps } from "../../modules/SearchForm/SearchForm";
import * as PrivateHelper from "./privateHelper";

export type ScreeningSearchAreaProps = {
  disabled: boolean;
  tagsField: Pick<PrivateHelper.TagFieldProps, "selectableTags">;
  defaultValues: ScreeningSearchFormValidator.ScreeningSearchQuerySchema;
  onChange: SubmitHandler<ScreeningSearchFormValidator.ScreeningSearchQuerySchema>;
};

const ScreeningSearchArea: React.FC<ScreeningSearchAreaProps> = props => {
  const { onChange } = props;
  const validateSchema = ScreeningSearchFormValidator.useScreeningSearchQuery();
  const methods = useForm<ScreeningSearchFormValidator.ScreeningSearchQuerySchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: props.defaultValues,
  });
  const textFilterField = PrivateHelper.useTextFilterField();
  const tagsField = PrivateHelper.useTagsField(props.tagsField);
  const activeStatusField = PrivateHelper.useActiveStatusField();

  React.useEffect(() => {
    const subscriber = methods.watch(() => {
      const fields = methods.getValues();
      onChange(fields);
    });
    return () => {
      subscriber.unsubscribe();
    };
  }, [methods, onChange]);
  const searchFormProps: SearchFormProps = {
    kind: "MULTI_LINE",
    disabled: props.disabled,
    textFilter: textFilterField,
    fields: [activeStatusField, tagsField],
    onReset: () => {
      const resetValues: ScreeningSearchFormValidator.ScreeningSearchQuerySchema = {
        activeStatus: null,
        tags: [],
        textFilter: "",
      };
      methods.reset(resetValues);
    },
  };
  return (
    <FormProvider {...methods}>
      <SearchForm {...searchFormProps} />
    </FormProvider>
  );
};

ScreeningSearchArea.displayName = "ScreeningSearchArea";

export default ScreeningSearchArea;
