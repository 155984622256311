import Link from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import Linkify from "linkify-react";
import type { Opts } from "linkifyjs";
import { IntermediateRepresentation } from "linkifyjs";
import * as React from "react";

const StyledLink = styled(Link)(() => ({
  fontSize: "inherit",
}));

const options: Opts = {
  target: "_blank",
  rel: "noreferrer",
  render: {
    url: ({ attributes, content }: IntermediateRepresentation) => {
      return (
        <StyledLink {...attributes} color="secondary">
          {content}
        </StyledLink>
      );
    },
  },
};

export type TextLinkifyProps = {
  children: string | undefined;
};

const TextLinkify: React.FC<TextLinkifyProps> = props => {
  return <Linkify options={options}>{props.children}</Linkify>;
};

TextLinkify.displayName = "TextLinkify";

export default TextLinkify;
