import { state } from "./State";
import type * as Types from "./types";

export const updateScreening = (newValue: Types.Screening): void => {
  state.screening = newValue;
};
export const updateScreeningInvitees = (newValue: Exclude<Types.Screening["invitees"], undefined>): void => {
  if (!state.screening) return;
  const inviteesSet = new Set(state.screening.invitees);
  newValue.forEach(invitee => inviteesSet.add(invitee));
  state.screening = {
    ...state.screening,
    invitees: Array.from(inviteesSet),
  };
};

export const clear = () => {
  state.screening = null;
  state.dialogStatus = "CLOSE";
  state.tags = [];
};

export const updateDialogStatus = (newStatus: Types.DialogStatus) => {
  state.dialogStatus = newStatus;
};

export const updateTags = (newTags: string[]) => {
  state.tags = newTags;
};
