import { proxyMap } from "valtio/utils";

import { state } from "./State";
import type * as Types from "./types";

export const updateAssessmentsResponse = (res: Types.AssessmentsResponse, defaultAssessmentId: Types.AssessmentId | null): void => {
  state.assessmentsResponse = res;
  if (defaultAssessmentId && res.assessments.findIndex(assessment => assessment.assessmentId === defaultAssessmentId) >= 0) {
    state.selectedAssessmentId = defaultAssessmentId;
  } else {
    const firstAssessmentId = res.assessments.at(0)?.assessmentId;
    if (firstAssessmentId) {
      state.selectedAssessmentId = firstAssessmentId;
    }
  }
};

export const clear = (): void => {
  state.assessmentsResponse = null;
  state.selectedAssessmentId = null;
  state.pager = {
    page: 0,
    size: 20,
    offset: 0,
    isDescending: true,
  };
  state.tailExamStatusMap = proxyMap();
  state.tailExamIdMap = proxyMap();
};

export const updateSelectedAssessmentId = (selectedAssessmentId: Types.AssessmentId) => {
  state.selectedAssessmentId = selectedAssessmentId;
};

export const updateSortField = (isDescending: boolean) => {
  state.pager = {
    ...state.pager,
    isDescending: isDescending,
    page: 0,
    offset: 0,
  };
};

export const updatePage = (page: number) => {
  state.pager = {
    ...state.pager,
    page,
    offset: page * state.pager.size,
  };
};

export const updatePageSize = (size: number) => {
  state.pager = {
    ...state.pager,
    page: 0,
    offset: 0,
    size: size,
  };
};

export const updateTailExamStatus = (examId: string, status: Types.TryStatus) => {
  state.tailExamStatusMap.set(examId, status);
};

export const deleteTailExamStatus = (examId: string) => {
  state.tailExamStatusMap.delete(examId);
};

export const updateTailExamMap = (examId: string, tailExamId: string) => {
  state.tailExamIdMap.set(examId, tailExamId);
};

export const deleteTailExamMap = (examId: string) => {
  state.tailExamIdMap.delete(examId);
};
