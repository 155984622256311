import { DevicePermissionName } from "./definition";
import { isPermissionName } from "./privateHelper";

export const getPermissionState = async (name: DevicePermissionName): Promise<PermissionState> => {
  if (!isPermissionName(name)) {
    throw new Error(`Invalid permission name: ${name}`);
  }

  if (navigator.permissions) {
    try {
      const result = await navigator.permissions.query({ name: name });
      return result.state;
    } catch {
      // Firefox doesn't support the "camera" and "microphone" permission
      // If the query function throws an error, we'll assume that the permission has granted.
      // https://developer.mozilla.org/en-US/docs/Web/API/Permissions_API#browser_compatibility
      return "granted";
    }
  } else {
    return "granted";
  }
};

export const addPermissionChangeCallback = async (name: DevicePermissionName, onChange: () => void) => {
  if (!isPermissionName(name)) {
    throw new Error(`Invalid permission name: ${name}`);
  }
  try {
    const result = await navigator.permissions.query({ name: name });
    result.onchange = onChange;
  } catch {
    // Firefox doesn't support the "camera" and "microphone" permission
    // https://developer.mozilla.org/en-US/docs/Web/API/Permissions_API#browser_compatibility
    console.warn(`Unable to add permission change callback for ${name}`);
  }
};
