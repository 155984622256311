import { useTranslation } from "@hireroo/i18n";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";

const StyledStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette["Gray/Shades"].p8,
  borderRadius: "4px",
  paddingX: theme.spacing(1.5),
}));

const StyledListItemText = styled(ListItemText)(() => ({
  margin: 0,
}));

type Item = {
  id: string;
  name: string;
  description: string;
};

export type DeleteMetricsDialogProps = {
  dialog: Pick<DialogWithCloseIconProps, "open" | "onClose" | "yesButton" | "noButton" | "title">;
  description: string;
  items: Item[];
  onSubmit?: () => void;
};

const DeleteMetricsDialog: React.FC<DeleteMetricsDialogProps> = props => {
  const { t } = useTranslation();
  const [checked, setChecked] = React.useState(false);

  const dialog: DialogWithCloseIconProps = {
    ...props.dialog,
    yesButton: {
      children: t("削除"),
      color: "error",
      onClick: () => {
        props.onSubmit?.();
        props.dialog.yesButton?.onClick?.();
      },
      disabled: !checked,
      autoFocus: false,
    },
    yesButtonTooltip: {
      title: t("削除するには同意が必要です。"),
    },
    noButton: {
      ...props.dialog.noButton,
      children: t("キャンセル"),
      variant: "outlined",
      color: "outline-only",
    },
    disableEnter: true,
    optionalDialog: {
      disableAutoFocus: true,
    },
  };
  const handleChange = (_event: React.SyntheticEvent, checked: boolean) => {
    setChecked(checked);
  };
  return (
    <DialogWithCloseIcon {...dialog}>
      <Stack>
        <Typography fontSize="14px">{props.description}</Typography>
        <List sx={{ mb: 2, maxHeight: "600px", overflowY: "auto" }}>
          {props.items.map(item => (
            <ListItem key={item.id} divider>
              <StyledListItemText
                primary={item.name}
                secondary={item.description}
                primaryTypographyProps={{ mb: "4px" }}
                secondaryTypographyProps={{ fontSize: 12 }}
              />
            </ListItem>
          ))}
        </List>
      </Stack>
      <StyledStack>
        <FormControlLabel
          sx={{ ml: 0 }}
          control={<Checkbox size="small" />}
          onChange={handleChange}
          label={<Typography fontSize={14}>{t("内容を確認し、削除した後に元に戻せないことに同意する")}</Typography>}
        />
      </StyledStack>
    </DialogWithCloseIcon>
  );
};

DeleteMetricsDialog.displayName = "DeleteMetricsDialog";

export default DeleteMetricsDialog;
