import { useTranslation } from "@hireroo/i18n";
import EndInterview from "@hireroo/ui-assets/images/Interview/EndInterview.svg";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";

import LiveCodingLayout, { LiveCodingLayoutProps } from "../../layouts/LiveCodingLayout/LiveCodingLayout";

export type Kind = "COMPLETE_CANDIDATE_INTERVIEW";

export type CompleteRemoteInterviewProps = {
  layout: LiveCodingLayoutProps;
  kind: Kind;
};

type TextSet = {
  title: string;
  description: string;
};

const useTextSetMap = (kind: Kind): TextSet => {
  const { t } = useTranslation();
  const textMap: Record<Kind, TextSet> = {
    COMPLETE_CANDIDATE_INTERVIEW: {
      title: t("インタビューお疲れさまでした。"),
      description: t("面接官によりインタビューが終了されました。企業様からのご連絡をお待ち下さい。"),
    },
  };
  return textMap[kind];
};

const CompleteRemoteInterview: React.FC<CompleteRemoteInterviewProps> = props => {
  const liveCodingLayout: LiveCodingLayoutProps = {
    ...props.layout,
  };
  const textSet = useTextSetMap(props.kind);
  return (
    <LiveCodingLayout {...liveCodingLayout}>
      <Box width="100vw" height="100%" bgcolor="background.default" justifyContent="center" alignItems="center" display="flex">
        <Box mx={10} justifyContent="center" alignItems="center" display="flex">
          <img src={EndInterview} width={300} alt="finish-notify" />
          <Box ml={2} />
          <Box display="flex" flexDirection="column" mb={1} mx={2}>
            <Typography variant="h4" gutterBottom>
              {textSet.title}
            </Typography>

            <Typography variant="body1" component="p" gutterBottom sx={{ lineHeight: 1.8 }}>
              {textSet.description}
            </Typography>
          </Box>
        </Box>
      </Box>
    </LiveCodingLayout>
  );
};

CompleteRemoteInterview.displayName = "CompleteRemoteInterview";

export default CompleteRemoteInterview;
