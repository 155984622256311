import { Company } from "@hireroo/app-store/essential/employee";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

type SuggestionItem = Widget.TagFieldProps["suggestions"][0];
const FETCH_LIMIT = 100;

export type GenerateSpotTagFieldPropsArgs = {
  name: string;
  variant: Widget.TagFieldProps["variant"];
};

export const useGenerateProps = (args: GenerateSpotTagFieldPropsArgs): Widget.TagFieldProps => {
  const { t } = useTranslation();
  const companyId = Company.useStrictActiveCompanyId();
  const [result, refresh] = Graphql.useListSpotTagsForSpotTagFieldQuery({
    variables: {
      spotTagsByCompanyId: {
        companyId: companyId,
        size: FETCH_LIMIT,
        // TODO: please change when create pagination.
        cursorSeconds: undefined,
        withCount: false,
      },
    },
    requestPolicy: "cache-and-network",
  });

  const suggestions = React.useMemo((): SuggestionItem[] => {
    return (result.data?.spotTagsByCompanyId?.tags || []).map((tag): SuggestionItem => {
      return {
        valueId: tag.name,
      };
    });
  }, [result.data?.spotTagsByCompanyId?.tags]);

  return {
    name: args.name,
    variant: args.variant,
    placeholder: t("タグを選択"),
    suggestions: suggestions,
    loading: result.fetching,
    onFocus: () => {
      refresh();
    },
  };
};
