import { App } from "@hireroo/app-store/essential/employee";
import { InterviewOverview } from "@hireroo/app-store/widget/e/InterviewOverview";
import * as Graphql from "@hireroo/graphql/client/urql";
import React from "react";
import { CombinedError } from "urql";

const FETCH_LIMIT = 100;

export type FetchResult = {
  isLoading: boolean;
  error?: CombinedError;
};

export type FetchTagsArgs = {
  companyId: number;
};

export const useFetchTags = (args: FetchTagsArgs): FetchResult => {
  const appStatus = App.useStatus();
  const [result] = Graphql.useListSpotTagsForScreeningTestOverviewQuery({
    variables: {
      input: {
        companyId: args.companyId,
        size: FETCH_LIMIT,
        // TODO: please change when create pagination.
        withCount: false,
        cursorSeconds: undefined,
      },
    },
    pause: appStatus !== "INITIALIZED",
  });

  React.useEffect(() => {
    if (result.data?.spotTagsByCompanyId?.tags) {
      InterviewOverview.updateTags(result.data.spotTagsByCompanyId.tags.map(t => t.name));
    }
  }, [result.data]);

  return {
    isLoading: result.fetching || appStatus !== "INITIALIZED",
    error: result.error,
  };
};
