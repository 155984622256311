import { useTranslation } from "@hireroo/i18n";
import Choice from "@hireroo/ui-assets/images/Form/Choice.png";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Box from "@mui/material/Box";
import Button, { type ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type RemoteInterviewEmptyContentProps = {
  addQuestionButton?: Pick<ButtonProps, "onClick" | "className">;
  notificationText?: string;
};

const Wrapper = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

const RemoteInterviewEmptyContent: React.FC<RemoteInterviewEmptyContentProps> = props => {
  const { t } = useTranslation();
  const addQuestionButtonProps: ButtonProps | undefined = props.addQuestionButton
    ? {
        ...props.addQuestionButton,
        variant: "contained",
        children: t("問題を追加する"),
        color: "secondary",
        startIcon: <AddCircleOutlineOutlinedIcon />,
        fullWidth: false,
      }
    : undefined;
  return (
    <Wrapper>
      <Box mb={2}>
        <img src={Choice} width={400} height={400} />
      </Box>
      {props.notificationText && <Typography children={props.notificationText} />}
      <Box>{addQuestionButtonProps && <Button {...addQuestionButtonProps} />}</Box>
    </Wrapper>
  );
};

RemoteInterviewEmptyContent.displayName = "RemoteInterviewEmptyContent";

export default RemoteInterviewEmptyContent;
