import { proxy } from "valtio";

import type * as Types from "./types";

export const state = proxy<Types.State>({
  plansResponse: null,
  customer: null,
  submitValue: null,
  showingTarget: "EDITOR",
  selectionItems: {},
  temporarySubmitValue: null,
  appliedCoupons: [],
  interviews: [],
  stripJsPaymentMethodResult: null,
  initialCost: null,
});
