import { ScreeningTestListForDetail } from "@hireroo/app-store/widget/e/ScreeningTestListForDetail";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

export type GenerateFilledVariablesFieldPropsArgs = {
  data: Graphql.ListSpotFilterOptionsByScreeningIdForScreeningDetailQuery;
};

export const useGenerateProps = (args: GenerateFilledVariablesFieldPropsArgs): Widget.FilledVariablesFieldProps => {
  const { t } = useTranslation();
  const { spotFilterOptionsByScreeningId } = args.data;

  const options = React.useMemo((): Widget.FilledVariablesFieldProps["options"] => {
    const formTypeMap: Record<Graphql.ScreeningFormType, string> = {
      INPUT_NUMBER: "",
      INPUT_TEXT: t("短文回答"),
      TEXTAREA: t("長文回答"),
    };
    const labelDuplicateCheck = new Map<string, boolean>();
    spotFilterOptionsByScreeningId?.filledVariableCandidates.forEach(variable => {
      if (labelDuplicateCheck.has(variable.label)) {
        labelDuplicateCheck.set(variable.label, true);
      } else {
        labelDuplicateCheck.set(variable.label, false);
      }
    });
    return (spotFilterOptionsByScreeningId?.filledVariableCandidates || []).map(
      (filledVariableCandidate): Widget.FilledVariablesFieldProps["options"][number] => {
        const numberText = filledVariableCandidate.valueType === "INTEGER" ? t("数値（整数）") : t("数値（小数）");
        const helperText = filledVariableCandidate.formType === "INPUT_NUMBER" ? numberText : formTypeMap[filledVariableCandidate.formType];
        const displayName = labelDuplicateCheck.get(filledVariableCandidate.label)
          ? `${filledVariableCandidate.label} (${helperText})`
          : filledVariableCandidate.label;
        return {
          displayName: displayName,
          label: filledVariableCandidate.label,
          uniqueValue: `${filledVariableCandidate.label}-${filledVariableCandidate.valueType}`,
          valueType: filledVariableCandidate.valueType,
        };
      },
    );
  }, [spotFilterOptionsByScreeningId?.filledVariableCandidates, t]);
  return {
    options: options,
    onChange: field => {
      ScreeningTestListForDetail.updateFilters({
        variableEntries: field.variables.map((variable): Graphql.SpotVariableEntryInput => {
          return {
            label: variable.label,
            valueType: variable.valueType,
            value: variable.value,
          };
        }),
      });
    },
  };
};
