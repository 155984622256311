import { App } from "@hireroo/app-store/essential/candidate";
import { InterviewsIdStore } from "@hireroo/app-store/page/c/interviews_id";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";
import { useParams } from "react-router-dom";

import ErrorPanelContainer from "../../../../widget/v2/c/ErrorPanel/Container";
import ScreeningTestContainer, { ScreeningTestContainerProps } from "./Container";

export type ScreeningTestFetchContainerProps = {};

const ScreeningTestFetchContainer: React.FC<ScreeningTestFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const { id } = useParams<{ id: string }>();
  const [spotId, setSpotId] = React.useState<string>(id || "");

  const initialized = InterviewsIdStore.useInitialized();
  const [result] = Graphql.useGetSpotForCandidateInterviewQuery({
    variables: {
      id: `${id}`,
      withAnswers: false,
      ignoreInheritance: true,
    },
    requestPolicy: "network-only",
    pause: appStatus !== "INITIALIZED" || initialized,
  });

  React.useEffect(() => {
    if (result.data) {
      const spot = result.data.spot;
      setSpotId(spot.id);
      InterviewsIdStore.setSpot(spot);
    }
  }, [result.data]);

  if (result.error) {
    return <ErrorPanelContainer graphqlErrors={result.error.graphQLErrors} />;
  }

  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER" color="secondary" />;
  }

  const containerProps: ScreeningTestContainerProps = {
    spotId,
  };
  return <ScreeningTestContainer {...containerProps} />;
};

ScreeningTestFetchContainer.displayName = "ScreeningTestFetchContainer";

export default withErrorBoundary(ScreeningTestFetchContainer, {});
