import { useAlgorithmVariantLabelMap, useProjectVariantLabelMap, useQuestionTypeLabelMap } from "@hireroo/app-helper/question";
import { AssessmentResourceEditor } from "@hireroo/app-store/widget/e/AssessmentResourceEditor";
import { QuestionSelectFieldForResourceEditor } from "@hireroo/app-store/widget/e/QuestionSelectFieldForResourceEditor";
import { TalentAssignField } from "@hireroo/app-store/widget/e/TalentAssignField";
import { ViewerAssignField } from "@hireroo/app-store/widget/e/ViewerAssignField";
import { languageMapForDisplay } from "@hireroo/challenge/definition";
import * as Time from "@hireroo/formatter/time";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import { Widget } from "@hireroo/presentation";
import { useComponentTypesMap } from "@hireroo/system-design/hooks";
import { AssessmentResourceEditorForm } from "@hireroo/validator";
import * as React from "react";

type ItemProps = Widget.AssessmentResourceEditorV2Props["confirmSection"]["selectedQuestions"][0];
type Viewer = Widget.AssessmentResourceEditorV2Props["confirmSection"]["viewers"][0];

export type GenerateConfirmSectionArgs = {
  mode: Widget.AssessmentResourceEditorV2Props["mode"];
};

export const useGenerateConfirmSectionProps = (_args: GenerateConfirmSectionArgs): Widget.AssessmentResourceEditorV2Props["confirmSection"] => {
  const lang = useLanguageCode();
  const { t } = useTranslation();
  const employees = ViewerAssignField.useEmployees();
  const employeeGroups = ViewerAssignField.useEmployeeGroups();
  const submitValues = AssessmentResourceEditor.useSubmitValues();
  const questions = QuestionSelectFieldForResourceEditor.useQuestionMap();
  const talents = TalentAssignField.useTalents();

  const testQuestionSetup = submitValues.TEST_QUESTION_SETUP;
  const testSetup = submitValues.TEST_SETUP;
  const reportSetup = submitValues.REPORT_SETUP;
  const testInviteSetup = submitValues.TEST_INVITE_SETUP;

  const projectVariantLabelMap = useProjectVariantLabelMap();
  const questionTypeLabelMap = useQuestionTypeLabelMap();
  const algorithmVariantLabelMap = useAlgorithmVariantLabelMap();
  const componentTypeMap = useComponentTypesMap();

  const viewerNames = React.useMemo((): Viewer[] => {
    const viewersField = reportSetup.viewers;
    return viewersField.reduce<Viewer[]>((all, viewer) => {
      if (viewer.value?.type === "EMPLOYEE") {
        const employee = employees.get(viewer.value.employeeId);
        if (!employee) {
          return all;
        }
        return all.concat({
          kind: "USER",
          iconSrc: employee.photoUrl,
          name: employee.displayName || employee.email,
        });
      }
      if (viewer.value?.type === "EMPLOYEE_GROUP") {
        const employeeGroup = employeeGroups.get(viewer.value.groupId);
        if (!employeeGroup) {
          return all;
        }
        return all.concat({
          kind: "GROUP",
          name: employeeGroup.groupName,
        });
      }
      return all;
    }, []);
  }, [employeeGroups, employees, reportSetup.viewers]);

  const talent = React.useMemo((): Widget.AssessmentResourceEditorV2Props["confirmSection"]["talent"] => {
    const talentField = submitValues.TEST_INVITE_SETUP.talent;
    const talent = talents.get(talentField.talentId);
    if (!talent) {
      return {
        displayName: "",
        email: "",
      };
    }
    return {
      displayName: talent.displayName,
      email: talent.email,
    };
  }, [submitValues.TEST_INVITE_SETUP.talent, talents]);

  const examIntervalMap: Record<AssessmentResourceEditorForm.ExamIntervalSchema, string> = {
    ONE_WEEK: t("1週間"),
    TWO_WEEKS: t("2週間"),
    ONE_MONTH: t("1ヶ月"),
    TWO_MONTHS: t("2ヶ月"),
    THREE_MONTHS: t("3ヶ月"),
  };

  const remindDayFieldLabel = React.useMemo((): string => {
    const value = testSetup.remindBeforeDays;
    if (Number.isNaN(value)) {
      return t("なし");
    }
    return [value, t("日前")].join(" ");
  }, [testSetup.remindBeforeDays, t]);

  return {
    title: testQuestionSetup.name,
    timelimit: Time.formatMinutes(testQuestionSetup.timeLimitMinutes, lang),
    selectedQuestions: testQuestionSetup.entityTracks.reduce<ItemProps[]>((all, entityTrack, index) => {
      /** Currently, Talent Score does not support anything other than FIXED */
      if (entityTrack.type !== "FIXED") {
        return all;
      }
      const entitySource = entityTrack.entitySource;
      const question = questions.get(entitySource.uniqueKey);
      if (!question) {
        return all;
      }
      const title = [
        `Q${index + 1}`,
        resolveLanguage(question, lang, "title"),
        `(${QuestionSelectFieldForResourceEditor.getVersion(question)})`,
      ].join(" ");
      switch (question.__typename) {
        case "SystemDesignQuestion": {
          if (entitySource.type !== "SYSTEM_DESIGN") {
            return all;
          }
          return all.concat({
            title,
            variant: questionTypeLabelMap[question.__typename],
            description: [
              t("選択可能なリソース種別") + ":",
              entitySource.componentTypes.map(componentType => componentTypeMap[componentType]).join(", "),
            ].join(" "),
          });
        }
        case "MultiChoicePackage": {
          if (entitySource.type !== "QUIZ") {
            return all;
          }
          return all.concat({
            title,
            variant: questionTypeLabelMap[question.__typename],
          });
        }
        case "AlgorithmQuestion": {
          if (entitySource.type !== "CHALLENGE") {
            return all;
          }
          const variantText = [questionTypeLabelMap[question.__typename], algorithmVariantLabelMap[question.algorithmQuestionVariant]]
            .filter(Boolean)
            .join(" - ");
          return all.concat({
            title,
            variant: variantText,
            description: [
              t("選択可能な言語") + ":",
              entitySource.enabledLanguages.map(language => languageMapForDisplay[language]).join(", "),
            ].join(" "),
          });
        }
        case "ProjectQuestion": {
          if (entitySource.type !== "PROJECT") {
            return all;
          }
          const variantText = [questionTypeLabelMap[question.__typename], projectVariantLabelMap[question.projectQuestionVariant]]
            .filter(Boolean)
            .join(" - ");
          return all.concat({
            title,
            variant: variantText,
          });
        }
      }
      return all;
    }, []),
    nextScheduleAt: Time.datetimeFormat(testSetup.nextStartScheduleAt),
    examInterval: examIntervalMap[testSetup.examInterval],
    remindBeforeDays: remindDayFieldLabel,
    memo: reportSetup.memo,
    viewers: viewerNames,
    talent: talent,
    messageForTalent: testInviteSetup.messageForTalent || undefined,
  };
};
