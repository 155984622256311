import { useTranslation } from "@hireroo/i18n";
import CircleIcon from "@mui/icons-material/Circle";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

type Status = "ACTIVE" | "NOT_ACTIVE";

const StyledCircleIcon = styled(CircleIcon)`
  font-size: 12px;
  top: 1px;
  position: relative;
`;

const StyledLabel = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.info.main,
  backgroundColor: theme.palette.action.hover,
  borderRadius: 4,
  paddingLeft: 4,
  paddingRight: 4,
}));

export type AssessmentMetaProps = {
  status: Status;
  nextDateLabel?: string;
};

const AssessmentMeta: React.FC<AssessmentMetaProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const statusDisplayText: Record<Status, string> = {
    ACTIVE: t("実施中"),
    NOT_ACTIVE: t("停止中"),
  };
  const statusColor: Record<Status, string> = {
    ACTIVE: theme.palette.success.light,
    NOT_ACTIVE: theme.palette.error.light,
  };

  return (
    <Stack spacing={2} direction="row" justifyContent={"center"} alignItems={"center"}>
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
        <Typography noWrap sx={{ color: statusColor[props.status] }} fontSize={12}>
          <StyledCircleIcon />
        </Typography>
        <Box mr={1} />
        <Typography noWrap fontSize={14}>
          {statusDisplayText[props.status]}
        </Typography>
      </Box>
      {props.nextDateLabel && (
        <StyledLabel noWrap>
          {t("次回開始日") + ":"}
          {props.nextDateLabel}
        </StyledLabel>
      )}
    </Stack>
  );
};

AssessmentMeta.displayName = "AssessmentMeta";

export default AssessmentMeta;
