import { useTranslation } from "@hireroo/i18n";
import { Fields, ScreeningResourceEditorForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import CustomScoreInputFields, { CustomScoreInputFieldsProps } from "../../../../modules/CustomScoreInputFields/CustomScoreInputFields";
import ResourceField from "../../../../modules/ResourceFieldV2/ResourceFieldV2";
import TimeLimitSettingField, { TimeLimitSettingFieldProps } from "../../../../modules/TimeLimitSettingField/TimeLimitSettingField";
import ToggleResourceField, { ToggleResourceFieldProps } from "../../../../modules/ToggleResourceField/ToggleResourceField";
import InputControlTextField, {
  InputControlTextFieldProps,
} from "../../../../primitive/InputControl/InputControlTextField/InputControlTextField";
import { type LayoutController, useScreeningResourceEditorContext } from "../../Context";
import BehavioralControlField from "./parts/BehavioralControlField/BehavioralControlField";
import HintControlField from "./parts/HintControlField/HintControlField";

type FieldName = keyof ScreeningResourceEditorForm.TestQuestionSetupFormSchema;

const ReservedFieldName = {
  TITLE: "name",
  DESCRIPTION: "description",
  ENTITY_TRACKS: "entityTracks",
} satisfies Record<string, FieldName>;

type EntityTrackFieldName = keyof Fields.EntityTrack.EntityTrack;

const ReservedEntityTrackFieldName = {
  QUESTION_SCORE_WEIGHT: "questionScoreWeight",
} as const satisfies Record<string, EntityTrackFieldName>;

type ReservedFieldName = (typeof ReservedFieldName)[keyof typeof ReservedFieldName];

const Subsection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  boxShadow: "none",
}));

type UniqueKey = Fields.EntitySource.EntitySource["uniqueKey"];
type ScoreWeightInputProps = CustomScoreInputFieldsProps["scoreWeightInputs"][number];
type EntityTrackExtractedData = {
  uniqueKeys: UniqueKey[];
};

type EntitySource = {
  title: string;
  variant: string;
};

export type TestQuestionSetupSectionProps = {
  mode: "CREATE" | "EDIT";
  timeLimitSettingField: TimeLimitSettingFieldProps;
  QuestionSelectField: React.ReactNode;
  onSubmit: SubmitHandler<ScreeningResourceEditorForm.TestQuestionSetupFormSchema>;
  defaultValues: ScreeningResourceEditorForm.TestQuestionSetupFormSchema;
  onChangeEntityTackExtractedDataList: (entityTrackExtractedDataList: EntityTrackExtractedData[]) => void;
  disableFields: Record<FieldName, boolean>;
  entitySources: Record<UniqueKey, EntitySource>;
  customScoreHelpLink: Exclude<CustomScoreInputFieldsProps["customScoreAllocation"], undefined>["helpLink"];
  enableBehavioralControl: boolean;
};

const TestQuestionSetupSection: React.FC<TestQuestionSetupSectionProps> = props => {
  const { t } = useTranslation();
  const { onSubmit, onChangeEntityTackExtractedDataList } = props;
  const { stepName, setController, setTitle, submitValues, setSubmitValue } = useScreeningResourceEditorContext();
  const validateSchema = ScreeningResourceEditorForm.useTestQuestionSetupForm();
  const methods = useForm<ScreeningResourceEditorForm.TestQuestionSetupFormSchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: submitValues["TEST_QUESTION_SETUP"] ?? props.defaultValues,
  });

  const controller = React.useMemo((): LayoutController => {
    return {
      checkGoNextStep: () => {
        return new Promise<boolean>(resolve => {
          methods.handleSubmit(
            fields => {
              onSubmit(fields);
              setSubmitValue("TEST_QUESTION_SETUP", fields);
              resolve(true);
            },
            errors => {
              setSubmitValue("TEST_QUESTION_SETUP", undefined);
              console.warn(errors);
              resolve(false);
            },
          )();
        });
      },
    };
  }, [methods, onSubmit, setSubmitValue]);

  const entityTracks = methods.watch("entityTracks");
  const selectedEntitySourceCount = entityTracks.length;
  React.useEffect(() => {
    const entityTrackExtractedDataList = entityTracks.reduce<EntityTrackExtractedData[]>((all, entityTrack) => {
      switch (entityTrack.type) {
        case "FIXED": {
          all.push({
            uniqueKeys: [entityTrack.entitySource.uniqueKey],
          });
          break;
        }
        case "RANDOM_FROM_QUESTIONS":
        case "SELECTABLE_FROM_QUESTIONS": {
          all.push({
            uniqueKeys: entityTrack.entitySources.map(entitySource => entitySource.uniqueKey),
          });
          break;
        }
      }
      return all;
    }, []);
    onChangeEntityTackExtractedDataList(entityTrackExtractedDataList);
  }, [onChangeEntityTackExtractedDataList, entityTracks]);

  React.useEffect(() => {
    setController("TEST_QUESTION_SETUP", controller);
  }, [setController, controller]);

  const screeningTitle = methods.watch("name");
  React.useEffect(() => {
    setTitle(screeningTitle);
  }, [screeningTitle, setTitle]);

  const titleFieldProps: InputControlTextFieldProps = {
    fullWidth: true,
    placeholder: t("フロントエンジニア採用向け試験"),
  };
  const descriptionOptionalResourceField: ToggleResourceFieldProps = {
    toggleKind: "BUTTON",
    label: t("コーディングテストの説明"),
    defaultOpen: !!methods.getValues("description"),
    onDiscard: () => {
      methods.setValue("description", "");
    },
    disabled: props.disableFields.description,
  };
  const descriptionFieldProps: InputControlTextFieldProps = {
    fullWidth: true,
    minRows: 3,
    maxRows: 10,
    multiline: true,
  };
  const scoreWeightInputsProps = React.useMemo((): ScoreWeightInputProps[] => {
    return entityTracks.reduce<ScoreWeightInputProps[]>((all, entityTrack, index) => {
      const titlePrefix = `Q${index + 1}`;
      switch (entityTrack.type) {
        case "FIXED": {
          const entitySource = props.entitySources[entityTrack.entitySource.uniqueKey];
          if (!entitySource) {
            break;
          }
          all.push({
            id: entityTrack.entitySource.uniqueKey,
            title: `${titlePrefix} ${entitySource.title}`,
            name: `${ReservedFieldName.ENTITY_TRACKS}.${index}.${ReservedEntityTrackFieldName.QUESTION_SCORE_WEIGHT}`,
            questionVariant: entitySource.variant,
          });
          break;
        }
        case "RANDOM_FROM_QUESTIONS": {
          all.push({
            id: entityTrack.entitySources.map(entitySource => entitySource.uniqueKey).join("-"),
            title: `${titlePrefix} ${t("ランダムに問題が出題される")}`,
            name: `${ReservedFieldName.ENTITY_TRACKS}.${index}.${ReservedEntityTrackFieldName.QUESTION_SCORE_WEIGHT}`,
          });
          break;
        }
        case "SELECTABLE_FROM_QUESTIONS": {
          all.push({
            id: entityTrack.entitySources.map(entitySource => entitySource.uniqueKey).join("-"),
            title: `${titlePrefix} ${t("受験者が問題を選択できる")}`,
            name: `${ReservedFieldName.ENTITY_TRACKS}.${index}.${ReservedEntityTrackFieldName.QUESTION_SCORE_WEIGHT}`,
          });
          break;
        }
        default:
          throw new Error(`Invalid entity Track: ${entityTrack satisfies never}`);
      }
      return all;
    }, []);
  }, [entityTracks, props.entitySources, t]);

  const handleCustomScoreReset = React.useCallback(() => {
    entityTracks.forEach((entityTrack, index) => {
      methods.setValue(`${ReservedFieldName.ENTITY_TRACKS}.${index}.${ReservedEntityTrackFieldName.QUESTION_SCORE_WEIGHT}`, 1);
    });
  }, [entityTracks, methods]);

  const customScoreInputFields: Omit<CustomScoreInputFieldsProps, "name"> = {
    description:
      t("初期設定ではスコアの重み付けが均等に設定されています。") +
      t("出題する問題ごとにスコアの配点を変更したい場合、1~100の範囲で配点の重み付けができます。") +
      t("配点の割合は入力された重み付け合算値からそれぞれ算出されます。"),
    resetButton: {
      onClick: handleCustomScoreReset,
    },
    scoreWeightInputs: scoreWeightInputsProps,
    customScoreAllocation:
      entityTracks.length > 0
        ? {
            allocationBar: {
              scores: entityTracks.map((entityTrack, index) => {
                return {
                  label: `Q${index + 1}`,
                  weight: entityTrack.questionScoreWeight,
                };
              }),
            },
            helpLink: props.customScoreHelpLink,
            tooltip: t("トータルスコアに対しての配点割合を表示します。"),
          }
        : undefined,
  };

  if (stepName !== "TEST_QUESTION_SETUP") {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <Stack py={2} spacing={1.5}>
        <Subsection>
          <ResourceField label={t("タイトル")} kind="REQUIRED" pb={2}>
            <InputControlTextField name={ReservedFieldName.TITLE} {...titleFieldProps} />
          </ResourceField>

          <ToggleResourceField {...descriptionOptionalResourceField}>
            <InputControlTextField name={ReservedFieldName.DESCRIPTION} {...descriptionFieldProps} />
          </ToggleResourceField>
        </Subsection>

        <Subsection>
          <ResourceField label={t("出題問題")} kind="REQUIRED" pb={2}>
            {props.QuestionSelectField}
          </ResourceField>
        </Subsection>

        {customScoreInputFields && (
          <Subsection>
            <ResourceField label={t("カスタムスコア")} kind="NONE" pb={3}>
              <CustomScoreInputFields {...customScoreInputFields} />
            </ResourceField>
          </Subsection>
        )}

        <Subsection>
          <ResourceField label={t("制限時間")} kind="NONE" pb={3}>
            {selectedEntitySourceCount > 0 && <TimeLimitSettingField {...props.timeLimitSettingField} version="V2" />}
            {selectedEntitySourceCount === 0 && (
              <Typography color="text.secondary" textAlign="center" variant="body2">
                {t("まずは問題を選択してください。")}
              </Typography>
            )}
          </ResourceField>

          {props.enableBehavioralControl && <BehavioralControlField />}

          <HintControlField />
        </Subsection>
      </Stack>
    </FormProvider>
  );
};

TestQuestionSetupSection.displayName = "TestQuestionSetupSection";

export default TestQuestionSetupSection;
