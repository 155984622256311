import React from "react";
import {
  CreateLocalTrackOptions,
  LocalAudioTrack,
  LocalVideoTrack,
  RemoteAudioTrack,
  RemoteParticipant,
  RemoteVideoTrack,
  Room,
  Track,
} from "twilio-video";

import { ParticipantId } from "./types";
import { useHandleRoomDisconnection } from "./useHandleRoomDisconnection";
import { useIsRemoteAudioTracksEnabled } from "./useIsTracksEnabled";
import { useLocalAudioToggle } from "./useLocalAudioToggle";
import { useLocalTracks } from "./useLocalTracks";
import { useLocalVideoToggle } from "./useLocalVideoToggle";
import { useNoiseCancellation } from "./useNoiseCancellation";
import { useRemoteParticipantAudioTracks } from "./useRemoteParticipantAudioTracks";
import { useRemoteParticipants } from "./useRemoteParticipants";
import { useRemoteParticipantVideoTracks } from "./useRemoteParticipantVideoTracks";
import { useRestartAudioTrackOnDeviceChange } from "./useRestartAudioTrackOnDeviceChange";
import { useRoom } from "./useRoom";

type ContextValue = {
  // TODO: Define interface for Don't depend on implementation twilio
  room: Room | null;
  localAudioTrack?: LocalAudioTrack;
  localVideoTrack?: LocalVideoTrack;
  isConnecting: boolean;
  connect: (token: string) => Promise<void>;
  getLocalVideoTrack: (newOptions?: CreateLocalTrackOptions) => Promise<LocalVideoTrack>;
  getLocalAudioTrack: () => Promise<LocalAudioTrack>;
  isAcquiringLocalTracks: boolean;
  removeLocalVideoTrack: () => void;
  removeLocalAudioTrack: () => void;
  getAudioAndVideoTracks: () => Promise<void>;
  isVideoEnabled: boolean;
  isAudioEnabled: boolean;
  isNoiseCancellationEnabled: boolean;
  toggleVideo: () => void;
  toggleAudio: () => void;
  toggleNoiseCancellation: () => void;
  remoteParticipants: RemoteParticipant[];
  remoteAudioTracks: Record<ParticipantId, RemoteAudioTrack>;
  remoteAudioTracksMicOn: Record<Track.SID, boolean>;
  remoteVideoTracks: Record<ParticipantId, RemoteVideoTrack>;
};

export const TwilioVideoContext = React.createContext<ContextValue>({} as ContextValue);

export const useTwilioVideoRoomContext = () => {
  const context = React.useContext(TwilioVideoContext);
  if (!context) {
    throw new Error("useTwilioVideoContext must be used within a VideoProvider");
  }
  return context;
};

export type TwilioVideoProviderProps = {
  children: React.ReactNode;
};

export const TwilioVideoProvider: React.FC<TwilioVideoProviderProps> = props => {
  // For Local Participant
  const {
    videoTrack,
    audioTrack,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
  } = useLocalTracks();
  const localTracks = [audioTrack, videoTrack].flatMap(v => (v ? [v] : []));
  const { room, isConnecting, connect } = useRoom(localTracks);

  // Register callback functions to be called on room disconnect.
  useHandleRoomDisconnection(room, removeLocalAudioTrack, removeLocalVideoTrack);
  useRestartAudioTrackOnDeviceChange(audioTrack);

  const { isEnabled: isAudioEnabled, toggle: toggleAudio } = useLocalAudioToggle(audioTrack, getLocalAudioTrack);
  const { isEnabled: isVideoEnabled, toggle: toggleVideo } = useLocalVideoToggle(
    videoTrack,
    getLocalVideoTrack,
    removeLocalVideoTrack,
    room?.localParticipant,
  );
  const { isEnabled: isNoiseCancellationEnabled, toggleNoiseCancellation } = useNoiseCancellation(audioTrack, getLocalAudioTrack);

  // For Remote
  const remoteParticipants = useRemoteParticipants(room);
  const remoteAudioTracks = useRemoteParticipantAudioTracks(remoteParticipants);
  const remoteVideoTracks = useRemoteParticipantVideoTracks(remoteParticipants);
  const remoteAudioTracksMicOn = useIsRemoteAudioTracksEnabled(Object.values(remoteAudioTracks));

  return (
    <TwilioVideoContext.Provider
      value={{
        room,
        localVideoTrack: videoTrack,
        localAudioTrack: audioTrack,
        isConnecting,
        getLocalVideoTrack,
        getLocalAudioTrack,
        connect,
        isAcquiringLocalTracks,
        removeLocalVideoTrack,
        removeLocalAudioTrack,
        getAudioAndVideoTracks,
        isAudioEnabled,
        isVideoEnabled,
        toggleAudio,
        toggleVideo,
        isNoiseCancellationEnabled,
        toggleNoiseCancellation,
        remoteParticipants,
        remoteAudioTracks,
        remoteAudioTracksMicOn,
        remoteVideoTracks,
      }}
    >
      {props.children}
    </TwilioVideoContext.Provider>
  );
};
