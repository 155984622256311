import { Payment, Role } from "@hireroo/app-store/essential/employee";
import { Pages, Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import * as React from "react";
import { Outlet } from "react-router-dom";

import SnackbarContainer from "../../widget/shared/Snackbar/Container";
import OopsContainer from "../Oops/Container";
import { useGenerateProps } from "./useGenerateProps";

const PermittedRouteForEmployeeContainer: React.FC = () => {
  const roleInitialized = Role.useInitialized();
  const paymentInitialized = Payment.useInitialized();
  const errorPanelProps = useGenerateProps();
  const initialized = roleInitialized && paymentInitialized;

  /**
   * Display the Loading screen until the Role of the currently signed-in user is initialized.
   *
   * If you make changes here, verify the following
   *
   * 1. Ability to access the Sign In, Sign Up, and Reset Password pages while not logged in.
   * 2. When directly accessing a page with a PlanType determination, there should not be an error message about the plan for a moment.
   *    example): /e/remotes
   */
  if (!initialized) {
    const emptyProps: Pages.EmptyProps = {
      layout: {
        loading: true,
        Bottom: <SnackbarContainer />,
      },
    };
    return <Pages.Empty {...emptyProps} />;
  }
  if (errorPanelProps) {
    return <Widget.ErrorPanel {...errorPanelProps} />;
  }
  return (
    <React.Suspense>
      <Outlet />
    </React.Suspense>
  );
};

PermittedRouteForEmployeeContainer.displayName = "PermittedRouteForEmployeeContainer";

export default withErrorBoundary(PermittedRouteForEmployeeContainer, { fallback: <OopsContainer /> });
