import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import * as z from "zod";

import * as AssignField from "./fields/AssignField";
import * as EntityTrack from "./fields/EntityTrack";
import * as TagField from "./fields/TagsField";

export const useCreateRemoteInterview = () => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const viewer = AssignField.useAssignListItem();
  const tag = TagField.useTagItem();

  return z.object({
    name: z
      .string()
      .max(100, {
        message: t2("ValidateMaxTextSizeMessage", {
          size: 100,
          name: t("タイトル"),
        }),
      })
      .nullable(),
    candidateName: z
      .string()
      .max(100, {
        message: t2("ValidateMaxTextSizeMessage", {
          size: 100,
          name: t("候補者名"),
        }),
      })
      .nullable(),
    memo: z
      .string()
      .max(10000, {
        message: t2("ValidateMaxTextSizeMessage", {
          size: 10000,
          name: t("メモ"),
        }),
      })
      .nullable(),
    isPublic: z.boolean(),
    /**
     * User ID to be set when viewing privileges are changed to private.
     */
    editorUid: z.string().nullable(),
    viewers: viewer.array(),
    tags: tag.array(),
  });
};
export type CreateRemoteInterviewSchema = z.infer<ReturnType<typeof useCreateRemoteInterview>>;

export const useCreateRemoteTemplate = () => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const remote = useCreateRemoteInterview();
  const entityTrack = EntityTrack.useEntityTrack();
  return z.object({
    remote: remote,
    entityTracks: entityTrack.array().max(10, { message: t("問題は10問以下で選択してください。") }),
    templateTitle: z
      .string()
      .min(1, { message: t("インタビューテンプレートタイトルは必須です。") })
      .max(100, {
        message: t2("ValidateMaxTextSizeMessage", {
          size: 100,
          name: t("インタビューテンプレートタイトル"),
        }),
      })
      .regex(/^[^\cA-\cZ]+$/, t("利用できない文字列が含まれています。")),
    templateDescription: z
      .string()
      .max(10000, {
        message: t2("ValidateMaxTextSizeMessage", {
          size: 10000,
          name: t("インタビューテンプレートの説明"),
        }),
      })
      .nullable(),
    variables: z
      .array(
        z.object({
          templateId: z.number(),
          name: z.string(),
          description: z.string().nullable(),
        }),
      )
      .nullable(),
  });
};
export type CreateRemoteTemplateSchema = z.infer<ReturnType<typeof useCreateRemoteTemplate>>;
