import * as React from "react";
import { FormProvider } from "react-hook-form";

import { useFreepadOptionalFormContext } from "./Context";
import CodeSection, { CodeSectionProps } from "./parts/CodeSection/CodeSection";
import FlowChartSection, { FlowChartSectionProps } from "./parts/FlowChartSection/FlowChartSection";
import VersionDialog, { VersionDialogProps } from "./parts/VersionDialog/VersionDialog";

export { FreepadOptionalFormProvider, type FreepadOptionalFormProviderProps, useFreepadOptionalFormContext } from "./Context";

export type FreepadOptionalFormProps = {
  versionDialog: VersionDialogProps;
  codeSection: CodeSectionProps;
  flowChartSection: FlowChartSectionProps;
};

const FreepadOptionalForm: React.FC<FreepadOptionalFormProps> = props => {
  const { methods, variant } = useFreepadOptionalFormContext();
  return (
    <FormProvider {...methods}>
      <VersionDialog {...props.versionDialog} />
      {variant === "ALGORITHM" && <CodeSection {...props.codeSection} />}
      {variant === "SYSTEM_DESIGN" && <FlowChartSection {...props.flowChartSection} />}
    </FormProvider>
  );
};

FreepadOptionalForm.displayName = "FreepadOptionalForm";

export default FreepadOptionalForm;
