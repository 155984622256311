import { getLanguageFromFile, supportReactTypeDefinition, TypeDefs, updateEditorValidationMessage } from "@hireroo/code-editor/helpers/monaco";
import CodeEditor, { type CodeEditorProps } from "@hireroo/code-editor/react/CodeEditor";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import * as React from "react";

import PlaybackToolbar, { PlaybackToolbarProps } from "../../../modules/PlaybackToolbar/PlaybackToolbar";

export type ProjectPlaybackDialogProps = {
  open: boolean;
  onClose: () => void;
  editor: CodeEditorProps;
  playbackToolbar: Omit<PlaybackToolbarProps, "screenStatus">;
  typeDefs: TypeDefs;
};

const StyledDialog = styled(Dialog)({
  root: {
    flexGrow: 1,
  },
  heading: {
    fontWeight: "bold",
  },
  expanded: {
    width: "80vw",
    height: "100vh",
  },
});

const ProjectPlaybackDialog: React.FC<ProjectPlaybackDialogProps> = props => {
  const editorProps: CodeEditorProps = {
    ...props.editor,
    options: {
      readOnly: true,
    },
    beforeMount: monaco => {
      updateEditorValidationMessage(monaco);
      supportReactTypeDefinition(monaco, props.typeDefs);
    },
    language: props.editor.path ? getLanguageFromFile(props.editor.path) : "",
  };
  const playbackToolbar: PlaybackToolbarProps = {
    ...props.playbackToolbar,
    screenStatus: "EXPANDED",
  };
  return (
    <StyledDialog onClose={props.onClose} open={props.open} maxWidth="lg">
      <Box display="flex" flexDirection="column" position="relative" height="100%" justifyContent="space-between">
        <Box>
          <Box width="80vw" height="80vh">
            <React.Suspense>
              <CodeEditor {...editorProps} width="100%" height="100%" key={editorProps.path} />
            </React.Suspense>
          </Box>
          <PlaybackToolbar {...playbackToolbar} />
        </Box>
      </Box>
    </StyledDialog>
  );
};

ProjectPlaybackDialog.displayName = "ProjectPlaybackDialog";

export default ProjectPlaybackDialog;
