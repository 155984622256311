import { useQuestionDifficultyTypeMap } from "@hireroo/app-definition/question";
import { InterviewOverview } from "@hireroo/app-store/widget/e/InterviewOverview";
import { languageMapForDisplay } from "@hireroo/challenge/definition";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useComponentTypesMap } from "@hireroo/system-design/hooks";

type Row = Exclude<Widget.ScreeningTestOverviewProps["questionOverview"], undefined>["rows"][number];

export const useGenerateQuestionOverviewProps = (): Widget.ScreeningTestOverviewProps["questionOverview"] => {
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const interview = InterviewOverview.useInterview();
  const componentTypeMap = useComponentTypesMap();
  const difficultyTypeMap = useQuestionDifficultyTypeMap();

  return {
    rows: interview.issuedEntities.map((issuedEntity, index): Row => {
      const entity = issuedEntity.entity;
      const qNumber = `Q${index + 1}`;
      if (entity.__typename === "ChallengeEntity" && entity.challengeQuestion) {
        const question = entity.challengeQuestion;
        return {
          title: {
            children: `${qNumber} ${resolveLanguage(question, lang, "title")} （${t("コーディング形式")}）`,
            href: generatePath("/e/questions/challenge/:id", {
              pathParams: {
                id: question.questionId.toString(),
              },
              queryParams: {
                version: question.version,
              },
            }),
          },
          variant: t("コーディング形式"),
          difficultyStars: {
            difficulty: difficultyTypeMap[question.difficulty],
          },
          selectedContents: entity.enabledLanguages.map(lang => languageMapForDisplay[lang] ?? lang),
          weight: issuedEntity.questionWeight,
          isArchived: question.algorithmQuestionStatus === "ARCHIVED",
        };
      } else if (entity.__typename === "QuizEntity" && entity.pb_package) {
        const pbPackage = entity.pb_package;
        return {
          title: {
            children: `${qNumber} ${resolveLanguage(pbPackage, lang, "title")} (${pbPackage.version})`,
            href: generatePath("/e/questions/quiz/:id", {
              pathParams: {
                id: pbPackage.packageId.toString(),
              },
              queryParams: {
                version: pbPackage.version,
              },
            }),
          },
          variant: t("クイズ形式"),
          difficultyStars: {
            difficulty: difficultyTypeMap[pbPackage.difficulty],
          },
          weight: issuedEntity.questionWeight,
          isArchived: pbPackage.multiChoicePackageStatus === "ARCHIVED",
        };
      } else if (entity.__typename === "ProjectEntity" && entity.question) {
        const question = entity.question;
        return {
          title: {
            children: `${qNumber} ${resolveLanguage(question, lang, "title")}`,
            href: generatePath("/e/questions/project/:id", {
              pathParams: {
                id: question.questionId.toString(),
              },
            }),
          },
          variant: t("実践形式"),
          difficultyStars: {
            difficulty: difficultyTypeMap[question.difficulty],
          },
          weight: issuedEntity.questionWeight,
          isArchived: question.projectQuestionStatus === "ARCHIVED",
        };
      } else if (entity.__typename === "SystemDesignEntity" && entity.question) {
        const question = entity.question;
        return {
          title: {
            children: `${qNumber} ${resolveLanguage(question, lang, "title")}`,
            href: generatePath("/e/questions/systemdesign/:id", {
              pathParams: {
                id: question.questionId.toString(),
              },
            }),
          },
          variant: t("システムデザイン形式"),
          difficultyStars: {
            difficulty: difficultyTypeMap[question.difficulty],
          },
          weight: issuedEntity.questionWeight,
          selectedContents: entity.componentTypes.map(c => componentTypeMap[c]),
          isArchived: question.systemDesignQuestionStatus === "ARCHIVED",
        };
      } else {
        throw new Error("Unexpected entity type");
      }
    }),
  };
};
