export const SplitParams = {
  splitId: "RemoteInterviewSystemDesignCodingEditor",
  Contents: {
    SelectComponentArea: {
      id: "SelectComponentArea",
    },
    DrawingArea: {
      id: "DrawingArea",
    },
  },
};
