export const SupportAlgorithmLanguages = [
  "c",
  "cpp",
  "csharp",
  "dart",
  "go",
  "java",
  "javascript",
  "kotlin",
  "php",
  "python3",
  "ruby",
  "rust",
  "scala",
  "swift",
  "typescript",
  "perl",
];

export const SupportDatabaseLanguages = ["mysql", "pgsql", "sqlite"];
export const SupportLanguages = SupportAlgorithmLanguages.concat(SupportDatabaseLanguages);

export const UNSUPPORTED_READABILITY_RUNTIMES = ["sqlite", "pgsql", "mysql", "dart"];
