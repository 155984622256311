import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";

import AppealMessageInReport, { AppealMessageInReportProps } from "../../../modules/AppealMessageInReport/AppealMessageInReport";
import PlaybackModeSwitcher, { PlaybackModeSwitcherProps } from "../../../modules/PlaybackModeSwitcher/PlaybackModeSwitcher";

export type ReportSystemDesignPlaybackSectionProps = {
  playbackModeSwitcher?: PlaybackModeSwitcherProps | false | null;
  ContentsViewer?: React.ReactNode;
  appealMessageArea?: Omit<AppealMessageInReportProps, "title"> | false | null | "";
};

const ReportSystemDesignPlaybackSection: React.FC<ReportSystemDesignPlaybackSectionProps> = props => {
  const { t } = useTranslation();
  const appealMessageAreaProps: AppealMessageInReportProps = {
    body: props.appealMessageArea ? props.appealMessageArea.body : t("受験者が入力した説明文はありません。"),
    title: t("候補者による提出されたフローチャートの説明を確認することができます。"),
  };
  return (
    <Box>
      <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} mb={3}>
        <Typography sx={{ fontWeight: "bold", color: "text.secondary" }}>{t("提出された解答")}</Typography>
        {props.playbackModeSwitcher && <PlaybackModeSwitcher {...props.playbackModeSwitcher} />}
      </Box>
      <Box display="flex" flexDirection="row" height="100%" width="100%" position="relative" mb={2}>
        {props.ContentsViewer}
      </Box>
      <AppealMessageInReport {...appealMessageAreaProps} />
    </Box>
  );
};

ReportSystemDesignPlaybackSection.displayName = "ReportSystemDesignPlaybackSection";

export default ReportSystemDesignPlaybackSection;
