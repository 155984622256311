import AppRegistrationOutlinedIcon from "@mui/icons-material/AppRegistrationOutlined";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import HomeOutlined from "@mui/icons-material/HomeOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import MonitorOutlined from "@mui/icons-material/MonitorOutlined";
import VideocamOutlined from "@mui/icons-material/VideocamOutlined";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText, { ListItemTextProps } from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import * as React from "react";

import Link from "../../../../../../primitive/Link/Link";
import type * as Types from "../../../../types";

const StyledListItemIcon = styled(ListItemIcon)(() => ({
  width: "24px",
  minWidth: "24px",
  marginRight: "8px",
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: "8px",
  borderRadius: "4px",
  "&:hover": {
    color: theme.palette.secondary.light,
    backgroundColor: theme.palette["Secondary/Shades"].p16,
  },
  svg: {
    color: theme.palette.secondary.main,
    maxWidth: "16px",
  },
}));

const StyledListItemText = styled(ListItemText)(() => ({
  "& .MuiTypography-root": {
    fontSize: 14,
    fontWeight: "inherit",
  },
}));

const StartIconMap: Record<Types.StartIcon, React.ReactNode> = {
  HOME: <HomeOutlined color="secondary" fontSize="small" />,
  MONITOR: <MonitorOutlined color="secondary" fontSize="small" />,
  ASSIGNMENT: <AssignmentIndOutlinedIcon color="secondary" fontSize="small" />,
  VIDEOCAM: <VideocamOutlined color="secondary" fontSize="small" />,
  REGISTRATION: <AppRegistrationOutlinedIcon color="secondary" fontSize="small" />,
  DOCUMENT: <InsertDriveFileOutlinedIcon color="secondary" fontSize="small" />,
};

export type NavigationMenuItemProps = Types.MenuItemWithoutChildren & {
  endIcon?: React.ReactNode;
  root?: boolean;
};

const NavigationMenuItem: React.FC<NavigationMenuItemProps> = props => {
  const listItemTextProps: ListItemTextProps = {
    primary: props.children,
    sx: {
      fontWeight: props.root ? 500 : 400,
      paddingLeft: props.startIcon ? undefined : "32px",
    },
  };
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <StyledListItemButton onClick={props.onClick} component={Link} href={props.href}>
      {props.startIcon && <StyledListItemIcon>{StartIconMap[props.startIcon]}</StyledListItemIcon>}
      <StyledListItemText {...listItemTextProps} />
      {props.endIcon}
    </StyledListItemButton>
  );
};

NavigationMenuItem.displayName = "NavigationMenuItem";

export default NavigationMenuItem;
