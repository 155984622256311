import { useTranslation } from "@hireroo/i18n";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";

import IconButton, { IconButtonProps } from "../../../primitive/Button/IconButton";
import IDEConsoleTable, { IDEConsoleTableProps } from "./IDEConsoleTable";
import TableInputDialog, { TableInputDialogProps } from "./TableInputDialog";

const calculateRecord = (tableRecords: string[][]): number => {
  let emptyRecordCount = 0;
  tableRecords.forEach(record => {
    let emptyStringCount = 0;
    record.forEach(col => {
      if (col === "") {
        emptyStringCount++;
      }
    });
    if (record.length === emptyStringCount) {
      emptyRecordCount++;
    }
  });
  return tableRecords.length - emptyRecordCount;
};

export type SummaryRowsProps = {
  tableName: string;
  isEditable?: boolean;

  tableInputDialog?: Omit<TableInputDialogProps, "open" | "onClose">;

  outputTable: Omit<IDEConsoleTableProps, "maxHeight">;
};

const SummaryRows: React.FC<SummaryRowsProps> = props => {
  const { t } = useTranslation();
  const [collapseOpen, setCollapseOpen] = React.useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);

  const expandButton: IconButtonProps = {
    size: "small",
    "aria-label": "expand row",
    onClick: React.useCallback(() => {
      setCollapseOpen(!collapseOpen);
    }, [collapseOpen]),
    children: collapseOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />,
  };

  const editButton: ButtonProps = {
    onClick: () => setDialogOpen(true),
  };
  const handleClose = React.useCallback(() => setDialogOpen(false), []);
  const tableInputDialog: TableInputDialogProps | undefined = props.tableInputDialog && {
    ...props.tableInputDialog,
    open: dialogOpen,
    onClose: handleClose,
  };
  return (
    <>
      <TableRow>
        <TableCell width="1rem" sx={{ paddingLeft: 0 }}>
          <IconButton {...expandButton} />
        </TableCell>
        <TableCell>{props.tableName}</TableCell>
        {/*table columns*/}
        <TableCell sx={{ overflow: "scroll" }}>{`[ ${props.outputTable.tableColumns.map(col => col.name).join(", ")} ]`}</TableCell>
        {/*table row count*/}
        <TableCell width="100px" align="right">
          {calculateRecord(props.outputTable.displayRecords)}
        </TableCell>
        {props.isEditable && (
          <TableCell width="100px" sx={{ paddingRight: 0 }}>
            <Button aria-label="edit row" startIcon={<EditIcon />} size="small" {...editButton}>
              {t("編集する")}
            </Button>
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell sx={{ py: 0, borderBottom: "unset" }} colSpan={4}>
          <Collapse in={collapseOpen} timeout="auto" unmountOnExit>
            <Box m={1} p={1}>
              <Typography variant="subtitle2" mb={1}>
                {`${t("プレビュー")}`}
              </Typography>
              <Box mx={2}>
                {calculateRecord(props.outputTable.displayRecords) > 0 ? (
                  <Paper sx={{ overflow: "hidden" }}>
                    <IDEConsoleTable {...props.outputTable} maxHeight={props.isEditable ? 120 : undefined} />
                  </Paper>
                ) : (
                  <Typography variant="body2">{`${t("データが入力されていません。")}`}</Typography>
                )}
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {props.isEditable && tableInputDialog && <TableInputDialog {...tableInputDialog} />}
    </>
  );
};

SummaryRows.displayName = "SummaryRows";

export default SummaryRows;
