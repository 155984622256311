import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import * as Graphql from "@hireroo/graphql/client/urql";
import type { Widget } from "@hireroo/presentation";

import { useGenerateTestCaseSectionPropsForBackendV2 } from "./useGenerateTestCaseSectionPropsForBackendV2";
import { useGenerateTestCaseSectionPropsForBackendV3 } from "./useGenerateTestCaseSectionPropsForBackendV3";
import { useGenerateTestCaseSectionPropsForDataScience } from "./useGenerateTestCaseSectionPropsForDataScience";
import { useGenerateTestCaseSectionPropsForDefault } from "./useGenerateTestCaseSectionPropsForDefault";
import { useGenerateTestCaseSectionPropsForFrontend } from "./useGenerateTestCaseSectionPropsForFrontend";

export type GenerateTestCaseSectionPropsArgs = {
  entityId: number;
  uniqueKey: ProjectTestReport.UniqueKey;
  showScore: boolean;
};

export const useGenerateTestCaseSectionProps = (args: GenerateTestCaseSectionPropsArgs): Widget.ProjectTestReportProps["testCaseSection"] => {
  const hooks = ProjectTestReport.useCreateProjectHooks(args.entityId);
  const question = hooks.useQuestion();
  const variant = hooks.useQuestionVariant();
  // TestCaseSection is shared between v2 and v3 but test case and test result schema is different,
  // so generate both props and pass it separately by the project version
  const backendTestCaseSectionPropsV2 = useGenerateTestCaseSectionPropsForBackendV2({
    ...args,
    ignore: question?.projectVersion.startsWith("v3") ?? false,
  });
  const backendTestCaseSectionPropsV3 = useGenerateTestCaseSectionPropsForBackendV3({
    ...args,
    ignore: !question?.projectVersion.startsWith("v3"),
  });
  const frontendTestCaseSectionProps = useGenerateTestCaseSectionPropsForFrontend(args);
  const dataScienceTestCaseSectionProps = useGenerateTestCaseSectionPropsForDataScience(args);
  const defaultTestCaseSectionProps = useGenerateTestCaseSectionPropsForDefault(args);
  switch (variant) {
    case Graphql.ProjectQuestionVariant.Backend:
      if (question?.projectVersion.startsWith("v3")) {
        return backendTestCaseSectionPropsV3;
      } else {
        return backendTestCaseSectionPropsV2;
      }
    case Graphql.ProjectQuestionVariant.Frontend:
      return frontendTestCaseSectionProps;
    case Graphql.ProjectQuestionVariant.DataScience:
      return dataScienceTestCaseSectionProps;
    case Graphql.ProjectQuestionVariant.Default:
      return defaultTestCaseSectionProps;
    default:
      throw new Error(`Invalid project question variant: ${variant}`);
  }
};
