import { ExamTransitionVisualizer } from "@hireroo/app-store/widget/shared/ExamTransitionVisualizer";
import { formatScore } from "@hireroo/formatter/score";
import * as Time from "@hireroo/formatter/time";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";
import { OperationContext } from "urql/core";

export type GenerateExamTransitionVisualizerPropsArgs = {
  selectedExamId: string | null;
  onChangeExamId: (examId: string) => void;
  refresh: (opts?: Partial<OperationContext>) => void;
};

type Data = Widget.ExamTransitionVisualizerProps["graph"]["data"][0];

export const useGenerateProps = (args: GenerateExamTransitionVisualizerPropsArgs): Widget.ExamTransitionVisualizerProps => {
  const { t } = useTranslation();
  const exams = ExamTransitionVisualizer.useCurrentExams();
  const hasNext = ExamTransitionVisualizer.useHasNextPage();
  const hasPrev = ExamTransitionVisualizer.useHasPreviousPage();
  const willFetch = ExamTransitionVisualizer.useWillFetch();
  const currentPage = ExamTransitionVisualizer.useCurrentPage();

  const data = React.useMemo((): Data[] => {
    const latestExam = exams.at(-1);
    /**
     * display the latest exam only when the status is finalized or expired
     * currenPage === 0 means latest exam section
     */
    const targetExams =
      currentPage === 0 && !(latestExam?.status === "FINALIZED" || latestExam?.status === "EXPIRED")
        ? exams.filter(e => e.examId !== latestExam?.examId)
        : exams;
    const currentData = targetExams.map((exam): Data => {
      return {
        id: exam.examId,
        xValue: `${exam.startIntervalAtSeconds ? Time.unixToDatetimeFormat(exam.startIntervalAtSeconds) : ""} - ${
          exam.endIntervalAtSeconds ? Time.unixToDatetimeFormat(exam.endIntervalAtSeconds) : ""
        }`,
        yValue: formatScore(exam.relativeScore),
        isActive: args.selectedExamId === exam.examId,
      };
    });
    if (targetExams.length < ExamTransitionVisualizer.DISPLAY_PER_PAGE && latestExam) {
      const nonYValueData = Array.from({ length: ExamTransitionVisualizer.DISPLAY_PER_PAGE - targetExams.length }, (_, index) => index).map(
        (v): Data => {
          const interval = (latestExam.endIntervalAtSeconds ?? 0) - (latestExam.startIntervalAtSeconds ?? 0);
          const additionalSeconds = interval * (v + 1);
          return {
            id: `${v}`,
            xValue: `${
              latestExam.startIntervalAtSeconds ? Time.unixToDatetimeFormat(latestExam.startIntervalAtSeconds + additionalSeconds) : ""
            } - ${latestExam.endIntervalAtSeconds ? Time.unixToDatetimeFormat(latestExam.endIntervalAtSeconds + additionalSeconds) : ""}`,
          };
        },
      );
      return currentData.concat(nonYValueData);
    }
    return currentData;
  }, [args.selectedExamId, currentPage, exams]);

  return {
    graph: {
      data: data,
      onClickY: payload => {
        args.onChangeExamId(payload.id);
      },
    },
    switchButtonGroup: {
      rightButton: {
        tooltipText: hasNext ? t("次回のテスト期間に移動する") : t("最新のテスト期間です"),
        onClick: () => {
          ExamTransitionVisualizer.goNextPage();
        },
        disabled: !hasNext,
      },
      leftButton: {
        tooltipText: hasPrev ? t("前回のテスト期間に移動する") : t("一番古いのテスト期間です"),
        onClick: () => {
          if (willFetch) {
            ExamTransitionVisualizer.updateOffsetToCurrentMax();
            args.refresh();
          }
          ExamTransitionVisualizer.goPreviousPage();
        },
        disabled: !hasPrev,
      },
    },
  };
};
