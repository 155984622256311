import { getTimeUnitTextAsFloat, Unit } from "@hireroo/formatter/time";
import { useTranslation } from "@hireroo/i18n";
import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { TooltipProps } from "recharts";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";

export type CustomTooltipProps = {};
type DataValue = {
  key: string;
  value: number;
};

const StyledWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  border: "none",
}));

const TargetDataName = new Set<string>(["label-min", "label-med", "label-max"]);

const useNameLabelMap = (): Record<string, string> => {
  const { t } = useTranslation();
  return {
    "label-max": t("最大値"),
    "label-med": t("中央値"),
    "label-min": t("最小値"),
  };
};

const useCorrectionLabel = (): Record<string, string> => {
  const { t } = useTranslation();
  return {
    true: t("正解"),
    false: t("不正解"),
  };
};

const useDisplayableValue = (data: TooltipProps<ValueType, NameType>) => {
  if (!data.payload || data.payload.length === 0) {
    return [];
  }

  const dataValues: DataValue[] = [];

  Object.keys(data.payload.at(0)?.payload).forEach(key => {
    const value = data.payload?.at(0)?.payload[key];
    if (TargetDataName.has(key)) {
      dataValues.push({ key, value });
    }
  });

  return dataValues.sort((a, b) => b.value - a.value);
};

const useCorrectionResult = (data: TooltipProps<ValueType, NameType>) => {
  return {
    isCorrect: data.payload?.at(0)?.payload["isCorrect"],
    isPassed: data.payload?.at(0)?.payload["isPassed"],
  };
};

const CustomTooltip: React.FC<TooltipProps<ValueType, NameType>> = props => {
  const { t } = useTranslation();
  const labelMap = useNameLabelMap();
  const data = useDisplayableValue(props);

  const correctionLabel = useCorrectionLabel();
  const correctionResult = useCorrectionResult(props);

  if (props.active) {
    return (
      <StyledWrapper className="custom-tooltip">
        <Typography className="label-correct">{`${t("解答")}: ${correctionLabel[String(correctionResult.isCorrect)]}`}</Typography>
        <Typography className="label-correct">{`${t("パフォーマンス")}: ${correctionLabel[String(correctionResult.isPassed)]}`}</Typography>
        <Divider />
        {data.map(v => (
          <Typography key={v.key} className="label">{`${labelMap[v.key ? v.key : ""]} : ${
            typeof v.value === "number" ? getTimeUnitTextAsFloat(v.value, Unit.NANOSECOND, 2) : "-"
          }`}</Typography>
        ))}
      </StyledWrapper>
    );
  }

  return null;
};

CustomTooltip.displayName = "CustomTooltip";

export default CustomTooltip;
