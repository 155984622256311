import { useTranslation } from "@hireroo/i18n";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import Box from "@mui/material/Box";
import Button, { type ButtonProps } from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import Link, { LinkProps } from "../../../../primitive/Link/Link";
import { useAssessmentResourceEditorV2Context } from "../../Context";
import EditStepper from "./parts/EditStepper/EditStepper";

const Wrapper = styled(Box)(() => ({
  height: "100%",
  width: "100%",
  position: "relative",
  display: "flex",
  flexDirection: "column",
}));

const StyledTitle = styled(Typography)(() => ({
  fontSize: 16,
  width: "100%",
  textOverflow: "ellipsis",
}));

const Heading = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 700,
  textAlign: "center",
  width: "100%",
}));

const Header = styled(Paper)(() => ({
  height: 56,
  flexShrink: 0,
  width: "100%",
  paddingLeft: "16px",
  paddingRight: "16px",
  boxShadow: "none",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

const Content = styled(Box)(() => ({
  height: "100%",
  width: "100%",
  overflowX: "hidden",
  overflowY: "auto",
}));

const ScrollableArea = styled(Box)(() => ({
  overflowX: "hidden",
  overflowY: "auto",
  width: "800px",
  margin: "0 auto",
}));

const Footer = styled(Paper)(() => ({
  height: "56px",
  width: "100%",
  paddingLeft: "16px",
  paddingRight: "16px",
  boxShadow: "none",
}));

const FooterContentWrapper = styled(Box)(() => ({
  height: "100$",
  paddingTop: "12px",
  paddingBottom: "12px",
  margin: "0 auto",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
}));

const FooterContentSeparator = styled(Box)(() => ({
  width: "100%",
}));

const StyledButton = styled(Button)(() => ({
  textTransform: "none",
  whiteSpace: "nowrap",
  flexShrink: 0,
}));

const HelpWrapper = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

const StyledHelpLink = styled(Link)(({ theme }) => ({
  textTransform: "none",
  whiteSpace: "nowrap",
  color: theme.palette.text.secondary,
  flexShrink: 0,
  marginLeft: 4,
  fontSize: 14,
  textDecoration: "none",
}));

type Mode = "CREATE" | "EDIT";

export type LayoutProps = {
  mode: Mode;
  className?: string;
  loading: boolean;
  helpLink: Pick<LinkProps, "href">;
  children: React.ReactNode;
};

const Layout: React.FC<LayoutProps> = props => {
  const { goNextStep, goPrevStep, stepName, title, submit } = useAssessmentResourceEditorV2Context();
  const { t } = useTranslation();

  const backButton: ButtonProps = {
    children: t("戻る"),
    variant: "outlined",
    color: "secondary",
    disabled: props.loading,
    onClick: () => {
      goPrevStep();
    },
  };

  const helpButton: LinkProps = {
    ...props.helpLink,
    target: "_blank",
    children: t("タレントスコアの作成・編集のヘルプ"),
  };

  const submitTextMap: Record<Mode, string> = {
    CREATE: t("作成する"),
    EDIT: t("保存する"),
  };

  const submitButton: ButtonProps = {
    children: submitTextMap[props.mode],
    variant: "contained",
    color: "primary",
    disabled: props.loading,
    onClick: () => {
      submit();
    },
  };

  const nextButton: ButtonProps = {
    children: t("次へ"),
    variant: "contained",
    color: "secondary",
    onClick: () => {
      goNextStep();
    },
  };

  return (
    <Wrapper className={props.className}>
      {stepName !== "CONFIRM" && (
        <Header elevation={2}>
          <Stack direction="row" alignItems="center" width="20%" sx={{ overflow: "hidden" }}>
            <InsertDriveFileOutlinedIcon fontSize="small" sx={{ marginRight: "10px" }} color="secondary" />
            <StyledTitle>{title}</StyledTitle>
          </Stack>
          <Box sx={{ width: "60%" }}>
            <EditStepper />
          </Box>
          {/* Spacer */}
          <Box width="20%" />
        </Header>
      )}
      {stepName === "CONFIRM" && (
        <Header elevation={2}>
          <Heading>{t("内容確認")}</Heading>
        </Header>
      )}
      <Content>
        <ScrollableArea>{props.children}</ScrollableArea>
      </Content>
      <Footer elevation={2}>
        <FooterContentWrapper>
          {stepName !== "CONFIRM" && (
            <HelpWrapper>
              <HelpOutlineOutlinedIcon fontSize="small" />
              <StyledHelpLink {...helpButton} />
            </HelpWrapper>
          )}
          <FooterContentSeparator />
          <Stack spacing={2} direction="row">
            {stepName !== "TEST_QUESTION_SETUP" && <StyledButton {...backButton} />}
            <StyledButton {...(stepName === "CONFIRM" ? submitButton : nextButton)} />
          </Stack>
        </FooterContentWrapper>
      </Footer>
    </Wrapper>
  );
};

Layout.displayName = "Layout";

export default Layout;
