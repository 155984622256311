import { SupportLanguageValue, useTranslation } from "@hireroo/i18n";
import { ErrorMessage } from "@hookform/error-message";
import { Warning } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { useAlgorithmQuestionDetailFormContext } from "../Context";

const useQuestionErrorMessages = () => {
  const { t } = useTranslation();
  const { methods } = useAlgorithmQuestionDetailFormContext();
  const { errors } = methods.formState;
  const localeMap: Record<SupportLanguageValue, string> = {
    ja: t("日本語タブの入力に不備があります。"),
    en: t("Englishタブの入力に不備があります。"),
  };

  const errorMessages: string[] = [];
  if (errors.questions) {
    const maxLength = methods.getValues("questions").length;
    for (let i = 0; i < maxLength; i++) {
      const question = errors.questions?.[i];
      if (question?.language) {
        const lang = question.language;
        //FIXME: fix type
        errorMessages.push(localeMap[lang as unknown as SupportLanguageValue]);
      }
    }
  }
  return errorMessages;
};

export type QuestionDetailsErrorMessageProps = {};

const QuestionDetailsErrorMessage: React.FC<QuestionDetailsErrorMessageProps> = _props => {
  const { t } = useTranslation();
  const { methods } = useAlgorithmQuestionDetailFormContext();
  const questionErrorMessages = useQuestionErrorMessages();
  const { errors } = methods.formState;
  return (
    <ErrorMessage
      errors={errors}
      name="questions"
      render={() => (
        <Box display="flex" flexDirection="column">
          <Box display="flex" gap={1}>
            <Warning color="error" fontSize="small" />
            <Typography color="error" variant="body2">
              {t("問題の入力に不備があります。")}
            </Typography>
          </Box>

          <Box mt={1}>
            {questionErrorMessages.map(errorMessage => (
              <Box key={errorMessage}>
                <Typography variant="caption" color="error">
                  {errorMessage}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    />
  );
};

QuestionDetailsErrorMessage.displayName = "QuestionDetailsErrorMessage";

export default QuestionDetailsErrorMessage;
