import { App, Company } from "@hireroo/app-store/essential/employee";
import { RemoteInterviewOverview } from "@hireroo/app-store/widget/e/RemoteInterviewOverview";
import * as Graphql from "@hireroo/graphql/client/urql";
import React from "react";
import { CombinedError } from "urql";

const FETCH_LIMIT = 100;

export type FetchResult = {
  isLoading: boolean;
  error?: CombinedError;
};

export type FetchTagsArgs = {};

export const useFetchTags = (_args: FetchTagsArgs): FetchResult => {
  const appStatus = App.useStatus();
  const companyId = Company.useStrictActiveCompanyId();
  const [result] = Graphql.useListRemoteTagsForRemoteInterviewOverviewQuery({
    variables: {
      companyId: companyId,
      size: FETCH_LIMIT,
      // TODO: please change when create pagination.
      withCount: false,
      cursorSeconds: null,
    },
    pause: appStatus !== "INITIALIZED",
  });

  React.useEffect(() => {
    if (result.data?.remoteTagsByCompanyId?.tags) {
      RemoteInterviewOverview.updateTags(result.data.remoteTagsByCompanyId.tags.map(t => t.name));
    }
  }, [result.data]);

  return {
    isLoading: result.fetching || appStatus !== "INITIALIZED",
    error: result.error,
  };
};
