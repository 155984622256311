import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination, { TablePaginationProps } from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import React from "react";

import OrderHistoryTableRow, { OrderHistoryTableRowProps } from "./parts/OrderHistoryTableRow/OrderHistoryTableRow";
import SelectBox, { SelectBoxProps } from "./parts/SelectBox/SelectBox";

const HeadingWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: 40,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
}));

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.text.primary,
  fontWeight: 700,
}));

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 12,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
}));

const StyledTableHeaderRow = styled(TableRow)(({ theme }) => ({
  width: "100%",
  height: 40,
  wordBreak: "keep-all",
  borderTop: `1px solid ${theme.palette["Secondary/Shades"].p12}`,
  borderBottom: `1px solid ${theme.palette["Secondary/Shades"].p12}`,
  "& .MuiTableCell-root": {
    borderColor: theme.palette["Secondary/Shades"].p12,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  width: "100%",
  height: 56,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  borderBottom: theme.palette["Secondary/Shades"].p12,
  "& .MuiTableCell-root": {
    borderColor: theme.palette["Secondary/Shades"].p12,
  },
}));

export type OrderHistoryProps = {
  rows: OrderHistoryTableRowProps[];
  selectBox: SelectBoxProps;
  pagination: Pick<TablePaginationProps, "page" | "rowsPerPage" | "count" | "onPageChange" | "rowsPerPageOptions" | "onRowsPerPageChange">;
};

const OrderHistory: React.FC<OrderHistoryProps> = props => {
  const { t } = useTranslation();

  const paginationProps: TablePaginationProps = {
    ...props.pagination,
    labelRowsPerPage: `${t("ページあたりの行数")}:`,
    SelectProps: {
      native: true,
    },
  };

  const isEmpty = props.rows.length === 0;
  const EmptyTableRow = (
    <StyledTableRow>
      <TableCell colSpan={5}>
        <Typography variant="body2" noWrap sx={{ textAlign: "center", color: "text.secondary" }}>
          {t("現在、表示可能な注文履歴はありません。")}
        </Typography>
      </TableCell>
    </StyledTableRow>
  );
  return (
    <Box>
      <HeadingWrapper mb={1.5}>
        <Heading>{t("注文履歴")}</Heading>
        <SelectBox {...props.selectBox} />
      </HeadingWrapper>
      <TableContainer>
        <Table>
          <TableHead>
            <StyledTableHeaderRow key={"score-table-header-row"}>
              <StyledTableHeaderCell align="left">{t("注文日")}</StyledTableHeaderCell>
              <StyledTableHeaderCell align="left">{t("項目")}</StyledTableHeaderCell>
              <StyledTableHeaderCell align="left">{t("単価（税抜）")}</StyledTableHeaderCell>
              <StyledTableHeaderCell align="left" sx={{ width: 24 }}>
                {t("個数")}
              </StyledTableHeaderCell>
              <StyledTableHeaderCell align="left">{t("合計金額（税抜）")}</StyledTableHeaderCell>
              <StyledTableHeaderCell align="left">{t("説明")}</StyledTableHeaderCell>
            </StyledTableHeaderRow>
          </TableHead>
          <TableBody>
            {props.rows.map(row => (
              <OrderHistoryTableRow key={row.itemId} {...row} />
            ))}
            {isEmpty && EmptyTableRow}
          </TableBody>
        </Table>
        <Box display="flex" justifyContent="flex-end" mt={4}>
          <TablePagination component={"div"} {...paginationProps} />
        </Box>
      </TableContainer>
    </Box>
  );
};

OrderHistory.displayName = "OrderHistory";

export default OrderHistory;
