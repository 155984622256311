import * as Graphql from "@hireroo/graphql/client/urql";
import { ScreeningSearchForm } from "@hireroo/validator";

export type SpotTags = Graphql.SpotTagByCompanyIdForScreeningsFragment;
export type Filters = Graphql.ScreeningsByCompanyIdFilters;

export type ListFetchingStatus = "STOP" | "FETCHING" | "FETCHED";

export type ScreeningId = string;
export type Screening = Graphql.ScreeningForScreeningsFragment;
export type DialogStatus = `TAG_OPEN_${ScreeningId}` | `ACTIVATE_OPEN_${ScreeningId}` | `DELETE_OPEN_${ScreeningId}` | "DELETES_OPEN" | "CLOSE";

export type ListRes = Graphql.ListScreeningsByCompanyIdForScreeningsQuery;

export type ScreeningsByCompanyIdResponse = Graphql.ScreeningsByCompanyIdResponseForScreeningsFragment;

export type SearchFilter = ScreeningSearchForm.ScreeningSearchQuerySchema;
export type SortFieldValue = ScreeningSearchForm.SortFieldValue;

export type Pager = {
  page: number;
  size: number;
  offset: number;
  sortFieldValue: SortFieldValue;
};

export const QueryKeys = {
  PAGE: "page",
  SIZE: "size",
  ACTIVATE_STATUS: "activateStatus",
  NAME: "name",
  TAG: "tag",
  SORT: "sorting",
} as const;

export type QueryType = (typeof QueryKeys)[keyof typeof QueryKeys];

export type State = {
  spotTags: SpotTags | null;
  tagRefresh: (() => void) | null;

  currentSearchFilter: SearchFilter | null;
  res: ScreeningsByCompanyIdResponse | null;
  pager: Pager;

  listFetchingStatus: ListFetchingStatus;
  dialogStatus: DialogStatus;
  screeningMap: Map<ScreeningId, Screening>;
  selectedScreeningIds: ScreeningId[];
  screeningIds: ScreeningId[];

  /**
   * refresh key to initialize list state
   * This is used to control the refresh timing of the list.
   */
  refreshKey: string;
};
