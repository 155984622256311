import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import { styled } from "@mui/material/styles";
import * as React from "react";

import SymbolButton from "../../primitive/Button/SymbolButton/SymbolButton";
import MenuItemWithTooltip, { MenuItemWithTooltipProps } from "./parts/MenuItemWithTooltip/MenuItemWithTooltip";

const StyledMenu = styled(Menu)(({ theme }) => ({
  ".MuiPaper-root": {
    borderRadius: theme.shape.borderRadius * 4,
  },
}));

export type HelpMenuProps = {
  options: MenuItemWithTooltipProps[];
};

const HelpMenu: React.FC<HelpMenuProps> = props => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      {/*id is used in tutorial*/}
      <SymbolButton onClick={handleClick} id="help-button">
        <HelpOutlineOutlinedIcon color="secondary" />
      </SymbolButton>
      <StyledMenu open={Boolean(anchorEl)} onClose={handleClose} anchorEl={anchorEl}>
        {props.options.map((option, index) => (
          <MenuItemWithTooltip
            {...option}
            key={`menu-${index}`}
            role={`menu${option.text}`}
            onClick={event => {
              option.onClick?.(event);
              handleClose();
            }}
            divider={index !== props.options.length - 1}
          />
        ))}
      </StyledMenu>
    </Box>
  );
};

HelpMenu.displayName = "HelpMenu";

export default HelpMenu;
