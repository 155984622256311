import { useTranslation } from "@hireroo/i18n";
import LinkOutlined from "@mui/icons-material/LinkOutlined";
import FilledInput, { FilledInputProps } from "@mui/material/FilledInput";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";
import CopyToClipboard from "react-copy-to-clipboard";

import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";

const StyledTextField = styled(FilledInput)(({ theme }) => ({
  ".MuiInputBase-input": {
    borderRadius: "4px 0px 0px 4px",
    border: "1px dashed",
    borderColor: theme.palette.divider,
    // TODO: fix style
    backgroundColor: theme.palette["Gray/Shades"].p12,
    paddingTop: "8px",
    paddingBottom: "8px",
    underline: {
      "&&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
  },
}));

const StyledIconButtonWithTooltip = styled(IconButtonWithTooltip)(({ theme }) => ({
  borderRadius: "0px 4px 4px 0px",
  border: "1px solid",
  borderColor: theme.palette.secondary.main,
  alignItems: "center",
  alignSelf: "stretch",
  width: "40px",
  height: "40px",
}));

export type CopyTextFieldProps = {
  text: string;
  onCopy?: () => void;
};

const CopyTextField: React.FC<CopyTextFieldProps> = props => {
  const { t } = useTranslation();
  const textField: FilledInputProps = {
    value: props.text,
    disableUnderline: true,
    disabled: true,
    size: "small",
  };
  const handleCopy = () => {
    props.onCopy?.();
  };
  const copyButton: IconButtonWithTooltipProps = {
    onClick: () => {
      handleCopy();
    },
    children: <LinkOutlined color="secondary" />,
    title: t("コピー"),
  };
  return (
    <CopyToClipboard text={props.text} onCopy={handleCopy}>
      <Stack direction="row">
        <StyledTextField {...textField} />
        <StyledIconButtonWithTooltip {...copyButton} />
      </Stack>
    </CopyToClipboard>
  );
};

CopyTextField.displayName = "CopyTextField";

export default CopyTextField;
