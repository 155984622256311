import type * as Graphql from "@hireroo/graphql/client/urql";
import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type EntryAssessmentContainerProps = {
  exam: Graphql.ExamForEntryAssessmentsIdFragment;
};

const EntryAssessmentContainer: React.FC<EntryAssessmentContainerProps> = props => {
  const entryAssessmentProps = useGenerateProps({
    exam: props.exam,
  });
  return (
    <ErrorBoundary>
      <Pages.EntryAssessment {...entryAssessmentProps} />
    </ErrorBoundary>
  );
};

EntryAssessmentContainer.displayName = "EntryAssessmentContainer";

export default withErrorBoundary(EntryAssessmentContainer, {});
