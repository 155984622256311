export const ClassNames = {
  WIDGET_NODE_CLASS_NAME: "here-monaco-cursor-widget",
  TOOLTIP_NODE_CLASS_NAME: "here-monaco-cursor-widget-message",
  OVERLAY_MESSAGE: "here-monaco-editor-overlaymessage",
};

export const generateCursorId = (suffix: string): string => {
  return `monaco-remote-cursor-${suffix}`;
};

export const generateDecorationName = (suffix: string): string => {
  return `monaco-editor-selection-${suffix}`;
};
