import Editor, { type EditorProps } from "@monaco-editor/react";
import * as monaco from "monaco-editor";
import * as React from "react";

import * as SwapTheme from "../../helpers/swap-theme";
import { getTheme, Theme } from "../../helpers/theme";

export type IStandaloneCodeEditor = monaco.editor.IStandaloneCodeEditor;
export type IIdentifiedSingleEditOperation = monaco.editor.IIdentifiedSingleEditOperation;
export type ITextModel = monaco.editor.ITextModel;

export type CodeEditorProps = EditorProps & {
  theme?: Theme;
};

/**
 * @deprecated Please use packages/code-editor/react/v2/CodeEditor/CodeEditor.tsx
 */
const CodeEditor: React.FC<CodeEditorProps> = props => {
  const { beforeMount, onMount, value, language } = props;
  const { theme, themeData } = getTheme(props.theme || "monokai");
  const monacoRef = React.useRef<typeof monaco | null>(null);
  const handleBeforeMount = React.useCallback<Exclude<CodeEditorProps["beforeMount"], undefined>>(
    monaco => {
      SwapTheme.hideVscodeTheme();
      monaco.editor.defineTheme(theme, themeData as monaco.editor.IStandaloneThemeData);
      monacoRef.current = monaco;
      beforeMount?.(monaco);
    },
    [beforeMount, theme, themeData],
  );
  const handleOnMount = React.useCallback<Exclude<CodeEditorProps["onMount"], undefined>>(
    (editor, monaco) => {
      if (value) {
        editor.setValue(value);
      }
      const model = editor.getModel();
      if (language && model) {
        /**
         * Set language more reliably
         */
        monaco.editor.setModelLanguage(model, language);
      }
      onMount?.(editor, monaco);
    },
    [onMount, value, language],
  );
  React.useEffect(() => {
    return () => {
      /**
       * DO NOT REMOVE
       * "@monaco-editor/react"用のthemeをアンマウント時にリセットする
       */
      monacoRef.current?.editor.defineTheme(theme, {
        base: "vs-dark",
        inherit: false,
        rules: [],
        colors: {},
      });
    };
  }, [theme]);
  return (
    <Editor
      {...props}
      theme={theme}
      beforeMount={handleBeforeMount}
      onMount={handleOnMount}
      options={{
        fontSize: 14,
        minimap: {
          enabled: false,
        },
        ...props.options,
      }}
    />
  );
};

CodeEditor.displayName = "CodeEditor";

export default CodeEditor;
