import { useTranslation } from "@hireroo/i18n";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DialogContent from "@mui/material/DialogContent";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";

import CopyTextField, { CopyTextFieldProps } from "../../../../modules/CopyTextField/CopyTextField";
import BaseDialog, { BaseDialogProps } from "../../../../primitive/BaseDialog/BaseDialog";
import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "../../../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";

export type SonarIntegrationTableRowProps = {
  /** sonar ATS token **/
  sonarIntegrationId: string;
  title: string;
  createdAt: string;
  copyButton: Omit<CopyTextFieldProps, "text">;
  loading: boolean;
  onDelete: () => void;
};

const SonarIntegrationTableRow: React.FC<SonarIntegrationTableRowProps> = props => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const tokenCopyField: CopyTextFieldProps = {
    ...props.copyButton,
    text: props.sonarIntegrationId,
  };

  const deleteButton: IconButtonWithTooltipProps = {
    onClick: () => {
      setOpen(true);
    },
    title: t("削除する"),
    children: <DeleteOutlineIcon fontSize="small" />,
  };
  const handleClose = () => {
    setOpen(false);
  };
  const deleteDialog: BaseDialogProps = {
    title: t("トークンを削除します。よろしいですか?"),
    open: open,
    yesButton: {
      disabled: props.loading,
      color: "error",
      children: t("削除"),
      onClick: () => {
        props.onDelete();
        handleClose();
      },
    },
    noButton: {
      disabled: props.loading,
      children: t("キャンセル"),
      onClick: () => {
        handleClose();
      },
    },
  };
  return (
    <TableRow>
      <TableCell>
        <Typography fontSize={14}>{props.title}</Typography>
      </TableCell>
      <TableCell>
        <CopyTextField {...tokenCopyField} />
      </TableCell>
      <TableCell align="left">
        <Typography fontSize={14}>{props.createdAt}</Typography>
      </TableCell>
      <TableCell align="right" width="5%">
        <IconButtonWithTooltip {...deleteButton} />
      </TableCell>
      <BaseDialog {...deleteDialog}>
        <DialogContent role="content" sx={{ p: 0 }}>
          <Typography variant="subtitle2" mt={1} color="textSecondary">
            {t("対象トークンのコーディングテスト名")}
          </Typography>
          <Typography mt={1}>{props.title}</Typography>
          <Typography mt={1} variant="body2">
            {t("一度削除すると元に戻すことはできません。")}
          </Typography>
        </DialogContent>
      </BaseDialog>
    </TableRow>
  );
};

SonarIntegrationTableRow.displayName = "SonarIntegrationTableRow";

export default SonarIntegrationTableRow;
