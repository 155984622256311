import { GraphQLClient } from "graphql-request";

import * as GraphqlRequest from "./graphql-request";

let client: GraphQLClient | undefined;

type GetRefreshAuthToken = () => Promise<string | null | undefined>;

type InitializeOptions = {
  appVersion: string;
  authToken: string;
  getOrRefreshAuthTokenExpire: GetRefreshAuthToken;
};

export const initialize = (url: string, options: InitializeOptions) => {
  client = new GraphQLClient(url, {
    headers: {
      Authorization: `Bearer ${options.authToken}`,
      "app-version": options.appVersion,
    },
    requestMiddleware: async request => {
      const authToken = await options.getOrRefreshAuthTokenExpire();
      if (authToken) {
        /**
         * The type definition of `request.headers` will be the same as the type definition of `headers` used in initializing `GraphQLClient`.
         */
        (request.headers as Record<string, string>)["Authorization"] = `Bearer ${authToken}`;
      }

      return request;
    },
  });
};

export const getGraphqlClient = (): GraphqlRequest.Sdk => {
  if (!client) {
    throw new Error("Not yet initialized");
  }
  return GraphqlRequest.getSdk(client);
};

export type TemporallyGraphqlClientOptions = {
  authToken?: string;
  appVersion: string;
};

export const createTemporallyGraphqlClient = (url: string, options: TemporallyGraphqlClientOptions) => {
  const headers: Record<string, string> = {};
  if (options.authToken) {
    headers["Authorization"] = `Bearer ${options.authToken}`;
  }
  headers["app-version"] = options.appVersion;
  const client = new GraphQLClient(url, {
    headers: headers,
  });
  return GraphqlRequest.getSdk(client);
};
