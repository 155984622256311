import { useTranslation } from "@hireroo/i18n";
import Group from "@mui/icons-material/Group";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Chip, { ChipProps } from "@mui/material/Chip";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import TextLinkify from "../../../../primitive/TextLinkify/TextLinkify";
import ListContent, { ListContentProps } from "../ListContent/ListContent";

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette["Background/Paper"].p2,
  borderRadius: "16px",
}));

const StyledBodyTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const StyledAlert = styled(Alert)(() => ({
  fontSize: 16,
  lineHeight: "150%",
  ".MuiAlert-message": {
    paddingTop: 0,
    paddingBottom: 0,
  },
  ".MuiAlert-icon": {
    paddingTop: 0,
    paddingBottom: 0,
    alignItems: "center",
  },
}));
export type MemberAndGroupChip = ChipProps & {
  photoUrl?: string;
  kind: "MEMBER" | "GROUP";
};

export type AssessmentSummaryProps = {
  terms: {
    startDate: string;
    endDate: string;
  };
  nextTerms?: {
    startDate: string;
    endDate: string;
  };
  completedDate: string;
  status: string;
  timeLimit: string;
  isPublic: boolean;
  authorizedMemberAndGroups: MemberAndGroupChip[];
  messageForTalent?: string;
};

const AssessmentSummary: React.FC<AssessmentSummaryProps> = props => {
  const { t } = useTranslation();
  const listContents: ListContentProps[] = [
    {
      title: t("実施期間"),
      children: (
        <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center">
          <StyledBodyTypography variant="subtitle1" noWrap>{`${props.terms.startDate} - ${props.terms.endDate}`}</StyledBodyTypography>
          {props.nextTerms && (
            <StyledAlert severity="info" variant="standard">
              {`${t("次回")}: ${props.nextTerms.startDate} - ${props.nextTerms.endDate}`}
            </StyledAlert>
          )}
        </Stack>
      ),
    },
    {
      title: t("実施日時"),
      children: <StyledBodyTypography>{props.completedDate}</StyledBodyTypography>,
    },
    {
      title: t("ステータス"),
      children: <StyledBodyTypography>{props.status}</StyledBodyTypography>,
    },
    props.messageForTalent && {
      title: t("タレントへのメッセージ"),
      children: (
        <StyledBodyTypography sx={{ whiteSpace: "pre-wrap" }}>
          <TextLinkify>{props.messageForTalent}</TextLinkify>
        </StyledBodyTypography>
      ),
    },
    {
      title: t("制限時間"),
      children: <StyledBodyTypography>{props.timeLimit}</StyledBodyTypography>,
    },
    {
      title: t("閲覧権限"),
      children: (
        <Box display="flex" alignItems="flex-start">
          {props.isPublic ? (
            <StyledBodyTypography>{t("メンバー全員に許可する。")}</StyledBodyTypography>
          ) : (
            <Stack direction="row" sx={{ flexWrap: "wrap", gap: "8px" }}>
              {props.authorizedMemberAndGroups.map(authorized => {
                const { kind, photoUrl, ...rest } = authorized;
                return <Chip key={authorized.key} {...rest} avatar={kind === "GROUP" ? <Group /> : <Avatar src={photoUrl} />} />;
              })}
            </Stack>
          )}
        </Box>
      ),
    },
  ].flatMap(v => (v ? [v] : []));
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
          {t("テスト情報")}
        </Typography>
      </Box>
      <StyledBox px={2} py={1}>
        <List component="nav" aria-label="coding-test-summary">
          {listContents.map((content, index) => (
            <ListContent key={content.title} {...content} divider={index !== listContents.length - 1} />
          ))}
        </List>
      </StyledBox>
    </Box>
  );
};

AssessmentSummary.displayName = "AssessmentSummary";

export default AssessmentSummary;
