import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";

import Split, { SplitProps } from "../../../primitive/Split/Split";
import QuestionAndAppealMessageSideBar, {
  QuestionAndAppealMessageSideBarProps,
} from "../QuestionAndAppealMessageSideBar/QuestionAndAppealMessageSideBar";

type LayoutKind = "HIDE_SIDE" | "NORMAL";

export type QuestionAndSolutionLayoutProps = {
  sidebar: QuestionAndAppealMessageSideBarProps;
  layoutKind: LayoutKind;
  items: SplitProps["items"];
};

const SidebarWrapper = styled(Box)`
  height: 100%;
`;

const StyledSplit = styled(Split)`
  width: 100%;
`;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
  width: 100%;
  max-height: 100vh;
`;

const SplitParams = {
  splitId: "ChallengeCodingEditor",
  Contents: {
    SideBar: {
      id: "SideBar",
    },
  },
};

const QuestionAndSolutionLayout: React.FC<QuestionAndSolutionLayoutProps> = props => {
  const splitItems: (SplitProps["items"][0] | false)[] = [
    {
      id: SplitParams.Contents.SideBar.id,
      Content: (
        <SidebarWrapper key="sidebar">
          <QuestionAndAppealMessageSideBar {...props.sidebar} />
        </SidebarWrapper>
      ),
      size: {
        value: 400,
        unit: "px",
      },
      minimizedSize: {
        value: 0,
        unit: "px",
      },
      expandedSize: {
        value: 400,
        unit: "px",
      },
      sidePane: {
        className: "tutorial-pane",
      },
    },
    ...props.items,
  ];
  const splitProps: SplitProps = {
    splitId: SplitParams.splitId,
    splitDirection: "VERTICAL",
    items: splitItems.flatMap(v => (v ? [v] : [])),
  };
  return (
    <Wrapper>
      <StyledSplit {...splitProps} />
    </Wrapper>
  );
};

QuestionAndSolutionLayout.displayName = "QuestionAndSolutionLayout";

export default QuestionAndSolutionLayout;
