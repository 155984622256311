import { getNearestIndex, useSystemDesignRevisions } from "@hireroo/app-helper/hooks";
import { SystemDesignTestReport } from "@hireroo/app-store/view-domain/SystemDesignTestReport";
import * as Time from "@hireroo/formatter/time";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { CATEGORY_REMOVED_VERSION, LATEST_VERSION } from "@hireroo/system-design/constants/flowChart";
import { FlowElement } from "@hireroo/system-design/features";
import { elementsFromFirebase, viewboxFromElements } from "@hireroo/system-design/helpers/flowChart";
import { elementsFromFirebaseV1 } from "@hireroo/system-design/helpers/v1";
import * as React from "react";
import semver from "semver";

export type GenerateSystemDesignPlaybackPropsArgs = {
  mode: "PLAYBACK" | "SUBMIT_RESULT";
  entityId: number;
};

export const useGenerateProps = (args: GenerateSystemDesignPlaybackPropsArgs): Widget.SystemDesignPlaybackProps => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const hooks = SystemDesignTestReport.useCreateSystemDesignHooks(args.entityId);
  const { result: submittedFlowChart } = hooks.useCurrentSelectedSubmissionSnapshot();
  const { result: initialFlowChart } = hooks.useInitialFlowChart();
  const systemDesignId = hooks.useSystemDesignId();
  const questionId = hooks.useQuestionId();
  const [revisionIndex, setRevisionIndex] = React.useState<number>(0);
  const componentType = submittedFlowChart.componentType;
  const version = submittedFlowChart.version === "" ? LATEST_VERSION : submittedFlowChart.version;
  const [actions, revisions, revisionsReady] = useSystemDesignRevisions(systemDesignId, questionId, componentType, version);
  const lastRevisionIndex = revisions.length - 1;
  const lastElements = React.useMemo(() => {
    if (revisionsReady) {
      const { elements, elementIds } =
        // TODO: We won't support old version history forever. Remove v1 converter instead disable to show playback for old version
        semver.gte(version, CATEGORY_REMOVED_VERSION)
          ? elementsFromFirebase(revisions, componentType, initialFlowChart.elements)
          : elementsFromFirebaseV1(revisions, componentType, initialFlowChart.elements);
      return elementIds.map(id => elements[id]);
    }
    return [];
  }, [componentType, initialFlowChart.elements, revisions, revisionsReady, version]);
  const [elements, setElements] = React.useState<FlowElement[]>([]);

  const [alreadyTouchSlider, setAlreadyTouchSlider] = React.useState(false);

  /**
   * Since Playback is in its final state when initially displayed, specify the last Revision Index.
   */
  React.useEffect(() => {
    if (revisionsReady) {
      setRevisionIndex(lastRevisionIndex);
    }
  }, [revisionsReady, lastRevisionIndex]);

  const marks = React.useMemo<{ value: number; label: string }[]>(() => {
    const res: { value: number; label: string }[] = [];
    let hintNum = 1;
    let subNum = 1;

    actions.forEach(action => {
      // hint is used at time i.
      if (action.s === "useh") {
        res.push({ value: getNearestIndex(action, revisions), label: `${t("ヒント")}${hintNum}` });
        hintNum++;
      }

      // submit at time i.
      if (action.s === "subq") {
        res.push({ value: getNearestIndex(action, revisions), label: `${t("提出")}${subNum}` });
        subNum++;
      }
    });
    return res;
  }, [actions, revisions, t]);

  const handlePlaybackValue = React.useCallback(
    (newRevisionIndex: number) => {
      if (!revisionsReady) {
        return;
      }
      setAlreadyTouchSlider(true);
      const { elements, elementIds } =
        // TODO: We won't support old version history forever. Remove v1 converter instead disable to show playback for old version
        semver.gte(version, CATEGORY_REMOVED_VERSION)
          ? elementsFromFirebase(revisions, componentType, initialFlowChart.elements, newRevisionIndex)
          : elementsFromFirebaseV1(revisions, componentType, initialFlowChart.elements, newRevisionIndex);
      const currentElements = elementIds.map(id => elements[id]);
      setElements(currentElements);
      setRevisionIndex(newRevisionIndex);
    },
    [revisionsReady, version, initialFlowChart.elements, revisions, componentType],
  );

  const remainTime = React.useMemo(() => {
    const endTime = revisions[lastRevisionIndex]?.t;
    const startTime = revisions[0]?.t;

    return Time.elapsedTimeFormat(endTime - startTime);
  }, [lastRevisionIndex, revisions]);

  const passedTime = React.useMemo(() => {
    const startTime = revisions[0]?.t;
    const currentTime = revisions[revisionIndex]?.t;
    return Time.elapsedTimeFormat(currentTime - startTime);
  }, [revisions, revisionIndex]);

  const playbackToolbarProps = React.useMemo((): Exclude<Widget.SystemDesignPlaybackProps["playbackToolbar"], undefined> => {
    return {
      value: revisionIndex,
      onChangePlaybackValue: handlePlaybackValue,
      slider: {
        min: 0,
        max: lastRevisionIndex,
        marks: marks,
      },
      enableAutoplay: true,
      screenButton: {
        onClick: () => {
          setOpen(true);
        },
      },
      remainTime: remainTime,
      passedTime: passedTime,
    };
  }, [revisionIndex, handlePlaybackValue, lastRevisionIndex, marks, remainTime, passedTime]);

  const staticFlowChart = React.useMemo<Widget.SystemDesignPlaybackProps["staticFlowChart"]>(() => {
    const showElements = (() => {
      if (args.mode === "PLAYBACK") {
        return alreadyTouchSlider ? elements : lastElements;
      }
      /**
       * If the mode is "SUBMIT_RESULT", display the submitted FlowChart.
       */
      return submittedFlowChart.elements;
    })();
    return {
      ...initialFlowChart,
      componentType,
      elements: showElements,
    };
  }, [initialFlowChart, componentType, lastElements, args.mode, alreadyTouchSlider, elements, submittedFlowChart]);

  const status = React.useMemo((): Widget.SystemDesignPlaybackProps["status"] => {
    if (!revisionsReady) {
      return "LOADING";
    }
    if (revisions.length === 0 && args.mode === "PLAYBACK") {
      return "NOT_FOUND";
    }
    return "READY";
  }, [args.mode, revisions.length, revisionsReady]);

  return {
    status: status,
    staticFlowChart: staticFlowChart,
    playbackToolbar: args.mode === "PLAYBACK" ? playbackToolbarProps : undefined,
    systemDesignPlaybackDialog:
      args.mode === "PLAYBACK"
        ? {
            open: open,
            onClose: () => {
              setOpen(false);
            },
            staticFlowChart: {
              ...staticFlowChart,
              viewbox: viewboxFromElements(lastElements, (window.innerHeight * 0.8) / (window.innerWidth * 0.8)),
            },
            playbackToolbar: {
              ...playbackToolbarProps,
              screenButton: {
                onClick: () => {
                  setOpen(false);
                },
              },
            },
          }
        : undefined,
  };
};
