import { ValidateAlgorithmFunctionVariantInput } from "@hireroo/validator";
import Box from "@mui/material/Box";
import * as React from "react";

import { useChallengeCodingEditorContext } from "../../../Context";
import AlgorithmClassInput, { AlgorithmClassInputProps } from "../AlgorithmClassInput/AlgorithmClassInput";
import AlgorithmInput, { AlgorithmInputProps } from "../AlgorithmInput/AlgorithmInput";
import DatabaseInput, { DatabaseInputProps } from "../DatabaseInput/DatabaseInput";

export type IDEConsoleInputProps = {
  consoleValue: string[];
  readonly?: boolean;
  errorMap?: Record<string, boolean>;
  errorCode?: string;
  handleErrorChange?: (key: string, hasError: boolean) => void;
  consoleValueDidChange?: (index: number, input: string) => void;
  inputStyle?: React.CSSProperties;
};

const IDEConsoleInput: React.FC<IDEConsoleInputProps> = props => {
  const { contextProps } = useChallengeCodingEditorContext();
  const { handleErrorChange, consoleValueDidChange } = props;

  const handleConsoleValueDidChangeForAlgorithm = (index: number, text: string, type?: string) => {
    if (consoleValueDidChange) {
      consoleValueDidChange(index, text);
      const isValid = ValidateAlgorithmFunctionVariantInput.isAlgorithmTestCaseValid(text, type ?? "");
      // clear on change
      handleErrorChange?.(String(index), !isValid);
    }
  };

  const handleConsoleValueDidChangeForAlgorithmClass = (index: number, text: string) => {
    if (consoleValueDidChange) {
      consoleValueDidChange(index, text);
    }
  };

  const handleConsoleValueDidChangeForDatabase = (index: number, text: string) => {
    if (consoleValueDidChange) {
      consoleValueDidChange(index, text);
    }
  };

  const algorithmInputProps: Omit<AlgorithmInputProps, "inputs"> = {
    consoleValue: props.consoleValue,
    readonly: props.readonly,
    errorMap: props.errorMap,
    inputStyle: props.inputStyle,
    consoleValueChange: handleConsoleValueDidChangeForAlgorithm,
  };

  const algorithmClassInputProps: Omit<AlgorithmClassInputProps, "methods" | "classConstructor"> = {
    consoleValue: props.consoleValue,
    readonly: props.readonly,
    errorCode: props.errorCode,
    inputStyle: props.inputStyle,
    consoleValueChange: handleConsoleValueDidChangeForAlgorithmClass,
  };

  const databaseInputProps: Omit<DatabaseInputProps, "tables"> = {
    consoleValue: props.consoleValue,
    readonly: props.readonly,
    errorMap: props.errorMap,
    consoleValueChange: handleConsoleValueDidChangeForDatabase,
    handleErrorChange: handleErrorChange,
  };

  return (
    <Box bgcolor="inherit" height="100%">
      {contextProps.signatureProps.variant === "ALGORITHM" && (
        <AlgorithmInput {...algorithmInputProps} inputs={contextProps.signatureProps.signature.inputs} />
      )}
      {contextProps.signatureProps.variant === "CLASS" && <AlgorithmClassInput {...algorithmClassInputProps} />}
      {contextProps.signatureProps.variant === "DATABASE" && (
        <DatabaseInput {...databaseInputProps} tables={contextProps.signatureProps.signature.tables} />
      )}
    </Box>
  );
};

IDEConsoleInput.displayName = "IDEConsoleInput";

export default IDEConsoleInput;
