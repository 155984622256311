import { useTranslation } from "@hireroo/i18n";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Button from "@mui/material/Button";
import Paper, { PaperProps } from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { MAX_SCALE, MIN_SCALE } from "../../../constants/flowChart";
import ControlButtonGroup from "../primitive/ControlButtonGroup/ControlButtonGroup";
import SquareButton from "../primitive/SquareButton/SquareButton";

export type ScaleControlPaperProps = {
  scale: number;
  handleZoomIn: () => void;
  handleZoomOut: () => void;
  paperProps?: PaperProps;
};

const StyledPaper = styled(Paper)`
  display: inline-block;
  height: 32px;
`;

const ReadOnlyButton = styled(Button)(() => ({
  height: 32,
  width: 56,
  cursor: "default",
  "&:hover": {
    cursor: "default",
    border: "none",
    backgroundColor: "inherit",
  },
}));

const ScaleControlPaper: React.FC<ScaleControlPaperProps> = props => {
  const { t } = useTranslation();

  return (
    <StyledPaper elevation={1} {...props.paperProps}>
      <ControlButtonGroup variant="outlined" aria-label="outlined button group">
        <SquareButton disabled={props.scale === MIN_SCALE} onClick={props.handleZoomOut} tooltipText={t("縮小")}>
          <RemoveIcon />
        </SquareButton>
        <ReadOnlyButton variant="outlined" color="outline-only" aria-readonly="true" disableRipple>
          <Typography component="span">{Math.round(props.scale * 10) * 10}%</Typography>
        </ReadOnlyButton>
        <SquareButton disabled={props.scale === MAX_SCALE} onClick={props.handleZoomIn} tooltipText={t("拡大")}>
          <AddIcon />
        </SquareButton>
      </ControlButtonGroup>
    </StyledPaper>
  );
};

ScaleControlPaper.displayName = "ScaleControlPaper";

export default ScaleControlPaper;
