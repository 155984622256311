import { RemotesId } from "@hireroo/app-store/page/c/remotes_id";
import { useLanguageCode } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Pages } from "@hireroo/presentation";

export const useGenerateLeftSidePanelProps = (): Pages.RemoteInterviewForCandidateProps["leftSidePanel"] => {
  const lang = useLanguageCode();
  const question = RemotesId.useNormalizedCurrentSession();

  return {
    questionSection: {
      title: resolveLanguage(question || {}, lang, "title"),
      description: resolveLanguage(question || {}, lang, "description"),
      /**
       * Always `false` as the user does not need to know if the issue has been archived during testing
       */
      isArchived: false,
    },
  };
};
