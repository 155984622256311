import { SupportLanguage, SupportLanguageValue, useChangeLanguage, useCurrentLanguage } from "@hireroo/i18n";
import * as React from "react";

import DropDownMenu, { DropDownMenuProps } from "../../primitive/DropDownMenu/DropDownMenu";

export type LanguageDropDownMenuProps = {
  onChangeLanguage?: (language: SupportLanguageValue) => void;
};

const LanguageDropDownMenu: React.FC<LanguageDropDownMenuProps> = props => {
  const changeLanguage = useChangeLanguage();
  const defaultLanguage = useCurrentLanguage();
  const dropDown: DropDownMenuProps = {
    options: [
      {
        text: "日本語",
        value: SupportLanguage.JA,
        icon: "LANGUAGE",
        onClick: () => {
          changeLanguage(SupportLanguage.JA);
          props.onChangeLanguage?.(SupportLanguage.JA);
        },
        default: defaultLanguage === SupportLanguage.JA,
      },
      {
        text: "English",
        value: SupportLanguage.EN,
        icon: "LANGUAGE",
        onClick: () => {
          changeLanguage(SupportLanguage.EN);
          props.onChangeLanguage?.(SupportLanguage.EN);
        },
        default: defaultLanguage === SupportLanguage.EN,
      },
    ],
    role: "language",
    menuId: "select-language-menu",
    button: {
      fullWidth: true,
      variant: "outlined",
      color: "secondary",
    },
    menu: {
      sx: {},
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
    },
  };
  return <DropDownMenu {...dropDown} />;
};

LanguageDropDownMenu.displayName = "LanguageDropDownMenu";

export default LanguageDropDownMenu;
