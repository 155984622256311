import { TargetElementIdMap } from "@hireroo/app-helper/challenge";
import { scrollToContentForReportV2 } from "@hireroo/app-helper/html-element";
import { ChallengeTestReport } from "@hireroo/app-store/view-domain/ChallengeTestReport";
import { useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import * as React from "react";

import { useGenerateIpAddressSectionProps } from "./useGenerateIpAddressSectionProps";
import { useGenerateSimilarCodesProps } from "./useGenerateSimilarCodesProps";

type CheatDetectionSection = Widget.ChallengeTestReportProps["cheatDetectionSection"];

export type GenerateCheatDetectionSectionPropsArgs = {
  uniqueKey: ChallengeTestReport.UniqueKey;
  challengeId: number;
  canShowPasteAndTabStatistics: boolean;
  canShowCheatDetectionSection: boolean;
  canShowStatistic: boolean;
  onUpdateEditorMode?: (mode: "SUBMIT_RESULT" | "PLAYBACK") => void;
};

export const useGenerateCheatDetectionSectionProps = (args: GenerateCheatDetectionSectionPropsArgs): CheatDetectionSection => {
  const challengeHooks = ChallengeTestReport.useCreateChallengeHooks(args.challengeId);
  const submissionMap = challengeHooks.useSubmissionMap();
  const submissionId = challengeHooks.useCurrentSelectedSubmissionId();
  const { t } = useTranslation();
  const statistics = challengeHooks.useStatistics();
  const ipAddressSection = useGenerateIpAddressSectionProps({ challengeId: args.challengeId });
  const similarCodes = useGenerateSimilarCodesProps({ challengeId: args.challengeId });

  return React.useMemo((): CheatDetectionSection => {
    if (!args.canShowCheatDetectionSection) {
      return;
    }

    const submission = submissionMap[submissionId];
    // Similarity score which is null means that similarity score is not calculated because
    // user take a coding test before similar code is released.
    const canShowSimilarCodes = submission.similarityScore !== null;
    const canShowCopyAndPasteStatistics = args.canShowCheatDetectionSection && args.canShowPasteAndTabStatistics;
    return {
      numTabEvents: (canShowCopyAndPasteStatistics || undefined) && {
        value: `${submission.numTabEvents} ${t("回")}`,
        icon: {
          key: (submission.numTabEvents ?? 0) <= Math.round(statistics?.numTabEvents?.avg ?? 0) ? "CHECK" : "WARNING",
          title: `${t("平均離脱回数")} ${Math.round(statistics?.numTabEvents?.avg ?? 0)}`,
        },
      },
      numPasteEvents: (canShowCopyAndPasteStatistics || undefined) && {
        value: `${submission.numPasteEvents} ${t("回")}`,
        icon: {
          key: (submission.numPasteEvents ?? 0) <= Math.round(statistics?.numPasteEvents?.avg ?? 0) ? "CHECK" : "WARNING",
          title: `${t("平均ペースト数")} ${Math.round(statistics?.numPasteEvents?.avg ?? 0)}`,
        },
        onClick: args.onUpdateEditorMode
          ? () => {
              //To paste playback
              const element = document.getElementById(TargetElementIdMap.PLAYBACK_SECTION);
              if (element) {
                args.onUpdateEditorMode?.("PLAYBACK");
                scrollToContentForReportV2(TargetElementIdMap.PLAYBACK_SECTION);
              }
            }
          : undefined,
      },
      numAccessEvents: {
        value: `${submission.numAccessEvents} ${t("回")}`,
        icon: {
          /** expected number of access is 1, otherwise warn */
          key: submission.numAccessEvents === 1 ? "CHECK" : "WARNING",
          title: t("IPアドレス検知回数"),
        },
      },
      similarityScore: canShowSimilarCodes
        ? {
            value: `${submission.similarCodes.length} ${t("件")}`,
            icon: {
              key: submission.similarCodes.length > 0 ? "WARNING" : "CHECK",
              title:
                submission.similarCodes.length > 0
                  ? t("提出されたコードと類似したコードがみつかりました。")
                  : t("提出されたコードと類似したコードはみつかりませんでした。"),
            },
          }
        : undefined,
      similarCodes: canShowSimilarCodes ? similarCodes : undefined,
      ipAddressSection: ipAddressSection,
      canShowTooltip: args.canShowStatistic,
    };
  }, [ipAddressSection, statistics?.numPasteEvents?.avg, statistics?.numTabEvents?.avg, submissionId, submissionMap, t, args, similarCodes]);
};
