type Option = {
  delayTimeMilliSeconds?: number;
};
/**
 * @param destElementHtmlId No # needed in Prefix
 * @param options
 */
export const scrollToContentForReportV2 = (destElementHtmlId: string, options?: Option) => {
  const callback = () => {
    const element = document.getElementById(destElementHtmlId);
    /**
     * Specify where to scroll, since the place to scroll is not the window,
     * but the contents of a particular element.
     */
    const contentWrapperElement = document.getElementById("report-content-wrapper");
    const offset = 120; // EmployeeLayout.Height(72px) + AssessmentDetailPageHeader.Height(48px)
    if (element && contentWrapperElement) {
      contentWrapperElement.scrollTo({
        top: element.offsetTop - offset,
      });
    }
  };
  if (options?.delayTimeMilliSeconds) {
    setTimeout(() => {
      callback();
    }, options.delayTimeMilliSeconds);
    return;
  }
  callback();
};
