import { useTranslation } from "@hireroo/i18n";
import SettingsBackupRestoreOutlined from "@mui/icons-material/SettingsBackupRestoreOutlined";
import Button, { type ButtonProps } from "@mui/material/Button";
import * as React from "react";

export type SwitchInterfaceFieldProps = {
  switchButton: Pick<ButtonProps, "onClick">;
};

const SwitchInterfaceField: React.FC<SwitchInterfaceFieldProps> = props => {
  const { t } = useTranslation();

  const switchButtonProps: ButtonProps = {
    ...props.switchButton,
    startIcon: <SettingsBackupRestoreOutlined />,
    children: t("旧デザインに戻す"),
    fullWidth: true,
    color: "secondary",
    variant: "outlined",
  };
  return <Button {...switchButtonProps} />;
};

SwitchInterfaceField.displayName = "SwitchInterfaceField";

export default SwitchInterfaceField;
