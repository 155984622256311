import { state } from "./State";
import type * as Types from "./types";

export type InitializeUserFromFirebaseArgs = {
  uid: string;
};

export const clear = () => {
  state.uid = null;
  state.initializeError = null;
};

export const initializeUserFromFirebase = (args: InitializeUserFromFirebaseArgs): void => {
  state.uid = args.uid;
};

export const setError = (initializeError: Types.InitializeError) => {
  state.initializeError = initializeError;
};
