import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

import ActiveStatusChip, { ActiveStatusChipProps } from "../../../../ms-components/Spot/ActiveStatusChip/ActiveStatusChip";
import InviteCandidateDialogV2, {
  InviteCandidateDialogV2Props,
} from "../../../../ms-components/Spot/InviteCandidateDialogV2/InviteCandidateDialogV2";
import MoreButton, { MoreButtonProps } from "../../../../primitive/Button/MoreButton/MoreButton";
import AcceptanceInfo, { AcceptanceInfoProps } from "../AcceptanceInfo/AcceptanceInfo";
import EmphasizedTypography from "../EmphasizedTypography/EmphasizedTypography";

const StyledButton = styled(Button)(({ theme }) => ({
  background: "linear-gradient(90deg, #6671FD 20.52%, #5056F1 87.87%)",
  color: theme.palette.text.primary,
}));

const StyledTypography = styled(Typography)(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxHeight: "48px",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  "&:hover": {
    cursor: "pointer",
    textDecoration: "underline",
  },
}));

const WrapperStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  width: "100%",
  height: "100%",
  borderRadius: "12px",
  backgroundColor: theme.palette.background.paper,
}));

export type ItemCardProps = {
  id: string;
  title: {
    text: string;
    onClick: () => void;
  };

  deadline?: string;
  editButton: {
    onClick: () => void;
  };
  acceptanceInfo: AcceptanceInfoProps;
  notEvaluatedButton?: Pick<ButtonProps, "onClick"> & {
    count: number;
  };
  evaluatedButton?: Pick<ButtonProps, "onClick"> & {
    count: number;
  };
  inviteDialog: Omit<InviteCandidateDialogV2Props, "dialog" | "name">;
  status: ActiveStatusChipProps["status"];
};

const ItemCard: React.FC<ItemCardProps> = props => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const [open, setOpen] = React.useState(false);
  const moreButton: MoreButtonProps = {
    options: [
      {
        value: "edit",
        displayName: t("編集"),
        onClick: props.editButton.onClick,
      },
      {
        value: "invite",
        displayName: t("招待"),
        onClick: () => {
          setOpen(true);
        },
      },
    ],
    variant: "vertical",
    iconButton: {
      sx: {
        height: "24px",
        width: "24px",
      },
    },
  };

  const inviteCandidate: InviteCandidateDialogV2Props = {
    ...props.inviteDialog,
    name: props.title.text,
    dialog: {
      open,
      onClose: () => {
        setOpen(false);
      },
    },
  };

  const notEvaluatedButton: ButtonProps | undefined = props.notEvaluatedButton
    ? {
        ...props.notEvaluatedButton,
        sx: {
          height: "36px",
          px: "12px",
          py: "2px",
          textTransform: "none",
        },
        variant: "contained",
        size: "small",
        endIcon: <KeyboardArrowRightOutlinedIcon />,
        children: <EmphasizedTypography emphasizedText={props.notEvaluatedButton.count.toString()} subtext={t("未評価")} />,
      }
    : undefined;
  const evaluatedButton: ButtonProps | undefined = props.evaluatedButton
    ? {
        ...props.evaluatedButton,
        sx: {
          height: "36px",
          px: "12px",
          py: "2px",
          textTransform: "none",
        },
        variant: "text",
        color: "outline-only",
        size: "small",
        children: <EmphasizedTypography emphasizedText={props.evaluatedButton.count.toString()} subtext={t("評価済")} />,
      }
    : undefined;
  return (
    <WrapperStack direction="column" width="100%" spacing={2} justifyContent="space-between">
      <Stack direction="row" width="100%" justifyContent="space-between">
        <Stack direction="column" width="100%" spacing={0.5}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Tooltip title={t2("ToDetail", { name: props.title.text })}>
              <StyledTypography fontSize={16} mb={0.5} fontWeight={700} maxWidth="50%" onClick={props.title.onClick}>
                {props.title.text}
              </StyledTypography>
            </Tooltip>
            <Stack direction="row" spacing={1.5} height="24px">
              <AcceptanceInfo {...props.acceptanceInfo} />
              <ActiveStatusChip status={props.status} />
              <Box>
                <MoreButton {...moreButton} />
              </Box>
            </Stack>
          </Stack>
          <Typography fontSize={12} color="textSecondary">
            {`${t("提出期限")}: ${props.deadline ?? t("なし")}`}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="end">
        <Stack direction="row" spacing={1}>
          {notEvaluatedButton ? <StyledButton {...notEvaluatedButton} /> : <EmphasizedTypography emphasizedText={"0"} subtext={t("未評価")} />}
          {evaluatedButton ? <Button {...evaluatedButton} /> : <EmphasizedTypography emphasizedText={"0"} subtext={t("評価済")} />}
        </Stack>
      </Stack>
      <InviteCandidateDialogV2 {...inviteCandidate} />
    </WrapperStack>
  );
};

ItemCard.displayName = "ItemCard";

export default ItemCard;
