import { checkIsQueryMatch } from "@hireroo/app-helper/regex";
import * as React from "react";
import { useSnapshot } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useMetrics = () => {
  const snapshot = useSnapshotState();
  return Array.from(snapshot.metrics.values());
};

export const useMetricMap = () => {
  const snapshot = useSnapshotState();
  return snapshot.metrics;
};

export const useMetricPagination = () => {
  const snapshot = useSnapshotState();
  return snapshot.metricPagination;
};

export const useSelectableMetrics = () => {
  const { searchText, selectedMetricIds } = useSnapshotState();
  const original = useMetrics();
  return React.useMemo(() => {
    const selectedMetricSet = selectedMetricIds;
    const selectableMetrics = original.filter(metric => !selectedMetricSet.has(metric.metricId));
    const filter = (metric: Types.Metric): boolean => {
      if (searchText === "") {
        return true;
      }
      return checkIsQueryMatch(searchText, metric.title) || checkIsQueryMatch(searchText, metric.description);
    };
    return selectableMetrics.filter(filter);
  }, [original, searchText, selectedMetricIds]);
};

export const useSearchText = () => {
  const snapshot = useSnapshotState();
  return snapshot.searchText;
};
