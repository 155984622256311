import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type EntryRemoteInterviewContainerProps = {};

const EntryRemoteInterviewContainer: React.FC<EntryRemoteInterviewContainerProps> = () => {
  const entryRemoteInterviewProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Pages.EntryRemoteInterview {...entryRemoteInterviewProps} />
    </ErrorBoundary>
  );
};

EntryRemoteInterviewContainer.displayName = "EntryRemoteInterviewContainer";

export default withErrorBoundary(EntryRemoteInterviewContainer, {});
