import { Company } from "@hireroo/app-store/essential/employee";
import { AssessmentResourceEditor } from "@hireroo/app-store/widget/e/AssessmentResourceEditor";
import { QuestionSelectFieldForResourceEditor } from "@hireroo/app-store/widget/e/QuestionSelectFieldForResourceEditor";
import { Widget } from "@hireroo/presentation";

import QuestionSelectFieldV2InitialContainer from "../QuestionSelectFieldV2/InitialContainer";

export type GenerateTestQuestionSetupSectionArgs = {
  mode: Widget.AssessmentResourceEditorV2Props["mode"];
  defaultValues?: Widget.AssessmentResourceEditorV2Props["testQuestionSetupSection"]["defaultValues"];
};

export const useGenerateTestQuestionSetupSectionProps = (
  args: GenerateTestQuestionSetupSectionArgs,
): Widget.AssessmentResourceEditorV2Props["testQuestionSetupSection"] => {
  const companyId = Company.useStrictActiveCompanyId();
  const recommendTestTime = QuestionSelectFieldForResourceEditor.useRecommendTestTime();
  return {
    mode: args.mode,
    onSubmit: fields => {
      AssessmentResourceEditor.setSubmitValue("TEST_QUESTION_SETUP", fields);
    },
    timeLimitSettingField: {
      defaultValue: undefined,
      disabled: false,
      radioValueMap: {
        TIGHT: recommendTestTime.tight,
        RECOMMENDED: recommendTestTime.recommend,
        RELAX: recommendTestTime.relax,
        CUSTOM: recommendTestTime.recommend,
      },
    },
    QuestionSelectField: (
      <QuestionSelectFieldV2InitialContainer
        name="entityTracks"
        target="exam"
        enabledRestoreQuestionFromQueryParams={args.mode === "CREATE"}
        enableMultiSelectQuestion={false}
      />
    ),
    defaultValues: args.defaultValues || {
      name: "",
      companyId: companyId,
      entityTracks: [],
      timeLimitType: "RECOMMENDED",
      timeLimitMinutes: 30,
    },
    onChangeEntityTackExtractedDataList: extractDataList => {
      QuestionSelectFieldForResourceEditor.updateSelectedUniqueKeys(
        extractDataList.map(extractData => {
          return {
            uniqueKeys: extractData.uniqueKeys,
          };
        }),
      );
    },
    disableFields: {
      companyId: false,
      name: false,
      entityTracks: false,
      timeLimitMinutes: false,
      timeLimitType: false,
    },
  };
};
