import { App } from "@hireroo/app-store/essential/talent";
import type { Pages } from "@hireroo/presentation";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as React from "react";
import { generatePath } from "react-router";

import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import FooterContainer from "../../../../widget/v2/t/Footer/Container";
import { useNotificationBanner } from "../../../../widget/v2/t/NotificationBanner/Container";
import TalentHeaderRightContentContainer from "../../../../widget/v2/t/TalentHeaderRightContent/Container";
import TalentSideBarContentContainer from "../../../../widget/v2/t/TalentSideBarContent/Container";
import AssessmentDetailForTalentFetchContainer from "./widget/AssessmentDetailForTalent/FetchContainer";

export type GenerateAssessmentDetailPropsArgs = {
  assessmentId: string;
};

export const useGenerateProps = (args: GenerateAssessmentDetailPropsArgs): Pages.AssessmentDetailForTalentProps => {
  const appStatus = App.useStatus();
  const navigate = useTransitionNavigate();
  const NotificationBannerContainer = useNotificationBanner();

  return {
    layout: {
      loading: appStatus === "INITIALIZING",
      NotificationBanner: NotificationBannerContainer,
      HeaderRightContent: <TalentHeaderRightContentContainer />,
      SideBarContent: <TalentSideBarContentContainer />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      logo: {
        href: generatePath("/t/assessments"),
        onClick: () => {
          navigate("/t/assessments");
        },
      },
    },
    children: <AssessmentDetailForTalentFetchContainer assessmentId={args.assessmentId} />,
  };
};
