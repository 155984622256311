import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import type { Widget } from "@hireroo/presentation";

export type GenerateSnackbarPropsArgs = {};

const useGenerateCommonSnackbarProps = (): Widget.SnackbarProps["common"] => {
  const state = Snackbar.useCommonSnackbarState();
  if (state.kind === "ACTION") {
    return {
      open: state.open,
      message: state.message,
      severity: state.severity,
      actionButton: {
        children: state.action?.message,
        onClick: () => {
          state.action?.callback();
          Snackbar.clearCommon();
        },
      },
      onClose: () => {
        Snackbar.clearCommon();
      },
    };
  }

  return {
    open: state.open,
    message: state.message,
    severity: state.severity,
    onClose: () => {
      Snackbar.clearCommon();
    },
  };
};

const useGenerateLoadingSnackbarProps = (): Widget.SnackbarProps["loading"] => {
  const state = Snackbar.useLoadingSnackbarState();
  return {
    open: state.open,
    message: "Loading ...",
    onClose: () => {
      Snackbar.clearLoading();
    },
  };
};

export const useGenerateProps = (_args: GenerateSnackbarPropsArgs): Widget.SnackbarProps => {
  const commonSnackbarProps = useGenerateCommonSnackbarProps();
  const loadingSnackbarProps = useGenerateLoadingSnackbarProps();
  return {
    common: commonSnackbarProps,
    loading: loadingSnackbarProps,
  };
};
