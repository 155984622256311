import { useLanguageCode } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateHelpCenterUrl } from "@hireroo/router/api";
import type { AssessmentResourceEditorForm } from "@hireroo/validator";

import { useGenerateConfirmSectionProps } from "./useGenerateConfirmSectionProps";
import { useGenerateReportSetupSectionProps } from "./useGenerateReportSetupSectionProps";
import { useGenerateTestInviteSetupSectionProps } from "./useGenerateTestInviteSetupSectionProps";
import { useGenerateTestQuestionSetupSectionProps } from "./useGenerateTestQuestionSetupSection";
import { useGenerateTestSetupSectionProps } from "./useGenerateTestSetupSectionProps";

export type GenerateAssessmentResourceEditorPropsArgs = {
  mode: Widget.AssessmentResourceEditorV2Props["mode"];
  onSubmit: Widget.AssessmentResourceEditorV2Props["onSubmit"];
  loading: boolean;
  defaultValues?: AssessmentResourceEditorForm.CreateAssessmentV2FormSchema;
};

export const useGenerateProps = (args: GenerateAssessmentResourceEditorPropsArgs): Widget.AssessmentResourceEditorV2Props => {
  const lang = useLanguageCode();
  const testQuestionSetupSectionProps = useGenerateTestQuestionSetupSectionProps({
    mode: args.mode,
    defaultValues: args.defaultValues?.testQuestionSetup,
  });
  const testSetupSectionProps = useGenerateTestSetupSectionProps({
    mode: args.mode,
    defaultValues: args.defaultValues?.testSetup,
  });
  const reportSetupSectionProps = useGenerateReportSetupSectionProps({
    mode: args.mode,
    defaultValues: args.defaultValues?.reportSetup,
  });
  const testInviteSetupSectionProps = useGenerateTestInviteSetupSectionProps({
    mode: args.mode,
    defaultValues: args.defaultValues?.testInviteSetup,
  });
  const confirmSectionProps = useGenerateConfirmSectionProps({
    mode: args.mode,
  });

  return {
    mode: args.mode,
    testQuestionSetupSection: testQuestionSetupSectionProps,
    testSetupSection: testSetupSectionProps,
    reportSetupSection: reportSetupSectionProps,
    testInviteSetupSection: testInviteSetupSectionProps,
    confirmSection: confirmSectionProps,
    onSubmit: args.onSubmit,
    layout: {
      loading: args.loading,
      helpLink: {
        href: generateHelpCenterUrl(lang, "ASSESSMENT_CREATE_PAGE"),
      },
    },
  };
};
