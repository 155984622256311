import { RemoteInterviewTutorial } from "@hireroo/app-store/widget/shared/RemoteInterviewTutorial";
import type { Widget } from "@hireroo/presentation";

export type GenerateRemoteInterviewTutorialPropsArgs = {};

export const useGenerateProps = (_args: GenerateRemoteInterviewTutorialPropsArgs): Widget.RemoteInterviewTutorialProps => {
  const runTutorial = RemoteInterviewTutorial.useRunTutorial();
  const kind = RemoteInterviewTutorial.useCurrentKind();
  return {
    kind: kind,
    runTutorial: runTutorial,
    onChangeRunTutorial: runTutorial => {
      RemoteInterviewTutorial.updateRunTutorial(runTutorial);
    },
  };
};
