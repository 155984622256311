import { useDroppable } from "@dnd-kit/core";
import { Typography } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";

const DroppableArea = styled(Box)(({ theme }) => ({
  border: `1px dashed ${theme.palette["Secondary/Shades"].p30}`,
  padding: theme.spacing(1.5),
  minHeight: 256,
  textAlign: "center",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export type UploadContentProps = {
  id: string;
  onSelectedFiles: (files: File[]) => void;
};

const UploadContent: React.FC<UploadContentProps> = props => {
  const { setNodeRef } = useDroppable({
    id: props.id,
  });
  const [draggableInArea, setDraggableInArea] = React.useState(false);

  const divProps: BoxProps = {
    onDrop: event => {
      event.preventDefault();
      if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
        const filesArray = Array.from(event.dataTransfer.files);
        props.onSelectedFiles(filesArray);
      }
    },
    onDragOver: event => {
      setDraggableInArea(true);
      event.preventDefault();
    },
    onDragLeave: event => {
      setDraggableInArea(false);
      event.preventDefault();
    },
    sx: theme => ({
      backgroundColor: draggableInArea ? theme.palette["Secondary/Shades"].p50 : theme.palette["Secondary/Shades"].p8,
    }),
  };

  return (
    <DroppableArea {...divProps} ref={setNodeRef}>
      <Typography>{draggableInArea ? "Drop your files here" : "Drag and drop files here"}</Typography>
    </DroppableArea>
  );
};

UploadContent.displayName = "UploadContent";

export default UploadContent;
