import { useSnapshot } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = (entityId: number) => {
  const snapshot = useSnapshotState();
  return snapshot.projects.has(entityId);
};

export const useCreateProjectEntityHooks = (entityId: number) => {
  const snapshot = useSnapshotState();
  const entityState = snapshot.projects.get(entityId);

  if (!entityState) {
    throw new Error(`Please initialize entityId=${entityId}`);
  }

  const useEntity = () => {
    return entityState.entity;
  };

  const useQuestion = () => {
    if (!entityState.entity.question) {
      throw new Error(`Project Question is not found. Please initialize question`);
    }
    return entityState.entity.question;
  };

  const useCompleted = () => {
    const list: Array<Types.ProjectEntity["projectStatus"]> = ["COMPLETED", "EVALUATED"];
    return list.includes(entityState.entity.projectStatus);
  };

  const useBackendTestCaseResultMap = () => {
    return entityState.testCasesForBackend;
  };

  const useFrontendTestCaseResultMap = () => {
    return entityState.testCasesForFrontend;
  };

  const useDataScienceTestCaseResultMap = () => {
    return entityState.testCasesForDataScience;
  };

  const useDefaultTestCaseResult = () => {
    return entityState.testCasesForDefault;
  };

  const useTestCaseState = () => {
    return entityState.testCaseState;
  };

  const useDefaultTestCaseState = () => {
    return entityState.defaultTestCaseState;
  };

  const useWorkspace = () => {
    return entityState.workspace;
  };

  const useAppealMessage = () => {
    return entityState.entity.appealMessage;
  };

  const useAgentServerHealth = () => {
    return entityState.agentServerHealth;
  };

  const useApplicationServerHealth = () => {
    return entityState.applicationServerHealth;
  };

  const useIpAddress = () => {
    const snapshot = useSnapshotState();
    return snapshot.ipAddress;
  };

  const useGeolocation = () => {
    const snapshot = useSnapshotState();
    return snapshot.geolocation;
  };

  const useInitialFileIndexes = () => {
    const question = useQuestion();
    return question.fileIndexes;
  };

  const useReadOnlyFilePatterns = () => {
    const question = useQuestion();
    // field名はreadOnlyFilesだが、`migrations/*`のようなケースが含まれるため、
    // 関数名は`FilePatterns`に改名しておく
    return question.readOnlyFiles;
  };

  return {
    useWorkspace,
    useTestCaseState,
    useDefaultTestCaseState,
    useBackendTestCaseResultMap,
    useFrontendTestCaseResultMap,
    useDataScienceTestCaseResultMap,
    useDefaultTestCaseResult,
    useCompleted,
    useEntity,
    useQuestion,
    useAppealMessage,
    useAgentServerHealth,
    useApplicationServerHealth,
    useIpAddress,
    useGeolocation,
    useInitialFileIndexes,
    useReadOnlyFilePatterns,
  };
};
