import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateAssignFieldPropsArgs, useGenerateProps } from "./useGenerateProps";

export type AssignFieldContainerProps = GenerateAssignFieldPropsArgs;

const AssignFieldContainer: React.FC<AssignFieldContainerProps> = props => {
  const assignFieldProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.AssignField {...assignFieldProps} />
    </ErrorBoundary>
  );
};

AssignFieldContainer.displayName = "AssignFieldContainer";

export default withErrorBoundary(AssignFieldContainer, {});
