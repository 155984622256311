import { proxy } from "valtio";

import type { State } from "./types";

export const state = proxy<State>({
  company: null,
  selectedGroupIds: [],
  createGroup: {
    selectedEmployeeIds: [],
  },
  editGroup: {
    selectedGroupId: null,
  },
  deleteGroup: {
    selectedGroupId: null,
  },
  addMembers: {
    selectedGroupId: null,
    selectedEmployeeIds: [],
    searchText: "",
  },
  removeMembers: {
    selectedGroupId: null,
    selectedEmployeeIds: [],
    searchText: "",
  },
  dialogStatus: "CLOSE",
  filterText: "",
});
