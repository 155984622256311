import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import * as z from "zod";

export const useSecuritySettingsFormSchema = () => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  return z.object({
    ssoStrictMode: z.boolean({
      required_error: t("必須項目です"),
    }),
    ssoEnabled: z.boolean({
      required_error: t("必須項目です"),
    }),
    idpSsoUrl: z.string().regex(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/, {
      message: t("SSO URLにはhttpまたはhttpsから始まるURLを登録してください。"),
    }),
    idpEntityId: z
      .string({
        required_error: t("必須項目です"),
      })
      .min(1, { message: t("必須項目です") }),
    idpCertificate: z.string().regex(/^-----BEGIN CERTIFICATE-----([\s\S]*)-----END CERTIFICATE-----\s?$/, {
      message: t2("copyAndPasteIdpCertificate", { certificate: "X.509" }),
    }),
    emailDomain: z.string().regex(/^[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/, {
      message: t2("inputOnlyDomain", { domain: "example.com" }),
    }),
  });
};

export type SecuritySettingsFormSchema = z.infer<ReturnType<typeof useSecuritySettingsFormSchema>>;
