import { useTranslation } from "@hireroo/i18n";
import { useMethods, withSplitProvider } from "@hireroo/react-split";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";

import ActivityBar, { ActivityBarProps } from "../../modules/ActivityBar/ActivityBar";
import { EditorAndTestCasesSection, type EditorAndTestCasesSectionProps } from "../../ms-components/Challenge/ChallengeCodingEditorParts";
import QuestionAndSolutionLayout, {
  QuestionAndSolutionLayoutProps,
} from "../../ms-components/Challenge/QuestionAndSolutionLayout/QuestionAndSolutionLayout";
import { SplitParams, SplitParamsInEditorAndTestCasesSection, useSplitEvent } from "./privateHelper";

export { ChallengeCodingEditorProvider, type ChallengeCodingEditorProviderProps, useChallengeCodingEditorContext } from "./Context";

const RightSideWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
`;

export type ChallengeCodingEditorProps = {
  editorAndTestCases: EditorAndTestCasesSectionProps;
  RightSidePanel?: React.ReactNode;
  content: Omit<QuestionAndSolutionLayoutProps, "children" | "layoutKind" | "items">;
};

const ChallengeCodingEditor: React.FC<ChallengeCodingEditorProps> = props => {
  const { t } = useTranslation();
  const methods = useMethods();
  const {
    sidebarVisibleStatus,
    setSidebarVisibleStatus,
    terminalVisibleStatus,
    setTerminalVisibleStatus,
    searchPanelVisibleStatus,
    setSearchPanelVisibleStatus,
  } = useSplitEvent();
  const leftSideStatus = "NORMAL";
  const editorAndTestCases: EditorAndTestCasesSectionProps = {
    ...props.editorAndTestCases,
  };

  const items: QuestionAndSolutionLayoutProps["items"] = [
    {
      id: SplitParams.Contents.Editor.id,
      Content: (
        <RightSideWrapper>
          <EditorAndTestCasesSection {...editorAndTestCases} />
        </RightSideWrapper>
      ),
      size: {
        value: 100,
        unit: "%",
      },
    },
  ];

  if (props.RightSidePanel) {
    items.push({
      id: SplitParams.Contents.SearchPanel.id,
      Content: props.RightSidePanel,
      size: {
        value: 400,
        unit: "px",
      },
      minimizedSize: {
        value: 0,
        unit: "px",
      },
      expandedSize: {
        value: 400,
        unit: "px",
      },
    });
  }

  const activityBarProps: ActivityBarProps = {
    items: [
      {
        icon: "DESCRIPTION",
        tooltip: t("問題"),
        onClick: () => {
          if (sidebarVisibleStatus === "MINIMIZED") {
            methods.expand(SplitParams.splitId, SplitParams.Contents.SideBar.id);
          } else {
            methods.minimize(SplitParams.splitId, SplitParams.Contents.SideBar.id);
          }
          setSidebarVisibleStatus(prev => (prev === "MINIMIZED" ? "OPEN" : "MINIMIZED"));
        },
        active: sidebarVisibleStatus === "OPEN",
      },
      {
        icon: "TERMINAL",
        tooltip: t("テストケース"),
        onClick: () => {
          if (terminalVisibleStatus === "MINIMIZED") {
            methods.expand(SplitParamsInEditorAndTestCasesSection.splitId, SplitParamsInEditorAndTestCasesSection.Contents.Terminal.id);
          } else {
            methods.minimize(SplitParamsInEditorAndTestCasesSection.splitId, SplitParamsInEditorAndTestCasesSection.Contents.Terminal.id);
          }
          setTerminalVisibleStatus(prev => (prev === "MINIMIZED" ? "OPEN" : "MINIMIZED"));
        },
        active: terminalVisibleStatus === "OPEN",
      },
    ],
  };

  if (props.RightSidePanel) {
    activityBarProps.items.push({
      icon: "SEARCH",
      tooltip: t("検索"),
      onClick: () => {
        if (searchPanelVisibleStatus === "MINIMIZED") {
          methods.expand(SplitParams.splitId, SplitParams.Contents.SearchPanel.id);
        } else {
          methods.minimize(SplitParams.splitId, SplitParams.Contents.SearchPanel.id);
        }
        setSearchPanelVisibleStatus(prev => (prev === "MINIMIZED" ? "OPEN" : "MINIMIZED"));
      },
      active: terminalVisibleStatus === "OPEN",
    });
  }

  return (
    <Box maxWidth="100vw" width="100%" height="100%" display="flex" flexDirection="row">
      <ActivityBar {...activityBarProps} />
      <QuestionAndSolutionLayout {...props.content} layoutKind={leftSideStatus} items={items} />
    </Box>
  );
};

ChallengeCodingEditor.displayName = "ChallengeCodingEditor";

export default withSplitProvider(ChallengeCodingEditor);
