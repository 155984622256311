import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import * as React from "react";

const StyledChip = styled(Chip)(({ theme }) => ({
  height: 30,
  ".MuiChip-deleteIcon": {
    color: theme.palette.secondary.main,
  },
  color: theme.palette.secondary.main,
  borderColor: theme.palette.secondary.main,
}));

export type TagSearchFieldProps = AutocompleteProps<string, true, true, true>;

export type TagInputProps = {
  tagSearchField: TextFieldProps;
  tagSearch: Omit<TagSearchFieldProps, "placeholder" | "renderInput">;
  onChange: (value: string[]) => void;
};

const TagInput: React.FC<TagInputProps> = props => {
  const { tagSearch, tagSearchField } = props;

  const tagInputProps: TagSearchFieldProps = {
    ...tagSearch,
    multiple: true,
    freeSolo: true,
    disableCloseOnSelect: true,
    getOptionLabel: (tagName: string) => tagName,
    renderInput: params => (
      <TextField
        {...params}
        {...tagSearchField}
        size="small"
        InputLabelProps={{
          shrink: true,
        }}
      />
    ),
    renderTags: (items, getTagProps) => {
      return items.map((item, index) => {
        const tagProps = getTagProps({ index });
        return <StyledChip {...tagProps} key={item} label={item} variant="outlined" />;
      });
    },
  };

  return <Autocomplete {...tagInputProps} />;
};

TagInput.displayName = "TagInput";

export default TagInput;
