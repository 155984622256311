import { FieldValidator } from "@hireroo/validator";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import * as React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

const StyledChip = styled(Chip)(({ theme }) => ({
  height: 30,
  ".MuiChip-deleteIcon": {
    color: theme.palette["Other"]["OutlinedBorder"],
  },
  color: theme.palette.text.primary,
  borderColor: theme.palette["Other"]["OutlinedBorder"],
}));

export type MultiEmailAddressFieldProps = Omit<TextFieldProps, "name">;

type Field = {
  emails: (FieldValidator.EmailListItemSchema & { id?: number })[];
};

/**
 * The name of `useFieldArray` must be a fixed value, so the value of name should be fixed with emails.
 * @see https://react-hook-form.com/api/usefieldarray
 */
const MultiEmailAddressField: React.FC<MultiEmailAddressFieldProps & { name: "emails" }> = props => {
  const method = useFormContext<Field>();
  const validator = FieldValidator.useEmailSchema();
  const { fields, append, remove } = useFieldArray<Field>({
    /**
     * Name of the field array. Note: Do not support dynamic name.
     * @see https://react-hook-form.com/api/usefieldarray
     */
    name: props.name,
    control: method.control,
  });
  const [value, updateValue] = React.useState("");
  const fieldError = method.formState.errors[props.name];
  const addItem = React.useCallback(
    (inputText: string) => {
      const remainInputs: string[] = [];
      const splitText = inputText.split(/(,|\s+)/).filter(t => ![",", " "].includes(t));
      const uniqueValueSet = new Set<string>();
      splitText.forEach(newInputText => {
        if (!newInputText || newInputText === " ") {
          return;
        }
        const trimmedText = newInputText.trim();
        const result = validator.safeParse(trimmedText);
        if (result.success) {
          if (fields.findIndex(field => field.value === trimmedText) === -1) {
            const value = newInputText.trim();
            if (!uniqueValueSet.has(value)) {
              uniqueValueSet.add(value);
              append({
                value: value,
              });
            }
            method.clearErrors(props.name);
          }
        } else {
          remainInputs.push(newInputText);
          method.setError(props.name, { message: result.error.issues.map(issue => issue.message).join("\n") });
        }
      });
      updateValue(remainInputs.join(","));
    },
    [append, fields, method, props.name, validator],
  );
  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputText = e.currentTarget.value;
      const lastCharacter = inputText[inputText.length - 1];
      if (![",", " "].includes(lastCharacter)) {
        updateValue(inputText);
        method.clearErrors(props.name);
        return;
      }
      addItem(inputText);
    },
    [addItem, method, props.name],
  );
  const handleBlur = React.useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      addItem(e.currentTarget.value);
    },
    [addItem],
  );
  return (
    <TextField
      size="small"
      fullWidth
      multiline
      color="primary"
      variant="outlined"
      {...props}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      name="email"
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        startAdornment: fields.map(item => <StyledChip label={item.value} variant="outlined" key={item.id} onDelete={() => remove(item.id)} />),
        sx: {
          flexWrap: "wrap",
          gap: "8px",
        },
      }}
      error={!!fieldError?.message}
      helperText={fieldError?.message}
    />
  );
};

MultiEmailAddressField.displayName = "MultiEmailAddressField";

export default MultiEmailAddressField;
