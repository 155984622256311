import { useTranslation } from "@hireroo/i18n";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button, { type ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

import EmployeeLayout, { EmployeeLayoutProps } from "../../layouts/EmployeeLayout/EmployeeLayout";

const StyledButton = styled(Button)(() => ({
  height: 36,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
}));

const StyledHeaderStack = styled(Stack)(() => ({
  height: 36,
  alignItems: "center",
  justifyContent: "space-between",
}));

export type ScreeningListProps = {
  layout: EmployeeLayoutProps;
  SearchArea: React.ReactNode;
  ScreeningList: React.ReactNode;
  createButton: Pick<ButtonProps, "onClick" | "disabled"> & {
    tooltipText: string;
  };
};

const ScreeningList: React.FC<ScreeningListProps> = props => {
  const { t } = useTranslation();
  const layoutProps: EmployeeLayoutProps = {
    ...props.layout,
  };
  const { tooltipText, ...rest } = props.createButton;
  const createButtonProps: ButtonProps = {
    ...rest,
    startIcon: <AddIcon />,
    color: "primary",
    variant: "contained",
  };

  return (
    <EmployeeLayout {...layoutProps}>
      <Box p={3}>
        <StyledHeaderStack direction="row" mb={2}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5" fontWeight={700} noWrap>
              {t("コーディングテスト一覧")}
            </Typography>
          </Stack>
          {createButtonProps.disabled && (
            <Tooltip title={tooltipText}>
              <span>
                <StyledButton {...createButtonProps}>{t("コーディングテスト作成")}</StyledButton>
              </span>
            </Tooltip>
          )}
          {!createButtonProps.disabled && <StyledButton {...createButtonProps}>{t("コーディングテスト作成")}</StyledButton>}
        </StyledHeaderStack>

        <Box>
          <Stack spacing={2}>
            {props.SearchArea}
            {props.ScreeningList}
          </Stack>
        </Box>
      </Box>
    </EmployeeLayout>
  );
};

ScreeningList.displayName = "ScreeningList";

export default ScreeningList;
