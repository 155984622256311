import { useTranslation } from "@hireroo/i18n";
import { ScreeningTestListForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import Box from "@mui/material/Box";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import { styled, useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";

import ScreeningTestListTableRow, { ScreeningTestListTableRowProps } from "./parts/ScreeningTestListTableRow/ScreeningTestListTableRow";

const StyledCheckbox = styled(Checkbox)(() => ({
  width: "38px",
  height: "38px",
  padding: "9px",
}));

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: "normal",
  border: "none",
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  fontSize: "12px",
  height: 40,
  wordBreak: "keep-all",
  whiteSpace: "nowrap",
}));

export type ScreeningTestListTableProps = {
  enabledStatisticsContents: boolean;
  enabledScreeningName: boolean;
  enabledMultiSelect: boolean;
  enabledSuspiciousDegree: boolean;
  items: Omit<ScreeningTestListTableRowProps, "name" | "mode" | "enabledStatisticsContents" | "enabledScreeningName">[];
  onChange?: (fields: ScreeningTestListForm.ScreeningTestListForm) => void;
};

const ScreeningTestListTable: React.FC<ScreeningTestListTableProps> = props => {
  const { onChange, items } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const validateSchema = ScreeningTestListForm.useScreeningTestListForm();
  const methods = useForm<ScreeningTestListForm.ScreeningTestListForm>({
    resolver: zodResolver(validateSchema),
    defaultValues: {
      items: items.map((item): ScreeningTestListForm.ScreeningTestListForm["items"][0] => {
        return {
          itemId: item.id,
          selected: false,
        };
      }),
    },
  });

  const [mode, setMode] = React.useState<ScreeningTestListTableRowProps["mode"]>("DEFAULT");

  React.useEffect(() => {
    const subscription = methods.watch(() => {
      const fields = methods.getValues();
      const hasSelected = fields.items.some(item => item.selected);
      setMode(hasSelected ? "SELECTABLE" : "DEFAULT");
      onChange?.(fields);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [methods, onChange]);
  const isAllSelected = methods.watch("items").every(item => item.selected);
  const isIndeterminate = methods.watch("items").some(item => item.selected) && !isAllSelected;

  const checkboxProps: CheckboxProps = {
    checked: isAllSelected,
    indeterminate: isIndeterminate,
    color: "secondary",
    size: "small",
    onClick: event => {
      event.stopPropagation();
      const newFieldValue = methods.getValues().items.map(item => {
        return {
          itemId: item.itemId,
          selected: isIndeterminate ? false : !isAllSelected,
        };
      });
      methods.reset({ items: newFieldValue });
    },
  };

  if (items.length === 0) {
    return (
      <Box sx={{ width: "100%" }} p={2}>
        <Typography variant="body2" textAlign="center" color="textSecondary">
          {t("該当の候補者はありません")}
        </Typography>
      </Box>
    );
  }

  return (
    <FormProvider {...methods}>
      <TableContainer sx={{ overflow: "auto" }}>
        <Table component="div" sx={{ display: "table" }}>
          <TableHead component="div" sx={{ display: "table-header-group" }}>
            <TableRow sx={{ borderTop: "1px solid", borderBottom: "1px solid", borderColor: theme.palette["Gray/Shades"].p8 }} component="div">
              {props.enabledMultiSelect && (
                <StyledTableHeaderCell align="center" component="div">
                  <StyledCheckbox {...checkboxProps} />
                </StyledTableHeaderCell>
              )}
              <StyledTableHeaderCell align="left" component="div">
                {t("候補者名")}
              </StyledTableHeaderCell>
              <StyledTableHeaderCell align="left" component="div">
                {t("タグ")}
              </StyledTableHeaderCell>
              {props.enabledScreeningName && (
                <StyledTableHeaderCell align="left" component="div">
                  {t("コーディングテスト")}
                </StyledTableHeaderCell>
              )}
              <StyledTableHeaderCell component="div">{t("ステータス")}</StyledTableHeaderCell>
              <StyledTableHeaderCell align="center" component="div">
                {t("スコア")}
              </StyledTableHeaderCell>
              {props.enabledStatisticsContents && (
                <StyledTableHeaderCell align="center" component="div">
                  {t("ランク")}
                </StyledTableHeaderCell>
              )}
              {props.enabledSuspiciousDegree && (
                <StyledTableHeaderCell align="center" component="div">
                  {t("不審度")}
                </StyledTableHeaderCell>
              )}
              <StyledTableHeaderCell align="center" component="div">
                {/* Evaluate button or result */}
              </StyledTableHeaderCell>
              <StyledTableHeaderCell align="right" component="div">
                {/* for MoreButton */}
              </StyledTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody component="div" sx={{ display: "table-row-group" }}>
            {items.map((itemProps, index) => (
              <ScreeningTestListTableRow
                key={itemProps.id}
                {...itemProps}
                name={`items.${index}`}
                mode={mode}
                enabledStatisticsContents={props.enabledStatisticsContents}
                enabledScreeningName={props.enabledScreeningName}
                enabledSuspiciousDegree={props.enabledSuspiciousDegree}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </FormProvider>
  );
};

ScreeningTestListTable.displayName = "TestListTable";

export default ScreeningTestListTable;
