import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { FileNode } from "@hireroo/project/helpers/fileTree";
import {
  BackendTestCaseDialogV3,
  type BackendTestCaseDialogV3Props,
  DataScienceTestCaseDialog,
  type DataScienceTestCaseDialogProps,
  DefaultTestCaseDialog,
  type DefaultTestCaseDialogProps,
  FrontendTestCaseDialog,
  type FrontendTestCaseDialogProps,
  type ProjectEditorToolBarProps,
  ProjectFileNavigationV3,
  type ProjectFileNavigationV3Props,
  ProjectUIFrame,
  ProjectUIFrameProps,
} from "@hireroo/project/react/v2/widget";
import { useMethods, withSplitProvider } from "@hireroo/react-split";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";

import ActivityBar, { ActivityBarProps } from "../../modules/ActivityBar/ActivityBar";
import BlockBuilder, { BlockBuilderProps } from "../../modules/BlockBuilder/BlockBuilder";
import ProjectCodingEditorSideBar, {
  ProjectCodingEditorSideBarProps,
} from "../../ms-components/Project/ProjectCodingEditorSideBar/ProjectCodingEditorSideBar";
import BaseDialog, { BaseDialogProps } from "../../primitive/BaseDialog/BaseDialog";
import Split, { SplitProps } from "../../primitive/Split/Split";
import Editor, { EditorProps } from "./parts/Editor";
import NotificationPanel, { NotificationPanelProps } from "./parts/NotificationPanel";
import ResetConfirmDialog, { ResetConfirmDialogProps } from "./parts/ResetConfirmDialog";
import SubmitConfirmDialog, { SubmitConfirmDialogProps } from "./parts/SubmitConfirmDialog";
import TextDialog, { TextDialogProps } from "./parts/TextDialog";
import { SplitParams, SplitParamsForWaiting, SplitParamsInEditorBox, useSplitEvent } from "./privateHelper";

const Wrapper = styled(Box)`
  display: flex;
  overflow: hidden;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

const StyledSplit = styled(Split)`
  height: 100%;
  width: calc(100vw - 36px);
  overflow: hidden;
`;

export type ProjectCodingEditorV3Props = {
  sidebar: ProjectCodingEditorSideBarProps;
  currentDirectoryFiles: string[];
  notificationPanel?: NotificationPanelProps;
  fileNavigation: Pick<
    ProjectFileNavigationV3Props,
    "status" | "resetButton" | "renderTree" | "focused" | "connectingPanel" | "fileTreeStatus"
  >;
  disabledEditActionButtons: "REMOTE_SERVER_NOT_RUNNING" | "EDITABLE";
  enableUiFrame: boolean;
  uiFrame?: ProjectUIFrameProps;
  toolbar: ProjectEditorToolBarProps;
  workspace: EditorProps["workspace"];
  terminal: EditorProps["terminal"];
  onCreateFile: (fields: Parameters<TextDialogProps["onSubmit"]>[0], node: FileNode) => void;
  onCreateDirectory: (fields: Parameters<TextDialogProps["onSubmit"]>[0], node: FileNode) => void;
  onDeleteNode: (node: FileNode) => void;
  /**
   * The node of file creation and directory creation is changed.
   */
  onChangeCreatingActionNode: (node: FileNode) => void;
  frontendTestCaseDialog?: Pick<FrontendTestCaseDialogProps, "items"> & {
    onClose: () => void;
  };
  backendTestCaseDialog?: Pick<BackendTestCaseDialogV3Props, "items"> & {
    onClose: () => void;
  };
  dataScienceTestCaseDialog?: Pick<DataScienceTestCaseDialogProps, "items"> & {
    onClose: () => void;
  };
  defaultTestCaseDialog?: {
    blockBuilder: BlockBuilderProps;
    onClose: () => void;
    failureMessage?: string;
  };
  resetConfirmDialog: Pick<ResetConfirmDialogProps, "onAccept" | "onClose">;
  submitConfirmDialog: Pick<SubmitConfirmDialogProps, "onAccept">;
  enableTerminal: boolean;
};

const ProjectCodingEditorV3: React.FC<ProjectCodingEditorV3Props> = props => {
  const methods = useMethods();
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const [openCreateFileDialog, setOpenCreateFileDialog] = React.useState(false);
  const [openCreateDirectoryDialog, setOpenCreateDirectoryDialog] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openTestCaseDialog, setOpenTestCaseDialog] = React.useState(false);
  const [openSubmitConfirmDialog, setSubmitConfirmDialog] = React.useState(false);
  const [openResetConfirmDialog, setResetConfirmDialog] = React.useState(false);
  const { onChangeCreatingActionNode, fileNavigation } = props;
  const targetNode = React.useRef<FileNode | null>(null);

  const {
    sidebarVisibleStatus,
    setSidebarVisibleStatus,
    fileTreeVisibleStatus,
    setFileTreeVisibleStatus,
    uiFrameVisibleStatus,
    setUiFrameVisibleStatus,
    terminalVisibleStatus,
    setTerminalVisibleStatus,
  } = useSplitEvent();

  const isWaiting = !!props.notificationPanel;

  const editorProps = React.useMemo(
    (): EditorProps => ({
      workspace: props.workspace,
      terminal: {
        ...props.terminal,
        runTestCaseButton: {
          ...props.terminal.runTestCaseButton,
          onClick: event => {
            setOpenTestCaseDialog(true);
            props.terminal.runTestCaseButton.onClick?.(event);
          },
        },
        submitButton: {
          ...props.terminal.submitButton,
          onClick: event => {
            setSubmitConfirmDialog(true);
            props.terminal.submitButton.onClick?.(event);
          },
        },
      },
      toolbar: props.toolbar,
      enableTerminal: props.enableTerminal,
    }),
    [props.workspace, props.enableTerminal, props.terminal, props.toolbar],
  );

  const fileNavigationProps = React.useMemo(
    (): ProjectFileNavigationV3Props => ({
      ...fileNavigation,
      resetButton: {
        ...props.fileNavigation.resetButton,
        disabled: props.disabledEditActionButtons === "REMOTE_SERVER_NOT_RUNNING",
        onClick: event => {
          setResetConfirmDialog(true);
          props.fileNavigation.resetButton?.onClick?.(event);
        },
      },
      createFileButton: {
        disabledReason: t("開発環境が起動するまでこの操作はできません"),
        disabled: props.disabledEditActionButtons === "REMOTE_SERVER_NOT_RUNNING",
        onClick: (event, node) => {
          onChangeCreatingActionNode(node);
          event.stopPropagation();
          setOpenCreateFileDialog(true);
          targetNode.current = node;
        },
      },
      createDirectoryButton: {
        disabledReason: t("開発環境が起動するまでこの操作はできません"),
        disabled: props.disabledEditActionButtons === "REMOTE_SERVER_NOT_RUNNING",
        onClick: (event, node) => {
          onChangeCreatingActionNode(node);
          event.stopPropagation();
          setOpenCreateDirectoryDialog(true);
          targetNode.current = node;
        },
      },
      deleteNodeButton: {
        title: t2("deleteNode", {
          name: targetNode.current?.id ?? "",
        }),
        disabledReason: t("開発環境が起動するまでこの操作はできません"),
        disabled: props.disabledEditActionButtons === "REMOTE_SERVER_NOT_RUNNING",
        onClick: (event, node) => {
          event.stopPropagation();
          targetNode.current = node;
          setOpenDeleteDialog(true);
        },
      },
    }),
    [fileNavigation, props.fileNavigation.resetButton, props.disabledEditActionButtons, t2, t, onChangeCreatingActionNode],
  );

  const createFileDialogProps: TextDialogProps = {
    existsFiles: props.currentDirectoryFiles,
    open: openCreateFileDialog,
    label: t("ファイル名"),
    cancelButton: {
      onClick: () => {
        setOpenCreateFileDialog(false);
      },
    },
    onClose: () => {
      setOpenCreateFileDialog(false);
    },
    onSubmit: fields => {
      setOpenCreateFileDialog(false);
      targetNode.current && props.onCreateFile(fields, targetNode.current);
    },
  };

  const createDirectoryDialogProps: TextDialogProps = {
    existsFiles: props.currentDirectoryFiles,
    open: openCreateDirectoryDialog,
    label: t("ディレクトリ名"),
    cancelButton: {
      onClick: () => {
        setOpenCreateDirectoryDialog(false);
      },
    },
    onClose: () => {
      setOpenCreateDirectoryDialog(false);
    },
    onSubmit: fields => {
      setOpenCreateDirectoryDialog(false);
      targetNode.current && props.onCreateDirectory(fields, targetNode.current);
    },
  };

  const deleteNodeDialogProps: BaseDialogProps = {
    open: openDeleteDialog,
    title: t2("deleteDialog", {
      filesState: targetNode.current?.id ?? "",
    }),
    yesButton: {
      children: t("削除"),
      color: "error",
      onClick: () => {
        if (targetNode.current) {
          props.onDeleteNode(targetNode.current);
        }
        setOpenDeleteDialog(false);
      },
    },
    noButton: {
      children: t("キャンセル"),
      onClick: () => {
        setOpenDeleteDialog(false);
      },
    },
  };

  const defaultTestCaseDialogProps: DefaultTestCaseDialogProps | undefined = props.defaultTestCaseDialog
    ? {
        open: openTestCaseDialog,
        onClose: () => {
          setOpenTestCaseDialog(false);
          props.defaultTestCaseDialog?.onClose();
        },
        children: (
          <Box p={3}>
            <BlockBuilder {...props.defaultTestCaseDialog.blockBuilder} />
          </Box>
        ),
      }
    : undefined;

  const frontendTestCaseDialogProps: FrontendTestCaseDialogProps | undefined = props.frontendTestCaseDialog
    ? {
        ...props.frontendTestCaseDialog,
        open: openTestCaseDialog,
        onClose: () => {
          setOpenTestCaseDialog(false);
          props.frontendTestCaseDialog?.onClose?.();
        },
      }
    : undefined;

  const backendTestCaseDialogProps: BackendTestCaseDialogV3Props | undefined = props.backendTestCaseDialog
    ? {
        ...props.backendTestCaseDialog,
        open: openTestCaseDialog,
        onClose: () => {
          setOpenTestCaseDialog(false);
          props.backendTestCaseDialog?.onClose?.();
        },
      }
    : undefined;

  const dataScienceTestCaseDialogProps: DataScienceTestCaseDialogProps | undefined = props.dataScienceTestCaseDialog
    ? {
        ...props.dataScienceTestCaseDialog,
        open: openTestCaseDialog,
        onClose: () => {
          setOpenTestCaseDialog(false);
          props.dataScienceTestCaseDialog?.onClose?.();
        },
      }
    : undefined;

  const submitConfirmDialogProps: SubmitConfirmDialogProps = {
    ...props.submitConfirmDialog,
    open: openSubmitConfirmDialog,
    onClose: () => {
      setSubmitConfirmDialog(false);
    },
    onAccept: controller => {
      props.submitConfirmDialog.onAccept(controller);
      setSubmitConfirmDialog(false);
    },
  };

  const resetConfirmDialogProps: ResetConfirmDialogProps = {
    ...props.resetConfirmDialog,
    open: openResetConfirmDialog,
    onClose: () => {
      props.resetConfirmDialog.onClose?.();
      setResetConfirmDialog(false);
    },
    onAccept: (option, controller) => {
      props.resetConfirmDialog.onAccept(option, controller);
      setResetConfirmDialog(false);
    },
  };

  const activityBarItems: (ActivityBarProps["items"][0] | false)[] = [
    {
      icon: "DESCRIPTION",
      tooltip: t("問題"),
      onClick: () => {
        if (sidebarVisibleStatus === "MINIMIZED") {
          methods.expand(SplitParams.splitId, SplitParams.Contents.SideBar.id);
        } else {
          methods.minimize(SplitParams.splitId, SplitParams.Contents.SideBar.id);
        }
        setSidebarVisibleStatus(prev => (prev === "MINIMIZED" ? "OPEN" : "MINIMIZED"));
      },
      active: sidebarVisibleStatus === "OPEN",
    },
    {
      icon: "FOLDER",
      tooltip: t("ファイルツリー"),
      onClick: () => {
        if (fileTreeVisibleStatus === "MINIMIZED") {
          methods.expand(SplitParams.splitId, SplitParams.Contents.FileNavigation.id);
        } else {
          methods.minimize(SplitParams.splitId, SplitParams.Contents.FileNavigation.id);
        }
        setFileTreeVisibleStatus(prev => (prev === "MINIMIZED" ? "OPEN" : "MINIMIZED"));
      },
      active: fileTreeVisibleStatus === "OPEN",
    },
    props.enableUiFrame && {
      icon: "EYE",
      tooltip: t("UIを確認する"),
      onClick: () => {
        if (uiFrameVisibleStatus === "MINIMIZED") {
          methods.expand(SplitParams.splitId, SplitParams.Contents.UiFrame.id);
        } else {
          methods.minimize(SplitParams.splitId, SplitParams.Contents.UiFrame.id);
        }
        setUiFrameVisibleStatus(prev => (prev === "MINIMIZED" ? "OPEN" : "MINIMIZED"));
      },
      active: uiFrameVisibleStatus === "OPEN",
    },
    props.enableTerminal && {
      icon: "TERMINAL",
      tooltip: t("ターミナル"),
      onClick: () => {
        if (terminalVisibleStatus === "MINIMIZED") {
          methods.expand(SplitParamsInEditorBox.splitId, SplitParamsInEditorBox.Contents.Terminal.id);
        } else {
          methods.minimize(SplitParamsInEditorBox.splitId, SplitParamsInEditorBox.Contents.Terminal.id);
        }
        setTerminalVisibleStatus(prev => (prev === "MINIMIZED" ? "OPEN" : "MINIMIZED"));
      },
      active: terminalVisibleStatus === "OPEN",
    },
  ];

  const activityBarProps: ActivityBarProps = {
    disabled: isWaiting,
    items: activityBarItems.flatMap(v => (v ? [v] : [])),
  };

  const splitItems: (SplitProps["items"][0] | false)[] = [
    {
      id: SplitParams.Contents.SideBar.id,
      Content: <ProjectCodingEditorSideBar key="sidebar" {...props.sidebar} />,
      size: {
        value: 400,
        unit: "px",
      },
      minimizedSize: {
        value: 0,
        unit: "px",
      },
      expandedSize: {
        value: 400,
        unit: "px",
      },
      sidePane: {
        className: "tutorial-pane",
      },
    },
    {
      id: SplitParams.Contents.FileNavigation.id,
      Content: <ProjectFileNavigationV3 key="file-navigation" {...fileNavigationProps} />,
      size: {
        value: 300,
        unit: "px",
      },
      minimizedSize: {
        value: 0,
        unit: "px",
      },
      expandedSize: {
        value: 300,
        unit: "px",
      },
    },
    {
      id: SplitParams.Contents.Editor.id,
      Content: <Editor key="editor" {...editorProps} />,
      size: {
        value: 100,
        unit: "%",
      },
    },
    props.enableUiFrame && {
      id: SplitParams.Contents.UiFrame.id,
      Content: props.uiFrame && <ProjectUIFrame key={"ui-frame"} {...props.uiFrame} />,
      size: {
        value: 300,
        unit: "px",
      },
      minimizedSize: {
        value: 0,
        unit: "px",
      },
      expandedSize: {
        value: 300,
        unit: "px",
      },
    },
  ];

  const waitingSplitItems: SplitProps["items"] = [
    {
      id: SplitParamsForWaiting.Contents.SideBar.id,
      Content: <ProjectCodingEditorSideBar key="sidebar" {...props.sidebar} />,
      size: {
        value: 400,
        unit: "px",
      },
      minimizedSize: {
        value: 0,
        unit: "px",
      },
      expandedSize: {
        value: 400,
        unit: "px",
      },
      sidePane: {
        className: "tutorial-pane",
      },
    },
    {
      id: SplitParamsForWaiting.Contents.NotificationPanel.id,
      Content: props.notificationPanel && <NotificationPanel {...props.notificationPanel} />,
      size: {
        value: 100,
        unit: "%",
      },
    },
  ];

  const splitPropsForWaiting: SplitProps = {
    splitDirection: "VERTICAL",
    splitId: SplitParamsForWaiting.splitId,
    items: waitingSplitItems,
  };

  const splitProps: SplitProps = {
    splitDirection: "VERTICAL",
    splitId: SplitParams.splitId,
    items: splitItems.flatMap(v => (v ? [v] : [])),
  };

  return (
    <Wrapper>
      <ActivityBar {...activityBarProps} />
      {isWaiting ? <StyledSplit key="waiting" {...splitPropsForWaiting} /> : <StyledSplit key="main" {...splitProps} />}
      <BaseDialog {...deleteNodeDialogProps} />
      <TextDialog {...createFileDialogProps} />
      <TextDialog {...createDirectoryDialogProps} />
      {defaultTestCaseDialogProps && <DefaultTestCaseDialog {...defaultTestCaseDialogProps} />}
      {frontendTestCaseDialogProps && <FrontendTestCaseDialog {...frontendTestCaseDialogProps} />}
      {backendTestCaseDialogProps && <BackendTestCaseDialogV3 {...backendTestCaseDialogProps} />}
      {dataScienceTestCaseDialogProps && <DataScienceTestCaseDialog {...dataScienceTestCaseDialogProps} />}
      {props.resetConfirmDialog && <ResetConfirmDialog {...resetConfirmDialogProps} />}
      <SubmitConfirmDialog {...submitConfirmDialogProps} />
    </Wrapper>
  );
};

ProjectCodingEditorV3.displayName = "ProjectCodingEditor";

export default withSplitProvider(ProjectCodingEditorV3);
