import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import * as React from "react";

export type NotificationButtonProps = {
  notificationCount: number;
  onClick?: IconButtonProps["onClick"];
  Notification: React.ReactNode;
};

const NotificationButton: React.FC<NotificationButtonProps> = props => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    props.onClick?.(event);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const rootKey = "notificationList";

  return (
    <>
      <IconButton role="openner" onClick={handleClick}>
        <Badge role="notificationbadge" badgeContent={props.notificationCount} max={10} color="primary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        id="header-popover"
        role="notifications"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          // Pagination doesn't work well unless I put the root key here :|
          id: rootKey,
          style: {
            // ListItem.height = 72, and we want to show 5 items + 1 header
            maxHeight: 72 * 6,
            maxWidth: 500,
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {props.Notification}
      </Popover>
    </>
  );
};

NotificationButton.displayName = "NotificationButton";

export default NotificationButton;
