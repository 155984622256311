import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { AssessmentSearchForm as AssessmentSearchFormValidator } from "@hireroo/validator";

import type { SearchFormProps } from "../../../modules/SearchForm/SearchForm";

type FieldProps = SearchFormProps["fields"][0];
export type MultiChoiceFieldProps = Extract<FieldProps, { kind: "MULTI_CHOICE" }>;
export type MultiChoiceV2FieldProps = Extract<FieldProps, { kind: "MULTI_CHOICE_V2" }>;
export type SingleChoiceFieldProps = Extract<FieldProps, { kind: "SINGLE_CHOICE" }>;
export type GroupMultiChoiceFieldProps = Extract<FieldProps, { kind: "GROUP_MULTI_CHOICE" }>;
export type RangeFieldProps = Extract<FieldProps, { kind: "RANGE" }>;
export type TagFieldProps = Extract<FieldProps, { kind: "TAG" }>;

type FieldKeyName = keyof AssessmentSearchFormValidator.AssessmentSearchQuerySchema;

const FieldName = {
  TEXT_FILTER: "textFilter",
  SCORE_TREND: "scoreTrend",
  TALENT: "talent",
  ASSESSMENT_STATUS: "assessmentStatuses",
  SUBMIT_STATUS: "submitStatus",
  VIEWERS: "viewers",
} satisfies Record<string, FieldKeyName>;

type ScoreTrendOption = {
  displayName: string;
  value: Exclude<AssessmentSearchFormValidator.AssessmentSearchQuerySchema["scoreTrend"], null>;
};

export const useScoreTrendField = (): SingleChoiceFieldProps => {
  const { t } = useTranslation();
  const options: ScoreTrendOption[] = [
    { value: "UP", displayName: t("前回より高い") },
    { value: "DOWN", displayName: t("前回より低い") },
    { value: "FLAT", displayName: t("前回と同等") },
  ];
  return {
    kind: "SINGLE_CHOICE",
    title: t("スコア動向"),
    name: FieldName.SCORE_TREND,
    options: options,
  };
};

type StatusOption = {
  displayName: string;
  value: AssessmentSearchFormValidator.AssessmentSearchQuerySchema["assessmentStatuses"][0];
};

export const useStatusField = (): MultiChoiceFieldProps => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const options: StatusOption[] = [
    { value: "RUNNING", displayName: t("アクティブ") },
    { value: "SUSPENDED", displayName: t2("talentScoreSuspended") },
    { value: "ARCHIVED", displayName: t("アーカイブ") },
  ];
  return {
    kind: "MULTI_CHOICE",
    title: t("ステータス"),
    name: FieldName.ASSESSMENT_STATUS,
    options: options,
  };
};

type SubmitStatusOption = {
  displayName: string;
  value: Exclude<AssessmentSearchFormValidator.AssessmentSearchQuerySchema["submitStatus"], null>;
};

export const useSubmitStatusField = (): SingleChoiceFieldProps => {
  const { t } = useTranslation();
  const options: SubmitStatusOption[] = [
    { value: "SUBMITTED", displayName: t("提出済") },
    { value: "NOT_SUBMITTED", displayName: t("未提出") },
  ];
  return {
    kind: "SINGLE_CHOICE",
    title: t("提出状態"),
    name: FieldName.SUBMIT_STATUS,
    options: options,
  };
};

export const useTalentField = (props: Pick<MultiChoiceFieldProps, "options">): MultiChoiceFieldProps => {
  const { t } = useTranslation();
  return {
    ...props,
    kind: "MULTI_CHOICE",
    name: FieldName.TALENT,
    title: t("タレント"),
  };
};

export const useViewerField = (props: Pick<MultiChoiceV2FieldProps, "options">): MultiChoiceV2FieldProps => {
  const { t } = useTranslation();
  return {
    ...props,
    kind: "MULTI_CHOICE_V2",
    name: FieldName.VIEWERS,
    title: t("閲覧者"),
  };
};

export const useTextFilterField = (): SearchFormProps["textFilter"] => {
  const { t } = useTranslation();
  return {
    name: FieldName.TEXT_FILTER,
    placeholder: t("タイトルを検索"),
  };
};
