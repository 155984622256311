import { useSnapshot } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = (entityId: number) => {
  const snapshot = useSnapshotState();
  return snapshot.challenges.has(entityId);
};

export const useCreateChallengeEntityHooks = (entityId: number) => {
  const snapshot = useSnapshotState();
  const entityState = snapshot.challenges.get(entityId);

  if (!entityState) {
    throw new Error(`Please initialize entityId=${entityId}`);
  }

  const useTestCaseResults = () => {
    return entityState.testCasesResults;
  };

  const useQuestion = () => {
    return entityState.question;
  };

  const useAppealMessage = () => {
    return entityState.appealMessage;
  };

  const useEntity = () => {
    return entityState.entity;
  };

  const useOutputStatusMap = (): Record<Types.TestCaseIndex, Types.OutputStatus> => {
    const statusMap: Record<Types.TestCaseIndex, Types.OutputStatus> = {};
    entityState.outputStatus.forEach((value, key) => {
      statusMap[key] = value;
    });

    return statusMap;
  };

  const useUsedHintIdsSet = () => {
    return new Set(entityState.usedHintIds);
  };

  const useWebSession = () => {
    return entityState.webSession;
  };

  const useChatGPTSession = () => {
    return entityState.chatGPTSession;
  };

  const useWebSearchEventId = () => {
    return entityState.webSession?.latestWebSearchedLog?.searchEventId || null;
  };

  return {
    useTestCaseResults,
    useQuestion,
    useAppealMessage,
    useEntity,
    useOutputStatusMap,
    useUsedHintIdsSet,
    useWebSession,
    useChatGPTSession,
    useWebSearchEventId,
  };
};

export const useIpAddress = () => {
  const snapshot = useSnapshotState();
  return snapshot.ipAddress;
};

export const useGeolocation = () => {
  const snapshot = useSnapshotState();
  return snapshot.geolocation;
};

export const useEnableRightSidePanel = () => {
  const snapshot = useSnapshotState();
  return snapshot.enableChatGPT || snapshot.enableWebSearch;
};

export const useEnableWebSearch = () => {
  const snapshot = useSnapshotState();
  return snapshot.enableWebSearch;
};

export const useEnableChatGPT = () => {
  const snapshot = useSnapshotState();
  return snapshot.enableChatGPT;
};

export const useChatGPTLoadingStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.chatGPTLoadingStatus;
};

export const useWebSearchParameters = () => {
  const snapshot = useSnapshotState();
  return snapshot.webSearchParameters;
};

export const useEnableHint = () => {
  const snapshot = useSnapshotState();
  return snapshot.enableHint;
};
