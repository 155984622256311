import * as Def from "./definitions";
import { getEndOfMonthDateUnixTimeSeconds, getStartOfMonthDateUnixTimeSeconds } from "./privateHelper";
import { state } from "./State";
import type * as Types from "./types";

export const updateResponse = (purchasesResponse: Types.PurchasesResponse, company: Types.Company): void => {
  state.purchasesResponses.set(`${state.page}-${state.fetchSize}-${state.purchasePeriod.startSeconds}`, purchasesResponse);
  state.company = company;
};

export const clear = (): void => {
  state.nextPager = null;
  state.page = Def.DEFAULT_PAGE;
  state.fetchSize = Def.DEFAULT_FETCH_SIZE;
  state.purchasesResponses.clear();
  state.company = null;
  state.purchasePeriod = Def.DEFAULT_PURCHASE_PERIOD;
};

export const setPage = (page: number) => {
  const targetPage = state.page < page ? state.page : page;
  const response = state.purchasesResponses.get(`${targetPage}-${state.fetchSize}-${state.purchasePeriod.startSeconds}`);
  if (response?.pager) {
    state.nextPager = response.pager;
  }
  state.page = page;
};

export const setPurchasePeriodStart = (startSeconds: number) => {
  const targetDate = new Date(startSeconds * 1000);
  const purchasePeriod: Types.State["purchasePeriod"] = {
    startSeconds: getStartOfMonthDateUnixTimeSeconds(targetDate),
    endSeconds: getEndOfMonthDateUnixTimeSeconds(targetDate),
  };
  state.purchasePeriod = purchasePeriod;
  state.page = Def.DEFAULT_PAGE;
  state.nextPager = null;
};

export const updateFetchSize = (fetchSize: number) => {
  state.fetchSize = fetchSize;
  state.page = Def.DEFAULT_PAGE;
  state.nextPager = null;
};
