import { proxy } from "valtio";

import type * as Types from "./types";

export const state = proxy<Types.State>({
  targetScreeningId: null,
  submitValues: {
    TEST_QUESTION_SETUP: {
      name: "",
      companyId: 1,
      entityTracks: [],
      timeLimitType: "RECOMMENDED",
      timeLimitMinutes: 10,
      enabledChatGPT: false,
      enabledWebSearch: false,
      enabledHint: false,
    },
    TEST_SETUP: {
      acceptableLimit: null,
      customFields: [],
      deadlineDate: null,
      isActive: true,
    },
    REPORT_SETUP: {
      memo: "",
      isPublic: true,
      editorUid: null,
      viewers: [],
      tags: [],
    },
    TEST_INVITE_SETUP: {
      invitationLanguage: "ja",
      messageForCandidate: "",
      candidateAccessPolicy: "ALLOW_ALL",
      ownerEmailAddress: "",
    },
  },
});
