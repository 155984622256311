import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import InfoIcon from "@mui/icons-material/Info";
import Alert, { AlertProps } from "@mui/material/Alert";
import Button, { type ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Trans } from "react-i18next";

const Wrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette["Gray/Shades"].p8,
  width: "100%",
  borderRadius: "4px",
}));

const InfoAlert = styled(Alert)(({ theme }) => ({
  padding: theme.spacing(0.75, 2),
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette["Gray/Shades"].p8,
}));

export type ReducedTimeMessageProps = {
  reducedTotalHours: number;
  reducedTestHours?: number;
  evaluate?: {
    jumpButton: Pick<ButtonProps, "onClick">;
    nonEvaluatedNum: number;
  };
};

const ReducedTimeMessage: React.FC<ReducedTimeMessageProps> = props => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();

  const evaluateButton: ButtonProps | undefined = props.evaluate?.jumpButton
    ? {
        ...props.evaluate.jumpButton,
        size: "small",
        color: "secondary",
        variant: "contained",
        sx: {
          textTransform: "none",
        },
        children: t("評価に進む"),
      }
    : undefined;
  const alertProps: AlertProps | undefined = props.evaluate
    ? {
        icon: <InfoIcon htmlColor={theme.palette.text.secondary} sx={{ height: "22px", width: "22px" }} />,
        variant: "filled",
        children: t2("EvaluateAndCheckReducedTimeResult", { num: props.evaluate.nonEvaluatedNum }),
        action: <Button {...evaluateButton} />,
      }
    : undefined;
  return (
    <Wrapper>
      <Stack direction="row" alignItems="center" px={2} py={1.5} width="100%" justifyContent="space-between">
        <Stack direction="row" spacing={1.5}>
          <Typography fontSize={28}>⚡️</Typography>
          <Stack spacing={0.5}>
            <Typography fontWeight={700}>{t("HireRooによって削減された面接時間")}</Typography>
            <Typography fontSize={12} color="textSecondary">
              {t("※受験者数 x (1 - 通過率) x テスト時間で算出")}
            </Typography>
          </Stack>
        </Stack>
        <Stack spacing={0.5}>
          <Stack direction="row" alignItems="end" justifyContent="flex-end" px={2}>
            <Typography fontSize={12} lineHeight="100%" color="textSecondary">
              {t("合計")}
            </Typography>
            <Typography px={0.5} fontSize={24} lineHeight="100%" fontWeight={700}>
              {props.reducedTotalHours}
            </Typography>
            <Typography fontSize={12} lineHeight="100%" color="textSecondary">
              {t2("Hours")}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Typography component={"div"} fontSize={12} color="textSecondary" display="flex">
              {typeof props.reducedTestHours === "number" ? (
                <Trans
                  values={{ hours: `<b> ${props.reducedTestHours} ${t2("Hours")}</b>` }}
                  components={{ b: <Typography px={0.5} fontSize={12} color="textPrimary" fontWeight={700} /> }}
                >
                  {t2("ReducedHoursCodingTest")}
                </Trans>
              ) : (
                t("このコーディングテストでは採点が完了しているテスト数が十分ではありません。")
              )}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      {alertProps && <InfoAlert {...alertProps} />}
    </Wrapper>
  );
};

ReducedTimeMessage.displayName = "ReducedTimeMessage";

export default ReducedTimeMessage;
