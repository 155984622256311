import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";

import IconButton, { IconButtonProps } from "../../../primitive/Button/IconButton/IconButton";

export type QuestionDetailNotificationDialogProps = {
  open: boolean;
  title: string;
  body: string;
  onClose: () => void;
};

const QuestionDetailNotificationDialog: React.FC<QuestionDetailNotificationDialogProps> = props => {
  const closeButton: IconButtonProps = {
    "aria-label": "close",
    sx: {
      position: "absolute",
      right: 8,
      top: 8,
      color: theme => theme.palette.grey[500],
    },
    size: "small",
    onClick: props.onClose,
  };
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>
        {props.title}
        <IconButton {...closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ lineHeight: "1.8" }}>{props.body}</DialogContent>
    </Dialog>
  );
};

QuestionDetailNotificationDialog.displayName = "QuestionDetailNotificationDialog";

export default QuestionDetailNotificationDialog;
