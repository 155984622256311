import * as React from "react";

import { AudioTrack } from "./types";

export type LiveCodingAudioSpeakerProps = {
  id: string;
  audioTrack?: AudioTrack;
};

const LiveCodingAudioSpeaker: React.FC<LiveCodingAudioSpeakerProps> = props => {
  const ref = React.useRef<HTMLAudioElement>(null);

  React.useEffect(() => {
    if (!props.audioTrack) {
      return;
    }
    const el = ref.current;
    if (el) {
      props.audioTrack.attach(el);
    }

    return () => {
      if (props.audioTrack) {
        const detachedEls = props.audioTrack.detach();
        detachedEls.forEach((detachedEl: HTMLMediaElement) => {
          if (detachedEl instanceof HTMLAudioElement) {
            detachedEl.srcObject = null;
          }
        });
      }
    };
  }, [props.audioTrack]);

  if (!props.audioTrack) {
    return null;
  }

  return <audio id={props.id} ref={ref} autoPlay />;
};

LiveCodingAudioSpeaker.displayName = "LiveCodingAudioSpeaker";

export default LiveCodingAudioSpeaker;
