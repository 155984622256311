import Box from "@mui/material/Box";
import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";

import type * as Types from "../../types";
import ChildNavigationMenuFactory, { ChildNavigationMenuFactoryProps } from "./parts/ChildNavigationMenuFactory/ChildNavigationMenuFactory";
import NavigationMenuItem from "./parts/NavigationMenuItem/NavigationMenuItem";
import SidePanelNavigationMenu from "./parts/SidePanelNavigationMenu/SidePanelNavigationMenu";

const StyledList = styled(List)(() => ({
  padding: 0,
}));

export type PopupMenuProps = {
  root?: boolean;
  items: Types.MenuItem[];
};

const PopupMenu: React.FC<PopupMenuProps> = props => {
  return (
    <Box>
      <StyledList>
        {props.items.map(item => {
          if ("title" in item) {
            const childNavigationMenuFactoryProps: ChildNavigationMenuFactoryProps = {
              ...item,
              NavigationMenu: SidePanelNavigationMenu,
            };
            return <ChildNavigationMenuFactory key={item.id} {...childNavigationMenuFactoryProps} />;
          }
          if (props.root) {
            return (
              <Tooltip key={item.id} title={item.children} placement="left-start">
                <span>
                  <NavigationMenuItem key={item.id} {...item} root={props.root} />
                </span>
              </Tooltip>
            );
          }
          return <NavigationMenuItem key={item.id} {...item} root={props.root} />;
        })}
      </StyledList>
    </Box>
  );
};

PopupMenu.displayName = "PopupMenu";

export default PopupMenu;
