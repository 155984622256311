import { generateKey } from "@hireroo/app-helper/parser";
import { proxyMap } from "valtio/utils";

import { state } from "./State";
import type * as Types from "./types";

export type CreateStatisticsStateArgs = {
  uniqueKey: Types.UniqueKey;
  query: Partial<Types.Query>;
};

export const createTotalScoreStatisticsState = (args: CreateStatisticsStateArgs): void => {
  const totalScoreStatistics: Types.TotalScoreStatistics = {
    currentQuery: {
      scope: "GLOBAL",
      enableDate: true,
      startDate: null,
      endDate: null,
      tags: [],
      ...args.query,
    },
    queryResultCacheMap: proxyMap(),
  };
  state.totalScoreStatisticsMap.set(args.uniqueKey, totalScoreStatistics);
};

export const clear = () => {
  state.totalScoreStatisticsMap.clear();
};

export const clearQueryResultCache = (uniqueKey: Types.UniqueKey) => {
  const entityState = state.totalScoreStatisticsMap.get(uniqueKey);
  entityState?.queryResultCacheMap.clear();
};

export const updateQuery = (uniqueKey: Types.UniqueKey, query: Types.Query): void => {
  const entity = state.totalScoreStatisticsMap.get(uniqueKey);
  if (entity) {
    entity.currentQuery = query;
  }
};

export const refreshQuery = (uniqueKey: Types.UniqueKey) => {
  /** FIXME: stop using setTimeout  */
  setTimeout(
    () => {
      const entity = state.totalScoreStatisticsMap.get(uniqueKey);
      if (entity) {
        entity.currentQuery = { ...entity.currentQuery };
      }
    },
    /** FIXME: only quiz took longer time to initialize  */
    uniqueKey.includes("Quiz") ? 1000 : 400,
  );
};

export const setResult = (uniqueKey: Types.UniqueKey, query: Types.Query, result: Types.Result) => {
  const entity = state.totalScoreStatisticsMap.get(uniqueKey);
  const queryKey = generateKey(query);
  if (entity) {
    entity.queryResultCacheMap.set(queryKey, result);
  }
};
