import { proxy } from "valtio";
import { proxyMap } from "valtio/utils";

import type * as Types from "./types";

export const state = proxy<Types.State>({
  companySpotStats: null,
  screeningsRes: null,
  screeningMap: proxyMap(),
  selectedScreeningId: null,
  selectedScreening: null,
});
