import { useTranslation } from "@hireroo/i18n";
import { ScreeningTestListForm } from "@hireroo/validator";
import AccountTreeOutlined from "@mui/icons-material/AccountTreeOutlined";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useController } from "react-hook-form";

import Link, { LinkProps } from "../../../../../../primitive/Link/Link";
import ActionMenu, { ActionMenuProps } from "./parts/ActionMenu/ActionMenu";
import Evaluation, { EvaluationProps } from "./parts/Evaluation/Evaluation";
import Rank, { RankProps } from "./parts/Rank/Rank";
import Score, { ScoreProps } from "./parts/Score/Score";
import Status, { StatusProps } from "./parts/Status/Status";
import SuspiciousDegree, { SuspiciousDegreeProps } from "./parts/SuspiciousDegree/SuspiciousDegree";

const SELECTED_CLASS_NAME = "__selected__";
const EllipsisText = styled(Typography)(() => ({
  textOverflow: "ellipsis",
  fontSize: "14px",
  maxWidth: "150px",
  display: "inline-block",
  overflow: "hidden",
  whiteSpace: "nowrap",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderTop: "1px solid",
  borderBottom: "1px solid",
  borderColor: theme.palette.Other.Divider,
  display: "table-row",
  [`&.${SELECTED_CLASS_NAME}`]: {
    backgroundColor: theme.palette["Secondary/Shades"].p8,
  },
}));

const StyledCheckbox = styled(Checkbox)(() => ({
  width: "38px",
  height: "38px",
  padding: "9px",
}));

const StyledTableCell = styled(TableCell)(() => ({
  border: "none",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  display: "table-cell",
}));

const CustomTableRow: React.FC<LinkProps> = props => {
  // FIXME I would like to ask the mui team how to define this type definition accurately.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <ButtonBase as={Link} {...props} disableRipple />;
};

type Mode = "DEFAULT" | "SELECTABLE";

export type ScreeningTestListTableRowProps = {
  enabledMultiSelect: boolean;

  enabledStatisticsContents: boolean;
  enabledScreeningName: boolean;
  enabledSuspiciousDegree: boolean;

  id: string;
  name: string;
  disabled?: boolean;
  mode: Mode;
  href: string;
  onClick: () => void;
  candidateName: string;
  candidateEmail?: string;
  screeningName?: string;
  status: StatusProps;
  tags: string[];
  score?: ScoreProps;
  rank?: RankProps;
  suspiciousDegree?: SuspiciousDegreeProps;
  evaluation: EvaluationProps;
  actionMenu: ActionMenuProps;
};

const ScreeningTestListTableRow: React.FC<ScreeningTestListTableRowProps> = props => {
  const { mode } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const { field } = useController<Record<string, ScreeningTestListForm.ScreeningTestListItem>>({
    name: props.name,
  });
  const checkboxProps: CheckboxProps = {
    checked: field.value.selected,
    size: "small",
    color: "secondary",
    onClick: event => {
      event.stopPropagation();
    },
    onChange: (_event, checked) => {
      const newFieldValue: ScreeningTestListForm.ScreeningTestListItem = {
        ...field.value,
        selected: checked,
      };
      field.onChange(newFieldValue);
    },
  };
  return (
    <StyledTableRow
      as={!props.disabled ? CustomTableRow : undefined}
      // FIXME I would like to ask the mui team how to define this type definition accurately.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      component={"a"}
      href={mode === "SELECTABLE" ? "" : props.href}
      className={field.value.selected ? SELECTED_CLASS_NAME : undefined}
      onClick={event => {
        event.stopPropagation();
        event.preventDefault();
        if (mode === "SELECTABLE") {
          const newFieldValue: ScreeningTestListForm.ScreeningTestListItem = {
            ...field.value,
            selected: !field.value.selected,
          };
          field.onChange(newFieldValue);
        } else {
          props.onClick?.();
        }
      }}
    >
      {props.enabledMultiSelect && (
        <StyledTableCell component="div" align="center" sx={{ width: "38px" }}>
          <StyledCheckbox {...checkboxProps} />
        </StyledTableCell>
      )}
      <StyledTableCell component="div">
        <Box display="flex" alignItems="center" maxWidth="350px" whiteSpace="pre-wrap" flexWrap="wrap">
          <Typography whiteSpace="pre-wrap" maxWidth="350px" mr={1} sx={{ wordBreak: "break-word" }}>
            {props.candidateName}
          </Typography>
          {props.candidateEmail && (
            <Typography fontSize={12} color="textSecondary">
              {props.candidateEmail}
            </Typography>
          )}
        </Box>
      </StyledTableCell>
      <StyledTableCell component="div">
        <Tooltip title={props.tags.join(", ")}>
          <EllipsisText color="textSecondary">{props.tags.join(" ")}</EllipsisText>
        </Tooltip>
      </StyledTableCell>
      {props.enabledScreeningName && (
        <StyledTableCell component="div" sx={{ maxWidth: "10%" }}>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <AccountTreeOutlined fontSize="small" sx={{ color: theme.palette.text.secondary, width: "18px", height: "18px" }} />
            <Typography fontSize={13} color="textSecondary">
              {props.screeningName}
            </Typography>
          </Stack>
        </StyledTableCell>
      )}
      <StyledTableCell component="div">
        <Status {...props.status} />
      </StyledTableCell>
      <StyledTableCell component="div" align="center">
        {props.score && <Score {...props.score} />}
      </StyledTableCell>
      {props.enabledStatisticsContents && (
        <StyledTableCell component="div" align="center">
          {props.rank && <Rank {...props.rank} />}
        </StyledTableCell>
      )}
      {props.enabledSuspiciousDegree && (
        <StyledTableCell component="div" align="center">
          {props.suspiciousDegree && <SuspiciousDegree {...props.suspiciousDegree} />}
        </StyledTableCell>
      )}
      <StyledTableCell component="div" align="center">
        <Evaluation {...props.evaluation} />
      </StyledTableCell>
      <StyledTableCell component="div" align="right">
        <ActionMenu {...props.actionMenu} disabled={mode === "SELECTABLE"} tooltipText={t("複数選択中はこの操作はできません。")} />
      </StyledTableCell>
    </StyledTableRow>
  );
};

ScreeningTestListTableRow.displayName = "ScreeningTestListTableRow";

export default ScreeningTestListTableRow;
