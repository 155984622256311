import { SystemDesignTestReport } from "@hireroo/app-store/view-domain/SystemDesignTestReport";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import { Widget } from "@hireroo/presentation";

export type GenerateHeaderPropsArgs = {
  entityId: number;
};

export const useGenerateHeaderProps = (args: GenerateHeaderPropsArgs): Widget.SystemDesignTestReportProps["header"] => {
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const hooks = SystemDesignTestReport.useCreateSystemDesignHooks(args.entityId);
  const question = hooks.useQuestion();

  return {
    title: [resolveLanguage(question, lang, "title")].join(" "),
    linkButton: {
      onClick: async () => {
        Snackbar.notify({
          severity: "info",
          message: t("クリップボードにコピーされました"),
        });
      },
      url: location.href,
    },
    detailButton: {},
  };
};
