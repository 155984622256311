import { styled } from "@mui/material/styles";
import * as React from "react";

import ScreeningTestLayout, { ScreeningTestLayoutProps } from "../../layouts/ScreeningTestLayout/ScreeningTestLayout";

const StyledLayout = styled(ScreeningTestLayout)`
  overflow: hidden;
`;

export type ScreeningTestDetailForCandidateProps = {
  layout: Omit<ScreeningTestLayoutProps, "children">;
  children: React.ReactNode;
};

const ScreeningTestDetailForCandidate: React.FC<ScreeningTestDetailForCandidateProps> = props => {
  return <StyledLayout {...props.layout}>{props.children}</StyledLayout>;
};

ScreeningTestDetailForCandidate.displayName = "ScreeningTestDetailForCandidate";

export default ScreeningTestDetailForCandidate;
