import { useTranslation } from "@hireroo/i18n";
import { AlgorithmTestCaseForm } from "@hireroo/validator";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { useAlgorithmAnswersFormContext } from "../../../Context";
import DBTestCaseRunnerRow from "../parts/DBTestCaseRunnerRow";
import OutputStatusSection from "../parts/OutputStatusSection";
import TestCaseRunnerRow, { TestCaseRunnerRowProps } from "../parts/TestCaseRunnerRow";
import { OutputWithStatus } from "../parts/types";

export type CorrectnessTableRowsProps = {
  id: string;
  correctnessTestCase: AlgorithmTestCaseForm.CorrectnessTestCaseSchema;
  outputStatus?: OutputWithStatus;
  runButton: TestCaseRunnerRowProps["runButton"];
};

const CorrectnessTableRows: React.FC<CorrectnessTableRowsProps> = props => {
  const { t } = useTranslation();
  const { signatureProps } = useAlgorithmAnswersFormContext();
  const [open, setOpen] = React.useState(false);
  const { outputStatus, correctnessTestCase } = props;

  const testcaseRunnerRow: TestCaseRunnerRowProps = {
    runButton: props.runButton,
    testCase: {
      id: props.id,
      title: correctnessTestCase?.title_ja ?? correctnessTestCase?.title_en ?? "",
      inputs: correctnessTestCase.inputs,
      outputs: correctnessTestCase.outputs,
    },
    outputStatus: outputStatus?.status,
    isOpen: open,
    expandButton: {
      onClick: () => setOpen(!open),
    },
  };

  return (
    <>
      {signatureProps.variant === "ALGORITHM" && <TestCaseRunnerRow {...testcaseRunnerRow} />}
      {signatureProps.variant === "DATABASE" && (
        <DBTestCaseRunnerRow
          {...testcaseRunnerRow}
          previewDialog={{
            signature: signatureProps.signature,
            testCase: testcaseRunnerRow.testCase,
          }}
        />
      )}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderBottom: "unset" }} colSpan={correctnessTestCase.inputs.length + 5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box mt={2} mx={1} mb={1} p={1}>
              {outputStatus?.output ? (
                <OutputStatusSection output={outputStatus.output} variant={signatureProps.variant} />
              ) : (
                <Typography variant="caption">{t("出力されたログは存在しません。")}</Typography>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

CorrectnessTableRows.displayName = "CorrectnessTableRows";

export default CorrectnessTableRows;
