import { extractUniqueEntity } from "@hireroo/app-helper/entity";
import { App } from "@hireroo/app-store/essential/talent";
import { AssessmentReport } from "@hireroo/app-store/widget/t/AssessmentReport";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import AssessmentReportContainer, { AssessmentReportContainerProps } from "./Container";
import * as Subscriber from "./Subscriber";

export type AssessmentReportFetchContainerProps = {
  examId: string;
  reportSettings: Graphql.AssessmentReportSettings | null;
};

const AssessmentReportFetchContainer: React.FC<AssessmentReportFetchContainerProps> = props => {
  const appStatus = App.useStatus();
  const initialized = AssessmentReport.useInitialized();
  const [result, refresh] = Graphql.useGetExamReportQuery({
    variables: {
      examId: props.examId,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "cache-and-network",
  });
  React.useEffect(() => {
    const stop = Subscriber.startSubscribeOnChangeTest();

    return () => {
      AssessmentReport.clear();
      stop();
    };
  }, []);
  React.useEffect(() => {
    if (result.data?.exam) {
      const uniqueEntity = extractUniqueEntity(window.location.hash);
      const isValid = result.data.exam.entities.some(entity => entity.entityId === uniqueEntity?.id);
      const firstUniqueEntityId = AssessmentReport.generateUniqueEntityId(result.data.exam.entities[0]);
      const entityId: AssessmentReport.UniqueEntityId =
        uniqueEntity && isValid ? `${uniqueEntity.type}-${uniqueEntity.id}` : firstUniqueEntityId;
      AssessmentReport.initialize(result.data.exam, entityId);
    }
  }, [result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="TOP" color="secondary" />;
  }

  const containerProps: AssessmentReportContainerProps = {
    reportSettings: props.reportSettings,
  };
  return (
    <ErrorBoundary>
      <AssessmentReportContainer {...containerProps} />
    </ErrorBoundary>
  );
};

AssessmentReportFetchContainer.displayName = "AssessmentReportFetchContainer";

export default withErrorBoundary(AssessmentReportFetchContainer, {});
