import { SplitProvider } from "@hireroo/react-split";
import * as React from "react";
export type StepName = "SELECT_QUESTION_TYPE" | "SELECT_QUESTION";

export type ContextValue = {
  activeStep: number;
  activeStepName: StepName;
  setActiveStep: (stepName: StepName) => void;
};

const StepMap: Record<StepName, number> = {
  SELECT_QUESTION_TYPE: 0,
  SELECT_QUESTION: 1,
};

const StepMapReverse: Record<number, StepName> = {
  0: "SELECT_QUESTION_TYPE",
  1: "SELECT_QUESTION",
};

export const RemoteInterviewForEmployeeContext = React.createContext<ContextValue>({
  activeStep: 0,
  activeStepName: "SELECT_QUESTION_TYPE",
  setActiveStep: () => undefined,
});

export const useRemoteInterviewForEmployeeContext = () => React.useContext(RemoteInterviewForEmployeeContext);

export type RemoteInterviewForEmployeeProviderProps = {};

export const RemoteInterviewForEmployeeProvider: React.FC<React.PropsWithChildren<RemoteInterviewForEmployeeProviderProps>> = props => {
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const contextValue: ContextValue = {
    activeStep: activeStep,
    activeStepName: StepMapReverse[activeStep],
    setActiveStep: stepName => {
      setActiveStep(StepMap[stepName]);
    },
  };
  return (
    <SplitProvider>
      <RemoteInterviewForEmployeeContext.Provider value={contextValue} children={props.children} />
    </SplitProvider>
  );
};
