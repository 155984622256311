import Stack from "@mui/material/Stack";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type VariableTextFiledProps = {
  id: string;
  label: string;
  onChange: TextFieldProps["onChange"];
  helperText?: string;
};

const VariableTextFiled: React.FC<VariableTextFiledProps> = props => {
  const textFieldProps: TextFieldProps = {
    variant: "outlined",
    InputLabelProps: {
      shrink: true,
    },
    color: "secondary",
    onChange: props.onChange,
    helperText: props.helperText,
  };
  return (
    <Stack direction="column" spacing={1.5}>
      <Typography fontSize={14} fontWeight={700}>
        {props.label}
      </Typography>
      <TextField {...textFieldProps} size="small" />
    </Stack>
  );
};

VariableTextFiled.displayName = "VariableTextFiled";

export default VariableTextFiled;
