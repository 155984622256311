import { add, differenceInSeconds, isBefore, secondsToMinutes } from "date-fns";

export const sleep = (ms: number): Promise<void> => {
  return new Promise<void>(resolve => setTimeout(resolve, ms));
};

export const timeInSeconds = (): number => {
  return new Date().getTime();
};

export const calculateTimeLimit = (willEndAtSeconds: number, timeLimitSeconds: number) => {
  const now = new Date();
  const willFinishDate = add(now, { seconds: timeLimitSeconds ?? 0 });
  const willEndAtDate = new Date((willEndAtSeconds ?? 0) * 1000);

  const comparingDate = isBefore(willEndAtDate, willFinishDate) ? willEndAtDate : willFinishDate;
  return secondsToMinutes(differenceInSeconds(comparingDate, now));
};
