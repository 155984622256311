import { state } from "./State";
import type * as Types from "./types";

export const clear = () => {
  state.endInterviewStatus = null;
  state.demo = null;
};

export const setDemo = (demo: Types.Demo): void => {
  state.demo = demo;
};

export const updateEndInterviewStatus = (status: Types.EndInterviewStatus) => {
  if (state.endInterviewStatus === null) {
    state.endInterviewStatus = status;
  }
};

export const updateEndInterviewLoadingStatus = (status: Types.EndInterviewLoadingStatus) => {
  state.endInterviewLoadingStatus = status;
};
