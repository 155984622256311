import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type EvaluationMetricSearchFieldContainerProps = {};

const EvaluationMetricGroupSearchFieldContainer: React.FC<EvaluationMetricSearchFieldContainerProps> = () => {
  const evaluationMetricSearchFieldProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.EvaluationMetricSearchField {...evaluationMetricSearchFieldProps} />
    </ErrorBoundary>
  );
};

EvaluationMetricGroupSearchFieldContainer.displayName = "EvaluationMetricGroupSearchFieldContainer";

export default withErrorBoundary(EvaluationMetricGroupSearchFieldContainer, {});
