import { type Language, translation } from "./language";

export type RemainingTimeFormatArgs = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  language: Language;
};

export const remainingTimeFormat = (args: RemainingTimeFormatArgs): string => {
  const { days, hours, minutes, seconds, language } = args;

  const d = translation.day[language];
  const h = translation.hours[language];
  const m = translation.minutes[language];
  const s = translation.seconds[language];

  if (days <= 0 && hours <= 0 && minutes <= 0) return `${Math.trunc(seconds)}${s}`;
  if (days <= 0 && hours <= 0) return `${minutes}${m}`;
  if (days <= 0) return `${hours}${h} ${("00" + String(minutes)).slice(-2)}${m}`;

  return `${days}${d} ${hours}${translation.hours[language]} ${("00" + String(minutes)).slice(-2)}${m}`;
};
