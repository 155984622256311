import { useTranslation } from "@hireroo/i18n";
import { RemoteInterviewSearchForm, TestSearchForm as TestSearchFormValidator } from "@hireroo/validator";

import type { SearchFormProps } from "../../modules/SearchForm/SearchForm";

type FieldProps = SearchFormProps["fields"][0];
export type MultiChoiceFieldProps = Extract<FieldProps, { kind: "MULTI_CHOICE" }>;
export type SingleChoiceFieldProps = Extract<FieldProps, { kind: "SINGLE_CHOICE" }>;
export type GroupMultiChoiceFieldProps = Extract<FieldProps, { kind: "GROUP_MULTI_CHOICE" }>;
export type RangeFieldProps = Extract<FieldProps, { kind: "RANGE" }>;
export type TagFieldProps = Extract<FieldProps, { kind: "TAG" }>;

type FieldKeyName = keyof RemoteInterviewSearchForm.RemoteInterviewSearchQuerySchema;

const FieldName = {
  TEXT_FILTER: "textFilter",
  STATUSES: "statuses",
  TAGS: "tags",
  RESULT: "result",
  CREATED_BY: "createdBy",
} satisfies Record<string, FieldKeyName>;

type StatusesOption = {
  displayName: string;
  value: RemoteInterviewSearchForm.RemoteInterviewSearchQuerySchema["statuses"][0];
};

export const useStatusesField = (): MultiChoiceFieldProps => {
  const { t } = useTranslation();
  const options: StatusesOption[] = [
    { value: "CREATED", displayName: t("作成済み") },
    { value: "STARTED", displayName: t("実施中") },
    { value: "COMPLETED", displayName: t("実施済み") },
    { value: "EVALUATED", displayName: t("評価済み") },
  ];
  return {
    kind: "MULTI_CHOICE",
    title: t("ステータス"),
    name: FieldName.STATUSES,
    options: options,
  };
};

export const useTextFilterField = (): SearchFormProps["textFilter"] => {
  const { t } = useTranslation();
  return {
    name: FieldName.TEXT_FILTER,
    placeholder: t("タイトルを検索"),
  };
};
type ResultOption = {
  displayName: string;
  value: Exclude<TestSearchFormValidator.TestSearchQuerySchema["result"], null>;
};

export const useResultsField = (): SingleChoiceFieldProps => {
  const { t } = useTranslation();
  const options: ResultOption[] = [
    { value: "PASSED", displayName: t("合格") },
    { value: "NOT_PASSED", displayName: t("不合格") },
  ];
  return {
    kind: "SINGLE_CHOICE",
    title: t("結果"),
    name: FieldName.RESULT,
    options: options,
  };
};

export const useCreatedByField = (props: Pick<MultiChoiceFieldProps, "options">): MultiChoiceFieldProps => {
  const { t } = useTranslation();
  return {
    ...props,
    kind: "MULTI_CHOICE",
    name: FieldName.CREATED_BY,
    title: t("作成者"),
  };
};

export const useTagsField = (props: Pick<TagFieldProps, "selectableTags">): TagFieldProps => {
  const { t } = useTranslation();
  return {
    ...props,
    kind: "TAG",
    name: FieldName.TAGS,
    title: t("タグ"),
  };
};
