import * as React from "react";

import { ChatGPTSidePanel, ChatGPTSidePanelProps } from "../../ms-components/Zeus";

export type ChallengeCodingEditorChatGPTRightSidePanelProps = {
  chatGPTSidePanel: ChatGPTSidePanelProps;
};

const ChallengeCodingEditorChatGPTRightSidePanel: React.FC<ChallengeCodingEditorChatGPTRightSidePanelProps> = props => {
  return <ChatGPTSidePanel {...props.chatGPTSidePanel} />;
};

ChallengeCodingEditorChatGPTRightSidePanel.displayName = "ChallengeCodingEditorChatGPTRightSidePanel";

export default ChallengeCodingEditorChatGPTRightSidePanel;
