import { useTranslation } from "@hireroo/i18n";
import OnlineCollaboration from "@hireroo/ui-assets/images/Remote/OnlineCollaboration.svg";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type MessageForCandidateProps = {};

const MessageForCandidate: React.FC<MessageForCandidateProps> = () => {
  const { t } = useTranslation();
  return (
    <Box width="100vw" height="100%" bgcolor="background.default" justifyContent="center" alignItems="center" display="flex">
      <Box textAlign={"center"} mx={10} justifyContent="center" alignItems="center" display="flex" flexDirection="column">
        <img src={OnlineCollaboration} width={600} height={400} alt={t("待合室の画像")} />
        <Box mt={2} ml={2} />
        <Box display="flex" flexDirection="column" mb={1} mx={2}>
          <Typography role="name" gutterBottom sx={{ lineHeight: "1.7" }}>
            {t("まだインタビューが開始されていません。")} <br />
            {t("面接官がインタビューを開始するまでお待ちください。")}
            <br />
            {t("開始されると画面は自動で切り替わります。")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

MessageForCandidate.displayName = "MessageForCandidate";

export default MessageForCandidate;
