import * as React from "react";

import Block, { BlockProps } from "./parts/Block";

export type BlockBuilderProps = {
  blocks: BlockProps[];
};

const BlockBuilder: React.FC<BlockBuilderProps> = props => {
  return (
    <>
      {props.blocks.map((blockProps, index) => (
        <Block key={`block-${index}`} {...blockProps} />
      ))}
    </>
  );
};

BlockBuilder.displayName = "BlockBuilder";

export default BlockBuilder;
