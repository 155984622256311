import { type SupportLanguageValue, useTranslation } from "@hireroo/i18n";
import { QuizQuestionForm } from "@hireroo/validator";
import Delete from "@mui/icons-material/Delete";
import HelpIcon from "@mui/icons-material/Help";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Radio, { RadioProps } from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import MarkdownPreviewEditor, { MarkdownPreviewEditorProps } from "../../../../usecase/MarkdownPreviewEditor/MarkdownPreviewEditor";

export type SingleChoiceItemProps = {
  name: string;
  index: number;
  language: SupportLanguageValue;
  deleteButton: Pick<IconButtonProps, "onClick">;
};

const SingleChoiceItem: React.FC<SingleChoiceItemProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [showAdditional, setShowAdditional] = React.useState(false);
  const method = useFormContext<QuizQuestionForm.QuizQuestionFormSchema>();
  const state = method.getValues("singleChoiceQuestions")[props.index];
  const content = state.content[props.language];
  const titleMarkdownPreviewEditor: MarkdownPreviewEditorProps = {
    label: t("詳細"),
    placeholder: "ex) SELECT * FROM $TABLE_NAME",
    helperText: t("設問の本文を入力します。本文はMarkdown形式で入力できます。"),
    defaultValue: content?.title,
    required: true,
  };
  const descriptionMarkdownPreviewEditor: MarkdownPreviewEditorProps = {
    label: t("解説"),
    placeholder: t("これは正しい選択肢です。"),
    helperText: t("解説を入力します。本文はMarkdown形式で入力できます。"),
    defaultValue: content?.additionalDetail,
  };
  const additionalButton: ButtonProps = {
    onClick: () => {
      setShowAdditional(prev => !prev);
    },
    startIcon: showAdditional ? <KeyboardArrowUp /> : <KeyboardArrowDown />,
    children: showAdditional ? t("解説を閉じる") : t("解説を入力"),
  };
  const deleteButton: IconButtonProps = {
    ...props.deleteButton,
    color: "default",
    sx: { justifyContent: "right" },
    "aria-label": "remove-type",
    size: "small",
  };
  const singleChoiceCorrectOptionIndex = method.watch("singleChoiceCorrectOptionIndex");
  const radioProps: RadioProps = {
    checked: singleChoiceCorrectOptionIndex.toString() === props.index.toString(),
  };
  return (
    <Stack direction="column">
      <Box display="flex" justifyContent="space-between" mb={1}>
        <RadioGroup {...method.register("singleChoiceCorrectOptionIndex")}>
          <FormControlLabel control={<Radio {...radioProps} />} label={t("正解")} value={props.index} />
        </RadioGroup>
        <Tooltip title={t("選択肢を削除する")} placement="top">
          <span>
            <IconButton {...deleteButton} component="span">
              <Delete />
            </IconButton>
          </span>
        </Tooltip>
      </Box>

      <Box>
        <MarkdownPreviewEditor
          {...titleMarkdownPreviewEditor}
          name={`singleChoiceQuestions.${props.index}.content.${props.language}.title`}
          areaSize="xs"
        />
      </Box>

      <Box display="flex" justifyContent="flex-start" mt={1} mb={1} alignItems="center">
        <Button {...additionalButton} />
        <Tooltip
          title={`${t(
            "選択肢に対しての解説を入力することができます。候補者が解説付きの選択肢を選んで提出しテストを終えるとレポート画面で解説が表示されます。こちらは必須項目ではありません。",
          )}`}
          placement="top"
        >
          <HelpIcon sx={{ color: theme.palette.grey["500"], ml: 1 }} fontSize="small" />
        </Tooltip>
      </Box>

      {showAdditional && (
        <MarkdownPreviewEditor
          {...descriptionMarkdownPreviewEditor}
          name={`singleChoiceQuestions.${props.index}.content.${props.language}.additionalDetail`}
          areaSize="xs"
        />
      )}
    </Stack>
  );
};

SingleChoiceItem.displayName = "SingleChoiceItem";

export default SingleChoiceItem;
