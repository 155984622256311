import { proxy } from "valtio";

import type { State } from "./types";

export const state = proxy<State>({
  company: null,
  employeeInvitation: {
    status: "READY",
    id: null,
  },
  talentInvitation: {
    status: "READY",
    id: null,
  },
  editRole: {
    selectedMemberId: null,
  },
  deleteMember: {
    selectedMemberId: null,
  },
  addMemberToGroups: {
    selectedMemberId: "",
    selectedGroupIds: [],
    searchText: "",
  },
  selectedMemberIds: [],
  dialogStatus: "CLOSE",
  filterText: "",
});
