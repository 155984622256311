import * as React from "react";

import AssessmentLayout, { AssessmentLayoutProps } from "../../layouts/AssessmentLayout/AssessmentLayout";

export type AssessmentProps = {
  layout: Omit<AssessmentLayoutProps, "children">;
  Content: React.ReactNode;
};

const Assessment: React.FC<AssessmentProps> = props => {
  return <AssessmentLayout {...props.layout}>{props.Content}</AssessmentLayout>;
};

Assessment.displayName = "Assessment";

export default Assessment;
