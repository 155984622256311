import Box from "@mui/material/Box";
import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import * as React from "react";

type LoadingKind = "TOP" | "CENTER" | "CENTER_%";

const TopedBox = styled(Box)`
  width: 100%;
  height: 100%;
  padding-top: 2em;
  padding-bottom: 2em;
  text-align: center;
`;

const CenteredBox = styled(Box)`
  width: 100vw;
  height: 100vh;
  padding-top: 2em;
  padding-bottom: 2em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CenteredBox100Percent = styled(Box)`
  width: 100%;
  height: 100%;
  padding-top: 2em;
  padding-bottom: 2em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export type LoadingProps = {
  kind: LoadingKind;
  /**
   * default "secondary"
   */
  color?: CircularProgressProps["color"];
};

const Loading: React.FC<LoadingProps> = props => {
  const { color = "secondary" } = props;
  const LoadingContainerMap: Record<LoadingKind, React.ReactNode> = {
    TOP: (
      <TopedBox>
        <CircularProgress color={color} size={30} />
      </TopedBox>
    ),
    CENTER: (
      <CenteredBox>
        <CircularProgress color={color} size={30} />
      </CenteredBox>
    ),
    "CENTER_%": (
      <CenteredBox100Percent>
        <CircularProgress color={color} size={30} />
      </CenteredBox100Percent>
    ),
  };
  return <>{LoadingContainerMap[props.kind]}</>;
};

Loading.displayName = "Loading";

export default Loading;
