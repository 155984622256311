import ButtonBase from "@mui/material/ButtonBase";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DifficultyStars, { DifficultyStarsProps } from "../../../modules/DifficultyStars/DifficultyStars";
import DivTableCell from "../../../primitive/DivTableCell/DivTableCell";
import DivTableRow, { DivTableRowProps } from "../../../primitive/DivTableRow/DivTableRow";
import Link, { LinkProps } from "../../../primitive/Link/Link";
import LeakScoreChip, { LeakScoreChipProps } from "../LeakScoreChip/LeakScoreChip";
import QuestionMeta, { QuestionMetaProps } from "../QuestionMeta/QuestionMeta";

const StyledTableRow = styled(DivTableRow)(({ theme }) => ({
  borderTop: "1px solid",
  borderBottom: "1px solid",
  borderColor: theme.palette.Other.Divider,
  "&.Mui-selected": {
    backgroundColor: theme.palette["Secondary/Shades"].p30,
    ":hover": {
      backgroundColor: theme.palette["Secondary/Shades"].p16,
    },
  },
  display: "table-row",
}));

const StyledTableCell = styled(DivTableCell)(() => ({
  border: "none",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
}));

const CustomTableRow: React.FC<LinkProps> = props => {
  // FIXME I would like to ask the mui team how to define this type definition accurately.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <ButtonBase as={Link} {...props} disableRipple />;
};

const StyledDifficultyStars = styled(DifficultyStars)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

/**
 * Column
 * 1. [Required] Meta
 * 2. [Required] Expected time limit
 * 3. [Optional] Average Time To Answer
 * 4. [Required] Correctness Rate
 * 5. [Required] Number of User
 * 6. [Optional] ActionComponent
 */
export type QuestionTableRowProps = {
  meta: QuestionMetaProps;
  correctnessRate: number;
  expectedTimelimit: string;
  averageTimeToAnswer?: string;
  numUses: number;
  onClick?: DivTableRowProps["onClick"];
  ActionComponent?: React.ReactNode;
  selected?: boolean;
  href?: string;
  leakScoreChip?: LeakScoreChipProps;
  showLeakScore: boolean;
  difficulty: DifficultyStarsProps["difficulty"];
};

const QuestionTableRow: React.FC<QuestionTableRowProps> = props => {
  return (
    <StyledTableRow
      as={CustomTableRow}
      // FIXME I would like to ask the mui team how to define this type definition accurately.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      component={"a"}
      href={props.href}
      onClick={props.onClick}
      selected={props.selected}
      color="secondary"
    >
      <StyledTableCell>
        <QuestionMeta {...props.meta} />
      </StyledTableCell>
      {props.difficulty && (
        <StyledTableCell align="center">
          <StyledDifficultyStars difficulty={props.difficulty} />
        </StyledTableCell>
      )}
      {props.showLeakScore && (
        <StyledTableCell align="center">{props.leakScoreChip && <LeakScoreChip {...props.leakScoreChip} />}</StyledTableCell>
      )}
      <StyledTableCell align="center">
        <Typography fontSize={14} color="text.secondary">
          {props.expectedTimelimit}
        </Typography>
      </StyledTableCell>
      {typeof props.averageTimeToAnswer === "string" && (
        <StyledTableCell align="center">
          <Typography fontSize={14}>{props.averageTimeToAnswer}</Typography>
        </StyledTableCell>
      )}
      <StyledTableCell align="center">
        <Typography fontSize={14}>{`${props.correctnessRate}%`}</Typography>
      </StyledTableCell>
      <StyledTableCell align="center">
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Typography fontSize={14}>{props.numUses?.toLocaleString()}</Typography>
        </Stack>
      </StyledTableCell>
      {props.ActionComponent && <StyledTableCell align="center">{props.ActionComponent}</StyledTableCell>}
    </StyledTableRow>
  );
};

QuestionTableRow.displayName = "QuestionTableRow";

export default QuestionTableRow;
