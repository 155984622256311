import * as React from "react";

export type ColorMode = "DARK" | "LIGHT";

export type ContextValue = {
  colorMode: ColorMode;
  setColorMode: (mode: ColorMode) => void;
};

export const ColorModeContext = React.createContext<ContextValue>({} as ContextValue);

export const useColorModeContext = () => React.useContext(ColorModeContext);

export type ColorModeProviderProps = {
  defaultColorMode?: ColorMode;
};

const validateColorMode = (colorMode?: string): colorMode is ColorMode => {
  return colorMode === "DARK" || colorMode === "LIGHT";
};

export const ColorModeProvider: React.FC<React.PropsWithChildren<ColorModeProviderProps>> = props => {
  const [colorMode, setColorMode] = React.useState<ColorMode>(validateColorMode(props.defaultColorMode) ? props.defaultColorMode : "DARK");

  const contextValue: ContextValue = {
    colorMode,
    setColorMode: colorMode => {
      if (validateColorMode(colorMode)) {
        setColorMode(colorMode);
      }
    },
  };
  return <ColorModeContext.Provider value={contextValue} children={props.children} />;
};
