import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";

import { FileNode } from "../../../../helpers/fileTree";

export type Kind = "CREATE_FILE" | "CREATE_DIRECTORY" | "DELETE_NODE";

const iconMap: Record<Kind, React.ReactNode> = {
  CREATE_FILE: <NoteAddOutlinedIcon fontSize="small" />,
  CREATE_DIRECTORY: <CreateNewFolderOutlinedIcon fontSize="small" />,
  DELETE_NODE: <DeleteOutlineIcon fontSize="small" />,
};

type ClickEvent = Parameters<Exclude<IconButtonProps["onClick"], undefined>>[0];

export type NavigationItemProps = {
  kind: Kind;
  title: string;
  disabledReason?: string;
  node: FileNode;
  onClick?: (event: ClickEvent, node: FileNode) => void;
} & Pick<IconButtonProps, "disabled" | "sx">;

const NavigationItem: React.FC<NavigationItemProps> = props => {
  const { title, kind, node, disabledReason, ...iconButtonProps } = props;
  const handleClick: Exclude<IconButtonProps["onClick"], undefined> = event => {
    props.onClick?.(event, node);
  };
  if (props.disabled) {
    return (
      <Tooltip title={disabledReason ?? ""}>
        <span>
          <IconButton {...iconButtonProps} onClick={undefined}>
            {iconMap[kind]}
          </IconButton>
        </span>
      </Tooltip>
    );
  }
  return (
    <Tooltip title={title}>
      <IconButton {...iconButtonProps} onClick={handleClick}>
        {iconMap[kind]}
      </IconButton>
    </Tooltip>
  );
};

NavigationItem.displayName = "NavigationItem";

export default NavigationItem;
