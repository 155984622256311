import { AssessmentResourceEditor } from "@hireroo/app-store/widget/e/AssessmentResourceEditor";
import { Widget } from "@hireroo/presentation";

import { getNearestQuarterDate } from "./privateHelper";

export type GenerateTestSetupSectionArgs = {
  mode: Widget.AssessmentResourceEditorV2Props["mode"];
  defaultValues?: Widget.AssessmentResourceEditorV2Props["testSetupSection"]["defaultValues"];
};

export const useGenerateTestSetupSectionProps = (
  args: GenerateTestSetupSectionArgs,
): Widget.AssessmentResourceEditorV2Props["testSetupSection"] => {
  return {
    onSubmit: fields => {
      AssessmentResourceEditor.setSubmitValue("TEST_SETUP", fields);
    },
    defaultValues: args.defaultValues || {
      nextStartScheduleAt: getNearestQuarterDate(),
      examInterval: "ONE_MONTH",
      remindBeforeDays: Number.NaN,
    },
    disableFields: {
      nextStartScheduleAt: false,
      examInterval: false,
      remindBeforeDays: false,
    },
    mode: args.mode,
  };
};
