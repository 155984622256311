import { ScreeningsIdDetailStore } from "@hireroo/app-store/page/e/screenings_id_detail";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateHelpCenterUrl } from "@hireroo/router/api";

export const useGenerateCustomScoreAllocationProps = (): Widget.ScreeningDetailProps["overview"]["customScoreAllocation"] => {
  const { t } = useTranslation();
  const screening = ScreeningsIdDetailStore.useScreening();
  const lang = useLanguageCode();

  return {
    allocationBar: {
      scores: screening.entityTracks.map(
        (
          entityTrack,
          index,
        ): Exclude<Widget.ScreeningDetailProps["overview"]["customScoreAllocation"], undefined>["allocationBar"]["scores"][number] => {
          return {
            label: `Q${index + 1}`,
            weight: entityTrack.questionScoreWeight,
          };
        },
      ),
    },
    helpLink: {
      href: generateHelpCenterUrl(lang, "CUSTOM_SCORE"),
    },
    tooltip: t("トータルスコアに対しての配点割合を表示します。"),
  };
};
