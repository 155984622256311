import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import AssessmentDetailSummary, { AssessmentDetailSummaryProps } from "../AssessmentDetailSummary/AssessmentDetailSummary";

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: "8px",
  overflowX: "auto",
  minWidth: 860,
  "&:last-of-type": {
    borderRadius: "8px",
  },
  "&:first-of-type": {
    borderRadius: "8px",
  },
  "&:before": {
    display: "none",
  },
}));

type User = {
  displayName: string;
  photoUrl: string;
  mail: string;
};

export type AssessmentSummaryProps = {
  user: User;
  detail: AssessmentDetailSummaryProps;
  Graph: React.ReactNode;
};

const AssessmentSummary: React.FC<AssessmentSummaryProps> = props => {
  const graphRef = React.useRef();
  return (
    <StyledAccordion defaultExpanded elevation={4}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack direction="row" display="flex" alignItems="center">
          <Avatar alt={props.user.displayName} src={props.user.photoUrl} />
          <Stack direction="column" ml={1}>
            <Typography fontSize={16} fontWeight={700}>
              {props.user.displayName}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {props.user.mail}
            </Typography>
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="row" spacing={2} width="100%" height="auto">
          <Box width="50%">
            <AssessmentDetailSummary {...props.detail} />
          </Box>
          <Box width="50%" height="100%" ref={graphRef}>
            {props.Graph}
          </Box>
        </Stack>
      </AccordionDetails>
    </StyledAccordion>
  );
};

AssessmentSummary.displayName = "AssessmentSummary";

export default AssessmentSummary;
