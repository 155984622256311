import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import * as React from "react";

import TalentLayout, { TalentLayoutProps } from "../../layouts/TalentLayout/TalentLayout";

const StyledTalentLayout = styled(TalentLayout)`
  overflow: hidden;
`;

const Wrapper = styled(Paper)`
  height: calc(100vh - 73px);
`;

export type TalentTestReviewProps = {
  layout: TalentLayoutProps;
  children: React.ReactNode;
};

const TalentTestReview: React.FC<TalentTestReviewProps> = props => {
  return (
    <StyledTalentLayout {...props.layout}>
      <Wrapper elevation={2}>{props.children}</Wrapper>
    </StyledTalentLayout>
  );
};

TalentTestReview.displayName = "TalentTestReview";

export default TalentTestReview;
