import { useTranslation } from "@hireroo/i18n";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import PlayArrow from "@mui/icons-material/PlayArrow";
import TableView from "@mui/icons-material/TableView";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import * as React from "react";

import IconButton, { IconButtonProps } from "../../../../../primitive/Button/IconButton";
import ChallengeOutputStatusIcon from "../../../../../usecase/ChallengeOutputStatusIcon/ChallengeOutputStatusIcon";
import DBTestCasePreviewDialog, { DBTestCasePreviewDialogProps } from "./DBTestCasePreviewDialog";
import { OutputStatusType } from "./types";

const StyledTableCell = styled(TableCell)({
  overflow: "auto",
  whiteSpace: "nowrap",
  maxWidth: 300,
});

export type DBTestCaseRunnerRowProps = {
  previewDialog: Omit<DBTestCasePreviewDialogProps, "open" | "onClose">;
  outputStatus?: OutputStatusType;

  isOpen: boolean;
  expandButton: Pick<IconButtonProps, "onClick">;

  runButton: Pick<IconButtonProps, "onClick">;
};

const DBTestCaseRunnerRow: React.FC<DBTestCaseRunnerRowProps> = props => {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClose = () => {
    setOpenDialog(false);
  };
  const expandButton: IconButtonProps = {
    size: "small",
    "aria-label": "expand row",
    onClick: props.expandButton.onClick,
    children: props.isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />,
  };
  const runButton: IconButtonProps = {
    ...props.runButton,
    title: t("このテストケースでコードを実行します。"),
    size: "small",
    component: "span",
    "aria-label": "expand row",
    children: <PlayArrow />,
  };

  const previewDialog: DBTestCasePreviewDialogProps = {
    ...props.previewDialog,
    open: openDialog,
    onClose: handleClose,
  };

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton {...expandButton} />
        </TableCell>
        <TableCell component="th" scope="row">
          {props.previewDialog.testCase.title}
        </TableCell>
        <StyledTableCell>
          <Button startIcon={<TableView />} onClick={() => setOpenDialog(true)}>
            {t("データを確認")}
          </Button>
        </StyledTableCell>
        <TableCell>
          <ChallengeOutputStatusIcon outputStatus={props.outputStatus} />
        </TableCell>
        <TableCell>
          <Box display="inline-block" alignItems="center" width={20}>
            {props.outputStatus === "LOADING" ? <CircularProgress size={20} color="inherit" /> : <IconButton {...runButton} />}
          </Box>
        </TableCell>
      </TableRow>
      <DBTestCasePreviewDialog {...previewDialog} />
    </>
  );
};

DBTestCaseRunnerRow.displayName = "DBTestCaseRunnerRow";

export default DBTestCaseRunnerRow;
