import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

export type RemoveElementConfirmDialogProps = {
  open: boolean;
  onClose: () => void;
  onResetFlowChart: () => void;
};

const RemoveElementConfirmDialog: React.FC<RemoveElementConfirmDialogProps> = props => {
  const { open } = props;
  const { t } = useTranslation();

  const yesButtonProps: ButtonProps = {
    variant: "contained",
    color: "error",
    onClick: () => {
      props.onResetFlowChart();
    },
    children: t("リセット"),
  };
  const noButtonProps: ButtonProps = {
    variant: "outlined",
    color: "secondary",
    onClick: () => {
      props.onClose();
    },
    children: t("キャンセル"),
  };

  return (
    <Dialog open={open}>
      <Box sx={{ width: 400 }}>
        <DialogTitle fontWeight="bold">{t("フローチャートをリセットします。よろしいですか？")}</DialogTitle>
        <DialogContent role="content">{t("破棄された変更内容は復元できませんのでご注意ください。")}</DialogContent>
        <DialogActions>
          <Button {...noButtonProps} />
          <Button {...yesButtonProps} />
        </DialogActions>
      </Box>
    </Dialog>
  );
};

RemoveElementConfirmDialog.displayName = "RemoveElementConfirmDialog";

export default RemoveElementConfirmDialog;
