import { useTranslation } from "@hireroo/i18n";
import { ErrorMessage } from "@hookform/error-message";
import { Delete, ExpandMore } from "@mui/icons-material";
import Accordion from "@mui/material/Accordion/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary/AccordionSummary";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";

import IconButton, { IconButtonProps } from "../../../../../../primitive/Button/IconButton";
import InputControlTextField, { InputControlTextFieldProps } from "../../../../../../primitive/InputControl/InputControlTextField";
import { useAlgorithmTestCasesFormContext } from "../../../../Context";
import AlgorithmInputFields from "../AlgorithmInputFields/AlgorithmInputFields";
import DatabaseTestCaseInputFields from "../DatabaseInputFields/DatabaseInputFields";

export type TestCaseInputProps = {
  index: number;
  length: number;
  onDelete: IconButtonProps["onClick"];
};

const TestCaseInput: React.FC<TestCaseInputProps> = props => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const { method, contextProps } = useAlgorithmTestCasesFormContext();

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const errors = method.formState.errors;

  const deleteIconButton: IconButtonProps = {
    onClick: props.onDelete,
    children: <Delete />,
    title: t("テストケースを削除する"),
  };

  const labelTextField: InputControlTextFieldProps = {
    control: method.control,
    label: t("ラベル"),
    placeholder: "N=3000",
    type: "text",
    color: "primary",
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
    variant: "outlined",
    required: true,
  };
  return (
    <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1d-content" id="panel1d-header">
        <Box display="flex" alignItems="center" gap={2}>
          <Box display="flex" gap={2} alignItems="center">
            {props.length > 1 && <IconButton {...deleteIconButton} />}
            <Typography>{`${t("テストケース")} ${props.index + 1}`}</Typography>
          </Box>
          <ErrorMessage
            errors={errors.performanceTestCases}
            name={`data.${props.index}`}
            render={({ message }) =>
              message && (
                <Typography variant="body2" color="error">
                  {t("入力項目に不正な項目があります。")}
                </Typography>
              )
            }
          />
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        <Box display="flex" flexDirection="column" gap={4}>
          <Box display="flex" gap={4} justifyContent="space-between" width="100%">
            <Box width="23vw">
              <InputControlTextField name={`performanceTestCases.data.${props.index}.label`} {...labelTextField} />
            </Box>
          </Box>

          <Box mt={1}>
            {contextProps.signatureProps.variant === "DATABASE" && (
              <DatabaseTestCaseInputFields signature={contextProps.signatureProps.signature} index={props.index} />
            )}

            {contextProps.signatureProps.variant === "ALGORITHM" && (
              <AlgorithmInputFields index={props.index} signature={contextProps.signatureProps.signature} />
            )}
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

TestCaseInput.displayName = "TestCaseInput";

export default TestCaseInput;
