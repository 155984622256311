import eachMonthOfInterval from "date-fns/eachMonthOfInterval";
import * as React from "react";
import { useSnapshot } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

const useSnapshotState = () => {
  return useSnapshot(state);
};

const useCurrentPurchasesResponse = () => {
  const snapshot = useSnapshotState();
  const key: Types.ResponseKey = `${snapshot.page}-${snapshot.fetchSize}-${snapshot.purchasePeriod.startSeconds}`;
  return snapshot.purchasesResponses.get(key);
};

export const useInitialized = (): boolean => {
  const snapshot = useSnapshotState();
  if (!snapshot.company) {
    return false;
  }
  return snapshot.purchasesResponses.has(`${snapshot.page}-${snapshot.fetchSize}-${snapshot.purchasePeriod.startSeconds}`);
};

export const useSelectablePurchasePeriodStart = (): Date[] => {
  const snapshot = useSnapshotState();
  const companyCreatedAtSeconds = snapshot.company?.createdAtSeconds;
  return React.useMemo((): Date[] => {
    if (!companyCreatedAtSeconds) {
      return [];
    }
    const startDate = new Date(companyCreatedAtSeconds * 1000);
    const interval = eachMonthOfInterval({ start: startDate, end: new Date() });
    return interval.sort((a, b) => b.getTime() - a.getTime());
  }, [companyCreatedAtSeconds]);
};

export const useCount = () => {
  const res = useCurrentPurchasesResponse();
  if (!res) {
    throw new Error("Not initialized");
  }
  return res.count;
};

export const usePurchases = () => {
  const res = useCurrentPurchasesResponse();
  if (!res) {
    throw new Error("Not initialized");
  }
  return res.purchases;
};

export const useNextPager = () => {
  const snapshot = useSnapshotState();
  return snapshot.nextPager;
};

export const useFetchable = (): boolean => {
  const snapshot = useSnapshotState();
  const res = useCurrentPurchasesResponse();
  if (!res) {
    return true;
  }
  return snapshot.nextPager?.hasNext === true;
};

export const usePage = () => {
  const snapshot = useSnapshotState();
  return snapshot.page;
};

export const useFetchSize = () => {
  const snapshot = useSnapshotState();
  return snapshot.fetchSize;
};

export const usePurchasePeriod = () => {
  const snapshot = useSnapshotState();
  return snapshot.purchasePeriod;
};
