import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";

import DeleteDialog, { DeleteDialogProps } from "../../../../modules/DeleteDialog/DeleteDialog";
import TagDialog, { TagDialogProps } from "../../../../modules/TagDialog/TagDialog";
import MoreButton, { MoreButtonProps } from "../../../../primitive/Button/MoreButton/MoreButton";

export type ActionMenuProps = {
  disabled?: boolean;
  options: MoreButtonProps["options"];
  tooltipText?: string;
  tagDialog: TagDialogProps;
  deleteDialog: DeleteDialogProps;
};

const ActionMenu: React.FC<ActionMenuProps> = props => {
  const moreButtonProps: MoreButtonProps = {
    iconButton: {
      disabled: props.disabled,
      size: "small",
    },
    variant: "vertical",
    options: props.options,
  };
  const tooltipText = props.disabled ? props.tooltipText ?? "" : "";
  return (
    <Box>
      <Tooltip title={tooltipText}>
        <span>
          <MoreButton {...moreButtonProps}></MoreButton>
        </span>
      </Tooltip>
      <TagDialog key={`tag-${props.tagDialog.open.toString()}`} {...props.tagDialog} />
      <DeleteDialog key={`delete-${props.deleteDialog.dialog.open.toString()}`} {...props.deleteDialog} />
    </Box>
  );
};

ActionMenu.displayName = "ActionMenu";

export default ActionMenu;
