import { QUERY_PARAMS_FOR_DEMO } from "@hireroo/app-definition/demo";
import { decompress } from "@hireroo/app-helper/lz-string";
import { StartDemoForm, StartDemoStorage } from "@hireroo/validator";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

type Kind = "SPOT" | "SCREENING";

export type GenerateStorageKeyParams = {
  kind: Kind;
  id: string;
};

const generateStorageKey = (params: GenerateStorageKeyParams) => {
  return `demo/${params.kind}/${params.id};`;
};

export const saveDemoParams = (keyParams: GenerateStorageKeyParams, value: Pick<StartDemoStorage.StartDemoStorage, "demoId">) => {
  const key = generateStorageKey(keyParams);
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const restoreDemoParams = (keyParams: GenerateStorageKeyParams): StartDemoStorage.StartDemoStorage | null => {
  const key = generateStorageKey(keyParams);
  try {
    const value = window.localStorage.getItem(key);
    if (value === null) {
      return null;
    }
    const parsedValue = JSON.parse(value);
    const parsedResult = StartDemoStorage.StartDemoStorage.safeParse(parsedValue);
    if (parsedResult.success) {
      window.localStorage.removeItem(key);
      return parsedResult.data;
    }
    return null;
  } catch (error) {
    window.localStorage.removeItem(key);
    return null;
  }
};

export const useDemoParamsByQueryParams = () => {
  const [searchParams] = useSearchParams();
  const stringifiedDemoParams = searchParams.get(QUERY_PARAMS_FOR_DEMO.key);

  return React.useMemo((): StartDemoForm.StartDemoParams | null => {
    if (stringifiedDemoParams === null) {
      return null;
    }
    try {
      const parsedDemoParams = JSON.parse(decompress(stringifiedDemoParams));
      const parsedResult = StartDemoForm.StartDemoParams.safeParse(parsedDemoParams);
      if (parsedResult.success) {
        return parsedResult.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }, [stringifiedDemoParams]);
};
