import { App, Company } from "@hireroo/app-store/essential/employee";
import { ScreeningsTestsStore } from "@hireroo/app-store/page/e/screenings_tests";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import ScreeningTestListContainer, { ScreeningTestListContainerProps } from "./Container";
import { startSubscriberListParamsForMixPanel } from "./Subscriber";

export type ScreeningTestListFetchContainerProps = {};

const ScreeningTestListFetchContainer: React.FC<ScreeningTestListFetchContainerProps> = () => {
  const activeCompanyId = Company.useStrictActiveCompanyId();
  const appStatus = App.useStatus();
  const listParams = ScreeningsTestsStore.useListParams();
  const initialized = ScreeningsTestsStore.useInitializedV2();

  const spotsByCompanyIdInput: Graphql.SpotsByCompanyIdInput = {
    companyId: activeCompanyId,
    isDescending: listParams.isDescending,
    offset: listParams.page * listParams.size,
    size: listParams.size,
    sortMethod: listParams.sortMethod,
    status: Graphql.SpotStatus.Unknown,
    filters: {
      ...listParams.filters,
      suspiciousDegrees: listParams.filters.suspiciousDegrees.slice(),
      candidateEmail: listParams.filters.name,
      candidateName: listParams.filters.name,
      minTotalScore: null,
      maxTotalScore: null,
      minCustomScore: listParams.filters.minCustomScore,
      maxCustomScore: listParams.filters.maxCustomScore,
      createdByList: [...listParams.filters.createdByList],
      statuses: [...listParams.filters.statuses],
      tagNames: [...listParams.filters.tagNames],
      rankEvaluations: [...listParams.filters.rankEvaluations],
      screeningIds: [...listParams.filters.screeningIds],
    },
    withAnswers: false,
    withCount: true,
    withDeleted: false,
  };
  const [result, refresh] = Graphql.useListSpotsByCompanyIdForInterviewsQuery({
    variables: {
      listByCompanyId: spotsByCompanyIdInput,
    },
    pause: appStatus !== "INITIALIZED" || activeCompanyId === 0,
    requestPolicy: "cache-and-network",
  });
  React.useEffect(() => {
    if (result.fetching) {
      ScreeningsTestsStore.updateListFetchingStatus("FETCHING");
    } else if (result.data) {
      ScreeningsTestsStore.updateListFetchingStatus("FETCHED");
    }
  }, [result.data, result.fetching]);

  React.useEffect(() => {
    const stop = startSubscriberListParamsForMixPanel();

    return () => {
      stop();
    };
  }, []);

  React.useEffect(() => {
    if (result.data) {
      if (result.data.spotsByCompanyId) {
        ScreeningsTestsStore.updateResponse(result.data.spotsByCompanyId);
        ScreeningsTestsStore.updateCount(result.data.spotsByCompanyId.count);
        ScreeningsTestsStore.clampPage();
      }
    }
  }, [result.data]);
  React.useEffect(() => {
    Snackbar.setOpenLoadingSnackbar(result.stale);
  }, [result.stale]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: ScreeningTestListContainerProps = {
    refresh: refresh,
  };

  return <ScreeningTestListContainer {...containerProps} />;
};

ScreeningTestListFetchContainer.displayName = "TestListTableFetchContainer";

export default withErrorBoundary(ScreeningTestListFetchContainer, {});
