import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import MuiListItemButton, { ListItemButtonProps } from "@mui/material/ListItemButton";
import Radio from "@mui/material/Radio";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography, { TypographyProps } from "@mui/material/Typography";
import * as React from "react";

const StyledListItemButton = styled(MuiListItemButton)(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: theme.palette["Secondary/Shades"].p12,
    "&:hover": {
      backgroundColor: theme.palette["Secondary/Shades"].p12,
    },
  },
  paddingLeft: "4px",
}));

type Kind = "SINGLE_CHOICE" | "MULTIPLE_CHOICE";

export type TechnicalCommentListItemButtonProps = {
  title: string;
  description: string;
  onClick: ListItemButtonProps["onClick"];
  selected?: boolean;
  kind: Kind;
};

const TechnicalCommentListItemButton: React.FC<TechnicalCommentListItemButtonProps> = props => {
  const theme = useTheme();
  const description: TypographyProps = {
    color: theme.palette.text.secondary,
    fontSize: 12,
    flexWrap: "wrap",
    alignItems: "center",
    variant: "caption",
  };
  const KindMap: Record<Kind, React.ReactNode> = {
    SINGLE_CHOICE: <Radio size="small" checked={props.selected} color="secondary" />,
    MULTIPLE_CHOICE: <Checkbox size="small" checked={props.selected} color="secondary" />,
  };
  return (
    <ListItem divider disablePadding>
      <StyledListItemButton onClick={props.onClick} selected={props.selected}>
        <Grid container>
          <Grid item xs={5}>
            <Stack direction="row" spacing={1} alignItems="center">
              {KindMap[props.kind]}
              <Box alignItems="center">
                <Typography fontSize={14} fontWeight={700}>
                  {props.title}
                </Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={7} alignItems="center" display="flex" pl={0.5} overflow="auto">
            <Typography {...description}>{props.description}</Typography>
          </Grid>
        </Grid>
      </StyledListItemButton>
    </ListItem>
  );
};

TechnicalCommentListItemButton.displayName = "TechnicalCommentListItemButton";

export default TechnicalCommentListItemButton;
