import MUITableBody, { TableBodyProps as MUITableBodyProps } from "@mui/material/TableBody";
import * as React from "react";

export type TableBodyProps = MUITableBodyProps & {};

const TableBody: React.FC<TableBodyProps> = props => {
  const { children } = props;
  return <MUITableBody {...props}>{children}</MUITableBody>;
};

TableBody.displayName = "TableBody";

export default TableBody;
