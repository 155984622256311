import { Widget } from "@hireroo/presentation";
import { useSystemDesignContext } from "@hireroo/system-design/react/FlowChart";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateRemoteInterviewSystemDesignCodingEditorPropsArgs, useGenerateProps } from "./useGenerateProps";

export type RemoteInterviewSystemDesignCodingEditorContainerProps = GenerateRemoteInterviewSystemDesignCodingEditorPropsArgs;

const RemoteInterviewSystemDesignCodingEditorContainer: React.FC<RemoteInterviewSystemDesignCodingEditorContainerProps> = props => {
  const store = useSystemDesignContext();
  const { collaborativeState } = props;
  const componentType = store.hooks.useComponentType();
  const remoteInterviewSystemDesignCodingEditorProps = useGenerateProps(props);
  const key = [collaborativeState.selectedSession, componentType].join("-");
  return (
    <ErrorBoundary>
      <Widget.RemoteInterviewSystemDesignCodingEditor key={key} {...remoteInterviewSystemDesignCodingEditorProps} />
    </ErrorBoundary>
  );
};

RemoteInterviewSystemDesignCodingEditorContainer.displayName = "RemoteInterviewSystemDesignCodingEditorContainer";

export default withErrorBoundary(RemoteInterviewSystemDesignCodingEditorContainer, {});
