import { useTranslation } from "@hireroo/i18n";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { green } from "@mui/material/colors";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import QuestionHeader, { QuestionHeaderProps } from "../QuestionHeader/QuestionHeader";

export type EvaluationItem = {
  title: string;
};

export type ReadonlyQuizQuestionFreeTextProps = {
  id: string;
  header: QuestionHeaderProps;
  items?: EvaluationItem[];
};

const ReadonlyQuizQuestionFreeText: React.FC<ReadonlyQuizQuestionFreeTextProps> = props => {
  const { t } = useTranslation();
  const header: QuestionHeaderProps = {
    ...props.header,
  };
  return (
    <QuestionHeader {...header}>
      {props.items && (
        <>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }} mb={1.5}>
            {t("評価基準")}
          </Typography>
          <List sx={{ p: 0 }}>
            <Stack direction="column" spacing={1.5}>
              {props.items.length === 0 && <Typography>{t("評価基準が存在しません。")}</Typography>}
              {props.items.map((item, index) => {
                return (
                  <ListItem key={`option-${index}-${item.title}`} alignItems="center" sx={{ p: 0 }}>
                    <Stack mr={1}>
                      <CheckCircleIcon style={{ color: green[500] }} />
                    </Stack>
                    <ListItemText primary={item.title} />
                  </ListItem>
                );
              })}
            </Stack>
          </List>
        </>
      )}
    </QuestionHeader>
  );
};

ReadonlyQuizQuestionFreeText.displayName = "ReadonlyQuizQuestionFreeText";

export default ReadonlyQuizQuestionFreeText;
