import { useTranslation } from "@hireroo/i18n";
import RedoIcon from "@mui/icons-material/Redo";
import UndoIcon from "@mui/icons-material/Undo";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import * as React from "react";

import ControlButtonGroup from "../primitive/ControlButtonGroup/ControlButtonGroup";
import SquareButton, { SquareButtonProps } from "../primitive/SquareButton/SquareButton";

const StyledPaper = styled(Paper)`
  display: inline-block;
  height: 32px;
`;

export type RedoUndoGroupProps = {
  canUndo: boolean;
  canRedo: boolean;
  undoButton: Pick<SquareButtonProps, "onClick">;
  redoButton: Pick<SquareButtonProps, "onClick">;
};

const RedoUndoGroup: React.FC<RedoUndoGroupProps> = props => {
  const { t } = useTranslation();
  return (
    <StyledPaper elevation={1}>
      <ControlButtonGroup variant="outlined" aria-label="outlined button group">
        <SquareButton {...props.undoButton} disabled={!props.canUndo} tooltipText={t("元に戻す")}>
          <UndoIcon />
        </SquareButton>
        <SquareButton {...props.redoButton} disabled={!props.canRedo} tooltipText={t("やり直し")}>
          <RedoIcon />
        </SquareButton>
      </ControlButtonGroup>
    </StyledPaper>
  );
};

RedoUndoGroup.displayName = "RedoUndoGroup";

export default RedoUndoGroup;
