import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import Typography from "@mui/material/Typography";
import * as React from "react";

import TablePagination, { TablePaginationProps } from "../../modules/TablePagination/TablePagination";
import { QuestionTableHeaderProps } from "../../ms-components/Question";
import QuestionTableHeader from "../../ms-components/Question/QuestionTableHeader/QuestionTableHeader";
import DivTable from "../../primitive/DivTable/DivTable";
import DivTableBody from "../../primitive/DivTableBody/DivTableBody";
import QuestionTableRow, { QuestionTableRowProps } from "./parts/QuestionTableRow/QuestionTableRow";

const StyledTableContainer = styled(TableContainer)(() => ({
  borderRadius: "8px",
  bgcolor: "inherit",
  height: "100%",
}));

const StyledTableBody = styled(DivTableBody)`
  height: 100%;
`;

const StyledTable = styled(DivTable)(({ theme }) => ({
  borderColor: theme.palette["Gray/Shades"].p8,
  height: "fit-content",
}));

export type QuestionTableProps = {
  rows: QuestionTableRowProps[];
  resultText: QuestionTableHeaderProps["resultText"];
  sortField: QuestionTableHeaderProps["sortField"];
  showLeakScore: QuestionTableHeaderProps["showLeakScore"];
  showDifficulty: QuestionTableHeaderProps["showDifficulty"];
  pagination?: TablePaginationProps;
};

const QuestionTable: React.FC<QuestionTableProps> = props => {
  const { t } = useTranslation();
  const tablePaginationProps: TablePaginationProps | undefined = props.pagination
    ? {
        ...props.pagination,
      }
    : undefined;

  const questionTableHeaderProps: QuestionTableHeaderProps = {
    resultText: props.resultText,
    sortField: props.sortField,
    showLeakScore: props.showLeakScore,
    showDifficulty: props.showDifficulty,
    hasAction: true,
    hasAverageTimeToAnswer: true,
  };

  return (
    <Paper sx={{ boxShadow: "none", borderRadius: "16px" }} elevation={2}>
      {props.rows.length > 0 && (
        <StyledTableContainer>
          <StyledTable>
            <QuestionTableHeader {...questionTableHeaderProps} size="small" />
            <StyledTableBody>
              {props.rows.map(row => (
                <QuestionTableRow key={row.id} {...row} />
              ))}
            </StyledTableBody>
          </StyledTable>
          {tablePaginationProps && <TablePagination {...tablePaginationProps} />}
        </StyledTableContainer>
      )}
      {props.rows.length === 0 && (
        <Box sx={{ width: "100%" }} p={2}>
          <Typography variant="body2" textAlign="center" color="textSecondary">
            {t("該当するデータがありません")}
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

QuestionTable.displayName = "QuestionTable";

export default QuestionTable;
