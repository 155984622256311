import * as Graphql from "@hireroo/graphql/client/urql";
import * as React from "react";
import { useSnapshot } from "valtio";

import * as Def from "./Definition";
import { state } from "./State";
import * as Types from "./types";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = (): boolean => {
  const snapshot = useSnapshotState();
  return !!snapshot.plansResponse && !!snapshot.customer;
};

export const usePlans = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.plansResponse) {
    throw new Error("Please Initialize PaymentContractCreateForm");
  }
  return React.useMemo(() => {
    const sortedList: Graphql.PlanType[] = [
      Graphql.PlanType.Basic,
      Graphql.PlanType.Standard,
      Graphql.PlanType.Professional,
      Graphql.PlanType.Enterprise,
    ];
    return sortedList.reduce<Types.Plan[]>((sortedPlans, planType) => {
      const target = snapshot.plansResponse?.plans.find(plan => plan.planType === planType);
      if (target) {
        return sortedPlans.concat(target);
      }
      return sortedPlans;
    }, []);
  }, [snapshot.plansResponse]);
};

export const useCustomer = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.customer) {
    throw new Error("Please Initialize PaymentContractCreateForm");
  }
  return snapshot.customer;
};

export const useSubmitValue = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.submitValue) {
    throw new Error("Please Set submit value");
  }
  return snapshot.submitValue;
};

export const useOptionalSubmitValue = () => {
  const snapshot = useSnapshotState();
  return snapshot.submitValue;
};

export const useShowingTarget = () => {
  const snapshot = useSnapshotState();
  return snapshot.showingTarget;
};

export const useSelectionItems = () => {
  const snapshot = useSnapshotState();
  return snapshot.selectionItems;
};

export const useTemporarySelectedPlan = () => {
  const snapshot = useSnapshotState();
  return snapshot.plansResponse?.plans.find(plan => Def.planTypeGraphqlToValidator[plan.planType] === snapshot.temporarySubmitValue?.plan);
};

export const useTemporarySelectionItems = () => {
  const snapshot = useSnapshotState();
  return snapshot.temporarySubmitValue?.selectionItems || [];
};

export const useTemporaryPaymentPeriod = () => {
  const snapshot = useSnapshotState();
  return snapshot.temporarySubmitValue?.paymentPeriod;
};

export const useAppliedCoupons = () => {
  const snapshot = useSnapshotState();
  return snapshot.appliedCoupons;
};

export const useBuyAbleInterviews = () => {
  const snapshot = useSnapshotState();
  return snapshot.interviews;
};

export const useBuyAbleSelectionItemMap = (): Record<string, Types.Interview> => {
  const snapshot = useSnapshotState();
  return React.useMemo(() => {
    return snapshot.interviews.reduce((acc, interview) => {
      return { ...acc, [interview.interviewId.toString()]: interview };
    }, {});
  }, [snapshot.interviews]);
};

export const useStripJsPaymentMethodResult = () => {
  const snapshot = useSnapshotState();
  return snapshot.stripJsPaymentMethodResult;
};

export const useInitialCost = () => {
  const snapshot = useSnapshotState();
  return snapshot.initialCost;
};
