import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type AssessmentOverviewContainerProps = {
  companyId: number;
};

const AssessmentOverviewContainer: React.FC<AssessmentOverviewContainerProps> = props => {
  const assessmentOverviewProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.AssessmentOverview {...assessmentOverviewProps} />
    </ErrorBoundary>
  );
};

AssessmentOverviewContainer.displayName = "AssessmentOverviewContainer";

export default withErrorBoundary(AssessmentOverviewContainer, {});
