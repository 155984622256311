import { App } from "@hireroo/app-store/essential/employee";
import { TechnicalCommentPrompt } from "@hireroo/app-store/widget/e/TechnicalCommentPrompt";
import * as Graphql from "@hireroo/graphql/client/urql";
import { GraphQLError } from "graphql";
import * as React from "react";

export type FetchResult = {
  isLoading: boolean;
  errors: GraphQLError[];
};

export type FetchMetricGroupsArgs = {
  companyId: number;
};

const FETCH_SIZE = 10;

export const useFetchMetricGroups = (args: FetchMetricGroupsArgs): FetchResult => {
  const appStatus = App.useStatus();
  const pagination = TechnicalCommentPrompt.useMetricGroupPagination();
  const loadingStatus = TechnicalCommentPrompt.useLoadingStatus();
  const [result] = Graphql.useListEvaluationMetricGroupsByCompanyIdForTechnicalCommentQuery({
    variables: {
      companyId: args.companyId,
      withCount: false,
      size: FETCH_SIZE,
      cursor: pagination.cursor,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "cache-and-network",
  });

  React.useEffect(() => {
    if (result.data?.evaluationMetricGroupsByCompany) {
      TechnicalCommentPrompt.addMetricGroups(result.data.evaluationMetricGroupsByCompany.metricGroups);
      TechnicalCommentPrompt.updateMetricGroupPagination({
        nextCursor: result.data.evaluationMetricGroupsByCompany.pager?.cursor || null,
        hasNext: result.data.evaluationMetricGroupsByCompany.pager?.hasNext || false,
      });
    }
  }, [result.data?.evaluationMetricGroupsByCompany]);

  return {
    isLoading: result.fetching || appStatus !== "INITIALIZED" || loadingStatus === "LOADING",
    errors: result.error?.graphQLErrors ? result.error.graphQLErrors : [],
  };
};
