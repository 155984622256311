import { useTranslation } from "@hireroo/i18n";
import { AlgorithmTestCaseForm } from "@hireroo/validator";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import BaseDialog, { BaseDialogProps } from "../../../../../../../primitive/BaseDialog/BaseDialog";
import TestCaseInputContent from "./parts/TestCaseInputContent/TestCaseInputContent";

type Kind = "CREATE" | "EDIT";

export type PerformanceTestCaseDialogProps = {
  dialog: BaseDialogProps;
  defaultValues: AlgorithmTestCaseForm.PerformanceTestCaseFormSchemaV2;
  onSubmit: SubmitHandler<AlgorithmTestCaseForm.PerformanceTestCaseFormSchemaV2>;
  kind: Kind;
};

const PerformanceTestCaseDialog: React.FC<PerformanceTestCaseDialogProps> = props => {
  const { t } = useTranslation();
  const method = useForm<AlgorithmTestCaseForm.PerformanceTestCaseFormSchemaV2>({
    defaultValues: props.defaultValues,
  });
  const titleMap: Record<Kind, string> = {
    CREATE: t("テストケースの追加"),
    EDIT: t("テストケースの編集"),
  };
  const buttonMap: Record<Kind, string> = {
    CREATE: t("追加"),
    EDIT: t("保存"),
  };
  const dialog: BaseDialogProps = {
    ...props.dialog,
    title: titleMap[props.kind],
    disableEnter: true,
    optionalDialog: {
      maxWidth: "md",
      fullWidth: true,
    },
    yesButton: {
      ...props.dialog.yesButton,
      children: buttonMap[props.kind],
      onClick: () => {
        props.dialog.yesButton?.onClick?.();
        method.handleSubmit(props.onSubmit);
      },
    },
    noButton: {
      ...props.dialog.noButton,
      children: t("キャンセル"),
    },
  };

  return (
    <BaseDialog {...dialog}>
      <FormProvider {...method}>
        <TestCaseInputContent />
      </FormProvider>
    </BaseDialog>
  );
};

PerformanceTestCaseDialog.displayName = "CorrectnessTestCaseDialog";

export default PerformanceTestCaseDialog;
