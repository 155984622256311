import { useTranslation } from "@hireroo/i18n";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Chip, { ChipProps } from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import escapeStringRegexp from "escape-string-regexp";
import * as React from "react";
import { useController } from "react-hook-form";

import { useSearchFormContext } from "../../PrivateContext";
import SplitButton, { SplitButtonProps } from "../SplitButton/SplitButton";

const StyledStack = styled(Stack)(() => ({
  flexWrap: "wrap",
  gap: "8px",
  overflow: "auto",
  width: "100%",
  maxHeight: "400px",
}));

type FieldValue = {
  displayName: string;
};

export type TagSelectFieldProps = {
  name: string;
  title: string;
  selectableTags: FieldValue[];
  onChange?: (fields: FieldValue[]) => void;
  disabled?: boolean;
  variant?: SplitButtonProps["variant"];
};

const TagSelectField: React.FC<TagSelectFieldProps> = props => {
  const { t } = useTranslation();
  const { onChange } = props;
  const [searchText, setSearchText] = React.useState<string>("");
  const { subscribeClearAllFields: onClearFields } = useSearchFormContext();
  const { field } = useController<Record<string, FieldValue[]>>({
    name: props.name,
  });
  const [tags, setTags] = React.useState<FieldValue[]>(field.value);

  const resetField = React.useCallback(() => {
    setSearchText("");
    setTags([]);
    onChange?.([]);
    field.onChange([]);
  }, [field, onChange]);

  React.useEffect(() => {
    const stop = onClearFields(() => {
      setSearchText("");
      setTags([]);
      onChange?.([]);
    });
    return () => {
      stop();
    };
  }, [onChange, onClearFields]);

  const filteredTags = React.useMemo(() => {
    return props.selectableTags.filter(targetTag => {
      const reg = new RegExp(escapeStringRegexp(searchText));
      return reg.test(targetTag.displayName);
    });
  }, [props.selectableTags, searchText]);

  const splitButtonProps: SplitButtonProps = {
    title: props.title,
    disabled: props.disabled,
    status: tags.length > 0 ? "ACTIVE" : "INACTIVE",
    label: {
      children: tags.length === 0 ? props.title : [props.title, tags.map(t => t.displayName).join(", ")].join(": "),
    },
    onApply: () => {
      props.onChange?.(tags);
      field.onChange(tags);
    },
    onReset: () => {
      resetField();
    },
    variant: props.variant,
  };

  const textFieldProps: TextFieldProps = {
    fullWidth: true,
    placeholder: t("タグを検索"),
    InputLabelProps: {
      shrink: true,
    },
    onChange: e => {
      setSearchText(e.target.value);
    },
    InputProps: {
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
    },
    size: "small",
    color: "secondary",
    disabled: props.disabled,
  };

  const handleSelect = (tag: FieldValue) => {
    setTags(prevTags => {
      if (prevTags.find(prevTag => prevTag.displayName === tag.displayName)) {
        return prevTags.filter(prevTag => prevTag.displayName !== tag.displayName);
      }
      return prevTags.concat(tag);
    });
  };

  return (
    <SplitButton {...splitButtonProps}>
      <Box px={1.5} py={0.5}>
        <FormControl size="small">
          <Box mt={1} width={400}>
            <TextField {...textFieldProps} />
            <FormGroup>
              {filteredTags.length > 0 && (
                <StyledStack direction="row" mt={2}>
                  {filteredTags.map((tag, index) => {
                    const exists = tags.findIndex(target => target.displayName === tag.displayName) !== -1;
                    const chipProps: ChipProps = {
                      label: tag.displayName,
                      onClick: () => {
                        handleSelect(tag);
                      },
                      color: exists ? "secondary" : "default",
                    };
                    return (
                      <Tooltip key={`tooltip-${tag.displayName}-${index}`} title={tag.displayName.length > 30 ? tag.displayName : ""}>
                        <Chip key={`tag-chip-${tag}-${index}`} {...chipProps} />
                      </Tooltip>
                    );
                  })}
                </StyledStack>
              )}
            </FormGroup>
            {filteredTags.length === 0 && <Typography sx={{ mt: 2 }}>{t("検索条件に該当するタグがありません。")}</Typography>}
          </Box>
        </FormControl>
      </Box>
    </SplitButton>
  );
};

TagSelectField.displayName = "TagSelectField";

export default TagSelectField;
