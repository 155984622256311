import { useTranslation } from "@hireroo/i18n";
import SendRounded from "@mui/icons-material/SendRounded";
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import ButtonWithTooltip, { ButtonWithTooltipProps } from "../../../../../../primitive/Button/ButtonWithTooltip/ButtonWithTooltip";
import Tab from "../../../../../../primitive/Tab/Tab";
import Tabs from "../../../../../../primitive/Tabs/Tabs";

const ConsoleHeaderWrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette["Secondary/Shades"].p16,
  height: 36,
}));

const TabsWrapper = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const RunButton = styled(ButtonWithTooltip)(() => ({
  height: "30px",
  wordBreak: "keep-all",
  whiteSpace: "nowrap",
}));

export type BasicTabItem = {
  /**
   * A value to identify the tab.
   * Do not use variable values such as i18n values.
   */
  id: string;
  Component: React.ReactNode;
  name: string;
  onClickTab?: () => void;
  tabId?: string;
};

export type IDEConsoleToolbarProps = {
  items: BasicTabItem[];
  runButton: Pick<ButtonWithTooltipProps, "onClick" | "disabled" | "title">;
  submitButton?: Pick<LoadingButtonProps, "onClick" | "disabled" | "loading">;
};

const IDEConsoleToolbar: React.FC<IDEConsoleToolbarProps> = props => {
  const defaultTab = props.items.length > 0 ? props.items[0]?.id : "";
  const { t } = useTranslation();
  const [currentName, updateCurrentSelectedName] = React.useState<string>(defaultTab);
  React.useEffect(() => {
    if (defaultTab) {
      updateCurrentSelectedName(defaultTab);
    }
  }, [defaultTab]);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    updateCurrentSelectedName(newValue);
  };
  const runButton: ButtonWithTooltipProps = {
    ...props.runButton,
    id: "code-execute-button",
    color: "secondary",
    variant: "text",
    startIcon: "PLAY_ARROW",
    onClick: event => {
      props.runButton.onClick?.(event);
      if (props.items.length > 1 && props.items[1]) {
        updateCurrentSelectedName(props.items[1].id);
      }
    },
    children: t("実行"),
  };

  const TabPanels = props.items.map(item => {
    return (
      <TabPanel key={item.id} value={item.id} sx={{ p: 0 }}>
        {item.Component}
      </TabPanel>
    );
  });

  const submitButton: LoadingButtonProps | undefined = props.submitButton && {
    color: "secondary",
    id: "code-submit-button",
    variant: "contained",
    sx: {
      height: "30px",
      wordBreak: "keep-all",
      whiteSpace: "nowrap",
    },
    startIcon: <SendRounded />,
    ...props.submitButton,
  };

  return (
    <TabContext value={currentName}>
      <TabsWrapper>
        <ConsoleHeaderWrapper direction="row" justifyContent="space-between">
          <Tabs value={currentName} onChange={handleChange} sx={{ bgcolor: "unset" }}>
            {props.items.map(item => (
              <Tab
                key={item.id}
                id={item.tabId}
                label={<Typography textTransform="none">{item.name}</Typography>}
                aria-labelledby={item.name}
                value={item.id}
                onClick={item.onClickTab}
              />
            ))}
          </Tabs>
          <Stack spacing={1} direction="row" alignItems="center" mx={1.5}>
            <RunButton {...runButton} />
            {submitButton && <LoadingButton {...submitButton}>{t("コード提出")}</LoadingButton>}
          </Stack>
        </ConsoleHeaderWrapper>
        <Box pt={1.5} bgcolor="inherit" sx={{ overflow: "auto" }}>
          {TabPanels}
        </Box>
      </TabsWrapper>
    </TabContext>
  );
};

IDEConsoleToolbar.displayName = "IDEConsoleToolbar";

export default IDEConsoleToolbar;
