import { useSnapshot } from "valtio";

import * as Def from "./constants";
import { state } from "./State";
import type * as Types from "./types";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useResponse = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.res) {
    throw new Error("Response not found");
  }
  return snapshot.res;
};

export const useInitializedCurrentSearchFilter = () => {
  const snapshot = useSnapshotState();
  return snapshot.currentSearchFilter !== null;
};

export const useScreeningIds = () => {
  const snapshot = useSnapshotState();
  return snapshot.screeningIds;
};

export const useScreenings = () => {
  const snapshot = useSnapshotState();
  const screeningIds = useScreeningIds();

  return screeningIds.map((id): Types.Screening => {
    const screening = snapshot.screeningMap.get(id);
    if (!screening) throw new Error(`screening not found: ${id}`);
    return screening;
  });
};

export const useTags = () => {
  const snapshot = useSnapshotState();
  return snapshot.spotTags?.tags || [];
};

export const useListFetchingStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.listFetchingStatus;
};

export const useInitialized = (): boolean => {
  const snapshot = useSnapshotState();
  return !!snapshot.res;
};

export const useCurrentSearchFilter = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.currentSearchFilter) {
    throw new Error("CurrentSearchFilter is not initialized");
  }
  return snapshot.currentSearchFilter;
};

export const useDialogStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.dialogStatus;
};

export const useSelectedScreeningIds = () => {
  const snapshot = useSnapshotState();
  return snapshot.selectedScreeningIds;
};
export const useSelectedScreenings = () => {
  const snapshot = useSnapshotState();

  return snapshot.selectedScreeningIds.map(id => {
    const screening = snapshot.screeningMap.get(id);
    if (!screening) {
      throw new Error(`screening not found: ${id}`);
    }
    return screening;
  });
};

export const usePager = () => {
  const snapshot = useSnapshotState();
  return snapshot.pager;
};

export const useSortParams = () => {
  const snapshot = useSnapshotState();
  return Def.SortParams[snapshot.pager.sortFieldValue];
};

export const useRefreshKey = () => {
  const snapshot = useSnapshotState();
  return snapshot.refreshKey;
};
