import { App } from "@hireroo/app-store/essential/employee";
import { AssessmentFeedbackStore } from "@hireroo/app-store/view-domain/AssessmentFeedback";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import ScreeningTestFeedbackContainer, { AssessmentFeedbackContainerProps } from "./Container";
import * as Subscriber from "./Subscriber";

export type AssessmentFeedbackFetchContainerProps = {
  examId: string;
};

const AssessmentFeedbackFetchContainer: React.FC<AssessmentFeedbackFetchContainerProps> = props => {
  const appStatus = App.useStatus();
  const initialized = AssessmentFeedbackStore.useInitialized();

  React.useEffect(() => {
    const stop = Subscriber.startSubscribeReviewedSpot();
    return () => {
      stop();
      AssessmentFeedbackStore.clear();
    };
  }, []);
  const [result, refresh] = Graphql.useGetExamForAssessmentFeedbackQuery({
    variables: {
      examId: props.examId,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "cache-and-network",
  });
  React.useEffect(() => {
    if (result.data?.exam) {
      AssessmentFeedbackStore.updateExam(result.data.exam);
    }
  }, [result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="TOP" color="secondary" />;
  }

  const containerProps: AssessmentFeedbackContainerProps = {};
  return (
    <ErrorBoundary>
      <ScreeningTestFeedbackContainer {...containerProps} />
    </ErrorBoundary>
  );
};

AssessmentFeedbackFetchContainer.displayName = "AssessmentFeedbackFetchContainer";

export default withErrorBoundary(AssessmentFeedbackFetchContainer, {});
