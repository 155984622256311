import { QuizTestReport } from "@hireroo/app-store/view-domain/QuizTestReport";
import { TotalScoreRankVisualizer } from "@hireroo/app-store/widget/shared/TotalScoreRankVisualizer";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateQuestionDetailProps } from "../../../../props-factory/v2/view-domain/QuizTestReport/useGenerateQuestionDetailProps";
import QuizTestReportContainer, { QuizTestReportContainerProps } from "./Container";
import { useStatusMessageMap } from "./privateHelper";
import { startSubscribeQueryKey } from "./Subscriber";
import { useGenerateHeaderProps } from "./useGenerateHeaderProps";

export type QuizTestReportFetchContainerProps = {
  featureKind: "test" | "exam";
  companyId: number;
  quizId: number;
  reportSettings: Graphql.AssessmentReportSettings | null;
};

const QuizTestReportFetchContainer: React.FC<QuizTestReportFetchContainerProps> = props => {
  const { quizId, companyId } = props;
  const initialized = QuizTestReport.useInitialized(quizId);
  const hooks = QuizTestReport.useCreateQuizHooks(quizId);
  const quiz = hooks.useQuiz();
  const quizPackage = quiz.pb_package;
  const header = useGenerateHeaderProps({ quizId: quizId });
  const messageMap = useStatusMessageMap();
  const questionDetail = useGenerateQuestionDetailProps({
    quizId: quizId,
    showAnswer: props.reportSettings?.showAnswer ?? false,
    showArchivedMark: false,
  });

  const uniqueKey = React.useMemo((): QuizTestReport.UniqueKey | null => {
    return `Quiz-${quizId}-${quiz.pb_package?.packageId}`;
  }, [quiz.pb_package?.packageId, quizId]);

  React.useEffect(() => {
    if (!quizPackage || !uniqueKey || quiz.quizStatus === "STARTED" || quiz.quizStatus === "CREATED") {
      return;
    }

    TotalScoreRankVisualizer.createTotalScoreStatisticsState({
      uniqueKey,
      query: {
        endDate: quiz.evaluatedAtSeconds ? new Date(quiz.evaluatedAtSeconds * 1000) : null,
      },
    });
    const stop = startSubscribeQueryKey({
      uniqueKey,
      entityId: quiz.quizEntityId,
      companyId: companyId,
      packageId: quizPackage.packageId,
      packageVersion: quizPackage.version,
    });

    TotalScoreRankVisualizer.refreshQuery(uniqueKey);

    return () => {
      stop?.();
    };
  }, [companyId, initialized, quiz, quiz.evaluatedAtSeconds, quizPackage, uniqueKey]);

  if (!uniqueKey || !initialized) {
    return <Widget.Loading kind="TOP" />;
  }

  if (quiz.quizStatus === "STARTED" || quiz.quizStatus === "CREATED") {
    const quizProps: Widget.QuizTestReportProps = {
      header: header,
      /**
       * talent will always hide open button
       */
      questionDetail: { ...questionDetail, openButton: undefined },
      announcement: messageMap[quiz.quizStatus],
    };
    return <Widget.QuizTestReport {...quizProps} />;
    // return <Widget.TestReportSectionMessage header={header} message={messageMap[quiz.quizStatus]} />;
  }

  const containerProps: QuizTestReportContainerProps = {
    ...props,
    uniqueKey,
  };
  return (
    <ErrorBoundary>
      <QuizTestReportContainer {...containerProps} />
    </ErrorBoundary>
  );
};

QuizTestReportFetchContainer.displayName = "QuizTestReportFetchContainer";

export default withErrorBoundary(QuizTestReportFetchContainer, {});
