import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import * as React from "react";

import FeatureTableHeadRow, { FeatureTableHeadRowProps } from "./parts/FeatureTableHeadRow/FeatureTableHeadRow";
import FeatureTableRow, { FeatureTableRowProps } from "./parts/FeatureTableRow/FeatureTableRow";

const BORDER_RADIUS = 12;

/**
 * NOTE: Trial Label extends beyond table due to position absolute, so overflow hidden cannot be used.
 */
const StyledTableContainer = styled(TableContainer)(() => ({
  overflow: "visible",
  borderRadius: BORDER_RADIUS,
  "& thead tr:first-of-type": {
    "& th:first-of-type, td:first-of-type": {
      borderTopLeftRadius: BORDER_RADIUS,
    },
    "& th:last-child, td:last-child": {
      borderTopRightRadius: BORDER_RADIUS,
    },
  },
  "& tbody tr:last-child": {
    "& th:firsto-of-type, & td:firsto-of-type": {
      borderBottomLeftRadius: BORDER_RADIUS,
    },
    "& td:last-child": {
      borderBottomRightRadius: BORDER_RADIUS,
    },
  },
}));

const StyledPaper = styled(Paper)(() => ({
  borderRadius: BORDER_RADIUS,
  boxShadow: `0px 1px 10px 0px #0000001F,  0px 4px 5px 0px #00000024, 0px 2px 4px -1px #00000014`,
}));

export type PlanListProps = {
  head: FeatureTableHeadRowProps;
  rows: FeatureTableRowProps[];
};

const PlanList: React.FC<PlanListProps> = props => {
  return (
    <StyledPaper>
      <StyledTableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <FeatureTableHeadRow {...props.head} />
          </TableHead>
          <TableBody>
            {props.rows.map((row, index) => {
              return <FeatureTableRow key={`feature-${index}`} {...row} />;
            })}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </StyledPaper>
  );
};

PlanList.displayName = "PlanList";

export default PlanList;
