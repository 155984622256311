import { useTranslation } from "@hireroo/i18n";
import { withSplitProvider } from "@hireroo/react-split";
import { useMethods } from "@hireroo/react-split";
import { FlowChartSideBar } from "@hireroo/system-design/react/v2";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";

import ActivityBar, { ActivityBarProps } from "../../modules/ActivityBar/ActivityBar";
import SystemDesignCodingEditorSideBar, {
  SystemDesignCodingEditorSideBarProps,
} from "../../ms-components/SystemDesign/SystemDesignCodingEditorSideBar//SystemDesignCodingEditorSideBar";
import Split, { SplitProps } from "../../primitive/Split/Split";
import SystemDesignFlowChart, { SystemDesignFlowChartProps } from "./parts/SystemDesignFlowChart/SystemDesignFlowChart";
import { SplitParams, useSplitEvent } from "./privateHelper";

export type SystemDesignCodingEditorProps = {
  sidebar: SystemDesignCodingEditorSideBarProps;
  flowChart: SystemDesignFlowChartProps;
};

const StyledSplit = styled(Split)`
  height: 100%;
  &[data-direction*="vertical"] {
    width: calc(100vw - 36px);
  }
`;

const SystemDesignCodingEditor: React.FC<SystemDesignCodingEditorProps> = props => {
  const { t } = useTranslation();
  const methods = useMethods();
  const { sidebarVisibleStatus, setSidebarVisibleStatus, selectComponentAreaVisibleStatus, setSelectComponentAreaVisibleStatus } =
    useSplitEvent();
  const splitterProps: SplitProps = {
    splitId: SplitParams.splitId,
    splitDirection: "VERTICAL",
    items: [
      {
        id: SplitParams.Contents.CodingEditorSideBar.id,
        Content: <SystemDesignCodingEditorSideBar key="sidebar" {...props.sidebar} />,
        size: {
          value: 400,
          unit: "px",
        },
        minimizedSize: {
          value: 0,
          unit: "px",
        },
        expandedSize: {
          value: 400,
          unit: "px",
        },
        sidePane: {
          className: "tutorial-pane",
        },
      },
      {
        id: SplitParams.Contents.SelectComponentArea.id,
        Content: <FlowChartSideBar {...props.flowChart.flowChartSidebar} />,
        size: {
          value: 300,
          unit: "px",
        },
        minimizedSize: {
          value: 0,
          unit: "px",
        },
        expandedSize: {
          value: 300,
          unit: "px",
        },
      },
      {
        id: SplitParams.Contents.DrawingArea.id,
        size: {
          value: 100,
          unit: "%",
        },
        Content: <SystemDesignFlowChart key="flow-chart" {...props.flowChart} />,
      },
    ],
  };

  const activityBarProps: ActivityBarProps = {
    items: [
      {
        icon: "DESCRIPTION",
        tooltip: t("問題"),
        onClick: () => {
          if (sidebarVisibleStatus === "MINIMIZED") {
            methods.expand(SplitParams.splitId, SplitParams.Contents.CodingEditorSideBar.id);
          } else {
            methods.minimize(SplitParams.splitId, SplitParams.Contents.CodingEditorSideBar.id);
          }
          setSidebarVisibleStatus(prev => (prev === "MINIMIZED" ? "OPEN" : "MINIMIZED"));
        },
        active: sidebarVisibleStatus === "OPEN",
      },
      {
        icon: "APPS",
        tooltip: t("コンポーネント"),
        onClick: () => {
          if (selectComponentAreaVisibleStatus === "MINIMIZED") {
            methods.expand(SplitParams.splitId, SplitParams.Contents.SelectComponentArea.id);
          } else {
            methods.minimize(SplitParams.splitId, SplitParams.Contents.SelectComponentArea.id);
          }
          setSelectComponentAreaVisibleStatus(prev => (prev === "MINIMIZED" ? "OPEN" : "MINIMIZED"));
        },
        active: selectComponentAreaVisibleStatus === "OPEN",
      },
    ],
  };

  return (
    <Box display="flex" flexDirection="row" sx={{ height: "calc(100vh - 73px)", overflow: "hidden", position: "relative" }}>
      <ActivityBar {...activityBarProps} />
      <StyledSplit {...splitterProps} />
    </Box>
  );
};

SystemDesignCodingEditor.displayName = "SystemDesignCodingEditor";

export default withSplitProvider(SystemDesignCodingEditor);
