import { formateRateToIntegerPercent } from "@hireroo/formatter/rate";
import * as Graphql from "@hireroo/graphql/client/urql";
import { SupportLanguageValue } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import { Widget } from "@hireroo/presentation";

export const generateQuizQuestionCardContentProps = (
  question: Graphql.GetMultiChoicePackageForQuestionQuizIdPackageFragment["questions"][0],
  lang: SupportLanguageValue,
  index: number,
): Widget.QuizPackageDetailProps["quizQuestionCardContents"][0] => {
  const questionNumber = index + 1;
  switch (question.variant) {
    case "SINGLE_CHOICE": {
      const correctOptionValue = question.options.find(option => option.isCorrect);
      return {
        kind: "SINGLE_CHOICE",
        content: {
          id: `${question.id}-${question.version}`,
          header: {
            index: questionNumber,
            title: `${resolveLanguage(question, lang, "title", "")}`,
            description: resolveLanguage(question, lang, "description", ""),
            correctIntegerRate: formateRateToIntegerPercent(question.correctAnswerRate),
          },
          selected: correctOptionValue?.id.toString() || "",
          items: question.options.map(option => {
            return {
              value: String(option.id),
              title: resolveLanguage(option, lang, "title"),
              description: resolveLanguage(option, lang, "additionalDetail", ""),
            };
          }),
        },
      };
    }

    case "MULTI_CHOICE": {
      return {
        kind: "MULTI_CHOICE",
        content: {
          id: `${question.id}-${question.version}`,
          header: {
            index: questionNumber,
            title: `${resolveLanguage(question, lang, "title", "")}`,
            description: resolveLanguage(question, lang, "description", ""),
            correctIntegerRate: formateRateToIntegerPercent(question.correctAnswerRate),
          },
          skipped: false,
          items: question.options.map(option => {
            return {
              title: resolveLanguage(option, lang, "title"),
              checked: option.isCorrect,
              description: resolveLanguage(option, lang, "additionalDetail", ""),
            };
          }),
        },
      };
    }

    case "FREE_TEXT": {
      return {
        kind: "FREE_TEXT",
        content: {
          id: `${question.id}-${question.version}`,
          header: {
            index: questionNumber,
            title: `${resolveLanguage(question, lang, "title", "")}`,
            description: resolveLanguage(question, lang, "description", ""),
            correctIntegerRate: formateRateToIntegerPercent(question.correctAnswerRate),
          },
          items: question.evaluationItems.map(evaluation => {
            return {
              title: resolveLanguage(evaluation, lang, "title"),
            };
          }),
        },
      };
    }
    default:
      throw new Error(`Unknown type`);
  }
};
