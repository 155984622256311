import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";

const SELECTED_CLASS_NAME = "selectedMatchedTextDecorator";
const MATCHED_CLASS_NAME = "matchedTextDecorator";

const StyledDivWrapper = styled("div")(() => ({
  width: "100%", // Do not change
  height: "100%", // Do not change
}));

type Theme = "WHITE" | "TRANSPARENT";
type TextMode = "SELECTED" | "MATCHED";

export type SimilarCodeStyledWrapperProps = {
  theme?: Theme;
  children?: React.ReactNode;
};

const SimilarCodeStyledWrapper: React.FC<SimilarCodeStyledWrapperProps> = props => {
  const theme = useTheme();
  const styleMap: Record<Theme, Record<TextMode, { backgroundColor: string; cursor: "pointer" }>> = {
    WHITE: {
      SELECTED: {
        backgroundColor: theme.palette["Gray/Shades"].p30,
        cursor: "pointer",
      },
      MATCHED: {
        backgroundColor: theme.palette["Gray/Shades"].p30,
        cursor: "pointer",
      },
    },
    TRANSPARENT: {
      SELECTED: {
        backgroundColor: "transparent",
        cursor: "pointer",
      },
      MATCHED: {
        backgroundColor: "transparent",
        cursor: "pointer",
      },
    },
  };
  const overrideStyles = styleMap[props.theme || "TRANSPARENT"];
  return (
    <StyledDivWrapper sx={{ [`.${SELECTED_CLASS_NAME}`]: overrideStyles["SELECTED"], [`.${MATCHED_CLASS_NAME}`]: overrideStyles["MATCHED"] }}>
      {props.children}
    </StyledDivWrapper>
  );
};

SimilarCodeStyledWrapper.displayName = "SimilarCodeStyledWrapper";

export default SimilarCodeStyledWrapper;
