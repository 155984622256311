import { useTranslation } from "@hireroo/i18n";
import { Circle } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledChip = styled(Chip)(({ theme }) => ({
  height: 20,
  marginLeft: 8,
  position: "relative",
  top: -1,
  ".MuiChip-label": {
    fontSize: 12,
    lineHeight: 1.2,
    padding: "1px 4px",
  },
  "&.MuiChip-colorDefault": {
    color: theme.palette.text.secondary,
  },
}));

type Status = "NOT_EVALUATED" | "CORRECT" | "INCORRECT" | "NO_SUBMISSION" | "SKIP";

type EvaluateStatus = "AUTO_EVALUATED" | "EDITED";

export type SubmissionAccordionSummaryContentProps = {
  /**
   * @example 1/5
   */
  accuracyRateLabel?: string;
  questionTitle: string;
  status: Status;
  evaluateStatus?: EvaluateStatus;
};

const SubmissionAccordionSummaryContent: React.FC<SubmissionAccordionSummaryContentProps> = props => {
  const { t } = useTranslation();
  const TitleIcon: Record<Status, React.ReactNode> = {
    NOT_EVALUATED: (
      <Tooltip title={t("未評価の提出物です。採点結果を入力することで評価できます。")} arrow>
        <Circle color="disabled" fontSize="small" />
      </Tooltip>
    ),
    CORRECT: <CheckCircleIcon color="success" fontSize="small" />,
    INCORRECT: <CancelIcon color="error" fontSize="small" />,
    SKIP: <CancelIcon color="error" fontSize="small" />,
    NO_SUBMISSION: <CancelIcon color="error" fontSize="small" />,
  };

  const statusLabel: Record<Status, string> = {
    NOT_EVALUATED: "",
    CORRECT: "",
    INCORRECT: "",
    SKIP: "",
    NO_SUBMISSION: `(${t("未解答")})`,
  };

  const evaluateStatusLabel: Record<EvaluateStatus, string> = {
    AUTO_EVALUATED: t("自動評価済"),
    EDITED: t("編集済み"),
  };

  return (
    <Box display="flex" justifyContent="start" width="100%" alignItems="center" gap={1}>
      {TitleIcon[props.status]}
      {props.accuracyRateLabel && (
        <Tooltip title={[t("正解数"), t("問題数")].join("/")}>
          <StyledChip label={props.accuracyRateLabel} />
        </Tooltip>
      )}
      <Typography variant="subtitle2" flexGrow={1}>{`${props.questionTitle} ${statusLabel[props.status]}`}</Typography>
      {props.evaluateStatus && <Typography variant="caption">{evaluateStatusLabel[props.evaluateStatus]}</Typography>}
    </Box>
  );
};

SubmissionAccordionSummaryContent.displayName = "SubmissionAccordionSummaryContent";

export default SubmissionAccordionSummaryContent;
