import { Avatar, IconButton, Popover } from "@mui/material";
import * as React from "react";

import MenuList, { MenuListProps } from "./parts/MenuList/MenuList";

export type NavigationPopupMenuProps = {
  photoUrl: string;
  menuList: MenuListProps;
};

const NavigationPopupMenu: React.FC<NavigationPopupMenuProps> = props => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton role="menuopener" onClick={handleClick} edge="end" size="large">
        <Avatar
          alt="userImage"
          src={props.photoUrl}
          style={{
            height: 35,
            width: 35,
          }}
        />
      </IconButton>

      <Popover
        id="employee-menu-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuList {...props.menuList} />
      </Popover>
    </>
  );
};

NavigationPopupMenu.displayName = "NavigationPopupMenu";

export default NavigationPopupMenu;
