type ValidateFunc = (v: string) => boolean;

const isString = (v: string): boolean => {
  const s = v.indexOf('"');
  const e = v.lastIndexOf('"');

  return s >= 0 && e >= 0;
};

const isFloat = (v: string): boolean => {
  if (v === "") return false;
  return Number.isFinite(Number(v));
};

const isInteger = (v: string) => {
  if (v === "") return false;
  return Number.isInteger(Number(v));
};

const isBool = (v: string): boolean => {
  switch (v) {
    case "true":
      return true;
    case "false":
      return true;
    default:
      return false;
  }
};

const isSll = (v: string): boolean => {
  const separated = separateCurrentScope(v, ",");
  for (let i = 0; i < separated.length; i++) {
    if (!isInteger(separated[i])) {
      return false;
    }
  }

  return true;
};

const isBt = (v: string): boolean => {
  const separated = separateCurrentScope(v, ",");

  for (let i = 0; i < separated.length; i++) {
    if (separated[i] === "null") {
      continue;
    }

    if (!isInteger(separated[i])) {
      return false;
    }
  }

  return true;
};

const validMapAlgorithmPrimitive: Record<string, ValidateFunc> = {
  string: isString,
  int: isInteger,
  float: isFloat,
  bool: isBool,
  sll: isSll,
  bt: isBt,
};

export const isAlgorithmTestCaseValid = (value: string, typeString: string): boolean => {
  if (validMapAlgorithmPrimitive[typeString] !== undefined) {
    return validMapAlgorithmPrimitive[typeString](value);
  }

  if (typeString.startsWith("array") || typeString.startsWith("map")) {
    const s = typeString.indexOf("[");
    const e = typeString.lastIndexOf("]");

    if (s >= e || s === -1 || e === -1) {
      return false;
    }

    const inType = typeString.slice(s + 1, e);
    if (typeString.startsWith("array")) {
      const sv = value.indexOf("[");
      const ev = value.lastIndexOf("]");
      if (sv >= ev || sv === -1 || ev === -1) {
        return false;
      }

      const separated = separateCurrentScope(value, ",");

      for (let i = 0; i < separated.length; i++) {
        const inTypeIsValid = isAlgorithmTestCaseValid(separated[i], inType);
        if (!inTypeIsValid) {
          return false;
        }
      }

      return true;
    } else {
      // TODO: mapの実装をする。
    }
  }

  return false;
};

// separateCurrentScope parses command separated element with current scope.
// "[[1,2,3], [4,5,6]]" => ["[1,2,3]", "[4,5,6]"]
// "[1,2,3,4,5,6]" => ["1","2","3","4","5","6"]
export const separateCurrentScope = (s: string, delimiter: string) => {
  let start = 0;
  let end = 0;
  let bracketCnt = 0;
  let isEmpty = true;

  const openChars = new Map<string, boolean>();
  openChars.set("[", true);

  const closeChars = new Map<string, true>();
  closeChars.set("]", true);

  const ignoreChar = new Map<string, boolean>();
  ignoreChar.set(" ", true);
  ignoreChar.set("[", true);
  ignoreChar.set("]", true);

  const separated: string[] = [];

  for (let i = 0; i < s.length; i++) {
    if (openChars.get(s[i])) {
      bracketCnt++;
    }

    if (closeChars.get(s[i])) {
      bracketCnt--;
    }

    if (s[i] === delimiter && bracketCnt === 1 && start !== i) {
      separated.push(s.slice(start + 1, i).trim());
      start = i;
    }

    if (!ignoreChar.get(s[i])) {
      isEmpty = false;
    }

    end = i;
  }

  if (start < end && !isEmpty) {
    separated.push(s.slice(start + 1, end).trim());
  }

  return separated;
};
