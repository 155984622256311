import { generateTimestampFromDate } from "@hireroo/app-helper/parser";
import { ExamRankVisualizer } from "@hireroo/app-store/widget/shared/ExamRankVisualizer";
import { getGraphqlClient } from "@hireroo/graphql/client/request";

export type SubscribeQueryKeyArgs = {
  companyId: number;
  examId: string;
};

export const startSubscribeQueryKey = (args: SubscribeQueryKeyArgs) => {
  const client = getGraphqlClient();
  return ExamRankVisualizer.subscribeQueryKey(async query => {
    await client
      .GetExamStatisticsAndRankForExamRankVisualizer({
        statisticsSource: {
          category: "EXAM",
          query: {
            exam: {
              minTimestamp: query.enableDate && query.startDate ? generateTimestampFromDate(query.startDate) : undefined,
              maxTimestamp: query.enableDate && query.endDate ? generateTimestampFromDate(query.endDate) : undefined,
              included: [],
              companyId: query.scope === "COMPANY" ? args.companyId : undefined,
              numBins: 20,
            },
          },
        },
        rankSource: {
          category: "EXAM",
          query: {
            exam: {
              minTimestamp: query.enableDate && query.startDate ? generateTimestampFromDate(query.startDate) : undefined,
              maxTimestamp: query.enableDate && query.endDate ? generateTimestampFromDate(query.endDate) : undefined,
              included: [],
              companyId: query.scope === "COMPANY" ? args.companyId : undefined,
              examId: args.examId,
            },
          },
        },
      })
      .then(({ statistics, rank }) => {
        ExamRankVisualizer.setResult(query, {
          rank: rank,
          statistics: statistics.examStatistics,
          status: rank.numSubset < 30 ? "NOT_ENOUGH" : "SATISFY",
        });
      })
      .catch(() => {
        ExamRankVisualizer.setResult(query, {
          rank: null,
          statistics: null,
          status: "ERROR",
        });
      });
  });
};
