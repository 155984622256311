import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";

import Instruction, { InstructionProps } from "../Instruction/Instruction";
import ReviewSection, { ReviewSectionProps } from "../ReviewSection/ReviewSection";
import SelectItemSection, { SelectItemSectionProps } from "../SelectItemSelection/SelectItemSection";

type StepVariant = "INSTRUCTION" | "SELECT_ITEM" | "REVIEW";

export type TechnicalCommentFormProps = {
  step: StepVariant;
  selectItem: SelectItemSectionProps;
  review: ReviewSectionProps;
  instruction: InstructionProps;
  isLoading?: boolean;
};

const TechnicalCommentForm: React.FC<TechnicalCommentFormProps> = props => {
  const instruction: InstructionProps = {
    ...props.instruction,
  };

  const selectItem: SelectItemSectionProps = {
    ...props.selectItem,
    nextButton: {
      ...props.selectItem.nextButton,
      onClick: event => {
        props.selectItem.nextButton.onClick?.(event);
      },
    },
    backButton: {
      onClick: event => {
        props.selectItem.backButton.onClick?.(event);
      },
    },
  };

  const review: ReviewSectionProps = {
    ...props.review,
    backButton: {
      onClick: event => {
        props.review.backButton.onClick?.(event);
      },
      disabled: props.review.backButton.disabled,
    },
  };

  const ContentMap = {
    INSTRUCTION: <Instruction {...instruction} />,
    SELECT_ITEM: <SelectItemSection {...selectItem} />,
    REVIEW: <ReviewSection {...review} />,
  } satisfies Record<StepVariant, React.ReactNode>;
  return (
    <Box width="100%" height="556px" p={2}>
      {props.step !== "SELECT_ITEM" && props.isLoading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress color="secondary" />
        </Box>
      )}
      {!(props.step !== "SELECT_ITEM" && props.isLoading) && ContentMap[props.step]}
    </Box>
  );
};

TechnicalCommentForm.displayName = "TechnicalCommentForm";

export default TechnicalCommentForm;
