import { SupportLanguage, SupportLanguageValue, useTranslation } from "@hireroo/i18n";
import { AlgorithmTestCaseForm } from "@hireroo/validator";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import InputControlTextField, { InputControlTextFieldProps } from "../../../../../../../../../primitive/InputControl/InputControlTextField";
import SwitchControl from "../../../../../../../../../primitive/InputControl/SwitchControl";
import MarkdownPreviewEditor, {
  MarkdownPreviewEditorProps,
} from "../../../../../../../../../usecase/MarkdownPreviewEditor/MarkdownPreviewEditor";
import { useAlgorithmTestCasesFormContext } from "../../../../../../../Context";
import AlgorithmInputFields from "../AlgorithmInputFields/AlgorithmInputFields";

const titlePlaceholderMap: Record<SupportLanguageValue, string> = {
  [SupportLanguage.JA]: "XXのケース",
  [SupportLanguage.EN]: "Case when XX",
};
const descriptionPlaceholderMap: Record<SupportLanguageValue, string> = {
  [SupportLanguage.JA]: "XXのケースでは、YYが必要なため、返り値がZZとなる。",
  [SupportLanguage.EN]: "In the XX case, YY is needed, so the return value is ZZ.",
};

const titleMap: Record<SupportLanguageValue, string> = {
  [SupportLanguage.JA]: "タイトル",
  [SupportLanguage.EN]: "title",
};
const descriptionMap: Record<SupportLanguageValue, string> = {
  [SupportLanguage.JA]: "詳細",
  [SupportLanguage.EN]: "description",
};

export type TestCaseInputContentProps = {
  language: SupportLanguageValue;
};

const TestCaseInputContent: React.FC<TestCaseInputContentProps> = props => {
  const { t } = useTranslation();
  const { contextProps } = useAlgorithmTestCasesFormContext();
  const method = useFormContext<AlgorithmTestCaseForm.CorrectnessTestCaseFormSchemaV2>();

  const titleTextFiled: InputControlTextFieldProps = {
    variant: "outlined",
    placeholder: titlePlaceholderMap[props.language],
    type: "text",
    label: titleMap[props.language],
    fullWidth: true,
    color: "primary",
    InputLabelProps: {
      shrink: true,
    },
    required: true,
    control: method.control,
    onChange: () => {
      method.clearErrors(`correctness.title_${props.language}`);
    },
  };
  const descriptionEditor: MarkdownPreviewEditorProps = {
    required: true,
    label: descriptionMap[props.language],
    placeholder: descriptionPlaceholderMap[props.language],
    minRows: 3,
  };

  return (
    <Box>
      <Box display="flex" flexDirection="column" gap={4}>
        <Box display="flex" gap={4} justifyContent="space-between" width="100%">
          <FormGroup>
            <Box display="flex" alignItems="center">
              <Typography width="300px">{t("テスト中は表示しない")}</Typography>
              <SwitchControl name={`correctness.is_hidden`} />
            </Box>
          </FormGroup>
        </Box>
        <Box display="flex" gap={4} justifyContent="space-between" width="100%">
          <Box width="100%" display="flex" flexDirection="column" gap={4}>
            <InputControlTextField name={`correctness.title_${props.language}`} {...titleTextFiled} />

            <MarkdownPreviewEditor name={`correctness.description_${props.language}`} {...descriptionEditor} />
          </Box>
        </Box>

        <Box mt={1}>
          {contextProps.signatureProps.variant === "ALGORITHM" && <AlgorithmInputFields signature={contextProps.signatureProps.signature} />}
          {/*TODO: implement database*/}
          {/*{contextProps.signatureProps.variant === "DATABASE" && (*/}
          {/*  <DatabaseInputFields signature={contextProps.signatureProps.signature} index={props.index} />*/}
          {/*)}*/}
        </Box>
      </Box>
    </Box>
  );
};

TestCaseInputContent.displayName = "TestCaseInputContent";

export default TestCaseInputContent;
