import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import * as React from "react";

import ScreeningInviteeSearchForm, {
  ScreeningInviteeSearchFormProps,
} from "../../ms-components/Spot/ScreeningInviteeSearchForm/ScreeningInviteeSearchForm";

export type ScreeningInviteeListForDetailProps = {
  active: boolean;
  search: ScreeningInviteeSearchFormProps;
  List: React.ReactNode;
};

const ScreeningInviteeListForDetail: React.FC<ScreeningInviteeListForDetailProps> = props => {
  return (
    <Box p={3}>
      <Stack spacing={2}>
        <ScreeningInviteeSearchForm key={props.active ? "ACTIVE" : "INACTIVE"} {...props.search} />
        {props.List}
      </Stack>
    </Box>
  );
};

ScreeningInviteeListForDetail.displayName = "ScreeningInviteeListForDetail";

export default ScreeningInviteeListForDetail;
