import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type SlackNotificationSettingsContainerProps = {};

const SlackNotificationSettingsContainer: React.FC<SlackNotificationSettingsContainerProps> = () => {
  const companySettings = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Pages.CompanySettings {...companySettings} />
    </ErrorBoundary>
  );
};

SlackNotificationSettingsContainer.displayName = "SlackNotificationSettingsContainer";

export default withErrorBoundary(SlackNotificationSettingsContainer, {});
