import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Company, Role } from "@hireroo/app-store/essential/employee";
import { GroupSettings } from "@hireroo/app-store/widget/e/GroupSettings";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import * as TimeFormatter from "@hireroo/formatter/time";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/react";
import * as React from "react";

import EmployeeAssignFieldContainer from "../EmployeeAssignField/Container";

type Item = Widget.GroupSettingsProps["table"]["items"][number];
export type GenerateEmployeeGroupSettingsPropsArgs = {
  reload: () => void;
};

export const useGenerateProps = (args: GenerateEmployeeGroupSettingsPropsArgs): Widget.GroupSettingsProps => {
  const { reload } = args;
  const groups = GroupSettings.useFilteredGroups();
  const employeeMap = GroupSettings.useEmployeeMap();
  const { t } = useTranslation();
  const companyId = Company.useStrictActiveCompanyId();
  const client = getGraphqlClient();
  const matchingRole = Role.useMatchingRole();
  const dialogStatus = GroupSettings.useDialogStatus();
  const selectedGroups = GroupSettings.useSelectedGroups();

  const items = React.useMemo((): Item[] => {
    return groups.map((group): Item => {
      const avatars = group.companyEmployees.reduce<Item["avatars"]>((all, current) => {
        const employee = employeeMap[current.employeeId];
        if (employee) {
          return [
            ...all,
            {
              displayName: employee.displayName,
              src: employee.photoUrl,
            },
          ];
        }
        return all;
      }, []);
      const editDialogDefaultValues: Item["editDialog"]["defaultValues"] = {
        name: group.groupName,
        employees: group.companyEmployees.map((employee): Item["editDialog"]["defaultValues"]["employees"][0] => {
          return {
            value: {
              type: "EMPLOYEE",
              employeeId: employee.employeeId,
            },
          };
        }),
      };
      return {
        groupId: group.id,
        groupName: group.groupName,
        avatars: avatars,
        createdAt: group.createdAtSeconds ? TimeFormatter.unixToDateFormat(group.createdAtSeconds) : "",
        checkbox: {
          disabled: !matchingRole.ADMIN_ONLY.matched,
          title: matchingRole.ADMIN_ONLY.messageOnUnmatched,
          checked: selectedGroups.groups.some(deleteGroup => deleteGroup.id === group.id),
          onChange: (_, checked) => {
            if (checked) {
              GroupSettings.selectGroup(group.id);
            } else {
              GroupSettings.unselectGroup(group.id);
            }
          },
        },
        editButton: {
          onClick: () => {
            GroupSettings.updateDialogStatus(`EDIT_GROUP_OPEN_${group.id}`);
          },
        },
        editDialog: {
          dialog: {
            open: dialogStatus === `EDIT_GROUP_OPEN_${group.id}`,
            onClose: () => {
              GroupSettings.updateDialogStatus("CLOSE");
            },
            noButton: {
              onClick: () => {
                GroupSettings.updateDialogStatus("CLOSE");
              },
            },
          },
          onSubmit: async field => {
            //TODO: replace rpc when backend is ready
            const changedEmployeeIds = field.employees.reduce<string[]>((all, current) => {
              return current.value?.type === "EMPLOYEE" ? [...all, current.value.employeeId] : all;
            }, []);
            const oldEmployeeIds = group.companyEmployees.map(employee => employee.employeeId);
            const newEmployeeIds = changedEmployeeIds.filter(id => !oldEmployeeIds.includes(id));
            const deletedEmployeeIds = oldEmployeeIds.filter(id => !changedEmployeeIds.includes(id));
            const promises: Promise<Graphql.EditGroupMutation | Graphql.AddMembersToGroupMutation | Graphql.RemoveMembersMutation>[] = [
              client.EditGroup({
                groupId: group.id,
                groupName: field.name,
              }),
            ];
            if (newEmployeeIds.length > 0) {
              promises.push(
                client.AddMembersToGroup({
                  companyId: companyId,
                  groupId: group.id,
                  employeeIds: newEmployeeIds,
                }),
              );
            }
            if (deletedEmployeeIds.length > 0) {
              promises.push(
                client.RemoveMembers({
                  companyId: companyId,
                  groupId: group.id,
                  employeeIds: deletedEmployeeIds,
                }),
              );
            }
            await Promise.all(promises)
              .then(() => {
                Snackbar.notify({
                  severity: "success",
                  message: t("グループの更新に成功しました。"),
                });
                GroupSettings.updateDialogStatus("CLOSE");
                GroupSettings.clear();
                reload();
              })
              .catch(error => {
                Sentry.captureException(error);
                const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("グループの更新に失敗しました。"));
                Snackbar.notify({
                  severity: "error",
                  message: errorNotification.message,
                });
              });
          },
          EmployeeField: <EmployeeAssignFieldContainer name="employees" variant="SECONDARY" />,
          defaultValues: editDialogDefaultValues,
        },
        deleteButton: {
          onClick: () => {
            GroupSettings.updateDialogStatus(`DELETE_GROUP_OPEN_${group.id}`);
          },
        },
        deleteDialog: {
          dialog: {
            open: dialogStatus === `DELETE_GROUP_OPEN_${group.id}`,
            onClose: () => {
              GroupSettings.updateDialogStatus("CLOSE");
            },
            noButton: {
              onClick: () => {
                GroupSettings.updateDialogStatus("CLOSE");
              },
            },
          },
          name: group.groupName,
          onSubmit: async () => {
            await client
              .DeleteGroup({
                groupId: group.id,
              })
              .then(() => {
                Snackbar.notify({
                  severity: "success",
                  message: t("グループの削除に成功しました。"),
                });
                GroupSettings.DeleteGroupAction.setGroupId(null);
                GroupSettings.clear();
                GroupSettings.updateDialogStatus("CLOSE");
                reload();
              })
              .catch(error => {
                Sentry.captureException(error);
                const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("グループの削除に失敗しました。"));
                Snackbar.notify({
                  severity: "error",
                  message: errorNotification.message,
                });
              });
          },
        },
      };
    });
  }, [
    client,
    companyId,
    selectedGroups.groups,
    dialogStatus,
    employeeMap,
    groups,
    matchingRole.ADMIN_ONLY.matched,
    matchingRole.ADMIN_ONLY.messageOnUnmatched,
    reload,
    t,
  ]);

  return {
    table: {
      items: items,
    },
    header: {
      createButton: {
        onClick: () => {
          GroupSettings.updateDialogStatus("CREATE_GROUP_OPEN");
        },
      },
      deleteButton: {
        onClick: () => {
          GroupSettings.updateDialogStatus("DELETE_GROUPS_OPEN");
        },
        disabled: selectedGroups.groups.length === 0,
      },
      searchTextField: {
        placeholder: t("グループ名を検索"),
        onSubmit: field => {
          GroupSettings.updateFilterText(field.textFilter);
        },
      },
      unselectButton: {
        onClick: () => {
          GroupSettings.setSelectedGroupIds([]);
        },
        disabled: selectedGroups.groups.length === 0,
      },
      editDialog: {
        dialog: {
          open: dialogStatus === "CREATE_GROUP_OPEN",
          onClose: () => {
            GroupSettings.updateDialogStatus("CLOSE");
          },
          noButton: {
            onClick: () => {
              GroupSettings.updateDialogStatus("CLOSE");
            },
          },
        },
        onSubmit: async field => {
          await client
            .CreateGroup({
              groupName: field.name,
              companyId: companyId,
              employeeIds: field.employees.map(employee => (employee.value?.type === "EMPLOYEE" ? employee.value.employeeId : "")),
            })
            .then(() => {
              Snackbar.notify({
                severity: "success",
                message: t("グループの作成に成功しました。"),
              });
              GroupSettings.updateDialogStatus("CLOSE");
              GroupSettings.CreateGroupAction.clear();
              args.reload();
            })
            .catch(error => {
              Sentry.captureException(error);
              const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("グループの作成に失敗しました。"));
              Snackbar.notify({
                severity: "error",
                message: errorNotification.message,
              });
            });
        },
        EmployeeField: <EmployeeAssignFieldContainer name="employees" variant="SECONDARY" />,
        defaultValues: {
          name: "",
          employees: [],
        },
      },
    },
    deleteGroups: {
      dialog: {
        open: dialogStatus === "DELETE_GROUPS_OPEN",
        onClose: () => {
          GroupSettings.updateDialogStatus("CLOSE");
        },
        noButton: {
          onClick: () => {
            GroupSettings.updateDialogStatus("CLOSE");
          },
        },
      },
      groups: selectedGroups.groups.map((group): Widget.GroupSettingsProps["deleteGroups"]["groups"][0] => {
        return {
          id: group.id,
          displayName: group.groupName,
        };
      }),
      onSubmit: async () => {
        await client
          .DeleteGroups({
            groupIds: selectedGroups.groups.map(group => group.id),
          })
          .then(() => {
            Snackbar.notify({
              severity: "success",
              message: t("グループの一括削除に成功しました。"),
            });
            GroupSettings.updateDialogStatus("CLOSE");
            GroupSettings.clear();
            args.reload();
          })
          .catch(error => {
            Sentry.captureException(error);
            const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("グループの一括削除に失敗しました。"));
            Snackbar.notify({
              severity: "error",
              message: errorNotification.message,
            });
          });
      },
    },
  };
};
