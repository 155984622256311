import { Widget } from "@hireroo/presentation/legacy";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type QuizQuestionEditorContainerProps = {};

const QuizQuestionEditorContainer: React.FC<QuizQuestionEditorContainerProps> = () => {
  const props = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.QuizQuestionEditor {...props} />
    </ErrorBoundary>
  );
};

QuizQuestionEditorContainer.displayName = "QuizQuestionEditorContainer";

export default withErrorBoundary(QuizQuestionEditorContainer, {});
