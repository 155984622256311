import { MediaDeviceSchema } from "@hireroo/validator";

const key = "media-device.selected-audio-input-device";

export const SelectedAudioDeviceId = {
  get: (): MediaDeviceSchema.AudioDeviceId | null => {
    const item = localStorage.getItem(key);
    if (!item) {
      return null;
    }

    const result = MediaDeviceSchema.AudioDeviceIdSchema.safeParse(item);
    if (result.success) {
      return result.data;
    } else {
      return null;
    }
  },
  save: (id: MediaDeviceSchema.AudioDeviceId) => {
    localStorage.setItem(key, id);
  },
  clear: () => {
    localStorage.removeItem(key);
  },
};
