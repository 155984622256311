import { App, Company } from "@hireroo/app-store/essential/employee";
import { PaymentMethodUpdateForm } from "@hireroo/app-store/widget/e/PaymentMethodUpdateForm";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import PaymentMethodUpdateFormContainer, { PaymentMethodUpdateFormContainerProps } from "./Container";

export type PaymentMethodUpdateFormFetchContainerProps = {};

const PaymentMethodUpdateFormFetchContainer: React.FC<PaymentMethodUpdateFormFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const activeCompanyId = Company.useStrictActiveCompanyId();
  const initialized = PaymentMethodUpdateForm.useInitialized();
  const [result, refresh] = Graphql.useGetPaymentMethodUpdateFormQuery({
    variables: {
      customerId: activeCompanyId,
    },
    requestPolicy: "network-only",
    pause: appStatus !== "INITIALIZED",
  });

  React.useEffect(() => {
    if (result.data) {
      PaymentMethodUpdateForm.setCustomer(result.data.customer);
    }
  }, [result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }

  if (result.fetching || !result.data || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: PaymentMethodUpdateFormContainerProps = {
    customer: result.data.customer,
  };

  return <PaymentMethodUpdateFormContainer {...containerProps} />;
};

PaymentMethodUpdateFormFetchContainer.displayName = "PaymentMethodUpdateFormFetchContainer";

export default withErrorBoundary(PaymentMethodUpdateFormFetchContainer, {});
