import { TotalScoreRankVisualizer } from "@hireroo/app-store/widget/shared/TotalScoreRankVisualizer";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";

export type GenerateTotalScoreRankVisualizerPropsArgs = {
  uniqueKey: TotalScoreRankVisualizer.UniqueKey;
  /**
   * 0 <= score <= 1
   */
  score: number;
  enableRank: boolean;
  readOnly?: boolean;
};

export const useGenerateProps = (args: GenerateTotalScoreRankVisualizerPropsArgs): Widget.RankVisualizerProps => {
  const { t } = useTranslation();
  const entityHooks = TotalScoreRankVisualizer.useCreateEntityHooks(args.uniqueKey);
  const binsData = entityHooks.useBins();
  const benchMarks = entityHooks.useBenchMarks(args.score, args.enableRank);
  const query = entityHooks.useQuery();
  const graphStatus = entityHooks.useGraphStatus();
  const now = new Date();

  return {
    status: graphStatus,
    queryToolbar: {
      readOnly: args.readOnly,
      enableTagField: false,
      defaultValues: {
        enableDate: query.enableDate,
        scope: query.scope,
        startDate: query.startDate,
        endDate: query.endDate,
      },
      onChange: fields => {
        TotalScoreRankVisualizer.updateQuery(args.uniqueKey, fields);
      },
      startDatePicker: {
        max: query.endDate || now,
        datePicker: {
          maxDate: query.endDate || now,
        },
      },
      endDatePicker: {
        min: query.startDate ?? undefined,
        max: now,
        datePicker: {
          minDate: query.startDate ?? undefined,
          maxDate: now,
        },
      },
    },
    scoreHistogram: {
      data: binsData,
      dataKey: {
        x: "name",
        y: "value",
      },
      lines: benchMarks,
      xAxis: {
        label: t("スコア（%）"),
      },
      yAxis: {
        label: t("受験者数（人）"),
      },
    },
  };
};
