import { useSplitContext } from "../Context";

export const useResizeMethod = () => {
  const context = useSplitContext();

  return (params: { splitId: string; width: number; height: number }) => {
    const direction = context.store.getSplitDirectionRequired(params.splitId);
    const previousParentSize = context.store.getSplitSize(params.splitId);
    const nextParentSize = direction === "HORIZONTAL" ? params.height : params.width;
    if (nextParentSize === previousParentSize) {
      return;
    }
    const contentStates = context.store.getContentStatesBySplitId(params.splitId);
    const flexibleContentStates = contentStates.matched.filter(contentState => contentState.mode === "FLEXIBLE");
    /**
     * Content that reaches MinSize and MaxSize is treated as FIXED.
     */
    const fixedContentStates = contentStates.matched.filter(contentState => {
      if (contentState.minSize?.unit === "px" && contentState.size.value === contentState.minSize.value) {
        return true;
      }
      if (contentState.maxSize?.unit === "px" && contentState.size.value === contentState.maxSize.value) {
        return true;
      }
      return contentState.mode === "FIXED";
    });
    const totalFixedContentSize = fixedContentStates.reduce<number>((total, contentState) => {
      return total + contentState.size.value;
    }, 0);
    let totalRatio = 0;
    const lastIndex = flexibleContentStates.length - 1;
    const divideValue = nextParentSize - totalFixedContentSize;
    flexibleContentStates.forEach((contentState, index) => {
      const isFinal = index === lastIndex;
      /**
       * Prevent ratio=1 from being set for a particular content because it will not be able to be restored if the previous size is 0.
       */
      const ratio = contentState.size.value !== 0 ? contentState.size.value / divideValue : 1 / flexibleContentStates.length;
      if (!isFinal) {
        totalRatio += ratio;
      }
      /**
       * Avoid having a margin at the rightmost edge
       */
      const usedRatio = !isFinal ? ratio : 1 - totalRatio;
      const nextContentSize = usedRatio * (nextParentSize - totalFixedContentSize);
      context.store.setContentSize(contentState.contentId, { value: nextContentSize, unit: "px" });
    });
    context.store.setSplitState(params.splitId, {
      width: params.width,
      height: params.height,
    });
  };
};
