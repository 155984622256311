import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Button, { type ButtonProps } from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const Heading = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 700,
}));

const Wrapper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: 4,
}));

const Header = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: 28,
  marginBottom: 16,
}));

const ChangeButton = styled(Button)(() => ({
  minWidth: "unset",
  minHeight: "unset",
  display: "inline",
}));

export type SubsectionProps = {
  title: string;
  children: React.ReactNode;
  changeButton: Pick<ButtonProps, "onClick">;
};

const Subsection: React.FC<SubsectionProps> = props => {
  const { t } = useTranslation();
  const changeButton: ButtonProps = {
    ...props.changeButton,
    children: t("変更"),
    color: "secondary",
    variant: "text",
  };
  return (
    <Wrapper>
      <Header>
        <Heading>{props.title}</Heading>
        <ChangeButton {...changeButton} />
      </Header>
      {props.children}
    </Wrapper>
  );
};

Subsection.displayName = "Subsection";

export default Subsection;
