import Table, { TableProps } from "@mui/material/Table";
import * as React from "react";

export type DivTableProps = TableProps;

const DivTable: React.FC<DivTableProps> = props => {
  return <Table {...props} component="div" sx={{ display: "table" }} />;
};

DivTable.displayName = "DivTable";

export default DivTable;
