import { createPlaybackManager, fetchLatestPathMap } from "@hireroo/app-helper/project-playback";
import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import ProjectContentsViewerV4Container, { ProjectContentsViewerV4ContainerProps } from "./Container";
import { useInitialFileNode } from "./privateHelpers";

export type ProjectContentsViewerV4InitialContainerProps = ProjectContentsViewerV4ContainerProps;

const ProjectContentsViewerV4InitialContainer: React.FC<ProjectContentsViewerV4InitialContainerProps> = props => {
  const initialized = ProjectTestReport.ProjectContentsViewerV4.useInitialized();
  const projectHooks = ProjectTestReport.useCreateProjectHooks(props.projectId);
  const questionId = projectHooks.useQuestionId();
  const currentSubmission = projectHooks.useStrictCurrentSubmission();
  const targetDiffs = currentSubmission.diffs.filter(diff => diff.operation === "MODIFICATION" || diff.operation === "ADDITION");
  const initialFileIndex = targetDiffs.map(diff => diff.toPath).at(0);
  const noDataNode = useInitialFileNode([]);

  React.useEffect(() => {
    if (questionId && initialFileIndex) {
      fetchLatestPathMap({ projectId: props.projectId, questionId: questionId }).then(async latestPathMap => {
        ProjectTestReport.ProjectContentsViewerV4.initialize(latestPathMap);
        const latestEncodedPathWithId = latestPathMap.get(initialFileIndex);
        if (!latestEncodedPathWithId) {
          return;
        }
        const instance = await createPlaybackManager({
          projectId: props.projectId,
          questionId: questionId,
          encodedFilePath: latestEncodedPathWithId,
        });
        instance.setTickIndex(instance.lastTickIndex);
        ProjectTestReport.ProjectContentsViewerV4.initializePlaybackManager({
          playbackManager: instance,
          selectedFileIndex: initialFileIndex,
        });
      });
    }
  }, [initialFileIndex, props.projectId, questionId]);

  React.useEffect(() => {
    return () => {
      ProjectTestReport.ProjectContentsViewerV4.clear();
    };
  }, []);

  if (targetDiffs.length === 0) {
    const noDataProps: Widget.ProjectContentsViewerV4Props = {
      status: "NO_DATA",
      showPasteRange: false,
      toolbar: {
        slider: {
          min: 0,
          max: 0,
          marks: [],
          disabled: true,
          valueLabelDisplay: "off",
          valueLabelFormat: "",
        },
        value: 0,
        remainTime: "00:00",
        passedTime: "00:00",
        onChangePlayStatus: () => {},
      },
      refreshEditorKey: "",
      onChangeSliderValue: () => {},
      ActivityTimelineLog: [],
      StatisticsContents: [],
      appealMessageInReport: { title: "", body: "" },
      fileTree: {
        mode: "NORMAL",
        props: {
          renderTree: {
            node: noDataNode,
            onSelectDirectory: () => {},
            onSelectFile: () => {},
          },
        },
      },
    };
    return <Widget.ProjectContentsViewerV4 {...noDataProps} />;
  }

  if (!initialized) {
    return <Widget.Loading kind="TOP" />;
  }

  return (
    <ErrorBoundary>
      <ProjectContentsViewerV4Container {...props} />
    </ErrorBoundary>
  );
};

ProjectContentsViewerV4InitialContainer.displayName = "ProjectContentsViewerV4InitialContainer";

export default withErrorBoundary(ProjectContentsViewerV4InitialContainer, {});
