import type * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";

export const useStatusMessageMap = (): Record<Graphql.SystemDesignStatus, string> => {
  const { t } = useTranslation();
  return {
    CREATED: t("問題を開始していないため提出結果がありません。"),
    STARTED: t("問題は開始されましたが、まだ提出されておりません。"),
    COMPLETED: "",
    EVALUATED: "",
    UNKNOWN: "",
  };
};
