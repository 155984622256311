import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";

const CLASS_NAME = "pastedTextDecorator";

const StyledDivWrapper = styled("div")(() => ({
  width: "100%", // Do not change
  height: "100%", // Do not change
}));

type Theme = "RED" | "GREEN" | "TRANSPARENT";

export type StyledWrapperProps = {
  theme?: Theme;
  children?: React.ReactNode;
};

const StyledWrapper: React.FC<StyledWrapperProps> = props => {
  const theme = useTheme();
  const styleMap: Record<Theme, { backgroundColor: string; cursor: "pointer" }> = {
    RED: {
      backgroundColor: theme.palette["Error/Shades"].p50,
      cursor: "pointer",
    },
    GREEN: {
      backgroundColor: theme.palette["Success/Shades"].p50,
      cursor: "pointer",
    },
    TRANSPARENT: {
      backgroundColor: "transparent",
      cursor: "pointer",
    },
  };
  const overrideStyles = styleMap[props.theme || "TRANSPARENT"];
  return <StyledDivWrapper sx={{ [`.${CLASS_NAME}`]: overrideStyles }}>{props.children}</StyledDivWrapper>;
};

StyledWrapper.displayName = "StyledWrapper";

export default StyledWrapper;
