import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type InstructionProps = {
  startButton: Pick<ButtonProps, "onClick">;
  message: string;
};

const Instruction: React.FC<InstructionProps> = props => {
  const { t } = useTranslation();
  return (
    <Box textAlign={"left"} display={"flex"} height="100%" flexDirection={"column"} justifyContent="space-between">
      <Box mb={2}>
        <Typography align={"center"} variant={"subtitle1"}>
          <Box fontWeight="fontWeightBold">{t("技術レビューを開始する")}</Box>
        </Typography>
      </Box>
      <Box>
        <Typography color="textSecondary">{props.message}</Typography>
        <Box mt={2} />
        <Typography color="textSecondary">
          {t("入力内容は一時保存されるため、途中から再開することができますが、一度提出すると変更できないためご注意下さい。")}
        </Typography>
      </Box>
      <Box flexGrow={1} />
      <Box flexDirection={"row"} justifyContent="end" display={"flex"} width={"100%"}>
        <Button color={"primary"} variant={"contained"} {...props.startButton}>
          {t("開始")}
        </Button>
      </Box>
    </Box>
  );
};

Instruction.displayName = "Instruction";

export default Instruction;
