import { Widget } from "@hireroo/presentation/legacy";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type ErrorPanelContainerProps = {};

const ErrorPanelContainer: React.FC<ErrorPanelContainerProps> = () => {
  const errorPanelProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.ErrorPanel {...errorPanelProps} />
    </ErrorBoundary>
  );
};

ErrorPanelContainer.displayName = "ErrorPanelContainer";

export default withErrorBoundary(ErrorPanelContainer, {});
