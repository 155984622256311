import { LiveCoding, useTwilioVideoRoomContext } from "@hireroo/app-helper/hooks";
import { Auth } from "@hireroo/app-store/essential/employee";
import { RemotesId } from "@hireroo/app-store/page/e/remotes_id";
import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import * as PrivateHelper from "./privateHelper";
import { useGenerateProps } from "./useGenerateProps";

export type RemoteInterviewForEmployeeContainerProps = {
  remoteId: string;
};

const RemoteInterviewForEmployeeContainer: React.FC<RemoteInterviewForEmployeeContainerProps> = props => {
  const uid = Auth.useCurrentUid();
  const currentUser = Auth.useCurrentUser();
  const session = RemotesId.useActiveSession();
  const liveCodingId = RemotesId.useLiveCodingId();
  const fetchRemote = PrivateHelper.useFetchRemote({
    remoteId: props.remoteId,
  });
  const { room, removeLocalVideoTrack, removeLocalAudioTrack } = useTwilioVideoRoomContext();

  const cleanUpRef = React.useRef<() => void>();
  React.useEffect(() => {
    cleanUpRef.current = () => {
      room?.disconnect();
      removeLocalAudioTrack();
      removeLocalVideoTrack();
    };
  }, [room, removeLocalAudioTrack, removeLocalVideoTrack]);

  // Clean up on unmount
  React.useEffect(() => {
    return () => {
      if (cleanUpRef.current) {
        cleanUpRef.current();
        cleanUpRef.current = undefined;
      }
    };
  }, []);

  // Reload page when page is shown with back-forward cache
  // For use video call in Safari
  React.useEffect(() => {
    const handlePageShowWithBfCache = (event: PageTransitionEvent) => {
      if (!event.persisted) {
        return;
      }
      window.location.reload();
    };
    window.addEventListener("pageshow", handlePageShowWithBfCache);

    return () => {
      window.removeEventListener("pageshow", handlePageShowWithBfCache);
    };
  }, []);

  const handleChangeSyncState = React.useCallback<LiveCoding.SyncStateCallback>(
    syncState => {
      switch (syncState.s) {
        case "dels": {
          RemotesId.removeSession(syncState.v);
          break;
        }
        case "adds": {
          fetchRemote.start();
          break;
        }
      }
    },
    [fetchRemote],
  );
  const liveCodingRealtimeDatabaseArgs = React.useMemo((): LiveCoding.LiveCodingRealtimeDatabaseArgs => {
    return {
      enableBrowserEventDetector: false,
      onChangeSyncState: handleChangeSyncState,
      liveCodingId: liveCodingId,
      sessionId: session?.liveCodingSessionId,
      isInterviewing: true,
      uid: uid,
      /**
       * User Name or participant Name
       */
      participantName: currentUser.displayName,
      isCandidate: false,
      session: session
        ? {
            id: session.liveCodingSessionId,
            liveCodingId: liveCodingId,
            liveCodingQuestionType: session.questionType,
            freepadQuestion: session.freepadQuestion
              ? {
                  id: session.freepadQuestion.freepadQuestionId,
                  version: session.freepadQuestion.version,
                  questionVariant: session.freepadQuestion.variant,
                  initialFlowCharts: session.freepadQuestion.variant === "SYSTEM_DESIGN" ? session.freepadQuestion.initialFlowCharts : null,
                }
              : null,
            algorithmQuestion: session.algorithmQuestion
              ? {
                  id: session.algorithmQuestion.questionId,
                  version: session.algorithmQuestion.version,
                  questionVariant: session.algorithmQuestion.variant,
                }
              : null,
            systemDesignQuestion: session.systemDesignQuestion
              ? {
                  id: session.systemDesignQuestion.questionId,
                  initialFlowChartSnapshot: session.systemDesignQuestion.initialSnapshot,
                }
              : null,
          }
        : null,
    };
  }, [handleChangeSyncState, currentUser.displayName, liveCodingId, session, uid]);
  const { collaborativeState, collaborativeAction } = LiveCoding.useLiveCodingRealtimeDatabase(liveCodingRealtimeDatabaseArgs);
  const remoteInterviewForEmployeeProps = useGenerateProps({
    remoteId: props.remoteId,
    collaborativeAction: collaborativeAction,
    collaborativeState: collaborativeState,
  });
  PrivateHelper.useConnectRealtimeHooksToRemoteInterviewParticipantsStore({
    collaborativeState,
  });
  PrivateHelper.useConnectRealtimeHooksToRemotesIdStore({
    collaborativeState,
  });
  PrivateHelper.useSubscribeFinishEvent({
    remoteId: props.remoteId,
    collaborativeState,
  });
  return (
    <ErrorBoundary>
      <Pages.RemoteInterviewForEmployee {...remoteInterviewForEmployeeProps} />
    </ErrorBoundary>
  );
};

RemoteInterviewForEmployeeContainer.displayName = "RemoteInterviewForEmployeeContainer";

export default withErrorBoundary(RemoteInterviewForEmployeeContainer, {});
