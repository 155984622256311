import { state } from "./State";
import type * as Types from "./types";

export const initialize = (tab: Types.TabValue): void => {
  state.currentTab = tab;
};

export const updateCurrentTab = (newValue: Types.TabValue): void => {
  state.currentTab = newValue;
};

export const initializeInterview = (interview: Types.Interview) => {
  state.interview = interview;
};

export const updateInterview = (newValue: Types.Interview): void => {
  state.interview = newValue;
};

export const updateSharedLink = (newValue: Types.SharedLink): void => {
  state.sharedLink = newValue;
};

export const clear = () => {
  state.currentTab = null;
  state.interview = null;
  state.pdfExports = {};
  state.spotForRetake = null;
  state.isSpotForRetakeLoading = false;
  state.dialogStatus = "CLOSE";
};

export const setPdfExport = (id: string, spotId: Types.SpotId, outputFilename: Types.ExportPdfState["outputFilename"]) => {
  state.pdfExports = {
    ...state.pdfExports,
    [spotId]: {
      id,
      spotId,
      status: "READY",
      downloadUrl: null,
      outputFilename,
    },
  };
};

type UpdatePdfExportStateParams = {
  status?: Types.ExportPdfStatus;
  downloadUrl: string | null;
};

export const updatePdfExportState = (spotId: Types.SpotId, params: UpdatePdfExportStateParams) => {
  const pdfState = state.pdfExports[spotId];
  if (!pdfState) {
    return;
  }
  state.pdfExports = {
    ...state.pdfExports,
    [spotId]: {
      ...pdfState,
      status: params.status ?? pdfState.status,
      downloadUrl: params.downloadUrl,
    },
  };
};

export const updateDialogStatus = (newStatus: Types.DialogStatus) => {
  state.dialogStatus = newStatus;
};

export const updateSpotForRetake = (spotForRetake: Types.SpotForRetake) => {
  state.spotForRetake = spotForRetake;
};
export const updateIsSpotForRetakeLoading = (isSpotForRetakeLoading: boolean) => {
  state.isSpotForRetakeLoading = isSpotForRetakeLoading;
};
