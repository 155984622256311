import { proxyMap } from "valtio/utils";

import { createDefaultState } from "./definitions";
import { state } from "./State";
import type * as Types from "./types";

export const clear = () => {
  const defaultState = createDefaultState();
  state.activeStep = defaultState.activeStep;
  state.multiChoiceTagsSearchQuery = defaultState.multiChoiceTagsSearchQuery;
  state.selectedTags = defaultState.selectedTags;
  state.orderOfQuestionIds = defaultState.orderOfQuestionIds;
  state.previewQuestionId = defaultState.previewQuestionId;
  state.openNewQuestionEditor = defaultState.openNewQuestionEditor;
};

export const goNext = () => {
  state.activeStep += 1;
};
export const updateActiveStep = (newValue: number) => {
  state.activeStep = newValue;
};

export const TagSearch = {
  updateSelectedTags: (tags: Types.Tag[]) => {
    const newTagMap = proxyMap<number, Types.Tag>();
    tags.forEach(tag => {
      newTagMap.set(tag.id, tag);
    });
    state.selectedTags = newTagMap;
  },
  removeTag: (tagId: number) => {
    state.selectedTags.delete(tagId);
  },
};

export const updateQuestionSequence = (questionIds: (Types.UniqueKey | string)[]) => {
  state.orderOfQuestionIds = questionIds as Types.UniqueKey[];
};

export const appendOrderOfQuestionIds = (questionIds: Types.UniqueKey[]) => {
  const unmergedIds = questionIds.filter(id => !state.orderOfQuestionIds.includes(id));
  unmergedIds.forEach(id => {
    state.orderOfQuestionIds.push(id);
  });
};

export const replaceQuestionId = (oldKey: Types.UniqueKey, newKey: Types.UniqueKey) => {
  const oldIndex = state.orderOfQuestionIds.findIndex(key => key === oldKey);
  state.orderOfQuestionIds[oldIndex] = newKey;
};

export const moveIndex = (startIndex: number, insertIndex: number) => {
  const list = [...state.orderOfQuestionIds];
  const item = list[startIndex];
  list.splice(startIndex, 1);
  list.splice(insertIndex, 0, item);
  state.orderOfQuestionIds = list;
};

export const setPreviewQuestionId = (questionId: Types.UniqueKey | null) => {
  state.previewQuestionId = questionId;
};

export const previewToEdit = () => {
  state.previewQuestionId = null;
};

export const setOpenNewQuestionEditor = (open: boolean) => {
  state.openNewQuestionEditor = open;
};

export const openWarningDialog = () => {
  state.showWarningDialog = true;
};

export const closeWarningDialog = () => {
  state.showWarningDialog = false;
};

export const appendSelectedQuestions = (question: Types.Question) => {
  state.selectedQuestions.set(`${question.id}-${question.version}`, question);
  state.orderOfQuestionIds.push(`${question.id}-${question.version}`);
};

export const setSelectedQuestions = (questions: Types.Question[]) => {
  const idSet = new Set(Array.from(questions.map(question => `${question.id}-${question.version}`)));
  questions.forEach(question => {
    state.selectedQuestions.set(`${question.id}-${question.version}`, question);
  });
  Array.from(state.selectedQuestions.values()).forEach(question => {
    if (idSet.has(`${question.id}-${question.version}`)) {
      return;
    } else {
      state.selectedQuestions.delete(`${question.id}-${question.version}`);
    }
  });
};

export const removeSelectedQuestions = (questionIds: Types.UniqueKey[]) => {
  questionIds.forEach(questionId => {
    state.selectedQuestions.delete(questionId);
    const index = state.orderOfQuestionIds.findIndex(id => id === questionId);
    state.orderOfQuestionIds.splice(index);
  });
};

export const updateSelectedQuestions = (question: Types.Question) => {
  state.selectedQuestions.set(`${question.id}-${question.version}`, question);
};
