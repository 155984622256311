import { TestReport } from "@hireroo/app-store/widget/c/TestReport";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateHelpCenterUrl } from "@hireroo/router/api";

type EntityScoreSelector = Widget.ScreeningTestReportProps["summary"]["entityScoreSelector"];
type Score = Exclude<EntityScoreSelector["customScoreAllocation"], undefined>["allocationBar"]["scores"][number];

export const useGenerateCustomScoreAllocationProps = (): EntityScoreSelector["customScoreAllocation"] => {
  const { t } = useTranslation();
  const test = TestReport.useTest();
  const lang = useLanguageCode();

  return {
    allocationBar: {
      scores: test.issuedEntities.map(
        (issuedEntity, index): Score => ({
          label: `Q${index + 1}`,
          weight: issuedEntity.questionWeight,
        }),
      ),
    },
    helpLink: {
      href: generateHelpCenterUrl(lang, "CUSTOM_SCORE"),
    },
    tooltip: t("トータルスコアに対しての配点割合を表示します。"),
  };
};
