import { useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledStack = styled(Stack)(({ theme }) => ({
  borderRadius: "4px",
  backgroundColor: theme.palette["Gray/Shades"].p8,
  padding: "2px 4px",
}));

export type RateChipProps = {
  title: string;
  integerValue: number;
};

const RateChip: React.FC<RateChipProps> = props => {
  const theme = useTheme();
  return (
    <StyledStack direction="row" display="flex" height={25} alignItems="center" spacing={0.5}>
      <Typography fontWeight={700} fontSize={12} sx={{ wordBreak: "keep-all" }} color={theme.palette.info.main}>
        {props.title}
      </Typography>
      <Typography fontWeight={700} fontSize={12} color={theme.palette.info.main}>
        {props.integerValue}
      </Typography>
      <Typography fontSize={12} color={theme.palette.info.main}>
        %
      </Typography>
    </StyledStack>
  );
};

RateChip.displayName = "RateChip";

export default RateChip;
