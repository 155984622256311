import { TechnicalCommentPrompt } from "@hireroo/app-store/widget/e/TechnicalCommentPrompt";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type TechnicalCommentPopperForSpotContainerProps = {
  companyId: number;
  spotId: string;
  onClose: () => void;
};

const TechnicalCommentPopperForSpotContainer: React.FC<TechnicalCommentPopperForSpotContainerProps> = props => {
  React.useEffect(() => {
    return () => {
      TechnicalCommentPrompt.clearFormValues();
    };
  }, []);
  const technicalCommentPopperProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.TechnicalCommentPopper {...technicalCommentPopperProps} />
    </ErrorBoundary>
  );
};

TechnicalCommentPopperForSpotContainer.displayName = "TechnicalCommentPopperForSpotContainer";

export default withErrorBoundary(TechnicalCommentPopperForSpotContainer, {});
