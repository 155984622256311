import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";

export type GenerateHeaderProps = {
  entityId: number;
};

export const useGenerateHeaderProps = (args: GenerateHeaderProps): Widget.ProjectTestReportProps["header"] => {
  const lang = useLanguageCode();
  const { t } = useTranslation();
  const hooks = ProjectTestReport.useCreateProjectHooks(args.entityId);
  const question = hooks.useQuestion();

  return {
    title: [resolveLanguage(question || {}, lang, "title")].join(" "),
    linkButton: {
      onClick: async () => {
        Snackbar.notify({
          severity: "info",
          message: t("クリップボードにコピーされました"),
        });
      },
      url: location.href,
    },
  };
};
