import { QuizTestReport } from "@hireroo/app-store/view-domain/QuizTestReport";
import { TotalScoreRankVisualizer } from "@hireroo/app-store/widget/shared/TotalScoreRankVisualizer";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateQuestionDetailProps } from "../../../../props-factory/v2/view-domain/QuizTestReport/useGenerateQuestionDetailProps";
import { useStatusMessageMap } from "./privateHelper";
import { startSubscribeQueryKey } from "./Subscriber";
import { useGenerateHeaderProps } from "./useGenerateHeaderProps";
import { GenerateQuizTestReportPropsArgs, useGenerateProps } from "./useGenerateProps";

export type QuizTestReportContainerProps = Omit<GenerateQuizTestReportPropsArgs, "uniqueKey">;

const QuizTestReportContainer: React.FC<QuizTestReportContainerProps> = props => {
  const { quizId, companyId } = props;

  const hooks = QuizTestReport.useCreateQuizHooks(quizId);
  const quiz = hooks.useQuiz();
  const quizPackage = quiz.pb_package;
  const header = useGenerateHeaderProps({ quizId: quizId });
  const messageMap = useStatusMessageMap();
  const questionDetail = useGenerateQuestionDetailProps({ quizId: quizId, showAnswer: true, showArchivedMark: true });

  const uniqueKey = React.useMemo((): QuizTestReport.UniqueKey => {
    return `Quiz-${quizId}-${quiz.pb_package?.packageId}`;
  }, [quiz.pb_package?.packageId, quizId]);
  const quizTestReportProps = useGenerateProps({ ...props, uniqueKey: uniqueKey });

  React.useEffect(() => {
    if (!quizPackage || !uniqueKey || quiz.quizStatus === "STARTED" || quiz.quizStatus === "CREATED") {
      return;
    }

    TotalScoreRankVisualizer.createTotalScoreStatisticsState({
      uniqueKey,
      query: {
        endDate: quiz.evaluatedAtSeconds ? new Date(quiz.evaluatedAtSeconds * 1000) : null,
      },
    });
    const stop = startSubscribeQueryKey({
      uniqueKey,
      entityId: quiz.quizEntityId,
      companyId: companyId,
      packageId: quizPackage.packageId,
      packageVersion: quizPackage.version,
    });

    TotalScoreRankVisualizer.refreshQuery(uniqueKey);

    return () => {
      stop?.();
    };
  }, [companyId, quiz, quiz.evaluatedAtSeconds, quizPackage, uniqueKey]);

  if (quiz.quizStatus === "STARTED" || quiz.quizStatus === "CREATED" || !uniqueKey) {
    const quizProps: Widget.QuizTestReportProps = {
      header: header,
      questionDetail: questionDetail,
      announcement: messageMap[quiz.quizStatus],
    };
    return <Widget.QuizTestReport {...quizProps} />;
  }

  return (
    <ErrorBoundary>
      <Widget.QuizTestReport {...quizTestReportProps} />
    </ErrorBoundary>
  );
};

QuizTestReportContainer.displayName = "QuizTestReportContainer";

export default withErrorBoundary(QuizTestReportContainer, {});
