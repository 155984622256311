import { App, Auth, Payment } from "@hireroo/app-store/essential/employee";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation/legacy";
import { currentWindowMatchPath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";

export type GenerateNotificationBannerPropsArgs = {};

export const useGenerateProps = (_args: GenerateNotificationBannerPropsArgs): Widget.NotificationBannerProps => {
  const navigate = useTransitionNavigate();
  const { t } = useTranslation();
  const currentUser = Auth.useCurrentUser();
  const customerStatus = Payment.useCustomerStatus();
  const status = App.useStatus();
  if (status !== "INITIALIZED") {
    return {
      open: false,
      message: "",
    };
  }
  if (!currentUser.emailVerified && currentUser.provider === "PASSWORD" && !currentWindowMatchPath("/e/settings/user")) {
    return {
      open: true,
      message: [
        t("登録されたメールアドレスの認証が完了していません。"),
        `(${currentUser.email})`,
        t("HireRooから送信されたメールを確認し、メール認証を完了してください。もしメールが見当たらない場合は個人設定から再送してください。"),
      ].join(" "),
      action: {
        children: t("個人設定へ"),
        onClick: () => {
          navigate("/e/settings/user", {
            queryParams: {
              tab: "general",
            },
          });
        },
      },
    };
  }
  if (customerStatus === "INVALID") {
    return {
      open: true,
      message: t("会社設定から有効な請求情報を登録ください。テスト発行など一部機能は請求情報登録が必要です。"),
      action: {
        children: t("請求情報を確認する"),
        onClick: () => {
          navigate("/e/settings/company", {
            queryParams: {
              tab: "billing",
            },
          });
        },
      },
    };
  }
  if (customerStatus === "BEFORE_FETCH") {
    return {
      open: true,
      message: [t("請求書情報の取得に失敗しました。"), t("しばらくしてから再度お試し頂くか、ヘルプボタンよりお問い合わせください。")].join(""),
    };
  }
  return {
    open: false,
    message: "",
  };
};
