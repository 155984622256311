import { useLanguageCode } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation/legacy";
import { generateHelpCenterUrl } from "@hireroo/router/api";

export type GenerateFooterPropsArgs = {};

// TODO: We have separate candidate and employee
export const useGenerateProps = (_args: GenerateFooterPropsArgs): Widget.FooterProps => {
  const lang = useLanguageCode();
  return {
    helpCenterUrl: generateHelpCenterUrl(lang, "ROOT"),
    tosUrl: generateHelpCenterUrl(lang, "TOS_FOR_EMPLOYEE"),
    privacyUrl: `${import.meta.env.VITE_LP_URL}/privacy`,
  };
};
