import { ref } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

export const initialize = (newState: Types.State): void => {
  state.common.message = newState.common.message;
};

export const notify = (params: { message: string; severity?: Types.Severity }) => {
  state.common.kind = "NOTIFY";
  state.common.message = params.message;
  state.common.severity = params.severity || null;
  state.common.open = true;
  state.common.action = null;
};

export const notifyWithAction = (params: { message: string; severity?: Types.Severity; action: Types.Action }) => {
  state.common.kind = "ACTION";
  state.common.message = params.message;
  state.common.severity = params.severity || null;
  state.common.open = true;
  state.common.action = ref(params.action);
};

export const clearCommon = () => {
  state.common.open = false;
  state.common.message = "";
  state.common.severity = null;
  state.common.action = null;
};

export const setOpenLoadingSnackbar = (open: boolean) => {
  state.loading.open = open;
};

export const clearLoading = () => {
  state.loading.open = false;
};
