import { type SupportLanguageValue, useTranslation } from "@hireroo/i18n";
import { QuizQuestionForm } from "@hireroo/validator";
import AddCircle from "@mui/icons-material/AddCircle";
import ListIcon from "@mui/icons-material/List";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import SingleChoiceItem, { SingleChoiceItemProps } from "./SingleChoiceItem";

export type SingleChoiceFormProps = {
  language: SupportLanguageValue;
};

const SingleChoiceForm: React.FC<SingleChoiceFormProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const method = useFormContext<QuizQuestionForm.QuizQuestionFormSchema>();
  const fieldArray = useFieldArray({
    control: method.control,
    name: "singleChoiceQuestions",
  });
  const correctOptionIndex = method.getValues("singleChoiceCorrectOptionIndex");
  const SingleChoiceQuestions = fieldArray.fields.map((field, idx) => {
    const singleChoiceItemProps: SingleChoiceItemProps = {
      index: idx,
      name: `singleChoiceQuestions.${idx}`,
      language: props.language,
      deleteButton: {
        onClick: () => {
          /**
           * If the target of the deletion is a correct option, prevent the correct option from being flagged as gone.
           */
          if (correctOptionIndex.toString() === idx.toString()) {
            method.setValue("singleChoiceCorrectOptionIndex", "0");
          }
          fieldArray.remove(idx);
        },
      },
    };
    return <SingleChoiceItem key={`question-${field.id}`} {...singleChoiceItemProps} />;
  });
  const addButtonProps: ButtonProps = {
    onClick: () => {
      const selectedLanguages = method.getValues("selectedLanguages");
      fieldArray.append({
        isCorrect: false,
        content: {
          ...(selectedLanguages.find(({ value }) => value === "en") && {
            en: {
              title: "",
              additionalDetail: "",
            },
          }),
          ...(selectedLanguages.find(({ value }) => value === "ja") && {
            ja: {
              title: "",
              additionalDetail: "",
            },
          }),
        },
      });
    },
  };
  return (
    <Box>
      <Stack direction="row" spacing={2} alignItems="center">
        <ListIcon fontSize="small" sx={{ color: theme.palette.common.white }} />
        <Typography mr={0.5} variant="subtitle1" sx={{ fontWeight: "bold" }}>
          {t("設問の選択肢を追加する")}
        </Typography>
      </Stack>
      <Stack spacing={4} divider={<Divider />}>
        {SingleChoiceQuestions}
      </Stack>
      <Box display="flex" justifyContent="flex-end" mt={1}>
        <Button {...addButtonProps} startIcon={<AddCircle />}>
          {t("選択肢を追加する")}
        </Button>
      </Box>
    </Box>
  );
};

SingleChoiceForm.displayName = "SingleChoiceForm";

export default SingleChoiceForm;
