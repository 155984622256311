import { styled, useTheme } from "@mui/material/styles";
import React, { useEffect, useRef } from "react";

import { Area, ComponentType, ELEMENT_LABEL, ELEMENT_TYPE, FlowElement, NodeElement, UserState } from "../../../features";
import { edgeCoordinatesFromNodes, geometryFromElement } from "../../../helpers/flowChart";
import CommentForStaticViewbox from "../../modules/CommentForStaticViewbox/CommentForStaticViewbox";
import Cursor from "../../modules/Cursor/Cursor";
import { Edge } from "../../modules/Edge/Edge";
import Network from "../../modules/Network/Network";
import { Node } from "../../modules/Node/Node";
import { ScaledNode } from "../../modules/ScaledNode/ScaledNode";
import Username from "../../modules/Username/Username";

export type StaticViewboxProps = {
  viewbox: Area;
  elementIds: string[];
  elements: Record<string, FlowElement>;
  componentType: ComponentType;
  viewarea?: Area;
  onWheel?: (e: React.WheelEvent<SVGSVGElement>, svgElement: SVGSVGElement) => void;
  disableGrid?: boolean;
  simpleView?: boolean;
  matchedIds?: string[];
  authors?: UserState[];
};

const StyledSvg = styled("svg")(() => ({
  "&:focus": {
    outline: "none",
  },
}));

export const StaticViewbox: React.FC<StaticViewboxProps> = (props: StaticViewboxProps) => {
  const theme = useTheme();
  const svgRef = useRef<SVGSVGElement>(null);

  const handleMouseWheel = (e: React.WheelEvent<SVGSVGElement>) => {
    if (svgRef.current && props.onWheel) {
      props.onWheel(e, svgRef.current);
    }
  };

  useEffect(() => {
    const svg = svgRef.current;
    if (svg) {
      svg.addEventListener(
        "wheel",
        e => {
          e.preventDefault();
          return;
        },
        { passive: false },
      );
    }

    return () => {
      if (svg) {
        svg.removeEventListener("wheel", e => {
          e.preventDefault();
          return;
        });
      }
    };
  }, []);

  // Comment has only minX and minY since it's box-size is calculated by its length or fontSize dynamically,
  // hence we need handling change comment area.
  const [commentGeometries, setCommentGeometries] = React.useState<Record<string, Area>>({});
  const handleChangeCommentArea = React.useCallback(
    (id: string, area: Area) => {
      if (!props.elements[id]) {
        return;
      }
      setCommentGeometries(prev => {
        prev[id] = area;
        return { ...prev };
      });
    },
    [props.elements],
  );

  const minimumAreaFactory = React.useCallback(
    (elementIds: string[]) => {
      const minimumArea: Area = {
        minX: Number.MAX_SAFE_INTEGER,
        minY: Number.MAX_SAFE_INTEGER,
        maxX: Number.MIN_SAFE_INTEGER,
        maxY: Number.MIN_SAFE_INTEGER,
      };

      elementIds.forEach(elementId => {
        // create
        const geometry = (() => {
          const element = props.elements[elementId];
          if (!element) return;
          switch (element.type) {
            case ELEMENT_TYPE.edge: {
              const { from, to } = edgeCoordinatesFromNodes(
                props.elements[element.source] as NodeElement,
                props.elements[element.target] as NodeElement,
              );
              return {
                minX: Math.min(from.x, to.x),
                maxX: Math.max(from.x, to.x),
                minY: Math.min(from.y, to.y),
                maxY: Math.max(from.y, to.y),
              };
            }
            case ELEMENT_TYPE.comment:
              return commentGeometries[element.id] ?? geometryFromElement(element);
            default:
              return geometryFromElement(element);
          }
        })();

        if (!geometry) return;

        // Update minimumArea
        if (geometry.minX < minimumArea.minX) {
          minimumArea.minX = geometry.minX;
        }
        if (geometry.minY < minimumArea.minY) {
          minimumArea.minY = geometry.minY;
        }
        if (geometry.maxX > minimumArea.maxX) {
          minimumArea.maxX = geometry.maxX;
        }
        if (geometry.maxY > minimumArea.maxY) {
          minimumArea.maxY = geometry.maxY;
        }
      });

      return minimumArea;
    },
    [commentGeometries, props.elements],
  );

  const backgroundColorMap: Record<"light" | "dark", string> = {
    light: props.disableGrid ? theme.palette.background.paper : theme.palette.grey[50],
    dark: props.disableGrid ? theme.palette.background.paper : theme.palette.grey[50],
  };

  return (
    <StyledSvg
      version="1.1"
      viewBox={`${props.viewbox.minX} ${props.viewbox.minY} ${props.viewbox.maxX - props.viewbox.minX} ${
        props.viewbox.maxY - props.viewbox.minY
      }`}
      tabIndex={0}
      focusable={false}
      ref={svgRef}
      onWheel={handleMouseWheel}
      style={{ backgroundColor: backgroundColorMap[theme.palette.mode] }}
    >
      {!props.disableGrid && (
        <g>
          <defs>
            <pattern id="smallGrid" width="20" height="20" patternUnits="userSpaceOnUse">
              <path d="M 20 0 L 0 0 0 20" fill="none" stroke={theme.palette.grey[500]} strokeWidth="0.5" />
            </pattern>
            <pattern id="grid" width="80" height="80" patternUnits="userSpaceOnUse">
              <rect width="80" height="80" fill="url(#smallGrid)" />
              <path d="M 80 0 L 0 0 0 80" fill="none" stroke={theme.palette.grey[500]} strokeWidth="1" />
            </pattern>
          </defs>
          <rect
            x={props.viewbox.minX}
            y={props.viewbox.minY}
            width={props.viewbox.maxX - props.viewbox.minX}
            height={props.viewbox.maxY - props.viewbox.minY}
            fill="url(#grid)"
          />
        </g>
      )}
      {props.elementIds
        .filter(id => props.elements[id].type === ELEMENT_TYPE.network)
        .map(networkId => {
          const element = props.elements[networkId];
          return (
            <React.Fragment key={`network-${networkId}`}>
              {element.type === ELEMENT_TYPE.network && (
                <Network
                  network={element}
                  componentType={props.componentType}
                  simpleView={props.simpleView}
                  isMatch={props.matchedIds && props.matchedIds.includes(networkId)}
                />
              )}
            </React.Fragment>
          );
        })}

      {props.elementIds.map(elementId => {
        const element = props.elements[elementId];
        if (element.type === ELEMENT_TYPE.node) {
          return (
            <React.Fragment key={`node-${element.id}`}>
              {element.label === ELEMENT_LABEL.vm && element.settings.autoScale ? (
                <ScaledNode
                  node={element}
                  componentType={props.componentType}
                  simpleView={props.simpleView}
                  isMatch={props.matchedIds && props.matchedIds.includes(elementId)}
                />
              ) : (
                <Node
                  node={element}
                  componentType={props.componentType}
                  simpleView={props.simpleView}
                  isMatch={props.matchedIds && props.matchedIds.includes(elementId)}
                />
              )}
            </React.Fragment>
          );
        }

        if (element.type === ELEMENT_TYPE.edge) {
          const { from, to } = edgeCoordinatesFromNodes(
            props.elements[element.source] as NodeElement,
            props.elements[element.target] as NodeElement,
          );

          return (
            <React.Fragment key={`edge-${element.id}`}>
              <Edge
                from={from}
                to={to}
                direction={element.settings.direction}
                isMatch={props.matchedIds && props.matchedIds.includes(elementId)}
              />
            </React.Fragment>
          );
        }

        if (element.type === ELEMENT_TYPE.comment && !props.simpleView) {
          return (
            <React.Fragment key={`comment-${elementId}`}>
              <CommentForStaticViewbox
                content={element.content}
                minX={element.geometry.minX}
                minY={element.geometry.minY}
                fontSize={element.settings.fontSize}
                onChangeArea={area => handleChangeCommentArea(element.id, area)}
              />
            </React.Fragment>
          );
        }
      })}

      {props.authors &&
        props.authors.map(user => {
          if (user.edit) {
            if (!props.elementIds.includes(user.edit)) return;

            const area = minimumAreaFactory([user.edit]);
            const minX = area.minX - 20;
            const minY = area.minY - 20;
            const maxX = area.maxX + 20;
            const maxY = area.maxY + 20;

            return (
              <g key={`collaborator-area-${user.uid}`}>
                <Username name={user.name} color={user.color} x={minX} y={minY} textAnchor={"start"} />
                <polygon
                  points={`${minX},${minY} ${maxX},${minY} ${maxX},${maxY} ${minX},${maxY}`}
                  stroke={user.color}
                  strokeWidth={2}
                  fill={user.color}
                  fillOpacity={0.1}
                  strokeDasharray={"8 8"}
                />
              </g>
            );
          } else if (user.select && user.select.length > 0) {
            if (user.select.some(id => !props.elementIds.includes(id))) return;

            const area = minimumAreaFactory(user.select);
            const minX = area.minX - 20;
            const minY = area.minY - 20;
            const maxX = area.maxX + 20;
            const maxY = area.maxY + 20;

            return (
              <g key={`collaborator-area-${user.uid}`}>
                <Username name={user.name} color={user.color} x={minX} y={minY} textAnchor={"start"} />
                <polygon
                  points={`${minX},${minY} ${maxX},${minY} ${maxX},${maxY} ${minX},${maxY}`}
                  stroke={user.color}
                  strokeWidth={2}
                  strokeDasharray={"8 8"}
                  fill={"none"}
                />
              </g>
            );
          } else if (user.cursor && !user.select) {
            return (
              <g key={`collaborator-cursor-${user.uid}`}>
                <Cursor color={user.color} x={user.cursor.x} y={user.cursor.y} />
                <Username name={user.name} color={user.color} x={user.cursor.x + 30} y={user.cursor.y + 50} textAnchor={"start"} />
              </g>
            );
          }
        })}

      {props.viewarea && (
        <rect
          x={props.viewarea.minX}
          y={props.viewarea.minY}
          width={props.viewarea.maxX - props.viewarea.minX}
          height={props.viewarea.maxY - props.viewarea.minY}
          fill={theme.palette.grey[300]}
          fillOpacity={0.3}
          stroke={"none"}
        />
      )}
    </StyledSvg>
  );
};
