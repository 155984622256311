import { useTranslation } from "@hireroo/i18n";
import { QuestionPackageSearchForm } from "@hireroo/validator";

import type { PopupSearchFilterFieldProps } from "../../../../../../modules/PopupSearchFilterField/PopupSearchFilterField";

type FieldProps = PopupSearchFilterFieldProps["fields"][0];
export type MultiChoiceFieldPrimitiveValueProps = Extract<FieldProps, { kind: "MULTI_CHOICE_PRIMITIVE_VALUE" }>;

type FieldKeyName = keyof QuestionPackageSearchForm.QuestionPackageSearchQuery;

export const FieldName = {
  TEXT_FILTER: "textFilter",
  DIFFICULTIES: "difficulties",
} satisfies Record<string, FieldKeyName>;

type DifficultiesOption = {
  displayName: string;
  value: QuestionPackageSearchForm.DifficultySchema[0];
};

export const useDifficultyField = (): MultiChoiceFieldPrimitiveValueProps => {
  const { t } = useTranslation();
  const options: DifficultiesOption[] = [
    { value: "EASY", displayName: t("易しい") },
    { value: "MEDIUM", displayName: t("ふつう") },
    { value: "DIFFICULT", displayName: t("難しい") },
  ];
  return {
    kind: "MULTI_CHOICE_PRIMITIVE_VALUE",
    title: t("難易度"),
    name: FieldName.DIFFICULTIES,
    options: options,
  };
};
