import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";

export const useSpotStatusMap = (): Record<Graphql.SpotStatus, string> => {
  const { t } = useTranslation();
  return {
    [Graphql.SpotStatus.Unknown]: t("ステータス不明"),
    [Graphql.SpotStatus.Created]: t("作成済み"),
    [Graphql.SpotStatus.Accepted]: t("作成済み"),
    [Graphql.SpotStatus.Started]: t("実施中"),
    [Graphql.SpotStatus.Completed]: t("実施済み"),
    [Graphql.SpotStatus.Finalized]: t("採点済み"),
    [Graphql.SpotStatus.Declined]: t("期限切れ"),
    [Graphql.SpotStatus.Expired]: t("期限切れ"),
    [Graphql.SpotStatus.Reviewed]: t("評価済み"),
    [Graphql.SpotStatus.Evaluated]: t("評価済み"),
  };
};

export const useInvitationMethodMap = (): Record<Graphql.InvitationMethod, string> => {
  const { t } = useTranslation();
  return {
    [Graphql.InvitationMethod.Unknown]: "",
    [Graphql.InvitationMethod.Link]: t("リンク"),
    [Graphql.InvitationMethod.Email]: t("メール"),
  };
};

export const useRankEvaluationMap = (): Record<Graphql.RankEvaluation, string> => {
  const { t } = useTranslationWithVariable();
  return {
    [Graphql.RankEvaluation.S]: t("RankName", { rank: Graphql.RankEvaluation.S }),
    [Graphql.RankEvaluation.A]: t("RankName", { rank: Graphql.RankEvaluation.A }),
    [Graphql.RankEvaluation.B]: t("RankName", { rank: Graphql.RankEvaluation.B }),
    [Graphql.RankEvaluation.C]: t("RankName", { rank: Graphql.RankEvaluation.C }),
    [Graphql.RankEvaluation.D]: t("RankName", { rank: Graphql.RankEvaluation.D }),
    [Graphql.RankEvaluation.Unknown]: "",
  };
};
