import { useTranslation } from "@hireroo/i18n";
import { TagForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";
import TagInput, { TagInputProps } from "../../primitive/TagInput/TagInput";

export type TagDialogProps = {
  tagInputField: Pick<TagInputProps["tagSearch"], "disabled" | "options">;
  onSubmit: SubmitHandler<TagForm.TagsSchema>;
  defaultValues: TagForm.TagsSchema;
  disabled?: boolean;
  open: boolean;
  onOpen?: () => void;
  onClose?: DialogWithCloseIconProps["onClose"];
};

const TagDialog: React.FC<TagDialogProps> = props => {
  const { t } = useTranslation();
  const validateSchema = TagForm.useTagsFormSchema();
  const methods = useForm<TagForm.TagsSchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: props.defaultValues,
    mode: "onSubmit",
  });
  const [searchText, setSearchText] = React.useState("");
  const { tags: errorTags } = methods.formState.errors;

  const handleChangeAutocomplete = React.useCallback(
    (tagNames: string[]) => {
      const newDraft = [...new Set(tagNames)];
      methods.setValue("tags", newDraft);
      setSearchText("");
    },
    [methods],
  );

  const handleBlurAutocomplete = React.useCallback(
    (_: React.FocusEvent<HTMLDivElement>) => {
      const selectedTags = methods.getValues("tags");
      if (searchText !== "") {
        const newDraft = [...new Set((selectedTags || []).concat(searchText))];
        methods.setValue("tags", newDraft);
        setSearchText("");
      }
    },
    [methods, searchText],
  );

  const tagInputFieldProps: TagInputProps = {
    tagSearch: {
      ...props.tagInputField,
      value: methods.watch("tags"),
      onChange: (_, value: string[]) => {
        methods.clearErrors();
        handleChangeAutocomplete(value);
      },
      onBlur: handleBlurAutocomplete,
      filterSelectedOptions: true,
    },
    tagSearchField: {
      placeholder: methods.getValues("tags").length === 0 ? t("タグを選択") : "",
      value: searchText,
      onChange: React.useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSearchText(e.currentTarget.value);
      }, []),
      color: "secondary",
      error: Boolean(errorTags?.message),
      helperText: errorTags?.message ?? "",
    },
    onChange: (tags: string[]) => {
      methods.setValue("tags", tags);
    },
  };
  const tagDialog: DialogWithCloseIconProps = {
    open: props.open,
    title: t("タグの編集"),
    onClose: () => {
      props.onClose?.();
    },
    noButton: {
      onClick: () => {
        props.onClose?.();
      },
      color: "secondary",
      children: t("キャンセル"),
    },
    disableEnter: true,
    yesButton: {
      onClick: () => {
        methods.handleSubmit(props.onSubmit)();
      },
      color: "secondary",
      children: t("保存"),
    },
  };
  return (
    <DialogWithCloseIcon key="tag-dialog" {...tagDialog}>
      <FormProvider {...methods}>
        <TagInput {...tagInputFieldProps} />
      </FormProvider>
    </DialogWithCloseIcon>
  );
};

TagDialog.displayName = "TagDialog";

export default TagDialog;
