import * as React from "react";

import EmptyLayout, { EmptyLayoutProps } from "../../layouts/EmptyLayout/EmptyLayout";

export type EmptyProps = {
  layout: EmptyLayoutProps;
};

const Empty: React.FC<EmptyProps> = props => {
  return <EmptyLayout {...props.layout} />;
};

Empty.displayName = "Empty";

export default Empty;
