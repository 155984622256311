import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";

export type DeleteGroupDialogProps = {
  dialog: Pick<DialogWithCloseIconProps, "open" | "onClose" | "yesButton" | "noButton">;
  name: string;
  onSubmit?: () => void;
};

const DeleteGroupDialog: React.FC<DeleteGroupDialogProps> = props => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();

  const baseDialogProps: DialogWithCloseIconProps = {
    ...props.dialog,
    title: t("グループの削除"),
    yesButton: {
      ...props.dialog.yesButton,
      disabled: false,
      color: "error",
      children: t("削除"),
      onClick: () => {
        props.dialog.yesButton?.onClick?.();
        props.onSubmit?.();
      },
      autoFocus: false,
    },
    noButton: {
      ...props.dialog.noButton,
      disabled: false,
      variant: "outlined",
      color: "outline-only",
      children: t("キャンセル"),
    },
    disableEnter: true,
    optionalDialog: {
      disableAutoFocus: true,
    },
    optionalContent: {
      sx: {
        px: 3,
        py: 2,
      },
    },
  };
  return (
    <DialogWithCloseIcon {...baseDialogProps}>
      <Typography fontSize={14} mb={1}>
        {t2("DeleteGroup", {
          groupName: props.name,
        })}
      </Typography>
      <Typography fontSize={14}>{t("削除されたグループは復元できません。")}</Typography>
    </DialogWithCloseIcon>
  );
};

DeleteGroupDialog.displayName = "DeleteGroupDialog";

export default DeleteGroupDialog;
