import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import Container from "./Container";
import FreepadResourceProvider from "./Provider";

export type QuizResourceEditorMiddleContainerProps = {};

const FreepadResourceEditorMiddleContainer: React.FC<QuizResourceEditorMiddleContainerProps> = _props => {
  return (
    <ErrorBoundary>
      <FreepadResourceProvider type={"CREATE"}>
        <Container />
      </FreepadResourceProvider>
    </ErrorBoundary>
  );
};

FreepadResourceEditorMiddleContainer.displayName = "FreepadResourceEditorMiddleContainer";

export default withErrorBoundary(FreepadResourceEditorMiddleContainer, {});
