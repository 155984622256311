import CodeEditor, { CodeEditorProps } from "@hireroo/code-editor/react/CodeEditor";
import * as React from "react";

export type PlaybackContentFreeTextProps = {
  editor: CodeEditorProps;
};

const PlaybackContentFreeText: React.FC<PlaybackContentFreeTextProps> = props => {
  const codeEditorProps: CodeEditorProps = {
    ...props.editor,
    height: "500px",
    language: "markdown",
    options: {
      readOnly: true,
      lineNumbers: "off",
    },
  };

  return (
    <React.Suspense>
      <CodeEditor key="code-editor" {...codeEditorProps} />
    </React.Suspense>
  );
};

PlaybackContentFreeText.displayName = "PlaybackContentFreeText";

export default PlaybackContentFreeText;
