import { Auth } from "@hireroo/app-store/essential/employee";
import { ScreeningResourceEditor } from "@hireroo/app-store/widget/e/ScreeningResourceEditor";
import { Widget } from "@hireroo/presentation";

export type GenerateTestInviteSetupSectionArgs = {
  mode: Widget.ScreeningResourceEditorProps["mode"];
  defaultValues?: Widget.ScreeningResourceEditorProps["testInviteSetupSection"]["defaultValues"];
};

export const useGenerateTestInviteSetupSectionProps = (
  args: GenerateTestInviteSetupSectionArgs,
): Widget.ScreeningResourceEditorProps["testInviteSetupSection"] => {
  const currentUser = Auth.useCurrentUser();
  return {
    onSubmit: fields => {
      ScreeningResourceEditor.setSubmitValue("TEST_INVITE_SETUP", fields);
    },
    defaultValues: args.defaultValues || {
      invitationLanguage: "ja",
      messageForCandidate: null,
      candidateAccessPolicy: "ALLOW_ALL",
      ownerEmailAddress: null,
    },
    emptyDefaultOwnerEmail: args.defaultValues?.ownerEmailAddress ?? currentUser.email,
  };
};
