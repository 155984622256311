import { variantMap } from "@hireroo/app-definition/freepad";
import { FeedbackLinkFactory } from "@hireroo/app-helper/question";
import { useTitle } from "@hireroo/app-helper/react-use";
import { Credential } from "@hireroo/app-store/essential/shared";
import { languageMapForHighlight } from "@hireroo/challenge/definition";
import { secondsToTimeObject } from "@hireroo/formatter/time";
import type * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import { Widget } from "@hireroo/presentation";
import * as React from "react";

type Props = Extract<Widget.FreepadQuestionDetailProps, { kind: "ALGORITHM" }>;

export type GenerateFreepadQuestionDetailPropsArgs = {
  question: Graphql.FreepadQuestionDetailFragment;
};

export const useGenerateProps = (args: GenerateFreepadQuestionDetailPropsArgs): Props => {
  const lang = useLanguageCode();
  const currentUserMailAddress = Credential.useCurrentUserMailAddress();
  const variant = variantMap[args.question.variant];
  const [answerIndex, setAnswerIndex] = React.useState(0);
  const answer = args.question.answers.at(answerIndex);

  const answerCodeMap = React.useMemo((): Record<string, string> => {
    if (variant === "ALGORITHM" && answer) {
      return answer.answerCodes.reduce((previousValue, currentValue) => {
        return { ...previousValue, [currentValue.runtime]: currentValue.body };
      }, {});
    }
    return {};
  }, [answer, variant]);
  const firstSelectableRuntime = Object.keys(answerCodeMap).at(0);
  const [selectedLanguage, setSelectedLanguage] = React.useState<string>(firstSelectableRuntime ?? "");

  const [open, setOpen] = React.useState<boolean>(args.question.status !== "PUBLISHED" && args.question.status !== "ARCHIVED");

  useTitle(resolveLanguage(args.question, lang, "title"));

  const averageElapsedTimeObject = secondsToTimeObject(args.question.averageElapsedTimeSeconds, "MINUTES");

  const answerSections = args.question.answers.map((answer, index): Props["content"]["answerSections"][0] => {
    return {
      title: resolveLanguage(answer, lang, "title"),
      description: resolveLanguage(answer, lang, "description"),
      onSelect: () => {
        setAnswerIndex(index);
      },
    };
  });

  return {
    kind: "ALGORITHM",
    content: {
      questionAndAnswerTab: {
        questionSection: {
          title: resolveLanguage(args.question, lang, "title"),
          description: resolveLanguage(args.question, lang, "description"),
          isArchived: args.question.status === "ARCHIVED",
        },
      },
      answerSections: answerSections,
      codeEditor: {
        value: answerCodeMap[selectedLanguage] ?? "",
        language: languageMapForHighlight[selectedLanguage],
        options: {
          readOnly: true,
        },
      },
      runtimeSelector: {
        selectedLanguage: selectedLanguage,
        enabledLanguages: answer?.answerCodes.map(code => code.runtime),
        onChange: event => {
          const language = event.target.value;
          setSelectedLanguage(language);
        },
        disabled: false,
      },
      information: {
        feedbackLink: FeedbackLinkFactory.generateFeedbackLink({
          mailAddress: currentUserMailAddress,
          targetUrl: window.location.href,
        }),
        stats: {
          numOfUsage: args.question.numUses.toLocaleString(),
          avgElapseTime:
            args.question.numUses > 0
              ? {
                  kind: "VALUE",
                  minutes: averageElapsedTimeObject.minutes,
                  seconds: averageElapsedTimeObject.seconds,
                }
              : { kind: "NONE" },
        },
        detailInfo: {
          difficultyStars: {
            difficulty: args.question.difficulty,
          },
          createdBy: args.question.isOfficial
            ? {
                kind: "OFFICIAL",
              }
            : {
                kind: "PRIVATE",
                displayName: args.question.employee.displayName,
              },
        },
      },
    },
    notificationDialog: {
      open: open,
      onClose: () => {
        setOpen(false);
      },
    },
  };
};
