import { useTranslation } from "@hireroo/i18n";
import { StaticFlowChart, type StaticFlowChartProps } from "@hireroo/system-design/react/v2";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";

import ExecutableTestCaseTable, { ExecutableTestCaseTableProps } from "../../../../modules/ExecutableTestCaseTable/ExecutableTestCaseTable";
import NoActionDialog, { NoActionDialogProps } from "../../../../primitive/NoActionDialog/NoActionDialog";

type Row = ExecutableTestCaseTableProps["rows"][0];

export type TestItem = {
  staticFlowChart?: StaticFlowChartProps;
  message: string;
} & Omit<Row, "Detail">;

export type TestCaseDialogProps = {
  dialog: Pick<NoActionDialogProps, "open" | "onClose">;
  items: TestItem[];
};

const TestCaseDialog: React.FC<TestCaseDialogProps> = props => {
  const { t } = useTranslation();
  const baseDialogProps: NoActionDialogProps = {
    ...props.dialog,
    heading: t("テストケースの実行"),
  };

  const executableTestCaseTable: ExecutableTestCaseTableProps = {
    rows: props.items.map((item): Row => {
      return {
        open: item.open,
        status: item.status,
        title: item.title,
        runButton: item.runButton,
        Detail: (
          <Box m={1} p={1}>
            <Box mt={1}>
              <Box>
                <Typography>{item.message}</Typography>
              </Box>
              {item.staticFlowChart && (
                <Box width={"100%"} mt={2}>
                  <Typography sx={{ mb: 2 }}>{t("該当箇所")}</Typography>
                  <React.Suspense>
                    <StaticFlowChart {...item.staticFlowChart} />
                  </React.Suspense>
                </Box>
              )}
            </Box>
          </Box>
        ),
      };
    }),
  };

  return (
    <NoActionDialog {...baseDialogProps}>
      <ExecutableTestCaseTable {...executableTestCaseTable} />
    </NoActionDialog>
  );
};

TestCaseDialog.displayName = "TestCaseDialog";

export default TestCaseDialog;
