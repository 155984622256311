import { FreepadResourceEditor } from "@hireroo/app-store/view-domain/FreepadResourceEditor";
import { getLanguage } from "@hireroo/i18n";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import FetchContainer from "./FetchContainer";
import FreepadResourceEditorMiddleContainer from "./MiddleContainer";

export type FreepadResourceEditorInitialContainerProps = {};

const variantMap: Record<string, "ALGORITHM" | "SYSTEM_DESIGN"> = {
  algorithm: "ALGORITHM",
  systemDesign: "SYSTEM_DESIGN",
};

const FreepadResourceEditorInitialContainer: React.FC<FreepadResourceEditorInitialContainerProps> = () => {
  const [query] = useSearchParams();
  const initVariant: string | null = query.get("type");

  const draftQuestionId: number | null = query.get("id") !== null ? Number(query.get("id")) : null;

  React.useEffect(() => {
    if (initVariant && draftQuestionId === null) {
      FreepadResourceEditor.initialize(getLanguage(), variantMap[initVariant]);
    }
  }, [draftQuestionId, initVariant]);

  React.useEffect(() => {
    return () => {
      FreepadResourceEditor.clear();
    };
  });

  if (draftQuestionId === null) {
    return (
      <ErrorBoundary>
        <FreepadResourceEditorMiddleContainer />
      </ErrorBoundary>
    );
  }
  return (
    <ErrorBoundary>
      <FetchContainer questionId={draftQuestionId} />
    </ErrorBoundary>
  );
};
FreepadResourceEditorInitialContainer.displayName = "FreepadResourceEditorInitialContainer";

export default withErrorBoundary(FreepadResourceEditorInitialContainer, {});
