export type TimeObject = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

type UNIT = "SECONDS" | "DAY" | "HOURS" | "MINUTES";

/**
 * Decompose the input numerical value into objects.
 *
 * @param seconds
 * @param unit Maximum units to display
 * @param timer {TimeObject}
 * @returns
 */
export const secondsToTimeObject = (
  seconds: number,
  unit: UNIT,
  timer: TimeObject = { days: 0, minutes: 0, seconds: 0, hours: 0 },
): TimeObject => {
  if (seconds < 0) {
    return timer;
  }
  switch (unit) {
    case "DAY": {
      const SECONDS_IN_DAY = 86400; // = 60 * 60 * 24;
      const days = Math.floor(seconds / SECONDS_IN_DAY);
      return secondsToTimeObject(seconds - days * SECONDS_IN_DAY, "HOURS", {
        ...timer,
        days,
      });
    }
    case "HOURS": {
      const SECONDS_IN_ONE_HOUR = 3600; // = 60 * 60;
      const hours = Math.floor(seconds / SECONDS_IN_ONE_HOUR);
      return secondsToTimeObject(seconds - hours * SECONDS_IN_ONE_HOUR, "MINUTES", {
        ...timer,
        hours,
      });
    }
    case "MINUTES": {
      const SECONDS_IN_ONE_MINUTE = 60;
      const minutes = Math.floor(seconds / SECONDS_IN_ONE_MINUTE);
      return secondsToTimeObject(seconds - minutes * SECONDS_IN_ONE_MINUTE, "SECONDS", {
        ...timer,
        minutes,
      });
    }
    case "SECONDS": {
      return {
        ...timer,
        seconds: Math.floor(seconds),
      };
    }
  }
};
