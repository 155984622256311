import { proxy } from "valtio";
import { proxyMap } from "valtio/utils";

import type * as Types from "./types";

export const state = proxy<Types.State>({
  currentId: null,
  quizzes: proxyMap(),
  submittedEntityOfLastQuestion: null,
  submitStatus: "READY",
  ipAddress: null,
  geolocation: null,
});
