import Box, { BoxProps } from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Paper, { PaperProps } from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";

import AppBar from "./parts/AppBar/AppBar";
import NavigationBreadcrumbs, { NavigationBreadcrumbsProps } from "./parts/NavigationBreadcrumbs/NavigationBreadcrumbs";
import SideBarDrawer from "./parts/SideBarDrawer/SideBarDrawer";
import SideBarHeader, { SideBarHeaderProps } from "./parts/SideBarHeader/SideBarHeader";

/**
 * !!! CAUTION !!!
 * Some pages have hard-coded heights, so please check all pages that reference this layout if you wish to change it.
 */
const HEADER_HEIGHT = 48;
const FOOTER_HEIGHT = 24;
const NOTIFICATION_BANNER_HEIGHT = 48;

const OPEN_SIDEBAR_WIDTH = 248;
const CLOSED_SIDEBAR_WIDTH = 48;

const RootWrapper = styled(Box)(() => ({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
}));

const MainWrapper = styled(Box)(() => ({
  flexGrow: 1,
  position: "relative",
}));

const NotificationBannerWrapper = styled(Box)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 100,
  width: "100%",
  height: NOTIFICATION_BANNER_HEIGHT,
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  minHeight: `calc(100vh - ${HEADER_HEIGHT + FOOTER_HEIGHT}px)`,
  height: "100%",
}));

const StyledAppBar = styled(AppBar)(() => ({
  minHeight: HEADER_HEIGHT,
  height: HEADER_HEIGHT,
  borderTop: "none",
}));

const StyledToolbar = styled(Toolbar)(() => ({
  height: HEADER_HEIGHT,
  minHeight: HEADER_HEIGHT,
  "@media (min-width:600px)": { minHeight: `${HEADER_HEIGHT}px` },
}));

const FooterWrapper = styled(Box)(() => ({
  height: FOOTER_HEIGHT,
}));

const DrawerHeader = styled(Paper)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  flexShrink: 0,
  boxShadow: "none",
  borderBottom: `1px solid ${theme.palette.divider}`,
  borderRadius: "0",
  height: HEADER_HEIGHT,
  minHeight: HEADER_HEIGHT,
}));

export type EmployeeLayoutProps = {
  className?: string;
  NotificationBanner: React.ReactNode;
  openSidebar: boolean;
  onChangeOpenSidebar: (open: boolean) => void;
  loading?: boolean;
  navigationBreadcrumbs?: NavigationBreadcrumbsProps;
  HeaderRightContent: React.ReactNode;
  SideBarContent: React.ReactNode;
  Footer: React.ReactNode;
  Snackbar: React.ReactNode;
  sideBarHeader: Pick<SideBarHeaderProps, "logo">;
  /**
   * default fixed
   */
  headerPosition?: "fixed" | "static";
  contentWrapper?: {
    className?: string;
  };
};

/**
 * Header Height: 48px
 * Footer Height: 24px
 */
const EmployeeLayout: React.FC<React.PropsWithChildren<EmployeeLayoutProps>> = props => {
  const { openSidebar, headerPosition = "fixed" } = props;
  const sidebarWidth = openSidebar ? OPEN_SIDEBAR_WIDTH : CLOSED_SIDEBAR_WIDTH;

  const sideBarHeaderProps: SideBarHeaderProps = {
    ...props.sideBarHeader,
    open: openSidebar,
    height: HEADER_HEIGHT,
    width: sidebarWidth,
    closeButton: {
      onClick: () => {
        props.onChangeOpenSidebar(false);
      },
    },
    onOpen: () => {
      props.onChangeOpenSidebar(true);
    },
  };

  const topMargin = props.NotificationBanner ? `${NOTIFICATION_BANNER_HEIGHT}px` : `0px`;

  const drawerHeaderProps: PaperProps = {
    sx: openSidebar
      ? { display: "flex", justifyContent: "center" }
      : { display: "flex", justifyContent: "center", py: 2, px: 0.5, height: "unset" },
  };

  const mainWrapperProps: BoxProps = {
    width: `calc(100% - ${sidebarWidth}px)`,
    sx: { top: headerPosition === "fixed" ? topMargin : 0 },
  };

  return (
    <RootWrapper className={props.className}>
      {props.loading && <LinearProgress sx={{ width: "100%", position: "fixed", zIndex: 2000, top: 0 }} color="secondary" />}
      {props.NotificationBanner && (
        <NotificationBannerWrapper width="100%" position="fixed">
          {props.NotificationBanner}
        </NotificationBannerWrapper>
      )}
      <StyledAppBar sx={{ position: headerPosition === "static" ? "static" : "fixed", marginTop: topMargin }} open={openSidebar} elevation={2}>
        <StyledToolbar>
          <Box>{props.navigationBreadcrumbs && <NavigationBreadcrumbs {...props.navigationBreadcrumbs} />}</Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box>{props.HeaderRightContent}</Box>
        </StyledToolbar>
      </StyledAppBar>

      <Box sx={{ top: topMargin, display: "flex", width: "100%", flexDirection: "row" }}>
        <SideBarDrawer variant="permanent" open={openSidebar}>
          <Box sx={{ paddingTop: topMargin }}>
            <DrawerHeader {...drawerHeaderProps}>
              <SideBarHeader {...sideBarHeaderProps} />
            </DrawerHeader>
            <Box>{props.SideBarContent}</Box>
          </Box>
        </SideBarDrawer>

        <MainWrapper component="main" {...mainWrapperProps}>
          {headerPosition === "fixed" && <DrawerHeader />}
          <ContentWrapper {...props.contentWrapper}>{props.children}</ContentWrapper>
          <FooterWrapper>{props.Footer}</FooterWrapper>
        </MainWrapper>
      </Box>
      {props.Snackbar}
    </RootWrapper>
  );
};

EmployeeLayout.displayName = "EmployeeLayout";

export default EmployeeLayout;
