import { useTranslation } from "@hireroo/i18n";
import Markdown from "@hireroo/markdown/react";
import Done from "@mui/icons-material/Done";
import Box from "@mui/material/Box";
import { green } from "@mui/material/colors";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type EvaluationItem = {
  title: string;
};

export type ReadonlyQuizQuestionFreeTextProps = {
  id: string;
  description: string;
  items: EvaluationItem[];
};

const ReadonlyQuizQuestionFreeText: React.FC<ReadonlyQuizQuestionFreeTextProps> = props => {
  const { t } = useTranslation();
  return (
    <Box>
      <React.Suspense>
        <Markdown size="middle" children={props.description} />
      </React.Suspense>

      <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
        {t("評価基準")}
      </Typography>
      <Box mt={2} px={2} overflow="scroll">
        <List>
          {props.items.length === 0 && <Typography>{t("評価基準が存在しません。")}</Typography>}
          {props.items.map((item, index) => {
            return (
              <ListItem key={`option-${index}-${item.title}`}>
                <ListItemIcon>
                  <Done style={{ color: green[500] }} />
                </ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Box>
  );
};

ReadonlyQuizQuestionFreeText.displayName = "ReadonlyQuizQuestionFreeText";

export default ReadonlyQuizQuestionFreeText;
