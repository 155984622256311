import { useTranslation } from "@hireroo/i18n";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Rating, { RatingProps } from "@mui/material/Rating";
import { styled, useTheme } from "@mui/material/styles";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

import RemoveEvaluationDialog, { type RemoveEvaluationDialogProps } from "../RemoveEvaluationDialog/RemoveEvaluationDialog";

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  backgroundColor: theme.palette.grey["800"],
}));

export type EvaluationItemProps = {
  id: number;
  index: number;
  title: string;
  description?: string;
  commentField: Pick<TextFieldProps, "onChange" | "defaultValue">;
  ratingField: RatingProps;
  onRemoveEvaluation: () => void;
};

const EvaluationItem: React.FC<EvaluationItemProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = React.useState<boolean>(false);
  const [expanded, setExpanded] = React.useState<boolean>(true);

  const onClickExpand = React.useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const evaluationCommentFieldProps: TextFieldProps = {
    ...props.commentField,
    name: `evaluations.${props.index}.comment`,
    type: "text",
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
    required: true,
    multiline: true,
    minRows: 4,
    color: "secondary",
    placeholder: t("評価コメント（任意）"),
  };

  const ratingField: RatingProps = {
    ...props.ratingField,
    max: 4,
  };

  const removeEvaluationDialogProps: RemoveEvaluationDialogProps = {
    open,
    removeTarget: props.title,
    yesButton: {
      onClick: () => props.onRemoveEvaluation(),
    },
    noButton: {
      onClick: () => setOpen(false),
    },
  };

  const onClickDeleteButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setOpen(true);
  };

  return (
    <>
      <StyledAccordion
        expanded={expanded}
        TransitionProps={{ unmountOnExit: true }}
        defaultExpanded={true}
        sx={{ borderRadius: "8px", mt: props.index === 0 ? 0 : 2, mb: 2 }}
        square
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor={theme.palette["Gray/Shades"].p56} />} onClick={onClickExpand}>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" width="100%">
            <Box display="flex" alignItems="center" gap={1}>
              <Tooltip title={t("評価項目を削除する")}>
                <IconButton color="default" aria-label="reset-type" component="span" onClick={onClickDeleteButton} size="small">
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Typography variant="subtitle2">{props.title}</Typography>
            </Box>
            <Box display="flex" flexDirection="row">
              <Rating {...ratingField} />
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" gap={4}>
            <Typography variant="body2">{props.description}</Typography>
            <TextField {...evaluationCommentFieldProps} />
          </Box>
        </AccordionDetails>
      </StyledAccordion>

      <RemoveEvaluationDialog {...removeEvaluationDialogProps} />
    </>
  );
};

EvaluationItem.displayName = "EvaluationItem";

export default EvaluationItem;
