import { useSnapshot } from "valtio";

import { state } from "./State";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useTemplate = () => {
  const snapshot = useSnapshotState();
  return snapshot.template;
};
