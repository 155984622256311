import ScoreHistogram, { ScoreHistogramProps } from "@hireroo/charts/react/ScoreHistogram";
import { useTranslation } from "@hireroo/i18n";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

import StatisticsQueryToolbar, { StatisticsQueryToolbarProps } from "../../modules/StatisticsQueryToolbar/StatisticsQueryToolbar";

type GraphStatus = "NOT_ENOUGH" | "SATISFY" | "ERROR" | "LOADING" | "NOT_EVALUATED";

export type RankVisualizerProps = {
  status: GraphStatus;
  queryToolbar: StatisticsQueryToolbarProps;
  scoreHistogram: ScoreHistogramProps;
};

const RankVisualizer: React.FC<RankVisualizerProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const scoreGraph: ScoreHistogramProps = {
    ...props.scoreHistogram,
  };

  const ContentMap: Record<GraphStatus, React.ReactNode> = {
    NOT_EVALUATED: (
      <Box width={"100%"} mt={2}>
        <Typography>{t("全ての提出の採点が完了すると相対評価が表示されます。")}</Typography>
      </Box>
    ),
    ERROR: (
      <Box width={"100%"} mt={2}>
        <Typography>{t("データの取得に失敗しました。しばらくしてから再度お試しいただくか、運営までお問合せください。")}</Typography>
      </Box>
    ),
    NOT_ENOUGH: (
      <Box width={"100%"} mt={2}>
        <Typography>{t("データが足りません。提出の数が一定数を超えると相対評価が可能になります。")}</Typography>
      </Box>
    ),
    SATISFY: (
      <Box height={350} width={"100%"} mt={6}>
        <React.Suspense>
          <ScoreHistogram {...scoreGraph} />
        </React.Suspense>
      </Box>
    ),
    LOADING: (
      <Box height={350} width={"100%"} mt={6} display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <CircularProgress color="secondary" />
      </Box>
    ),
  };
  return (
    <Box>
      <Stack direction="row" mb={2} alignItems="center">
        <Typography variant="subtitle2" fontSize={14} fontWeight={500} color="textSecondary">
          {t("相対評価")}
        </Typography>
        <Tooltip title={t("相対評価は任意の母集団における候補者の相対位置を示したものです。")} placement="top">
          <InfoOutlinedIcon sx={{ ml: 0.5, fontSize: "0.8rem", color: theme.palette.text.secondary }} />
        </Tooltip>
      </Stack>
      <StatisticsQueryToolbar {...props.queryToolbar} />
      {ContentMap[props.status]}
    </Box>
  );
};

RankVisualizer.displayName = "RankVisualizer";

export default RankVisualizer;
