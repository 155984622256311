import { useDraggable } from "@dnd-kit/core";
import { useTranslation } from "@hireroo/i18n";
import CloseIcon from "@mui/icons-material/Close";
import MaximizeOutlinedIcon from "@mui/icons-material/MaximizeOutlined";
import MinimizeOutlinedIcon from "@mui/icons-material/MinimizeOutlined";
import Box, { BoxProps } from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";
import DragIndicator from "../../primitive/DragIndicator/DragIndicator";
import { useDraggablePromptContext } from "./Context";

export { DraggablePromptProvider, type DraggablePromptProviderProps, useDraggablePromptContext } from "./Context";

const StyledStack = styled(Stack)`
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

type ScreenStatus = "MINIMIZED" | "DEFAULT";

const ScreenIconMap = {
  DEFAULT: <MinimizeOutlinedIcon />,
  //TODO: change icon
  MINIMIZED: <MaximizeOutlinedIcon />,
} satisfies Record<ScreenStatus, React.ReactElement>;

export type DraggablePromptProps = {
  title: string;
  style?: BoxProps["style"];
  onClose: IconButtonWithTooltipProps["onClick"];
};

const DraggablePrompt: React.FC<React.PropsWithChildren<DraggablePromptProps>> = props => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [screenStatus, setScreenStatus] = React.useState<ScreenStatus>("DEFAULT");
  const { listeners, setNodeRef, transform, isDragging } = useDraggable({ id: "draggable-prompt" });
  const { coordinatesRef } = useDraggablePromptContext();

  const style = React.useMemo((): React.CSSProperties => {
    return {
      ...props.style,
      top: coordinatesRef.current.y,
      left: coordinatesRef.current.x,
      cursor: isDragging ? "grabbing" : "inherit",
      transform: `translate3d(${transform?.x ?? 0}px, ${transform?.y ?? 0}px, 0)`,
    };
  }, [coordinatesRef, isDragging, props.style, transform?.x, transform?.y]);

  const baseBox: BoxProps = {
    position: "fixed",
    borderRadius: "20px",
    bgcolor: theme.palette["Background/Paper"].p24,
    sx: {
      elevation: 24,
    },
    style: style,
    width: "560px",
    minWidth: "560px",
    px: 2,
    zIndex: theme.zIndex.modal,
  };

  return (
    <Box {...baseBox} ref={setNodeRef}>
      <StyledStack direction="row" borderBottom="1px solid" borderColor={theme.palette.divider} {...listeners}>
        <Box>
          <Typography>{props.title}</Typography>
        </Box>
        <Box>
          <DragIndicator color="outline-only" />
        </Box>
        <Box display="flex">
          <IconButtonWithTooltip
            onClick={event => {
              event.stopPropagation();
              setScreenStatus(draft => (draft === "DEFAULT" ? "MINIMIZED" : "DEFAULT"));
            }}
          >
            {ScreenIconMap[screenStatus]}
          </IconButtonWithTooltip>
          <IconButtonWithTooltip onClick={props.onClose} title={t("閉じる")}>
            <CloseIcon />
          </IconButtonWithTooltip>
        </Box>
      </StyledStack>
      {screenStatus === "DEFAULT" && <Box width="100%">{props.children}</Box>}
    </Box>
  );
};

DraggablePrompt.displayName = "DraggablePrompt";

export default DraggablePrompt;
