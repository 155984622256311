import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useTheme } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import ListItem from "@mui/material/ListItem";
import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectProps } from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Controller } from "react-hook-form";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&.MuiMenuItem-root": {
    "&:hover ": {
      backgroundColor: theme.palette["Secondary/Shades"].p8,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette["Secondary/Shades"].p16,
    },
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.Other.FilledInputBG,
  ".MuiSelect-outlined": {
    border: "none",
  },
}));

const StyledInputLabel = styled(InputLabel)(() => ({
  fontSize: "14px",
  "&[data-shrink='false']": {
    top: "-5px",
  },
}));

type Option = {
  displayName: string;
  label: string;
  uniqueValue: string;
  valueType: "STRING" | "INTEGER" | "BOOLEAN";
};

const SELECT_WIDTH = 250;

export type SelectWithSearchProps = {
  name: string;
  options: Option[];
  helperText?: string;
  label?: string;
  placeholder?: string;
  onSelect?: (option: Option) => void;
  emptyText?: string;
};

const SelectWithSearch: React.FC<SelectWithSearchProps> = props => {
  const theme = useTheme();
  const [searchText, setSearchText] = React.useState<string>("");

  const selectProps: SelectProps = {
    MenuProps: {
      autoFocus: false,
      disableScrollLock: true,
      sx: { minWidth: `${SELECT_WIDTH}px`, maxHeight: "350px" },
    },
    labelId: "select-with-search-label",
    color: "secondary",
    label: props.label,
    size: "small",
    onClose: () => {
      setSearchText("");
    },
    sx: {
      width: `${SELECT_WIDTH}px`,
    },
  };
  const textFieldProps: TextFieldProps = {
    value: searchText,
    color: "secondary",
    autoFocus: false,
    fullWidth: true,
    variant: "outlined",
    size: "small",
    placeholder: props.placeholder,
    onKeyDown: event => {
      event.stopPropagation();
    },
    sx: {
      borderRadius: "4px",
      backgroundColor: theme.palette["Other"]["FilledInputBG"],
      "& fieldset": { border: "none" },
    },
    InputProps: {
      sx: { fontSize: "14px" },
      startAdornment: (
        <InputAdornment position="start">
          <SearchOutlinedIcon fontSize="small" color="disabled" />
        </InputAdornment>
      ),
    },
    onChange: event => {
      setSearchText(event.target.value);
    },
  };
  const filteredOptions = React.useMemo(() => {
    return props.options.filter(option => {
      return option.displayName.includes(searchText);
    });
  }, [props.options, searchText]);

  return (
    <Controller
      render={({ field, fieldState }) => (
        <FormControl error={Boolean(fieldState.error?.message)}>
          {props.label && (
            <StyledInputLabel variant="outlined" color="secondary">
              {props.label}
            </StyledInputLabel>
          )}
          <Select
            {...selectProps}
            {...field}
            onChange={event => {
              field.onChange(event);
              const selectedOption = props.options.find(option => option.uniqueValue === event.target.value);
              if (selectedOption) {
                props.onSelect?.(selectedOption);
              }
            }}
          >
            <ListSubheader sx={{ backgroundColor: "unset" }}>
              <StyledTextField {...textFieldProps} />
            </ListSubheader>
            {filteredOptions.map(option => (
              <StyledMenuItem key={option.uniqueValue} value={option.uniqueValue}>
                <Tooltip title={option.displayName}>
                  <span>{option.displayName}</span>
                </Tooltip>
              </StyledMenuItem>
            ))}
            {filteredOptions.length === 0 && (
              <ListItem>
                <Typography fontSize={14} color="textSecondary">
                  {props.emptyText}
                </Typography>
              </ListItem>
            )}
          </Select>
          {(props.helperText || fieldState?.error) && (
            <FormHelperText error={Boolean(fieldState.error?.message)}>
              {fieldState.error?.message ? fieldState.error.message : props.helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
      name={props.name}
    />
  );
};

SelectWithSearch.displayName = "SelectWithSearch";

export default SelectWithSearch;
