/* eslint-disable */
/** ====  THIS FILE IS GENERATED AUTOMATICALLY   ==== */
import { GraphQLClient, RequestOptions } from "graphql-request";
import gql from "graphql-tag";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
type GraphQLClientRequestHeaders = RequestOptions["requestHeaders"];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export const AcceptScreeningErrorCode = {
  EmailAndCodeCombinationError: "EMAIL_AND_CODE_COMBINATION_ERROR",
  EmailIsAlreadyInUse: "EMAIL_IS_ALREADY_IN_USE",
} as const;

export type AcceptScreeningErrorCode = (typeof AcceptScreeningErrorCode)[keyof typeof AcceptScreeningErrorCode];
export type AcceptScreeningErrorResponse = {
  __typename?: "AcceptScreeningErrorResponse";
  errorCode: AcceptScreeningErrorCode;
};

export type AcceptScreeningInput = {
  candidateEmail: Scalars["String"]["input"];
  candidateName: Scalars["String"]["input"];
  filledVariables: Array<ScreeningFilledVariableInput>;
  invitationCode?: InputMaybe<Scalars["String"]["input"]>;
  screeningId: Scalars["String"]["input"];
  selectedQuestions: Array<ScreeningSelectedQuestionByTrack>;
};

export type AcceptScreeningResponse = AcceptScreeningErrorResponse | AcceptScreeningSuccessResponse;

export type AcceptScreeningSuccessResponse = {
  __typename?: "AcceptScreeningSuccessResponse";
  spot: Spot;
};

export type AcceptTalentInvitationInput = {
  invitationId: Scalars["String"]["input"];
  talentId: Scalars["String"]["input"];
};

export type AddEvaluationMetricToGroupsInput = {
  metricGroupIds: Array<Scalars["Int"]["input"]>;
  metricId: Scalars["Int"]["input"];
};

export type AddEvaluationMetricsToGroupInput = {
  metricGroupId: Scalars["Int"]["input"];
  metricIds: Array<Scalars["Int"]["input"]>;
};

export type AddEvaluationMetricsToGroupsInput = {
  metricGroupIds: Array<Scalars["Int"]["input"]>;
  metricIds: Array<Scalars["Int"]["input"]>;
};

export type AddLiveCodingSessionInput = {
  liveCodingId: Scalars["Int"]["input"];
  questionId: Scalars["Int"]["input"];
  questionType: LiveCodingQuestionType;
  questionVariant: LiveCodingQuestionVariant;
  questionVersion: Scalars["String"]["input"];
};

export type AddLiveCodingVideoChatConversationInput = {
  liveCodingId: Scalars["Int"]["input"];
};

export type AddLiveCodingVideoChatRoomInput = {
  liveCodingId: Scalars["Int"]["input"];
};

export type AlgorithmAnswer = {
  __typename?: "AlgorithmAnswer";
  answerRuntimes: Array<AlgorithmAnswerRuntime>;
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  descriptionEn: Maybe<Scalars["String"]["output"]>;
  descriptionJa: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  label: Scalars["String"]["output"];
  questionId: Scalars["Int"]["output"];
  questionVersion: Scalars["String"]["output"];
  runtimeComplexity: Scalars["String"]["output"];
  spaceComplexity: Scalars["String"]["output"];
  titleEn: Maybe<Scalars["String"]["output"]>;
  titleJa: Maybe<Scalars["String"]["output"]>;
  uniqueId: Scalars["String"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type AlgorithmAnswerRuntime = {
  __typename?: "AlgorithmAnswerRuntime";
  answerId: Scalars["Int"]["output"];
  codeBody: Scalars["String"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["Int"]["output"];
  machineCpu: Scalars["Int"]["output"];
  machineMemory: Scalars["String"]["output"];
  machineOs: Scalars["String"]["output"];
  readability: Scalars["Int"]["output"];
  runtime: Scalars["String"]["output"];
  stats: Scalars["String"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export const AlgorithmCompressionFormat = {
  Gzip: "GZIP",
} as const;

export type AlgorithmCompressionFormat = (typeof AlgorithmCompressionFormat)[keyof typeof AlgorithmCompressionFormat];
export type AlgorithmCorrectnessTestCase = {
  __typename?: "AlgorithmCorrectnessTestCase";
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  descriptionEn: Scalars["String"]["output"];
  descriptionJa: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  inputs: Array<Scalars["String"]["output"]>;
  isHidden: Scalars["Boolean"]["output"];
  outputs: Array<Scalars["String"]["output"]>;
  questionId: Scalars["Int"]["output"];
  questionVersion: Scalars["String"]["output"];
  testCaseId: Scalars["Int"]["output"];
  titleEn: Scalars["String"]["output"];
  titleJa: Scalars["String"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type AlgorithmHint = {
  __typename?: "AlgorithmHint";
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  descriptionEn: Maybe<Scalars["String"]["output"]>;
  descriptionJa: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  orderNum: Scalars["Int"]["output"];
  questionId: Scalars["Int"]["output"];
  questionVersion: Scalars["String"]["output"];
  uniqueId: Scalars["String"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type AlgorithmInitialCode = {
  __typename?: "AlgorithmInitialCode";
  c: Scalars["String"]["output"];
  cpp: Scalars["String"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  csharp: Scalars["String"]["output"];
  dart: Scalars["String"]["output"];
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  go: Scalars["String"]["output"];
  java: Scalars["String"]["output"];
  javascript: Scalars["String"]["output"];
  kotlin: Scalars["String"]["output"];
  mysql: Scalars["String"]["output"];
  perl: Scalars["String"]["output"];
  pgsql: Scalars["String"]["output"];
  php: Scalars["String"]["output"];
  python3: Scalars["String"]["output"];
  ruby: Scalars["String"]["output"];
  rust: Scalars["String"]["output"];
  scala: Scalars["String"]["output"];
  sqlite: Scalars["String"]["output"];
  swift: Scalars["String"]["output"];
  typescript: Scalars["String"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type AlgorithmPerformanceTestCase = {
  __typename?: "AlgorithmPerformanceTestCase";
  compressedInputs: Scalars["String"]["output"];
  compressedOutputs: Scalars["String"]["output"];
  compressionFormat: AlgorithmCompressionFormat;
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  isHidden: Scalars["Boolean"]["output"];
  label: Scalars["String"]["output"];
  orderNumber: Scalars["Int"]["output"];
  questionId: Scalars["Int"]["output"];
  questionVersion: Scalars["String"]["output"];
  testCaseId: Scalars["Int"]["output"];
  truncatedInputs: Array<Scalars["String"]["output"]>;
  truncatedOutputs: Array<Scalars["String"]["output"]>;
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type AlgorithmProfileCodeResponse = {
  __typename?: "AlgorithmProfileCodeResponse";
  isAccepted: Scalars["Boolean"]["output"];
  log: Scalars["String"]["output"];
  machineCpu: Scalars["Int"]["output"];
  machineMemory: Scalars["String"]["output"];
  machineOs: Scalars["String"]["output"];
  maxMemoryAvg: Scalars["Float"]["output"];
  maxMemoryMed: Scalars["Float"]["output"];
  maxMemoryStd: Scalars["Float"]["output"];
  output: Scalars["String"]["output"];
  performanceAvg: Scalars["Int"]["output"];
  performanceAvgString: Scalars["String"]["output"];
  performanceMed: Scalars["Int"]["output"];
  performanceMedString: Scalars["String"]["output"];
  performanceStd: Scalars["Int"]["output"];
  performanceStdString: Scalars["String"]["output"];
  status: Scalars["String"]["output"];
};

export type AlgorithmQuestion = Node & {
  __typename?: "AlgorithmQuestion";
  accuracyRate: Scalars["Float"]["output"];
  answers: Array<AlgorithmAnswer>;
  averageElapsedTimeSeconds: Scalars["Float"]["output"];
  companyId: Scalars["Int"]["output"];
  /** @deprecated Use correctness_test_case_ids instead */
  correctnessTestCase: Scalars["String"]["output"];
  correctnessTestCaseIds: Array<Scalars["Int"]["output"]>;
  correctnessTestCases: Array<AlgorithmCorrectnessTestCase>;
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  descriptionEn: Maybe<Scalars["String"]["output"]>;
  descriptionJa: Maybe<Scalars["String"]["output"]>;
  difficulty: Difficulty;
  employee: User;
  employeeId: Scalars["String"]["output"];
  hints: Array<AlgorithmHint>;
  id: Scalars["ID"]["output"];
  initialCode: Maybe<AlgorithmInitialCode>;
  isOfficial: Scalars["Boolean"]["output"];
  isPrivate: Scalars["Boolean"]["output"];
  isSupportedLeakScore: Scalars["Boolean"]["output"];
  key: Scalars["String"]["output"];
  leakScore: Maybe<LeakScore>;
  numUses: Scalars["Int"]["output"];
  /** @deprecated Use performance_test_case_ids instead */
  performanceTestCase: Scalars["String"]["output"];
  performanceTestCaseIds: Array<Scalars["Int"]["output"]>;
  performanceTestCases: Array<AlgorithmPerformanceTestCase>;
  performanceTimeLimitMilliSeconds: Scalars["Int"]["output"];
  questionId: Scalars["Int"]["output"];
  scoreDistributionBins: Array<Scalars["Int"]["output"]>;
  signature: Scalars["String"]["output"];
  slug: Scalars["String"]["output"];
  status: QuestionStatus;
  supportedLanguages: Array<Scalars["String"]["output"]>;
  tags: Array<AlgorithmTag>;
  timeLimitSeconds: Maybe<Scalars["Int"]["output"]>;
  titleEn: Maybe<Scalars["String"]["output"]>;
  titleJa: Maybe<Scalars["String"]["output"]>;
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  variant: AlgorithmQuestionVariant;
  version: Scalars["String"]["output"];
};

export const AlgorithmQuestionLeakScoreLevel = {
  High: "HIGH",
  Low: "LOW",
  Medium: "MEDIUM",
} as const;

export type AlgorithmQuestionLeakScoreLevel = (typeof AlgorithmQuestionLeakScoreLevel)[keyof typeof AlgorithmQuestionLeakScoreLevel];
export const AlgorithmQuestionVariant = {
  Algorithm: "ALGORITHM",
  Class: "CLASS",
  Database: "DATABASE",
  Unknown: "UNKNOWN",
} as const;

export type AlgorithmQuestionVariant = (typeof AlgorithmQuestionVariant)[keyof typeof AlgorithmQuestionVariant];
export type AlgorithmRunCodeResponse = {
  __typename?: "AlgorithmRunCodeResponse";
  expected: Scalars["String"]["output"];
  isAccepted: Scalars["Boolean"]["output"];
  log: Scalars["String"]["output"];
  maxMemory: Scalars["Float"]["output"];
  output: Scalars["String"]["output"];
  performance: Scalars["Int"]["output"];
  status: Scalars["String"]["output"];
};

export type AlgorithmTag = {
  __typename?: "AlgorithmTag";
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  name: Scalars["String"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type AlgorithmTemplateCodeResponse = {
  __typename?: "AlgorithmTemplateCodeResponse";
  c: Scalars["String"]["output"];
  cpp: Scalars["String"]["output"];
  csharp: Scalars["String"]["output"];
  dart: Scalars["String"]["output"];
  go: Scalars["String"]["output"];
  java: Scalars["String"]["output"];
  javascript: Scalars["String"]["output"];
  kotlin: Scalars["String"]["output"];
  mysql: Scalars["String"]["output"];
  perl: Scalars["String"]["output"];
  pgsql: Scalars["String"]["output"];
  php: Scalars["String"]["output"];
  python3: Scalars["String"]["output"];
  ruby: Scalars["String"]["output"];
  rust: Scalars["String"]["output"];
  scala: Scalars["String"]["output"];
  sqlite: Scalars["String"]["output"];
  swift: Scalars["String"]["output"];
  typescript: Scalars["String"]["output"];
};

export type AnalyzeRemoteInterviewTemplateInput = {
  candidateName: Scalars["String"]["input"];
  isPublic: Scalars["Boolean"]["input"];
  memo?: InputMaybe<Scalars["String"]["input"]>;
  questions: Array<RemoteEntitySourceInput>;
  remoteName: Scalars["String"]["input"];
  reviewerIds: Array<RemoteInterviewReviewerInput>;
  tagNames?: InputMaybe<Array<Scalars["String"]["input"]>>;
  variables: Array<TemplateVariableInput>;
};

export type AnalyzeTemplateInput = {
  deadlineSeconds: Scalars["Int"]["input"];
  invitationEmail?: InputMaybe<Scalars["String"]["input"]>;
  invitationLanguage?: InputMaybe<Language>;
  invitationMethod: SpotInvitationMethod;
  isPublic: Scalars["Boolean"]["input"];
  memo?: InputMaybe<Scalars["String"]["input"]>;
  messageForCandidate?: InputMaybe<Scalars["String"]["input"]>;
  questions: Array<SpotEntitySourceInput>;
  reviewerIds: Array<SpotReviewerInput>;
  spotName: Scalars["String"]["input"];
  tagNames?: InputMaybe<Array<Scalars["String"]["input"]>>;
  timeLimitSeconds: Scalars["Int"]["input"];
  variables: Array<TemplateVariableInput>;
};

export type ApplyCouponInput = {
  couponId: Scalars["Int"]["input"];
  subscriptionId: Scalars["Int"]["input"];
};

export type AskChatGptInput = {
  /** When chatId is not given, it is a new conversation with ChatGPT */
  chatId?: InputMaybe<Scalars["String"]["input"]>;
  /** TODO: @ksrnnb クライアントがリクエストを送るようになったら必須にする */
  gptModel?: InputMaybe<ChatGptModel>;
  prompt: Scalars["String"]["input"];
  sessionId: Scalars["String"]["input"];
};

export type AskChatGptResponse = {
  __typename?: "AskChatGPTResponse";
  chatId: Scalars["String"]["output"];
  messages: Array<ChatGptMessage>;
  sessionId: Scalars["String"]["output"];
};

export type Assessment = Node & {
  __typename?: "Assessment";
  activatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  assessmentId: Scalars["String"]["output"];
  company: Maybe<Company>;
  companyId: Scalars["Int"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  creatorId: Scalars["String"]["output"];
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  employee: Maybe<User>;
  entitySourceQuestions: Array<AssessmentEntitySourceQuestion>;
  /** @deprecated Change to bff_internal. Use `entitySourceQuestions` instead. */
  entitySources: Array<AssessmentEntitySource>;
  examInterval: AssessmentExamInterval;
  /**
   * When retrieving an exam with a list, MicroService will only include the exam.id in the response.
   * Therefore, if you want fields other than id in the list, you need to set withEntity to true.
   */
  exams: Array<Exam>;
  id: Scalars["ID"]["output"];
  isPublic: Scalars["Boolean"]["output"];
  isSubmittedAtLatestInterval: Scalars["Boolean"]["output"];
  latestBestExam: Maybe<Exam>;
  memo: Maybe<Scalars["String"]["output"]>;
  messageForTalent: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  nextScheduleAtSeconds: Maybe<Scalars["Int"]["output"]>;
  previousBestExam: Maybe<Exam>;
  questions: Array<SpotQuestion>;
  remindBeforeSeconds: Maybe<Scalars["Int"]["output"]>;
  reportSettings: Maybe<AssessmentReportSettings>;
  rootExamIds: Array<Scalars["String"]["output"]>;
  scoreTrend: AssessmentScoreTrend;
  startScheduleAtSeconds: Maybe<Scalars["Int"]["output"]>;
  status: AssessmentStatus;
  talent: User;
  talentId: Scalars["String"]["output"];
  timeLimitSeconds: Maybe<Scalars["Int"]["output"]>;
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  viewerSources: Array<AssessmentViewerSource>;
  viewers: Array<AssessmentViewer>;
};

export type AssessmentEntitySourceQuestionsArgs = {
  withAnswers: Scalars["Boolean"]["input"];
};

export type AssessmentExamsArgs = {
  withEntity: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AssessmentQuestionsArgs = {
  withAnswers: Scalars["Boolean"]["input"];
};

export type AssessmentChallengeEntitySourceQuestion = {
  __typename?: "AssessmentChallengeEntitySourceQuestion";
  enabledLanguages: Array<Scalars["String"]["output"]>;
  question: AlgorithmQuestion;
  questionId: Scalars["Int"]["output"];
  /** This field may contain the string "latest"; do not use it for Map Keys, etc. */
  questionVersion: Scalars["String"]["output"];
};

export type AssessmentChallengeQuestion = {
  __typename?: "AssessmentChallengeQuestion";
  enabledLanguages: Array<Scalars["String"]["output"]>;
  entity: AlgorithmQuestion;
  questionId: Scalars["Int"]["output"];
  questionVersion: Scalars["String"]["output"];
};

export type AssessmentChallengeQuestionInput = {
  enabledLanguages: Array<Scalars["String"]["input"]>;
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
};

export type AssessmentEntity = ChallengeEntity | ProjectEntity | QuizEntity | SystemDesignEntity;

export type AssessmentEntityId = {
  __typename?: "AssessmentEntityId";
  entityId: Scalars["Int"]["output"];
  entityType: AssessmentEntityType;
};

/** @deprecated This will be the Schema to be used inside the BFF. Do not refer to it. */
export type AssessmentEntitySource = Node & {
  __typename?: "AssessmentEntitySource";
  challengeQuestion: Maybe<AssessmentChallengeQuestion>;
  entityType: AssessmentEntityType;
  id: Scalars["ID"]["output"];
  projectQuestion: Maybe<AssessmentProjectQuestion>;
  /** @deprecated Field no longer supported */
  questionId: Scalars["Int"]["output"];
  quizPackage: Maybe<AssessmentQuizPackage>;
  systemDesignQuestion: Maybe<AssessmentSystemDesignQuestion>;
};

export type AssessmentEntitySourceInput = {
  challengeQuestion?: InputMaybe<AssessmentChallengeQuestionInput>;
  entityType: AssessmentEntityType;
  projectQuestion?: InputMaybe<AssessmentProjectQuestionInput>;
  questionId: Scalars["Int"]["input"];
  quizPackage?: InputMaybe<AssessmentQuizPackageInput>;
  systemDesignQuestion?: InputMaybe<AssessmentSystemDesignQuestionInput>;
};

export type AssessmentEntitySourceQuestion =
  | AssessmentChallengeEntitySourceQuestion
  | AssessmentProjectEntitySourceQuestion
  | AssessmentQuizEntitySourcePackage
  | AssessmentSystemDesignEntitySourceQuestion;

export const AssessmentEntityType = {
  Challenge: "CHALLENGE",
  Project: "PROJECT",
  Quiz: "QUIZ",
  SystemDesign: "SYSTEM_DESIGN",
  Unknown: "UNKNOWN",
} as const;

export type AssessmentEntityType = (typeof AssessmentEntityType)[keyof typeof AssessmentEntityType];
export const AssessmentExamInterval = {
  OneMonth: "ONE_MONTH",
  OneWeek: "ONE_WEEK",
  ThreeMonths: "THREE_MONTHS",
  TwoMonths: "TWO_MONTHS",
  TwoWeeks: "TWO_WEEKS",
  Unknown: "UNKNOWN",
} as const;

export type AssessmentExamInterval = (typeof AssessmentExamInterval)[keyof typeof AssessmentExamInterval];
export type AssessmentFilters = {
  isSubmitted?: InputMaybe<Scalars["Boolean"]["input"]>;
  name: Scalars["String"]["input"];
  scoreTrend: AssessmentScoreTrend;
  statuses: Array<AssessmentStatus>;
  talentIds: Array<Scalars["String"]["input"]>;
  viewerIds: Array<AssessmentViewerIdInput>;
};

export type AssessmentListOptionsByFieldsResponse = {
  __typename?: "AssessmentListOptionsByFieldsResponse";
  talentIds: Array<Scalars["String"]["output"]>;
  talents: Array<User>;
  viewerSources: Array<AssessmentViewerSource>;
  viewers: Array<AssessmentViewer>;
};

export type AssessmentListOptionsByFieldsResponseViewersArgs = {
  companyId: Scalars["Int"]["input"];
};

export type AssessmentOptionalRemindBeforeInput = {
  remindBeforeSeconds?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AssessmentProjectEntitySourceQuestion = {
  __typename?: "AssessmentProjectEntitySourceQuestion";
  question: ProjectQuestion;
  questionId: Scalars["Int"]["output"];
  /** This field may contain the string "latest"; do not use it for Map Keys, etc. */
  questionVersion: Scalars["String"]["output"];
};

export type AssessmentProjectQuestion = {
  __typename?: "AssessmentProjectQuestion";
  entity: ProjectQuestion;
  questionId: Scalars["Int"]["output"];
  questionVersion: Scalars["String"]["output"];
};

export type AssessmentProjectQuestionInput = {
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
};

export type AssessmentQuizEntitySourcePackage = {
  __typename?: "AssessmentQuizEntitySourcePackage";
  packageId: Scalars["Int"]["output"];
  /** This field may contain the string "latest"; do not use it for Map Keys, etc. */
  packageVersion: Scalars["String"]["output"];
  pb_package: MultiChoicePackage;
};

export type AssessmentQuizPackage = {
  __typename?: "AssessmentQuizPackage";
  entity: MultiChoicePackage;
  packageId: Scalars["Int"]["output"];
  packageVersion: Scalars["String"]["output"];
};

export type AssessmentQuizPackageInput = {
  packageId: Scalars["Int"]["input"];
  packageVersion: Scalars["String"]["input"];
};

export type AssessmentReportSettings = {
  __typename?: "AssessmentReportSettings";
  showAnswer: Scalars["Boolean"]["output"];
  showPlayback: Scalars["Boolean"]["output"];
  showRelativeEvaluation: Scalars["Boolean"]["output"];
};

export type AssessmentReportSettingsInput = {
  showAnswer: Scalars["Boolean"]["input"];
  showPlayback: Scalars["Boolean"]["input"];
  showRelativeEvaluation: Scalars["Boolean"]["input"];
};

export const AssessmentScoreTrend = {
  Down: "DOWN",
  Flat: "FLAT",
  Unknown: "UNKNOWN",
  Up: "UP",
} as const;

export type AssessmentScoreTrend = (typeof AssessmentScoreTrend)[keyof typeof AssessmentScoreTrend];
export const AssessmentSortMethod = {
  CreatedAt: "CREATED_AT",
  RelativeScore: "RELATIVE_SCORE",
  Unknown: "UNKNOWN",
} as const;

export type AssessmentSortMethod = (typeof AssessmentSortMethod)[keyof typeof AssessmentSortMethod];
export const AssessmentStatus = {
  Archived: "ARCHIVED",
  Running: "RUNNING",
  Suspended: "SUSPENDED",
  Unknown: "UNKNOWN",
} as const;

export type AssessmentStatus = (typeof AssessmentStatus)[keyof typeof AssessmentStatus];
export type AssessmentSystemDesignEntitySourceQuestion = {
  __typename?: "AssessmentSystemDesignEntitySourceQuestion";
  componentTypes: Array<SystemDesignComponentType>;
  question: SystemDesignQuestion;
  questionId: Scalars["Int"]["output"];
};

export type AssessmentSystemDesignQuestion = {
  __typename?: "AssessmentSystemDesignQuestion";
  componentTypes: Array<SystemDesignComponentType>;
  entity: SystemDesignQuestion;
  questionId: Scalars["Int"]["output"];
};

export type AssessmentSystemDesignQuestionInput = {
  componentTypes: Array<SystemDesignComponentType>;
  questionId: Scalars["Int"]["input"];
};

export type AssessmentViewer = EmployeeGroup | User;

export type AssessmentViewerIdInput = {
  employeeId?: InputMaybe<Scalars["String"]["input"]>;
  groupId?: InputMaybe<Scalars["String"]["input"]>;
  type: AssessmentViewerType;
};

export type AssessmentViewerSource = {
  __typename?: "AssessmentViewerSource";
  viewerId: Scalars["String"]["output"];
  viewerType: AssessmentViewerType;
};

export const AssessmentViewerType = {
  Employee: "EMPLOYEE",
  Group: "GROUP",
  Unknown: "UNKNOWN",
} as const;

export type AssessmentViewerType = (typeof AssessmentViewerType)[keyof typeof AssessmentViewerType];
export type AssignedSkillTagIdsResponse = {
  __typename?: "AssignedSkillTagIdsResponse";
  skillTagIds: Array<Scalars["String"]["output"]>;
};

export type AtsPager = {
  __typename?: "AtsPager";
  cursor: Scalars["Int"]["output"];
  hasNext: Scalars["Boolean"]["output"];
};

export const AuthPlanType = {
  BasicV1: "BASIC_V1",
  BasicV2: "BASIC_V2",
  BasicV3: "BASIC_V3",
  BasicV4: "BASIC_V4",
  BasicV5: "BASIC_V5",
  BasicV6: "BASIC_V6",
  BasicV7: "BASIC_V7",
  BasicV8: "BASIC_V8",
  EnterpriseV1: "ENTERPRISE_V1",
  EnterpriseV2: "ENTERPRISE_V2",
  EnterpriseV3: "ENTERPRISE_V3",
  EnterpriseV4: "ENTERPRISE_V4",
  EnterpriseV5: "ENTERPRISE_V5",
  EnterpriseV6: "ENTERPRISE_V6",
  EnterpriseV7: "ENTERPRISE_V7",
  EnterpriseV8: "ENTERPRISE_V8",
  ProfessionalV1: "PROFESSIONAL_V1",
  ProfessionalV2: "PROFESSIONAL_V2",
  ProfessionalV3: "PROFESSIONAL_V3",
  ProfessionalV4: "PROFESSIONAL_V4",
  ProfessionalV5: "PROFESSIONAL_V5",
  ProfessionalV6: "PROFESSIONAL_V6",
  StandardV1: "STANDARD_V1",
  StandardV2: "STANDARD_V2",
  StandardV3: "STANDARD_V3",
  StandardV4: "STANDARD_V4",
  StandardV5: "STANDARD_V5",
  StandardV6: "STANDARD_V6",
  StandardV7: "STANDARD_V7",
  StandardV8: "STANDARD_V8",
  Unknown: "UNKNOWN",
} as const;

export type AuthPlanType = (typeof AuthPlanType)[keyof typeof AuthPlanType];
export const AuthProvider = {
  Github: "GITHUB",
  Google: "GOOGLE",
  Password: "PASSWORD",
} as const;

export type AuthProvider = (typeof AuthProvider)[keyof typeof AuthProvider];
export type AuthorizeSlackInput = {
  authorizeCode: Scalars["String"]["input"];
  companyId: Scalars["Int"]["input"];
  redirectUri: Scalars["String"]["input"];
};

export type BehavioralEvent =
  | ChatGptConversationResetEvent
  | ChatGptRequestedEvent
  | ChatGptRespondedEvent
  | WebAccessedEvent
  | WebSearchedEvent;

export type BehavioralEventLogs = {
  __typename?: "BehavioralEventLogs";
  events: Array<BehavioralEvent>;
};

export type BehavioralEventLogsInput = {
  chatSessionId?: InputMaybe<Scalars["String"]["input"]>;
  webSessionId?: InputMaybe<Scalars["String"]["input"]>;
};

export type BuyAblePacksResponse = {
  __typename?: "BuyAblePacksResponse";
  interviews: Array<PaymentInterview>;
};

export type ChallengeChatGptSession = {
  __typename?: "ChallengeChatGPTSession";
  challengeId: Scalars["Int"]["output"];
  currentChatGPTMessages: Array<ChatGptMessage>;
  currentChatId: Maybe<Scalars["String"]["output"]>;
  language: Scalars["String"]["output"];
  sessionId: Scalars["String"]["output"];
};

export type ChallengeEntity = Node & {
  __typename?: "ChallengeEntity";
  appealMessage: Maybe<Scalars["String"]["output"]>;
  candidateId: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Field no longer supported */
  challengeEntityId: Scalars["Int"]["output"];
  challengeQuestion: Maybe<AlgorithmQuestion>;
  companyId: Scalars["Int"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  didEndAtSeconds: Maybe<Scalars["Int"]["output"]>;
  didStartAtSeconds: Maybe<Scalars["Int"]["output"]>;
  employeeId: Maybe<Scalars["String"]["output"]>;
  enabledLanguages: Array<Scalars["String"]["output"]>;
  entityId: Scalars["Int"]["output"];
  evaluatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  questionId: Scalars["Int"]["output"];
  questionVersion: Scalars["String"]["output"];
  sessions: Array<ChallengeSession>;
  status: ChallengeStatus;
  submissions: Array<ChallengeSubmission>;
  suspiciousDegree: ChallengeSuspiciousDegree;
  suspiciousInference: Maybe<SuspiciousInference>;
  /** 不審度算出の対象外の場合は NULL となる */
  suspiciousInferenceId: Maybe<Scalars["String"]["output"]>;
  timeLimitSeconds: Maybe<Scalars["Int"]["output"]>;
  totalScore: Scalars["Float"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type ChallengeEntityChallengeQuestionArgs = {
  withAnswers: Scalars["Boolean"]["input"];
};

export type ChallengeQuestionInput = {
  enabledLanguages: Array<Scalars["String"]["input"]>;
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
};

export type ChallengeRunCodeResponse = {
  __typename?: "ChallengeRunCodeResponse";
  expected: Scalars["String"]["output"];
  isAccepted: Scalars["Boolean"]["output"];
  log: Scalars["String"]["output"];
  maxMemory: Scalars["Float"]["output"];
  output: Scalars["String"]["output"];
  performance: Scalars["Int"]["output"];
  snapshotId: Scalars["Int"]["output"];
  status: Scalars["String"]["output"];
};

export type ChallengeSession = ChallengeChatGptSession | ChallengeWebSession;

export type ChallengeSnapshot = Node & {
  __typename?: "ChallengeSnapshot";
  challengeId: Scalars["Int"]["output"];
  challengeSnapshotId: Scalars["Int"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  expected: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  input: Scalars["String"]["output"];
  isAccepted: Scalars["Boolean"]["output"];
  log: Scalars["String"]["output"];
  maxMemory: Scalars["Int"]["output"];
  output: Scalars["String"]["output"];
  performance: Scalars["Int"]["output"];
  questionId: Scalars["Int"]["output"];
  questionVersion: Scalars["String"]["output"];
  status: Scalars["String"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type ChallengeStatistics = {
  __typename?: "ChallengeStatistics";
  avgMaxMemory: Maybe<StatisticsStats>;
  avgPerformance: Maybe<StatisticsStats>;
  bins: Array<Scalars["Int"]["output"]>;
  coverage: Maybe<StatisticsStats>;
  elapsedTime: Maybe<StatisticsStats>;
  numHints: Maybe<StatisticsStats>;
  numPasteEvents: Maybe<StatisticsStats>;
  numSnapshots: Maybe<StatisticsStats>;
  numTabEvents: Maybe<StatisticsStats>;
  performance: Maybe<StatisticsStats>;
  readabilityScore: Maybe<StatisticsStats>;
  totalScore: Maybe<StatisticsStats>;
};

export const ChallengeStatus = {
  Completed: "COMPLETED",
  Created: "CREATED",
  Evaluated: "EVALUATED",
  Started: "STARTED",
  Unknown: "UNKNOWN",
} as const;

export type ChallengeStatus = (typeof ChallengeStatus)[keyof typeof ChallengeStatus];
export type ChallengeSubmission = Node & {
  __typename?: "ChallengeSubmission";
  avgMaxMemory: Scalars["Float"]["output"];
  avgPerformance: Scalars["Float"]["output"];
  challengeId: Scalars["Int"]["output"];
  challengeSubmissionId: Scalars["Int"]["output"];
  challengeUsedHintCount: Scalars["Int"]["output"];
  challengeUsedHintIds: Array<Scalars["Int"]["output"]>;
  code: Scalars["String"]["output"];
  companyId: Scalars["Int"]["output"];
  correctnessTestResult: Scalars["String"]["output"];
  coverage: Scalars["Float"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  elapsedTimeSeconds: Maybe<Scalars["Int"]["output"]>;
  enqueuedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  evaluatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  isBest: Scalars["Boolean"]["output"];
  machineCpu: Maybe<Scalars["Int"]["output"]>;
  machineMemory: Scalars["String"]["output"];
  machineOs: Scalars["String"]["output"];
  numAccessEvents: Scalars["Int"]["output"];
  numPassed: Scalars["Int"]["output"];
  numPasteEvents: Scalars["Int"]["output"];
  numSubset: Scalars["Int"]["output"];
  numTabEvents: Scalars["Int"]["output"];
  numTests: Scalars["Int"]["output"];
  performance: Scalars["Float"]["output"];
  performanceTestResult: Scalars["String"]["output"];
  questionId: Scalars["Int"]["output"];
  questionVersion: Scalars["String"]["output"];
  readability: Scalars["Float"]["output"];
  readabilityTestResult: Scalars["String"]["output"];
  runtime: Scalars["String"]["output"];
  runtimeComplexity: Scalars["String"]["output"];
  runtimeVersion: Scalars["String"]["output"];
  similarCodes: Array<ChallengeSubmissionSimilarCode>;
  similarityScore: Maybe<Scalars["Float"]["output"]>;
  snapshots: Array<ChallengeSnapshot>;
  solutionType: Scalars["String"]["output"];
  spaceComplexity: Scalars["String"]["output"];
  status: ChallengeSubmissionStatus;
  totalElapsedTimeSeconds: Maybe<Scalars["Int"]["output"]>;
  totalScore: Scalars["Float"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type ChallengeSubmissionSimilarCode = {
  __typename?: "ChallengeSubmissionSimilarCode";
  code: Scalars["String"]["output"];
  fragments: Array<ChallengeSubmissionSimilarCodeFragment>;
  metadata: SimilarCodeMetadata;
  score: Scalars["Float"]["output"];
  similarSubmission: Maybe<SimilarSubmission>;
  /** TODO: make url bff_internal because metadata is used instead */
  url: Maybe<Scalars["String"]["output"]>;
};

export type ChallengeSubmissionSimilarCodeFragment = {
  __typename?: "ChallengeSubmissionSimilarCodeFragment";
  left: ChallengeSubmissionSimilarCodeSelection;
  right: ChallengeSubmissionSimilarCodeSelection;
};

export type ChallengeSubmissionSimilarCodeSelection = {
  __typename?: "ChallengeSubmissionSimilarCodeSelection";
  endCol: Scalars["Int"]["output"];
  endRow: Scalars["Int"]["output"];
  startCol: Scalars["Int"]["output"];
  startRow: Scalars["Int"]["output"];
};

export const ChallengeSubmissionStatus = {
  Enqueued: "ENQUEUED",
  Evaluated: "EVALUATED",
  Failed: "FAILED",
  Unknown: "UNKNOWN",
} as const;

export type ChallengeSubmissionStatus = (typeof ChallengeSubmissionStatus)[keyof typeof ChallengeSubmissionStatus];
export const ChallengeSuspiciousDegree = {
  Clean: "CLEAN",
  Suspicious: "SUSPICIOUS",
  Uncalculated: "UNCALCULATED",
} as const;

export type ChallengeSuspiciousDegree = (typeof ChallengeSuspiciousDegree)[keyof typeof ChallengeSuspiciousDegree];
export type ChallengeTemplateCodeResponse = {
  __typename?: "ChallengeTemplateCodeResponse";
  c: Scalars["String"]["output"];
  cpp: Scalars["String"]["output"];
  csharp: Scalars["String"]["output"];
  dart: Scalars["String"]["output"];
  go: Scalars["String"]["output"];
  java: Scalars["String"]["output"];
  javascript: Scalars["String"]["output"];
  kotlin: Scalars["String"]["output"];
  mysql: Scalars["String"]["output"];
  perl: Scalars["String"]["output"];
  pgsql: Scalars["String"]["output"];
  php: Scalars["String"]["output"];
  python3: Scalars["String"]["output"];
  ruby: Scalars["String"]["output"];
  rust: Scalars["String"]["output"];
  scala: Scalars["String"]["output"];
  sqlite: Scalars["String"]["output"];
  swift: Scalars["String"]["output"];
  typescript: Scalars["String"]["output"];
};

export type ChallengeWebSession = {
  __typename?: "ChallengeWebSession";
  challengeId: Scalars["Int"]["output"];
  language: Scalars["String"]["output"];
  /** When latestWebSearchedLog is null, web search is not used in this session */
  latestWebSearchedLog: Maybe<LatestWebSearchedLog>;
  sessionId: Scalars["String"]["output"];
};

export type ChangePlanInput = {
  customerId: Scalars["Int"]["input"];
  planId: Scalars["Int"]["input"];
  subscriptionId: Scalars["Int"]["input"];
};

export type ChatGptBehavioralEvent = ChatGptConversationResetEvent | ChatGptRequestedEvent | ChatGptRespondedEvent;

export type ChatGptConversationResetEvent = {
  __typename?: "ChatGPTConversationResetEvent";
  chatId: Scalars["String"]["output"];
  resetAtNanoSeconds: Maybe<Scalars["Int"]["output"]>;
  resetAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type ChatGptMessage = {
  __typename?: "ChatGPTMessage";
  content: Scalars["String"]["output"];
  role: ChatGptRole;
};

export const ChatGptModel = {
  Gpt_3_5: "GPT_3_5",
  Gpt_4o: "GPT_4o",
} as const;

export type ChatGptModel = (typeof ChatGptModel)[keyof typeof ChatGptModel];
export type ChatGptRequestedEvent = {
  __typename?: "ChatGPTRequestedEvent";
  chatId: Scalars["String"]["output"];
  gptModel: ChatGptModel;
  prompt: Scalars["String"]["output"];
  requestedAtNanoSeconds: Maybe<Scalars["Int"]["output"]>;
  requestedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type ChatGptRespondedEvent = {
  __typename?: "ChatGPTRespondedEvent";
  chatId: Scalars["String"]["output"];
  gptModel: ChatGptModel;
  respondedAtNanoSeconds: Maybe<Scalars["Int"]["output"]>;
  respondedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  response: Scalars["String"]["output"];
};

export const ChatGptRole = {
  Assistant: "ASSISTANT",
  Unknown: "UNKNOWN",
  User: "USER",
} as const;

export type ChatGptRole = (typeof ChatGptRole)[keyof typeof ChatGptRole];
export const ClientSideInitializeAction = {
  GoEmployeeHome: "GO_EMPLOYEE_HOME",
  GoResetPassword: "GO_RESET_PASSWORD",
  GoSignIn: "GO_SIGN_IN",
  GoSignUp: "GO_SIGN_UP",
  GoTalentHome: "GO_TALENT_HOME",
  RefreshAuthToken: "REFRESH_AUTH_TOKEN",
  ShouldSignOut: "SHOULD_SIGN_OUT",
  StayCandidate: "STAY_CANDIDATE",
  StayUnknown: "STAY_UNKNOWN",
  WaitUntilInternalServerError: "WAIT_UNTIL_INTERNAL_SERVER_ERROR",
} as const;

export type ClientSideInitializeAction = (typeof ClientSideInitializeAction)[keyof typeof ClientSideInitializeAction];
export type CompaniesByTalentId = {
  __typename?: "CompaniesByTalentId";
  activeId: Scalars["Int"]["output"];
  companies: Array<Company>;
  pager: Maybe<CompaniesByTalentIdTimestampPager>;
};

export type CompaniesByTalentIdTimestampPager = {
  __typename?: "CompaniesByTalentIdTimestampPager";
  cursorSeconds: Maybe<Scalars["Int"]["output"]>;
  hasNext: Scalars["Boolean"]["output"];
};

export type Company = Node & {
  __typename?: "Company";
  companyEmployees: Array<CompanyEmployee>;
  companyId: Scalars["Int"]["output"];
  companyTalents: Array<CompanyTalent>;
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  employees: Array<User>;
  enableTalentScore: Scalars["Boolean"]["output"];
  groups: Array<EmployeeGroup>;
  id: Scalars["ID"]["output"];
  /** @deprecated Field no longer supported */
  invitations: Array<Invitation>;
  isHireRoo: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  /** TODO: get from security service and remove below */
  security: Maybe<Security>;
  skillTags: Array<CompanySkillTag>;
  talents: Array<User>;
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  websiteUrl: Scalars["String"]["output"];
};

export type CompanyEmployee = {
  __typename?: "CompanyEmployee";
  companyId: Scalars["Int"]["output"];
  employeeId: Scalars["String"]["output"];
  lastSignedInAtSeconds: Maybe<Scalars["Int"]["output"]>;
  role: Role;
};

export const CompanyMemberType = {
  Employee: "EMPLOYEE",
  Talent: "TALENT",
} as const;

export type CompanyMemberType = (typeof CompanyMemberType)[keyof typeof CompanyMemberType];
export type CompanySkillTag = {
  __typename?: "CompanySkillTag";
  skillTag: Maybe<SkillTagNode>;
  skillTagId: Scalars["String"]["output"];
};

export type CompanyTalent = Node & {
  __typename?: "CompanyTalent";
  companyId: Scalars["Int"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  invitationId: Scalars["String"]["output"];
  talentId: Scalars["String"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type CompositePrimaryKey = {
  questionId: Scalars["Int"]["input"];
  version: Scalars["String"]["input"];
};

export type ContractPeriodUsage = {
  __typename?: "ContractPeriodUsage";
  freeCount: Scalars["Int"]["output"];
  paidCount: Scalars["Int"]["output"];
  totalCount: Scalars["Int"]["output"];
  yearMonth: Scalars["String"]["output"];
};

export type ContractPeriodUsagesResponse = {
  __typename?: "ContractPeriodUsagesResponse";
  contractPeriodUsages: Array<ContractPeriodUsage>;
};

export type ContractPlanInput = {
  city: Scalars["String"]["input"];
  country: Country;
  couponIds: Array<Scalars["Int"]["input"]>;
  customerId: Scalars["Int"]["input"];
  email: Scalars["String"]["input"];
  language: Language;
  lastFour: Scalars["String"]["input"];
  line1: Scalars["String"]["input"];
  line2: Scalars["String"]["input"];
  paymentMethod: Scalars["String"]["input"];
  paymentType: PaymentType;
  periodType: PaymentPeriodType;
  planId: Scalars["Int"]["input"];
  postalCode: Scalars["String"]["input"];
  purchasePackages: Array<PurchasePackageInput>;
  state: Scalars["String"]["input"];
};

export type ConversationToken = {
  __typename?: "ConversationToken";
  token: Scalars["String"]["output"];
};

/** @see https://stripe.com/docs/api/country_specs/retrieve */
export const Country = {
  Jp: "JP",
  Us: "US",
} as const;

export type Country = (typeof Country)[keyof typeof Country];
export type Coupon = {
  __typename?: "Coupon";
  couponDurationType: PaymentCouponDurationType;
  couponId: Scalars["Int"]["output"];
  couponType: PaymentCouponType;
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  discountNumber: Scalars["Int"]["output"];
  durationMonth: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  nameEn: Scalars["String"]["output"];
  nameJa: Scalars["String"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type CreateAlgorithmCorrectnessTestCaseInput = {
  descriptionEn: Scalars["String"]["input"];
  descriptionJa: Scalars["String"]["input"];
  inputs: Array<Scalars["String"]["input"]>;
  isHidden: Scalars["Boolean"]["input"];
  outputs: Array<Scalars["String"]["input"]>;
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
  titleEn: Scalars["String"]["input"];
  titleJa: Scalars["String"]["input"];
};

export type CreateAlgorithmPerformanceTestCaseInput = {
  inputs: Array<Scalars["String"]["input"]>;
  isHidden: Scalars["Boolean"]["input"];
  label: Scalars["String"]["input"];
  outputs: Array<Scalars["String"]["input"]>;
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
};

export type CreateAlgorithmQuestionInput = {
  companyId: Scalars["Int"]["input"];
  /** @deprecated Use correctnessTestCaseIds instead */
  correctnessTestCase?: InputMaybe<Scalars["String"]["input"]>;
  correctnessTestCaseIds: Array<Scalars["Int"]["input"]>;
  descriptionEn?: InputMaybe<Scalars["String"]["input"]>;
  descriptionJa?: InputMaybe<Scalars["String"]["input"]>;
  difficulty: Difficulty;
  employeeId: Scalars["String"]["input"];
  isPrivate: Scalars["Boolean"]["input"];
  /** @deprecated Use performanceTestCaseIds instead */
  performanceTestCase?: InputMaybe<Scalars["String"]["input"]>;
  performanceTestCaseIds: Array<Scalars["Int"]["input"]>;
  performanceTimeLimitMilliSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  signature?: InputMaybe<Scalars["String"]["input"]>;
  status: QuestionStatus;
  timeLimitMin: Scalars["Int"]["input"];
  titleEn?: InputMaybe<Scalars["String"]["input"]>;
  titleJa?: InputMaybe<Scalars["String"]["input"]>;
  variant: AlgorithmQuestionVariant;
  version: Scalars["String"]["input"];
};

export type CreateAssessmentRequestInput = {
  companyId: Scalars["Int"]["input"];
  employeeId: Scalars["String"]["input"];
  entitySources: Array<AssessmentEntitySourceInput>;
  examInterval: AssessmentExamInterval;
  isPublic: Scalars["Boolean"]["input"];
  memo?: InputMaybe<Scalars["String"]["input"]>;
  messageForTalent?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  remindBeforeSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  reportSettings: AssessmentReportSettingsInput;
  startScheduleAtSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  talentId: Scalars["String"]["input"];
  timeLimitSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  viewerIds: Array<AssessmentViewerIdInput>;
};

export type CreateCustomerInput = {
  companyId: Scalars["Int"]["input"];
  companyName: Scalars["String"]["input"];
};

export type CreateDemoFromScreeningInput = {
  invitationCode?: InputMaybe<Scalars["String"]["input"]>;
  screeningId: Scalars["String"]["input"];
};

export type CreateEvaluationMetricGroupInput = {
  companyId: Scalars["Int"]["input"];
  description: Scalars["String"]["input"];
  employeeId: Scalars["String"]["input"];
  metricIds: Array<Scalars["Int"]["input"]>;
  title: Scalars["String"]["input"];
};

export type CreateEvaluationMetricInput = {
  companyId: Scalars["Int"]["input"];
  description: Scalars["String"]["input"];
  employeeId: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
};

export type CreateExamImmediatelyRequestInput = {
  assessmentId: Scalars["String"]["input"];
};

export type CreateFreepadQuestionInput = {
  answers: Array<FreepadAnswerInput>;
  companyId: Scalars["Int"]["input"];
  descriptionEn?: InputMaybe<Scalars["String"]["input"]>;
  descriptionJa?: InputMaybe<Scalars["String"]["input"]>;
  difficulty: Difficulty;
  employeeId: Scalars["String"]["input"];
  initialCodes: Array<FreepadInitialCodeInput>;
  initialFlowCharts: Array<FreepadInitialFlowChartInput>;
  isPrivate: Scalars["Boolean"]["input"];
  status: QuestionStatus;
  tags: Array<Scalars["String"]["input"]>;
  titleEn?: InputMaybe<Scalars["String"]["input"]>;
  titleJa?: InputMaybe<Scalars["String"]["input"]>;
  variant: FreepadQuestionVariant;
  version: Scalars["String"]["input"];
};

export type CreateMultiChoicePackage = {
  companyId: Scalars["Int"]["input"];
  employeeId: Scalars["String"]["input"];
  questionKeys: Array<CompositePrimaryKey>;
};

export type CreateMultiChoiceQuestionInput = {
  companyId: Scalars["Int"]["input"];
  descriptionEn?: InputMaybe<Scalars["String"]["input"]>;
  descriptionJa?: InputMaybe<Scalars["String"]["input"]>;
  difficulty: Difficulty;
  employeeId: Scalars["String"]["input"];
  evaluationItems: Array<MultiChoiceEvaluationItemInput>;
  isPrivate: Scalars["Boolean"]["input"];
  metricType: MultiChoiceMetricType;
  options: Array<MultiChoiceOptionInput>;
  status: QuestionStatus;
  tags: Array<MultiChoiceTagInput>;
  titleEn?: InputMaybe<Scalars["String"]["input"]>;
  titleJa?: InputMaybe<Scalars["String"]["input"]>;
  variant: MultiChoiceQuestionVariant;
  version: Scalars["String"]["input"];
};

export type CreateRemoteInput = {
  candidateName: Scalars["String"]["input"];
  companyId: Scalars["Int"]["input"];
  employeeId: Scalars["String"]["input"];
  entitySources?: InputMaybe<Array<RemoteEntitySourceInput>>;
  isPublic: Scalars["Boolean"]["input"];
  memo?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  tagNames: Array<Scalars["String"]["input"]>;
  viewerIds: Array<RemoteViewerIdInput>;
};

export type CreateRemoteInterviewTemplateInput = {
  candidateName: Scalars["String"]["input"];
  companyId: Scalars["Int"]["input"];
  createdBy: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
  isPublic: Scalars["Boolean"]["input"];
  memo?: InputMaybe<Scalars["String"]["input"]>;
  questions: Array<RemoteEntitySourceInput>;
  remoteName: Scalars["String"]["input"];
  reviewerIds: Array<ReviewerIdInput>;
  tagNames?: InputMaybe<Array<Scalars["String"]["input"]>>;
  title: Scalars["String"]["input"];
  variables: Array<TemplateVariableInput>;
};

export type CreateScreeningEntityTrackInput = {
  fixed?: InputMaybe<CreateScreeningFixedEntityTrackInput>;
  randomFromQuestions?: InputMaybe<CreateScreeningRandomFromQuestionsEntityTrackInput>;
  selectableFromQuestions?: InputMaybe<CreateScreeningSelectableFromQuestionsEntityTrackInput>;
};

export type CreateScreeningFixedEntityTrackInput = {
  entitySource: SpotEntitySourceForEntityTrackInput;
  questionScoreWeight: Scalars["Int"]["input"];
};

export type CreateScreeningInput = {
  acceptableLimit?: InputMaybe<Scalars["Int"]["input"]>;
  allowChatGPT: Scalars["Boolean"]["input"];
  allowHint: Scalars["Boolean"]["input"];
  allowWebSearch: Scalars["Boolean"]["input"];
  candidateAccessPolicy: ScreeningCandidateAccessPolicy;
  companyId: Scalars["Int"]["input"];
  creatorId: Scalars["String"]["input"];
  deadlineSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** @deprecated @Himenon will remove */
  entitySources?: InputMaybe<Array<SpotEntitySourceInput>>;
  entityTrackInputs: Array<CreateScreeningEntityTrackInput>;
  invitationLanguage: Language;
  isActive: Scalars["Boolean"]["input"];
  memo?: InputMaybe<Scalars["String"]["input"]>;
  messageForCandidate?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  ownerEmailAddress?: InputMaybe<Scalars["String"]["input"]>;
  spotIsPublic: Scalars["Boolean"]["input"];
  tagNames?: InputMaybe<Array<Scalars["String"]["input"]>>;
  timeLimitSeconds: Scalars["Int"]["input"];
  variables: Array<ScreeningVariableInput>;
  viewerIds: Array<SpotViewerInput>;
};

export type CreateScreeningRandomFromQuestionsEntityTrackInput = {
  entitySources: Array<SpotEntitySourceForEntityTrackInput>;
  questionScoreWeight: Scalars["Int"]["input"];
};

export type CreateScreeningSelectableFromQuestionsEntityTrackInput = {
  entitySources: Array<SpotEntitySourceForEntityTrackInput>;
  questionScoreWeight: Scalars["Int"]["input"];
};

export type CreateSpotInput = {
  allowChatGPT: Scalars["Boolean"]["input"];
  allowHint: Scalars["Boolean"]["input"];
  allowWebSearch: Scalars["Boolean"]["input"];
  companyId: Scalars["Int"]["input"];
  employeeId: Scalars["String"]["input"];
  entitySources: Array<SpotEntitySourceInput>;
  invitationEmail?: InputMaybe<Scalars["String"]["input"]>;
  invitationLanguage?: InputMaybe<Language>;
  invitationMethod: SpotInvitationMethod;
  isPublic: Scalars["Boolean"]["input"];
  memo?: InputMaybe<Scalars["String"]["input"]>;
  messageForCandidate?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  reviewerIds: Array<SpotReviewerInput>;
  tagNames?: InputMaybe<Array<Scalars["String"]["input"]>>;
  timeLimitSeconds: Scalars["Int"]["input"];
  willEndAtSeconds: Scalars["Int"]["input"];
};

export type CreateTalentInvitationInput = {
  companyId: Scalars["Int"]["input"];
  emailAddresses: Array<Scalars["String"]["input"]>;
  employeeId: Scalars["String"]["input"];
  invitationLanguage: InvitationLanguage;
  invitationMethod: InvitationMethod;
};

export type DeleteAlgorithmQuestionInput = {
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
};

export type DeleteAssessmentRequestInput = {
  assessmentId: Scalars["String"]["input"];
};

export type DeleteCompanyTalentInput = {
  companyId: Scalars["Int"]["input"];
  talentId: Scalars["String"]["input"];
};

export type DeleteEvaluationMetricGroupInput = {
  metricGroupId: Scalars["Int"]["input"];
};

export type DeleteEvaluationMetricInput = {
  metricId: Scalars["Int"]["input"];
};

export type DeleteEvaluationMetricsFromGroupInput = {
  metricGroupId: Scalars["Int"]["input"];
  metricIds: Array<Scalars["Int"]["input"]>;
};

export type DeleteFreepadQuestionInput = {
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
};

export type DeleteLiveCodingSessionInput = {
  liveCodingId: Scalars["Int"]["input"];
  sessionId: Scalars["Int"]["input"];
};

export type DeleteMultiChoicePackageInput = {
  packageId: Scalars["Int"]["input"];
  version: Scalars["String"]["input"];
};

export type DeleteMultiChoiceQuestionInput = {
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
};

export type DeleteMultiEvaluationMetricsInput = {
  metricIds: Array<Scalars["Int"]["input"]>;
};

export type DeleteMultiScreeningsInput = {
  screeningIds: Array<Scalars["String"]["input"]>;
};

export type DeleteMultiSpotsInput = {
  spotIds: Array<Scalars["String"]["input"]>;
};

export type DeleteProjectQuestionInput = {
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
};

export type DeleteRemoteInput = {
  remoteId: Scalars["String"]["input"];
};

export type DeleteScreeningInput = {
  screeningId: Scalars["String"]["input"];
};

export type DeleteSystemDesignQuestionInput = {
  questionId: Scalars["Int"]["input"];
};

export type Demo = Node & {
  __typename?: "Demo";
  allowChatGPT: Scalars["Boolean"]["output"];
  allowWebSearch: Scalars["Boolean"]["output"];
  candidate: Maybe<User>;
  candidateId: Maybe<Scalars["String"]["output"]>;
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  demoId: Scalars["String"]["output"];
  entities: Array<DemoEntity>;
  entitySources: Array<DemoEntitySource>;
  id: Scalars["ID"]["output"];
  status: DemoStatus;
  timeLimitSeconds: Maybe<Scalars["Int"]["output"]>;
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type DemoEntitiesArgs = {
  withAnswers: Scalars["Boolean"]["input"];
};

export type DemoEntity = ChallengeEntity | ProjectEntity | QuizEntity | SystemDesignEntity;

export type DemoEntitySource = {
  __typename?: "DemoEntitySource";
  entityId: Scalars["Int"]["output"];
  entityType: DemoEntityType;
};

export const DemoEntityType = {
  Challenge: "CHALLENGE",
  Project: "PROJECT",
  Quiz: "QUIZ",
  SystemDesign: "SYSTEM_DESIGN",
  Unknown: "UNKNOWN",
} as const;

export type DemoEntityType = (typeof DemoEntityType)[keyof typeof DemoEntityType];
export const DemoStatus = {
  Completed: "COMPLETED",
  Finalized: "FINALIZED",
  Started: "STARTED",
  Unknown: "UNKNOWN",
} as const;

export type DemoStatus = (typeof DemoStatus)[keyof typeof DemoStatus];
export const Difficulty = {
  Difficult: "DIFFICULT",
  Easy: "EASY",
  Medium: "MEDIUM",
  Unknown: "UNKNOWN",
} as const;

export type Difficulty = (typeof Difficulty)[keyof typeof Difficulty];
export type EmployeeGroup = {
  __typename?: "EmployeeGroup";
  companyEmployees: Array<CompanyEmployee>;
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  employeeGroupId: Scalars["String"]["output"];
  groupName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
};

export type EmployeeNotification = {
  __typename?: "EmployeeNotification";
  actionLink: Scalars["String"]["output"];
  companyId: Scalars["Int"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  descriptionEn: Scalars["String"]["output"];
  descriptionJa: Scalars["String"]["output"];
  employeeId: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  isRead: Scalars["Boolean"]["output"];
  notificationId: Scalars["Int"]["output"];
  titleEn: Scalars["String"]["output"];
  titleJa: Scalars["String"]["output"];
};

export type EmployeeNotificationsResponse = {
  __typename?: "EmployeeNotificationsResponse";
  count: Scalars["Int"]["output"];
  employeeNotifications: Array<EmployeeNotification>;
  pager: Maybe<NotificationPager>;
};

export type EndExamRequestInput = {
  examId: Scalars["String"]["input"];
};

export type EndRemoteInput = {
  remoteId: Scalars["String"]["input"];
};

export type EnqueueProjectSubmissionInput = {
  projectId: Scalars["Int"]["input"];
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
};

export type EvaluateMultiSpotsInput = {
  employeeId: Scalars["String"]["input"];
  evaluationComment: Scalars["String"]["input"];
  isPassed: Scalars["Boolean"]["input"];
  spotIds: Array<Scalars["String"]["input"]>;
};

export type EvaluateQuizSubmissionInput = {
  evaluations: Array<QuizEvaluationsInput>;
  isCorrect: Scalars["Boolean"]["input"];
  submissionId: Scalars["Int"]["input"];
};

export type EvaluateRemoteInput = {
  employeeId: Scalars["String"]["input"];
  evaluationComment: Scalars["String"]["input"];
  isPassed: Scalars["Boolean"]["input"];
  remoteId: Scalars["String"]["input"];
};

export type Evaluation = Node & {
  __typename?: "Evaluation";
  comment: Scalars["String"]["output"];
  companyId: Scalars["Int"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  employeeId: Scalars["String"]["output"];
  evaluationId: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  metric: Maybe<EvaluationMetric>;
  metricId: Scalars["Int"]["output"];
  numStars: Scalars["Int"]["output"];
  recommendationId: Scalars["Int"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type EvaluationMetric = Node & {
  __typename?: "EvaluationMetric";
  companyId: Scalars["Int"]["output"];
  createdAtSeconds: Scalars["Int"]["output"];
  createdBy: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  employee: User;
  id: Scalars["ID"]["output"];
  metricId: Scalars["Int"]["output"];
  title: Scalars["String"]["output"];
  updatedAtSeconds: Scalars["Int"]["output"];
};

export type EvaluationMetricGroup = Node & {
  __typename?: "EvaluationMetricGroup";
  companyId: Scalars["Int"]["output"];
  createdAtSeconds: Scalars["Int"]["output"];
  createdBy: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  employee: User;
  id: Scalars["ID"]["output"];
  metricGroupId: Scalars["Int"]["output"];
  metrics: Array<EvaluationMetric>;
  title: Scalars["String"]["output"];
  updatedAtSeconds: Scalars["Int"]["output"];
};

export type EvaluationMetricGroupsByCompanyResponse = {
  __typename?: "EvaluationMetricGroupsByCompanyResponse";
  count: Scalars["Int"]["output"];
  metricGroups: Array<EvaluationMetricGroup>;
  pager: Maybe<EvaluationPager>;
};

export type EvaluationMetricsByCompanyResponse = {
  __typename?: "EvaluationMetricsByCompanyResponse";
  count: Scalars["Int"]["output"];
  metrics: Array<EvaluationMetric>;
  pager: Maybe<EvaluationPager>;
};

export type EvaluationPager = {
  __typename?: "EvaluationPager";
  cursor: Scalars["Int"]["output"];
  hasNext: Scalars["Boolean"]["output"];
};

export type EvaluationRecommendation = {
  __typename?: "EvaluationRecommendation";
  comment: Scalars["String"]["output"];
  companyId: Scalars["Int"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  employeeId: Scalars["String"]["output"];
  evaluationRecommendationId: Scalars["Int"]["output"];
  evaluations: Array<Evaluation>;
  id: Scalars["ID"]["output"];
  isRecommended: Scalars["Boolean"]["output"];
  reviewer: User;
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type EvaluationSourceInput = {
  isPassed: Scalars["Boolean"]["input"];
  scoringItemId: Scalars["Int"]["input"];
};

export type Exam = Node & {
  __typename?: "Exam";
  assessmentId: Scalars["String"]["output"];
  company: Maybe<Company>;
  companyId: Scalars["Int"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  didEndAtSeconds: Maybe<Scalars["Int"]["output"]>;
  didStartAtSeconds: Maybe<Scalars["Int"]["output"]>;
  endIntervalAtSeconds: Maybe<Scalars["Int"]["output"]>;
  entities: Array<AssessmentEntity>;
  entityCount: Scalars["Int"]["output"];
  entityIds: Array<AssessmentEntityId>;
  examId: Scalars["String"]["output"];
  expiredAtSeconds: Maybe<Scalars["Int"]["output"]>;
  finalizedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  isReliableRank: Scalars["Boolean"]["output"];
  messageForTalent: Maybe<Scalars["String"]["output"]>;
  numInterval: Scalars["Int"]["output"];
  numSubset: Scalars["Int"]["output"];
  numTry: Scalars["Int"]["output"];
  originId: Scalars["String"]["output"];
  parentId: Maybe<Scalars["String"]["output"]>;
  rank: Scalars["Int"]["output"];
  rankEvaluation: StatisticsEvaluation;
  recommendationIds: Array<Scalars["Int"]["output"]>;
  recommendations: Array<EvaluationRecommendation>;
  relativeScore: Scalars["Float"]["output"];
  reportSettings: Maybe<AssessmentReportSettings>;
  startIntervalAtSeconds: Maybe<Scalars["Int"]["output"]>;
  status: ExamStatus;
  timeLimitSeconds: Maybe<Scalars["Int"]["output"]>;
  totalScore: Scalars["Float"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  willEndAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type ExamEntitiesArgs = {
  withAnswers: Scalars["Boolean"]["input"];
};

export type ExamStatistics = {
  __typename?: "ExamStatistics";
  bins: Array<Scalars["Int"]["output"]>;
  relativeScore: Maybe<StatisticsStats>;
};

export const ExamStatus = {
  Completed: "COMPLETED",
  Created: "CREATED",
  Expired: "EXPIRED",
  Finalized: "FINALIZED",
  Started: "STARTED",
  Unknown: "UNKNOWN",
} as const;

export type ExamStatus = (typeof ExamStatus)[keyof typeof ExamStatus];
export const ExportFileType = {
  Csv: "CSV",
  Excel: "EXCEL",
} as const;

export type ExportFileType = (typeof ExportFileType)[keyof typeof ExportFileType];
export const ExportLanguage = {
  En: "EN",
  Ja: "JA",
} as const;

export type ExportLanguage = (typeof ExportLanguage)[keyof typeof ExportLanguage];
export type ExportPdfResponse = {
  __typename?: "ExportPdfResponse";
  id: Scalars["String"]["output"];
};

export type ExportResponse = {
  __typename?: "ExportResponse";
  downloadUrl: Scalars["String"]["output"];
  reason: Scalars["String"]["output"];
  status: ExportStatus;
};

export const ExportStatus = {
  Complete: "COMPLETE",
  Error: "ERROR",
  Pending: "PENDING",
  Unknown: "UNKNOWN",
} as const;

export type ExportStatus = (typeof ExportStatus)[keyof typeof ExportStatus];
export type ExportTestsResponse = {
  __typename?: "ExportTestsResponse";
  id: Scalars["String"]["output"];
};

export type FeedbackSuspiciousDegreeInput = {
  comment: Scalars["String"]["input"];
  feedbackProviderId: Scalars["String"]["input"];
  inferenceId: Scalars["String"]["input"];
  isCorrect: Scalars["Boolean"]["input"];
};

export type FillTemplateInput = {
  id: Scalars["Int"]["input"];
  variableInput: Array<FillTemplateVariableInput>;
};

export type FillTemplateVariableInput = {
  key: Scalars["Int"]["input"];
  value: Scalars["String"]["input"];
};

export type Filters = {
  difficulties: Array<Difficulty>;
  leakScoreLevels: Array<QuestionLeakScoreLevel>;
  questionVariants: Array<QuestionVariant>;
  skillTagIds: Array<Scalars["String"]["input"]>;
  statuses: Array<QuestionStatus>;
  titleEn: Scalars["String"]["input"];
  titleJa: Scalars["String"]["input"];
};

export type FreepadAnswer = Node & {
  __typename?: "FreepadAnswer";
  answerCodes: Array<FreepadAnswerCode>;
  answerFlowCharts: Array<FreepadAnswerFlowChart>;
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  descriptionEn: Maybe<Scalars["String"]["output"]>;
  descriptionJa: Maybe<Scalars["String"]["output"]>;
  freepadAnswerId: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  questionId: Scalars["Int"]["output"];
  questionVersion: Scalars["String"]["output"];
  titleEn: Maybe<Scalars["String"]["output"]>;
  titleJa: Maybe<Scalars["String"]["output"]>;
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type FreepadAnswerCode = Node & {
  __typename?: "FreepadAnswerCode";
  answerId: Scalars["Int"]["output"];
  body: Scalars["String"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  freepadAnswerCodeId: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  runtime: Scalars["String"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type FreepadAnswerCodeInput = {
  answerId: Scalars["Int"]["input"];
  body: Scalars["String"]["input"];
  createdAtSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  deletedAtSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["Int"]["input"];
  runtime: Scalars["String"]["input"];
  updatedAtSeconds?: InputMaybe<Scalars["Int"]["input"]>;
};

export type FreepadAnswerFlowChart = Node & {
  __typename?: "FreepadAnswerFlowChart";
  answerId: Scalars["Int"]["output"];
  body: Scalars["String"]["output"];
  componentType: SystemDesignComponentType;
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  freepadAnswerFlowChartId: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type FreepadAnswerFlowChartInput = {
  answerId: Scalars["Int"]["input"];
  body: Scalars["String"]["input"];
  componentType: SystemDesignComponentType;
  createdAtSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  deletedAtSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["Int"]["input"];
  updatedAtSeconds?: InputMaybe<Scalars["Int"]["input"]>;
};

export type FreepadAnswerInput = {
  answerCodes: Array<FreepadAnswerCodeInput>;
  answerFlowCharts: Array<FreepadAnswerFlowChartInput>;
  createdAtSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  deletedAtSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  descriptionEn?: InputMaybe<Scalars["String"]["input"]>;
  descriptionJa?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  label: Scalars["String"]["input"];
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
  titleEn?: InputMaybe<Scalars["String"]["input"]>;
  titleJa?: InputMaybe<Scalars["String"]["input"]>;
  updatedAtSeconds?: InputMaybe<Scalars["Int"]["input"]>;
};

export type FreepadInitialCode = Node & {
  __typename?: "FreepadInitialCode";
  body: Scalars["String"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  freepadInitialCodeId: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  questionId: Scalars["Int"]["output"];
  questionVersion: Scalars["String"]["output"];
  runtime: Scalars["String"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type FreepadInitialCodeInput = {
  body: Scalars["String"]["input"];
  createdAtSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  deletedAtSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["Int"]["input"];
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
  runtime: Scalars["String"]["input"];
  updatedAtSeconds?: InputMaybe<Scalars["Int"]["input"]>;
};

export type FreepadInitialFlowChart = Node & {
  __typename?: "FreepadInitialFlowChart";
  body: Scalars["String"]["output"];
  componentType: SystemDesignComponentType;
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  freepadInitialFlowChartId: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  questionId: Scalars["Int"]["output"];
  questionVersion: Scalars["String"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type FreepadInitialFlowChartInput = {
  body: Scalars["String"]["input"];
  componentType: SystemDesignComponentType;
  createdAtSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  deletedAtSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["Int"]["input"];
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
  updatedAtSeconds?: InputMaybe<Scalars["Int"]["input"]>;
};

export type FreepadQuestion = Node & {
  __typename?: "FreepadQuestion";
  answers: Array<FreepadAnswer>;
  averageElapsedTimeSeconds: Scalars["Float"]["output"];
  companyId: Scalars["Int"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  descriptionEn: Maybe<Scalars["String"]["output"]>;
  descriptionJa: Maybe<Scalars["String"]["output"]>;
  difficulty: Difficulty;
  employee: User;
  employeeId: Scalars["String"]["output"];
  freepadQuestionId: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  initialCodes: Array<FreepadInitialCode>;
  initialFlowCharts: Array<FreepadInitialFlowChart>;
  isOfficial: Scalars["Boolean"]["output"];
  isPrivate: Scalars["Boolean"]["output"];
  isSupportedLeakScore: Scalars["Boolean"]["output"];
  key: Scalars["String"]["output"];
  leakScore: Maybe<LeakScore>;
  numUses: Scalars["Int"]["output"];
  status: QuestionStatus;
  tags: Array<FreepadTag>;
  titleEn: Maybe<Scalars["String"]["output"]>;
  titleJa: Maybe<Scalars["String"]["output"]>;
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  variant: FreepadQuestionVariant;
  version: Scalars["String"]["output"];
};

export const FreepadQuestionVariant = {
  Algorithm: "ALGORITHM",
  Class: "CLASS",
  Database: "DATABASE",
  SystemDesign: "SYSTEM_DESIGN",
  Unknown: "UNKNOWN",
} as const;

export type FreepadQuestionVariant = (typeof FreepadQuestionVariant)[keyof typeof FreepadQuestionVariant];
export type FreepadTag = {
  __typename?: "FreepadTag";
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  name: Scalars["String"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type GenerateSpotSharedLinkInput = {
  periodSeconds: Scalars["Int"]["input"];
  showAnswer: Scalars["Boolean"]["input"];
  showPlayback: Scalars["Boolean"]["input"];
  showRank: Scalars["Boolean"]["input"];
  showRelativeEvaluation: Scalars["Boolean"]["input"];
  showReview: Scalars["Boolean"]["input"];
  showScore: Scalars["Boolean"]["input"];
  spotId: Scalars["String"]["input"];
};

export type GetContractPeriodUsagesInput = {
  contractPeriod: Scalars["Int"]["input"];
  customerId: Scalars["Int"]["input"];
  startedAtSeconds?: InputMaybe<Scalars["Int"]["input"]>;
};

export type HierarchicalSkillTagsInput = {
  depth: Scalars["Int"]["input"];
  limit: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
  offset: Scalars["Int"]["input"];
  withCount: Scalars["Boolean"]["input"];
};

export type HierarchicalSkillTagsResponse = {
  __typename?: "HierarchicalSkillTagsResponse";
  count: Scalars["Int"]["output"];
  hasNext: Scalars["Boolean"]["output"];
  skillTagNodes: Array<SkillTagNode>;
};

export type InitializeInput = {
  appVersion: Scalars["String"]["input"];
  clientLanguage: Language;
  routeType: RoutingType;
};

export type InitializedAuthResponse = {
  __typename?: "InitializedAuthResponse";
  currentUser: Maybe<User>;
};

export type InitializedCandidateUserResponse = {
  __typename?: "InitializedCandidateUserResponse";
  reserved: Maybe<Scalars["Boolean"]["output"]>;
};

export type InitializedEmployeeUserResponse = {
  __typename?: "InitializedEmployeeUserResponse";
  activeCompany: Company;
  belongsCompanies: Array<Company>;
  currentUser: User;
  customer: PaymentV2Customer;
  employeeRole: Role;
  shouldAction: Maybe<ClientSideInitializeAction>;
};

export type InitializedInvitationEmployeeResponse = {
  __typename?: "InitializedInvitationEmployeeResponse";
  currentUser: Maybe<User>;
};

export type InitializedInvitationTalentResponse = {
  __typename?: "InitializedInvitationTalentResponse";
  currentUser: Maybe<User>;
};

export type InitializedResponse =
  | InitializedAuthResponse
  | InitializedCandidateUserResponse
  | InitializedEmployeeUserResponse
  | InitializedInvitationEmployeeResponse
  | InitializedInvitationTalentResponse
  | InitializedTalentUserResponse
  | NeedClientSideActionResponse;

export type InitializedTalentUserResponse = {
  __typename?: "InitializedTalentUserResponse";
  activeCompany: Company;
  belongsCompanies: Array<Company>;
  currentUser: User;
  shouldAction: Maybe<ClientSideInitializeAction>;
};

export const InterviewType = {
  Remote: "REMOTE",
  Spot: "SPOT",
  Unknown: "UNKNOWN",
} as const;

export type InterviewType = (typeof InterviewType)[keyof typeof InterviewType];
export type Invitation = {
  __typename?: "Invitation";
  companyEmployees: Array<CompanyEmployee>;
  companyId: Scalars["Int"]["output"];
  createdBy: Scalars["String"]["output"];
  emailAddresses: Array<Scalars["String"]["output"]>;
  expiresAtSeconds: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["String"]["output"];
  invitationLanguage: InvitationLanguage;
  invitationMethod: InvitationMethod;
  role: Role;
};

export const InvitationLanguage = {
  En: "EN",
  Ja: "JA",
  Unknown: "UNKNOWN",
} as const;

export type InvitationLanguage = (typeof InvitationLanguage)[keyof typeof InvitationLanguage];
export const InvitationMethod = {
  Email: "EMAIL",
  Link: "LINK",
  Unknown: "UNKNOWN",
} as const;

export type InvitationMethod = (typeof InvitationMethod)[keyof typeof InvitationMethod];
export type InviteCandidateToScreeningResponse = {
  __typename?: "InviteCandidateToScreeningResponse";
  invitees: Array<ScreeningInvitee>;
  screening: Maybe<Screening>;
};

export type InviteCandidatesToScreeningInput = {
  candidateEmails: Array<Scalars["String"]["input"]>;
  screeningId: Scalars["String"]["input"];
};

export const InvoiceStatus = {
  Paid: "PAID",
  Processing: "PROCESSING",
  Unknown: "UNKNOWN",
  Unpaid: "UNPAID",
} as const;

export type InvoiceStatus = (typeof InvoiceStatus)[keyof typeof InvoiceStatus];
export type InvoicesResponse = {
  __typename?: "InvoicesResponse";
  count: Scalars["Int"]["output"];
  invoices: Array<PaymentV2Invoice>;
  pager: Maybe<PaymentPager>;
};

export type IssuedSpotEntitySource = {
  __typename?: "IssuedSpotEntitySource";
  entityId: Scalars["Int"]["output"];
  entityType: SpotEntityType;
  questionWeight: Scalars["Int"]["output"];
};

export type IssuersForRemoteByFieldsResponse = {
  __typename?: "IssuersForRemoteByFieldsResponse";
  /** companyId is required to fetch viewer group's name */
  companyId: Scalars["Int"]["output"];
  creatorIds: Array<Scalars["String"]["output"]>;
  creators: Array<User>;
  viewerSources: Array<RemoteViewerSource>;
  viewers: Array<RemoteViewer>;
};

export type IssuersInput = {
  companyId: Scalars["Int"]["input"];
  creator: Scalars["Boolean"]["input"];
  ignoreError?: InputMaybe<Scalars["Boolean"]["input"]>;
  reviewer: Scalars["Boolean"]["input"];
  screening: Scalars["Boolean"]["input"];
};

export type IssuersResponse = {
  __typename?: "IssuersResponse";
  creatorIds: Array<Scalars["String"]["output"]>;
  creators: Array<User>;
  reviewerSources: Array<SpotReviewerSource>;
  reviewers: Array<SpotReviewer>;
  screeningIds: Array<Scalars["String"]["output"]>;
  screenings: Array<Screening>;
};

export type IssuersResponseReviewersArgs = {
  companyId: Scalars["Int"]["input"];
};

export type JoinLiveCodingInput = {
  liveCodingId: Scalars["Int"]["input"];
  userName: Scalars["String"]["input"];
};

export type JoinRemoteInput = {
  remoteId: Scalars["String"]["input"];
};

export type JoinVideoChatConversationInput = {
  conversationId: Scalars["String"]["input"];
  displayName: Scalars["String"]["input"];
};

export type JoinVideoChatRoomInput = {
  displayName: Scalars["String"]["input"];
  roomId: Scalars["String"]["input"];
};

export type JsonQuery = {
  columnName: Scalars["String"]["input"];
  path: Scalars["String"]["input"];
};

export const Language = {
  En: "EN",
  Ja: "JA",
  Unknown: "UNKNOWN",
} as const;

export type Language = (typeof Language)[keyof typeof Language];
export type LatestWebSearchedLog = {
  __typename?: "LatestWebSearchedLog";
  /** When page is NULL, it means user searched. Otherwise, user moved page. */
  page: Maybe<Scalars["Int"]["output"]>;
  query: Scalars["String"]["output"];
  searchEventId: Scalars["String"]["output"];
};

export type LeakScore = {
  __typename?: "LeakScore";
  level: AlgorithmQuestionLeakScoreLevel;
};

export type LinkSpotToSonarIntegrationInput = {
  compoundId: Scalars["String"]["input"];
  sonarIntegrationId: Scalars["String"]["input"];
  spotId: Scalars["String"]["input"];
};

export type ListAssessmentsByCompanyId = {
  __typename?: "ListAssessmentsByCompanyId";
  assessments: Array<Assessment>;
  count: Scalars["Int"]["output"];
  hasNext: Scalars["Boolean"]["output"];
};

export type ListAssessmentsByTalentId = {
  __typename?: "ListAssessmentsByTalentId";
  assessments: Array<Assessment>;
  count: Scalars["Int"]["output"];
  hasNext: Scalars["Boolean"]["output"];
};

export type ListBestExamsByAssessmentId = {
  __typename?: "ListBestExamsByAssessmentId";
  count: Scalars["Int"]["output"];
  exams: Array<Exam>;
  hasNext: Scalars["Boolean"]["output"];
};

export type ListCompanies = {
  __typename?: "ListCompanies";
  activeCompanyId: Scalars["Int"]["output"];
  companies: Array<Company>;
  pager: Maybe<CompaniesByTalentIdTimestampPager>;
};

export type ListExamsRecursivePayload = {
  __typename?: "ListExamsRecursivePayload";
  bestExamId: Scalars["String"]["output"];
  exams: Array<Exam>;
};

export type ListInvoicesInput = {
  cursorSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  customerId: Scalars["Int"]["input"];
  size: Scalars["Int"]["input"];
};

export type ListUsagesInput = {
  cursorSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  customerId: Scalars["Int"]["input"];
  size: Scalars["Int"]["input"];
  usagePeriodStartSeconds?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LiveCoding = Node & {
  __typename?: "LiveCoding";
  candidateId: Scalars["String"]["output"];
  companyId: Scalars["Int"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  didEndAtSeconds: Maybe<Scalars["Int"]["output"]>;
  didEvaluatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  didStartAtSeconds: Maybe<Scalars["Int"]["output"]>;
  employeeId: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  liveCodingId: Scalars["Int"]["output"];
  memo: Scalars["String"]["output"];
  participants: Array<LiveCodingParticipant>;
  sessions: Array<Maybe<LiveCodingSession>>;
  status: Maybe<LiveCodingStatus>;
  totalScore: Scalars["Float"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  videoChatConversation: Maybe<LiveCodingVideoChatConversation>;
  videoChatRooms: Array<LiveCodingVideoChatRoom>;
};

export type LiveCodingParticipant = {
  __typename?: "LiveCodingParticipant";
  liveCodingId: Scalars["Int"]["output"];
  userId: Scalars["String"]["output"];
  userName: Scalars["String"]["output"];
  userType: LiveCodingUserType;
};

export type LiveCodingQuestion = AlgorithmQuestion | FreepadQuestion | SystemDesignQuestion;

export const LiveCodingQuestionType = {
  Algorithm: "ALGORITHM",
  EmptyCanvas: "EMPTY_CANVAS",
  EmptyPad: "EMPTY_PAD",
  Freepad: "FREEPAD",
  SystemDesign: "SYSTEM_DESIGN",
  Unknown: "UNKNOWN",
} as const;

export type LiveCodingQuestionType = (typeof LiveCodingQuestionType)[keyof typeof LiveCodingQuestionType];
export const LiveCodingQuestionVariant = {
  Algorithm: "ALGORITHM",
  Class: "CLASS",
  Database: "DATABASE",
  EmptyCanvas: "EMPTY_CANVAS",
  EmptyPad: "EMPTY_PAD",
  SystemDesign: "SYSTEM_DESIGN",
  Unknown: "UNKNOWN",
} as const;

export type LiveCodingQuestionVariant = (typeof LiveCodingQuestionVariant)[keyof typeof LiveCodingQuestionVariant];
export type LiveCodingSession = Node & {
  __typename?: "LiveCodingSession";
  algorithmQuestion: Maybe<AlgorithmQuestion>;
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  elapsedTimeSeconds: Maybe<Scalars["Int"]["output"]>;
  freepadQuestion: Maybe<FreepadQuestion>;
  id: Scalars["ID"]["output"];
  liveCodingId: Scalars["Int"]["output"];
  liveCodingSessionId: Scalars["Int"]["output"];
  questionId: Scalars["Int"]["output"];
  questionType: LiveCodingQuestionType;
  questionVariant: Maybe<LiveCodingQuestionVariant>;
  questionVersion: Scalars["String"]["output"];
  systemDesignQuestion: Maybe<SystemDesignQuestion>;
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type LiveCodingSessionAlgorithmQuestionArgs = {
  withAnswers: Scalars["Boolean"]["input"];
};

export type LiveCodingSessionFreepadQuestionArgs = {
  withAnswers: Scalars["Boolean"]["input"];
};

export type LiveCodingSessionSystemDesignQuestionArgs = {
  withAnswers: Scalars["Boolean"]["input"];
};

export const LiveCodingStatus = {
  Awaiting: "AWAITING",
  Completed: "COMPLETED",
  Created: "CREATED",
  Started: "STARTED",
  Unknown: "UNKNOWN",
} as const;

export type LiveCodingStatus = (typeof LiveCodingStatus)[keyof typeof LiveCodingStatus];
export type LiveCodingTemplateCodes = {
  __typename?: "LiveCodingTemplateCodes";
  c: Scalars["String"]["output"];
  cpp: Scalars["String"]["output"];
  csharp: Scalars["String"]["output"];
  dart: Scalars["String"]["output"];
  go: Scalars["String"]["output"];
  java: Scalars["String"]["output"];
  javascript: Scalars["String"]["output"];
  kotlin: Scalars["String"]["output"];
  mysql: Scalars["String"]["output"];
  perl: Scalars["String"]["output"];
  pgsql: Scalars["String"]["output"];
  php: Scalars["String"]["output"];
  python3: Scalars["String"]["output"];
  ruby: Scalars["String"]["output"];
  rust: Scalars["String"]["output"];
  scala: Scalars["String"]["output"];
  sqlite: Scalars["String"]["output"];
  swift: Scalars["String"]["output"];
  typescript: Scalars["String"]["output"];
};

export const LiveCodingUserType = {
  Candidate: "CANDIDATE",
  Employee: "EMPLOYEE",
  Unknown: "UNKNOWN",
} as const;

export type LiveCodingUserType = (typeof LiveCodingUserType)[keyof typeof LiveCodingUserType];
export type LiveCodingVideoChat = {
  __typename?: "LiveCodingVideoChat";
  token: Scalars["String"]["output"];
};

export type LiveCodingVideoChatConversation = Node & {
  __typename?: "LiveCodingVideoChatConversation";
  conversationId: Scalars["String"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  liveCodingId: Scalars["Int"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type LiveCodingVideoChatRoom = Node & {
  __typename?: "LiveCodingVideoChatRoom";
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  liveCodingId: Scalars["Int"]["output"];
  roomId: Scalars["String"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type MultiChoiceEvaluationItem = {
  __typename?: "MultiChoiceEvaluationItem";
  descriptionEn: Maybe<Scalars["String"]["output"]>;
  descriptionJa: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  questionId: Scalars["Int"]["output"];
  questionVersion: Scalars["String"]["output"];
  titleEn: Maybe<Scalars["String"]["output"]>;
  titleJa: Maybe<Scalars["String"]["output"]>;
};

export type MultiChoiceEvaluationItemInput = {
  descriptionEn?: InputMaybe<Scalars["String"]["input"]>;
  descriptionJa?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
  titleEn?: InputMaybe<Scalars["String"]["input"]>;
  titleJa?: InputMaybe<Scalars["String"]["input"]>;
};

export const MultiChoiceMetricType = {
  Backend: "BACKEND",
  ComputeScience: "COMPUTE_SCIENCE",
  Frontend: "FRONTEND",
  Infrastructure: "INFRASTRUCTURE",
  MachineLearning: "MACHINE_LEARNING",
  Mobile: "MOBILE",
  OperatingSystem: "OPERATING_SYSTEM",
  Other: "OTHER",
  ProjectManagement: "PROJECT_MANAGEMENT",
  Security: "SECURITY",
  Unknown: "UNKNOWN",
} as const;

export type MultiChoiceMetricType = (typeof MultiChoiceMetricType)[keyof typeof MultiChoiceMetricType];
export type MultiChoiceOption = {
  __typename?: "MultiChoiceOption";
  additionalDetailEn: Maybe<Scalars["String"]["output"]>;
  additionalDetailJa: Maybe<Scalars["String"]["output"]>;
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["Int"]["output"];
  isCorrect: Scalars["Boolean"]["output"];
  titleEn: Maybe<Scalars["String"]["output"]>;
  titleJa: Maybe<Scalars["String"]["output"]>;
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type MultiChoiceOptionInput = {
  additionalDetailEn?: InputMaybe<Scalars["String"]["input"]>;
  additionalDetailJa?: InputMaybe<Scalars["String"]["input"]>;
  createdAtSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  deletedAtSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["Int"]["input"];
  isCorrect: Scalars["Boolean"]["input"];
  titleEn?: InputMaybe<Scalars["String"]["input"]>;
  titleJa?: InputMaybe<Scalars["String"]["input"]>;
  updatedAtSeconds?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MultiChoicePackage = {
  __typename?: "MultiChoicePackage";
  accuracyRate: Scalars["Float"]["output"];
  averageElapsedTimeSeconds: Scalars["Float"]["output"];
  companyId: Scalars["Int"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  descriptionEn: Maybe<Scalars["String"]["output"]>;
  descriptionJa: Maybe<Scalars["String"]["output"]>;
  difficulty: Difficulty;
  employee: User;
  employeeId: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  isOfficial: Scalars["Boolean"]["output"];
  isPrivate: Scalars["Boolean"]["output"];
  isSupportedLeakScore: Scalars["Boolean"]["output"];
  key: Scalars["String"]["output"];
  leakScore: Maybe<LeakScore>;
  numUses: Scalars["Int"]["output"];
  packageId: Scalars["Int"]["output"];
  questions: Array<MultiChoiceQuestion>;
  scoreDistributionBins: Array<Scalars["Int"]["output"]>;
  status: QuestionStatus;
  timeLimitSeconds: Maybe<Scalars["Int"]["output"]>;
  titleEn: Maybe<Scalars["String"]["output"]>;
  titleJa: Maybe<Scalars["String"]["output"]>;
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  version: Scalars["String"]["output"];
};

export type MultiChoicePager = {
  __typename?: "MultiChoicePager";
  cursorNanoSeconds: Maybe<Scalars["Int"]["output"]>;
  cursorSeconds: Maybe<Scalars["Int"]["output"]>;
  hasNext: Maybe<Scalars["Boolean"]["output"]>;
};

export type MultiChoiceQuestion = {
  __typename?: "MultiChoiceQuestion";
  companyId: Scalars["Int"]["output"];
  correctAnswerRate: Scalars["Float"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  descriptionEn: Maybe<Scalars["String"]["output"]>;
  descriptionJa: Maybe<Scalars["String"]["output"]>;
  difficulty: Difficulty;
  employeeId: Scalars["String"]["output"];
  evaluationItems: Array<MultiChoiceEvaluationItem>;
  id: Scalars["Int"]["output"];
  isOfficial: Scalars["Boolean"]["output"];
  isPrivate: Scalars["Boolean"]["output"];
  metricType: MultiChoiceMetricType;
  options: Array<MultiChoiceOption>;
  status: QuestionStatus;
  tags: Array<MultiChoiceTag>;
  titleEn: Maybe<Scalars["String"]["output"]>;
  titleJa: Maybe<Scalars["String"]["output"]>;
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  variant: MultiChoiceQuestionVariant;
  version: Scalars["String"]["output"];
  versions: Array<Scalars["String"]["output"]>;
};

export const MultiChoiceQuestionVariant = {
  FreeText: "FREE_TEXT",
  MultiChoice: "MULTI_CHOICE",
  SingleChoice: "SINGLE_CHOICE",
  Unknown: "UNKNOWN",
} as const;

export type MultiChoiceQuestionVariant = (typeof MultiChoiceQuestionVariant)[keyof typeof MultiChoiceQuestionVariant];
export type MultiChoiceQuestionsInput = {
  cursorNanoSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  cursorSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  isDescending: Scalars["Boolean"]["input"];
  queryString: Scalars["String"]["input"];
  size: Scalars["Int"]["input"];
  status: MultiChoiceStatus;
  tagIds: Array<Scalars["Int"]["input"]>;
  withCount: Scalars["Boolean"]["input"];
};

export type MultiChoiceQuestionsResponse = {
  __typename?: "MultiChoiceQuestionsResponse";
  count: Scalars["Int"]["output"];
  pager: Maybe<MultiChoicePager>;
  questions: Array<MultiChoiceQuestion>;
};

export const MultiChoiceStatus = {
  Draft: "DRAFT",
  Published: "PUBLISHED",
  Rejected: "REJECTED",
  Reviewing: "REVIEWING",
  Unknown: "UNKNOWN",
} as const;

export type MultiChoiceStatus = (typeof MultiChoiceStatus)[keyof typeof MultiChoiceStatus];
export type MultiChoiceTag = {
  __typename?: "MultiChoiceTag";
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type MultiChoiceTagInput = {
  createdAtSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  deletedAtSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
  updatedAtSeconds?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MultiChoiceTagsInput = {
  cursorNanoSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  cursorSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  isDescending: Scalars["Boolean"]["input"];
  queryString: Scalars["String"]["input"];
  size: Scalars["Int"]["input"];
  withCount: Scalars["Boolean"]["input"];
};

export type MultiChoiceTagsResponse = {
  __typename?: "MultiChoiceTagsResponse";
  count: Scalars["Int"]["output"];
  pager: Maybe<MultiChoicePager>;
  tags: Array<MultiChoiceTag>;
};

export type Mutation = {
  __typename?: "Mutation";
  acceptEmployeeInvitation: Maybe<Invitation>;
  acceptScreening: AcceptScreeningResponse;
  acceptTalentInvitation: TalentInvitation;
  addEmployeeToGroups: Maybe<CompanyEmployee>;
  addEvaluationMetricToGroups: Array<EvaluationMetricGroup>;
  addEvaluationMetricsToGroup: Maybe<EvaluationMetricGroup>;
  addEvaluationMetricsToGroups: Scalars["Boolean"]["output"];
  addLiveCodingSession: LiveCodingSession;
  addLiveCodingVideoChatConversation: LiveCodingVideoChatConversation;
  addLiveCodingVideoChatRoom: LiveCodingVideoChatRoom;
  addMembersToGroup: Scalars["Boolean"]["output"];
  analyzeRemoteInterviewTemplate: Maybe<Array<TemplateVariable>>;
  analyzeTemplate: Maybe<Array<TemplateVariable>>;
  applyCoupon: PaymentV2Subscription;
  askChatGPT: AskChatGptResponse;
  authorizeSlack: Maybe<SlackSettings>;
  challengeSubmitCode: ChallengeSubmission;
  /** Void response */
  changePlan: Scalars["Boolean"]["output"];
  contractPlan: PaymentV2Customer;
  createAlgorithmCorrectnessTestCase: AlgorithmCorrectnessTestCase;
  createAlgorithmPerformanceTestCase: AlgorithmPerformanceTestCase;
  createAlgorithmQuestion: AlgorithmQuestion;
  createAssessment: Assessment;
  createDemoFromScreening: Demo;
  createDemoFromSpot: Demo;
  createEmployeeInvitation: Maybe<Invitation>;
  /** evaluation metric */
  createEvaluationMetric: EvaluationMetric;
  /** evaluation metric group */
  createEvaluationMetricGroup: EvaluationMetricGroup;
  createExamImmediately: Exam;
  createFreepadQuestion: FreepadQuestion;
  createGroup: Scalars["Boolean"]["output"];
  createMultiChoicePackage: MultiChoicePackage;
  createMultiChoiceQuestion: MultiChoiceQuestion;
  createRemote: Remote;
  createRemoteInterviewTemplate: RemoteInterviewTemplate;
  createScreening: Screening;
  /** @deprecated Delete after process migration to Screening */
  createSpot: Maybe<Spot>;
  createTalentInvitation: TalentInvitation;
  deleteAlgorithmQuestion: Scalars["Boolean"]["output"];
  deleteAssessment: Scalars["Boolean"]["output"];
  deleteCompanyEmployee: Scalars["Boolean"]["output"];
  deleteCompanyTalent: Scalars["Boolean"]["output"];
  deleteEvaluationMetric: Scalars["Boolean"]["output"];
  deleteEvaluationMetricGroup: Scalars["Boolean"]["output"];
  deleteEvaluationMetricsFromGroup: Scalars["Boolean"]["output"];
  deleteFreepadQuestion: Scalars["Boolean"]["output"];
  deleteGroup: Scalars["Boolean"]["output"];
  deleteGroups: Scalars["Boolean"]["output"];
  deleteLiveCodingSession: Maybe<Scalars["Boolean"]["output"]>;
  deleteMultiChoicePackage: Scalars["Boolean"]["output"];
  deleteMultiChoiceQuestion: Scalars["Boolean"]["output"];
  deleteMultiCompanyEmployees: Scalars["Boolean"]["output"];
  deleteMultiCompanyTalents: Scalars["Boolean"]["output"];
  deleteMultiEvaluationMetrics: Scalars["Boolean"]["output"];
  deleteMultiScreenings: Scalars["Boolean"]["output"];
  deleteMultiSpots: Scalars["Boolean"]["output"];
  deleteProjectQuestion: Scalars["Boolean"]["output"];
  deleteRemote: Maybe<Scalars["Boolean"]["output"]>;
  deleteRemoteInterviewTemplate: Scalars["Boolean"]["output"];
  deleteScreening: Scalars["Boolean"]["output"];
  deleteSonar: Scalars["Boolean"]["output"];
  deleteSpot: Scalars["Boolean"]["output"];
  deleteSystemDesignQuestion: Scalars["Boolean"]["output"];
  deleteTemplate: Scalars["Boolean"]["output"];
  editGroup: Scalars["Boolean"]["output"];
  endDemo: Demo;
  endExam: Exam;
  endRemote: Remote;
  endSpot: Spot;
  enqueueProjectSubmission: ProjectSubmission;
  enqueueSystemDesignSubmission: Maybe<SystemDesignSubmission>;
  evaluateMultiSpots: Array<Spot>;
  evaluateQuizSubmission: QuizSubmission;
  evaluateRemote: Remote;
  evaluateSpot: Maybe<Spot>;
  exportTestReportPdf: ExportPdfResponse;
  exportTestResultTable: ExportTestsResponse;
  feedbackSuspiciousDegree: Scalars["Boolean"]["output"];
  fillRemoteInterviewTemplate: RemoteInterviewTemplate;
  generateSpotSharedLink: SpotSharedLink;
  initializeApp: InitializedResponse;
  inviteCandidateToScreening: InviteCandidateToScreeningResponse;
  joinLiveCoding: LiveCoding;
  joinVideoChatConversation: ConversationToken;
  joinVideoChatRoom: RoomToken;
  linkSpotToSonarIntegration: Scalars["Boolean"]["output"];
  purchasePackages: PaymentV2Subscription;
  readEmployeeNotifications: Array<EmployeeNotification>;
  readTalentNotifications: Array<TalentNotification>;
  recreateExam: Exam;
  recreateSpot: Maybe<Spot>;
  reevaluateSystemDesign: ReevaluateSystemDesignResponse;
  registerSonar: Maybe<SonarIntegration>;
  removeMembers: Scalars["Boolean"]["output"];
  replicateScreening: Screening;
  requestReviewAlgorithmQuestion: AlgorithmQuestion;
  resetChatGPTConversation: Scalars["Boolean"]["output"];
  resetDevelopmentWorkspace: ProjectWorkspace;
  resetEvaluationWorkspace: ProjectWorkspace;
  /** Void response */
  retryPayment: Scalars["Boolean"]["output"];
  reviewExam: Exam;
  reviewRemote: Remote;
  reviewSpot: Spot;
  runProject: ProjectSnapshot;
  runSystemDesign: RunSystemDesignResponse;
  saveChallengeAppealMessage: Maybe<ChallengeEntity>;
  saveProjectAppealMessage: ProjectEntity;
  saveSystemDesignAppealMessage: Maybe<SystemDesignEntity>;
  startChallenge: ChallengeEntity;
  startChallengeSessions: StartChallengeSessionsResponse;
  startDevelopmentWorkspace: ProjectWorkspace;
  startEvaluationWorkspace: ProjectWorkspace;
  startExam: Exam;
  startLiveCoding: LiveCoding;
  startProject: ProjectEntity;
  startQuiz: QuizEntity;
  startRecordingVideoChat: Scalars["Boolean"]["output"];
  startRemote: Remote;
  startSpot: Spot;
  startSystemDesign: SystemDesignEntity;
  stopRecordingVideoChat: Scalars["Boolean"]["output"];
  storeWebAccessEvent: WebAccessedEvent;
  storeWebSearchEvent: WebSearchedEvent;
  submitQuizQuestion: QuizSubmission;
  switchActiveCompanyForEmployee: Scalars["Boolean"]["output"];
  /** This mutation internally includes updateClaims as well. */
  switchActiveCompanyForEmployeeV2: Scalars["Boolean"]["output"];
  switchActiveCompanyForTalent: Scalars["Boolean"]["output"];
  /** This mutation internally includes updateClaims as well. */
  switchActiveCompanyForTalentV2: Scalars["Boolean"]["output"];
  translateMarkdown: Scalars["String"]["output"];
  translatePlainText: Scalars["String"]["output"];
  updateAlgorithmQuestion: AlgorithmQuestion;
  updateAssessment: Assessment;
  /** @deprecated please use updateClaimsV2 */
  updateClaims: Scalars["Boolean"]["output"];
  updateClaimsV2: Scalars["Boolean"]["output"];
  updateCompany: Maybe<Company>;
  updateCompanyEmployee: Maybe<CompanyEmployee>;
  updateCompanySkillTags: Array<CompanySkillTag>;
  updateEmployeeInvitation: Maybe<Invitation>;
  updateEvaluationMetric: UpdateEvaluationMetricPayload;
  updateEvaluationMetricGroup: UpdateEvaluationMetricGroupPayload;
  updateFreepadQuestion: FreepadQuestion;
  updateMultiChoicePackage: MultiChoicePackage;
  updateMultiChoiceQuestion: MultiChoiceQuestion;
  updatePaymentCustomer: UpdateCustomerResponse;
  updateRemote: Remote;
  updateRemoteInterviewTemplate: RemoteInterviewTemplate;
  updateScreening: Screening;
  updateSecurity: Maybe<Security>;
  updateSlackSettings: Maybe<SlackSettings>;
  updateSpot: Maybe<Spot>;
  updateTagsToScreenings: Array<Screening>;
  updateTagsToSpots: Array<Spot>;
  updateUser: User;
  updateUserType: User;
  uploadUserImage: Scalars["String"]["output"];
};

export type MutationAcceptEmployeeInvitationArgs = {
  employeeId: Scalars["String"]["input"];
  invitationId: Scalars["String"]["input"];
};

export type MutationAcceptScreeningArgs = {
  input: AcceptScreeningInput;
};

export type MutationAcceptTalentInvitationArgs = {
  input: AcceptTalentInvitationInput;
};

export type MutationAddEmployeeToGroupsArgs = {
  companyId: Scalars["Int"]["input"];
  employeeId: Scalars["String"]["input"];
  groupIds: Array<Scalars["String"]["input"]>;
};

export type MutationAddEvaluationMetricToGroupsArgs = {
  input: AddEvaluationMetricToGroupsInput;
};

export type MutationAddEvaluationMetricsToGroupArgs = {
  input: AddEvaluationMetricsToGroupInput;
};

export type MutationAddEvaluationMetricsToGroupsArgs = {
  input: AddEvaluationMetricsToGroupsInput;
};

export type MutationAddLiveCodingSessionArgs = {
  input: AddLiveCodingSessionInput;
};

export type MutationAddLiveCodingVideoChatConversationArgs = {
  input: AddLiveCodingVideoChatConversationInput;
};

export type MutationAddLiveCodingVideoChatRoomArgs = {
  input: AddLiveCodingVideoChatRoomInput;
};

export type MutationAddMembersToGroupArgs = {
  companyId: Scalars["Int"]["input"];
  employeeIds: Array<Scalars["String"]["input"]>;
  groupId: Scalars["String"]["input"];
};

export type MutationAnalyzeRemoteInterviewTemplateArgs = {
  input: AnalyzeRemoteInterviewTemplateInput;
};

export type MutationAnalyzeTemplateArgs = {
  input: AnalyzeTemplateInput;
};

export type MutationApplyCouponArgs = {
  input: ApplyCouponInput;
};

export type MutationAskChatGptArgs = {
  input: AskChatGptInput;
};

export type MutationAuthorizeSlackArgs = {
  input: AuthorizeSlackInput;
};

export type MutationChallengeSubmitCodeArgs = {
  challengeId: Scalars["Int"]["input"];
  codeBody: Scalars["String"]["input"];
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
  runtime: Scalars["String"]["input"];
};

export type MutationChangePlanArgs = {
  input: ChangePlanInput;
};

export type MutationContractPlanArgs = {
  input: ContractPlanInput;
};

export type MutationCreateAlgorithmCorrectnessTestCaseArgs = {
  input: CreateAlgorithmCorrectnessTestCaseInput;
};

export type MutationCreateAlgorithmPerformanceTestCaseArgs = {
  input: CreateAlgorithmPerformanceTestCaseInput;
};

export type MutationCreateAlgorithmQuestionArgs = {
  createQuestionInput: CreateAlgorithmQuestionInput;
};

export type MutationCreateAssessmentArgs = {
  input: CreateAssessmentRequestInput;
};

export type MutationCreateDemoFromScreeningArgs = {
  input: CreateDemoFromScreeningInput;
};

export type MutationCreateDemoFromSpotArgs = {
  candidateId: Scalars["String"]["input"];
  spotId: Scalars["String"]["input"];
};

export type MutationCreateEmployeeInvitationArgs = {
  companyId: Scalars["Int"]["input"];
  emailAddresses: Array<Scalars["String"]["input"]>;
  employeeId: Scalars["String"]["input"];
  invitationLanguage: InvitationLanguage;
  invitationMethod: InvitationMethod;
  role: Role;
};

export type MutationCreateEvaluationMetricArgs = {
  input: CreateEvaluationMetricInput;
};

export type MutationCreateEvaluationMetricGroupArgs = {
  input: CreateEvaluationMetricGroupInput;
};

export type MutationCreateExamImmediatelyArgs = {
  input: CreateExamImmediatelyRequestInput;
};

export type MutationCreateFreepadQuestionArgs = {
  input: CreateFreepadQuestionInput;
};

export type MutationCreateGroupArgs = {
  companyId: Scalars["Int"]["input"];
  employeeIds: Array<Scalars["String"]["input"]>;
  groupName: Scalars["String"]["input"];
};

export type MutationCreateMultiChoicePackageArgs = {
  createMultiChoicePackage: CreateMultiChoicePackage;
};

export type MutationCreateMultiChoiceQuestionArgs = {
  payload: CreateMultiChoiceQuestionInput;
};

export type MutationCreateRemoteArgs = {
  input: CreateRemoteInput;
};

export type MutationCreateRemoteInterviewTemplateArgs = {
  input: CreateRemoteInterviewTemplateInput;
};

export type MutationCreateScreeningArgs = {
  input: CreateScreeningInput;
};

export type MutationCreateSpotArgs = {
  input: CreateSpotInput;
};

export type MutationCreateTalentInvitationArgs = {
  input: CreateTalentInvitationInput;
};

export type MutationDeleteAlgorithmQuestionArgs = {
  input: DeleteAlgorithmQuestionInput;
};

export type MutationDeleteAssessmentArgs = {
  input: DeleteAssessmentRequestInput;
};

export type MutationDeleteCompanyEmployeeArgs = {
  companyId: Scalars["Int"]["input"];
  employeeId: Scalars["String"]["input"];
};

export type MutationDeleteCompanyTalentArgs = {
  input: DeleteCompanyTalentInput;
};

export type MutationDeleteEvaluationMetricArgs = {
  input: DeleteEvaluationMetricInput;
};

export type MutationDeleteEvaluationMetricGroupArgs = {
  input: DeleteEvaluationMetricGroupInput;
};

export type MutationDeleteEvaluationMetricsFromGroupArgs = {
  input: DeleteEvaluationMetricsFromGroupInput;
};

export type MutationDeleteFreepadQuestionArgs = {
  input: DeleteFreepadQuestionInput;
};

export type MutationDeleteGroupArgs = {
  groupId: Scalars["String"]["input"];
};

export type MutationDeleteGroupsArgs = {
  groupIds: Array<Scalars["String"]["input"]>;
};

export type MutationDeleteLiveCodingSessionArgs = {
  input: DeleteLiveCodingSessionInput;
};

export type MutationDeleteMultiChoicePackageArgs = {
  input: DeleteMultiChoicePackageInput;
};

export type MutationDeleteMultiChoiceQuestionArgs = {
  input: DeleteMultiChoiceQuestionInput;
};

export type MutationDeleteMultiCompanyEmployeesArgs = {
  companyId: Scalars["Int"]["input"];
  employeeIds: Array<Scalars["String"]["input"]>;
};

export type MutationDeleteMultiCompanyTalentsArgs = {
  companyId: Scalars["Int"]["input"];
  talentIds: Array<Scalars["String"]["input"]>;
};

export type MutationDeleteMultiEvaluationMetricsArgs = {
  input: DeleteMultiEvaluationMetricsInput;
};

export type MutationDeleteMultiScreeningsArgs = {
  input: DeleteMultiScreeningsInput;
};

export type MutationDeleteMultiSpotsArgs = {
  input: DeleteMultiSpotsInput;
};

export type MutationDeleteProjectQuestionArgs = {
  input: DeleteProjectQuestionInput;
};

export type MutationDeleteRemoteArgs = {
  input: DeleteRemoteInput;
};

export type MutationDeleteRemoteInterviewTemplateArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteScreeningArgs = {
  input: DeleteScreeningInput;
};

export type MutationDeleteSonarArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteSpotArgs = {
  spotId: Scalars["String"]["input"];
};

export type MutationDeleteSystemDesignQuestionArgs = {
  input: DeleteSystemDesignQuestionInput;
};

export type MutationDeleteTemplateArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationEditGroupArgs = {
  groupId: Scalars["String"]["input"];
  groupName: Scalars["String"]["input"];
};

export type MutationEndDemoArgs = {
  demoId: Scalars["String"]["input"];
};

export type MutationEndExamArgs = {
  input: EndExamRequestInput;
};

export type MutationEndRemoteArgs = {
  input: EndRemoteInput;
};

export type MutationEndSpotArgs = {
  spotId: Scalars["String"]["input"];
};

export type MutationEnqueueProjectSubmissionArgs = {
  input: EnqueueProjectSubmissionInput;
};

export type MutationEnqueueSystemDesignSubmissionArgs = {
  questionId: Scalars["Int"]["input"];
  snapshot: Scalars["String"]["input"];
  systemDesignId: Scalars["Int"]["input"];
};

export type MutationEvaluateMultiSpotsArgs = {
  input: EvaluateMultiSpotsInput;
};

export type MutationEvaluateQuizSubmissionArgs = {
  input: EvaluateQuizSubmissionInput;
};

export type MutationEvaluateRemoteArgs = {
  input: EvaluateRemoteInput;
};

export type MutationEvaluateSpotArgs = {
  employeeId: Scalars["String"]["input"];
  evaluationComment: Scalars["String"]["input"];
  isPassed: Scalars["Boolean"]["input"];
  spotId: Scalars["String"]["input"];
};

export type MutationExportTestReportPdfArgs = {
  exportLanguage: ExportLanguage;
  spotId: Scalars["String"]["input"];
};

export type MutationExportTestResultTableArgs = {
  fileType: ExportFileType;
  filters: InputMaybe<SpotsByCompanyIdFilters>;
  jsonQueries: Array<JsonQuery>;
  language: ExportLanguage;
};

export type MutationFeedbackSuspiciousDegreeArgs = {
  input: FeedbackSuspiciousDegreeInput;
};

export type MutationFillRemoteInterviewTemplateArgs = {
  input: FillTemplateInput;
};

export type MutationGenerateSpotSharedLinkArgs = {
  input: GenerateSpotSharedLinkInput;
};

export type MutationInitializeAppArgs = {
  input: InitializeInput;
};

export type MutationInviteCandidateToScreeningArgs = {
  input: InviteCandidatesToScreeningInput;
};

export type MutationJoinLiveCodingArgs = {
  input: JoinLiveCodingInput;
};

export type MutationJoinVideoChatConversationArgs = {
  input: JoinVideoChatConversationInput;
};

export type MutationJoinVideoChatRoomArgs = {
  input: JoinVideoChatRoomInput;
};

export type MutationLinkSpotToSonarIntegrationArgs = {
  input: LinkSpotToSonarIntegrationInput;
};

export type MutationPurchasePackagesArgs = {
  input: PurchasePackagesInput;
};

export type MutationReadEmployeeNotificationsArgs = {
  companyId: Scalars["Int"]["input"];
  employeeId: Scalars["String"]["input"];
  employeeNotificationIds: Array<Scalars["Int"]["input"]>;
};

export type MutationReadTalentNotificationsArgs = {
  companyId: Scalars["Int"]["input"];
  talentId: Scalars["String"]["input"];
  talentNotificationIds: Array<Scalars["Int"]["input"]>;
};

export type MutationRecreateExamArgs = {
  input: RecreateExamRequestInput;
};

export type MutationRecreateSpotArgs = {
  input: RecreateSpotInput;
};

export type MutationReevaluateSystemDesignArgs = {
  evaluationSources: Array<EvaluationSourceInput>;
  submissionId: Scalars["Int"]["input"];
  systemDesignId: Scalars["Int"]["input"];
};

export type MutationRegisterSonarArgs = {
  input: RegisterSonarInput;
};

export type MutationRemoveMembersArgs = {
  companyId: Scalars["Int"]["input"];
  employeeIds: Array<Scalars["String"]["input"]>;
  groupId: Scalars["String"]["input"];
};

export type MutationReplicateScreeningArgs = {
  input: ReplicateScreening;
};

export type MutationRequestReviewAlgorithmQuestionArgs = {
  requestReviewQuestionInput: RequestReviewAlgorithmQuestionInput;
};

export type MutationResetChatGptConversationArgs = {
  input: ResetChatGptConversationInput;
};

export type MutationResetDevelopmentWorkspaceArgs = {
  input: ResetDevelopmentWorkspaceInput;
};

export type MutationResetEvaluationWorkspaceArgs = {
  input: ResetEvaluationWorkspaceInput;
};

export type MutationRetryPaymentArgs = {
  input: RetryPaymentInput;
};

export type MutationReviewExamArgs = {
  input: ReviewExamRequestInput;
};

export type MutationReviewRemoteArgs = {
  input: ReviewRemoteInput;
};

export type MutationReviewSpotArgs = {
  payload: ReviewSpotInput;
};

export type MutationRunProjectArgs = {
  input: RunProjectInput;
};

export type MutationRunSystemDesignArgs = {
  scoringItemId: Scalars["Int"]["input"];
  snapshot: Scalars["String"]["input"];
  systemDesignId: Scalars["Int"]["input"];
  takeSnapshot: Scalars["Boolean"]["input"];
};

export type MutationSaveChallengeAppealMessageArgs = {
  appealMessage: Scalars["String"]["input"];
  challengeId: Scalars["Int"]["input"];
};

export type MutationSaveProjectAppealMessageArgs = {
  input: SaveProjectAppealMessageInput;
};

export type MutationSaveSystemDesignAppealMessageArgs = {
  appealMessage: Scalars["String"]["input"];
  systemDesignId: Scalars["Int"]["input"];
};

export type MutationStartChallengeArgs = {
  candidateId: Scalars["String"]["input"];
  challengeId: Scalars["Int"]["input"];
};

export type MutationStartChallengeSessionsArgs = {
  input: StartChallengeSessionsInput;
};

export type MutationStartDevelopmentWorkspaceArgs = {
  input: StartDevelopmentWorkspaceInput;
};

export type MutationStartEvaluationWorkspaceArgs = {
  input: StartEvaluationWorkspaceInput;
};

export type MutationStartExamArgs = {
  input: StartExamRequestInput;
};

export type MutationStartLiveCodingArgs = {
  input: StartLiveCodingInput;
};

export type MutationStartProjectArgs = {
  input: StartProjectInput;
};

export type MutationStartQuizArgs = {
  candidateId: Scalars["String"]["input"];
  quizId: Scalars["Int"]["input"];
};

export type MutationStartRecordingVideoChatArgs = {
  input: StartRecordingVideoChatInput;
};

export type MutationStartRemoteArgs = {
  input: StartRemoteInput;
};

export type MutationStartSpotArgs = {
  payload: StartSpotInput;
};

export type MutationStartSystemDesignArgs = {
  candidateId: Scalars["String"]["input"];
  systemDesignId: Scalars["Int"]["input"];
};

export type MutationStopRecordingVideoChatArgs = {
  input: StopRecordingVideoChatInput;
};

export type MutationStoreWebAccessEventArgs = {
  input: StoreWebAccessEventInput;
};

export type MutationStoreWebSearchEventArgs = {
  input: StoreWebSearchEventInput;
};

export type MutationSubmitQuizQuestionArgs = {
  input: SubmitQuizQuestionInput;
};

export type MutationSwitchActiveCompanyForEmployeeArgs = {
  input: SwitchActiveCompanyForEmployeeInput;
};

export type MutationSwitchActiveCompanyForEmployeeV2Args = {
  input: SwitchActiveCompanyForEmployeeV2Input;
};

export type MutationSwitchActiveCompanyForTalentArgs = {
  input: SwitchActiveCompanyForTalentInput;
};

export type MutationSwitchActiveCompanyForTalentV2Args = {
  input: SwitchActiveCompanyForTalentV2Input;
};

export type MutationTranslateMarkdownArgs = {
  source: TranslateSource;
};

export type MutationTranslatePlainTextArgs = {
  source: TranslateSource;
};

export type MutationUpdateAlgorithmQuestionArgs = {
  updateQuestionInput: UpdateAlgorithmQuestionInput;
};

export type MutationUpdateAssessmentArgs = {
  input: UpdateAssessmentRequestInput;
};

export type MutationUpdateClaimsArgs = {
  updateClaimsInput: UpdateClaimsInput;
};

export type MutationUpdateClaimsV2Args = {
  input: UpdateClaimsV2Input;
};

export type MutationUpdateCompanyArgs = {
  companyId: Scalars["Int"]["input"];
  logo: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  websiteUrl: Scalars["String"]["input"];
};

export type MutationUpdateCompanyEmployeeArgs = {
  companyId: Scalars["Int"]["input"];
  employeeId: Scalars["String"]["input"];
  role: Role;
};

export type MutationUpdateCompanySkillTagsArgs = {
  input: UpdateCompanySkillTagsInput;
};

export type MutationUpdateEmployeeInvitationArgs = {
  invitationId: Scalars["String"]["input"];
  role: Role;
};

export type MutationUpdateEvaluationMetricArgs = {
  input: UpdateEvaluationMetricInput;
};

export type MutationUpdateEvaluationMetricGroupArgs = {
  input: UpdateEvaluationMetricGroupInput;
};

export type MutationUpdateFreepadQuestionArgs = {
  input: UpdateFreepadQuestionInput;
};

export type MutationUpdateMultiChoicePackageArgs = {
  updateMultiChoicePackage: UpdateMultiChoicePackage;
};

export type MutationUpdateMultiChoiceQuestionArgs = {
  payload: UpdateMultiChoiceQuestionInput;
};

export type MutationUpdatePaymentCustomerArgs = {
  input: UpdateCustomerInput;
};

export type MutationUpdateRemoteArgs = {
  input: UpdateRemoteInput;
};

export type MutationUpdateRemoteInterviewTemplateArgs = {
  input: UpdateRemoteInterviewTemplateInput;
};

export type MutationUpdateScreeningArgs = {
  input: UpdateScreeningInput;
};

export type MutationUpdateSecurityArgs = {
  updateSecurityInput: UpdateSecurityInput;
};

export type MutationUpdateSlackSettingsArgs = {
  companyId: Scalars["Int"]["input"];
  language: Language;
  remoteSetting: RemoteSettingsSubscribeSlackInput;
  spotSetting: SpotSettingsSubscribeSlackInput;
};

export type MutationUpdateSpotArgs = {
  input: UpdateSpotInput;
};

export type MutationUpdateTagsToScreeningsArgs = {
  input: UpdateTagsToScreeningsInput;
};

export type MutationUpdateTagsToSpotsArgs = {
  input: UpdateTagsToSpotsInput;
};

export type MutationUpdateUserArgs = {
  updateUserInput: UpdateUserInput;
};

export type MutationUpdateUserTypeArgs = {
  input: UpdateUserTypeInput;
};

export type MutationUploadUserImageArgs = {
  image: Scalars["String"]["input"];
  uid: Scalars["ID"]["input"];
};

export type NeedClientSideActionResponse = {
  __typename?: "NeedClientSideActionResponse";
  action: ClientSideInitializeAction;
};

export type Node = {
  id: Scalars["ID"]["output"];
};

export const NotificationMethod = {
  Email: "EMAIL",
  Unknown: "UNKNOWN",
} as const;

export type NotificationMethod = (typeof NotificationMethod)[keyof typeof NotificationMethod];
export type NotificationPager = {
  __typename?: "NotificationPager";
  cursor: Scalars["Int"]["output"];
  hasNext: Scalars["Boolean"]["output"];
};

export type OptionalAcceptableLimit = {
  acceptableLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type OptionalAlgorithmCorrectnessTestCaseIdsInput = {
  ids: Array<Scalars["Int"]["input"]>;
};

export type OptionalAlgorithmPerformanceTestCaseIdsInput = {
  ids: Array<Scalars["Int"]["input"]>;
};

export type OptionalDeadline = {
  deadlineSeconds?: InputMaybe<Scalars["Int"]["input"]>;
};

export type OptionalOwnerEmailAddressInput = {
  ownerEmailAddress?: InputMaybe<Scalars["String"]["input"]>;
};

export type Pager = {
  __typename?: "Pager";
  cursorSeconds: Maybe<Scalars["Int"]["output"]>;
  hasNext: Scalars["Boolean"]["output"];
};

export const PaymentCouponDurationType = {
  Once: "ONCE",
  Repeating: "REPEATING",
  Unknown: "UNKNOWN",
} as const;

export type PaymentCouponDurationType = (typeof PaymentCouponDurationType)[keyof typeof PaymentCouponDurationType];
export const PaymentCouponType = {
  AmountOff: "AMOUNT_OFF",
  PercentOff: "PERCENT_OFF",
  Unknown: "UNKNOWN",
} as const;

export type PaymentCouponType = (typeof PaymentCouponType)[keyof typeof PaymentCouponType];
export const PaymentErrorCode = {
  NotAllowedForUpdatingPaymentType: "NOT_ALLOWED_FOR_UPDATING_PAYMENT_TYPE",
} as const;

export type PaymentErrorCode = (typeof PaymentErrorCode)[keyof typeof PaymentErrorCode];
export type PaymentFeature = {
  __typename?: "PaymentFeature";
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  descriptionEn: Scalars["String"]["output"];
  descriptionJa: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  nameEn: Scalars["String"]["output"];
  nameJa: Scalars["String"]["output"];
  paymentFeatureId: Scalars["Int"]["output"];
  planFeature: PlanFeature;
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type PaymentInterview = {
  __typename?: "PaymentInterview";
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  descriptionEn: Scalars["String"]["output"];
  descriptionJa: Scalars["String"]["output"];
  generation: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  interviewId: Scalars["Int"]["output"];
  interviewKind: PaymentInterviewKind;
  nameEn: Scalars["String"]["output"];
  nameJa: Scalars["String"]["output"];
  price: Scalars["Int"]["output"];
  quantity: Scalars["Int"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export const PaymentInterviewKind = {
  InitialCost: "INITIAL_COST",
  Pack: "PACK",
  Shot: "SHOT",
  Unknown: "UNKNOWN",
} as const;

export type PaymentInterviewKind = (typeof PaymentInterviewKind)[keyof typeof PaymentInterviewKind];
export const PaymentItemType = {
  Basic: "BASIC",
  BasicV2: "BASIC_V2",
  BasicV3: "BASIC_V3",
  BasicV4: "BASIC_V4",
  BasicV5: "BASIC_V5",
  BasicV6: "BASIC_V6",
  BasicV7: "BASIC_V7",
  BasicV8: "BASIC_V8",
  BasicV9: "BASIC_V9",
  CodingInterview: "CODING_INTERVIEW",
  Enterprise: "ENTERPRISE",
  EnterpriseV6: "ENTERPRISE_V6",
  EnterpriseV7: "ENTERPRISE_V7",
  EnterpriseV8: "ENTERPRISE_V8",
  EnterpriseV9: "ENTERPRISE_V9",
  Pack: "PACK",
  Professional: "PROFESSIONAL",
  ProfessionalV2: "PROFESSIONAL_V2",
  ProfessionalV3: "PROFESSIONAL_V3",
  ProfessionalV4: "PROFESSIONAL_V4",
  ProfessionalV5: "PROFESSIONAL_V5",
  ProfessionalV6: "PROFESSIONAL_V6",
  Standard: "STANDARD",
  StandardV2: "STANDARD_V2",
  StandardV3: "STANDARD_V3",
  StandardV4: "STANDARD_V4",
  StandardV5: "STANDARD_V5",
  StandardV6: "STANDARD_V6",
  StandardV7: "STANDARD_V7",
  StandardV8: "STANDARD_V8",
  StandardV9: "STANDARD_V9",
  Unknown: "UNKNOWN",
} as const;

export type PaymentItemType = (typeof PaymentItemType)[keyof typeof PaymentItemType];
export type PaymentPager = {
  __typename?: "PaymentPager";
  cursorNanoSeconds: Maybe<Scalars["Int"]["output"]>;
  cursorSeconds: Maybe<Scalars["Int"]["output"]>;
  hasNext: Scalars["Boolean"]["output"];
};

export const PaymentPeriodType = {
  Month: "MONTH",
  Unknown: "UNKNOWN",
  Year: "YEAR",
} as const;

export type PaymentPeriodType = (typeof PaymentPeriodType)[keyof typeof PaymentPeriodType];
export type PaymentPurchase = {
  __typename?: "PaymentPurchase";
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  customerId: Scalars["Int"]["output"];
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  division: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  interview: Maybe<PaymentInterview>;
  plan: Maybe<PaymentV2Plan>;
  purchaseId: Scalars["Int"]["output"];
  quantity: Scalars["Int"]["output"];
  seat: Maybe<UserSeat>;
  totalPrice: Scalars["Int"]["output"];
  unitPrice: Scalars["Int"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export const PaymentReasonType = {
  FailedAlreadyCanceled: "FAILED_ALREADY_CANCELED",
  FailedCompanyOperation: "FAILED_COMPANY_OPERATION",
  FailedServiceDemo: "FAILED_SERVICE_DEMO",
  FailedServiceRetakingInterview: "FAILED_SERVICE_RETAKING_INTERVIEW",
  FailedServiceTrial: "FAILED_SERVICE_TRIAL",
  FailedSystemError: "FAILED_SYSTEM_ERROR",
  Success: "SUCCESS",
  Unknown: "UNKNOWN",
} as const;

export type PaymentReasonType = (typeof PaymentReasonType)[keyof typeof PaymentReasonType];
export const PaymentStatus = {
  Paid: "PAID",
  Suspended: "SUSPENDED",
  Unknown: "UNKNOWN",
  Unpaid: "UNPAID",
} as const;

export type PaymentStatus = (typeof PaymentStatus)[keyof typeof PaymentStatus];
export const PaymentType = {
  Card: "CARD",
  Invoice: "INVOICE",
  Unknown: "UNKNOWN",
} as const;

export type PaymentType = (typeof PaymentType)[keyof typeof PaymentType];
export type PaymentUsage = {
  __typename?: "PaymentUsage";
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  interviewId: Scalars["String"]["output"];
  interviewType: InterviewType;
  isBillingTarget: Scalars["Boolean"]["output"];
  price: Scalars["Int"]["output"];
  reasonType: PaymentReasonType;
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  usageId: Scalars["Int"]["output"];
};

export type PaymentUsagesResponse = {
  __typename?: "PaymentUsagesResponse";
  count: Maybe<Scalars["Int"]["output"]>;
  pager: Maybe<PaymentPager>;
  usages: Array<PaymentUsage>;
};

/** https://stripe.com/docs/payments/customer-balance/funding-instructions?country=jp#create-funding-instructions */
export const PaymentV2AccountType = {
  Futsu: "FUTSU",
  Toza: "TOZA",
} as const;

export type PaymentV2AccountType = (typeof PaymentV2AccountType)[keyof typeof PaymentV2AccountType];
/** TODO RENAME to "Customer" after Payment v1 is deprecated */
export type PaymentV2Customer = {
  __typename?: "PaymentV2Customer";
  accountHolderName: Scalars["String"]["output"];
  accountNumber: Scalars["String"]["output"];
  accountType: PaymentV2AccountType;
  attribute: Scalars["String"]["output"];
  bankCode: Scalars["String"]["output"];
  bankName: Scalars["String"]["output"];
  branchCode: Scalars["String"]["output"];
  branchName: Scalars["String"]["output"];
  city: Scalars["String"]["output"];
  country: Country;
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  customerId: Scalars["Int"]["output"];
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  email: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  isAutoRenewal: Scalars["Boolean"]["output"];
  isTrial: Scalars["Boolean"]["output"];
  language: Language;
  lastFour: Scalars["String"]["output"];
  line1: Scalars["String"]["output"];
  line2: Scalars["String"]["output"];
  paymentMethod: Scalars["String"]["output"];
  paymentStatus: PaymentStatus;
  paymentType: PaymentType;
  postalCode: Scalars["String"]["output"];
  state: Scalars["String"]["output"];
  subscription: PaymentV2Subscription;
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

/** TODO RENAME to "Invoice" after Payment v1 is deprecated */
export type PaymentV2Invoice = {
  __typename?: "PaymentV2Invoice";
  attribute: Scalars["String"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  customerId: Scalars["Int"]["output"];
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  dueDateSeconds: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  invoiceId: Scalars["Int"]["output"];
  invoiceUrl: Scalars["String"]["output"];
  status: InvoiceStatus;
  totalPrice: Scalars["Int"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

/** TODO RENAME to "PaymentPlan" after Payment v1 is deprecated */
export type PaymentV2Plan = {
  __typename?: "PaymentV2Plan";
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  descriptionEn: Scalars["String"]["output"];
  descriptionJa: Scalars["String"]["output"];
  enabledChangePlan: Scalars["Boolean"]["output"];
  enabledPurchasePack: Scalars["Boolean"]["output"];
  features: Array<PaymentFeature>;
  generation: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  nameEn: Scalars["String"]["output"];
  nameJa: Scalars["String"]["output"];
  nextGeneration: Scalars["Int"]["output"];
  planId: Scalars["Int"]["output"];
  planType: PlanType;
  price: Scalars["Int"]["output"];
  term: Scalars["Int"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

/** TODO RENAME to "PaymentSubscription" after Payment v1 is deprecated */
export type PaymentV2Subscription = {
  __typename?: "PaymentV2Subscription";
  child: Maybe<PaymentV2Subscription>;
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  customerId: Scalars["Int"]["output"];
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  didStartAtSeconds: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  nextPaymentAtSeconds: Maybe<Scalars["Int"]["output"]>;
  originId: Scalars["Int"]["output"];
  packStock: Scalars["Int"]["output"];
  periodType: PaymentPeriodType;
  plan: PaymentV2Plan;
  shotInterview: PaymentInterview;
  status: PaymentV2SubscriptionStatus;
  subscribedCoupons: Array<SubscribedCoupon>;
  subscriptionId: Scalars["Int"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  willEndAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export const PaymentV2SubscriptionStatus = {
  Active: "ACTIVE",
  Inactive: "INACTIVE",
  Unknown: "UNKNOWN",
} as const;

export type PaymentV2SubscriptionStatus = (typeof PaymentV2SubscriptionStatus)[keyof typeof PaymentV2SubscriptionStatus];
export const PlanFeature = {
  Algorithm: "ALGORITHM",
  AtsIntegration: "ATS_INTEGRATION",
  Detection: "DETECTION",
  LiveCoding: "LIVE_CODING",
  PlayBack: "PLAY_BACK",
  Project: "PROJECT",
  PublicApi: "PUBLIC_API",
  Quiz: "QUIZ",
  RelativeEvaluation: "RELATIVE_EVALUATION",
  RemoveBias: "REMOVE_BIAS",
  SlackNotification: "SLACK_NOTIFICATION",
  SystemDesign: "SYSTEM_DESIGN",
  TechnicalReview: "TECHNICAL_REVIEW",
  Unknown: "UNKNOWN",
} as const;

export type PlanFeature = (typeof PlanFeature)[keyof typeof PlanFeature];
export const PlanType = {
  Basic: "BASIC",
  Enterprise: "ENTERPRISE",
  Professional: "PROFESSIONAL",
  Standard: "STANDARD",
} as const;

export type PlanType = (typeof PlanType)[keyof typeof PlanType];
export type PlansResponse = {
  __typename?: "PlansResponse";
  plans: Array<PaymentV2Plan>;
};

export type ProjectAnswer = {
  __typename?: "ProjectAnswer";
  answerId: Scalars["Int"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  descriptionEn: Scalars["String"]["output"];
  descriptionJa: Scalars["String"]["output"];
  fileIndexList: Array<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  questionId: Scalars["Int"]["output"];
  questionVersion: Scalars["String"]["output"];
  titleEn: Scalars["String"]["output"];
  titleJa: Scalars["String"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type ProjectAnswerFileBody = {
  __typename?: "ProjectAnswerFileBody";
  fileBody: Scalars["String"]["output"];
};

export type ProjectDiff = Node & {
  __typename?: "ProjectDiff";
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  fromLink: Scalars["String"]["output"];
  fromPath: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  operation: ProjectOperation;
  projectDiffId: Scalars["Int"]["output"];
  projectId: Scalars["Int"]["output"];
  questionId: Scalars["Int"]["output"];
  questionVersion: Scalars["String"]["output"];
  submissionId: Scalars["Int"]["output"];
  toLink: Scalars["String"]["output"];
  toPath: Scalars["String"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type ProjectEntity = Node & {
  __typename?: "ProjectEntity";
  agentVersion: Scalars["String"]["output"];
  appealMessage: Maybe<Scalars["String"]["output"]>;
  baseHash: Scalars["String"]["output"];
  candidateId: Scalars["String"]["output"];
  companyId: Scalars["Int"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  didEndAtSeconds: Maybe<Scalars["Int"]["output"]>;
  didStartAtSeconds: Maybe<Scalars["Int"]["output"]>;
  diffs: Array<ProjectDiff>;
  employeeId: Scalars["String"]["output"];
  entityId: Scalars["Int"]["output"];
  evaluatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  /** @deprecated Field no longer supported */
  projectEntityId: Scalars["Int"]["output"];
  question: Maybe<ProjectQuestion>;
  snapshots: Array<ProjectSnapshot>;
  status: ProjectStatus;
  submissions: Array<ProjectSubmission>;
  timeLimitSeconds: Maybe<Scalars["Int"]["output"]>;
  totalScore: Scalars["Float"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export const ProjectFailureReason = {
  EvaluationPreparationFailed: "EVALUATION_PREPARATION_FAILED",
  ServerHealthCheckFailed: "SERVER_HEALTH_CHECK_FAILED",
  Unknown: "UNKNOWN",
  WorkspacePreparationFailed: "WORKSPACE_PREPARATION_FAILED",
} as const;

export type ProjectFailureReason = (typeof ProjectFailureReason)[keyof typeof ProjectFailureReason];
export type ProjectFileDiff = {
  __typename?: "ProjectFileDiff";
  fromBody: Scalars["String"]["output"];
  fromPath: Scalars["String"]["output"];
  operation: ProjectOperation;
  toBody: Scalars["String"]["output"];
  toPath: Scalars["String"]["output"];
};

export type ProjectInitialFileBodyResponse = {
  __typename?: "ProjectInitialFileBodyResponse";
  fileBody: Scalars["String"]["output"];
};

export const ProjectOperation = {
  Addition: "ADDITION",
  Deletion: "DELETION",
  Modification: "MODIFICATION",
  Rename: "RENAME",
  Unknown: "UNKNOWN",
} as const;

export type ProjectOperation = (typeof ProjectOperation)[keyof typeof ProjectOperation];
export type ProjectQuestion = {
  __typename?: "ProjectQuestion";
  accuracyRate: Scalars["Float"]["output"];
  answers: Array<ProjectAnswer>;
  averageElapsedTimeSeconds: Scalars["Float"]["output"];
  companyId: Scalars["Int"]["output"];
  correctnessTestCase: Scalars["String"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  defaultTestCase: Scalars["String"]["output"];
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  descriptionEn: Maybe<Scalars["String"]["output"]>;
  descriptionJa: Maybe<Scalars["String"]["output"]>;
  developmentBuildScript: Scalars["String"]["output"];
  difficulty: Difficulty;
  enableHotReload: Scalars["Boolean"]["output"];
  evaluationBuildScript: Scalars["String"]["output"];
  fileIndexes: Array<Scalars["String"]["output"]>;
  healthCheckEndpoint: Scalars["String"]["output"];
  healthCheckScript: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  ignoreFiles: Array<Scalars["String"]["output"]>;
  isOfficial: Scalars["Boolean"]["output"];
  isPrivate: Scalars["Boolean"]["output"];
  isSupportedLeakScore: Scalars["Boolean"]["output"];
  key: Scalars["String"]["output"];
  leakScore: Maybe<LeakScore>;
  numUses: Scalars["Int"]["output"];
  performanceTestCase: Scalars["String"]["output"];
  projectVersion: Scalars["String"]["output"];
  questionId: Scalars["Int"]["output"];
  /**
   * パターンマッチで返ってくることもある
   * @see https://github.com/hireroo/project-questions/blob/main/questions/105/v1.0.0/question.yaml#L225
   */
  readOnlyFiles: Array<Scalars["String"]["output"]>;
  scoreDistributionBins: Array<Scalars["Int"]["output"]>;
  shutdownScript: Scalars["String"]["output"];
  skillTagIds: Array<Scalars["String"]["output"]>;
  skillTags: Array<SkillTagNode>;
  startupScript: Scalars["String"]["output"];
  status: ProjectQuestionStatus;
  syncDir: Scalars["String"]["output"];
  timeLimitSeconds: Maybe<Scalars["Int"]["output"]>;
  titleEn: Maybe<Scalars["String"]["output"]>;
  titleJa: Maybe<Scalars["String"]["output"]>;
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  variant: ProjectQuestionVariant;
  version: Scalars["String"]["output"];
  watchFiles: Array<Scalars["String"]["output"]>;
};

export type ProjectQuestionInput = {
  questionId: Scalars["Int"]["input"];
  /** questionVersion should be strict but temporarily make optional to account for requests from older clients */
  questionVersion?: InputMaybe<Scalars["String"]["input"]>;
};

export const ProjectQuestionStatus = {
  Archived: "ARCHIVED",
  Published: "PUBLISHED",
} as const;

export type ProjectQuestionStatus = (typeof ProjectQuestionStatus)[keyof typeof ProjectQuestionStatus];
export const ProjectQuestionVariant = {
  Backend: "Backend",
  DataScience: "DataScience",
  Default: "Default",
  Frontend: "Frontend",
  Unknown: "UNKNOWN",
} as const;

export type ProjectQuestionVariant = (typeof ProjectQuestionVariant)[keyof typeof ProjectQuestionVariant];
export type ProjectSnapshot = Node & {
  __typename?: "ProjectSnapshot";
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  failureReason: ProjectFailureReason;
  id: Scalars["ID"]["output"];
  isPassed: Scalars["Boolean"]["output"];
  projectId: Scalars["Int"]["output"];
  projectSnapshotId: Scalars["Int"]["output"];
  questionId: Scalars["Int"]["output"];
  questionVersion: Scalars["String"]["output"];
  status: ProjectSnapshotStatus;
  testCase: Scalars["String"]["output"];
  testResult: Scalars["String"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export const ProjectSnapshotStatus = {
  Evaluated: "EVALUATED",
  Failed: "FAILED",
  Unknown: "UNKNOWN",
} as const;

export type ProjectSnapshotStatus = (typeof ProjectSnapshotStatus)[keyof typeof ProjectSnapshotStatus];
export type ProjectStatistics = {
  __typename?: "ProjectStatistics";
  avgLatency: Maybe<StatisticsStats>;
  avgSla: Maybe<StatisticsStats>;
  bins: Array<Scalars["Int"]["output"]>;
  coverage: Maybe<StatisticsStats>;
  elapsedTime: Maybe<StatisticsStats>;
  numSnapshots: Maybe<StatisticsStats>;
  numTabEvents: Maybe<StatisticsStats>;
  performance: Maybe<StatisticsStats>;
  totalScore: Maybe<StatisticsStats>;
};

export const ProjectStatus = {
  Completed: "COMPLETED",
  Created: "CREATED",
  Evaluated: "EVALUATED",
  Started: "STARTED",
  Unknown: "UNKNOWN",
} as const;

export type ProjectStatus = (typeof ProjectStatus)[keyof typeof ProjectStatus];
export type ProjectSubmission = Node & {
  __typename?: "ProjectSubmission";
  avgLatency: Scalars["Int"]["output"];
  avgSla: Scalars["Float"]["output"];
  builtAtSeconds: Maybe<Scalars["Int"]["output"]>;
  companyId: Scalars["Int"]["output"];
  correctnessTestResult: Scalars["String"]["output"];
  coverage: Scalars["Float"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  diffs: Array<ProjectDiff>;
  elapsedTimeSeconds: Maybe<Scalars["Int"]["output"]>;
  enqueuedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  evaluatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  failedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  failureReason: ProjectFailureReason;
  id: Scalars["ID"]["output"];
  isBest: Scalars["Boolean"]["output"];
  metrics: Scalars["String"]["output"];
  numAccessEvents: Scalars["Int"]["output"];
  numPassed: Scalars["Int"]["output"];
  numSubset: Scalars["Int"]["output"];
  numTabEvents: Scalars["Int"]["output"];
  numTests: Scalars["Int"]["output"];
  performance: Scalars["Float"]["output"];
  performanceTestResult: Scalars["String"]["output"];
  projectId: Scalars["Int"]["output"];
  projectSubmissionId: Scalars["Int"]["output"];
  questionId: Scalars["Int"]["output"];
  questionVersion: Scalars["String"]["output"];
  snapshots: Array<ProjectSnapshot>;
  status: ProjectSubmissionStatus;
  testResult: Scalars["String"]["output"];
  totalElapsedTimeSeconds: Maybe<Scalars["Int"]["output"]>;
  totalScore: Scalars["Float"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export const ProjectSubmissionReason = {
  BuildFailed: "BUILD_FAILED",
  CleanUpFailed: "CLEAN_UP_FAILED",
  EvaluationFailed: "EVALUATION_FAILED",
  PreparationFailed: "PREPARATION_FAILED",
  Unknown: "UNKNOWN",
} as const;

export type ProjectSubmissionReason = (typeof ProjectSubmissionReason)[keyof typeof ProjectSubmissionReason];
export const ProjectSubmissionStatus = {
  Enqueued: "ENQUEUED",
  Evaluated: "EVALUATED",
  Failed: "FAILED",
  Unknown: "UNKNOWN",
} as const;

export type ProjectSubmissionStatus = (typeof ProjectSubmissionStatus)[keyof typeof ProjectSubmissionStatus];
export type ProjectWorkspace = {
  __typename?: "ProjectWorkspace";
  agentPort: Scalars["Int"]["output"];
  clientPort: Scalars["Int"]["output"];
  cpu: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  memory: Scalars["String"]["output"];
  storage: Scalars["String"]["output"];
  uri: Scalars["String"]["output"];
};

export type PurchasePackageInput = {
  packageId: Scalars["Int"]["input"];
  quantity: Scalars["Int"]["input"];
};

export type PurchasePackagesInput = {
  customerId: Scalars["Int"]["input"];
  purchasePackages: Array<PurchasePackageInput>;
};

export type PurchasesResponse = {
  __typename?: "PurchasesResponse";
  count: Scalars["Int"]["output"];
  pager: Maybe<PaymentPager>;
  purchases: Array<PaymentPurchase>;
};

export type Query = {
  __typename?: "Query";
  activeCompany: Company;
  algorithmCorrectnessTestCase: AlgorithmCorrectnessTestCase;
  algorithmPerformanceTestCase: AlgorithmPerformanceTestCase;
  /** TODO Make this a mutation */
  algorithmProfileCode: AlgorithmProfileCodeResponse;
  algorithmQuestion: AlgorithmQuestion;
  /** TODO Make this a mutation */
  algorithmRunCode: AlgorithmRunCodeResponse;
  /** @deprecated Use the initialCode of the AlgorithmQuestion. */
  algorithmTemplateCode: AlgorithmTemplateCodeResponse;
  answerFileBody: ProjectAnswerFileBody;
  assessment: Assessment;
  assessmentListOptionsByFields: AssessmentListOptionsByFieldsResponse;
  assignedSkillTagIds: AssignedSkillTagIdsResponse;
  behavioralEventLogs: BehavioralEventLogs;
  buyAblePacks: BuyAblePacksResponse;
  challengeEntity: ChallengeEntity;
  challengeRunCode: Maybe<ChallengeRunCodeResponse>;
  challengeSubmission: ChallengeSubmission;
  /** @deprecated Use the initialCode of the AlgorithmQuestion. */
  challengeTemplateCode: ChallengeTemplateCodeResponse;
  companiesByEmployeeId: ListCompanies;
  companiesByTalentId: CompaniesByTalentId;
  company: Company;
  companyByEmployeeInvitation: Company;
  companyByTalentInvitationId: Company;
  /** TODO RENAME to "contractPeriodUsagesV2" after Payment v1 is deprecated */
  contractPeriodUsagesV2: ContractPeriodUsagesResponse;
  couponByCode: Maybe<Coupon>;
  currentUser: User;
  customer: PaymentV2Customer;
  demo: Demo;
  employeeInvitation: Invitation;
  employeeNotifications: EmployeeNotificationsResponse;
  employees: Array<User>;
  /** evaluation metric */
  evaluationMetric: EvaluationMetric;
  /** evaluation metric group */
  evaluationMetricGroup: EvaluationMetricGroup;
  evaluationMetricGroupsByCompany: Maybe<EvaluationMetricGroupsByCompanyResponse>;
  evaluationMetricsByCompany: Maybe<EvaluationMetricsByCompanyResponse>;
  evaluationMetricsByIds: Array<EvaluationMetric>;
  exam: Exam;
  /** rpc: ListByFilter */
  fieldsForFilter: Maybe<SpotFieldsForFilterResponse>;
  fileDiff: Maybe<ProjectFileDiff>;
  freepadQuestion: FreepadQuestion;
  hierarchicalSkillTags: HierarchicalSkillTagsResponse;
  /** TODO RENAME to "invoices" after Payment v1 is deprecated */
  invoicesV2: InvoicesResponse;
  /**
   * use fieldsForFilter instead
   * @deprecated Field no longer supported
   */
  issuers: Maybe<IssuersResponse>;
  issuersForRemoteByFields: IssuersForRemoteByFieldsResponse;
  listAssessmentsByCompanyId: ListAssessmentsByCompanyId;
  listAssessmentsByTalentId: ListAssessmentsByTalentId;
  listBestExamsByAssessmentId: ListBestExamsByAssessmentId;
  listExamsRecursive: ListExamsRecursivePayload;
  liveCoding: LiveCoding;
  liveCodingTemplateCodes: LiveCodingTemplateCodes;
  mostExpensivePurchase: Maybe<PaymentPurchase>;
  multiChoicePackage: Maybe<MultiChoicePackage>;
  multiChoiceQuestions: MultiChoiceQuestionsResponse;
  multiChoiceTags: MultiChoiceTagsResponse;
  multiQuestions: Array<QuestionObject>;
  paymentInitialCost: Maybe<PaymentInterview>;
  paymentUsages: PaymentUsagesResponse;
  plans: PlansResponse;
  projectEntity: ProjectEntity;
  projectQuestion: Maybe<ProjectQuestion>;
  projectQuestionInitialFileBody: ProjectInitialFileBodyResponse;
  projectSubmission: ProjectSubmission;
  purchases: PurchasesResponse;
  questionPackage: QuestionPackage;
  questionPackages: QuestionPackagesResponse;
  questions: Questions;
  quizEntity: QuizEntity;
  rank: StatisticsRank;
  recentScreenings: Array<Screening>;
  remote: Remote;
  remoteInterviewTemplate: RemoteInterviewTemplate;
  remoteInterviewTemplatesByCompanyId: Maybe<RemoteInterviewTemplatesByCompanyIdResponse>;
  remoteTagsByCompanyId: RemoteTagsByCompanyIdResponse;
  remotesByCompanyId: RemotesByCompanyIdResponse;
  remotesForSchedule: Array<Remote>;
  screening: Screening;
  screeningInvitees: ScreeningInviteesResponse;
  screeningVariablesByCompanyId: Maybe<ScreeningVariablesByCompanyIdResponse>;
  screeningsByCompanyId: Maybe<ScreeningsByCompanyIdResponse>;
  securityByCompanyId: Security;
  securityByEmail: Security;
  skillTags: SkillTagsResponse;
  slackSettings: Maybe<SlackSettings>;
  sonarIntegrations: SonarIntegrationsResponse;
  spot: Spot;
  spotFilterOptionsByScreeningId: Maybe<SpotFilterOptionsByScreeningIdResponse>;
  spotFromSharedLink: Spot;
  spotStatsByCompanyId: SpotStats;
  spotTagsByCompanyId: Maybe<SpotTagsByCompanyIdResponse>;
  spotsByCompanyId: Maybe<SpotsByCompanyIdResponse>;
  spotsByScreeningId: SpotsByScreeningIdResponse;
  spotsForSchedule: Array<Spot>;
  statistics: Statistics;
  systemDesignEntity: SystemDesignEntity;
  systemDesignQuestion: SystemDesignQuestion;
  systemDesignSubmission: SystemDesignSubmission;
  tailExam: Exam;
  talentInvitation: TalentInvitation;
  talentNotifications: TalentNotificationsResponse;
  testReportPdf: ExportResponse;
  testResultTable: ExportResponse;
  usagesV2: UsagesV2Response;
  videoChatConversation: Maybe<VideoChatConversation>;
  videoChatRoom: Maybe<VideoChatRoom>;
};

export type QueryActiveCompanyArgs = {
  memberType: CompanyMemberType;
  userId: Scalars["String"]["input"];
};

export type QueryAlgorithmCorrectnessTestCaseArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryAlgorithmPerformanceTestCaseArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryAlgorithmProfileCodeArgs = {
  codeBody: Scalars["String"]["input"];
  runtime: Scalars["String"]["input"];
  signature: Scalars["String"]["input"];
  tcInput: Scalars["String"]["input"];
  tcOutput: Scalars["String"]["input"];
  variant: AlgorithmQuestionVariant;
};

export type QueryAlgorithmQuestionArgs = {
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
  withAnswers: Scalars["Boolean"]["input"];
  withPerformanceTcs: Scalars["Boolean"]["input"];
};

export type QueryAlgorithmRunCodeArgs = {
  codeBody: Scalars["String"]["input"];
  runtime: Scalars["String"]["input"];
  signature: Scalars["String"]["input"];
  tcInput: Scalars["String"]["input"];
  tcOutput: Scalars["String"]["input"];
  variant: AlgorithmQuestionVariant;
};

export type QueryAlgorithmTemplateCodeArgs = {
  signature: Scalars["String"]["input"];
  variant: AlgorithmQuestionVariant;
};

export type QueryAnswerFileBodyArgs = {
  answerId: Scalars["Int"]["input"];
  filePath: Scalars["String"]["input"];
};

export type QueryAssessmentArgs = {
  assessmentId: Scalars["String"]["input"];
};

export type QueryAssessmentListOptionsByFieldsArgs = {
  companyId: Scalars["Int"]["input"];
  withTalents: Scalars["Boolean"]["input"];
  withViewers: Scalars["Boolean"]["input"];
};

export type QueryAssignedSkillTagIdsArgs = {
  companyId: Scalars["Int"]["input"];
};

export type QueryBehavioralEventLogsArgs = {
  input: BehavioralEventLogsInput;
};

export type QueryBuyAblePacksArgs = {
  customerId: Scalars["Int"]["input"];
};

export type QueryChallengeEntityArgs = {
  challengeId: Scalars["Int"]["input"];
  withAnswers: Scalars["Boolean"]["input"];
};

export type QueryChallengeRunCodeArgs = {
  challengeId: Scalars["Int"]["input"];
  codeBody: Scalars["String"]["input"];
  input: Scalars["String"]["input"];
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
  runtime: Scalars["String"]["input"];
  takeSnapshot: Scalars["Boolean"]["input"];
};

export type QueryChallengeSubmissionArgs = {
  submissionId: Scalars["Int"]["input"];
};

export type QueryChallengeTemplateCodeArgs = {
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
};

export type QueryCompaniesByEmployeeIdArgs = {
  cursorSeconds: InputMaybe<Scalars["Int"]["input"]>;
  size: Scalars["Int"]["input"];
  uid: Scalars["String"]["input"];
  withActiveId: Scalars["Boolean"]["input"];
};

export type QueryCompaniesByTalentIdArgs = {
  cursorSeconds: InputMaybe<Scalars["Int"]["input"]>;
  size: Scalars["Int"]["input"];
  talentId: Scalars["String"]["input"];
  withActiveId: Scalars["Boolean"]["input"];
};

export type QueryCompanyArgs = {
  companyId: Scalars["Int"]["input"];
};

export type QueryCompanyByEmployeeInvitationArgs = {
  invitationId: Scalars["String"]["input"];
};

export type QueryCompanyByTalentInvitationIdArgs = {
  invitationId: Scalars["String"]["input"];
};

export type QueryContractPeriodUsagesV2Args = {
  contractPeriod: Scalars["Int"]["input"];
  customerId: Scalars["Int"]["input"];
  startedAtSeconds: Scalars["Int"]["input"];
};

export type QueryCouponByCodeArgs = {
  couponCode: Scalars["String"]["input"];
};

export type QueryCurrentUserArgs = {
  uid: Scalars["String"]["input"];
};

export type QueryCustomerArgs = {
  customerId: Scalars["Int"]["input"];
};

export type QueryDemoArgs = {
  candidateId: Scalars["String"]["input"];
  demoId: Scalars["String"]["input"];
};

export type QueryEmployeeInvitationArgs = {
  invitationId: Scalars["String"]["input"];
};

export type QueryEmployeeNotificationsArgs = {
  companyId: Scalars["Int"]["input"];
  cursor: InputMaybe<Scalars["Int"]["input"]>;
  employeeId: Scalars["String"]["input"];
  size: Scalars["Int"]["input"];
  withCount: Scalars["Boolean"]["input"];
};

export type QueryEmployeesArgs = {
  uids: Array<Scalars["String"]["input"]>;
};

export type QueryEvaluationMetricArgs = {
  metricId: Scalars["Int"]["input"];
};

export type QueryEvaluationMetricGroupArgs = {
  metricGroupId: Scalars["Int"]["input"];
};

export type QueryEvaluationMetricGroupsByCompanyArgs = {
  companyId: Scalars["Int"]["input"];
  cursor: InputMaybe<Scalars["Int"]["input"]>;
  isDescending: InputMaybe<Scalars["Boolean"]["input"]>;
  size: Scalars["Int"]["input"];
  withCount: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryEvaluationMetricsByCompanyArgs = {
  companyId: Scalars["Int"]["input"];
  cursor: InputMaybe<Scalars["Int"]["input"]>;
  size: Scalars["Int"]["input"];
  withCount: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryEvaluationMetricsByIdsArgs = {
  metricIds: Array<Scalars["Int"]["input"]>;
};

export type QueryExamArgs = {
  examId: Scalars["String"]["input"];
};

export type QueryFieldsForFilterArgs = {
  input: SpotFieldsForFilterInput;
};

export type QueryFileDiffArgs = {
  filePath: Scalars["String"]["input"];
  hash: Scalars["String"]["input"];
  projectId: Scalars["Int"]["input"];
  submissionId: Scalars["Int"]["input"];
};

export type QueryFreepadQuestionArgs = {
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
};

export type QueryHierarchicalSkillTagsArgs = {
  input: HierarchicalSkillTagsInput;
};

export type QueryInvoicesV2Args = {
  cursorNanoSeconds: InputMaybe<Scalars["Int"]["input"]>;
  cursorSeconds: InputMaybe<Scalars["Int"]["input"]>;
  customerId: Scalars["Int"]["input"];
  size: Scalars["Int"]["input"];
};

export type QueryIssuersArgs = {
  issuersInput: IssuersInput;
};

export type QueryIssuersForRemoteByFieldsArgs = {
  companyId: Scalars["Int"]["input"];
  creator: Scalars["Boolean"]["input"];
  viewer: Scalars["Boolean"]["input"];
};

export type QueryListAssessmentsByCompanyIdArgs = {
  companyId: Scalars["Int"]["input"];
  filters: AssessmentFilters;
  isDescending: Scalars["Boolean"]["input"];
  offset: Scalars["Int"]["input"];
  size: Scalars["Int"]["input"];
  sortMethod: AssessmentSortMethod;
  withCount: Scalars["Boolean"]["input"];
  withDeleted: Scalars["Boolean"]["input"];
};

export type QueryListAssessmentsByTalentIdArgs = {
  isDescending: Scalars["Boolean"]["input"];
  offset: Scalars["Int"]["input"];
  size: Scalars["Int"]["input"];
  talentId: Scalars["String"]["input"];
  withCount: Scalars["Boolean"]["input"];
  withDeleted: Scalars["Boolean"]["input"];
};

export type QueryListBestExamsByAssessmentIdArgs = {
  assessmentId: Scalars["String"]["input"];
  isDescending: Scalars["Boolean"]["input"];
  offset: Scalars["Int"]["input"];
  size: Scalars["Int"]["input"];
  withCount: Scalars["Boolean"]["input"];
};

export type QueryListExamsRecursiveArgs = {
  examId: Scalars["String"]["input"];
};

export type QueryLiveCodingArgs = {
  liveCodingId: Scalars["Int"]["input"];
};

export type QueryLiveCodingTemplateCodesArgs = {
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
};

export type QueryMostExpensivePurchaseArgs = {
  cursorSeconds: InputMaybe<Scalars["Int"]["input"]>;
  customerId: Scalars["Int"]["input"];
  purchasePeriodEndSeconds: InputMaybe<Scalars["Int"]["input"]>;
  purchasePeriodStartSeconds: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryMultiChoicePackageArgs = {
  id: Scalars["Int"]["input"];
  version: Scalars["String"]["input"];
  withAnswers: Scalars["Boolean"]["input"];
};

export type QueryMultiChoiceQuestionsArgs = {
  multiChoiceQuestionsInput: MultiChoiceQuestionsInput;
};

export type QueryMultiChoiceTagsArgs = {
  multiChoiceTagsInput: MultiChoiceTagsInput;
};

export type QueryMultiQuestionsArgs = {
  compositePrimaryKeys: Array<QuestionCompositePrimaryKey>;
  withAnswers: Scalars["Boolean"]["input"];
};

export type QueryPaymentInitialCostArgs = {
  planGeneration: Scalars["Int"]["input"];
};

export type QueryPaymentUsagesArgs = {
  cursorSeconds: InputMaybe<Scalars["Int"]["input"]>;
  customerId: Scalars["Int"]["input"];
  size: Scalars["Int"]["input"];
  usagePeriodStartSeconds: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryPlansArgs = {
  customerId: Scalars["Int"]["input"];
};

export type QueryProjectEntityArgs = {
  projectId: Scalars["Int"]["input"];
  withAnswers: Scalars["Boolean"]["input"];
};

export type QueryProjectQuestionArgs = {
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
  withAnswers: Scalars["Boolean"]["input"];
};

export type QueryProjectQuestionInitialFileBodyArgs = {
  filePath: Scalars["String"]["input"];
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
};

export type QueryProjectSubmissionArgs = {
  submissionId: Scalars["Int"]["input"];
};

export type QueryPurchasesArgs = {
  cursorNanoSeconds: InputMaybe<Scalars["Int"]["input"]>;
  cursorSeconds: InputMaybe<Scalars["Int"]["input"]>;
  customerId: Scalars["Int"]["input"];
  isAscending: Scalars["Boolean"]["input"];
  purchasePeriodEndSeconds: Scalars["Int"]["input"];
  purchasePeriodStartSeconds: Scalars["Int"]["input"];
  size: Scalars["Int"]["input"];
};

export type QueryQuestionPackageArgs = {
  questionPackageId: Scalars["String"]["input"];
};

export type QueryQuestionPackagesArgs = {
  filters: QuestionPackageFilters;
  isDescending: Scalars["Boolean"]["input"];
  offset: Scalars["Int"]["input"];
  size: Scalars["Int"]["input"];
  sortMethod: QuestionPackageSortMethod;
};

export type QueryQuestionsArgs = {
  questionsInput: QuestionsInput;
};

export type QueryQuizEntityArgs = {
  quizId: Scalars["Int"]["input"];
  withAnswers: Scalars["Boolean"]["input"];
};

export type QueryRankArgs = {
  rankSource: RankSourceInput;
};

export type QueryRecentScreeningsArgs = {
  companyId: Scalars["Int"]["input"];
  size: Scalars["Int"]["input"];
};

export type QueryRemoteArgs = {
  remoteId: Scalars["String"]["input"];
};

export type QueryRemoteInterviewTemplateArgs = {
  templateId: Scalars["Int"]["input"];
};

export type QueryRemoteInterviewTemplatesByCompanyIdArgs = {
  input: RemoteInterviewTemplatesByCompanyIdInput;
};

export type QueryRemoteTagsByCompanyIdArgs = {
  companyId: Scalars["Int"]["input"];
  cursorSeconds: InputMaybe<Scalars["Int"]["input"]>;
  size: Scalars["Int"]["input"];
  withCount: Scalars["Boolean"]["input"];
};

export type QueryRemotesByCompanyIdArgs = {
  companyId: Scalars["Int"]["input"];
  filters: InputMaybe<RemoteFilterInput>;
  isDescending: Scalars["Boolean"]["input"];
  offset: Scalars["Int"]["input"];
  size: Scalars["Int"]["input"];
  sortMethod: RemoteSortMethod;
  withCount: Scalars["Boolean"]["input"];
  withDeleted: Scalars["Boolean"]["input"];
};

export type QueryRemotesForScheduleArgs = {
  companyId: Scalars["Int"]["input"];
  size: Scalars["Int"]["input"];
};

export type QueryScreeningArgs = {
  invitationCode: InputMaybe<Scalars["String"]["input"]>;
  screeningId: Scalars["String"]["input"];
  withSpotStats?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryScreeningInviteesArgs = {
  input: ScreeningInviteesInput;
};

export type QueryScreeningVariablesByCompanyIdArgs = {
  screeningVariablesByCompanyId: ScreeningVariablesByCompanyIdInput;
};

export type QueryScreeningsByCompanyIdArgs = {
  input: ScreeningsByCompanyIdInput;
};

export type QuerySecurityByCompanyIdArgs = {
  activeCompanyId: Scalars["Int"]["input"];
};

export type QuerySecurityByEmailArgs = {
  email: Scalars["String"]["input"];
};

export type QuerySkillTagsArgs = {
  input: SkillTagsInput;
};

export type QuerySlackSettingsArgs = {
  companyId: Scalars["Int"]["input"];
};

export type QuerySonarIntegrationsArgs = {
  companyId: Scalars["Int"]["input"];
  cursorSeconds: InputMaybe<Scalars["Int"]["input"]>;
  size: Scalars["Int"]["input"];
  withCount: Scalars["Boolean"]["input"];
};

export type QuerySpotArgs = {
  id: Scalars["String"]["input"];
  ignoreInheritance?: InputMaybe<Scalars["Boolean"]["input"]>;
  withAnswers: Scalars["Boolean"]["input"];
};

export type QuerySpotFilterOptionsByScreeningIdArgs = {
  input: SpotFilterOptionsByScreeningInput;
};

export type QuerySpotFromSharedLinkArgs = {
  linkHash: Scalars["String"]["input"];
  spotId: Scalars["String"]["input"];
};

export type QuerySpotStatsByCompanyIdArgs = {
  companyId: Scalars["Int"]["input"];
};

export type QuerySpotTagsByCompanyIdArgs = {
  spotTagsByCompanyId: SpotTagsByCompanyIdInput;
};

export type QuerySpotsByCompanyIdArgs = {
  spotsByCompanyId: SpotsByCompanyIdInput;
};

export type QuerySpotsByScreeningIdArgs = {
  input: SpotsByScreeningIdInput;
};

export type QuerySpotsForScheduleArgs = {
  companyId: Scalars["Int"]["input"];
  size: Scalars["Int"]["input"];
};

export type QueryStatisticsArgs = {
  statisticsSource: StatisticsSourceInput;
};

export type QuerySystemDesignEntityArgs = {
  systemDesignId: Scalars["Int"]["input"];
  withAnswers: Scalars["Boolean"]["input"];
};

export type QuerySystemDesignQuestionArgs = {
  questionId: Scalars["Int"]["input"];
  withAnswers: Scalars["Boolean"]["input"];
};

export type QuerySystemDesignSubmissionArgs = {
  submissionId: Scalars["Int"]["input"];
};

export type QueryTailExamArgs = {
  examId: Scalars["String"]["input"];
};

export type QueryTalentInvitationArgs = {
  invitationId: Scalars["String"]["input"];
};

export type QueryTalentNotificationsArgs = {
  companyId: Scalars["Int"]["input"];
  cursor: InputMaybe<Scalars["Int"]["input"]>;
  size: Scalars["Int"]["input"];
  talentId: Scalars["String"]["input"];
  withCount: Scalars["Boolean"]["input"];
};

export type QueryTestReportPdfArgs = {
  id: Scalars["String"]["input"];
};

export type QueryTestResultTableArgs = {
  id: Scalars["String"]["input"];
};

export type QueryUsagesV2Args = {
  cursorNanoSeconds: InputMaybe<Scalars["Int"]["input"]>;
  cursorSeconds: InputMaybe<Scalars["Int"]["input"]>;
  customerId: Scalars["Int"]["input"];
  size: Scalars["Int"]["input"];
  usagePeriodStartSeconds: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryVideoChatConversationArgs = {
  input: VideoChatConversationInput;
};

export type QueryVideoChatRoomArgs = {
  input: VideoChatRoomInput;
};

export type Question = AlgorithmQuestion | FreepadQuestion | MultiChoicePackage | ProjectQuestion | SystemDesignQuestion;

export type QuestionCompositePrimaryKey = {
  questionId: Scalars["Int"]["input"];
  questionVariant: QuestionVariant;
  version: Scalars["String"]["input"];
};

export type QuestionIdentity = {
  __typename?: "QuestionIdentity";
  questionId: Scalars["Int"]["output"];
  questionType: QuestionType;
  questionVariant: QuestionVariant;
  questionVersion: Scalars["String"]["output"];
};

export const QuestionLeakScoreLevel = {
  High: "HIGH",
  Low: "LOW",
  Medium: "MEDIUM",
} as const;

export type QuestionLeakScoreLevel = (typeof QuestionLeakScoreLevel)[keyof typeof QuestionLeakScoreLevel];
export type QuestionObject = Node & {
  __typename?: "QuestionObject";
  id: Scalars["ID"]["output"];
  liveCodingQuestion: Maybe<LiveCodingQuestion>;
  question: Question;
  questionObjectId: Scalars["String"]["output"];
  questionVariant: QuestionVariant;
  spotQuestion: Maybe<SpotQuestion>;
  version: Maybe<Scalars["String"]["output"]>;
};

export type QuestionPackage = Node & {
  __typename?: "QuestionPackage";
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  descriptionEn: Scalars["String"]["output"];
  descriptionJa: Scalars["String"]["output"];
  difficulty: QuestionPackageDifficulty;
  id: Scalars["ID"]["output"];
  questionCount: Scalars["Int"]["output"];
  questionIdentities: Array<QuestionIdentity>;
  questionObjects: Array<QuestionObject>;
  questionPackageId: Scalars["String"]["output"];
  titleEn: Scalars["String"]["output"];
  titleJa: Scalars["String"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export const QuestionPackageDifficulty = {
  Difficult: "DIFFICULT",
  Easy: "EASY",
  Medium: "MEDIUM",
} as const;

export type QuestionPackageDifficulty = (typeof QuestionPackageDifficulty)[keyof typeof QuestionPackageDifficulty];
export type QuestionPackageFilters = {
  difficulties: Array<QuestionPackageDifficulty>;
  questionVariants: Array<QuestionVariant>;
  titleEn: Scalars["String"]["input"];
  titleJa: Scalars["String"]["input"];
};

export const QuestionPackageSortMethod = {
  MethodCreatedAt: "METHOD_CREATED_AT",
} as const;

export type QuestionPackageSortMethod = (typeof QuestionPackageSortMethod)[keyof typeof QuestionPackageSortMethod];
export type QuestionPackagesResponse = {
  __typename?: "QuestionPackagesResponse";
  count: Scalars["Int"]["output"];
  hasNext: Scalars["Boolean"]["output"];
  packages: Array<QuestionPackage>;
};

export const QuestionStatus = {
  Archived: "ARCHIVED",
  Draft: "DRAFT",
  Published: "PUBLISHED",
  Rejected: "REJECTED",
  Reviewing: "REVIEWING",
  Unknown: "UNKNOWN",
} as const;

export type QuestionStatus = (typeof QuestionStatus)[keyof typeof QuestionStatus];
export const QuestionType = {
  Algorithm: "ALGORITHM",
  Freepad: "FREEPAD",
  MultiChoice: "MULTI_CHOICE",
  Project: "PROJECT",
  SystemDesign: "SYSTEM_DESIGN",
} as const;

export type QuestionType = (typeof QuestionType)[keyof typeof QuestionType];
export const QuestionVariant = {
  Challenge: "CHALLENGE",
  ChallengeAlgorithm: "CHALLENGE_ALGORITHM",
  ChallengeClass: "CHALLENGE_CLASS",
  ChallengeDatabase: "CHALLENGE_DATABASE",
  Freepad: "FREEPAD",
  Project: "PROJECT",
  ProjectBackend: "PROJECT_BACKEND",
  ProjectDataScience: "PROJECT_DATA_SCIENCE",
  ProjectFrontend: "PROJECT_FRONTEND",
  ProjectOther: "PROJECT_OTHER",
  Quiz: "QUIZ",
  SystemDesign: "SYSTEM_DESIGN",
} as const;

export type QuestionVariant = (typeof QuestionVariant)[keyof typeof QuestionVariant];
export type Questions = {
  __typename?: "Questions";
  count: Scalars["Int"]["output"];
  hasNext: Scalars["Boolean"]["output"];
  questionObjects: Array<QuestionObject>;
  /** @deprecated Please DO NOT USE this property. */
  questions: Array<QuestionObject>;
};

export type QuestionsInput = {
  filters: Filters;
  isDescending: Scalars["Boolean"]["input"];
  offset: Scalars["Int"]["input"];
  size: Scalars["Int"]["input"];
  sortMethod: SortMethod;
  withCount: Scalars["Boolean"]["input"];
};

export type QuizEntity = Node & {
  __typename?: "QuizEntity";
  backend: Maybe<QuizMetric>;
  candidateId: Scalars["String"]["output"];
  companyId: Scalars["Int"]["output"];
  computerScience: Maybe<QuizMetric>;
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  didEndAtSeconds: Maybe<Scalars["Int"]["output"]>;
  didStartAtSeconds: Maybe<Scalars["Int"]["output"]>;
  elapsedTimeSeconds: Maybe<Scalars["Int"]["output"]>;
  employeeId: Scalars["String"]["output"];
  entityId: Scalars["Int"]["output"];
  evaluatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  frontend: Maybe<QuizMetric>;
  id: Scalars["ID"]["output"];
  infrastructure: Maybe<QuizMetric>;
  machineLearning: Maybe<QuizMetric>;
  mobile: Maybe<QuizMetric>;
  numSubset: Scalars["Int"]["output"];
  operatingSystem: Maybe<QuizMetric>;
  packageId: Scalars["Int"]["output"];
  packageVersion: Scalars["String"]["output"];
  pb_package: Maybe<MultiChoicePackage>;
  /** @deprecated Field no longer supported */
  quizEntityId: Scalars["Int"]["output"];
  security: Maybe<QuizMetric>;
  status: QuizStatus;
  submissions: Array<QuizSubmission>;
  timeLimitSeconds: Maybe<Scalars["Int"]["output"]>;
  totalScore: Scalars["Float"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type QuizEntityPb_PackageArgs = {
  withAnswers: Scalars["Boolean"]["input"];
};

export type QuizEvaluation = {
  __typename?: "QuizEvaluation";
  evaluationItem: Maybe<MultiChoiceEvaluationItem>;
  isPassed: Scalars["Boolean"]["output"];
  reasonEn: Scalars["String"]["output"];
  reasonJa: Scalars["String"]["output"];
  score: Scalars["Int"]["output"];
  submissionId: Scalars["Int"]["output"];
};

export type QuizEvaluationItem = {
  __typename?: "QuizEvaluationItem";
  descriptionEn: Maybe<Scalars["String"]["output"]>;
  descriptionJa: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  questionId: Scalars["Int"]["output"];
  questionVersion: Scalars["String"]["output"];
  titleEn: Maybe<Scalars["String"]["output"]>;
  titleJa: Maybe<Scalars["String"]["output"]>;
};

export type QuizEvaluationItemInput = {
  id: Scalars["Int"]["input"];
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
};

export type QuizEvaluationsInput = {
  evaluationItem: QuizEvaluationItemInput;
  isPassed: Scalars["Boolean"]["input"];
  submissionId: Scalars["Int"]["input"];
};

export type QuizMetric = {
  __typename?: "QuizMetric";
  correctRate: Scalars["Float"]["output"];
  numCorrectAnswers: Scalars["Int"]["output"];
  numNotSubmitted: Scalars["Int"]["output"];
  numQuestions: Scalars["Int"]["output"];
  numScoreItems: Scalars["Int"]["output"];
  numSkippedAnswers: Scalars["Int"]["output"];
  numSubmissions: Scalars["Int"]["output"];
  numWrongAnswers: Scalars["Int"]["output"];
  scoreRate: Scalars["Float"]["output"];
  submissions: Array<QuizSubmission>;
  totalScore: Scalars["Float"]["output"];
};

export type QuizPackageInput = {
  packageId: Scalars["Int"]["input"];
  packageVersion: Scalars["String"]["input"];
};

export type QuizStatistics = {
  __typename?: "QuizStatistics";
  bins: Array<Scalars["Int"]["output"]>;
  elapsedTime: Maybe<StatisticsStats>;
  numTabEvents: Maybe<StatisticsStats>;
  totalScore: Maybe<StatisticsStats>;
};

export const QuizStatus = {
  Completed: "COMPLETED",
  Created: "CREATED",
  Evaluated: "EVALUATED",
  Started: "STARTED",
  Unknown: "UNKNOWN",
} as const;

export type QuizStatus = (typeof QuizStatus)[keyof typeof QuizStatus];
export type QuizSubmission = Node & {
  __typename?: "QuizSubmission";
  answerText: Scalars["String"]["output"];
  companyId: Scalars["Int"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  elapsedTimeSeconds: Maybe<Scalars["Int"]["output"]>;
  evaluatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  evaluatedBy: Scalars["String"]["output"];
  evaluatedUser: Maybe<User>;
  evaluations: Array<QuizEvaluation>;
  id: Scalars["ID"]["output"];
  isAutomaticEvaluation: Scalars["Boolean"]["output"];
  isCorrect: Scalars["Boolean"]["output"];
  numAccessEvents: Scalars["Int"]["output"];
  numTabEvents: Scalars["Int"]["output"];
  optionIds: Array<Scalars["Int"]["output"]>;
  packageId: Scalars["Int"]["output"];
  packageVersion: Scalars["String"]["output"];
  questionId: Scalars["Int"]["output"];
  questionVersion: Scalars["String"]["output"];
  quizId: Scalars["Int"]["output"];
  quizSubmissionId: Scalars["Int"]["output"];
  score: Scalars["Int"]["output"];
  status: QuizSubmissionStatus;
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export const QuizSubmissionStatus = {
  Created: "CREATED",
  Evaluated: "EVALUATED",
  Unknown: "UNKNOWN",
} as const;

export type QuizSubmissionStatus = (typeof QuizSubmissionStatus)[keyof typeof QuizSubmissionStatus];
export const RankEvaluation = {
  A: "A",
  B: "B",
  C: "C",
  D: "D",
  S: "S",
  Unknown: "UNKNOWN",
} as const;

export type RankEvaluation = (typeof RankEvaluation)[keyof typeof RankEvaluation];
export type RankSourceInput = {
  category: StatisticsCategory;
  query?: InputMaybe<StatisticsRankQueryInput>;
};

export type RecommendationEvaluationInput = {
  comment: Scalars["String"]["input"];
  companyId: Scalars["Int"]["input"];
  employeeId: Scalars["String"]["input"];
  metricId: Scalars["Int"]["input"];
  numStars: Scalars["Int"]["input"];
};

export type RecreateExamRequestInput = {
  examId: Scalars["String"]["input"];
};

export const RecreateReason = {
  InsufficientTimeLimit: "INSUFFICIENT_TIME_LIMIT",
  InterviewMisdelivery: "INTERVIEW_MISDELIVERY",
  Other: "OTHER",
  SystemError: "SYSTEM_ERROR",
  Unknown: "UNKNOWN",
} as const;

export type RecreateReason = (typeof RecreateReason)[keyof typeof RecreateReason];
export type RecreateSpotInput = {
  completedEntityIds: Array<SpotEntityIdInput>;
  elapsedTimeSeconds: Scalars["Int"]["input"];
  recreateReason: RecreateReason;
  recreateReasonComment?: InputMaybe<Scalars["String"]["input"]>;
  spotId: Scalars["String"]["input"];
  willEndAtSeconds: Scalars["Int"]["input"];
};

export type ReevaluateSystemDesignResponse = {
  __typename?: "ReevaluateSystemDesignResponse";
  evaluation: Maybe<SystemDesignEvaluation>;
  submission: Maybe<SystemDesignSubmission>;
};

export type RegisterSonarInput = {
  companyId: Scalars["Int"]["input"];
  screeningId: Scalars["String"]["input"];
};

export type Remote = {
  __typename?: "Remote";
  /** @deprecated Field no longer supported */
  candidate: Maybe<User>;
  candidateName: Scalars["String"]["output"];
  company: Maybe<Company>;
  companyId: Scalars["Int"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  creator: Maybe<User>;
  creatorId: Scalars["String"]["output"];
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  didEndAtSeconds: Maybe<Scalars["Int"]["output"]>;
  didStartAtSeconds: Maybe<Scalars["Int"]["output"]>;
  entity: Maybe<RemoteEntity>;
  entitySource: Maybe<RemoteEntitySource>;
  evaluatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  evaluatedBy: Maybe<User>;
  evaluationComment: Scalars["String"]["output"];
  evaluatorId: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  isPassed: Scalars["Boolean"]["output"];
  isPublic: Scalars["Boolean"]["output"];
  memo: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  recommendationIds: Array<Scalars["Int"]["output"]>;
  recommendations: Array<EvaluationRecommendation>;
  remoteId: Scalars["String"]["output"];
  status: RemoteStatus;
  tags: Array<RemoteTag>;
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  viewerSources: Array<RemoteViewerSource>;
  viewers: Array<RemoteViewer>;
};

export type RemoteAlgorithmQuestionInput = {
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
};

export type RemoteEntity = {
  __typename?: "RemoteEntity";
  entityType: RemoteEntityType;
  liveCoding: Maybe<LiveCoding>;
};

export type RemoteEntitySource = {
  __typename?: "RemoteEntitySource";
  entityId: Scalars["Int"]["output"];
  entityType: RemoteEntityType;
};

export type RemoteEntitySourceInput = {
  algorithmQuestion?: InputMaybe<RemoteAlgorithmQuestionInput>;
  entityType: RemoteEntityType;
  freePadQuestion?: InputMaybe<RemoteFreePadQuestionInput>;
  systemDesignQuestion?: InputMaybe<RemoteSystemDesignQuestionInput>;
  variant: RemoteQuestionVariant;
};

export const RemoteEntityType = {
  LiveCoding: "LIVE_CODING",
  Unknown: "UNKNOWN",
} as const;

export type RemoteEntityType = (typeof RemoteEntityType)[keyof typeof RemoteEntityType];
export type RemoteFilterInput = {
  createdByList: Array<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  result?: InputMaybe<Scalars["Boolean"]["input"]>;
  statuses: Array<RemoteStatus>;
  tagNames: Array<Scalars["String"]["input"]>;
};

export type RemoteFreePadQuestionInput = {
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
};

export type RemoteInterviewReviewerInput = {
  employeeId?: InputMaybe<Scalars["String"]["input"]>;
  groupId?: InputMaybe<Scalars["String"]["input"]>;
  type: ReviewerType;
};

export type RemoteInterviewReviewerSource = {
  __typename?: "RemoteInterviewReviewerSource";
  reviewerId: Scalars["String"]["output"];
  reviewerType: RemoteReviewerType;
};

export type RemoteInterviewTemplate = Node & {
  __typename?: "RemoteInterviewTemplate";
  candidateName: Scalars["String"]["output"];
  companyId: Scalars["Int"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  createdBy: Scalars["String"]["output"];
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  isPublic: Scalars["Boolean"]["output"];
  memo: Maybe<Scalars["String"]["output"]>;
  questions: Array<RemoteInterviewTemplateEntitySource>;
  remoteName: Scalars["String"]["output"];
  reviewerSources: Array<RemoteInterviewReviewerSource>;
  reviewers: Array<RemoteReviewer>;
  tagNames: Array<Scalars["String"]["output"]>;
  templateId: Scalars["Int"]["output"];
  templateName: Scalars["String"]["output"];
  templateQuestions: Array<RemoteInterviewTemplateQuestion>;
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  updatedBy: Scalars["String"]["output"];
  variables: Maybe<Array<TemplateVariable>>;
};

export type RemoteInterviewTemplateTemplateQuestionsArgs = {
  withAnswers: Scalars["Boolean"]["input"];
};

export type RemoteInterviewTemplateEntitySource = {
  __typename?: "RemoteInterviewTemplateEntitySource";
  challengeQuestion: Maybe<TemplateChallengeQuestionSource>;
  entityType: Maybe<RemoteInterviewTemplateEntityType>;
  freepadQuestion: Maybe<TemplateFreepadQuestionSource>;
  questionId: Scalars["Int"]["output"];
  questionKey: Scalars["String"]["output"];
  systemDesignQuestion: Maybe<TemplateSystemDesignQuestionSource>;
};

export const RemoteInterviewTemplateEntityType = {
  Challenge: "CHALLENGE",
  Freepad: "FREEPAD",
  SystemDesign: "SYSTEM_DESIGN",
  Unknown: "UNKNOWN",
} as const;

export type RemoteInterviewTemplateEntityType = (typeof RemoteInterviewTemplateEntityType)[keyof typeof RemoteInterviewTemplateEntityType];
/** For remote */
export type RemoteInterviewTemplateQuestion = AlgorithmQuestion | FreepadQuestion | SystemDesignQuestion;

export type RemoteInterviewTemplatesByCompanyIdInput = {
  companyId: Scalars["Int"]["input"];
  idCursor?: InputMaybe<Scalars["Int"]["input"]>;
  size: Scalars["Int"]["input"];
  withCount: Scalars["Boolean"]["input"];
};

export type RemoteInterviewTemplatesByCompanyIdResponse = {
  __typename?: "RemoteInterviewTemplatesByCompanyIdResponse";
  count: Scalars["Int"]["output"];
  pager: Maybe<TemplatePager>;
  templates: Array<RemoteInterviewTemplate>;
};

export type RemotePager = {
  __typename?: "RemotePager";
  cursorNanoSeconds: Maybe<Scalars["Int"]["output"]>;
  cursorSeconds: Maybe<Scalars["Int"]["output"]>;
  hasNext: Scalars["Boolean"]["output"];
};

export const RemoteQuestionVariant = {
  Algorithm: "ALGORITHM",
  Class: "CLASS",
  Database: "DATABASE",
  EmptyCanvas: "EMPTY_CANVAS",
  EmptyPad: "EMPTY_PAD",
  SystemDesign: "SYSTEM_DESIGN",
  Unknown: "UNKNOWN",
} as const;

export type RemoteQuestionVariant = (typeof RemoteQuestionVariant)[keyof typeof RemoteQuestionVariant];
/** TODO: @ksrnnb delete RemoteReviewer after template service shut down */
export type RemoteReviewer = {
  __typename?: "RemoteReviewer";
  employee: Maybe<User>;
  group: Maybe<EmployeeGroup>;
  type: ReviewerType;
};

/** TODO: @ksrnnb delete RemoteReviewerType after template service shut down */
export const RemoteReviewerType = {
  Employee: "EMPLOYEE",
  EmployeeGroup: "EMPLOYEE_GROUP",
  Unknown: "UNKNOWN",
} as const;

export type RemoteReviewerType = (typeof RemoteReviewerType)[keyof typeof RemoteReviewerType];
export type RemoteSettingsSubscribeSlack = {
  __typename?: "RemoteSettingsSubscribeSlack";
  subscribeRemoteAwaiting: Scalars["Boolean"]["output"];
  subscribeRemoteCompleted: Scalars["Boolean"]["output"];
  subscribeRemoteCreated: Scalars["Boolean"]["output"];
  subscribeRemoteDeleted: Scalars["Boolean"]["output"];
  subscribeRemoteEvaluated: Scalars["Boolean"]["output"];
  subscribeRemoteReviewed: Scalars["Boolean"]["output"];
  subscribeRemoteStarted: Scalars["Boolean"]["output"];
};

export type RemoteSettingsSubscribeSlackInput = {
  subscribeRemoteAwaiting: Scalars["Boolean"]["input"];
  subscribeRemoteCompleted: Scalars["Boolean"]["input"];
  subscribeRemoteCreated: Scalars["Boolean"]["input"];
  subscribeRemoteDeleted: Scalars["Boolean"]["input"];
  subscribeRemoteEvaluated: Scalars["Boolean"]["input"];
  subscribeRemoteReviewed: Scalars["Boolean"]["input"];
  subscribeRemoteStarted: Scalars["Boolean"]["input"];
};

export const RemoteSortMethod = {
  CreatedAt: "CREATED_AT",
  Unknown: "UNKNOWN",
} as const;

export type RemoteSortMethod = (typeof RemoteSortMethod)[keyof typeof RemoteSortMethod];
export const RemoteStatus = {
  Completed: "COMPLETED",
  Created: "CREATED",
  Evaluated: "EVALUATED",
  Started: "STARTED",
  Unknown: "UNKNOWN",
} as const;

export type RemoteStatus = (typeof RemoteStatus)[keyof typeof RemoteStatus];
export type RemoteSystemDesignQuestionInput = {
  questionId: Scalars["Int"]["input"];
};

export type RemoteTag = {
  __typename?: "RemoteTag";
  companyId: Scalars["Int"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  name: Scalars["String"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type RemoteTagsByCompanyIdResponse = {
  __typename?: "RemoteTagsByCompanyIdResponse";
  count: Scalars["Int"]["output"];
  pager: Maybe<RemotePager>;
  tags: Array<RemoteTag>;
};

export type RemoteViewer = EmployeeGroup | User;

export type RemoteViewerIdInput = {
  employeeId?: InputMaybe<Scalars["String"]["input"]>;
  groupId?: InputMaybe<Scalars["String"]["input"]>;
  type: RemoteViewerType;
};

export type RemoteViewerSource = {
  __typename?: "RemoteViewerSource";
  viewerId: Scalars["String"]["output"];
  viewerType: RemoteViewerType;
};

export const RemoteViewerType = {
  Employee: "EMPLOYEE",
  Group: "GROUP",
} as const;

export type RemoteViewerType = (typeof RemoteViewerType)[keyof typeof RemoteViewerType];
export type RemotesByCompanyIdResponse = {
  __typename?: "RemotesByCompanyIdResponse";
  count: Scalars["Int"]["output"];
  hasNext: Scalars["Boolean"]["output"];
  remotes: Array<Remote>;
};

export type ReplicateScreening = {
  replicatedBy: Scalars["String"]["input"];
  screeningId: Scalars["String"]["input"];
};

export type RequestReviewAlgorithmQuestionInput = {
  questionSlug: Scalars["String"]["input"];
  questionVersion: Scalars["String"]["input"];
};

export type ResetChatGptConversationInput = {
  chatId: Scalars["String"]["input"];
  sessionId: Scalars["String"]["input"];
};

export type ResetDevelopmentWorkspaceInput = {
  projectId: Scalars["Int"]["input"];
  withSourceCode: Scalars["Boolean"]["input"];
};

export type ResetEvaluationWorkspaceInput = {
  submissionId: Scalars["Int"]["input"];
  withSourceCode: Scalars["Boolean"]["input"];
};

export type RetryPaymentInput = {
  customerId: Scalars["Int"]["input"];
};

export type ReviewExamRequestInput = {
  comment: Scalars["String"]["input"];
  companyId: Scalars["Int"]["input"];
  employeeId: Scalars["String"]["input"];
  evaluations: Array<ViewExamEvaluationInput>;
  examId: Scalars["String"]["input"];
  isRecommended: Scalars["Boolean"]["input"];
};

export type ReviewRemoteEvaluationInput = {
  comment: Scalars["String"]["input"];
  metricId: Scalars["Int"]["input"];
  numStars: Scalars["Int"]["input"];
};

export type ReviewRemoteInput = {
  comment: Scalars["String"]["input"];
  companyId: Scalars["Int"]["input"];
  employeeId: Scalars["String"]["input"];
  evaluations: Array<ReviewRemoteEvaluationInput>;
  isRecommended: Scalars["Boolean"]["input"];
  remoteId: Scalars["String"]["input"];
};

export type ReviewSpotEvaluationInput = {
  comment: Scalars["String"]["input"];
  metricId: Scalars["Int"]["input"];
  numStars: Scalars["Int"]["input"];
};

export type ReviewSpotInput = {
  comment: Scalars["String"]["input"];
  companyId: Scalars["Int"]["input"];
  employeeId: Scalars["String"]["input"];
  evaluations: Array<ReviewSpotEvaluationInput>;
  isRecommended: Scalars["Boolean"]["input"];
  spotId: Scalars["String"]["input"];
};

export type ReviewerIdInput = {
  employeeId?: InputMaybe<Scalars["String"]["input"]>;
  groupId?: InputMaybe<Scalars["String"]["input"]>;
  type: RemoteReviewerType;
};

export const ReviewerType = {
  Employee: "EMPLOYEE",
  EmployeeGroup: "EMPLOYEE_GROUP",
  Unknown: "UNKNOWN",
} as const;

export type ReviewerType = (typeof ReviewerType)[keyof typeof ReviewerType];
export const Role = {
  Admin: "ADMIN",
  Candidate: "CANDIDATE",
  Employee: "EMPLOYEE",
  Engineer: "ENGINEER",
  Manager: "MANAGER",
  Unknown: "UNKNOWN",
} as const;

export type Role = (typeof Role)[keyof typeof Role];
export type RoomToken = {
  __typename?: "RoomToken";
  token: Scalars["String"]["output"];
};

export const RoutingType = {
  Auth: "AUTH",
  Candidate: "CANDIDATE",
  Employee: "EMPLOYEE",
  InvitationForEmployee: "INVITATION_FOR_EMPLOYEE",
  InvitationForTalent: "INVITATION_FOR_TALENT",
  NotFound: "NOT_FOUND",
  ResetPassword: "RESET_PASSWORD",
  Root: "ROOT",
  SignInEmployeeOrTalent: "SIGN_IN_EMPLOYEE_OR_TALENT",
  SignUpEmployeeOrTalent: "SIGN_UP_EMPLOYEE_OR_TALENT",
  Talent: "TALENT",
} as const;

export type RoutingType = (typeof RoutingType)[keyof typeof RoutingType];
export type RunProjectInput = {
  projectId: Scalars["Int"]["input"];
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
  takeSnapshot: Scalars["Boolean"]["input"];
  testCase: Scalars["String"]["input"];
  workspaceId: Scalars["String"]["input"];
};

export type RunSystemDesignResponse = {
  __typename?: "RunSystemDesignResponse";
  isPassed: Scalars["Boolean"]["output"];
  matchedIds: Array<Scalars["String"]["output"]>;
  messageEn: Scalars["String"]["output"];
  messageJa: Scalars["String"]["output"];
  snapshotId: Scalars["Int"]["output"];
};

export const SsoMode = {
  Loose: "LOOSE",
  Strict: "STRICT",
  Unknown: "UNKNOWN",
} as const;

export type SsoMode = (typeof SsoMode)[keyof typeof SsoMode];
export type SaveProjectAppealMessageInput = {
  appealMessage: Scalars["String"]["input"];
  projectId: Scalars["Int"]["input"];
};

export type Screening = Node & {
  __typename?: "Screening";
  acceptableLimit: Maybe<Scalars["Int"]["output"]>;
  /** Status for test takers to know if they can be screened without knowing how many people are available or have already taken the test. */
  acceptanceStatus: ScreeningAcceptanceStatus;
  allowChatGPT: Scalars["Boolean"]["output"];
  allowHint: Scalars["Boolean"]["output"];
  allowWebSearch: Scalars["Boolean"]["output"];
  candidateAccessPolicy: ScreeningCandidateAccessPolicy;
  company: Maybe<Company>;
  companyId: Scalars["Int"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  createdBy: Maybe<User>;
  creatorId: Scalars["String"]["output"];
  deadlineSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  description: Scalars["String"]["output"];
  entitySourceQuestions: Array<ScreeningEntitySourceQuestion>;
  entitySources: Array<ScreeningEntitySource>;
  entityTracks: Array<ScreeningEntityTrack>;
  id: Scalars["ID"]["output"];
  invitationLanguage: Language;
  invitees: Array<ScreeningInvitee>;
  isActive: Scalars["Boolean"]["output"];
  memo: Maybe<Scalars["String"]["output"]>;
  messageForCandidate: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  numAccepted: Scalars["Int"]["output"];
  ownerEmailAddress: Maybe<Scalars["String"]["output"]>;
  questionCount: Scalars["Int"]["output"];
  questions: Array<ScreeningQuestion>;
  screeningId: Scalars["String"]["output"];
  spotIsPublic: Scalars["Boolean"]["output"];
  spotStatistics: Maybe<SpotStats>;
  /** @deprecated spotStats should be replaced by spotStatistics */
  spotStats: Maybe<ScreeningSpotStats>;
  tags: Array<SpotTag>;
  timeLimitSeconds: Scalars["Int"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  variables: Array<ScreeningVariable>;
  viewerSources: Array<SpotViewerSource>;
  viewers: Array<SpotViewer>;
};

export type ScreeningEntitySourceQuestionsArgs = {
  withAnswers: Scalars["Boolean"]["input"];
};

export type ScreeningEntityTracksArgs = {
  withAnswers: Scalars["Boolean"]["input"];
};

export type ScreeningQuestionsArgs = {
  withAnswers: Scalars["Boolean"]["input"];
};

export const ScreeningAcceptanceStatus = {
  Accepting: "ACCEPTING",
  Overcapacity: "OVERCAPACITY",
  SubmitDeadlineExceeded: "SUBMIT_DEADLINE_EXCEEDED",
  Suspended: "SUSPENDED",
} as const;

export type ScreeningAcceptanceStatus = (typeof ScreeningAcceptanceStatus)[keyof typeof ScreeningAcceptanceStatus];
export const ScreeningActiveStatus = {
  Active: "ACTIVE",
  NotActive: "NOT_ACTIVE",
} as const;

export type ScreeningActiveStatus = (typeof ScreeningActiveStatus)[keyof typeof ScreeningActiveStatus];
export type ScreeningBooleanFieldVariable = {
  __typename?: "ScreeningBooleanFieldVariable";
  validationRule: ScreeningBooleanFieldVariableValidationRule;
};

export type ScreeningBooleanFieldVariableValidationRule = {
  __typename?: "ScreeningBooleanFieldVariableValidationRule";
  required: Scalars["Boolean"]["output"];
};

export type ScreeningBooleanValidationRuleInput = {
  max?: InputMaybe<Scalars["Int"]["input"]>;
  min?: InputMaybe<Scalars["Int"]["input"]>;
  required: Scalars["Boolean"]["input"];
};

export const ScreeningCandidateAccessPolicy = {
  AllowAll: "ALLOW_ALL",
  RestrictedByInvitationCode: "RESTRICTED_BY_INVITATION_CODE",
} as const;

export type ScreeningCandidateAccessPolicy = (typeof ScreeningCandidateAccessPolicy)[keyof typeof ScreeningCandidateAccessPolicy];
export type ScreeningChallengeEntitySourceQuestion = {
  __typename?: "ScreeningChallengeEntitySourceQuestion";
  enabledLanguages: Array<Scalars["String"]["output"]>;
  question: AlgorithmQuestion;
  questionId: Scalars["Int"]["output"];
  /** This field may contain the string "latest"; do not use it for Map Keys, etc. */
  questionVersion: Scalars["String"]["output"];
  questionWeight: Scalars["Int"]["output"];
};

/** This field is only used inside the BFF. */
export type ScreeningEntitySource = {
  __typename?: "ScreeningEntitySource";
  challengeQuestion: Maybe<SpotChallengeQuestionSource>;
  entityType: SpotEntityType;
  projectQuestion: Maybe<SpotProjectQuestionSource>;
  questionKey: Scalars["String"]["output"];
  questionWeight: Scalars["Int"]["output"];
  quizPackage: Maybe<SpotQuizPackageSource>;
  systemDesignQuestion: Maybe<SpotSystemDesignQuestionSource>;
};

export type ScreeningEntitySourceQuestion =
  | ScreeningChallengeEntitySourceQuestion
  | ScreeningProjectEntitySourceQuestion
  | ScreeningQuizEntitySourcePackage
  | ScreeningSystemDesignEntitySourceQuestion;

export type ScreeningEntityTrack =
  | ScreeningFixedEntityTrack
  | ScreeningRandomFromQuestionsEntityTrack
  | ScreeningSelectableFromQuestionsEntityTrack;

export type ScreeningFilledVariableInput = {
  value?: InputMaybe<ScreeningGenericValueInput>;
  variableId: Scalars["Int"]["input"];
};

export type ScreeningFixedEntityTrack = Node & {
  __typename?: "ScreeningFixedEntityTrack";
  entitySourceQuestion: ScreeningEntitySourceQuestion;
  id: Scalars["ID"]["output"];
  questionScoreWeight: Scalars["Int"]["output"];
  trackId: Scalars["Int"]["output"];
};

export type ScreeningFixedEntityTrackEntitySourceQuestionArgs = {
  withAnswers: Scalars["Boolean"]["input"];
};

export const ScreeningFormType = {
  InputNumber: "INPUT_NUMBER",
  InputText: "INPUT_TEXT",
  Textarea: "TEXTAREA",
} as const;

export type ScreeningFormType = (typeof ScreeningFormType)[keyof typeof ScreeningFormType];
export type ScreeningGenericValue = {
  __typename?: "ScreeningGenericValue";
  booleanValue: Maybe<Scalars["Boolean"]["output"]>;
  integerValue: Maybe<Scalars["Int"]["output"]>;
  stringValue: Maybe<Scalars["String"]["output"]>;
  stringifiedValue: Scalars["String"]["output"];
};

export type ScreeningGenericValueInput = {
  booleanValue?: InputMaybe<Scalars["Boolean"]["input"]>;
  floatValue?: InputMaybe<Scalars["Float"]["input"]>;
  integerValue?: InputMaybe<Scalars["Int"]["input"]>;
  stringValue?: InputMaybe<Scalars["String"]["input"]>;
};

export type ScreeningIntegerFieldVariable = {
  __typename?: "ScreeningIntegerFieldVariable";
  validationRule: ScreeningIntegerFieldVariableValidationRule;
};

export type ScreeningIntegerFieldVariableValidationRule = {
  __typename?: "ScreeningIntegerFieldVariableValidationRule";
  max: Maybe<Scalars["Int"]["output"]>;
  min: Maybe<Scalars["Int"]["output"]>;
  required: Scalars["Boolean"]["output"];
};

export type ScreeningIntegerValidationRuleInput = {
  max?: InputMaybe<Scalars["Int"]["input"]>;
  min?: InputMaybe<Scalars["Int"]["input"]>;
  required: Scalars["Boolean"]["input"];
};

export type ScreeningInvitedCandidate = {
  __typename?: "ScreeningInvitedCandidate";
  email: Scalars["String"]["output"];
  invitationCode: Maybe<Scalars["String"]["output"]>;
  screeningId: Scalars["String"]["output"];
};

export type ScreeningInvitee = {
  __typename?: "ScreeningInvitee";
  email: Scalars["String"]["output"];
  invitationCode: Maybe<Scalars["String"]["output"]>;
  screeningId: Scalars["String"]["output"];
  status: ScreeningInviteeStatus;
};

export const ScreeningInviteeStatus = {
  Accepted: "ACCEPTED",
  Invited: "INVITED",
} as const;

export type ScreeningInviteeStatus = (typeof ScreeningInviteeStatus)[keyof typeof ScreeningInviteeStatus];
export type ScreeningInviteesFilterInput = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  statuses: Array<ScreeningInviteeStatus>;
};

export type ScreeningInviteesInput = {
  filters: ScreeningInviteesFilterInput;
  isDescending: Scalars["Boolean"]["input"];
  offset: Scalars["Int"]["input"];
  screeningId: Scalars["String"]["input"];
  size: Scalars["Int"]["input"];
  withCount: Scalars["Boolean"]["input"];
};

export type ScreeningInviteesResponse = {
  __typename?: "ScreeningInviteesResponse";
  count: Scalars["Int"]["output"];
  hasNext: Scalars["Boolean"]["output"];
  invitees: Array<ScreeningInvitee>;
};

export type ScreeningProjectEntitySourceQuestion = {
  __typename?: "ScreeningProjectEntitySourceQuestion";
  question: ProjectQuestion;
  questionId: Scalars["Int"]["output"];
  /** This field may contain the string "latest"; do not use it for Map Keys, etc. */
  questionVersion: Scalars["String"]["output"];
  questionWeight: Scalars["Int"]["output"];
};

export type ScreeningQuestion = AlgorithmQuestion | MultiChoicePackage | ProjectQuestion | SystemDesignQuestion;

export type ScreeningQuizEntitySourcePackage = {
  __typename?: "ScreeningQuizEntitySourcePackage";
  packageId: Scalars["Int"]["output"];
  /** This field may contain the string "latest"; do not use it for Map Keys, etc. */
  packageVersion: Scalars["String"]["output"];
  pb_package: MultiChoicePackage;
  questionWeight: Scalars["Int"]["output"];
};

export type ScreeningRandomFromQuestionsEntityTrack = Node & {
  __typename?: "ScreeningRandomFromQuestionsEntityTrack";
  entitySourceQuestions: Array<ScreeningEntitySourceQuestion>;
  id: Scalars["ID"]["output"];
  questionScoreWeight: Scalars["Int"]["output"];
  trackId: Scalars["Int"]["output"];
};

export type ScreeningRandomFromQuestionsEntityTrackEntitySourceQuestionsArgs = {
  withAnswers: Scalars["Boolean"]["input"];
};

export type ScreeningSelectableFromQuestionsEntityTrack = Node & {
  __typename?: "ScreeningSelectableFromQuestionsEntityTrack";
  entitySourceQuestions: Array<ScreeningEntitySourceQuestion>;
  id: Scalars["ID"]["output"];
  questionScoreWeight: Scalars["Int"]["output"];
  trackId: Scalars["Int"]["output"];
};

export type ScreeningSelectableFromQuestionsEntityTrackEntitySourceQuestionsArgs = {
  withAnswers: Scalars["Boolean"]["input"];
};

export type ScreeningSelectedQuestionByTrack = {
  selectedIndex: Scalars["Int"]["input"];
  trackId: Scalars["Int"]["input"];
};

export type ScreeningSpotStats = {
  __typename?: "ScreeningSpotStats";
  spotCountByStatus: Maybe<SpotCountByStatus>;
};

export type ScreeningStringFieldVariable = {
  __typename?: "ScreeningStringFieldVariable";
  formType: ScreeningStringVariableFieldFormType;
  validationRule: ScreeningStringFieldVariableValidationRule;
};

export type ScreeningStringFieldVariableValidationRule = {
  __typename?: "ScreeningStringFieldVariableValidationRule";
  max: Maybe<Scalars["Int"]["output"]>;
  min: Maybe<Scalars["Int"]["output"]>;
  required: Scalars["Boolean"]["output"];
};

export type ScreeningStringValidationRuleInput = {
  max?: InputMaybe<Scalars["Int"]["input"]>;
  min?: InputMaybe<Scalars["Int"]["input"]>;
  required: Scalars["Boolean"]["input"];
};

export const ScreeningStringVariableFieldFormType = {
  InputText: "INPUT_TEXT",
  Textarea: "TEXTAREA",
} as const;

export type ScreeningStringVariableFieldFormType =
  (typeof ScreeningStringVariableFieldFormType)[keyof typeof ScreeningStringVariableFieldFormType];
export type ScreeningSystemDesignEntitySourceQuestion = {
  __typename?: "ScreeningSystemDesignEntitySourceQuestion";
  componentTypes: Array<SystemDesignComponentType>;
  question: SystemDesignQuestion;
  questionId: Scalars["Int"]["output"];
  questionWeight: Scalars["Int"]["output"];
};

export type ScreeningValidationRuleInput = {
  booleanValidationRule?: InputMaybe<ScreeningBooleanValidationRuleInput>;
  integerValidationRule?: InputMaybe<ScreeningIntegerValidationRuleInput>;
  stringValidationRule?: InputMaybe<ScreeningStringValidationRuleInput>;
};

export const ScreeningValueType = {
  Boolean: "BOOLEAN",
  Integer: "INTEGER",
  String: "STRING",
} as const;

export type ScreeningValueType = (typeof ScreeningValueType)[keyof typeof ScreeningValueType];
export type ScreeningVariable = {
  __typename?: "ScreeningVariable";
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  screeningId: Scalars["String"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  variableField: ScreeningVariableField;
  variableId: Scalars["Int"]["output"];
};

export type ScreeningVariableField = ScreeningBooleanFieldVariable | ScreeningIntegerFieldVariable | ScreeningStringFieldVariable;

export type ScreeningVariableInput = {
  description: Scalars["String"]["input"];
  formType: ScreeningFormType;
  label: Scalars["String"]["input"];
  validationRule: ScreeningValidationRuleInput;
  valueType: ScreeningValueType;
};

export type ScreeningVariablesByCompanyIdInput = {
  companyId: Scalars["Int"]["input"];
  cursorSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  size: Scalars["Int"]["input"];
  withCount: Scalars["Boolean"]["input"];
};

export type ScreeningVariablesByCompanyIdResponse = {
  __typename?: "ScreeningVariablesByCompanyIdResponse";
  count: Scalars["Int"]["output"];
  pager: Maybe<SpotPager>;
  variables: Array<ScreeningVariable>;
};

export type ScreeningsByCompanyIdFilters = {
  activeStatus?: InputMaybe<ScreeningActiveStatus>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  tagNames: Array<Scalars["String"]["input"]>;
};

export type ScreeningsByCompanyIdInput = {
  companyId: Scalars["Int"]["input"];
  filters?: InputMaybe<ScreeningsByCompanyIdFilters>;
  isDescending: Scalars["Boolean"]["input"];
  offset: Scalars["Int"]["input"];
  size: Scalars["Int"]["input"];
  withCount: Scalars["Boolean"]["input"];
};

export type ScreeningsByCompanyIdResponse = {
  __typename?: "ScreeningsByCompanyIdResponse";
  count: Scalars["Int"]["output"];
  hasNext: Scalars["Boolean"]["output"];
  screenings: Array<Screening>;
};

export type Security = {
  __typename?: "Security";
  companyId: Scalars["Int"]["output"];
  emailDomain: Scalars["String"]["output"];
  idpCertificate: Scalars["String"]["output"];
  idpEntityId: Scalars["String"]["output"];
  idpSsoUrl: Scalars["String"]["output"];
  providerId: Scalars["String"]["output"];
  ssoEnabled: Scalars["Boolean"]["output"];
  ssoMode: SsoMode;
};

export type SimilarCodeCrawlerMetadata = {
  __typename?: "SimilarCodeCrawlerMetadata";
  url: Scalars["String"]["output"];
};

export type SimilarCodeMetadata =
  | SimilarCodeCrawlerMetadata
  | SimilarCodeNotFoundMetadata
  | SimilarCodeOtherCompanyMetadata
  | SimilarCodeSpotMetadata;

export type SimilarCodeNotFoundMetadata = {
  __typename?: "SimilarCodeNotFoundMetadata";
  /**
   * reserved is a dummy field
   * This is defined because GraphQL type must at least 1 field
   */
  reserved: Maybe<Scalars["Boolean"]["output"]>;
};

export type SimilarCodeOtherCompanyMetadata = {
  __typename?: "SimilarCodeOtherCompanyMetadata";
  /**
   * reserved is a dummy field
   * This is defined because GraphQL type must at least 1 field
   */
  reserved: Maybe<Scalars["Boolean"]["output"]>;
};

export type SimilarCodeSpotMetadata = {
  __typename?: "SimilarCodeSpotMetadata";
  challengeId: Scalars["Int"]["output"];
  questionId: Scalars["Int"]["output"];
  questionVersion: Scalars["String"]["output"];
  spotId: Scalars["String"]["output"];
};

export type SimilarSubmission = {
  __typename?: "SimilarSubmission";
  challengeId: Scalars["Int"]["output"];
  questionId: Scalars["Int"]["output"];
  questionVersion: Scalars["String"]["output"];
  submissionId: Scalars["Int"]["output"];
};

export type SkillTagNode = Node & {
  __typename?: "SkillTagNode";
  aliases: Array<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  isVirtual: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  path: Scalars["String"]["output"];
  skillTagNodeId: Scalars["String"]["output"];
};

export type SkillTagsInput = {
  isDescending: Scalars["Boolean"]["input"];
  limit: Scalars["Int"]["input"];
  offset: Scalars["Int"]["input"];
  withCount: Scalars["Boolean"]["input"];
};

export type SkillTagsResponse = {
  __typename?: "SkillTagsResponse";
  count: Scalars["Int"]["output"];
  hasNext: Scalars["Boolean"]["output"];
  skillTagNodes: Array<SkillTagNode>;
};

export type SlackIncomingWebhook = {
  __typename?: "SlackIncomingWebhook";
  channel: Scalars["String"]["output"];
  channelId: Scalars["String"]["output"];
  configurationUrl: Scalars["String"]["output"];
  webhookUrl: Scalars["String"]["output"];
};

export type SlackSettings = {
  __typename?: "SlackSettings";
  companyId: Scalars["Int"]["output"];
  incomingWebHook: Maybe<SlackIncomingWebhook>;
  isAuthorized: Scalars["Boolean"]["output"];
  language: Language;
  remoteSettings: Maybe<RemoteSettingsSubscribeSlack>;
  spotSettings: Maybe<SpotSettingsSubscribeSlack>;
};

export type SonarIntegration = {
  __typename?: "SonarIntegration";
  companyId: Scalars["Int"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  screening: Maybe<Screening>;
  screeningId: Scalars["String"]["output"];
};

export type SonarIntegrationsResponse = {
  __typename?: "SonarIntegrationsResponse";
  count: Scalars["Int"]["output"];
  pager: Maybe<AtsPager>;
  sonarIntegrations: Array<SonarIntegration>;
};

export const SortMethod = {
  AccuracyRate: "ACCURACY_RATE",
  AverageElapsedTime: "AVERAGE_ELAPSED_TIME",
  CreatedAt: "CREATED_AT",
  LeakScore: "LEAK_SCORE",
  NumUses: "NUM_USES",
  Unknown: "UNKNOWN",
} as const;

export type SortMethod = (typeof SortMethod)[keyof typeof SortMethod];
export type Spot = Node & {
  __typename?: "Spot";
  allowChatGPT: Scalars["Boolean"]["output"];
  allowHint: Scalars["Boolean"]["output"];
  allowWebSearch: Scalars["Boolean"]["output"];
  /**
   * @himenon Workaround
   * https://hireroo.slack.com/archives/C05CK3RR5ED/p1720162187188899
   */
  canShowCandidateName: Scalars["Boolean"]["output"];
  candidate: Maybe<User>;
  candidateEmail: Scalars["String"]["output"];
  candidateId: Maybe<Scalars["String"]["output"]>;
  candidateName: Scalars["String"]["output"];
  company: Maybe<Company>;
  companyId: Scalars["Int"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  creatorId: Scalars["String"]["output"];
  customScore: Scalars["Float"]["output"];
  declinedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  didEndAtSeconds: Maybe<Scalars["Int"]["output"]>;
  didStartAtSeconds: Maybe<Scalars["Int"]["output"]>;
  elapsedTimeSeconds: Maybe<Scalars["Int"]["output"]>;
  employee: Maybe<User>;
  /** @deprecated entities should be replaced by issuedEntities */
  entities: Array<SpotEntity>;
  entityCount: Scalars["Int"]["output"];
  /** @deprecated entitySources should be replaced by issuedEntitySources */
  entitySources: Array<SpotEntitySource>;
  evaluatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  evaluatedBy: Maybe<User>;
  evaluationComment: Scalars["String"]["output"];
  evaluatorId: Maybe<Scalars["String"]["output"]>;
  filledVariables: Array<SpotFilledVariable>;
  finalizedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  /** @deprecated invitationEmail is deprecated from proto definition */
  invitationEmail: Scalars["String"]["output"];
  invitationLanguage: Maybe<Language>;
  /** @deprecated invitationMethod is deprecated from proto definition */
  invitationMethod: InvitationMethod;
  isPassed: Scalars["Boolean"]["output"];
  isPublic: Maybe<Scalars["Boolean"]["output"]>;
  isReliableRank: Scalars["Boolean"]["output"];
  isRetake: Scalars["Boolean"]["output"];
  issuedEntities: Array<SpotIssuedEntity>;
  issuedEntitySources: Array<IssuedSpotEntitySource>;
  memo: Maybe<Scalars["String"]["output"]>;
  messageForCandidate: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  numSubset: Scalars["Int"]["output"];
  originId: Scalars["String"]["output"];
  ownerEmailAddress: Maybe<Scalars["String"]["output"]>;
  parentId: Scalars["String"]["output"];
  rank: Scalars["Int"]["output"];
  rankEvaluation: RankEvaluation;
  recommendationIds: Array<Scalars["Int"]["output"]>;
  recommendations: Array<EvaluationRecommendation>;
  recommenders: Array<User>;
  relativeScore: Scalars["Float"]["output"];
  remainingTimeSeconds: Scalars["Int"]["output"];
  /** @deprecated reviewers should be replaced by viewers */
  reviewerSources: Array<SpotReviewerSource>;
  /** @deprecated reviewers should be replaced by viewers */
  reviewers: Array<SpotReviewer>;
  screening: Maybe<Screening>;
  screeningId: Maybe<Scalars["String"]["output"]>;
  sharedLinks: Array<SpotSharedLink>;
  spotId: Scalars["String"]["output"];
  status: SpotStatus;
  suspiciousDegree: SpotSuspiciousDegree;
  tags: Array<SpotTag>;
  timeLimitSeconds: Scalars["Int"]["output"];
  totalScore: Scalars["Float"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  viewerSources: Array<SpotViewerSource>;
  viewers: Array<SpotViewer>;
  willExpireAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type SpotEntitiesArgs = {
  withAnswers: Scalars["Boolean"]["input"];
};

export type SpotIssuedEntitiesArgs = {
  withAnswers: Scalars["Boolean"]["input"];
};

export type SpotChallengeQuestionSource = {
  __typename?: "SpotChallengeQuestionSource";
  enabledLanguages: Array<Scalars["String"]["output"]>;
  questionId: Scalars["Int"]["output"];
  /** This field may contain the string "latest"; do not use it for Map Keys, etc. */
  questionVersion: Scalars["String"]["output"];
};

export type SpotCountByResult = {
  __typename?: "SpotCountByResult";
  notEvaluated: Scalars["Int"]["output"];
  notPassed: Scalars["Int"]["output"];
  passed: Scalars["Int"]["output"];
};

export type SpotCountByStatus = {
  __typename?: "SpotCountByStatus";
  completed: Scalars["Int"]["output"];
  created: Scalars["Int"]["output"];
  evaluated: Scalars["Int"]["output"];
  expired: Scalars["Int"]["output"];
  finalized: Scalars["Int"]["output"];
  started: Scalars["Int"]["output"];
};

export type SpotEntity = ChallengeEntity | ProjectEntity | QuizEntity | SystemDesignEntity;

export type SpotEntityIdInput = {
  entityId: Scalars["Int"]["input"];
  entityType: SpotEntityType;
};

/** FIXME: rename to SpotEntityId since proto name is EntityId */
export type SpotEntitySource = {
  __typename?: "SpotEntitySource";
  entityId: Scalars["Int"]["output"];
  entityType: SpotEntityType;
};

export type SpotEntitySourceForEntityTrackInput = {
  challengeQuestion?: InputMaybe<ChallengeQuestionInput>;
  projectQuestion?: InputMaybe<ProjectQuestionInput>;
  quizPackage?: InputMaybe<QuizPackageInput>;
  systemDesignQuestion?: InputMaybe<SystemDesignQuestionInput>;
};

export type SpotEntitySourceInput = {
  challengeQuestion?: InputMaybe<ChallengeQuestionInput>;
  entityType?: InputMaybe<SpotEntityType>;
  projectQuestion?: InputMaybe<ProjectQuestionInput>;
  questionId: Scalars["Int"]["input"];
  questionWeight: Scalars["Int"]["input"];
  quizPackage?: InputMaybe<QuizPackageInput>;
  systemDesignQuestion?: InputMaybe<SystemDesignQuestionInput>;
};

export const SpotEntityType = {
  Challenge: "CHALLENGE",
  Project: "PROJECT",
  Quiz: "QUIZ",
  SystemDesign: "SYSTEM_DESIGN",
  Unknown: "UNKNOWN",
} as const;

export type SpotEntityType = (typeof SpotEntityType)[keyof typeof SpotEntityType];
export type SpotFieldsForFilterInput = {
  companyId: Scalars["Int"]["input"];
  creator: Scalars["Boolean"]["input"];
  ignoreError?: InputMaybe<Scalars["Boolean"]["input"]>;
  screening: Scalars["Boolean"]["input"];
  viewer: Scalars["Boolean"]["input"];
};

export type SpotFieldsForFilterResponse = {
  __typename?: "SpotFieldsForFilterResponse";
  creatorIds: Array<Scalars["String"]["output"]>;
  creators: Array<User>;
  screeningIds: Array<Scalars["String"]["output"]>;
  screenings: Array<Screening>;
  viewerSources: Array<SpotViewerSource>;
  viewers: Array<SpotViewer>;
};

export type SpotFieldsForFilterResponseViewersArgs = {
  companyId: Scalars["Int"]["input"];
};

export type SpotFilledVariable = {
  __typename?: "SpotFilledVariable";
  description: Scalars["String"]["output"];
  filledVariableId: Scalars["Int"]["output"];
  formType: ScreeningFormType;
  label: Scalars["String"]["output"];
  spotId: Scalars["String"]["output"];
  value: Maybe<ScreeningGenericValue>;
  valueType: ScreeningValueType;
};

export type SpotFilledVariableCandidate = {
  __typename?: "SpotFilledVariableCandidate";
  formType: ScreeningFormType;
  label: Scalars["String"]["output"];
  valueType: ScreeningValueType;
};

export type SpotFilterOptionsByScreeningIdResponse = {
  __typename?: "SpotFilterOptionsByScreeningIdResponse";
  filledVariableCandidates: Array<SpotFilledVariableCandidate>;
};

export type SpotFilterOptionsByScreeningInput = {
  screeningId: Scalars["String"]["input"];
  withFilledVariables: Scalars["Boolean"]["input"];
};

export type SpotFiltersByScreeningInput = {
  candidateEmail?: InputMaybe<Scalars["String"]["input"]>;
  candidateName?: InputMaybe<Scalars["String"]["input"]>;
  maxCustomScore?: InputMaybe<Scalars["Float"]["input"]>;
  maxTotalScore?: InputMaybe<Scalars["Float"]["input"]>;
  minCustomScore?: InputMaybe<Scalars["Float"]["input"]>;
  minTotalScore?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
  rankEvaluations: Array<RankEvaluation>;
  result?: InputMaybe<Scalars["Boolean"]["input"]>;
  statuses: Array<SpotStatus>;
  suspiciousDegrees: Array<SpotSuspiciousDegree>;
  tagNames: Array<Scalars["String"]["input"]>;
  variableEntries: Array<SpotVariableEntryInput>;
};

export const SpotInvitationMethod = {
  Email: "EMAIL",
  Link: "LINK",
  Unknown: "UNKNOWN",
} as const;

export type SpotInvitationMethod = (typeof SpotInvitationMethod)[keyof typeof SpotInvitationMethod];
export type SpotIssuedChallengeEntity = {
  __typename?: "SpotIssuedChallengeEntity";
  entity: ChallengeEntity;
  questionWeight: Scalars["Int"]["output"];
};

export type SpotIssuedEntity = SpotIssuedChallengeEntity | SpotIssuedProjectEntity | SpotIssuedQuizEntity | SpotIssuedSystemDesignEntity;

export type SpotIssuedProjectEntity = {
  __typename?: "SpotIssuedProjectEntity";
  entity: ProjectEntity;
  questionWeight: Scalars["Int"]["output"];
};

export type SpotIssuedQuizEntity = {
  __typename?: "SpotIssuedQuizEntity";
  entity: QuizEntity;
  questionWeight: Scalars["Int"]["output"];
};

export type SpotIssuedSystemDesignEntity = {
  __typename?: "SpotIssuedSystemDesignEntity";
  entity: SystemDesignEntity;
  questionWeight: Scalars["Int"]["output"];
};

export type SpotPager = {
  __typename?: "SpotPager";
  cursorSeconds: Maybe<Scalars["Int"]["output"]>;
  hasNext: Scalars["Boolean"]["output"];
};

export type SpotProjectQuestionSource = {
  __typename?: "SpotProjectQuestionSource";
  questionId: Scalars["Int"]["output"];
  /** This field may contain the string "latest"; do not use it for Map Keys, etc. */
  questionVersion: Scalars["String"]["output"];
};

export type SpotQuestion = AlgorithmQuestion | MultiChoicePackage | ProjectQuestion | SystemDesignQuestion;

export type SpotQuizPackageSource = {
  __typename?: "SpotQuizPackageSource";
  packageId: Scalars["Int"]["output"];
  /** This field may contain the string "latest"; do not use it for Map Keys, etc. */
  packageVersion: Scalars["String"]["output"];
};

export type SpotReviewer = {
  __typename?: "SpotReviewer";
  employee: Maybe<User>;
  group: Maybe<EmployeeGroup>;
  type: ReviewerType;
};

export type SpotReviewerInput = {
  employeeId?: InputMaybe<Scalars["String"]["input"]>;
  groupId?: InputMaybe<Scalars["String"]["input"]>;
  type: ReviewerType;
};

export type SpotReviewerSource = {
  __typename?: "SpotReviewerSource";
  reviewerId: Scalars["String"]["output"];
  reviewerType: ReviewerType;
};

export type SpotSettingsSubscribeSlack = {
  __typename?: "SpotSettingsSubscribeSlack";
  subscribeSpotCompleted: Scalars["Boolean"]["output"];
  subscribeSpotCreated: Scalars["Boolean"]["output"];
  subscribeSpotDeleted: Scalars["Boolean"]["output"];
  subscribeSpotEvaluated: Scalars["Boolean"]["output"];
  subscribeSpotExpired: Scalars["Boolean"]["output"];
  subscribeSpotFinalized: Scalars["Boolean"]["output"];
  subscribeSpotRecreated: Scalars["Boolean"]["output"];
  subscribeSpotReviewed: Scalars["Boolean"]["output"];
  subscribeSpotStarted: Scalars["Boolean"]["output"];
};

export type SpotSettingsSubscribeSlackInput = {
  subscribeSpotCompleted: Scalars["Boolean"]["input"];
  subscribeSpotCreated: Scalars["Boolean"]["input"];
  subscribeSpotDeleted: Scalars["Boolean"]["input"];
  subscribeSpotEvaluated: Scalars["Boolean"]["input"];
  subscribeSpotExpired: Scalars["Boolean"]["input"];
  subscribeSpotFinalized: Scalars["Boolean"]["input"];
  subscribeSpotRecreated: Scalars["Boolean"]["input"];
  subscribeSpotReviewed: Scalars["Boolean"]["input"];
  subscribeSpotStarted: Scalars["Boolean"]["input"];
};

export type SpotSharedLink = {
  __typename?: "SpotSharedLink";
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  expiredAtSeconds: Maybe<Scalars["Int"]["output"]>;
  linkHash: Scalars["String"]["output"];
  showAnswer: Scalars["Boolean"]["output"];
  showCheatDetect: Scalars["Boolean"]["output"];
  showPlayback: Scalars["Boolean"]["output"];
  showRank: Scalars["Boolean"]["output"];
  showRelativeEvaluation: Scalars["Boolean"]["output"];
  showReview: Scalars["Boolean"]["output"];
  showScore: Scalars["Boolean"]["output"];
  spotId: Scalars["String"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type SpotStatistics = {
  __typename?: "SpotStatistics";
  bins: Array<Scalars["Int"]["output"]>;
  relativeScore: Maybe<StatisticsStats>;
};

export type SpotStats = {
  __typename?: "SpotStats";
  /** @example 0 ~ 100 */
  averagePassedCustomScoreFormatted: Maybe<Scalars["Int"]["output"]>;
  spotCountByResult: Maybe<SpotCountByResult>;
  spotCountByStatus: Maybe<SpotCountByStatus>;
};

export const SpotStatus = {
  Accepted: "ACCEPTED",
  Completed: "COMPLETED",
  Created: "CREATED",
  Declined: "DECLINED",
  Evaluated: "EVALUATED",
  Expired: "EXPIRED",
  Finalized: "FINALIZED",
  Reviewed: "REVIEWED",
  Started: "STARTED",
  Unknown: "UNKNOWN",
} as const;

export type SpotStatus = (typeof SpotStatus)[keyof typeof SpotStatus];
export const SpotSuspiciousDegree = {
  Clean: "CLEAN",
  Suspicious: "SUSPICIOUS",
  Uncalculated: "UNCALCULATED",
} as const;

export type SpotSuspiciousDegree = (typeof SpotSuspiciousDegree)[keyof typeof SpotSuspiciousDegree];
export type SpotSystemDesignQuestionSource = {
  __typename?: "SpotSystemDesignQuestionSource";
  componentTypes: Array<SystemDesignComponentType>;
  questionId: Scalars["Int"]["output"];
};

export type SpotTag = {
  __typename?: "SpotTag";
  companyId: Scalars["Int"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  name: Scalars["String"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type SpotTagsByCompanyIdInput = {
  companyId: Scalars["Int"]["input"];
  cursorSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  size: Scalars["Int"]["input"];
  withCount: Scalars["Boolean"]["input"];
};

export type SpotTagsByCompanyIdResponse = {
  __typename?: "SpotTagsByCompanyIdResponse";
  count: Scalars["Int"]["output"];
  pager: Maybe<SpotPager>;
  tags: Array<SpotTag>;
};

export type SpotVariableEntryInput = {
  label: Scalars["String"]["input"];
  value?: InputMaybe<Scalars["String"]["input"]>;
  valueType: ScreeningValueType;
};

export type SpotViewer = EmployeeGroup | User;

export type SpotViewerInput = {
  employeeId?: InputMaybe<Scalars["String"]["input"]>;
  groupId?: InputMaybe<Scalars["String"]["input"]>;
  type: SpotViewerType;
};

export type SpotViewerSource = {
  __typename?: "SpotViewerSource";
  type: SpotViewerType;
  viewerId: Scalars["String"]["output"];
};

export const SpotViewerType = {
  Employee: "EMPLOYEE",
  EmployeeGroup: "EMPLOYEE_GROUP",
} as const;

export type SpotViewerType = (typeof SpotViewerType)[keyof typeof SpotViewerType];
export type SpotsByCompanyIdFilters = {
  candidateEmail?: InputMaybe<Scalars["String"]["input"]>;
  candidateName?: InputMaybe<Scalars["String"]["input"]>;
  createdByList: Array<Scalars["String"]["input"]>;
  maxCustomScore?: InputMaybe<Scalars["Float"]["input"]>;
  maxTotalScore?: InputMaybe<Scalars["Float"]["input"]>;
  minCustomScore?: InputMaybe<Scalars["Float"]["input"]>;
  minTotalScore?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
  rankEvaluations: Array<RankEvaluation>;
  result?: InputMaybe<Scalars["Boolean"]["input"]>;
  screeningIds: Array<Scalars["String"]["input"]>;
  statuses: Array<SpotStatus>;
  suspiciousDegrees: Array<SpotSuspiciousDegree>;
  tagNames: Array<Scalars["String"]["input"]>;
};

export type SpotsByCompanyIdInput = {
  companyId: Scalars["Int"]["input"];
  cursorSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  filters?: InputMaybe<SpotsByCompanyIdFilters>;
  isDescending: Scalars["Boolean"]["input"];
  offset: Scalars["Int"]["input"];
  size: Scalars["Int"]["input"];
  sortMethod: SpotsByCompanyIdSortMethod;
  status: SpotStatus;
  withAnswers: Scalars["Boolean"]["input"];
  withCount: Scalars["Boolean"]["input"];
  withDeleted: Scalars["Boolean"]["input"];
};

export type SpotsByCompanyIdResponse = {
  __typename?: "SpotsByCompanyIdResponse";
  count: Scalars["Int"]["output"];
  hasNext: Scalars["Boolean"]["output"];
  /** @deprecated pager is deleted from proto definition */
  pager: Maybe<SpotPager>;
  spots: Array<Spot>;
};

export const SpotsByCompanyIdSortMethod = {
  CreatedAt: "CREATED_AT",
  DidStartAt: "DID_START_AT",
  EvaluatedAt: "EVALUATED_AT",
  Rank: "RANK",
  TotalScore: "TOTAL_SCORE",
  Unknown: "UNKNOWN",
} as const;

export type SpotsByCompanyIdSortMethod = (typeof SpotsByCompanyIdSortMethod)[keyof typeof SpotsByCompanyIdSortMethod];
export type SpotsByScreeningIdInput = {
  cursorSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  filters: SpotFiltersByScreeningInput;
  ignoreInheritance: Scalars["Boolean"]["input"];
  isDescending: Scalars["Boolean"]["input"];
  offset: Scalars["Int"]["input"];
  screeningId: Scalars["String"]["input"];
  size: Scalars["Int"]["input"];
  sortMethod: SpotsByCompanyIdSortMethod;
  withCount: Scalars["Boolean"]["input"];
  withDeleted: Scalars["Boolean"]["input"];
};

export type SpotsByScreeningIdResponse = {
  __typename?: "SpotsByScreeningIdResponse";
  count: Scalars["Int"]["output"];
  hasNext: Scalars["Boolean"]["output"];
  spots: Array<Spot>;
};

export type StartChallengeSessionsInput = {
  challengeId: Scalars["Int"]["input"];
  language: Scalars["String"]["input"];
  startChatSession: Scalars["Boolean"]["input"];
  startWebSession: Scalars["Boolean"]["input"];
};

export type StartChallengeSessionsResponse = {
  __typename?: "StartChallengeSessionsResponse";
  chatGPTSession: Maybe<ChallengeChatGptSession>;
  webSession: Maybe<ChallengeWebSession>;
};

export type StartDevelopmentWorkspaceInput = {
  projectId: Scalars["Int"]["input"];
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
};

export type StartEvaluationWorkspaceInput = {
  submissionId: Scalars["Int"]["input"];
};

export type StartExamRequestInput = {
  examId: Scalars["String"]["input"];
};

export type StartLiveCodingInput = {
  employeeId: Scalars["String"]["input"];
  liveCodingId: Scalars["Int"]["input"];
  userName: Scalars["String"]["input"];
};

export type StartProjectInput = {
  candidateId: Scalars["String"]["input"];
  projectId: Scalars["Int"]["input"];
};

export type StartRecordingVideoChatInput = {
  roomId: Scalars["String"]["input"];
};

export type StartRemoteInput = {
  remoteId: Scalars["String"]["input"];
};

export type StartSpotInput = {
  candidateId?: InputMaybe<Scalars["String"]["input"]>;
  candidateName: Scalars["String"]["input"];
  emailAddress: Scalars["String"]["input"];
  spotId: Scalars["String"]["input"];
};

export type Statistics = {
  __typename?: "Statistics";
  category: StatisticsCategory;
  challengeStatistics: Maybe<ChallengeStatistics>;
  examStatistics: Maybe<ExamStatistics>;
  projectStatistics: Maybe<ProjectStatistics>;
  quizStatistics: Maybe<QuizStatistics>;
  spotStatistics: Maybe<SpotStatistics>;
  systemDesignStatistics: Maybe<SystemDesignStatistics>;
};

export const StatisticsCategory = {
  Challenge: "CHALLENGE",
  Exam: "EXAM",
  Project: "PROJECT",
  Quiz: "QUIZ",
  Spot: "SPOT",
  SystemDesign: "SYSTEM_DESIGN",
  Unknown: "UNKNOWN",
} as const;

export type StatisticsCategory = (typeof StatisticsCategory)[keyof typeof StatisticsCategory];
export type StatisticsChallengeQueryInput = {
  companyId?: InputMaybe<Scalars["Int"]["input"]>;
  maxTimestamp?: InputMaybe<TimestampInput>;
  minTimestamp?: InputMaybe<TimestampInput>;
  numBins: Scalars["Int"]["input"];
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
};

export type StatisticsChallengeRankQueryInput = {
  companyId?: InputMaybe<Scalars["Int"]["input"]>;
  maxTimestamp?: InputMaybe<TimestampInput>;
  minTimestamp?: InputMaybe<TimestampInput>;
  submissionId: Scalars["Int"]["input"];
};

export const StatisticsEvaluation = {
  A: "A",
  B: "B",
  C: "C",
  D: "D",
  S: "S",
  Unknown: "UNKNOWN",
} as const;

export type StatisticsEvaluation = (typeof StatisticsEvaluation)[keyof typeof StatisticsEvaluation];
export type StatisticsExamQueryInput = {
  companyId?: InputMaybe<Scalars["Int"]["input"]>;
  included: Array<Scalars["String"]["input"]>;
  maxTimestamp?: InputMaybe<TimestampInput>;
  minTimestamp?: InputMaybe<TimestampInput>;
  numBins: Scalars["Int"]["input"];
};

export type StatisticsExamRankQueryInput = {
  companyId?: InputMaybe<Scalars["Int"]["input"]>;
  examId: Scalars["String"]["input"];
  included: Array<Scalars["String"]["input"]>;
  maxTimestamp?: InputMaybe<TimestampInput>;
  minTimestamp?: InputMaybe<TimestampInput>;
};

export type StatisticsProjectQueryInput = {
  companyId?: InputMaybe<Scalars["Int"]["input"]>;
  maxTimestamp?: InputMaybe<TimestampInput>;
  minTimestamp?: InputMaybe<TimestampInput>;
  numBins: Scalars["Int"]["input"];
  questionId: Scalars["Int"]["input"];
};

export type StatisticsProjectRankQueryInput = {
  companyId?: InputMaybe<Scalars["Int"]["input"]>;
  maxTimestamp?: InputMaybe<TimestampInput>;
  minTimestamp?: InputMaybe<TimestampInput>;
  submissionId: Scalars["Int"]["input"];
};

export type StatisticsQueryInput = {
  challenge?: InputMaybe<StatisticsChallengeQueryInput>;
  exam?: InputMaybe<StatisticsExamQueryInput>;
  project?: InputMaybe<StatisticsProjectQueryInput>;
  quiz?: InputMaybe<StatisticsQuizQueryInput>;
  spot?: InputMaybe<StatisticsSpotQueryInput>;
  systemDesign?: InputMaybe<StatisticsSystemDesignQueryInput>;
};

export type StatisticsQuizQueryInput = {
  companyId?: InputMaybe<Scalars["Int"]["input"]>;
  maxTimestamp?: InputMaybe<TimestampInput>;
  minTimestamp?: InputMaybe<TimestampInput>;
  numBins: Scalars["Int"]["input"];
  packageId: Scalars["Int"]["input"];
  packageVersion: Scalars["String"]["input"];
};

export type StatisticsQuizRankQueryInput = {
  companyId?: InputMaybe<Scalars["Int"]["input"]>;
  maxTimestamp?: InputMaybe<TimestampInput>;
  minTimestamp?: InputMaybe<TimestampInput>;
  quizId: Scalars["Int"]["input"];
};

export type StatisticsRank = {
  __typename?: "StatisticsRank";
  evaluation: StatisticsEvaluation;
  numSubset: Scalars["Int"]["output"];
  rank: Scalars["Int"]["output"];
  relativeScore: Scalars["Float"]["output"];
};

export type StatisticsRankQueryInput = {
  challenge?: InputMaybe<StatisticsChallengeRankQueryInput>;
  exam?: InputMaybe<StatisticsExamRankQueryInput>;
  project?: InputMaybe<StatisticsProjectRankQueryInput>;
  quiz?: InputMaybe<StatisticsQuizRankQueryInput>;
  spot?: InputMaybe<StatisticsSpotRankQueryInput>;
  systemDesign?: InputMaybe<StatisticsSystemDesignRankQueryInput>;
};

export type StatisticsSourceInput = {
  category: StatisticsCategory;
  query?: InputMaybe<StatisticsQueryInput>;
};

export type StatisticsSpotQueryInput = {
  companyId?: InputMaybe<Scalars["Int"]["input"]>;
  included: Array<Scalars["String"]["input"]>;
  maxTimestamp?: InputMaybe<TimestampInput>;
  minTimestamp?: InputMaybe<TimestampInput>;
  numBins: Scalars["Int"]["input"];
};

export type StatisticsSpotRankQueryInput = {
  companyId?: InputMaybe<Scalars["Int"]["input"]>;
  included: Array<Scalars["String"]["input"]>;
  maxTimestamp?: InputMaybe<TimestampInput>;
  minTimestamp?: InputMaybe<TimestampInput>;
  spotId: Scalars["String"]["input"];
};

export type StatisticsStats = {
  __typename?: "StatisticsStats";
  avg: Scalars["Float"]["output"];
  max: Scalars["Float"]["output"];
  min: Scalars["Float"]["output"];
  p25: Scalars["Float"]["output"];
  p50: Scalars["Float"]["output"];
  p75: Scalars["Float"]["output"];
  p95: Scalars["Float"]["output"];
  std: Scalars["Float"]["output"];
};

export type StatisticsSystemDesignQueryInput = {
  companyId?: InputMaybe<Scalars["Int"]["input"]>;
  maxTimestamp?: InputMaybe<TimestampInput>;
  minTimestamp?: InputMaybe<TimestampInput>;
  numBins: Scalars["Int"]["input"];
  questionId: Scalars["Int"]["input"];
};

export type StatisticsSystemDesignRankQueryInput = {
  companyId?: InputMaybe<Scalars["Int"]["input"]>;
  maxTimestamp?: InputMaybe<TimestampInput>;
  minTimestamp?: InputMaybe<TimestampInput>;
  submissionId: Scalars["Int"]["input"];
};

export type StopRecordingVideoChatInput = {
  roomId: Scalars["String"]["input"];
};

export type StoreWebAccessEventInput = {
  description: Scalars["String"]["input"];
  searchEventId: Scalars["String"]["input"];
  sessionId: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  url: Scalars["String"]["input"];
};

export type StoreWebSearchEventInput = {
  /** When page is NULL, it means user searched. Otherwise, user moved page. */
  page?: InputMaybe<Scalars["Int"]["input"]>;
  query: Scalars["String"]["input"];
  sessionId: Scalars["String"]["input"];
};

export type SubmitQuizQuestionInput = {
  answerText?: InputMaybe<Scalars["String"]["input"]>;
  optionIds: Array<Scalars["Int"]["input"]>;
  packageId: Scalars["Int"]["input"];
  packageVersion: Scalars["String"]["input"];
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
  quizId: Scalars["Int"]["input"];
};

export type SubscribedCoupon = {
  __typename?: "SubscribedCoupon";
  coupon: Coupon;
  couponId: Scalars["Int"]["output"];
  createdAtSeconds: Scalars["Int"]["output"];
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  expiresAtSeconds: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  isActive: Scalars["Boolean"]["output"];
  subscribedCouponId: Scalars["Int"]["output"];
  subscriptionId: Scalars["Int"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type SuspiciousInference = {
  __typename?: "SuspiciousInference";
  feedback: Maybe<SuspiciousInferenceFeedback>;
  inferenceId: Scalars["String"]["output"];
};

export type SuspiciousInferenceFeedback = {
  __typename?: "SuspiciousInferenceFeedback";
  isCorrect: Scalars["Boolean"]["output"];
};

export type SwitchActiveCompanyForEmployeeInput = {
  companyId: Scalars["Int"]["input"];
  employeeId: Scalars["String"]["input"];
};

export type SwitchActiveCompanyForEmployeeV2Input = {
  companyId: Scalars["Int"]["input"];
  employeeId: Scalars["String"]["input"];
  role: Role;
  userType?: InputMaybe<UserType>;
};

export type SwitchActiveCompanyForTalentInput = {
  companyId: Scalars["Int"]["input"];
  talentId: Scalars["String"]["input"];
};

export type SwitchActiveCompanyForTalentV2Input = {
  companyId: Scalars["Int"]["input"];
  talentId: Scalars["String"]["input"];
};

export type SystemDesignAnswer = Node & {
  __typename?: "SystemDesignAnswer";
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  descriptionEn: Scalars["String"]["output"];
  descriptionJa: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  questionId: Scalars["Int"]["output"];
  snapshot: Scalars["String"]["output"];
  systemDesignAnswerId: Scalars["Int"]["output"];
  titleEn: Scalars["String"]["output"];
  titleJa: Scalars["String"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

/**
 * Should be aligned with UpperCase, but should not be easily aligned with UpperCase until SystemDesign refactoring is done,
 * as most existing SystemDesign implementations are built on lowercase
 */
export const SystemDesignComponentType = {
  Aws: "aws",
  Azure: "azure",
  Default: "default",
  Gcp: "gcp",
} as const;

export type SystemDesignComponentType = (typeof SystemDesignComponentType)[keyof typeof SystemDesignComponentType];
export type SystemDesignEntity = Node & {
  __typename?: "SystemDesignEntity";
  appealMessage: Maybe<Scalars["String"]["output"]>;
  candidateId: Scalars["String"]["output"];
  companyId: Scalars["Int"]["output"];
  componentTypes: Array<SystemDesignComponentType>;
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  didEndAtSeconds: Maybe<Scalars["Int"]["output"]>;
  didStartAtSeconds: Maybe<Scalars["Int"]["output"]>;
  employeeId: Scalars["String"]["output"];
  entityId: Scalars["Int"]["output"];
  evaluatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  question: Maybe<SystemDesignQuestion>;
  status: SystemDesignStatus;
  submissions: Array<SystemDesignSubmission>;
  /** @deprecated Field no longer supported */
  systemDesignEntityId: Scalars["Int"]["output"];
  timeLimitSeconds: Maybe<Scalars["Int"]["output"]>;
  totalScore: Scalars["Float"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type SystemDesignEvaluation = Node & {
  __typename?: "SystemDesignEvaluation";
  availability: Scalars["Float"]["output"];
  consistency: Scalars["Float"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  evaluatedBy: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  result: Scalars["String"]["output"];
  scalability: Scalars["Float"]["output"];
  submissionId: Scalars["Int"]["output"];
  systemDesignEvaluationId: Scalars["Int"]["output"];
  systemDesignId: Scalars["Int"]["output"];
  totalScore: Scalars["Float"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type SystemDesignHint = Node & {
  __typename?: "SystemDesignHint";
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  descriptionEn: Maybe<Scalars["String"]["output"]>;
  descriptionJa: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  orderNum: Scalars["Int"]["output"];
  questionId: Scalars["Int"]["output"];
  systemDesignHintId: Scalars["Int"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type SystemDesignQuestion = Node & {
  __typename?: "SystemDesignQuestion";
  accuracyRate: Scalars["Float"]["output"];
  answers: Array<SystemDesignAnswer>;
  averageElapsedTimeSeconds: Scalars["Float"]["output"];
  companyId: Scalars["Int"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  descriptionEn: Maybe<Scalars["String"]["output"]>;
  descriptionJa: Maybe<Scalars["String"]["output"]>;
  difficulty: Difficulty;
  hints: Array<SystemDesignHint>;
  id: Scalars["ID"]["output"];
  initialSnapshot: Scalars["String"]["output"];
  isOfficial: Scalars["Boolean"]["output"];
  isPrivate: Scalars["Boolean"]["output"];
  isSupportedLeakScore: Scalars["Boolean"]["output"];
  key: Scalars["String"]["output"];
  leakScore: Maybe<LeakScore>;
  numUses: Scalars["Int"]["output"];
  questionId: Scalars["Int"]["output"];
  scoreDistributionBins: Array<Scalars["Int"]["output"]>;
  scoringItems: Array<SystemDesignScoringItem>;
  status: SystemDesignQuestionStatus;
  timeLimitSeconds: Maybe<Scalars["Int"]["output"]>;
  titleEn: Maybe<Scalars["String"]["output"]>;
  titleJa: Maybe<Scalars["String"]["output"]>;
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type SystemDesignQuestionInput = {
  componentTypes: Array<SystemDesignComponentType>;
  questionId: Scalars["Int"]["input"];
};

export const SystemDesignQuestionStatus = {
  Archived: "ARCHIVED",
  Published: "PUBLISHED",
} as const;

export type SystemDesignQuestionStatus = (typeof SystemDesignQuestionStatus)[keyof typeof SystemDesignQuestionStatus];
export type SystemDesignScoringItem = Node & {
  __typename?: "SystemDesignScoringItem";
  category: SystemDesignScoringItemCategory;
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  descriptionEn: Scalars["String"]["output"];
  descriptionJa: Scalars["String"]["output"];
  graphlets: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  isHidden: Scalars["Boolean"]["output"];
  questionId: Scalars["Int"]["output"];
  systemDesignScoringItemId: Scalars["Int"]["output"];
  titleEn: Scalars["String"]["output"];
  titleJa: Scalars["String"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export const SystemDesignScoringItemCategory = {
  Availability: "AVAILABILITY",
  Consistency: "CONSISTENCY",
  Scalability: "SCALABILITY",
  Unknown: "UNKNOWN",
} as const;

export type SystemDesignScoringItemCategory = (typeof SystemDesignScoringItemCategory)[keyof typeof SystemDesignScoringItemCategory];
export type SystemDesignSnapshot = Node & {
  __typename?: "SystemDesignSnapshot";
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  isPassed: Scalars["Boolean"]["output"];
  matchedIds: Array<Scalars["String"]["output"]>;
  scoringItemId: Scalars["Int"]["output"];
  systemDesignId: Scalars["Int"]["output"];
  systemDesignSnapshotId: Scalars["Int"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type SystemDesignStatistics = {
  __typename?: "SystemDesignStatistics";
  availability: Maybe<StatisticsStats>;
  bins: Array<Scalars["Int"]["output"]>;
  consistency: Maybe<StatisticsStats>;
  elapsedTime: Maybe<StatisticsStats>;
  numHints: Maybe<StatisticsStats>;
  numSnapshots: Maybe<StatisticsStats>;
  numTabEvents: Maybe<StatisticsStats>;
  scalability: Maybe<StatisticsStats>;
  totalScore: Maybe<StatisticsStats>;
};

export const SystemDesignStatus = {
  Completed: "COMPLETED",
  Created: "CREATED",
  Evaluated: "EVALUATED",
  Started: "STARTED",
  Unknown: "UNKNOWN",
} as const;

export type SystemDesignStatus = (typeof SystemDesignStatus)[keyof typeof SystemDesignStatus];
export type SystemDesignSubmission = Node & {
  __typename?: "SystemDesignSubmission";
  companyId: Scalars["Int"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  elapsedTimeSeconds: Maybe<Scalars["Int"]["output"]>;
  enqueuedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  evaluatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  evaluations: Array<SystemDesignEvaluation>;
  id: Scalars["ID"]["output"];
  isBest: Scalars["Boolean"]["output"];
  numAccessEvents: Scalars["Int"]["output"];
  numSubset: Scalars["Int"]["output"];
  numTabEvents: Scalars["Int"]["output"];
  questionId: Scalars["Int"]["output"];
  snapshot: Scalars["String"]["output"];
  snapshots: Array<SystemDesignSnapshot>;
  status: SystemDesignSubmissionStatus;
  systemDesign: SystemDesignEntity;
  systemDesignId: Scalars["Int"]["output"];
  systemDesignSubmissionId: Scalars["Int"]["output"];
  totalElapsedTimeSeconds: Maybe<Scalars["Int"]["output"]>;
  totalScore: Scalars["Float"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  usedHints: Array<SystemDesignHint>;
};

export const SystemDesignSubmissionStatus = {
  Enqueued: "ENQUEUED",
  Evaluated: "EVALUATED",
  Failed: "FAILED",
  Unknown: "UNKNOWN",
} as const;

export type SystemDesignSubmissionStatus = (typeof SystemDesignSubmissionStatus)[keyof typeof SystemDesignSubmissionStatus];
export type TalentInvitation = Node & {
  __typename?: "TalentInvitation";
  companyId: Scalars["Int"]["output"];
  companyTalents: Array<CompanyTalent>;
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  createdBy: Scalars["String"]["output"];
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  emailAddresses: Array<Scalars["String"]["output"]>;
  expiresAtSeconds: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  invitationLanguage: InvitationLanguage;
  invitationMethod: InvitationMethod;
  talentInvitationId: Scalars["String"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type TalentNotification = {
  __typename?: "TalentNotification";
  actionLink: Scalars["String"]["output"];
  companyId: Scalars["Int"]["output"];
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  descriptionEn: Scalars["String"]["output"];
  descriptionJa: Scalars["String"]["output"];
  eventId: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  isRead: Scalars["Boolean"]["output"];
  messageId: Scalars["String"]["output"];
  method: NotificationMethod;
  readAtSeconds: Maybe<Scalars["Int"]["output"]>;
  talentId: Scalars["String"]["output"];
  talentNotificationId: Scalars["Int"]["output"];
  titleEn: Scalars["String"]["output"];
  titleJa: Scalars["String"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type TalentNotificationsResponse = {
  __typename?: "TalentNotificationsResponse";
  count: Scalars["Int"]["output"];
  notifications: Array<TalentNotification>;
  pager: Maybe<NotificationPager>;
};

export type TemplateChallengeQuestionSource = {
  __typename?: "TemplateChallengeQuestionSource";
  enabledLanguages: Array<Scalars["String"]["output"]>;
  questionId: Scalars["Int"]["output"];
  questionVersion: Scalars["String"]["output"];
  uniqueKey: Scalars["String"]["output"];
};

export type TemplateEntitySource = {
  __typename?: "TemplateEntitySource";
  challengeQuestion: Maybe<TemplateChallengeQuestionSource>;
  entityType: Maybe<TemplateEntityType>;
  projectQuestion: Maybe<TemplateProjectQuestionSource>;
  questionId: Scalars["Int"]["output"];
  questionKey: Scalars["String"]["output"];
  quizPackage: Maybe<TemplateQuizPackageSource>;
  systemDesignQuestion: Maybe<TemplateSystemDesignQuestionSource>;
};

export const TemplateEntityType = {
  Challenge: "CHALLENGE",
  Project: "PROJECT",
  Quiz: "QUIZ",
  SystemDesign: "SYSTEM_DESIGN",
  Unknown: "UNKNOWN",
} as const;

export type TemplateEntityType = (typeof TemplateEntityType)[keyof typeof TemplateEntityType];
export type TemplateFreepadQuestionSource = {
  __typename?: "TemplateFreepadQuestionSource";
  questionId: Scalars["Int"]["output"];
  questionVersion: Scalars["String"]["output"];
  uniqueKey: Scalars["String"]["output"];
};

export type TemplatePager = {
  __typename?: "TemplatePager";
  hasNext: Scalars["Boolean"]["output"];
  idCursor: Maybe<Scalars["Int"]["output"]>;
};

export type TemplateProjectQuestionSource = {
  __typename?: "TemplateProjectQuestionSource";
  questionId: Scalars["Int"]["output"];
  questionVersion: Scalars["String"]["output"];
  uniqueKey: Scalars["String"]["output"];
};

export type TemplateQuizPackageSource = {
  __typename?: "TemplateQuizPackageSource";
  packageId: Scalars["Int"]["output"];
  packageVersion: Scalars["String"]["output"];
  uniqueKey: Scalars["String"]["output"];
};

export type TemplateSystemDesignQuestionSource = {
  __typename?: "TemplateSystemDesignQuestionSource";
  componentTypes: Array<SystemDesignComponentType>;
  questionId: Scalars["Int"]["output"];
  uniqueKey: Scalars["String"]["output"];
};

export type TemplateVariable = {
  __typename?: "TemplateVariable";
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  key: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  templateId: Scalars["Int"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export type TemplateVariableInput = {
  description: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  templateId: Scalars["Int"]["input"];
};

export type TemplatesByCompanyIdInput = {
  companyId: Scalars["Int"]["input"];
  idCursor?: InputMaybe<Scalars["Int"]["input"]>;
  size: Scalars["Int"]["input"];
  withCount: Scalars["Boolean"]["input"];
};

export type TimestampInput = {
  nanos: Scalars["Int"]["input"];
  seconds: Scalars["Int"]["input"];
};

export const TranslatableLanguage = {
  En: "EN",
  Ja: "JA",
} as const;

export type TranslatableLanguage = (typeof TranslatableLanguage)[keyof typeof TranslatableLanguage];
export type TranslateSource = {
  body: Scalars["String"]["input"];
  from: TranslatableLanguage;
  to: TranslatableLanguage;
};

export type UpdateAlgorithmAnswerInput = {
  answerRuntimes: Array<UpdateAlgorithmAnswerRuntimeInput>;
  descriptionEn?: InputMaybe<Scalars["String"]["input"]>;
  descriptionJa?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  label: Scalars["String"]["input"];
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
  runtimeComplexity: Scalars["String"]["input"];
  spaceComplexity: Scalars["String"]["input"];
  titleEn?: InputMaybe<Scalars["String"]["input"]>;
  titleJa?: InputMaybe<Scalars["String"]["input"]>;
  uniqueId: Scalars["String"]["input"];
};

export type UpdateAlgorithmAnswerRuntimeInput = {
  answerId: Scalars["Int"]["input"];
  codeBody: Scalars["String"]["input"];
  id: Scalars["Int"]["input"];
  machineCpu?: InputMaybe<Scalars["Int"]["input"]>;
  machineMemory?: InputMaybe<Scalars["String"]["input"]>;
  machineOs?: InputMaybe<Scalars["String"]["input"]>;
  readability?: InputMaybe<Scalars["Int"]["input"]>;
  runtime: Scalars["String"]["input"];
  stats?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateAlgorithmHintInput = {
  descriptionEn?: InputMaybe<Scalars["String"]["input"]>;
  descriptionJa?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  orderNum: Scalars["Int"]["input"];
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
  uniqueId: Scalars["String"]["input"];
};

export type UpdateAlgorithmQuestionInput = {
  answers: Array<UpdateAlgorithmAnswerInput>;
  companyId: Scalars["Int"]["input"];
  /** @deprecated Use correctnessTestCaseIds instead */
  correctnessTestCase?: InputMaybe<Scalars["String"]["input"]>;
  correctnessTestCaseIds?: InputMaybe<OptionalAlgorithmCorrectnessTestCaseIdsInput>;
  descriptionEn?: InputMaybe<Scalars["String"]["input"]>;
  descriptionJa?: InputMaybe<Scalars["String"]["input"]>;
  difficulty: Difficulty;
  employeeId: Scalars["String"]["input"];
  hints: Array<UpdateAlgorithmHintInput>;
  isPrivate: Scalars["Boolean"]["input"];
  newVersion: Scalars["String"]["input"];
  oldVersion: Scalars["String"]["input"];
  /** @deprecated Use performanceTestCaseIds instead */
  performanceTestCase?: InputMaybe<Scalars["String"]["input"]>;
  performanceTestCaseIds?: InputMaybe<OptionalAlgorithmPerformanceTestCaseIdsInput>;
  performanceTimeLimitMilliSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  questionId: Scalars["Int"]["input"];
  signature?: InputMaybe<Scalars["String"]["input"]>;
  status: QuestionStatus;
  timeLimitMin: Scalars["Int"]["input"];
  titleEn?: InputMaybe<Scalars["String"]["input"]>;
  titleJa?: InputMaybe<Scalars["String"]["input"]>;
  variant: AlgorithmQuestionVariant;
};

export type UpdateAssessmentRequestInput = {
  assessmentId: Scalars["String"]["input"];
  assessmentStatus?: InputMaybe<AssessmentStatus>;
  entitySources?: InputMaybe<Array<AssessmentEntitySourceInput>>;
  examInterval?: InputMaybe<AssessmentExamInterval>;
  isPublic?: InputMaybe<Scalars["Boolean"]["input"]>;
  memo?: InputMaybe<Scalars["String"]["input"]>;
  messageForTalent?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  optionalRemindBefore?: InputMaybe<AssessmentOptionalRemindBeforeInput>;
  /** @deprecated: use optionalRemindBefore instead */
  remindBeforeSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  reportSettings?: InputMaybe<AssessmentReportSettingsInput>;
  startScheduleAtSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  timeLimitSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  viewerIds?: InputMaybe<Array<AssessmentViewerIdInput>>;
};

export type UpdateClaimsInput = {
  companyId?: InputMaybe<Scalars["Int"]["input"]>;
  planType?: InputMaybe<PaymentItemType>;
  role: Role;
  uid: Scalars["String"]["input"];
  /** TODO Remove after userType is removed from UpdateClaim rpc */
  userType?: InputMaybe<UserType>;
};

export type UpdateClaimsV2Input = {
  companyId?: InputMaybe<Scalars["Int"]["input"]>;
  planType?: InputMaybe<PaymentItemType>;
  role?: InputMaybe<Role>;
  uid: Scalars["String"]["input"];
  userType?: InputMaybe<UserType>;
};

export type UpdateCompanySkillTagsInput = {
  companyId: Scalars["Int"]["input"];
  skillTagIds: Array<Scalars["String"]["input"]>;
};

export type UpdateCustomerInput = {
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  customerId: Scalars["Int"]["input"];
  email?: InputMaybe<Scalars["String"]["input"]>;
  isAutoRenewal?: InputMaybe<Scalars["Boolean"]["input"]>;
  language?: InputMaybe<Language>;
  lastFour?: InputMaybe<Scalars["String"]["input"]>;
  line1?: InputMaybe<Scalars["String"]["input"]>;
  line2?: InputMaybe<Scalars["String"]["input"]>;
  paymentMethod?: InputMaybe<Scalars["String"]["input"]>;
  paymentType?: InputMaybe<PaymentType>;
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateCustomerResponse = {
  __typename?: "UpdateCustomerResponse";
  customer: PaymentV2Customer;
  errorCode: Maybe<PaymentErrorCode>;
};

export type UpdateEvaluationMetricGroupInput = {
  description: Scalars["String"]["input"];
  metricGroupId: Scalars["Int"]["input"];
  title: Scalars["String"]["input"];
};

export type UpdateEvaluationMetricGroupPayload = {
  __typename?: "UpdateEvaluationMetricGroupPayload";
  description: Scalars["String"]["output"];
  metricGroupId: Scalars["Int"]["output"];
  title: Scalars["String"]["output"];
};

export type UpdateEvaluationMetricInput = {
  description: Scalars["String"]["input"];
  metricId: Scalars["Int"]["input"];
  title: Scalars["String"]["input"];
};

export type UpdateEvaluationMetricPayload = {
  __typename?: "UpdateEvaluationMetricPayload";
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  metricId: Scalars["Int"]["output"];
  title: Scalars["String"]["output"];
};

export type UpdateFreepadQuestionInput = {
  answers: Array<FreepadAnswerInput>;
  companyId: Scalars["Int"]["input"];
  descriptionEn?: InputMaybe<Scalars["String"]["input"]>;
  descriptionJa?: InputMaybe<Scalars["String"]["input"]>;
  difficulty: Difficulty;
  employeeId: Scalars["String"]["input"];
  id: Scalars["Int"]["input"];
  initialCodes: Array<FreepadInitialCodeInput>;
  initialFlowCharts: Array<FreepadInitialFlowChartInput>;
  isPrivate: Scalars["Boolean"]["input"];
  newVersion: Scalars["String"]["input"];
  oldVersion: Scalars["String"]["input"];
  status: QuestionStatus;
  tags: Array<Scalars["String"]["input"]>;
  titleEn?: InputMaybe<Scalars["String"]["input"]>;
  titleJa?: InputMaybe<Scalars["String"]["input"]>;
  variant: FreepadQuestionVariant;
};

export type UpdateMultiChoicePackage = {
  companyId: Scalars["Int"]["input"];
  descriptionEn?: InputMaybe<Scalars["String"]["input"]>;
  descriptionJa?: InputMaybe<Scalars["String"]["input"]>;
  difficulty: Difficulty;
  employeeId: Scalars["String"]["input"];
  id: Scalars["Int"]["input"];
  isPrivate: Scalars["Boolean"]["input"];
  newVersion: Scalars["String"]["input"];
  oldVersion: Scalars["String"]["input"];
  questionKeys: Array<CompositePrimaryKey>;
  status: QuestionStatus;
  timeLimitSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  titleEn?: InputMaybe<Scalars["String"]["input"]>;
  titleJa?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateMultiChoiceQuestionInput = {
  companyId: Scalars["Int"]["input"];
  descriptionEn?: InputMaybe<Scalars["String"]["input"]>;
  descriptionJa?: InputMaybe<Scalars["String"]["input"]>;
  difficulty: Difficulty;
  employeeId: Scalars["String"]["input"];
  evaluationItems: Array<MultiChoiceEvaluationItemInput>;
  id: Scalars["Int"]["input"];
  isPrivate: Scalars["Boolean"]["input"];
  metricType: MultiChoiceMetricType;
  newVersion: Scalars["String"]["input"];
  oldVersion: Scalars["String"]["input"];
  options: Array<MultiChoiceOptionInput>;
  status: QuestionStatus;
  tags: Array<MultiChoiceTagInput>;
  titleEn?: InputMaybe<Scalars["String"]["input"]>;
  titleJa?: InputMaybe<Scalars["String"]["input"]>;
  variant: MultiChoiceQuestionVariant;
};

export type UpdateRemoteInput = {
  candidateName?: InputMaybe<Scalars["String"]["input"]>;
  entitySources?: InputMaybe<Array<RemoteEntitySourceInput>>;
  isPublic?: InputMaybe<Scalars["Boolean"]["input"]>;
  memo?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  remoteId: Scalars["String"]["input"];
  tagNames?: InputMaybe<Array<Scalars["String"]["input"]>>;
  viewerIds?: InputMaybe<Array<RemoteViewerIdInput>>;
};

export type UpdateRemoteInterviewTemplateInput = {
  candidateName: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
  entitySources: Array<RemoteEntitySourceInput>;
  isPublic?: InputMaybe<Scalars["Boolean"]["input"]>;
  memo?: InputMaybe<Scalars["String"]["input"]>;
  remoteName: Scalars["String"]["input"];
  reviewerIds: Array<RemoteInterviewReviewerInput>;
  tagNames?: InputMaybe<Array<Scalars["String"]["input"]>>;
  templateId: Scalars["Int"]["input"];
  title: Scalars["String"]["input"];
  updatedBy: Scalars["String"]["input"];
  variables: Array<TemplateVariableInput>;
};

export type UpdateScreeningInput = {
  allowChatGPT?: InputMaybe<Scalars["Boolean"]["input"]>;
  allowHint?: InputMaybe<Scalars["Boolean"]["input"]>;
  allowWebSearch?: InputMaybe<Scalars["Boolean"]["input"]>;
  candidateAccessPolicy?: InputMaybe<ScreeningCandidateAccessPolicy>;
  companyId?: InputMaybe<Scalars["Int"]["input"]>;
  creatorId?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** @deprecated @Himenon will remove */
  entitySources?: InputMaybe<Array<SpotEntitySourceInput>>;
  entityTrackInputs?: InputMaybe<Array<CreateScreeningEntityTrackInput>>;
  invitationLanguage?: InputMaybe<Language>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  memo?: InputMaybe<Scalars["String"]["input"]>;
  messageForCandidate?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  optionalAcceptableLimit?: InputMaybe<OptionalAcceptableLimit>;
  optionalDeadline?: InputMaybe<OptionalDeadline>;
  ownerEmailAddress?: InputMaybe<OptionalOwnerEmailAddressInput>;
  screeningId: Scalars["String"]["input"];
  spotIsPublic?: InputMaybe<Scalars["Boolean"]["input"]>;
  tagNames?: InputMaybe<Array<Scalars["String"]["input"]>>;
  timeLimitSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  variables?: InputMaybe<Array<ScreeningVariableInput>>;
  viewerIds?: InputMaybe<Array<SpotViewerInput>>;
};

export type UpdateSecurityInput = {
  companyId: Scalars["Int"]["input"];
  emailDomain: Scalars["String"]["input"];
  idpCertificate: Scalars["String"]["input"];
  idpEntityId: Scalars["String"]["input"];
  idpSsoUrl: Scalars["String"]["input"];
  ssoEnabled: Scalars["Boolean"]["input"];
  ssoMode: SsoMode;
};

export type UpdateSlackSettingResponse = {
  __typename?: "UpdateSlackSettingResponse";
  companyId: Scalars["Int"]["output"];
  language: Scalars["String"]["output"];
  remoteSettings: Maybe<RemoteSettingsSubscribeSlack>;
  spotSettings: Maybe<SpotSettingsSubscribeSlack>;
};

export type UpdateSpotInput = {
  /** @deprecated Delete after process migration to Screening */
  allowChatGPT?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** @deprecated Delete after process migration to Screening */
  allowWebSearch?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** @deprecated Delete after process migration to Screening */
  entitySources?: InputMaybe<Array<SpotEntitySourceInput>>;
  isPublic?: InputMaybe<Scalars["Boolean"]["input"]>;
  memo?: InputMaybe<Scalars["String"]["input"]>;
  /** @deprecated Delete after process migration to Screening */
  messageForCandidate?: InputMaybe<Scalars["String"]["input"]>;
  /** @deprecated Delete after process migration to Screening */
  name?: InputMaybe<Scalars["String"]["input"]>;
  ownerEmailAddress?: InputMaybe<OptionalOwnerEmailAddressInput>;
  /** @deprecated Please use viewerIds */
  reviewerIds?: InputMaybe<Array<SpotReviewerInput>>;
  spotId: Scalars["String"]["input"];
  tagNames?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** @deprecated Delete after process migration to Screening */
  timeLimitSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  viewerIds?: InputMaybe<Array<SpotViewerInput>>;
  /** @deprecated Delete after process migration to Screening */
  willEndAtSeconds?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UpdateTagsToScreeningsInput = {
  addTagNames: Array<Scalars["String"]["input"]>;
  deleteTagNames: Array<Scalars["String"]["input"]>;
  screeningIds: Array<Scalars["String"]["input"]>;
};

export type UpdateTagsToSpotsInput = {
  addTagNames: Array<Scalars["String"]["input"]>;
  deleteTagNames: Array<Scalars["String"]["input"]>;
  spotIds: Array<Scalars["String"]["input"]>;
};

export type UpdateUserInput = {
  displayName: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
  language: Language;
  photoUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateUserTypeInput = {
  uid: Scalars["String"]["input"];
  userType: UserType;
};

export type UsagesV2Response = {
  __typename?: "UsagesV2Response";
  count: Scalars["Int"]["output"];
  pager: Maybe<PaymentPager>;
  usages: Array<PaymentUsage>;
};

export type User = Node & {
  __typename?: "User";
  displayName: Scalars["String"]["output"];
  email: Scalars["String"]["output"];
  emailVerified: Scalars["Boolean"]["output"];
  fullName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  isSystemAdmin: Scalars["Boolean"]["output"];
  language: Language;
  photoUrl: Scalars["String"]["output"];
  provider: Maybe<AuthProvider>;
  providerId: Scalars["String"]["output"];
  uid: Scalars["String"]["output"];
  userType: UserType;
};

export type UserSeat = {
  __typename?: "UserSeat";
  createdAtSeconds: Maybe<Scalars["Int"]["output"]>;
  deletedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  descriptionEn: Scalars["String"]["output"];
  descriptionJa: Scalars["String"]["output"];
  generation: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  nameEn: Scalars["String"]["output"];
  nameJa: Scalars["String"]["output"];
  price: Scalars["Int"]["output"];
  quantity: Scalars["Int"]["output"];
  seatId: Scalars["Int"]["output"];
  updatedAtSeconds: Maybe<Scalars["Int"]["output"]>;
};

export const UserType = {
  Candidate: "CANDIDATE",
  Employee: "EMPLOYEE",
  Talent: "TALENT",
  Unknown: "UNKNOWN",
} as const;

export type UserType = (typeof UserType)[keyof typeof UserType];
export type VideoChatConversation = Node & {
  __typename?: "VideoChatConversation";
  conversationId: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  participants: Array<VideoChatConversationParticipant>;
  status: VideoChatConversationStatus;
};

export type VideoChatConversationInput = {
  conversationId: Scalars["String"]["input"];
};

export type VideoChatConversationParticipant = {
  __typename?: "VideoChatConversationParticipant";
  conversationId: Scalars["String"]["output"];
  displayName: Scalars["String"]["output"];
  userId: Scalars["String"]["output"];
  userType: VideoChatUserType;
};

export const VideoChatConversationStatus = {
  Active: "ACTIVE",
  Closed: "CLOSED",
  Unknown: "UNKNOWN",
} as const;

export type VideoChatConversationStatus = (typeof VideoChatConversationStatus)[keyof typeof VideoChatConversationStatus];
export type VideoChatRoom = Node & {
  __typename?: "VideoChatRoom";
  id: Scalars["ID"]["output"];
  participants: Array<VideoChatRoomParticipant>;
  roomId: Scalars["String"]["output"];
  status: VideoChatRoomStatus;
};

export type VideoChatRoomInput = {
  roomId: Scalars["String"]["input"];
};

export type VideoChatRoomParticipant = {
  __typename?: "VideoChatRoomParticipant";
  displayName: Scalars["String"]["output"];
  roomId: Scalars["String"]["output"];
  userId: Scalars["String"]["output"];
  userType: VideoChatUserType;
};

export const VideoChatRoomStatus = {
  Active: "ACTIVE",
  Closed: "CLOSED",
  Unknown: "UNKNOWN",
} as const;

export type VideoChatRoomStatus = (typeof VideoChatRoomStatus)[keyof typeof VideoChatRoomStatus];
export const VideoChatUserType = {
  Candidate: "CANDIDATE",
  Employee: "EMPLOYEE",
  Unknown: "UNKNOWN",
} as const;

export type VideoChatUserType = (typeof VideoChatUserType)[keyof typeof VideoChatUserType];
export type ViewExamEvaluationInput = {
  comment: Scalars["String"]["input"];
  metricId: Scalars["Int"]["input"];
  numStars: Scalars["Int"]["input"];
};

export type WebAccessedEvent = {
  __typename?: "WebAccessedEvent";
  accessedAtNanoSeconds: Maybe<Scalars["Int"]["output"]>;
  accessedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  description: Scalars["String"]["output"];
  eventId: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
};

export type WebBehavioralEvent = WebAccessedEvent | WebSearchedEvent;

export type WebSearchedEvent = {
  __typename?: "WebSearchedEvent";
  eventId: Scalars["String"]["output"];
  /** When page is NULL, it means user searched. Otherwise, user moved page. */
  page: Maybe<Scalars["Int"]["output"]>;
  query: Scalars["String"]["output"];
  searchedAtNanoSeconds: Maybe<Scalars["Int"]["output"]>;
  searchedAtSeconds: Maybe<Scalars["Int"]["output"]>;
  sessionId: Scalars["String"]["output"];
};

export type UpdateClaimsForCandidateMutationVariables = Exact<{
  updateClaimsInput: UpdateClaimsInput;
}>;

export type UpdateClaimsForCandidateMutation = { __typename?: "Mutation"; updateClaims: boolean };

export type UpdateUserTypeForCandidateMutationVariables = Exact<{
  updateUserTypeInput: UpdateUserTypeInput;
}>;

export type UpdateUserTypeForCandidateMutation = { __typename?: "Mutation"; updateUserType: { __typename?: "User"; uid: string } };

export type EssentialEmployeeFragment = {
  __typename?: "User";
  uid: string;
  displayName: string;
  photoUrl: string;
  email: string;
  emailVerified: boolean;
  fullName: string;
  language: Language;
  provider: AuthProvider | null;
  userType: UserType;
};

export type EssentialCompanyEmployeeFragment = { __typename?: "CompanyEmployee"; employeeId: string; role: Role };

export type EssentialCompanyFragment = {
  __typename?: "Company";
  companyId: number;
  name: string;
  isHireRoo: boolean;
  enableTalentScore: boolean;
  companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string; role: Role }>;
  security: { __typename?: "Security"; ssoEnabled: boolean; ssoMode: SsoMode; providerId: string } | null;
};

export type EssentialCompaniesFragment = {
  __typename?: "ListCompanies";
  activeCompanyId: number;
  companies: Array<{
    __typename?: "Company";
    companyId: number;
    name: string;
    isHireRoo: boolean;
    enableTalentScore: boolean;
    companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string; role: Role }>;
    security: { __typename?: "Security"; ssoEnabled: boolean; ssoMode: SsoMode; providerId: string } | null;
  }>;
  pager: { __typename?: "CompaniesByTalentIdTimestampPager"; cursorSeconds: number | null; hasNext: boolean } | null;
};

export type InitializeForEmployeeQueryVariables = Exact<{
  uid: Scalars["String"]["input"];
  size: Scalars["Int"]["input"];
}>;

export type InitializeForEmployeeQuery = {
  __typename?: "Query";
  companiesByEmployeeId: {
    __typename?: "ListCompanies";
    activeCompanyId: number;
    companies: Array<{
      __typename?: "Company";
      companyId: number;
      name: string;
      isHireRoo: boolean;
      enableTalentScore: boolean;
      companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string; role: Role }>;
      security: { __typename?: "Security"; ssoEnabled: boolean; ssoMode: SsoMode; providerId: string } | null;
    }>;
    pager: { __typename?: "CompaniesByTalentIdTimestampPager"; cursorSeconds: number | null; hasNext: boolean } | null;
  };
  currentUser: {
    __typename?: "User";
    uid: string;
    displayName: string;
    photoUrl: string;
    email: string;
    emailVerified: boolean;
    fullName: string;
    language: Language;
    provider: AuthProvider | null;
    userType: UserType;
  };
};

export type EssentialSecurityForEmployeeFragment = { __typename?: "Security"; ssoEnabled: boolean; ssoMode: SsoMode; providerId: string };

export type GetEssentialCompanyWithSecurityForEmployeeQueryVariables = Exact<{
  activeCompanyId: Scalars["Int"]["input"];
}>;

export type GetEssentialCompanyWithSecurityForEmployeeQuery = {
  __typename?: "Query";
  company: {
    __typename?: "Company";
    companyId: number;
    name: string;
    enableTalentScore: boolean;
    companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string; role: Role }>;
    security: { __typename?: "Security"; ssoEnabled: boolean; ssoMode: SsoMode; providerId: string } | null;
  };
};

export type EssentialCustomerForEmployeeFragment = {
  __typename?: "PaymentV2Customer";
  paymentStatus: PaymentStatus;
  paymentType: PaymentType;
  isTrial: boolean;
  createdAtSeconds: number | null;
  subscription: {
    __typename?: "PaymentV2Subscription";
    status: PaymentV2SubscriptionStatus;
    packStock: number;
    didStartAtSeconds: number | null;
    plan: { __typename?: "PaymentV2Plan"; generation: number; planType: PlanType };
  };
};

export type GetEssentialCustomerForEmployeeQueryVariables = Exact<{
  customerId: Scalars["Int"]["input"];
}>;

export type GetEssentialCustomerForEmployeeQuery = {
  __typename?: "Query";
  customer: {
    __typename?: "PaymentV2Customer";
    paymentStatus: PaymentStatus;
    paymentType: PaymentType;
    isTrial: boolean;
    createdAtSeconds: number | null;
    subscription: {
      __typename?: "PaymentV2Subscription";
      status: PaymentV2SubscriptionStatus;
      packStock: number;
      didStartAtSeconds: number | null;
      plan: { __typename?: "PaymentV2Plan"; generation: number; planType: PlanType };
    };
  };
};

export type CurrentUserQueryVariables = Exact<{
  uid: Scalars["String"]["input"];
}>;

export type CurrentUserQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    id: string;
    displayName: string;
    photoUrl: string;
    emailVerified: boolean;
    email: string;
    fullName: string;
    language: Language;
  };
};

export type UpdateClaimsForEmployeeMutationVariables = Exact<{
  updateClaimsInput: UpdateClaimsInput;
}>;

export type UpdateClaimsForEmployeeMutation = { __typename?: "Mutation"; updateClaims: boolean };

export type SwitchActiveCompanyForEmployeeMutationVariables = Exact<{
  input: SwitchActiveCompanyForEmployeeInput;
}>;

export type SwitchActiveCompanyForEmployeeMutation = { __typename?: "Mutation"; switchActiveCompanyForEmployee: boolean };

export type GetCurrentUserQueryVariables = Exact<{
  uid: Scalars["String"]["input"];
}>;

export type GetCurrentUserQuery = { __typename?: "Query"; currentUser: { __typename?: "User"; userType: UserType } };

export type UpdateUserTypeForSignedInUserMutationVariables = Exact<{
  updateUserTypeInput: UpdateUserTypeInput;
}>;

export type UpdateUserTypeForSignedInUserMutation = { __typename?: "Mutation"; updateUserType: { __typename?: "User"; uid: string } };

export type InitializeParamsForEmployeeFragment = {
  __typename: "InitializedEmployeeUserResponse";
  shouldAction: ClientSideInitializeAction | null;
  employeeRole: Role;
  activeCompany: {
    __typename?: "Company";
    companyId: number;
    name: string;
    isHireRoo: boolean;
    enableTalentScore: boolean;
    companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string; role: Role }>;
    security: { __typename?: "Security"; ssoEnabled: boolean; ssoMode: SsoMode; providerId: string } | null;
  };
  belongsCompanies: Array<{
    __typename?: "Company";
    companyId: number;
    name: string;
    isHireRoo: boolean;
    enableTalentScore: boolean;
    companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string; role: Role }>;
    security: { __typename?: "Security"; ssoEnabled: boolean; ssoMode: SsoMode; providerId: string } | null;
  }>;
  customer: {
    __typename?: "PaymentV2Customer";
    paymentStatus: PaymentStatus;
    paymentType: PaymentType;
    isTrial: boolean;
    createdAtSeconds: number | null;
    subscription: {
      __typename?: "PaymentV2Subscription";
      status: PaymentV2SubscriptionStatus;
      packStock: number;
      didStartAtSeconds: number | null;
      plan: { __typename?: "PaymentV2Plan"; generation: number; planType: PlanType };
    };
  };
  currentUser: {
    __typename?: "User";
    uid: string;
    displayName: string;
    photoUrl: string;
    email: string;
    emailVerified: boolean;
    fullName: string;
    language: Language;
    provider: AuthProvider | null;
    userType: UserType;
  };
};

export type InitializeParamsForTalentFragment = {
  __typename: "InitializedTalentUserResponse";
  shouldAction: ClientSideInitializeAction | null;
  activeCompany: {
    __typename?: "Company";
    companyId: number;
    name: string;
    isHireRoo: boolean;
    enableTalentScore: boolean;
    companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string; role: Role }>;
    security: { __typename?: "Security"; ssoEnabled: boolean; ssoMode: SsoMode; providerId: string } | null;
  };
  belongsCompanies: Array<{
    __typename?: "Company";
    companyId: number;
    name: string;
    isHireRoo: boolean;
    enableTalentScore: boolean;
    companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string; role: Role }>;
    security: { __typename?: "Security"; ssoEnabled: boolean; ssoMode: SsoMode; providerId: string } | null;
  }>;
  currentUser: {
    __typename?: "User";
    uid: string;
    displayName: string;
    photoUrl: string;
    email: string;
    emailVerified: boolean;
    fullName: string;
    language: Language;
    provider: AuthProvider | null;
    userType: UserType;
  };
};

export type InitializeParamsForAuthFragment = {
  __typename: "InitializedAuthResponse";
  user: { __typename?: "User"; userType: UserType } | null;
};

export type InitializeParamsForInvitationEmployeeFragment = {
  __typename: "InitializedInvitationEmployeeResponse";
  user: { __typename?: "User"; userType: UserType } | null;
};

export type InitializeParamsForInvitationTalentFragment = {
  __typename: "InitializedInvitationTalentResponse";
  user: { __typename?: "User"; userType: UserType } | null;
};

export type InitializeAppMutationVariables = Exact<{
  input: InitializeInput;
}>;

export type InitializeAppMutation = {
  __typename?: "Mutation";
  initializeApp:
    | { __typename: "InitializedAuthResponse"; user: { __typename?: "User"; userType: UserType } | null }
    | { __typename: "InitializedCandidateUserResponse"; reserved: boolean | null }
    | {
        __typename: "InitializedEmployeeUserResponse";
        shouldAction: ClientSideInitializeAction | null;
        employeeRole: Role;
        activeCompany: {
          __typename?: "Company";
          companyId: number;
          name: string;
          isHireRoo: boolean;
          enableTalentScore: boolean;
          companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string; role: Role }>;
          security: { __typename?: "Security"; ssoEnabled: boolean; ssoMode: SsoMode; providerId: string } | null;
        };
        belongsCompanies: Array<{
          __typename?: "Company";
          companyId: number;
          name: string;
          isHireRoo: boolean;
          enableTalentScore: boolean;
          companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string; role: Role }>;
          security: { __typename?: "Security"; ssoEnabled: boolean; ssoMode: SsoMode; providerId: string } | null;
        }>;
        customer: {
          __typename?: "PaymentV2Customer";
          paymentStatus: PaymentStatus;
          paymentType: PaymentType;
          isTrial: boolean;
          createdAtSeconds: number | null;
          subscription: {
            __typename?: "PaymentV2Subscription";
            status: PaymentV2SubscriptionStatus;
            packStock: number;
            didStartAtSeconds: number | null;
            plan: { __typename?: "PaymentV2Plan"; generation: number; planType: PlanType };
          };
        };
        currentUser: {
          __typename?: "User";
          uid: string;
          displayName: string;
          photoUrl: string;
          email: string;
          emailVerified: boolean;
          fullName: string;
          language: Language;
          provider: AuthProvider | null;
          userType: UserType;
        };
      }
    | { __typename: "InitializedInvitationEmployeeResponse"; user: { __typename?: "User"; userType: UserType } | null }
    | { __typename: "InitializedInvitationTalentResponse"; user: { __typename?: "User"; userType: UserType } | null }
    | {
        __typename: "InitializedTalentUserResponse";
        shouldAction: ClientSideInitializeAction | null;
        activeCompany: {
          __typename?: "Company";
          companyId: number;
          name: string;
          isHireRoo: boolean;
          enableTalentScore: boolean;
          companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string; role: Role }>;
          security: { __typename?: "Security"; ssoEnabled: boolean; ssoMode: SsoMode; providerId: string } | null;
        };
        belongsCompanies: Array<{
          __typename?: "Company";
          companyId: number;
          name: string;
          isHireRoo: boolean;
          enableTalentScore: boolean;
          companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string; role: Role }>;
          security: { __typename?: "Security"; ssoEnabled: boolean; ssoMode: SsoMode; providerId: string } | null;
        }>;
        currentUser: {
          __typename?: "User";
          uid: string;
          displayName: string;
          photoUrl: string;
          email: string;
          emailVerified: boolean;
          fullName: string;
          language: Language;
          provider: AuthProvider | null;
          userType: UserType;
        };
      }
    | { __typename: "NeedClientSideActionResponse"; action: ClientSideInitializeAction };
};

export type SwitchActiveCompanyForEmployeeV2MutationVariables = Exact<{
  input: SwitchActiveCompanyForEmployeeV2Input;
}>;

export type SwitchActiveCompanyForEmployeeV2Mutation = { __typename?: "Mutation"; switchActiveCompanyForEmployeeV2: boolean };

export type SwitchActiveCompanyForTalentV2MutationVariables = Exact<{
  input: SwitchActiveCompanyForTalentV2Input;
}>;

export type SwitchActiveCompanyForTalentV2Mutation = { __typename?: "Mutation"; switchActiveCompanyForTalentV2: boolean };

export type UpdateClaimsV2MutationVariables = Exact<{
  input: UpdateClaimsV2Input;
}>;

export type UpdateClaimsV2Mutation = { __typename?: "Mutation"; updateClaimsV2: boolean };

export type EssentialTalentFragment = {
  __typename?: "User";
  uid: string;
  displayName: string;
  photoUrl: string;
  email: string;
  emailVerified: boolean;
  fullName: string;
  language: Language;
  provider: AuthProvider | null;
  userType: UserType;
};

export type EssentialCompanyForTalentFragment = { __typename?: "Company"; companyId: number; name: string; enableTalentScore: boolean };

export type EssentialCompaniesForTalentFragment = {
  __typename?: "CompaniesByTalentId";
  activeId: number;
  companies: Array<{ __typename?: "Company"; companyId: number; name: string; enableTalentScore: boolean }>;
};

export type InitializeForTalentQueryVariables = Exact<{
  talentId: Scalars["String"]["input"];
  cursorSeconds: InputMaybe<Scalars["Int"]["input"]>;
  size: Scalars["Int"]["input"];
  withActiveId: Scalars["Boolean"]["input"];
}>;

export type InitializeForTalentQuery = {
  __typename?: "Query";
  companiesByTalentId: {
    __typename?: "CompaniesByTalentId";
    activeId: number;
    companies: Array<{ __typename?: "Company"; companyId: number; name: string; enableTalentScore: boolean }>;
  };
  currentUser: {
    __typename?: "User";
    uid: string;
    displayName: string;
    photoUrl: string;
    email: string;
    emailVerified: boolean;
    fullName: string;
    language: Language;
    provider: AuthProvider | null;
    userType: UserType;
  };
};

export type SwitchActiveCompanyForTalentMutationVariables = Exact<{
  input: SwitchActiveCompanyForTalentInput;
}>;

export type SwitchActiveCompanyForTalentMutation = { __typename?: "Mutation"; switchActiveCompanyForTalent: boolean };

export type EssentialCustomerForTalentFragment = {
  __typename?: "PaymentV2Customer";
  subscription: {
    __typename?: "PaymentV2Subscription";
    packStock: number;
    plan: { __typename?: "PaymentV2Plan"; generation: number; planType: PlanType };
  };
};

export type GetEssentialCustomerForTalentQueryVariables = Exact<{
  customerId: Scalars["Int"]["input"];
}>;

export type GetEssentialCustomerForTalentQuery = {
  __typename?: "Query";
  customer: {
    __typename?: "PaymentV2Customer";
    subscription: {
      __typename?: "PaymentV2Subscription";
      packStock: number;
      plan: { __typename?: "PaymentV2Plan"; generation: number; planType: PlanType };
    };
  };
};

export type UpdateClaimsForTalentMutationVariables = Exact<{
  updateClaimsInput: UpdateClaimsInput;
}>;

export type UpdateClaimsForTalentMutation = { __typename?: "Mutation"; updateClaims: boolean };

export type EssentialSecurityForTalentFragment = { __typename?: "Security"; ssoEnabled: boolean; ssoMode: SsoMode; providerId: string };

export type GetEssentialCompanyWithSecurityForTalentQueryVariables = Exact<{
  activeCompanyId: Scalars["Int"]["input"];
}>;

export type GetEssentialCompanyWithSecurityForTalentQuery = {
  __typename?: "Query";
  company: { __typename?: "Company"; security: { __typename?: "Security"; ssoEnabled: boolean; ssoMode: SsoMode; providerId: string } | null };
};

export type DemoForCandidateDemosIdFragment = {
  __typename?: "Demo";
  demoId: string;
  status: DemoStatus;
  timeLimitSeconds: number | null;
  createdAtSeconds: number | null;
  allowWebSearch: boolean;
  allowChatGPT: boolean;
  candidate: { __typename?: "User"; displayName: string } | null;
  entities: Array<
    | {
        __typename: "ChallengeEntity";
        challengeEntityId: number;
        questionVersion: string;
        enabledLanguages: Array<string>;
        appealMessage: string | null;
        challengeStatus: ChallengeStatus;
        challengeQuestion: {
          __typename?: "AlgorithmQuestion";
          id: string;
          questionId: number;
          version: string;
          variant: AlgorithmQuestionVariant;
          companyId: number;
          employeeId: string;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          difficulty: Difficulty;
          supportedLanguages: Array<string>;
          signature: string;
          correctnessTestCase: string;
          performanceTestCase: string;
          performanceTimeLimitMilliSeconds: number;
          hints: Array<{
            __typename?: "AlgorithmHint";
            id: number;
            questionId: number;
            questionVersion: string;
            descriptionJa: string | null;
            descriptionEn: string | null;
            orderNum: number;
          }>;
          initialCode: {
            __typename?: "AlgorithmInitialCode";
            c: string;
            cpp: string;
            csharp: string;
            dart: string;
            go: string;
            java: string;
            javascript: string;
            kotlin: string;
            mysql: string;
            perl: string;
            pgsql: string;
            php: string;
            python3: string;
            ruby: string;
            rust: string;
            scala: string;
            sqlite: string;
            swift: string;
            typescript: string;
          } | null;
        } | null;
        submissions: Array<{ __typename?: "ChallengeSubmission"; challengeSubmissionId: number }>;
      }
    | {
        __typename: "ProjectEntity";
        projectEntityId: number;
        appealMessage: string | null;
        agentVersion: string;
        projectStatus: ProjectStatus;
        question: {
          __typename?: "ProjectQuestion";
          questionId: number;
          version: string;
          projectVersion: string;
          variant: ProjectQuestionVariant;
          titleJa: string | null;
          titleEn: string | null;
          correctnessTestCase: string;
          performanceTestCase: string;
          defaultTestCase: string;
          descriptionJa: string | null;
          descriptionEn: string | null;
          timeLimitSeconds: number | null;
          fileIndexes: Array<string>;
          readOnlyFiles: Array<string>;
        } | null;
        submissions: Array<{ __typename?: "ProjectSubmission"; projectSubmissionId: number }>;
      }
    | {
        __typename: "QuizEntity";
        quizEntityId: number;
        packageVersion: string;
        quizStatus: QuizStatus;
        pb_package: {
          __typename?: "MultiChoicePackage";
          packageId: number;
          version: string;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          questions: Array<{
            __typename?: "MultiChoiceQuestion";
            id: number;
            version: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            variant: MultiChoiceQuestionVariant;
            options: Array<{
              __typename?: "MultiChoiceOption";
              id: number;
              titleJa: string | null;
              titleEn: string | null;
              isCorrect: boolean;
            }>;
          }>;
        } | null;
        submissions: Array<{
          __typename?: "QuizSubmission";
          quizSubmissionId: number;
          questionId: number;
          questionVersion: string;
          optionIds: Array<number>;
        }>;
      }
    | {
        __typename: "SystemDesignEntity";
        systemDesignEntityId: number;
        status: SystemDesignStatus;
        companyId: number;
        candidateId: string;
        employeeId: string;
        appealMessage: string | null;
        componentTypes: Array<SystemDesignComponentType>;
        question: {
          __typename?: "SystemDesignQuestion";
          questionId: number;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          initialSnapshot: string;
          hints: Array<{
            __typename?: "SystemDesignHint";
            systemDesignHintId: number;
            descriptionJa: string | null;
            descriptionEn: string | null;
          }>;
          scoringItems: Array<{
            __typename?: "SystemDesignScoringItem";
            systemDesignScoringItemId: number;
            category: SystemDesignScoringItemCategory;
            isHidden: boolean;
          }>;
        } | null;
        submissions: Array<{ __typename?: "SystemDesignSubmission"; systemDesignSubmissionId: number; snapshot: string }>;
      }
  >;
};

export type GetDemoForCandidateDemosIdQueryVariables = Exact<{
  demoId: Scalars["String"]["input"];
  candidateId: Scalars["String"]["input"];
}>;

export type GetDemoForCandidateDemosIdQuery = {
  __typename?: "Query";
  demo: {
    __typename?: "Demo";
    demoId: string;
    status: DemoStatus;
    timeLimitSeconds: number | null;
    createdAtSeconds: number | null;
    allowWebSearch: boolean;
    allowChatGPT: boolean;
    candidate: { __typename?: "User"; displayName: string } | null;
    entities: Array<
      | {
          __typename: "ChallengeEntity";
          challengeEntityId: number;
          questionVersion: string;
          enabledLanguages: Array<string>;
          appealMessage: string | null;
          challengeStatus: ChallengeStatus;
          challengeQuestion: {
            __typename?: "AlgorithmQuestion";
            id: string;
            questionId: number;
            version: string;
            variant: AlgorithmQuestionVariant;
            companyId: number;
            employeeId: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            supportedLanguages: Array<string>;
            signature: string;
            correctnessTestCase: string;
            performanceTestCase: string;
            performanceTimeLimitMilliSeconds: number;
            hints: Array<{
              __typename?: "AlgorithmHint";
              id: number;
              questionId: number;
              questionVersion: string;
              descriptionJa: string | null;
              descriptionEn: string | null;
              orderNum: number;
            }>;
            initialCode: {
              __typename?: "AlgorithmInitialCode";
              c: string;
              cpp: string;
              csharp: string;
              dart: string;
              go: string;
              java: string;
              javascript: string;
              kotlin: string;
              mysql: string;
              perl: string;
              pgsql: string;
              php: string;
              python3: string;
              ruby: string;
              rust: string;
              scala: string;
              sqlite: string;
              swift: string;
              typescript: string;
            } | null;
          } | null;
          submissions: Array<{ __typename?: "ChallengeSubmission"; challengeSubmissionId: number }>;
        }
      | {
          __typename: "ProjectEntity";
          projectEntityId: number;
          appealMessage: string | null;
          agentVersion: string;
          projectStatus: ProjectStatus;
          question: {
            __typename?: "ProjectQuestion";
            questionId: number;
            version: string;
            projectVersion: string;
            variant: ProjectQuestionVariant;
            titleJa: string | null;
            titleEn: string | null;
            correctnessTestCase: string;
            performanceTestCase: string;
            defaultTestCase: string;
            descriptionJa: string | null;
            descriptionEn: string | null;
            timeLimitSeconds: number | null;
            fileIndexes: Array<string>;
            readOnlyFiles: Array<string>;
          } | null;
          submissions: Array<{ __typename?: "ProjectSubmission"; projectSubmissionId: number }>;
        }
      | {
          __typename: "QuizEntity";
          quizEntityId: number;
          packageVersion: string;
          quizStatus: QuizStatus;
          pb_package: {
            __typename?: "MultiChoicePackage";
            packageId: number;
            version: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            questions: Array<{
              __typename?: "MultiChoiceQuestion";
              id: number;
              version: string;
              titleJa: string | null;
              titleEn: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
              variant: MultiChoiceQuestionVariant;
              options: Array<{
                __typename?: "MultiChoiceOption";
                id: number;
                titleJa: string | null;
                titleEn: string | null;
                isCorrect: boolean;
              }>;
            }>;
          } | null;
          submissions: Array<{
            __typename?: "QuizSubmission";
            quizSubmissionId: number;
            questionId: number;
            questionVersion: string;
            optionIds: Array<number>;
          }>;
        }
      | {
          __typename: "SystemDesignEntity";
          systemDesignEntityId: number;
          status: SystemDesignStatus;
          companyId: number;
          candidateId: string;
          employeeId: string;
          appealMessage: string | null;
          componentTypes: Array<SystemDesignComponentType>;
          question: {
            __typename?: "SystemDesignQuestion";
            questionId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            initialSnapshot: string;
            hints: Array<{
              __typename?: "SystemDesignHint";
              systemDesignHintId: number;
              descriptionJa: string | null;
              descriptionEn: string | null;
            }>;
            scoringItems: Array<{
              __typename?: "SystemDesignScoringItem";
              systemDesignScoringItemId: number;
              category: SystemDesignScoringItemCategory;
              isHidden: boolean;
            }>;
          } | null;
          submissions: Array<{ __typename?: "SystemDesignSubmission"; systemDesignSubmissionId: number; snapshot: string }>;
        }
    >;
  };
};

export type CreateDemoFromSpotMutationVariables = Exact<{
  spotId: Scalars["String"]["input"];
  candidateId: Scalars["String"]["input"];
}>;

export type CreateDemoFromSpotMutation = {
  __typename?: "Mutation";
  createDemoFromSpot: {
    __typename?: "Demo";
    demoId: string;
    status: DemoStatus;
    timeLimitSeconds: number | null;
    createdAtSeconds: number | null;
    allowWebSearch: boolean;
    allowChatGPT: boolean;
    candidate: { __typename?: "User"; displayName: string } | null;
    entities: Array<
      | {
          __typename: "ChallengeEntity";
          challengeEntityId: number;
          questionVersion: string;
          enabledLanguages: Array<string>;
          appealMessage: string | null;
          challengeStatus: ChallengeStatus;
          challengeQuestion: {
            __typename?: "AlgorithmQuestion";
            id: string;
            questionId: number;
            version: string;
            variant: AlgorithmQuestionVariant;
            companyId: number;
            employeeId: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            supportedLanguages: Array<string>;
            signature: string;
            correctnessTestCase: string;
            performanceTestCase: string;
            performanceTimeLimitMilliSeconds: number;
            hints: Array<{
              __typename?: "AlgorithmHint";
              id: number;
              questionId: number;
              questionVersion: string;
              descriptionJa: string | null;
              descriptionEn: string | null;
              orderNum: number;
            }>;
            initialCode: {
              __typename?: "AlgorithmInitialCode";
              c: string;
              cpp: string;
              csharp: string;
              dart: string;
              go: string;
              java: string;
              javascript: string;
              kotlin: string;
              mysql: string;
              perl: string;
              pgsql: string;
              php: string;
              python3: string;
              ruby: string;
              rust: string;
              scala: string;
              sqlite: string;
              swift: string;
              typescript: string;
            } | null;
          } | null;
          submissions: Array<{ __typename?: "ChallengeSubmission"; challengeSubmissionId: number }>;
        }
      | {
          __typename: "ProjectEntity";
          projectEntityId: number;
          appealMessage: string | null;
          agentVersion: string;
          projectStatus: ProjectStatus;
          question: {
            __typename?: "ProjectQuestion";
            questionId: number;
            version: string;
            projectVersion: string;
            variant: ProjectQuestionVariant;
            titleJa: string | null;
            titleEn: string | null;
            correctnessTestCase: string;
            performanceTestCase: string;
            defaultTestCase: string;
            descriptionJa: string | null;
            descriptionEn: string | null;
            timeLimitSeconds: number | null;
            fileIndexes: Array<string>;
            readOnlyFiles: Array<string>;
          } | null;
          submissions: Array<{ __typename?: "ProjectSubmission"; projectSubmissionId: number }>;
        }
      | {
          __typename: "QuizEntity";
          quizEntityId: number;
          packageVersion: string;
          quizStatus: QuizStatus;
          pb_package: {
            __typename?: "MultiChoicePackage";
            packageId: number;
            version: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            questions: Array<{
              __typename?: "MultiChoiceQuestion";
              id: number;
              version: string;
              titleJa: string | null;
              titleEn: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
              variant: MultiChoiceQuestionVariant;
              options: Array<{
                __typename?: "MultiChoiceOption";
                id: number;
                titleJa: string | null;
                titleEn: string | null;
                isCorrect: boolean;
              }>;
            }>;
          } | null;
          submissions: Array<{
            __typename?: "QuizSubmission";
            quizSubmissionId: number;
            questionId: number;
            questionVersion: string;
            optionIds: Array<number>;
          }>;
        }
      | {
          __typename: "SystemDesignEntity";
          systemDesignEntityId: number;
          status: SystemDesignStatus;
          companyId: number;
          candidateId: string;
          employeeId: string;
          appealMessage: string | null;
          componentTypes: Array<SystemDesignComponentType>;
          question: {
            __typename?: "SystemDesignQuestion";
            questionId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            initialSnapshot: string;
            hints: Array<{
              __typename?: "SystemDesignHint";
              systemDesignHintId: number;
              descriptionJa: string | null;
              descriptionEn: string | null;
            }>;
            scoringItems: Array<{
              __typename?: "SystemDesignScoringItem";
              systemDesignScoringItemId: number;
              category: SystemDesignScoringItemCategory;
              isHidden: boolean;
            }>;
          } | null;
          submissions: Array<{ __typename?: "SystemDesignSubmission"; systemDesignSubmissionId: number; snapshot: string }>;
        }
    >;
  };
};

export type EndDemoMutationVariables = Exact<{
  demoId: Scalars["String"]["input"];
}>;

export type EndDemoMutation = {
  __typename?: "Mutation";
  endDemo: {
    __typename?: "Demo";
    demoId: string;
    status: DemoStatus;
    timeLimitSeconds: number | null;
    createdAtSeconds: number | null;
    allowWebSearch: boolean;
    allowChatGPT: boolean;
    candidate: { __typename?: "User"; displayName: string } | null;
    entities: Array<
      | {
          __typename: "ChallengeEntity";
          challengeEntityId: number;
          questionVersion: string;
          enabledLanguages: Array<string>;
          appealMessage: string | null;
          challengeStatus: ChallengeStatus;
          challengeQuestion: {
            __typename?: "AlgorithmQuestion";
            id: string;
            questionId: number;
            version: string;
            variant: AlgorithmQuestionVariant;
            companyId: number;
            employeeId: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            supportedLanguages: Array<string>;
            signature: string;
            correctnessTestCase: string;
            performanceTestCase: string;
            performanceTimeLimitMilliSeconds: number;
            hints: Array<{
              __typename?: "AlgorithmHint";
              id: number;
              questionId: number;
              questionVersion: string;
              descriptionJa: string | null;
              descriptionEn: string | null;
              orderNum: number;
            }>;
            initialCode: {
              __typename?: "AlgorithmInitialCode";
              c: string;
              cpp: string;
              csharp: string;
              dart: string;
              go: string;
              java: string;
              javascript: string;
              kotlin: string;
              mysql: string;
              perl: string;
              pgsql: string;
              php: string;
              python3: string;
              ruby: string;
              rust: string;
              scala: string;
              sqlite: string;
              swift: string;
              typescript: string;
            } | null;
          } | null;
          submissions: Array<{ __typename?: "ChallengeSubmission"; challengeSubmissionId: number }>;
        }
      | {
          __typename: "ProjectEntity";
          projectEntityId: number;
          appealMessage: string | null;
          agentVersion: string;
          projectStatus: ProjectStatus;
          question: {
            __typename?: "ProjectQuestion";
            questionId: number;
            version: string;
            projectVersion: string;
            variant: ProjectQuestionVariant;
            titleJa: string | null;
            titleEn: string | null;
            correctnessTestCase: string;
            performanceTestCase: string;
            defaultTestCase: string;
            descriptionJa: string | null;
            descriptionEn: string | null;
            timeLimitSeconds: number | null;
            fileIndexes: Array<string>;
            readOnlyFiles: Array<string>;
          } | null;
          submissions: Array<{ __typename?: "ProjectSubmission"; projectSubmissionId: number }>;
        }
      | {
          __typename: "QuizEntity";
          quizEntityId: number;
          packageVersion: string;
          quizStatus: QuizStatus;
          pb_package: {
            __typename?: "MultiChoicePackage";
            packageId: number;
            version: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            questions: Array<{
              __typename?: "MultiChoiceQuestion";
              id: number;
              version: string;
              titleJa: string | null;
              titleEn: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
              variant: MultiChoiceQuestionVariant;
              options: Array<{
                __typename?: "MultiChoiceOption";
                id: number;
                titleJa: string | null;
                titleEn: string | null;
                isCorrect: boolean;
              }>;
            }>;
          } | null;
          submissions: Array<{
            __typename?: "QuizSubmission";
            quizSubmissionId: number;
            questionId: number;
            questionVersion: string;
            optionIds: Array<number>;
          }>;
        }
      | {
          __typename: "SystemDesignEntity";
          systemDesignEntityId: number;
          status: SystemDesignStatus;
          companyId: number;
          candidateId: string;
          employeeId: string;
          appealMessage: string | null;
          componentTypes: Array<SystemDesignComponentType>;
          question: {
            __typename?: "SystemDesignQuestion";
            questionId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            initialSnapshot: string;
            hints: Array<{
              __typename?: "SystemDesignHint";
              systemDesignHintId: number;
              descriptionJa: string | null;
              descriptionEn: string | null;
            }>;
            scoringItems: Array<{
              __typename?: "SystemDesignScoringItem";
              systemDesignScoringItemId: number;
              category: SystemDesignScoringItemCategory;
              isHidden: boolean;
            }>;
          } | null;
          submissions: Array<{ __typename?: "SystemDesignSubmission"; systemDesignSubmissionId: number; snapshot: string }>;
        }
    >;
  };
};

export type GetDemoForCandidateDemosStartQueryVariables = Exact<{
  demoId: Scalars["String"]["input"];
  candidateId: Scalars["String"]["input"];
}>;

export type GetDemoForCandidateDemosStartQuery = { __typename?: "Query"; demo: { __typename?: "Demo"; demoId: string; status: DemoStatus } };

export type FreepadQuestionForCandidateRemotesIdFragment = {
  __typename?: "FreepadQuestion";
  freepadQuestionId: number;
  version: string;
  variant: FreepadQuestionVariant;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  answers: Array<{
    __typename?: "FreepadAnswer";
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
  }>;
  initialCodes: Array<{ __typename?: "FreepadInitialCode"; questionId: number; questionVersion: string; runtime: string; body: string }>;
  initialFlowCharts: Array<{
    __typename?: "FreepadInitialFlowChart";
    questionId: number;
    questionVersion: string;
    componentType: SystemDesignComponentType;
    body: string;
  }>;
};

export type AlgorithmQuestionForCandidateRemotesIdFragment = {
  __typename?: "AlgorithmQuestion";
  questionId: number;
  version: string;
  variant: AlgorithmQuestionVariant;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  supportedLanguages: Array<string>;
};

export type SystemDesignQuestionForCandidateRemotesIdFragment = {
  __typename?: "SystemDesignQuestion";
  questionId: number;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  initialSnapshot: string;
  answers: Array<{ __typename?: "SystemDesignAnswer"; titleJa: string; titleEn: string; descriptionJa: string; descriptionEn: string }>;
};

export type LiveCodingSessionForCandidateRemotesIdFragment = {
  __typename?: "LiveCodingSession";
  questionType: LiveCodingQuestionType;
  liveCodingId: number;
  liveCodingSessionId: number;
  elapsedTimeSeconds: number | null;
  freepadQuestion: {
    __typename?: "FreepadQuestion";
    freepadQuestionId: number;
    version: string;
    variant: FreepadQuestionVariant;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    answers: Array<{
      __typename?: "FreepadAnswer";
      titleJa: string | null;
      titleEn: string | null;
      descriptionJa: string | null;
      descriptionEn: string | null;
    }>;
    initialCodes: Array<{ __typename?: "FreepadInitialCode"; questionId: number; questionVersion: string; runtime: string; body: string }>;
    initialFlowCharts: Array<{
      __typename?: "FreepadInitialFlowChart";
      questionId: number;
      questionVersion: string;
      componentType: SystemDesignComponentType;
      body: string;
    }>;
  } | null;
  algorithmQuestion: {
    __typename?: "AlgorithmQuestion";
    questionId: number;
    version: string;
    variant: AlgorithmQuestionVariant;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    supportedLanguages: Array<string>;
  } | null;
  systemDesignQuestion: {
    __typename?: "SystemDesignQuestion";
    questionId: number;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    initialSnapshot: string;
    answers: Array<{ __typename?: "SystemDesignAnswer"; titleJa: string; titleEn: string; descriptionJa: string; descriptionEn: string }>;
  } | null;
};

export type LiveCodingVideoChatRoomForCandidateRemotesIdFragment = {
  __typename?: "LiveCodingVideoChatRoom";
  id: string;
  roomId: string;
  liveCodingId: number;
  createdAtSeconds: number | null;
};

export type LiveCodingVideoChatConversationForCandidateRemotesIdFragment = {
  __typename?: "LiveCodingVideoChatConversation";
  id: string;
  conversationId: string;
  liveCodingId: number;
  createdAtSeconds: number | null;
};

export type LiveCodingParticipantForCandidateRemotesIdFragment = {
  __typename?: "LiveCodingParticipant";
  liveCodingId: number;
  userType: LiveCodingUserType;
  userId: string;
  userName: string;
};

export type LiveCodingForCandidateRemotesIdFragment = {
  __typename?: "LiveCoding";
  status: LiveCodingStatus | null;
  liveCodingId: number;
  sessions: Array<{
    __typename?: "LiveCodingSession";
    questionType: LiveCodingQuestionType;
    liveCodingId: number;
    liveCodingSessionId: number;
    elapsedTimeSeconds: number | null;
    freepadQuestion: {
      __typename?: "FreepadQuestion";
      freepadQuestionId: number;
      version: string;
      variant: FreepadQuestionVariant;
      titleJa: string | null;
      titleEn: string | null;
      descriptionJa: string | null;
      descriptionEn: string | null;
      answers: Array<{
        __typename?: "FreepadAnswer";
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
      }>;
      initialCodes: Array<{ __typename?: "FreepadInitialCode"; questionId: number; questionVersion: string; runtime: string; body: string }>;
      initialFlowCharts: Array<{
        __typename?: "FreepadInitialFlowChart";
        questionId: number;
        questionVersion: string;
        componentType: SystemDesignComponentType;
        body: string;
      }>;
    } | null;
    algorithmQuestion: {
      __typename?: "AlgorithmQuestion";
      questionId: number;
      version: string;
      variant: AlgorithmQuestionVariant;
      titleJa: string | null;
      titleEn: string | null;
      descriptionJa: string | null;
      descriptionEn: string | null;
      supportedLanguages: Array<string>;
    } | null;
    systemDesignQuestion: {
      __typename?: "SystemDesignQuestion";
      questionId: number;
      titleJa: string | null;
      titleEn: string | null;
      descriptionJa: string | null;
      descriptionEn: string | null;
      initialSnapshot: string;
      answers: Array<{ __typename?: "SystemDesignAnswer"; titleJa: string; titleEn: string; descriptionJa: string; descriptionEn: string }>;
    } | null;
  } | null>;
  participants: Array<{
    __typename?: "LiveCodingParticipant";
    liveCodingId: number;
    userType: LiveCodingUserType;
    userId: string;
    userName: string;
  }>;
  videoChatRooms: Array<{
    __typename?: "LiveCodingVideoChatRoom";
    id: string;
    roomId: string;
    liveCodingId: number;
    createdAtSeconds: number | null;
  }>;
  videoChatConversation: {
    __typename?: "LiveCodingVideoChatConversation";
    id: string;
    conversationId: string;
    liveCodingId: number;
    createdAtSeconds: number | null;
  } | null;
};

export type RemoteEntityForCandidateRemotesIdFragment = {
  __typename?: "RemoteEntity";
  entityType: RemoteEntityType;
  liveCoding: {
    __typename?: "LiveCoding";
    status: LiveCodingStatus | null;
    liveCodingId: number;
    sessions: Array<{
      __typename?: "LiveCodingSession";
      questionType: LiveCodingQuestionType;
      liveCodingId: number;
      liveCodingSessionId: number;
      elapsedTimeSeconds: number | null;
      freepadQuestion: {
        __typename?: "FreepadQuestion";
        freepadQuestionId: number;
        version: string;
        variant: FreepadQuestionVariant;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        answers: Array<{
          __typename?: "FreepadAnswer";
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
        }>;
        initialCodes: Array<{ __typename?: "FreepadInitialCode"; questionId: number; questionVersion: string; runtime: string; body: string }>;
        initialFlowCharts: Array<{
          __typename?: "FreepadInitialFlowChart";
          questionId: number;
          questionVersion: string;
          componentType: SystemDesignComponentType;
          body: string;
        }>;
      } | null;
      algorithmQuestion: {
        __typename?: "AlgorithmQuestion";
        questionId: number;
        version: string;
        variant: AlgorithmQuestionVariant;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        supportedLanguages: Array<string>;
      } | null;
      systemDesignQuestion: {
        __typename?: "SystemDesignQuestion";
        questionId: number;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        initialSnapshot: string;
        answers: Array<{ __typename?: "SystemDesignAnswer"; titleJa: string; titleEn: string; descriptionJa: string; descriptionEn: string }>;
      } | null;
    } | null>;
    participants: Array<{
      __typename?: "LiveCodingParticipant";
      liveCodingId: number;
      userType: LiveCodingUserType;
      userId: string;
      userName: string;
    }>;
    videoChatRooms: Array<{
      __typename?: "LiveCodingVideoChatRoom";
      id: string;
      roomId: string;
      liveCodingId: number;
      createdAtSeconds: number | null;
    }>;
    videoChatConversation: {
      __typename?: "LiveCodingVideoChatConversation";
      id: string;
      conversationId: string;
      liveCodingId: number;
      createdAtSeconds: number | null;
    } | null;
  } | null;
};

export type RemoteForCandidateRemotesIdFragment = {
  __typename?: "Remote";
  status: RemoteStatus;
  remoteId: string;
  name: string;
  candidateName: string;
  didStartAtSeconds: number | null;
  company: { __typename?: "Company"; name: string } | null;
  entity: {
    __typename?: "RemoteEntity";
    entityType: RemoteEntityType;
    liveCoding: {
      __typename?: "LiveCoding";
      status: LiveCodingStatus | null;
      liveCodingId: number;
      sessions: Array<{
        __typename?: "LiveCodingSession";
        questionType: LiveCodingQuestionType;
        liveCodingId: number;
        liveCodingSessionId: number;
        elapsedTimeSeconds: number | null;
        freepadQuestion: {
          __typename?: "FreepadQuestion";
          freepadQuestionId: number;
          version: string;
          variant: FreepadQuestionVariant;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          answers: Array<{
            __typename?: "FreepadAnswer";
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
          }>;
          initialCodes: Array<{
            __typename?: "FreepadInitialCode";
            questionId: number;
            questionVersion: string;
            runtime: string;
            body: string;
          }>;
          initialFlowCharts: Array<{
            __typename?: "FreepadInitialFlowChart";
            questionId: number;
            questionVersion: string;
            componentType: SystemDesignComponentType;
            body: string;
          }>;
        } | null;
        algorithmQuestion: {
          __typename?: "AlgorithmQuestion";
          questionId: number;
          version: string;
          variant: AlgorithmQuestionVariant;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          supportedLanguages: Array<string>;
        } | null;
        systemDesignQuestion: {
          __typename?: "SystemDesignQuestion";
          questionId: number;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          initialSnapshot: string;
          answers: Array<{ __typename?: "SystemDesignAnswer"; titleJa: string; titleEn: string; descriptionJa: string; descriptionEn: string }>;
        } | null;
      } | null>;
      participants: Array<{
        __typename?: "LiveCodingParticipant";
        liveCodingId: number;
        userType: LiveCodingUserType;
        userId: string;
        userName: string;
      }>;
      videoChatRooms: Array<{
        __typename?: "LiveCodingVideoChatRoom";
        id: string;
        roomId: string;
        liveCodingId: number;
        createdAtSeconds: number | null;
      }>;
      videoChatConversation: {
        __typename?: "LiveCodingVideoChatConversation";
        id: string;
        conversationId: string;
        liveCodingId: number;
        createdAtSeconds: number | null;
      } | null;
    } | null;
  } | null;
};

export type GetRemotesForCandidateQueryVariables = Exact<{
  remoteId: Scalars["String"]["input"];
}>;

export type GetRemotesForCandidateQuery = {
  __typename?: "Query";
  remote: {
    __typename?: "Remote";
    status: RemoteStatus;
    remoteId: string;
    name: string;
    candidateName: string;
    didStartAtSeconds: number | null;
    company: { __typename?: "Company"; name: string } | null;
    entity: {
      __typename?: "RemoteEntity";
      entityType: RemoteEntityType;
      liveCoding: {
        __typename?: "LiveCoding";
        status: LiveCodingStatus | null;
        liveCodingId: number;
        sessions: Array<{
          __typename?: "LiveCodingSession";
          questionType: LiveCodingQuestionType;
          liveCodingId: number;
          liveCodingSessionId: number;
          elapsedTimeSeconds: number | null;
          freepadQuestion: {
            __typename?: "FreepadQuestion";
            freepadQuestionId: number;
            version: string;
            variant: FreepadQuestionVariant;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            answers: Array<{
              __typename?: "FreepadAnswer";
              titleJa: string | null;
              titleEn: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
            }>;
            initialCodes: Array<{
              __typename?: "FreepadInitialCode";
              questionId: number;
              questionVersion: string;
              runtime: string;
              body: string;
            }>;
            initialFlowCharts: Array<{
              __typename?: "FreepadInitialFlowChart";
              questionId: number;
              questionVersion: string;
              componentType: SystemDesignComponentType;
              body: string;
            }>;
          } | null;
          algorithmQuestion: {
            __typename?: "AlgorithmQuestion";
            questionId: number;
            version: string;
            variant: AlgorithmQuestionVariant;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            supportedLanguages: Array<string>;
          } | null;
          systemDesignQuestion: {
            __typename?: "SystemDesignQuestion";
            questionId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            initialSnapshot: string;
            answers: Array<{
              __typename?: "SystemDesignAnswer";
              titleJa: string;
              titleEn: string;
              descriptionJa: string;
              descriptionEn: string;
            }>;
          } | null;
        } | null>;
        participants: Array<{
          __typename?: "LiveCodingParticipant";
          liveCodingId: number;
          userType: LiveCodingUserType;
          userId: string;
          userName: string;
        }>;
        videoChatRooms: Array<{
          __typename?: "LiveCodingVideoChatRoom";
          id: string;
          roomId: string;
          liveCodingId: number;
          createdAtSeconds: number | null;
        }>;
        videoChatConversation: {
          __typename?: "LiveCodingVideoChatConversation";
          id: string;
          conversationId: string;
          liveCodingId: number;
          createdAtSeconds: number | null;
        } | null;
      } | null;
    } | null;
  };
};

export type GetLiveCodingForCandidateQueryVariables = Exact<{
  liveCodingId: Scalars["Int"]["input"];
}>;

export type GetLiveCodingForCandidateQuery = {
  __typename?: "Query";
  liveCoding: {
    __typename?: "LiveCoding";
    status: LiveCodingStatus | null;
    liveCodingId: number;
    sessions: Array<{
      __typename?: "LiveCodingSession";
      questionType: LiveCodingQuestionType;
      liveCodingId: number;
      liveCodingSessionId: number;
      elapsedTimeSeconds: number | null;
      freepadQuestion: {
        __typename?: "FreepadQuestion";
        freepadQuestionId: number;
        version: string;
        variant: FreepadQuestionVariant;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        answers: Array<{
          __typename?: "FreepadAnswer";
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
        }>;
        initialCodes: Array<{ __typename?: "FreepadInitialCode"; questionId: number; questionVersion: string; runtime: string; body: string }>;
        initialFlowCharts: Array<{
          __typename?: "FreepadInitialFlowChart";
          questionId: number;
          questionVersion: string;
          componentType: SystemDesignComponentType;
          body: string;
        }>;
      } | null;
      algorithmQuestion: {
        __typename?: "AlgorithmQuestion";
        questionId: number;
        version: string;
        variant: AlgorithmQuestionVariant;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        supportedLanguages: Array<string>;
      } | null;
      systemDesignQuestion: {
        __typename?: "SystemDesignQuestion";
        questionId: number;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        initialSnapshot: string;
        answers: Array<{ __typename?: "SystemDesignAnswer"; titleJa: string; titleEn: string; descriptionJa: string; descriptionEn: string }>;
      } | null;
    } | null>;
    participants: Array<{
      __typename?: "LiveCodingParticipant";
      liveCodingId: number;
      userType: LiveCodingUserType;
      userId: string;
      userName: string;
    }>;
    videoChatRooms: Array<{
      __typename?: "LiveCodingVideoChatRoom";
      id: string;
      roomId: string;
      liveCodingId: number;
      createdAtSeconds: number | null;
    }>;
    videoChatConversation: {
      __typename?: "LiveCodingVideoChatConversation";
      id: string;
      conversationId: string;
      liveCodingId: number;
      createdAtSeconds: number | null;
    } | null;
  };
};

export type AddLiveCodingVideoChatRoomForCandidateMutationVariables = Exact<{
  input: AddLiveCodingVideoChatRoomInput;
}>;

export type AddLiveCodingVideoChatRoomForCandidateMutation = {
  __typename?: "Mutation";
  addLiveCodingVideoChatRoom: {
    __typename?: "LiveCodingVideoChatRoom";
    id: string;
    roomId: string;
    liveCodingId: number;
    createdAtSeconds: number | null;
  };
};

export type AddLiveCodingVideoChatConversationForCandidateMutationVariables = Exact<{
  input: AddLiveCodingVideoChatConversationInput;
}>;

export type AddLiveCodingVideoChatConversationForCandidateMutation = {
  __typename?: "Mutation";
  addLiveCodingVideoChatConversation: {
    __typename?: "LiveCodingVideoChatConversation";
    id: string;
    conversationId: string;
    liveCodingId: number;
    createdAtSeconds: number | null;
  };
};

export type JoinLiveCodingForCandidateMutationVariables = Exact<{
  input: JoinLiveCodingInput;
}>;

export type JoinLiveCodingForCandidateMutation = {
  __typename?: "Mutation";
  joinLiveCoding: {
    __typename?: "LiveCoding";
    status: LiveCodingStatus | null;
    liveCodingId: number;
    sessions: Array<{
      __typename?: "LiveCodingSession";
      questionType: LiveCodingQuestionType;
      liveCodingId: number;
      liveCodingSessionId: number;
      elapsedTimeSeconds: number | null;
      freepadQuestion: {
        __typename?: "FreepadQuestion";
        freepadQuestionId: number;
        version: string;
        variant: FreepadQuestionVariant;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        answers: Array<{
          __typename?: "FreepadAnswer";
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
        }>;
        initialCodes: Array<{ __typename?: "FreepadInitialCode"; questionId: number; questionVersion: string; runtime: string; body: string }>;
        initialFlowCharts: Array<{
          __typename?: "FreepadInitialFlowChart";
          questionId: number;
          questionVersion: string;
          componentType: SystemDesignComponentType;
          body: string;
        }>;
      } | null;
      algorithmQuestion: {
        __typename?: "AlgorithmQuestion";
        questionId: number;
        version: string;
        variant: AlgorithmQuestionVariant;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        supportedLanguages: Array<string>;
      } | null;
      systemDesignQuestion: {
        __typename?: "SystemDesignQuestion";
        questionId: number;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        initialSnapshot: string;
        answers: Array<{ __typename?: "SystemDesignAnswer"; titleJa: string; titleEn: string; descriptionJa: string; descriptionEn: string }>;
      } | null;
    } | null>;
    participants: Array<{
      __typename?: "LiveCodingParticipant";
      liveCodingId: number;
      userType: LiveCodingUserType;
      userId: string;
      userName: string;
    }>;
    videoChatRooms: Array<{
      __typename?: "LiveCodingVideoChatRoom";
      id: string;
      roomId: string;
      liveCodingId: number;
      createdAtSeconds: number | null;
    }>;
    videoChatConversation: {
      __typename?: "LiveCodingVideoChatConversation";
      id: string;
      conversationId: string;
      liveCodingId: number;
      createdAtSeconds: number | null;
    } | null;
  };
};

export type VideoChatRoomTokenForCandidateRemotesIdFragment = { __typename?: "RoomToken"; token: string };

export type JoinVideoChatRoomCandidateMutationVariables = Exact<{
  input: JoinVideoChatRoomInput;
}>;

export type JoinVideoChatRoomCandidateMutation = { __typename?: "Mutation"; joinVideoChatRoom: { __typename?: "RoomToken"; token: string } };

export type VideoChatConversationTokenForCandidateRemotesIdFragment = { __typename?: "ConversationToken"; token: string };

export type JoinVideoChatConversationForCandidateMutationVariables = Exact<{
  input: JoinVideoChatConversationInput;
}>;

export type JoinVideoChatConversationForCandidateMutation = {
  __typename?: "Mutation";
  joinVideoChatConversation: { __typename?: "ConversationToken"; token: string };
};

export type VideoChatConversationParticipantForCandidateRemotesIdFragment = {
  __typename?: "VideoChatConversationParticipant";
  displayName: string;
  conversationId: string;
  userId: string;
  userType: VideoChatUserType;
};

export type VideoChatConversationForCandidateRemotesIdFragment = {
  __typename?: "VideoChatConversation";
  id: string;
  conversationId: string;
  status: VideoChatConversationStatus;
  participants: Array<{
    __typename?: "VideoChatConversationParticipant";
    displayName: string;
    conversationId: string;
    userId: string;
    userType: VideoChatUserType;
  }>;
};

export type GetVideoChatConversationForCandidateQueryVariables = Exact<{
  input: VideoChatConversationInput;
}>;

export type GetVideoChatConversationForCandidateQuery = {
  __typename?: "Query";
  videoChatConversation: {
    __typename?: "VideoChatConversation";
    id: string;
    conversationId: string;
    status: VideoChatConversationStatus;
    participants: Array<{
      __typename?: "VideoChatConversationParticipant";
      displayName: string;
      conversationId: string;
      userId: string;
      userType: VideoChatUserType;
    }>;
  } | null;
};

export type VariableForScreeningsIdFragment = {
  __typename?: "ScreeningVariable";
  id: string;
  variableId: number;
  screeningId: string;
  label: string;
  description: string;
  variableField:
    | {
        __typename: "ScreeningBooleanFieldVariable";
        validationRule: { __typename?: "ScreeningBooleanFieldVariableValidationRule"; required: boolean };
      }
    | {
        __typename: "ScreeningIntegerFieldVariable";
        validationRule: {
          __typename?: "ScreeningIntegerFieldVariableValidationRule";
          min: number | null;
          max: number | null;
          required: boolean;
        };
      }
    | {
        __typename: "ScreeningStringFieldVariable";
        formType: ScreeningStringVariableFieldFormType;
        validationRule: {
          __typename?: "ScreeningStringFieldVariableValidationRule";
          min: number | null;
          max: number | null;
          required: boolean;
        };
      };
};

type ScreeningEntitySourceQuestionForScreeningsId_ScreeningChallengeEntitySourceQuestion_Fragment = {
  __typename: "ScreeningChallengeEntitySourceQuestion";
  question: { __typename: "AlgorithmQuestion"; titleJa: string | null; titleEn: string | null; variant: AlgorithmQuestionVariant };
};

type ScreeningEntitySourceQuestionForScreeningsId_ScreeningProjectEntitySourceQuestion_Fragment = {
  __typename: "ScreeningProjectEntitySourceQuestion";
  question: { __typename: "ProjectQuestion"; titleEn: string | null; titleJa: string | null; projectQuestionVariant: ProjectQuestionVariant };
};

type ScreeningEntitySourceQuestionForScreeningsId_ScreeningQuizEntitySourcePackage_Fragment = {
  __typename: "ScreeningQuizEntitySourcePackage";
  pb_package: { __typename?: "MultiChoicePackage"; titleJa: string | null; titleEn: string | null };
};

type ScreeningEntitySourceQuestionForScreeningsId_ScreeningSystemDesignEntitySourceQuestion_Fragment = {
  __typename: "ScreeningSystemDesignEntitySourceQuestion";
  question: { __typename: "SystemDesignQuestion"; titleEn: string | null; titleJa: string | null };
};

export type ScreeningEntitySourceQuestionForScreeningsIdFragment =
  | ScreeningEntitySourceQuestionForScreeningsId_ScreeningChallengeEntitySourceQuestion_Fragment
  | ScreeningEntitySourceQuestionForScreeningsId_ScreeningProjectEntitySourceQuestion_Fragment
  | ScreeningEntitySourceQuestionForScreeningsId_ScreeningQuizEntitySourcePackage_Fragment
  | ScreeningEntitySourceQuestionForScreeningsId_ScreeningSystemDesignEntitySourceQuestion_Fragment;

export type ScreeningForScreeningsIdFragment = {
  __typename?: "Screening";
  id: string;
  screeningId: string;
  description: string;
  timeLimitSeconds: number;
  deadlineSeconds: number | null;
  isActive: boolean;
  questionCount: number;
  candidateAccessPolicy: ScreeningCandidateAccessPolicy;
  messageForCandidate: string | null;
  acceptanceStatus: ScreeningAcceptanceStatus;
  company: { __typename?: "Company"; name: string } | null;
  variables: Array<{
    __typename?: "ScreeningVariable";
    id: string;
    variableId: number;
    screeningId: string;
    label: string;
    description: string;
    variableField:
      | {
          __typename: "ScreeningBooleanFieldVariable";
          validationRule: { __typename?: "ScreeningBooleanFieldVariableValidationRule"; required: boolean };
        }
      | {
          __typename: "ScreeningIntegerFieldVariable";
          validationRule: {
            __typename?: "ScreeningIntegerFieldVariableValidationRule";
            min: number | null;
            max: number | null;
            required: boolean;
          };
        }
      | {
          __typename: "ScreeningStringFieldVariable";
          formType: ScreeningStringVariableFieldFormType;
          validationRule: {
            __typename?: "ScreeningStringFieldVariableValidationRule";
            min: number | null;
            max: number | null;
            required: boolean;
          };
        };
  }>;
  entityTracks: Array<
    | {
        __typename: "ScreeningFixedEntityTrack";
        trackId: number;
        entitySourceQuestion:
          | {
              __typename: "ScreeningChallengeEntitySourceQuestion";
              question: { __typename: "AlgorithmQuestion"; titleJa: string | null; titleEn: string | null; variant: AlgorithmQuestionVariant };
            }
          | {
              __typename: "ScreeningProjectEntitySourceQuestion";
              question: {
                __typename: "ProjectQuestion";
                titleEn: string | null;
                titleJa: string | null;
                projectQuestionVariant: ProjectQuestionVariant;
              };
            }
          | {
              __typename: "ScreeningQuizEntitySourcePackage";
              pb_package: { __typename?: "MultiChoicePackage"; titleJa: string | null; titleEn: string | null };
            }
          | {
              __typename: "ScreeningSystemDesignEntitySourceQuestion";
              question: { __typename: "SystemDesignQuestion"; titleEn: string | null; titleJa: string | null };
            };
      }
    | { __typename: "ScreeningRandomFromQuestionsEntityTrack"; trackId: number }
    | {
        __typename: "ScreeningSelectableFromQuestionsEntityTrack";
        trackId: number;
        entitySourceQuestions: Array<
          | {
              __typename: "ScreeningChallengeEntitySourceQuestion";
              question: { __typename: "AlgorithmQuestion"; titleJa: string | null; titleEn: string | null; variant: AlgorithmQuestionVariant };
            }
          | {
              __typename: "ScreeningProjectEntitySourceQuestion";
              question: {
                __typename: "ProjectQuestion";
                titleEn: string | null;
                titleJa: string | null;
                projectQuestionVariant: ProjectQuestionVariant;
              };
            }
          | {
              __typename: "ScreeningQuizEntitySourcePackage";
              pb_package: { __typename?: "MultiChoicePackage"; titleJa: string | null; titleEn: string | null };
            }
          | {
              __typename: "ScreeningSystemDesignEntitySourceQuestion";
              question: { __typename: "SystemDesignQuestion"; titleEn: string | null; titleJa: string | null };
            }
        >;
      }
  >;
};

export type GetScreeningForScreeningsIdQueryVariables = Exact<{
  screeningId: Scalars["String"]["input"];
  invitationCode: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetScreeningForScreeningsIdQuery = {
  __typename?: "Query";
  screening: {
    __typename?: "Screening";
    id: string;
    screeningId: string;
    description: string;
    timeLimitSeconds: number;
    deadlineSeconds: number | null;
    isActive: boolean;
    questionCount: number;
    candidateAccessPolicy: ScreeningCandidateAccessPolicy;
    messageForCandidate: string | null;
    acceptanceStatus: ScreeningAcceptanceStatus;
    company: { __typename?: "Company"; name: string } | null;
    variables: Array<{
      __typename?: "ScreeningVariable";
      id: string;
      variableId: number;
      screeningId: string;
      label: string;
      description: string;
      variableField:
        | {
            __typename: "ScreeningBooleanFieldVariable";
            validationRule: { __typename?: "ScreeningBooleanFieldVariableValidationRule"; required: boolean };
          }
        | {
            __typename: "ScreeningIntegerFieldVariable";
            validationRule: {
              __typename?: "ScreeningIntegerFieldVariableValidationRule";
              min: number | null;
              max: number | null;
              required: boolean;
            };
          }
        | {
            __typename: "ScreeningStringFieldVariable";
            formType: ScreeningStringVariableFieldFormType;
            validationRule: {
              __typename?: "ScreeningStringFieldVariableValidationRule";
              min: number | null;
              max: number | null;
              required: boolean;
            };
          };
    }>;
    entityTracks: Array<
      | {
          __typename: "ScreeningFixedEntityTrack";
          trackId: number;
          entitySourceQuestion:
            | {
                __typename: "ScreeningChallengeEntitySourceQuestion";
                question: {
                  __typename: "AlgorithmQuestion";
                  titleJa: string | null;
                  titleEn: string | null;
                  variant: AlgorithmQuestionVariant;
                };
              }
            | {
                __typename: "ScreeningProjectEntitySourceQuestion";
                question: {
                  __typename: "ProjectQuestion";
                  titleEn: string | null;
                  titleJa: string | null;
                  projectQuestionVariant: ProjectQuestionVariant;
                };
              }
            | {
                __typename: "ScreeningQuizEntitySourcePackage";
                pb_package: { __typename?: "MultiChoicePackage"; titleJa: string | null; titleEn: string | null };
              }
            | {
                __typename: "ScreeningSystemDesignEntitySourceQuestion";
                question: { __typename: "SystemDesignQuestion"; titleEn: string | null; titleJa: string | null };
              };
        }
      | { __typename: "ScreeningRandomFromQuestionsEntityTrack"; trackId: number }
      | {
          __typename: "ScreeningSelectableFromQuestionsEntityTrack";
          trackId: number;
          entitySourceQuestions: Array<
            | {
                __typename: "ScreeningChallengeEntitySourceQuestion";
                question: {
                  __typename: "AlgorithmQuestion";
                  titleJa: string | null;
                  titleEn: string | null;
                  variant: AlgorithmQuestionVariant;
                };
              }
            | {
                __typename: "ScreeningProjectEntitySourceQuestion";
                question: {
                  __typename: "ProjectQuestion";
                  titleEn: string | null;
                  titleJa: string | null;
                  projectQuestionVariant: ProjectQuestionVariant;
                };
              }
            | {
                __typename: "ScreeningQuizEntitySourcePackage";
                pb_package: { __typename?: "MultiChoicePackage"; titleJa: string | null; titleEn: string | null };
              }
            | {
                __typename: "ScreeningSystemDesignEntitySourceQuestion";
                question: { __typename: "SystemDesignQuestion"; titleEn: string | null; titleJa: string | null };
              }
          >;
        }
    >;
  };
};

type AcceptScreeningResponseForScreeningsId_AcceptScreeningErrorResponse_Fragment = {
  __typename: "AcceptScreeningErrorResponse";
  errorCode: AcceptScreeningErrorCode;
};

type AcceptScreeningResponseForScreeningsId_AcceptScreeningSuccessResponse_Fragment = {
  __typename: "AcceptScreeningSuccessResponse";
  spot: {
    __typename?: "Spot";
    id: string;
    spotId: string;
    status: SpotStatus;
    candidate: { __typename?: "User"; id: string; uid: string } | null;
  };
};

export type AcceptScreeningResponseForScreeningsIdFragment =
  | AcceptScreeningResponseForScreeningsId_AcceptScreeningErrorResponse_Fragment
  | AcceptScreeningResponseForScreeningsId_AcceptScreeningSuccessResponse_Fragment;

export type AcceptScreeningForScreeningsIdMutationVariables = Exact<{
  input: AcceptScreeningInput;
}>;

export type AcceptScreeningForScreeningsIdMutation = {
  __typename?: "Mutation";
  acceptScreening:
    | { __typename: "AcceptScreeningErrorResponse"; errorCode: AcceptScreeningErrorCode }
    | {
        __typename: "AcceptScreeningSuccessResponse";
        spot: {
          __typename?: "Spot";
          id: string;
          spotId: string;
          status: SpotStatus;
          candidate: { __typename?: "User"; id: string; uid: string } | null;
        };
      };
};

export type StartSpotForScreeningsIdMutationVariables = Exact<{
  input: StartSpotInput;
}>;

export type StartSpotForScreeningsIdMutation = {
  __typename?: "Mutation";
  startSpot: { __typename?: "Spot"; id: string; spotId: string; status: SpotStatus };
};

export type CreateDemoFromScreeningForScreeningIdMutationVariables = Exact<{
  input: CreateDemoFromScreeningInput;
}>;

export type CreateDemoFromScreeningForScreeningIdMutation = {
  __typename?: "Mutation";
  createDemoFromScreening: {
    __typename?: "Demo";
    demoId: string;
    status: DemoStatus;
    timeLimitSeconds: number | null;
    createdAtSeconds: number | null;
    allowWebSearch: boolean;
    allowChatGPT: boolean;
    candidate: { __typename?: "User"; displayName: string } | null;
    entities: Array<
      | {
          __typename: "ChallengeEntity";
          challengeEntityId: number;
          questionVersion: string;
          enabledLanguages: Array<string>;
          appealMessage: string | null;
          challengeStatus: ChallengeStatus;
          challengeQuestion: {
            __typename?: "AlgorithmQuestion";
            id: string;
            questionId: number;
            version: string;
            variant: AlgorithmQuestionVariant;
            companyId: number;
            employeeId: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            supportedLanguages: Array<string>;
            signature: string;
            correctnessTestCase: string;
            performanceTestCase: string;
            performanceTimeLimitMilliSeconds: number;
            hints: Array<{
              __typename?: "AlgorithmHint";
              id: number;
              questionId: number;
              questionVersion: string;
              descriptionJa: string | null;
              descriptionEn: string | null;
              orderNum: number;
            }>;
            initialCode: {
              __typename?: "AlgorithmInitialCode";
              c: string;
              cpp: string;
              csharp: string;
              dart: string;
              go: string;
              java: string;
              javascript: string;
              kotlin: string;
              mysql: string;
              perl: string;
              pgsql: string;
              php: string;
              python3: string;
              ruby: string;
              rust: string;
              scala: string;
              sqlite: string;
              swift: string;
              typescript: string;
            } | null;
          } | null;
          submissions: Array<{ __typename?: "ChallengeSubmission"; challengeSubmissionId: number }>;
        }
      | {
          __typename: "ProjectEntity";
          projectEntityId: number;
          appealMessage: string | null;
          agentVersion: string;
          projectStatus: ProjectStatus;
          question: {
            __typename?: "ProjectQuestion";
            questionId: number;
            version: string;
            projectVersion: string;
            variant: ProjectQuestionVariant;
            titleJa: string | null;
            titleEn: string | null;
            correctnessTestCase: string;
            performanceTestCase: string;
            defaultTestCase: string;
            descriptionJa: string | null;
            descriptionEn: string | null;
            timeLimitSeconds: number | null;
            fileIndexes: Array<string>;
            readOnlyFiles: Array<string>;
          } | null;
          submissions: Array<{ __typename?: "ProjectSubmission"; projectSubmissionId: number }>;
        }
      | {
          __typename: "QuizEntity";
          quizEntityId: number;
          packageVersion: string;
          quizStatus: QuizStatus;
          pb_package: {
            __typename?: "MultiChoicePackage";
            packageId: number;
            version: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            questions: Array<{
              __typename?: "MultiChoiceQuestion";
              id: number;
              version: string;
              titleJa: string | null;
              titleEn: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
              variant: MultiChoiceQuestionVariant;
              options: Array<{
                __typename?: "MultiChoiceOption";
                id: number;
                titleJa: string | null;
                titleEn: string | null;
                isCorrect: boolean;
              }>;
            }>;
          } | null;
          submissions: Array<{
            __typename?: "QuizSubmission";
            quizSubmissionId: number;
            questionId: number;
            questionVersion: string;
            optionIds: Array<number>;
          }>;
        }
      | {
          __typename: "SystemDesignEntity";
          systemDesignEntityId: number;
          status: SystemDesignStatus;
          companyId: number;
          candidateId: string;
          employeeId: string;
          appealMessage: string | null;
          componentTypes: Array<SystemDesignComponentType>;
          question: {
            __typename?: "SystemDesignQuestion";
            questionId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            initialSnapshot: string;
            hints: Array<{
              __typename?: "SystemDesignHint";
              systemDesignHintId: number;
              descriptionJa: string | null;
              descriptionEn: string | null;
            }>;
            scoringItems: Array<{
              __typename?: "SystemDesignScoringItem";
              systemDesignScoringItemId: number;
              category: SystemDesignScoringItemCategory;
              isHidden: boolean;
            }>;
          } | null;
          submissions: Array<{ __typename?: "SystemDesignSubmission"; systemDesignSubmissionId: number; snapshot: string }>;
        }
    >;
  };
};

export type LinkSpotToSonarIntegrationForScreeningsIdMutationVariables = Exact<{
  input: LinkSpotToSonarIntegrationInput;
}>;

export type LinkSpotToSonarIntegrationForScreeningsIdMutation = { __typename?: "Mutation"; linkSpotToSonarIntegration: boolean };

export type DeleteSpotForScreeningsIdMutationVariables = Exact<{
  spotId: Scalars["String"]["input"];
}>;

export type DeleteSpotForScreeningsIdMutation = { __typename?: "Mutation"; deleteSpot: boolean };

export type SpotForEntryScreeningsTestsIdFragment = {
  __typename?: "Spot";
  id: string;
  isRetake: boolean;
  screeningId: string | null;
  candidateName: string;
  candidateEmail: string;
  timeLimitSeconds: number;
  messageForCandidate: string | null;
  entityCount: number;
  didStartAtSeconds: number | null;
  remainingTimeSeconds: number;
  elapsedTimeSeconds: number | null;
  willExpireAtSeconds: number | null;
  company: { __typename?: "Company"; name: string } | null;
};

export type GetSpotForEntryScreeningsTestsIdQueryVariables = Exact<{
  id: Scalars["String"]["input"];
  ignoreInheritance?: Scalars["Boolean"]["input"];
}>;

export type GetSpotForEntryScreeningsTestsIdQuery = {
  __typename?: "Query";
  spot: {
    __typename?: "Spot";
    id: string;
    isRetake: boolean;
    screeningId: string | null;
    candidateName: string;
    candidateEmail: string;
    timeLimitSeconds: number;
    messageForCandidate: string | null;
    entityCount: number;
    didStartAtSeconds: number | null;
    remainingTimeSeconds: number;
    elapsedTimeSeconds: number | null;
    willExpireAtSeconds: number | null;
    company: { __typename?: "Company"; name: string } | null;
  };
};

export type MultiChoiceQuestionForCandidateInterviewFragment = {
  __typename?: "MultiChoiceQuestion";
  id: number;
  version: string;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  variant: MultiChoiceQuestionVariant;
  options: Array<{ __typename?: "MultiChoiceOption"; id: number; titleJa: string | null; titleEn: string | null; isCorrect: boolean }>;
};

export type QuizEntityForCandidateInterviewFragment = {
  __typename: "QuizEntity";
  quizEntityId: number;
  packageVersion: string;
  quizStatus: QuizStatus;
  pb_package: {
    __typename?: "MultiChoicePackage";
    packageId: number;
    version: string;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    questions: Array<{
      __typename?: "MultiChoiceQuestion";
      id: number;
      version: string;
      titleJa: string | null;
      titleEn: string | null;
      descriptionJa: string | null;
      descriptionEn: string | null;
      variant: MultiChoiceQuestionVariant;
      options: Array<{ __typename?: "MultiChoiceOption"; id: number; titleJa: string | null; titleEn: string | null; isCorrect: boolean }>;
    }>;
  } | null;
  submissions: Array<{
    __typename?: "QuizSubmission";
    quizSubmissionId: number;
    questionId: number;
    questionVersion: string;
    optionIds: Array<number>;
  }>;
};

export type ProjectQuestionForCandidateInterviewFragment = {
  __typename?: "ProjectQuestion";
  questionId: number;
  version: string;
  projectVersion: string;
  variant: ProjectQuestionVariant;
  titleJa: string | null;
  titleEn: string | null;
  correctnessTestCase: string;
  performanceTestCase: string;
  defaultTestCase: string;
  descriptionJa: string | null;
  descriptionEn: string | null;
  timeLimitSeconds: number | null;
  fileIndexes: Array<string>;
  readOnlyFiles: Array<string>;
};

export type ProjectEntityForCandidateInterviewFragment = {
  __typename: "ProjectEntity";
  projectEntityId: number;
  appealMessage: string | null;
  agentVersion: string;
  projectStatus: ProjectStatus;
  question: {
    __typename?: "ProjectQuestion";
    questionId: number;
    version: string;
    projectVersion: string;
    variant: ProjectQuestionVariant;
    titleJa: string | null;
    titleEn: string | null;
    correctnessTestCase: string;
    performanceTestCase: string;
    defaultTestCase: string;
    descriptionJa: string | null;
    descriptionEn: string | null;
    timeLimitSeconds: number | null;
    fileIndexes: Array<string>;
    readOnlyFiles: Array<string>;
  } | null;
  submissions: Array<{ __typename?: "ProjectSubmission"; projectSubmissionId: number }>;
};

export type AlgorithmHintForCandidateInterviewFragment = {
  __typename?: "AlgorithmHint";
  id: number;
  questionId: number;
  questionVersion: string;
  descriptionJa: string | null;
  descriptionEn: string | null;
  orderNum: number;
};

export type AlgorithmInitialCodeForCandidateInterviewFragment = {
  __typename?: "AlgorithmInitialCode";
  c: string;
  cpp: string;
  csharp: string;
  dart: string;
  go: string;
  java: string;
  javascript: string;
  kotlin: string;
  mysql: string;
  perl: string;
  pgsql: string;
  php: string;
  python3: string;
  ruby: string;
  rust: string;
  scala: string;
  sqlite: string;
  swift: string;
  typescript: string;
};

export type AlgorithmQuestionForCandidateInterviewFragment = {
  __typename?: "AlgorithmQuestion";
  id: string;
  questionId: number;
  version: string;
  variant: AlgorithmQuestionVariant;
  companyId: number;
  employeeId: string;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  difficulty: Difficulty;
  supportedLanguages: Array<string>;
  signature: string;
  correctnessTestCase: string;
  performanceTestCase: string;
  performanceTimeLimitMilliSeconds: number;
  hints: Array<{
    __typename?: "AlgorithmHint";
    id: number;
    questionId: number;
    questionVersion: string;
    descriptionJa: string | null;
    descriptionEn: string | null;
    orderNum: number;
  }>;
  initialCode: {
    __typename?: "AlgorithmInitialCode";
    c: string;
    cpp: string;
    csharp: string;
    dart: string;
    go: string;
    java: string;
    javascript: string;
    kotlin: string;
    mysql: string;
    perl: string;
    pgsql: string;
    php: string;
    python3: string;
    ruby: string;
    rust: string;
    scala: string;
    sqlite: string;
    swift: string;
    typescript: string;
  } | null;
};

export type ChallengeEntityForCandidateInterviewFragment = {
  __typename: "ChallengeEntity";
  challengeEntityId: number;
  questionVersion: string;
  enabledLanguages: Array<string>;
  appealMessage: string | null;
  challengeStatus: ChallengeStatus;
  challengeQuestion: {
    __typename?: "AlgorithmQuestion";
    id: string;
    questionId: number;
    version: string;
    variant: AlgorithmQuestionVariant;
    companyId: number;
    employeeId: string;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    difficulty: Difficulty;
    supportedLanguages: Array<string>;
    signature: string;
    correctnessTestCase: string;
    performanceTestCase: string;
    performanceTimeLimitMilliSeconds: number;
    hints: Array<{
      __typename?: "AlgorithmHint";
      id: number;
      questionId: number;
      questionVersion: string;
      descriptionJa: string | null;
      descriptionEn: string | null;
      orderNum: number;
    }>;
    initialCode: {
      __typename?: "AlgorithmInitialCode";
      c: string;
      cpp: string;
      csharp: string;
      dart: string;
      go: string;
      java: string;
      javascript: string;
      kotlin: string;
      mysql: string;
      perl: string;
      pgsql: string;
      php: string;
      python3: string;
      ruby: string;
      rust: string;
      scala: string;
      sqlite: string;
      swift: string;
      typescript: string;
    } | null;
  } | null;
  submissions: Array<{ __typename?: "ChallengeSubmission"; challengeSubmissionId: number }>;
};

export type SystemDesignEntityForCandidateInterviewFragment = {
  __typename: "SystemDesignEntity";
  systemDesignEntityId: number;
  status: SystemDesignStatus;
  companyId: number;
  candidateId: string;
  employeeId: string;
  appealMessage: string | null;
  componentTypes: Array<SystemDesignComponentType>;
  question: {
    __typename?: "SystemDesignQuestion";
    questionId: number;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    initialSnapshot: string;
    hints: Array<{ __typename?: "SystemDesignHint"; systemDesignHintId: number; descriptionJa: string | null; descriptionEn: string | null }>;
    scoringItems: Array<{
      __typename?: "SystemDesignScoringItem";
      systemDesignScoringItemId: number;
      category: SystemDesignScoringItemCategory;
      isHidden: boolean;
    }>;
  } | null;
  submissions: Array<{ __typename?: "SystemDesignSubmission"; systemDesignSubmissionId: number; snapshot: string }>;
};

export type SpotForCandidateInterviewFragment = {
  __typename?: "Spot";
  id: string;
  candidateName: string;
  status: SpotStatus;
  willExpireAtSeconds: number | null;
  didStartAtSeconds: number | null;
  timeLimitSeconds: number;
  messageForCandidate: string | null;
  remainingTimeSeconds: number;
  elapsedTimeSeconds: number | null;
  isRetake: boolean;
  allowWebSearch: boolean;
  allowChatGPT: boolean;
  allowHint: boolean;
  entities: Array<
    | {
        __typename: "ChallengeEntity";
        challengeEntityId: number;
        questionVersion: string;
        enabledLanguages: Array<string>;
        appealMessage: string | null;
        challengeStatus: ChallengeStatus;
        challengeQuestion: {
          __typename?: "AlgorithmQuestion";
          id: string;
          questionId: number;
          version: string;
          variant: AlgorithmQuestionVariant;
          companyId: number;
          employeeId: string;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          difficulty: Difficulty;
          supportedLanguages: Array<string>;
          signature: string;
          correctnessTestCase: string;
          performanceTestCase: string;
          performanceTimeLimitMilliSeconds: number;
          hints: Array<{
            __typename?: "AlgorithmHint";
            id: number;
            questionId: number;
            questionVersion: string;
            descriptionJa: string | null;
            descriptionEn: string | null;
            orderNum: number;
          }>;
          initialCode: {
            __typename?: "AlgorithmInitialCode";
            c: string;
            cpp: string;
            csharp: string;
            dart: string;
            go: string;
            java: string;
            javascript: string;
            kotlin: string;
            mysql: string;
            perl: string;
            pgsql: string;
            php: string;
            python3: string;
            ruby: string;
            rust: string;
            scala: string;
            sqlite: string;
            swift: string;
            typescript: string;
          } | null;
        } | null;
        submissions: Array<{ __typename?: "ChallengeSubmission"; challengeSubmissionId: number }>;
      }
    | {
        __typename: "ProjectEntity";
        projectEntityId: number;
        appealMessage: string | null;
        agentVersion: string;
        projectStatus: ProjectStatus;
        question: {
          __typename?: "ProjectQuestion";
          questionId: number;
          version: string;
          projectVersion: string;
          variant: ProjectQuestionVariant;
          titleJa: string | null;
          titleEn: string | null;
          correctnessTestCase: string;
          performanceTestCase: string;
          defaultTestCase: string;
          descriptionJa: string | null;
          descriptionEn: string | null;
          timeLimitSeconds: number | null;
          fileIndexes: Array<string>;
          readOnlyFiles: Array<string>;
        } | null;
        submissions: Array<{ __typename?: "ProjectSubmission"; projectSubmissionId: number }>;
      }
    | {
        __typename: "QuizEntity";
        quizEntityId: number;
        packageVersion: string;
        quizStatus: QuizStatus;
        pb_package: {
          __typename?: "MultiChoicePackage";
          packageId: number;
          version: string;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          questions: Array<{
            __typename?: "MultiChoiceQuestion";
            id: number;
            version: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            variant: MultiChoiceQuestionVariant;
            options: Array<{
              __typename?: "MultiChoiceOption";
              id: number;
              titleJa: string | null;
              titleEn: string | null;
              isCorrect: boolean;
            }>;
          }>;
        } | null;
        submissions: Array<{
          __typename?: "QuizSubmission";
          quizSubmissionId: number;
          questionId: number;
          questionVersion: string;
          optionIds: Array<number>;
        }>;
      }
    | {
        __typename: "SystemDesignEntity";
        systemDesignEntityId: number;
        status: SystemDesignStatus;
        companyId: number;
        candidateId: string;
        employeeId: string;
        appealMessage: string | null;
        componentTypes: Array<SystemDesignComponentType>;
        question: {
          __typename?: "SystemDesignQuestion";
          questionId: number;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          initialSnapshot: string;
          hints: Array<{
            __typename?: "SystemDesignHint";
            systemDesignHintId: number;
            descriptionJa: string | null;
            descriptionEn: string | null;
          }>;
          scoringItems: Array<{
            __typename?: "SystemDesignScoringItem";
            systemDesignScoringItemId: number;
            category: SystemDesignScoringItemCategory;
            isHidden: boolean;
          }>;
        } | null;
        submissions: Array<{ __typename?: "SystemDesignSubmission"; systemDesignSubmissionId: number; snapshot: string }>;
      }
  >;
};

export type GetSpotForCandidateInterviewQueryVariables = Exact<{
  id: Scalars["String"]["input"];
  withAnswers: Scalars["Boolean"]["input"];
  ignoreInheritance: Scalars["Boolean"]["input"];
}>;

export type GetSpotForCandidateInterviewQuery = {
  __typename?: "Query";
  spot: {
    __typename?: "Spot";
    id: string;
    candidateName: string;
    status: SpotStatus;
    willExpireAtSeconds: number | null;
    didStartAtSeconds: number | null;
    timeLimitSeconds: number;
    messageForCandidate: string | null;
    remainingTimeSeconds: number;
    elapsedTimeSeconds: number | null;
    isRetake: boolean;
    allowWebSearch: boolean;
    allowChatGPT: boolean;
    allowHint: boolean;
    entities: Array<
      | {
          __typename: "ChallengeEntity";
          challengeEntityId: number;
          questionVersion: string;
          enabledLanguages: Array<string>;
          appealMessage: string | null;
          challengeStatus: ChallengeStatus;
          challengeQuestion: {
            __typename?: "AlgorithmQuestion";
            id: string;
            questionId: number;
            version: string;
            variant: AlgorithmQuestionVariant;
            companyId: number;
            employeeId: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            supportedLanguages: Array<string>;
            signature: string;
            correctnessTestCase: string;
            performanceTestCase: string;
            performanceTimeLimitMilliSeconds: number;
            hints: Array<{
              __typename?: "AlgorithmHint";
              id: number;
              questionId: number;
              questionVersion: string;
              descriptionJa: string | null;
              descriptionEn: string | null;
              orderNum: number;
            }>;
            initialCode: {
              __typename?: "AlgorithmInitialCode";
              c: string;
              cpp: string;
              csharp: string;
              dart: string;
              go: string;
              java: string;
              javascript: string;
              kotlin: string;
              mysql: string;
              perl: string;
              pgsql: string;
              php: string;
              python3: string;
              ruby: string;
              rust: string;
              scala: string;
              sqlite: string;
              swift: string;
              typescript: string;
            } | null;
          } | null;
          submissions: Array<{ __typename?: "ChallengeSubmission"; challengeSubmissionId: number }>;
        }
      | {
          __typename: "ProjectEntity";
          projectEntityId: number;
          appealMessage: string | null;
          agentVersion: string;
          projectStatus: ProjectStatus;
          question: {
            __typename?: "ProjectQuestion";
            questionId: number;
            version: string;
            projectVersion: string;
            variant: ProjectQuestionVariant;
            titleJa: string | null;
            titleEn: string | null;
            correctnessTestCase: string;
            performanceTestCase: string;
            defaultTestCase: string;
            descriptionJa: string | null;
            descriptionEn: string | null;
            timeLimitSeconds: number | null;
            fileIndexes: Array<string>;
            readOnlyFiles: Array<string>;
          } | null;
          submissions: Array<{ __typename?: "ProjectSubmission"; projectSubmissionId: number }>;
        }
      | {
          __typename: "QuizEntity";
          quizEntityId: number;
          packageVersion: string;
          quizStatus: QuizStatus;
          pb_package: {
            __typename?: "MultiChoicePackage";
            packageId: number;
            version: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            questions: Array<{
              __typename?: "MultiChoiceQuestion";
              id: number;
              version: string;
              titleJa: string | null;
              titleEn: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
              variant: MultiChoiceQuestionVariant;
              options: Array<{
                __typename?: "MultiChoiceOption";
                id: number;
                titleJa: string | null;
                titleEn: string | null;
                isCorrect: boolean;
              }>;
            }>;
          } | null;
          submissions: Array<{
            __typename?: "QuizSubmission";
            quizSubmissionId: number;
            questionId: number;
            questionVersion: string;
            optionIds: Array<number>;
          }>;
        }
      | {
          __typename: "SystemDesignEntity";
          systemDesignEntityId: number;
          status: SystemDesignStatus;
          companyId: number;
          candidateId: string;
          employeeId: string;
          appealMessage: string | null;
          componentTypes: Array<SystemDesignComponentType>;
          question: {
            __typename?: "SystemDesignQuestion";
            questionId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            initialSnapshot: string;
            hints: Array<{
              __typename?: "SystemDesignHint";
              systemDesignHintId: number;
              descriptionJa: string | null;
              descriptionEn: string | null;
            }>;
            scoringItems: Array<{
              __typename?: "SystemDesignScoringItem";
              systemDesignScoringItemId: number;
              category: SystemDesignScoringItemCategory;
              isHidden: boolean;
            }>;
          } | null;
          submissions: Array<{ __typename?: "SystemDesignSubmission"; systemDesignSubmissionId: number; snapshot: string }>;
        }
    >;
  };
};

export type SpotForEntryInterviewFragment = {
  __typename?: "Spot";
  id: string;
  name: string;
  candidateName: string;
  timeLimitSeconds: number;
  messageForCandidate: string | null;
  entityCount: number;
  elapsedTimeSeconds: number | null;
  willExpireAtSeconds: number | null;
  company: { __typename?: "Company"; name: string } | null;
};

export type GetSpotForEntryInterviewQueryVariables = Exact<{
  id: Scalars["String"]["input"];
  withAnswers: Scalars["Boolean"]["input"];
  ignoreInheritance: Scalars["Boolean"]["input"];
}>;

export type GetSpotForEntryInterviewQuery = {
  __typename?: "Query";
  spot: {
    __typename?: "Spot";
    id: string;
    name: string;
    candidateName: string;
    timeLimitSeconds: number;
    messageForCandidate: string | null;
    entityCount: number;
    elapsedTimeSeconds: number | null;
    willExpireAtSeconds: number | null;
    company: { __typename?: "Company"; name: string } | null;
  };
};

export type StartSpotMutationVariables = Exact<{
  payload: StartSpotInput;
}>;

export type StartSpotMutation = {
  __typename?: "Mutation";
  startSpot: {
    __typename?: "Spot";
    id: string;
    candidateName: string;
    status: SpotStatus;
    willExpireAtSeconds: number | null;
    didStartAtSeconds: number | null;
    timeLimitSeconds: number;
    messageForCandidate: string | null;
    remainingTimeSeconds: number;
    elapsedTimeSeconds: number | null;
    isRetake: boolean;
    allowWebSearch: boolean;
    allowChatGPT: boolean;
    allowHint: boolean;
    entities: Array<
      | {
          __typename: "ChallengeEntity";
          challengeEntityId: number;
          questionVersion: string;
          enabledLanguages: Array<string>;
          appealMessage: string | null;
          challengeStatus: ChallengeStatus;
          challengeQuestion: {
            __typename?: "AlgorithmQuestion";
            id: string;
            questionId: number;
            version: string;
            variant: AlgorithmQuestionVariant;
            companyId: number;
            employeeId: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            supportedLanguages: Array<string>;
            signature: string;
            correctnessTestCase: string;
            performanceTestCase: string;
            performanceTimeLimitMilliSeconds: number;
            hints: Array<{
              __typename?: "AlgorithmHint";
              id: number;
              questionId: number;
              questionVersion: string;
              descriptionJa: string | null;
              descriptionEn: string | null;
              orderNum: number;
            }>;
            initialCode: {
              __typename?: "AlgorithmInitialCode";
              c: string;
              cpp: string;
              csharp: string;
              dart: string;
              go: string;
              java: string;
              javascript: string;
              kotlin: string;
              mysql: string;
              perl: string;
              pgsql: string;
              php: string;
              python3: string;
              ruby: string;
              rust: string;
              scala: string;
              sqlite: string;
              swift: string;
              typescript: string;
            } | null;
          } | null;
          submissions: Array<{ __typename?: "ChallengeSubmission"; challengeSubmissionId: number }>;
        }
      | {
          __typename: "ProjectEntity";
          projectEntityId: number;
          appealMessage: string | null;
          agentVersion: string;
          projectStatus: ProjectStatus;
          question: {
            __typename?: "ProjectQuestion";
            questionId: number;
            version: string;
            projectVersion: string;
            variant: ProjectQuestionVariant;
            titleJa: string | null;
            titleEn: string | null;
            correctnessTestCase: string;
            performanceTestCase: string;
            defaultTestCase: string;
            descriptionJa: string | null;
            descriptionEn: string | null;
            timeLimitSeconds: number | null;
            fileIndexes: Array<string>;
            readOnlyFiles: Array<string>;
          } | null;
          submissions: Array<{ __typename?: "ProjectSubmission"; projectSubmissionId: number }>;
        }
      | {
          __typename: "QuizEntity";
          quizEntityId: number;
          packageVersion: string;
          quizStatus: QuizStatus;
          pb_package: {
            __typename?: "MultiChoicePackage";
            packageId: number;
            version: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            questions: Array<{
              __typename?: "MultiChoiceQuestion";
              id: number;
              version: string;
              titleJa: string | null;
              titleEn: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
              variant: MultiChoiceQuestionVariant;
              options: Array<{
                __typename?: "MultiChoiceOption";
                id: number;
                titleJa: string | null;
                titleEn: string | null;
                isCorrect: boolean;
              }>;
            }>;
          } | null;
          submissions: Array<{
            __typename?: "QuizSubmission";
            quizSubmissionId: number;
            questionId: number;
            questionVersion: string;
            optionIds: Array<number>;
          }>;
        }
      | {
          __typename: "SystemDesignEntity";
          systemDesignEntityId: number;
          status: SystemDesignStatus;
          companyId: number;
          candidateId: string;
          employeeId: string;
          appealMessage: string | null;
          componentTypes: Array<SystemDesignComponentType>;
          question: {
            __typename?: "SystemDesignQuestion";
            questionId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            initialSnapshot: string;
            hints: Array<{
              __typename?: "SystemDesignHint";
              systemDesignHintId: number;
              descriptionJa: string | null;
              descriptionEn: string | null;
            }>;
            scoringItems: Array<{
              __typename?: "SystemDesignScoringItem";
              systemDesignScoringItemId: number;
              category: SystemDesignScoringItemCategory;
              isHidden: boolean;
            }>;
          } | null;
          submissions: Array<{ __typename?: "SystemDesignSubmission"; systemDesignSubmissionId: number; snapshot: string }>;
        }
    >;
  };
};

export type EndSpotMutationVariables = Exact<{
  spotId: Scalars["String"]["input"];
}>;

export type EndSpotMutation = {
  __typename?: "Mutation";
  endSpot: {
    __typename?: "Spot";
    id: string;
    candidateName: string;
    status: SpotStatus;
    willExpireAtSeconds: number | null;
    didStartAtSeconds: number | null;
    timeLimitSeconds: number;
    messageForCandidate: string | null;
    remainingTimeSeconds: number;
    elapsedTimeSeconds: number | null;
    isRetake: boolean;
    allowWebSearch: boolean;
    allowChatGPT: boolean;
    allowHint: boolean;
    entities: Array<
      | {
          __typename: "ChallengeEntity";
          challengeEntityId: number;
          questionVersion: string;
          enabledLanguages: Array<string>;
          appealMessage: string | null;
          challengeStatus: ChallengeStatus;
          challengeQuestion: {
            __typename?: "AlgorithmQuestion";
            id: string;
            questionId: number;
            version: string;
            variant: AlgorithmQuestionVariant;
            companyId: number;
            employeeId: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            supportedLanguages: Array<string>;
            signature: string;
            correctnessTestCase: string;
            performanceTestCase: string;
            performanceTimeLimitMilliSeconds: number;
            hints: Array<{
              __typename?: "AlgorithmHint";
              id: number;
              questionId: number;
              questionVersion: string;
              descriptionJa: string | null;
              descriptionEn: string | null;
              orderNum: number;
            }>;
            initialCode: {
              __typename?: "AlgorithmInitialCode";
              c: string;
              cpp: string;
              csharp: string;
              dart: string;
              go: string;
              java: string;
              javascript: string;
              kotlin: string;
              mysql: string;
              perl: string;
              pgsql: string;
              php: string;
              python3: string;
              ruby: string;
              rust: string;
              scala: string;
              sqlite: string;
              swift: string;
              typescript: string;
            } | null;
          } | null;
          submissions: Array<{ __typename?: "ChallengeSubmission"; challengeSubmissionId: number }>;
        }
      | {
          __typename: "ProjectEntity";
          projectEntityId: number;
          appealMessage: string | null;
          agentVersion: string;
          projectStatus: ProjectStatus;
          question: {
            __typename?: "ProjectQuestion";
            questionId: number;
            version: string;
            projectVersion: string;
            variant: ProjectQuestionVariant;
            titleJa: string | null;
            titleEn: string | null;
            correctnessTestCase: string;
            performanceTestCase: string;
            defaultTestCase: string;
            descriptionJa: string | null;
            descriptionEn: string | null;
            timeLimitSeconds: number | null;
            fileIndexes: Array<string>;
            readOnlyFiles: Array<string>;
          } | null;
          submissions: Array<{ __typename?: "ProjectSubmission"; projectSubmissionId: number }>;
        }
      | {
          __typename: "QuizEntity";
          quizEntityId: number;
          packageVersion: string;
          quizStatus: QuizStatus;
          pb_package: {
            __typename?: "MultiChoicePackage";
            packageId: number;
            version: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            questions: Array<{
              __typename?: "MultiChoiceQuestion";
              id: number;
              version: string;
              titleJa: string | null;
              titleEn: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
              variant: MultiChoiceQuestionVariant;
              options: Array<{
                __typename?: "MultiChoiceOption";
                id: number;
                titleJa: string | null;
                titleEn: string | null;
                isCorrect: boolean;
              }>;
            }>;
          } | null;
          submissions: Array<{
            __typename?: "QuizSubmission";
            quizSubmissionId: number;
            questionId: number;
            questionVersion: string;
            optionIds: Array<number>;
          }>;
        }
      | {
          __typename: "SystemDesignEntity";
          systemDesignEntityId: number;
          status: SystemDesignStatus;
          companyId: number;
          candidateId: string;
          employeeId: string;
          appealMessage: string | null;
          componentTypes: Array<SystemDesignComponentType>;
          question: {
            __typename?: "SystemDesignQuestion";
            questionId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            initialSnapshot: string;
            hints: Array<{
              __typename?: "SystemDesignHint";
              systemDesignHintId: number;
              descriptionJa: string | null;
              descriptionEn: string | null;
            }>;
            scoringItems: Array<{
              __typename?: "SystemDesignScoringItem";
              systemDesignScoringItemId: number;
              category: SystemDesignScoringItemCategory;
              isHidden: boolean;
            }>;
          } | null;
          submissions: Array<{ __typename?: "SystemDesignSubmission"; systemDesignSubmissionId: number; snapshot: string }>;
        }
    >;
  };
};

export type InterviewDetailSharedLinkFragment = {
  __typename?: "SpotSharedLink";
  linkHash: string;
  spotId: string;
  showAnswer: boolean;
  showRelativeEvaluation: boolean;
  showPlayback: boolean;
  showRank: boolean;
  showReview: boolean;
  showScore: boolean;
  showCheatDetect: boolean;
};

export type InterviewDetailFromSharedLinkFragment = {
  __typename?: "Spot";
  id: string;
  isRetake: boolean;
  parentId: string;
  candidateName: string;
  candidateEmail: string;
  evaluatedAtSeconds: number | null;
  isPublic: boolean | null;
  name: string;
  status: SpotStatus;
  canShowCandidateName: boolean;
  sharedLinks: Array<{
    __typename?: "SpotSharedLink";
    linkHash: string;
    spotId: string;
    showAnswer: boolean;
    showRelativeEvaluation: boolean;
    showPlayback: boolean;
    showRank: boolean;
    showReview: boolean;
    showScore: boolean;
    showCheatDetect: boolean;
  }>;
};

export type GetInterviewDetailFromSharedLinkQueryVariables = Exact<{
  spotId: Scalars["String"]["input"];
  linkHash: Scalars["String"]["input"];
}>;

export type GetInterviewDetailFromSharedLinkQuery = {
  __typename?: "Query";
  spotFromSharedLink: {
    __typename?: "Spot";
    id: string;
    isRetake: boolean;
    parentId: string;
    candidateName: string;
    candidateEmail: string;
    evaluatedAtSeconds: number | null;
    isPublic: boolean | null;
    name: string;
    status: SpotStatus;
    canShowCandidateName: boolean;
    sharedLinks: Array<{
      __typename?: "SpotSharedLink";
      linkHash: string;
      spotId: string;
      showAnswer: boolean;
      showRelativeEvaluation: boolean;
      showPlayback: boolean;
      showRank: boolean;
      showReview: boolean;
      showScore: boolean;
      showCheatDetect: boolean;
    }>;
  };
};

export type ExamForForEmployeeAssessmentsFragment = {
  __typename?: "Exam";
  examId: string;
  assessmentId: string;
  parentId: string | null;
  numInterval: number;
  totalScore: number;
  status: ExamStatus;
  relativeScore: number;
  rankEvaluation: StatisticsEvaluation;
  isReliableRank: boolean;
  finalizedAtSeconds: number | null;
  willEndAtSeconds: number | null;
  expiredAtSeconds: number | null;
  createdAtSeconds: number | null;
  updatedAtSeconds: number | null;
  deletedAtSeconds: number | null;
  didEndAtSeconds: number | null;
};

export type AssessmentForEmployeeAssessmentFragment = {
  __typename?: "Assessment";
  assessmentId: string;
  name: string;
  status: AssessmentStatus;
  scoreTrend: AssessmentScoreTrend;
  startScheduleAtSeconds: number | null;
  nextScheduleAtSeconds: number | null;
  remindBeforeSeconds: number | null;
  examInterval: AssessmentExamInterval;
  talent: { __typename?: "User"; email: string; displayName: string; photoUrl: string };
  previousBestExam: {
    __typename?: "Exam";
    examId: string;
    assessmentId: string;
    parentId: string | null;
    numInterval: number;
    totalScore: number;
    status: ExamStatus;
    relativeScore: number;
    rankEvaluation: StatisticsEvaluation;
    isReliableRank: boolean;
    finalizedAtSeconds: number | null;
    willEndAtSeconds: number | null;
    expiredAtSeconds: number | null;
    createdAtSeconds: number | null;
    updatedAtSeconds: number | null;
    deletedAtSeconds: number | null;
    didEndAtSeconds: number | null;
  } | null;
  latestBestExam: {
    __typename?: "Exam";
    examId: string;
    assessmentId: string;
    parentId: string | null;
    numInterval: number;
    totalScore: number;
    status: ExamStatus;
    relativeScore: number;
    rankEvaluation: StatisticsEvaluation;
    isReliableRank: boolean;
    finalizedAtSeconds: number | null;
    willEndAtSeconds: number | null;
    expiredAtSeconds: number | null;
    createdAtSeconds: number | null;
    updatedAtSeconds: number | null;
    deletedAtSeconds: number | null;
    didEndAtSeconds: number | null;
  } | null;
  viewers: Array<
    | { __typename?: "EmployeeGroup"; employeeGroupId: string; groupName: string }
    | { __typename?: "User"; uid: string; displayName: string; email: string; photoUrl: string }
  >;
  reportSettings: {
    __typename?: "AssessmentReportSettings";
    showAnswer: boolean;
    showPlayback: boolean;
    showRelativeEvaluation: boolean;
  } | null;
};

export type ListAssessmentsByCompanyIdForEmployeeAssessmentsFragment = {
  __typename?: "ListAssessmentsByCompanyId";
  count: number;
  hasNext: boolean;
  assessments: Array<{
    __typename?: "Assessment";
    assessmentId: string;
    name: string;
    status: AssessmentStatus;
    scoreTrend: AssessmentScoreTrend;
    startScheduleAtSeconds: number | null;
    nextScheduleAtSeconds: number | null;
    remindBeforeSeconds: number | null;
    examInterval: AssessmentExamInterval;
    talent: { __typename?: "User"; email: string; displayName: string; photoUrl: string };
    previousBestExam: {
      __typename?: "Exam";
      examId: string;
      assessmentId: string;
      parentId: string | null;
      numInterval: number;
      totalScore: number;
      status: ExamStatus;
      relativeScore: number;
      rankEvaluation: StatisticsEvaluation;
      isReliableRank: boolean;
      finalizedAtSeconds: number | null;
      willEndAtSeconds: number | null;
      expiredAtSeconds: number | null;
      createdAtSeconds: number | null;
      updatedAtSeconds: number | null;
      deletedAtSeconds: number | null;
      didEndAtSeconds: number | null;
    } | null;
    latestBestExam: {
      __typename?: "Exam";
      examId: string;
      assessmentId: string;
      parentId: string | null;
      numInterval: number;
      totalScore: number;
      status: ExamStatus;
      relativeScore: number;
      rankEvaluation: StatisticsEvaluation;
      isReliableRank: boolean;
      finalizedAtSeconds: number | null;
      willEndAtSeconds: number | null;
      expiredAtSeconds: number | null;
      createdAtSeconds: number | null;
      updatedAtSeconds: number | null;
      deletedAtSeconds: number | null;
      didEndAtSeconds: number | null;
    } | null;
    viewers: Array<
      | { __typename?: "EmployeeGroup"; employeeGroupId: string; groupName: string }
      | { __typename?: "User"; uid: string; displayName: string; email: string; photoUrl: string }
    >;
    reportSettings: {
      __typename?: "AssessmentReportSettings";
      showAnswer: boolean;
      showPlayback: boolean;
      showRelativeEvaluation: boolean;
    } | null;
  }>;
};

export type ListAssessmentsByCompanyIdForEmployeeAssessmentsQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  withCount: Scalars["Boolean"]["input"];
  withDeleted: Scalars["Boolean"]["input"];
  filters: AssessmentFilters;
  sortMethod: AssessmentSortMethod;
  size: Scalars["Int"]["input"];
  offset: Scalars["Int"]["input"];
  isDescending: Scalars["Boolean"]["input"];
}>;

export type ListAssessmentsByCompanyIdForEmployeeAssessmentsQuery = {
  __typename?: "Query";
  listAssessmentsByCompanyId: {
    __typename?: "ListAssessmentsByCompanyId";
    count: number;
    hasNext: boolean;
    assessments: Array<{
      __typename?: "Assessment";
      assessmentId: string;
      name: string;
      status: AssessmentStatus;
      scoreTrend: AssessmentScoreTrend;
      startScheduleAtSeconds: number | null;
      nextScheduleAtSeconds: number | null;
      remindBeforeSeconds: number | null;
      examInterval: AssessmentExamInterval;
      talent: { __typename?: "User"; email: string; displayName: string; photoUrl: string };
      previousBestExam: {
        __typename?: "Exam";
        examId: string;
        assessmentId: string;
        parentId: string | null;
        numInterval: number;
        totalScore: number;
        status: ExamStatus;
        relativeScore: number;
        rankEvaluation: StatisticsEvaluation;
        isReliableRank: boolean;
        finalizedAtSeconds: number | null;
        willEndAtSeconds: number | null;
        expiredAtSeconds: number | null;
        createdAtSeconds: number | null;
        updatedAtSeconds: number | null;
        deletedAtSeconds: number | null;
        didEndAtSeconds: number | null;
      } | null;
      latestBestExam: {
        __typename?: "Exam";
        examId: string;
        assessmentId: string;
        parentId: string | null;
        numInterval: number;
        totalScore: number;
        status: ExamStatus;
        relativeScore: number;
        rankEvaluation: StatisticsEvaluation;
        isReliableRank: boolean;
        finalizedAtSeconds: number | null;
        willEndAtSeconds: number | null;
        expiredAtSeconds: number | null;
        createdAtSeconds: number | null;
        updatedAtSeconds: number | null;
        deletedAtSeconds: number | null;
        didEndAtSeconds: number | null;
      } | null;
      viewers: Array<
        | { __typename?: "EmployeeGroup"; employeeGroupId: string; groupName: string }
        | { __typename?: "User"; uid: string; displayName: string; email: string; photoUrl: string }
      >;
      reportSettings: {
        __typename?: "AssessmentReportSettings";
        showAnswer: boolean;
        showPlayback: boolean;
        showRelativeEvaluation: boolean;
      } | null;
    }>;
  };
};

export type AssessmentListOptionsByFieldsResponseForEmployeeAssessmentsSearchParamsFragment = {
  __typename?: "AssessmentListOptionsByFieldsResponse";
  viewers: Array<
    | { __typename: "EmployeeGroup"; employeeGroupId: string; groupName: string }
    | { __typename: "User"; uid: string; displayName: string; photoUrl: string; email: string }
  >;
  talents: Array<{ __typename?: "User"; uid: string; displayName: string; photoUrl: string; email: string }>;
};

export type GetAssessmentSearchParamsForEmployeeAssessmentsSearchParamsQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
}>;

export type GetAssessmentSearchParamsForEmployeeAssessmentsSearchParamsQuery = {
  __typename?: "Query";
  assessmentListOptionsByFields: {
    __typename?: "AssessmentListOptionsByFieldsResponse";
    viewers: Array<
      | { __typename: "EmployeeGroup"; employeeGroupId: string; groupName: string }
      | { __typename: "User"; uid: string; displayName: string; photoUrl: string; email: string }
    >;
    talents: Array<{ __typename?: "User"; uid: string; displayName: string; photoUrl: string; email: string }>;
  };
};

export type CreateExamImmediatelyMutationVariables = Exact<{
  input: CreateExamImmediatelyRequestInput;
}>;

export type CreateExamImmediatelyMutation = { __typename?: "Mutation"; createExamImmediately: { __typename?: "Exam"; examId: string } };

export type DeleteAssessmentForEmployeeAssessmentsMutationVariables = Exact<{
  input: DeleteAssessmentRequestInput;
}>;

export type DeleteAssessmentForEmployeeAssessmentsMutation = { __typename?: "Mutation"; deleteAssessment: boolean };

export type UpdateAssessmentForEmployeeAssessmentsMutationVariables = Exact<{
  input: UpdateAssessmentRequestInput;
}>;

export type UpdateAssessmentForEmployeeAssessmentsMutation = {
  __typename?: "Mutation";
  updateAssessment: { __typename: "Assessment"; id: string; assessmentId: string };
};

export type UpdateAssessmentReportShareSettingsResponseForEmployeeAssessmentsFragment = {
  __typename: "Assessment";
  id: string;
  assessmentId: string;
  reportSettings: {
    __typename?: "AssessmentReportSettings";
    showAnswer: boolean;
    showPlayback: boolean;
    showRelativeEvaluation: boolean;
  } | null;
};

export type UpdateAssessmentReportShareSettingsForEmployeeAssessmentsMutationVariables = Exact<{
  input: UpdateAssessmentRequestInput;
}>;

export type UpdateAssessmentReportShareSettingsForEmployeeAssessmentsMutation = {
  __typename?: "Mutation";
  updateAssessment: {
    __typename: "Assessment";
    id: string;
    assessmentId: string;
    reportSettings: {
      __typename?: "AssessmentReportSettings";
      showAnswer: boolean;
      showPlayback: boolean;
      showRelativeEvaluation: boolean;
    } | null;
  };
};

export type CreateAssessmentForAssessmentsCreateMutationVariables = Exact<{
  input: CreateAssessmentRequestInput;
}>;

export type CreateAssessmentForAssessmentsCreateMutation = {
  __typename?: "Mutation";
  createAssessment: { __typename?: "Assessment"; assessmentId: string };
};

export type AssessmentDetailUserFragment = {
  __typename?: "User";
  id: string;
  uid: string;
  displayName: string;
  email: string;
  photoUrl: string;
  language: Language;
};

export type ExamForAssessmentsIdDetailFragment = {
  __typename: "Exam";
  id: string;
  examId: string;
  assessmentId: string;
  originId: string;
  numInterval: number;
  numTry: number;
  startIntervalAtSeconds: number | null;
  endIntervalAtSeconds: number | null;
  relativeScore: number;
  totalScore: number;
  status: ExamStatus;
  createdAtSeconds: number | null;
  willEndAtSeconds: number | null;
};

export type AssessmentForDetailEmployeeFragment = {
  __typename?: "Assessment";
  id: string;
  assessmentId: string;
  createdAtSeconds: number | null;
  nextScheduleAtSeconds: number | null;
  examInterval: AssessmentExamInterval;
  rootExamIds: Array<string>;
  isPublic: boolean;
  name: string;
  memo: string | null;
  messageForTalent: string | null;
  timeLimitSeconds: number | null;
  status: AssessmentStatus;
  reportSettings: {
    __typename?: "AssessmentReportSettings";
    showAnswer: boolean;
    showPlayback: boolean;
    showRelativeEvaluation: boolean;
  } | null;
  questions: Array<
    | { __typename: "AlgorithmQuestion" }
    | { __typename: "MultiChoicePackage" }
    | { __typename: "ProjectQuestion" }
    | { __typename: "SystemDesignQuestion" }
  >;
  employee: { __typename?: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string; language: Language } | null;
  talent: { __typename?: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string; language: Language };
  viewers: Array<
    | {
        __typename: "EmployeeGroup";
        id: string;
        groupName: string;
        companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string; role: Role }>;
      }
    | { __typename: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string; language: Language }
  >;
};

export type ListBestExamsResponseForAssessmentsIdDetailFragment = {
  __typename?: "ListBestExamsByAssessmentId";
  count: number;
  hasNext: boolean;
  exams: Array<{
    __typename: "Exam";
    id: string;
    examId: string;
    assessmentId: string;
    originId: string;
    numInterval: number;
    numTry: number;
    startIntervalAtSeconds: number | null;
    endIntervalAtSeconds: number | null;
    relativeScore: number;
    totalScore: number;
    status: ExamStatus;
    createdAtSeconds: number | null;
    willEndAtSeconds: number | null;
  }>;
};

export type GetAssessmentForAssessmentsIdDetailEmployeeQueryVariables = Exact<{
  assessmentId: Scalars["String"]["input"];
  withCount: Scalars["Boolean"]["input"];
  size: Scalars["Int"]["input"];
  offset: Scalars["Int"]["input"];
  isDescending: Scalars["Boolean"]["input"];
}>;

export type GetAssessmentForAssessmentsIdDetailEmployeeQuery = {
  __typename?: "Query";
  assessment: {
    __typename?: "Assessment";
    id: string;
    assessmentId: string;
    createdAtSeconds: number | null;
    nextScheduleAtSeconds: number | null;
    examInterval: AssessmentExamInterval;
    rootExamIds: Array<string>;
    isPublic: boolean;
    name: string;
    memo: string | null;
    messageForTalent: string | null;
    timeLimitSeconds: number | null;
    status: AssessmentStatus;
    reportSettings: {
      __typename?: "AssessmentReportSettings";
      showAnswer: boolean;
      showPlayback: boolean;
      showRelativeEvaluation: boolean;
    } | null;
    questions: Array<
      | { __typename: "AlgorithmQuestion" }
      | { __typename: "MultiChoicePackage" }
      | { __typename: "ProjectQuestion" }
      | { __typename: "SystemDesignQuestion" }
    >;
    employee: { __typename?: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string; language: Language } | null;
    talent: { __typename?: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string; language: Language };
    viewers: Array<
      | {
          __typename: "EmployeeGroup";
          id: string;
          groupName: string;
          companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string; role: Role }>;
        }
      | { __typename: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string; language: Language }
    >;
  };
  listBestExamsByAssessmentId: {
    __typename?: "ListBestExamsByAssessmentId";
    count: number;
    hasNext: boolean;
    exams: Array<{
      __typename: "Exam";
      id: string;
      examId: string;
      assessmentId: string;
      originId: string;
      numInterval: number;
      numTry: number;
      startIntervalAtSeconds: number | null;
      endIntervalAtSeconds: number | null;
      relativeScore: number;
      totalScore: number;
      status: ExamStatus;
      createdAtSeconds: number | null;
      willEndAtSeconds: number | null;
    }>;
  };
};

export type ListExamsRecursivePayloadForAssessmentsIdDetailEmployeeFragment = {
  __typename?: "ListExamsRecursivePayload";
  bestExamId: string;
  exams: Array<{
    __typename: "Exam";
    id: string;
    examId: string;
    assessmentId: string;
    originId: string;
    numInterval: number;
    numTry: number;
    startIntervalAtSeconds: number | null;
    endIntervalAtSeconds: number | null;
    relativeScore: number;
    totalScore: number;
    status: ExamStatus;
    createdAtSeconds: number | null;
    willEndAtSeconds: number | null;
  }>;
};

export type ListExamsRecursiveForAssessmentsIdDetailEmployeeQueryVariables = Exact<{
  examId: Scalars["String"]["input"];
}>;

export type ListExamsRecursiveForAssessmentsIdDetailEmployeeQuery = {
  __typename?: "Query";
  listExamsRecursive: {
    __typename?: "ListExamsRecursivePayload";
    bestExamId: string;
    exams: Array<{
      __typename: "Exam";
      id: string;
      examId: string;
      assessmentId: string;
      originId: string;
      numInterval: number;
      numTry: number;
      startIntervalAtSeconds: number | null;
      endIntervalAtSeconds: number | null;
      relativeScore: number;
      totalScore: number;
      status: ExamStatus;
      createdAtSeconds: number | null;
      willEndAtSeconds: number | null;
    }>;
  };
};

export type DeleteAssessmentForAssessmentsIdDetailMutationVariables = Exact<{
  input: DeleteAssessmentRequestInput;
}>;

export type DeleteAssessmentForAssessmentsIdDetailMutation = { __typename?: "Mutation"; deleteAssessment: boolean };

export type UpdateAssessmentForAssessmentsIdDetailMutationVariables = Exact<{
  input: UpdateAssessmentRequestInput;
}>;

export type UpdateAssessmentForAssessmentsIdDetailMutation = {
  __typename?: "Mutation";
  updateAssessment: {
    __typename?: "Assessment";
    id: string;
    assessmentId: string;
    createdAtSeconds: number | null;
    nextScheduleAtSeconds: number | null;
    examInterval: AssessmentExamInterval;
    rootExamIds: Array<string>;
    isPublic: boolean;
    name: string;
    memo: string | null;
    messageForTalent: string | null;
    timeLimitSeconds: number | null;
    status: AssessmentStatus;
    reportSettings: {
      __typename?: "AssessmentReportSettings";
      showAnswer: boolean;
      showPlayback: boolean;
      showRelativeEvaluation: boolean;
    } | null;
    questions: Array<
      | { __typename: "AlgorithmQuestion" }
      | { __typename: "MultiChoicePackage" }
      | { __typename: "ProjectQuestion" }
      | { __typename: "SystemDesignQuestion" }
    >;
    employee: { __typename?: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string; language: Language } | null;
    talent: { __typename?: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string; language: Language };
    viewers: Array<
      | {
          __typename: "EmployeeGroup";
          id: string;
          groupName: string;
          companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string; role: Role }>;
        }
      | { __typename: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string; language: Language }
    >;
  };
};

export type UpdateAssessmentForAssessmentsIdUpdateMutationVariables = Exact<{
  input: UpdateAssessmentRequestInput;
}>;

export type UpdateAssessmentForAssessmentsIdUpdateMutation = {
  __typename?: "Mutation";
  updateAssessment: { __typename?: "Assessment"; assessmentId: string };
};

export type EvaluationMetricWithEmployeeResponseFragment = {
  __typename?: "EvaluationMetric";
  id: string;
  metricId: number;
  companyId: number;
  createdBy: string;
  title: string;
  description: string;
  createdAtSeconds: number;
  employee: {
    __typename?: "User";
    id: string;
    uid: string;
    displayName: string;
    email: string;
    fullName: string;
    photoUrl: string;
    emailVerified: boolean;
    language: Language;
    provider: AuthProvider | null;
  };
};

export type CreateEvaluationMetricMutationVariables = Exact<{
  input: CreateEvaluationMetricInput;
}>;

export type CreateEvaluationMetricMutation = {
  __typename?: "Mutation";
  createEvaluationMetric: {
    __typename?: "EvaluationMetric";
    id: string;
    metricId: number;
    companyId: number;
    createdBy: string;
    title: string;
    description: string;
    createdAtSeconds: number;
    employee: {
      __typename?: "User";
      id: string;
      uid: string;
      displayName: string;
      email: string;
      fullName: string;
      photoUrl: string;
      emailVerified: boolean;
      language: Language;
      provider: AuthProvider | null;
    };
  };
};

export type GetEvaluationMetricQueryVariables = Exact<{
  metricId: Scalars["Int"]["input"];
}>;

export type GetEvaluationMetricQuery = {
  __typename?: "Query";
  evaluationMetric: {
    __typename?: "EvaluationMetric";
    id: string;
    metricId: number;
    companyId: number;
    createdBy: string;
    title: string;
    description: string;
    createdAtSeconds: number;
    employee: {
      __typename?: "User";
      id: string;
      uid: string;
      displayName: string;
      email: string;
      fullName: string;
      photoUrl: string;
      emailVerified: boolean;
      language: Language;
      provider: AuthProvider | null;
    };
  };
};

export type ListEvaluationMetricsByCompanyIdQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  size: Scalars["Int"]["input"];
  cursor: InputMaybe<Scalars["Int"]["input"]>;
  withCount: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type ListEvaluationMetricsByCompanyIdQuery = {
  __typename?: "Query";
  evaluationMetricsByCompany: {
    __typename?: "EvaluationMetricsByCompanyResponse";
    count: number;
    metrics: Array<{
      __typename?: "EvaluationMetric";
      id: string;
      metricId: number;
      companyId: number;
      createdBy: string;
      title: string;
      description: string;
      createdAtSeconds: number;
      employee: {
        __typename?: "User";
        id: string;
        uid: string;
        displayName: string;
        email: string;
        fullName: string;
        photoUrl: string;
        emailVerified: boolean;
        language: Language;
        provider: AuthProvider | null;
      };
    }>;
    pager: { __typename?: "EvaluationPager"; cursor: number; hasNext: boolean } | null;
  } | null;
};

export type UpdateEvaluationMetricMutationVariables = Exact<{
  input: UpdateEvaluationMetricInput;
}>;

export type UpdateEvaluationMetricMutation = {
  __typename?: "Mutation";
  updateEvaluationMetric: { __typename?: "UpdateEvaluationMetricPayload"; metricId: number; title: string; description: string };
};

export type DeleteEvaluationMetricMutationVariables = Exact<{
  input: DeleteEvaluationMetricInput;
}>;

export type DeleteEvaluationMetricMutation = { __typename?: "Mutation"; deleteEvaluationMetric: boolean };

export type DeleteMultiEvaluationMetricsMutationVariables = Exact<{
  input: DeleteMultiEvaluationMetricsInput;
}>;

export type DeleteMultiEvaluationMetricsMutation = { __typename?: "Mutation"; deleteMultiEvaluationMetrics: boolean };

export type EvaluationMetricGroupsWithMetricResponseFragment = {
  __typename?: "EvaluationMetricGroup";
  id: string;
  metricGroupId: number;
  companyId: number;
  title: string;
  description: string;
  createdBy: string;
  metrics: Array<{
    __typename?: "EvaluationMetric";
    id: string;
    metricId: number;
    companyId: number;
    createdBy: string;
    title: string;
    description: string;
    createdAtSeconds: number;
    employee: {
      __typename?: "User";
      id: string;
      uid: string;
      displayName: string;
      email: string;
      fullName: string;
      photoUrl: string;
      emailVerified: boolean;
      language: Language;
      provider: AuthProvider | null;
    };
  }>;
  employee: {
    __typename?: "User";
    id: string;
    uid: string;
    displayName: string;
    email: string;
    fullName: string;
    photoUrl: string;
    emailVerified: boolean;
    language: Language;
    provider: AuthProvider | null;
  };
};

export type CreateEvaluationMetricGroupMutationVariables = Exact<{
  input: CreateEvaluationMetricGroupInput;
}>;

export type CreateEvaluationMetricGroupMutation = {
  __typename?: "Mutation";
  createEvaluationMetricGroup: {
    __typename?: "EvaluationMetricGroup";
    id: string;
    metricGroupId: number;
    companyId: number;
    title: string;
    description: string;
    createdBy: string;
    metrics: Array<{
      __typename?: "EvaluationMetric";
      id: string;
      metricId: number;
      companyId: number;
      createdBy: string;
      title: string;
      description: string;
      createdAtSeconds: number;
      employee: {
        __typename?: "User";
        id: string;
        uid: string;
        displayName: string;
        email: string;
        fullName: string;
        photoUrl: string;
        emailVerified: boolean;
        language: Language;
        provider: AuthProvider | null;
      };
    }>;
    employee: {
      __typename?: "User";
      id: string;
      uid: string;
      displayName: string;
      email: string;
      fullName: string;
      photoUrl: string;
      emailVerified: boolean;
      language: Language;
      provider: AuthProvider | null;
    };
  };
};

export type GetEvaluationMetricGroupQueryVariables = Exact<{
  metricGroupId: Scalars["Int"]["input"];
}>;

export type GetEvaluationMetricGroupQuery = {
  __typename?: "Query";
  evaluationMetricGroup: {
    __typename?: "EvaluationMetricGroup";
    id: string;
    metricGroupId: number;
    companyId: number;
    title: string;
    description: string;
    createdBy: string;
    metrics: Array<{
      __typename?: "EvaluationMetric";
      id: string;
      metricId: number;
      companyId: number;
      createdBy: string;
      title: string;
      description: string;
      createdAtSeconds: number;
      employee: {
        __typename?: "User";
        id: string;
        uid: string;
        displayName: string;
        email: string;
        fullName: string;
        photoUrl: string;
        emailVerified: boolean;
        language: Language;
        provider: AuthProvider | null;
      };
    }>;
    employee: {
      __typename?: "User";
      id: string;
      uid: string;
      displayName: string;
      email: string;
      fullName: string;
      photoUrl: string;
      emailVerified: boolean;
      language: Language;
      provider: AuthProvider | null;
    };
  };
};

export type ListEvaluationMetricGroupsByCompanyIdQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  size: Scalars["Int"]["input"];
  cursor: InputMaybe<Scalars["Int"]["input"]>;
  withCount: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type ListEvaluationMetricGroupsByCompanyIdQuery = {
  __typename?: "Query";
  evaluationMetricGroupsByCompany: {
    __typename?: "EvaluationMetricGroupsByCompanyResponse";
    count: number;
    metricGroups: Array<{
      __typename?: "EvaluationMetricGroup";
      id: string;
      metricGroupId: number;
      companyId: number;
      title: string;
      description: string;
      createdBy: string;
      metrics: Array<{
        __typename?: "EvaluationMetric";
        id: string;
        metricId: number;
        companyId: number;
        createdBy: string;
        title: string;
        description: string;
        createdAtSeconds: number;
        employee: {
          __typename?: "User";
          id: string;
          uid: string;
          displayName: string;
          email: string;
          fullName: string;
          photoUrl: string;
          emailVerified: boolean;
          language: Language;
          provider: AuthProvider | null;
        };
      }>;
      employee: {
        __typename?: "User";
        id: string;
        uid: string;
        displayName: string;
        email: string;
        fullName: string;
        photoUrl: string;
        emailVerified: boolean;
        language: Language;
        provider: AuthProvider | null;
      };
    }>;
    pager: { __typename?: "EvaluationPager"; cursor: number; hasNext: boolean } | null;
  } | null;
};

export type UpdateEvaluationMetricGroupMutationVariables = Exact<{
  input: UpdateEvaluationMetricGroupInput;
}>;

export type UpdateEvaluationMetricGroupMutation = {
  __typename?: "Mutation";
  updateEvaluationMetricGroup: { __typename?: "UpdateEvaluationMetricGroupPayload"; metricGroupId: number; title: string; description: string };
};

export type DeleteEvaluationMetricGroupMutationVariables = Exact<{
  input: DeleteEvaluationMetricGroupInput;
}>;

export type DeleteEvaluationMetricGroupMutation = { __typename?: "Mutation"; deleteEvaluationMetricGroup: boolean };

export type AddEvaluationMetricToGroupsMutationVariables = Exact<{
  input: AddEvaluationMetricToGroupsInput;
}>;

export type AddEvaluationMetricToGroupsMutation = {
  __typename?: "Mutation";
  addEvaluationMetricToGroups: Array<{
    __typename?: "EvaluationMetricGroup";
    id: string;
    metricGroupId: number;
    companyId: number;
    title: string;
    description: string;
    createdBy: string;
    metrics: Array<{
      __typename?: "EvaluationMetric";
      id: string;
      metricId: number;
      companyId: number;
      createdBy: string;
      title: string;
      description: string;
      createdAtSeconds: number;
      employee: {
        __typename?: "User";
        id: string;
        uid: string;
        displayName: string;
        email: string;
        fullName: string;
        photoUrl: string;
        emailVerified: boolean;
        language: Language;
        provider: AuthProvider | null;
      };
    }>;
    employee: {
      __typename?: "User";
      id: string;
      uid: string;
      displayName: string;
      email: string;
      fullName: string;
      photoUrl: string;
      emailVerified: boolean;
      language: Language;
      provider: AuthProvider | null;
    };
  }>;
};

export type AddEvaluationMetricsToGroupMutationVariables = Exact<{
  input: AddEvaluationMetricsToGroupInput;
}>;

export type AddEvaluationMetricsToGroupMutation = {
  __typename?: "Mutation";
  addEvaluationMetricsToGroup: {
    __typename?: "EvaluationMetricGroup";
    id: string;
    metricGroupId: number;
    companyId: number;
    title: string;
    description: string;
    createdBy: string;
    metrics: Array<{
      __typename?: "EvaluationMetric";
      id: string;
      metricId: number;
      companyId: number;
      createdBy: string;
      title: string;
      description: string;
      createdAtSeconds: number;
      employee: {
        __typename?: "User";
        id: string;
        uid: string;
        displayName: string;
        email: string;
        fullName: string;
        photoUrl: string;
        emailVerified: boolean;
        language: Language;
        provider: AuthProvider | null;
      };
    }>;
    employee: {
      __typename?: "User";
      id: string;
      uid: string;
      displayName: string;
      email: string;
      fullName: string;
      photoUrl: string;
      emailVerified: boolean;
      language: Language;
      provider: AuthProvider | null;
    };
  } | null;
};

export type DeleteEvaluationMetricsFromGroupMutationVariables = Exact<{
  input: DeleteEvaluationMetricsFromGroupInput;
}>;

export type DeleteEvaluationMetricsFromGroupMutation = { __typename?: "Mutation"; deleteEvaluationMetricsFromGroup: boolean };

export type ExamForEmployeeInterviewsIdReviewFragment = {
  __typename?: "Exam";
  examId: string;
  entities: Array<
    | { __typename: "ChallengeEntity" }
    | {
        __typename: "ProjectEntity";
        projectEntityId: number;
        appealMessage: string | null;
        agentVersion: string;
        projectStatus: ProjectStatus;
        question: {
          __typename?: "ProjectQuestion";
          questionId: number;
          version: string;
          projectVersion: string;
          variant: ProjectQuestionVariant;
          titleJa: string | null;
          titleEn: string | null;
          correctnessTestCase: string;
          performanceTestCase: string;
          defaultTestCase: string;
          descriptionJa: string | null;
          descriptionEn: string | null;
          timeLimitSeconds: number | null;
          fileIndexes: Array<string>;
          readOnlyFiles: Array<string>;
        } | null;
        submissions: Array<{ __typename?: "ProjectSubmission"; projectSubmissionId: number }>;
      }
    | { __typename: "QuizEntity" }
    | { __typename: "SystemDesignEntity" }
  >;
};

export type GetExamForEmployeeExamIdReviewQueryVariables = Exact<{
  examId: Scalars["String"]["input"];
}>;

export type GetExamForEmployeeExamIdReviewQuery = {
  __typename?: "Query";
  exam: {
    __typename?: "Exam";
    examId: string;
    entities: Array<
      | { __typename: "ChallengeEntity" }
      | {
          __typename: "ProjectEntity";
          projectEntityId: number;
          appealMessage: string | null;
          agentVersion: string;
          projectStatus: ProjectStatus;
          question: {
            __typename?: "ProjectQuestion";
            questionId: number;
            version: string;
            projectVersion: string;
            variant: ProjectQuestionVariant;
            titleJa: string | null;
            titleEn: string | null;
            correctnessTestCase: string;
            performanceTestCase: string;
            defaultTestCase: string;
            descriptionJa: string | null;
            descriptionEn: string | null;
            timeLimitSeconds: number | null;
            fileIndexes: Array<string>;
            readOnlyFiles: Array<string>;
          } | null;
          submissions: Array<{ __typename?: "ProjectSubmission"; projectSubmissionId: number }>;
        }
      | { __typename: "QuizEntity" }
      | { __typename: "SystemDesignEntity" }
    >;
  };
};

export type ScreeningForDashboardScreeningSectionFragment = {
  __typename: "Screening";
  id: string;
  screeningId: string;
  name: string;
  isActive: boolean;
  acceptableLimit: number | null;
  numAccepted: number;
  acceptanceStatus: ScreeningAcceptanceStatus;
  candidateAccessPolicy: ScreeningCandidateAccessPolicy;
  invitationLanguage: Language;
  deadlineSeconds: number | null;
  spotStats: {
    __typename?: "ScreeningSpotStats";
    spotCountByStatus: { __typename?: "SpotCountByStatus"; finalized: number; evaluated: number } | null;
  } | null;
};

export type ListRecentScreeningsForDashboardScreeningSectionQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  size: Scalars["Int"]["input"];
}>;

export type ListRecentScreeningsForDashboardScreeningSectionQuery = {
  __typename?: "Query";
  recentScreenings: Array<{
    __typename: "Screening";
    id: string;
    screeningId: string;
    name: string;
    isActive: boolean;
    acceptableLimit: number | null;
    numAccepted: number;
    acceptanceStatus: ScreeningAcceptanceStatus;
    candidateAccessPolicy: ScreeningCandidateAccessPolicy;
    invitationLanguage: Language;
    deadlineSeconds: number | null;
    spotStats: {
      __typename?: "ScreeningSpotStats";
      spotCountByStatus: { __typename?: "SpotCountByStatus"; finalized: number; evaluated: number } | null;
    } | null;
  }>;
};

export type RemoteForDashboardRemoteInterviewSectionFragment = {
  __typename?: "Remote";
  id: string;
  remoteId: string;
  name: string;
  status: RemoteStatus;
  candidateName: string;
};

export type ListRemotesForDashboardRemoteInterviewSectionQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  size: Scalars["Int"]["input"];
}>;

export type ListRemotesForDashboardRemoteInterviewSectionQuery = {
  __typename?: "Query";
  remotesForSchedule: Array<{ __typename?: "Remote"; id: string; remoteId: string; name: string; status: RemoteStatus; candidateName: string }>;
};

export type SpotStatsForDashboardScreeningStatsSectionFragment = {
  __typename?: "SpotStats";
  averagePassedCustomScoreFormatted: number | null;
  spotCountByStatus: {
    __typename?: "SpotCountByStatus";
    created: number;
    started: number;
    completed: number;
    finalized: number;
    evaluated: number;
    expired: number;
  } | null;
  spotCountByResult: { __typename?: "SpotCountByResult"; passed: number; notPassed: number; notEvaluated: number } | null;
};

export type ScreeningsByCompanyIdResponseForDashboardScreeningStatsSectionFragment = {
  __typename?: "ScreeningsByCompanyIdResponse";
  count: number;
  hasNext: boolean;
  screenings: Array<{ __typename?: "Screening"; id: string; screeningId: string; name: string }>;
};

export type ListScreeningsByCompanyIdForDashboardScreeningStatsSectionQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  screeningsByCompanyIdInput: ScreeningsByCompanyIdInput;
}>;

export type ListScreeningsByCompanyIdForDashboardScreeningStatsSectionQuery = {
  __typename?: "Query";
  spotStatsByCompanyId: {
    __typename?: "SpotStats";
    averagePassedCustomScoreFormatted: number | null;
    spotCountByStatus: {
      __typename?: "SpotCountByStatus";
      created: number;
      started: number;
      completed: number;
      finalized: number;
      evaluated: number;
      expired: number;
    } | null;
    spotCountByResult: { __typename?: "SpotCountByResult"; passed: number; notPassed: number; notEvaluated: number } | null;
  };
  screeningsByCompanyId: {
    __typename?: "ScreeningsByCompanyIdResponse";
    count: number;
    hasNext: boolean;
    screenings: Array<{ __typename?: "Screening"; id: string; screeningId: string; name: string }>;
  } | null;
};

export type ScreeningForDashboardScreeningStatsSectionFragment = {
  __typename?: "Screening";
  id: string;
  screeningId: string;
  name: string;
  timeLimitSeconds: number;
  spotStatistics: {
    __typename?: "SpotStats";
    averagePassedCustomScoreFormatted: number | null;
    spotCountByStatus: {
      __typename?: "SpotCountByStatus";
      created: number;
      started: number;
      completed: number;
      finalized: number;
      evaluated: number;
      expired: number;
    } | null;
    spotCountByResult: { __typename?: "SpotCountByResult"; passed: number; notPassed: number; notEvaluated: number } | null;
  } | null;
};

export type GetScreeningForDashboardScreeningStatsSectionQueryVariables = Exact<{
  screeningId: Scalars["String"]["input"];
  withSpotStats: Scalars["Boolean"]["input"];
}>;

export type GetScreeningForDashboardScreeningStatsSectionQuery = {
  __typename?: "Query";
  screening: {
    __typename?: "Screening";
    id: string;
    screeningId: string;
    name: string;
    timeLimitSeconds: number;
    spotStatistics: {
      __typename?: "SpotStats";
      averagePassedCustomScoreFormatted: number | null;
      spotCountByStatus: {
        __typename?: "SpotCountByStatus";
        created: number;
        started: number;
        completed: number;
        finalized: number;
        evaluated: number;
        expired: number;
      } | null;
      spotCountByResult: { __typename?: "SpotCountByResult"; passed: number; notPassed: number; notEvaluated: number } | null;
    } | null;
  };
};

export type AcceptEmployeeInvitationMutationVariables = Exact<{
  invitationId: Scalars["String"]["input"];
  employeeId: Scalars["String"]["input"];
}>;

export type AcceptEmployeeInvitationMutation = {
  __typename?: "Mutation";
  acceptEmployeeInvitation: { __typename?: "Invitation"; companyId: number; role: Role } | null;
};

export type InvitationForEmployeeInvitationsIdFragment = { __typename?: "Invitation"; id: string; role: Role; expiresAtSeconds: number | null };

export type CompanyEmployeeForEmployeeInvitationsIdFragment = { __typename?: "CompanyEmployee"; companyId: number; employeeId: string };

export type CompanyForEmployeeInvitationsIdFragment = {
  __typename?: "Company";
  name: string;
  companyId: number;
  companyEmployees: Array<{ __typename?: "CompanyEmployee"; companyId: number; employeeId: string }>;
};

export type CompanyByEmployeeInvitationForEmployeeInvitationIdQueryVariables = Exact<{
  invitationId: Scalars["String"]["input"];
}>;

export type CompanyByEmployeeInvitationForEmployeeInvitationIdQuery = {
  __typename?: "Query";
  companyByEmployeeInvitation: {
    __typename?: "Company";
    name: string;
    companyId: number;
    companyEmployees: Array<{ __typename?: "CompanyEmployee"; companyId: number; employeeId: string }>;
  };
  employeeInvitation: { __typename?: "Invitation"; id: string; role: Role; expiresAtSeconds: number | null };
};

export type MultiChoiceQuestionForQuestionQuizIdQuestionFragment = {
  __typename?: "MultiChoiceQuestion";
  id: number;
  version: string;
  titleJa: string | null;
  titleEn: string | null;
  difficulty: Difficulty;
  descriptionJa: string | null;
  descriptionEn: string | null;
  metricType: MultiChoiceMetricType;
  companyId: number;
  isPrivate: boolean;
  isOfficial: boolean;
  status: QuestionStatus;
  variant: MultiChoiceQuestionVariant;
  correctAnswerRate: number;
  options: Array<{
    __typename?: "MultiChoiceOption";
    id: number;
    titleJa: string | null;
    titleEn: string | null;
    isCorrect: boolean;
    additionalDetailJa: string | null;
    additionalDetailEn: string | null;
  }>;
  evaluationItems: Array<{
    __typename?: "MultiChoiceEvaluationItem";
    id: number;
    questionId: number;
    questionVersion: string;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
  }>;
};

export type GetMultiChoicePackageForQuestionQuizIdPackageFragment = {
  __typename?: "MultiChoicePackage";
  id: string;
  packageId: number;
  version: string;
  companyId: number;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  difficulty: Difficulty;
  isPrivate: boolean;
  status: QuestionStatus;
  timeLimitSeconds: number | null;
  scoreDistributionBins: Array<number>;
  numUses: number;
  accuracyRate: number;
  averageElapsedTimeSeconds: number;
  isOfficial: boolean;
  questions: Array<{
    __typename?: "MultiChoiceQuestion";
    id: number;
    version: string;
    titleJa: string | null;
    titleEn: string | null;
    difficulty: Difficulty;
    descriptionJa: string | null;
    descriptionEn: string | null;
    metricType: MultiChoiceMetricType;
    companyId: number;
    isPrivate: boolean;
    isOfficial: boolean;
    status: QuestionStatus;
    variant: MultiChoiceQuestionVariant;
    correctAnswerRate: number;
    options: Array<{
      __typename?: "MultiChoiceOption";
      id: number;
      titleJa: string | null;
      titleEn: string | null;
      isCorrect: boolean;
      additionalDetailJa: string | null;
      additionalDetailEn: string | null;
    }>;
    evaluationItems: Array<{
      __typename?: "MultiChoiceEvaluationItem";
      id: number;
      questionId: number;
      questionVersion: string;
      titleJa: string | null;
      titleEn: string | null;
      descriptionJa: string | null;
      descriptionEn: string | null;
    }>;
  }>;
  employee: { __typename?: "User"; id: string; displayName: string };
};

export type GetMultiChoicePackageForQuestionQuizIdQueryVariables = Exact<{
  id: Scalars["Int"]["input"];
  version: Scalars["String"]["input"];
  withAnswers: Scalars["Boolean"]["input"];
}>;

export type GetMultiChoicePackageForQuestionQuizIdQuery = {
  __typename?: "Query";
  multiChoicePackage: {
    __typename?: "MultiChoicePackage";
    id: string;
    packageId: number;
    version: string;
    companyId: number;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    difficulty: Difficulty;
    isPrivate: boolean;
    status: QuestionStatus;
    timeLimitSeconds: number | null;
    scoreDistributionBins: Array<number>;
    numUses: number;
    accuracyRate: number;
    averageElapsedTimeSeconds: number;
    isOfficial: boolean;
    questions: Array<{
      __typename?: "MultiChoiceQuestion";
      id: number;
      version: string;
      titleJa: string | null;
      titleEn: string | null;
      difficulty: Difficulty;
      descriptionJa: string | null;
      descriptionEn: string | null;
      metricType: MultiChoiceMetricType;
      companyId: number;
      isPrivate: boolean;
      isOfficial: boolean;
      status: QuestionStatus;
      variant: MultiChoiceQuestionVariant;
      correctAnswerRate: number;
      options: Array<{
        __typename?: "MultiChoiceOption";
        id: number;
        titleJa: string | null;
        titleEn: string | null;
        isCorrect: boolean;
        additionalDetailJa: string | null;
        additionalDetailEn: string | null;
      }>;
      evaluationItems: Array<{
        __typename?: "MultiChoiceEvaluationItem";
        id: number;
        questionId: number;
        questionVersion: string;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
      }>;
    }>;
    employee: { __typename?: "User"; id: string; displayName: string };
  } | null;
};

export type ListAlgorithmQuestionFragment = {
  __typename: "AlgorithmQuestion";
  id: string;
  questionId: number;
  version: string;
  variant: AlgorithmQuestionVariant;
  companyId: number;
  employeeId: string;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  status: QuestionStatus;
  supportedLanguages: Array<string>;
  difficulty: Difficulty;
  isPrivate: boolean;
  isOfficial: boolean;
  isSupportedLeakScore: boolean;
  numUses: number;
  accuracyRate: number;
  averageElapsedTimeSeconds: number;
  timeLimitSeconds: number | null;
  createdAtSeconds: number | null;
  algorithmQuestionStatus: QuestionStatus;
  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
};

export type ListMultiChoiceQuestionFragment = {
  __typename: "MultiChoicePackage";
  id: string;
  packageId: number;
  version: string;
  companyId: number;
  employeeId: string;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  status: QuestionStatus;
  difficulty: Difficulty;
  isPrivate: boolean;
  isOfficial: boolean;
  isSupportedLeakScore: boolean;
  numUses: number;
  accuracyRate: number;
  averageElapsedTimeSeconds: number;
  timeLimitSeconds: number | null;
  createdAtSeconds: number | null;
  multiChoicePackageStatus: QuestionStatus;
  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
};

export type ListProjectQuestionFragment = {
  __typename: "ProjectQuestion";
  id: string;
  questionId: number;
  version: string;
  projectVersion: string;
  companyId: number;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  difficulty: Difficulty;
  isPrivate: boolean;
  isOfficial: boolean;
  isSupportedLeakScore: boolean;
  numUses: number;
  accuracyRate: number;
  averageElapsedTimeSeconds: number;
  skillTagIds: Array<string>;
  timeLimitSeconds: number | null;
  createdAtSeconds: number | null;
  projectQuestionStatus: ProjectQuestionStatus;
  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
  skillTags: Array<{ __typename?: "SkillTagNode"; id: string; skillTagNodeId: string; name: string; path: string }>;
};

export type ListSystemDesignQuestionFragment = {
  __typename: "SystemDesignQuestion";
  id: string;
  questionId: number;
  companyId: number;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  difficulty: Difficulty;
  isPrivate: boolean;
  isOfficial: boolean;
  isSupportedLeakScore: boolean;
  numUses: number;
  accuracyRate: number;
  averageElapsedTimeSeconds: number;
  timeLimitSeconds: number | null;
  createdAtSeconds: number | null;
  systemDesignQuestionStatus: SystemDesignQuestionStatus;
  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
};

export type ListFreepadQuestionFragment = {
  __typename: "FreepadQuestion";
  id: string;
  freepadQuestionId: number;
  version: string;
  companyId: number;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  status: QuestionStatus;
  difficulty: Difficulty;
  isPrivate: boolean;
  isOfficial: boolean;
  isSupportedLeakScore: boolean;
  numUses: number;
  averageElapsedTimeSeconds: number;
  createdAtSeconds: number | null;
  freepadVariant: FreepadQuestionVariant;
  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
};

export type QuestionForListQuestionFragment = {
  __typename?: "QuestionObject";
  id: string;
  questionObjectId: string;
  questionVariant: QuestionVariant;
  question:
    | {
        __typename: "AlgorithmQuestion";
        id: string;
        questionId: number;
        version: string;
        variant: AlgorithmQuestionVariant;
        companyId: number;
        employeeId: string;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        status: QuestionStatus;
        supportedLanguages: Array<string>;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        isSupportedLeakScore: boolean;
        numUses: number;
        accuracyRate: number;
        averageElapsedTimeSeconds: number;
        timeLimitSeconds: number | null;
        createdAtSeconds: number | null;
        algorithmQuestionStatus: QuestionStatus;
        leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
      }
    | {
        __typename: "FreepadQuestion";
        id: string;
        freepadQuestionId: number;
        version: string;
        companyId: number;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        status: QuestionStatus;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        isSupportedLeakScore: boolean;
        numUses: number;
        averageElapsedTimeSeconds: number;
        createdAtSeconds: number | null;
        freepadVariant: FreepadQuestionVariant;
        leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
      }
    | {
        __typename: "MultiChoicePackage";
        id: string;
        packageId: number;
        version: string;
        companyId: number;
        employeeId: string;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        status: QuestionStatus;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        isSupportedLeakScore: boolean;
        numUses: number;
        accuracyRate: number;
        averageElapsedTimeSeconds: number;
        timeLimitSeconds: number | null;
        createdAtSeconds: number | null;
        multiChoicePackageStatus: QuestionStatus;
        leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
      }
    | {
        __typename: "ProjectQuestion";
        id: string;
        questionId: number;
        version: string;
        projectVersion: string;
        companyId: number;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        isSupportedLeakScore: boolean;
        numUses: number;
        accuracyRate: number;
        averageElapsedTimeSeconds: number;
        skillTagIds: Array<string>;
        timeLimitSeconds: number | null;
        createdAtSeconds: number | null;
        projectQuestionStatus: ProjectQuestionStatus;
        leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        skillTags: Array<{ __typename?: "SkillTagNode"; id: string; skillTagNodeId: string; name: string; path: string }>;
      }
    | {
        __typename: "SystemDesignQuestion";
        id: string;
        questionId: number;
        companyId: number;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        isSupportedLeakScore: boolean;
        numUses: number;
        accuracyRate: number;
        averageElapsedTimeSeconds: number;
        timeLimitSeconds: number | null;
        createdAtSeconds: number | null;
        systemDesignQuestionStatus: SystemDesignQuestionStatus;
        leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
      };
};

export type ListQuestionsQueryVariables = Exact<{
  questionsInput: QuestionsInput;
}>;

export type ListQuestionsQuery = {
  __typename?: "Query";
  questions: {
    __typename?: "Questions";
    count: number;
    hasNext: boolean;
    questions: Array<{
      __typename?: "QuestionObject";
      id: string;
      questionObjectId: string;
      questionVariant: QuestionVariant;
      question:
        | {
            __typename: "AlgorithmQuestion";
            id: string;
            questionId: number;
            version: string;
            variant: AlgorithmQuestionVariant;
            companyId: number;
            employeeId: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            status: QuestionStatus;
            supportedLanguages: Array<string>;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            timeLimitSeconds: number | null;
            createdAtSeconds: number | null;
            algorithmQuestionStatus: QuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          }
        | {
            __typename: "FreepadQuestion";
            id: string;
            freepadQuestionId: number;
            version: string;
            companyId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            status: QuestionStatus;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            numUses: number;
            averageElapsedTimeSeconds: number;
            createdAtSeconds: number | null;
            freepadVariant: FreepadQuestionVariant;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          }
        | {
            __typename: "MultiChoicePackage";
            id: string;
            packageId: number;
            version: string;
            companyId: number;
            employeeId: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            status: QuestionStatus;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            timeLimitSeconds: number | null;
            createdAtSeconds: number | null;
            multiChoicePackageStatus: QuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          }
        | {
            __typename: "ProjectQuestion";
            id: string;
            questionId: number;
            version: string;
            projectVersion: string;
            companyId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            skillTagIds: Array<string>;
            timeLimitSeconds: number | null;
            createdAtSeconds: number | null;
            projectQuestionStatus: ProjectQuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
            skillTags: Array<{ __typename?: "SkillTagNode"; id: string; skillTagNodeId: string; name: string; path: string }>;
          }
        | {
            __typename: "SystemDesignQuestion";
            id: string;
            questionId: number;
            companyId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            timeLimitSeconds: number | null;
            createdAtSeconds: number | null;
            systemDesignQuestionStatus: SystemDesignQuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          };
    }>;
  };
};

export type QuestionsResponseForListQuestionsPageFragment = {
  __typename?: "Questions";
  count: number;
  hasNext: boolean;
  questionObjects: Array<{
    __typename?: "QuestionObject";
    id: string;
    questionObjectId: string;
    questionVariant: QuestionVariant;
    question:
      | {
          __typename: "AlgorithmQuestion";
          id: string;
          questionId: number;
          version: string;
          variant: AlgorithmQuestionVariant;
          companyId: number;
          employeeId: string;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          status: QuestionStatus;
          supportedLanguages: Array<string>;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          timeLimitSeconds: number | null;
          createdAtSeconds: number | null;
          algorithmQuestionStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | {
          __typename: "FreepadQuestion";
          id: string;
          freepadQuestionId: number;
          version: string;
          companyId: number;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          status: QuestionStatus;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          numUses: number;
          averageElapsedTimeSeconds: number;
          createdAtSeconds: number | null;
          freepadVariant: FreepadQuestionVariant;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | {
          __typename: "MultiChoicePackage";
          id: string;
          packageId: number;
          version: string;
          companyId: number;
          employeeId: string;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          status: QuestionStatus;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          timeLimitSeconds: number | null;
          createdAtSeconds: number | null;
          multiChoicePackageStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | {
          __typename: "ProjectQuestion";
          id: string;
          questionId: number;
          version: string;
          projectVersion: string;
          companyId: number;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          skillTagIds: Array<string>;
          timeLimitSeconds: number | null;
          createdAtSeconds: number | null;
          projectQuestionStatus: ProjectQuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          skillTags: Array<{ __typename?: "SkillTagNode"; id: string; skillTagNodeId: string; name: string; path: string }>;
        }
      | {
          __typename: "SystemDesignQuestion";
          id: string;
          questionId: number;
          companyId: number;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          timeLimitSeconds: number | null;
          createdAtSeconds: number | null;
          systemDesignQuestionStatus: SystemDesignQuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        };
  }>;
};

export type ListQuestionsForQuestionsPageQueryVariables = Exact<{
  questionsInput: QuestionsInput;
}>;

export type ListQuestionsForQuestionsPageQuery = {
  __typename?: "Query";
  questions: {
    __typename?: "Questions";
    count: number;
    hasNext: boolean;
    questionObjects: Array<{
      __typename?: "QuestionObject";
      id: string;
      questionObjectId: string;
      questionVariant: QuestionVariant;
      question:
        | {
            __typename: "AlgorithmQuestion";
            id: string;
            questionId: number;
            version: string;
            variant: AlgorithmQuestionVariant;
            companyId: number;
            employeeId: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            status: QuestionStatus;
            supportedLanguages: Array<string>;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            timeLimitSeconds: number | null;
            createdAtSeconds: number | null;
            algorithmQuestionStatus: QuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          }
        | {
            __typename: "FreepadQuestion";
            id: string;
            freepadQuestionId: number;
            version: string;
            companyId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            status: QuestionStatus;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            numUses: number;
            averageElapsedTimeSeconds: number;
            createdAtSeconds: number | null;
            freepadVariant: FreepadQuestionVariant;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          }
        | {
            __typename: "MultiChoicePackage";
            id: string;
            packageId: number;
            version: string;
            companyId: number;
            employeeId: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            status: QuestionStatus;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            timeLimitSeconds: number | null;
            createdAtSeconds: number | null;
            multiChoicePackageStatus: QuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          }
        | {
            __typename: "ProjectQuestion";
            id: string;
            questionId: number;
            version: string;
            projectVersion: string;
            companyId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            skillTagIds: Array<string>;
            timeLimitSeconds: number | null;
            createdAtSeconds: number | null;
            projectQuestionStatus: ProjectQuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
            skillTags: Array<{ __typename?: "SkillTagNode"; id: string; skillTagNodeId: string; name: string; path: string }>;
          }
        | {
            __typename: "SystemDesignQuestion";
            id: string;
            questionId: number;
            companyId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            timeLimitSeconds: number | null;
            createdAtSeconds: number | null;
            systemDesignQuestionStatus: SystemDesignQuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          };
    }>;
  };
};

export type DeleteAlgorithmQuestionMutationVariables = Exact<{
  input: DeleteAlgorithmQuestionInput;
}>;

export type DeleteAlgorithmQuestionMutation = { __typename?: "Mutation"; deleteAlgorithmQuestion: boolean };

export type DeleteMultiChoicePackageMutationVariables = Exact<{
  input: DeleteMultiChoicePackageInput;
}>;

export type DeleteMultiChoicePackageMutation = { __typename?: "Mutation"; deleteMultiChoicePackage: boolean };

export type DeleteProjectQuestionMutationVariables = Exact<{
  input: DeleteProjectQuestionInput;
}>;

export type DeleteProjectQuestionMutation = { __typename?: "Mutation"; deleteProjectQuestion: boolean };

export type DeleteFreepadQuestionMutationVariables = Exact<{
  input: DeleteFreepadQuestionInput;
}>;

export type DeleteFreepadQuestionMutation = { __typename?: "Mutation"; deleteFreepadQuestion: boolean };

export type DeleteSystemDesignQuestionMutationVariables = Exact<{
  input: DeleteSystemDesignQuestionInput;
}>;

export type DeleteSystemDesignQuestionMutation = { __typename?: "Mutation"; deleteSystemDesignQuestion: boolean };

export type SkillTagNodeForQuestionsFragment = { __typename?: "SkillTagNode"; id: string; skillTagNodeId: string; name: string; path: string };

export type HierarchicalSkillTagsResponseForQuestionsFragment = {
  __typename?: "HierarchicalSkillTagsResponse";
  count: number;
  hasNext: boolean;
  skillTagNodes: Array<{ __typename?: "SkillTagNode"; id: string; skillTagNodeId: string; name: string; path: string }>;
};

export type GetHierarchicalSkillTagsForQuestionsQueryVariables = Exact<{
  input: HierarchicalSkillTagsInput;
}>;

export type GetHierarchicalSkillTagsForQuestionsQuery = {
  __typename?: "Query";
  hierarchicalSkillTags: {
    __typename?: "HierarchicalSkillTagsResponse";
    count: number;
    hasNext: boolean;
    skillTagNodes: Array<{ __typename?: "SkillTagNode"; id: string; skillTagNodeId: string; name: string; path: string }>;
  };
};

export type AlgorithmHintForQuestionsChallengeIdFragment = {
  __typename?: "AlgorithmHint";
  id: number;
  questionId: number;
  questionVersion: string;
  descriptionJa: string | null;
  descriptionEn: string | null;
  orderNum: number;
};

export type AlgorithmAnswerRuntimeForQuestionsChallengeIdFragment = {
  __typename?: "AlgorithmAnswerRuntime";
  runtime: string;
  codeBody: string;
};

export type AlgorithmAnswerForQuestionsChallengeIdFragment = {
  __typename?: "AlgorithmAnswer";
  id: number;
  questionId: number;
  questionVersion: string;
  titleEn: string | null;
  titleJa: string | null;
  label: string;
  descriptionEn: string | null;
  descriptionJa: string | null;
  answerRuntimes: Array<{ __typename?: "AlgorithmAnswerRuntime"; runtime: string; codeBody: string }>;
};

export type AlgorithmInitialCodeForQuestionsChallengeIdFragment = {
  __typename?: "AlgorithmInitialCode";
  c: string;
  cpp: string;
  csharp: string;
  dart: string;
  go: string;
  java: string;
  javascript: string;
  kotlin: string;
  mysql: string;
  perl: string;
  pgsql: string;
  php: string;
  python3: string;
  ruby: string;
  rust: string;
  scala: string;
  sqlite: string;
  swift: string;
  typescript: string;
};

export type AlgorithmQuestionForQuestionsChallengeIdFragment = {
  __typename?: "AlgorithmQuestion";
  id: string;
  questionId: number;
  version: string;
  variant: AlgorithmQuestionVariant;
  companyId: number;
  employeeId: string;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  difficulty: Difficulty;
  isPrivate: boolean;
  status: QuestionStatus;
  supportedLanguages: Array<string>;
  signature: string;
  correctnessTestCase: string;
  isOfficial: boolean;
  scoreDistributionBins: Array<number>;
  isSupportedLeakScore: boolean;
  numUses: number;
  accuracyRate: number;
  averageElapsedTimeSeconds: number;
  timeLimitSeconds: number | null;
  initialCode: {
    __typename?: "AlgorithmInitialCode";
    c: string;
    cpp: string;
    csharp: string;
    dart: string;
    go: string;
    java: string;
    javascript: string;
    kotlin: string;
    mysql: string;
    perl: string;
    pgsql: string;
    php: string;
    python3: string;
    ruby: string;
    rust: string;
    scala: string;
    sqlite: string;
    swift: string;
    typescript: string;
  } | null;
  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
  answers: Array<{
    __typename?: "AlgorithmAnswer";
    id: number;
    questionId: number;
    questionVersion: string;
    titleEn: string | null;
    titleJa: string | null;
    label: string;
    descriptionEn: string | null;
    descriptionJa: string | null;
    answerRuntimes: Array<{ __typename?: "AlgorithmAnswerRuntime"; runtime: string; codeBody: string }>;
  }>;
  hints: Array<{
    __typename?: "AlgorithmHint";
    id: number;
    questionId: number;
    questionVersion: string;
    descriptionJa: string | null;
    descriptionEn: string | null;
    orderNum: number;
  }>;
  employee: { __typename?: "User"; id: string; displayName: string };
};

export type GetAlgorithmQuestionForQuestionsChallengeIdQueryVariables = Exact<{
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
  withAnswers: Scalars["Boolean"]["input"];
  withPerformanceTcs: Scalars["Boolean"]["input"];
}>;

export type GetAlgorithmQuestionForQuestionsChallengeIdQuery = {
  __typename?: "Query";
  algorithmQuestion: {
    __typename?: "AlgorithmQuestion";
    id: string;
    questionId: number;
    version: string;
    variant: AlgorithmQuestionVariant;
    companyId: number;
    employeeId: string;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    difficulty: Difficulty;
    isPrivate: boolean;
    status: QuestionStatus;
    supportedLanguages: Array<string>;
    signature: string;
    correctnessTestCase: string;
    isOfficial: boolean;
    scoreDistributionBins: Array<number>;
    isSupportedLeakScore: boolean;
    numUses: number;
    accuracyRate: number;
    averageElapsedTimeSeconds: number;
    timeLimitSeconds: number | null;
    initialCode: {
      __typename?: "AlgorithmInitialCode";
      c: string;
      cpp: string;
      csharp: string;
      dart: string;
      go: string;
      java: string;
      javascript: string;
      kotlin: string;
      mysql: string;
      perl: string;
      pgsql: string;
      php: string;
      python3: string;
      ruby: string;
      rust: string;
      scala: string;
      sqlite: string;
      swift: string;
      typescript: string;
    } | null;
    leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
    answers: Array<{
      __typename?: "AlgorithmAnswer";
      id: number;
      questionId: number;
      questionVersion: string;
      titleEn: string | null;
      titleJa: string | null;
      label: string;
      descriptionEn: string | null;
      descriptionJa: string | null;
      answerRuntimes: Array<{ __typename?: "AlgorithmAnswerRuntime"; runtime: string; codeBody: string }>;
    }>;
    hints: Array<{
      __typename?: "AlgorithmHint";
      id: number;
      questionId: number;
      questionVersion: string;
      descriptionJa: string | null;
      descriptionEn: string | null;
      orderNum: number;
    }>;
    employee: { __typename?: "User"; id: string; displayName: string };
  };
};

export type FreepadQuestionDetailFragment = {
  __typename?: "FreepadQuestion";
  id: string;
  freepadQuestionId: number;
  version: string;
  variant: FreepadQuestionVariant;
  isPrivate: boolean;
  status: QuestionStatus;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  difficulty: Difficulty;
  numUses: number;
  averageElapsedTimeSeconds: number;
  isOfficial: boolean;
  answers: Array<{
    __typename?: "FreepadAnswer";
    id: string;
    freepadAnswerId: number;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    answerCodes: Array<{ __typename?: "FreepadAnswerCode"; id: string; freepadAnswerCodeId: number; body: string; runtime: string }>;
    answerFlowCharts: Array<{
      __typename?: "FreepadAnswerFlowChart";
      id: string;
      freepadAnswerFlowChartId: number;
      body: string;
      componentType: SystemDesignComponentType;
    }>;
  }>;
  employee: { __typename?: "User"; id: string; displayName: string };
};

export type FreepadQuestionForQuestionDetailQueryVariables = Exact<{
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
}>;

export type FreepadQuestionForQuestionDetailQuery = {
  __typename?: "Query";
  freepadQuestion: {
    __typename?: "FreepadQuestion";
    id: string;
    freepadQuestionId: number;
    version: string;
    variant: FreepadQuestionVariant;
    isPrivate: boolean;
    status: QuestionStatus;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    difficulty: Difficulty;
    numUses: number;
    averageElapsedTimeSeconds: number;
    isOfficial: boolean;
    answers: Array<{
      __typename?: "FreepadAnswer";
      id: string;
      freepadAnswerId: number;
      titleJa: string | null;
      titleEn: string | null;
      descriptionJa: string | null;
      descriptionEn: string | null;
      answerCodes: Array<{ __typename?: "FreepadAnswerCode"; id: string; freepadAnswerCodeId: number; body: string; runtime: string }>;
      answerFlowCharts: Array<{
        __typename?: "FreepadAnswerFlowChart";
        id: string;
        freepadAnswerFlowChartId: number;
        body: string;
        componentType: SystemDesignComponentType;
      }>;
    }>;
    employee: { __typename?: "User"; id: string; displayName: string };
  };
};

export type QuestionPackageForQuestionsPackagesFragment = {
  __typename: "QuestionPackage";
  id: string;
  questionPackageId: string;
  difficulty: QuestionPackageDifficulty;
  titleJa: string;
  titleEn: string;
  descriptionJa: string;
  descriptionEn: string;
  questionCount: number;
};

export type QuestionPackagesResponseForQuestionsPackagesFragment = {
  __typename?: "QuestionPackagesResponse";
  count: number;
  packages: Array<{
    __typename: "QuestionPackage";
    id: string;
    questionPackageId: string;
    difficulty: QuestionPackageDifficulty;
    titleJa: string;
    titleEn: string;
    descriptionJa: string;
    descriptionEn: string;
    questionCount: number;
  }>;
};

export type ListQuestionsPackagesForQuestionsPackagesQueryVariables = Exact<{
  size: Scalars["Int"]["input"];
  offset: Scalars["Int"]["input"];
  isDescending: Scalars["Boolean"]["input"];
  sortMethod: QuestionPackageSortMethod;
  filters: QuestionPackageFilters;
}>;

export type ListQuestionsPackagesForQuestionsPackagesQuery = {
  __typename?: "Query";
  questionPackages: {
    __typename?: "QuestionPackagesResponse";
    count: number;
    packages: Array<{
      __typename: "QuestionPackage";
      id: string;
      questionPackageId: string;
      difficulty: QuestionPackageDifficulty;
      titleJa: string;
      titleEn: string;
      descriptionJa: string;
      descriptionEn: string;
      questionCount: number;
    }>;
  };
};

export type QuestionObjectForQuestionsPackagesDetailFragment = {
  __typename?: "QuestionObject";
  id: string;
  questionObjectId: string;
  questionVariant: QuestionVariant;
  version: string | null;
  question:
    | {
        __typename: "AlgorithmQuestion";
        id: string;
        questionId: number;
        version: string;
        variant: AlgorithmQuestionVariant;
        companyId: number;
        employeeId: string;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        status: QuestionStatus;
        supportedLanguages: Array<string>;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        isSupportedLeakScore: boolean;
        numUses: number;
        accuracyRate: number;
        averageElapsedTimeSeconds: number;
        timeLimitSeconds: number | null;
        createdAtSeconds: number | null;
        algorithmQuestionStatus: QuestionStatus;
        leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
      }
    | {
        __typename: "FreepadQuestion";
        id: string;
        freepadQuestionId: number;
        version: string;
        companyId: number;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        status: QuestionStatus;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        isSupportedLeakScore: boolean;
        numUses: number;
        averageElapsedTimeSeconds: number;
        createdAtSeconds: number | null;
        freepadVariant: FreepadQuestionVariant;
        leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
      }
    | {
        __typename: "MultiChoicePackage";
        id: string;
        packageId: number;
        version: string;
        companyId: number;
        employeeId: string;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        status: QuestionStatus;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        isSupportedLeakScore: boolean;
        numUses: number;
        accuracyRate: number;
        averageElapsedTimeSeconds: number;
        timeLimitSeconds: number | null;
        createdAtSeconds: number | null;
        multiChoicePackageStatus: QuestionStatus;
        leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
      }
    | {
        __typename: "ProjectQuestion";
        id: string;
        questionId: number;
        version: string;
        projectVersion: string;
        companyId: number;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        isSupportedLeakScore: boolean;
        numUses: number;
        accuracyRate: number;
        averageElapsedTimeSeconds: number;
        skillTagIds: Array<string>;
        timeLimitSeconds: number | null;
        createdAtSeconds: number | null;
        projectQuestionStatus: ProjectQuestionStatus;
        leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        skillTags: Array<{ __typename?: "SkillTagNode"; id: string; skillTagNodeId: string; name: string; path: string }>;
      }
    | {
        __typename: "SystemDesignQuestion";
        id: string;
        questionId: number;
        companyId: number;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        isSupportedLeakScore: boolean;
        numUses: number;
        accuracyRate: number;
        averageElapsedTimeSeconds: number;
        timeLimitSeconds: number | null;
        createdAtSeconds: number | null;
        systemDesignQuestionStatus: SystemDesignQuestionStatus;
        leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
      };
};

export type QuestionPackageForQuestionsPackagesDetailFragment = {
  __typename: "QuestionPackage";
  id: string;
  questionPackageId: string;
  difficulty: QuestionPackageDifficulty;
  titleJa: string;
  titleEn: string;
  descriptionJa: string;
  descriptionEn: string;
  questionCount: number;
  questionObjects: Array<{
    __typename?: "QuestionObject";
    id: string;
    questionObjectId: string;
    questionVariant: QuestionVariant;
    version: string | null;
    question:
      | {
          __typename: "AlgorithmQuestion";
          id: string;
          questionId: number;
          version: string;
          variant: AlgorithmQuestionVariant;
          companyId: number;
          employeeId: string;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          status: QuestionStatus;
          supportedLanguages: Array<string>;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          timeLimitSeconds: number | null;
          createdAtSeconds: number | null;
          algorithmQuestionStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | {
          __typename: "FreepadQuestion";
          id: string;
          freepadQuestionId: number;
          version: string;
          companyId: number;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          status: QuestionStatus;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          numUses: number;
          averageElapsedTimeSeconds: number;
          createdAtSeconds: number | null;
          freepadVariant: FreepadQuestionVariant;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | {
          __typename: "MultiChoicePackage";
          id: string;
          packageId: number;
          version: string;
          companyId: number;
          employeeId: string;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          status: QuestionStatus;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          timeLimitSeconds: number | null;
          createdAtSeconds: number | null;
          multiChoicePackageStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | {
          __typename: "ProjectQuestion";
          id: string;
          questionId: number;
          version: string;
          projectVersion: string;
          companyId: number;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          skillTagIds: Array<string>;
          timeLimitSeconds: number | null;
          createdAtSeconds: number | null;
          projectQuestionStatus: ProjectQuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          skillTags: Array<{ __typename?: "SkillTagNode"; id: string; skillTagNodeId: string; name: string; path: string }>;
        }
      | {
          __typename: "SystemDesignQuestion";
          id: string;
          questionId: number;
          companyId: number;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          timeLimitSeconds: number | null;
          createdAtSeconds: number | null;
          systemDesignQuestionStatus: SystemDesignQuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        };
  }>;
};

export type GetQuestionPackageForQuestionsPackagesDetailQueryVariables = Exact<{
  questionPackageId: Scalars["String"]["input"];
}>;

export type GetQuestionPackageForQuestionsPackagesDetailQuery = {
  __typename?: "Query";
  questionPackage: {
    __typename: "QuestionPackage";
    id: string;
    questionPackageId: string;
    difficulty: QuestionPackageDifficulty;
    titleJa: string;
    titleEn: string;
    descriptionJa: string;
    descriptionEn: string;
    questionCount: number;
    questionObjects: Array<{
      __typename?: "QuestionObject";
      id: string;
      questionObjectId: string;
      questionVariant: QuestionVariant;
      version: string | null;
      question:
        | {
            __typename: "AlgorithmQuestion";
            id: string;
            questionId: number;
            version: string;
            variant: AlgorithmQuestionVariant;
            companyId: number;
            employeeId: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            status: QuestionStatus;
            supportedLanguages: Array<string>;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            timeLimitSeconds: number | null;
            createdAtSeconds: number | null;
            algorithmQuestionStatus: QuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          }
        | {
            __typename: "FreepadQuestion";
            id: string;
            freepadQuestionId: number;
            version: string;
            companyId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            status: QuestionStatus;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            numUses: number;
            averageElapsedTimeSeconds: number;
            createdAtSeconds: number | null;
            freepadVariant: FreepadQuestionVariant;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          }
        | {
            __typename: "MultiChoicePackage";
            id: string;
            packageId: number;
            version: string;
            companyId: number;
            employeeId: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            status: QuestionStatus;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            timeLimitSeconds: number | null;
            createdAtSeconds: number | null;
            multiChoicePackageStatus: QuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          }
        | {
            __typename: "ProjectQuestion";
            id: string;
            questionId: number;
            version: string;
            projectVersion: string;
            companyId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            skillTagIds: Array<string>;
            timeLimitSeconds: number | null;
            createdAtSeconds: number | null;
            projectQuestionStatus: ProjectQuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
            skillTags: Array<{ __typename?: "SkillTagNode"; id: string; skillTagNodeId: string; name: string; path: string }>;
          }
        | {
            __typename: "SystemDesignQuestion";
            id: string;
            questionId: number;
            companyId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            timeLimitSeconds: number | null;
            createdAtSeconds: number | null;
            systemDesignQuestionStatus: SystemDesignQuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          };
    }>;
  };
};

export type SkillTagNodeForQuestionDetailFragment = {
  __typename?: "SkillTagNode";
  id: string;
  skillTagNodeId: string;
  name: string;
  path: string;
};

export type ProjectQuestionDetailFragment = {
  __typename?: "ProjectQuestion";
  id: string;
  questionId: number;
  status: ProjectQuestionStatus;
  version: string;
  projectVersion: string;
  variant: ProjectQuestionVariant;
  companyId: number;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  difficulty: Difficulty;
  timeLimitSeconds: number | null;
  isPrivate: boolean;
  numUses: number;
  accuracyRate: number;
  scoreDistributionBins: Array<number>;
  averageElapsedTimeSeconds: number;
  isOfficial: boolean;
  skillTags: Array<{ __typename?: "SkillTagNode"; id: string; skillTagNodeId: string; name: string; path: string }>;
  answers: Array<{
    __typename?: "ProjectAnswer";
    answerId: number;
    questionId: number;
    questionVersion: string;
    titleJa: string;
    titleEn: string;
    descriptionJa: string;
    descriptionEn: string;
    fileIndexList: Array<string>;
  }>;
};

export type ProjectQuestionDetailQueryVariables = Exact<{
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
  withAnswers: Scalars["Boolean"]["input"];
}>;

export type ProjectQuestionDetailQuery = {
  __typename?: "Query";
  projectQuestion: {
    __typename?: "ProjectQuestion";
    id: string;
    questionId: number;
    status: ProjectQuestionStatus;
    version: string;
    projectVersion: string;
    variant: ProjectQuestionVariant;
    companyId: number;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    difficulty: Difficulty;
    timeLimitSeconds: number | null;
    isPrivate: boolean;
    numUses: number;
    accuracyRate: number;
    scoreDistributionBins: Array<number>;
    averageElapsedTimeSeconds: number;
    isOfficial: boolean;
    skillTags: Array<{ __typename?: "SkillTagNode"; id: string; skillTagNodeId: string; name: string; path: string }>;
    answers: Array<{
      __typename?: "ProjectAnswer";
      answerId: number;
      questionId: number;
      questionVersion: string;
      titleJa: string;
      titleEn: string;
      descriptionJa: string;
      descriptionEn: string;
      fileIndexList: Array<string>;
    }>;
  } | null;
};

export type ProjectAnswerFileBodyQueryVariables = Exact<{
  answerId: Scalars["Int"]["input"];
  filePath: Scalars["String"]["input"];
}>;

export type ProjectAnswerFileBodyQuery = { __typename?: "Query"; answerFileBody: { __typename?: "ProjectAnswerFileBody"; fileBody: string } };

export type SystemDesignQuestionDetailFragment = {
  __typename?: "SystemDesignQuestion";
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  difficulty: Difficulty;
  status: SystemDesignQuestionStatus;
  scoreDistributionBins: Array<number>;
  numUses: number;
  accuracyRate: number;
  averageElapsedTimeSeconds: number;
  timeLimitSeconds: number | null;
  isOfficial: boolean;
  hints: Array<{ __typename?: "SystemDesignHint"; descriptionJa: string | null; descriptionEn: string | null }>;
  answers: Array<{
    __typename?: "SystemDesignAnswer";
    titleJa: string;
    titleEn: string;
    descriptionJa: string;
    descriptionEn: string;
    snapshot: string;
  }>;
  scoringItems: Array<{
    __typename?: "SystemDesignScoringItem";
    titleJa: string;
    titleEn: string;
    descriptionJa: string;
    descriptionEn: string;
    graphlets: string;
    category: SystemDesignScoringItemCategory;
  }>;
};

export type SystemDesignQuestionForQuestionDetailQueryVariables = Exact<{
  questionId: Scalars["Int"]["input"];
  withAnswers: Scalars["Boolean"]["input"];
}>;

export type SystemDesignQuestionForQuestionDetailQuery = {
  __typename?: "Query";
  systemDesignQuestion: {
    __typename?: "SystemDesignQuestion";
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    difficulty: Difficulty;
    status: SystemDesignQuestionStatus;
    scoreDistributionBins: Array<number>;
    numUses: number;
    accuracyRate: number;
    averageElapsedTimeSeconds: number;
    timeLimitSeconds: number | null;
    isOfficial: boolean;
    hints: Array<{ __typename?: "SystemDesignHint"; descriptionJa: string | null; descriptionEn: string | null }>;
    answers: Array<{
      __typename?: "SystemDesignAnswer";
      titleJa: string;
      titleEn: string;
      descriptionJa: string;
      descriptionEn: string;
      snapshot: string;
    }>;
    scoringItems: Array<{
      __typename?: "SystemDesignScoringItem";
      titleJa: string;
      titleEn: string;
      descriptionJa: string;
      descriptionEn: string;
      graphlets: string;
      category: SystemDesignScoringItemCategory;
    }>;
  };
};

export type UserForRemotesFragment = { __typename?: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string };

export type RemotesForRemotesInterviewListFragment = {
  __typename: "Remote";
  id: string;
  name: string;
  remoteId: string;
  candidateName: string;
  status: RemoteStatus;
  isPassed: boolean;
  isPublic: boolean;
  creator: { __typename?: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string } | null;
  tags: Array<{ __typename?: "RemoteTag"; name: string }>;
};

export type ListRemotesByCompanyIdForRemotesInterviewListQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  withCount: Scalars["Boolean"]["input"];
  withDeleted: Scalars["Boolean"]["input"];
  filters: RemoteFilterInput;
  sortMethod: RemoteSortMethod;
  size: Scalars["Int"]["input"];
  offset: Scalars["Int"]["input"];
  isDescending: Scalars["Boolean"]["input"];
}>;

export type ListRemotesByCompanyIdForRemotesInterviewListQuery = {
  __typename?: "Query";
  remotesByCompanyId: {
    __typename?: "RemotesByCompanyIdResponse";
    count: number;
    hasNext: boolean;
    remotes: Array<{
      __typename: "Remote";
      id: string;
      name: string;
      remoteId: string;
      candidateName: string;
      status: RemoteStatus;
      isPassed: boolean;
      isPublic: boolean;
      creator: { __typename?: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string } | null;
      tags: Array<{ __typename?: "RemoteTag"; name: string }>;
    }>;
  };
};

export type GetSearchItemsForRemotesQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  cursorSeconds: InputMaybe<Scalars["Int"]["input"]>;
  size: Scalars["Int"]["input"];
  withCount: Scalars["Boolean"]["input"];
  creator: Scalars["Boolean"]["input"];
}>;

export type GetSearchItemsForRemotesQuery = {
  __typename?: "Query";
  remoteTagsByCompanyId: {
    __typename?: "RemoteTagsByCompanyIdResponse";
    count: number;
    tags: Array<{ __typename?: "RemoteTag"; name: string }>;
    pager: { __typename?: "RemotePager"; cursorSeconds: number | null; hasNext: boolean } | null;
  };
  issuersForRemoteByFields: {
    __typename?: "IssuersForRemoteByFieldsResponse";
    creators: Array<{ __typename?: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string }>;
  };
};

export type StartRemoteForInterviewListMutationVariables = Exact<{
  input: StartRemoteInput;
}>;

export type StartRemoteForInterviewListMutation = {
  __typename?: "Mutation";
  startRemote: { __typename?: "Remote"; id: string; remoteId: string; status: RemoteStatus };
};

export type CreateRemoteForRemoteInterviewsCreateMutationVariables = Exact<{
  createRemoteInput: CreateRemoteInput;
}>;

export type CreateRemoteForRemoteInterviewsCreateMutation = {
  __typename?: "Mutation";
  createRemote: { __typename?: "Remote"; id: string; name: string };
};

export type FreepadQuestionForRemotesIdFragment = {
  __typename?: "FreepadQuestion";
  freepadQuestionId: number;
  version: string;
  variant: FreepadQuestionVariant;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  difficulty: Difficulty;
  answers: Array<{
    __typename?: "FreepadAnswer";
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    answerCodes: Array<{ __typename?: "FreepadAnswerCode"; body: string }>;
    answerFlowCharts: Array<{ __typename?: "FreepadAnswerFlowChart"; componentType: SystemDesignComponentType; body: string }>;
  }>;
  initialCodes: Array<{ __typename?: "FreepadInitialCode"; questionId: number; questionVersion: string; runtime: string; body: string }>;
  initialFlowCharts: Array<{
    __typename?: "FreepadInitialFlowChart";
    questionId: number;
    questionVersion: string;
    componentType: SystemDesignComponentType;
    body: string;
  }>;
};

export type AlgorithmQuestionForRemotesIdFragment = {
  __typename?: "AlgorithmQuestion";
  questionId: number;
  version: string;
  variant: AlgorithmQuestionVariant;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  difficulty: Difficulty;
  supportedLanguages: Array<string>;
  answers: Array<{
    __typename?: "AlgorithmAnswer";
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    label: string;
  }>;
};

export type SystemDesignQuestionForRemotesIdFragment = {
  __typename?: "SystemDesignQuestion";
  questionId: number;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  difficulty: Difficulty;
  initialSnapshot: string;
  answers: Array<{ __typename?: "SystemDesignAnswer"; titleJa: string; titleEn: string; descriptionJa: string; descriptionEn: string }>;
};

export type LiveCodingSessionForRemotesIdFragment = {
  __typename?: "LiveCodingSession";
  questionType: LiveCodingQuestionType;
  liveCodingId: number;
  liveCodingSessionId: number;
  elapsedTimeSeconds: number | null;
  freepadQuestion: {
    __typename?: "FreepadQuestion";
    freepadQuestionId: number;
    version: string;
    variant: FreepadQuestionVariant;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    difficulty: Difficulty;
    answers: Array<{
      __typename?: "FreepadAnswer";
      titleJa: string | null;
      titleEn: string | null;
      descriptionJa: string | null;
      descriptionEn: string | null;
      answerCodes: Array<{ __typename?: "FreepadAnswerCode"; body: string }>;
      answerFlowCharts: Array<{ __typename?: "FreepadAnswerFlowChart"; componentType: SystemDesignComponentType; body: string }>;
    }>;
    initialCodes: Array<{ __typename?: "FreepadInitialCode"; questionId: number; questionVersion: string; runtime: string; body: string }>;
    initialFlowCharts: Array<{
      __typename?: "FreepadInitialFlowChart";
      questionId: number;
      questionVersion: string;
      componentType: SystemDesignComponentType;
      body: string;
    }>;
  } | null;
  algorithmQuestion: {
    __typename?: "AlgorithmQuestion";
    questionId: number;
    version: string;
    variant: AlgorithmQuestionVariant;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    difficulty: Difficulty;
    supportedLanguages: Array<string>;
    answers: Array<{
      __typename?: "AlgorithmAnswer";
      titleJa: string | null;
      titleEn: string | null;
      descriptionJa: string | null;
      descriptionEn: string | null;
      label: string;
    }>;
  } | null;
  systemDesignQuestion: {
    __typename?: "SystemDesignQuestion";
    questionId: number;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    difficulty: Difficulty;
    initialSnapshot: string;
    answers: Array<{ __typename?: "SystemDesignAnswer"; titleJa: string; titleEn: string; descriptionJa: string; descriptionEn: string }>;
  } | null;
};

export type LiveCodingVideoChatRoomForEmployeeRemotesIdFragment = {
  __typename?: "LiveCodingVideoChatRoom";
  id: string;
  roomId: string;
  liveCodingId: number;
  createdAtSeconds: number | null;
};

export type LiveCodingVideoChatConversationForEmployeeRemotesIdFragment = {
  __typename?: "LiveCodingVideoChatConversation";
  id: string;
  conversationId: string;
  liveCodingId: number;
  createdAtSeconds: number | null;
};

export type LiveCodingParticipantForRemotesIdFragment = {
  __typename?: "LiveCodingParticipant";
  liveCodingId: number;
  userType: LiveCodingUserType;
  userId: string;
  userName: string;
};

export type LiveCodingForRemotesIdFragment = {
  __typename?: "LiveCoding";
  status: LiveCodingStatus | null;
  liveCodingId: number;
  sessions: Array<{
    __typename?: "LiveCodingSession";
    questionType: LiveCodingQuestionType;
    liveCodingId: number;
    liveCodingSessionId: number;
    elapsedTimeSeconds: number | null;
    freepadQuestion: {
      __typename?: "FreepadQuestion";
      freepadQuestionId: number;
      version: string;
      variant: FreepadQuestionVariant;
      titleJa: string | null;
      titleEn: string | null;
      descriptionJa: string | null;
      descriptionEn: string | null;
      difficulty: Difficulty;
      answers: Array<{
        __typename?: "FreepadAnswer";
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        answerCodes: Array<{ __typename?: "FreepadAnswerCode"; body: string }>;
        answerFlowCharts: Array<{ __typename?: "FreepadAnswerFlowChart"; componentType: SystemDesignComponentType; body: string }>;
      }>;
      initialCodes: Array<{ __typename?: "FreepadInitialCode"; questionId: number; questionVersion: string; runtime: string; body: string }>;
      initialFlowCharts: Array<{
        __typename?: "FreepadInitialFlowChart";
        questionId: number;
        questionVersion: string;
        componentType: SystemDesignComponentType;
        body: string;
      }>;
    } | null;
    algorithmQuestion: {
      __typename?: "AlgorithmQuestion";
      questionId: number;
      version: string;
      variant: AlgorithmQuestionVariant;
      titleJa: string | null;
      titleEn: string | null;
      descriptionJa: string | null;
      descriptionEn: string | null;
      difficulty: Difficulty;
      supportedLanguages: Array<string>;
      answers: Array<{
        __typename?: "AlgorithmAnswer";
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        label: string;
      }>;
    } | null;
    systemDesignQuestion: {
      __typename?: "SystemDesignQuestion";
      questionId: number;
      titleJa: string | null;
      titleEn: string | null;
      descriptionJa: string | null;
      descriptionEn: string | null;
      difficulty: Difficulty;
      initialSnapshot: string;
      answers: Array<{ __typename?: "SystemDesignAnswer"; titleJa: string; titleEn: string; descriptionJa: string; descriptionEn: string }>;
    } | null;
  } | null>;
  participants: Array<{
    __typename?: "LiveCodingParticipant";
    liveCodingId: number;
    userType: LiveCodingUserType;
    userId: string;
    userName: string;
  }>;
  videoChatRooms: Array<{
    __typename?: "LiveCodingVideoChatRoom";
    id: string;
    roomId: string;
    liveCodingId: number;
    createdAtSeconds: number | null;
  }>;
  videoChatConversation: {
    __typename?: "LiveCodingVideoChatConversation";
    id: string;
    conversationId: string;
    liveCodingId: number;
    createdAtSeconds: number | null;
  } | null;
};

export type RemoteEntityForRemotesIdFragment = {
  __typename?: "RemoteEntity";
  entityType: RemoteEntityType;
  liveCoding: {
    __typename?: "LiveCoding";
    status: LiveCodingStatus | null;
    liveCodingId: number;
    sessions: Array<{
      __typename?: "LiveCodingSession";
      questionType: LiveCodingQuestionType;
      liveCodingId: number;
      liveCodingSessionId: number;
      elapsedTimeSeconds: number | null;
      freepadQuestion: {
        __typename?: "FreepadQuestion";
        freepadQuestionId: number;
        version: string;
        variant: FreepadQuestionVariant;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        difficulty: Difficulty;
        answers: Array<{
          __typename?: "FreepadAnswer";
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          answerCodes: Array<{ __typename?: "FreepadAnswerCode"; body: string }>;
          answerFlowCharts: Array<{ __typename?: "FreepadAnswerFlowChart"; componentType: SystemDesignComponentType; body: string }>;
        }>;
        initialCodes: Array<{ __typename?: "FreepadInitialCode"; questionId: number; questionVersion: string; runtime: string; body: string }>;
        initialFlowCharts: Array<{
          __typename?: "FreepadInitialFlowChart";
          questionId: number;
          questionVersion: string;
          componentType: SystemDesignComponentType;
          body: string;
        }>;
      } | null;
      algorithmQuestion: {
        __typename?: "AlgorithmQuestion";
        questionId: number;
        version: string;
        variant: AlgorithmQuestionVariant;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        difficulty: Difficulty;
        supportedLanguages: Array<string>;
        answers: Array<{
          __typename?: "AlgorithmAnswer";
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          label: string;
        }>;
      } | null;
      systemDesignQuestion: {
        __typename?: "SystemDesignQuestion";
        questionId: number;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        difficulty: Difficulty;
        initialSnapshot: string;
        answers: Array<{ __typename?: "SystemDesignAnswer"; titleJa: string; titleEn: string; descriptionJa: string; descriptionEn: string }>;
      } | null;
    } | null>;
    participants: Array<{
      __typename?: "LiveCodingParticipant";
      liveCodingId: number;
      userType: LiveCodingUserType;
      userId: string;
      userName: string;
    }>;
    videoChatRooms: Array<{
      __typename?: "LiveCodingVideoChatRoom";
      id: string;
      roomId: string;
      liveCodingId: number;
      createdAtSeconds: number | null;
    }>;
    videoChatConversation: {
      __typename?: "LiveCodingVideoChatConversation";
      id: string;
      conversationId: string;
      liveCodingId: number;
      createdAtSeconds: number | null;
    } | null;
  } | null;
};

export type RemoteForRemotesIdFragment = {
  __typename?: "Remote";
  status: RemoteStatus;
  remoteId: string;
  name: string;
  candidateName: string;
  didStartAtSeconds: number | null;
  entity: {
    __typename?: "RemoteEntity";
    entityType: RemoteEntityType;
    liveCoding: {
      __typename?: "LiveCoding";
      status: LiveCodingStatus | null;
      liveCodingId: number;
      sessions: Array<{
        __typename?: "LiveCodingSession";
        questionType: LiveCodingQuestionType;
        liveCodingId: number;
        liveCodingSessionId: number;
        elapsedTimeSeconds: number | null;
        freepadQuestion: {
          __typename?: "FreepadQuestion";
          freepadQuestionId: number;
          version: string;
          variant: FreepadQuestionVariant;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          difficulty: Difficulty;
          answers: Array<{
            __typename?: "FreepadAnswer";
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            answerCodes: Array<{ __typename?: "FreepadAnswerCode"; body: string }>;
            answerFlowCharts: Array<{ __typename?: "FreepadAnswerFlowChart"; componentType: SystemDesignComponentType; body: string }>;
          }>;
          initialCodes: Array<{
            __typename?: "FreepadInitialCode";
            questionId: number;
            questionVersion: string;
            runtime: string;
            body: string;
          }>;
          initialFlowCharts: Array<{
            __typename?: "FreepadInitialFlowChart";
            questionId: number;
            questionVersion: string;
            componentType: SystemDesignComponentType;
            body: string;
          }>;
        } | null;
        algorithmQuestion: {
          __typename?: "AlgorithmQuestion";
          questionId: number;
          version: string;
          variant: AlgorithmQuestionVariant;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          difficulty: Difficulty;
          supportedLanguages: Array<string>;
          answers: Array<{
            __typename?: "AlgorithmAnswer";
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            label: string;
          }>;
        } | null;
        systemDesignQuestion: {
          __typename?: "SystemDesignQuestion";
          questionId: number;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          difficulty: Difficulty;
          initialSnapshot: string;
          answers: Array<{ __typename?: "SystemDesignAnswer"; titleJa: string; titleEn: string; descriptionJa: string; descriptionEn: string }>;
        } | null;
      } | null>;
      participants: Array<{
        __typename?: "LiveCodingParticipant";
        liveCodingId: number;
        userType: LiveCodingUserType;
        userId: string;
        userName: string;
      }>;
      videoChatRooms: Array<{
        __typename?: "LiveCodingVideoChatRoom";
        id: string;
        roomId: string;
        liveCodingId: number;
        createdAtSeconds: number | null;
      }>;
      videoChatConversation: {
        __typename?: "LiveCodingVideoChatConversation";
        id: string;
        conversationId: string;
        liveCodingId: number;
        createdAtSeconds: number | null;
      } | null;
    } | null;
  } | null;
};

export type GetRemotesQueryVariables = Exact<{
  remoteId: Scalars["String"]["input"];
}>;

export type GetRemotesQuery = {
  __typename?: "Query";
  remote: {
    __typename?: "Remote";
    status: RemoteStatus;
    remoteId: string;
    name: string;
    candidateName: string;
    didStartAtSeconds: number | null;
    entity: {
      __typename?: "RemoteEntity";
      entityType: RemoteEntityType;
      liveCoding: {
        __typename?: "LiveCoding";
        status: LiveCodingStatus | null;
        liveCodingId: number;
        sessions: Array<{
          __typename?: "LiveCodingSession";
          questionType: LiveCodingQuestionType;
          liveCodingId: number;
          liveCodingSessionId: number;
          elapsedTimeSeconds: number | null;
          freepadQuestion: {
            __typename?: "FreepadQuestion";
            freepadQuestionId: number;
            version: string;
            variant: FreepadQuestionVariant;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            answers: Array<{
              __typename?: "FreepadAnswer";
              titleJa: string | null;
              titleEn: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
              answerCodes: Array<{ __typename?: "FreepadAnswerCode"; body: string }>;
              answerFlowCharts: Array<{ __typename?: "FreepadAnswerFlowChart"; componentType: SystemDesignComponentType; body: string }>;
            }>;
            initialCodes: Array<{
              __typename?: "FreepadInitialCode";
              questionId: number;
              questionVersion: string;
              runtime: string;
              body: string;
            }>;
            initialFlowCharts: Array<{
              __typename?: "FreepadInitialFlowChart";
              questionId: number;
              questionVersion: string;
              componentType: SystemDesignComponentType;
              body: string;
            }>;
          } | null;
          algorithmQuestion: {
            __typename?: "AlgorithmQuestion";
            questionId: number;
            version: string;
            variant: AlgorithmQuestionVariant;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            supportedLanguages: Array<string>;
            answers: Array<{
              __typename?: "AlgorithmAnswer";
              titleJa: string | null;
              titleEn: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
              label: string;
            }>;
          } | null;
          systemDesignQuestion: {
            __typename?: "SystemDesignQuestion";
            questionId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            initialSnapshot: string;
            answers: Array<{
              __typename?: "SystemDesignAnswer";
              titleJa: string;
              titleEn: string;
              descriptionJa: string;
              descriptionEn: string;
            }>;
          } | null;
        } | null>;
        participants: Array<{
          __typename?: "LiveCodingParticipant";
          liveCodingId: number;
          userType: LiveCodingUserType;
          userId: string;
          userName: string;
        }>;
        videoChatRooms: Array<{
          __typename?: "LiveCodingVideoChatRoom";
          id: string;
          roomId: string;
          liveCodingId: number;
          createdAtSeconds: number | null;
        }>;
        videoChatConversation: {
          __typename?: "LiveCodingVideoChatConversation";
          id: string;
          conversationId: string;
          liveCodingId: number;
          createdAtSeconds: number | null;
        } | null;
      } | null;
    } | null;
  };
};

export type GetLiveCodingForEmployeeQueryVariables = Exact<{
  liveCodingId: Scalars["Int"]["input"];
}>;

export type GetLiveCodingForEmployeeQuery = {
  __typename?: "Query";
  liveCoding: {
    __typename?: "LiveCoding";
    status: LiveCodingStatus | null;
    liveCodingId: number;
    sessions: Array<{
      __typename?: "LiveCodingSession";
      questionType: LiveCodingQuestionType;
      liveCodingId: number;
      liveCodingSessionId: number;
      elapsedTimeSeconds: number | null;
      freepadQuestion: {
        __typename?: "FreepadQuestion";
        freepadQuestionId: number;
        version: string;
        variant: FreepadQuestionVariant;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        answers: Array<{
          __typename?: "FreepadAnswer";
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
        }>;
        initialCodes: Array<{ __typename?: "FreepadInitialCode"; questionId: number; questionVersion: string; runtime: string; body: string }>;
        initialFlowCharts: Array<{
          __typename?: "FreepadInitialFlowChart";
          questionId: number;
          questionVersion: string;
          componentType: SystemDesignComponentType;
          body: string;
        }>;
      } | null;
      algorithmQuestion: {
        __typename?: "AlgorithmQuestion";
        questionId: number;
        version: string;
        variant: AlgorithmQuestionVariant;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        supportedLanguages: Array<string>;
      } | null;
      systemDesignQuestion: {
        __typename?: "SystemDesignQuestion";
        questionId: number;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        initialSnapshot: string;
        answers: Array<{ __typename?: "SystemDesignAnswer"; titleJa: string; titleEn: string; descriptionJa: string; descriptionEn: string }>;
      } | null;
    } | null>;
    participants: Array<{
      __typename?: "LiveCodingParticipant";
      liveCodingId: number;
      userType: LiveCodingUserType;
      userId: string;
      userName: string;
    }>;
    videoChatRooms: Array<{
      __typename?: "LiveCodingVideoChatRoom";
      id: string;
      roomId: string;
      liveCodingId: number;
      createdAtSeconds: number | null;
    }>;
    videoChatConversation: {
      __typename?: "LiveCodingVideoChatConversation";
      id: string;
      conversationId: string;
      liveCodingId: number;
      createdAtSeconds: number | null;
    } | null;
  };
};

export type DeleteLiveCodingSessionMutationVariables = Exact<{
  input: DeleteLiveCodingSessionInput;
}>;

export type DeleteLiveCodingSessionMutation = { __typename?: "Mutation"; deleteLiveCodingSession: boolean | null };

export type EndRemoteInterviewMutationVariables = Exact<{
  input: EndRemoteInput;
}>;

export type EndRemoteInterviewMutation = {
  __typename?: "Mutation";
  endRemote: {
    __typename?: "Remote";
    status: RemoteStatus;
    remoteId: string;
    name: string;
    candidateName: string;
    didStartAtSeconds: number | null;
    entity: {
      __typename?: "RemoteEntity";
      entityType: RemoteEntityType;
      liveCoding: {
        __typename?: "LiveCoding";
        status: LiveCodingStatus | null;
        liveCodingId: number;
        sessions: Array<{
          __typename?: "LiveCodingSession";
          questionType: LiveCodingQuestionType;
          liveCodingId: number;
          liveCodingSessionId: number;
          elapsedTimeSeconds: number | null;
          freepadQuestion: {
            __typename?: "FreepadQuestion";
            freepadQuestionId: number;
            version: string;
            variant: FreepadQuestionVariant;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            answers: Array<{
              __typename?: "FreepadAnswer";
              titleJa: string | null;
              titleEn: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
              answerCodes: Array<{ __typename?: "FreepadAnswerCode"; body: string }>;
              answerFlowCharts: Array<{ __typename?: "FreepadAnswerFlowChart"; componentType: SystemDesignComponentType; body: string }>;
            }>;
            initialCodes: Array<{
              __typename?: "FreepadInitialCode";
              questionId: number;
              questionVersion: string;
              runtime: string;
              body: string;
            }>;
            initialFlowCharts: Array<{
              __typename?: "FreepadInitialFlowChart";
              questionId: number;
              questionVersion: string;
              componentType: SystemDesignComponentType;
              body: string;
            }>;
          } | null;
          algorithmQuestion: {
            __typename?: "AlgorithmQuestion";
            questionId: number;
            version: string;
            variant: AlgorithmQuestionVariant;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            supportedLanguages: Array<string>;
            answers: Array<{
              __typename?: "AlgorithmAnswer";
              titleJa: string | null;
              titleEn: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
              label: string;
            }>;
          } | null;
          systemDesignQuestion: {
            __typename?: "SystemDesignQuestion";
            questionId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            initialSnapshot: string;
            answers: Array<{
              __typename?: "SystemDesignAnswer";
              titleJa: string;
              titleEn: string;
              descriptionJa: string;
              descriptionEn: string;
            }>;
          } | null;
        } | null>;
        participants: Array<{
          __typename?: "LiveCodingParticipant";
          liveCodingId: number;
          userType: LiveCodingUserType;
          userId: string;
          userName: string;
        }>;
        videoChatRooms: Array<{
          __typename?: "LiveCodingVideoChatRoom";
          id: string;
          roomId: string;
          liveCodingId: number;
          createdAtSeconds: number | null;
        }>;
        videoChatConversation: {
          __typename?: "LiveCodingVideoChatConversation";
          id: string;
          conversationId: string;
          liveCodingId: number;
          createdAtSeconds: number | null;
        } | null;
      } | null;
    } | null;
  };
};

export type LiveCodingTemplateCodesForRemotesIdFragment = {
  __typename?: "LiveCodingTemplateCodes";
  python3: string;
  go: string;
  javascript: string;
  php: string;
  ruby: string;
  typescript: string;
  cpp: string;
  kotlin: string;
  java: string;
  swift: string;
  scala: string;
  rust: string;
  sqlite: string;
  pgsql: string;
  mysql: string;
  dart: string;
  csharp: string;
  c: string;
  perl: string;
};

export type GetLiveCodingTemplateCodesQueryVariables = Exact<{
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
}>;

export type GetLiveCodingTemplateCodesQuery = {
  __typename?: "Query";
  liveCodingTemplateCodes: {
    __typename?: "LiveCodingTemplateCodes";
    python3: string;
    go: string;
    javascript: string;
    php: string;
    ruby: string;
    typescript: string;
    cpp: string;
    kotlin: string;
    java: string;
    swift: string;
    scala: string;
    rust: string;
    sqlite: string;
    pgsql: string;
    mysql: string;
    dart: string;
    csharp: string;
    c: string;
    perl: string;
  };
};

export type AddLiveCodingSessionMutationVariables = Exact<{
  input: AddLiveCodingSessionInput;
}>;

export type AddLiveCodingSessionMutation = {
  __typename?: "Mutation";
  addLiveCodingSession: {
    __typename?: "LiveCodingSession";
    questionType: LiveCodingQuestionType;
    liveCodingId: number;
    liveCodingSessionId: number;
    elapsedTimeSeconds: number | null;
    freepadQuestion: {
      __typename?: "FreepadQuestion";
      freepadQuestionId: number;
      version: string;
      variant: FreepadQuestionVariant;
      titleJa: string | null;
      titleEn: string | null;
      descriptionJa: string | null;
      descriptionEn: string | null;
      difficulty: Difficulty;
      answers: Array<{
        __typename?: "FreepadAnswer";
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        answerCodes: Array<{ __typename?: "FreepadAnswerCode"; body: string }>;
        answerFlowCharts: Array<{ __typename?: "FreepadAnswerFlowChart"; componentType: SystemDesignComponentType; body: string }>;
      }>;
      initialCodes: Array<{ __typename?: "FreepadInitialCode"; questionId: number; questionVersion: string; runtime: string; body: string }>;
      initialFlowCharts: Array<{
        __typename?: "FreepadInitialFlowChart";
        questionId: number;
        questionVersion: string;
        componentType: SystemDesignComponentType;
        body: string;
      }>;
    } | null;
    algorithmQuestion: {
      __typename?: "AlgorithmQuestion";
      questionId: number;
      version: string;
      variant: AlgorithmQuestionVariant;
      titleJa: string | null;
      titleEn: string | null;
      descriptionJa: string | null;
      descriptionEn: string | null;
      difficulty: Difficulty;
      supportedLanguages: Array<string>;
      answers: Array<{
        __typename?: "AlgorithmAnswer";
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        label: string;
      }>;
    } | null;
    systemDesignQuestion: {
      __typename?: "SystemDesignQuestion";
      questionId: number;
      titleJa: string | null;
      titleEn: string | null;
      descriptionJa: string | null;
      descriptionEn: string | null;
      difficulty: Difficulty;
      initialSnapshot: string;
      answers: Array<{ __typename?: "SystemDesignAnswer"; titleJa: string; titleEn: string; descriptionJa: string; descriptionEn: string }>;
    } | null;
  };
};

export type JoinLiveCodingForEmployeeMutationVariables = Exact<{
  input: JoinLiveCodingInput;
}>;

export type JoinLiveCodingForEmployeeMutation = {
  __typename?: "Mutation";
  joinLiveCoding: {
    __typename?: "LiveCoding";
    status: LiveCodingStatus | null;
    liveCodingId: number;
    sessions: Array<{
      __typename?: "LiveCodingSession";
      questionType: LiveCodingQuestionType;
      liveCodingId: number;
      liveCodingSessionId: number;
      elapsedTimeSeconds: number | null;
      freepadQuestion: {
        __typename?: "FreepadQuestion";
        freepadQuestionId: number;
        version: string;
        variant: FreepadQuestionVariant;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        difficulty: Difficulty;
        answers: Array<{
          __typename?: "FreepadAnswer";
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          answerCodes: Array<{ __typename?: "FreepadAnswerCode"; body: string }>;
          answerFlowCharts: Array<{ __typename?: "FreepadAnswerFlowChart"; componentType: SystemDesignComponentType; body: string }>;
        }>;
        initialCodes: Array<{ __typename?: "FreepadInitialCode"; questionId: number; questionVersion: string; runtime: string; body: string }>;
        initialFlowCharts: Array<{
          __typename?: "FreepadInitialFlowChart";
          questionId: number;
          questionVersion: string;
          componentType: SystemDesignComponentType;
          body: string;
        }>;
      } | null;
      algorithmQuestion: {
        __typename?: "AlgorithmQuestion";
        questionId: number;
        version: string;
        variant: AlgorithmQuestionVariant;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        difficulty: Difficulty;
        supportedLanguages: Array<string>;
        answers: Array<{
          __typename?: "AlgorithmAnswer";
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          label: string;
        }>;
      } | null;
      systemDesignQuestion: {
        __typename?: "SystemDesignQuestion";
        questionId: number;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        difficulty: Difficulty;
        initialSnapshot: string;
        answers: Array<{ __typename?: "SystemDesignAnswer"; titleJa: string; titleEn: string; descriptionJa: string; descriptionEn: string }>;
      } | null;
    } | null>;
    participants: Array<{
      __typename?: "LiveCodingParticipant";
      liveCodingId: number;
      userType: LiveCodingUserType;
      userId: string;
      userName: string;
    }>;
    videoChatRooms: Array<{
      __typename?: "LiveCodingVideoChatRoom";
      id: string;
      roomId: string;
      liveCodingId: number;
      createdAtSeconds: number | null;
    }>;
    videoChatConversation: {
      __typename?: "LiveCodingVideoChatConversation";
      id: string;
      conversationId: string;
      liveCodingId: number;
      createdAtSeconds: number | null;
    } | null;
  };
};

export type StartLiveCodingMutationVariables = Exact<{
  input: StartLiveCodingInput;
}>;

export type StartLiveCodingMutation = {
  __typename?: "Mutation";
  startLiveCoding: {
    __typename?: "LiveCoding";
    status: LiveCodingStatus | null;
    liveCodingId: number;
    sessions: Array<{
      __typename?: "LiveCodingSession";
      questionType: LiveCodingQuestionType;
      liveCodingId: number;
      liveCodingSessionId: number;
      elapsedTimeSeconds: number | null;
      freepadQuestion: {
        __typename?: "FreepadQuestion";
        freepadQuestionId: number;
        version: string;
        variant: FreepadQuestionVariant;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        difficulty: Difficulty;
        answers: Array<{
          __typename?: "FreepadAnswer";
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          answerCodes: Array<{ __typename?: "FreepadAnswerCode"; body: string }>;
          answerFlowCharts: Array<{ __typename?: "FreepadAnswerFlowChart"; componentType: SystemDesignComponentType; body: string }>;
        }>;
        initialCodes: Array<{ __typename?: "FreepadInitialCode"; questionId: number; questionVersion: string; runtime: string; body: string }>;
        initialFlowCharts: Array<{
          __typename?: "FreepadInitialFlowChart";
          questionId: number;
          questionVersion: string;
          componentType: SystemDesignComponentType;
          body: string;
        }>;
      } | null;
      algorithmQuestion: {
        __typename?: "AlgorithmQuestion";
        questionId: number;
        version: string;
        variant: AlgorithmQuestionVariant;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        difficulty: Difficulty;
        supportedLanguages: Array<string>;
        answers: Array<{
          __typename?: "AlgorithmAnswer";
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          label: string;
        }>;
      } | null;
      systemDesignQuestion: {
        __typename?: "SystemDesignQuestion";
        questionId: number;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        difficulty: Difficulty;
        initialSnapshot: string;
        answers: Array<{ __typename?: "SystemDesignAnswer"; titleJa: string; titleEn: string; descriptionJa: string; descriptionEn: string }>;
      } | null;
    } | null>;
    participants: Array<{
      __typename?: "LiveCodingParticipant";
      liveCodingId: number;
      userType: LiveCodingUserType;
      userId: string;
      userName: string;
    }>;
    videoChatRooms: Array<{
      __typename?: "LiveCodingVideoChatRoom";
      id: string;
      roomId: string;
      liveCodingId: number;
      createdAtSeconds: number | null;
    }>;
    videoChatConversation: {
      __typename?: "LiveCodingVideoChatConversation";
      id: string;
      conversationId: string;
      liveCodingId: number;
      createdAtSeconds: number | null;
    } | null;
  };
};

export type EvaluationMetricsForRemotesIdFragment = {
  __typename?: "EvaluationMetric";
  id: string;
  metricId: number;
  title: string;
  description: string;
};

export type ListEvaluationMetricsForRemotesIdQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  size: Scalars["Int"]["input"];
  cursor: InputMaybe<Scalars["Int"]["input"]>;
  withCount: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type ListEvaluationMetricsForRemotesIdQuery = {
  __typename?: "Query";
  evaluationMetricsByCompany: {
    __typename?: "EvaluationMetricsByCompanyResponse";
    count: number;
    metrics: Array<{ __typename?: "EvaluationMetric"; id: string; metricId: number; title: string; description: string }>;
    pager: { __typename?: "EvaluationPager"; cursor: number; hasNext: boolean } | null;
  } | null;
};

export type AddLiveCodingVideoChatRoomForEmployeeRemotesIdMutationVariables = Exact<{
  input: AddLiveCodingVideoChatRoomInput;
}>;

export type AddLiveCodingVideoChatRoomForEmployeeRemotesIdMutation = {
  __typename?: "Mutation";
  addLiveCodingVideoChatRoom: {
    __typename?: "LiveCodingVideoChatRoom";
    id: string;
    roomId: string;
    liveCodingId: number;
    createdAtSeconds: number | null;
  };
};

export type AddLiveCodingVideoChatConversationForEmployeeRemotesIdMutationVariables = Exact<{
  input: AddLiveCodingVideoChatConversationInput;
}>;

export type AddLiveCodingVideoChatConversationForEmployeeRemotesIdMutation = {
  __typename?: "Mutation";
  addLiveCodingVideoChatConversation: {
    __typename?: "LiveCodingVideoChatConversation";
    id: string;
    conversationId: string;
    liveCodingId: number;
    createdAtSeconds: number | null;
  };
};

export type VideoChatRoomTokenForEmployeeRemotesIdFragment = { __typename?: "RoomToken"; token: string };

export type JoinVideoChatRoomForEmployeeMutationVariables = Exact<{
  input: JoinVideoChatRoomInput;
}>;

export type JoinVideoChatRoomForEmployeeMutation = { __typename?: "Mutation"; joinVideoChatRoom: { __typename?: "RoomToken"; token: string } };

export type StartRecordingVideoChatForEmployeeMutationVariables = Exact<{
  input: StartRecordingVideoChatInput;
}>;

export type StartRecordingVideoChatForEmployeeMutation = { __typename?: "Mutation"; startRecordingVideoChat: boolean };

export type StopRecordingVideoChatForEmployeeMutationVariables = Exact<{
  input: StopRecordingVideoChatInput;
}>;

export type StopRecordingVideoChatForEmployeeMutation = { __typename?: "Mutation"; stopRecordingVideoChat: boolean };

export type VideoChatConversationTokenForEmployeeRemotesIdFragment = { __typename?: "ConversationToken"; token: string };

export type JoinVideoChatConversationForEmployeeMutationVariables = Exact<{
  input: JoinVideoChatConversationInput;
}>;

export type JoinVideoChatConversationForEmployeeMutation = {
  __typename?: "Mutation";
  joinVideoChatConversation: { __typename?: "ConversationToken"; token: string };
};

export type VideoChatConversationParticipantForEmployeeRemotesIdFragment = {
  __typename?: "VideoChatConversationParticipant";
  displayName: string;
  conversationId: string;
  userId: string;
  userType: VideoChatUserType;
};

export type VideoChatConversationForEmployeeRemotesIdFragment = {
  __typename?: "VideoChatConversation";
  id: string;
  conversationId: string;
  status: VideoChatConversationStatus;
  participants: Array<{
    __typename?: "VideoChatConversationParticipant";
    displayName: string;
    conversationId: string;
    userId: string;
    userType: VideoChatUserType;
  }>;
};

export type GetVideoChatConversationForEmployeeQueryVariables = Exact<{
  input: VideoChatConversationInput;
}>;

export type GetVideoChatConversationForEmployeeQuery = {
  __typename?: "Query";
  videoChatConversation: {
    __typename?: "VideoChatConversation";
    id: string;
    conversationId: string;
    status: VideoChatConversationStatus;
    participants: Array<{
      __typename?: "VideoChatConversationParticipant";
      displayName: string;
      conversationId: string;
      userId: string;
      userType: VideoChatUserType;
    }>;
  } | null;
};

export type RemoteForRemoteInterviewDetailFragment = {
  __typename?: "Remote";
  id: string;
  name: string;
  candidateName: string;
  status: RemoteStatus;
  isPassed: boolean;
  evaluatedAtSeconds: number | null;
  didEndAtSeconds: number | null;
  evaluationComment: string;
  evaluatedBy: { __typename?: "User"; id: string; uid: string; displayName: string; email: string } | null;
};

export type GetRemoteForRemoteInterviewQueryVariables = Exact<{
  remoteId: Scalars["String"]["input"];
}>;

export type GetRemoteForRemoteInterviewQuery = {
  __typename?: "Query";
  remote: {
    __typename?: "Remote";
    id: string;
    name: string;
    candidateName: string;
    status: RemoteStatus;
    isPassed: boolean;
    evaluatedAtSeconds: number | null;
    didEndAtSeconds: number | null;
    evaluationComment: string;
    evaluatedBy: { __typename?: "User"; id: string; uid: string; displayName: string; email: string } | null;
  };
};

export type StartRemoteForRemoteInterviewDetailMutationVariables = Exact<{
  input: StartRemoteInput;
}>;

export type StartRemoteForRemoteInterviewDetailMutation = {
  __typename?: "Mutation";
  startRemote: {
    __typename?: "Remote";
    id: string;
    name: string;
    candidateName: string;
    status: RemoteStatus;
    isPassed: boolean;
    evaluatedAtSeconds: number | null;
    didEndAtSeconds: number | null;
    evaluationComment: string;
    evaluatedBy: { __typename?: "User"; id: string; uid: string; displayName: string; email: string } | null;
  };
};

export type EvaluateRemoteForRemoteInterviewDetailMutationVariables = Exact<{
  input: EvaluateRemoteInput;
}>;

export type EvaluateRemoteForRemoteInterviewDetailMutation = {
  __typename?: "Mutation";
  evaluateRemote: {
    __typename?: "Remote";
    id: string;
    name: string;
    candidateName: string;
    status: RemoteStatus;
    isPassed: boolean;
    evaluatedAtSeconds: number | null;
    didEndAtSeconds: number | null;
    evaluationComment: string;
    evaluatedBy: { __typename?: "User"; id: string; uid: string; displayName: string; email: string } | null;
  };
};

export type DeleteRemoteForRemoteInterviewDetailMutationVariables = Exact<{
  input: DeleteRemoteInput;
}>;

export type DeleteRemoteForRemoteInterviewDetailMutation = { __typename?: "Mutation"; deleteRemote: boolean | null };

export type GetRemotesForRemotesEvaluateQueryVariables = Exact<{
  remoteId: Scalars["String"]["input"];
}>;

export type GetRemotesForRemotesEvaluateQuery = { __typename?: "Query"; remote: { __typename?: "Remote"; remoteId: string } };

export type ReviewRemoteRemotesEvaluateMutationVariables = Exact<{
  reviewRemoteInput: ReviewRemoteInput;
}>;

export type ReviewRemoteRemotesEvaluateMutation = {
  __typename?: "Mutation";
  reviewRemote: { __typename?: "Remote"; id: string; remoteId: string };
};

export type EvaluationMetricsForRemotesEvaluateFragment = {
  __typename?: "EvaluationMetric";
  id: string;
  metricId: number;
  title: string;
  description: string;
};

export type ListEvaluationMetricsForRemotesEvaluateQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  size: Scalars["Int"]["input"];
  cursor: InputMaybe<Scalars["Int"]["input"]>;
  withCount: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type ListEvaluationMetricsForRemotesEvaluateQuery = {
  __typename?: "Query";
  evaluationMetricsByCompany: {
    __typename?: "EvaluationMetricsByCompanyResponse";
    count: number;
    metrics: Array<{ __typename?: "EvaluationMetric"; id: string; metricId: number; title: string; description: string }>;
    pager: { __typename?: "EvaluationPager"; cursor: number; hasNext: boolean } | null;
  } | null;
};

export type UpdateRemoteForRemoteInterviewsUpdateMutationVariables = Exact<{
  updateRemoteInput: UpdateRemoteInput;
}>;

export type UpdateRemoteForRemoteInterviewsUpdateMutation = {
  __typename?: "Mutation";
  updateRemote: { __typename?: "Remote"; id: string; name: string };
};

export type UserForScreeningsFragment = { __typename?: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string };

export type VariableForScreeningsFragment = {
  __typename?: "ScreeningVariable";
  screeningId: string;
  variableId: number;
  label: string;
  description: string;
  variableField:
    | {
        __typename: "ScreeningBooleanFieldVariable";
        validationRule: { __typename: "ScreeningBooleanFieldVariableValidationRule"; required: boolean };
      }
    | {
        __typename: "ScreeningIntegerFieldVariable";
        validationRule: {
          __typename: "ScreeningIntegerFieldVariableValidationRule";
          required: boolean;
          maxIntegerValue: number | null;
          minIntegerValue: number | null;
        };
      }
    | {
        __typename: "ScreeningStringFieldVariable";
        formType: ScreeningStringVariableFieldFormType;
        validationRule: {
          __typename: "ScreeningStringFieldVariableValidationRule";
          required: boolean;
          maxLength: number | null;
          minLength: number | null;
        };
      };
};

export type ScreeningForScreeningsFragment = {
  __typename?: "Screening";
  id: string;
  screeningId: string;
  name: string;
  memo: string | null;
  ownerEmailAddress: string | null;
  companyId: number;
  description: string;
  deadlineSeconds: number | null;
  acceptanceStatus: ScreeningAcceptanceStatus;
  messageForCandidate: string | null;
  timeLimitSeconds: number;
  numAccepted: number;
  acceptableLimit: number | null;
  isActive: boolean;
  candidateAccessPolicy: ScreeningCandidateAccessPolicy;
  invitationLanguage: Language;
  allowChatGPT: boolean;
  allowWebSearch: boolean;
  spotIsPublic: boolean;
  createdBy: { __typename?: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string } | null;
  variables: Array<{
    __typename?: "ScreeningVariable";
    screeningId: string;
    variableId: number;
    label: string;
    description: string;
    variableField:
      | {
          __typename: "ScreeningBooleanFieldVariable";
          validationRule: { __typename: "ScreeningBooleanFieldVariableValidationRule"; required: boolean };
        }
      | {
          __typename: "ScreeningIntegerFieldVariable";
          validationRule: {
            __typename: "ScreeningIntegerFieldVariableValidationRule";
            required: boolean;
            maxIntegerValue: number | null;
            minIntegerValue: number | null;
          };
        }
      | {
          __typename: "ScreeningStringFieldVariable";
          formType: ScreeningStringVariableFieldFormType;
          validationRule: {
            __typename: "ScreeningStringFieldVariableValidationRule";
            required: boolean;
            maxLength: number | null;
            minLength: number | null;
          };
        };
  }>;
  invitees: Array<{ __typename?: "ScreeningInvitee"; screeningId: string; email: string; status: ScreeningInviteeStatus }>;
  viewers: Array<
    | { __typename: "EmployeeGroup"; id: string; employeeGroupId: string; groupName: string }
    | { __typename: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string }
  >;
  tags: Array<{ __typename?: "SpotTag"; name: string }>;
};

export type ScreeningsByCompanyIdResponseForScreeningsFragment = {
  __typename?: "ScreeningsByCompanyIdResponse";
  hasNext: boolean;
  count: number;
  screenings: Array<{
    __typename?: "Screening";
    id: string;
    screeningId: string;
    name: string;
    memo: string | null;
    ownerEmailAddress: string | null;
    companyId: number;
    description: string;
    deadlineSeconds: number | null;
    acceptanceStatus: ScreeningAcceptanceStatus;
    messageForCandidate: string | null;
    timeLimitSeconds: number;
    numAccepted: number;
    acceptableLimit: number | null;
    isActive: boolean;
    candidateAccessPolicy: ScreeningCandidateAccessPolicy;
    invitationLanguage: Language;
    allowChatGPT: boolean;
    allowWebSearch: boolean;
    spotIsPublic: boolean;
    createdBy: { __typename?: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string } | null;
    variables: Array<{
      __typename?: "ScreeningVariable";
      screeningId: string;
      variableId: number;
      label: string;
      description: string;
      variableField:
        | {
            __typename: "ScreeningBooleanFieldVariable";
            validationRule: { __typename: "ScreeningBooleanFieldVariableValidationRule"; required: boolean };
          }
        | {
            __typename: "ScreeningIntegerFieldVariable";
            validationRule: {
              __typename: "ScreeningIntegerFieldVariableValidationRule";
              required: boolean;
              maxIntegerValue: number | null;
              minIntegerValue: number | null;
            };
          }
        | {
            __typename: "ScreeningStringFieldVariable";
            formType: ScreeningStringVariableFieldFormType;
            validationRule: {
              __typename: "ScreeningStringFieldVariableValidationRule";
              required: boolean;
              maxLength: number | null;
              minLength: number | null;
            };
          };
    }>;
    invitees: Array<{ __typename?: "ScreeningInvitee"; screeningId: string; email: string; status: ScreeningInviteeStatus }>;
    viewers: Array<
      | { __typename: "EmployeeGroup"; id: string; employeeGroupId: string; groupName: string }
      | { __typename: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string }
    >;
    tags: Array<{ __typename?: "SpotTag"; name: string }>;
  }>;
};

export type ListScreeningsByCompanyIdForScreeningsQueryVariables = Exact<{
  input: ScreeningsByCompanyIdInput;
}>;

export type ListScreeningsByCompanyIdForScreeningsQuery = {
  __typename?: "Query";
  screeningsByCompanyId: {
    __typename?: "ScreeningsByCompanyIdResponse";
    hasNext: boolean;
    count: number;
    screenings: Array<{
      __typename?: "Screening";
      id: string;
      screeningId: string;
      name: string;
      memo: string | null;
      ownerEmailAddress: string | null;
      companyId: number;
      description: string;
      deadlineSeconds: number | null;
      acceptanceStatus: ScreeningAcceptanceStatus;
      messageForCandidate: string | null;
      timeLimitSeconds: number;
      numAccepted: number;
      acceptableLimit: number | null;
      isActive: boolean;
      candidateAccessPolicy: ScreeningCandidateAccessPolicy;
      invitationLanguage: Language;
      allowChatGPT: boolean;
      allowWebSearch: boolean;
      spotIsPublic: boolean;
      createdBy: { __typename?: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string } | null;
      variables: Array<{
        __typename?: "ScreeningVariable";
        screeningId: string;
        variableId: number;
        label: string;
        description: string;
        variableField:
          | {
              __typename: "ScreeningBooleanFieldVariable";
              validationRule: { __typename: "ScreeningBooleanFieldVariableValidationRule"; required: boolean };
            }
          | {
              __typename: "ScreeningIntegerFieldVariable";
              validationRule: {
                __typename: "ScreeningIntegerFieldVariableValidationRule";
                required: boolean;
                maxIntegerValue: number | null;
                minIntegerValue: number | null;
              };
            }
          | {
              __typename: "ScreeningStringFieldVariable";
              formType: ScreeningStringVariableFieldFormType;
              validationRule: {
                __typename: "ScreeningStringFieldVariableValidationRule";
                required: boolean;
                maxLength: number | null;
                minLength: number | null;
              };
            };
      }>;
      invitees: Array<{ __typename?: "ScreeningInvitee"; screeningId: string; email: string; status: ScreeningInviteeStatus }>;
      viewers: Array<
        | { __typename: "EmployeeGroup"; id: string; employeeGroupId: string; groupName: string }
        | { __typename: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string }
      >;
      tags: Array<{ __typename?: "SpotTag"; name: string }>;
    }>;
  } | null;
};

export type SpotTagByCompanyIdForScreeningsFragment = {
  __typename?: "SpotTagsByCompanyIdResponse";
  count: number;
  tags: Array<{ __typename?: "SpotTag"; name: string }>;
  pager: { __typename?: "SpotPager"; cursorSeconds: number | null; hasNext: boolean } | null;
};

export type GetSpotTagsForScreeningsQueryVariables = Exact<{
  spotTagsByCompanyId: SpotTagsByCompanyIdInput;
}>;

export type GetSpotTagsForScreeningsQuery = {
  __typename?: "Query";
  spotTagsByCompanyId: {
    __typename?: "SpotTagsByCompanyIdResponse";
    count: number;
    tags: Array<{ __typename?: "SpotTag"; name: string }>;
    pager: { __typename?: "SpotPager"; cursorSeconds: number | null; hasNext: boolean } | null;
  } | null;
};

export type UpdateScreeningForScreeningsMutationVariables = Exact<{
  input: UpdateScreeningInput;
}>;

export type UpdateScreeningForScreeningsMutation = {
  __typename?: "Mutation";
  updateScreening: {
    __typename?: "Screening";
    id: string;
    screeningId: string;
    name: string;
    memo: string | null;
    ownerEmailAddress: string | null;
    companyId: number;
    description: string;
    deadlineSeconds: number | null;
    acceptanceStatus: ScreeningAcceptanceStatus;
    messageForCandidate: string | null;
    timeLimitSeconds: number;
    numAccepted: number;
    acceptableLimit: number | null;
    isActive: boolean;
    candidateAccessPolicy: ScreeningCandidateAccessPolicy;
    invitationLanguage: Language;
    allowChatGPT: boolean;
    allowWebSearch: boolean;
    spotIsPublic: boolean;
    createdBy: { __typename?: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string } | null;
    variables: Array<{
      __typename?: "ScreeningVariable";
      screeningId: string;
      variableId: number;
      label: string;
      description: string;
      variableField:
        | {
            __typename: "ScreeningBooleanFieldVariable";
            validationRule: { __typename: "ScreeningBooleanFieldVariableValidationRule"; required: boolean };
          }
        | {
            __typename: "ScreeningIntegerFieldVariable";
            validationRule: {
              __typename: "ScreeningIntegerFieldVariableValidationRule";
              required: boolean;
              maxIntegerValue: number | null;
              minIntegerValue: number | null;
            };
          }
        | {
            __typename: "ScreeningStringFieldVariable";
            formType: ScreeningStringVariableFieldFormType;
            validationRule: {
              __typename: "ScreeningStringFieldVariableValidationRule";
              required: boolean;
              maxLength: number | null;
              minLength: number | null;
            };
          };
    }>;
    invitees: Array<{ __typename?: "ScreeningInvitee"; screeningId: string; email: string; status: ScreeningInviteeStatus }>;
    viewers: Array<
      | { __typename: "EmployeeGroup"; id: string; employeeGroupId: string; groupName: string }
      | { __typename: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string }
    >;
    tags: Array<{ __typename?: "SpotTag"; name: string }>;
  };
};

export type DeleteScreeningForScreeningsMutationVariables = Exact<{
  input: DeleteScreeningInput;
}>;

export type DeleteScreeningForScreeningsMutation = { __typename?: "Mutation"; deleteScreening: boolean };

export type DeleteMultiScreeningsForScreeningsMutationVariables = Exact<{
  input: DeleteMultiScreeningsInput;
}>;

export type DeleteMultiScreeningsForScreeningsMutation = { __typename?: "Mutation"; deleteMultiScreenings: boolean };

export type UpdateTagsToScreeningsMutationVariables = Exact<{
  input: UpdateTagsToScreeningsInput;
}>;

export type UpdateTagsToScreeningsMutation = {
  __typename?: "Mutation";
  updateTagsToScreenings: Array<{
    __typename?: "Screening";
    id: string;
    screeningId: string;
    name: string;
    memo: string | null;
    ownerEmailAddress: string | null;
    companyId: number;
    description: string;
    deadlineSeconds: number | null;
    acceptanceStatus: ScreeningAcceptanceStatus;
    messageForCandidate: string | null;
    timeLimitSeconds: number;
    numAccepted: number;
    acceptableLimit: number | null;
    isActive: boolean;
    candidateAccessPolicy: ScreeningCandidateAccessPolicy;
    invitationLanguage: Language;
    allowChatGPT: boolean;
    allowWebSearch: boolean;
    spotIsPublic: boolean;
    createdBy: { __typename?: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string } | null;
    variables: Array<{
      __typename?: "ScreeningVariable";
      screeningId: string;
      variableId: number;
      label: string;
      description: string;
      variableField:
        | {
            __typename: "ScreeningBooleanFieldVariable";
            validationRule: { __typename: "ScreeningBooleanFieldVariableValidationRule"; required: boolean };
          }
        | {
            __typename: "ScreeningIntegerFieldVariable";
            validationRule: {
              __typename: "ScreeningIntegerFieldVariableValidationRule";
              required: boolean;
              maxIntegerValue: number | null;
              minIntegerValue: number | null;
            };
          }
        | {
            __typename: "ScreeningStringFieldVariable";
            formType: ScreeningStringVariableFieldFormType;
            validationRule: {
              __typename: "ScreeningStringFieldVariableValidationRule";
              required: boolean;
              maxLength: number | null;
              minLength: number | null;
            };
          };
    }>;
    invitees: Array<{ __typename?: "ScreeningInvitee"; screeningId: string; email: string; status: ScreeningInviteeStatus }>;
    viewers: Array<
      | { __typename: "EmployeeGroup"; id: string; employeeGroupId: string; groupName: string }
      | { __typename: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string }
    >;
    tags: Array<{ __typename?: "SpotTag"; name: string }>;
  }>;
};

export type InviteCandidateToScreeningForScreeningsMutationVariables = Exact<{
  input: InviteCandidatesToScreeningInput;
}>;

export type InviteCandidateToScreeningForScreeningsMutation = {
  __typename?: "Mutation";
  inviteCandidateToScreening: {
    __typename?: "InviteCandidateToScreeningResponse";
    invitees: Array<{ __typename?: "ScreeningInvitee"; screeningId: string; status: ScreeningInviteeStatus; email: string }>;
  };
};

export type GetScreeningForScreeningsQueryVariables = Exact<{
  screeningId: Scalars["String"]["input"];
  invitationCode: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetScreeningForScreeningsQuery = {
  __typename?: "Query";
  screening: {
    __typename?: "Screening";
    id: string;
    screeningId: string;
    name: string;
    memo: string | null;
    ownerEmailAddress: string | null;
    companyId: number;
    description: string;
    deadlineSeconds: number | null;
    acceptanceStatus: ScreeningAcceptanceStatus;
    messageForCandidate: string | null;
    timeLimitSeconds: number;
    numAccepted: number;
    acceptableLimit: number | null;
    isActive: boolean;
    candidateAccessPolicy: ScreeningCandidateAccessPolicy;
    invitationLanguage: Language;
    allowChatGPT: boolean;
    allowWebSearch: boolean;
    spotIsPublic: boolean;
    createdBy: { __typename?: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string } | null;
    variables: Array<{
      __typename?: "ScreeningVariable";
      screeningId: string;
      variableId: number;
      label: string;
      description: string;
      variableField:
        | {
            __typename: "ScreeningBooleanFieldVariable";
            validationRule: { __typename: "ScreeningBooleanFieldVariableValidationRule"; required: boolean };
          }
        | {
            __typename: "ScreeningIntegerFieldVariable";
            validationRule: {
              __typename: "ScreeningIntegerFieldVariableValidationRule";
              required: boolean;
              maxIntegerValue: number | null;
              minIntegerValue: number | null;
            };
          }
        | {
            __typename: "ScreeningStringFieldVariable";
            formType: ScreeningStringVariableFieldFormType;
            validationRule: {
              __typename: "ScreeningStringFieldVariableValidationRule";
              required: boolean;
              maxLength: number | null;
              minLength: number | null;
            };
          };
    }>;
    invitees: Array<{ __typename?: "ScreeningInvitee"; screeningId: string; email: string; status: ScreeningInviteeStatus }>;
    viewers: Array<
      | { __typename: "EmployeeGroup"; id: string; employeeGroupId: string; groupName: string }
      | { __typename: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string }
    >;
    tags: Array<{ __typename?: "SpotTag"; name: string }>;
  };
};

export type ReplicateScreeningForScreeningsMutationVariables = Exact<{
  input: ReplicateScreening;
}>;

export type ReplicateScreeningForScreeningsMutation = {
  __typename?: "Mutation";
  replicateScreening: {
    __typename?: "Screening";
    id: string;
    screeningId: string;
    name: string;
    memo: string | null;
    ownerEmailAddress: string | null;
    companyId: number;
    description: string;
    deadlineSeconds: number | null;
    acceptanceStatus: ScreeningAcceptanceStatus;
    messageForCandidate: string | null;
    timeLimitSeconds: number;
    numAccepted: number;
    acceptableLimit: number | null;
    isActive: boolean;
    candidateAccessPolicy: ScreeningCandidateAccessPolicy;
    invitationLanguage: Language;
    allowChatGPT: boolean;
    allowWebSearch: boolean;
    spotIsPublic: boolean;
    createdBy: { __typename?: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string } | null;
    variables: Array<{
      __typename?: "ScreeningVariable";
      screeningId: string;
      variableId: number;
      label: string;
      description: string;
      variableField:
        | {
            __typename: "ScreeningBooleanFieldVariable";
            validationRule: { __typename: "ScreeningBooleanFieldVariableValidationRule"; required: boolean };
          }
        | {
            __typename: "ScreeningIntegerFieldVariable";
            validationRule: {
              __typename: "ScreeningIntegerFieldVariableValidationRule";
              required: boolean;
              maxIntegerValue: number | null;
              minIntegerValue: number | null;
            };
          }
        | {
            __typename: "ScreeningStringFieldVariable";
            formType: ScreeningStringVariableFieldFormType;
            validationRule: {
              __typename: "ScreeningStringFieldVariableValidationRule";
              required: boolean;
              maxLength: number | null;
              minLength: number | null;
            };
          };
    }>;
    invitees: Array<{ __typename?: "ScreeningInvitee"; screeningId: string; email: string; status: ScreeningInviteeStatus }>;
    viewers: Array<
      | { __typename: "EmployeeGroup"; id: string; employeeGroupId: string; groupName: string }
      | { __typename: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string }
    >;
    tags: Array<{ __typename?: "SpotTag"; name: string }>;
  };
};

export type CreateScreeningForScreeningsCreateMutationVariables = Exact<{
  input: CreateScreeningInput;
}>;

export type CreateScreeningForScreeningsCreateMutation = {
  __typename?: "Mutation";
  createScreening: { __typename?: "Screening"; id: string; screeningId: string; name: string };
};

export type UpdateScreeningForScreeningsIdUpdateMutationVariables = Exact<{
  input: UpdateScreeningInput;
}>;

export type UpdateScreeningForScreeningsIdUpdateMutation = {
  __typename?: "Mutation";
  updateScreening: { __typename?: "Screening"; id: string; screeningId: string; name: string };
};

export type GetTestResultTableQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type GetTestResultTableQuery = {
  __typename?: "Query";
  testResultTable: { __typename?: "ExportResponse"; status: ExportStatus; reason: string; downloadUrl: string };
};

export type GetInterviewsForCreatorFragment = { __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string };

export type GetInterviewsForSpotTagsByCompanyIdResponseFragment = {
  __typename?: "SpotTagsByCompanyIdResponse";
  count: number;
  tags: Array<{ __typename?: "SpotTag"; name: string; companyId: number }>;
  pager: { __typename?: "SpotPager"; cursorSeconds: number | null; hasNext: boolean } | null;
};

export type GetInterviewsForScreeningVariablesByCompanyIdResponseFragment = {
  __typename?: "ScreeningVariablesByCompanyIdResponse";
  count: number;
  variables: Array<{ __typename?: "ScreeningVariable"; label: string }>;
  pager: { __typename?: "SpotPager"; cursorSeconds: number | null; hasNext: boolean } | null;
};

export type ScreeningForScreeningsTestsFragment = { __typename?: "Screening"; screeningId: string; name: string };

export type GetInterviewsQueryVariables = Exact<{
  spotTagsByCompanyId: SpotTagsByCompanyIdInput;
  screeningVariablesByCompanyId: ScreeningVariablesByCompanyIdInput;
  issuers: IssuersInput;
}>;

export type GetInterviewsQuery = {
  __typename?: "Query";
  spotTagsByCompanyId: {
    __typename?: "SpotTagsByCompanyIdResponse";
    count: number;
    tags: Array<{ __typename?: "SpotTag"; name: string; companyId: number }>;
    pager: { __typename?: "SpotPager"; cursorSeconds: number | null; hasNext: boolean } | null;
  } | null;
  screeningVariablesByCompanyId: {
    __typename?: "ScreeningVariablesByCompanyIdResponse";
    count: number;
    variables: Array<{ __typename?: "ScreeningVariable"; label: string }>;
    pager: { __typename?: "SpotPager"; cursorSeconds: number | null; hasNext: boolean } | null;
  } | null;
  issuers: {
    __typename?: "IssuersResponse";
    creators: Array<{ __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string }>;
    screenings: Array<{ __typename?: "Screening"; screeningId: string; name: string }>;
  } | null;
};

export type SpotForEmployeeInterviewsFragment = {
  __typename?: "Spot";
  id: string;
  spotId: string;
  parentId: string;
  originId: string;
  name: string;
  candidateName: string;
  candidateEmail: string;
  screeningId: string | null;
  status: SpotStatus;
  isPublic: boolean | null;
  isRetake: boolean;
  totalScore: number;
  customScore: number;
  rank: number;
  numSubset: number;
  rankEvaluation: RankEvaluation;
  isReliableRank: boolean;
  isPassed: boolean;
  suspiciousDegree: SpotSuspiciousDegree;
  createdAtSeconds: number | null;
  declinedAtSeconds: number | null;
  deletedAtSeconds: number | null;
  didEndAtSeconds: number | null;
  didStartAtSeconds: number | null;
  elapsedTimeSeconds: number | null;
  evaluatedAtSeconds: number | null;
  finalizedAtSeconds: number | null;
  timeLimitSeconds: number;
  updatedAtSeconds: number | null;
  willExpireAtSeconds: number | null;
  screening: { __typename?: "Screening"; id: string; screeningId: string; name: string } | null;
  employee: {
    __typename?: "User";
    id: string;
    email: string;
    emailVerified: boolean;
    displayName: string;
    fullName: string;
    language: Language;
    photoUrl: string;
  } | null;
  tags: Array<{ __typename?: "SpotTag"; name: string }>;
  recommenders: Array<{ __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string }>;
  evaluatedBy: { __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string } | null;
};

export type SpotsByCompanyIdResponseForInterviewFragment = {
  __typename?: "SpotsByCompanyIdResponse";
  count: number;
  hasNext: boolean;
  spots: Array<{
    __typename?: "Spot";
    id: string;
    spotId: string;
    parentId: string;
    originId: string;
    name: string;
    candidateName: string;
    candidateEmail: string;
    screeningId: string | null;
    status: SpotStatus;
    isPublic: boolean | null;
    isRetake: boolean;
    totalScore: number;
    customScore: number;
    rank: number;
    numSubset: number;
    rankEvaluation: RankEvaluation;
    isReliableRank: boolean;
    isPassed: boolean;
    suspiciousDegree: SpotSuspiciousDegree;
    createdAtSeconds: number | null;
    declinedAtSeconds: number | null;
    deletedAtSeconds: number | null;
    didEndAtSeconds: number | null;
    didStartAtSeconds: number | null;
    elapsedTimeSeconds: number | null;
    evaluatedAtSeconds: number | null;
    finalizedAtSeconds: number | null;
    timeLimitSeconds: number;
    updatedAtSeconds: number | null;
    willExpireAtSeconds: number | null;
    screening: { __typename?: "Screening"; id: string; screeningId: string; name: string } | null;
    employee: {
      __typename?: "User";
      id: string;
      email: string;
      emailVerified: boolean;
      displayName: string;
      fullName: string;
      language: Language;
      photoUrl: string;
    } | null;
    tags: Array<{ __typename?: "SpotTag"; name: string }>;
    recommenders: Array<{ __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string }>;
    evaluatedBy: { __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string } | null;
  }>;
  pager: { __typename?: "SpotPager"; cursorSeconds: number | null; hasNext: boolean } | null;
};

export type ListSpotsByCompanyIdForInterviewsQueryVariables = Exact<{
  listByCompanyId: SpotsByCompanyIdInput;
}>;

export type ListSpotsByCompanyIdForInterviewsQuery = {
  __typename?: "Query";
  spotsByCompanyId: {
    __typename?: "SpotsByCompanyIdResponse";
    count: number;
    hasNext: boolean;
    spots: Array<{
      __typename?: "Spot";
      id: string;
      spotId: string;
      parentId: string;
      originId: string;
      name: string;
      candidateName: string;
      candidateEmail: string;
      screeningId: string | null;
      status: SpotStatus;
      isPublic: boolean | null;
      isRetake: boolean;
      totalScore: number;
      customScore: number;
      rank: number;
      numSubset: number;
      rankEvaluation: RankEvaluation;
      isReliableRank: boolean;
      isPassed: boolean;
      suspiciousDegree: SpotSuspiciousDegree;
      createdAtSeconds: number | null;
      declinedAtSeconds: number | null;
      deletedAtSeconds: number | null;
      didEndAtSeconds: number | null;
      didStartAtSeconds: number | null;
      elapsedTimeSeconds: number | null;
      evaluatedAtSeconds: number | null;
      finalizedAtSeconds: number | null;
      timeLimitSeconds: number;
      updatedAtSeconds: number | null;
      willExpireAtSeconds: number | null;
      screening: { __typename?: "Screening"; id: string; screeningId: string; name: string } | null;
      employee: {
        __typename?: "User";
        id: string;
        email: string;
        emailVerified: boolean;
        displayName: string;
        fullName: string;
        language: Language;
        photoUrl: string;
      } | null;
      tags: Array<{ __typename?: "SpotTag"; name: string }>;
      recommenders: Array<{ __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string }>;
      evaluatedBy: { __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string } | null;
    }>;
    pager: { __typename?: "SpotPager"; cursorSeconds: number | null; hasNext: boolean } | null;
  } | null;
};

export type UpdateSpotTagsMutationVariables = Exact<{
  updateSpotInput: UpdateSpotInput;
}>;

export type UpdateSpotTagsMutation = {
  __typename?: "Mutation";
  updateSpot: {
    __typename?: "Spot";
    id: string;
    spotId: string;
    parentId: string;
    originId: string;
    name: string;
    candidateName: string;
    candidateEmail: string;
    screeningId: string | null;
    status: SpotStatus;
    isPublic: boolean | null;
    isRetake: boolean;
    totalScore: number;
    customScore: number;
    rank: number;
    numSubset: number;
    rankEvaluation: RankEvaluation;
    isReliableRank: boolean;
    isPassed: boolean;
    suspiciousDegree: SpotSuspiciousDegree;
    createdAtSeconds: number | null;
    declinedAtSeconds: number | null;
    deletedAtSeconds: number | null;
    didEndAtSeconds: number | null;
    didStartAtSeconds: number | null;
    elapsedTimeSeconds: number | null;
    evaluatedAtSeconds: number | null;
    finalizedAtSeconds: number | null;
    timeLimitSeconds: number;
    updatedAtSeconds: number | null;
    willExpireAtSeconds: number | null;
    screening: { __typename?: "Screening"; id: string; screeningId: string; name: string } | null;
    employee: {
      __typename?: "User";
      id: string;
      email: string;
      emailVerified: boolean;
      displayName: string;
      fullName: string;
      language: Language;
      photoUrl: string;
    } | null;
    tags: Array<{ __typename?: "SpotTag"; name: string }>;
    recommenders: Array<{ __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string }>;
    evaluatedBy: { __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string } | null;
  } | null;
};

export type EvaluateInterviewForInterviewsMutationVariables = Exact<{
  spotId: Scalars["String"]["input"];
  employeeId: Scalars["String"]["input"];
  isPassed: Scalars["Boolean"]["input"];
  evaluationComment: Scalars["String"]["input"];
}>;

export type EvaluateInterviewForInterviewsMutation = {
  __typename?: "Mutation";
  evaluateSpot: {
    __typename?: "Spot";
    id: string;
    spotId: string;
    parentId: string;
    originId: string;
    name: string;
    candidateName: string;
    candidateEmail: string;
    screeningId: string | null;
    status: SpotStatus;
    isPublic: boolean | null;
    isRetake: boolean;
    totalScore: number;
    customScore: number;
    rank: number;
    numSubset: number;
    rankEvaluation: RankEvaluation;
    isReliableRank: boolean;
    isPassed: boolean;
    suspiciousDegree: SpotSuspiciousDegree;
    createdAtSeconds: number | null;
    declinedAtSeconds: number | null;
    deletedAtSeconds: number | null;
    didEndAtSeconds: number | null;
    didStartAtSeconds: number | null;
    elapsedTimeSeconds: number | null;
    evaluatedAtSeconds: number | null;
    finalizedAtSeconds: number | null;
    timeLimitSeconds: number;
    updatedAtSeconds: number | null;
    willExpireAtSeconds: number | null;
    screening: { __typename?: "Screening"; id: string; screeningId: string; name: string } | null;
    employee: {
      __typename?: "User";
      id: string;
      email: string;
      emailVerified: boolean;
      displayName: string;
      fullName: string;
      language: Language;
      photoUrl: string;
    } | null;
    tags: Array<{ __typename?: "SpotTag"; name: string }>;
    recommenders: Array<{ __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string }>;
    evaluatedBy: { __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string } | null;
  } | null;
};

export type DeleteMultiSpotsForScreeningsTestsMutationVariables = Exact<{
  input: DeleteMultiSpotsInput;
}>;

export type DeleteMultiSpotsForScreeningsTestsMutation = { __typename?: "Mutation"; deleteMultiSpots: boolean };

export type UpdateTagsToSpotsForScreeningsTestsMutationVariables = Exact<{
  input: UpdateTagsToSpotsInput;
}>;

export type UpdateTagsToSpotsForScreeningsTestsMutation = {
  __typename?: "Mutation";
  updateTagsToSpots: Array<{
    __typename?: "Spot";
    id: string;
    spotId: string;
    parentId: string;
    originId: string;
    name: string;
    candidateName: string;
    candidateEmail: string;
    screeningId: string | null;
    status: SpotStatus;
    isPublic: boolean | null;
    isRetake: boolean;
    totalScore: number;
    customScore: number;
    rank: number;
    numSubset: number;
    rankEvaluation: RankEvaluation;
    isReliableRank: boolean;
    isPassed: boolean;
    suspiciousDegree: SpotSuspiciousDegree;
    createdAtSeconds: number | null;
    declinedAtSeconds: number | null;
    deletedAtSeconds: number | null;
    didEndAtSeconds: number | null;
    didStartAtSeconds: number | null;
    elapsedTimeSeconds: number | null;
    evaluatedAtSeconds: number | null;
    finalizedAtSeconds: number | null;
    timeLimitSeconds: number;
    updatedAtSeconds: number | null;
    willExpireAtSeconds: number | null;
    screening: { __typename?: "Screening"; id: string; screeningId: string; name: string } | null;
    employee: {
      __typename?: "User";
      id: string;
      email: string;
      emailVerified: boolean;
      displayName: string;
      fullName: string;
      language: Language;
      photoUrl: string;
    } | null;
    tags: Array<{ __typename?: "SpotTag"; name: string }>;
    recommenders: Array<{ __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string }>;
    evaluatedBy: { __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string } | null;
  }>;
};

export type EvaluateMultiSpotsForScreeningsTestsMutationVariables = Exact<{
  input: EvaluateMultiSpotsInput;
}>;

export type EvaluateMultiSpotsForScreeningsTestsMutation = {
  __typename?: "Mutation";
  evaluateMultiSpots: Array<{
    __typename?: "Spot";
    id: string;
    spotId: string;
    parentId: string;
    originId: string;
    name: string;
    candidateName: string;
    candidateEmail: string;
    screeningId: string | null;
    status: SpotStatus;
    isPublic: boolean | null;
    isRetake: boolean;
    totalScore: number;
    customScore: number;
    rank: number;
    numSubset: number;
    rankEvaluation: RankEvaluation;
    isReliableRank: boolean;
    isPassed: boolean;
    suspiciousDegree: SpotSuspiciousDegree;
    createdAtSeconds: number | null;
    declinedAtSeconds: number | null;
    deletedAtSeconds: number | null;
    didEndAtSeconds: number | null;
    didStartAtSeconds: number | null;
    elapsedTimeSeconds: number | null;
    evaluatedAtSeconds: number | null;
    finalizedAtSeconds: number | null;
    timeLimitSeconds: number;
    updatedAtSeconds: number | null;
    willExpireAtSeconds: number | null;
    screening: { __typename?: "Screening"; id: string; screeningId: string; name: string } | null;
    employee: {
      __typename?: "User";
      id: string;
      email: string;
      emailVerified: boolean;
      displayName: string;
      fullName: string;
      language: Language;
      photoUrl: string;
    } | null;
    tags: Array<{ __typename?: "SpotTag"; name: string }>;
    recommenders: Array<{ __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string }>;
    evaluatedBy: { __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string } | null;
  }>;
};

export type InterviewDetailUserFragment = {
  __typename?: "User";
  id: string;
  uid: string;
  displayName: string;
  email: string;
  photoUrl: string;
  isSystemAdmin: boolean;
};

export type InterviewDetailFragment = {
  __typename?: "Spot";
  id: string;
  originId: string;
  parentId: string;
  candidateName: string;
  candidateEmail: string;
  screeningId: string | null;
  createdAtSeconds: number | null;
  evaluatedAtSeconds: number | null;
  declinedAtSeconds: number | null;
  didStartAtSeconds: number | null;
  didEndAtSeconds: number | null;
  finalizedAtSeconds: number | null;
  isPassed: boolean;
  evaluationComment: string;
  rankEvaluation: RankEvaluation;
  elapsedTimeSeconds: number | null;
  isPublic: boolean | null;
  name: string;
  status: SpotStatus;
  memo: string | null;
  messageForCandidate: string | null;
  timeLimitSeconds: number;
  totalScore: number;
  customScore: number;
  willExpireAtSeconds: number | null;
  rank: number;
  numSubset: number;
  screening: { __typename?: "Screening"; screeningId: string; name: string; deletedAtSeconds: number | null } | null;
  evaluatedBy: {
    __typename?: "User";
    id: string;
    displayName: string;
    email: string;
    photoUrl: string;
    uid: string;
    isSystemAdmin: boolean;
  } | null;
  entities: Array<
    | {
        __typename?: "ChallengeEntity";
        id: string;
        challengeEntityId: number;
        enabledLanguages: Array<string>;
        challengeStatus: ChallengeStatus;
        challengeQuestion: {
          __typename?: "AlgorithmQuestion";
          id: string;
          questionId: number;
          version: string;
          titleJa: string | null;
          titleEn: string | null;
          descriptionEn: string | null;
          descriptionJa: string | null;
          difficulty: Difficulty;
          algorithmQuestionStatus: QuestionStatus;
        } | null;
      }
    | {
        __typename?: "ProjectEntity";
        id: string;
        projectEntityId: number;
        projectStatus: ProjectStatus;
        question: {
          __typename?: "ProjectQuestion";
          id: string;
          questionId: number;
          version: string;
          titleEn: string | null;
          titleJa: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          difficulty: Difficulty;
          projectQuestionStatus: ProjectQuestionStatus;
        } | null;
      }
    | {
        __typename?: "QuizEntity";
        id: string;
        quizEntityId: number;
        quizStatus: QuizStatus;
        pb_package: {
          __typename?: "MultiChoicePackage";
          id: string;
          packageId: number;
          version: string;
          titleEn: string | null;
          titleJa: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          difficulty: Difficulty;
          multiChoicePackageStatus: QuestionStatus;
        } | null;
      }
    | {
        __typename?: "SystemDesignEntity";
        id: string;
        systemDesignEntityId: number;
        componentTypes: Array<SystemDesignComponentType>;
        systemDesignStatus: SystemDesignStatus;
        question: {
          __typename?: "SystemDesignQuestion";
          id: string;
          questionId: number;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          difficulty: Difficulty;
          systemDesignQuestionStatus: SystemDesignQuestionStatus;
        } | null;
      }
  >;
  tags: Array<{ __typename: "SpotTag"; name: string }>;
  employee: {
    __typename?: "User";
    id: string;
    email: string;
    emailVerified: boolean;
    displayName: string;
    fullName: string;
    language: Language;
    photoUrl: string;
  } | null;
  recommenders: Array<{ __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string }>;
  reviewers: Array<{
    __typename: "SpotReviewer";
    type: ReviewerType;
    employee: {
      __typename?: "User";
      id: string;
      uid: string;
      displayName: string;
      email: string;
      photoUrl: string;
      isSystemAdmin: boolean;
    } | null;
    group: {
      __typename?: "EmployeeGroup";
      id: string;
      groupName: string;
      companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string; role: Role }>;
    } | null;
  }>;
};

export type GetInterviewDetailQueryVariables = Exact<{
  id: Scalars["String"]["input"];
  withAnswers: Scalars["Boolean"]["input"];
  ignoreInheritance: Scalars["Boolean"]["input"];
}>;

export type GetInterviewDetailQuery = {
  __typename?: "Query";
  spot: {
    __typename?: "Spot";
    id: string;
    originId: string;
    parentId: string;
    candidateName: string;
    candidateEmail: string;
    screeningId: string | null;
    createdAtSeconds: number | null;
    evaluatedAtSeconds: number | null;
    declinedAtSeconds: number | null;
    didStartAtSeconds: number | null;
    didEndAtSeconds: number | null;
    finalizedAtSeconds: number | null;
    isPassed: boolean;
    evaluationComment: string;
    rankEvaluation: RankEvaluation;
    elapsedTimeSeconds: number | null;
    isPublic: boolean | null;
    name: string;
    status: SpotStatus;
    memo: string | null;
    messageForCandidate: string | null;
    timeLimitSeconds: number;
    totalScore: number;
    customScore: number;
    willExpireAtSeconds: number | null;
    rank: number;
    numSubset: number;
    screening: { __typename?: "Screening"; screeningId: string; name: string; deletedAtSeconds: number | null } | null;
    evaluatedBy: {
      __typename?: "User";
      id: string;
      displayName: string;
      email: string;
      photoUrl: string;
      uid: string;
      isSystemAdmin: boolean;
    } | null;
    entities: Array<
      | {
          __typename?: "ChallengeEntity";
          id: string;
          challengeEntityId: number;
          enabledLanguages: Array<string>;
          challengeStatus: ChallengeStatus;
          challengeQuestion: {
            __typename?: "AlgorithmQuestion";
            id: string;
            questionId: number;
            version: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionEn: string | null;
            descriptionJa: string | null;
            difficulty: Difficulty;
            algorithmQuestionStatus: QuestionStatus;
          } | null;
        }
      | {
          __typename?: "ProjectEntity";
          id: string;
          projectEntityId: number;
          projectStatus: ProjectStatus;
          question: {
            __typename?: "ProjectQuestion";
            id: string;
            questionId: number;
            version: string;
            titleEn: string | null;
            titleJa: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            projectQuestionStatus: ProjectQuestionStatus;
          } | null;
        }
      | {
          __typename?: "QuizEntity";
          id: string;
          quizEntityId: number;
          quizStatus: QuizStatus;
          pb_package: {
            __typename?: "MultiChoicePackage";
            id: string;
            packageId: number;
            version: string;
            titleEn: string | null;
            titleJa: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            multiChoicePackageStatus: QuestionStatus;
          } | null;
        }
      | {
          __typename?: "SystemDesignEntity";
          id: string;
          systemDesignEntityId: number;
          componentTypes: Array<SystemDesignComponentType>;
          systemDesignStatus: SystemDesignStatus;
          question: {
            __typename?: "SystemDesignQuestion";
            id: string;
            questionId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            systemDesignQuestionStatus: SystemDesignQuestionStatus;
          } | null;
        }
    >;
    tags: Array<{ __typename: "SpotTag"; name: string }>;
    employee: {
      __typename?: "User";
      id: string;
      email: string;
      emailVerified: boolean;
      displayName: string;
      fullName: string;
      language: Language;
      photoUrl: string;
    } | null;
    recommenders: Array<{ __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string }>;
    reviewers: Array<{
      __typename: "SpotReviewer";
      type: ReviewerType;
      employee: {
        __typename?: "User";
        id: string;
        uid: string;
        displayName: string;
        email: string;
        photoUrl: string;
        isSystemAdmin: boolean;
      } | null;
      group: {
        __typename?: "EmployeeGroup";
        id: string;
        groupName: string;
        companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string; role: Role }>;
      } | null;
    }>;
  };
};

export type EvaluateInterviewMutationVariables = Exact<{
  spotId: Scalars["String"]["input"];
  employeeId: Scalars["String"]["input"];
  isPassed: Scalars["Boolean"]["input"];
  evaluationComment: Scalars["String"]["input"];
}>;

export type EvaluateInterviewMutation = {
  __typename?: "Mutation";
  evaluateSpot: {
    __typename?: "Spot";
    id: string;
    originId: string;
    parentId: string;
    candidateName: string;
    candidateEmail: string;
    screeningId: string | null;
    createdAtSeconds: number | null;
    evaluatedAtSeconds: number | null;
    declinedAtSeconds: number | null;
    didStartAtSeconds: number | null;
    didEndAtSeconds: number | null;
    finalizedAtSeconds: number | null;
    isPassed: boolean;
    evaluationComment: string;
    rankEvaluation: RankEvaluation;
    elapsedTimeSeconds: number | null;
    isPublic: boolean | null;
    name: string;
    status: SpotStatus;
    memo: string | null;
    messageForCandidate: string | null;
    timeLimitSeconds: number;
    totalScore: number;
    customScore: number;
    willExpireAtSeconds: number | null;
    rank: number;
    numSubset: number;
    screening: { __typename?: "Screening"; screeningId: string; name: string; deletedAtSeconds: number | null } | null;
    evaluatedBy: {
      __typename?: "User";
      id: string;
      displayName: string;
      email: string;
      photoUrl: string;
      uid: string;
      isSystemAdmin: boolean;
    } | null;
    entities: Array<
      | {
          __typename?: "ChallengeEntity";
          id: string;
          challengeEntityId: number;
          enabledLanguages: Array<string>;
          challengeStatus: ChallengeStatus;
          challengeQuestion: {
            __typename?: "AlgorithmQuestion";
            id: string;
            questionId: number;
            version: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionEn: string | null;
            descriptionJa: string | null;
            difficulty: Difficulty;
            algorithmQuestionStatus: QuestionStatus;
          } | null;
        }
      | {
          __typename?: "ProjectEntity";
          id: string;
          projectEntityId: number;
          projectStatus: ProjectStatus;
          question: {
            __typename?: "ProjectQuestion";
            id: string;
            questionId: number;
            version: string;
            titleEn: string | null;
            titleJa: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            projectQuestionStatus: ProjectQuestionStatus;
          } | null;
        }
      | {
          __typename?: "QuizEntity";
          id: string;
          quizEntityId: number;
          quizStatus: QuizStatus;
          pb_package: {
            __typename?: "MultiChoicePackage";
            id: string;
            packageId: number;
            version: string;
            titleEn: string | null;
            titleJa: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            multiChoicePackageStatus: QuestionStatus;
          } | null;
        }
      | {
          __typename?: "SystemDesignEntity";
          id: string;
          systemDesignEntityId: number;
          componentTypes: Array<SystemDesignComponentType>;
          systemDesignStatus: SystemDesignStatus;
          question: {
            __typename?: "SystemDesignQuestion";
            id: string;
            questionId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            systemDesignQuestionStatus: SystemDesignQuestionStatus;
          } | null;
        }
    >;
    tags: Array<{ __typename: "SpotTag"; name: string }>;
    employee: {
      __typename?: "User";
      id: string;
      email: string;
      emailVerified: boolean;
      displayName: string;
      fullName: string;
      language: Language;
      photoUrl: string;
    } | null;
    recommenders: Array<{ __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string }>;
    reviewers: Array<{
      __typename: "SpotReviewer";
      type: ReviewerType;
      employee: {
        __typename?: "User";
        id: string;
        uid: string;
        displayName: string;
        email: string;
        photoUrl: string;
        isSystemAdmin: boolean;
      } | null;
      group: {
        __typename?: "EmployeeGroup";
        id: string;
        groupName: string;
        companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string; role: Role }>;
      } | null;
    }>;
  } | null;
};

export type RecreateInterviewMutationVariables = Exact<{
  input: RecreateSpotInput;
}>;

export type RecreateInterviewMutation = {
  __typename?: "Mutation";
  recreateSpot: {
    __typename?: "Spot";
    id: string;
    originId: string;
    parentId: string;
    candidateName: string;
    candidateEmail: string;
    screeningId: string | null;
    createdAtSeconds: number | null;
    evaluatedAtSeconds: number | null;
    declinedAtSeconds: number | null;
    didStartAtSeconds: number | null;
    didEndAtSeconds: number | null;
    finalizedAtSeconds: number | null;
    isPassed: boolean;
    evaluationComment: string;
    rankEvaluation: RankEvaluation;
    elapsedTimeSeconds: number | null;
    isPublic: boolean | null;
    name: string;
    status: SpotStatus;
    memo: string | null;
    messageForCandidate: string | null;
    timeLimitSeconds: number;
    totalScore: number;
    customScore: number;
    willExpireAtSeconds: number | null;
    rank: number;
    numSubset: number;
    screening: { __typename?: "Screening"; screeningId: string; name: string; deletedAtSeconds: number | null } | null;
    evaluatedBy: {
      __typename?: "User";
      id: string;
      displayName: string;
      email: string;
      photoUrl: string;
      uid: string;
      isSystemAdmin: boolean;
    } | null;
    entities: Array<
      | {
          __typename?: "ChallengeEntity";
          id: string;
          challengeEntityId: number;
          enabledLanguages: Array<string>;
          challengeStatus: ChallengeStatus;
          challengeQuestion: {
            __typename?: "AlgorithmQuestion";
            id: string;
            questionId: number;
            version: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionEn: string | null;
            descriptionJa: string | null;
            difficulty: Difficulty;
            algorithmQuestionStatus: QuestionStatus;
          } | null;
        }
      | {
          __typename?: "ProjectEntity";
          id: string;
          projectEntityId: number;
          projectStatus: ProjectStatus;
          question: {
            __typename?: "ProjectQuestion";
            id: string;
            questionId: number;
            version: string;
            titleEn: string | null;
            titleJa: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            projectQuestionStatus: ProjectQuestionStatus;
          } | null;
        }
      | {
          __typename?: "QuizEntity";
          id: string;
          quizEntityId: number;
          quizStatus: QuizStatus;
          pb_package: {
            __typename?: "MultiChoicePackage";
            id: string;
            packageId: number;
            version: string;
            titleEn: string | null;
            titleJa: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            multiChoicePackageStatus: QuestionStatus;
          } | null;
        }
      | {
          __typename?: "SystemDesignEntity";
          id: string;
          systemDesignEntityId: number;
          componentTypes: Array<SystemDesignComponentType>;
          systemDesignStatus: SystemDesignStatus;
          question: {
            __typename?: "SystemDesignQuestion";
            id: string;
            questionId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            systemDesignQuestionStatus: SystemDesignQuestionStatus;
          } | null;
        }
    >;
    tags: Array<{ __typename: "SpotTag"; name: string }>;
    employee: {
      __typename?: "User";
      id: string;
      email: string;
      emailVerified: boolean;
      displayName: string;
      fullName: string;
      language: Language;
      photoUrl: string;
    } | null;
    recommenders: Array<{ __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string }>;
    reviewers: Array<{
      __typename: "SpotReviewer";
      type: ReviewerType;
      employee: {
        __typename?: "User";
        id: string;
        uid: string;
        displayName: string;
        email: string;
        photoUrl: string;
        isSystemAdmin: boolean;
      } | null;
      group: {
        __typename?: "EmployeeGroup";
        id: string;
        groupName: string;
        companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string; role: Role }>;
      } | null;
    }>;
  } | null;
};

export type DeleteInterviewMutationVariables = Exact<{
  spotId: Scalars["String"]["input"];
}>;

export type DeleteInterviewMutation = { __typename?: "Mutation"; deleteSpot: boolean };

export type ExportTestReportPdfForInterviewsIdDetailMutationVariables = Exact<{
  spotId: Scalars["String"]["input"];
  exportLanguage: ExportLanguage;
}>;

export type ExportTestReportPdfForInterviewsIdDetailMutation = {
  __typename?: "Mutation";
  exportTestReportPdf: { __typename?: "ExportPdfResponse"; id: string };
};

export type TestReportPdfForInterviewsIdDetailQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type TestReportPdfForInterviewsIdDetailQuery = {
  __typename?: "Query";
  testReportPdf: { __typename?: "ExportResponse"; status: ExportStatus; reason: string; downloadUrl: string };
};

export type SpotSharedLinkForScreeningsTestsIdDetailFragment = {
  __typename?: "SpotSharedLink";
  spotId: string;
  linkHash: string;
  showAnswer: boolean;
  showRelativeEvaluation: boolean;
  showPlayback: boolean;
  showRank: boolean;
  showReview: boolean;
  showScore: boolean;
  createdAtSeconds: number | null;
  updatedAtSeconds: number | null;
  deletedAtSeconds: number | null;
  expiredAtSeconds: number | null;
};

export type GenerateSpotSharedLinkMutationVariables = Exact<{
  input: GenerateSpotSharedLinkInput;
}>;

export type GenerateSpotSharedLinkMutation = {
  __typename?: "Mutation";
  generateSpotSharedLink: {
    __typename?: "SpotSharedLink";
    spotId: string;
    linkHash: string;
    showAnswer: boolean;
    showRelativeEvaluation: boolean;
    showPlayback: boolean;
    showRank: boolean;
    showReview: boolean;
    showScore: boolean;
    createdAtSeconds: number | null;
    updatedAtSeconds: number | null;
    deletedAtSeconds: number | null;
    expiredAtSeconds: number | null;
  };
};

export type UpdateSpotForInterviewsIdDetailMutationVariables = Exact<{
  input: UpdateSpotInput;
}>;

export type UpdateSpotForInterviewsIdDetailMutation = {
  __typename?: "Mutation";
  updateSpot: {
    __typename?: "Spot";
    id: string;
    originId: string;
    parentId: string;
    candidateName: string;
    candidateEmail: string;
    screeningId: string | null;
    createdAtSeconds: number | null;
    evaluatedAtSeconds: number | null;
    declinedAtSeconds: number | null;
    didStartAtSeconds: number | null;
    didEndAtSeconds: number | null;
    finalizedAtSeconds: number | null;
    isPassed: boolean;
    evaluationComment: string;
    rankEvaluation: RankEvaluation;
    elapsedTimeSeconds: number | null;
    isPublic: boolean | null;
    name: string;
    status: SpotStatus;
    memo: string | null;
    messageForCandidate: string | null;
    timeLimitSeconds: number;
    totalScore: number;
    customScore: number;
    willExpireAtSeconds: number | null;
    rank: number;
    numSubset: number;
    screening: { __typename?: "Screening"; screeningId: string; name: string; deletedAtSeconds: number | null } | null;
    evaluatedBy: {
      __typename?: "User";
      id: string;
      displayName: string;
      email: string;
      photoUrl: string;
      uid: string;
      isSystemAdmin: boolean;
    } | null;
    entities: Array<
      | {
          __typename?: "ChallengeEntity";
          id: string;
          challengeEntityId: number;
          enabledLanguages: Array<string>;
          challengeStatus: ChallengeStatus;
          challengeQuestion: {
            __typename?: "AlgorithmQuestion";
            id: string;
            questionId: number;
            version: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionEn: string | null;
            descriptionJa: string | null;
            difficulty: Difficulty;
            algorithmQuestionStatus: QuestionStatus;
          } | null;
        }
      | {
          __typename?: "ProjectEntity";
          id: string;
          projectEntityId: number;
          projectStatus: ProjectStatus;
          question: {
            __typename?: "ProjectQuestion";
            id: string;
            questionId: number;
            version: string;
            titleEn: string | null;
            titleJa: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            projectQuestionStatus: ProjectQuestionStatus;
          } | null;
        }
      | {
          __typename?: "QuizEntity";
          id: string;
          quizEntityId: number;
          quizStatus: QuizStatus;
          pb_package: {
            __typename?: "MultiChoicePackage";
            id: string;
            packageId: number;
            version: string;
            titleEn: string | null;
            titleJa: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            multiChoicePackageStatus: QuestionStatus;
          } | null;
        }
      | {
          __typename?: "SystemDesignEntity";
          id: string;
          systemDesignEntityId: number;
          componentTypes: Array<SystemDesignComponentType>;
          systemDesignStatus: SystemDesignStatus;
          question: {
            __typename?: "SystemDesignQuestion";
            id: string;
            questionId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            systemDesignQuestionStatus: SystemDesignQuestionStatus;
          } | null;
        }
    >;
    tags: Array<{ __typename: "SpotTag"; name: string }>;
    employee: {
      __typename?: "User";
      id: string;
      email: string;
      emailVerified: boolean;
      displayName: string;
      fullName: string;
      language: Language;
      photoUrl: string;
    } | null;
    recommenders: Array<{ __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string }>;
    reviewers: Array<{
      __typename: "SpotReviewer";
      type: ReviewerType;
      employee: {
        __typename?: "User";
        id: string;
        uid: string;
        displayName: string;
        email: string;
        photoUrl: string;
        isSystemAdmin: boolean;
      } | null;
      group: {
        __typename?: "EmployeeGroup";
        id: string;
        groupName: string;
        companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string; role: Role }>;
      } | null;
    }>;
  } | null;
};

export type ChallengeEntityForRetakeInterviewIdDetailFragment = {
  __typename: "ChallengeEntity";
  id: string;
  challengeEntityId: number;
  enabledLanguages: Array<string>;
  challengeStatus: ChallengeStatus;
  challengeQuestion: {
    __typename?: "AlgorithmQuestion";
    id: string;
    questionId: number;
    version: string;
    titleJa: string | null;
    titleEn: string | null;
    timeLimitSeconds: number | null;
  } | null;
};

export type QuizEntityForRetakeInterviewIdDetailFragment = {
  __typename: "QuizEntity";
  id: string;
  quizEntityId: number;
  quizStatus: QuizStatus;
  pb_package: {
    __typename?: "MultiChoicePackage";
    id: string;
    packageId: number;
    version: string;
    titleEn: string | null;
    titleJa: string | null;
    timeLimitSeconds: number | null;
  } | null;
};

export type ProjectEntityForRetakeInterviewIdDetailFragment = {
  __typename: "ProjectEntity";
  id: string;
  projectEntityId: number;
  projectStatus: ProjectStatus;
  question: {
    __typename?: "ProjectQuestion";
    id: string;
    questionId: number;
    version: string;
    titleEn: string | null;
    titleJa: string | null;
    timeLimitSeconds: number | null;
  } | null;
};

export type SystemDesignEntityForRetakeInterviewIdDetailFragment = {
  __typename: "SystemDesignEntity";
  id: string;
  systemDesignEntityId: number;
  componentTypes: Array<SystemDesignComponentType>;
  systemDesignStatus: SystemDesignStatus;
  question: {
    __typename?: "SystemDesignQuestion";
    id: string;
    questionId: number;
    titleJa: string | null;
    titleEn: string | null;
    timeLimitSeconds: number | null;
  } | null;
};

export type SpotForRetakeInterviewsIdDetailFragment = {
  __typename?: "Spot";
  id: string;
  spotId: string;
  timeLimitSeconds: number;
  elapsedTimeSeconds: number | null;
  entities: Array<
    | {
        __typename: "ChallengeEntity";
        id: string;
        challengeEntityId: number;
        enabledLanguages: Array<string>;
        challengeStatus: ChallengeStatus;
        challengeQuestion: {
          __typename?: "AlgorithmQuestion";
          id: string;
          questionId: number;
          version: string;
          titleJa: string | null;
          titleEn: string | null;
          timeLimitSeconds: number | null;
        } | null;
      }
    | {
        __typename: "ProjectEntity";
        id: string;
        projectEntityId: number;
        projectStatus: ProjectStatus;
        question: {
          __typename?: "ProjectQuestion";
          id: string;
          questionId: number;
          version: string;
          titleEn: string | null;
          titleJa: string | null;
          timeLimitSeconds: number | null;
        } | null;
      }
    | {
        __typename: "QuizEntity";
        id: string;
        quizEntityId: number;
        quizStatus: QuizStatus;
        pb_package: {
          __typename?: "MultiChoicePackage";
          id: string;
          packageId: number;
          version: string;
          titleEn: string | null;
          titleJa: string | null;
          timeLimitSeconds: number | null;
        } | null;
      }
    | {
        __typename: "SystemDesignEntity";
        id: string;
        systemDesignEntityId: number;
        componentTypes: Array<SystemDesignComponentType>;
        systemDesignStatus: SystemDesignStatus;
        question: {
          __typename?: "SystemDesignQuestion";
          id: string;
          questionId: number;
          titleJa: string | null;
          titleEn: string | null;
          timeLimitSeconds: number | null;
        } | null;
      }
  >;
};

export type GetSpotForRetakeInterviewsIdDetailQueryVariables = Exact<{
  id: Scalars["String"]["input"];
  withAnswers: Scalars["Boolean"]["input"];
  ignoreInheritance: Scalars["Boolean"]["input"];
}>;

export type GetSpotForRetakeInterviewsIdDetailQuery = {
  __typename?: "Query";
  spot: {
    __typename?: "Spot";
    id: string;
    spotId: string;
    timeLimitSeconds: number;
    elapsedTimeSeconds: number | null;
    entities: Array<
      | {
          __typename: "ChallengeEntity";
          id: string;
          challengeEntityId: number;
          enabledLanguages: Array<string>;
          challengeStatus: ChallengeStatus;
          challengeQuestion: {
            __typename?: "AlgorithmQuestion";
            id: string;
            questionId: number;
            version: string;
            titleJa: string | null;
            titleEn: string | null;
            timeLimitSeconds: number | null;
          } | null;
        }
      | {
          __typename: "ProjectEntity";
          id: string;
          projectEntityId: number;
          projectStatus: ProjectStatus;
          question: {
            __typename?: "ProjectQuestion";
            id: string;
            questionId: number;
            version: string;
            titleEn: string | null;
            titleJa: string | null;
            timeLimitSeconds: number | null;
          } | null;
        }
      | {
          __typename: "QuizEntity";
          id: string;
          quizEntityId: number;
          quizStatus: QuizStatus;
          pb_package: {
            __typename?: "MultiChoicePackage";
            id: string;
            packageId: number;
            version: string;
            titleEn: string | null;
            titleJa: string | null;
            timeLimitSeconds: number | null;
          } | null;
        }
      | {
          __typename: "SystemDesignEntity";
          id: string;
          systemDesignEntityId: number;
          componentTypes: Array<SystemDesignComponentType>;
          systemDesignStatus: SystemDesignStatus;
          question: {
            __typename?: "SystemDesignQuestion";
            id: string;
            questionId: number;
            titleJa: string | null;
            titleEn: string | null;
            timeLimitSeconds: number | null;
          } | null;
        }
    >;
  };
};

export type QuizEntityForEmployeeInterviewsIdReviewFragment = { __typename: "QuizEntity" };

export type ProjectEntityForEmployeeInterviewsIdReviewFragment = {
  __typename: "ProjectEntity";
  projectEntityId: number;
  appealMessage: string | null;
  agentVersion: string;
  projectStatus: ProjectStatus;
  question: {
    __typename?: "ProjectQuestion";
    questionId: number;
    version: string;
    projectVersion: string;
    variant: ProjectQuestionVariant;
    titleJa: string | null;
    titleEn: string | null;
    correctnessTestCase: string;
    performanceTestCase: string;
    defaultTestCase: string;
    descriptionJa: string | null;
    descriptionEn: string | null;
    timeLimitSeconds: number | null;
    fileIndexes: Array<string>;
    readOnlyFiles: Array<string>;
  } | null;
  submissions: Array<{ __typename?: "ProjectSubmission"; projectSubmissionId: number }>;
};

export type ChallengeEntityForEmployeeInterviewsIdReviewFragment = { __typename: "ChallengeEntity" };

export type SystemDesignEntityForEmployeeInterviewsIdReviewFragment = { __typename: "SystemDesignEntity" };

export type SpotForEmployeeInterviewsIdReviewFragment = {
  __typename?: "Spot";
  name: string;
  candidateName: string;
  status: SpotStatus;
  entities: Array<
    | { __typename: "ChallengeEntity" }
    | {
        __typename: "ProjectEntity";
        projectEntityId: number;
        appealMessage: string | null;
        agentVersion: string;
        projectStatus: ProjectStatus;
        question: {
          __typename?: "ProjectQuestion";
          questionId: number;
          version: string;
          projectVersion: string;
          variant: ProjectQuestionVariant;
          titleJa: string | null;
          titleEn: string | null;
          correctnessTestCase: string;
          performanceTestCase: string;
          defaultTestCase: string;
          descriptionJa: string | null;
          descriptionEn: string | null;
          timeLimitSeconds: number | null;
          fileIndexes: Array<string>;
          readOnlyFiles: Array<string>;
        } | null;
        submissions: Array<{ __typename?: "ProjectSubmission"; projectSubmissionId: number }>;
      }
    | { __typename: "QuizEntity" }
    | { __typename: "SystemDesignEntity" }
  >;
};

export type GetSpotForEmployeeInterviewsIdReviewQueryVariables = Exact<{
  id: Scalars["String"]["input"];
  withAnswers: Scalars["Boolean"]["input"];
}>;

export type GetSpotForEmployeeInterviewsIdReviewQuery = {
  __typename?: "Query";
  spot: {
    __typename?: "Spot";
    name: string;
    candidateName: string;
    status: SpotStatus;
    entities: Array<
      | { __typename: "ChallengeEntity" }
      | {
          __typename: "ProjectEntity";
          projectEntityId: number;
          appealMessage: string | null;
          agentVersion: string;
          projectStatus: ProjectStatus;
          question: {
            __typename?: "ProjectQuestion";
            questionId: number;
            version: string;
            projectVersion: string;
            variant: ProjectQuestionVariant;
            titleJa: string | null;
            titleEn: string | null;
            correctnessTestCase: string;
            performanceTestCase: string;
            defaultTestCase: string;
            descriptionJa: string | null;
            descriptionEn: string | null;
            timeLimitSeconds: number | null;
            fileIndexes: Array<string>;
            readOnlyFiles: Array<string>;
          } | null;
          submissions: Array<{ __typename?: "ProjectSubmission"; projectSubmissionId: number }>;
        }
      | { __typename: "QuizEntity" }
      | { __typename: "SystemDesignEntity" }
    >;
  };
};

export type CreateRemoteInterviewTemplateMutationVariables = Exact<{
  CreateRemoteInterviewTemplateInput: CreateRemoteInterviewTemplateInput;
}>;

export type CreateRemoteInterviewTemplateMutation = {
  __typename?: "Mutation";
  createRemoteInterviewTemplate: { __typename?: "RemoteInterviewTemplate"; id: string; templateId: number };
};

export type UpdateRemoteInterviewTemplateMutationVariables = Exact<{
  UpdateRemoteInterviewTemplateInput: UpdateRemoteInterviewTemplateInput;
}>;

export type UpdateRemoteInterviewTemplateMutation = {
  __typename?: "Mutation";
  updateRemoteInterviewTemplate: { __typename?: "RemoteInterviewTemplate"; id: string; templateId: number };
};

export type ExamForAssessmentsIdFragment = {
  __typename?: "Exam";
  examId: string;
  messageForTalent: string | null;
  status: ExamStatus;
  timeLimitSeconds: number | null;
  willEndAtSeconds: number | null;
  didStartAtSeconds: number | null;
  entityCount: number;
  company: { __typename?: "Company"; name: string } | null;
  entities: Array<
    | {
        __typename: "ChallengeEntity";
        challengeEntityId: number;
        questionVersion: string;
        enabledLanguages: Array<string>;
        appealMessage: string | null;
        challengeStatus: ChallengeStatus;
        challengeQuestion: {
          __typename?: "AlgorithmQuestion";
          id: string;
          questionId: number;
          version: string;
          variant: AlgorithmQuestionVariant;
          companyId: number;
          employeeId: string;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          difficulty: Difficulty;
          supportedLanguages: Array<string>;
          signature: string;
          correctnessTestCase: string;
          performanceTestCase: string;
          performanceTimeLimitMilliSeconds: number;
          hints: Array<{
            __typename?: "AlgorithmHint";
            id: number;
            questionId: number;
            questionVersion: string;
            descriptionJa: string | null;
            descriptionEn: string | null;
            orderNum: number;
          }>;
          initialCode: {
            __typename?: "AlgorithmInitialCode";
            c: string;
            cpp: string;
            csharp: string;
            dart: string;
            go: string;
            java: string;
            javascript: string;
            kotlin: string;
            mysql: string;
            perl: string;
            pgsql: string;
            php: string;
            python3: string;
            ruby: string;
            rust: string;
            scala: string;
            sqlite: string;
            swift: string;
            typescript: string;
          } | null;
        } | null;
        submissions: Array<{ __typename?: "ChallengeSubmission"; challengeSubmissionId: number }>;
      }
    | {
        __typename: "ProjectEntity";
        projectEntityId: number;
        appealMessage: string | null;
        agentVersion: string;
        projectStatus: ProjectStatus;
        question: {
          __typename?: "ProjectQuestion";
          questionId: number;
          version: string;
          projectVersion: string;
          variant: ProjectQuestionVariant;
          titleJa: string | null;
          titleEn: string | null;
          correctnessTestCase: string;
          performanceTestCase: string;
          defaultTestCase: string;
          descriptionJa: string | null;
          descriptionEn: string | null;
          timeLimitSeconds: number | null;
          fileIndexes: Array<string>;
          readOnlyFiles: Array<string>;
        } | null;
        submissions: Array<{ __typename?: "ProjectSubmission"; projectSubmissionId: number }>;
      }
    | {
        __typename: "QuizEntity";
        quizEntityId: number;
        packageVersion: string;
        quizStatus: QuizStatus;
        pb_package: {
          __typename?: "MultiChoicePackage";
          packageId: number;
          version: string;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          questions: Array<{
            __typename?: "MultiChoiceQuestion";
            id: number;
            version: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            variant: MultiChoiceQuestionVariant;
            options: Array<{
              __typename?: "MultiChoiceOption";
              id: number;
              titleJa: string | null;
              titleEn: string | null;
              isCorrect: boolean;
            }>;
          }>;
        } | null;
        submissions: Array<{
          __typename?: "QuizSubmission";
          quizSubmissionId: number;
          questionId: number;
          questionVersion: string;
          optionIds: Array<number>;
        }>;
      }
    | {
        __typename: "SystemDesignEntity";
        systemDesignEntityId: number;
        status: SystemDesignStatus;
        companyId: number;
        candidateId: string;
        employeeId: string;
        appealMessage: string | null;
        componentTypes: Array<SystemDesignComponentType>;
        question: {
          __typename?: "SystemDesignQuestion";
          questionId: number;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          initialSnapshot: string;
          hints: Array<{
            __typename?: "SystemDesignHint";
            systemDesignHintId: number;
            descriptionJa: string | null;
            descriptionEn: string | null;
          }>;
          scoringItems: Array<{
            __typename?: "SystemDesignScoringItem";
            systemDesignScoringItemId: number;
            category: SystemDesignScoringItemCategory;
            isHidden: boolean;
          }>;
        } | null;
        submissions: Array<{ __typename?: "SystemDesignSubmission"; systemDesignSubmissionId: number; snapshot: string }>;
      }
  >;
};

export type GetExamForAssessmentsIdQueryVariables = Exact<{
  examId: Scalars["String"]["input"];
}>;

export type GetExamForAssessmentsIdQuery = {
  __typename?: "Query";
  exam: {
    __typename?: "Exam";
    examId: string;
    messageForTalent: string | null;
    status: ExamStatus;
    timeLimitSeconds: number | null;
    willEndAtSeconds: number | null;
    didStartAtSeconds: number | null;
    entityCount: number;
    company: { __typename?: "Company"; name: string } | null;
    entities: Array<
      | {
          __typename: "ChallengeEntity";
          challengeEntityId: number;
          questionVersion: string;
          enabledLanguages: Array<string>;
          appealMessage: string | null;
          challengeStatus: ChallengeStatus;
          challengeQuestion: {
            __typename?: "AlgorithmQuestion";
            id: string;
            questionId: number;
            version: string;
            variant: AlgorithmQuestionVariant;
            companyId: number;
            employeeId: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            supportedLanguages: Array<string>;
            signature: string;
            correctnessTestCase: string;
            performanceTestCase: string;
            performanceTimeLimitMilliSeconds: number;
            hints: Array<{
              __typename?: "AlgorithmHint";
              id: number;
              questionId: number;
              questionVersion: string;
              descriptionJa: string | null;
              descriptionEn: string | null;
              orderNum: number;
            }>;
            initialCode: {
              __typename?: "AlgorithmInitialCode";
              c: string;
              cpp: string;
              csharp: string;
              dart: string;
              go: string;
              java: string;
              javascript: string;
              kotlin: string;
              mysql: string;
              perl: string;
              pgsql: string;
              php: string;
              python3: string;
              ruby: string;
              rust: string;
              scala: string;
              sqlite: string;
              swift: string;
              typescript: string;
            } | null;
          } | null;
          submissions: Array<{ __typename?: "ChallengeSubmission"; challengeSubmissionId: number }>;
        }
      | {
          __typename: "ProjectEntity";
          projectEntityId: number;
          appealMessage: string | null;
          agentVersion: string;
          projectStatus: ProjectStatus;
          question: {
            __typename?: "ProjectQuestion";
            questionId: number;
            version: string;
            projectVersion: string;
            variant: ProjectQuestionVariant;
            titleJa: string | null;
            titleEn: string | null;
            correctnessTestCase: string;
            performanceTestCase: string;
            defaultTestCase: string;
            descriptionJa: string | null;
            descriptionEn: string | null;
            timeLimitSeconds: number | null;
            fileIndexes: Array<string>;
            readOnlyFiles: Array<string>;
          } | null;
          submissions: Array<{ __typename?: "ProjectSubmission"; projectSubmissionId: number }>;
        }
      | {
          __typename: "QuizEntity";
          quizEntityId: number;
          packageVersion: string;
          quizStatus: QuizStatus;
          pb_package: {
            __typename?: "MultiChoicePackage";
            packageId: number;
            version: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            questions: Array<{
              __typename?: "MultiChoiceQuestion";
              id: number;
              version: string;
              titleJa: string | null;
              titleEn: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
              variant: MultiChoiceQuestionVariant;
              options: Array<{
                __typename?: "MultiChoiceOption";
                id: number;
                titleJa: string | null;
                titleEn: string | null;
                isCorrect: boolean;
              }>;
            }>;
          } | null;
          submissions: Array<{
            __typename?: "QuizSubmission";
            quizSubmissionId: number;
            questionId: number;
            questionVersion: string;
            optionIds: Array<number>;
          }>;
        }
      | {
          __typename: "SystemDesignEntity";
          systemDesignEntityId: number;
          status: SystemDesignStatus;
          companyId: number;
          candidateId: string;
          employeeId: string;
          appealMessage: string | null;
          componentTypes: Array<SystemDesignComponentType>;
          question: {
            __typename?: "SystemDesignQuestion";
            questionId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            initialSnapshot: string;
            hints: Array<{
              __typename?: "SystemDesignHint";
              systemDesignHintId: number;
              descriptionJa: string | null;
              descriptionEn: string | null;
            }>;
            scoringItems: Array<{
              __typename?: "SystemDesignScoringItem";
              systemDesignScoringItemId: number;
              category: SystemDesignScoringItemCategory;
              isHidden: boolean;
            }>;
          } | null;
          submissions: Array<{ __typename?: "SystemDesignSubmission"; systemDesignSubmissionId: number; snapshot: string }>;
        }
    >;
  };
};

export type ExamForEntryAssessmentsIdFragment = {
  __typename?: "Exam";
  examId: string;
  messageForTalent: string | null;
  status: ExamStatus;
  timeLimitSeconds: number | null;
  willEndAtSeconds: number | null;
  didStartAtSeconds: number | null;
  entityCount: number;
  company: { __typename?: "Company"; name: string } | null;
};

export type GetExamForEntryAssessmentsIdQueryVariables = Exact<{
  examId: Scalars["String"]["input"];
}>;

export type GetExamForEntryAssessmentsIdQuery = {
  __typename?: "Query";
  exam: {
    __typename?: "Exam";
    examId: string;
    messageForTalent: string | null;
    status: ExamStatus;
    timeLimitSeconds: number | null;
    willEndAtSeconds: number | null;
    didStartAtSeconds: number | null;
    entityCount: number;
    company: { __typename?: "Company"; name: string } | null;
  };
};

export type StartExamMutationVariables = Exact<{
  input: StartExamRequestInput;
}>;

export type StartExamMutation = {
  __typename?: "Mutation";
  startExam: {
    __typename?: "Exam";
    examId: string;
    messageForTalent: string | null;
    status: ExamStatus;
    timeLimitSeconds: number | null;
    willEndAtSeconds: number | null;
    didStartAtSeconds: number | null;
    entityCount: number;
    company: { __typename?: "Company"; name: string } | null;
    entities: Array<
      | {
          __typename: "ChallengeEntity";
          challengeEntityId: number;
          questionVersion: string;
          enabledLanguages: Array<string>;
          appealMessage: string | null;
          challengeStatus: ChallengeStatus;
          challengeQuestion: {
            __typename?: "AlgorithmQuestion";
            id: string;
            questionId: number;
            version: string;
            variant: AlgorithmQuestionVariant;
            companyId: number;
            employeeId: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            supportedLanguages: Array<string>;
            signature: string;
            correctnessTestCase: string;
            performanceTestCase: string;
            performanceTimeLimitMilliSeconds: number;
            hints: Array<{
              __typename?: "AlgorithmHint";
              id: number;
              questionId: number;
              questionVersion: string;
              descriptionJa: string | null;
              descriptionEn: string | null;
              orderNum: number;
            }>;
            initialCode: {
              __typename?: "AlgorithmInitialCode";
              c: string;
              cpp: string;
              csharp: string;
              dart: string;
              go: string;
              java: string;
              javascript: string;
              kotlin: string;
              mysql: string;
              perl: string;
              pgsql: string;
              php: string;
              python3: string;
              ruby: string;
              rust: string;
              scala: string;
              sqlite: string;
              swift: string;
              typescript: string;
            } | null;
          } | null;
          submissions: Array<{ __typename?: "ChallengeSubmission"; challengeSubmissionId: number }>;
        }
      | {
          __typename: "ProjectEntity";
          projectEntityId: number;
          appealMessage: string | null;
          agentVersion: string;
          projectStatus: ProjectStatus;
          question: {
            __typename?: "ProjectQuestion";
            questionId: number;
            version: string;
            projectVersion: string;
            variant: ProjectQuestionVariant;
            titleJa: string | null;
            titleEn: string | null;
            correctnessTestCase: string;
            performanceTestCase: string;
            defaultTestCase: string;
            descriptionJa: string | null;
            descriptionEn: string | null;
            timeLimitSeconds: number | null;
            fileIndexes: Array<string>;
            readOnlyFiles: Array<string>;
          } | null;
          submissions: Array<{ __typename?: "ProjectSubmission"; projectSubmissionId: number }>;
        }
      | {
          __typename: "QuizEntity";
          quizEntityId: number;
          packageVersion: string;
          quizStatus: QuizStatus;
          pb_package: {
            __typename?: "MultiChoicePackage";
            packageId: number;
            version: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            questions: Array<{
              __typename?: "MultiChoiceQuestion";
              id: number;
              version: string;
              titleJa: string | null;
              titleEn: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
              variant: MultiChoiceQuestionVariant;
              options: Array<{
                __typename?: "MultiChoiceOption";
                id: number;
                titleJa: string | null;
                titleEn: string | null;
                isCorrect: boolean;
              }>;
            }>;
          } | null;
          submissions: Array<{
            __typename?: "QuizSubmission";
            quizSubmissionId: number;
            questionId: number;
            questionVersion: string;
            optionIds: Array<number>;
          }>;
        }
      | {
          __typename: "SystemDesignEntity";
          systemDesignEntityId: number;
          status: SystemDesignStatus;
          companyId: number;
          candidateId: string;
          employeeId: string;
          appealMessage: string | null;
          componentTypes: Array<SystemDesignComponentType>;
          question: {
            __typename?: "SystemDesignQuestion";
            questionId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            initialSnapshot: string;
            hints: Array<{
              __typename?: "SystemDesignHint";
              systemDesignHintId: number;
              descriptionJa: string | null;
              descriptionEn: string | null;
            }>;
            scoringItems: Array<{
              __typename?: "SystemDesignScoringItem";
              systemDesignScoringItemId: number;
              category: SystemDesignScoringItemCategory;
              isHidden: boolean;
            }>;
          } | null;
          submissions: Array<{ __typename?: "SystemDesignSubmission"; systemDesignSubmissionId: number; snapshot: string }>;
        }
    >;
  };
};

export type EndExamMutationVariables = Exact<{
  input: EndExamRequestInput;
}>;

export type EndExamMutation = {
  __typename?: "Mutation";
  endExam: {
    __typename?: "Exam";
    examId: string;
    messageForTalent: string | null;
    status: ExamStatus;
    timeLimitSeconds: number | null;
    willEndAtSeconds: number | null;
    didStartAtSeconds: number | null;
    entityCount: number;
    company: { __typename?: "Company"; name: string } | null;
    entities: Array<
      | {
          __typename: "ChallengeEntity";
          challengeEntityId: number;
          questionVersion: string;
          enabledLanguages: Array<string>;
          appealMessage: string | null;
          challengeStatus: ChallengeStatus;
          challengeQuestion: {
            __typename?: "AlgorithmQuestion";
            id: string;
            questionId: number;
            version: string;
            variant: AlgorithmQuestionVariant;
            companyId: number;
            employeeId: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            supportedLanguages: Array<string>;
            signature: string;
            correctnessTestCase: string;
            performanceTestCase: string;
            performanceTimeLimitMilliSeconds: number;
            hints: Array<{
              __typename?: "AlgorithmHint";
              id: number;
              questionId: number;
              questionVersion: string;
              descriptionJa: string | null;
              descriptionEn: string | null;
              orderNum: number;
            }>;
            initialCode: {
              __typename?: "AlgorithmInitialCode";
              c: string;
              cpp: string;
              csharp: string;
              dart: string;
              go: string;
              java: string;
              javascript: string;
              kotlin: string;
              mysql: string;
              perl: string;
              pgsql: string;
              php: string;
              python3: string;
              ruby: string;
              rust: string;
              scala: string;
              sqlite: string;
              swift: string;
              typescript: string;
            } | null;
          } | null;
          submissions: Array<{ __typename?: "ChallengeSubmission"; challengeSubmissionId: number }>;
        }
      | {
          __typename: "ProjectEntity";
          projectEntityId: number;
          appealMessage: string | null;
          agentVersion: string;
          projectStatus: ProjectStatus;
          question: {
            __typename?: "ProjectQuestion";
            questionId: number;
            version: string;
            projectVersion: string;
            variant: ProjectQuestionVariant;
            titleJa: string | null;
            titleEn: string | null;
            correctnessTestCase: string;
            performanceTestCase: string;
            defaultTestCase: string;
            descriptionJa: string | null;
            descriptionEn: string | null;
            timeLimitSeconds: number | null;
            fileIndexes: Array<string>;
            readOnlyFiles: Array<string>;
          } | null;
          submissions: Array<{ __typename?: "ProjectSubmission"; projectSubmissionId: number }>;
        }
      | {
          __typename: "QuizEntity";
          quizEntityId: number;
          packageVersion: string;
          quizStatus: QuizStatus;
          pb_package: {
            __typename?: "MultiChoicePackage";
            packageId: number;
            version: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            questions: Array<{
              __typename?: "MultiChoiceQuestion";
              id: number;
              version: string;
              titleJa: string | null;
              titleEn: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
              variant: MultiChoiceQuestionVariant;
              options: Array<{
                __typename?: "MultiChoiceOption";
                id: number;
                titleJa: string | null;
                titleEn: string | null;
                isCorrect: boolean;
              }>;
            }>;
          } | null;
          submissions: Array<{
            __typename?: "QuizSubmission";
            quizSubmissionId: number;
            questionId: number;
            questionVersion: string;
            optionIds: Array<number>;
          }>;
        }
      | {
          __typename: "SystemDesignEntity";
          systemDesignEntityId: number;
          status: SystemDesignStatus;
          companyId: number;
          candidateId: string;
          employeeId: string;
          appealMessage: string | null;
          componentTypes: Array<SystemDesignComponentType>;
          question: {
            __typename?: "SystemDesignQuestion";
            questionId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            initialSnapshot: string;
            hints: Array<{
              __typename?: "SystemDesignHint";
              systemDesignHintId: number;
              descriptionJa: string | null;
              descriptionEn: string | null;
            }>;
            scoringItems: Array<{
              __typename?: "SystemDesignScoringItem";
              systemDesignScoringItemId: number;
              category: SystemDesignScoringItemCategory;
              isHidden: boolean;
            }>;
          } | null;
          submissions: Array<{ __typename?: "SystemDesignSubmission"; systemDesignSubmissionId: number; snapshot: string }>;
        }
    >;
  };
};

export type AssessmentForDetailTalentFragment = {
  __typename?: "Assessment";
  id: string;
  assessmentId: string;
  createdAtSeconds: number | null;
  nextScheduleAtSeconds: number | null;
  examInterval: AssessmentExamInterval;
  rootExamIds: Array<string>;
  isPublic: boolean;
  name: string;
  memo: string | null;
  messageForTalent: string | null;
  timeLimitSeconds: number | null;
  status: AssessmentStatus;
  reportSettings: {
    __typename?: "AssessmentReportSettings";
    showAnswer: boolean;
    showPlayback: boolean;
    showRelativeEvaluation: boolean;
  } | null;
  questions: Array<
    | { __typename: "AlgorithmQuestion" }
    | { __typename: "MultiChoicePackage" }
    | { __typename: "ProjectQuestion" }
    | { __typename: "SystemDesignQuestion" }
  >;
  employee: { __typename?: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string; language: Language } | null;
  talent: { __typename?: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string; language: Language };
  viewers: Array<
    | {
        __typename: "EmployeeGroup";
        id: string;
        groupName: string;
        companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string; role: Role }>;
      }
    | { __typename: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string; language: Language }
  >;
};

export type ExamForAssessmentsIdDetailTalentFragment = {
  __typename: "Exam";
  id: string;
  examId: string;
  assessmentId: string;
  originId: string;
  numInterval: number;
  numTry: number;
  startIntervalAtSeconds: number | null;
  endIntervalAtSeconds: number | null;
  relativeScore: number;
  totalScore: number;
  status: ExamStatus;
  createdAtSeconds: number | null;
  willEndAtSeconds: number | null;
};

export type ListBestExamsResponseForAssessmentsIdDetailTalentFragment = {
  __typename?: "ListBestExamsByAssessmentId";
  count: number;
  hasNext: boolean;
  exams: Array<{
    __typename: "Exam";
    id: string;
    examId: string;
    assessmentId: string;
    originId: string;
    numInterval: number;
    numTry: number;
    startIntervalAtSeconds: number | null;
    endIntervalAtSeconds: number | null;
    relativeScore: number;
    totalScore: number;
    status: ExamStatus;
    createdAtSeconds: number | null;
    willEndAtSeconds: number | null;
  }>;
};

export type GetAssessmentForAssessmentsIdDetailTalentQueryVariables = Exact<{
  assessmentId: Scalars["String"]["input"];
  withCount: Scalars["Boolean"]["input"];
  size: Scalars["Int"]["input"];
  offset: Scalars["Int"]["input"];
  isDescending: Scalars["Boolean"]["input"];
}>;

export type GetAssessmentForAssessmentsIdDetailTalentQuery = {
  __typename?: "Query";
  assessment: {
    __typename?: "Assessment";
    id: string;
    assessmentId: string;
    createdAtSeconds: number | null;
    nextScheduleAtSeconds: number | null;
    examInterval: AssessmentExamInterval;
    rootExamIds: Array<string>;
    isPublic: boolean;
    name: string;
    memo: string | null;
    messageForTalent: string | null;
    timeLimitSeconds: number | null;
    status: AssessmentStatus;
    reportSettings: {
      __typename?: "AssessmentReportSettings";
      showAnswer: boolean;
      showPlayback: boolean;
      showRelativeEvaluation: boolean;
    } | null;
    questions: Array<
      | { __typename: "AlgorithmQuestion" }
      | { __typename: "MultiChoicePackage" }
      | { __typename: "ProjectQuestion" }
      | { __typename: "SystemDesignQuestion" }
    >;
    employee: { __typename?: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string; language: Language } | null;
    talent: { __typename?: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string; language: Language };
    viewers: Array<
      | {
          __typename: "EmployeeGroup";
          id: string;
          groupName: string;
          companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string; role: Role }>;
        }
      | { __typename: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string; language: Language }
    >;
  };
  listBestExamsByAssessmentId: {
    __typename?: "ListBestExamsByAssessmentId";
    count: number;
    hasNext: boolean;
    exams: Array<{
      __typename: "Exam";
      id: string;
      examId: string;
      assessmentId: string;
      originId: string;
      numInterval: number;
      numTry: number;
      startIntervalAtSeconds: number | null;
      endIntervalAtSeconds: number | null;
      relativeScore: number;
      totalScore: number;
      status: ExamStatus;
      createdAtSeconds: number | null;
      willEndAtSeconds: number | null;
    }>;
  };
};

export type ListExamsRecursivePayloadForAssessmentsIdDetailTalentFragment = {
  __typename?: "ListExamsRecursivePayload";
  bestExamId: string;
  exams: Array<{
    __typename: "Exam";
    id: string;
    examId: string;
    assessmentId: string;
    originId: string;
    numInterval: number;
    numTry: number;
    startIntervalAtSeconds: number | null;
    endIntervalAtSeconds: number | null;
    relativeScore: number;
    totalScore: number;
    status: ExamStatus;
    createdAtSeconds: number | null;
    willEndAtSeconds: number | null;
  }>;
};

export type ListExamsRecursiveForAssessmentsIdDetailTalentQueryVariables = Exact<{
  examId: Scalars["String"]["input"];
}>;

export type ListExamsRecursiveForAssessmentsIdDetailTalentQuery = {
  __typename?: "Query";
  listExamsRecursive: {
    __typename?: "ListExamsRecursivePayload";
    bestExamId: string;
    exams: Array<{
      __typename: "Exam";
      id: string;
      examId: string;
      assessmentId: string;
      originId: string;
      numInterval: number;
      numTry: number;
      startIntervalAtSeconds: number | null;
      endIntervalAtSeconds: number | null;
      relativeScore: number;
      totalScore: number;
      status: ExamStatus;
      createdAtSeconds: number | null;
      willEndAtSeconds: number | null;
    }>;
  };
};

export type RecreateExamForAssessmentsIdDetailTalentMutationVariables = Exact<{
  input: RecreateExamRequestInput;
}>;

export type RecreateExamForAssessmentsIdDetailTalentMutation = {
  __typename?: "Mutation";
  recreateExam: { __typename: "Exam"; examId: string };
};

export type GetTailExamForAssessmentIdDetailTalentQueryVariables = Exact<{
  examId: Scalars["String"]["input"];
}>;

export type GetTailExamForAssessmentIdDetailTalentQuery = {
  __typename?: "Query";
  tailExam: { __typename: "Exam"; examId: string; status: ExamStatus };
};

export type AcceptTalentInvitationMutationVariables = Exact<{
  input: AcceptTalentInvitationInput;
}>;

export type AcceptTalentInvitationMutation = {
  __typename?: "Mutation";
  acceptTalentInvitation: { __typename?: "TalentInvitation"; talentInvitationId: string; companyId: number };
};

export type InvitationForTalentInvitationsIdFragment = { __typename?: "TalentInvitation"; id: string; expiresAtSeconds: number | null };

export type CompanyTalentForTalentInvitationsIdFragment = { __typename?: "CompanyTalent"; companyId: number; talentId: string };

export type CompanyForTalentInvitationsIdFragment = {
  __typename?: "Company";
  name: string;
  companyId: number;
  companyTalents: Array<{ __typename?: "CompanyTalent"; companyId: number; talentId: string }>;
};

export type GetCompanyByTalentInvitationForTalentInvitationIdQueryVariables = Exact<{
  invitationId: Scalars["String"]["input"];
}>;

export type GetCompanyByTalentInvitationForTalentInvitationIdQuery = {
  __typename?: "Query";
  companyByTalentInvitationId: {
    __typename?: "Company";
    name: string;
    companyId: number;
    companyTalents: Array<{ __typename?: "CompanyTalent"; companyId: number; talentId: string }>;
  };
  talentInvitation: { __typename?: "TalentInvitation"; id: string; expiresAtSeconds: number | null };
};

export type AlgorithmHintForAlgorithmResourceEditorFragment = {
  __typename?: "AlgorithmHint";
  id: number;
  uniqueId: string;
  questionId: number;
  questionVersion: string;
  descriptionJa: string | null;
  descriptionEn: string | null;
  orderNum: number;
};

export type AlgorithmAnswerRuntimeForAlgorithmResourceEditorFragment = {
  __typename?: "AlgorithmAnswerRuntime";
  id: number;
  answerId: number;
  machineCpu: number;
  machineMemory: string;
  machineOs: string;
  readability: number;
  stats: string;
  runtime: string;
  codeBody: string;
};

export type AlgorithmAnswerForAlgorithmResourceEditorFragment = {
  __typename?: "AlgorithmAnswer";
  id: number;
  uniqueId: string;
  questionId: number;
  questionVersion: string;
  titleEn: string | null;
  titleJa: string | null;
  label: string;
  descriptionEn: string | null;
  descriptionJa: string | null;
  runtimeComplexity: string;
  spaceComplexity: string;
  answerRuntimes: Array<{
    __typename?: "AlgorithmAnswerRuntime";
    id: number;
    answerId: number;
    machineCpu: number;
    machineMemory: string;
    machineOs: string;
    readability: number;
    stats: string;
    runtime: string;
    codeBody: string;
  }>;
};

export type AlgorithmQuestionForAlgorithmResourceEditorFragment = {
  __typename?: "AlgorithmQuestion";
  id: string;
  questionId: number;
  slug: string;
  version: string;
  variant: AlgorithmQuestionVariant;
  companyId: number;
  employeeId: string;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  difficulty: Difficulty;
  timeLimitSeconds: number | null;
  isPrivate: boolean;
  status: QuestionStatus;
  supportedLanguages: Array<string>;
  signature: string;
  correctnessTestCase: string;
  performanceTestCase: string;
  correctnessTestCaseIds: Array<number>;
  performanceTestCaseIds: Array<number>;
  performanceTimeLimitMilliSeconds: number;
  isOfficial: boolean;
  numUses: number;
  accuracyRate: number;
  averageElapsedTimeSeconds: number;
  answers: Array<{
    __typename?: "AlgorithmAnswer";
    id: number;
    uniqueId: string;
    questionId: number;
    questionVersion: string;
    titleEn: string | null;
    titleJa: string | null;
    label: string;
    descriptionEn: string | null;
    descriptionJa: string | null;
    runtimeComplexity: string;
    spaceComplexity: string;
    answerRuntimes: Array<{
      __typename?: "AlgorithmAnswerRuntime";
      id: number;
      answerId: number;
      machineCpu: number;
      machineMemory: string;
      machineOs: string;
      readability: number;
      stats: string;
      runtime: string;
      codeBody: string;
    }>;
  }>;
  hints: Array<{
    __typename?: "AlgorithmHint";
    id: number;
    uniqueId: string;
    questionId: number;
    questionVersion: string;
    descriptionJa: string | null;
    descriptionEn: string | null;
    orderNum: number;
  }>;
};

export type CreateAlgorithmQuestionMutationVariables = Exact<{
  createQuestionInput: CreateAlgorithmQuestionInput;
}>;

export type CreateAlgorithmQuestionMutation = {
  __typename?: "Mutation";
  createAlgorithmQuestion: {
    __typename?: "AlgorithmQuestion";
    id: string;
    questionId: number;
    slug: string;
    version: string;
    variant: AlgorithmQuestionVariant;
    companyId: number;
    employeeId: string;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    difficulty: Difficulty;
    timeLimitSeconds: number | null;
    isPrivate: boolean;
    status: QuestionStatus;
    supportedLanguages: Array<string>;
    signature: string;
    correctnessTestCase: string;
    performanceTestCase: string;
    correctnessTestCaseIds: Array<number>;
    performanceTestCaseIds: Array<number>;
    performanceTimeLimitMilliSeconds: number;
    isOfficial: boolean;
    numUses: number;
    accuracyRate: number;
    averageElapsedTimeSeconds: number;
    answers: Array<{
      __typename?: "AlgorithmAnswer";
      id: number;
      uniqueId: string;
      questionId: number;
      questionVersion: string;
      titleEn: string | null;
      titleJa: string | null;
      label: string;
      descriptionEn: string | null;
      descriptionJa: string | null;
      runtimeComplexity: string;
      spaceComplexity: string;
      answerRuntimes: Array<{
        __typename?: "AlgorithmAnswerRuntime";
        id: number;
        answerId: number;
        machineCpu: number;
        machineMemory: string;
        machineOs: string;
        readability: number;
        stats: string;
        runtime: string;
        codeBody: string;
      }>;
    }>;
    hints: Array<{
      __typename?: "AlgorithmHint";
      id: number;
      uniqueId: string;
      questionId: number;
      questionVersion: string;
      descriptionJa: string | null;
      descriptionEn: string | null;
      orderNum: number;
    }>;
  };
};

export type UpdateAlgorithmQuestionMutationVariables = Exact<{
  updateQuestionInput: UpdateAlgorithmQuestionInput;
}>;

export type UpdateAlgorithmQuestionMutation = {
  __typename?: "Mutation";
  updateAlgorithmQuestion: {
    __typename?: "AlgorithmQuestion";
    id: string;
    questionId: number;
    slug: string;
    version: string;
    variant: AlgorithmQuestionVariant;
    companyId: number;
    employeeId: string;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    difficulty: Difficulty;
    timeLimitSeconds: number | null;
    isPrivate: boolean;
    status: QuestionStatus;
    supportedLanguages: Array<string>;
    signature: string;
    correctnessTestCase: string;
    performanceTestCase: string;
    correctnessTestCaseIds: Array<number>;
    performanceTestCaseIds: Array<number>;
    performanceTimeLimitMilliSeconds: number;
    isOfficial: boolean;
    numUses: number;
    accuracyRate: number;
    averageElapsedTimeSeconds: number;
    answers: Array<{
      __typename?: "AlgorithmAnswer";
      id: number;
      uniqueId: string;
      questionId: number;
      questionVersion: string;
      titleEn: string | null;
      titleJa: string | null;
      label: string;
      descriptionEn: string | null;
      descriptionJa: string | null;
      runtimeComplexity: string;
      spaceComplexity: string;
      answerRuntimes: Array<{
        __typename?: "AlgorithmAnswerRuntime";
        id: number;
        answerId: number;
        machineCpu: number;
        machineMemory: string;
        machineOs: string;
        readability: number;
        stats: string;
        runtime: string;
        codeBody: string;
      }>;
    }>;
    hints: Array<{
      __typename?: "AlgorithmHint";
      id: number;
      uniqueId: string;
      questionId: number;
      questionVersion: string;
      descriptionJa: string | null;
      descriptionEn: string | null;
      orderNum: number;
    }>;
  };
};

export type RequestReviewAlgorithmQuestionForAlgorithmResourceEditorMutationVariables = Exact<{
  requestReviewQuestionInput: RequestReviewAlgorithmQuestionInput;
}>;

export type RequestReviewAlgorithmQuestionForAlgorithmResourceEditorMutation = {
  __typename?: "Mutation";
  requestReviewAlgorithmQuestion: {
    __typename?: "AlgorithmQuestion";
    id: string;
    questionId: number;
    slug: string;
    version: string;
    variant: AlgorithmQuestionVariant;
    companyId: number;
    employeeId: string;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    difficulty: Difficulty;
    timeLimitSeconds: number | null;
    isPrivate: boolean;
    status: QuestionStatus;
    supportedLanguages: Array<string>;
    signature: string;
    correctnessTestCase: string;
    performanceTestCase: string;
    correctnessTestCaseIds: Array<number>;
    performanceTestCaseIds: Array<number>;
    performanceTimeLimitMilliSeconds: number;
    isOfficial: boolean;
    numUses: number;
    accuracyRate: number;
    averageElapsedTimeSeconds: number;
    answers: Array<{
      __typename?: "AlgorithmAnswer";
      id: number;
      uniqueId: string;
      questionId: number;
      questionVersion: string;
      titleEn: string | null;
      titleJa: string | null;
      label: string;
      descriptionEn: string | null;
      descriptionJa: string | null;
      runtimeComplexity: string;
      spaceComplexity: string;
      answerRuntimes: Array<{
        __typename?: "AlgorithmAnswerRuntime";
        id: number;
        answerId: number;
        machineCpu: number;
        machineMemory: string;
        machineOs: string;
        readability: number;
        stats: string;
        runtime: string;
        codeBody: string;
      }>;
    }>;
    hints: Array<{
      __typename?: "AlgorithmHint";
      id: number;
      uniqueId: string;
      questionId: number;
      questionVersion: string;
      descriptionJa: string | null;
      descriptionEn: string | null;
      orderNum: number;
    }>;
  };
};

export type GetAlgorithmQuestionForAlgorithmResourceEditorQueryVariables = Exact<{
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
  withAnswers: Scalars["Boolean"]["input"];
  withPerformanceTcs: Scalars["Boolean"]["input"];
}>;

export type GetAlgorithmQuestionForAlgorithmResourceEditorQuery = {
  __typename?: "Query";
  algorithmQuestion: {
    __typename?: "AlgorithmQuestion";
    id: string;
    questionId: number;
    slug: string;
    version: string;
    variant: AlgorithmQuestionVariant;
    companyId: number;
    employeeId: string;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    difficulty: Difficulty;
    timeLimitSeconds: number | null;
    isPrivate: boolean;
    status: QuestionStatus;
    supportedLanguages: Array<string>;
    signature: string;
    correctnessTestCase: string;
    performanceTestCase: string;
    correctnessTestCaseIds: Array<number>;
    performanceTestCaseIds: Array<number>;
    performanceTimeLimitMilliSeconds: number;
    isOfficial: boolean;
    numUses: number;
    accuracyRate: number;
    averageElapsedTimeSeconds: number;
    answers: Array<{
      __typename?: "AlgorithmAnswer";
      id: number;
      uniqueId: string;
      questionId: number;
      questionVersion: string;
      titleEn: string | null;
      titleJa: string | null;
      label: string;
      descriptionEn: string | null;
      descriptionJa: string | null;
      runtimeComplexity: string;
      spaceComplexity: string;
      answerRuntimes: Array<{
        __typename?: "AlgorithmAnswerRuntime";
        id: number;
        answerId: number;
        machineCpu: number;
        machineMemory: string;
        machineOs: string;
        readability: number;
        stats: string;
        runtime: string;
        codeBody: string;
      }>;
    }>;
    hints: Array<{
      __typename?: "AlgorithmHint";
      id: number;
      uniqueId: string;
      questionId: number;
      questionVersion: string;
      descriptionJa: string | null;
      descriptionEn: string | null;
      orderNum: number;
    }>;
  };
};

export type AlgorithmTemplateCodeQueryVariables = Exact<{
  signature: Scalars["String"]["input"];
  variant: AlgorithmQuestionVariant;
}>;

export type AlgorithmTemplateCodeQuery = {
  __typename?: "Query";
  algorithmTemplateCode: {
    __typename?: "AlgorithmTemplateCodeResponse";
    python3: string;
    go: string;
    javascript: string;
    php: string;
    ruby: string;
    typescript: string;
    cpp: string;
    kotlin: string;
    java: string;
    swift: string;
    scala: string;
    rust: string;
    sqlite: string;
    pgsql: string;
    mysql: string;
    dart: string;
    csharp: string;
    c: string;
    perl: string;
  };
};

export type AlgorithmRunCodeQueryVariables = Exact<{
  signature: Scalars["String"]["input"];
  runtime: Scalars["String"]["input"];
  codeBody: Scalars["String"]["input"];
  tcInput: Scalars["String"]["input"];
  tcOutput: Scalars["String"]["input"];
  variant: AlgorithmQuestionVariant;
}>;

export type AlgorithmRunCodeQuery = {
  __typename?: "Query";
  algorithmRunCode: {
    __typename?: "AlgorithmRunCodeResponse";
    output: string;
    log: string;
    status: string;
    expected: string;
    isAccepted: boolean;
    performance: number;
    maxMemory: number;
  };
};

export type AlgorithmProfileCodeQueryVariables = Exact<{
  signature: Scalars["String"]["input"];
  runtime: Scalars["String"]["input"];
  codeBody: Scalars["String"]["input"];
  tcInput: Scalars["String"]["input"];
  tcOutput: Scalars["String"]["input"];
  variant: AlgorithmQuestionVariant;
}>;

export type AlgorithmProfileCodeQuery = {
  __typename?: "Query";
  algorithmProfileCode: {
    __typename?: "AlgorithmProfileCodeResponse";
    output: string;
    log: string;
    status: string;
    performanceAvgString: string;
    performanceStdString: string;
    performanceMedString: string;
    maxMemoryAvg: number;
    maxMemoryStd: number;
    maxMemoryMed: number;
    machineOs: string;
    machineCpu: number;
    machineMemory: string;
    isAccepted: boolean;
  };
};

export type TranslatePlainTextForAlgorithmResourceEditorMutationVariables = Exact<{
  source: TranslateSource;
}>;

export type TranslatePlainTextForAlgorithmResourceEditorMutation = { __typename?: "Mutation"; translatePlainText: string };

export type TranslateMarkdownForAlgorithmResourceEditorMutationVariables = Exact<{
  source: TranslateSource;
}>;

export type TranslateMarkdownForAlgorithmResourceEditorMutation = { __typename?: "Mutation"; translateMarkdown: string };

export type BehavioralEventLogsForChallengeTestReportFragment = {
  __typename?: "BehavioralEventLogs";
  events: Array<
    | { __typename: "ChatGPTConversationResetEvent"; chatId: string; resetAtSeconds: number | null; resetAtNanoSeconds: number | null }
    | {
        __typename: "ChatGPTRequestedEvent";
        chatId: string;
        prompt: string;
        gptModel: ChatGptModel;
        requestedAtSeconds: number | null;
        requestedAtNanoSeconds: number | null;
      }
    | {
        __typename: "ChatGPTRespondedEvent";
        chatId: string;
        response: string;
        gptModel: ChatGptModel;
        respondedAtSeconds: number | null;
        respondedAtNanoSeconds: number | null;
      }
    | {
        __typename: "WebAccessedEvent";
        url: string;
        title: string;
        description: string;
        accessedAtSeconds: number | null;
        accessedAtNanoSeconds: number | null;
      }
    | {
        __typename: "WebSearchedEvent";
        query: string;
        page: number | null;
        searchedAtSeconds: number | null;
        searchedAtNanoSeconds: number | null;
      }
  >;
};

export type GetBehavioralEventLogsForChallengeTestReportQueryVariables = Exact<{
  input: BehavioralEventLogsInput;
}>;

export type GetBehavioralEventLogsForChallengeTestReportQuery = {
  __typename?: "Query";
  behavioralEventLogs: {
    __typename?: "BehavioralEventLogs";
    events: Array<
      | { __typename: "ChatGPTConversationResetEvent"; chatId: string; resetAtSeconds: number | null; resetAtNanoSeconds: number | null }
      | {
          __typename: "ChatGPTRequestedEvent";
          chatId: string;
          prompt: string;
          gptModel: ChatGptModel;
          requestedAtSeconds: number | null;
          requestedAtNanoSeconds: number | null;
        }
      | {
          __typename: "ChatGPTRespondedEvent";
          chatId: string;
          response: string;
          gptModel: ChatGptModel;
          respondedAtSeconds: number | null;
          respondedAtNanoSeconds: number | null;
        }
      | {
          __typename: "WebAccessedEvent";
          url: string;
          title: string;
          description: string;
          accessedAtSeconds: number | null;
          accessedAtNanoSeconds: number | null;
        }
      | {
          __typename: "WebSearchedEvent";
          query: string;
          page: number | null;
          searchedAtSeconds: number | null;
          searchedAtNanoSeconds: number | null;
        }
    >;
  };
};

export type InitialCodesForFreepadResourceEditorFragment = {
  __typename?: "FreepadInitialCode";
  id: string;
  questionId: number;
  questionVersion: string;
  freepadInitialCodeId: number;
  runtime: string;
  body: string;
};

export type InitialFlowChartsForFreepadResourceEditorFragment = {
  __typename?: "FreepadInitialFlowChart";
  id: string;
  questionId: number;
  questionVersion: string;
  freepadInitialFlowChartId: number;
  componentType: SystemDesignComponentType;
  body: string;
};

export type AnswerCodeForFreepadResourceEditorFragment = {
  __typename?: "FreepadAnswerCode";
  id: string;
  answerId: number;
  freepadAnswerCodeId: number;
  runtime: string;
  body: string;
};

export type AnswerFlowChartForFreepadResourceEditorFragment = {
  __typename?: "FreepadAnswerFlowChart";
  id: string;
  answerId: number;
  freepadAnswerFlowChartId: number;
  body: string;
  componentType: SystemDesignComponentType;
};

export type AnswerForFreepadResourceEditorFragment = {
  __typename?: "FreepadAnswer";
  id: string;
  label: string;
  questionId: number;
  questionVersion: string;
  freepadAnswerId: number;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  answerFlowCharts: Array<{
    __typename?: "FreepadAnswerFlowChart";
    id: string;
    answerId: number;
    freepadAnswerFlowChartId: number;
    body: string;
    componentType: SystemDesignComponentType;
  }>;
  answerCodes: Array<{
    __typename?: "FreepadAnswerCode";
    id: string;
    answerId: number;
    freepadAnswerCodeId: number;
    runtime: string;
    body: string;
  }>;
};

export type FreepadQuestionForFreepadResourceEditorFragment = {
  __typename?: "FreepadQuestion";
  id: string;
  freepadQuestionId: number;
  version: string;
  variant: FreepadQuestionVariant;
  companyId: number;
  employeeId: string;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  difficulty: Difficulty;
  isOfficial: boolean;
  isPrivate: boolean;
  status: QuestionStatus;
  numUses: number;
  averageElapsedTimeSeconds: number;
  initialCodes: Array<{
    __typename?: "FreepadInitialCode";
    id: string;
    questionId: number;
    questionVersion: string;
    freepadInitialCodeId: number;
    runtime: string;
    body: string;
  }>;
  initialFlowCharts: Array<{
    __typename?: "FreepadInitialFlowChart";
    id: string;
    questionId: number;
    questionVersion: string;
    freepadInitialFlowChartId: number;
    componentType: SystemDesignComponentType;
    body: string;
  }>;
  answers: Array<{
    __typename?: "FreepadAnswer";
    id: string;
    label: string;
    questionId: number;
    questionVersion: string;
    freepadAnswerId: number;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    answerFlowCharts: Array<{
      __typename?: "FreepadAnswerFlowChart";
      id: string;
      answerId: number;
      freepadAnswerFlowChartId: number;
      body: string;
      componentType: SystemDesignComponentType;
    }>;
    answerCodes: Array<{
      __typename?: "FreepadAnswerCode";
      id: string;
      answerId: number;
      freepadAnswerCodeId: number;
      runtime: string;
      body: string;
    }>;
  }>;
  tags: Array<{ __typename?: "FreepadTag"; name: string }>;
};

export type GetQuestionForFreepadResourceEditorQueryVariables = Exact<{
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
}>;

export type GetQuestionForFreepadResourceEditorQuery = {
  __typename?: "Query";
  freepadQuestion: {
    __typename?: "FreepadQuestion";
    id: string;
    freepadQuestionId: number;
    version: string;
    variant: FreepadQuestionVariant;
    companyId: number;
    employeeId: string;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    difficulty: Difficulty;
    isOfficial: boolean;
    isPrivate: boolean;
    status: QuestionStatus;
    numUses: number;
    averageElapsedTimeSeconds: number;
    initialCodes: Array<{
      __typename?: "FreepadInitialCode";
      id: string;
      questionId: number;
      questionVersion: string;
      freepadInitialCodeId: number;
      runtime: string;
      body: string;
    }>;
    initialFlowCharts: Array<{
      __typename?: "FreepadInitialFlowChart";
      id: string;
      questionId: number;
      questionVersion: string;
      freepadInitialFlowChartId: number;
      componentType: SystemDesignComponentType;
      body: string;
    }>;
    answers: Array<{
      __typename?: "FreepadAnswer";
      id: string;
      label: string;
      questionId: number;
      questionVersion: string;
      freepadAnswerId: number;
      titleJa: string | null;
      titleEn: string | null;
      descriptionJa: string | null;
      descriptionEn: string | null;
      answerFlowCharts: Array<{
        __typename?: "FreepadAnswerFlowChart";
        id: string;
        answerId: number;
        freepadAnswerFlowChartId: number;
        body: string;
        componentType: SystemDesignComponentType;
      }>;
      answerCodes: Array<{
        __typename?: "FreepadAnswerCode";
        id: string;
        answerId: number;
        freepadAnswerCodeId: number;
        runtime: string;
        body: string;
      }>;
    }>;
    tags: Array<{ __typename?: "FreepadTag"; name: string }>;
  };
};

export type CreateQuestionForFreepadResourceEditorMutationVariables = Exact<{
  input: CreateFreepadQuestionInput;
}>;

export type CreateQuestionForFreepadResourceEditorMutation = {
  __typename?: "Mutation";
  createFreepadQuestion: {
    __typename?: "FreepadQuestion";
    id: string;
    freepadQuestionId: number;
    version: string;
    variant: FreepadQuestionVariant;
    companyId: number;
    employeeId: string;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    difficulty: Difficulty;
    isOfficial: boolean;
    isPrivate: boolean;
    status: QuestionStatus;
    numUses: number;
    averageElapsedTimeSeconds: number;
    initialCodes: Array<{
      __typename?: "FreepadInitialCode";
      id: string;
      questionId: number;
      questionVersion: string;
      freepadInitialCodeId: number;
      runtime: string;
      body: string;
    }>;
    initialFlowCharts: Array<{
      __typename?: "FreepadInitialFlowChart";
      id: string;
      questionId: number;
      questionVersion: string;
      freepadInitialFlowChartId: number;
      componentType: SystemDesignComponentType;
      body: string;
    }>;
    answers: Array<{
      __typename?: "FreepadAnswer";
      id: string;
      label: string;
      questionId: number;
      questionVersion: string;
      freepadAnswerId: number;
      titleJa: string | null;
      titleEn: string | null;
      descriptionJa: string | null;
      descriptionEn: string | null;
      answerFlowCharts: Array<{
        __typename?: "FreepadAnswerFlowChart";
        id: string;
        answerId: number;
        freepadAnswerFlowChartId: number;
        body: string;
        componentType: SystemDesignComponentType;
      }>;
      answerCodes: Array<{
        __typename?: "FreepadAnswerCode";
        id: string;
        answerId: number;
        freepadAnswerCodeId: number;
        runtime: string;
        body: string;
      }>;
    }>;
    tags: Array<{ __typename?: "FreepadTag"; name: string }>;
  };
};

export type UpdateQuestionForFreepadResourceEditorMutationVariables = Exact<{
  input: UpdateFreepadQuestionInput;
}>;

export type UpdateQuestionForFreepadResourceEditorMutation = {
  __typename?: "Mutation";
  updateFreepadQuestion: {
    __typename?: "FreepadQuestion";
    id: string;
    freepadQuestionId: number;
    version: string;
    variant: FreepadQuestionVariant;
    companyId: number;
    employeeId: string;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    difficulty: Difficulty;
    isOfficial: boolean;
    isPrivate: boolean;
    status: QuestionStatus;
    numUses: number;
    averageElapsedTimeSeconds: number;
    initialCodes: Array<{
      __typename?: "FreepadInitialCode";
      id: string;
      questionId: number;
      questionVersion: string;
      freepadInitialCodeId: number;
      runtime: string;
      body: string;
    }>;
    initialFlowCharts: Array<{
      __typename?: "FreepadInitialFlowChart";
      id: string;
      questionId: number;
      questionVersion: string;
      freepadInitialFlowChartId: number;
      componentType: SystemDesignComponentType;
      body: string;
    }>;
    answers: Array<{
      __typename?: "FreepadAnswer";
      id: string;
      label: string;
      questionId: number;
      questionVersion: string;
      freepadAnswerId: number;
      titleJa: string | null;
      titleEn: string | null;
      descriptionJa: string | null;
      descriptionEn: string | null;
      answerFlowCharts: Array<{
        __typename?: "FreepadAnswerFlowChart";
        id: string;
        answerId: number;
        freepadAnswerFlowChartId: number;
        body: string;
        componentType: SystemDesignComponentType;
      }>;
      answerCodes: Array<{
        __typename?: "FreepadAnswerCode";
        id: string;
        answerId: number;
        freepadAnswerCodeId: number;
        runtime: string;
        body: string;
      }>;
    }>;
    tags: Array<{ __typename?: "FreepadTag"; name: string }>;
  };
};

export type AlgorithmTemplateCodeForFreepadResourceEditorFragment = {
  __typename?: "AlgorithmTemplateCodeResponse";
  python3: string;
  go: string;
  javascript: string;
  php: string;
  ruby: string;
  typescript: string;
  cpp: string;
  kotlin: string;
  java: string;
  swift: string;
  scala: string;
  rust: string;
  sqlite: string;
  pgsql: string;
  mysql: string;
  dart: string;
  csharp: string;
  c: string;
  perl: string;
};

export type GetAlgorithmTemplateCodeForFreepadResourceEditorQueryVariables = Exact<{
  signature: Scalars["String"]["input"];
  variant: AlgorithmQuestionVariant;
}>;

export type GetAlgorithmTemplateCodeForFreepadResourceEditorQuery = {
  __typename?: "Query";
  algorithmTemplateCode: {
    __typename?: "AlgorithmTemplateCodeResponse";
    python3: string;
    go: string;
    javascript: string;
    php: string;
    ruby: string;
    typescript: string;
    cpp: string;
    kotlin: string;
    java: string;
    swift: string;
    scala: string;
    rust: string;
    sqlite: string;
    pgsql: string;
    mysql: string;
    dart: string;
    csharp: string;
    c: string;
    perl: string;
  };
};

export type TranslatePlainTextForFreepadResourceEditorMutationVariables = Exact<{
  source: TranslateSource;
}>;

export type TranslatePlainTextForFreepadResourceEditorMutation = { __typename?: "Mutation"; translatePlainText: string };

export type TranslateMarkdownForFreepadResourceEditorMutationVariables = Exact<{
  source: TranslateSource;
}>;

export type TranslateMarkdownForFreepadResourceEditorMutation = { __typename?: "Mutation"; translateMarkdown: string };

export type MultiChoiceQuestionForQuizResourceEditorFragment = {
  __typename?: "MultiChoiceQuestion";
  id: number;
  version: string;
  titleJa: string | null;
  titleEn: string | null;
  difficulty: Difficulty;
  descriptionJa: string | null;
  descriptionEn: string | null;
  metricType: MultiChoiceMetricType;
  companyId: number;
  employeeId: string;
  isPrivate: boolean;
  isOfficial: boolean;
  status: QuestionStatus;
  versions: Array<string>;
  correctAnswerRate: number;
  variant: MultiChoiceQuestionVariant;
  createdAtSeconds: number | null;
  updatedAtSeconds: number | null;
  deletedAtSeconds: number | null;
  tags: Array<{ __typename?: "MultiChoiceTag"; id: number; name: string }>;
  options: Array<{
    __typename?: "MultiChoiceOption";
    id: number;
    titleJa: string | null;
    titleEn: string | null;
    isCorrect: boolean;
    additionalDetailJa: string | null;
    additionalDetailEn: string | null;
    createdAtSeconds: number | null;
    updatedAtSeconds: number | null;
    deletedAtSeconds: number | null;
  }>;
  evaluationItems: Array<{
    __typename?: "MultiChoiceEvaluationItem";
    id: number;
    questionId: number;
    questionVersion: string;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
  }>;
};

export type ListQuestionsForSearchQuestionQueryVariables = Exact<{
  multiChoiceQuestionsInput: MultiChoiceQuestionsInput;
}>;

export type ListQuestionsForSearchQuestionQuery = {
  __typename?: "Query";
  multiChoiceQuestions: {
    __typename?: "MultiChoiceQuestionsResponse";
    count: number;
    questions: Array<{
      __typename?: "MultiChoiceQuestion";
      id: number;
      version: string;
      titleJa: string | null;
      titleEn: string | null;
      difficulty: Difficulty;
      descriptionJa: string | null;
      descriptionEn: string | null;
      metricType: MultiChoiceMetricType;
      companyId: number;
      employeeId: string;
      isPrivate: boolean;
      isOfficial: boolean;
      status: QuestionStatus;
      versions: Array<string>;
      correctAnswerRate: number;
      variant: MultiChoiceQuestionVariant;
      createdAtSeconds: number | null;
      updatedAtSeconds: number | null;
      deletedAtSeconds: number | null;
      tags: Array<{ __typename?: "MultiChoiceTag"; id: number; name: string }>;
      options: Array<{
        __typename?: "MultiChoiceOption";
        id: number;
        titleJa: string | null;
        titleEn: string | null;
        isCorrect: boolean;
        additionalDetailJa: string | null;
        additionalDetailEn: string | null;
        createdAtSeconds: number | null;
        updatedAtSeconds: number | null;
        deletedAtSeconds: number | null;
      }>;
      evaluationItems: Array<{
        __typename?: "MultiChoiceEvaluationItem";
        id: number;
        questionId: number;
        questionVersion: string;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
      }>;
    }>;
    pager: { __typename?: "MultiChoicePager"; cursorSeconds: number | null; cursorNanoSeconds: number | null; hasNext: boolean | null } | null;
  };
};

export type ListMultiQuestionTagsQueryVariables = Exact<{
  multiChoiceTagsInput: MultiChoiceTagsInput;
}>;

export type ListMultiQuestionTagsQuery = {
  __typename?: "Query";
  multiChoiceTags: {
    __typename?: "MultiChoiceTagsResponse";
    count: number;
    tags: Array<{ __typename?: "MultiChoiceTag"; id: number; name: string }>;
    pager: { __typename?: "MultiChoicePager"; cursorSeconds: number | null; cursorNanoSeconds: number | null; hasNext: boolean | null } | null;
  };
};

export type CreateMultiChoicePackageFragment = {
  __typename?: "MultiChoicePackage";
  id: string;
  packageId: number;
  version: string;
  companyId: number;
  employeeId: string;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  difficulty: Difficulty;
  isPrivate: boolean;
  status: QuestionStatus;
  timeLimitSeconds: number | null;
  createdAtSeconds: number | null;
  updatedAtSeconds: number | null;
  deletedAtSeconds: number | null;
  questions: Array<{ __typename?: "MultiChoiceQuestion"; id: number; version: string; isPrivate: boolean }>;
};

export type CreateMultiChoicePackageMutationVariables = Exact<{
  createMultiChoicePackage: CreateMultiChoicePackage;
}>;

export type CreateMultiChoicePackageMutation = {
  __typename?: "Mutation";
  createMultiChoicePackage: {
    __typename?: "MultiChoicePackage";
    id: string;
    packageId: number;
    version: string;
    companyId: number;
    employeeId: string;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    difficulty: Difficulty;
    isPrivate: boolean;
    status: QuestionStatus;
    timeLimitSeconds: number | null;
    createdAtSeconds: number | null;
    updatedAtSeconds: number | null;
    deletedAtSeconds: number | null;
    questions: Array<{ __typename?: "MultiChoiceQuestion"; id: number; version: string; isPrivate: boolean }>;
  };
};

export type UpdateMultiChoicePackageFragment = {
  __typename?: "MultiChoicePackage";
  id: string;
  packageId: number;
  version: string;
  companyId: number;
  employeeId: string;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  difficulty: Difficulty;
  isPrivate: boolean;
  status: QuestionStatus;
  timeLimitSeconds: number | null;
  createdAtSeconds: number | null;
  updatedAtSeconds: number | null;
  deletedAtSeconds: number | null;
  questions: Array<{ __typename?: "MultiChoiceQuestion"; id: number; version: string }>;
};

export type UpdateMultiChoicePackageMutationVariables = Exact<{
  updateMultiChoicePackage: UpdateMultiChoicePackage;
}>;

export type UpdateMultiChoicePackageMutation = {
  __typename?: "Mutation";
  updateMultiChoicePackage: {
    __typename?: "MultiChoicePackage";
    id: string;
    packageId: number;
    version: string;
    companyId: number;
    employeeId: string;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    difficulty: Difficulty;
    isPrivate: boolean;
    status: QuestionStatus;
    timeLimitSeconds: number | null;
    createdAtSeconds: number | null;
    updatedAtSeconds: number | null;
    deletedAtSeconds: number | null;
    questions: Array<{ __typename?: "MultiChoiceQuestion"; id: number; version: string }>;
  };
};

export type GetMultiChoicePackageFragment = {
  __typename?: "MultiChoicePackage";
  id: string;
  packageId: number;
  version: string;
  companyId: number;
  employeeId: string;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  difficulty: Difficulty;
  isPrivate: boolean;
  status: QuestionStatus;
  timeLimitSeconds: number | null;
  createdAtSeconds: number | null;
  updatedAtSeconds: number | null;
  deletedAtSeconds: number | null;
  questions: Array<{
    __typename?: "MultiChoiceQuestion";
    id: number;
    version: string;
    titleJa: string | null;
    titleEn: string | null;
    difficulty: Difficulty;
    descriptionJa: string | null;
    descriptionEn: string | null;
    metricType: MultiChoiceMetricType;
    companyId: number;
    employeeId: string;
    isPrivate: boolean;
    isOfficial: boolean;
    status: QuestionStatus;
    versions: Array<string>;
    correctAnswerRate: number;
    variant: MultiChoiceQuestionVariant;
    createdAtSeconds: number | null;
    updatedAtSeconds: number | null;
    deletedAtSeconds: number | null;
    tags: Array<{ __typename?: "MultiChoiceTag"; id: number; name: string }>;
    options: Array<{
      __typename?: "MultiChoiceOption";
      id: number;
      titleJa: string | null;
      titleEn: string | null;
      isCorrect: boolean;
      additionalDetailJa: string | null;
      additionalDetailEn: string | null;
      createdAtSeconds: number | null;
      updatedAtSeconds: number | null;
      deletedAtSeconds: number | null;
    }>;
    evaluationItems: Array<{
      __typename?: "MultiChoiceEvaluationItem";
      id: number;
      questionId: number;
      questionVersion: string;
      titleJa: string | null;
      titleEn: string | null;
      descriptionJa: string | null;
      descriptionEn: string | null;
    }>;
  }>;
};

export type GetMultiChoicePackageQueryVariables = Exact<{
  id: Scalars["Int"]["input"];
  version: Scalars["String"]["input"];
  withAnswers: Scalars["Boolean"]["input"];
}>;

export type GetMultiChoicePackageQuery = {
  __typename?: "Query";
  multiChoicePackage: {
    __typename?: "MultiChoicePackage";
    id: string;
    packageId: number;
    version: string;
    companyId: number;
    employeeId: string;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    difficulty: Difficulty;
    isPrivate: boolean;
    status: QuestionStatus;
    timeLimitSeconds: number | null;
    createdAtSeconds: number | null;
    updatedAtSeconds: number | null;
    deletedAtSeconds: number | null;
    questions: Array<{
      __typename?: "MultiChoiceQuestion";
      id: number;
      version: string;
      titleJa: string | null;
      titleEn: string | null;
      difficulty: Difficulty;
      descriptionJa: string | null;
      descriptionEn: string | null;
      metricType: MultiChoiceMetricType;
      companyId: number;
      employeeId: string;
      isPrivate: boolean;
      isOfficial: boolean;
      status: QuestionStatus;
      versions: Array<string>;
      correctAnswerRate: number;
      variant: MultiChoiceQuestionVariant;
      createdAtSeconds: number | null;
      updatedAtSeconds: number | null;
      deletedAtSeconds: number | null;
      tags: Array<{ __typename?: "MultiChoiceTag"; id: number; name: string }>;
      options: Array<{
        __typename?: "MultiChoiceOption";
        id: number;
        titleJa: string | null;
        titleEn: string | null;
        isCorrect: boolean;
        additionalDetailJa: string | null;
        additionalDetailEn: string | null;
        createdAtSeconds: number | null;
        updatedAtSeconds: number | null;
        deletedAtSeconds: number | null;
      }>;
      evaluationItems: Array<{
        __typename?: "MultiChoiceEvaluationItem";
        id: number;
        questionId: number;
        questionVersion: string;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
      }>;
    }>;
  } | null;
};

export type CreateMultiChoiceQuestionMutationVariables = Exact<{
  payload: CreateMultiChoiceQuestionInput;
}>;

export type CreateMultiChoiceQuestionMutation = {
  __typename?: "Mutation";
  createMultiChoiceQuestion: {
    __typename?: "MultiChoiceQuestion";
    id: number;
    version: string;
    titleJa: string | null;
    titleEn: string | null;
    difficulty: Difficulty;
    descriptionJa: string | null;
    descriptionEn: string | null;
    metricType: MultiChoiceMetricType;
    companyId: number;
    employeeId: string;
    isPrivate: boolean;
    isOfficial: boolean;
    status: QuestionStatus;
    versions: Array<string>;
    correctAnswerRate: number;
    variant: MultiChoiceQuestionVariant;
    createdAtSeconds: number | null;
    updatedAtSeconds: number | null;
    deletedAtSeconds: number | null;
    tags: Array<{ __typename?: "MultiChoiceTag"; id: number; name: string }>;
    options: Array<{
      __typename?: "MultiChoiceOption";
      id: number;
      titleJa: string | null;
      titleEn: string | null;
      isCorrect: boolean;
      additionalDetailJa: string | null;
      additionalDetailEn: string | null;
      createdAtSeconds: number | null;
      updatedAtSeconds: number | null;
      deletedAtSeconds: number | null;
    }>;
    evaluationItems: Array<{
      __typename?: "MultiChoiceEvaluationItem";
      id: number;
      questionId: number;
      questionVersion: string;
      titleJa: string | null;
      titleEn: string | null;
      descriptionJa: string | null;
      descriptionEn: string | null;
    }>;
  };
};

export type UpdateMultiChoiceQuestionMutationVariables = Exact<{
  payload: UpdateMultiChoiceQuestionInput;
}>;

export type UpdateMultiChoiceQuestionMutation = {
  __typename?: "Mutation";
  updateMultiChoiceQuestion: {
    __typename?: "MultiChoiceQuestion";
    id: number;
    version: string;
    titleJa: string | null;
    titleEn: string | null;
    difficulty: Difficulty;
    descriptionJa: string | null;
    descriptionEn: string | null;
    metricType: MultiChoiceMetricType;
    companyId: number;
    employeeId: string;
    isPrivate: boolean;
    isOfficial: boolean;
    status: QuestionStatus;
    versions: Array<string>;
    correctAnswerRate: number;
    variant: MultiChoiceQuestionVariant;
    createdAtSeconds: number | null;
    updatedAtSeconds: number | null;
    deletedAtSeconds: number | null;
    tags: Array<{ __typename?: "MultiChoiceTag"; id: number; name: string }>;
    options: Array<{
      __typename?: "MultiChoiceOption";
      id: number;
      titleJa: string | null;
      titleEn: string | null;
      isCorrect: boolean;
      additionalDetailJa: string | null;
      additionalDetailEn: string | null;
      createdAtSeconds: number | null;
      updatedAtSeconds: number | null;
      deletedAtSeconds: number | null;
    }>;
    evaluationItems: Array<{
      __typename?: "MultiChoiceEvaluationItem";
      id: number;
      questionId: number;
      questionVersion: string;
      titleJa: string | null;
      titleEn: string | null;
      descriptionJa: string | null;
      descriptionEn: string | null;
    }>;
  };
};

export type ListRemoteTagsForRemoteInterviewResourceEditorTagFragment = {
  __typename?: "RemoteTagsByCompanyIdResponse";
  count: number;
  tags: Array<{ __typename?: "RemoteTag"; name: string; companyId: number }>;
  pager: { __typename?: "RemotePager"; cursorSeconds: number | null; hasNext: boolean } | null;
};

export type ListRemoteTagsForRemoteInterviewResourceEditorQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  cursorSeconds: InputMaybe<Scalars["Int"]["input"]>;
  size: Scalars["Int"]["input"];
  withCount: Scalars["Boolean"]["input"];
}>;

export type ListRemoteTagsForRemoteInterviewResourceEditorQuery = {
  __typename?: "Query";
  remoteTagsByCompanyId: {
    __typename?: "RemoteTagsByCompanyIdResponse";
    count: number;
    tags: Array<{ __typename?: "RemoteTag"; name: string; companyId: number }>;
    pager: { __typename?: "RemotePager"; cursorSeconds: number | null; hasNext: boolean } | null;
  };
};

export type GetRemoteForRemoteInterviewResourceEditorAlgorithmQuestionFragment = {
  __typename: "AlgorithmQuestion";
  id: string;
  questionId: number;
  version: string;
  key: string;
  companyId: number;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  status: QuestionStatus;
  supportedLanguages: Array<string>;
  difficulty: Difficulty;
  isPrivate: boolean;
  isOfficial: boolean;
  timeLimitSeconds: number | null;
  numUses: number;
  accuracyRate: number;
  averageElapsedTimeSeconds: number;
  AlgorithmQuestionVariant: AlgorithmQuestionVariant;
};

export type GetRemoteForRemoteInterviewResourceEditorSystemDesignQuestionFragment = {
  __typename: "SystemDesignQuestion";
  id: string;
  questionId: number;
  key: string;
  companyId: number;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  difficulty: Difficulty;
  isPrivate: boolean;
  isOfficial: boolean;
  timeLimitSeconds: number | null;
  numUses: number;
  accuracyRate: number;
  averageElapsedTimeSeconds: number;
};

export type GetRemoteForRemoteInterviewResourceEditorFreepadQuestionFragment = {
  __typename: "FreepadQuestion";
  id: string;
  freepadQuestionId: number;
  key: string;
  version: string;
  variant: FreepadQuestionVariant;
  companyId: number;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  difficulty: Difficulty;
  isPrivate: boolean;
  status: QuestionStatus;
  isOfficial: boolean;
  numUses: number;
  averageElapsedTimeSeconds: number;
};

export type GetRemoteForRemoteInterviewResourceEditorRemoteFragment = {
  __typename?: "Remote";
  id: string;
  name: string;
  candidateName: string;
  memo: string | null;
  isPublic: boolean;
  status: RemoteStatus;
  creator: { __typename?: "User"; uid: string; email: string; displayName: string; photoUrl: string } | null;
  viewers: Array<
    { __typename: "EmployeeGroup"; employeeGroupId: string; groupName: string } | { __typename: "User"; uid: string; displayName: string }
  >;
  tags: Array<{ __typename?: "RemoteTag"; name: string }>;
  entity: {
    __typename?: "RemoteEntity";
    entityType: RemoteEntityType;
    liveCoding: {
      __typename?: "LiveCoding";
      sessions: Array<{
        __typename?: "LiveCodingSession";
        questionType: LiveCodingQuestionType;
        questionVariant: LiveCodingQuestionVariant | null;
        algorithmQuestion: {
          __typename: "AlgorithmQuestion";
          id: string;
          questionId: number;
          version: string;
          key: string;
          companyId: number;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          status: QuestionStatus;
          supportedLanguages: Array<string>;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          AlgorithmQuestionVariant: AlgorithmQuestionVariant;
        } | null;
        systemDesignQuestion: {
          __typename: "SystemDesignQuestion";
          id: string;
          questionId: number;
          key: string;
          companyId: number;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
        } | null;
        freepadQuestion: {
          __typename: "FreepadQuestion";
          id: string;
          freepadQuestionId: number;
          key: string;
          version: string;
          variant: FreepadQuestionVariant;
          companyId: number;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          status: QuestionStatus;
          isOfficial: boolean;
          numUses: number;
          averageElapsedTimeSeconds: number;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type GetRemoteForRemoteInterviewResourceEditorQueryVariables = Exact<{
  remoteId: Scalars["String"]["input"];
}>;

export type GetRemoteForRemoteInterviewResourceEditorQuery = {
  __typename?: "Query";
  remote: {
    __typename?: "Remote";
    id: string;
    name: string;
    candidateName: string;
    memo: string | null;
    isPublic: boolean;
    status: RemoteStatus;
    creator: { __typename?: "User"; uid: string; email: string; displayName: string; photoUrl: string } | null;
    viewers: Array<
      { __typename: "EmployeeGroup"; employeeGroupId: string; groupName: string } | { __typename: "User"; uid: string; displayName: string }
    >;
    tags: Array<{ __typename?: "RemoteTag"; name: string }>;
    entity: {
      __typename?: "RemoteEntity";
      entityType: RemoteEntityType;
      liveCoding: {
        __typename?: "LiveCoding";
        sessions: Array<{
          __typename?: "LiveCodingSession";
          questionType: LiveCodingQuestionType;
          questionVariant: LiveCodingQuestionVariant | null;
          algorithmQuestion: {
            __typename: "AlgorithmQuestion";
            id: string;
            questionId: number;
            version: string;
            key: string;
            companyId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            status: QuestionStatus;
            supportedLanguages: Array<string>;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            timeLimitSeconds: number | null;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            AlgorithmQuestionVariant: AlgorithmQuestionVariant;
          } | null;
          systemDesignQuestion: {
            __typename: "SystemDesignQuestion";
            id: string;
            questionId: number;
            key: string;
            companyId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            timeLimitSeconds: number | null;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
          } | null;
          freepadQuestion: {
            __typename: "FreepadQuestion";
            id: string;
            freepadQuestionId: number;
            key: string;
            version: string;
            variant: FreepadQuestionVariant;
            companyId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            status: QuestionStatus;
            isOfficial: boolean;
            numUses: number;
            averageElapsedTimeSeconds: number;
          } | null;
        } | null>;
      } | null;
    } | null;
  };
};

export type RemoteForRemoteInterviewResourceEditorFragment = {
  __typename?: "Remote";
  id: string;
  name: string;
  candidateName: string;
  memo: string | null;
  isPublic: boolean;
  status: RemoteStatus;
  creator: { __typename?: "User"; uid: string; email: string; displayName: string; photoUrl: string } | null;
  viewers: Array<
    { __typename: "EmployeeGroup"; employeeGroupId: string; groupName: string } | { __typename: "User"; uid: string; displayName: string }
  >;
  tags: Array<{ __typename?: "RemoteTag"; name: string }>;
  entity: {
    __typename?: "RemoteEntity";
    entityType: RemoteEntityType;
    liveCoding: {
      __typename?: "LiveCoding";
      sessions: Array<{
        __typename?: "LiveCodingSession";
        questionType: LiveCodingQuestionType;
        questionVariant: LiveCodingQuestionVariant | null;
        algorithmQuestion: {
          __typename: "AlgorithmQuestion";
          questionId: number;
          version: string;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          supportedLanguages: Array<string>;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          isSupportedLeakScore: boolean;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          algorithmQuestionVariant: AlgorithmQuestionVariant;
          algorithmQuestionStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        } | null;
        systemDesignQuestion: {
          __typename: "SystemDesignQuestion";
          questionId: number;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          systemDesignQuestionStatus: SystemDesignQuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        } | null;
        freepadQuestion: {
          __typename: "FreepadQuestion";
          key: string;
          freepadQuestionId: number;
          version: string;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          numUses: number;
          freepadQuestionStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type GetRemoteForRemoteInterviewResourceEditorV2QueryVariables = Exact<{
  remoteId: Scalars["String"]["input"];
}>;

export type GetRemoteForRemoteInterviewResourceEditorV2Query = {
  __typename?: "Query";
  remote: {
    __typename?: "Remote";
    id: string;
    name: string;
    candidateName: string;
    memo: string | null;
    isPublic: boolean;
    status: RemoteStatus;
    creator: { __typename?: "User"; uid: string; email: string; displayName: string; photoUrl: string } | null;
    viewers: Array<
      { __typename: "EmployeeGroup"; employeeGroupId: string; groupName: string } | { __typename: "User"; uid: string; displayName: string }
    >;
    tags: Array<{ __typename?: "RemoteTag"; name: string }>;
    entity: {
      __typename?: "RemoteEntity";
      entityType: RemoteEntityType;
      liveCoding: {
        __typename?: "LiveCoding";
        sessions: Array<{
          __typename?: "LiveCodingSession";
          questionType: LiveCodingQuestionType;
          questionVariant: LiveCodingQuestionVariant | null;
          algorithmQuestion: {
            __typename: "AlgorithmQuestion";
            questionId: number;
            version: string;
            key: string;
            titleJa: string | null;
            titleEn: string | null;
            supportedLanguages: Array<string>;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            timeLimitSeconds: number | null;
            numUses: number;
            isSupportedLeakScore: boolean;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            algorithmQuestionVariant: AlgorithmQuestionVariant;
            algorithmQuestionStatus: QuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          } | null;
          systemDesignQuestion: {
            __typename: "SystemDesignQuestion";
            questionId: number;
            key: string;
            titleJa: string | null;
            titleEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            timeLimitSeconds: number | null;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            systemDesignQuestionStatus: SystemDesignQuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          } | null;
          freepadQuestion: {
            __typename: "FreepadQuestion";
            key: string;
            freepadQuestionId: number;
            version: string;
            titleJa: string | null;
            titleEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            numUses: number;
            freepadQuestionStatus: QuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          } | null;
        } | null>;
      } | null;
    } | null;
  };
};

export type ListRemotesForRemoteInterviewResourceEditorFragment = {
  __typename?: "RemotesByCompanyIdResponse";
  count: number;
  hasNext: boolean;
  remotes: Array<{ __typename?: "Remote"; id: string; name: string }>;
};

export type ListRemotesForRemoteInterviewResourceEditorQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  withCount: Scalars["Boolean"]["input"];
  withDeleted: Scalars["Boolean"]["input"];
  filters: InputMaybe<RemoteFilterInput>;
  sortMethod: RemoteSortMethod;
  size: Scalars["Int"]["input"];
  offset: Scalars["Int"]["input"];
  isDescending: Scalars["Boolean"]["input"];
}>;

export type ListRemotesForRemoteInterviewResourceEditorQuery = {
  __typename?: "Query";
  remotesByCompanyId: {
    __typename?: "RemotesByCompanyIdResponse";
    count: number;
    hasNext: boolean;
    remotes: Array<{ __typename?: "Remote"; id: string; name: string }>;
  };
};

export type ListAlgorithmQuestionForRemoteInterviewResourceEditorFragment = {
  __typename: "AlgorithmQuestion";
  id: string;
  questionId: number;
  version: string;
  key: string;
  companyId: number;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  status: QuestionStatus;
  supportedLanguages: Array<string>;
  difficulty: Difficulty;
  isPrivate: boolean;
  isOfficial: boolean;
  timeLimitSeconds: number | null;
  numUses: number;
  accuracyRate: number;
  averageElapsedTimeSeconds: number;
  AlgorithmQuestionVariant: AlgorithmQuestionVariant;
};

export type ListSystemDesignQuestionForRemoteInterviewResourceEditorFragment = {
  __typename: "SystemDesignQuestion";
  id: string;
  questionId: number;
  key: string;
  companyId: number;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  difficulty: Difficulty;
  isPrivate: boolean;
  isOfficial: boolean;
  numUses: number;
  accuracyRate: number;
  averageElapsedTimeSeconds: number;
  timeLimitSeconds: number | null;
};

export type ListFreepadQuestionForRemoteInterviewResourceEditorFragment = {
  __typename: "FreepadQuestion";
  id: string;
  version: string;
  freepadQuestionId: number;
  key: string;
  companyId: number;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  difficulty: Difficulty;
  status: QuestionStatus;
  isPrivate: boolean;
  isOfficial: boolean;
  variant: FreepadQuestionVariant;
  numUses: number;
  averageElapsedTimeSeconds: number;
};

export type ListQuestionForRemoteInterviewResourceEditorFragment = {
  __typename?: "QuestionObject";
  id: string;
  questionVariant: QuestionVariant;
  liveCodingQuestion:
    | {
        __typename: "AlgorithmQuestion";
        id: string;
        questionId: number;
        version: string;
        key: string;
        companyId: number;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        status: QuestionStatus;
        supportedLanguages: Array<string>;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        timeLimitSeconds: number | null;
        numUses: number;
        accuracyRate: number;
        averageElapsedTimeSeconds: number;
        AlgorithmQuestionVariant: AlgorithmQuestionVariant;
      }
    | {
        __typename: "FreepadQuestion";
        id: string;
        version: string;
        freepadQuestionId: number;
        key: string;
        companyId: number;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        difficulty: Difficulty;
        status: QuestionStatus;
        isPrivate: boolean;
        isOfficial: boolean;
        variant: FreepadQuestionVariant;
        numUses: number;
        averageElapsedTimeSeconds: number;
      }
    | {
        __typename: "SystemDesignQuestion";
        id: string;
        questionId: number;
        key: string;
        companyId: number;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        numUses: number;
        accuracyRate: number;
        averageElapsedTimeSeconds: number;
        timeLimitSeconds: number | null;
      }
    | null;
};

export type ListQuestionsForRemoteInterviewResourceEditorQueryVariables = Exact<{
  questionsInput: QuestionsInput;
}>;

export type ListQuestionsForRemoteInterviewResourceEditorQuery = {
  __typename?: "Query";
  questions: {
    __typename?: "Questions";
    count: number;
    hasNext: boolean;
    questionObjects: Array<{
      __typename?: "QuestionObject";
      id: string;
      questionVariant: QuestionVariant;
      liveCodingQuestion:
        | {
            __typename: "AlgorithmQuestion";
            id: string;
            questionId: number;
            version: string;
            key: string;
            companyId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            status: QuestionStatus;
            supportedLanguages: Array<string>;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            timeLimitSeconds: number | null;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            AlgorithmQuestionVariant: AlgorithmQuestionVariant;
          }
        | {
            __typename: "FreepadQuestion";
            id: string;
            version: string;
            freepadQuestionId: number;
            key: string;
            companyId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            status: QuestionStatus;
            isPrivate: boolean;
            isOfficial: boolean;
            variant: FreepadQuestionVariant;
            numUses: number;
            averageElapsedTimeSeconds: number;
          }
        | {
            __typename: "SystemDesignQuestion";
            id: string;
            questionId: number;
            key: string;
            companyId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            timeLimitSeconds: number | null;
          }
        | null;
    }>;
  };
};

export type TemplateForRemoteInterviewResourceEditorFragment = {
  __typename?: "RemoteInterviewTemplate";
  id: string;
  templateId: number;
  templateName: string;
  description: string;
  remoteName: string;
  candidateName: string;
  tagNames: Array<string>;
  memo: string | null;
  isPublic: boolean;
  reviewers: Array<{
    __typename?: "RemoteReviewer";
    type: ReviewerType;
    employee: { __typename?: "User"; id: string; displayName: string } | null;
    group: { __typename?: "EmployeeGroup"; id: string; groupName: string } | null;
  }>;
  questions: Array<{
    __typename?: "RemoteInterviewTemplateEntitySource";
    questionId: number;
    questionKey: string;
    entityType: RemoteInterviewTemplateEntityType | null;
    challengeQuestion: {
      __typename?: "TemplateChallengeQuestionSource";
      questionId: number;
      questionVersion: string;
      uniqueKey: string;
    } | null;
    systemDesignQuestion: {
      __typename?: "TemplateSystemDesignQuestionSource";
      uniqueKey: string;
      questionId: number;
      componentTypes: Array<SystemDesignComponentType>;
    } | null;
    freepadQuestion: { __typename?: "TemplateFreepadQuestionSource"; questionId: number; questionVersion: string; uniqueKey: string } | null;
  }>;
  templateQuestions: Array<
    | {
        __typename: "AlgorithmQuestion";
        questionId: number;
        version: string;
        key: string;
        titleJa: string | null;
        titleEn: string | null;
        supportedLanguages: Array<string>;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        timeLimitSeconds: number | null;
        numUses: number;
        isSupportedLeakScore: boolean;
        accuracyRate: number;
        averageElapsedTimeSeconds: number;
        algorithmQuestionVariant: AlgorithmQuestionVariant;
        algorithmQuestionStatus: QuestionStatus;
        leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
      }
    | {
        __typename: "FreepadQuestion";
        key: string;
        freepadQuestionId: number;
        version: string;
        titleJa: string | null;
        titleEn: string | null;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        isSupportedLeakScore: boolean;
        numUses: number;
        freepadQuestionStatus: QuestionStatus;
        leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
      }
    | {
        __typename: "SystemDesignQuestion";
        questionId: number;
        key: string;
        titleJa: string | null;
        titleEn: string | null;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        isSupportedLeakScore: boolean;
        timeLimitSeconds: number | null;
        numUses: number;
        accuracyRate: number;
        averageElapsedTimeSeconds: number;
        systemDesignQuestionStatus: SystemDesignQuestionStatus;
        leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
      }
  >;
  variables: Array<{ __typename?: "TemplateVariable"; id: string; key: number; templateId: number; name: string; description: string }> | null;
};

export type RemoteInterviewTemplatesByCompanyIdResponseForRemoteInterviewResourceEditorFragment = {
  __typename?: "RemoteInterviewTemplatesByCompanyIdResponse";
  count: number;
  templates: Array<{
    __typename?: "RemoteInterviewTemplate";
    id: string;
    templateId: number;
    templateName: string;
    description: string;
    remoteName: string;
    candidateName: string;
    tagNames: Array<string>;
    memo: string | null;
    isPublic: boolean;
    reviewers: Array<{
      __typename?: "RemoteReviewer";
      type: ReviewerType;
      employee: { __typename?: "User"; id: string; displayName: string } | null;
      group: { __typename?: "EmployeeGroup"; id: string; groupName: string } | null;
    }>;
    questions: Array<{
      __typename?: "RemoteInterviewTemplateEntitySource";
      questionId: number;
      questionKey: string;
      entityType: RemoteInterviewTemplateEntityType | null;
      challengeQuestion: {
        __typename?: "TemplateChallengeQuestionSource";
        questionId: number;
        questionVersion: string;
        uniqueKey: string;
      } | null;
      systemDesignQuestion: {
        __typename?: "TemplateSystemDesignQuestionSource";
        uniqueKey: string;
        questionId: number;
        componentTypes: Array<SystemDesignComponentType>;
      } | null;
      freepadQuestion: { __typename?: "TemplateFreepadQuestionSource"; questionId: number; questionVersion: string; uniqueKey: string } | null;
    }>;
    templateQuestions: Array<
      | {
          __typename: "AlgorithmQuestion";
          questionId: number;
          version: string;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          supportedLanguages: Array<string>;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          isSupportedLeakScore: boolean;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          algorithmQuestionVariant: AlgorithmQuestionVariant;
          algorithmQuestionStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | {
          __typename: "FreepadQuestion";
          key: string;
          freepadQuestionId: number;
          version: string;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          numUses: number;
          freepadQuestionStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | {
          __typename: "SystemDesignQuestion";
          questionId: number;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          systemDesignQuestionStatus: SystemDesignQuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
    >;
    variables: Array<{
      __typename?: "TemplateVariable";
      id: string;
      key: number;
      templateId: number;
      name: string;
      description: string;
    }> | null;
  }>;
  pager: { __typename?: "TemplatePager"; idCursor: number | null; hasNext: boolean } | null;
};

export type ListTemplatesForRemoteInterviewResourceEditorQueryVariables = Exact<{
  input: RemoteInterviewTemplatesByCompanyIdInput;
}>;

export type ListTemplatesForRemoteInterviewResourceEditorQuery = {
  __typename?: "Query";
  remoteInterviewTemplatesByCompanyId: {
    __typename?: "RemoteInterviewTemplatesByCompanyIdResponse";
    count: number;
    templates: Array<{
      __typename?: "RemoteInterviewTemplate";
      id: string;
      templateId: number;
      templateName: string;
      description: string;
      remoteName: string;
      candidateName: string;
      tagNames: Array<string>;
      memo: string | null;
      isPublic: boolean;
      reviewers: Array<{
        __typename?: "RemoteReviewer";
        type: ReviewerType;
        employee: { __typename?: "User"; id: string; displayName: string } | null;
        group: { __typename?: "EmployeeGroup"; id: string; groupName: string } | null;
      }>;
      questions: Array<{
        __typename?: "RemoteInterviewTemplateEntitySource";
        questionId: number;
        questionKey: string;
        entityType: RemoteInterviewTemplateEntityType | null;
        challengeQuestion: {
          __typename?: "TemplateChallengeQuestionSource";
          questionId: number;
          questionVersion: string;
          uniqueKey: string;
        } | null;
        systemDesignQuestion: {
          __typename?: "TemplateSystemDesignQuestionSource";
          uniqueKey: string;
          questionId: number;
          componentTypes: Array<SystemDesignComponentType>;
        } | null;
        freepadQuestion: {
          __typename?: "TemplateFreepadQuestionSource";
          questionId: number;
          questionVersion: string;
          uniqueKey: string;
        } | null;
      }>;
      templateQuestions: Array<
        | {
            __typename: "AlgorithmQuestion";
            questionId: number;
            version: string;
            key: string;
            titleJa: string | null;
            titleEn: string | null;
            supportedLanguages: Array<string>;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            timeLimitSeconds: number | null;
            numUses: number;
            isSupportedLeakScore: boolean;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            algorithmQuestionVariant: AlgorithmQuestionVariant;
            algorithmQuestionStatus: QuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          }
        | {
            __typename: "FreepadQuestion";
            key: string;
            freepadQuestionId: number;
            version: string;
            titleJa: string | null;
            titleEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            numUses: number;
            freepadQuestionStatus: QuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          }
        | {
            __typename: "SystemDesignQuestion";
            questionId: number;
            key: string;
            titleJa: string | null;
            titleEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            timeLimitSeconds: number | null;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            systemDesignQuestionStatus: SystemDesignQuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          }
      >;
      variables: Array<{
        __typename?: "TemplateVariable";
        id: string;
        key: number;
        templateId: number;
        name: string;
        description: string;
      }> | null;
    }>;
    pager: { __typename?: "TemplatePager"; idCursor: number | null; hasNext: boolean } | null;
  } | null;
};

export type FillTemplateForRemoteInterviewResourceEditorMutationVariables = Exact<{
  input: FillTemplateInput;
}>;

export type FillTemplateForRemoteInterviewResourceEditorMutation = {
  __typename?: "Mutation";
  fillRemoteInterviewTemplate: {
    __typename?: "RemoteInterviewTemplate";
    id: string;
    templateId: number;
    templateName: string;
    description: string;
    remoteName: string;
    candidateName: string;
    tagNames: Array<string>;
    memo: string | null;
    isPublic: boolean;
    reviewers: Array<{
      __typename?: "RemoteReviewer";
      type: ReviewerType;
      employee: { __typename?: "User"; id: string; displayName: string } | null;
      group: { __typename?: "EmployeeGroup"; id: string; groupName: string } | null;
    }>;
    questions: Array<{
      __typename?: "RemoteInterviewTemplateEntitySource";
      questionId: number;
      questionKey: string;
      entityType: RemoteInterviewTemplateEntityType | null;
      challengeQuestion: {
        __typename?: "TemplateChallengeQuestionSource";
        questionId: number;
        questionVersion: string;
        uniqueKey: string;
      } | null;
      systemDesignQuestion: {
        __typename?: "TemplateSystemDesignQuestionSource";
        uniqueKey: string;
        questionId: number;
        componentTypes: Array<SystemDesignComponentType>;
      } | null;
      freepadQuestion: { __typename?: "TemplateFreepadQuestionSource"; questionId: number; questionVersion: string; uniqueKey: string } | null;
    }>;
    templateQuestions: Array<
      | {
          __typename: "AlgorithmQuestion";
          questionId: number;
          version: string;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          supportedLanguages: Array<string>;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          isSupportedLeakScore: boolean;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          algorithmQuestionVariant: AlgorithmQuestionVariant;
          algorithmQuestionStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | {
          __typename: "FreepadQuestion";
          key: string;
          freepadQuestionId: number;
          version: string;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          numUses: number;
          freepadQuestionStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | {
          __typename: "SystemDesignQuestion";
          questionId: number;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          systemDesignQuestionStatus: SystemDesignQuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
    >;
    variables: Array<{
      __typename?: "TemplateVariable";
      id: string;
      key: number;
      templateId: number;
      name: string;
      description: string;
    }> | null;
  };
};

export type GetTemplateForRemoteInterviewResourceEditorQueryVariables = Exact<{
  templateId: Scalars["Int"]["input"];
}>;

export type GetTemplateForRemoteInterviewResourceEditorQuery = {
  __typename?: "Query";
  remoteInterviewTemplate: {
    __typename?: "RemoteInterviewTemplate";
    id: string;
    templateId: number;
    templateName: string;
    description: string;
    remoteName: string;
    candidateName: string;
    tagNames: Array<string>;
    memo: string | null;
    isPublic: boolean;
    reviewers: Array<{
      __typename?: "RemoteReviewer";
      type: ReviewerType;
      employee: { __typename?: "User"; id: string; displayName: string } | null;
      group: { __typename?: "EmployeeGroup"; id: string; groupName: string } | null;
    }>;
    questions: Array<{
      __typename?: "RemoteInterviewTemplateEntitySource";
      questionId: number;
      questionKey: string;
      entityType: RemoteInterviewTemplateEntityType | null;
      challengeQuestion: {
        __typename?: "TemplateChallengeQuestionSource";
        questionId: number;
        questionVersion: string;
        uniqueKey: string;
      } | null;
      systemDesignQuestion: {
        __typename?: "TemplateSystemDesignQuestionSource";
        uniqueKey: string;
        questionId: number;
        componentTypes: Array<SystemDesignComponentType>;
      } | null;
      freepadQuestion: { __typename?: "TemplateFreepadQuestionSource"; questionId: number; questionVersion: string; uniqueKey: string } | null;
    }>;
    templateQuestions: Array<
      | {
          __typename: "AlgorithmQuestion";
          questionId: number;
          version: string;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          supportedLanguages: Array<string>;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          isSupportedLeakScore: boolean;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          algorithmQuestionVariant: AlgorithmQuestionVariant;
          algorithmQuestionStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | {
          __typename: "FreepadQuestion";
          key: string;
          freepadQuestionId: number;
          version: string;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          numUses: number;
          freepadQuestionStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | {
          __typename: "SystemDesignQuestion";
          questionId: number;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          systemDesignQuestionStatus: SystemDesignQuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
    >;
    variables: Array<{
      __typename?: "TemplateVariable";
      id: string;
      key: number;
      templateId: number;
      name: string;
      description: string;
    }> | null;
  };
};

export type RemoteTagsByCompanyIdResponseForRemoteInterviewTemplateResourceEditorFragment = {
  __typename?: "RemoteTagsByCompanyIdResponse";
  count: number;
  tags: Array<{ __typename?: "RemoteTag"; name: string; companyId: number }>;
  pager: { __typename?: "RemotePager"; cursorSeconds: number | null; hasNext: boolean } | null;
};

export type ListRemoteTagsForRemoteInterviewTemplateResourceEditorQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  cursorSeconds: InputMaybe<Scalars["Int"]["input"]>;
  size: Scalars["Int"]["input"];
  withCount: Scalars["Boolean"]["input"];
}>;

export type ListRemoteTagsForRemoteInterviewTemplateResourceEditorQuery = {
  __typename?: "Query";
  remoteTagsByCompanyId: {
    __typename?: "RemoteTagsByCompanyIdResponse";
    count: number;
    tags: Array<{ __typename?: "RemoteTag"; name: string; companyId: number }>;
    pager: { __typename?: "RemotePager"; cursorSeconds: number | null; hasNext: boolean } | null;
  };
};

export type ListRemoteInterviewTemplatesResourceEditorQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  withCount: Scalars["Boolean"]["input"];
  withDeleted: Scalars["Boolean"]["input"];
  sortMethod: RemoteSortMethod;
  size: Scalars["Int"]["input"];
  offset: Scalars["Int"]["input"];
  isDescending: Scalars["Boolean"]["input"];
}>;

export type ListRemoteInterviewTemplatesResourceEditorQuery = {
  __typename?: "Query";
  remotesByCompanyId: {
    __typename?: "RemotesByCompanyIdResponse";
    count: number;
    hasNext: boolean;
    remotes: Array<{ __typename?: "Remote"; id: string; name: string }>;
  };
};

export type AlgorithmQuestionForRemoteInterviewTemplateResourceEditorFragment = {
  __typename: "AlgorithmQuestion";
  id: string;
  questionId: number;
  version: string;
  key: string;
  companyId: number;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  status: QuestionStatus;
  supportedLanguages: Array<string>;
  difficulty: Difficulty;
  isPrivate: boolean;
  isOfficial: boolean;
  timeLimitSeconds: number | null;
  numUses: number;
  accuracyRate: number;
  averageElapsedTimeSeconds: number;
};

export type SystemDesignQuestionForRemoteInterviewTemplateResourceEditorFragment = {
  __typename: "SystemDesignQuestion";
  id: string;
  questionId: number;
  key: string;
  companyId: number;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  difficulty: Difficulty;
  isPrivate: boolean;
  isOfficial: boolean;
  timeLimitSeconds: number | null;
  numUses: number;
  accuracyRate: number;
  averageElapsedTimeSeconds: number;
};

export type FreepadQuestionForRemoteInterviewTemplateResourceEditorFragment = {
  __typename: "FreepadQuestion";
  id: string;
  freepadQuestionId: number;
  key: string;
  version: string;
  variant: FreepadQuestionVariant;
  companyId: number;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  difficulty: Difficulty;
  isPrivate: boolean;
  status: QuestionStatus;
  isOfficial: boolean;
  numUses: number;
  averageElapsedTimeSeconds: number;
};

export type RemoteForRemoteInterviewTemplateResourceEditorFragment = {
  __typename?: "Remote";
  id: string;
  name: string;
  candidateName: string;
  memo: string | null;
  status: RemoteStatus;
  isPublic: boolean;
  viewers: Array<
    { __typename: "EmployeeGroup"; employeeGroupId: string; groupName: string } | { __typename: "User"; uid: string; displayName: string }
  >;
  tags: Array<{ __typename?: "RemoteTag"; name: string }>;
  entity: {
    __typename?: "RemoteEntity";
    entityType: RemoteEntityType;
    liveCoding: {
      __typename?: "LiveCoding";
      sessions: Array<{
        __typename?: "LiveCodingSession";
        questionType: LiveCodingQuestionType;
        questionVariant: LiveCodingQuestionVariant | null;
        algorithmQuestion: {
          __typename: "AlgorithmQuestion";
          id: string;
          questionId: number;
          version: string;
          key: string;
          companyId: number;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          status: QuestionStatus;
          supportedLanguages: Array<string>;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
        } | null;
        systemDesignQuestion: {
          __typename: "SystemDesignQuestion";
          id: string;
          questionId: number;
          key: string;
          companyId: number;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
        } | null;
        freepadQuestion: {
          __typename: "FreepadQuestion";
          id: string;
          freepadQuestionId: number;
          key: string;
          version: string;
          variant: FreepadQuestionVariant;
          companyId: number;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          status: QuestionStatus;
          isOfficial: boolean;
          numUses: number;
          averageElapsedTimeSeconds: number;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type GetRemoteForRemoteInterviewTemplateResourceEditorQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type GetRemoteForRemoteInterviewTemplateResourceEditorQuery = {
  __typename?: "Query";
  remote: {
    __typename?: "Remote";
    id: string;
    name: string;
    candidateName: string;
    memo: string | null;
    status: RemoteStatus;
    isPublic: boolean;
    viewers: Array<
      { __typename: "EmployeeGroup"; employeeGroupId: string; groupName: string } | { __typename: "User"; uid: string; displayName: string }
    >;
    tags: Array<{ __typename?: "RemoteTag"; name: string }>;
    entity: {
      __typename?: "RemoteEntity";
      entityType: RemoteEntityType;
      liveCoding: {
        __typename?: "LiveCoding";
        sessions: Array<{
          __typename?: "LiveCodingSession";
          questionType: LiveCodingQuestionType;
          questionVariant: LiveCodingQuestionVariant | null;
          algorithmQuestion: {
            __typename: "AlgorithmQuestion";
            id: string;
            questionId: number;
            version: string;
            key: string;
            companyId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            status: QuestionStatus;
            supportedLanguages: Array<string>;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            timeLimitSeconds: number | null;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
          } | null;
          systemDesignQuestion: {
            __typename: "SystemDesignQuestion";
            id: string;
            questionId: number;
            key: string;
            companyId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            timeLimitSeconds: number | null;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
          } | null;
          freepadQuestion: {
            __typename: "FreepadQuestion";
            id: string;
            freepadQuestionId: number;
            key: string;
            version: string;
            variant: FreepadQuestionVariant;
            companyId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            status: QuestionStatus;
            isOfficial: boolean;
            numUses: number;
            averageElapsedTimeSeconds: number;
          } | null;
        } | null>;
      } | null;
    } | null;
  };
};

export type RemoteInterviewTemplateForRemoteInterviewTemplateResourceEditorFragment = {
  __typename?: "RemoteInterviewTemplate";
  id: string;
  templateName: string;
  remoteName: string;
  candidateName: string;
  description: string;
  memo: string | null;
  isPublic: boolean;
  tagNames: Array<string>;
  reviewers: Array<{
    __typename?: "RemoteReviewer";
    type: ReviewerType;
    employee: { __typename?: "User"; id: string; displayName: string } | null;
    group: { __typename?: "EmployeeGroup"; id: string; groupName: string } | null;
  }>;
  templateQuestions: Array<
    | {
        __typename: "AlgorithmQuestion";
        id: string;
        questionId: number;
        version: string;
        key: string;
        companyId: number;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        status: QuestionStatus;
        supportedLanguages: Array<string>;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        timeLimitSeconds: number | null;
        numUses: number;
        accuracyRate: number;
        averageElapsedTimeSeconds: number;
      }
    | {
        __typename: "FreepadQuestion";
        id: string;
        freepadQuestionId: number;
        key: string;
        version: string;
        variant: FreepadQuestionVariant;
        companyId: number;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        difficulty: Difficulty;
        isPrivate: boolean;
        status: QuestionStatus;
        isOfficial: boolean;
        numUses: number;
        averageElapsedTimeSeconds: number;
      }
    | {
        __typename: "SystemDesignQuestion";
        id: string;
        questionId: number;
        key: string;
        companyId: number;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        timeLimitSeconds: number | null;
        numUses: number;
        accuracyRate: number;
        averageElapsedTimeSeconds: number;
      }
  >;
  questions: Array<{
    __typename: "RemoteInterviewTemplateEntitySource";
    questionId: number;
    questionKey: string;
    entityType: RemoteInterviewTemplateEntityType | null;
    challengeQuestion: {
      __typename?: "TemplateChallengeQuestionSource";
      questionId: number;
      uniqueKey: string;
      enabledLanguages: Array<string>;
    } | null;
    systemDesignQuestion: {
      __typename?: "TemplateSystemDesignQuestionSource";
      questionId: number;
      componentTypes: Array<SystemDesignComponentType>;
    } | null;
  }>;
  variables: Array<{ __typename?: "TemplateVariable"; id: string; templateId: number; name: string; description: string }> | null;
};

export type GetRemoteInterviewTemplateForRemoteInterviewTemplateResourceEditorQueryVariables = Exact<{
  templateId: Scalars["Int"]["input"];
}>;

export type GetRemoteInterviewTemplateForRemoteInterviewTemplateResourceEditorQuery = {
  __typename?: "Query";
  remoteInterviewTemplate: {
    __typename?: "RemoteInterviewTemplate";
    id: string;
    templateName: string;
    remoteName: string;
    candidateName: string;
    description: string;
    memo: string | null;
    isPublic: boolean;
    tagNames: Array<string>;
    reviewers: Array<{
      __typename?: "RemoteReviewer";
      type: ReviewerType;
      employee: { __typename?: "User"; id: string; displayName: string } | null;
      group: { __typename?: "EmployeeGroup"; id: string; groupName: string } | null;
    }>;
    templateQuestions: Array<
      | {
          __typename: "AlgorithmQuestion";
          id: string;
          questionId: number;
          version: string;
          key: string;
          companyId: number;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          status: QuestionStatus;
          supportedLanguages: Array<string>;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
        }
      | {
          __typename: "FreepadQuestion";
          id: string;
          freepadQuestionId: number;
          key: string;
          version: string;
          variant: FreepadQuestionVariant;
          companyId: number;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          status: QuestionStatus;
          isOfficial: boolean;
          numUses: number;
          averageElapsedTimeSeconds: number;
        }
      | {
          __typename: "SystemDesignQuestion";
          id: string;
          questionId: number;
          key: string;
          companyId: number;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
        }
    >;
    questions: Array<{
      __typename: "RemoteInterviewTemplateEntitySource";
      questionId: number;
      questionKey: string;
      entityType: RemoteInterviewTemplateEntityType | null;
      challengeQuestion: {
        __typename?: "TemplateChallengeQuestionSource";
        questionId: number;
        uniqueKey: string;
        enabledLanguages: Array<string>;
      } | null;
      systemDesignQuestion: {
        __typename?: "TemplateSystemDesignQuestionSource";
        questionId: number;
        componentTypes: Array<SystemDesignComponentType>;
      } | null;
    }>;
    variables: Array<{ __typename?: "TemplateVariable"; id: string; templateId: number; name: string; description: string }> | null;
  };
};

export type RemoteInterviewTemplateForRemoteTemplateResourceEditorFragment = {
  __typename?: "RemoteInterviewTemplate";
  id: string;
  templateName: string;
  remoteName: string;
  candidateName: string;
  description: string;
  memo: string | null;
  isPublic: boolean;
  tagNames: Array<string>;
  reviewers: Array<{
    __typename?: "RemoteReviewer";
    type: ReviewerType;
    employee: { __typename?: "User"; id: string; displayName: string } | null;
    group: { __typename?: "EmployeeGroup"; id: string; groupName: string } | null;
  }>;
  templateQuestions: Array<
    | {
        __typename: "AlgorithmQuestion";
        questionId: number;
        version: string;
        key: string;
        titleJa: string | null;
        titleEn: string | null;
        supportedLanguages: Array<string>;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        timeLimitSeconds: number | null;
        numUses: number;
        isSupportedLeakScore: boolean;
        accuracyRate: number;
        averageElapsedTimeSeconds: number;
        algorithmQuestionVariant: AlgorithmQuestionVariant;
        algorithmQuestionStatus: QuestionStatus;
        leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
      }
    | {
        __typename: "FreepadQuestion";
        key: string;
        freepadQuestionId: number;
        version: string;
        titleJa: string | null;
        titleEn: string | null;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        isSupportedLeakScore: boolean;
        numUses: number;
        freepadQuestionStatus: QuestionStatus;
        leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
      }
    | {
        __typename: "SystemDesignQuestion";
        questionId: number;
        key: string;
        titleJa: string | null;
        titleEn: string | null;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        isSupportedLeakScore: boolean;
        timeLimitSeconds: number | null;
        numUses: number;
        accuracyRate: number;
        averageElapsedTimeSeconds: number;
        systemDesignQuestionStatus: SystemDesignQuestionStatus;
        leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
      }
  >;
  questions: Array<{
    __typename: "RemoteInterviewTemplateEntitySource";
    questionId: number;
    questionKey: string;
    entityType: RemoteInterviewTemplateEntityType | null;
    challengeQuestion: {
      __typename?: "TemplateChallengeQuestionSource";
      questionId: number;
      uniqueKey: string;
      enabledLanguages: Array<string>;
    } | null;
    systemDesignQuestion: {
      __typename?: "TemplateSystemDesignQuestionSource";
      questionId: number;
      componentTypes: Array<SystemDesignComponentType>;
    } | null;
  }>;
  variables: Array<{ __typename?: "TemplateVariable"; id: string; templateId: number; name: string; description: string }> | null;
};

export type GetRemoteInterviewTemplateForRemoteTemplateResourceEditorQueryVariables = Exact<{
  templateId: Scalars["Int"]["input"];
}>;

export type GetRemoteInterviewTemplateForRemoteTemplateResourceEditorQuery = {
  __typename?: "Query";
  remoteInterviewTemplate: {
    __typename?: "RemoteInterviewTemplate";
    id: string;
    templateName: string;
    remoteName: string;
    candidateName: string;
    description: string;
    memo: string | null;
    isPublic: boolean;
    tagNames: Array<string>;
    reviewers: Array<{
      __typename?: "RemoteReviewer";
      type: ReviewerType;
      employee: { __typename?: "User"; id: string; displayName: string } | null;
      group: { __typename?: "EmployeeGroup"; id: string; groupName: string } | null;
    }>;
    templateQuestions: Array<
      | {
          __typename: "AlgorithmQuestion";
          questionId: number;
          version: string;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          supportedLanguages: Array<string>;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          isSupportedLeakScore: boolean;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          algorithmQuestionVariant: AlgorithmQuestionVariant;
          algorithmQuestionStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | {
          __typename: "FreepadQuestion";
          key: string;
          freepadQuestionId: number;
          version: string;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          numUses: number;
          freepadQuestionStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | {
          __typename: "SystemDesignQuestion";
          questionId: number;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          systemDesignQuestionStatus: SystemDesignQuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
    >;
    questions: Array<{
      __typename: "RemoteInterviewTemplateEntitySource";
      questionId: number;
      questionKey: string;
      entityType: RemoteInterviewTemplateEntityType | null;
      challengeQuestion: {
        __typename?: "TemplateChallengeQuestionSource";
        questionId: number;
        uniqueKey: string;
        enabledLanguages: Array<string>;
      } | null;
      systemDesignQuestion: {
        __typename?: "TemplateSystemDesignQuestionSource";
        questionId: number;
        componentTypes: Array<SystemDesignComponentType>;
      } | null;
    }>;
    variables: Array<{ __typename?: "TemplateVariable"; id: string; templateId: number; name: string; description: string }> | null;
  };
};

export type RemoteForRemoteTemplateResourceEditorFragment = {
  __typename?: "Remote";
  id: string;
  name: string;
  candidateName: string;
  memo: string | null;
  status: RemoteStatus;
  isPublic: boolean;
  viewers: Array<
    { __typename: "EmployeeGroup"; employeeGroupId: string; groupName: string } | { __typename: "User"; uid: string; displayName: string }
  >;
  tags: Array<{ __typename?: "RemoteTag"; name: string }>;
  entity: {
    __typename?: "RemoteEntity";
    entityType: RemoteEntityType;
    liveCoding: {
      __typename?: "LiveCoding";
      sessions: Array<{
        __typename?: "LiveCodingSession";
        questionType: LiveCodingQuestionType;
        questionVariant: LiveCodingQuestionVariant | null;
        algorithmQuestion: {
          __typename: "AlgorithmQuestion";
          questionId: number;
          version: string;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          supportedLanguages: Array<string>;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          isSupportedLeakScore: boolean;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          algorithmQuestionVariant: AlgorithmQuestionVariant;
          algorithmQuestionStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        } | null;
        systemDesignQuestion: {
          __typename: "SystemDesignQuestion";
          questionId: number;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          systemDesignQuestionStatus: SystemDesignQuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        } | null;
        freepadQuestion: {
          __typename: "FreepadQuestion";
          key: string;
          freepadQuestionId: number;
          version: string;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          numUses: number;
          freepadQuestionStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type GetRemoteForRemoteTemplateResourceEditorQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type GetRemoteForRemoteTemplateResourceEditorQuery = {
  __typename?: "Query";
  remote: {
    __typename?: "Remote";
    id: string;
    name: string;
    candidateName: string;
    memo: string | null;
    status: RemoteStatus;
    isPublic: boolean;
    viewers: Array<
      { __typename: "EmployeeGroup"; employeeGroupId: string; groupName: string } | { __typename: "User"; uid: string; displayName: string }
    >;
    tags: Array<{ __typename?: "RemoteTag"; name: string }>;
    entity: {
      __typename?: "RemoteEntity";
      entityType: RemoteEntityType;
      liveCoding: {
        __typename?: "LiveCoding";
        sessions: Array<{
          __typename?: "LiveCodingSession";
          questionType: LiveCodingQuestionType;
          questionVariant: LiveCodingQuestionVariant | null;
          algorithmQuestion: {
            __typename: "AlgorithmQuestion";
            questionId: number;
            version: string;
            key: string;
            titleJa: string | null;
            titleEn: string | null;
            supportedLanguages: Array<string>;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            timeLimitSeconds: number | null;
            numUses: number;
            isSupportedLeakScore: boolean;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            algorithmQuestionVariant: AlgorithmQuestionVariant;
            algorithmQuestionStatus: QuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          } | null;
          systemDesignQuestion: {
            __typename: "SystemDesignQuestion";
            questionId: number;
            key: string;
            titleJa: string | null;
            titleEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            timeLimitSeconds: number | null;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            systemDesignQuestionStatus: SystemDesignQuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          } | null;
          freepadQuestion: {
            __typename: "FreepadQuestion";
            key: string;
            freepadQuestionId: number;
            version: string;
            titleJa: string | null;
            titleEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            numUses: number;
            freepadQuestionStatus: QuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          } | null;
        } | null>;
      } | null;
    } | null;
  };
};

export type AnalyzeRemoteInterviewTemplateResourceEditorMutationVariables = Exact<{
  analyzeTemplateInput: AnalyzeRemoteInterviewTemplateInput;
}>;

export type AnalyzeRemoteInterviewTemplateResourceEditorMutation = {
  __typename?: "Mutation";
  analyzeRemoteInterviewTemplate: Array<{
    __typename?: "TemplateVariable";
    id: string;
    templateId: number;
    name: string;
    description: string;
  }> | null;
};

export type QuestionObjectForRemoteInterviewTemplateResourceEditorFragment = {
  __typename?: "QuestionObject";
  id: string;
  questionVariant: QuestionVariant;
  liveCodingQuestion:
    | {
        __typename: "AlgorithmQuestion";
        id: string;
        questionId: number;
        version: string;
        key: string;
        companyId: number;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        status: QuestionStatus;
        supportedLanguages: Array<string>;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        timeLimitSeconds: number | null;
        numUses: number;
        accuracyRate: number;
        averageElapsedTimeSeconds: number;
      }
    | {
        __typename: "FreepadQuestion";
        id: string;
        freepadQuestionId: number;
        key: string;
        version: string;
        variant: FreepadQuestionVariant;
        companyId: number;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        difficulty: Difficulty;
        isPrivate: boolean;
        status: QuestionStatus;
        isOfficial: boolean;
        numUses: number;
        averageElapsedTimeSeconds: number;
      }
    | {
        __typename: "SystemDesignQuestion";
        id: string;
        questionId: number;
        key: string;
        companyId: number;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        timeLimitSeconds: number | null;
        numUses: number;
        accuracyRate: number;
        averageElapsedTimeSeconds: number;
      }
    | null;
};

export type ListQuestionsForForRemoteInterviewTemplateResourceEditorQueryVariables = Exact<{
  questionsInput: QuestionsInput;
}>;

export type ListQuestionsForForRemoteInterviewTemplateResourceEditorQuery = {
  __typename?: "Query";
  questions: {
    __typename?: "Questions";
    count: number;
    hasNext: boolean;
    questionObjects: Array<{
      __typename?: "QuestionObject";
      id: string;
      questionVariant: QuestionVariant;
      liveCodingQuestion:
        | {
            __typename: "AlgorithmQuestion";
            id: string;
            questionId: number;
            version: string;
            key: string;
            companyId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            status: QuestionStatus;
            supportedLanguages: Array<string>;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            timeLimitSeconds: number | null;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
          }
        | {
            __typename: "FreepadQuestion";
            id: string;
            freepadQuestionId: number;
            key: string;
            version: string;
            variant: FreepadQuestionVariant;
            companyId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            status: QuestionStatus;
            isOfficial: boolean;
            numUses: number;
            averageElapsedTimeSeconds: number;
          }
        | {
            __typename: "SystemDesignQuestion";
            id: string;
            questionId: number;
            key: string;
            companyId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            timeLimitSeconds: number | null;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
          }
        | null;
    }>;
  };
};

export type InterviewFeedbackRecommendationForCandidateInterviewFeedbackFragment = {
  __typename?: "EvaluationRecommendation";
  id: string;
  employeeId: string;
  evaluationRecommendationId: number;
  comment: string;
  evaluations: Array<{
    __typename?: "Evaluation";
    id: string;
    evaluationId: number;
    employeeId: string;
    metricId: number;
    comment: string;
    numStars: number;
    metric: { __typename?: "EvaluationMetric"; id: string; title: string; metricId: number; description: string } | null;
  }>;
};

export type InterviewFeedbackForCandidateInterviewFeedbackFragment = {
  __typename?: "Spot";
  id: string;
  recommendations: Array<{
    __typename?: "EvaluationRecommendation";
    id: string;
    employeeId: string;
    evaluationRecommendationId: number;
    comment: string;
    evaluations: Array<{
      __typename?: "Evaluation";
      id: string;
      evaluationId: number;
      employeeId: string;
      metricId: number;
      comment: string;
      numStars: number;
      metric: { __typename?: "EvaluationMetric"; id: string; title: string; metricId: number; description: string } | null;
    }>;
  }>;
};

export type GetInterviewFeedbackForCandidateInterviewFeedbackQueryVariables = Exact<{
  id: Scalars["String"]["input"];
  withAnswers: Scalars["Boolean"]["input"];
}>;

export type GetInterviewFeedbackForCandidateInterviewFeedbackQuery = {
  __typename?: "Query";
  spot: {
    __typename?: "Spot";
    id: string;
    recommendations: Array<{
      __typename?: "EvaluationRecommendation";
      id: string;
      employeeId: string;
      evaluationRecommendationId: number;
      comment: string;
      evaluations: Array<{
        __typename?: "Evaluation";
        id: string;
        evaluationId: number;
        employeeId: string;
        metricId: number;
        comment: string;
        numStars: number;
        metric: { __typename?: "EvaluationMetric"; id: string; title: string; metricId: number; description: string } | null;
      }>;
    }>;
  };
};

export type InterviewReportFromSharedLinkFragment = {
  __typename?: "Spot";
  id: string;
  status: SpotStatus;
  entityCount: number;
  totalScore: number;
  customScore: number;
  relativeScore: number;
  didStartAtSeconds: number | null;
  rank: number;
  numSubset: number;
  rankEvaluation: RankEvaluation;
  isReliableRank: boolean;
  finalizedAtSeconds: number | null;
  evaluatedAtSeconds: number | null;
  company: { __typename?: "Company"; companyId: number } | null;
  entities: Array<
    | {
        __typename?: "ChallengeEntity";
        id: string;
        entityId: number;
        challengeEntityId: number;
        totalScore: number;
        timeLimitSeconds: number | null;
        appealMessage: string | null;
        suspiciousDegree: ChallengeSuspiciousDegree;
        challengeStatus: ChallengeStatus;
        submissions: Array<{
          __typename?: "ChallengeSubmission";
          id: string;
          challengeSubmissionId: number;
          questionId: number;
          questionVersion: string;
          isBest: boolean;
          runtime: string;
          runtimeVersion: string;
          correctnessTestResult: string;
          totalScore: number;
          numSubset: number;
          totalElapsedTimeSeconds: number | null;
          readabilityTestResult: string;
          evaluatedAtSeconds: number | null;
          coverage: number;
          performance: number;
          readability: number;
          runtimeComplexity: string;
          spaceComplexity: string;
          avgPerformance: number;
          avgMaxMemory: number;
          machineOs: string;
          machineMemory: string;
          machineCpu: number | null;
          numPassed: number;
          numTests: number;
          numTabEvents: number;
          numPasteEvents: number;
          numAccessEvents: number;
          similarityScore: number | null;
          code: string;
          elapsedTimeSeconds: number | null;
          performanceTestResult: string;
          challengeUsedHintCount: number;
          challengeSubmissionStatus: ChallengeSubmissionStatus;
          similarCodes: Array<{
            __typename?: "ChallengeSubmissionSimilarCode";
            code: string;
            score: number;
            metadata:
              | { __typename: "SimilarCodeCrawlerMetadata"; url: string }
              | { __typename: "SimilarCodeNotFoundMetadata"; reserved: boolean | null }
              | { __typename: "SimilarCodeOtherCompanyMetadata"; reserved: boolean | null }
              | { __typename: "SimilarCodeSpotMetadata"; spotId: string; challengeId: number; questionId: number; questionVersion: string };
            fragments: Array<{
              __typename?: "ChallengeSubmissionSimilarCodeFragment";
              left: {
                __typename?: "ChallengeSubmissionSimilarCodeSelection";
                endCol: number;
                endRow: number;
                startCol: number;
                startRow: number;
              };
              right: {
                __typename?: "ChallengeSubmissionSimilarCodeSelection";
                endCol: number;
                endRow: number;
                startCol: number;
                startRow: number;
              };
            }>;
          }>;
          snapshots: Array<{ __typename?: "ChallengeSnapshot"; id: string; challengeSnapshotId: number }>;
        }>;
        challengeQuestion: {
          __typename?: "AlgorithmQuestion";
          id: string;
          questionId: number;
          version: string;
          variant: AlgorithmQuestionVariant;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          descriptionEn: string | null;
          descriptionJa: string | null;
          correctnessTestCase: string;
          isSupportedLeakScore: boolean;
          numUses: number;
          supportedLanguages: Array<string>;
          timeLimitSeconds: number | null;
          averageElapsedTimeSeconds: number;
          accuracyRate: number;
          isOfficial: boolean;
          isPrivate: boolean;
          performanceTimeLimitMilliSeconds: number;
          algorithmQuestionStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          employee: { __typename?: "User"; id: string; displayName: string };
          initialCode: {
            __typename?: "AlgorithmInitialCode";
            c: string;
            cpp: string;
            csharp: string;
            dart: string;
            go: string;
            java: string;
            javascript: string;
            kotlin: string;
            mysql: string;
            perl: string;
            pgsql: string;
            php: string;
            python3: string;
            ruby: string;
            rust: string;
            scala: string;
            sqlite: string;
            swift: string;
            typescript: string;
          } | null;
          answers: Array<{
            __typename?: "AlgorithmAnswer";
            id: number;
            titleEn: string | null;
            titleJa: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            label: string;
            runtimeComplexity: string;
            spaceComplexity: string;
          }>;
          hints: Array<{
            __typename?: "AlgorithmHint";
            id: number;
            descriptionJa: string | null;
            descriptionEn: string | null;
            orderNum: number;
          }>;
        } | null;
        sessions: Array<
          | { __typename?: "ChallengeChatGPTSession"; sessionId: string; challengeId: number; language: string }
          | { __typename?: "ChallengeWebSession"; sessionId: string; challengeId: number; language: string }
        >;
        suspiciousInference: {
          __typename?: "SuspiciousInference";
          inferenceId: string;
          feedback: { __typename?: "SuspiciousInferenceFeedback"; isCorrect: boolean } | null;
        } | null;
      }
    | {
        __typename?: "ProjectEntity";
        id: string;
        entityId: number;
        projectEntityId: number;
        totalScore: number;
        evaluatedAtSeconds: number | null;
        agentVersion: string;
        appealMessage: string | null;
        projectStatus: ProjectStatus;
        question: {
          __typename?: "ProjectQuestion";
          id: string;
          questionId: number;
          version: string;
          projectVersion: string;
          variant: ProjectQuestionVariant;
          titleEn: string | null;
          titleJa: string | null;
          difficulty: Difficulty;
          descriptionJa: string | null;
          descriptionEn: string | null;
          correctnessTestCase: string;
          performanceTestCase: string;
          timeLimitSeconds: number | null;
          isPrivate: boolean;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          isOfficial: boolean;
          projectQuestionStatus: ProjectQuestionStatus;
          answers: Array<{
            __typename?: "ProjectAnswer";
            titleJa: string;
            titleEn: string;
            descriptionJa: string;
            descriptionEn: string;
            answerId: number;
          }>;
        } | null;
        submissions: Array<{
          __typename?: "ProjectSubmission";
          projectSubmissionId: number;
          numTests: number;
          numPassed: number;
          numTabEvents: number;
          numAccessEvents: number;
          totalScore: number;
          numSubset: number;
          isBest: boolean;
          testResult: string;
          avgLatency: number;
          avgSla: number;
          coverage: number;
          performance: number;
          correctnessTestResult: string;
          performanceTestResult: string;
          metrics: string;
          failureReason: ProjectFailureReason;
          totalElapsedTimeSeconds: number | null;
          elapsedTimeSeconds: number | null;
          evaluatedAtSeconds: number | null;
          submissionStatus: ProjectSubmissionStatus;
          snapshots: Array<{ __typename?: "ProjectSnapshot"; testCase: string; testResult: string; isPassed: boolean }>;
          diffs: Array<{
            __typename?: "ProjectDiff";
            projectDiffId: number;
            operation: ProjectOperation;
            fromPath: string;
            toPath: string;
            fromLink: string;
            toLink: string;
          }>;
        }>;
      }
    | {
        __typename?: "QuizEntity";
        id: string;
        entityId: number;
        quizEntityId: number;
        totalScore: number;
        numSubset: number;
        timeLimitSeconds: number | null;
        evaluatedAtSeconds: number | null;
        elapsedTimeSeconds: number | null;
        quizStatus: QuizStatus;
        pb_package: {
          __typename?: "MultiChoicePackage";
          packageId: number;
          version: string;
          difficulty: Difficulty;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          numUses: number;
          timeLimitSeconds: number | null;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          isOfficial: boolean;
          isPrivate: boolean;
          multiChoicePackageStatus: QuestionStatus;
          employee: { __typename?: "User"; id: string; displayName: string };
          questions: Array<{
            __typename?: "MultiChoiceQuestion";
            id: number;
            version: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            correctAnswerRate: number;
            variant: MultiChoiceQuestionVariant;
            options: Array<{
              __typename?: "MultiChoiceOption";
              id: number;
              titleJa: string | null;
              titleEn: string | null;
              isCorrect: boolean;
              additionalDetailJa: string | null;
              additionalDetailEn: string | null;
            }>;
            evaluationItems: Array<{
              __typename?: "MultiChoiceEvaluationItem";
              id: number;
              questionId: number;
              questionVersion: string;
              titleJa: string | null;
              titleEn: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
            }>;
          }>;
        } | null;
        submissions: Array<{
          __typename?: "QuizSubmission";
          id: string;
          quizSubmissionId: number;
          quizId: number;
          packageId: number;
          packageVersion: string;
          questionId: number;
          questionVersion: string;
          optionIds: Array<number>;
          isCorrect: boolean;
          answerText: string;
          score: number;
          evaluatedBy: string;
          isAutomaticEvaluation: boolean;
          evaluatedAtSeconds: number | null;
          numTabEvents: number;
          numAccessEvents: number;
          elapsedTimeSeconds: number | null;
          createdAtSeconds: number | null;
          quizSubmissionStatus: QuizSubmissionStatus;
          evaluations: Array<{
            __typename?: "QuizEvaluation";
            submissionId: number;
            score: number;
            isPassed: boolean;
            reasonJa: string;
            reasonEn: string;
            evaluationItem: {
              __typename?: "MultiChoiceEvaluationItem";
              id: number;
              questionId: number;
              questionVersion: string;
              titleJa: string | null;
              titleEn: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
            } | null;
          }>;
          evaluatedUser: { __typename?: "User"; uid: string; displayName: string } | null;
        }>;
      }
    | {
        __typename?: "SystemDesignEntity";
        id: string;
        entityId: number;
        systemDesignEntityId: number;
        totalScore: number;
        evaluatedAtSeconds: number | null;
        appealMessage: string | null;
        systemDesignStatus: SystemDesignStatus;
        submissions: Array<{
          __typename?: "SystemDesignSubmission";
          systemDesignSubmissionId: number;
          isBest: boolean;
          status: SystemDesignSubmissionStatus;
          snapshot: string;
          numTabEvents: number;
          numAccessEvents: number;
          totalScore: number;
          numSubset: number;
          totalElapsedTimeSeconds: number | null;
          elapsedTimeSeconds: number | null;
          evaluatedAtSeconds: number | null;
          evaluations: Array<{
            __typename?: "SystemDesignEvaluation";
            totalScore: number;
            evaluatedBy: string;
            availability: number;
            scalability: number;
            consistency: number;
            result: string;
          }>;
          usedHints: Array<{ __typename?: "SystemDesignHint"; systemDesignHintId: number }>;
          snapshots: Array<{
            __typename?: "SystemDesignSnapshot";
            systemDesignSnapshotId: number;
            scoringItemId: number;
            isPassed: boolean;
            matchedIds: Array<string>;
          }>;
        }>;
        question: {
          __typename?: "SystemDesignQuestion";
          questionId: number;
          titleEn: string | null;
          titleJa: string | null;
          difficulty: Difficulty;
          descriptionJa: string | null;
          descriptionEn: string | null;
          initialSnapshot: string;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          timeLimitSeconds: number | null;
          isOfficial: boolean;
          systemDesignQuestionStatus: SystemDesignQuestionStatus;
          hints: Array<{ __typename?: "SystemDesignHint"; descriptionJa: string | null; descriptionEn: string | null }>;
          answers: Array<{
            __typename?: "SystemDesignAnswer";
            id: string;
            titleJa: string;
            titleEn: string;
            descriptionJa: string;
            descriptionEn: string;
          }>;
          scoringItems: Array<{
            __typename?: "SystemDesignScoringItem";
            systemDesignScoringItemId: number;
            category: SystemDesignScoringItemCategory;
            titleJa: string;
            titleEn: string;
            descriptionJa: string;
            descriptionEn: string;
            graphlets: string;
            isHidden: boolean;
          }>;
        } | null;
      }
  >;
  issuedEntities: Array<
    | {
        __typename?: "SpotIssuedChallengeEntity";
        questionWeight: number;
        entity: {
          __typename?: "ChallengeEntity";
          id: string;
          entityId: number;
          challengeEntityId: number;
          totalScore: number;
          timeLimitSeconds: number | null;
          appealMessage: string | null;
          suspiciousDegree: ChallengeSuspiciousDegree;
          challengeStatus: ChallengeStatus;
          submissions: Array<{
            __typename?: "ChallengeSubmission";
            id: string;
            challengeSubmissionId: number;
            questionId: number;
            questionVersion: string;
            isBest: boolean;
            runtime: string;
            runtimeVersion: string;
            correctnessTestResult: string;
            totalScore: number;
            numSubset: number;
            totalElapsedTimeSeconds: number | null;
            readabilityTestResult: string;
            evaluatedAtSeconds: number | null;
            coverage: number;
            performance: number;
            readability: number;
            runtimeComplexity: string;
            spaceComplexity: string;
            avgPerformance: number;
            avgMaxMemory: number;
            machineOs: string;
            machineMemory: string;
            machineCpu: number | null;
            numPassed: number;
            numTests: number;
            numTabEvents: number;
            numPasteEvents: number;
            numAccessEvents: number;
            similarityScore: number | null;
            code: string;
            elapsedTimeSeconds: number | null;
            performanceTestResult: string;
            challengeUsedHintCount: number;
            challengeSubmissionStatus: ChallengeSubmissionStatus;
            similarCodes: Array<{
              __typename?: "ChallengeSubmissionSimilarCode";
              code: string;
              score: number;
              metadata:
                | { __typename: "SimilarCodeCrawlerMetadata"; url: string }
                | { __typename: "SimilarCodeNotFoundMetadata"; reserved: boolean | null }
                | { __typename: "SimilarCodeOtherCompanyMetadata"; reserved: boolean | null }
                | { __typename: "SimilarCodeSpotMetadata"; spotId: string; challengeId: number; questionId: number; questionVersion: string };
              fragments: Array<{
                __typename?: "ChallengeSubmissionSimilarCodeFragment";
                left: {
                  __typename?: "ChallengeSubmissionSimilarCodeSelection";
                  endCol: number;
                  endRow: number;
                  startCol: number;
                  startRow: number;
                };
                right: {
                  __typename?: "ChallengeSubmissionSimilarCodeSelection";
                  endCol: number;
                  endRow: number;
                  startCol: number;
                  startRow: number;
                };
              }>;
            }>;
            snapshots: Array<{ __typename?: "ChallengeSnapshot"; id: string; challengeSnapshotId: number }>;
          }>;
          challengeQuestion: {
            __typename?: "AlgorithmQuestion";
            id: string;
            questionId: number;
            version: string;
            variant: AlgorithmQuestionVariant;
            titleJa: string | null;
            titleEn: string | null;
            difficulty: Difficulty;
            descriptionEn: string | null;
            descriptionJa: string | null;
            correctnessTestCase: string;
            isSupportedLeakScore: boolean;
            numUses: number;
            supportedLanguages: Array<string>;
            timeLimitSeconds: number | null;
            averageElapsedTimeSeconds: number;
            accuracyRate: number;
            isOfficial: boolean;
            isPrivate: boolean;
            performanceTimeLimitMilliSeconds: number;
            algorithmQuestionStatus: QuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
            employee: { __typename?: "User"; id: string; displayName: string };
            initialCode: {
              __typename?: "AlgorithmInitialCode";
              c: string;
              cpp: string;
              csharp: string;
              dart: string;
              go: string;
              java: string;
              javascript: string;
              kotlin: string;
              mysql: string;
              perl: string;
              pgsql: string;
              php: string;
              python3: string;
              ruby: string;
              rust: string;
              scala: string;
              sqlite: string;
              swift: string;
              typescript: string;
            } | null;
            answers: Array<{
              __typename?: "AlgorithmAnswer";
              id: number;
              titleEn: string | null;
              titleJa: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
              label: string;
              runtimeComplexity: string;
              spaceComplexity: string;
            }>;
            hints: Array<{
              __typename?: "AlgorithmHint";
              id: number;
              descriptionJa: string | null;
              descriptionEn: string | null;
              orderNum: number;
            }>;
          } | null;
          sessions: Array<
            | { __typename?: "ChallengeChatGPTSession"; sessionId: string; challengeId: number; language: string }
            | { __typename?: "ChallengeWebSession"; sessionId: string; challengeId: number; language: string }
          >;
          suspiciousInference: {
            __typename?: "SuspiciousInference";
            inferenceId: string;
            feedback: { __typename?: "SuspiciousInferenceFeedback"; isCorrect: boolean } | null;
          } | null;
        };
      }
    | {
        __typename?: "SpotIssuedProjectEntity";
        questionWeight: number;
        entity: {
          __typename?: "ProjectEntity";
          id: string;
          entityId: number;
          projectEntityId: number;
          totalScore: number;
          evaluatedAtSeconds: number | null;
          agentVersion: string;
          appealMessage: string | null;
          projectStatus: ProjectStatus;
          question: {
            __typename?: "ProjectQuestion";
            id: string;
            questionId: number;
            version: string;
            projectVersion: string;
            variant: ProjectQuestionVariant;
            titleEn: string | null;
            titleJa: string | null;
            difficulty: Difficulty;
            descriptionJa: string | null;
            descriptionEn: string | null;
            correctnessTestCase: string;
            performanceTestCase: string;
            timeLimitSeconds: number | null;
            isPrivate: boolean;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            isOfficial: boolean;
            projectQuestionStatus: ProjectQuestionStatus;
            answers: Array<{
              __typename?: "ProjectAnswer";
              titleJa: string;
              titleEn: string;
              descriptionJa: string;
              descriptionEn: string;
              answerId: number;
            }>;
          } | null;
          submissions: Array<{
            __typename?: "ProjectSubmission";
            projectSubmissionId: number;
            numTests: number;
            numPassed: number;
            numTabEvents: number;
            numAccessEvents: number;
            totalScore: number;
            numSubset: number;
            isBest: boolean;
            testResult: string;
            avgLatency: number;
            avgSla: number;
            coverage: number;
            performance: number;
            correctnessTestResult: string;
            performanceTestResult: string;
            metrics: string;
            failureReason: ProjectFailureReason;
            totalElapsedTimeSeconds: number | null;
            elapsedTimeSeconds: number | null;
            evaluatedAtSeconds: number | null;
            submissionStatus: ProjectSubmissionStatus;
            snapshots: Array<{ __typename?: "ProjectSnapshot"; testCase: string; testResult: string; isPassed: boolean }>;
            diffs: Array<{
              __typename?: "ProjectDiff";
              projectDiffId: number;
              operation: ProjectOperation;
              fromPath: string;
              toPath: string;
              fromLink: string;
              toLink: string;
            }>;
          }>;
        };
      }
    | {
        __typename?: "SpotIssuedQuizEntity";
        questionWeight: number;
        entity: {
          __typename?: "QuizEntity";
          id: string;
          entityId: number;
          quizEntityId: number;
          totalScore: number;
          numSubset: number;
          timeLimitSeconds: number | null;
          evaluatedAtSeconds: number | null;
          elapsedTimeSeconds: number | null;
          quizStatus: QuizStatus;
          pb_package: {
            __typename?: "MultiChoicePackage";
            packageId: number;
            version: string;
            difficulty: Difficulty;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            numUses: number;
            timeLimitSeconds: number | null;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            isOfficial: boolean;
            isPrivate: boolean;
            multiChoicePackageStatus: QuestionStatus;
            employee: { __typename?: "User"; id: string; displayName: string };
            questions: Array<{
              __typename?: "MultiChoiceQuestion";
              id: number;
              version: string;
              titleJa: string | null;
              titleEn: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
              correctAnswerRate: number;
              variant: MultiChoiceQuestionVariant;
              options: Array<{
                __typename?: "MultiChoiceOption";
                id: number;
                titleJa: string | null;
                titleEn: string | null;
                isCorrect: boolean;
                additionalDetailJa: string | null;
                additionalDetailEn: string | null;
              }>;
              evaluationItems: Array<{
                __typename?: "MultiChoiceEvaluationItem";
                id: number;
                questionId: number;
                questionVersion: string;
                titleJa: string | null;
                titleEn: string | null;
                descriptionJa: string | null;
                descriptionEn: string | null;
              }>;
            }>;
          } | null;
          submissions: Array<{
            __typename?: "QuizSubmission";
            id: string;
            quizSubmissionId: number;
            quizId: number;
            packageId: number;
            packageVersion: string;
            questionId: number;
            questionVersion: string;
            optionIds: Array<number>;
            isCorrect: boolean;
            answerText: string;
            score: number;
            evaluatedBy: string;
            isAutomaticEvaluation: boolean;
            evaluatedAtSeconds: number | null;
            numTabEvents: number;
            numAccessEvents: number;
            elapsedTimeSeconds: number | null;
            createdAtSeconds: number | null;
            quizSubmissionStatus: QuizSubmissionStatus;
            evaluations: Array<{
              __typename?: "QuizEvaluation";
              submissionId: number;
              score: number;
              isPassed: boolean;
              reasonJa: string;
              reasonEn: string;
              evaluationItem: {
                __typename?: "MultiChoiceEvaluationItem";
                id: number;
                questionId: number;
                questionVersion: string;
                titleJa: string | null;
                titleEn: string | null;
                descriptionJa: string | null;
                descriptionEn: string | null;
              } | null;
            }>;
            evaluatedUser: { __typename?: "User"; uid: string; displayName: string } | null;
          }>;
        };
      }
    | {
        __typename?: "SpotIssuedSystemDesignEntity";
        questionWeight: number;
        entity: {
          __typename?: "SystemDesignEntity";
          id: string;
          entityId: number;
          systemDesignEntityId: number;
          totalScore: number;
          evaluatedAtSeconds: number | null;
          appealMessage: string | null;
          systemDesignStatus: SystemDesignStatus;
          submissions: Array<{
            __typename?: "SystemDesignSubmission";
            systemDesignSubmissionId: number;
            isBest: boolean;
            status: SystemDesignSubmissionStatus;
            snapshot: string;
            numTabEvents: number;
            numAccessEvents: number;
            totalScore: number;
            numSubset: number;
            totalElapsedTimeSeconds: number | null;
            elapsedTimeSeconds: number | null;
            evaluatedAtSeconds: number | null;
            evaluations: Array<{
              __typename?: "SystemDesignEvaluation";
              totalScore: number;
              evaluatedBy: string;
              availability: number;
              scalability: number;
              consistency: number;
              result: string;
            }>;
            usedHints: Array<{ __typename?: "SystemDesignHint"; systemDesignHintId: number }>;
            snapshots: Array<{
              __typename?: "SystemDesignSnapshot";
              systemDesignSnapshotId: number;
              scoringItemId: number;
              isPassed: boolean;
              matchedIds: Array<string>;
            }>;
          }>;
          question: {
            __typename?: "SystemDesignQuestion";
            questionId: number;
            titleEn: string | null;
            titleJa: string | null;
            difficulty: Difficulty;
            descriptionJa: string | null;
            descriptionEn: string | null;
            initialSnapshot: string;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            timeLimitSeconds: number | null;
            isOfficial: boolean;
            systemDesignQuestionStatus: SystemDesignQuestionStatus;
            hints: Array<{ __typename?: "SystemDesignHint"; descriptionJa: string | null; descriptionEn: string | null }>;
            answers: Array<{
              __typename?: "SystemDesignAnswer";
              id: string;
              titleJa: string;
              titleEn: string;
              descriptionJa: string;
              descriptionEn: string;
            }>;
            scoringItems: Array<{
              __typename?: "SystemDesignScoringItem";
              systemDesignScoringItemId: number;
              category: SystemDesignScoringItemCategory;
              titleJa: string;
              titleEn: string;
              descriptionJa: string;
              descriptionEn: string;
              graphlets: string;
              isHidden: boolean;
            }>;
          } | null;
        };
      }
  >;
};

export type GetInterviewReportFromSharedLinkQueryVariables = Exact<{
  spotId: Scalars["String"]["input"];
  linkHash: Scalars["String"]["input"];
}>;

export type GetInterviewReportFromSharedLinkQuery = {
  __typename?: "Query";
  spotFromSharedLink: {
    __typename?: "Spot";
    id: string;
    status: SpotStatus;
    entityCount: number;
    totalScore: number;
    customScore: number;
    relativeScore: number;
    didStartAtSeconds: number | null;
    rank: number;
    numSubset: number;
    rankEvaluation: RankEvaluation;
    isReliableRank: boolean;
    finalizedAtSeconds: number | null;
    evaluatedAtSeconds: number | null;
    company: { __typename?: "Company"; companyId: number } | null;
    entities: Array<
      | {
          __typename?: "ChallengeEntity";
          id: string;
          entityId: number;
          challengeEntityId: number;
          totalScore: number;
          timeLimitSeconds: number | null;
          appealMessage: string | null;
          suspiciousDegree: ChallengeSuspiciousDegree;
          challengeStatus: ChallengeStatus;
          submissions: Array<{
            __typename?: "ChallengeSubmission";
            id: string;
            challengeSubmissionId: number;
            questionId: number;
            questionVersion: string;
            isBest: boolean;
            runtime: string;
            runtimeVersion: string;
            correctnessTestResult: string;
            totalScore: number;
            numSubset: number;
            totalElapsedTimeSeconds: number | null;
            readabilityTestResult: string;
            evaluatedAtSeconds: number | null;
            coverage: number;
            performance: number;
            readability: number;
            runtimeComplexity: string;
            spaceComplexity: string;
            avgPerformance: number;
            avgMaxMemory: number;
            machineOs: string;
            machineMemory: string;
            machineCpu: number | null;
            numPassed: number;
            numTests: number;
            numTabEvents: number;
            numPasteEvents: number;
            numAccessEvents: number;
            similarityScore: number | null;
            code: string;
            elapsedTimeSeconds: number | null;
            performanceTestResult: string;
            challengeUsedHintCount: number;
            challengeSubmissionStatus: ChallengeSubmissionStatus;
            similarCodes: Array<{
              __typename?: "ChallengeSubmissionSimilarCode";
              code: string;
              score: number;
              metadata:
                | { __typename: "SimilarCodeCrawlerMetadata"; url: string }
                | { __typename: "SimilarCodeNotFoundMetadata"; reserved: boolean | null }
                | { __typename: "SimilarCodeOtherCompanyMetadata"; reserved: boolean | null }
                | { __typename: "SimilarCodeSpotMetadata"; spotId: string; challengeId: number; questionId: number; questionVersion: string };
              fragments: Array<{
                __typename?: "ChallengeSubmissionSimilarCodeFragment";
                left: {
                  __typename?: "ChallengeSubmissionSimilarCodeSelection";
                  endCol: number;
                  endRow: number;
                  startCol: number;
                  startRow: number;
                };
                right: {
                  __typename?: "ChallengeSubmissionSimilarCodeSelection";
                  endCol: number;
                  endRow: number;
                  startCol: number;
                  startRow: number;
                };
              }>;
            }>;
            snapshots: Array<{ __typename?: "ChallengeSnapshot"; id: string; challengeSnapshotId: number }>;
          }>;
          challengeQuestion: {
            __typename?: "AlgorithmQuestion";
            id: string;
            questionId: number;
            version: string;
            variant: AlgorithmQuestionVariant;
            titleJa: string | null;
            titleEn: string | null;
            difficulty: Difficulty;
            descriptionEn: string | null;
            descriptionJa: string | null;
            correctnessTestCase: string;
            isSupportedLeakScore: boolean;
            numUses: number;
            supportedLanguages: Array<string>;
            timeLimitSeconds: number | null;
            averageElapsedTimeSeconds: number;
            accuracyRate: number;
            isOfficial: boolean;
            isPrivate: boolean;
            performanceTimeLimitMilliSeconds: number;
            algorithmQuestionStatus: QuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
            employee: { __typename?: "User"; id: string; displayName: string };
            initialCode: {
              __typename?: "AlgorithmInitialCode";
              c: string;
              cpp: string;
              csharp: string;
              dart: string;
              go: string;
              java: string;
              javascript: string;
              kotlin: string;
              mysql: string;
              perl: string;
              pgsql: string;
              php: string;
              python3: string;
              ruby: string;
              rust: string;
              scala: string;
              sqlite: string;
              swift: string;
              typescript: string;
            } | null;
            answers: Array<{
              __typename?: "AlgorithmAnswer";
              id: number;
              titleEn: string | null;
              titleJa: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
              label: string;
              runtimeComplexity: string;
              spaceComplexity: string;
            }>;
            hints: Array<{
              __typename?: "AlgorithmHint";
              id: number;
              descriptionJa: string | null;
              descriptionEn: string | null;
              orderNum: number;
            }>;
          } | null;
          sessions: Array<
            | { __typename?: "ChallengeChatGPTSession"; sessionId: string; challengeId: number; language: string }
            | { __typename?: "ChallengeWebSession"; sessionId: string; challengeId: number; language: string }
          >;
          suspiciousInference: {
            __typename?: "SuspiciousInference";
            inferenceId: string;
            feedback: { __typename?: "SuspiciousInferenceFeedback"; isCorrect: boolean } | null;
          } | null;
        }
      | {
          __typename?: "ProjectEntity";
          id: string;
          entityId: number;
          projectEntityId: number;
          totalScore: number;
          evaluatedAtSeconds: number | null;
          agentVersion: string;
          appealMessage: string | null;
          projectStatus: ProjectStatus;
          question: {
            __typename?: "ProjectQuestion";
            id: string;
            questionId: number;
            version: string;
            projectVersion: string;
            variant: ProjectQuestionVariant;
            titleEn: string | null;
            titleJa: string | null;
            difficulty: Difficulty;
            descriptionJa: string | null;
            descriptionEn: string | null;
            correctnessTestCase: string;
            performanceTestCase: string;
            timeLimitSeconds: number | null;
            isPrivate: boolean;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            isOfficial: boolean;
            projectQuestionStatus: ProjectQuestionStatus;
            answers: Array<{
              __typename?: "ProjectAnswer";
              titleJa: string;
              titleEn: string;
              descriptionJa: string;
              descriptionEn: string;
              answerId: number;
            }>;
          } | null;
          submissions: Array<{
            __typename?: "ProjectSubmission";
            projectSubmissionId: number;
            numTests: number;
            numPassed: number;
            numTabEvents: number;
            numAccessEvents: number;
            totalScore: number;
            numSubset: number;
            isBest: boolean;
            testResult: string;
            avgLatency: number;
            avgSla: number;
            coverage: number;
            performance: number;
            correctnessTestResult: string;
            performanceTestResult: string;
            metrics: string;
            failureReason: ProjectFailureReason;
            totalElapsedTimeSeconds: number | null;
            elapsedTimeSeconds: number | null;
            evaluatedAtSeconds: number | null;
            submissionStatus: ProjectSubmissionStatus;
            snapshots: Array<{ __typename?: "ProjectSnapshot"; testCase: string; testResult: string; isPassed: boolean }>;
            diffs: Array<{
              __typename?: "ProjectDiff";
              projectDiffId: number;
              operation: ProjectOperation;
              fromPath: string;
              toPath: string;
              fromLink: string;
              toLink: string;
            }>;
          }>;
        }
      | {
          __typename?: "QuizEntity";
          id: string;
          entityId: number;
          quizEntityId: number;
          totalScore: number;
          numSubset: number;
          timeLimitSeconds: number | null;
          evaluatedAtSeconds: number | null;
          elapsedTimeSeconds: number | null;
          quizStatus: QuizStatus;
          pb_package: {
            __typename?: "MultiChoicePackage";
            packageId: number;
            version: string;
            difficulty: Difficulty;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            numUses: number;
            timeLimitSeconds: number | null;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            isOfficial: boolean;
            isPrivate: boolean;
            multiChoicePackageStatus: QuestionStatus;
            employee: { __typename?: "User"; id: string; displayName: string };
            questions: Array<{
              __typename?: "MultiChoiceQuestion";
              id: number;
              version: string;
              titleJa: string | null;
              titleEn: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
              correctAnswerRate: number;
              variant: MultiChoiceQuestionVariant;
              options: Array<{
                __typename?: "MultiChoiceOption";
                id: number;
                titleJa: string | null;
                titleEn: string | null;
                isCorrect: boolean;
                additionalDetailJa: string | null;
                additionalDetailEn: string | null;
              }>;
              evaluationItems: Array<{
                __typename?: "MultiChoiceEvaluationItem";
                id: number;
                questionId: number;
                questionVersion: string;
                titleJa: string | null;
                titleEn: string | null;
                descriptionJa: string | null;
                descriptionEn: string | null;
              }>;
            }>;
          } | null;
          submissions: Array<{
            __typename?: "QuizSubmission";
            id: string;
            quizSubmissionId: number;
            quizId: number;
            packageId: number;
            packageVersion: string;
            questionId: number;
            questionVersion: string;
            optionIds: Array<number>;
            isCorrect: boolean;
            answerText: string;
            score: number;
            evaluatedBy: string;
            isAutomaticEvaluation: boolean;
            evaluatedAtSeconds: number | null;
            numTabEvents: number;
            numAccessEvents: number;
            elapsedTimeSeconds: number | null;
            createdAtSeconds: number | null;
            quizSubmissionStatus: QuizSubmissionStatus;
            evaluations: Array<{
              __typename?: "QuizEvaluation";
              submissionId: number;
              score: number;
              isPassed: boolean;
              reasonJa: string;
              reasonEn: string;
              evaluationItem: {
                __typename?: "MultiChoiceEvaluationItem";
                id: number;
                questionId: number;
                questionVersion: string;
                titleJa: string | null;
                titleEn: string | null;
                descriptionJa: string | null;
                descriptionEn: string | null;
              } | null;
            }>;
            evaluatedUser: { __typename?: "User"; uid: string; displayName: string } | null;
          }>;
        }
      | {
          __typename?: "SystemDesignEntity";
          id: string;
          entityId: number;
          systemDesignEntityId: number;
          totalScore: number;
          evaluatedAtSeconds: number | null;
          appealMessage: string | null;
          systemDesignStatus: SystemDesignStatus;
          submissions: Array<{
            __typename?: "SystemDesignSubmission";
            systemDesignSubmissionId: number;
            isBest: boolean;
            status: SystemDesignSubmissionStatus;
            snapshot: string;
            numTabEvents: number;
            numAccessEvents: number;
            totalScore: number;
            numSubset: number;
            totalElapsedTimeSeconds: number | null;
            elapsedTimeSeconds: number | null;
            evaluatedAtSeconds: number | null;
            evaluations: Array<{
              __typename?: "SystemDesignEvaluation";
              totalScore: number;
              evaluatedBy: string;
              availability: number;
              scalability: number;
              consistency: number;
              result: string;
            }>;
            usedHints: Array<{ __typename?: "SystemDesignHint"; systemDesignHintId: number }>;
            snapshots: Array<{
              __typename?: "SystemDesignSnapshot";
              systemDesignSnapshotId: number;
              scoringItemId: number;
              isPassed: boolean;
              matchedIds: Array<string>;
            }>;
          }>;
          question: {
            __typename?: "SystemDesignQuestion";
            questionId: number;
            titleEn: string | null;
            titleJa: string | null;
            difficulty: Difficulty;
            descriptionJa: string | null;
            descriptionEn: string | null;
            initialSnapshot: string;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            timeLimitSeconds: number | null;
            isOfficial: boolean;
            systemDesignQuestionStatus: SystemDesignQuestionStatus;
            hints: Array<{ __typename?: "SystemDesignHint"; descriptionJa: string | null; descriptionEn: string | null }>;
            answers: Array<{
              __typename?: "SystemDesignAnswer";
              id: string;
              titleJa: string;
              titleEn: string;
              descriptionJa: string;
              descriptionEn: string;
            }>;
            scoringItems: Array<{
              __typename?: "SystemDesignScoringItem";
              systemDesignScoringItemId: number;
              category: SystemDesignScoringItemCategory;
              titleJa: string;
              titleEn: string;
              descriptionJa: string;
              descriptionEn: string;
              graphlets: string;
              isHidden: boolean;
            }>;
          } | null;
        }
    >;
    issuedEntities: Array<
      | {
          __typename?: "SpotIssuedChallengeEntity";
          questionWeight: number;
          entity: {
            __typename?: "ChallengeEntity";
            id: string;
            entityId: number;
            challengeEntityId: number;
            totalScore: number;
            timeLimitSeconds: number | null;
            appealMessage: string | null;
            suspiciousDegree: ChallengeSuspiciousDegree;
            challengeStatus: ChallengeStatus;
            submissions: Array<{
              __typename?: "ChallengeSubmission";
              id: string;
              challengeSubmissionId: number;
              questionId: number;
              questionVersion: string;
              isBest: boolean;
              runtime: string;
              runtimeVersion: string;
              correctnessTestResult: string;
              totalScore: number;
              numSubset: number;
              totalElapsedTimeSeconds: number | null;
              readabilityTestResult: string;
              evaluatedAtSeconds: number | null;
              coverage: number;
              performance: number;
              readability: number;
              runtimeComplexity: string;
              spaceComplexity: string;
              avgPerformance: number;
              avgMaxMemory: number;
              machineOs: string;
              machineMemory: string;
              machineCpu: number | null;
              numPassed: number;
              numTests: number;
              numTabEvents: number;
              numPasteEvents: number;
              numAccessEvents: number;
              similarityScore: number | null;
              code: string;
              elapsedTimeSeconds: number | null;
              performanceTestResult: string;
              challengeUsedHintCount: number;
              challengeSubmissionStatus: ChallengeSubmissionStatus;
              similarCodes: Array<{
                __typename?: "ChallengeSubmissionSimilarCode";
                code: string;
                score: number;
                metadata:
                  | { __typename: "SimilarCodeCrawlerMetadata"; url: string }
                  | { __typename: "SimilarCodeNotFoundMetadata"; reserved: boolean | null }
                  | { __typename: "SimilarCodeOtherCompanyMetadata"; reserved: boolean | null }
                  | { __typename: "SimilarCodeSpotMetadata"; spotId: string; challengeId: number; questionId: number; questionVersion: string };
                fragments: Array<{
                  __typename?: "ChallengeSubmissionSimilarCodeFragment";
                  left: {
                    __typename?: "ChallengeSubmissionSimilarCodeSelection";
                    endCol: number;
                    endRow: number;
                    startCol: number;
                    startRow: number;
                  };
                  right: {
                    __typename?: "ChallengeSubmissionSimilarCodeSelection";
                    endCol: number;
                    endRow: number;
                    startCol: number;
                    startRow: number;
                  };
                }>;
              }>;
              snapshots: Array<{ __typename?: "ChallengeSnapshot"; id: string; challengeSnapshotId: number }>;
            }>;
            challengeQuestion: {
              __typename?: "AlgorithmQuestion";
              id: string;
              questionId: number;
              version: string;
              variant: AlgorithmQuestionVariant;
              titleJa: string | null;
              titleEn: string | null;
              difficulty: Difficulty;
              descriptionEn: string | null;
              descriptionJa: string | null;
              correctnessTestCase: string;
              isSupportedLeakScore: boolean;
              numUses: number;
              supportedLanguages: Array<string>;
              timeLimitSeconds: number | null;
              averageElapsedTimeSeconds: number;
              accuracyRate: number;
              isOfficial: boolean;
              isPrivate: boolean;
              performanceTimeLimitMilliSeconds: number;
              algorithmQuestionStatus: QuestionStatus;
              leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
              employee: { __typename?: "User"; id: string; displayName: string };
              initialCode: {
                __typename?: "AlgorithmInitialCode";
                c: string;
                cpp: string;
                csharp: string;
                dart: string;
                go: string;
                java: string;
                javascript: string;
                kotlin: string;
                mysql: string;
                perl: string;
                pgsql: string;
                php: string;
                python3: string;
                ruby: string;
                rust: string;
                scala: string;
                sqlite: string;
                swift: string;
                typescript: string;
              } | null;
              answers: Array<{
                __typename?: "AlgorithmAnswer";
                id: number;
                titleEn: string | null;
                titleJa: string | null;
                descriptionJa: string | null;
                descriptionEn: string | null;
                label: string;
                runtimeComplexity: string;
                spaceComplexity: string;
              }>;
              hints: Array<{
                __typename?: "AlgorithmHint";
                id: number;
                descriptionJa: string | null;
                descriptionEn: string | null;
                orderNum: number;
              }>;
            } | null;
            sessions: Array<
              | { __typename?: "ChallengeChatGPTSession"; sessionId: string; challengeId: number; language: string }
              | { __typename?: "ChallengeWebSession"; sessionId: string; challengeId: number; language: string }
            >;
            suspiciousInference: {
              __typename?: "SuspiciousInference";
              inferenceId: string;
              feedback: { __typename?: "SuspiciousInferenceFeedback"; isCorrect: boolean } | null;
            } | null;
          };
        }
      | {
          __typename?: "SpotIssuedProjectEntity";
          questionWeight: number;
          entity: {
            __typename?: "ProjectEntity";
            id: string;
            entityId: number;
            projectEntityId: number;
            totalScore: number;
            evaluatedAtSeconds: number | null;
            agentVersion: string;
            appealMessage: string | null;
            projectStatus: ProjectStatus;
            question: {
              __typename?: "ProjectQuestion";
              id: string;
              questionId: number;
              version: string;
              projectVersion: string;
              variant: ProjectQuestionVariant;
              titleEn: string | null;
              titleJa: string | null;
              difficulty: Difficulty;
              descriptionJa: string | null;
              descriptionEn: string | null;
              correctnessTestCase: string;
              performanceTestCase: string;
              timeLimitSeconds: number | null;
              isPrivate: boolean;
              numUses: number;
              accuracyRate: number;
              averageElapsedTimeSeconds: number;
              isOfficial: boolean;
              projectQuestionStatus: ProjectQuestionStatus;
              answers: Array<{
                __typename?: "ProjectAnswer";
                titleJa: string;
                titleEn: string;
                descriptionJa: string;
                descriptionEn: string;
                answerId: number;
              }>;
            } | null;
            submissions: Array<{
              __typename?: "ProjectSubmission";
              projectSubmissionId: number;
              numTests: number;
              numPassed: number;
              numTabEvents: number;
              numAccessEvents: number;
              totalScore: number;
              numSubset: number;
              isBest: boolean;
              testResult: string;
              avgLatency: number;
              avgSla: number;
              coverage: number;
              performance: number;
              correctnessTestResult: string;
              performanceTestResult: string;
              metrics: string;
              failureReason: ProjectFailureReason;
              totalElapsedTimeSeconds: number | null;
              elapsedTimeSeconds: number | null;
              evaluatedAtSeconds: number | null;
              submissionStatus: ProjectSubmissionStatus;
              snapshots: Array<{ __typename?: "ProjectSnapshot"; testCase: string; testResult: string; isPassed: boolean }>;
              diffs: Array<{
                __typename?: "ProjectDiff";
                projectDiffId: number;
                operation: ProjectOperation;
                fromPath: string;
                toPath: string;
                fromLink: string;
                toLink: string;
              }>;
            }>;
          };
        }
      | {
          __typename?: "SpotIssuedQuizEntity";
          questionWeight: number;
          entity: {
            __typename?: "QuizEntity";
            id: string;
            entityId: number;
            quizEntityId: number;
            totalScore: number;
            numSubset: number;
            timeLimitSeconds: number | null;
            evaluatedAtSeconds: number | null;
            elapsedTimeSeconds: number | null;
            quizStatus: QuizStatus;
            pb_package: {
              __typename?: "MultiChoicePackage";
              packageId: number;
              version: string;
              difficulty: Difficulty;
              titleJa: string | null;
              titleEn: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
              numUses: number;
              timeLimitSeconds: number | null;
              accuracyRate: number;
              averageElapsedTimeSeconds: number;
              isOfficial: boolean;
              isPrivate: boolean;
              multiChoicePackageStatus: QuestionStatus;
              employee: { __typename?: "User"; id: string; displayName: string };
              questions: Array<{
                __typename?: "MultiChoiceQuestion";
                id: number;
                version: string;
                titleJa: string | null;
                titleEn: string | null;
                descriptionJa: string | null;
                descriptionEn: string | null;
                correctAnswerRate: number;
                variant: MultiChoiceQuestionVariant;
                options: Array<{
                  __typename?: "MultiChoiceOption";
                  id: number;
                  titleJa: string | null;
                  titleEn: string | null;
                  isCorrect: boolean;
                  additionalDetailJa: string | null;
                  additionalDetailEn: string | null;
                }>;
                evaluationItems: Array<{
                  __typename?: "MultiChoiceEvaluationItem";
                  id: number;
                  questionId: number;
                  questionVersion: string;
                  titleJa: string | null;
                  titleEn: string | null;
                  descriptionJa: string | null;
                  descriptionEn: string | null;
                }>;
              }>;
            } | null;
            submissions: Array<{
              __typename?: "QuizSubmission";
              id: string;
              quizSubmissionId: number;
              quizId: number;
              packageId: number;
              packageVersion: string;
              questionId: number;
              questionVersion: string;
              optionIds: Array<number>;
              isCorrect: boolean;
              answerText: string;
              score: number;
              evaluatedBy: string;
              isAutomaticEvaluation: boolean;
              evaluatedAtSeconds: number | null;
              numTabEvents: number;
              numAccessEvents: number;
              elapsedTimeSeconds: number | null;
              createdAtSeconds: number | null;
              quizSubmissionStatus: QuizSubmissionStatus;
              evaluations: Array<{
                __typename?: "QuizEvaluation";
                submissionId: number;
                score: number;
                isPassed: boolean;
                reasonJa: string;
                reasonEn: string;
                evaluationItem: {
                  __typename?: "MultiChoiceEvaluationItem";
                  id: number;
                  questionId: number;
                  questionVersion: string;
                  titleJa: string | null;
                  titleEn: string | null;
                  descriptionJa: string | null;
                  descriptionEn: string | null;
                } | null;
              }>;
              evaluatedUser: { __typename?: "User"; uid: string; displayName: string } | null;
            }>;
          };
        }
      | {
          __typename?: "SpotIssuedSystemDesignEntity";
          questionWeight: number;
          entity: {
            __typename?: "SystemDesignEntity";
            id: string;
            entityId: number;
            systemDesignEntityId: number;
            totalScore: number;
            evaluatedAtSeconds: number | null;
            appealMessage: string | null;
            systemDesignStatus: SystemDesignStatus;
            submissions: Array<{
              __typename?: "SystemDesignSubmission";
              systemDesignSubmissionId: number;
              isBest: boolean;
              status: SystemDesignSubmissionStatus;
              snapshot: string;
              numTabEvents: number;
              numAccessEvents: number;
              totalScore: number;
              numSubset: number;
              totalElapsedTimeSeconds: number | null;
              elapsedTimeSeconds: number | null;
              evaluatedAtSeconds: number | null;
              evaluations: Array<{
                __typename?: "SystemDesignEvaluation";
                totalScore: number;
                evaluatedBy: string;
                availability: number;
                scalability: number;
                consistency: number;
                result: string;
              }>;
              usedHints: Array<{ __typename?: "SystemDesignHint"; systemDesignHintId: number }>;
              snapshots: Array<{
                __typename?: "SystemDesignSnapshot";
                systemDesignSnapshotId: number;
                scoringItemId: number;
                isPassed: boolean;
                matchedIds: Array<string>;
              }>;
            }>;
            question: {
              __typename?: "SystemDesignQuestion";
              questionId: number;
              titleEn: string | null;
              titleJa: string | null;
              difficulty: Difficulty;
              descriptionJa: string | null;
              descriptionEn: string | null;
              initialSnapshot: string;
              numUses: number;
              accuracyRate: number;
              averageElapsedTimeSeconds: number;
              timeLimitSeconds: number | null;
              isOfficial: boolean;
              systemDesignQuestionStatus: SystemDesignQuestionStatus;
              hints: Array<{ __typename?: "SystemDesignHint"; descriptionJa: string | null; descriptionEn: string | null }>;
              answers: Array<{
                __typename?: "SystemDesignAnswer";
                id: string;
                titleJa: string;
                titleEn: string;
                descriptionJa: string;
                descriptionEn: string;
              }>;
              scoringItems: Array<{
                __typename?: "SystemDesignScoringItem";
                systemDesignScoringItemId: number;
                category: SystemDesignScoringItemCategory;
                titleJa: string;
                titleEn: string;
                descriptionJa: string;
                descriptionEn: string;
                graphlets: string;
                isHidden: boolean;
              }>;
            } | null;
          };
        }
    >;
  };
};

export type AssessmentFeedbackRecommendationFragment = {
  __typename?: "EvaluationRecommendation";
  id: string;
  employeeId: string;
  evaluationRecommendationId: number;
  isRecommended: boolean;
  comment: string;
  createdAtSeconds: number | null;
  evaluations: Array<{
    __typename?: "Evaluation";
    id: string;
    evaluationId: number;
    employeeId: string;
    metricId: number;
    comment: string;
    numStars: number;
    metric: { __typename?: "EvaluationMetric"; id: string; title: string; metricId: number; description: string } | null;
  }>;
  reviewer: { __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string };
};

export type ExamForAssessmentFeedbackFragment = {
  __typename?: "Exam";
  id: string;
  recommendations: Array<{
    __typename?: "EvaluationRecommendation";
    id: string;
    employeeId: string;
    evaluationRecommendationId: number;
    isRecommended: boolean;
    comment: string;
    createdAtSeconds: number | null;
    evaluations: Array<{
      __typename?: "Evaluation";
      id: string;
      evaluationId: number;
      employeeId: string;
      metricId: number;
      comment: string;
      numStars: number;
      metric: { __typename?: "EvaluationMetric"; id: string; title: string; metricId: number; description: string } | null;
    }>;
    reviewer: { __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string };
  }>;
};

export type GetExamForAssessmentFeedbackQueryVariables = Exact<{
  examId: Scalars["String"]["input"];
}>;

export type GetExamForAssessmentFeedbackQuery = {
  __typename?: "Query";
  exam: {
    __typename?: "Exam";
    id: string;
    recommendations: Array<{
      __typename?: "EvaluationRecommendation";
      id: string;
      employeeId: string;
      evaluationRecommendationId: number;
      isRecommended: boolean;
      comment: string;
      createdAtSeconds: number | null;
      evaluations: Array<{
        __typename?: "Evaluation";
        id: string;
        evaluationId: number;
        employeeId: string;
        metricId: number;
        comment: string;
        numStars: number;
        metric: { __typename?: "EvaluationMetric"; id: string; title: string; metricId: number; description: string } | null;
      }>;
      reviewer: { __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string };
    }>;
  };
};

export type ListAssessmentsByCompanyIdForAssessmentsHistorySelectorFragment = {
  __typename?: "ListAssessmentsByCompanyId";
  count: number;
  hasNext: boolean;
  assessments: Array<{ __typename?: "Assessment"; id: string; name: string }>;
};

export type ListAssessmentsByCompanyIdForAssessmentsHistorySelectorQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  withCount: Scalars["Boolean"]["input"];
  withDeleted: Scalars["Boolean"]["input"];
  filters: AssessmentFilters;
  sortMethod: AssessmentSortMethod;
  size: Scalars["Int"]["input"];
  offset: Scalars["Int"]["input"];
  isDescending: Scalars["Boolean"]["input"];
}>;

export type ListAssessmentsByCompanyIdForAssessmentsHistorySelectorQuery = {
  __typename?: "Query";
  listAssessmentsByCompanyId: {
    __typename?: "ListAssessmentsByCompanyId";
    count: number;
    hasNext: boolean;
    assessments: Array<{ __typename?: "Assessment"; id: string; name: string }>;
  };
};

type AssessmentEntitySourceQuestionForAssessmentHistorySelector_AssessmentChallengeEntitySourceQuestion_Fragment = {
  __typename: "AssessmentChallengeEntitySourceQuestion";
  enabledLanguages: Array<string>;
  question: {
    __typename: "AlgorithmQuestion";
    questionId: number;
    version: string;
    key: string;
    titleJa: string | null;
    titleEn: string | null;
    supportedLanguages: Array<string>;
    difficulty: Difficulty;
    isPrivate: boolean;
    isOfficial: boolean;
    timeLimitSeconds: number | null;
    numUses: number;
    isSupportedLeakScore: boolean;
    accuracyRate: number;
    averageElapsedTimeSeconds: number;
    algorithmQuestionVariant: AlgorithmQuestionVariant;
    algorithmQuestionStatus: QuestionStatus;
    leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
  };
};

type AssessmentEntitySourceQuestionForAssessmentHistorySelector_AssessmentProjectEntitySourceQuestion_Fragment = {
  __typename: "AssessmentProjectEntitySourceQuestion";
  question: {
    __typename: "ProjectQuestion";
    questionId: number;
    version: string;
    projectVersion: string;
    key: string;
    companyId: number;
    titleJa: string | null;
    titleEn: string | null;
    difficulty: Difficulty;
    isPrivate: boolean;
    isOfficial: boolean;
    isSupportedLeakScore: boolean;
    timeLimitSeconds: number | null;
    numUses: number;
    accuracyRate: number;
    averageElapsedTimeSeconds: number;
    projectQuestionVariant: ProjectQuestionVariant;
    projectQuestionStatus: ProjectQuestionStatus;
    leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
  };
};

type AssessmentEntitySourceQuestionForAssessmentHistorySelector_AssessmentQuizEntitySourcePackage_Fragment = {
  __typename: "AssessmentQuizEntitySourcePackage";
  pb_package: {
    __typename: "MultiChoicePackage";
    packageId: number;
    version: string;
    key: string;
    titleJa: string | null;
    titleEn: string | null;
    difficulty: Difficulty;
    isPrivate: boolean;
    isOfficial: boolean;
    isSupportedLeakScore: boolean;
    timeLimitSeconds: number | null;
    numUses: number;
    accuracyRate: number;
    averageElapsedTimeSeconds: number;
    multiChoicePackageStatus: QuestionStatus;
    leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
  };
};

type AssessmentEntitySourceQuestionForAssessmentHistorySelector_AssessmentSystemDesignEntitySourceQuestion_Fragment = {
  __typename: "AssessmentSystemDesignEntitySourceQuestion";
  componentTypes: Array<SystemDesignComponentType>;
  question: {
    __typename: "SystemDesignQuestion";
    questionId: number;
    key: string;
    titleJa: string | null;
    titleEn: string | null;
    difficulty: Difficulty;
    isPrivate: boolean;
    isOfficial: boolean;
    isSupportedLeakScore: boolean;
    timeLimitSeconds: number | null;
    numUses: number;
    accuracyRate: number;
    averageElapsedTimeSeconds: number;
    systemDesignQuestionStatus: SystemDesignQuestionStatus;
    leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
  };
};

export type AssessmentEntitySourceQuestionForAssessmentHistorySelectorFragment =
  | AssessmentEntitySourceQuestionForAssessmentHistorySelector_AssessmentChallengeEntitySourceQuestion_Fragment
  | AssessmentEntitySourceQuestionForAssessmentHistorySelector_AssessmentProjectEntitySourceQuestion_Fragment
  | AssessmentEntitySourceQuestionForAssessmentHistorySelector_AssessmentQuizEntitySourcePackage_Fragment
  | AssessmentEntitySourceQuestionForAssessmentHistorySelector_AssessmentSystemDesignEntitySourceQuestion_Fragment;

export type GetAssessmentForAssessmentHistorySelectorQueryVariables = Exact<{
  assessmentId: Scalars["String"]["input"];
}>;

export type GetAssessmentForAssessmentHistorySelectorQuery = {
  __typename?: "Query";
  assessment: {
    __typename?: "Assessment";
    entitySourceQuestions: Array<
      | {
          __typename: "AssessmentChallengeEntitySourceQuestion";
          enabledLanguages: Array<string>;
          question: {
            __typename: "AlgorithmQuestion";
            questionId: number;
            version: string;
            key: string;
            titleJa: string | null;
            titleEn: string | null;
            supportedLanguages: Array<string>;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            timeLimitSeconds: number | null;
            numUses: number;
            isSupportedLeakScore: boolean;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            algorithmQuestionVariant: AlgorithmQuestionVariant;
            algorithmQuestionStatus: QuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          };
        }
      | {
          __typename: "AssessmentProjectEntitySourceQuestion";
          question: {
            __typename: "ProjectQuestion";
            questionId: number;
            version: string;
            projectVersion: string;
            key: string;
            companyId: number;
            titleJa: string | null;
            titleEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            timeLimitSeconds: number | null;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            projectQuestionVariant: ProjectQuestionVariant;
            projectQuestionStatus: ProjectQuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          };
        }
      | {
          __typename: "AssessmentQuizEntitySourcePackage";
          pb_package: {
            __typename: "MultiChoicePackage";
            packageId: number;
            version: string;
            key: string;
            titleJa: string | null;
            titleEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            timeLimitSeconds: number | null;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            multiChoicePackageStatus: QuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          };
        }
      | {
          __typename: "AssessmentSystemDesignEntitySourceQuestion";
          componentTypes: Array<SystemDesignComponentType>;
          question: {
            __typename: "SystemDesignQuestion";
            questionId: number;
            key: string;
            titleJa: string | null;
            titleEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            timeLimitSeconds: number | null;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            systemDesignQuestionStatus: SystemDesignQuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          };
        }
    >;
  };
};

export type AssessmentOverviewChallengeQuestionFragment = {
  __typename: "AlgorithmQuestion";
  id: string;
  questionId: number;
  version: string;
  titleJa: string | null;
  titleEn: string | null;
  difficulty: Difficulty;
  algorithmQuestionStatus: QuestionStatus;
};

export type AssessmentOverviewQuizPackageFragment = {
  __typename: "MultiChoicePackage";
  id: string;
  packageId: number;
  version: string;
  titleJa: string | null;
  titleEn: string | null;
  difficulty: Difficulty;
  multiChoicePackageStatus: QuestionStatus;
};

export type AssessmentOverviewProjectQuestionFragment = {
  __typename: "ProjectQuestion";
  id: string;
  questionId: number;
  version: string;
  projectVersion: string;
  titleJa: string | null;
  titleEn: string | null;
  difficulty: Difficulty;
  projectQuestionStatus: ProjectQuestionStatus;
};

export type AssessmentOverviewSystemDesignQuestionFragment = {
  __typename: "SystemDesignQuestion";
  id: string;
  questionId: number;
  titleJa: string | null;
  titleEn: string | null;
  difficulty: Difficulty;
  systemDesignQuestionStatus: SystemDesignQuestionStatus;
};

export type AssessmentOverviewFragment = {
  __typename?: "Assessment";
  id: string;
  messageForTalent: string | null;
  startScheduleAtSeconds: number | null;
  examInterval: AssessmentExamInterval;
  createdAtSeconds: number | null;
  deletedAtSeconds: number | null;
  isPublic: boolean;
  memo: string | null;
  name: string;
  timeLimitSeconds: number | null;
  viewers: Array<
    | {
        __typename: "EmployeeGroup";
        id: string;
        groupName: string;
        companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string; role: Role }>;
      }
    | { __typename: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string; language: Language }
  >;
  employee: { __typename?: "User"; displayName: string } | null;
};

export type ExamForAssessmentOverviewFragment = {
  __typename?: "Exam";
  id: string;
  examId: string;
  status: ExamStatus;
  timeLimitSeconds: number | null;
  expiredAtSeconds: number | null;
  createdAtSeconds: number | null;
  willEndAtSeconds: number | null;
  didEndAtSeconds: number | null;
  messageForTalent: string | null;
  entities: Array<
    | {
        __typename?: "ChallengeEntity";
        enabledLanguages: Array<string>;
        challengeQuestion: {
          __typename: "AlgorithmQuestion";
          id: string;
          questionId: number;
          version: string;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          algorithmQuestionStatus: QuestionStatus;
        } | null;
      }
    | {
        __typename?: "ProjectEntity";
        question: {
          __typename: "ProjectQuestion";
          id: string;
          questionId: number;
          version: string;
          projectVersion: string;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          projectQuestionStatus: ProjectQuestionStatus;
        } | null;
      }
    | {
        __typename?: "QuizEntity";
        pb_package: {
          __typename: "MultiChoicePackage";
          id: string;
          packageId: number;
          version: string;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          multiChoicePackageStatus: QuestionStatus;
        } | null;
      }
    | {
        __typename?: "SystemDesignEntity";
        componentTypes: Array<SystemDesignComponentType>;
        question: {
          __typename: "SystemDesignQuestion";
          id: string;
          questionId: number;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          systemDesignQuestionStatus: SystemDesignQuestionStatus;
        } | null;
      }
  >;
};

export type GetAssessmentOverviewQueryVariables = Exact<{
  assessmentId: Scalars["String"]["input"];
  examId: Scalars["String"]["input"];
}>;

export type GetAssessmentOverviewQuery = {
  __typename?: "Query";
  assessment: {
    __typename?: "Assessment";
    id: string;
    messageForTalent: string | null;
    startScheduleAtSeconds: number | null;
    examInterval: AssessmentExamInterval;
    createdAtSeconds: number | null;
    deletedAtSeconds: number | null;
    isPublic: boolean;
    memo: string | null;
    name: string;
    timeLimitSeconds: number | null;
    viewers: Array<
      | {
          __typename: "EmployeeGroup";
          id: string;
          groupName: string;
          companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string; role: Role }>;
        }
      | { __typename: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string; language: Language }
    >;
    employee: { __typename?: "User"; displayName: string } | null;
  };
  exam: {
    __typename?: "Exam";
    id: string;
    examId: string;
    status: ExamStatus;
    timeLimitSeconds: number | null;
    expiredAtSeconds: number | null;
    createdAtSeconds: number | null;
    willEndAtSeconds: number | null;
    didEndAtSeconds: number | null;
    messageForTalent: string | null;
    entities: Array<
      | {
          __typename?: "ChallengeEntity";
          enabledLanguages: Array<string>;
          challengeQuestion: {
            __typename: "AlgorithmQuestion";
            id: string;
            questionId: number;
            version: string;
            titleJa: string | null;
            titleEn: string | null;
            difficulty: Difficulty;
            algorithmQuestionStatus: QuestionStatus;
          } | null;
        }
      | {
          __typename?: "ProjectEntity";
          question: {
            __typename: "ProjectQuestion";
            id: string;
            questionId: number;
            version: string;
            projectVersion: string;
            titleJa: string | null;
            titleEn: string | null;
            difficulty: Difficulty;
            projectQuestionStatus: ProjectQuestionStatus;
          } | null;
        }
      | {
          __typename?: "QuizEntity";
          pb_package: {
            __typename: "MultiChoicePackage";
            id: string;
            packageId: number;
            version: string;
            titleJa: string | null;
            titleEn: string | null;
            difficulty: Difficulty;
            multiChoicePackageStatus: QuestionStatus;
          } | null;
        }
      | {
          __typename?: "SystemDesignEntity";
          componentTypes: Array<SystemDesignComponentType>;
          question: {
            __typename: "SystemDesignQuestion";
            id: string;
            questionId: number;
            titleJa: string | null;
            titleEn: string | null;
            difficulty: Difficulty;
            systemDesignQuestionStatus: SystemDesignQuestionStatus;
          } | null;
        }
    >;
  };
};

export type UpdateAssessmentForAssessmentOverviewMutationVariables = Exact<{
  input: UpdateAssessmentRequestInput;
}>;

export type UpdateAssessmentForAssessmentOverviewMutation = {
  __typename?: "Mutation";
  updateAssessment: {
    __typename?: "Assessment";
    id: string;
    messageForTalent: string | null;
    startScheduleAtSeconds: number | null;
    examInterval: AssessmentExamInterval;
    createdAtSeconds: number | null;
    deletedAtSeconds: number | null;
    isPublic: boolean;
    memo: string | null;
    name: string;
    timeLimitSeconds: number | null;
    viewers: Array<
      | {
          __typename: "EmployeeGroup";
          id: string;
          groupName: string;
          companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string; role: Role }>;
        }
      | { __typename: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string; language: Language }
    >;
    employee: { __typename?: "User"; displayName: string } | null;
  };
};

export type ExamReportFragment = {
  __typename?: "Exam";
  id: string;
  status: ExamStatus;
  totalScore: number;
  rank: number;
  rankEvaluation: StatisticsEvaluation;
  isReliableRank: boolean;
  relativeScore: number;
  numSubset: number;
  didStartAtSeconds: number | null;
  finalizedAtSeconds: number | null;
  entities: Array<
    | {
        __typename?: "ChallengeEntity";
        id: string;
        entityId: number;
        challengeEntityId: number;
        totalScore: number;
        timeLimitSeconds: number | null;
        appealMessage: string | null;
        suspiciousDegree: ChallengeSuspiciousDegree;
        challengeStatus: ChallengeStatus;
        submissions: Array<{
          __typename?: "ChallengeSubmission";
          id: string;
          challengeSubmissionId: number;
          questionId: number;
          questionVersion: string;
          isBest: boolean;
          runtime: string;
          runtimeVersion: string;
          correctnessTestResult: string;
          totalScore: number;
          numSubset: number;
          totalElapsedTimeSeconds: number | null;
          readabilityTestResult: string;
          evaluatedAtSeconds: number | null;
          coverage: number;
          performance: number;
          readability: number;
          runtimeComplexity: string;
          spaceComplexity: string;
          avgPerformance: number;
          avgMaxMemory: number;
          machineOs: string;
          machineMemory: string;
          machineCpu: number | null;
          numPassed: number;
          numTests: number;
          numTabEvents: number;
          numPasteEvents: number;
          numAccessEvents: number;
          similarityScore: number | null;
          code: string;
          elapsedTimeSeconds: number | null;
          performanceTestResult: string;
          challengeUsedHintCount: number;
          challengeSubmissionStatus: ChallengeSubmissionStatus;
          similarCodes: Array<{
            __typename?: "ChallengeSubmissionSimilarCode";
            code: string;
            score: number;
            metadata:
              | { __typename: "SimilarCodeCrawlerMetadata"; url: string }
              | { __typename: "SimilarCodeNotFoundMetadata"; reserved: boolean | null }
              | { __typename: "SimilarCodeOtherCompanyMetadata"; reserved: boolean | null }
              | { __typename: "SimilarCodeSpotMetadata"; spotId: string; challengeId: number; questionId: number; questionVersion: string };
            fragments: Array<{
              __typename?: "ChallengeSubmissionSimilarCodeFragment";
              left: {
                __typename?: "ChallengeSubmissionSimilarCodeSelection";
                endCol: number;
                endRow: number;
                startCol: number;
                startRow: number;
              };
              right: {
                __typename?: "ChallengeSubmissionSimilarCodeSelection";
                endCol: number;
                endRow: number;
                startCol: number;
                startRow: number;
              };
            }>;
          }>;
          snapshots: Array<{ __typename?: "ChallengeSnapshot"; id: string; challengeSnapshotId: number }>;
        }>;
        challengeQuestion: {
          __typename?: "AlgorithmQuestion";
          id: string;
          questionId: number;
          version: string;
          variant: AlgorithmQuestionVariant;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          descriptionEn: string | null;
          descriptionJa: string | null;
          correctnessTestCase: string;
          isSupportedLeakScore: boolean;
          numUses: number;
          supportedLanguages: Array<string>;
          timeLimitSeconds: number | null;
          averageElapsedTimeSeconds: number;
          accuracyRate: number;
          isOfficial: boolean;
          isPrivate: boolean;
          performanceTimeLimitMilliSeconds: number;
          algorithmQuestionStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          employee: { __typename?: "User"; id: string; displayName: string };
          initialCode: {
            __typename?: "AlgorithmInitialCode";
            c: string;
            cpp: string;
            csharp: string;
            dart: string;
            go: string;
            java: string;
            javascript: string;
            kotlin: string;
            mysql: string;
            perl: string;
            pgsql: string;
            php: string;
            python3: string;
            ruby: string;
            rust: string;
            scala: string;
            sqlite: string;
            swift: string;
            typescript: string;
          } | null;
          answers: Array<{
            __typename?: "AlgorithmAnswer";
            id: number;
            titleEn: string | null;
            titleJa: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            label: string;
            runtimeComplexity: string;
            spaceComplexity: string;
          }>;
          hints: Array<{
            __typename?: "AlgorithmHint";
            id: number;
            descriptionJa: string | null;
            descriptionEn: string | null;
            orderNum: number;
          }>;
        } | null;
        sessions: Array<
          | { __typename?: "ChallengeChatGPTSession"; sessionId: string; challengeId: number; language: string }
          | { __typename?: "ChallengeWebSession"; sessionId: string; challengeId: number; language: string }
        >;
        suspiciousInference: {
          __typename?: "SuspiciousInference";
          inferenceId: string;
          feedback: { __typename?: "SuspiciousInferenceFeedback"; isCorrect: boolean } | null;
        } | null;
      }
    | {
        __typename?: "ProjectEntity";
        id: string;
        entityId: number;
        projectEntityId: number;
        totalScore: number;
        evaluatedAtSeconds: number | null;
        agentVersion: string;
        appealMessage: string | null;
        projectStatus: ProjectStatus;
        question: {
          __typename?: "ProjectQuestion";
          id: string;
          questionId: number;
          version: string;
          projectVersion: string;
          variant: ProjectQuestionVariant;
          titleEn: string | null;
          titleJa: string | null;
          difficulty: Difficulty;
          descriptionJa: string | null;
          descriptionEn: string | null;
          correctnessTestCase: string;
          performanceTestCase: string;
          timeLimitSeconds: number | null;
          isPrivate: boolean;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          isOfficial: boolean;
          projectQuestionStatus: ProjectQuestionStatus;
          answers: Array<{
            __typename?: "ProjectAnswer";
            titleJa: string;
            titleEn: string;
            descriptionJa: string;
            descriptionEn: string;
            answerId: number;
          }>;
        } | null;
        submissions: Array<{
          __typename?: "ProjectSubmission";
          projectSubmissionId: number;
          numTests: number;
          numPassed: number;
          numTabEvents: number;
          numAccessEvents: number;
          totalScore: number;
          numSubset: number;
          isBest: boolean;
          testResult: string;
          avgLatency: number;
          avgSla: number;
          coverage: number;
          performance: number;
          correctnessTestResult: string;
          performanceTestResult: string;
          metrics: string;
          failureReason: ProjectFailureReason;
          totalElapsedTimeSeconds: number | null;
          elapsedTimeSeconds: number | null;
          evaluatedAtSeconds: number | null;
          submissionStatus: ProjectSubmissionStatus;
          snapshots: Array<{ __typename?: "ProjectSnapshot"; testCase: string; testResult: string; isPassed: boolean }>;
          diffs: Array<{
            __typename?: "ProjectDiff";
            projectDiffId: number;
            operation: ProjectOperation;
            fromPath: string;
            toPath: string;
            fromLink: string;
            toLink: string;
          }>;
        }>;
      }
    | {
        __typename?: "QuizEntity";
        id: string;
        entityId: number;
        quizEntityId: number;
        totalScore: number;
        numSubset: number;
        timeLimitSeconds: number | null;
        evaluatedAtSeconds: number | null;
        elapsedTimeSeconds: number | null;
        quizStatus: QuizStatus;
        pb_package: {
          __typename?: "MultiChoicePackage";
          packageId: number;
          version: string;
          difficulty: Difficulty;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          numUses: number;
          timeLimitSeconds: number | null;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          isOfficial: boolean;
          isPrivate: boolean;
          multiChoicePackageStatus: QuestionStatus;
          employee: { __typename?: "User"; id: string; displayName: string };
          questions: Array<{
            __typename?: "MultiChoiceQuestion";
            id: number;
            version: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            correctAnswerRate: number;
            variant: MultiChoiceQuestionVariant;
            options: Array<{
              __typename?: "MultiChoiceOption";
              id: number;
              titleJa: string | null;
              titleEn: string | null;
              isCorrect: boolean;
              additionalDetailJa: string | null;
              additionalDetailEn: string | null;
            }>;
            evaluationItems: Array<{
              __typename?: "MultiChoiceEvaluationItem";
              id: number;
              questionId: number;
              questionVersion: string;
              titleJa: string | null;
              titleEn: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
            }>;
          }>;
        } | null;
        submissions: Array<{
          __typename?: "QuizSubmission";
          id: string;
          quizSubmissionId: number;
          quizId: number;
          packageId: number;
          packageVersion: string;
          questionId: number;
          questionVersion: string;
          optionIds: Array<number>;
          isCorrect: boolean;
          answerText: string;
          score: number;
          evaluatedBy: string;
          isAutomaticEvaluation: boolean;
          evaluatedAtSeconds: number | null;
          numTabEvents: number;
          numAccessEvents: number;
          elapsedTimeSeconds: number | null;
          createdAtSeconds: number | null;
          quizSubmissionStatus: QuizSubmissionStatus;
          evaluations: Array<{
            __typename?: "QuizEvaluation";
            submissionId: number;
            score: number;
            isPassed: boolean;
            reasonJa: string;
            reasonEn: string;
            evaluationItem: {
              __typename?: "MultiChoiceEvaluationItem";
              id: number;
              questionId: number;
              questionVersion: string;
              titleJa: string | null;
              titleEn: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
            } | null;
          }>;
          evaluatedUser: { __typename?: "User"; uid: string; displayName: string } | null;
        }>;
      }
    | {
        __typename?: "SystemDesignEntity";
        id: string;
        entityId: number;
        systemDesignEntityId: number;
        totalScore: number;
        evaluatedAtSeconds: number | null;
        appealMessage: string | null;
        systemDesignStatus: SystemDesignStatus;
        submissions: Array<{
          __typename?: "SystemDesignSubmission";
          systemDesignSubmissionId: number;
          isBest: boolean;
          status: SystemDesignSubmissionStatus;
          snapshot: string;
          numTabEvents: number;
          numAccessEvents: number;
          totalScore: number;
          numSubset: number;
          totalElapsedTimeSeconds: number | null;
          elapsedTimeSeconds: number | null;
          evaluatedAtSeconds: number | null;
          evaluations: Array<{
            __typename?: "SystemDesignEvaluation";
            totalScore: number;
            evaluatedBy: string;
            availability: number;
            scalability: number;
            consistency: number;
            result: string;
          }>;
          usedHints: Array<{ __typename?: "SystemDesignHint"; systemDesignHintId: number }>;
          snapshots: Array<{
            __typename?: "SystemDesignSnapshot";
            systemDesignSnapshotId: number;
            scoringItemId: number;
            isPassed: boolean;
            matchedIds: Array<string>;
          }>;
        }>;
        question: {
          __typename?: "SystemDesignQuestion";
          questionId: number;
          titleEn: string | null;
          titleJa: string | null;
          difficulty: Difficulty;
          descriptionJa: string | null;
          descriptionEn: string | null;
          initialSnapshot: string;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          timeLimitSeconds: number | null;
          isOfficial: boolean;
          systemDesignQuestionStatus: SystemDesignQuestionStatus;
          hints: Array<{ __typename?: "SystemDesignHint"; descriptionJa: string | null; descriptionEn: string | null }>;
          answers: Array<{
            __typename?: "SystemDesignAnswer";
            id: string;
            titleJa: string;
            titleEn: string;
            descriptionJa: string;
            descriptionEn: string;
          }>;
          scoringItems: Array<{
            __typename?: "SystemDesignScoringItem";
            systemDesignScoringItemId: number;
            category: SystemDesignScoringItemCategory;
            titleJa: string;
            titleEn: string;
            descriptionJa: string;
            descriptionEn: string;
            graphlets: string;
            isHidden: boolean;
          }>;
        } | null;
      }
  >;
};

export type GetExamReportQueryVariables = Exact<{
  examId: Scalars["String"]["input"];
}>;

export type GetExamReportQuery = {
  __typename?: "Query";
  exam: {
    __typename?: "Exam";
    id: string;
    status: ExamStatus;
    totalScore: number;
    rank: number;
    rankEvaluation: StatisticsEvaluation;
    isReliableRank: boolean;
    relativeScore: number;
    numSubset: number;
    didStartAtSeconds: number | null;
    finalizedAtSeconds: number | null;
    entities: Array<
      | {
          __typename?: "ChallengeEntity";
          id: string;
          entityId: number;
          challengeEntityId: number;
          totalScore: number;
          timeLimitSeconds: number | null;
          appealMessage: string | null;
          suspiciousDegree: ChallengeSuspiciousDegree;
          challengeStatus: ChallengeStatus;
          submissions: Array<{
            __typename?: "ChallengeSubmission";
            id: string;
            challengeSubmissionId: number;
            questionId: number;
            questionVersion: string;
            isBest: boolean;
            runtime: string;
            runtimeVersion: string;
            correctnessTestResult: string;
            totalScore: number;
            numSubset: number;
            totalElapsedTimeSeconds: number | null;
            readabilityTestResult: string;
            evaluatedAtSeconds: number | null;
            coverage: number;
            performance: number;
            readability: number;
            runtimeComplexity: string;
            spaceComplexity: string;
            avgPerformance: number;
            avgMaxMemory: number;
            machineOs: string;
            machineMemory: string;
            machineCpu: number | null;
            numPassed: number;
            numTests: number;
            numTabEvents: number;
            numPasteEvents: number;
            numAccessEvents: number;
            similarityScore: number | null;
            code: string;
            elapsedTimeSeconds: number | null;
            performanceTestResult: string;
            challengeUsedHintCount: number;
            challengeSubmissionStatus: ChallengeSubmissionStatus;
            similarCodes: Array<{
              __typename?: "ChallengeSubmissionSimilarCode";
              code: string;
              score: number;
              metadata:
                | { __typename: "SimilarCodeCrawlerMetadata"; url: string }
                | { __typename: "SimilarCodeNotFoundMetadata"; reserved: boolean | null }
                | { __typename: "SimilarCodeOtherCompanyMetadata"; reserved: boolean | null }
                | { __typename: "SimilarCodeSpotMetadata"; spotId: string; challengeId: number; questionId: number; questionVersion: string };
              fragments: Array<{
                __typename?: "ChallengeSubmissionSimilarCodeFragment";
                left: {
                  __typename?: "ChallengeSubmissionSimilarCodeSelection";
                  endCol: number;
                  endRow: number;
                  startCol: number;
                  startRow: number;
                };
                right: {
                  __typename?: "ChallengeSubmissionSimilarCodeSelection";
                  endCol: number;
                  endRow: number;
                  startCol: number;
                  startRow: number;
                };
              }>;
            }>;
            snapshots: Array<{ __typename?: "ChallengeSnapshot"; id: string; challengeSnapshotId: number }>;
          }>;
          challengeQuestion: {
            __typename?: "AlgorithmQuestion";
            id: string;
            questionId: number;
            version: string;
            variant: AlgorithmQuestionVariant;
            titleJa: string | null;
            titleEn: string | null;
            difficulty: Difficulty;
            descriptionEn: string | null;
            descriptionJa: string | null;
            correctnessTestCase: string;
            isSupportedLeakScore: boolean;
            numUses: number;
            supportedLanguages: Array<string>;
            timeLimitSeconds: number | null;
            averageElapsedTimeSeconds: number;
            accuracyRate: number;
            isOfficial: boolean;
            isPrivate: boolean;
            performanceTimeLimitMilliSeconds: number;
            algorithmQuestionStatus: QuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
            employee: { __typename?: "User"; id: string; displayName: string };
            initialCode: {
              __typename?: "AlgorithmInitialCode";
              c: string;
              cpp: string;
              csharp: string;
              dart: string;
              go: string;
              java: string;
              javascript: string;
              kotlin: string;
              mysql: string;
              perl: string;
              pgsql: string;
              php: string;
              python3: string;
              ruby: string;
              rust: string;
              scala: string;
              sqlite: string;
              swift: string;
              typescript: string;
            } | null;
            answers: Array<{
              __typename?: "AlgorithmAnswer";
              id: number;
              titleEn: string | null;
              titleJa: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
              label: string;
              runtimeComplexity: string;
              spaceComplexity: string;
            }>;
            hints: Array<{
              __typename?: "AlgorithmHint";
              id: number;
              descriptionJa: string | null;
              descriptionEn: string | null;
              orderNum: number;
            }>;
          } | null;
          sessions: Array<
            | { __typename?: "ChallengeChatGPTSession"; sessionId: string; challengeId: number; language: string }
            | { __typename?: "ChallengeWebSession"; sessionId: string; challengeId: number; language: string }
          >;
          suspiciousInference: {
            __typename?: "SuspiciousInference";
            inferenceId: string;
            feedback: { __typename?: "SuspiciousInferenceFeedback"; isCorrect: boolean } | null;
          } | null;
        }
      | {
          __typename?: "ProjectEntity";
          id: string;
          entityId: number;
          projectEntityId: number;
          totalScore: number;
          evaluatedAtSeconds: number | null;
          agentVersion: string;
          appealMessage: string | null;
          projectStatus: ProjectStatus;
          question: {
            __typename?: "ProjectQuestion";
            id: string;
            questionId: number;
            version: string;
            projectVersion: string;
            variant: ProjectQuestionVariant;
            titleEn: string | null;
            titleJa: string | null;
            difficulty: Difficulty;
            descriptionJa: string | null;
            descriptionEn: string | null;
            correctnessTestCase: string;
            performanceTestCase: string;
            timeLimitSeconds: number | null;
            isPrivate: boolean;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            isOfficial: boolean;
            projectQuestionStatus: ProjectQuestionStatus;
            answers: Array<{
              __typename?: "ProjectAnswer";
              titleJa: string;
              titleEn: string;
              descriptionJa: string;
              descriptionEn: string;
              answerId: number;
            }>;
          } | null;
          submissions: Array<{
            __typename?: "ProjectSubmission";
            projectSubmissionId: number;
            numTests: number;
            numPassed: number;
            numTabEvents: number;
            numAccessEvents: number;
            totalScore: number;
            numSubset: number;
            isBest: boolean;
            testResult: string;
            avgLatency: number;
            avgSla: number;
            coverage: number;
            performance: number;
            correctnessTestResult: string;
            performanceTestResult: string;
            metrics: string;
            failureReason: ProjectFailureReason;
            totalElapsedTimeSeconds: number | null;
            elapsedTimeSeconds: number | null;
            evaluatedAtSeconds: number | null;
            submissionStatus: ProjectSubmissionStatus;
            snapshots: Array<{ __typename?: "ProjectSnapshot"; testCase: string; testResult: string; isPassed: boolean }>;
            diffs: Array<{
              __typename?: "ProjectDiff";
              projectDiffId: number;
              operation: ProjectOperation;
              fromPath: string;
              toPath: string;
              fromLink: string;
              toLink: string;
            }>;
          }>;
        }
      | {
          __typename?: "QuizEntity";
          id: string;
          entityId: number;
          quizEntityId: number;
          totalScore: number;
          numSubset: number;
          timeLimitSeconds: number | null;
          evaluatedAtSeconds: number | null;
          elapsedTimeSeconds: number | null;
          quizStatus: QuizStatus;
          pb_package: {
            __typename?: "MultiChoicePackage";
            packageId: number;
            version: string;
            difficulty: Difficulty;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            numUses: number;
            timeLimitSeconds: number | null;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            isOfficial: boolean;
            isPrivate: boolean;
            multiChoicePackageStatus: QuestionStatus;
            employee: { __typename?: "User"; id: string; displayName: string };
            questions: Array<{
              __typename?: "MultiChoiceQuestion";
              id: number;
              version: string;
              titleJa: string | null;
              titleEn: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
              correctAnswerRate: number;
              variant: MultiChoiceQuestionVariant;
              options: Array<{
                __typename?: "MultiChoiceOption";
                id: number;
                titleJa: string | null;
                titleEn: string | null;
                isCorrect: boolean;
                additionalDetailJa: string | null;
                additionalDetailEn: string | null;
              }>;
              evaluationItems: Array<{
                __typename?: "MultiChoiceEvaluationItem";
                id: number;
                questionId: number;
                questionVersion: string;
                titleJa: string | null;
                titleEn: string | null;
                descriptionJa: string | null;
                descriptionEn: string | null;
              }>;
            }>;
          } | null;
          submissions: Array<{
            __typename?: "QuizSubmission";
            id: string;
            quizSubmissionId: number;
            quizId: number;
            packageId: number;
            packageVersion: string;
            questionId: number;
            questionVersion: string;
            optionIds: Array<number>;
            isCorrect: boolean;
            answerText: string;
            score: number;
            evaluatedBy: string;
            isAutomaticEvaluation: boolean;
            evaluatedAtSeconds: number | null;
            numTabEvents: number;
            numAccessEvents: number;
            elapsedTimeSeconds: number | null;
            createdAtSeconds: number | null;
            quizSubmissionStatus: QuizSubmissionStatus;
            evaluations: Array<{
              __typename?: "QuizEvaluation";
              submissionId: number;
              score: number;
              isPassed: boolean;
              reasonJa: string;
              reasonEn: string;
              evaluationItem: {
                __typename?: "MultiChoiceEvaluationItem";
                id: number;
                questionId: number;
                questionVersion: string;
                titleJa: string | null;
                titleEn: string | null;
                descriptionJa: string | null;
                descriptionEn: string | null;
              } | null;
            }>;
            evaluatedUser: { __typename?: "User"; uid: string; displayName: string } | null;
          }>;
        }
      | {
          __typename?: "SystemDesignEntity";
          id: string;
          entityId: number;
          systemDesignEntityId: number;
          totalScore: number;
          evaluatedAtSeconds: number | null;
          appealMessage: string | null;
          systemDesignStatus: SystemDesignStatus;
          submissions: Array<{
            __typename?: "SystemDesignSubmission";
            systemDesignSubmissionId: number;
            isBest: boolean;
            status: SystemDesignSubmissionStatus;
            snapshot: string;
            numTabEvents: number;
            numAccessEvents: number;
            totalScore: number;
            numSubset: number;
            totalElapsedTimeSeconds: number | null;
            elapsedTimeSeconds: number | null;
            evaluatedAtSeconds: number | null;
            evaluations: Array<{
              __typename?: "SystemDesignEvaluation";
              totalScore: number;
              evaluatedBy: string;
              availability: number;
              scalability: number;
              consistency: number;
              result: string;
            }>;
            usedHints: Array<{ __typename?: "SystemDesignHint"; systemDesignHintId: number }>;
            snapshots: Array<{
              __typename?: "SystemDesignSnapshot";
              systemDesignSnapshotId: number;
              scoringItemId: number;
              isPassed: boolean;
              matchedIds: Array<string>;
            }>;
          }>;
          question: {
            __typename?: "SystemDesignQuestion";
            questionId: number;
            titleEn: string | null;
            titleJa: string | null;
            difficulty: Difficulty;
            descriptionJa: string | null;
            descriptionEn: string | null;
            initialSnapshot: string;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            timeLimitSeconds: number | null;
            isOfficial: boolean;
            systemDesignQuestionStatus: SystemDesignQuestionStatus;
            hints: Array<{ __typename?: "SystemDesignHint"; descriptionJa: string | null; descriptionEn: string | null }>;
            answers: Array<{
              __typename?: "SystemDesignAnswer";
              id: string;
              titleJa: string;
              titleEn: string;
              descriptionJa: string;
              descriptionEn: string;
            }>;
            scoringItems: Array<{
              __typename?: "SystemDesignScoringItem";
              systemDesignScoringItemId: number;
              category: SystemDesignScoringItemCategory;
              titleJa: string;
              titleEn: string;
              descriptionJa: string;
              descriptionEn: string;
              graphlets: string;
              isHidden: boolean;
            }>;
          } | null;
        }
    >;
  };
};

export type AssessmentEntitySourceForAssessmentResourceEditorFragment = {
  __typename?: "AssessmentEntitySource";
  questionId: number;
  entityType: AssessmentEntityType;
  challengeQuestion: {
    __typename?: "AssessmentChallengeQuestion";
    questionId: number;
    questionVersion: string;
    enabledLanguages: Array<string>;
  } | null;
  projectQuestion: { __typename?: "AssessmentProjectQuestion"; questionId: number; questionVersion: string } | null;
  quizPackage: { __typename?: "AssessmentQuizPackage"; packageId: number; packageVersion: string } | null;
  systemDesignQuestion: {
    __typename?: "AssessmentSystemDesignQuestion";
    questionId: number;
    componentTypes: Array<SystemDesignComponentType>;
  } | null;
};

export type AssessmentForAssessmentResourceEditorFragment = {
  __typename?: "Assessment";
  assessmentId: string;
  name: string;
  status: AssessmentStatus;
  memo: string | null;
  messageForTalent: string | null;
  timeLimitSeconds: number | null;
  startScheduleAtSeconds: number | null;
  nextScheduleAtSeconds: number | null;
  remindBeforeSeconds: number | null;
  isPublic: boolean;
  examInterval: AssessmentExamInterval;
  talent: { __typename?: "User"; displayName: string; uid: string };
  employee: { __typename?: "User"; displayName: string; uid: string } | null;
  entitySources: Array<{
    __typename?: "AssessmentEntitySource";
    questionId: number;
    entityType: AssessmentEntityType;
    challengeQuestion: {
      __typename?: "AssessmentChallengeQuestion";
      questionId: number;
      questionVersion: string;
      enabledLanguages: Array<string>;
    } | null;
    projectQuestion: { __typename?: "AssessmentProjectQuestion"; questionId: number; questionVersion: string } | null;
    quizPackage: { __typename?: "AssessmentQuizPackage"; packageId: number; packageVersion: string } | null;
    systemDesignQuestion: {
      __typename?: "AssessmentSystemDesignQuestion";
      questionId: number;
      componentTypes: Array<SystemDesignComponentType>;
    } | null;
  }>;
  questions: Array<
    | {
        __typename: "AlgorithmQuestion";
        questionId: number;
        version: string;
        key: string;
        titleJa: string | null;
        titleEn: string | null;
        supportedLanguages: Array<string>;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        timeLimitSeconds: number | null;
        numUses: number;
        isSupportedLeakScore: boolean;
        accuracyRate: number;
        averageElapsedTimeSeconds: number;
        algorithmQuestionVariant: AlgorithmQuestionVariant;
        algorithmQuestionStatus: QuestionStatus;
        leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
      }
    | {
        __typename: "MultiChoicePackage";
        packageId: number;
        version: string;
        key: string;
        titleJa: string | null;
        titleEn: string | null;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        isSupportedLeakScore: boolean;
        timeLimitSeconds: number | null;
        numUses: number;
        accuracyRate: number;
        averageElapsedTimeSeconds: number;
        multiChoicePackageStatus: QuestionStatus;
        leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
      }
    | {
        __typename: "ProjectQuestion";
        questionId: number;
        version: string;
        projectVersion: string;
        key: string;
        companyId: number;
        titleJa: string | null;
        titleEn: string | null;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        isSupportedLeakScore: boolean;
        timeLimitSeconds: number | null;
        numUses: number;
        accuracyRate: number;
        averageElapsedTimeSeconds: number;
        projectQuestionVariant: ProjectQuestionVariant;
        projectQuestionStatus: ProjectQuestionStatus;
        leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
      }
    | {
        __typename: "SystemDesignQuestion";
        questionId: number;
        key: string;
        titleJa: string | null;
        titleEn: string | null;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        isSupportedLeakScore: boolean;
        timeLimitSeconds: number | null;
        numUses: number;
        accuracyRate: number;
        averageElapsedTimeSeconds: number;
        systemDesignQuestionStatus: SystemDesignQuestionStatus;
        leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
      }
  >;
  reportSettings: {
    __typename?: "AssessmentReportSettings";
    showAnswer: boolean;
    showPlayback: boolean;
    showRelativeEvaluation: boolean;
  } | null;
  viewers: Array<
    | {
        __typename: "EmployeeGroup";
        employeeGroupId: string;
        groupName: string;
        companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string }>;
      }
    | { __typename: "User"; uid: string; displayName: string; email: string }
  >;
};

export type GetAssessmentForAssessmentResourceEditorQueryVariables = Exact<{
  assessmentId: Scalars["String"]["input"];
}>;

export type GetAssessmentForAssessmentResourceEditorQuery = {
  __typename?: "Query";
  assessment: {
    __typename?: "Assessment";
    assessmentId: string;
    name: string;
    status: AssessmentStatus;
    memo: string | null;
    messageForTalent: string | null;
    timeLimitSeconds: number | null;
    startScheduleAtSeconds: number | null;
    nextScheduleAtSeconds: number | null;
    remindBeforeSeconds: number | null;
    isPublic: boolean;
    examInterval: AssessmentExamInterval;
    talent: { __typename?: "User"; displayName: string; uid: string };
    employee: { __typename?: "User"; displayName: string; uid: string } | null;
    entitySources: Array<{
      __typename?: "AssessmentEntitySource";
      questionId: number;
      entityType: AssessmentEntityType;
      challengeQuestion: {
        __typename?: "AssessmentChallengeQuestion";
        questionId: number;
        questionVersion: string;
        enabledLanguages: Array<string>;
      } | null;
      projectQuestion: { __typename?: "AssessmentProjectQuestion"; questionId: number; questionVersion: string } | null;
      quizPackage: { __typename?: "AssessmentQuizPackage"; packageId: number; packageVersion: string } | null;
      systemDesignQuestion: {
        __typename?: "AssessmentSystemDesignQuestion";
        questionId: number;
        componentTypes: Array<SystemDesignComponentType>;
      } | null;
    }>;
    questions: Array<
      | {
          __typename: "AlgorithmQuestion";
          questionId: number;
          version: string;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          supportedLanguages: Array<string>;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          isSupportedLeakScore: boolean;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          algorithmQuestionVariant: AlgorithmQuestionVariant;
          algorithmQuestionStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | {
          __typename: "MultiChoicePackage";
          packageId: number;
          version: string;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          multiChoicePackageStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | {
          __typename: "ProjectQuestion";
          questionId: number;
          version: string;
          projectVersion: string;
          key: string;
          companyId: number;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          projectQuestionVariant: ProjectQuestionVariant;
          projectQuestionStatus: ProjectQuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | {
          __typename: "SystemDesignQuestion";
          questionId: number;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          systemDesignQuestionStatus: SystemDesignQuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
    >;
    reportSettings: {
      __typename?: "AssessmentReportSettings";
      showAnswer: boolean;
      showPlayback: boolean;
      showRelativeEvaluation: boolean;
    } | null;
    viewers: Array<
      | {
          __typename: "EmployeeGroup";
          employeeGroupId: string;
          groupName: string;
          companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string }>;
        }
      | { __typename: "User"; uid: string; displayName: string; email: string }
    >;
  };
};

export type PaymentPlanForBillingOverviewFragment = {
  __typename?: "PaymentV2Plan";
  planId: number;
  nameJa: string;
  nameEn: string;
  descriptionJa: string;
  descriptionEn: string;
  price: number;
  generation: number;
  planType: PlanType;
  enabledChangePlan: boolean;
  enabledPurchasePack: boolean;
};

export type PaymentSubscriptionForBillingOverviewFragment = {
  __typename?: "PaymentV2Subscription";
  subscriptionId: number;
  status: PaymentV2SubscriptionStatus;
  packStock: number;
  periodType: PaymentPeriodType;
  didStartAtSeconds: number | null;
  willEndAtSeconds: number | null;
  nextPaymentAtSeconds: number | null;
  plan: {
    __typename?: "PaymentV2Plan";
    planId: number;
    nameJa: string;
    nameEn: string;
    descriptionJa: string;
    descriptionEn: string;
    price: number;
    generation: number;
    planType: PlanType;
    enabledChangePlan: boolean;
    enabledPurchasePack: boolean;
  };
};

export type CustomerForBillingOverviewFragment = {
  __typename?: "PaymentV2Customer";
  customerId: number;
  paymentMethod: string;
  paymentType: PaymentType;
  paymentStatus: PaymentStatus;
  isTrial: boolean;
  isAutoRenewal: boolean;
  email: string;
  country: Country;
  postalCode: string;
  state: string;
  city: string;
  line1: string;
  line2: string;
  lastFour: string;
  bankName: string;
  bankCode: string;
  branchName: string;
  branchCode: string;
  accountHolderName: string;
  accountNumber: string;
  accountType: PaymentV2AccountType;
  language: Language;
  subscription: {
    __typename?: "PaymentV2Subscription";
    subscriptionId: number;
    status: PaymentV2SubscriptionStatus;
    packStock: number;
    periodType: PaymentPeriodType;
    didStartAtSeconds: number | null;
    willEndAtSeconds: number | null;
    nextPaymentAtSeconds: number | null;
    plan: {
      __typename?: "PaymentV2Plan";
      planId: number;
      nameJa: string;
      nameEn: string;
      descriptionJa: string;
      descriptionEn: string;
      price: number;
      generation: number;
      planType: PlanType;
      enabledChangePlan: boolean;
      enabledPurchasePack: boolean;
    };
  };
};

export type ContractPeriodUsageForBillingOverviewFragment = {
  __typename?: "ContractPeriodUsage";
  yearMonth: string;
  totalCount: number;
  paidCount: number;
  freeCount: number;
};

export type GetBillingOverviewQueryVariables = Exact<{
  customerId: Scalars["Int"]["input"];
  startedAtSeconds: Scalars["Int"]["input"];
  contractPeriod: Scalars["Int"]["input"];
}>;

export type GetBillingOverviewQuery = {
  __typename?: "Query";
  customer: {
    __typename?: "PaymentV2Customer";
    customerId: number;
    paymentMethod: string;
    paymentType: PaymentType;
    paymentStatus: PaymentStatus;
    isTrial: boolean;
    isAutoRenewal: boolean;
    email: string;
    country: Country;
    postalCode: string;
    state: string;
    city: string;
    line1: string;
    line2: string;
    lastFour: string;
    bankName: string;
    bankCode: string;
    branchName: string;
    branchCode: string;
    accountHolderName: string;
    accountNumber: string;
    accountType: PaymentV2AccountType;
    language: Language;
    subscription: {
      __typename?: "PaymentV2Subscription";
      subscriptionId: number;
      status: PaymentV2SubscriptionStatus;
      packStock: number;
      periodType: PaymentPeriodType;
      didStartAtSeconds: number | null;
      willEndAtSeconds: number | null;
      nextPaymentAtSeconds: number | null;
      plan: {
        __typename?: "PaymentV2Plan";
        planId: number;
        nameJa: string;
        nameEn: string;
        descriptionJa: string;
        descriptionEn: string;
        price: number;
        generation: number;
        planType: PlanType;
        enabledChangePlan: boolean;
        enabledPurchasePack: boolean;
      };
    };
  };
  contractPeriodUsagesV2: {
    __typename?: "ContractPeriodUsagesResponse";
    contractPeriodUsages: Array<{
      __typename?: "ContractPeriodUsage";
      yearMonth: string;
      totalCount: number;
      paidCount: number;
      freeCount: number;
    }>;
  };
};

export type UpdateAutoRenewalForBillingOverviewMutationVariables = Exact<{
  input: UpdateCustomerInput;
}>;

export type UpdateAutoRenewalForBillingOverviewMutation = {
  __typename?: "Mutation";
  updatePaymentCustomer: { __typename?: "UpdateCustomerResponse"; customer: { __typename?: "PaymentV2Customer"; isAutoRenewal: boolean } };
};

export type CustomerForBuySelectionsFragment = {
  __typename?: "PaymentV2Customer";
  customerId: number;
  paymentType: PaymentType;
  paymentMethod: string;
  paymentStatus: PaymentStatus;
  isTrial: boolean;
  subscription: {
    __typename?: "PaymentV2Subscription";
    subscriptionId: number;
    status: PaymentV2SubscriptionStatus;
    customerId: number;
    plan: { __typename?: "PaymentV2Plan"; generation: number; planType: PlanType; enabledPurchasePack: boolean };
    shotInterview: { __typename?: "PaymentInterview"; price: number };
    subscribedCoupons: Array<{
      __typename?: "SubscribedCoupon";
      isActive: boolean;
      createdAtSeconds: number;
      expiresAtSeconds: number;
      coupon: {
        __typename?: "Coupon";
        couponId: number;
        nameJa: string;
        nameEn: string;
        couponType: PaymentCouponType;
        couponDurationType: PaymentCouponDurationType;
        durationMonth: number;
        discountNumber: number;
      };
    }>;
  };
};

export type PaymentInterviewForBuySelectionsFragment = {
  __typename?: "PaymentInterview";
  interviewId: number;
  nameJa: string;
  nameEn: string;
  descriptionJa: string;
  descriptionEn: string;
  price: number;
  quantity: number;
  generation: number;
  interviewKind: PaymentInterviewKind;
};

export type GetCustomerForBuySelectionsQueryVariables = Exact<{
  customerId: Scalars["Int"]["input"];
}>;

export type GetCustomerForBuySelectionsQuery = {
  __typename?: "Query";
  customer: {
    __typename?: "PaymentV2Customer";
    customerId: number;
    paymentType: PaymentType;
    paymentMethod: string;
    paymentStatus: PaymentStatus;
    isTrial: boolean;
    subscription: {
      __typename?: "PaymentV2Subscription";
      subscriptionId: number;
      status: PaymentV2SubscriptionStatus;
      customerId: number;
      plan: { __typename?: "PaymentV2Plan"; generation: number; planType: PlanType; enabledPurchasePack: boolean };
      shotInterview: { __typename?: "PaymentInterview"; price: number };
      subscribedCoupons: Array<{
        __typename?: "SubscribedCoupon";
        isActive: boolean;
        createdAtSeconds: number;
        expiresAtSeconds: number;
        coupon: {
          __typename?: "Coupon";
          couponId: number;
          nameJa: string;
          nameEn: string;
          couponType: PaymentCouponType;
          couponDurationType: PaymentCouponDurationType;
          durationMonth: number;
          discountNumber: number;
        };
      }>;
    };
  };
  buyAblePacks: {
    __typename?: "BuyAblePacksResponse";
    interviews: Array<{
      __typename?: "PaymentInterview";
      interviewId: number;
      nameJa: string;
      nameEn: string;
      descriptionJa: string;
      descriptionEn: string;
      price: number;
      quantity: number;
      generation: number;
      interviewKind: PaymentInterviewKind;
    }>;
  };
};

export type PurchasePackagesForBuySelectionsMutationVariables = Exact<{
  input: PurchasePackagesInput;
}>;

export type PurchasePackagesForBuySelectionsMutation = {
  __typename?: "Mutation";
  purchasePackages: {
    __typename?: "PaymentV2Subscription";
    subscriptionId: number;
    customerId: number;
    packStock: number;
    periodType: PaymentPeriodType;
  };
};

export type CouponForBuySelectionsFragment = {
  __typename?: "Coupon";
  couponId: number;
  nameJa: string;
  nameEn: string;
  couponType: PaymentCouponType;
  couponDurationType: PaymentCouponDurationType;
  durationMonth: number;
  discountNumber: number;
  createdAtSeconds: number | null;
  updatedAtSeconds: number | null;
  deletedAtSeconds: number | null;
};

export type GetCouponByCodeForBuySelectionQueryVariables = Exact<{
  couponCode: Scalars["String"]["input"];
}>;

export type GetCouponByCodeForBuySelectionQuery = {
  __typename?: "Query";
  couponByCode: {
    __typename?: "Coupon";
    couponId: number;
    nameJa: string;
    nameEn: string;
    couponType: PaymentCouponType;
    couponDurationType: PaymentCouponDurationType;
    durationMonth: number;
    discountNumber: number;
    createdAtSeconds: number | null;
    updatedAtSeconds: number | null;
    deletedAtSeconds: number | null;
  } | null;
};

export type ChallengeSnapshotFragment = { __typename?: "ChallengeSnapshot"; id: string; challengeId: number; challengeSnapshotId: number };

export type ChallengeSubmissionFragment = {
  __typename?: "ChallengeSubmission";
  id: string;
  avgMaxMemory: number;
  avgPerformance: number;
  challengeId: number;
  challengeSubmissionId: number;
  code: string;
  companyId: number;
  correctnessTestResult: string;
  coverage: number;
  isBest: boolean;
  snapshots: Array<{ __typename?: "ChallengeSnapshot"; id: string; challengeId: number; challengeSnapshotId: number }>;
};

export type ChallengeRunCodeResponseFragment = {
  __typename?: "ChallengeRunCodeResponse";
  output: string;
  log: string;
  status: string;
  expected: string;
  isAccepted: boolean;
  performance: number;
  maxMemory: number;
  snapshotId: number;
};

export type ChallengeSubmissionQueryVariables = Exact<{
  submissionId: Scalars["Int"]["input"];
}>;

export type ChallengeSubmissionQuery = {
  __typename?: "Query";
  challengeSubmission: {
    __typename?: "ChallengeSubmission";
    id: string;
    avgMaxMemory: number;
    avgPerformance: number;
    challengeId: number;
    challengeSubmissionId: number;
    code: string;
    companyId: number;
    correctnessTestResult: string;
    coverage: number;
    isBest: boolean;
    snapshots: Array<{ __typename?: "ChallengeSnapshot"; id: string; challengeId: number; challengeSnapshotId: number }>;
  };
};

export type ChallengeRunCodeQueryVariables = Exact<{
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
  challengeId: Scalars["Int"]["input"];
  codeBody: Scalars["String"]["input"];
  runtime: Scalars["String"]["input"];
  input: Scalars["String"]["input"];
  takeSnapshot: Scalars["Boolean"]["input"];
}>;

export type ChallengeRunCodeQuery = {
  __typename?: "Query";
  challengeRunCode: {
    __typename?: "ChallengeRunCodeResponse";
    output: string;
    log: string;
    status: string;
    expected: string;
    isAccepted: boolean;
    performance: number;
    maxMemory: number;
    snapshotId: number;
  } | null;
};

export type StartChallengeMutationVariables = Exact<{
  challengeId: Scalars["Int"]["input"];
  candidateId: Scalars["String"]["input"];
}>;

export type StartChallengeMutation = {
  __typename?: "Mutation";
  startChallenge: {
    __typename: "ChallengeEntity";
    challengeEntityId: number;
    questionVersion: string;
    enabledLanguages: Array<string>;
    appealMessage: string | null;
    challengeStatus: ChallengeStatus;
    challengeQuestion: {
      __typename?: "AlgorithmQuestion";
      id: string;
      questionId: number;
      version: string;
      variant: AlgorithmQuestionVariant;
      companyId: number;
      employeeId: string;
      titleJa: string | null;
      titleEn: string | null;
      descriptionJa: string | null;
      descriptionEn: string | null;
      difficulty: Difficulty;
      supportedLanguages: Array<string>;
      signature: string;
      correctnessTestCase: string;
      performanceTestCase: string;
      performanceTimeLimitMilliSeconds: number;
      hints: Array<{
        __typename?: "AlgorithmHint";
        id: number;
        questionId: number;
        questionVersion: string;
        descriptionJa: string | null;
        descriptionEn: string | null;
        orderNum: number;
      }>;
      initialCode: {
        __typename?: "AlgorithmInitialCode";
        c: string;
        cpp: string;
        csharp: string;
        dart: string;
        go: string;
        java: string;
        javascript: string;
        kotlin: string;
        mysql: string;
        perl: string;
        pgsql: string;
        php: string;
        python3: string;
        ruby: string;
        rust: string;
        scala: string;
        sqlite: string;
        swift: string;
        typescript: string;
      } | null;
    } | null;
    submissions: Array<{ __typename?: "ChallengeSubmission"; challengeSubmissionId: number }>;
  };
};

export type SaveChallengeAppealMessageMutationVariables = Exact<{
  challengeId: Scalars["Int"]["input"];
  appealMessage: Scalars["String"]["input"];
}>;

export type SaveChallengeAppealMessageMutation = {
  __typename?: "Mutation";
  saveChallengeAppealMessage: {
    __typename: "ChallengeEntity";
    challengeEntityId: number;
    questionVersion: string;
    enabledLanguages: Array<string>;
    appealMessage: string | null;
    challengeStatus: ChallengeStatus;
    challengeQuestion: {
      __typename?: "AlgorithmQuestion";
      id: string;
      questionId: number;
      version: string;
      variant: AlgorithmQuestionVariant;
      companyId: number;
      employeeId: string;
      titleJa: string | null;
      titleEn: string | null;
      descriptionJa: string | null;
      descriptionEn: string | null;
      difficulty: Difficulty;
      supportedLanguages: Array<string>;
      signature: string;
      correctnessTestCase: string;
      performanceTestCase: string;
      performanceTimeLimitMilliSeconds: number;
      hints: Array<{
        __typename?: "AlgorithmHint";
        id: number;
        questionId: number;
        questionVersion: string;
        descriptionJa: string | null;
        descriptionEn: string | null;
        orderNum: number;
      }>;
      initialCode: {
        __typename?: "AlgorithmInitialCode";
        c: string;
        cpp: string;
        csharp: string;
        dart: string;
        go: string;
        java: string;
        javascript: string;
        kotlin: string;
        mysql: string;
        perl: string;
        pgsql: string;
        php: string;
        python3: string;
        ruby: string;
        rust: string;
        scala: string;
        sqlite: string;
        swift: string;
        typescript: string;
      } | null;
    } | null;
    submissions: Array<{ __typename?: "ChallengeSubmission"; challengeSubmissionId: number }>;
  } | null;
};

export type ChallengeSubmitCodeMutationVariables = Exact<{
  challengeId: Scalars["Int"]["input"];
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
  runtime: Scalars["String"]["input"];
  codeBody: Scalars["String"]["input"];
}>;

export type ChallengeSubmitCodeMutation = {
  __typename?: "Mutation";
  challengeSubmitCode: {
    __typename?: "ChallengeSubmission";
    id: string;
    avgMaxMemory: number;
    avgPerformance: number;
    challengeId: number;
    challengeSubmissionId: number;
    code: string;
    companyId: number;
    correctnessTestResult: string;
    coverage: number;
    isBest: boolean;
    snapshots: Array<{ __typename?: "ChallengeSnapshot"; id: string; challengeId: number; challengeSnapshotId: number }>;
  };
};

export type ChallengeWebSessionForChallengeCodingEditorFragment = {
  __typename?: "ChallengeWebSession";
  sessionId: string;
  challengeId: number;
  language: string;
  latestWebSearchedLog: { __typename?: "LatestWebSearchedLog"; searchEventId: string; query: string; page: number | null } | null;
};

export type ChallengeChatGptSessionForChallengeCodingEditorFragment = {
  __typename?: "ChallengeChatGPTSession";
  sessionId: string;
  challengeId: number;
  language: string;
  currentChatId: string | null;
  currentChatGPTMessages: Array<{ __typename?: "ChatGPTMessage"; role: ChatGptRole; content: string }>;
};

export type StartChallengeSessionsForChallengeCodingEditorMutationVariables = Exact<{
  input: StartChallengeSessionsInput;
}>;

export type StartChallengeSessionsForChallengeCodingEditorMutation = {
  __typename?: "Mutation";
  startChallengeSessions: {
    __typename?: "StartChallengeSessionsResponse";
    webSession: {
      __typename?: "ChallengeWebSession";
      sessionId: string;
      challengeId: number;
      language: string;
      latestWebSearchedLog: { __typename?: "LatestWebSearchedLog"; searchEventId: string; query: string; page: number | null } | null;
    } | null;
    chatGPTSession: {
      __typename?: "ChallengeChatGPTSession";
      sessionId: string;
      challengeId: number;
      language: string;
      currentChatId: string | null;
      currentChatGPTMessages: Array<{ __typename?: "ChatGPTMessage"; role: ChatGptRole; content: string }>;
    } | null;
  };
};

export type WebSearchEventForChallengeCodingEditorFragment = {
  __typename?: "WebSearchedEvent";
  eventId: string;
  query: string;
  page: number | null;
  sessionId: string;
  searchedAtSeconds: number | null;
};

export type StoreWebSearchEventForChallengeCodingEditorMutationVariables = Exact<{
  input: StoreWebSearchEventInput;
}>;

export type StoreWebSearchEventForChallengeCodingEditorMutation = {
  __typename?: "Mutation";
  storeWebSearchEvent: {
    __typename?: "WebSearchedEvent";
    eventId: string;
    query: string;
    page: number | null;
    sessionId: string;
    searchedAtSeconds: number | null;
  };
};

export type StoreWebAccessEventForChallengeCodingEditorMutationVariables = Exact<{
  input: StoreWebAccessEventInput;
}>;

export type StoreWebAccessEventForChallengeCodingEditorMutation = {
  __typename?: "Mutation";
  storeWebAccessEvent: { __typename?: "WebAccessedEvent"; eventId: string };
};

export type AskChatGptForChallengeCodingEditorMutationVariables = Exact<{
  input: AskChatGptInput;
}>;

export type AskChatGptForChallengeCodingEditorMutation = {
  __typename?: "Mutation";
  askChatGPT: {
    __typename?: "AskChatGPTResponse";
    chatId: string;
    sessionId: string;
    messages: Array<{ __typename?: "ChatGPTMessage"; role: ChatGptRole; content: string }>;
  };
};

export type ResetChatGptConversationForChallengeCodingEditorMutationVariables = Exact<{
  input: ResetChatGptConversationInput;
}>;

export type ResetChatGptConversationForChallengeCodingEditorMutation = { __typename?: "Mutation"; resetChatGPTConversation: boolean };

export type ChallengeStatisticsStatForRankVisualizerFragment = {
  __typename?: "StatisticsStats";
  avg: number;
  max: number;
  min: number;
  p25: number;
  p50: number;
  p75: number;
  p95: number;
  std: number;
};

export type ChallengeStatisticsForRankVisualizerFragment = {
  __typename: "ChallengeStatistics";
  bins: Array<number>;
  avgMaxMemory: {
    __typename?: "StatisticsStats";
    avg: number;
    max: number;
    min: number;
    p25: number;
    p50: number;
    p75: number;
    p95: number;
    std: number;
  } | null;
  avgPerformance: {
    __typename?: "StatisticsStats";
    avg: number;
    max: number;
    min: number;
    p25: number;
    p50: number;
    p75: number;
    p95: number;
    std: number;
  } | null;
  coverage: {
    __typename?: "StatisticsStats";
    avg: number;
    max: number;
    min: number;
    p25: number;
    p50: number;
    p75: number;
    p95: number;
    std: number;
  } | null;
  elapsedTime: {
    __typename?: "StatisticsStats";
    avg: number;
    max: number;
    min: number;
    p25: number;
    p50: number;
    p75: number;
    p95: number;
    std: number;
  } | null;
  numHints: {
    __typename?: "StatisticsStats";
    avg: number;
    max: number;
    min: number;
    p25: number;
    p50: number;
    p75: number;
    p95: number;
    std: number;
  } | null;
  numTabEvents: {
    __typename?: "StatisticsStats";
    avg: number;
    max: number;
    min: number;
    p25: number;
    p50: number;
    p75: number;
    p95: number;
    std: number;
  } | null;
  numPasteEvents: {
    __typename?: "StatisticsStats";
    avg: number;
    max: number;
    min: number;
    p25: number;
    p50: number;
    p75: number;
    p95: number;
    std: number;
  } | null;
  performance: {
    __typename?: "StatisticsStats";
    avg: number;
    max: number;
    min: number;
    p25: number;
    p50: number;
    p75: number;
    p95: number;
    std: number;
  } | null;
  readabilityScore: {
    __typename?: "StatisticsStats";
    avg: number;
    max: number;
    min: number;
    p25: number;
    p50: number;
    p75: number;
    p95: number;
    std: number;
  } | null;
  totalScore: {
    __typename?: "StatisticsStats";
    avg: number;
    max: number;
    min: number;
    p25: number;
    p50: number;
    p75: number;
    p95: number;
    std: number;
  } | null;
  numSnapshots: {
    __typename?: "StatisticsStats";
    avg: number;
    max: number;
    min: number;
    p25: number;
    p50: number;
    p75: number;
    p95: number;
    std: number;
  } | null;
};

export type GetChallengeStatisticsAndRankForRankVisualizerQueryVariables = Exact<{
  statisticsSource: StatisticsSourceInput;
  rankSource: RankSourceInput;
}>;

export type GetChallengeStatisticsAndRankForRankVisualizerQuery = {
  __typename?: "Query";
  statistics: {
    __typename: "Statistics";
    challengeStatistics: {
      __typename: "ChallengeStatistics";
      bins: Array<number>;
      avgMaxMemory: {
        __typename?: "StatisticsStats";
        avg: number;
        max: number;
        min: number;
        p25: number;
        p50: number;
        p75: number;
        p95: number;
        std: number;
      } | null;
      avgPerformance: {
        __typename?: "StatisticsStats";
        avg: number;
        max: number;
        min: number;
        p25: number;
        p50: number;
        p75: number;
        p95: number;
        std: number;
      } | null;
      coverage: {
        __typename?: "StatisticsStats";
        avg: number;
        max: number;
        min: number;
        p25: number;
        p50: number;
        p75: number;
        p95: number;
        std: number;
      } | null;
      elapsedTime: {
        __typename?: "StatisticsStats";
        avg: number;
        max: number;
        min: number;
        p25: number;
        p50: number;
        p75: number;
        p95: number;
        std: number;
      } | null;
      numHints: {
        __typename?: "StatisticsStats";
        avg: number;
        max: number;
        min: number;
        p25: number;
        p50: number;
        p75: number;
        p95: number;
        std: number;
      } | null;
      numTabEvents: {
        __typename?: "StatisticsStats";
        avg: number;
        max: number;
        min: number;
        p25: number;
        p50: number;
        p75: number;
        p95: number;
        std: number;
      } | null;
      numPasteEvents: {
        __typename?: "StatisticsStats";
        avg: number;
        max: number;
        min: number;
        p25: number;
        p50: number;
        p75: number;
        p95: number;
        std: number;
      } | null;
      performance: {
        __typename?: "StatisticsStats";
        avg: number;
        max: number;
        min: number;
        p25: number;
        p50: number;
        p75: number;
        p95: number;
        std: number;
      } | null;
      readabilityScore: {
        __typename?: "StatisticsStats";
        avg: number;
        max: number;
        min: number;
        p25: number;
        p50: number;
        p75: number;
        p95: number;
        std: number;
      } | null;
      totalScore: {
        __typename?: "StatisticsStats";
        avg: number;
        max: number;
        min: number;
        p25: number;
        p50: number;
        p75: number;
        p95: number;
        std: number;
      } | null;
      numSnapshots: {
        __typename?: "StatisticsStats";
        avg: number;
        max: number;
        min: number;
        p25: number;
        p50: number;
        p75: number;
        p95: number;
        std: number;
      } | null;
    } | null;
  };
  rank: { __typename?: "StatisticsRank"; evaluation: StatisticsEvaluation; numSubset: number; rank: number; relativeScore: number };
};

export type ChallengeWebSessionForChallengeTestReportFragment = {
  __typename?: "ChallengeWebSession";
  sessionId: string;
  challengeId: number;
  language: string;
};

export type ChallengeChatGptSessionForChallengeTestReportFragment = {
  __typename?: "ChallengeChatGPTSession";
  sessionId: string;
  challengeId: number;
  language: string;
};

export type ChallengeSuspiciousInferenceForChallengeTestReportFragment = {
  __typename?: "SuspiciousInference";
  inferenceId: string;
  feedback: { __typename?: "SuspiciousInferenceFeedback"; isCorrect: boolean } | null;
};

export type AlgorithmInitialCodeForChallengeTestReportFragment = {
  __typename?: "AlgorithmInitialCode";
  c: string;
  cpp: string;
  csharp: string;
  dart: string;
  go: string;
  java: string;
  javascript: string;
  kotlin: string;
  mysql: string;
  perl: string;
  pgsql: string;
  php: string;
  python3: string;
  ruby: string;
  rust: string;
  scala: string;
  sqlite: string;
  swift: string;
  typescript: string;
};

export type AlgorithmQuestionForChallengeTestReportFragment = {
  __typename?: "AlgorithmQuestion";
  id: string;
  questionId: number;
  version: string;
  variant: AlgorithmQuestionVariant;
  titleJa: string | null;
  titleEn: string | null;
  difficulty: Difficulty;
  descriptionEn: string | null;
  descriptionJa: string | null;
  correctnessTestCase: string;
  isSupportedLeakScore: boolean;
  numUses: number;
  supportedLanguages: Array<string>;
  timeLimitSeconds: number | null;
  averageElapsedTimeSeconds: number;
  accuracyRate: number;
  isOfficial: boolean;
  isPrivate: boolean;
  performanceTimeLimitMilliSeconds: number;
  algorithmQuestionStatus: QuestionStatus;
  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
  employee: { __typename?: "User"; id: string; displayName: string };
  initialCode: {
    __typename?: "AlgorithmInitialCode";
    c: string;
    cpp: string;
    csharp: string;
    dart: string;
    go: string;
    java: string;
    javascript: string;
    kotlin: string;
    mysql: string;
    perl: string;
    pgsql: string;
    php: string;
    python3: string;
    ruby: string;
    rust: string;
    scala: string;
    sqlite: string;
    swift: string;
    typescript: string;
  } | null;
  answers: Array<{
    __typename?: "AlgorithmAnswer";
    id: number;
    titleEn: string | null;
    titleJa: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    label: string;
    runtimeComplexity: string;
    spaceComplexity: string;
  }>;
  hints: Array<{ __typename?: "AlgorithmHint"; id: number; descriptionJa: string | null; descriptionEn: string | null; orderNum: number }>;
};

export type InterviewReportChallengeFragment = {
  __typename?: "ChallengeEntity";
  id: string;
  entityId: number;
  challengeEntityId: number;
  totalScore: number;
  timeLimitSeconds: number | null;
  appealMessage: string | null;
  suspiciousDegree: ChallengeSuspiciousDegree;
  challengeStatus: ChallengeStatus;
  submissions: Array<{
    __typename?: "ChallengeSubmission";
    id: string;
    challengeSubmissionId: number;
    questionId: number;
    questionVersion: string;
    isBest: boolean;
    runtime: string;
    runtimeVersion: string;
    correctnessTestResult: string;
    totalScore: number;
    numSubset: number;
    totalElapsedTimeSeconds: number | null;
    readabilityTestResult: string;
    evaluatedAtSeconds: number | null;
    coverage: number;
    performance: number;
    readability: number;
    runtimeComplexity: string;
    spaceComplexity: string;
    avgPerformance: number;
    avgMaxMemory: number;
    machineOs: string;
    machineMemory: string;
    machineCpu: number | null;
    numPassed: number;
    numTests: number;
    numTabEvents: number;
    numPasteEvents: number;
    numAccessEvents: number;
    similarityScore: number | null;
    code: string;
    elapsedTimeSeconds: number | null;
    performanceTestResult: string;
    challengeUsedHintCount: number;
    challengeSubmissionStatus: ChallengeSubmissionStatus;
    similarCodes: Array<{
      __typename?: "ChallengeSubmissionSimilarCode";
      code: string;
      score: number;
      metadata:
        | { __typename: "SimilarCodeCrawlerMetadata"; url: string }
        | { __typename: "SimilarCodeNotFoundMetadata"; reserved: boolean | null }
        | { __typename: "SimilarCodeOtherCompanyMetadata"; reserved: boolean | null }
        | { __typename: "SimilarCodeSpotMetadata"; spotId: string; challengeId: number; questionId: number; questionVersion: string };
      fragments: Array<{
        __typename?: "ChallengeSubmissionSimilarCodeFragment";
        left: { __typename?: "ChallengeSubmissionSimilarCodeSelection"; endCol: number; endRow: number; startCol: number; startRow: number };
        right: { __typename?: "ChallengeSubmissionSimilarCodeSelection"; endCol: number; endRow: number; startCol: number; startRow: number };
      }>;
    }>;
    snapshots: Array<{ __typename?: "ChallengeSnapshot"; id: string; challengeSnapshotId: number }>;
  }>;
  challengeQuestion: {
    __typename?: "AlgorithmQuestion";
    id: string;
    questionId: number;
    version: string;
    variant: AlgorithmQuestionVariant;
    titleJa: string | null;
    titleEn: string | null;
    difficulty: Difficulty;
    descriptionEn: string | null;
    descriptionJa: string | null;
    correctnessTestCase: string;
    isSupportedLeakScore: boolean;
    numUses: number;
    supportedLanguages: Array<string>;
    timeLimitSeconds: number | null;
    averageElapsedTimeSeconds: number;
    accuracyRate: number;
    isOfficial: boolean;
    isPrivate: boolean;
    performanceTimeLimitMilliSeconds: number;
    algorithmQuestionStatus: QuestionStatus;
    leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
    employee: { __typename?: "User"; id: string; displayName: string };
    initialCode: {
      __typename?: "AlgorithmInitialCode";
      c: string;
      cpp: string;
      csharp: string;
      dart: string;
      go: string;
      java: string;
      javascript: string;
      kotlin: string;
      mysql: string;
      perl: string;
      pgsql: string;
      php: string;
      python3: string;
      ruby: string;
      rust: string;
      scala: string;
      sqlite: string;
      swift: string;
      typescript: string;
    } | null;
    answers: Array<{
      __typename?: "AlgorithmAnswer";
      id: number;
      titleEn: string | null;
      titleJa: string | null;
      descriptionJa: string | null;
      descriptionEn: string | null;
      label: string;
      runtimeComplexity: string;
      spaceComplexity: string;
    }>;
    hints: Array<{ __typename?: "AlgorithmHint"; id: number; descriptionJa: string | null; descriptionEn: string | null; orderNum: number }>;
  } | null;
  sessions: Array<
    | { __typename?: "ChallengeChatGPTSession"; sessionId: string; challengeId: number; language: string }
    | { __typename?: "ChallengeWebSession"; sessionId: string; challengeId: number; language: string }
  >;
  suspiciousInference: {
    __typename?: "SuspiciousInference";
    inferenceId: string;
    feedback: { __typename?: "SuspiciousInferenceFeedback"; isCorrect: boolean } | null;
  } | null;
};

export type GetChallengeEntityForChallengeTestReportQueryVariables = Exact<{
  challengeId: Scalars["Int"]["input"];
  withAnswers: Scalars["Boolean"]["input"];
}>;

export type GetChallengeEntityForChallengeTestReportQuery = {
  __typename?: "Query";
  challengeEntity: {
    __typename?: "ChallengeEntity";
    id: string;
    entityId: number;
    challengeEntityId: number;
    totalScore: number;
    timeLimitSeconds: number | null;
    appealMessage: string | null;
    suspiciousDegree: ChallengeSuspiciousDegree;
    challengeStatus: ChallengeStatus;
    submissions: Array<{
      __typename?: "ChallengeSubmission";
      id: string;
      challengeSubmissionId: number;
      questionId: number;
      questionVersion: string;
      isBest: boolean;
      runtime: string;
      runtimeVersion: string;
      correctnessTestResult: string;
      totalScore: number;
      numSubset: number;
      totalElapsedTimeSeconds: number | null;
      readabilityTestResult: string;
      evaluatedAtSeconds: number | null;
      coverage: number;
      performance: number;
      readability: number;
      runtimeComplexity: string;
      spaceComplexity: string;
      avgPerformance: number;
      avgMaxMemory: number;
      machineOs: string;
      machineMemory: string;
      machineCpu: number | null;
      numPassed: number;
      numTests: number;
      numTabEvents: number;
      numPasteEvents: number;
      numAccessEvents: number;
      similarityScore: number | null;
      code: string;
      elapsedTimeSeconds: number | null;
      performanceTestResult: string;
      challengeUsedHintCount: number;
      challengeSubmissionStatus: ChallengeSubmissionStatus;
      similarCodes: Array<{
        __typename?: "ChallengeSubmissionSimilarCode";
        code: string;
        score: number;
        metadata:
          | { __typename: "SimilarCodeCrawlerMetadata"; url: string }
          | { __typename: "SimilarCodeNotFoundMetadata"; reserved: boolean | null }
          | { __typename: "SimilarCodeOtherCompanyMetadata"; reserved: boolean | null }
          | { __typename: "SimilarCodeSpotMetadata"; spotId: string; challengeId: number; questionId: number; questionVersion: string };
        fragments: Array<{
          __typename?: "ChallengeSubmissionSimilarCodeFragment";
          left: { __typename?: "ChallengeSubmissionSimilarCodeSelection"; endCol: number; endRow: number; startCol: number; startRow: number };
          right: { __typename?: "ChallengeSubmissionSimilarCodeSelection"; endCol: number; endRow: number; startCol: number; startRow: number };
        }>;
      }>;
      snapshots: Array<{ __typename?: "ChallengeSnapshot"; id: string; challengeSnapshotId: number }>;
    }>;
    challengeQuestion: {
      __typename?: "AlgorithmQuestion";
      id: string;
      questionId: number;
      version: string;
      variant: AlgorithmQuestionVariant;
      titleJa: string | null;
      titleEn: string | null;
      difficulty: Difficulty;
      descriptionEn: string | null;
      descriptionJa: string | null;
      correctnessTestCase: string;
      isSupportedLeakScore: boolean;
      numUses: number;
      supportedLanguages: Array<string>;
      timeLimitSeconds: number | null;
      averageElapsedTimeSeconds: number;
      accuracyRate: number;
      isOfficial: boolean;
      isPrivate: boolean;
      performanceTimeLimitMilliSeconds: number;
      algorithmQuestionStatus: QuestionStatus;
      leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
      employee: { __typename?: "User"; id: string; displayName: string };
      initialCode: {
        __typename?: "AlgorithmInitialCode";
        c: string;
        cpp: string;
        csharp: string;
        dart: string;
        go: string;
        java: string;
        javascript: string;
        kotlin: string;
        mysql: string;
        perl: string;
        pgsql: string;
        php: string;
        python3: string;
        ruby: string;
        rust: string;
        scala: string;
        sqlite: string;
        swift: string;
        typescript: string;
      } | null;
      answers: Array<{
        __typename?: "AlgorithmAnswer";
        id: number;
        titleEn: string | null;
        titleJa: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        label: string;
        runtimeComplexity: string;
        spaceComplexity: string;
      }>;
      hints: Array<{ __typename?: "AlgorithmHint"; id: number; descriptionJa: string | null; descriptionEn: string | null; orderNum: number }>;
    } | null;
    sessions: Array<
      | { __typename?: "ChallengeChatGPTSession"; sessionId: string; challengeId: number; language: string }
      | { __typename?: "ChallengeWebSession"; sessionId: string; challengeId: number; language: string }
    >;
    suspiciousInference: {
      __typename?: "SuspiciousInference";
      inferenceId: string;
      feedback: { __typename?: "SuspiciousInferenceFeedback"; isCorrect: boolean } | null;
    } | null;
  };
};

export type ChallengeSimilarCodeForChallengeTestReportFragment = {
  __typename?: "ChallengeSubmissionSimilarCode";
  code: string;
  score: number;
  metadata:
    | { __typename: "SimilarCodeCrawlerMetadata"; url: string }
    | { __typename: "SimilarCodeNotFoundMetadata"; reserved: boolean | null }
    | { __typename: "SimilarCodeOtherCompanyMetadata"; reserved: boolean | null }
    | { __typename: "SimilarCodeSpotMetadata"; spotId: string; challengeId: number; questionId: number; questionVersion: string };
  fragments: Array<{
    __typename?: "ChallengeSubmissionSimilarCodeFragment";
    left: { __typename?: "ChallengeSubmissionSimilarCodeSelection"; endCol: number; endRow: number; startCol: number; startRow: number };
    right: { __typename?: "ChallengeSubmissionSimilarCodeSelection"; endCol: number; endRow: number; startCol: number; startRow: number };
  }>;
};

export type TestReportChallengeSubmissionFragment = {
  __typename?: "ChallengeSubmission";
  id: string;
  challengeSubmissionId: number;
  questionId: number;
  questionVersion: string;
  isBest: boolean;
  runtime: string;
  runtimeVersion: string;
  correctnessTestResult: string;
  totalScore: number;
  numSubset: number;
  totalElapsedTimeSeconds: number | null;
  readabilityTestResult: string;
  evaluatedAtSeconds: number | null;
  coverage: number;
  performance: number;
  readability: number;
  runtimeComplexity: string;
  spaceComplexity: string;
  avgPerformance: number;
  avgMaxMemory: number;
  machineOs: string;
  machineMemory: string;
  machineCpu: number | null;
  numPassed: number;
  numTests: number;
  numTabEvents: number;
  numPasteEvents: number;
  numAccessEvents: number;
  similarityScore: number | null;
  code: string;
  elapsedTimeSeconds: number | null;
  performanceTestResult: string;
  challengeUsedHintCount: number;
  challengeSubmissionStatus: ChallengeSubmissionStatus;
  similarCodes: Array<{
    __typename?: "ChallengeSubmissionSimilarCode";
    code: string;
    score: number;
    metadata:
      | { __typename: "SimilarCodeCrawlerMetadata"; url: string }
      | { __typename: "SimilarCodeNotFoundMetadata"; reserved: boolean | null }
      | { __typename: "SimilarCodeOtherCompanyMetadata"; reserved: boolean | null }
      | { __typename: "SimilarCodeSpotMetadata"; spotId: string; challengeId: number; questionId: number; questionVersion: string };
    fragments: Array<{
      __typename?: "ChallengeSubmissionSimilarCodeFragment";
      left: { __typename?: "ChallengeSubmissionSimilarCodeSelection"; endCol: number; endRow: number; startCol: number; startRow: number };
      right: { __typename?: "ChallengeSubmissionSimilarCodeSelection"; endCol: number; endRow: number; startCol: number; startRow: number };
    }>;
  }>;
  snapshots: Array<{ __typename?: "ChallengeSnapshot"; id: string; challengeSnapshotId: number }>;
};

export type GetChallengeSubmissionForChallengeTestReportQueryVariables = Exact<{
  submissionId: Scalars["Int"]["input"];
}>;

export type GetChallengeSubmissionForChallengeTestReportQuery = {
  __typename?: "Query";
  challengeSubmission: {
    __typename?: "ChallengeSubmission";
    id: string;
    challengeSubmissionId: number;
    questionId: number;
    questionVersion: string;
    isBest: boolean;
    runtime: string;
    runtimeVersion: string;
    correctnessTestResult: string;
    totalScore: number;
    numSubset: number;
    totalElapsedTimeSeconds: number | null;
    readabilityTestResult: string;
    evaluatedAtSeconds: number | null;
    coverage: number;
    performance: number;
    readability: number;
    runtimeComplexity: string;
    spaceComplexity: string;
    avgPerformance: number;
    avgMaxMemory: number;
    machineOs: string;
    machineMemory: string;
    machineCpu: number | null;
    numPassed: number;
    numTests: number;
    numTabEvents: number;
    numPasteEvents: number;
    numAccessEvents: number;
    similarityScore: number | null;
    code: string;
    elapsedTimeSeconds: number | null;
    performanceTestResult: string;
    challengeUsedHintCount: number;
    challengeSubmissionStatus: ChallengeSubmissionStatus;
    similarCodes: Array<{
      __typename?: "ChallengeSubmissionSimilarCode";
      code: string;
      score: number;
      metadata:
        | { __typename: "SimilarCodeCrawlerMetadata"; url: string }
        | { __typename: "SimilarCodeNotFoundMetadata"; reserved: boolean | null }
        | { __typename: "SimilarCodeOtherCompanyMetadata"; reserved: boolean | null }
        | { __typename: "SimilarCodeSpotMetadata"; spotId: string; challengeId: number; questionId: number; questionVersion: string };
      fragments: Array<{
        __typename?: "ChallengeSubmissionSimilarCodeFragment";
        left: { __typename?: "ChallengeSubmissionSimilarCodeSelection"; endCol: number; endRow: number; startCol: number; startRow: number };
        right: { __typename?: "ChallengeSubmissionSimilarCodeSelection"; endCol: number; endRow: number; startCol: number; startRow: number };
      }>;
    }>;
    snapshots: Array<{ __typename?: "ChallengeSnapshot"; id: string; challengeSnapshotId: number }>;
  };
};

export type FeedbackSuspiciousDegreeForChallengeTestReportMutationVariables = Exact<{
  input: FeedbackSuspiciousDegreeInput;
}>;

export type FeedbackSuspiciousDegreeForChallengeTestReportMutation = { __typename?: "Mutation"; feedbackSuspiciousDegree: boolean };

export type ExportTestResultTableMutationVariables = Exact<{
  jsonQueries: Array<JsonQuery> | JsonQuery;
  language: ExportLanguage;
  fileType: ExportFileType;
  filters: InputMaybe<SpotsByCompanyIdFilters>;
}>;

export type ExportTestResultTableMutation = {
  __typename?: "Mutation";
  exportTestResultTable: { __typename?: "ExportTestsResponse"; id: string };
};

export type EmployeeForEmployeeAssignFieldFragment = {
  __typename?: "User";
  uid: string;
  displayName: string;
  fullName: string;
  email: string;
  photoUrl: string;
};

export type CompanyForEmployeeAssignFieldFragment = {
  __typename?: "Company";
  companyId: number;
  name: string;
  employees: Array<{ __typename?: "User"; uid: string; displayName: string; fullName: string; email: string; photoUrl: string }>;
};

export type GetEmployeesForEmployeeAssignFieldQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
}>;

export type GetEmployeesForEmployeeAssignFieldQuery = {
  __typename?: "Query";
  company: {
    __typename?: "Company";
    companyId: number;
    name: string;
    employees: Array<{ __typename?: "User"; uid: string; displayName: string; fullName: string; email: string; photoUrl: string }>;
  };
};

export type GetCompanyForEmployeeGroupChipsGroupFragment = {
  __typename?: "EmployeeGroup";
  id: string;
  groupName: string;
  createdAtSeconds: number | null;
  companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string }>;
};

export type GetCompanyForEmployeeGroupChipsEmployeeFragment = {
  __typename?: "User";
  id: string;
  displayName: string;
  fullName: string;
  email: string;
  photoUrl: string;
};

export type GetCompanyForEmployeeGroupChipsCompanyFragment = {
  __typename?: "Company";
  companyId: number;
  name: string;
  employees: Array<{ __typename?: "User"; id: string; displayName: string; fullName: string; email: string; photoUrl: string }>;
  groups: Array<{
    __typename?: "EmployeeGroup";
    id: string;
    groupName: string;
    createdAtSeconds: number | null;
    companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string }>;
  }>;
};

export type GetCompanyForEmployeeGroupChipsQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
}>;

export type GetCompanyForEmployeeGroupChipsQuery = {
  __typename?: "Query";
  company: {
    __typename?: "Company";
    companyId: number;
    name: string;
    employees: Array<{ __typename?: "User"; id: string; displayName: string; fullName: string; email: string; photoUrl: string }>;
    groups: Array<{
      __typename?: "EmployeeGroup";
      id: string;
      groupName: string;
      createdAtSeconds: number | null;
      companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string }>;
    }>;
  };
};

export type EmployeeNotificationForEmployeeNavigationFragment = {
  __typename?: "EmployeeNotification";
  id: string;
  notificationId: number;
  employeeId: string;
  isRead: boolean;
  titleJa: string;
  titleEn: string;
  descriptionJa: string;
  descriptionEn: string;
  createdAtSeconds: number | null;
  companyId: number;
  actionLink: string;
};

export type EmployeeNotificationsResponseForEmployeeNavigationFragment = {
  __typename?: "EmployeeNotificationsResponse";
  count: number;
  employeeNotifications: Array<{
    __typename?: "EmployeeNotification";
    id: string;
    notificationId: number;
    employeeId: string;
    isRead: boolean;
    titleJa: string;
    titleEn: string;
    descriptionJa: string;
    descriptionEn: string;
    createdAtSeconds: number | null;
    companyId: number;
    actionLink: string;
  }>;
  pager: { __typename?: "NotificationPager"; hasNext: boolean; cursor: number } | null;
};

export type ListEmployeeNotificationsQueryVariables = Exact<{
  employeeId: Scalars["String"]["input"];
  companyId: Scalars["Int"]["input"];
  cursor: InputMaybe<Scalars["Int"]["input"]>;
  size: Scalars["Int"]["input"];
  withCount: Scalars["Boolean"]["input"];
}>;

export type ListEmployeeNotificationsQuery = {
  __typename?: "Query";
  employeeNotifications: {
    __typename?: "EmployeeNotificationsResponse";
    count: number;
    employeeNotifications: Array<{
      __typename?: "EmployeeNotification";
      id: string;
      notificationId: number;
      employeeId: string;
      isRead: boolean;
      titleJa: string;
      titleEn: string;
      descriptionJa: string;
      descriptionEn: string;
      createdAtSeconds: number | null;
      companyId: number;
      actionLink: string;
    }>;
    pager: { __typename?: "NotificationPager"; hasNext: boolean; cursor: number } | null;
  };
};

export type GetCompaniesByEmployeeIdForEmployeeSideBarContentQueryVariables = Exact<{
  uid: Scalars["String"]["input"];
  size: Scalars["Int"]["input"];
  cursorSeconds: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetCompaniesByEmployeeIdForEmployeeSideBarContentQuery = {
  __typename?: "Query";
  companiesByEmployeeId: {
    __typename?: "ListCompanies";
    activeCompanyId: number;
    companies: Array<{
      __typename?: "Company";
      companyId: number;
      name: string;
      isHireRoo: boolean;
      enableTalentScore: boolean;
      companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string; role: Role }>;
      security: { __typename?: "Security"; ssoEnabled: boolean; ssoMode: SsoMode; providerId: string } | null;
    }>;
    pager: { __typename?: "CompaniesByTalentIdTimestampPager"; cursorSeconds: number | null; hasNext: boolean } | null;
  };
};

export type EvaluationMetricGroupsForEvaluationMetricGroupSearchFieldFragment = {
  __typename?: "EvaluationMetricGroup";
  id: string;
  metricGroupId: number;
  companyId: number;
  title: string;
  description: string;
  createdBy: string;
};

export type EvaluationMetricGroupsByCompanyResponseForEvaluationMetricGroupSearchFieldFragment = {
  __typename?: "EvaluationMetricGroupsByCompanyResponse";
  count: number;
  metricGroups: Array<{
    __typename?: "EvaluationMetricGroup";
    id: string;
    metricGroupId: number;
    companyId: number;
    title: string;
    description: string;
    createdBy: string;
  }>;
  pager: { __typename?: "EvaluationPager"; cursor: number; hasNext: boolean } | null;
};

export type ListEvaluationMetricsGroupsForEvaluationMetricGroupSearchFieldQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  size: Scalars["Int"]["input"];
  cursor: InputMaybe<Scalars["Int"]["input"]>;
  withCount: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type ListEvaluationMetricsGroupsForEvaluationMetricGroupSearchFieldQuery = {
  __typename?: "Query";
  evaluationMetricGroupsByCompany: {
    __typename?: "EvaluationMetricGroupsByCompanyResponse";
    count: number;
    metricGroups: Array<{
      __typename?: "EvaluationMetricGroup";
      id: string;
      metricGroupId: number;
      companyId: number;
      title: string;
      description: string;
      createdBy: string;
    }>;
    pager: { __typename?: "EvaluationPager"; cursor: number; hasNext: boolean } | null;
  } | null;
};

export type EvaluationMetricForEvaluationMetricSearchFieldFragment = {
  __typename?: "EvaluationMetric";
  id: string;
  metricId: number;
  companyId: number;
  createdBy: string;
  title: string;
  description: string;
  createdAtSeconds: number;
};

export type EvaluationMetricsByCompanyResponseForEvaluationMetricSearchFieldFragment = {
  __typename?: "EvaluationMetricsByCompanyResponse";
  count: number;
  metrics: Array<{
    __typename?: "EvaluationMetric";
    id: string;
    metricId: number;
    companyId: number;
    createdBy: string;
    title: string;
    description: string;
    createdAtSeconds: number;
  }>;
  pager: { __typename?: "EvaluationPager"; cursor: number; hasNext: boolean } | null;
};

export type ListEvaluationMetricsForEvaluationMetricSearchFieldQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  size: Scalars["Int"]["input"];
  cursor: InputMaybe<Scalars["Int"]["input"]>;
  withCount: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type ListEvaluationMetricsForEvaluationMetricSearchFieldQuery = {
  __typename?: "Query";
  evaluationMetricsByCompany: {
    __typename?: "EvaluationMetricsByCompanyResponse";
    count: number;
    metrics: Array<{
      __typename?: "EvaluationMetric";
      id: string;
      metricId: number;
      companyId: number;
      createdBy: string;
      title: string;
      description: string;
      createdAtSeconds: number;
    }>;
    pager: { __typename?: "EvaluationPager"; cursor: number; hasNext: boolean } | null;
  } | null;
};

export type EvaluationMetricForEvaluationMetricsFragment = {
  __typename?: "EvaluationMetric";
  id: string;
  metricId: number;
  companyId: number;
  createdBy: string;
  title: string;
  description: string;
  createdAtSeconds: number;
  updatedAtSeconds: number;
  employee: {
    __typename?: "User";
    id: string;
    uid: string;
    displayName: string;
    email: string;
    fullName: string;
    photoUrl: string;
    emailVerified: boolean;
    language: Language;
    provider: AuthProvider | null;
  };
};

export type EvaluationMetricsByCompanyResponseForEvaluationMetricsFragment = {
  __typename?: "EvaluationMetricsByCompanyResponse";
  count: number;
  metrics: Array<{
    __typename?: "EvaluationMetric";
    id: string;
    metricId: number;
    companyId: number;
    createdBy: string;
    title: string;
    description: string;
    createdAtSeconds: number;
    updatedAtSeconds: number;
    employee: {
      __typename?: "User";
      id: string;
      uid: string;
      displayName: string;
      email: string;
      fullName: string;
      photoUrl: string;
      emailVerified: boolean;
      language: Language;
      provider: AuthProvider | null;
    };
  }>;
  pager: { __typename?: "EvaluationPager"; cursor: number; hasNext: boolean } | null;
};

export type ListEvaluationMetricsForEvaluationMetricsQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  size: Scalars["Int"]["input"];
  cursor: InputMaybe<Scalars["Int"]["input"]>;
  withCount: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type ListEvaluationMetricsForEvaluationMetricsQuery = {
  __typename?: "Query";
  evaluationMetricsByCompany: {
    __typename?: "EvaluationMetricsByCompanyResponse";
    count: number;
    metrics: Array<{
      __typename?: "EvaluationMetric";
      id: string;
      metricId: number;
      companyId: number;
      createdBy: string;
      title: string;
      description: string;
      createdAtSeconds: number;
      updatedAtSeconds: number;
      employee: {
        __typename?: "User";
        id: string;
        uid: string;
        displayName: string;
        email: string;
        fullName: string;
        photoUrl: string;
        emailVerified: boolean;
        language: Language;
        provider: AuthProvider | null;
      };
    }>;
    pager: { __typename?: "EvaluationPager"; cursor: number; hasNext: boolean } | null;
  } | null;
};

export type GetEvaluationMetricForEvaluationMetricsQueryVariables = Exact<{
  metricId: Scalars["Int"]["input"];
}>;

export type GetEvaluationMetricForEvaluationMetricsQuery = {
  __typename?: "Query";
  evaluationMetric: {
    __typename?: "EvaluationMetric";
    id: string;
    metricId: number;
    companyId: number;
    createdBy: string;
    title: string;
    description: string;
    createdAtSeconds: number;
    updatedAtSeconds: number;
    employee: {
      __typename?: "User";
      id: string;
      uid: string;
      displayName: string;
      email: string;
      fullName: string;
      photoUrl: string;
      emailVerified: boolean;
      language: Language;
      provider: AuthProvider | null;
    };
  };
};

export type CreateEvaluationMetricForEvaluationMetricsMutationVariables = Exact<{
  input: CreateEvaluationMetricInput;
}>;

export type CreateEvaluationMetricForEvaluationMetricsMutation = {
  __typename?: "Mutation";
  createEvaluationMetric: {
    __typename?: "EvaluationMetric";
    id: string;
    metricId: number;
    companyId: number;
    createdBy: string;
    title: string;
    description: string;
    createdAtSeconds: number;
    updatedAtSeconds: number;
    employee: {
      __typename?: "User";
      id: string;
      uid: string;
      displayName: string;
      email: string;
      fullName: string;
      photoUrl: string;
      emailVerified: boolean;
      language: Language;
      provider: AuthProvider | null;
    };
  };
};

export type AddEvaluationMetricsToGroupsForEvaluationMetricsMutationVariables = Exact<{
  input: AddEvaluationMetricsToGroupsInput;
}>;

export type AddEvaluationMetricsToGroupsForEvaluationMetricsMutation = { __typename?: "Mutation"; addEvaluationMetricsToGroups: boolean };

export type EvaluationMetricGroupForEvaluationMetricsGroupsFragment = {
  __typename?: "EvaluationMetricGroup";
  id: string;
  metricGroupId: number;
  companyId: number;
  title: string;
  description: string;
  createdAtSeconds: number;
  updatedAtSeconds: number;
  createdBy: string;
  metrics: Array<{
    __typename?: "EvaluationMetric";
    id: string;
    metricId: number;
    companyId: number;
    createdBy: string;
    title: string;
    description: string;
    createdAtSeconds: number;
    employee: {
      __typename?: "User";
      id: string;
      uid: string;
      displayName: string;
      email: string;
      fullName: string;
      photoUrl: string;
      emailVerified: boolean;
      language: Language;
      provider: AuthProvider | null;
    };
  }>;
  employee: {
    __typename?: "User";
    id: string;
    uid: string;
    displayName: string;
    email: string;
    fullName: string;
    photoUrl: string;
    emailVerified: boolean;
    language: Language;
    provider: AuthProvider | null;
  };
};

export type EvaluationMetricGroupsByCompanyResponseForEvaluationMetricsGroupsFragment = {
  __typename?: "EvaluationMetricGroupsByCompanyResponse";
  count: number;
  metricGroups: Array<{
    __typename?: "EvaluationMetricGroup";
    id: string;
    metricGroupId: number;
    companyId: number;
    title: string;
    description: string;
    createdAtSeconds: number;
    updatedAtSeconds: number;
    createdBy: string;
    metrics: Array<{
      __typename?: "EvaluationMetric";
      id: string;
      metricId: number;
      companyId: number;
      createdBy: string;
      title: string;
      description: string;
      createdAtSeconds: number;
      employee: {
        __typename?: "User";
        id: string;
        uid: string;
        displayName: string;
        email: string;
        fullName: string;
        photoUrl: string;
        emailVerified: boolean;
        language: Language;
        provider: AuthProvider | null;
      };
    }>;
    employee: {
      __typename?: "User";
      id: string;
      uid: string;
      displayName: string;
      email: string;
      fullName: string;
      photoUrl: string;
      emailVerified: boolean;
      language: Language;
      provider: AuthProvider | null;
    };
  }>;
  pager: { __typename?: "EvaluationPager"; cursor: number; hasNext: boolean } | null;
};

export type ListEvaluationMetricsGroupsForEvaluationMetricsGroupsQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  size: Scalars["Int"]["input"];
  cursor: InputMaybe<Scalars["Int"]["input"]>;
  withCount: InputMaybe<Scalars["Boolean"]["input"]>;
  isDescending: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type ListEvaluationMetricsGroupsForEvaluationMetricsGroupsQuery = {
  __typename?: "Query";
  evaluationMetricGroupsByCompany: {
    __typename?: "EvaluationMetricGroupsByCompanyResponse";
    count: number;
    metricGroups: Array<{
      __typename?: "EvaluationMetricGroup";
      id: string;
      metricGroupId: number;
      companyId: number;
      title: string;
      description: string;
      createdAtSeconds: number;
      updatedAtSeconds: number;
      createdBy: string;
      metrics: Array<{
        __typename?: "EvaluationMetric";
        id: string;
        metricId: number;
        companyId: number;
        createdBy: string;
        title: string;
        description: string;
        createdAtSeconds: number;
        employee: {
          __typename?: "User";
          id: string;
          uid: string;
          displayName: string;
          email: string;
          fullName: string;
          photoUrl: string;
          emailVerified: boolean;
          language: Language;
          provider: AuthProvider | null;
        };
      }>;
      employee: {
        __typename?: "User";
        id: string;
        uid: string;
        displayName: string;
        email: string;
        fullName: string;
        photoUrl: string;
        emailVerified: boolean;
        language: Language;
        provider: AuthProvider | null;
      };
    }>;
    pager: { __typename?: "EvaluationPager"; cursor: number; hasNext: boolean } | null;
  } | null;
};

export type GetEvaluationMetricGroupForEvaluationMetricsGroupsQueryVariables = Exact<{
  metricGroupId: Scalars["Int"]["input"];
}>;

export type GetEvaluationMetricGroupForEvaluationMetricsGroupsQuery = {
  __typename?: "Query";
  evaluationMetricGroup: {
    __typename?: "EvaluationMetricGroup";
    id: string;
    metricGroupId: number;
    companyId: number;
    title: string;
    description: string;
    createdAtSeconds: number;
    updatedAtSeconds: number;
    createdBy: string;
    metrics: Array<{
      __typename?: "EvaluationMetric";
      id: string;
      metricId: number;
      companyId: number;
      createdBy: string;
      title: string;
      description: string;
      createdAtSeconds: number;
      employee: {
        __typename?: "User";
        id: string;
        uid: string;
        displayName: string;
        email: string;
        fullName: string;
        photoUrl: string;
        emailVerified: boolean;
        language: Language;
        provider: AuthProvider | null;
      };
    }>;
    employee: {
      __typename?: "User";
      id: string;
      uid: string;
      displayName: string;
      email: string;
      fullName: string;
      photoUrl: string;
      emailVerified: boolean;
      language: Language;
      provider: AuthProvider | null;
    };
  };
};

export type CreateEvaluationMetricGroupForEvaluationMetricsGroupsMutationVariables = Exact<{
  input: CreateEvaluationMetricGroupInput;
}>;

export type CreateEvaluationMetricGroupForEvaluationMetricsGroupsMutation = {
  __typename?: "Mutation";
  createEvaluationMetricGroup: {
    __typename?: "EvaluationMetricGroup";
    id: string;
    metricGroupId: number;
    companyId: number;
    title: string;
    description: string;
    createdAtSeconds: number;
    updatedAtSeconds: number;
    createdBy: string;
    metrics: Array<{
      __typename?: "EvaluationMetric";
      id: string;
      metricId: number;
      companyId: number;
      createdBy: string;
      title: string;
      description: string;
      createdAtSeconds: number;
      employee: {
        __typename?: "User";
        id: string;
        uid: string;
        displayName: string;
        email: string;
        fullName: string;
        photoUrl: string;
        emailVerified: boolean;
        language: Language;
        provider: AuthProvider | null;
      };
    }>;
    employee: {
      __typename?: "User";
      id: string;
      uid: string;
      displayName: string;
      email: string;
      fullName: string;
      photoUrl: string;
      emailVerified: boolean;
      language: Language;
      provider: AuthProvider | null;
    };
  };
};

export type ExamStatisticsForExamRankVisualizerFragment = {
  __typename: "ExamStatistics";
  bins: Array<number>;
  relativeScore: {
    __typename?: "StatisticsStats";
    avg: number;
    max: number;
    min: number;
    p25: number;
    p50: number;
    p75: number;
    p95: number;
    std: number;
  } | null;
};

export type RankForExamRankVisualizerFragment = {
  __typename?: "StatisticsRank";
  evaluation: StatisticsEvaluation;
  numSubset: number;
  rank: number;
  relativeScore: number;
};

export type GetExamStatisticsAndRankForExamRankVisualizerQueryVariables = Exact<{
  statisticsSource: StatisticsSourceInput;
  rankSource: RankSourceInput;
}>;

export type GetExamStatisticsAndRankForExamRankVisualizerQuery = {
  __typename?: "Query";
  statistics: {
    __typename: "Statistics";
    examStatistics: {
      __typename: "ExamStatistics";
      bins: Array<number>;
      relativeScore: {
        __typename?: "StatisticsStats";
        avg: number;
        max: number;
        min: number;
        p25: number;
        p50: number;
        p75: number;
        p95: number;
        std: number;
      } | null;
    } | null;
  };
  rank: { __typename?: "StatisticsRank"; evaluation: StatisticsEvaluation; numSubset: number; rank: number; relativeScore: number };
};

export type ExamForExamTransitionVisualizerFragment = {
  __typename: "Exam";
  id: string;
  examId: string;
  assessmentId: string;
  originId: string;
  numInterval: number;
  numTry: number;
  relativeScore: number;
  totalScore: number;
  startIntervalAtSeconds: number | null;
  endIntervalAtSeconds: number | null;
  createdAtSeconds: number | null;
  willEndAtSeconds: number | null;
  status: ExamStatus;
};

export type ListBestExamsResponseForExamTransitionVisualizerFragment = {
  __typename?: "ListBestExamsByAssessmentId";
  count: number;
  hasNext: boolean;
  exams: Array<{
    __typename: "Exam";
    id: string;
    examId: string;
    assessmentId: string;
    originId: string;
    numInterval: number;
    numTry: number;
    relativeScore: number;
    totalScore: number;
    startIntervalAtSeconds: number | null;
    endIntervalAtSeconds: number | null;
    createdAtSeconds: number | null;
    willEndAtSeconds: number | null;
    status: ExamStatus;
  }>;
};

export type ListBestExamsByAssessmentIdForExamTransitionVisualizerQueryVariables = Exact<{
  assessmentId: Scalars["String"]["input"];
  withCount: Scalars["Boolean"]["input"];
  size: Scalars["Int"]["input"];
  offset: Scalars["Int"]["input"];
  isDescending: Scalars["Boolean"]["input"];
}>;

export type ListBestExamsByAssessmentIdForExamTransitionVisualizerQuery = {
  __typename?: "Query";
  listBestExamsByAssessmentId: {
    __typename?: "ListBestExamsByAssessmentId";
    count: number;
    hasNext: boolean;
    exams: Array<{
      __typename: "Exam";
      id: string;
      examId: string;
      assessmentId: string;
      originId: string;
      numInterval: number;
      numTry: number;
      relativeScore: number;
      totalScore: number;
      startIntervalAtSeconds: number | null;
      endIntervalAtSeconds: number | null;
      createdAtSeconds: number | null;
      willEndAtSeconds: number | null;
      status: ExamStatus;
    }>;
  };
};

export type GetCompanyForGeneralSettingsCompanyFragment = { __typename?: "Company"; companyId: number; name: string; websiteUrl: string };

export type GetCompanyForGeneralSettingsQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
}>;

export type GetCompanyForGeneralSettingsQuery = {
  __typename?: "Query";
  company: { __typename?: "Company"; companyId: number; name: string; websiteUrl: string };
};

export type UpdateCompanyForGeneralSettingsCompanyFragment = { __typename?: "Company"; companyId: number; name: string; websiteUrl: string };

export type UpdateCompanyForGeneralSettingsMutationVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  logo: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  websiteUrl: Scalars["String"]["input"];
}>;

export type UpdateCompanyForGeneralSettingsMutation = {
  __typename?: "Mutation";
  updateCompany: { __typename?: "Company"; companyId: number; name: string; websiteUrl: string } | null;
};

export type GetCompanyForGroupSettingsGroupFragment = {
  __typename?: "EmployeeGroup";
  id: string;
  groupName: string;
  createdAtSeconds: number | null;
  companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string }>;
};

export type GetCompanyForGroupSettingsEmployeeFragment = {
  __typename?: "User";
  id: string;
  displayName: string;
  fullName: string;
  email: string;
  photoUrl: string;
};

export type GetCompanyForGroupSettingsCompanyFragment = {
  __typename?: "Company";
  companyId: number;
  name: string;
  employees: Array<{ __typename?: "User"; id: string; displayName: string; fullName: string; email: string; photoUrl: string }>;
  groups: Array<{
    __typename?: "EmployeeGroup";
    id: string;
    groupName: string;
    createdAtSeconds: number | null;
    companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string }>;
  }>;
};

export type GetCompanyForGroupSettingsQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
}>;

export type GetCompanyForGroupSettingsQuery = {
  __typename?: "Query";
  company: {
    __typename?: "Company";
    companyId: number;
    name: string;
    employees: Array<{ __typename?: "User"; id: string; displayName: string; fullName: string; email: string; photoUrl: string }>;
    groups: Array<{
      __typename?: "EmployeeGroup";
      id: string;
      groupName: string;
      createdAtSeconds: number | null;
      companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string }>;
    }>;
  };
};

export type CreateGroupMutationVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  groupName: Scalars["String"]["input"];
  employeeIds: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type CreateGroupMutation = { __typename?: "Mutation"; createGroup: boolean };

export type DeleteGroupMutationVariables = Exact<{
  groupId: Scalars["String"]["input"];
}>;

export type DeleteGroupMutation = { __typename?: "Mutation"; deleteGroup: boolean };

export type DeleteGroupsMutationVariables = Exact<{
  groupIds: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type DeleteGroupsMutation = { __typename?: "Mutation"; deleteGroups: boolean };

export type EditGroupMutationVariables = Exact<{
  groupId: Scalars["String"]["input"];
  groupName: Scalars["String"]["input"];
}>;

export type EditGroupMutation = { __typename?: "Mutation"; editGroup: boolean };

export type AddMembersToGroupMutationVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  groupId: Scalars["String"]["input"];
  employeeIds: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type AddMembersToGroupMutation = { __typename?: "Mutation"; addMembersToGroup: boolean };

export type AddEmployeeToGroupsMutationVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  employeeId: Scalars["String"]["input"];
  groupIds: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type AddEmployeeToGroupsMutation = {
  __typename?: "Mutation";
  addEmployeeToGroups: { __typename?: "CompanyEmployee"; employeeId: string } | null;
};

export type RemoveMembersMutationVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  groupId: Scalars["String"]["input"];
  employeeIds: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type RemoveMembersMutation = { __typename?: "Mutation"; removeMembers: boolean };

export type RegisterSonarMutationVariables = Exact<{
  input: RegisterSonarInput;
}>;

export type RegisterSonarMutation = {
  __typename?: "Mutation";
  registerSonar: {
    __typename?: "SonarIntegration";
    id: string;
    companyId: number;
    createdAtSeconds: number | null;
    screeningId: string;
    screening: { __typename?: "Screening"; id: string; screeningId: string; name: string; description: string } | null;
  } | null;
};

export type DeleteSonarMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteSonarMutation = { __typename?: "Mutation"; deleteSonar: boolean };

export type ScreeningForIntegrationSettingsFragment = {
  __typename?: "Screening";
  id: string;
  screeningId: string;
  name: string;
  description: string;
  candidateAccessPolicy: ScreeningCandidateAccessPolicy;
  isActive: boolean;
};

export type ListSonarIntegrationsSonarFragment = {
  __typename?: "SonarIntegration";
  id: string;
  companyId: number;
  createdAtSeconds: number | null;
  screeningId: string;
  screening: {
    __typename?: "Screening";
    id: string;
    screeningId: string;
    name: string;
    description: string;
    candidateAccessPolicy: ScreeningCandidateAccessPolicy;
    isActive: boolean;
  } | null;
};

export type SonarIntegrationsResponseFragment = {
  __typename?: "SonarIntegrationsResponse";
  count: number;
  sonarIntegrations: Array<{
    __typename?: "SonarIntegration";
    id: string;
    companyId: number;
    createdAtSeconds: number | null;
    screeningId: string;
    screening: {
      __typename?: "Screening";
      id: string;
      screeningId: string;
      name: string;
      description: string;
      candidateAccessPolicy: ScreeningCandidateAccessPolicy;
      isActive: boolean;
    } | null;
  }>;
  pager: { __typename?: "AtsPager"; cursor: number; hasNext: boolean } | null;
};

export type ListSonarIntegrationsQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  cursorSeconds: InputMaybe<Scalars["Int"]["input"]>;
  size: Scalars["Int"]["input"];
  withCount: Scalars["Boolean"]["input"];
}>;

export type ListSonarIntegrationsQuery = {
  __typename?: "Query";
  sonarIntegrations: {
    __typename?: "SonarIntegrationsResponse";
    count: number;
    sonarIntegrations: Array<{
      __typename?: "SonarIntegration";
      id: string;
      companyId: number;
      createdAtSeconds: number | null;
      screeningId: string;
      screening: {
        __typename?: "Screening";
        id: string;
        screeningId: string;
        name: string;
        description: string;
        candidateAccessPolicy: ScreeningCandidateAccessPolicy;
        isActive: boolean;
      } | null;
    }>;
    pager: { __typename?: "AtsPager"; cursor: number; hasNext: boolean } | null;
  };
};

export type ScreeningsByCompanyIdResponseForIntegrationSettingsFragment = {
  __typename?: "ScreeningsByCompanyIdResponse";
  count: number;
  hasNext: boolean;
  screenings: Array<{
    __typename?: "Screening";
    id: string;
    screeningId: string;
    name: string;
    description: string;
    candidateAccessPolicy: ScreeningCandidateAccessPolicy;
    isActive: boolean;
  }>;
};

export type ListScreeningsForIntegrationSettingsQueryVariables = Exact<{
  input: ScreeningsByCompanyIdInput;
}>;

export type ListScreeningsForIntegrationSettingsQuery = {
  __typename?: "Query";
  screeningsByCompanyId: {
    __typename?: "ScreeningsByCompanyIdResponse";
    count: number;
    hasNext: boolean;
    screenings: Array<{
      __typename?: "Screening";
      id: string;
      screeningId: string;
      name: string;
      description: string;
      candidateAccessPolicy: ScreeningCandidateAccessPolicy;
      isActive: boolean;
    }>;
  } | null;
};

export type InterviewFeedbackRecommendationFragment = {
  __typename?: "EvaluationRecommendation";
  id: string;
  employeeId: string;
  evaluationRecommendationId: number;
  isRecommended: boolean;
  comment: string;
  createdAtSeconds: number | null;
  evaluations: Array<{
    __typename?: "Evaluation";
    id: string;
    evaluationId: number;
    employeeId: string;
    metricId: number;
    comment: string;
    numStars: number;
    metric: { __typename?: "EvaluationMetric"; id: string; title: string; metricId: number; description: string } | null;
  }>;
  reviewer: { __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string };
};

export type InterviewFeedbackFragment = {
  __typename?: "Spot";
  id: string;
  recommendations: Array<{
    __typename?: "EvaluationRecommendation";
    id: string;
    employeeId: string;
    evaluationRecommendationId: number;
    isRecommended: boolean;
    comment: string;
    createdAtSeconds: number | null;
    evaluations: Array<{
      __typename?: "Evaluation";
      id: string;
      evaluationId: number;
      employeeId: string;
      metricId: number;
      comment: string;
      numStars: number;
      metric: { __typename?: "EvaluationMetric"; id: string; title: string; metricId: number; description: string } | null;
    }>;
    reviewer: { __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string };
  }>;
};

export type GetInterviewFeedbackQueryVariables = Exact<{
  id: Scalars["String"]["input"];
  withAnswers: Scalars["Boolean"]["input"];
}>;

export type GetInterviewFeedbackQuery = {
  __typename?: "Query";
  spot: {
    __typename?: "Spot";
    id: string;
    recommendations: Array<{
      __typename?: "EvaluationRecommendation";
      id: string;
      employeeId: string;
      evaluationRecommendationId: number;
      isRecommended: boolean;
      comment: string;
      createdAtSeconds: number | null;
      evaluations: Array<{
        __typename?: "Evaluation";
        id: string;
        evaluationId: number;
        employeeId: string;
        metricId: number;
        comment: string;
        numStars: number;
        metric: { __typename?: "EvaluationMetric"; id: string; title: string; metricId: number; description: string } | null;
      }>;
      reviewer: { __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string };
    }>;
  };
};

export type InterviewOverviewChallengeFragment = {
  __typename?: "ChallengeEntity";
  id: string;
  challengeEntityId: number;
  enabledLanguages: Array<string>;
  challengeStatus: ChallengeStatus;
  challengeQuestion: {
    __typename?: "AlgorithmQuestion";
    id: string;
    questionId: number;
    version: string;
    titleJa: string | null;
    titleEn: string | null;
    descriptionEn: string | null;
    descriptionJa: string | null;
    difficulty: Difficulty;
    algorithmQuestionStatus: QuestionStatus;
  } | null;
};

export type InterviewOverviewQuizFragment = {
  __typename?: "QuizEntity";
  id: string;
  quizEntityId: number;
  quizStatus: QuizStatus;
  pb_package: {
    __typename?: "MultiChoicePackage";
    id: string;
    packageId: number;
    version: string;
    titleEn: string | null;
    titleJa: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    difficulty: Difficulty;
    multiChoicePackageStatus: QuestionStatus;
  } | null;
};

export type InterviewOverviewProjectFragment = {
  __typename?: "ProjectEntity";
  id: string;
  projectEntityId: number;
  projectStatus: ProjectStatus;
  question: {
    __typename?: "ProjectQuestion";
    id: string;
    questionId: number;
    version: string;
    titleEn: string | null;
    titleJa: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    difficulty: Difficulty;
    projectQuestionStatus: ProjectQuestionStatus;
  } | null;
};

export type InterviewOverviewSystemDesignFragment = {
  __typename?: "SystemDesignEntity";
  id: string;
  systemDesignEntityId: number;
  componentTypes: Array<SystemDesignComponentType>;
  systemDesignStatus: SystemDesignStatus;
  question: {
    __typename?: "SystemDesignQuestion";
    id: string;
    questionId: number;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    difficulty: Difficulty;
    systemDesignQuestionStatus: SystemDesignQuestionStatus;
  } | null;
};

export type InterviewOverviewFragment = {
  __typename?: "Spot";
  id: string;
  originId: string;
  parentId: string;
  candidateName: string;
  candidateEmail: string;
  ownerEmailAddress: string | null;
  messageForCandidate: string | null;
  evaluationComment: string;
  isPassed: boolean;
  createdAtSeconds: number | null;
  declinedAtSeconds: number | null;
  deletedAtSeconds: number | null;
  didEndAtSeconds: number | null;
  didStartAtSeconds: number | null;
  evaluatedAtSeconds: number | null;
  finalizedAtSeconds: number | null;
  isPublic: boolean | null;
  memo: string | null;
  name: string;
  status: SpotStatus;
  timeLimitSeconds: number;
  willExpireAtSeconds: number | null;
  invitationEmail: string;
  invitationLanguage: Language | null;
  invitationMethod: InvitationMethod;
  allowWebSearch: boolean;
  allowChatGPT: boolean;
  allowHint: boolean;
  filledVariables: Array<{
    __typename?: "SpotFilledVariable";
    filledVariableId: number;
    label: string;
    description: string;
    valueType: ScreeningValueType;
    value: { __typename?: "ScreeningGenericValue"; stringifiedValue: string } | null;
  }>;
  entities: Array<
    | {
        __typename?: "ChallengeEntity";
        id: string;
        challengeEntityId: number;
        enabledLanguages: Array<string>;
        challengeStatus: ChallengeStatus;
        challengeQuestion: {
          __typename?: "AlgorithmQuestion";
          id: string;
          questionId: number;
          version: string;
          titleJa: string | null;
          titleEn: string | null;
          descriptionEn: string | null;
          descriptionJa: string | null;
          difficulty: Difficulty;
          algorithmQuestionStatus: QuestionStatus;
        } | null;
      }
    | {
        __typename?: "ProjectEntity";
        id: string;
        projectEntityId: number;
        projectStatus: ProjectStatus;
        question: {
          __typename?: "ProjectQuestion";
          id: string;
          questionId: number;
          version: string;
          titleEn: string | null;
          titleJa: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          difficulty: Difficulty;
          projectQuestionStatus: ProjectQuestionStatus;
        } | null;
      }
    | {
        __typename?: "QuizEntity";
        id: string;
        quizEntityId: number;
        quizStatus: QuizStatus;
        pb_package: {
          __typename?: "MultiChoicePackage";
          id: string;
          packageId: number;
          version: string;
          titleEn: string | null;
          titleJa: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          difficulty: Difficulty;
          multiChoicePackageStatus: QuestionStatus;
        } | null;
      }
    | {
        __typename?: "SystemDesignEntity";
        id: string;
        systemDesignEntityId: number;
        componentTypes: Array<SystemDesignComponentType>;
        systemDesignStatus: SystemDesignStatus;
        question: {
          __typename?: "SystemDesignQuestion";
          id: string;
          questionId: number;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          difficulty: Difficulty;
          systemDesignQuestionStatus: SystemDesignQuestionStatus;
        } | null;
      }
  >;
  issuedEntities: Array<
    | {
        __typename?: "SpotIssuedChallengeEntity";
        questionWeight: number;
        entity: {
          __typename?: "ChallengeEntity";
          id: string;
          challengeEntityId: number;
          enabledLanguages: Array<string>;
          challengeStatus: ChallengeStatus;
          challengeQuestion: {
            __typename?: "AlgorithmQuestion";
            id: string;
            questionId: number;
            version: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionEn: string | null;
            descriptionJa: string | null;
            difficulty: Difficulty;
            algorithmQuestionStatus: QuestionStatus;
          } | null;
        };
      }
    | {
        __typename?: "SpotIssuedProjectEntity";
        questionWeight: number;
        entity: {
          __typename?: "ProjectEntity";
          id: string;
          projectEntityId: number;
          projectStatus: ProjectStatus;
          question: {
            __typename?: "ProjectQuestion";
            id: string;
            questionId: number;
            version: string;
            titleEn: string | null;
            titleJa: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            projectQuestionStatus: ProjectQuestionStatus;
          } | null;
        };
      }
    | {
        __typename?: "SpotIssuedQuizEntity";
        questionWeight: number;
        entity: {
          __typename?: "QuizEntity";
          id: string;
          quizEntityId: number;
          quizStatus: QuizStatus;
          pb_package: {
            __typename?: "MultiChoicePackage";
            id: string;
            packageId: number;
            version: string;
            titleEn: string | null;
            titleJa: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            multiChoicePackageStatus: QuestionStatus;
          } | null;
        };
      }
    | {
        __typename?: "SpotIssuedSystemDesignEntity";
        questionWeight: number;
        entity: {
          __typename?: "SystemDesignEntity";
          id: string;
          systemDesignEntityId: number;
          componentTypes: Array<SystemDesignComponentType>;
          systemDesignStatus: SystemDesignStatus;
          question: {
            __typename?: "SystemDesignQuestion";
            id: string;
            questionId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            systemDesignQuestionStatus: SystemDesignQuestionStatus;
          } | null;
        };
      }
  >;
  reviewers: Array<{
    __typename: "SpotReviewer";
    type: ReviewerType;
    employee: {
      __typename?: "User";
      id: string;
      uid: string;
      displayName: string;
      email: string;
      photoUrl: string;
      isSystemAdmin: boolean;
    } | null;
    group: {
      __typename?: "EmployeeGroup";
      id: string;
      groupName: string;
      companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string; role: Role }>;
    } | null;
  }>;
  viewers: Array<
    { __typename: "EmployeeGroup"; employeeGroupId: string; groupName: string } | { __typename: "User"; uid: string; displayName: string }
  >;
  tags: Array<{ __typename: "SpotTag"; name: string }>;
  employee: {
    __typename?: "User";
    id: string;
    uid: string;
    displayName: string;
    email: string;
    photoUrl: string;
    isSystemAdmin: boolean;
  } | null;
  evaluatedBy: { __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string } | null;
};

export type GetInterviewOverviewQueryVariables = Exact<{
  id: Scalars["String"]["input"];
  withAnswers: Scalars["Boolean"]["input"];
}>;

export type GetInterviewOverviewQuery = {
  __typename?: "Query";
  spot: {
    __typename?: "Spot";
    id: string;
    originId: string;
    parentId: string;
    candidateName: string;
    candidateEmail: string;
    ownerEmailAddress: string | null;
    messageForCandidate: string | null;
    evaluationComment: string;
    isPassed: boolean;
    createdAtSeconds: number | null;
    declinedAtSeconds: number | null;
    deletedAtSeconds: number | null;
    didEndAtSeconds: number | null;
    didStartAtSeconds: number | null;
    evaluatedAtSeconds: number | null;
    finalizedAtSeconds: number | null;
    isPublic: boolean | null;
    memo: string | null;
    name: string;
    status: SpotStatus;
    timeLimitSeconds: number;
    willExpireAtSeconds: number | null;
    invitationEmail: string;
    invitationLanguage: Language | null;
    invitationMethod: InvitationMethod;
    allowWebSearch: boolean;
    allowChatGPT: boolean;
    allowHint: boolean;
    filledVariables: Array<{
      __typename?: "SpotFilledVariable";
      filledVariableId: number;
      label: string;
      description: string;
      valueType: ScreeningValueType;
      value: { __typename?: "ScreeningGenericValue"; stringifiedValue: string } | null;
    }>;
    entities: Array<
      | {
          __typename?: "ChallengeEntity";
          id: string;
          challengeEntityId: number;
          enabledLanguages: Array<string>;
          challengeStatus: ChallengeStatus;
          challengeQuestion: {
            __typename?: "AlgorithmQuestion";
            id: string;
            questionId: number;
            version: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionEn: string | null;
            descriptionJa: string | null;
            difficulty: Difficulty;
            algorithmQuestionStatus: QuestionStatus;
          } | null;
        }
      | {
          __typename?: "ProjectEntity";
          id: string;
          projectEntityId: number;
          projectStatus: ProjectStatus;
          question: {
            __typename?: "ProjectQuestion";
            id: string;
            questionId: number;
            version: string;
            titleEn: string | null;
            titleJa: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            projectQuestionStatus: ProjectQuestionStatus;
          } | null;
        }
      | {
          __typename?: "QuizEntity";
          id: string;
          quizEntityId: number;
          quizStatus: QuizStatus;
          pb_package: {
            __typename?: "MultiChoicePackage";
            id: string;
            packageId: number;
            version: string;
            titleEn: string | null;
            titleJa: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            multiChoicePackageStatus: QuestionStatus;
          } | null;
        }
      | {
          __typename?: "SystemDesignEntity";
          id: string;
          systemDesignEntityId: number;
          componentTypes: Array<SystemDesignComponentType>;
          systemDesignStatus: SystemDesignStatus;
          question: {
            __typename?: "SystemDesignQuestion";
            id: string;
            questionId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            systemDesignQuestionStatus: SystemDesignQuestionStatus;
          } | null;
        }
    >;
    issuedEntities: Array<
      | {
          __typename?: "SpotIssuedChallengeEntity";
          questionWeight: number;
          entity: {
            __typename?: "ChallengeEntity";
            id: string;
            challengeEntityId: number;
            enabledLanguages: Array<string>;
            challengeStatus: ChallengeStatus;
            challengeQuestion: {
              __typename?: "AlgorithmQuestion";
              id: string;
              questionId: number;
              version: string;
              titleJa: string | null;
              titleEn: string | null;
              descriptionEn: string | null;
              descriptionJa: string | null;
              difficulty: Difficulty;
              algorithmQuestionStatus: QuestionStatus;
            } | null;
          };
        }
      | {
          __typename?: "SpotIssuedProjectEntity";
          questionWeight: number;
          entity: {
            __typename?: "ProjectEntity";
            id: string;
            projectEntityId: number;
            projectStatus: ProjectStatus;
            question: {
              __typename?: "ProjectQuestion";
              id: string;
              questionId: number;
              version: string;
              titleEn: string | null;
              titleJa: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
              difficulty: Difficulty;
              projectQuestionStatus: ProjectQuestionStatus;
            } | null;
          };
        }
      | {
          __typename?: "SpotIssuedQuizEntity";
          questionWeight: number;
          entity: {
            __typename?: "QuizEntity";
            id: string;
            quizEntityId: number;
            quizStatus: QuizStatus;
            pb_package: {
              __typename?: "MultiChoicePackage";
              id: string;
              packageId: number;
              version: string;
              titleEn: string | null;
              titleJa: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
              difficulty: Difficulty;
              multiChoicePackageStatus: QuestionStatus;
            } | null;
          };
        }
      | {
          __typename?: "SpotIssuedSystemDesignEntity";
          questionWeight: number;
          entity: {
            __typename?: "SystemDesignEntity";
            id: string;
            systemDesignEntityId: number;
            componentTypes: Array<SystemDesignComponentType>;
            systemDesignStatus: SystemDesignStatus;
            question: {
              __typename?: "SystemDesignQuestion";
              id: string;
              questionId: number;
              titleJa: string | null;
              titleEn: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
              difficulty: Difficulty;
              systemDesignQuestionStatus: SystemDesignQuestionStatus;
            } | null;
          };
        }
    >;
    reviewers: Array<{
      __typename: "SpotReviewer";
      type: ReviewerType;
      employee: {
        __typename?: "User";
        id: string;
        uid: string;
        displayName: string;
        email: string;
        photoUrl: string;
        isSystemAdmin: boolean;
      } | null;
      group: {
        __typename?: "EmployeeGroup";
        id: string;
        groupName: string;
        companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string; role: Role }>;
      } | null;
    }>;
    viewers: Array<
      { __typename: "EmployeeGroup"; employeeGroupId: string; groupName: string } | { __typename: "User"; uid: string; displayName: string }
    >;
    tags: Array<{ __typename: "SpotTag"; name: string }>;
    employee: {
      __typename?: "User";
      id: string;
      uid: string;
      displayName: string;
      email: string;
      photoUrl: string;
      isSystemAdmin: boolean;
    } | null;
    evaluatedBy: { __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string } | null;
  };
};

export type InterviewReportFragment = {
  __typename?: "Spot";
  id: string;
  status: SpotStatus;
  entityCount: number;
  totalScore: number;
  customScore: number;
  relativeScore: number;
  rank: number;
  numSubset: number;
  rankEvaluation: RankEvaluation;
  isReliableRank: boolean;
  didStartAtSeconds: number | null;
  finalizedAtSeconds: number | null;
  evaluatedAtSeconds: number | null;
  allowHint: boolean;
  suspiciousDegree: SpotSuspiciousDegree;
  entities: Array<
    | {
        __typename?: "ChallengeEntity";
        id: string;
        entityId: number;
        challengeEntityId: number;
        totalScore: number;
        timeLimitSeconds: number | null;
        appealMessage: string | null;
        suspiciousDegree: ChallengeSuspiciousDegree;
        challengeStatus: ChallengeStatus;
        submissions: Array<{
          __typename?: "ChallengeSubmission";
          id: string;
          challengeSubmissionId: number;
          questionId: number;
          questionVersion: string;
          isBest: boolean;
          runtime: string;
          runtimeVersion: string;
          correctnessTestResult: string;
          totalScore: number;
          numSubset: number;
          totalElapsedTimeSeconds: number | null;
          readabilityTestResult: string;
          evaluatedAtSeconds: number | null;
          coverage: number;
          performance: number;
          readability: number;
          runtimeComplexity: string;
          spaceComplexity: string;
          avgPerformance: number;
          avgMaxMemory: number;
          machineOs: string;
          machineMemory: string;
          machineCpu: number | null;
          numPassed: number;
          numTests: number;
          numTabEvents: number;
          numPasteEvents: number;
          numAccessEvents: number;
          similarityScore: number | null;
          code: string;
          elapsedTimeSeconds: number | null;
          performanceTestResult: string;
          challengeUsedHintCount: number;
          challengeSubmissionStatus: ChallengeSubmissionStatus;
          similarCodes: Array<{
            __typename?: "ChallengeSubmissionSimilarCode";
            code: string;
            score: number;
            metadata:
              | { __typename: "SimilarCodeCrawlerMetadata"; url: string }
              | { __typename: "SimilarCodeNotFoundMetadata"; reserved: boolean | null }
              | { __typename: "SimilarCodeOtherCompanyMetadata"; reserved: boolean | null }
              | { __typename: "SimilarCodeSpotMetadata"; spotId: string; challengeId: number; questionId: number; questionVersion: string };
            fragments: Array<{
              __typename?: "ChallengeSubmissionSimilarCodeFragment";
              left: {
                __typename?: "ChallengeSubmissionSimilarCodeSelection";
                endCol: number;
                endRow: number;
                startCol: number;
                startRow: number;
              };
              right: {
                __typename?: "ChallengeSubmissionSimilarCodeSelection";
                endCol: number;
                endRow: number;
                startCol: number;
                startRow: number;
              };
            }>;
          }>;
          snapshots: Array<{ __typename?: "ChallengeSnapshot"; id: string; challengeSnapshotId: number }>;
        }>;
        challengeQuestion: {
          __typename?: "AlgorithmQuestion";
          id: string;
          questionId: number;
          version: string;
          variant: AlgorithmQuestionVariant;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          descriptionEn: string | null;
          descriptionJa: string | null;
          correctnessTestCase: string;
          isSupportedLeakScore: boolean;
          numUses: number;
          supportedLanguages: Array<string>;
          timeLimitSeconds: number | null;
          averageElapsedTimeSeconds: number;
          accuracyRate: number;
          isOfficial: boolean;
          isPrivate: boolean;
          performanceTimeLimitMilliSeconds: number;
          algorithmQuestionStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          employee: { __typename?: "User"; id: string; displayName: string };
          initialCode: {
            __typename?: "AlgorithmInitialCode";
            c: string;
            cpp: string;
            csharp: string;
            dart: string;
            go: string;
            java: string;
            javascript: string;
            kotlin: string;
            mysql: string;
            perl: string;
            pgsql: string;
            php: string;
            python3: string;
            ruby: string;
            rust: string;
            scala: string;
            sqlite: string;
            swift: string;
            typescript: string;
          } | null;
          answers: Array<{
            __typename?: "AlgorithmAnswer";
            id: number;
            titleEn: string | null;
            titleJa: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            label: string;
            runtimeComplexity: string;
            spaceComplexity: string;
          }>;
          hints: Array<{
            __typename?: "AlgorithmHint";
            id: number;
            descriptionJa: string | null;
            descriptionEn: string | null;
            orderNum: number;
          }>;
        } | null;
        sessions: Array<
          | { __typename?: "ChallengeChatGPTSession"; sessionId: string; challengeId: number; language: string }
          | { __typename?: "ChallengeWebSession"; sessionId: string; challengeId: number; language: string }
        >;
        suspiciousInference: {
          __typename?: "SuspiciousInference";
          inferenceId: string;
          feedback: { __typename?: "SuspiciousInferenceFeedback"; isCorrect: boolean } | null;
        } | null;
      }
    | {
        __typename?: "ProjectEntity";
        id: string;
        entityId: number;
        projectEntityId: number;
        totalScore: number;
        evaluatedAtSeconds: number | null;
        agentVersion: string;
        appealMessage: string | null;
        projectStatus: ProjectStatus;
        question: {
          __typename?: "ProjectQuestion";
          id: string;
          questionId: number;
          version: string;
          projectVersion: string;
          variant: ProjectQuestionVariant;
          titleEn: string | null;
          titleJa: string | null;
          difficulty: Difficulty;
          descriptionJa: string | null;
          descriptionEn: string | null;
          correctnessTestCase: string;
          performanceTestCase: string;
          timeLimitSeconds: number | null;
          isPrivate: boolean;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          isOfficial: boolean;
          projectQuestionStatus: ProjectQuestionStatus;
          answers: Array<{
            __typename?: "ProjectAnswer";
            titleJa: string;
            titleEn: string;
            descriptionJa: string;
            descriptionEn: string;
            answerId: number;
          }>;
        } | null;
        submissions: Array<{
          __typename?: "ProjectSubmission";
          projectSubmissionId: number;
          numTests: number;
          numPassed: number;
          numTabEvents: number;
          numAccessEvents: number;
          totalScore: number;
          numSubset: number;
          isBest: boolean;
          testResult: string;
          avgLatency: number;
          avgSla: number;
          coverage: number;
          performance: number;
          correctnessTestResult: string;
          performanceTestResult: string;
          metrics: string;
          failureReason: ProjectFailureReason;
          totalElapsedTimeSeconds: number | null;
          elapsedTimeSeconds: number | null;
          evaluatedAtSeconds: number | null;
          submissionStatus: ProjectSubmissionStatus;
          snapshots: Array<{ __typename?: "ProjectSnapshot"; testCase: string; testResult: string; isPassed: boolean }>;
          diffs: Array<{
            __typename?: "ProjectDiff";
            projectDiffId: number;
            operation: ProjectOperation;
            fromPath: string;
            toPath: string;
            fromLink: string;
            toLink: string;
          }>;
        }>;
      }
    | {
        __typename?: "QuizEntity";
        id: string;
        entityId: number;
        quizEntityId: number;
        totalScore: number;
        numSubset: number;
        timeLimitSeconds: number | null;
        evaluatedAtSeconds: number | null;
        elapsedTimeSeconds: number | null;
        quizStatus: QuizStatus;
        pb_package: {
          __typename?: "MultiChoicePackage";
          packageId: number;
          version: string;
          difficulty: Difficulty;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          numUses: number;
          timeLimitSeconds: number | null;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          isOfficial: boolean;
          isPrivate: boolean;
          multiChoicePackageStatus: QuestionStatus;
          employee: { __typename?: "User"; id: string; displayName: string };
          questions: Array<{
            __typename?: "MultiChoiceQuestion";
            id: number;
            version: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            correctAnswerRate: number;
            variant: MultiChoiceQuestionVariant;
            options: Array<{
              __typename?: "MultiChoiceOption";
              id: number;
              titleJa: string | null;
              titleEn: string | null;
              isCorrect: boolean;
              additionalDetailJa: string | null;
              additionalDetailEn: string | null;
            }>;
            evaluationItems: Array<{
              __typename?: "MultiChoiceEvaluationItem";
              id: number;
              questionId: number;
              questionVersion: string;
              titleJa: string | null;
              titleEn: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
            }>;
          }>;
        } | null;
        submissions: Array<{
          __typename?: "QuizSubmission";
          id: string;
          quizSubmissionId: number;
          quizId: number;
          packageId: number;
          packageVersion: string;
          questionId: number;
          questionVersion: string;
          optionIds: Array<number>;
          isCorrect: boolean;
          answerText: string;
          score: number;
          evaluatedBy: string;
          isAutomaticEvaluation: boolean;
          evaluatedAtSeconds: number | null;
          numTabEvents: number;
          numAccessEvents: number;
          elapsedTimeSeconds: number | null;
          createdAtSeconds: number | null;
          quizSubmissionStatus: QuizSubmissionStatus;
          evaluations: Array<{
            __typename?: "QuizEvaluation";
            submissionId: number;
            score: number;
            isPassed: boolean;
            reasonJa: string;
            reasonEn: string;
            evaluationItem: {
              __typename?: "MultiChoiceEvaluationItem";
              id: number;
              questionId: number;
              questionVersion: string;
              titleJa: string | null;
              titleEn: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
            } | null;
          }>;
          evaluatedUser: { __typename?: "User"; uid: string; displayName: string } | null;
        }>;
      }
    | {
        __typename?: "SystemDesignEntity";
        id: string;
        entityId: number;
        systemDesignEntityId: number;
        totalScore: number;
        evaluatedAtSeconds: number | null;
        appealMessage: string | null;
        systemDesignStatus: SystemDesignStatus;
        submissions: Array<{
          __typename?: "SystemDesignSubmission";
          systemDesignSubmissionId: number;
          isBest: boolean;
          status: SystemDesignSubmissionStatus;
          snapshot: string;
          numTabEvents: number;
          numAccessEvents: number;
          totalScore: number;
          numSubset: number;
          totalElapsedTimeSeconds: number | null;
          elapsedTimeSeconds: number | null;
          evaluatedAtSeconds: number | null;
          evaluations: Array<{
            __typename?: "SystemDesignEvaluation";
            totalScore: number;
            evaluatedBy: string;
            availability: number;
            scalability: number;
            consistency: number;
            result: string;
          }>;
          usedHints: Array<{ __typename?: "SystemDesignHint"; systemDesignHintId: number }>;
          snapshots: Array<{
            __typename?: "SystemDesignSnapshot";
            systemDesignSnapshotId: number;
            scoringItemId: number;
            isPassed: boolean;
            matchedIds: Array<string>;
          }>;
        }>;
        question: {
          __typename?: "SystemDesignQuestion";
          questionId: number;
          titleEn: string | null;
          titleJa: string | null;
          difficulty: Difficulty;
          descriptionJa: string | null;
          descriptionEn: string | null;
          initialSnapshot: string;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          timeLimitSeconds: number | null;
          isOfficial: boolean;
          systemDesignQuestionStatus: SystemDesignQuestionStatus;
          hints: Array<{ __typename?: "SystemDesignHint"; descriptionJa: string | null; descriptionEn: string | null }>;
          answers: Array<{
            __typename?: "SystemDesignAnswer";
            id: string;
            titleJa: string;
            titleEn: string;
            descriptionJa: string;
            descriptionEn: string;
          }>;
          scoringItems: Array<{
            __typename?: "SystemDesignScoringItem";
            systemDesignScoringItemId: number;
            category: SystemDesignScoringItemCategory;
            titleJa: string;
            titleEn: string;
            descriptionJa: string;
            descriptionEn: string;
            graphlets: string;
            isHidden: boolean;
          }>;
        } | null;
      }
  >;
  issuedEntities: Array<
    | {
        __typename?: "SpotIssuedChallengeEntity";
        questionWeight: number;
        entity: {
          __typename?: "ChallengeEntity";
          id: string;
          challengeEntityId: number;
          enabledLanguages: Array<string>;
          challengeStatus: ChallengeStatus;
          challengeQuestion: {
            __typename?: "AlgorithmQuestion";
            id: string;
            questionId: number;
            version: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionEn: string | null;
            descriptionJa: string | null;
            difficulty: Difficulty;
            algorithmQuestionStatus: QuestionStatus;
          } | null;
        };
      }
    | {
        __typename?: "SpotIssuedProjectEntity";
        questionWeight: number;
        entity: {
          __typename?: "ProjectEntity";
          id: string;
          projectEntityId: number;
          projectStatus: ProjectStatus;
          question: {
            __typename?: "ProjectQuestion";
            id: string;
            questionId: number;
            version: string;
            titleEn: string | null;
            titleJa: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            projectQuestionStatus: ProjectQuestionStatus;
          } | null;
        };
      }
    | {
        __typename?: "SpotIssuedQuizEntity";
        questionWeight: number;
        entity: {
          __typename?: "QuizEntity";
          id: string;
          quizEntityId: number;
          quizStatus: QuizStatus;
          pb_package: {
            __typename?: "MultiChoicePackage";
            id: string;
            packageId: number;
            version: string;
            titleEn: string | null;
            titleJa: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            multiChoicePackageStatus: QuestionStatus;
          } | null;
        };
      }
    | {
        __typename?: "SpotIssuedSystemDesignEntity";
        questionWeight: number;
        entity: {
          __typename?: "SystemDesignEntity";
          id: string;
          systemDesignEntityId: number;
          componentTypes: Array<SystemDesignComponentType>;
          systemDesignStatus: SystemDesignStatus;
          question: {
            __typename?: "SystemDesignQuestion";
            id: string;
            questionId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            systemDesignQuestionStatus: SystemDesignQuestionStatus;
          } | null;
        };
      }
  >;
};

export type GetInterviewReportQueryVariables = Exact<{
  id: Scalars["String"]["input"];
  withAnswers: Scalars["Boolean"]["input"];
}>;

export type GetInterviewReportQuery = {
  __typename?: "Query";
  spot: {
    __typename?: "Spot";
    id: string;
    status: SpotStatus;
    entityCount: number;
    totalScore: number;
    customScore: number;
    relativeScore: number;
    rank: number;
    numSubset: number;
    rankEvaluation: RankEvaluation;
    isReliableRank: boolean;
    didStartAtSeconds: number | null;
    finalizedAtSeconds: number | null;
    evaluatedAtSeconds: number | null;
    allowHint: boolean;
    suspiciousDegree: SpotSuspiciousDegree;
    entities: Array<
      | {
          __typename?: "ChallengeEntity";
          id: string;
          entityId: number;
          challengeEntityId: number;
          totalScore: number;
          timeLimitSeconds: number | null;
          appealMessage: string | null;
          suspiciousDegree: ChallengeSuspiciousDegree;
          challengeStatus: ChallengeStatus;
          submissions: Array<{
            __typename?: "ChallengeSubmission";
            id: string;
            challengeSubmissionId: number;
            questionId: number;
            questionVersion: string;
            isBest: boolean;
            runtime: string;
            runtimeVersion: string;
            correctnessTestResult: string;
            totalScore: number;
            numSubset: number;
            totalElapsedTimeSeconds: number | null;
            readabilityTestResult: string;
            evaluatedAtSeconds: number | null;
            coverage: number;
            performance: number;
            readability: number;
            runtimeComplexity: string;
            spaceComplexity: string;
            avgPerformance: number;
            avgMaxMemory: number;
            machineOs: string;
            machineMemory: string;
            machineCpu: number | null;
            numPassed: number;
            numTests: number;
            numTabEvents: number;
            numPasteEvents: number;
            numAccessEvents: number;
            similarityScore: number | null;
            code: string;
            elapsedTimeSeconds: number | null;
            performanceTestResult: string;
            challengeUsedHintCount: number;
            challengeSubmissionStatus: ChallengeSubmissionStatus;
            similarCodes: Array<{
              __typename?: "ChallengeSubmissionSimilarCode";
              code: string;
              score: number;
              metadata:
                | { __typename: "SimilarCodeCrawlerMetadata"; url: string }
                | { __typename: "SimilarCodeNotFoundMetadata"; reserved: boolean | null }
                | { __typename: "SimilarCodeOtherCompanyMetadata"; reserved: boolean | null }
                | { __typename: "SimilarCodeSpotMetadata"; spotId: string; challengeId: number; questionId: number; questionVersion: string };
              fragments: Array<{
                __typename?: "ChallengeSubmissionSimilarCodeFragment";
                left: {
                  __typename?: "ChallengeSubmissionSimilarCodeSelection";
                  endCol: number;
                  endRow: number;
                  startCol: number;
                  startRow: number;
                };
                right: {
                  __typename?: "ChallengeSubmissionSimilarCodeSelection";
                  endCol: number;
                  endRow: number;
                  startCol: number;
                  startRow: number;
                };
              }>;
            }>;
            snapshots: Array<{ __typename?: "ChallengeSnapshot"; id: string; challengeSnapshotId: number }>;
          }>;
          challengeQuestion: {
            __typename?: "AlgorithmQuestion";
            id: string;
            questionId: number;
            version: string;
            variant: AlgorithmQuestionVariant;
            titleJa: string | null;
            titleEn: string | null;
            difficulty: Difficulty;
            descriptionEn: string | null;
            descriptionJa: string | null;
            correctnessTestCase: string;
            isSupportedLeakScore: boolean;
            numUses: number;
            supportedLanguages: Array<string>;
            timeLimitSeconds: number | null;
            averageElapsedTimeSeconds: number;
            accuracyRate: number;
            isOfficial: boolean;
            isPrivate: boolean;
            performanceTimeLimitMilliSeconds: number;
            algorithmQuestionStatus: QuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
            employee: { __typename?: "User"; id: string; displayName: string };
            initialCode: {
              __typename?: "AlgorithmInitialCode";
              c: string;
              cpp: string;
              csharp: string;
              dart: string;
              go: string;
              java: string;
              javascript: string;
              kotlin: string;
              mysql: string;
              perl: string;
              pgsql: string;
              php: string;
              python3: string;
              ruby: string;
              rust: string;
              scala: string;
              sqlite: string;
              swift: string;
              typescript: string;
            } | null;
            answers: Array<{
              __typename?: "AlgorithmAnswer";
              id: number;
              titleEn: string | null;
              titleJa: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
              label: string;
              runtimeComplexity: string;
              spaceComplexity: string;
            }>;
            hints: Array<{
              __typename?: "AlgorithmHint";
              id: number;
              descriptionJa: string | null;
              descriptionEn: string | null;
              orderNum: number;
            }>;
          } | null;
          sessions: Array<
            | { __typename?: "ChallengeChatGPTSession"; sessionId: string; challengeId: number; language: string }
            | { __typename?: "ChallengeWebSession"; sessionId: string; challengeId: number; language: string }
          >;
          suspiciousInference: {
            __typename?: "SuspiciousInference";
            inferenceId: string;
            feedback: { __typename?: "SuspiciousInferenceFeedback"; isCorrect: boolean } | null;
          } | null;
        }
      | {
          __typename?: "ProjectEntity";
          id: string;
          entityId: number;
          projectEntityId: number;
          totalScore: number;
          evaluatedAtSeconds: number | null;
          agentVersion: string;
          appealMessage: string | null;
          projectStatus: ProjectStatus;
          question: {
            __typename?: "ProjectQuestion";
            id: string;
            questionId: number;
            version: string;
            projectVersion: string;
            variant: ProjectQuestionVariant;
            titleEn: string | null;
            titleJa: string | null;
            difficulty: Difficulty;
            descriptionJa: string | null;
            descriptionEn: string | null;
            correctnessTestCase: string;
            performanceTestCase: string;
            timeLimitSeconds: number | null;
            isPrivate: boolean;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            isOfficial: boolean;
            projectQuestionStatus: ProjectQuestionStatus;
            answers: Array<{
              __typename?: "ProjectAnswer";
              titleJa: string;
              titleEn: string;
              descriptionJa: string;
              descriptionEn: string;
              answerId: number;
            }>;
          } | null;
          submissions: Array<{
            __typename?: "ProjectSubmission";
            projectSubmissionId: number;
            numTests: number;
            numPassed: number;
            numTabEvents: number;
            numAccessEvents: number;
            totalScore: number;
            numSubset: number;
            isBest: boolean;
            testResult: string;
            avgLatency: number;
            avgSla: number;
            coverage: number;
            performance: number;
            correctnessTestResult: string;
            performanceTestResult: string;
            metrics: string;
            failureReason: ProjectFailureReason;
            totalElapsedTimeSeconds: number | null;
            elapsedTimeSeconds: number | null;
            evaluatedAtSeconds: number | null;
            submissionStatus: ProjectSubmissionStatus;
            snapshots: Array<{ __typename?: "ProjectSnapshot"; testCase: string; testResult: string; isPassed: boolean }>;
            diffs: Array<{
              __typename?: "ProjectDiff";
              projectDiffId: number;
              operation: ProjectOperation;
              fromPath: string;
              toPath: string;
              fromLink: string;
              toLink: string;
            }>;
          }>;
        }
      | {
          __typename?: "QuizEntity";
          id: string;
          entityId: number;
          quizEntityId: number;
          totalScore: number;
          numSubset: number;
          timeLimitSeconds: number | null;
          evaluatedAtSeconds: number | null;
          elapsedTimeSeconds: number | null;
          quizStatus: QuizStatus;
          pb_package: {
            __typename?: "MultiChoicePackage";
            packageId: number;
            version: string;
            difficulty: Difficulty;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            numUses: number;
            timeLimitSeconds: number | null;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            isOfficial: boolean;
            isPrivate: boolean;
            multiChoicePackageStatus: QuestionStatus;
            employee: { __typename?: "User"; id: string; displayName: string };
            questions: Array<{
              __typename?: "MultiChoiceQuestion";
              id: number;
              version: string;
              titleJa: string | null;
              titleEn: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
              correctAnswerRate: number;
              variant: MultiChoiceQuestionVariant;
              options: Array<{
                __typename?: "MultiChoiceOption";
                id: number;
                titleJa: string | null;
                titleEn: string | null;
                isCorrect: boolean;
                additionalDetailJa: string | null;
                additionalDetailEn: string | null;
              }>;
              evaluationItems: Array<{
                __typename?: "MultiChoiceEvaluationItem";
                id: number;
                questionId: number;
                questionVersion: string;
                titleJa: string | null;
                titleEn: string | null;
                descriptionJa: string | null;
                descriptionEn: string | null;
              }>;
            }>;
          } | null;
          submissions: Array<{
            __typename?: "QuizSubmission";
            id: string;
            quizSubmissionId: number;
            quizId: number;
            packageId: number;
            packageVersion: string;
            questionId: number;
            questionVersion: string;
            optionIds: Array<number>;
            isCorrect: boolean;
            answerText: string;
            score: number;
            evaluatedBy: string;
            isAutomaticEvaluation: boolean;
            evaluatedAtSeconds: number | null;
            numTabEvents: number;
            numAccessEvents: number;
            elapsedTimeSeconds: number | null;
            createdAtSeconds: number | null;
            quizSubmissionStatus: QuizSubmissionStatus;
            evaluations: Array<{
              __typename?: "QuizEvaluation";
              submissionId: number;
              score: number;
              isPassed: boolean;
              reasonJa: string;
              reasonEn: string;
              evaluationItem: {
                __typename?: "MultiChoiceEvaluationItem";
                id: number;
                questionId: number;
                questionVersion: string;
                titleJa: string | null;
                titleEn: string | null;
                descriptionJa: string | null;
                descriptionEn: string | null;
              } | null;
            }>;
            evaluatedUser: { __typename?: "User"; uid: string; displayName: string } | null;
          }>;
        }
      | {
          __typename?: "SystemDesignEntity";
          id: string;
          entityId: number;
          systemDesignEntityId: number;
          totalScore: number;
          evaluatedAtSeconds: number | null;
          appealMessage: string | null;
          systemDesignStatus: SystemDesignStatus;
          submissions: Array<{
            __typename?: "SystemDesignSubmission";
            systemDesignSubmissionId: number;
            isBest: boolean;
            status: SystemDesignSubmissionStatus;
            snapshot: string;
            numTabEvents: number;
            numAccessEvents: number;
            totalScore: number;
            numSubset: number;
            totalElapsedTimeSeconds: number | null;
            elapsedTimeSeconds: number | null;
            evaluatedAtSeconds: number | null;
            evaluations: Array<{
              __typename?: "SystemDesignEvaluation";
              totalScore: number;
              evaluatedBy: string;
              availability: number;
              scalability: number;
              consistency: number;
              result: string;
            }>;
            usedHints: Array<{ __typename?: "SystemDesignHint"; systemDesignHintId: number }>;
            snapshots: Array<{
              __typename?: "SystemDesignSnapshot";
              systemDesignSnapshotId: number;
              scoringItemId: number;
              isPassed: boolean;
              matchedIds: Array<string>;
            }>;
          }>;
          question: {
            __typename?: "SystemDesignQuestion";
            questionId: number;
            titleEn: string | null;
            titleJa: string | null;
            difficulty: Difficulty;
            descriptionJa: string | null;
            descriptionEn: string | null;
            initialSnapshot: string;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            timeLimitSeconds: number | null;
            isOfficial: boolean;
            systemDesignQuestionStatus: SystemDesignQuestionStatus;
            hints: Array<{ __typename?: "SystemDesignHint"; descriptionJa: string | null; descriptionEn: string | null }>;
            answers: Array<{
              __typename?: "SystemDesignAnswer";
              id: string;
              titleJa: string;
              titleEn: string;
              descriptionJa: string;
              descriptionEn: string;
            }>;
            scoringItems: Array<{
              __typename?: "SystemDesignScoringItem";
              systemDesignScoringItemId: number;
              category: SystemDesignScoringItemCategory;
              titleJa: string;
              titleEn: string;
              descriptionJa: string;
              descriptionEn: string;
              graphlets: string;
              isHidden: boolean;
            }>;
          } | null;
        }
    >;
    issuedEntities: Array<
      | {
          __typename?: "SpotIssuedChallengeEntity";
          questionWeight: number;
          entity: {
            __typename?: "ChallengeEntity";
            id: string;
            challengeEntityId: number;
            enabledLanguages: Array<string>;
            challengeStatus: ChallengeStatus;
            challengeQuestion: {
              __typename?: "AlgorithmQuestion";
              id: string;
              questionId: number;
              version: string;
              titleJa: string | null;
              titleEn: string | null;
              descriptionEn: string | null;
              descriptionJa: string | null;
              difficulty: Difficulty;
              algorithmQuestionStatus: QuestionStatus;
            } | null;
          };
        }
      | {
          __typename?: "SpotIssuedProjectEntity";
          questionWeight: number;
          entity: {
            __typename?: "ProjectEntity";
            id: string;
            projectEntityId: number;
            projectStatus: ProjectStatus;
            question: {
              __typename?: "ProjectQuestion";
              id: string;
              questionId: number;
              version: string;
              titleEn: string | null;
              titleJa: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
              difficulty: Difficulty;
              projectQuestionStatus: ProjectQuestionStatus;
            } | null;
          };
        }
      | {
          __typename?: "SpotIssuedQuizEntity";
          questionWeight: number;
          entity: {
            __typename?: "QuizEntity";
            id: string;
            quizEntityId: number;
            quizStatus: QuizStatus;
            pb_package: {
              __typename?: "MultiChoicePackage";
              id: string;
              packageId: number;
              version: string;
              titleEn: string | null;
              titleJa: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
              difficulty: Difficulty;
              multiChoicePackageStatus: QuestionStatus;
            } | null;
          };
        }
      | {
          __typename?: "SpotIssuedSystemDesignEntity";
          questionWeight: number;
          entity: {
            __typename?: "SystemDesignEntity";
            id: string;
            systemDesignEntityId: number;
            componentTypes: Array<SystemDesignComponentType>;
            systemDesignStatus: SystemDesignStatus;
            question: {
              __typename?: "SystemDesignQuestion";
              id: string;
              questionId: number;
              titleJa: string | null;
              titleEn: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
              difficulty: Difficulty;
              systemDesignQuestionStatus: SystemDesignQuestionStatus;
            } | null;
          };
        }
    >;
  };
};

export type GetCompanyForMemberSettingsGroupFragment = {
  __typename?: "EmployeeGroup";
  id: string;
  groupName: string;
  createdAtSeconds: number | null;
  companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string }>;
};

export type GetCompanyForMemberSettingsEmployeeFragment = {
  __typename?: "User";
  id: string;
  displayName: string;
  fullName: string;
  email: string;
  photoUrl: string;
};

export type GetCompanyForMemberSettingsForCompanyEmployeeFragment = {
  __typename?: "CompanyEmployee";
  employeeId: string;
  role: Role;
  lastSignedInAtSeconds: number | null;
};

export type GetCompanyForMemberSettingsCompanyFragment = {
  __typename?: "Company";
  companyId: number;
  name: string;
  employees: Array<{ __typename?: "User"; id: string; displayName: string; fullName: string; email: string; photoUrl: string }>;
  companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string; role: Role; lastSignedInAtSeconds: number | null }>;
  groups: Array<{
    __typename?: "EmployeeGroup";
    id: string;
    groupName: string;
    createdAtSeconds: number | null;
    companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string }>;
  }>;
};

export type GetCompanyForMemberSettingsQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
}>;

export type GetCompanyForMemberSettingsQuery = {
  __typename?: "Query";
  company: {
    __typename?: "Company";
    companyId: number;
    name: string;
    employees: Array<{ __typename?: "User"; id: string; displayName: string; fullName: string; email: string; photoUrl: string }>;
    companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string; role: Role; lastSignedInAtSeconds: number | null }>;
    groups: Array<{
      __typename?: "EmployeeGroup";
      id: string;
      groupName: string;
      createdAtSeconds: number | null;
      companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string }>;
    }>;
  };
};

export type CreateEmployeeInvitationMutationVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  employeeId: Scalars["String"]["input"];
  invitationMethod: InvitationMethod;
  emailAddresses: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
  role: Role;
  invitationLanguage: InvitationLanguage;
}>;

export type CreateEmployeeInvitationMutation = {
  __typename?: "Mutation";
  createEmployeeInvitation: {
    __typename?: "Invitation";
    id: string;
    invitationMethod: InvitationMethod;
    emailAddresses: Array<string>;
    role: Role;
    createdBy: string;
    expiresAtSeconds: number | null;
    invitationLanguage: InvitationLanguage;
  } | null;
};

export type DeleteMultiCompanyEmployeesForMemberSettingsMutationVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  employeeIds: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type DeleteMultiCompanyEmployeesForMemberSettingsMutation = { __typename?: "Mutation"; deleteMultiCompanyEmployees: boolean };

export type DeleteCompanyEmployeeForMemberSettingsMutationVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  employeeId: Scalars["String"]["input"];
}>;

export type DeleteCompanyEmployeeForMemberSettingsMutation = { __typename?: "Mutation"; deleteCompanyEmployee: boolean };

export type UpdateCompanyEmployeeForMemberSettingsMutationVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  employeeId: Scalars["String"]["input"];
  role: Role;
}>;

export type UpdateCompanyEmployeeForMemberSettingsMutation = {
  __typename?: "Mutation";
  updateCompanyEmployee: { __typename?: "CompanyEmployee"; employeeId: string; role: Role } | null;
};

export type ReadEmployeeNotificationsMutationVariables = Exact<{
  employeeNotificationIds: Array<Scalars["Int"]["input"]> | Scalars["Int"]["input"];
  employeeId: Scalars["String"]["input"];
  companyId: Scalars["Int"]["input"];
}>;

export type ReadEmployeeNotificationsMutation = {
  __typename?: "Mutation";
  readEmployeeNotifications: Array<{
    __typename?: "EmployeeNotification";
    id: string;
    notificationId: number;
    employeeId: string;
    isRead: boolean;
    titleJa: string;
    titleEn: string;
    descriptionJa: string;
    descriptionEn: string;
    createdAtSeconds: number | null;
    companyId: number;
    actionLink: string;
  }>;
};

export type UpdateSlackSettingsForIncomingWebHookFragment = {
  __typename?: "SlackIncomingWebhook";
  webhookUrl: string;
  channel: string;
  channelId: string;
  configurationUrl: string;
};

export type UpdateSlackSettingsForSpotSettingsFragment = {
  __typename?: "SpotSettingsSubscribeSlack";
  subscribeSpotCompleted: boolean;
  subscribeSpotCreated: boolean;
  subscribeSpotDeleted: boolean;
  subscribeSpotEvaluated: boolean;
  subscribeSpotRecreated: boolean;
  subscribeSpotReviewed: boolean;
  subscribeSpotFinalized: boolean;
  subscribeSpotStarted: boolean;
  subscribeSpotExpired: boolean;
};

export type UpdateSlackSettingsForRemoteSettingsFragment = {
  __typename?: "RemoteSettingsSubscribeSlack";
  subscribeRemoteCreated: boolean;
  subscribeRemoteAwaiting: boolean;
  subscribeRemoteStarted: boolean;
  subscribeRemoteCompleted: boolean;
  subscribeRemoteDeleted: boolean;
  subscribeRemoteReviewed: boolean;
  subscribeRemoteEvaluated: boolean;
};

export type UpdateSlackSettingsMutationVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  language: Language;
  spotSetting: SpotSettingsSubscribeSlackInput;
  remoteSetting: RemoteSettingsSubscribeSlackInput;
}>;

export type UpdateSlackSettingsMutation = {
  __typename?: "Mutation";
  updateSlackSettings: {
    __typename?: "SlackSettings";
    companyId: number;
    isAuthorized: boolean;
    language: Language;
    spotSettings: {
      __typename?: "SpotSettingsSubscribeSlack";
      subscribeSpotCompleted: boolean;
      subscribeSpotCreated: boolean;
      subscribeSpotDeleted: boolean;
      subscribeSpotEvaluated: boolean;
      subscribeSpotRecreated: boolean;
      subscribeSpotReviewed: boolean;
      subscribeSpotFinalized: boolean;
      subscribeSpotStarted: boolean;
      subscribeSpotExpired: boolean;
    } | null;
    remoteSettings: {
      __typename?: "RemoteSettingsSubscribeSlack";
      subscribeRemoteCreated: boolean;
      subscribeRemoteAwaiting: boolean;
      subscribeRemoteStarted: boolean;
      subscribeRemoteCompleted: boolean;
      subscribeRemoteDeleted: boolean;
      subscribeRemoteReviewed: boolean;
      subscribeRemoteEvaluated: boolean;
    } | null;
    incomingWebHook: {
      __typename?: "SlackIncomingWebhook";
      webhookUrl: string;
      channel: string;
      channelId: string;
      configurationUrl: string;
    } | null;
  } | null;
};

export type AuthorizeSlackMutationVariables = Exact<{
  input: AuthorizeSlackInput;
}>;

export type AuthorizeSlackMutation = {
  __typename?: "Mutation";
  authorizeSlack: {
    __typename?: "SlackSettings";
    companyId: number;
    isAuthorized: boolean;
    incomingWebHook: {
      __typename?: "SlackIncomingWebhook";
      webhookUrl: string;
      channel: string;
      channelId: string;
      configurationUrl: string;
    } | null;
  } | null;
};

export type GetSlackSettingsForIncomingWebHookFragment = {
  __typename?: "SlackIncomingWebhook";
  webhookUrl: string;
  channel: string;
  channelId: string;
  configurationUrl: string;
};

export type GetSlackSettingsForSpotSettingsFragment = {
  __typename?: "SpotSettingsSubscribeSlack";
  subscribeSpotCompleted: boolean;
  subscribeSpotCreated: boolean;
  subscribeSpotDeleted: boolean;
  subscribeSpotEvaluated: boolean;
  subscribeSpotRecreated: boolean;
  subscribeSpotReviewed: boolean;
  subscribeSpotFinalized: boolean;
  subscribeSpotStarted: boolean;
  subscribeSpotExpired: boolean;
};

export type GetSlackSettingsForRemoteSettingsFragment = {
  __typename?: "RemoteSettingsSubscribeSlack";
  subscribeRemoteCreated: boolean;
  subscribeRemoteAwaiting: boolean;
  subscribeRemoteStarted: boolean;
  subscribeRemoteCompleted: boolean;
  subscribeRemoteDeleted: boolean;
  subscribeRemoteReviewed: boolean;
  subscribeRemoteEvaluated: boolean;
};

export type GetSlackSettingsQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
}>;

export type GetSlackSettingsQuery = {
  __typename?: "Query";
  slackSettings: {
    __typename?: "SlackSettings";
    companyId: number;
    isAuthorized: boolean;
    language: Language;
    incomingWebHook: {
      __typename?: "SlackIncomingWebhook";
      webhookUrl: string;
      channel: string;
      channelId: string;
      configurationUrl: string;
    } | null;
    spotSettings: {
      __typename?: "SpotSettingsSubscribeSlack";
      subscribeSpotCompleted: boolean;
      subscribeSpotCreated: boolean;
      subscribeSpotDeleted: boolean;
      subscribeSpotEvaluated: boolean;
      subscribeSpotRecreated: boolean;
      subscribeSpotReviewed: boolean;
      subscribeSpotFinalized: boolean;
      subscribeSpotStarted: boolean;
      subscribeSpotExpired: boolean;
    } | null;
    remoteSettings: {
      __typename?: "RemoteSettingsSubscribeSlack";
      subscribeRemoteCreated: boolean;
      subscribeRemoteAwaiting: boolean;
      subscribeRemoteStarted: boolean;
      subscribeRemoteCompleted: boolean;
      subscribeRemoteDeleted: boolean;
      subscribeRemoteReviewed: boolean;
      subscribeRemoteEvaluated: boolean;
    } | null;
  } | null;
};

export type PaymentPurchaseForOrderHistoryFragment = {
  __typename?: "PaymentPurchase";
  purchaseId: number;
  customerId: number;
  unitPrice: number;
  totalPrice: number;
  quantity: number;
  createdAtSeconds: number | null;
  plan: { __typename?: "PaymentV2Plan"; nameJa: string; nameEn: string; planType: PlanType; price: number } | null;
  interview: {
    __typename?: "PaymentInterview";
    interviewId: number;
    nameJa: string;
    nameEn: string;
    price: number;
    quantity: number;
    generation: number;
    interviewKind: PaymentInterviewKind;
  } | null;
  seat: { __typename?: "UserSeat"; seatId: number; nameJa: string; nameEn: string; price: number; quantity: number; generation: number } | null;
};

export type PurchasesResponseForOrderHistoryFragment = {
  __typename?: "PurchasesResponse";
  count: number;
  purchases: Array<{
    __typename?: "PaymentPurchase";
    purchaseId: number;
    customerId: number;
    unitPrice: number;
    totalPrice: number;
    quantity: number;
    createdAtSeconds: number | null;
    plan: { __typename?: "PaymentV2Plan"; nameJa: string; nameEn: string; planType: PlanType; price: number } | null;
    interview: {
      __typename?: "PaymentInterview";
      interviewId: number;
      nameJa: string;
      nameEn: string;
      price: number;
      quantity: number;
      generation: number;
      interviewKind: PaymentInterviewKind;
    } | null;
    seat: {
      __typename?: "UserSeat";
      seatId: number;
      nameJa: string;
      nameEn: string;
      price: number;
      quantity: number;
      generation: number;
    } | null;
  }>;
  pager: { __typename?: "PaymentPager"; cursorSeconds: number | null; cursorNanoSeconds: number | null; hasNext: boolean } | null;
};

export type CompanyForOrderHistoryFragment = { __typename?: "Company"; createdAtSeconds: number | null };

export type GetPurchasesForOrderHistoryQueryVariables = Exact<{
  customerId: Scalars["Int"]["input"];
  purchasePeriodStartSeconds: Scalars["Int"]["input"];
  purchasePeriodEndSeconds: Scalars["Int"]["input"];
  cursorSeconds: InputMaybe<Scalars["Int"]["input"]>;
  cursorNanoSeconds: InputMaybe<Scalars["Int"]["input"]>;
  size: Scalars["Int"]["input"];
}>;

export type GetPurchasesForOrderHistoryQuery = {
  __typename?: "Query";
  purchases: {
    __typename?: "PurchasesResponse";
    count: number;
    purchases: Array<{
      __typename?: "PaymentPurchase";
      purchaseId: number;
      customerId: number;
      unitPrice: number;
      totalPrice: number;
      quantity: number;
      createdAtSeconds: number | null;
      plan: { __typename?: "PaymentV2Plan"; nameJa: string; nameEn: string; planType: PlanType; price: number } | null;
      interview: {
        __typename?: "PaymentInterview";
        interviewId: number;
        nameJa: string;
        nameEn: string;
        price: number;
        quantity: number;
        generation: number;
        interviewKind: PaymentInterviewKind;
      } | null;
      seat: {
        __typename?: "UserSeat";
        seatId: number;
        nameJa: string;
        nameEn: string;
        price: number;
        quantity: number;
        generation: number;
      } | null;
    }>;
    pager: { __typename?: "PaymentPager"; cursorSeconds: number | null; cursorNanoSeconds: number | null; hasNext: boolean } | null;
  };
  company: { __typename?: "Company"; createdAtSeconds: number | null };
};

export type PaymentV2PlanForPaymentContractCreateFormFragment = {
  __typename?: "PaymentV2Plan";
  id: string;
  planId: number;
  nameJa: string;
  nameEn: string;
  descriptionJa: string;
  descriptionEn: string;
  price: number;
  generation: number;
  planType: PlanType;
  term: number;
  enabledChangePlan: boolean;
  enabledPurchasePack: boolean;
};

export type PlansResponseForPaymentContractCreateFormFragment = {
  __typename?: "PlansResponse";
  plans: Array<{
    __typename?: "PaymentV2Plan";
    id: string;
    planId: number;
    nameJa: string;
    nameEn: string;
    descriptionJa: string;
    descriptionEn: string;
    price: number;
    generation: number;
    planType: PlanType;
    term: number;
    enabledChangePlan: boolean;
    enabledPurchasePack: boolean;
  }>;
};

export type CustomerForPaymentContractCreateFormFragment = {
  __typename?: "PaymentV2Customer";
  customerId: number;
  paymentMethod: string;
  paymentType: PaymentType;
  paymentStatus: PaymentStatus;
  isTrial: boolean;
  isAutoRenewal: boolean;
  language: Language;
  email: string;
  country: Country;
  postalCode: string;
  state: string;
  city: string;
  line1: string;
  line2: string;
  lastFour: string;
  bankName: string;
  bankCode: string;
  branchName: string;
  branchCode: string;
  accountHolderName: string;
  accountNumber: string;
  accountType: PaymentV2AccountType;
  subscription: {
    __typename?: "PaymentV2Subscription";
    subscriptionId: number;
    customerId: number;
    status: PaymentV2SubscriptionStatus;
    plan: { __typename?: "PaymentV2Plan"; generation: number; planType: PlanType };
    shotInterview: { __typename?: "PaymentInterview"; price: number };
    subscribedCoupons: Array<{
      __typename?: "SubscribedCoupon";
      isActive: boolean;
      createdAtSeconds: number;
      expiresAtSeconds: number;
      coupon: {
        __typename?: "Coupon";
        couponId: number;
        nameJa: string;
        nameEn: string;
        couponType: PaymentCouponType;
        couponDurationType: PaymentCouponDurationType;
        durationMonth: number;
        discountNumber: number;
      };
    }>;
  };
};

export type PaymentInterviewForPaymentContractCreateFormFragment = {
  __typename?: "PaymentInterview";
  interviewId: number;
  nameJa: string;
  nameEn: string;
  descriptionJa: string;
  descriptionEn: string;
  price: number;
  quantity: number;
  generation: number;
  interviewKind: PaymentInterviewKind;
};

export type PaymentInitialCostForPaymentContractCreateFormFragment = {
  __typename?: "PaymentInterview";
  nameJa: string;
  nameEn: string;
  descriptionJa: string;
  descriptionEn: string;
  interviewKind: PaymentInterviewKind;
  price: number;
  quantity: number;
};

export type GetPlansForPaymentContractCreateFormQueryVariables = Exact<{
  customerId: Scalars["Int"]["input"];
  planGeneration: Scalars["Int"]["input"];
}>;

export type GetPlansForPaymentContractCreateFormQuery = {
  __typename?: "Query";
  customer: {
    __typename?: "PaymentV2Customer";
    customerId: number;
    paymentMethod: string;
    paymentType: PaymentType;
    paymentStatus: PaymentStatus;
    isTrial: boolean;
    isAutoRenewal: boolean;
    language: Language;
    email: string;
    country: Country;
    postalCode: string;
    state: string;
    city: string;
    line1: string;
    line2: string;
    lastFour: string;
    bankName: string;
    bankCode: string;
    branchName: string;
    branchCode: string;
    accountHolderName: string;
    accountNumber: string;
    accountType: PaymentV2AccountType;
    subscription: {
      __typename?: "PaymentV2Subscription";
      subscriptionId: number;
      customerId: number;
      status: PaymentV2SubscriptionStatus;
      plan: { __typename?: "PaymentV2Plan"; generation: number; planType: PlanType };
      shotInterview: { __typename?: "PaymentInterview"; price: number };
      subscribedCoupons: Array<{
        __typename?: "SubscribedCoupon";
        isActive: boolean;
        createdAtSeconds: number;
        expiresAtSeconds: number;
        coupon: {
          __typename?: "Coupon";
          couponId: number;
          nameJa: string;
          nameEn: string;
          couponType: PaymentCouponType;
          couponDurationType: PaymentCouponDurationType;
          durationMonth: number;
          discountNumber: number;
        };
      }>;
    };
  };
  plans: {
    __typename?: "PlansResponse";
    plans: Array<{
      __typename?: "PaymentV2Plan";
      id: string;
      planId: number;
      nameJa: string;
      nameEn: string;
      descriptionJa: string;
      descriptionEn: string;
      price: number;
      generation: number;
      planType: PlanType;
      term: number;
      enabledChangePlan: boolean;
      enabledPurchasePack: boolean;
    }>;
  };
  buyAblePacks: {
    __typename?: "BuyAblePacksResponse";
    interviews: Array<{
      __typename?: "PaymentInterview";
      interviewId: number;
      nameJa: string;
      nameEn: string;
      descriptionJa: string;
      descriptionEn: string;
      price: number;
      quantity: number;
      generation: number;
      interviewKind: PaymentInterviewKind;
    }>;
  };
  paymentInitialCost: {
    __typename?: "PaymentInterview";
    nameJa: string;
    nameEn: string;
    descriptionJa: string;
    descriptionEn: string;
    interviewKind: PaymentInterviewKind;
    price: number;
    quantity: number;
  } | null;
};

export type ContractPlanForPaymentContractCreateFormMutationVariables = Exact<{
  input: ContractPlanInput;
}>;

export type ContractPlanForPaymentContractCreateFormMutation = {
  __typename?: "Mutation";
  contractPlan: {
    __typename?: "PaymentV2Customer";
    customerId: number;
    paymentMethod: string;
    paymentType: PaymentType;
    paymentStatus: PaymentStatus;
    isTrial: boolean;
    isAutoRenewal: boolean;
    language: Language;
    email: string;
    country: Country;
    postalCode: string;
    state: string;
    city: string;
    line1: string;
    line2: string;
    lastFour: string;
    bankName: string;
    bankCode: string;
    branchName: string;
    branchCode: string;
    accountHolderName: string;
    accountNumber: string;
    accountType: PaymentV2AccountType;
    subscription: {
      __typename?: "PaymentV2Subscription";
      subscriptionId: number;
      customerId: number;
      status: PaymentV2SubscriptionStatus;
      plan: { __typename?: "PaymentV2Plan"; generation: number; planType: PlanType };
      shotInterview: { __typename?: "PaymentInterview"; price: number };
      subscribedCoupons: Array<{
        __typename?: "SubscribedCoupon";
        isActive: boolean;
        createdAtSeconds: number;
        expiresAtSeconds: number;
        coupon: {
          __typename?: "Coupon";
          couponId: number;
          nameJa: string;
          nameEn: string;
          couponType: PaymentCouponType;
          couponDurationType: PaymentCouponDurationType;
          durationMonth: number;
          discountNumber: number;
        };
      }>;
    };
  };
};

export type CouponForPaymentContractCreateFormFragment = {
  __typename?: "Coupon";
  couponId: number;
  nameJa: string;
  nameEn: string;
  couponType: PaymentCouponType;
  couponDurationType: PaymentCouponDurationType;
  durationMonth: number;
  discountNumber: number;
  createdAtSeconds: number | null;
  updatedAtSeconds: number | null;
  deletedAtSeconds: number | null;
};

export type GetCouponByCodeForPaymentContractCreateFormQueryVariables = Exact<{
  couponCode: Scalars["String"]["input"];
}>;

export type GetCouponByCodeForPaymentContractCreateFormQuery = {
  __typename?: "Query";
  couponByCode: {
    __typename?: "Coupon";
    couponId: number;
    nameJa: string;
    nameEn: string;
    couponType: PaymentCouponType;
    couponDurationType: PaymentCouponDurationType;
    durationMonth: number;
    discountNumber: number;
    createdAtSeconds: number | null;
    updatedAtSeconds: number | null;
    deletedAtSeconds: number | null;
  } | null;
};

export type CustomerForPaymentMethodUpdateFormFragment = {
  __typename?: "PaymentV2Customer";
  customerId: number;
  paymentMethod: string;
  paymentType: PaymentType;
  paymentStatus: PaymentStatus;
  email: string;
  country: Country;
  postalCode: string;
  state: string;
  city: string;
  line1: string;
  line2: string;
  lastFour: string;
  bankName: string;
  bankCode: string;
  branchName: string;
  branchCode: string;
  accountHolderName: string;
  accountNumber: string;
  accountType: PaymentV2AccountType;
  language: Language;
};

export type GetPaymentMethodUpdateFormQueryVariables = Exact<{
  customerId: Scalars["Int"]["input"];
}>;

export type GetPaymentMethodUpdateFormQuery = {
  __typename?: "Query";
  customer: {
    __typename?: "PaymentV2Customer";
    customerId: number;
    paymentMethod: string;
    paymentType: PaymentType;
    paymentStatus: PaymentStatus;
    email: string;
    country: Country;
    postalCode: string;
    state: string;
    city: string;
    line1: string;
    line2: string;
    lastFour: string;
    bankName: string;
    bankCode: string;
    branchName: string;
    branchCode: string;
    accountHolderName: string;
    accountNumber: string;
    accountType: PaymentV2AccountType;
    language: Language;
  };
};

export type UpdatePaymentV2CustomerForPaymentMethodUpdateFormMutationVariables = Exact<{
  input: UpdateCustomerInput;
}>;

export type UpdatePaymentV2CustomerForPaymentMethodUpdateFormMutation = {
  __typename?: "Mutation";
  updatePaymentCustomer: {
    __typename?: "UpdateCustomerResponse";
    errorCode: PaymentErrorCode | null;
    customer: {
      __typename?: "PaymentV2Customer";
      customerId: number;
      paymentMethod: string;
      paymentType: PaymentType;
      paymentStatus: PaymentStatus;
      email: string;
      country: Country;
      postalCode: string;
      state: string;
      city: string;
      line1: string;
      line2: string;
      lastFour: string;
      bankName: string;
      bankCode: string;
      branchName: string;
      branchCode: string;
      accountHolderName: string;
      accountNumber: string;
      accountType: PaymentV2AccountType;
      language: Language;
    };
  };
};

export type RetryPaymentForPaymentMethodUpdateFormMutationVariables = Exact<{
  input: RetryPaymentInput;
}>;

export type RetryPaymentForPaymentMethodUpdateFormMutation = { __typename?: "Mutation"; retryPayment: boolean };

export type PaymentV2PlanForPaymentPlanListFragment = { __typename?: "PaymentV2Plan"; planType: PlanType };

export type PlansResponseForPaymentPlanListFragment = {
  __typename?: "PlansResponse";
  plans: Array<{ __typename?: "PaymentV2Plan"; planType: PlanType }>;
};

export type CustomerForPaymentPlanListFragment = {
  __typename?: "PaymentV2Customer";
  isTrial: boolean;
  language: Language;
  subscription: { __typename?: "PaymentV2Subscription"; plan: { __typename?: "PaymentV2Plan"; generation: number; planType: PlanType } };
};

export type GetPlansForPaymentPlanListQueryVariables = Exact<{
  customerId: Scalars["Int"]["input"];
}>;

export type GetPlansForPaymentPlanListQuery = {
  __typename?: "Query";
  customer: {
    __typename?: "PaymentV2Customer";
    isTrial: boolean;
    language: Language;
    subscription: { __typename?: "PaymentV2Subscription"; plan: { __typename?: "PaymentV2Plan"; generation: number; planType: PlanType } };
  };
  plans: { __typename?: "PlansResponse"; plans: Array<{ __typename?: "PaymentV2Plan"; planType: PlanType }> };
};

export type PaymentV2PlanForPaymentPlanUpdateFormFragment = {
  __typename?: "PaymentV2Plan";
  id: string;
  planId: number;
  nameJa: string;
  nameEn: string;
  descriptionJa: string;
  descriptionEn: string;
  price: number;
  term: number;
  generation: number;
  planType: PlanType;
};

export type PlansResponseForPaymentPlanUpdateFormFragment = {
  __typename?: "PlansResponse";
  plans: Array<{
    __typename?: "PaymentV2Plan";
    id: string;
    planId: number;
    nameJa: string;
    nameEn: string;
    descriptionJa: string;
    descriptionEn: string;
    price: number;
    term: number;
    generation: number;
    planType: PlanType;
  }>;
};

export type CustomerForPaymentPlanUpdateFormFragment = {
  __typename?: "PaymentV2Customer";
  paymentMethod: string;
  paymentType: PaymentType;
  paymentStatus: PaymentStatus;
  isTrial: boolean;
  isAutoRenewal: boolean;
  language: Language;
  subscription: {
    __typename?: "PaymentV2Subscription";
    subscriptionId: number;
    status: PaymentV2SubscriptionStatus;
    customerId: number;
    periodType: PaymentPeriodType;
    plan: { __typename?: "PaymentV2Plan"; generation: number; planType: PlanType; enabledChangePlan: boolean };
    subscribedCoupons: Array<{
      __typename?: "SubscribedCoupon";
      isActive: boolean;
      createdAtSeconds: number;
      expiresAtSeconds: number;
      coupon: {
        __typename?: "Coupon";
        couponId: number;
        nameJa: string;
        nameEn: string;
        couponType: PaymentCouponType;
        couponDurationType: PaymentCouponDurationType;
        durationMonth: number;
        discountNumber: number;
      };
    }>;
  };
};

export type MostExpensivePurchaseForPaymentPlanUpdateFormFragment = {
  __typename?: "PaymentPurchase";
  purchaseId: number;
  plan: {
    __typename?: "PaymentV2Plan";
    id: string;
    planId: number;
    nameJa: string;
    nameEn: string;
    descriptionJa: string;
    descriptionEn: string;
    price: number;
    term: number;
    generation: number;
    planType: PlanType;
  } | null;
};

export type GetPlansForPaymentPlanUpdateFormQueryVariables = Exact<{
  customerId: Scalars["Int"]["input"];
  purchasePeriodStartSeconds: Scalars["Int"]["input"];
  purchasePeriodEndSeconds: Scalars["Int"]["input"];
  cursorSeconds: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetPlansForPaymentPlanUpdateFormQuery = {
  __typename?: "Query";
  customer: {
    __typename?: "PaymentV2Customer";
    paymentMethod: string;
    paymentType: PaymentType;
    paymentStatus: PaymentStatus;
    isTrial: boolean;
    isAutoRenewal: boolean;
    language: Language;
    subscription: {
      __typename?: "PaymentV2Subscription";
      subscriptionId: number;
      status: PaymentV2SubscriptionStatus;
      customerId: number;
      periodType: PaymentPeriodType;
      plan: { __typename?: "PaymentV2Plan"; generation: number; planType: PlanType; enabledChangePlan: boolean };
      subscribedCoupons: Array<{
        __typename?: "SubscribedCoupon";
        isActive: boolean;
        createdAtSeconds: number;
        expiresAtSeconds: number;
        coupon: {
          __typename?: "Coupon";
          couponId: number;
          nameJa: string;
          nameEn: string;
          couponType: PaymentCouponType;
          couponDurationType: PaymentCouponDurationType;
          durationMonth: number;
          discountNumber: number;
        };
      }>;
    };
  };
  plans: {
    __typename?: "PlansResponse";
    plans: Array<{
      __typename?: "PaymentV2Plan";
      id: string;
      planId: number;
      nameJa: string;
      nameEn: string;
      descriptionJa: string;
      descriptionEn: string;
      price: number;
      term: number;
      generation: number;
      planType: PlanType;
    }>;
  };
  mostExpensivePurchase: {
    __typename?: "PaymentPurchase";
    purchaseId: number;
    plan: {
      __typename?: "PaymentV2Plan";
      id: string;
      planId: number;
      nameJa: string;
      nameEn: string;
      descriptionJa: string;
      descriptionEn: string;
      price: number;
      term: number;
      generation: number;
      planType: PlanType;
    } | null;
  } | null;
};

export type ChangePlanForPlanUpdateFormMutationVariables = Exact<{
  input: ChangePlanInput;
}>;

export type ChangePlanForPlanUpdateFormMutation = { __typename?: "Mutation"; changePlan: boolean };

export type StartProjectForProjectCodingEditorMutationVariables = Exact<{
  input: StartProjectInput;
}>;

export type StartProjectForProjectCodingEditorMutation = {
  __typename?: "Mutation";
  startProject: {
    __typename: "ProjectEntity";
    projectEntityId: number;
    appealMessage: string | null;
    agentVersion: string;
    projectStatus: ProjectStatus;
    question: {
      __typename?: "ProjectQuestion";
      questionId: number;
      version: string;
      projectVersion: string;
      variant: ProjectQuestionVariant;
      titleJa: string | null;
      titleEn: string | null;
      correctnessTestCase: string;
      performanceTestCase: string;
      defaultTestCase: string;
      descriptionJa: string | null;
      descriptionEn: string | null;
      timeLimitSeconds: number | null;
      fileIndexes: Array<string>;
      readOnlyFiles: Array<string>;
    } | null;
    submissions: Array<{ __typename?: "ProjectSubmission"; projectSubmissionId: number }>;
  };
};

export type StartDevelopmentWorkspaceForProjectCodingEditorMutationVariables = Exact<{
  input: StartDevelopmentWorkspaceInput;
}>;

export type StartDevelopmentWorkspaceForProjectCodingEditorMutation = {
  __typename?: "Mutation";
  startDevelopmentWorkspace: { __typename?: "ProjectWorkspace"; id: string; uri: string; clientPort: number; agentPort: number };
};

export type ProjectWorkspaceForProjectCodingEditorFragment = {
  __typename?: "ProjectWorkspace";
  id: string;
  uri: string;
  clientPort: number;
  agentPort: number;
};

export type ResetDevelopmentWorkspaceForProjectCodingEditorMutationVariables = Exact<{
  input: ResetDevelopmentWorkspaceInput;
}>;

export type ResetDevelopmentWorkspaceForProjectCodingEditorMutation = {
  __typename?: "Mutation";
  resetDevelopmentWorkspace: { __typename?: "ProjectWorkspace"; id: string; uri: string; clientPort: number; agentPort: number };
};

export type SaveProjectAppealMessageForProjectCodingEditorMutationVariables = Exact<{
  input: SaveProjectAppealMessageInput;
}>;

export type SaveProjectAppealMessageForProjectCodingEditorMutation = {
  __typename?: "Mutation";
  saveProjectAppealMessage: { __typename?: "ProjectEntity"; projectEntityId: number; appealMessage: string | null };
};

export type StartEvaluationWorkspaceForProjectCodingEditorMutationVariables = Exact<{
  input: StartEvaluationWorkspaceInput;
}>;

export type StartEvaluationWorkspaceForProjectCodingEditorMutation = {
  __typename?: "Mutation";
  startEvaluationWorkspace: { __typename?: "ProjectWorkspace"; id: string; uri: string; clientPort: number; agentPort: number };
};

export type ResetEvaluationWorkspaceForProjectCodingEditorMutationVariables = Exact<{
  input: ResetEvaluationWorkspaceInput;
}>;

export type ResetEvaluationWorkspaceForProjectCodingEditorMutation = {
  __typename?: "Mutation";
  resetEvaluationWorkspace: { __typename?: "ProjectWorkspace"; id: string; uri: string; clientPort: number; agentPort: number };
};

export type EnqueueProjectSubmissionForProjectCodingEditorMutationVariables = Exact<{
  input: EnqueueProjectSubmissionInput;
}>;

export type EnqueueProjectSubmissionForProjectCodingEditorMutation = {
  __typename?: "Mutation";
  enqueueProjectSubmission: { __typename?: "ProjectSubmission"; projectSubmissionId: number };
};

export type RunProjectForProjectCodingEditorMutationVariables = Exact<{
  input: RunProjectInput;
}>;

export type RunProjectForProjectCodingEditorMutation = {
  __typename?: "Mutation";
  runProject: {
    __typename?: "ProjectSnapshot";
    testCase: string;
    testResult: string;
    status: ProjectSnapshotStatus;
    failureReason: ProjectFailureReason;
    isPassed: boolean;
  };
};

export type GetProjectQuestionInitialCodeQueryVariables = Exact<{
  questionId: Scalars["Int"]["input"];
  questionVersion: Scalars["String"]["input"];
  filePath: Scalars["String"]["input"];
}>;

export type GetProjectQuestionInitialCodeQuery = {
  __typename?: "Query";
  projectQuestionInitialFileBody: { __typename?: "ProjectInitialFileBodyResponse"; fileBody: string };
};

export type StatisticsStatsForProjectRankVisualizerFragment = {
  __typename?: "StatisticsStats";
  avg: number;
  max: number;
  min: number;
  p25: number;
  p50: number;
  p75: number;
  p95: number;
  std: number;
};

export type ProjectStatisticsForProjectRankVisualizerFragment = {
  __typename?: "ProjectStatistics";
  bins: Array<number>;
  totalScore: {
    __typename?: "StatisticsStats";
    avg: number;
    max: number;
    min: number;
    p25: number;
    p50: number;
    p75: number;
    p95: number;
    std: number;
  } | null;
  performance: {
    __typename?: "StatisticsStats";
    avg: number;
    max: number;
    min: number;
    p25: number;
    p50: number;
    p75: number;
    p95: number;
    std: number;
  } | null;
  coverage: {
    __typename?: "StatisticsStats";
    avg: number;
    max: number;
    min: number;
    p25: number;
    p50: number;
    p75: number;
    p95: number;
    std: number;
  } | null;
  numTabEvents: {
    __typename?: "StatisticsStats";
    avg: number;
    max: number;
    min: number;
    p25: number;
    p50: number;
    p75: number;
    p95: number;
    std: number;
  } | null;
  avgLatency: {
    __typename?: "StatisticsStats";
    avg: number;
    max: number;
    min: number;
    p25: number;
    p50: number;
    p75: number;
    p95: number;
    std: number;
  } | null;
  avgSla: {
    __typename?: "StatisticsStats";
    avg: number;
    max: number;
    min: number;
    p25: number;
    p50: number;
    p75: number;
    p95: number;
    std: number;
  } | null;
  numSnapshots: {
    __typename?: "StatisticsStats";
    avg: number;
    max: number;
    min: number;
    p25: number;
    p50: number;
    p75: number;
    p95: number;
    std: number;
  } | null;
  elapsedTime: {
    __typename?: "StatisticsStats";
    avg: number;
    max: number;
    min: number;
    p25: number;
    p50: number;
    p75: number;
    p95: number;
    std: number;
  } | null;
};

export type GetProjectStatisticsQueryVariables = Exact<{
  statisticsSource: StatisticsSourceInput;
  rankSource: RankSourceInput;
}>;

export type GetProjectStatisticsQuery = {
  __typename?: "Query";
  statistics: {
    __typename?: "Statistics";
    projectStatistics: {
      __typename?: "ProjectStatistics";
      bins: Array<number>;
      totalScore: {
        __typename?: "StatisticsStats";
        avg: number;
        max: number;
        min: number;
        p25: number;
        p50: number;
        p75: number;
        p95: number;
        std: number;
      } | null;
      performance: {
        __typename?: "StatisticsStats";
        avg: number;
        max: number;
        min: number;
        p25: number;
        p50: number;
        p75: number;
        p95: number;
        std: number;
      } | null;
      coverage: {
        __typename?: "StatisticsStats";
        avg: number;
        max: number;
        min: number;
        p25: number;
        p50: number;
        p75: number;
        p95: number;
        std: number;
      } | null;
      numTabEvents: {
        __typename?: "StatisticsStats";
        avg: number;
        max: number;
        min: number;
        p25: number;
        p50: number;
        p75: number;
        p95: number;
        std: number;
      } | null;
      avgLatency: {
        __typename?: "StatisticsStats";
        avg: number;
        max: number;
        min: number;
        p25: number;
        p50: number;
        p75: number;
        p95: number;
        std: number;
      } | null;
      avgSla: {
        __typename?: "StatisticsStats";
        avg: number;
        max: number;
        min: number;
        p25: number;
        p50: number;
        p75: number;
        p95: number;
        std: number;
      } | null;
      numSnapshots: {
        __typename?: "StatisticsStats";
        avg: number;
        max: number;
        min: number;
        p25: number;
        p50: number;
        p75: number;
        p95: number;
        std: number;
      } | null;
      elapsedTime: {
        __typename?: "StatisticsStats";
        avg: number;
        max: number;
        min: number;
        p25: number;
        p50: number;
        p75: number;
        p95: number;
        std: number;
      } | null;
    } | null;
  };
  rank: { __typename?: "StatisticsRank"; evaluation: StatisticsEvaluation; numSubset: number; rank: number; relativeScore: number };
};

export type ProjectDiffForProjectTestReportFragment = {
  __typename?: "ProjectDiff";
  projectDiffId: number;
  operation: ProjectOperation;
  fromPath: string;
  toPath: string;
  fromLink: string;
  toLink: string;
};

export type ProjectSubmissionForProjectTestReportFragment = {
  __typename?: "ProjectSubmission";
  projectSubmissionId: number;
  numTests: number;
  numPassed: number;
  numTabEvents: number;
  numAccessEvents: number;
  totalScore: number;
  numSubset: number;
  isBest: boolean;
  testResult: string;
  avgLatency: number;
  avgSla: number;
  coverage: number;
  performance: number;
  correctnessTestResult: string;
  performanceTestResult: string;
  metrics: string;
  failureReason: ProjectFailureReason;
  totalElapsedTimeSeconds: number | null;
  elapsedTimeSeconds: number | null;
  evaluatedAtSeconds: number | null;
  submissionStatus: ProjectSubmissionStatus;
  snapshots: Array<{ __typename?: "ProjectSnapshot"; testCase: string; testResult: string; isPassed: boolean }>;
  diffs: Array<{
    __typename?: "ProjectDiff";
    projectDiffId: number;
    operation: ProjectOperation;
    fromPath: string;
    toPath: string;
    fromLink: string;
    toLink: string;
  }>;
};

export type ProjectSubmissionForProjectTestReportQueryVariables = Exact<{
  submissionId: Scalars["Int"]["input"];
}>;

export type ProjectSubmissionForProjectTestReportQuery = {
  __typename?: "Query";
  projectSubmission: {
    __typename?: "ProjectSubmission";
    projectSubmissionId: number;
    numTests: number;
    numPassed: number;
    numTabEvents: number;
    numAccessEvents: number;
    totalScore: number;
    numSubset: number;
    isBest: boolean;
    testResult: string;
    avgLatency: number;
    avgSla: number;
    coverage: number;
    performance: number;
    correctnessTestResult: string;
    performanceTestResult: string;
    metrics: string;
    failureReason: ProjectFailureReason;
    totalElapsedTimeSeconds: number | null;
    elapsedTimeSeconds: number | null;
    evaluatedAtSeconds: number | null;
    submissionStatus: ProjectSubmissionStatus;
    snapshots: Array<{ __typename?: "ProjectSnapshot"; testCase: string; testResult: string; isPassed: boolean }>;
    diffs: Array<{
      __typename?: "ProjectDiff";
      projectDiffId: number;
      operation: ProjectOperation;
      fromPath: string;
      toPath: string;
      fromLink: string;
      toLink: string;
    }>;
  };
};

export type ProjectQuestionForProjectTestReportFragment = {
  __typename?: "ProjectQuestion";
  id: string;
  questionId: number;
  version: string;
  projectVersion: string;
  variant: ProjectQuestionVariant;
  titleEn: string | null;
  titleJa: string | null;
  difficulty: Difficulty;
  descriptionJa: string | null;
  descriptionEn: string | null;
  correctnessTestCase: string;
  performanceTestCase: string;
  timeLimitSeconds: number | null;
  isPrivate: boolean;
  numUses: number;
  accuracyRate: number;
  averageElapsedTimeSeconds: number;
  isOfficial: boolean;
  projectQuestionStatus: ProjectQuestionStatus;
  answers: Array<{
    __typename?: "ProjectAnswer";
    titleJa: string;
    titleEn: string;
    descriptionJa: string;
    descriptionEn: string;
    answerId: number;
  }>;
};

export type ProjectEntityForProjectTestReportFragment = {
  __typename?: "ProjectEntity";
  id: string;
  entityId: number;
  projectEntityId: number;
  totalScore: number;
  evaluatedAtSeconds: number | null;
  agentVersion: string;
  appealMessage: string | null;
  projectStatus: ProjectStatus;
  question: {
    __typename?: "ProjectQuestion";
    id: string;
    questionId: number;
    version: string;
    projectVersion: string;
    variant: ProjectQuestionVariant;
    titleEn: string | null;
    titleJa: string | null;
    difficulty: Difficulty;
    descriptionJa: string | null;
    descriptionEn: string | null;
    correctnessTestCase: string;
    performanceTestCase: string;
    timeLimitSeconds: number | null;
    isPrivate: boolean;
    numUses: number;
    accuracyRate: number;
    averageElapsedTimeSeconds: number;
    isOfficial: boolean;
    projectQuestionStatus: ProjectQuestionStatus;
    answers: Array<{
      __typename?: "ProjectAnswer";
      titleJa: string;
      titleEn: string;
      descriptionJa: string;
      descriptionEn: string;
      answerId: number;
    }>;
  } | null;
  submissions: Array<{
    __typename?: "ProjectSubmission";
    projectSubmissionId: number;
    numTests: number;
    numPassed: number;
    numTabEvents: number;
    numAccessEvents: number;
    totalScore: number;
    numSubset: number;
    isBest: boolean;
    testResult: string;
    avgLatency: number;
    avgSla: number;
    coverage: number;
    performance: number;
    correctnessTestResult: string;
    performanceTestResult: string;
    metrics: string;
    failureReason: ProjectFailureReason;
    totalElapsedTimeSeconds: number | null;
    elapsedTimeSeconds: number | null;
    evaluatedAtSeconds: number | null;
    submissionStatus: ProjectSubmissionStatus;
    snapshots: Array<{ __typename?: "ProjectSnapshot"; testCase: string; testResult: string; isPassed: boolean }>;
    diffs: Array<{
      __typename?: "ProjectDiff";
      projectDiffId: number;
      operation: ProjectOperation;
      fromPath: string;
      toPath: string;
      fromLink: string;
      toLink: string;
    }>;
  }>;
};

export type GetProjectEntityForProjectTestReportQueryVariables = Exact<{
  projectId: Scalars["Int"]["input"];
  withAnswers: Scalars["Boolean"]["input"];
}>;

export type GetProjectEntityForProjectTestReportQuery = {
  __typename?: "Query";
  projectEntity: {
    __typename?: "ProjectEntity";
    id: string;
    entityId: number;
    projectEntityId: number;
    totalScore: number;
    evaluatedAtSeconds: number | null;
    agentVersion: string;
    appealMessage: string | null;
    projectStatus: ProjectStatus;
    question: {
      __typename?: "ProjectQuestion";
      id: string;
      questionId: number;
      version: string;
      projectVersion: string;
      variant: ProjectQuestionVariant;
      titleEn: string | null;
      titleJa: string | null;
      difficulty: Difficulty;
      descriptionJa: string | null;
      descriptionEn: string | null;
      correctnessTestCase: string;
      performanceTestCase: string;
      timeLimitSeconds: number | null;
      isPrivate: boolean;
      numUses: number;
      accuracyRate: number;
      averageElapsedTimeSeconds: number;
      isOfficial: boolean;
      projectQuestionStatus: ProjectQuestionStatus;
      answers: Array<{
        __typename?: "ProjectAnswer";
        titleJa: string;
        titleEn: string;
        descriptionJa: string;
        descriptionEn: string;
        answerId: number;
      }>;
    } | null;
    submissions: Array<{
      __typename?: "ProjectSubmission";
      projectSubmissionId: number;
      numTests: number;
      numPassed: number;
      numTabEvents: number;
      numAccessEvents: number;
      totalScore: number;
      numSubset: number;
      isBest: boolean;
      testResult: string;
      avgLatency: number;
      avgSla: number;
      coverage: number;
      performance: number;
      correctnessTestResult: string;
      performanceTestResult: string;
      metrics: string;
      failureReason: ProjectFailureReason;
      totalElapsedTimeSeconds: number | null;
      elapsedTimeSeconds: number | null;
      evaluatedAtSeconds: number | null;
      submissionStatus: ProjectSubmissionStatus;
      snapshots: Array<{ __typename?: "ProjectSnapshot"; testCase: string; testResult: string; isPassed: boolean }>;
      diffs: Array<{
        __typename?: "ProjectDiff";
        projectDiffId: number;
        operation: ProjectOperation;
        fromPath: string;
        toPath: string;
        fromLink: string;
        toLink: string;
      }>;
    }>;
  };
};

export type SkillTagNodeForQuestionSearchAreaFragment = {
  __typename?: "SkillTagNode";
  id: string;
  skillTagNodeId: string;
  name: string;
  path: string;
};

export type HierarchicalSkillTagsResponseForQuestionSearchAreaFragment = {
  __typename?: "HierarchicalSkillTagsResponse";
  count: number;
  hasNext: boolean;
  skillTagNodes: Array<{ __typename?: "SkillTagNode"; id: string; skillTagNodeId: string; name: string; path: string }>;
};

export type GetHierarchicalSkillTagsForQuestionSearchAreaQueryVariables = Exact<{
  input: HierarchicalSkillTagsInput;
}>;

export type GetHierarchicalSkillTagsForQuestionSearchAreaQuery = {
  __typename?: "Query";
  hierarchicalSkillTags: {
    __typename?: "HierarchicalSkillTagsResponse";
    count: number;
    hasNext: boolean;
    skillTagNodes: Array<{ __typename?: "SkillTagNode"; id: string; skillTagNodeId: string; name: string; path: string }>;
  };
};

export type ListAlgorithmQuestionForQuestionSelectFieldForResourceEditorFragment = {
  __typename: "AlgorithmQuestion";
  questionId: number;
  version: string;
  key: string;
  titleJa: string | null;
  titleEn: string | null;
  supportedLanguages: Array<string>;
  difficulty: Difficulty;
  isPrivate: boolean;
  isOfficial: boolean;
  timeLimitSeconds: number | null;
  numUses: number;
  isSupportedLeakScore: boolean;
  accuracyRate: number;
  averageElapsedTimeSeconds: number;
  algorithmQuestionVariant: AlgorithmQuestionVariant;
  algorithmQuestionStatus: QuestionStatus;
  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
};

export type ListMultiChoiceQuestionForQuestionSelectFieldForResourceEditorFragment = {
  __typename: "MultiChoicePackage";
  packageId: number;
  version: string;
  key: string;
  titleJa: string | null;
  titleEn: string | null;
  difficulty: Difficulty;
  isPrivate: boolean;
  isOfficial: boolean;
  isSupportedLeakScore: boolean;
  timeLimitSeconds: number | null;
  numUses: number;
  accuracyRate: number;
  averageElapsedTimeSeconds: number;
  multiChoicePackageStatus: QuestionStatus;
  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
};

export type ListProjectQuestionForQuestionSelectFieldForResourceEditorFragment = {
  __typename: "ProjectQuestion";
  questionId: number;
  version: string;
  projectVersion: string;
  key: string;
  companyId: number;
  titleJa: string | null;
  titleEn: string | null;
  difficulty: Difficulty;
  isPrivate: boolean;
  isOfficial: boolean;
  isSupportedLeakScore: boolean;
  timeLimitSeconds: number | null;
  numUses: number;
  accuracyRate: number;
  averageElapsedTimeSeconds: number;
  projectQuestionVariant: ProjectQuestionVariant;
  projectQuestionStatus: ProjectQuestionStatus;
  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
};

export type ListSystemDesignQuestionForQuestionSelectFieldForResourceEditorFragment = {
  __typename: "SystemDesignQuestion";
  questionId: number;
  key: string;
  titleJa: string | null;
  titleEn: string | null;
  difficulty: Difficulty;
  isPrivate: boolean;
  isOfficial: boolean;
  isSupportedLeakScore: boolean;
  timeLimitSeconds: number | null;
  numUses: number;
  accuracyRate: number;
  averageElapsedTimeSeconds: number;
  systemDesignQuestionStatus: SystemDesignQuestionStatus;
  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
};

export type ListFreepadQuestionForQuestionSelectFieldForResourceEditorFragment = {
  __typename: "FreepadQuestion";
  key: string;
  freepadQuestionId: number;
  version: string;
  titleJa: string | null;
  titleEn: string | null;
  difficulty: Difficulty;
  isPrivate: boolean;
  isOfficial: boolean;
  isSupportedLeakScore: boolean;
  numUses: number;
  freepadQuestionStatus: QuestionStatus;
  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
};

type SpotQuestionForQuestionSelectFieldForResourceEditor_AlgorithmQuestion_Fragment = {
  __typename: "AlgorithmQuestion";
  questionId: number;
  version: string;
  key: string;
  titleJa: string | null;
  titleEn: string | null;
  supportedLanguages: Array<string>;
  difficulty: Difficulty;
  isPrivate: boolean;
  isOfficial: boolean;
  timeLimitSeconds: number | null;
  numUses: number;
  isSupportedLeakScore: boolean;
  accuracyRate: number;
  averageElapsedTimeSeconds: number;
  algorithmQuestionVariant: AlgorithmQuestionVariant;
  algorithmQuestionStatus: QuestionStatus;
  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
};

type SpotQuestionForQuestionSelectFieldForResourceEditor_MultiChoicePackage_Fragment = {
  __typename: "MultiChoicePackage";
  packageId: number;
  version: string;
  key: string;
  titleJa: string | null;
  titleEn: string | null;
  difficulty: Difficulty;
  isPrivate: boolean;
  isOfficial: boolean;
  isSupportedLeakScore: boolean;
  timeLimitSeconds: number | null;
  numUses: number;
  accuracyRate: number;
  averageElapsedTimeSeconds: number;
  multiChoicePackageStatus: QuestionStatus;
  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
};

type SpotQuestionForQuestionSelectFieldForResourceEditor_ProjectQuestion_Fragment = {
  __typename: "ProjectQuestion";
  questionId: number;
  version: string;
  projectVersion: string;
  key: string;
  companyId: number;
  titleJa: string | null;
  titleEn: string | null;
  difficulty: Difficulty;
  isPrivate: boolean;
  isOfficial: boolean;
  isSupportedLeakScore: boolean;
  timeLimitSeconds: number | null;
  numUses: number;
  accuracyRate: number;
  averageElapsedTimeSeconds: number;
  projectQuestionVariant: ProjectQuestionVariant;
  projectQuestionStatus: ProjectQuestionStatus;
  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
};

type SpotQuestionForQuestionSelectFieldForResourceEditor_SystemDesignQuestion_Fragment = {
  __typename: "SystemDesignQuestion";
  questionId: number;
  key: string;
  titleJa: string | null;
  titleEn: string | null;
  difficulty: Difficulty;
  isPrivate: boolean;
  isOfficial: boolean;
  isSupportedLeakScore: boolean;
  timeLimitSeconds: number | null;
  numUses: number;
  accuracyRate: number;
  averageElapsedTimeSeconds: number;
  systemDesignQuestionStatus: SystemDesignQuestionStatus;
  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
};

export type SpotQuestionForQuestionSelectFieldForResourceEditorFragment =
  | SpotQuestionForQuestionSelectFieldForResourceEditor_AlgorithmQuestion_Fragment
  | SpotQuestionForQuestionSelectFieldForResourceEditor_MultiChoicePackage_Fragment
  | SpotQuestionForQuestionSelectFieldForResourceEditor_ProjectQuestion_Fragment
  | SpotQuestionForQuestionSelectFieldForResourceEditor_SystemDesignQuestion_Fragment;

type LiveCodingQuestionForQuestionSelectFieldForResourceEditor_AlgorithmQuestion_Fragment = {
  __typename: "AlgorithmQuestion";
  questionId: number;
  version: string;
  key: string;
  titleJa: string | null;
  titleEn: string | null;
  supportedLanguages: Array<string>;
  difficulty: Difficulty;
  isPrivate: boolean;
  isOfficial: boolean;
  timeLimitSeconds: number | null;
  numUses: number;
  isSupportedLeakScore: boolean;
  accuracyRate: number;
  averageElapsedTimeSeconds: number;
  algorithmQuestionVariant: AlgorithmQuestionVariant;
  algorithmQuestionStatus: QuestionStatus;
  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
};

type LiveCodingQuestionForQuestionSelectFieldForResourceEditor_FreepadQuestion_Fragment = {
  __typename: "FreepadQuestion";
  key: string;
  freepadQuestionId: number;
  version: string;
  titleJa: string | null;
  titleEn: string | null;
  difficulty: Difficulty;
  isPrivate: boolean;
  isOfficial: boolean;
  isSupportedLeakScore: boolean;
  numUses: number;
  freepadQuestionStatus: QuestionStatus;
  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
};

type LiveCodingQuestionForQuestionSelectFieldForResourceEditor_SystemDesignQuestion_Fragment = {
  __typename: "SystemDesignQuestion";
  questionId: number;
  key: string;
  titleJa: string | null;
  titleEn: string | null;
  difficulty: Difficulty;
  isPrivate: boolean;
  isOfficial: boolean;
  isSupportedLeakScore: boolean;
  timeLimitSeconds: number | null;
  numUses: number;
  accuracyRate: number;
  averageElapsedTimeSeconds: number;
  systemDesignQuestionStatus: SystemDesignQuestionStatus;
  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
};

export type LiveCodingQuestionForQuestionSelectFieldForResourceEditorFragment =
  | LiveCodingQuestionForQuestionSelectFieldForResourceEditor_AlgorithmQuestion_Fragment
  | LiveCodingQuestionForQuestionSelectFieldForResourceEditor_FreepadQuestion_Fragment
  | LiveCodingQuestionForQuestionSelectFieldForResourceEditor_SystemDesignQuestion_Fragment;

export type QuestionObjectForQuestionSelectFieldForResourceEditorFragment = {
  __typename?: "QuestionObject";
  id: string;
  questionVariant: QuestionVariant;
  spotQuestion:
    | {
        __typename: "AlgorithmQuestion";
        questionId: number;
        version: string;
        key: string;
        titleJa: string | null;
        titleEn: string | null;
        supportedLanguages: Array<string>;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        timeLimitSeconds: number | null;
        numUses: number;
        isSupportedLeakScore: boolean;
        accuracyRate: number;
        averageElapsedTimeSeconds: number;
        algorithmQuestionVariant: AlgorithmQuestionVariant;
        algorithmQuestionStatus: QuestionStatus;
        leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
      }
    | {
        __typename: "MultiChoicePackage";
        packageId: number;
        version: string;
        key: string;
        titleJa: string | null;
        titleEn: string | null;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        isSupportedLeakScore: boolean;
        timeLimitSeconds: number | null;
        numUses: number;
        accuracyRate: number;
        averageElapsedTimeSeconds: number;
        multiChoicePackageStatus: QuestionStatus;
        leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
      }
    | {
        __typename: "ProjectQuestion";
        questionId: number;
        version: string;
        projectVersion: string;
        key: string;
        companyId: number;
        titleJa: string | null;
        titleEn: string | null;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        isSupportedLeakScore: boolean;
        timeLimitSeconds: number | null;
        numUses: number;
        accuracyRate: number;
        averageElapsedTimeSeconds: number;
        projectQuestionVariant: ProjectQuestionVariant;
        projectQuestionStatus: ProjectQuestionStatus;
        leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
      }
    | {
        __typename: "SystemDesignQuestion";
        questionId: number;
        key: string;
        titleJa: string | null;
        titleEn: string | null;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        isSupportedLeakScore: boolean;
        timeLimitSeconds: number | null;
        numUses: number;
        accuracyRate: number;
        averageElapsedTimeSeconds: number;
        systemDesignQuestionStatus: SystemDesignQuestionStatus;
        leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
      }
    | null;
  liveCodingQuestion:
    | {
        __typename: "AlgorithmQuestion";
        questionId: number;
        version: string;
        key: string;
        titleJa: string | null;
        titleEn: string | null;
        supportedLanguages: Array<string>;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        timeLimitSeconds: number | null;
        numUses: number;
        isSupportedLeakScore: boolean;
        accuracyRate: number;
        averageElapsedTimeSeconds: number;
        algorithmQuestionVariant: AlgorithmQuestionVariant;
        algorithmQuestionStatus: QuestionStatus;
        leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
      }
    | {
        __typename: "FreepadQuestion";
        key: string;
        freepadQuestionId: number;
        version: string;
        titleJa: string | null;
        titleEn: string | null;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        isSupportedLeakScore: boolean;
        numUses: number;
        freepadQuestionStatus: QuestionStatus;
        leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
      }
    | {
        __typename: "SystemDesignQuestion";
        questionId: number;
        key: string;
        titleJa: string | null;
        titleEn: string | null;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        isSupportedLeakScore: boolean;
        timeLimitSeconds: number | null;
        numUses: number;
        accuracyRate: number;
        averageElapsedTimeSeconds: number;
        systemDesignQuestionStatus: SystemDesignQuestionStatus;
        leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
      }
    | null;
};

export type QuestionsForQuestionSelectFieldForResourceEditorFragment = {
  __typename?: "Questions";
  count: number;
  hasNext: boolean;
  questions: Array<{
    __typename?: "QuestionObject";
    id: string;
    questionVariant: QuestionVariant;
    spotQuestion:
      | {
          __typename: "AlgorithmQuestion";
          questionId: number;
          version: string;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          supportedLanguages: Array<string>;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          isSupportedLeakScore: boolean;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          algorithmQuestionVariant: AlgorithmQuestionVariant;
          algorithmQuestionStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | {
          __typename: "MultiChoicePackage";
          packageId: number;
          version: string;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          multiChoicePackageStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | {
          __typename: "ProjectQuestion";
          questionId: number;
          version: string;
          projectVersion: string;
          key: string;
          companyId: number;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          projectQuestionVariant: ProjectQuestionVariant;
          projectQuestionStatus: ProjectQuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | {
          __typename: "SystemDesignQuestion";
          questionId: number;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          systemDesignQuestionStatus: SystemDesignQuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | null;
    liveCodingQuestion:
      | {
          __typename: "AlgorithmQuestion";
          questionId: number;
          version: string;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          supportedLanguages: Array<string>;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          isSupportedLeakScore: boolean;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          algorithmQuestionVariant: AlgorithmQuestionVariant;
          algorithmQuestionStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | {
          __typename: "FreepadQuestion";
          key: string;
          freepadQuestionId: number;
          version: string;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          numUses: number;
          freepadQuestionStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | {
          __typename: "SystemDesignQuestion";
          questionId: number;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          systemDesignQuestionStatus: SystemDesignQuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | null;
  }>;
};

export type ListQuestionsForQuestionSelectFieldForResourceEditorQueryVariables = Exact<{
  questionsInput: QuestionsInput;
}>;

export type ListQuestionsForQuestionSelectFieldForResourceEditorQuery = {
  __typename?: "Query";
  questions: {
    __typename?: "Questions";
    count: number;
    hasNext: boolean;
    questions: Array<{
      __typename?: "QuestionObject";
      id: string;
      questionVariant: QuestionVariant;
      spotQuestion:
        | {
            __typename: "AlgorithmQuestion";
            questionId: number;
            version: string;
            key: string;
            titleJa: string | null;
            titleEn: string | null;
            supportedLanguages: Array<string>;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            timeLimitSeconds: number | null;
            numUses: number;
            isSupportedLeakScore: boolean;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            algorithmQuestionVariant: AlgorithmQuestionVariant;
            algorithmQuestionStatus: QuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          }
        | {
            __typename: "MultiChoicePackage";
            packageId: number;
            version: string;
            key: string;
            titleJa: string | null;
            titleEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            timeLimitSeconds: number | null;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            multiChoicePackageStatus: QuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          }
        | {
            __typename: "ProjectQuestion";
            questionId: number;
            version: string;
            projectVersion: string;
            key: string;
            companyId: number;
            titleJa: string | null;
            titleEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            timeLimitSeconds: number | null;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            projectQuestionVariant: ProjectQuestionVariant;
            projectQuestionStatus: ProjectQuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          }
        | {
            __typename: "SystemDesignQuestion";
            questionId: number;
            key: string;
            titleJa: string | null;
            titleEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            timeLimitSeconds: number | null;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            systemDesignQuestionStatus: SystemDesignQuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          }
        | null;
      liveCodingQuestion:
        | {
            __typename: "AlgorithmQuestion";
            questionId: number;
            version: string;
            key: string;
            titleJa: string | null;
            titleEn: string | null;
            supportedLanguages: Array<string>;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            timeLimitSeconds: number | null;
            numUses: number;
            isSupportedLeakScore: boolean;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            algorithmQuestionVariant: AlgorithmQuestionVariant;
            algorithmQuestionStatus: QuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          }
        | {
            __typename: "FreepadQuestion";
            key: string;
            freepadQuestionId: number;
            version: string;
            titleJa: string | null;
            titleEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            numUses: number;
            freepadQuestionStatus: QuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          }
        | {
            __typename: "SystemDesignQuestion";
            questionId: number;
            key: string;
            titleJa: string | null;
            titleEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            timeLimitSeconds: number | null;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            systemDesignQuestionStatus: SystemDesignQuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          }
        | null;
    }>;
  };
};

export type QuestionPackageForQuestionSelectFieldForResourceEditorFragment = {
  __typename: "QuestionPackage";
  id: string;
  questionPackageId: string;
  difficulty: QuestionPackageDifficulty;
  titleJa: string;
  titleEn: string;
  descriptionJa: string;
  descriptionEn: string;
  questionObjects: Array<{
    __typename?: "QuestionObject";
    id: string;
    questionVariant: QuestionVariant;
    spotQuestion:
      | {
          __typename: "AlgorithmQuestion";
          questionId: number;
          version: string;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          supportedLanguages: Array<string>;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          isSupportedLeakScore: boolean;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          algorithmQuestionVariant: AlgorithmQuestionVariant;
          algorithmQuestionStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | {
          __typename: "MultiChoicePackage";
          packageId: number;
          version: string;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          multiChoicePackageStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | {
          __typename: "ProjectQuestion";
          questionId: number;
          version: string;
          projectVersion: string;
          key: string;
          companyId: number;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          projectQuestionVariant: ProjectQuestionVariant;
          projectQuestionStatus: ProjectQuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | {
          __typename: "SystemDesignQuestion";
          questionId: number;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          systemDesignQuestionStatus: SystemDesignQuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | null;
    liveCodingQuestion:
      | {
          __typename: "AlgorithmQuestion";
          questionId: number;
          version: string;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          supportedLanguages: Array<string>;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          isSupportedLeakScore: boolean;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          algorithmQuestionVariant: AlgorithmQuestionVariant;
          algorithmQuestionStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | {
          __typename: "FreepadQuestion";
          key: string;
          freepadQuestionId: number;
          version: string;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          numUses: number;
          freepadQuestionStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | {
          __typename: "SystemDesignQuestion";
          questionId: number;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          systemDesignQuestionStatus: SystemDesignQuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | null;
  }>;
};

export type QuestionPackagesResponseForQuestionSelectFieldForResourceEditorFragment = {
  __typename?: "QuestionPackagesResponse";
  count: number;
  hasNext: boolean;
  packages: Array<{
    __typename: "QuestionPackage";
    id: string;
    questionPackageId: string;
    difficulty: QuestionPackageDifficulty;
    titleJa: string;
    titleEn: string;
    descriptionJa: string;
    descriptionEn: string;
    questionObjects: Array<{
      __typename?: "QuestionObject";
      id: string;
      questionVariant: QuestionVariant;
      spotQuestion:
        | {
            __typename: "AlgorithmQuestion";
            questionId: number;
            version: string;
            key: string;
            titleJa: string | null;
            titleEn: string | null;
            supportedLanguages: Array<string>;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            timeLimitSeconds: number | null;
            numUses: number;
            isSupportedLeakScore: boolean;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            algorithmQuestionVariant: AlgorithmQuestionVariant;
            algorithmQuestionStatus: QuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          }
        | {
            __typename: "MultiChoicePackage";
            packageId: number;
            version: string;
            key: string;
            titleJa: string | null;
            titleEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            timeLimitSeconds: number | null;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            multiChoicePackageStatus: QuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          }
        | {
            __typename: "ProjectQuestion";
            questionId: number;
            version: string;
            projectVersion: string;
            key: string;
            companyId: number;
            titleJa: string | null;
            titleEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            timeLimitSeconds: number | null;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            projectQuestionVariant: ProjectQuestionVariant;
            projectQuestionStatus: ProjectQuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          }
        | {
            __typename: "SystemDesignQuestion";
            questionId: number;
            key: string;
            titleJa: string | null;
            titleEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            timeLimitSeconds: number | null;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            systemDesignQuestionStatus: SystemDesignQuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          }
        | null;
      liveCodingQuestion:
        | {
            __typename: "AlgorithmQuestion";
            questionId: number;
            version: string;
            key: string;
            titleJa: string | null;
            titleEn: string | null;
            supportedLanguages: Array<string>;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            timeLimitSeconds: number | null;
            numUses: number;
            isSupportedLeakScore: boolean;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            algorithmQuestionVariant: AlgorithmQuestionVariant;
            algorithmQuestionStatus: QuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          }
        | {
            __typename: "FreepadQuestion";
            key: string;
            freepadQuestionId: number;
            version: string;
            titleJa: string | null;
            titleEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            numUses: number;
            freepadQuestionStatus: QuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          }
        | {
            __typename: "SystemDesignQuestion";
            questionId: number;
            key: string;
            titleJa: string | null;
            titleEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            timeLimitSeconds: number | null;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            systemDesignQuestionStatus: SystemDesignQuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          }
        | null;
    }>;
  }>;
};

export type ListQuestionsPackagesForQuestionSelectFieldForResourceEditorQueryVariables = Exact<{
  size: Scalars["Int"]["input"];
  offset: Scalars["Int"]["input"];
  isDescending: Scalars["Boolean"]["input"];
  sortMethod: QuestionPackageSortMethod;
  filters: QuestionPackageFilters;
}>;

export type ListQuestionsPackagesForQuestionSelectFieldForResourceEditorQuery = {
  __typename?: "Query";
  questionPackages: {
    __typename?: "QuestionPackagesResponse";
    count: number;
    hasNext: boolean;
    packages: Array<{
      __typename: "QuestionPackage";
      id: string;
      questionPackageId: string;
      difficulty: QuestionPackageDifficulty;
      titleJa: string;
      titleEn: string;
      descriptionJa: string;
      descriptionEn: string;
      questionObjects: Array<{
        __typename?: "QuestionObject";
        id: string;
        questionVariant: QuestionVariant;
        spotQuestion:
          | {
              __typename: "AlgorithmQuestion";
              questionId: number;
              version: string;
              key: string;
              titleJa: string | null;
              titleEn: string | null;
              supportedLanguages: Array<string>;
              difficulty: Difficulty;
              isPrivate: boolean;
              isOfficial: boolean;
              timeLimitSeconds: number | null;
              numUses: number;
              isSupportedLeakScore: boolean;
              accuracyRate: number;
              averageElapsedTimeSeconds: number;
              algorithmQuestionVariant: AlgorithmQuestionVariant;
              algorithmQuestionStatus: QuestionStatus;
              leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
            }
          | {
              __typename: "MultiChoicePackage";
              packageId: number;
              version: string;
              key: string;
              titleJa: string | null;
              titleEn: string | null;
              difficulty: Difficulty;
              isPrivate: boolean;
              isOfficial: boolean;
              isSupportedLeakScore: boolean;
              timeLimitSeconds: number | null;
              numUses: number;
              accuracyRate: number;
              averageElapsedTimeSeconds: number;
              multiChoicePackageStatus: QuestionStatus;
              leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
            }
          | {
              __typename: "ProjectQuestion";
              questionId: number;
              version: string;
              projectVersion: string;
              key: string;
              companyId: number;
              titleJa: string | null;
              titleEn: string | null;
              difficulty: Difficulty;
              isPrivate: boolean;
              isOfficial: boolean;
              isSupportedLeakScore: boolean;
              timeLimitSeconds: number | null;
              numUses: number;
              accuracyRate: number;
              averageElapsedTimeSeconds: number;
              projectQuestionVariant: ProjectQuestionVariant;
              projectQuestionStatus: ProjectQuestionStatus;
              leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
            }
          | {
              __typename: "SystemDesignQuestion";
              questionId: number;
              key: string;
              titleJa: string | null;
              titleEn: string | null;
              difficulty: Difficulty;
              isPrivate: boolean;
              isOfficial: boolean;
              isSupportedLeakScore: boolean;
              timeLimitSeconds: number | null;
              numUses: number;
              accuracyRate: number;
              averageElapsedTimeSeconds: number;
              systemDesignQuestionStatus: SystemDesignQuestionStatus;
              leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
            }
          | null;
        liveCodingQuestion:
          | {
              __typename: "AlgorithmQuestion";
              questionId: number;
              version: string;
              key: string;
              titleJa: string | null;
              titleEn: string | null;
              supportedLanguages: Array<string>;
              difficulty: Difficulty;
              isPrivate: boolean;
              isOfficial: boolean;
              timeLimitSeconds: number | null;
              numUses: number;
              isSupportedLeakScore: boolean;
              accuracyRate: number;
              averageElapsedTimeSeconds: number;
              algorithmQuestionVariant: AlgorithmQuestionVariant;
              algorithmQuestionStatus: QuestionStatus;
              leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
            }
          | {
              __typename: "FreepadQuestion";
              key: string;
              freepadQuestionId: number;
              version: string;
              titleJa: string | null;
              titleEn: string | null;
              difficulty: Difficulty;
              isPrivate: boolean;
              isOfficial: boolean;
              isSupportedLeakScore: boolean;
              numUses: number;
              freepadQuestionStatus: QuestionStatus;
              leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
            }
          | {
              __typename: "SystemDesignQuestion";
              questionId: number;
              key: string;
              titleJa: string | null;
              titleEn: string | null;
              difficulty: Difficulty;
              isPrivate: boolean;
              isOfficial: boolean;
              isSupportedLeakScore: boolean;
              timeLimitSeconds: number | null;
              numUses: number;
              accuracyRate: number;
              averageElapsedTimeSeconds: number;
              systemDesignQuestionStatus: SystemDesignQuestionStatus;
              leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
            }
          | null;
      }>;
    }>;
  };
};

export type GetMultiQuestionsForQuestionSelectFieldForResourceEditorQueryVariables = Exact<{
  compositePrimaryKeys: Array<QuestionCompositePrimaryKey> | QuestionCompositePrimaryKey;
  withAnswers: Scalars["Boolean"]["input"];
}>;

export type GetMultiQuestionsForQuestionSelectFieldForResourceEditorQuery = {
  __typename?: "Query";
  multiQuestions: Array<{
    __typename?: "QuestionObject";
    id: string;
    questionVariant: QuestionVariant;
    spotQuestion:
      | {
          __typename: "AlgorithmQuestion";
          questionId: number;
          version: string;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          supportedLanguages: Array<string>;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          isSupportedLeakScore: boolean;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          algorithmQuestionVariant: AlgorithmQuestionVariant;
          algorithmQuestionStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | {
          __typename: "MultiChoicePackage";
          packageId: number;
          version: string;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          multiChoicePackageStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | {
          __typename: "ProjectQuestion";
          questionId: number;
          version: string;
          projectVersion: string;
          key: string;
          companyId: number;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          projectQuestionVariant: ProjectQuestionVariant;
          projectQuestionStatus: ProjectQuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | {
          __typename: "SystemDesignQuestion";
          questionId: number;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          systemDesignQuestionStatus: SystemDesignQuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | null;
    liveCodingQuestion:
      | {
          __typename: "AlgorithmQuestion";
          questionId: number;
          version: string;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          supportedLanguages: Array<string>;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          isSupportedLeakScore: boolean;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          algorithmQuestionVariant: AlgorithmQuestionVariant;
          algorithmQuestionStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | {
          __typename: "FreepadQuestion";
          key: string;
          freepadQuestionId: number;
          version: string;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          numUses: number;
          freepadQuestionStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | {
          __typename: "SystemDesignQuestion";
          questionId: number;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          systemDesignQuestionStatus: SystemDesignQuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        }
      | null;
  }>;
};

export type SkillTagNodeForQuestionSelectFieldForResourceEditorFragment = {
  __typename?: "SkillTagNode";
  id: string;
  skillTagNodeId: string;
  name: string;
  path: string;
};

export type HierarchicalSkillTagsResponseForQuestionSelectFieldForResourceEditorFragment = {
  __typename?: "HierarchicalSkillTagsResponse";
  count: number;
  hasNext: boolean;
  skillTagNodes: Array<{ __typename?: "SkillTagNode"; id: string; skillTagNodeId: string; name: string; path: string }>;
};

export type GetHierarchicalSkillTagsForQuestionSelectFieldForResourceEditorQueryVariables = Exact<{
  input: HierarchicalSkillTagsInput;
}>;

export type GetHierarchicalSkillTagsForQuestionSelectFieldForResourceEditorQuery = {
  __typename?: "Query";
  hierarchicalSkillTags: {
    __typename?: "HierarchicalSkillTagsResponse";
    count: number;
    hasNext: boolean;
    skillTagNodes: Array<{ __typename?: "SkillTagNode"; id: string; skillTagNodeId: string; name: string; path: string }>;
  };
};

export type StartQuizForQuizCodingEditorMutationVariables = Exact<{
  quizId: Scalars["Int"]["input"];
  candidateId: Scalars["String"]["input"];
}>;

export type StartQuizForQuizCodingEditorMutation = {
  __typename?: "Mutation";
  startQuiz: {
    __typename: "QuizEntity";
    quizEntityId: number;
    packageVersion: string;
    quizStatus: QuizStatus;
    pb_package: {
      __typename?: "MultiChoicePackage";
      packageId: number;
      version: string;
      titleJa: string | null;
      titleEn: string | null;
      descriptionJa: string | null;
      descriptionEn: string | null;
      questions: Array<{
        __typename?: "MultiChoiceQuestion";
        id: number;
        version: string;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        variant: MultiChoiceQuestionVariant;
        options: Array<{ __typename?: "MultiChoiceOption"; id: number; titleJa: string | null; titleEn: string | null; isCorrect: boolean }>;
      }>;
    } | null;
    submissions: Array<{
      __typename?: "QuizSubmission";
      quizSubmissionId: number;
      questionId: number;
      questionVersion: string;
      optionIds: Array<number>;
    }>;
  };
};

export type SubmitQuizQuestionForQuizCodingEditorMutationVariables = Exact<{
  input: SubmitQuizQuestionInput;
}>;

export type SubmitQuizQuestionForQuizCodingEditorMutation = {
  __typename?: "Mutation";
  submitQuizQuestion: { __typename?: "QuizSubmission"; questionId: number };
};

export type TranslateMarkdownForQuizResourceEditorMutationVariables = Exact<{
  source: TranslateSource;
}>;

export type TranslateMarkdownForQuizResourceEditorMutation = { __typename?: "Mutation"; translateMarkdown: string };

export type StatisticsStatsForQuizRankVisualizerFragment = {
  __typename?: "StatisticsStats";
  avg: number;
  max: number;
  min: number;
  p25: number;
  p50: number;
  p75: number;
  p95: number;
  std: number;
};

export type QuizStatisticsForQuizRankVisualizerFragment = {
  __typename?: "QuizStatistics";
  bins: Array<number>;
  totalScore: {
    __typename?: "StatisticsStats";
    avg: number;
    max: number;
    min: number;
    p25: number;
    p50: number;
    p75: number;
    p95: number;
    std: number;
  } | null;
  numTabEvents: {
    __typename?: "StatisticsStats";
    avg: number;
    max: number;
    min: number;
    p25: number;
    p50: number;
    p75: number;
    p95: number;
    std: number;
  } | null;
  elapsedTime: {
    __typename?: "StatisticsStats";
    avg: number;
    max: number;
    min: number;
    p25: number;
    p50: number;
    p75: number;
    p95: number;
    std: number;
  } | null;
};

export type GetQuizStatisticsQueryVariables = Exact<{
  statisticsSource: StatisticsSourceInput;
  rankSource: RankSourceInput;
}>;

export type GetQuizStatisticsQuery = {
  __typename?: "Query";
  statistics: {
    __typename?: "Statistics";
    quizStatistics: {
      __typename?: "QuizStatistics";
      bins: Array<number>;
      totalScore: {
        __typename?: "StatisticsStats";
        avg: number;
        max: number;
        min: number;
        p25: number;
        p50: number;
        p75: number;
        p95: number;
        std: number;
      } | null;
      numTabEvents: {
        __typename?: "StatisticsStats";
        avg: number;
        max: number;
        min: number;
        p25: number;
        p50: number;
        p75: number;
        p95: number;
        std: number;
      } | null;
      elapsedTime: {
        __typename?: "StatisticsStats";
        avg: number;
        max: number;
        min: number;
        p25: number;
        p50: number;
        p75: number;
        p95: number;
        std: number;
      } | null;
    } | null;
  };
  rank: { __typename?: "StatisticsRank"; evaluation: StatisticsEvaluation; numSubset: number; rank: number; relativeScore: number };
};

export type MultiChoiceEvaluationItemForQuizTestReportFragment = {
  __typename?: "MultiChoiceEvaluationItem";
  id: number;
  questionId: number;
  questionVersion: string;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
};

export type QuizEvaluationForQuizTestReportFragment = {
  __typename?: "QuizEvaluation";
  submissionId: number;
  score: number;
  isPassed: boolean;
  reasonJa: string;
  reasonEn: string;
  evaluationItem: {
    __typename?: "MultiChoiceEvaluationItem";
    id: number;
    questionId: number;
    questionVersion: string;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
  } | null;
};

export type QuizSubmissionForQuizTestReportFragment = {
  __typename?: "QuizSubmission";
  id: string;
  quizSubmissionId: number;
  quizId: number;
  packageId: number;
  packageVersion: string;
  questionId: number;
  questionVersion: string;
  optionIds: Array<number>;
  isCorrect: boolean;
  answerText: string;
  score: number;
  evaluatedBy: string;
  isAutomaticEvaluation: boolean;
  evaluatedAtSeconds: number | null;
  numTabEvents: number;
  numAccessEvents: number;
  elapsedTimeSeconds: number | null;
  createdAtSeconds: number | null;
  quizSubmissionStatus: QuizSubmissionStatus;
  evaluations: Array<{
    __typename?: "QuizEvaluation";
    submissionId: number;
    score: number;
    isPassed: boolean;
    reasonJa: string;
    reasonEn: string;
    evaluationItem: {
      __typename?: "MultiChoiceEvaluationItem";
      id: number;
      questionId: number;
      questionVersion: string;
      titleJa: string | null;
      titleEn: string | null;
      descriptionJa: string | null;
      descriptionEn: string | null;
    } | null;
  }>;
  evaluatedUser: { __typename?: "User"; uid: string; displayName: string } | null;
};

export type MultiChoiceQuestionForQuizTestReportFragment = {
  __typename?: "MultiChoiceQuestion";
  id: number;
  version: string;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  correctAnswerRate: number;
  variant: MultiChoiceQuestionVariant;
  options: Array<{
    __typename?: "MultiChoiceOption";
    id: number;
    titleJa: string | null;
    titleEn: string | null;
    isCorrect: boolean;
    additionalDetailJa: string | null;
    additionalDetailEn: string | null;
  }>;
  evaluationItems: Array<{
    __typename?: "MultiChoiceEvaluationItem";
    id: number;
    questionId: number;
    questionVersion: string;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
  }>;
};

export type MultiChoiceOptionForQuizTestReportFragment = {
  __typename?: "MultiChoiceOption";
  id: number;
  titleJa: string | null;
  titleEn: string | null;
  isCorrect: boolean;
  additionalDetailJa: string | null;
  additionalDetailEn: string | null;
};

export type QuizEntityForQuizTestReportFragment = {
  __typename?: "QuizEntity";
  id: string;
  entityId: number;
  quizEntityId: number;
  totalScore: number;
  numSubset: number;
  timeLimitSeconds: number | null;
  evaluatedAtSeconds: number | null;
  elapsedTimeSeconds: number | null;
  quizStatus: QuizStatus;
  pb_package: {
    __typename?: "MultiChoicePackage";
    packageId: number;
    version: string;
    difficulty: Difficulty;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    numUses: number;
    timeLimitSeconds: number | null;
    accuracyRate: number;
    averageElapsedTimeSeconds: number;
    isOfficial: boolean;
    isPrivate: boolean;
    multiChoicePackageStatus: QuestionStatus;
    employee: { __typename?: "User"; id: string; displayName: string };
    questions: Array<{
      __typename?: "MultiChoiceQuestion";
      id: number;
      version: string;
      titleJa: string | null;
      titleEn: string | null;
      descriptionJa: string | null;
      descriptionEn: string | null;
      correctAnswerRate: number;
      variant: MultiChoiceQuestionVariant;
      options: Array<{
        __typename?: "MultiChoiceOption";
        id: number;
        titleJa: string | null;
        titleEn: string | null;
        isCorrect: boolean;
        additionalDetailJa: string | null;
        additionalDetailEn: string | null;
      }>;
      evaluationItems: Array<{
        __typename?: "MultiChoiceEvaluationItem";
        id: number;
        questionId: number;
        questionVersion: string;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
      }>;
    }>;
  } | null;
  submissions: Array<{
    __typename?: "QuizSubmission";
    id: string;
    quizSubmissionId: number;
    quizId: number;
    packageId: number;
    packageVersion: string;
    questionId: number;
    questionVersion: string;
    optionIds: Array<number>;
    isCorrect: boolean;
    answerText: string;
    score: number;
    evaluatedBy: string;
    isAutomaticEvaluation: boolean;
    evaluatedAtSeconds: number | null;
    numTabEvents: number;
    numAccessEvents: number;
    elapsedTimeSeconds: number | null;
    createdAtSeconds: number | null;
    quizSubmissionStatus: QuizSubmissionStatus;
    evaluations: Array<{
      __typename?: "QuizEvaluation";
      submissionId: number;
      score: number;
      isPassed: boolean;
      reasonJa: string;
      reasonEn: string;
      evaluationItem: {
        __typename?: "MultiChoiceEvaluationItem";
        id: number;
        questionId: number;
        questionVersion: string;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
      } | null;
    }>;
    evaluatedUser: { __typename?: "User"; uid: string; displayName: string } | null;
  }>;
};

export type GetQuizEntityForQuizTestReportQueryVariables = Exact<{
  quizId: Scalars["Int"]["input"];
  withAnswers: Scalars["Boolean"]["input"];
}>;

export type GetQuizEntityForQuizTestReportQuery = {
  __typename?: "Query";
  quizEntity: {
    __typename?: "QuizEntity";
    id: string;
    entityId: number;
    quizEntityId: number;
    totalScore: number;
    numSubset: number;
    timeLimitSeconds: number | null;
    evaluatedAtSeconds: number | null;
    elapsedTimeSeconds: number | null;
    quizStatus: QuizStatus;
    pb_package: {
      __typename?: "MultiChoicePackage";
      packageId: number;
      version: string;
      difficulty: Difficulty;
      titleJa: string | null;
      titleEn: string | null;
      descriptionJa: string | null;
      descriptionEn: string | null;
      numUses: number;
      timeLimitSeconds: number | null;
      accuracyRate: number;
      averageElapsedTimeSeconds: number;
      isOfficial: boolean;
      isPrivate: boolean;
      multiChoicePackageStatus: QuestionStatus;
      employee: { __typename?: "User"; id: string; displayName: string };
      questions: Array<{
        __typename?: "MultiChoiceQuestion";
        id: number;
        version: string;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        correctAnswerRate: number;
        variant: MultiChoiceQuestionVariant;
        options: Array<{
          __typename?: "MultiChoiceOption";
          id: number;
          titleJa: string | null;
          titleEn: string | null;
          isCorrect: boolean;
          additionalDetailJa: string | null;
          additionalDetailEn: string | null;
        }>;
        evaluationItems: Array<{
          __typename?: "MultiChoiceEvaluationItem";
          id: number;
          questionId: number;
          questionVersion: string;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
        }>;
      }>;
    } | null;
    submissions: Array<{
      __typename?: "QuizSubmission";
      id: string;
      quizSubmissionId: number;
      quizId: number;
      packageId: number;
      packageVersion: string;
      questionId: number;
      questionVersion: string;
      optionIds: Array<number>;
      isCorrect: boolean;
      answerText: string;
      score: number;
      evaluatedBy: string;
      isAutomaticEvaluation: boolean;
      evaluatedAtSeconds: number | null;
      numTabEvents: number;
      numAccessEvents: number;
      elapsedTimeSeconds: number | null;
      createdAtSeconds: number | null;
      quizSubmissionStatus: QuizSubmissionStatus;
      evaluations: Array<{
        __typename?: "QuizEvaluation";
        submissionId: number;
        score: number;
        isPassed: boolean;
        reasonJa: string;
        reasonEn: string;
        evaluationItem: {
          __typename?: "MultiChoiceEvaluationItem";
          id: number;
          questionId: number;
          questionVersion: string;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
        } | null;
      }>;
      evaluatedUser: { __typename?: "User"; uid: string; displayName: string } | null;
    }>;
  };
};

export type EvaluateQuizSubmissionMutationVariables = Exact<{
  input: EvaluateQuizSubmissionInput;
}>;

export type EvaluateQuizSubmissionMutation = {
  __typename?: "Mutation";
  evaluateQuizSubmission: {
    __typename?: "QuizSubmission";
    id: string;
    quizSubmissionId: number;
    quizId: number;
    packageId: number;
    packageVersion: string;
    questionId: number;
    questionVersion: string;
    optionIds: Array<number>;
    isCorrect: boolean;
    answerText: string;
    score: number;
    evaluatedBy: string;
    isAutomaticEvaluation: boolean;
    evaluatedAtSeconds: number | null;
    numTabEvents: number;
    numAccessEvents: number;
    elapsedTimeSeconds: number | null;
    createdAtSeconds: number | null;
    quizSubmissionStatus: QuizSubmissionStatus;
    evaluations: Array<{
      __typename?: "QuizEvaluation";
      submissionId: number;
      score: number;
      isPassed: boolean;
      reasonJa: string;
      reasonEn: string;
      evaluationItem: {
        __typename?: "MultiChoiceEvaluationItem";
        id: number;
        questionId: number;
        questionVersion: string;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
      } | null;
    }>;
    evaluatedUser: { __typename?: "User"; uid: string; displayName: string } | null;
  };
};

export type RecommendationForRemoteInterviewFeedbackFragment = {
  __typename?: "EvaluationRecommendation";
  id: string;
  employeeId: string;
  evaluationRecommendationId: number;
  isRecommended: boolean;
  createdAtSeconds: number | null;
  comment: string;
  evaluations: Array<{
    __typename?: "Evaluation";
    id: string;
    evaluationId: number;
    employeeId: string;
    metricId: number;
    comment: string;
    numStars: number;
    metric: { __typename?: "EvaluationMetric"; id: string; title: string; metricId: number; description: string } | null;
  }>;
  reviewer: { __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string };
};

export type RemoteForRemoteInterviewFeedbackFragment = {
  __typename?: "Remote";
  id: string;
  remoteId: string;
  recommendations: Array<{
    __typename?: "EvaluationRecommendation";
    id: string;
    employeeId: string;
    evaluationRecommendationId: number;
    isRecommended: boolean;
    createdAtSeconds: number | null;
    comment: string;
    evaluations: Array<{
      __typename?: "Evaluation";
      id: string;
      evaluationId: number;
      employeeId: string;
      metricId: number;
      comment: string;
      numStars: number;
      metric: { __typename?: "EvaluationMetric"; id: string; title: string; metricId: number; description: string } | null;
    }>;
    reviewer: { __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string };
  }>;
};

export type GetRemoteForRemoteInterviewFeedbackQueryVariables = Exact<{
  remoteId: Scalars["String"]["input"];
}>;

export type GetRemoteForRemoteInterviewFeedbackQuery = {
  __typename?: "Query";
  remote: {
    __typename?: "Remote";
    id: string;
    remoteId: string;
    recommendations: Array<{
      __typename?: "EvaluationRecommendation";
      id: string;
      employeeId: string;
      evaluationRecommendationId: number;
      isRecommended: boolean;
      createdAtSeconds: number | null;
      comment: string;
      evaluations: Array<{
        __typename?: "Evaluation";
        id: string;
        evaluationId: number;
        employeeId: string;
        metricId: number;
        comment: string;
        numStars: number;
        metric: { __typename?: "EvaluationMetric"; id: string; title: string; metricId: number; description: string } | null;
      }>;
      reviewer: { __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string };
    }>;
  };
};

export type UserForRemoteInterviewOverviewFragment = {
  __typename?: "User";
  id: string;
  uid: string;
  displayName: string;
  photoUrl: string;
  email: string;
  isSystemAdmin: boolean;
};

export type SessionForRemoteInterviewOverviewFragment = {
  __typename?: "LiveCodingSession";
  id: string;
  liveCodingSessionId: number;
  questionType: LiveCodingQuestionType;
  questionVariant: LiveCodingQuestionVariant | null;
  algorithmQuestion: {
    __typename?: "AlgorithmQuestion";
    id: string;
    questionId: number;
    status: QuestionStatus;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    version: string;
    difficulty: Difficulty;
  } | null;
  systemDesignQuestion: {
    __typename?: "SystemDesignQuestion";
    id: string;
    questionId: number;
    status: SystemDesignQuestionStatus;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    difficulty: Difficulty;
  } | null;
  freepadQuestion: {
    __typename?: "FreepadQuestion";
    id: string;
    freepadQuestionId: number;
    status: QuestionStatus;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    version: string;
    difficulty: Difficulty;
    variant: FreepadQuestionVariant;
  } | null;
};

export type RemoteForRemoteInterviewOverviewFragment = {
  __typename?: "Remote";
  id: string;
  remoteId: string;
  isPublic: boolean;
  isPassed: boolean;
  memo: string | null;
  name: string;
  status: RemoteStatus;
  candidateName: string;
  evaluationComment: string;
  createdAtSeconds: number | null;
  didStartAtSeconds: number | null;
  didEndAtSeconds: number | null;
  evaluatedAtSeconds: number | null;
  creator: {
    __typename?: "User";
    id: string;
    uid: string;
    displayName: string;
    photoUrl: string;
    email: string;
    isSystemAdmin: boolean;
  } | null;
  entity: {
    __typename?: "RemoteEntity";
    entityType: RemoteEntityType;
    liveCoding: {
      __typename?: "LiveCoding";
      id: string;
      sessions: Array<{
        __typename?: "LiveCodingSession";
        id: string;
        liveCodingSessionId: number;
        questionType: LiveCodingQuestionType;
        questionVariant: LiveCodingQuestionVariant | null;
        algorithmQuestion: {
          __typename?: "AlgorithmQuestion";
          id: string;
          questionId: number;
          status: QuestionStatus;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          version: string;
          difficulty: Difficulty;
        } | null;
        systemDesignQuestion: {
          __typename?: "SystemDesignQuestion";
          id: string;
          questionId: number;
          status: SystemDesignQuestionStatus;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          difficulty: Difficulty;
        } | null;
        freepadQuestion: {
          __typename?: "FreepadQuestion";
          id: string;
          freepadQuestionId: number;
          status: QuestionStatus;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          version: string;
          difficulty: Difficulty;
          variant: FreepadQuestionVariant;
        } | null;
      } | null>;
    } | null;
  } | null;
  evaluatedBy: {
    __typename?: "User";
    id: string;
    uid: string;
    displayName: string;
    photoUrl: string;
    email: string;
    isSystemAdmin: boolean;
  } | null;
  tags: Array<{ __typename: "RemoteTag"; name: string }>;
  viewers: Array<
    | {
        __typename: "EmployeeGroup";
        id: string;
        groupName: string;
        companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string; role: Role }>;
      }
    | { __typename: "User"; id: string; uid: string; displayName: string; photoUrl: string; email: string; isSystemAdmin: boolean }
  >;
};

export type GetRemoteForRemoteInterviewOverviewQueryVariables = Exact<{
  remoteId: Scalars["String"]["input"];
}>;

export type GetRemoteForRemoteInterviewOverviewQuery = {
  __typename?: "Query";
  remote: {
    __typename?: "Remote";
    id: string;
    remoteId: string;
    isPublic: boolean;
    isPassed: boolean;
    memo: string | null;
    name: string;
    status: RemoteStatus;
    candidateName: string;
    evaluationComment: string;
    createdAtSeconds: number | null;
    didStartAtSeconds: number | null;
    didEndAtSeconds: number | null;
    evaluatedAtSeconds: number | null;
    creator: {
      __typename?: "User";
      id: string;
      uid: string;
      displayName: string;
      photoUrl: string;
      email: string;
      isSystemAdmin: boolean;
    } | null;
    entity: {
      __typename?: "RemoteEntity";
      entityType: RemoteEntityType;
      liveCoding: {
        __typename?: "LiveCoding";
        id: string;
        sessions: Array<{
          __typename?: "LiveCodingSession";
          id: string;
          liveCodingSessionId: number;
          questionType: LiveCodingQuestionType;
          questionVariant: LiveCodingQuestionVariant | null;
          algorithmQuestion: {
            __typename?: "AlgorithmQuestion";
            id: string;
            questionId: number;
            status: QuestionStatus;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            version: string;
            difficulty: Difficulty;
          } | null;
          systemDesignQuestion: {
            __typename?: "SystemDesignQuestion";
            id: string;
            questionId: number;
            status: SystemDesignQuestionStatus;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
          } | null;
          freepadQuestion: {
            __typename?: "FreepadQuestion";
            id: string;
            freepadQuestionId: number;
            status: QuestionStatus;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            version: string;
            difficulty: Difficulty;
            variant: FreepadQuestionVariant;
          } | null;
        } | null>;
      } | null;
    } | null;
    evaluatedBy: {
      __typename?: "User";
      id: string;
      uid: string;
      displayName: string;
      photoUrl: string;
      email: string;
      isSystemAdmin: boolean;
    } | null;
    tags: Array<{ __typename: "RemoteTag"; name: string }>;
    viewers: Array<
      | {
          __typename: "EmployeeGroup";
          id: string;
          groupName: string;
          companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string; role: Role }>;
        }
      | { __typename: "User"; id: string; uid: string; displayName: string; photoUrl: string; email: string; isSystemAdmin: boolean }
    >;
  };
};

export type UpdateRemoteForRemoteInterviewOverviewMutationVariables = Exact<{
  input: UpdateRemoteInput;
}>;

export type UpdateRemoteForRemoteInterviewOverviewMutation = {
  __typename?: "Mutation";
  updateRemote: {
    __typename?: "Remote";
    id: string;
    remoteId: string;
    isPublic: boolean;
    isPassed: boolean;
    memo: string | null;
    name: string;
    status: RemoteStatus;
    candidateName: string;
    evaluationComment: string;
    createdAtSeconds: number | null;
    didStartAtSeconds: number | null;
    didEndAtSeconds: number | null;
    evaluatedAtSeconds: number | null;
    creator: {
      __typename?: "User";
      id: string;
      uid: string;
      displayName: string;
      photoUrl: string;
      email: string;
      isSystemAdmin: boolean;
    } | null;
    entity: {
      __typename?: "RemoteEntity";
      entityType: RemoteEntityType;
      liveCoding: {
        __typename?: "LiveCoding";
        id: string;
        sessions: Array<{
          __typename?: "LiveCodingSession";
          id: string;
          liveCodingSessionId: number;
          questionType: LiveCodingQuestionType;
          questionVariant: LiveCodingQuestionVariant | null;
          algorithmQuestion: {
            __typename?: "AlgorithmQuestion";
            id: string;
            questionId: number;
            status: QuestionStatus;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            version: string;
            difficulty: Difficulty;
          } | null;
          systemDesignQuestion: {
            __typename?: "SystemDesignQuestion";
            id: string;
            questionId: number;
            status: SystemDesignQuestionStatus;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
          } | null;
          freepadQuestion: {
            __typename?: "FreepadQuestion";
            id: string;
            freepadQuestionId: number;
            status: QuestionStatus;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            version: string;
            difficulty: Difficulty;
            variant: FreepadQuestionVariant;
          } | null;
        } | null>;
      } | null;
    } | null;
    evaluatedBy: {
      __typename?: "User";
      id: string;
      uid: string;
      displayName: string;
      photoUrl: string;
      email: string;
      isSystemAdmin: boolean;
    } | null;
    tags: Array<{ __typename: "RemoteTag"; name: string }>;
    viewers: Array<
      | {
          __typename: "EmployeeGroup";
          id: string;
          groupName: string;
          companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string; role: Role }>;
        }
      | { __typename: "User"; id: string; uid: string; displayName: string; photoUrl: string; email: string; isSystemAdmin: boolean }
    >;
  };
};

export type ListRemoteTagsForRemoteInterviewOverviewQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  cursorSeconds: InputMaybe<Scalars["Int"]["input"]>;
  size: Scalars["Int"]["input"];
  withCount: Scalars["Boolean"]["input"];
}>;

export type ListRemoteTagsForRemoteInterviewOverviewQuery = {
  __typename?: "Query";
  remoteTagsByCompanyId: {
    __typename?: "RemoteTagsByCompanyIdResponse";
    count: number;
    tags: Array<{ __typename?: "RemoteTag"; name: string }>;
  };
};

export type EmployeeForRemoteInterviewParticipantsFragment = { __typename?: "User"; id: string; photoUrl: string };

export type GetMultiEmployeesQueryVariables = Exact<{
  uids: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type GetMultiEmployeesQuery = { __typename?: "Query"; employees: Array<{ __typename?: "User"; id: string; photoUrl: string }> };

export type SessionForRemoteInterviewPlaybackFragment = {
  __typename?: "LiveCodingSession";
  id: string;
  liveCodingId: number;
  liveCodingSessionId: number;
  questionVariant: LiveCodingQuestionVariant | null;
  questionType: LiveCodingQuestionType;
  algorithmQuestion: {
    __typename?: "AlgorithmQuestion";
    id: string;
    questionId: number;
    status: QuestionStatus;
    version: string;
    variant: AlgorithmQuestionVariant;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    difficulty: Difficulty;
    answers: Array<{
      __typename?: "AlgorithmAnswer";
      id: number;
      titleJa: string | null;
      titleEn: string | null;
      descriptionJa: string | null;
      descriptionEn: string | null;
      label: string;
    }>;
  } | null;
  systemDesignQuestion: {
    __typename?: "SystemDesignQuestion";
    id: string;
    questionId: number;
    status: SystemDesignQuestionStatus;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    difficulty: Difficulty;
    answers: Array<{
      __typename?: "SystemDesignAnswer";
      id: string;
      titleJa: string;
      titleEn: string;
      descriptionJa: string;
      descriptionEn: string;
    }>;
  } | null;
  freepadQuestion: {
    __typename?: "FreepadQuestion";
    id: string;
    freepadQuestionId: number;
    status: QuestionStatus;
    titleJa: string | null;
    titleEn: string | null;
    descriptionJa: string | null;
    descriptionEn: string | null;
    version: string;
    difficulty: Difficulty;
    variant: FreepadQuestionVariant;
    answers: Array<{
      __typename?: "FreepadAnswer";
      id: string;
      titleJa: string | null;
      titleEn: string | null;
      descriptionJa: string | null;
      descriptionEn: string | null;
      label: string;
      freepadAnswerId: number;
      answerCodes: Array<{ __typename?: "FreepadAnswerCode"; id: string; freepadAnswerCodeId: number; runtime: string; body: string }>;
      answerFlowCharts: Array<{
        __typename?: "FreepadAnswerFlowChart";
        id: string;
        freepadAnswerFlowChartId: number;
        componentType: SystemDesignComponentType;
        body: string;
      }>;
    }>;
  } | null;
};

export type ParticipantForRemoteInterviewPlaybackFragment = {
  __typename?: "LiveCodingParticipant";
  userId: string;
  liveCodingId: number;
  userName: string;
  userType: LiveCodingUserType;
};

export type RemoteForRemoteInterviewPlaybackFragment = {
  __typename?: "Remote";
  id: string;
  remoteId: string;
  status: RemoteStatus;
  entity: {
    __typename?: "RemoteEntity";
    entityType: RemoteEntityType;
    liveCoding: {
      __typename?: "LiveCoding";
      id: string;
      liveCodingId: number;
      sessions: Array<{
        __typename?: "LiveCodingSession";
        id: string;
        liveCodingId: number;
        liveCodingSessionId: number;
        questionVariant: LiveCodingQuestionVariant | null;
        questionType: LiveCodingQuestionType;
        algorithmQuestion: {
          __typename?: "AlgorithmQuestion";
          id: string;
          questionId: number;
          status: QuestionStatus;
          version: string;
          variant: AlgorithmQuestionVariant;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          difficulty: Difficulty;
          answers: Array<{
            __typename?: "AlgorithmAnswer";
            id: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            label: string;
          }>;
        } | null;
        systemDesignQuestion: {
          __typename?: "SystemDesignQuestion";
          id: string;
          questionId: number;
          status: SystemDesignQuestionStatus;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          difficulty: Difficulty;
          answers: Array<{
            __typename?: "SystemDesignAnswer";
            id: string;
            titleJa: string;
            titleEn: string;
            descriptionJa: string;
            descriptionEn: string;
          }>;
        } | null;
        freepadQuestion: {
          __typename?: "FreepadQuestion";
          id: string;
          freepadQuestionId: number;
          status: QuestionStatus;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          version: string;
          difficulty: Difficulty;
          variant: FreepadQuestionVariant;
          answers: Array<{
            __typename?: "FreepadAnswer";
            id: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            label: string;
            freepadAnswerId: number;
            answerCodes: Array<{ __typename?: "FreepadAnswerCode"; id: string; freepadAnswerCodeId: number; runtime: string; body: string }>;
            answerFlowCharts: Array<{
              __typename?: "FreepadAnswerFlowChart";
              id: string;
              freepadAnswerFlowChartId: number;
              componentType: SystemDesignComponentType;
              body: string;
            }>;
          }>;
        } | null;
      } | null>;
      participants: Array<{
        __typename?: "LiveCodingParticipant";
        userId: string;
        liveCodingId: number;
        userName: string;
        userType: LiveCodingUserType;
      }>;
    } | null;
  } | null;
};

export type GetRemoteForRemoteInterviewPlaybackQueryVariables = Exact<{
  remoteId: Scalars["String"]["input"];
}>;

export type GetRemoteForRemoteInterviewPlaybackQuery = {
  __typename?: "Query";
  remote: {
    __typename?: "Remote";
    id: string;
    remoteId: string;
    status: RemoteStatus;
    entity: {
      __typename?: "RemoteEntity";
      entityType: RemoteEntityType;
      liveCoding: {
        __typename?: "LiveCoding";
        id: string;
        liveCodingId: number;
        sessions: Array<{
          __typename?: "LiveCodingSession";
          id: string;
          liveCodingId: number;
          liveCodingSessionId: number;
          questionVariant: LiveCodingQuestionVariant | null;
          questionType: LiveCodingQuestionType;
          algorithmQuestion: {
            __typename?: "AlgorithmQuestion";
            id: string;
            questionId: number;
            status: QuestionStatus;
            version: string;
            variant: AlgorithmQuestionVariant;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            answers: Array<{
              __typename?: "AlgorithmAnswer";
              id: number;
              titleJa: string | null;
              titleEn: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
              label: string;
            }>;
          } | null;
          systemDesignQuestion: {
            __typename?: "SystemDesignQuestion";
            id: string;
            questionId: number;
            status: SystemDesignQuestionStatus;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            answers: Array<{
              __typename?: "SystemDesignAnswer";
              id: string;
              titleJa: string;
              titleEn: string;
              descriptionJa: string;
              descriptionEn: string;
            }>;
          } | null;
          freepadQuestion: {
            __typename?: "FreepadQuestion";
            id: string;
            freepadQuestionId: number;
            status: QuestionStatus;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            version: string;
            difficulty: Difficulty;
            variant: FreepadQuestionVariant;
            answers: Array<{
              __typename?: "FreepadAnswer";
              id: string;
              titleJa: string | null;
              titleEn: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
              label: string;
              freepadAnswerId: number;
              answerCodes: Array<{ __typename?: "FreepadAnswerCode"; id: string; freepadAnswerCodeId: number; runtime: string; body: string }>;
              answerFlowCharts: Array<{
                __typename?: "FreepadAnswerFlowChart";
                id: string;
                freepadAnswerFlowChartId: number;
                componentType: SystemDesignComponentType;
                body: string;
              }>;
            }>;
          } | null;
        } | null>;
        participants: Array<{
          __typename?: "LiveCodingParticipant";
          userId: string;
          liveCodingId: number;
          userName: string;
          userType: LiveCodingUserType;
        }>;
      } | null;
    } | null;
  };
};

export type AlgorithmQuestionForRemoteInterviewTemplateListMenuFragment = {
  __typename: "AlgorithmQuestion";
  id: string;
  questionId: number;
  version: string;
  key: string;
  companyId: number;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  status: QuestionStatus;
  supportedLanguages: Array<string>;
  difficulty: Difficulty;
  isPrivate: boolean;
  isOfficial: boolean;
  timeLimitSeconds: number | null;
  AlgorithmQuestionVariant: AlgorithmQuestionVariant;
};

export type SystemDesignQuestionForRemoteInterviewTemplateListMenuFragment = {
  __typename: "SystemDesignQuestion";
  id: string;
  questionId: number;
  key: string;
  companyId: number;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  difficulty: Difficulty;
  isPrivate: boolean;
  isOfficial: boolean;
  timeLimitSeconds: number | null;
};

export type FreepadQuestionForRemoteInterviewTemplateListMenuFragment = {
  __typename: "FreepadQuestion";
  id: string;
  companyId: number;
  key: string;
  titleJa: string | null;
  titleEn: string | null;
  descriptionJa: string | null;
  descriptionEn: string | null;
  status: QuestionStatus;
  difficulty: Difficulty;
  isPrivate: boolean;
  isOfficial: boolean;
};

export type TemplateForRemoteInterviewTemplateListMenuFragment = {
  __typename?: "RemoteInterviewTemplate";
  id: string;
  templateId: number;
  templateName: string;
  description: string;
  candidateName: string;
  remoteName: string;
  tagNames: Array<string>;
  memo: string | null;
  isPublic: boolean;
  reviewers: Array<{
    __typename?: "RemoteReviewer";
    type: ReviewerType;
    employee: { __typename?: "User"; id: string; displayName: string } | null;
    group: { __typename?: "EmployeeGroup"; id: string; groupName: string } | null;
  }>;
  templateQuestions: Array<
    | {
        __typename: "AlgorithmQuestion";
        id: string;
        questionId: number;
        version: string;
        key: string;
        companyId: number;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        status: QuestionStatus;
        supportedLanguages: Array<string>;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        timeLimitSeconds: number | null;
        AlgorithmQuestionVariant: AlgorithmQuestionVariant;
      }
    | {
        __typename: "FreepadQuestion";
        id: string;
        companyId: number;
        key: string;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        status: QuestionStatus;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
      }
    | {
        __typename: "SystemDesignQuestion";
        id: string;
        questionId: number;
        key: string;
        companyId: number;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        timeLimitSeconds: number | null;
      }
  >;
  questions: Array<{
    __typename?: "RemoteInterviewTemplateEntitySource";
    questionId: number;
    entityType: RemoteInterviewTemplateEntityType | null;
    questionKey: string;
    challengeQuestion: { __typename?: "TemplateChallengeQuestionSource"; questionId: number; uniqueKey: string } | null;
    systemDesignQuestion: {
      __typename?: "TemplateSystemDesignQuestionSource";
      questionId: number;
      componentTypes: Array<SystemDesignComponentType>;
    } | null;
    freepadQuestion: { __typename?: "TemplateFreepadQuestionSource"; questionId: number; uniqueKey: string } | null;
  }>;
  variables: Array<{ __typename?: "TemplateVariable"; id: string; key: number; templateId: number; name: string; description: string }> | null;
};

export type GetTemplateForRemoteInterviewTemplateListMenuQueryVariables = Exact<{
  templateId: Scalars["Int"]["input"];
}>;

export type GetTemplateForRemoteInterviewTemplateListMenuQuery = {
  __typename?: "Query";
  remoteInterviewTemplate: {
    __typename?: "RemoteInterviewTemplate";
    id: string;
    templateId: number;
    templateName: string;
    description: string;
    candidateName: string;
    remoteName: string;
    tagNames: Array<string>;
    memo: string | null;
    isPublic: boolean;
    reviewers: Array<{
      __typename?: "RemoteReviewer";
      type: ReviewerType;
      employee: { __typename?: "User"; id: string; displayName: string } | null;
      group: { __typename?: "EmployeeGroup"; id: string; groupName: string } | null;
    }>;
    templateQuestions: Array<
      | {
          __typename: "AlgorithmQuestion";
          id: string;
          questionId: number;
          version: string;
          key: string;
          companyId: number;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          status: QuestionStatus;
          supportedLanguages: Array<string>;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          timeLimitSeconds: number | null;
          AlgorithmQuestionVariant: AlgorithmQuestionVariant;
        }
      | {
          __typename: "FreepadQuestion";
          id: string;
          companyId: number;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          status: QuestionStatus;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
        }
      | {
          __typename: "SystemDesignQuestion";
          id: string;
          questionId: number;
          key: string;
          companyId: number;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          timeLimitSeconds: number | null;
        }
    >;
    questions: Array<{
      __typename?: "RemoteInterviewTemplateEntitySource";
      questionId: number;
      entityType: RemoteInterviewTemplateEntityType | null;
      questionKey: string;
      challengeQuestion: { __typename?: "TemplateChallengeQuestionSource"; questionId: number; uniqueKey: string } | null;
      systemDesignQuestion: {
        __typename?: "TemplateSystemDesignQuestionSource";
        questionId: number;
        componentTypes: Array<SystemDesignComponentType>;
      } | null;
      freepadQuestion: { __typename?: "TemplateFreepadQuestionSource"; questionId: number; uniqueKey: string } | null;
    }>;
    variables: Array<{
      __typename?: "TemplateVariable";
      id: string;
      key: number;
      templateId: number;
      name: string;
      description: string;
    }> | null;
  };
};

export type ListTemplateForRemoteInterviewTemplateListMenuFragment = {
  __typename?: "RemoteInterviewTemplate";
  id: string;
  templateId: number;
  templateName: string;
  description: string;
  remoteName: string;
  candidateName: string;
  tagNames: Array<string>;
  memo: string | null;
  isPublic: boolean;
  reviewers: Array<{
    __typename?: "RemoteReviewer";
    type: ReviewerType;
    employee: { __typename?: "User"; id: string; displayName: string } | null;
    group: { __typename?: "EmployeeGroup"; id: string; groupName: string } | null;
  }>;
  templateQuestions: Array<
    | {
        __typename: "AlgorithmQuestion";
        id: string;
        questionId: number;
        version: string;
        key: string;
        companyId: number;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        status: QuestionStatus;
        supportedLanguages: Array<string>;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        timeLimitSeconds: number | null;
        AlgorithmQuestionVariant: AlgorithmQuestionVariant;
      }
    | {
        __typename: "FreepadQuestion";
        id: string;
        companyId: number;
        key: string;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        status: QuestionStatus;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
      }
    | {
        __typename: "SystemDesignQuestion";
        id: string;
        questionId: number;
        key: string;
        companyId: number;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        timeLimitSeconds: number | null;
      }
  >;
  questions: Array<{
    __typename?: "RemoteInterviewTemplateEntitySource";
    questionId: number;
    questionKey: string;
    entityType: RemoteInterviewTemplateEntityType | null;
    challengeQuestion: { __typename?: "TemplateChallengeQuestionSource"; questionId: number; uniqueKey: string } | null;
    systemDesignQuestion: {
      __typename?: "TemplateSystemDesignQuestionSource";
      questionId: number;
      componentTypes: Array<SystemDesignComponentType>;
    } | null;
    freepadQuestion: { __typename?: "TemplateFreepadQuestionSource"; questionId: number; uniqueKey: string } | null;
  }>;
  variables: Array<{ __typename?: "TemplateVariable"; id: string; key: number; templateId: number; name: string; description: string }> | null;
};

export type ListTemplatesForRemoteInterviewTemplateListMenuQueryVariables = Exact<{
  input: RemoteInterviewTemplatesByCompanyIdInput;
}>;

export type ListTemplatesForRemoteInterviewTemplateListMenuQuery = {
  __typename?: "Query";
  remoteInterviewTemplatesByCompanyId: {
    __typename?: "RemoteInterviewTemplatesByCompanyIdResponse";
    count: number;
    templates: Array<{
      __typename?: "RemoteInterviewTemplate";
      id: string;
      templateId: number;
      templateName: string;
      description: string;
      remoteName: string;
      candidateName: string;
      tagNames: Array<string>;
      memo: string | null;
      isPublic: boolean;
      reviewers: Array<{
        __typename?: "RemoteReviewer";
        type: ReviewerType;
        employee: { __typename?: "User"; id: string; displayName: string } | null;
        group: { __typename?: "EmployeeGroup"; id: string; groupName: string } | null;
      }>;
      templateQuestions: Array<
        | {
            __typename: "AlgorithmQuestion";
            id: string;
            questionId: number;
            version: string;
            key: string;
            companyId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            status: QuestionStatus;
            supportedLanguages: Array<string>;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            timeLimitSeconds: number | null;
            AlgorithmQuestionVariant: AlgorithmQuestionVariant;
          }
        | {
            __typename: "FreepadQuestion";
            id: string;
            companyId: number;
            key: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            status: QuestionStatus;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
          }
        | {
            __typename: "SystemDesignQuestion";
            id: string;
            questionId: number;
            key: string;
            companyId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            timeLimitSeconds: number | null;
          }
      >;
      questions: Array<{
        __typename?: "RemoteInterviewTemplateEntitySource";
        questionId: number;
        questionKey: string;
        entityType: RemoteInterviewTemplateEntityType | null;
        challengeQuestion: { __typename?: "TemplateChallengeQuestionSource"; questionId: number; uniqueKey: string } | null;
        systemDesignQuestion: {
          __typename?: "TemplateSystemDesignQuestionSource";
          questionId: number;
          componentTypes: Array<SystemDesignComponentType>;
        } | null;
        freepadQuestion: { __typename?: "TemplateFreepadQuestionSource"; questionId: number; uniqueKey: string } | null;
      }>;
      variables: Array<{
        __typename?: "TemplateVariable";
        id: string;
        key: number;
        templateId: number;
        name: string;
        description: string;
      }> | null;
    }>;
    pager: { __typename?: "TemplatePager"; idCursor: number | null; hasNext: boolean } | null;
  } | null;
};

export type DeleteTemplateForRemoteInterviewTemplateListMenuMutationVariables = Exact<{
  id: Scalars["Int"]["input"];
}>;

export type DeleteTemplateForRemoteInterviewTemplateListMenuMutation = { __typename?: "Mutation"; deleteRemoteInterviewTemplate: boolean };

export type FillTemplateForRemoteInterviewTemplateListMenuFragment = {
  __typename?: "RemoteInterviewTemplate";
  id: string;
  templateId: number;
  templateName: string;
  description: string;
  remoteName: string;
  candidateName: string;
  tagNames: Array<string>;
  memo: string | null;
  isPublic: boolean;
  reviewers: Array<{
    __typename?: "RemoteReviewer";
    type: ReviewerType;
    employee: { __typename?: "User"; id: string; displayName: string } | null;
    group: { __typename?: "EmployeeGroup"; id: string; groupName: string } | null;
  }>;
  templateQuestions: Array<
    | {
        __typename: "AlgorithmQuestion";
        id: string;
        questionId: number;
        version: string;
        key: string;
        companyId: number;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        status: QuestionStatus;
        supportedLanguages: Array<string>;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        timeLimitSeconds: number | null;
        AlgorithmQuestionVariant: AlgorithmQuestionVariant;
      }
    | {
        __typename: "FreepadQuestion";
        id: string;
        companyId: number;
        key: string;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        status: QuestionStatus;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
      }
    | {
        __typename: "SystemDesignQuestion";
        id: string;
        questionId: number;
        key: string;
        companyId: number;
        titleJa: string | null;
        titleEn: string | null;
        descriptionJa: string | null;
        descriptionEn: string | null;
        difficulty: Difficulty;
        isPrivate: boolean;
        isOfficial: boolean;
        timeLimitSeconds: number | null;
      }
  >;
  questions: Array<{
    __typename?: "RemoteInterviewTemplateEntitySource";
    questionId: number;
    questionKey: string;
    entityType: RemoteInterviewTemplateEntityType | null;
    challengeQuestion: { __typename?: "TemplateChallengeQuestionSource"; questionId: number; uniqueKey: string } | null;
    systemDesignQuestion: {
      __typename?: "TemplateSystemDesignQuestionSource";
      questionId: number;
      componentTypes: Array<SystemDesignComponentType>;
    } | null;
    freepadQuestion: { __typename?: "TemplateFreepadQuestionSource"; questionId: number; uniqueKey: string } | null;
  }>;
  variables: Array<{ __typename?: "TemplateVariable"; id: string; key: number; templateId: number; name: string; description: string }> | null;
};

export type FillTemplateForRemoteInterviewTemplateListMenuMutationVariables = Exact<{
  input: FillTemplateInput;
}>;

export type FillTemplateForRemoteInterviewTemplateListMenuMutation = {
  __typename?: "Mutation";
  fillRemoteInterviewTemplate: {
    __typename?: "RemoteInterviewTemplate";
    id: string;
    templateId: number;
    templateName: string;
    description: string;
    remoteName: string;
    candidateName: string;
    tagNames: Array<string>;
    memo: string | null;
    isPublic: boolean;
    reviewers: Array<{
      __typename?: "RemoteReviewer";
      type: ReviewerType;
      employee: { __typename?: "User"; id: string; displayName: string } | null;
      group: { __typename?: "EmployeeGroup"; id: string; groupName: string } | null;
    }>;
    templateQuestions: Array<
      | {
          __typename: "AlgorithmQuestion";
          id: string;
          questionId: number;
          version: string;
          key: string;
          companyId: number;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          status: QuestionStatus;
          supportedLanguages: Array<string>;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          timeLimitSeconds: number | null;
          AlgorithmQuestionVariant: AlgorithmQuestionVariant;
        }
      | {
          __typename: "FreepadQuestion";
          id: string;
          companyId: number;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          status: QuestionStatus;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
        }
      | {
          __typename: "SystemDesignQuestion";
          id: string;
          questionId: number;
          key: string;
          companyId: number;
          titleJa: string | null;
          titleEn: string | null;
          descriptionJa: string | null;
          descriptionEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          timeLimitSeconds: number | null;
        }
    >;
    questions: Array<{
      __typename?: "RemoteInterviewTemplateEntitySource";
      questionId: number;
      questionKey: string;
      entityType: RemoteInterviewTemplateEntityType | null;
      challengeQuestion: { __typename?: "TemplateChallengeQuestionSource"; questionId: number; uniqueKey: string } | null;
      systemDesignQuestion: {
        __typename?: "TemplateSystemDesignQuestionSource";
        questionId: number;
        componentTypes: Array<SystemDesignComponentType>;
      } | null;
      freepadQuestion: { __typename?: "TemplateFreepadQuestionSource"; questionId: number; uniqueKey: string } | null;
    }>;
    variables: Array<{
      __typename?: "TemplateVariable";
      id: string;
      key: number;
      templateId: number;
      name: string;
      description: string;
    }> | null;
  };
};

export type EmployeeForRemoteInterviewVideoChatParticipantsFragment = { __typename?: "User"; id: string; photoUrl: string };

export type GetMultiEmployeesForRemoteInterviewVideoChatParticipantsQueryVariables = Exact<{
  uids: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type GetMultiEmployeesForRemoteInterviewVideoChatParticipantsQuery = {
  __typename?: "Query";
  employees: Array<{ __typename?: "User"; id: string; photoUrl: string }>;
};

export type SpotForScheduleEventsFragment = {
  __typename: "Spot";
  id: string;
  name: string;
  willExpireAtSeconds: number | null;
  createdAtSeconds: number | null;
};

export type RemoteForScheduleEventsFragment = {
  __typename: "Remote";
  id: string;
  name: string;
  createdAtSeconds: number | null;
  status: RemoteStatus;
};

export type GetScheduleEventsQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  size: Scalars["Int"]["input"];
}>;

export type GetScheduleEventsQuery = {
  __typename?: "Query";
  spotsForSchedule: Array<{
    __typename: "Spot";
    id: string;
    name: string;
    willExpireAtSeconds: number | null;
    createdAtSeconds: number | null;
  }>;
  remotesForSchedule: Array<{ __typename: "Remote"; id: string; name: string; createdAtSeconds: number | null; status: RemoteStatus }>;
};

export type StartRemoteForScheduleEventsMutationVariables = Exact<{
  input: StartRemoteInput;
}>;

export type StartRemoteForScheduleEventsMutation = {
  __typename?: "Mutation";
  startRemote: { __typename?: "Remote"; id: string; name: string; createdAtSeconds: number | null; status: RemoteStatus };
};

export type ScreeningInviteeForScreeningDetailFragment = {
  __typename?: "ScreeningInvitee";
  screeningId: string;
  email: string;
  status: ScreeningInviteeStatus;
};

export type VariableForScreeningDetailFragment = {
  __typename?: "ScreeningVariable";
  screeningId: string;
  variableId: number;
  label: string;
  description: string;
  variableField:
    | {
        __typename: "ScreeningBooleanFieldVariable";
        validationRule: { __typename: "ScreeningBooleanFieldVariableValidationRule"; required: boolean };
      }
    | {
        __typename: "ScreeningIntegerFieldVariable";
        validationRule: {
          __typename: "ScreeningIntegerFieldVariableValidationRule";
          required: boolean;
          maxIntegerValue: number | null;
          minIntegerValue: number | null;
        };
      }
    | {
        __typename: "ScreeningStringFieldVariable";
        formType: ScreeningStringVariableFieldFormType;
        validationRule: {
          __typename: "ScreeningStringFieldVariableValidationRule";
          required: boolean;
          maxLength: number | null;
          minLength: number | null;
        };
      };
};

export type ScreeningChallengeEntitySourceQuestionForScreeningDetailFragment = {
  __typename?: "ScreeningChallengeEntitySourceQuestion";
  questionId: number;
  questionVersion: string;
  enabledLanguages: Array<string>;
  questionWeight: number;
  question: {
    __typename: "AlgorithmQuestion";
    questionId: number;
    version: string;
    key: string;
    titleJa: string | null;
    titleEn: string | null;
    supportedLanguages: Array<string>;
    difficulty: Difficulty;
    isPrivate: boolean;
    isOfficial: boolean;
    timeLimitSeconds: number | null;
    numUses: number;
    isSupportedLeakScore: boolean;
    accuracyRate: number;
    averageElapsedTimeSeconds: number;
    algorithmQuestionStatus: QuestionStatus;
    algorithmQuestionVariant: AlgorithmQuestionVariant;
    leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
  };
};

export type ScreeningQuizEntitySourcePackageForScreeningDetailFragment = {
  __typename?: "ScreeningQuizEntitySourcePackage";
  packageId: number;
  packageVersion: string;
  questionWeight: number;
  pb_package: {
    __typename: "MultiChoicePackage";
    packageId: number;
    version: string;
    key: string;
    titleJa: string | null;
    titleEn: string | null;
    difficulty: Difficulty;
    isPrivate: boolean;
    isOfficial: boolean;
    isSupportedLeakScore: boolean;
    timeLimitSeconds: number | null;
    numUses: number;
    accuracyRate: number;
    averageElapsedTimeSeconds: number;
    multiChoicePackageStatus: QuestionStatus;
    leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
  };
};

export type ScreeningProjectEntitySourceQuestionForScreeningDetailFragment = {
  __typename?: "ScreeningProjectEntitySourceQuestion";
  questionId: number;
  questionVersion: string;
  questionWeight: number;
  question: {
    __typename: "ProjectQuestion";
    questionId: number;
    version: string;
    projectVersion: string;
    key: string;
    companyId: number;
    titleJa: string | null;
    titleEn: string | null;
    difficulty: Difficulty;
    isPrivate: boolean;
    isOfficial: boolean;
    isSupportedLeakScore: boolean;
    timeLimitSeconds: number | null;
    numUses: number;
    accuracyRate: number;
    averageElapsedTimeSeconds: number;
    projectQuestionStatus: ProjectQuestionStatus;
    projectQuestionVariant: ProjectQuestionVariant;
    leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
  };
};

export type ScreeningSystemDesignEntitySourceQuestionForScreeningDetailFragment = {
  __typename?: "ScreeningSystemDesignEntitySourceQuestion";
  questionId: number;
  componentTypes: Array<SystemDesignComponentType>;
  questionWeight: number;
  question: {
    __typename: "SystemDesignQuestion";
    questionId: number;
    key: string;
    titleJa: string | null;
    titleEn: string | null;
    difficulty: Difficulty;
    isPrivate: boolean;
    isOfficial: boolean;
    isSupportedLeakScore: boolean;
    timeLimitSeconds: number | null;
    numUses: number;
    accuracyRate: number;
    averageElapsedTimeSeconds: number;
    systemDesignQuestionStatus: SystemDesignQuestionStatus;
    leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
  };
};

type ScreeningEntitySourceQuestionForScreeningDetail_ScreeningChallengeEntitySourceQuestion_Fragment = {
  __typename: "ScreeningChallengeEntitySourceQuestion";
  questionId: number;
  questionVersion: string;
  enabledLanguages: Array<string>;
  questionWeight: number;
  question: {
    __typename: "AlgorithmQuestion";
    questionId: number;
    version: string;
    key: string;
    titleJa: string | null;
    titleEn: string | null;
    supportedLanguages: Array<string>;
    difficulty: Difficulty;
    isPrivate: boolean;
    isOfficial: boolean;
    timeLimitSeconds: number | null;
    numUses: number;
    isSupportedLeakScore: boolean;
    accuracyRate: number;
    averageElapsedTimeSeconds: number;
    algorithmQuestionStatus: QuestionStatus;
    algorithmQuestionVariant: AlgorithmQuestionVariant;
    leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
  };
};

type ScreeningEntitySourceQuestionForScreeningDetail_ScreeningProjectEntitySourceQuestion_Fragment = {
  __typename: "ScreeningProjectEntitySourceQuestion";
  questionId: number;
  questionVersion: string;
  questionWeight: number;
  question: {
    __typename: "ProjectQuestion";
    questionId: number;
    version: string;
    projectVersion: string;
    key: string;
    companyId: number;
    titleJa: string | null;
    titleEn: string | null;
    difficulty: Difficulty;
    isPrivate: boolean;
    isOfficial: boolean;
    isSupportedLeakScore: boolean;
    timeLimitSeconds: number | null;
    numUses: number;
    accuracyRate: number;
    averageElapsedTimeSeconds: number;
    projectQuestionStatus: ProjectQuestionStatus;
    projectQuestionVariant: ProjectQuestionVariant;
    leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
  };
};

type ScreeningEntitySourceQuestionForScreeningDetail_ScreeningQuizEntitySourcePackage_Fragment = {
  __typename: "ScreeningQuizEntitySourcePackage";
  packageId: number;
  packageVersion: string;
  questionWeight: number;
  pb_package: {
    __typename: "MultiChoicePackage";
    packageId: number;
    version: string;
    key: string;
    titleJa: string | null;
    titleEn: string | null;
    difficulty: Difficulty;
    isPrivate: boolean;
    isOfficial: boolean;
    isSupportedLeakScore: boolean;
    timeLimitSeconds: number | null;
    numUses: number;
    accuracyRate: number;
    averageElapsedTimeSeconds: number;
    multiChoicePackageStatus: QuestionStatus;
    leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
  };
};

type ScreeningEntitySourceQuestionForScreeningDetail_ScreeningSystemDesignEntitySourceQuestion_Fragment = {
  __typename: "ScreeningSystemDesignEntitySourceQuestion";
  questionId: number;
  componentTypes: Array<SystemDesignComponentType>;
  questionWeight: number;
  question: {
    __typename: "SystemDesignQuestion";
    questionId: number;
    key: string;
    titleJa: string | null;
    titleEn: string | null;
    difficulty: Difficulty;
    isPrivate: boolean;
    isOfficial: boolean;
    isSupportedLeakScore: boolean;
    timeLimitSeconds: number | null;
    numUses: number;
    accuracyRate: number;
    averageElapsedTimeSeconds: number;
    systemDesignQuestionStatus: SystemDesignQuestionStatus;
    leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
  };
};

export type ScreeningEntitySourceQuestionForScreeningDetailFragment =
  | ScreeningEntitySourceQuestionForScreeningDetail_ScreeningChallengeEntitySourceQuestion_Fragment
  | ScreeningEntitySourceQuestionForScreeningDetail_ScreeningProjectEntitySourceQuestion_Fragment
  | ScreeningEntitySourceQuestionForScreeningDetail_ScreeningQuizEntitySourcePackage_Fragment
  | ScreeningEntitySourceQuestionForScreeningDetail_ScreeningSystemDesignEntitySourceQuestion_Fragment;

export type ScreeningForScreeningDetailFragment = {
  __typename?: "Screening";
  id: string;
  screeningId: string;
  allowWebSearch: boolean;
  allowChatGPT: boolean;
  allowHint: boolean;
  acceptableLimit: number | null;
  deadlineSeconds: number | null;
  name: string;
  description: string;
  numAccepted: number;
  acceptanceStatus: ScreeningAcceptanceStatus;
  createdAtSeconds: number | null;
  memo: string | null;
  ownerEmailAddress: string | null;
  messageForCandidate: string | null;
  timeLimitSeconds: number;
  candidateAccessPolicy: ScreeningCandidateAccessPolicy;
  invitationLanguage: Language;
  isActive: boolean;
  spotIsPublic: boolean;
  entityTracks: Array<
    | {
        __typename: "ScreeningFixedEntityTrack";
        trackId: number;
        questionScoreWeight: number;
        entitySourceQuestion:
          | {
              __typename: "ScreeningChallengeEntitySourceQuestion";
              questionId: number;
              questionVersion: string;
              enabledLanguages: Array<string>;
              questionWeight: number;
              question: {
                __typename: "AlgorithmQuestion";
                questionId: number;
                version: string;
                key: string;
                titleJa: string | null;
                titleEn: string | null;
                supportedLanguages: Array<string>;
                difficulty: Difficulty;
                isPrivate: boolean;
                isOfficial: boolean;
                timeLimitSeconds: number | null;
                numUses: number;
                isSupportedLeakScore: boolean;
                accuracyRate: number;
                averageElapsedTimeSeconds: number;
                algorithmQuestionStatus: QuestionStatus;
                algorithmQuestionVariant: AlgorithmQuestionVariant;
                leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
              };
            }
          | {
              __typename: "ScreeningProjectEntitySourceQuestion";
              questionId: number;
              questionVersion: string;
              questionWeight: number;
              question: {
                __typename: "ProjectQuestion";
                questionId: number;
                version: string;
                projectVersion: string;
                key: string;
                companyId: number;
                titleJa: string | null;
                titleEn: string | null;
                difficulty: Difficulty;
                isPrivate: boolean;
                isOfficial: boolean;
                isSupportedLeakScore: boolean;
                timeLimitSeconds: number | null;
                numUses: number;
                accuracyRate: number;
                averageElapsedTimeSeconds: number;
                projectQuestionStatus: ProjectQuestionStatus;
                projectQuestionVariant: ProjectQuestionVariant;
                leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
              };
            }
          | {
              __typename: "ScreeningQuizEntitySourcePackage";
              packageId: number;
              packageVersion: string;
              questionWeight: number;
              pb_package: {
                __typename: "MultiChoicePackage";
                packageId: number;
                version: string;
                key: string;
                titleJa: string | null;
                titleEn: string | null;
                difficulty: Difficulty;
                isPrivate: boolean;
                isOfficial: boolean;
                isSupportedLeakScore: boolean;
                timeLimitSeconds: number | null;
                numUses: number;
                accuracyRate: number;
                averageElapsedTimeSeconds: number;
                multiChoicePackageStatus: QuestionStatus;
                leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
              };
            }
          | {
              __typename: "ScreeningSystemDesignEntitySourceQuestion";
              questionId: number;
              componentTypes: Array<SystemDesignComponentType>;
              questionWeight: number;
              question: {
                __typename: "SystemDesignQuestion";
                questionId: number;
                key: string;
                titleJa: string | null;
                titleEn: string | null;
                difficulty: Difficulty;
                isPrivate: boolean;
                isOfficial: boolean;
                isSupportedLeakScore: boolean;
                timeLimitSeconds: number | null;
                numUses: number;
                accuracyRate: number;
                averageElapsedTimeSeconds: number;
                systemDesignQuestionStatus: SystemDesignQuestionStatus;
                leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
              };
            };
      }
    | {
        __typename: "ScreeningRandomFromQuestionsEntityTrack";
        trackId: number;
        questionScoreWeight: number;
        entitySourceQuestions: Array<
          | {
              __typename: "ScreeningChallengeEntitySourceQuestion";
              questionId: number;
              questionVersion: string;
              enabledLanguages: Array<string>;
              questionWeight: number;
              question: {
                __typename: "AlgorithmQuestion";
                questionId: number;
                version: string;
                key: string;
                titleJa: string | null;
                titleEn: string | null;
                supportedLanguages: Array<string>;
                difficulty: Difficulty;
                isPrivate: boolean;
                isOfficial: boolean;
                timeLimitSeconds: number | null;
                numUses: number;
                isSupportedLeakScore: boolean;
                accuracyRate: number;
                averageElapsedTimeSeconds: number;
                algorithmQuestionStatus: QuestionStatus;
                algorithmQuestionVariant: AlgorithmQuestionVariant;
                leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
              };
            }
          | {
              __typename: "ScreeningProjectEntitySourceQuestion";
              questionId: number;
              questionVersion: string;
              questionWeight: number;
              question: {
                __typename: "ProjectQuestion";
                questionId: number;
                version: string;
                projectVersion: string;
                key: string;
                companyId: number;
                titleJa: string | null;
                titleEn: string | null;
                difficulty: Difficulty;
                isPrivate: boolean;
                isOfficial: boolean;
                isSupportedLeakScore: boolean;
                timeLimitSeconds: number | null;
                numUses: number;
                accuracyRate: number;
                averageElapsedTimeSeconds: number;
                projectQuestionStatus: ProjectQuestionStatus;
                projectQuestionVariant: ProjectQuestionVariant;
                leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
              };
            }
          | {
              __typename: "ScreeningQuizEntitySourcePackage";
              packageId: number;
              packageVersion: string;
              questionWeight: number;
              pb_package: {
                __typename: "MultiChoicePackage";
                packageId: number;
                version: string;
                key: string;
                titleJa: string | null;
                titleEn: string | null;
                difficulty: Difficulty;
                isPrivate: boolean;
                isOfficial: boolean;
                isSupportedLeakScore: boolean;
                timeLimitSeconds: number | null;
                numUses: number;
                accuracyRate: number;
                averageElapsedTimeSeconds: number;
                multiChoicePackageStatus: QuestionStatus;
                leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
              };
            }
          | {
              __typename: "ScreeningSystemDesignEntitySourceQuestion";
              questionId: number;
              componentTypes: Array<SystemDesignComponentType>;
              questionWeight: number;
              question: {
                __typename: "SystemDesignQuestion";
                questionId: number;
                key: string;
                titleJa: string | null;
                titleEn: string | null;
                difficulty: Difficulty;
                isPrivate: boolean;
                isOfficial: boolean;
                isSupportedLeakScore: boolean;
                timeLimitSeconds: number | null;
                numUses: number;
                accuracyRate: number;
                averageElapsedTimeSeconds: number;
                systemDesignQuestionStatus: SystemDesignQuestionStatus;
                leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
              };
            }
        >;
      }
    | {
        __typename: "ScreeningSelectableFromQuestionsEntityTrack";
        trackId: number;
        questionScoreWeight: number;
        entitySourceQuestions: Array<
          | {
              __typename: "ScreeningChallengeEntitySourceQuestion";
              questionId: number;
              questionVersion: string;
              enabledLanguages: Array<string>;
              questionWeight: number;
              question: {
                __typename: "AlgorithmQuestion";
                questionId: number;
                version: string;
                key: string;
                titleJa: string | null;
                titleEn: string | null;
                supportedLanguages: Array<string>;
                difficulty: Difficulty;
                isPrivate: boolean;
                isOfficial: boolean;
                timeLimitSeconds: number | null;
                numUses: number;
                isSupportedLeakScore: boolean;
                accuracyRate: number;
                averageElapsedTimeSeconds: number;
                algorithmQuestionStatus: QuestionStatus;
                algorithmQuestionVariant: AlgorithmQuestionVariant;
                leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
              };
            }
          | {
              __typename: "ScreeningProjectEntitySourceQuestion";
              questionId: number;
              questionVersion: string;
              questionWeight: number;
              question: {
                __typename: "ProjectQuestion";
                questionId: number;
                version: string;
                projectVersion: string;
                key: string;
                companyId: number;
                titleJa: string | null;
                titleEn: string | null;
                difficulty: Difficulty;
                isPrivate: boolean;
                isOfficial: boolean;
                isSupportedLeakScore: boolean;
                timeLimitSeconds: number | null;
                numUses: number;
                accuracyRate: number;
                averageElapsedTimeSeconds: number;
                projectQuestionStatus: ProjectQuestionStatus;
                projectQuestionVariant: ProjectQuestionVariant;
                leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
              };
            }
          | {
              __typename: "ScreeningQuizEntitySourcePackage";
              packageId: number;
              packageVersion: string;
              questionWeight: number;
              pb_package: {
                __typename: "MultiChoicePackage";
                packageId: number;
                version: string;
                key: string;
                titleJa: string | null;
                titleEn: string | null;
                difficulty: Difficulty;
                isPrivate: boolean;
                isOfficial: boolean;
                isSupportedLeakScore: boolean;
                timeLimitSeconds: number | null;
                numUses: number;
                accuracyRate: number;
                averageElapsedTimeSeconds: number;
                multiChoicePackageStatus: QuestionStatus;
                leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
              };
            }
          | {
              __typename: "ScreeningSystemDesignEntitySourceQuestion";
              questionId: number;
              componentTypes: Array<SystemDesignComponentType>;
              questionWeight: number;
              question: {
                __typename: "SystemDesignQuestion";
                questionId: number;
                key: string;
                titleJa: string | null;
                titleEn: string | null;
                difficulty: Difficulty;
                isPrivate: boolean;
                isOfficial: boolean;
                isSupportedLeakScore: boolean;
                timeLimitSeconds: number | null;
                numUses: number;
                accuracyRate: number;
                averageElapsedTimeSeconds: number;
                systemDesignQuestionStatus: SystemDesignQuestionStatus;
                leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
              };
            }
        >;
      }
  >;
  invitees: Array<{ __typename?: "ScreeningInvitee"; screeningId: string; email: string; status: ScreeningInviteeStatus }>;
  variables: Array<{
    __typename?: "ScreeningVariable";
    screeningId: string;
    variableId: number;
    label: string;
    description: string;
    variableField:
      | {
          __typename: "ScreeningBooleanFieldVariable";
          validationRule: { __typename: "ScreeningBooleanFieldVariableValidationRule"; required: boolean };
        }
      | {
          __typename: "ScreeningIntegerFieldVariable";
          validationRule: {
            __typename: "ScreeningIntegerFieldVariableValidationRule";
            required: boolean;
            maxIntegerValue: number | null;
            minIntegerValue: number | null;
          };
        }
      | {
          __typename: "ScreeningStringFieldVariable";
          formType: ScreeningStringVariableFieldFormType;
          validationRule: {
            __typename: "ScreeningStringFieldVariableValidationRule";
            required: boolean;
            maxLength: number | null;
            minLength: number | null;
          };
        };
  }>;
  createdBy: { __typename?: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string } | null;
  viewers: Array<
    | { __typename: "EmployeeGroup"; id: string; employeeGroupId: string; groupName: string }
    | { __typename: "User"; id: string; uid: string; photoUrl: string; isSystemAdmin: boolean; displayName: string; email: string }
  >;
  tags: Array<{ __typename?: "SpotTag"; name: string }>;
};

export type GetScreeningForScreeningDetailQueryVariables = Exact<{
  screeningId: Scalars["String"]["input"];
  invitationCode: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetScreeningForScreeningDetailQuery = {
  __typename?: "Query";
  screening: {
    __typename?: "Screening";
    id: string;
    screeningId: string;
    allowWebSearch: boolean;
    allowChatGPT: boolean;
    allowHint: boolean;
    acceptableLimit: number | null;
    deadlineSeconds: number | null;
    name: string;
    description: string;
    numAccepted: number;
    acceptanceStatus: ScreeningAcceptanceStatus;
    createdAtSeconds: number | null;
    memo: string | null;
    ownerEmailAddress: string | null;
    messageForCandidate: string | null;
    timeLimitSeconds: number;
    candidateAccessPolicy: ScreeningCandidateAccessPolicy;
    invitationLanguage: Language;
    isActive: boolean;
    spotIsPublic: boolean;
    entityTracks: Array<
      | {
          __typename: "ScreeningFixedEntityTrack";
          trackId: number;
          questionScoreWeight: number;
          entitySourceQuestion:
            | {
                __typename: "ScreeningChallengeEntitySourceQuestion";
                questionId: number;
                questionVersion: string;
                enabledLanguages: Array<string>;
                questionWeight: number;
                question: {
                  __typename: "AlgorithmQuestion";
                  questionId: number;
                  version: string;
                  key: string;
                  titleJa: string | null;
                  titleEn: string | null;
                  supportedLanguages: Array<string>;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  isSupportedLeakScore: boolean;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  algorithmQuestionStatus: QuestionStatus;
                  algorithmQuestionVariant: AlgorithmQuestionVariant;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              }
            | {
                __typename: "ScreeningProjectEntitySourceQuestion";
                questionId: number;
                questionVersion: string;
                questionWeight: number;
                question: {
                  __typename: "ProjectQuestion";
                  questionId: number;
                  version: string;
                  projectVersion: string;
                  key: string;
                  companyId: number;
                  titleJa: string | null;
                  titleEn: string | null;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  isSupportedLeakScore: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  projectQuestionStatus: ProjectQuestionStatus;
                  projectQuestionVariant: ProjectQuestionVariant;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              }
            | {
                __typename: "ScreeningQuizEntitySourcePackage";
                packageId: number;
                packageVersion: string;
                questionWeight: number;
                pb_package: {
                  __typename: "MultiChoicePackage";
                  packageId: number;
                  version: string;
                  key: string;
                  titleJa: string | null;
                  titleEn: string | null;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  isSupportedLeakScore: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  multiChoicePackageStatus: QuestionStatus;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              }
            | {
                __typename: "ScreeningSystemDesignEntitySourceQuestion";
                questionId: number;
                componentTypes: Array<SystemDesignComponentType>;
                questionWeight: number;
                question: {
                  __typename: "SystemDesignQuestion";
                  questionId: number;
                  key: string;
                  titleJa: string | null;
                  titleEn: string | null;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  isSupportedLeakScore: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  systemDesignQuestionStatus: SystemDesignQuestionStatus;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              };
        }
      | {
          __typename: "ScreeningRandomFromQuestionsEntityTrack";
          trackId: number;
          questionScoreWeight: number;
          entitySourceQuestions: Array<
            | {
                __typename: "ScreeningChallengeEntitySourceQuestion";
                questionId: number;
                questionVersion: string;
                enabledLanguages: Array<string>;
                questionWeight: number;
                question: {
                  __typename: "AlgorithmQuestion";
                  questionId: number;
                  version: string;
                  key: string;
                  titleJa: string | null;
                  titleEn: string | null;
                  supportedLanguages: Array<string>;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  isSupportedLeakScore: boolean;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  algorithmQuestionStatus: QuestionStatus;
                  algorithmQuestionVariant: AlgorithmQuestionVariant;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              }
            | {
                __typename: "ScreeningProjectEntitySourceQuestion";
                questionId: number;
                questionVersion: string;
                questionWeight: number;
                question: {
                  __typename: "ProjectQuestion";
                  questionId: number;
                  version: string;
                  projectVersion: string;
                  key: string;
                  companyId: number;
                  titleJa: string | null;
                  titleEn: string | null;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  isSupportedLeakScore: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  projectQuestionStatus: ProjectQuestionStatus;
                  projectQuestionVariant: ProjectQuestionVariant;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              }
            | {
                __typename: "ScreeningQuizEntitySourcePackage";
                packageId: number;
                packageVersion: string;
                questionWeight: number;
                pb_package: {
                  __typename: "MultiChoicePackage";
                  packageId: number;
                  version: string;
                  key: string;
                  titleJa: string | null;
                  titleEn: string | null;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  isSupportedLeakScore: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  multiChoicePackageStatus: QuestionStatus;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              }
            | {
                __typename: "ScreeningSystemDesignEntitySourceQuestion";
                questionId: number;
                componentTypes: Array<SystemDesignComponentType>;
                questionWeight: number;
                question: {
                  __typename: "SystemDesignQuestion";
                  questionId: number;
                  key: string;
                  titleJa: string | null;
                  titleEn: string | null;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  isSupportedLeakScore: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  systemDesignQuestionStatus: SystemDesignQuestionStatus;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              }
          >;
        }
      | {
          __typename: "ScreeningSelectableFromQuestionsEntityTrack";
          trackId: number;
          questionScoreWeight: number;
          entitySourceQuestions: Array<
            | {
                __typename: "ScreeningChallengeEntitySourceQuestion";
                questionId: number;
                questionVersion: string;
                enabledLanguages: Array<string>;
                questionWeight: number;
                question: {
                  __typename: "AlgorithmQuestion";
                  questionId: number;
                  version: string;
                  key: string;
                  titleJa: string | null;
                  titleEn: string | null;
                  supportedLanguages: Array<string>;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  isSupportedLeakScore: boolean;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  algorithmQuestionStatus: QuestionStatus;
                  algorithmQuestionVariant: AlgorithmQuestionVariant;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              }
            | {
                __typename: "ScreeningProjectEntitySourceQuestion";
                questionId: number;
                questionVersion: string;
                questionWeight: number;
                question: {
                  __typename: "ProjectQuestion";
                  questionId: number;
                  version: string;
                  projectVersion: string;
                  key: string;
                  companyId: number;
                  titleJa: string | null;
                  titleEn: string | null;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  isSupportedLeakScore: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  projectQuestionStatus: ProjectQuestionStatus;
                  projectQuestionVariant: ProjectQuestionVariant;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              }
            | {
                __typename: "ScreeningQuizEntitySourcePackage";
                packageId: number;
                packageVersion: string;
                questionWeight: number;
                pb_package: {
                  __typename: "MultiChoicePackage";
                  packageId: number;
                  version: string;
                  key: string;
                  titleJa: string | null;
                  titleEn: string | null;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  isSupportedLeakScore: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  multiChoicePackageStatus: QuestionStatus;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              }
            | {
                __typename: "ScreeningSystemDesignEntitySourceQuestion";
                questionId: number;
                componentTypes: Array<SystemDesignComponentType>;
                questionWeight: number;
                question: {
                  __typename: "SystemDesignQuestion";
                  questionId: number;
                  key: string;
                  titleJa: string | null;
                  titleEn: string | null;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  isSupportedLeakScore: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  systemDesignQuestionStatus: SystemDesignQuestionStatus;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              }
          >;
        }
    >;
    invitees: Array<{ __typename?: "ScreeningInvitee"; screeningId: string; email: string; status: ScreeningInviteeStatus }>;
    variables: Array<{
      __typename?: "ScreeningVariable";
      screeningId: string;
      variableId: number;
      label: string;
      description: string;
      variableField:
        | {
            __typename: "ScreeningBooleanFieldVariable";
            validationRule: { __typename: "ScreeningBooleanFieldVariableValidationRule"; required: boolean };
          }
        | {
            __typename: "ScreeningIntegerFieldVariable";
            validationRule: {
              __typename: "ScreeningIntegerFieldVariableValidationRule";
              required: boolean;
              maxIntegerValue: number | null;
              minIntegerValue: number | null;
            };
          }
        | {
            __typename: "ScreeningStringFieldVariable";
            formType: ScreeningStringVariableFieldFormType;
            validationRule: {
              __typename: "ScreeningStringFieldVariableValidationRule";
              required: boolean;
              maxLength: number | null;
              minLength: number | null;
            };
          };
    }>;
    createdBy: { __typename?: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string } | null;
    viewers: Array<
      | { __typename: "EmployeeGroup"; id: string; employeeGroupId: string; groupName: string }
      | { __typename: "User"; id: string; uid: string; photoUrl: string; isSystemAdmin: boolean; displayName: string; email: string }
    >;
    tags: Array<{ __typename?: "SpotTag"; name: string }>;
  };
};

export type UpdateScreeningForScreeningDetailMutationVariables = Exact<{
  input: UpdateScreeningInput;
}>;

export type UpdateScreeningForScreeningDetailMutation = {
  __typename?: "Mutation";
  updateScreening: {
    __typename?: "Screening";
    id: string;
    screeningId: string;
    allowWebSearch: boolean;
    allowChatGPT: boolean;
    allowHint: boolean;
    acceptableLimit: number | null;
    deadlineSeconds: number | null;
    name: string;
    description: string;
    numAccepted: number;
    acceptanceStatus: ScreeningAcceptanceStatus;
    createdAtSeconds: number | null;
    memo: string | null;
    ownerEmailAddress: string | null;
    messageForCandidate: string | null;
    timeLimitSeconds: number;
    candidateAccessPolicy: ScreeningCandidateAccessPolicy;
    invitationLanguage: Language;
    isActive: boolean;
    spotIsPublic: boolean;
    entityTracks: Array<
      | {
          __typename: "ScreeningFixedEntityTrack";
          trackId: number;
          questionScoreWeight: number;
          entitySourceQuestion:
            | {
                __typename: "ScreeningChallengeEntitySourceQuestion";
                questionId: number;
                questionVersion: string;
                enabledLanguages: Array<string>;
                questionWeight: number;
                question: {
                  __typename: "AlgorithmQuestion";
                  questionId: number;
                  version: string;
                  key: string;
                  titleJa: string | null;
                  titleEn: string | null;
                  supportedLanguages: Array<string>;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  isSupportedLeakScore: boolean;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  algorithmQuestionStatus: QuestionStatus;
                  algorithmQuestionVariant: AlgorithmQuestionVariant;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              }
            | {
                __typename: "ScreeningProjectEntitySourceQuestion";
                questionId: number;
                questionVersion: string;
                questionWeight: number;
                question: {
                  __typename: "ProjectQuestion";
                  questionId: number;
                  version: string;
                  projectVersion: string;
                  key: string;
                  companyId: number;
                  titleJa: string | null;
                  titleEn: string | null;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  isSupportedLeakScore: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  projectQuestionStatus: ProjectQuestionStatus;
                  projectQuestionVariant: ProjectQuestionVariant;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              }
            | {
                __typename: "ScreeningQuizEntitySourcePackage";
                packageId: number;
                packageVersion: string;
                questionWeight: number;
                pb_package: {
                  __typename: "MultiChoicePackage";
                  packageId: number;
                  version: string;
                  key: string;
                  titleJa: string | null;
                  titleEn: string | null;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  isSupportedLeakScore: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  multiChoicePackageStatus: QuestionStatus;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              }
            | {
                __typename: "ScreeningSystemDesignEntitySourceQuestion";
                questionId: number;
                componentTypes: Array<SystemDesignComponentType>;
                questionWeight: number;
                question: {
                  __typename: "SystemDesignQuestion";
                  questionId: number;
                  key: string;
                  titleJa: string | null;
                  titleEn: string | null;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  isSupportedLeakScore: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  systemDesignQuestionStatus: SystemDesignQuestionStatus;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              };
        }
      | {
          __typename: "ScreeningRandomFromQuestionsEntityTrack";
          trackId: number;
          questionScoreWeight: number;
          entitySourceQuestions: Array<
            | {
                __typename: "ScreeningChallengeEntitySourceQuestion";
                questionId: number;
                questionVersion: string;
                enabledLanguages: Array<string>;
                questionWeight: number;
                question: {
                  __typename: "AlgorithmQuestion";
                  questionId: number;
                  version: string;
                  key: string;
                  titleJa: string | null;
                  titleEn: string | null;
                  supportedLanguages: Array<string>;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  isSupportedLeakScore: boolean;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  algorithmQuestionStatus: QuestionStatus;
                  algorithmQuestionVariant: AlgorithmQuestionVariant;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              }
            | {
                __typename: "ScreeningProjectEntitySourceQuestion";
                questionId: number;
                questionVersion: string;
                questionWeight: number;
                question: {
                  __typename: "ProjectQuestion";
                  questionId: number;
                  version: string;
                  projectVersion: string;
                  key: string;
                  companyId: number;
                  titleJa: string | null;
                  titleEn: string | null;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  isSupportedLeakScore: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  projectQuestionStatus: ProjectQuestionStatus;
                  projectQuestionVariant: ProjectQuestionVariant;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              }
            | {
                __typename: "ScreeningQuizEntitySourcePackage";
                packageId: number;
                packageVersion: string;
                questionWeight: number;
                pb_package: {
                  __typename: "MultiChoicePackage";
                  packageId: number;
                  version: string;
                  key: string;
                  titleJa: string | null;
                  titleEn: string | null;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  isSupportedLeakScore: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  multiChoicePackageStatus: QuestionStatus;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              }
            | {
                __typename: "ScreeningSystemDesignEntitySourceQuestion";
                questionId: number;
                componentTypes: Array<SystemDesignComponentType>;
                questionWeight: number;
                question: {
                  __typename: "SystemDesignQuestion";
                  questionId: number;
                  key: string;
                  titleJa: string | null;
                  titleEn: string | null;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  isSupportedLeakScore: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  systemDesignQuestionStatus: SystemDesignQuestionStatus;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              }
          >;
        }
      | {
          __typename: "ScreeningSelectableFromQuestionsEntityTrack";
          trackId: number;
          questionScoreWeight: number;
          entitySourceQuestions: Array<
            | {
                __typename: "ScreeningChallengeEntitySourceQuestion";
                questionId: number;
                questionVersion: string;
                enabledLanguages: Array<string>;
                questionWeight: number;
                question: {
                  __typename: "AlgorithmQuestion";
                  questionId: number;
                  version: string;
                  key: string;
                  titleJa: string | null;
                  titleEn: string | null;
                  supportedLanguages: Array<string>;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  isSupportedLeakScore: boolean;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  algorithmQuestionStatus: QuestionStatus;
                  algorithmQuestionVariant: AlgorithmQuestionVariant;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              }
            | {
                __typename: "ScreeningProjectEntitySourceQuestion";
                questionId: number;
                questionVersion: string;
                questionWeight: number;
                question: {
                  __typename: "ProjectQuestion";
                  questionId: number;
                  version: string;
                  projectVersion: string;
                  key: string;
                  companyId: number;
                  titleJa: string | null;
                  titleEn: string | null;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  isSupportedLeakScore: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  projectQuestionStatus: ProjectQuestionStatus;
                  projectQuestionVariant: ProjectQuestionVariant;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              }
            | {
                __typename: "ScreeningQuizEntitySourcePackage";
                packageId: number;
                packageVersion: string;
                questionWeight: number;
                pb_package: {
                  __typename: "MultiChoicePackage";
                  packageId: number;
                  version: string;
                  key: string;
                  titleJa: string | null;
                  titleEn: string | null;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  isSupportedLeakScore: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  multiChoicePackageStatus: QuestionStatus;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              }
            | {
                __typename: "ScreeningSystemDesignEntitySourceQuestion";
                questionId: number;
                componentTypes: Array<SystemDesignComponentType>;
                questionWeight: number;
                question: {
                  __typename: "SystemDesignQuestion";
                  questionId: number;
                  key: string;
                  titleJa: string | null;
                  titleEn: string | null;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  isSupportedLeakScore: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  systemDesignQuestionStatus: SystemDesignQuestionStatus;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              }
          >;
        }
    >;
    invitees: Array<{ __typename?: "ScreeningInvitee"; screeningId: string; email: string; status: ScreeningInviteeStatus }>;
    variables: Array<{
      __typename?: "ScreeningVariable";
      screeningId: string;
      variableId: number;
      label: string;
      description: string;
      variableField:
        | {
            __typename: "ScreeningBooleanFieldVariable";
            validationRule: { __typename: "ScreeningBooleanFieldVariableValidationRule"; required: boolean };
          }
        | {
            __typename: "ScreeningIntegerFieldVariable";
            validationRule: {
              __typename: "ScreeningIntegerFieldVariableValidationRule";
              required: boolean;
              maxIntegerValue: number | null;
              minIntegerValue: number | null;
            };
          }
        | {
            __typename: "ScreeningStringFieldVariable";
            formType: ScreeningStringVariableFieldFormType;
            validationRule: {
              __typename: "ScreeningStringFieldVariableValidationRule";
              required: boolean;
              maxLength: number | null;
              minLength: number | null;
            };
          };
    }>;
    createdBy: { __typename?: "User"; id: string; uid: string; displayName: string; email: string; photoUrl: string } | null;
    viewers: Array<
      | { __typename: "EmployeeGroup"; id: string; employeeGroupId: string; groupName: string }
      | { __typename: "User"; id: string; uid: string; photoUrl: string; isSystemAdmin: boolean; displayName: string; email: string }
    >;
    tags: Array<{ __typename?: "SpotTag"; name: string }>;
  };
};

export type DeleteScreeningForScreeningDetailMutationVariables = Exact<{
  input: DeleteScreeningInput;
}>;

export type DeleteScreeningForScreeningDetailMutation = { __typename?: "Mutation"; deleteScreening: boolean };

export type ListSpotTagsForScreeningDetailQueryVariables = Exact<{
  input: SpotTagsByCompanyIdInput;
}>;

export type ListSpotTagsForScreeningDetailQuery = {
  __typename?: "Query";
  spotTagsByCompanyId: { __typename?: "SpotTagsByCompanyIdResponse"; tags: Array<{ __typename?: "SpotTag"; name: string }> } | null;
};

export type InviteCandidateToScreeningForScreeningDetailMutationVariables = Exact<{
  input: InviteCandidatesToScreeningInput;
}>;

export type InviteCandidateToScreeningForScreeningDetailMutation = {
  __typename?: "Mutation";
  inviteCandidateToScreening: {
    __typename?: "InviteCandidateToScreeningResponse";
    invitees: Array<{ __typename?: "ScreeningInvitee"; screeningId: string; status: ScreeningInviteeStatus; email: string }>;
  };
};

export type SpotForScreeningDetailFragment = {
  __typename?: "Spot";
  id: string;
  spotId: string;
  parentId: string;
  originId: string;
  name: string;
  candidateName: string;
  candidateEmail: string;
  screeningId: string | null;
  status: SpotStatus;
  isPublic: boolean | null;
  totalScore: number;
  customScore: number;
  rank: number;
  numSubset: number;
  rankEvaluation: RankEvaluation;
  isReliableRank: boolean;
  isPassed: boolean;
  isRetake: boolean;
  suspiciousDegree: SpotSuspiciousDegree;
  createdAtSeconds: number | null;
  declinedAtSeconds: number | null;
  deletedAtSeconds: number | null;
  didEndAtSeconds: number | null;
  didStartAtSeconds: number | null;
  elapsedTimeSeconds: number | null;
  evaluatedAtSeconds: number | null;
  finalizedAtSeconds: number | null;
  timeLimitSeconds: number;
  updatedAtSeconds: number | null;
  willExpireAtSeconds: number | null;
  employee: {
    __typename?: "User";
    id: string;
    email: string;
    emailVerified: boolean;
    displayName: string;
    fullName: string;
    language: Language;
    photoUrl: string;
  } | null;
  tags: Array<{ __typename?: "SpotTag"; name: string }>;
  recommenders: Array<{ __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string }>;
  evaluatedBy: { __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string } | null;
};

export type SpotsByScreeningIdResponseForScreeningDetailFragment = {
  __typename?: "SpotsByScreeningIdResponse";
  count: number;
  hasNext: boolean;
  spots: Array<{
    __typename?: "Spot";
    id: string;
    spotId: string;
    parentId: string;
    originId: string;
    name: string;
    candidateName: string;
    candidateEmail: string;
    screeningId: string | null;
    status: SpotStatus;
    isPublic: boolean | null;
    totalScore: number;
    customScore: number;
    rank: number;
    numSubset: number;
    rankEvaluation: RankEvaluation;
    isReliableRank: boolean;
    isPassed: boolean;
    isRetake: boolean;
    suspiciousDegree: SpotSuspiciousDegree;
    createdAtSeconds: number | null;
    declinedAtSeconds: number | null;
    deletedAtSeconds: number | null;
    didEndAtSeconds: number | null;
    didStartAtSeconds: number | null;
    elapsedTimeSeconds: number | null;
    evaluatedAtSeconds: number | null;
    finalizedAtSeconds: number | null;
    timeLimitSeconds: number;
    updatedAtSeconds: number | null;
    willExpireAtSeconds: number | null;
    employee: {
      __typename?: "User";
      id: string;
      email: string;
      emailVerified: boolean;
      displayName: string;
      fullName: string;
      language: Language;
      photoUrl: string;
    } | null;
    tags: Array<{ __typename?: "SpotTag"; name: string }>;
    recommenders: Array<{ __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string }>;
    evaluatedBy: { __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string } | null;
  }>;
};

export type ListSpotsByScreeningIdForScreeningDetailQueryVariables = Exact<{
  input: SpotsByScreeningIdInput;
}>;

export type ListSpotsByScreeningIdForScreeningDetailQuery = {
  __typename?: "Query";
  spotsByScreeningId: {
    __typename?: "SpotsByScreeningIdResponse";
    count: number;
    hasNext: boolean;
    spots: Array<{
      __typename?: "Spot";
      id: string;
      spotId: string;
      parentId: string;
      originId: string;
      name: string;
      candidateName: string;
      candidateEmail: string;
      screeningId: string | null;
      status: SpotStatus;
      isPublic: boolean | null;
      totalScore: number;
      customScore: number;
      rank: number;
      numSubset: number;
      rankEvaluation: RankEvaluation;
      isReliableRank: boolean;
      isPassed: boolean;
      isRetake: boolean;
      suspiciousDegree: SpotSuspiciousDegree;
      createdAtSeconds: number | null;
      declinedAtSeconds: number | null;
      deletedAtSeconds: number | null;
      didEndAtSeconds: number | null;
      didStartAtSeconds: number | null;
      elapsedTimeSeconds: number | null;
      evaluatedAtSeconds: number | null;
      finalizedAtSeconds: number | null;
      timeLimitSeconds: number;
      updatedAtSeconds: number | null;
      willExpireAtSeconds: number | null;
      employee: {
        __typename?: "User";
        id: string;
        email: string;
        emailVerified: boolean;
        displayName: string;
        fullName: string;
        language: Language;
        photoUrl: string;
      } | null;
      tags: Array<{ __typename?: "SpotTag"; name: string }>;
      recommenders: Array<{ __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string }>;
      evaluatedBy: { __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string } | null;
    }>;
  };
};

export type SpotTagsByCompanyIdResponseForScreeningDetailFragment = {
  __typename?: "SpotTagsByCompanyIdResponse";
  count: number;
  tags: Array<{ __typename?: "SpotTag"; name: string; companyId: number }>;
  pager: { __typename?: "SpotPager"; cursorSeconds: number | null; hasNext: boolean } | null;
};

export type GetSpotTagsByCompanyIdForScreeningDetailQueryVariables = Exact<{
  spotTagsByCompanyId: SpotTagsByCompanyIdInput;
}>;

export type GetSpotTagsByCompanyIdForScreeningDetailQuery = {
  __typename?: "Query";
  spotTagsByCompanyId: {
    __typename?: "SpotTagsByCompanyIdResponse";
    count: number;
    tags: Array<{ __typename?: "SpotTag"; name: string; companyId: number }>;
    pager: { __typename?: "SpotPager"; cursorSeconds: number | null; hasNext: boolean } | null;
  } | null;
};

export type SpotFilledVariableCandidateForScreeningDetailFragment = {
  __typename?: "SpotFilledVariableCandidate";
  label: string;
  formType: ScreeningFormType;
  valueType: ScreeningValueType;
};

export type ListSpotFilterOptionsByScreeningIdForScreeningDetailQueryVariables = Exact<{
  input: SpotFilterOptionsByScreeningInput;
}>;

export type ListSpotFilterOptionsByScreeningIdForScreeningDetailQuery = {
  __typename?: "Query";
  spotFilterOptionsByScreeningId: {
    __typename?: "SpotFilterOptionsByScreeningIdResponse";
    filledVariableCandidates: Array<{
      __typename?: "SpotFilledVariableCandidate";
      label: string;
      formType: ScreeningFormType;
      valueType: ScreeningValueType;
    }>;
  } | null;
};

export type EvaluateMultiSpotsForScreeningsDetailMutationVariables = Exact<{
  input: EvaluateMultiSpotsInput;
}>;

export type EvaluateMultiSpotsForScreeningsDetailMutation = {
  __typename?: "Mutation";
  evaluateMultiSpots: Array<{
    __typename?: "Spot";
    id: string;
    spotId: string;
    parentId: string;
    originId: string;
    name: string;
    candidateName: string;
    candidateEmail: string;
    screeningId: string | null;
    status: SpotStatus;
    isPublic: boolean | null;
    totalScore: number;
    customScore: number;
    rank: number;
    numSubset: number;
    rankEvaluation: RankEvaluation;
    isReliableRank: boolean;
    isPassed: boolean;
    isRetake: boolean;
    suspiciousDegree: SpotSuspiciousDegree;
    createdAtSeconds: number | null;
    declinedAtSeconds: number | null;
    deletedAtSeconds: number | null;
    didEndAtSeconds: number | null;
    didStartAtSeconds: number | null;
    elapsedTimeSeconds: number | null;
    evaluatedAtSeconds: number | null;
    finalizedAtSeconds: number | null;
    timeLimitSeconds: number;
    updatedAtSeconds: number | null;
    willExpireAtSeconds: number | null;
    employee: {
      __typename?: "User";
      id: string;
      email: string;
      emailVerified: boolean;
      displayName: string;
      fullName: string;
      language: Language;
      photoUrl: string;
    } | null;
    tags: Array<{ __typename?: "SpotTag"; name: string }>;
    recommenders: Array<{ __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string }>;
    evaluatedBy: { __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string } | null;
  }>;
};

export type ScreeningInviteeForScreeningInviteeListFragment = {
  __typename?: "ScreeningInvitee";
  screeningId: string;
  email: string;
  status: ScreeningInviteeStatus;
};

export type ScreeningInviteesResponseForScreeningInviteeListFragment = {
  __typename?: "ScreeningInviteesResponse";
  count: number;
  hasNext: boolean;
  invitees: Array<{ __typename?: "ScreeningInvitee"; screeningId: string; email: string; status: ScreeningInviteeStatus }>;
};

export type ListScreeningInviteeForScreeningInviteeListQueryVariables = Exact<{
  input: ScreeningInviteesInput;
}>;

export type ListScreeningInviteeForScreeningInviteeListQuery = {
  __typename?: "Query";
  screeningInvitees: {
    __typename?: "ScreeningInviteesResponse";
    count: number;
    hasNext: boolean;
    invitees: Array<{ __typename?: "ScreeningInvitee"; screeningId: string; email: string; status: ScreeningInviteeStatus }>;
  };
};

export type ScreeningStringFieldVariableForScreeningResourceEditorFragment = {
  __typename: "ScreeningStringFieldVariable";
  formType: ScreeningStringVariableFieldFormType;
  validationRule: {
    __typename: "ScreeningStringFieldVariableValidationRule";
    required: boolean;
    maxLength: number | null;
    minLength: number | null;
  };
};

export type ScreeningIntegerFieldVariableForScreeningResourceEditorFragment = {
  __typename: "ScreeningIntegerFieldVariable";
  validationRule: {
    __typename: "ScreeningIntegerFieldVariableValidationRule";
    required: boolean;
    maxIntegerValue: number | null;
    minIntegerValue: number | null;
  };
};

export type ScreeningBooleanFieldVariableForScreeningResourceEditorFragment = {
  __typename: "ScreeningBooleanFieldVariable";
  validationRule: { __typename: "ScreeningBooleanFieldVariableValidationRule"; required: boolean };
};

export type VariableForScreeningResourceEditorFragment = {
  __typename?: "ScreeningVariable";
  screeningId: string;
  variableId: number;
  label: string;
  description: string;
  variableField:
    | {
        __typename: "ScreeningBooleanFieldVariable";
        validationRule: { __typename: "ScreeningBooleanFieldVariableValidationRule"; required: boolean };
      }
    | {
        __typename: "ScreeningIntegerFieldVariable";
        validationRule: {
          __typename: "ScreeningIntegerFieldVariableValidationRule";
          required: boolean;
          maxIntegerValue: number | null;
          minIntegerValue: number | null;
        };
      }
    | {
        __typename: "ScreeningStringFieldVariable";
        formType: ScreeningStringVariableFieldFormType;
        validationRule: {
          __typename: "ScreeningStringFieldVariableValidationRule";
          required: boolean;
          maxLength: number | null;
          minLength: number | null;
        };
      };
};

type ScreeningEntitySourceQuestionForScreeningResourceEditor_ScreeningChallengeEntitySourceQuestion_Fragment = {
  __typename: "ScreeningChallengeEntitySourceQuestion";
  enabledLanguages: Array<string>;
  questionWeight: number;
  question: {
    __typename: "AlgorithmQuestion";
    questionId: number;
    version: string;
    key: string;
    titleJa: string | null;
    titleEn: string | null;
    supportedLanguages: Array<string>;
    difficulty: Difficulty;
    isPrivate: boolean;
    isOfficial: boolean;
    timeLimitSeconds: number | null;
    numUses: number;
    isSupportedLeakScore: boolean;
    accuracyRate: number;
    averageElapsedTimeSeconds: number;
    algorithmQuestionVariant: AlgorithmQuestionVariant;
    algorithmQuestionStatus: QuestionStatus;
    leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
  };
};

type ScreeningEntitySourceQuestionForScreeningResourceEditor_ScreeningProjectEntitySourceQuestion_Fragment = {
  __typename: "ScreeningProjectEntitySourceQuestion";
  questionWeight: number;
  question: {
    __typename: "ProjectQuestion";
    questionId: number;
    version: string;
    projectVersion: string;
    key: string;
    companyId: number;
    titleJa: string | null;
    titleEn: string | null;
    difficulty: Difficulty;
    isPrivate: boolean;
    isOfficial: boolean;
    isSupportedLeakScore: boolean;
    timeLimitSeconds: number | null;
    numUses: number;
    accuracyRate: number;
    averageElapsedTimeSeconds: number;
    projectQuestionVariant: ProjectQuestionVariant;
    projectQuestionStatus: ProjectQuestionStatus;
    leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
  };
};

type ScreeningEntitySourceQuestionForScreeningResourceEditor_ScreeningQuizEntitySourcePackage_Fragment = {
  __typename: "ScreeningQuizEntitySourcePackage";
  questionWeight: number;
  pb_package: {
    __typename: "MultiChoicePackage";
    packageId: number;
    version: string;
    key: string;
    titleJa: string | null;
    titleEn: string | null;
    difficulty: Difficulty;
    isPrivate: boolean;
    isOfficial: boolean;
    isSupportedLeakScore: boolean;
    timeLimitSeconds: number | null;
    numUses: number;
    accuracyRate: number;
    averageElapsedTimeSeconds: number;
    multiChoicePackageStatus: QuestionStatus;
    leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
  };
};

type ScreeningEntitySourceQuestionForScreeningResourceEditor_ScreeningSystemDesignEntitySourceQuestion_Fragment = {
  __typename: "ScreeningSystemDesignEntitySourceQuestion";
  questionWeight: number;
  componentTypes: Array<SystemDesignComponentType>;
  question: {
    __typename: "SystemDesignQuestion";
    questionId: number;
    key: string;
    titleJa: string | null;
    titleEn: string | null;
    difficulty: Difficulty;
    isPrivate: boolean;
    isOfficial: boolean;
    isSupportedLeakScore: boolean;
    timeLimitSeconds: number | null;
    numUses: number;
    accuracyRate: number;
    averageElapsedTimeSeconds: number;
    systemDesignQuestionStatus: SystemDesignQuestionStatus;
    leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
  };
};

export type ScreeningEntitySourceQuestionForScreeningResourceEditorFragment =
  | ScreeningEntitySourceQuestionForScreeningResourceEditor_ScreeningChallengeEntitySourceQuestion_Fragment
  | ScreeningEntitySourceQuestionForScreeningResourceEditor_ScreeningProjectEntitySourceQuestion_Fragment
  | ScreeningEntitySourceQuestionForScreeningResourceEditor_ScreeningQuizEntitySourcePackage_Fragment
  | ScreeningEntitySourceQuestionForScreeningResourceEditor_ScreeningSystemDesignEntitySourceQuestion_Fragment;

export type ScreeningForScreeningResourceEditorFragment = {
  __typename?: "Screening";
  id: string;
  screeningId: string;
  allowWebSearch: boolean;
  allowChatGPT: boolean;
  allowHint: boolean;
  acceptableLimit: number | null;
  deadlineSeconds: number | null;
  name: string;
  description: string;
  numAccepted: number;
  memo: string | null;
  messageForCandidate: string | null;
  ownerEmailAddress: string | null;
  timeLimitSeconds: number;
  candidateAccessPolicy: ScreeningCandidateAccessPolicy;
  invitationLanguage: Language;
  isActive: boolean;
  spotIsPublic: boolean;
  entityTracks: Array<
    | {
        __typename: "ScreeningFixedEntityTrack";
        trackId: number;
        questionScoreWeight: number;
        entitySourceQuestion:
          | {
              __typename: "ScreeningChallengeEntitySourceQuestion";
              enabledLanguages: Array<string>;
              questionWeight: number;
              question: {
                __typename: "AlgorithmQuestion";
                questionId: number;
                version: string;
                key: string;
                titleJa: string | null;
                titleEn: string | null;
                supportedLanguages: Array<string>;
                difficulty: Difficulty;
                isPrivate: boolean;
                isOfficial: boolean;
                timeLimitSeconds: number | null;
                numUses: number;
                isSupportedLeakScore: boolean;
                accuracyRate: number;
                averageElapsedTimeSeconds: number;
                algorithmQuestionVariant: AlgorithmQuestionVariant;
                algorithmQuestionStatus: QuestionStatus;
                leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
              };
            }
          | {
              __typename: "ScreeningProjectEntitySourceQuestion";
              questionWeight: number;
              question: {
                __typename: "ProjectQuestion";
                questionId: number;
                version: string;
                projectVersion: string;
                key: string;
                companyId: number;
                titleJa: string | null;
                titleEn: string | null;
                difficulty: Difficulty;
                isPrivate: boolean;
                isOfficial: boolean;
                isSupportedLeakScore: boolean;
                timeLimitSeconds: number | null;
                numUses: number;
                accuracyRate: number;
                averageElapsedTimeSeconds: number;
                projectQuestionVariant: ProjectQuestionVariant;
                projectQuestionStatus: ProjectQuestionStatus;
                leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
              };
            }
          | {
              __typename: "ScreeningQuizEntitySourcePackage";
              questionWeight: number;
              pb_package: {
                __typename: "MultiChoicePackage";
                packageId: number;
                version: string;
                key: string;
                titleJa: string | null;
                titleEn: string | null;
                difficulty: Difficulty;
                isPrivate: boolean;
                isOfficial: boolean;
                isSupportedLeakScore: boolean;
                timeLimitSeconds: number | null;
                numUses: number;
                accuracyRate: number;
                averageElapsedTimeSeconds: number;
                multiChoicePackageStatus: QuestionStatus;
                leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
              };
            }
          | {
              __typename: "ScreeningSystemDesignEntitySourceQuestion";
              questionWeight: number;
              componentTypes: Array<SystemDesignComponentType>;
              question: {
                __typename: "SystemDesignQuestion";
                questionId: number;
                key: string;
                titleJa: string | null;
                titleEn: string | null;
                difficulty: Difficulty;
                isPrivate: boolean;
                isOfficial: boolean;
                isSupportedLeakScore: boolean;
                timeLimitSeconds: number | null;
                numUses: number;
                accuracyRate: number;
                averageElapsedTimeSeconds: number;
                systemDesignQuestionStatus: SystemDesignQuestionStatus;
                leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
              };
            };
      }
    | {
        __typename: "ScreeningRandomFromQuestionsEntityTrack";
        trackId: number;
        questionScoreWeight: number;
        entitySourceQuestions: Array<
          | {
              __typename: "ScreeningChallengeEntitySourceQuestion";
              enabledLanguages: Array<string>;
              questionWeight: number;
              question: {
                __typename: "AlgorithmQuestion";
                questionId: number;
                version: string;
                key: string;
                titleJa: string | null;
                titleEn: string | null;
                supportedLanguages: Array<string>;
                difficulty: Difficulty;
                isPrivate: boolean;
                isOfficial: boolean;
                timeLimitSeconds: number | null;
                numUses: number;
                isSupportedLeakScore: boolean;
                accuracyRate: number;
                averageElapsedTimeSeconds: number;
                algorithmQuestionVariant: AlgorithmQuestionVariant;
                algorithmQuestionStatus: QuestionStatus;
                leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
              };
            }
          | {
              __typename: "ScreeningProjectEntitySourceQuestion";
              questionWeight: number;
              question: {
                __typename: "ProjectQuestion";
                questionId: number;
                version: string;
                projectVersion: string;
                key: string;
                companyId: number;
                titleJa: string | null;
                titleEn: string | null;
                difficulty: Difficulty;
                isPrivate: boolean;
                isOfficial: boolean;
                isSupportedLeakScore: boolean;
                timeLimitSeconds: number | null;
                numUses: number;
                accuracyRate: number;
                averageElapsedTimeSeconds: number;
                projectQuestionVariant: ProjectQuestionVariant;
                projectQuestionStatus: ProjectQuestionStatus;
                leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
              };
            }
          | {
              __typename: "ScreeningQuizEntitySourcePackage";
              questionWeight: number;
              pb_package: {
                __typename: "MultiChoicePackage";
                packageId: number;
                version: string;
                key: string;
                titleJa: string | null;
                titleEn: string | null;
                difficulty: Difficulty;
                isPrivate: boolean;
                isOfficial: boolean;
                isSupportedLeakScore: boolean;
                timeLimitSeconds: number | null;
                numUses: number;
                accuracyRate: number;
                averageElapsedTimeSeconds: number;
                multiChoicePackageStatus: QuestionStatus;
                leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
              };
            }
          | {
              __typename: "ScreeningSystemDesignEntitySourceQuestion";
              questionWeight: number;
              componentTypes: Array<SystemDesignComponentType>;
              question: {
                __typename: "SystemDesignQuestion";
                questionId: number;
                key: string;
                titleJa: string | null;
                titleEn: string | null;
                difficulty: Difficulty;
                isPrivate: boolean;
                isOfficial: boolean;
                isSupportedLeakScore: boolean;
                timeLimitSeconds: number | null;
                numUses: number;
                accuracyRate: number;
                averageElapsedTimeSeconds: number;
                systemDesignQuestionStatus: SystemDesignQuestionStatus;
                leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
              };
            }
        >;
      }
    | {
        __typename: "ScreeningSelectableFromQuestionsEntityTrack";
        trackId: number;
        questionScoreWeight: number;
        entitySourceQuestions: Array<
          | {
              __typename: "ScreeningChallengeEntitySourceQuestion";
              enabledLanguages: Array<string>;
              questionWeight: number;
              question: {
                __typename: "AlgorithmQuestion";
                questionId: number;
                version: string;
                key: string;
                titleJa: string | null;
                titleEn: string | null;
                supportedLanguages: Array<string>;
                difficulty: Difficulty;
                isPrivate: boolean;
                isOfficial: boolean;
                timeLimitSeconds: number | null;
                numUses: number;
                isSupportedLeakScore: boolean;
                accuracyRate: number;
                averageElapsedTimeSeconds: number;
                algorithmQuestionVariant: AlgorithmQuestionVariant;
                algorithmQuestionStatus: QuestionStatus;
                leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
              };
            }
          | {
              __typename: "ScreeningProjectEntitySourceQuestion";
              questionWeight: number;
              question: {
                __typename: "ProjectQuestion";
                questionId: number;
                version: string;
                projectVersion: string;
                key: string;
                companyId: number;
                titleJa: string | null;
                titleEn: string | null;
                difficulty: Difficulty;
                isPrivate: boolean;
                isOfficial: boolean;
                isSupportedLeakScore: boolean;
                timeLimitSeconds: number | null;
                numUses: number;
                accuracyRate: number;
                averageElapsedTimeSeconds: number;
                projectQuestionVariant: ProjectQuestionVariant;
                projectQuestionStatus: ProjectQuestionStatus;
                leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
              };
            }
          | {
              __typename: "ScreeningQuizEntitySourcePackage";
              questionWeight: number;
              pb_package: {
                __typename: "MultiChoicePackage";
                packageId: number;
                version: string;
                key: string;
                titleJa: string | null;
                titleEn: string | null;
                difficulty: Difficulty;
                isPrivate: boolean;
                isOfficial: boolean;
                isSupportedLeakScore: boolean;
                timeLimitSeconds: number | null;
                numUses: number;
                accuracyRate: number;
                averageElapsedTimeSeconds: number;
                multiChoicePackageStatus: QuestionStatus;
                leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
              };
            }
          | {
              __typename: "ScreeningSystemDesignEntitySourceQuestion";
              questionWeight: number;
              componentTypes: Array<SystemDesignComponentType>;
              question: {
                __typename: "SystemDesignQuestion";
                questionId: number;
                key: string;
                titleJa: string | null;
                titleEn: string | null;
                difficulty: Difficulty;
                isPrivate: boolean;
                isOfficial: boolean;
                isSupportedLeakScore: boolean;
                timeLimitSeconds: number | null;
                numUses: number;
                accuracyRate: number;
                averageElapsedTimeSeconds: number;
                systemDesignQuestionStatus: SystemDesignQuestionStatus;
                leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
              };
            }
        >;
      }
  >;
  variables: Array<{
    __typename?: "ScreeningVariable";
    screeningId: string;
    variableId: number;
    label: string;
    description: string;
    variableField:
      | {
          __typename: "ScreeningBooleanFieldVariable";
          validationRule: { __typename: "ScreeningBooleanFieldVariableValidationRule"; required: boolean };
        }
      | {
          __typename: "ScreeningIntegerFieldVariable";
          validationRule: {
            __typename: "ScreeningIntegerFieldVariableValidationRule";
            required: boolean;
            maxIntegerValue: number | null;
            minIntegerValue: number | null;
          };
        }
      | {
          __typename: "ScreeningStringFieldVariable";
          formType: ScreeningStringVariableFieldFormType;
          validationRule: {
            __typename: "ScreeningStringFieldVariableValidationRule";
            required: boolean;
            maxLength: number | null;
            minLength: number | null;
          };
        };
  }>;
  createdBy: { __typename?: "User"; id: string; uid: string; displayName: string } | null;
  viewers: Array<
    | { __typename?: "EmployeeGroup"; id: string; employeeGroupId: string; groupName: string }
    | { __typename?: "User"; id: string; uid: string; displayName: string }
  >;
  tags: Array<{ __typename?: "SpotTag"; name: string }>;
};

export type GetScreeningResourceEditorQueryVariables = Exact<{
  screeningId: Scalars["String"]["input"];
}>;

export type GetScreeningResourceEditorQuery = {
  __typename?: "Query";
  screening: {
    __typename?: "Screening";
    id: string;
    screeningId: string;
    allowWebSearch: boolean;
    allowChatGPT: boolean;
    allowHint: boolean;
    acceptableLimit: number | null;
    deadlineSeconds: number | null;
    name: string;
    description: string;
    numAccepted: number;
    memo: string | null;
    messageForCandidate: string | null;
    ownerEmailAddress: string | null;
    timeLimitSeconds: number;
    candidateAccessPolicy: ScreeningCandidateAccessPolicy;
    invitationLanguage: Language;
    isActive: boolean;
    spotIsPublic: boolean;
    entityTracks: Array<
      | {
          __typename: "ScreeningFixedEntityTrack";
          trackId: number;
          questionScoreWeight: number;
          entitySourceQuestion:
            | {
                __typename: "ScreeningChallengeEntitySourceQuestion";
                enabledLanguages: Array<string>;
                questionWeight: number;
                question: {
                  __typename: "AlgorithmQuestion";
                  questionId: number;
                  version: string;
                  key: string;
                  titleJa: string | null;
                  titleEn: string | null;
                  supportedLanguages: Array<string>;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  isSupportedLeakScore: boolean;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  algorithmQuestionVariant: AlgorithmQuestionVariant;
                  algorithmQuestionStatus: QuestionStatus;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              }
            | {
                __typename: "ScreeningProjectEntitySourceQuestion";
                questionWeight: number;
                question: {
                  __typename: "ProjectQuestion";
                  questionId: number;
                  version: string;
                  projectVersion: string;
                  key: string;
                  companyId: number;
                  titleJa: string | null;
                  titleEn: string | null;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  isSupportedLeakScore: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  projectQuestionVariant: ProjectQuestionVariant;
                  projectQuestionStatus: ProjectQuestionStatus;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              }
            | {
                __typename: "ScreeningQuizEntitySourcePackage";
                questionWeight: number;
                pb_package: {
                  __typename: "MultiChoicePackage";
                  packageId: number;
                  version: string;
                  key: string;
                  titleJa: string | null;
                  titleEn: string | null;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  isSupportedLeakScore: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  multiChoicePackageStatus: QuestionStatus;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              }
            | {
                __typename: "ScreeningSystemDesignEntitySourceQuestion";
                questionWeight: number;
                componentTypes: Array<SystemDesignComponentType>;
                question: {
                  __typename: "SystemDesignQuestion";
                  questionId: number;
                  key: string;
                  titleJa: string | null;
                  titleEn: string | null;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  isSupportedLeakScore: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  systemDesignQuestionStatus: SystemDesignQuestionStatus;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              };
        }
      | {
          __typename: "ScreeningRandomFromQuestionsEntityTrack";
          trackId: number;
          questionScoreWeight: number;
          entitySourceQuestions: Array<
            | {
                __typename: "ScreeningChallengeEntitySourceQuestion";
                enabledLanguages: Array<string>;
                questionWeight: number;
                question: {
                  __typename: "AlgorithmQuestion";
                  questionId: number;
                  version: string;
                  key: string;
                  titleJa: string | null;
                  titleEn: string | null;
                  supportedLanguages: Array<string>;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  isSupportedLeakScore: boolean;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  algorithmQuestionVariant: AlgorithmQuestionVariant;
                  algorithmQuestionStatus: QuestionStatus;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              }
            | {
                __typename: "ScreeningProjectEntitySourceQuestion";
                questionWeight: number;
                question: {
                  __typename: "ProjectQuestion";
                  questionId: number;
                  version: string;
                  projectVersion: string;
                  key: string;
                  companyId: number;
                  titleJa: string | null;
                  titleEn: string | null;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  isSupportedLeakScore: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  projectQuestionVariant: ProjectQuestionVariant;
                  projectQuestionStatus: ProjectQuestionStatus;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              }
            | {
                __typename: "ScreeningQuizEntitySourcePackage";
                questionWeight: number;
                pb_package: {
                  __typename: "MultiChoicePackage";
                  packageId: number;
                  version: string;
                  key: string;
                  titleJa: string | null;
                  titleEn: string | null;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  isSupportedLeakScore: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  multiChoicePackageStatus: QuestionStatus;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              }
            | {
                __typename: "ScreeningSystemDesignEntitySourceQuestion";
                questionWeight: number;
                componentTypes: Array<SystemDesignComponentType>;
                question: {
                  __typename: "SystemDesignQuestion";
                  questionId: number;
                  key: string;
                  titleJa: string | null;
                  titleEn: string | null;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  isSupportedLeakScore: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  systemDesignQuestionStatus: SystemDesignQuestionStatus;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              }
          >;
        }
      | {
          __typename: "ScreeningSelectableFromQuestionsEntityTrack";
          trackId: number;
          questionScoreWeight: number;
          entitySourceQuestions: Array<
            | {
                __typename: "ScreeningChallengeEntitySourceQuestion";
                enabledLanguages: Array<string>;
                questionWeight: number;
                question: {
                  __typename: "AlgorithmQuestion";
                  questionId: number;
                  version: string;
                  key: string;
                  titleJa: string | null;
                  titleEn: string | null;
                  supportedLanguages: Array<string>;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  isSupportedLeakScore: boolean;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  algorithmQuestionVariant: AlgorithmQuestionVariant;
                  algorithmQuestionStatus: QuestionStatus;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              }
            | {
                __typename: "ScreeningProjectEntitySourceQuestion";
                questionWeight: number;
                question: {
                  __typename: "ProjectQuestion";
                  questionId: number;
                  version: string;
                  projectVersion: string;
                  key: string;
                  companyId: number;
                  titleJa: string | null;
                  titleEn: string | null;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  isSupportedLeakScore: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  projectQuestionVariant: ProjectQuestionVariant;
                  projectQuestionStatus: ProjectQuestionStatus;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              }
            | {
                __typename: "ScreeningQuizEntitySourcePackage";
                questionWeight: number;
                pb_package: {
                  __typename: "MultiChoicePackage";
                  packageId: number;
                  version: string;
                  key: string;
                  titleJa: string | null;
                  titleEn: string | null;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  isSupportedLeakScore: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  multiChoicePackageStatus: QuestionStatus;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              }
            | {
                __typename: "ScreeningSystemDesignEntitySourceQuestion";
                questionWeight: number;
                componentTypes: Array<SystemDesignComponentType>;
                question: {
                  __typename: "SystemDesignQuestion";
                  questionId: number;
                  key: string;
                  titleJa: string | null;
                  titleEn: string | null;
                  difficulty: Difficulty;
                  isPrivate: boolean;
                  isOfficial: boolean;
                  isSupportedLeakScore: boolean;
                  timeLimitSeconds: number | null;
                  numUses: number;
                  accuracyRate: number;
                  averageElapsedTimeSeconds: number;
                  systemDesignQuestionStatus: SystemDesignQuestionStatus;
                  leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
                };
              }
          >;
        }
    >;
    variables: Array<{
      __typename?: "ScreeningVariable";
      screeningId: string;
      variableId: number;
      label: string;
      description: string;
      variableField:
        | {
            __typename: "ScreeningBooleanFieldVariable";
            validationRule: { __typename: "ScreeningBooleanFieldVariableValidationRule"; required: boolean };
          }
        | {
            __typename: "ScreeningIntegerFieldVariable";
            validationRule: {
              __typename: "ScreeningIntegerFieldVariableValidationRule";
              required: boolean;
              maxIntegerValue: number | null;
              minIntegerValue: number | null;
            };
          }
        | {
            __typename: "ScreeningStringFieldVariable";
            formType: ScreeningStringVariableFieldFormType;
            validationRule: {
              __typename: "ScreeningStringFieldVariableValidationRule";
              required: boolean;
              maxLength: number | null;
              minLength: number | null;
            };
          };
    }>;
    createdBy: { __typename?: "User"; id: string; uid: string; displayName: string } | null;
    viewers: Array<
      | { __typename?: "EmployeeGroup"; id: string; employeeGroupId: string; groupName: string }
      | { __typename?: "User"; id: string; uid: string; displayName: string }
    >;
    tags: Array<{ __typename?: "SpotTag"; name: string }>;
  };
};

export type UpdateSpotForScreeningTestOverviewMutationVariables = Exact<{
  input: UpdateSpotInput;
}>;

export type UpdateSpotForScreeningTestOverviewMutation = {
  __typename?: "Mutation";
  updateSpot: {
    __typename?: "Spot";
    id: string;
    originId: string;
    parentId: string;
    candidateName: string;
    candidateEmail: string;
    ownerEmailAddress: string | null;
    messageForCandidate: string | null;
    evaluationComment: string;
    isPassed: boolean;
    createdAtSeconds: number | null;
    declinedAtSeconds: number | null;
    deletedAtSeconds: number | null;
    didEndAtSeconds: number | null;
    didStartAtSeconds: number | null;
    evaluatedAtSeconds: number | null;
    finalizedAtSeconds: number | null;
    isPublic: boolean | null;
    memo: string | null;
    name: string;
    status: SpotStatus;
    timeLimitSeconds: number;
    willExpireAtSeconds: number | null;
    invitationEmail: string;
    invitationLanguage: Language | null;
    invitationMethod: InvitationMethod;
    allowWebSearch: boolean;
    allowChatGPT: boolean;
    allowHint: boolean;
    filledVariables: Array<{
      __typename?: "SpotFilledVariable";
      filledVariableId: number;
      label: string;
      description: string;
      valueType: ScreeningValueType;
      value: { __typename?: "ScreeningGenericValue"; stringifiedValue: string } | null;
    }>;
    entities: Array<
      | {
          __typename?: "ChallengeEntity";
          id: string;
          challengeEntityId: number;
          enabledLanguages: Array<string>;
          challengeStatus: ChallengeStatus;
          challengeQuestion: {
            __typename?: "AlgorithmQuestion";
            id: string;
            questionId: number;
            version: string;
            titleJa: string | null;
            titleEn: string | null;
            descriptionEn: string | null;
            descriptionJa: string | null;
            difficulty: Difficulty;
            algorithmQuestionStatus: QuestionStatus;
          } | null;
        }
      | {
          __typename?: "ProjectEntity";
          id: string;
          projectEntityId: number;
          projectStatus: ProjectStatus;
          question: {
            __typename?: "ProjectQuestion";
            id: string;
            questionId: number;
            version: string;
            titleEn: string | null;
            titleJa: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            projectQuestionStatus: ProjectQuestionStatus;
          } | null;
        }
      | {
          __typename?: "QuizEntity";
          id: string;
          quizEntityId: number;
          quizStatus: QuizStatus;
          pb_package: {
            __typename?: "MultiChoicePackage";
            id: string;
            packageId: number;
            version: string;
            titleEn: string | null;
            titleJa: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            multiChoicePackageStatus: QuestionStatus;
          } | null;
        }
      | {
          __typename?: "SystemDesignEntity";
          id: string;
          systemDesignEntityId: number;
          componentTypes: Array<SystemDesignComponentType>;
          systemDesignStatus: SystemDesignStatus;
          question: {
            __typename?: "SystemDesignQuestion";
            id: string;
            questionId: number;
            titleJa: string | null;
            titleEn: string | null;
            descriptionJa: string | null;
            descriptionEn: string | null;
            difficulty: Difficulty;
            systemDesignQuestionStatus: SystemDesignQuestionStatus;
          } | null;
        }
    >;
    issuedEntities: Array<
      | {
          __typename?: "SpotIssuedChallengeEntity";
          questionWeight: number;
          entity: {
            __typename?: "ChallengeEntity";
            id: string;
            challengeEntityId: number;
            enabledLanguages: Array<string>;
            challengeStatus: ChallengeStatus;
            challengeQuestion: {
              __typename?: "AlgorithmQuestion";
              id: string;
              questionId: number;
              version: string;
              titleJa: string | null;
              titleEn: string | null;
              descriptionEn: string | null;
              descriptionJa: string | null;
              difficulty: Difficulty;
              algorithmQuestionStatus: QuestionStatus;
            } | null;
          };
        }
      | {
          __typename?: "SpotIssuedProjectEntity";
          questionWeight: number;
          entity: {
            __typename?: "ProjectEntity";
            id: string;
            projectEntityId: number;
            projectStatus: ProjectStatus;
            question: {
              __typename?: "ProjectQuestion";
              id: string;
              questionId: number;
              version: string;
              titleEn: string | null;
              titleJa: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
              difficulty: Difficulty;
              projectQuestionStatus: ProjectQuestionStatus;
            } | null;
          };
        }
      | {
          __typename?: "SpotIssuedQuizEntity";
          questionWeight: number;
          entity: {
            __typename?: "QuizEntity";
            id: string;
            quizEntityId: number;
            quizStatus: QuizStatus;
            pb_package: {
              __typename?: "MultiChoicePackage";
              id: string;
              packageId: number;
              version: string;
              titleEn: string | null;
              titleJa: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
              difficulty: Difficulty;
              multiChoicePackageStatus: QuestionStatus;
            } | null;
          };
        }
      | {
          __typename?: "SpotIssuedSystemDesignEntity";
          questionWeight: number;
          entity: {
            __typename?: "SystemDesignEntity";
            id: string;
            systemDesignEntityId: number;
            componentTypes: Array<SystemDesignComponentType>;
            systemDesignStatus: SystemDesignStatus;
            question: {
              __typename?: "SystemDesignQuestion";
              id: string;
              questionId: number;
              titleJa: string | null;
              titleEn: string | null;
              descriptionJa: string | null;
              descriptionEn: string | null;
              difficulty: Difficulty;
              systemDesignQuestionStatus: SystemDesignQuestionStatus;
            } | null;
          };
        }
    >;
    reviewers: Array<{
      __typename: "SpotReviewer";
      type: ReviewerType;
      employee: {
        __typename?: "User";
        id: string;
        uid: string;
        displayName: string;
        email: string;
        photoUrl: string;
        isSystemAdmin: boolean;
      } | null;
      group: {
        __typename?: "EmployeeGroup";
        id: string;
        groupName: string;
        companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string; role: Role }>;
      } | null;
    }>;
    viewers: Array<
      { __typename: "EmployeeGroup"; employeeGroupId: string; groupName: string } | { __typename: "User"; uid: string; displayName: string }
    >;
    tags: Array<{ __typename: "SpotTag"; name: string }>;
    employee: {
      __typename?: "User";
      id: string;
      uid: string;
      displayName: string;
      email: string;
      photoUrl: string;
      isSystemAdmin: boolean;
    } | null;
    evaluatedBy: { __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string } | null;
  } | null;
};

export type ListSpotTagsForScreeningTestOverviewQueryVariables = Exact<{
  input: SpotTagsByCompanyIdInput;
}>;

export type ListSpotTagsForScreeningTestOverviewQuery = {
  __typename?: "Query";
  spotTagsByCompanyId: {
    __typename?: "SpotTagsByCompanyIdResponse";
    count: number;
    tags: Array<{ __typename: "SpotTag"; name: string }>;
    pager: { __typename?: "SpotPager"; hasNext: boolean; cursorSeconds: number | null } | null;
  } | null;
};

export type ChallengeEntityForScreeningTestResourceEditorFragment = {
  __typename?: "ChallengeEntity";
  id: string;
  enabledLanguages: Array<string>;
  challengeQuestion: {
    __typename: "AlgorithmQuestion";
    questionId: number;
    version: string;
    key: string;
    titleJa: string | null;
    titleEn: string | null;
    supportedLanguages: Array<string>;
    difficulty: Difficulty;
    isPrivate: boolean;
    isOfficial: boolean;
    timeLimitSeconds: number | null;
    numUses: number;
    isSupportedLeakScore: boolean;
    accuracyRate: number;
    averageElapsedTimeSeconds: number;
    algorithmQuestionVariant: AlgorithmQuestionVariant;
    algorithmQuestionStatus: QuestionStatus;
    leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
  } | null;
};

export type ProjectEntityForScreeningTestResourceEditorFragment = {
  __typename?: "ProjectEntity";
  id: string;
  question: {
    __typename: "ProjectQuestion";
    questionId: number;
    version: string;
    projectVersion: string;
    key: string;
    companyId: number;
    titleJa: string | null;
    titleEn: string | null;
    difficulty: Difficulty;
    isPrivate: boolean;
    isOfficial: boolean;
    isSupportedLeakScore: boolean;
    timeLimitSeconds: number | null;
    numUses: number;
    accuracyRate: number;
    averageElapsedTimeSeconds: number;
    projectQuestionVariant: ProjectQuestionVariant;
    projectQuestionStatus: ProjectQuestionStatus;
    leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
  } | null;
};

export type QuizEntityForScreeningTestResourceEditorFragment = {
  __typename?: "QuizEntity";
  id: string;
  pb_package: {
    __typename: "MultiChoicePackage";
    packageId: number;
    version: string;
    key: string;
    titleJa: string | null;
    titleEn: string | null;
    difficulty: Difficulty;
    isPrivate: boolean;
    isOfficial: boolean;
    isSupportedLeakScore: boolean;
    timeLimitSeconds: number | null;
    numUses: number;
    accuracyRate: number;
    averageElapsedTimeSeconds: number;
    multiChoicePackageStatus: QuestionStatus;
    leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
  } | null;
};

export type SystemDesignEntityForScreeningTestResourceEditorFragment = {
  __typename?: "SystemDesignEntity";
  id: string;
  componentTypes: Array<SystemDesignComponentType>;
  question: {
    __typename: "SystemDesignQuestion";
    questionId: number;
    key: string;
    titleJa: string | null;
    titleEn: string | null;
    difficulty: Difficulty;
    isPrivate: boolean;
    isOfficial: boolean;
    isSupportedLeakScore: boolean;
    timeLimitSeconds: number | null;
    numUses: number;
    accuracyRate: number;
    averageElapsedTimeSeconds: number;
    systemDesignQuestionStatus: SystemDesignQuestionStatus;
    leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
  } | null;
};

export type SpotForScreeningTestResourceEditorFragment = {
  __typename?: "Spot";
  id: string;
  spotId: string;
  originId: string;
  allowWebSearch: boolean;
  allowChatGPT: boolean;
  name: string;
  memo: string | null;
  messageForCandidate: string | null;
  status: SpotStatus;
  willExpireAtSeconds: number | null;
  timeLimitSeconds: number;
  invitationMethod: InvitationMethod;
  invitationEmail: string;
  invitationLanguage: Language | null;
  isPublic: boolean | null;
  employee: { __typename?: "User"; id: string; displayName: string } | null;
  reviewers: Array<{
    __typename?: "SpotReviewer";
    type: ReviewerType;
    employee: { __typename?: "User"; id: string; displayName: string } | null;
    group: { __typename?: "EmployeeGroup"; id: string; groupName: string } | null;
  }>;
  tags: Array<{ __typename?: "SpotTag"; name: string }>;
  entities: Array<
    | {
        __typename: "ChallengeEntity";
        id: string;
        enabledLanguages: Array<string>;
        challengeQuestion: {
          __typename: "AlgorithmQuestion";
          questionId: number;
          version: string;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          supportedLanguages: Array<string>;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          isSupportedLeakScore: boolean;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          algorithmQuestionVariant: AlgorithmQuestionVariant;
          algorithmQuestionStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        } | null;
      }
    | {
        __typename: "ProjectEntity";
        id: string;
        question: {
          __typename: "ProjectQuestion";
          questionId: number;
          version: string;
          projectVersion: string;
          key: string;
          companyId: number;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          projectQuestionVariant: ProjectQuestionVariant;
          projectQuestionStatus: ProjectQuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        } | null;
      }
    | {
        __typename: "QuizEntity";
        id: string;
        pb_package: {
          __typename: "MultiChoicePackage";
          packageId: number;
          version: string;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          multiChoicePackageStatus: QuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        } | null;
      }
    | {
        __typename: "SystemDesignEntity";
        id: string;
        componentTypes: Array<SystemDesignComponentType>;
        question: {
          __typename: "SystemDesignQuestion";
          questionId: number;
          key: string;
          titleJa: string | null;
          titleEn: string | null;
          difficulty: Difficulty;
          isPrivate: boolean;
          isOfficial: boolean;
          isSupportedLeakScore: boolean;
          timeLimitSeconds: number | null;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          systemDesignQuestionStatus: SystemDesignQuestionStatus;
          leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
        } | null;
      }
  >;
};

export type GetScreeningTestResourceEditorQueryVariables = Exact<{
  spotId: Scalars["String"]["input"];
  withAnswers: Scalars["Boolean"]["input"];
}>;

export type GetScreeningTestResourceEditorQuery = {
  __typename?: "Query";
  spot: {
    __typename?: "Spot";
    id: string;
    spotId: string;
    originId: string;
    allowWebSearch: boolean;
    allowChatGPT: boolean;
    name: string;
    memo: string | null;
    messageForCandidate: string | null;
    status: SpotStatus;
    willExpireAtSeconds: number | null;
    timeLimitSeconds: number;
    invitationMethod: InvitationMethod;
    invitationEmail: string;
    invitationLanguage: Language | null;
    isPublic: boolean | null;
    employee: { __typename?: "User"; id: string; displayName: string } | null;
    reviewers: Array<{
      __typename?: "SpotReviewer";
      type: ReviewerType;
      employee: { __typename?: "User"; id: string; displayName: string } | null;
      group: { __typename?: "EmployeeGroup"; id: string; groupName: string } | null;
    }>;
    tags: Array<{ __typename?: "SpotTag"; name: string }>;
    entities: Array<
      | {
          __typename: "ChallengeEntity";
          id: string;
          enabledLanguages: Array<string>;
          challengeQuestion: {
            __typename: "AlgorithmQuestion";
            questionId: number;
            version: string;
            key: string;
            titleJa: string | null;
            titleEn: string | null;
            supportedLanguages: Array<string>;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            timeLimitSeconds: number | null;
            numUses: number;
            isSupportedLeakScore: boolean;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            algorithmQuestionVariant: AlgorithmQuestionVariant;
            algorithmQuestionStatus: QuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          } | null;
        }
      | {
          __typename: "ProjectEntity";
          id: string;
          question: {
            __typename: "ProjectQuestion";
            questionId: number;
            version: string;
            projectVersion: string;
            key: string;
            companyId: number;
            titleJa: string | null;
            titleEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            timeLimitSeconds: number | null;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            projectQuestionVariant: ProjectQuestionVariant;
            projectQuestionStatus: ProjectQuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          } | null;
        }
      | {
          __typename: "QuizEntity";
          id: string;
          pb_package: {
            __typename: "MultiChoicePackage";
            packageId: number;
            version: string;
            key: string;
            titleJa: string | null;
            titleEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            timeLimitSeconds: number | null;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            multiChoicePackageStatus: QuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          } | null;
        }
      | {
          __typename: "SystemDesignEntity";
          id: string;
          componentTypes: Array<SystemDesignComponentType>;
          question: {
            __typename: "SystemDesignQuestion";
            questionId: number;
            key: string;
            titleJa: string | null;
            titleEn: string | null;
            difficulty: Difficulty;
            isPrivate: boolean;
            isOfficial: boolean;
            isSupportedLeakScore: boolean;
            timeLimitSeconds: number | null;
            numUses: number;
            accuracyRate: number;
            averageElapsedTimeSeconds: number;
            systemDesignQuestionStatus: SystemDesignQuestionStatus;
            leakScore: { __typename?: "LeakScore"; level: AlgorithmQuestionLeakScoreLevel } | null;
          } | null;
        }
    >;
  };
};

export type GetCompanyForSecuritySettingsSecurityFragment = {
  __typename?: "Security";
  emailDomain: string;
  idpCertificate: string;
  idpEntityId: string;
  idpSsoUrl: string;
  providerId: string;
  ssoEnabled: boolean;
  ssoMode: SsoMode;
};

export type GetCompanyForSecuritySettingsQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
}>;

export type GetCompanyForSecuritySettingsQuery = {
  __typename?: "Query";
  company: {
    __typename?: "Company";
    id: string;
    security: {
      __typename?: "Security";
      emailDomain: string;
      idpCertificate: string;
      idpEntityId: string;
      idpSsoUrl: string;
      providerId: string;
      ssoEnabled: boolean;
      ssoMode: SsoMode;
    } | null;
  };
};

export type GetSecurityByEmailQueryVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type GetSecurityByEmailQuery = { __typename?: "Query"; securityByEmail: { __typename?: "Security"; providerId: string } };

export type UpdateSecurityMutationVariables = Exact<{
  updateSecurityInput: UpdateSecurityInput;
}>;

export type UpdateSecurityMutation = {
  __typename?: "Mutation";
  updateSecurity: {
    __typename?: "Security";
    emailDomain: string;
    idpCertificate: string;
    idpEntityId: string;
    idpSsoUrl: string;
    providerId: string;
    ssoEnabled: boolean;
    ssoMode: SsoMode;
  } | null;
};

export type AuthorizeSlackForSlackNotificationSettingsMutationVariables = Exact<{
  input: AuthorizeSlackInput;
}>;

export type AuthorizeSlackForSlackNotificationSettingsMutation = {
  __typename?: "Mutation";
  authorizeSlack: {
    __typename?: "SlackSettings";
    companyId: number;
    isAuthorized: boolean;
    incomingWebHook: {
      __typename?: "SlackIncomingWebhook";
      webhookUrl: string;
      channel: string;
      channelId: string;
      configurationUrl: string;
    } | null;
  } | null;
};

export type SpotTagsByCompanyIdResponseForSpotTagFieldFragment = {
  __typename?: "SpotTagsByCompanyIdResponse";
  count: number;
  tags: Array<{ __typename?: "SpotTag"; name: string; companyId: number }>;
  pager: { __typename?: "SpotPager"; cursorSeconds: number | null; hasNext: boolean } | null;
};

export type ListSpotTagsForSpotTagFieldQueryVariables = Exact<{
  spotTagsByCompanyId: SpotTagsByCompanyIdInput;
}>;

export type ListSpotTagsForSpotTagFieldQuery = {
  __typename?: "Query";
  spotTagsByCompanyId: {
    __typename?: "SpotTagsByCompanyIdResponse";
    count: number;
    tags: Array<{ __typename?: "SpotTag"; name: string; companyId: number }>;
    pager: { __typename?: "SpotPager"; cursorSeconds: number | null; hasNext: boolean } | null;
  } | null;
};

export type InvoiceForSubscriptionInvoiceV2Fragment = {
  __typename?: "PaymentV2Invoice";
  invoiceId: number;
  customerId: number;
  invoiceUrl: string;
  totalPrice: number;
  status: InvoiceStatus;
  dueDateSeconds: number | null;
  createdAtSeconds: number | null;
};

export type PaymentPagerForSubscriptionInvoiceV2Fragment = {
  __typename?: "PaymentPager";
  cursorSeconds: number | null;
  cursorNanoSeconds: number | null;
  hasNext: boolean;
};

export type InvoicesResponseForSubscriptionInvoiceV2Fragment = {
  __typename?: "InvoicesResponse";
  count: number;
  invoices: Array<{
    __typename?: "PaymentV2Invoice";
    invoiceId: number;
    customerId: number;
    invoiceUrl: string;
    totalPrice: number;
    status: InvoiceStatus;
    dueDateSeconds: number | null;
    createdAtSeconds: number | null;
  }>;
  pager: { __typename?: "PaymentPager"; cursorSeconds: number | null; cursorNanoSeconds: number | null; hasNext: boolean } | null;
};

export type GetInvoicesForSubscriptionInvoiceV2QueryVariables = Exact<{
  customerId: Scalars["Int"]["input"];
  cursorSeconds: InputMaybe<Scalars["Int"]["input"]>;
  cursorNanoSeconds: InputMaybe<Scalars["Int"]["input"]>;
  size: Scalars["Int"]["input"];
}>;

export type GetInvoicesForSubscriptionInvoiceV2Query = {
  __typename?: "Query";
  invoicesV2: {
    __typename?: "InvoicesResponse";
    count: number;
    invoices: Array<{
      __typename?: "PaymentV2Invoice";
      invoiceId: number;
      customerId: number;
      invoiceUrl: string;
      totalPrice: number;
      status: InvoiceStatus;
      dueDateSeconds: number | null;
      createdAtSeconds: number | null;
    }>;
    pager: { __typename?: "PaymentPager"; cursorSeconds: number | null; cursorNanoSeconds: number | null; hasNext: boolean } | null;
  };
};

export type ContractPeriodUsageForSubscriptionOverviewV2Fragment = {
  __typename?: "ContractPeriodUsage";
  yearMonth: string;
  totalCount: number;
  paidCount: number;
  freeCount: number;
};

export type CustomerForSubscriptionOverviewV2Fragment = {
  __typename?: "PaymentV2Customer";
  subscription: {
    __typename?: "PaymentV2Subscription";
    packStock: number;
    didStartAtSeconds: number | null;
    willEndAtSeconds: number | null;
    plan: { __typename?: "PaymentV2Plan"; planType: PlanType; generation: number };
  };
};

export type GetContractPeriodUsagesForSubscriptionOverviewV2QueryVariables = Exact<{
  customerId: Scalars["Int"]["input"];
  startedAtSeconds: Scalars["Int"]["input"];
  contractPeriod: Scalars["Int"]["input"];
}>;

export type GetContractPeriodUsagesForSubscriptionOverviewV2Query = {
  __typename?: "Query";
  contractPeriodUsagesV2: {
    __typename?: "ContractPeriodUsagesResponse";
    contractPeriodUsages: Array<{
      __typename?: "ContractPeriodUsage";
      yearMonth: string;
      totalCount: number;
      paidCount: number;
      freeCount: number;
    }>;
  };
  customer: {
    __typename?: "PaymentV2Customer";
    subscription: {
      __typename?: "PaymentV2Subscription";
      packStock: number;
      didStartAtSeconds: number | null;
      willEndAtSeconds: number | null;
      plan: { __typename?: "PaymentV2Plan"; planType: PlanType; generation: number };
    };
  };
};

export type PaymentPagerForSubscriptionUsageV2Fragment = {
  __typename?: "PaymentPager";
  cursorSeconds: number | null;
  cursorNanoSeconds: number | null;
  hasNext: boolean;
};

export type PaymentUsageForSubscriptionUsageV2Fragment = {
  __typename?: "PaymentUsage";
  usageId: number;
  interviewId: string;
  interviewType: InterviewType;
  price: number;
  reasonType: PaymentReasonType;
  createdAtSeconds: number | null;
};

export type UsagesV2ResponseForSubscriptionUsageV2Fragment = {
  __typename?: "UsagesV2Response";
  count: number;
  usages: Array<{
    __typename?: "PaymentUsage";
    usageId: number;
    interviewId: string;
    interviewType: InterviewType;
    price: number;
    reasonType: PaymentReasonType;
    createdAtSeconds: number | null;
  }>;
  pager: { __typename?: "PaymentPager"; cursorSeconds: number | null; cursorNanoSeconds: number | null; hasNext: boolean } | null;
};

export type GetSubscribeUsagesForSubscriptionUsageV2QueryVariables = Exact<{
  customerId: Scalars["Int"]["input"];
  usagePeriodStartSeconds: Scalars["Int"]["input"];
  cursorSeconds: InputMaybe<Scalars["Int"]["input"]>;
  cursorNanoSeconds: InputMaybe<Scalars["Int"]["input"]>;
  size: Scalars["Int"]["input"];
}>;

export type GetSubscribeUsagesForSubscriptionUsageV2Query = {
  __typename?: "Query";
  usagesV2: {
    __typename?: "UsagesV2Response";
    count: number;
    usages: Array<{
      __typename?: "PaymentUsage";
      usageId: number;
      interviewId: string;
      interviewType: InterviewType;
      price: number;
      reasonType: PaymentReasonType;
      createdAtSeconds: number | null;
    }>;
    pager: { __typename?: "PaymentPager"; cursorSeconds: number | null; cursorNanoSeconds: number | null; hasNext: boolean } | null;
  };
};

export type RunSystemDesignMutationVariables = Exact<{
  systemDesignId: Scalars["Int"]["input"];
  snapshot: Scalars["String"]["input"];
  scoringItemId: Scalars["Int"]["input"];
  takeSnapshot: Scalars["Boolean"]["input"];
}>;

export type RunSystemDesignMutation = {
  __typename?: "Mutation";
  runSystemDesign: {
    __typename?: "RunSystemDesignResponse";
    isPassed: boolean;
    matchedIds: Array<string>;
    messageJa: string;
    messageEn: string;
    snapshotId: number;
  };
};

export type SaveSystemDesignAppealMessageMutationVariables = Exact<{
  systemDesignId: Scalars["Int"]["input"];
  appealMessage: Scalars["String"]["input"];
}>;

export type SaveSystemDesignAppealMessageMutation = {
  __typename?: "Mutation";
  saveSystemDesignAppealMessage: { __typename?: "SystemDesignEntity"; systemDesignEntityId: number; appealMessage: string | null } | null;
};

export type EnqueueSystemDesignSubmissionMutationVariables = Exact<{
  systemDesignId: Scalars["Int"]["input"];
  questionId: Scalars["Int"]["input"];
  snapshot: Scalars["String"]["input"];
}>;

export type EnqueueSystemDesignSubmissionMutation = {
  __typename?: "Mutation";
  enqueueSystemDesignSubmission: {
    __typename?: "SystemDesignSubmission";
    systemDesignSubmissionId: number;
    status: SystemDesignSubmissionStatus;
  } | null;
};

export type StartSystemDesignMutationVariables = Exact<{
  systemDesignId: Scalars["Int"]["input"];
  candidateId: Scalars["String"]["input"];
}>;

export type StartSystemDesignMutation = {
  __typename?: "Mutation";
  startSystemDesign: {
    __typename: "SystemDesignEntity";
    systemDesignEntityId: number;
    status: SystemDesignStatus;
    companyId: number;
    candidateId: string;
    employeeId: string;
    appealMessage: string | null;
    componentTypes: Array<SystemDesignComponentType>;
    question: {
      __typename?: "SystemDesignQuestion";
      questionId: number;
      titleJa: string | null;
      titleEn: string | null;
      descriptionJa: string | null;
      descriptionEn: string | null;
      initialSnapshot: string;
      hints: Array<{ __typename?: "SystemDesignHint"; systemDesignHintId: number; descriptionJa: string | null; descriptionEn: string | null }>;
      scoringItems: Array<{
        __typename?: "SystemDesignScoringItem";
        systemDesignScoringItemId: number;
        category: SystemDesignScoringItemCategory;
        isHidden: boolean;
      }>;
    } | null;
    submissions: Array<{ __typename?: "SystemDesignSubmission"; systemDesignSubmissionId: number; snapshot: string }>;
  };
};

export type StatisticsStatsForSystemDesignRankVisualizerFragment = {
  __typename?: "StatisticsStats";
  avg: number;
  max: number;
  min: number;
  p25: number;
  p50: number;
  p75: number;
  p95: number;
  std: number;
};

export type SystemDesignStatisticsForSystemDesignRankVisualizerFragment = {
  __typename?: "SystemDesignStatistics";
  bins: Array<number>;
  totalScore: {
    __typename?: "StatisticsStats";
    avg: number;
    max: number;
    min: number;
    p25: number;
    p50: number;
    p75: number;
    p95: number;
    std: number;
  } | null;
  availability: {
    __typename?: "StatisticsStats";
    avg: number;
    max: number;
    min: number;
    p25: number;
    p50: number;
    p75: number;
    p95: number;
    std: number;
  } | null;
  scalability: {
    __typename?: "StatisticsStats";
    avg: number;
    max: number;
    min: number;
    p25: number;
    p50: number;
    p75: number;
    p95: number;
    std: number;
  } | null;
  numTabEvents: {
    __typename?: "StatisticsStats";
    avg: number;
    max: number;
    min: number;
    p25: number;
    p50: number;
    p75: number;
    p95: number;
    std: number;
  } | null;
  consistency: {
    __typename?: "StatisticsStats";
    avg: number;
    max: number;
    min: number;
    p25: number;
    p50: number;
    p75: number;
    p95: number;
    std: number;
  } | null;
  numHints: {
    __typename?: "StatisticsStats";
    avg: number;
    max: number;
    min: number;
    p25: number;
    p50: number;
    p75: number;
    p95: number;
    std: number;
  } | null;
  numSnapshots: {
    __typename?: "StatisticsStats";
    avg: number;
    max: number;
    min: number;
    p25: number;
    p50: number;
    p75: number;
    p95: number;
    std: number;
  } | null;
  elapsedTime: {
    __typename?: "StatisticsStats";
    avg: number;
    max: number;
    min: number;
    p25: number;
    p50: number;
    p75: number;
    p95: number;
    std: number;
  } | null;
};

export type GetSystemDesignStatisticsQueryVariables = Exact<{
  statisticsSource: StatisticsSourceInput;
  rankSource: RankSourceInput;
}>;

export type GetSystemDesignStatisticsQuery = {
  __typename?: "Query";
  statistics: {
    __typename?: "Statistics";
    systemDesignStatistics: {
      __typename?: "SystemDesignStatistics";
      bins: Array<number>;
      totalScore: {
        __typename?: "StatisticsStats";
        avg: number;
        max: number;
        min: number;
        p25: number;
        p50: number;
        p75: number;
        p95: number;
        std: number;
      } | null;
      availability: {
        __typename?: "StatisticsStats";
        avg: number;
        max: number;
        min: number;
        p25: number;
        p50: number;
        p75: number;
        p95: number;
        std: number;
      } | null;
      scalability: {
        __typename?: "StatisticsStats";
        avg: number;
        max: number;
        min: number;
        p25: number;
        p50: number;
        p75: number;
        p95: number;
        std: number;
      } | null;
      numTabEvents: {
        __typename?: "StatisticsStats";
        avg: number;
        max: number;
        min: number;
        p25: number;
        p50: number;
        p75: number;
        p95: number;
        std: number;
      } | null;
      consistency: {
        __typename?: "StatisticsStats";
        avg: number;
        max: number;
        min: number;
        p25: number;
        p50: number;
        p75: number;
        p95: number;
        std: number;
      } | null;
      numHints: {
        __typename?: "StatisticsStats";
        avg: number;
        max: number;
        min: number;
        p25: number;
        p50: number;
        p75: number;
        p95: number;
        std: number;
      } | null;
      numSnapshots: {
        __typename?: "StatisticsStats";
        avg: number;
        max: number;
        min: number;
        p25: number;
        p50: number;
        p75: number;
        p95: number;
        std: number;
      } | null;
      elapsedTime: {
        __typename?: "StatisticsStats";
        avg: number;
        max: number;
        min: number;
        p25: number;
        p50: number;
        p75: number;
        p95: number;
        std: number;
      } | null;
    } | null;
  };
  rank: { __typename?: "StatisticsRank"; evaluation: StatisticsEvaluation; numSubset: number; rank: number; relativeScore: number };
};

export type SystemDesignEvaluationForSystemDesignTestReportFragment = {
  __typename?: "SystemDesignEvaluation";
  totalScore: number;
  evaluatedBy: string;
  availability: number;
  scalability: number;
  consistency: number;
  result: string;
};

export type SystemDesignSubmissionForSystemDesignTestReportFragment = {
  __typename?: "SystemDesignSubmission";
  systemDesignSubmissionId: number;
  isBest: boolean;
  status: SystemDesignSubmissionStatus;
  snapshot: string;
  numTabEvents: number;
  numAccessEvents: number;
  totalScore: number;
  numSubset: number;
  totalElapsedTimeSeconds: number | null;
  elapsedTimeSeconds: number | null;
  evaluatedAtSeconds: number | null;
  evaluations: Array<{
    __typename?: "SystemDesignEvaluation";
    totalScore: number;
    evaluatedBy: string;
    availability: number;
    scalability: number;
    consistency: number;
    result: string;
  }>;
  usedHints: Array<{ __typename?: "SystemDesignHint"; systemDesignHintId: number }>;
  snapshots: Array<{
    __typename?: "SystemDesignSnapshot";
    systemDesignSnapshotId: number;
    scoringItemId: number;
    isPassed: boolean;
    matchedIds: Array<string>;
  }>;
};

export type SystemDesignScoringItemForSystemDesignTestReportFragment = {
  __typename?: "SystemDesignScoringItem";
  systemDesignScoringItemId: number;
  category: SystemDesignScoringItemCategory;
  titleJa: string;
  titleEn: string;
  descriptionJa: string;
  descriptionEn: string;
  graphlets: string;
  isHidden: boolean;
};

export type GetSystemDesignSubmissionForSystemDesignTestReportQueryVariables = Exact<{
  submissionId: Scalars["Int"]["input"];
}>;

export type GetSystemDesignSubmissionForSystemDesignTestReportQuery = {
  __typename?: "Query";
  systemDesignSubmission: {
    __typename?: "SystemDesignSubmission";
    systemDesignSubmissionId: number;
    isBest: boolean;
    status: SystemDesignSubmissionStatus;
    snapshot: string;
    numTabEvents: number;
    numAccessEvents: number;
    totalScore: number;
    numSubset: number;
    totalElapsedTimeSeconds: number | null;
    elapsedTimeSeconds: number | null;
    evaluatedAtSeconds: number | null;
    evaluations: Array<{
      __typename?: "SystemDesignEvaluation";
      totalScore: number;
      evaluatedBy: string;
      availability: number;
      scalability: number;
      consistency: number;
      result: string;
    }>;
    usedHints: Array<{ __typename?: "SystemDesignHint"; systemDesignHintId: number }>;
    snapshots: Array<{
      __typename?: "SystemDesignSnapshot";
      systemDesignSnapshotId: number;
      scoringItemId: number;
      isPassed: boolean;
      matchedIds: Array<string>;
    }>;
  };
};

export type SystemDesignQuestionDetailForSystemDesignTestReportFragment = {
  __typename?: "SystemDesignQuestion";
  questionId: number;
  titleEn: string | null;
  titleJa: string | null;
  difficulty: Difficulty;
  descriptionJa: string | null;
  descriptionEn: string | null;
  initialSnapshot: string;
  numUses: number;
  accuracyRate: number;
  averageElapsedTimeSeconds: number;
  timeLimitSeconds: number | null;
  isOfficial: boolean;
  systemDesignQuestionStatus: SystemDesignQuestionStatus;
  hints: Array<{ __typename?: "SystemDesignHint"; descriptionJa: string | null; descriptionEn: string | null }>;
  answers: Array<{
    __typename?: "SystemDesignAnswer";
    id: string;
    titleJa: string;
    titleEn: string;
    descriptionJa: string;
    descriptionEn: string;
  }>;
  scoringItems: Array<{
    __typename?: "SystemDesignScoringItem";
    systemDesignScoringItemId: number;
    category: SystemDesignScoringItemCategory;
    titleJa: string;
    titleEn: string;
    descriptionJa: string;
    descriptionEn: string;
    graphlets: string;
    isHidden: boolean;
  }>;
};

export type SystemDesignEntityForSystemDesignTestReportFragment = {
  __typename?: "SystemDesignEntity";
  id: string;
  entityId: number;
  systemDesignEntityId: number;
  totalScore: number;
  evaluatedAtSeconds: number | null;
  appealMessage: string | null;
  systemDesignStatus: SystemDesignStatus;
  submissions: Array<{
    __typename?: "SystemDesignSubmission";
    systemDesignSubmissionId: number;
    isBest: boolean;
    status: SystemDesignSubmissionStatus;
    snapshot: string;
    numTabEvents: number;
    numAccessEvents: number;
    totalScore: number;
    numSubset: number;
    totalElapsedTimeSeconds: number | null;
    elapsedTimeSeconds: number | null;
    evaluatedAtSeconds: number | null;
    evaluations: Array<{
      __typename?: "SystemDesignEvaluation";
      totalScore: number;
      evaluatedBy: string;
      availability: number;
      scalability: number;
      consistency: number;
      result: string;
    }>;
    usedHints: Array<{ __typename?: "SystemDesignHint"; systemDesignHintId: number }>;
    snapshots: Array<{
      __typename?: "SystemDesignSnapshot";
      systemDesignSnapshotId: number;
      scoringItemId: number;
      isPassed: boolean;
      matchedIds: Array<string>;
    }>;
  }>;
  question: {
    __typename?: "SystemDesignQuestion";
    questionId: number;
    titleEn: string | null;
    titleJa: string | null;
    difficulty: Difficulty;
    descriptionJa: string | null;
    descriptionEn: string | null;
    initialSnapshot: string;
    numUses: number;
    accuracyRate: number;
    averageElapsedTimeSeconds: number;
    timeLimitSeconds: number | null;
    isOfficial: boolean;
    systemDesignQuestionStatus: SystemDesignQuestionStatus;
    hints: Array<{ __typename?: "SystemDesignHint"; descriptionJa: string | null; descriptionEn: string | null }>;
    answers: Array<{
      __typename?: "SystemDesignAnswer";
      id: string;
      titleJa: string;
      titleEn: string;
      descriptionJa: string;
      descriptionEn: string;
    }>;
    scoringItems: Array<{
      __typename?: "SystemDesignScoringItem";
      systemDesignScoringItemId: number;
      category: SystemDesignScoringItemCategory;
      titleJa: string;
      titleEn: string;
      descriptionJa: string;
      descriptionEn: string;
      graphlets: string;
      isHidden: boolean;
    }>;
  } | null;
};

export type GetSystemDesignEntityForSystemDesignTestReportQueryVariables = Exact<{
  systemDesignId: Scalars["Int"]["input"];
  withAnswers: Scalars["Boolean"]["input"];
}>;

export type GetSystemDesignEntityForSystemDesignTestReportQuery = {
  __typename?: "Query";
  systemDesignEntity: {
    __typename?: "SystemDesignEntity";
    id: string;
    entityId: number;
    systemDesignEntityId: number;
    totalScore: number;
    evaluatedAtSeconds: number | null;
    appealMessage: string | null;
    systemDesignStatus: SystemDesignStatus;
    submissions: Array<{
      __typename?: "SystemDesignSubmission";
      systemDesignSubmissionId: number;
      isBest: boolean;
      status: SystemDesignSubmissionStatus;
      snapshot: string;
      numTabEvents: number;
      numAccessEvents: number;
      totalScore: number;
      numSubset: number;
      totalElapsedTimeSeconds: number | null;
      elapsedTimeSeconds: number | null;
      evaluatedAtSeconds: number | null;
      evaluations: Array<{
        __typename?: "SystemDesignEvaluation";
        totalScore: number;
        evaluatedBy: string;
        availability: number;
        scalability: number;
        consistency: number;
        result: string;
      }>;
      usedHints: Array<{ __typename?: "SystemDesignHint"; systemDesignHintId: number }>;
      snapshots: Array<{
        __typename?: "SystemDesignSnapshot";
        systemDesignSnapshotId: number;
        scoringItemId: number;
        isPassed: boolean;
        matchedIds: Array<string>;
      }>;
    }>;
    question: {
      __typename?: "SystemDesignQuestion";
      questionId: number;
      titleEn: string | null;
      titleJa: string | null;
      difficulty: Difficulty;
      descriptionJa: string | null;
      descriptionEn: string | null;
      initialSnapshot: string;
      numUses: number;
      accuracyRate: number;
      averageElapsedTimeSeconds: number;
      timeLimitSeconds: number | null;
      isOfficial: boolean;
      systemDesignQuestionStatus: SystemDesignQuestionStatus;
      hints: Array<{ __typename?: "SystemDesignHint"; descriptionJa: string | null; descriptionEn: string | null }>;
      answers: Array<{
        __typename?: "SystemDesignAnswer";
        id: string;
        titleJa: string;
        titleEn: string;
        descriptionJa: string;
        descriptionEn: string;
      }>;
      scoringItems: Array<{
        __typename?: "SystemDesignScoringItem";
        systemDesignScoringItemId: number;
        category: SystemDesignScoringItemCategory;
        titleJa: string;
        titleEn: string;
        descriptionJa: string;
        descriptionEn: string;
        graphlets: string;
        isHidden: boolean;
      }>;
    } | null;
  };
};

export type ReevaluateSystemDesignMutationVariables = Exact<{
  systemDesignId: Scalars["Int"]["input"];
  submissionId: Scalars["Int"]["input"];
  evaluationSources: Array<EvaluationSourceInput> | EvaluationSourceInput;
}>;

export type ReevaluateSystemDesignMutation = {
  __typename?: "Mutation";
  reevaluateSystemDesign: {
    __typename?: "ReevaluateSystemDesignResponse";
    submission: {
      __typename?: "SystemDesignSubmission";
      systemDesign: {
        __typename?: "SystemDesignEntity";
        id: string;
        entityId: number;
        systemDesignEntityId: number;
        totalScore: number;
        evaluatedAtSeconds: number | null;
        appealMessage: string | null;
        systemDesignStatus: SystemDesignStatus;
        submissions: Array<{
          __typename?: "SystemDesignSubmission";
          systemDesignSubmissionId: number;
          isBest: boolean;
          status: SystemDesignSubmissionStatus;
          snapshot: string;
          numTabEvents: number;
          numAccessEvents: number;
          totalScore: number;
          numSubset: number;
          totalElapsedTimeSeconds: number | null;
          elapsedTimeSeconds: number | null;
          evaluatedAtSeconds: number | null;
          evaluations: Array<{
            __typename?: "SystemDesignEvaluation";
            totalScore: number;
            evaluatedBy: string;
            availability: number;
            scalability: number;
            consistency: number;
            result: string;
          }>;
          usedHints: Array<{ __typename?: "SystemDesignHint"; systemDesignHintId: number }>;
          snapshots: Array<{
            __typename?: "SystemDesignSnapshot";
            systemDesignSnapshotId: number;
            scoringItemId: number;
            isPassed: boolean;
            matchedIds: Array<string>;
          }>;
        }>;
        question: {
          __typename?: "SystemDesignQuestion";
          questionId: number;
          titleEn: string | null;
          titleJa: string | null;
          difficulty: Difficulty;
          descriptionJa: string | null;
          descriptionEn: string | null;
          initialSnapshot: string;
          numUses: number;
          accuracyRate: number;
          averageElapsedTimeSeconds: number;
          timeLimitSeconds: number | null;
          isOfficial: boolean;
          systemDesignQuestionStatus: SystemDesignQuestionStatus;
          hints: Array<{ __typename?: "SystemDesignHint"; descriptionJa: string | null; descriptionEn: string | null }>;
          answers: Array<{
            __typename?: "SystemDesignAnswer";
            id: string;
            titleJa: string;
            titleEn: string;
            descriptionJa: string;
            descriptionEn: string;
          }>;
          scoringItems: Array<{
            __typename?: "SystemDesignScoringItem";
            systemDesignScoringItemId: number;
            category: SystemDesignScoringItemCategory;
            titleJa: string;
            titleEn: string;
            descriptionJa: string;
            descriptionEn: string;
            graphlets: string;
            isHidden: boolean;
          }>;
        } | null;
      };
    } | null;
  };
};

export type TalentForViewerAssignFieldFragment = {
  __typename?: "User";
  uid: string;
  displayName: string;
  fullName: string;
  email: string;
  photoUrl: string;
};

export type CompanyForTalentAssignFieldFragment = {
  __typename?: "Company";
  companyId: number;
  name: string;
  talents: Array<{ __typename?: "User"; uid: string; displayName: string; fullName: string; email: string; photoUrl: string }>;
};

export type GetTalentsForTalentAssignFieldQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
}>;

export type GetTalentsForTalentAssignFieldQuery = {
  __typename?: "Query";
  company: {
    __typename?: "Company";
    companyId: number;
    name: string;
    talents: Array<{ __typename?: "User"; uid: string; displayName: string; fullName: string; email: string; photoUrl: string }>;
  };
};

export type TalentForTalentMemberSettingsFragment = {
  __typename?: "User";
  id: string;
  displayName: string;
  fullName: string;
  email: string;
  photoUrl: string;
};

export type CompanyTalentForTalentMemberSettingsFragment = { __typename?: "CompanyTalent"; id: string; companyId: number; talentId: string };

export type CompanyForTalentMemberSettingsFragment = {
  __typename?: "Company";
  companyId: number;
  name: string;
  talents: Array<{ __typename?: "User"; id: string; displayName: string; fullName: string; email: string; photoUrl: string }>;
  companyTalents: Array<{ __typename?: "CompanyTalent"; id: string; companyId: number; talentId: string }>;
};

export type GetCompanyForTalentMemberSettingsQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
}>;

export type GetCompanyForTalentMemberSettingsQuery = {
  __typename?: "Query";
  company: {
    __typename?: "Company";
    companyId: number;
    name: string;
    talents: Array<{ __typename?: "User"; id: string; displayName: string; fullName: string; email: string; photoUrl: string }>;
    companyTalents: Array<{ __typename?: "CompanyTalent"; id: string; companyId: number; talentId: string }>;
  };
};

export type CreateTalentInvitationMutationVariables = Exact<{
  input: CreateTalentInvitationInput;
}>;

export type CreateTalentInvitationMutation = {
  __typename?: "Mutation";
  createTalentInvitation: {
    __typename?: "TalentInvitation";
    talentInvitationId: string;
    companyId: number;
    createdBy: string;
    invitationMethod: InvitationMethod;
    emailAddresses: Array<string>;
    expiresAtSeconds: number | null;
    invitationLanguage: InvitationLanguage;
  };
};

export type DeleteMultiCompanyTalentsForTalentMemberSettingsMutationVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  talentIds: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type DeleteMultiCompanyTalentsForTalentMemberSettingsMutation = { __typename?: "Mutation"; deleteMultiCompanyTalents: boolean };

export type DeleteCompanyTalentForTalentMemberSettingsMutationVariables = Exact<{
  input: DeleteCompanyTalentInput;
}>;

export type DeleteCompanyTalentForTalentMemberSettingsMutation = { __typename?: "Mutation"; deleteCompanyTalent: boolean };

export type EvaluationMetricsForTechnicalCommentEvaluationsFormFragment = {
  __typename?: "EvaluationMetric";
  id: string;
  metricId: number;
  title: string;
  description: string;
};

export type ListEvaluationMetricsForTechnicalCommentEvaluationsFormQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  size: Scalars["Int"]["input"];
  cursor: InputMaybe<Scalars["Int"]["input"]>;
  withCount: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type ListEvaluationMetricsForTechnicalCommentEvaluationsFormQuery = {
  __typename?: "Query";
  evaluationMetricsByCompany: {
    __typename?: "EvaluationMetricsByCompanyResponse";
    count: number;
    metrics: Array<{ __typename?: "EvaluationMetric"; id: string; metricId: number; title: string; description: string }>;
    pager: { __typename?: "EvaluationPager"; cursor: number; hasNext: boolean } | null;
  } | null;
};

export type ReviewExamForTechnicalCommentMutationVariables = Exact<{
  input: ReviewExamRequestInput;
}>;

export type ReviewExamForTechnicalCommentMutation = {
  __typename?: "Mutation";
  reviewExam: {
    __typename?: "Exam";
    id: string;
    recommendations: Array<{
      __typename?: "EvaluationRecommendation";
      id: string;
      employeeId: string;
      evaluationRecommendationId: number;
      isRecommended: boolean;
      comment: string;
      createdAtSeconds: number | null;
      evaluations: Array<{
        __typename?: "Evaluation";
        id: string;
        evaluationId: number;
        employeeId: string;
        metricId: number;
        comment: string;
        numStars: number;
        metric: { __typename?: "EvaluationMetric"; id: string; title: string; metricId: number; description: string } | null;
      }>;
      reviewer: { __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string };
    }>;
  };
};

export type EvaluationMetricGroupsForTechnicalCommentFragment = {
  __typename?: "EvaluationMetricGroup";
  id: string;
  metricGroupId: number;
  title: string;
  description: string;
  metrics: Array<{ __typename?: "EvaluationMetric"; id: string; metricId: number; title: string; description: string }>;
};

export type ListEvaluationMetricGroupsByCompanyIdForTechnicalCommentQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  size: Scalars["Int"]["input"];
  cursor: InputMaybe<Scalars["Int"]["input"]>;
  withCount: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type ListEvaluationMetricGroupsByCompanyIdForTechnicalCommentQuery = {
  __typename?: "Query";
  evaluationMetricGroupsByCompany: {
    __typename: "EvaluationMetricGroupsByCompanyResponse";
    metricGroups: Array<{
      __typename?: "EvaluationMetricGroup";
      id: string;
      metricGroupId: number;
      title: string;
      description: string;
      metrics: Array<{ __typename?: "EvaluationMetric"; id: string; metricId: number; title: string; description: string }>;
    }>;
    pager: { __typename?: "EvaluationPager"; cursor: number; hasNext: boolean } | null;
  } | null;
};

export type GetEvaluationMetricGroupForTechnicalCommentQueryVariables = Exact<{
  metricGroupId: Scalars["Int"]["input"];
}>;

export type GetEvaluationMetricGroupForTechnicalCommentQuery = {
  __typename?: "Query";
  evaluationMetricGroup: {
    __typename?: "EvaluationMetricGroup";
    id: string;
    metricGroupId: number;
    title: string;
    description: string;
    metrics: Array<{ __typename?: "EvaluationMetric"; id: string; metricId: number; title: string; description: string }>;
  };
};

export type ReviewSpotForTechnicalCommentMutationVariables = Exact<{
  payload: ReviewSpotInput;
}>;

export type ReviewSpotForTechnicalCommentMutation = {
  __typename?: "Mutation";
  reviewSpot: {
    __typename?: "Spot";
    id: string;
    recommendations: Array<{
      __typename?: "EvaluationRecommendation";
      id: string;
      employeeId: string;
      evaluationRecommendationId: number;
      isRecommended: boolean;
      comment: string;
      createdAtSeconds: number | null;
      evaluations: Array<{
        __typename?: "Evaluation";
        id: string;
        evaluationId: number;
        employeeId: string;
        metricId: number;
        comment: string;
        numStars: number;
        metric: { __typename?: "EvaluationMetric"; id: string; title: string; metricId: number; description: string } | null;
      }>;
      reviewer: { __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string };
    }>;
  };
};

export type EvaluationMetricForTechnicalCommentRemoteFragment = {
  __typename?: "EvaluationMetric";
  id: string;
  metricId: number;
  title: string;
  description: string;
};

export type GetEvaluationMetricsForTechnicalCommentForRemoteQueryVariables = Exact<{
  metricsIds: Array<Scalars["Int"]["input"]> | Scalars["Int"]["input"];
}>;

export type GetEvaluationMetricsForTechnicalCommentForRemoteQuery = {
  __typename?: "Query";
  evaluationMetricsByIds: Array<{ __typename?: "EvaluationMetric"; id: string; metricId: number; title: string; description: string }>;
};

export type ListEvaluationMetricsByCompanyIdForTechnicalCommentPromptForRemoteQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
  size: Scalars["Int"]["input"];
  cursor: InputMaybe<Scalars["Int"]["input"]>;
  withCount: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type ListEvaluationMetricsByCompanyIdForTechnicalCommentPromptForRemoteQuery = {
  __typename?: "Query";
  evaluationMetricsByCompany: {
    __typename: "EvaluationMetricsByCompanyResponse";
    metrics: Array<{ __typename?: "EvaluationMetric"; id: string; metricId: number; title: string; description: string }>;
    pager: { __typename?: "EvaluationPager"; cursor: number; hasNext: boolean } | null;
  } | null;
};

export type ReviewRemoteForTechnicalCommentRemoteMutationVariables = Exact<{
  input: ReviewRemoteInput;
}>;

export type ReviewRemoteForTechnicalCommentRemoteMutation = {
  __typename?: "Mutation";
  reviewRemote: {
    __typename?: "Remote";
    id: string;
    remoteId: string;
    recommendations: Array<{
      __typename?: "EvaluationRecommendation";
      id: string;
      employeeId: string;
      evaluationRecommendationId: number;
      isRecommended: boolean;
      createdAtSeconds: number | null;
      comment: string;
      evaluations: Array<{
        __typename?: "Evaluation";
        id: string;
        evaluationId: number;
        employeeId: string;
        metricId: number;
        comment: string;
        numStars: number;
        metric: { __typename?: "EvaluationMetric"; id: string; title: string; metricId: number; description: string } | null;
      }>;
      reviewer: { __typename?: "User"; id: string; displayName: string; email: string; photoUrl: string };
    }>;
  };
};

export type SpotForTestRankVisualizerFragment = {
  __typename?: "Spot";
  id: string;
  name: string;
  totalScore: number;
  rank: number;
  numSubset: number;
  company: { __typename?: "Company"; id: string; name: string } | null;
};

export type ListSpotsByTagsForTestRankVisualizerQueryVariables = Exact<{
  spotsByCompanyId: SpotsByCompanyIdInput;
}>;

export type ListSpotsByTagsForTestRankVisualizerQuery = {
  __typename?: "Query";
  spotsByCompanyId: {
    __typename?: "SpotsByCompanyIdResponse";
    count: number;
    hasNext: boolean;
    spots: Array<{
      __typename?: "Spot";
      id: string;
      name: string;
      totalScore: number;
      rank: number;
      numSubset: number;
      company: { __typename?: "Company"; id: string; name: string } | null;
    }>;
    pager: { __typename?: "SpotPager"; hasNext: boolean; cursorSeconds: number | null } | null;
  } | null;
};

export type SpotStatisticsForTestRankVisualizerFragment = {
  __typename: "SpotStatistics";
  bins: Array<number>;
  relativeScore: {
    __typename?: "StatisticsStats";
    avg: number;
    max: number;
    min: number;
    p25: number;
    p50: number;
    p75: number;
    p95: number;
    std: number;
  } | null;
};

export type RankForTestRankVisualizerFragment = {
  __typename?: "StatisticsRank";
  evaluation: StatisticsEvaluation;
  numSubset: number;
  rank: number;
  relativeScore: number;
};

export type GetSpotStatisticsAndRankForTestRankVisualizerQueryVariables = Exact<{
  statisticsSource: StatisticsSourceInput;
  rankSource: RankSourceInput;
}>;

export type GetSpotStatisticsAndRankForTestRankVisualizerQuery = {
  __typename?: "Query";
  statistics: {
    __typename: "Statistics";
    spotStatistics: {
      __typename: "SpotStatistics";
      bins: Array<number>;
      relativeScore: {
        __typename?: "StatisticsStats";
        avg: number;
        max: number;
        min: number;
        p25: number;
        p50: number;
        p75: number;
        p95: number;
        std: number;
      } | null;
    } | null;
  };
  rank: { __typename?: "StatisticsRank"; evaluation: StatisticsEvaluation; numSubset: number; rank: number; relativeScore: number };
};

export type ListSpotTagsForTestRankVisualizerFragment = {
  __typename?: "SpotTagsByCompanyIdResponse";
  count: number;
  tags: Array<{ __typename?: "SpotTag"; name: string }>;
  pager: { __typename?: "SpotPager"; cursorSeconds: number | null; hasNext: boolean } | null;
};

export type ListSpotTagsForTestRankVisualizerQueryVariables = Exact<{
  spotTagsByCompanyId: SpotTagsByCompanyIdInput;
}>;

export type ListSpotTagsForTestRankVisualizerQuery = {
  __typename?: "Query";
  spotTagsByCompanyId: {
    __typename?: "SpotTagsByCompanyIdResponse";
    count: number;
    tags: Array<{ __typename?: "SpotTag"; name: string }>;
    pager: { __typename?: "SpotPager"; cursorSeconds: number | null; hasNext: boolean } | null;
  } | null;
};

export type UpdateUserForUserProfileMutationVariables = Exact<{
  updateUserInput: UpdateUserInput;
}>;

export type UpdateUserForUserProfileMutation = {
  __typename?: "Mutation";
  updateUser: {
    __typename?: "User";
    uid: string;
    displayName: string;
    photoUrl: string;
    email: string;
    emailVerified: boolean;
    fullName: string;
    language: Language;
    provider: AuthProvider | null;
    userType: UserType;
  };
};

export type UpdateUserLanguageMutationVariables = Exact<{
  updateUserInput: UpdateUserInput;
}>;

export type UpdateUserLanguageMutation = {
  __typename?: "Mutation";
  updateUser: {
    __typename?: "User";
    uid: string;
    displayName: string;
    photoUrl: string;
    email: string;
    emailVerified: boolean;
    fullName: string;
    language: Language;
    provider: AuthProvider | null;
    userType: UserType;
  };
};

export type UploadUserImageForUserProfileMutationVariables = Exact<{
  uid: Scalars["ID"]["input"];
  image: Scalars["String"]["input"];
}>;

export type UploadUserImageForUserProfileMutation = { __typename?: "Mutation"; uploadUserImage: string };

export type EmployeeGroupForViewerAssignFieldFragment = {
  __typename?: "EmployeeGroup";
  employeeGroupId: string;
  groupName: string;
  createdAtSeconds: number | null;
  companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string }>;
};

export type EmployeeForViewerAssignFieldFragment = {
  __typename?: "User";
  uid: string;
  displayName: string;
  fullName: string;
  email: string;
  photoUrl: string;
};

export type CompanyForViewerAssignFieldFragment = {
  __typename?: "Company";
  companyId: number;
  name: string;
  employees: Array<{ __typename?: "User"; uid: string; displayName: string; fullName: string; email: string; photoUrl: string }>;
  groups: Array<{
    __typename?: "EmployeeGroup";
    employeeGroupId: string;
    groupName: string;
    createdAtSeconds: number | null;
    companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string }>;
  }>;
};

export type GetViewersForViewerAssignFieldQueryVariables = Exact<{
  companyId: Scalars["Int"]["input"];
}>;

export type GetViewersForViewerAssignFieldQuery = {
  __typename?: "Query";
  company: {
    __typename?: "Company";
    companyId: number;
    name: string;
    employees: Array<{ __typename?: "User"; uid: string; displayName: string; fullName: string; email: string; photoUrl: string }>;
    groups: Array<{
      __typename?: "EmployeeGroup";
      employeeGroupId: string;
      groupName: string;
      createdAtSeconds: number | null;
      companyEmployees: Array<{ __typename?: "CompanyEmployee"; employeeId: string }>;
    }>;
  };
};

export type QuizEntityForTestReviewFragment = { __typename: "QuizEntity" };

export type ProjectEntityForTestReviewFragment = {
  __typename: "ProjectEntity";
  projectEntityId: number;
  appealMessage: string | null;
  agentVersion: string;
  projectStatus: ProjectStatus;
  question: {
    __typename?: "ProjectQuestion";
    questionId: number;
    version: string;
    projectVersion: string;
    variant: ProjectQuestionVariant;
    titleJa: string | null;
    titleEn: string | null;
    correctnessTestCase: string;
    performanceTestCase: string;
    defaultTestCase: string;
    descriptionJa: string | null;
    descriptionEn: string | null;
    timeLimitSeconds: number | null;
    fileIndexes: Array<string>;
    readOnlyFiles: Array<string>;
  } | null;
  submissions: Array<{ __typename?: "ProjectSubmission"; projectSubmissionId: number }>;
};

export type ChallengeEntityForTestReviewFragment = { __typename: "ChallengeEntity" };

export type SystemDesignEntityForTestReviewFragment = { __typename: "SystemDesignEntity" };

export type AssessmentForAssessmentsForExamTabTablesForTalentFragment = {
  __typename?: "Assessment";
  assessmentId: string;
  name: string;
  status: AssessmentStatus;
  activatedAtSeconds: number | null;
  createdAtSeconds: number | null;
  updatedAtSeconds: number | null;
  deletedAtSeconds: number | null;
  startScheduleAtSeconds: number | null;
  nextScheduleAtSeconds: number | null;
  examInterval: AssessmentExamInterval;
  remindBeforeSeconds: number | null;
  isSubmittedAtLatestInterval: boolean;
  rootExamIds: Array<string>;
};

export type ListAssessmentsByTalentIdForExamTabTablesForTalentFragment = {
  __typename?: "ListAssessmentsByTalentId";
  count: number;
  hasNext: boolean;
  assessments: Array<{
    __typename?: "Assessment";
    assessmentId: string;
    name: string;
    status: AssessmentStatus;
    activatedAtSeconds: number | null;
    createdAtSeconds: number | null;
    updatedAtSeconds: number | null;
    deletedAtSeconds: number | null;
    startScheduleAtSeconds: number | null;
    nextScheduleAtSeconds: number | null;
    examInterval: AssessmentExamInterval;
    remindBeforeSeconds: number | null;
    isSubmittedAtLatestInterval: boolean;
    rootExamIds: Array<string>;
  }>;
};

export type GetAssessmentsByTalentIdForExamTabTablesForTalentQueryVariables = Exact<{
  talentId: Scalars["String"]["input"];
  withCount: Scalars["Boolean"]["input"];
  withDeleted: Scalars["Boolean"]["input"];
  size: Scalars["Int"]["input"];
  offset: Scalars["Int"]["input"];
  isDescending: Scalars["Boolean"]["input"];
}>;

export type GetAssessmentsByTalentIdForExamTabTablesForTalentQuery = {
  __typename?: "Query";
  listAssessmentsByTalentId: {
    __typename?: "ListAssessmentsByTalentId";
    count: number;
    hasNext: boolean;
    assessments: Array<{
      __typename?: "Assessment";
      assessmentId: string;
      name: string;
      status: AssessmentStatus;
      activatedAtSeconds: number | null;
      createdAtSeconds: number | null;
      updatedAtSeconds: number | null;
      deletedAtSeconds: number | null;
      startScheduleAtSeconds: number | null;
      nextScheduleAtSeconds: number | null;
      examInterval: AssessmentExamInterval;
      remindBeforeSeconds: number | null;
      isSubmittedAtLatestInterval: boolean;
      rootExamIds: Array<string>;
    }>;
  };
};

export type ExamForExamTabTablesForTalentFragment = {
  __typename?: "Exam";
  examId: string;
  status: ExamStatus;
  didStartAtSeconds: number | null;
  didEndAtSeconds: number | null;
  expiredAtSeconds: number | null;
  willEndAtSeconds: number | null;
  totalScore: number;
  rankEvaluation: StatisticsEvaluation;
  isReliableRank: boolean;
  numInterval: number;
  reportSettings: {
    __typename?: "AssessmentReportSettings";
    showAnswer: boolean;
    showPlayback: boolean;
    showRelativeEvaluation: boolean;
  } | null;
};

export type ListBestExamsByAssessmentIdForExamTablesFragment = {
  __typename?: "ListBestExamsByAssessmentId";
  count: number;
  hasNext: boolean;
  exams: Array<{
    __typename?: "Exam";
    examId: string;
    status: ExamStatus;
    didStartAtSeconds: number | null;
    didEndAtSeconds: number | null;
    expiredAtSeconds: number | null;
    willEndAtSeconds: number | null;
    totalScore: number;
    rankEvaluation: StatisticsEvaluation;
    isReliableRank: boolean;
    numInterval: number;
    reportSettings: {
      __typename?: "AssessmentReportSettings";
      showAnswer: boolean;
      showPlayback: boolean;
      showRelativeEvaluation: boolean;
    } | null;
  }>;
};

export type ListBestExamsByAssessmentIdForExamListForTalentQueryVariables = Exact<{
  assessmentId: Scalars["String"]["input"];
  withCount: Scalars["Boolean"]["input"];
  size: Scalars["Int"]["input"];
  offset: Scalars["Int"]["input"];
  isDescending: Scalars["Boolean"]["input"];
}>;

export type ListBestExamsByAssessmentIdForExamListForTalentQuery = {
  __typename?: "Query";
  listBestExamsByAssessmentId: {
    __typename?: "ListBestExamsByAssessmentId";
    count: number;
    hasNext: boolean;
    exams: Array<{
      __typename?: "Exam";
      examId: string;
      status: ExamStatus;
      didStartAtSeconds: number | null;
      didEndAtSeconds: number | null;
      expiredAtSeconds: number | null;
      willEndAtSeconds: number | null;
      totalScore: number;
      rankEvaluation: StatisticsEvaluation;
      isReliableRank: boolean;
      numInterval: number;
      reportSettings: {
        __typename?: "AssessmentReportSettings";
        showAnswer: boolean;
        showPlayback: boolean;
        showRelativeEvaluation: boolean;
      } | null;
    }>;
  };
};

export type ReCreateExamForExamListForTalentMutationVariables = Exact<{
  input: RecreateExamRequestInput;
}>;

export type ReCreateExamForExamListForTalentMutation = { __typename?: "Mutation"; recreateExam: { __typename?: "Exam"; examId: string } };

export type GetTailExamForExamListForTalentQueryVariables = Exact<{
  examId: Scalars["String"]["input"];
}>;

export type GetTailExamForExamListForTalentQuery = {
  __typename?: "Query";
  tailExam: { __typename?: "Exam"; examId: string; status: ExamStatus };
};

export type TalentNotificationForNotificationListForTalentFragment = {
  __typename?: "TalentNotification";
  id: string;
  talentNotificationId: number;
  talentId: string;
  isRead: boolean;
  titleJa: string;
  titleEn: string;
  descriptionJa: string;
  descriptionEn: string;
  createdAtSeconds: number | null;
  companyId: number;
  actionLink: string;
};

export type TalentNotificationsResponseForNotificationListForTalentFragment = {
  __typename?: "TalentNotificationsResponse";
  count: number;
  notifications: Array<{
    __typename?: "TalentNotification";
    id: string;
    talentNotificationId: number;
    talentId: string;
    isRead: boolean;
    titleJa: string;
    titleEn: string;
    descriptionJa: string;
    descriptionEn: string;
    createdAtSeconds: number | null;
    companyId: number;
    actionLink: string;
  }>;
  pager: { __typename?: "NotificationPager"; hasNext: boolean; cursor: number } | null;
};

export type ListTalentNotificationsForNotificationListForTalentQueryVariables = Exact<{
  talentId: Scalars["String"]["input"];
  companyId: Scalars["Int"]["input"];
  cursor: InputMaybe<Scalars["Int"]["input"]>;
  size: Scalars["Int"]["input"];
  withCount: Scalars["Boolean"]["input"];
}>;

export type ListTalentNotificationsForNotificationListForTalentQuery = {
  __typename?: "Query";
  talentNotifications: {
    __typename?: "TalentNotificationsResponse";
    count: number;
    notifications: Array<{
      __typename?: "TalentNotification";
      id: string;
      talentNotificationId: number;
      talentId: string;
      isRead: boolean;
      titleJa: string;
      titleEn: string;
      descriptionJa: string;
      descriptionEn: string;
      createdAtSeconds: number | null;
      companyId: number;
      actionLink: string;
    }>;
    pager: { __typename?: "NotificationPager"; hasNext: boolean; cursor: number } | null;
  };
};

export type ReadTalentNotificationsForNotificationListForTalentMutationVariables = Exact<{
  talentNotificationIds: Array<Scalars["Int"]["input"]> | Scalars["Int"]["input"];
  talentId: Scalars["String"]["input"];
  companyId: Scalars["Int"]["input"];
}>;

export type ReadTalentNotificationsForNotificationListForTalentMutation = {
  __typename?: "Mutation";
  readTalentNotifications: Array<{
    __typename?: "TalentNotification";
    id: string;
    talentNotificationId: number;
    talentId: string;
    isRead: boolean;
    titleJa: string;
    titleEn: string;
    descriptionJa: string;
    descriptionEn: string;
    createdAtSeconds: number | null;
    companyId: number;
    actionLink: string;
  }>;
};

export const EssentialCompanyEmployeeFragmentDoc = gql`
  fragment EssentialCompanyEmployee on CompanyEmployee {
    employeeId
    role
  }
`;
export const EssentialSecurityForEmployeeFragmentDoc = gql`
  fragment EssentialSecurityForEmployee on Security {
    ssoEnabled
    ssoMode
    providerId
  }
`;
export const EssentialCompanyFragmentDoc = gql`
  fragment EssentialCompany on Company {
    companyId
    name
    isHireRoo
    enableTalentScore
    companyEmployees {
      ...EssentialCompanyEmployee
    }
    security {
      ...EssentialSecurityForEmployee
    }
  }
  ${EssentialCompanyEmployeeFragmentDoc}
  ${EssentialSecurityForEmployeeFragmentDoc}
`;
export const EssentialCompaniesFragmentDoc = gql`
  fragment EssentialCompanies on ListCompanies {
    activeCompanyId
    companies {
      ...EssentialCompany
    }
    pager {
      cursorSeconds
      hasNext
    }
  }
  ${EssentialCompanyFragmentDoc}
`;
export const EssentialCustomerForEmployeeFragmentDoc = gql`
  fragment EssentialCustomerForEmployee on PaymentV2Customer {
    paymentStatus
    paymentType
    isTrial
    subscription {
      status
      packStock
      plan {
        generation
        planType
      }
      didStartAtSeconds
    }
    createdAtSeconds
  }
`;
export const EssentialEmployeeFragmentDoc = gql`
  fragment EssentialEmployee on User {
    uid
    displayName
    photoUrl
    email
    emailVerified
    fullName
    language
    provider
    userType
  }
`;
export const InitializeParamsForEmployeeFragmentDoc = gql`
  fragment InitializeParamsForEmployee on InitializedEmployeeUserResponse {
    __typename
    shouldAction
    activeCompany {
      ...EssentialCompany
    }
    belongsCompanies {
      ...EssentialCompany
    }
    customer {
      ...EssentialCustomerForEmployee
    }
    currentUser {
      ...EssentialEmployee
    }
    employeeRole
  }
  ${EssentialCompanyFragmentDoc}
  ${EssentialCustomerForEmployeeFragmentDoc}
  ${EssentialEmployeeFragmentDoc}
`;
export const EssentialTalentFragmentDoc = gql`
  fragment EssentialTalent on User {
    uid
    displayName
    photoUrl
    email
    emailVerified
    fullName
    language
    provider
    userType
  }
`;
export const InitializeParamsForTalentFragmentDoc = gql`
  fragment InitializeParamsForTalent on InitializedTalentUserResponse {
    __typename
    shouldAction
    activeCompany {
      ...EssentialCompany
    }
    belongsCompanies {
      ...EssentialCompany
    }
    currentUser {
      ...EssentialTalent
    }
  }
  ${EssentialCompanyFragmentDoc}
  ${EssentialTalentFragmentDoc}
`;
export const InitializeParamsForAuthFragmentDoc = gql`
  fragment InitializeParamsForAuth on InitializedAuthResponse {
    __typename
    user: currentUser {
      userType
    }
  }
`;
export const InitializeParamsForInvitationEmployeeFragmentDoc = gql`
  fragment InitializeParamsForInvitationEmployee on InitializedInvitationEmployeeResponse {
    __typename
    user: currentUser {
      userType
    }
  }
`;
export const InitializeParamsForInvitationTalentFragmentDoc = gql`
  fragment InitializeParamsForInvitationTalent on InitializedInvitationTalentResponse {
    __typename
    user: currentUser {
      userType
    }
  }
`;
export const EssentialCompanyForTalentFragmentDoc = gql`
  fragment EssentialCompanyForTalent on Company {
    companyId
    name
    enableTalentScore
  }
`;
export const EssentialCompaniesForTalentFragmentDoc = gql`
  fragment EssentialCompaniesForTalent on CompaniesByTalentId {
    activeId
    companies {
      ...EssentialCompanyForTalent
    }
  }
  ${EssentialCompanyForTalentFragmentDoc}
`;
export const EssentialCustomerForTalentFragmentDoc = gql`
  fragment EssentialCustomerForTalent on PaymentV2Customer {
    subscription {
      packStock
      plan {
        generation
        planType
      }
    }
  }
`;
export const EssentialSecurityForTalentFragmentDoc = gql`
  fragment EssentialSecurityForTalent on Security {
    ssoEnabled
    ssoMode
    providerId
  }
`;
export const MultiChoiceQuestionForCandidateInterviewFragmentDoc = gql`
  fragment MultiChoiceQuestionForCandidateInterview on MultiChoiceQuestion {
    id
    version
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    variant
    options {
      id
      titleJa
      titleEn
      isCorrect
    }
  }
`;
export const QuizEntityForCandidateInterviewFragmentDoc = gql`
  fragment QuizEntityForCandidateInterview on QuizEntity {
    __typename
    quizEntityId
    quizStatus: status
    pb_package(withAnswers: false) {
      packageId
      version
      titleJa
      titleEn
      descriptionJa
      descriptionEn
      questions {
        ...MultiChoiceQuestionForCandidateInterview
      }
    }
    submissions {
      quizSubmissionId
      questionId
      questionVersion
      optionIds
    }
    packageVersion
  }
  ${MultiChoiceQuestionForCandidateInterviewFragmentDoc}
`;
export const ProjectQuestionForCandidateInterviewFragmentDoc = gql`
  fragment ProjectQuestionForCandidateInterview on ProjectQuestion {
    questionId
    version
    projectVersion
    variant
    titleJa
    titleEn
    correctnessTestCase
    performanceTestCase
    defaultTestCase
    descriptionJa
    descriptionEn
    timeLimitSeconds
    fileIndexes
    readOnlyFiles
  }
`;
export const ProjectEntityForCandidateInterviewFragmentDoc = gql`
  fragment ProjectEntityForCandidateInterview on ProjectEntity {
    __typename
    projectEntityId
    projectStatus: status
    question {
      ...ProjectQuestionForCandidateInterview
    }
    appealMessage
    submissions {
      projectSubmissionId
    }
    agentVersion
  }
  ${ProjectQuestionForCandidateInterviewFragmentDoc}
`;
export const AlgorithmHintForCandidateInterviewFragmentDoc = gql`
  fragment AlgorithmHintForCandidateInterview on AlgorithmHint {
    id
    questionId
    questionVersion
    descriptionJa
    descriptionEn
    orderNum
  }
`;
export const AlgorithmInitialCodeForCandidateInterviewFragmentDoc = gql`
  fragment AlgorithmInitialCodeForCandidateInterview on AlgorithmInitialCode {
    c
    cpp
    csharp
    dart
    go
    java
    javascript
    kotlin
    mysql
    perl
    pgsql
    php
    python3
    ruby
    rust
    scala
    sqlite
    swift
    typescript
  }
`;
export const AlgorithmQuestionForCandidateInterviewFragmentDoc = gql`
  fragment AlgorithmQuestionForCandidateInterview on AlgorithmQuestion {
    id
    questionId
    version
    variant
    companyId
    employeeId
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    difficulty
    supportedLanguages
    signature
    correctnessTestCase
    performanceTestCase
    performanceTimeLimitMilliSeconds
    hints {
      ...AlgorithmHintForCandidateInterview
    }
    initialCode {
      ...AlgorithmInitialCodeForCandidateInterview
    }
  }
  ${AlgorithmHintForCandidateInterviewFragmentDoc}
  ${AlgorithmInitialCodeForCandidateInterviewFragmentDoc}
`;
export const ChallengeEntityForCandidateInterviewFragmentDoc = gql`
  fragment ChallengeEntityForCandidateInterview on ChallengeEntity {
    __typename
    challengeEntityId
    challengeStatus: status
    questionVersion
    challengeQuestion(withAnswers: false) {
      ...AlgorithmQuestionForCandidateInterview
    }
    submissions {
      challengeSubmissionId
    }
    enabledLanguages
    appealMessage
  }
  ${AlgorithmQuestionForCandidateInterviewFragmentDoc}
`;
export const SystemDesignEntityForCandidateInterviewFragmentDoc = gql`
  fragment SystemDesignEntityForCandidateInterview on SystemDesignEntity {
    __typename
    systemDesignEntityId
    status
    companyId
    candidateId
    employeeId
    appealMessage
    question {
      questionId
      titleJa
      titleEn
      descriptionJa
      descriptionEn
      initialSnapshot
      hints {
        systemDesignHintId
        descriptionJa
        descriptionEn
      }
      scoringItems {
        systemDesignScoringItemId
        category
        isHidden
      }
    }
    submissions {
      systemDesignSubmissionId
      snapshot
    }
    componentTypes
  }
`;
export const DemoForCandidateDemosIdFragmentDoc = gql`
  fragment DemoForCandidateDemosId on Demo {
    demoId
    status
    timeLimitSeconds
    createdAtSeconds
    candidate {
      displayName
    }
    entities(withAnswers: false) {
      ... on QuizEntity {
        ...QuizEntityForCandidateInterview
      }
      ... on ProjectEntity {
        ...ProjectEntityForCandidateInterview
      }
      ... on ChallengeEntity {
        ...ChallengeEntityForCandidateInterview
      }
      ... on SystemDesignEntity {
        ...SystemDesignEntityForCandidateInterview
      }
    }
    allowWebSearch
    allowChatGPT
  }
  ${QuizEntityForCandidateInterviewFragmentDoc}
  ${ProjectEntityForCandidateInterviewFragmentDoc}
  ${ChallengeEntityForCandidateInterviewFragmentDoc}
  ${SystemDesignEntityForCandidateInterviewFragmentDoc}
`;
export const FreepadQuestionForCandidateRemotesIdFragmentDoc = gql`
  fragment FreepadQuestionForCandidateRemotesId on FreepadQuestion {
    freepadQuestionId
    version
    variant
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    variant
    answers {
      titleJa
      titleEn
      descriptionJa
      descriptionEn
    }
    initialCodes {
      questionId
      questionVersion
      runtime
      body
    }
    initialFlowCharts {
      questionId
      questionVersion
      componentType
      body
    }
  }
`;
export const AlgorithmQuestionForCandidateRemotesIdFragmentDoc = gql`
  fragment AlgorithmQuestionForCandidateRemotesId on AlgorithmQuestion {
    questionId
    version
    variant
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    supportedLanguages
  }
`;
export const SystemDesignQuestionForCandidateRemotesIdFragmentDoc = gql`
  fragment SystemDesignQuestionForCandidateRemotesId on SystemDesignQuestion {
    questionId
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    initialSnapshot
    answers {
      titleJa
      titleEn
      descriptionJa
      descriptionEn
    }
  }
`;
export const LiveCodingSessionForCandidateRemotesIdFragmentDoc = gql`
  fragment LiveCodingSessionForCandidateRemotesId on LiveCodingSession {
    questionType
    liveCodingId
    liveCodingSessionId
    freepadQuestion(withAnswers: false) {
      ...FreepadQuestionForCandidateRemotesId
    }
    algorithmQuestion(withAnswers: false) {
      ...AlgorithmQuestionForCandidateRemotesId
    }
    systemDesignQuestion(withAnswers: false) {
      ...SystemDesignQuestionForCandidateRemotesId
    }
    elapsedTimeSeconds
  }
  ${FreepadQuestionForCandidateRemotesIdFragmentDoc}
  ${AlgorithmQuestionForCandidateRemotesIdFragmentDoc}
  ${SystemDesignQuestionForCandidateRemotesIdFragmentDoc}
`;
export const LiveCodingParticipantForCandidateRemotesIdFragmentDoc = gql`
  fragment LiveCodingParticipantForCandidateRemotesId on LiveCodingParticipant {
    liveCodingId
    userType
    userId
    userName
  }
`;
export const LiveCodingVideoChatRoomForCandidateRemotesIdFragmentDoc = gql`
  fragment LiveCodingVideoChatRoomForCandidateRemotesId on LiveCodingVideoChatRoom {
    id
    roomId
    liveCodingId
    createdAtSeconds
  }
`;
export const LiveCodingVideoChatConversationForCandidateRemotesIdFragmentDoc = gql`
  fragment LiveCodingVideoChatConversationForCandidateRemotesId on LiveCodingVideoChatConversation {
    id
    conversationId
    liveCodingId
    createdAtSeconds
  }
`;
export const LiveCodingForCandidateRemotesIdFragmentDoc = gql`
  fragment LiveCodingForCandidateRemotesId on LiveCoding {
    status
    liveCodingId
    sessions {
      ...LiveCodingSessionForCandidateRemotesId
    }
    participants {
      ...LiveCodingParticipantForCandidateRemotesId
    }
    videoChatRooms {
      ...LiveCodingVideoChatRoomForCandidateRemotesId
    }
    videoChatConversation {
      ...LiveCodingVideoChatConversationForCandidateRemotesId
    }
  }
  ${LiveCodingSessionForCandidateRemotesIdFragmentDoc}
  ${LiveCodingParticipantForCandidateRemotesIdFragmentDoc}
  ${LiveCodingVideoChatRoomForCandidateRemotesIdFragmentDoc}
  ${LiveCodingVideoChatConversationForCandidateRemotesIdFragmentDoc}
`;
export const RemoteEntityForCandidateRemotesIdFragmentDoc = gql`
  fragment RemoteEntityForCandidateRemotesId on RemoteEntity {
    entityType
    liveCoding {
      ...LiveCodingForCandidateRemotesId
    }
  }
  ${LiveCodingForCandidateRemotesIdFragmentDoc}
`;
export const RemoteForCandidateRemotesIdFragmentDoc = gql`
  fragment RemoteForCandidateRemotesId on Remote {
    status
    remoteId
    name
    candidateName
    didStartAtSeconds
    company {
      name
    }
    entity {
      ...RemoteEntityForCandidateRemotesId
    }
  }
  ${RemoteEntityForCandidateRemotesIdFragmentDoc}
`;
export const VideoChatRoomTokenForCandidateRemotesIdFragmentDoc = gql`
  fragment VideoChatRoomTokenForCandidateRemotesId on RoomToken {
    token
  }
`;
export const VideoChatConversationTokenForCandidateRemotesIdFragmentDoc = gql`
  fragment VideoChatConversationTokenForCandidateRemotesId on ConversationToken {
    token
  }
`;
export const VideoChatConversationParticipantForCandidateRemotesIdFragmentDoc = gql`
  fragment VideoChatConversationParticipantForCandidateRemotesId on VideoChatConversationParticipant {
    displayName
    conversationId
    userId
    userType
  }
`;
export const VideoChatConversationForCandidateRemotesIdFragmentDoc = gql`
  fragment VideoChatConversationForCandidateRemotesId on VideoChatConversation {
    id
    participants {
      ...VideoChatConversationParticipantForCandidateRemotesId
    }
    conversationId
    status
  }
  ${VideoChatConversationParticipantForCandidateRemotesIdFragmentDoc}
`;
export const VariableForScreeningsIdFragmentDoc = gql`
  fragment VariableForScreeningsId on ScreeningVariable {
    id
    variableId
    screeningId
    label
    description
    variableField {
      __typename
      ... on ScreeningStringFieldVariable {
        formType
        validationRule {
          min
          max
          required
        }
      }
      ... on ScreeningIntegerFieldVariable {
        validationRule {
          min
          max
          required
        }
      }
      ... on ScreeningBooleanFieldVariable {
        validationRule {
          required
        }
      }
    }
  }
`;
export const ScreeningEntitySourceQuestionForScreeningsIdFragmentDoc = gql`
  fragment ScreeningEntitySourceQuestionForScreeningsId on ScreeningEntitySourceQuestion {
    ... on ScreeningChallengeEntitySourceQuestion {
      __typename
      question {
        __typename
        titleJa
        titleEn
        variant
      }
    }
    ... on ScreeningQuizEntitySourcePackage {
      __typename
      pb_package {
        titleJa
        titleEn
      }
    }
    ... on ScreeningProjectEntitySourceQuestion {
      __typename
      question {
        __typename
        titleEn
        titleJa
        projectQuestionVariant: variant
      }
    }
    ... on ScreeningSystemDesignEntitySourceQuestion {
      __typename
      question {
        __typename
        titleEn
        titleJa
      }
    }
  }
`;
export const ScreeningForScreeningsIdFragmentDoc = gql`
  fragment ScreeningForScreeningsId on Screening {
    id
    screeningId
    description
    timeLimitSeconds
    deadlineSeconds
    isActive
    questionCount
    candidateAccessPolicy
    messageForCandidate
    acceptanceStatus
    company {
      name
    }
    variables {
      ...VariableForScreeningsId
    }
    entityTracks(withAnswers: false) {
      ... on ScreeningFixedEntityTrack {
        __typename
        trackId
        entitySourceQuestion(withAnswers: false) {
          ...ScreeningEntitySourceQuestionForScreeningsId
        }
      }
      ... on ScreeningSelectableFromQuestionsEntityTrack {
        __typename
        trackId
        entitySourceQuestions(withAnswers: false) {
          ...ScreeningEntitySourceQuestionForScreeningsId
        }
      }
      ... on ScreeningRandomFromQuestionsEntityTrack {
        __typename
        trackId
      }
    }
  }
  ${VariableForScreeningsIdFragmentDoc}
  ${ScreeningEntitySourceQuestionForScreeningsIdFragmentDoc}
`;
export const AcceptScreeningResponseForScreeningsIdFragmentDoc = gql`
  fragment AcceptScreeningResponseForScreeningsId on AcceptScreeningResponse {
    ... on AcceptScreeningSuccessResponse {
      __typename
      spot {
        id
        spotId
        status
        candidate {
          id
          uid
        }
      }
    }
    ... on AcceptScreeningErrorResponse {
      __typename
      errorCode
    }
  }
`;
export const SpotForEntryScreeningsTestsIdFragmentDoc = gql`
  fragment SpotForEntryScreeningsTestsId on Spot {
    id
    company {
      name
    }
    isRetake
    screeningId
    candidateName
    candidateEmail
    timeLimitSeconds
    messageForCandidate
    entityCount
    didStartAtSeconds
    remainingTimeSeconds
    elapsedTimeSeconds
    willExpireAtSeconds
    entityCount
  }
`;
export const SpotForCandidateInterviewFragmentDoc = gql`
  fragment SpotForCandidateInterview on Spot {
    id
    candidateName
    status
    willExpireAtSeconds
    didStartAtSeconds
    timeLimitSeconds
    messageForCandidate
    remainingTimeSeconds
    elapsedTimeSeconds
    isRetake
    entities(withAnswers: false) {
      ... on QuizEntity {
        ...QuizEntityForCandidateInterview
      }
      ... on ProjectEntity {
        ...ProjectEntityForCandidateInterview
      }
      ... on ChallengeEntity {
        ...ChallengeEntityForCandidateInterview
      }
      ... on SystemDesignEntity {
        ...SystemDesignEntityForCandidateInterview
      }
    }
    allowWebSearch
    allowChatGPT
    allowHint
  }
  ${QuizEntityForCandidateInterviewFragmentDoc}
  ${ProjectEntityForCandidateInterviewFragmentDoc}
  ${ChallengeEntityForCandidateInterviewFragmentDoc}
  ${SystemDesignEntityForCandidateInterviewFragmentDoc}
`;
export const SpotForEntryInterviewFragmentDoc = gql`
  fragment SpotForEntryInterview on Spot {
    id
    name
    company {
      name
    }
    candidateName
    timeLimitSeconds
    messageForCandidate
    entityCount
    elapsedTimeSeconds
    willExpireAtSeconds
  }
`;
export const InterviewDetailSharedLinkFragmentDoc = gql`
  fragment InterviewDetailSharedLink on SpotSharedLink {
    linkHash
    spotId
    showAnswer
    showRelativeEvaluation
    showPlayback
    showRank
    showReview
    showScore
    showCheatDetect
  }
`;
export const InterviewDetailFromSharedLinkFragmentDoc = gql`
  fragment InterviewDetailFromSharedLink on Spot {
    id
    isRetake
    parentId
    candidateName
    candidateEmail
    evaluatedAtSeconds
    isPublic
    name
    status
    sharedLinks {
      ...InterviewDetailSharedLink
    }
    canShowCandidateName
  }
  ${InterviewDetailSharedLinkFragmentDoc}
`;
export const ExamForForEmployeeAssessmentsFragmentDoc = gql`
  fragment ExamForForEmployeeAssessments on Exam {
    examId
    assessmentId
    parentId
    numInterval
    totalScore
    status
    relativeScore
    rankEvaluation
    isReliableRank
    finalizedAtSeconds
    willEndAtSeconds
    expiredAtSeconds
    createdAtSeconds
    updatedAtSeconds
    deletedAtSeconds
    didEndAtSeconds
  }
`;
export const AssessmentForEmployeeAssessmentFragmentDoc = gql`
  fragment AssessmentForEmployeeAssessment on Assessment {
    assessmentId
    name
    status
    talent {
      email
      displayName
      photoUrl
    }
    scoreTrend
    startScheduleAtSeconds
    nextScheduleAtSeconds
    remindBeforeSeconds
    examInterval
    previousBestExam {
      ...ExamForForEmployeeAssessments
    }
    latestBestExam {
      ...ExamForForEmployeeAssessments
    }
    viewers {
      ... on User {
        uid
        displayName
        email
        photoUrl
      }
      ... on EmployeeGroup {
        employeeGroupId
        groupName
      }
    }
    reportSettings {
      showAnswer
      showPlayback
      showRelativeEvaluation
    }
  }
  ${ExamForForEmployeeAssessmentsFragmentDoc}
`;
export const ListAssessmentsByCompanyIdForEmployeeAssessmentsFragmentDoc = gql`
  fragment ListAssessmentsByCompanyIdForEmployeeAssessments on ListAssessmentsByCompanyId {
    assessments {
      ...AssessmentForEmployeeAssessment
    }
    count
    hasNext
  }
  ${AssessmentForEmployeeAssessmentFragmentDoc}
`;
export const AssessmentListOptionsByFieldsResponseForEmployeeAssessmentsSearchParamsFragmentDoc = gql`
  fragment AssessmentListOptionsByFieldsResponseForEmployeeAssessmentsSearchParams on AssessmentListOptionsByFieldsResponse {
    viewers(companyId: $companyId) {
      __typename
      ... on User {
        uid
        displayName
        photoUrl
        email
      }
      ... on EmployeeGroup {
        employeeGroupId
        groupName
      }
    }
    talents {
      uid
      displayName
      photoUrl
      email
    }
  }
`;
export const UpdateAssessmentReportShareSettingsResponseForEmployeeAssessmentsFragmentDoc = gql`
  fragment UpdateAssessmentReportShareSettingsResponseForEmployeeAssessments on Assessment {
    __typename
    id
    assessmentId
    reportSettings {
      showAnswer
      showPlayback
      showRelativeEvaluation
    }
  }
`;
export const AssessmentDetailUserFragmentDoc = gql`
  fragment AssessmentDetailUser on User {
    id
    uid
    displayName
    email
    photoUrl
    language
  }
`;
export const AssessmentForDetailEmployeeFragmentDoc = gql`
  fragment AssessmentForDetailEmployee on Assessment {
    id
    assessmentId
    createdAtSeconds
    nextScheduleAtSeconds
    examInterval
    rootExamIds
    isPublic
    name
    memo
    messageForTalent
    timeLimitSeconds
    status
    reportSettings {
      showAnswer
      showPlayback
      showRelativeEvaluation
    }
    questions(withAnswers: true) {
      __typename
    }
    employee {
      ...AssessmentDetailUser
    }
    talent {
      ...AssessmentDetailUser
    }
    viewers {
      __typename
      ... on User {
        ...AssessmentDetailUser
      }
      ... on EmployeeGroup {
        id
        groupName
        companyEmployees {
          employeeId
          role
        }
      }
    }
  }
  ${AssessmentDetailUserFragmentDoc}
`;
export const ExamForAssessmentsIdDetailFragmentDoc = gql`
  fragment ExamForAssessmentsIdDetail on Exam {
    __typename
    id
    examId
    assessmentId
    originId
    numInterval
    numTry
    startIntervalAtSeconds
    endIntervalAtSeconds
    relativeScore
    totalScore
    status
    createdAtSeconds
    willEndAtSeconds
  }
`;
export const ListBestExamsResponseForAssessmentsIdDetailFragmentDoc = gql`
  fragment ListBestExamsResponseForAssessmentsIdDetail on ListBestExamsByAssessmentId {
    exams {
      ...ExamForAssessmentsIdDetail
    }
    count
    hasNext
  }
  ${ExamForAssessmentsIdDetailFragmentDoc}
`;
export const ListExamsRecursivePayloadForAssessmentsIdDetailEmployeeFragmentDoc = gql`
  fragment ListExamsRecursivePayloadForAssessmentsIdDetailEmployee on ListExamsRecursivePayload {
    exams {
      ...ExamForAssessmentsIdDetail
    }
    bestExamId
  }
  ${ExamForAssessmentsIdDetailFragmentDoc}
`;
export const EvaluationMetricWithEmployeeResponseFragmentDoc = gql`
  fragment EvaluationMetricWithEmployeeResponse on EvaluationMetric {
    id
    metricId
    companyId
    createdBy
    title
    description
    createdAtSeconds
    employee {
      id
      uid
      displayName
      email
      fullName
      photoUrl
      emailVerified
      language
      provider
    }
  }
`;
export const EvaluationMetricGroupsWithMetricResponseFragmentDoc = gql`
  fragment EvaluationMetricGroupsWithMetricResponse on EvaluationMetricGroup {
    id
    metricGroupId
    companyId
    title
    description
    metrics {
      ...EvaluationMetricWithEmployeeResponse
    }
    createdBy
    employee {
      id
      uid
      displayName
      email
      fullName
      photoUrl
      emailVerified
      language
      provider
    }
  }
  ${EvaluationMetricWithEmployeeResponseFragmentDoc}
`;
export const QuizEntityForTestReviewFragmentDoc = gql`
  fragment QuizEntityForTestReview on QuizEntity {
    __typename
  }
`;
export const ProjectEntityForTestReviewFragmentDoc = gql`
  fragment ProjectEntityForTestReview on ProjectEntity {
    __typename
    projectEntityId
    projectStatus: status
    question {
      questionId
      version
      projectVersion
      variant
      titleJa
      titleEn
      correctnessTestCase
      performanceTestCase
      defaultTestCase
      descriptionJa
      descriptionEn
      timeLimitSeconds
      fileIndexes
      readOnlyFiles
    }
    appealMessage
    submissions {
      projectSubmissionId
    }
    agentVersion
  }
`;
export const ChallengeEntityForTestReviewFragmentDoc = gql`
  fragment ChallengeEntityForTestReview on ChallengeEntity {
    __typename
  }
`;
export const SystemDesignEntityForTestReviewFragmentDoc = gql`
  fragment SystemDesignEntityForTestReview on SystemDesignEntity {
    __typename
  }
`;
export const ExamForEmployeeInterviewsIdReviewFragmentDoc = gql`
  fragment ExamForEmployeeInterviewsIdReview on Exam {
    examId
    entities(withAnswers: true) {
      ... on QuizEntity {
        ...QuizEntityForTestReview
      }
      ... on ProjectEntity {
        ...ProjectEntityForTestReview
      }
      ... on ChallengeEntity {
        ...ChallengeEntityForTestReview
      }
      ... on SystemDesignEntity {
        ...SystemDesignEntityForTestReview
      }
    }
  }
  ${QuizEntityForTestReviewFragmentDoc}
  ${ProjectEntityForTestReviewFragmentDoc}
  ${ChallengeEntityForTestReviewFragmentDoc}
  ${SystemDesignEntityForTestReviewFragmentDoc}
`;
export const ScreeningForDashboardScreeningSectionFragmentDoc = gql`
  fragment ScreeningForDashboardScreeningSection on Screening {
    __typename
    id
    screeningId
    name
    isActive
    acceptableLimit
    numAccepted
    acceptanceStatus
    candidateAccessPolicy
    invitationLanguage
    deadlineSeconds
    spotStats {
      spotCountByStatus {
        finalized
        evaluated
      }
    }
  }
`;
export const RemoteForDashboardRemoteInterviewSectionFragmentDoc = gql`
  fragment RemoteForDashboardRemoteInterviewSection on Remote {
    id
    remoteId
    name
    status
    candidateName
  }
`;
export const ScreeningsByCompanyIdResponseForDashboardScreeningStatsSectionFragmentDoc = gql`
  fragment ScreeningsByCompanyIdResponseForDashboardScreeningStatsSection on ScreeningsByCompanyIdResponse {
    screenings {
      id
      screeningId
      name
    }
    count
    hasNext
  }
`;
export const SpotStatsForDashboardScreeningStatsSectionFragmentDoc = gql`
  fragment SpotStatsForDashboardScreeningStatsSection on SpotStats {
    spotCountByStatus {
      created
      started
      completed
      finalized
      evaluated
      expired
    }
    spotCountByResult {
      passed
      notPassed
      notEvaluated
    }
    averagePassedCustomScoreFormatted
  }
`;
export const ScreeningForDashboardScreeningStatsSectionFragmentDoc = gql`
  fragment ScreeningForDashboardScreeningStatsSection on Screening {
    id
    screeningId
    name
    spotStatistics {
      ...SpotStatsForDashboardScreeningStatsSection
    }
    timeLimitSeconds
  }
  ${SpotStatsForDashboardScreeningStatsSectionFragmentDoc}
`;
export const InvitationForEmployeeInvitationsIdFragmentDoc = gql`
  fragment InvitationForEmployeeInvitationsId on Invitation {
    id
    role
    expiresAtSeconds
  }
`;
export const CompanyEmployeeForEmployeeInvitationsIdFragmentDoc = gql`
  fragment CompanyEmployeeForEmployeeInvitationsId on CompanyEmployee {
    companyId
    employeeId
  }
`;
export const CompanyForEmployeeInvitationsIdFragmentDoc = gql`
  fragment CompanyForEmployeeInvitationsId on Company {
    name
    companyId
    companyEmployees {
      ...CompanyEmployeeForEmployeeInvitationsId
    }
  }
  ${CompanyEmployeeForEmployeeInvitationsIdFragmentDoc}
`;
export const MultiChoiceQuestionForQuestionQuizIdQuestionFragmentDoc = gql`
  fragment MultiChoiceQuestionForQuestionQuizIdQuestion on MultiChoiceQuestion {
    id
    version
    titleJa
    titleEn
    difficulty
    descriptionJa
    descriptionEn
    options {
      id
      titleJa
      titleEn
      isCorrect
      additionalDetailJa
      additionalDetailEn
    }
    metricType
    companyId
    isPrivate
    isOfficial
    status
    variant
    correctAnswerRate
    evaluationItems {
      id
      questionId
      questionVersion
      titleJa
      titleEn
      descriptionJa
      descriptionEn
    }
  }
`;
export const GetMultiChoicePackageForQuestionQuizIdPackageFragmentDoc = gql`
  fragment GetMultiChoicePackageForQuestionQuizIdPackage on MultiChoicePackage {
    id
    packageId
    version
    companyId
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    difficulty
    isPrivate
    status
    questions {
      ...MultiChoiceQuestionForQuestionQuizIdQuestion
    }
    timeLimitSeconds
    scoreDistributionBins
    numUses
    accuracyRate
    averageElapsedTimeSeconds
    isOfficial
    employee {
      id
      displayName
    }
  }
  ${MultiChoiceQuestionForQuestionQuizIdQuestionFragmentDoc}
`;
export const ListAlgorithmQuestionFragmentDoc = gql`
  fragment ListAlgorithmQuestion on AlgorithmQuestion {
    __typename
    id
    questionId
    version
    variant
    companyId
    employeeId
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    status
    supportedLanguages
    difficulty
    isPrivate
    isOfficial
    leakScore {
      level
    }
    isSupportedLeakScore
    numUses
    accuracyRate
    averageElapsedTimeSeconds
    algorithmQuestionStatus: status
    timeLimitSeconds
    createdAtSeconds
  }
`;
export const ListMultiChoiceQuestionFragmentDoc = gql`
  fragment ListMultiChoiceQuestion on MultiChoicePackage {
    __typename
    id
    packageId
    version
    companyId
    employeeId
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    status
    difficulty
    isPrivate
    isOfficial
    leakScore {
      level
    }
    isSupportedLeakScore
    numUses
    accuracyRate
    averageElapsedTimeSeconds
    multiChoicePackageStatus: status
    timeLimitSeconds
    createdAtSeconds
  }
`;
export const SkillTagNodeForQuestionsFragmentDoc = gql`
  fragment SkillTagNodeForQuestions on SkillTagNode {
    id
    skillTagNodeId
    name
    path
  }
`;
export const ListProjectQuestionFragmentDoc = gql`
  fragment ListProjectQuestion on ProjectQuestion {
    __typename
    id
    questionId
    version
    projectVersion
    companyId
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    difficulty
    isPrivate
    isOfficial
    leakScore {
      level
    }
    isSupportedLeakScore
    numUses
    accuracyRate
    averageElapsedTimeSeconds
    skillTags {
      ...SkillTagNodeForQuestions
    }
    skillTagIds
    projectQuestionStatus: status
    timeLimitSeconds
    createdAtSeconds
  }
  ${SkillTagNodeForQuestionsFragmentDoc}
`;
export const ListSystemDesignQuestionFragmentDoc = gql`
  fragment ListSystemDesignQuestion on SystemDesignQuestion {
    __typename
    id
    questionId
    companyId
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    difficulty
    isPrivate
    isOfficial
    leakScore {
      level
    }
    isSupportedLeakScore
    numUses
    accuracyRate
    averageElapsedTimeSeconds
    systemDesignQuestionStatus: status
    timeLimitSeconds
    createdAtSeconds
  }
`;
export const ListFreepadQuestionFragmentDoc = gql`
  fragment ListFreepadQuestion on FreepadQuestion {
    __typename
    id
    freepadQuestionId
    version
    freepadVariant: variant
    companyId
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    status
    difficulty
    isPrivate
    isOfficial
    leakScore {
      level
    }
    isSupportedLeakScore
    numUses
    averageElapsedTimeSeconds
    createdAtSeconds
  }
`;
export const QuestionForListQuestionFragmentDoc = gql`
  fragment QuestionForListQuestion on QuestionObject {
    id
    questionObjectId
    questionVariant
    question {
      ... on AlgorithmQuestion {
        ...ListAlgorithmQuestion
      }
      ... on MultiChoicePackage {
        ...ListMultiChoiceQuestion
      }
      ... on ProjectQuestion {
        ...ListProjectQuestion
      }
      ... on SystemDesignQuestion {
        ...ListSystemDesignQuestion
      }
      ... on FreepadQuestion {
        ...ListFreepadQuestion
      }
    }
  }
  ${ListAlgorithmQuestionFragmentDoc}
  ${ListMultiChoiceQuestionFragmentDoc}
  ${ListProjectQuestionFragmentDoc}
  ${ListSystemDesignQuestionFragmentDoc}
  ${ListFreepadQuestionFragmentDoc}
`;
export const QuestionsResponseForListQuestionsPageFragmentDoc = gql`
  fragment QuestionsResponseForListQuestionsPage on Questions {
    questionObjects {
      ...QuestionForListQuestion
    }
    count
    hasNext
  }
  ${QuestionForListQuestionFragmentDoc}
`;
export const HierarchicalSkillTagsResponseForQuestionsFragmentDoc = gql`
  fragment HierarchicalSkillTagsResponseForQuestions on HierarchicalSkillTagsResponse {
    skillTagNodes {
      ...SkillTagNodeForQuestions
    }
    count
    hasNext
  }
  ${SkillTagNodeForQuestionsFragmentDoc}
`;
export const AlgorithmInitialCodeForQuestionsChallengeIdFragmentDoc = gql`
  fragment AlgorithmInitialCodeForQuestionsChallengeId on AlgorithmInitialCode {
    c
    cpp
    csharp
    dart
    go
    java
    javascript
    kotlin
    mysql
    perl
    pgsql
    php
    python3
    ruby
    rust
    scala
    sqlite
    swift
    typescript
  }
`;
export const AlgorithmAnswerRuntimeForQuestionsChallengeIdFragmentDoc = gql`
  fragment AlgorithmAnswerRuntimeForQuestionsChallengeId on AlgorithmAnswerRuntime {
    runtime
    codeBody
  }
`;
export const AlgorithmAnswerForQuestionsChallengeIdFragmentDoc = gql`
  fragment AlgorithmAnswerForQuestionsChallengeId on AlgorithmAnswer {
    id
    questionId
    questionVersion
    titleEn
    titleJa
    label
    descriptionEn
    descriptionJa
    answerRuntimes {
      ...AlgorithmAnswerRuntimeForQuestionsChallengeId
    }
  }
  ${AlgorithmAnswerRuntimeForQuestionsChallengeIdFragmentDoc}
`;
export const AlgorithmHintForQuestionsChallengeIdFragmentDoc = gql`
  fragment AlgorithmHintForQuestionsChallengeId on AlgorithmHint {
    id
    questionId
    questionVersion
    descriptionJa
    descriptionEn
    orderNum
  }
`;
export const AlgorithmQuestionForQuestionsChallengeIdFragmentDoc = gql`
  fragment AlgorithmQuestionForQuestionsChallengeId on AlgorithmQuestion {
    id
    questionId
    version
    variant
    companyId
    employeeId
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    difficulty
    isPrivate
    status
    supportedLanguages
    signature
    correctnessTestCase
    isOfficial
    initialCode {
      ...AlgorithmInitialCodeForQuestionsChallengeId
    }
    leakScore {
      level
    }
    scoreDistributionBins
    isSupportedLeakScore
    numUses
    accuracyRate
    averageElapsedTimeSeconds
    timeLimitSeconds
    answers {
      ...AlgorithmAnswerForQuestionsChallengeId
    }
    hints {
      ...AlgorithmHintForQuestionsChallengeId
    }
    employee {
      id
      displayName
    }
  }
  ${AlgorithmInitialCodeForQuestionsChallengeIdFragmentDoc}
  ${AlgorithmAnswerForQuestionsChallengeIdFragmentDoc}
  ${AlgorithmHintForQuestionsChallengeIdFragmentDoc}
`;
export const FreepadQuestionDetailFragmentDoc = gql`
  fragment FreepadQuestionDetail on FreepadQuestion {
    id
    freepadQuestionId
    version
    variant
    isPrivate
    status
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    difficulty
    answers {
      id
      freepadAnswerId
      titleJa
      titleEn
      descriptionJa
      descriptionEn
      answerCodes {
        id
        freepadAnswerCodeId
        body
        runtime
      }
      answerFlowCharts {
        id
        freepadAnswerFlowChartId
        body
        componentType
      }
    }
    numUses
    averageElapsedTimeSeconds
    isOfficial
    employee {
      id
      displayName
    }
  }
`;
export const QuestionPackageForQuestionsPackagesFragmentDoc = gql`
  fragment QuestionPackageForQuestionsPackages on QuestionPackage {
    __typename
    id
    questionPackageId
    difficulty
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    questionCount
  }
`;
export const QuestionPackagesResponseForQuestionsPackagesFragmentDoc = gql`
  fragment QuestionPackagesResponseForQuestionsPackages on QuestionPackagesResponse {
    count
    packages {
      ...QuestionPackageForQuestionsPackages
    }
  }
  ${QuestionPackageForQuestionsPackagesFragmentDoc}
`;
export const QuestionObjectForQuestionsPackagesDetailFragmentDoc = gql`
  fragment QuestionObjectForQuestionsPackagesDetail on QuestionObject {
    id
    questionObjectId
    questionVariant
    version
    question {
      ... on AlgorithmQuestion {
        ...ListAlgorithmQuestion
      }
      ... on MultiChoicePackage {
        ...ListMultiChoiceQuestion
      }
      ... on ProjectQuestion {
        ...ListProjectQuestion
      }
      ... on SystemDesignQuestion {
        ...ListSystemDesignQuestion
      }
      ... on FreepadQuestion {
        ...ListFreepadQuestion
      }
    }
  }
  ${ListAlgorithmQuestionFragmentDoc}
  ${ListMultiChoiceQuestionFragmentDoc}
  ${ListProjectQuestionFragmentDoc}
  ${ListSystemDesignQuestionFragmentDoc}
  ${ListFreepadQuestionFragmentDoc}
`;
export const QuestionPackageForQuestionsPackagesDetailFragmentDoc = gql`
  fragment QuestionPackageForQuestionsPackagesDetail on QuestionPackage {
    __typename
    id
    questionPackageId
    difficulty
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    questionCount
    questionObjects {
      ...QuestionObjectForQuestionsPackagesDetail
    }
  }
  ${QuestionObjectForQuestionsPackagesDetailFragmentDoc}
`;
export const SkillTagNodeForQuestionDetailFragmentDoc = gql`
  fragment SkillTagNodeForQuestionDetail on SkillTagNode {
    id
    skillTagNodeId
    name
    path
  }
`;
export const ProjectQuestionDetailFragmentDoc = gql`
  fragment ProjectQuestionDetail on ProjectQuestion {
    id
    questionId
    status
    version
    projectVersion
    variant
    companyId
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    difficulty
    timeLimitSeconds
    isPrivate
    numUses
    accuracyRate
    scoreDistributionBins
    averageElapsedTimeSeconds
    isOfficial
    skillTags {
      ...SkillTagNodeForQuestionDetail
    }
    answers {
      answerId
      questionId
      questionVersion
      titleJa
      titleEn
      descriptionJa
      descriptionEn
      fileIndexList
    }
  }
  ${SkillTagNodeForQuestionDetailFragmentDoc}
`;
export const SystemDesignQuestionDetailFragmentDoc = gql`
  fragment SystemDesignQuestionDetail on SystemDesignQuestion {
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    difficulty
    status
    hints {
      descriptionJa
      descriptionEn
    }
    answers {
      titleJa
      titleEn
      descriptionJa
      descriptionEn
      snapshot
    }
    scoringItems {
      titleJa
      titleEn
      descriptionJa
      descriptionEn
      graphlets
      category
    }
    scoreDistributionBins
    numUses
    accuracyRate
    averageElapsedTimeSeconds
    timeLimitSeconds
    isOfficial
  }
`;
export const UserForRemotesFragmentDoc = gql`
  fragment UserForRemotes on User {
    id
    uid
    displayName
    email
    photoUrl
  }
`;
export const RemotesForRemotesInterviewListFragmentDoc = gql`
  fragment RemotesForRemotesInterviewList on Remote {
    __typename
    id
    name
    remoteId
    creator {
      ...UserForRemotes
    }
    tags {
      name
    }
    candidateName
    status
    isPassed
    isPublic
  }
  ${UserForRemotesFragmentDoc}
`;
export const LiveCodingVideoChatConversationForEmployeeRemotesIdFragmentDoc = gql`
  fragment LiveCodingVideoChatConversationForEmployeeRemotesId on LiveCodingVideoChatConversation {
    id
    conversationId
    liveCodingId
    createdAtSeconds
  }
`;
export const FreepadQuestionForRemotesIdFragmentDoc = gql`
  fragment FreepadQuestionForRemotesId on FreepadQuestion {
    freepadQuestionId
    version
    variant
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    difficulty
    variant
    answers {
      titleJa
      titleEn
      descriptionJa
      descriptionEn
      answerCodes {
        body
      }
      answerFlowCharts {
        componentType
        body
      }
    }
    initialCodes {
      questionId
      questionVersion
      runtime
      body
    }
    initialFlowCharts {
      questionId
      questionVersion
      componentType
      body
    }
  }
`;
export const AlgorithmQuestionForRemotesIdFragmentDoc = gql`
  fragment AlgorithmQuestionForRemotesId on AlgorithmQuestion {
    questionId
    version
    variant
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    difficulty
    answers {
      titleJa
      titleEn
      descriptionJa
      descriptionEn
      label
    }
    supportedLanguages
  }
`;
export const SystemDesignQuestionForRemotesIdFragmentDoc = gql`
  fragment SystemDesignQuestionForRemotesId on SystemDesignQuestion {
    questionId
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    difficulty
    initialSnapshot
    answers {
      titleJa
      titleEn
      descriptionJa
      descriptionEn
    }
  }
`;
export const LiveCodingSessionForRemotesIdFragmentDoc = gql`
  fragment LiveCodingSessionForRemotesId on LiveCodingSession {
    questionType
    liveCodingId
    liveCodingSessionId
    freepadQuestion(withAnswers: true) {
      ...FreepadQuestionForRemotesId
    }
    algorithmQuestion(withAnswers: true) {
      ...AlgorithmQuestionForRemotesId
    }
    systemDesignQuestion(withAnswers: true) {
      ...SystemDesignQuestionForRemotesId
    }
    elapsedTimeSeconds
  }
  ${FreepadQuestionForRemotesIdFragmentDoc}
  ${AlgorithmQuestionForRemotesIdFragmentDoc}
  ${SystemDesignQuestionForRemotesIdFragmentDoc}
`;
export const LiveCodingParticipantForRemotesIdFragmentDoc = gql`
  fragment LiveCodingParticipantForRemotesId on LiveCodingParticipant {
    liveCodingId
    userType
    userId
    userName
  }
`;
export const LiveCodingVideoChatRoomForEmployeeRemotesIdFragmentDoc = gql`
  fragment LiveCodingVideoChatRoomForEmployeeRemotesId on LiveCodingVideoChatRoom {
    id
    roomId
    liveCodingId
    createdAtSeconds
  }
`;
export const LiveCodingForRemotesIdFragmentDoc = gql`
  fragment LiveCodingForRemotesId on LiveCoding {
    status
    liveCodingId
    sessions {
      ...LiveCodingSessionForRemotesId
    }
    participants {
      ...LiveCodingParticipantForRemotesId
    }
    videoChatRooms {
      ...LiveCodingVideoChatRoomForEmployeeRemotesId
    }
    videoChatConversation {
      ...LiveCodingVideoChatConversationForCandidateRemotesId
    }
  }
  ${LiveCodingSessionForRemotesIdFragmentDoc}
  ${LiveCodingParticipantForRemotesIdFragmentDoc}
  ${LiveCodingVideoChatRoomForEmployeeRemotesIdFragmentDoc}
  ${LiveCodingVideoChatConversationForCandidateRemotesIdFragmentDoc}
`;
export const RemoteEntityForRemotesIdFragmentDoc = gql`
  fragment RemoteEntityForRemotesId on RemoteEntity {
    entityType
    liveCoding {
      ...LiveCodingForRemotesId
    }
  }
  ${LiveCodingForRemotesIdFragmentDoc}
`;
export const RemoteForRemotesIdFragmentDoc = gql`
  fragment RemoteForRemotesId on Remote {
    status
    remoteId
    name
    candidateName
    didStartAtSeconds
    entity {
      ...RemoteEntityForRemotesId
    }
  }
  ${RemoteEntityForRemotesIdFragmentDoc}
`;
export const LiveCodingTemplateCodesForRemotesIdFragmentDoc = gql`
  fragment LiveCodingTemplateCodesForRemotesId on LiveCodingTemplateCodes {
    python3
    go
    javascript
    php
    ruby
    typescript
    cpp
    kotlin
    java
    swift
    scala
    rust
    sqlite
    pgsql
    mysql
    dart
    csharp
    c
    perl
  }
`;
export const EvaluationMetricsForRemotesIdFragmentDoc = gql`
  fragment EvaluationMetricsForRemotesId on EvaluationMetric {
    id
    metricId
    title
    description
  }
`;
export const VideoChatRoomTokenForEmployeeRemotesIdFragmentDoc = gql`
  fragment VideoChatRoomTokenForEmployeeRemotesId on RoomToken {
    token
  }
`;
export const VideoChatConversationTokenForEmployeeRemotesIdFragmentDoc = gql`
  fragment VideoChatConversationTokenForEmployeeRemotesId on ConversationToken {
    token
  }
`;
export const VideoChatConversationParticipantForEmployeeRemotesIdFragmentDoc = gql`
  fragment VideoChatConversationParticipantForEmployeeRemotesId on VideoChatConversationParticipant {
    displayName
    conversationId
    userId
    userType
  }
`;
export const VideoChatConversationForEmployeeRemotesIdFragmentDoc = gql`
  fragment VideoChatConversationForEmployeeRemotesId on VideoChatConversation {
    id
    participants {
      ...VideoChatConversationParticipantForEmployeeRemotesId
    }
    conversationId
    status
  }
  ${VideoChatConversationParticipantForEmployeeRemotesIdFragmentDoc}
`;
export const RemoteForRemoteInterviewDetailFragmentDoc = gql`
  fragment RemoteForRemoteInterviewDetail on Remote {
    id
    name
    candidateName
    status
    isPassed
    evaluatedAtSeconds
    didEndAtSeconds
    evaluationComment
    evaluatedBy {
      id
      uid
      displayName
      email
    }
  }
`;
export const EvaluationMetricsForRemotesEvaluateFragmentDoc = gql`
  fragment EvaluationMetricsForRemotesEvaluate on EvaluationMetric {
    id
    metricId
    title
    description
  }
`;
export const UserForScreeningsFragmentDoc = gql`
  fragment UserForScreenings on User {
    id
    uid
    displayName
    email
    photoUrl
  }
`;
export const VariableForScreeningsFragmentDoc = gql`
  fragment VariableForScreenings on ScreeningVariable {
    screeningId
    variableId
    label
    description
    variableField {
      __typename
      ... on ScreeningStringFieldVariable {
        __typename
        validationRule {
          __typename
          maxLength: max
          minLength: min
          required
        }
        formType
      }
      ... on ScreeningIntegerFieldVariable {
        __typename
        validationRule {
          __typename
          maxIntegerValue: max
          minIntegerValue: min
          required
        }
      }
      ... on ScreeningBooleanFieldVariable {
        __typename
        validationRule {
          __typename
          required
        }
      }
    }
  }
`;
export const ScreeningForScreeningsFragmentDoc = gql`
  fragment ScreeningForScreenings on Screening {
    id
    screeningId
    name
    memo
    ownerEmailAddress
    companyId
    description
    deadlineSeconds
    createdBy {
      ...UserForScreenings
    }
    acceptanceStatus
    messageForCandidate
    timeLimitSeconds
    numAccepted
    acceptableLimit
    isActive
    candidateAccessPolicy
    invitationLanguage
    variables {
      ...VariableForScreenings
    }
    allowChatGPT
    allowWebSearch
    invitees {
      screeningId
      email
      status
    }
    spotIsPublic
    viewers {
      __typename
      ... on User {
        ...UserForScreenings
      }
      ... on EmployeeGroup {
        id
        employeeGroupId
        groupName
      }
    }
    tags {
      name
    }
  }
  ${UserForScreeningsFragmentDoc}
  ${VariableForScreeningsFragmentDoc}
`;
export const ScreeningsByCompanyIdResponseForScreeningsFragmentDoc = gql`
  fragment ScreeningsByCompanyIdResponseForScreenings on ScreeningsByCompanyIdResponse {
    screenings {
      ...ScreeningForScreenings
    }
    hasNext
    count
  }
  ${ScreeningForScreeningsFragmentDoc}
`;
export const SpotTagByCompanyIdForScreeningsFragmentDoc = gql`
  fragment SpotTagByCompanyIdForScreenings on SpotTagsByCompanyIdResponse {
    tags {
      name
    }
    count
    pager {
      cursorSeconds
      hasNext
    }
  }
`;
export const GetInterviewsForCreatorFragmentDoc = gql`
  fragment GetInterviewsForCreator on User {
    id
    displayName
    email
    photoUrl
  }
`;
export const GetInterviewsForSpotTagsByCompanyIdResponseFragmentDoc = gql`
  fragment GetInterviewsForSpotTagsByCompanyIdResponse on SpotTagsByCompanyIdResponse {
    tags {
      name
      companyId
    }
    count
    pager {
      cursorSeconds
      hasNext
    }
  }
`;
export const GetInterviewsForScreeningVariablesByCompanyIdResponseFragmentDoc = gql`
  fragment GetInterviewsForScreeningVariablesByCompanyIdResponse on ScreeningVariablesByCompanyIdResponse {
    variables {
      label
    }
    count
    pager {
      cursorSeconds
      hasNext
    }
  }
`;
export const ScreeningForScreeningsTestsFragmentDoc = gql`
  fragment ScreeningForScreeningsTests on Screening {
    screeningId
    name
  }
`;
export const SpotForEmployeeInterviewsFragmentDoc = gql`
  fragment SpotForEmployeeInterviews on Spot {
    id
    spotId
    parentId
    originId
    name
    candidateName
    candidateEmail
    screeningId
    screening {
      id
      screeningId
      name
    }
    status
    isPublic
    isRetake
    totalScore
    customScore
    rank
    numSubset
    rankEvaluation
    isReliableRank
    isPassed
    employee {
      id
      email
      emailVerified
      displayName
      fullName
      language
      photoUrl
    }
    tags {
      name
    }
    recommenders {
      id
      displayName
      email
      photoUrl
    }
    evaluatedBy {
      id
      displayName
      email
      photoUrl
    }
    suspiciousDegree
    createdAtSeconds
    declinedAtSeconds
    deletedAtSeconds
    didEndAtSeconds
    didStartAtSeconds
    elapsedTimeSeconds
    evaluatedAtSeconds
    finalizedAtSeconds
    timeLimitSeconds
    updatedAtSeconds
    willExpireAtSeconds
  }
`;
export const SpotsByCompanyIdResponseForInterviewFragmentDoc = gql`
  fragment SpotsByCompanyIdResponseForInterview on SpotsByCompanyIdResponse {
    spots {
      ...SpotForEmployeeInterviews
    }
    count
    pager {
      cursorSeconds
      hasNext
    }
    hasNext
  }
  ${SpotForEmployeeInterviewsFragmentDoc}
`;
export const InterviewDetailUserFragmentDoc = gql`
  fragment InterviewDetailUser on User {
    id
    uid
    displayName
    email
    photoUrl
    isSystemAdmin
  }
`;
export const InterviewOverviewChallengeFragmentDoc = gql`
  fragment InterviewOverviewChallenge on ChallengeEntity {
    id
    challengeEntityId
    challengeStatus: status
    challengeQuestion(withAnswers: false) {
      id
      questionId
      version
      titleJa
      titleEn
      descriptionEn
      descriptionJa
      difficulty
      algorithmQuestionStatus: status
    }
    enabledLanguages
  }
`;
export const InterviewOverviewQuizFragmentDoc = gql`
  fragment InterviewOverviewQuiz on QuizEntity {
    id
    quizEntityId
    pb_package(withAnswers: true) {
      id
      packageId
      version
      titleEn
      titleJa
      descriptionJa
      descriptionEn
      difficulty
      multiChoicePackageStatus: status
    }
    quizStatus: status
  }
`;
export const InterviewOverviewProjectFragmentDoc = gql`
  fragment InterviewOverviewProject on ProjectEntity {
    id
    projectEntityId
    projectStatus: status
    question {
      id
      questionId
      version
      titleEn
      titleJa
      descriptionJa
      descriptionEn
      difficulty
      projectQuestionStatus: status
    }
  }
`;
export const InterviewOverviewSystemDesignFragmentDoc = gql`
  fragment InterviewOverviewSystemDesign on SystemDesignEntity {
    id
    systemDesignEntityId
    systemDesignStatus: status
    question {
      id
      questionId
      titleJa
      titleEn
      descriptionJa
      descriptionEn
      difficulty
      systemDesignQuestionStatus: status
    }
    componentTypes
  }
`;
export const InterviewDetailFragmentDoc = gql`
  fragment InterviewDetail on Spot {
    id
    originId
    parentId
    candidateName
    candidateEmail
    screeningId
    screening {
      screeningId
      name
      deletedAtSeconds
    }
    createdAtSeconds
    evaluatedAtSeconds
    declinedAtSeconds
    didStartAtSeconds
    didEndAtSeconds
    finalizedAtSeconds
    isPassed
    evaluationComment
    rankEvaluation
    elapsedTimeSeconds
    evaluatedBy {
      ...InterviewDetailUser
    }
    isPublic
    name
    status
    entities(withAnswers: true) {
      ... on ChallengeEntity {
        ...InterviewOverviewChallenge
      }
      ... on QuizEntity {
        ...InterviewOverviewQuiz
      }
      ... on ProjectEntity {
        ...InterviewOverviewProject
      }
      ... on SystemDesignEntity {
        ...InterviewOverviewSystemDesign
      }
    }
    tags {
      __typename
      name
    }
    memo
    messageForCandidate
    timeLimitSeconds
    totalScore
    customScore
    willExpireAtSeconds
    rank
    numSubset
    employee {
      id
      email
      emailVerified
      displayName
      fullName
      language
      photoUrl
    }
    recommenders {
      id
      displayName
      email
      photoUrl
    }
    evaluatedBy {
      id
      displayName
      email
      photoUrl
    }
    reviewers {
      __typename
      employee {
        ...InterviewDetailUser
      }
      type
      group {
        id
        groupName
        companyEmployees {
          employeeId
          role
        }
      }
    }
  }
  ${InterviewDetailUserFragmentDoc}
  ${InterviewOverviewChallengeFragmentDoc}
  ${InterviewOverviewQuizFragmentDoc}
  ${InterviewOverviewProjectFragmentDoc}
  ${InterviewOverviewSystemDesignFragmentDoc}
`;
export const SpotSharedLinkForScreeningsTestsIdDetailFragmentDoc = gql`
  fragment SpotSharedLinkForScreeningsTestsIdDetail on SpotSharedLink {
    spotId
    linkHash
    showAnswer
    showRelativeEvaluation
    showPlayback
    showRank
    showReview
    showScore
    createdAtSeconds
    updatedAtSeconds
    deletedAtSeconds
    expiredAtSeconds
  }
`;
export const ChallengeEntityForRetakeInterviewIdDetailFragmentDoc = gql`
  fragment ChallengeEntityForRetakeInterviewIdDetail on ChallengeEntity {
    __typename
    id
    challengeEntityId
    challengeStatus: status
    challengeQuestion(withAnswers: false) {
      id
      questionId
      version
      titleJa
      titleEn
      timeLimitSeconds
    }
    enabledLanguages
  }
`;
export const QuizEntityForRetakeInterviewIdDetailFragmentDoc = gql`
  fragment QuizEntityForRetakeInterviewIdDetail on QuizEntity {
    __typename
    id
    quizEntityId
    pb_package(withAnswers: true) {
      id
      packageId
      version
      titleEn
      titleJa
      timeLimitSeconds
    }
    quizStatus: status
  }
`;
export const ProjectEntityForRetakeInterviewIdDetailFragmentDoc = gql`
  fragment ProjectEntityForRetakeInterviewIdDetail on ProjectEntity {
    __typename
    id
    projectEntityId
    projectStatus: status
    question {
      id
      questionId
      version
      titleEn
      titleJa
      timeLimitSeconds
    }
  }
`;
export const SystemDesignEntityForRetakeInterviewIdDetailFragmentDoc = gql`
  fragment SystemDesignEntityForRetakeInterviewIdDetail on SystemDesignEntity {
    __typename
    id
    systemDesignEntityId
    systemDesignStatus: status
    question {
      id
      questionId
      titleJa
      titleEn
      timeLimitSeconds
    }
    componentTypes
  }
`;
export const SpotForRetakeInterviewsIdDetailFragmentDoc = gql`
  fragment SpotForRetakeInterviewsIdDetail on Spot {
    id
    spotId
    timeLimitSeconds
    elapsedTimeSeconds
    entities(withAnswers: false) {
      ... on ChallengeEntity {
        ...ChallengeEntityForRetakeInterviewIdDetail
      }
      ... on QuizEntity {
        ...QuizEntityForRetakeInterviewIdDetail
      }
      ... on ProjectEntity {
        ...ProjectEntityForRetakeInterviewIdDetail
      }
      ... on SystemDesignEntity {
        ...SystemDesignEntityForRetakeInterviewIdDetail
      }
    }
  }
  ${ChallengeEntityForRetakeInterviewIdDetailFragmentDoc}
  ${QuizEntityForRetakeInterviewIdDetailFragmentDoc}
  ${ProjectEntityForRetakeInterviewIdDetailFragmentDoc}
  ${SystemDesignEntityForRetakeInterviewIdDetailFragmentDoc}
`;
export const QuizEntityForEmployeeInterviewsIdReviewFragmentDoc = gql`
  fragment QuizEntityForEmployeeInterviewsIdReview on QuizEntity {
    __typename
  }
`;
export const ProjectEntityForEmployeeInterviewsIdReviewFragmentDoc = gql`
  fragment ProjectEntityForEmployeeInterviewsIdReview on ProjectEntity {
    __typename
    projectEntityId
    projectStatus: status
    question {
      questionId
      version
      projectVersion
      variant
      titleJa
      titleEn
      correctnessTestCase
      performanceTestCase
      defaultTestCase
      descriptionJa
      descriptionEn
      timeLimitSeconds
      fileIndexes
      readOnlyFiles
    }
    appealMessage
    submissions {
      projectSubmissionId
    }
    agentVersion
  }
`;
export const ChallengeEntityForEmployeeInterviewsIdReviewFragmentDoc = gql`
  fragment ChallengeEntityForEmployeeInterviewsIdReview on ChallengeEntity {
    __typename
  }
`;
export const SystemDesignEntityForEmployeeInterviewsIdReviewFragmentDoc = gql`
  fragment SystemDesignEntityForEmployeeInterviewsIdReview on SystemDesignEntity {
    __typename
  }
`;
export const SpotForEmployeeInterviewsIdReviewFragmentDoc = gql`
  fragment SpotForEmployeeInterviewsIdReview on Spot {
    name
    candidateName
    status
    entities(withAnswers: true) {
      ... on QuizEntity {
        ...QuizEntityForEmployeeInterviewsIdReview
      }
      ... on ProjectEntity {
        ...ProjectEntityForEmployeeInterviewsIdReview
      }
      ... on ChallengeEntity {
        ...ChallengeEntityForEmployeeInterviewsIdReview
      }
      ... on SystemDesignEntity {
        ...SystemDesignEntityForEmployeeInterviewsIdReview
      }
    }
  }
  ${QuizEntityForEmployeeInterviewsIdReviewFragmentDoc}
  ${ProjectEntityForEmployeeInterviewsIdReviewFragmentDoc}
  ${ChallengeEntityForEmployeeInterviewsIdReviewFragmentDoc}
  ${SystemDesignEntityForEmployeeInterviewsIdReviewFragmentDoc}
`;
export const ExamForAssessmentsIdFragmentDoc = gql`
  fragment ExamForAssessmentsId on Exam {
    examId
    company {
      name
    }
    messageForTalent
    status
    timeLimitSeconds
    willEndAtSeconds
    didStartAtSeconds
    entityCount
    entities(withAnswers: false) {
      ... on QuizEntity {
        ...QuizEntityForCandidateInterview
      }
      ... on ProjectEntity {
        ...ProjectEntityForCandidateInterview
      }
      ... on ChallengeEntity {
        ...ChallengeEntityForCandidateInterview
      }
      ... on SystemDesignEntity {
        ...SystemDesignEntityForCandidateInterview
      }
    }
  }
  ${QuizEntityForCandidateInterviewFragmentDoc}
  ${ProjectEntityForCandidateInterviewFragmentDoc}
  ${ChallengeEntityForCandidateInterviewFragmentDoc}
  ${SystemDesignEntityForCandidateInterviewFragmentDoc}
`;
export const ExamForEntryAssessmentsIdFragmentDoc = gql`
  fragment ExamForEntryAssessmentsId on Exam {
    examId
    company {
      name
    }
    messageForTalent
    status
    timeLimitSeconds
    willEndAtSeconds
    didStartAtSeconds
    entityCount
  }
`;
export const AssessmentForDetailTalentFragmentDoc = gql`
  fragment AssessmentForDetailTalent on Assessment {
    id
    assessmentId
    createdAtSeconds
    nextScheduleAtSeconds
    examInterval
    rootExamIds
    isPublic
    name
    memo
    messageForTalent
    timeLimitSeconds
    status
    reportSettings {
      showAnswer
      showPlayback
      showRelativeEvaluation
    }
    questions(withAnswers: false) {
      __typename
    }
    employee {
      ...AssessmentDetailUser
    }
    talent {
      ...AssessmentDetailUser
    }
    viewers {
      __typename
      ... on User {
        ...AssessmentDetailUser
      }
      ... on EmployeeGroup {
        id
        groupName
        companyEmployees {
          employeeId
          role
        }
      }
    }
  }
  ${AssessmentDetailUserFragmentDoc}
`;
export const ExamForAssessmentsIdDetailTalentFragmentDoc = gql`
  fragment ExamForAssessmentsIdDetailTalent on Exam {
    __typename
    id
    examId
    assessmentId
    originId
    numInterval
    numTry
    startIntervalAtSeconds
    endIntervalAtSeconds
    relativeScore
    totalScore
    status
    createdAtSeconds
    willEndAtSeconds
  }
`;
export const ListBestExamsResponseForAssessmentsIdDetailTalentFragmentDoc = gql`
  fragment ListBestExamsResponseForAssessmentsIdDetailTalent on ListBestExamsByAssessmentId {
    exams {
      ...ExamForAssessmentsIdDetailTalent
    }
    count
    hasNext
  }
  ${ExamForAssessmentsIdDetailTalentFragmentDoc}
`;
export const ListExamsRecursivePayloadForAssessmentsIdDetailTalentFragmentDoc = gql`
  fragment ListExamsRecursivePayloadForAssessmentsIdDetailTalent on ListExamsRecursivePayload {
    exams {
      ...ExamForAssessmentsIdDetailTalent
    }
    bestExamId
  }
  ${ExamForAssessmentsIdDetailTalentFragmentDoc}
`;
export const InvitationForTalentInvitationsIdFragmentDoc = gql`
  fragment InvitationForTalentInvitationsId on TalentInvitation {
    id
    expiresAtSeconds
  }
`;
export const CompanyTalentForTalentInvitationsIdFragmentDoc = gql`
  fragment CompanyTalentForTalentInvitationsId on CompanyTalent {
    companyId
    talentId
  }
`;
export const CompanyForTalentInvitationsIdFragmentDoc = gql`
  fragment CompanyForTalentInvitationsId on Company {
    name
    companyId
    companyTalents {
      ...CompanyTalentForTalentInvitationsId
    }
  }
  ${CompanyTalentForTalentInvitationsIdFragmentDoc}
`;
export const AlgorithmAnswerRuntimeForAlgorithmResourceEditorFragmentDoc = gql`
  fragment AlgorithmAnswerRuntimeForAlgorithmResourceEditor on AlgorithmAnswerRuntime {
    id
    answerId
    machineCpu
    machineMemory
    machineOs
    readability
    stats
    runtime
    codeBody
  }
`;
export const AlgorithmAnswerForAlgorithmResourceEditorFragmentDoc = gql`
  fragment AlgorithmAnswerForAlgorithmResourceEditor on AlgorithmAnswer {
    id
    uniqueId
    questionId
    questionVersion
    titleEn
    titleJa
    label
    descriptionEn
    descriptionJa
    runtimeComplexity
    spaceComplexity
    answerRuntimes {
      ...AlgorithmAnswerRuntimeForAlgorithmResourceEditor
    }
  }
  ${AlgorithmAnswerRuntimeForAlgorithmResourceEditorFragmentDoc}
`;
export const AlgorithmHintForAlgorithmResourceEditorFragmentDoc = gql`
  fragment AlgorithmHintForAlgorithmResourceEditor on AlgorithmHint {
    id
    uniqueId
    questionId
    questionVersion
    descriptionJa
    descriptionEn
    orderNum
  }
`;
export const AlgorithmQuestionForAlgorithmResourceEditorFragmentDoc = gql`
  fragment AlgorithmQuestionForAlgorithmResourceEditor on AlgorithmQuestion {
    id
    questionId
    slug
    version
    variant
    companyId
    employeeId
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    difficulty
    timeLimitSeconds
    isPrivate
    status
    supportedLanguages
    signature
    correctnessTestCase
    performanceTestCase
    correctnessTestCaseIds
    performanceTestCaseIds
    performanceTimeLimitMilliSeconds
    isOfficial
    answers {
      ...AlgorithmAnswerForAlgorithmResourceEditor
    }
    hints {
      ...AlgorithmHintForAlgorithmResourceEditor
    }
    numUses
    accuracyRate
    averageElapsedTimeSeconds
  }
  ${AlgorithmAnswerForAlgorithmResourceEditorFragmentDoc}
  ${AlgorithmHintForAlgorithmResourceEditorFragmentDoc}
`;
export const BehavioralEventLogsForChallengeTestReportFragmentDoc = gql`
  fragment BehavioralEventLogsForChallengeTestReport on BehavioralEventLogs {
    events {
      ... on WebSearchedEvent {
        __typename
        query
        page
        searchedAtSeconds
        searchedAtNanoSeconds
      }
      ... on WebAccessedEvent {
        __typename
        url
        title
        description
        accessedAtSeconds
        accessedAtNanoSeconds
      }
      ... on ChatGPTRequestedEvent {
        __typename
        chatId
        prompt
        gptModel
        requestedAtSeconds
        requestedAtNanoSeconds
      }
      ... on ChatGPTRespondedEvent {
        __typename
        chatId
        response
        gptModel
        respondedAtSeconds
        respondedAtNanoSeconds
      }
      ... on ChatGPTConversationResetEvent {
        __typename
        chatId
        resetAtSeconds
        resetAtNanoSeconds
      }
    }
  }
`;
export const InitialCodesForFreepadResourceEditorFragmentDoc = gql`
  fragment InitialCodesForFreepadResourceEditor on FreepadInitialCode {
    id
    questionId
    questionVersion
    freepadInitialCodeId
    runtime
    body
  }
`;
export const InitialFlowChartsForFreepadResourceEditorFragmentDoc = gql`
  fragment InitialFlowChartsForFreepadResourceEditor on FreepadInitialFlowChart {
    id
    questionId
    questionVersion
    freepadInitialFlowChartId
    componentType
    body
  }
`;
export const AnswerFlowChartForFreepadResourceEditorFragmentDoc = gql`
  fragment AnswerFlowChartForFreepadResourceEditor on FreepadAnswerFlowChart {
    id
    answerId
    freepadAnswerFlowChartId
    body
    componentType
  }
`;
export const AnswerCodeForFreepadResourceEditorFragmentDoc = gql`
  fragment AnswerCodeForFreepadResourceEditor on FreepadAnswerCode {
    id
    answerId
    freepadAnswerCodeId
    runtime
    body
  }
`;
export const AnswerForFreepadResourceEditorFragmentDoc = gql`
  fragment AnswerForFreepadResourceEditor on FreepadAnswer {
    id
    label
    questionId
    questionVersion
    freepadAnswerId
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    answerFlowCharts {
      ...AnswerFlowChartForFreepadResourceEditor
    }
    answerCodes {
      ...AnswerCodeForFreepadResourceEditor
    }
  }
  ${AnswerFlowChartForFreepadResourceEditorFragmentDoc}
  ${AnswerCodeForFreepadResourceEditorFragmentDoc}
`;
export const FreepadQuestionForFreepadResourceEditorFragmentDoc = gql`
  fragment FreepadQuestionForFreepadResourceEditor on FreepadQuestion {
    id
    freepadQuestionId
    version
    variant
    companyId
    employeeId
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    difficulty
    isOfficial
    isPrivate
    status
    initialCodes {
      ...InitialCodesForFreepadResourceEditor
    }
    initialFlowCharts {
      ...InitialFlowChartsForFreepadResourceEditor
    }
    answers {
      ...AnswerForFreepadResourceEditor
    }
    tags {
      name
    }
    numUses
    averageElapsedTimeSeconds
  }
  ${InitialCodesForFreepadResourceEditorFragmentDoc}
  ${InitialFlowChartsForFreepadResourceEditorFragmentDoc}
  ${AnswerForFreepadResourceEditorFragmentDoc}
`;
export const AlgorithmTemplateCodeForFreepadResourceEditorFragmentDoc = gql`
  fragment AlgorithmTemplateCodeForFreepadResourceEditor on AlgorithmTemplateCodeResponse {
    python3
    go
    javascript
    php
    ruby
    typescript
    cpp
    kotlin
    java
    swift
    scala
    rust
    sqlite
    pgsql
    mysql
    dart
    csharp
    c
    perl
  }
`;
export const CreateMultiChoicePackageFragmentDoc = gql`
  fragment CreateMultiChoicePackage on MultiChoicePackage {
    id
    packageId
    version
    companyId
    employeeId
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    difficulty
    isPrivate
    status
    questions {
      id
      version
      isPrivate
    }
    timeLimitSeconds
    createdAtSeconds
    updatedAtSeconds
    deletedAtSeconds
  }
`;
export const UpdateMultiChoicePackageFragmentDoc = gql`
  fragment UpdateMultiChoicePackage on MultiChoicePackage {
    id
    packageId
    version
    companyId
    employeeId
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    difficulty
    isPrivate
    status
    questions {
      id
      version
    }
    timeLimitSeconds
    createdAtSeconds
    updatedAtSeconds
    deletedAtSeconds
  }
`;
export const MultiChoiceQuestionForQuizResourceEditorFragmentDoc = gql`
  fragment MultiChoiceQuestionForQuizResourceEditor on MultiChoiceQuestion {
    id
    version
    titleJa
    titleEn
    difficulty
    descriptionJa
    descriptionEn
    tags {
      id
      name
    }
    options {
      id
      titleJa
      titleEn
      isCorrect
      additionalDetailJa
      additionalDetailEn
      createdAtSeconds
      updatedAtSeconds
      deletedAtSeconds
    }
    metricType
    companyId
    employeeId
    isPrivate
    isOfficial
    status
    versions
    correctAnswerRate
    variant
    evaluationItems {
      id
      questionId
      questionVersion
      titleJa
      titleEn
      descriptionJa
      descriptionEn
    }
    createdAtSeconds
    updatedAtSeconds
    deletedAtSeconds
  }
`;
export const GetMultiChoicePackageFragmentDoc = gql`
  fragment GetMultiChoicePackage on MultiChoicePackage {
    id
    packageId
    version
    companyId
    employeeId
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    difficulty
    isPrivate
    status
    questions {
      ...MultiChoiceQuestionForQuizResourceEditor
    }
    timeLimitSeconds
    createdAtSeconds
    updatedAtSeconds
    deletedAtSeconds
  }
  ${MultiChoiceQuestionForQuizResourceEditorFragmentDoc}
`;
export const ListRemoteTagsForRemoteInterviewResourceEditorTagFragmentDoc = gql`
  fragment ListRemoteTagsForRemoteInterviewResourceEditorTag on RemoteTagsByCompanyIdResponse {
    tags {
      name
      companyId
    }
    count
    pager {
      cursorSeconds
      hasNext
    }
  }
`;
export const GetRemoteForRemoteInterviewResourceEditorAlgorithmQuestionFragmentDoc = gql`
  fragment GetRemoteForRemoteInterviewResourceEditorAlgorithmQuestion on AlgorithmQuestion {
    __typename
    id
    questionId
    version
    key
    companyId
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    status
    supportedLanguages
    difficulty
    isPrivate
    isOfficial
    timeLimitSeconds
    numUses
    accuracyRate
    averageElapsedTimeSeconds
    AlgorithmQuestionVariant: variant
  }
`;
export const GetRemoteForRemoteInterviewResourceEditorSystemDesignQuestionFragmentDoc = gql`
  fragment GetRemoteForRemoteInterviewResourceEditorSystemDesignQuestion on SystemDesignQuestion {
    __typename
    id
    questionId
    key
    companyId
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    difficulty
    isPrivate
    isOfficial
    timeLimitSeconds
    numUses
    accuracyRate
    averageElapsedTimeSeconds
  }
`;
export const GetRemoteForRemoteInterviewResourceEditorFreepadQuestionFragmentDoc = gql`
  fragment GetRemoteForRemoteInterviewResourceEditorFreepadQuestion on FreepadQuestion {
    __typename
    id
    freepadQuestionId
    key
    version
    variant
    companyId
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    difficulty
    isPrivate
    status
    isOfficial
    numUses
    averageElapsedTimeSeconds
  }
`;
export const GetRemoteForRemoteInterviewResourceEditorRemoteFragmentDoc = gql`
  fragment GetRemoteForRemoteInterviewResourceEditorRemote on Remote {
    id
    name
    candidateName
    memo
    isPublic
    status
    creator {
      uid
      email
      displayName
      photoUrl
    }
    viewers {
      __typename
      ... on User {
        uid
        displayName
      }
      ... on EmployeeGroup {
        employeeGroupId
        groupName
      }
    }
    tags {
      name
    }
    entity {
      entityType
      liveCoding {
        sessions {
          questionType
          questionVariant
          algorithmQuestion(withAnswers: false) {
            ...GetRemoteForRemoteInterviewResourceEditorAlgorithmQuestion
          }
          systemDesignQuestion(withAnswers: false) {
            ...GetRemoteForRemoteInterviewResourceEditorSystemDesignQuestion
          }
          freepadQuestion(withAnswers: false) {
            ...GetRemoteForRemoteInterviewResourceEditorFreepadQuestion
          }
        }
      }
    }
  }
  ${GetRemoteForRemoteInterviewResourceEditorAlgorithmQuestionFragmentDoc}
  ${GetRemoteForRemoteInterviewResourceEditorSystemDesignQuestionFragmentDoc}
  ${GetRemoteForRemoteInterviewResourceEditorFreepadQuestionFragmentDoc}
`;
export const ListAlgorithmQuestionForQuestionSelectFieldForResourceEditorFragmentDoc = gql`
  fragment ListAlgorithmQuestionForQuestionSelectFieldForResourceEditor on AlgorithmQuestion {
    __typename
    questionId
    version
    algorithmQuestionVariant: variant
    key
    titleJa
    titleEn
    algorithmQuestionStatus: status
    supportedLanguages
    difficulty
    isPrivate
    isOfficial
    timeLimitSeconds
    numUses
    leakScore {
      level
    }
    isSupportedLeakScore
    accuracyRate
    averageElapsedTimeSeconds
  }
`;
export const ListSystemDesignQuestionForQuestionSelectFieldForResourceEditorFragmentDoc = gql`
  fragment ListSystemDesignQuestionForQuestionSelectFieldForResourceEditor on SystemDesignQuestion {
    __typename
    questionId
    key
    titleJa
    titleEn
    difficulty
    isPrivate
    isOfficial
    leakScore {
      level
    }
    isSupportedLeakScore
    timeLimitSeconds
    numUses
    accuracyRate
    averageElapsedTimeSeconds
    systemDesignQuestionStatus: status
  }
`;
export const ListFreepadQuestionForQuestionSelectFieldForResourceEditorFragmentDoc = gql`
  fragment ListFreepadQuestionForQuestionSelectFieldForResourceEditor on FreepadQuestion {
    __typename
    key
    freepadQuestionId
    version
    titleJa
    titleEn
    difficulty
    isPrivate
    isOfficial
    leakScore {
      level
    }
    isSupportedLeakScore
    numUses
    freepadQuestionStatus: status
  }
`;
export const RemoteForRemoteInterviewResourceEditorFragmentDoc = gql`
  fragment RemoteForRemoteInterviewResourceEditor on Remote {
    id
    name
    candidateName
    memo
    isPublic
    status
    creator {
      uid
      email
      displayName
      photoUrl
    }
    viewers {
      __typename
      ... on User {
        uid
        displayName
      }
      ... on EmployeeGroup {
        employeeGroupId
        groupName
      }
    }
    tags {
      name
    }
    entity {
      entityType
      liveCoding {
        sessions {
          questionType
          questionVariant
          algorithmQuestion(withAnswers: false) {
            ...ListAlgorithmQuestionForQuestionSelectFieldForResourceEditor
          }
          systemDesignQuestion(withAnswers: false) {
            ...ListSystemDesignQuestionForQuestionSelectFieldForResourceEditor
          }
          freepadQuestion(withAnswers: false) {
            ...ListFreepadQuestionForQuestionSelectFieldForResourceEditor
          }
        }
      }
    }
  }
  ${ListAlgorithmQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
  ${ListSystemDesignQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
  ${ListFreepadQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
`;
export const ListRemotesForRemoteInterviewResourceEditorFragmentDoc = gql`
  fragment ListRemotesForRemoteInterviewResourceEditor on RemotesByCompanyIdResponse {
    remotes {
      id
      name
    }
    count
    hasNext
  }
`;
export const ListAlgorithmQuestionForRemoteInterviewResourceEditorFragmentDoc = gql`
  fragment ListAlgorithmQuestionForRemoteInterviewResourceEditor on AlgorithmQuestion {
    __typename
    id
    questionId
    version
    key
    companyId
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    status
    supportedLanguages
    difficulty
    isPrivate
    isOfficial
    timeLimitSeconds
    numUses
    accuracyRate
    averageElapsedTimeSeconds
    AlgorithmQuestionVariant: variant
  }
`;
export const ListSystemDesignQuestionForRemoteInterviewResourceEditorFragmentDoc = gql`
  fragment ListSystemDesignQuestionForRemoteInterviewResourceEditor on SystemDesignQuestion {
    __typename
    id
    questionId
    key
    companyId
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    difficulty
    isPrivate
    isOfficial
    numUses
    accuracyRate
    averageElapsedTimeSeconds
    timeLimitSeconds
  }
`;
export const ListFreepadQuestionForRemoteInterviewResourceEditorFragmentDoc = gql`
  fragment ListFreepadQuestionForRemoteInterviewResourceEditor on FreepadQuestion {
    __typename
    id
    version
    freepadQuestionId
    key
    companyId
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    difficulty
    status
    isPrivate
    isOfficial
    variant
    numUses
    averageElapsedTimeSeconds
  }
`;
export const ListQuestionForRemoteInterviewResourceEditorFragmentDoc = gql`
  fragment ListQuestionForRemoteInterviewResourceEditor on QuestionObject {
    id
    questionVariant
    liveCodingQuestion {
      ... on AlgorithmQuestion {
        ...ListAlgorithmQuestionForRemoteInterviewResourceEditor
      }
      ... on SystemDesignQuestion {
        ...ListSystemDesignQuestionForRemoteInterviewResourceEditor
      }
      ... on FreepadQuestion {
        ...ListFreepadQuestionForRemoteInterviewResourceEditor
      }
    }
  }
  ${ListAlgorithmQuestionForRemoteInterviewResourceEditorFragmentDoc}
  ${ListSystemDesignQuestionForRemoteInterviewResourceEditorFragmentDoc}
  ${ListFreepadQuestionForRemoteInterviewResourceEditorFragmentDoc}
`;
export const TemplateForRemoteInterviewResourceEditorFragmentDoc = gql`
  fragment TemplateForRemoteInterviewResourceEditor on RemoteInterviewTemplate {
    id
    templateId
    templateName
    description
    remoteName
    candidateName
    reviewers {
      type
      employee {
        id
        displayName
      }
      group {
        id
        groupName
      }
    }
    tagNames
    memo
    isPublic
    questions {
      questionId
      questionKey
      entityType
      challengeQuestion {
        questionId
        questionVersion
        uniqueKey
      }
      systemDesignQuestion {
        uniqueKey
        questionId
        componentTypes
      }
      freepadQuestion {
        questionId
        questionVersion
        uniqueKey
      }
    }
    templateQuestions(withAnswers: false) {
      ... on AlgorithmQuestion {
        ...ListAlgorithmQuestionForQuestionSelectFieldForResourceEditor
      }
      ... on SystemDesignQuestion {
        ...ListSystemDesignQuestionForQuestionSelectFieldForResourceEditor
      }
      ... on FreepadQuestion {
        ...ListFreepadQuestionForQuestionSelectFieldForResourceEditor
      }
    }
    variables {
      id
      key
      templateId
      name
      description
    }
  }
  ${ListAlgorithmQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
  ${ListSystemDesignQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
  ${ListFreepadQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
`;
export const RemoteInterviewTemplatesByCompanyIdResponseForRemoteInterviewResourceEditorFragmentDoc = gql`
  fragment RemoteInterviewTemplatesByCompanyIdResponseForRemoteInterviewResourceEditor on RemoteInterviewTemplatesByCompanyIdResponse {
    templates {
      ...TemplateForRemoteInterviewResourceEditor
    }
    count
    pager {
      idCursor
      hasNext
    }
  }
  ${TemplateForRemoteInterviewResourceEditorFragmentDoc}
`;
export const RemoteTagsByCompanyIdResponseForRemoteInterviewTemplateResourceEditorFragmentDoc = gql`
  fragment RemoteTagsByCompanyIdResponseForRemoteInterviewTemplateResourceEditor on RemoteTagsByCompanyIdResponse {
    tags {
      name
      companyId
    }
    count
    pager {
      cursorSeconds
      hasNext
    }
  }
`;
export const AlgorithmQuestionForRemoteInterviewTemplateResourceEditorFragmentDoc = gql`
  fragment AlgorithmQuestionForRemoteInterviewTemplateResourceEditor on AlgorithmQuestion {
    __typename
    id
    questionId
    version
    key
    companyId
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    status
    supportedLanguages
    difficulty
    isPrivate
    isOfficial
    timeLimitSeconds
    numUses
    accuracyRate
    averageElapsedTimeSeconds
  }
`;
export const SystemDesignQuestionForRemoteInterviewTemplateResourceEditorFragmentDoc = gql`
  fragment SystemDesignQuestionForRemoteInterviewTemplateResourceEditor on SystemDesignQuestion {
    __typename
    id
    questionId
    key
    companyId
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    difficulty
    isPrivate
    isOfficial
    timeLimitSeconds
    numUses
    accuracyRate
    averageElapsedTimeSeconds
  }
`;
export const FreepadQuestionForRemoteInterviewTemplateResourceEditorFragmentDoc = gql`
  fragment FreepadQuestionForRemoteInterviewTemplateResourceEditor on FreepadQuestion {
    __typename
    id
    freepadQuestionId
    key
    version
    variant
    companyId
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    difficulty
    isPrivate
    status
    isOfficial
    numUses
    averageElapsedTimeSeconds
  }
`;
export const RemoteForRemoteInterviewTemplateResourceEditorFragmentDoc = gql`
  fragment RemoteForRemoteInterviewTemplateResourceEditor on Remote {
    id
    name
    candidateName
    memo
    status
    isPublic
    viewers {
      __typename
      ... on User {
        uid
        displayName
      }
      ... on EmployeeGroup {
        employeeGroupId
        groupName
      }
    }
    tags {
      name
    }
    entity {
      entityType
      liveCoding {
        sessions {
          questionType
          questionVariant
          algorithmQuestion(withAnswers: false) {
            ...AlgorithmQuestionForRemoteInterviewTemplateResourceEditor
          }
          systemDesignQuestion(withAnswers: false) {
            ...SystemDesignQuestionForRemoteInterviewTemplateResourceEditor
          }
          freepadQuestion(withAnswers: false) {
            ...FreepadQuestionForRemoteInterviewTemplateResourceEditor
          }
        }
      }
    }
  }
  ${AlgorithmQuestionForRemoteInterviewTemplateResourceEditorFragmentDoc}
  ${SystemDesignQuestionForRemoteInterviewTemplateResourceEditorFragmentDoc}
  ${FreepadQuestionForRemoteInterviewTemplateResourceEditorFragmentDoc}
`;
export const RemoteInterviewTemplateForRemoteInterviewTemplateResourceEditorFragmentDoc = gql`
  fragment RemoteInterviewTemplateForRemoteInterviewTemplateResourceEditor on RemoteInterviewTemplate {
    id
    templateName
    remoteName
    candidateName
    description
    memo
    isPublic
    reviewers {
      type
      employee {
        id
        displayName
      }
      group {
        id
        groupName
      }
    }
    tagNames
    templateQuestions(withAnswers: false) {
      __typename
      ... on AlgorithmQuestion {
        ...AlgorithmQuestionForRemoteInterviewTemplateResourceEditor
      }
      ... on SystemDesignQuestion {
        ...SystemDesignQuestionForRemoteInterviewTemplateResourceEditor
      }
      ... on FreepadQuestion {
        ...FreepadQuestionForRemoteInterviewTemplateResourceEditor
      }
    }
    questions {
      __typename
      questionId
      questionKey
      entityType
      challengeQuestion {
        questionId
        uniqueKey
        enabledLanguages
      }
      systemDesignQuestion {
        questionId
        componentTypes
      }
    }
    variables {
      id
      templateId
      name
      description
    }
  }
  ${AlgorithmQuestionForRemoteInterviewTemplateResourceEditorFragmentDoc}
  ${SystemDesignQuestionForRemoteInterviewTemplateResourceEditorFragmentDoc}
  ${FreepadQuestionForRemoteInterviewTemplateResourceEditorFragmentDoc}
`;
export const RemoteInterviewTemplateForRemoteTemplateResourceEditorFragmentDoc = gql`
  fragment RemoteInterviewTemplateForRemoteTemplateResourceEditor on RemoteInterviewTemplate {
    id
    templateName
    remoteName
    candidateName
    description
    memo
    isPublic
    reviewers {
      type
      employee {
        id
        displayName
      }
      group {
        id
        groupName
      }
    }
    tagNames
    templateQuestions(withAnswers: false) {
      __typename
      ... on AlgorithmQuestion {
        ...ListAlgorithmQuestionForQuestionSelectFieldForResourceEditor
      }
      ... on SystemDesignQuestion {
        ...ListSystemDesignQuestionForQuestionSelectFieldForResourceEditor
      }
      ... on FreepadQuestion {
        ...ListFreepadQuestionForQuestionSelectFieldForResourceEditor
      }
    }
    questions {
      __typename
      questionId
      questionKey
      entityType
      challengeQuestion {
        questionId
        uniqueKey
        enabledLanguages
      }
      systemDesignQuestion {
        questionId
        componentTypes
      }
    }
    variables {
      id
      templateId
      name
      description
    }
  }
  ${ListAlgorithmQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
  ${ListSystemDesignQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
  ${ListFreepadQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
`;
export const RemoteForRemoteTemplateResourceEditorFragmentDoc = gql`
  fragment RemoteForRemoteTemplateResourceEditor on Remote {
    id
    name
    candidateName
    memo
    status
    isPublic
    viewers {
      __typename
      ... on User {
        uid
        displayName
      }
      ... on EmployeeGroup {
        employeeGroupId
        groupName
      }
    }
    tags {
      name
    }
    entity {
      entityType
      liveCoding {
        sessions {
          questionType
          questionVariant
          algorithmQuestion(withAnswers: false) {
            ...ListAlgorithmQuestionForQuestionSelectFieldForResourceEditor
          }
          systemDesignQuestion(withAnswers: false) {
            ...ListSystemDesignQuestionForQuestionSelectFieldForResourceEditor
          }
          freepadQuestion(withAnswers: false) {
            ...ListFreepadQuestionForQuestionSelectFieldForResourceEditor
          }
        }
      }
    }
  }
  ${ListAlgorithmQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
  ${ListSystemDesignQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
  ${ListFreepadQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
`;
export const QuestionObjectForRemoteInterviewTemplateResourceEditorFragmentDoc = gql`
  fragment QuestionObjectForRemoteInterviewTemplateResourceEditor on QuestionObject {
    id
    questionVariant
    liveCodingQuestion {
      ... on AlgorithmQuestion {
        ...AlgorithmQuestionForRemoteInterviewTemplateResourceEditor
      }
      ... on SystemDesignQuestion {
        ...SystemDesignQuestionForRemoteInterviewTemplateResourceEditor
      }
      ... on FreepadQuestion {
        ...FreepadQuestionForRemoteInterviewTemplateResourceEditor
      }
    }
  }
  ${AlgorithmQuestionForRemoteInterviewTemplateResourceEditorFragmentDoc}
  ${SystemDesignQuestionForRemoteInterviewTemplateResourceEditorFragmentDoc}
  ${FreepadQuestionForRemoteInterviewTemplateResourceEditorFragmentDoc}
`;
export const InterviewFeedbackRecommendationForCandidateInterviewFeedbackFragmentDoc = gql`
  fragment InterviewFeedbackRecommendationForCandidateInterviewFeedback on EvaluationRecommendation {
    id
    employeeId
    evaluationRecommendationId
    evaluations {
      id
      evaluationId
      employeeId
      metricId
      comment
      numStars
      metric {
        id
        title
        metricId
        description
      }
    }
    comment
  }
`;
export const InterviewFeedbackForCandidateInterviewFeedbackFragmentDoc = gql`
  fragment InterviewFeedbackForCandidateInterviewFeedback on Spot {
    id
    recommendations {
      ...InterviewFeedbackRecommendationForCandidateInterviewFeedback
    }
  }
  ${InterviewFeedbackRecommendationForCandidateInterviewFeedbackFragmentDoc}
`;
export const ChallengeSimilarCodeForChallengeTestReportFragmentDoc = gql`
  fragment ChallengeSimilarCodeForChallengeTestReport on ChallengeSubmissionSimilarCode {
    code
    score
    metadata {
      __typename
      ... on SimilarCodeCrawlerMetadata {
        url
      }
      ... on SimilarCodeNotFoundMetadata {
        reserved
      }
      ... on SimilarCodeOtherCompanyMetadata {
        reserved
      }
      ... on SimilarCodeSpotMetadata {
        spotId
        challengeId
        questionId
        questionVersion
      }
    }
    fragments {
      left {
        endCol
        endRow
        startCol
        startRow
      }
      right {
        endCol
        endRow
        startCol
        startRow
      }
    }
  }
`;
export const TestReportChallengeSubmissionFragmentDoc = gql`
  fragment TestReportChallengeSubmission on ChallengeSubmission {
    id
    challengeSubmissionId
    questionId
    questionVersion
    isBest
    runtime
    runtimeVersion
    correctnessTestResult
    totalScore
    numSubset
    totalElapsedTimeSeconds
    readabilityTestResult
    evaluatedAtSeconds
    coverage
    performance
    readability
    runtimeComplexity
    spaceComplexity
    avgPerformance
    avgMaxMemory
    machineOs
    machineMemory
    machineCpu
    numPassed
    numTests
    numTabEvents
    numPasteEvents
    numAccessEvents
    similarityScore
    similarCodes {
      ...ChallengeSimilarCodeForChallengeTestReport
    }
    code
    challengeSubmissionStatus: status
    elapsedTimeSeconds
    performanceTestResult
    snapshots {
      id
      challengeSnapshotId
    }
    challengeUsedHintCount
  }
  ${ChallengeSimilarCodeForChallengeTestReportFragmentDoc}
`;
export const AlgorithmInitialCodeForChallengeTestReportFragmentDoc = gql`
  fragment AlgorithmInitialCodeForChallengeTestReport on AlgorithmInitialCode {
    c
    cpp
    csharp
    dart
    go
    java
    javascript
    kotlin
    mysql
    perl
    pgsql
    php
    python3
    ruby
    rust
    scala
    sqlite
    swift
    typescript
  }
`;
export const AlgorithmQuestionForChallengeTestReportFragmentDoc = gql`
  fragment AlgorithmQuestionForChallengeTestReport on AlgorithmQuestion {
    id
    questionId
    version
    variant
    titleJa
    titleEn
    difficulty
    descriptionEn
    descriptionJa
    correctnessTestCase
    algorithmQuestionStatus: status
    leakScore {
      level
    }
    isSupportedLeakScore
    numUses
    supportedLanguages
    timeLimitSeconds
    averageElapsedTimeSeconds
    accuracyRate
    isOfficial
    isPrivate
    employee {
      id
      displayName
    }
    initialCode {
      ...AlgorithmInitialCodeForChallengeTestReport
    }
    answers {
      id
      titleEn
      titleJa
      descriptionJa
      descriptionEn
      label
      runtimeComplexity
      spaceComplexity
    }
    hints {
      id
      descriptionJa
      descriptionEn
      orderNum
    }
    performanceTimeLimitMilliSeconds
  }
  ${AlgorithmInitialCodeForChallengeTestReportFragmentDoc}
`;
export const ChallengeWebSessionForChallengeTestReportFragmentDoc = gql`
  fragment ChallengeWebSessionForChallengeTestReport on ChallengeWebSession {
    sessionId
    challengeId
    language
  }
`;
export const ChallengeChatGptSessionForChallengeTestReportFragmentDoc = gql`
  fragment ChallengeChatGPTSessionForChallengeTestReport on ChallengeChatGPTSession {
    sessionId
    challengeId
    language
  }
`;
export const ChallengeSuspiciousInferenceForChallengeTestReportFragmentDoc = gql`
  fragment ChallengeSuspiciousInferenceForChallengeTestReport on SuspiciousInference {
    inferenceId
    feedback {
      isCorrect
    }
  }
`;
export const InterviewReportChallengeFragmentDoc = gql`
  fragment InterviewReportChallenge on ChallengeEntity {
    id
    entityId
    challengeEntityId
    challengeStatus: status
    totalScore
    submissions {
      ...TestReportChallengeSubmission
    }
    timeLimitSeconds
    appealMessage
    challengeQuestion(withAnswers: true) {
      ...AlgorithmQuestionForChallengeTestReport
    }
    sessions {
      ... on ChallengeWebSession {
        ...ChallengeWebSessionForChallengeTestReport
      }
      ... on ChallengeChatGPTSession {
        ...ChallengeChatGPTSessionForChallengeTestReport
      }
    }
    suspiciousDegree
    suspiciousInference {
      ... on SuspiciousInference {
        ...ChallengeSuspiciousInferenceForChallengeTestReport
      }
    }
  }
  ${TestReportChallengeSubmissionFragmentDoc}
  ${AlgorithmQuestionForChallengeTestReportFragmentDoc}
  ${ChallengeWebSessionForChallengeTestReportFragmentDoc}
  ${ChallengeChatGptSessionForChallengeTestReportFragmentDoc}
  ${ChallengeSuspiciousInferenceForChallengeTestReportFragmentDoc}
`;
export const MultiChoiceOptionForQuizTestReportFragmentDoc = gql`
  fragment MultiChoiceOptionForQuizTestReport on MultiChoiceOption {
    id
    titleJa
    titleEn
    isCorrect
    additionalDetailJa
    additionalDetailEn
  }
`;
export const MultiChoiceQuestionForQuizTestReportFragmentDoc = gql`
  fragment MultiChoiceQuestionForQuizTestReport on MultiChoiceQuestion {
    id
    version
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    correctAnswerRate
    variant
    options {
      ...MultiChoiceOptionForQuizTestReport
    }
    evaluationItems {
      id
      questionId
      questionVersion
      titleJa
      titleEn
      descriptionJa
      descriptionEn
    }
  }
  ${MultiChoiceOptionForQuizTestReportFragmentDoc}
`;
export const MultiChoiceEvaluationItemForQuizTestReportFragmentDoc = gql`
  fragment MultiChoiceEvaluationItemForQuizTestReport on MultiChoiceEvaluationItem {
    id
    questionId
    questionVersion
    titleJa
    titleEn
    descriptionJa
    descriptionEn
  }
`;
export const QuizEvaluationForQuizTestReportFragmentDoc = gql`
  fragment QuizEvaluationForQuizTestReport on QuizEvaluation {
    submissionId
    evaluationItem {
      ...MultiChoiceEvaluationItemForQuizTestReport
    }
    score
    isPassed
    reasonJa
    reasonEn
  }
  ${MultiChoiceEvaluationItemForQuizTestReportFragmentDoc}
`;
export const QuizSubmissionForQuizTestReportFragmentDoc = gql`
  fragment QuizSubmissionForQuizTestReport on QuizSubmission {
    id
    quizSubmissionId
    quizId
    packageId
    packageVersion
    questionId
    questionVersion
    optionIds
    isCorrect
    answerText
    score
    evaluations {
      ...QuizEvaluationForQuizTestReport
    }
    evaluatedBy
    isAutomaticEvaluation
    evaluatedUser {
      uid
      displayName
    }
    quizSubmissionStatus: status
    evaluatedAtSeconds
    numTabEvents
    numAccessEvents
    elapsedTimeSeconds
    createdAtSeconds
  }
  ${QuizEvaluationForQuizTestReportFragmentDoc}
`;
export const QuizEntityForQuizTestReportFragmentDoc = gql`
  fragment QuizEntityForQuizTestReport on QuizEntity {
    id
    entityId
    quizEntityId
    quizStatus: status
    pb_package(withAnswers: true) {
      packageId
      version
      multiChoicePackageStatus: status
      difficulty
      titleJa
      titleEn
      descriptionJa
      descriptionEn
      numUses
      timeLimitSeconds
      accuracyRate
      averageElapsedTimeSeconds
      isOfficial
      isPrivate
      employee {
        id
        displayName
      }
      questions {
        ...MultiChoiceQuestionForQuizTestReport
      }
    }
    submissions {
      ...QuizSubmissionForQuizTestReport
    }
    totalScore
    numSubset
    timeLimitSeconds
    evaluatedAtSeconds
    elapsedTimeSeconds
  }
  ${MultiChoiceQuestionForQuizTestReportFragmentDoc}
  ${QuizSubmissionForQuizTestReportFragmentDoc}
`;
export const ProjectQuestionForProjectTestReportFragmentDoc = gql`
  fragment ProjectQuestionForProjectTestReport on ProjectQuestion {
    id
    questionId
    version
    projectQuestionStatus: status
    projectVersion
    variant
    titleEn
    titleJa
    difficulty
    descriptionJa
    descriptionEn
    correctnessTestCase
    performanceTestCase
    timeLimitSeconds
    isPrivate
    numUses
    accuracyRate
    averageElapsedTimeSeconds
    isOfficial
    answers {
      answerId: id
      titleJa
      titleEn
      descriptionJa
      descriptionEn
    }
  }
`;
export const ProjectDiffForProjectTestReportFragmentDoc = gql`
  fragment ProjectDiffForProjectTestReport on ProjectDiff {
    projectDiffId
    operation
    fromPath
    toPath
    fromLink
    toLink
  }
`;
export const ProjectSubmissionForProjectTestReportFragmentDoc = gql`
  fragment ProjectSubmissionForProjectTestReport on ProjectSubmission {
    projectSubmissionId
    numTests
    numPassed
    numTabEvents
    numAccessEvents
    totalScore
    numSubset
    isBest
    testResult
    avgLatency
    avgSla
    coverage
    performance
    correctnessTestResult
    performanceTestResult
    snapshots {
      testCase
      testResult
      isPassed
    }
    metrics
    submissionStatus: status
    failureReason
    totalElapsedTimeSeconds
    elapsedTimeSeconds
    evaluatedAtSeconds
    diffs {
      ...ProjectDiffForProjectTestReport
    }
  }
  ${ProjectDiffForProjectTestReportFragmentDoc}
`;
export const ProjectEntityForProjectTestReportFragmentDoc = gql`
  fragment ProjectEntityForProjectTestReport on ProjectEntity {
    id
    entityId
    projectEntityId
    projectStatus: status
    totalScore
    evaluatedAtSeconds
    agentVersion
    appealMessage
    question {
      ...ProjectQuestionForProjectTestReport
    }
    submissions {
      ...ProjectSubmissionForProjectTestReport
    }
  }
  ${ProjectQuestionForProjectTestReportFragmentDoc}
  ${ProjectSubmissionForProjectTestReportFragmentDoc}
`;
export const SystemDesignEvaluationForSystemDesignTestReportFragmentDoc = gql`
  fragment SystemDesignEvaluationForSystemDesignTestReport on SystemDesignEvaluation {
    totalScore
    evaluatedBy
    availability
    scalability
    consistency
    result
  }
`;
export const SystemDesignSubmissionForSystemDesignTestReportFragmentDoc = gql`
  fragment SystemDesignSubmissionForSystemDesignTestReport on SystemDesignSubmission {
    systemDesignSubmissionId
    evaluations {
      ...SystemDesignEvaluationForSystemDesignTestReport
    }
    isBest
    status
    snapshot
    usedHints {
      systemDesignHintId
    }
    snapshots {
      systemDesignSnapshotId
      scoringItemId
      isPassed
      matchedIds
    }
    numTabEvents
    numAccessEvents
    totalScore
    numSubset
    totalElapsedTimeSeconds
    elapsedTimeSeconds
    evaluatedAtSeconds
  }
  ${SystemDesignEvaluationForSystemDesignTestReportFragmentDoc}
`;
export const SystemDesignScoringItemForSystemDesignTestReportFragmentDoc = gql`
  fragment SystemDesignScoringItemForSystemDesignTestReport on SystemDesignScoringItem {
    systemDesignScoringItemId
    category
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    graphlets
    isHidden
  }
`;
export const SystemDesignQuestionDetailForSystemDesignTestReportFragmentDoc = gql`
  fragment SystemDesignQuestionDetailForSystemDesignTestReport on SystemDesignQuestion {
    questionId
    titleEn
    titleJa
    difficulty
    descriptionJa
    descriptionEn
    initialSnapshot
    systemDesignQuestionStatus: status
    hints {
      descriptionJa
      descriptionEn
    }
    answers {
      id
      titleJa
      titleEn
      descriptionJa
      descriptionEn
    }
    scoringItems {
      ...SystemDesignScoringItemForSystemDesignTestReport
    }
    numUses
    accuracyRate
    averageElapsedTimeSeconds
    timeLimitSeconds
    isOfficial
  }
  ${SystemDesignScoringItemForSystemDesignTestReportFragmentDoc}
`;
export const SystemDesignEntityForSystemDesignTestReportFragmentDoc = gql`
  fragment SystemDesignEntityForSystemDesignTestReport on SystemDesignEntity {
    id
    entityId
    systemDesignEntityId
    systemDesignStatus: status
    totalScore
    evaluatedAtSeconds
    appealMessage
    submissions {
      ...SystemDesignSubmissionForSystemDesignTestReport
    }
    question {
      ...SystemDesignQuestionDetailForSystemDesignTestReport
    }
  }
  ${SystemDesignSubmissionForSystemDesignTestReportFragmentDoc}
  ${SystemDesignQuestionDetailForSystemDesignTestReportFragmentDoc}
`;
export const InterviewReportFromSharedLinkFragmentDoc = gql`
  fragment InterviewReportFromSharedLink on Spot {
    id
    company {
      companyId
    }
    status
    entities(withAnswers: false) {
      ... on ChallengeEntity {
        ...InterviewReportChallenge
      }
      ... on QuizEntity {
        ...QuizEntityForQuizTestReport
      }
      ... on ProjectEntity {
        ...ProjectEntityForProjectTestReport
      }
      ... on SystemDesignEntity {
        ...SystemDesignEntityForSystemDesignTestReport
      }
    }
    issuedEntities(withAnswers: false) {
      ... on SpotIssuedChallengeEntity {
        entity {
          ...InterviewReportChallenge
        }
        questionWeight
      }
      ... on SpotIssuedQuizEntity {
        entity {
          ...QuizEntityForQuizTestReport
        }
        questionWeight
      }
      ... on SpotIssuedProjectEntity {
        entity {
          ...ProjectEntityForProjectTestReport
        }
        questionWeight
      }
      ... on SpotIssuedSystemDesignEntity {
        entity {
          ...SystemDesignEntityForSystemDesignTestReport
        }
        questionWeight
      }
    }
    entityCount
    totalScore
    customScore
    relativeScore
    didStartAtSeconds
    rank
    numSubset
    rankEvaluation
    isReliableRank
    finalizedAtSeconds
    evaluatedAtSeconds
  }
  ${InterviewReportChallengeFragmentDoc}
  ${QuizEntityForQuizTestReportFragmentDoc}
  ${ProjectEntityForProjectTestReportFragmentDoc}
  ${SystemDesignEntityForSystemDesignTestReportFragmentDoc}
`;
export const AssessmentFeedbackRecommendationFragmentDoc = gql`
  fragment AssessmentFeedbackRecommendation on EvaluationRecommendation {
    id
    employeeId
    evaluationRecommendationId
    evaluations {
      id
      evaluationId
      employeeId
      metricId
      comment
      numStars
      metric {
        id
        title
        metricId
        description
      }
    }
    isRecommended
    comment
    reviewer {
      id
      displayName
      email
      photoUrl
    }
    createdAtSeconds
  }
`;
export const ExamForAssessmentFeedbackFragmentDoc = gql`
  fragment ExamForAssessmentFeedback on Exam {
    id
    recommendations {
      ...AssessmentFeedbackRecommendation
    }
  }
  ${AssessmentFeedbackRecommendationFragmentDoc}
`;
export const ListAssessmentsByCompanyIdForAssessmentsHistorySelectorFragmentDoc = gql`
  fragment ListAssessmentsByCompanyIdForAssessmentsHistorySelector on ListAssessmentsByCompanyId {
    assessments {
      id
      name
    }
    count
    hasNext
  }
`;
export const ListMultiChoiceQuestionForQuestionSelectFieldForResourceEditorFragmentDoc = gql`
  fragment ListMultiChoiceQuestionForQuestionSelectFieldForResourceEditor on MultiChoicePackage {
    __typename
    packageId
    version
    key
    titleJa
    titleEn
    multiChoicePackageStatus: status
    difficulty
    isPrivate
    isOfficial
    leakScore {
      level
    }
    isSupportedLeakScore
    timeLimitSeconds
    numUses
    accuracyRate
    averageElapsedTimeSeconds
  }
`;
export const ListProjectQuestionForQuestionSelectFieldForResourceEditorFragmentDoc = gql`
  fragment ListProjectQuestionForQuestionSelectFieldForResourceEditor on ProjectQuestion {
    __typename
    questionId
    version
    projectQuestionVariant: variant
    projectVersion
    key
    companyId
    titleJa
    titleEn
    difficulty
    isPrivate
    isOfficial
    leakScore {
      level
    }
    isSupportedLeakScore
    timeLimitSeconds
    numUses
    accuracyRate
    averageElapsedTimeSeconds
    projectQuestionStatus: status
  }
`;
export const AssessmentEntitySourceQuestionForAssessmentHistorySelectorFragmentDoc = gql`
  fragment AssessmentEntitySourceQuestionForAssessmentHistorySelector on AssessmentEntitySourceQuestion {
    ... on AssessmentChallengeEntitySourceQuestion {
      __typename
      enabledLanguages
      question {
        ...ListAlgorithmQuestionForQuestionSelectFieldForResourceEditor
      }
    }
    ... on AssessmentQuizEntitySourcePackage {
      __typename
      pb_package {
        ...ListMultiChoiceQuestionForQuestionSelectFieldForResourceEditor
      }
    }
    ... on AssessmentProjectEntitySourceQuestion {
      __typename
      question {
        ...ListProjectQuestionForQuestionSelectFieldForResourceEditor
      }
    }
    ... on AssessmentSystemDesignEntitySourceQuestion {
      __typename
      componentTypes
      question {
        ...ListSystemDesignQuestionForQuestionSelectFieldForResourceEditor
      }
    }
  }
  ${ListAlgorithmQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
  ${ListMultiChoiceQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
  ${ListProjectQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
  ${ListSystemDesignQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
`;
export const AssessmentOverviewFragmentDoc = gql`
  fragment AssessmentOverview on Assessment {
    id
    viewers {
      __typename
      ... on User {
        ...AssessmentDetailUser
      }
      ... on EmployeeGroup {
        id
        groupName
        companyEmployees {
          employeeId
          role
        }
      }
    }
    employee {
      displayName
    }
    messageForTalent
    startScheduleAtSeconds
    examInterval
    createdAtSeconds
    deletedAtSeconds
    isPublic
    memo
    name
    timeLimitSeconds
  }
  ${AssessmentDetailUserFragmentDoc}
`;
export const AssessmentOverviewChallengeQuestionFragmentDoc = gql`
  fragment AssessmentOverviewChallengeQuestion on AlgorithmQuestion {
    __typename
    id
    questionId
    version
    titleJa
    titleEn
    difficulty
    algorithmQuestionStatus: status
  }
`;
export const AssessmentOverviewQuizPackageFragmentDoc = gql`
  fragment AssessmentOverviewQuizPackage on MultiChoicePackage {
    __typename
    id
    packageId
    version
    titleJa
    titleEn
    difficulty
    multiChoicePackageStatus: status
  }
`;
export const AssessmentOverviewSystemDesignQuestionFragmentDoc = gql`
  fragment AssessmentOverviewSystemDesignQuestion on SystemDesignQuestion {
    __typename
    id
    questionId
    titleJa
    titleEn
    difficulty
    systemDesignQuestionStatus: status
  }
`;
export const AssessmentOverviewProjectQuestionFragmentDoc = gql`
  fragment AssessmentOverviewProjectQuestion on ProjectQuestion {
    __typename
    id
    questionId
    version
    projectVersion
    titleJa
    titleEn
    difficulty
    projectQuestionStatus: status
  }
`;
export const ExamForAssessmentOverviewFragmentDoc = gql`
  fragment ExamForAssessmentOverview on Exam {
    id
    examId
    status
    timeLimitSeconds
    expiredAtSeconds
    createdAtSeconds
    willEndAtSeconds
    didEndAtSeconds
    messageForTalent
    entities(withAnswers: false) {
      ... on ChallengeEntity {
        enabledLanguages
        challengeQuestion(withAnswers: false) {
          ...AssessmentOverviewChallengeQuestion
        }
      }
      ... on QuizEntity {
        pb_package(withAnswers: true) {
          ...AssessmentOverviewQuizPackage
        }
      }
      ... on SystemDesignEntity {
        componentTypes
        question {
          ...AssessmentOverviewSystemDesignQuestion
        }
      }
      ... on ProjectEntity {
        question {
          ...AssessmentOverviewProjectQuestion
        }
      }
    }
  }
  ${AssessmentOverviewChallengeQuestionFragmentDoc}
  ${AssessmentOverviewQuizPackageFragmentDoc}
  ${AssessmentOverviewSystemDesignQuestionFragmentDoc}
  ${AssessmentOverviewProjectQuestionFragmentDoc}
`;
export const ExamReportFragmentDoc = gql`
  fragment ExamReport on Exam {
    id
    status
    entities(withAnswers: true) {
      ... on ChallengeEntity {
        ...InterviewReportChallenge
      }
      ... on QuizEntity {
        ...QuizEntityForQuizTestReport
      }
      ... on ProjectEntity {
        ...ProjectEntityForProjectTestReport
      }
      ... on SystemDesignEntity {
        ...SystemDesignEntityForSystemDesignTestReport
      }
    }
    totalScore
    rank
    rankEvaluation
    isReliableRank
    relativeScore
    numSubset
    didStartAtSeconds
    finalizedAtSeconds
  }
  ${InterviewReportChallengeFragmentDoc}
  ${QuizEntityForQuizTestReportFragmentDoc}
  ${ProjectEntityForProjectTestReportFragmentDoc}
  ${SystemDesignEntityForSystemDesignTestReportFragmentDoc}
`;
export const AssessmentEntitySourceForAssessmentResourceEditorFragmentDoc = gql`
  fragment AssessmentEntitySourceForAssessmentResourceEditor on AssessmentEntitySource {
    questionId
    entityType
    challengeQuestion {
      questionId
      questionVersion
      enabledLanguages
    }
    projectQuestion {
      questionId
      questionVersion
    }
    quizPackage {
      packageId
      packageVersion
    }
    systemDesignQuestion {
      questionId
      componentTypes
    }
  }
`;
export const AssessmentForAssessmentResourceEditorFragmentDoc = gql`
  fragment AssessmentForAssessmentResourceEditor on Assessment {
    assessmentId
    name
    status
    talent {
      displayName
      uid
    }
    employee {
      displayName
      uid
    }
    memo
    messageForTalent
    entitySources {
      ...AssessmentEntitySourceForAssessmentResourceEditor
    }
    questions(withAnswers: false) {
      ... on AlgorithmQuestion {
        ...ListAlgorithmQuestionForQuestionSelectFieldForResourceEditor
      }
      ... on MultiChoicePackage {
        ...ListMultiChoiceQuestionForQuestionSelectFieldForResourceEditor
      }
      ... on ProjectQuestion {
        ...ListProjectQuestionForQuestionSelectFieldForResourceEditor
      }
      ... on SystemDesignQuestion {
        ...ListSystemDesignQuestionForQuestionSelectFieldForResourceEditor
      }
    }
    timeLimitSeconds
    startScheduleAtSeconds
    nextScheduleAtSeconds
    remindBeforeSeconds
    isPublic
    examInterval
    reportSettings {
      showAnswer
      showPlayback
      showRelativeEvaluation
    }
    viewers {
      __typename
      ... on User {
        uid
        displayName
        email
      }
      ... on EmployeeGroup {
        employeeGroupId
        groupName
        companyEmployees {
          employeeId
        }
      }
    }
  }
  ${AssessmentEntitySourceForAssessmentResourceEditorFragmentDoc}
  ${ListAlgorithmQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
  ${ListMultiChoiceQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
  ${ListProjectQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
  ${ListSystemDesignQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
`;
export const PaymentPlanForBillingOverviewFragmentDoc = gql`
  fragment PaymentPlanForBillingOverview on PaymentV2Plan {
    planId
    nameJa
    nameEn
    descriptionJa
    descriptionEn
    price
    generation
    planType
    enabledChangePlan
    enabledPurchasePack
  }
`;
export const PaymentSubscriptionForBillingOverviewFragmentDoc = gql`
  fragment PaymentSubscriptionForBillingOverview on PaymentV2Subscription {
    subscriptionId
    status
    packStock
    periodType
    didStartAtSeconds
    willEndAtSeconds
    nextPaymentAtSeconds
    plan {
      ...PaymentPlanForBillingOverview
    }
  }
  ${PaymentPlanForBillingOverviewFragmentDoc}
`;
export const CustomerForBillingOverviewFragmentDoc = gql`
  fragment CustomerForBillingOverview on PaymentV2Customer {
    customerId
    paymentMethod
    paymentType
    paymentStatus
    isTrial
    isAutoRenewal
    email
    country
    postalCode
    state
    city
    line1
    line2
    lastFour
    bankName
    bankCode
    branchName
    branchCode
    accountHolderName
    accountNumber
    accountType
    language
    subscription {
      ...PaymentSubscriptionForBillingOverview
    }
  }
  ${PaymentSubscriptionForBillingOverviewFragmentDoc}
`;
export const ContractPeriodUsageForBillingOverviewFragmentDoc = gql`
  fragment ContractPeriodUsageForBillingOverview on ContractPeriodUsage {
    yearMonth
    totalCount
    paidCount
    freeCount
  }
`;
export const CustomerForBuySelectionsFragmentDoc = gql`
  fragment CustomerForBuySelections on PaymentV2Customer {
    customerId
    paymentType
    paymentMethod
    paymentStatus
    isTrial
    subscription {
      subscriptionId
      status
      customerId
      plan {
        generation
        planType
        enabledPurchasePack
      }
      shotInterview {
        price
      }
      subscribedCoupons {
        isActive
        createdAtSeconds
        expiresAtSeconds
        coupon {
          couponId
          nameJa
          nameEn
          couponType
          couponDurationType
          durationMonth
          discountNumber
        }
      }
    }
  }
`;
export const PaymentInterviewForBuySelectionsFragmentDoc = gql`
  fragment PaymentInterviewForBuySelections on PaymentInterview {
    interviewId
    nameJa
    nameEn
    descriptionJa
    descriptionEn
    price
    quantity
    generation
    interviewKind
  }
`;
export const CouponForBuySelectionsFragmentDoc = gql`
  fragment CouponForBuySelections on Coupon {
    couponId
    nameJa
    nameEn
    couponType
    couponDurationType
    durationMonth
    discountNumber
    createdAtSeconds
    updatedAtSeconds
    deletedAtSeconds
  }
`;
export const ChallengeSnapshotFragmentDoc = gql`
  fragment ChallengeSnapshot on ChallengeSnapshot {
    id
    challengeId
    challengeSnapshotId
  }
`;
export const ChallengeSubmissionFragmentDoc = gql`
  fragment ChallengeSubmission on ChallengeSubmission {
    id
    avgMaxMemory
    avgPerformance
    challengeId
    challengeSubmissionId
    code
    companyId
    correctnessTestResult
    coverage
    isBest
    snapshots {
      ...ChallengeSnapshot
    }
  }
  ${ChallengeSnapshotFragmentDoc}
`;
export const ChallengeRunCodeResponseFragmentDoc = gql`
  fragment ChallengeRunCodeResponse on ChallengeRunCodeResponse {
    output
    log
    status
    expected
    isAccepted
    performance
    maxMemory
    snapshotId
  }
`;
export const ChallengeWebSessionForChallengeCodingEditorFragmentDoc = gql`
  fragment ChallengeWebSessionForChallengeCodingEditor on ChallengeWebSession {
    sessionId
    challengeId
    language
    latestWebSearchedLog {
      searchEventId
      query
      page
    }
  }
`;
export const ChallengeChatGptSessionForChallengeCodingEditorFragmentDoc = gql`
  fragment ChallengeChatGPTSessionForChallengeCodingEditor on ChallengeChatGPTSession {
    sessionId
    challengeId
    language
    currentChatId
    currentChatGPTMessages {
      role
      content
    }
  }
`;
export const WebSearchEventForChallengeCodingEditorFragmentDoc = gql`
  fragment WebSearchEventForChallengeCodingEditor on WebSearchedEvent {
    eventId
    query
    page
    sessionId
    searchedAtSeconds
  }
`;
export const ChallengeStatisticsStatForRankVisualizerFragmentDoc = gql`
  fragment ChallengeStatisticsStatForRankVisualizer on StatisticsStats {
    avg
    max
    min
    p25
    p50
    p75
    p95
    std
  }
`;
export const ChallengeStatisticsForRankVisualizerFragmentDoc = gql`
  fragment ChallengeStatisticsForRankVisualizer on ChallengeStatistics {
    __typename
    avgMaxMemory {
      ...ChallengeStatisticsStatForRankVisualizer
    }
    avgPerformance {
      ...ChallengeStatisticsStatForRankVisualizer
    }
    coverage {
      ...ChallengeStatisticsStatForRankVisualizer
    }
    elapsedTime {
      ...ChallengeStatisticsStatForRankVisualizer
    }
    numHints {
      ...ChallengeStatisticsStatForRankVisualizer
    }
    numTabEvents {
      ...ChallengeStatisticsStatForRankVisualizer
    }
    numPasteEvents {
      ...ChallengeStatisticsStatForRankVisualizer
    }
    performance {
      ...ChallengeStatisticsStatForRankVisualizer
    }
    readabilityScore {
      ...ChallengeStatisticsStatForRankVisualizer
    }
    totalScore {
      ...ChallengeStatisticsStatForRankVisualizer
    }
    numSnapshots {
      ...ChallengeStatisticsStatForRankVisualizer
    }
    bins
  }
  ${ChallengeStatisticsStatForRankVisualizerFragmentDoc}
`;
export const EmployeeForEmployeeAssignFieldFragmentDoc = gql`
  fragment EmployeeForEmployeeAssignField on User {
    uid
    displayName
    fullName
    email
    photoUrl
  }
`;
export const CompanyForEmployeeAssignFieldFragmentDoc = gql`
  fragment CompanyForEmployeeAssignField on Company {
    companyId
    name
    employees {
      ...EmployeeForEmployeeAssignField
    }
  }
  ${EmployeeForEmployeeAssignFieldFragmentDoc}
`;
export const GetCompanyForEmployeeGroupChipsEmployeeFragmentDoc = gql`
  fragment GetCompanyForEmployeeGroupChipsEmployee on User {
    id
    displayName
    fullName
    email
    photoUrl
  }
`;
export const GetCompanyForEmployeeGroupChipsGroupFragmentDoc = gql`
  fragment GetCompanyForEmployeeGroupChipsGroup on EmployeeGroup {
    id
    groupName
    companyEmployees {
      employeeId
    }
    createdAtSeconds
  }
`;
export const GetCompanyForEmployeeGroupChipsCompanyFragmentDoc = gql`
  fragment GetCompanyForEmployeeGroupChipsCompany on Company {
    companyId
    name
    employees {
      ...GetCompanyForEmployeeGroupChipsEmployee
    }
    groups {
      ...GetCompanyForEmployeeGroupChipsGroup
    }
  }
  ${GetCompanyForEmployeeGroupChipsEmployeeFragmentDoc}
  ${GetCompanyForEmployeeGroupChipsGroupFragmentDoc}
`;
export const EmployeeNotificationForEmployeeNavigationFragmentDoc = gql`
  fragment EmployeeNotificationForEmployeeNavigation on EmployeeNotification {
    id
    notificationId
    employeeId
    isRead
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    createdAtSeconds
    companyId
    actionLink
  }
`;
export const EmployeeNotificationsResponseForEmployeeNavigationFragmentDoc = gql`
  fragment EmployeeNotificationsResponseForEmployeeNavigation on EmployeeNotificationsResponse {
    employeeNotifications {
      ...EmployeeNotificationForEmployeeNavigation
    }
    count
    pager {
      hasNext
      cursor
    }
  }
  ${EmployeeNotificationForEmployeeNavigationFragmentDoc}
`;
export const EvaluationMetricGroupsForEvaluationMetricGroupSearchFieldFragmentDoc = gql`
  fragment EvaluationMetricGroupsForEvaluationMetricGroupSearchField on EvaluationMetricGroup {
    id
    metricGroupId
    companyId
    title
    description
    createdBy
  }
`;
export const EvaluationMetricGroupsByCompanyResponseForEvaluationMetricGroupSearchFieldFragmentDoc = gql`
  fragment EvaluationMetricGroupsByCompanyResponseForEvaluationMetricGroupSearchField on EvaluationMetricGroupsByCompanyResponse {
    metricGroups {
      ...EvaluationMetricGroupsForEvaluationMetricGroupSearchField
    }
    pager {
      cursor
      hasNext
    }
    count
  }
  ${EvaluationMetricGroupsForEvaluationMetricGroupSearchFieldFragmentDoc}
`;
export const EvaluationMetricForEvaluationMetricSearchFieldFragmentDoc = gql`
  fragment EvaluationMetricForEvaluationMetricSearchField on EvaluationMetric {
    id
    metricId
    companyId
    createdBy
    title
    description
    createdAtSeconds
  }
`;
export const EvaluationMetricsByCompanyResponseForEvaluationMetricSearchFieldFragmentDoc = gql`
  fragment EvaluationMetricsByCompanyResponseForEvaluationMetricSearchField on EvaluationMetricsByCompanyResponse {
    metrics {
      ...EvaluationMetricForEvaluationMetricSearchField
    }
    pager {
      cursor
      hasNext
    }
    count
  }
  ${EvaluationMetricForEvaluationMetricSearchFieldFragmentDoc}
`;
export const EvaluationMetricForEvaluationMetricsFragmentDoc = gql`
  fragment EvaluationMetricForEvaluationMetrics on EvaluationMetric {
    id
    metricId
    companyId
    createdBy
    title
    description
    createdAtSeconds
    updatedAtSeconds
    employee {
      id
      uid
      displayName
      email
      fullName
      photoUrl
      emailVerified
      language
      provider
    }
  }
`;
export const EvaluationMetricsByCompanyResponseForEvaluationMetricsFragmentDoc = gql`
  fragment EvaluationMetricsByCompanyResponseForEvaluationMetrics on EvaluationMetricsByCompanyResponse {
    metrics {
      ...EvaluationMetricForEvaluationMetrics
    }
    pager {
      cursor
      hasNext
    }
    count
  }
  ${EvaluationMetricForEvaluationMetricsFragmentDoc}
`;
export const EvaluationMetricGroupForEvaluationMetricsGroupsFragmentDoc = gql`
  fragment EvaluationMetricGroupForEvaluationMetricsGroups on EvaluationMetricGroup {
    id
    metricGroupId
    companyId
    title
    description
    createdAtSeconds
    updatedAtSeconds
    metrics {
      ...EvaluationMetricWithEmployeeResponse
    }
    createdBy
    employee {
      id
      uid
      displayName
      email
      fullName
      photoUrl
      emailVerified
      language
      provider
    }
  }
  ${EvaluationMetricWithEmployeeResponseFragmentDoc}
`;
export const EvaluationMetricGroupsByCompanyResponseForEvaluationMetricsGroupsFragmentDoc = gql`
  fragment EvaluationMetricGroupsByCompanyResponseForEvaluationMetricsGroups on EvaluationMetricGroupsByCompanyResponse {
    metricGroups {
      ...EvaluationMetricGroupForEvaluationMetricsGroups
    }
    pager {
      cursor
      hasNext
    }
    count
  }
  ${EvaluationMetricGroupForEvaluationMetricsGroupsFragmentDoc}
`;
export const ExamStatisticsForExamRankVisualizerFragmentDoc = gql`
  fragment ExamStatisticsForExamRankVisualizer on ExamStatistics {
    __typename
    bins
    relativeScore {
      avg
      max
      min
      p25
      p50
      p75
      p95
      std
    }
  }
`;
export const RankForExamRankVisualizerFragmentDoc = gql`
  fragment RankForExamRankVisualizer on StatisticsRank {
    evaluation
    numSubset
    rank
    relativeScore
  }
`;
export const ExamForExamTransitionVisualizerFragmentDoc = gql`
  fragment ExamForExamTransitionVisualizer on Exam {
    id
    examId
    assessmentId
    __typename
    originId
    numInterval
    numTry
    relativeScore
    totalScore
    startIntervalAtSeconds
    endIntervalAtSeconds
    createdAtSeconds
    willEndAtSeconds
    status
  }
`;
export const ListBestExamsResponseForExamTransitionVisualizerFragmentDoc = gql`
  fragment ListBestExamsResponseForExamTransitionVisualizer on ListBestExamsByAssessmentId {
    exams {
      ...ExamForExamTransitionVisualizer
    }
    count
    hasNext
  }
  ${ExamForExamTransitionVisualizerFragmentDoc}
`;
export const GetCompanyForGeneralSettingsCompanyFragmentDoc = gql`
  fragment GetCompanyForGeneralSettingsCompany on Company {
    companyId
    name
    websiteUrl
  }
`;
export const UpdateCompanyForGeneralSettingsCompanyFragmentDoc = gql`
  fragment UpdateCompanyForGeneralSettingsCompany on Company {
    companyId
    name
    websiteUrl
  }
`;
export const GetCompanyForGroupSettingsEmployeeFragmentDoc = gql`
  fragment GetCompanyForGroupSettingsEmployee on User {
    id
    displayName
    fullName
    email
    photoUrl
  }
`;
export const GetCompanyForGroupSettingsGroupFragmentDoc = gql`
  fragment GetCompanyForGroupSettingsGroup on EmployeeGroup {
    id
    groupName
    companyEmployees {
      employeeId
    }
    createdAtSeconds
  }
`;
export const GetCompanyForGroupSettingsCompanyFragmentDoc = gql`
  fragment GetCompanyForGroupSettingsCompany on Company {
    companyId
    name
    employees {
      ...GetCompanyForGroupSettingsEmployee
    }
    groups {
      ...GetCompanyForGroupSettingsGroup
    }
  }
  ${GetCompanyForGroupSettingsEmployeeFragmentDoc}
  ${GetCompanyForGroupSettingsGroupFragmentDoc}
`;
export const ScreeningForIntegrationSettingsFragmentDoc = gql`
  fragment ScreeningForIntegrationSettings on Screening {
    id
    screeningId
    name
    description
    candidateAccessPolicy
    isActive
  }
`;
export const ListSonarIntegrationsSonarFragmentDoc = gql`
  fragment ListSonarIntegrationsSonar on SonarIntegration {
    id
    companyId
    createdAtSeconds
    screeningId
    screening {
      ...ScreeningForIntegrationSettings
    }
  }
  ${ScreeningForIntegrationSettingsFragmentDoc}
`;
export const SonarIntegrationsResponseFragmentDoc = gql`
  fragment SonarIntegrationsResponse on SonarIntegrationsResponse {
    sonarIntegrations {
      ...ListSonarIntegrationsSonar
    }
    pager {
      cursor
      hasNext
    }
    count
  }
  ${ListSonarIntegrationsSonarFragmentDoc}
`;
export const ScreeningsByCompanyIdResponseForIntegrationSettingsFragmentDoc = gql`
  fragment ScreeningsByCompanyIdResponseForIntegrationSettings on ScreeningsByCompanyIdResponse {
    screenings {
      ...ScreeningForIntegrationSettings
    }
    count
    hasNext
  }
  ${ScreeningForIntegrationSettingsFragmentDoc}
`;
export const InterviewFeedbackRecommendationFragmentDoc = gql`
  fragment InterviewFeedbackRecommendation on EvaluationRecommendation {
    id
    employeeId
    evaluationRecommendationId
    evaluations {
      id
      evaluationId
      employeeId
      metricId
      comment
      numStars
      metric {
        id
        title
        metricId
        description
      }
    }
    isRecommended
    comment
    reviewer {
      id
      displayName
      email
      photoUrl
    }
    createdAtSeconds
  }
`;
export const InterviewFeedbackFragmentDoc = gql`
  fragment InterviewFeedback on Spot {
    id
    recommendations {
      ...InterviewFeedbackRecommendation
    }
  }
  ${InterviewFeedbackRecommendationFragmentDoc}
`;
export const InterviewOverviewFragmentDoc = gql`
  fragment InterviewOverview on Spot {
    id
    originId
    parentId
    candidateName
    candidateEmail
    filledVariables {
      filledVariableId
      label
      description
      valueType
      value {
        stringifiedValue
      }
    }
    entities(withAnswers: false) {
      ... on ChallengeEntity {
        ...InterviewOverviewChallenge
      }
      ... on QuizEntity {
        ...InterviewOverviewQuiz
      }
      ... on ProjectEntity {
        ...InterviewOverviewProject
      }
      ... on SystemDesignEntity {
        ...InterviewOverviewSystemDesign
      }
    }
    issuedEntities(withAnswers: false) {
      ... on SpotIssuedChallengeEntity {
        entity {
          ...InterviewOverviewChallenge
        }
        questionWeight
      }
      ... on SpotIssuedQuizEntity {
        entity {
          ...InterviewOverviewQuiz
        }
        questionWeight
      }
      ... on SpotIssuedProjectEntity {
        entity {
          ...InterviewOverviewProject
        }
        questionWeight
      }
      ... on SpotIssuedSystemDesignEntity {
        entity {
          ...InterviewOverviewSystemDesign
        }
        questionWeight
      }
    }
    reviewers {
      __typename
      employee {
        ...InterviewDetailUser
      }
      type
      group {
        id
        groupName
        companyEmployees {
          employeeId
          role
        }
      }
    }
    viewers {
      __typename
      ... on User {
        uid
        displayName
      }
      ... on EmployeeGroup {
        employeeGroupId
        groupName
      }
    }
    tags {
      __typename
      name
    }
    employee {
      ...InterviewDetailUser
    }
    evaluatedBy {
      id
      displayName
      email
      photoUrl
    }
    ownerEmailAddress
    messageForCandidate
    evaluationComment
    isPassed
    createdAtSeconds
    declinedAtSeconds
    deletedAtSeconds
    didEndAtSeconds
    didStartAtSeconds
    evaluatedAtSeconds
    finalizedAtSeconds
    isPublic
    memo
    name
    status
    timeLimitSeconds
    willExpireAtSeconds
    invitationEmail
    invitationLanguage
    invitationMethod
    allowWebSearch
    allowChatGPT
    allowHint
  }
  ${InterviewOverviewChallengeFragmentDoc}
  ${InterviewOverviewQuizFragmentDoc}
  ${InterviewOverviewProjectFragmentDoc}
  ${InterviewOverviewSystemDesignFragmentDoc}
  ${InterviewDetailUserFragmentDoc}
`;
export const InterviewReportFragmentDoc = gql`
  fragment InterviewReport on Spot {
    id
    status
    entities(withAnswers: true) {
      ... on ChallengeEntity {
        ...InterviewReportChallenge
      }
      ... on QuizEntity {
        ...QuizEntityForQuizTestReport
      }
      ... on ProjectEntity {
        ...ProjectEntityForProjectTestReport
      }
      ... on SystemDesignEntity {
        ...SystemDesignEntityForSystemDesignTestReport
      }
    }
    issuedEntities(withAnswers: true) {
      ... on SpotIssuedChallengeEntity {
        entity {
          ...InterviewOverviewChallenge
        }
        questionWeight
      }
      ... on SpotIssuedQuizEntity {
        entity {
          ...InterviewOverviewQuiz
        }
        questionWeight
      }
      ... on SpotIssuedProjectEntity {
        entity {
          ...InterviewOverviewProject
        }
        questionWeight
      }
      ... on SpotIssuedSystemDesignEntity {
        entity {
          ...InterviewOverviewSystemDesign
        }
        questionWeight
      }
    }
    entityCount
    totalScore
    customScore
    relativeScore
    rank
    numSubset
    rankEvaluation
    isReliableRank
    didStartAtSeconds
    finalizedAtSeconds
    evaluatedAtSeconds
    allowHint
    suspiciousDegree
  }
  ${InterviewReportChallengeFragmentDoc}
  ${QuizEntityForQuizTestReportFragmentDoc}
  ${ProjectEntityForProjectTestReportFragmentDoc}
  ${SystemDesignEntityForSystemDesignTestReportFragmentDoc}
  ${InterviewOverviewChallengeFragmentDoc}
  ${InterviewOverviewQuizFragmentDoc}
  ${InterviewOverviewProjectFragmentDoc}
  ${InterviewOverviewSystemDesignFragmentDoc}
`;
export const GetCompanyForMemberSettingsEmployeeFragmentDoc = gql`
  fragment GetCompanyForMemberSettingsEmployee on User {
    id
    displayName
    fullName
    email
    photoUrl
  }
`;
export const GetCompanyForMemberSettingsForCompanyEmployeeFragmentDoc = gql`
  fragment GetCompanyForMemberSettingsForCompanyEmployee on CompanyEmployee {
    employeeId
    role
    lastSignedInAtSeconds
  }
`;
export const GetCompanyForMemberSettingsGroupFragmentDoc = gql`
  fragment GetCompanyForMemberSettingsGroup on EmployeeGroup {
    id
    groupName
    companyEmployees {
      employeeId
    }
    createdAtSeconds
  }
`;
export const GetCompanyForMemberSettingsCompanyFragmentDoc = gql`
  fragment GetCompanyForMemberSettingsCompany on Company {
    companyId
    name
    employees {
      ...GetCompanyForMemberSettingsEmployee
    }
    companyEmployees {
      ...GetCompanyForMemberSettingsForCompanyEmployee
    }
    groups {
      ...GetCompanyForMemberSettingsGroup
    }
  }
  ${GetCompanyForMemberSettingsEmployeeFragmentDoc}
  ${GetCompanyForMemberSettingsForCompanyEmployeeFragmentDoc}
  ${GetCompanyForMemberSettingsGroupFragmentDoc}
`;
export const UpdateSlackSettingsForIncomingWebHookFragmentDoc = gql`
  fragment UpdateSlackSettingsForIncomingWebHook on SlackIncomingWebhook {
    webhookUrl
    channel
    channelId
    configurationUrl
  }
`;
export const UpdateSlackSettingsForSpotSettingsFragmentDoc = gql`
  fragment UpdateSlackSettingsForSpotSettings on SpotSettingsSubscribeSlack {
    subscribeSpotCompleted
    subscribeSpotCreated
    subscribeSpotDeleted
    subscribeSpotEvaluated
    subscribeSpotRecreated
    subscribeSpotReviewed
    subscribeSpotFinalized
    subscribeSpotStarted
    subscribeSpotExpired
  }
`;
export const UpdateSlackSettingsForRemoteSettingsFragmentDoc = gql`
  fragment UpdateSlackSettingsForRemoteSettings on RemoteSettingsSubscribeSlack {
    subscribeRemoteCreated
    subscribeRemoteAwaiting
    subscribeRemoteStarted
    subscribeRemoteCompleted
    subscribeRemoteDeleted
    subscribeRemoteReviewed
    subscribeRemoteEvaluated
  }
`;
export const GetSlackSettingsForIncomingWebHookFragmentDoc = gql`
  fragment GetSlackSettingsForIncomingWebHook on SlackIncomingWebhook {
    webhookUrl
    channel
    channelId
    configurationUrl
  }
`;
export const GetSlackSettingsForSpotSettingsFragmentDoc = gql`
  fragment GetSlackSettingsForSpotSettings on SpotSettingsSubscribeSlack {
    subscribeSpotCompleted
    subscribeSpotCreated
    subscribeSpotDeleted
    subscribeSpotEvaluated
    subscribeSpotRecreated
    subscribeSpotReviewed
    subscribeSpotFinalized
    subscribeSpotStarted
    subscribeSpotExpired
  }
`;
export const GetSlackSettingsForRemoteSettingsFragmentDoc = gql`
  fragment GetSlackSettingsForRemoteSettings on RemoteSettingsSubscribeSlack {
    subscribeRemoteCreated
    subscribeRemoteAwaiting
    subscribeRemoteStarted
    subscribeRemoteCompleted
    subscribeRemoteDeleted
    subscribeRemoteReviewed
    subscribeRemoteEvaluated
  }
`;
export const PaymentPurchaseForOrderHistoryFragmentDoc = gql`
  fragment PaymentPurchaseForOrderHistory on PaymentPurchase {
    purchaseId
    customerId
    plan {
      nameJa
      nameEn
      planType
      price
    }
    interview {
      interviewId
      nameJa
      nameEn
      price
      quantity
      generation
      interviewKind
    }
    seat {
      seatId
      nameJa
      nameEn
      price
      quantity
      generation
    }
    unitPrice
    totalPrice
    quantity
    createdAtSeconds
  }
`;
export const PurchasesResponseForOrderHistoryFragmentDoc = gql`
  fragment PurchasesResponseForOrderHistory on PurchasesResponse {
    purchases {
      ...PaymentPurchaseForOrderHistory
    }
    count
    pager {
      cursorSeconds
      cursorNanoSeconds
      hasNext
    }
  }
  ${PaymentPurchaseForOrderHistoryFragmentDoc}
`;
export const CompanyForOrderHistoryFragmentDoc = gql`
  fragment CompanyForOrderHistory on Company {
    createdAtSeconds
  }
`;
export const PaymentV2PlanForPaymentContractCreateFormFragmentDoc = gql`
  fragment PaymentV2PlanForPaymentContractCreateForm on PaymentV2Plan {
    id
    planId
    nameJa
    nameEn
    descriptionJa
    descriptionEn
    price
    generation
    planType
    term
    enabledChangePlan
    enabledPurchasePack
  }
`;
export const PlansResponseForPaymentContractCreateFormFragmentDoc = gql`
  fragment PlansResponseForPaymentContractCreateForm on PlansResponse {
    plans {
      ...PaymentV2PlanForPaymentContractCreateForm
    }
  }
  ${PaymentV2PlanForPaymentContractCreateFormFragmentDoc}
`;
export const CustomerForPaymentContractCreateFormFragmentDoc = gql`
  fragment CustomerForPaymentContractCreateForm on PaymentV2Customer {
    customerId
    paymentMethod
    paymentType
    paymentStatus
    isTrial
    isAutoRenewal
    language
    email
    country
    postalCode
    state
    city
    line1
    line2
    lastFour
    bankName
    bankCode
    branchName
    branchCode
    accountHolderName
    accountNumber
    accountType
    subscription {
      subscriptionId
      customerId
      status
      plan {
        generation
        planType
      }
      shotInterview {
        price
      }
      subscribedCoupons {
        isActive
        createdAtSeconds
        expiresAtSeconds
        coupon {
          couponId
          nameJa
          nameEn
          couponType
          couponDurationType
          durationMonth
          discountNumber
        }
      }
    }
  }
`;
export const PaymentInterviewForPaymentContractCreateFormFragmentDoc = gql`
  fragment PaymentInterviewForPaymentContractCreateForm on PaymentInterview {
    interviewId
    nameJa
    nameEn
    descriptionJa
    descriptionEn
    price
    quantity
    generation
    interviewKind
  }
`;
export const PaymentInitialCostForPaymentContractCreateFormFragmentDoc = gql`
  fragment PaymentInitialCostForPaymentContractCreateForm on PaymentInterview {
    nameJa
    nameEn
    descriptionJa
    descriptionEn
    interviewKind
    price
    quantity
  }
`;
export const CouponForPaymentContractCreateFormFragmentDoc = gql`
  fragment CouponForPaymentContractCreateForm on Coupon {
    couponId
    nameJa
    nameEn
    couponType
    couponDurationType
    durationMonth
    discountNumber
    createdAtSeconds
    updatedAtSeconds
    deletedAtSeconds
  }
`;
export const CustomerForPaymentMethodUpdateFormFragmentDoc = gql`
  fragment CustomerForPaymentMethodUpdateForm on PaymentV2Customer {
    customerId
    paymentMethod
    paymentType
    paymentStatus
    email
    country
    postalCode
    state
    city
    line1
    line2
    lastFour
    bankName
    bankCode
    branchName
    branchCode
    accountHolderName
    accountNumber
    accountType
    language
  }
`;
export const PaymentV2PlanForPaymentPlanListFragmentDoc = gql`
  fragment PaymentV2PlanForPaymentPlanList on PaymentV2Plan {
    planType
  }
`;
export const PlansResponseForPaymentPlanListFragmentDoc = gql`
  fragment PlansResponseForPaymentPlanList on PlansResponse {
    plans {
      ...PaymentV2PlanForPaymentPlanList
    }
  }
  ${PaymentV2PlanForPaymentPlanListFragmentDoc}
`;
export const CustomerForPaymentPlanListFragmentDoc = gql`
  fragment CustomerForPaymentPlanList on PaymentV2Customer {
    isTrial
    language
    subscription {
      plan {
        generation
        planType
      }
    }
  }
`;
export const PaymentV2PlanForPaymentPlanUpdateFormFragmentDoc = gql`
  fragment PaymentV2PlanForPaymentPlanUpdateForm on PaymentV2Plan {
    id
    planId
    nameJa
    nameEn
    descriptionJa
    descriptionEn
    price
    term
    generation
    planType
  }
`;
export const PlansResponseForPaymentPlanUpdateFormFragmentDoc = gql`
  fragment PlansResponseForPaymentPlanUpdateForm on PlansResponse {
    plans {
      ...PaymentV2PlanForPaymentPlanUpdateForm
    }
  }
  ${PaymentV2PlanForPaymentPlanUpdateFormFragmentDoc}
`;
export const CustomerForPaymentPlanUpdateFormFragmentDoc = gql`
  fragment CustomerForPaymentPlanUpdateForm on PaymentV2Customer {
    paymentMethod
    paymentType
    paymentStatus
    isTrial
    isAutoRenewal
    language
    subscription {
      subscriptionId
      status
      customerId
      periodType
      plan {
        generation
        planType
        enabledChangePlan
      }
      subscribedCoupons {
        isActive
        createdAtSeconds
        expiresAtSeconds
        coupon {
          couponId
          nameJa
          nameEn
          couponType
          couponDurationType
          durationMonth
          discountNumber
        }
      }
    }
  }
`;
export const MostExpensivePurchaseForPaymentPlanUpdateFormFragmentDoc = gql`
  fragment MostExpensivePurchaseForPaymentPlanUpdateForm on PaymentPurchase {
    purchaseId
    plan {
      ...PaymentV2PlanForPaymentPlanUpdateForm
    }
  }
  ${PaymentV2PlanForPaymentPlanUpdateFormFragmentDoc}
`;
export const ProjectWorkspaceForProjectCodingEditorFragmentDoc = gql`
  fragment ProjectWorkspaceForProjectCodingEditor on ProjectWorkspace {
    id
    uri
    clientPort
    agentPort
  }
`;
export const StatisticsStatsForProjectRankVisualizerFragmentDoc = gql`
  fragment StatisticsStatsForProjectRankVisualizer on StatisticsStats {
    avg
    max
    min
    p25
    p50
    p75
    p95
    std
  }
`;
export const ProjectStatisticsForProjectRankVisualizerFragmentDoc = gql`
  fragment ProjectStatisticsForProjectRankVisualizer on ProjectStatistics {
    totalScore {
      ...StatisticsStatsForProjectRankVisualizer
    }
    performance {
      ...StatisticsStatsForProjectRankVisualizer
    }
    coverage {
      ...StatisticsStatsForProjectRankVisualizer
    }
    numTabEvents {
      ...StatisticsStatsForProjectRankVisualizer
    }
    avgLatency {
      ...StatisticsStatsForProjectRankVisualizer
    }
    avgSla {
      ...StatisticsStatsForProjectRankVisualizer
    }
    numSnapshots {
      ...StatisticsStatsForProjectRankVisualizer
    }
    elapsedTime {
      ...StatisticsStatsForProjectRankVisualizer
    }
    bins
  }
  ${StatisticsStatsForProjectRankVisualizerFragmentDoc}
`;
export const SkillTagNodeForQuestionSearchAreaFragmentDoc = gql`
  fragment SkillTagNodeForQuestionSearchArea on SkillTagNode {
    id
    skillTagNodeId
    name
    path
  }
`;
export const HierarchicalSkillTagsResponseForQuestionSearchAreaFragmentDoc = gql`
  fragment HierarchicalSkillTagsResponseForQuestionSearchArea on HierarchicalSkillTagsResponse {
    skillTagNodes {
      ...SkillTagNodeForQuestionSearchArea
    }
    count
    hasNext
  }
  ${SkillTagNodeForQuestionSearchAreaFragmentDoc}
`;
export const SpotQuestionForQuestionSelectFieldForResourceEditorFragmentDoc = gql`
  fragment SpotQuestionForQuestionSelectFieldForResourceEditor on SpotQuestion {
    ... on AlgorithmQuestion {
      ...ListAlgorithmQuestionForQuestionSelectFieldForResourceEditor
    }
    ... on MultiChoicePackage {
      ...ListMultiChoiceQuestionForQuestionSelectFieldForResourceEditor
    }
    ... on ProjectQuestion {
      ...ListProjectQuestionForQuestionSelectFieldForResourceEditor
    }
    ... on SystemDesignQuestion {
      ...ListSystemDesignQuestionForQuestionSelectFieldForResourceEditor
    }
  }
  ${ListAlgorithmQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
  ${ListMultiChoiceQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
  ${ListProjectQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
  ${ListSystemDesignQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
`;
export const LiveCodingQuestionForQuestionSelectFieldForResourceEditorFragmentDoc = gql`
  fragment LiveCodingQuestionForQuestionSelectFieldForResourceEditor on LiveCodingQuestion {
    ... on AlgorithmQuestion {
      ...ListAlgorithmQuestionForQuestionSelectFieldForResourceEditor
    }
    ... on SystemDesignQuestion {
      ...ListSystemDesignQuestionForQuestionSelectFieldForResourceEditor
    }
    ... on FreepadQuestion {
      ...ListFreepadQuestionForQuestionSelectFieldForResourceEditor
    }
  }
  ${ListAlgorithmQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
  ${ListSystemDesignQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
  ${ListFreepadQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
`;
export const QuestionObjectForQuestionSelectFieldForResourceEditorFragmentDoc = gql`
  fragment QuestionObjectForQuestionSelectFieldForResourceEditor on QuestionObject {
    id
    questionVariant
    spotQuestion {
      ...SpotQuestionForQuestionSelectFieldForResourceEditor
    }
    liveCodingQuestion {
      ...LiveCodingQuestionForQuestionSelectFieldForResourceEditor
    }
  }
  ${SpotQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
  ${LiveCodingQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
`;
export const QuestionsForQuestionSelectFieldForResourceEditorFragmentDoc = gql`
  fragment QuestionsForQuestionSelectFieldForResourceEditor on Questions {
    questions {
      ...QuestionObjectForQuestionSelectFieldForResourceEditor
    }
    count
    hasNext
  }
  ${QuestionObjectForQuestionSelectFieldForResourceEditorFragmentDoc}
`;
export const QuestionPackageForQuestionSelectFieldForResourceEditorFragmentDoc = gql`
  fragment QuestionPackageForQuestionSelectFieldForResourceEditor on QuestionPackage {
    __typename
    id
    questionPackageId
    difficulty
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    questionObjects {
      ...QuestionObjectForQuestionSelectFieldForResourceEditor
    }
  }
  ${QuestionObjectForQuestionSelectFieldForResourceEditorFragmentDoc}
`;
export const QuestionPackagesResponseForQuestionSelectFieldForResourceEditorFragmentDoc = gql`
  fragment QuestionPackagesResponseForQuestionSelectFieldForResourceEditor on QuestionPackagesResponse {
    count
    packages {
      ...QuestionPackageForQuestionSelectFieldForResourceEditor
    }
    hasNext
  }
  ${QuestionPackageForQuestionSelectFieldForResourceEditorFragmentDoc}
`;
export const SkillTagNodeForQuestionSelectFieldForResourceEditorFragmentDoc = gql`
  fragment SkillTagNodeForQuestionSelectFieldForResourceEditor on SkillTagNode {
    id
    skillTagNodeId
    name
    path
  }
`;
export const HierarchicalSkillTagsResponseForQuestionSelectFieldForResourceEditorFragmentDoc = gql`
  fragment HierarchicalSkillTagsResponseForQuestionSelectFieldForResourceEditor on HierarchicalSkillTagsResponse {
    skillTagNodes {
      ...SkillTagNodeForQuestionSelectFieldForResourceEditor
    }
    count
    hasNext
  }
  ${SkillTagNodeForQuestionSelectFieldForResourceEditorFragmentDoc}
`;
export const StatisticsStatsForQuizRankVisualizerFragmentDoc = gql`
  fragment StatisticsStatsForQuizRankVisualizer on StatisticsStats {
    avg
    max
    min
    p25
    p50
    p75
    p95
    std
  }
`;
export const QuizStatisticsForQuizRankVisualizerFragmentDoc = gql`
  fragment QuizStatisticsForQuizRankVisualizer on QuizStatistics {
    totalScore {
      ...StatisticsStatsForQuizRankVisualizer
    }
    numTabEvents {
      ...StatisticsStatsForQuizRankVisualizer
    }
    elapsedTime {
      ...StatisticsStatsForQuizRankVisualizer
    }
    bins
  }
  ${StatisticsStatsForQuizRankVisualizerFragmentDoc}
`;
export const RecommendationForRemoteInterviewFeedbackFragmentDoc = gql`
  fragment RecommendationForRemoteInterviewFeedback on EvaluationRecommendation {
    id
    employeeId
    evaluationRecommendationId
    evaluations {
      id
      evaluationId
      employeeId
      metricId
      comment
      numStars
      metric {
        id
        title
        metricId
        description
      }
    }
    isRecommended
    createdAtSeconds
    comment
    reviewer {
      id
      displayName
      email
      photoUrl
    }
  }
`;
export const RemoteForRemoteInterviewFeedbackFragmentDoc = gql`
  fragment RemoteForRemoteInterviewFeedback on Remote {
    id
    remoteId
    recommendations {
      ...RecommendationForRemoteInterviewFeedback
    }
  }
  ${RecommendationForRemoteInterviewFeedbackFragmentDoc}
`;
export const UserForRemoteInterviewOverviewFragmentDoc = gql`
  fragment UserForRemoteInterviewOverview on User {
    id
    uid
    displayName
    photoUrl
    email
    isSystemAdmin
  }
`;
export const SessionForRemoteInterviewOverviewFragmentDoc = gql`
  fragment SessionForRemoteInterviewOverview on LiveCodingSession {
    id
    liveCodingSessionId
    questionType
    questionVariant
    algorithmQuestion(withAnswers: false) {
      id
      questionId
      status
      titleJa
      titleEn
      descriptionJa
      descriptionEn
      version
      difficulty
    }
    systemDesignQuestion(withAnswers: false) {
      id
      questionId
      status
      titleJa
      titleEn
      descriptionJa
      descriptionEn
      difficulty
    }
    freepadQuestion(withAnswers: false) {
      id
      freepadQuestionId
      status
      titleJa
      titleEn
      descriptionJa
      descriptionEn
      version
      difficulty
      variant
    }
  }
`;
export const RemoteForRemoteInterviewOverviewFragmentDoc = gql`
  fragment RemoteForRemoteInterviewOverview on Remote {
    id
    remoteId
    creator {
      ...UserForRemoteInterviewOverview
    }
    entity {
      entityType
      liveCoding {
        id
        sessions {
          ...SessionForRemoteInterviewOverview
        }
      }
    }
    isPublic
    isPassed
    memo
    name
    status
    candidateName
    evaluationComment
    createdAtSeconds
    didStartAtSeconds
    didEndAtSeconds
    evaluatedAtSeconds
    evaluatedBy {
      ...UserForRemoteInterviewOverview
    }
    tags {
      __typename
      name
    }
    viewers {
      __typename
      ... on User {
        ...UserForRemoteInterviewOverview
      }
      ... on EmployeeGroup {
        id
        groupName
        companyEmployees {
          employeeId
          role
        }
      }
    }
  }
  ${UserForRemoteInterviewOverviewFragmentDoc}
  ${SessionForRemoteInterviewOverviewFragmentDoc}
`;
export const EmployeeForRemoteInterviewParticipantsFragmentDoc = gql`
  fragment EmployeeForRemoteInterviewParticipants on User {
    id
    photoUrl
  }
`;
export const SessionForRemoteInterviewPlaybackFragmentDoc = gql`
  fragment SessionForRemoteInterviewPlayback on LiveCodingSession {
    id
    liveCodingId
    liveCodingSessionId
    questionVariant
    questionType
    algorithmQuestion(withAnswers: true) {
      id
      questionId
      status
      version
      variant
      titleJa
      titleEn
      descriptionJa
      descriptionEn
      difficulty
      answers {
        id
        titleJa
        titleEn
        descriptionJa
        descriptionEn
        label
      }
    }
    systemDesignQuestion(withAnswers: true) {
      id
      questionId
      status
      titleJa
      titleEn
      descriptionJa
      descriptionEn
      difficulty
      answers {
        id
        titleJa
        titleEn
        descriptionJa
        descriptionEn
      }
    }
    freepadQuestion(withAnswers: true) {
      id
      freepadQuestionId
      status
      titleJa
      titleEn
      descriptionJa
      descriptionEn
      version
      difficulty
      variant
      answers {
        id
        titleJa
        titleEn
        descriptionJa
        descriptionEn
        label
        freepadAnswerId
        answerCodes {
          id
          freepadAnswerCodeId
          runtime
          body
        }
        answerFlowCharts {
          id
          freepadAnswerFlowChartId
          componentType
          body
        }
      }
    }
  }
`;
export const ParticipantForRemoteInterviewPlaybackFragmentDoc = gql`
  fragment ParticipantForRemoteInterviewPlayback on LiveCodingParticipant {
    userId
    liveCodingId
    userName
    userType
  }
`;
export const RemoteForRemoteInterviewPlaybackFragmentDoc = gql`
  fragment RemoteForRemoteInterviewPlayback on Remote {
    id
    remoteId
    status
    entity {
      entityType
      liveCoding {
        id
        liveCodingId
        sessions {
          ...SessionForRemoteInterviewPlayback
        }
        participants {
          ...ParticipantForRemoteInterviewPlayback
        }
      }
    }
  }
  ${SessionForRemoteInterviewPlaybackFragmentDoc}
  ${ParticipantForRemoteInterviewPlaybackFragmentDoc}
`;
export const AlgorithmQuestionForRemoteInterviewTemplateListMenuFragmentDoc = gql`
  fragment AlgorithmQuestionForRemoteInterviewTemplateListMenu on AlgorithmQuestion {
    __typename
    id
    questionId
    version
    key
    companyId
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    status
    supportedLanguages
    difficulty
    isPrivate
    isOfficial
    timeLimitSeconds
    AlgorithmQuestionVariant: variant
  }
`;
export const SystemDesignQuestionForRemoteInterviewTemplateListMenuFragmentDoc = gql`
  fragment SystemDesignQuestionForRemoteInterviewTemplateListMenu on SystemDesignQuestion {
    __typename
    id
    questionId
    key
    companyId
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    difficulty
    isPrivate
    isOfficial
    timeLimitSeconds
  }
`;
export const FreepadQuestionForRemoteInterviewTemplateListMenuFragmentDoc = gql`
  fragment FreepadQuestionForRemoteInterviewTemplateListMenu on FreepadQuestion {
    __typename
    id
    companyId
    key
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    status
    difficulty
    isPrivate
    isOfficial
  }
`;
export const TemplateForRemoteInterviewTemplateListMenuFragmentDoc = gql`
  fragment TemplateForRemoteInterviewTemplateListMenu on RemoteInterviewTemplate {
    id
    templateId
    templateName
    description
    candidateName
    remoteName
    reviewers {
      type
      employee {
        id
        displayName
      }
      group {
        id
        groupName
      }
    }
    tagNames
    templateQuestions(withAnswers: false) {
      __typename
      ... on AlgorithmQuestion {
        ...AlgorithmQuestionForRemoteInterviewTemplateListMenu
      }
      ... on SystemDesignQuestion {
        ...SystemDesignQuestionForRemoteInterviewTemplateListMenu
      }
      ... on FreepadQuestion {
        ...FreepadQuestionForRemoteInterviewTemplateListMenu
      }
    }
    questions {
      questionId
      entityType
      questionKey
      challengeQuestion {
        questionId
        uniqueKey
      }
      systemDesignQuestion {
        questionId
        componentTypes
      }
      freepadQuestion {
        questionId
        uniqueKey
      }
    }
    memo
    variables {
      id
      key
      templateId
      name
      description
    }
    isPublic
  }
  ${AlgorithmQuestionForRemoteInterviewTemplateListMenuFragmentDoc}
  ${SystemDesignQuestionForRemoteInterviewTemplateListMenuFragmentDoc}
  ${FreepadQuestionForRemoteInterviewTemplateListMenuFragmentDoc}
`;
export const ListTemplateForRemoteInterviewTemplateListMenuFragmentDoc = gql`
  fragment ListTemplateForRemoteInterviewTemplateListMenu on RemoteInterviewTemplate {
    id
    templateId
    templateName
    description
    remoteName
    candidateName
    reviewers {
      type
      employee {
        id
        displayName
      }
      group {
        id
        groupName
      }
    }
    tagNames
    templateQuestions(withAnswers: false) {
      __typename
      ... on AlgorithmQuestion {
        ...AlgorithmQuestionForRemoteInterviewTemplateListMenu
        AlgorithmQuestionVariant: variant
      }
      ... on SystemDesignQuestion {
        ...SystemDesignQuestionForRemoteInterviewTemplateListMenu
      }
      ... on FreepadQuestion {
        ...FreepadQuestionForRemoteInterviewTemplateListMenu
      }
    }
    questions {
      questionId
      questionKey
      entityType
      challengeQuestion {
        questionId
        uniqueKey
      }
      systemDesignQuestion {
        questionId
        componentTypes
      }
      freepadQuestion {
        questionId
        uniqueKey
      }
    }
    memo
    variables {
      id
      key
      templateId
      name
      description
    }
    isPublic
  }
  ${AlgorithmQuestionForRemoteInterviewTemplateListMenuFragmentDoc}
  ${SystemDesignQuestionForRemoteInterviewTemplateListMenuFragmentDoc}
  ${FreepadQuestionForRemoteInterviewTemplateListMenuFragmentDoc}
`;
export const FillTemplateForRemoteInterviewTemplateListMenuFragmentDoc = gql`
  fragment FillTemplateForRemoteInterviewTemplateListMenu on RemoteInterviewTemplate {
    id
    templateId
    templateName
    description
    remoteName
    candidateName
    reviewers {
      type
      employee {
        id
        displayName
      }
      group {
        id
        groupName
      }
    }
    tagNames
    templateQuestions(withAnswers: false) {
      __typename
      ... on AlgorithmQuestion {
        ...AlgorithmQuestionForRemoteInterviewTemplateListMenu
      }
      ... on SystemDesignQuestion {
        ...SystemDesignQuestionForRemoteInterviewTemplateListMenu
      }
      ... on FreepadQuestion {
        ...FreepadQuestionForRemoteInterviewTemplateListMenu
      }
    }
    questions {
      questionId
      questionKey
      entityType
      challengeQuestion {
        questionId
        uniqueKey
      }
      systemDesignQuestion {
        questionId
        componentTypes
      }
      freepadQuestion {
        questionId
        uniqueKey
      }
    }
    memo
    variables {
      id
      key
      templateId
      name
      description
    }
    isPublic
  }
  ${AlgorithmQuestionForRemoteInterviewTemplateListMenuFragmentDoc}
  ${SystemDesignQuestionForRemoteInterviewTemplateListMenuFragmentDoc}
  ${FreepadQuestionForRemoteInterviewTemplateListMenuFragmentDoc}
`;
export const EmployeeForRemoteInterviewVideoChatParticipantsFragmentDoc = gql`
  fragment EmployeeForRemoteInterviewVideoChatParticipants on User {
    id
    photoUrl
  }
`;
export const SpotForScheduleEventsFragmentDoc = gql`
  fragment SpotForScheduleEvents on Spot {
    __typename
    id
    name
    willExpireAtSeconds
    createdAtSeconds
  }
`;
export const RemoteForScheduleEventsFragmentDoc = gql`
  fragment RemoteForScheduleEvents on Remote {
    __typename
    id
    name
    createdAtSeconds
    status
  }
`;
export const ScreeningChallengeEntitySourceQuestionForScreeningDetailFragmentDoc = gql`
  fragment ScreeningChallengeEntitySourceQuestionForScreeningDetail on ScreeningChallengeEntitySourceQuestion {
    questionId
    questionVersion
    enabledLanguages
    questionWeight
    question {
      __typename
      ...ListAlgorithmQuestionForQuestionSelectFieldForResourceEditor
      algorithmQuestionStatus: status
    }
  }
  ${ListAlgorithmQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
`;
export const ScreeningQuizEntitySourcePackageForScreeningDetailFragmentDoc = gql`
  fragment ScreeningQuizEntitySourcePackageForScreeningDetail on ScreeningQuizEntitySourcePackage {
    packageId
    packageVersion
    questionWeight
    pb_package {
      __typename
      ...ListMultiChoiceQuestionForQuestionSelectFieldForResourceEditor
      multiChoicePackageStatus: status
    }
  }
  ${ListMultiChoiceQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
`;
export const ScreeningProjectEntitySourceQuestionForScreeningDetailFragmentDoc = gql`
  fragment ScreeningProjectEntitySourceQuestionForScreeningDetail on ScreeningProjectEntitySourceQuestion {
    questionId
    questionVersion
    questionWeight
    question {
      __typename
      ...ListProjectQuestionForQuestionSelectFieldForResourceEditor
      projectQuestionStatus: status
    }
  }
  ${ListProjectQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
`;
export const ScreeningSystemDesignEntitySourceQuestionForScreeningDetailFragmentDoc = gql`
  fragment ScreeningSystemDesignEntitySourceQuestionForScreeningDetail on ScreeningSystemDesignEntitySourceQuestion {
    questionId
    componentTypes
    questionWeight
    question {
      __typename
      ...ListSystemDesignQuestionForQuestionSelectFieldForResourceEditor
      systemDesignQuestionStatus: status
    }
  }
  ${ListSystemDesignQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
`;
export const ScreeningEntitySourceQuestionForScreeningDetailFragmentDoc = gql`
  fragment ScreeningEntitySourceQuestionForScreeningDetail on ScreeningEntitySourceQuestion {
    __typename
    ... on ScreeningChallengeEntitySourceQuestion {
      ...ScreeningChallengeEntitySourceQuestionForScreeningDetail
    }
    ... on ScreeningQuizEntitySourcePackage {
      ...ScreeningQuizEntitySourcePackageForScreeningDetail
    }
    ... on ScreeningProjectEntitySourceQuestion {
      ...ScreeningProjectEntitySourceQuestionForScreeningDetail
    }
    ... on ScreeningSystemDesignEntitySourceQuestion {
      ...ScreeningSystemDesignEntitySourceQuestionForScreeningDetail
    }
  }
  ${ScreeningChallengeEntitySourceQuestionForScreeningDetailFragmentDoc}
  ${ScreeningQuizEntitySourcePackageForScreeningDetailFragmentDoc}
  ${ScreeningProjectEntitySourceQuestionForScreeningDetailFragmentDoc}
  ${ScreeningSystemDesignEntitySourceQuestionForScreeningDetailFragmentDoc}
`;
export const ScreeningInviteeForScreeningDetailFragmentDoc = gql`
  fragment ScreeningInviteeForScreeningDetail on ScreeningInvitee {
    screeningId
    email
    status
  }
`;
export const VariableForScreeningDetailFragmentDoc = gql`
  fragment VariableForScreeningDetail on ScreeningVariable {
    screeningId
    variableId
    label
    description
    variableField {
      __typename
      ... on ScreeningStringFieldVariable {
        __typename
        validationRule {
          __typename
          maxLength: max
          minLength: min
          required
        }
        formType
      }
      ... on ScreeningIntegerFieldVariable {
        __typename
        validationRule {
          __typename
          maxIntegerValue: max
          minIntegerValue: min
          required
        }
      }
      ... on ScreeningBooleanFieldVariable {
        __typename
        validationRule {
          __typename
          required
        }
      }
    }
  }
`;
export const ScreeningForScreeningDetailFragmentDoc = gql`
  fragment ScreeningForScreeningDetail on Screening {
    id
    screeningId
    allowWebSearch
    allowChatGPT
    allowHint
    acceptableLimit
    deadlineSeconds
    name
    description
    numAccepted
    acceptanceStatus
    createdAtSeconds
    memo
    ownerEmailAddress
    messageForCandidate
    entityTracks(withAnswers: true) {
      ... on ScreeningFixedEntityTrack {
        __typename
        trackId
        questionScoreWeight
        entitySourceQuestion(withAnswers: true) {
          ...ScreeningEntitySourceQuestionForScreeningDetail
        }
      }
      ... on ScreeningSelectableFromQuestionsEntityTrack {
        __typename
        trackId
        questionScoreWeight
        entitySourceQuestions(withAnswers: true) {
          ...ScreeningEntitySourceQuestionForScreeningDetail
        }
      }
      ... on ScreeningRandomFromQuestionsEntityTrack {
        __typename
        trackId
        questionScoreWeight
        entitySourceQuestions(withAnswers: true) {
          ...ScreeningEntitySourceQuestionForScreeningDetail
        }
      }
    }
    timeLimitSeconds
    candidateAccessPolicy
    invitationLanguage
    isActive
    spotIsPublic
    invitees {
      ...ScreeningInviteeForScreeningDetail
    }
    variables {
      ...VariableForScreeningDetail
    }
    createdBy {
      id
      uid
      displayName
      email
      photoUrl
    }
    viewers {
      __typename
      ... on User {
        id
        uid
        photoUrl
        isSystemAdmin
        displayName
        email
      }
      ... on EmployeeGroup {
        id
        employeeGroupId
        groupName
      }
    }
    tags {
      name
    }
  }
  ${ScreeningEntitySourceQuestionForScreeningDetailFragmentDoc}
  ${ScreeningInviteeForScreeningDetailFragmentDoc}
  ${VariableForScreeningDetailFragmentDoc}
`;
export const SpotForScreeningDetailFragmentDoc = gql`
  fragment SpotForScreeningDetail on Spot {
    id
    spotId
    parentId
    originId
    name
    candidateName
    candidateEmail
    screeningId
    status
    isPublic
    totalScore
    customScore
    rank
    numSubset
    rankEvaluation
    isReliableRank
    isPassed
    isRetake
    employee {
      id
      email
      emailVerified
      displayName
      fullName
      language
      photoUrl
    }
    tags {
      name
    }
    recommenders {
      id
      displayName
      email
      photoUrl
    }
    evaluatedBy {
      id
      displayName
      email
      photoUrl
    }
    suspiciousDegree
    createdAtSeconds
    declinedAtSeconds
    deletedAtSeconds
    didEndAtSeconds
    didStartAtSeconds
    elapsedTimeSeconds
    evaluatedAtSeconds
    finalizedAtSeconds
    timeLimitSeconds
    updatedAtSeconds
    willExpireAtSeconds
  }
`;
export const SpotsByScreeningIdResponseForScreeningDetailFragmentDoc = gql`
  fragment SpotsByScreeningIdResponseForScreeningDetail on SpotsByScreeningIdResponse {
    count
    hasNext
    spots {
      ...SpotForScreeningDetail
    }
  }
  ${SpotForScreeningDetailFragmentDoc}
`;
export const SpotTagsByCompanyIdResponseForScreeningDetailFragmentDoc = gql`
  fragment SpotTagsByCompanyIdResponseForScreeningDetail on SpotTagsByCompanyIdResponse {
    tags {
      name
      companyId
    }
    count
    pager {
      cursorSeconds
      hasNext
    }
  }
`;
export const SpotFilledVariableCandidateForScreeningDetailFragmentDoc = gql`
  fragment SpotFilledVariableCandidateForScreeningDetail on SpotFilledVariableCandidate {
    label
    formType
    valueType
  }
`;
export const ScreeningInviteeForScreeningInviteeListFragmentDoc = gql`
  fragment ScreeningInviteeForScreeningInviteeList on ScreeningInvitee {
    screeningId
    email
    status
  }
`;
export const ScreeningInviteesResponseForScreeningInviteeListFragmentDoc = gql`
  fragment ScreeningInviteesResponseForScreeningInviteeList on ScreeningInviteesResponse {
    invitees {
      ...ScreeningInviteeForScreeningInviteeList
    }
    count
    hasNext
  }
  ${ScreeningInviteeForScreeningInviteeListFragmentDoc}
`;
export const ScreeningEntitySourceQuestionForScreeningResourceEditorFragmentDoc = gql`
  fragment ScreeningEntitySourceQuestionForScreeningResourceEditor on ScreeningEntitySourceQuestion {
    ... on ScreeningChallengeEntitySourceQuestion {
      __typename
      enabledLanguages
      questionWeight
      question {
        ...ListAlgorithmQuestionForQuestionSelectFieldForResourceEditor
      }
    }
    ... on ScreeningQuizEntitySourcePackage {
      __typename
      questionWeight
      pb_package {
        ...ListMultiChoiceQuestionForQuestionSelectFieldForResourceEditor
      }
    }
    ... on ScreeningProjectEntitySourceQuestion {
      __typename
      questionWeight
      question {
        ...ListProjectQuestionForQuestionSelectFieldForResourceEditor
      }
    }
    ... on ScreeningSystemDesignEntitySourceQuestion {
      __typename
      questionWeight
      componentTypes
      question {
        ...ListSystemDesignQuestionForQuestionSelectFieldForResourceEditor
      }
    }
  }
  ${ListAlgorithmQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
  ${ListMultiChoiceQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
  ${ListProjectQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
  ${ListSystemDesignQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
`;
export const ScreeningStringFieldVariableForScreeningResourceEditorFragmentDoc = gql`
  fragment ScreeningStringFieldVariableForScreeningResourceEditor on ScreeningStringFieldVariable {
    __typename
    validationRule {
      __typename
      maxLength: max
      minLength: min
      required
    }
    formType
  }
`;
export const ScreeningIntegerFieldVariableForScreeningResourceEditorFragmentDoc = gql`
  fragment ScreeningIntegerFieldVariableForScreeningResourceEditor on ScreeningIntegerFieldVariable {
    __typename
    validationRule {
      __typename
      maxIntegerValue: max
      minIntegerValue: min
      required
    }
  }
`;
export const ScreeningBooleanFieldVariableForScreeningResourceEditorFragmentDoc = gql`
  fragment ScreeningBooleanFieldVariableForScreeningResourceEditor on ScreeningBooleanFieldVariable {
    __typename
    validationRule {
      __typename
      required
    }
  }
`;
export const VariableForScreeningResourceEditorFragmentDoc = gql`
  fragment VariableForScreeningResourceEditor on ScreeningVariable {
    screeningId
    variableId
    label
    description
    variableField {
      __typename
      ... on ScreeningStringFieldVariable {
        ...ScreeningStringFieldVariableForScreeningResourceEditor
      }
      ... on ScreeningIntegerFieldVariable {
        ...ScreeningIntegerFieldVariableForScreeningResourceEditor
      }
      ... on ScreeningBooleanFieldVariable {
        ...ScreeningBooleanFieldVariableForScreeningResourceEditor
      }
    }
  }
  ${ScreeningStringFieldVariableForScreeningResourceEditorFragmentDoc}
  ${ScreeningIntegerFieldVariableForScreeningResourceEditorFragmentDoc}
  ${ScreeningBooleanFieldVariableForScreeningResourceEditorFragmentDoc}
`;
export const ScreeningForScreeningResourceEditorFragmentDoc = gql`
  fragment ScreeningForScreeningResourceEditor on Screening {
    id
    screeningId
    allowWebSearch
    allowChatGPT
    allowHint
    acceptableLimit
    deadlineSeconds
    name
    description
    numAccepted
    memo
    messageForCandidate
    ownerEmailAddress
    entityTracks(withAnswers: false) {
      ... on ScreeningFixedEntityTrack {
        __typename
        trackId
        questionScoreWeight
        entitySourceQuestion(withAnswers: false) {
          ...ScreeningEntitySourceQuestionForScreeningResourceEditor
        }
      }
      ... on ScreeningSelectableFromQuestionsEntityTrack {
        __typename
        trackId
        questionScoreWeight
        entitySourceQuestions(withAnswers: false) {
          ...ScreeningEntitySourceQuestionForScreeningResourceEditor
        }
      }
      ... on ScreeningRandomFromQuestionsEntityTrack {
        __typename
        trackId
        questionScoreWeight
        entitySourceQuestions(withAnswers: false) {
          ...ScreeningEntitySourceQuestionForScreeningResourceEditor
        }
      }
    }
    timeLimitSeconds
    candidateAccessPolicy
    invitationLanguage
    isActive
    spotIsPublic
    variables {
      ...VariableForScreeningResourceEditor
    }
    createdBy {
      id
      uid
      displayName
    }
    viewers {
      ... on User {
        id
        uid
        displayName
      }
      ... on EmployeeGroup {
        id
        employeeGroupId
        groupName
      }
    }
    tags {
      name
    }
  }
  ${ScreeningEntitySourceQuestionForScreeningResourceEditorFragmentDoc}
  ${VariableForScreeningResourceEditorFragmentDoc}
`;
export const ChallengeEntityForScreeningTestResourceEditorFragmentDoc = gql`
  fragment ChallengeEntityForScreeningTestResourceEditor on ChallengeEntity {
    id
    enabledLanguages
    challengeQuestion(withAnswers: false) {
      ...ListAlgorithmQuestionForQuestionSelectFieldForResourceEditor
    }
  }
  ${ListAlgorithmQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
`;
export const ProjectEntityForScreeningTestResourceEditorFragmentDoc = gql`
  fragment ProjectEntityForScreeningTestResourceEditor on ProjectEntity {
    id
    question {
      ...ListProjectQuestionForQuestionSelectFieldForResourceEditor
    }
  }
  ${ListProjectQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
`;
export const QuizEntityForScreeningTestResourceEditorFragmentDoc = gql`
  fragment QuizEntityForScreeningTestResourceEditor on QuizEntity {
    id
    pb_package(withAnswers: false) {
      ...ListMultiChoiceQuestionForQuestionSelectFieldForResourceEditor
    }
  }
  ${ListMultiChoiceQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
`;
export const SystemDesignEntityForScreeningTestResourceEditorFragmentDoc = gql`
  fragment SystemDesignEntityForScreeningTestResourceEditor on SystemDesignEntity {
    id
    componentTypes
    question {
      ...ListSystemDesignQuestionForQuestionSelectFieldForResourceEditor
    }
  }
  ${ListSystemDesignQuestionForQuestionSelectFieldForResourceEditorFragmentDoc}
`;
export const SpotForScreeningTestResourceEditorFragmentDoc = gql`
  fragment SpotForScreeningTestResourceEditor on Spot {
    id
    spotId
    originId
    allowWebSearch
    allowChatGPT
    name
    memo
    messageForCandidate
    status
    willExpireAtSeconds
    timeLimitSeconds
    invitationMethod
    invitationEmail
    invitationLanguage
    isPublic
    employee {
      id
      displayName
    }
    reviewers {
      type
      employee {
        id
        displayName
      }
      group {
        id
        groupName
      }
    }
    tags {
      name
    }
    entities(withAnswers: false) {
      __typename
      ... on ChallengeEntity {
        ...ChallengeEntityForScreeningTestResourceEditor
      }
      ... on ProjectEntity {
        ...ProjectEntityForScreeningTestResourceEditor
      }
      ... on QuizEntity {
        ...QuizEntityForScreeningTestResourceEditor
      }
      ... on SystemDesignEntity {
        ...SystemDesignEntityForScreeningTestResourceEditor
      }
    }
  }
  ${ChallengeEntityForScreeningTestResourceEditorFragmentDoc}
  ${ProjectEntityForScreeningTestResourceEditorFragmentDoc}
  ${QuizEntityForScreeningTestResourceEditorFragmentDoc}
  ${SystemDesignEntityForScreeningTestResourceEditorFragmentDoc}
`;
export const GetCompanyForSecuritySettingsSecurityFragmentDoc = gql`
  fragment GetCompanyForSecuritySettingsSecurity on Security {
    emailDomain
    idpCertificate
    idpEntityId
    idpSsoUrl
    providerId
    ssoEnabled
    ssoMode
  }
`;
export const SpotTagsByCompanyIdResponseForSpotTagFieldFragmentDoc = gql`
  fragment SpotTagsByCompanyIdResponseForSpotTagField on SpotTagsByCompanyIdResponse {
    tags {
      name
      companyId
    }
    count
    pager {
      cursorSeconds
      hasNext
    }
  }
`;
export const InvoiceForSubscriptionInvoiceV2FragmentDoc = gql`
  fragment InvoiceForSubscriptionInvoiceV2 on PaymentV2Invoice {
    invoiceId
    customerId
    invoiceUrl
    totalPrice
    status
    dueDateSeconds
    createdAtSeconds
  }
`;
export const PaymentPagerForSubscriptionInvoiceV2FragmentDoc = gql`
  fragment PaymentPagerForSubscriptionInvoiceV2 on PaymentPager {
    cursorSeconds
    cursorNanoSeconds
    hasNext
  }
`;
export const InvoicesResponseForSubscriptionInvoiceV2FragmentDoc = gql`
  fragment InvoicesResponseForSubscriptionInvoiceV2 on InvoicesResponse {
    invoices {
      ...InvoiceForSubscriptionInvoiceV2
    }
    count
    pager {
      ...PaymentPagerForSubscriptionInvoiceV2
    }
  }
  ${InvoiceForSubscriptionInvoiceV2FragmentDoc}
  ${PaymentPagerForSubscriptionInvoiceV2FragmentDoc}
`;
export const ContractPeriodUsageForSubscriptionOverviewV2FragmentDoc = gql`
  fragment ContractPeriodUsageForSubscriptionOverviewV2 on ContractPeriodUsage {
    yearMonth
    totalCount
    paidCount
    freeCount
  }
`;
export const CustomerForSubscriptionOverviewV2FragmentDoc = gql`
  fragment CustomerForSubscriptionOverviewV2 on PaymentV2Customer {
    subscription {
      plan {
        planType
        generation
      }
      packStock
      didStartAtSeconds
      willEndAtSeconds
    }
  }
`;
export const PaymentUsageForSubscriptionUsageV2FragmentDoc = gql`
  fragment PaymentUsageForSubscriptionUsageV2 on PaymentUsage {
    usageId
    interviewId
    interviewType
    price
    reasonType
    createdAtSeconds
  }
`;
export const PaymentPagerForSubscriptionUsageV2FragmentDoc = gql`
  fragment PaymentPagerForSubscriptionUsageV2 on PaymentPager {
    cursorSeconds
    cursorNanoSeconds
    hasNext
  }
`;
export const UsagesV2ResponseForSubscriptionUsageV2FragmentDoc = gql`
  fragment UsagesV2ResponseForSubscriptionUsageV2 on UsagesV2Response {
    usages {
      ...PaymentUsageForSubscriptionUsageV2
    }
    count
    pager {
      ...PaymentPagerForSubscriptionUsageV2
    }
  }
  ${PaymentUsageForSubscriptionUsageV2FragmentDoc}
  ${PaymentPagerForSubscriptionUsageV2FragmentDoc}
`;
export const StatisticsStatsForSystemDesignRankVisualizerFragmentDoc = gql`
  fragment StatisticsStatsForSystemDesignRankVisualizer on StatisticsStats {
    avg
    max
    min
    p25
    p50
    p75
    p95
    std
  }
`;
export const SystemDesignStatisticsForSystemDesignRankVisualizerFragmentDoc = gql`
  fragment SystemDesignStatisticsForSystemDesignRankVisualizer on SystemDesignStatistics {
    totalScore {
      ...StatisticsStatsForSystemDesignRankVisualizer
    }
    availability {
      ...StatisticsStatsForSystemDesignRankVisualizer
    }
    scalability {
      ...StatisticsStatsForSystemDesignRankVisualizer
    }
    numTabEvents {
      ...StatisticsStatsForSystemDesignRankVisualizer
    }
    consistency {
      ...StatisticsStatsForSystemDesignRankVisualizer
    }
    numHints {
      ...StatisticsStatsForSystemDesignRankVisualizer
    }
    numSnapshots {
      ...StatisticsStatsForSystemDesignRankVisualizer
    }
    elapsedTime {
      ...StatisticsStatsForSystemDesignRankVisualizer
    }
    bins
  }
  ${StatisticsStatsForSystemDesignRankVisualizerFragmentDoc}
`;
export const TalentForViewerAssignFieldFragmentDoc = gql`
  fragment TalentForViewerAssignField on User {
    uid
    displayName
    fullName
    email
    photoUrl
  }
`;
export const CompanyForTalentAssignFieldFragmentDoc = gql`
  fragment CompanyForTalentAssignField on Company {
    companyId
    name
    talents {
      ...TalentForViewerAssignField
    }
  }
  ${TalentForViewerAssignFieldFragmentDoc}
`;
export const TalentForTalentMemberSettingsFragmentDoc = gql`
  fragment TalentForTalentMemberSettings on User {
    id
    displayName
    fullName
    email
    photoUrl
  }
`;
export const CompanyTalentForTalentMemberSettingsFragmentDoc = gql`
  fragment CompanyTalentForTalentMemberSettings on CompanyTalent {
    id
    companyId
    talentId
  }
`;
export const CompanyForTalentMemberSettingsFragmentDoc = gql`
  fragment CompanyForTalentMemberSettings on Company {
    companyId
    name
    talents {
      ...TalentForTalentMemberSettings
    }
    companyTalents {
      ...CompanyTalentForTalentMemberSettings
    }
  }
  ${TalentForTalentMemberSettingsFragmentDoc}
  ${CompanyTalentForTalentMemberSettingsFragmentDoc}
`;
export const EvaluationMetricsForTechnicalCommentEvaluationsFormFragmentDoc = gql`
  fragment EvaluationMetricsForTechnicalCommentEvaluationsForm on EvaluationMetric {
    id
    metricId
    title
    description
  }
`;
export const EvaluationMetricGroupsForTechnicalCommentFragmentDoc = gql`
  fragment EvaluationMetricGroupsForTechnicalComment on EvaluationMetricGroup {
    id
    metricGroupId
    title
    description
    metrics {
      id
      metricId
      title
      description
    }
  }
`;
export const EvaluationMetricForTechnicalCommentRemoteFragmentDoc = gql`
  fragment EvaluationMetricForTechnicalCommentRemote on EvaluationMetric {
    id
    metricId
    title
    description
  }
`;
export const SpotForTestRankVisualizerFragmentDoc = gql`
  fragment SpotForTestRankVisualizer on Spot {
    id
    name
    totalScore
    rank
    numSubset
    company {
      id
      name
    }
  }
`;
export const SpotStatisticsForTestRankVisualizerFragmentDoc = gql`
  fragment SpotStatisticsForTestRankVisualizer on SpotStatistics {
    __typename
    bins
    relativeScore {
      avg
      max
      min
      p25
      p50
      p75
      p95
      std
    }
  }
`;
export const RankForTestRankVisualizerFragmentDoc = gql`
  fragment RankForTestRankVisualizer on StatisticsRank {
    evaluation
    numSubset
    rank
    relativeScore
  }
`;
export const ListSpotTagsForTestRankVisualizerFragmentDoc = gql`
  fragment ListSpotTagsForTestRankVisualizer on SpotTagsByCompanyIdResponse {
    tags {
      name
    }
    count
    pager {
      cursorSeconds
      hasNext
    }
  }
`;
export const EmployeeForViewerAssignFieldFragmentDoc = gql`
  fragment EmployeeForViewerAssignField on User {
    uid
    displayName
    fullName
    email
    photoUrl
  }
`;
export const EmployeeGroupForViewerAssignFieldFragmentDoc = gql`
  fragment EmployeeGroupForViewerAssignField on EmployeeGroup {
    employeeGroupId
    groupName
    companyEmployees {
      employeeId
    }
    createdAtSeconds
  }
`;
export const CompanyForViewerAssignFieldFragmentDoc = gql`
  fragment CompanyForViewerAssignField on Company {
    companyId
    name
    employees {
      ...EmployeeForViewerAssignField
    }
    groups {
      ...EmployeeGroupForViewerAssignField
    }
  }
  ${EmployeeForViewerAssignFieldFragmentDoc}
  ${EmployeeGroupForViewerAssignFieldFragmentDoc}
`;
export const AssessmentForAssessmentsForExamTabTablesForTalentFragmentDoc = gql`
  fragment AssessmentForAssessmentsForExamTabTablesForTalent on Assessment {
    assessmentId
    name
    status
    activatedAtSeconds
    createdAtSeconds
    updatedAtSeconds
    deletedAtSeconds
    startScheduleAtSeconds
    nextScheduleAtSeconds
    examInterval
    remindBeforeSeconds
    isSubmittedAtLatestInterval
    rootExamIds
  }
`;
export const ListAssessmentsByTalentIdForExamTabTablesForTalentFragmentDoc = gql`
  fragment ListAssessmentsByTalentIdForExamTabTablesForTalent on ListAssessmentsByTalentId {
    assessments {
      ...AssessmentForAssessmentsForExamTabTablesForTalent
    }
    count
    hasNext
  }
  ${AssessmentForAssessmentsForExamTabTablesForTalentFragmentDoc}
`;
export const ExamForExamTabTablesForTalentFragmentDoc = gql`
  fragment ExamForExamTabTablesForTalent on Exam {
    examId
    status
    didStartAtSeconds
    didEndAtSeconds
    expiredAtSeconds
    willEndAtSeconds
    totalScore
    rankEvaluation
    isReliableRank
    numInterval
    reportSettings {
      showAnswer
      showPlayback
      showRelativeEvaluation
    }
  }
`;
export const ListBestExamsByAssessmentIdForExamTablesFragmentDoc = gql`
  fragment ListBestExamsByAssessmentIdForExamTables on ListBestExamsByAssessmentId {
    exams {
      ...ExamForExamTabTablesForTalent
    }
    count
    hasNext
  }
  ${ExamForExamTabTablesForTalentFragmentDoc}
`;
export const TalentNotificationForNotificationListForTalentFragmentDoc = gql`
  fragment TalentNotificationForNotificationListForTalent on TalentNotification {
    id
    talentNotificationId
    talentId
    isRead
    titleJa
    titleEn
    descriptionJa
    descriptionEn
    createdAtSeconds
    companyId
    actionLink
  }
`;
export const TalentNotificationsResponseForNotificationListForTalentFragmentDoc = gql`
  fragment TalentNotificationsResponseForNotificationListForTalent on TalentNotificationsResponse {
    notifications {
      ...TalentNotificationForNotificationListForTalent
    }
    count
    pager {
      hasNext
      cursor
    }
  }
  ${TalentNotificationForNotificationListForTalentFragmentDoc}
`;
export const UpdateClaimsForCandidateDocument = gql`
  mutation UpdateClaimsForCandidate($updateClaimsInput: UpdateClaimsInput!) {
    updateClaims(updateClaimsInput: $updateClaimsInput)
  }
`;
export const UpdateUserTypeForCandidateDocument = gql`
  mutation UpdateUserTypeForCandidate($updateUserTypeInput: UpdateUserTypeInput!) {
    updateUserType(input: $updateUserTypeInput) {
      uid
    }
  }
`;
export const InitializeForEmployeeDocument = gql`
  query InitializeForEmployee($uid: String!, $size: Int!) {
    companiesByEmployeeId(uid: $uid, withActiveId: true, size: $size) {
      ...EssentialCompanies
    }
    currentUser(uid: $uid) {
      ...EssentialEmployee
    }
  }
  ${EssentialCompaniesFragmentDoc}
  ${EssentialEmployeeFragmentDoc}
`;
export const GetEssentialCompanyWithSecurityForEmployeeDocument = gql`
  query GetEssentialCompanyWithSecurityForEmployee($activeCompanyId: Int!) {
    company(companyId: $activeCompanyId) {
      companyId
      name
      companyEmployees {
        ...EssentialCompanyEmployee
      }
      enableTalentScore
      security {
        ...EssentialSecurityForEmployee
      }
    }
  }
  ${EssentialCompanyEmployeeFragmentDoc}
  ${EssentialSecurityForEmployeeFragmentDoc}
`;
export const GetEssentialCustomerForEmployeeDocument = gql`
  query GetEssentialCustomerForEmployee($customerId: Int!) {
    customer(customerId: $customerId) {
      ...EssentialCustomerForEmployee
    }
  }
  ${EssentialCustomerForEmployeeFragmentDoc}
`;
export const CurrentUserDocument = gql`
  query CurrentUser($uid: String!) {
    currentUser(uid: $uid) {
      id
      displayName
      photoUrl
      emailVerified
      email
      fullName
      language
    }
  }
`;
export const UpdateClaimsForEmployeeDocument = gql`
  mutation UpdateClaimsForEmployee($updateClaimsInput: UpdateClaimsInput!) {
    updateClaims(updateClaimsInput: $updateClaimsInput)
  }
`;
export const SwitchActiveCompanyForEmployeeDocument = gql`
  mutation SwitchActiveCompanyForEmployee($input: SwitchActiveCompanyForEmployeeInput!) {
    switchActiveCompanyForEmployee(input: $input)
  }
`;
export const GetCurrentUserDocument = gql`
  query GetCurrentUser($uid: String!) {
    currentUser(uid: $uid) {
      userType
    }
  }
`;
export const UpdateUserTypeForSignedInUserDocument = gql`
  mutation UpdateUserTypeForSignedInUser($updateUserTypeInput: UpdateUserTypeInput!) {
    updateUserType(input: $updateUserTypeInput) {
      uid
    }
  }
`;
export const InitializeAppDocument = gql`
  mutation InitializeApp($input: InitializeInput!) {
    initializeApp(input: $input) {
      ... on InitializedEmployeeUserResponse {
        ...InitializeParamsForEmployee
      }
      ... on InitializedCandidateUserResponse {
        __typename
        reserved
      }
      ... on InitializedTalentUserResponse {
        ...InitializeParamsForTalent
      }
      ... on NeedClientSideActionResponse {
        __typename
        action
      }
      ... on InitializedAuthResponse {
        ...InitializeParamsForAuth
      }
      ... on InitializedInvitationEmployeeResponse {
        ...InitializeParamsForInvitationEmployee
      }
      ... on InitializedInvitationTalentResponse {
        ...InitializeParamsForInvitationTalent
      }
    }
  }
  ${InitializeParamsForEmployeeFragmentDoc}
  ${InitializeParamsForTalentFragmentDoc}
  ${InitializeParamsForAuthFragmentDoc}
  ${InitializeParamsForInvitationEmployeeFragmentDoc}
  ${InitializeParamsForInvitationTalentFragmentDoc}
`;
export const SwitchActiveCompanyForEmployeeV2Document = gql`
  mutation SwitchActiveCompanyForEmployeeV2($input: SwitchActiveCompanyForEmployeeV2Input!) {
    switchActiveCompanyForEmployeeV2(input: $input)
  }
`;
export const SwitchActiveCompanyForTalentV2Document = gql`
  mutation SwitchActiveCompanyForTalentV2($input: SwitchActiveCompanyForTalentV2Input!) {
    switchActiveCompanyForTalentV2(input: $input)
  }
`;
export const UpdateClaimsV2Document = gql`
  mutation UpdateClaimsV2($input: UpdateClaimsV2Input!) {
    updateClaimsV2(input: $input)
  }
`;
export const InitializeForTalentDocument = gql`
  query InitializeForTalent($talentId: String!, $cursorSeconds: Int, $size: Int!, $withActiveId: Boolean!) {
    companiesByTalentId(talentId: $talentId, cursorSeconds: $cursorSeconds, size: $size, withActiveId: $withActiveId) {
      ...EssentialCompaniesForTalent
    }
    currentUser(uid: $talentId) {
      ...EssentialTalent
    }
  }
  ${EssentialCompaniesForTalentFragmentDoc}
  ${EssentialTalentFragmentDoc}
`;
export const SwitchActiveCompanyForTalentDocument = gql`
  mutation SwitchActiveCompanyForTalent($input: SwitchActiveCompanyForTalentInput!) {
    switchActiveCompanyForTalent(input: $input)
  }
`;
export const GetEssentialCustomerForTalentDocument = gql`
  query GetEssentialCustomerForTalent($customerId: Int!) {
    customer(customerId: $customerId) {
      ...EssentialCustomerForTalent
    }
  }
  ${EssentialCustomerForTalentFragmentDoc}
`;
export const UpdateClaimsForTalentDocument = gql`
  mutation UpdateClaimsForTalent($updateClaimsInput: UpdateClaimsInput!) {
    updateClaims(updateClaimsInput: $updateClaimsInput)
  }
`;
export const GetEssentialCompanyWithSecurityForTalentDocument = gql`
  query GetEssentialCompanyWithSecurityForTalent($activeCompanyId: Int!) {
    company(companyId: $activeCompanyId) {
      security {
        ...EssentialSecurityForTalent
      }
    }
  }
  ${EssentialSecurityForTalentFragmentDoc}
`;
export const GetDemoForCandidateDemosIdDocument = gql`
  query GetDemoForCandidateDemosId($demoId: String!, $candidateId: String!) {
    demo(demoId: $demoId, candidateId: $candidateId) {
      ...DemoForCandidateDemosId
    }
  }
  ${DemoForCandidateDemosIdFragmentDoc}
`;
export const CreateDemoFromSpotDocument = gql`
  mutation CreateDemoFromSpot($spotId: String!, $candidateId: String!) {
    createDemoFromSpot(spotId: $spotId, candidateId: $candidateId) {
      ...DemoForCandidateDemosId
    }
  }
  ${DemoForCandidateDemosIdFragmentDoc}
`;
export const EndDemoDocument = gql`
  mutation EndDemo($demoId: String!) {
    endDemo(demoId: $demoId) {
      ...DemoForCandidateDemosId
    }
  }
  ${DemoForCandidateDemosIdFragmentDoc}
`;
export const GetDemoForCandidateDemosStartDocument = gql`
  query GetDemoForCandidateDemosStart($demoId: String!, $candidateId: String!) {
    demo(demoId: $demoId, candidateId: $candidateId) {
      demoId
      status
    }
  }
`;
export const GetRemotesForCandidateDocument = gql`
  query GetRemotesForCandidate($remoteId: String!) {
    remote(remoteId: $remoteId) {
      ...RemoteForCandidateRemotesId
    }
  }
  ${RemoteForCandidateRemotesIdFragmentDoc}
`;
export const GetLiveCodingForCandidateDocument = gql`
  query GetLiveCodingForCandidate($liveCodingId: Int!) {
    liveCoding(liveCodingId: $liveCodingId) {
      ...LiveCodingForCandidateRemotesId
    }
  }
  ${LiveCodingForCandidateRemotesIdFragmentDoc}
`;
export const AddLiveCodingVideoChatRoomForCandidateDocument = gql`
  mutation AddLiveCodingVideoChatRoomForCandidate($input: AddLiveCodingVideoChatRoomInput!) {
    addLiveCodingVideoChatRoom(input: $input) {
      ...LiveCodingVideoChatRoomForCandidateRemotesId
    }
  }
  ${LiveCodingVideoChatRoomForCandidateRemotesIdFragmentDoc}
`;
export const AddLiveCodingVideoChatConversationForCandidateDocument = gql`
  mutation AddLiveCodingVideoChatConversationForCandidate($input: AddLiveCodingVideoChatConversationInput!) {
    addLiveCodingVideoChatConversation(input: $input) {
      ...LiveCodingVideoChatConversationForCandidateRemotesId
    }
  }
  ${LiveCodingVideoChatConversationForCandidateRemotesIdFragmentDoc}
`;
export const JoinLiveCodingForCandidateDocument = gql`
  mutation JoinLiveCodingForCandidate($input: JoinLiveCodingInput!) {
    joinLiveCoding(input: $input) {
      ...LiveCodingForCandidateRemotesId
    }
  }
  ${LiveCodingForCandidateRemotesIdFragmentDoc}
`;
export const JoinVideoChatRoomCandidateDocument = gql`
  mutation JoinVideoChatRoomCandidate($input: JoinVideoChatRoomInput!) {
    joinVideoChatRoom(input: $input) {
      ...VideoChatRoomTokenForCandidateRemotesId
    }
  }
  ${VideoChatRoomTokenForCandidateRemotesIdFragmentDoc}
`;
export const JoinVideoChatConversationForCandidateDocument = gql`
  mutation JoinVideoChatConversationForCandidate($input: JoinVideoChatConversationInput!) {
    joinVideoChatConversation(input: $input) {
      ...VideoChatConversationTokenForCandidateRemotesId
    }
  }
  ${VideoChatConversationTokenForCandidateRemotesIdFragmentDoc}
`;
export const GetVideoChatConversationForCandidateDocument = gql`
  query GetVideoChatConversationForCandidate($input: VideoChatConversationInput!) {
    videoChatConversation(input: $input) {
      ...VideoChatConversationForCandidateRemotesId
    }
  }
  ${VideoChatConversationForCandidateRemotesIdFragmentDoc}
`;
export const GetScreeningForScreeningsIdDocument = gql`
  query GetScreeningForScreeningsId($screeningId: String!, $invitationCode: String) {
    screening(screeningId: $screeningId, invitationCode: $invitationCode) {
      ...ScreeningForScreeningsId
    }
  }
  ${ScreeningForScreeningsIdFragmentDoc}
`;
export const AcceptScreeningForScreeningsIdDocument = gql`
  mutation AcceptScreeningForScreeningsId($input: AcceptScreeningInput!) {
    acceptScreening(input: $input) {
      ...AcceptScreeningResponseForScreeningsId
    }
  }
  ${AcceptScreeningResponseForScreeningsIdFragmentDoc}
`;
export const StartSpotForScreeningsIdDocument = gql`
  mutation StartSpotForScreeningsId($input: StartSpotInput!) {
    startSpot(payload: $input) {
      id
      spotId
      status
    }
  }
`;
export const CreateDemoFromScreeningForScreeningIdDocument = gql`
  mutation CreateDemoFromScreeningForScreeningId($input: CreateDemoFromScreeningInput!) {
    createDemoFromScreening(input: $input) {
      ...DemoForCandidateDemosId
    }
  }
  ${DemoForCandidateDemosIdFragmentDoc}
`;
export const LinkSpotToSonarIntegrationForScreeningsIdDocument = gql`
  mutation LinkSpotToSonarIntegrationForScreeningsId($input: LinkSpotToSonarIntegrationInput!) {
    linkSpotToSonarIntegration(input: $input)
  }
`;
export const DeleteSpotForScreeningsIdDocument = gql`
  mutation DeleteSpotForScreeningsId($spotId: String!) {
    deleteSpot(spotId: $spotId)
  }
`;
export const GetSpotForEntryScreeningsTestsIdDocument = gql`
  query GetSpotForEntryScreeningsTestsId($id: String!, $ignoreInheritance: Boolean! = false) {
    spot(id: $id, withAnswers: false, ignoreInheritance: $ignoreInheritance) {
      ...SpotForEntryScreeningsTestsId
    }
  }
  ${SpotForEntryScreeningsTestsIdFragmentDoc}
`;
export const GetSpotForCandidateInterviewDocument = gql`
  query GetSpotForCandidateInterview($id: String!, $withAnswers: Boolean!, $ignoreInheritance: Boolean!) {
    spot(id: $id, withAnswers: $withAnswers, ignoreInheritance: $ignoreInheritance) {
      ...SpotForCandidateInterview
    }
  }
  ${SpotForCandidateInterviewFragmentDoc}
`;
export const GetSpotForEntryInterviewDocument = gql`
  query GetSpotForEntryInterview($id: String!, $withAnswers: Boolean!, $ignoreInheritance: Boolean!) {
    spot(id: $id, withAnswers: $withAnswers, ignoreInheritance: $ignoreInheritance) {
      ...SpotForEntryInterview
    }
  }
  ${SpotForEntryInterviewFragmentDoc}
`;
export const StartSpotDocument = gql`
  mutation StartSpot($payload: StartSpotInput!) {
    startSpot(payload: $payload) {
      ...SpotForCandidateInterview
    }
  }
  ${SpotForCandidateInterviewFragmentDoc}
`;
export const EndSpotDocument = gql`
  mutation EndSpot($spotId: String!) {
    endSpot(spotId: $spotId) {
      ...SpotForCandidateInterview
    }
  }
  ${SpotForCandidateInterviewFragmentDoc}
`;
export const GetInterviewDetailFromSharedLinkDocument = gql`
  query GetInterviewDetailFromSharedLink($spotId: String!, $linkHash: String!) {
    spotFromSharedLink(spotId: $spotId, linkHash: $linkHash) {
      ...InterviewDetailFromSharedLink
    }
  }
  ${InterviewDetailFromSharedLinkFragmentDoc}
`;
export const ListAssessmentsByCompanyIdForEmployeeAssessmentsDocument = gql`
  query ListAssessmentsByCompanyIdForEmployeeAssessments(
    $companyId: Int!
    $withCount: Boolean!
    $withDeleted: Boolean!
    $filters: AssessmentFilters!
    $sortMethod: AssessmentSortMethod!
    $size: Int!
    $offset: Int!
    $isDescending: Boolean!
  ) {
    listAssessmentsByCompanyId(
      companyId: $companyId
      withCount: $withCount
      withDeleted: $withDeleted
      filters: $filters
      sortMethod: $sortMethod
      size: $size
      offset: $offset
      isDescending: $isDescending
    ) {
      ...ListAssessmentsByCompanyIdForEmployeeAssessments
    }
  }
  ${ListAssessmentsByCompanyIdForEmployeeAssessmentsFragmentDoc}
`;
export const GetAssessmentSearchParamsForEmployeeAssessmentsSearchParamsDocument = gql`
  query GetAssessmentSearchParamsForEmployeeAssessmentsSearchParams($companyId: Int!) {
    assessmentListOptionsByFields(companyId: $companyId, withTalents: true, withViewers: true) {
      ...AssessmentListOptionsByFieldsResponseForEmployeeAssessmentsSearchParams
    }
  }
  ${AssessmentListOptionsByFieldsResponseForEmployeeAssessmentsSearchParamsFragmentDoc}
`;
export const CreateExamImmediatelyDocument = gql`
  mutation createExamImmediately($input: CreateExamImmediatelyRequestInput!) {
    createExamImmediately(input: $input) {
      examId
    }
  }
`;
export const DeleteAssessmentForEmployeeAssessmentsDocument = gql`
  mutation DeleteAssessmentForEmployeeAssessments($input: DeleteAssessmentRequestInput!) {
    deleteAssessment(input: $input)
  }
`;
export const UpdateAssessmentForEmployeeAssessmentsDocument = gql`
  mutation UpdateAssessmentForEmployeeAssessments($input: UpdateAssessmentRequestInput!) {
    updateAssessment(input: $input) {
      __typename
      id
      assessmentId
    }
  }
`;
export const UpdateAssessmentReportShareSettingsForEmployeeAssessmentsDocument = gql`
  mutation UpdateAssessmentReportShareSettingsForEmployeeAssessments($input: UpdateAssessmentRequestInput!) {
    updateAssessment(input: $input) {
      ...UpdateAssessmentReportShareSettingsResponseForEmployeeAssessments
    }
  }
  ${UpdateAssessmentReportShareSettingsResponseForEmployeeAssessmentsFragmentDoc}
`;
export const CreateAssessmentForAssessmentsCreateDocument = gql`
  mutation CreateAssessmentForAssessmentsCreate($input: CreateAssessmentRequestInput!) {
    createAssessment(input: $input) {
      assessmentId
    }
  }
`;
export const GetAssessmentForAssessmentsIdDetailEmployeeDocument = gql`
  query GetAssessmentForAssessmentsIdDetailEmployee(
    $assessmentId: String!
    $withCount: Boolean!
    $size: Int!
    $offset: Int!
    $isDescending: Boolean!
  ) {
    assessment(assessmentId: $assessmentId) {
      ...AssessmentForDetailEmployee
    }
    listBestExamsByAssessmentId(assessmentId: $assessmentId, withCount: $withCount, size: $size, offset: $offset, isDescending: $isDescending) {
      ...ListBestExamsResponseForAssessmentsIdDetail
    }
  }
  ${AssessmentForDetailEmployeeFragmentDoc}
  ${ListBestExamsResponseForAssessmentsIdDetailFragmentDoc}
`;
export const ListExamsRecursiveForAssessmentsIdDetailEmployeeDocument = gql`
  query ListExamsRecursiveForAssessmentsIdDetailEmployee($examId: String!) {
    listExamsRecursive(examId: $examId) {
      ...ListExamsRecursivePayloadForAssessmentsIdDetailEmployee
    }
  }
  ${ListExamsRecursivePayloadForAssessmentsIdDetailEmployeeFragmentDoc}
`;
export const DeleteAssessmentForAssessmentsIdDetailDocument = gql`
  mutation DeleteAssessmentForAssessmentsIdDetail($input: DeleteAssessmentRequestInput!) {
    deleteAssessment(input: $input)
  }
`;
export const UpdateAssessmentForAssessmentsIdDetailDocument = gql`
  mutation UpdateAssessmentForAssessmentsIdDetail($input: UpdateAssessmentRequestInput!) {
    updateAssessment(input: $input) {
      ...AssessmentForDetailEmployee
    }
  }
  ${AssessmentForDetailEmployeeFragmentDoc}
`;
export const UpdateAssessmentForAssessmentsIdUpdateDocument = gql`
  mutation UpdateAssessmentForAssessmentsIdUpdate($input: UpdateAssessmentRequestInput!) {
    updateAssessment(input: $input) {
      assessmentId
    }
  }
`;
export const CreateEvaluationMetricDocument = gql`
  mutation CreateEvaluationMetric($input: CreateEvaluationMetricInput!) {
    createEvaluationMetric(input: $input) {
      ...EvaluationMetricWithEmployeeResponse
    }
  }
  ${EvaluationMetricWithEmployeeResponseFragmentDoc}
`;
export const GetEvaluationMetricDocument = gql`
  query GetEvaluationMetric($metricId: Int!) {
    evaluationMetric(metricId: $metricId) {
      ...EvaluationMetricWithEmployeeResponse
    }
  }
  ${EvaluationMetricWithEmployeeResponseFragmentDoc}
`;
export const ListEvaluationMetricsByCompanyIdDocument = gql`
  query ListEvaluationMetricsByCompanyId($companyId: Int!, $size: Int!, $cursor: Int, $withCount: Boolean) {
    evaluationMetricsByCompany(companyId: $companyId, size: $size, cursor: $cursor, withCount: $withCount) {
      metrics {
        ...EvaluationMetricWithEmployeeResponse
      }
      pager {
        cursor
        hasNext
      }
      count
    }
  }
  ${EvaluationMetricWithEmployeeResponseFragmentDoc}
`;
export const UpdateEvaluationMetricDocument = gql`
  mutation UpdateEvaluationMetric($input: UpdateEvaluationMetricInput!) {
    updateEvaluationMetric(input: $input) {
      metricId
      title
      description
    }
  }
`;
export const DeleteEvaluationMetricDocument = gql`
  mutation DeleteEvaluationMetric($input: DeleteEvaluationMetricInput!) {
    deleteEvaluationMetric(input: $input)
  }
`;
export const DeleteMultiEvaluationMetricsDocument = gql`
  mutation DeleteMultiEvaluationMetrics($input: DeleteMultiEvaluationMetricsInput!) {
    deleteMultiEvaluationMetrics(input: $input)
  }
`;
export const CreateEvaluationMetricGroupDocument = gql`
  mutation CreateEvaluationMetricGroup($input: CreateEvaluationMetricGroupInput!) {
    createEvaluationMetricGroup(input: $input) {
      ...EvaluationMetricGroupsWithMetricResponse
    }
  }
  ${EvaluationMetricGroupsWithMetricResponseFragmentDoc}
`;
export const GetEvaluationMetricGroupDocument = gql`
  query GetEvaluationMetricGroup($metricGroupId: Int!) {
    evaluationMetricGroup(metricGroupId: $metricGroupId) {
      ...EvaluationMetricGroupsWithMetricResponse
    }
  }
  ${EvaluationMetricGroupsWithMetricResponseFragmentDoc}
`;
export const ListEvaluationMetricGroupsByCompanyIdDocument = gql`
  query ListEvaluationMetricGroupsByCompanyId($companyId: Int!, $size: Int!, $cursor: Int, $withCount: Boolean) {
    evaluationMetricGroupsByCompany(companyId: $companyId, size: $size, cursor: $cursor, withCount: $withCount) {
      metricGroups {
        ...EvaluationMetricGroupsWithMetricResponse
      }
      pager {
        cursor
        hasNext
      }
      count
    }
  }
  ${EvaluationMetricGroupsWithMetricResponseFragmentDoc}
`;
export const UpdateEvaluationMetricGroupDocument = gql`
  mutation UpdateEvaluationMetricGroup($input: UpdateEvaluationMetricGroupInput!) {
    updateEvaluationMetricGroup(input: $input) {
      metricGroupId
      title
      description
    }
  }
`;
export const DeleteEvaluationMetricGroupDocument = gql`
  mutation DeleteEvaluationMetricGroup($input: DeleteEvaluationMetricGroupInput!) {
    deleteEvaluationMetricGroup(input: $input)
  }
`;
export const AddEvaluationMetricToGroupsDocument = gql`
  mutation AddEvaluationMetricToGroups($input: AddEvaluationMetricToGroupsInput!) {
    addEvaluationMetricToGroups(input: $input) {
      ...EvaluationMetricGroupsWithMetricResponse
    }
  }
  ${EvaluationMetricGroupsWithMetricResponseFragmentDoc}
`;
export const AddEvaluationMetricsToGroupDocument = gql`
  mutation AddEvaluationMetricsToGroup($input: AddEvaluationMetricsToGroupInput!) {
    addEvaluationMetricsToGroup(input: $input) {
      ...EvaluationMetricGroupsWithMetricResponse
    }
  }
  ${EvaluationMetricGroupsWithMetricResponseFragmentDoc}
`;
export const DeleteEvaluationMetricsFromGroupDocument = gql`
  mutation DeleteEvaluationMetricsFromGroup($input: DeleteEvaluationMetricsFromGroupInput!) {
    deleteEvaluationMetricsFromGroup(input: $input)
  }
`;
export const GetExamForEmployeeExamIdReviewDocument = gql`
  query GetExamForEmployeeExamIdReview($examId: String!) {
    exam(examId: $examId) {
      ...ExamForEmployeeInterviewsIdReview
    }
  }
  ${ExamForEmployeeInterviewsIdReviewFragmentDoc}
`;
export const ListRecentScreeningsForDashboardScreeningSectionDocument = gql`
  query ListRecentScreeningsForDashboardScreeningSection($companyId: Int!, $size: Int!) {
    recentScreenings(companyId: $companyId, size: $size) {
      ...ScreeningForDashboardScreeningSection
    }
  }
  ${ScreeningForDashboardScreeningSectionFragmentDoc}
`;
export const ListRemotesForDashboardRemoteInterviewSectionDocument = gql`
  query ListRemotesForDashboardRemoteInterviewSection($companyId: Int!, $size: Int!) {
    remotesForSchedule(companyId: $companyId, size: $size) {
      ...RemoteForDashboardRemoteInterviewSection
    }
  }
  ${RemoteForDashboardRemoteInterviewSectionFragmentDoc}
`;
export const ListScreeningsByCompanyIdForDashboardScreeningStatsSectionDocument = gql`
  query ListScreeningsByCompanyIdForDashboardScreeningStatsSection($companyId: Int!, $screeningsByCompanyIdInput: ScreeningsByCompanyIdInput!) {
    spotStatsByCompanyId(companyId: $companyId) {
      ...SpotStatsForDashboardScreeningStatsSection
    }
    screeningsByCompanyId(input: $screeningsByCompanyIdInput) {
      ...ScreeningsByCompanyIdResponseForDashboardScreeningStatsSection
    }
  }
  ${SpotStatsForDashboardScreeningStatsSectionFragmentDoc}
  ${ScreeningsByCompanyIdResponseForDashboardScreeningStatsSectionFragmentDoc}
`;
export const GetScreeningForDashboardScreeningStatsSectionDocument = gql`
  query GetScreeningForDashboardScreeningStatsSection($screeningId: String!, $withSpotStats: Boolean!) {
    screening(screeningId: $screeningId, invitationCode: $screeningId, withSpotStats: $withSpotStats) {
      ...ScreeningForDashboardScreeningStatsSection
    }
  }
  ${ScreeningForDashboardScreeningStatsSectionFragmentDoc}
`;
export const AcceptEmployeeInvitationDocument = gql`
  mutation AcceptEmployeeInvitation($invitationId: String!, $employeeId: String!) {
    acceptEmployeeInvitation(invitationId: $invitationId, employeeId: $employeeId) {
      companyId
      role
    }
  }
`;
export const CompanyByEmployeeInvitationForEmployeeInvitationIdDocument = gql`
  query CompanyByEmployeeInvitationForEmployeeInvitationId($invitationId: String!) {
    companyByEmployeeInvitation(invitationId: $invitationId) {
      ...CompanyForEmployeeInvitationsId
    }
    employeeInvitation(invitationId: $invitationId) {
      ...InvitationForEmployeeInvitationsId
    }
  }
  ${CompanyForEmployeeInvitationsIdFragmentDoc}
  ${InvitationForEmployeeInvitationsIdFragmentDoc}
`;
export const GetMultiChoicePackageForQuestionQuizIdDocument = gql`
  query GetMultiChoicePackageForQuestionQuizId($id: Int!, $version: String!, $withAnswers: Boolean!) {
    multiChoicePackage(id: $id, version: $version, withAnswers: $withAnswers) {
      ...GetMultiChoicePackageForQuestionQuizIdPackage
    }
  }
  ${GetMultiChoicePackageForQuestionQuizIdPackageFragmentDoc}
`;
export const ListQuestionsDocument = gql`
  query ListQuestions($questionsInput: QuestionsInput!) {
    questions(questionsInput: $questionsInput) {
      questions {
        ...QuestionForListQuestion
      }
      count
      hasNext
    }
  }
  ${QuestionForListQuestionFragmentDoc}
`;
export const ListQuestionsForQuestionsPageDocument = gql`
  query ListQuestionsForQuestionsPage($questionsInput: QuestionsInput!) {
    questions(questionsInput: $questionsInput) {
      ...QuestionsResponseForListQuestionsPage
    }
  }
  ${QuestionsResponseForListQuestionsPageFragmentDoc}
`;
export const DeleteAlgorithmQuestionDocument = gql`
  mutation DeleteAlgorithmQuestion($input: DeleteAlgorithmQuestionInput!) {
    deleteAlgorithmQuestion(input: $input)
  }
`;
export const DeleteMultiChoicePackageDocument = gql`
  mutation DeleteMultiChoicePackage($input: DeleteMultiChoicePackageInput!) {
    deleteMultiChoicePackage(input: $input)
  }
`;
export const DeleteProjectQuestionDocument = gql`
  mutation DeleteProjectQuestion($input: DeleteProjectQuestionInput!) {
    deleteProjectQuestion(input: $input)
  }
`;
export const DeleteFreepadQuestionDocument = gql`
  mutation DeleteFreepadQuestion($input: DeleteFreepadQuestionInput!) {
    deleteFreepadQuestion(input: $input)
  }
`;
export const DeleteSystemDesignQuestionDocument = gql`
  mutation DeleteSystemDesignQuestion($input: DeleteSystemDesignQuestionInput!) {
    deleteSystemDesignQuestion(input: $input)
  }
`;
export const GetHierarchicalSkillTagsForQuestionsDocument = gql`
  query GetHierarchicalSkillTagsForQuestions($input: HierarchicalSkillTagsInput!) {
    hierarchicalSkillTags(input: $input) {
      ...HierarchicalSkillTagsResponseForQuestions
    }
  }
  ${HierarchicalSkillTagsResponseForQuestionsFragmentDoc}
`;
export const GetAlgorithmQuestionForQuestionsChallengeIdDocument = gql`
  query GetAlgorithmQuestionForQuestionsChallengeId(
    $questionId: Int!
    $questionVersion: String!
    $withAnswers: Boolean!
    $withPerformanceTcs: Boolean!
  ) {
    algorithmQuestion(
      questionId: $questionId
      questionVersion: $questionVersion
      withAnswers: $withAnswers
      withPerformanceTcs: $withPerformanceTcs
    ) {
      ...AlgorithmQuestionForQuestionsChallengeId
    }
  }
  ${AlgorithmQuestionForQuestionsChallengeIdFragmentDoc}
`;
export const FreepadQuestionForQuestionDetailDocument = gql`
  query FreepadQuestionForQuestionDetail($questionId: Int!, $questionVersion: String!) {
    freepadQuestion(questionId: $questionId, questionVersion: $questionVersion) {
      ...FreepadQuestionDetail
    }
  }
  ${FreepadQuestionDetailFragmentDoc}
`;
export const ListQuestionsPackagesForQuestionsPackagesDocument = gql`
  query ListQuestionsPackagesForQuestionsPackages(
    $size: Int!
    $offset: Int!
    $isDescending: Boolean!
    $sortMethod: QuestionPackageSortMethod!
    $filters: QuestionPackageFilters!
  ) {
    questionPackages(size: $size, offset: $offset, isDescending: $isDescending, sortMethod: $sortMethod, filters: $filters) {
      ...QuestionPackagesResponseForQuestionsPackages
    }
  }
  ${QuestionPackagesResponseForQuestionsPackagesFragmentDoc}
`;
export const GetQuestionPackageForQuestionsPackagesDetailDocument = gql`
  query GetQuestionPackageForQuestionsPackagesDetail($questionPackageId: String!) {
    questionPackage(questionPackageId: $questionPackageId) {
      ...QuestionPackageForQuestionsPackagesDetail
    }
  }
  ${QuestionPackageForQuestionsPackagesDetailFragmentDoc}
`;
export const ProjectQuestionDetailDocument = gql`
  query ProjectQuestionDetail($questionId: Int!, $questionVersion: String!, $withAnswers: Boolean!) {
    projectQuestion(questionId: $questionId, questionVersion: $questionVersion, withAnswers: $withAnswers) {
      ...ProjectQuestionDetail
    }
  }
  ${ProjectQuestionDetailFragmentDoc}
`;
export const ProjectAnswerFileBodyDocument = gql`
  query ProjectAnswerFileBody($answerId: Int!, $filePath: String!) {
    answerFileBody(answerId: $answerId, filePath: $filePath) {
      fileBody
    }
  }
`;
export const SystemDesignQuestionForQuestionDetailDocument = gql`
  query SystemDesignQuestionForQuestionDetail($questionId: Int!, $withAnswers: Boolean!) {
    systemDesignQuestion(questionId: $questionId, withAnswers: $withAnswers) {
      ...SystemDesignQuestionDetail
    }
  }
  ${SystemDesignQuestionDetailFragmentDoc}
`;
export const ListRemotesByCompanyIdForRemotesInterviewListDocument = gql`
  query ListRemotesByCompanyIdForRemotesInterviewList(
    $companyId: Int!
    $withCount: Boolean!
    $withDeleted: Boolean!
    $filters: RemoteFilterInput!
    $sortMethod: RemoteSortMethod!
    $size: Int!
    $offset: Int!
    $isDescending: Boolean!
  ) {
    remotesByCompanyId(
      companyId: $companyId
      withCount: $withCount
      withDeleted: $withDeleted
      filters: $filters
      sortMethod: $sortMethod
      size: $size
      offset: $offset
      isDescending: $isDescending
    ) {
      remotes {
        ...RemotesForRemotesInterviewList
      }
      count
      hasNext
    }
  }
  ${RemotesForRemotesInterviewListFragmentDoc}
`;
export const GetSearchItemsForRemotesDocument = gql`
  query GetSearchItemsForRemotes($companyId: Int!, $cursorSeconds: Int, $size: Int!, $withCount: Boolean!, $creator: Boolean!) {
    remoteTagsByCompanyId(companyId: $companyId, cursorSeconds: $cursorSeconds, size: $size, withCount: $withCount) {
      tags {
        name
      }
      count
      pager {
        cursorSeconds
        hasNext
      }
    }
    issuersForRemoteByFields(creator: $creator, viewer: false, companyId: $companyId) {
      creators {
        ...UserForRemotes
      }
    }
  }
  ${UserForRemotesFragmentDoc}
`;
export const StartRemoteForInterviewListDocument = gql`
  mutation StartRemoteForInterviewList($input: StartRemoteInput!) {
    startRemote(input: $input) {
      id
      remoteId
      status
    }
  }
`;
export const CreateRemoteForRemoteInterviewsCreateDocument = gql`
  mutation CreateRemoteForRemoteInterviewsCreate($createRemoteInput: CreateRemoteInput!) {
    createRemote(input: $createRemoteInput) {
      id
      name
    }
  }
`;
export const GetRemotesDocument = gql`
  query GetRemotes($remoteId: String!) {
    remote(remoteId: $remoteId) {
      ...RemoteForRemotesId
    }
  }
  ${RemoteForRemotesIdFragmentDoc}
`;
export const GetLiveCodingForEmployeeDocument = gql`
  query GetLiveCodingForEmployee($liveCodingId: Int!) {
    liveCoding(liveCodingId: $liveCodingId) {
      ...LiveCodingForCandidateRemotesId
    }
  }
  ${LiveCodingForCandidateRemotesIdFragmentDoc}
`;
export const DeleteLiveCodingSessionDocument = gql`
  mutation DeleteLiveCodingSession($input: DeleteLiveCodingSessionInput!) {
    deleteLiveCodingSession(input: $input)
  }
`;
export const EndRemoteInterviewDocument = gql`
  mutation EndRemoteInterview($input: EndRemoteInput!) {
    endRemote(input: $input) {
      ...RemoteForRemotesId
    }
  }
  ${RemoteForRemotesIdFragmentDoc}
`;
export const GetLiveCodingTemplateCodesDocument = gql`
  query GetLiveCodingTemplateCodes($questionId: Int!, $questionVersion: String!) {
    liveCodingTemplateCodes(questionId: $questionId, questionVersion: $questionVersion) {
      ...LiveCodingTemplateCodesForRemotesId
    }
  }
  ${LiveCodingTemplateCodesForRemotesIdFragmentDoc}
`;
export const AddLiveCodingSessionDocument = gql`
  mutation AddLiveCodingSession($input: AddLiveCodingSessionInput!) {
    addLiveCodingSession(input: $input) {
      ...LiveCodingSessionForRemotesId
    }
  }
  ${LiveCodingSessionForRemotesIdFragmentDoc}
`;
export const JoinLiveCodingForEmployeeDocument = gql`
  mutation JoinLiveCodingForEmployee($input: JoinLiveCodingInput!) {
    joinLiveCoding(input: $input) {
      ...LiveCodingForRemotesId
    }
  }
  ${LiveCodingForRemotesIdFragmentDoc}
`;
export const StartLiveCodingDocument = gql`
  mutation StartLiveCoding($input: StartLiveCodingInput!) {
    startLiveCoding(input: $input) {
      ...LiveCodingForRemotesId
    }
  }
  ${LiveCodingForRemotesIdFragmentDoc}
`;
export const ListEvaluationMetricsForRemotesIdDocument = gql`
  query ListEvaluationMetricsForRemotesId($companyId: Int!, $size: Int!, $cursor: Int, $withCount: Boolean) {
    evaluationMetricsByCompany(companyId: $companyId, size: $size, cursor: $cursor, withCount: $withCount) {
      metrics {
        ...EvaluationMetricsForRemotesId
      }
      count
      pager {
        cursor
        hasNext
      }
    }
  }
  ${EvaluationMetricsForRemotesIdFragmentDoc}
`;
export const AddLiveCodingVideoChatRoomForEmployeeRemotesIdDocument = gql`
  mutation AddLiveCodingVideoChatRoomForEmployeeRemotesId($input: AddLiveCodingVideoChatRoomInput!) {
    addLiveCodingVideoChatRoom(input: $input) {
      ...LiveCodingVideoChatRoomForEmployeeRemotesId
    }
  }
  ${LiveCodingVideoChatRoomForEmployeeRemotesIdFragmentDoc}
`;
export const AddLiveCodingVideoChatConversationForEmployeeRemotesIdDocument = gql`
  mutation AddLiveCodingVideoChatConversationForEmployeeRemotesId($input: AddLiveCodingVideoChatConversationInput!) {
    addLiveCodingVideoChatConversation(input: $input) {
      ...LiveCodingVideoChatConversationForEmployeeRemotesId
    }
  }
  ${LiveCodingVideoChatConversationForEmployeeRemotesIdFragmentDoc}
`;
export const JoinVideoChatRoomForEmployeeDocument = gql`
  mutation JoinVideoChatRoomForEmployee($input: JoinVideoChatRoomInput!) {
    joinVideoChatRoom(input: $input) {
      ...VideoChatRoomTokenForEmployeeRemotesId
    }
  }
  ${VideoChatRoomTokenForEmployeeRemotesIdFragmentDoc}
`;
export const StartRecordingVideoChatForEmployeeDocument = gql`
  mutation StartRecordingVideoChatForEmployee($input: StartRecordingVideoChatInput!) {
    startRecordingVideoChat(input: $input)
  }
`;
export const StopRecordingVideoChatForEmployeeDocument = gql`
  mutation StopRecordingVideoChatForEmployee($input: StopRecordingVideoChatInput!) {
    stopRecordingVideoChat(input: $input)
  }
`;
export const JoinVideoChatConversationForEmployeeDocument = gql`
  mutation JoinVideoChatConversationForEmployee($input: JoinVideoChatConversationInput!) {
    joinVideoChatConversation(input: $input) {
      ...VideoChatConversationTokenForEmployeeRemotesId
    }
  }
  ${VideoChatConversationTokenForEmployeeRemotesIdFragmentDoc}
`;
export const GetVideoChatConversationForEmployeeDocument = gql`
  query GetVideoChatConversationForEmployee($input: VideoChatConversationInput!) {
    videoChatConversation(input: $input) {
      ...VideoChatConversationForEmployeeRemotesId
    }
  }
  ${VideoChatConversationForEmployeeRemotesIdFragmentDoc}
`;
export const GetRemoteForRemoteInterviewDocument = gql`
  query GetRemoteForRemoteInterview($remoteId: String!) {
    remote(remoteId: $remoteId) {
      ...RemoteForRemoteInterviewDetail
    }
  }
  ${RemoteForRemoteInterviewDetailFragmentDoc}
`;
export const StartRemoteForRemoteInterviewDetailDocument = gql`
  mutation StartRemoteForRemoteInterviewDetail($input: StartRemoteInput!) {
    startRemote(input: $input) {
      ...RemoteForRemoteInterviewDetail
    }
  }
  ${RemoteForRemoteInterviewDetailFragmentDoc}
`;
export const EvaluateRemoteForRemoteInterviewDetailDocument = gql`
  mutation EvaluateRemoteForRemoteInterviewDetail($input: EvaluateRemoteInput!) {
    evaluateRemote(input: $input) {
      ...RemoteForRemoteInterviewDetail
    }
  }
  ${RemoteForRemoteInterviewDetailFragmentDoc}
`;
export const DeleteRemoteForRemoteInterviewDetailDocument = gql`
  mutation DeleteRemoteForRemoteInterviewDetail($input: DeleteRemoteInput!) {
    deleteRemote(input: $input)
  }
`;
export const GetRemotesForRemotesEvaluateDocument = gql`
  query GetRemotesForRemotesEvaluate($remoteId: String!) {
    remote(remoteId: $remoteId) {
      remoteId
    }
  }
`;
export const ReviewRemoteRemotesEvaluateDocument = gql`
  mutation ReviewRemoteRemotesEvaluate($reviewRemoteInput: ReviewRemoteInput!) {
    reviewRemote(input: $reviewRemoteInput) {
      id
      remoteId
    }
  }
`;
export const ListEvaluationMetricsForRemotesEvaluateDocument = gql`
  query ListEvaluationMetricsForRemotesEvaluate($companyId: Int!, $size: Int!, $cursor: Int, $withCount: Boolean) {
    evaluationMetricsByCompany(companyId: $companyId, size: $size, cursor: $cursor, withCount: $withCount) {
      metrics {
        ...EvaluationMetricsForRemotesEvaluate
      }
      count
      pager {
        cursor
        hasNext
      }
    }
  }
  ${EvaluationMetricsForRemotesEvaluateFragmentDoc}
`;
export const UpdateRemoteForRemoteInterviewsUpdateDocument = gql`
  mutation UpdateRemoteForRemoteInterviewsUpdate($updateRemoteInput: UpdateRemoteInput!) {
    updateRemote(input: $updateRemoteInput) {
      id
      name
    }
  }
`;
export const ListScreeningsByCompanyIdForScreeningsDocument = gql`
  query ListScreeningsByCompanyIdForScreenings($input: ScreeningsByCompanyIdInput!) {
    screeningsByCompanyId(input: $input) {
      ...ScreeningsByCompanyIdResponseForScreenings
    }
  }
  ${ScreeningsByCompanyIdResponseForScreeningsFragmentDoc}
`;
export const GetSpotTagsForScreeningsDocument = gql`
  query GetSpotTagsForScreenings($spotTagsByCompanyId: SpotTagsByCompanyIdInput!) {
    spotTagsByCompanyId(spotTagsByCompanyId: $spotTagsByCompanyId) {
      ...SpotTagByCompanyIdForScreenings
    }
  }
  ${SpotTagByCompanyIdForScreeningsFragmentDoc}
`;
export const UpdateScreeningForScreeningsDocument = gql`
  mutation UpdateScreeningForScreenings($input: UpdateScreeningInput!) {
    updateScreening(input: $input) {
      ...ScreeningForScreenings
    }
  }
  ${ScreeningForScreeningsFragmentDoc}
`;
export const DeleteScreeningForScreeningsDocument = gql`
  mutation DeleteScreeningForScreenings($input: DeleteScreeningInput!) {
    deleteScreening(input: $input)
  }
`;
export const DeleteMultiScreeningsForScreeningsDocument = gql`
  mutation DeleteMultiScreeningsForScreenings($input: DeleteMultiScreeningsInput!) {
    deleteMultiScreenings(input: $input)
  }
`;
export const UpdateTagsToScreeningsDocument = gql`
  mutation UpdateTagsToScreenings($input: UpdateTagsToScreeningsInput!) {
    updateTagsToScreenings(input: $input) {
      ...ScreeningForScreenings
    }
  }
  ${ScreeningForScreeningsFragmentDoc}
`;
export const InviteCandidateToScreeningForScreeningsDocument = gql`
  mutation InviteCandidateToScreeningForScreenings($input: InviteCandidatesToScreeningInput!) {
    inviteCandidateToScreening(input: $input) {
      invitees {
        screeningId
        status
        email
      }
    }
  }
`;
export const GetScreeningForScreeningsDocument = gql`
  query GetScreeningForScreenings($screeningId: String!, $invitationCode: String) {
    screening(screeningId: $screeningId, invitationCode: $invitationCode) {
      ...ScreeningForScreenings
    }
  }
  ${ScreeningForScreeningsFragmentDoc}
`;
export const ReplicateScreeningForScreeningsDocument = gql`
  mutation ReplicateScreeningForScreenings($input: ReplicateScreening!) {
    replicateScreening(input: $input) {
      ...ScreeningForScreenings
    }
  }
  ${ScreeningForScreeningsFragmentDoc}
`;
export const CreateScreeningForScreeningsCreateDocument = gql`
  mutation CreateScreeningForScreeningsCreate($input: CreateScreeningInput!) {
    createScreening(input: $input) {
      id
      screeningId
      name
    }
  }
`;
export const UpdateScreeningForScreeningsIdUpdateDocument = gql`
  mutation UpdateScreeningForScreeningsIdUpdate($input: UpdateScreeningInput!) {
    updateScreening(input: $input) {
      id
      screeningId
      name
    }
  }
`;
export const GetTestResultTableDocument = gql`
  query GetTestResultTable($id: String!) {
    testResultTable(id: $id) {
      status
      reason
      downloadUrl
    }
  }
`;
export const GetInterviewsDocument = gql`
  query GetInterviews(
    $spotTagsByCompanyId: SpotTagsByCompanyIdInput!
    $screeningVariablesByCompanyId: ScreeningVariablesByCompanyIdInput!
    $issuers: IssuersInput!
  ) {
    spotTagsByCompanyId(spotTagsByCompanyId: $spotTagsByCompanyId) {
      ...GetInterviewsForSpotTagsByCompanyIdResponse
    }
    screeningVariablesByCompanyId(screeningVariablesByCompanyId: $screeningVariablesByCompanyId) {
      ...GetInterviewsForScreeningVariablesByCompanyIdResponse
    }
    issuers(issuersInput: $issuers) {
      creators {
        ...GetInterviewsForCreator
      }
      screenings {
        ...ScreeningForScreeningsTests
      }
    }
  }
  ${GetInterviewsForSpotTagsByCompanyIdResponseFragmentDoc}
  ${GetInterviewsForScreeningVariablesByCompanyIdResponseFragmentDoc}
  ${GetInterviewsForCreatorFragmentDoc}
  ${ScreeningForScreeningsTestsFragmentDoc}
`;
export const ListSpotsByCompanyIdForInterviewsDocument = gql`
  query ListSpotsByCompanyIdForInterviews($listByCompanyId: SpotsByCompanyIdInput!) {
    spotsByCompanyId(spotsByCompanyId: $listByCompanyId) {
      ...SpotsByCompanyIdResponseForInterview
    }
  }
  ${SpotsByCompanyIdResponseForInterviewFragmentDoc}
`;
export const UpdateSpotTagsDocument = gql`
  mutation UpdateSpotTags($updateSpotInput: UpdateSpotInput!) {
    updateSpot(input: $updateSpotInput) {
      ...SpotForEmployeeInterviews
    }
  }
  ${SpotForEmployeeInterviewsFragmentDoc}
`;
export const EvaluateInterviewForInterviewsDocument = gql`
  mutation EvaluateInterviewForInterviews($spotId: String!, $employeeId: String!, $isPassed: Boolean!, $evaluationComment: String!) {
    evaluateSpot(spotId: $spotId, employeeId: $employeeId, isPassed: $isPassed, evaluationComment: $evaluationComment) {
      ...SpotForEmployeeInterviews
    }
  }
  ${SpotForEmployeeInterviewsFragmentDoc}
`;
export const DeleteMultiSpotsForScreeningsTestsDocument = gql`
  mutation DeleteMultiSpotsForScreeningsTests($input: DeleteMultiSpotsInput!) {
    deleteMultiSpots(input: $input)
  }
`;
export const UpdateTagsToSpotsForScreeningsTestsDocument = gql`
  mutation UpdateTagsToSpotsForScreeningsTests($input: UpdateTagsToSpotsInput!) {
    updateTagsToSpots(input: $input) {
      ...SpotForEmployeeInterviews
    }
  }
  ${SpotForEmployeeInterviewsFragmentDoc}
`;
export const EvaluateMultiSpotsForScreeningsTestsDocument = gql`
  mutation EvaluateMultiSpotsForScreeningsTests($input: EvaluateMultiSpotsInput!) {
    evaluateMultiSpots(input: $input) {
      ...SpotForEmployeeInterviews
    }
  }
  ${SpotForEmployeeInterviewsFragmentDoc}
`;
export const GetInterviewDetailDocument = gql`
  query GetInterviewDetail($id: String!, $withAnswers: Boolean!, $ignoreInheritance: Boolean!) {
    spot(id: $id, withAnswers: $withAnswers, ignoreInheritance: $ignoreInheritance) {
      ...InterviewDetail
    }
  }
  ${InterviewDetailFragmentDoc}
`;
export const EvaluateInterviewDocument = gql`
  mutation EvaluateInterview($spotId: String!, $employeeId: String!, $isPassed: Boolean!, $evaluationComment: String!) {
    evaluateSpot(spotId: $spotId, employeeId: $employeeId, isPassed: $isPassed, evaluationComment: $evaluationComment) {
      ...InterviewDetail
    }
  }
  ${InterviewDetailFragmentDoc}
`;
export const RecreateInterviewDocument = gql`
  mutation RecreateInterview($input: RecreateSpotInput!) {
    recreateSpot(input: $input) {
      ...InterviewDetail
    }
  }
  ${InterviewDetailFragmentDoc}
`;
export const DeleteInterviewDocument = gql`
  mutation DeleteInterview($spotId: String!) {
    deleteSpot(spotId: $spotId)
  }
`;
export const ExportTestReportPdfForInterviewsIdDetailDocument = gql`
  mutation ExportTestReportPdfForInterviewsIdDetail($spotId: String!, $exportLanguage: ExportLanguage!) {
    exportTestReportPdf(spotId: $spotId, exportLanguage: $exportLanguage) {
      id
    }
  }
`;
export const TestReportPdfForInterviewsIdDetailDocument = gql`
  query TestReportPdfForInterviewsIdDetail($id: String!) {
    testReportPdf(id: $id) {
      status
      reason
      downloadUrl
    }
  }
`;
export const GenerateSpotSharedLinkDocument = gql`
  mutation GenerateSpotSharedLink($input: GenerateSpotSharedLinkInput!) {
    generateSpotSharedLink(input: $input) {
      ...SpotSharedLinkForScreeningsTestsIdDetail
    }
  }
  ${SpotSharedLinkForScreeningsTestsIdDetailFragmentDoc}
`;
export const UpdateSpotForInterviewsIdDetailDocument = gql`
  mutation UpdateSpotForInterviewsIdDetail($input: UpdateSpotInput!) {
    updateSpot(input: $input) {
      ...InterviewDetail
    }
  }
  ${InterviewDetailFragmentDoc}
`;
export const GetSpotForRetakeInterviewsIdDetailDocument = gql`
  query GetSpotForRetakeInterviewsIdDetail($id: String!, $withAnswers: Boolean!, $ignoreInheritance: Boolean!) {
    spot(id: $id, withAnswers: $withAnswers, ignoreInheritance: $ignoreInheritance) {
      ...SpotForRetakeInterviewsIdDetail
    }
  }
  ${SpotForRetakeInterviewsIdDetailFragmentDoc}
`;
export const GetSpotForEmployeeInterviewsIdReviewDocument = gql`
  query GetSpotForEmployeeInterviewsIdReview($id: String!, $withAnswers: Boolean!) {
    spot(id: $id, withAnswers: $withAnswers) {
      ...SpotForEmployeeInterviewsIdReview
    }
  }
  ${SpotForEmployeeInterviewsIdReviewFragmentDoc}
`;
export const CreateRemoteInterviewTemplateDocument = gql`
  mutation CreateRemoteInterviewTemplate($CreateRemoteInterviewTemplateInput: CreateRemoteInterviewTemplateInput!) {
    createRemoteInterviewTemplate(input: $CreateRemoteInterviewTemplateInput) {
      id
      templateId
    }
  }
`;
export const UpdateRemoteInterviewTemplateDocument = gql`
  mutation UpdateRemoteInterviewTemplate($UpdateRemoteInterviewTemplateInput: UpdateRemoteInterviewTemplateInput!) {
    updateRemoteInterviewTemplate(input: $UpdateRemoteInterviewTemplateInput) {
      id
      templateId
    }
  }
`;
export const GetExamForAssessmentsIdDocument = gql`
  query GetExamForAssessmentsId($examId: String!) {
    exam(examId: $examId) {
      ...ExamForAssessmentsId
    }
  }
  ${ExamForAssessmentsIdFragmentDoc}
`;
export const GetExamForEntryAssessmentsIdDocument = gql`
  query GetExamForEntryAssessmentsId($examId: String!) {
    exam(examId: $examId) {
      ...ExamForEntryAssessmentsId
    }
  }
  ${ExamForEntryAssessmentsIdFragmentDoc}
`;
export const StartExamDocument = gql`
  mutation StartExam($input: StartExamRequestInput!) {
    startExam(input: $input) {
      ...ExamForAssessmentsId
    }
  }
  ${ExamForAssessmentsIdFragmentDoc}
`;
export const EndExamDocument = gql`
  mutation EndExam($input: EndExamRequestInput!) {
    endExam(input: $input) {
      ...ExamForAssessmentsId
    }
  }
  ${ExamForAssessmentsIdFragmentDoc}
`;
export const GetAssessmentForAssessmentsIdDetailTalentDocument = gql`
  query GetAssessmentForAssessmentsIdDetailTalent(
    $assessmentId: String!
    $withCount: Boolean!
    $size: Int!
    $offset: Int!
    $isDescending: Boolean!
  ) {
    assessment(assessmentId: $assessmentId) {
      ...AssessmentForDetailTalent
    }
    listBestExamsByAssessmentId(assessmentId: $assessmentId, withCount: $withCount, size: $size, offset: $offset, isDescending: $isDescending) {
      ...ListBestExamsResponseForAssessmentsIdDetailTalent
    }
  }
  ${AssessmentForDetailTalentFragmentDoc}
  ${ListBestExamsResponseForAssessmentsIdDetailTalentFragmentDoc}
`;
export const ListExamsRecursiveForAssessmentsIdDetailTalentDocument = gql`
  query ListExamsRecursiveForAssessmentsIdDetailTalent($examId: String!) {
    listExamsRecursive(examId: $examId) {
      ...ListExamsRecursivePayloadForAssessmentsIdDetailTalent
    }
  }
  ${ListExamsRecursivePayloadForAssessmentsIdDetailTalentFragmentDoc}
`;
export const RecreateExamForAssessmentsIdDetailTalentDocument = gql`
  mutation RecreateExamForAssessmentsIdDetailTalent($input: RecreateExamRequestInput!) {
    recreateExam(input: $input) {
      __typename
      examId
    }
  }
`;
export const GetTailExamForAssessmentIdDetailTalentDocument = gql`
  query GetTailExamForAssessmentIdDetailTalent($examId: String!) {
    tailExam(examId: $examId) {
      __typename
      examId
      status
    }
  }
`;
export const AcceptTalentInvitationDocument = gql`
  mutation AcceptTalentInvitation($input: AcceptTalentInvitationInput!) {
    acceptTalentInvitation(input: $input) {
      talentInvitationId
      companyId
    }
  }
`;
export const GetCompanyByTalentInvitationForTalentInvitationIdDocument = gql`
  query GetCompanyByTalentInvitationForTalentInvitationId($invitationId: String!) {
    companyByTalentInvitationId(invitationId: $invitationId) {
      ...CompanyForTalentInvitationsId
    }
    talentInvitation(invitationId: $invitationId) {
      ...InvitationForTalentInvitationsId
    }
  }
  ${CompanyForTalentInvitationsIdFragmentDoc}
  ${InvitationForTalentInvitationsIdFragmentDoc}
`;
export const CreateAlgorithmQuestionDocument = gql`
  mutation CreateAlgorithmQuestion($createQuestionInput: CreateAlgorithmQuestionInput!) {
    createAlgorithmQuestion(createQuestionInput: $createQuestionInput) {
      ...AlgorithmQuestionForAlgorithmResourceEditor
    }
  }
  ${AlgorithmQuestionForAlgorithmResourceEditorFragmentDoc}
`;
export const UpdateAlgorithmQuestionDocument = gql`
  mutation UpdateAlgorithmQuestion($updateQuestionInput: UpdateAlgorithmQuestionInput!) {
    updateAlgorithmQuestion(updateQuestionInput: $updateQuestionInput) {
      ...AlgorithmQuestionForAlgorithmResourceEditor
    }
  }
  ${AlgorithmQuestionForAlgorithmResourceEditorFragmentDoc}
`;
export const RequestReviewAlgorithmQuestionForAlgorithmResourceEditorDocument = gql`
  mutation RequestReviewAlgorithmQuestionForAlgorithmResourceEditor($requestReviewQuestionInput: RequestReviewAlgorithmQuestionInput!) {
    requestReviewAlgorithmQuestion(requestReviewQuestionInput: $requestReviewQuestionInput) {
      ...AlgorithmQuestionForAlgorithmResourceEditor
    }
  }
  ${AlgorithmQuestionForAlgorithmResourceEditorFragmentDoc}
`;
export const GetAlgorithmQuestionForAlgorithmResourceEditorDocument = gql`
  query GetAlgorithmQuestionForAlgorithmResourceEditor(
    $questionId: Int!
    $questionVersion: String!
    $withAnswers: Boolean!
    $withPerformanceTcs: Boolean!
  ) {
    algorithmQuestion(
      questionId: $questionId
      questionVersion: $questionVersion
      withAnswers: $withAnswers
      withPerformanceTcs: $withPerformanceTcs
    ) {
      ...AlgorithmQuestionForAlgorithmResourceEditor
    }
  }
  ${AlgorithmQuestionForAlgorithmResourceEditorFragmentDoc}
`;
export const AlgorithmTemplateCodeDocument = gql`
  query AlgorithmTemplateCode($signature: String!, $variant: AlgorithmQuestionVariant!) {
    algorithmTemplateCode(signature: $signature, variant: $variant) {
      python3
      go
      javascript
      php
      ruby
      typescript
      cpp
      kotlin
      java
      swift
      scala
      rust
      sqlite
      pgsql
      mysql
      dart
      csharp
      c
      perl
    }
  }
`;
export const AlgorithmRunCodeDocument = gql`
  query AlgorithmRunCode(
    $signature: String!
    $runtime: String!
    $codeBody: String!
    $tcInput: String!
    $tcOutput: String!
    $variant: AlgorithmQuestionVariant!
  ) {
    algorithmRunCode(runtime: $runtime, codeBody: $codeBody, tcInput: $tcInput, tcOutput: $tcOutput, signature: $signature, variant: $variant) {
      output
      log
      status
      expected
      isAccepted
      performance
      maxMemory
    }
  }
`;
export const AlgorithmProfileCodeDocument = gql`
  query AlgorithmProfileCode(
    $signature: String!
    $runtime: String!
    $codeBody: String!
    $tcInput: String!
    $tcOutput: String!
    $variant: AlgorithmQuestionVariant!
  ) {
    algorithmProfileCode(
      runtime: $runtime
      codeBody: $codeBody
      tcInput: $tcInput
      tcOutput: $tcOutput
      signature: $signature
      variant: $variant
    ) {
      output
      log
      status
      performanceAvgString
      performanceStdString
      performanceMedString
      maxMemoryAvg
      maxMemoryStd
      maxMemoryMed
      machineOs
      machineCpu
      machineMemory
      isAccepted
    }
  }
`;
export const TranslatePlainTextForAlgorithmResourceEditorDocument = gql`
  mutation TranslatePlainTextForAlgorithmResourceEditor($source: TranslateSource!) {
    translatePlainText(source: $source)
  }
`;
export const TranslateMarkdownForAlgorithmResourceEditorDocument = gql`
  mutation TranslateMarkdownForAlgorithmResourceEditor($source: TranslateSource!) {
    translateMarkdown(source: $source)
  }
`;
export const GetBehavioralEventLogsForChallengeTestReportDocument = gql`
  query GetBehavioralEventLogsForChallengeTestReport($input: BehavioralEventLogsInput!) {
    behavioralEventLogs(input: $input) {
      ...BehavioralEventLogsForChallengeTestReport
    }
  }
  ${BehavioralEventLogsForChallengeTestReportFragmentDoc}
`;
export const GetQuestionForFreepadResourceEditorDocument = gql`
  query GetQuestionForFreepadResourceEditor($questionId: Int!, $questionVersion: String!) {
    freepadQuestion(questionId: $questionId, questionVersion: $questionVersion) {
      ...FreepadQuestionForFreepadResourceEditor
    }
  }
  ${FreepadQuestionForFreepadResourceEditorFragmentDoc}
`;
export const CreateQuestionForFreepadResourceEditorDocument = gql`
  mutation CreateQuestionForFreepadResourceEditor($input: CreateFreepadQuestionInput!) {
    createFreepadQuestion(input: $input) {
      ...FreepadQuestionForFreepadResourceEditor
    }
  }
  ${FreepadQuestionForFreepadResourceEditorFragmentDoc}
`;
export const UpdateQuestionForFreepadResourceEditorDocument = gql`
  mutation UpdateQuestionForFreepadResourceEditor($input: UpdateFreepadQuestionInput!) {
    updateFreepadQuestion(input: $input) {
      ...FreepadQuestionForFreepadResourceEditor
    }
  }
  ${FreepadQuestionForFreepadResourceEditorFragmentDoc}
`;
export const GetAlgorithmTemplateCodeForFreepadResourceEditorDocument = gql`
  query GetAlgorithmTemplateCodeForFreepadResourceEditor($signature: String!, $variant: AlgorithmQuestionVariant!) {
    algorithmTemplateCode(signature: $signature, variant: $variant) {
      ...AlgorithmTemplateCodeForFreepadResourceEditor
    }
  }
  ${AlgorithmTemplateCodeForFreepadResourceEditorFragmentDoc}
`;
export const TranslatePlainTextForFreepadResourceEditorDocument = gql`
  mutation TranslatePlainTextForFreepadResourceEditor($source: TranslateSource!) {
    translatePlainText(source: $source)
  }
`;
export const TranslateMarkdownForFreepadResourceEditorDocument = gql`
  mutation TranslateMarkdownForFreepadResourceEditor($source: TranslateSource!) {
    translateMarkdown(source: $source)
  }
`;
export const ListQuestionsForSearchQuestionDocument = gql`
  query ListQuestionsForSearchQuestion($multiChoiceQuestionsInput: MultiChoiceQuestionsInput!) {
    multiChoiceQuestions(multiChoiceQuestionsInput: $multiChoiceQuestionsInput) {
      questions {
        ...MultiChoiceQuestionForQuizResourceEditor
      }
      count
      pager {
        cursorSeconds
        cursorNanoSeconds
        hasNext
      }
    }
  }
  ${MultiChoiceQuestionForQuizResourceEditorFragmentDoc}
`;
export const ListMultiQuestionTagsDocument = gql`
  query ListMultiQuestionTags($multiChoiceTagsInput: MultiChoiceTagsInput!) {
    multiChoiceTags(multiChoiceTagsInput: $multiChoiceTagsInput) {
      tags {
        id
        name
      }
      count
      pager {
        cursorSeconds
        cursorNanoSeconds
        hasNext
      }
    }
  }
`;
export const CreateMultiChoicePackageDocument = gql`
  mutation CreateMultiChoicePackage($createMultiChoicePackage: CreateMultiChoicePackage!) {
    createMultiChoicePackage(createMultiChoicePackage: $createMultiChoicePackage) {
      ...CreateMultiChoicePackage
    }
  }
  ${CreateMultiChoicePackageFragmentDoc}
`;
export const UpdateMultiChoicePackageDocument = gql`
  mutation UpdateMultiChoicePackage($updateMultiChoicePackage: UpdateMultiChoicePackage!) {
    updateMultiChoicePackage(updateMultiChoicePackage: $updateMultiChoicePackage) {
      ...UpdateMultiChoicePackage
    }
  }
  ${UpdateMultiChoicePackageFragmentDoc}
`;
export const GetMultiChoicePackageDocument = gql`
  query GetMultiChoicePackage($id: Int!, $version: String!, $withAnswers: Boolean!) {
    multiChoicePackage(id: $id, version: $version, withAnswers: $withAnswers) {
      ...GetMultiChoicePackage
    }
  }
  ${GetMultiChoicePackageFragmentDoc}
`;
export const CreateMultiChoiceQuestionDocument = gql`
  mutation CreateMultiChoiceQuestion($payload: CreateMultiChoiceQuestionInput!) {
    createMultiChoiceQuestion(payload: $payload) {
      ...MultiChoiceQuestionForQuizResourceEditor
    }
  }
  ${MultiChoiceQuestionForQuizResourceEditorFragmentDoc}
`;
export const UpdateMultiChoiceQuestionDocument = gql`
  mutation UpdateMultiChoiceQuestion($payload: UpdateMultiChoiceQuestionInput!) {
    updateMultiChoiceQuestion(payload: $payload) {
      ...MultiChoiceQuestionForQuizResourceEditor
    }
  }
  ${MultiChoiceQuestionForQuizResourceEditorFragmentDoc}
`;
export const ListRemoteTagsForRemoteInterviewResourceEditorDocument = gql`
  query ListRemoteTagsForRemoteInterviewResourceEditor($companyId: Int!, $cursorSeconds: Int, $size: Int!, $withCount: Boolean!) {
    remoteTagsByCompanyId(companyId: $companyId, cursorSeconds: $cursorSeconds, size: $size, withCount: $withCount) {
      ...ListRemoteTagsForRemoteInterviewResourceEditorTag
    }
  }
  ${ListRemoteTagsForRemoteInterviewResourceEditorTagFragmentDoc}
`;
export const GetRemoteForRemoteInterviewResourceEditorDocument = gql`
  query GetRemoteForRemoteInterviewResourceEditor($remoteId: String!) {
    remote(remoteId: $remoteId) {
      ...GetRemoteForRemoteInterviewResourceEditorRemote
    }
  }
  ${GetRemoteForRemoteInterviewResourceEditorRemoteFragmentDoc}
`;
export const GetRemoteForRemoteInterviewResourceEditorV2Document = gql`
  query GetRemoteForRemoteInterviewResourceEditorV2($remoteId: String!) {
    remote(remoteId: $remoteId) {
      ...RemoteForRemoteInterviewResourceEditor
    }
  }
  ${RemoteForRemoteInterviewResourceEditorFragmentDoc}
`;
export const ListRemotesForRemoteInterviewResourceEditorDocument = gql`
  query ListRemotesForRemoteInterviewResourceEditor(
    $companyId: Int!
    $withCount: Boolean!
    $withDeleted: Boolean!
    $filters: RemoteFilterInput
    $sortMethod: RemoteSortMethod!
    $size: Int!
    $offset: Int!
    $isDescending: Boolean!
  ) {
    remotesByCompanyId(
      companyId: $companyId
      withCount: $withCount
      withDeleted: $withDeleted
      filters: $filters
      sortMethod: $sortMethod
      size: $size
      offset: $offset
      isDescending: $isDescending
    ) {
      ...ListRemotesForRemoteInterviewResourceEditor
    }
  }
  ${ListRemotesForRemoteInterviewResourceEditorFragmentDoc}
`;
export const ListQuestionsForRemoteInterviewResourceEditorDocument = gql`
  query ListQuestionsForRemoteInterviewResourceEditor($questionsInput: QuestionsInput!) {
    questions(questionsInput: $questionsInput) {
      questionObjects {
        ...ListQuestionForRemoteInterviewResourceEditor
      }
      count
      hasNext
    }
  }
  ${ListQuestionForRemoteInterviewResourceEditorFragmentDoc}
`;
export const ListTemplatesForRemoteInterviewResourceEditorDocument = gql`
  query ListTemplatesForRemoteInterviewResourceEditor($input: RemoteInterviewTemplatesByCompanyIdInput!) {
    remoteInterviewTemplatesByCompanyId(input: $input) {
      ...RemoteInterviewTemplatesByCompanyIdResponseForRemoteInterviewResourceEditor
    }
  }
  ${RemoteInterviewTemplatesByCompanyIdResponseForRemoteInterviewResourceEditorFragmentDoc}
`;
export const FillTemplateForRemoteInterviewResourceEditorDocument = gql`
  mutation FillTemplateForRemoteInterviewResourceEditor($input: FillTemplateInput!) {
    fillRemoteInterviewTemplate(input: $input) {
      ...TemplateForRemoteInterviewResourceEditor
    }
  }
  ${TemplateForRemoteInterviewResourceEditorFragmentDoc}
`;
export const GetTemplateForRemoteInterviewResourceEditorDocument = gql`
  query GetTemplateForRemoteInterviewResourceEditor($templateId: Int!) {
    remoteInterviewTemplate(templateId: $templateId) {
      ...TemplateForRemoteInterviewResourceEditor
    }
  }
  ${TemplateForRemoteInterviewResourceEditorFragmentDoc}
`;
export const ListRemoteTagsForRemoteInterviewTemplateResourceEditorDocument = gql`
  query ListRemoteTagsForRemoteInterviewTemplateResourceEditor($companyId: Int!, $cursorSeconds: Int, $size: Int!, $withCount: Boolean!) {
    remoteTagsByCompanyId(companyId: $companyId, cursorSeconds: $cursorSeconds, size: $size, withCount: $withCount) {
      ...RemoteTagsByCompanyIdResponseForRemoteInterviewTemplateResourceEditor
    }
  }
  ${RemoteTagsByCompanyIdResponseForRemoteInterviewTemplateResourceEditorFragmentDoc}
`;
export const ListRemoteInterviewTemplatesResourceEditorDocument = gql`
  query ListRemoteInterviewTemplatesResourceEditor(
    $companyId: Int!
    $withCount: Boolean!
    $withDeleted: Boolean!
    $sortMethod: RemoteSortMethod!
    $size: Int!
    $offset: Int!
    $isDescending: Boolean!
  ) {
    remotesByCompanyId(
      companyId: $companyId
      withCount: $withCount
      withDeleted: $withDeleted
      sortMethod: $sortMethod
      size: $size
      offset: $offset
      isDescending: $isDescending
    ) {
      ...ListRemotesForRemoteInterviewResourceEditor
    }
  }
  ${ListRemotesForRemoteInterviewResourceEditorFragmentDoc}
`;
export const GetRemoteForRemoteInterviewTemplateResourceEditorDocument = gql`
  query GetRemoteForRemoteInterviewTemplateResourceEditor($id: String!) {
    remote(remoteId: $id) {
      ...RemoteForRemoteInterviewTemplateResourceEditor
    }
  }
  ${RemoteForRemoteInterviewTemplateResourceEditorFragmentDoc}
`;
export const GetRemoteInterviewTemplateForRemoteInterviewTemplateResourceEditorDocument = gql`
  query GetRemoteInterviewTemplateForRemoteInterviewTemplateResourceEditor($templateId: Int!) {
    remoteInterviewTemplate(templateId: $templateId) {
      ...RemoteInterviewTemplateForRemoteInterviewTemplateResourceEditor
    }
  }
  ${RemoteInterviewTemplateForRemoteInterviewTemplateResourceEditorFragmentDoc}
`;
export const GetRemoteInterviewTemplateForRemoteTemplateResourceEditorDocument = gql`
  query GetRemoteInterviewTemplateForRemoteTemplateResourceEditor($templateId: Int!) {
    remoteInterviewTemplate(templateId: $templateId) {
      ...RemoteInterviewTemplateForRemoteTemplateResourceEditor
    }
  }
  ${RemoteInterviewTemplateForRemoteTemplateResourceEditorFragmentDoc}
`;
export const GetRemoteForRemoteTemplateResourceEditorDocument = gql`
  query GetRemoteForRemoteTemplateResourceEditor($id: String!) {
    remote(remoteId: $id) {
      ...RemoteForRemoteTemplateResourceEditor
    }
  }
  ${RemoteForRemoteTemplateResourceEditorFragmentDoc}
`;
export const AnalyzeRemoteInterviewTemplateResourceEditorDocument = gql`
  mutation AnalyzeRemoteInterviewTemplateResourceEditor($analyzeTemplateInput: AnalyzeRemoteInterviewTemplateInput!) {
    analyzeRemoteInterviewTemplate(input: $analyzeTemplateInput) {
      id
      templateId
      name
      description
    }
  }
`;
export const ListQuestionsForForRemoteInterviewTemplateResourceEditorDocument = gql`
  query ListQuestionsForForRemoteInterviewTemplateResourceEditor($questionsInput: QuestionsInput!) {
    questions(questionsInput: $questionsInput) {
      questionObjects {
        ...QuestionObjectForRemoteInterviewTemplateResourceEditor
      }
      count
      hasNext
    }
  }
  ${QuestionObjectForRemoteInterviewTemplateResourceEditorFragmentDoc}
`;
export const GetInterviewFeedbackForCandidateInterviewFeedbackDocument = gql`
  query GetInterviewFeedbackForCandidateInterviewFeedback($id: String!, $withAnswers: Boolean!) {
    spot(id: $id, withAnswers: $withAnswers) {
      ...InterviewFeedbackForCandidateInterviewFeedback
    }
  }
  ${InterviewFeedbackForCandidateInterviewFeedbackFragmentDoc}
`;
export const GetInterviewReportFromSharedLinkDocument = gql`
  query GetInterviewReportFromSharedLink($spotId: String!, $linkHash: String!) {
    spotFromSharedLink(spotId: $spotId, linkHash: $linkHash) {
      ...InterviewReportFromSharedLink
    }
  }
  ${InterviewReportFromSharedLinkFragmentDoc}
`;
export const GetExamForAssessmentFeedbackDocument = gql`
  query GetExamForAssessmentFeedback($examId: String!) {
    exam(examId: $examId) {
      ...ExamForAssessmentFeedback
    }
  }
  ${ExamForAssessmentFeedbackFragmentDoc}
`;
export const ListAssessmentsByCompanyIdForAssessmentsHistorySelectorDocument = gql`
  query ListAssessmentsByCompanyIdForAssessmentsHistorySelector(
    $companyId: Int!
    $withCount: Boolean!
    $withDeleted: Boolean!
    $filters: AssessmentFilters!
    $sortMethod: AssessmentSortMethod!
    $size: Int!
    $offset: Int!
    $isDescending: Boolean!
  ) {
    listAssessmentsByCompanyId(
      companyId: $companyId
      withCount: $withCount
      withDeleted: $withDeleted
      filters: $filters
      sortMethod: $sortMethod
      size: $size
      offset: $offset
      isDescending: $isDescending
    ) {
      ...ListAssessmentsByCompanyIdForAssessmentsHistorySelector
    }
  }
  ${ListAssessmentsByCompanyIdForAssessmentsHistorySelectorFragmentDoc}
`;
export const GetAssessmentForAssessmentHistorySelectorDocument = gql`
  query GetAssessmentForAssessmentHistorySelector($assessmentId: String!) {
    assessment(assessmentId: $assessmentId) {
      entitySourceQuestions(withAnswers: false) {
        ...AssessmentEntitySourceQuestionForAssessmentHistorySelector
      }
    }
  }
  ${AssessmentEntitySourceQuestionForAssessmentHistorySelectorFragmentDoc}
`;
export const GetAssessmentOverviewDocument = gql`
  query GetAssessmentOverview($assessmentId: String!, $examId: String!) {
    assessment(assessmentId: $assessmentId) {
      ...AssessmentOverview
    }
    exam(examId: $examId) {
      ...ExamForAssessmentOverview
    }
  }
  ${AssessmentOverviewFragmentDoc}
  ${ExamForAssessmentOverviewFragmentDoc}
`;
export const UpdateAssessmentForAssessmentOverviewDocument = gql`
  mutation UpdateAssessmentForAssessmentOverview($input: UpdateAssessmentRequestInput!) {
    updateAssessment(input: $input) {
      ...AssessmentOverview
    }
  }
  ${AssessmentOverviewFragmentDoc}
`;
export const GetExamReportDocument = gql`
  query GetExamReport($examId: String!) {
    exam(examId: $examId) {
      ...ExamReport
    }
  }
  ${ExamReportFragmentDoc}
`;
export const GetAssessmentForAssessmentResourceEditorDocument = gql`
  query GetAssessmentForAssessmentResourceEditor($assessmentId: String!) {
    assessment(assessmentId: $assessmentId) {
      ...AssessmentForAssessmentResourceEditor
    }
  }
  ${AssessmentForAssessmentResourceEditorFragmentDoc}
`;
export const GetBillingOverviewDocument = gql`
  query GetBillingOverview($customerId: Int!, $startedAtSeconds: Int!, $contractPeriod: Int!) {
    customer(customerId: $customerId) {
      ...CustomerForBillingOverview
    }
    contractPeriodUsagesV2(customerId: $customerId, startedAtSeconds: $startedAtSeconds, contractPeriod: $contractPeriod) {
      contractPeriodUsages {
        ...ContractPeriodUsageForBillingOverview
      }
    }
  }
  ${CustomerForBillingOverviewFragmentDoc}
  ${ContractPeriodUsageForBillingOverviewFragmentDoc}
`;
export const UpdateAutoRenewalForBillingOverviewDocument = gql`
  mutation UpdateAutoRenewalForBillingOverview($input: UpdateCustomerInput!) {
    updatePaymentCustomer(input: $input) {
      customer {
        isAutoRenewal
      }
    }
  }
`;
export const GetCustomerForBuySelectionsDocument = gql`
  query GetCustomerForBuySelections($customerId: Int!) {
    customer(customerId: $customerId) {
      ...CustomerForBuySelections
    }
    buyAblePacks(customerId: $customerId) {
      interviews {
        ...PaymentInterviewForBuySelections
      }
    }
  }
  ${CustomerForBuySelectionsFragmentDoc}
  ${PaymentInterviewForBuySelectionsFragmentDoc}
`;
export const PurchasePackagesForBuySelectionsDocument = gql`
  mutation PurchasePackagesForBuySelections($input: PurchasePackagesInput!) {
    purchasePackages(input: $input) {
      subscriptionId
      customerId
      packStock
      periodType
    }
  }
`;
export const GetCouponByCodeForBuySelectionDocument = gql`
  query GetCouponByCodeForBuySelection($couponCode: String!) {
    couponByCode(couponCode: $couponCode) {
      ...CouponForBuySelections
    }
  }
  ${CouponForBuySelectionsFragmentDoc}
`;
export const ChallengeSubmissionDocument = gql`
  query ChallengeSubmission($submissionId: Int!) {
    challengeSubmission(submissionId: $submissionId) {
      ...ChallengeSubmission
    }
  }
  ${ChallengeSubmissionFragmentDoc}
`;
export const ChallengeRunCodeDocument = gql`
  query ChallengeRunCode(
    $questionId: Int!
    $questionVersion: String!
    $challengeId: Int!
    $codeBody: String!
    $runtime: String!
    $input: String!
    $takeSnapshot: Boolean!
  ) {
    challengeRunCode(
      questionId: $questionId
      questionVersion: $questionVersion
      challengeId: $challengeId
      codeBody: $codeBody
      runtime: $runtime
      input: $input
      takeSnapshot: $takeSnapshot
    ) {
      ...ChallengeRunCodeResponse
    }
  }
  ${ChallengeRunCodeResponseFragmentDoc}
`;
export const StartChallengeDocument = gql`
  mutation StartChallenge($challengeId: Int!, $candidateId: String!) {
    startChallenge(challengeId: $challengeId, candidateId: $candidateId) {
      ...ChallengeEntityForCandidateInterview
    }
  }
  ${ChallengeEntityForCandidateInterviewFragmentDoc}
`;
export const SaveChallengeAppealMessageDocument = gql`
  mutation SaveChallengeAppealMessage($challengeId: Int!, $appealMessage: String!) {
    saveChallengeAppealMessage(challengeId: $challengeId, appealMessage: $appealMessage) {
      ...ChallengeEntityForCandidateInterview
    }
  }
  ${ChallengeEntityForCandidateInterviewFragmentDoc}
`;
export const ChallengeSubmitCodeDocument = gql`
  mutation ChallengeSubmitCode($challengeId: Int!, $questionId: Int!, $questionVersion: String!, $runtime: String!, $codeBody: String!) {
    challengeSubmitCode(
      challengeId: $challengeId
      questionId: $questionId
      questionVersion: $questionVersion
      runtime: $runtime
      codeBody: $codeBody
    ) {
      ...ChallengeSubmission
    }
  }
  ${ChallengeSubmissionFragmentDoc}
`;
export const StartChallengeSessionsForChallengeCodingEditorDocument = gql`
  mutation StartChallengeSessionsForChallengeCodingEditor($input: StartChallengeSessionsInput!) {
    startChallengeSessions(input: $input) {
      webSession {
        ...ChallengeWebSessionForChallengeCodingEditor
      }
      chatGPTSession {
        ...ChallengeChatGPTSessionForChallengeCodingEditor
      }
    }
  }
  ${ChallengeWebSessionForChallengeCodingEditorFragmentDoc}
  ${ChallengeChatGptSessionForChallengeCodingEditorFragmentDoc}
`;
export const StoreWebSearchEventForChallengeCodingEditorDocument = gql`
  mutation StoreWebSearchEventForChallengeCodingEditor($input: StoreWebSearchEventInput!) {
    storeWebSearchEvent(input: $input) {
      ...WebSearchEventForChallengeCodingEditor
    }
  }
  ${WebSearchEventForChallengeCodingEditorFragmentDoc}
`;
export const StoreWebAccessEventForChallengeCodingEditorDocument = gql`
  mutation StoreWebAccessEventForChallengeCodingEditor($input: StoreWebAccessEventInput!) {
    storeWebAccessEvent(input: $input) {
      eventId
    }
  }
`;
export const AskChatGptForChallengeCodingEditorDocument = gql`
  mutation AskChatGPTForChallengeCodingEditor($input: AskChatGPTInput!) {
    askChatGPT(input: $input) {
      chatId
      messages {
        role
        content
      }
      sessionId
    }
  }
`;
export const ResetChatGptConversationForChallengeCodingEditorDocument = gql`
  mutation ResetChatGPTConversationForChallengeCodingEditor($input: ResetChatGPTConversationInput!) {
    resetChatGPTConversation(input: $input)
  }
`;
export const GetChallengeStatisticsAndRankForRankVisualizerDocument = gql`
  query GetChallengeStatisticsAndRankForRankVisualizer($statisticsSource: StatisticsSourceInput!, $rankSource: RankSourceInput!) {
    statistics(statisticsSource: $statisticsSource) {
      __typename
      challengeStatistics {
        ...ChallengeStatisticsForRankVisualizer
      }
    }
    rank(rankSource: $rankSource) {
      ...RankForTestRankVisualizer
    }
  }
  ${ChallengeStatisticsForRankVisualizerFragmentDoc}
  ${RankForTestRankVisualizerFragmentDoc}
`;
export const GetChallengeEntityForChallengeTestReportDocument = gql`
  query GetChallengeEntityForChallengeTestReport($challengeId: Int!, $withAnswers: Boolean!) {
    challengeEntity(challengeId: $challengeId, withAnswers: $withAnswers) {
      ...InterviewReportChallenge
    }
  }
  ${InterviewReportChallengeFragmentDoc}
`;
export const GetChallengeSubmissionForChallengeTestReportDocument = gql`
  query GetChallengeSubmissionForChallengeTestReport($submissionId: Int!) {
    challengeSubmission(submissionId: $submissionId) {
      ...TestReportChallengeSubmission
    }
  }
  ${TestReportChallengeSubmissionFragmentDoc}
`;
export const FeedbackSuspiciousDegreeForChallengeTestReportDocument = gql`
  mutation FeedbackSuspiciousDegreeForChallengeTestReport($input: FeedbackSuspiciousDegreeInput!) {
    feedbackSuspiciousDegree(input: $input)
  }
`;
export const ExportTestResultTableDocument = gql`
  mutation ExportTestResultTable(
    $jsonQueries: [JsonQuery!]!
    $language: ExportLanguage!
    $fileType: ExportFileType!
    $filters: SpotsByCompanyIdFilters
  ) {
    exportTestResultTable(jsonQueries: $jsonQueries, language: $language, fileType: $fileType, filters: $filters) {
      id
    }
  }
`;
export const GetEmployeesForEmployeeAssignFieldDocument = gql`
  query GetEmployeesForEmployeeAssignField($companyId: Int!) {
    company(companyId: $companyId) {
      ...CompanyForEmployeeAssignField
    }
  }
  ${CompanyForEmployeeAssignFieldFragmentDoc}
`;
export const GetCompanyForEmployeeGroupChipsDocument = gql`
  query GetCompanyForEmployeeGroupChips($companyId: Int!) {
    company(companyId: $companyId) {
      ...GetCompanyForEmployeeGroupChipsCompany
    }
  }
  ${GetCompanyForEmployeeGroupChipsCompanyFragmentDoc}
`;
export const ListEmployeeNotificationsDocument = gql`
  query ListEmployeeNotifications($employeeId: String!, $companyId: Int!, $cursor: Int, $size: Int!, $withCount: Boolean!) {
    employeeNotifications(employeeId: $employeeId, companyId: $companyId, cursor: $cursor, size: $size, withCount: $withCount) {
      ...EmployeeNotificationsResponseForEmployeeNavigation
    }
  }
  ${EmployeeNotificationsResponseForEmployeeNavigationFragmentDoc}
`;
export const GetCompaniesByEmployeeIdForEmployeeSideBarContentDocument = gql`
  query GetCompaniesByEmployeeIdForEmployeeSideBarContent($uid: String!, $size: Int!, $cursorSeconds: Int) {
    companiesByEmployeeId(uid: $uid, withActiveId: true, size: $size, cursorSeconds: $cursorSeconds) {
      ...EssentialCompanies
    }
  }
  ${EssentialCompaniesFragmentDoc}
`;
export const ListEvaluationMetricsGroupsForEvaluationMetricGroupSearchFieldDocument = gql`
  query ListEvaluationMetricsGroupsForEvaluationMetricGroupSearchField($companyId: Int!, $size: Int!, $cursor: Int, $withCount: Boolean) {
    evaluationMetricGroupsByCompany(companyId: $companyId, size: $size, cursor: $cursor, withCount: $withCount) {
      ...EvaluationMetricGroupsByCompanyResponseForEvaluationMetricGroupSearchField
    }
  }
  ${EvaluationMetricGroupsByCompanyResponseForEvaluationMetricGroupSearchFieldFragmentDoc}
`;
export const ListEvaluationMetricsForEvaluationMetricSearchFieldDocument = gql`
  query ListEvaluationMetricsForEvaluationMetricSearchField($companyId: Int!, $size: Int!, $cursor: Int, $withCount: Boolean) {
    evaluationMetricsByCompany(companyId: $companyId, size: $size, cursor: $cursor, withCount: $withCount) {
      ...EvaluationMetricsByCompanyResponseForEvaluationMetricSearchField
    }
  }
  ${EvaluationMetricsByCompanyResponseForEvaluationMetricSearchFieldFragmentDoc}
`;
export const ListEvaluationMetricsForEvaluationMetricsDocument = gql`
  query ListEvaluationMetricsForEvaluationMetrics($companyId: Int!, $size: Int!, $cursor: Int, $withCount: Boolean) {
    evaluationMetricsByCompany(companyId: $companyId, size: $size, cursor: $cursor, withCount: $withCount) {
      ...EvaluationMetricsByCompanyResponseForEvaluationMetrics
    }
  }
  ${EvaluationMetricsByCompanyResponseForEvaluationMetricsFragmentDoc}
`;
export const GetEvaluationMetricForEvaluationMetricsDocument = gql`
  query GetEvaluationMetricForEvaluationMetrics($metricId: Int!) {
    evaluationMetric(metricId: $metricId) {
      ...EvaluationMetricForEvaluationMetrics
    }
  }
  ${EvaluationMetricForEvaluationMetricsFragmentDoc}
`;
export const CreateEvaluationMetricForEvaluationMetricsDocument = gql`
  mutation CreateEvaluationMetricForEvaluationMetrics($input: CreateEvaluationMetricInput!) {
    createEvaluationMetric(input: $input) {
      ...EvaluationMetricForEvaluationMetrics
    }
  }
  ${EvaluationMetricForEvaluationMetricsFragmentDoc}
`;
export const AddEvaluationMetricsToGroupsForEvaluationMetricsDocument = gql`
  mutation AddEvaluationMetricsToGroupsForEvaluationMetrics($input: AddEvaluationMetricsToGroupsInput!) {
    addEvaluationMetricsToGroups(input: $input)
  }
`;
export const ListEvaluationMetricsGroupsForEvaluationMetricsGroupsDocument = gql`
  query ListEvaluationMetricsGroupsForEvaluationMetricsGroups(
    $companyId: Int!
    $size: Int!
    $cursor: Int
    $withCount: Boolean
    $isDescending: Boolean
  ) {
    evaluationMetricGroupsByCompany(companyId: $companyId, size: $size, cursor: $cursor, withCount: $withCount, isDescending: $isDescending) {
      ...EvaluationMetricGroupsByCompanyResponseForEvaluationMetricsGroups
    }
  }
  ${EvaluationMetricGroupsByCompanyResponseForEvaluationMetricsGroupsFragmentDoc}
`;
export const GetEvaluationMetricGroupForEvaluationMetricsGroupsDocument = gql`
  query GetEvaluationMetricGroupForEvaluationMetricsGroups($metricGroupId: Int!) {
    evaluationMetricGroup(metricGroupId: $metricGroupId) {
      ...EvaluationMetricGroupForEvaluationMetricsGroups
    }
  }
  ${EvaluationMetricGroupForEvaluationMetricsGroupsFragmentDoc}
`;
export const CreateEvaluationMetricGroupForEvaluationMetricsGroupsDocument = gql`
  mutation CreateEvaluationMetricGroupForEvaluationMetricsGroups($input: CreateEvaluationMetricGroupInput!) {
    createEvaluationMetricGroup(input: $input) {
      ...EvaluationMetricGroupForEvaluationMetricsGroups
    }
  }
  ${EvaluationMetricGroupForEvaluationMetricsGroupsFragmentDoc}
`;
export const GetExamStatisticsAndRankForExamRankVisualizerDocument = gql`
  query GetExamStatisticsAndRankForExamRankVisualizer($statisticsSource: StatisticsSourceInput!, $rankSource: RankSourceInput!) {
    statistics(statisticsSource: $statisticsSource) {
      __typename
      examStatistics {
        ...ExamStatisticsForExamRankVisualizer
      }
    }
    rank(rankSource: $rankSource) {
      ...RankForExamRankVisualizer
    }
  }
  ${ExamStatisticsForExamRankVisualizerFragmentDoc}
  ${RankForExamRankVisualizerFragmentDoc}
`;
export const ListBestExamsByAssessmentIdForExamTransitionVisualizerDocument = gql`
  query ListBestExamsByAssessmentIdForExamTransitionVisualizer(
    $assessmentId: String!
    $withCount: Boolean!
    $size: Int!
    $offset: Int!
    $isDescending: Boolean!
  ) {
    listBestExamsByAssessmentId(assessmentId: $assessmentId, withCount: $withCount, size: $size, offset: $offset, isDescending: $isDescending) {
      ...ListBestExamsResponseForExamTransitionVisualizer
    }
  }
  ${ListBestExamsResponseForExamTransitionVisualizerFragmentDoc}
`;
export const GetCompanyForGeneralSettingsDocument = gql`
  query GetCompanyForGeneralSettings($companyId: Int!) {
    company(companyId: $companyId) {
      ...GetCompanyForGeneralSettingsCompany
    }
  }
  ${GetCompanyForGeneralSettingsCompanyFragmentDoc}
`;
export const UpdateCompanyForGeneralSettingsDocument = gql`
  mutation UpdateCompanyForGeneralSettings($companyId: Int!, $logo: String!, $name: String!, $websiteUrl: String!) {
    updateCompany(companyId: $companyId, logo: $logo, name: $name, websiteUrl: $websiteUrl) {
      ...UpdateCompanyForGeneralSettingsCompany
    }
  }
  ${UpdateCompanyForGeneralSettingsCompanyFragmentDoc}
`;
export const GetCompanyForGroupSettingsDocument = gql`
  query GetCompanyForGroupSettings($companyId: Int!) {
    company(companyId: $companyId) {
      ...GetCompanyForGroupSettingsCompany
    }
  }
  ${GetCompanyForGroupSettingsCompanyFragmentDoc}
`;
export const CreateGroupDocument = gql`
  mutation CreateGroup($companyId: Int!, $groupName: String!, $employeeIds: [String!]!) {
    createGroup(companyId: $companyId, groupName: $groupName, employeeIds: $employeeIds)
  }
`;
export const DeleteGroupDocument = gql`
  mutation DeleteGroup($groupId: String!) {
    deleteGroup(groupId: $groupId)
  }
`;
export const DeleteGroupsDocument = gql`
  mutation DeleteGroups($groupIds: [String!]!) {
    deleteGroups(groupIds: $groupIds)
  }
`;
export const EditGroupDocument = gql`
  mutation EditGroup($groupId: String!, $groupName: String!) {
    editGroup(groupId: $groupId, groupName: $groupName)
  }
`;
export const AddMembersToGroupDocument = gql`
  mutation AddMembersToGroup($companyId: Int!, $groupId: String!, $employeeIds: [String!]!) {
    addMembersToGroup(companyId: $companyId, groupId: $groupId, employeeIds: $employeeIds)
  }
`;
export const AddEmployeeToGroupsDocument = gql`
  mutation AddEmployeeToGroups($companyId: Int!, $employeeId: String!, $groupIds: [String!]!) {
    addEmployeeToGroups(companyId: $companyId, employeeId: $employeeId, groupIds: $groupIds) {
      employeeId
    }
  }
`;
export const RemoveMembersDocument = gql`
  mutation RemoveMembers($companyId: Int!, $groupId: String!, $employeeIds: [String!]!) {
    removeMembers(companyId: $companyId, groupId: $groupId, employeeIds: $employeeIds)
  }
`;
export const RegisterSonarDocument = gql`
  mutation RegisterSonar($input: RegisterSonarInput!) {
    registerSonar(input: $input) {
      id
      companyId
      createdAtSeconds
      screeningId
      screening {
        id
        screeningId
        name
        description
      }
    }
  }
`;
export const DeleteSonarDocument = gql`
  mutation DeleteSonar($id: String!) {
    deleteSonar(id: $id)
  }
`;
export const ListSonarIntegrationsDocument = gql`
  query ListSonarIntegrations($companyId: Int!, $cursorSeconds: Int, $size: Int!, $withCount: Boolean!) {
    sonarIntegrations(companyId: $companyId, cursorSeconds: $cursorSeconds, size: $size, withCount: $withCount) {
      ...SonarIntegrationsResponse
    }
  }
  ${SonarIntegrationsResponseFragmentDoc}
`;
export const ListScreeningsForIntegrationSettingsDocument = gql`
  query ListScreeningsForIntegrationSettings($input: ScreeningsByCompanyIdInput!) {
    screeningsByCompanyId(input: $input) {
      ...ScreeningsByCompanyIdResponseForIntegrationSettings
    }
  }
  ${ScreeningsByCompanyIdResponseForIntegrationSettingsFragmentDoc}
`;
export const GetInterviewFeedbackDocument = gql`
  query GetInterviewFeedback($id: String!, $withAnswers: Boolean!) {
    spot(id: $id, withAnswers: $withAnswers) {
      ...InterviewFeedback
    }
  }
  ${InterviewFeedbackFragmentDoc}
`;
export const GetInterviewOverviewDocument = gql`
  query GetInterviewOverview($id: String!, $withAnswers: Boolean!) {
    spot(id: $id, withAnswers: $withAnswers) {
      ...InterviewOverview
    }
  }
  ${InterviewOverviewFragmentDoc}
`;
export const GetInterviewReportDocument = gql`
  query GetInterviewReport($id: String!, $withAnswers: Boolean!) {
    spot(id: $id, withAnswers: $withAnswers) {
      ...InterviewReport
    }
  }
  ${InterviewReportFragmentDoc}
`;
export const GetCompanyForMemberSettingsDocument = gql`
  query GetCompanyForMemberSettings($companyId: Int!) {
    company(companyId: $companyId) {
      ...GetCompanyForMemberSettingsCompany
    }
  }
  ${GetCompanyForMemberSettingsCompanyFragmentDoc}
`;
export const CreateEmployeeInvitationDocument = gql`
  mutation CreateEmployeeInvitation(
    $companyId: Int!
    $employeeId: String!
    $invitationMethod: InvitationMethod!
    $emailAddresses: [String!]!
    $role: Role!
    $invitationLanguage: InvitationLanguage!
  ) {
    createEmployeeInvitation(
      companyId: $companyId
      employeeId: $employeeId
      invitationMethod: $invitationMethod
      emailAddresses: $emailAddresses
      role: $role
      invitationLanguage: $invitationLanguage
    ) {
      id
      invitationMethod
      emailAddresses
      role
      createdBy
      expiresAtSeconds
      invitationLanguage
    }
  }
`;
export const DeleteMultiCompanyEmployeesForMemberSettingsDocument = gql`
  mutation DeleteMultiCompanyEmployeesForMemberSettings($companyId: Int!, $employeeIds: [String!]!) {
    deleteMultiCompanyEmployees(companyId: $companyId, employeeIds: $employeeIds)
  }
`;
export const DeleteCompanyEmployeeForMemberSettingsDocument = gql`
  mutation DeleteCompanyEmployeeForMemberSettings($companyId: Int!, $employeeId: String!) {
    deleteCompanyEmployee(companyId: $companyId, employeeId: $employeeId)
  }
`;
export const UpdateCompanyEmployeeForMemberSettingsDocument = gql`
  mutation UpdateCompanyEmployeeForMemberSettings($companyId: Int!, $employeeId: String!, $role: Role!) {
    updateCompanyEmployee(companyId: $companyId, employeeId: $employeeId, role: $role) {
      employeeId
      role
    }
  }
`;
export const ReadEmployeeNotificationsDocument = gql`
  mutation ReadEmployeeNotifications($employeeNotificationIds: [Int!]!, $employeeId: String!, $companyId: Int!) {
    readEmployeeNotifications(employeeNotificationIds: $employeeNotificationIds, employeeId: $employeeId, companyId: $companyId) {
      id
      notificationId
      employeeId
      isRead
      titleJa
      titleEn
      descriptionJa
      descriptionEn
      createdAtSeconds
      companyId
      actionLink
    }
  }
`;
export const UpdateSlackSettingsDocument = gql`
  mutation UpdateSlackSettings(
    $companyId: Int!
    $language: Language!
    $spotSetting: SpotSettingsSubscribeSlackInput!
    $remoteSetting: RemoteSettingsSubscribeSlackInput!
  ) {
    updateSlackSettings(companyId: $companyId, language: $language, spotSetting: $spotSetting, remoteSetting: $remoteSetting) {
      companyId
      isAuthorized
      language
      spotSettings {
        ...UpdateSlackSettingsForSpotSettings
      }
      remoteSettings {
        ...UpdateSlackSettingsForRemoteSettings
      }
      incomingWebHook {
        ...UpdateSlackSettingsForIncomingWebHook
      }
    }
  }
  ${UpdateSlackSettingsForSpotSettingsFragmentDoc}
  ${UpdateSlackSettingsForRemoteSettingsFragmentDoc}
  ${UpdateSlackSettingsForIncomingWebHookFragmentDoc}
`;
export const AuthorizeSlackDocument = gql`
  mutation AuthorizeSlack($input: AuthorizeSlackInput!) {
    authorizeSlack(input: $input) {
      companyId
      isAuthorized
      incomingWebHook {
        ...UpdateSlackSettingsForIncomingWebHook
      }
    }
  }
  ${UpdateSlackSettingsForIncomingWebHookFragmentDoc}
`;
export const GetSlackSettingsDocument = gql`
  query GetSlackSettings($companyId: Int!) {
    slackSettings(companyId: $companyId) {
      companyId
      isAuthorized
      language
      incomingWebHook {
        ...GetSlackSettingsForIncomingWebHook
      }
      spotSettings {
        ...GetSlackSettingsForSpotSettings
      }
      remoteSettings {
        ...GetSlackSettingsForRemoteSettings
      }
    }
  }
  ${GetSlackSettingsForIncomingWebHookFragmentDoc}
  ${GetSlackSettingsForSpotSettingsFragmentDoc}
  ${GetSlackSettingsForRemoteSettingsFragmentDoc}
`;
export const GetPurchasesForOrderHistoryDocument = gql`
  query GetPurchasesForOrderHistory(
    $customerId: Int!
    $purchasePeriodStartSeconds: Int!
    $purchasePeriodEndSeconds: Int!
    $cursorSeconds: Int
    $cursorNanoSeconds: Int
    $size: Int!
  ) {
    purchases(
      customerId: $customerId
      purchasePeriodStartSeconds: $purchasePeriodStartSeconds
      purchasePeriodEndSeconds: $purchasePeriodEndSeconds
      cursorSeconds: $cursorSeconds
      cursorNanoSeconds: $cursorNanoSeconds
      size: $size
      isAscending: false
    ) {
      ...PurchasesResponseForOrderHistory
    }
    company(companyId: $customerId) {
      ...CompanyForOrderHistory
    }
  }
  ${PurchasesResponseForOrderHistoryFragmentDoc}
  ${CompanyForOrderHistoryFragmentDoc}
`;
export const GetPlansForPaymentContractCreateFormDocument = gql`
  query GetPlansForPaymentContractCreateForm($customerId: Int!, $planGeneration: Int!) {
    customer(customerId: $customerId) {
      ...CustomerForPaymentContractCreateForm
    }
    plans(customerId: $customerId) {
      ...PlansResponseForPaymentContractCreateForm
    }
    buyAblePacks(customerId: $customerId) {
      interviews {
        ...PaymentInterviewForPaymentContractCreateForm
      }
    }
    paymentInitialCost(planGeneration: $planGeneration) {
      ...PaymentInitialCostForPaymentContractCreateForm
    }
  }
  ${CustomerForPaymentContractCreateFormFragmentDoc}
  ${PlansResponseForPaymentContractCreateFormFragmentDoc}
  ${PaymentInterviewForPaymentContractCreateFormFragmentDoc}
  ${PaymentInitialCostForPaymentContractCreateFormFragmentDoc}
`;
export const ContractPlanForPaymentContractCreateFormDocument = gql`
  mutation ContractPlanForPaymentContractCreateForm($input: ContractPlanInput!) {
    contractPlan(input: $input) {
      ...CustomerForPaymentContractCreateForm
    }
  }
  ${CustomerForPaymentContractCreateFormFragmentDoc}
`;
export const GetCouponByCodeForPaymentContractCreateFormDocument = gql`
  query GetCouponByCodeForPaymentContractCreateForm($couponCode: String!) {
    couponByCode(couponCode: $couponCode) {
      ...CouponForPaymentContractCreateForm
    }
  }
  ${CouponForPaymentContractCreateFormFragmentDoc}
`;
export const GetPaymentMethodUpdateFormDocument = gql`
  query GetPaymentMethodUpdateForm($customerId: Int!) {
    customer(customerId: $customerId) {
      ...CustomerForPaymentMethodUpdateForm
    }
  }
  ${CustomerForPaymentMethodUpdateFormFragmentDoc}
`;
export const UpdatePaymentV2CustomerForPaymentMethodUpdateFormDocument = gql`
  mutation UpdatePaymentV2CustomerForPaymentMethodUpdateForm($input: UpdateCustomerInput!) {
    updatePaymentCustomer(input: $input) {
      customer {
        ...CustomerForPaymentMethodUpdateForm
      }
      errorCode
    }
  }
  ${CustomerForPaymentMethodUpdateFormFragmentDoc}
`;
export const RetryPaymentForPaymentMethodUpdateFormDocument = gql`
  mutation RetryPaymentForPaymentMethodUpdateForm($input: RetryPaymentInput!) {
    retryPayment(input: $input)
  }
`;
export const GetPlansForPaymentPlanListDocument = gql`
  query GetPlansForPaymentPlanList($customerId: Int!) {
    customer(customerId: $customerId) {
      ...CustomerForPaymentPlanList
    }
    plans(customerId: $customerId) {
      ...PlansResponseForPaymentPlanList
    }
  }
  ${CustomerForPaymentPlanListFragmentDoc}
  ${PlansResponseForPaymentPlanListFragmentDoc}
`;
export const GetPlansForPaymentPlanUpdateFormDocument = gql`
  query GetPlansForPaymentPlanUpdateForm(
    $customerId: Int!
    $purchasePeriodStartSeconds: Int!
    $purchasePeriodEndSeconds: Int!
    $cursorSeconds: Int
  ) {
    customer(customerId: $customerId) {
      ...CustomerForPaymentPlanUpdateForm
    }
    plans(customerId: $customerId) {
      ...PlansResponseForPaymentPlanUpdateForm
    }
    mostExpensivePurchase(
      customerId: $customerId
      purchasePeriodStartSeconds: $purchasePeriodStartSeconds
      purchasePeriodEndSeconds: $purchasePeriodEndSeconds
      cursorSeconds: $cursorSeconds
    ) {
      ...MostExpensivePurchaseForPaymentPlanUpdateForm
    }
  }
  ${CustomerForPaymentPlanUpdateFormFragmentDoc}
  ${PlansResponseForPaymentPlanUpdateFormFragmentDoc}
  ${MostExpensivePurchaseForPaymentPlanUpdateFormFragmentDoc}
`;
export const ChangePlanForPlanUpdateFormDocument = gql`
  mutation ChangePlanForPlanUpdateForm($input: ChangePlanInput!) {
    changePlan(input: $input)
  }
`;
export const StartProjectForProjectCodingEditorDocument = gql`
  mutation StartProjectForProjectCodingEditor($input: StartProjectInput!) {
    startProject(input: $input) {
      ...ProjectEntityForCandidateInterview
    }
  }
  ${ProjectEntityForCandidateInterviewFragmentDoc}
`;
export const StartDevelopmentWorkspaceForProjectCodingEditorDocument = gql`
  mutation StartDevelopmentWorkspaceForProjectCodingEditor($input: StartDevelopmentWorkspaceInput!) {
    startDevelopmentWorkspace(input: $input) {
      ...ProjectWorkspaceForProjectCodingEditor
    }
  }
  ${ProjectWorkspaceForProjectCodingEditorFragmentDoc}
`;
export const ResetDevelopmentWorkspaceForProjectCodingEditorDocument = gql`
  mutation ResetDevelopmentWorkspaceForProjectCodingEditor($input: ResetDevelopmentWorkspaceInput!) {
    resetDevelopmentWorkspace(input: $input) {
      ...ProjectWorkspaceForProjectCodingEditor
    }
  }
  ${ProjectWorkspaceForProjectCodingEditorFragmentDoc}
`;
export const SaveProjectAppealMessageForProjectCodingEditorDocument = gql`
  mutation SaveProjectAppealMessageForProjectCodingEditor($input: SaveProjectAppealMessageInput!) {
    saveProjectAppealMessage(input: $input) {
      projectEntityId
      appealMessage
    }
  }
`;
export const StartEvaluationWorkspaceForProjectCodingEditorDocument = gql`
  mutation StartEvaluationWorkspaceForProjectCodingEditor($input: StartEvaluationWorkspaceInput!) {
    startEvaluationWorkspace(input: $input) {
      ...ProjectWorkspaceForProjectCodingEditor
    }
  }
  ${ProjectWorkspaceForProjectCodingEditorFragmentDoc}
`;
export const ResetEvaluationWorkspaceForProjectCodingEditorDocument = gql`
  mutation ResetEvaluationWorkspaceForProjectCodingEditor($input: ResetEvaluationWorkspaceInput!) {
    resetEvaluationWorkspace(input: $input) {
      ...ProjectWorkspaceForProjectCodingEditor
    }
  }
  ${ProjectWorkspaceForProjectCodingEditorFragmentDoc}
`;
export const EnqueueProjectSubmissionForProjectCodingEditorDocument = gql`
  mutation EnqueueProjectSubmissionForProjectCodingEditor($input: EnqueueProjectSubmissionInput!) {
    enqueueProjectSubmission(input: $input) {
      projectSubmissionId
    }
  }
`;
export const RunProjectForProjectCodingEditorDocument = gql`
  mutation RunProjectForProjectCodingEditor($input: RunProjectInput!) {
    runProject(input: $input) {
      testCase
      testResult
      status
      failureReason
      isPassed
    }
  }
`;
export const GetProjectQuestionInitialCodeDocument = gql`
  query GetProjectQuestionInitialCode($questionId: Int!, $questionVersion: String!, $filePath: String!) {
    projectQuestionInitialFileBody(questionId: $questionId, questionVersion: $questionVersion, filePath: $filePath) {
      fileBody
    }
  }
`;
export const GetProjectStatisticsDocument = gql`
  query GetProjectStatistics($statisticsSource: StatisticsSourceInput!, $rankSource: RankSourceInput!) {
    statistics(statisticsSource: $statisticsSource) {
      projectStatistics {
        ...ProjectStatisticsForProjectRankVisualizer
      }
    }
    rank(rankSource: $rankSource) {
      ...RankForTestRankVisualizer
    }
  }
  ${ProjectStatisticsForProjectRankVisualizerFragmentDoc}
  ${RankForTestRankVisualizerFragmentDoc}
`;
export const ProjectSubmissionForProjectTestReportDocument = gql`
  query ProjectSubmissionForProjectTestReport($submissionId: Int!) {
    projectSubmission(submissionId: $submissionId) {
      ...ProjectSubmissionForProjectTestReport
    }
  }
  ${ProjectSubmissionForProjectTestReportFragmentDoc}
`;
export const GetProjectEntityForProjectTestReportDocument = gql`
  query GetProjectEntityForProjectTestReport($projectId: Int!, $withAnswers: Boolean!) {
    projectEntity(projectId: $projectId, withAnswers: $withAnswers) {
      ...ProjectEntityForProjectTestReport
    }
  }
  ${ProjectEntityForProjectTestReportFragmentDoc}
`;
export const GetHierarchicalSkillTagsForQuestionSearchAreaDocument = gql`
  query GetHierarchicalSkillTagsForQuestionSearchArea($input: HierarchicalSkillTagsInput!) {
    hierarchicalSkillTags(input: $input) {
      ...HierarchicalSkillTagsResponseForQuestionSearchArea
    }
  }
  ${HierarchicalSkillTagsResponseForQuestionSearchAreaFragmentDoc}
`;
export const ListQuestionsForQuestionSelectFieldForResourceEditorDocument = gql`
  query ListQuestionsForQuestionSelectFieldForResourceEditor($questionsInput: QuestionsInput!) {
    questions(questionsInput: $questionsInput) {
      ...QuestionsForQuestionSelectFieldForResourceEditor
    }
  }
  ${QuestionsForQuestionSelectFieldForResourceEditorFragmentDoc}
`;
export const ListQuestionsPackagesForQuestionSelectFieldForResourceEditorDocument = gql`
  query ListQuestionsPackagesForQuestionSelectFieldForResourceEditor(
    $size: Int!
    $offset: Int!
    $isDescending: Boolean!
    $sortMethod: QuestionPackageSortMethod!
    $filters: QuestionPackageFilters!
  ) {
    questionPackages(size: $size, offset: $offset, isDescending: $isDescending, sortMethod: $sortMethod, filters: $filters) {
      ...QuestionPackagesResponseForQuestionSelectFieldForResourceEditor
    }
  }
  ${QuestionPackagesResponseForQuestionSelectFieldForResourceEditorFragmentDoc}
`;
export const GetMultiQuestionsForQuestionSelectFieldForResourceEditorDocument = gql`
  query GetMultiQuestionsForQuestionSelectFieldForResourceEditor(
    $compositePrimaryKeys: [QuestionCompositePrimaryKey!]!
    $withAnswers: Boolean!
  ) {
    multiQuestions(compositePrimaryKeys: $compositePrimaryKeys, withAnswers: $withAnswers) {
      ...QuestionObjectForQuestionSelectFieldForResourceEditor
    }
  }
  ${QuestionObjectForQuestionSelectFieldForResourceEditorFragmentDoc}
`;
export const GetHierarchicalSkillTagsForQuestionSelectFieldForResourceEditorDocument = gql`
  query GetHierarchicalSkillTagsForQuestionSelectFieldForResourceEditor($input: HierarchicalSkillTagsInput!) {
    hierarchicalSkillTags(input: $input) {
      ...HierarchicalSkillTagsResponseForQuestionSelectFieldForResourceEditor
    }
  }
  ${HierarchicalSkillTagsResponseForQuestionSelectFieldForResourceEditorFragmentDoc}
`;
export const StartQuizForQuizCodingEditorDocument = gql`
  mutation StartQuizForQuizCodingEditor($quizId: Int!, $candidateId: String!) {
    startQuiz(quizId: $quizId, candidateId: $candidateId) {
      ...QuizEntityForCandidateInterview
    }
  }
  ${QuizEntityForCandidateInterviewFragmentDoc}
`;
export const SubmitQuizQuestionForQuizCodingEditorDocument = gql`
  mutation SubmitQuizQuestionForQuizCodingEditor($input: SubmitQuizQuestionInput!) {
    submitQuizQuestion(input: $input) {
      questionId
    }
  }
`;
export const TranslateMarkdownForQuizResourceEditorDocument = gql`
  mutation TranslateMarkdownForQuizResourceEditor($source: TranslateSource!) {
    translateMarkdown(source: $source)
  }
`;
export const GetQuizStatisticsDocument = gql`
  query GetQuizStatistics($statisticsSource: StatisticsSourceInput!, $rankSource: RankSourceInput!) {
    statistics(statisticsSource: $statisticsSource) {
      quizStatistics {
        ...QuizStatisticsForQuizRankVisualizer
      }
    }
    rank(rankSource: $rankSource) {
      ...RankForTestRankVisualizer
    }
  }
  ${QuizStatisticsForQuizRankVisualizerFragmentDoc}
  ${RankForTestRankVisualizerFragmentDoc}
`;
export const GetQuizEntityForQuizTestReportDocument = gql`
  query GetQuizEntityForQuizTestReport($quizId: Int!, $withAnswers: Boolean!) {
    quizEntity(quizId: $quizId, withAnswers: $withAnswers) {
      ...QuizEntityForQuizTestReport
    }
  }
  ${QuizEntityForQuizTestReportFragmentDoc}
`;
export const EvaluateQuizSubmissionDocument = gql`
  mutation EvaluateQuizSubmission($input: EvaluateQuizSubmissionInput!) {
    evaluateQuizSubmission(input: $input) {
      ...QuizSubmissionForQuizTestReport
    }
  }
  ${QuizSubmissionForQuizTestReportFragmentDoc}
`;
export const GetRemoteForRemoteInterviewFeedbackDocument = gql`
  query GetRemoteForRemoteInterviewFeedback($remoteId: String!) {
    remote(remoteId: $remoteId) {
      ...RemoteForRemoteInterviewFeedback
    }
  }
  ${RemoteForRemoteInterviewFeedbackFragmentDoc}
`;
export const GetRemoteForRemoteInterviewOverviewDocument = gql`
  query GetRemoteForRemoteInterviewOverview($remoteId: String!) {
    remote(remoteId: $remoteId) {
      ...RemoteForRemoteInterviewOverview
    }
  }
  ${RemoteForRemoteInterviewOverviewFragmentDoc}
`;
export const UpdateRemoteForRemoteInterviewOverviewDocument = gql`
  mutation UpdateRemoteForRemoteInterviewOverview($input: UpdateRemoteInput!) {
    updateRemote(input: $input) {
      ...RemoteForRemoteInterviewOverview
    }
  }
  ${RemoteForRemoteInterviewOverviewFragmentDoc}
`;
export const ListRemoteTagsForRemoteInterviewOverviewDocument = gql`
  query ListRemoteTagsForRemoteInterviewOverview($companyId: Int!, $cursorSeconds: Int, $size: Int!, $withCount: Boolean!) {
    remoteTagsByCompanyId(companyId: $companyId, cursorSeconds: $cursorSeconds, size: $size, withCount: $withCount) {
      tags {
        name
      }
      count
    }
  }
`;
export const GetMultiEmployeesDocument = gql`
  query GetMultiEmployees($uids: [String!]!) {
    employees(uids: $uids) {
      ...EmployeeForRemoteInterviewParticipants
    }
  }
  ${EmployeeForRemoteInterviewParticipantsFragmentDoc}
`;
export const GetRemoteForRemoteInterviewPlaybackDocument = gql`
  query GetRemoteForRemoteInterviewPlayback($remoteId: String!) {
    remote(remoteId: $remoteId) {
      ...RemoteForRemoteInterviewPlayback
    }
  }
  ${RemoteForRemoteInterviewPlaybackFragmentDoc}
`;
export const GetTemplateForRemoteInterviewTemplateListMenuDocument = gql`
  query GetTemplateForRemoteInterviewTemplateListMenu($templateId: Int!) {
    remoteInterviewTemplate(templateId: $templateId) {
      ...TemplateForRemoteInterviewTemplateListMenu
    }
  }
  ${TemplateForRemoteInterviewTemplateListMenuFragmentDoc}
`;
export const ListTemplatesForRemoteInterviewTemplateListMenuDocument = gql`
  query ListTemplatesForRemoteInterviewTemplateListMenu($input: RemoteInterviewTemplatesByCompanyIdInput!) {
    remoteInterviewTemplatesByCompanyId(input: $input) {
      templates {
        ...ListTemplateForRemoteInterviewTemplateListMenu
      }
      count
      pager {
        idCursor
        hasNext
      }
    }
  }
  ${ListTemplateForRemoteInterviewTemplateListMenuFragmentDoc}
`;
export const DeleteTemplateForRemoteInterviewTemplateListMenuDocument = gql`
  mutation DeleteTemplateForRemoteInterviewTemplateListMenu($id: Int!) {
    deleteRemoteInterviewTemplate(id: $id)
  }
`;
export const FillTemplateForRemoteInterviewTemplateListMenuDocument = gql`
  mutation FillTemplateForRemoteInterviewTemplateListMenu($input: FillTemplateInput!) {
    fillRemoteInterviewTemplate(input: $input) {
      ...FillTemplateForRemoteInterviewTemplateListMenu
    }
  }
  ${FillTemplateForRemoteInterviewTemplateListMenuFragmentDoc}
`;
export const GetMultiEmployeesForRemoteInterviewVideoChatParticipantsDocument = gql`
  query GetMultiEmployeesForRemoteInterviewVideoChatParticipants($uids: [String!]!) {
    employees(uids: $uids) {
      ...EmployeeForRemoteInterviewVideoChatParticipants
    }
  }
  ${EmployeeForRemoteInterviewVideoChatParticipantsFragmentDoc}
`;
export const GetScheduleEventsDocument = gql`
  query GetScheduleEvents($companyId: Int!, $size: Int!) {
    spotsForSchedule(companyId: $companyId, size: $size) {
      ...SpotForScheduleEvents
    }
    remotesForSchedule(companyId: $companyId, size: $size) {
      ...RemoteForScheduleEvents
    }
  }
  ${SpotForScheduleEventsFragmentDoc}
  ${RemoteForScheduleEventsFragmentDoc}
`;
export const StartRemoteForScheduleEventsDocument = gql`
  mutation StartRemoteForScheduleEvents($input: StartRemoteInput!) {
    startRemote(input: $input) {
      id
      name
      createdAtSeconds
      status
    }
  }
`;
export const GetScreeningForScreeningDetailDocument = gql`
  query GetScreeningForScreeningDetail($screeningId: String!, $invitationCode: String) {
    screening(screeningId: $screeningId, invitationCode: $invitationCode) {
      ...ScreeningForScreeningDetail
    }
  }
  ${ScreeningForScreeningDetailFragmentDoc}
`;
export const UpdateScreeningForScreeningDetailDocument = gql`
  mutation UpdateScreeningForScreeningDetail($input: UpdateScreeningInput!) {
    updateScreening(input: $input) {
      ...ScreeningForScreeningDetail
    }
  }
  ${ScreeningForScreeningDetailFragmentDoc}
`;
export const DeleteScreeningForScreeningDetailDocument = gql`
  mutation DeleteScreeningForScreeningDetail($input: DeleteScreeningInput!) {
    deleteScreening(input: $input)
  }
`;
export const ListSpotTagsForScreeningDetailDocument = gql`
  query ListSpotTagsForScreeningDetail($input: SpotTagsByCompanyIdInput!) {
    spotTagsByCompanyId(spotTagsByCompanyId: $input) {
      tags {
        name
      }
    }
  }
`;
export const InviteCandidateToScreeningForScreeningDetailDocument = gql`
  mutation InviteCandidateToScreeningForScreeningDetail($input: InviteCandidatesToScreeningInput!) {
    inviteCandidateToScreening(input: $input) {
      invitees {
        screeningId
        status
        email
      }
    }
  }
`;
export const ListSpotsByScreeningIdForScreeningDetailDocument = gql`
  query ListSpotsByScreeningIdForScreeningDetail($input: SpotsByScreeningIdInput!) {
    spotsByScreeningId(input: $input) {
      ...SpotsByScreeningIdResponseForScreeningDetail
    }
  }
  ${SpotsByScreeningIdResponseForScreeningDetailFragmentDoc}
`;
export const GetSpotTagsByCompanyIdForScreeningDetailDocument = gql`
  query GetSpotTagsByCompanyIdForScreeningDetail($spotTagsByCompanyId: SpotTagsByCompanyIdInput!) {
    spotTagsByCompanyId(spotTagsByCompanyId: $spotTagsByCompanyId) {
      ...SpotTagsByCompanyIdResponseForScreeningDetail
    }
  }
  ${SpotTagsByCompanyIdResponseForScreeningDetailFragmentDoc}
`;
export const ListSpotFilterOptionsByScreeningIdForScreeningDetailDocument = gql`
  query ListSpotFilterOptionsByScreeningIdForScreeningDetail($input: SpotFilterOptionsByScreeningInput!) {
    spotFilterOptionsByScreeningId(input: $input) {
      filledVariableCandidates {
        ...SpotFilledVariableCandidateForScreeningDetail
      }
    }
  }
  ${SpotFilledVariableCandidateForScreeningDetailFragmentDoc}
`;
export const EvaluateMultiSpotsForScreeningsDetailDocument = gql`
  mutation EvaluateMultiSpotsForScreeningsDetail($input: EvaluateMultiSpotsInput!) {
    evaluateMultiSpots(input: $input) {
      ...SpotForScreeningDetail
    }
  }
  ${SpotForScreeningDetailFragmentDoc}
`;
export const ListScreeningInviteeForScreeningInviteeListDocument = gql`
  query ListScreeningInviteeForScreeningInviteeList($input: ScreeningInviteesInput!) {
    screeningInvitees(input: $input) {
      ...ScreeningInviteesResponseForScreeningInviteeList
    }
  }
  ${ScreeningInviteesResponseForScreeningInviteeListFragmentDoc}
`;
export const GetScreeningResourceEditorDocument = gql`
  query GetScreeningResourceEditor($screeningId: String!) {
    screening(screeningId: $screeningId) {
      ...ScreeningForScreeningResourceEditor
    }
  }
  ${ScreeningForScreeningResourceEditorFragmentDoc}
`;
export const UpdateSpotForScreeningTestOverviewDocument = gql`
  mutation UpdateSpotForScreeningTestOverview($input: UpdateSpotInput!) {
    updateSpot(input: $input) {
      ...InterviewOverview
    }
  }
  ${InterviewOverviewFragmentDoc}
`;
export const ListSpotTagsForScreeningTestOverviewDocument = gql`
  query ListSpotTagsForScreeningTestOverview($input: SpotTagsByCompanyIdInput!) {
    spotTagsByCompanyId(spotTagsByCompanyId: $input) {
      tags {
        __typename
        name
      }
      pager {
        hasNext
        cursorSeconds
      }
      count
    }
  }
`;
export const GetScreeningTestResourceEditorDocument = gql`
  query GetScreeningTestResourceEditor($spotId: String!, $withAnswers: Boolean!) {
    spot(id: $spotId, withAnswers: $withAnswers) {
      ...SpotForScreeningTestResourceEditor
    }
  }
  ${SpotForScreeningTestResourceEditorFragmentDoc}
`;
export const GetCompanyForSecuritySettingsDocument = gql`
  query GetCompanyForSecuritySettings($companyId: Int!) {
    company(companyId: $companyId) {
      id
      security {
        ...GetCompanyForSecuritySettingsSecurity
      }
    }
  }
  ${GetCompanyForSecuritySettingsSecurityFragmentDoc}
`;
export const GetSecurityByEmailDocument = gql`
  query GetSecurityByEmail($email: String!) {
    securityByEmail(email: $email) {
      providerId
    }
  }
`;
export const UpdateSecurityDocument = gql`
  mutation UpdateSecurity($updateSecurityInput: UpdateSecurityInput!) {
    updateSecurity(updateSecurityInput: $updateSecurityInput) {
      emailDomain
      idpCertificate
      idpEntityId
      idpSsoUrl
      providerId
      ssoEnabled
      ssoMode
    }
  }
`;
export const AuthorizeSlackForSlackNotificationSettingsDocument = gql`
  mutation AuthorizeSlackForSlackNotificationSettings($input: AuthorizeSlackInput!) {
    authorizeSlack(input: $input) {
      companyId
      isAuthorized
      incomingWebHook {
        ...UpdateSlackSettingsForIncomingWebHook
      }
    }
  }
  ${UpdateSlackSettingsForIncomingWebHookFragmentDoc}
`;
export const ListSpotTagsForSpotTagFieldDocument = gql`
  query ListSpotTagsForSpotTagField($spotTagsByCompanyId: SpotTagsByCompanyIdInput!) {
    spotTagsByCompanyId(spotTagsByCompanyId: $spotTagsByCompanyId) {
      ...SpotTagsByCompanyIdResponseForSpotTagField
    }
  }
  ${SpotTagsByCompanyIdResponseForSpotTagFieldFragmentDoc}
`;
export const GetInvoicesForSubscriptionInvoiceV2Document = gql`
  query GetInvoicesForSubscriptionInvoiceV2($customerId: Int!, $cursorSeconds: Int, $cursorNanoSeconds: Int, $size: Int!) {
    invoicesV2(customerId: $customerId, cursorSeconds: $cursorSeconds, cursorNanoSeconds: $cursorNanoSeconds, size: $size) {
      ...InvoicesResponseForSubscriptionInvoiceV2
    }
  }
  ${InvoicesResponseForSubscriptionInvoiceV2FragmentDoc}
`;
export const GetContractPeriodUsagesForSubscriptionOverviewV2Document = gql`
  query GetContractPeriodUsagesForSubscriptionOverviewV2($customerId: Int!, $startedAtSeconds: Int!, $contractPeriod: Int!) {
    contractPeriodUsagesV2(customerId: $customerId, startedAtSeconds: $startedAtSeconds, contractPeriod: $contractPeriod) {
      contractPeriodUsages {
        ...ContractPeriodUsageForSubscriptionOverviewV2
      }
    }
    customer(customerId: $customerId) {
      ...CustomerForSubscriptionOverviewV2
    }
  }
  ${ContractPeriodUsageForSubscriptionOverviewV2FragmentDoc}
  ${CustomerForSubscriptionOverviewV2FragmentDoc}
`;
export const GetSubscribeUsagesForSubscriptionUsageV2Document = gql`
  query GetSubscribeUsagesForSubscriptionUsageV2(
    $customerId: Int!
    $usagePeriodStartSeconds: Int!
    $cursorSeconds: Int
    $cursorNanoSeconds: Int
    $size: Int!
  ) {
    usagesV2(
      customerId: $customerId
      usagePeriodStartSeconds: $usagePeriodStartSeconds
      cursorSeconds: $cursorSeconds
      cursorNanoSeconds: $cursorNanoSeconds
      size: $size
    ) {
      ...UsagesV2ResponseForSubscriptionUsageV2
    }
  }
  ${UsagesV2ResponseForSubscriptionUsageV2FragmentDoc}
`;
export const RunSystemDesignDocument = gql`
  mutation RunSystemDesign($systemDesignId: Int!, $snapshot: String!, $scoringItemId: Int!, $takeSnapshot: Boolean!) {
    runSystemDesign(systemDesignId: $systemDesignId, snapshot: $snapshot, scoringItemId: $scoringItemId, takeSnapshot: $takeSnapshot) {
      isPassed
      matchedIds
      messageJa
      messageEn
      snapshotId
    }
  }
`;
export const SaveSystemDesignAppealMessageDocument = gql`
  mutation SaveSystemDesignAppealMessage($systemDesignId: Int!, $appealMessage: String!) {
    saveSystemDesignAppealMessage(systemDesignId: $systemDesignId, appealMessage: $appealMessage) {
      systemDesignEntityId
      appealMessage
    }
  }
`;
export const EnqueueSystemDesignSubmissionDocument = gql`
  mutation EnqueueSystemDesignSubmission($systemDesignId: Int!, $questionId: Int!, $snapshot: String!) {
    enqueueSystemDesignSubmission(systemDesignId: $systemDesignId, questionId: $questionId, snapshot: $snapshot) {
      systemDesignSubmissionId
      status
    }
  }
`;
export const StartSystemDesignDocument = gql`
  mutation StartSystemDesign($systemDesignId: Int!, $candidateId: String!) {
    startSystemDesign(systemDesignId: $systemDesignId, candidateId: $candidateId) {
      ...SystemDesignEntityForCandidateInterview
    }
  }
  ${SystemDesignEntityForCandidateInterviewFragmentDoc}
`;
export const GetSystemDesignStatisticsDocument = gql`
  query GetSystemDesignStatistics($statisticsSource: StatisticsSourceInput!, $rankSource: RankSourceInput!) {
    statistics(statisticsSource: $statisticsSource) {
      systemDesignStatistics {
        ...SystemDesignStatisticsForSystemDesignRankVisualizer
      }
    }
    rank(rankSource: $rankSource) {
      ...RankForTestRankVisualizer
    }
  }
  ${SystemDesignStatisticsForSystemDesignRankVisualizerFragmentDoc}
  ${RankForTestRankVisualizerFragmentDoc}
`;
export const GetSystemDesignSubmissionForSystemDesignTestReportDocument = gql`
  query GetSystemDesignSubmissionForSystemDesignTestReport($submissionId: Int!) {
    systemDesignSubmission(submissionId: $submissionId) {
      ...SystemDesignSubmissionForSystemDesignTestReport
    }
  }
  ${SystemDesignSubmissionForSystemDesignTestReportFragmentDoc}
`;
export const GetSystemDesignEntityForSystemDesignTestReportDocument = gql`
  query GetSystemDesignEntityForSystemDesignTestReport($systemDesignId: Int!, $withAnswers: Boolean!) {
    systemDesignEntity(systemDesignId: $systemDesignId, withAnswers: $withAnswers) {
      ...SystemDesignEntityForSystemDesignTestReport
    }
  }
  ${SystemDesignEntityForSystemDesignTestReportFragmentDoc}
`;
export const ReevaluateSystemDesignDocument = gql`
  mutation ReevaluateSystemDesign($systemDesignId: Int!, $submissionId: Int!, $evaluationSources: [EvaluationSourceInput!]!) {
    reevaluateSystemDesign(systemDesignId: $systemDesignId, submissionId: $submissionId, evaluationSources: $evaluationSources) {
      submission {
        systemDesign {
          ...SystemDesignEntityForSystemDesignTestReport
        }
      }
    }
  }
  ${SystemDesignEntityForSystemDesignTestReportFragmentDoc}
`;
export const GetTalentsForTalentAssignFieldDocument = gql`
  query GetTalentsForTalentAssignField($companyId: Int!) {
    company(companyId: $companyId) {
      ...CompanyForTalentAssignField
    }
  }
  ${CompanyForTalentAssignFieldFragmentDoc}
`;
export const GetCompanyForTalentMemberSettingsDocument = gql`
  query GetCompanyForTalentMemberSettings($companyId: Int!) {
    company(companyId: $companyId) {
      ...CompanyForTalentMemberSettings
    }
  }
  ${CompanyForTalentMemberSettingsFragmentDoc}
`;
export const CreateTalentInvitationDocument = gql`
  mutation CreateTalentInvitation($input: CreateTalentInvitationInput!) {
    createTalentInvitation(input: $input) {
      talentInvitationId
      companyId
      createdBy
      invitationMethod
      emailAddresses
      expiresAtSeconds
      invitationLanguage
    }
  }
`;
export const DeleteMultiCompanyTalentsForTalentMemberSettingsDocument = gql`
  mutation DeleteMultiCompanyTalentsForTalentMemberSettings($companyId: Int!, $talentIds: [String!]!) {
    deleteMultiCompanyTalents(companyId: $companyId, talentIds: $talentIds)
  }
`;
export const DeleteCompanyTalentForTalentMemberSettingsDocument = gql`
  mutation DeleteCompanyTalentForTalentMemberSettings($input: DeleteCompanyTalentInput!) {
    deleteCompanyTalent(input: $input)
  }
`;
export const ListEvaluationMetricsForTechnicalCommentEvaluationsFormDocument = gql`
  query ListEvaluationMetricsForTechnicalCommentEvaluationsForm($companyId: Int!, $size: Int!, $cursor: Int, $withCount: Boolean) {
    evaluationMetricsByCompany(companyId: $companyId, size: $size, cursor: $cursor, withCount: $withCount) {
      metrics {
        ...EvaluationMetricsForTechnicalCommentEvaluationsForm
      }
      count
      pager {
        cursor
        hasNext
      }
    }
  }
  ${EvaluationMetricsForTechnicalCommentEvaluationsFormFragmentDoc}
`;
export const ReviewExamForTechnicalCommentDocument = gql`
  mutation ReviewExamForTechnicalComment($input: ReviewExamRequestInput!) {
    reviewExam(input: $input) {
      ...ExamForAssessmentFeedback
    }
  }
  ${ExamForAssessmentFeedbackFragmentDoc}
`;
export const ListEvaluationMetricGroupsByCompanyIdForTechnicalCommentDocument = gql`
  query ListEvaluationMetricGroupsByCompanyIdForTechnicalComment($companyId: Int!, $size: Int!, $cursor: Int, $withCount: Boolean) {
    evaluationMetricGroupsByCompany(companyId: $companyId, size: $size, cursor: $cursor, withCount: $withCount) {
      __typename
      metricGroups {
        ...EvaluationMetricGroupsForTechnicalComment
      }
      pager {
        cursor
        hasNext
      }
    }
  }
  ${EvaluationMetricGroupsForTechnicalCommentFragmentDoc}
`;
export const GetEvaluationMetricGroupForTechnicalCommentDocument = gql`
  query GetEvaluationMetricGroupForTechnicalComment($metricGroupId: Int!) {
    evaluationMetricGroup(metricGroupId: $metricGroupId) {
      ...EvaluationMetricGroupsForTechnicalComment
    }
  }
  ${EvaluationMetricGroupsForTechnicalCommentFragmentDoc}
`;
export const ReviewSpotForTechnicalCommentDocument = gql`
  mutation ReviewSpotForTechnicalComment($payload: ReviewSpotInput!) {
    reviewSpot(payload: $payload) {
      ...InterviewFeedback
    }
  }
  ${InterviewFeedbackFragmentDoc}
`;
export const GetEvaluationMetricsForTechnicalCommentForRemoteDocument = gql`
  query GetEvaluationMetricsForTechnicalCommentForRemote($metricsIds: [Int!]!) {
    evaluationMetricsByIds(metricIds: $metricsIds) {
      ...EvaluationMetricForTechnicalCommentRemote
    }
  }
  ${EvaluationMetricForTechnicalCommentRemoteFragmentDoc}
`;
export const ListEvaluationMetricsByCompanyIdForTechnicalCommentPromptForRemoteDocument = gql`
  query ListEvaluationMetricsByCompanyIdForTechnicalCommentPromptForRemote($companyId: Int!, $size: Int!, $cursor: Int, $withCount: Boolean) {
    evaluationMetricsByCompany(companyId: $companyId, size: $size, cursor: $cursor, withCount: $withCount) {
      __typename
      metrics {
        ...EvaluationMetricForTechnicalCommentRemote
      }
      pager {
        cursor
        hasNext
      }
    }
  }
  ${EvaluationMetricForTechnicalCommentRemoteFragmentDoc}
`;
export const ReviewRemoteForTechnicalCommentRemoteDocument = gql`
  mutation ReviewRemoteForTechnicalCommentRemote($input: ReviewRemoteInput!) {
    reviewRemote(input: $input) {
      ...RemoteForRemoteInterviewFeedback
    }
  }
  ${RemoteForRemoteInterviewFeedbackFragmentDoc}
`;
export const ListSpotsByTagsForTestRankVisualizerDocument = gql`
  query ListSpotsByTagsForTestRankVisualizer($spotsByCompanyId: SpotsByCompanyIdInput!) {
    spotsByCompanyId(spotsByCompanyId: $spotsByCompanyId) {
      spots {
        ...SpotForTestRankVisualizer
      }
      count
      hasNext
      pager {
        hasNext
        cursorSeconds
      }
    }
  }
  ${SpotForTestRankVisualizerFragmentDoc}
`;
export const GetSpotStatisticsAndRankForTestRankVisualizerDocument = gql`
  query GetSpotStatisticsAndRankForTestRankVisualizer($statisticsSource: StatisticsSourceInput!, $rankSource: RankSourceInput!) {
    statistics(statisticsSource: $statisticsSource) {
      __typename
      spotStatistics {
        ...SpotStatisticsForTestRankVisualizer
      }
    }
    rank(rankSource: $rankSource) {
      ...RankForTestRankVisualizer
    }
  }
  ${SpotStatisticsForTestRankVisualizerFragmentDoc}
  ${RankForTestRankVisualizerFragmentDoc}
`;
export const ListSpotTagsForTestRankVisualizerDocument = gql`
  query ListSpotTagsForTestRankVisualizer($spotTagsByCompanyId: SpotTagsByCompanyIdInput!) {
    spotTagsByCompanyId(spotTagsByCompanyId: $spotTagsByCompanyId) {
      ...ListSpotTagsForTestRankVisualizer
    }
  }
  ${ListSpotTagsForTestRankVisualizerFragmentDoc}
`;
export const UpdateUserForUserProfileDocument = gql`
  mutation UpdateUserForUserProfile($updateUserInput: UpdateUserInput!) {
    updateUser(updateUserInput: $updateUserInput) {
      ...EssentialEmployee
    }
  }
  ${EssentialEmployeeFragmentDoc}
`;
export const UpdateUserLanguageDocument = gql`
  mutation UpdateUserLanguage($updateUserInput: UpdateUserInput!) {
    updateUser(updateUserInput: $updateUserInput) {
      ...EssentialEmployee
    }
  }
  ${EssentialEmployeeFragmentDoc}
`;
export const UploadUserImageForUserProfileDocument = gql`
  mutation UploadUserImageForUserProfile($uid: ID!, $image: String!) {
    uploadUserImage(uid: $uid, image: $image)
  }
`;
export const GetViewersForViewerAssignFieldDocument = gql`
  query GetViewersForViewerAssignField($companyId: Int!) {
    company(companyId: $companyId) {
      ...CompanyForViewerAssignField
    }
  }
  ${CompanyForViewerAssignFieldFragmentDoc}
`;
export const GetAssessmentsByTalentIdForExamTabTablesForTalentDocument = gql`
  query GetAssessmentsByTalentIdForExamTabTablesForTalent(
    $talentId: String!
    $withCount: Boolean!
    $withDeleted: Boolean!
    $size: Int!
    $offset: Int!
    $isDescending: Boolean!
  ) {
    listAssessmentsByTalentId(
      talentId: $talentId
      withCount: $withCount
      withDeleted: $withDeleted
      size: $size
      offset: $offset
      isDescending: $isDescending
    ) {
      ...ListAssessmentsByTalentIdForExamTabTablesForTalent
    }
  }
  ${ListAssessmentsByTalentIdForExamTabTablesForTalentFragmentDoc}
`;
export const ListBestExamsByAssessmentIdForExamListForTalentDocument = gql`
  query ListBestExamsByAssessmentIdForExamListForTalent(
    $assessmentId: String!
    $withCount: Boolean!
    $size: Int!
    $offset: Int!
    $isDescending: Boolean!
  ) {
    listBestExamsByAssessmentId(assessmentId: $assessmentId, withCount: $withCount, size: $size, offset: $offset, isDescending: $isDescending) {
      ...ListBestExamsByAssessmentIdForExamTables
    }
  }
  ${ListBestExamsByAssessmentIdForExamTablesFragmentDoc}
`;
export const ReCreateExamForExamListForTalentDocument = gql`
  mutation ReCreateExamForExamListForTalent($input: RecreateExamRequestInput!) {
    recreateExam(input: $input) {
      examId
    }
  }
`;
export const GetTailExamForExamListForTalentDocument = gql`
  query GetTailExamForExamListForTalent($examId: String!) {
    tailExam(examId: $examId) {
      examId
      status
    }
  }
`;
export const ListTalentNotificationsForNotificationListForTalentDocument = gql`
  query ListTalentNotificationsForNotificationListForTalent(
    $talentId: String!
    $companyId: Int!
    $cursor: Int
    $size: Int!
    $withCount: Boolean!
  ) {
    talentNotifications(talentId: $talentId, companyId: $companyId, cursor: $cursor, size: $size, withCount: $withCount) {
      ...TalentNotificationsResponseForNotificationListForTalent
    }
  }
  ${TalentNotificationsResponseForNotificationListForTalentFragmentDoc}
`;
export const ReadTalentNotificationsForNotificationListForTalentDocument = gql`
  mutation ReadTalentNotificationsForNotificationListForTalent($talentNotificationIds: [Int!]!, $talentId: String!, $companyId: Int!) {
    readTalentNotifications(talentNotificationIds: $talentNotificationIds, talentId: $talentId, companyId: $companyId) {
      ...TalentNotificationForNotificationListForTalent
    }
  }
  ${TalentNotificationForNotificationListForTalentFragmentDoc}
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any,
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    UpdateClaimsForCandidate(
      variables: UpdateClaimsForCandidateMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateClaimsForCandidateMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateClaimsForCandidateMutation>(UpdateClaimsForCandidateDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "UpdateClaimsForCandidate",
        "mutation",
        variables,
      );
    },
    UpdateUserTypeForCandidate(
      variables: UpdateUserTypeForCandidateMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateUserTypeForCandidateMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateUserTypeForCandidateMutation>(UpdateUserTypeForCandidateDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "UpdateUserTypeForCandidate",
        "mutation",
        variables,
      );
    },
    InitializeForEmployee(
      variables: InitializeForEmployeeQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<InitializeForEmployeeQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<InitializeForEmployeeQuery>(InitializeForEmployeeDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "InitializeForEmployee",
        "query",
        variables,
      );
    },
    GetEssentialCompanyWithSecurityForEmployee(
      variables: GetEssentialCompanyWithSecurityForEmployeeQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetEssentialCompanyWithSecurityForEmployeeQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetEssentialCompanyWithSecurityForEmployeeQuery>(GetEssentialCompanyWithSecurityForEmployeeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetEssentialCompanyWithSecurityForEmployee",
        "query",
        variables,
      );
    },
    GetEssentialCustomerForEmployee(
      variables: GetEssentialCustomerForEmployeeQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetEssentialCustomerForEmployeeQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetEssentialCustomerForEmployeeQuery>(GetEssentialCustomerForEmployeeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetEssentialCustomerForEmployee",
        "query",
        variables,
      );
    },
    CurrentUser(variables: CurrentUserQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CurrentUserQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CurrentUserQuery>(CurrentUserDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "CurrentUser",
        "query",
        variables,
      );
    },
    UpdateClaimsForEmployee(
      variables: UpdateClaimsForEmployeeMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateClaimsForEmployeeMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateClaimsForEmployeeMutation>(UpdateClaimsForEmployeeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "UpdateClaimsForEmployee",
        "mutation",
        variables,
      );
    },
    SwitchActiveCompanyForEmployee(
      variables: SwitchActiveCompanyForEmployeeMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SwitchActiveCompanyForEmployeeMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<SwitchActiveCompanyForEmployeeMutation>(SwitchActiveCompanyForEmployeeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "SwitchActiveCompanyForEmployee",
        "mutation",
        variables,
      );
    },
    GetCurrentUser(variables: GetCurrentUserQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetCurrentUserQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetCurrentUserQuery>(GetCurrentUserDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "GetCurrentUser",
        "query",
        variables,
      );
    },
    UpdateUserTypeForSignedInUser(
      variables: UpdateUserTypeForSignedInUserMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateUserTypeForSignedInUserMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateUserTypeForSignedInUserMutation>(UpdateUserTypeForSignedInUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "UpdateUserTypeForSignedInUser",
        "mutation",
        variables,
      );
    },
    InitializeApp(variables: InitializeAppMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<InitializeAppMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<InitializeAppMutation>(InitializeAppDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "InitializeApp",
        "mutation",
        variables,
      );
    },
    SwitchActiveCompanyForEmployeeV2(
      variables: SwitchActiveCompanyForEmployeeV2MutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SwitchActiveCompanyForEmployeeV2Mutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<SwitchActiveCompanyForEmployeeV2Mutation>(SwitchActiveCompanyForEmployeeV2Document, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "SwitchActiveCompanyForEmployeeV2",
        "mutation",
        variables,
      );
    },
    SwitchActiveCompanyForTalentV2(
      variables: SwitchActiveCompanyForTalentV2MutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SwitchActiveCompanyForTalentV2Mutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<SwitchActiveCompanyForTalentV2Mutation>(SwitchActiveCompanyForTalentV2Document, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "SwitchActiveCompanyForTalentV2",
        "mutation",
        variables,
      );
    },
    UpdateClaimsV2(variables: UpdateClaimsV2MutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateClaimsV2Mutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateClaimsV2Mutation>(UpdateClaimsV2Document, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "UpdateClaimsV2",
        "mutation",
        variables,
      );
    },
    InitializeForTalent(
      variables: InitializeForTalentQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<InitializeForTalentQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<InitializeForTalentQuery>(InitializeForTalentDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "InitializeForTalent",
        "query",
        variables,
      );
    },
    SwitchActiveCompanyForTalent(
      variables: SwitchActiveCompanyForTalentMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SwitchActiveCompanyForTalentMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<SwitchActiveCompanyForTalentMutation>(SwitchActiveCompanyForTalentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "SwitchActiveCompanyForTalent",
        "mutation",
        variables,
      );
    },
    GetEssentialCustomerForTalent(
      variables: GetEssentialCustomerForTalentQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetEssentialCustomerForTalentQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetEssentialCustomerForTalentQuery>(GetEssentialCustomerForTalentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetEssentialCustomerForTalent",
        "query",
        variables,
      );
    },
    UpdateClaimsForTalent(
      variables: UpdateClaimsForTalentMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateClaimsForTalentMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateClaimsForTalentMutation>(UpdateClaimsForTalentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "UpdateClaimsForTalent",
        "mutation",
        variables,
      );
    },
    GetEssentialCompanyWithSecurityForTalent(
      variables: GetEssentialCompanyWithSecurityForTalentQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetEssentialCompanyWithSecurityForTalentQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetEssentialCompanyWithSecurityForTalentQuery>(GetEssentialCompanyWithSecurityForTalentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetEssentialCompanyWithSecurityForTalent",
        "query",
        variables,
      );
    },
    GetDemoForCandidateDemosId(
      variables: GetDemoForCandidateDemosIdQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetDemoForCandidateDemosIdQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetDemoForCandidateDemosIdQuery>(GetDemoForCandidateDemosIdDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetDemoForCandidateDemosId",
        "query",
        variables,
      );
    },
    CreateDemoFromSpot(
      variables: CreateDemoFromSpotMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CreateDemoFromSpotMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreateDemoFromSpotMutation>(CreateDemoFromSpotDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "CreateDemoFromSpot",
        "mutation",
        variables,
      );
    },
    EndDemo(variables: EndDemoMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<EndDemoMutation> {
      return withWrapper(
        wrappedRequestHeaders => client.request<EndDemoMutation>(EndDemoDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "EndDemo",
        "mutation",
        variables,
      );
    },
    GetDemoForCandidateDemosStart(
      variables: GetDemoForCandidateDemosStartQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetDemoForCandidateDemosStartQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetDemoForCandidateDemosStartQuery>(GetDemoForCandidateDemosStartDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetDemoForCandidateDemosStart",
        "query",
        variables,
      );
    },
    GetRemotesForCandidate(
      variables: GetRemotesForCandidateQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetRemotesForCandidateQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetRemotesForCandidateQuery>(GetRemotesForCandidateDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetRemotesForCandidate",
        "query",
        variables,
      );
    },
    GetLiveCodingForCandidate(
      variables: GetLiveCodingForCandidateQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetLiveCodingForCandidateQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetLiveCodingForCandidateQuery>(GetLiveCodingForCandidateDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetLiveCodingForCandidate",
        "query",
        variables,
      );
    },
    AddLiveCodingVideoChatRoomForCandidate(
      variables: AddLiveCodingVideoChatRoomForCandidateMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AddLiveCodingVideoChatRoomForCandidateMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<AddLiveCodingVideoChatRoomForCandidateMutation>(AddLiveCodingVideoChatRoomForCandidateDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "AddLiveCodingVideoChatRoomForCandidate",
        "mutation",
        variables,
      );
    },
    AddLiveCodingVideoChatConversationForCandidate(
      variables: AddLiveCodingVideoChatConversationForCandidateMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AddLiveCodingVideoChatConversationForCandidateMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<AddLiveCodingVideoChatConversationForCandidateMutation>(
            AddLiveCodingVideoChatConversationForCandidateDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "AddLiveCodingVideoChatConversationForCandidate",
        "mutation",
        variables,
      );
    },
    JoinLiveCodingForCandidate(
      variables: JoinLiveCodingForCandidateMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<JoinLiveCodingForCandidateMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<JoinLiveCodingForCandidateMutation>(JoinLiveCodingForCandidateDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "JoinLiveCodingForCandidate",
        "mutation",
        variables,
      );
    },
    JoinVideoChatRoomCandidate(
      variables: JoinVideoChatRoomCandidateMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<JoinVideoChatRoomCandidateMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<JoinVideoChatRoomCandidateMutation>(JoinVideoChatRoomCandidateDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "JoinVideoChatRoomCandidate",
        "mutation",
        variables,
      );
    },
    JoinVideoChatConversationForCandidate(
      variables: JoinVideoChatConversationForCandidateMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<JoinVideoChatConversationForCandidateMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<JoinVideoChatConversationForCandidateMutation>(JoinVideoChatConversationForCandidateDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "JoinVideoChatConversationForCandidate",
        "mutation",
        variables,
      );
    },
    GetVideoChatConversationForCandidate(
      variables: GetVideoChatConversationForCandidateQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetVideoChatConversationForCandidateQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetVideoChatConversationForCandidateQuery>(GetVideoChatConversationForCandidateDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetVideoChatConversationForCandidate",
        "query",
        variables,
      );
    },
    GetScreeningForScreeningsId(
      variables: GetScreeningForScreeningsIdQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetScreeningForScreeningsIdQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetScreeningForScreeningsIdQuery>(GetScreeningForScreeningsIdDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetScreeningForScreeningsId",
        "query",
        variables,
      );
    },
    AcceptScreeningForScreeningsId(
      variables: AcceptScreeningForScreeningsIdMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AcceptScreeningForScreeningsIdMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<AcceptScreeningForScreeningsIdMutation>(AcceptScreeningForScreeningsIdDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "AcceptScreeningForScreeningsId",
        "mutation",
        variables,
      );
    },
    StartSpotForScreeningsId(
      variables: StartSpotForScreeningsIdMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<StartSpotForScreeningsIdMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<StartSpotForScreeningsIdMutation>(StartSpotForScreeningsIdDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "StartSpotForScreeningsId",
        "mutation",
        variables,
      );
    },
    CreateDemoFromScreeningForScreeningId(
      variables: CreateDemoFromScreeningForScreeningIdMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CreateDemoFromScreeningForScreeningIdMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreateDemoFromScreeningForScreeningIdMutation>(CreateDemoFromScreeningForScreeningIdDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "CreateDemoFromScreeningForScreeningId",
        "mutation",
        variables,
      );
    },
    LinkSpotToSonarIntegrationForScreeningsId(
      variables: LinkSpotToSonarIntegrationForScreeningsIdMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<LinkSpotToSonarIntegrationForScreeningsIdMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<LinkSpotToSonarIntegrationForScreeningsIdMutation>(LinkSpotToSonarIntegrationForScreeningsIdDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "LinkSpotToSonarIntegrationForScreeningsId",
        "mutation",
        variables,
      );
    },
    DeleteSpotForScreeningsId(
      variables: DeleteSpotForScreeningsIdMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteSpotForScreeningsIdMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteSpotForScreeningsIdMutation>(DeleteSpotForScreeningsIdDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "DeleteSpotForScreeningsId",
        "mutation",
        variables,
      );
    },
    GetSpotForEntryScreeningsTestsId(
      variables: GetSpotForEntryScreeningsTestsIdQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetSpotForEntryScreeningsTestsIdQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetSpotForEntryScreeningsTestsIdQuery>(GetSpotForEntryScreeningsTestsIdDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetSpotForEntryScreeningsTestsId",
        "query",
        variables,
      );
    },
    GetSpotForCandidateInterview(
      variables: GetSpotForCandidateInterviewQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetSpotForCandidateInterviewQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetSpotForCandidateInterviewQuery>(GetSpotForCandidateInterviewDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetSpotForCandidateInterview",
        "query",
        variables,
      );
    },
    GetSpotForEntryInterview(
      variables: GetSpotForEntryInterviewQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetSpotForEntryInterviewQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetSpotForEntryInterviewQuery>(GetSpotForEntryInterviewDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetSpotForEntryInterview",
        "query",
        variables,
      );
    },
    StartSpot(variables: StartSpotMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<StartSpotMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<StartSpotMutation>(StartSpotDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "StartSpot",
        "mutation",
        variables,
      );
    },
    EndSpot(variables: EndSpotMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<EndSpotMutation> {
      return withWrapper(
        wrappedRequestHeaders => client.request<EndSpotMutation>(EndSpotDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "EndSpot",
        "mutation",
        variables,
      );
    },
    GetInterviewDetailFromSharedLink(
      variables: GetInterviewDetailFromSharedLinkQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetInterviewDetailFromSharedLinkQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetInterviewDetailFromSharedLinkQuery>(GetInterviewDetailFromSharedLinkDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetInterviewDetailFromSharedLink",
        "query",
        variables,
      );
    },
    ListAssessmentsByCompanyIdForEmployeeAssessments(
      variables: ListAssessmentsByCompanyIdForEmployeeAssessmentsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListAssessmentsByCompanyIdForEmployeeAssessmentsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListAssessmentsByCompanyIdForEmployeeAssessmentsQuery>(
            ListAssessmentsByCompanyIdForEmployeeAssessmentsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "ListAssessmentsByCompanyIdForEmployeeAssessments",
        "query",
        variables,
      );
    },
    GetAssessmentSearchParamsForEmployeeAssessmentsSearchParams(
      variables: GetAssessmentSearchParamsForEmployeeAssessmentsSearchParamsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetAssessmentSearchParamsForEmployeeAssessmentsSearchParamsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetAssessmentSearchParamsForEmployeeAssessmentsSearchParamsQuery>(
            GetAssessmentSearchParamsForEmployeeAssessmentsSearchParamsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "GetAssessmentSearchParamsForEmployeeAssessmentsSearchParams",
        "query",
        variables,
      );
    },
    createExamImmediately(
      variables: CreateExamImmediatelyMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CreateExamImmediatelyMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreateExamImmediatelyMutation>(CreateExamImmediatelyDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "createExamImmediately",
        "mutation",
        variables,
      );
    },
    DeleteAssessmentForEmployeeAssessments(
      variables: DeleteAssessmentForEmployeeAssessmentsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteAssessmentForEmployeeAssessmentsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteAssessmentForEmployeeAssessmentsMutation>(DeleteAssessmentForEmployeeAssessmentsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "DeleteAssessmentForEmployeeAssessments",
        "mutation",
        variables,
      );
    },
    UpdateAssessmentForEmployeeAssessments(
      variables: UpdateAssessmentForEmployeeAssessmentsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateAssessmentForEmployeeAssessmentsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateAssessmentForEmployeeAssessmentsMutation>(UpdateAssessmentForEmployeeAssessmentsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "UpdateAssessmentForEmployeeAssessments",
        "mutation",
        variables,
      );
    },
    UpdateAssessmentReportShareSettingsForEmployeeAssessments(
      variables: UpdateAssessmentReportShareSettingsForEmployeeAssessmentsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateAssessmentReportShareSettingsForEmployeeAssessmentsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateAssessmentReportShareSettingsForEmployeeAssessmentsMutation>(
            UpdateAssessmentReportShareSettingsForEmployeeAssessmentsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "UpdateAssessmentReportShareSettingsForEmployeeAssessments",
        "mutation",
        variables,
      );
    },
    CreateAssessmentForAssessmentsCreate(
      variables: CreateAssessmentForAssessmentsCreateMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CreateAssessmentForAssessmentsCreateMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreateAssessmentForAssessmentsCreateMutation>(CreateAssessmentForAssessmentsCreateDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "CreateAssessmentForAssessmentsCreate",
        "mutation",
        variables,
      );
    },
    GetAssessmentForAssessmentsIdDetailEmployee(
      variables: GetAssessmentForAssessmentsIdDetailEmployeeQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetAssessmentForAssessmentsIdDetailEmployeeQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetAssessmentForAssessmentsIdDetailEmployeeQuery>(GetAssessmentForAssessmentsIdDetailEmployeeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetAssessmentForAssessmentsIdDetailEmployee",
        "query",
        variables,
      );
    },
    ListExamsRecursiveForAssessmentsIdDetailEmployee(
      variables: ListExamsRecursiveForAssessmentsIdDetailEmployeeQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListExamsRecursiveForAssessmentsIdDetailEmployeeQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListExamsRecursiveForAssessmentsIdDetailEmployeeQuery>(
            ListExamsRecursiveForAssessmentsIdDetailEmployeeDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "ListExamsRecursiveForAssessmentsIdDetailEmployee",
        "query",
        variables,
      );
    },
    DeleteAssessmentForAssessmentsIdDetail(
      variables: DeleteAssessmentForAssessmentsIdDetailMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteAssessmentForAssessmentsIdDetailMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteAssessmentForAssessmentsIdDetailMutation>(DeleteAssessmentForAssessmentsIdDetailDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "DeleteAssessmentForAssessmentsIdDetail",
        "mutation",
        variables,
      );
    },
    UpdateAssessmentForAssessmentsIdDetail(
      variables: UpdateAssessmentForAssessmentsIdDetailMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateAssessmentForAssessmentsIdDetailMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateAssessmentForAssessmentsIdDetailMutation>(UpdateAssessmentForAssessmentsIdDetailDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "UpdateAssessmentForAssessmentsIdDetail",
        "mutation",
        variables,
      );
    },
    UpdateAssessmentForAssessmentsIdUpdate(
      variables: UpdateAssessmentForAssessmentsIdUpdateMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateAssessmentForAssessmentsIdUpdateMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateAssessmentForAssessmentsIdUpdateMutation>(UpdateAssessmentForAssessmentsIdUpdateDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "UpdateAssessmentForAssessmentsIdUpdate",
        "mutation",
        variables,
      );
    },
    CreateEvaluationMetric(
      variables: CreateEvaluationMetricMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CreateEvaluationMetricMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreateEvaluationMetricMutation>(CreateEvaluationMetricDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "CreateEvaluationMetric",
        "mutation",
        variables,
      );
    },
    GetEvaluationMetric(
      variables: GetEvaluationMetricQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetEvaluationMetricQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetEvaluationMetricQuery>(GetEvaluationMetricDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "GetEvaluationMetric",
        "query",
        variables,
      );
    },
    ListEvaluationMetricsByCompanyId(
      variables: ListEvaluationMetricsByCompanyIdQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListEvaluationMetricsByCompanyIdQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListEvaluationMetricsByCompanyIdQuery>(ListEvaluationMetricsByCompanyIdDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ListEvaluationMetricsByCompanyId",
        "query",
        variables,
      );
    },
    UpdateEvaluationMetric(
      variables: UpdateEvaluationMetricMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateEvaluationMetricMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateEvaluationMetricMutation>(UpdateEvaluationMetricDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "UpdateEvaluationMetric",
        "mutation",
        variables,
      );
    },
    DeleteEvaluationMetric(
      variables: DeleteEvaluationMetricMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteEvaluationMetricMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteEvaluationMetricMutation>(DeleteEvaluationMetricDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "DeleteEvaluationMetric",
        "mutation",
        variables,
      );
    },
    DeleteMultiEvaluationMetrics(
      variables: DeleteMultiEvaluationMetricsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteMultiEvaluationMetricsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteMultiEvaluationMetricsMutation>(DeleteMultiEvaluationMetricsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "DeleteMultiEvaluationMetrics",
        "mutation",
        variables,
      );
    },
    CreateEvaluationMetricGroup(
      variables: CreateEvaluationMetricGroupMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CreateEvaluationMetricGroupMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreateEvaluationMetricGroupMutation>(CreateEvaluationMetricGroupDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "CreateEvaluationMetricGroup",
        "mutation",
        variables,
      );
    },
    GetEvaluationMetricGroup(
      variables: GetEvaluationMetricGroupQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetEvaluationMetricGroupQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetEvaluationMetricGroupQuery>(GetEvaluationMetricGroupDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetEvaluationMetricGroup",
        "query",
        variables,
      );
    },
    ListEvaluationMetricGroupsByCompanyId(
      variables: ListEvaluationMetricGroupsByCompanyIdQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListEvaluationMetricGroupsByCompanyIdQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListEvaluationMetricGroupsByCompanyIdQuery>(ListEvaluationMetricGroupsByCompanyIdDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ListEvaluationMetricGroupsByCompanyId",
        "query",
        variables,
      );
    },
    UpdateEvaluationMetricGroup(
      variables: UpdateEvaluationMetricGroupMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateEvaluationMetricGroupMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateEvaluationMetricGroupMutation>(UpdateEvaluationMetricGroupDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "UpdateEvaluationMetricGroup",
        "mutation",
        variables,
      );
    },
    DeleteEvaluationMetricGroup(
      variables: DeleteEvaluationMetricGroupMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteEvaluationMetricGroupMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteEvaluationMetricGroupMutation>(DeleteEvaluationMetricGroupDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "DeleteEvaluationMetricGroup",
        "mutation",
        variables,
      );
    },
    AddEvaluationMetricToGroups(
      variables: AddEvaluationMetricToGroupsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AddEvaluationMetricToGroupsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<AddEvaluationMetricToGroupsMutation>(AddEvaluationMetricToGroupsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "AddEvaluationMetricToGroups",
        "mutation",
        variables,
      );
    },
    AddEvaluationMetricsToGroup(
      variables: AddEvaluationMetricsToGroupMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AddEvaluationMetricsToGroupMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<AddEvaluationMetricsToGroupMutation>(AddEvaluationMetricsToGroupDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "AddEvaluationMetricsToGroup",
        "mutation",
        variables,
      );
    },
    DeleteEvaluationMetricsFromGroup(
      variables: DeleteEvaluationMetricsFromGroupMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteEvaluationMetricsFromGroupMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteEvaluationMetricsFromGroupMutation>(DeleteEvaluationMetricsFromGroupDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "DeleteEvaluationMetricsFromGroup",
        "mutation",
        variables,
      );
    },
    GetExamForEmployeeExamIdReview(
      variables: GetExamForEmployeeExamIdReviewQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetExamForEmployeeExamIdReviewQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetExamForEmployeeExamIdReviewQuery>(GetExamForEmployeeExamIdReviewDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetExamForEmployeeExamIdReview",
        "query",
        variables,
      );
    },
    ListRecentScreeningsForDashboardScreeningSection(
      variables: ListRecentScreeningsForDashboardScreeningSectionQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListRecentScreeningsForDashboardScreeningSectionQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListRecentScreeningsForDashboardScreeningSectionQuery>(
            ListRecentScreeningsForDashboardScreeningSectionDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "ListRecentScreeningsForDashboardScreeningSection",
        "query",
        variables,
      );
    },
    ListRemotesForDashboardRemoteInterviewSection(
      variables: ListRemotesForDashboardRemoteInterviewSectionQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListRemotesForDashboardRemoteInterviewSectionQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListRemotesForDashboardRemoteInterviewSectionQuery>(ListRemotesForDashboardRemoteInterviewSectionDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ListRemotesForDashboardRemoteInterviewSection",
        "query",
        variables,
      );
    },
    ListScreeningsByCompanyIdForDashboardScreeningStatsSection(
      variables: ListScreeningsByCompanyIdForDashboardScreeningStatsSectionQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListScreeningsByCompanyIdForDashboardScreeningStatsSectionQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListScreeningsByCompanyIdForDashboardScreeningStatsSectionQuery>(
            ListScreeningsByCompanyIdForDashboardScreeningStatsSectionDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "ListScreeningsByCompanyIdForDashboardScreeningStatsSection",
        "query",
        variables,
      );
    },
    GetScreeningForDashboardScreeningStatsSection(
      variables: GetScreeningForDashboardScreeningStatsSectionQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetScreeningForDashboardScreeningStatsSectionQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetScreeningForDashboardScreeningStatsSectionQuery>(GetScreeningForDashboardScreeningStatsSectionDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetScreeningForDashboardScreeningStatsSection",
        "query",
        variables,
      );
    },
    AcceptEmployeeInvitation(
      variables: AcceptEmployeeInvitationMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AcceptEmployeeInvitationMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<AcceptEmployeeInvitationMutation>(AcceptEmployeeInvitationDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "AcceptEmployeeInvitation",
        "mutation",
        variables,
      );
    },
    CompanyByEmployeeInvitationForEmployeeInvitationId(
      variables: CompanyByEmployeeInvitationForEmployeeInvitationIdQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CompanyByEmployeeInvitationForEmployeeInvitationIdQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CompanyByEmployeeInvitationForEmployeeInvitationIdQuery>(
            CompanyByEmployeeInvitationForEmployeeInvitationIdDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "CompanyByEmployeeInvitationForEmployeeInvitationId",
        "query",
        variables,
      );
    },
    GetMultiChoicePackageForQuestionQuizId(
      variables: GetMultiChoicePackageForQuestionQuizIdQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetMultiChoicePackageForQuestionQuizIdQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetMultiChoicePackageForQuestionQuizIdQuery>(GetMultiChoicePackageForQuestionQuizIdDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetMultiChoicePackageForQuestionQuizId",
        "query",
        variables,
      );
    },
    ListQuestions(variables: ListQuestionsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ListQuestionsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListQuestionsQuery>(ListQuestionsDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "ListQuestions",
        "query",
        variables,
      );
    },
    ListQuestionsForQuestionsPage(
      variables: ListQuestionsForQuestionsPageQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListQuestionsForQuestionsPageQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListQuestionsForQuestionsPageQuery>(ListQuestionsForQuestionsPageDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ListQuestionsForQuestionsPage",
        "query",
        variables,
      );
    },
    DeleteAlgorithmQuestion(
      variables: DeleteAlgorithmQuestionMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteAlgorithmQuestionMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteAlgorithmQuestionMutation>(DeleteAlgorithmQuestionDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "DeleteAlgorithmQuestion",
        "mutation",
        variables,
      );
    },
    DeleteMultiChoicePackage(
      variables: DeleteMultiChoicePackageMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteMultiChoicePackageMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteMultiChoicePackageMutation>(DeleteMultiChoicePackageDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "DeleteMultiChoicePackage",
        "mutation",
        variables,
      );
    },
    DeleteProjectQuestion(
      variables: DeleteProjectQuestionMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteProjectQuestionMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteProjectQuestionMutation>(DeleteProjectQuestionDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "DeleteProjectQuestion",
        "mutation",
        variables,
      );
    },
    DeleteFreepadQuestion(
      variables: DeleteFreepadQuestionMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteFreepadQuestionMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteFreepadQuestionMutation>(DeleteFreepadQuestionDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "DeleteFreepadQuestion",
        "mutation",
        variables,
      );
    },
    DeleteSystemDesignQuestion(
      variables: DeleteSystemDesignQuestionMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteSystemDesignQuestionMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteSystemDesignQuestionMutation>(DeleteSystemDesignQuestionDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "DeleteSystemDesignQuestion",
        "mutation",
        variables,
      );
    },
    GetHierarchicalSkillTagsForQuestions(
      variables: GetHierarchicalSkillTagsForQuestionsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetHierarchicalSkillTagsForQuestionsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetHierarchicalSkillTagsForQuestionsQuery>(GetHierarchicalSkillTagsForQuestionsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetHierarchicalSkillTagsForQuestions",
        "query",
        variables,
      );
    },
    GetAlgorithmQuestionForQuestionsChallengeId(
      variables: GetAlgorithmQuestionForQuestionsChallengeIdQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetAlgorithmQuestionForQuestionsChallengeIdQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetAlgorithmQuestionForQuestionsChallengeIdQuery>(GetAlgorithmQuestionForQuestionsChallengeIdDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetAlgorithmQuestionForQuestionsChallengeId",
        "query",
        variables,
      );
    },
    FreepadQuestionForQuestionDetail(
      variables: FreepadQuestionForQuestionDetailQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<FreepadQuestionForQuestionDetailQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<FreepadQuestionForQuestionDetailQuery>(FreepadQuestionForQuestionDetailDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "FreepadQuestionForQuestionDetail",
        "query",
        variables,
      );
    },
    ListQuestionsPackagesForQuestionsPackages(
      variables: ListQuestionsPackagesForQuestionsPackagesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListQuestionsPackagesForQuestionsPackagesQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListQuestionsPackagesForQuestionsPackagesQuery>(ListQuestionsPackagesForQuestionsPackagesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ListQuestionsPackagesForQuestionsPackages",
        "query",
        variables,
      );
    },
    GetQuestionPackageForQuestionsPackagesDetail(
      variables: GetQuestionPackageForQuestionsPackagesDetailQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetQuestionPackageForQuestionsPackagesDetailQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetQuestionPackageForQuestionsPackagesDetailQuery>(GetQuestionPackageForQuestionsPackagesDetailDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetQuestionPackageForQuestionsPackagesDetail",
        "query",
        variables,
      );
    },
    ProjectQuestionDetail(
      variables: ProjectQuestionDetailQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ProjectQuestionDetailQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ProjectQuestionDetailQuery>(ProjectQuestionDetailDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "ProjectQuestionDetail",
        "query",
        variables,
      );
    },
    ProjectAnswerFileBody(
      variables: ProjectAnswerFileBodyQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ProjectAnswerFileBodyQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ProjectAnswerFileBodyQuery>(ProjectAnswerFileBodyDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "ProjectAnswerFileBody",
        "query",
        variables,
      );
    },
    SystemDesignQuestionForQuestionDetail(
      variables: SystemDesignQuestionForQuestionDetailQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SystemDesignQuestionForQuestionDetailQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<SystemDesignQuestionForQuestionDetailQuery>(SystemDesignQuestionForQuestionDetailDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "SystemDesignQuestionForQuestionDetail",
        "query",
        variables,
      );
    },
    ListRemotesByCompanyIdForRemotesInterviewList(
      variables: ListRemotesByCompanyIdForRemotesInterviewListQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListRemotesByCompanyIdForRemotesInterviewListQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListRemotesByCompanyIdForRemotesInterviewListQuery>(ListRemotesByCompanyIdForRemotesInterviewListDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ListRemotesByCompanyIdForRemotesInterviewList",
        "query",
        variables,
      );
    },
    GetSearchItemsForRemotes(
      variables: GetSearchItemsForRemotesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetSearchItemsForRemotesQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetSearchItemsForRemotesQuery>(GetSearchItemsForRemotesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetSearchItemsForRemotes",
        "query",
        variables,
      );
    },
    StartRemoteForInterviewList(
      variables: StartRemoteForInterviewListMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<StartRemoteForInterviewListMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<StartRemoteForInterviewListMutation>(StartRemoteForInterviewListDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "StartRemoteForInterviewList",
        "mutation",
        variables,
      );
    },
    CreateRemoteForRemoteInterviewsCreate(
      variables: CreateRemoteForRemoteInterviewsCreateMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CreateRemoteForRemoteInterviewsCreateMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreateRemoteForRemoteInterviewsCreateMutation>(CreateRemoteForRemoteInterviewsCreateDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "CreateRemoteForRemoteInterviewsCreate",
        "mutation",
        variables,
      );
    },
    GetRemotes(variables: GetRemotesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetRemotesQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetRemotesQuery>(GetRemotesDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "GetRemotes",
        "query",
        variables,
      );
    },
    GetLiveCodingForEmployee(
      variables: GetLiveCodingForEmployeeQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetLiveCodingForEmployeeQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetLiveCodingForEmployeeQuery>(GetLiveCodingForEmployeeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetLiveCodingForEmployee",
        "query",
        variables,
      );
    },
    DeleteLiveCodingSession(
      variables: DeleteLiveCodingSessionMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteLiveCodingSessionMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteLiveCodingSessionMutation>(DeleteLiveCodingSessionDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "DeleteLiveCodingSession",
        "mutation",
        variables,
      );
    },
    EndRemoteInterview(
      variables: EndRemoteInterviewMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<EndRemoteInterviewMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<EndRemoteInterviewMutation>(EndRemoteInterviewDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "EndRemoteInterview",
        "mutation",
        variables,
      );
    },
    GetLiveCodingTemplateCodes(
      variables: GetLiveCodingTemplateCodesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetLiveCodingTemplateCodesQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetLiveCodingTemplateCodesQuery>(GetLiveCodingTemplateCodesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetLiveCodingTemplateCodes",
        "query",
        variables,
      );
    },
    AddLiveCodingSession(
      variables: AddLiveCodingSessionMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AddLiveCodingSessionMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<AddLiveCodingSessionMutation>(AddLiveCodingSessionDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "AddLiveCodingSession",
        "mutation",
        variables,
      );
    },
    JoinLiveCodingForEmployee(
      variables: JoinLiveCodingForEmployeeMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<JoinLiveCodingForEmployeeMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<JoinLiveCodingForEmployeeMutation>(JoinLiveCodingForEmployeeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "JoinLiveCodingForEmployee",
        "mutation",
        variables,
      );
    },
    StartLiveCoding(
      variables: StartLiveCodingMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<StartLiveCodingMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<StartLiveCodingMutation>(StartLiveCodingDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "StartLiveCoding",
        "mutation",
        variables,
      );
    },
    ListEvaluationMetricsForRemotesId(
      variables: ListEvaluationMetricsForRemotesIdQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListEvaluationMetricsForRemotesIdQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListEvaluationMetricsForRemotesIdQuery>(ListEvaluationMetricsForRemotesIdDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ListEvaluationMetricsForRemotesId",
        "query",
        variables,
      );
    },
    AddLiveCodingVideoChatRoomForEmployeeRemotesId(
      variables: AddLiveCodingVideoChatRoomForEmployeeRemotesIdMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AddLiveCodingVideoChatRoomForEmployeeRemotesIdMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<AddLiveCodingVideoChatRoomForEmployeeRemotesIdMutation>(
            AddLiveCodingVideoChatRoomForEmployeeRemotesIdDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "AddLiveCodingVideoChatRoomForEmployeeRemotesId",
        "mutation",
        variables,
      );
    },
    AddLiveCodingVideoChatConversationForEmployeeRemotesId(
      variables: AddLiveCodingVideoChatConversationForEmployeeRemotesIdMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AddLiveCodingVideoChatConversationForEmployeeRemotesIdMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<AddLiveCodingVideoChatConversationForEmployeeRemotesIdMutation>(
            AddLiveCodingVideoChatConversationForEmployeeRemotesIdDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "AddLiveCodingVideoChatConversationForEmployeeRemotesId",
        "mutation",
        variables,
      );
    },
    JoinVideoChatRoomForEmployee(
      variables: JoinVideoChatRoomForEmployeeMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<JoinVideoChatRoomForEmployeeMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<JoinVideoChatRoomForEmployeeMutation>(JoinVideoChatRoomForEmployeeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "JoinVideoChatRoomForEmployee",
        "mutation",
        variables,
      );
    },
    StartRecordingVideoChatForEmployee(
      variables: StartRecordingVideoChatForEmployeeMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<StartRecordingVideoChatForEmployeeMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<StartRecordingVideoChatForEmployeeMutation>(StartRecordingVideoChatForEmployeeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "StartRecordingVideoChatForEmployee",
        "mutation",
        variables,
      );
    },
    StopRecordingVideoChatForEmployee(
      variables: StopRecordingVideoChatForEmployeeMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<StopRecordingVideoChatForEmployeeMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<StopRecordingVideoChatForEmployeeMutation>(StopRecordingVideoChatForEmployeeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "StopRecordingVideoChatForEmployee",
        "mutation",
        variables,
      );
    },
    JoinVideoChatConversationForEmployee(
      variables: JoinVideoChatConversationForEmployeeMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<JoinVideoChatConversationForEmployeeMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<JoinVideoChatConversationForEmployeeMutation>(JoinVideoChatConversationForEmployeeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "JoinVideoChatConversationForEmployee",
        "mutation",
        variables,
      );
    },
    GetVideoChatConversationForEmployee(
      variables: GetVideoChatConversationForEmployeeQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetVideoChatConversationForEmployeeQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetVideoChatConversationForEmployeeQuery>(GetVideoChatConversationForEmployeeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetVideoChatConversationForEmployee",
        "query",
        variables,
      );
    },
    GetRemoteForRemoteInterview(
      variables: GetRemoteForRemoteInterviewQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetRemoteForRemoteInterviewQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetRemoteForRemoteInterviewQuery>(GetRemoteForRemoteInterviewDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetRemoteForRemoteInterview",
        "query",
        variables,
      );
    },
    StartRemoteForRemoteInterviewDetail(
      variables: StartRemoteForRemoteInterviewDetailMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<StartRemoteForRemoteInterviewDetailMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<StartRemoteForRemoteInterviewDetailMutation>(StartRemoteForRemoteInterviewDetailDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "StartRemoteForRemoteInterviewDetail",
        "mutation",
        variables,
      );
    },
    EvaluateRemoteForRemoteInterviewDetail(
      variables: EvaluateRemoteForRemoteInterviewDetailMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<EvaluateRemoteForRemoteInterviewDetailMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<EvaluateRemoteForRemoteInterviewDetailMutation>(EvaluateRemoteForRemoteInterviewDetailDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "EvaluateRemoteForRemoteInterviewDetail",
        "mutation",
        variables,
      );
    },
    DeleteRemoteForRemoteInterviewDetail(
      variables: DeleteRemoteForRemoteInterviewDetailMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteRemoteForRemoteInterviewDetailMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteRemoteForRemoteInterviewDetailMutation>(DeleteRemoteForRemoteInterviewDetailDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "DeleteRemoteForRemoteInterviewDetail",
        "mutation",
        variables,
      );
    },
    GetRemotesForRemotesEvaluate(
      variables: GetRemotesForRemotesEvaluateQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetRemotesForRemotesEvaluateQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetRemotesForRemotesEvaluateQuery>(GetRemotesForRemotesEvaluateDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetRemotesForRemotesEvaluate",
        "query",
        variables,
      );
    },
    ReviewRemoteRemotesEvaluate(
      variables: ReviewRemoteRemotesEvaluateMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ReviewRemoteRemotesEvaluateMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ReviewRemoteRemotesEvaluateMutation>(ReviewRemoteRemotesEvaluateDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ReviewRemoteRemotesEvaluate",
        "mutation",
        variables,
      );
    },
    ListEvaluationMetricsForRemotesEvaluate(
      variables: ListEvaluationMetricsForRemotesEvaluateQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListEvaluationMetricsForRemotesEvaluateQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListEvaluationMetricsForRemotesEvaluateQuery>(ListEvaluationMetricsForRemotesEvaluateDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ListEvaluationMetricsForRemotesEvaluate",
        "query",
        variables,
      );
    },
    UpdateRemoteForRemoteInterviewsUpdate(
      variables: UpdateRemoteForRemoteInterviewsUpdateMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateRemoteForRemoteInterviewsUpdateMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateRemoteForRemoteInterviewsUpdateMutation>(UpdateRemoteForRemoteInterviewsUpdateDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "UpdateRemoteForRemoteInterviewsUpdate",
        "mutation",
        variables,
      );
    },
    ListScreeningsByCompanyIdForScreenings(
      variables: ListScreeningsByCompanyIdForScreeningsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListScreeningsByCompanyIdForScreeningsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListScreeningsByCompanyIdForScreeningsQuery>(ListScreeningsByCompanyIdForScreeningsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ListScreeningsByCompanyIdForScreenings",
        "query",
        variables,
      );
    },
    GetSpotTagsForScreenings(
      variables: GetSpotTagsForScreeningsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetSpotTagsForScreeningsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetSpotTagsForScreeningsQuery>(GetSpotTagsForScreeningsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetSpotTagsForScreenings",
        "query",
        variables,
      );
    },
    UpdateScreeningForScreenings(
      variables: UpdateScreeningForScreeningsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateScreeningForScreeningsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateScreeningForScreeningsMutation>(UpdateScreeningForScreeningsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "UpdateScreeningForScreenings",
        "mutation",
        variables,
      );
    },
    DeleteScreeningForScreenings(
      variables: DeleteScreeningForScreeningsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteScreeningForScreeningsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteScreeningForScreeningsMutation>(DeleteScreeningForScreeningsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "DeleteScreeningForScreenings",
        "mutation",
        variables,
      );
    },
    DeleteMultiScreeningsForScreenings(
      variables: DeleteMultiScreeningsForScreeningsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteMultiScreeningsForScreeningsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteMultiScreeningsForScreeningsMutation>(DeleteMultiScreeningsForScreeningsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "DeleteMultiScreeningsForScreenings",
        "mutation",
        variables,
      );
    },
    UpdateTagsToScreenings(
      variables: UpdateTagsToScreeningsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateTagsToScreeningsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateTagsToScreeningsMutation>(UpdateTagsToScreeningsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "UpdateTagsToScreenings",
        "mutation",
        variables,
      );
    },
    InviteCandidateToScreeningForScreenings(
      variables: InviteCandidateToScreeningForScreeningsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<InviteCandidateToScreeningForScreeningsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<InviteCandidateToScreeningForScreeningsMutation>(InviteCandidateToScreeningForScreeningsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "InviteCandidateToScreeningForScreenings",
        "mutation",
        variables,
      );
    },
    GetScreeningForScreenings(
      variables: GetScreeningForScreeningsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetScreeningForScreeningsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetScreeningForScreeningsQuery>(GetScreeningForScreeningsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetScreeningForScreenings",
        "query",
        variables,
      );
    },
    ReplicateScreeningForScreenings(
      variables: ReplicateScreeningForScreeningsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ReplicateScreeningForScreeningsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ReplicateScreeningForScreeningsMutation>(ReplicateScreeningForScreeningsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ReplicateScreeningForScreenings",
        "mutation",
        variables,
      );
    },
    CreateScreeningForScreeningsCreate(
      variables: CreateScreeningForScreeningsCreateMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CreateScreeningForScreeningsCreateMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreateScreeningForScreeningsCreateMutation>(CreateScreeningForScreeningsCreateDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "CreateScreeningForScreeningsCreate",
        "mutation",
        variables,
      );
    },
    UpdateScreeningForScreeningsIdUpdate(
      variables: UpdateScreeningForScreeningsIdUpdateMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateScreeningForScreeningsIdUpdateMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateScreeningForScreeningsIdUpdateMutation>(UpdateScreeningForScreeningsIdUpdateDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "UpdateScreeningForScreeningsIdUpdate",
        "mutation",
        variables,
      );
    },
    GetTestResultTable(
      variables: GetTestResultTableQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetTestResultTableQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetTestResultTableQuery>(GetTestResultTableDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "GetTestResultTable",
        "query",
        variables,
      );
    },
    GetInterviews(variables: GetInterviewsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetInterviewsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetInterviewsQuery>(GetInterviewsDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "GetInterviews",
        "query",
        variables,
      );
    },
    ListSpotsByCompanyIdForInterviews(
      variables: ListSpotsByCompanyIdForInterviewsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListSpotsByCompanyIdForInterviewsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListSpotsByCompanyIdForInterviewsQuery>(ListSpotsByCompanyIdForInterviewsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ListSpotsByCompanyIdForInterviews",
        "query",
        variables,
      );
    },
    UpdateSpotTags(variables: UpdateSpotTagsMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateSpotTagsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateSpotTagsMutation>(UpdateSpotTagsDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "UpdateSpotTags",
        "mutation",
        variables,
      );
    },
    EvaluateInterviewForInterviews(
      variables: EvaluateInterviewForInterviewsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<EvaluateInterviewForInterviewsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<EvaluateInterviewForInterviewsMutation>(EvaluateInterviewForInterviewsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "EvaluateInterviewForInterviews",
        "mutation",
        variables,
      );
    },
    DeleteMultiSpotsForScreeningsTests(
      variables: DeleteMultiSpotsForScreeningsTestsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteMultiSpotsForScreeningsTestsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteMultiSpotsForScreeningsTestsMutation>(DeleteMultiSpotsForScreeningsTestsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "DeleteMultiSpotsForScreeningsTests",
        "mutation",
        variables,
      );
    },
    UpdateTagsToSpotsForScreeningsTests(
      variables: UpdateTagsToSpotsForScreeningsTestsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateTagsToSpotsForScreeningsTestsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateTagsToSpotsForScreeningsTestsMutation>(UpdateTagsToSpotsForScreeningsTestsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "UpdateTagsToSpotsForScreeningsTests",
        "mutation",
        variables,
      );
    },
    EvaluateMultiSpotsForScreeningsTests(
      variables: EvaluateMultiSpotsForScreeningsTestsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<EvaluateMultiSpotsForScreeningsTestsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<EvaluateMultiSpotsForScreeningsTestsMutation>(EvaluateMultiSpotsForScreeningsTestsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "EvaluateMultiSpotsForScreeningsTests",
        "mutation",
        variables,
      );
    },
    GetInterviewDetail(
      variables: GetInterviewDetailQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetInterviewDetailQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetInterviewDetailQuery>(GetInterviewDetailDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "GetInterviewDetail",
        "query",
        variables,
      );
    },
    EvaluateInterview(
      variables: EvaluateInterviewMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<EvaluateInterviewMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<EvaluateInterviewMutation>(EvaluateInterviewDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "EvaluateInterview",
        "mutation",
        variables,
      );
    },
    RecreateInterview(
      variables: RecreateInterviewMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<RecreateInterviewMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<RecreateInterviewMutation>(RecreateInterviewDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "RecreateInterview",
        "mutation",
        variables,
      );
    },
    DeleteInterview(
      variables: DeleteInterviewMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteInterviewMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteInterviewMutation>(DeleteInterviewDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "DeleteInterview",
        "mutation",
        variables,
      );
    },
    ExportTestReportPdfForInterviewsIdDetail(
      variables: ExportTestReportPdfForInterviewsIdDetailMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ExportTestReportPdfForInterviewsIdDetailMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ExportTestReportPdfForInterviewsIdDetailMutation>(ExportTestReportPdfForInterviewsIdDetailDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ExportTestReportPdfForInterviewsIdDetail",
        "mutation",
        variables,
      );
    },
    TestReportPdfForInterviewsIdDetail(
      variables: TestReportPdfForInterviewsIdDetailQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<TestReportPdfForInterviewsIdDetailQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<TestReportPdfForInterviewsIdDetailQuery>(TestReportPdfForInterviewsIdDetailDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "TestReportPdfForInterviewsIdDetail",
        "query",
        variables,
      );
    },
    GenerateSpotSharedLink(
      variables: GenerateSpotSharedLinkMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GenerateSpotSharedLinkMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GenerateSpotSharedLinkMutation>(GenerateSpotSharedLinkDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GenerateSpotSharedLink",
        "mutation",
        variables,
      );
    },
    UpdateSpotForInterviewsIdDetail(
      variables: UpdateSpotForInterviewsIdDetailMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateSpotForInterviewsIdDetailMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateSpotForInterviewsIdDetailMutation>(UpdateSpotForInterviewsIdDetailDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "UpdateSpotForInterviewsIdDetail",
        "mutation",
        variables,
      );
    },
    GetSpotForRetakeInterviewsIdDetail(
      variables: GetSpotForRetakeInterviewsIdDetailQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetSpotForRetakeInterviewsIdDetailQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetSpotForRetakeInterviewsIdDetailQuery>(GetSpotForRetakeInterviewsIdDetailDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetSpotForRetakeInterviewsIdDetail",
        "query",
        variables,
      );
    },
    GetSpotForEmployeeInterviewsIdReview(
      variables: GetSpotForEmployeeInterviewsIdReviewQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetSpotForEmployeeInterviewsIdReviewQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetSpotForEmployeeInterviewsIdReviewQuery>(GetSpotForEmployeeInterviewsIdReviewDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetSpotForEmployeeInterviewsIdReview",
        "query",
        variables,
      );
    },
    CreateRemoteInterviewTemplate(
      variables: CreateRemoteInterviewTemplateMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CreateRemoteInterviewTemplateMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreateRemoteInterviewTemplateMutation>(CreateRemoteInterviewTemplateDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "CreateRemoteInterviewTemplate",
        "mutation",
        variables,
      );
    },
    UpdateRemoteInterviewTemplate(
      variables: UpdateRemoteInterviewTemplateMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateRemoteInterviewTemplateMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateRemoteInterviewTemplateMutation>(UpdateRemoteInterviewTemplateDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "UpdateRemoteInterviewTemplate",
        "mutation",
        variables,
      );
    },
    GetExamForAssessmentsId(
      variables: GetExamForAssessmentsIdQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetExamForAssessmentsIdQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetExamForAssessmentsIdQuery>(GetExamForAssessmentsIdDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetExamForAssessmentsId",
        "query",
        variables,
      );
    },
    GetExamForEntryAssessmentsId(
      variables: GetExamForEntryAssessmentsIdQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetExamForEntryAssessmentsIdQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetExamForEntryAssessmentsIdQuery>(GetExamForEntryAssessmentsIdDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetExamForEntryAssessmentsId",
        "query",
        variables,
      );
    },
    StartExam(variables: StartExamMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<StartExamMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<StartExamMutation>(StartExamDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "StartExam",
        "mutation",
        variables,
      );
    },
    EndExam(variables: EndExamMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<EndExamMutation> {
      return withWrapper(
        wrappedRequestHeaders => client.request<EndExamMutation>(EndExamDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "EndExam",
        "mutation",
        variables,
      );
    },
    GetAssessmentForAssessmentsIdDetailTalent(
      variables: GetAssessmentForAssessmentsIdDetailTalentQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetAssessmentForAssessmentsIdDetailTalentQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetAssessmentForAssessmentsIdDetailTalentQuery>(GetAssessmentForAssessmentsIdDetailTalentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetAssessmentForAssessmentsIdDetailTalent",
        "query",
        variables,
      );
    },
    ListExamsRecursiveForAssessmentsIdDetailTalent(
      variables: ListExamsRecursiveForAssessmentsIdDetailTalentQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListExamsRecursiveForAssessmentsIdDetailTalentQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListExamsRecursiveForAssessmentsIdDetailTalentQuery>(
            ListExamsRecursiveForAssessmentsIdDetailTalentDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "ListExamsRecursiveForAssessmentsIdDetailTalent",
        "query",
        variables,
      );
    },
    RecreateExamForAssessmentsIdDetailTalent(
      variables: RecreateExamForAssessmentsIdDetailTalentMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<RecreateExamForAssessmentsIdDetailTalentMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<RecreateExamForAssessmentsIdDetailTalentMutation>(RecreateExamForAssessmentsIdDetailTalentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "RecreateExamForAssessmentsIdDetailTalent",
        "mutation",
        variables,
      );
    },
    GetTailExamForAssessmentIdDetailTalent(
      variables: GetTailExamForAssessmentIdDetailTalentQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetTailExamForAssessmentIdDetailTalentQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetTailExamForAssessmentIdDetailTalentQuery>(GetTailExamForAssessmentIdDetailTalentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetTailExamForAssessmentIdDetailTalent",
        "query",
        variables,
      );
    },
    AcceptTalentInvitation(
      variables: AcceptTalentInvitationMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AcceptTalentInvitationMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<AcceptTalentInvitationMutation>(AcceptTalentInvitationDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "AcceptTalentInvitation",
        "mutation",
        variables,
      );
    },
    GetCompanyByTalentInvitationForTalentInvitationId(
      variables: GetCompanyByTalentInvitationForTalentInvitationIdQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetCompanyByTalentInvitationForTalentInvitationIdQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetCompanyByTalentInvitationForTalentInvitationIdQuery>(
            GetCompanyByTalentInvitationForTalentInvitationIdDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "GetCompanyByTalentInvitationForTalentInvitationId",
        "query",
        variables,
      );
    },
    CreateAlgorithmQuestion(
      variables: CreateAlgorithmQuestionMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CreateAlgorithmQuestionMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreateAlgorithmQuestionMutation>(CreateAlgorithmQuestionDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "CreateAlgorithmQuestion",
        "mutation",
        variables,
      );
    },
    UpdateAlgorithmQuestion(
      variables: UpdateAlgorithmQuestionMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateAlgorithmQuestionMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateAlgorithmQuestionMutation>(UpdateAlgorithmQuestionDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "UpdateAlgorithmQuestion",
        "mutation",
        variables,
      );
    },
    RequestReviewAlgorithmQuestionForAlgorithmResourceEditor(
      variables: RequestReviewAlgorithmQuestionForAlgorithmResourceEditorMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<RequestReviewAlgorithmQuestionForAlgorithmResourceEditorMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<RequestReviewAlgorithmQuestionForAlgorithmResourceEditorMutation>(
            RequestReviewAlgorithmQuestionForAlgorithmResourceEditorDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "RequestReviewAlgorithmQuestionForAlgorithmResourceEditor",
        "mutation",
        variables,
      );
    },
    GetAlgorithmQuestionForAlgorithmResourceEditor(
      variables: GetAlgorithmQuestionForAlgorithmResourceEditorQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetAlgorithmQuestionForAlgorithmResourceEditorQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetAlgorithmQuestionForAlgorithmResourceEditorQuery>(
            GetAlgorithmQuestionForAlgorithmResourceEditorDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "GetAlgorithmQuestionForAlgorithmResourceEditor",
        "query",
        variables,
      );
    },
    AlgorithmTemplateCode(
      variables: AlgorithmTemplateCodeQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AlgorithmTemplateCodeQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<AlgorithmTemplateCodeQuery>(AlgorithmTemplateCodeDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "AlgorithmTemplateCode",
        "query",
        variables,
      );
    },
    AlgorithmRunCode(variables: AlgorithmRunCodeQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AlgorithmRunCodeQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<AlgorithmRunCodeQuery>(AlgorithmRunCodeDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "AlgorithmRunCode",
        "query",
        variables,
      );
    },
    AlgorithmProfileCode(
      variables: AlgorithmProfileCodeQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AlgorithmProfileCodeQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<AlgorithmProfileCodeQuery>(AlgorithmProfileCodeDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "AlgorithmProfileCode",
        "query",
        variables,
      );
    },
    TranslatePlainTextForAlgorithmResourceEditor(
      variables: TranslatePlainTextForAlgorithmResourceEditorMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<TranslatePlainTextForAlgorithmResourceEditorMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<TranslatePlainTextForAlgorithmResourceEditorMutation>(
            TranslatePlainTextForAlgorithmResourceEditorDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "TranslatePlainTextForAlgorithmResourceEditor",
        "mutation",
        variables,
      );
    },
    TranslateMarkdownForAlgorithmResourceEditor(
      variables: TranslateMarkdownForAlgorithmResourceEditorMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<TranslateMarkdownForAlgorithmResourceEditorMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<TranslateMarkdownForAlgorithmResourceEditorMutation>(TranslateMarkdownForAlgorithmResourceEditorDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "TranslateMarkdownForAlgorithmResourceEditor",
        "mutation",
        variables,
      );
    },
    GetBehavioralEventLogsForChallengeTestReport(
      variables: GetBehavioralEventLogsForChallengeTestReportQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetBehavioralEventLogsForChallengeTestReportQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetBehavioralEventLogsForChallengeTestReportQuery>(GetBehavioralEventLogsForChallengeTestReportDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetBehavioralEventLogsForChallengeTestReport",
        "query",
        variables,
      );
    },
    GetQuestionForFreepadResourceEditor(
      variables: GetQuestionForFreepadResourceEditorQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetQuestionForFreepadResourceEditorQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetQuestionForFreepadResourceEditorQuery>(GetQuestionForFreepadResourceEditorDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetQuestionForFreepadResourceEditor",
        "query",
        variables,
      );
    },
    CreateQuestionForFreepadResourceEditor(
      variables: CreateQuestionForFreepadResourceEditorMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CreateQuestionForFreepadResourceEditorMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreateQuestionForFreepadResourceEditorMutation>(CreateQuestionForFreepadResourceEditorDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "CreateQuestionForFreepadResourceEditor",
        "mutation",
        variables,
      );
    },
    UpdateQuestionForFreepadResourceEditor(
      variables: UpdateQuestionForFreepadResourceEditorMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateQuestionForFreepadResourceEditorMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateQuestionForFreepadResourceEditorMutation>(UpdateQuestionForFreepadResourceEditorDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "UpdateQuestionForFreepadResourceEditor",
        "mutation",
        variables,
      );
    },
    GetAlgorithmTemplateCodeForFreepadResourceEditor(
      variables: GetAlgorithmTemplateCodeForFreepadResourceEditorQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetAlgorithmTemplateCodeForFreepadResourceEditorQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetAlgorithmTemplateCodeForFreepadResourceEditorQuery>(
            GetAlgorithmTemplateCodeForFreepadResourceEditorDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "GetAlgorithmTemplateCodeForFreepadResourceEditor",
        "query",
        variables,
      );
    },
    TranslatePlainTextForFreepadResourceEditor(
      variables: TranslatePlainTextForFreepadResourceEditorMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<TranslatePlainTextForFreepadResourceEditorMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<TranslatePlainTextForFreepadResourceEditorMutation>(TranslatePlainTextForFreepadResourceEditorDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "TranslatePlainTextForFreepadResourceEditor",
        "mutation",
        variables,
      );
    },
    TranslateMarkdownForFreepadResourceEditor(
      variables: TranslateMarkdownForFreepadResourceEditorMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<TranslateMarkdownForFreepadResourceEditorMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<TranslateMarkdownForFreepadResourceEditorMutation>(TranslateMarkdownForFreepadResourceEditorDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "TranslateMarkdownForFreepadResourceEditor",
        "mutation",
        variables,
      );
    },
    ListQuestionsForSearchQuestion(
      variables: ListQuestionsForSearchQuestionQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListQuestionsForSearchQuestionQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListQuestionsForSearchQuestionQuery>(ListQuestionsForSearchQuestionDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ListQuestionsForSearchQuestion",
        "query",
        variables,
      );
    },
    ListMultiQuestionTags(
      variables: ListMultiQuestionTagsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListMultiQuestionTagsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListMultiQuestionTagsQuery>(ListMultiQuestionTagsDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "ListMultiQuestionTags",
        "query",
        variables,
      );
    },
    CreateMultiChoicePackage(
      variables: CreateMultiChoicePackageMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CreateMultiChoicePackageMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreateMultiChoicePackageMutation>(CreateMultiChoicePackageDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "CreateMultiChoicePackage",
        "mutation",
        variables,
      );
    },
    UpdateMultiChoicePackage(
      variables: UpdateMultiChoicePackageMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateMultiChoicePackageMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateMultiChoicePackageMutation>(UpdateMultiChoicePackageDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "UpdateMultiChoicePackage",
        "mutation",
        variables,
      );
    },
    GetMultiChoicePackage(
      variables: GetMultiChoicePackageQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetMultiChoicePackageQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetMultiChoicePackageQuery>(GetMultiChoicePackageDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "GetMultiChoicePackage",
        "query",
        variables,
      );
    },
    CreateMultiChoiceQuestion(
      variables: CreateMultiChoiceQuestionMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CreateMultiChoiceQuestionMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreateMultiChoiceQuestionMutation>(CreateMultiChoiceQuestionDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "CreateMultiChoiceQuestion",
        "mutation",
        variables,
      );
    },
    UpdateMultiChoiceQuestion(
      variables: UpdateMultiChoiceQuestionMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateMultiChoiceQuestionMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateMultiChoiceQuestionMutation>(UpdateMultiChoiceQuestionDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "UpdateMultiChoiceQuestion",
        "mutation",
        variables,
      );
    },
    ListRemoteTagsForRemoteInterviewResourceEditor(
      variables: ListRemoteTagsForRemoteInterviewResourceEditorQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListRemoteTagsForRemoteInterviewResourceEditorQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListRemoteTagsForRemoteInterviewResourceEditorQuery>(
            ListRemoteTagsForRemoteInterviewResourceEditorDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "ListRemoteTagsForRemoteInterviewResourceEditor",
        "query",
        variables,
      );
    },
    GetRemoteForRemoteInterviewResourceEditor(
      variables: GetRemoteForRemoteInterviewResourceEditorQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetRemoteForRemoteInterviewResourceEditorQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetRemoteForRemoteInterviewResourceEditorQuery>(GetRemoteForRemoteInterviewResourceEditorDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetRemoteForRemoteInterviewResourceEditor",
        "query",
        variables,
      );
    },
    GetRemoteForRemoteInterviewResourceEditorV2(
      variables: GetRemoteForRemoteInterviewResourceEditorV2QueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetRemoteForRemoteInterviewResourceEditorV2Query> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetRemoteForRemoteInterviewResourceEditorV2Query>(GetRemoteForRemoteInterviewResourceEditorV2Document, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetRemoteForRemoteInterviewResourceEditorV2",
        "query",
        variables,
      );
    },
    ListRemotesForRemoteInterviewResourceEditor(
      variables: ListRemotesForRemoteInterviewResourceEditorQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListRemotesForRemoteInterviewResourceEditorQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListRemotesForRemoteInterviewResourceEditorQuery>(ListRemotesForRemoteInterviewResourceEditorDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ListRemotesForRemoteInterviewResourceEditor",
        "query",
        variables,
      );
    },
    ListQuestionsForRemoteInterviewResourceEditor(
      variables: ListQuestionsForRemoteInterviewResourceEditorQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListQuestionsForRemoteInterviewResourceEditorQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListQuestionsForRemoteInterviewResourceEditorQuery>(ListQuestionsForRemoteInterviewResourceEditorDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ListQuestionsForRemoteInterviewResourceEditor",
        "query",
        variables,
      );
    },
    ListTemplatesForRemoteInterviewResourceEditor(
      variables: ListTemplatesForRemoteInterviewResourceEditorQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListTemplatesForRemoteInterviewResourceEditorQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListTemplatesForRemoteInterviewResourceEditorQuery>(ListTemplatesForRemoteInterviewResourceEditorDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ListTemplatesForRemoteInterviewResourceEditor",
        "query",
        variables,
      );
    },
    FillTemplateForRemoteInterviewResourceEditor(
      variables: FillTemplateForRemoteInterviewResourceEditorMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<FillTemplateForRemoteInterviewResourceEditorMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<FillTemplateForRemoteInterviewResourceEditorMutation>(
            FillTemplateForRemoteInterviewResourceEditorDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "FillTemplateForRemoteInterviewResourceEditor",
        "mutation",
        variables,
      );
    },
    GetTemplateForRemoteInterviewResourceEditor(
      variables: GetTemplateForRemoteInterviewResourceEditorQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetTemplateForRemoteInterviewResourceEditorQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetTemplateForRemoteInterviewResourceEditorQuery>(GetTemplateForRemoteInterviewResourceEditorDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetTemplateForRemoteInterviewResourceEditor",
        "query",
        variables,
      );
    },
    ListRemoteTagsForRemoteInterviewTemplateResourceEditor(
      variables: ListRemoteTagsForRemoteInterviewTemplateResourceEditorQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListRemoteTagsForRemoteInterviewTemplateResourceEditorQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListRemoteTagsForRemoteInterviewTemplateResourceEditorQuery>(
            ListRemoteTagsForRemoteInterviewTemplateResourceEditorDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "ListRemoteTagsForRemoteInterviewTemplateResourceEditor",
        "query",
        variables,
      );
    },
    ListRemoteInterviewTemplatesResourceEditor(
      variables: ListRemoteInterviewTemplatesResourceEditorQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListRemoteInterviewTemplatesResourceEditorQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListRemoteInterviewTemplatesResourceEditorQuery>(ListRemoteInterviewTemplatesResourceEditorDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ListRemoteInterviewTemplatesResourceEditor",
        "query",
        variables,
      );
    },
    GetRemoteForRemoteInterviewTemplateResourceEditor(
      variables: GetRemoteForRemoteInterviewTemplateResourceEditorQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetRemoteForRemoteInterviewTemplateResourceEditorQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetRemoteForRemoteInterviewTemplateResourceEditorQuery>(
            GetRemoteForRemoteInterviewTemplateResourceEditorDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "GetRemoteForRemoteInterviewTemplateResourceEditor",
        "query",
        variables,
      );
    },
    GetRemoteInterviewTemplateForRemoteInterviewTemplateResourceEditor(
      variables: GetRemoteInterviewTemplateForRemoteInterviewTemplateResourceEditorQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetRemoteInterviewTemplateForRemoteInterviewTemplateResourceEditorQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetRemoteInterviewTemplateForRemoteInterviewTemplateResourceEditorQuery>(
            GetRemoteInterviewTemplateForRemoteInterviewTemplateResourceEditorDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "GetRemoteInterviewTemplateForRemoteInterviewTemplateResourceEditor",
        "query",
        variables,
      );
    },
    GetRemoteInterviewTemplateForRemoteTemplateResourceEditor(
      variables: GetRemoteInterviewTemplateForRemoteTemplateResourceEditorQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetRemoteInterviewTemplateForRemoteTemplateResourceEditorQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetRemoteInterviewTemplateForRemoteTemplateResourceEditorQuery>(
            GetRemoteInterviewTemplateForRemoteTemplateResourceEditorDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "GetRemoteInterviewTemplateForRemoteTemplateResourceEditor",
        "query",
        variables,
      );
    },
    GetRemoteForRemoteTemplateResourceEditor(
      variables: GetRemoteForRemoteTemplateResourceEditorQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetRemoteForRemoteTemplateResourceEditorQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetRemoteForRemoteTemplateResourceEditorQuery>(GetRemoteForRemoteTemplateResourceEditorDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetRemoteForRemoteTemplateResourceEditor",
        "query",
        variables,
      );
    },
    AnalyzeRemoteInterviewTemplateResourceEditor(
      variables: AnalyzeRemoteInterviewTemplateResourceEditorMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AnalyzeRemoteInterviewTemplateResourceEditorMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<AnalyzeRemoteInterviewTemplateResourceEditorMutation>(
            AnalyzeRemoteInterviewTemplateResourceEditorDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "AnalyzeRemoteInterviewTemplateResourceEditor",
        "mutation",
        variables,
      );
    },
    ListQuestionsForForRemoteInterviewTemplateResourceEditor(
      variables: ListQuestionsForForRemoteInterviewTemplateResourceEditorQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListQuestionsForForRemoteInterviewTemplateResourceEditorQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListQuestionsForForRemoteInterviewTemplateResourceEditorQuery>(
            ListQuestionsForForRemoteInterviewTemplateResourceEditorDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "ListQuestionsForForRemoteInterviewTemplateResourceEditor",
        "query",
        variables,
      );
    },
    GetInterviewFeedbackForCandidateInterviewFeedback(
      variables: GetInterviewFeedbackForCandidateInterviewFeedbackQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetInterviewFeedbackForCandidateInterviewFeedbackQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetInterviewFeedbackForCandidateInterviewFeedbackQuery>(
            GetInterviewFeedbackForCandidateInterviewFeedbackDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "GetInterviewFeedbackForCandidateInterviewFeedback",
        "query",
        variables,
      );
    },
    GetInterviewReportFromSharedLink(
      variables: GetInterviewReportFromSharedLinkQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetInterviewReportFromSharedLinkQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetInterviewReportFromSharedLinkQuery>(GetInterviewReportFromSharedLinkDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetInterviewReportFromSharedLink",
        "query",
        variables,
      );
    },
    GetExamForAssessmentFeedback(
      variables: GetExamForAssessmentFeedbackQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetExamForAssessmentFeedbackQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetExamForAssessmentFeedbackQuery>(GetExamForAssessmentFeedbackDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetExamForAssessmentFeedback",
        "query",
        variables,
      );
    },
    ListAssessmentsByCompanyIdForAssessmentsHistorySelector(
      variables: ListAssessmentsByCompanyIdForAssessmentsHistorySelectorQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListAssessmentsByCompanyIdForAssessmentsHistorySelectorQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListAssessmentsByCompanyIdForAssessmentsHistorySelectorQuery>(
            ListAssessmentsByCompanyIdForAssessmentsHistorySelectorDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "ListAssessmentsByCompanyIdForAssessmentsHistorySelector",
        "query",
        variables,
      );
    },
    GetAssessmentForAssessmentHistorySelector(
      variables: GetAssessmentForAssessmentHistorySelectorQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetAssessmentForAssessmentHistorySelectorQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetAssessmentForAssessmentHistorySelectorQuery>(GetAssessmentForAssessmentHistorySelectorDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetAssessmentForAssessmentHistorySelector",
        "query",
        variables,
      );
    },
    GetAssessmentOverview(
      variables: GetAssessmentOverviewQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetAssessmentOverviewQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetAssessmentOverviewQuery>(GetAssessmentOverviewDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "GetAssessmentOverview",
        "query",
        variables,
      );
    },
    UpdateAssessmentForAssessmentOverview(
      variables: UpdateAssessmentForAssessmentOverviewMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateAssessmentForAssessmentOverviewMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateAssessmentForAssessmentOverviewMutation>(UpdateAssessmentForAssessmentOverviewDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "UpdateAssessmentForAssessmentOverview",
        "mutation",
        variables,
      );
    },
    GetExamReport(variables: GetExamReportQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetExamReportQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetExamReportQuery>(GetExamReportDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "GetExamReport",
        "query",
        variables,
      );
    },
    GetAssessmentForAssessmentResourceEditor(
      variables: GetAssessmentForAssessmentResourceEditorQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetAssessmentForAssessmentResourceEditorQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetAssessmentForAssessmentResourceEditorQuery>(GetAssessmentForAssessmentResourceEditorDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetAssessmentForAssessmentResourceEditor",
        "query",
        variables,
      );
    },
    GetBillingOverview(
      variables: GetBillingOverviewQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetBillingOverviewQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetBillingOverviewQuery>(GetBillingOverviewDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "GetBillingOverview",
        "query",
        variables,
      );
    },
    UpdateAutoRenewalForBillingOverview(
      variables: UpdateAutoRenewalForBillingOverviewMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateAutoRenewalForBillingOverviewMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateAutoRenewalForBillingOverviewMutation>(UpdateAutoRenewalForBillingOverviewDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "UpdateAutoRenewalForBillingOverview",
        "mutation",
        variables,
      );
    },
    GetCustomerForBuySelections(
      variables: GetCustomerForBuySelectionsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetCustomerForBuySelectionsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetCustomerForBuySelectionsQuery>(GetCustomerForBuySelectionsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetCustomerForBuySelections",
        "query",
        variables,
      );
    },
    PurchasePackagesForBuySelections(
      variables: PurchasePackagesForBuySelectionsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<PurchasePackagesForBuySelectionsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<PurchasePackagesForBuySelectionsMutation>(PurchasePackagesForBuySelectionsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "PurchasePackagesForBuySelections",
        "mutation",
        variables,
      );
    },
    GetCouponByCodeForBuySelection(
      variables: GetCouponByCodeForBuySelectionQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetCouponByCodeForBuySelectionQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetCouponByCodeForBuySelectionQuery>(GetCouponByCodeForBuySelectionDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetCouponByCodeForBuySelection",
        "query",
        variables,
      );
    },
    ChallengeSubmission(
      variables: ChallengeSubmissionQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ChallengeSubmissionQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ChallengeSubmissionQuery>(ChallengeSubmissionDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "ChallengeSubmission",
        "query",
        variables,
      );
    },
    ChallengeRunCode(variables: ChallengeRunCodeQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ChallengeRunCodeQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ChallengeRunCodeQuery>(ChallengeRunCodeDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "ChallengeRunCode",
        "query",
        variables,
      );
    },
    StartChallenge(variables: StartChallengeMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<StartChallengeMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<StartChallengeMutation>(StartChallengeDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "StartChallenge",
        "mutation",
        variables,
      );
    },
    SaveChallengeAppealMessage(
      variables: SaveChallengeAppealMessageMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SaveChallengeAppealMessageMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<SaveChallengeAppealMessageMutation>(SaveChallengeAppealMessageDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "SaveChallengeAppealMessage",
        "mutation",
        variables,
      );
    },
    ChallengeSubmitCode(
      variables: ChallengeSubmitCodeMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ChallengeSubmitCodeMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ChallengeSubmitCodeMutation>(ChallengeSubmitCodeDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "ChallengeSubmitCode",
        "mutation",
        variables,
      );
    },
    StartChallengeSessionsForChallengeCodingEditor(
      variables: StartChallengeSessionsForChallengeCodingEditorMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<StartChallengeSessionsForChallengeCodingEditorMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<StartChallengeSessionsForChallengeCodingEditorMutation>(
            StartChallengeSessionsForChallengeCodingEditorDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "StartChallengeSessionsForChallengeCodingEditor",
        "mutation",
        variables,
      );
    },
    StoreWebSearchEventForChallengeCodingEditor(
      variables: StoreWebSearchEventForChallengeCodingEditorMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<StoreWebSearchEventForChallengeCodingEditorMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<StoreWebSearchEventForChallengeCodingEditorMutation>(StoreWebSearchEventForChallengeCodingEditorDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "StoreWebSearchEventForChallengeCodingEditor",
        "mutation",
        variables,
      );
    },
    StoreWebAccessEventForChallengeCodingEditor(
      variables: StoreWebAccessEventForChallengeCodingEditorMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<StoreWebAccessEventForChallengeCodingEditorMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<StoreWebAccessEventForChallengeCodingEditorMutation>(StoreWebAccessEventForChallengeCodingEditorDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "StoreWebAccessEventForChallengeCodingEditor",
        "mutation",
        variables,
      );
    },
    AskChatGPTForChallengeCodingEditor(
      variables: AskChatGptForChallengeCodingEditorMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AskChatGptForChallengeCodingEditorMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<AskChatGptForChallengeCodingEditorMutation>(AskChatGptForChallengeCodingEditorDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "AskChatGPTForChallengeCodingEditor",
        "mutation",
        variables,
      );
    },
    ResetChatGPTConversationForChallengeCodingEditor(
      variables: ResetChatGptConversationForChallengeCodingEditorMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ResetChatGptConversationForChallengeCodingEditorMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ResetChatGptConversationForChallengeCodingEditorMutation>(
            ResetChatGptConversationForChallengeCodingEditorDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "ResetChatGPTConversationForChallengeCodingEditor",
        "mutation",
        variables,
      );
    },
    GetChallengeStatisticsAndRankForRankVisualizer(
      variables: GetChallengeStatisticsAndRankForRankVisualizerQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetChallengeStatisticsAndRankForRankVisualizerQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetChallengeStatisticsAndRankForRankVisualizerQuery>(
            GetChallengeStatisticsAndRankForRankVisualizerDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "GetChallengeStatisticsAndRankForRankVisualizer",
        "query",
        variables,
      );
    },
    GetChallengeEntityForChallengeTestReport(
      variables: GetChallengeEntityForChallengeTestReportQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetChallengeEntityForChallengeTestReportQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetChallengeEntityForChallengeTestReportQuery>(GetChallengeEntityForChallengeTestReportDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetChallengeEntityForChallengeTestReport",
        "query",
        variables,
      );
    },
    GetChallengeSubmissionForChallengeTestReport(
      variables: GetChallengeSubmissionForChallengeTestReportQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetChallengeSubmissionForChallengeTestReportQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetChallengeSubmissionForChallengeTestReportQuery>(GetChallengeSubmissionForChallengeTestReportDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetChallengeSubmissionForChallengeTestReport",
        "query",
        variables,
      );
    },
    FeedbackSuspiciousDegreeForChallengeTestReport(
      variables: FeedbackSuspiciousDegreeForChallengeTestReportMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<FeedbackSuspiciousDegreeForChallengeTestReportMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<FeedbackSuspiciousDegreeForChallengeTestReportMutation>(
            FeedbackSuspiciousDegreeForChallengeTestReportDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "FeedbackSuspiciousDegreeForChallengeTestReport",
        "mutation",
        variables,
      );
    },
    ExportTestResultTable(
      variables: ExportTestResultTableMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ExportTestResultTableMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ExportTestResultTableMutation>(ExportTestResultTableDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ExportTestResultTable",
        "mutation",
        variables,
      );
    },
    GetEmployeesForEmployeeAssignField(
      variables: GetEmployeesForEmployeeAssignFieldQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetEmployeesForEmployeeAssignFieldQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetEmployeesForEmployeeAssignFieldQuery>(GetEmployeesForEmployeeAssignFieldDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetEmployeesForEmployeeAssignField",
        "query",
        variables,
      );
    },
    GetCompanyForEmployeeGroupChips(
      variables: GetCompanyForEmployeeGroupChipsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetCompanyForEmployeeGroupChipsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetCompanyForEmployeeGroupChipsQuery>(GetCompanyForEmployeeGroupChipsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetCompanyForEmployeeGroupChips",
        "query",
        variables,
      );
    },
    ListEmployeeNotifications(
      variables: ListEmployeeNotificationsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListEmployeeNotificationsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListEmployeeNotificationsQuery>(ListEmployeeNotificationsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ListEmployeeNotifications",
        "query",
        variables,
      );
    },
    GetCompaniesByEmployeeIdForEmployeeSideBarContent(
      variables: GetCompaniesByEmployeeIdForEmployeeSideBarContentQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetCompaniesByEmployeeIdForEmployeeSideBarContentQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetCompaniesByEmployeeIdForEmployeeSideBarContentQuery>(
            GetCompaniesByEmployeeIdForEmployeeSideBarContentDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "GetCompaniesByEmployeeIdForEmployeeSideBarContent",
        "query",
        variables,
      );
    },
    ListEvaluationMetricsGroupsForEvaluationMetricGroupSearchField(
      variables: ListEvaluationMetricsGroupsForEvaluationMetricGroupSearchFieldQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListEvaluationMetricsGroupsForEvaluationMetricGroupSearchFieldQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListEvaluationMetricsGroupsForEvaluationMetricGroupSearchFieldQuery>(
            ListEvaluationMetricsGroupsForEvaluationMetricGroupSearchFieldDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "ListEvaluationMetricsGroupsForEvaluationMetricGroupSearchField",
        "query",
        variables,
      );
    },
    ListEvaluationMetricsForEvaluationMetricSearchField(
      variables: ListEvaluationMetricsForEvaluationMetricSearchFieldQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListEvaluationMetricsForEvaluationMetricSearchFieldQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListEvaluationMetricsForEvaluationMetricSearchFieldQuery>(
            ListEvaluationMetricsForEvaluationMetricSearchFieldDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "ListEvaluationMetricsForEvaluationMetricSearchField",
        "query",
        variables,
      );
    },
    ListEvaluationMetricsForEvaluationMetrics(
      variables: ListEvaluationMetricsForEvaluationMetricsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListEvaluationMetricsForEvaluationMetricsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListEvaluationMetricsForEvaluationMetricsQuery>(ListEvaluationMetricsForEvaluationMetricsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ListEvaluationMetricsForEvaluationMetrics",
        "query",
        variables,
      );
    },
    GetEvaluationMetricForEvaluationMetrics(
      variables: GetEvaluationMetricForEvaluationMetricsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetEvaluationMetricForEvaluationMetricsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetEvaluationMetricForEvaluationMetricsQuery>(GetEvaluationMetricForEvaluationMetricsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetEvaluationMetricForEvaluationMetrics",
        "query",
        variables,
      );
    },
    CreateEvaluationMetricForEvaluationMetrics(
      variables: CreateEvaluationMetricForEvaluationMetricsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CreateEvaluationMetricForEvaluationMetricsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreateEvaluationMetricForEvaluationMetricsMutation>(CreateEvaluationMetricForEvaluationMetricsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "CreateEvaluationMetricForEvaluationMetrics",
        "mutation",
        variables,
      );
    },
    AddEvaluationMetricsToGroupsForEvaluationMetrics(
      variables: AddEvaluationMetricsToGroupsForEvaluationMetricsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AddEvaluationMetricsToGroupsForEvaluationMetricsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<AddEvaluationMetricsToGroupsForEvaluationMetricsMutation>(
            AddEvaluationMetricsToGroupsForEvaluationMetricsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "AddEvaluationMetricsToGroupsForEvaluationMetrics",
        "mutation",
        variables,
      );
    },
    ListEvaluationMetricsGroupsForEvaluationMetricsGroups(
      variables: ListEvaluationMetricsGroupsForEvaluationMetricsGroupsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListEvaluationMetricsGroupsForEvaluationMetricsGroupsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListEvaluationMetricsGroupsForEvaluationMetricsGroupsQuery>(
            ListEvaluationMetricsGroupsForEvaluationMetricsGroupsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "ListEvaluationMetricsGroupsForEvaluationMetricsGroups",
        "query",
        variables,
      );
    },
    GetEvaluationMetricGroupForEvaluationMetricsGroups(
      variables: GetEvaluationMetricGroupForEvaluationMetricsGroupsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetEvaluationMetricGroupForEvaluationMetricsGroupsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetEvaluationMetricGroupForEvaluationMetricsGroupsQuery>(
            GetEvaluationMetricGroupForEvaluationMetricsGroupsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "GetEvaluationMetricGroupForEvaluationMetricsGroups",
        "query",
        variables,
      );
    },
    CreateEvaluationMetricGroupForEvaluationMetricsGroups(
      variables: CreateEvaluationMetricGroupForEvaluationMetricsGroupsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CreateEvaluationMetricGroupForEvaluationMetricsGroupsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreateEvaluationMetricGroupForEvaluationMetricsGroupsMutation>(
            CreateEvaluationMetricGroupForEvaluationMetricsGroupsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "CreateEvaluationMetricGroupForEvaluationMetricsGroups",
        "mutation",
        variables,
      );
    },
    GetExamStatisticsAndRankForExamRankVisualizer(
      variables: GetExamStatisticsAndRankForExamRankVisualizerQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetExamStatisticsAndRankForExamRankVisualizerQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetExamStatisticsAndRankForExamRankVisualizerQuery>(GetExamStatisticsAndRankForExamRankVisualizerDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetExamStatisticsAndRankForExamRankVisualizer",
        "query",
        variables,
      );
    },
    ListBestExamsByAssessmentIdForExamTransitionVisualizer(
      variables: ListBestExamsByAssessmentIdForExamTransitionVisualizerQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListBestExamsByAssessmentIdForExamTransitionVisualizerQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListBestExamsByAssessmentIdForExamTransitionVisualizerQuery>(
            ListBestExamsByAssessmentIdForExamTransitionVisualizerDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "ListBestExamsByAssessmentIdForExamTransitionVisualizer",
        "query",
        variables,
      );
    },
    GetCompanyForGeneralSettings(
      variables: GetCompanyForGeneralSettingsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetCompanyForGeneralSettingsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetCompanyForGeneralSettingsQuery>(GetCompanyForGeneralSettingsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetCompanyForGeneralSettings",
        "query",
        variables,
      );
    },
    UpdateCompanyForGeneralSettings(
      variables: UpdateCompanyForGeneralSettingsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateCompanyForGeneralSettingsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateCompanyForGeneralSettingsMutation>(UpdateCompanyForGeneralSettingsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "UpdateCompanyForGeneralSettings",
        "mutation",
        variables,
      );
    },
    GetCompanyForGroupSettings(
      variables: GetCompanyForGroupSettingsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetCompanyForGroupSettingsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetCompanyForGroupSettingsQuery>(GetCompanyForGroupSettingsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetCompanyForGroupSettings",
        "query",
        variables,
      );
    },
    CreateGroup(variables: CreateGroupMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateGroupMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreateGroupMutation>(CreateGroupDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "CreateGroup",
        "mutation",
        variables,
      );
    },
    DeleteGroup(variables: DeleteGroupMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteGroupMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteGroupMutation>(DeleteGroupDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "DeleteGroup",
        "mutation",
        variables,
      );
    },
    DeleteGroups(variables: DeleteGroupsMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteGroupsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteGroupsMutation>(DeleteGroupsDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "DeleteGroups",
        "mutation",
        variables,
      );
    },
    EditGroup(variables: EditGroupMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<EditGroupMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<EditGroupMutation>(EditGroupDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "EditGroup",
        "mutation",
        variables,
      );
    },
    AddMembersToGroup(
      variables: AddMembersToGroupMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AddMembersToGroupMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<AddMembersToGroupMutation>(AddMembersToGroupDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "AddMembersToGroup",
        "mutation",
        variables,
      );
    },
    AddEmployeeToGroups(
      variables: AddEmployeeToGroupsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AddEmployeeToGroupsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<AddEmployeeToGroupsMutation>(AddEmployeeToGroupsDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "AddEmployeeToGroups",
        "mutation",
        variables,
      );
    },
    RemoveMembers(variables: RemoveMembersMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RemoveMembersMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<RemoveMembersMutation>(RemoveMembersDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "RemoveMembers",
        "mutation",
        variables,
      );
    },
    RegisterSonar(variables: RegisterSonarMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RegisterSonarMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<RegisterSonarMutation>(RegisterSonarDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "RegisterSonar",
        "mutation",
        variables,
      );
    },
    DeleteSonar(variables: DeleteSonarMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteSonarMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteSonarMutation>(DeleteSonarDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "DeleteSonar",
        "mutation",
        variables,
      );
    },
    ListSonarIntegrations(
      variables: ListSonarIntegrationsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListSonarIntegrationsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListSonarIntegrationsQuery>(ListSonarIntegrationsDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "ListSonarIntegrations",
        "query",
        variables,
      );
    },
    ListScreeningsForIntegrationSettings(
      variables: ListScreeningsForIntegrationSettingsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListScreeningsForIntegrationSettingsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListScreeningsForIntegrationSettingsQuery>(ListScreeningsForIntegrationSettingsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ListScreeningsForIntegrationSettings",
        "query",
        variables,
      );
    },
    GetInterviewFeedback(
      variables: GetInterviewFeedbackQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetInterviewFeedbackQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetInterviewFeedbackQuery>(GetInterviewFeedbackDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "GetInterviewFeedback",
        "query",
        variables,
      );
    },
    GetInterviewOverview(
      variables: GetInterviewOverviewQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetInterviewOverviewQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetInterviewOverviewQuery>(GetInterviewOverviewDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "GetInterviewOverview",
        "query",
        variables,
      );
    },
    GetInterviewReport(
      variables: GetInterviewReportQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetInterviewReportQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetInterviewReportQuery>(GetInterviewReportDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "GetInterviewReport",
        "query",
        variables,
      );
    },
    GetCompanyForMemberSettings(
      variables: GetCompanyForMemberSettingsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetCompanyForMemberSettingsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetCompanyForMemberSettingsQuery>(GetCompanyForMemberSettingsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetCompanyForMemberSettings",
        "query",
        variables,
      );
    },
    CreateEmployeeInvitation(
      variables: CreateEmployeeInvitationMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CreateEmployeeInvitationMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreateEmployeeInvitationMutation>(CreateEmployeeInvitationDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "CreateEmployeeInvitation",
        "mutation",
        variables,
      );
    },
    DeleteMultiCompanyEmployeesForMemberSettings(
      variables: DeleteMultiCompanyEmployeesForMemberSettingsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteMultiCompanyEmployeesForMemberSettingsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteMultiCompanyEmployeesForMemberSettingsMutation>(
            DeleteMultiCompanyEmployeesForMemberSettingsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "DeleteMultiCompanyEmployeesForMemberSettings",
        "mutation",
        variables,
      );
    },
    DeleteCompanyEmployeeForMemberSettings(
      variables: DeleteCompanyEmployeeForMemberSettingsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteCompanyEmployeeForMemberSettingsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteCompanyEmployeeForMemberSettingsMutation>(DeleteCompanyEmployeeForMemberSettingsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "DeleteCompanyEmployeeForMemberSettings",
        "mutation",
        variables,
      );
    },
    UpdateCompanyEmployeeForMemberSettings(
      variables: UpdateCompanyEmployeeForMemberSettingsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateCompanyEmployeeForMemberSettingsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateCompanyEmployeeForMemberSettingsMutation>(UpdateCompanyEmployeeForMemberSettingsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "UpdateCompanyEmployeeForMemberSettings",
        "mutation",
        variables,
      );
    },
    ReadEmployeeNotifications(
      variables: ReadEmployeeNotificationsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ReadEmployeeNotificationsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ReadEmployeeNotificationsMutation>(ReadEmployeeNotificationsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ReadEmployeeNotifications",
        "mutation",
        variables,
      );
    },
    UpdateSlackSettings(
      variables: UpdateSlackSettingsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateSlackSettingsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateSlackSettingsMutation>(UpdateSlackSettingsDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "UpdateSlackSettings",
        "mutation",
        variables,
      );
    },
    AuthorizeSlack(variables: AuthorizeSlackMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AuthorizeSlackMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<AuthorizeSlackMutation>(AuthorizeSlackDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "AuthorizeSlack",
        "mutation",
        variables,
      );
    },
    GetSlackSettings(variables: GetSlackSettingsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetSlackSettingsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetSlackSettingsQuery>(GetSlackSettingsDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "GetSlackSettings",
        "query",
        variables,
      );
    },
    GetPurchasesForOrderHistory(
      variables: GetPurchasesForOrderHistoryQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetPurchasesForOrderHistoryQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetPurchasesForOrderHistoryQuery>(GetPurchasesForOrderHistoryDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetPurchasesForOrderHistory",
        "query",
        variables,
      );
    },
    GetPlansForPaymentContractCreateForm(
      variables: GetPlansForPaymentContractCreateFormQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetPlansForPaymentContractCreateFormQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetPlansForPaymentContractCreateFormQuery>(GetPlansForPaymentContractCreateFormDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetPlansForPaymentContractCreateForm",
        "query",
        variables,
      );
    },
    ContractPlanForPaymentContractCreateForm(
      variables: ContractPlanForPaymentContractCreateFormMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ContractPlanForPaymentContractCreateFormMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ContractPlanForPaymentContractCreateFormMutation>(ContractPlanForPaymentContractCreateFormDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ContractPlanForPaymentContractCreateForm",
        "mutation",
        variables,
      );
    },
    GetCouponByCodeForPaymentContractCreateForm(
      variables: GetCouponByCodeForPaymentContractCreateFormQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetCouponByCodeForPaymentContractCreateFormQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetCouponByCodeForPaymentContractCreateFormQuery>(GetCouponByCodeForPaymentContractCreateFormDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetCouponByCodeForPaymentContractCreateForm",
        "query",
        variables,
      );
    },
    GetPaymentMethodUpdateForm(
      variables: GetPaymentMethodUpdateFormQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetPaymentMethodUpdateFormQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetPaymentMethodUpdateFormQuery>(GetPaymentMethodUpdateFormDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetPaymentMethodUpdateForm",
        "query",
        variables,
      );
    },
    UpdatePaymentV2CustomerForPaymentMethodUpdateForm(
      variables: UpdatePaymentV2CustomerForPaymentMethodUpdateFormMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdatePaymentV2CustomerForPaymentMethodUpdateFormMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdatePaymentV2CustomerForPaymentMethodUpdateFormMutation>(
            UpdatePaymentV2CustomerForPaymentMethodUpdateFormDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "UpdatePaymentV2CustomerForPaymentMethodUpdateForm",
        "mutation",
        variables,
      );
    },
    RetryPaymentForPaymentMethodUpdateForm(
      variables: RetryPaymentForPaymentMethodUpdateFormMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<RetryPaymentForPaymentMethodUpdateFormMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<RetryPaymentForPaymentMethodUpdateFormMutation>(RetryPaymentForPaymentMethodUpdateFormDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "RetryPaymentForPaymentMethodUpdateForm",
        "mutation",
        variables,
      );
    },
    GetPlansForPaymentPlanList(
      variables: GetPlansForPaymentPlanListQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetPlansForPaymentPlanListQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetPlansForPaymentPlanListQuery>(GetPlansForPaymentPlanListDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetPlansForPaymentPlanList",
        "query",
        variables,
      );
    },
    GetPlansForPaymentPlanUpdateForm(
      variables: GetPlansForPaymentPlanUpdateFormQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetPlansForPaymentPlanUpdateFormQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetPlansForPaymentPlanUpdateFormQuery>(GetPlansForPaymentPlanUpdateFormDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetPlansForPaymentPlanUpdateForm",
        "query",
        variables,
      );
    },
    ChangePlanForPlanUpdateForm(
      variables: ChangePlanForPlanUpdateFormMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ChangePlanForPlanUpdateFormMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ChangePlanForPlanUpdateFormMutation>(ChangePlanForPlanUpdateFormDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ChangePlanForPlanUpdateForm",
        "mutation",
        variables,
      );
    },
    StartProjectForProjectCodingEditor(
      variables: StartProjectForProjectCodingEditorMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<StartProjectForProjectCodingEditorMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<StartProjectForProjectCodingEditorMutation>(StartProjectForProjectCodingEditorDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "StartProjectForProjectCodingEditor",
        "mutation",
        variables,
      );
    },
    StartDevelopmentWorkspaceForProjectCodingEditor(
      variables: StartDevelopmentWorkspaceForProjectCodingEditorMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<StartDevelopmentWorkspaceForProjectCodingEditorMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<StartDevelopmentWorkspaceForProjectCodingEditorMutation>(
            StartDevelopmentWorkspaceForProjectCodingEditorDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "StartDevelopmentWorkspaceForProjectCodingEditor",
        "mutation",
        variables,
      );
    },
    ResetDevelopmentWorkspaceForProjectCodingEditor(
      variables: ResetDevelopmentWorkspaceForProjectCodingEditorMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ResetDevelopmentWorkspaceForProjectCodingEditorMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ResetDevelopmentWorkspaceForProjectCodingEditorMutation>(
            ResetDevelopmentWorkspaceForProjectCodingEditorDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "ResetDevelopmentWorkspaceForProjectCodingEditor",
        "mutation",
        variables,
      );
    },
    SaveProjectAppealMessageForProjectCodingEditor(
      variables: SaveProjectAppealMessageForProjectCodingEditorMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SaveProjectAppealMessageForProjectCodingEditorMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<SaveProjectAppealMessageForProjectCodingEditorMutation>(
            SaveProjectAppealMessageForProjectCodingEditorDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "SaveProjectAppealMessageForProjectCodingEditor",
        "mutation",
        variables,
      );
    },
    StartEvaluationWorkspaceForProjectCodingEditor(
      variables: StartEvaluationWorkspaceForProjectCodingEditorMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<StartEvaluationWorkspaceForProjectCodingEditorMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<StartEvaluationWorkspaceForProjectCodingEditorMutation>(
            StartEvaluationWorkspaceForProjectCodingEditorDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "StartEvaluationWorkspaceForProjectCodingEditor",
        "mutation",
        variables,
      );
    },
    ResetEvaluationWorkspaceForProjectCodingEditor(
      variables: ResetEvaluationWorkspaceForProjectCodingEditorMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ResetEvaluationWorkspaceForProjectCodingEditorMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ResetEvaluationWorkspaceForProjectCodingEditorMutation>(
            ResetEvaluationWorkspaceForProjectCodingEditorDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "ResetEvaluationWorkspaceForProjectCodingEditor",
        "mutation",
        variables,
      );
    },
    EnqueueProjectSubmissionForProjectCodingEditor(
      variables: EnqueueProjectSubmissionForProjectCodingEditorMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<EnqueueProjectSubmissionForProjectCodingEditorMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<EnqueueProjectSubmissionForProjectCodingEditorMutation>(
            EnqueueProjectSubmissionForProjectCodingEditorDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "EnqueueProjectSubmissionForProjectCodingEditor",
        "mutation",
        variables,
      );
    },
    RunProjectForProjectCodingEditor(
      variables: RunProjectForProjectCodingEditorMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<RunProjectForProjectCodingEditorMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<RunProjectForProjectCodingEditorMutation>(RunProjectForProjectCodingEditorDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "RunProjectForProjectCodingEditor",
        "mutation",
        variables,
      );
    },
    GetProjectQuestionInitialCode(
      variables: GetProjectQuestionInitialCodeQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetProjectQuestionInitialCodeQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetProjectQuestionInitialCodeQuery>(GetProjectQuestionInitialCodeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetProjectQuestionInitialCode",
        "query",
        variables,
      );
    },
    GetProjectStatistics(
      variables: GetProjectStatisticsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetProjectStatisticsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetProjectStatisticsQuery>(GetProjectStatisticsDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "GetProjectStatistics",
        "query",
        variables,
      );
    },
    ProjectSubmissionForProjectTestReport(
      variables: ProjectSubmissionForProjectTestReportQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ProjectSubmissionForProjectTestReportQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ProjectSubmissionForProjectTestReportQuery>(ProjectSubmissionForProjectTestReportDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ProjectSubmissionForProjectTestReport",
        "query",
        variables,
      );
    },
    GetProjectEntityForProjectTestReport(
      variables: GetProjectEntityForProjectTestReportQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetProjectEntityForProjectTestReportQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetProjectEntityForProjectTestReportQuery>(GetProjectEntityForProjectTestReportDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetProjectEntityForProjectTestReport",
        "query",
        variables,
      );
    },
    GetHierarchicalSkillTagsForQuestionSearchArea(
      variables: GetHierarchicalSkillTagsForQuestionSearchAreaQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetHierarchicalSkillTagsForQuestionSearchAreaQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetHierarchicalSkillTagsForQuestionSearchAreaQuery>(GetHierarchicalSkillTagsForQuestionSearchAreaDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetHierarchicalSkillTagsForQuestionSearchArea",
        "query",
        variables,
      );
    },
    ListQuestionsForQuestionSelectFieldForResourceEditor(
      variables: ListQuestionsForQuestionSelectFieldForResourceEditorQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListQuestionsForQuestionSelectFieldForResourceEditorQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListQuestionsForQuestionSelectFieldForResourceEditorQuery>(
            ListQuestionsForQuestionSelectFieldForResourceEditorDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "ListQuestionsForQuestionSelectFieldForResourceEditor",
        "query",
        variables,
      );
    },
    ListQuestionsPackagesForQuestionSelectFieldForResourceEditor(
      variables: ListQuestionsPackagesForQuestionSelectFieldForResourceEditorQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListQuestionsPackagesForQuestionSelectFieldForResourceEditorQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListQuestionsPackagesForQuestionSelectFieldForResourceEditorQuery>(
            ListQuestionsPackagesForQuestionSelectFieldForResourceEditorDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "ListQuestionsPackagesForQuestionSelectFieldForResourceEditor",
        "query",
        variables,
      );
    },
    GetMultiQuestionsForQuestionSelectFieldForResourceEditor(
      variables: GetMultiQuestionsForQuestionSelectFieldForResourceEditorQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetMultiQuestionsForQuestionSelectFieldForResourceEditorQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetMultiQuestionsForQuestionSelectFieldForResourceEditorQuery>(
            GetMultiQuestionsForQuestionSelectFieldForResourceEditorDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "GetMultiQuestionsForQuestionSelectFieldForResourceEditor",
        "query",
        variables,
      );
    },
    GetHierarchicalSkillTagsForQuestionSelectFieldForResourceEditor(
      variables: GetHierarchicalSkillTagsForQuestionSelectFieldForResourceEditorQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetHierarchicalSkillTagsForQuestionSelectFieldForResourceEditorQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetHierarchicalSkillTagsForQuestionSelectFieldForResourceEditorQuery>(
            GetHierarchicalSkillTagsForQuestionSelectFieldForResourceEditorDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "GetHierarchicalSkillTagsForQuestionSelectFieldForResourceEditor",
        "query",
        variables,
      );
    },
    StartQuizForQuizCodingEditor(
      variables: StartQuizForQuizCodingEditorMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<StartQuizForQuizCodingEditorMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<StartQuizForQuizCodingEditorMutation>(StartQuizForQuizCodingEditorDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "StartQuizForQuizCodingEditor",
        "mutation",
        variables,
      );
    },
    SubmitQuizQuestionForQuizCodingEditor(
      variables: SubmitQuizQuestionForQuizCodingEditorMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SubmitQuizQuestionForQuizCodingEditorMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<SubmitQuizQuestionForQuizCodingEditorMutation>(SubmitQuizQuestionForQuizCodingEditorDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "SubmitQuizQuestionForQuizCodingEditor",
        "mutation",
        variables,
      );
    },
    TranslateMarkdownForQuizResourceEditor(
      variables: TranslateMarkdownForQuizResourceEditorMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<TranslateMarkdownForQuizResourceEditorMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<TranslateMarkdownForQuizResourceEditorMutation>(TranslateMarkdownForQuizResourceEditorDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "TranslateMarkdownForQuizResourceEditor",
        "mutation",
        variables,
      );
    },
    GetQuizStatistics(
      variables: GetQuizStatisticsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetQuizStatisticsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetQuizStatisticsQuery>(GetQuizStatisticsDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "GetQuizStatistics",
        "query",
        variables,
      );
    },
    GetQuizEntityForQuizTestReport(
      variables: GetQuizEntityForQuizTestReportQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetQuizEntityForQuizTestReportQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetQuizEntityForQuizTestReportQuery>(GetQuizEntityForQuizTestReportDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetQuizEntityForQuizTestReport",
        "query",
        variables,
      );
    },
    EvaluateQuizSubmission(
      variables: EvaluateQuizSubmissionMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<EvaluateQuizSubmissionMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<EvaluateQuizSubmissionMutation>(EvaluateQuizSubmissionDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "EvaluateQuizSubmission",
        "mutation",
        variables,
      );
    },
    GetRemoteForRemoteInterviewFeedback(
      variables: GetRemoteForRemoteInterviewFeedbackQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetRemoteForRemoteInterviewFeedbackQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetRemoteForRemoteInterviewFeedbackQuery>(GetRemoteForRemoteInterviewFeedbackDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetRemoteForRemoteInterviewFeedback",
        "query",
        variables,
      );
    },
    GetRemoteForRemoteInterviewOverview(
      variables: GetRemoteForRemoteInterviewOverviewQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetRemoteForRemoteInterviewOverviewQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetRemoteForRemoteInterviewOverviewQuery>(GetRemoteForRemoteInterviewOverviewDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetRemoteForRemoteInterviewOverview",
        "query",
        variables,
      );
    },
    UpdateRemoteForRemoteInterviewOverview(
      variables: UpdateRemoteForRemoteInterviewOverviewMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateRemoteForRemoteInterviewOverviewMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateRemoteForRemoteInterviewOverviewMutation>(UpdateRemoteForRemoteInterviewOverviewDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "UpdateRemoteForRemoteInterviewOverview",
        "mutation",
        variables,
      );
    },
    ListRemoteTagsForRemoteInterviewOverview(
      variables: ListRemoteTagsForRemoteInterviewOverviewQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListRemoteTagsForRemoteInterviewOverviewQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListRemoteTagsForRemoteInterviewOverviewQuery>(ListRemoteTagsForRemoteInterviewOverviewDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ListRemoteTagsForRemoteInterviewOverview",
        "query",
        variables,
      );
    },
    GetMultiEmployees(
      variables: GetMultiEmployeesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetMultiEmployeesQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetMultiEmployeesQuery>(GetMultiEmployeesDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "GetMultiEmployees",
        "query",
        variables,
      );
    },
    GetRemoteForRemoteInterviewPlayback(
      variables: GetRemoteForRemoteInterviewPlaybackQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetRemoteForRemoteInterviewPlaybackQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetRemoteForRemoteInterviewPlaybackQuery>(GetRemoteForRemoteInterviewPlaybackDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetRemoteForRemoteInterviewPlayback",
        "query",
        variables,
      );
    },
    GetTemplateForRemoteInterviewTemplateListMenu(
      variables: GetTemplateForRemoteInterviewTemplateListMenuQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetTemplateForRemoteInterviewTemplateListMenuQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetTemplateForRemoteInterviewTemplateListMenuQuery>(GetTemplateForRemoteInterviewTemplateListMenuDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetTemplateForRemoteInterviewTemplateListMenu",
        "query",
        variables,
      );
    },
    ListTemplatesForRemoteInterviewTemplateListMenu(
      variables: ListTemplatesForRemoteInterviewTemplateListMenuQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListTemplatesForRemoteInterviewTemplateListMenuQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListTemplatesForRemoteInterviewTemplateListMenuQuery>(
            ListTemplatesForRemoteInterviewTemplateListMenuDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "ListTemplatesForRemoteInterviewTemplateListMenu",
        "query",
        variables,
      );
    },
    DeleteTemplateForRemoteInterviewTemplateListMenu(
      variables: DeleteTemplateForRemoteInterviewTemplateListMenuMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteTemplateForRemoteInterviewTemplateListMenuMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteTemplateForRemoteInterviewTemplateListMenuMutation>(
            DeleteTemplateForRemoteInterviewTemplateListMenuDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "DeleteTemplateForRemoteInterviewTemplateListMenu",
        "mutation",
        variables,
      );
    },
    FillTemplateForRemoteInterviewTemplateListMenu(
      variables: FillTemplateForRemoteInterviewTemplateListMenuMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<FillTemplateForRemoteInterviewTemplateListMenuMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<FillTemplateForRemoteInterviewTemplateListMenuMutation>(
            FillTemplateForRemoteInterviewTemplateListMenuDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "FillTemplateForRemoteInterviewTemplateListMenu",
        "mutation",
        variables,
      );
    },
    GetMultiEmployeesForRemoteInterviewVideoChatParticipants(
      variables: GetMultiEmployeesForRemoteInterviewVideoChatParticipantsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetMultiEmployeesForRemoteInterviewVideoChatParticipantsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetMultiEmployeesForRemoteInterviewVideoChatParticipantsQuery>(
            GetMultiEmployeesForRemoteInterviewVideoChatParticipantsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "GetMultiEmployeesForRemoteInterviewVideoChatParticipants",
        "query",
        variables,
      );
    },
    GetScheduleEvents(
      variables: GetScheduleEventsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetScheduleEventsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetScheduleEventsQuery>(GetScheduleEventsDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "GetScheduleEvents",
        "query",
        variables,
      );
    },
    StartRemoteForScheduleEvents(
      variables: StartRemoteForScheduleEventsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<StartRemoteForScheduleEventsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<StartRemoteForScheduleEventsMutation>(StartRemoteForScheduleEventsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "StartRemoteForScheduleEvents",
        "mutation",
        variables,
      );
    },
    GetScreeningForScreeningDetail(
      variables: GetScreeningForScreeningDetailQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetScreeningForScreeningDetailQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetScreeningForScreeningDetailQuery>(GetScreeningForScreeningDetailDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetScreeningForScreeningDetail",
        "query",
        variables,
      );
    },
    UpdateScreeningForScreeningDetail(
      variables: UpdateScreeningForScreeningDetailMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateScreeningForScreeningDetailMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateScreeningForScreeningDetailMutation>(UpdateScreeningForScreeningDetailDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "UpdateScreeningForScreeningDetail",
        "mutation",
        variables,
      );
    },
    DeleteScreeningForScreeningDetail(
      variables: DeleteScreeningForScreeningDetailMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteScreeningForScreeningDetailMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteScreeningForScreeningDetailMutation>(DeleteScreeningForScreeningDetailDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "DeleteScreeningForScreeningDetail",
        "mutation",
        variables,
      );
    },
    ListSpotTagsForScreeningDetail(
      variables: ListSpotTagsForScreeningDetailQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListSpotTagsForScreeningDetailQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListSpotTagsForScreeningDetailQuery>(ListSpotTagsForScreeningDetailDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ListSpotTagsForScreeningDetail",
        "query",
        variables,
      );
    },
    InviteCandidateToScreeningForScreeningDetail(
      variables: InviteCandidateToScreeningForScreeningDetailMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<InviteCandidateToScreeningForScreeningDetailMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<InviteCandidateToScreeningForScreeningDetailMutation>(
            InviteCandidateToScreeningForScreeningDetailDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "InviteCandidateToScreeningForScreeningDetail",
        "mutation",
        variables,
      );
    },
    ListSpotsByScreeningIdForScreeningDetail(
      variables: ListSpotsByScreeningIdForScreeningDetailQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListSpotsByScreeningIdForScreeningDetailQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListSpotsByScreeningIdForScreeningDetailQuery>(ListSpotsByScreeningIdForScreeningDetailDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ListSpotsByScreeningIdForScreeningDetail",
        "query",
        variables,
      );
    },
    GetSpotTagsByCompanyIdForScreeningDetail(
      variables: GetSpotTagsByCompanyIdForScreeningDetailQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetSpotTagsByCompanyIdForScreeningDetailQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetSpotTagsByCompanyIdForScreeningDetailQuery>(GetSpotTagsByCompanyIdForScreeningDetailDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetSpotTagsByCompanyIdForScreeningDetail",
        "query",
        variables,
      );
    },
    ListSpotFilterOptionsByScreeningIdForScreeningDetail(
      variables: ListSpotFilterOptionsByScreeningIdForScreeningDetailQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListSpotFilterOptionsByScreeningIdForScreeningDetailQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListSpotFilterOptionsByScreeningIdForScreeningDetailQuery>(
            ListSpotFilterOptionsByScreeningIdForScreeningDetailDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "ListSpotFilterOptionsByScreeningIdForScreeningDetail",
        "query",
        variables,
      );
    },
    EvaluateMultiSpotsForScreeningsDetail(
      variables: EvaluateMultiSpotsForScreeningsDetailMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<EvaluateMultiSpotsForScreeningsDetailMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<EvaluateMultiSpotsForScreeningsDetailMutation>(EvaluateMultiSpotsForScreeningsDetailDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "EvaluateMultiSpotsForScreeningsDetail",
        "mutation",
        variables,
      );
    },
    ListScreeningInviteeForScreeningInviteeList(
      variables: ListScreeningInviteeForScreeningInviteeListQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListScreeningInviteeForScreeningInviteeListQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListScreeningInviteeForScreeningInviteeListQuery>(ListScreeningInviteeForScreeningInviteeListDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ListScreeningInviteeForScreeningInviteeList",
        "query",
        variables,
      );
    },
    GetScreeningResourceEditor(
      variables: GetScreeningResourceEditorQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetScreeningResourceEditorQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetScreeningResourceEditorQuery>(GetScreeningResourceEditorDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetScreeningResourceEditor",
        "query",
        variables,
      );
    },
    UpdateSpotForScreeningTestOverview(
      variables: UpdateSpotForScreeningTestOverviewMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateSpotForScreeningTestOverviewMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateSpotForScreeningTestOverviewMutation>(UpdateSpotForScreeningTestOverviewDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "UpdateSpotForScreeningTestOverview",
        "mutation",
        variables,
      );
    },
    ListSpotTagsForScreeningTestOverview(
      variables: ListSpotTagsForScreeningTestOverviewQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListSpotTagsForScreeningTestOverviewQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListSpotTagsForScreeningTestOverviewQuery>(ListSpotTagsForScreeningTestOverviewDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ListSpotTagsForScreeningTestOverview",
        "query",
        variables,
      );
    },
    GetScreeningTestResourceEditor(
      variables: GetScreeningTestResourceEditorQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetScreeningTestResourceEditorQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetScreeningTestResourceEditorQuery>(GetScreeningTestResourceEditorDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetScreeningTestResourceEditor",
        "query",
        variables,
      );
    },
    GetCompanyForSecuritySettings(
      variables: GetCompanyForSecuritySettingsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetCompanyForSecuritySettingsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetCompanyForSecuritySettingsQuery>(GetCompanyForSecuritySettingsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetCompanyForSecuritySettings",
        "query",
        variables,
      );
    },
    GetSecurityByEmail(
      variables: GetSecurityByEmailQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetSecurityByEmailQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetSecurityByEmailQuery>(GetSecurityByEmailDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "GetSecurityByEmail",
        "query",
        variables,
      );
    },
    UpdateSecurity(variables: UpdateSecurityMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateSecurityMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateSecurityMutation>(UpdateSecurityDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "UpdateSecurity",
        "mutation",
        variables,
      );
    },
    AuthorizeSlackForSlackNotificationSettings(
      variables: AuthorizeSlackForSlackNotificationSettingsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AuthorizeSlackForSlackNotificationSettingsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<AuthorizeSlackForSlackNotificationSettingsMutation>(AuthorizeSlackForSlackNotificationSettingsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "AuthorizeSlackForSlackNotificationSettings",
        "mutation",
        variables,
      );
    },
    ListSpotTagsForSpotTagField(
      variables: ListSpotTagsForSpotTagFieldQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListSpotTagsForSpotTagFieldQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListSpotTagsForSpotTagFieldQuery>(ListSpotTagsForSpotTagFieldDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ListSpotTagsForSpotTagField",
        "query",
        variables,
      );
    },
    GetInvoicesForSubscriptionInvoiceV2(
      variables: GetInvoicesForSubscriptionInvoiceV2QueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetInvoicesForSubscriptionInvoiceV2Query> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetInvoicesForSubscriptionInvoiceV2Query>(GetInvoicesForSubscriptionInvoiceV2Document, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetInvoicesForSubscriptionInvoiceV2",
        "query",
        variables,
      );
    },
    GetContractPeriodUsagesForSubscriptionOverviewV2(
      variables: GetContractPeriodUsagesForSubscriptionOverviewV2QueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetContractPeriodUsagesForSubscriptionOverviewV2Query> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetContractPeriodUsagesForSubscriptionOverviewV2Query>(
            GetContractPeriodUsagesForSubscriptionOverviewV2Document,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "GetContractPeriodUsagesForSubscriptionOverviewV2",
        "query",
        variables,
      );
    },
    GetSubscribeUsagesForSubscriptionUsageV2(
      variables: GetSubscribeUsagesForSubscriptionUsageV2QueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetSubscribeUsagesForSubscriptionUsageV2Query> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetSubscribeUsagesForSubscriptionUsageV2Query>(GetSubscribeUsagesForSubscriptionUsageV2Document, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetSubscribeUsagesForSubscriptionUsageV2",
        "query",
        variables,
      );
    },
    RunSystemDesign(
      variables: RunSystemDesignMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<RunSystemDesignMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<RunSystemDesignMutation>(RunSystemDesignDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "RunSystemDesign",
        "mutation",
        variables,
      );
    },
    SaveSystemDesignAppealMessage(
      variables: SaveSystemDesignAppealMessageMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SaveSystemDesignAppealMessageMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<SaveSystemDesignAppealMessageMutation>(SaveSystemDesignAppealMessageDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "SaveSystemDesignAppealMessage",
        "mutation",
        variables,
      );
    },
    EnqueueSystemDesignSubmission(
      variables: EnqueueSystemDesignSubmissionMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<EnqueueSystemDesignSubmissionMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<EnqueueSystemDesignSubmissionMutation>(EnqueueSystemDesignSubmissionDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "EnqueueSystemDesignSubmission",
        "mutation",
        variables,
      );
    },
    StartSystemDesign(
      variables: StartSystemDesignMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<StartSystemDesignMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<StartSystemDesignMutation>(StartSystemDesignDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "StartSystemDesign",
        "mutation",
        variables,
      );
    },
    GetSystemDesignStatistics(
      variables: GetSystemDesignStatisticsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetSystemDesignStatisticsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetSystemDesignStatisticsQuery>(GetSystemDesignStatisticsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetSystemDesignStatistics",
        "query",
        variables,
      );
    },
    GetSystemDesignSubmissionForSystemDesignTestReport(
      variables: GetSystemDesignSubmissionForSystemDesignTestReportQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetSystemDesignSubmissionForSystemDesignTestReportQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetSystemDesignSubmissionForSystemDesignTestReportQuery>(
            GetSystemDesignSubmissionForSystemDesignTestReportDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "GetSystemDesignSubmissionForSystemDesignTestReport",
        "query",
        variables,
      );
    },
    GetSystemDesignEntityForSystemDesignTestReport(
      variables: GetSystemDesignEntityForSystemDesignTestReportQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetSystemDesignEntityForSystemDesignTestReportQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetSystemDesignEntityForSystemDesignTestReportQuery>(
            GetSystemDesignEntityForSystemDesignTestReportDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "GetSystemDesignEntityForSystemDesignTestReport",
        "query",
        variables,
      );
    },
    ReevaluateSystemDesign(
      variables: ReevaluateSystemDesignMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ReevaluateSystemDesignMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ReevaluateSystemDesignMutation>(ReevaluateSystemDesignDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ReevaluateSystemDesign",
        "mutation",
        variables,
      );
    },
    GetTalentsForTalentAssignField(
      variables: GetTalentsForTalentAssignFieldQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetTalentsForTalentAssignFieldQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetTalentsForTalentAssignFieldQuery>(GetTalentsForTalentAssignFieldDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetTalentsForTalentAssignField",
        "query",
        variables,
      );
    },
    GetCompanyForTalentMemberSettings(
      variables: GetCompanyForTalentMemberSettingsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetCompanyForTalentMemberSettingsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetCompanyForTalentMemberSettingsQuery>(GetCompanyForTalentMemberSettingsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetCompanyForTalentMemberSettings",
        "query",
        variables,
      );
    },
    CreateTalentInvitation(
      variables: CreateTalentInvitationMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CreateTalentInvitationMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CreateTalentInvitationMutation>(CreateTalentInvitationDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "CreateTalentInvitation",
        "mutation",
        variables,
      );
    },
    DeleteMultiCompanyTalentsForTalentMemberSettings(
      variables: DeleteMultiCompanyTalentsForTalentMemberSettingsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteMultiCompanyTalentsForTalentMemberSettingsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteMultiCompanyTalentsForTalentMemberSettingsMutation>(
            DeleteMultiCompanyTalentsForTalentMemberSettingsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "DeleteMultiCompanyTalentsForTalentMemberSettings",
        "mutation",
        variables,
      );
    },
    DeleteCompanyTalentForTalentMemberSettings(
      variables: DeleteCompanyTalentForTalentMemberSettingsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteCompanyTalentForTalentMemberSettingsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteCompanyTalentForTalentMemberSettingsMutation>(DeleteCompanyTalentForTalentMemberSettingsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "DeleteCompanyTalentForTalentMemberSettings",
        "mutation",
        variables,
      );
    },
    ListEvaluationMetricsForTechnicalCommentEvaluationsForm(
      variables: ListEvaluationMetricsForTechnicalCommentEvaluationsFormQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListEvaluationMetricsForTechnicalCommentEvaluationsFormQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListEvaluationMetricsForTechnicalCommentEvaluationsFormQuery>(
            ListEvaluationMetricsForTechnicalCommentEvaluationsFormDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "ListEvaluationMetricsForTechnicalCommentEvaluationsForm",
        "query",
        variables,
      );
    },
    ReviewExamForTechnicalComment(
      variables: ReviewExamForTechnicalCommentMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ReviewExamForTechnicalCommentMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ReviewExamForTechnicalCommentMutation>(ReviewExamForTechnicalCommentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ReviewExamForTechnicalComment",
        "mutation",
        variables,
      );
    },
    ListEvaluationMetricGroupsByCompanyIdForTechnicalComment(
      variables: ListEvaluationMetricGroupsByCompanyIdForTechnicalCommentQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListEvaluationMetricGroupsByCompanyIdForTechnicalCommentQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListEvaluationMetricGroupsByCompanyIdForTechnicalCommentQuery>(
            ListEvaluationMetricGroupsByCompanyIdForTechnicalCommentDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "ListEvaluationMetricGroupsByCompanyIdForTechnicalComment",
        "query",
        variables,
      );
    },
    GetEvaluationMetricGroupForTechnicalComment(
      variables: GetEvaluationMetricGroupForTechnicalCommentQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetEvaluationMetricGroupForTechnicalCommentQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetEvaluationMetricGroupForTechnicalCommentQuery>(GetEvaluationMetricGroupForTechnicalCommentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetEvaluationMetricGroupForTechnicalComment",
        "query",
        variables,
      );
    },
    ReviewSpotForTechnicalComment(
      variables: ReviewSpotForTechnicalCommentMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ReviewSpotForTechnicalCommentMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ReviewSpotForTechnicalCommentMutation>(ReviewSpotForTechnicalCommentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ReviewSpotForTechnicalComment",
        "mutation",
        variables,
      );
    },
    GetEvaluationMetricsForTechnicalCommentForRemote(
      variables: GetEvaluationMetricsForTechnicalCommentForRemoteQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetEvaluationMetricsForTechnicalCommentForRemoteQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetEvaluationMetricsForTechnicalCommentForRemoteQuery>(
            GetEvaluationMetricsForTechnicalCommentForRemoteDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "GetEvaluationMetricsForTechnicalCommentForRemote",
        "query",
        variables,
      );
    },
    ListEvaluationMetricsByCompanyIdForTechnicalCommentPromptForRemote(
      variables: ListEvaluationMetricsByCompanyIdForTechnicalCommentPromptForRemoteQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListEvaluationMetricsByCompanyIdForTechnicalCommentPromptForRemoteQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListEvaluationMetricsByCompanyIdForTechnicalCommentPromptForRemoteQuery>(
            ListEvaluationMetricsByCompanyIdForTechnicalCommentPromptForRemoteDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "ListEvaluationMetricsByCompanyIdForTechnicalCommentPromptForRemote",
        "query",
        variables,
      );
    },
    ReviewRemoteForTechnicalCommentRemote(
      variables: ReviewRemoteForTechnicalCommentRemoteMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ReviewRemoteForTechnicalCommentRemoteMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ReviewRemoteForTechnicalCommentRemoteMutation>(ReviewRemoteForTechnicalCommentRemoteDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ReviewRemoteForTechnicalCommentRemote",
        "mutation",
        variables,
      );
    },
    ListSpotsByTagsForTestRankVisualizer(
      variables: ListSpotsByTagsForTestRankVisualizerQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListSpotsByTagsForTestRankVisualizerQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListSpotsByTagsForTestRankVisualizerQuery>(ListSpotsByTagsForTestRankVisualizerDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ListSpotsByTagsForTestRankVisualizer",
        "query",
        variables,
      );
    },
    GetSpotStatisticsAndRankForTestRankVisualizer(
      variables: GetSpotStatisticsAndRankForTestRankVisualizerQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetSpotStatisticsAndRankForTestRankVisualizerQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetSpotStatisticsAndRankForTestRankVisualizerQuery>(GetSpotStatisticsAndRankForTestRankVisualizerDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetSpotStatisticsAndRankForTestRankVisualizer",
        "query",
        variables,
      );
    },
    ListSpotTagsForTestRankVisualizer(
      variables: ListSpotTagsForTestRankVisualizerQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListSpotTagsForTestRankVisualizerQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListSpotTagsForTestRankVisualizerQuery>(ListSpotTagsForTestRankVisualizerDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ListSpotTagsForTestRankVisualizer",
        "query",
        variables,
      );
    },
    UpdateUserForUserProfile(
      variables: UpdateUserForUserProfileMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateUserForUserProfileMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateUserForUserProfileMutation>(UpdateUserForUserProfileDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "UpdateUserForUserProfile",
        "mutation",
        variables,
      );
    },
    UpdateUserLanguage(
      variables: UpdateUserLanguageMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateUserLanguageMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateUserLanguageMutation>(UpdateUserLanguageDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "UpdateUserLanguage",
        "mutation",
        variables,
      );
    },
    UploadUserImageForUserProfile(
      variables: UploadUserImageForUserProfileMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UploadUserImageForUserProfileMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UploadUserImageForUserProfileMutation>(UploadUserImageForUserProfileDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "UploadUserImageForUserProfile",
        "mutation",
        variables,
      );
    },
    GetViewersForViewerAssignField(
      variables: GetViewersForViewerAssignFieldQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetViewersForViewerAssignFieldQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetViewersForViewerAssignFieldQuery>(GetViewersForViewerAssignFieldDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetViewersForViewerAssignField",
        "query",
        variables,
      );
    },
    GetAssessmentsByTalentIdForExamTabTablesForTalent(
      variables: GetAssessmentsByTalentIdForExamTabTablesForTalentQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetAssessmentsByTalentIdForExamTabTablesForTalentQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetAssessmentsByTalentIdForExamTabTablesForTalentQuery>(
            GetAssessmentsByTalentIdForExamTabTablesForTalentDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "GetAssessmentsByTalentIdForExamTabTablesForTalent",
        "query",
        variables,
      );
    },
    ListBestExamsByAssessmentIdForExamListForTalent(
      variables: ListBestExamsByAssessmentIdForExamListForTalentQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListBestExamsByAssessmentIdForExamListForTalentQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListBestExamsByAssessmentIdForExamListForTalentQuery>(
            ListBestExamsByAssessmentIdForExamListForTalentDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "ListBestExamsByAssessmentIdForExamListForTalent",
        "query",
        variables,
      );
    },
    ReCreateExamForExamListForTalent(
      variables: ReCreateExamForExamListForTalentMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ReCreateExamForExamListForTalentMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ReCreateExamForExamListForTalentMutation>(ReCreateExamForExamListForTalentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ReCreateExamForExamListForTalent",
        "mutation",
        variables,
      );
    },
    GetTailExamForExamListForTalent(
      variables: GetTailExamForExamListForTalentQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetTailExamForExamListForTalentQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetTailExamForExamListForTalentQuery>(GetTailExamForExamListForTalentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetTailExamForExamListForTalent",
        "query",
        variables,
      );
    },
    ListTalentNotificationsForNotificationListForTalent(
      variables: ListTalentNotificationsForNotificationListForTalentQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListTalentNotificationsForNotificationListForTalentQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ListTalentNotificationsForNotificationListForTalentQuery>(
            ListTalentNotificationsForNotificationListForTalentDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "ListTalentNotificationsForNotificationListForTalent",
        "query",
        variables,
      );
    },
    ReadTalentNotificationsForNotificationListForTalent(
      variables: ReadTalentNotificationsForNotificationListForTalentMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ReadTalentNotificationsForNotificationListForTalentMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<ReadTalentNotificationsForNotificationListForTalentMutation>(
            ReadTalentNotificationsForNotificationListForTalentDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "ReadTalentNotificationsForNotificationListForTalent",
        "mutation",
        variables,
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
