import { useSnapshot } from "valtio";

import { state } from "./State";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useTalents = () => {
  const snapshot = useSnapshotState();
  return snapshot.talents;
};
