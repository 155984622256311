import { useTranslation } from "@hireroo/i18n";
import { MemberSearchForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import GroupAddOutlined from "@mui/icons-material/GroupAddOutlined";
import IndeterminateCheckBoxOutlined from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button, { type ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import SearchForm, { SearchFormProps } from "../../../../modules/SearchForm/SearchForm";
import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "../../../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";
import InviteEmployee, { InviteEmployeeProps } from "../InviteEmployee/InviteEmployee";
import InviteTalent, { InviteTalentProps } from "../InviteTalent/InviteTalent";

const StyledPaper = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  borderRadius: "4px",
  alignItems: "center",
  backgroundColor: theme.palette.Other.FilledInputBG,
  boxShadow: "none",
}));

const StyledSearchForm = styled(SearchForm)(() => ({
  "& .MuiInputBase-input": {
    padding: "0px",
  },
}));

export type MemberHeaderProps = {
  unselectButton: Pick<IconButtonWithTooltipProps, "onClick" | "disabled">;
  groupAddButton?: Pick<IconButtonWithTooltipProps, "onClick" | "disabled">;
  deleteButton: Pick<IconButtonWithTooltipProps, "onClick" | "disabled">;
  searchTextField: Pick<SearchFormProps["textFilter"], "placeholder"> & {
    onSubmit: SubmitHandler<MemberSearchForm.MemberSearchFormSchema>;
  };
  inviteButton: Pick<ButtonProps, "onClick">;
  inviteEmployee?: InviteEmployeeProps;
  inviteTalent?: InviteTalentProps;
};

const MemberHeader: React.FC<MemberHeaderProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const validateSchema = MemberSearchForm.useMemberSearchForm();
  const methods = useForm<MemberSearchForm.MemberSearchFormSchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: {
      textFilter: "",
    },
  });
  const { onSubmit } = props.searchTextField;

  React.useEffect(() => {
    const subscription = methods.watch(() => {
      onSubmit(methods.getValues());
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [methods, onSubmit]);
  const inviteButton: ButtonProps = {
    ...props.inviteButton,
    variant: "contained",
    color: "secondary",
    sx: {
      wordBreak: "keep-all",
    },
    startIcon: <PersonAddOutlinedIcon />,
    children: t("招待する"),
  };
  // TODO: This should be checkbox with select all feature included. But there is no pager in list, so we don't want to select all unless the pager is implemented.
  const unselectButton: IconButtonWithTooltipProps = {
    ...props.unselectButton,
    size: "small",
    title: t("選択を全解除する"),
    children: <IndeterminateCheckBoxOutlined sx={{ height: "20px", width: "20px" }} />,
  };
  const groupAddButton: IconButtonWithTooltipProps | undefined = props.groupAddButton
    ? {
        ...props.groupAddButton,
        onClick: event => {
          props.groupAddButton?.onClick?.(event);
        },
        title: t("グループに追加"),
        children: <GroupAddOutlined sx={{ height: "20px", width: "20px" }} />,
      }
    : undefined;
  const deleteButton: IconButtonWithTooltipProps = {
    ...props.deleteButton,
    onClick: event => {
      props.deleteButton.onClick?.(event);
    },
    title: t("削除する"),
    children: <DeleteOutlineIcon sx={{ height: "20px", width: "20px" }} />,
  };
  const searchFormProps: SearchFormProps = {
    textFilter: {
      ...props.searchTextField,
      name: "textFilter",
      variant: "standard",
      InputProps: {
        sx: {
          fontSize: "14px",
        },
        disableUnderline: true,
        startAdornment: (
          <Box mr={1} display="flex" alignItems="center">
            <SearchIcon fontSize="small" htmlColor={theme.palette["Gray/Shades"].p56} />
          </Box>
        ),
      },
    },
    kind: "SINGLE_LINE",
    fields: [],
  };
  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="row" alignItems="center" spacing={1.5}>
        <IconButtonWithTooltip key="unselect" {...unselectButton} />
        {groupAddButton && <IconButtonWithTooltip key="group-add" {...groupAddButton} />}
        <IconButtonWithTooltip key="delete" {...deleteButton} />
        <Button {...inviteButton} />
      </Stack>
      <FormProvider {...methods}>
        <StyledPaper width="35%">
          <StyledSearchForm {...searchFormProps} />
        </StyledPaper>
      </FormProvider>
      {props.inviteEmployee && <InviteEmployee {...props.inviteEmployee} />}
      {props.inviteTalent && <InviteTalent {...props.inviteTalent} />}
    </Stack>
  );
};

MemberHeader.displayName = "MemberHeader";

export default MemberHeader;
