import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { Credential } from "@hireroo/app-store/essential/shared";
import { ThemeProvider } from "@hireroo/ui-theme/theme-v2";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";
import { Provider } from "urql";

import * as Graphql from "./graphql";
import AppRouter from "./routes";

const cache = createCache({
  key: "css",
  prepend: true,
});

export const App: React.FC = () => {
  const authToken = Credential.useAuthToken();
  const graphqlClient = React.useMemo(() => {
    return Graphql.createClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);
  return (
    <ErrorBoundary>
      <Provider value={graphqlClient}>
        <CacheProvider value={cache}>
          <ThemeProvider>
            <AppRouter />
          </ThemeProvider>
        </CacheProvider>
      </Provider>
    </ErrorBoundary>
  );
};

App.displayName = "App";

export default withErrorBoundary(App, {});
