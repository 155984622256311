import { App, Company } from "@hireroo/app-store/essential/employee";
import { ScreeningsStore } from "@hireroo/app-store/page/e/screenings";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import * as React from "react";

import ScreeningListContainer, { ScreeningListContainerProps } from "./Container";

export type ScreeningListFetchContainerProps = {};

const ScreeningListFetchContainer: React.FC<ScreeningListFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const companyId = Company.useStrictActiveCompanyId();
  const currentSearchFilter = ScreeningsStore.useCurrentSearchFilter();
  const initialized = ScreeningsStore.useInitialized();
  const pager = ScreeningsStore.usePager();
  const { isDescending } = ScreeningsStore.useSortParams();
  const [result, refresh] = Graphql.useListScreeningsByCompanyIdForScreeningsQuery({
    variables: {
      input: {
        companyId: companyId,
        filters: {
          name: currentSearchFilter.textFilter || undefined,
          activeStatus: currentSearchFilter.activeStatus ?? undefined,
          tagNames: currentSearchFilter.tags.map(tag => tag.displayName),
        },
        size: pager.size,
        offset: pager.offset,
        isDescending: isDescending,
        withCount: true,
      },
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "cache-and-network",
  });

  React.useEffect(() => {
    if (result.data?.screeningsByCompanyId) {
      ScreeningsStore.setResponse(result.data.screeningsByCompanyId);
    }
  }, [result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: ScreeningListContainerProps = {};

  return <ScreeningListContainer {...containerProps} />;
};

ScreeningListFetchContainer.displayName = "ScreeningListFetchContainer";

export default withErrorBoundary(ScreeningListFetchContainer, {});
