import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { InterviewOverview } from "@hireroo/app-store/widget/e/InterviewOverview";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/react";
import * as React from "react";

import { useFetchTags } from "./useFetchTags";

export type GenerateTagDialogPropsArgs = {
  companyId: number;
};

export const useGenerateTagDialogProps = (args: GenerateTagDialogPropsArgs): Widget.ScreeningTestOverviewProps["testSummary"]["tagDialog"] => {
  const [open, setOpen] = React.useState(false);
  const interview = InterviewOverview.useInterview();
  const { t } = useTranslation();
  const [resultUpdateSpot, updateSpot] = Graphql.useUpdateSpotForScreeningTestOverviewMutation();
  const { isLoading } = useFetchTags(args);
  const selectableTags = InterviewOverview.useTags();
  const defaultTags = interview.tags.map(t => t.name);

  return {
    open,
    tagInputField: {
      disabled: isLoading,
      options: selectableTags,
    },
    onOpen: () => {
      setOpen(true);
    },
    onClose: () => {
      setOpen(false);
    },
    defaultValues: {
      tags: defaultTags,
    },
    disabled: resultUpdateSpot.fetching,
    onSubmit: fields => {
      updateSpot({
        input: { spotId: interview.id, tagNames: fields.tags },
      }).then(result => {
        if (result.data?.updateSpot) {
          InterviewOverview.updateInterview(result.data.updateSpot);
          setOpen(false);
          Snackbar.notify({
            severity: "success",
            message: t("テスト情報を更新しました。"),
          });
        }
        if (result.error) {
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(result.error, t("テストの更新に失敗しました。"));
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
          Sentry.captureException(result.error);
        }
      });
    },
  };
};
