import { useEnabledLeakScore, useEnabledQuestionSkillTagSearch } from "@hireroo/app-helper/feature";
import { Payment } from "@hireroo/app-store/essential/employee";
import { QuestionsStore } from "@hireroo/app-store/page/e/questions";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

import { useGenerateSkillTagsFieldProps } from "./useGenerateSkillTagsFieldProps";

export type GenerateQuestionSearchAreaPropsArgs = {};

export const useGenerateProps = (_args: GenerateQuestionSearchAreaPropsArgs): Widget.QuestionSearchAreaProps => {
  const currentSearchFilter = QuestionsStore.useCurrentSearchFilter();
  const skillTagsFieldProps = useGenerateSkillTagsFieldProps();
  const isAvailableFeature = Payment.useIsAvailableFeature();
  const showingTargets = React.useMemo((): Widget.QuestionSearchAreaProps["questionShowingTarget"] => {
    const targets: Widget.QuestionSearchAreaProps["questionShowingTarget"] = ["CHALLENGE", "QUIZ"];
    if (isAvailableFeature("question.project.read")) {
      targets.push("PROJECT");
    }
    if (isAvailableFeature("question.systemDesign.read")) {
      targets.push("SYSTEM_DESIGN");
    }
    if (isAvailableFeature("question.freepad.read")) {
      targets.push("FREEPAD");
    }
    return targets;
  }, [isAvailableFeature]);
  const enabledQuestionSkillTagSearch = useEnabledQuestionSkillTagSearch();
  const enabledLeakScore = useEnabledLeakScore();
  const defaultValues: Widget.QuestionSearchAreaProps["defaultValues"] = {
    questionVariants: currentSearchFilter.questionVariants.slice(),
    statuses: currentSearchFilter.statuses.slice(),
    difficulties: currentSearchFilter.difficulties.slice(),
    textFilter: currentSearchFilter.textFilter,
    skillTags: currentSearchFilter.skillTags.slice(),
    leakScoreLevels: currentSearchFilter.leakScoreLevels.slice(),
  };
  return {
    disabled: false,
    defaultValues: defaultValues,
    skillTagsField: skillTagsFieldProps,
    enableSkillTagFilter: enabledQuestionSkillTagSearch,
    onChange: fields => {
      QuestionsStore.updateCurrentSearchFilter(fields);
    },
    hasStatus: true,
    questionShowingTarget: showingTargets,
    variant: "STYLED",
    enableLeakScore: enabledLeakScore,
  };
};
