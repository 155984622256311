import type * as Interface from "@hireroo/evaluation-result-interface";
import { useLanguageCode } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import OriginMarkdown from "@hireroo/markdown-v2/react";
import * as React from "react";

export type MarkdownProps = Interface.Markdown["value"];

const Markdown: React.FC<MarkdownProps> = props => {
  const lang = useLanguageCode();
  return (
    <React.Suspense>
      <OriginMarkdown size="middle">{resolveLanguage(props.text, lang, "translateText")}</OriginMarkdown>
    </React.Suspense>
  );
};

Markdown.displayName = "Markdown";

export default Markdown;
