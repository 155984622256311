import type * as Types from "./types";

export const createDefaultState = (initialState?: Partial<Types.State>): Types.State => {
  return {
    fitAddon: null,
    terminal: null,
    socket: null,
    ...initialState,
  };
};
