import { generateKey } from "@hireroo/app-helper/parser";
import { subscribeKey } from "valtio/utils";

import { state } from "./State";
import * as Types from "./types";

export type SubscribeQueryState = (query: Types.Query, shouldRefresh: boolean) => void;

export const subscribeQueryKey = (uniqueKey: Types.UniqueKey, callback: SubscribeQueryState) => {
  const totalScoreStatistics = state.totalScoreStatisticsMap.get(uniqueKey);
  if (!totalScoreStatistics) return;
  return subscribeKey(totalScoreStatistics, "currentQuery", query => {
    const queryKey = generateKey(query);
    const result = totalScoreStatistics.queryResultCacheMap.get(queryKey);
    const hasResultData = result && (result.status === "NOT_ENOUGH" || result.status === "SATISFY");
    if (!hasResultData) {
      totalScoreStatistics.queryResultCacheMap.set(queryKey, {
        rank: null,
        statistics: null,
        status: "LOADING",
      });
    }
    callback(query, !result);
  });
};
