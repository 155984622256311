import { useTranslation } from "@hireroo/i18n";
import AddCircle from "@mui/icons-material/AddCircle";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import update from "immutability-helper";
import * as React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import DraggableSelectedQuestionTableRow, { DraggableSelectedQuestionTableRowProps } from "./parts/DraggableSelectedQuestionTableRow";

export type SelectedQuestionTableProps = {
  onChangeRowIds?: (rowIds: string[]) => void;
  rows: DraggableSelectedQuestionTableRowProps[];
  addButton: Pick<ButtonProps, "onClick">;
};

const SelectedQuestionTable: React.FC<SelectedQuestionTableProps> = props => {
  const [rows, setRows] = React.useState(props.rows);
  const [orderedRows, setOrderedRows] = React.useState<string[] | null>(null);
  const { onChangeRowIds } = props;
  const { t } = useTranslation();
  React.useEffect(() => {
    setRows(props.rows);
  }, [props.rows]);
  const moveItem = React.useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragRecord = rows[dragIndex];
      setRows(prevRows => {
        const newRows = update(prevRows, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRecord],
          ],
        });
        setOrderedRows(newRows.map(row => row.id));
        return newRows;
      });
    },
    [rows],
  );
  const handleEndDrop = React.useCallback(() => {
    if (orderedRows) {
      onChangeRowIds?.(orderedRows);
      setOrderedRows(null);
    }
  }, [orderedRows, onChangeRowIds]);
  return (
    <Box>
      <Box mb={2}>
        <Typography>{`${t("選択した設問")} (${rows.length})`}</Typography>
      </Box>
      {rows.length === 0 && (
        <Box>
          <Typography>{t("選択された設問がありません。")}</Typography>
        </Box>
      )}
      {rows.length > 0 && (
        <Box>
          <TableContainer sx={{ maxHeight: "50vh" }}>
            <Table sx={{ minWidth: 300 }} aria-label="select question table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell width="24px" />
                  <TableCell width="16%">{t("出題順")}</TableCell>
                  <TableCell width="40%">{t("タイトル")}</TableCell>
                  <TableCell width="20%">{t("難易度")}</TableCell>
                  <TableCell width="5%" />
                </TableRow>
              </TableHead>
              <TableBody>
                <DndProvider backend={HTML5Backend}>
                  {rows.map((row, index) => {
                    return (
                      <DraggableSelectedQuestionTableRow
                        key={row.id}
                        {...row}
                        order={index + 1}
                        index={index}
                        moveItem={moveItem}
                        onEndDrop={handleEndDrop}
                      />
                    );
                  })}
                </DndProvider>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      <Box display="flex" justifyContent="end" mt={1}>
        <Button {...props.addButton} startIcon={<AddCircle />}>
          {t("新規の設問を追加する")}
        </Button>
      </Box>
    </Box>
  );
};

SelectedQuestionTable.displayName = "SelectedQuestionTable";

export default SelectedQuestionTable;
