import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Role } from "@hireroo/app-store/essential/employee";
import { ScreeningsStore } from "@hireroo/app-store/page/e/screenings";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/react";
import * as React from "react";

type BulkListActivityBarProps = Widget.ScreeningListProps["searchResultBar"]["bulkListActivityBar"];

type TagOption = BulkListActivityBarProps["tagPopper"]["form"]["multiChoiceField"]["options"][number];
type DefaultValues = BulkListActivityBarProps["tagPopper"]["form"]["defaultValues"];

export type GenerateBulkListActivityBarPropsArgs = {};

export const useGenerateBulkListActivityBarProps = (_args: GenerateBulkListActivityBarPropsArgs): BulkListActivityBarProps => {
  const { t } = useTranslation();
  const client = getGraphqlClient();
  const selectedScreeningIds = ScreeningsStore.useSelectedScreeningIds();
  const selectedScreenings = ScreeningsStore.useSelectedScreenings();
  const tags = ScreeningsStore.useTags();
  const [addedTags, setAddedTags] = React.useState<string[]>([]);
  const matchingRole = Role.useMatchingRole();

  const defaultValues = React.useMemo((): DefaultValues => {
    const tagSet = new Set<string>();
    const newAddedTags = addedTags.map((tag): DefaultValues["tags"][0] => ({
      name: tag,
      status: "CHECKED",
    }));
    const indeterminateTags = selectedScreenings.reduce<DefaultValues["tags"]>((all, screening) => {
      screening.tags.forEach(tag => {
        if (!tagSet.has(tag.name)) {
          all.push({
            name: tag.name,
            status: "INDETERMINATE",
          });
          tagSet.add(tag.name);
        }
      });
      return all;
    }, []);
    return {
      tags: newAddedTags.concat(indeterminateTags),
    };
  }, [addedTags, selectedScreenings]);

  const defaultTags = React.useMemo((): string[] => {
    return defaultValues.tags.map(tag => tag.name);
  }, [defaultValues]);

  const tagOptions = React.useMemo((): TagOption[] => {
    const newAddedTags = addedTags.map(
      (tag): TagOption => ({
        value: tag,
        displayName: tag,
      }),
    );
    const existingTags = tags.map((tag): TagOption => {
      return {
        value: tag.name,
        displayName: tag.name,
        hasIndeterminate: defaultTags.includes(tag.name),
      };
    });
    return newAddedTags.concat(existingTags);
  }, [addedTags, tags, defaultTags]);

  return {
    selectedItemsCount: selectedScreeningIds.length,
    deleteButton: {
      disabled: !matchingRole.ADMIN_OR_MANAGER.matched,
      title: matchingRole.ADMIN_OR_MANAGER.messageOnUnmatched,
    },
    deletesDialog: {
      title: t("選択されたコーディングテストを一括削除"),
      description: t("一度削除すると元に戻すことはできません。（共有されたリンクからもアクセスができなくなりますのでご注意ください。）"),
      items: selectedScreenings.map(screening => {
        return {
          id: screening.screeningId,
          name: screening.name,
        };
      }),
      onSubmit: controller => {
        controller.setLoading(true);
        const targetScreeningIds = selectedScreeningIds.slice();
        client
          .DeleteMultiScreeningsForScreenings({
            input: {
              screeningIds: targetScreeningIds,
            },
          })
          .then(() => {
            Snackbar.notify({
              severity: "success",
              message: t("コーディングテストを削除しました。"),
            });
            ScreeningsStore.deleteScreenings(targetScreeningIds);
            controller.close();
            ScreeningsStore.clearSelectedScreeningIds();
          })
          .catch(error => {
            Sentry.captureException(error);
            const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("コーディングテストの削除に失敗しました。"));
            Snackbar.notify({
              severity: "error",
              message: errorNotification.message,
            });
          })
          .finally(() => {
            controller.setLoading(false);
          });
      },
    },
    tagPopper: {
      form: {
        onSubmit: (fields, controller) => {
          const addTags = fields.tags.filter(tag => tag.status === "INDETERMINATE" || tag.status === "CHECKED").map(t => t.name);
          const deleteTags = fields.tags.filter(tag => tag.status === "UNCHECKED").map(t => t.name);
          controller.setLoading(true);
          client
            .UpdateTagsToScreenings({
              input: {
                screeningIds: selectedScreeningIds.slice(),
                addTagNames: addTags,
                deleteTagNames: deleteTags,
              },
            })
            .then(res => {
              Snackbar.notify({
                severity: "success",
                message: t("コーディングテストにタグを付与しました。"),
              });
              ScreeningsStore.updateScreenings(res.updateTagsToScreenings);
              controller.close();
              /**
               * Refresh tag list to get new created tag
               */
              ScreeningsStore.tagRefresh();
              setAddedTags([]);
              ScreeningsStore.updateRefreshKey();
              ScreeningsStore.clearSelectedScreeningIds();
            })
            .catch(error => {
              Sentry.captureException(error);
              const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                error,
                t("コーディングテストのタグの付与に失敗しました。"),
              );
              Snackbar.notify({
                severity: "error",
                message: errorNotification.message,
              });
            })
            .finally(() => {
              controller.setLoading(false);
            });
        },
        onTagAdded: tagName => {
          setAddedTags(prev => [tagName, ...prev]);
        },
        multiChoiceField: {
          options: tagOptions,
        },
        defaultValues: defaultValues,
      },
    },
  };
};
