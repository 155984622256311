import { useSnapshot } from "valtio";

import { generateUniqueEntityId } from "./api";
import { state } from "./State";
import type * as Types from "./types";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = () => {
  const snapshot = useSnapshotState();
  return !!snapshot.exam;
};

export const useExam = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.exam) {
    throw new Error("need to initialize test");
  }
  return snapshot.exam;
};

export const useSelectedEntityId = () => {
  const snapshot = useSnapshotState();
  return snapshot.selectedEntityId;
};

export const useEntityMap = (): Record<Types.UniqueEntityId, Types.Exam> => {
  const snapshot = useSnapshotState();
  if (!snapshot.exam) {
    throw new Error("need to initialize test");
  }

  return snapshot.exam.entities.reduce((prev, current): Record<Types.UniqueEntityId, Types.Exam> => {
    return { ...prev, [current.id]: current };
  }, {});
};

export const useNextEntityId = (): Types.UniqueEntityId | null => {
  const snapshot = useSnapshotState();
  if (!snapshot.exam) {
    return null;
  }
  const currentIndex = snapshot.exam.entities.findIndex(entity => generateUniqueEntityId(entity) === snapshot.selectedEntityId);
  const nextEntity = snapshot.exam.entities.at(currentIndex + 1);
  if (currentIndex === -1 || !nextEntity) {
    return null;
  }
  return generateUniqueEntityId(nextEntity);
};
export const usePrevEntityId = (): Types.UniqueEntityId | null => {
  const snapshot = useSnapshotState();
  if (!snapshot.exam) {
    return null;
  }
  const currentIndex = snapshot.exam.entities.findIndex(entity => generateUniqueEntityId(entity) === snapshot.selectedEntityId);
  const prevEntity = snapshot.exam.entities.at(currentIndex - 1);
  if (currentIndex === -1 || currentIndex === 0 || !prevEntity) {
    return null;
  }
  return generateUniqueEntityId(prevEntity);
};
