import { state } from "./State";
import type * as Types from "./types";

export const updateCurrentRole = (role: Types.Role) => {
  state.currentRole = role;
};

export const initialize = () => {
  state.initialized = true;
};

export const clear = () => {
  state.initialized = false;
  state.currentRole = "UNKNOWN";
};
