import { useTranslation } from "@hireroo/i18n";
import { ScreeningSearchForm as ScreeningSearchFormValidator } from "@hireroo/validator";

import type { SearchFormProps } from "../../modules/SearchForm/SearchForm";

type FieldProps = SearchFormProps["fields"][0];
export type MultiChoiceFieldProps = Extract<FieldProps, { kind: "MULTI_CHOICE" }>;
export type SingleChoiceFieldProps = Extract<FieldProps, { kind: "SINGLE_CHOICE" }>;
export type GroupMultiChoiceFieldProps = Extract<FieldProps, { kind: "GROUP_MULTI_CHOICE" }>;
export type RangeFieldProps = Extract<FieldProps, { kind: "RANGE" }>;
export type TagFieldProps = Extract<FieldProps, { kind: "TAG" }>;

type FieldKeyName = keyof ScreeningSearchFormValidator.ScreeningSearchQuerySchema;

const FieldName = {
  TEXT_FILTER: "textFilter",
  ACTIVE_STATUS: "activeStatus",
  TAGS: "tags",
} satisfies Record<string, FieldKeyName>;

export const useActiveStatusField = (): SingleChoiceFieldProps => {
  const { t } = useTranslation();
  const options: SingleChoiceFieldProps["options"] = [
    { value: "ACTIVE", displayName: t("受付中") },
    { value: "NOT_ACTIVE", displayName: t("受付停止中") },
  ];
  return {
    kind: "SINGLE_CHOICE",
    name: FieldName.ACTIVE_STATUS,
    title: t("ステータス"),
    options: options,
  };
};

export const useTextFilterField = (): SearchFormProps["textFilter"] => {
  const { t } = useTranslation();
  return {
    name: FieldName.TEXT_FILTER,
    placeholder: t("タイトルを検索"),
  };
};

export const useTagsField = (props: Pick<TagFieldProps, "selectableTags">): TagFieldProps => {
  const { t } = useTranslation();
  return {
    ...props,
    kind: "TAG",
    name: FieldName.TAGS,
    title: t("タグ"),
  };
};
