import { BehavioralEvent } from "@hireroo/app-helper/playback";
import { ChallengePlayback } from "@hireroo/app-store/widget/shared/ChallengePlayback";
import { Widget } from "@hireroo/presentation";
import * as React from "react";

export type MessageCard = Exclude<
  Widget.ReportChallengePlaybackRightSidePanelProps["chatGPTPlaybackRightSidePanel"],
  undefined
>["messageCards"][0];
export type AccessedWebSite = Exclude<
  Exclude<
    Widget.ReportChallengePlaybackRightSidePanelProps["webSearchPlaybackRightSidePanel"],
    undefined
  >["searchResultsStack"]["accessedWebSite"],
  undefined
>;

export type BehavioralEventMatrix = BehavioralEvent[][];

export type Mode = Widget.ReportChallengePlaybackRightSidePanelProps["mode"];

type InternalState = {
  messageCards: MessageCard[];
  query: string;
  accessedWebSite: AccessedWebSite | null;
  mode: Mode;
};

type GenerateStatesArgs = {
  sliderIndex: number;
  behavioralEventMatrix: BehavioralEventMatrix;
};

const getInitialMode = (behavioralEventMatrix: BehavioralEventMatrix): InternalState["mode"] => {
  const initialBehavioralEvents = behavioralEventMatrix.find(behavioralEvents => behavioralEvents.length > 0);
  if (!initialBehavioralEvents) {
    return "WEB_SEARCH";
  }

  return initialBehavioralEvents.at(0)?.kind === "CHATGPT_REQUEST" ? "CHAT_GPT" : "WEB_SEARCH";
};

const generateStates = (args: GenerateStatesArgs) => {
  const { sliderIndex, behavioralEventMatrix } = args;
  const states: InternalState = {
    messageCards: [],
    query: "",
    accessedWebSite: null,
    mode: getInitialMode(behavioralEventMatrix),
  };

  const updateStates = (behavioralEvent: BehavioralEvent) => {
    switch (behavioralEvent.kind) {
      case "CHATGPT_REQUEST": {
        states.messageCards.push({
          avatar: {
            role: "USER",
          },
          message: behavioralEvent.prompt,
        });
        states.mode = "CHAT_GPT";
        return;
      }
      case "CHATGPT_RESPOND": {
        states.messageCards.push({
          avatar: {
            role: "ASSISTANT",
          },
          message: behavioralEvent.message,
        });
        states.mode = "CHAT_GPT";
        return;
      }
      case "CHATGPT_RESET": {
        states.messageCards = [];
        states.mode = "CHAT_GPT";
        return;
      }
      case "WEB_SITE_SEARCH": {
        states.query = behavioralEvent.query;
        states.accessedWebSite = null;
        states.mode = "WEB_SEARCH";
        return;
      }
      case "EXTERNAL_WEB_SITE_ACCESS": {
        states.accessedWebSite = {
          key: `web-access-${behavioralEvent.ts}`,
          title: behavioralEvent.title,
          url: behavioralEvent.url,
          description: behavioralEvent.description,
        };
        states.mode = "WEB_SEARCH";
        return;
      }
      default:
        throw new Error(`invalid kind ${behavioralEvent satisfies never}`);
    }
  };

  for (let index = 0; index <= sliderIndex; index++) {
    const behavioralEvents = behavioralEventMatrix.at(index);
    if (behavioralEvents) {
      behavioralEvents.forEach(event => {
        updateStates(event);
      });
    }
  }

  return states;
};

export type BehavioralStatesArgs = {
  behavioralEventMatrix: BehavioralEventMatrix;
  currentSliderIndex: number;
};

type GPTModel = Widget.ReportChallengePlaybackRightSidePanelProps["gptModel"];

type BehavioralStates = {
  messageCards: MessageCard[];
  query: string;
  accessedWebSite: AccessedWebSite | null;
  restoreBehavioralEvent: (sliderIndex: number) => void;
  applyBehavioralEvent: (behavioralEvent: BehavioralEvent) => void;
  gptModel: GPTModel;
};

export const useBehavioralStates = (args: BehavioralStatesArgs): BehavioralStates => {
  const { behavioralEventMatrix, currentSliderIndex } = args;
  const initialStates = React.useMemo(() => {
    return generateStates({
      sliderIndex: currentSliderIndex,
      behavioralEventMatrix,
    });
  }, [currentSliderIndex, behavioralEventMatrix]);
  const [messageCards, setMessageCards] = React.useState<MessageCard[]>(initialStates.messageCards);
  const [query, setQuery] = React.useState(initialStates.query);
  const [accessedWebSite, setAccessedWebSite] = React.useState<AccessedWebSite | null>(initialStates.accessedWebSite);
  const updateRightSidePanelMode = ChallengePlayback.updateRightSidePanelMode;
  const gptModel: GPTModel = React.useMemo(() => {
    for (const behavioralEvents of behavioralEventMatrix) {
      for (const behavioralEvent of behavioralEvents) {
        if (behavioralEvent.kind === "CHATGPT_REQUEST") {
          switch (behavioralEvent.gptModel) {
            case "GPT_3_5":
              return "GPT-3.5";
            case "GPT_4o":
              return "GPT-4o";
            default:
              throw Error(`invalid behavioral event ${behavioralEvent}`);
          }
        }
      }
    }
    // when behavioral event does not exist, we show gpt model "GPT-4o";
    return "GPT-4o";
  }, [behavioralEventMatrix]);

  const restoreBehavioralEvent = React.useCallback(
    (sliderIndex: number) => {
      const newStates = generateStates({
        sliderIndex,
        behavioralEventMatrix,
      });
      setMessageCards(newStates.messageCards);
      setAccessedWebSite(newStates.accessedWebSite);
      setQuery(newStates.query);
      updateRightSidePanelMode(newStates.mode);
    },
    [behavioralEventMatrix, updateRightSidePanelMode],
  );

  const applyBehavioralEvent = React.useCallback(
    (behavioralEvent: BehavioralEvent) => {
      switch (behavioralEvent.kind) {
        case "CHATGPT_REQUEST": {
          setMessageCards(current => [
            ...current,
            {
              avatar: {
                role: "USER",
              },
              message: behavioralEvent.prompt,
            },
          ]);
          updateRightSidePanelMode("CHAT_GPT");
          return;
        }
        case "CHATGPT_RESPOND": {
          setMessageCards(current => [
            ...current,
            {
              avatar: {
                role: "ASSISTANT",
              },
              message: behavioralEvent.message,
            },
          ]);
          updateRightSidePanelMode("CHAT_GPT");
          return;
        }
        case "CHATGPT_RESET": {
          setMessageCards([]);
          updateRightSidePanelMode("CHAT_GPT");
          return;
        }
        case "WEB_SITE_SEARCH": {
          setQuery(behavioralEvent.query);
          setAccessedWebSite(null);
          updateRightSidePanelMode("WEB_SEARCH");
          return;
        }
        case "EXTERNAL_WEB_SITE_ACCESS": {
          setAccessedWebSite({
            key: `web-access-${behavioralEvent.ts}`,
            title: behavioralEvent.title,
            url: behavioralEvent.url,
            description: behavioralEvent.description,
          });
          updateRightSidePanelMode("WEB_SEARCH");
          return;
        }
        default:
          throw new Error(`invalid kind ${behavioralEvent satisfies never}`);
      }
    },
    [updateRightSidePanelMode],
  );

  React.useEffect(() => {
    updateRightSidePanelMode(initialStates.mode);
  }, [initialStates, updateRightSidePanelMode]);

  return {
    messageCards,
    query,
    accessedWebSite,
    restoreBehavioralEvent,
    applyBehavioralEvent,
    gptModel,
  };
};
