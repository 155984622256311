import { useTranslation } from "@hireroo/i18n";
import Markdown from "@hireroo/markdown-v2/react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import RateChip from "../RateChip/RateChip";

export type QuestionHeaderProps = {
  index: number;
  title: string;
  description: string;
  correctIntegerRate?: number;
};

const QuestionHeader: React.FC<React.PropsWithChildren<QuestionHeaderProps>> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Box>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          <Typography fontSize={20} color={theme.palette["Secondary/Shades"].p50} mr={2}>
            {props.index}
          </Typography>
          <Typography color="textSecondary">{props.title}</Typography>
        </Stack>
        {!!props.correctIntegerRate && <RateChip integerValue={props.correctIntegerRate} title={t("平均スコア")} />}
      </Stack>
      <Box ml={3} mb={2} mt={2}>
        <React.Suspense>
          <Markdown size="middle" children={props.description} />
        </React.Suspense>
      </Box>
      <Box ml={3}>{props.children}</Box>
    </Box>
  );
};

QuestionHeader.displayName = "QuestionHeader";

export default QuestionHeader;
