import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateFilledVariablesFieldPropsArgs, useGenerateProps } from "./useGenerateProps";

export type FilledVariablesFieldContainerProps = GenerateFilledVariablesFieldPropsArgs;

const FilledVariablesFieldContainer: React.FC<FilledVariablesFieldContainerProps> = props => {
  const filledVariablesFieldProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.FilledVariablesField {...filledVariablesFieldProps} />
    </ErrorBoundary>
  );
};

FilledVariablesFieldContainer.displayName = "FilledVariablesFieldContainer";

export default withErrorBoundary(FilledVariablesFieldContainer, {});
