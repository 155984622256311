import { useTranslation } from "@hireroo/i18n";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Popper, { PopperProps } from "@mui/material/Popper";
import { useTheme } from "@mui/material/styles";
import * as React from "react";

import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "../../../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";
import TagsApplyForm, { TagsApplyFormProps } from "./parts/TagsApplyForm/TagsApplyForm";

export type TagBulkApplyPopperProps = {
  form: Omit<TagsApplyFormProps, "onClose">;
};

const TagBulkApplyPopper: React.FC<TagBulkApplyPopperProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const tagIconButton: IconButtonWithTooltipProps = {
    children: <LabelOutlinedIcon fontSize="small" />,
    onClick: event => {
      setAnchorEl(event.currentTarget);
    },
    title: t("タグを一括適用"),
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const popperProps: PopperProps = {
    sx: {
      zIndex: theme.zIndex.modal,
    },
    open: Boolean(anchorEl),
    anchorEl: anchorEl,
    disablePortal: false,
    placement: "bottom-start",
  };

  return (
    <React.Fragment>
      <IconButtonWithTooltip {...tagIconButton} />
      <Popper {...popperProps}>
        <ClickAwayListener onClickAway={handleClose}>
          <Paper>
            <TagsApplyForm key={props.form.defaultValues.tags.join(",")} {...props.form} onClose={() => setAnchorEl(null)} />
          </Paper>
        </ClickAwayListener>
      </Popper>
    </React.Fragment>
  );
};

TagBulkApplyPopper.displayName = "TagBulkApplyPopper";

export default TagBulkApplyPopper;
