import { SupportLanguage, useTranslation } from "@hireroo/i18n";
import * as z from "zod";

export const useUserSettingsFormSchema = () => {
  const { t } = useTranslation();
  return z.object({
    displayName: z
      .string()
      .min(1, { message: t("表示名は1文字以上で入力してください。") })
      .max(100, { message: t("表示名は100文字以内で入力してください。") })
      .refine(value => value.trim().length > 0, { message: t("表示名は1文字以上で入力してください。") }),
    email: z
      .string({
        required_error: t("メールアドレスは必須項目です。"),
      })
      .regex(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, { message: t("メールアドレスが有効な形式ではありません。") }),
    language: z.enum([SupportLanguage.EN, SupportLanguage.JA]).default(SupportLanguage.JA),
  });
};

export type UserSettingsFormSchema = z.infer<ReturnType<typeof useUserSettingsFormSchema>>;
