import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import NativeSelect, { NativeSelectProps } from "@mui/material/NativeSelect";
import { styled } from "@mui/material/styles";
import * as React from "react";

export const FONT_FAMILY_SET = [
  "-apple-system",
  "BlinkMacSystemFont",
  "Segoe UI",
  "Roboto",
  "Helvetica Neue",
  "Arial",
  "sans-serif",
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
];

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  borderRadius: 4,
  position: "relative",
  backgroundColor: theme.palette["Other"]["FilledInputBG"],
  fontSize: "0.9rem",
  padding: "10px 0px 10px 12px",
  transition: theme.transitions.create(["border-color", "box-shadow"]),
  // Use the system font instead of the default Roboto font.
  fontFamily: FONT_FAMILY_SET.join(","),
  "&:focus": {
    borderRadius: 4,
    borderColor: "#80bdff",
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
  },
}));

type Option = {
  value: string;
  displayName: string;
};

export type NativeSelectorProps = {
  disabled?: boolean;
  onChange: NativeSelectProps["onChange"];
  options: Option[];
  value: string;
};

const NativeSelector: React.FC<NativeSelectorProps> = props => {
  return (
    <Box minWidth={100} alignItems="center" display="flex" justifyContent="center">
      <FormControl>
        <NativeSelect
          disabled={props.disabled}
          value={props.value}
          onChange={event => {
            props.onChange?.(event);
          }}
          input={<BootstrapInput />}
          sx={{ height: "28px" }}
        >
          {props.options.map(option => (
            <option key={option.value} value={option.value}>
              {option.displayName}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
    </Box>
  );
};

NativeSelector.displayName = "NativeSelector";

export default NativeSelector;
