import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type LiveCodingVideoChatGalleryLayoutContainerProps = {};

const LiveCodingVideoChatGalleryLayoutContainer: React.FC<LiveCodingVideoChatGalleryLayoutContainerProps> = props => {
  const generatedProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.LiveCodingGalleryLayout {...generatedProps} />
    </ErrorBoundary>
  );
};

LiveCodingVideoChatGalleryLayoutContainer.displayName = "RemoteInterviewEvaluationContainer";

export default withErrorBoundary(LiveCodingVideoChatGalleryLayoutContainer, {});
