import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";
import * as React from "react";

import InputControlTextField, { InputControlTextFieldProps } from "../../../../../../../../../primitive/InputControl/InputControlTextField";
import SwitchControl from "../../../../../../../../../primitive/InputControl/SwitchControl";
import { useAlgorithmTestCasesFormContext } from "../../../../../../../Context";
import AlgorithmInputFields from "../AlgorithmInputFields/AlgorithmInputFields";

export type TestCaseInputContentProps = {};

const TestCaseInputContent: React.FC<TestCaseInputContentProps> = _props => {
  const { t } = useTranslation();
  const { contextProps } = useAlgorithmTestCasesFormContext();

  const labelTextField: InputControlTextFieldProps = {
    label: t("ラベル"),
    placeholder: "N=3000",
    type: "text",
    color: "primary",
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
    variant: "outlined",
    required: true,
  };

  return (
    <Box>
      <Box display="flex" flexDirection="column" gap={4}>
        <Box display="flex" gap={4} justifyContent="space-between" width="100%">
          <FormGroup>
            <Box display="flex" alignItems="center">
              <Typography width="300px">{t("テスト中は表示しない")}</Typography>
              <SwitchControl name={`performance.is_hidden`} />
            </Box>
          </FormGroup>
        </Box>
        <Box display="flex" gap={4} justifyContent="space-between" width="100%">
          <Box width="23vw">
            <InputControlTextField name={`performance.label`} {...labelTextField} />
          </Box>
        </Box>

        <Box mt={1}>
          {contextProps.signatureProps.variant === "ALGORITHM" && <AlgorithmInputFields signature={contextProps.signatureProps.signature} />}
          {/*TODO: implement database*/}
          {/*{contextProps.signatureProps.variant === "DATABASE" && (*/}
          {/*  <DatabaseInputFields signature={contextProps.signatureProps.signature} index={props.index} />*/}
          {/*)}*/}
        </Box>
      </Box>
    </Box>
  );
};

TestCaseInputContent.displayName = "TestCaseInputContent";

export default TestCaseInputContent;
