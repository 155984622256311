import { useTranslation } from "@hireroo/i18n";
import { TestSearchForm as TestSearchFormValidator } from "@hireroo/validator";

import type { SearchFormProps } from "../../../modules/SearchForm/SearchForm";

type FieldProps = SearchFormProps["fields"][0];
export type MultiChoiceFieldProps = Extract<
  FieldProps,
  {
    kind: "MULTI_CHOICE";
  }
>;
export type SingleChoiceFieldProps = Extract<
  FieldProps,
  {
    kind: "SINGLE_CHOICE";
  }
>;
export type GroupMultiChoiceFieldProps = Extract<
  FieldProps,
  {
    kind: "GROUP_MULTI_CHOICE";
  }
>;
export type RangeFieldProps = Extract<
  FieldProps,
  {
    kind: "RANGE";
  }
>;
export type TagFieldProps = Extract<
  FieldProps,
  {
    kind: "TAG";
  }
>;

type FieldKeyName = keyof TestSearchFormValidator.TestSearchQuerySchema;

const FieldName = {
  TEXT_FILTER: "textFilter",
  CREATED_BY: "createdBy",
  SCREENING_IDS: "screeningIds",
  TAGS: "tags",
  SCORE: "score",
  QUESTION_VARIANTS: "questionVariants",
  STATUSES: "statuses",
  RESULT: "result",
  RANK: "rank",
  SUSPICIOUS_DEGREES: "suspiciousDegrees",
} satisfies Record<string, FieldKeyName>;

type ResultOption = {
  displayName: string;
  value: Exclude<TestSearchFormValidator.TestSearchQuerySchema["result"], null>;
};

export const useResultsField = (): SingleChoiceFieldProps => {
  const { t } = useTranslation();
  const options: ResultOption[] = [
    { value: "PASSED", displayName: t("合格") },
    { value: "NOT_PASSED", displayName: t("不合格") },
  ];
  return {
    kind: "SINGLE_CHOICE",
    title: t("結果"),
    name: FieldName.RESULT,
    options: options,
  };
};

type RankOption = {
  displayName: string;
  value: TestSearchFormValidator.TestSearchQuerySchema["rank"][0];
};

export const useRankField = (): MultiChoiceFieldProps => {
  const { t } = useTranslation();
  const options: RankOption[] = [
    { value: "S", displayName: `${t("ランク")} S` },
    { value: "A", displayName: `${t("ランク")} A` },
    { value: "B", displayName: `${t("ランク")} B` },
    { value: "C", displayName: `${t("ランク")} C` },
    { value: "D", displayName: `${t("ランク")} D` },
  ];
  return {
    kind: "MULTI_CHOICE",
    title: t("ランク"),
    name: FieldName.RANK,
    options: options,
  };
};

type QuestionTypeOption = {
  displayName: string;
  value: TestSearchFormValidator.TestSearchQuerySchema["questionVariants"][0];
  children?: QuestionTypeOption[];
};

/**
 * TODO @himenon Moves when a Question List is created.
 */
export const useQuestionTypesField = (): GroupMultiChoiceFieldProps => {
  const { t } = useTranslation();
  const options: QuestionTypeOption[] = [
    {
      value: "codingtest",
      displayName: t("コーディング形式"),
      children: [
        { value: "algorithm", displayName: t("アルゴリズム") },
        { value: "database", displayName: t("データベース") },
      ],
    },
    {
      value: "project",
      displayName: t("実践形式"),
      children: [
        { value: "frontend", displayName: t("フロントエンド") },
        { value: "backend", displayName: t("バックエンド") },
        { value: "dataScience", displayName: t("データサイエンス") },
        { value: "other", displayName: t("他") },
      ],
    },
    { value: "multichoice", displayName: t("クイズ形式") },
    { value: "systemdesign", displayName: t("システムデザイン") },
  ];
  return {
    name: FieldName.QUESTION_VARIANTS,
    kind: "GROUP_MULTI_CHOICE",
    title: t("問題形式"),
    options: options,
  };
};

type StatusesOption = {
  displayName: string;
  value: TestSearchFormValidator.TestSearchQuerySchema["statuses"][0];
};

export const useStatusesField = (): MultiChoiceFieldProps => {
  const { t } = useTranslation();
  const options: StatusesOption[] = [
    { value: "CREATED", displayName: t("作成済み") },
    { value: "STARTED", displayName: t("実施中") },
    { value: "COMPLETED", displayName: t("実施済み") },
    { value: "FINALIZED", displayName: t("採点済み") },
    { value: "EVALUATED", displayName: t("評価済み") },
    { value: "EXPIRED", displayName: t("期限切れ") },
  ];
  return {
    kind: "MULTI_CHOICE",
    title: t("ステータス"),
    name: FieldName.STATUSES,
    options: options,
  };
};

export const useScreeningField = (props: Pick<MultiChoiceFieldProps, "options">): MultiChoiceFieldProps => {
  const { t } = useTranslation();
  return {
    ...props,
    kind: "MULTI_CHOICE",
    name: FieldName.SCREENING_IDS,
    title: t("コーディングテスト"),
  };
};

export const useCreatedByField = (props: Pick<MultiChoiceFieldProps, "options">): MultiChoiceFieldProps => {
  const { t } = useTranslation();
  return {
    ...props,
    kind: "MULTI_CHOICE",
    name: FieldName.CREATED_BY,
    title: t("作成者"),
  };
};

type TextFilterFieldArgs = {
  enabledScreeningFeature: boolean;
};

export const useTextFilterField = (args: TextFilterFieldArgs): SearchFormProps["textFilter"] => {
  const { t } = useTranslation();
  return {
    name: FieldName.TEXT_FILTER,
    placeholder: args.enabledScreeningFeature ? t("候補者名、メールアドレスを検索") : t("タイトルを検索"),
  };
};

export const useScoreField = (): RangeFieldProps => {
  const { t } = useTranslation();
  return {
    kind: "RANGE",
    name: FieldName.SCORE,
    title: t("スコア"),
    label: t("スコア"),
  };
};

export const useTagsField = (props: Pick<TagFieldProps, "selectableTags">): TagFieldProps => {
  const { t } = useTranslation();
  return {
    ...props,
    kind: "TAG",
    name: FieldName.TAGS,
    title: t("タグ"),
  };
};

type SuspiciousDegreeOption = {
  displayName: string;
  value: TestSearchFormValidator.TestSearchQuerySchema["suspiciousDegrees"][0];
};

export const useSuspiciousDegreeField = (): MultiChoiceFieldProps => {
  const { t } = useTranslation();
  const options: SuspiciousDegreeOption[] = [
    { value: "CLEAN", displayName: t("正常") },
    { value: "SUSPICIOUS", displayName: t("不審") },
  ];
  return {
    kind: "MULTI_CHOICE",
    name: FieldName.SUSPICIOUS_DEGREES,
    title: t("不審度"),
    options: options,
  };
};
