import Computer from "@mui/icons-material/Computer";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

type IconKey = "COMPUTER" | "WARNING";

const StyledComputer = styled(Computer)(({ theme }) => ({
  color: theme.palette["Gray/Shades"].p56,
}));

const iconMap: Record<IconKey, React.ReactElement> = {
  COMPUTER: <StyledComputer />,
  WARNING: <WarningAmberOutlinedIcon color="warning" />,
};

export type CustomStepLabelProps = {
  mark: IconKey;
  label: string;
  date?: string;
};

const CustomStepLabel: React.FC<CustomStepLabelProps> = props => {
  return (
    <StepLabel icon={iconMap[props.mark]}>
      <Typography color="textPrimary" textTransform="none" fontWeight={400} fontSize={14} variant="body2">
        {props.date && `${props.date} - `}
        {props.label}
      </Typography>
    </StepLabel>
  );
};

CustomStepLabel.displayName = "CustomStepLabel";

export default CustomStepLabel;
