import firebase from "firebase/compat/app";

const signOutCallbacks: (() => void)[] = [];

export const signOut = async () => {
  return await firebase
    .auth()
    .signOut()
    .then(() => {
      signOutCallbacks.forEach(signOutCallback => {
        signOutCallback();
      });
    });
};

type AuthStateChangedEventArgs = {
  /**
   * Callback to be executed after sign-out is complete
   */
  onAfterSignOut: () => void;
};

export const listenAuthStateChanged = (args: AuthStateChangedEventArgs) => {
  /**
   * It is also possible to use `onAuthStateChanged`, but it is not used here because it is executed when first checking whether the user is signing in or not, so it is also executed outside of the sign-out event.
   */
  signOutCallbacks.push(args.onAfterSignOut);
};
