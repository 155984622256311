import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type FreepadQuestionDetailContainerProps = {
  question: Graphql.FreepadQuestionDetailFragment;
};

const FreepadQuestionDetailForAlgorithmContainer: React.FC<FreepadQuestionDetailContainerProps> = props => {
  const freepadQuestionDetailProps = useGenerateProps(props);
  return <Widget.FreepadQuestionDetail {...freepadQuestionDetailProps} />;
};

FreepadQuestionDetailForAlgorithmContainer.displayName = "FreepadQuestionDetailForAlgorithmContainer";

export default withErrorBoundary(FreepadQuestionDetailForAlgorithmContainer, {});
