import Editor, { Monaco, SwappableCodeEditorProps as EditorProps } from "@hireroo/code-editor/react/SwappableCodeEditor";
import StyledWrapper from "@hireroo/code-editor/react/v2/StyledWrapper";
import { useTranslation } from "@hireroo/i18n";
import { ReadonlyProjectFileTree, ReadonlyProjectFileTreeDiff } from "@hireroo/project/react/v2/widget";
import { withSplitProvider } from "@hireroo/react-split";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import type * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import * as React from "react";

import PlaybackToolbar, { OptionalPlaybackChangeParams, PlaybackToolbarProps } from "../../modules/PlaybackToolbar/PlaybackToolbar";
import Split, { SplitProps } from "../../primitive/Split/Split";
import ProjectDetailPlayback, { ProjectDetailPlaybackProps } from "./parts/ProjectDetailPlayback/ProjectDetailPlayback";

const CODE_EDITOR_HEIGHT = 450;

const StyledSplit = styled(Split)(() => ({
  height: CODE_EDITOR_HEIGHT,
  flexGrow: 1,
}));

const SplitParams = {
  splitId: "ReportProjectPlaybackSplit",
  Contents: {
    FileTree: "FileTree",
    CodeEditor: "CodeEditor",
  },
};

export type ProjectContentsViewerV4Props = {
  monaco?: Monaco | null;
  modelForDefaultScreen?: monaco.editor.ITextModel | null;
  modelForFullScreen?: monaco.editor.ITextModel | null;
  toolbar: Omit<PlaybackToolbarProps, "screenButton" | "screenStatus" | "enableAutoplay" | "onChangePlaybackValue">;
  status: "READY" | "LOADING" | "NO_DATA";
  showPasteRange: boolean;
  onChangeSliderValue: (value: number, isTouchedPlay?: boolean) => void;
  onEditorMount?: EditorProps["onMount"];
} & Pick<ProjectDetailPlaybackProps, "ActivityTimelineLog" | "StatisticsContents" | "appealMessageInReport" | "fileTree" | "refreshEditorKey">;

const ProjectContentsViewerV4: React.FC<ProjectContentsViewerV4Props> = props => {
  const { t } = useTranslation();
  const {
    onEditorMount,
    ActivityTimelineLog,
    StatisticsContents,
    onChangeSliderValue,
    fileTree,
    modelForDefaultScreen,
    refreshEditorKey,
    modelForFullScreen,
    appealMessageInReport,
    showPasteRange,
  } = props;
  const [screenStatus, setScreenStatus] = React.useState<"DEFAULT" | "EXPANDED">("DEFAULT");

  const handleExpandedEditor = React.useCallback(() => {
    setScreenStatus(draft => {
      return draft === "EXPANDED" ? "DEFAULT" : "EXPANDED";
    });
  }, []);

  const handleCloseExpandedEditor = React.useCallback(() => {
    setScreenStatus("DEFAULT");
  }, []);

  const handleChangePlaybackValue: PlaybackToolbarProps["onChangePlaybackValue"] = React.useCallback(
    (value: number, params?: OptionalPlaybackChangeParams) => {
      const isTouchedPlay = (params && params.isTouched && params.playStatus === "PLAY") ?? false;
      onChangeSliderValue(value, isTouchedPlay);
    },
    [onChangeSliderValue],
  );

  const playbackToolbar = React.useMemo((): Omit<PlaybackToolbarProps, "enableAutoplay"> => {
    return {
      ...props.toolbar,
      onChangePlaybackValue: handleChangePlaybackValue,
      slider: {
        ...props.toolbar.slider,
        min: 0,
      },
      screenButton: {
        onClick: handleExpandedEditor,
      },
      screenStatus: screenStatus,
    };
  }, [props.toolbar, screenStatus, handleExpandedEditor, handleChangePlaybackValue]);

  const codeEditor = React.useMemo((): EditorProps | undefined => {
    if (!modelForDefaultScreen) {
      return undefined;
    }
    return {
      height: CODE_EDITOR_HEIGHT,
      options: {
        fontSize: 13,
        model: modelForDefaultScreen,
        readOnly: true,
        minimap: {
          enabled: false,
        },
      },
      keepCurrentModel: true,
    };
  }, [modelForDefaultScreen]);

  const splitItems: SplitProps["items"] = [
    {
      id: SplitParams.Contents.FileTree,
      Content: (
        <Box height={CODE_EDITOR_HEIGHT}>
          {props.fileTree.mode === "NORMAL" && <ReadonlyProjectFileTree {...props.fileTree.props} />}
          {props.fileTree.mode === "WITH_DIFF" && <ReadonlyProjectFileTreeDiff {...props.fileTree.props} />}
        </Box>
      ),
      size: {
        value: 20,
        unit: "%",
      },
      minSize: {
        value: 0,
        unit: "%",
      },
    },
    {
      id: SplitParams.Contents.CodeEditor,
      Content: (
        <Box height="100%" width="100%">
          <StyledWrapper theme={props.showPasteRange ? "RED" : "TRANSPARENT"}>
            {codeEditor && (
              <React.Suspense>
                <Editor key={`${props.refreshEditorKey}`} {...codeEditor} onMount={onEditorMount} theme="monokai" />
              </React.Suspense>
            )}
          </StyledWrapper>
        </Box>
      ),
      size: {
        value: 80,
        unit: "%",
      },
      minSize: {
        value: 20,
        unit: "%",
      },
    },
  ];

  const splitProps: SplitProps = {
    splitId: SplitParams.splitId,
    splitDirection: "VERTICAL",
    items: splitItems,
  };

  const detailPlayback = React.useMemo((): ProjectDetailPlaybackProps | undefined => {
    if (!modelForDefaultScreen) {
      return undefined;
    }

    return {
      model: modelForFullScreen,
      showPasteRange: showPasteRange,
      toolbar: playbackToolbar,
      onClose: handleCloseExpandedEditor,
      ActivityTimelineLog: ActivityTimelineLog,
      StatisticsContents: StatisticsContents.filter(Boolean),
      refreshEditorKey: refreshEditorKey,
      fileTree: fileTree,
      appealMessageInReport: appealMessageInReport,
      onEditorMount: onEditorMount,
    };
  }, [
    ActivityTimelineLog,
    fileTree,
    refreshEditorKey,
    StatisticsContents,
    appealMessageInReport,
    handleCloseExpandedEditor,
    modelForDefaultScreen,
    modelForFullScreen,
    onEditorMount,
    playbackToolbar,
    showPasteRange,
  ]);

  if (props.status === "LOADING") {
    return (
      <Box minHeight={500} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  }

  if (props.status === "NO_DATA") {
    return (
      <Box width={"100%"} height={"100%"} py={2}>
        <Typography variant="body2" color="text.secondary" fontSize={14} textAlign="center">
          {t("提出過程がありませんでした。")}
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Box height="100%">
        <StyledSplit {...splitProps} />
        <PlaybackToolbar {...playbackToolbar} enableAutoplay={screenStatus === "DEFAULT"} key={screenStatus} />
      </Box>
      <Dialog open={screenStatus === "EXPANDED"} onClose={handleCloseExpandedEditor} maxWidth={false} fullScreen>
        {detailPlayback && <ProjectDetailPlayback {...detailPlayback} />}
      </Dialog>
    </Box>
  );
};

ProjectContentsViewerV4.displayName = "ProjectContentViewerV4";

export default withSplitProvider(ProjectContentsViewerV4);
