import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type QuestionPackageTableContainerProps = {};

const QuestionPackageTableContainer: React.FC<QuestionPackageTableContainerProps> = () => {
  const questionPackageTableProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.QuestionPackageTable {...questionPackageTableProps} />
    </ErrorBoundary>
  );
};

QuestionPackageTableContainer.displayName = "QuestionPackageTableContainer";

export default withErrorBoundary(QuestionPackageTableContainer, {});
