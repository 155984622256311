import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type UsagesContainerProps = {};

const UsagesContainer: React.FC<UsagesContainerProps> = () => {
  const usagesProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.Usages {...usagesProps} />
    </ErrorBoundary>
  );
};

UsagesContainer.displayName = "UsagesContainer";

export default withErrorBoundary(UsagesContainer, {});
