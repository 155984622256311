import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import React from "react";

import { LabelVm, NodeElement, OPERATION_TYPE, OperationType, UnionSettingsFields } from "../../../../../features";

export type VmSettingsFormProps = {
  element: NodeElement<LabelVm>;
  updateSettings: (id: string, updates: UnionSettingsFields, operationType: OperationType) => void;
};

export const VmSettingsForm: React.FC<VmSettingsFormProps> = (props: VmSettingsFormProps) => {
  const { t } = useTranslation();

  const handleSwitchAutoScale = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.updateSettings(props.element.id, { autoScale: e.target.checked }, OPERATION_TYPE.do);
  };

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Box mt={2}>
        <Typography>{t("自動スケール")}</Typography>
        <Box mt={1} />
        <FormControlLabel
          control={<Switch checked={props.element.settings.autoScale} onChange={handleSwitchAutoScale} />}
          label={props.element.settings.autoScale ? "ON" : "OFF"}
        />
      </Box>
    </Box>
  );
};

VmSettingsForm.displayName = "VmSettingsForm";

export default VmSettingsForm;
