import type { LiveCoding } from "@hireroo/app-helper/hooks";
import { useFirepadForLiveCoding } from "@hireroo/app-helper/hooks";
import { languageMapForHighlight } from "@hireroo/challenge/definition";
import { useChallengeCodingEditorContext } from "@hireroo/challenge/store";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

import * as PrivateHelper from "./privateHelper";

export type GenerateRemoteInterviewChallengeCodingEditorPropsArgs = {
  defaultValue: string;
  uid: string;
  displayName: string;
  liveCodingId: number;
  collaborativeState: LiveCoding.CollaborativeState;
  /**
   * A flag that allows only certain users to set default values to avoid conflicts during initialization.
   */
  allowSetInitialValue: boolean;
};

type OnMount = Exclude<Widget.RemoteInterviewChallengeCodingEditorProps["onMount"], undefined>;

export const useGenerateProps = (
  args: GenerateRemoteInterviewChallengeCodingEditorPropsArgs,
): Widget.RemoteInterviewChallengeCodingEditorProps => {
  const { collaborativeState, uid, displayName, defaultValue } = args;
  const { action, hooks } = useChallengeCodingEditorContext();
  const language = hooks.useCurrentLanguage();
  const path = PrivateHelper.generateLiveCodingPath({
    liveCodingId: args.liveCodingId,
    sessionId: collaborativeState.selectedSession.toString(),
    language,
  });
  /**
   * Allow default values to be set at the time the component is initialized.
   * After the default value is set, the flag is set to false and therefore managed as an internal state.
   */
  const [allowSetInitialValue, updateAllowSetInitialValue] = React.useState(args.allowSetInitialValue);
  const updateMethod = hooks.useUpdateMethod();
  const [, padAction] = useFirepadForLiveCoding({
    db: "liveCoding",
    key: path,
    userName: displayName,
    readOnly: false,
  });

  React.useEffect(() => {
    return () => {
      action.deactivate();
      updateAllowSetInitialValue(args.allowSetInitialValue);
    };
  }, [action, args.allowSetInitialValue]);

  const handleMount: OnMount = React.useCallback(
    (editor, monaco) => {
      if (updateMethod === "ACTIVE" || updateMethod === "INITIAL" || allowSetInitialValue) {
        padAction.initPad(uid, editor, defaultValue).then(() => {
          /**
           * Once successfully initialized, the flag should then be false
           */
          updateAllowSetInitialValue(false);
        });
      } else {
        padAction.initPad(uid, editor);
      }
      // Following lines fix an issue between different platform syncs.
      // https://github.com/interviewstreet/firepad-x/issues/41
      // https://github.com/FirebaseExtended/firepad/issues/315
      const model = editor.getModel();
      model?.setEOL(monaco.editor.EndOfLineSequence.LF);
      model?.applyEdits([
        {
          range: model?.getFullModelRange(),
          text: model?.getLinesContent().join("\n"),
        },
      ]);
    },
    [allowSetInitialValue, defaultValue, padAction, uid, updateMethod],
  );

  return {
    path: path,
    language: languageMapForHighlight[language],
    saveViewState: false,
    options: {
      readOnly: false,
    },
    onMount: handleMount,
  };
};
