import { useTranslation } from "@hireroo/i18n";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@mui/icons-material/Search";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Popover, { PopoverProps } from "@mui/material/Popover";
import Skeleton from "@mui/material/Skeleton";
import { styled, useTheme } from "@mui/material/styles";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import escapeStringRegexp from "escape-string-regexp";
import * as React from "react";
import { Virtuoso, VirtuosoProps } from "react-virtuoso";

const SEARCH_FIELD_WRAPPER_HEIGHT = 48;
const SEARCH_FIELD_HEIGHT = 32;
const AVATAR_SIZE = 32;
const MIN_ITEM_HEIGHT = 44;

/**
 * Threshold for the number of options to display in the search field
 */
const THRESHOLD_FOR_DISPLAY = 10;

const CONTENT_HEIGHT = MIN_ITEM_HEIGHT * THRESHOLD_FOR_DISPLAY;
const CONTENT_WIDTH = 512;
const CONTENT_PADDING_Y = 16;
const MAX_HEIGHT = CONTENT_HEIGHT;

const CompanyNameTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  wordBreak: "break-word",
  whiteSpace: "normal",
  width: "100%",
  maxHeight: 48,
  overflow: "hidden",
}));

const StyledAvatar = styled(Avatar)(() => ({
  height: AVATAR_SIZE,
  width: AVATAR_SIZE,
  color: "#FFFFFF",
  backgroundColor: "#78909C",
}));

const SearchBox = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  height: SEARCH_FIELD_WRAPPER_HEIGHT,
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.Other.FilledInputBG,
  textTransform: "none",
  overflowY: "hidden",
  ".MuiInputBase-root": {
    height: SEARCH_FIELD_HEIGHT,
    paddingLeft: 4,
    "&.Mui-focused fieldset": {
      borderWidth: 1,
    },
  },
  ".MuiOutlinedInput-input": {
    height: SEARCH_FIELD_HEIGHT,
  },
}));

const StyledButton = styled(Button)(() => ({
  textTransform: "none",
  minWidth: "unset",
}));

const StyledMenu = styled(MenuList)(() => ({
  textTransform: "none",
  overflow: "hidden",
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  height: MIN_ITEM_HEIGHT,
  "&.MuiMenuItem-root": {
    "&:hover ": {
      backgroundColor: theme.palette["Secondary/Shades"].p8,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette["Secondary/Shades"].p16,
    },
  },
}));

type Item = {
  text: string;
  value: string;
  imgUrl?: string;
  onClick?: () => void;
  default?: boolean;
};

type InfiniteScrollableListProps = VirtuosoProps<Item, unknown>;

type Mode = "EXPANDED" | "MINIMIZED";

export type CompanySelectProps = {
  mode: Mode;
  items: Item[];
  button?: Omit<ButtonProps, "onClick">;
  onEndReached: InfiniteScrollableListProps["endReached"];
};

const CompanySelect: React.FC<CompanySelectProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const textFieldRef = React.useRef<HTMLInputElement>(null);
  const [searchText, setSearchText] = React.useState("");
  const canShowMenuList = props.items.length > 1;
  const canShowSearchField = props.items.length >= THRESHOLD_FOR_DISPLAY;
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const buttonProps: ButtonProps = {
    ...props.button,
    endIcon: canShowMenuList && props.mode === "EXPANDED" ? <ArrowDropDownIcon sx={{ color: theme.palette.secondary.light }} /> : null,
    disabled: !canShowMenuList,
    onClick: event => {
      if (canShowMenuList) {
        setAnchorEl(event.currentTarget);
      }
    },
    sx:
      props.mode === "MINIMIZED"
        ? {
            width: "36px",
            height: "36px",
          }
        : {},
    fullWidth: true,
    color: "secondary",
  };

  const defaultOption: Item = React.useMemo(() => {
    return (
      props.items.find(option => !!option.default) ||
      props.items.at(0) || {
        text: "",
        value: "",
      }
    );
  }, [props.items]);

  const [selectedOption, updateOption] = React.useState<Item>(defaultOption);

  const searchTextField: TextFieldProps = {
    size: "small",
    fullWidth: true,
    color: "secondary",
    inputRef: textFieldRef,
    InputProps: {
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
    },
    onChange: event => {
      setSearchText(event.target.value);
    },
    placeholder: t("会社名を検索"),
  };

  const items = React.useMemo((): Item[] => {
    if (searchText === "") {
      return props.items;
    }
    const pattern = new RegExp(escapeStringRegexp(searchText.toLowerCase()));
    return props.items.filter(option => {
      return pattern.test(option.text.toLowerCase());
    });
  }, [searchText, props.items]);

  const contentHeight = Math.min(MIN_ITEM_HEIGHT * items.length, MAX_HEIGHT);
  const overScanSizePixel = MIN_ITEM_HEIGHT * (THRESHOLD_FOR_DISPLAY + 1);

  const virtuosoProps: InfiniteScrollableListProps = {
    data: items,
    style: { height: contentHeight, maxHeight: MAX_HEIGHT, overflow: "scroll" },
    overscan: {
      main: overScanSizePixel,
      reverse: overScanSizePixel,
    },
    increaseViewportBy: {
      top: overScanSizePixel,
      bottom: overScanSizePixel,
    },
    itemContent: (index, item) => {
      return (
        <StyledMenuItem
          key={`menu-${item.text}-${index}`}
          value={item.value}
          selected={selectedOption.value === item.value}
          onClick={() => {
            item.onClick?.();
            updateOption(item);
            setAnchorEl(null);
            setSearchText("");
          }}
        >
          <Box width={AVATAR_SIZE} mr={2}>
            {item.imgUrl ? (
              <StyledAvatar src={item.imgUrl} variant="circular" />
            ) : (
              <StyledAvatar variant="circular">
                <ApartmentOutlinedIcon />
              </StyledAvatar>
            )}
          </Box>
          <Typography color={theme.palette.text.primary}>{item.text}</Typography>
        </StyledMenuItem>
      );
    },
    endReached: props.onEndReached,
  };

  const popoverProps: PopoverProps = {
    open: Boolean(anchorEl),
    anchorEl: anchorEl,
    onClose: () => {
      setAnchorEl(null);
      setSearchText("");
    },
    transformOrigin: {
      vertical: -(buttonRef?.current?.clientHeight ?? 0),
      horizontal: "left",
    },
    PaperProps: {
      elevation: 8,
      sx: {
        borderRadius: 4,
        minWidth: CONTENT_WIDTH,
        maxHeight: CONTENT_HEIGHT + SEARCH_FIELD_WRAPPER_HEIGHT + CONTENT_PADDING_Y,
        overflow: "hidden",
      },
    },
  };

  React.useEffect(() => {
    if (anchorEl || searchText === "") {
      textFieldRef.current?.focus();
    }
  }, [anchorEl, searchText]);

  if (props.items.length === 0) {
    return <Skeleton width="100px" height="36px" />;
  }

  return (
    <Box width="100%">
      <StyledButton {...buttonProps} ref={buttonRef}>
        {selectedOption.imgUrl && (
          <StyledAvatar src={selectedOption.imgUrl} variant="circular" sx={{ marginRight: props.mode === "EXPANDED" ? "16px" : "unset" }} />
        )}
        {!selectedOption.imgUrl && props.mode === "MINIMIZED" && (
          <StyledAvatar>
            <ApartmentOutlinedIcon />
          </StyledAvatar>
        )}
        {props.mode === "EXPANDED" && <CompanyNameTypography>{selectedOption.text}</CompanyNameTypography>}
      </StyledButton>
      <Popover {...popoverProps}>
        {canShowSearchField && (
          <SearchBox>
            <StyledTextField {...searchTextField} />
          </SearchBox>
        )}

        {items.length === 0 && (
          <Box py={1.5} px={2}>
            <Typography color="text.secondary" fontSize={14} textAlign="center">
              {t("検索条件に一致する会社は見つかりませんでした。")}
            </Typography>
          </Box>
        )}
        <StyledMenu sx={{ height: "100%", maxHeight: CONTENT_HEIGHT + CONTENT_PADDING_Y }}>
          <Virtuoso {...virtuosoProps} />
        </StyledMenu>
      </Popover>
    </Box>
  );
};

CompanySelect.displayName = "CompanySelect";

export default CompanySelect;
