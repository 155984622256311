//
// This Validator should aggregate Schema definitions for events used in Playback.
//
// [IMPORTANT]
// Note that Breaking Change may make it impossible to read historical data, as it is directly related to the data stored in Firebase.
//

import * as z from "zod";

import * as BrowserEvent from "./BrowserEvent";

const TimeStamp = z.number();

const Key = z.string().optional();

export const SyncOperation = z.object({
  a: z.string(),
  o: z.union([z.string(), z.number()]).array(),
  t: TimeStamp,
  /**
   * Key for paste Detection
   */
  k: Key,
});

export type SyncOperation = z.infer<typeof SyncOperation>;

export const SelectLanguageAction = z.object({
  s: z.literal("sell"),
  /** language */
  v: z.string(),
  t: TimeStamp,
  k: Key,
});

export type SelectLanguageAction = z.infer<typeof SelectLanguageAction>;

export const UseHintAction = z.object({
  s: z.literal("useh"),
  t: TimeStamp,
  /** Hint Number */
  v: z.number(),
  k: Key,
});

export type UseHintAction = z.infer<typeof UseHintAction>;

export const SubmitQuestion = z.object({
  s: z.literal("subq"),
  t: TimeStamp,
  k: Key,
});

export type SubmitQuestion = z.infer<typeof SubmitQuestion>;

export const RunCode = z.object({
  s: z.literal("runc"),
  /** Snapshot Id */
  v: z.number(),
  t: TimeStamp,
  k: Key,
});

export type RunCode = z.infer<typeof RunCode>;

export const LanguageState = z.union([RunCode, SubmitQuestion]);

export type LanguageState = z.infer<typeof LanguageState>;

export const LanguageStateObject = z.record(LanguageState);

export type LanguageStateObject = z.infer<typeof LanguageStateObject>;

const CutCopyEventValue = z.object({ text: z.string(), position: z.number(), selectionEnd: z.number() }).array();

export const CopyEvent = z.object({
  s: z.literal("copy"),
  /** uid */
  a: z.string(),
  /** clipboard value object */
  v: CutCopyEventValue,
  t: TimeStamp,
  k: Key,
});

export type CopyEvent = z.infer<typeof CopyEvent>;

export const CutEvent = z.object({
  s: z.literal("cut"),
  /** uid */
  a: z.string(),
  /** clipboard value object */
  v: CutCopyEventValue,
  t: TimeStamp,
  k: Key,
});

export type CutEvent = z.infer<typeof CutEvent>;

const PasteEventValue = z.array(z.union([z.string(), z.number()]));

export const PasteEvent = z.object({
  s: z.enum(["paste"]),
  /** uid */
  a: z.string(),
  /** clipboard value object */
  v: PasteEventValue,
  t: TimeStamp,
  k: Key,
});

export type PasteEvent = z.infer<typeof PasteEvent>;

export const QuestionEvent = z.union([
  BrowserEvent.AccessEventPayload,
  BrowserEvent.BlurEventPayload,
  BrowserEvent.FocusEventPayload,
  BrowserEvent.VisibleEventPayload,
  BrowserEvent.HiddenEventPayload,
]);

export type QuestionEvent = z.infer<typeof QuestionEvent>;

export const QuestionEventObject = z.record(QuestionEvent);

export type QuestionEventObject = z.infer<typeof QuestionEventObject>;

export const LanguageEvent = z.union([CopyEvent, CutEvent, PasteEvent]);

export type LanguageEvent = z.infer<typeof LanguageEvent>;

export const LanguageEventObject = z.record(LanguageEvent);

export type LanguageEventObject = z.infer<typeof LanguageEventObject>;

export const QuestionState = z.union([SelectLanguageAction, UseHintAction]);

export type QuestionState = z.infer<typeof QuestionState>;

export const QuestionStateObject = z.record(QuestionState);

export type QuestionStateObject = z.infer<typeof QuestionStateObject>;

export const Revision = z.union([SyncOperation, QuestionState]);

export type Revision = z.infer<typeof Revision>;

export const RevisionObject = z.record(Revision);

export type RevisionObject = z.infer<typeof RevisionObject>;

/**
 * live coding playback event
 */
export const runtime = z.string();
export const RevisionHistoriesRecord = z.record(
  /**
   * runtime or component type
   */
  runtime,
  z.object({
    history: RevisionObject.optional(),
  }),
);
export type RevisionHistoriesRecord = z.infer<typeof RevisionHistoriesRecord>;

export const RevisionHistories = z.record(runtime, Revision.array());
export type RevisionHistories = z.infer<typeof RevisionHistories>;
