import { useTranslation } from "@hireroo/i18n";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import MuiListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import * as React from "react";

import IconButton, { IconButtonProps } from "../../../../primitive/Button/IconButton/IconButton";

const EllipsisText = styled(Typography)({
  maxWidth: "100%",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  overflow: "hidden",
  textOverflow: "ellipsis",
});

type Batch = {
  id: string;
  title: string;
  description: string;
};

export type BatchListItemProps = {
  enableScreeningFeature?: boolean;
  batch: Batch;
  editButton: IconButtonProps;
  onClick?: () => void;
};

const BatchListItem: React.FC<BatchListItemProps> = props => {
  const { t } = useTranslation();
  const handleClick = () => {
    props.onClick?.();
  };
  return (
    <ListItem
      divider
      disablePadding
      secondaryAction={
        <Box flexDirection="row" display="flex">
          <IconButton
            {...props.editButton}
            title={props.enableScreeningFeature ? t("コーディングテストを編集する") : t("バッチを編集する")}
            size="small"
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Box>
      }
    >
      <MuiListItemButton onClick={handleClick}>
        <ListItemText
          primary={props.batch.title}
          secondary={<EllipsisText variant="inherit">{props.batch.description}</EllipsisText>}
          secondaryTypographyProps={{ component: "span" }}
        />
      </MuiListItemButton>
    </ListItem>
  );
};

BatchListItem.displayName = "BatchListItem";

export default BatchListItem;
