import * as Graphql from "@hireroo/graphql/client/urql";
import { SupportLanguage, SupportLanguageValue } from "@hireroo/i18n";

export const languageMap: Record<SupportLanguage[keyof SupportLanguage], Graphql.Language> = {
  [SupportLanguage.JA]: Graphql.Language.Ja,
  [SupportLanguage.EN]: Graphql.Language.En,
};

export const LanguageMap: Record<Graphql.Language, SupportLanguageValue> = {
  [Graphql.Language.Ja]: SupportLanguage.JA,
  [Graphql.Language.En]: SupportLanguage.EN,
  [Graphql.Language.Unknown]: SupportLanguage.JA,
};

export const translationLanguageMap: Record<SupportLanguageValue, Graphql.TranslatableLanguage> = {
  ja: Graphql.TranslatableLanguage.Ja,
  en: Graphql.TranslatableLanguage.En,
};
