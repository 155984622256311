import { useSnapshot } from "valtio";

import { state } from "./State";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useEmployees = () => {
  const snapshot = useSnapshotState();
  return snapshot.employees;
};
