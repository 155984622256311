import { SupportLanguage } from "@hireroo/i18n";
import * as z from "zod";

export const useSlackSettingsFormSchema = () => {
  return z.object({
    spotSettings: z.object({
      subscribeSpotCreated: z.boolean(),
      subscribeSpotStarted: z.boolean(),
      subscribeSpotCompleted: z.boolean(),
      subscribeSpotReviewed: z.boolean(),
      subscribeSpotFinalized: z.boolean(),
      subscribeSpotEvaluated: z.boolean(),
      subscribeSpotRecreated: z.boolean(),
      subscribeSpotDeleted: z.boolean(),
      subscribeSpotExpired: z.boolean(),
    }),
    remoteSettings: z
      .object({
        subscribeRemoteCreated: z.boolean(),
        subscribeRemoteAwaiting: z.boolean(),
        subscribeRemoteStarted: z.boolean(),
        subscribeRemoteCompleted: z.boolean(),
        subscribeRemoteDeleted: z.boolean(),
        subscribeRemoteReviewed: z.boolean(),
        subscribeRemoteEvaluated: z.boolean(),
      })
      .optional(),
    language: z.nativeEnum(SupportLanguage),
  });
};

export type SlackSettingsFormSchema = z.infer<ReturnType<typeof useSlackSettingsFormSchema>>;
