import { IntegrationsSettings } from "@hireroo/app-store/widget/e/IntegrationsSettings";
import type { Widget } from "@hireroo/presentation";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as React from "react";

export type GenerateIntegrationBatchMenuListPropsArgs = {
  fetching: boolean;
  onClick: () => void;
};

type ListStatus = Widget.IntegrationBatchMenuListProps["status"];

export const useGenerateProps = (args: GenerateIntegrationBatchMenuListPropsArgs): Widget.IntegrationBatchMenuListProps => {
  const navigate = useTransitionNavigate();
  const pager = IntegrationsSettings.useScreeningPagination();
  const status: ListStatus = args.fetching ? "LOADING" : "FETCHED";

  const nonIntegratedScreenings = IntegrationsSettings.useNonIntegratedScreenings();
  const batchListItems = React.useMemo((): Widget.IntegrationBatchMenuListProps["items"] => {
    return nonIntegratedScreenings.map(screening => {
      return {
        batch: {
          id: screening.screeningId,
          title: screening.name,
          description: screening.description,
        },
        onClick: () => {
          IntegrationsSettings.updateRegisterSonar({ selectedScreening: screening });
          args.onClick();
        },
        editButton: {
          onClick: () => {
            navigate("/e/screenings/:id/update", {
              pathParams: {
                id: screening.screeningId,
              },
            });
          },
        },
      };
    });
  }, [args, navigate, nonIntegratedScreenings]);

  return {
    enableScreeningFeature: true,
    items: batchListItems,
    createButton: {
      onClick: () => {
        navigate("/e/screenings/create");
      },
    },
    hasNext: Boolean(pager?.cursor),
    status: status,
    onEndReached: () => {
      IntegrationsSettings.goNextBatchPage();
    },
  };
};
