import { useExamInterval } from "@hireroo/app-definition/assessment";
import { AssessmentsIdDetailStore } from "@hireroo/app-store/page/e/assessments_id_detail";
import * as Time from "@hireroo/formatter/time";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import ExamTransitionVisualizerFetchContainer from "../../../../../../widget/v2/shared/ExamTransitionVisualizer/FetchContainer";

export const useGenerateSummaryProps = (): Widget.AssessmentDetailForEmployeeProps["summary"] => {
  const { t } = useTranslation();
  const assessment = AssessmentsIdDetailStore.useAssessment();
  const selectedExamId = AssessmentsIdDetailStore.useSelectedExamId();
  const intervalMap = useExamInterval();
  const lang = useLanguageCode();
  const [, setParams] = useSearchParams();

  return {
    user: {
      displayName: assessment.talent.displayName,
      photoUrl: assessment.talent.photoUrl,
      mail: assessment.talent.email,
    },
    detail: {
      createdDate: assessment.createdAtSeconds ? Time.unixTimeSecondsToFormat(assessment.createdAtSeconds) : "",
      nextStartScheduledDate:
        assessment.status === "RUNNING" && assessment.nextScheduleAtSeconds
          ? Time.unixTimeSecondsToFormat(assessment.nextScheduleAtSeconds)
          : undefined,
      duration: intervalMap[assessment.examInterval],
      examNumber: assessment.rootExamIds.length,
      questionTypes: `${t("問題指定")}, ${assessment.questions.length}${t("問")}, ${
        assessment.timeLimitSeconds ? Time.formatMinutes(assessment.timeLimitSeconds / 60, lang) : ""
      }`,
    },
    Graph: assessment.rootExamIds.length > 0 && (
      <ExamTransitionVisualizerFetchContainer
        assessmentId={assessment.assessmentId}
        selectedExamId={selectedExamId}
        onChangeExamId={examId => {
          AssessmentsIdDetailStore.updateSelectedExamId(examId);
          setParams(prev => {
            return {
              ...Object.fromEntries(prev),
              examId: examId,
            };
          });
        }}
      />
    ),
  };
};
