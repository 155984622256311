import { EmployeeSideBarContent } from "@hireroo/app-store/widget/e/EmployeeSideBarContent";
import type { Pages } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import React from "react";

import EmployeeHeaderRightContentContainer from "../../../../widget/v2/e/EmployeeHeaderRightContent/Container";
import EmployeeSideBarContentContainer from "../../../../widget/v2/e/EmployeeSideBarContent/Container";
import FooterContainer from "../../../../widget/v2/e/Footer/Container";
import { useNotificationBanner } from "../../../../widget/v2/e/NotificationBanner/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import TestReviewFetchContainer from "./widget/TestReview/FetchContainer";

export type GenerateEmployeeTestReviewPropsArgs = {};

export const useGenerateProps = (_args: GenerateEmployeeTestReviewPropsArgs): Pages.EmployeeTestReviewProps => {
  const navigate = useTransitionNavigate();
  const NotificationBannerContainer = useNotificationBanner();

  React.useEffect(() => {
    EmployeeSideBarContent.setOpenSidebar(false);
  }, []);

  return {
    layout: {
      openSidebar: EmployeeSideBarContent.useOpenSidebar(),
      onChangeOpenSidebar: open => {
        EmployeeSideBarContent.setOpenSidebar(open);
      },
      NotificationBanner: NotificationBannerContainer,
      HeaderRightContent: <EmployeeHeaderRightContentContainer />,
      SideBarContent: <EmployeeSideBarContentContainer />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      sideBarHeader: {
        logo: {
          href: generatePath("/e/assessments"),
          onClick: () => {
            navigate("/e/assessments");
          },
        },
      },
    },
    children: <TestReviewFetchContainer />,
  };
};
