import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type RemoteInterviewPlaybackContainerProps = {};

const RemoteInterviewPlaybackContainer: React.FC<RemoteInterviewPlaybackContainerProps> = () => {
  const remoteInterviewPlaybackProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.RemoteInterviewPlayback {...remoteInterviewPlaybackProps} />
    </ErrorBoundary>
  );
};

RemoteInterviewPlaybackContainer.displayName = "RemoteInterviewPlaybackContainer";

export default withErrorBoundary(RemoteInterviewPlaybackContainer, {});
