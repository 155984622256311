import { secondsToTimeObject, TimeObject } from "@hireroo/formatter/time";
import * as React from "react";
import { useSnapshot } from "valtio";

import { state } from "./State";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = () => {
  const snapshot = useSnapshotState();
  return typeof snapshot.remainTimeSeconds === "number";
};

export const useCountDownTimer = (): TimeObject => {
  const snapshot = useSnapshotState();
  const { remainTimeSeconds, timeLimitSeconds } = snapshot;
  /**
   * Cache the time when the component was mounted
   */
  const [baseTimeMilliseconds] = React.useState(new Date().getTime());
  const [currentTimeMilliseconds, setCurrentTimeMilliseconds] = React.useState(baseTimeMilliseconds);
  const diffInSeconds = React.useMemo(() => {
    const value = (baseTimeMilliseconds + (remainTimeSeconds ?? 0) * 1000 - currentTimeMilliseconds) / 1000;
    return Math.floor(value);
  }, [baseTimeMilliseconds, remainTimeSeconds, currentTimeMilliseconds]);

  React.useEffect(() => {
    const timer = window.setInterval(() => {
      const now = new Date().getTime();
      setCurrentTimeMilliseconds(now);
    }, 1000);
    return () => {
      window.clearInterval(timer);
    };
  }, []);

  React.useEffect(() => {
    if (typeof remainTimeSeconds === "number" && remainTimeSeconds > 0) {
      state.percentageOfTimeRemaining = Math.floor((diffInSeconds / timeLimitSeconds) * 100);
    } else {
      state.percentageOfTimeRemaining = 0;
    }
  }, [diffInSeconds, timeLimitSeconds, remainTimeSeconds]);

  return secondsToTimeObject(diffInSeconds, "DAY");
};

export const usePercentageOfTimeRemaining = () => {
  const snapshot = useSnapshotState();
  const { percentageOfTimeRemaining } = snapshot;
  return percentageOfTimeRemaining;
};
