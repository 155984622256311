import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";

import SortField, { SortFieldProps } from "../../modules/SortField/SortField";
import ExamTable, { ExamTableProps } from "../../ms-components/Assessment/ExamTable/ExamTable";
import AssessmentMeta, { AssessmentMetaProps } from "./parts/AssessmentMeta/AssessmentMeta";
import AssessmentNotificationBanner, {
  AssessmentNotificationBannerProps,
} from "./parts/AssessmentNotificationBanner/AssessmentNotificationBanner";
import AssessmentSelect, { AssessmentSelectProps } from "./parts/AssessmentSelect/AssessmentSelect";

const StyledPaper = styled(Paper)(() => ({
  boxShadow: "none",
  borderRadius: "16px",
}));

export type ExamTabTablesForTalentProps = {
  notification?: Pick<AssessmentNotificationBannerProps, "count">;
  assessmentSelect: Pick<AssessmentSelectProps, "items" | "onChange" | "defaultValue">;
  showHeader: boolean;
  meta: AssessmentMetaProps;
  Loading?: React.ReactNode;
  examTable: ExamTableProps;
  sortField: SortFieldProps;
};

const ExamTabTablesForTalent: React.FC<ExamTabTablesForTalentProps> = props => {
  const [openSelect, setOpenSelect] = React.useState(false);
  const assessmentNotificationBanner: AssessmentNotificationBannerProps | undefined = props.notification
    ? {
        ...props.notification,
        confirmButton: {
          onClick: () => {
            setOpenSelect(true);
          },
        },
      }
    : undefined;
  const assessmentSelectProps: AssessmentSelectProps = {
    ...props.assessmentSelect,
    open: openSelect,
    onOpen: () => {
      setOpenSelect(true);
    },
    onClose: () => {
      setOpenSelect(false);
    },
  };
  return (
    <Box>
      {assessmentNotificationBanner && (
        <Box mb={3}>
          <AssessmentNotificationBanner {...assessmentNotificationBanner} />
        </Box>
      )}
      <StyledPaper>
        {props.showHeader && (
          <Box display="flex" flexDirection="row" justifyContent="space-between" px={1.5}>
            <AssessmentSelect {...assessmentSelectProps} />
            <Stack spacing={2} direction="row">
              <AssessmentMeta {...props.meta} />
              <SortField {...props.sortField} />
            </Stack>
          </Box>
        )}
        <Box px={1.5}>{props.Loading || <ExamTable {...props.examTable} />}</Box>
      </StyledPaper>
    </Box>
  );
};

ExamTabTablesForTalent.displayName = "ExamTabTablesForTalent";

export default ExamTabTablesForTalent;
