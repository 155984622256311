import PanToolAltOutlinedIcon from "@mui/icons-material/PanToolAltOutlined";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import SkeletonBox from "./parts/SkeletonBox/SkeletonBox";

const TitleWrapper = styled(Box)(() => ({
  position: "relative",
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  textDecoration: "underline",
  marginBottom: theme.spacing(1),
  fontSize: 16,
}));

const URLTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontSize: 12,
}));

const DescriptionTypography = styled(Typography)(() => ({
  fontSize: 12,
}));

const StyledPanToolAltOutlinedIcon = styled(PanToolAltOutlinedIcon)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(2),
  left: theme.spacing(2),
  animation: "moveUp .3s ease-in-out",
  animationFillMode: "forwards",

  "@keyframes moveUp": {
    "0%": {
      transform: "translateY(0)",
    },
    "100%": {
      transform: `translateY(-${theme.spacing(1)})`,
    },
  },
}));

type AccessedWebSite = {
  key: string;
  title: string;
  url: string;
  description: string;
};

export type SearchResultsStackProps = {
  accessedWebSite?: AccessedWebSite;
};

const SearchResultsStack: React.FC<SearchResultsStackProps> = props => {
  const accessedWebSite = props.accessedWebSite;

  return (
    <Stack spacing={2}>
      {accessedWebSite ? (
        <>
          <Box>
            <TitleWrapper>
              <TitleTypography>{accessedWebSite.title}</TitleTypography>
              {/* specify key to play an icon animation */}
              <StyledPanToolAltOutlinedIcon key={accessedWebSite.key} />
            </TitleWrapper>
            <URLTypography>{accessedWebSite.url}</URLTypography>
            <DescriptionTypography>{accessedWebSite.description}</DescriptionTypography>
          </Box>
          <SkeletonBox count={2} />
        </>
      ) : (
        <SkeletonBox count={3} />
      )}
    </Stack>
  );
};

SearchResultsStack.displayName = "SearchResultsStack";

export default SearchResultsStack;
