import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import ChallengeIDEConsoleInputTable from "../../../../ChallengeIDEConsoleInputTable/ChallengeIDEConsoleInputTable";
import { ChallengeCodingEditorContextProps } from "../../../Context";

type DatabaseTablesType = Extract<ChallengeCodingEditorContextProps["signatureProps"], { variant: "DATABASE" }>["signature"]["tables"];

export type DatabaseInputProps = {
  tables: DatabaseTablesType;
  consoleValue: string[];
  readonly?: boolean;
  errorMap?: Record<string, boolean>;
  handleErrorChange?: (key: string, hasError: boolean) => void;
  consoleValueChange: (index: number, text: string) => void;
};

const DatabaseInput: React.FC<DatabaseInputProps> = props => {
  const { t } = useTranslation();
  const { consoleValueChange, handleErrorChange } = props;

  return (
    <Box>
      {props.tables.map((table, index) => (
        <Stack key={table.name} direction="column" height="100%" bgcolor="inherit" spacing={1.5}>
          <Typography variant="subtitle2" sx={{ wordBreak: "keep-all", whiteSpace: "nowrap" }}>
            {table.name}
          </Typography>
          <ChallengeIDEConsoleInputTable
            tableName={table.name}
            tableColumns={table.columns}
            readonly={props.readonly}
            value={props.consoleValue?.[index] ?? "[]"}
            onChange={text => consoleValueChange(index, text)}
            handleErrorSet={handleErrorChange}
            errorMap={props.errorMap}
          />
          <FormHelperText
            error={props.errorMap && props.errorMap[String(index)]}
            children={props.errorMap && props.errorMap[String(index)] && t("型情報と異なる入力値が入力されています。")}
          />
        </Stack>
      ))}
    </Box>
  );
};

DatabaseInput.displayName = "DatabaseInput";

export default DatabaseInput;
