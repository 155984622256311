import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type CompanyProfileSettingsContainerProps = {};

const CompanyProfileSettingsContainer: React.FC<CompanyProfileSettingsContainerProps> = args => {
  const props = useGenerateProps(args);
  return (
    <ErrorBoundary>
      <Widget.CompanyProfileSettings {...props} />
    </ErrorBoundary>
  );
};

CompanyProfileSettingsContainer.displayName = "CompanyProfileSettingsContainer";

export default withErrorBoundary(CompanyProfileSettingsContainer, {});
