import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Auth } from "@hireroo/app-store/essential/employee";
import { ChallengeTestReport } from "@hireroo/app-store/view-domain/ChallengeTestReport";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useLanguageCode } from "@hireroo/i18n";
import { useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/browser";
import * as React from "react";

type FeedbackSuspiciousDegreeDialog = Widget.ChallengeTestReportProps["feedbackSuspiciousDegreeDialog"];

type GenerateFeedbackSuspiciousDegreeDialogArgs = {
  challengeId: number;
  canShowFeedbackSuspiciousDegree: boolean;
};

export const useGenerateFeedbackSuspiciousDegreeDialog = (args: GenerateFeedbackSuspiciousDegreeDialogArgs): FeedbackSuspiciousDegreeDialog => {
  const { challengeId, canShowFeedbackSuspiciousDegree } = args;
  const currentUserId = Auth.useCurrentUid();
  const challengeHooks = ChallengeTestReport.useCreateChallengeHooks(challengeId);
  const challenge = challengeHooks.useChallenge();
  const dialogStatus = challengeHooks.useFeedbackSuspiciousDegreeDialogStatus();
  const setDialogStatus = React.useCallback(
    (status: ChallengeTestReport.FeedbackSuspiciousDegreeDialogStatus) => {
      ChallengeTestReport.setFeedbackSuspiciousDegreeDialogStatus(challengeId, status);
    },
    [challengeId],
  );
  const setSuspiciousInferenceFeedback = React.useCallback(
    (isCorrect: boolean) => {
      ChallengeTestReport.setSuspiciousInferenceFeedback(challengeId, isCorrect);
    },
    [challengeId],
  );
  const suspiciousInference = challenge.suspiciousInference;

  const lang = useLanguageCode();
  const { t } = useTranslation();

  const client = getGraphqlClient();
  const feedbackSuspiciousDegreeDialog: FeedbackSuspiciousDegreeDialog | undefined = React.useMemo(() => {
    if (!canShowFeedbackSuspiciousDegree) {
      return undefined;
    }

    if (suspiciousInference === null) {
      return undefined;
    }

    return {
      open: dialogStatus === "OPEN",
      questionTitle: resolveLanguage(challenge.challengeQuestion || {}, lang, "title"),
      onClose: () => setDialogStatus("CLOSE"),
      onFeedback: async (fields, controller) => {
        controller.setLoading(true);
        const isCorrect = fields.correctness === "CORRECT";
        await client
          .FeedbackSuspiciousDegreeForChallengeTestReport({
            input: {
              inferenceId: suspiciousInference.inferenceId,
              isCorrect: isCorrect,
              feedbackProviderId: currentUserId,
              comment: fields.comment,
            },
          })
          .then(() => {
            Snackbar.notify({
              severity: "success",
              message: t("不審度のフィードバックが送信されました。"),
            });
            setSuspiciousInferenceFeedback(isCorrect);
            controller.close();
          })
          .catch(error => {
            Sentry.captureException(error);
            const errorNotification = ErrorHandlingHelper.generateErrorNotification(
              error,
              t("不審度のフィードバックに失敗しました。しばらくしてから再度お試しください。"),
            );
            Snackbar.notify({
              severity: "error",
              message: errorNotification.message,
            });
          })
          .finally(() => {
            controller.setLoading(false);
          });
      },
    };
  }, [
    dialogStatus,
    setDialogStatus,
    lang,
    client,
    t,
    suspiciousInference,
    currentUserId,
    setSuspiciousInferenceFeedback,
    challenge.challengeQuestion,
    canShowFeedbackSuspiciousDegree,
  ]);

  return feedbackSuspiciousDegreeDialog;
};
