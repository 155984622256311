import * as React from "react";
import { useResizeDetector } from "react-resize-detector";

import Pane, { PaneProps } from "../Pane/Pane";
import type * as Types from "../types";
import Wrapper, { WrapperProps } from "../Wrapper/Wrapper";
import { useSplitController } from "./useSplitController";

export type SplitProps = {
  className?: string;
  splitId: Types.SplitId;
  splitDirection: Types.SplitDirection;
  items: Types.PairProps[];
  snapOffset?: number;
  Pane?: {
    defaultClassName?: string;
    draggingClassName?: string;
    /**
     * default 3 px
     */
    defaultPaneSize?: number;
  };
  Wrapper?: {
    defaultClassName?: string;
  };
};

const Split: React.FC<SplitProps> = props => {
  const divRef = React.useRef<HTMLDivElement>(null);
  const initialized = React.useRef(false);
  const defaultPanePixelSize = props.Pane?.defaultPaneSize ?? 3;
  const method = useSplitController({
    splitId: props.splitId,
    defaultPanePixelSize: defaultPanePixelSize,
  });
  useResizeDetector({
    targetRef: divRef,
    handleHeight: props.splitDirection === "HORIZONTAL",
    handleWidth: props.splitDirection === "VERTICAL",
    refreshRate: 100,
    onResize: () => {
      if (!divRef.current) {
        return;
      }
      if (!initialized.current) {
        method.initialize({
          direction: props.splitDirection,
          height: divRef.current.clientHeight,
          width: divRef.current.clientWidth,
          items: props.items,
          snapOffset: props.snapOffset,
        });
        initialized.current = true;
      } else {
        method.resize({
          splitId: props.splitId,
          width: divRef.current.clientWidth,
          height: divRef.current.clientHeight,
        });
      }
    },
  });
  const Elements = React.useMemo(() => {
    const LAST = props.items.length - 1;
    return props.items.reduce<React.ReactNode[]>((acc, item, index) => {
      const Element = item.Content;
      const wrapperProps: WrapperProps = {
        direction: props.splitDirection,
        contentId: `${props.splitId}/Content/${item.id}`,
        className: props.Wrapper?.defaultClassName,
      };
      acc.push(
        <Wrapper key={`content-${item.id}`} {...wrapperProps}>
          {Element}
        </Wrapper>,
      );
      if (index !== LAST) {
        const paneProps: PaneProps = {
          paneId: `${props.splitId}/Pane/${item.id}`,
          direction: props.splitDirection,
          className: [props.Pane?.defaultClassName, item.sidePane?.className].filter(Boolean).join(" "),
          draggingClassName: props.Pane?.draggingClassName,
          size: item.sidePane?.size?.value ?? defaultPanePixelSize,
        };
        acc.push(<Pane key={`pane-${index}`} {...paneProps} />);
      }
      return acc;
    }, []);
  }, [
    defaultPanePixelSize,
    props.Pane?.defaultClassName,
    props.Pane?.draggingClassName,
    props.Wrapper?.defaultClassName,
    props.items,
    props.splitDirection,
    props.splitId,
  ]);

  React.useEffect(() => {
    return () => {
      initialized.current = false;
    };
  }, []);

  return (
    <div className={props.className} data-direction={props.splitDirection.toLowerCase()} ref={divRef}>
      {Elements}
    </div>
  );
};

Split.displayName = "Split";

export default React.memo(Split);
