import { useTranslation } from "@hireroo/i18n";
import { PlaybackSettingsForm } from "@hireroo/validator";
import Check from "@mui/icons-material/Check";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useFormContext } from "react-hook-form";

const StyledMenuItem = styled(MenuItem)({
  padding: "6px 16px",
});

const DescriptionTypography = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.text.secondary,
  whiteSpace: "pre-wrap",
}));

export type PlaybackModeSelectorProps = {
  menu: Pick<MenuProps, "anchorEl" | "onClose">;
};

const PlaybackModeSelector: React.FC<PlaybackModeSelectorProps> = props => {
  const { t } = useTranslation();
  const methods = useFormContext<PlaybackSettingsForm.ProjectPlaybackSettingsFormSchema>();
  const playbackMode = methods.watch("playbackMode");
  const menu: MenuProps = {
    ...props.menu,
    open: Boolean(props.menu.anchorEl),
    MenuListProps: {
      "aria-labelledby": "playback-mode-selector",
    },
    PaperProps: {
      sx: {
        maxWidth: 300,
      },
    },
  };
  const handleTimelineClick = () => {
    methods.setValue("playbackMode", "TIMELINE");
    props.menu.onClose?.({}, "backdropClick");
  };
  const handleEachFileClick = () => {
    methods.setValue("playbackMode", "EACH_FILE");
    props.menu.onClose?.({}, "backdropClick");
  };
  return (
    <Menu {...menu}>
      <StyledMenuItem key={`timeline`} onClick={handleTimelineClick}>
        <ListItemIcon>{playbackMode === "TIMELINE" && <Check color="secondary" fontSize="small" />}</ListItemIcon>
        <Stack spacing={0.5}>
          <Typography>{t("時系列プレイバックモード")}</Typography>
          <DescriptionTypography>{t("候補者の操作を時系列で確認し、全体の流れや作業過程を把握することができます。")}</DescriptionTypography>
        </Stack>
      </StyledMenuItem>
      <StyledMenuItem key={`each-file`} onClick={handleEachFileClick}>
        <ListItemIcon>{playbackMode === "EACH_FILE" && <Check color="secondary" fontSize="small" />}</ListItemIcon>
        <Stack spacing={0.5}>
          <Typography>{t("ファイル別プレイバックモード")}</Typography>
          <DescriptionTypography>
            {t("特定のファイルを選択して、そのファイルないでのコーディングプロセスを集中的に追跡することができます。")}
          </DescriptionTypography>
        </Stack>
      </StyledMenuItem>
    </Menu>
  );
};

PlaybackModeSelector.displayName = "PlaybackModeSelector";

export default PlaybackModeSelector;
