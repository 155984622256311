import { getOrRefreshAuthTokenExpire } from "./Action";
import { state } from "./State";

/**
 * If the auth token has expired, it is reacquired.
 */
export const getAuthToken = async (): Promise<string | null> => {
  await getOrRefreshAuthTokenExpire();
  return state.authToken;
};

export const getCurrentUserId = () => {
  if (!state.uid) {
    throw new Error("Please set from firebase uid");
  }
  return state.uid;
};

export const getUserType = () => {
  if (state.userType === null) {
    throw new Error("Please initialize user type!");
  }
  return state.userType;
};
