import { state } from "./State";
import type * as Types from "./types";

export const clear = () => {
  state.entityKeys = [];
  state.entities = {};
  state.currentSelectedEntityKey = null;
};

export const initialize = (): void => {
  state.initialized = true;
  /**
   * The reference time for the start of TimeLimit starts at the time when the initialization of data acquisition is completed.
   */
  state.initializedDate = new Date();
};

export const initializeEntities = (entities: Types.EntityForNavigation[]) => {
  entities.forEach(entity => {
    state.entities[entity.key] = entity;
    state.entityKeys.push(entity.key);
  });
};

export const setSelectedEntityKey = (entityKey: string) => {
  state.currentSelectedEntityKey = entityKey;
};

export const completeEntity = (key: string) => {
  const target = state.entities[key];
  if (target) {
    target.completed = true;
  }
};

export const updateLoadingStatus = (status: Types.LoadingStatus) => {
  state.loadingStatus = status;
};
