import { App } from "@hireroo/app-store/essential/candidate";
import { ScreeningsId } from "@hireroo/app-store/page/c/screenings_id";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

import CustomErrorMessagePanelContainer from "../../../../widget/v2/c/CustomErrorMessagePanel/Container";
import ErrorPanelContainer from "../../../../widget/v2/c/ErrorPanel/Container";
import EntryScreeningContainer, { EntryScreeningContainerProps } from "./Container";

export type EntryScreeningFetchContainerProps = {};

const EntryScreeningFetchContainer: React.FC<EntryScreeningFetchContainerProps> = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const invitationCode = searchParams.get("code");
  const appStatus = App.useStatus();
  const { id } = useParams<{ id: string }>();
  const initialized = ScreeningsId.useInitialized();
  const [result] = Graphql.useGetScreeningForScreeningsIdQuery({
    variables: {
      screeningId: `${id}`,
      invitationCode: invitationCode,
    },
    pause: appStatus !== "INITIALIZED",
  });
  React.useEffect(() => {
    if (result.data) {
      ScreeningsId.setScreening(result.data.screening);
    }
  }, [result.data]);

  React.useEffect(() => {
    return () => {
      ScreeningsId.clear();
    };
  }, []);

  if (result.error) {
    return <ErrorPanelContainer graphqlErrors={result.error.graphQLErrors} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="TOP" color="secondary" />;
  }

  if (result.data?.screening.acceptanceStatus !== undefined) {
    switch (result.data.screening.acceptanceStatus) {
      case "ACCEPTING":
        break;
      case "SUBMIT_DEADLINE_EXCEEDED": {
        return (
          <CustomErrorMessagePanelContainer
            title={t("このテストの提出期限がすでに過ぎています。")}
            message={t("詳細はこのテストを出題している企業までお問い合わせください。")}
          />
        );
      }
      case "OVERCAPACITY":
        return (
          <CustomErrorMessagePanelContainer
            title={t("このテストは定員の上限に到達しています。")}
            message={t("詳細はこのテストを出題している企業までお問い合わせください。")}
          />
        );
      case "SUSPENDED":
        return (
          <CustomErrorMessagePanelContainer
            title={t("このテストは新規の受付を停止しています。")}
            message={t("詳細はこのテストを出題している企業までお問い合わせください。")}
          />
        );
      default:
        throw new Error(`Screening is invalid: ${result.data?.screening.acceptanceStatus satisfies never}`);
    }
  }

  const containerProps: EntryScreeningContainerProps = {
    invitationCode,
  };
  return (
    <ErrorBoundary>
      <EntryScreeningContainer {...containerProps} />
    </ErrorBoundary>
  );
};

EntryScreeningFetchContainer.displayName = "EntryScreeningFetchContainer";

export default withErrorBoundary(EntryScreeningFetchContainer, {});
