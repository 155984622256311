import * as React from "react";

import { WebSearchSidePanel, WebSearchSidePanelProps } from "../../ms-components/Zeus";

export type ChallengeCodingEditorWebSearchRightSidePanelProps = {
  webSearchSidePanel: WebSearchSidePanelProps;
};

const ChallengeCodingEditorWebSearchRightSidePanel: React.FC<ChallengeCodingEditorWebSearchRightSidePanelProps> = props => {
  return <WebSearchSidePanel {...props.webSearchSidePanel} />;
};

ChallengeCodingEditorWebSearchRightSidePanel.displayName = "ChallengeCodingEditorWebSearchRightSidePanel";

export default ChallengeCodingEditorWebSearchRightSidePanel;
