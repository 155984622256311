import { App, Company } from "@hireroo/app-store/essential/employee";
import { EvaluationMetricsGroups } from "@hireroo/app-store/widget/e/EvaluationMetricsGroups";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import EvaluationMetricsGroupsContainer, { EvaluationMetricsGroupsContainerProps } from "./Container";

export type EvaluationMetricsGroupsFetchContainerProps = {};

const EvaluationMetricsGroupsFetchContainer: React.FC<EvaluationMetricsGroupsFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const nextPager = EvaluationMetricsGroups.useNextPager();
  const pagination = EvaluationMetricsGroups.usePagination();
  const companyId = Company.useStrictActiveCompanyId();

  const [result, refresh] = Graphql.useListEvaluationMetricsGroupsForEvaluationMetricsGroupsQuery({
    variables: {
      companyId: companyId,
      size: pagination.size,
      cursor: nextPager?.cursor ?? null,
      withCount: true,
      isDescending: true,
    },
    requestPolicy: "network-only",
    pause: appStatus !== "INITIALIZED",
  });

  React.useEffect(() => {
    if (result.data?.evaluationMetricGroupsByCompany?.metricGroups) {
      EvaluationMetricsGroups.initialize(result.data.evaluationMetricGroupsByCompany);
    }
  }, [result.data]);

  React.useEffect(() => {
    return () => {
      EvaluationMetricsGroups.clear();
    };
  }, []);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED") {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: EvaluationMetricsGroupsContainerProps = {
    refresh: refresh,
  };

  return <EvaluationMetricsGroupsContainer {...containerProps} />;
};

EvaluationMetricsGroupsFetchContainer.displayName = "EvaluationMetricsGroupsFetchContainer";

export default withErrorBoundary(EvaluationMetricsGroupsFetchContainer, {});
