import { SupportLanguage, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import * as semver from "semver";
import * as z from "zod";

import * as Question from "./fields/Question";

export const useMultiChoiceOption = () => {
  const { t } = useTranslation();
  const content = z.object({
    title: z.string().min(1, { message: t("詳細は必須です。") }),
    detail: z.string(),
  });
  return z.object({
    multiChoiceOptionId: z.number().optional(),
    isCorrect: z.boolean(),
    content: z.object({
      [SupportLanguage.EN]: content.optional(),
      [SupportLanguage.JA]: content.optional(),
    }),
  });
};

export const useSingleChoice = () => {
  const { t } = useTranslation();
  const content = z.object({
    title: z.string().min(1, { message: t("詳細は必須です。") }),
    additionalDetail: z.string(),
  });
  return z.object({
    singleChoiceOptionId: z.number().optional(),
    isCorrect: z.boolean(),
    content: z.object({
      [SupportLanguage.EN]: content.optional(),
      [SupportLanguage.JA]: content.optional(),
    }),
  });
};

export const useMultiChoice = () => {
  const option = useMultiChoiceOption();
  const { t } = useTranslation();
  return z.object({
    options: z.array(option).superRefine((options, ctx) => {
      if (!options.some(option => option.isCorrect)) {
        options.forEach((_, idx) => {
          ctx.addIssue({
            code: z.ZodIssueCode.too_small,
            path: [idx, "isCorrect"],
            minimum: 1,
            type: "string",
            inclusive: true,
            message: t("少なくとも1つ正解が必須です。"),
          });
        });
      }
    }),
  });
};

export const useEvaluation = () => {
  const { t } = useTranslation();
  const content = z.object({
    title: z.string().min(1, { message: t("タイトルは必須項目です。") }),
  });
  return z.object({
    freeTextOptionId: z.number().optional(),
    questionId: z.number().optional(),
    questionVersion: z.string().optional(),
    content: z.object({
      ja: content.optional(),
      en: content.optional(),
    }),
  });
};

const useSingleChoiceSchema = () => {
  const singleChoice = useSingleChoice();
  return z.object({
    variant: z.literal("SINGLE_CHOICE"),
    singleChoiceCorrectOptionIndex: z.string(),
    singleChoiceQuestions: z.array(singleChoice),
  });
};

const useMultiChoiceForm = () => {
  const multiChoice = useMultiChoice();
  return z.object({
    variant: z.literal("MULTI_CHOICE"),
    multiChoiceQuestion: multiChoice,
  });
};

const useFreeTextSchema = () => {
  const evaluation = useEvaluation();
  return z.object({
    variant: z.literal("FREE_TEXT"),
    evaluations: z.array(evaluation),
  });
};

const useSharedSchema = () => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const content = z.object({
    title: z.string().min(1, { message: t("タイトルは必須項目です。") }),
    detail: z.string(),
  });

  return z
    .object({
      id: z.number().optional(),
      newVersion: z.string().regex(/^v([0-9]+)\.([0-9]+)\.([0-9]+)$/, { message: t2("ValidateQuestionSetVersion") }),
      oldVersion: z.string().regex(/^v([0-9]+)\.([0-9]+)\.([0-9]+)$/, { message: t2("ValidateQuestionSetVersion") }),
      isPublished: z.boolean(),
      status: Question.QuestionStatus,
      isPublic: z.boolean(),
      difficulty: Question.Difficulty,
      metricType: Question.MetricType,
      variant: Question.Variant,
      questionDetails: z.object({
        [SupportLanguage.EN]: content.optional(),
        [SupportLanguage.JA]: content.optional(),
      }),
      selectedLanguages: Question.SelectedLanguages,
    })
    .superRefine((obj, ctx) => {
      if (!semver.valid(obj.newVersion)) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_string,
          validation: "regex",
          path: ["newVersion"],
          message: t2("ValidateQuestionSetVersion"),
        });
      } else if (obj.isPublished && !semver.gt(obj.newVersion, obj.oldVersion)) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_small,
          path: ["newVersion"],
          minimum: 5,
          type: "number",
          inclusive: true,
          message: t2("VersionErrorText", { preVersion: obj.oldVersion }),
        });
      }
    });
};

export type SharedSchema = z.infer<ReturnType<typeof useSharedSchema>>;

export const useQuizQuestionForm = () => {
  const singleChoiceSchema = useSingleChoiceSchema();
  const multiChoiceForm = useMultiChoiceForm();
  const freeTextSchema = useFreeTextSchema();
  const base = useSharedSchema();
  return z.intersection(base, z.union([singleChoiceSchema, multiChoiceForm, freeTextSchema]));
};

export type QuizQuestionFormSchema = z.infer<ReturnType<typeof useQuizQuestionForm>>;
