import { RemoteInterviewTimelimit } from "@hireroo/app-store/widget/shared/RemoteInterviewTimelimit";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type RemoteInterviewTimerContainerProps = {};

const RemoteInterviewTimerContainer: React.FC<RemoteInterviewTimerContainerProps> = () => {
  const initialized = RemoteInterviewTimelimit.useInitialized();
  const remoteInterviewTimerProps = useGenerateProps({});
  if (!initialized) {
    return <></>;
  }

  return (
    <ErrorBoundary>
      <Widget.RemoteInterviewTimer {...remoteInterviewTimerProps} />
    </ErrorBoundary>
  );
};

RemoteInterviewTimerContainer.displayName = "RemoteInterviewTimerContainer";

export default withErrorBoundary(RemoteInterviewTimerContainer, {});
