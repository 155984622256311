import { useTranslation } from "@hireroo/i18n";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button, { type ButtonProps } from "@mui/material/Button";
import * as React from "react";

export type AddEvaluationButtonProps = {
  onClick?: ButtonProps["onClick"];
};

const AddEvaluationButton: React.FC<AddEvaluationButtonProps> = props => {
  const { t } = useTranslation();
  const buttonProps: ButtonProps = {
    /**
     * This value is used to identify elements in the tutorial.
     */
    className: "add-evaluation-item-button",
    size: "medium",
    variant: "outlined",
    color: "secondary",
    startIcon: <AddIcon color="inherit" fontSize="small" />,
    children: t("評価項目を追加する"),
    onClick: props.onClick,
  };
  return (
    <Box display="flex" justifyContent="center">
      <Button {...buttonProps} />
    </Box>
  );
};

AddEvaluationButton.displayName = "AddEvaluationButton";

export default AddEvaluationButton;
