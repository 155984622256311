type Mode = "FLEXIBLE" | "FIXED";

export type PercentSize = {
  /**
   * if unit = %:
   *  0 < size < 100
   */
  value: number;
  unit: "%";
};

export type PixelSize = {
  value: number;
  unit: "px";
};

export type ContentSizeInput = {
  id: string;
  type: "CONTENT";
  size: PercentSize | PixelSize;
};

export type PaneSizeInput = {
  id: string;
  type: "PANE";
  size: PixelSize;
};

export type SizeInput = ContentSizeInput | PaneSizeInput;

type SizeOutput = {
  id: string;
  type: "CONTENT" | "PANE";
  size: PixelSize;
  mode: Mode;
};

export type Args = {
  parentSize: number;
  inputs: SizeInput[];
};

export type Result = {
  outputs: SizeOutput[];
};

export const calculateInitialSizes = (args: Args): Result => {
  const fullWidth = args.parentSize;
  /**
   * Width available in Percent
   */
  const remainWidth = args.inputs.reduce<number>((width, input) => {
    if (input.type === "CONTENT" && input.size.unit === "px") {
      return width - input.size.value;
    }
    if (input.type === "PANE" && input.size.unit === "px") {
      return width - input.size.value;
    }
    return width;
  }, fullWidth);
  if (remainWidth < 0) {
    console.warn("The specified default value exceeds the available width.");
  }

  const outputs = args.inputs.map((input): SizeOutput => {
    if (input.type === "CONTENT" && input.size.unit === "%") {
      const pct = input.size.value;
      return {
        id: input.id,
        type: input.type,
        size: {
          value: Math.trunc((remainWidth / 100) * pct),
          unit: "px",
        },
        mode: "FLEXIBLE",
      };
    }
    return {
      id: input.id,
      type: input.type,
      size: {
        value: input.size.value,
        unit: "px",
      },
      mode: "FIXED",
    };
  });
  return {
    outputs,
  };
};
