import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      border: `3px solid`,
      borderColor: theme.palette.secondary.main,
    },
    [`& .${stepConnectorClasses.root}`]: {
      color: theme.palette.secondary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      border: `3px solid`,
      borderColor: theme.palette.secondary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    border: "3px dashed rgba(75, 81, 193, 0.3)",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const StyledCheckCircleIcon = styled(CheckOutlinedIcon)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  borderRadius: "50px",
  border: "3px solid",
  borderColor: theme.palette.secondary.main,
}));

const CustomBorder = {
  light: "3px solid var(--light-secondary-shades-30-p, rgba(75, 81, 193, 0.30))",
  dark: "3px solid var(--dark-secondary-shades-30-p, rgba(131, 141, 255, 0.30))",
} as const satisfies Record<string, string>;

const StyledCircleIcon = styled(CircleOutlinedIcon)(({ theme }) => ({
  color: "transparent",
  borderRadius: "50px",
  border: CustomBorder[theme.palette.mode],
}));

const StepperIcon = (props: StepIconProps) => {
  const { completed, error, active } = props;

  if (error) {
    return <CancelIcon color="error" />;
  }
  if (completed) {
    return <StyledCheckCircleIcon />;
  }
  if (active) {
    return <CheckCircleIcon color="success" />;
  }

  return <StyledCircleIcon />;
};

type StatusType = "DOING" | "DONE" | "DECLINED";

export type TimelineItemType = {
  title: string;
  status: StatusType;
  dateTime?: string;
};

export type TimelineProps = {
  items: TimelineItemType[];
};

const Timeline: React.FC<TimelineProps> = props => {
  const theme = useTheme();
  const textColorMap = {
    DOING: theme.palette.text.disabled,
    DONE: theme.palette.text.primary,
    DECLINED: "error",
  } satisfies Record<StatusType, string>;
  return (
    <Box sx={{ width: "100%" }} mt={2}>
      <Stepper connector={<StyledConnector />} alternativeLabel>
        {props.items.map(timelineItem => (
          <Step key={timelineItem.title} completed={timelineItem.status === "DONE"}>
            <StepLabel
              color={theme.palette.secondary.main}
              error={timelineItem.status === "DECLINED"}
              StepIconComponent={StepperIcon}
              optional={
                <Box textAlign="center" mt={1}>
                  <Typography variant="subtitle1" fontSize={12} fontWeight={700} color={textColorMap[timelineItem.status]}>
                    {timelineItem.title}
                  </Typography>
                  <Typography variant="caption" fontSize={10} color={timelineItem.status === "DECLINED" ? "error" : "textSecondary"}>
                    {timelineItem.dateTime}
                  </Typography>
                </Box>
              }
            />
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

Timeline.displayName = "Timeline";

export default Timeline;
