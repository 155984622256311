import { subscribeKey } from "valtio/utils";

import { state } from "./State";
import type * as Types from "./types";

export type SubscribeOnChangeEntityCallback = (entityKey: string, entity: Types.EntityForNavigation) => void;
export const subscribeOnChangeShowingEntity = (callback: SubscribeOnChangeEntityCallback) => {
  return subscribeKey(state, "currentSelectedEntityKey", currentSelectedEntityKey => {
    if (!currentSelectedEntityKey) {
      return;
    }
    const targetEntity = state.entities[currentSelectedEntityKey];
    targetEntity && callback(currentSelectedEntityKey, targetEntity);
  });
};
