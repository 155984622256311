import { useSnapshot } from "valtio";

import { state } from "./State";
import * as Types from "./types";

export const useInitialized = (projectId: Types.ProjectId): boolean => {
  const snapshot = useSnapshot(state.projectMap);
  return snapshot.has(projectId);
};

export const createHooks = (projectId: Types.ProjectId) => {
  const useSnapshotState = () => {
    const snapshot = useSnapshot(state.projectMap);
    const target = snapshot.get(projectId);
    if (!target) {
      throw new Error(`Not initialized: projectId=${projectId}`);
    }
    return target;
  };

  const useSourceFileFetchStatus = () => {
    const snapshot = useSnapshotState();
    return snapshot.sourceFileFetchStatus;
  };

  const useCurrentSelectFilename = () => {
    const snapshot = useSnapshotState();
    return snapshot.currentSelectFilename || "";
  };

  const useDiffMap = () => {
    const snapshot = useSnapshotState();
    return snapshot.diffsByFilename;
  };

  const useCurrentDiff = () => {
    const snapshot = useSnapshotState();
    if (!snapshot.currentSelectFilename) {
      return;
    }
    return snapshot.diffsByFilename.get(snapshot.currentSelectFilename);
  };

  const useDiffSources = () => {
    const snapshot = useSnapshotState();
    const currentSelectFilename = useCurrentSelectFilename();
    const sources = snapshot.diffStateMap.get(currentSelectFilename);
    return {
      from: sources?.fromSource,
      to: sources?.toSource,
    };
  };

  return {
    useCurrentSelectFilename,
    useDiffMap,
    useCurrentDiff,
    useDiffSources,
    useSourceFileFetchStatus,
  };
};
