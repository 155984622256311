import { DeepReadonly } from "@hireroo/app-helper/types";
import { AssessmentReport } from "@hireroo/app-store/widget/t/AssessmentReport";
export const generateSmoothTargetId = (entity: DeepReadonly<AssessmentReport.Entity>): string => {
  switch (entity.__typename) {
    case "ChallengeEntity": {
      return `challenge-${entity.challengeEntityId}-${entity.challengeQuestion?.questionId}-${entity.challengeQuestion?.version}`;
    }
    case "QuizEntity": {
      return `quiz-${entity.quizEntityId}-${entity.pb_package?.packageId}-${entity.pb_package?.version}`;
    }
    case "ProjectEntity": {
      return `project-${entity.projectEntityId}-${entity.question?.questionId}`;
    }
    case "SystemDesignEntity": {
      return `systemDesign-${entity.systemDesignEntityId}-${entity.question?.questionId}`;
    }
    default: {
      return "";
    }
  }
};
