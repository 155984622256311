import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import * as QuestionSelectFieldV2Subscriber from "../QuestionSelectFieldV2/Subscriber";
import { useInitialStepName } from "./privateHelper";
import { GenerateScreeningResourceEditorPropsArgs, useGenerateProps } from "./useGenerateProps";

export type ScreeningResourceEditorContainerProps = GenerateScreeningResourceEditorPropsArgs;

const ScreeningResourceEditorContainer: React.FC<ScreeningResourceEditorContainerProps> = props => {
  const initialStepName = useInitialStepName();
  const screeningResourceEditorProps = useGenerateProps(props);
  React.useEffect(() => {
    const cleanup1 = QuestionSelectFieldV2Subscriber.startSubscribeSelectedQuestions();
    const cleanup2 = QuestionSelectFieldV2Subscriber.startSubscribeQuestionPackages();
    return () => {
      cleanup1();
      cleanup2();
    };
  }, []);
  return (
    <ErrorBoundary>
      <Widget.ScreeningResourceEditorProvider initialStepName={props.mode === "EDIT" ? initialStepName : undefined}>
        <Widget.ScreeningResourceEditor {...screeningResourceEditorProps} />
      </Widget.ScreeningResourceEditorProvider>
    </ErrorBoundary>
  );
};

ScreeningResourceEditorContainer.displayName = "ScreeningResourceEditorContainer";

export default withErrorBoundary(ScreeningResourceEditorContainer, {});
