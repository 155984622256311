import { useSnapshot } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = () => {
  const snapshot = useSnapshotState();
  return snapshot.status === "INITIALIZED";
};

export const useSpotSettings = (): Types.SpotSettingsSubscribeSlack => {
  const snapshot = useSnapshotState();
  return snapshot.spotSettings;
};

export const useRemoteSettings = (): Types.RemoteSettingsSubscribeSlack => {
  const snapshot = useSnapshotState();
  return snapshot.remoteSettings;
};

export const useIsAuthorized = () => {
  const snapshot = useSnapshotState();
  return snapshot.isAuthorized;
};

export const useLanguage = () => {
  const snapshot = useSnapshotState();
  return snapshot.language;
};

export const useWebhook = () => {
  const snapshot = useSnapshotState();
  return snapshot.incomingWebHook;
};
