import { useTranslation } from "@hireroo/i18n";
import { AlgorithmSignatureForm } from "@hireroo/validator";
import Add from "@mui/icons-material/Add";
import Close from "@mui/icons-material/Close";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Collapse from "@mui/material/Collapse";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useFieldArray } from "react-hook-form";

import AcceptButton from "../../../primitive/Button/AcceptButton";
import IconButton, { IconButtonProps } from "../../../primitive/Button/IconButton";
import { InputControlTextFieldProps } from "../../../primitive/InputControl/InputControlTextField";
import { useDatabaseTableSignatureContext } from "../Context";
import ColumnInput from "./ColumnInput";

export const defaultColumn: AlgorithmSignatureForm.DatabaseInputSchemaType = {
  name: "",
  type: "string",
};

export type TableSchemaInputProps = {
  tableIndex: number;

  showRemoveButton: boolean;
  deleteTableButton: IconButtonProps;
};

const TableSchemaInput: React.FC<TableSchemaInputProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { methods } = useDatabaseTableSignatureContext();
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [tableName, setTableName] = React.useState<string>(methods.getValues(`tables.${props.tableIndex}.name`));

  const nameFieldState = methods.getFieldState(`tables.${props.tableIndex}.name`);

  const columnsField = useFieldArray({
    control: methods.control,
    name: `tables.${props.tableIndex}.columns`,
  });

  const addColumn = () => {
    columnsField.append(defaultColumn);
  };

  const removeColumn = (index: number) => {
    columnsField.remove(index);
  };

  const deleteTableButton: IconButtonProps = {
    ...props.deleteTableButton,
    title: t("テーブルを削除する"),
    color: "default",
    sx: { margin: 1 },
    size: "small",
    component: "span",
    children: <Close />,
  };
  const expandButton: IconButtonProps = {
    size: "small",
    sx: { width: 44 },
    onClick: React.useCallback(() => {
      setExpanded(!expanded);
    }, [expanded, setExpanded]),
    children: expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />,
  };
  const tableNameTextField: InputControlTextFieldProps = {
    label: t("テーブル名"),
    type: "text",
    variant: "standard",
    color: "primary",
    control: methods.control,
    InputLabelProps: {
      shrink: true,
    },
    size: "small",
    fullWidth: true,
    required: true,
    placeholder: "user",
  };
  return (
    <Box mt={2} mx={1}>
      <Card variant="outlined" sx={{ backgroundColor: theme.palette.background.default }}>
        <CardHeader
          action={props.showRemoveButton && <IconButton {...deleteTableButton} />}
          title={
            <Box display="flex">
              <IconButton {...expandButton} />
              <Box mx={2}>
                <TextField
                  {...tableNameTextField}
                  {...methods.register(`tables.${props.tableIndex}.name`)}
                  onChange={event => {
                    setTableName(event.target.value);
                    methods.clearErrors();
                  }}
                  onBlur={() => methods.setValue(`tables.${props.tableIndex}.name`, tableName)}
                  value={tableName}
                  error={Boolean(nameFieldState?.error?.message)}
                  helperText={nameFieldState?.error?.message}
                />
              </Box>
            </Box>
          }
        />
        <Collapse in={expanded} mountOnEnter>
          <CardContent>
            {columnsField.fields.map((field, i) => (
              <ColumnInput
                key={`table-col-${field.id}`}
                deleteColumnButton={{
                  onClick: () => removeColumn(i),
                  disabled: columnsField.fields.length <= 1,
                }}
                columnIndex={i}
                fieldPrefix={`tables.${props.tableIndex}`}
              />
            ))}
          </CardContent>
          <CardActions>
            <AcceptButton size="small" startIcon={<Add fontSize="small" />} onClick={addColumn} variant="text">
              {t("カラムを追加する").toString()}
            </AcceptButton>
          </CardActions>
        </Collapse>
      </Card>
    </Box>
  );
};

TableSchemaInput.displayName = "TableSchemaInput";

export default TableSchemaInput;
