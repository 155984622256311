import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";

export const useUserTypeTextConverter = () => {
  const { t } = useTranslation();
  const displayText: Record<Graphql.UserType, string> = {
    [Graphql.UserType.Unknown]: t("不明"),
    [Graphql.UserType.Candidate]: t("候補者"),
    [Graphql.UserType.Employee]: t("従業員"),
    [Graphql.UserType.Talent]: t("タレント"),
  };
  return (userType: Graphql.UserType): string => displayText[userType];
};
