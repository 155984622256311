import type * as Types from "./types";

export class PlaybackTickManager {
  #ticks: Map<Types.TimeStamp, Types.PlaybackTickEvent[]> = new Map();
  #sortedTicks: Types.PlaybackTick[] = [];
  /**
   * Threshold for interval between occurrences of mergable user events
   *
   * The reason for the 50 msec value is that the user cannot generate an event shorter than this.
   *
   * Example:
   * Even the user with the fastest typing speed is about 75 msec (800 type/sec).
   */
  static readonly THRESHOLD_MILLISECOND_BETWEEN_TICKS = 50;
  /**
   * Threshold for aggregating RUN_CODE events since they are called simultaneously as many times as the test case
   */
  static readonly THRESHOLD_MILLISECOND_BETWEEN_RUN_CODE_EVENTS = 80;
  /**
   * Threshold for merge interval BROWSER_BLUR and BROWSER_HIDDEN event since they are happened simultaneously when user open new tab.
   */
  static readonly THRESHOLD_MILLISECOND_BETWEEN_BLUR_AND_HIDDEN_EVENTS = 50;

  public addTick(ts: Types.TimeStamp, event: Types.PlaybackTickEvent) {
    const events = this.#ticks.get(ts) || [];
    this.#ticks.set(ts, [...events, event]);
  }

  public getTimeStampList = (): Types.TimeStamp[] => {
    return this.#sortedTicks.map(tick => tick.ts);
  };

  public findLatestTickBefore(ts: Types.TimeStamp): Types.PlaybackTick | null {
    const closestTick = this.#sortedTicks.filter(tick => tick.ts <= ts).at(-1);
    if (!closestTick) {
      return null;
    }
    return closestTick;
  }

  private mergeTickEventsByRunCode(): void {
    const tsList = Array.from(this.#ticks.keys()).sort();
    let cachedTs = 0;
    let previousTs = 0;
    let mergedEvents: Types.RunCode[] = [];
    tsList.forEach((currentTs, index) => {
      const canMerge = currentTs - previousTs <= PlaybackTickManager.THRESHOLD_MILLISECOND_BETWEEN_RUN_CODE_EVENTS;
      if (mergedEvents.length === 0) {
        cachedTs = currentTs;
      }
      if (!canMerge) {
        const events = this.#ticks.get(cachedTs) || [];
        this.#ticks.set(
          cachedTs,
          [...events, ...mergedEvents].map(event => ({ ...event, ts: cachedTs })),
        );
        cachedTs = currentTs;
        mergedEvents = [];
      }
      previousTs = currentTs;
      const tickEvents = this.#ticks.get(currentTs) || [];
      const runCodeEvents = tickEvents.filter(event => event.kind === "RUN_CODE") as Types.RunCode[];
      const otherEvents = tickEvents.filter(event => event.kind !== "RUN_CODE");
      if (otherEvents.length > 0) {
        this.#ticks.set(currentTs, otherEvents);
      } else {
        this.#ticks.delete(currentTs);
      }
      mergedEvents = mergedEvents.concat(runCodeEvents);
      if (index === tsList.length - 1) {
        const events = this.#ticks.get(cachedTs) || [];
        this.#ticks.set(
          cachedTs,
          [...events, ...mergedEvents].map(event => ({ ...event, ts: cachedTs })),
        );
      }
    });
  }

  /**
   * Paste Event merges with the nearest CODE_EDITOR event with matching Text Operation
   */
  private mergeTickEventsByPasteEvent(): void {
    const tsList = Array.from(this.#ticks.keys()).sort();
    const lastTsListIndex = tsList.length - 1;
    /**
     * Merge PasteEvent with a Tick load containing CodeEditor events.
     */
    const merge = (ts: Types.TimeStamp, pasteEvent: Types.EditorPasteEvent): boolean => {
      const tickEvents = this.#ticks.get(ts);
      const hasSameTextOperation = tickEvents?.some(event => {
        if (event.kind === "CODE_EDITOR") {
          return pasteEvent.value.map<boolean>((_, index) => event.textOperations[index] === pasteEvent.value[index]).every(value => value);
        }
        return false;
      });
      if (hasSameTextOperation && tickEvents) {
        /** Overwrite TimeStamp */
        this.#ticks.set(
          ts,
          [...tickEvents, pasteEvent].map(event => ({ ...event, ts })),
        );
        return true;
      }
      return false;
    };

    for (let currentTsIndex = 0; currentTsIndex < lastTsListIndex; currentTsIndex++) {
      const currentTs = tsList.at(currentTsIndex);
      if (!currentTs) {
        continue;
      }
      const tickEvents = this.#ticks.get(currentTs) || [];
      const pasteEvents = tickEvents.filter(event => event.kind === "EDITOR_PASTE") as Types.EditorPasteEvent[];
      const otherEvents = tickEvents.filter(event => event.kind !== "EDITOR_PASTE");
      if (otherEvents.length > 0) {
        this.#ticks.set(currentTs, otherEvents);
      } else {
        this.#ticks.delete(currentTs);
      }

      const maxIndex = Math.max(currentTsIndex, lastTsListIndex - currentTsIndex);
      pasteEvents.forEach(pasteEvent => {
        const hasCurrentTs = merge(currentTs, pasteEvent);
        if (hasCurrentTs) {
          return;
        }
        // <--- leftTs                                                                rightTs --->
        // [1, 2, 3, currentTsIndex - 1, currentTsIndex, currentTsIndex + 1, ..., lastTsListIndex];
        for (let moveTsIndex = 0; moveTsIndex <= maxIndex; moveTsIndex++) {
          const leftTs = currentTsIndex - moveTsIndex >= 0 ? tsList.at(currentTsIndex - moveTsIndex) : undefined;
          const rightTs = tsList.at(currentTsIndex + moveTsIndex);
          const leftMerged = leftTs ? merge(leftTs, pasteEvent) : false;
          if (leftMerged) {
            return;
          }
          const rightMerged = rightTs ? merge(rightTs, pasteEvent) : false;
          if (rightMerged) {
            return;
          }
        }
      });
    }
  }

  private mergeTickEventsByCloseBlurAndHiddenEvent(): void {
    const tsList = Array.from(this.#ticks.keys()).sort();
    let previousBlurEvent: Types.BrowserBlurEvent | null = null;
    let previousBlurEventIndex: number | null = null;

    tsList.forEach(ts => {
      const tickEvents = this.#ticks.get(ts) || [];
      let isMerged = false;

      tickEvents.forEach((event, index) => {
        if (event.kind === "BROWSER_BLUR") {
          previousBlurEvent = event;
          previousBlurEventIndex = index;
        }

        if (event.kind === "BROWSER_HIDDEN" && previousBlurEvent !== null && previousBlurEventIndex !== null) {
          const canMerge = event.ts - previousBlurEvent.ts < PlaybackTickManager.THRESHOLD_MILLISECOND_BETWEEN_BLUR_AND_HIDDEN_EVENTS;
          if (canMerge) {
            const includingPreviousBlurTickEvents = this.#ticks.get(previousBlurEvent.ts) || [];
            const otherEvents = includingPreviousBlurTickEvents.filter((_event, index) => index !== previousBlurEventIndex);
            if (otherEvents.length > 0) {
              this.#ticks.set(previousBlurEvent.ts, otherEvents);
            } else {
              this.#ticks.delete(previousBlurEvent.ts);
            }
            previousBlurEvent = null;
            previousBlurEventIndex = null;
            isMerged = true;
          }
        }
      });

      if (!isMerged) {
        this.#ticks.set(
          ts,
          tickEvents.map(event => ({ ...event, ts })),
        );
      }
    });
  }

  private mergeTicksByCloseTimeStamp(): void {
    const tsList = Array.from(this.#ticks.keys()).sort();
    let leftIndex = 0;
    for (let rightIndex = 1; rightIndex < tsList.length; rightIndex++) {
      const leftTs = tsList.at(leftIndex);
      const rightTs = tsList.at(rightIndex);
      if (!leftTs) {
        leftIndex = rightIndex;
        continue;
      }
      if (!rightTs) {
        continue;
      }
      const leftTickEvents = this.#ticks.get(leftTs);
      const rightTickEvents = this.#ticks.get(rightTs);
      const canMergeForward = rightTs - leftTs < PlaybackTickManager.THRESHOLD_MILLISECOND_BETWEEN_TICKS;
      if (canMergeForward && leftTickEvents && rightTickEvents) {
        /** TimeStamp is also brought to the value of the merging side. */
        const mergedTicks = [...leftTickEvents, ...rightTickEvents].map(tick => {
          return { ...tick, ts: leftTs };
        });
        this.#ticks.set(leftTs, mergedTicks);
        this.#ticks.delete(rightTs);
      } else {
        leftIndex = rightIndex;
      }
    }
  }

  public mergeTickEvents(): void {
    this.mergeTickEventsByRunCode();
    this.mergeTickEventsByPasteEvent();
    this.mergeTickEventsByCloseBlurAndHiddenEvent();
    this.mergeTicksByCloseTimeStamp();
  }

  public sortByTimeStamp(): void {
    const tsList = Array.from(this.#ticks.keys()).sort();
    this.#sortedTicks = tsList.reduce<Types.PlaybackTick[]>((ticks, ts) => {
      const tickEvents = this.#ticks.get(ts);
      if (!tickEvents) {
        return ticks;
      }
      return ticks.concat({
        ts,
        events: tickEvents,
      });
    }, []);
  }

  public get ticks() {
    return this.#sortedTicks;
  }

  public debug() {
    console.log(this.#sortedTicks);
  }
}
