export type ActionCodeOperation =
  | "RECOVER_EMAIL"
  | "PASSWORD_RESET"
  | "EMAIL_SIGNIN"
  | "VERIFY_EMAIL"
  | "VERIFY_AND_CHANGE_EMAIL"
  | "REVERT_SECOND_FACTOR_ADDITION";

const actionCodeOperationMap: Record<string, ActionCodeOperation | null> = {
  recoverEmail: "RECOVER_EMAIL",
  resetPassword: "PASSWORD_RESET",
  signIn: "EMAIL_SIGNIN",
  verifyEmail: "VERIFY_EMAIL",
  verifyAndChangeEmail: "VERIFY_AND_CHANGE_EMAIL",
  revertSecondFactorAddition: "REVERT_SECOND_FACTOR_ADDITION",
};

/**
 * @see https://github.com/firebase/firebase-js-sdk/blob/c8a6e08b01a52b3eca77ca9da8989dac2e77a972/packages/auth/src/core/action_code_url.ts#L42
 * Since it is an internal implementation, it is not required as an API.
 */
export const parseMode = (mode: string | null): ActionCodeOperation | null => {
  if (mode === null) {
    return null;
  }
  return actionCodeOperationMap[mode] || null;
};
