import Stack, { StackProps } from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";

import Header, { HeaderProps } from "./parts/Header/Header";

const WrapperStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette["Background/Paper"].p2,
  borderRadius: "16px",
}));

export type HomeSectionLayoutProps = {
  className?: string;
  header: HeaderProps;
  /**
   * default 2
   */
  spacing?: StackProps["spacing"];
};

const HomeSectionLayout: React.FC<React.PropsWithChildren<HomeSectionLayoutProps>> = props => {
  return (
    <WrapperStack spacing={props.spacing ?? 2} className={props.className}>
      <Header {...props.header} />
      {props.children}
    </WrapperStack>
  );
};

HomeSectionLayout.displayName = "HomeSectionLayout";

export default HomeSectionLayout;
