import Box from "@mui/material/Box";
import * as React from "react";
import { FormProvider } from "react-hook-form";

import { useFreepadQuestionDetailFormContext } from "./Context";
import ErrorMessage from "./parts/ErrorMessage/ErrorMessage";
import GeneralSettingFields, { GeneralSettingFieldsProps } from "./parts/GeneralSettingFields/GeneralSettingFields";
import QuestionTextFields, { QuestionTextFieldsProps } from "./parts/QuestionTextFields/QuestionTextFields";

export { FreepadQuestionDetailFormProvider, type FreepadQuestionDetailFormProviderProps, useFreepadQuestionDetailFormContext } from "./Context";
export type FreepadQuestionDetailFormProps = {
  generalSettingFields: GeneralSettingFieldsProps;
  questionTextFields: QuestionTextFieldsProps;
};

const FreepadQuestionDetailForm: React.FC<FreepadQuestionDetailFormProps> = props => {
  const { methods } = useFreepadQuestionDetailFormContext();
  return (
    <FormProvider {...methods}>
      <ErrorMessage />
      <Box mb={5}>
        <GeneralSettingFields {...props.generalSettingFields} />
      </Box>
      <QuestionTextFields {...props.questionTextFields} />
    </FormProvider>
  );
};

FreepadQuestionDetailForm.displayName = "FreepadQuestionDetailForm";

export default FreepadQuestionDetailForm;
