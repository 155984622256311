import { LanguageMap, languageMap } from "@hireroo/app-definition";
import { convertFileToBase64, getCroppedImage } from "@hireroo/app-helper/files";
import { Auth } from "@hireroo/app-store/essential/talent";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getCurrentUser, sendEmailVerification } from "@hireroo/firebase";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import { SupportLanguage, useChangeLanguage, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import { UserSettingsForm } from "@hireroo/validator";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { Area } from "react-easy-crop";
import { SubmitHandler } from "react-hook-form";

type UpdateUserProfileArg = {
  uid: string;
  displayName: string;
  language: Graphql.Language;
  photoUrl: string;
};

export const useGenerateProps = (): Widget.UserProfileProps => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const changeLanguage = useChangeLanguage();
  const [status, setStatus] = React.useState<"READY" | "PENDING">("READY");
  const [sendMailStatus, setSendMailStatus] = React.useState<"LOADING" | "READY">("READY");

  const client = getGraphqlClient();
  const currentUser = Auth.useCurrentUser();

  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState<Area>();
  const [originalImage, setOriginalImage] = React.useState<string>();

  const updateUserProfile = React.useCallback(
    async (args: UpdateUserProfileArg): Promise<void> => {
      await client
        .UpdateUserForUserProfile({
          updateUserInput: {
            id: args.uid,
            displayName: args.displayName,
            language: args.language,
            photoUrl: args.photoUrl,
          },
        })
        .then(res => {
          Snackbar.notify({
            severity: "success",
            message: t("ユーザ情報を更新しました。"),
          });
          Auth.setUser(res.updateUser);
        })
        .catch(() => {
          Snackbar.notify({
            severity: "error",
            message: t("ユーザ情報の更新に失敗しました。しばらくしてから再度お試し頂くか、ヘルプボタンよりお問い合わせください。"),
          });
        });
    },
    [client, t],
  );

  const onCropImage = React.useCallback(() => {
    if (!originalImage || !croppedAreaPixels) return;

    // Crop the selected image
    const croppedImage = getCroppedImage(originalImage, croppedAreaPixels);
    setStatus("PENDING");
    // Upload it to the GCS
    client
      .UploadUserImageForUserProfile({ uid: currentUser.uid, image: croppedImage })
      .then(res => {
        setOriginalImage(undefined);
        updateUserProfile({
          uid: currentUser.uid,
          displayName: currentUser.displayName,
          language: currentUser.language,
          photoUrl: res.uploadUserImage,
        });
      })
      .catch(error => {
        Sentry.captureException(error);
      })
      .finally(() => {
        setStatus("READY");
      });
  }, [originalImage, croppedAreaPixels, client, currentUser.uid, currentUser.displayName, currentUser.language, updateUserProfile]);

  const onImageCropDialogCancel = React.useCallback(() => {
    setOriginalImage(undefined);
  }, []);

  const onCropComplete = React.useCallback((croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onClickFileUpload = React.useCallback(
    async (file: File) => {
      // 4MB is the Upper limit of the request body for the envoy gateway
      if (file.size > 1024 * 1024 * 4) {
        Snackbar.notify({
          severity: "error",
          message: t("アップロード可能な画像のデータサイズの上限は4MBまでです。"),
        });
        return;
      }

      const base64Img = await convertFileToBase64(file);
      setOriginalImage(base64Img);
    },
    [t],
  );

  const onSubmit: SubmitHandler<UserSettingsForm.UserSettingsFormSchema> = React.useCallback(
    field => {
      setStatus("PENDING");
      updateUserProfile({
        uid: currentUser.uid,
        displayName: field.displayName,
        language: languageMap[field.language],
        photoUrl: currentUser.photoUrl,
      })
        .then(() => {
          changeLanguage(field.language);
        })
        .catch(error => {
          Sentry.captureException(error);
        })
        .finally(() => {
          setStatus("READY");
        });
    },
    [changeLanguage, currentUser.photoUrl, currentUser.uid, updateUserProfile],
  );

  return {
    isLoading: status === "PENDING",
    formSubmitButton: {
      fullWidth: true,
      children: t("保存する"),
    },
    editButton: {
      disabled: false,
    },
    onSubmit: onSubmit,
    displayNameField: {
      value: currentUser.displayName,
    },
    mailAddressField: {
      value: currentUser.email,
      helperText: t("一度登録されたメールアドレスの変更はできません。"),
      verified: (currentUser.provider === "PASSWORD" && currentUser.emailVerified) || currentUser.provider !== "PASSWORD",
    },
    resendVerificationButton:
      currentUser.provider === "PASSWORD" && !currentUser.emailVerified
        ? {
            loading: sendMailStatus === "LOADING",
            onClick: async () => {
              setSendMailStatus("LOADING");
              const user = await getCurrentUser();
              if (!user) {
                setSendMailStatus("READY");
                return;
              }

              await sendEmailVerification(user, currentUser.language)
                .then(() => {
                  Snackbar.notify({
                    severity: "success",
                    message: t2("sendEmailVerify", { email: user.email }),
                  });
                })
                .catch(() => {
                  Snackbar.notify({
                    severity: "error",
                    message: t("メールアドレスの確認メールの送信に失敗しました。再度サインインし直しください。"),
                  });
                })
                .finally(() => {
                  setSendMailStatus("READY");
                });
            },
          }
        : undefined,
    languageSelector: {
      value: LanguageMap[currentUser.language] || SupportLanguage.JA,
    },
    photoURLField: {
      photoUrl: currentUser.photoUrl,
      disabled: status === "PENDING",
      onClickFileUpload,
    },
    imageCropDialog: {
      originalImage,
      onCropComplete,
      yesButton: {
        onClick: onCropImage,
      },
      noButton: {
        onClick: onImageCropDialogCancel,
      },
    },
  };
};
