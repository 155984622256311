import { Mixpanel } from "@hireroo/app-monitoring";
import { ScreeningsTestsStore } from "@hireroo/app-store/page/e/screenings_tests";
export const startSubscriberListParamsForMixPanel = () => {
  return ScreeningsTestsStore.subscribeListParams(listParams => {
    Mixpanel.track({
      name: "TestList-Filter",
      properties: {
        minTotalScore: listParams.filters.minTotalScore ?? "undefined",
        maxTotalScore: listParams.filters.maxTotalScore ?? "undefined",
        createdByList: listParams.filters.createdByList.join(", "),
        statuses: listParams.filters.statuses.join(", "),
        tagNames: listParams.filters.tagNames.join(", "),
        rankEvaluations: listParams.filters.rankEvaluations.join(", "),
        screeningIds: listParams.filters.screeningIds.join(", "),
        sortMethod: listParams.sortMethod,
        isDescending: listParams.isDescending ? "true" : "false",
        suspiciousDegrees: listParams.filters.suspiciousDegrees.join(", "),
      },
    });
  });
};
