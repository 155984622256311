import { MediaDeviceSchema } from "@hireroo/validator";

const key = "media-device.selected-noise-cancellation-enabled";

export const SelectedNoiseCancellationStatus = {
  get: (): MediaDeviceSchema.NoiseCancellationStatus => {
    const value = localStorage.getItem(key);
    if (!value) {
      // default value
      return "ENABLED";
    }

    const result = MediaDeviceSchema.NoiseCancellationStatusSchema.safeParse(value);
    if (result.success) {
      return result.data;
    } else {
      // default value
      return "ENABLED";
    }
  },
  save: (status: MediaDeviceSchema.NoiseCancellationStatus) => {
    localStorage.setItem(key, status);
  },
  clear: () => {
    localStorage.removeItem(key);
  },
};
