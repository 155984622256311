import { getEnabledProjectLog } from "@hireroo/app-helper/feature";
import { getDatabaseReference, getTimestamp } from "@hireroo/firebase";
import { Widget } from "@hireroo/presentation";
import { FirebaseStorePath, HistoriesPathsModel, IndexModel } from "@hireroo/project-shared-utils";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateProjectCodingEditorV3PropsArgs, useGenerateProps } from "./useGeneratePropsForDevelopmentWorkspace";

export type DevelopmentWorkspaceContainerProps = Omit<GenerateProjectCodingEditorV3PropsArgs, "historiesPathsModel" | "indexModel">;

const DevelopmentWorkspaceContainer: React.FC<DevelopmentWorkspaceContainerProps> = props => {
  const enabledShowLog = getEnabledProjectLog();
  const rdbBasePath = React.useMemo(() => {
    return `projects/${props.entityId}/questions/${props.questionId}`;
  }, [props.entityId, props.questionId]);
  const historiesPathsModel = React.useMemo(() => {
    return new HistoriesPathsModel({
      logger: {
        error: message => {
          if (enabledShowLog) {
            console.error(message);
          }
        },
        debug: message => {
          if (enabledShowLog) {
            console.log(message);
          }
        },
        info: message => {
          if (enabledShowLog) {
            console.info(message);
          }
        },
      },
      rdbBasePath,
      key: FirebaseStorePath.HISTORIES_PATHS,
      database: () => getDatabaseReference("project"),
      getTimestamp,
    });
  }, [enabledShowLog, rdbBasePath]);

  const indexModel = React.useMemo(() => {
    return new IndexModel({
      logger: {
        error: message => {
          if (enabledShowLog) {
            console.error(message);
          }
        },
        debug: message => {
          if (enabledShowLog) {
            console.log(message);
          }
        },
        info: message => {
          if (enabledShowLog) {
            console.info(message);
          }
        },
      },
      rdbBasePath,
      key: FirebaseStorePath.INDEX_MODEL_PATH,
      database: () => getDatabaseReference("project"),
      getTimestamp,
    });
  }, [enabledShowLog, rdbBasePath]);

  const projectCodingEditorV3Props = useGenerateProps({
    ...props,
    historiesPathsModel: historiesPathsModel,
    indexModel: indexModel,
  });

  React.useEffect(() => {
    return () => {
      historiesPathsModel.dispose();
    };
  }, [historiesPathsModel]);

  if (!historiesPathsModel) {
    return <Widget.Loading kind="CENTER_%" color="secondary" />;
  }

  return (
    <ErrorBoundary>
      <Widget.ProjectCodingEditorV3 {...projectCodingEditorV3Props} />
    </ErrorBoundary>
  );
};

DevelopmentWorkspaceContainer.displayName = "DevelopmentWorkspaceContainer";

export default withErrorBoundary(DevelopmentWorkspaceContainer, {});
