import Box from "@mui/material/Box";
import * as React from "react";

import PlaybackContentFreeText, { PlaybackContentFreeTextProps } from "../PlaybackContentFreeText/PlaybackContentFreeText";
import PlaybackContentMultiOption, { PlaybackContentMultiOptionProps } from "../PlaybackContentMultiOption/PlaybackContentMultiOption";
import PlaybackContentSingleOption, { PlaybackContentSingleOptionProps } from "../PlaybackContentSingleOption/PlaybackContentSingleOption";

export type SingleChoiceContentProps = {
  kind: "SINGLE_CHOICE";
  content: PlaybackContentSingleOptionProps;
};

export type MultiChoiceContentProps = {
  kind: "MULTI_CHOICE";
  content: PlaybackContentMultiOptionProps;
};

export type FreeTextContentProps = {
  kind: "FREE_TEXT";
  content: PlaybackContentFreeTextProps;
};

export type PlaybackContentProps = SingleChoiceContentProps | MultiChoiceContentProps | FreeTextContentProps;

const PlaybackContent: React.FC<PlaybackContentProps> = props => {
  return (
    <Box>
      {props.kind === "SINGLE_CHOICE" && <PlaybackContentSingleOption {...props.content} />}
      {props.kind === "MULTI_CHOICE" && <PlaybackContentMultiOption {...props.content} />}
      {props.kind === "FREE_TEXT" && <PlaybackContentFreeText {...props.content} />}
    </Box>
  );
};

PlaybackContent.displayName = "PlaybackContent";

export default PlaybackContent;
