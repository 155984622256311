import * as BatExtDefaultExt from "@codingame/monaco-vscode-bat-default-extension";
import * as CppExtDefaultExt from "@codingame/monaco-vscode-cpp-default-extension";
import * as CsharpExtDefaultExt from "@codingame/monaco-vscode-csharp-default-extension";
import * as CssExtDefaultExt from "@codingame/monaco-vscode-css-default-extension";
import * as DartExtDefaultExt from "@codingame/monaco-vscode-dart-default-extension";
import * as DiffExtDefaultExt from "@codingame/monaco-vscode-diff-default-extension";
import * as DockerExtDefaultExt from "@codingame/monaco-vscode-docker-default-extension";
import * as GitExtBaseExtDefaultExt from "@codingame/monaco-vscode-git-base-default-extension";
import * as GoExtDefaultExt from "@codingame/monaco-vscode-go-default-extension";
import * as GroovyExtDefaultExt from "@codingame/monaco-vscode-groovy-default-extension";
import * as HandlebarsExtDefaultExt from "@codingame/monaco-vscode-handlebars-default-extension";
import * as HtmlExtDefaultExt from "@codingame/monaco-vscode-html-default-extension";
import * as IniExtDefaultExt from "@codingame/monaco-vscode-ini-default-extension";
import * as JavaExtDefaultExt from "@codingame/monaco-vscode-java-default-extension";
import * as JavascriptExtDefaultExt from "@codingame/monaco-vscode-javascript-default-extension";
import * as JsonExtDefaultExt from "@codingame/monaco-vscode-json-default-extension";
import * as JuliaExtDefaultExt from "@codingame/monaco-vscode-julia-default-extension";
import * as LogExtDefaultExt from "@codingame/monaco-vscode-log-default-extension";
import * as LuaExtDefaultExt from "@codingame/monaco-vscode-lua-default-extension";
import * as MarkdownExtBasicsExtDefaultExt from "@codingame/monaco-vscode-markdown-basics-default-extension";
import * as MarkdownExtMathExtDefaultExt from "@codingame/monaco-vscode-markdown-math-default-extension";
import * as NpmExtDefaultExt from "@codingame/monaco-vscode-npm-default-extension";
import * as PerlExtDefaultExt from "@codingame/monaco-vscode-perl-default-extension";
import * as PhpExtDefaultExt from "@codingame/monaco-vscode-php-default-extension";
import * as PowershellExtDefaultExt from "@codingame/monaco-vscode-powershell-default-extension";
import * as PugExtDefaultExt from "@codingame/monaco-vscode-pug-default-extension";
import * as PythonExtDefaultExt from "@codingame/monaco-vscode-python-default-extension";
import * as RExtDefaultExt from "@codingame/monaco-vscode-r-default-extension";
import * as RestructuredtextExtDefaultExt from "@codingame/monaco-vscode-restructuredtext-default-extension";
import * as RubyExtDefaultExt from "@codingame/monaco-vscode-ruby-default-extension";
import * as RustExtDefaultExt from "@codingame/monaco-vscode-rust-default-extension";
import * as ScssExtDefaultExt from "@codingame/monaco-vscode-scss-default-extension";
import * as ShellscriptExtDefaultExt from "@codingame/monaco-vscode-shellscript-default-extension";
import * as SqlExtDefaultExt from "@codingame/monaco-vscode-sql-default-extension";
import * as SwiftExtDefaultExt from "@codingame/monaco-vscode-swift-default-extension";
import * as ThemeExtMonokaiExtDefaultExt from "@codingame/monaco-vscode-theme-monokai-default-extension";
import * as TypescriptExtBasicsExtDefaultExt from "@codingame/monaco-vscode-typescript-basics-default-extension";
import * as TypescriptExtLanguageExtFeaturesExtDefaultExt from "@codingame/monaco-vscode-typescript-language-features-default-extension";
import * as XmlExtDefaultExt from "@codingame/monaco-vscode-xml-default-extension";
import * as YamlExtDefaultExt from "@codingame/monaco-vscode-yaml-default-extension";

import * as MathiasfrohlichKotlinExt from "./resources/mathiasfrohlich.Kotlin-1.7.1.vsix";
import * as VueVoltarExt from "./resources/Vue.volar-2.0.26.vsix";

export const awaitExtensionReadiness: (() => Promise<void>)[] = [
  TypescriptExtBasicsExtDefaultExt.whenReady,
  GoExtDefaultExt.whenReady,
  RustExtDefaultExt.whenReady,
  RubyExtDefaultExt.whenReady,
  PythonExtDefaultExt.whenReady,
  BatExtDefaultExt.whenReady,
  CppExtDefaultExt.whenReady,
  CsharpExtDefaultExt.whenReady,
  CssExtDefaultExt.whenReady,
  DartExtDefaultExt.whenReady,
  DiffExtDefaultExt.whenReady,
  DockerExtDefaultExt.whenReady,
  GitExtBaseExtDefaultExt.whenReady,
  GroovyExtDefaultExt.whenReady,
  HandlebarsExtDefaultExt.whenReady,
  HtmlExtDefaultExt.whenReady,
  IniExtDefaultExt.whenReady,
  JavaExtDefaultExt.whenReady,
  JavascriptExtDefaultExt.whenReady,
  JsonExtDefaultExt.whenReady,
  JuliaExtDefaultExt.whenReady,
  LogExtDefaultExt.whenReady,
  LuaExtDefaultExt.whenReady,
  MarkdownExtBasicsExtDefaultExt.whenReady,
  MarkdownExtMathExtDefaultExt.whenReady,
  NpmExtDefaultExt.whenReady,
  PerlExtDefaultExt.whenReady,
  PhpExtDefaultExt.whenReady,
  PowershellExtDefaultExt.whenReady,
  PugExtDefaultExt.whenReady,
  RExtDefaultExt.whenReady,
  RestructuredtextExtDefaultExt.whenReady,
  ScssExtDefaultExt.whenReady,
  ShellscriptExtDefaultExt.whenReady,
  SqlExtDefaultExt.whenReady,
  SwiftExtDefaultExt.whenReady,
  TypescriptExtLanguageExtFeaturesExtDefaultExt.whenReady,
  XmlExtDefaultExt.whenReady,
  YamlExtDefaultExt.whenReady,
  ThemeExtMonokaiExtDefaultExt.whenReady,
  VueVoltarExt.whenReady,
  MathiasfrohlichKotlinExt.whenReady,
];
