import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type RemoteInterviewDetailContainerProps = {};

const RemoteInterviewDetailContainer: React.FC<RemoteInterviewDetailContainerProps> = () => {
  const remoteInterviewDetailProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.RemoteInterviewDetail {...remoteInterviewDetailProps} />
    </ErrorBoundary>
  );
};

RemoteInterviewDetailContainer.displayName = "RemoteInterviewDetailContainer";

export default withErrorBoundary(RemoteInterviewDetailContainer, {});
