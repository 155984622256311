import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { SystemDesignCodingEditor } from "@hireroo/app-store/widget/shared/SystemDesignCodingEditor";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

export type GenerateAppealMessagePropsArgs = {
  entityId: number;
  hideAppealMessage: boolean;
};

export const useGenerateAppealMessageProps = (
  args: GenerateAppealMessagePropsArgs,
): Widget.SystemDesignCodingEditorProps["sidebar"]["appealMessage"] => {
  const [_appealMessageStatus, setAppealMessageStatus] = React.useState<"READY" | "PENDING">("READY");
  const entityAction = SystemDesignCodingEditor.createSystemDesignEntityAction(args.entityId);
  const hooks = SystemDesignCodingEditor.useCreateSystemDesignEntityHooks(args.entityId);
  const appealMessage = hooks.useAppealMessage();
  const client = getGraphqlClient();
  const { t } = useTranslation();

  return {
    showing: !args.hideAppealMessage,
    value: appealMessage ?? "",
    placeholder: t(
      "提出するフローチャートに説明を加えることができます。設計のモチベーションや配置したコンポーネントの役割などを自由に記述してください。",
    ),
    onChange: appealMessage => {
      setAppealMessageStatus("PENDING");
      client
        .SaveSystemDesignAppealMessage({
          systemDesignId: args.entityId,
          appealMessage: appealMessage,
        })
        .then(res => {
          if (res.saveSystemDesignAppealMessage?.appealMessage) {
            entityAction.updateAppealMessage(res.saveSystemDesignAppealMessage.appealMessage);
          }
          Snackbar.notify({
            severity: "success",
            message: t("説明文の保存に成功しました。"),
          });
        })
        .catch(() => {
          Snackbar.notify({
            severity: "success",
            message: t("説明文の保存に失敗しました。しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
          });
        })
        .finally(() => {
          setAppealMessageStatus("READY");
        });
    },
  };
};
