import { useTitle } from "@hireroo/app-helper/react-use";
import { useTranslation } from "@hireroo/i18n";
import type { Pages } from "@hireroo/presentation/legacy";

import { useGenerateAlgorithmResourceEditorProps } from "../../../props-factory/e/AlgorithmResourceEditor/useGenerateAlgorithmResourceEditorProps";

export type GenerateAlgorithmResourceEditorPropsArgs = {
  companyId: number;
  type: "CREATE" | "UPDATE";
};

export const useGenerateProps = (args: GenerateAlgorithmResourceEditorPropsArgs): Pages.AlgorithmResourceEditorProps => {
  const { t } = useTranslation();
  useTitle(`${t("問題作成")}(${t("コーディング形式")})`);
  return useGenerateAlgorithmResourceEditorProps(args);
};
