import { useTranslation } from "@hireroo/i18n";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Box, { BoxProps } from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import BaseDialog, { BaseDialogProps } from "../../../../primitive/BaseDialog/BaseDialog";

export type LockedHintProps = {
  title: string;
  disabled: boolean;
  onUnlocked: () => void;
};

const StyledBox = styled(Box)(props => ({
  display: "flex",
  justifyContent: "space-between",
  minHeight: "36px",
  alignItems: "center",
  borderRadius: "8px",
  opacity: props["aria-disabled"] === "true" ? 0.7 : undefined,
  cursor: props["aria-disabled"] === "true" ? "default" : "pointer",
  marginBottom: "16px",
  "&:last-child": {
    marginBottom: 0,
  },
}));

const LockedBox = styled(Box)`
  display: flex;
`;

const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(15),
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.secondary.main,
}));

const StyledExpandMoreIcon = styled(ExpandMoreIcon)(({ theme, ...props }) => ({
  color: theme.palette["Gray/Shades"].p56,
  opacity: props["aria-disabled"] === "true" ? undefined : 0.7,
  cursor: props["aria-disabled"] === "true" ? "default" : undefined,
}));

const LockedHint: React.FC<LockedHintProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const styledBoxProps: BoxProps = {
    px: 2,
    "aria-disabled": props.disabled ? "true" : undefined,
    onClick: props.disabled
      ? undefined
      : () => {
          if (!open) {
            setOpen(true);
          }
        },
  };
  const confirmDialogProps: BaseDialogProps = {
    open,
    title: props.title,
    yesButton: {
      children: t("はい"),
      onClick: () => {
        setOpen(false);
        props.onUnlocked();
      },
    },
    noButton: {
      children: t("いいえ"),
      onClick: () => setOpen(false),
    },
  };
  return (
    <StyledBox {...styledBoxProps} px={1} bgcolor={theme.palette["Secondary/Shades"].p12}>
      <Box display="flex" alignItems="center">
        <LockedBox mr={1}>
          <LockOutlinedIcon sx={{ fontSize: "0.9em" }} color="secondary" />
        </LockedBox>
        <Title>{props.title}</Title>
      </Box>
      <StyledExpandMoreIcon aria-disabled={props.disabled ? "true" : undefined} />
      <BaseDialog {...confirmDialogProps}>
        {t("まずは自分の力で解いてみましょう。それでも詰まった場合はヒントを使って解答しましょう。")}
      </BaseDialog>
    </StyledBox>
  );
};

LockedHint.displayName = "LockedHint";

export default LockedHint;
