import { state } from "./State";
import type * as Types from "./types";

export const setRedirectUrlAfterSignIn = (redirectUrl: string) => {
  state.redirectUrlAfterSignIn = redirectUrl;
};

export const setAfterResetPasswordPayload = (payload: Partial<Types.AfterResetPasswordPayload>): void => {
  state.afterResetPasswordPayload = {
    ...state.afterResetPasswordPayload,
    ...payload,
  };
};

export const setFirebaseAuthStatusCode = (errorCode: Types.FirebaseAuthStatusCode) => {
  state.firebaseAuthStatusCode = errorCode;
};

export const updateSignInStatus = (status: Types.SignInStatus) => {
  state.signInStatus = status;
};
