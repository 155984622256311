import { RemoteInterviewFeedbackStore } from "@hireroo/app-store/view-domain/RemoteInterviewFeedback";
import * as TimeFormatter from "@hireroo/formatter/time";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";

export type GenerateRemoteInterviewFeedbackPropsArgs = {};
type Item = Widget.RemoteInterviewFeedbackProps["feedbacks"][0]["items"][0];

export const useGenerateProps = (_args: GenerateRemoteInterviewFeedbackPropsArgs): Widget.RemoteInterviewFeedbackProps => {
  const { t } = useTranslation();
  const interview = RemoteInterviewFeedbackStore.useInterview();
  return {
    emptyMessage: t("技術評価が未入力です。テストレポートの評価ボタンから問題の評価を行えます。"),
    feedbacks: interview.recommendations.map(recommendation => {
      return {
        id: recommendation.id,
        reviewer: {
          displayName: recommendation.reviewer.displayName || recommendation.reviewer.email,
          photoUrl: recommendation.reviewer.photoUrl,
        },
        reviewedAt: recommendation.createdAtSeconds ? TimeFormatter.unixToDateFormat(recommendation.createdAtSeconds) : "",
        items: recommendation.evaluations.map(
          (evaluation): Item => ({
            id: evaluation.id,
            title: evaluation.metric?.title ?? "",
            evaluation: {
              numStars: evaluation.numStars,
              comment: evaluation.comment,
            },
          }),
        ),
        status: recommendation.isRecommended ? "RECOMMENDED" : "NOT_RECOMMENDED",
        comment: recommendation.comment,
      };
    }),
  };
};
