import type { Pages } from "@hireroo/presentation";

import SnackbarContainer from "../../../widget/v2/shared/Snackbar/Container";
import ResetPasswordContainer from "./widgets/ResetPassword/Container";

export type GenerateResetPasswordPropsArgs = {};

export const useGenerateProps = (_args: GenerateResetPasswordPropsArgs): Pages.SignInSignUpProps => {
  return {
    layout: {
      Bottom: <SnackbarContainer />,
    },
    children: <ResetPasswordContainer />,
  };
};
