import { useTranslation } from "@hireroo/i18n";
import { AssessmentResourceEditorForm } from "@hireroo/validator";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import * as React from "react";

import ShareSettingsItem, { ShareSettingsItemProps } from "./parts/ShareSettingsItem/ShareSettingsItem";

const ReservedField = {
  ANSWER: "showAnswer",
  PLAYBACK: "showPlayback",
  RELATIVE_EVALUATION: "showRelativeEvaluation",
} satisfies Record<string, keyof AssessmentResourceEditorForm.AssessmentShareSettingsFormSchema>;

const joinName = <T extends string>(prefix: string | undefined, name: T): T => {
  return (prefix ? prefix + "." + name : name) as T;
};

export type AssessmentReportShareSettingFieldProps = {
  /**
   * @example htmlName will be "${namePrefix}.showScore"
   *
   * "." is not required
   */
  namePrefix?: string;
};

/**
 * This component contains the name defined in `AssessmentsShareSettingsFormSchema`.
 */
const AssessmentReportShareSettingField: React.FC<AssessmentReportShareSettingFieldProps> = props => {
  const { t } = useTranslation();
  const items: ShareSettingsItemProps[] = [
    {
      id: "relative-evaluation",
      name: joinName(props.namePrefix, ReservedField.RELATIVE_EVALUATION),
      label: t("相対評価"),
    },
    {
      id: "playback",
      name: joinName(props.namePrefix, ReservedField.PLAYBACK),
      label: t("プレイバック"),
    },
    {
      id: "answer",
      name: joinName(props.namePrefix, ReservedField.ANSWER),
      label: t("解答"),
    },
  ];
  return (
    <Stack>
      <FormControl component="fieldset" variant="standard">
        <FormGroup>
          <Stack direction="row" spacing={2}>
            {items.map(item => (
              <ShareSettingsItem key={item.id} {...item} />
            ))}
          </Stack>
        </FormGroup>
      </FormControl>
    </Stack>
  );
};

AssessmentReportShareSettingField.displayName = "AssessmentReportShareSettingField";

export default AssessmentReportShareSettingField;
