import * as Def from "@hireroo/app-definition/errors";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { useHelpCenterNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/browser";
import type { GraphQLError } from "graphql";
import * as React from "react";

export type GenerateErrorPanelPropsArgsByServiceErrorCode = {
  graphqlErrors: GraphQLError[];
  isSignInUser?: boolean;
};

export type CustomErrorProps = {
  graphqlErrors?: undefined;
  title: string;
  message: string;
  additionalMessage?: string;
};

export type GenerateErrorPanelPropsArgs = GenerateErrorPanelPropsArgsByServiceErrorCode | CustomErrorProps;

export const useGenerateProps = (args: GenerateErrorPanelPropsArgs): Widget.ErrorPanelProps => {
  const { t } = useTranslation();
  const languageCode = useLanguageCode();
  const helpCenterNavigate = useHelpCenterNavigate(languageCode);
  const detailErrorMessageMap = Def.useDetailErrorCodeMap("FOR_CANDIDATE");
  const serverErrorMessageMap = Def.useServerErrorMessageMap();
  const links: Widget.ErrorPanelProps["links"] = [
    {
      children: t("ヘルプセンター"),
      onClick: () => {
        helpCenterNavigate("CANDIDATE_HELP_PAGE", { _blank: true });
      },
    },
  ];

  React.useEffect(() => {
    if (args.graphqlErrors) {
      const errorCodes = args.graphqlErrors
        .map(graphqlError => `${graphqlError.extensions.code}/${graphqlError.extensions.detailCode}`)
        .join(",");
      Sentry.captureMessage(`GraphQL Errors: ${errorCodes}`);
    }
  }, [args.graphqlErrors]);

  if (args.graphqlErrors) {
    const errors = args.graphqlErrors.map(graphQLError => {
      return {
        serverErrorCode: graphQLError.extensions.code,
        detailErrorCode: graphQLError.extensions.detailCode,
      };
    });
    const errorCode: Def.ErrorObject = errors[0] || {
      serverErrorCode: "UNKNOWN",
    };
    // TODO we should determine showing error priority.
    if (errorCode.detailErrorCode) {
      return {
        ...detailErrorMessageMap[errorCode.detailErrorCode],
        links,
      };
    }
    return {
      ...serverErrorMessageMap[errorCode.serverErrorCode],
      links,
    };
  }

  return {
    name: args.title,
    message: args.message,
    additionalMessage: args.additionalMessage,
    links: links,
  };
};
