import { useQuizAccessEvents } from "@hireroo/app-helper/hooks";
import { QuizTestReport } from "@hireroo/app-store/view-domain/QuizTestReport";
import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import * as React from "react";

import { extractDuplicateAccessEvents } from "./privateHelper";

type QuizCheatDetectionProps = Exclude<Widget.QuizTestReportProps["cheatDetectionSection"], undefined>;
type IpAddressSection = QuizCheatDetectionProps["ipAddressSection"];
type CustomStepLabelProps = Exclude<QuizCheatDetectionProps["ipAddressSection"], null>["firstAccessInfo"];

export type GenerateIpAddressSectionArgs = {
  quizId: number;
  packageId: number;
};

export const useGenerateIpAddressSectionProps = (
  args: GenerateIpAddressSectionArgs,
): (IpAddressSection & { totalNumAccess: number }) | null => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const hooks = QuizTestReport.useCreateQuizHooks(args.quizId);
  const submissions = hooks.useSubmissions();
  const { accessEventMap, accessEventsReady } = useQuizAccessEvents({
    quizId: args.quizId,
    packageId: args.packageId,
    questionIds: submissions.map(submission => submission.questionId),
  });

  /** totalNumAccess was added because this is now fetched and calculated from firebase */
  const ipAddressSection: (IpAddressSection & { totalNumAccess: number }) | null = React.useMemo(() => {
    if (accessEventsReady) {
      const filteredAccessEvents = extractDuplicateAccessEvents(accessEventMap);
      if (filteredAccessEvents.length === 0) {
        /**
         * If IP address is not detected, show this message.
         * TODO: Update this message when the issue with browser extension is fixed
         */
        const firstAccessInfo: CustomStepLabelProps = {
          mark: "WARNING",
          label: t(
            "IPアドレスが検知されませんでした。受験者が使っているブラウザの拡張機能によって位置情報を取得するために必要なリクエストがブロックされた可能性があります。",
          ),
        };
        return {
          firstAccessInfo,
          extraAccessInfos: [],
          totalNumAccess: 0,
        };
      }

      const [firstAccess, ...remainingAccess] = filteredAccessEvents;
      const { event } = firstAccess;
      const d = new Date(event.t);
      const date = d.toLocaleString();

      /** first access custom step label */
      const firstAccessInfo: CustomStepLabelProps = {
        mark: "COMPUTER",
        label: t2("IpAddressDetectionQuizLabel1", { ipAddress: event.l, geolocation: event.g }),
        date: date,
      };
      /** access custom step label for accesses after the first */
      const extraAccessInfos: Array<CustomStepLabelProps> = remainingAccess.map(accessEvent => {
        const { questionId, event } = accessEvent;
        const d = new Date(event.t);
        const date = d.toLocaleString();

        return {
          mark: "WARNING",
          label: t2("IpAddressDetectionQuizLabel2", {
            ipAddress: event.l,
            geolocation: event.g,
            questionId,
          }),
          date: date,
        };
      });

      return {
        firstAccessInfo,
        extraAccessInfos,
        totalNumAccess: filteredAccessEvents.length,
      };
    }
    return null;
  }, [accessEventMap, accessEventsReady, t, t2]);

  return ipAddressSection;
};
