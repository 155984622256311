import { ProjectCodingEditorV3 } from "@hireroo/app-store/widget/shared/ProjectCodingEditorV3";
import * as Graphql from "@hireroo/graphql/client/urql";
import * as Sentry from "@sentry/react";
import * as React from "react";

export type StartWorkspaceArgs = {
  entityId: number;
  questionId: number;
  questionVersion: string;
  submissionId: number;
};

export type StartWorkspaceErrorStatus = "PENDING" | "ERROR";

export const useStartEvaluationWorkspace = (args: StartWorkspaceArgs) => {
  const [startEvaluationWorkspaceResult, startEvaluationWorkspace] = Graphql.useStartEvaluationWorkspaceForProjectCodingEditorMutation();
  const { setWorkspace } = ProjectCodingEditorV3.createProjectEntityAction(args.entityId);
  const { useWorkspace } = ProjectCodingEditorV3.useCreateProjectEntityHooks(args.entityId);
  const workspace = useWorkspace();

  React.useEffect(() => {
    if (workspace) {
      return;
    }
    if (startEvaluationWorkspaceResult.data || startEvaluationWorkspaceResult.fetching) {
      return;
    }
    startEvaluationWorkspace({
      input: {
        submissionId: args.submissionId,
      },
    })
      .then(res => {
        if (res.data) {
          setWorkspace(res.data.startEvaluationWorkspace);
        }
      })
      .catch(error => {
        Sentry.captureException(error);
      });
  }, [
    args.submissionId,
    setWorkspace,
    startEvaluationWorkspace,
    startEvaluationWorkspaceResult.data,
    startEvaluationWorkspaceResult.fetching,
    workspace,
  ]);

  return {
    isStartWorkspaceLoading: startEvaluationWorkspaceResult.fetching,
    startWorkspaceError: startEvaluationWorkspaceResult.error === undefined ? "PENDING" : "ERROR",
  };
};
