import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";

import TablePagination, { TablePaginationProps } from "../../../modules/TablePagination/TablePagination";
import AssessmentCard, { AssessmentCardProps } from "../AssessmentCard/AssessmentCard";

export type AssessmentCardListProps = {
  items: AssessmentCardProps[];
  tablePagination: TablePaginationProps;
};

const AssessmentCardList: React.FC<AssessmentCardListProps> = props => {
  const { t } = useTranslation();
  const PaginationComponent = (
    <Box display="flex" justifyContent="flex-end">
      <TablePagination {...props.tablePagination} />
    </Box>
  );
  if (props.items.length === 0) {
    return (
      <Box>
        <Box>
          <Typography textAlign="center" noWrap>
            {t("表示可能なタレントスコアはありません。")}
          </Typography>
        </Box>
        {PaginationComponent}
      </Box>
    );
  }
  return (
    <Box>
      <Grid container spacing={2}>
        {props.items.map((item, index) => (
          <Grid key={index.toString()} item>
            <AssessmentCard {...item} />
          </Grid>
        ))}
      </Grid>
      {PaginationComponent}
    </Box>
  );
};

AssessmentCardList.displayName = "AssessmentCardList";

export default AssessmentCardList;
