import type { CollaborativeAction, CollaborativeState } from "@hireroo/app-helper/hooks";
import { useSystemDesignRevisions } from "@hireroo/app-helper/hooks";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { SystemDesignCodingEditor } from "@hireroo/app-store/widget/shared/SystemDesignCodingEditor";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import type * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { useTranslationWithVariable } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import { generateHelpCenterUrl } from "@hireroo/router/api";
import { LATEST_VERSION } from "@hireroo/system-design/constants/flowChart";
import { FontSizeMap, OPERATION_TYPE } from "@hireroo/system-design/features";
import { elementsFromFirebase, parseFlowChartSnapshot } from "@hireroo/system-design/helpers/flowChart";
import { ELEMENT_TYPE, Helpers, LabelTypeMap, useSystemDesignContext } from "@hireroo/system-design/react/FlowChart";
import * as React from "react";

import { useGenerateAppealMessageProps } from "./useGenerateAppealMessageProps";
import { generateFlowChartProps, PlacingElementLabel } from "./useGenerateDrawAreaProps";

type QuestionHintStack = Widget.SystemDesignCodingEditorProps["sidebar"]["questionHintStack"];
type QuestionHintItem = Exclude<QuestionHintStack, undefined>["items"][0];
type TestCaseRow = Widget.SystemDesignCodingEditorProps["flowChart"]["testCaseDialog"]["items"][0];

export type ShowingOption = "HIDE_APPEAL_MESSAGE" | "SHOW_ALL_HINTS" | "DISABLE_SUBMIT";

export type GenerateSystemDesignCodingEditorPropsArgs = {
  showingOptions: ShowingOption[];
  collaborativeState: CollaborativeState;
  collaborativeAction: CollaborativeAction;
  entityId: number;
};

export const useGenerateProps = (args: GenerateSystemDesignCodingEditorPropsArgs): Widget.SystemDesignCodingEditorProps => {
  const lang = useLanguageCode();
  const { collaborativeAction, collaborativeState } = args;
  const { t } = useTranslation();
  const Store = useSystemDesignContext();
  const client = getGraphqlClient();
  const entityHooks = SystemDesignCodingEditor.useCreateSystemDesignEntityHooks(args.entityId);
  const entityAction = SystemDesignCodingEditor.createSystemDesignEntityAction(args.entityId);
  const question = entityHooks.useQuestion();
  const testCaseResult = entityHooks.useTestCase();
  const enabledHint = entityHooks.useEnabledHint();
  const [submittedStatus, setSubmitStatus] = React.useState<"READY" | "SUBMITTING">("READY");
  const [testRunningStatuses, setTestRunningStatuses] = React.useState<Record<number, "READY" | "RUNNING">>(
    (question?.scoringItems || []).reduce<Record<number, "READY" | "RUNNING">>((all, item) => {
      return {
        ...all,
        [item.systemDesignScoringItemId]: "READY",
      };
    }, {}),
  );
  const { t: t2 } = useTranslationWithVariable();

  const scoringItemCategoryMap: Record<Graphql.SystemDesignScoringItemCategory, string> = {
    AVAILABILITY: t("可用性"),
    SCALABILITY: t("スケーラビリティ"),
    CONSISTENCY: t("一貫性"),
    UNKNOWN: "",
  };
  // Placing element related states
  const [isPlacingElement, setIsPlacingElement] = React.useState<boolean>(false);
  const [placingElementLabel, setPlacingElementLabel] = React.useState<PlacingElementLabel | undefined>(undefined);
  const elementsList = Store.hooks.useElementsList();
  const viewbox = Store.hooks.useCachedViewbox();
  const viewboxCenter = React.useMemo(() => {
    return { x: (viewbox.minX + viewbox.maxX) / 2, y: (viewbox.minY + viewbox.maxY) / 2 };
  }, [viewbox.maxX, viewbox.maxY, viewbox.minX, viewbox.minY]);
  const handleDragOver = React.useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  }, []);
  const handleDrop = React.useCallback((e: React.DragEvent) => {
    e.preventDefault();
    setIsPlacingElement(false);
  }, []);
  const componentType = Store.hooks.useComponentType();
  const selectedElementId = Store.hooks.useSelectedElementId();
  const [_, histories, revisionsReady] = useSystemDesignRevisions(
    args.entityId,
    question?.questionId ?? 0,
    collaborativeState.selectedComponentType,
    LATEST_VERSION,
  );
  const flowChartSnapshot = React.useMemo(() => {
    return JSON.stringify({ version: LATEST_VERSION, elements: elementsList, componentType: collaborativeState.selectedComponentType });
  }, [collaborativeState.selectedComponentType, elementsList]);
  React.useEffect(() => {
    if (!revisionsReady) return;
    const { result } = parseFlowChartSnapshot(question?.initialSnapshot ?? "");
    const { elements, elementIds } = elementsFromFirebase(histories, collaborativeState.selectedComponentType, result.elements);
    Store.action.restoreElements({ elements, elementIds });
  }, [revisionsReady, histories, collaborativeState.selectedComponentType, Store.action, question?.initialSnapshot]);
  React.useEffect(() => {
    Store.action.updateComponentType(collaborativeState.selectedComponentType);
  }, [Store.action, collaborativeState.selectedComponentType]);
  const appealMessageProps = useGenerateAppealMessageProps({
    entityId: args.entityId,
    hideAppealMessage: args.showingOptions.includes("HIDE_APPEAL_MESSAGE"),
  });

  const questionHintStack: QuestionHintStack = enabledHint
    ? {
        items: (question?.hints || []).map((hint, index): QuestionHintItem => {
          if (args.showingOptions.includes("SHOW_ALL_HINTS") || collaborativeState.usedHints.has(hint.systemDesignHintId)) {
            return {
              mode: "unlocked",
              value: {
                disabled: false,
                title: t2("Hint", { num: index + 1 }),
                description: resolveLanguage(hint, lang, "description"),
              },
            };
          }

          return {
            mode: "locked",
            value: {
              disabled: false,
              title: t2("Hint", { num: index + 1 }),
              onUnlocked: () => {
                collaborativeAction.useHint(hint.systemDesignHintId);
              },
            },
          };
        }),
      }
    : undefined;

  return {
    sidebar: {
      questionSection: {
        title: resolveLanguage(question || {}, lang, "title"),
        description: resolveLanguage(question || {}, lang, "description"),
        /**
         * Always `false` as the user does not need to know if the issue has been archived during testing
         */
        isArchived: false,
      },
      questionHintStack: questionHintStack,
      appealMessage: appealMessageProps,
    },
    flowChart: {
      flowChartSidebar: {
        componentTypeSelector: {
          onChange: selectedComponentType => {
            collaborativeAction.selectComponentType(selectedComponentType);
          },
        },
        onClickElement: label => {
          const hashId = Helpers.generateHashId();
          if (label === "text") {
            collaborativeAction.addComment(
              hashId,
              t("ダブルクリックでテキストを編集できます。"),
              viewboxCenter.x - 240,
              viewboxCenter.y - 20,
              FontSizeMap["medium"],
              OPERATION_TYPE.do,
            );
          } else {
            const type = LabelTypeMap[label];
            const { w, h } = Helpers.getInitialWidthAndHeight(label);
            const settings = Helpers.getInitialSettings(label, componentType, lang);
            if (LabelTypeMap[label] === ELEMENT_TYPE.node) {
              collaborativeAction.addElement(
                hashId,
                type,
                label,
                viewboxCenter.x - 80,
                viewboxCenter.y - 80,
                w,
                h,
                settings,
                OPERATION_TYPE.do,
              );
            }
            if (LabelTypeMap[label] === ELEMENT_TYPE.network) {
              collaborativeAction.addElement(
                hashId,
                type,
                label,
                viewboxCenter.x - 320,
                viewboxCenter.y - 320,
                w,
                h,
                settings,
                OPERATION_TYPE.do,
              );
            }
          }
        },
        onDragElementStart: (e, label) => {
          e.stopPropagation();
          setIsPlacingElement(true);
          setPlacingElementLabel(label);

          e.dataTransfer.setData("application/flowchart", label);
          e.dataTransfer.effectAllowed = "move";
        },
        helpCenterUrl: generateHelpCenterUrl(lang, "DRAWING_TOOL"),
      },
      flowChart: {
        drawingArea: generateFlowChartProps({
          snapshot: question?.initialSnapshot || "",
          fontSize: "medium",
          isPlacingElement: isPlacingElement,
          placingElementLabel: placingElementLabel,
          onDragOver: handleDragOver,
          onDrop: handleDrop,
          collaborativeState,
          collaborativeAction,
        }),
        elementSettings: selectedElementId
          ? {
              elementId: selectedElementId,
              componentType: componentType,
              updateSettings: (id, updates) => {
                collaborativeAction.updateSettings(id, updates, OPERATION_TYPE.do);
              },
              deleteElementButton: {
                onClick: () => {
                  if (selectedElementId) {
                    collaborativeAction.deleteElements([selectedElementId], "do");
                  }
                },
              },
              closeButton: {
                onClick: () => {
                  Store.action.unselectElements();
                },
              },
            }
          : undefined,
        editControlPanel: {
          submitButton: {
            loading: submittedStatus === "SUBMITTING",
            disabled: args.showingOptions.includes("DISABLE_SUBMIT") || submittedStatus === "SUBMITTING",
            onClick: () => {
              setSubmitStatus("SUBMITTING");
              client
                .EnqueueSystemDesignSubmission({
                  systemDesignId: args.entityId,
                  snapshot: flowChartSnapshot,
                  questionId: question?.questionId ?? 0,
                })
                .then(() => {
                  Snackbar.notify({
                    severity: "success",
                    message: t("お疲れ様でした！未提出の問題がある場合は次に進んでください。"),
                  });
                  collaborativeAction.submitQuestion();
                  SystemDesignCodingEditor.setSubmittedEntity(args.entityId);
                })
                .catch(() => {
                  Snackbar.notify({
                    severity: "error",
                    message: t("問題の提出に失敗しました。再度お試しください。"),
                  });
                })
                .finally(() => {
                  setSubmitStatus("READY");
                });
            },
          },
        },
        resetControl: {
          onResetFlowChart: () => {
            collaborativeAction.resetElements();
          },
        },
        redoUndoGroup: {
          canUndo: collaborativeState.canUndo,
          canRedo: collaborativeState.canRedo,
          undoButton: {
            onClick: () => {
              collaborativeAction.undo();
            },
          },
          redoButton: {
            onClick: () => {
              collaborativeAction.redo();
            },
          },
        },
      },
      testCaseDialog: {
        dialog: {
          onClose: () => {
            entityAction.clearTestcaseResult();
          },
        },
        items: (question?.scoringItems || [])
          .filter(item => !item.isHidden)
          .map((scoringItem, index): TestCaseRow => {
            const result = testCaseResult?.get(scoringItem.systemDesignScoringItemId);
            const parsedFlowChart = parseFlowChartSnapshot(flowChartSnapshot);
            return {
              open: !!result,
              staticFlowChart:
                result && result.isPassed
                  ? {
                      elements: parsedFlowChart.result.elements,
                      componentType: parsedFlowChart.result.componentType,
                      matchedIds: result.matchedIds,
                    }
                  : undefined,
              status: (() => {
                // TODO: refactor as one state
                if (testRunningStatuses[scoringItem.systemDesignScoringItemId] === "RUNNING") {
                  return "RUNNING";
                }
                if (!result) {
                  return "UNCHECK";
                }
                if (result.isPassed) {
                  return "SUCCESS";
                }
                return "FAIL";
              })(),
              message: resolveLanguage(result ?? {}, lang, "message", { ja: "", en: "" }),
              title: [t("テストケース"), index + 1, scoringItemCategoryMap[scoringItem.category]].join(" "),
              runButton: {
                onClick: () => {
                  setTestRunningStatuses(prev => {
                    return { ...prev, [scoringItem.systemDesignScoringItemId]: "RUNNING" };
                  });
                  client
                    .RunSystemDesign({
                      systemDesignId: args.entityId,
                      snapshot: flowChartSnapshot,
                      scoringItemId: scoringItem.systemDesignScoringItemId,
                      takeSnapshot: true,
                    })
                    .then(res => {
                      collaborativeAction.runTestcase(res.runSystemDesign.snapshotId);
                      entityAction.setTestcaseResult(scoringItem.systemDesignScoringItemId, res.runSystemDesign);
                      Snackbar.notify({
                        severity: "success",
                        message: t("実行が完了しました。結果を確認してください。"),
                      });
                    })
                    .catch(() => {
                      Snackbar.notify({
                        severity: "error",
                        message: t("実行に失敗しました。サーバーが起動しているか確認してください。"),
                      });
                    })
                    .finally(() => {
                      setTestRunningStatuses(prev => {
                        return { ...prev, [scoringItem.systemDesignScoringItemId]: "READY" };
                      });
                    });
                },
              },
            };
          }),
      },
    },
  };
};
