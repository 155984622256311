import { AlgorithmSignatureForm } from "@hireroo/validator";
import { ArrowDropDown, ArrowForwardIos } from "@mui/icons-material";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Slide from "@mui/material/Slide/Slide";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FieldPath } from "react-hook-form";

import InputControlTextField, { InputControlTextFieldProps } from "../../../primitive/InputControl/InputControlTextField";
import { useAlgorithmFunctionDefinitionContext } from "../Context";

const typeLabelMap: Record<string, string> = {
  string: "String",
  int: "Integer",
  float: "Float",
  bool: "Boolean",
  sll: "Single Linked List",
  bt: "Binary Tree",
  array: "Array",
  // TODO: add map
};

const nonPrimitiveType: Record<string, string> = {
  array: "array",
  map: "map",
};

const formatTypeString = (t: string, added: string): string => {
  if (t === "") {
    if (added === "array") {
      return "array[]";
    }

    return added;
  }

  if (t.startsWith("array") || t.startsWith("map")) {
    if (t.startsWith("array")) {
      const s = t.indexOf("[");
      const e = t.lastIndexOf("]");
      return `array[${formatTypeString(t.substring(s + 1, e), added)}]`;
    }
    // TODO: add map process when map is implemented
  }

  return t;
};

export type FunctionTypeSelectProps = {
  value: string;
  name: FieldPath<AlgorithmSignatureForm.AlgorithmSignatureFormSchema>;
  label: string;

  hasNameFieldError?: boolean;
};

const FunctionTypeSelect: React.FC<FunctionTypeSelectProps> = props => {
  const [steps, setSteps] = React.useState<boolean[]>([true]);
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [signature, setSignature] = React.useState<string>(props.value);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { methods } = useAlgorithmFunctionDefinitionContext();

  const handleClose = (lastSelected?: string) => {
    // 最後に選択した型が、プリミティブ型でなければ値を更新しない。
    if (lastSelected) {
      methods.setValue(props.name, lastSelected);
    }
    setAnchorEl(null);
  };

  const handleListItemClick = (type: string) => {
    // activeStep = 0のときは、初回選択時のため空文字
    const formatted = formatTypeString(activeStep === 0 ? "" : signature, type);
    setSignature(formatted);

    if (type === "array") {
      setSteps(draft => draft.concat(true));
      setActiveStep((prev: number) => prev + 1);
    } else {
      setSteps([true]);
      setActiveStep(0);
      handleClose(formatted);
    }
  };

  const typeTextField: InputControlTextFieldProps = {
    label: props.label,
    control: methods.control,
    type: "text",
    color: "primary",
    fullWidth: true,
    variant: "outlined",
    helperText: props.hasNameFieldError ? " " : undefined,
    onClick: event => {
      setAnchorEl(event.currentTarget);
    },
    InputLabelProps: {
      shrink: true,
    },
    sx: {
      cursor: "pointer",
    },
    InputProps: {
      readOnly: true,
      endAdornment: (
        <InputAdornment position="end">
          <ArrowDropDown />
        </InputAdornment>
      ),
    },
  };

  return (
    <Box width="100%">
      <InputControlTextField name={props.name} {...typeTextField} />

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {steps.map(
          (step, i) =>
            i === activeStep && (
              <Slide key={i} direction="left" mountOnEnter unmountOnExit in={i === activeStep} appear={i !== 0}>
                <Box>
                  {Object.keys(typeLabelMap).map((type: string) => (
                    <MenuItem key={`${i}-${type}`} value={type} onClick={() => handleListItemClick(type)}>
                      {type !== nonPrimitiveType[type] ? (
                        <Typography variant="subtitle1">{typeLabelMap[type]}</Typography>
                      ) : (
                        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                          <Typography variant="subtitle1">{typeLabelMap[type]}</Typography>
                          <Box display="flex">
                            <ArrowForwardIos sx={{ fontSize: "0.8rem" }} />
                          </Box>
                        </Box>
                      )}
                    </MenuItem>
                  ))}
                </Box>
              </Slide>
            ),
        )}
      </Menu>
    </Box>
  );
};

FunctionTypeSelect.displayName = "FunctionTypeSelect";

export default FunctionTypeSelect;
