import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import * as React from "react";

import PreferencesMenu, { PreferencesMenuProps } from "../../modules/PreferencesMenu/PreferencesMenu";
import SymbolButton, { SymbolButtonProps } from "../../primitive/Button/SymbolButton/SymbolButton";

export type TalentHeaderRightContentProps = {
  Menu: React.ReactNode;
  helpButton: SymbolButtonProps;
  preferences: PreferencesMenuProps;
  NotificationButton: React.ReactNode;
};

const TalentHeaderRightContent: React.FC<TalentHeaderRightContentProps> = props => {
  const helpButton: SymbolButtonProps = {
    ...props.helpButton,
    children: <HelpOutlineIcon />,
    color: "secondary",
  };
  return (
    <Box>
      <Stack direction="row" spacing={1} width="100%" justifyContent="flex-end" alignItems="center">
        {props.NotificationButton}
        <PreferencesMenu {...props.preferences} />
        <SymbolButton {...helpButton} />
        {props.Menu}
      </Stack>
    </Box>
  );
};

TalentHeaderRightContent.displayName = "TalentHeaderRightContent";

export default TalentHeaderRightContent;
