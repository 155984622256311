import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import * as Subscriber from "./Subscriber";
import { GenerateProjectContentsViewerV1PropsArgs, useGenerateProps } from "./useGenerateProps";

export type ProjectContentsViewerV1ContainerProps = GenerateProjectContentsViewerV1PropsArgs;

const ProjectContentsViewerV1Container: React.FC<ProjectContentsViewerV1ContainerProps> = props => {
  const initialized = ProjectTestReport.ProjectContentsViewerV1.useInitialized(props.projectId);
  const action = ProjectTestReport.ProjectContentsViewerV1.createAction(props.projectId);
  const { useSourceFileFetchStatus: useDiffFileFetchStatus } = ProjectTestReport.ProjectContentsViewerV1.createHooks(props.projectId);
  const status = useDiffFileFetchStatus();
  const projectContentsViewerV1Props = useGenerateProps(props);
  React.useEffect(() => {
    const stop = Subscriber.startSubscribeHistoryCurrentSubmission(props.projectId);
    return () => {
      stop();
    };
  }, [props.projectId]);
  React.useEffect(() => {
    if (status === "BEFORE_FETCH") {
      action.setSourceFileFetchStatus("CAN_FETCH");
    }
  }, [action, status]);
  if (!initialized) {
    return <Widget.Loading kind="TOP" />;
  }
  return (
    <ErrorBoundary>
      <Widget.ProjectContentsViewerV1 {...projectContentsViewerV1Props} />
    </ErrorBoundary>
  );
};

ProjectContentsViewerV1Container.displayName = "ProjectContentsViewerV1Container";

export default withErrorBoundary(ProjectContentsViewerV1Container, {});
