import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const Wrapper = styled(Box)(() => ({
  display: "inline-flex",
  flexDirection: "row",
  alignItems: "center",
}));

const NormalTypography = styled(Typography)(() => ({
  wordBreak: "keep-all",
  whiteSpace: "nowrap",
  flexGrow: 1,
  flexShrink: 0,
  fontSize: 16,
  lineHeight: "24px",
}));

type Color = "PINK" | "BLUE" | "GREEN";

const TooltipTypography = styled(Typography)(({ theme }) => ({
  height: 16,
  fontSize: 10,

  borderRadius: 2,
  padding: "1px 4px",
  display: "inline-block",
  flexGrow: 1,
  flexShrink: 0,
  marginLeft: "8px",
  lineHeight: 1.4,
  fontWeight: 700,
  '&[data-color="PINK"]': {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.Pink[400],
  },
  '&[data-color="BLUE"]': {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.LightBlue[400],
  },
  '&[data-color="GREEN"]': {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.Green[400],
  },
}));

export type LabelProps = {
  text: string;
  suffix?: {
    text: string;
    color: Color;
  };
};

const Label: React.FC<LabelProps> = props => {
  return (
    <Wrapper>
      <NormalTypography>{props.text}</NormalTypography>
      {props.suffix && <TooltipTypography data-color={props.suffix.color}>{props.suffix.text}</TooltipTypography>}
    </Wrapper>
  );
};

Label.displayName = "Label";

export default Label;
