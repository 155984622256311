import UsageBarGraph, { UsageBarGraphProps } from "@hireroo/charts/react/v2/UsageBarGraph";
import { useTranslation } from "@hireroo/i18n";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import Link, { LinkProps } from "../../../../primitive/Link/Link";

const Wrapper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: 400,
}));

export type UsageBarGraphSectionProps = {
  usageBarGraph: UsageBarGraphProps;
  link: Pick<LinkProps, "href" | "onClick">;
};

const UsageBarGraphSection: React.FC<UsageBarGraphSectionProps> = props => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box display="flex" flexDirection="row">
        <Typography variant="body2" fontWeight="bold" mb={1.5} mr={1}>
          {t("使用量")}
        </Typography>
        <IconButton component={Link} {...props.link} color="secondary" sx={{ height: 20, width: 20, position: "relative", top: 1 }}>
          <KeyboardArrowRightOutlinedIcon />
        </IconButton>
      </Box>
      <Wrapper elevation={4}>
        <React.Suspense>
          <UsageBarGraph {...props.usageBarGraph} />
        </React.Suspense>
      </Wrapper>
    </Box>
  );
};

UsageBarGraphSection.displayName = "UsageBarGraphSection";

export default UsageBarGraphSection;
