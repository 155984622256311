import { EditorCopyEvent, EditorCutEvent, EditorPasteEvent } from "@hireroo/app-helper/playback";
import { getTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import { AlgorithmTestCase } from "@hireroo/validator";

export type ClipboardEvent = EditorCopyEvent | EditorCutEvent | EditorPasteEvent;

type PerformanceSection = Exclude<Widget.ChallengeTestReportProps["performanceSection"], undefined>;

export const adapterForLegacyPerformanceResult = (
  testCase: AlgorithmTestCase.PerformanceTestCaseResultsSchema,
): PerformanceSection["graph"] => {
  const { t } = getTranslation();
  return {
    version: "V1",
    data: {
      lines: [
        {
          dataKey: "submission_stats.performance_avg",
          name: t("提出コード"),
          color: "#87cede",
        },
        {
          dataKey: "optimal_stats.performance_avg",
          name: t("最適解"),
          color: "#7fa46c",
        },
        ...((testCase.at(0)?.sub_optimal_stats !== undefined && [
          {
            dataKey: "sub_optimal_stats.performance_avg",
            name: t("標準解"),
            color: "#d2ad4d",
          },
        ]) ||
          []),
        ...((testCase.at(0)?.brute_force_stats !== undefined && [
          {
            dataKey: "brute_force_stats.performance_avg",
            name: t("愚直解"),
            color: "#e18a4c",
          },
        ]) ||
          []),
      ],
      xAxisLabelKey: "label",
      data: testCase,
    },
  };
};

export const adapterForPerformanceResult = (
  testCase: AlgorithmTestCase.PerformanceTestCaseResultV2Schema,
  performanceTimeLimitMilliSeconds?: number,
): PerformanceSection["graph"] => {
  const { t } = getTranslation();

  return {
    version: "V2",
    data: {
      data: testCase.result.map(value => {
        return {
          label: value.label,
          isPassed: value.is_passed,
          isCorrect: value.is_correct,
          min: value.performance.min,
          max: value.performance.max,
          med: value.performance.med,
          p25: value.performance.p25,
          p75: value.performance.p75,
          avg: value.performance.avg,
          plots: value.performance.data.map(v => v),
        };
      }),
      line: performanceTimeLimitMilliSeconds
        ? {
            data: performanceTimeLimitMilliSeconds * 1000 * 1000,
            label: t("タイムアウト"),
          }
        : undefined,
    },
  };
};
