import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";

import { useGenerateProps } from "./useGenerateProps";

export type UserSettingsContainerProps = {};

const UserSettingsContainer = () => {
  const props = useGenerateProps();
  return (
    <ErrorBoundary>
      <Pages.UserSettingsForTalent {...props} />
    </ErrorBoundary>
  );
};

export default withErrorBoundary(UserSettingsContainer, {});
