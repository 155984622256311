import { useTranslation } from "@hireroo/i18n";
import OpenInNew from "@mui/icons-material/OpenInNew";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";

import Link, { LinkProps } from "../../../primitive/Link/Link";

export type ReportProjectWorkspaceNotificationSectionProps = {
  url: string;
  singleMessage?: {
    text: string;
  };
  additionalMessages: string[];
  helpLink: Pick<LinkProps, "onClick">;
};

const ReportProjectWorkspaceNotificationSection: React.FC<ReportProjectWorkspaceNotificationSectionProps> = props => {
  const { t } = useTranslation();
  if (props.singleMessage) {
    return (
      <Box>
        <Typography>{props.singleMessage.text}</Typography>
      </Box>
    );
  }

  return (
    <Box>
      {props.additionalMessages.length > 0 && (
        <Box>
          <Box>
            {props.additionalMessages.map((message, index) => {
              return <Typography key={`message-${index}`}>{message}</Typography>;
            })}
          </Box>
          <Box textAlign="right" my={1}>
            <Typography variant="caption">
              <Link {...props.helpLink} underline={"hover"}>{`${t("実践形式の評価時のエラーについて")}`}</Link>
            </Typography>
          </Box>
        </Box>
      )}
      <Link underline="hover" href={props.url} target="_blank" color="secondary">
        <Box display="flex" alignItems="center" flexWrap="wrap" mt={4}>
          <Typography color="secondary" mr={1}>
            {t("開発環境にアクセスする")}
          </Typography>
          <OpenInNew fontSize="small" color="secondary" />
        </Box>
      </Link>
      <Box mt={1} />
      <Typography variant={"subtitle2"}>{t("受験者の開発環境に入り、提出されたコード等が見れます。")}</Typography>
      <Typography variant={"subtitle2"}>{t("環境へは何度でもアクセスできますが、起動後一時間が経つとインスタンスが停止します。")}</Typography>
    </Box>
  );
};

ReportProjectWorkspaceNotificationSection.displayName = "ReportProjectWorkspaceNotificationSection";

export default ReportProjectWorkspaceNotificationSection;
