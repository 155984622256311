import { proxy } from "valtio";

import type { State } from "./types";

export const state = proxy<State>({
  company: null,
  talentInvitation: {
    status: "READY",
    id: null,
  },
  deleteMember: {
    selectedMemberId: null,
  },
  selectedMemberIds: [],
  dialogStatus: "CLOSE",
  filterText: "",
});
