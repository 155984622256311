import { proxyMap } from "valtio/utils";

import { state } from "./State";
import type * as Types from "./types";

export const updateResponse = (res: Types.TemplatesByCompanyIdResponse | null) => {
  state.res = res;
  res?.templates.forEach(template => {
    state.templates.set(template.templateId, template);
  });
  state.currentPager = res ? res.pager : null;
};

export const goNextPage = (): void => {
  if (state.currentPager) {
    state.nextPager = state.currentPager;
  }
};

export const addTemplate = (template: Types.Template) => {
  state.templates.set(template.templateId, template);
};

export const updateStep = (step: Types.Step) => {
  state.step = step;
};
export const goBackChoiceStep = () => {
  state.step = "CHOICE";
  state.variableWithValue.clear();
};

export const removeTemplate = (id: Types.TemplateId) => {
  state.templates.delete(id);
};

export const clearSelectedTemplate = () => {
  state.selectedTemplateId = null;
  state.variableWithValue = proxyMap();
  state.step = "CHOICE";
};

export const clear = (): void => {
  state.templates = proxyMap();
  state.currentPager = null;
  state.nextPager = null;
  state.res = null;
  clearSelectedTemplate();
};

export const initializeSelectedTemplate = (id: Types.TemplateId) => {
  state.selectedTemplateId = id;
  state.step = "CONFIRM";
  const template = state.templates.get(id);
  if (template && template.variables && template.variables.length > 0) {
    template.variables.forEach(variable => {
      state.variableWithValue.set(variable.key, "");
    });
  }
};

export const updateVariablesWithValue = (key: number, value: string) => {
  state.variableWithValue.set(key, value);
};
