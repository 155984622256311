import { useSnapshot } from "valtio";

import { state } from "./State";
import * as Types from "./types";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = (questionUid: string) => {
  const snapshot = useSnapshotState();
  return snapshot.questions.has(questionUid);
};

export const useCreateQuestionHooks = (questionUid: string) => {
  const snapshot = useSnapshotState();
  const questionState = snapshot.questions.get(questionUid);

  if (!questionState) {
    throw new Error(`Need to initialize questionId=${questionUid}`);
  }
  const useQuestion = (): Types.Question => {
    return questionState.question;
  };

  const useCodeBodies = (): Types.CodeBodies => {
    const answer =
      questionState.question.answers.find(answer => {
        return answer.label === "OPTIMAL";
      }) || questionState.question.answers[0];
    if (!answer) {
      return {};
    }
    return answer.answerRuntimes.reduce((previousValue, currentValue) => {
      return { ...previousValue, [currentValue.runtime]: currentValue.codeBody };
    }, {});
  };
  const useTestCaseResults = () => {
    return questionState.testCasesResults;
  };

  const useOutputStatusMap = (): Record<Types.TestCaseIndex, Types.OutputStatus> => {
    const statusMap: Record<Types.TestCaseIndex, Types.OutputStatus> = {};
    questionState.outputStatus.forEach((value, key) => {
      statusMap[key] = value;
    });

    return statusMap;
  };

  return {
    useQuestion,
    useCodeBodies,
    useTestCaseResults,
    useOutputStatusMap,
  };
};

export const useRunStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.runStatus;
};
