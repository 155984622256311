import { useTranslation } from "@hireroo/i18n";
import { AlignHorizontalRight } from "@mui/icons-material";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import InputControlTextField, { InputControlTextFieldProps } from "../../../../../primitive/InputControl/InputControlTextField";
import { useAlgorithmTestCasesFormContext } from "../../../Context";
import AddTestCase, { AddTestCaseProps } from "./parts/AddTestCase/AddTestCase";
import ReadonlyTestCase, { ReadonlyTestCaseProps } from "./parts/ReadonlyTestCase/ReadonlyTestCase";

export type AlgorithmPerformanceTestCasesFormProps = {
  testCases: ReadonlyTestCaseProps[];
  addTestCase: AddTestCaseProps;
};

const AlgorithmPerformanceTestCasesForm: React.FC<AlgorithmPerformanceTestCasesFormProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { method } = useAlgorithmTestCasesFormContext();

  const timeLimitField: InputControlTextFieldProps = {
    control: method.control,
    label: t("テストケースの最大実行時間"),
    InputLabelProps: {
      shrink: true,
    },
    type: "number",
    color: "primary",
    fullWidth: true,
    variant: "outlined",
    InputProps: {
      endAdornment: <InputAdornment position="end">{"ms"}</InputAdornment>,
    },
    helperText: t("テストケースの実行時間がこの値を超えた場合、不正解となります。"),
    required: true,
  };

  return (
    <Box>
      <Stack direction="row" alignItems="center" spacing={1}>
        <AlignHorizontalRight fontSize="small" sx={{ color: theme.palette.common.white }} />
        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
          {t("テストケース（パフォーマンス）を入力する")}
        </Typography>
      </Stack>

      <Box mt={2}>
        <Typography variant="body2">
          {t(
            "テストケース（パフォーマンス）は、提出されたコードのパフォーマンスを計測するのに利用されます。入力負荷が徐々に上がるようにケースを追加すると精度が上がります。",
          )}
        </Typography>
      </Box>

      <Box mt={3}>
        <Box width="50%">
          <InputControlTextField name="performanceTestCases.timeLimit" {...timeLimitField} />
        </Box>

        <Box my={2} display="flex" justifyContent="end">
          <AddTestCase {...props.addTestCase} />
        </Box>

        {props.testCases.map((testCase, index) => (
          <ReadonlyTestCase
            key={`${testCase.accordion.id}-${index}`}
            {...testCase}
            accordion={{ ...testCase.accordion, title: `${t("テストケース")} ${index + 1}: ${testCase.accordion.title}` }}
          />
        ))}
      </Box>
    </Box>
  );
};

AlgorithmPerformanceTestCasesForm.displayName = "AlgorithmPerformanceTestCasesForm";

export default AlgorithmPerformanceTestCasesForm;
