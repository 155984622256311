import { useTranslation } from "@hireroo/i18n";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import ListContent, { ListContentProps } from "./parts/ListContent/ListContent";

const StyledStack = styled(Stack)(({ theme }) => ({
  borderRadius: "8px",
  border: "1px solid",
  borderColor: theme.palette.divider,
  padding: "16px",
  height: 242,
}));

const StyledList = styled(List)(() => ({
  padding: 0,
}));

export type AssessmentDetailSummaryProps = {
  createdDate: string;
  nextStartScheduledDate?: string;
  duration: string;
  examNumber: number;
  questionTypes: string;
};

const AssessmentDetailSummary: React.FC<AssessmentDetailSummaryProps> = props => {
  const { t } = useTranslation();
  const listContents: ListContentProps[] = [
    {
      title: t("タレントスコア作成日"),
      children: props.createdDate,
    },
    props.nextStartScheduledDate && {
      title: t("次の試験作成予定"),
      children: props.nextStartScheduledDate,
    },
    {
      title: t("実施回数"),
      children: `${props.examNumber} ${t("回")}`,
    },
    {
      title: t("実施頻度"),
      children: props.duration,
    },
    {
      title: t("出題する問題"),
      children: props.questionTypes,
    },
  ].flatMap(v => (v ? [v] : []));
  return (
    <StyledStack spacing={1}>
      <Typography noWrap mb={1}>
        {t("タレントスコア情報")}
      </Typography>
      <StyledList>
        {listContents.map(content => (
          <ListContent key={content.title} {...content} />
        ))}
      </StyledList>
    </StyledStack>
  );
};

AssessmentDetailSummary.displayName = "AssessmentDetailSummary";

export default AssessmentDetailSummary;
