import { styled } from "@mui/material/styles";
import * as React from "react";

import TalentLayout, { TalentLayoutProps } from "../../layouts/TalentLayout/TalentLayout";

const StyledTalentLayout = styled(TalentLayout)`
  overflow: hidden;
`;

export type AssessmentDetailForTalentProps = {
  layout: TalentLayoutProps;
  children: React.ReactNode;
};

const AssessmentDetailForTalent: React.FC<AssessmentDetailForTalentProps> = props => {
  return <StyledTalentLayout {...props.layout}>{props.children}</StyledTalentLayout>;
};

AssessmentDetailForTalent.displayName = "AssessmentDetailForTalent";

export default AssessmentDetailForTalent;
