import { LATEST_VERSION } from "@hireroo/app-definition/question";
import { App } from "@hireroo/app-store/essential/employee";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

import QuizPackageDetailContainer, { QuizPackageDetailContainerProps } from "./Container";

export type QuizPackageDetailFetchContainerProps = {};

const QuizPackageDetailFetchContainer: React.FC<QuizPackageDetailFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const { id } = useParams<{ id: string }>();
  const [query] = useSearchParams();
  const [packageVersion, setPackageVersion] = React.useState(query.get("version") ?? LATEST_VERSION);

  const [result, refresh] = Graphql.useGetMultiChoicePackageForQuestionQuizIdQuery({
    variables: {
      id: Number(id),
      version: packageVersion,
      withAnswers: true,
    },
    pause: appStatus !== "INITIALIZED",
  });

  React.useEffect(() => {
    if (result.data) {
      const { multiChoicePackage } = result.data;
      const multiChoicePackageVersion = multiChoicePackage?.version;
      if (multiChoicePackageVersion) {
        setPackageVersion(prev => {
          if (prev !== multiChoicePackageVersion) {
            return multiChoicePackageVersion;
          }
          return prev;
        });
      }
    }
  }, [result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }

  if (result.fetching || appStatus !== "INITIALIZED" || !result.data?.multiChoicePackage) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: QuizPackageDetailContainerProps = {
    packageDetail: result.data.multiChoicePackage,
  };

  return <QuizPackageDetailContainer {...containerProps} />;
};

QuizPackageDetailFetchContainer.displayName = "QuizPackageDetailFetchContainer";

export default withErrorBoundary(QuizPackageDetailFetchContainer, {});
