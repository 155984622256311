import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import Icon, { IconProps } from "../../../../subblocks/Icon/Icon";

export type TestCaseProps = {
  icon: IconProps;
  title: string;
};

const TestCase: React.FC<React.PropsWithChildren<TestCaseProps>> = props => {
  return (
    <Accordion>
      <AccordionSummary>
        <Stack direction="row" alignItems="center" spacing={2}>
          {props.icon && <Icon {...props.icon} />}
          <Typography>{props.title}</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>{props.children}</AccordionDetails>
    </Accordion>
  );
};

TestCase.displayName = "TestCase";

export default TestCase;
