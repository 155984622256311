import ScoreTransitionGraph, { ScoreTransitionGraphProps } from "@hireroo/charts/react/v2/ScoreTransitionGraph";
import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import ArrowSwitchButtonGroup, { ArrowSwitchButtonGroupProps } from "./parts/ArrowSwitchButtonGroup/ArrowSwitchButtonGroup";

const StyledBox = styled(Box)(({ theme }) => ({
  borderRadius: "8px",
  border: "1px solid",
  borderColor: theme.palette.divider,
  width: "100%",
  padding: "16px",
  height: 242,
}));

export type ExamTransitionVisualizerProps = {
  graph: Omit<ScoreTransitionGraphProps, "yLabel">;
  switchButtonGroup: ArrowSwitchButtonGroupProps;
};

const ExamTransitionVisualizer: React.FC<ExamTransitionVisualizerProps> = props => {
  const { t } = useTranslation();
  const graph: ScoreTransitionGraphProps = {
    ...props.graph,
    yLabel: t("最高総合点"),
  };
  return (
    <StyledBox>
      <Stack direction="row" display="flex" justifyContent="space-between" mb={2}>
        <Typography variant="subtitle1" noWrap>
          {t("タレント実績")}
        </Typography>
        <ArrowSwitchButtonGroup {...props.switchButtonGroup} />
      </Stack>
      <Box height={173} width="100%">
        <React.Suspense>
          <ScoreTransitionGraph {...graph} />
        </React.Suspense>
      </Box>
    </StyledBox>
  );
};

ExamTransitionVisualizer.displayName = "ExamTransitionVisualizer";

export default ExamTransitionVisualizer;
