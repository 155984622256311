import { useTheme } from "@mui/material/styles";
import React, { useState } from "react";

import { ComponentType, ELEMENT_LABEL, NodeElement } from "../../../../features";
import { adjustorsFromElement } from "../../../../helpers/flowChart";
import { Node } from "../../Node/Node";
import { ScaledNode } from "../../ScaledNode/ScaledNode";

export type DynamicNodeProps = {
  node: NodeElement;
  componentType: ComponentType;
  isSelected: boolean;
  isMoving: boolean;
  isConnecting: boolean;
  isConnectingOwn: boolean;
  isDuplicateEdge: boolean;
  isReconnectingAsBefore: boolean;
  onSelect: (e: React.MouseEvent<SVGElement, MouseEvent>, nodeId: string) => void;
  onStartConnectNodes: (e: React.MouseEvent<SVGElement, MouseEvent>, nodeId: string) => void;
  onEndConnectNodes: (e: React.MouseEvent<SVGElement, MouseEvent>, nodeId: string) => void;
};

export const DynamicNode: React.FC<DynamicNodeProps> = React.memo((props: DynamicNodeProps) => {
  const theme = useTheme();
  const {
    geometry: { minX, minY, maxX, maxY },
  } = props.node;
  const { leftTop, topMid, rightTop, rightMid, rightBottom, bottomMid, leftBottom, leftMid } = adjustorsFromElement(props.node);
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const canConnect =
    (isHovering && props.isConnecting && !props.isConnectingOwn && !props.isDuplicateEdge) || (isHovering && props.isReconnectingAsBefore);

  return (
    <g
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onMouseUp={e => props.onEndConnectNodes(e, props.node.id)}
    >
      {props.node.label === ELEMENT_LABEL.vm && props.node.settings.autoScale ? (
        <ScaledNode node={props.node} componentType={props.componentType} dashedLine={props.isSelected} />
      ) : (
        <Node node={props.node} componentType={props.componentType} dashedLine={props.isSelected} />
      )}

      <rect
        width={maxX - minX}
        height={maxY - minY}
        x={minX}
        y={minY}
        rx={canConnect ? 0 : 10}
        ry={canConnect ? 0 : 10}
        fill={theme.palette.common.white}
        fillOpacity={0}
        stroke={canConnect ? theme.palette.secondary.main : theme.palette.common.black}
        strokeOpacity={props.isSelected || canConnect ? 1 : 0}
        strokeWidth={canConnect ? 8 : 1}
        strokeDasharray={props.isSelected ? "8 8" : "0 0"}
        cursor={props.isConnecting ? "crosshair" : props.isMoving ? "default" : "move"}
        onMouseDown={e => props.onSelect(e, props.node.id)}
      />

      {props.isSelected &&
        [leftTop, topMid, rightTop, rightMid, rightBottom, bottomMid, leftBottom, leftMid].map((adaptor, i) => (
          <g key={`adaptor-${i}`}>
            <circle cx={adaptor.x} cy={adaptor.y} r={8} stroke={theme.palette.common.black} fill={"none"} />
            <circle
              cx={adaptor.x}
              cy={adaptor.y}
              r={20}
              strokeOpacity={0}
              fillOpacity={0}
              onMouseDown={e => props.onStartConnectNodes(e, props.node.id)}
              cursor={"crosshair"}
            />
          </g>
        ))}
    </g>
  );
});

DynamicNode.displayName = "DynamicNode";

export default DynamicNode;
