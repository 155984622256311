import TableRow, { TableRowProps } from "@mui/material/TableRow";

export type DivTableRowProps = TableRowProps;

const DivTableRow: React.FC<DivTableRowProps> = props => {
  return <TableRow {...props} component="div" sx={{ display: "table-row" }} />;
};

DivTableRow.displayName = "DivTableRow";

export default DivTableRow;
