import { ref } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

export const initialize = (newValue: Types.Assessment): void => {
  state.assessment = newValue;
};

export const clear = () => {
  state.assessment = null;
  state.exam = null;
};

export const setRefresh = (callback: Types.RefreshCallback) => {
  state.refresh = ref(callback);
};

export const updateAssessment = (newValue: Types.Assessment): void => {
  state.assessment = newValue;
};

export const updateExam = (newValue: Types.Exam): void => {
  state.exam = newValue;
};
