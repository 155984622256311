import { Credential } from "@hireroo/app-store/essential/shared";
import { SignInOrSignUp } from "@hireroo/app-store/essential/shared";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { applyActionCode, ErrorCode, FirebaseError, verifyPasswordResetCode } from "@hireroo/firebase";
import { parseMode } from "@hireroo/firebase";
import * as Graphql from "@hireroo/graphql/client/urql";
import { getTranslation } from "@hireroo/i18n";

type AuthInitializeArgs = {
  res: Graphql.InitializeParamsForAuthFragment;
};

const initialize = async (args: AuthInitializeArgs) => {
  const { user: currentUser } = args.res;
  Credential.setCurrentUserType(currentUser?.userType || "UNKNOWN");
  const searchParams = new URLSearchParams(window.location.search);
  const { t } = getTranslation();
  const oddCode = searchParams.get("oobCode");
  const mode = parseMode(searchParams.get("mode"));
  if (typeof oddCode !== "string") {
    SignInOrSignUp.setFirebaseAuthStatusCode("INVALID_OOB_CODE");
    return;
  }
  switch (mode) {
    case "VERIFY_EMAIL": {
      applyActionCode(oddCode)
        .then(async () => {
          Snackbar.notify({
            severity: "success",
            message: t("メールの認証に成功しました。"),
          });
          SignInOrSignUp.setFirebaseAuthStatusCode("EMAIL_SUCCESSFULLY_AUTHENTICATED");
        })
        .catch((error: FirebaseError) => {
          switch (error.code) {
            case ErrorCode.INVALID_OOB_CODE: {
              SignInOrSignUp.setFirebaseAuthStatusCode("INVALID_OOB_CODE");
              break;
            }
            case ErrorCode.CODE_EXPIRED: {
              SignInOrSignUp.setFirebaseAuthStatusCode("CODE_EXPIRED");
              break;
            }
            case ErrorCode.EXPIRED_OOB_CODE: {
              SignInOrSignUp.setFirebaseAuthStatusCode("INVALID_OOB_CODE");
              break;
            }
          }
        });
      return;
    }
    case "PASSWORD_RESET": {
      await verifyPasswordResetCode(oddCode)
        .then(emailAddress => {
          SignInOrSignUp.setAfterResetPasswordPayload({
            emailAddress: emailAddress,
          });
          SignInOrSignUp.setFirebaseAuthStatusCode("PASSWORD_RESET");
        })
        .catch(() => {
          Snackbar.notify({
            severity: "error",
            message: t("認証コードが正しくありません。リンクを再度確認してください。"),
          });
          SignInOrSignUp.setFirebaseAuthStatusCode("INVALID_OOB_CODE_FOR_PASSWORD_RESET");
        });
      return;
    }
    default: {
      SignInOrSignUp.setFirebaseAuthStatusCode("INVALID_OOB_CODE");
      return;
    }
  }
};

export const Auth = {
  initialize,
};
