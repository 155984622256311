import { useTitle } from "@hireroo/app-helper/react-use";
import { useTranslation } from "@hireroo/i18n";
import type { Pages } from "@hireroo/presentation/legacy";

import { useGenerateFreepadResourceEditorProps } from "../../../props-factory/e/FreepadResourceEditor/useGenerateFreepadResourceEditorProps";

export type GenerateFreepadResourceEditorPropsArgs = {
  companyId: number;
  type: "CREATE" | "UPDATE";
};

export const useGenerateProps = (args: GenerateFreepadResourceEditorPropsArgs): Pages.FreepadResourceEditorProps => {
  const { t } = useTranslation();
  useTitle(`${t("問題作成")}(${t("ライブコーディング")})`);
  return useGenerateFreepadResourceEditorProps(args);
};
