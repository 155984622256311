import { ScreeningInviteeSearchForm as ScreeningInviteeSearchFormValidator } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import SearchForm, { SearchFormProps } from "../../../modules/SearchForm/SearchForm";
import * as PrivateHelper from "./privateHelper";

export type ScreeningInviteeSearchFormProps = {
  onChange: SubmitHandler<ScreeningInviteeSearchFormValidator.InviteeSearchQuerySchema>;
  onReset?: () => void;
  defaultValues: ScreeningInviteeSearchFormValidator.InviteeSearchQuerySchema;
};

const ScreeningInviteeSearchForm: React.FC<ScreeningInviteeSearchFormProps> = props => {
  const { onChange } = props;
  const validateSchema = ScreeningInviteeSearchFormValidator.useInviteeSearchQuery();
  const methods = useForm<ScreeningInviteeSearchFormValidator.InviteeSearchQuerySchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: props.defaultValues,
  });
  const statuesField = PrivateHelper.useStatusesField();
  const textFilerField = PrivateHelper.useTextFilterField();

  React.useEffect(() => {
    const subscriber = methods.watch(() => {
      methods.handleSubmit(onChange, console.warn)();
    });
    return () => {
      subscriber.unsubscribe();
    };
  }, [methods, onChange]);

  const searchFormProps: SearchFormProps = {
    kind: "MULTI_LINE",
    disabled: false,
    textFilter: textFilerField,
    onReset: () => {
      methods.reset({
        textFilter: "",
        statuses: [],
      });
      props.onReset?.();
    },
    fields: React.useMemo(() => [statuesField], [statuesField]),
  };
  return (
    <FormProvider {...methods}>
      <SearchForm {...searchFormProps} />
    </FormProvider>
  );
};

ScreeningInviteeSearchForm.displayName = "ScreeningInviteeSearchForm";

export default ScreeningInviteeSearchForm;
