import { useStringDebouncedCallback } from "@hireroo/app-helper/react-use";
import * as SkillTagHelper from "@hireroo/app-helper/skill-tag";
import { QuestionSelectFieldForResourceEditor } from "@hireroo/app-store/widget/e/QuestionSelectFieldForResourceEditor";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

type DisplaySources = Widget.QuestionSearchForResourceEditorProps["skillTagsField"]["selector"]["displaySources"];

export const useGenerateSkillTagsFieldProps = (): Widget.QuestionSearchForResourceEditorProps["skillTagsField"] => {
  const { t } = useTranslation();
  const skillTagRes = QuestionSelectFieldForResourceEditor.useSkillTagRes();
  const currentSkillTags = QuestionSelectFieldForResourceEditor.useCurrentSkillTags();
  const skillTagSources = QuestionSelectFieldForResourceEditor.useSkillTagSources();
  const textFilter = QuestionSelectFieldForResourceEditor.useSkillTagTextFilter();
  const nextOffset = QuestionSelectFieldForResourceEditor.useSkillTagNextOffset();
  const debounced = useStringDebouncedCallback({
    callback: React.useCallback(
      value => {
        if (value !== textFilter) {
          QuestionSelectFieldForResourceEditor.clearCurrentSkillTagState();
        }
        QuestionSelectFieldForResourceEditor.updateSkillTagTextFilter(value);
      },
      [textFilter],
    ),
    delayInMicroSeconds: 500,
  });

  const [result, refresh] = Graphql.useGetHierarchicalSkillTagsForQuestionsQuery({
    variables: {
      input: {
        name: textFilter,
        depth: 1,
        offset: nextOffset,
        limit: QuestionSelectFieldForResourceEditor.SKILL_TAG_SIZE,
        withCount: false,
      },
    },
    requestPolicy: "network-only",
  });

  React.useEffect(() => {
    if (result.data) {
      QuestionSelectFieldForResourceEditor.updateSkillTags(result.data.hierarchicalSkillTags);
    }
  }, [result.data]);

  return {
    selector: {
      displaySources: React.useMemo(() => {
        const skillTagUniqueNameMap = SkillTagHelper.generateUniqueNameMapFromPath(
          skillTagSources.map(skillTag => ({
            id: skillTag.skillTagNodeId,
            name: skillTag.name,
            path: skillTag.path,
          })),
        );
        return skillTagSources.reduce<DisplaySources>((all, skillTag) => {
          all[skillTag.skillTagNodeId] = {
            optionText: skillTagUniqueNameMap.get(skillTag.skillTagNodeId) || skillTag.name,
            tooltip: skillTag.path,
          };
          return all;
        }, {});
      }, [skillTagSources]),
      suggestions: currentSkillTags.map(skillTag => ({ valueId: skillTag.skillTagNodeId })),
      loading: result.fetching,
      placeholder: t("スキルタグを検索"),
      onReachedEnd: () => {
        if (skillTagRes?.hasNext && !result.fetching) {
          QuestionSelectFieldForResourceEditor.updateSkillTagNextOffset();
        }
      },
      onInputChange: text => {
        debounced(text);
      },
    },
    onChange: fields => {
      // Clear skillTagState fields if the field is empty
      if (fields.length === 0) {
        QuestionSelectFieldForResourceEditor.clearCurrentSkillTagState();
      }
    },
    splitButton: {
      onOpen: () => {
        if (!result.fetching) {
          refresh();
        }
      },
      onClose: () => {
        QuestionSelectFieldForResourceEditor.clearCurrentSkillTagState();
      },
    },
  };
};
