import { Auth } from "@hireroo/app-store/essential/employee";
import { EmployeeNavigation } from "@hireroo/app-store/widget/e/EmployeeNavigation";
import { Widget } from "@hireroo/presentation/legacy";
import { useState } from "react";

import { useFetchNotificationList } from "./useFetchNotificationList";
import NotificationListContainer from "./widget/NotificationList/Container";

export type GenerateNotificationButtonPropsArgs = {
  companyId: number;
};

export type NotificationButtonProps = Widget.EmployeeNavigationProps["notificationButton"];

export const useGenerateNotificationButtonProps = (args: GenerateNotificationButtonPropsArgs): NotificationButtonProps => {
  const count = EmployeeNavigation.useUnreadNotificationCount();
  const currentUser = Auth.useCurrentUser();
  const [now, setNow] = useState<Date>(new Date());
  useFetchNotificationList({ companyId: args.companyId, employeeId: currentUser.uid });

  return {
    notificationCount: count,
    Notification: <NotificationListContainer now={now} companyId={args.companyId} />,
    onClick: () => {
      setNow(new Date());
    },
  };
};
