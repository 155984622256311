import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type TestReportContentNotAvailableContainerProps = {
  title: string;
  description: string;
};

const TestReportContentNotAvailableContainer: React.FC<TestReportContentNotAvailableContainerProps> = props => {
  const featureNotAvailableProps = useGenerateProps({
    title: props.title,
    description: props.description,
  });
  return (
    <ErrorBoundary>
      <Widget.FeatureNotAvailable {...featureNotAvailableProps} />
    </ErrorBoundary>
  );
};

TestReportContentNotAvailableContainer.displayName = "TestReportContentNotAvailableContainer";

export default withErrorBoundary(TestReportContentNotAvailableContainer, {});
