import { Auth } from "@hireroo/app-store/essential/talent";
import { signOut } from "@hireroo/firebase";
import { useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";

export type GenerateEmployeeMenuPropsArgs = {};

export const useGenerateProps = (_args: GenerateEmployeeMenuPropsArgs): Widget.NavigationPopupMenuProps => {
  const { t } = useTranslation();
  const currentUser = Auth.useCurrentUser();
  const navigate = useTransitionNavigate();

  return {
    photoUrl: currentUser.photoUrl,
    menuList: {
      displayName: currentUser.displayName,
      email: currentUser.email,
      links: [
        { children: t("個人設定"), href: generatePath("/t/settings/user"), onClick: () => navigate("/t/settings/user") },
        {
          children: t("サインアウト"),
          onClick: async () => {
            await signOut();
          },
        },
      ],
    },
  };
};
