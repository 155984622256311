import { differenceInSeconds, isAfter, isBefore } from "date-fns";

// calculateRemainingTime calculate remaining time.
// The shorter of the time limit calculated from didStartAt + timeLimit and willEndAt (submission deadline) is the remaining time.
export const calculateRemainingTime = (
  nowInSeconds: number,
  didStartAtInSeconds: number,
  timeLimitInSeconds: number,
  willEndAtInSeconds: number,
): number => {
  const willFinishAtInSeconds = didStartAtInSeconds + timeLimitInSeconds;
  const willFinishAt = new Date(willFinishAtInSeconds * 1000);
  const willEndAt = new Date(willEndAtInSeconds * 1000);

  const now = new Date(nowInSeconds * 1000);
  const comparingDate = isBefore(willEndAt, willFinishAt) ? willEndAt : willFinishAt;

  if (isAfter(now, comparingDate)) return 0;
  return differenceInSeconds(comparingDate, now);
};
