import { generateKey } from "@hireroo/app-helper/parser";

import { state } from "./State";
import type * as Types from "./types";

export const hasStatistics = (entityId: Types.QuizId, query: Types.Query) => {
  const quizState = state.quizzes.get(entityId);
  if (!quizState) {
    return;
  }
  return quizState.statisticsMap.has(generateKey(query));
};
