import { useTranslation } from "@hireroo/i18n";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Stack, { StackProps } from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Controller } from "react-hook-form";

export type AutomaticContractRenewalFieldProps = {
  name: string;
} & Pick<StackProps, "mb">;

const AutomaticContractRenewalField: React.FC<AutomaticContractRenewalFieldProps> = props => {
  const { t } = useTranslation();
  return (
    <Stack spacing={2} mb={props.mb}>
      <Typography variant="body2" color="text.secondary">
        {t("有効にすることで契約期間が自動的に更新されます。")}
      </Typography>
      <Controller
        name={props.name}
        render={({ field }) => {
          return (
            <FormGroup onChange={field.onChange}>
              <Stack direction="column" color="primary">
                <FormControlLabel
                  control={<Checkbox color="secondary" checked={field.value} />}
                  label={
                    <Typography variant="body1" gutterBottom mb={0}>
                      {t("自動更新の有効化")}
                    </Typography>
                  }
                />
              </Stack>
            </FormGroup>
          );
        }}
      />
    </Stack>
  );
};

AutomaticContractRenewalField.displayName = "AutomaticContractRenewalField";

export default AutomaticContractRenewalField;
