import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateAssessmentListForEmployeePropsArgs, useGenerateProps } from "./useGenerateProps";

export type AssessmentListForEmployeeContainerProps = GenerateAssessmentListForEmployeePropsArgs;

const AssessmentListForEmployeeContainer: React.FC<AssessmentListForEmployeeContainerProps> = props => {
  const assessmentListForEmployeeProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.AssessmentListForEmployee {...assessmentListForEmployeeProps} />
    </ErrorBoundary>
  );
};

AssessmentListForEmployeeContainer.displayName = "AssessmentListForEmployeeContainer";

export default withErrorBoundary(AssessmentListForEmployeeContainer, {});
