import { proxyMap } from "valtio/utils";

import { state } from "./State";
import type * as Types from "./types";

export const updateParticipants = (participants: Types.Participant[]): void => {
  /**
   * By creating a new Map, you can build up an up-to-date participant status.
   */
  const newParticipants: Types.State["participants"] = proxyMap();
  participants.forEach(participant => {
    newParticipants.set(participant.id, participant);
    if (!state.userInfoMap.has(participant.id)) {
      state.userInfoMap.set(participant.id, {
        status: "BEFORE_FETCH",
        employee: null,
      });
    }
  });
  state.participants = newParticipants;
};

export const updateEmployees = (employees: Types.Employee[]) => {
  employees.forEach(employee => {
    state.userInfoMap.set(employee.id, {
      status: "FETCHED",
      employee: employee,
    });
  });
};
