import { proxyMap } from "valtio/utils";

import { state } from "./State";
import type * as Types from "./types";

export const initialize = (entity: Types.ProjectEntity) => {
  const entityState: Types.ProjectEntityState = {
    entity,
    testCaseState: proxyMap(),
    defaultTestCaseState: {
      status: "DEFAULT",
    },
    testCasesForBackend: proxyMap(),
    testCasesForFrontend: proxyMap(),
    testCasesForDataScience: proxyMap(),
    testCasesForDefault: "",
    workspace: null,
    agentServerHealth: false,
    applicationServerHealth: false,
  };
  state.projects.set(entity.projectEntityId, entityState);
};

export const clear = (entityId: Types.ProjectEntity["projectEntityId"]) => {
  const entityState = state.projects.get(entityId);
  if (!entityState) {
    return;
  }
};

export const clearSubmittedEntity = () => {
  state.submittedEntity = null;
};

export const setSubmittedEntity = (entity: Types.ProjectEntity) => {
  state.submittedEntity = entity;
};

export const setIpAddress = (ipAddress: string) => {
  state.ipAddress = ipAddress;
};

export const setGeolocation = (geolocation: string) => {
  state.geolocation = geolocation;
};

export const createProjectEntityAction = (entityId: number) => {
  const project = state.projects.get(entityId);
  if (!project) {
    throw new Error(`Please initialize: entityId=${entityId}`);
  }
  const clearTestResultForBackend = () => {
    project.testCasesForBackend.clear();
    project.testCaseState.clear();
  };
  const setTestCaseResultForBackend = (key: string, result: Types.TestCaseResultForBackend) => {
    project.testCasesForBackend.set(key, result);
  };
  const clearTestResultForFrontend = () => {
    project.testCasesForFrontend.clear();
    project.testCaseState.clear();
  };
  const setTestCaseResultForFrontend = (key: string, result: Types.TestCaseResultForFrontend) => {
    project.testCasesForFrontend.set(key, result);
  };
  const clearTestResultForDataScience = () => {
    project.testCasesForDataScience.clear();
    project.testCaseState.clear();
  };
  const setTestCaseResultForDataScience = (key: string, result: Types.TestCaseResultForDataScience) => {
    project.testCasesForDataScience.set(key, result);
  };
  const clearTestResultForDefault = () => {
    project.testCasesForDefault = "";
  };
  const setTestCaseResultForDefault = (result: string) => {
    project.testCasesForDefault = result;
    project.testCaseState.clear();
  };
  const setTestCaseState = (key: string, testCaseState: Types.TestCaseState) => {
    project.testCaseState.set(key, testCaseState);
  };
  const setDefaultTestCaseState = (testCaseState: Types.TestCaseState) => {
    project.defaultTestCaseState = testCaseState;
  };
  const setWorkspace = (workspace: Types.ProjectWorkspace | null) => {
    project.workspace = workspace;
  };
  const updateAppealMessage = (appealMessage: string) => {
    project.entity.appealMessage = appealMessage;
  };
  const setAgentServerHealth = (agentServerHealth: boolean) => {
    project.agentServerHealth = agentServerHealth;
  };
  const setApplicationServerHealth = (applicationServerHealth: boolean) => {
    project.applicationServerHealth = applicationServerHealth;
  };
  return {
    setWorkspace,
    setTestCaseState,
    setTestCaseResultForDataScience,
    setDefaultTestCaseState,
    setTestCaseResultForBackend,
    setTestCaseResultForFrontend,
    setTestCaseResultForDefault,
    clearTestResultForBackend,
    clearTestResultForFrontend,
    clearTestResultForDataScience,
    clearTestResultForDefault,
    updateAppealMessage,
    setAgentServerHealth,
    setApplicationServerHealth,
  };
};
