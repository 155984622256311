import { fetchLiveCodingRevisionHistoriesComponentTypes } from "@hireroo/app-helper/remote";
import { RemoteInterviewPlayback } from "@hireroo/app-store/widget/e/RemoteInterviewPlayback";
import { Widget } from "@hireroo/presentation";
import { ComponentType } from "@hireroo/system-design/features";
import { SystemDesignProvider, SystemDesignProviderProps } from "@hireroo/system-design/react/FlowChart";
import * as Sentry from "@sentry/react";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import RemoteInterviewSystemDesignPlaybackContainer, { RemoteInterviewSystemDesignPlaybackContainerProps } from "./Container";

export type RemoteInterviewSystemDesignPlaybackInitialContainerProps = {};

const RemoteInterviewSystemDesignPlaybackInitialContainer: React.FC<RemoteInterviewSystemDesignPlaybackInitialContainerProps> = () => {
  const activeSessionId = RemoteInterviewPlayback.useActiveSessionId();
  const normalizedActiveSession = RemoteInterviewPlayback.useNormalizedActiveSession();
  const containerProps: RemoteInterviewSystemDesignPlaybackContainerProps = {};
  const [liveCodingId] = React.useState<number | undefined>(normalizedActiveSession?.liveCodingId);
  const componentTypes = RemoteInterviewPlayback.useComponentTypes();
  const isHistoriesInitialized = RemoteInterviewPlayback.useIsHistoriesInitialized();

  React.useEffect(() => {
    if (liveCodingId) {
      fetchLiveCodingRevisionHistoriesComponentTypes({
        liveCodingId: liveCodingId,
        sessionId: activeSessionId,
      })
        .then(histories => {
          RemoteInterviewPlayback.initializeComponentTypesHistories(histories);
        })
        .catch(error => {
          Sentry.captureException(error);
        });
    }
  }, [activeSessionId, liveCodingId]);

  React.useEffect(() => {
    return () => {
      RemoteInterviewPlayback.clearSystemDesign();
    };
  }, []);

  const systemDesignProvider: SystemDesignProviderProps = {
    initialState: {
      componentType: componentTypes.length > 0 ? componentTypes[componentTypes.length - 1] : "default",
      selectableComponentTypes: new Set<ComponentType>(componentTypes.length > 0 ? componentTypes : ["default"]),
    },
  };
  if (!isHistoriesInitialized) return <Widget.Loading kind="CENTER_%" />;

  return (
    <ErrorBoundary>
      <SystemDesignProvider {...systemDesignProvider}>
        <RemoteInterviewSystemDesignPlaybackContainer {...containerProps} />
      </SystemDesignProvider>
    </ErrorBoundary>
  );
};
RemoteInterviewSystemDesignPlaybackInitialContainer.displayName = "RemoteInterviewSystemDesignPlaybackInitialContainer";

export default withErrorBoundary(RemoteInterviewSystemDesignPlaybackInitialContainer, {});
