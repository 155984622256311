import { ScreeningResourceEditor } from "@hireroo/app-store/widget/e/ScreeningResourceEditor";
import { Widget } from "@hireroo/presentation";

export type GenerateTestSetupSectionArgs = {
  mode: Widget.ScreeningResourceEditorProps["mode"];
  defaultValues?: Widget.ScreeningResourceEditorProps["testSetupSection"]["defaultValues"];
  minAcceptableLimit?: number;
};

export const useGenerateTestSetupSectionProps = (
  args: GenerateTestSetupSectionArgs,
): Widget.ScreeningResourceEditorProps["testSetupSection"] => {
  return {
    minAcceptableLimit: args.minAcceptableLimit ?? 0,
    onSubmit: fields => {
      ScreeningResourceEditor.setSubmitValue("TEST_SETUP", fields);
    },
    defaultValues: args.defaultValues || {
      isActive: true,
      acceptableLimit: null,
      deadlineDate: null,
      customFields: [],
    },
    disableFields: {
      customFields: false,
      acceptableLimit: false,
      deadlineDate: false,
      isActive: false,
    },
  };
};
