import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type BillingOverviewContainerProps = {};

const BillingOverviewContainer: React.FC<BillingOverviewContainerProps> = () => {
  const billingOverviewProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.BillingOverview {...billingOverviewProps} />
    </ErrorBoundary>
  );
};

BillingOverviewContainer.displayName = "BillingOverviewContainer";

export default withErrorBoundary(BillingOverviewContainer, {});
