import Box from "@mui/material/Box";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";

import PairMatchCodeEditor, { PairMatchCodeEditorProps } from "./parts/PairMatchCodeEditor/PairMatchCodeEditor";
import PairMatchCodeEditorFooter, { PairMatchCodeEditorFooterProps } from "./parts/PairMatchCodeEditorFooter/PairMatchCodeEditorFooter";

type Fragment = PairMatchCodeEditorProps["fragments"][0];
interface Pair {
  leftValue: string;
  rightValue: string;
  fragments: Fragment[];
}

export type PairEditorProps = {
  pair: Pair;
  rightFooter: PairMatchCodeEditorFooterProps;
  runtime: string;
};

const PairEditor: React.FC<PairEditorProps> = ({ pair, runtime, rightFooter }) => {
  const [selectedMatch, setSelectedMatch] = React.useState<Fragment[] | null>(null);
  const typographyRef = React.useRef<HTMLElement>(null);

  const pairMatchCodeEditorProps: Omit<PairMatchCodeEditorProps, "side" | "value"> = {
    runtime: runtime,
    fragments: pair.fragments,
    selectedMatch: selectedMatch,
    onChangeSelectedMatch: setSelectedMatch,
  };

  const resizeDetector = useResizeDetector({
    handleWidth: true,
    handleHeight: false,
  });

  const editorWidth = resizeDetector.width ? Math.floor(resizeDetector.width / 2) : undefined;
  const wrapperHeight = 400;
  const rightCodeEditorHeight = typographyRef.current === null ? undefined : wrapperHeight - typographyRef.current.clientHeight;

  const leftProps: PairMatchCodeEditorProps = { side: "left", value: pair.leftValue, width: editorWidth, ...pairMatchCodeEditorProps };
  const rightProps: PairMatchCodeEditorProps = {
    side: "right",
    value: pair.rightValue,
    width: editorWidth,
    height: rightCodeEditorHeight,
    ...pairMatchCodeEditorProps,
  };

  return (
    <Box height={wrapperHeight} display="flex" ref={resizeDetector.ref}>
      <Box sx={{ flex: 1, mr: "1px", height: "100%" }}>
        <PairMatchCodeEditor {...leftProps} />
      </Box>
      <Box sx={{ flex: 1, height: "100%", display: "flex", flexDirection: "column" }}>
        <PairMatchCodeEditor {...rightProps} />
        <PairMatchCodeEditorFooter {...rightFooter} />
      </Box>
    </Box>
  );
};

PairEditor.displayName = "PairEditor";

export default PairEditor;
