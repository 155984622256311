import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";

import ThumbSwitch, { ThumbSwitchProps } from "../../../../modules/ThumbSwitch/ThumbSwitch";

export type RecommendationProps = {
  commentField: Pick<TextFieldProps, "onChange" | "value">;
  recommendation: ThumbSwitchProps;
};

const Recommendation: React.FC<RecommendationProps> = props => {
  const { t } = useTranslation();
  const commentFieldProps: TextFieldProps = {
    ...props.commentField,
    name: "comment",
    type: "text",
    color: "secondary",
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
    required: true,
    multiline: true,
    placeholder: t("合格理由（任意）"),
    rows: 7,
  };

  return (
    <Box display="flex" flexDirection="column">
      <Stack my={2} direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle1" fontSize={16}>
          {t("合否判定")}
        </Typography>
        <ThumbSwitch {...props.recommendation} />
      </Stack>
      <TextField {...commentFieldProps} />
    </Box>
  );
};

Recommendation.displayName = "Recommendation";

export default Recommendation;
