import { useQuestionDifficultyMap } from "@hireroo/app-definition/question";
import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Auth } from "@hireroo/app-store/essential/employee";
import { QuestionQuizPackageResourceEditor } from "@hireroo/app-store/view-domain/QuestionQuizPackageResourceEditor";
import { QuizQuestionEditor } from "@hireroo/app-store/widget/e/QuizQuestionEditor";
import { SearchQuizQuestion } from "@hireroo/app-store/widget/e/SearchQuizQuestion";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import type * as Graphql from "@hireroo/graphql/client/graphql-request";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Pages } from "@hireroo/presentation/legacy";
import { Widget } from "@hireroo/presentation/legacy";
import { QuizQuestionForm } from "@hireroo/validator";
import * as Sentry from "@sentry/browser";

import QuizQuestionEditorContainer from "../../../widget/e/QuizQuestionEditor/Container";
import SearchQuizQuestionFetchContainer from "../../../widget/e/SearchQuizQuestion/FetchContainer";
import { useGenerateQuizQuestionSearchFieldProps } from "./useGenerateQuizQuestionSearchFieldProps";
import { useGenerateQuizResourcePreviewDialog } from "./useGenerateQuizResourcePreviewDialogProps";

type RowProps = Pages.QuizResourceEditorProps["selectQuestions"]["selectedQuestionTable"]["rows"][0];

export type GenerateSelectQuestionsPropsArgs = {
  companyId: number;
};

const MIN_OPTIONS = 3;
const MAX_OPTIONS = 5;

export const useGenerateSelectQuestionsProps = (args: GenerateSelectQuestionsPropsArgs): Pages.QuizResourceEditorProps["selectQuestions"] => {
  const { t } = useTranslation();
  const userId = Auth.useCurrentUid();
  const client = getGraphqlClient();
  const languageCode = useLanguageCode();
  const quizQuestionSearchField = useGenerateQuizQuestionSearchFieldProps({});
  const quizResourcePreviewDialog = useGenerateQuizResourcePreviewDialog({});
  const selectedQuestionMap = QuestionQuizPackageResourceEditor.useSelectedQuestionMap();
  const difficultyMap = useQuestionDifficultyMap();
  const question = QuizQuestionEditor.useQuestion();
  const openNewQuestionEditor = QuestionQuizPackageResourceEditor.useOpenNewQuestionEditor();
  const maxSelectableQuizSize = QuestionQuizPackageResourceEditor.useMaxQuestions();
  const { method, prepareSubmit } = Widget.useQuizQuestionEditorContext();
  const orderOfQuestionIds = QuestionQuizPackageResourceEditor.useOrderOfQuestionIds();

  const handleUpdateQuestion = (fields: QuizQuestionForm.QuizQuestionFormSchema) => {
    const oldVersion = question?.version;
    if (!oldVersion || !fields.id) {
      throw new Error("Invalid Implementations");
    }
    return client
      .UpdateMultiChoiceQuestion({
        payload: {
          id: fields.id,
          companyId: args.companyId,
          titleEn: fields.questionDetails.en?.title,
          titleJa: fields.questionDetails.ja?.title,
          descriptionEn: fields.questionDetails.en?.detail,
          descriptionJa: fields.questionDetails.ja?.detail,
          difficulty: fields.difficulty,
          employeeId: userId,
          evaluationItems:
            fields.variant === "FREE_TEXT"
              ? fields.evaluations.map(evaluation => {
                  return {
                    id: evaluation.freeTextOptionId || 0,
                    titleEn: evaluation.content.en?.title,
                    titleJa: evaluation.content.ja?.title,
                    descriptionEn: evaluation.content.en?.title,
                    descriptionJa: evaluation.content.ja?.title,
                    questionId: evaluation.questionId || 0,
                    questionVersion: evaluation.questionVersion || "",
                  };
                })
              : [],
          isPrivate: !fields.isPublic,
          metricType: fields.metricType,
          options: (() => {
            if (fields.variant === "SINGLE_CHOICE") {
              return fields.singleChoiceQuestions.map<Graphql.MultiChoiceOptionInput>((option, optionIndex) => {
                return {
                  id: option.singleChoiceOptionId || 0,
                  titleJa: option.content.ja?.title,
                  titleEn: option.content.en?.title,
                  additionalDetailJa: option.content.ja?.additionalDetail,
                  additionalDetailEn: option.content.en?.additionalDetail,
                  isCorrect: optionIndex.toString() === fields.singleChoiceCorrectOptionIndex.toString(),
                };
              });
            } else if (fields.variant === "MULTI_CHOICE") {
              return fields.multiChoiceQuestion.options.map<Graphql.MultiChoiceOptionInput>(option => {
                return {
                  id: option.multiChoiceOptionId || 0,
                  titleJa: option.content.ja?.title,
                  titleEn: option.content.en?.title,
                  additionalDetailJa: option.content.ja?.detail,
                  additionalDetailEn: option.content.en?.detail,
                  isCorrect: option.isCorrect,
                };
              });
            }
            return [];
          })(),
          status: "DRAFT",
          tags: [],
          variant: fields.variant,
          newVersion: fields.newVersion,
          oldVersion: fields.oldVersion,
        },
      })
      .then(res => {
        QuestionQuizPackageResourceEditor.setOpenNewQuestionEditor(false);
        const updatedQuestion = res.updateMultiChoiceQuestion;
        if (typeof fields.id === "number" && fields.newVersion !== fields.oldVersion) {
          const oldKey = `${fields.id}-${fields.oldVersion}` as const;
          const newKey = `${updatedQuestion.id}-${updatedQuestion.version}` as const;
          QuestionQuizPackageResourceEditor.removeSelectedQuestions([oldKey]);
          SearchQuizQuestion.removeSelectQuestion(oldKey);
          QuestionQuizPackageResourceEditor.replaceQuestionId(oldKey, newKey);
        }
        QuestionQuizPackageResourceEditor.updateSelectedQuestions(updatedQuestion);
        SearchQuizQuestion.updateQuestion(updatedQuestion);
        /**
         * If the question is removed first, the text to be displayed in the UI will change before the dialog is closed,
         * which is prevented by delaying it with setTimeout.
         */
        window.setTimeout(() => {
          QuizQuestionEditor.removeQuestion();
          Snackbar.notify({
            severity: "success",
            message: t("クイズ形式の問題の保存が完了しました。"),
          });
          method.reset();
          /**
           * The value of 300 ms should be longer than the dialog closes.
           */
        }, 300);
      })
      .catch(error => {
        Sentry.captureException(error);
        const errorNotification = ErrorHandlingHelper.generateErrorNotification(
          error,
          t("クイズ形式の問題の保存に失敗しました。しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
        );
        Snackbar.notify({
          severity: "error",
          message: errorNotification.message,
        });
      });
  };

  const handleCreateQuestion = (fields: QuizQuestionForm.QuizQuestionFormSchema) => {
    return client
      .CreateMultiChoiceQuestion({
        payload: {
          companyId: args.companyId,
          titleEn: fields.questionDetails.en?.title,
          titleJa: fields.questionDetails.ja?.title,
          descriptionEn: fields.questionDetails.en?.detail,
          descriptionJa: fields.questionDetails.ja?.detail,
          difficulty: fields.difficulty,
          employeeId: userId,
          evaluationItems:
            fields.variant === "FREE_TEXT"
              ? fields.evaluations.map(evaluation => {
                  return {
                    id: evaluation.freeTextOptionId || 0,
                    titleEn: evaluation.content.en?.title,
                    titleJa: evaluation.content.ja?.title,
                    descriptionEn: evaluation.content.en?.title,
                    descriptionJa: evaluation.content.ja?.title,
                    questionId: evaluation.questionId || 0,
                    questionVersion: evaluation.questionVersion || "",
                  };
                })
              : [],
          isPrivate: !fields.isPublic,
          metricType: fields.metricType,
          options: (() => {
            if (fields.variant === "SINGLE_CHOICE") {
              return fields.singleChoiceQuestions.map<Graphql.MultiChoiceOptionInput>((option, optionIndex) => {
                return {
                  id: option.singleChoiceOptionId || 0,
                  titleJa: option.content.ja?.title,
                  titleEn: option.content.en?.title,
                  additionalDetailJa: option.content.ja?.additionalDetail,
                  additionalDetailEn: option.content.en?.additionalDetail,
                  isCorrect: optionIndex.toString() === fields.singleChoiceCorrectOptionIndex.toString(),
                };
              });
            } else if (fields.variant === "MULTI_CHOICE") {
              return fields.multiChoiceQuestion.options.map<Graphql.MultiChoiceOptionInput>(option => {
                return {
                  id: option.multiChoiceOptionId || 0,
                  titleJa: option.content.ja?.title,
                  titleEn: option.content.en?.title,
                  additionalDetailJa: option.content.ja?.detail,
                  additionalDetailEn: option.content.en?.detail,
                  isCorrect: option.isCorrect,
                };
              });
            }
            return [];
          })(),
          status: fields.status,
          tags: [],
          variant: fields.variant,
          version: fields.newVersion,
        },
      })
      .then(res => {
        QuestionQuizPackageResourceEditor.setOpenNewQuestionEditor(false);
        const newQuestion = res.createMultiChoiceQuestion;
        SearchQuizQuestion.setTemporallySelectedQuestions([newQuestion]);
        QuestionQuizPackageResourceEditor.appendSelectedQuestions(newQuestion);
        Snackbar.notify({
          severity: "success",
          message: t("クイズ形式の問題の作成が完了しました。"),
        });
        method.reset();
      })
      .catch(error => {
        Sentry.captureException(error);
        const errorNotification = ErrorHandlingHelper.generateErrorNotification(
          error,
          t("クイズ形式問題の作成に失敗しました。しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
        );
        Snackbar.notify({
          severity: "error",
          message: errorNotification.message,
        });
      });
  };

  return {
    warningMessage:
      selectedQuestionMap.size > maxSelectableQuizSize
        ? {
            message: t("設問は1問以上、40問以内で選択可能です。"),
          }
        : undefined,
    SearchQuizQuestion: <SearchQuizQuestionFetchContainer />,
    selectedQuestionTable: {
      onChangeRowIds: rowIds => {
        QuestionQuizPackageResourceEditor.updateQuestionSequence(rowIds);
      },
      rows: orderOfQuestionIds
        .map<RowProps | undefined>(id => {
          const question = selectedQuestionMap.get(id);
          if (!question) {
            return;
          }
          return {
            id: `${question.id}-${question.version}`,
            isPrivate: question.isPrivate,
            title: resolveLanguage(question, languageCode, "title"),
            difficulty: difficultyMap[question.difficulty],
            onClick: event => {
              event.stopPropagation();
              QuestionQuizPackageResourceEditor.setPreviewQuestionId(`${question.id}-${question.version}`);
            },
            deleteButton: {
              onClick: event => {
                event.stopPropagation();
                QuestionQuizPackageResourceEditor.removeSelectedQuestions([`${question.id}-${question.version}`]);
                SearchQuizQuestion.removeSelectQuestion(`${question.id}-${question.version}`);
              },
            },
          };
        })
        .flatMap(v => (v ? [v] : [])),
      addButton: {
        onClick: () => {
          QuizQuestionEditor.removeQuestion();
          QuestionQuizPackageResourceEditor.setOpenNewQuestionEditor(true);
        },
      },
    },
    quizQuestionSearchField: quizQuestionSearchField,
    QuizQuestionEditor: <QuizQuestionEditorContainer />,
    quizResourcePreviewDialog: quizResourcePreviewDialog,
    quizQuestionEditorDialog: {
      open: openNewQuestionEditor,
      title: question
        ? [resolveLanguage(question, languageCode, "title"), question.version ? `(${question.version})` : ""].join(" ")
        : t("新規設問の作成"),
      yesButton: {
        onClick: () => {
          prepareSubmit();
          method.handleSubmit(
            fields => {
              if (fields.variant === "SINGLE_CHOICE") {
                const count = fields.singleChoiceQuestions.length;
                if (count < MIN_OPTIONS || MAX_OPTIONS < count) {
                  Snackbar.notify({
                    severity: "error",
                    message: t("選択肢の数は3~5個の間で登録してください。"),
                  });
                  return;
                }
              }
              if (fields.variant === "MULTI_CHOICE") {
                const count = fields.multiChoiceQuestion.options.length;
                if (count < MIN_OPTIONS || MAX_OPTIONS < count) {
                  Snackbar.notify({
                    severity: "error",
                    message: t("選択肢の数は3~5個の間で登録してください。"),
                  });
                  return;
                }
              }
              if (fields.id) {
                void handleUpdateQuestion(fields);
              } else {
                void handleCreateQuestion(fields);
              }
            },
            () => {
              Snackbar.notify({
                severity: "error",
                message: t("入力項目に不備があります。翻訳情報も含めご確認ください。"),
              });
            },
          )();
        },
      },
      noButton: {
        onClick: () => {
          QuestionQuizPackageResourceEditor.setOpenNewQuestionEditor(false);
          QuizQuestionEditor.removeQuestion();
        },
      },
    },
  };
};
