import Markdown from "@hireroo/markdown-v2/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: theme.palette["Secondary/Shades"].p12,
  backgroundImage: "none",
  boxShadow: "none",
  borderRadius: "8px",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  "&:expanded": {
    margin: "auto",
  },
  marginBottom: "16px",
  "&:last-child": {
    marginBottom: 0,
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  cursor: "pointer",
  minHeight: "36px",
  padding: theme.spacing(1),
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  "&.Mui-expanded": {
    minHeight: "36px",
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
  "& .Mui-expanded": {
    margin: 0,
  },
}));

const StyledTitleTypography = styled(Typography)`
  font-weight: bold;
  min-height: 20px;
  line-height: 1.1;
  word-break: break-word;
`;

export type SystemDesignScoringItemProps = {
  title: string;
  description: string;
};

const SystemDesignScoringItem: React.FC<SystemDesignScoringItemProps> = props => {
  return (
    <StyledAccordion square>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <StyledTitleTypography>{props.title}</StyledTitleTypography>
      </StyledAccordionSummary>
      <AccordionDetails>
        <React.Suspense>
          <Markdown size="middle" children={props.description} />
        </React.Suspense>
      </AccordionDetails>
    </StyledAccordion>
  );
};

SystemDesignScoringItem.displayName = "SystemDesignScoringItem";

export default SystemDesignScoringItem;
