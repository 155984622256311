import { App, Company } from "@hireroo/app-store/essential/employee";
import { TechnicalCommentPromptForRemote } from "@hireroo/app-store/widget/e/TechnicalCommentPromptForRemote";
import * as Graphql from "@hireroo/graphql/client/urql";
import { GraphQLError } from "graphql";
import * as React from "react";

export type FetchResult = {
  isLoading: boolean;
  errors: GraphQLError[];
};

export type FetchMetricGroupsArgs = {};

const FETCH_SIZE = 10;

export const useFetchMetrics = (_args: FetchMetricGroupsArgs): FetchResult => {
  const activeCompanyId = Company.useStrictActiveCompanyId();
  const appStatus = App.useStatus();
  const pagination = TechnicalCommentPromptForRemote.useMetricPagination();
  const [result] = Graphql.useListEvaluationMetricsByCompanyIdForTechnicalCommentPromptForRemoteQuery({
    variables: {
      companyId: activeCompanyId,
      withCount: false,
      size: FETCH_SIZE,
      cursor: pagination.cursor,
    },
    pause: appStatus !== "INITIALIZED",
  });

  React.useEffect(() => {
    if (result.data?.evaluationMetricsByCompany) {
      TechnicalCommentPromptForRemote.addMetrics(result.data.evaluationMetricsByCompany.metrics);
      TechnicalCommentPromptForRemote.updateMetricPagination({
        nextCursor: result.data.evaluationMetricsByCompany.pager?.cursor || null,
        hasNext: result.data.evaluationMetricsByCompany.pager?.hasNext || false,
      });
    }
  }, [result.data?.evaluationMetricsByCompany]);

  return {
    isLoading: result.fetching || appStatus !== "INITIALIZED",
    errors: result.error?.graphQLErrors ? result.error.graphQLErrors : [],
  };
};
