import { INITIAL_VERSION } from "@hireroo/app-definition/question";
import { App } from "@hireroo/app-store/essential/employee";
import { AlgorithmResourceEditor } from "@hireroo/app-store/view-domain/AlgorithmResourceEditor";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation/legacy";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import ErrorPanelContainer from "../../../widget/e/ErrorPanel/Container";
import { AlgorithmResourceEditorContainerProps } from "./Container";
import AlgorithmResourceEditorMiddleContainer from "./MiddleContainer";

export type AlgorithmResourceEditorFetchContainerProps = {
  questionId: number;
};
const AlgorithmResourceEditorFetchContainer: React.FC<AlgorithmResourceEditorFetchContainerProps> = props => {
  const appStatus = App.useStatus();
  const initialized = AlgorithmResourceEditor.useQuestionInitialize();
  const [result] = Graphql.useGetAlgorithmQuestionForAlgorithmResourceEditorQuery({
    variables: {
      questionId: props.questionId,
      questionVersion: INITIAL_VERSION,
      withAnswers: true,
      withPerformanceTcs: true,
    },
    pause: appStatus !== "INITIALIZED" || initialized,
  });
  React.useEffect(() => {
    if (result.data) {
      // update your state
      AlgorithmResourceEditor.updateQuestion(result.data.algorithmQuestion);
      if (
        result.data.algorithmQuestion.variant !== "UNKNOWN" &&
        result.data.algorithmQuestion.variant !== "DATABASE" &&
        result.data.algorithmQuestion.variant !== "CLASS"
      ) {
        AlgorithmResourceEditor.updateVariant(result.data.algorithmQuestion.variant);
      }
    }
  }, [result.data]);

  if (result.error) {
    return <ErrorPanelContainer graphqlErrors={result.error.graphQLErrors} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER" />;
  }

  const containerProps: AlgorithmResourceEditorContainerProps = {};
  return (
    <ErrorBoundary>
      <AlgorithmResourceEditorMiddleContainer {...containerProps} />
    </ErrorBoundary>
  );
};

AlgorithmResourceEditorFetchContainer.displayName = "AlgorithmResourceEditorFetchContainer";

export default withErrorBoundary(AlgorithmResourceEditorFetchContainer, {});
