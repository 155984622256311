import { AssessmentsIdDetailStore } from "@hireroo/app-store/page/t/assessments_id_detail";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generatePath, navigate as navigateApi } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/browser";
import * as React from "react";

import { useGenerateExamSelector } from "./useGenerateExamSelector";

type Status = Exclude<Widget.AssessmentDetailForTalentProps["detailHeaderPage"]["retryButton"], undefined>["status"];

export type GenerateDetailHeaderPropsArgs = {
  assessmentId: string;
};

export const useGenerateDetailHeaderProps = (
  _args: GenerateDetailHeaderPropsArgs,
): Widget.AssessmentDetailForTalentProps["detailHeaderPage"] => {
  const selectedExamId = AssessmentsIdDetailStore.useSelectedExamId();
  const { t } = useTranslation();
  const client = getGraphqlClient();
  const assessment = AssessmentsIdDetailStore.useAssessment();
  const currentExam = AssessmentsIdDetailStore.useCurrentExam();
  const examSelector = useGenerateExamSelector();
  const [resultRecreate, recreateExam] = Graphql.useRecreateExamForAssessmentsIdDetailTalentMutation();
  const navigate = useTransitionNavigate();
  const [status, setStatus] = React.useState<Status>("CHECKING_RETRY");
  const [forceClose, setForceClose] = React.useState(false);
  const tailExamId = AssessmentsIdDetailStore.useTailExamId();

  return {
    backButton: {
      onClick: () => {
        navigate("/t/assessments", {
          queryParams: {
            id: assessment.assessmentId,
          },
        });
      },
    },
    startExamButton:
      (currentExam &&
        currentExam.status === "CREATED" && {
          onClick: () => {
            const url = generatePath("/t/exams/:id", {
              pathParams: {
                id: currentExam.examId,
              },
            });
            navigateApi(url, {
              _blank: true,
            });
          },
        }) ||
      undefined,
    retryButton:
      (selectedExamId &&
        currentExam &&
        (currentExam.status === "COMPLETED" || currentExam.status === "FINALIZED") && {
          status: status,
          onRetry: () => {
            recreateExam({
              input: {
                examId: selectedExamId,
              },
            }).then(res => {
              if (res.error) {
                Snackbar.notify({
                  message: t("再試験の作成に失敗しました。しばらくしてから再度お試しください。"),
                  severity: "error",
                });
              } else if (res.data) {
                setForceClose(true);
                Snackbar.notify({
                  message: t("再試験の作成に成功しました。"),
                  severity: "success",
                });
                const url = generatePath("/t/exams/:id", {
                  pathParams: {
                    id: res.data?.recreateExam.examId,
                  },
                });
                navigateApi(url, {
                  _blank: true,
                });
              }
            });
          },
          onAlreadyExistsRetry: () => {
            if (!tailExamId) {
              return;
            }
            const url = generatePath("/t/exams/:id", {
              pathParams: {
                id: tailExamId,
              },
            });
            navigateApi(url, {
              _blank: true,
            });
          },
          onOpenDialog: () => {
            setForceClose(false);
            client
              .GetTailExamForAssessmentIdDetailTalent({ examId: currentExam.examId })
              .then(({ tailExam }) => {
                if (["COMPLETED", "FINALIZED"].includes(tailExam.status)) {
                  AssessmentsIdDetailStore.updateTailExamId(tailExam.examId);
                  setStatus("CAN_RETRY");
                } else {
                  setStatus("ALREADY_EXISTS_RETRY");
                }
              })
              .catch(error => {
                Sentry.captureException(error);
              });
          },
          onCloseDialog: () => {
            //
          },
          forceClose: forceClose,
          disabled: resultRecreate.fetching,
        }) ||
      undefined,
    examSelector: examSelector && examSelector.items.length > 0 ? examSelector : undefined,
  };
};
