import { Widget } from "@lumino/widgets";
import * as React from "react";
// TODO @himenon Investigate alternatives and replace code
// eslint-disable-next-line react/no-deprecated
import { unmountComponentAtNode } from "react-dom";

export type LuminoProps = {
  id?: string;
  height?: string | number;
  children: Widget;
};

const Lumino: React.FC<LuminoProps> = props => {
  const ref = React.useRef<HTMLDivElement>(null);
  const { children, id, height } = props;
  React.useEffect(() => {
    if (ref && ref.current) {
      try {
        Widget.attach(children, ref.current);
      } catch (e) {
        console.warn("Exception while attaching Lumino widget.", e);
      }
      return () => {
        try {
          unmountComponentAtNode(children.node);
          if (children.isAttached || children.node.isConnected) {
            children.dispose();
            Widget.detach(children);
          }
        } catch (e) {
          // no-op.
          // console.debug('Exception while detaching Lumino widget.', e);
        }
      };
    }
  }, [ref, children]);
  return <div ref={ref} id={id} style={{ height: height, minHeight: height }}></div>;
};

Lumino.displayName = "Lumino";

export default Lumino;
