import { App, Payment } from "@hireroo/app-store/essential/employee";
import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import type { Widget } from "@hireroo/presentation";

import { useGenerateAnswerSectionProps } from "../../../../props-factory/v2/view-domain/ProjectTestReport/useGenerateAnswerSectionProps";
import { useGenerateCheatDetectionSectionProps } from "../../../../props-factory/v2/view-domain/ProjectTestReport/useGenerateCheatDetectionSectionProps";
import { useGenerateEntityScoreBoardProps } from "../../../../props-factory/v2/view-domain/ProjectTestReport/useGenerateEntityScoreBoardProps";
import { useGenerateHeaderProps } from "../../../../props-factory/v2/view-domain/ProjectTestReport/useGenerateHeaderProps";
import { useGeneratePerformanceDetailSectionProps } from "../../../../props-factory/v2/view-domain/ProjectTestReport/useGeneratePerformanceDetailSectionProps";
import { useGeneratePlaybackSectionProps } from "../../../../props-factory/v2/view-domain/ProjectTestReport/useGeneratePlaybackSectionProps";
import { useGenerateQuestionDetailProps } from "../../../../props-factory/v2/view-domain/ProjectTestReport/useGenerateQuestionDetailProps";
import {
  GenerateWorkspaceNotificationSectionPropsArgs,
  useGenerateWorkspaceNotificationSectionProps,
} from "../../../../props-factory/v2/view-domain/ProjectTestReport/useGenerateWorkspaceNotificationSectionProps";
import { useGenerateTestCaseSectionProps } from "../../../../props-factory/v2/view-domain/ProjectTestReport/useTestCaseSectionProps";
import TotalScoreRankVisualizerInitialContainer from "../../../v2/shared/TotalScoreRankVisualizer/InitialContainer";

export type GenerateProjectTestReportPropsArgs = {
  featureKind: "test" | "exam";
  kindOfReview: GenerateWorkspaceNotificationSectionPropsArgs["kindOfReview"];
  testId: string;
  entityId: number;
  uniqueKey: ProjectTestReport.UniqueKey;
  canShowCheatDetectionSection: boolean;
};

export const useGenerateProps = (args: GenerateProjectTestReportPropsArgs): Widget.ProjectTestReportProps => {
  const isAvailableFeature = Payment.useIsAvailableFeature();
  const appStatus = App.useStatus();
  const hooks = ProjectTestReport.useCreateProjectHooks(args.entityId);
  const submissionStatus = hooks.useCurrentSubmissionStatus();
  const submission = hooks.useCurrentSubmission();
  const projectVersion = hooks.useProjectVersion();
  const headerProps = useGenerateHeaderProps(args);
  const entityScoreBoardProps = useGenerateEntityScoreBoardProps({ ...args, showingTargets: ["SCORE", "RANK"] });
  const testCaseSectionProps = useGenerateTestCaseSectionProps({ ...args, showScore: true });
  const answerSectionProps = useGenerateAnswerSectionProps({
    ...args,
    showingTargets: isAvailableFeature(`${args.featureKind}.statics.read`) ? ["AVERAGE_VALUE"] : [],
  });
  const performanceDetailSectionProps = useGeneratePerformanceDetailSectionProps({ ...args, showScore: true });
  // TODO: Add date check for paste and tab statistics from the day project paste event is released
  const canShowPasteAndTabStatistics = isAvailableFeature("test.copy-and-paste.read");
  const playbackSectionProps = useGeneratePlaybackSectionProps({
    ...args,
    pause: appStatus !== "INITIALIZED",
    canShowPasteAndTabStatistics: canShowPasteAndTabStatistics,
  });
  const workspaceNotificationSectionProps = useGenerateWorkspaceNotificationSectionProps(args);
  const questionDetailProps = useGenerateQuestionDetailProps({
    entityId: args.entityId,
    showAnswer: true,
    showArchivedMark: true,
  });
  const cheatDetectionSection = useGenerateCheatDetectionSectionProps({
    ...args,
    projectId: args.entityId,
    canShowStatistic: isAvailableFeature("test.statics.read"),
  });

  return {
    header: headerProps,
    answerDetailSection: answerSectionProps,
    entityScoreBoard: entityScoreBoardProps,
    StatisticsContent: isAvailableFeature(`${args.featureKind}.statics.read`) && (
      <TotalScoreRankVisualizerInitialContainer uniqueKey={args.uniqueKey} score={submission?.totalScore ?? 0} enableRank />
    ),
    testCaseSection: submissionStatus === "EVALUATED" ? testCaseSectionProps : undefined,
    // We show playback section only for V1 and V3 project
    playbackSection: projectVersion !== "V2" ? playbackSectionProps : undefined,
    // We support review environment only for V3 project
    cheatDetectionSection: projectVersion === "V3" ? cheatDetectionSection : undefined,
    workspaceNotificationSection: projectVersion === "V3" ? workspaceNotificationSectionProps : undefined,
    performanceDetailSection: submissionStatus === "EVALUATED" ? performanceDetailSectionProps : undefined,
    questionDetail: questionDetailProps,
  };
};
