import { useMethods } from "@hireroo/react-split";
import * as React from "react";

export const SplitParams = {
  splitId: "ProjectCodingEditorV3",
  Contents: {
    SideBar: {
      id: "SideBar",
    },
    FileNavigation: {
      id: "FileNavigation",
    },
    Editor: {
      id: "Editor",
    },
    UiFrame: {
      id: "UiFrame",
    },
  },
};

export const SplitParamsForWaiting = {
  splitId: "ProjectCodingEditorV3-For-Waiting",
  Contents: {
    SideBar: {
      id: "SideBar",
    },
    NotificationPanel: {
      id: "NotificationPanel",
    },
  },
};

export const SplitParamsInEditorBox = {
  splitId: "EditorBox",
  Contents: {
    Editor: {
      id: "Editor",
    },
    Terminal: {
      id: "Terminal",
    },
  },
};

export const useSplitEvent = () => {
  const methods = useMethods();
  const [sidebarVisibleStatus, setSidebarVisibleStatus] = React.useState<"MINIMIZED" | "OPEN">("OPEN");
  const [fileTreeVisibleStatus, setFileTreeVisibleStatus] = React.useState<"MINIMIZED" | "OPEN">("OPEN");
  const [uiFrameVisibleStatus, setUiFrameVisibleStatus] = React.useState<"MINIMIZED" | "OPEN">("OPEN");
  const [terminalVisibleStatus, setTerminalVisibleStatus] = React.useState<"MINIMIZED" | "OPEN">("OPEN");

  React.useEffect(() => {
    const cleanup1 = methods.subscribeChangeEvent(SplitParams.splitId, event => {
      if (event.type === "MINIMIZED") {
        if (event.contentId === SplitParams.Contents.SideBar.id) {
          setSidebarVisibleStatus("MINIMIZED");
        }
        if (event.contentId === SplitParams.Contents.FileNavigation.id) {
          setFileTreeVisibleStatus("MINIMIZED");
        }
        if (event.contentId === SplitParams.Contents.UiFrame.id) {
          setUiFrameVisibleStatus("MINIMIZED");
        }
        return;
      }
      if (event.type === "RELEASE_MINIMIZED") {
        if (event.contentId === SplitParams.Contents.SideBar.id) {
          setSidebarVisibleStatus("OPEN");
        }
        if (event.contentId === SplitParams.Contents.FileNavigation.id) {
          setFileTreeVisibleStatus("OPEN");
        }
        if (event.contentId === SplitParams.Contents.UiFrame.id) {
          setUiFrameVisibleStatus("OPEN");
        }

        return;
      }
    });
    const cleanup2 = methods.subscribeChangeEvent(SplitParamsInEditorBox.splitId, event => {
      if (event.type === "MINIMIZED") {
        if (event.contentId === SplitParamsInEditorBox.Contents.Terminal.id) {
          setTerminalVisibleStatus("MINIMIZED");
        }
        return;
      }
      if (event.type === "RELEASE_MINIMIZED") {
        if (event.contentId === SplitParamsInEditorBox.Contents.Terminal.id) {
          setTerminalVisibleStatus("OPEN");
        }
        return;
      }
    });
    return () => {
      cleanup1();
      cleanup2();
    };
  }, [methods]);

  return {
    sidebarVisibleStatus,
    setSidebarVisibleStatus,
    fileTreeVisibleStatus,
    setFileTreeVisibleStatus,
    uiFrameVisibleStatus,
    setUiFrameVisibleStatus,
    terminalVisibleStatus,
    setTerminalVisibleStatus,
  };
};
