import Add from "@mui/icons-material/Add";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import React from "react";
import { useResizeDetector } from "react-resize-detector";

import Tab, { TabProps } from "../../../primitive/Tab/Tab";
import Tabs from "../../../primitive/Tabs/Tabs";
import TabLabel, { TabLabelProps } from "./parts/TabLabel/TabLabel";

const PlusTab = styled(Tab)`
  height: 36px;
  width: 36px;
  min-height: 36px;
  min-width: 36px;
`;

const ExtraWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "36px",
  backgroundColor: theme.palette["Secondary/Shades"].p16,
  flexShrink: 0,
}));

const Spacer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette["Secondary/Shades"].p16,
  flexGrow: 1,
}));

const Wrapper = styled(Box)`
  height: 100%;
  align-items: center;
  overflow: hidden;
`;

const Wrapper1 = styled(Box)`
  display: flex;
  width: 100%;
`;

const Wrapper2 = styled(Box)`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: row;
`;

type TabItem = {
  /**
   * A value to identify the tab.
   * Do not use variable values such as i18n values.
   */
  id: string;
  label: TabLabelProps;
  onClick?: TabProps["onClick"];
  children: React.ReactNode;
};

export type RemoteInterviewTabProps = {
  items: TabItem[];
  plusTab?: Pick<TabProps, "onClick" | "className">;
  Extra?: React.ReactNode;
  currentTab: string;
  onChangeTab?: (value: string) => void;
};

const RESERVED_TAB_NAMES = {
  PLUS_TAB: "plus-tab",
};

const RemoteInterviewTab: React.FC<RemoteInterviewTabProps> = props => {
  const { onChangeTab, currentTab } = props;
  const wrapperBoxDetector = useResizeDetector();
  const tabsDetector = useResizeDetector();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    if (Object.values(RESERVED_TAB_NAMES).includes(newValue)) {
      return;
    }
    onChangeTab?.(newValue);
  };
  const TabPanels = React.useMemo(() => {
    return props.items.map(item => {
      return (
        <TabPanel key={item.id} value={item.id} sx={{ p: 0, height: "100%" }}>
          <Box sx={{ overflow: "hidden", position: "relative", height: "100%" }}>{item.children}</Box>
        </TabPanel>
      );
    });
  }, [props.items]);

  const plusTabProps: TabProps | undefined = props.plusTab
    ? {
        ...props.plusTab,
        icon: <Add fontSize="small" />,
        value: RESERVED_TAB_NAMES.PLUS_TAB,
      }
    : undefined;

  return (
    <Wrapper ref={wrapperBoxDetector.ref}>
      <TabContext value={currentTab}>
        <Wrapper1 ref={tabsDetector.ref}>
          <Wrapper2>
            <Tabs value={currentTab} onChange={handleChange} variant="scrollable" scrollButtons="auto">
              {props.items.map(item => (
                <Tab
                  key={item.id}
                  label={<TabLabel {...item.label} />}
                  aria-labelledby={item.label.title}
                  value={item.id}
                  onClick={item.onClick}
                />
              ))}
              {plusTabProps && <PlusTab key="plus-tab" {...plusTabProps} />}
            </Tabs>
            <Spacer />
            <ExtraWrapper>{props.Extra}</ExtraWrapper>
          </Wrapper2>
        </Wrapper1>
        {TabPanels}
      </TabContext>
    </Wrapper>
  );
};

RemoteInterviewTab.displayName = "RemoteInterviewTab";

export default RemoteInterviewTab;
