import "firebase/compat/database";

import { getRef, getTimestamp } from "@hireroo/firebase";
import firebase from "firebase/compat/app";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { revisionFromId, revisionToId, SyncState } from "../firepad";
import { tuple } from "../tuple";

export type ProjectRealtimeDatabaseArgs = {
  projectId: number;
  isInterviewing?: boolean;
  isCandidate: boolean;
  selectedQuestion: number;
};

export const useProjectRealtimeDatabase = (args: ProjectRealtimeDatabaseArgs) => {
  const { selectedQuestion, isCandidate, projectId, isInterviewing } = args;
  // To write the realtime log at projects/${projectId}
  const projectIndexRef = useRef<number>(0);
  const projectRef = useRef<firebase.database.Reference | undefined>();
  const projectLastStateRef = useRef<SyncState>();
  const [projectReady, setProjectReady] = useState<boolean>(false);

  // To write the realtime log at challenges/${challengeId}/questions/${questionId}
  const questionIndexRef = useRef<number>(0);
  const questionRef = useRef<firebase.database.Reference | undefined>();
  const [questionReady, setQuestionReady] = useState<boolean>(false);

  const ready = useMemo(() => {
    return projectReady && questionReady;
  }, [projectReady, questionReady]);

  const setInQuestionWrapper = useCallback(
    (newSelectedQuestion: number) => {
      if (!projectReady) return;
      projectRef.current?.child(revisionToId(projectIndexRef.current)).set({
        s: "inq",
        v: newSelectedQuestion,
        t: getTimestamp(),
      });
    },
    [projectReady],
  );

  const setOutQuestionWrapper = useCallback(
    (newSelectedQuestion: number) => {
      if (!projectReady) return;
      projectRef.current?.child(revisionToId(projectIndexRef.current)).set({
        s: "outq",
        v: newSelectedQuestion,
        t: getTimestamp(),
      });
    },
    [projectReady],
  );

  const getLatestState = (data: { [key: string]: SyncState }): { q?: number; k?: number; i?: boolean } => {
    // q: questionId, k: latest index, i: last state is "inq" or not.
    const v: { q?: number; k?: number; i?: boolean } = {};
    const inqOutq = new Set<string>();

    Object.keys(data)
      .sort()
      .forEach((key: string) => {
        if (data[key].s === "inq") inqOutq.add("inq");
        if (data[key].s === "outq") inqOutq.clear();
        v.k = revisionFromId(key);
      });
    v.i = inqOutq.size > 0;
    return v;
  };

  useEffect(() => {
    // Connect to firebase for realtime sync
    projectRef.current = getRef("project", `projects/${projectId}/state`);
    let initialLoaded = false;

    projectRef.current?.on("child_added", snapshot => {
      if (!initialLoaded) return;
      projectLastStateRef.current = snapshot.val() as SyncState;
      projectIndexRef.current = revisionFromId(snapshot.key as string) + 1;
    });

    projectRef.current?.once("value", snapshot => {
      const data: Record<string, SyncState> | null = snapshot.val();
      if (data) {
        // state under projects/${projectId}/state holds `selq` only.
        // only latest.q will be considered, and others will be ignored.
        const latest = getLatestState(data);
        if (latest.k !== undefined) projectIndexRef.current = latest.k + 1;

        // The interviewer's log is unnecessary and will not go through.
        // And, No values are recorded, even for tests that have already been completed.
        if (isCandidate && isInterviewing) {
          // If 'inq' already exists as latest, do not add 'inq'
          if (latest.i === undefined || latest.i === false) {
            setInQuestionWrapper(selectedQuestion);
          }
        }
      } else {
        if (isCandidate && isInterviewing) {
          // If this is the first landing where data does not exist, enter an initial value.
          setInQuestionWrapper(selectedQuestion);
        }
      }

      // Initial sync is done.
      initialLoaded = true;
      setProjectReady(true);
    });
  }, [isCandidate, isInterviewing, projectId, selectedQuestion, setInQuestionWrapper]);

  useEffect(() => {
    if (!projectReady) return;
    // Connect to firebase for realtime sync
    questionRef.current = getRef("project", `projects/${projectId}/questions/${selectedQuestion}/state`);
    let initialLoaded = false;

    questionRef.current?.on("child_added", snapshot => {
      if (!initialLoaded) return;
      questionIndexRef.current = revisionFromId(snapshot.key as string) + 1;
    });

    questionRef.current?.once("value", snapshot => {
      const data: Record<string, SyncState> | null = snapshot.val();
      if (data) {
        // state under projects/${projectId}/questions/${questionId}/state holds `self` only.
        // only latest.f and latest.s will be considered, and others will be ignored.
        const latest = getLatestState(data);
        if (latest.k !== undefined) questionIndexRef.current = latest.k + 1;
      }

      // Initial sync is done.
      initialLoaded = true;
      setQuestionReady(true);
    });
  }, [projectReady, projectId, selectedQuestion]);

  const isOutState = useCallback((state?: SyncState) => {
    return state?.s === "outq";
  }, []);

  // If the cleanup function that throws 'outq' is called after IndexRef.current,
  // IT MUST BE CALLED FIRST because the value will be after initialization.
  useEffect(() => {
    if (!isInterviewing) return;
    // Set project out event to firebase.
    // This works only when the question switched.
    return () => {
      if (projectReady && !isOutState(projectLastStateRef.current)) setOutQuestionWrapper(selectedQuestion);
    };
  }, [isInterviewing, isOutState, projectReady, selectedQuestion, setOutQuestionWrapper]);

  useEffect(() => {
    return () => {
      projectRef.current = undefined;
      projectIndexRef.current = 0;
      setProjectReady(false);

      questionRef.current = undefined;
      questionIndexRef.current = 0;
      setQuestionReady(false);
    };
  }, []);

  const state = {
    selectedQuestion,
    ready,
  };

  const dispatcher = {};

  return tuple(state, dispatcher);
};
