import { useSnapshot } from "valtio";

import * as Credential from "../../shared/credential";
import { state } from "./State";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitializeError = () => {
  const snapshot = useSnapshotState();
  return snapshot.initializeError;
};

export const useCurrentUid = () => {
  const uid = Credential.useUid();
  if (!uid) {
    throw new Error("Please set uid");
  }
  return uid;
};

export const useCurrentUser = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.currentUser) {
    throw new Error("Not found current user");
  }
  return snapshot.currentUser;
};
