import { extractUniqueEntity } from "@hireroo/app-helper/entity";
import { App } from "@hireroo/app-store/essential/candidate";
import { TestReport } from "@hireroo/app-store/widget/c/TestReport";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import TestReportContainer, { TestReportContainerProps } from "./Container";

export type ScreeningTestReportFetchContainerProps = {
  interviewId: string;
  linkHash: string;
  sharedLink: Graphql.InterviewDetailSharedLinkFragment;
};

const ScreeningTestReportFetchContainer: React.FC<ScreeningTestReportFetchContainerProps> = props => {
  const appStatus = App.useStatus();
  const initialized = TestReport.useInitialized();
  const [result, refresh] = Graphql.useGetInterviewReportFromSharedLinkQuery({
    variables: {
      spotId: props.interviewId,
      linkHash: props.linkHash,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "cache-and-network",
  });
  React.useEffect(() => {
    return () => {
      TestReport.clear();
    };
  }, []);
  React.useEffect(() => {
    if (result.data?.spotFromSharedLink) {
      const uniqueEntity = extractUniqueEntity(window.location.hash);
      const isValid = result.data.spotFromSharedLink.entities.some(entity => entity.entityId === uniqueEntity?.id);
      const firstUniqueEntityId = TestReport.generateUniqueEntityId(result.data.spotFromSharedLink.entities[0]);
      const entityId: TestReport.UniqueEntityId = uniqueEntity && isValid ? `${uniqueEntity.type}-${uniqueEntity.id}` : firstUniqueEntityId;
      TestReport.initialize(result.data.spotFromSharedLink, entityId);
    }
  }, [result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="TOP" />;
  }

  const containerProps: TestReportContainerProps = {
    sharedLink: props.sharedLink,
  };
  return (
    <ErrorBoundary>
      <TestReportContainer {...containerProps} />
    </ErrorBoundary>
  );
};

ScreeningTestReportFetchContainer.displayName = "TestReportFetchContainer";

export default withErrorBoundary(ScreeningTestReportFetchContainer, {});
