import { EventFrequencyTimeline, EventFrequencyTimelineProps } from "@hireroo/charts2/react/EventFrequencyTimeline";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const Wrapper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  width: 384,
  height: 236,
}));

const ContentWrapper = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const NoDataLabel = styled(Typography)(({ theme }) => ({
  position: "relative",
  width: "100%",
  textAlign: "center",
  fontWeight: 700,
  color: theme.palette.text.secondary,
  fontSize: 24,
  top: -16,
}));

type Data = {
  x: string;
  y: number;
};

type DataSource = {
  sourceId: string;
  label: string;
  data: Data[];
};

export type EventFrequencyTimelinePanelProps = {
  title: string;
  xLabel: string;
  yLabel: string;
  dataSources: DataSource[];
};

const EventFrequencyTimelinePanel: React.FC<EventFrequencyTimelinePanelProps> = props => {
  const { dataSources } = props;
  const theme = useTheme();

  const eventFrequencyTimelineProps: EventFrequencyTimelineProps = {
    options: {
      dataset: props.dataSources.map((dataSource): echarts.DatasetComponentOption => {
        return {
          id: dataSource.sourceId,
          source: dataSource.data,
        };
      }),
      xAxis: {
        type: "category",
        name: props.xLabel,
        axisTick: {
          alignWithLabel: true,
        },
      },
      yAxis: {
        splitLine: {
          lineStyle: {
            type: "dashed",
            color: theme.palette.Other.Divider,
          },
        },
        minInterval: 1,
        name: props.yLabel,
      },
      series: props.dataSources.map(dataSource => {
        return {
          name: dataSource.label,
          type: "bar",
          datasetId: dataSource.sourceId,
          stack: "A",
        };
      }),
    },
  };

  const hasData = React.useMemo(() => {
    const total = dataSources.reduce((total, dataSource) => {
      return total + dataSource.data.length;
    }, 0);
    return total > 0;
  }, [dataSources]);

  return (
    <Wrapper elevation={2}>
      <Stack spacing={2} direction="column" height="100%">
        <Box>
          <Typography variant="subtitle1">{props.title}</Typography>
        </Box>
        <ContentWrapper>
          {!hasData && <NoDataLabel variant="subtitle1">NO DATA</NoDataLabel>}
          {hasData && <EventFrequencyTimeline {...eventFrequencyTimelineProps} />}
        </ContentWrapper>
      </Stack>
    </Wrapper>
  );
};

EventFrequencyTimelinePanel.displayName = "EventFrequencyTimelinePanel";

export default EventFrequencyTimelinePanel;
