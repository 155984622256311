import { ResetPasswordStore } from "@hireroo/app-store/page/reset_password";
import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type ResetPasswordContainerProps = {};

const ResetPasswordContainer: React.FC<ResetPasswordContainerProps> = () => {
  const signInSignUpProps = useGenerateProps({});
  React.useEffect(() => {
    ResetPasswordStore.reset();
    () => {
      ResetPasswordStore.reset();
    };
  }, []);

  return (
    <ErrorBoundary>
      <Pages.SignInSignUp {...signInSignUpProps} />
    </ErrorBoundary>
  );
};

ResetPasswordContainer.displayName = "ResetPasswordContainer";

export default withErrorBoundary(ResetPasswordContainer, {});
