import type * as Graphql from "@hireroo/graphql/client/urql";
import * as React from "react";
import { useSnapshot } from "valtio";

import { state } from "./State";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useActiveStep = () => {
  const snapshot = useSnapshotState();
  return snapshot.activeStep;
};

export const useMultiQuestionTagsSearchQuery = () => {
  const snapshot = useSnapshotState();
  return snapshot.multiChoiceTagsSearchQuery;
};

export const useSelectedTags = () => {
  const snapshot = useSnapshotState();
  return Array.from(snapshot.selectedTags.values());
};

export const useOrderOfQuestionIds = () => {
  const snapshot = useSnapshotState();
  return snapshot.orderOfQuestionIds;
};

export const usePreviewQuestionId = () => {
  const snapshot = useSnapshotState();
  return snapshot.previewQuestionId;
};

export const useOpenNewQuestionEditor = () => {
  const snapshot = useSnapshotState();
  return snapshot.openNewQuestionEditor;
};

export const useMaxQuestions = () => {
  return 40;
};

export const useShowWarningDialog = () => {
  const snapshot = useSnapshotState();
  return snapshot.showWarningDialog;
};

export const useSelectedQuestionMap = () => {
  const snapshot = useSnapshotState();
  return snapshot.selectedQuestions;
};

export const useSelectedPrivateQuestions = () => {
  const questions = useSelectedQuestionMap();
  return Array.from(questions.values()).filter(q => q.isPrivate);
};

export const useGenerateOrderedQuestionKeys = () => {
  const questionSequence = useOrderOfQuestionIds();
  const selectedQuestionMap = useSelectedQuestionMap();
  return React.useCallback(() => {
    return questionSequence.reduce<Graphql.CompositePrimaryKey[]>((all, questionId) => {
      const question = selectedQuestionMap.get(questionId);
      if (!question) {
        return all;
      }
      return all.concat({
        questionId: question.id,
        version: question.version,
      });
    }, []);
  }, [questionSequence, selectedQuestionMap]);
};
