import { App, Auth } from "@hireroo/app-store/essential/employee";
import { QuestionSelectFieldForResourceEditor } from "@hireroo/app-store/widget/e/QuestionSelectFieldForResourceEditor";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { Fields } from "@hireroo/validator";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import { RemoteTemplateResourceEditorContainerProps } from "./Container";
import RemoteTemplateResourceEditorMiddleContainer, { RemoteTemplateResourceEditorMiddleContainerProps } from "./MiddleContainer";
import * as PrivateHelper from "./privateHelper";

export type RemoteTemplateResourceEditorInitialContainerProps = RemoteTemplateResourceEditorContainerProps & {
  defaultValues?: Widget.RemoteTemplateResourceEditorProviderProps["defaultValues"];
  remoteId: string;
};

const RemoteTemplateResourceEditorInitialContainer: React.FC<RemoteTemplateResourceEditorInitialContainerProps> = props => {
  const appStatus = App.useStatus();
  const currentUid = Auth.useCurrentUid();

  const [result, refresh] = Graphql.useGetRemoteForRemoteTemplateResourceEditorQuery({
    variables: {
      id: props.remoteId,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "cache-and-network",
  });

  const viewers = React.useMemo((): Fields.AssignField.AssignListItemSchema[] => {
    const { data } = result;
    const defaultViewers = (data?.remote.viewers ?? []).reduce<Fields.AssignField.AssignListItemSchema[]>((all, viewer) => {
      switch (viewer.__typename) {
        case "EmployeeGroup": {
          return all.concat({
            value: {
              type: "EMPLOYEE_GROUP",
              groupId: viewer.employeeGroupId,
            },
          });
        }
        case "User": {
          return all.concat({
            value: {
              type: "EMPLOYEE",
              employeeId: viewer.uid,
              locked: viewer.uid === currentUid,
            },
          });
        }
        case undefined:
          return all;
        default:
          throw new Error(`Invalid type: ${viewer satisfies never}`);
      }
      return all;
    }, []);
    const hasCurrentUser = defaultViewers.some(viewer => viewer.value?.type === "EMPLOYEE" && viewer.value.employeeId === currentUid);

    return hasCurrentUser
      ? defaultViewers
      : [
          {
            value: {
              type: "EMPLOYEE",
              employeeId: currentUid,
              locked: true,
            },
          },
          ...defaultViewers,
        ];
  }, [currentUid, result]);

  React.useEffect(() => {
    if (result.data?.remote) {
      QuestionSelectFieldForResourceEditor.setQuestionByEntitySourceQuestions(
        PrivateHelper.convertSessionsToEntitySourceQuestions(result.data.remote.entity?.liveCoding?.sessions ?? []),
      );
    }
  }, [result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED") {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const middleProps: RemoteTemplateResourceEditorMiddleContainerProps = {
    ...props,
    defaultValues: result.data?.remote
      ? {
          remote: {
            name: result.data.remote.name,
            candidateName: result.data.remote.candidateName,
            memo: result.data.remote.memo,
            isPublic: result.data.remote.isPublic,
            editorUid: currentUid,
            viewers: viewers,
            tags: result.data.remote.tags.map(t => ({ value: { name: t.name } })),
          },
          entityTracks: PrivateHelper.convertEntityTracksValidatorFromSessionGraphql(result.data.remote.entity?.liveCoding?.sessions ?? []),
          templateTitle: "",
          templateDescription: null,
          variables: [],
        }
      : undefined,
  };

  return <RemoteTemplateResourceEditorMiddleContainer {...middleProps} />;
};

RemoteTemplateResourceEditorInitialContainer.displayName = "RemoteTemplateResourceEditorMiddleContainer";

export default withErrorBoundary(RemoteTemplateResourceEditorInitialContainer, {});
