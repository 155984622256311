import { useTranslation } from "@hireroo/i18n";
import Grid from "@mui/material/Grid";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.grey["800"],
  borderRadius: "12px",
  padding: "12px",
}));

export type EvaluationItemProps = {
  id: number;
  title: string;
  comment: string;
  numStars: number;
};

const EvaluationItem: React.FC<EvaluationItemProps> = props => {
  const { t } = useTranslation();
  return (
    <Grid item xs={6}>
      <StyledStack direction="column" spacing={1.5}>
        <Stack direction="row" display="flex" justifyContent="space-between" width="100%">
          <Typography fontSize={14} fontWeight={700}>
            {props.title}
          </Typography>
          <Rating max={4} value={props.numStars} readOnly />
        </Stack>
        <Typography variant="body2" color="textSecondary" sx={{ overflowWrap: "anywhere" }}>
          {props.comment || t("入力がありません。")}
        </Typography>
      </StyledStack>
    </Grid>
  );
};

EvaluationItem.displayName = "EvaluationItem";

export default EvaluationItem;
