import { AlgorithmSignatureForm, AlgorithmTestCaseForm } from "@hireroo/validator";

export const generateDefaultAlgorithmCorrectnessTC = (
  signature: AlgorithmSignatureForm.AlgorithmSignatureSchemaType,
): AlgorithmTestCaseForm.CorrectnessTestCaseSchema => {
  const inputs = signature?.inputs ?? [];
  return {
    title_ja: "",
    description_ja: "",
    title_en: "",
    description_en: "",
    inputs: inputs.map(() => ""),
    outputs: [""],
    is_hidden: false,
  };
};

export const generateDefaultAlgorithmPerformanceTC = (
  signature: AlgorithmSignatureForm.AlgorithmSignatureSchemaType,
): AlgorithmTestCaseForm.PerformanceTestCaseSchema => {
  const inputs = signature?.inputs ?? [];
  return {
    label: "",
    inputs: inputs.map(() => ""),
    outputs: [""],
  };
};

export const generateDefaultDatabaseCorrectnessTC = (
  signature: AlgorithmSignatureForm.DatabaseSignatureSchemaType,
): AlgorithmTestCaseForm.CorrectnessTestCaseSchema => {
  const inputs = signature?.tables ?? [];
  return {
    title_ja: "",
    description_ja: "",
    title_en: "",
    description_en: "",
    inputs: inputs.map(() => ""),
    outputs: ["[]"],
    is_hidden: false,
  };
};

export const generateDefaultDatabasePerformanceTC = (
  signature: AlgorithmSignatureForm.DatabaseSignatureSchemaType,
): AlgorithmTestCaseForm.PerformanceTestCaseSchema => {
  const inputs = signature?.tables ?? [];
  return {
    label: "",
    inputs: inputs.map(() => ""),
    outputs: ["[]"],
  };
};

export const generateDefaultClassCorrectnessTC = (
  signature: AlgorithmSignatureForm.ClassSignatureSchemaType,
): AlgorithmTestCaseForm.CorrectnessTestCaseSchema => {
  const inputs = signature?.constructor?.inputs ?? [];
  return {
    title_ja: "",
    description_ja: "",
    title_en: "",
    description_en: "",
    inputs: inputs.map(() => ""),
    outputs: ["[]"],
    is_hidden: false,
  };
};

export const generateDefaultClassPerformanceTC = (
  signature: AlgorithmSignatureForm.ClassSignatureSchemaType,
): AlgorithmTestCaseForm.PerformanceTestCaseSchema => {
  const inputs = signature?.constructor?.inputs ?? [];
  return {
    label: "",
    inputs: inputs.map(() => ""),
    outputs: ["[]"],
  };
};
