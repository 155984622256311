import * as z from "zod";

export const AccessEventSchema = z.object({
  /** type of event, i.e. access */
  s: z.string(),
  /** ip address of a user */
  l: z.string().optional(),
  /** geolocation of a user */
  g: z.string().optional(),
  /** uid */
  a: z.string(),
  /** timestamp */
  t: z.number(),
});

const PasteEventValue = z.array(z.union([z.string(), z.number()]));

const CutCopyEventValue = z.array(z.object({ text: z.string(), position: z.number(), selectionEnd: z.number() }));

export const ClipboardEvent = z.object({
  /** type of clipboard event, i.e. cut/copy/paste */
  s: z.enum(["cut", "copy", "paste"]),
  /** uid */
  a: z.string(),
  /** timestamp */
  t: z.number(),
  /** clipboard value object */
  v: z.union([PasteEventValue, CutCopyEventValue]),
});

export type ClipboardEvent = z.infer<typeof ClipboardEvent>;

export const ClipboardEvents = z.record(z.string(), ClipboardEvent);

export const AccessEvents = z.record(z.string(), AccessEventSchema);
