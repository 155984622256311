import { useTranslation } from "@hireroo/i18n";
import { PlaybackSettingsForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowRightOutlined } from "@mui/icons-material";
import SettingsIcon from "@mui/icons-material/Settings";
import FormControlLabel from "@mui/material/FormControlLabel";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled, useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import SwitchControl from "../../primitive/InputControl/SwitchControl/SwitchControl";
import PlaybackModeSelector, { PlaybackModeSelectorProps } from "./parts/PlaybackModeSelector/PlaybackModeSelector";
import SquareButton, { SquareButtonProps } from "./parts/SquareButton/SquareButton";

const StyledMenu = styled(Menu)(() => ({
  ".MuiMenu-paper": {
    minWidth: 250,
  },
}));

const StyledMenuItem = styled(MenuItem)({
  padding: 0,
  root: {
    "&.Mui-disabled": {
      pointerEvents: "auto",
    },
  },
});

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  padding: "6px 16px",
  margin: 0,
  ".MuiFormControlLabel-label": {
    width: "100%",
  },
}));

type MenuItemProps = {
  name: keyof PlaybackSettingsForm.PlaybackSettingsFormSchema;
  text: string;
  /** @default false */
  disabled: boolean;
  disabledTooltipText: string;
};

type ItemProps = Pick<MenuItemProps, "disabled" | "disabledTooltipText">;

export type ProjectPlaybackSettingsMenuProps = {
  items: {
    copyAndPasteDetection?: ItemProps;
    behavioralControl?: ItemProps;
  };
  defaultValues: PlaybackSettingsForm.ProjectPlaybackSettingsFormSchema;
  onSubmit?: SubmitHandler<PlaybackSettingsForm.ProjectPlaybackSettingsFormSchema>;
};

const ProjectPlaybackSettingsMenu: React.FC<ProjectPlaybackSettingsMenuProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const validateSchema = PlaybackSettingsForm.useProjectPlaybackSettingsForm();
  const { items: inputItems, onSubmit } = props;

  const methods = useForm<PlaybackSettingsForm.ProjectPlaybackSettingsFormSchema>({
    defaultValues: props.defaultValues,
    resolver: zodResolver(validateSchema),
    mode: "onChange",
  });
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = React.useMemo(() => Boolean(anchorEl), [anchorEl]);
  const playbackMode = methods.watch("playbackMode");
  const [modeAnchorEl, setModeAnchorEl] = React.useState<HTMLElement | null>(null);

  const playbackModeMap: Record<PlaybackSettingsForm.ProjectPlaybackSettingsFormSchema["playbackMode"], string> = {
    TIMELINE: t("時系列プレイバックモード"),
    EACH_FILE: t("ファイル別プレイバックモード"),
  };

  const handleClickSettingsMenuButton = React.useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseSettingsMenu = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const settingsMenuButton: SquareButtonProps = React.useMemo(
    () => ({
      children: <SettingsIcon />,
      onClick: event => {
        handleClickSettingsMenuButton(event);
      },
    }),
    [handleClickSettingsMenuButton],
  );

  const items: MenuItemProps[] = React.useMemo((): MenuItemProps[] => {
    const innerItems: MenuItemProps[] = [];
    if (inputItems.behavioralControl) {
      innerItems.push({
        ...inputItems.behavioralControl,
        name: "enabledBehavioralControl",
        text: [t("Google検索"), "ChatGPT"].join("/"),
      });
    }
    if (inputItems.copyAndPasteDetection) {
      innerItems.push({
        ...inputItems.copyAndPasteDetection,
        name: "enabledCopyAndPasteDetection",
        text: t("ペースト検知"),
      });
    }
    return innerItems;
  }, [inputItems, t]);

  React.useEffect(() => {
    const subscriber = methods.watch(() => {
      const fields = methods.getValues();
      onSubmit?.(fields);
    });
    return () => {
      subscriber.unsubscribe();
    };
  }, [methods, onSubmit]);

  const handlePlaybackModeClick = React.useCallback((event: React.MouseEvent<HTMLLIElement>) => {
    setModeAnchorEl(event.currentTarget);
  }, []);

  const modeSelector: PlaybackModeSelectorProps = {
    menu: {
      anchorEl: modeAnchorEl,
      onClose: () => {
        setModeAnchorEl(null);
      },
    },
  };

  return (
    <FormProvider {...methods}>
      <StyledMenu
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseSettingsMenu}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <StyledMenuItem
          disableRipple
          sx={{
            alignItems: "center",
            padding: "6px 16px",
          }}
          onClick={handlePlaybackModeClick}
        >
          <ListItemText>{playbackModeMap[playbackMode]}</ListItemText>
          <ListItemIcon sx={{ display: "flex", justifyContent: "end" }}>
            <ArrowRightOutlined htmlColor={theme.palette.text.secondary} fontSize="small" />
          </ListItemIcon>
        </StyledMenuItem>
        {items.map((item, index) => {
          return (
            <Tooltip key={`${index}-${item.name}`} title={item.disabled ? item.disabledTooltipText : ""}>
              <span>
                <StyledMenuItem disabled={item.disabled} disableRipple>
                  <StyledFormControlLabel
                    labelPlacement="start"
                    control={
                      <span>
                        <SwitchControl name={item.name} color="secondary" size="small" />
                      </span>
                    }
                    label={<ListItemText>{item.text}</ListItemText>}
                  />
                </StyledMenuItem>
              </span>
            </Tooltip>
          );
        })}
      </StyledMenu>
      <SquareButton {...settingsMenuButton} />
      <PlaybackModeSelector {...modeSelector} />
    </FormProvider>
  );
};

ProjectPlaybackSettingsMenu.displayName = "ProjectPlaybackSettingsMenu";

export default ProjectPlaybackSettingsMenu;
