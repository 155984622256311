import ComputerRoundedIcon from "@mui/icons-material/ComputerRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const MarkMap = {
  COMPUTER: <ComputerRoundedIcon fontSize="small" />,
  WARNING: <WarningRoundedIcon fontSize="small" />,
} as const;

const Wrapper = styled(Stack)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(1.5),
  "&[data-highlight=true]": {
    backgroundColor: theme.palette.action.selected,
  },
}));

const StyledButton = styled(Button)(() => ({
  padding: 0,
  minWidth: 0,
  textAlign: "left",
}));

const MarkWrapper = styled(Box)(({ theme }) => ({
  width: 20,
  height: 20,
  borderRadius: "50%",
  position: "relative",
  "&[data-kind=COMPUTER]": {
    backgroundColor: theme.palette.action.active,
  },
  "&[data-kind=WARNING]": {
    backgroundColor: theme.palette.warning.main,
  },
  svg: {
    color: theme.palette.primary.contrastText,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: 12,
    width: 12,
  },
}));

const Heading = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: 14,
  textTransform: "none",
}));

const ValueLabel = styled("span")(({ theme }) => ({
  marginLeft: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const TimeLabel = styled(Typography)(({ theme }) => ({
  display: "inline-block",
  width: "fit-content",
  color: theme.palette.text.primary,
  padding: "2px 4px",
  backgroundColor: theme.palette["Secondary/Shades"].p16,
  borderRadius: "4px",
  fontSize: 14,
  textTransform: "none",
}));

export type ActivityItemProps = {
  highlight?: boolean;
  mark: keyof typeof MarkMap;
  heading: {
    kind: string;
    value?: string;
  };
  timeLabel: string;
  onClick?: () => void;
  onHighlight?: (element: HTMLButtonElement) => void;
};

const ActivityItem: React.FC<ActivityItemProps> = props => {
  const { onHighlight, highlight } = props;
  const wrapperRef = React.createRef<HTMLButtonElement>();
  React.useEffect(() => {
    if (highlight && wrapperRef.current) {
      onHighlight?.(wrapperRef.current);
    }
  }, [highlight, onHighlight, wrapperRef]);
  return (
    <StyledButton color="secondary" onClick={props.onClick} ref={wrapperRef}>
      <Wrapper direction="row" spacing={2} data-highlight={props.highlight}>
        <MarkWrapper data-kind={props.mark}>{MarkMap[props.mark]}</MarkWrapper>
        <Stack spacing={0.5} direction="column">
          <Heading>
            {props.heading.kind}
            <ValueLabel>{props.heading.value}</ValueLabel>
          </Heading>
          <TimeLabel>{props.timeLabel}</TimeLabel>
        </Stack>
      </Wrapper>
    </StyledButton>
  );
};

ActivityItem.displayName = "ActivityItem";

export default ActivityItem;
