import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";

import Split, { SplitProps } from "../../../primitive/Split/Split";
import QuestionAndAnswerTab, { QuestionAndAnswerTabProps } from "../../Question/QuestionAndAnswerTab/QuestionAndAnswerTab";
import QuestionInformationSidePanel, {
  QuestionInformationSidePanelProps,
} from "../../Question/QuestionInformationSidePanel/QuestionInformationSidePanel";

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  width: 100%;
  max-height: 100vh;
  height: 100%;
`;

const SidebarWrapper = styled(Box)`
  height: 100%;
`;

const SplitParams = {
  splitId: "ChallengeCodingEditor",
  Contents: {
    LeftSideBar: {
      id: "LeftSideBar",
    },
    RightSideBar: {
      id: "RightSideBar",
    },
  },
};

export type QuestionDetailLayoutProps = {
  sidebar: QuestionAndAnswerTabProps;
  items: SplitProps["items"];
} & Pick<QuestionInformationSidePanelProps, "stats" | "detailInfo" | "feedbackLink" | "scoreHistogram">;

const QuestionDetailLayout: React.FC<QuestionDetailLayoutProps> = props => {
  const theme = useTheme();
  const questionInformationSidePanelProps: QuestionInformationSidePanelProps = {
    stats: props.stats,
    detailInfo: props.detailInfo,
    feedbackLink: props.feedbackLink,
    scoreHistogram: props.scoreHistogram,
  };
  const splitProps: SplitProps = {
    splitId: SplitParams.splitId,
    splitDirection: "VERTICAL",
    items: [
      {
        id: SplitParams.Contents.LeftSideBar.id,
        Content: (
          <SidebarWrapper key="sidebar" bgcolor={theme.palette["Secondary/Shades"].p8}>
            <QuestionAndAnswerTab {...props.sidebar} />
          </SidebarWrapper>
        ),
        size: {
          value: 400,
          unit: "px",
        },
        minimizedSize: {
          value: 0,
          unit: "px",
        },
        expandedSize: {
          value: 400,
          unit: "px",
        },
      },
      ...props.items,
      {
        id: SplitParams.Contents.RightSideBar.id,
        Content: <QuestionInformationSidePanel {...questionInformationSidePanelProps} />,
        size: {
          value: 400,
          unit: "px",
        },
        minimizedSize: {
          value: 0,
          unit: "px",
        },
        expandedSize: {
          value: 400,
          unit: "px",
        },
      },
    ],
  };
  return (
    <Wrapper>
      <Split {...splitProps} />
    </Wrapper>
  );
};

QuestionDetailLayout.displayName = "QuestionDetailLayout";

export default QuestionDetailLayout;
