import { getRef } from "@hireroo/firebase";
import { RdbKey } from "@hireroo/project-shared-utils";
import { BrowserEvent, PlaybackEvent } from "@hireroo/validator";
import * as Sentry from "@sentry/browser";

import { fetchProjectFileHistoryRevisions, fetchProjectHistoriesPaths, fetchQuestionEvent } from "../firepad";
import { PlaybackManager, PlaybackTickManager } from "../playback";

type HumanReadablePath = string;
type EncodedPathWithId = string; // decoded example: "src/index.tsx:0"

export type CreatePlaybackManagerArgs = {
  projectId: number;
  questionId: number;
  encodedFilePath: EncodedPathWithId;
};

export const createPlaybackManager = async (args: CreatePlaybackManagerArgs) => {
  const playbackTickManager = new PlaybackTickManager();
  const historyRef = getRef("project", `projects/${args.projectId}/questions/${args.questionId}/histories/files/${args.encodedFilePath}`);
  const questionRef = getRef("project", `projects/${args.projectId}/questions/${args.questionId}`);

  const [revisions, questionEvents] = await Promise.all([fetchProjectFileHistoryRevisions(historyRef), fetchQuestionEvent(questionRef)]).catch(
    error => {
      Sentry.captureException(error);
      return [];
    },
  );

  questionEvents.forEach(questionEvent => {
    const result1 = BrowserEvent.BlurEventPayload.safeParse(questionEvent);
    if (result1.success) {
      playbackTickManager.addTick(questionEvent.t, {
        kind: "BROWSER_BLUR",
        ts: questionEvent.t,
      });
      return;
    }

    const result2 = BrowserEvent.HiddenEventPayload.safeParse(questionEvent);
    if (result2.success) {
      playbackTickManager.addTick(questionEvent.t, {
        kind: "BROWSER_HIDDEN",
        ts: questionEvent.t,
        reason: (() => {
          if (!result2.data.r) {
            return "UNKNOWN";
          }
          if (result2.data.r.k === "clickElement") {
            return "CLICK_ON_LINK";
          }
          if (result2.data.r.k === "googleSearch") {
            return "GOOGLE_SEARCH";
          }
          return "UNKNOWN";
        })(),
      });
      return;
    }

    const result3 = BrowserEvent.VisibleEventPayload.safeParse(questionEvent);
    if (result3.success) {
      playbackTickManager.addTick(questionEvent.t, {
        kind: "BROWSER_VISIBLE",
        ts: questionEvent.t,
      });
      return;
    }

    const result4 = BrowserEvent.AccessEventPayload.safeParse(questionEvent);
    if (result4.success) {
      playbackTickManager.addTick(questionEvent.t, {
        kind: "ACCESS",
        ipAddress: result4.data.l,
        geometry: result4.data.g || undefined,
        ts: questionEvent.t,
      });
      return;
    }

    const result5 = BrowserEvent.FocusEventPayload.safeParse(questionEvent);
    if (result5.success) {
      playbackTickManager.addTick(questionEvent.t, {
        kind: "BROWSER_FOCUS",
        ts: questionEvent.t,
      });
      return;
    }
  });

  //TODO: add clipboard events

  revisions.forEach(revision => {
    const syncOperation = PlaybackEvent.SyncOperation.safeParse(revision);
    if (syncOperation.success) {
      playbackTickManager.addTick(revision.t, {
        kind: "CODE_EDITOR",
        textOperations: syncOperation.data.o,
        userId: syncOperation.data.a,
        key: revision.k ?? "",
        ts: revision.t,
      });
    }
  });

  const playbackManager = new PlaybackManager(playbackTickManager);

  playbackManager.initialize();

  return playbackManager;
};

export type FetchQuestionRecordsArgs = {
  projectId: number;
  questionId: number;
};

export const fetchLatestPathMap = async (args: FetchQuestionRecordsArgs) => {
  const historiesRef = getRef("project", `projects/${args.projectId}/questions/${args.questionId}/histories`);

  const pathsHistories = await fetchProjectHistoriesPaths(historiesRef);

  const latestEncodedPathWithIdMap = new Map<HumanReadablePath, EncodedPathWithId>();
  Object.keys(pathsHistories).forEach(encodedPath => {
    const decodedPath = RdbKey.decodeFirebaseKey(encodedPath);
    const pathHistoryValues = pathsHistories[encodedPath];
    const latestPathHistory = pathHistoryValues[pathHistoryValues.length - 1];
    const encodedPathWithId = RdbKey.encodeFirebaseKey(`${decodedPath}:${latestPathHistory.id}`);
    latestEncodedPathWithIdMap.set(decodedPath, encodedPathWithId);
  });

  return latestEncodedPathWithIdMap;
};
