import CodeEditor, { CodeEditorProps } from "@hireroo/code-editor/react/CodeEditor";
import Markdown from "@hireroo/markdown-v2/react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup, { ToggleButtonGroupProps } from "@mui/material/ToggleButtonGroup";
import * as React from "react";

const Wrapper = styled(Box)(() => ({
  display: "flex",
  flex: 1,
  scrollbarWidth: "none",
  /* Safari and Chrome */
  "&::-webkit-scrollbar": {
    display: "none",
  },
}));

export type MarkdownPreviewCodeEditorProps = {
  codeEditor: CodeEditorProps;
  value: string;
};

const StyledPreviewArea = styled(Box)(() => ({
  boxSizing: "border-box",
  padding: "12px",
  width: "100%",
  minHeight: "350px",
  height: "100%",
  overflowY: "auto",
  wordBreak: "break-word",
  "& *:first-of-type": {
    marginTop: "6px",
  },
}));

const alignmentMap = {
  WRITE: "WRITE",
  PREVIEW: "PREVIEW",
};

const MarkdownPreviewCodeEditor: React.FC<MarkdownPreviewCodeEditorProps> = ({ ...props }) => {
  const [alignment, updateAlignment] = React.useState(alignmentMap.WRITE);

  const codeEditorProps: CodeEditorProps = {
    ...props.codeEditor,
    height: "350px",
    language: "markdown",
    options: {
      ...props.codeEditor.options,
      readOnly: false,
      lineNumbers: "off",
    },
  };

  const toggleButtonGroupProps: ToggleButtonGroupProps = {
    color: "primary",
    size: "small",
    value: alignment,
    exclusive: true,
    onChange: (_e, newValue) => {
      if (Object.values(alignmentMap).includes(newValue)) {
        updateAlignment(newValue);
      }
    },
  };

  const Preview = (
    <React.Suspense>
      <Markdown children={props.value} size="middle" />
    </React.Suspense>
  );

  return (
    <Wrapper flexDirection="column" overflow="hidden" height="100%">
      <Box overflow={alignment === alignmentMap.PREVIEW ? "hidden" : undefined}>
        {alignment === alignmentMap.WRITE && <CodeEditor {...codeEditorProps} />}
        {alignment === alignmentMap.PREVIEW && <StyledPreviewArea>{Preview}</StyledPreviewArea>}
      </Box>
      <Box display="flex" justifyContent="right">
        <ToggleButtonGroup {...toggleButtonGroupProps}>
          <ToggleButton value={alignmentMap.WRITE} color="secondary" size="small">
            <EditOutlinedIcon fontSize="small" />
          </ToggleButton>
          <ToggleButton value={alignmentMap.PREVIEW} color="secondary" size="small">
            <VisibilityOutlinedIcon fontSize="small" />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Wrapper>
  );
};

MarkdownPreviewCodeEditor.displayName = "MarkdownPreviewCodeEditor";

export default MarkdownPreviewCodeEditor;
