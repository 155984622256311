import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import FormControlLabel, { FormControlLabelProps } from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useController } from "react-hook-form";

type FieldValue = string[];

type MultipleChoiceOption = {
  value: string;
  displayName: string;
  iconSrc?: string;
};

export type MultiChoiceFieldProps = {
  name: string;
  className?: string;
  options: MultipleChoiceOption[];
  onChange?: (value: FieldValue) => void;
  disabled?: boolean;
  /**
   * @default true
   *
   * If this flag is turned off, the State managed by the react-hook-form is not updated,
   * so the user of this component must update the state of the react-hook-form using the value obtained by `onChange`.
   */
  watch?: boolean;
};

const MultiChoiceField: React.FC<MultiChoiceFieldProps> = props => {
  const { onChange, watch = true } = props;
  const { field } = useController<Record<string, FieldValue>>({
    name: props.name,
  });
  const [selectedValue, setSelectedValue] = React.useState<string[]>(field.value);

  const { onChange: onChangeField } = field;

  /**
   * The following warning is prevented by performing a state change that may belong to the parent component after the setState of this component is complete.
   *
   * Warning: Cannot update a component while rendering a different component
   */
  React.useEffect(() => {
    if (watch) {
      onChangeField(selectedValue);
    }
    onChange?.(selectedValue);
  }, [onChangeField, onChange, watch, selectedValue]);

  const handleChange: Exclude<CheckboxProps["onChange"], undefined> = React.useCallback(({ target: { value } }, checked) => {
    if (typeof value === "string") {
      setSelectedValue(prev => {
        return checked ? prev.concat(value) : prev.filter(prevValue => prevValue !== value);
      });
    }
  }, []);

  return (
    <FormGroup className={props.className}>
      {props.options.map(option => {
        const Icon = typeof option.iconSrc === "string" ? <Avatar sx={{ width: 20, height: 20, mr: 1 }} src={option.iconSrc} /> : null;
        const checked = watch ? field.value.includes(option.value) : selectedValue.includes(option.value);
        const formControlLabelProps: FormControlLabelProps = {
          control: <Checkbox onChange={handleChange} checked={checked} color="secondary" disabled={props.disabled} />,
          label: (
            <Box component="span" display="flex">
              {Icon}
              <Typography variant="body2">{option.displayName}</Typography>
            </Box>
          ),
          value: option.value,
        };
        return <FormControlLabel key={option.value} {...formControlLabelProps} />;
      })}
    </FormGroup>
  );
};

MultiChoiceField.displayName = "MultiChoiceField";

export default MultiChoiceField;
