import { styled } from "@mui/material/styles";
import Typography, { TypographyProps } from "@mui/material/Typography";
import * as React from "react";

type HeadingClass = "h5" | "h6" | "subtitle1" | "subtitle2";

const PaddingBottomMap: Record<HeadingClass, `${number}em` | undefined> = {
  h5: undefined,
  /** h6 has border bottom */
  h6: "0.5em",
  subtitle1: undefined,
  subtitle2: undefined,
};

const MarginBottomMap: Record<HeadingClass, `${number}em`> = {
  h5: "1em",
  /** h6 has border bottom */
  h6: "0.75em",
  subtitle1: "1.3em",
  subtitle2: "1.2em",
};

const StyledHeading = styled(Typography)<{ ownerState: { variant: HeadingClass } }>(({ ownerState, theme }) => ({
  marginTop: 0,
  paddingBottom: PaddingBottomMap[ownerState.variant],
  borderBottom: ownerState.variant === "h6" ? `1px solid ${theme.palette.Other.Divider}` : undefined,
  marginBottom: MarginBottomMap[ownerState.variant] ?? "1em",
  color: theme.palette.text.primary,
  fontWeight: "bold",
}));

export type HeadingProps = TypographyProps & {
  level: number;
  children: React.ReactNode[];
};

const Heading: React.FC<HeadingProps> = props => {
  const { level } = props;
  let variant: HeadingClass;
  switch (level) {
    case 1:
      variant = "h5";
      break;
    case 2:
      variant = "h6";
      break;
    case 3:
      variant = "subtitle1";
      break;
    default:
      variant = "h6";
      break;
  }

  return (
    <StyledHeading gutterBottom variant={variant} ownerState={{ variant }}>
      {props.children}
    </StyledHeading>
  );
};

Heading.displayName = "Heading";

export default Heading;
