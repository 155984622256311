import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import * as React from "react";

import TestReportEvaluationItem, { TestReportEvaluationItemProps } from "../../../modules/TestReportEvaluationItem/TestReportEvaluationItem";

type Item = Pick<TestReportEvaluationItemProps, "value" | "icon">;

export type ReportSystemDesignAnswerDetailSectionProps = {
  totalElapsedTime: Item;
  runNum: Item;
  usedHint?: Item;
  /**
   * When this flag is true, additional information will be displayed by means of tooltips.
   */
  canShowTooltip: boolean;
};

const ReportSystemDesignAnswerDetailSection: React.FC<ReportSystemDesignAnswerDetailSectionProps> = props => {
  const { t } = useTranslation();
  const leftSections: TestReportEvaluationItemProps[] = [
    {
      ...props.totalElapsedTime,
      title: t("経過時間"),
      canShowTooltip: props.canShowTooltip,
    },
    {
      ...props.runNum,
      title: t("実行回数"),
      canShowTooltip: props.canShowTooltip,
    },
  ];

  const rightSections: TestReportEvaluationItemProps[] = React.useMemo(() => {
    return [
      props.usedHint && {
        ...props.usedHint,
        title: t("使用したヒント"),
        canShowTooltip: props.canShowTooltip,
      },
    ].filter(value => !!value);
  }, [props.usedHint, props.canShowTooltip, t]);

  return (
    <Box>
      <Typography variant="subtitle1" sx={theme => ({ fontWeight: "bold", color: theme.palette.text.secondary })} mb={3}>
        {t("解答詳細")}
      </Typography>
      <Grid container columnSpacing={1}>
        <Grid item xs={6}>
          <List component="nav" aria-label="answer-detail-1">
            {leftSections.map(section => (
              <TestReportEvaluationItem key={section.title} {...section} />
            ))}
          </List>
        </Grid>
        {rightSections.length > 0 && (
          <Grid item xs={6}>
            <List component="nav" aria-label="answer-detail-1">
              {rightSections.map(section => (
                <TestReportEvaluationItem key={section.title} {...section} />
              ))}
            </List>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

ReportSystemDesignAnswerDetailSection.displayName = "ReportSystemDesignAnswerDetailSection";

export default ReportSystemDesignAnswerDetailSection;
