import { useTranslation } from "@hireroo/i18n";
import EditorBG from "@hireroo/project-assets/images/EditorBG.svg";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type IdlePanelProps = {
  localDevelopmentHelpUrl?: string;
  messages: string[];
};

const StyledBox = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

const IdlePanel: React.FC<IdlePanelProps> = props => {
  const { t } = useTranslation();
  return (
    <StyledBox>
      <img loading="lazy" src={EditorBG} width={200} height={200} />
      <Stack spacing={2}>
        <Box>
          {props.messages.map(message => (
            <Typography key={message}>{message}</Typography>
          ))}
        </Box>
        {props.localDevelopmentHelpUrl && (
          <Box>
            <Typography sx={{ mr: 1 }}>{t("ローカル環境でご自身のIDE等を使って開発することもできます。")}</Typography>
            <Typography>
              <Link
                href={props.localDevelopmentHelpUrl}
                target="_blank"
                rel="noreferrer"
                color="secondary"
                underline="hover"
                sx={{ cursor: "pointer" }}
              >
                {t("ローカル環境での開発方法について")}
              </Link>
            </Typography>
          </Box>
        )}
      </Stack>
    </StyledBox>
  );
};

IdlePanel.displayName = "IdlePanel";

export default IdlePanel;
