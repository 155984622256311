import BaseSplit, { SplitProps as BaseSplitProps } from "@hireroo/react-split";
import { styled } from "@mui/material/styles";

const StyledSplit = styled(BaseSplit)(({ theme }) => ({
  '&[data-direction*="vertical"]': {
    display: "flex",
    flexDirection: "row",
    margin: 0,
    padding: 0,
    height: "100%",
    width: "100%",
  },
  '&[data-direction*="horizontal"]': {
    display: "flex",
    flexDirection: "column",
    margin: 0,
    padding: 0,
    height: "100%",
    width: "100%",
  },
  ".pane": {
    margin: 0,
    padding: 0,
    /**
     * TODO @himenon I'll get around to it before Light Theme support.
     */
    backgroundColor: "#232323",
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    /** Do not allow pane expansion and contraction */
    flexShrink: 0,
    flexGrow: 0,
    "&:hover": {
      transition: "all .25s ease",
      backgroundColor: theme.palette.secondary.main,
    },
    '&[data-direction="vertical"]': {
      backgroundImage: `url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==')`,
      cursor: "col-resize",
    },
    '&[data-direction*="horizontal"]': {
      backgroundImage: `url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=')`,
      cursor: "row-resize",
    },
  },
  ".pane-dragging": {
    backgroundColor: theme.palette.secondary.main,
  },
  ".content-wrapper": {
    height: "100%",
  },
}));

export type SplitProps = BaseSplitProps;

const Split: React.FC<SplitProps> = props => {
  const splitProps: SplitProps = {
    ...props,
    Pane: {
      defaultClassName: "pane",
      draggingClassName: "pane-dragging",
      defaultPaneSize: 5,
    },
    Wrapper: {
      defaultClassName: "content-wrapper",
    },
  };
  return <StyledSplit {...splitProps} />;
};

Split.displayName = "Split";

export default Split;
