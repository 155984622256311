import * as z from "zod";

export const QuestionVariant = z.enum([
  "CHALLENGE",
  "QUIZ",
  "PROJECT",
  "SYSTEM_DESIGN",
  "FREEPAD",
  "CHALLENGE_ALGORITHM",
  "CHALLENGE_DATABASE",
  "CHALLENGE_CLASS",
  "PROJECT_FRONTEND",
  "PROJECT_BACKEND",
  "PROJECT_DATA_SCIENCE",
  "PROJECT_OTHER",
]);
export type QuestionVariant = z.infer<typeof QuestionVariant>;

export const QuestionCompositePrimaryKey = z.object({
  questionId: z.number(),
  version: z.string(),
  questionVariant: QuestionVariant,
});

export type QuestionCompositePrimaryKey = z.infer<typeof QuestionCompositePrimaryKey>;

export const QuestionCompositePrimaryKeys = QuestionCompositePrimaryKey.array();

export type QuestionCompositePrimaryKeys = z.infer<typeof QuestionCompositePrimaryKeys>;
