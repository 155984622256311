import { INITIAL_VERSION } from "@hireroo/app-definition/question";
import { FreepadResourceEditor } from "@hireroo/app-store/view-domain/FreepadResourceEditor";
import { defaultLanguageMap } from "@hireroo/challenge/definition";
import { Pages } from "@hireroo/presentation/legacy";
import { FreepadAnswerForm } from "@hireroo/validator";
import * as React from "react";

type QuestionDetailDefaultValues = Pages.FreepadResourceEditorProviderProps["questionDetailProvider"]["defaultValues"];
type QuestionType = QuestionDetailDefaultValues["questions"][0];

export const useQuestionDetailDefaultValues = (): Pages.FreepadResourceEditorProviderProps["questionDetailProvider"] => {
  const variant = FreepadResourceEditor.useVariant();
  const question = FreepadResourceEditor.useQuestion();
  const lang = FreepadResourceEditor.useInitialLanguage();

  const defaultQuestions = React.useMemo((): QuestionType[] => {
    if (!question) {
      return [
        {
          title: "",
          description: "",
          language: lang,
        },
      ];
    }
    const questionsField: QuestionType[] = [];
    if (question.titleJa && question.descriptionJa) {
      const questionField: QuestionType = {
        language: "ja",
        title: question.titleJa,
        description: question.descriptionJa,
      };
      questionsField.push(questionField);
    }

    if (question.titleEn && question.descriptionEn) {
      const questionField: QuestionType = {
        language: "en",
        title: question.titleEn,
        description: question.descriptionEn,
      };
      questionsField.push(questionField);
    }

    return questionsField;
  }, [lang, question]);

  return React.useMemo(() => {
    const questionVariant = question?.variant === "ALGORITHM" || question?.variant === "SYSTEM_DESIGN" ? question.variant : undefined;
    return {
      defaultValues: {
        isPublic: question ? !question.isPrivate : false,
        variant: questionVariant ?? variant,
        difficulty: question?.difficulty ?? "EASY",
        questions: defaultQuestions,
      },
    };
  }, [defaultQuestions, question, variant]);
};

type AnswerDefaultValues = Pages.FreepadResourceEditorProviderProps["answerProvider"]["defaultValues"]["answers"][0];
type ComponentType = FreepadAnswerForm.FreepadAnswersFormSchema["answers"][0]["answerFlowCharts"][0]["componentType"];
export const useAnswersDefaultValues = (): Pages.FreepadResourceEditorProviderProps["answerProvider"] => {
  const variant = FreepadResourceEditor.useVariant();
  const question = FreepadResourceEditor.useQuestion();
  const initLanguage = FreepadResourceEditor.useInitialLanguage();
  const templateCode = FreepadResourceEditor.useTemplateCode();
  const answers = React.useMemo((): AnswerDefaultValues[] => {
    if (question?.answers && question.answers.length > 0) {
      return question.answers.map((answer): AnswerDefaultValues => {
        const contents: FreepadAnswerForm.AnswersContentSchema[] = [];
        if (answer.titleJa && answer.descriptionJa) {
          const content: FreepadAnswerForm.AnswersContentSchema = {
            title: answer.titleJa,
            description: answer.descriptionJa,
            language: "ja",
          };
          contents.push(content);
        }

        if (answer.titleEn && answer.descriptionEn) {
          const content: FreepadAnswerForm.AnswersContentSchema = {
            title: answer.titleEn,
            description: answer.descriptionEn,
            language: "en",
          };
          contents.push(content);
        }
        return {
          id: answer.freepadAnswerId,
          label: answer.label,
          contents: contents,
          answerFlowCharts: answer.answerFlowCharts.map(chart => ({
            id: chart.freepadAnswerFlowChartId,
            componentType: chart.componentType as ComponentType,
            body: chart.body,
          })),
          answerCodes: answer.answerCodes.map(code => ({
            id: code.freepadAnswerCodeId,
            runtime: code.runtime,
            body: code.body,
          })),
        };
      });
    }
    return [
      {
        label: "OPTIMAL",
        contents: [
          {
            title: "",
            description: "",
            language: initLanguage,
          },
        ],
        answerCodes: [
          {
            runtime: defaultLanguageMap["ALGORITHM"],
            body: templateCode[defaultLanguageMap["ALGORITHM"] as keyof typeof templateCode] ?? "",
          },
        ],
        answerFlowCharts: [
          {
            componentType: "default",
            body: "",
          },
        ],
      },
    ];
  }, [initLanguage, question?.answers, templateCode]);
  return React.useMemo(() => {
    return {
      variant: variant,
      defaultValues: {
        answers: answers,
        variant: variant,
      },
    };
  }, [answers, variant]);
};

type InitialFlowCharts = Pages.FreepadResourceEditorProviderProps["optionalProvider"]["defaultValues"]["initialFlowCharts"];
type InitialCodes = Pages.FreepadResourceEditorProviderProps["optionalProvider"]["defaultValues"]["initialCodes"];
export const useOptionalDefaultValues = (): Pages.FreepadResourceEditorProviderProps["optionalProvider"] => {
  const variant = FreepadResourceEditor.useVariant();
  const question = FreepadResourceEditor.useQuestion();

  const initialFlowCharts = React.useMemo((): InitialFlowCharts => {
    return (question?.initialFlowCharts || []).map(chart => ({
      id: chart.freepadInitialFlowChartId,
      componentType: chart.componentType as ComponentType,
      body: chart.body,
    }));
  }, [question?.initialFlowCharts]);

  const initialCodes = React.useMemo((): InitialCodes => {
    return (question?.initialCodes || []).map(code => ({
      id: code.freepadInitialCodeId,
      runtime: code.runtime,
      body: code.body,
    }));
  }, [question?.initialCodes]);

  return {
    variant: variant,
    defaultValues: {
      initialFlowCharts: initialFlowCharts,
      initialCodes: initialCodes,
      newVersion: question?.version?.split("-")[0] ?? INITIAL_VERSION,
      oldVersion: question?.version || INITIAL_VERSION,
      needUpdateVersion: question ? question.version !== INITIAL_VERSION : false,
    },
  };
};
