import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateDashboardRemoteInterviewSectionPropsArgs, useGenerateProps } from "./useGenerateProps";

export type DashboardRemoteInterviewSectionContainerProps = GenerateDashboardRemoteInterviewSectionPropsArgs;

const DashboardRemoteInterviewSectionContainer: React.FC<DashboardRemoteInterviewSectionContainerProps> = props => {
  const dashboardRemoteInterviewSectionProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.DashboardRemoteInterviewSection {...dashboardRemoteInterviewSectionProps} />
    </ErrorBoundary>
  );
};

DashboardRemoteInterviewSectionContainer.displayName = "DashboardRemoteInterviewSectionContainer";

export default withErrorBoundary(DashboardRemoteInterviewSectionContainer, {});
