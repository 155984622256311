import Box from "@mui/material/Box";
import * as React from "react";

import ReadonlyQuizQuestionFreeText, { ReadonlyQuizQuestionFreeTextProps } from "../ReadonlyQuizQuestionFreeText/ReadonlyQuizQuestionFreeText";
import ReadonlyQuizQuestionMultiChoice, {
  ReadonlyQuizQuestionMultiChoiceProps,
} from "../ReadonlyQuizQuestionMultiChoice/ReadonlyQuizQuestionMultiChoice";
import ReadonlyQuizQuestionSingleChoice, {
  ReadonlyQuizQuestionSingleChoiceProps,
} from "../ReadonlyQuizQuestionSingleChoice/ReadonlyQuizQuestionSingleChoice";

export type SingleChoiceContentProps = {
  kind: "SINGLE_CHOICE";
  content: ReadonlyQuizQuestionSingleChoiceProps;
};

export type MultiChoiceContentProps = {
  kind: "MULTI_CHOICE";
  content: ReadonlyQuizQuestionMultiChoiceProps;
};

export type EvaluationContentProps = {
  kind: "FREE_TEXT";
  content: ReadonlyQuizQuestionFreeTextProps;
};

export type QuizQuestionCardContentProps = SingleChoiceContentProps | MultiChoiceContentProps | EvaluationContentProps;

const QuizQuestionCardContent: React.FC<QuizQuestionCardContentProps> = props => {
  return (
    <Box>
      {props.kind === "SINGLE_CHOICE" && <ReadonlyQuizQuestionSingleChoice {...props.content} />}
      {props.kind === "MULTI_CHOICE" && <ReadonlyQuizQuestionMultiChoice {...props.content} />}
      {props.kind === "FREE_TEXT" && <ReadonlyQuizQuestionFreeText {...props.content} />}
    </Box>
  );
};

QuizQuestionCardContent.displayName = "QuizQuestionCardContent";

export default QuizQuestionCardContent;
