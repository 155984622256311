export const convertApplicationPdfReadableStreamReaderToBlob = async (reader: ReadableStreamDefaultReader<Uint8Array>) => {
  const chunks: Uint8Array[] = [];
  let done = false;
  while (!done) {
    const result = await reader.read();
    if (result.value) {
      chunks.push(result.value);
    }
    done = result.done;
  }
  return new Blob(chunks, { type: "application/pdf" });
};
