import { useTranslation } from "@hireroo/i18n";
import { Fields } from "@hireroo/validator";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { useQuestionSearchAndSelectableAreaContext } from "../../Context";
import SortableQuestionListItem, { SortableQuestionListItemProps } from "./parts/SortableQuestionListItem/SortableQuestionListItem";

type UniqueKey = Fields.EntitySource.EntitySource["uniqueKey"];

const move = <T,>(list: T[], index: number, count: number): T[] => {
  if (index < 0 || index >= list.length || index + count < 0 || index + count >= list.length) {
    throw new Error("Invalid index or count");
  }

  const element = list[index];
  const newList = [...list];
  newList.splice(index, 1);
  newList.splice(index + count, 0, element);

  return newList;
};

const LIST_ITEM_CLASS_NAME = "__list-item__";

const Wrapper = styled(Box)(({ theme }) => ({
  height: "100%",
  width: "100%",
  flexShrink: 0,
  [`.${LIST_ITEM_CLASS_NAME}`]: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const CenteredContent = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
}));

export type SortableQuestionListProps = {
  entitySources: Record<UniqueKey, SortableQuestionListItemProps["questionMeta"]>;
  disabledAll?: boolean;
};

const SortableQuestionList: React.FC<SortableQuestionListProps> = props => {
  const { entitySources } = props;
  const { t } = useTranslation();
  const { selectedEntitySources, updateSelectedEntitySources } = useQuestionSearchAndSelectableAreaContext();
  const sortableQuestionListItems = React.useMemo((): SortableQuestionListItemProps[] => {
    const last = selectedEntitySources.length - 1;
    return selectedEntitySources.reduce<SortableQuestionListItemProps[]>((all, selectedEntitySource, index) => {
      const uniqueKey = selectedEntitySource.uniqueKey;
      const entitySource = entitySources[uniqueKey];
      if (!entitySource) {
        return all;
      }
      const item: SortableQuestionListItemProps = {
        index,
        num: index + 1,
        uniqueKey,
        disabledAll: props.disabledAll,
        questionMeta: {
          title: entitySource.title,
          variant: entitySource.variant,
          difficulty: entitySource.difficulty,
          isOfficial: entitySource.isOfficial,
          averageScore: entitySource.averageScore,
          numOfUse: entitySource.numOfUse,
          timelimit: entitySource.timelimit,
          leakScore: entitySource.leakScore,
          mark: entitySource.mark,
        },
        upButton: {
          disabled: index === 0 || props.disabledAll,
          onClick: () => {
            updateSelectedEntitySources(prev => move(prev, index, -1));
          },
        },
        downButton: {
          disabled: index === last || props.disabledAll,
          onClick: () => {
            updateSelectedEntitySources(prev => move(prev, index, 1));
          },
        },
        removeButton: {
          onClick: () => {
            updateSelectedEntitySources(prev => {
              return prev.filter(entitySource => {
                return entitySource.uniqueKey !== uniqueKey;
              });
            });
          },
          disabled: props.disabledAll,
        },
      };
      return all.concat(item);
    }, []);
  }, [entitySources, selectedEntitySources, props.disabledAll, updateSelectedEntitySources]);

  const SortableQuestionListItems = sortableQuestionListItems.map(sortableQuestionListItemProps => {
    return (
      <SortableQuestionListItem
        key={sortableQuestionListItemProps.uniqueKey}
        {...sortableQuestionListItemProps}
        className={LIST_ITEM_CLASS_NAME}
      />
    );
  });

  if (SortableQuestionListItems.length === 0) {
    return (
      <CenteredContent>
        <Box sx={{ textAlign: "center" }}>
          <Typography color="text.secondary" variant="caption" noWrap>
            {t("問題数はウォーミングアップの問題を含めて2〜3問がおすすめです。")}
          </Typography>
          <Typography color="text.secondary" variant="caption" noWrap>
            {t("最大10問まで選択できます。")}
          </Typography>
        </Box>
      </CenteredContent>
    );
  }

  return <Wrapper>{SortableQuestionListItems}</Wrapper>;
};

SortableQuestionList.displayName = "SortableQuestionList";

export default SortableQuestionList;
