import * as echarts from "echarts";

export type CreateChartArgs = {
  dom: HTMLDivElement;
  options: echarts.EChartsOption;
};

export const createChart = (args: CreateChartArgs): echarts.ECharts => {
  const { dom, options: defaultOptions } = args;
  const chart = echarts.init(dom, null, {
    renderer: "canvas",
    useDirtyRect: false,
    ssr: echarts.env.node,
  });
  const option: echarts.EChartsOption = {
    ...defaultOptions,
    grid: {
      left: 40,
      bottom: 40,
      top: 5,
      right: 5,
    },
    tooltip: {
      trigger: "axis",
    },
    animation: false,
    xAxis: {
      ...defaultOptions.xAxis,
      nameLocation: "middle",
      nameGap: 25,
    },
    yAxis: {
      ...defaultOptions.yAxis,
      nameLocation: "middle",
      nameGap: 25,
    },
  };
  chart.setOption(option);
  return chart;
};
