import { useTranslation } from "@hireroo/i18n";
import Slack from "@hireroo/ui-assets/images/Logo/Slack.png";
import { SlackSettingsForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import SlackIntegrateButton, { SlackIntegrateButtonProps } from "./parts/SlackIntegrateButton/SlackIntegrateButton";
import SlackNotificationFields, { SlackNotificationFieldsProps } from "./parts/SlackNotificationFields/SlackNotificationFields";

const StyledTypography = styled(Typography)({
  fontWeight: 700,
});

export type SlackNotificationSettingsProps = {
  /** isAuthorized to integrate with slack settings **/
  isAuthorized: boolean;
  defaultValues: SlackSettingsForm.SlackSettingsFormSchema;
  onSubmit: SubmitHandler<SlackSettingsForm.SlackSettingsFormSchema>;
  slackNotification: SlackNotificationFieldsProps;
  slackIntegrateButton: SlackIntegrateButtonProps;
  loading?: boolean;
};

const SlackNotificationSettings: React.FC<SlackNotificationSettingsProps> = props => {
  const { t } = useTranslation();
  const validateSchema = SlackSettingsForm.useSlackSettingsFormSchema();
  const { defaultValues } = props;
  const methods = useForm<SlackSettingsForm.SlackSettingsFormSchema>({
    resolver: zodResolver(validateSchema),
    shouldUnregister: true,
    mode: "onSubmit",
    defaultValues,
  });
  React.useEffect(() => {
    const subscription = methods.watch(() => {
      props.onSubmit(methods.getValues());
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [methods, props]);
  return (
    <FormProvider {...methods}>
      <Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1.5}>
          <Stack direction="row" spacing={1.5} alignItems="center">
            <img src={Slack} width={24} height={24} />
            <StyledTypography fontSize={14}>{t("Slack連携")}</StyledTypography>
          </Stack>
        </Stack>
        <Box display="flex" flexDirection={"column"}>
          {props.isAuthorized ? (
            <SlackNotificationFields {...props.slackNotification} />
          ) : (
            <SlackIntegrateButton {...props.slackIntegrateButton} />
          )}
        </Box>
      </Stack>
    </FormProvider>
  );
};

SlackNotificationSettings.displayName = "SlackNotificationSettings";

export default SlackNotificationSettings;
