import { languageMapForDisplay, SupportLanguage, SupportLanguageValue, useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import * as React from "react";
import { useFieldArray } from "react-hook-form";

import EditLanguageTab, { EditLanguageTabProps } from "../../../usecase/EditLanguageTab/EditLanguageTab";
import MarkdownPreviewEditor, { MarkdownPreviewEditorProps } from "../../../usecase/MarkdownPreviewEditor/MarkdownPreviewEditor";
import { useAlgorithmHintsFormContext } from "../Context";

const descriptionMap: Record<SupportLanguageValue, string> = {
  [SupportLanguage.JA]: "本文",
  [SupportLanguage.EN]: "description",
};

export type HintLanguageTabProps = {
  index: number;
  tabMenu: EditLanguageTabProps["menu"];
  onTabChange: EditLanguageTabProps["onTabsChange"];
};

const HintLanguageTab: React.FC<HintLanguageTabProps> = props => {
  const { t } = useTranslation();
  const { methods } = useAlgorithmHintsFormContext();

  const contentsField = useFieldArray({
    control: methods.control,
    name: `hints.${props.index}.contents`,
  });
  const contents = methods.getValues(`hints.${props.index}.contents`);

  const editLanguageTab: EditLanguageTabProps = {
    menu: props.tabMenu,
    onTabsChange: props.onTabChange,
    addButton: {
      disabled: contents[0]?.description === "",
    },
    items: contents.map((content, index) => {
      const descriptionEditor: MarkdownPreviewEditorProps = {
        label: descriptionMap[content.language],
        helperText: t("問題の本文を入力します。本文はMarkdown形式で入力できます。"),
        placeholder: `ex) ${t("まず、XXXを実現するにはYYYをする必要がありそれはZZZを使うことで表現できます。")}`,
        required: true,
        defaultValue: content.description,
      };
      return {
        id: index.toString(),
        name: languageMapForDisplay[content.language],
        Content: (
          <Box mt={2} key={content.language}>
            <MarkdownPreviewEditor name={`hints.${props.index}.contents.${index}.description`} areaSize="md" {...descriptionEditor} />
          </Box>
        ),
        tab: {
          closeButton: {
            onClick: () => {
              contentsField.remove(index);
            },
          },
        },
      };
    }),
  };

  return <EditLanguageTab {...editLanguageTab} />;
};

HintLanguageTab.displayName = "HintInput";

export default HintLanguageTab;
