import { App } from "@hireroo/app-store/essential/employee";
import { RemotesId } from "@hireroo/app-store/page/e/remotes_id";
import { RemoteInterviewTimelimit } from "@hireroo/app-store/widget/shared/RemoteInterviewTimelimit";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";
import { useParams } from "react-router-dom";

import ErrorPanelContainer from "../../../../widget/e/ErrorPanel/Container";
import RemoteInterviewForEmployeeMiddleContainer, { RemoteInterviewForEmployeeMiddleContainerProps } from "./MiddleContainer";
import * as Subscriber from "./Subscriber";

const RemoteInterviewForEmployeeFetchContainer: React.FC = () => {
  const appStatus = App.useStatus();
  const { id: remoteId } = useParams();
  const initialized = RemotesId.useInitialized();
  const [result] = Graphql.useGetRemotesQuery({
    variables: {
      remoteId: remoteId ?? "",
    },
    requestPolicy: "network-only",
    pause: appStatus !== "INITIALIZED" || !remoteId,
  });

  React.useEffect(() => {
    const stopSubscribeForUpdateTemplateCodes = Subscriber.startSubscribeActiveSessionsForUpdateTemplateCodes();
    const stopForHideQuestions = Subscriber.startSubscribeActiveSessionsForHideQuestions();
    return () => {
      stopSubscribeForUpdateTemplateCodes();
      stopForHideQuestions();
      RemotesId.clear();
    };
  }, []);

  React.useEffect(() => {
    if (result.data) {
      RemotesId.initialize(result.data.remote);
      if (result.data.remote.didStartAtSeconds) {
        RemoteInterviewTimelimit.initialize(result.data.remote.didStartAtSeconds);
      }
    }
  }, [result.data]);

  if (result.error) {
    return <ErrorPanelContainer graphqlErrors={result.error.graphQLErrors} />;
  }

  if (result.fetching || appStatus !== "INITIALIZED" || !initialized || !remoteId) {
    return <Widget.Loading kind="CENTER" color="secondary" />;
  }
  const containerProps: RemoteInterviewForEmployeeMiddleContainerProps = {
    remoteId: remoteId,
  };
  return (
    <ErrorBoundary>
      <RemoteInterviewForEmployeeMiddleContainer {...containerProps} />
    </ErrorBoundary>
  );
};

RemoteInterviewForEmployeeFetchContainer.displayName = "RemoteInterviewForEmployeeFetchContainer";

export default withErrorBoundary(RemoteInterviewForEmployeeFetchContainer, {});
