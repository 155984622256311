import firebase from "firebase/compat/app";

export async function getIdToken(forceRefresh?: boolean): Promise<string | undefined> {
  return await firebase.auth().currentUser?.getIdToken(forceRefresh);
}

export async function getExpireDate() {
  const result = await firebase.auth().currentUser?.getIdTokenResult();
  if (!result) {
    return;
  }
  return new Date(result.expirationTime);
}
