import { INITIAL_VERSION } from "@hireroo/app-definition/question";
import type * as Graphql from "@hireroo/graphql/client/urql";

import type { DeepReadonly } from "../types";

type Entity = Graphql.SpotForCandidateInterviewFragment["entities"][0];

export const generateEntityKey = (entity: DeepReadonly<Entity>): string => {
  switch (entity.__typename) {
    case "ChallengeEntity":
      return `challenge-${entity.challengeEntityId}-${entity.challengeQuestion?.questionId}-${entity.questionVersion}`;
    case "ProjectEntity":
      return `project-${entity.projectEntityId}-${entity.question?.questionId}-${entity.question?.version ?? INITIAL_VERSION}`;
    case "QuizEntity":
      return `quiz-${entity.quizEntityId}-${entity.pb_package?.packageId}-${entity.packageVersion}`;
    case "SystemDesignEntity":
      return `systemDesign-${entity.systemDesignEntityId}-${entity.question?.questionId}-${INITIAL_VERSION}`;
    default:
      return "";
  }
};

export const generateSubmittedEntityKey = (entity: DeepReadonly<Graphql.SpotForEmployeeInterviewsIdReviewFragment["entities"][0]>) => {
  switch (entity.__typename) {
    case "ProjectEntity":
      return `project-${entity.projectEntityId}`;
    default:
      return "";
  }
};

export type EntityType = "challenge" | "project" | "quiz" | "systemDesign";

export type NormalizedEntity = {
  entityType: EntityType;
  entityId: number;
  questionId: number;
  questionVersion: string;
};

export const generateNormalizedEntity = (entity: DeepReadonly<Entity>): NormalizedEntity => {
  switch (entity.__typename) {
    case "ChallengeEntity":
      return {
        entityType: "challenge",
        entityId: entity.challengeEntityId,
        questionId: entity.challengeQuestion?.questionId ?? 0,
        questionVersion: entity.questionVersion,
      };
    case "ProjectEntity":
      return {
        entityType: "project",
        entityId: entity.projectEntityId,
        questionId: entity.question?.questionId ?? 0,
        questionVersion: entity.question?.version ?? INITIAL_VERSION,
      };
    case "QuizEntity":
      return {
        entityType: "quiz",
        entityId: entity.quizEntityId,
        questionId: entity.pb_package?.packageId ?? 0,
        questionVersion: entity.packageVersion,
      };
    case "SystemDesignEntity":
      return {
        entityType: "systemDesign",
        entityId: entity.systemDesignEntityId,
        questionId: entity.question?.questionId ?? 0,
        questionVersion: INITIAL_VERSION,
      };
    default:
      throw new Error("Unsporrted");
  }
};

export const extractQuestionTitle = (entity: Entity): { titleJa: string | undefined; titleEn: string | undefined } => {
  switch (entity.__typename) {
    case "ChallengeEntity":
      return {
        titleEn: entity.challengeQuestion?.titleEn ?? "",
        titleJa: entity.challengeQuestion?.titleJa ?? "",
      };
    case "ProjectEntity":
      return {
        titleEn: entity.question?.titleEn ?? "",
        titleJa: entity.question?.titleJa ?? "",
      };
    case "QuizEntity":
      return {
        titleEn: entity.pb_package?.titleEn ?? "",
        titleJa: entity.pb_package?.titleJa ?? "",
      };
    case "SystemDesignEntity":
      return {
        titleEn: entity.question?.titleEn ?? "",
        titleJa: entity.question?.titleJa ?? "",
      };
    default:
      return {
        titleEn: "",
        titleJa: "",
      };
  }
};

export const generateCompletedEntities = (entities: DeepReadonly<Entity[]> = []) => {
  return entities.reduce<DeepReadonly<Entity>[]>((all, entity) => {
    switch (entity.__typename) {
      case "ChallengeEntity": {
        if (entity.submissions.length) {
          all.push(entity);
        }
        break;
      }
      case "ProjectEntity": {
        if (entity.submissions.length) {
          all.push(entity);
        }
        break;
      }
      case "SystemDesignEntity": {
        if (entity.submissions.length) {
          all.push(entity);
        }
        break;
      }
      case "QuizEntity": {
        const keySet = new Set((entity.pb_package?.questions || []).map(q => `${q.id}-${q.version}`));
        entity.submissions.forEach(submission => {
          const key = `${submission.questionId}-${submission.questionVersion}`;
          keySet.has(key) && keySet.delete(key);
        });
        if (keySet.size === 0) {
          all.push(entity);
        }
        break;
      }
    }
    return all;
  }, []);
};
