import React from "react";
import Video, {
  CreateLocalAudioTrackOptions,
  CreateLocalTrackOptions,
  LocalAudioTrack,
  LocalVideoTrack,
  NoiseCancellationOptions,
} from "twilio-video";
import { CreateLocalTracksOptions } from "twilio-video/tsdef/types";

import { DEFAULT_VIDEO_CONSTRAINTS, getDeviceInfo, getPermissionState, SelectedAudioDeviceId, SelectedVideoDeviceId } from "../../media-device";
import { isLocalAudioTrack, isLocalVideoTrack } from "./privateHelper";

const noiseCancellationOptions: NoiseCancellationOptions = {
  // self hosting krisp-audio-plugin path
  // See https://www.npmjs.com/package/@twilio/krisp-audio-plugin
  sdkAssetsPath: new URL("/noise-cancellation/krisp-audio-plugin/v1.0.0", window.location.origin).href,
  vendor: "krisp",
};

/**
 * This hook returns the local audio and video tracks.
 */
export const useLocalTracks = () => {
  // TODO @valbeat This should originally be written in useRef.
  const [audioTrack, setAudioTrack] = React.useState<LocalAudioTrack>();
  const [videoTrack, setVideoTrack] = React.useState<LocalVideoTrack>();
  const [isAcquiringLocalTracks, setIsAcquiringLocalTracks] = React.useState(false);

  const replaceVideoTrack = React.useCallback((newTrack: LocalVideoTrack) => {
    setVideoTrack(prevState => {
      // Stop the existing video track. This is important to do, because otherwise, the camera
      prevState?.stop();
      return newTrack;
    });
  }, []);

  const replaceAudioTrack = React.useCallback((newTrack: LocalAudioTrack) => {
    setAudioTrack(prevState => {
      // Stop the existing audio track. This is important to do, because otherwise, the microphone
      prevState?.stop();
      return newTrack;
    });
  }, []);

  const getLocalVideoTrack = React.useCallback(async () => {
    const selectedVideoDeviceId = SelectedVideoDeviceId.get() ?? "";

    const { videoInputDevices } = await getDeviceInfo();

    const hasSelectedVideoDevice = videoInputDevices.some(device => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId);

    const options: CreateLocalTrackOptions = {
      // Date.now is sufficient for our use-case.
      name: `camera-${Date.now()}`,
      ...(hasSelectedVideoDevice && { deviceId: { exact: selectedVideoDeviceId } }),
    };

    return Video.createLocalVideoTrack(options).then(newTrack => {
      replaceVideoTrack(newTrack);
      return newTrack;
    });
  }, [replaceVideoTrack]);

  const getLocalAudioTrack = React.useCallback(async () => {
    const selectedAudioDeviceId = SelectedAudioDeviceId.get() ?? "";

    const { audioInputDevices } = await getDeviceInfo();

    const hasSelectedAudioDevice = audioInputDevices.some(device => selectedAudioDeviceId && device.deviceId === selectedAudioDeviceId);

    const options: CreateLocalAudioTrackOptions = {
      noiseCancellationOptions,
      ...(hasSelectedAudioDevice && { deviceId: { exact: selectedAudioDeviceId } }),
    };

    return Video.createLocalAudioTrack(options).then((newTrack: Video.LocalAudioTrack) => {
      newTrack.restart();
      replaceAudioTrack(newTrack);
      return newTrack;
    });
  }, [replaceAudioTrack]);

  const removeLocalAudioTrack = React.useCallback(() => {
    if (audioTrack) {
      audioTrack.stop();
      setAudioTrack(undefined);
    }
  }, [audioTrack]);

  const removeLocalVideoTrack = React.useCallback(() => {
    if (videoTrack) {
      videoTrack.stop();
      setVideoTrack(undefined);
    }
  }, [videoTrack]);

  const getAudioAndVideoTracks = React.useCallback(async () => {
    const { audioInputDevices, videoInputDevices, hasAudioInputDevices, hasVideoInputDevices } = await getDeviceInfo();

    if (!hasAudioInputDevices && !hasVideoInputDevices) {
      return;
    }
    if (isAcquiringLocalTracks) {
      return;
    }

    setIsAcquiringLocalTracks(true);

    const selectedVideoDeviceId = SelectedVideoDeviceId.get() ?? "";
    const selectedAudioDeviceId = SelectedAudioDeviceId.get() ?? "";

    const hasSelectedVideoDevice = videoInputDevices.some(device => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId);
    const hasSelectedAudioDevice = audioInputDevices.some(device => selectedAudioDeviceId && device.deviceId === selectedAudioDeviceId);

    // For Chrome, it is possible to deny permissions to only audio or only video.
    const cameraPermissionState = await getPermissionState("camera");
    const microphonePermissionState = await getPermissionState("microphone");
    const shouldAcquireVideo = hasVideoInputDevices && cameraPermissionState !== "denied";
    const shouldAcquireAudio = hasAudioInputDevices && microphonePermissionState !== "denied";

    const localTrackConstraints: CreateLocalTracksOptions = {
      video: shouldAcquireVideo && {
        ...DEFAULT_VIDEO_CONSTRAINTS,
        name: `camera-${Date.now()}`,
        ...(hasSelectedVideoDevice && { deviceId: { exact: selectedVideoDeviceId } }),
      },
      audio: shouldAcquireAudio && {
        // For performance improvement, not load noise cancellation plugin first request
        ...(hasSelectedAudioDevice && { deviceId: { exact: selectedAudioDeviceId } }),
      },
    };

    return Video.createLocalTracks(localTrackConstraints)
      .then((tracks: Video.LocalTrack[]) => {
        const newVideoTrack = tracks.find(track => track.kind === "video");
        const newAudioTrack = tracks.find(track => track.kind === "audio");
        if (isLocalVideoTrack(newVideoTrack)) {
          replaceVideoTrack(newVideoTrack);
          // Save the deviceId, so it can be picked up by the VideoInputList component. This only matters
          // in cases where the user's video is disabled.
          SelectedVideoDeviceId.save(newVideoTrack.mediaStreamTrack.getSettings().deviceId ?? "");
        }
        if (isLocalAudioTrack(newAudioTrack)) {
          replaceAudioTrack(newAudioTrack);
        }
      })
      .finally(() => {
        setIsAcquiringLocalTracks(false);
      });
  }, [isAcquiringLocalTracks, replaceVideoTrack, replaceAudioTrack]);

  return {
    videoTrack,
    audioTrack,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
  };
};
