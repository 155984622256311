import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type CustomErrorMessagePanelContainerProps = {
  title: string;
  message: string;
};

const CustomErrorMessagePanelContainer: React.FC<CustomErrorMessagePanelContainerProps> = props => {
  const errorPanelProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ErrorPanel {...errorPanelProps} />
    </ErrorBoundary>
  );
};

CustomErrorMessagePanelContainer.displayName = "CustomErrorMessagePanelContainer";

export default withErrorBoundary(CustomErrorMessagePanelContainer, {});
