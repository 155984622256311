import * as z from "zod";

//TODO: rename to challenge playback settings form
export const usePlaybackSettingsForm = () => {
  return z.object({
    enabledCopyAndPasteDetection: z.boolean(),
    enabledBehavioralControl: z.boolean(),
  });
};

export type PlaybackSettingsFormSchema = z.infer<ReturnType<typeof usePlaybackSettingsForm>>;

export const ProjectPlaybackMode = z.union([z.literal("TIMELINE"), z.literal("EACH_FILE")]);

export const useProjectPlaybackSettingsForm = () => {
  return z.object({
    enabledCopyAndPasteDetection: z.boolean(),
    enabledBehavioralControl: z.boolean(),
    playbackMode: ProjectPlaybackMode,
  });
};

export type ProjectPlaybackSettingsFormSchema = z.infer<ReturnType<typeof useProjectPlaybackSettingsForm>>;
