import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { PaymentForm } from "@hireroo/validator";
import Box, { BoxProps } from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Trans } from "react-i18next";

import ResourceField from "../../../modules/ResourceField/ResourceField";
import InputControlTextField from "../../../primitive/InputControl/InputControlTextField/InputControlTextField";
import SelectControl, { SelectControlProps } from "../../../primitive/InputControl/SelectControl/SelectControl";
import Link, { LinkProps } from "../../../primitive/Link/Link";

const HEIGHT = 40;

const LabelTextField = styled(Typography)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  wordBreak: "keep-all",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  fontWeight: "bold",
  fontSize: "14px",
  color: theme.palette.text.primary,
  marginBottom: "12px",
}));

const ValueTextField = styled(InputControlTextField)(({ theme }) => ({
  minHeight: HEIGHT,
  fontSize: 16,
  marginRight: theme.spacing(1.5),
  width: "100%",
}));

type Language = PaymentForm.BillingInformationField["language"];

export type BillingInformationFieldProps = {
  name: string;
  companyName: {
    settingPageLink: LinkProps;
  };
} & Pick<BoxProps, "mb">;

const BillingInformationField: React.FC<BillingInformationFieldProps> = props => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const menuItems: { label: string; value: Language }[] = [
    {
      label: t("日本語"),
      value: "JA",
    },
    {
      label: t("英語"),
      value: "EN",
    },
  ];
  const languageSelectControlProps: SelectControlProps = {
    value: "JA",
    menuItems: menuItems,
    color: "secondary",
  };

  const HelperTextForCompanyName = (
    <Trans
      values={{
        name: `<pageLink>${t("一般情報")}</pageLink>`,
      }}
      components={{
        pageLink: (
          <Link {...props.companyName.settingPageLink} color="secondary">
            {t("一般情報")}
          </Link>
        ),
      }}
    >
      {t2("thisItemCanBeChangedFrom")}
    </Trans>
  );

  return (
    <Box mb={props.mb}>
      <Stack spacing={2} direction="column">
        <Stack spacing={2} direction="row">
          <Box width="50%">
            <LabelTextField>{t("会社名")}</LabelTextField>
            <ValueTextField name={`${props.name}.companyName`} disabled helperText={HelperTextForCompanyName} />
          </Box>
          <Box width="50%">
            <ResourceField label={t("請求先メールアドレス")} kind="REQUIRED">
              <ValueTextField name={`${props.name}.mailAddress`} />
            </ResourceField>
          </Box>
        </Stack>
        <Stack direction="row" width="100%" spacing={2}>
          <Box width="50%">
            <ResourceField label={t("郵便番号")} kind="REQUIRED">
              <ValueTextField name={`${props.name}.postalCode`} />
            </ResourceField>
          </Box>
          <Box width="50%" />
        </Stack>
        <Stack direction="row" width="100%" spacing={2}>
          <Box width="50%">
            <ResourceField label={t("都道府県")} kind="REQUIRED">
              <ValueTextField name={`${props.name}.state`} />
            </ResourceField>
          </Box>
          <Box width="50%">
            <ResourceField label={t("市区町村")} kind="REQUIRED">
              <ValueTextField name={`${props.name}.city`} />
            </ResourceField>
          </Box>
        </Stack>
        <Box width="100%">
          <ResourceField label={t("住所")} kind="REQUIRED">
            <ValueTextField name={`${props.name}.line1`} />
          </ResourceField>
        </Box>
        <Box width="25%">
          <LabelTextField>{t("請求書の言語")}</LabelTextField>
          <SelectControl name={`${props.name}.language`} {...languageSelectControlProps} />
        </Box>
      </Stack>
    </Box>
  );
};

BillingInformationField.displayName = "BillingInformationField";

export default BillingInformationField;
