import { Visibility, VisibilityOff } from "@mui/icons-material";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import * as React from "react";

type Kind = "VISIBLE" | "INVISIBLE";

const iconMap: Record<Kind, React.ReactNode> = {
  VISIBLE: <VisibilityOff fontSize="small" />,
  INVISIBLE: <Visibility fontSize="small" />,
};

export type ChangeVisibilityButtonProps = {
  kind: Kind;
  onClick: IconButtonProps["onClick"];
};

const ChangeVisibilityButton: React.FC<ChangeVisibilityButtonProps> = props => {
  const handleMouseDown: IconButtonProps["onMouseDown"] = event => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <IconButton aria-label="toggle password visibility" onClick={props.onClick} onMouseDown={handleMouseDown} edge="end">
      {iconMap[props.kind]}
    </IconButton>
  );
};

ChangeVisibilityButton.displayName = "ChangeVisibilityButton";

export default ChangeVisibilityButton;
