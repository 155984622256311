import { usePlanNameMap } from "@hireroo/app-definition/payment";
import { PaymentContractCreateForm } from "@hireroo/app-store/widget/e/PaymentContractCreateForm";
import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as React from "react";

export type GeneratePaymentCompletionPropsArgs = {};

export const useGenerateProps = (_args: GeneratePaymentCompletionPropsArgs): Widget.PaymentCompletionProps => {
  const selectedTemporaryPlan = PaymentContractCreateForm.useTemporarySelectedPlan();
  const customer = PaymentContractCreateForm.useCustomer();
  const plans = PaymentContractCreateForm.usePlans();
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const navigate = useTransitionNavigate();
  const planNameMap = usePlanNameMap(customer.subscription.plan.generation);
  const selectedPlan = React.useMemo(() => {
    return plans.find(plan => {
      return selectedTemporaryPlan?.planType === plan.planType;
    });
  }, [plans, selectedTemporaryPlan]);

  const planName = selectedPlan ? planNameMap[selectedPlan.planType] : "";

  return {
    title: t("お申し込みありがとうございました。"),
    message: [t2("contractedPlan", { planName }), t("新しいプランを利用するためにはページのリロードが必要です。")].join(""),
    link: {
      href: generatePath("/e/settings/billing/overview"),
      onClick: () => {
        PaymentContractCreateForm.clear();
        navigate("/e/settings/billing/overview");
      },
      children: t("お支払い概要に戻る"),
    },
  };
};
