import { Credential } from "@hireroo/app-store/essential/shared";
import { Company, Payment, Security } from "@hireroo/app-store/essential/talent";
import { Pages, Widget } from "@hireroo/presentation";
import { currentWindowMatchPath } from "@hireroo/router/api";
import { withErrorBoundary } from "@sentry/react";
import * as React from "react";
import { Outlet } from "react-router-dom";

import SnackbarContainer from "../../widget/shared/Snackbar/Container";
import OopsContainer from "../Oops/Container";
import { useGenerateProps } from "./useGenerateProps";

const PermittedRouteForTalentContainer: React.FC = () => {
  const errorPanelProps = useGenerateProps();
  const companyInitialized = Company.useInitialized();
  const paymentInitialized = Payment.useInitialized();
  const securityInitialized = Security.useInitialized();
  const hasAuthToken = Credential.useHasAuthToken();
  const initialized = React.useMemo((): boolean => {
    if (!paymentInitialized || !hasAuthToken) {
      return false;
    }
    // Invitation page does not require SSO confirmation
    if (currentWindowMatchPath("/t/invitations/:id")) {
      return true;
    }
    return companyInitialized && securityInitialized;
  }, [hasAuthToken, companyInitialized, paymentInitialized, securityInitialized]);

  if (!initialized) {
    const emptyProps: Pages.EmptyProps = {
      layout: {
        loading: true,
        Bottom: <SnackbarContainer />,
      },
    };
    return <Pages.Empty {...emptyProps} />;
  }
  if (errorPanelProps) {
    return <Widget.ErrorPanel {...errorPanelProps} />;
  }
  return (
    <React.Suspense>
      <Outlet />
    </React.Suspense>
  );
};

PermittedRouteForTalentContainer.displayName = "PermittedRouteForTalentContainer";

export default withErrorBoundary(PermittedRouteForTalentContainer, { fallback: <OopsContainer /> });
