import { Company } from "@hireroo/app-store/essential/employee";
import { Pages, Widget } from "@hireroo/presentation/legacy";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type AlgorithmResourceEditorContainerProps = {};

const AlgorithmResourceEditorContainer: React.FC<AlgorithmResourceEditorContainerProps> = _args => {
  const companyId = Company.useActiveCompanyId();
  const props = useGenerateProps({ companyId: companyId ?? 0, type: "UPDATE" });
  const { methods } = Widget.useAlgorithmQuestionDetailFormContext();

  React.useEffect(() => {
    return () => {
      //reset on unmount since the question detail is the first page of algorithm resource editor
      methods.reset();
    };
  }, [methods]);

  return (
    <ErrorBoundary>
      <Pages.AlgorithmResourceEditor {...props} />
    </ErrorBoundary>
  );
};

AlgorithmResourceEditorContainer.displayName = "AlgorithmResourceEditorContainer";

export default withErrorBoundary(AlgorithmResourceEditorContainer, {});
