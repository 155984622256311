type UpdateQueryParamsByObjectArgs = {
  [key: string]: string[];
};

export const updateQueryParamsByObject = (args: UpdateQueryParamsByObjectArgs) => {
  const params = new URLSearchParams(window.location.search);
  Object.entries(args).forEach(([key, values]) => {
    if (values.length === 0) {
      params.delete(key);
    } else {
      params.set(key, values.join("%"));
    }
  });
  if (params.toString().length === 0) {
    // Reset query params
    history.replaceState(null, "", window.location.pathname);
  } else {
    // Update query params
    history.replaceState(null, "", "?" + params.toString());
  }
};

export const updateQueryParams = (key: string, values: string[]) => {
  const params = new URLSearchParams(window.location.search);
  // Set values
  values.length === 0 ? params.delete(key) : params.set(key, values.join("%"));
  if (params.toString().length === 0) {
    // Reset query params
    history.replaceState(null, "", window.location.pathname);
  } else {
    // Update query params
    history.replaceState(null, "", "?" + params.toString());
  }
};

export const updateHashParam = (key: string) => {
  history.replaceState(null, "", "#" + key);
};
