import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type ScreeningTestFeedbackContainerProps = {};

const ScreeningTestFeedbackContainer: React.FC<ScreeningTestFeedbackContainerProps> = () => {
  const ScreeningTestFeedbackProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.ScreeningTestFeedback {...ScreeningTestFeedbackProps} />
    </ErrorBoundary>
  );
};

ScreeningTestFeedbackContainer.displayName = "ScreeningTestFeedbackContainer";

export default withErrorBoundary(ScreeningTestFeedbackContainer, {});
