import { LanguageOutlined } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import BaseMenu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Skeleton from "@mui/material/Skeleton";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&.MuiMenuItem-root": {
    "&:hover ": {
      backgroundColor: theme.palette["Secondary/Shades"].p8,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette["Secondary/Shades"].p16,
    },
  },
}));

const IconMap = {
  LANGUAGE: <LanguageOutlined color="secondary" />,
} satisfies Record<string, React.ReactElement>;
type Icon = keyof typeof IconMap;

type Option = {
  text: string;
  value: string;
  onClick?: () => void;
  icon?: Icon;
  default?: boolean;
};

type TextColor = "secondary" | "text.primary";

export type DropDownMenuProps = {
  /**
   * default secondary
   */
  textColor?: TextColor;
  fontSize?: number;
  options: Option[];
  role: string;
  menuId: string;
  button?: Omit<ButtonProps, "onClick">;
  menu?: Omit<MenuProps, "anchor" | "open" | "onClose">;
};

const DropDownMenu: React.FC<DropDownMenuProps> = props => {
  const theme = useTheme();
  const textColorMap: Record<TextColor, string> = {
    secondary: theme.palette.secondary.main,
    "text.primary": theme.palette.text.primary,
  };
  const textColor = textColorMap[props.textColor ?? "secondary"];
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const defaultOption: Option = React.useMemo(() => {
    return (
      props.options.find(o => !!o.default) ||
      props.options[0] || {
        text: "",
        value: "",
      }
    );
  }, [props.options]);
  const [selectedOption, updateOption] = React.useState<Option>(defaultOption);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnClick = React.useCallback((newOption: Option) => {
    updateOption(newOption);
    handleClose();
  }, []);

  if (props.options.length === 0) {
    return <Skeleton width="100px" height="36px" />;
  }

  return (
    <Box>
      <Button
        {...props.button}
        role="openner"
        endIcon={<ArrowDropDownIcon sx={{ color: textColor }} />}
        onClick={handleClick}
        sx={{ ...props.button?.sx, wordBreak: "keep-all", whiteSpace: "nowrap", fontSize: theme.typography.body2.fontSize }}
      >
        {selectedOption.icon && IconMap[selectedOption.icon]}
        <Typography role={`current${props.role}`} fontSize={props.fontSize} ml={selectedOption.icon ? 1 : undefined} color={textColor}>
          {selectedOption.text}
        </Typography>
      </Button>
      <BaseMenu
        {...props.menu}
        role={`${props.role}menu`}
        id={props.menuId}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.options.map((option, index) => (
          <StyledMenuItem
            role={`menu${option.text}`}
            key={`menu-${option.text}-${index}`}
            value={option.value}
            selected={selectedOption.value === option.value}
            onClick={() => {
              option.onClick?.();
              handleOnClick(option);
            }}
          >
            {option.icon && IconMap[option.icon]}
            <Typography ml={option.icon ? 1 : undefined} color={textColor} fontSize={props.fontSize}>
              {option.text}
            </Typography>
          </StyledMenuItem>
        ))}
      </BaseMenu>
    </Box>
  );
};

DropDownMenu.displayName = "DropDownMenu";

export default DropDownMenu;
