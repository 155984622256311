import { useTheme } from "@mui/material/styles";
import React from "react";

import { Coordinate, DIRECTION, Direction } from "../../../features";

export type EdgeProps = {
  from: Coordinate;
  to: Coordinate;
  direction: Direction;
  dashedLine?: boolean;
  isMatch?: boolean;
};

const lightGreen = "#76ff03"; // lightGreen A400

export const Edge: React.FC<EdgeProps> = (props: EdgeProps) => {
  const theme = useTheme();

  return (
    <g>
      <defs>
        <marker id="startarrow" markerWidth="15" markerHeight="10" refX="0" refY="5" orient="auto">
          <polygon points="10 0, 10 10, 0 5" />
        </marker>
        <marker id="endarrow" markerWidth="15" markerHeight="10" refX="10" refY="5" orient="auto" markerUnits="strokeWidth">
          <polygon points="0 0, 10 5, 0 10" />
        </marker>
      </defs>
      <path
        d={`M ${props.from.x} ${props.from.y} L ${props.to.x} ${props.to.y}`}
        stroke={theme.palette.common.black}
        strokeWidth={1}
        strokeDasharray={props.dashedLine ? "8 8" : "0 0"}
        markerStart={props.direction === DIRECTION.bidirectional ? "url(#startarrow)" : ""}
        markerEnd="url(#endarrow)"
      />
      {props.isMatch && (
        <path d={`M ${props.from.x} ${props.from.y} L ${props.to.x} ${props.to.y}`} stroke={lightGreen} strokeWidth={10} strokeOpacity={0.7} />
      )}
    </g>
  );
};

Edge.displayName = "Edge";

export default Edge;
