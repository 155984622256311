import { Widget } from "@hireroo/presentation/legacy";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type FooterContainerProps = {};

const FooterContainer: React.FC<FooterContainerProps> = () => {
  const props = useGenerateProps({});
  return <Widget.Footer {...props} />;
};

FooterContainer.displayName = "FooterContainer";

export default FooterContainer;
