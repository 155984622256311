import { useTheme } from "@mui/material/styles";
import React from "react";

import { Coordinate, EdgeElement, NodeElement } from "../../../../features";
import { edgeCoordinatesFromNodes } from "../../../../helpers/flowChart";
import { useSystemDesignContext } from "../../../../store";
import { Edge } from "../../Edge/Edge";

export type DynamicEdgeProps = {
  edge: EdgeElement;
  isSelected: boolean;
  isReconnectingSource: boolean;
  isReconnectingTarget: boolean;
  edgeEnd?: Coordinate;
  onSelect: (e: React.MouseEvent<SVGElement, MouseEvent>, nodeId: string) => void;
  onStartReconnect: (e: React.MouseEvent<SVGElement, MouseEvent>, edgeId: string, isTarget: boolean) => void;
};

export const DynamicEdge: React.FC<DynamicEdgeProps> = React.memo((props: DynamicEdgeProps) => {
  const theme = useTheme();
  const Store = useSystemDesignContext();
  const elementFactory = Store.hooks.useElement();
  const sourceNode = elementFactory(props.edge.source) as NodeElement;
  const targetNode = elementFactory(props.edge.target) as NodeElement;
  if (!sourceNode || !targetNode) return <></>;

  let { from, to } = edgeCoordinatesFromNodes(sourceNode, targetNode);

  if (props.isReconnectingSource && props.edgeEnd) {
    from = props.edgeEnd;
  }
  if (props.isReconnectingTarget && props.edgeEnd) {
    to = props.edgeEnd;
  }

  return (
    <g style={props.isReconnectingTarget || props.isReconnectingSource ? { pointerEvents: "none" } : {}}>
      <Edge from={from} to={to} direction={props.edge.settings.direction} dashedLine={props.isSelected} />
      {!props.isReconnectingSource && !props.isReconnectingTarget && (
        <path
          d={`M ${from.x} ${from.y} L ${to.x} ${to.y}`}
          stroke={theme.palette.common.black}
          strokeWidth={40}
          strokeOpacity={0}
          cursor={"pointer"}
          onMouseDown={e => props.onSelect(e, props.edge.id)}
        />
      )}
      {props.isSelected && (
        <g>
          {!props.isReconnectingSource && (
            <g>
              <circle cx={from.x} cy={from.y} r={8} stroke={theme.palette.common.black} fill={"none"} />
              <circle
                cx={from.x}
                cy={from.y}
                r={20}
                strokeOpacity={0}
                fillOpacity={0}
                onMouseDown={e => props.onStartReconnect(e, props.edge.id, false)}
                cursor={"crosshair"}
              />
            </g>
          )}
          {!props.isReconnectingTarget && (
            <g>
              <circle cx={to.x} cy={to.y} r={8} stroke={theme.palette.common.black} fill={"none"} />
              <circle
                cx={to.x}
                cy={to.y}
                r={20}
                strokeOpacity={0}
                fillOpacity={0}
                onMouseDown={e => props.onStartReconnect(e, props.edge.id, true)}
                cursor={"crosshair"}
              />
            </g>
          )}
        </g>
      )}
    </g>
  );
});

DynamicEdge.displayName = "DynamicEdge";

export default DynamicEdge;
