import "firebase/compat/auth";

import firebase from "firebase/compat/app";

import * as App from "./app";

export type InitializeParameter = {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  databaseQuizUrl: string;
  databaseProjectUrl: string;
  databaseSystemDesignUrl: string;
  databaseInterviewUrl: string;
  databaseLiveCodingUrl: string;
};

export const initialize = (parameter: InitializeParameter): Promise<void> => {
  const app = firebase.initializeApp(parameter);
  App.setupApp({
    challenge: {
      ...parameter,
      databaseURL: parameter.databaseURL,
    },
    systemDesign: {
      ...parameter,
      databaseURL: parameter.databaseSystemDesignUrl,
    },
    project: {
      ...parameter,
      databaseURL: parameter.databaseProjectUrl,
    },
    quiz: {
      ...parameter,
      databaseURL: parameter.databaseQuizUrl,
    },
    interview: {
      ...parameter,
      databaseURL: parameter.databaseInterviewUrl,
    },
    liveCoding: {
      ...parameter,
      databaseURL: parameter.databaseLiveCodingUrl,
    },
  });
  return new Promise(resolve => {
    app.auth().onAuthStateChanged(() => {
      resolve();
    });
  });
};
