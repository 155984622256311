import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledPaper = styled(Paper)(() => ({
  width: "100%",
  boxShadow: "none",
}));

const ItemWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
}));

const LabelWrapper = styled(Box)(() => ({
  flexShrink: 0,
  width: 64,
  marginRight: 8,
}));

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 14,
  fontWeight: 400,
  wordBreak: "keep-all",
}));

const Description = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 400,
}));

type Item = {
  label: string;
  description: string;
};

export type ActivityDetailProps = {
  question: Pick<Item, "description">;
  time: Pick<Item, "description">;
};

const ActivityDetail: React.FC<ActivityDetailProps> = props => {
  const { t } = useTranslation();
  const items: Item[] = [
    {
      ...props.question,
      label: t("問題"),
    },
    {
      ...props.time,
      label: t("解答時間"),
    },
  ];
  return (
    <StyledPaper>
      <Box px={2} py={1.5}>
        <Typography fontSize={14}>{t("コーディングテスト情報")}</Typography>
      </Box>
      <Stack spacing={1.5} direction="column" px={2} py={1.5}>
        {items.map((item, index) => {
          return (
            <ItemWrapper key={`detail-${index}`}>
              <LabelWrapper>
                <Label variant="body2">{item.label}</Label>
              </LabelWrapper>
              <Box>
                <Description>{item.description}</Description>
              </Box>
            </ItemWrapper>
          );
        })}
      </Stack>
    </StyledPaper>
  );
};

ActivityDetail.displayName = "ActivityDetail";

export default ActivityDetail;
