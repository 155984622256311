import CodeEditor, { CodeEditorProps } from "@hireroo/code-editor/react/CodeEditor";
import * as React from "react";

export type ChallengeCodingEditorProps = CodeEditorProps;

const ChallengeCodingEditor: React.FC<ChallengeCodingEditorProps> = props => {
  return (
    <React.Suspense>
      <CodeEditor {...props} />
    </React.Suspense>
  );
};

ChallengeCodingEditor.displayName = "ChallengeCodingEditor";

export default ChallengeCodingEditor;
