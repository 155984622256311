import * as Graphql from "@hireroo/graphql/client/urql";

export const ResultKeys = {
  PASSED: "passed",
  FAILED: "failed",
} as const;

export const RESULT_REVERSE_MAP: Record<string, boolean> = {
  [ResultKeys.PASSED]: true,
  [ResultKeys.FAILED]: false,
};

export const STATUS_REVERSE_MAP: Record<string, Graphql.SpotStatus> = {
  [Graphql.SpotStatus.Unknown]: Graphql.SpotStatus.Unknown,
  [Graphql.SpotStatus.Accepted]: Graphql.SpotStatus.Accepted,
  [Graphql.SpotStatus.Started]: Graphql.SpotStatus.Started,
  [Graphql.SpotStatus.Created]: Graphql.SpotStatus.Created,
  [Graphql.SpotStatus.Completed]: Graphql.SpotStatus.Completed,
  [Graphql.SpotStatus.Evaluated]: Graphql.SpotStatus.Evaluated,
  [Graphql.SpotStatus.Declined]: Graphql.SpotStatus.Declined,
  [Graphql.SpotStatus.Finalized]: Graphql.SpotStatus.Finalized,
  [Graphql.SpotStatus.Reviewed]: Graphql.SpotStatus.Reviewed,
};

export const RANK_EVALUATION_REVERSE_MAP: Record<string, Graphql.RankEvaluation> = {
  [Graphql.RankEvaluation.Unknown]: Graphql.RankEvaluation.Unknown,
  [Graphql.RankEvaluation.S]: Graphql.RankEvaluation.S,
  [Graphql.RankEvaluation.A]: Graphql.RankEvaluation.A,
  [Graphql.RankEvaluation.B]: Graphql.RankEvaluation.B,
  [Graphql.RankEvaluation.C]: Graphql.RankEvaluation.C,
  [Graphql.RankEvaluation.D]: Graphql.RankEvaluation.D,
};

export const SORT_METHOD_REVERS_MAP: Record<string, Graphql.SpotsByCompanyIdSortMethod> = {
  [Graphql.SpotsByCompanyIdSortMethod.Unknown]: Graphql.SpotsByCompanyIdSortMethod.Unknown,
  [Graphql.SpotsByCompanyIdSortMethod.CreatedAt]: Graphql.SpotsByCompanyIdSortMethod.CreatedAt,
  [Graphql.SpotsByCompanyIdSortMethod.TotalScore]: Graphql.SpotsByCompanyIdSortMethod.TotalScore,
  [Graphql.SpotsByCompanyIdSortMethod.Rank]: Graphql.SpotsByCompanyIdSortMethod.Rank,
};

type Rank = "S" | "A" | "B" | "C" | "D";
//TODO: fix type without using Rank
export const RANK_MAP: Record<Graphql.RankEvaluation, Rank> = {
  [Graphql.RankEvaluation.Unknown]: "D",
  [Graphql.RankEvaluation.S]: "S",
  [Graphql.RankEvaluation.A]: "A",
  [Graphql.RankEvaluation.B]: "B",
  [Graphql.RankEvaluation.C]: "C",
  [Graphql.RankEvaluation.D]: "D",
};

export const SUSPICIOUS_DEGREE_REVERSE_MAP: Record<string, Graphql.SpotSuspiciousDegree> = {
  [Graphql.SpotSuspiciousDegree.Clean]: Graphql.SpotSuspiciousDegree.Clean,
  [Graphql.SpotSuspiciousDegree.Suspicious]: Graphql.SpotSuspiciousDegree.Suspicious,
};
