import type * as Interface from "@hireroo/evaluation-result-interface";
import { useTranslation } from "@hireroo/i18n";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs, { TabsProps } from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import * as React from "react";

import Image from "../Image/Image";

const StyledStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  alignItems: "center",
}));

const StyledTab = styled(Tab)(() => ({
  "&.MuiButtonBase-root": {
    fontSize: "14px",
    padding: "9px 16px",
  },
  textTransform: "none",
  height: "42px",
}));

type Kind = "output" | "expected";

const KindValue = {
  Result: "output",
  Expected: "expected",
} as const satisfies Record<string, Kind>;

export type ImageDiffDialogProps = {
  dialog: Pick<DialogProps, "open" | "onClose">;
  results: Interface.ImageInputOutputExpected["value"]["results"];
  initialState?: {
    startIndex: number;
    kind: Kind;
  };
};

const ImageDiffDialog: React.FC<ImageDiffDialogProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [kindValue, setKindValue] = React.useState<Kind>(props.initialState?.kind ?? KindValue.Result);
  const [index, setIndex] = React.useState<number>(props.initialState?.startIndex ?? 0);
  const maxLength = props.results.length;
  const handleToggleChange = (event: React.SyntheticEvent, newValue: Kind) => {
    setKindValue(newValue);
  };
  const dialogProps: DialogProps = {
    ...props.dialog,
    maxWidth: "xl",
    PaperProps: {
      sx: {
        minWidth: "540px",
        borderRadius: "4px",
        backgroundColor: theme.palette.background.default,
        overflow: "hidden",
      },
    },
  };
  const handleClose = () => {
    props.dialog?.onClose?.({}, "backdropClick");
  };
  const currentResult = props.results.at(index);
  const tabsProps: TabsProps = {
    value: kindValue,
    onChange: handleToggleChange,
    color: "secondary",
    textColor: "secondary",
    indicatorColor: "secondary",
    sx: {
      height: "42px",
    },
  };
  const back: ButtonProps = {
    onClick: () => {
      setIndex(prev => {
        if (prev <= 0) return 0;
        return prev - 1;
      });
    },
    disabled: index === 0,
    color: "secondary",
    startIcon: <KeyboardArrowLeftIcon />,
    children: t("戻る"),
  };
  const next: ButtonProps = {
    onClick: () => {
      setIndex(prev => {
        if (maxLength - 1 <= prev) return maxLength - 1;
        return prev + 1;
      });
    },
    disabled: index === maxLength - 1,
    color: "secondary",
    endIcon: <KeyboardArrowRightIcon />,
    children: t("次へ"),
  };
  return (
    <Dialog {...dialogProps}>
      <Stack direction="column" width="100%">
        <StyledStack direction="row" justifyContent="space-between" pr={1.5}>
          <Tabs {...tabsProps}>
            <StyledTab value={KindValue.Result} label={t("出力")} color="secondary" aria-controls="output-tab" />
            <StyledTab value={KindValue.Expected} label={t("期待値")} color="secondary" aria-controls="expected-tab" />
          </Tabs>
          <Box>
            <IconButton onClick={handleClose} sx={{ height: "24px", width: "24px" }}>
              <ClearOutlinedIcon sx={{ height: "20px", width: "20px" }} />
            </IconButton>
          </Box>
        </StyledStack>
        <Box minHeight="396px" width="100%">
          {currentResult && <Image src={currentResult[kindValue].src} alt={`${kindValue}-${index}`} icon={{ kind: currentResult.mark }} />}
        </Box>
        <StyledStack direction="row" justifyContent="space-between" px={2} pt={0.75}>
          <Button {...back} />
          <Typography>{`${index + 1} / ${maxLength}`}</Typography>
          <Button {...next} />
        </StyledStack>
      </Stack>
    </Dialog>
  );
};

ImageDiffDialog.displayName = "ImageDiffDialog";

export default ImageDiffDialog;
