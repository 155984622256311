import { useSnapshot } from "valtio";

import { state } from "./State";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useFirebaseAuthStatusCode = () => {
  const snapshot = useSnapshotState();
  return snapshot.firebaseAuthStatusCode;
};

export const useSignInStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.signInStatus;
};

/**
 * TODO @Himenon Design it so that the state always exists.
 */
export const useAfterResetPasswordPayload = () => {
  const snapshot = useSnapshotState();
  return snapshot.afterResetPasswordPayload;
};
