import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type ScreeningTestResourceEditorContainerProps = {};

const ScreeningTestResourceEditorContainer: React.FC<ScreeningTestResourceEditorContainerProps> = () => {
  const screeningTestResourceEditorProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Pages.ScreeningResourceEditor {...screeningTestResourceEditorProps} />
    </ErrorBoundary>
  );
};

ScreeningTestResourceEditorContainer.displayName = "ScreeningTestResourceEditorContainer";

export default withErrorBoundary(ScreeningTestResourceEditorContainer, {});
