import { useSnapshot } from "valtio";

import * as Def from "./definition";
import { state } from "./State";
import type * as Types from "./types";

const useSnapshotState = () => {
  return useSnapshot(state);
};

const useEntity = () => {
  const snapshot = useSnapshotState();
  return snapshot.remote?.entity;
};

export const useInitialized = () => {
  const snapshot = useSnapshotState();
  if (snapshot.fetchStatus === "BEFORE_FETCH") {
    return false;
  }
  /**
   * If an Employee has removed all sessions, the activeSessionId may not exist even after Fetch.
   */
  if (snapshot.activeSessionMap.size === 0) {
    return true;
  }
  /**
   * If at least one session exists, there will always be an activeSessionId.
   */
  return !!snapshot.remote && snapshot.activeSessionId !== null;
};

export const useActiveSessionId = () => {
  const snapshot = useSnapshotState();
  return snapshot.activeSessionId;
};

export const useActiveSession = () => {
  const snapshot = useSnapshotState();
  return snapshot.activeSessionId ? snapshot.activeSessionMap.get(snapshot.activeSessionId) : null;
};

export const useActiveSessions = () => {
  const snapshot = useSnapshotState();
  return Array.from(snapshot.activeSessionMap.values());
};

export const useActiveSessionRemoteQuestionVariant = (): Types.LiveCodingQuestionVariant => {
  const activeSession = useActiveSession();
  if (!activeSession) {
    return "UNKNOWN";
  }
  if (activeSession.algorithmQuestion) {
    return "ALGORITHM";
  }
  if (activeSession.freepadQuestion) {
    return activeSession.freepadQuestion.variant;
  }
  if (activeSession.systemDesignQuestion) {
    return "SYSTEM_DESIGN";
  }
  if (activeSession.questionType === "EMPTY_PAD") {
    return "EMPTY_PAD";
  }
  if (activeSession.questionType === "EMPTY_CANVAS") {
    return "EMPTY_CANVAS";
  }

  return "UNKNOWN";
};

export const useNormalizedSessions = () => {
  const activeSessions = useActiveSessions();
  return activeSessions.map((session): Types.NormalizedSession => {
    if (session?.algorithmQuestion) {
      return {
        questionId: session.algorithmQuestion.questionId,
        questionVersion: session.algorithmQuestion.version,
        variant: session.algorithmQuestion.variant,
        liveCodingId: session.liveCodingId,
        liveCodingSessionId: session.liveCodingSessionId,
        titleJa: session.algorithmQuestion.titleJa,
        titleEn: session.algorithmQuestion.titleEn,
        descriptionJa: session.algorithmQuestion.descriptionJa,
        descriptionEn: session.algorithmQuestion.descriptionEn,
      };
    } else if (session?.freepadQuestion) {
      return {
        questionId: session.freepadQuestion.freepadQuestionId,
        questionVersion: session.freepadQuestion.version,
        variant: session.freepadQuestion.variant,
        liveCodingId: session.liveCodingId,
        liveCodingSessionId: session.liveCodingSessionId,
        titleJa: session.freepadQuestion.titleJa,
        titleEn: session.freepadQuestion.titleEn,
        descriptionJa: session.freepadQuestion.descriptionJa,
        descriptionEn: session.freepadQuestion.descriptionEn,
      };
    } else if (session?.systemDesignQuestion) {
      return {
        questionId: session.systemDesignQuestion.questionId,
        questionVersion: "",
        variant: "SYSTEM_DESIGN",
        liveCodingId: session.liveCodingId,
        liveCodingSessionId: session.liveCodingSessionId,
        titleJa: session.systemDesignQuestion.titleJa,
        titleEn: session.systemDesignQuestion.titleEn,
        descriptionJa: session.systemDesignQuestion.descriptionJa,
        descriptionEn: session.systemDesignQuestion.descriptionEn,
      };
    } else if (session.questionType === "EMPTY_PAD") {
      return {
        questionId: 0,
        questionVersion: "",
        questionType: "EMPTY_PAD",
        variant: "EMPTY_PAD",
        liveCodingId: session.liveCodingId,
        liveCodingSessionId: session.liveCodingSessionId,
        titleJa: "無題のコーディング問題",
        titleEn: "Empty Coding Question",
        descriptionJa: "", // an empty question doesn't have description
        descriptionEn: "", // an empty question doesn't have description
      };
    } else if (session.questionType === "EMPTY_CANVAS") {
      return {
        questionId: 0,
        questionVersion: "",
        questionType: "EMPTY_CANVAS",
        variant: "EMPTY_CANVAS",
        liveCodingId: session.liveCodingId,
        liveCodingSessionId: session.liveCodingSessionId,
        titleJa: "無題のシステム設計問題",
        titleEn: "Empty System Design Question",
        descriptionJa: "", // an empty question doesn't have description
        descriptionEn: "", // an empty question doesn't have description
      };
    }
    throw new Error("Strange error");
  });
};

const useNormalizedActiveSession = () => {
  const snapshot = useSnapshotState();
  const normalizedSessions = useNormalizedSessions();
  return normalizedSessions.find(session => session.liveCodingSessionId === snapshot.activeSessionId);
};

export const useNormalizedCurrentSession = () => {
  const snapshot = useSnapshotState();
  const sessions = useNormalizedSessions();
  return sessions.find(session => session.liveCodingSessionId === snapshot.activeSessionId);
};

export const useIsEmptyQuestionVariant = () => {
  const question = useNormalizedCurrentSession();
  return question?.variant === "EMPTY_PAD" || question?.variant === "EMPTY_CANVAS";
};

export const useLiveCodingId = () => {
  const entity = useEntity();
  if (!entity?.liveCoding?.liveCodingId) {
    throw new Error("Not found live coding ID");
  }
  return entity?.liveCoding?.liveCodingId;
};

export const useLiveCodingVideoChatRoomId = (): string => {
  const snapshot = useSnapshotState();
  return snapshot.liveCodingVideoChatRoomId;
};

export const useLiveCodingVideoChatConversationId = (): string => {
  const snapshot = useSnapshotState();
  return snapshot.liveCodingVideoChatConversationId;
};

export const useAlgorithmId = (): string => {
  const activeSession = useActiveSession();
  if (activeSession?.algorithmQuestion) {
    return activeSession?.liveCodingSessionId.toString() ?? Def.ALGORITHM_ID;
  }
  return Def.ALGORITHM_ID;
};

export const useSystemDesignId = (): string => {
  const activeSession = useActiveSession();
  if (activeSession?.systemDesignQuestion) {
    return activeSession?.liveCodingSessionId.toString() ?? Def.SYSTEM_DESIGN_DUMMY_ID;
  }
  return Def.SYSTEM_DESIGN_DUMMY_ID;
};

export const useInterviewStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.remote?.status;
};

export const useLiveCodingQuestionVariant = () => {
  const snapshot = useSnapshotState();
  return snapshot.liveCodingQuestionVariant;
};

export const useLiveCodingTemplateCodes = () => {
  const snapshot = useSnapshotState();
  const normalizedActiveSession = useNormalizedActiveSession();
  if (!normalizedActiveSession) {
    return null;
  }
  const key: Types.LiveCodingTemplateCodesMapKey = `${normalizedActiveSession.questionId}-${normalizedActiveSession.questionVersion}`;
  return snapshot.liveCodingTemplateCodesMap.get(key);
};

export const useLiveCodingTemplateCodeFetched = (): boolean => {
  const snapshot = useSnapshotState();
  const activeSession = useActiveSession();
  const normalizedActiveSession = useNormalizedActiveSession();
  /**
   * TemplateCode of freepad is contained in Session
   */
  if (activeSession?.questionType === "FREEPAD") {
    return true;
  }
  if (!normalizedActiveSession) {
    return false;
  }
  const key: Types.LiveCodingTemplateCodesMapKey = `${normalizedActiveSession.questionId}-${normalizedActiveSession.questionVersion}`;
  return snapshot.liveCodingTemplateCodesMap.has(key);
};

export const useInitialCodeMapForFreepad = (): Record<string, string> => {
  const activeSession = useActiveSession();
  return (activeSession?.freepadQuestion?.initialCodes || []).reduce((all, initialCode) => {
    return { ...all, [initialCode.runtime]: initialCode.body };
  }, {});
};

/**
 * Key: ComponentType
 */
export const useInitialFlowChartMap = (): Record<Types.ComponentType, string | undefined> => {
  const activeSession = useActiveSession();
  return (activeSession?.freepadQuestion?.initialFlowCharts || []).reduce((all, initialFlowChart) => {
    return { ...all, [initialFlowChart.componentType]: initialFlowChart.body };
  }, {});
};

export const useCheckJoinStatus = (uid: string | null) => {
  const entity = useEntity();
  if (!uid) {
    return false;
  }
  const participants = entity?.liveCoding?.participants || [];
  return participants.findIndex(participant => participant.userId === uid) >= 0;
};

export const useInterviewName = () => {
  const snapshot = useSnapshotState();
  return snapshot.remote?.name;
};

export const useCompanyName = () => {
  const snapshot = useSnapshotState();
  return snapshot.remote?.company?.name;
};

export const useCandidateUser = (uid: string) => {
  const entity = useEntity();
  const participant = entity?.liveCoding?.participants.find(participant => participant.userId === uid);
  if (!participant) {
    throw new Error("user not found. It will not work properly.");
  }
  return participant;
};
