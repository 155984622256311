import * as React from "react";

import { useSplitContext } from "../Context";
import type * as Types from "../types";

export type WrapperProps = {
  className?: string;
  /**
   * Left Side Content Id
   */
  contentId: Types.ContentId;
  direction: Types.SplitDirection;
  children?: React.ReactNode;
};

const NOOP = () => undefined;

const Wrapper: React.FC<WrapperProps> = props => {
  const ref = React.useRef<HTMLDivElement>(null);
  const context = useSplitContext();
  React.useLayoutEffect(() => {
    context.store.setContentState(props.contentId, {
      setSize: size => {
        if (!ref.current) {
          return;
        }
        if (props.direction === "HORIZONTAL") {
          ref.current.style.height = `${size.value}${size.unit}`;
        } else {
          ref.current.style.width = `${size.value}${size.unit}`;
        }
      },
      getBoundingClientRect: () => {
        if (!ref.current) {
          return {
            width: 0,
            height: 0,
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          };
        }
        const rect = ref.current.getBoundingClientRect();
        return {
          width: rect.width,
          height: rect.height,
          left: rect.left,
          right: rect.right,
          top: rect.top,
          bottom: rect.bottom,
        };
      },
      onDragStart: () => {
        if (!ref.current) {
          return;
        }
        ref.current.addEventListener("selectstart", NOOP);
        ref.current.addEventListener("dragstart", NOOP);
        ref.current.style.userSelect = "none";
        ref.current.style.pointerEvents = "none";
      },
      onDragEnd: () => {
        if (!ref.current) {
          return;
        }
        ref.current.removeEventListener("selectstart", NOOP);
        ref.current.removeEventListener("dragstart", NOOP);
        ref.current.removeEventListener("selectstart", NOOP);
        ref.current.removeEventListener("dragstart", NOOP);
        ref.current.style.userSelect = "";
        ref.current.style.pointerEvents = "";
      },
    });
  }, [context.store, props.contentId, props.direction]);
  return (
    <div ref={ref} className={props.className}>
      {props.children}
    </div>
  );
};

Wrapper.displayName = "Wrapper";

export default React.memo(Wrapper);
