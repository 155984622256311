import { DeepReadonly } from "@hireroo/app-helper/types";
import { MemberSettings } from "@hireroo/app-store/widget/e/MemberSettings";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

type SuggestionItem = Widget.AssignFieldProps["suggestions"][0];

type DisplaySources = Widget.AssignFieldProps["displaySources"];

export type GenerateEmployeeGroupAssignFieldPropsArgs = {
  variant: Widget.AssignFieldProps["variant"];
  selectableGroups: DeepReadonly<MemberSettings.Group>[];
};

export const useGenerateProps = (args: GenerateEmployeeGroupAssignFieldPropsArgs): Widget.AssignFieldProps => {
  const { t } = useTranslation();
  const { selectableGroups } = args;

  const suggestionsProps = React.useMemo((): SuggestionItem[] => {
    const suggestions: SuggestionItem[] = [];
    selectableGroups.forEach(group => {
      suggestions.push({
        type: "EMPLOYEE_GROUP",
        valueId: group.id,
        locked: false,
      });
    });
    return suggestions;
  }, [selectableGroups]);

  const displayNamesProps = React.useMemo((): DisplaySources => {
    const displayNames: DisplaySources = {};
    selectableGroups.forEach(group => {
      displayNames[group.id] = {
        optionText: `${group.groupName || "No Name"}`,
        chipText: group.groupName || "No Name",
      };
    });
    return displayNames;
  }, [selectableGroups]);

  return {
    multiple: true,
    variant: args.variant,
    name: "groups",
    placeholder: t("グループ名で検索"),
    suggestions: suggestionsProps,
    displaySources: displayNamesProps,
    loading: false,
  };
};
