import { App } from "@hireroo/app-store/essential/employee";
import { FreepadResourceEditor } from "@hireroo/app-store/view-domain/FreepadResourceEditor";
import { Pages, Widget } from "@hireroo/presentation/legacy";
import * as React from "react";

import EmployeeNavigationContainer from "../../../widget/e/EmployeeNavigation/Container";
import NotificationBannerContainer from "../../../widget/e/NotificationBanner/Container";
import FooterContainer from "../../../widget/shared/Footer/Container";
import SnackbarContainer from "../../../widget/shared/Snackbar/Container";
import { useGenerateAnswersProps } from "./useGenerateAnswersProps";
import { useGenerateOptionalProps } from "./useGenerateOptionalProps";
import { useGenerateQuestionDetailsProps } from "./useGenerateQuestionDetailsProps";

export type GenerateFreepadResourceEditorProps = {
  companyId: number;
  type: "CREATE" | "UPDATE";
};

export const useGenerateFreepadResourceEditorProps = (args: GenerateFreepadResourceEditorProps): Pages.FreepadResourceEditorProps => {
  const activeStep = FreepadResourceEditor.useActiveStep();
  const appStatus = App.useStatus();
  const questionDetails = useGenerateQuestionDetailsProps({ companyId: args.companyId });
  const answers = useGenerateAnswersProps({ companyId: args.companyId });
  const optional = useGenerateOptionalProps({ companyId: args.companyId });
  const saveStatus = FreepadResourceEditor.useSaveStatus();
  const { methods: answersMethods } = Widget.useFreepadAnswerFormContext();
  const { methods: optionalMethods } = Widget.useFreepadOptionalFormContext();

  return {
    activeStep: activeStep,
    layout: {
      loading: appStatus === "INITIALIZING",
      Header: <EmployeeNavigationContainer />,
      Banner: <NotificationBannerContainer />,
      Footer: <FooterContainer />,
      Bottom: <SnackbarContainer />,
    },
    previousButton: {
      onClick: () => {
        switch (activeStep) {
          case FreepadResourceEditor.STEPS.ANSWERS:
            answersMethods.reset();
            break;
          case FreepadResourceEditor.STEPS.OPTIONAL:
            optionalMethods.reset();
            break;
        }
        FreepadResourceEditor.goPrevious();
      },
      disabled: saveStatus === "LOADING",
    },
    questionDetails: questionDetails.props,
    saveQuestionDetailsButton: questionDetails.saveButton,
    answers: answers.props,
    saveAnswersButton: answers.saveButton,
    optional: optional.props,
    saveOptionalButton: optional.saveButton,
    draftSaveButton: {
      onClick: () => {
        switch (activeStep) {
          case FreepadResourceEditor.STEPS.ANSWERS:
            answers.onDraftSave();
            break;
          case FreepadResourceEditor.STEPS.OPTIONAL:
            optional.onDraftSave();
            break;
        }
      },
      disabled: saveStatus === "LOADING",
    },
  };
};
