import { BrowserWindowEventDetect, ChallengePad, useFirebaseSender, useMonacoEditorObserver } from "@hireroo/app-helper/hooks";
import { ChallengeCodingEditor } from "@hireroo/app-store/widget/shared/ChallengeCodingEditor";
import { getTimestamp } from "@hireroo/firebase";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { isValidLeaveEventDurationTime, observerPageLinkClickEvent } from "./privateHelper";
import { GenerateChallengeCodingEditorPropsArgs, useGenerateProps } from "./useGenerateProps";

export type ChallengeCodingEditorContainerProps = {
  entityId: number;
  interviewKind: "INTERVIEW" | "DEMO";
  editorKind: GenerateChallengeCodingEditorPropsArgs["editorKind"];
  uid: string;
  displayName: string;
  enableBrowserEventDetector: boolean;
  collaborativeState: ChallengePad.CollaborativeState;
  collaborativeAction: ChallengePad.CollaborativeAction;
};

const ChallengeCodingEditorContainer: React.FC<ChallengeCodingEditorContainerProps> = props => {
  const { entityId, enableBrowserEventDetector, collaborativeState, collaborativeAction } = props;
  const { clearLeavePageAction: clearWebSearchAction } = ChallengeCodingEditor.createChallengeEntityAction(entityId);
  const challengeEntityHooks = ChallengeCodingEditor.useCreateChallengeEntityHooks(entityId);
  const { selectedLanguage } = Widget.useChallengeCodingEditorContext();
  const entity = challengeEntityHooks.useEntity();
  const ipAddress = ChallengeCodingEditor.useIpAddress();
  const geolocation = ChallengeCodingEditor.useGeolocation();
  const cleanup = React.useRef<() => void>(() => undefined);
  const entityApi = React.useMemo(() => {
    return ChallengeCodingEditor.Api.createEntityApi(entityId);
  }, [entityId]);

  React.useEffect(() => {
    const stop = observerPageLinkClickEvent(entityId);
    return () => {
      stop();
      cleanup.current();
    };
  }, [entityId]);

  const { pushEventHistory, pushAccessEvent } = useFirebaseSender({
    uid: props.uid,
    appType: "challenge",
    challengeId: entity.challengeEntityId,
    questionId: entity?.challengeQuestion?.questionId ?? 0,
  });

  const browserWindowEventDetector = React.useRef(
    new BrowserWindowEventDetect({
      callback: pushEventHistory,
      additionalParameterFactory: {
        getHideReason: () => {
          const nowMilliSeconds = Date.now();
          const leavePageAction = entityApi.getLeavePageAction();
          if (!leavePageAction) {
            return null;
          }
          const durationMilliseconds = nowMilliSeconds - leavePageAction.createdAtMilliseconds;
          if (!isValidLeaveEventDurationTime(durationMilliseconds)) {
            return null;
          }
          switch (leavePageAction.type) {
            case "GOOGLE_SEARCH":
              return {
                k: "googleSearch",
              };
            case "CLICK":
              return {
                k: "clickElement",
                x: leavePageAction.xPath,
              };
            default:
              throw new Error(`Leave Page Action: ${leavePageAction satisfies never}`);
          }
          return null;
        },
        getLink: () => {
          const leavePageAction = entityApi.getLeavePageAction();
          if (!leavePageAction) {
            return null;
          }
          const nowMilliSeconds = Date.now();
          const durationMilliseconds = nowMilliSeconds - leavePageAction.createdAtMilliseconds;
          if (!isValidLeaveEventDurationTime(durationMilliseconds)) {
            return null;
          }
          if (leavePageAction.type === "GOOGLE_SEARCH") {
            return leavePageAction.url;
          }
          return null;
        },
      },
      onDispatched: eventName => {
        if (eventName === "hidden") {
          clearWebSearchAction();
        }
      },
    }),
  );

  const clipboardEventSender = useFirebaseSender({
    uid: props.uid,
    appType: "challenge-language",
    challengeId: entity.challengeEntityId,
    language: selectedLanguage,
    questionId: entity?.challengeQuestion?.questionId ?? 0,
  });

  const { startWatch } = useMonacoEditorObserver({
    callback: clipboardEventSender.pushEventHistory,
  });

  const challengeCodingEditorProps = useGenerateProps({
    ...props,
    collaborativeState,
    collaborativeAction,
    onMount: (editor, monaco) => {
      cleanup.current = startWatch(editor, monaco);
      if (ipAddress !== null && geolocation !== null) {
        pushAccessEvent({
          l: ipAddress,
          g: geolocation,
          t: getTimestamp(),
        });
      }
    },
  });

  React.useEffect(() => {
    if (!enableBrowserEventDetector) {
      return;
    }
    if (entity.challengeStatus !== "STARTED") {
      return;
    }
    const stop = browserWindowEventDetector.current.subscribe();
    return () => {
      stop();
    };
  }, [browserWindowEventDetector, entity.challengeStatus, enableBrowserEventDetector]);

  if (!collaborativeState.ready) {
    return <Widget.Loading kind="CENTER_%" color="secondary" />;
  }

  return (
    <ErrorBoundary>
      <Widget.ChallengeCodingEditor {...challengeCodingEditorProps} />
    </ErrorBoundary>
  );
};

ChallengeCodingEditorContainer.displayName = "ChallengeCodingEditorContainer";

export default withErrorBoundary(ChallengeCodingEditorContainer, {});
