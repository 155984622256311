import { proxyMap } from "valtio/utils";

import { State } from "./types";

export const createDefaultState = (): Readonly<State> => ({
  temporarySelectedQuestions: proxyMap(),
  searchQuery: {
    cursorSeconds: null,
    isDescending: false,
    queryString: "",
    size: 50,
    status: "UNKNOWN",
    tagIds: [],
    withCount: true,
  },
  questions: proxyMap(),
  pageQuestions: proxyMap(),
  page: 0,
  pager: null,
  selectedQuestions: proxyMap(),
  recordCount: 0,
});
