import { QuestionSearchArea } from "@hireroo/app-store/widget/e/QuestionSearchArea";
import { useCurrentLanguage } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import {
  convertStringsToDifficulties,
  convertStringsToQuestionLeakScoreLevel,
  convertStringsToQuestionVariants,
  convertStringsToSkillTagIds,
  convertStringsToStatuses,
  convertStringToIsDesc,
  convertStringToSortMethod,
} from "./privateHelper";
import { startSubscribe } from "./Subscriber";
import { useGenerateProps } from "./useGenerateProps";

export type RemoteInterviewQuestionSearchAreaContainerProps = {
  isLoading?: boolean;
};

const RemoteInterviewQuestionSearchAreaContainer: React.FC<RemoteInterviewQuestionSearchAreaContainerProps> = (
  props: RemoteInterviewQuestionSearchAreaContainerProps,
) => {
  const language = useCurrentLanguage();
  const questionSearchAreaProps = useGenerateProps(props);
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    const stopSubscribe = startSubscribe({
      updateQueryParams: params => {
        setSearchParams(params);
      },
    });
    return () => {
      stopSubscribe();
    };
  }, [setSearchParams]);

  React.useEffect(() => {
    return () => {
      QuestionSearchArea.clearCurrentSkillTagState();
    };
  }, []);

  React.useEffect(() => {
    QuestionSearchArea.initialize({
      size: searchParams.get(QuestionSearchArea.QueryKeys.SIZE) ? Number(searchParams.get(QuestionSearchArea.QueryKeys.SIZE)) : undefined,
      // Actual (zero-index) page number = (page number from the query param) - 1
      page: searchParams.get(QuestionSearchArea.QueryKeys.PAGE) ? Number(searchParams.get(QuestionSearchArea.QueryKeys.PAGE)) - 1 : undefined,
      filters: {
        titleJa: language === "ja" ? searchParams.get(QuestionSearchArea.QueryKeys.TITLE) ?? "" : "",
        titleEn: language === "en" ? searchParams.get(QuestionSearchArea.QueryKeys.TITLE) ?? "" : "",
        statuses: convertStringsToStatuses(searchParams.getAll(QuestionSearchArea.QueryKeys.STATUS)),
        questionVariants: convertStringsToQuestionVariants(searchParams.getAll(QuestionSearchArea.QueryKeys.QUESTION_VARIANT)),
        difficulties: convertStringsToDifficulties(searchParams.getAll(QuestionSearchArea.QueryKeys.DIFFICULTY)),
        skillTagIds: convertStringsToSkillTagIds(searchParams.getAll(QuestionSearchArea.QueryKeys.SKILL_TAG)),
        leakScoreLevels: convertStringsToQuestionLeakScoreLevel(searchParams.getAll(QuestionSearchArea.QueryKeys.LEAK_SCORE_LEVEL)),
      },
      sortMethod: convertStringToSortMethod(searchParams.get(QuestionSearchArea.QueryKeys.SORT)),
      isDescending: convertStringToIsDesc(searchParams.get(QuestionSearchArea.QueryKeys.SORT)),
    });
  }, [language, searchParams]);

  return (
    <ErrorBoundary>
      <Widget.RemoteInterviewQuestionSearchArea {...questionSearchAreaProps} />
    </ErrorBoundary>
  );
};

RemoteInterviewQuestionSearchAreaContainer.displayName = "RemoteInterviewQuestionSearchAreaContainer";

export default withErrorBoundary(RemoteInterviewQuestionSearchAreaContainer, {});
