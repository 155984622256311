import { useTitle } from "@hireroo/app-helper/react-use";
import { useTranslation } from "@hireroo/i18n";
import type { Pages } from "@hireroo/presentation";
import * as React from "react";

import FooterContainer from "../../../../widget/v2/e/Footer/Container";
import { useNotificationBanner } from "../../../../widget/v2/e/NotificationBanner/Container";
import ScreeningTestTutorialContainer from "../../../../widget/v2/shared/ScreeningTestTutorial/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import ScreeningTestReviewNavigationContainer, {
  ScreeningTestReviewNavigationContainerProps,
} from "./widget/ScreeningTestReviewNavigation/Container";
import TestReviewFetchContainer from "./widget/TestReview/FetchContainer";

export type GenerateInterviewPropsArgs = {};

export const useGenerateProps = (_args: GenerateInterviewPropsArgs): Pages.ScreeningTestProps => {
  const { t } = useTranslation();
  const interviewNavigationContainerProps: ScreeningTestReviewNavigationContainerProps = {};
  const NotificationBannerContainer = useNotificationBanner();

  useTitle(t("レビュー"));

  return {
    layout: {
      loading: false,
      NotificationBanner: NotificationBannerContainer,
      Header: <ScreeningTestReviewNavigationContainer {...interviewNavigationContainerProps} />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      Tutorial: <ScreeningTestTutorialContainer />,
    },
    Content: <TestReviewFetchContainer />,
  };
};
