import { parseOutputRecords, parseTableRecords, typeLabelMap } from "@hireroo/challenge/definition";
import * as Graphql from "@hireroo/graphql/client/urql";
import { AlgorithmSignatureForm, AlgorithmTestCaseForm, QuestionForList, ValidateDatabaseVariantInput } from "@hireroo/validator";

export const isSignatureDifferent = (
  left: AlgorithmSignatureForm.AlgorithmSignatureSchemaType,
  right: AlgorithmSignatureForm.AlgorithmSignatureSchemaType,
): boolean => {
  // stringify to format the Escape of the contents.
  // replacer null and space 2 are needed for strict compare
  return JSON.stringify(left, null, 2) !== JSON.stringify(right, null, 2);
};

export const isDBSignatureDifferent = (
  left: AlgorithmSignatureForm.DatabaseSignatureSchemaType,
  right: AlgorithmSignatureForm.DatabaseSignatureSchemaType,
): boolean => {
  // stringify to format the Escape of the contents.
  // replacer null and space 2 are needed for strict compare
  return JSON.stringify(left, null, 2) !== JSON.stringify(right, null, 2);
};

//testcases
export const getDBTestCasesInputsErrorMessages = (
  tc: AlgorithmTestCaseForm.CorrectnessTestCaseSchema | AlgorithmTestCaseForm.PerformanceTestCaseSchema,
  signature: AlgorithmSignatureForm.DatabaseSignatureSchemaType,
): string[] => {
  const inputsErrorMessages: string[] = [];
  tc.inputs.forEach((input, tableIndex) => {
    const parsedInput = parseTableRecords(input);
    parsedInput.forEach(row => {
      signature.tables[tableIndex].columns.forEach((col, index) => {
        // return when null
        if (row[index] === "" || row[index] === undefined) return;
        if (!ValidateDatabaseVariantInput.isDatabaseTestCaseValid(row[index]?.toString() || null, col.type)) {
          inputsErrorMessages.push(`${typeLabelMap[col.type]} ${signature.tables[tableIndex].name}(${col.name}: ${row[index]})`);
        }
      });
    });
  });

  return inputsErrorMessages;
};
export const getDBTestCasesOutputsErrorMessages = (
  tc: AlgorithmTestCaseForm.CorrectnessTestCaseSchema | AlgorithmTestCaseForm.PerformanceTestCaseSchema,
  signature: AlgorithmSignatureForm.DatabaseSignatureSchemaType,
): string[] => {
  const outputsErrorMessages: string[] = [];
  tc.outputs.forEach(output => {
    const parsedOutput = parseOutputRecords(output);
    // return when null
    if (parsedOutput[0] === undefined) return;
    // The outputs have only one table, hence parse the first element
    Object.values(parsedOutput[0]).forEach((val, columnIndex) => {
      if (val === undefined) return;
      const col = signature.columns[columnIndex];
      if (col === undefined) {
        outputsErrorMessages.push(`(${columnIndex})`);
      } else if (!ValidateDatabaseVariantInput.isDatabaseTestCaseValid(val?.toString() || null, col.type)) {
        outputsErrorMessages.push(`${typeLabelMap[col.type]} (${col.name}: ${val})`);
      }
    });
  });

  return outputsErrorMessages;
};

export const extractQuestionForList = (
  updatedQuestion: Graphql.UpdateAlgorithmQuestionMutation["updateAlgorithmQuestion"],
): QuestionForList.QuestionForListSchemaType => {
  return {
    id: updatedQuestion.questionId,
    version: updatedQuestion.version,
    difficulty: updatedQuestion.difficulty,
    type: "AlgorithmQuestion",
    titleJa: updatedQuestion.titleJa ?? "",
    titleEn: updatedQuestion.titleEn ?? "",
    descriptionJa: updatedQuestion.descriptionJa ?? "",
    descriptionEn: updatedQuestion.descriptionEn ?? "",
    status: updatedQuestion.status,
    isPrivate: updatedQuestion.isPrivate,
    supportedLanguages: updatedQuestion.supportedLanguages,
    companyId: updatedQuestion.companyId,
    employeeId: updatedQuestion.employeeId,
    isOfficial: updatedQuestion.isOfficial,
    numUses: updatedQuestion.numUses,
    accuracyRate: updatedQuestion.accuracyRate,
    averageElapsedTimeSeconds: updatedQuestion.averageElapsedTimeSeconds,
  };
};
