import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import Container from "./Container";
import AlgorithmResourceProvider from "./Provider";

export type QuizResourceEditorMiddleContainerProps = {};

const AlgorithmResourceEditorMiddleContainer: React.FC<QuizResourceEditorMiddleContainerProps> = _props => {
  return (
    <ErrorBoundary>
      <AlgorithmResourceProvider type={"CREATE"}>
        <Container />
      </AlgorithmResourceProvider>
    </ErrorBoundary>
  );
};

AlgorithmResourceEditorMiddleContainer.displayName = "AlgorithmResourceEditorMiddleContainer";

export default withErrorBoundary(AlgorithmResourceEditorMiddleContainer, {});
