import { LATEST_VERSION } from "@hireroo/app-definition/question";
import { proxyMap } from "valtio/utils";

import { state } from "./State";
import type * as Types from "./types";

export const initialize = (question: Types.Question, isLatest: boolean): void => {
  const questionState: Types.QuestionState = {
    question: question,
    testCasesResults: proxyMap(),
    outputStatus: proxyMap(),
  };
  const uniqueKey = isLatest ? `${question.questionId}-${LATEST_VERSION}` : question.id;
  state.questions.set(uniqueKey, questionState);
};

export const createQuestionAction = (questionUid: string) => {
  const questionState = state.questions.get(questionUid);

  const setTestcaseResult = (testCaseIndex: Types.TestCaseIndex, testCaseResult: Types.TestCaseResult) => {
    questionState?.testCasesResults.set(testCaseIndex, testCaseResult);

    if (questionState) {
      questionState.testCasesResults.forEach((value, key) => {
        questionState.outputStatus.set(key, value.isAccepted ? "COMPLETED" : "REJECTED");
      });
    }
  };

  const clearTestcaseResults = () => {
    questionState?.testCasesResults.clear();
    questionState?.outputStatus.clear();
  };

  const setLoadingOutputStatus = (testCaseIndex: Types.TestCaseIndex) => {
    questionState?.outputStatus.set(testCaseIndex, "LOADING");
  };
  const setRejectedOutputStatus = (testCaseIndex: Types.TestCaseIndex) => {
    questionState?.outputStatus.set(testCaseIndex, "REJECTED");
  };

  const clearTestcaseResult = (testCaseIndex: Types.TestCaseIndex) => {
    questionState?.testCasesResults.delete(testCaseIndex);
    questionState?.outputStatus.delete(testCaseIndex);
  };

  return {
    setTestcaseResult,
    setRejectedOutputStatus,
    clearTestcaseResults,
    setLoadingOutputStatus,
    clearTestcaseResult,
  };
};

export const updateRunningStatus = (runStatus: Types.RunStatus) => {
  state.runStatus = runStatus;
};
