import { useTranslation } from "@hireroo/i18n";
import { ChallengeCodingEditorForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import SendIcon from "@mui/icons-material/Send";
import Box from "@mui/material/Box";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import InputControlTextField, {
  InputControlTextFieldProps,
} from "../../../../../primitive/InputControl/InputControlTextField/InputControlTextField";

const Wrapper = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
}));

const TextFieldWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.Other.FilledInputBG,
  borderRadius: "4px",
  width: "100%",
  height: "fit-content",
  display: "flex",
  flexDirection: "row",
}));

const StyledIconButton = styled(IconButton)(() => ({
  padding: 0,
  position: "absolute",
  right: "8px",
  bottom: "8px",
}));

const RightBoxWrapper = styled(Box)(() => ({
  marginLeft: "8px",
  position: "relative",
  width: "42px",
}));

const StyledInputControlTextField = styled(InputControlTextField)(({ theme }) => ({
  "& .MuiInput-input": {
    padding: theme.spacing(1),
    paddingRight: theme.spacing(4.5),
  },
  "& .MuiInput-root": {
    minHeight: 26,
    fontSize: 14,
    lineHeight: "1.5em",
    paddingBottom: 0,
  },
}));

const StyledResetIconButton = styled(IconButton)(() => ({
  position: "absolute",
  bottom: "0px",
  right: "0px",
  root: {
    "&.Mui-disabled": {
      pointerEvents: "auto",
    },
  },
}));

export type PromptTextFieldProps = {
  loadingStatus: "LOADING" | "RESETTING" | "NONE";
  onSubmit: SubmitHandler<ChallengeCodingEditorForm.ChatGPTFormSchema>;
  resetButton: IconButtonProps;
};

const PromptTextField: React.FC<PromptTextFieldProps> = props => {
  const { t } = useTranslation();
  const { onSubmit } = props;

  const validateSchema = ChallengeCodingEditorForm.useChatGPTForm();
  const methods = useForm<ChallengeCodingEditorForm.ChatGPTFormSchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: {
      prompt: "",
    },
  });

  const sendButton: IconButtonProps = {
    children: <SendIcon color="action" />,
    disabled: props.loadingStatus !== "NONE",
    onClick: () => {
      if (props.loadingStatus !== "NONE") {
        return;
      }
      onSubmit(methods.getValues());
      methods.resetField("prompt");
    },
  };

  const searchFormProps: InputControlTextFieldProps = {
    placeholder: t("メッセージを送信"),
    variant: "standard",
    multiline: true,
    maxRows: 7,
    fullWidth: true,
    InputProps: {
      disableUnderline: true,
      endAdornment: sendButton.disabled ? (
        <Tooltip title={t("メッセージを送信")} placement="top">
          <span>
            <StyledIconButton {...sendButton} />
          </span>
        </Tooltip>
      ) : (
        <Tooltip title={t("メッセージを送信")} placement="top">
          <StyledIconButton {...sendButton} />
        </Tooltip>
      ),
    },
    onKeyDownCapture: event => {
      if (props.loadingStatus !== "NONE") {
        return;
      }
      const value = methods.getValues();
      const trimmedPromptText = value.prompt.trim();
      if (trimmedPromptText.length === 0) {
        return;
      }
      if (trimmedPromptText.length > 0 && event.key === "Enter" && !event.shiftKey && !event.nativeEvent.isComposing) {
        onSubmit(methods.getValues());
        methods.setValue("prompt", "");
        event.currentTarget.focus();
        event.preventDefault();
        return;
      }
    },
  };

  const tooltipTitle = props.resetButton.disabled ? t("チャットの履歴が無いためリセットできません") : t("チャットの履歴をリセットする");

  return (
    <FormProvider {...methods}>
      <Wrapper>
        <TextFieldWrapper>
          <StyledInputControlTextField name="prompt" {...searchFormProps} />
        </TextFieldWrapper>
        <RightBoxWrapper>
          <Tooltip title={tooltipTitle} placement="top">
            <span>
              <StyledResetIconButton {...props.resetButton}>
                <RefreshOutlinedIcon />
              </StyledResetIconButton>
            </span>
          </Tooltip>
        </RightBoxWrapper>
      </Wrapper>
    </FormProvider>
  );
};

PromptTextField.displayName = "PromptTextField";

export default PromptTextField;
