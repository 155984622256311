import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import * as React from "react";
import { useController } from "react-hook-form";

import { useSearchFormContext } from "../../PrivateContext";
import SplitButton, { SplitButtonProps } from "../SplitButton/SplitButton";

type FieldValue = string[];

type CheckboxSelectorPropsOption = {
  value: string;
  displayName: string;
  children?: CheckboxSelectorPropsOption[];
};

const checkChildrenCheckedStatus = (option: CheckboxSelectorPropsOption, selected: string[]): "all" | "some" | "none" => {
  if (option.children) {
    const numberOfSelectedInOption = option.children.filter(child => selected.includes(child.value)).length;
    if (numberOfSelectedInOption === 0) {
      return "none";
    } else if (numberOfSelectedInOption > 0 && numberOfSelectedInOption < option.children.length) {
      return "some";
    } else return "all";
  } else {
    return selected.includes(option.value) ? "all" : "none";
  }
};

const extractLeafValues = (option: CheckboxSelectorPropsOption[]): string[] => {
  return option.reduce<string[]>((all, child) => {
    if (child.children) {
      return all.concat(extractLeafValues(child.children));
    } else {
      return all.concat(child.value);
    }
  }, []);
};

export type GroupMultiChoiceFieldProps = {
  name: string;
  title: string;
  options: CheckboxSelectorPropsOption[];
  onChange?: (value: FieldValue) => void;
  disabled?: boolean;
  variant?: SplitButtonProps["variant"];
};

const GroupMultiChoiceField: React.FC<GroupMultiChoiceFieldProps> = props => {
  const { onChange } = props;
  const { subscribeClearAllFields: onClearFields } = useSearchFormContext();
  const { field } = useController<Record<string, FieldValue>>({
    name: props.name,
  });
  const [selectedOptions, setSelectedOptions] = React.useState<FieldValue>(field.value);
  const resetField = React.useCallback(() => {
    setSelectedOptions([]);
    onChange?.([]);
    field.onChange([]);
  }, [field, onChange]);

  React.useEffect(() => {
    const stop = onClearFields(() => {
      setSelectedOptions([]);
      onChange?.([]);
    });
    return () => {
      stop();
    };
  }, [onChange, onClearFields]);

  const handleChange = (value: FieldValue) => {
    props.onChange?.(value);
    field.onChange(value);
  };

  const handleSetSelectedOptions = (items: FieldValue) => {
    setSelectedOptions(items);
  };

  const handleChildChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const parentObject = props.options.find(parent => parent.children?.map(child => child.value).includes(value));

    if (selectedOptions.includes(value)) {
      const tempArray: string[] = [...selectedOptions.filter(option => option !== value)];

      if (parentObject?.children) {
        const allChildrenAreUnselected = parentObject.children.every(child => !tempArray.includes(child.value));

        if (allChildrenAreUnselected) {
          handleSetSelectedOptions(tempArray.filter(option => option !== parentObject.value));
        } else {
          handleSetSelectedOptions(selectedOptions.filter(option => option !== value));
        }
      }
    } else {
      const tempArray = [...selectedOptions, value];

      if (parentObject?.value && !selectedOptions.includes(parentObject?.value)) {
        tempArray.push(parentObject?.value);
      }
      handleSetSelectedOptions(tempArray);
    }
  };

  const handleParentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    const parentObject = props.options.filter(parent => parent.value === value);
    const childrenOfParent = parentObject[0].children;
    let selectedArr = [...selectedOptions];

    if (checked) {
      if (childrenOfParent) {
        childrenOfParent.forEach(child => {
          if (!selectedArr.includes(child.value)) {
            selectedArr.push(child.value);
          }
        });
      }
      !selectedOptions.includes(value) && selectedArr.push(value);

      handleSetSelectedOptions(selectedArr);
    } else {
      if (!childrenOfParent) {
        const indexOfValue = selectedArr.indexOf(value);
        selectedArr.splice(indexOfValue, 1);
        handleSetSelectedOptions(selectedArr);
      } else {
        childrenOfParent.forEach(child => {
          if (selectedArr.includes(child.value)) {
            selectedArr = selectedArr.filter(selected => selected !== child.value);
          }
        });
        const indexOfParent = selectedArr.indexOf(value);
        selectedArr.splice(indexOfParent, 1);
        handleSetSelectedOptions(selectedArr);
      }
    }
  };

  const leafOptionValues = React.useMemo(() => {
    return extractLeafValues(props.options);
  }, [props.options]);

  const labelText =
    selectedOptions.length === 0 ? props.title : `${props.title} (${selectedOptions.filter(o => leafOptionValues.includes(o)).length})`;

  const splitButtonProps: SplitButtonProps = {
    title: props.title,
    disabled: props.disabled,
    status: selectedOptions.length > 0 ? "ACTIVE" : "INACTIVE",
    label: {
      children: labelText,
    },
    onApply: () => {
      handleChange(selectedOptions);
    },
    onReset: () => {
      resetField();
    },
    variant: props.variant,
  };

  return (
    <SplitButton {...splitButtonProps}>
      <Box py={0.5} overflow="auto" maxHeight="30vh">
        <FormGroup>
          {props.options.map((option, i) => (
            <Box key={option.value + i}>
              <FormControlLabel
                label={option.displayName}
                key={option.value}
                control={
                  <Checkbox
                    value={option.value}
                    checked={checkChildrenCheckedStatus(option, selectedOptions) === "all"}
                    indeterminate={checkChildrenCheckedStatus(option, selectedOptions) === "some"}
                    onChange={handleParentChange}
                    color="secondary"
                  />
                }
              />
              {option.children && (
                <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
                  {option.children.map(child => (
                    <FormControlLabel
                      label={child.displayName}
                      key={child.value}
                      control={
                        <Checkbox
                          color="secondary"
                          value={child.value}
                          checked={selectedOptions.includes(child.value)}
                          onChange={handleChildChange}
                        />
                      }
                    />
                  ))}
                </Box>
              )}
            </Box>
          ))}
        </FormGroup>
      </Box>
    </SplitButton>
  );
};

GroupMultiChoiceField.displayName = "GroupMultiChoiceField";

export default GroupMultiChoiceField;
