import { useTranslation } from "@hireroo/i18n";
import { ThemeProvider } from "@hireroo/ui-theme/theme-v2";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";

const StyledButton = styled(LoadingButton)(({ theme }) => ({
  paddingLeft: 10,
  paddingRight: 10,
  /**
   * Ensure that the button design does not change with the expansion and contraction of the display area
   */
  wordBreak: "keep-all",
  "&.MuiButton-outlined:not(:hover)": {
    backgroundColor: "white",
  },
  height: "32px",
  "&:hover": {
    borderColor: theme.palette.secondary.main,
  },
}));

export type EditControlPanelProps = {
  runButton?: Pick<LoadingButtonProps, "disabled" | "onClick">;
  submitButton?: Pick<LoadingButtonProps, "disabled" | "onClick" | "loading">;
};

const EditControlPanel: React.FC<EditControlPanelProps> = props => {
  const { t } = useTranslation();

  return (
    <Box>
      <Stack spacing={2} direction="row">
        {(props.runButton || props.submitButton) && (
          <Stack spacing={2} direction="row">
            <ThemeProvider defaultColorMode="LIGHT">
              {props.runButton && (
                <StyledButton
                  {...props.runButton}
                  startIcon={<PlayArrowIcon />}
                  color="secondary"
                  variant="outlined"
                  id="system-design-ru-test-button"
                >
                  {t("実行")}
                </StyledButton>
              )}
              {props.submitButton && (
                <StyledButton
                  {...props.submitButton}
                  id="system-design-submit-button"
                  startIcon={<SendIcon />}
                  variant="contained"
                  color="secondary"
                >
                  {t("提出")}
                </StyledButton>
              )}
            </ThemeProvider>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

EditControlPanel.displayName = "EditControlPanel";

export default EditControlPanel;
