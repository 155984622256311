import { BOOL_VALUE, exampleMap, INITIAL_RECORDS_STRING } from "@hireroo/challenge/definition";
import { useTranslation } from "@hireroo/i18n";
import { ValidateDatabaseVariantInput } from "@hireroo/validator";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Close from "@mui/icons-material/Close";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import { styled, useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import * as React from "react";

import IconButton, { IconButtonProps } from "../../../primitive/Button/IconButton/IconButton";

//TODO: move to container side
export const generateDBPlaceholder = (t: string): string => {
  if (t === "string") return "hello";

  return exampleMap[t];
};

const safeJsonTestCaseInputsParse = (target: string): string[][] | undefined => {
  try {
    //TODO: @asaki15 refactor using zod
    return JSON.parse(target);
  } catch (e) {
    return undefined;
  }
};

const StyledTextField = styled(TextField)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: 'Menlo, Monaco, "Courier New", monospace',
  width: "100%",
  "& .MuiOutlinedInput-inputMarginDense": {
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  "& .MuiOutlinedInput-input": {
    padding: "5px 10px",
  },
  "& .MuiInputBase-input": {
    fontSize: "14px",
  },
  "& .MuiInputBase-root": {
    margin: "5px 0",
    height: "40px",
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.secondary.main,
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderColor: theme.palette.divider,
  wordBreak: "keep-all",
  whiteSpace: "nowrap",
}));

const BOOL_OPTIONS = [
  {
    value: 0,
    label: BOOL_VALUE.FALSE,
  },
  {
    value: 1,
    label: BOOL_VALUE.TRUE,
  },
];

type Row = string[];
type Rows = Row[];

const convertRowsToString = (array: Rows): string => {
  return `[${array.map(row => `["${row.join('","')}"]`).toString()}]`;
};

const generateEmptyRow = (length: number): string[][] => {
  return [Array<string>(length).fill("")];
};

const convertStringToRows = (value: string): string[][] | undefined => {
  if (value === "" || value === undefined) {
    return undefined;
  }
  const parsedValue = safeJsonTestCaseInputsParse(value);
  if (parsedValue?.length && parsedValue.length > 0) {
    return parsedValue;
  }
  return undefined;
};

export type ConsoleInput = {
  name: string;
  type: string;
};

export type ChallengeIDEConsoleInputTableProps = {
  tableName: string;
  tableColumns: ConsoleInput[];
  value: string;
  readonly?: boolean;
  errorMap?: Record<string, boolean>;
  onChange: (input: string) => void;
  handleErrorSet?: (key: string, hasError: boolean) => void;
};

const ChallengeIDEConsoleInputTable: React.FC<ChallengeIDEConsoleInputTableProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();

  const rows = React.useMemo(() => {
    return convertStringToRows(props.value) ?? [[]];
  }, [props.value]);
  const handleRemoveRow = (rowIndex: number) => {
    const tempRows = rows.filter((_, index) => index !== rowIndex);
    // If there is only one record and return initial string value
    if (rowIndex === 0 && tempRows.length === 1) {
      props.onChange(INITIAL_RECORDS_STRING);
      return;
    }
    props.onChange(convertRowsToString(tempRows));
  };

  const handleAddRow = () => {
    const tempRows = rows.concat(generateEmptyRow(props.tableColumns.length));
    props.onChange(convertRowsToString(tempRows));
  };

  //TODO: @asaki15 need to refactor (better to handle parse when saving)
  const handleRowChange = (row: number, col: number, inputValue: string, type: string) => {
    const tempRows = rows;
    tempRows[row][col] = inputValue;
    props.onChange(convertRowsToString(tempRows));

    if (props.handleErrorSet) {
      const isValid = ValidateDatabaseVariantInput.isDatabaseTestCaseValid(`${inputValue}`, type);
      props.handleErrorSet(`${props.tableName}-${String(row)}-${String(col)}`, !isValid);
    }
  };

  const closeButton: IconButtonProps = {
    children: <Close sx={{ fontSize: "1.1rem" }} />,
    size: "small",
  };

  const inputCommonTextField: TextFieldProps = {
    size: "small",
    variant: "outlined",
    InputProps: { readOnly: props.readonly },
  };

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            {props.tableColumns.map(column => (
              <StyledTableCell key={`${column.name}-header`}>{`${column.name} (${column.type})`}</StyledTableCell>
            ))}
            {!props.readonly && <StyledTableCell />}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {props.tableColumns.map((column: ConsoleInput, colIndex) => {
                const common: TextFieldProps = {
                  ...inputCommonTextField,
                  onChange: e => {
                    handleRowChange(rowIndex, colIndex, e.target.value, column.type);
                  },
                  color: "secondary",
                  placeholder: generateDBPlaceholder(column.type),
                  error: props.errorMap && Boolean(props.errorMap[`${props.tableName}-${rowIndex}-${colIndex}`]),
                  helperText:
                    props.errorMap &&
                    props.errorMap[`${props.tableName}-${rowIndex}-${colIndex}`] &&
                    t("型情報と異なる入力値が入力されています。"),
                };
                const menuItemSx: MenuItemProps["sx"] = {
                  "&.Mui-selected": {
                    backgroundColor: theme.palette["Secondary/Shades"].p30,
                  },
                  "&.Mui-selected:hover": {
                    backgroundColor: theme.palette["Secondary/Shades"].p30,
                  },
                  "&:hover": {
                    backgroundColor: theme.palette["Secondary/Shades"].p12,
                  },
                };
                const withChoice: TextFieldProps = {
                  ...common,
                  value: row[colIndex] ?? 0,
                  select: true,
                  children: BOOL_OPTIONS.map(option => (
                    <MenuItem key={`${column.name}-${option.value}`} sx={menuItemSx} color="secondary" value={option.value}>
                      {option.label}
                    </MenuItem>
                  )),
                };
                const textInput: TextFieldProps = {
                  ...common,
                  value: row[colIndex] ?? "",
                };

                return (
                  <TableCell key={column.name} style={{ padding: "1px 7px" }}>
                    <StyledTextField {...(column.type === "bool" ? withChoice : textInput)} />
                  </TableCell>
                );
              })}

              {!props.readonly && (
                <StyledTableCell padding="checkbox">
                  <IconButton {...closeButton} onClick={() => handleRemoveRow(rowIndex)} />
                </StyledTableCell>
              )}
            </TableRow>
          ))}
        </TableBody>

        {!props.readonly && (
          <TableFooter>
            <TableRow hover>
              <StyledTableCell sx={{ pb: 0, border: "none" }} align="left" colSpan={props.tableColumns.length} onClick={handleAddRow}>
                <AddCircleIcon fontSize="small" />
              </StyledTableCell>
              <StyledTableCell sx={{ border: "none" }} />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};

ChallengeIDEConsoleInputTable.displayName = "ChallengeIDEConsoleInputTable";

export default ChallengeIDEConsoleInputTable;
