import { PaymentForm } from "@hireroo/validator";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useController } from "react-hook-form";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.Other.Divider}`,
}));

const StyledTableCell = styled(TableCell)(() => ({
  padding: 12,
}));

const StyledListItemText = styled(ListItemText)(() => ({
  flex: "none",
  ".MuiListItemText-primary": {
    whiteSpace: "nowrap",
    wordBreak: "keep-all",
    textAlign: "right",
  },
  ".MuiListItemText-secondary": {
    whiteSpace: "nowrap",
    wordBreak: "keep-all",
    textAlign: "right",
  },
}));

const StyledStack = styled(Stack)(({ theme }) => ({
  borderRadius: 40,
  height: 40,
  width: 120,
  backgroundColor: theme.palette.Other.FilledInputBG,
  alignItems: "center",
}));

type FieldValue = PaymentForm.SelectionItem;

export type SelectionItemProps = {
  disabled?: boolean;
  name: `${string}.${number}`;
  label: string;
  priceLabel: string;
  unitPriceLabel: string;
  onChange?: () => void;
  totalPriceLabel: string;
};

const SelectionItem: React.FC<SelectionItemProps> = props => {
  const methods = useController<Record<string, FieldValue>>({
    name: props.name,
  });
  const fieldValue = methods.field.value;

  const minusButton: IconButtonProps = {
    disabled: props.disabled,
    children: <RemoveCircleIcon color={props.disabled || fieldValue.amount === 0 ? "disabled" : "secondary"} />,
    onClick: () => {
      const newValue: FieldValue = {
        ...fieldValue,
        amount: fieldValue.amount - 1,
      };
      if (newValue.amount >= 0) {
        methods.field.onChange(newValue);
        props.onChange?.();
      }
    },
  };
  const plusButton: IconButtonProps = {
    disabled: props.disabled,
    children: <AddCircleIcon color={props.disabled ? "disabled" : "secondary"} />,
    onClick: () => {
      const newValue: FieldValue = {
        ...fieldValue,
        amount: fieldValue.amount + 1,
      };
      methods.field.onChange(newValue);
      props.onChange?.();
    },
  };
  return (
    <StyledTableRow>
      <StyledTableCell>
        <Typography fontWeight="bold" fontSize={16}>
          {props.label}
        </Typography>
      </StyledTableCell>

      <StyledTableCell align="right">
        <StyledListItemText primary={props.priceLabel} secondary={props.unitPriceLabel} primaryTypographyProps={{ fontWeight: "bold" }} />
      </StyledTableCell>

      <StyledTableCell align="center">
        <StyledStack direction="row" spacing={2} mx={2}>
          <IconButton {...minusButton} />
          <Typography>{String(fieldValue.amount)}</Typography>
          <IconButton {...plusButton} />
        </StyledStack>
      </StyledTableCell>

      <StyledTableCell align="right">
        <StyledListItemText primary={props.totalPriceLabel} primaryTypographyProps={{ fontWeight: "bold" }} />
      </StyledTableCell>
    </StyledTableRow>
  );
};

SelectionItem.displayName = "SelectionItem";

export default SelectionItem;
