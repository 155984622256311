import { App, Company } from "@hireroo/app-store/essential/employee";
import { GroupSettings } from "@hireroo/app-store/widget/e/GroupSettings";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import EmployeeGroupSettingsContainer, { EmployeeGroupSettingsContainerProps } from "./Container";

export type EmployeeGroupSettingsFetchContainerProps = {};

const EmployeeGroupSettingsFetchContainer: React.FC<EmployeeGroupSettingsFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const companyId = Company.useStrictActiveCompanyId();
  const [result, refresh] = Graphql.useGetCompanyForGroupSettingsQuery({
    variables: {
      companyId: companyId,
    },
    requestPolicy: "network-only",
    pause: appStatus !== "INITIALIZED",
  });
  const groupSettingsStateIsInitialized = GroupSettings.useInitialized();
  React.useEffect(() => {
    if (result.data) {
      GroupSettings.setCompany(result.data.company);
    }
  }, [result.data]);

  React.useEffect(() => {
    return () => {
      GroupSettings.clear();
    };
  }, []);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !groupSettingsStateIsInitialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: EmployeeGroupSettingsContainerProps = {
    reload: refresh,
  };

  return <EmployeeGroupSettingsContainer {...containerProps} />;
};

EmployeeGroupSettingsFetchContainer.displayName = "EmployeeGroupSettingsFetchContainer";

export default withErrorBoundary(EmployeeGroupSettingsFetchContainer, {});
