import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";

import SearchResultHeader, { SearchResultHeaderProps } from "../SearchResultHeader/SearchResultHeader";

export type QuestionSearchHeaderProps = {
  SearchArea: React.ReactNode;
  searchResultHeader: SearchResultHeaderProps;
};

const QuestionSearchHeader: React.FC<QuestionSearchHeaderProps> = props => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box p={1.5}>
        <Typography mb={2} fontWeight={700} fontSize={16}>
          {t("すべての問題")}
        </Typography>
        <Box>{props.SearchArea}</Box>
      </Box>
      <SearchResultHeader {...props.searchResultHeader} />
    </Box>
  );
};

QuestionSearchHeader.displayName = "QuestionSearchHeader";

export default QuestionSearchHeader;
