import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import * as React from "react";

export type AppBarProps = MuiAppBarProps & {
  open: boolean;
};

const drawerOpenWidth = 248;
const drawerCloseWidth = 48;

const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  backgroundColor: theme.palette["Background/Paper"].p2,
  zIndex: theme.zIndex.drawer + 1,
  ...(open && {
    marginLeft: drawerOpenWidth,
    width: `calc(100% - ${drawerOpenWidth}px)`,
  }),
  ...(!open && {
    marginLeft: drawerCloseWidth,
    width: `calc(100% - ${drawerCloseWidth}px)`,
  }),
  borderLeft: "none",
  borderRight: "none",
}));

const AppBar: React.FC<AppBarProps> = props => {
  return <StyledAppBar {...props} variant="outlined" elevation={0} />;
};

AppBar.displayName = "AppBar";

export default AppBar;
