import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";

import AddToSlackButton from "../AddToSlackButton/AddToSlackButton";

export type SlackIntegrateButtonProps = {
  showAddSlackButton: boolean;
  slackOauthLink: string;
};

const SlackIntegrateButton: React.FC<SlackIntegrateButtonProps> = props => {
  const { t } = useTranslation();
  return (
    <Box display="flex" flexDirection="row">
      <Box flexGrow={1} display="flex" flexDirection="column">
        <Typography color="textSecondary" fontSize={14}>
          {t("Slackと連携することで、リアルタイムにテストの通知を受け取ることができます。")}
        </Typography>
        <Typography color="textSecondary" fontSize={14}>
          {t("連携に必要な権限は `incoming-webhook` です。")}
        </Typography>
      </Box>
      <Box>
        {/* only admin can manage the slack settings */}
        {props.showAddSlackButton ? (
          <AddToSlackButton slackOauthLink={props.slackOauthLink} />
        ) : (
          <Typography>{t("Slackの連携には管理者以上の権限が必要です。")}</Typography>
        )}
      </Box>
    </Box>
  );
};

SlackIntegrateButton.displayName = "SlackIntegrateButton";

export default SlackIntegrateButton;
