import { INITIAL_VERSION } from "@hireroo/app-definition/question";
import { App } from "@hireroo/app-store/essential/employee";
import { FreepadResourceEditor } from "@hireroo/app-store/view-domain/FreepadResourceEditor";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation/legacy";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

import ErrorPanelContainer from "../../../widget/e/ErrorPanel/Container";
import FreepadResourceEditorMiddleContainer, { FreepadResourceEditorMiddleContainerProps } from "./MiddleContainer";

export type FreepadResourceEditorFetchContainerProps = {};

const FreepadResourceEditorFetchContainer: React.FC<FreepadResourceEditorFetchContainerProps> = _props => {
  const params = useParams<{ id: string }>();
  const [query] = useSearchParams();
  const appStatus = App.useStatus();
  const { t } = useTranslation();
  const [result] = Graphql.useGetQuestionForFreepadResourceEditorQuery({
    variables: {
      questionId: Number(params.id),
      questionVersion: query.get("version") || INITIAL_VERSION,
    },
    pause: appStatus !== "INITIALIZED",
  });
  React.useEffect(() => {
    if (result.data?.freepadQuestion) {
      FreepadResourceEditor.initializeQuestion(result.data.freepadQuestion);
    }
  }, [result.data]);

  if (result.error) {
    return <ErrorPanelContainer graphqlErrors={result.error.graphQLErrors} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED") {
    return <Widget.Loading kind="CENTER" />;
  }
  if (result.data?.freepadQuestion.status === "ARCHIVED") {
    return <ErrorPanelContainer title={t("この問題はアーカイブされています。")} message={t("アーカイブした問題は編集できません。")} />;
  }

  const containerProps: FreepadResourceEditorMiddleContainerProps = {};
  return (
    <ErrorBoundary>
      <FreepadResourceEditorMiddleContainer {...containerProps} />
    </ErrorBoundary>
  );
};

FreepadResourceEditorFetchContainer.displayName = "FreepadResourceEditorFetchContainer";

export default withErrorBoundary(FreepadResourceEditorFetchContainer, {});
