import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Auth, Company } from "@hireroo/app-store/essential/employee";
import { EvaluationMetricsGroups } from "@hireroo/app-store/widget/e/EvaluationMetricsGroups";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import * as Time from "@hireroo/formatter/time";
import {
  AddEvaluationMetricsToGroupMutation,
  DeleteEvaluationMetricsFromGroupMutation,
  UpdateEvaluationMetricGroupMutation,
} from "@hireroo/graphql/client/graphql-request";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateHelpCenterUrl } from "@hireroo/router/api";
import { useHelpCenterNavigate, useTransitionNavigate } from "@hireroo/router/hooks";
import { EvaluationMetricsForm } from "@hireroo/validator";
import * as Sentry from "@sentry/react";
import * as React from "react";

import EvaluationMetricSearchFieldContainer from "./widget/EvaluationMetricSearchField/Container";

type Item = Widget.EvaluationMetricsGroupsProps["table"]["items"][number];

export type GenerateEvaluationMetricsGroupsPropsArgs = {
  refresh: () => void;
};

export const useGenerateProps = (args: GenerateEvaluationMetricsGroupsPropsArgs): Widget.EvaluationMetricsGroupsProps => {
  const { refresh } = args;
  const navigate = useTransitionNavigate();
  const companyId = Company.useStrictActiveCompanyId();
  const { t } = useTranslation();
  const metricsGroups = EvaluationMetricsGroups.useMetricsGroups();
  const dialogStatus = EvaluationMetricsGroups.useDialogStatus();
  const currentUser = Auth.useCurrentUser();
  const client = getGraphqlClient();
  const selectedMetricGroupIds = EvaluationMetricsGroups.useSelectedMetricGroupIds();
  const selectedMetricGroups = EvaluationMetricsGroups.useSelectedMetricGroups();
  const currentUid = Auth.useCurrentUid();
  const lang = useLanguageCode();
  const helpCenterNavigate = useHelpCenterNavigate(lang);
  const pagination = EvaluationMetricsGroups.usePagination();
  const [loading, setLoading] = React.useState(false);

  const items = React.useMemo((): Item[] => {
    return metricsGroups.map((metricGroup): Item => {
      return {
        id: metricGroup.metricGroupId.toString(),
        checkbox: {
          checked: selectedMetricGroupIds.includes(metricGroup.metricGroupId),
          onChange: (_, checked) => {
            if (checked) {
              EvaluationMetricsGroups.addSelectedItemId(metricGroup.metricGroupId);
            } else {
              EvaluationMetricsGroups.removeSelectedItemId(metricGroup.metricGroupId);
            }
          },
        },
        title: metricGroup.title,
        description: metricGroup.description,
        updatedAt: Time.datetimeFormat(new Date(metricGroup.updatedAtSeconds * 1000)),
        editButton: {
          onClick: () => {
            EvaluationMetricsGroups.updateDialogStatus(`EDIT_OPEN_${metricGroup.metricGroupId}`);
          },
        },
        editDialog: {
          dialog: {
            open: dialogStatus === `EDIT_OPEN_${metricGroup.metricGroupId}`,
            yesButton: {
              disabled: loading,
            },
            noButton: {
              onClick: () => {
                EvaluationMetricsGroups.updateDialogStatus("CLOSE");
              },
              disabled: loading,
            },
            onClose: () => {
              EvaluationMetricsGroups.updateDialogStatus("CLOSE");
            },
          },
          onSubmit: async field => {
            setLoading(true);
            //TODO: change to single mutation when backend is ready
            const changedMetricIds = field.items.map(m => Number(m.itemId));
            const oldMetricIds = metricGroup.metrics.map(m => m.metricId);
            const nextMetricIds = changedMetricIds.filter(id => !oldMetricIds.includes(id));
            const deleteMetricIds = oldMetricIds.filter(id => !changedMetricIds.includes(id));
            const requests: Promise<
              UpdateEvaluationMetricGroupMutation | AddEvaluationMetricsToGroupMutation | DeleteEvaluationMetricsFromGroupMutation
            >[] = [
              client.UpdateEvaluationMetricGroup({
                input: {
                  metricGroupId: metricGroup.metricGroupId,
                  title: field.title,
                  description: field.description ?? "",
                },
              }),
            ];
            if (nextMetricIds.length > 0) {
              requests.push(
                client.AddEvaluationMetricsToGroup({
                  input: {
                    metricGroupId: metricGroup.metricGroupId,
                    metricIds: nextMetricIds,
                  },
                }),
              );
            }
            if (deleteMetricIds.length > 0) {
              requests.push(
                client.DeleteEvaluationMetricsFromGroup({
                  input: {
                    metricGroupId: metricGroup.metricGroupId,
                    metricIds: deleteMetricIds,
                  },
                }),
              );
            }
            await Promise.all(requests)
              .then(() => {
                client
                  .GetEvaluationMetricGroupForEvaluationMetricsGroups({
                    metricGroupId: metricGroup.metricGroupId,
                  })
                  .then(res => {
                    EvaluationMetricsGroups.updateMetricGroup(res.evaluationMetricGroup);
                    EvaluationMetricsGroups.updateDialogStatus("CLOSE");
                    Snackbar.notify({
                      severity: "success",
                      message: t("評価指標の更新に成功しました。"),
                    });
                    refresh();
                  })
                  .catch(error => {
                    Sentry.captureException(error);
                    const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("評価指標の更新に失敗しました。"));
                    Snackbar.notify({
                      severity: "error",
                      message: errorNotification.message,
                    });
                  });
              })
              .catch(error => {
                Sentry.captureException(error);
                const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("評価指標の更新に失敗しました。"));
                Snackbar.notify({
                  severity: "error",
                  message: errorNotification.message,
                });
              })
              .finally(() => {
                setLoading(false);
              });
          },
          kind: "EDIT",
          SearchField: <EvaluationMetricSearchFieldContainer />,
          defaultValues: {
            title: metricGroup.title,
            description: metricGroup.description,
            items: metricGroup.metrics.map(
              (metric): EvaluationMetricsForm.MetricItemSchema => ({
                itemId: metric.metricId.toString(),
                name: metric.title,
              }),
            ),
          },
          creator: {
            displayName: metricGroup.employee.displayName || metricGroup.employee.email,
            src: metricGroup.employee.photoUrl,
          },
        },
        items: metricGroup.metrics.map(metric => metric.title),
        deleteButton: {
          onClick: () => {
            EvaluationMetricsGroups.updateDialogStatus(`DELETE_OPEN_${metricGroup.metricGroupId}`);
          },
        },
        deleteDialog: {
          dialog: {
            open: dialogStatus === `DELETE_OPEN_${metricGroup.metricGroupId}`,
            yesButton: {
              disabled: loading,
            },
            noButton: {
              onClick: () => {
                EvaluationMetricsGroups.updateDialogStatus("CLOSE");
              },
              disabled: loading,
            },
            onClose: () => {
              EvaluationMetricsGroups.updateDialogStatus("CLOSE");
            },
            title: t("評価指標の削除"),
          },
          onSubmit: async () => {
            setLoading(true);
            await client
              .DeleteEvaluationMetricGroup({
                input: {
                  metricGroupId: metricGroup.metricGroupId,
                },
              })
              .then(() => {
                EvaluationMetricsGroups.updateDialogStatus("CLOSE");
                Snackbar.notify({
                  severity: "success",
                  message: t("評価指標の削除に成功しました。"),
                });
                EvaluationMetricsGroups.removeMetricGroup(metricGroup.metricGroupId);
                EvaluationMetricsGroups.clearPage();
                refresh();
              })
              .catch(error => {
                Sentry.captureException(error);
                const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("評価指標の削除に失敗しました。"));
                Snackbar.notify({
                  severity: "error",
                  message: errorNotification.message,
                });
              })
              .finally(() => {
                setLoading(false);
              });
          },
          name: metricGroup.title,
          description: `${t("一度削除すると元に戻すことはできません。")}${t(
            "この操作によって対象の評価指標を使った技術レビューが削除されることはありません。",
          )}`,
        },
      };
    });
  }, [client, dialogStatus, loading, metricsGroups, refresh, selectedMetricGroupIds, t]);

  return {
    header: {
      checkbox: {
        indeterminate: selectedMetricGroupIds.length > 0,
        onChange: (_, checked) => {
          if (checked && selectedMetricGroupIds.length === 0) {
            metricsGroups.forEach(metricGroup => EvaluationMetricsGroups.addSelectedItemId(metricGroup.metricGroupId));
          } else {
            EvaluationMetricsGroups.clearSelectedItemIds();
          }
        },
        checked: selectedMetricGroupIds.length === metricsGroups.length,
      },
      createButton: {
        onClick: () => {
          EvaluationMetricsGroups.updateDialogStatus("CREATE_OPEN");
        },
      },
      helpLink: {
        onClick: () => {
          helpCenterNavigate("EVALUATION_METRICS", { _blank: true });
        },
        href: generateHelpCenterUrl(lang, "EVALUATION_METRICS"),
      },
      deleteButton: {
        onClick: () => {
          EvaluationMetricsGroups.updateDialogStatus("DELETES_OPEN");
        },
        disabled: selectedMetricGroups.length === 0,
      },
    },
    metricsButton: {
      onClick: () => {
        navigate("/e/evaluations/metrics");
      },
    },
    table: {
      items: items,
      pagination: {
        count: pagination.count,
        rowsPerPage: pagination.size,
        page: pagination.page,
        onPageChange: (_, page) => {
          EvaluationMetricsGroups.updatePage(page);
        },
        onRowsPerPageChange: event => {
          EvaluationMetricsGroups.updateSize(Number(event.target.value));
        },
      },
    },
    createDialog: {
      dialog: {
        open: dialogStatus === "CREATE_OPEN",
        yesButton: {
          disabled: loading,
        },
        noButton: {
          onClick: () => {
            EvaluationMetricsGroups.updateDialogStatus("CLOSE");
          },
        },
        onClose: () => {
          EvaluationMetricsGroups.updateDialogStatus("CLOSE");
        },
      },
      onSubmit: async field => {
        setLoading(true);
        await client
          .CreateEvaluationMetricGroupForEvaluationMetricsGroups({
            input: {
              companyId: companyId,
              employeeId: currentUid,
              title: field.title,
              description: field.description ?? "",
              metricIds: field.items.map(m => Number(m.itemId)),
            },
          })
          .then(res => {
            if (res.createEvaluationMetricGroup) {
              Snackbar.notify({
                severity: "success",
                message: t("評価指標の作成に成功しました。"),
              });
              EvaluationMetricsGroups.updateMetricGroup(res.createEvaluationMetricGroup);
              EvaluationMetricsGroups.updateDialogStatus("CLOSE");
              refresh();
            }
          })
          .catch(error => {
            Sentry.captureException(error);
            const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("評価指標の作成に失敗しました。"));
            Snackbar.notify({
              severity: "error",
              message: errorNotification.message,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      },
      SearchField: <EvaluationMetricSearchFieldContainer />,
      kind: "CREATE",
      defaultValues: {
        title: "",
        description: "",
        items: [],
      },
      creator: {
        displayName: currentUser.displayName || currentUser.email,
        src: currentUser.photoUrl,
      },
    },
    deletesDialog: {
      dialog: {
        open: dialogStatus === "DELETES_OPEN",
        yesButton: {
          disabled: loading,
        },
        noButton: {
          onClick: () => {
            EvaluationMetricsGroups.updateDialogStatus("CLOSE");
          },
          disabled: loading,
        },
        onClose: () => {
          EvaluationMetricsGroups.updateDialogStatus("CLOSE");
        },
        title: t("評価指標の削除"),
      },
      onSubmit: async () => {
        setLoading(true);
        const requests = selectedMetricGroups.map(metricGroup => {
          return client.DeleteEvaluationMetricGroup({
            input: {
              metricGroupId: metricGroup.metricGroupId,
            },
          });
        });
        await Promise.all(requests)
          .then(() => {
            EvaluationMetricsGroups.updateDialogStatus("CLOSE");
            Snackbar.notify({
              severity: "success",
              message: t("評価指標の削除に成功しました。"),
            });
            EvaluationMetricsGroups.removeMetricGroups(selectedMetricGroups.map(metricGroup => metricGroup.metricGroupId));
            EvaluationMetricsGroups.clearSelectedItemIds();
            EvaluationMetricsGroups.clearPage();
            refresh();
          })
          .catch(error => {
            Sentry.captureException(error);
            const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("評価指標の削除に失敗しました。"));
            Snackbar.notify({
              severity: "error",
              message: errorNotification.message,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      },
      items: selectedMetricGroups.map(metricGroup => ({
        id: metricGroup.metricGroupId.toString(),
        name: metricGroup.title,
        description: metricGroup.description,
      })),
      description: t("選択した評価指標を削除します。一度削除すると元に戻すことはできません。"),
    },
  };
};
