import { translationLanguageMap } from "@hireroo/app-definition";
import { INITIAL_VERSION } from "@hireroo/app-definition/question";
import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { createAnswersInput, createInitialCodes, createInitialFlowCharts } from "@hireroo/app-helper/freepad";
import { Auth, Company } from "@hireroo/app-store/essential/employee";
import { FreepadResourceEditor } from "@hireroo/app-store/view-domain/FreepadResourceEditor";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { SupportLanguageValue, useTranslation } from "@hireroo/i18n";
import { Pages, Widget } from "@hireroo/presentation/legacy";
import { updateQueryParams } from "@hireroo/router/api";
import { AlgorithmSignatureForm, FreepadQuestionForm } from "@hireroo/validator";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { SubmitHandler, useFieldArray } from "react-hook-form";

type UseGenerateQuestionDetailProps = {
  companyId: number;
};

type QuestionDetails = {
  props: Pages.FreepadResourceEditorProps["questionDetails"];
  saveButton: Pages.FreepadResourceEditorProps["saveQuestionDetailsButton"];
};

export const useGenerateQuestionDetailsProps = (args: UseGenerateQuestionDetailProps): QuestionDetails => {
  const { t } = useTranslation();
  const isHireroo = Company.useIsHireroo();
  const client = getGraphqlClient();

  const userId = Auth.useCurrentUid();
  const { methods } = Widget.useFreepadQuestionDetailFormContext();
  const [currentTabValue, setCurrentTabValue] = React.useState<string>("0");
  const [isTranslateLoading, setIsTranslateLoading] = React.useState<boolean>(false);
  const question = FreepadResourceEditor.useQuestion();
  const saveStatus = FreepadResourceEditor.useSaveStatus();

  const fetchTemplateCode = React.useCallback(() => {
    const signature = {
      function: "solution",
      inputs: [{ name: "s", type: "string" }],
      outputs: [{ type: "string" }],
    } satisfies AlgorithmSignatureForm.AlgorithmSignatureSchemaType;
    client
      .GetAlgorithmTemplateCodeForFreepadResourceEditor({
        signature: JSON.stringify(signature),
        variant: "ALGORITHM",
      })
      .then(res => {
        FreepadResourceEditor.updateTemplateCode(res.algorithmTemplateCode);
      })
      .catch(error => {
        Sentry.captureException(error);
      });
  }, [client]);

  const handleQuestionDetailsSubmit: SubmitHandler<FreepadQuestionForm.FreepadQuestionFormSchema> = React.useCallback(
    fields => {
      const content = {
        titleJa: "",
        descriptionJa: "",
        titleEn: "",
        descriptionEn: "",
      } satisfies Record<string, string>;
      fields.questions.forEach(q => {
        if (q.language === "ja") {
          content.descriptionJa = q.description;
          content.titleJa = q.title;
        }
        if (q.language === "en") {
          content.descriptionEn = q.description;
          content.titleEn = q.title;
        }
      });
      if (question) {
        const now = Date.now();
        const published = question.status === "PUBLISHED";
        client
          .UpdateQuestionForFreepadResourceEditor({
            input: {
              id: question.freepadQuestionId,
              oldVersion: question.version,
              newVersion: published ? `${question.version}-${now}` : question.version,
              companyId: args.companyId,
              employeeId: userId,
              variant: fields.variant,
              difficulty: fields.difficulty,
              isPrivate: !fields.isPublic,
              status: "DRAFT",
              titleJa: content.titleJa,
              titleEn: content.titleEn,
              descriptionJa: content.descriptionJa,
              descriptionEn: content.descriptionEn,
              answers: createAnswersInput(question.answers),
              initialCodes: createInitialCodes(question.initialCodes),
              initialFlowCharts: createInitialFlowCharts(question.initialFlowCharts),
              tags: question.tags.map(t => t.name),
            },
          })
          .then(res => {
            FreepadResourceEditor.updateQuestion(res.updateFreepadQuestion);
            Snackbar.notify({
              severity: "success",
              message: t("問題の一時保存が完了しました。"),
            });
            // Exclude Database since there is noway to create database variant
            if (
              res.updateFreepadQuestion.variant !== "UNKNOWN" &&
              res.updateFreepadQuestion.variant !== "DATABASE" &&
              res.updateFreepadQuestion.variant !== "CLASS"
            ) {
              FreepadResourceEditor.updateVariant(res.updateFreepadQuestion.variant);
            }

            if (published) {
              updateQueryParams("version", [res.updateFreepadQuestion.version]);
            }
            if (res.updateFreepadQuestion.variant === "ALGORITHM") {
              fetchTemplateCode();
            }
            FreepadResourceEditor.updateActiveStep(FreepadResourceEditor.STEPS.ANSWERS);
          })
          .catch(error => {
            Sentry.captureException(error);
            const errorNotification = ErrorHandlingHelper.generateErrorNotification(
              error,
              t("問題の一時保存に失敗しました。しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
            );
            Snackbar.notify({
              severity: "error",
              message: errorNotification.message,
            });
          })
          .finally(() => {
            FreepadResourceEditor.updateSaveStatus("READY");
          });
      } else {
        client
          .CreateQuestionForFreepadResourceEditor({
            input: {
              version: INITIAL_VERSION,
              companyId: args.companyId,
              employeeId: userId,
              variant: fields.variant,
              difficulty: fields.difficulty,
              isPrivate: !fields.isPublic,
              status: "DRAFT",
              titleJa: content.titleJa,
              titleEn: content.titleEn,
              descriptionJa: content.descriptionJa,
              descriptionEn: content.descriptionEn,
              answers: [],
              initialCodes: [],
              initialFlowCharts: [],
              tags: [],
            },
          })
          .then(res => {
            FreepadResourceEditor.updateQuestion(res.createFreepadQuestion);
            // Exclude Database since there is noway to create database variant
            if (
              res.createFreepadQuestion.variant !== "UNKNOWN" &&
              res.createFreepadQuestion.variant !== "DATABASE" &&
              res.createFreepadQuestion.variant !== "CLASS"
            ) {
              FreepadResourceEditor.updateVariant(res.createFreepadQuestion.variant);
            }
            Snackbar.notify({
              severity: "success",
              message: t("問題の一時保存が完了しました。"),
            });
            updateQueryParams("id", [res.createFreepadQuestion.freepadQuestionId.toString()]);
            FreepadResourceEditor.updateActiveStep(FreepadResourceEditor.STEPS.ANSWERS);

            if (res.createFreepadQuestion.variant === "ALGORITHM") {
              fetchTemplateCode();
            }
          })
          .catch(error => {
            Sentry.captureException(error);
            const errorNotification = ErrorHandlingHelper.generateErrorNotification(
              error,
              t("問題の一時保存に失敗しました。しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
            );
            Snackbar.notify({
              severity: "error",
              message: errorNotification.message,
            });
          })
          .finally(() => {
            FreepadResourceEditor.updateSaveStatus("READY");
          });
      }
    },
    [args.companyId, client, fetchTemplateCode, question, t, userId],
  );

  const questionsField = useFieldArray({
    control: methods.control,
    name: "questions",
  });

  const translateQuestion = React.useCallback(
    async (
      question: FreepadQuestionForm.FreepadQuestionDetailSchema,
      to: SupportLanguageValue,
    ): Promise<FreepadQuestionForm.FreepadQuestionDetailSchema> => {
      const res = await Promise.all([
        client.TranslatePlainTextForFreepadResourceEditor({
          source: {
            from: translationLanguageMap[question.language],
            to: translationLanguageMap[to],
            body: question.title,
          },
        }),
        client.TranslateMarkdownForFreepadResourceEditor({
          source: {
            from: translationLanguageMap[question.language],
            to: translationLanguageMap[to],
            body: question.description,
          },
        }),
      ])
        .then(res => {
          Snackbar.notify({
            severity: "success",
            message: t("自動翻訳に成功しました。"),
          });
          return res;
        })
        .catch(error => {
          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(
            error,
            t("自動翻訳に失敗しました。しばらくしてから再度お試しください。"),
          );
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
          return [];
        });
      return {
        title: res[0]?.translatePlainText ?? "",
        description: res[1]?.translateMarkdown ?? "",
        language: to,
      };
    },
    [client, t],
  );

  const questions = methods.watch("questions");

  const selectedLanguagesSet = React.useMemo(() => {
    if (questions) {
      return new Set(questions.map(q => q.language));
    }
    return new Set();
  }, [questions]);

  return {
    props: {
      generalSettingFields: {
        canCreatePublic: isHireroo,
        isPublicSwitch: {
          disabled: question && !question.isPrivate && (question.version !== INITIAL_VERSION || question.status === "PUBLISHED"),
        },
        variantSelect: {
          disabled: Boolean(question),
        },
      },
      questionTextFields: {
        languageTab: {
          onTabsChange: value => {
            setCurrentTabValue(value);
          },
          menu: {
            items: [
              {
                text: "日本語",
                value: "ja",
                onClick: async () => {
                  setIsTranslateLoading(true);
                  const question = methods.getValues(`questions.${Number(currentTabValue)}`);
                  const translated = await translateQuestion(question, "ja");
                  questionsField.append(translated);
                  setIsTranslateLoading(false);
                },
                isLoading: isTranslateLoading,
              },
              {
                text: "English",
                value: "en",
                onClick: async () => {
                  setIsTranslateLoading(true);
                  const question = methods.getValues(`questions.${Number(currentTabValue)}`);
                  const translated = await translateQuestion(question, "en");
                  questionsField.append(translated);
                  setIsTranslateLoading(false);
                },
                isLoading: isTranslateLoading,
              },
            ].filter(item => !selectedLanguagesSet.has(item.value as SupportLanguageValue)),
          },
        },
      },
    },
    saveButton: {
      onClick: () => {
        FreepadResourceEditor.updateSaveStatus("LOADING");
        methods.handleSubmit(handleQuestionDetailsSubmit, () => FreepadResourceEditor.updateSaveStatus("READY"))();
      },
      loading: saveStatus === "LOADING",
    },
  };
};
