import { useTranslation } from "@hireroo/i18n";
import { EvaluationMetricsForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import ResourceField from "../../../../modules/ResourceField/ResourceField";
import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";

const StyledChip = styled(Chip)(({ theme }) => ({
  height: 30,
  ".MuiChip-deleteIcon": {
    color: theme.palette.secondary.main,
  },
  color: theme.palette.secondary.main,
  borderColor: theme.palette.secondary.main,
}));

export type AddMetricsToGroupsProps = {
  dialog: Pick<DialogWithCloseIconProps, "open" | "onClose" | "yesButton" | "noButton">;
  selectedItems: {
    id: string;
    name: string;
  }[];
  SearchGroups: React.ReactNode;
  onSubmit: SubmitHandler<EvaluationMetricsForm.EvaluationMetricsSearchFormSchema>;
};

const AddMetricsToGroups: React.FC<AddMetricsToGroupsProps> = props => {
  const { t } = useTranslation();
  const validator = EvaluationMetricsForm.useEvaluationMetricsSearchFormSchema();
  const methods = useForm<EvaluationMetricsForm.EvaluationMetricsSearchFormSchema>({
    resolver: zodResolver(validator),
    defaultValues: {
      items: [],
    },
  });
  const dialog: DialogWithCloseIconProps = {
    ...props.dialog,
    title: t("評価項目を追加"),
    noButton: {
      ...props.dialog.noButton,
      color: "outline-only",
      variant: "outlined",
      children: t("キャンセル"),
      onClick: event => {
        props.dialog.noButton?.onClick?.(event);
        methods.clearErrors();
      },
    },
    onClose: () => {
      props.dialog.onClose?.();
      methods.clearErrors();
    },
    yesButton: {
      ...props.dialog.yesButton,
      color: "secondary",
      children: t("追加"),
      onClick: () => {
        methods.handleSubmit(props.onSubmit)();
        props.dialog.yesButton?.onClick?.();
      },
    },
    disableEnter: true,
  };

  return (
    <DialogWithCloseIcon {...dialog}>
      <Typography color="textSecondary" fontSize={14} mb={1}>
        {t(
          "評価項目を評価指標に追加します。評価指標は複数の評価項目を持つことができます。更新後、新しく評価をするレポートからこの評価指標が利用できます。",
        )}
      </Typography>
      <Stack direction="column" spacing={1.5} width="100%" mb={1.5}>
        <Typography fontSize={14} fontWeight={700}>
          {t("対象の評価項目")}
        </Typography>
        <Stack direction="row" width="100%" gap={1} flexWrap="wrap">
          {props.selectedItems.map(item => {
            return (
              <Tooltip key={item.id} title={item.name}>
                <StyledChip key={item.id} variant="outlined" label={item.name} />
              </Tooltip>
            );
          })}
        </Stack>
      </Stack>
      <ResourceField label={t("追加する評価指標")} kind="REQUIRED">
        <FormProvider {...methods}>{props.SearchGroups}</FormProvider>
      </ResourceField>
    </DialogWithCloseIcon>
  );
};

AddMetricsToGroups.displayName = "AddMetricsToGroups";

export default AddMetricsToGroups;
