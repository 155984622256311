import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type BillingUsageContainerProps = {};

const BillingUsageContainer: React.FC<BillingUsageContainerProps> = () => {
  const companySettingsProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Pages.CompanySettings {...companySettingsProps} />
    </ErrorBoundary>
  );
};

BillingUsageContainer.displayName = "BillingUsageContainer";

export default withErrorBoundary(BillingUsageContainer, {});
