import { QuestionSelectFieldForResourceEditor } from "@hireroo/app-store/widget/e/QuestionSelectFieldForResourceEditor";
import * as Graphql from "@hireroo/graphql/client/urql";
import { ScreeningTestResourceEditorForm } from "@hireroo/validator";

type EntitySourceQuestion = Graphql.AssessmentEntitySourceQuestionForAssessmentHistorySelectorFragment;
type EntityTrack = ScreeningTestResourceEditorForm.CreateScreeningTestFormSchema["entityTracks"][0];

export const generateQuestionsFromEntitySourceQuestions = (
  entitySourceQuestions: EntitySourceQuestion[],
): QuestionSelectFieldForResourceEditor.SpotQuestion[] => {
  return entitySourceQuestions.map((entitySourceQuestion): QuestionSelectFieldForResourceEditor.SpotQuestion => {
    switch (entitySourceQuestion.__typename) {
      case "AssessmentChallengeEntitySourceQuestion":
      case "AssessmentProjectEntitySourceQuestion":
      case "AssessmentSystemDesignEntitySourceQuestion":
        return entitySourceQuestion.question;
      case "AssessmentQuizEntitySourcePackage":
        return entitySourceQuestion.pb_package;
      default:
        throw new Error(`entitySourceQuestion is invalid: ${entitySourceQuestion satisfies never}`);
    }
  });
};

export const generateEntityTracksFromEntities = (
  entitySourceQuestions: EntitySourceQuestion[],
  isAvailableQuestion: (variant: Graphql.QuestionVariant) => boolean,
): EntityTrack[] => {
  return entitySourceQuestions.map((entitySourceQuestion): EntityTrack => {
    switch (entitySourceQuestion.__typename) {
      case "AssessmentChallengeEntitySourceQuestion": {
        const entityTrack: EntityTrack = {
          type: "FIXED",
          questionScoreWeight: 1,
          entitySource: {
            type: "CHALLENGE",
            uniqueKey: entitySourceQuestion.question.key,
            questionId: entitySourceQuestion.question.questionId,
            questionVersion: entitySourceQuestion.question.version,
            enabledLanguages: entitySourceQuestion.enabledLanguages.slice(),
            isSelectable: isAvailableQuestion("CHALLENGE"),
          },
        };
        return entityTrack;
      }
      case "AssessmentQuizEntitySourcePackage": {
        const entityTrack: EntityTrack = {
          type: "FIXED",
          questionScoreWeight: 1,
          entitySource: {
            type: "QUIZ",
            uniqueKey: entitySourceQuestion.pb_package.key,
            packageId: entitySourceQuestion.pb_package.packageId,
            packageVersion: entitySourceQuestion.pb_package.version,
            isSelectable: isAvailableQuestion("QUIZ"),
          },
        };
        return entityTrack;
      }
      case "AssessmentProjectEntitySourceQuestion": {
        const entityTrack: EntityTrack = {
          type: "FIXED",
          questionScoreWeight: 1,
          entitySource: {
            type: "PROJECT",
            uniqueKey: entitySourceQuestion.question.key,
            questionId: entitySourceQuestion.question.questionId,
            questionVersion: entitySourceQuestion.question.version,
            isSelectable: isAvailableQuestion("PROJECT"),
          },
        };
        return entityTrack;
      }
      case "AssessmentSystemDesignEntitySourceQuestion": {
        const entityTrack: EntityTrack = {
          type: "FIXED",
          questionScoreWeight: 1,
          entitySource: {
            type: "SYSTEM_DESIGN",
            uniqueKey: entitySourceQuestion.question.key,
            questionId: entitySourceQuestion.question.questionId,
            componentTypes: entitySourceQuestion.componentTypes,
            isSelectable: isAvailableQuestion("SYSTEM_DESIGN"),
          },
        };
        return entityTrack;
      }
      default:
        throw new Error(`EntitySource is invalid: ${entitySourceQuestion satisfies never}`);
    }
  });
};
