import { useTranslation } from "@hireroo/i18n";
import * as z from "zod";

import * as AssignField from "./fields/AssignField";

export const useAccessPermissionFormSchema = () => {
  const { t } = useTranslation();

  const viewers = AssignField.useAssignListItem();

  const schema = z.object({
    viewers: viewers.array(),
    isPublic: z.boolean(),
  });

  return schema.superRefine((obj, ctx) => {
    if (obj.isPublic === false && obj.viewers.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_small,
        path: ["viewers"],
        minimum: 1,
        type: "number",
        inclusive: true,
        message: t("閲覧できるユーザーまたはグループを1つ以上選択してください。"),
      });
    }
  });
};

export type AccessPermissionSchema = z.infer<ReturnType<typeof useAccessPermissionFormSchema>>;
