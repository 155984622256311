import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { type ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";

import SymbolButton, { SymbolButtonProps } from "../../primitive/Button/SymbolButton/SymbolButton";
import QuestionSwitcher, { QuestionSwitcherProps } from "../QuestionSwitcher/QuestionSwitcher";

const StyledStack = styled(Stack)(({ theme }) => ({
  width: "100%",
  position: "sticky",
  bottom: 0, // footer
  left: 0,
  borderTop: `1px solid ${theme.palette.Other.OutlinedBorder}`,
  padding: "4px 24px",
  backgroundColor: theme.palette.background.default,
  zIndex: theme.zIndex.appBar,
}));

const StyledQuestionSwitcher = styled(QuestionSwitcher)(({ theme }) => ({
  justifyContent: "space-between",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "inherit",
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(1),
    border: "1px solid",
  },
  "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
    borderRadius: theme.shape.borderRadius,
    border: "1px solid",
  },
  "& .MuiToggleButtonGroup-grouped:not(:last-of-type)": {
    borderRadius: theme.shape.borderRadius,
    border: "1px solid",
  },
  "& .MuiButtonBase-root": {
    "&.Mui-selected": {
      backgroundColor: "inherit",
      color: "inherit",
      borderColor: theme.palette.secondary.main,
    },
  },
}));

export type ReportPageFooterProps = {
  switcher: QuestionSwitcherProps;
  topButton: Pick<ButtonProps, "onClick">;
};

const ReportPageFooter: React.FC<ReportPageFooterProps> = props => {
  const topButton: SymbolButtonProps = {
    ...props.topButton,
    children: <ArrowUpwardIcon />,
    sx: { height: "32px", width: "32px", fontSize: "14px" },
    variant: "OUTLINE",
  };
  return (
    <StyledStack direction="row" justifyContent="space-between" position="relative" alignItems="center">
      <StyledQuestionSwitcher {...props.switcher} />
      <SymbolButton {...topButton} />
    </StyledStack>
  );
};

ReportPageFooter.displayName = "ReportPageFooter";

export default ReportPageFooter;
