import { BuySelections } from "@hireroo/app-store/widget/e/BuySelections";
import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as React from "react";

export type GeneratePaymentCompletionPropsArgs = {};

export const useGenerateProps = (_args: GeneratePaymentCompletionPropsArgs): Widget.PaymentCompletionProps => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const navigate = useTransitionNavigate();

  const interviewsMap = BuySelections.useBuyAbleSelectionItemMap();
  const submitValue = BuySelections.useSubmitValue();

  const totalSelection = React.useMemo(() => {
    return submitValue.selectionItems.reduce<number>((total, current) => {
      const interview = interviewsMap[current.itemId];
      if (!interview) {
        return total;
      }
      return total + current.amount * interview.quantity;
    }, 0);
  }, [interviewsMap, submitValue]);

  return {
    title: t("ご購入ありがとうございました。"),
    message: t2("buyNumSelections", { num: totalSelection }),
    link: {
      href: generatePath("/e/settings/billing/overview"),
      onClick: () => {
        navigate("/e/settings/billing/overview");
      },
      children: t("お支払い概要に戻る"),
    },
  };
};
