import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import FreepadResourceProvider from "../questions_freepad_create/Provider";
import Container from "./Container";

export type FreepadResourceEditorMiddleContainerProps = {};

const FreepadResourceEditorMiddleContainer: React.FC<FreepadResourceEditorMiddleContainerProps> = _props => {
  return (
    <ErrorBoundary>
      <FreepadResourceProvider type={"UPDATE"}>
        <Container />
      </FreepadResourceProvider>
    </ErrorBoundary>
  );
};

FreepadResourceEditorMiddleContainer.displayName = "FreepadResourceEditorMiddleContainer";

export default withErrorBoundary(FreepadResourceEditorMiddleContainer, {});
