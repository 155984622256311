import { useTranslation } from "@hireroo/i18n";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";

type Degree = "NOT_SUPPORTED" | "UNCALCULATED" | "CLEAN" | "SUSPICIOUS";

export type SuspiciousDegreeProps = {
  degree: Degree;
};

const StyledWarningIcon = styled(WarningIcon)(({ theme }) => ({
  color: theme.palette.warning.main,
}));

const StyledCheckCircleIcon = styled(CheckCircleIcon)(({ theme }) => ({
  color: theme.palette.text.disabled,
}));

const SuspiciousDegree: React.FC<SuspiciousDegreeProps> = props => {
  const { t } = useTranslation();

  switch (props.degree) {
    case "UNCALCULATED":
      return <></>;
    case "NOT_SUPPORTED":
      return (
        <Tooltip title={t("このテストは不審度の計算対象外です。")}>
          <span>-</span>
        </Tooltip>
      );
    case "CLEAN":
      return (
        <Tooltip title={t("不審な行動はみつかりませんでした。")}>
          <StyledCheckCircleIcon />
        </Tooltip>
      );
    case "SUSPICIOUS":
      return (
        <Tooltip title={t("不審な行動がみつかりました。プレイバックなどを確認し、候補者の行動に問題がないか確認してください。")}>
          <StyledWarningIcon />
        </Tooltip>
      );
    default:
      throw new Error(`invalid degree ${props.degree satisfies never} is given`);
  }
};

SuspiciousDegree.displayName = "SuspiciousDegree";

export default SuspiciousDegree;
