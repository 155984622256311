import * as React from "react";

import EmptyLayout, { EmptyLayoutProps } from "../../layouts/EmptyLayout/EmptyLayout";

export type SignInSignUpProps = {
  layout: EmptyLayoutProps;
  children: React.ReactNode;
};

const SignInSignUp: React.FC<SignInSignUpProps> = props => {
  return <EmptyLayout {...props.layout}>{props.children}</EmptyLayout>;
};

SignInSignUp.displayName = "SignInSignUp";

export default SignInSignUp;
