import { useTranslation } from "@hireroo/i18n";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

type Kind = "SUBMITTED" | "NOT_SUBMITTED" | "UNDER_EVALUATION" | "EXPIRED";

const ExpiredTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.action.disabledBackground,
  display: "inline-block",
  padding: `2px 4px 2px 4px`,
  alignItems: "center",
  borderRadius: "64px",
  fontSize: 12,
  wordBreak: "keep-all",
  whiteSpace: "nowrap",
}));

const EvaluatedTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.contrastText,
  backgroundColor: theme.palette.success.main,
  display: "inline-block",
  padding: `2px 4px 2px 4px`,
  alignItems: "center",
  borderRadius: "64px",
  fontSize: 12,
  wordBreak: "keep-all",
  whiteSpace: "nowrap",
}));

const UnderEvaluationTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.warning.contrastText,
  backgroundColor: theme.palette.warning.main,
  display: "inline-block",
  padding: `2px 4px 2px 4px`,
  alignItems: "center",
  borderRadius: "64px",
  fontSize: 12,
  wordBreak: "keep-all",
  whiteSpace: "nowrap",
}));

export type ExamStatusProps = {
  kind: Kind;
};

const ExamStatus: React.FC<ExamStatusProps> = props => {
  const { t } = useTranslation();
  const StatusMap: Record<Kind, React.ReactNode> = {
    NOT_SUBMITTED: <ExpiredTypography>{t("未提出")}</ExpiredTypography>,
    UNDER_EVALUATION: <UnderEvaluationTypography>{t("採点中")}</UnderEvaluationTypography>,
    SUBMITTED: <EvaluatedTypography>{t("採点済み")}</EvaluatedTypography>,
    EXPIRED: <ExpiredTypography>{t("期限切れ")}</ExpiredTypography>,
  };
  return <>{StatusMap[props.kind]}</>;
};

ExamStatus.displayName = "ExamStatus";

export default ExamStatus;
