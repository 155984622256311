import Editor, { SwappableCodeEditorProps as EditorProps } from "@hireroo/code-editor/react/SwappableCodeEditor";
import * as React from "react";

export type RemoteInterviewChallengePlaybackEditorProps = EditorProps;

const RemoteInterviewChallengePlaybackEditor: React.FC<RemoteInterviewChallengePlaybackEditorProps> = props => {
  const codeEditor: EditorProps = {
    ...props,
  };
  return (
    <React.Suspense>
      <Editor key="editor-1" {...codeEditor} theme="monokai" />
    </React.Suspense>
  );
};

RemoteInterviewChallengePlaybackEditor.displayName = "RemoteInterviewChallengePlaybackEditor";

export default RemoteInterviewChallengePlaybackEditor;
