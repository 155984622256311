import { styled } from "@mui/material/styles";
import Typography, { TypographyProps } from "@mui/material/Typography";
import * as React from "react";

const useParagraphProps = (props: TypographyProps): Pick<TypographyProps, "variant" | "lineHeight" | "marginTop"> => {
  const newProps: TypographyProps = {
    ...props,
    marginTop: "16px",
    lineHeight: 1.5,
  };

  switch (props.variant) {
    case "subtitle1":
      newProps.variant = "subtitle1";
      newProps.fontWeight = "bold";
      break;
    case "body1":
      newProps.variant = "body1";
      break;
    case "body2":
      newProps.variant = "body2";
      newProps.lineHeight = 1.45;
      break;
    default:
      newProps.variant = "body1";
  }

  return newProps;
};

const StyledText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  whiteSpace: "pre-line",
}));

export type ParagraphProps = TypographyProps & {
  children: React.ReactNode[];
};

const Paragraph: React.FC<ParagraphProps> = props => {
  const { children } = props;
  const paragraphProps = useParagraphProps(props);

  return (
    <StyledText {...paragraphProps} paragraph>
      {children}
    </StyledText>
  );
};
Paragraph.displayName = "Paragraph";

export default Paragraph;
