import { state } from "./State";
import type * as Types from "./types";

export const initialize = (customer: Types.Customer, contractPeriodUsages: Types.ContractPeriodUsage[]): void => {
  state.customer = customer;
  state.contractPeriodUsages = contractPeriodUsages;
};

export const clear = (): void => {
  state.customer = null;
  state.contractPeriodUsages = [];
};

export const updateAutoRenewal = (isAutoRenewal: boolean) => {
  if (state.customer) {
    state.customer.isAutoRenewal = isAutoRenewal;
  }
};
