import type { IStandaloneCodeEditor, Monaco } from "@hireroo/code-editor/react/CodeEditor";
import { getTimestamp } from "@hireroo/firebase";
import * as monaco from "monaco-editor";

import type {
  MonacoCopyEventPayload,
  MonacoCutEventPayload,
  MonacoPasteEventPayload,
  Operations,
  TextSelection,
  TextSelections,
} from "./types";

export type Params = {
  callback?: (payload: MonacoPasteEventPayload | MonacoCopyEventPayload | MonacoCutEventPayload) => void;
};

export interface ClipboardEventDispatcher {
  startWatch: () => void;
}

export type ClipboardEventType = "paste" | "copy" | "cut";

export const useMonacoEditorObserver = ({ callback }: Params) => {
  /**
   * Send data to Firebase
   */
  const dispatchPasteEvent = (eventName: ClipboardEventType, value: Operations) => {
    if (callback && value.length > 0 && eventName === "paste") {
      callback({
        s: eventName,
        t: getTimestamp(),
        v: value,
      });
    }
  };

  const dispatchCutCopyEvent = (eventName: ClipboardEventType, value: TextSelections) => {
    if (callback && value.length > 0 && (eventName === "cut" || eventName === "copy")) {
      callback({
        s: eventName,
        t: getTimestamp(),
        v: value,
      });
    }
  };

  const startWatch = (editor: IStandaloneCodeEditor, _monaco: Monaco) => {
    let currentChanges: monaco.editor.IModelContentChange[] = [];

    /** Paste detection */
    const onPaste = () => {
      if (!currentChanges) {
        return;
      }

      let prevRangeLength = 0;
      let prevOffset = 0;
      let operations: Operations = [];

      for (const change of currentChanges) {
        const { text, rangeOffset, rangeLength } = change;

        if (isFirstOp(operations)) {
          /** if rangeOffset is 0, exclude from operations */
          rangeOffset === 0 ? (operations = [text]) : (operations = [rangeOffset, text]);
        } else {
          /** offset must be recalculated to take into account the previous offset and range length */
          operations = [...operations, ...[rangeOffset - prevOffset - prevRangeLength, text]];
        }

        prevOffset = rangeOffset;
        prevRangeLength = rangeLength;
      }

      dispatchPasteEvent("paste", operations);
    };

    /** Copy and cut detection */
    const createCopyOrCutHandler = (eventType: "copy" | "cut") => () => {
      const value = editor.getValue();
      const selections = editor.getSelections();
      const model = editor.getModel();

      if (selections && model) {
        const sortedSelections = sortSelections(selections);
        const copiedValues: TextSelection[] = [];

        for (const selection of sortedSelections) {
          const start = model.getOffsetAt(selection.getStartPosition());
          const end = model.getOffsetAt(selection.getEndPosition());
          if (end > value.length) return;

          const v: TextSelection = {
            text: value.substring(start, end),
            position: start,
            selectionEnd: end,
          };
          copiedValues.push(v);

          dispatchCutCopyEvent(eventType, copiedValues);
        }
      }
    };

    const onCopy = createCopyOrCutHandler("copy");
    const onCut = createCopyOrCutHandler("cut");

    window.addEventListener("copy", onCopy);
    window.addEventListener("cut", onCut);

    /** Current code relies on this listener to run before onDidPaste */
    editor.onDidChangeModelContent(({ changes }: monaco.editor.IModelContentChangedEvent) => {
      currentChanges = changes;
    });
    /** Paste detection */
    editor.onDidPaste(onPaste);

    return () => {
      window.removeEventListener("copy", onCopy);
      window.removeEventListener("cut", onCut);
    };
  };

  return {
    startWatch,
  };
};

/**
 *
 * If multiple cursors are present in the editor, getSelections() returns these so that the primary cursor is ordered first, followed by secondary cursors.
 * If the first cursor is at line 5, but is duplicated to line 4, then line 3, the selections array will be ordered that way.
 *
 * However for text operations, the order is sorted by ascending order of startLineNumber, with a secondary sort by startColumn if startLineNumber is equal.
 * Therefore, we need to sort the selections are ordered in accordance with text operations.
 *
 */
function sortSelections(selection: monaco.Selection[]): monaco.Selection[] {
  return selection.sort((a, b) => {
    if (a.startLineNumber !== b.startLineNumber) {
      return a.startLineNumber - b.startLineNumber;
    } else {
      return a.startColumn - b.startColumn;
    }
  });
}

function isFirstOp(operations: Operations): boolean {
  return operations.length < 1;
}
