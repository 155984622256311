import { generateKey } from "@hireroo/app-helper/parser";
import { subscribeKey } from "valtio/utils";

import { state } from "./State";
import * as Types from "./types";

export type SubscribePdfState = (query: Types.Query) => void;

export const subscribeQueryKey = (callback: SubscribePdfState) => {
  return subscribeKey(state, "query", query => {
    const queryKey = generateKey(query);
    const result = state.queryResultMap.get(queryKey);
    if (result && (result.status === "NOT_ENOUGH" || result.status === "SATISFY")) {
      return;
    }
    state.queryResultMap.set(queryKey, {
      rank: null,
      statistics: null,
      status: "LOADING",
    });
    callback(query);
  });
};
