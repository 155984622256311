import { SplitProvider } from "@hireroo/react-split";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";

import Split, { SplitProps } from "../../../../primitive/Split/Split";
import LeftSidePanel, { LeftSidePanelProps } from "../LeftSidePanel/LeftSidePanel";

const SplitParams = {
  splitId: "RemoteInterviewForDetail",
  Contents: {
    LeftSideBar: {
      id: "LeftSideBar",
    },
    Main: {
      id: "Main",
    },
    RightSideBar: {
      id: "RightSideBar",
    },
  },
};
const StyledSplit = styled(Split)`
  height: 100%;

  &[data-direction*="horizontal"] {
    width: calc(100% - 36px);
  }
`;

export type QuestionWithEditorProps = {
  leftSidePanel: Omit<LeftSidePanelProps, "closePaneButton">;
  Editor: React.ReactNode;
  height?: number;
};

const QuestionWithEditor: React.FC<QuestionWithEditorProps> = props => {
  const left = useResizeDetector();
  const splitItems: SplitProps["items"][0][] = [
    {
      id: SplitParams.Contents.LeftSideBar.id,
      Content: (
        <Box ref={left.ref} maxHeight="100%" height="100%" overflow="auto">
          <LeftSidePanel {...props.leftSidePanel} />
        </Box>
      ),
      size: {
        value: 400,
        unit: "px",
      },
      minimizedSize: {
        value: 0,
        unit: "px",
      },
      expandedSize: {
        value: 400,
        unit: "px",
      },
      sidePane: {
        className: "tutorial-pane",
      },
    },
    {
      id: SplitParams.Contents.Main.id,
      Content: (
        <Box key="main-content" height={props.height ?? "100vh"} width="100%">
          {props.Editor}
        </Box>
      ),
      size: {
        value: 100,
        unit: "%",
      },
      sidePane: {
        className: "tutorial-pane",
      },
    },
  ];

  const splitProps: SplitProps = {
    splitDirection: "VERTICAL",
    splitId: SplitParams.splitId,
    items: splitItems,
  };
  return (
    <SplitProvider>
      <Box height="100%" overflow="auto">
        <StyledSplit key={splitProps.splitId} {...splitProps} />
      </Box>
    </SplitProvider>
  );
};

QuestionWithEditor.displayName = "QuestionWithEditor";

export default QuestionWithEditor;
