import CommentOutlined from "@mui/icons-material/CommentOutlined";
import DescriptionOutlined from "@mui/icons-material/DescriptionOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import TrendingUpOutlined from "@mui/icons-material/TrendingUpOutlined";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";

import Tab, { TabProps } from "../../../../primitive/Tab/Tab";

const IconMap = {
  DESCRIPTION: <DescriptionOutlined />,
  TRENDING_UP: <TrendingUpOutlined />,
  COMMENT: <CommentOutlined />,
  MAIL: <EmailOutlinedIcon fontSize="small" />,
  LINK: <InsertLinkOutlinedIcon fontSize="small" />,
} satisfies Record<string, React.ReactElement>;

type Icon = keyof typeof IconMap;
export type TabWithTooltipProps = {
  value: string;
  name: string;
  onClick?: TabProps["onClick"];
  disabled?: TabProps["disabled"];
  disabledTooltip?: string;
  icon?: Icon;
};

const TabWithTooltip: React.FC<TabWithTooltipProps> = props => {
  const { value, name, onClick, disabled, disabledTooltip, ...other } = props;
  const tab: TabProps = {
    ...other,
    sx: {
      textTransform: "none",
    },
    label: name,
    icon: props.icon && IconMap[props.icon],
    value: value,
    "aria-labelledby": name,
    onClick: onClick,
    iconPosition: "start",
  };

  if (disabled) {
    return (
      <Tooltip title={disabledTooltip ?? ""}>
        <span>
          <Tab {...tab} disabled={disabled} />
        </span>
      </Tooltip>
    );
  }

  return <Tab {...tab} />;
};

TabWithTooltip.displayName = "TabWithTooltip";

export default TabWithTooltip;
