import * as Graphql from "@hireroo/graphql/client/urql";

import * as Types from "../screenings/types";
import { State } from "./types";

export const initFilters = {
  createdByList: [],
  name: "",
  result: undefined,
  statuses: [],
  tagNames: [],
};
export const SortFields = {
  CREATED_AT_DESCENDING: "created-at-descending",
  CREATED_AT_ASCENDING: "created-at-ascending",
} satisfies Record<string, Types.SortFieldValue>;

export const SortParams = {
  [SortFields.CREATED_AT_DESCENDING]: { sortMethod: "CREATED_AT", isDescending: true },
  [SortFields.CREATED_AT_ASCENDING]: { sortMethod: "CREATED_AT", isDescending: false },
} satisfies Record<
  string,
  {
    isDescending: boolean;
    sortMethod: Graphql.SortMethod;
  }
>;

export const DefaultPager: Types.Pager = {
  page: 0,
  size: 20,
  offset: 0,
  sortFieldValue: SortFields.CREATED_AT_DESCENDING,
};

export const defaultState: Readonly<State> = {
  remoteTags: null,
  searchTags: [],
  listParams: {
    isDescending: true,
    page: 0,
    size: 50,
    sortMethod: Graphql.SpotsByCompanyIdSortMethod.CreatedAt,
    filters: initFilters,
  },
  count: 0,
  creators: [],
  initialized: false,
  loadingStatus: "NONE",
  currentSearchFilter: null,
  pager: DefaultPager,
  res: null,
};
