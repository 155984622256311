import { useTranslation } from "@hireroo/i18n";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Rating, { RatingProps } from "@mui/material/Rating";
import { styled, useTheme } from "@mui/material/styles";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  backgroundColor: theme.palette.grey["800"],
}));

export type EvaluationItemProps = {
  id: string;
  index: number;
  title: string;
  description?: string;
  commentField: Pick<TextFieldProps, "onChange" | "defaultValue">;
  ratingField: RatingProps;
};

const EvaluationItem: React.FC<EvaluationItemProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState<boolean>(true);

  const handleClickExpand = React.useCallback(() => {
    setExpanded(prev => !prev);
  }, []);

  const ratingField: RatingProps = {
    ...props.ratingField,
    max: 4,
  };
  const evaluationCommentFieldProps: TextFieldProps = {
    ...props.commentField,
    color: "secondary",
    type: "text",
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
    required: true,
    multiline: true,
    minRows: 5,
    placeholder: t("評価コメント（任意）"),
  };

  return (
    <StyledAccordion
      expanded={expanded}
      TransitionProps={{ unmountOnExit: true }}
      defaultExpanded={true}
      sx={{ borderRadius: "8px", mt: props.index === 0 ? 0 : 2, mb: 2 }}
      square
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.action.active }} />} onClick={handleClickExpand}>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" width="100%">
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="subtitle2">{props.title}</Typography>
          </Box>
          <Box display="flex" flexDirection="row">
            <Rating {...ratingField} />
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" flexDirection="column" gap={4}>
          <Typography variant="body2">{props.description}</Typography>
          <TextField {...evaluationCommentFieldProps} />
        </Box>
      </AccordionDetails>
    </StyledAccordion>
  );
};

EvaluationItem.displayName = "EvaluationItem";

export default EvaluationItem;
