import { ref } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

export const initialize = (
  plans: Types.PlansResponse,
  customer: Types.Customer,
  interviews: Types.Interview[],
  initialCost: Types.PaymentInitialCost | null,
): void => {
  state.plansResponse = plans;
  state.customer = customer;
  state.interviews = interviews;
  state.initialCost = initialCost;
};

export const setSubmitValue = (submitValue: Types.SubmitValue) => {
  state.submitValue = submitValue;
};

export const clear = (): void => {
  state.customer = null;
  state.plansResponse = null;
  state.submitValue = null;
  state.temporarySubmitValue = null;
  state.showingTarget = "EDITOR";
  state.selectionItems = {};
  state.appliedCoupons = [];
  state.interviews = [];
  state.stripJsPaymentMethodResult = null;
  state.initialCost = null;
};

export const updateShowingTarget = (status: Types.ShowingTarget) => {
  state.showingTarget = status;
};

export const setTemporarySubmitValue = (temporarySubmitValue: Types.SubmitValue) => {
  state.temporarySubmitValue = temporarySubmitValue;
  /** Operations required to fire anEvent */
  state.temporarySubmitValue.selectionItems = temporarySubmitValue.selectionItems.slice();
};

export const addCoupon = (coupon: Types.Coupon) => {
  state.appliedCoupons.push(coupon);
};

export const setStripJsPaymentMethodResult = (stripJsPaymentMethodResult: Types.StripeJsPaymentMethodResult | null) => {
  if (stripJsPaymentMethodResult) {
    state.stripJsPaymentMethodResult = ref(stripJsPaymentMethodResult);
  } else {
    state.stripJsPaymentMethodResult = null;
  }
};
