import { ISessionContext } from "@jupyterlab/apputils";
import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { useJupyterContext } from "../../../store";

type KernelDisplayStatus = ISessionContext.KernelDisplayStatus;

const HEIGHT = 30;

const Wrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  height: HEIGHT,
}));

const MarkWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingRight: 4,
  paddingLeft: 4,
  svg: {
    width: 12,
    height: 12,
  },
}));

const StyledDisplayName = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.text.primary,
  fontFamily: `var(--jp-ui-font-family)`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export type KernelStatusProps = {};

/**
 * @example https://github.com/jupyterlab/jupyterlab/blob/main/packages/notebook/src/executionindicator.tsx
 */
const KernelStatus: React.FC<KernelStatusProps> = () => {
  const { adapter } = useJupyterContext();
  const [kernelName, setKernelName] = React.useState(adapter?.sessionContext?.kernelDisplayName ?? "-");
  const [kernelStatus, setKernelStatus] = React.useState<KernelDisplayStatus>(adapter?.sessionContext?.kernelDisplayStatus ?? "");
  const updateKernelName = React.useCallback(() => {
    setKernelName(adapter?.sessionContext?.kernelDisplayName ?? "-");
    setKernelStatus(adapter?.sessionContext?.kernelDisplayStatus ?? "");
  }, [adapter]);

  React.useEffect(() => {
    adapter?.sessionContext?.kernelChanged.connect(updateKernelName);
    return () => {
      adapter?.sessionContext?.kernelChanged.disconnect(updateKernelName);
    };
  }, [adapter, updateKernelName]);

  const KernelStatusMark: Record<KernelDisplayStatus, React.ReactNode> = {
    "": <CircleOutlinedIcon color="disabled" />,
    idle: <CircleOutlinedIcon color="disabled" />,
    unknown: <CircleOutlinedIcon color="disabled" />,
    starting: <CircleIcon color="secondary" />,
    busy: <CircleIcon color="warning" />,
    terminating: <CircleOutlinedIcon color="warning" />,
    restarting: <CircleOutlinedIcon color="warning" />,
    autorestarting: <CircleOutlinedIcon color="success" />,
    dead: <CircleIcon color="error" />,
    connected: <CircleIcon color="success" />,
    connecting: <CircleIcon color="success" />,
    disconnected: <CircleIcon color="error" />,
    initializing: <CircleIcon color="warning" />,
  };

  const KernelStatusLabel: Record<KernelDisplayStatus, string> = {
    "": "Idle",
    unknown: "Idle",
    starting: "Starting",
    idle: "Idle",
    busy: "Busy",
    terminating: "Terminating",
    restarting: "Restarting",
    autorestarting: "Auto Restarting",
    dead: "Dead",
    connected: "Connected",
    connecting: "Connecting",
    disconnected: "Disconnected",
    initializing: "Initializing",
  };

  return (
    <Wrapper>
      <StyledDisplayName>{kernelName}</StyledDisplayName>
      <Tooltip title={`Kernel Status: ${KernelStatusLabel[kernelStatus]}`}>
        <MarkWrapper>{KernelStatusMark[kernelStatus]}</MarkWrapper>
      </Tooltip>
    </Wrapper>
  );
};

KernelStatus.displayName = "KernelStatus";

export default KernelStatus;
