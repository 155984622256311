import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { startSubscribe } from "./Subscriber";
import { useGenerateProps } from "./useGenerateProps";

export type QuestionSearchAreaContainerProps = {};

const QuestionSearchAreaContainer: React.FC<QuestionSearchAreaContainerProps> = () => {
  const questionSearchAreaProps = useGenerateProps({});
  React.useEffect(() => {
    const stop = startSubscribe();
    return () => {
      stop();
    };
  }, []);
  return (
    <ErrorBoundary>
      <Widget.QuestionSearchArea {...questionSearchAreaProps} />
    </ErrorBoundary>
  );
};

QuestionSearchAreaContainer.displayName = "QuestionSearchAreaContainer";

export default withErrorBoundary(QuestionSearchAreaContainer, {});
