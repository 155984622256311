import { useQuestionDifficultyMap } from "@hireroo/app-definition/question";
import { RemotesId } from "@hireroo/app-store/page/e/remotes_id";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Pages } from "@hireroo/presentation";

export const useGenerateLeftSidePanelProps = (): Pages.RemoteInterviewForEmployeeProps["leftSidePanel"] => {
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const question = RemotesId.useNormalizedCurrentSession();
  const questionDifficultyMap = useQuestionDifficultyMap();
  const activeSessionId = RemotesId.useActiveSessionId();
  return {
    questionAndAnswer: {
      uniqueKey: activeSessionId?.toString(),
      questionSection: {
        title: resolveLanguage(question || {}, lang, "title"),
        description: resolveLanguage(question || {}, lang, "description"),
        difficultyText: question ? questionDifficultyMap[question.difficulty] : "",
        /**
         * Always `false` as the user does not need to know if the issue has been archived during testing
         */
        isArchived: false,
      },
      answerSections: (question?.answers || []).map((answer, index) => {
        return {
          title: resolveLanguage(question || {}, lang, "title"),
          tabName: resolveLanguage(answer || {}, lang, "title", `${t("模範解答")} ${index + 1}`),
          description: resolveLanguage(answer || {}, lang, "description"),
        };
      }),
    },
  };
};
