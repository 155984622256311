import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import EntityScoreBoard, { EntityScoreBoardProps } from "../../modules/EntityScoreBoard/EntityScoreBoard";
import ReportCheatDetectionSection, {
  ReportCheatDetectionSectionProps,
} from "../../modules/ReportCheatDetectionSection/ReportCheatDetectionSection";
import TestReportSectionHeader, { TestReportSectionHeaderProps } from "../../modules/TestReportSectionHeader/TestReportSectionHeader";
import QuizQuestionCardContent, {
  QuizQuestionCardContentProps,
} from "../../ms-components/MultiChoice/QuizQuestionCardContent/QuizQuestionCardContent";
import QuestionDetailDrawer, { QuestionDetailDrawerProps } from "../../ms-components/Question/QuestionDetailDrawer/QuestionDetailDrawer";
import ReportQuizAnswerDetailSection, {
  ReportQuizAnswerDetailSectionProps,
} from "../../ms-components/Quiz/ReportQuizAnswerDetailSection/ReportQuizAnswerDetailSection";
import ReportQuizPlaybackSection, {
  ReportQuizPlaybackSectionProps,
} from "../../ms-components/Quiz/ReportQuizPlaybackSection/ReportQuizPlaybackSection";
import ReportQuizSubmissionSection, {
  ReportQuizSubmissionSectionProps,
} from "../../ms-components/Quiz/ReportQuizSubmissionSection/ReportQuizSubmissionSection";

const EntityReportWrapper = styled(Box)(({ theme }) => ({
  borderRadius: "16px",
  padding: "16px",
  backgroundColor: theme.palette["Background/Paper"].p2,
}));

export type QuizTestReportProps = {
  header: Omit<TestReportSectionHeaderProps, "detailButton">;
  questionDetail: Omit<QuestionDetailDrawerProps, "open" | "onClose" | "variantText" | "AnswerContent" | "answerContents"> & {
    questionItems: QuizQuestionCardContentProps[];
    /**
     * default: true
     */
    showAnswer?: boolean;
  };
  entityScoreBoard?: Omit<EntityScoreBoardProps, "title">;
  answerDetailSection?: ReportQuizAnswerDetailSectionProps;
  StatisticsContent?: React.ReactNode;
  submissionSection?: ReportQuizSubmissionSectionProps;
  cheatDetectionSection?: ReportCheatDetectionSectionProps;
  playbackSection?: ReportQuizPlaybackSectionProps;
  announcement?: string;
};

const QuizTestReport: React.FC<QuizTestReportProps> = props => {
  const { t } = useTranslation();
  const [detailOpen, setDetailOpen] = React.useState(false);

  const header: TestReportSectionHeaderProps = {
    ...props.header,
    detailButton: {
      onClick: () => {
        setDetailOpen(true);
      },
    },
  };
  const { questionItems, showAnswer = true, ...details } = props.questionDetail;
  const questionDrawer: QuestionDetailDrawerProps = {
    ...details,
    answerContents: [
      {
        id: "answer",
        Component: (
          <Box width="100%" p={3}>
            {questionItems.map(quizQuestionCardContent => (
              <Box mb={4} display="flex" justifyContent="center" key={quizQuestionCardContent.content.id} width="100%">
                <Paper elevation={5} sx={{ borderRadius: "8px", padding: 2, width: "100%" }}>
                  <Box height="100%">
                    <QuizQuestionCardContent {...quizQuestionCardContent} />
                  </Box>
                </Paper>
              </Box>
            ))}
          </Box>
        ),
        label: {
          name: showAnswer ? t("解答") : t("問題"),
        },
      },
    ],
    open: detailOpen,
    onClose: () => {
      setDetailOpen(false);
    },
    variantText: t("クイズ形式"),
  };

  const entityScoreBoard: EntityScoreBoardProps | undefined = props.entityScoreBoard && {
    ...props.entityScoreBoard,
    title: t("クイズ形式"),
  };

  return (
    <Box>
      <TestReportSectionHeader {...header} />
      <EntityReportWrapper mt={2}>
        {props.announcement && <Typography>{props.announcement}</Typography>}
        {!props.announcement && (
          <Box>
            {entityScoreBoard && <EntityScoreBoard {...entityScoreBoard} />}
            <Stack spacing={5} mt={5}>
              {props.StatisticsContent && props.StatisticsContent}
              {props.answerDetailSection && <ReportQuizAnswerDetailSection {...props.answerDetailSection} />}
              {props.cheatDetectionSection && <ReportCheatDetectionSection {...props.cheatDetectionSection} />}
              {props.submissionSection && <ReportQuizSubmissionSection {...props.submissionSection} />}
              {props.playbackSection && <ReportQuizPlaybackSection {...props.playbackSection} />}
            </Stack>
          </Box>
        )}
      </EntityReportWrapper>
      <QuestionDetailDrawer {...questionDrawer} />
    </Box>
  );
};

QuizTestReport.displayName = "QuizTestReport";

export default QuizTestReport;
