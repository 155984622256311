import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type RemoteInterviewTutorialContainerProps = {};

const RemoteInterviewTutorialContainer: React.FC<RemoteInterviewTutorialContainerProps> = () => {
  const remoteInterviewTutorialProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.RemoteInterviewTutorial {...remoteInterviewTutorialProps} />
    </ErrorBoundary>
  );
};

RemoteInterviewTutorialContainer.displayName = "RemoteInterviewTutorialContainer";

export default withErrorBoundary(RemoteInterviewTutorialContainer, {});
