import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import QuestionItem, { QuestionItemProps } from "./parts/QuestionItem/QuestionItem";

export type SelectQuestionFieldProps = {
  items: QuestionItemProps[];
};

const SelectQuestionField: React.FC<SelectQuestionFieldProps> = props => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography variant="h6" style={{ fontWeight: "bold", textAlign: "center" }} mb={2}>
        {t("出題する問題")}
      </Typography>
      <Stack spacing={1.5} direction="column">
        {props.items.map((item, index) => {
          return <QuestionItem key={`Q${index}`} {...item} />;
        })}
      </Stack>
    </Box>
  );
};

SelectQuestionField.displayName = "SelectQuestionField";

export default SelectQuestionField;
