import * as ProjectHelperV2 from "@hireroo/app-helper/project-v2";
import * as ProjectHelperV3 from "@hireroo/app-helper/project-v3";
import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import { formatScore } from "@hireroo/formatter/score";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

export type GenerateGeneratePerformanceDetailSectionPropsForBackendArgs = {
  entityId: number;
  uniqueKey: ProjectTestReport.UniqueKey;
  showScore: boolean;
};

export const useGeneratePerformanceDetailSectionPropsForBackend = (
  args: GenerateGeneratePerformanceDetailSectionPropsForBackendArgs,
): Widget.ProjectTestReportProps["performanceDetailSection"] => {
  const hooks = ProjectTestReport.useCreateProjectHooks(args.entityId);
  const submission = hooks.useCurrentSubmission();
  const question = hooks.useQuestion();
  const variant = hooks.useQuestionVariant();

  const data = React.useMemo(() => {
    if (!submission?.performanceTestResult || variant !== "Backend") {
      return [];
    }

    if (question?.projectVersion.startsWith("v3")) {
      const list = ProjectHelperV3.BackendTestCase.parsePerformanceTestCaseResults(submission.performanceTestResult)?.test_results ?? [];
      return list.map(x => {
        // Since we use a stacked area chart, we only care the relative diff to the closet one
        // Loop through each percentile value, then get the diff by subtracting the previous value
        const p90 = x.p90 - x.p50;
        const p95 = x.p95 - p90;
        const p99 = x.p99 - p95;
        return {
          ...x,
          p90,
          p95,
          p99,
        };
      });
    } else {
      const list = ProjectHelperV2.BackendTestCase.parsePerformanceTestCaseResults(submission.performanceTestResult) ?? [];
      return list.map(x => {
        // Since we use a stacked area chart, we only care the relative diff to the closet one
        // Loop through each percentile value, then get the diff by subtracting the previous value
        const p90 = x.p90 - x.p50;
        const p95 = x.p95 - p90;
        const p99 = x.p99 - p95;
        return {
          ...x,
          p90,
          p95,
          p99,
        };
      });
    }
  }, [question?.projectVersion, submission?.performanceTestResult, variant]);

  return {
    kind: "BACKEND",
    scrollTargetElementId: ProjectTestReport.TargetElementIdMap.PERFORMANCE_SECTION,
    titleWithScoreBar: {
      score: args.showScore ? formatScore(submission?.performance ?? 0) : undefined,
    },
    content: {
      httpStatusCodeGraph: {
        data: data,
      },
      latencyGraph: {
        data: data,
      },
    },
  };
};
