import { Auth } from "@hireroo/app-store/essential/employee";
import { EmployeeNavigation } from "@hireroo/app-store/widget/e/EmployeeNavigation";
import { formatTimeDistance } from "@hireroo/formatter/time";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useLanguageCode } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import { Widget } from "@hireroo/presentation/legacy";
import { useTransitionNavigateWithRawUri } from "@hireroo/router/hooks";

export type GenerateNotificationListPropsArgs = {
  companyId: number;
  now: Date;
};

export const useGenerateProps = (args: GenerateNotificationListPropsArgs): Widget.NotificationListProps => {
  const { now } = args;
  const employeeNotifications = EmployeeNavigation.useUnreadEmployeeNotifications();
  const hasNext = EmployeeNavigation.useHasNext();
  const currentUser = Auth.useCurrentUser();
  const lang = useLanguageCode();
  const client = getGraphqlClient();
  const navigate = useTransitionNavigateWithRawUri();

  return {
    notifications: employeeNotifications.map(employeeNotification => {
      const distance = employeeNotification.createdAtSeconds
        ? formatTimeDistance(new Date(employeeNotification.createdAtSeconds * 1000), now, lang)
        : "";
      return {
        id: employeeNotification.notificationId,
        title: resolveLanguage(employeeNotification, lang, "title"),
        distance: distance,
        onClick: async () => {
          await client
            .ReadEmployeeNotifications({
              companyId: employeeNotification.companyId,
              employeeId: employeeNotification.employeeId,
              employeeNotificationIds: [employeeNotification.notificationId],
            })
            .then(() => {
              EmployeeNavigation.read(employeeNotification.notificationId);
              // Parse the path from the action link, excluding the domain
              const url = new URL(employeeNotification.actionLink);
              navigate(url.pathname);
            });
        },
      };
    }),
    hasNext: hasNext,
    onClickReadAll: async () => {
      await client
        .ReadEmployeeNotifications({
          companyId: args.companyId,
          employeeId: currentUser.uid,
          employeeNotificationIds: employeeNotifications.map(e => e.notificationId),
        })
        .then(res => {
          const readNotificationIds = res.readEmployeeNotifications.map(notification => notification.notificationId);
          EmployeeNavigation.readAll(readNotificationIds);
        });
    },
    intersectCallback: () => {
      EmployeeNavigation.goNextPage();
    },
  };
};
