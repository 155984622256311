import { SKIP_OPTION_ID } from "@hireroo/app-definition/quiz";
import { type AccessEvent } from "@hireroo/app-helper/firepad";
import { QuizTestReport } from "@hireroo/app-store/view-domain/QuizTestReport";
import { QuizFreeTextEvaluateForm } from "@hireroo/validator";

export type SubmissionStatus = "NOT_EVALUATED" | "CORRECT" | "INCORRECT" | "NO_SUBMISSION" | "SKIP";

export type AccessEventRecord = {
  questionId: string;
  event: AccessEvent;
};

/** For scroll */
export const QUIZ_PLAYBACK_ELEMENT_ID = "quiz-playback";

export const getSubmissionStatus = (question: QuizTestReport.QuizQuestion, submission?: QuizTestReport.Submission): SubmissionStatus => {
  if (!submission) return "NO_SUBMISSION";
  if (question.variant === "FREE_TEXT" && submission.quizSubmissionStatus !== "EVALUATED") {
    return "NOT_EVALUATED";
  }

  const optionIdSet = new Set<number>([...submission.optionIds]);
  if (optionIdSet.has(SKIP_OPTION_ID)) return "SKIP";
  if (submission.isCorrect) return "CORRECT";
  return "INCORRECT";
};

export const shouldDisplayAdditionalDetail = (question: QuizTestReport.QuizQuestion, submission?: QuizTestReport.Submission) => {
  if (question.variant === "FREE_TEXT") return false;
  if (submission && !submission.isCorrect) {
    return true;
  }
  return false;
};

export const convertSubmissionsFieldValue = (
  resources: QuizFreeTextEvaluateForm.SubmissionResource[],
): QuizFreeTextEvaluateForm.QuizFreeTextEvaluateForm => {
  const initialValue = {};
  return resources.reduce((obj, submission) => {
    const key = String(submission.submissionId);
    return {
      ...obj,
      [key]: submission,
    };
  }, initialValue);
};

export const diffDefaultSubmissionIDs = (
  fields: QuizFreeTextEvaluateForm.QuizFreeTextEvaluateForm,
  defaultValues: QuizFreeTextEvaluateForm.SubmissionResource[],
): number[] => {
  const diffSubmissionIDs: number[] = [];
  const submissionsMap = new Map<number, boolean | undefined>();
  const evaluationsMap = new Map<number, boolean | undefined>();

  defaultValues.forEach(resource => {
    submissionsMap.set(resource.submissionId, resource.isCorrect);
    resource.evaluations.forEach(evaluation => {
      evaluationsMap.set(evaluation.id, evaluation.isPassed);
    });
  });

  Object.values(fields).forEach(field => {
    if (field.isCorrect !== submissionsMap.get(field.submissionId)) {
      diffSubmissionIDs.push(field.submissionId);
    } else if (field.evaluations && field.evaluations.length >= 0) {
      field.evaluations.forEach(evaluation => {
        if (evaluation.isPassed !== evaluationsMap.get(evaluation.id)) {
          diffSubmissionIDs.push(field.submissionId);
        }
      });
    }
  });

  return diffSubmissionIDs;
};

/**
 * Extracts duplicate access events by IP address.
 * Returns an array of AccessEventRecord, containing the question id and the access event.
 */
export const extractDuplicateAccessEvents = (accessEvents: Record<string, Record<string, AccessEvent>>): Array<AccessEventRecord> => {
  const flattenedAccessEvents: Array<AccessEventRecord> = [];

  /** flatten access events object into array */
  for (const [questionId, history] of Object.entries(accessEvents)) {
    for (const event of Object.values(history)) {
      flattenedAccessEvents.push({ questionId, event });
    }
  }

  /** sort by timestamp */
  const sortedAccessEvents = flattenedAccessEvents.slice().sort((a, b) => a.event.t - b.event.t);

  /** filter duplicate ip addresses */
  const loggedIpAddresses = new Set<string>();
  const extractedAccessEvents = sortedAccessEvents.reduce((accessEventRecords: Array<AccessEventRecord>, accessEvent) => {
    if (accessEvent.event.l && !loggedIpAddresses.has(accessEvent.event.l)) {
      loggedIpAddresses.add(accessEvent.event.l);
      accessEventRecords.push(accessEvent);
    }
    return accessEventRecords;
  }, []);

  return extractedAccessEvents;
};
