import * as monaco from "monaco-editor/esm/vs/editor/editor.api";

import * as Def from ".//Definition";
import * as Factory from "./Factory";
import type * as Types from "./types";

type TooltipNode = {
  dom: HTMLElement;
  duration: number;
};

type CursorNode = {
  id: string;
  dom: HTMLElement;
  position: monaco.editor.IContentWidgetPosition | null;
  clearTimer: () => void;
};

type StyleNode = {
  dom: HTMLStyleElement;
};

const NOOP = () => undefined;

/**
 * This class implements a Monaco Content Widget to render a user's name in a tooltip and decoration.
 * Ref: https://github.com/convergencelabs/monaco-collab-ext/blob/master/src/ts/RemoteCursorWidget.ts
 */
export class CursorWidget implements monaco.editor.IContentWidget, Types.IDisposable {
  private readonly scrollListener: monaco.IDisposable | null;
  private style: StyleNode;
  private disposed: boolean = false;

  private cursor: CursorNode;
  private tooltip: TooltipNode;
  private afterDisposed: () => void = NOOP;

  constructor(
    private readonly editor: monaco.editor.ICodeEditor,
    config: Types.ICursorWidgetConstructorOptions,
  ) {
    const tooltipDOM = config.label
      ? Factory.createTooltipElement({
          classNames: [
            `${Def.ClassNames.TOOLTIP_NODE_CLASS_NAME}-${config.backgroundColor.replace("#", "")}`,
            Def.ClassNames.TOOLTIP_NODE_CLASS_NAME,
          ],
          text: config.label,
          textColor: config.textColor ?? "#FFFFFF",
          opacity: config.opacity ?? "1.0",
          backgroundColor: config.backgroundColor,
          borderColor: config.backgroundColor,
        })
      : Factory.createSquareElement({
          classNames: [
            `${Def.ClassNames.TOOLTIP_NODE_CLASS_NAME}-${config.backgroundColor.replace("#", "")}`,
            Def.ClassNames.TOOLTIP_NODE_CLASS_NAME,
          ],
          opacity: config.opacity ?? "1.0",
          backgroundColor: config.backgroundColor,
          borderColor: config.backgroundColor,
        });

    this.tooltip = {
      dom: tooltipDOM,
      duration: config.autoHideDurationMilliseconds ?? 500,
    };

    this.cursor = {
      id: Def.generateCursorId(config.cursorId),
      dom: Factory.createCursorElement({
        classNames: [Def.ClassNames.OVERLAY_MESSAGE, Def.ClassNames.WIDGET_NODE_CLASS_NAME],
        backgroundColor: config.backgroundColor,
      }),
      position: null,
      clearTimer: NOOP,
    };

    this.cursor.dom.appendChild(this.tooltip.dom);

    this.style = {
      dom: Factory.createStyleElement({
        className: this.decorationName,
        backgroundColor: config.backgroundColor,
      }),
    };

    document.head.appendChild(this.style.dom);

    /**
     * we only need to listen to scroll positions to update the
     * tooltip location on scrolling.
     */
    this.scrollListener = this.editor.onDidScrollChange(() => {
      this.updateTooltipPosition();
    });
    this.afterDisposed = config.afterDisposed || NOOP;
  }

  /** impl */
  public getId = (): string => {
    return this.cursor.id;
  };

  /** impl */
  public getDomNode = (): HTMLElement => {
    return this.cursor.dom;
  };

  /** impl */
  public getPosition = (): monaco.editor.IContentWidgetPosition | null => {
    return this.cursor.position;
  };

  public updateCursorPosition = (range: monaco.Range): void => {
    this.cursor.position = {
      position: range.getEndPosition(),
      preference: [monaco.editor.ContentWidgetPositionPreference.ABOVE, monaco.editor.ContentWidgetPositionPreference.BELOW],
    };

    this.editor.layoutContentWidget(this);
    this.showCursorTooltip();
  };

  public updateContent = (cursorLabel?: string): void => {
    if (typeof cursorLabel !== "string" || !this.tooltip.dom) {
      return;
    }
    this.tooltip.dom.textContent = cursorLabel;
  };

  public dispose = (): void => {
    if (this.disposed) return;
    this.editor.removeContentWidget(this);
    this.cursor.dom.remove();
    this.tooltip.dom.remove();
    this.style?.dom.remove();
    this.scrollListener?.dispose();
    this.disposed = true;
    this.afterDisposed();
  };

  public isDisposed = (): boolean => {
    return this.disposed;
  };

  public get decorationName(): string {
    return Def.generateDecorationName(this.cursor.id);
  }

  private showCursorTooltip = (): void => {
    this.updateTooltipPosition();
    this.setTooltipVisible(true);

    this.cursor.clearTimer();
    const timer = window.setTimeout(() => {
      this.setTooltipVisible(false);
    }, this.tooltip.duration);

    this.cursor.clearTimer = () => {
      window.clearTimeout(timer);
      this.cursor.clearTimer = NOOP;
    };
  };

  private updateTooltipPosition = (): void => {
    const distanceFromTop = this.cursor.dom.offsetTop - this.editor.getScrollTop();
    const willShowOnCursor = distanceFromTop - this.tooltip.dom.offsetHeight < 5;
    const top = willShowOnCursor ? this.tooltip.dom.offsetHeight + 2 : -this.tooltip.dom.offsetHeight;
    this.tooltip.dom.style.top = `${top}px`;
    this.tooltip.dom.style.left = "0";
  };

  private setTooltipVisible = (visible: boolean): void => {
    this.tooltip.dom.style.display = visible ? "block" : "none";
  };
}
