import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination, { TablePaginationProps } from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import React from "react";

import SelectBox, { SelectBoxProps } from "./parts/SelectBox/SelectBox";
import UsageTableRow, { UsageTableRowProps } from "./parts/UsageTableRow/UsageTableRow";

const HeadingWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: 40,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
}));

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.text.primary,
  fontWeight: 700,
}));

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 12,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
}));

const StyledTableHeaderRow = styled(TableRow)(({ theme }) => ({
  width: "100%",
  height: 40,
  wordBreak: "keep-all",
  borderTop: `1px solid ${theme.palette["Secondary/Shades"].p12}`,
  borderBottom: `1px solid ${theme.palette["Secondary/Shades"].p12}`,
  "& .MuiTableCell-root": {
    borderColor: theme.palette["Secondary/Shades"].p12,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  width: "100%",
  height: 56,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  borderBottom: theme.palette["Secondary/Shades"].p12,
  "& .MuiTableCell-root": {
    borderColor: theme.palette["Secondary/Shades"].p12,
  },
}));

export type UsagesProps = {
  rows: UsageTableRowProps[];
  selectBox: SelectBoxProps;
  pagination: Pick<TablePaginationProps, "page" | "rowsPerPage" | "count" | "onPageChange" | "rowsPerPageOptions">;
};

const Usages: React.FC<UsagesProps> = props => {
  const { t } = useTranslation();

  const isEmpty = props.rows.length === 0;
  const EmptyTableRow = (
    <StyledTableRow>
      <TableCell colSpan={4}>
        <Typography variant="body2" noWrap sx={{ textAlign: "center", color: "text.secondary" }}>
          {t("現在、表示可能な使用量はありません。")}
        </Typography>
      </TableCell>
    </StyledTableRow>
  );
  return (
    <Box>
      <HeadingWrapper mb={1.5}>
        <Heading>{t("使用量")}</Heading>
        <SelectBox {...props.selectBox} />
      </HeadingWrapper>
      <TableContainer>
        <Table>
          <TableHead>
            <StyledTableHeaderRow key={"score-table-header-row"}>
              <StyledTableHeaderCell align="left">{t("金額（税抜）")}</StyledTableHeaderCell>
              <StyledTableHeaderCell align="left">{t("テストID")}</StyledTableHeaderCell>
              <StyledTableHeaderCell align="left">{t("利用日")}</StyledTableHeaderCell>
              <StyledTableHeaderCell align="left">{t("インタビュータイプ")}</StyledTableHeaderCell>
            </StyledTableHeaderRow>
          </TableHead>
          <TableBody>
            {props.rows.map(usageTableRow => (
              <UsageTableRow key={usageTableRow.testId} {...usageTableRow} />
            ))}
            {isEmpty && EmptyTableRow}
          </TableBody>
        </Table>
        <Box display="flex" justifyContent="flex-end" mt={4}>
          <TablePagination component={"div"} {...props.pagination} />
        </Box>
      </TableContainer>
    </Box>
  );
};

Usages.displayName = "Usages";

export default Usages;
