import { useMethods } from "@hireroo/react-split";
import * as React from "react";

export const SplitParams = {
  splitId: "AlgorithmQuestionDetail",
  Contents: {
    QuestionAndAnswer: {
      id: "QuestionAndAnswer",
    },
    Information: {
      id: "Information",
    },
  },
};

export const useSplitEvent = () => {
  const methods = useMethods();
  const [sidebarVisibleStatus, setSidebarVisibleStatus] = React.useState<"MINIMIZED" | "OPEN">("OPEN");
  const [informationVisibleStatus, setInformationVisibleStatus] = React.useState<"MINIMIZED" | "OPEN">("OPEN");

  React.useEffect(() => {
    const cleanup = methods.subscribeChangeEvent(SplitParams.splitId, event => {
      if (event.type === "MINIMIZED") {
        if (event.contentId === SplitParams.Contents.QuestionAndAnswer.id) {
          setSidebarVisibleStatus("MINIMIZED");
        }
        if (event.contentId === SplitParams.Contents.Information.id) {
          setInformationVisibleStatus("MINIMIZED");
        }
        return;
      }
      if (event.type === "RELEASE_MINIMIZED") {
        if (event.contentId === SplitParams.Contents.QuestionAndAnswer.id) {
          setSidebarVisibleStatus("OPEN");
        }
        if (event.contentId === SplitParams.Contents.Information.id) {
          setInformationVisibleStatus("OPEN");
        }
        return;
      }
    });
    return () => {
      cleanup();
    };
  }, [methods]);

  return {
    sidebarVisibleStatus,
    setSidebarVisibleStatus,
    setInformationVisibleStatus,
    informationVisibleStatus,
  };
};
