import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type CompanyProfileSettingsContainerProps = {};

const CompanyEmployeeSettingsContainer: React.FC<CompanyProfileSettingsContainerProps> = () => {
  const companyProfileSettingsProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Pages.CompanySettings {...companyProfileSettingsProps} />
    </ErrorBoundary>
  );
};

CompanyEmployeeSettingsContainer.displayName = "CompanyEmployeeSettingsContainer";

export default withErrorBoundary(CompanyEmployeeSettingsContainer, {});
