import { secondsToTimeObject } from "@hireroo/formatter/time";
import * as React from "react";
import { useSnapshot } from "valtio";

import * as Helper from "./privateHelper";
import { state } from "./State";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = (): boolean => {
  const snapshot = useSnapshotState();
  return !!snapshot.companySpotStats;
};

export const useSelectedScreeningId = () => {
  const snapshot = useSnapshotState();
  return snapshot.selectedScreeningId;
};

export const useScreeningInitialized = () => {
  const snapshot = useSnapshotState();
  return !!snapshot.selectedScreening;
};

export const useSelectedScreening = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.selectedScreening) {
    throw new Error("Selected screening not initialized");
  }
  return snapshot.selectedScreening;
};

export const useScreenings = () => {
  const snapshot = useSnapshotState();
  return Array.from(snapshot.screeningMap.values());
};

export const useCompanySpotStats = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.companySpotStats) {
    throw new Error("Company spot stats not initialized");
  }
  return snapshot.companySpotStats;
};

export const useSelectedTotalSpotCount = (): number | undefined => {
  const snapshot = useSnapshotState();
  return React.useMemo(() => {
    if (!snapshot.selectedScreening || !snapshot.selectedScreening.spotStatistics) {
      return undefined;
    }
    if (!snapshot.selectedScreening.spotStatistics.spotCountByStatus) {
      return 0;
    }
    return (
      snapshot.selectedScreening.spotStatistics.spotCountByStatus.created +
      snapshot.selectedScreening.spotStatistics.spotCountByStatus.started +
      snapshot.selectedScreening.spotStatistics.spotCountByStatus.completed +
      snapshot.selectedScreening.spotStatistics.spotCountByStatus.finalized +
      snapshot.selectedScreening.spotStatistics.spotCountByStatus.evaluated +
      snapshot.selectedScreening.spotStatistics.spotCountByStatus.expired
    );
  }, [snapshot.selectedScreening]);
};
export const useCompanyReducedHours = (): number => {
  const snapshot = useSnapshotState();
  const spotCountByResult = snapshot.companySpotStats?.spotCountByResult;
  if (!spotCountByResult) {
    return 0;
  }
  const notPassedNum = Helper.calculateNotPassedNumFromSpotCountByResult(spotCountByResult);
  return Helper.calculateReducedHours({
    notPassedNum,
    // TODO: change using data
    testHours: 0.5,
  });
};

export const useSelectedScreeningReducedHours = (): number | undefined => {
  const selectedScreening = useSelectedScreening();
  if (!selectedScreening?.spotStatistics?.spotCountByResult || !Helper.isEnoughSpotData(selectedScreening.spotStatistics.spotCountByResult)) {
    return undefined;
  }
  const notPassedNum = Helper.calculateNotPassedNumFromSpotCountByResult(selectedScreening.spotStatistics.spotCountByResult);
  return Helper.calculateReducedHours({
    notPassedNum,
    testHours: secondsToTimeObject(selectedScreening.timeLimitSeconds, "HOURS").hours,
  });
};
