import { subscribeKey } from "valtio/utils";

import { state } from "./State";
import * as Types from "./types";

export type SubscribeOnChangeDemoCallback = (spot: Types.Demo) => void;
export const subscribeOnChangeDemo = (callback: SubscribeOnChangeDemoCallback) => {
  return subscribeKey(state, "demo", demo => {
    if (demo) {
      callback(demo);
    }
  });
};
