import { QuestionSelectFieldForResourceEditor } from "@hireroo/app-store/widget/e/QuestionSelectFieldForResourceEditor";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateQuestionSelectFieldV2PropsArgs, useGenerateProps } from "./useGenerateProps";

export type QuestionSelectFieldV2ContainerProps = GenerateQuestionSelectFieldV2PropsArgs;

/**
 * CAUTION: Other components should not imitate this implementation.
 *
 * Data fetching is done in `Subscriber.ts` as this component is displayed in a dialog.
 * Please use Subscriber.ts for the pages needed to make question selections.
 *
 * TODO @Himenon This should be examined to determine if this is an implementation pattern.
 */
const QuestionSelectFieldV2Container: React.FC<QuestionSelectFieldV2ContainerProps> = props => {
  const questionSelectFieldV2Props = useGenerateProps(props);
  React.useEffect(() => {
    return () => {
      QuestionSelectFieldForResourceEditor.clear();
    };
  }, []);
  return (
    <ErrorBoundary>
      <Widget.QuestionSelectFieldV2 {...questionSelectFieldV2Props} />
    </ErrorBoundary>
  );
};

QuestionSelectFieldV2Container.displayName = "QuestionSelectFieldV2Container";

export default withErrorBoundary(QuestionSelectFieldV2Container, {});
