import { useTranslation } from "@hireroo/i18n";
import { Fields } from "@hireroo/validator";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useFieldArray } from "react-hook-form";

import FieldFactory, { FieldFactoryProps } from "./parts/FieldFactory/FieldFactory";

type Field = {
  customFields: (Fields.CustomField.CustomField & { id?: number })[];
};

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.action.disabled}`,
}));

export type CustomFieldsProps = {
  name: string;
};

const CustomFields: React.FC<CustomFieldsProps> = props => {
  const { t } = useTranslation();
  const fieldArray = useFieldArray<Field>({
    /**
     * Name of the field array. Note: Do not support dynamic name.
     * @see https://react-hook-form.com/api/usefieldarray
     */
    name: props.name as "customFields",
  });
  const addFieldButton: ButtonProps = {
    startIcon: <AddCircleOutlineOutlinedIcon />,
    children: t("質問を追加する"),
    variant: "outlined",
    color: "outline-only",
    onClick: () => {
      const value: Fields.CustomField.StringValueField = {
        label: "",
        description: "",
        formType: "INPUT_TEXT",
        valueType: "STRING",
        validationRule: {
          required: false,
          minLength: undefined,
          maxLength: undefined,
        },
      };
      fieldArray.append(value);
    },
  };

  return (
    <Box>
      {fieldArray.fields.map((field, index) => {
        const fieldFactoryProps: FieldFactoryProps = {
          name: `${props.name}.${index}`,
          onDelete: () => {
            fieldArray.remove(index);
          },
          defaultFieldValue: ((): FieldFactoryProps["defaultFieldValue"] => {
            switch (field.formType) {
              case "INPUT_NUMBER":
                return `${field.formType}-${field.valueType}` as const;
              case "INPUT_TEXT":
              case "TEXTAREA":
                return `${field.formType}-${field.valueType}` as const;
              default:
                throw new Error(`Form type is unknown: ${field satisfies never}`);
            }
          })(),
        };
        return <FieldFactory {...fieldFactoryProps} key={field.id} />;
      })}
      <Box sx={{ textAlign: "center" }}>
        <StyledButton {...addFieldButton} />
      </Box>
    </Box>
  );
};

CustomFields.displayName = "CustomFields";

export default CustomFields;
