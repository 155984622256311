import { IYText } from "@jupyter/ydoc";
import {
  CodeMirrorEditorFactory,
  EditorExtensionRegistry,
  EditorLanguageRegistry,
  EditorThemeRegistry,
  ybinding,
} from "@jupyterlab/codemirror";
import { MathJaxTypesetter } from "@jupyterlab/mathjax-extension";
import { RenderMimeRegistry, standardRendererFactories } from "@jupyterlab/rendermime";

import { rendererFactory as plotlyFactory } from "../../../../plotly/PlotlyRenderer";
import { initializeMarked } from "../../../../utils/markdown";

const themes = new EditorThemeRegistry();

const editorExtensions = () => {
  const registry = new EditorExtensionRegistry();
  for (const extensionFactory of EditorExtensionRegistry.getDefaultExtensions({ themes })) {
    registry.addExtension(extensionFactory);
  }
  registry.addExtension({
    name: "shared-model-binding",
    factory: options => {
      const sharedModel = options.model.sharedModel as IYText;
      return EditorExtensionRegistry.createImmutableExtension(
        ybinding({
          ytext: sharedModel.ysource,
          undoManager: sharedModel.undoManager ?? undefined,
        }),
      );
    },
  });
  return registry;
};
const languages = new EditorLanguageRegistry();
for (const language of EditorLanguageRegistry.getDefaultLanguages()) {
  languages.addLanguage(language);
}
languages.addLanguage({
  name: "ipythongfm",
  mime: "text/x-ipythongfm",
  load: async () => {
    // TODO: add support for LaTeX
    const m = await import("@codemirror/lang-markdown");
    return m.markdown({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      codeLanguages: (info: string) => languages.findBest(info) as unknown,
    });
  },
});

const renderFactories = standardRendererFactories.concat(plotlyFactory);

const marked = initializeMarked(languages);

export const rendermime = new RenderMimeRegistry({
  initialFactories: renderFactories,
  latexTypesetter: new MathJaxTypesetter(),
  markdownParser: {
    render: source => {
      return new Promise<string>((resolve, reject) => {
        marked(source, (error, parsedResult) => {
          if (error) {
            reject(error);
          } else {
            resolve(parsedResult);
          }
        });
      });
    },
  },
});

export const factoryService = new CodeMirrorEditorFactory({
  extensions: editorExtensions(),
  languages,
});
