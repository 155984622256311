import * as React from "react";

import ScreeningTestLayout, { ScreeningTestLayoutProps } from "../../layouts/ScreeningTestLayout/ScreeningTestLayout";

export type ScreeningTestProps = {
  layout: Omit<ScreeningTestLayoutProps, "children">;
  Content: React.ReactNode;
};

const ScreeningTest: React.FC<ScreeningTestProps> = props => {
  return <ScreeningTestLayout {...props.layout}>{props.Content}</ScreeningTestLayout>;
};

ScreeningTest.displayName = "ScreeningTest";

export default ScreeningTest;
