import { useSnapshot } from "valtio";

import { state } from "./State";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useParticipants = () => {
  const snapshot = useSnapshotState();
  return Array.from(snapshot.participants.values()).filter(participant => {
    const userInfo = snapshot.userInfoMap.get(participant.id);
    return userInfo?.status === "FETCHED";
  });
};

export const useUserInfoMap = () => {
  const snapshot = useSnapshotState();
  return snapshot.userInfoMap;
};
