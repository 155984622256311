/// <reference types="@hireroo/graphql/types" />

import { getTranslation } from "@hireroo/i18n";
import { GraphQLError } from "graphql";
import { ClientError } from "graphql-request";
import { CombinedError } from "urql";

const generateErrorNotificationByGraphQLErrorExtensions = (errorExtensions: GraphQLError["extensions"]): ErrorNotification | undefined => {
  const { t } = getTranslation();
  switch (errorExtensions.code) {
    case "PERMISSION_DENIED":
      return {
        message: t("この機能を利用する権限がありません。管理者に権限を付与してもらうことで利用可能になります。"),
      };
    case "SERVICE_UNAVAILABLE":
      return {
        message: t("サーバー側で何らかのエラーが発生しています。時間をおいても解決しない場合はお問い合わせください。"),
      };
    case "NOT_FOUND":
    case "ALREADY_EXISTS":
    case "UNAUTHENTICATED":
    case "UNKNOWN":
      break;
    default:
      console.warn(`Extension code is invalid: ${errorExtensions?.code satisfies never}`);
      break;
  }
  return;
};

export type ErrorNotification = {
  message: string;
};

export const generateErrorNotification = (error: Error, defaultMessage: string): ErrorNotification => {
  if (error instanceof GraphQLError) {
    const errorNotification = generateErrorNotificationByGraphQLErrorExtensions(error.extensions);
    if (errorNotification) {
      return errorNotification;
    }
  } else if (error instanceof CombinedError) {
    const firstErrorNotification = error.graphQLErrors
      .map(error => {
        return generateErrorNotificationByGraphQLErrorExtensions(error.extensions);
      })
      .filter(errorNotification => errorNotification !== undefined)
      .at(0);
    if (firstErrorNotification) {
      return firstErrorNotification;
    }
  } else if (error instanceof ClientError) {
    const firstErrorNotification = (error.response.errors || [])
      .map(error => {
        return generateErrorNotificationByGraphQLErrorExtensions(error.extensions);
      })
      .filter(errorNotification => errorNotification !== undefined)
      .at(0);
    if (firstErrorNotification) {
      return firstErrorNotification;
    }
  }
  return {
    message: defaultMessage,
  };
};
