import { App } from "@hireroo/app-store/essential/candidate";
import { DemosIdStore } from "@hireroo/app-store/page/c/demos_id";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";
import { useParams } from "react-router-dom";

import ErrorPanelContainer from "../../../../widget/v2/c/ErrorPanel/Container";
import InterviewContainer, { InterviewContainerProps } from "./Container";

const InterviewFetchContainer: React.FC = () => {
  const appStatus = App.useStatus();
  const { id } = useParams<{ id: string }>();
  const initialized = DemosIdStore.useInitialized();
  const [result] = Graphql.useGetDemoForCandidateDemosIdQuery({
    variables: {
      demoId: id ?? "",
      candidateId: "",
    },
    pause: appStatus !== "INITIALIZED" || initialized,
  });
  React.useEffect(() => {
    if (result.data) {
      DemosIdStore.setDemo(result.data.demo);
    }
  }, [result.data]);
  if (result.error) {
    return <ErrorPanelContainer graphqlErrors={result.error.graphQLErrors} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER" color="secondary" />;
  }
  const containerProps: InterviewContainerProps = {
    demoId: `${id}`,
  };
  return (
    <ErrorBoundary>
      <InterviewContainer {...containerProps} />
    </ErrorBoundary>
  );
};

InterviewFetchContainer.displayName = "InterviewFetchContainer";

export default withErrorBoundary(InterviewFetchContainer, {});
