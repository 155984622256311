import * as React from "react";

import CommonSnackbar, { CommonSnackbarProps } from "./parts/CommonSnackbar/CommonSnackbar";
import LoadingSnackbar, { LoadingSnackbarProps } from "./parts/LoadingSnackbar/LoadingSnackbar";

export type SnackbarProps = {
  common?: CommonSnackbarProps;
  loading?: LoadingSnackbarProps;
};

const Snackbar: React.FC<SnackbarProps> = props => {
  return (
    <>
      {props.common && <CommonSnackbar {...props.common} />}
      {props.loading && <LoadingSnackbar {...props.loading} />}
    </>
  );
};

Snackbar.displayName = "Snackbar";

export default Snackbar;
