import { useTranslation } from "@hireroo/i18n";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DeleteMetricsDialog, { DeleteMetricsDialogProps } from "../../ms-components/Evaluation/DeleteMetricsDialog/DeleteMetricsDialog";
import MetricsGroupsEditorDialog, { MetricsGroupsEditorDialogProps } from "./parts/MetricsGroupsEditorDialog/MetricsGroupsEditorDialog";
import MetricsGroupsHeader, { MetricsGroupsHeaderProps } from "./parts/MetricsGroupsHeader/MetricsGroupsHeader";
import MetricsGroupsTable, { MetricsGroupsTableProps } from "./parts/MetricsGroupsTable/MetricsGroupsTable";

export type EvaluationMetricsGroupsProps = {
  header: MetricsGroupsHeaderProps;
  table: MetricsGroupsTableProps;
  deletesDialog: DeleteMetricsDialogProps;
  createDialog: MetricsGroupsEditorDialogProps;
  metricsButton: Pick<ButtonProps, "onClick">;
};

const EvaluationMetricsGroups: React.FC<EvaluationMetricsGroupsProps> = props => {
  const { t } = useTranslation();
  const metricsButton: ButtonProps = {
    ...props.metricsButton,
    variant: "text",
    size: "small",
    sx: {
      wordBreak: "keep-all",
      textTransform: "none",
    },
    color: "info",
    children: t("評価項目一覧"),
    endIcon: <KeyboardArrowRightOutlinedIcon fontSize="small" />,
  };
  return (
    <Stack direction="column" spacing={1.5}>
      <Stack direction="row" justifyContent="space-between" mb={3} alignItems="center">
        <Typography fontSize={24} fontWeight={700} sx={{ wordBreak: "keep-all" }}>
          {t("評価指標一覧")}
        </Typography>
        <Button {...metricsButton} />
      </Stack>
      <MetricsGroupsHeader {...props.header} />
      <MetricsGroupsTable {...props.table} />
      <DeleteMetricsDialog key={`deletes-dialog-${props.deletesDialog.dialog.open}`} {...props.deletesDialog} />
      <MetricsGroupsEditorDialog key={`create-${props.createDialog.dialog.open}`} {...props.createDialog} />
    </Stack>
  );
};

EvaluationMetricsGroups.displayName = "EvaluationMetricsGroups";

export default EvaluationMetricsGroups;
