import { proxy } from "valtio";
import { proxyMap, proxySet } from "valtio/utils";

import type * as Types from "./types";

export const state = proxy<Types.State>({
  employees: proxyMap(),
  selectedEmployeeIds: proxySet(),

  groups: proxyMap(),
  selectedGroupIds: proxySet(),

  searchText: "",
});
