import { state } from "./State";
import type * as Types from "./types";

export const setCompany = (company: Types.Company): void => {
  state.company = company;
};

export const selectMember = (selected: boolean, memberId: string) => {
  if (selected) {
    state.selectedMemberIds.push(memberId);
  } else {
    state.selectedMemberIds = state.selectedMemberIds.filter(id => id !== memberId);
  }
};

export const clearSelectedMembers = () => {
  state.selectedMemberIds = [];
};

export const clear = () => {
  clearSelectedMembers();
  state.filterText = "";
};

export const EmployeeInvitationAction = {
  setInvitationId: (invitationId: string | null) => {
    state.employeeInvitation.id = invitationId;
  },
  setStatus: (status: Types.InvitationState["status"]) => {
    state.employeeInvitation.status = status;
  },
  clear: () => {
    state.employeeInvitation.id = null;
    state.employeeInvitation.status = "READY";
  },
};

export const TalentInvitationAction = {
  setInvitationId: (invitationId: string | null) => {
    state.talentInvitation.id = invitationId;
  },
  setStatus: (status: Types.InvitationState["status"]) => {
    state.talentInvitation.status = status;
  },
};

export const EditRoleAction = {
  setMemberId: (memberId: string | null) => {
    state.editRole.selectedMemberId = memberId;
  },
  updateRole: (memberId: string, role: Types.Role) => {
    const companyEmployee = state.company?.companyEmployees.find(companyEmployee => memberId === companyEmployee.employeeId);
    if (companyEmployee) {
      companyEmployee.role = role;
    }
  },
  clear: () => {
    state.editRole.selectedMemberId = null;
  },
};

export const DeleteMemberAction = {
  setMemberId: (memberId: string | null) => {
    state.deleteMember.selectedMemberId = memberId;
  },
  clear: () => {
    state.deleteMember.selectedMemberId = null;
  },
};

export const AddMemberToGroupAction = {
  setMemberId: (memberId: string | null): void => {
    state.addMemberToGroups.selectedMemberId = memberId;
  },
  clear: (): void => {
    state.addMemberToGroups.selectedGroupIds = [];
  },
  addId: (groupId: string): void => {
    state.addMemberToGroups.selectedGroupIds.push(groupId);
  },
  removeId: (groupId: string): void => {
    state.addMemberToGroups.selectedGroupIds = state.addMemberToGroups.selectedGroupIds.filter(id => id !== groupId);
  },
  updateSearchText: (searchText: string) => {
    state.addMemberToGroups.searchText = searchText;
  },
};

export const updateDialogStatus = (status: Types.DialogStatus) => {
  state.dialogStatus = status;
};

export const updateFilterText = (filterText: string) => {
  state.filterText = filterText;
};
