import TableCell, { TableCellProps } from "@mui/material/TableCell";
import * as React from "react";

export type DivTableCellProps = TableCellProps;

const DivTableCell: React.FC<DivTableCellProps> = props => {
  return <TableCell {...props} component="div" />;
};

DivTableCell.displayName = "DivTableCell";

export default DivTableCell;
