import type * as Interface from "@hireroo/evaluation-result-interface";
import { styled } from "@mui/material/styles";
import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderColor: theme.palette.Other.Divider,
  fontSize: "14px",
}));

const StyledTable = styled(MuiTable)(() => ({
  border: "none",
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  borderRadius: "4px",
  border: "1px solid",
  borderColor: theme.palette.Other.Divider,
}));

export type TableProps = Interface.Table["value"];

const Table: React.FC<TableProps> = props => {
  return (
    <StyledTableContainer sx={{ borderRadius: "4px", border: "1px solid divider" }}>
      <StyledTable>
        <TableHead>
          <TableRow>
            {props.headers.map((header, index) => (
              <StyledTableCell key={`header-${header}-${index}`}>
                <Typography fontSize={14} fontWeight={700}>
                  {header}
                </Typography>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.bodies.map((row, index) => (
            <TableRow key={`row-${index}`}>
              {row.map((cell, cellIndex) => (
                <StyledTableCell key={`cell-${cell}-${cellIndex}`}>{cell}</StyledTableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
    </StyledTableContainer>
  );
};

Table.displayName = "Table";

export default Table;
