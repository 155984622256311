import Box from "@mui/material/Box";
import * as React from "react";
import { useController } from "react-hook-form";

import BaseMultiChoiceField, {
  MultiChoiceV2FieldProps as BaseMultiChoiceFieldProps,
} from "../../../../primitive/InputControl/MultiChoiceFieldV2/MultiChoiceV2Field";
import { useSearchFormContext } from "../../PrivateContext";
import SplitButton, { SplitButtonProps } from "../SplitButton/SplitButton";

/**
 * The value of the Option that MultiChoice has must be a unique `id`. Other fields are allowed to be extended to be unique.
 *
 * NOTE:
 * It is possible to tightly constrain the type definition using TypeScript's Generics,
 * but since this requires a deep understanding of TypeScript,
 * we only specify `& { [key: string]: unknown }` here to explicitly share the extension.
 */
type FieldValue = {
  id: string;
} & { [key: string]: unknown };

type MultipleChoiceV2Option = {
  value: FieldValue;
  displayName: string;
  iconSrc?: string;
};

export type MultiChoiceV2FieldProps = {
  name: string;
  title: string;
  options: MultipleChoiceV2Option[];
  onChange?: (selectedValues: FieldValue[]) => void;
  disabled?: boolean;
  variant?: SplitButtonProps["variant"];
};

/**
 * TODO rename MultiChoiceObjectValueField
 */
const MultiChoiceV2Field: React.FC<MultiChoiceV2FieldProps> = props => {
  const { onChange, options } = props;
  const { subscribeClearAllFields: onClearFields } = useSearchFormContext();
  const { field } = useController<Record<string, FieldValue[]>>({
    name: props.name,
  });
  const [selectedValue, setSelectedValue] = React.useState<FieldValue[]>(field.value);
  const [resetCount, setResetCount] = React.useState(0);

  const selectedDisplayNames = options.reduce<string[]>((all, option) => {
    const index = selectedValue.findIndex(selected => selected.id === option.value.id);
    if (index < 0) {
      return all;
    }
    return all.concat(option.displayName);
  }, []);

  const resetField = React.useCallback(() => {
    setSelectedValue([]);
    onChange?.([]);
    field.onChange([]);
    setResetCount(prev => prev + 1);
  }, [field, onChange]);

  React.useEffect(() => {
    const stop = onClearFields(() => {
      setSelectedValue([]);
      onChange?.([]);
      setResetCount(prev => prev + 1);
    });
    return () => {
      stop();
    };
  }, [onChange, onClearFields]);

  const splitButtonProps: SplitButtonProps = {
    title: props.title,
    disabled: props.disabled,
    status: selectedValue.length > 0 ? "ACTIVE" : "INACTIVE",
    label: {
      children: selectedValue.length === 0 ? props.title : [props.title, selectedDisplayNames.join(", ")].join(": "),
    },
    onApply: () => {
      props.onChange?.(selectedValue);
      field.onChange(selectedValue);
    },
    onReset: () => {
      resetField();
    },
    variant: props.variant,
  };

  const baseMultiChoiceFieldProps: BaseMultiChoiceFieldProps = {
    name: props.name,
    options: props.options,
    onChange: value => {
      setSelectedValue(value);
    },
    disabled: props.disabled,
    watch: false,
  };

  return (
    <SplitButton {...splitButtonProps}>
      <Box sx={{ maxHeight: 250, overflow: "auto" }}>
        <BaseMultiChoiceField key={resetCount.toString()} {...baseMultiChoiceFieldProps} />
      </Box>
    </SplitButton>
  );
};

MultiChoiceV2Field.displayName = "MultiChoiceV2Field";

export default MultiChoiceV2Field;
