import * as Graphql from "@hireroo/graphql/client/urql";

export type Creator = Graphql.GetInterviewsForCreatorFragment;
export type SpotTags = Graphql.GetInterviewsForSpotTagsByCompanyIdResponseFragment;
export type ScreeningVariables = Graphql.GetInterviewsForScreeningVariablesByCompanyIdResponseFragment;
export type Filters = Graphql.SpotsByCompanyIdFilters;
export type Spot = Graphql.SpotForEmployeeInterviewsFragment;
export type SpotsByCompanyIdResponse = Graphql.SpotsByCompanyIdResponseForInterviewFragment;

export type ExportFileType = Graphql.ExportFileType;

export type DownloadStatus = "READY" | "PENDING" | "FAILED" | "SUCCESS";

export type ExportField = { name: string; value: string };

export type DownloadState = {
  open: boolean;
  status: DownloadStatus;
  fields: ExportField[];
  downloadId: string | null;
  exportFileType: ExportFileType;
  downloadUrl: string | null;
};

export type ListFetchingStatus = "STOP" | "FETCHING" | "FETCHED";
export type SpotId = string;
export type ScreeningId = string;
export type DialogStatus = "DELETES_OPEN" | "CLOSE";
export type Screening = Graphql.ScreeningForScreeningsTestsFragment;

export type State = {
  spotTags: SpotTags | null;
  screeningVariables: ScreeningVariables | null;
  searchTags: string[];
  listParams: ListParams;
  count: number;
  creators: Creator[];
  download: DownloadState;
  listFetchingStatus: ListFetchingStatus;
  initialized: boolean;
  screeningMap: Map<ScreeningId, Screening>;
  res: SpotsByCompanyIdResponse | null;
  spotMap: Map<SpotId, Spot>;
  selectedSpotIds: SpotId[];
  tagRefresh: (() => void) | null;
  dialogStatus: DialogStatus;
  /**
   * Random string to force refresh the list
   */
  refreshKey: string;
};

export type ListParams = {
  isDescending: boolean;
  page: number;
  size: number;
  sortMethod: Graphql.SpotsByCompanyIdSortMethod;
  filters: Graphql.SpotsByCompanyIdFilters;
};

export const QueryKeys = {
  PAGE: "page",
  SIZE: "size",
  TITLE: "title",
  STATUS: "status",
  RESULT: "result",
  ISSUER: "issuer",
  TAG: "tag",
  RANK_EVALUATION: "rank",
  MIN_SCORE: "minScore",
  MAX_SCORE: "maxScore",
  SORT: "sorting",
  SCREENING_IDS: "screeningIds",
  SUSPICIOUS_DEGREES: "suspiciousDegrees",
} as const;

export type QueryType = (typeof QueryKeys)[keyof typeof QueryKeys];
