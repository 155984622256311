import { useTwilioConversationsContext } from "@hireroo/app-helper/hooks";
import { App, Auth } from "@hireroo/app-store/essential/employee";
import { RemotesId } from "@hireroo/app-store/page/e/remotes_id";
import { LiveCodingChatWindow } from "@hireroo/app-store/widget/e/LiveCodingChatWindow";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import LiveCodingChatWindowContainer, { LiveCodingChatWindowContainerProps } from "./Container";
import * as PrivateHelper from "./privateHelper";

export type LiveCodingChatWindowFetchContainerProps = {};

const LiveCodingChatWindowFetchContainer: React.FC<LiveCodingChatWindowFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const liveCodingId = RemotesId.useLiveCodingId();
  const uid = Auth.useCurrentUid();
  const user = RemotesId.useEmployeeUser(uid ?? "");
  const { start, status } = PrivateHelper.useStartVideoChatConversation({
    liveCodingId: liveCodingId,
    displayName: user.userName,
  });
  const { messages } = useTwilioConversationsContext();
  const conversationId = RemotesId.useLiveCodingVideoChatConversationId();

  // Start video chat conversation
  React.useEffect(() => {
    if (status === "STOP") {
      start();
    }
  }, [start, status]);

  const [result, refresh] = Graphql.useGetVideoChatConversationForEmployeeQuery({
    variables: {
      input: {
        conversationId: conversationId,
      },
    },
    requestPolicy: "network-only",
    pause: !conversationId,
  });

  // fetch conversation when messages are updated
  React.useEffect(() => {
    refresh();
  }, [messages, refresh]);

  // update participants
  React.useEffect(() => {
    if (result.error) {
      return;
    }
    const conversation = result.data?.videoChatConversation;
    if (!conversation) {
      return;
    }
    const participants = conversation.participants.map((participant): LiveCodingChatWindow.Participant => {
      return {
        id: participant.userId,
        displayName: participant.displayName,
        src: null,
      };
    });
    LiveCodingChatWindow.updateParticipants(participants);
  }, [result]);

  if (appStatus !== "INITIALIZED") {
    return <Widget.Loading kind="TOP" color="secondary" />;
  }

  if (status === "FAILED") {
    return <Widget.RefreshPanel refresh={start} />;
  }

  if (status !== "CONNECTED") {
    return <Widget.Loading kind="CENTER_%" color="secondary" />;
  }

  const containerProps: LiveCodingChatWindowContainerProps = {};

  return (
    <Widget.LiveCodingChatWindowProvider>
      <LiveCodingChatWindowContainer {...containerProps} />
    </Widget.LiveCodingChatWindowProvider>
  );
};

LiveCodingChatWindowFetchContainer.displayName = "LiveCodingChatWindowFetchContainer";

export default withErrorBoundary(LiveCodingChatWindowFetchContainer, {});
