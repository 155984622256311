import * as z from "zod";

export const useEmployeeGroupAssignee = () => {
  return z.object({
    type: z.literal("EMPLOYEE_GROUP"),
    groupId: z.string(),
    locked: z.boolean().optional(),
  });
};

export type EmployeeGroupAssigneeSchema = z.infer<ReturnType<typeof useEmployeeGroupAssignee>>;

export const useEmployeeAssignee = () => {
  return z.object({
    type: z.literal("EMPLOYEE"),
    employeeId: z.string(),
    locked: z.boolean().optional(),
  });
};

export type EmployeeAssigneeSchema = z.infer<ReturnType<typeof useEmployeeAssignee>>;

export const useTalentAssignee = () => {
  return z.object({
    type: z.literal("TALENT"),
    talentId: z.string(),
    locked: z.boolean().optional(),
  });
};

export type TalentAssigneeSchema = z.infer<ReturnType<typeof useTalentAssignee>>;

export const useAssigneeSchema = () => {
  const employeeGroupAssignee = useEmployeeGroupAssignee();
  const employeeAssignee = useEmployeeAssignee();
  const talentAssignee = useTalentAssignee();
  return z.union([employeeGroupAssignee, employeeAssignee, talentAssignee, z.null()]);
};

export type AssigneeSchema = z.infer<ReturnType<typeof useAssigneeSchema>>;

export type AssignableType = Exclude<AssigneeSchema, null>["type"];

export const useAssignListItem = () => {
  const target = useAssigneeSchema();
  return z.object({
    value: target,
  });
};

export type AssignListItemSchema = z.infer<ReturnType<typeof useAssignListItem>>;
