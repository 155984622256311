import { getTimeUnitText, Unit } from "@hireroo/formatter/time";
import { useTheme } from "@mui/material/styles";
import * as React from "react";
import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import type { Formatter } from "recharts/types/component/DefaultTooltipContent";

import * as Def from "../../definition";

export type Data = {
  p50: number;
  p90: number;
  p95: number;
  p99: number;
};

export type LatencyGraphProps = {
  syncId?: string;
  data: Data[];
};

const LatencyGraph: React.FC<LatencyGraphProps> = props => {
  const theme = useTheme();

  // FIXME Too difficult
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const formatter: Formatter<number, keyof Data> = (v, n, p) => {
    let cum = v;
    const percentileMap: Data = {
      p50: 50,
      p90: 90,
      p95: 95,
      p99: 99,
    };

    if (percentileMap[n] > 50) {
      cum += p.payload.p50;
    }
    if (percentileMap[n] > 90) {
      cum += p.payload.p90;
    }
    if (percentileMap[n] > 95) {
      cum += p.payload.p95;
    }
    if (percentileMap[n] > 99) {
      cum += p.payload.p99;
    }

    // if cum >= 1000, convert it into a second
    return [getTimeUnitText(cum, Unit.MILLISECOND), n];
  };
  return (
    <ResponsiveContainer>
      <AreaChart data={props.data} syncId={props.syncId}>
        <CartesianGrid stroke={theme.palette.text.disabled} strokeDasharray="3 3" vertical={false} />
        <XAxis
          tickFormatter={(_v: unknown, i: number) => {
            return `t${i + 1}`;
          }}
          tick={{ fill: theme.palette.text.secondary }}
          tickLine={{ stroke: theme.palette.text.secondary }}
          axisLine={{ stroke: theme.palette.text.secondary }}
        />
        <YAxis
          tick={{ fill: theme.palette.text.secondary }}
          tickLine={{ stroke: theme.palette.text.secondary }}
          axisLine={{ stroke: theme.palette.text.secondary }}
          tickFormatter={value => getTimeUnitText(value, Unit.MILLISECOND)}
        />
        <Area
          type={"linear"}
          dataKey={"p50"}
          stroke={Def.GrafanaLatencyColorMap["p50"]}
          strokeWidth={2}
          fillOpacity={0.1}
          fill={Def.GrafanaLatencyColorMap["p50"]}
          stackId={"latency chart"}
          isAnimationActive={false}
        />
        <Area
          type={"linear"}
          dataKey={"p90"}
          stroke={Def.GrafanaLatencyColorMap["p90"]}
          strokeWidth={2}
          fill={Def.GrafanaLatencyColorMap["p90"]}
          fillOpacity={0.1}
          stackId={"latency chart"}
          isAnimationActive={false}
        />
        <Area
          type={"linear"}
          dataKey={"p95"}
          stroke={Def.GrafanaLatencyColorMap["p95"]}
          strokeWidth={2}
          fill={Def.GrafanaLatencyColorMap["p95"]}
          fillOpacity={0.1}
          stackId={"latency chart"}
          isAnimationActive={false}
        />
        <Area
          type={"linear"}
          dataKey={"p99"}
          stroke={Def.GrafanaLatencyColorMap["p99"]}
          strokeWidth={2}
          fill={Def.GrafanaLatencyColorMap["p99"]}
          fillOpacity={0.1}
          stackId={"latency chart"}
          isAnimationActive={false}
        />
        <Tooltip
          contentStyle={{
            borderColor: theme.palette.background.paper,
            backgroundColor: theme.palette.background.paper,
          }}
          formatter={formatter}
          labelFormatter={v => {
            return `t${Number(v) + 1}`;
          }}
          labelStyle={{
            color: theme.palette.text.secondary,
          }}
        />
        <Legend
          align={"left"}
          iconType={"square"}
          iconSize={12}
          wrapperStyle={{
            paddingLeft: 50,
            fontSize: 14,
          }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

LatencyGraph.displayName = "LatencyGraph";

export default LatencyGraph;
