import { Auth, Payment } from "@hireroo/app-store/essential/employee";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { currentWindowMatchPath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";

export type GenerateNotificationBannerPropsArgs = {};

export const useGenerateProps = (_args: GenerateNotificationBannerPropsArgs): Widget.NotificationBannerProps | null => {
  const { t } = useTranslation();
  const customer = Payment.useOptionalCustomerV2();
  const currentUser = Auth.useCurrentUser();
  const navigate = useTransitionNavigate();

  if (!currentUser.emailVerified && currentUser.provider === "PASSWORD" && !currentWindowMatchPath("/e/settings/user")) {
    return {
      severity: "warning",
      message: [t("メールアドレスの認証が必要です。"), t("もしメールが見当たらない場合は個人設定から再送してください。")].join(" "),
      actionButton: {
        children: t("個人設定へ"),
        onClick: () => {
          navigate("/e/settings/user", {
            queryParams: {
              tab: "general",
            },
          });
        },
      },
    };
  }

  if (!customer) {
    return null;
  }
  if (customer.isTrial) {
    return null;
  }
  if (customer.paymentStatus === "UNPAID") {
    return {
      severity: "warning",
      message: [t("現在、お支払いが確認できておりません。"), t("お支払い方法に不備がないかご確認ください。")].join(""),
      actionButton: {
        href: generatePath("/e/settings/payment/method/update"),
        onClick: () => {
          navigate("/e/settings/payment/method/update");
        },
        children: t("確認"),
      },
    };
  }
  if (customer.paymentStatus === "SUSPENDED") {
    return {
      severity: "warning",
      message: [t("現在、お支払いが確認できておりません。"), t("2週間以上この状態が続くとサービスがご利用できなくなります。")].join(""),
      actionButton: {
        href: generatePath("/e/settings/payment/method/update"),
        onClick: () => {
          navigate("/e/settings/payment/method/update");
        },
        children: t("確認"),
      },
    };
  }
  return null;
};
