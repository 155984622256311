import { useTranslation } from "@hireroo/i18n";
import SearchIcon from "@mui/icons-material/Search";
import SettingsBackupRestoreRoundedIcon from "@mui/icons-material/SettingsBackupRestoreRounded";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import escapeStringRegexp from "escape-string-regexp";
import * as React from "react";

export type TestTagSelectMenuProps = {
  className?: string;
  selected: string[];
  onSelect: (tagName: string) => void;
  onReset: () => void;
  onClose?: () => void;
  enableLabel?: boolean;
  tagNames: string[];
  disabled?: boolean;
};

const TestTagSelectMenu: React.FC<TestTagSelectMenuProps> = props => {
  const { t } = useTranslation();
  const [text, setText] = React.useState<string>("");

  const hitTagNames = React.useMemo(() => {
    return props.tagNames.filter(tagName => {
      const reg = new RegExp(escapeStringRegexp(text));
      return reg.test(tagName);
    });
  }, [props.tagNames, text]);

  return (
    <FormControl size="small" sx={{ mr: 2 }}>
      {props.enableLabel && <InputLabel>{t("タグ")}</InputLabel>}
      <Select
        id="tag-search-selector"
        label={props.enableLabel ? "Age" : undefined}
        autoWidth
        className={props.className}
        size="small"
        color="secondary"
        disabled={props.disabled}
        displayEmpty
        // We should specify multiple flag, otherwise the menu disappear every time you click on it,
        // and accordingly we have to set empty array to the value
        multiple
        onClose={props.onClose}
        // To fix the label
        value={["dummy"]}
        renderValue={() => {
          return (
            <Box display={"flex"} flexDirection={"row"} gap={1}>
              <Typography mt={0.2}>{`${t("タグ").toString()}`}</Typography>
              {props.selected.length > 0 && <Chip label={props.selected.length} size="small" />}
            </Box>
          );
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
      >
        <Box mx={2} mt={1} width={400}>
          <TextField
            fullWidth
            placeholder={t("タグを検索")}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => {
              setText(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="disabled" />
                </InputAdornment>
              ),
            }}
            size={"small"}
          />
          {hitTagNames.length > 0 ? (
            <Stack direction={"row"} sx={{ flexWrap: "wrap", gap: "8px", mt: 2 }}>
              {hitTagNames.map(tagName => (
                <Chip
                  label={tagName}
                  key={`tag-chip-${tagName}`}
                  onClick={() => {
                    props.onSelect(tagName);
                  }}
                  color={props.selected.includes(tagName) ? "secondary" : "default"}
                />
              ))}
            </Stack>
          ) : (
            <Typography sx={{ mt: 2 }}>{t("検索条件に該当するタグがありません。")}</Typography>
          )}

          <Divider sx={{ my: 2 }} />
          <Box display={"flex"} justifyContent={"flex-end"}>
            <Button
              size="small"
              children={<Box mt={0.3}>{t("全てのタグをリセット").toString()}</Box>}
              sx={{ color: "text.secondary" }}
              onClick={props.onReset}
              startIcon={<SettingsBackupRestoreRoundedIcon />}
            />
          </Box>
        </Box>
      </Select>
    </FormControl>
  );
};

TestTagSelectMenu.displayName = "TestTagSelectMenu";

export default TestTagSelectMenu;
