import { AssessmentsIdDetailStore } from "@hireroo/app-store/page/e/assessments_id_detail";
import { formatScore } from "@hireroo/formatter/score";
import * as Time from "@hireroo/formatter/time";
import { Widget } from "@hireroo/presentation";
import * as React from "react";

import { useFetchListExamsRecursive } from "./useFetchListExamsRecursive";

type RetriedSelector = Exclude<Widget.AssessmentDetailForEmployeeProps["retriedSelector"], undefined>;
type Option = RetriedSelector["options"][0];

export const useGenerateRetriedSelectorProps = (): Widget.AssessmentDetailForEmployeeProps["retriedSelector"] => {
  const currentOriginExamId = AssessmentsIdDetailStore.useCurrentOriginExamId();
  const selectedExamId = AssessmentsIdDetailStore.useSelectedExamId();
  const res = useFetchListExamsRecursive({ examId: currentOriginExamId });

  const options = React.useMemo(() => {
    return (res.data?.exams ?? []).map((exam): Option => {
      return {
        id: exam.examId,
        displayText: `${exam.createdAtSeconds ? Time.unixToDateFormat(exam.createdAtSeconds) : ""}`,
        selected: exam.examId === selectedExamId,
        score: exam.status === "FINALIZED" ? formatScore(exam.relativeScore) : undefined,
        isBest: exam.examId === res.data?.bestExamId,
        onClick: () => {
          AssessmentsIdDetailStore.updateSelectedExamId(exam.examId);
        },
      };
    });
  }, [res.data?.bestExamId, res.data?.exams, selectedExamId]);

  if (res.isLoading || options.length === 0) return undefined;

  return {
    options: options,
    retriedCount: res.data?.exams.length ?? 0,
  };
};
