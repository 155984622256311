import { SupportLanguage } from "@hireroo/i18n";
import * as z from "zod";

export const Variant = z.union([z.literal("SINGLE_CHOICE"), z.literal("MULTI_CHOICE"), z.literal("FREE_TEXT"), z.literal("UNKNOWN")]);

export const QuestionStatus = z.union([
  z.literal("UNKNOWN"),
  z.literal("DRAFT"),
  z.literal("REVIEWING"),
  z.literal("PUBLISHED"),
  z.literal("REJECTED"),
  z.literal("ARCHIVED"),
]);

export const MetricType = z.union([
  z.literal("FRONTEND"),
  z.literal("BACKEND"),
  z.literal("MOBILE"),
  z.literal("MACHINE_LEARNING"),
  z.literal("INFRASTRUCTURE"),
  z.literal("SECURITY"),
  z.literal("COMPUTE_SCIENCE"),
  z.literal("OPERATING_SYSTEM"),
  z.literal("PROJECT_MANAGEMENT"),
  z.literal("OTHER"),
  z.literal("UNKNOWN"),
]);

export const Difficulty = z.union([z.literal("UNKNOWN"), z.literal("EASY"), z.literal("MEDIUM"), z.literal("DIFFICULT")]);

export const SelectedLanguages = z.array(
  z.object({
    value: z.union([z.literal(SupportLanguage.EN), z.literal(SupportLanguage.JA)]),
  }),
);
