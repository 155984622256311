import { useTranslation } from "@hireroo/i18n";
import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type AlgorithmClassInputProps = {
  consoleValue: string[];
  errorCode?: string;
  readonly?: boolean;
  inputStyle?: React.CSSProperties;
  consoleValueChange: (index: number, text: string, type?: string) => void;
};

const StyledInput = styled("input")(({ theme }) => ({
  color: theme.palette.text.primary,
  borderRadius: "4px",
  border: "none",
  fontFamily: 'Menlo, Monaco, "Courier New", monospace',
  paddingLeft: "8px",
  outline: "none",
  height: "36px",
}));

const AlgorithmClassInput: React.FC<AlgorithmClassInputProps> = props => {
  const { t } = useTranslation();
  const { consoleValueChange } = props;

  const errorMessageMap: Record<string, string> = {
    INVALID_INPUT_LENGTH_IS_NOT_TWO: t("入力値は必ず2つ入力されている必要があります。"),
    INVALID_INPUT_LENGTH_IS_NOT_SAME: t("入力値の数がメソッド名と引数で異なります。"),
    INVALID_INPUT_METHOD_NAME_NOT_FOUND: t("型定義に存在しないメソッド名が入力されています。"),
    INVALID_INPUT_FIRST_METHOD_INPUT_SHOULD_BE_CLASS_NAME: t("メソッドは必ず最初にコンストラクタである必要があります。"),
    INVALID_INPUT_VALUE_HAS_NOT_SUPPORTED_TYPE: t("型情報と異なる入力値が入力されています。"),
    VALID_INPUT: "Valid input",
  };

  return (
    <Box>
      {/* For methods input */}
      <Stack spacing={1}>
        <Typography variant="subtitle2" sx={{ wordBreak: "keep-all", whiteSpace: "nowrap" }}>
          Methods
        </Typography>
        <StyledInput
          sx={{
            ...props.inputStyle,
          }}
          readOnly={props.readonly}
          value={props.consoleValue?.[0]}
          onChange={e => consoleValueChange(0, e.target.value)}
        />
      </Stack>

      {/* For values input */}
      <Stack spacing={1}>
        <Typography variant="subtitle2" sx={{ wordBreak: "keep-all", whiteSpace: "nowrap" }}>
          Values
        </Typography>
        <StyledInput
          sx={{
            ...props.inputStyle,
          }}
          readOnly={props.readonly}
          value={props.consoleValue?.[1]}
          onChange={e => consoleValueChange(1, e.target.value)}
        />
      </Stack>

      <FormHelperText error={props.errorCode ? true : false} children={props.errorCode ? errorMessageMap[props.errorCode] : ""} />
    </Box>
  );
};

AlgorithmClassInput.displayName = "AlgorithmClassInput";

export default AlgorithmClassInput;
