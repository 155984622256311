import { RemotesId } from "@hireroo/app-store/page/c/remotes_id";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Pages } from "@hireroo/presentation";
import { generateHelpCenterUrl } from "@hireroo/router/api";
import * as Sentry from "@sentry/react";
import * as React from "react";

import CandidateFooterContainer from "../../../../../../widget/v2/c/CandidateFooter/Container";
import SnackbarContainer from "../../../../../../widget/v2/shared/Snackbar/Container";
import RemoteInterviewCandidateHeaderContainer from "../RemoteInterviewCandidateHeader/Container";

export type GenerateEntryRemoteInterviewPropsArgs = {};

export const useGenerateProps = (_args: GenerateEntryRemoteInterviewPropsArgs): Pages.EntryRemoteInterviewProps => {
  const client = getGraphqlClient();
  const lang = useLanguageCode();
  const interviewName = RemotesId.useInterviewName();
  const companyName = RemotesId.useCompanyName();
  const liveCodingId = RemotesId.useLiveCodingId();
  const { t } = useTranslation();
  const [status, setStatus] = React.useState<"READY" | "PENDING">("READY");

  return {
    layout: {
      loading: status === "PENDING",
      NotificationBanner: null,
      Header: <RemoteInterviewCandidateHeaderContainer mode="WAITING_ROOM" />,
      Footer: <CandidateFooterContainer />,
      Snackbar: <SnackbarContainer />,
      Tutorial: <></>,
    },
    entryInterviewOverviewSection: {
      interviewName: interviewName ?? "",
      companyName: companyName ?? t("会社名不明"),
    },
    onboarding: {
      tosUrl: generateHelpCenterUrl(lang, "TOS_FOR_CANDIDATE"),
    },
    confirmForm: {
      loading: status === "PENDING",
      onSubmit: fields => {
        setStatus("PENDING");
        client
          .JoinLiveCodingForCandidate({
            input: {
              liveCodingId: liveCodingId,
              userName: fields.name,
            },
          })
          .then(res => {
            RemotesId.updateLiveCoding(res.joinLiveCoding);
            Snackbar.notify({
              severity: "success",
              message: t("インタビューに参加しました。"),
            });
          })
          .catch(error => {
            Snackbar.notify({
              severity: "error",
              message: t("インタビューに参加できませんでした。"),
            });
            Sentry.captureException(error);
          })
          .finally(() => {
            setStatus("READY");
          });
      },
    },
  };
};
