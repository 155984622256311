import { useTranslation } from "@hireroo/i18n";
import type { SelectChangeEvent } from "@mui/material";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import React from "react";

import { LabelQueue, NodeElement, OPERATION_TYPE, OperationType, QUEUE_TYPE, QueueType, UnionSettingsFields } from "../../../../../features";

export type QueueSettingsFormProps = {
  element: NodeElement<LabelQueue>;
  updateSettings: (id: string, updates: UnionSettingsFields, operationType: OperationType) => void;
};

export const QueueSettingsForm: React.FC<QueueSettingsFormProps> = (props: QueueSettingsFormProps) => {
  const { t } = useTranslation();

  const handleSelectQueueType = (e: SelectChangeEvent<unknown>) => {
    props.updateSettings(props.element.id, { queueType: e.target.value as QueueType }, OPERATION_TYPE.do);
  };

  return (
    <Box display={"flex"} flexDirection={"column"} mt={2}>
      <Typography>{t("キュータイプ")}</Typography>
      <Box display={"flex"} flexDirection={"column"} mt={1}>
        <Typography variant={"caption"}>{`${t("標準キュー")}: ${t("順序が保証されない")}`}</Typography>
        <Typography variant={"caption"}>{`${t("FIFOキュー")}: ${t("先入れ先出し")}`}</Typography>
      </Box>
      <Box mt={1} />
      <Select size={"small"} variant={"outlined"} onChange={handleSelectQueueType} value={props.element.settings.queueType}>
        <MenuItem value={QUEUE_TYPE.standard}>{t("標準キュー")}</MenuItem>
        <MenuItem value={QUEUE_TYPE.fifo}>{t("FIFOキュー")}</MenuItem>
      </Select>
    </Box>
  );
};

QueueSettingsForm.displayName = "QueueSettingsForm";

export default QueueSettingsForm;
