import { useTranslation } from "@hireroo/i18n";
import { CloseOutlined } from "@mui/icons-material";
import SettingsOutlined from "@mui/icons-material/SettingsOutlined";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import SymbolButton from "../../primitive/Button/SymbolButton/SymbolButton";
import LanguageDropDownMenu, { LanguageDropDownMenuProps } from "../LanguageDropDownMenu/LanguageDropDownMenu";
import SwitchInterfaceField, { SwitchInterfaceFieldProps } from "./parts/SwitchInterfaceField/SwitchInterfaceField";

const StyledMenu = styled(Menu)`
  border-radius: 16px;
`;

export type PreferencesMenuProps = {
  languageDropDownMenu?: LanguageDropDownMenuProps;
  switchInterfaceField?: SwitchInterfaceFieldProps;
};

const PreferencesMenu: React.FC<PreferencesMenuProps> = props => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <SymbolButton onClick={handleClick}>
        <SettingsOutlined color="secondary" />
      </SymbolButton>

      <StyledMenu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Box sx={{ py: "8px", px: "16px", borderBottom: "1px solid grey" }}>
          <Box display="inline-flex" justifyContent="space-between" width="100%" alignItems="center">
            <Typography>{t("設定")}</Typography>
            <IconButton size="small" onClick={handleClose}>
              <CloseOutlined fontSize="small" />
            </IconButton>
          </Box>
        </Box>
        <Box p={2}>
          <Stack spacing={2}>
            {/* Phase 3 */}
            {/* <Box>
              <Box mb={1.5}>
                <Typography>{t("モード")}</Typography>
              </Box>
              <ThemeChangeField />
            </Box> */}
            <Box>
              <Box mb={1.5}>
                <Typography>{t("言語")}</Typography>
              </Box>
              <LanguageDropDownMenu {...props.languageDropDownMenu} />
            </Box>
            {props.switchInterfaceField && (
              <Box>
                <Box mb={1.5}>
                  <Typography>{t("インターフェース")}</Typography>
                </Box>
                <SwitchInterfaceField {...props.switchInterfaceField} />
              </Box>
            )}
          </Stack>
        </Box>
      </StyledMenu>
    </Box>
  );
};

PreferencesMenu.displayName = "PreferencesMenu";

export default PreferencesMenu;
