import type * as Interface from "@hireroo/evaluation-result-interface";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const HEIGHT = 44;

const AREA_WIDTH = 100 / 3;

const GoodArea = styled(Stack)(() => ({
  backgroundColor: "#00BFA5",
  height: `${HEIGHT}px`,
  alignItems: "center",
  justifyContent: "center",
  width: `${AREA_WIDTH}%`,
  borderRadius: "4px 0 0 4px",
}));

const NeedsImprovementArea = styled(Stack)(() => ({
  backgroundColor: "#FFAB00",
  height: `${HEIGHT}px`,
  alignItems: "center",
  justifyContent: "center",
  width: `${AREA_WIDTH}%`,
}));

const PoorArea = styled(Stack)(() => ({
  backgroundColor: "#FF5630",
  height: `${HEIGHT}px`,
  alignItems: "center",
  justifyContent: "center",
  width: `${AREA_WIDTH}%`,
  borderRadius: "0 4px 4px 0",
}));

const StackAreaWrapper = styled(Stack)`
  position: relative;

  &::before {
    content: " ";
    position: absolute;
    top: -14px;
    left: ${({ line }: { line: number }) => line}%;
    border-left: 3px dotted white;
    display: flex;
    height: 58px;
  }

  &::after {
    content: "0.02";
    position: absolute;
    align-items: center;
    background-color: white;
    font-size: 14px;
    color: black;
    top: -24px;
    left: calc(${({ line }: { line: number }) => line}% - 15px);
    display: flex;
    height: 18px;
    border-radius: 2px;
    padding: 2px 4px;
  }
`;

const LabelTypography = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: 700,
}));

export type CumulativeLayoutShiftProps = Interface.CumulativeLayoutShift["value"];

const CumulativeLayoutShift: React.FC<CumulativeLayoutShiftProps> = props => {
  const lineValue = React.useMemo((): number => {
    const min = 0;
    const max = props.poor.end;
    if (props.line.value < min) {
      return 0;
    }
    if (props.line.value > max) {
      return 100;
    }
    return (props.line.value / max) * 100;
  }, [props]);
  return (
    <Box width="100%" pt={2}>
      <StackAreaWrapper direction="row" width="100%" spacing="2px" line={lineValue}>
        <GoodArea direction="row">
          <LabelTypography>GOOD</LabelTypography>
        </GoodArea>
        <NeedsImprovementArea direction="row">
          <LabelTypography>NEEDS IMPROVEMENT</LabelTypography>
        </NeedsImprovementArea>
        <PoorArea direction="row">
          <LabelTypography>POOR</LabelTypography>
        </PoorArea>
      </StackAreaWrapper>
      <Stack direction="row" width="100%" justifyContent="space-between">
        <Box />
        <Typography fontSize={14}>{props.needsImprovement.start}</Typography>
        <Typography fontSize={14}>{props.needsImprovement.end}</Typography>
        <Box />
      </Stack>
    </Box>
  );
};

CumulativeLayoutShift.displayName = "CumulativeLayoutShift";

export default CumulativeLayoutShift;
