import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";

import GroupMultiChoiceField, { GroupMultiChoiceFieldProps } from "../GroupMultiChoiceField/GroupMultiChoiceField";
import InlineTextFilterField, { InlineTextFilterFieldProps } from "../InlineTextFilterField/InlineTextFilterField";
import MultiChoiceField, { MultiChoiceFieldProps } from "../MultiChoiceField/MultiChoiceField";
import MultiChoiceV2Field, { MultiChoiceV2FieldProps } from "../MultiChoiceV2Field/MultiChoiceV2Field";
import RangeField, { RangeFieldProps } from "../RangeField/RangeField";
import SingleChoiceField, { SingleChoiceFieldProps } from "../SingleChoiceField/SingleChoiceField";
import TagSelectField, { TagSelectFieldProps } from "../TagSelectField/TagSelectField";

type MultiChoiceFieldWithKindProps = MultiChoiceFieldProps & { kind: "MULTI_CHOICE" };
type MultiChoiceFieldV2WithKindProps = MultiChoiceV2FieldProps & { kind: "MULTI_CHOICE_V2" };
type SingleChoiceFieldWithKindProps = SingleChoiceFieldProps & { kind: "SINGLE_CHOICE" };
type GroupChoiceFieldWithKindProps = GroupMultiChoiceFieldProps & { kind: "GROUP_MULTI_CHOICE" };
type TagSelectFieldWithKindProps = TagSelectFieldProps & { kind: "TAG" };
type RangeFieldWithKindProps = RangeFieldProps & { kind: "RANGE" };

type FieldProps =
  | MultiChoiceFieldWithKindProps
  | MultiChoiceFieldV2WithKindProps
  | GroupChoiceFieldWithKindProps
  | TagSelectFieldWithKindProps
  | RangeFieldWithKindProps
  | SingleChoiceFieldWithKindProps;

const StyledStack = styled(Stack)(() => ({
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
}));

export type SingleLineSearchFormProps = {
  disabled?: boolean;
  textFilter: InlineTextFilterFieldProps;
  fields: FieldProps[];
  className?: string;
};

const SingleLineSearchForm: React.FC<SingleLineSearchFormProps> = props => {
  return (
    <Box className={props.className}>
      <Box display="flex" justifyContent="space-between" whiteSpace={"nowrap"}>
        <Box sx={{ overflowX: "auto", width: "100%" }}>
          <StyledStack spacing={2} direction={"row"}>
            <InlineTextFilterField {...props.textFilter} disabled={props.disabled} />
            {props.fields.map((selectorProp, index) => {
              switch (selectorProp.kind) {
                case "GROUP_MULTI_CHOICE": {
                  const { kind, ...fieldProps } = selectorProp;
                  return <GroupMultiChoiceField key={`${kind}-${index}`} {...fieldProps} disabled={props.disabled} variant="inline" />;
                }
                case "MULTI_CHOICE": {
                  const { kind, ...fieldProps } = selectorProp;
                  return <MultiChoiceField key={`${kind}-${index}`} {...fieldProps} disabled={props.disabled} variant="inline" />;
                }
                case "MULTI_CHOICE_V2": {
                  const { kind, ...fieldProps } = selectorProp;
                  return <MultiChoiceV2Field key={`${kind}-${index}`} {...fieldProps} disabled={props.disabled} />;
                }
                case "SINGLE_CHOICE": {
                  const { kind, ...fieldProps } = selectorProp;
                  return <SingleChoiceField key={`${kind}-${index}`} {...fieldProps} disabled={props.disabled} variant="inline" />;
                }
                case "TAG": {
                  const { kind, ...fieldProps } = selectorProp;
                  return <TagSelectField key={`${kind}-${index}`} {...fieldProps} disabled={props.disabled} variant="inline" />;
                }
                case "RANGE": {
                  const { kind, ...fieldProps } = selectorProp;
                  return <RangeField key={`${kind}-${index}`} {...fieldProps} disabled={props.disabled} variant="inline" />;
                }
              }
            })}
          </StyledStack>
        </Box>
      </Box>
    </Box>
  );
};

SingleLineSearchForm.displayName = "SingleLineSearchForm";

export default SingleLineSearchForm;
