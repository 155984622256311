import { translationLanguageMap } from "@hireroo/app-definition";
import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { AlgorithmResourceEditor } from "@hireroo/app-store/view-domain/AlgorithmResourceEditor";
import { AlgorithmTemplatePreview } from "@hireroo/app-store/widget/e/AlgorithmTemplatePreview";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { defaultLanguageMap } from "@hireroo/challenge/definition";
import * as Time from "@hireroo/formatter/time";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { SupportLanguageValue, useLanguageCode, useTranslation } from "@hireroo/i18n";
import { Pages, Widget } from "@hireroo/presentation/legacy";
import { AlgorithmAnswerForm } from "@hireroo/validator";
import * as Sentry from "@sentry/browser";
import * as React from "react";
import { useFieldArray } from "react-hook-form";

import { useCodeAction } from "./answersHooks";

const DELIMITER = ",";
type AnswersProps = Pages.AlgorithmResourceEditorProps["answers"];
type CorrectnessTableRowsProps =
  Pages.AlgorithmResourceEditorProps["answers"]["codeSection"]["testCaseRunnerSection"]["correctnessTable"]["tableRows"];
type PerformanceTableRowsProps =
  Pages.AlgorithmResourceEditorProps["answers"]["codeSection"]["testCaseRunnerSection"]["performanceTable"]["tableRows"];

export const useGenerateAnswersProps = (): AnswersProps => {
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const client = getGraphqlClient();
  const {
    methods,
    signatureProps,
    performanceTestCases,
    correctnessTestCases,
    selectedAnswerIndex: currentAnswerTabIndex,
    selectedRuntimeIndex: currentRuntimeTabIndex,
  } = Widget.useAlgorithmAnswersFormContext();
  const templateCode = AlgorithmTemplatePreview.useTemplate();
  const [currentLanguageTabIndex, setCurrentLanguageTabIndex] = React.useState<string>("0");
  const runCodeOutput = AlgorithmResourceEditor.useRunCodeOutput();
  const profileCodeOutput = AlgorithmResourceEditor.useProfileCodeOutput();
  const { runCode, profileCode } = useCodeAction();
  const [isTranslateLoading, setIsTranslateLoading] = React.useState<boolean>(false);
  const isRunCodeLoadingMap = AlgorithmResourceEditor.useIsRunCodeLoading();
  const isProfileCodeLoadingMap = AlgorithmResourceEditor.useIsProfileCodeLoading();
  const initRuntime = defaultLanguageMap[signatureProps.variant];

  const contentsField = useFieldArray({
    control: methods.control,
    name: `answers.${Number(currentAnswerTabIndex)}.contents`,
  });
  const contents = methods.watch(`answers.${Number(currentAnswerTabIndex)}.contents`);

  const selectedLanguageSet = React.useMemo(() => {
    if (contents && contents.length) {
      return new Set(contents.map(c => c.language));
    }
    return new Set();
  }, [contents]);

  const translateAnswer = React.useCallback(
    async (answer: AlgorithmAnswerForm.AnswersContentSchema, to: SupportLanguageValue): Promise<AlgorithmAnswerForm.AnswersContentSchema> => {
      const res = await Promise.all([
        client.TranslatePlainTextForAlgorithmResourceEditor({
          source: {
            from: translationLanguageMap[answer?.language],
            to: translationLanguageMap[to],
            body: answer.title,
          },
        }),
        client.TranslateMarkdownForAlgorithmResourceEditor({
          source: {
            from: translationLanguageMap[answer?.language],
            to: translationLanguageMap[to],
            body: answer.description,
          },
        }),
      ])
        .then(res => {
          Snackbar.notify({
            severity: "success",
            message: t("自動翻訳に成功しました。"),
          });
          return res;
        })
        .catch(error => {
          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(
            error,
            t("自動翻訳に失敗しました。しばらくしてから再度お試しください。"),
          );
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
          return [];
        });

      return {
        title: res[0]?.translatePlainText ?? "",
        description: res[1]?.translateMarkdown ?? "",
        language: to,
      };
    },
    [client, t],
  );

  const runCodeAll = React.useCallback(() => {
    const runtime = methods.getValues(`answers.${Number(currentAnswerTabIndex)}.answerRuntimes.${Number(currentRuntimeTabIndex)}.runtime`);
    const codeBody = methods.getValues(
      `answers.${Number(currentAnswerTabIndex)}.answerRuntimes.${Number(currentRuntimeTabIndex)}.codeBodies.${runtime}`,
    );

    correctnessTestCases.data.forEach((testCase, i) => {
      const input = testCase.inputs.join(DELIMITER);
      const output = testCase.outputs.join(DELIMITER);

      runCode(
        {
          signature: JSON.stringify(signatureProps.signature),
          tcInput: input,
          tcOutput: output,
          runtime: runtime,
          variant: signatureProps.variant,
          codeBody: codeBody,
        },
        i.toString(),
      );
    });
  }, [correctnessTestCases, currentAnswerTabIndex, currentRuntimeTabIndex, methods, runCode, signatureProps.signature, signatureProps.variant]);

  const profileCodeAll = React.useCallback(() => {
    const runtime = methods.getValues(`answers.${Number(currentAnswerTabIndex)}.answerRuntimes.${Number(currentRuntimeTabIndex)}.runtime`);
    const codeBody = methods.getValues(
      `answers.${Number(currentAnswerTabIndex)}.answerRuntimes.${Number(currentRuntimeTabIndex)}.codeBodies.${runtime}`,
    );

    performanceTestCases.data.forEach((testCase, i) => {
      const input = testCase.inputs.join(DELIMITER);
      const output = testCase.outputs.join(DELIMITER);

      profileCode(
        {
          signature: JSON.stringify(signatureProps.signature),
          tcInput: input,
          tcOutput: output,
          runtime: runtime,
          variant: signatureProps.variant,
          codeBody: codeBody,
        },
        i.toString(),
      );
    });
  }, [
    currentAnswerTabIndex,
    currentRuntimeTabIndex,
    methods,
    performanceTestCases,
    profileCode,
    signatureProps.signature,
    signatureProps.variant,
  ]);

  const correctnessTableRows: CorrectnessTableRowsProps = correctnessTestCases.data.map((testCase, index) => {
    const responseRunCode = runCodeOutput.get(index.toString());
    return {
      id: `${testCase.title_ja}${testCase.title_en}`,
      correctnessTestCase: testCase,
      outputStatus: isRunCodeLoadingMap.get(index.toString())
        ? { status: "LOADING" }
        : responseRunCode
          ? {
              status: responseRunCode.isAccepted ? "COMPLETED" : "REJECTED",
              output: responseRunCode,
            }
          : undefined,
      runButton: {
        onClick: async () => {
          const runtime = methods.getValues(
            `answers.${Number(currentAnswerTabIndex)}.answerRuntimes.${Number(currentRuntimeTabIndex)}.runtime`,
          );
          const codeBody = methods.getValues(
            `answers.${Number(currentAnswerTabIndex)}.answerRuntimes.${Number(currentRuntimeTabIndex)}.codeBodies.${runtime}`,
          );
          const input = testCase.inputs.join(DELIMITER);
          const output = testCase.outputs.join(DELIMITER);
          await runCode(
            {
              signature: JSON.stringify(signatureProps.signature),
              tcInput: input,
              tcOutput: output,
              runtime: runtime,
              variant: signatureProps.variant,
              codeBody: codeBody,
            },
            index.toString(),
          );
        },
      },
    };
  });

  const performanceTableRows: PerformanceTableRowsProps = performanceTestCases.data.map((testCase, index) => {
    const profileResponse = profileCodeOutput.get(index.toString());
    const outputStatus: PerformanceTableRowsProps[0]["outputStatus"] = {
      //TODO: the LOADING status won't show when the first request executes
      status: isProfileCodeLoadingMap.get(index.toString()) ? "LOADING" : profileResponse?.isAccepted ? "COMPLETED" : "REJECTED",
      output:
        (profileResponse && {
          output: profileResponse.output,
          log: profileResponse.log,
          status: profileResponse.status,
          performanceAvg: `${Time.formatTime(Number(profileResponse.performanceAvgString), Time.Unit.NANOSECOND)}`,
          performanceStd: `${Time.formatTime(Number(profileResponse.performanceStdString), Time.Unit.NANOSECOND)}`,
          performanceMed: `${Time.formatTime(Number(profileResponse.performanceMedString), Time.Unit.NANOSECOND)}`,
          maxMemoryAvgMB: Math.floor(profileResponse.maxMemoryAvg),
          maxMemoryStdMB: Math.round(profileResponse.maxMemoryStd * 100) / 100,
          maxMemoryMedMB: Math.floor(profileResponse.maxMemoryMed),
          machineOs: profileResponse.machineOs,
          machineCpu: profileResponse.machineCpu,
          machineMemory: profileResponse.machineMemory,
          isAccepted: profileResponse.isAccepted,
        }) ||
        undefined,
    };
    return {
      id: testCase.label,
      performanceTestCase: testCase,
      outputStatus: profileResponse ? outputStatus : undefined,
      runButton: {
        onClick: async () => {
          const runtime = methods.getValues(
            `answers.${Number(currentAnswerTabIndex)}.answerRuntimes.${Number(currentRuntimeTabIndex)}.runtime`,
          );
          const codeBody = methods.getValues(
            `answers.${Number(currentAnswerTabIndex)}.answerRuntimes.${Number(currentRuntimeTabIndex)}.codeBodies.${runtime}`,
          );
          const input = testCase.inputs.join(DELIMITER);
          const output = testCase.outputs.join(DELIMITER);
          await profileCode(
            {
              signature: JSON.stringify(signatureProps.signature),
              tcInput: input,
              tcOutput: output,
              runtime: runtime,
              variant: signatureProps.variant,
              codeBody: codeBody,
            },
            index.toString(),
          );
        },
      },
    };
  });

  return {
    answerInputDetails: {
      answerEditLanguageTabMenu: {
        items: [
          {
            text: "日本語",
            value: "ja",
            onClick: async () => {
              setIsTranslateLoading(true);
              const answer = methods.getValues(`answers.${Number(currentAnswerTabIndex)}.contents.${Number(currentLanguageTabIndex)}`);
              const translated = await translateAnswer(answer, "ja");
              contentsField.append(translated);
              setIsTranslateLoading(false);
            },
            isLoading: isTranslateLoading,
          },
          {
            text: "English",
            value: "en",
            onClick: async () => {
              setIsTranslateLoading(true);
              const answer = methods.getValues(`answers.${Number(currentAnswerTabIndex)}.contents.${Number(currentLanguageTabIndex)}`);
              const translated = await translateAnswer(answer, "en");
              contentsField.append(translated);
              setIsTranslateLoading(false);
            },
            isLoading: isTranslateLoading,
          },
        ].filter(item => !selectedLanguageSet.has(item.value as SupportLanguageValue)),
      },
      onLanguageTabChange: value => {
        setCurrentLanguageTabIndex(value);
      },
    },
    defaultCodeBody: templateCode[initRuntime as keyof AlgorithmTemplatePreview.Template],
    baseLanguage: lang,
    codeSection: {
      codeTemplate: templateCode,
      testCaseRunnerSection: {
        runAllTestCaseButton: {
          onClick: selectedType => {
            switch (selectedType) {
              case "correctness":
                runCodeAll();
                break;
              case "performance":
                profileCodeAll();
                break;
            }
          },
          loading: false,
        },
        correctnessTable: {
          tableRows: correctnessTableRows,
        },
        performanceTable: {
          tableRows: performanceTableRows,
        },
      },
    },
  };
};
