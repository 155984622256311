import Alert, { type AlertProps } from "@mui/material/Alert";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import * as React from "react";

import Link, { LinkProps } from "../../primitive/Link/Link";

const StyledAlert = styled(Alert)(() => ({
  borderRadius: 0,
}));

export type NotificationBannerProps = {
  severity: AlertProps["severity"];
  message: string;
  actionButton?: Pick<LinkProps, "href" | "onClick" | "children">;
};

const NotificationBanner: React.FC<NotificationBannerProps> = props => {
  const { actionButton } = props;
  const alertProps: AlertProps = {
    variant: "filled",
    severity: props.severity,
    action: actionButton && (
      <Button
        variant="text"
        size="small"
        sx={{ color: "inherit" }}
        component={Link}
        href={actionButton.href}
        onClick={actionButton.onClick}
        children={actionButton.children}
      />
    ),
    children: props.message,
  };
  return <StyledAlert {...alertProps} />;
};

NotificationBanner.displayName = "NotificationBanner";

export default NotificationBanner;
