import { useTranslation } from "@hireroo/i18n";

import { OnboardingGuideProps } from "../../modules/OnboardingGuide/OnboardingGuide";

export const useGenerateQuizOnboardingGuideProps = (): OnboardingGuideProps => {
  const { t } = useTranslation();
  return {
    steps: [
      {
        title: t("チュートリアルへようこそ！"),
        content: t(
          "こちらではクイズ形式の画面操作を説明します。このチュートリアルは何度でも再開できますので、操作に迷った際にご確認ください。",
        ),
        placement: "center",
        target: "body",
        disableScrollParentFix: true,
      },
      {
        title: t("問題の解答"),
        content: t(
          "問題は選択肢の中から正しいものを選ぶ形式と、自由記入の問題があります。それぞれ一度だけ解答可能なため提出前に再度解答が正しいかを確認してから次に進むようにしてください。",
        ),
        placement: "bottom",
        target: "#quiz-form",
        disableScrollParentFix: true,
      },
      {
        title: t("残り問題数"),
        content: t("残り問題数と、解答問題数を確認できます。制限時間を考慮して解答するようにしてください。"),
        placement: "bottom",
        target: "#remain-questions",
        disableScrollParentFix: true,
      },
      {
        title: t("制限時間"),
        content: t("問題には制限時間があります。制限時間を過ぎるとそれ以降の問題は解答することはできませんのでご注意ください。"),
        placement: "bottom",
        styles: {
          options: {
            width: 300,
          },
        },
        target: "#timelimit-box",
      },
      {
        title: t("ヘルプセンター"),
        content: t(
          "こちらのボタンを押すとヘルプセンターが開かれます。分からないことがありましたらこちらにアクセスし、記事を参照するか、右下のチャットからお問い合わせください。",
        ),
        placement: "left",
        styles: {
          options: {
            width: 300,
          },
        },
        target: "#help-button",
        disableScrollParentFix: true,
      },
      {
        title: t("チュートリアル終了"),
        content: t("これでチュートリアルは終了です。再度確認したい場合は、もう一度ボタンを押しチュートリアルを開始してください。"),
        placement: "center",
        target: "body",
        disableScrollParentFix: true,
      },
    ],
  };
};
