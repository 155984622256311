import { Auth } from "@hireroo/app-store/essential/talent";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { currentWindowMatchPath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";

export type GenerateNotificationBannerPropsArgs = {};

export const useGenerateProps = (_args: GenerateNotificationBannerPropsArgs): Widget.NotificationBannerProps | null => {
  const { t } = useTranslation();
  const currentUser = Auth.useCurrentUser();
  const navigate = useTransitionNavigate();

  if (!currentUser.emailVerified && currentUser.provider === "PASSWORD" && !currentWindowMatchPath("/t/settings/user")) {
    return {
      severity: "warning",
      message: [t("メールアドレスの認証が必要です。"), t("もしメールが見当たらない場合は個人設定から再送してください。")].join(" "),
      actionButton: {
        children: t("個人設定へ"),
        onClick: () => {
          navigate("/t/settings/user", {
            queryParams: {
              tab: "general",
            },
          });
        },
      },
    };
  }
  return null;
};
