import { useTranslation } from "@hireroo/i18n";
import { useTheme } from "@mui/material/styles";
import * as React from "react";
import { Bar, BarChart, CartesianGrid, Label, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

export type UsageData = {
  yearMonth: string;
  totalCount: number;
  paidCount: number;
  freeCount: number;
};

export type UsageBarGraphProps = {
  labels: { key: string }[];
  data: UsageData[];
};

const UsageBarGraph: React.FC<UsageBarGraphProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={100}
        height={300}
        data={props.data}
        margin={{
          top: 20,
          right: 10,
          left: 30,
          bottom: 50,
        }}
      >
        <CartesianGrid stroke={theme.palette.text.disabled} strokeDasharray="3 3" vertical={false} />
        <XAxis
          dataKey="yearMonth"
          minTickGap={-30}
          axisLine={false}
          tickLine={false}
          angle={-45}
          dy={20}
          tick={{ fontSize: 12, fill: theme.palette.text.secondary }}
        >
          <Label style={{ fontSize: 12, fill: theme.palette.text.primary }} offset={35} position={"bottom"}>
            {t("発行年月")}
          </Label>
        </XAxis>
        <YAxis tick={{ fontSize: 12, fill: theme.palette.text.secondary }}>
          <Label style={{ fontSize: 12, fill: theme.palette.text.primary }} offset={10} position={"left"} angle={-90} dy={-30}>
            {t("テスト発行数（件）")}
          </Label>
        </YAxis>
        <Tooltip
          labelStyle={{
            color: theme.palette.text.secondary,
          }}
          cursor={{ fillOpacity: 0.5 }}
          contentStyle={{
            borderColor: theme.palette.background.paper,
            backgroundColor: theme.palette.background.paper,
          }}
          itemStyle={{
            color: theme.palette.text.secondary,
          }}
          formatter={(v: string, n: string) => {
            const translateMap = new Map();

            translateMap.set("paidCount", t("課金対象数"));
            translateMap.set("freeCount", t("無料対象数"));

            return [v, translateMap.get(n)];
          }}
        />
        <Legend
          verticalAlign="top"
          align={"right"}
          height={36}
          wrapperStyle={{ fontSize: 14 }}
          formatter={(value: string): string => {
            const translateMap = new Map();

            translateMap.set("paidCount", t("課金対象数"));
            translateMap.set("freeCount", t("無料対象数"));

            return translateMap.get(value);
          }}
        />
        {props.labels.map(label => (
          <Bar
            key={label.key}
            dataKey={label.key}
            stackId="interview"
            fill={label.key === "paidCount" ? theme.palette.success.main : theme.palette.grey[400]}
            isAnimationActive={false}
            fillOpacity={1}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

UsageBarGraph.displayName = "UsageBarGraph";

export default UsageBarGraph;
