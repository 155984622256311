import { getLanguageFromFile, supportReactTypeDefinition, TypeDefs } from "@hireroo/code-editor/helpers/monaco";
import DiffEditor, { DiffEditorProps } from "@hireroo/code-editor/react/DiffEditor";
import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import * as React from "react";
export type Status = "READY" | "FETCH_ERROR" | "LOADING" | "NO_DIFF_EXISTS";

export type ProjectDiffViewerProps = {
  status: "READY" | "FETCH_ERROR" | "LOADING" | "NO_DIFF_EXISTS";
  diffImageSet?: {
    fromSrc: string;
    toSrc: string;
  };
  diffEditor: DiffEditorProps;
  typeDefs: TypeDefs;
};

const ProjectDiffViewer: React.FC<ProjectDiffViewerProps> = props => {
  const { t } = useTranslation();
  const contentMap: Record<Status, React.ReactNode> = {
    FETCH_ERROR: (
      <Typography>{t("ファイル内容の取得に失敗しました。しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。")}</Typography>
    ),
    LOADING: (
      <Box height={400} display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <CircularProgress />
      </Box>
    ),
    NO_DIFF_EXISTS: <Typography>{t("ファイル内容はテキストではないので表示できません。")}</Typography>,
    READY: null,
  };
  const diffEditorProps: DiffEditorProps = {
    ...props.diffEditor,
    beforeMount: monaco => {
      supportReactTypeDefinition(monaco, props.typeDefs);
    },
    originalLanguage: props.diffEditor.originalModelPath ? getLanguageFromFile(props.diffEditor.originalModelPath) : "",
    modifiedLanguage: props.diffEditor.modifiedModelPath ? getLanguageFromFile(props.diffEditor.modifiedModelPath) : "",
  };
  const Content = contentMap[props.status];
  if (Content) {
    return <Box>{Content}</Box>;
  }
  if (props.diffImageSet) {
    return (
      <Box height={400} display={"flex"} alignItems={"center"} justifyContent={"space-evenly"}>
        <Box sx={{ border: 2, borderColor: "red" }} width={400} height={400} display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Box component="img" src={props.diffImageSet.fromSrc} width={"100%"} height={"100%"} />
        </Box>
        <Box sx={{ border: 2, borderColor: "green" }} width={400} height={400} display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Box component="img" src={props.diffImageSet.toSrc} width={"100%"} height={"100%"} />
        </Box>
      </Box>
    );
  }
  return (
    <Box>
      <React.Suspense>
        <DiffEditor {...diffEditorProps} height={400} />
      </React.Suspense>
    </Box>
  );
};

ProjectDiffViewer.displayName = "ProjectDiffViewer";

export default ProjectDiffViewer;
