import { useChallengeCodingEditorContext } from "@hireroo/challenge/store";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import * as PrivateHelper from "./privateHelper";
import { GenerateRemoteInterviewChallengeCodingEditorPropsArgs, useGenerateProps } from "./useGenerateProps";

export type RemoteInterviewChallengeCodingEditorContainerProps = GenerateRemoteInterviewChallengeCodingEditorPropsArgs;

const RemoteInterviewChallengeCodingEditorContainer: React.FC<RemoteInterviewChallengeCodingEditorContainerProps> = props => {
  const { hooks } = useChallengeCodingEditorContext();
  const language = hooks.useCurrentLanguage();
  const path = PrivateHelper.generateLiveCodingPath({
    liveCodingId: props.liveCodingId,
    sessionId: props.collaborativeState.selectedSession.toString(),
    language,
  });
  const remoteInterviewChallengeCodingEditorProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      {/* This key is required to uniquely change the INSTANCE. Do not delete it. */}
      <Widget.RemoteInterviewChallengeCodingEditor key={path} {...remoteInterviewChallengeCodingEditorProps} />
    </ErrorBoundary>
  );
};

RemoteInterviewChallengeCodingEditorContainer.displayName = "RemoteInterviewChallengeCodingEditorContainer";

export default withErrorBoundary(RemoteInterviewChallengeCodingEditorContainer, {});
