import { useTranslation } from "@hireroo/i18n";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import * as React from "react";

import { useAlgorithmHintsFormContext } from "../Context";
import ClosableTab, { ClosableTabProps } from "./ClosableTab";
import HintLanguageTab, { HintLanguageTabProps } from "./HintLanguageTab";

export type TabFieldItem = {
  id: string;
  closableTab: Omit<ClosableTabProps, "labelText">;
  editLanguageTab: Omit<HintLanguageTabProps, "index">;
};

export type HintTabsProps = {
  tabItems: TabFieldItem[];
};

const HintTabs: React.FC<HintTabsProps> = props => {
  const { t } = useTranslation();
  const { selectedHintIndex, updateSelectedHintIndex } = useAlgorithmHintsFormContext();
  const { tabItems } = props;
  const theme = useTheme();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    updateSelectedHintIndex(newValue);
  };

  const TabPanels = props.tabItems.map((item, index) => {
    return (
      <TabPanel key={item.id} value={item.id} sx={{ paddingX: 2 }}>
        <HintLanguageTab index={index} {...item.editLanguageTab} />
      </TabPanel>
    );
  });

  return (
    <Box>
      <TabContext value={selectedHintIndex}>
        <Box display="flex" justifyContent="space-between" sx={{ bgcolor: theme.palette.background.paper }}>
          <Tabs value={selectedHintIndex} onChange={handleChange}>
            {tabItems.map((item, i) => {
              const showingCloseButton = selectedHintIndex === item.id;
              const closableTab = {
                ...item.closableTab,
                closeButton: showingCloseButton ? item.closableTab.closeButton : undefined,
                value: i.toString(),
                "aria-labelledby": i.toString(),
                "aria-controls": i.toString(),
                labelText: `${t("ヒント")} ${i + 1}`,
              };
              return <ClosableTab key={item.id} {...closableTab} />;
            })}
          </Tabs>
        </Box>
        <Box>{TabPanels}</Box>
      </TabContext>
    </Box>
  );
};

HintTabs.displayName = "HintTabs";

export default HintTabs;
