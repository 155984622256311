import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Switch, { SwitchProps } from "@mui/material/Switch/Switch";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { MediaDeviceSelectorProps } from "../../ms-components/Livecoding";
import MediaDeviceSelector from "../../ms-components/Livecoding/MediaDeviceSelector/MediaDeviceSelector";

export type LiveCodingDeviceSettingsProps = {
  videoInputSelector: Omit<MediaDeviceSelectorProps, "label">;
  audioInputSelector: Omit<MediaDeviceSelectorProps, "label">;
  noiseCancellationSelector?: Pick<SwitchProps, "checked" | "onChange">;
};

const LiveCodingDeviceSettings: React.FC<LiveCodingDeviceSettingsProps> = props => {
  const { t } = useTranslation();
  const videoDeviceSelectorProps: MediaDeviceSelectorProps = {
    ...props.videoInputSelector,
    label: t("映像入力"),
  };
  const audioDeviceSelectorProps: MediaDeviceSelectorProps = {
    ...props.audioInputSelector,
    label: t("音声入力"),
  };
  const switchProps: SwitchProps | undefined = props.noiseCancellationSelector && {
    ...props.noiseCancellationSelector,
    color: "secondary",
  };
  return (
    <Stack direction="column" spacing={2} px={2}>
      <MediaDeviceSelector key="video" {...videoDeviceSelectorProps} />
      <MediaDeviceSelector key="audio" {...audioDeviceSelectorProps} />
      {switchProps && (
        <Box>
          <Typography variant="subtitle2" gutterBottom>
            {t("ノイズキャンセリング")}
          </Typography>
          <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
            <Typography variant="body2" color="text.secondary">
              {t("有効にすると、周囲のノイズを自動で削減できます。")}
            </Typography>
            <Switch {...switchProps} />
          </Stack>
        </Box>
      )}
    </Stack>
  );
};

LiveCodingDeviceSettings.displayName = "LiveCodingDeviceSettings";

export default LiveCodingDeviceSettings;
