import { state } from "./State";
import type * as Types from "./types";

export const addEmployees = (employees: Types.Employee[]): void => {
  employees.forEach(employee => {
    state.employees.set(employee.uid, employee);
  });
};
export const clear = (): void => {
  state.employees.clear();
};
