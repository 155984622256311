import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import CustomScoreAllocation, { CustomScoreAllocationProps } from "../../modules/CustomScoreAllocation/CustomScoreAllocation";
import MemoEditor, { MemoEditorProps } from "../../modules/MemoEditor/MemoEditor";
import StatusInfo, { StatusInfoProps } from "../../modules/StatusInfo/StatusInfo";
import Timeline, { TimelineProps } from "../../modules/Timeline/Timeline";
import QuestionCard, { QuestionCardProps } from "../../ms-components/Question/QuestionCard/QuestionCard";
import QuestionOverviewTable, { QuestionOverviewTableProps } from "../../ms-components/Question/QuestionOverviewTable/QuestionOverviewTable";
import { ReviewSummaryProps } from "./parts/ReviewSummary";
import TestSummary, { TestSummaryProps } from "./parts/TestSummary";

const StyledStack = styled(Stack)(({ theme }) => ({
  borderRadius: "16px",
  backgroundColor: theme.palette["Background/Paper"].p2,
  padding: theme.spacing(2),
}));

const StyledBox = styled(Box)(({ theme }) => ({
  borderRadius: "16px",
  backgroundColor: theme.palette["Background/Paper"].p2,
  padding: theme.spacing(2),
}));

export type ScreeningTestOverviewProps = {
  timeline: TimelineProps;
  testSummary: TestSummaryProps;
  reviewSummary: ReviewSummaryProps;
  questions: QuestionCardProps[];
  questionOverview?: QuestionOverviewTableProps;
  customScoreAllocation?: CustomScoreAllocationProps;
  memoEditor: MemoEditorProps;
  statusInfo?: StatusInfoProps;
};

const ScreeningTestOverview: React.FC<ScreeningTestOverviewProps> = props => {
  const { t } = useTranslation();
  const memoEditor: MemoEditorProps = {
    ...props.memoEditor,
  };
  return (
    <Box p={3}>
      {props.statusInfo && (
        <Stack pb={3} direction="column">
          <Box display="inline-block" width="100%" alignItems="center" justifyContent="center">
            <StatusInfo {...props.statusInfo} />
          </Box>
        </Stack>
      )}

      <StyledStack direction="column" spacing={2}>
        <Timeline {...props.timeline} />
        <MemoEditor key={`memo-${memoEditor.open.toString()}`} {...memoEditor} />
      </StyledStack>

      <Box my={3}>
        <TestSummary {...props.testSummary} />
      </Box>

      <Box>
        <Typography mb={2} fontSize={16} sx={{ fontWeight: "bold" }}>
          {props.questionOverview ? t("出題する問題とカスタムスコア") : t("出題する問題")}
        </Typography>

        <StyledBox>
          {props.questionOverview && props.customScoreAllocation ? (
            <Stack spacing={2}>
              <CustomScoreAllocation {...props.customScoreAllocation} />
              <QuestionOverviewTable {...props.questionOverview} />
            </Stack>
          ) : (
            <Grid container spacing={3}>
              {props.questions.map(question => (
                <Grid key={question.id} item xs={3} md={3}>
                  <QuestionCard {...question} />
                </Grid>
              ))}
            </Grid>
          )}
        </StyledBox>
      </Box>
    </Box>
  );
};

ScreeningTestOverview.displayName = "ScreeningTestOverview";

export default ScreeningTestOverview;
