import * as ProjectHelperV2 from "@hireroo/app-helper/project-v2";
import * as ProjectHelperV3 from "@hireroo/app-helper/project-v3";
import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import { formatScore } from "@hireroo/formatter/score";
import * as Time from "@hireroo/formatter/time";
import type { Widget } from "@hireroo/presentation";
import type { ProjectFrontendTestCaseSchemaV3 } from "@hireroo/validator";
import * as React from "react";

export type GenerateGeneratePerformanceDetailSectionPropsForBackendArgs = {
  entityId: number;
  uniqueKey: ProjectTestReport.UniqueKey;
  showScore: boolean;
};

const metricDummyData: ProjectFrontendTestCaseSchemaV3.PerformanceMetricSchema = {
  score: 0,
  value: 0,
};

const frontendPerformanceTestCaseResultDummyData: ProjectFrontendTestCaseSchemaV3.FrontendPerformanceTestCaseResultSchema = {
  fcp: metricDummyData,
  si: metricDummyData,
  lcp: metricDummyData,
  tti: metricDummyData,
  tbt: metricDummyData,
  cls: metricDummyData,
};

export const useGeneratePerformanceDetailSectionPropsForFrontend = (
  args: GenerateGeneratePerformanceDetailSectionPropsForBackendArgs,
): Widget.ProjectTestReportProps["performanceDetailSection"] => {
  const hooks = ProjectTestReport.useCreateProjectHooks(args.entityId);
  const submission = hooks.useCurrentSubmission();
  const question = hooks.useQuestion();

  const variant = hooks.useQuestionVariant();

  const results = React.useMemo((): ProjectFrontendTestCaseSchemaV3.FrontendPerformanceTestCaseResultSchema[] => {
    if (!submission?.performanceTestResult || variant !== "Frontend") {
      return [];
    }
    if (question?.projectVersion.startsWith("v3")) {
      return ProjectHelperV3.FrontendTestCase.parseFrontendPerformanceTestCaseResults(submission.performanceTestResult)?.test_results ?? [];
    } else {
      return ProjectHelperV2.FrontendTestCase.parseFrontendPerformanceTestCaseResults(submission.performanceTestResult) ?? [];
    }
  }, [question?.projectVersion, submission?.performanceTestResult, variant]);

  const metrics = React.useMemo(() => {
    if (results.length) {
      return results[0];
    }
    return frontendPerformanceTestCaseResultDummyData;
  }, [results]);

  return {
    kind: "FRONTEND",
    scrollTargetElementId: ProjectTestReport.TargetElementIdMap.PERFORMANCE_SECTION,
    titleWithScoreBar: {
      score: args.showScore ? formatScore(submission?.performance ?? 0) : undefined,
    },
    content: {
      fcp: {
        score: ProjectHelperV3.FrontendTestCase.determineMetrics("fcp", metrics.cls.value),
        value: Time.getTimeUnitText(metrics.fcp.value, Time.Unit.MILLISECOND),
      },
      si: {
        score: ProjectHelperV3.FrontendTestCase.determineMetrics("si", metrics.cls.value),
        value: Time.getTimeUnitText(metrics.si.value, Time.Unit.MILLISECOND),
      },
      lcp: {
        score: ProjectHelperV3.FrontendTestCase.determineMetrics("lcp", metrics.cls.value),
        value: Time.getTimeUnitText(metrics.lcp.value, Time.Unit.MILLISECOND),
      },
      tti: {
        score: ProjectHelperV3.FrontendTestCase.determineMetrics("tti", metrics.cls.value),
        value: Time.getTimeUnitText(metrics.tti.value, Time.Unit.MILLISECOND),
      },
      tbt: {
        score: ProjectHelperV3.FrontendTestCase.determineMetrics("tbt", metrics.cls.value),
        value: Time.getTimeUnitText(metrics.tbt.value, Time.Unit.MILLISECOND),
      },
      cls: { score: ProjectHelperV3.FrontendTestCase.determineMetrics("cls", metrics.cls.value), value: metrics.cls.value.toString() },
    },
  };
};
