import { colorFromUserId } from "@hireroo/app-helper/color";
import { composeTextOperationV2, invertTextOperation } from "@hireroo/app-helper/firepad";
import { applyOperation, getTheme, useMonaco } from "@hireroo/app-helper/monaco";
import { RemoteInterviewPlayback } from "@hireroo/app-store/widget/e/RemoteInterviewPlayback";
import { languageMapForHighlight } from "@hireroo/challenge/definition";
import { useChallengeCodingEditorContext } from "@hireroo/challenge/store";
import { getCursorRange } from "@hireroo/code-editor/helpers/monaco";
import { IStandaloneCodeEditor } from "@hireroo/code-editor/react/CodeEditor";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

import { useCursorWidgetManager } from "./privateHelper";

export type GenerateRemoteInterviewChallengePlaybackEditorPropsArgs = {};

export const useGenerateProps = (
  _args: GenerateRemoteInterviewChallengePlaybackEditorPropsArgs,
): Widget.RemoteInterviewChallengePlaybackEditorProps => {
  const { t } = useTranslation();
  const monacoEditorRef = React.useRef<IStandaloneCodeEditor | null>(null);
  const { hooks } = useChallengeCodingEditorContext();
  const runtime = hooks.useCurrentLanguage();
  const cursorWidgetManager = useCursorWidgetManager();
  const codeEditorInputEvents = RemoteInterviewPlayback.useCodeEditorInputEvents();
  const lastCodeEditorInputEventsIndex = RemoteInterviewPlayback.useLastCodeEditorInputEventsIndex();
  const playbackManager = RemoteInterviewPlayback.usePlaybackManager();
  const participantMap = RemoteInterviewPlayback.useParticipantMap();
  const monaco = useMonaco();

  const createModel = React.useCallback(() => {
    const defaultValue = composeTextOperationV2(lastCodeEditorInputEventsIndex, codeEditorInputEvents);
    if (!monaco) {
      return null;
    }
    return monaco.editor.createModel(defaultValue, languageMapForHighlight[runtime]);
  }, [monaco, lastCodeEditorInputEventsIndex, codeEditorInputEvents, runtime]);

  const model = React.useMemo(() => {
    return createModel();
  }, [createModel]);

  React.useEffect(() => {
    if (!monaco || !model) return;

    const cleanupMoveForward = playbackManager.onMoveForward(payload => {
      const tickIndex = payload.currentIndex;
      const sliderIndex = payload.nextIndex;
      for (let step = 0; step < sliderIndex - tickIndex; step++) {
        const revision = codeEditorInputEvents.at(tickIndex + step + 1);
        if (revision && model) {
          applyOperation(revision.textOperations, monaco, model);
          const range = getCursorRange(revision.textOperations, monaco, model);
          monacoEditorRef.current?.revealLineInCenter(range.startLineNumber);
          const userName = participantMap.get(revision.userId)?.userName || t("匿名ユーザー");
          cursorWidgetManager.updateCursor(revision.userId, range, colorFromUserId(revision.userId), userName);
        }
      }
    });

    const cleanupMoveBackward = playbackManager.onMoveBackward(payload => {
      const tickIndex = payload.currentIndex;
      const sliderIndex = payload.nextIndex;
      for (let step = 0; step < tickIndex - sliderIndex; step++) {
        const revision = codeEditorInputEvents.at(tickIndex - step);
        if (revision && model) {
          const content = composeTextOperationV2(tickIndex - step - 1, codeEditorInputEvents);
          const inverseOp = invertTextOperation(revision.textOperations, content);
          const range = getCursorRange(revision.textOperations, monaco, model);
          monacoEditorRef.current?.revealLineInCenter(range.startLineNumber);
          const userName = participantMap.get(revision.userId)?.userName || t("匿名ユーザー");
          cursorWidgetManager.updateCursor(revision.userId, range, colorFromUserId(revision.userId), userName);
          applyOperation(inverseOp, monaco, model);
        }
      }
    });

    return () => {
      cleanupMoveForward();
      cleanupMoveBackward();
      model.dispose();
      monacoEditorRef.current?.dispose();
    };
  }, [codeEditorInputEvents, cursorWidgetManager, model, monaco, participantMap, playbackManager, t]);

  return {
    language: languageMapForHighlight[runtime],
    onMount: editor => {
      monacoEditorRef.current = editor;
      cursorWidgetManager.initCursorWidgetController(editor);
      editor.onDidDispose(() => {
        monacoEditorRef.current = null;
        model?.dispose();
      });
    },
    beforeMount: monaco => {
      // For initial mount, defining theme is necessary
      const { theme, themeData } = getTheme("monokai");
      monaco.editor.defineTheme(theme, themeData);
    },
    options: {
      readOnly: true,
      model: model,
      minimap: {
        enabled: false,
      },
    },
    keepCurrentModel: true,
  };
};
