import type * as Types from "./types";

export const createAction = (state: Types.State) => {
  return {
    deactivate: () => {
      state.updateMethod = "INITIAL";
    },
    updateLanguage: (language: Types.Language, updateMethod: Types.UpdateMethod) => {
      if (language === "") {
        return;
      }
      state.currentLanguage = language;
      state.updateMethod = updateMethod;
    },
    updateEnableLanguages: (enableLanguages: string[]) => {
      state.enableLanguages = enableLanguages;
    },
  };
};
