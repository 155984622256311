import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as React from "react";

const KindMap = {
  ACCOUNT: "ACCOUNT",
} as const;

type Kind = (typeof KindMap)[keyof typeof KindMap];

type Item = Widget.UserOrCompanySettingsNavigatorProps["items"][number];

export type GenerateUserSettingsNavigatorPropsArgs = {
  active: Kind;
};

export const useGenerateProps = (args: GenerateUserSettingsNavigatorPropsArgs): Widget.UserOrCompanySettingsNavigatorProps => {
  const { t } = useTranslation();
  const navigate = useTransitionNavigate();

  const items = React.useMemo((): Item[] => {
    return [
      {
        id: KindMap.ACCOUNT,
        label: t("個人設定"),
        href: generatePath("/t/settings/user"),
        onClick: () => {
          navigate("/t/settings/user");
        },
      },
    ];
  }, [t, navigate]);
  return {
    active: args.active,
    items: items,
  };
};
