import { state } from "./State";
import type * as Types from "./types";

export const initialize = (customer: Types.Customer, interviews: Types.Interview[]): void => {
  state.customer = customer;
  state.interviews = interviews;
};

export const clear = (): void => {
  state.submitValue = null;
  state.customer = null;
  state.interviews = [];
  state.temporarySelectionItems = [];
  state.showingTarget = "EDITOR";
};

export const setSubmitValue = (submitValue: Types.SubmitValue) => {
  state.submitValue = submitValue;
};

export const updateShowingTarget = (showingTarget: Types.ShowingTarget) => {
  state.showingTarget = showingTarget;
};

export const setTemporarySelectionItems = (temporarySelectionItems: Types.TemporaryBuySelectionItems) => {
  state.temporarySelectionItems = [...temporarySelectionItems];
};
