import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import * as React from "react";

import type * as Types from "../../../../types";
import NavigationMenuItem, { NavigationMenuItemProps } from "../NavigationMenuItem/NavigationMenuItem";

const EndIconMap: Record<`${boolean}`, React.ReactElement> = {
  true: <ExpandLess color="secondary" fontSize="small" />,
  false: <ExpandMore color="secondary" fontSize="small" />,
};

export type ChildNavigationMenuFactoryProps = Types.MenuItemWithChildren & {
  NavigationMenu: React.FC<{ items: Types.MenuItem[] }>;
};

const ChildNavigationMenuFactory: React.FC<ChildNavigationMenuFactoryProps> = props => {
  const [open, setOpen] = React.useState(true);
  const { id, children, NavigationMenu } = props;
  const navigationMenuItemProps: NavigationMenuItemProps = {
    root: true,
    id: id,
    href: props.href,
    startIcon: props.startIcon,
    children: props.title,
    onClick: () => {
      setOpen(prev => !prev);
    },
    endIcon: EndIconMap[`${open}`],
  };
  return (
    <>
      <NavigationMenuItem {...navigationMenuItemProps} />
      <Collapse in={open}>
        <NavigationMenu items={children} />
      </Collapse>
    </>
  );
};

ChildNavigationMenuFactory.displayName = "ChildNavigationMenuFactory";

export default ChildNavigationMenuFactory;
