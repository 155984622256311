import { App } from "@hireroo/app-store/essential/candidate";
import { InterviewFeedback } from "@hireroo/app-store/widget/c/InterviewFeedback";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import ScreeningTestFeedbackContainer, { ScreeningTestFeedbackContainerProps } from "./Container";

export type ScreeningTestFeedbackFetchContainerProps = {
  interviewId: string;
};

const ScreeningTestFeedbackFetchContainer: React.FC<ScreeningTestFeedbackFetchContainerProps> = props => {
  const appStatus = App.useStatus();
  const initialized = InterviewFeedback.useInitialized();
  const [result, refresh] = Graphql.useGetInterviewFeedbackForCandidateInterviewFeedbackQuery({
    variables: {
      id: props.interviewId,
      withAnswers: true,
    },
    pause: appStatus !== "INITIALIZED",
  });
  React.useEffect(() => {
    if (result.data?.spot) {
      InterviewFeedback.updateInterview(result.data.spot);
    }
  }, [result.data]);

  React.useEffect(() => {
    return () => {
      InterviewFeedback.clear();
    };
  }, []);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="TOP" />;
  }

  const containerProps: ScreeningTestFeedbackContainerProps = {};
  return (
    <ErrorBoundary>
      <ScreeningTestFeedbackContainer {...containerProps} />
    </ErrorBoundary>
  );
};

ScreeningTestFeedbackFetchContainer.displayName = "ScreeningTestFeedbackFetchContainer";

export default withErrorBoundary(ScreeningTestFeedbackFetchContainer, {});
