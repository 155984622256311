import { useTranslation } from "@hireroo/i18n";
import * as React from "react";
import { FormProvider } from "react-hook-form";

import { DraggablePromptProvider } from "../../modules/DraggablePrompt/Context";
import DraggablePrompt, { DraggablePromptProps } from "../../modules/DraggablePrompt/DraggablePrompt";
import { useTechnicalCommentPopperContext } from "./Context";
import TechnicalCommentForm, { TechnicalCommentFormProps } from "./parts/TechnicalCommentForm/TechnicalCommentForm";

export { TechnicalCommentPopperProvider, type TechnicalCommentPopperProviderProps, useTechnicalCommentPopperContext } from "./Context";
export type TechnicalCommentPopperProps = {
  formContent: TechnicalCommentFormProps;
  onClose: DraggablePromptProps["onClose"];
};

const PROMPT_WIDTH = 560;
const PROMPT_HEIGHT = 609;

const TechnicalCommentPopper: React.FC<TechnicalCommentPopperProps> = props => {
  const { t } = useTranslation();
  const { methods } = useTechnicalCommentPopperContext();

  const draggablePrompt: DraggablePromptProps = {
    title: t("技術レビュー"),
    onClose: props.onClose,
  };
  const technicalCommentForm: TechnicalCommentFormProps = {
    ...props.formContent,
  };
  return (
    <DraggablePromptProvider defaultCoordinates={{ x: window.innerWidth - PROMPT_WIDTH, y: window.innerHeight - PROMPT_HEIGHT }}>
      <DraggablePrompt {...draggablePrompt}>
        <FormProvider {...methods}>
          <TechnicalCommentForm {...technicalCommentForm} />
        </FormProvider>
      </DraggablePrompt>
    </DraggablePromptProvider>
  );
};

TechnicalCommentPopper.displayName = "TechnicalCommentPopper";

export default TechnicalCommentPopper;
