import { useTranslation } from "@hireroo/i18n";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";

import GroupTableRow, { GroupTableRowProps } from "../GroupTableRow/GroupTableRow";

const ColumnTitle = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  color: theme.palette.text.secondary,
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  "& .MuiTableCell-root": {
    borderColor: theme.palette["Secondary/Shades"].p12,
    padding: theme.spacing(1.5),
  },
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  borderTop: "1px solid",
  borderColor: theme.palette["Secondary/Shades"].p12,
}));

export type GroupTableProps = {
  items: GroupTableRowProps[];
};

const GroupTable: React.FC<GroupTableProps> = props => {
  const { t } = useTranslation();
  return (
    <StyledTableContainer>
      <Table>
        <StyledTableHead>
          <TableRow key="company-members-header-row">
            <TableCell padding="checkbox">{/* spacer */}</TableCell>
            <TableCell>
              <ColumnTitle>{t("名前")}</ColumnTitle>
            </TableCell>
            <TableCell>
              <ColumnTitle>{t("メンバー")}</ColumnTitle>
            </TableCell>
            <TableCell>
              <ColumnTitle>{t("作成日")}</ColumnTitle>
            </TableCell>
            <TableCell align="right" width="5%">
              {/* spacer */}
            </TableCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {props.items.map(item => (
            <GroupTableRow key={item.groupId} {...item} />
          ))}
          {props.items.length === 0 && (
            <TableCell colSpan={4}>
              <Typography fontSize="12px" color="textSecondary" textAlign="center">
                {t("グループが存在しません。グループを作成することで、レビュアーの設定等が容易になります。")}
              </Typography>
            </TableCell>
          )}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

GroupTable.displayName = "GroupTable";

export default GroupTable;
