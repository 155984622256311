import { useTitle } from "@hireroo/app-helper/react-use";
import { Company, Payment } from "@hireroo/app-store/essential/talent";
import { AssessmentsIdDetailStore } from "@hireroo/app-store/page/t/assessments_id_detail";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import AssessmentFeedbackFetchContainer from "../../../../../../widget/v2/t/AssessmentFeedback/FetchContainer";
import AssessmentOverviewFetchContainer from "../../../../../../widget/v2/t/AssessmentOverview/FetchContainer";
import AssessmentReportFetchContainer from "../../../../../../widget/v2/t/AssessmentReport/FetchContainer";
import { useGenerateDetailHeaderProps } from "./useGenerateDetailHeaderProps";
import { useGenerateRetriedSelectorProps } from "./useGenerateRetriedSelectorProps";
import { useGenerateSummaryProps } from "./useGenerateSummaryProps";

export type GenerateAssessmentDetailForTalentPropsArgs = {
  assessmentId: string;
};

export const useGenerateProps = (args: GenerateAssessmentDetailForTalentPropsArgs): Widget.AssessmentDetailForTalentProps => {
  const { t } = useTranslation();
  const assessment = AssessmentsIdDetailStore.useAssessment();
  const currentTab = AssessmentsIdDetailStore.useCurrentTab();
  const [, setParams] = useSearchParams();
  const isAvailableFeature = Payment.useIsAvailableFeature();
  const activeCompanyId = Company.useStrictActiveCompanyId();
  const selectedExamId = AssessmentsIdDetailStore.useSelectedExamId();

  const detailHeaderProps = useGenerateDetailHeaderProps(args);
  const summaryProps = useGenerateSummaryProps();
  const retriedSelector = useGenerateRetriedSelectorProps();
  const currentExam = AssessmentsIdDetailStore.useCurrentExam();

  const canReview = currentExam?.status === "COMPLETED" || currentExam?.status === "FINALIZED";

  useTitle(assessment.name);

  return {
    detailHeaderPage: detailHeaderProps,
    defaultTabId: currentTab || undefined,
    items: [
      {
        id: "summary",
        Component: activeCompanyId && selectedExamId && (
          <AssessmentOverviewFetchContainer assessmentId={assessment.id} companyId={activeCompanyId} examId={selectedExamId} />
        ),
        label: {
          name: t("テスト概要"),
          onClick: () => {
            AssessmentsIdDetailStore.updateCurrentTab("summary");
            setParams(prev => {
              return {
                ...Object.fromEntries(prev),
                tab: "summary",
              };
            });
          },
          icon: "DESCRIPTION" as const,
        },
      },
      {
        id: "report",
        Component: selectedExamId && <AssessmentReportFetchContainer examId={selectedExamId} reportSettings={assessment.reportSettings} />,
        label: {
          name: t("レポート"),
          onClick: () => {
            AssessmentsIdDetailStore.updateCurrentTab("report");
            setParams(prev => {
              return {
                ...Object.fromEntries(prev),
                tab: "report",
              };
            });
          },
          icon: "TRENDING_UP" as const,
          disabled: !canReview,
          disabledTooltip: t("試験が終了すると利用可能になります。"),
        },
      },
      isAvailableFeature("test.technical-comment.create") && {
        id: "review",
        Component: selectedExamId && <AssessmentFeedbackFetchContainer examId={selectedExamId} />,
        label: {
          name: t("技術レビュー"),
          onClick: () => {
            AssessmentsIdDetailStore.updateCurrentTab("review");
            setParams(prev => {
              return {
                ...Object.fromEntries(prev),
                tab: "review",
              };
            });
          },
          disabled: !canReview,
          disabledTooltip: t("試験が終了すると利用可能になります。"),
          icon: "COMMENT" as const,
        },
      },
    ].flatMap(v => (v ? [v] : [])),
    status: assessment.status === "RUNNING" ? "ACTIVATED" : "DEACTIVATED",
    title: assessment.name,
    summary: summaryProps,
    retriedSelector: retriedSelector,
    announcementMessage: currentExam?.status === "COMPLETED" ? t("テストの実施が終了しました。ただいま、採点中です。") : undefined,
  };
};
