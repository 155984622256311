import { useTranslation } from "@hireroo/i18n";
import ConnectLineGif from "@hireroo/ui-assets/images/tutorial/connect-line.gif";
import EditComponentGif from "@hireroo/ui-assets/images/tutorial/edit-component.gif";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { OnboardingGuideProps } from "../../../modules/OnboardingGuide/OnboardingGuide";

const FigureWrapper = styled("figure")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  padding: theme.spacing(1),
}));

export const useGenerateSystemDesignOnboardingGuideForEmployeeProps = (): OnboardingGuideProps => {
  const { t } = useTranslation();
  return {
    steps: [
      {
        title: t("チュートリアルへようこそ！"),
        content: t(
          "こちらではライブコーディングの画面操作を説明します。このチュートリアルは何度でも再開できますので、操作に迷った際にご確認ください。",
        ),
        placement: "center",
        target: "body",
      },
      {
        title: t("ペーンの調整"),
        content: t("こちらをクリックし、動かすことでペーンの幅を調整することができます。"),
        placement: "right-start",
        styles: {
          options: {
            width: 300,
          },
        },
        target: ".tutorial-pane",
      },
      {
        title: t("ペーンの開閉"),
        content: t("こちらをクリックすることでペーンを開閉することができます。"),
        placement: "bottom",
        styles: {
          options: {
            width: 300,
          },
        },
        target: ".toggle-pane-button",
      },
      {
        title: t("問題の追加"),
        content: t("クリックすることで問題選択ダイアログが表示され、選択した問題を追加することができます。"),
        placement: "top",
        styles: {
          options: {
            width: 300,
          },
        },
        target: ".add-question",
      },
      {
        title: t("問題の削除"),
        content: t("クリックすることで出題している問題を削除することができます。また、すべての問題削除可能です。"),
        placement: "top",
        styles: {
          options: {
            width: 300,
          },
        },
        target: ".close-tab",
      },
      {
        title: t("コンポーネントタイプの選択"),
        content: t("好きなコンポーネントタイプ（デフォルト/AWS/GCP/Azure）を選択しシステム設計を行うことが可能です。"),
        placement: "bottom",
        styles: {
          options: {
            width: 300,
          },
        },
        target: ".component-type-selector",
      },
      {
        title: t("コンポーネントの配置"),
        content: t("アイコンをクリックするか、右の描画エリアにドラッグ&ドロップすることで、任意のコンポーネントを配置することができます。"),
        placement: "left",
        styles: {
          options: {
            width: 300,
          },
        },
        target: "#user-object",
      },
      {
        title: t("コンポーネントの編集"),
        content: (
          <Stack spacing={0.5} direction="column">
            <FigureWrapper>
              <img src={EditComponentGif} />
            </FigureWrapper>
            <Typography variant="body1">{t("選択中のコンポーネントのプロパティを右サイドパネルより変更できます。")}</Typography>
          </Stack>
        ),
        placement: "left",
        styles: {
          options: {
            width: 500,
          },
        },
        target: "#view-area",
      },
      {
        title: t("フローチャートの作成"),
        content: (
          <Stack spacing={0.5} direction="column">
            <FigureWrapper>
              <img src={ConnectLineGif} />
            </FigureWrapper>
            <Typography variant="body1">
              {t(
                "配置したコンポーネントを矢印で繋げることで問題の要件を満たすシステムを設計しましょう。必要に応じて描画エリアにコメントを添えることもできます。",
              )}
            </Typography>
          </Stack>
        ),
        placement: "left",
        styles: {
          options: {
            width: 500,
          },
        },
        target: "#view-area",
      },
      {
        title: t("フローチャートのリセット"),
        content: t("初期配置に戻します。リセットした内容は復元できませんのでご注意ください。"),
        placement: "top",
        styles: {
          options: {
            width: 300,
          },
        },
        target: "#system-design-reset-button",
      },
      {
        title: t("評価項目の追加"),
        content: t("こちらをクリックすることで追加する評価項目を選択することができます。"),
        placement: "left",
        styles: {
          options: {
            width: 300,
          },
        },
        target: ".add-evaluation-item-button",
      },
      {
        title: t("ビデオ通話を開始する"),
        content: t("クリックすることでビデオ通話ダイアログが表示され、参加を押すことでビデオ通話を開始すことができます。"),
        placement: "auto",
        styles: {
          options: {
            width: 300,
          },
        },
        target: ".video-call-start-button",
      },
      {
        title: t("チャット"),
        content: t("インタビューの参加者とチャットをすることが可能です。Markdown形式でチャットを送ることができます。"),
        placement: "auto",
        styles: {
          options: {
            width: 300,
          },
        },
        target: ".chat-tab",
      },
      {
        title: t("ヘルプセンター"),
        content: t(
          "こちらのボタンを押すとヘルプセンターが開かれます。分からないことがありましたらこちらにアクセスし、記事を参照するか、右下のチャットからお問い合わせください。",
        ),
        placement: "top",
        styles: {
          options: {
            width: 300,
          },
        },
        target: "#help-button",
      },
      {
        title: t("チュートリアル終了"),
        content: t("これでチュートリアルは終了です。再度確認したい場合は、もう一度ボタンを押しチュートリアルを開始してください。"),
        placement: "center",
        target: "body",
      },
    ],
  };
};
