import { FlowElement } from "@hireroo/system-design/features";
import { viewboxFromElements } from "@hireroo/system-design/helpers/flowChart";
import { StaticFlowChart, StaticFlowChartProps } from "@hireroo/system-design/react/v2";
import Box from "@mui/material/Box";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";

export type RemoteInterviewStaticFlowChartProps = {
  staticFlowChart: StaticFlowChartProps;
  lastElements: FlowElement[];
};

const RemoteInterviewStaticFlowChart: React.FC<RemoteInterviewStaticFlowChartProps> = props => {
  const boxRef = useResizeDetector();
  const staticFlowChart: StaticFlowChartProps = {
    ...props.staticFlowChart,
    viewbox: viewboxFromElements(props.lastElements, (boxRef.height ?? 0) / (boxRef.width ?? 0)),
    disableAutoResize: true,
  };
  return (
    <Box ref={boxRef.ref} height="100%">
      <React.Suspense>
        <StaticFlowChart {...staticFlowChart} />
      </React.Suspense>
    </Box>
  );
};

RemoteInterviewStaticFlowChart.displayName = "RemoteInterviewStaticFlowChart";

export default RemoteInterviewStaticFlowChart;
