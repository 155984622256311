import { AssessmentFeedbackStore } from "@hireroo/app-store/view-domain/AssessmentFeedback";
import * as TimeFormatter from "@hireroo/formatter/time";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";

export type GenerateAssessmentFeedbackPropsArgs = {};

type Item = Widget.AssessmentFeedbackProps["feedbacks"][0]["items"][0];

export const useGenerateProps = (_args: GenerateAssessmentFeedbackPropsArgs): Widget.AssessmentFeedbackProps => {
  const exam = AssessmentFeedbackStore.useExam();
  const { t } = useTranslation();

  return {
    feedbacks: exam.recommendations.map(recommendation => {
      return {
        id: recommendation.id,
        reviewer: {
          displayName: recommendation.reviewer.displayName || recommendation.reviewer.email,
          photoUrl: recommendation.reviewer.photoUrl,
          email: recommendation.reviewer.email,
        },
        items: recommendation.evaluations.map(
          (evaluation): Item => ({
            id: evaluation.id,
            title: evaluation.metric?.title ?? "",
            evaluation: {
              numStars: evaluation.numStars,
              comment: evaluation.comment,
            },
          }),
        ),
        isRecommended: recommendation.isRecommended,
        comment: recommendation.comment,
        reviewedAt: TimeFormatter.unixToDateFormat(recommendation.createdAtSeconds ?? 0),
      };
    }),
    emptyMessage: t("技術評価がありません。評価者が入力を完了すると内容を確認することができます。"),
  };
};
