import { useTranslation } from "@hireroo/i18n";
import { PaymentForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import ApplicationConfirmationSection, {
  ApplicationConfirmationSectionProps,
} from "../../ms-components/Payment/ApplicationConfirmationSection/ApplicationConfirmationSection";
import CouponField, { CouponFieldProps } from "../../ms-components/Payment/CouponField/CouponField";
import PlanSelectionField, { PlanSelectionFieldProps } from "../../ms-components/Payment/PlanSelectionField/PlanSelectionField";
import PlanSelectionTips, { PlanSelectionTipsProps } from "../../ms-components/Payment/PlanSelectionTips/PlanSelectionTips";

const Footer = styled(Box)(() => ({
  textAlign: "center",
}));

const SectionLabel = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  marginBottom: theme.spacing(2),
}));

export type PlanUpdateFormProps = {
  onSubmit: SubmitHandler<PaymentForm.PaymentContractUpdateForm>;
  onChange: (fields: PaymentForm.PaymentContractUpdateForm) => void;
  planSelectionField: Omit<PlanSelectionFieldProps, "name">;
  planSelectionTips?: PlanSelectionTipsProps;
  payNextMonthConfirmSection: ApplicationConfirmationSectionProps;
  payInTwoMonthConfirmSection?: ApplicationConfirmationSectionProps;
  defaultValues: PaymentForm.PaymentContractUpdateForm;
  couponField?: Omit<CouponFieldProps, "name">;
};

const PlanUpdateForm: React.FC<PlanUpdateFormProps> = props => {
  const { t } = useTranslation();
  const { onChange } = props;
  const validateSchema = PaymentForm.usePaymentContractUpdateForm();
  const methods = useForm<PaymentForm.PaymentContractUpdateForm>({
    resolver: zodResolver(validateSchema),
    defaultValues: props.defaultValues,
  });
  const confirmButton: ButtonProps = {
    variant: "contained",
    children: t("申込内容の確認をする"),
    onClick: () => {
      methods.handleSubmit(props.onSubmit, console.error)();
    },
  };

  React.useEffect(() => {
    const subscription = methods.watch(() => {
      onChange(methods.getValues());
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [methods, onChange]);

  return (
    <FormProvider {...methods}>
      <Box>
        <SectionLabel>{t("プラン選択")}</SectionLabel>
        <PlanSelectionField {...props.planSelectionField} name="plan" mb={3} />
        {props.planSelectionTips && <PlanSelectionTips {...props.planSelectionTips} mb={3} />}
        {props.couponField && (
          <>
            <SectionLabel>{t("クーポン")}</SectionLabel>
            <CouponField {...props.couponField} name="coupon" mb={3} />
          </>
        )}
        <SectionLabel>{t("お申し込み内容")}</SectionLabel>
        <ApplicationConfirmationSection {...props.payNextMonthConfirmSection} mb={3} />
        {props.payInTwoMonthConfirmSection && <ApplicationConfirmationSection {...props.payInTwoMonthConfirmSection} mb={3} />}
        <Footer>
          <Button {...confirmButton} />
        </Footer>
      </Box>
    </FormProvider>
  );
};

PlanUpdateForm.displayName = "PlanUpdateForm";

export default PlanUpdateForm;
