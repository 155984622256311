import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { Message } from "../../types";
import TextMessage from "./TextMessage/TextMessage";

const SCROLL_BOTTOM_MARGIN = 100;

export type MessageListProps = {
  messages: Message[];
  onScrollToBottom?: () => void;
};

const MessageList: React.FC<MessageListProps> = props => {
  const theme = useTheme();
  const scrollContainerRef = React.useRef<HTMLElement>(null);
  const [isScrollBottom, setIsScrollBottom] = React.useState(true);
  const { onScrollToBottom } = props;

  // Call onScrollToBottom when the user scrolls to the bottom
  React.useEffect(() => {
    if (isScrollBottom) {
      onScrollToBottom?.();
    }
  }, [isScrollBottom, onScrollToBottom]);

  // Scroll down when new messages are added
  React.useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) {
      return;
    }
    const { scrollHeight, clientHeight } = scrollContainer;
    // only already scroll at the bottom
    if (isScrollBottom) {
      scrollContainer.scrollTop = scrollHeight - clientHeight;
    }
  }, [props.messages, isScrollBottom]);

  const handleScroll = React.useCallback(() => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) {
      return;
    }
    const { scrollTop, clientHeight } = scrollContainer;
    const currentPosition = scrollTop + clientHeight;

    // Check if the user is at the bottom of the scroll container
    if (currentPosition + SCROLL_BOTTOM_MARGIN >= scrollContainer.scrollHeight) {
      setIsScrollBottom(true);
    } else {
      setIsScrollBottom(false);
    }
  }, []);

  // Update scroll position when the user scrolls
  React.useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) {
      return;
    }
    // first call to handleScroll to set initial scroll position
    handleScroll();

    scrollContainer.addEventListener("scroll", handleScroll);
    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <Box height={"100%"} width={"100%"} px={2} style={{ overflowX: "hidden", overflowY: "auto" }} ref={scrollContainerRef}>
      {props.messages.map((message, idx) => {
        const prevMessage = props.messages[idx - 1];

        // Display message info when the author or formatted timestamp differs from the previous message
        const shouldDisplayMessageInfo = message.displayTime !== prevMessage?.displayTime || message.author.id !== prevMessage?.author.id;
        return (
          <Box key={message.id} width={"100%"} my={1.5}>
            <Stack direction="row" justifyContent="flex-start" spacing={1}>
              <Stack direction="column" display={"box"} width={"100%"}>
                {shouldDisplayMessageInfo && (
                  <Stack pt={1} direction="row" justifyContent="flex-start" spacing={1} mb={1} alignItems="center">
                    <Avatar
                      sx={{ bgcolor: message.author.color, height: 24, width: 24 }}
                      src={message.author.photoUrl || message.author.displayName}
                      alt={message.author.displayName}
                    />
                    <Typography fontSize={14} variant={"subtitle1"}>
                      {message.author.displayName}
                    </Typography>
                    <Typography fontSize={12} variant="body2" color={theme.palette.text.secondary}>
                      {message.displayTime}
                    </Typography>
                  </Stack>
                )}
                {message.type === "text" && <TextMessage body={message.body ?? ""} />}
              </Stack>
            </Stack>
          </Box>
        );
      })}
    </Box>
  );
};

MessageList.displayName = "MessageList";

export default MessageList;
