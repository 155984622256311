import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import * as Subscriber from "./Subscriber";
import { useGenerateProps } from "./useGenerateProps";

export type AssessmentListForEmployeeContainerProps = {};

const AssessmentListForEmployeeContainer: React.FC<AssessmentListForEmployeeContainerProps> = () => {
  const assessmentListForEmployeeProps = useGenerateProps({});
  React.useEffect(() => {
    const stopSubscribeSearchFilter = Subscriber.startSubscribeSearchFilter();
    const stopSubscribePager = Subscriber.startSubscribePager();
    return () => {
      stopSubscribeSearchFilter();
      stopSubscribePager();
    };
  }, []);
  return (
    <ErrorBoundary>
      <Pages.AssessmentListForEmployee {...assessmentListForEmployeeProps} />
    </ErrorBoundary>
  );
};

AssessmentListForEmployeeContainer.displayName = "AssessmentListForEmployeeContainer";

export default withErrorBoundary(AssessmentListForEmployeeContainer, {});
