import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import MuiTabScrollButton, { TabScrollButtonProps as MuiTabScrollButtonProps } from "@mui/material/TabScrollButton";
import * as React from "react";
import { createPortal } from "react-dom";

export type TabScrollButtonProps = MuiTabScrollButtonProps;

const TabScrollButton: React.FC<TabScrollButtonProps> = props => {
  const id = props.id ?? "dummy";
  if (props.direction === "left") {
    const target = document.getElementById(id);
    if (target) {
      /**
       * FIXME I could not figure out how to move the TabScrollButton to the right side, so I tentatively specified the following method.
       */
      return createPortal(<MuiTabScrollButton {...props} direction="left" disabled={false} />, target);
    }
    return null;
  }

  return (
    <Paper elevation={4} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Box id={id} />
      <Box>
        <MuiTabScrollButton {...props} disabled={false} />
      </Box>
    </Paper>
  );
};

TabScrollButton.displayName = "TabScrollButton";

export default TabScrollButton;
