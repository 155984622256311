import { useSnapshot } from "valtio";

import { state } from "./State";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitializeError = () => {
  const snapshot = useSnapshotState();
  return snapshot.initializeError;
};

export const useCurrentUid = () => {
  const snapshot = useSnapshotState();
  return snapshot.uid;
};
