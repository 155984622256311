import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { useQuestionSearchAndSelectableAreaContext } from "../../Context";
import SelectableQuestionListItem, { SelectableQuestionListItemProps } from "./parts/SelectableQuestionListItem/SelectableQuestionListItem";

const LIST_ITEM_CLASS_NAME = "__list-item__";

const Wrapper = styled(Box)(({ theme }) => ({
  height: "100%",
  width: "100%",
  flexShrink: 0,
  [`.${LIST_ITEM_CLASS_NAME}`]: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const CenteredContent = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
}));

export type SelectableQuestionListProps = {
  items: SelectableQuestionListItemProps[];
  disabledAll?: boolean;
};

const SelectableQuestionList: React.FC<SelectableQuestionListProps> = props => {
  const { t } = useTranslation();
  const { selectedEntitySources, mode } = useQuestionSearchAndSelectableAreaContext();
  const uniqueKeysSetByTemporaryFieldValue = selectedEntitySources.reduce<Set<string>>((all, entitySource) => {
    all.add(entitySource.uniqueKey);
    return all;
  }, new Set());

  const SelectableQuestionListItems = props.items.reduce<React.ReactNode[]>((list, selectableQuestionListItemProps) => {
    const entitySource = selectableQuestionListItemProps.entitySource;
    const selectedByTemporary = uniqueKeysSetByTemporaryFieldValue.has(entitySource.uniqueKey);
    if (mode === "SELECT_FROM_ALL_QUESTIONS" && uniqueKeysSetByTemporaryFieldValue.has(entitySource.uniqueKey)) {
      return list;
    }
    const selected = selectableQuestionListItemProps.selected || selectedByTemporary;
    const Component = (
      <SelectableQuestionListItem
        key={entitySource.uniqueKey}
        {...selectableQuestionListItemProps}
        selected={selected}
        className={LIST_ITEM_CLASS_NAME}
        disabled={selectableQuestionListItemProps.disabled || props.disabledAll}
      />
    );
    return list.concat(Component);
  }, []);

  if (SelectableQuestionListItems.length === 0) {
    return (
      <CenteredContent>
        <Typography color="text.secondary" variant="caption">
          {t("検索条件に一致する問題は見つかりませんでした。")}
        </Typography>
      </CenteredContent>
    );
  }
  return <Wrapper>{SelectableQuestionListItems}</Wrapper>;
};

SelectableQuestionList.displayName = "SelectableQuestionList";

export default SelectableQuestionList;
