import { useTranslation } from "@hireroo/i18n";
import Cancel from "@mui/icons-material/Cancel";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import PlayArrow from "@mui/icons-material/PlayArrow";
import RadioButtonUncheckedOutlined from "@mui/icons-material/RadioButtonUncheckedOutlined";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import { styled, useTheme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type Status = "RUNNING" | "SUCCESS" | "UNCHECK" | "FAIL";

export type ExecutableTestCaseTableRowProps = {
  open?: boolean;
  status: Status;
  title: string;
  runButton: Pick<ButtonProps, "onClick">;
  Detail: React.ReactNode;
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&.details:not(:last-child)": {
    borderBottom: "1px solid",
    borderColor: theme.palette.Other.Divider,
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  borderBottom: "unset",
}));

const StatusIconMap: Record<Status, React.ReactNode> = {
  RUNNING: <CircularProgress color="primary" size={20} />,
  UNCHECK: <RadioButtonUncheckedOutlined color="disabled" fontSize="inherit" />,
  SUCCESS: <CheckCircleOutline color="success" fontSize="small" />,
  FAIL: <Cancel color="error" fontSize="small" />,
};

const ExecutableTestCaseTableRow: React.FC<ExecutableTestCaseTableRowProps> = props => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [open, setOpen] = React.useState<boolean>(props.open ?? false);
  React.useEffect(() => {
    typeof props.open === "boolean" && setOpen(props.open);
  }, [props.open]);

  const runButtonProps: ButtonProps = {
    ...props.runButton,
    size: "small",
    color: "secondary",
    disabled: props.status === "RUNNING",
    startIcon: <PlayArrow />,
    sx: {
      whiteSpace: "nowrap",
    },
  };
  return (
    <>
      <StyledTableRow>
        <StyledTableCell>
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            {StatusIconMap[props.status]}
          </Box>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" width="100%" sx={{ paddingLeft: 0 }}>
          <Typography variant={"subtitle1"}>{props.title}</Typography>
        </StyledTableCell>
        <StyledTableCell align={"right"} sx={{ paddingLeft: 0 }}>
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Button {...runButtonProps}>{t("実行")}</Button>
          </Box>
        </StyledTableCell>
        <StyledTableCell sx={{ paddingLeft: 0 }}>
          <IconButton
            sx={{ color: theme.palette["Gray/Shades"].p56 }}
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            disabled={props.status === "UNCHECK"}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow className="details">
        <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {props.Detail}
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

ExecutableTestCaseTableRow.displayName = "ExecutableTestCaseTableRow";

export default ExecutableTestCaseTableRow;
