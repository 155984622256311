import { useTranslation } from "@hireroo/i18n";
import { ShareSettingsForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import Box from "@mui/material/Box";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import BaseDialog, { BaseDialogProps } from "../../../../primitive/BaseDialog/BaseDialog";
import CopyLinkField, { CopyLinkFieldProps } from "./CopyLinkField";
import ShareSettingsFields, { ShareSettingsFieldsProps } from "./ShareSettingsFields";

export type GenerateSharedLinkStep = "SETTINGS" | "COPY";

export type GenerateSharedLinkDialogProps = {
  dialog: BaseDialogProps;
  copyLinkField: CopyLinkFieldProps;
  step: GenerateSharedLinkStep;
  onSubmit: SubmitHandler<ShareSettingsForm.ShareSettingsFormSchema>;
  shareSettingsFields: ShareSettingsFieldsProps;
  defaultValues: ShareSettingsForm.ShareSettingsFormSchema;
};

const GenerateSharedLinkDialog: React.FC<GenerateSharedLinkDialogProps> = props => {
  const { t } = useTranslation();
  const validateSchema = ShareSettingsForm.useShareSettingsFormSchema();
  const method = useForm({
    resolver: zodResolver(validateSchema),
    defaultValues: props.defaultValues,
  });

  const showScore = method.watch("showScore");

  React.useEffect(() => {
    if (!showScore) {
      /**
       * Options that cannot be enabled unless Score is available
       */
      method.setValue("showRank", false);
      method.setValue("showRelativeEvaluation", false);
    }
  }, [method, showScore]);

  const dialogProps = React.useMemo((): BaseDialogProps => {
    const dialogPropsForSettings: BaseDialogProps = {
      ...props.dialog,
      yesButton: {
        ...props.dialog.yesButton,
        onClick: () => {
          method.handleSubmit(props.onSubmit)();
          props.dialog.yesButton?.onClick?.();
        },
        children: t("リンクを生成する"),
      },
      noButton: {
        ...props.dialog.noButton,
        children: t("キャンセル"),
      },
    };

    const dialogPropsForCopy: BaseDialogProps = {
      ...props.dialog,
      yesButton: {
        ...props.dialog.yesButton,
        children: t("完了"),
      },
      noButton: {
        ...props.dialog.noButton,
        children: t("戻る"),
      },
    };

    const dialogPropsMap: Record<GenerateSharedLinkStep, BaseDialogProps> = {
      SETTINGS: dialogPropsForSettings,
      COPY: dialogPropsForCopy,
    };
    return dialogPropsMap[props.step];
  }, [method, props.dialog, props.onSubmit, props.step, t]);

  return (
    <FormProvider {...method}>
      <BaseDialog {...dialogProps}>
        <Box sx={{ minWidth: "500px" }}>
          {props.step === "SETTINGS" && <ShareSettingsFields {...props.shareSettingsFields} />}
          {props.step === "COPY" && <CopyLinkField {...props.copyLinkField} />}
        </Box>
      </BaseDialog>
    </FormProvider>
  );
};

GenerateSharedLinkDialog.displayName = "GenerateSharedLinkDialog";

export default GenerateSharedLinkDialog;
