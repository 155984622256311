import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import * as React from "react";

export type ScreeningTestLayoutProps = {
  NotificationBanner: React.ReactNode;
  loading?: boolean;
  Header: React.ReactNode;
  Footer: React.ReactNode;
  Snackbar: React.ReactNode;
  Tutorial: React.ReactNode;
  children: React.ReactNode;
  className?: string;
};

const NOTIFICATION_BANNER_HEIGHT = 48;
const HEADER_HEIGHT = 48;
const FOOTER_HEIGHT = 24;
const ADJUST_HEIGHT = 1;

const NotificationBannerWrapper = styled(Box)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 100,
  width: "100%",
  height: NOTIFICATION_BANNER_HEIGHT,
}));

const StyledHeaderWrapper = styled(Box)(() => ({
  overflow: "hidden",
  position: "relative",
  height: HEADER_HEIGHT,
  justifyContent: "center",
  boxShadow: "none",
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  height: HEADER_HEIGHT,
  position: "relative",
  backgroundColor: theme.palette.background.paper,
  justifyContent: "center",
  boxShadow: "none",
}));

const ScreeningTestLayout: React.FC<ScreeningTestLayoutProps> = props => {
  const topMargin = props.NotificationBanner ? `${NOTIFICATION_BANNER_HEIGHT}px` : `0px`;
  return (
    <Paper elevation={2}>
      {props.loading && <LinearProgress sx={{ width: "100%", position: "fixed", zIndex: 2000, top: 0 }} color="secondary" />}
      {props.NotificationBanner && <NotificationBannerWrapper>{props.NotificationBanner}</NotificationBannerWrapper>}
      <StyledAppBar>
        <StyledHeaderWrapper>{props.Header}</StyledHeaderWrapper>
      </StyledAppBar>
      <Box
        className={props.className}
        sx={{ height: `calc(100vh - ${HEADER_HEIGHT + FOOTER_HEIGHT + ADJUST_HEIGHT}px - ${topMargin})` }}
        overflow="auto"
      >
        {props.children}
      </Box>
      <Box sx={{ height: FOOTER_HEIGHT }}>{props.Footer}</Box>
      {props.Snackbar}
      {props.Tutorial}
    </Paper>
  );
};

ScreeningTestLayout.displayName = "ScreeningTestLayout";

export default ScreeningTestLayout;
