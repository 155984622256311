import { useTranslation } from "@hireroo/i18n";
import { ButtonProps } from "@mui/material/Button";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import * as React from "react";

import CommonLayout, { CommonLayoutProps } from "../../layout/CommonLayout/CommonLayout";
import StepContentLayout, { StepContentLayoutProps } from "../../primitive/StepContentLayout/StepContentLayout";
import FreepadAnswerForm, { FreepadAnswerFormProps } from "../../widget/FreepadAnswerForm/FreepadAnswerForm";
import FreepadOptionalForm, { FreepadOptionalFormProps } from "../../widget/FreepadOptionalForm/FreepadOptionalForm";
import FreepadQuestionDetailForm, { FreepadQuestionDetailFormProps } from "../../widget/FreepadQuestionDetailForm/FreepadQuestionDetailForm";

export { FreepadResourceEditorProvider, type FreepadResourceEditorProviderProps, useFreepadResourceEditorContext } from "./Context";
export type FreepadResourceEditorProps = {
  layout: Omit<CommonLayoutProps, "heading">;
  /** greater than or equal to 0 */
  activeStep: number;
  questionDetails: FreepadQuestionDetailFormProps;
  saveQuestionDetailsButton: Pick<StepContentLayoutProps["nextButton"], "disabled" | "loading" | "onClick">;
  answers: FreepadAnswerFormProps;
  saveAnswersButton: Pick<StepContentLayoutProps["nextButton"], "disabled" | "loading" | "onClick">;
  optional: FreepadOptionalFormProps;
  saveOptionalButton: Pick<StepContentLayoutProps["nextButton"], "disabled" | "loading" | "onClick">;
  draftSaveButton: StepContentLayoutProps["middleActionButton"];
  previousButton: ButtonProps;
};

const FreepadResourceEditor: React.FC<FreepadResourceEditorProps> = props => {
  const { t } = useTranslation();
  const layoutProps: CommonLayoutProps = {
    ...props.layout,
    heading: {
      text: t("問題作成"),
    },
  };

  /**
   * QuestionDetailInput 問題概要入力
   * */
  const questionDetailLayout: StepContentLayoutProps = {
    title: t("問題概要入力"),
    description: t("問題の概要を入力します。候補者が問題を把握しやすいようにわかりやすく入力ください。"),
    nextButton: {
      ...props.saveQuestionDetailsButton,
      children: t("保存して次へ"),
    },
  };

  /**
   * AnswersInput 解答の入力
   * */
  const answers: StepContentLayoutProps = {
    title: t("解答の入力"),
    description: t("解答を入力します。評価時に利用する解答文や、候補者の提出したコードと比較する解答コードを追加します。"),
    previousButton: {
      ...props.previousButton,
      children: t("前に戻る"),
    },
    middleActionButton: {
      ...props.draftSaveButton,
      children: t("一時保存する"),
    },
    nextButton: {
      ...props.saveAnswersButton,
      children: t("保存して次へ"),
    },
  };

  /**
   * OptionalInput その他項目
   * */
  const optional: StepContentLayoutProps = {
    title: t("（任意）その他項目"),
    description: t("初期コードなど問題で設定できる項目を入力します。後からでも追加が可能です。"),
    previousButton: {
      ...props.previousButton,
      children: t("前に戻る"),
    },
    middleActionButton: {
      ...props.draftSaveButton,
      children: t("一時保存する"),
    },
    nextButton: {
      ...props.saveOptionalButton,
      children: t("保存する"),
    },
  };

  const steps = [
    {
      title: questionDetailLayout.title,
      Content: (
        <StepContentLayout {...questionDetailLayout}>
          <FreepadQuestionDetailForm {...props.questionDetails} />
        </StepContentLayout>
      ),
    },
    {
      title: answers.title,
      Content: (
        <StepContentLayout {...answers}>
          <FreepadAnswerForm {...props.answers} />
        </StepContentLayout>
      ),
    },
    {
      title: optional.title,
      Content: (
        <StepContentLayout {...optional}>
          <FreepadOptionalForm {...props.optional} />
        </StepContentLayout>
      ),
    },
  ];

  return (
    <CommonLayout {...layoutProps}>
      <Stepper orientation="vertical" activeStep={props.activeStep}>
        {steps.map(step => {
          return (
            <Step key={step.title}>
              <StepLabel>{step.title}</StepLabel>
              <StepContent>{step.Content}</StepContent>
            </Step>
          );
        })}
      </Stepper>
    </CommonLayout>
  );
};

FreepadResourceEditor.displayName = "FreepadResourceEditor";

export default FreepadResourceEditor;
