import * as React from "react";

export type UsePaginatorArgs = {
  /** root represents element id for IntersectionObserver of root **/
  root: string;
  /** target represents element id for observe target to intersect. **/
  target: string;
  /** callback is for the callback when the target is intersecting **/
  callback: () => void;
  deps: React.DependencyList;
};

export const usePaginator = (args: UsePaginatorArgs): void => {
  const { root, target, callback, deps } = args;
  React.useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => entry.isIntersecting && callback());
      },
      {
        root: root ? document.getElementById(root) : null,
        rootMargin: "0px",
        threshold: 0.25,
      },
    );

    //TODO: refactor using ref https://reactjs.org/docs/refs-and-the-dom.html
    const element = document.getElementById(target);
    if (element) observer.observe(element);
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [root, target, callback, ...(deps ?? [])]);
};
