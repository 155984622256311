import Box, { BoxProps } from "@mui/material/Box";
import * as React from "react";

export type TabPanelBoxProps = BoxProps & {
  children?: React.ReactNode;
  index: string;
  value: string;
};

const TabPanelBox: React.FC<TabPanelBoxProps> = props => {
  const { index, value, children, ...rest } = props;
  return (
    <Box role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...rest}>
      {value === index && children}
    </Box>
  );
};

TabPanelBox.displayName = "TabPanelBox";

export default TabPanelBox;
