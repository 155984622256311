import { useTranslation } from "@hireroo/i18n";
import { ConfirmInterviewForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";

import LiveCodingLayout, { LiveCodingLayoutProps } from "../../layouts/LiveCodingLayout/LiveCodingLayout";
import ConfirmForm, { ConfirmFormProps } from "./parts/ConfirmForm/ConfirmForm";
import EntryInterviewOverviewSection, {
  EntryInterviewOverviewSectionProps,
} from "./parts/EntryInterviewOverviewSection/EntryInterviewOverviewSection";
import Onboarding, { OnboardingProps } from "./parts/Onboarding/Onboarding";

const Heading = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  fontWeight: 700,
  textAlign: "center",
  marginBottom: theme.spacing(1.5),
}));

export type EntryRemoteInterviewProps = {
  layout: LiveCodingLayoutProps;
  entryInterviewOverviewSection: EntryInterviewOverviewSectionProps;
  onboarding: OnboardingProps;
  confirmForm: ConfirmFormProps;
};

const EntryRemoteInterview: React.FC<EntryRemoteInterviewProps> = props => {
  const { t } = useTranslation();
  const validateSchema = ConfirmInterviewForm.useConfirmInterviewFormSchema({
    enableEmailField: false,
  });
  const methods = useForm({
    resolver: zodResolver(validateSchema),
    defaultValues: {
      name: "",
      agreement: false,
      email: undefined,
    },
  });

  return (
    <LiveCodingLayout {...props.layout}>
      <Container sx={{ paddingY: "1em" }}>
        <Heading variant="h5">{t("エントリー")}</Heading>
        <Stack spacing={2} m={"auto"} sx={{ minWidth: "640px", maxWidth: "800px" }}>
          <FormProvider {...methods}>
            <EntryInterviewOverviewSection {...props.entryInterviewOverviewSection} />
            <Onboarding {...props.onboarding} />
            <ConfirmForm {...props.confirmForm} />
          </FormProvider>
        </Stack>
      </Container>
    </LiveCodingLayout>
  );
};

EntryRemoteInterview.displayName = "EntryRemoteInterview";

export default EntryRemoteInterview;
