import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateTalentMemberSettingsPropsArgs, useGenerateProps } from "./useGenerateProps";

export type TalentMemberSettingsContainerProps = GenerateTalentMemberSettingsPropsArgs;

const TalentMemberSettingsContainer: React.FC<TalentMemberSettingsContainerProps> = props => {
  const talentMemberSettingsProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.EmployeeMemberSettings {...talentMemberSettingsProps} />
    </ErrorBoundary>
  );
};

TalentMemberSettingsContainer.displayName = "TalentMemberSettingsContainer";

export default withErrorBoundary(TalentMemberSettingsContainer, {});
