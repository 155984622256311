import { useTranslation } from "@hireroo/i18n";
import { TechnicalCommentEvaluationForm as TechnicalCommentEvaluationFormValidator } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import MetricsList, { MetricsListProps } from "../MetricsList/MetricsList";
import TextFilterField, { TextFilterFieldProps } from "../TextFilterField/TextFilterField";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "&.MuiPaper-root-MuiDialog-paper": {
    borderRadius: theme.shape.borderRadius * 2,
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  borderRadius: theme.shape.borderRadius * 2,
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  "&. MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "&.MuiDialogContent-dividers": {
    borderBottom: "none",
  },
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: 8,
  top: 8,
  color: theme.palette.grey[500],
}));

export type MetricSearchMenuProps = {
  open: boolean;
  onClose?: () => void;
  metricList: MetricsListProps;
  textField: TextFilterFieldProps;
  actionButton: Pick<ButtonProps, "onClick">;
  defaultValues: TechnicalCommentEvaluationFormValidator.TechnicalCommentEvaluationListFormSchema;
  onSubmit: SubmitHandler<TechnicalCommentEvaluationFormValidator.TechnicalCommentEvaluationListFormSchema>;
};

const MetricSearchMenu: React.FC<MetricSearchMenuProps> = props => {
  const { onSubmit } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const validateSchema = TechnicalCommentEvaluationFormValidator.useTechnicalCommentEvaluationListForm();
  const methods = useForm<TechnicalCommentEvaluationFormValidator.TechnicalCommentEvaluationListFormSchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: props.defaultValues,
  });

  const dialogProps: DialogProps = {
    open: props.open,
    onClose: props.onClose,
    fullWidth: true,
    maxWidth: "md",
    PaperComponent: props => <Paper {...props} sx={{ borderRadius: theme.shape.borderRadius }} />,
  };

  const actionButtonProps: ButtonProps = {
    ...props.actionButton,
    onClick: event => {
      props.actionButton.onClick?.(event);
      methods.handleSubmit(fields => {
        onSubmit(fields);
      }, console.warn)();
    },
    color: "secondary",
    variant: "contained",
  };

  React.useEffect(() => {
    methods.setValue("items", props.defaultValues.items);
  }, [methods, props.defaultValues.items]);

  return (
    <FormProvider {...methods}>
      <StyledDialog {...dialogProps}>
        <Box p={2}>
          <Typography variant="subtitle2">{t("評価項目を追加")}</Typography>
          <CloseIconButton onClick={props.onClose}>
            <CloseIcon />
          </CloseIconButton>
        </Box>
        <StyledDialogContent dividers>
          <Stack direction="column" spacing={3}>
            <TextFilterField {...props.textField} />
            <MetricsList {...props.metricList} />
          </Stack>
        </StyledDialogContent>
        <DialogActions>
          <Button {...actionButtonProps}>{t("追加")}</Button>
        </DialogActions>
      </StyledDialog>
    </FormProvider>
  );
};

MetricSearchMenu.displayName = "MetricSearchMenu";

export default MetricSearchMenu;
