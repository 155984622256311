import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateScreeningTestRetakeConfirmPropsArgs, useGenerateProps } from "./useGenerateProps";

export type ScreeningTestRetakeConfirmContainerProps = GenerateScreeningTestRetakeConfirmPropsArgs;

const ScreeningTestRetakeConfirmContainer: React.FC<ScreeningTestRetakeConfirmContainerProps> = props => {
  const screeningTestRetakeConfirmProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ScreeningTestRetakeConfirm {...screeningTestRetakeConfirmProps} />
    </ErrorBoundary>
  );
};

ScreeningTestRetakeConfirmContainer.displayName = "ScreeningTestRetakeConfirmContainer";

export default withErrorBoundary(ScreeningTestRetakeConfirmContainer, {});
