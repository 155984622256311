import { subscribeKey } from "valtio/utils";

import { state } from "./State";
import type * as Types from "./types";

export type SubscribeReviewedSpotCallback = (reviewedSpot: Types.ReviewedSpot) => void;

export const subscribeReviewedSpotKey = (callback: SubscribeReviewedSpotCallback) => {
  return subscribeKey(state, "reviewedSpot", reviewSpot => {
    if (reviewSpot) {
      callback(reviewSpot);
    }
  });
};
