import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { styled } from "@mui/material/styles";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import * as React from "react";
import { useController } from "react-hook-form";

import { useSearchFormContext } from "../../PrivateContext";

type FieldValue = string;

export type TextFilterFieldProps = Pick<TextFieldProps, "id" | "placeholder" | "disabled" | "sx"> & {
  name: string;
};

const HEIGHT = 48;

const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.Other.FilledInputBG,
  borderRadius: 8,
  ".MuiInputBase-root": {
    height: HEIGHT,
  },
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
    borderRadius: 8,
  },
}));

const TextFilterField: React.FC<TextFilterFieldProps> = props => {
  const { name, ...rest } = props;
  const { subscribeClearAllFields: onClearFields } = useSearchFormContext();
  const { field } = useController<Record<string, FieldValue>>({
    name: name,
  });
  const [value, setValue] = React.useState(field.value);

  const resetField = React.useCallback(() => {
    setValue("");
    field.onChange("");
  }, [field]);

  React.useEffect(() => {
    const stop = onClearFields(() => {
      setValue("");
    });
    return () => {
      stop();
    };
  }, [onClearFields]);

  const textFieldProps: TextFieldProps = {
    size: "small",
    fullWidth: true,
    value: value,
    color: "secondary",
    inputRef: field.ref,
    onChange: event => {
      if (typeof event.target.value === "string") {
        setValue(event.target.value);
      }
    },
    onBlur: () => {
      field.onChange(value);
    },
    onKeyDown: event => {
      if (event.key === "Enter") {
        field.onChange(event);
      }
    },
    InputProps: {
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
      endAdornment: (value || undefined) && (
        <IconButton
          disabled={props.disabled}
          onClick={() => {
            resetField();
          }}
        >
          <CancelIcon color={props.disabled ? "disabled" : "action"} />
        </IconButton>
      ),
    },
    ...rest,
  };

  return <StyledTextField {...textFieldProps} />;
};

TextFilterField.displayName = "TextFilterField";

export default TextFilterField;
