import { getRef } from "@hireroo/firebase";
import { FirebaseFieldSchema } from "@hireroo/validator";
import firebase from "firebase/compat/app";
import { useCallback, useEffect, useRef } from "react";

import { revisionFromId, revisionToId } from "../../firepad";
import type { AccessEventPayload, Action, BrowserUniqSendPayload, FirebaseReferenceObject, Params, SendPayload } from "./types";

export const getRevisionLatestId = (snapshot: Record<string, SendPayload>): number => {
  const list = Object.keys(snapshot).sort();
  return revisionFromId(list[list.length - 1]);
};

const createFirebaseReference = (params: Params): FirebaseReferenceObject => {
  switch (params.appType) {
    case "challenge": {
      const key = `challenges/${params.challengeId}/questions/${params.questionId}/event`;
      return { key, ref: getRef("challenge", key) };
    }
    case "challenge-language": {
      const key = `challenges/${params.challengeId}/questions/${params.questionId}/languages/${params.language}/event`;
      return { key, ref: getRef("challenge-language", key) };
    }
    case "quiz": {
      const key = `quizzes/${params.quizId}/packages/${params.packageId}/questions/${params.questionId}/event`;
      return { key, ref: getRef("quiz", key) };
    }
    case "systemDesign": {
      const key = `systemDesigns/${params.systemDesignId}/questions/${params.questionId}/event`;
      return { key, ref: getRef("systemDesign", key) };
    }
    case "project": {
      const key = `projects/${params.projectId}/questions/${params.questionId}/event`;
      return { key, ref: getRef("project", key) };
    }
    case "liveCoding": {
      const key = `liveCodings/${params.liveCodingId}/sessions/${params.sessionId}/event`;
      return { key, ref: getRef("liveCoding", key) };
    }
    default: {
      return ((): never => params)();
    }
  }
};

export const useFirebaseSender = (params: Params): Action => {
  const { uid } = params;
  const firebaseRefObject = useRef(createFirebaseReference(params));
  const indexRef = useRef<number>(0);
  useEffect(() => {
    firebaseRefObject.current = createFirebaseReference(params);
    indexRef.current = 0;
    const onceValueCallback = (snapshot: firebase.database.DataSnapshot) => {
      if (typeof snapshot.key !== "string") {
        return;
      }
      const snapshotValue = snapshot.val() as Record<string, SendPayload>;
      if (!snapshotValue) {
        return;
      }
      const latestId = getRevisionLatestId(snapshotValue);
      indexRef.current = latestId;
    };

    const childAddedCallback = (snapshot: firebase.database.DataSnapshot) => {
      if (typeof snapshot.key !== "string") {
        return;
      }
      const latestId = revisionFromId(snapshot.key);
      indexRef.current = latestId;
    };

    void firebaseRefObject.current.ref.once("value", onceValueCallback);
    void firebaseRefObject.current.ref.on("child_added", childAddedCallback);

    return () => {
      firebaseRefObject.current.ref.off("value", onceValueCallback);
      firebaseRefObject.current.ref.off("child_added", childAddedCallback);
    };
  }, [params]);

  const pushEventHistory = useCallback(
    async (payload: SendPayload) => {
      const browserUniquePayload: BrowserUniqSendPayload = {
        ...payload,
        /**
         * key `a` is fixed with UserID
         */
        a: uid,
      };
      const nextStringId = revisionToId(indexRef.current + 1);
      await firebaseRefObject.current.ref.child(nextStringId).set(browserUniquePayload);
    },
    [firebaseRefObject, uid],
  );

  const pushAccessEvent = useCallback(
    async (payload: Omit<AccessEventPayload, "s">) => {
      const browserUniquePayload: AccessEventPayload & { a: string } = {
        ...payload,
        s: "access",
        /**
         * key `a` is fixed with UserID
         */
        a: uid,
      };

      const events = await firebaseRefObject.current.ref.once("value");
      const ipAddresses: Set<string> = new Set();
      if (events.exists()) {
        events.forEach(e => {
          const result = FirebaseFieldSchema.AccessEventSchema.safeParse(e.val());
          if (result.success && result.data.l) {
            ipAddresses.add(result.data.l);
          }
        });
      }

      /**
       * If a new user id is detected, or if the user's location changes after the browser is started again, record in firebase
       */
      if (!events.exists() || !ipAddresses.has(payload.l)) {
        const nextStringId = revisionToId(indexRef.current + 1);
        await firebaseRefObject.current.ref.child(nextStringId).set(browserUniquePayload);
      }
    },
    [firebaseRefObject, uid],
  );

  return {
    pushEventHistory: pushEventHistory,
    pushAccessEvent: pushAccessEvent,
  };
};
