import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { ProjectFrontendTestCaseSchemaV2 } from "@hireroo/validator";

import { safeJsonParse } from "../parser";

const frontendTestCaseSchema = ProjectFrontendTestCaseSchemaV2.generateFrontendCorrectnessTestCase();

export const parseFrontendTestCase = (data: string): ProjectFrontendTestCaseSchemaV2.FrontendCorrectnessTestCase | undefined => {
  const parsedValue = safeJsonParse(data);
  const result = frontendTestCaseSchema.safeParse(parsedValue);
  if (result.success) {
    return result.data;
  } else {
    return;
  }
};

const frontendTestCasesSchema = ProjectFrontendTestCaseSchemaV2.generateFrontendCorrectnessTestCases();

export const parseFrontendTestCases = (data: string): ProjectFrontendTestCaseSchemaV2.FrontendCorrectnessTestCases => {
  const parsedValue = safeJsonParse(data);
  const result = frontendTestCasesSchema.safeParse(parsedValue);
  if (result.success) {
    return result.data;
  } else {
    return [];
  }
};

const frontendCorrectnessTestCaseResultSchema = ProjectFrontendTestCaseSchemaV2.generateFrontendCorrectnessTestCaseResult();

export const parseFrontendCorrectnessTestCaseResult = (
  data: string,
): ProjectFrontendTestCaseSchemaV2.FrontendCorrectnessTestCaseResult | undefined => {
  const parsedValue = safeJsonParse(data);
  const result = frontendCorrectnessTestCaseResultSchema.safeParse(parsedValue);
  if (result.success) {
    return result.data;
  } else {
    return undefined;
  }
};

const frontendCorrectnessTestCaseResultsSchema = ProjectFrontendTestCaseSchemaV2.generateFrontendCorrectnessTestCaseResults();

export const parseFrontendCorrectnessTestCaseResults = (
  data: string,
): ProjectFrontendTestCaseSchemaV2.FrontendCorrectnessTestCaseResults | undefined => {
  const parsedValue = safeJsonParse(data);
  const result = frontendCorrectnessTestCaseResultsSchema.safeParse(parsedValue);
  if (result.success) {
    return result.data;
  } else {
    return undefined;
  }
};

const frontendPerformanceTestCaseResultsSchema = ProjectFrontendTestCaseSchemaV2.generateFrontendPerformanceTestCaseResults();

export const parseFrontendPerformanceTestCaseResults = (data: string): ProjectFrontendTestCaseSchemaV2.FrontendPerformanceTestCaseResults => {
  const parsedValue = safeJsonParse(data);
  const result = frontendPerformanceTestCaseResultsSchema.safeParse(parsedValue);
  if (result.success) {
    return result.data;
  } else {
    return [];
  }
};

// Actions
const LOAD = "LOAD";
const CLICK = "CLICK";
const KEYS = "KEYS";
const TEXT = "TEXT";
const ATTRIBUTE = "ATTRIBUTE";

// Conditions
const EQ = "EQ";
const NEQ = "NEQ";
const ERR = "ERR";

export const ActionMap = {
  LOAD,
  CLICK,
  KEYS,
  TEXT,
  ATTRIBUTE,
} as const;

export const ConditionMap = {
  EQ,
  NEQ,
  ERR,
};

export type ActionType = (typeof ActionMap)[keyof typeof ActionMap];

export const useScenarioTestAction = () => {
  const { t } = useTranslation();
  const lang = useLanguageCode();
  return (command: ProjectFrontendTestCaseSchemaV2.PerCommandSchema): string => {
    let actionText = lang === "en" ? `Unknown` : `不明`;

    // TODO: Please fix testcase.
    switch (command?.action) {
      case ActionMap.LOAD:
        actionText = t("ページを表示する");
        break;
      case ActionMap.KEYS:
        actionText = lang === "en" ? `Input "${command.body}"` : `"${command.body}" と入力`;
        break;
      case ActionMap.CLICK:
        actionText = lang === "en" ? `Click "${command.id}"` : `"${command.id}" をクリック`;
        break;
      case ActionMap.TEXT:
        if (command.condition === ConditionMap.EQ) actionText = `"${command.expected}" ${t("と表示されている")}`;

        if (command.condition === ConditionMap.NEQ) actionText = `"${command.expected}" ${t("が表示されていない")}`;

        if (command.condition === ConditionMap.ERR) actionText = t("エラーが発生する");
        break;
      case ActionMap.ATTRIBUTE:
        actionText =
          lang === "en"
            ? `Confirm that the ${command.body} attribute is "${command.expected}"`
            : `${command.body} 属性が "${command.expected}" であることを確認する`;
        break;
      default:
        actionText = lang === "en" ? `Unknown` : `不明`;
    }

    return actionText;
  };
};

/**
 * lighthouse metrics
 */
export const MetricsMap = {
  /** @see https://web.dev/first-contentful-paint/ */
  FCP: "fcp",
  /** @see https://web.dev/speed-index/ */
  SI: "si",
  /** @see https://web.dev/lighthouse-largest-contentful-paint/ */
  LCP: "lcp",
  /** @see https://web.dev/interactive/ */
  TTI: "tti",
  /** @see https://web.dev/lighthouse-total-blocking-time/ */
  TBT: "tbt",
  /** @see https://web.dev/cls/ */
  CLS: "cls",
} as const;

export type MetricsType = (typeof MetricsMap)[keyof typeof MetricsMap];

export const ScoreMap = {
  GOOD: "GOOD",
  NEEDS_IMPROVEMENT: "NEEDS_IMPROVEMENT",
  POOR: "POOR",
} as const;

export type ScoreType = (typeof ScoreMap)[keyof typeof ScoreMap];

const metricsScoreMap: Record<MetricsType, { GOOD: number; POOR: number }> = {
  fcp: {
    GOOD: 1800,
    POOR: 3000,
  },
  si: {
    GOOD: 3400,
    POOR: 5800,
  },
  lcp: {
    GOOD: 2500,
    POOR: 4000,
  },
  tti: {
    GOOD: 3800,
    POOR: 7300,
  },
  tbt: {
    GOOD: 200,
    POOR: 600,
  },
  cls: {
    GOOD: 0.1,
    POOR: 0.25,
  },
};

export const determineMetrics = (key: MetricsType, value: number): ScoreType => {
  switch (key) {
    // see: https://web.dev/first-contentful-paint/#how-lighthouse-determines-your-fcp-score
    case MetricsMap.FCP:
      if (value <= metricsScoreMap.fcp.GOOD) return ScoreMap.GOOD;
      if (metricsScoreMap.fcp.POOR < value) return ScoreMap.POOR;
      return ScoreMap.NEEDS_IMPROVEMENT;

    // see: https://web.dev/speed-index/#how-lighthouse-determines-your-speed-index-score
    case MetricsMap.SI:
      if (value <= metricsScoreMap.si.GOOD) return ScoreMap.GOOD;
      if (metricsScoreMap.si.POOR < value) return ScoreMap.POOR;
      return ScoreMap.NEEDS_IMPROVEMENT;

    // see: https://web.dev/lighthouse-largest-contentful-paint/#how-lighthouse-determines-your-lcp-score
    case MetricsMap.LCP:
      if (value <= metricsScoreMap.lcp.GOOD) return ScoreMap.GOOD;
      if (metricsScoreMap.lcp.POOR < value) return ScoreMap.POOR;
      return ScoreMap.NEEDS_IMPROVEMENT;

    // see: https://web.dev/interactive/#how-lighthouse-determines-your-tti-score
    case MetricsMap.TTI:
      if (value <= metricsScoreMap.tti.GOOD) return ScoreMap.GOOD;
      if (metricsScoreMap.tti.POOR < value) return ScoreMap.POOR;
      return ScoreMap.NEEDS_IMPROVEMENT;

    // see: https://web.dev/lighthouse-total-blocking-time/#how-lighthouse-determines-your-tbt-score
    case MetricsMap.TBT:
      if (value <= metricsScoreMap.tbt.GOOD) return ScoreMap.GOOD;
      if (metricsScoreMap.tbt.POOR < value) return ScoreMap.POOR;
      return ScoreMap.NEEDS_IMPROVEMENT;

    // see: https://web.dev/cls/#what-is-a-good-cls-score
    case MetricsMap.CLS:
      if (value <= metricsScoreMap.cls.GOOD) return ScoreMap.GOOD;
      if (metricsScoreMap.cls.POOR < value) return ScoreMap.POOR;
      return ScoreMap.NEEDS_IMPROVEMENT;
  }
};
