/* eslint no-prototype-builtins: 0 */
import { getLanguage } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import * as Sentry from "@sentry/react";

import {
  CACHE_POLICY,
  CacheSettings,
  CommentElement,
  CommentSettings,
  ComponentType,
  DefaultSettings,
  EdgeElement,
  EdgeSettings,
  ELEMENT_LABEL,
  ELEMENT_TYPE,
  ElementDataKeyMap,
  ElementLabel,
  ElementMasterData,
  FLOW_ACTION,
  FlowAction,
  FlowElement,
  NetworkElement,
  NodeElement,
  QUEUE_TYPE,
  QueueSettings,
  REPLICATION,
  SqlSettings,
  VmSettings,
} from "../features";
import { createComment, createEdge } from "./flowChart";

const isValidHistory = (history: FlowAction): boolean => {
  switch (history.s) {
    case FLOW_ACTION.addElement:
      return (
        history.hasOwnProperty("v") &&
        history.v.hasOwnProperty("id") &&
        history.v.hasOwnProperty("l") &&
        history.v.hasOwnProperty("x") &&
        history.v.hasOwnProperty("y")
      );
    case FLOW_ACTION.addComment:
      return (
        history.hasOwnProperty("v") &&
        history.v.hasOwnProperty("id") &&
        history.v.hasOwnProperty("c") &&
        history.v.hasOwnProperty("x") &&
        history.v.hasOwnProperty("y") &&
        history.v.hasOwnProperty("f")
      );
    case FLOW_ACTION.editComment:
      return history.hasOwnProperty("v") && history.v.hasOwnProperty("id") && history.v.hasOwnProperty("c");
    case FLOW_ACTION.moveElements:
      return history.hasOwnProperty("v") && history.v.hasOwnProperty("ids");
    case FLOW_ACTION.shapeElement:
      return (
        history.hasOwnProperty("v") &&
        history.v.hasOwnProperty("id") &&
        history.v.hasOwnProperty("p") &&
        history.v.hasOwnProperty("dx") &&
        history.v.hasOwnProperty("dy")
      );
    case FLOW_ACTION.deleteElements:
      return history.hasOwnProperty("v") && history.v.hasOwnProperty("ids");
    case FLOW_ACTION.reviveElements:
      return history.hasOwnProperty("v") && history.v.hasOwnProperty("ids");
    case FLOW_ACTION.connectNodes:
      return history.hasOwnProperty("v") && history.v.hasOwnProperty("id") && history.v.hasOwnProperty("s") && history.v.hasOwnProperty("t");
    case FLOW_ACTION.reconnectEdge:
      return history.hasOwnProperty("v") && history.v.hasOwnProperty("id") && history.v.hasOwnProperty("s") && history.v.hasOwnProperty("t");
    case FLOW_ACTION.pasteElements:
      return history.hasOwnProperty("v") && history.v.hasOwnProperty("dst");
    case FLOW_ACTION.updateSettings:
      return history.hasOwnProperty("v") && history.v.hasOwnProperty("id") && history.v.hasOwnProperty("u");
    // in case of 'useh' or 'subq'
    default:
      return true;
  }
};

const createElementFromLabel = (
  id: string,
  label: ElementLabel,
  componentType: ComponentType,
  minX: number,
  minY: number,
  timestamp: number,
): NodeElement | NetworkElement => {
  const lang = getLanguage();

  switch (label) {
    case ELEMENT_LABEL.vpc:
      return {
        id,
        type: ELEMENT_TYPE.network,
        label,
        geometry: { minX: minX, minY: minY, maxX: minX + 640, maxY: minY + 640 },
        settings: {
          name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
        },
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.subnet:
      return {
        id,
        type: ELEMENT_TYPE.network,
        label,
        geometry: { minX: minX, minY: minY, maxX: minX + 640, maxY: minY + 640 },
        settings: {
          name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
        },
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.availabilityZone:
      return {
        id,
        type: ELEMENT_TYPE.network,
        label,
        geometry: { minX: minX, minY: minY, maxX: minX + 640, maxY: minY + 640 },
        settings: {
          name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
        },
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.region:
      return {
        id,
        type: ELEMENT_TYPE.network,
        label,
        geometry: { minX: minX, minY: minY, maxX: minX + 640, maxY: minY + 640 },
        settings: {
          name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
        },
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.user:
      return {
        id,
        type: ELEMENT_TYPE.node,
        label,
        geometry: { minX: minX, minY: minY, maxX: minX + 160, maxY: minY + 160 },
        settings: {
          name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
        },
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.dns:
      return {
        id,
        type: ELEMENT_TYPE.node,
        label,
        geometry: { minX: minX, minY: minY, maxX: minX + 160, maxY: minY + 160 },
        settings: {
          name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
        },
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.vm:
      return {
        id,
        type: ELEMENT_TYPE.node,
        label,
        geometry: { minX: minX, minY: minY, maxX: minX + 160, maxY: minY + 160 },
        settings: {
          name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
          autoScale: false,
        },
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.serverless:
      return {
        id,
        type: ELEMENT_TYPE.node,
        label,
        geometry: { minX: minX, minY: minY, maxX: minX + 160, maxY: minY + 160 },
        settings: {
          name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
        },
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.loadBalancer:
      return {
        id,
        type: ELEMENT_TYPE.node,
        label,
        geometry: { minX: minX, minY: minY, maxX: minX + 160, maxY: minY + 160 },
        settings: {
          name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
        },
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.cache:
      return {
        id,
        type: ELEMENT_TYPE.node,
        label,
        geometry: { minX: minX, minY: minY, maxX: minX + 160, maxY: minY + 160 },
        settings: {
          name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
          cachePolicy: CACHE_POLICY.ttl,
        },
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.sql:
      return {
        id,
        type: ELEMENT_TYPE.node,
        label,
        geometry: { minX: minX, minY: minY, maxX: minX + 160, maxY: minY + 160 },
        settings: {
          name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
          enableReplication: false,
          replication: REPLICATION.primary,
          enableSharding: false,
        },
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.nosql:
      return {
        id,
        type: ELEMENT_TYPE.node,
        label,
        geometry: { minX: minX, minY: minY, maxX: minX + 160, maxY: minY + 160 },
        settings: {
          name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
        },
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.elasticsearch:
      return {
        id,
        type: ELEMENT_TYPE.node,
        label,
        geometry: { minX: minX, minY: minY, maxX: minX + 160, maxY: minY + 160 },
        settings: {
          name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
        },
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.storage:
      return {
        id,
        type: ELEMENT_TYPE.node,
        label,
        geometry: { minX: minX, minY: minY, maxX: minX + 160, maxY: minY + 160 },
        settings: {
          name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
        },
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.imageRegistry:
      return {
        id,
        type: ELEMENT_TYPE.node,
        label,
        geometry: { minX: minX, minY: minY, maxX: minX + 160, maxY: minY + 160 },
        settings: {
          name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
        },
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.cdn:
      return {
        id,
        type: ELEMENT_TYPE.node,
        label,
        geometry: { minX: minX, minY: minY, maxX: minX + 160, maxY: minY + 160 },
        settings: {
          name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
        },
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.queue:
      return {
        id,
        type: ELEMENT_TYPE.node,
        label,
        geometry: { minX: minX, minY: minY, maxX: minX + 160, maxY: minY + 160 },
        settings: {
          name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
          queueType: QUEUE_TYPE.standard,
        },
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.pubsub:
      return {
        id,
        type: ELEMENT_TYPE.node,
        label,
        geometry: { minX: minX, minY: minY, maxX: minX + 160, maxY: minY + 160 },
        settings: {
          name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
        },
        updatedAt: timestamp,
      };
    case ELEMENT_LABEL.scheduler:
      return {
        id,
        type: ELEMENT_TYPE.node,
        label,
        geometry: { minX: minX, minY: minY, maxX: minX + 160, maxY: minY + 160 },
        settings: {
          name: resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title"),
        },
        updatedAt: timestamp,
      };
  }
};

export const elementsFromFirebaseV1 = (
  histories: FlowAction[],
  componentType: ComponentType,
  initialElements: FlowElement[] = [],
  at?: number,
): { elements: Record<string, FlowElement>; elementIds: string[] } => {
  let elements: Record<string, FlowElement> = {};
  let elementIds: string[] = [];

  if (initialElements) {
    initialElements.forEach(element => {
      elements[element.id] = element;
      elementIds.push(element.id);
    });
  }

  const duplicateEdgeIds: string[] = [];
  const sliced = at !== undefined ? histories.slice(0, at + 1) : histories;

  sliced.forEach(history => {
    // Skip the process when the history is invalid, otherwise app crashes
    if (!isValidHistory(history)) {
      Sentry.captureException(`failed to parse history ${history.s}`);
      return;
    }

    switch (history.s) {
      case FLOW_ACTION.addElement: {
        const element = createElementFromLabel(history.v.id, history.v.l, componentType, history.v.x, history.v.y, history.t);
        elements[element.id] = element;
        elementIds.push(element.id);
        return;
      }
      case FLOW_ACTION.addComment: {
        const comment = createComment(history.v.id, history.v.c, history.v.x, history.v.y, history.v.f, history.t);
        elements[comment.id] = comment;
        elementIds.push(comment.id);
        return;
      }
      case FLOW_ACTION.editComment: {
        const comment = elements[history.v.id] as CommentElement;
        elements[history.v.id] = { ...comment, content: history.v.c, updatedAt: history.t };
        return;
      }
      case FLOW_ACTION.moveElements: {
        elementIds.forEach(id => {
          const element = elements[id];
          if (history.v.ids.includes(element.id) && element.type !== ELEMENT_TYPE.edge) {
            elements[id] = {
              ...element,
              geometry: {
                minX: element.geometry.minX + history.v.dx,
                minY: element.geometry.minY + history.v.dy,
                maxX: element.geometry.maxX + history.v.dx,
                maxY: element.geometry.maxY + history.v.dy,
              },
            };
          }
        });
        return;
      }
      case FLOW_ACTION.shapeElement: {
        const element = elements[history.v.id];
        if (element.type !== ELEMENT_TYPE.network) return;

        let geometry = element.geometry;

        switch (history.v.p) {
          case "leftTop":
            geometry = { ...geometry, minX: geometry.minX + history.v.dx, minY: geometry.minY + history.v.dy };
            break;
          case "rightTop":
            geometry = { ...geometry, maxX: geometry.maxX + history.v.dx, minY: geometry.minY + history.v.dy };
            break;
          case "rightBottom":
            geometry = { ...geometry, maxX: geometry.maxX + history.v.dx, maxY: geometry.maxY + history.v.dy };
            break;
          case "leftBottom":
            geometry = { ...geometry, minX: geometry.minX + history.v.dx, maxY: geometry.maxY + history.v.dy };
            break;
          case "leftMid":
            geometry = { ...geometry, minX: geometry.minX + history.v.dx };
            break;
          case "topMid":
            geometry = { ...geometry, minY: geometry.minY + history.v.dy };
            break;
          case "rightMid":
            geometry = { ...geometry, maxX: geometry.maxX + history.v.dx };
            break;
          case "bottomMid":
            geometry = { ...geometry, maxY: geometry.maxY + history.v.dy };
            break;
        }

        elements[history.v.id] = { ...element, geometry };
        return;
      }
      case FLOW_ACTION.deleteElements: {
        elementIds = elementIds.filter(elementId => {
          const element = elements[elementId];
          const isRemovedElement = history.v.ids.includes(elementId);
          const isNeighborEdge =
            element.type === ELEMENT_TYPE.edge && (history.v.ids.includes(element.source) || history.v.ids.includes(element.target));
          return !isRemovedElement && !isNeighborEdge;
        });
        return;
      }
      case FLOW_ACTION.reviveElements: {
        history.v.ids.forEach(id => {
          if (!elementIds.includes(id) && elements[id]) {
            const cancatIds = [...history.v.ids, ...elementIds];
            const element = elements[id];
            // Skip the operation when trying to revive the edge with nodes deleted by a peer
            if (element.type === ELEMENT_TYPE.edge && (!cancatIds.includes(element.source) || !cancatIds.includes(element.target))) {
              return;
            }
            elementIds.push(id);
          }
        });
        return;
      }
      case FLOW_ACTION.connectNodes: {
        const edge = createEdge(history.v.id, history.v.s, history.v.t, history.t);
        elements[history.v.id] = edge;

        // When the source or target node does not exist, skip the subsequent process
        if (!elementIds.includes(history.v.s) || !elementIds.includes(history.v.t)) return;

        // Check if the duplicate edge exists or not
        let duplicateEdge: EdgeElement | undefined;
        elementIds.forEach(elementId => {
          const element = elements[elementId];
          if (element.type === ELEMENT_TYPE.edge) {
            if (
              (element.source === history.v.s && element.target === history.v.t) ||
              (element.source === history.v.t && element.target === history.v.s)
            ) {
              duplicateEdge = element;
            }
          }
        });

        // If the duplicate node exists, last one wins
        if (duplicateEdge) {
          // Discard the incoming change since the existing edge is the latest
          if (duplicateEdge.updatedAt > history.t) {
            duplicateEdgeIds.push(history.v.id);
            return;
          } else {
            // Swap the old edge and the new edge since the incoming change is the latest
            const duplicateEdgeId = duplicateEdge.id;
            elementIds = elementIds.filter(id => id !== duplicateEdgeId);
            duplicateEdgeIds.push(duplicateEdgeId);
            elementIds.push(history.v.id);
          }
        } else {
          elementIds.push(history.v.id);
        }
        return;
      }
      case FLOW_ACTION.reconnectEdge: {
        const edge = elements[history.v.id] as EdgeElement;

        // Discard the incoming change since the local edge is the latest
        if (edge.updatedAt > history.t) return;

        // If the duplicate edge is reconnected, it will not be duplicate, hence revive the edge id
        if (duplicateEdgeIds.includes(edge.id)) {
          duplicateEdgeIds.filter(id => id !== edge.id);
          elementIds.push(edge.id);
        }

        elements[history.v.id] = {
          ...edge,
          source: history.v.s,
          target: history.v.t,
          updatedAt: history.t,
        };

        // When the source or target node does not exist, delete the reconnecting edge id from the array
        if (!elementIds.includes(history.v.s) || !elementIds.includes(history.v.t)) {
          elementIds = elementIds.filter(id => id !== edge.id);
          return;
        }

        // Check if a duplicate edge exists or not
        let duplicateEdge: EdgeElement | undefined;
        elementIds.forEach(elementId => {
          const element = elements[elementId];
          if (element.type === ELEMENT_TYPE.edge) {
            if (
              element.id !== edge.id &&
              ((element.source === history.v.s && element.target === history.v.t) ||
                (element.source === history.v.t && element.target === history.v.s))
            ) {
              duplicateEdge = element;
            }
          }
        });

        // If a duplicate edge exists, last one wins
        if (duplicateEdge) {
          // Delete the reconnecting edge from the array since the connected one is the latest
          if (duplicateEdge.updatedAt > history.t) {
            elementIds = elementIds.filter(id => id !== history.v.id);
            duplicateEdgeIds.push(history.v.id);
            return;
          } else {
            // Delete the connected edge from the array since the reconnecting one is the latest
            const duplicateEdgeId = duplicateEdge.id;
            elementIds = elementIds.filter(id => id !== duplicateEdgeId);
            duplicateEdgeIds.push(duplicateEdgeId);
          }
        }
        return;
      }
      case FLOW_ACTION.pasteElements: {
        history.v.dst.forEach(element => {
          elementIds.push(element.id);
          elements[element.id] = element;
        });
        return;
      }
      case FLOW_ACTION.updateSettings: {
        const updatedElement = elements[history.v.id];
        if (updatedElement.type === ELEMENT_TYPE.comment) {
          const updates = history.v.u as Partial<CommentSettings>;
          elements[history.v.id] = { ...updatedElement, settings: { ...updatedElement.settings, ...updates }, updatedAt: history.t };
        } else if (updatedElement.type === ELEMENT_TYPE.edge) {
          const updates = history.v.u as Partial<EdgeSettings>;
          elements[history.v.id] = { ...updatedElement, settings: { ...updatedElement.settings, ...updates }, updatedAt: history.t };
        } else if (updatedElement.type === ELEMENT_TYPE.node && updatedElement.label === ELEMENT_LABEL.vm) {
          const updates = history.v.u as Partial<VmSettings>;
          let geometry = updatedElement.geometry;
          if (updates.hasOwnProperty("autoScale")) {
            if (updates.autoScale) {
              geometry = {
                ...geometry,
                minX: geometry.minX - 20,
                minY: geometry.minY - 20,
                maxX: geometry.maxX + 20,
                maxY: geometry.maxY + 20,
              };
            } else {
              geometry = {
                ...geometry,
                minX: geometry.minX + 20,
                minY: geometry.minY + 20,
                maxX: geometry.maxX - 20,
                maxY: geometry.maxY - 20,
              };
            }
          }
          elements[history.v.id] = { ...updatedElement, geometry, settings: { ...updatedElement.settings, ...updates }, updatedAt: history.t };
        } else if (updatedElement.type === ELEMENT_TYPE.node && updatedElement.label === ELEMENT_LABEL.sql) {
          const updates = history.v.u as Partial<SqlSettings>;
          elements[history.v.id] = { ...updatedElement, settings: { ...updatedElement.settings, ...updates }, updatedAt: history.t };
        } else if (updatedElement.type === ELEMENT_TYPE.node && updatedElement.label === ELEMENT_LABEL.queue) {
          const updates = history.v.u as Partial<QueueSettings>;
          elements[history.v.id] = { ...updatedElement, settings: { ...updatedElement.settings, ...updates }, updatedAt: history.t };
        } else if (updatedElement.type === ELEMENT_TYPE.node && updatedElement.label === ELEMENT_LABEL.cache) {
          const updates = history.v.u as Partial<CacheSettings>;
          elements[history.v.id] = { ...updatedElement, settings: { ...updatedElement.settings, ...updates }, updatedAt: history.t };
        } else {
          const updates = history.v.u as Partial<DefaultSettings>;
          elements[history.v.id] = { ...updatedElement, settings: { ...updatedElement.settings, ...updates }, updatedAt: history.t };
        }
        return;
      }
      case FLOW_ACTION.resetElements: {
        const initialElementMap: Record<string, FlowElement> = {};
        const initialElementIds: string[] = [];
        initialElements.forEach(element => {
          initialElementMap[element.id] = element;
          initialElementIds.push(element.id);
        });
        elements = initialElementMap;
        elementIds = initialElementIds;
        return;
      }
      default:
        return;
    }
  });

  return { elements, elementIds };
};
