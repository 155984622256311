import { AlgorithmResourceEditor } from "@hireroo/app-store/view-domain/AlgorithmResourceEditor";
import { getLanguage } from "@hireroo/i18n";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import FetchContainer from "./FetchContainer";

export type AlgorithmResourceEditorInitialContainerProps = {};

const AlgorithmResourceEditorInitialContainer: React.FC<AlgorithmResourceEditorInitialContainerProps> = () => {
  React.useEffect(() => {
    AlgorithmResourceEditor.initialize(getLanguage());
    return () => {
      AlgorithmResourceEditor.clear();
    };
  }, []);
  return (
    <ErrorBoundary>
      <FetchContainer />
    </ErrorBoundary>
  );
};
AlgorithmResourceEditorInitialContainer.displayName = "AlgorithmResourceEditorInitialContainer";

export default withErrorBoundary(AlgorithmResourceEditorInitialContainer, {});
