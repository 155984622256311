import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import LiveCodingLayout, { LiveCodingLayoutProps } from "../../layouts/LiveCodingLayout/LiveCodingLayout";
import BaseDialog, { BaseDialogProps } from "../../primitive/BaseDialog/BaseDialog";
import StepContentLayoutLower, {
  StepContentLayoutLowerProps,
} from "../../primitive/StepContentLayout/StepContentLayoutWithoutTitle/StepContentLayoutLower/StepContentLayoutLower";
import Confirmation, { ConfirmationProps } from "./parts/Confirmation/Confirmation";
import ConfirmEvaluationDialog, { ConfirmEvaluationDialogProps } from "./parts/ConfirmEvaluationDialog/ConfirmEvaluationDialog";
import Recommendation, { RecommendationProps } from "./parts/Recommendation/Recommendation";

const StyledPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  min-width: 640px;
  max-width: 800px;
  width: 70%;
  justify-content: center;
  align-items: center;
  padding: 24px;
  margin: auto;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export type RemoteEvaluationProps = {
  /** greater than 0 */
  activeStep: 0 | 1;
  layout: LiveCodingLayoutProps;
  Evaluations: React.ReactNode;
  recommendation: RecommendationProps;
  confirmation: ConfirmationProps;
  confirmEvaluationDialog: ConfirmEvaluationDialogProps;
  submitEvaluationItems: Pick<StepContentLayoutLowerProps, "nextButton">;
  submitConfirm: StepContentLayoutLowerProps;
  laterButton: Pick<Exclude<BaseDialogProps["yesButton"], undefined>, "onClick">;
};

const RemoteEvaluation: React.FC<RemoteEvaluationProps> = props => {
  const theme = useTheme();
  const { activeStep } = props;
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const step1: StepContentLayoutLowerProps = {
    nextButton: {
      ...props.submitEvaluationItems.nextButton,
      children: t("次へ"),
      variant: "contained",
    },
    previousButton: {
      color: "primary",
      variant: "text",
      children: t("あとで評価する"),
      onClick: () => {
        setOpen(true);
      },
    },
  };

  const step2: StepContentLayoutLowerProps = {
    previousButton: {
      ...props.submitConfirm.previousButton,
      children: t("戻る"),
    },
    nextButton: {
      ...props.submitConfirm.nextButton,
      children: t("確定"),
      variant: "contained",
      color: "primary",
    },
  };

  const StepContentMap: Record<number, JSX.Element> = {
    0: (
      <>
        <Typography color="textSecondary" fontSize={14}>
          {t("合否判定、評価項目を入力して、評価を完了してください。")}
        </Typography>
        <StepContentLayoutLower {...step1}>
          <Box mb={3}>
            <Recommendation {...props.recommendation} />
          </Box>
          {props.Evaluations}
        </StepContentLayoutLower>
      </>
    ),
    1: (
      <StepContentLayoutLower {...step2}>
        <Confirmation {...props.confirmation} />
      </StepContentLayoutLower>
    ),
  } as const;

  const laterDialog: BaseDialogProps = {
    open: open,
    title: t("あとで評価します"),
    noButton: {
      color: "primary",
      onClick: () => setOpen(false),
      children: t("キャンセル"),
    },
    yesButton: {
      children: t("確認して終了"),
      ...props.laterButton,
      color: "primary",
    },
  };

  const labels = [t("評価"), t("確認")];

  return (
    <LiveCodingLayout {...props.layout}>
      <StyledPaper elevation={8}>
        <Box display="flex" justifyContent="center" width="100%">
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            {t("お疲れさまでした!")}
          </Typography>
        </Box>

        <Box mt={2} width="100%">
          <Stepper
            activeStep={activeStep}
            orientation="horizontal"
            sx={{
              justifyContent: "center",
              m: "auto",
              width: "392px",
              "& .Mui-active": {
                "& .MuiStepIcon-root": {
                  color: theme.palette.secondary.main,
                },
              },
              "& .Mui-completed": {
                "& .MuiStepIcon-root": {
                  color: theme.palette.secondary.main,
                },
              },
            }}
          >
            {labels.map(label => {
              return (
                <Step key={label} color="secondary">
                  <StepLabel color="secondary">{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          <Box pt={4} px={2}>
            {StepContentMap[activeStep]}
          </Box>
        </Box>
      </StyledPaper>
      <ConfirmEvaluationDialog {...props.confirmEvaluationDialog} />
      <BaseDialog {...laterDialog}>
        <Typography>{t("入力された評価項目は削除されません。")}</Typography>
        <Typography>{t("インタビュー終了後、レポート画面から評価を続けることができます。")}</Typography>
      </BaseDialog>
    </LiveCodingLayout>
  );
};

RemoteEvaluation.displayName = "RemoteEvaluation";

export default RemoteEvaluation;
