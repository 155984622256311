import { type SupportLanguageValue } from "@hireroo/i18n";
import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import * as React from "react";

import InputControlTextField, { InputControlTextFieldProps } from "../../../primitive/InputControl/InputControlTextField";
import MarkdownPreviewEditor, { MarkdownPreviewEditorProps } from "../../../usecase/MarkdownPreviewEditor/MarkdownPreviewEditor";

export type QuestionDetailFieldProps = {
  language: SupportLanguageValue;
};

type TextMap = Record<SupportLanguageValue, string>;

const QuestionDetailField: React.FC<QuestionDetailFieldProps> = props => {
  const { t } = useTranslation();
  const titleFieldProps: InputControlTextFieldProps = {
    label: (
      {
        ja: "タイトル",
        en: "Title",
      } as TextMap
    )[props.language],
    placeholder: t("SQL入門"),
    required: true,
    fullWidth: true,
    variant: "outlined",
    InputLabelProps: {
      shrink: true,
    },
  };
  const markdownPreviewEditor: MarkdownPreviewEditorProps = {
    label: (
      {
        ja: "詳細",
        en: "Description",
      } as TextMap
    )[props.language],
    required: true,
    placeholder: `ex) ${t("以下のSQL文の内、特定のテーブルからレコードを取得するときに利用されるものはどれ？")}`,
    minRows: 4,
    helperText: t("設問の本文を入力します。本文はMarkdown形式で入力できます。"),
  };
  return (
    <Box>
      <Box display="flex" mb={3}>
        <InputControlTextField {...titleFieldProps} name={`questionDetails.${props.language}.title`} />
      </Box>
      <MarkdownPreviewEditor {...markdownPreviewEditor} name={`questionDetails.${props.language}.detail`} />
    </Box>
  );
};

QuestionDetailField.displayName = "QuestionDetailField";

export default QuestionDetailField;
