import { useTranslation } from "@hireroo/i18n";
import { PaymentForm } from "@hireroo/validator";
import Box, { BoxProps } from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import RadioControlField, { RadioControlFieldProps } from "../../../primitive/InputControl/RadioControlField/RadioControlField";

const StyledRadioControlField = styled(RadioControlField)(() => ({
  display: "flex",
  flexDirection: "row",
  ".MuiTypography-root": {
    fontWeight: "bold",
  },
}));

type Option = {
  id: PaymentForm.PaymentPeriod;
  label: string;
  value: PaymentForm.PaymentPeriod;
};

export type PaymentPeriodFieldProps = {
  name: string;
} & Pick<BoxProps, "mb">;

const PaymentPeriodField: React.FC<PaymentPeriodFieldProps> = props => {
  const { t } = useTranslation();
  const methods = useFormContext<Record<string, PaymentForm.PaymentPeriod>>();

  const options: Option[] = [
    {
      id: "YEARLY",
      label: `${t("一括払い")}(${t("年払い")})`,
      value: "YEARLY",
    },
    {
      id: "ONE_MONTHLY",
      label: `${t("分割払い")}(${t("月払い")})`,
      value: "ONE_MONTHLY",
    },
  ];
  const radioControlFieldProps: RadioControlFieldProps = {
    name: props.name,
    options: options,
    color: "secondary",
    onChange: value => {
      methods.setValue(props.name, value as PaymentForm.PaymentPeriod);
    },
  };
  return (
    <Box mb={props.mb}>
      <StyledRadioControlField {...radioControlFieldProps} />
    </Box>
  );
};

PaymentPeriodField.displayName = "PaymentPeriodField";

export default PaymentPeriodField;
