/* eslint-disable react/display-name */
import { useTheme } from "@mui/material/styles";
import { Label, ReferenceLine } from "recharts";

import { CustomLabelArgs, useCustomLabel } from "./CustomLabel";
import { CustomLabelWithRankArgs, useCustomLabelWithRank } from "./CustomLabelWithRank";

const strokeType = {
  solid: "0 0",
  dash: "3 3",
} as const;

const useCreateLabel = () => {
  const theme = useTheme();
  const customLabelWithRank = useCustomLabelWithRank();
  const customLabel = useCustomLabel();
  return (props: string | CustomLabelArgs | CustomLabelWithRankArgs) => {
    const labelFontSize = theme.typography.body2.fontSize;
    if (typeof props === "string") {
      return (
        <Label style={{ fontSize: labelFontSize, fill: theme.palette.text.primary }} offset={10} position={"bottom"}>
          {props}
        </Label>
      );
    } else if (props.kind === "COMMON") {
      return <Label style={{ fontSize: labelFontSize, fill: theme.palette.text.primary }} offset={10} content={customLabel(props)} />;
    }
    return <Label style={{ fontSize: labelFontSize, fill: theme.palette.text.primary }} offset={10} content={customLabelWithRank(props)} />;
  };
};

export type CustomReferenceLineProps = {
  x: number | string;
  label: string | CustomLabelArgs | CustomLabelWithRankArgs;
  strokeType: keyof typeof strokeType;
  strokeColor?: string;
};

export const useCreateCustomReferenceLine = () => {
  const createLabel = useCreateLabel();
  return (props: CustomReferenceLineProps) => {
    return (
      <ReferenceLine key={`line-${props.x}-${Math.random()}`} x={props.x} strokeDasharray={strokeType[props.strokeType]}>
        {createLabel(props.label)}
      </ReferenceLine>
    );
  };
};
