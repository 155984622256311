import Stack from "@mui/material/Stack";
import * as React from "react";

import Contract, { ContractProps } from "./parts/Contract/Contract";
import Pack, { PackProps } from "./parts/Pack/Pack";
import PaymentInformation, { PaymentInformationProps } from "./parts/PaymentInformation/PaymentInformation";
import Plan, { PlanProps } from "./parts/Plan/Plan";
import UsageBarGraphSection, { UsageBarGraphSectionProps } from "./parts/UsageBarGraphSection/UsageBarGraphSection";

export type BillingOverviewProps = {
  contract: ContractProps;
  pack: PackProps;
  plan: PlanProps;
  paymentInformation: PaymentInformationProps;
  usageBarGraphSection: UsageBarGraphSectionProps;
};

const BillingOverview: React.FC<BillingOverviewProps> = props => {
  return (
    <Stack spacing={2}>
      <Plan {...props.plan} />
      <Stack direction="row" spacing={2}>
        <Pack {...props.pack} />
        <Contract {...props.contract} />
      </Stack>
      <PaymentInformation {...props.paymentInformation} />
      <UsageBarGraphSection {...props.usageBarGraphSection} />
    </Stack>
  );
};

BillingOverview.displayName = "BillingOverview";

export default BillingOverview;
