import { proxyMap } from "valtio/utils";

import { state } from "./State";
import type * as Types from "./types";

export const clear = () => {
  state.formValues = {
    step: 0,
    metricIds: [],
    comment: "",
    isRecommended: false,
    evaluations: [],
  };
  state.metrics = proxyMap();
  state.metricPagination = {
    hasNext: false,
    cursor: null,
    nextCursor: null,
  };
  state.reviewedRemote = null;
};
export const clearFormValues = () => {
  state.formValues = {
    step: 0,
    metricIds: [],
    comment: "",
    isRecommended: false,
    evaluations: [],
  };
};

export const updatePartialFormValues = (newValue: Partial<Types.FormValues>) => {
  state.formValues = {
    ...state.formValues,
    ...newValue,
  };
};

export const initFormValuesMetric = (metricId: number): void => {
  if (state.formValues.metricIds.includes(metricId)) {
    state.formValues.metricIds = state.formValues.metricIds.filter(id => id !== metricId);
    state.formValues.evaluations = state.formValues.evaluations.filter(e => e.metricId !== metricId);
  } else {
    state.formValues.metricIds = [...state.formValues.metricIds, metricId];
    state.formValues.evaluations = state.formValues.evaluations.concat({
      metricId: metricId,
      comment: "",
      numStars: 0,
    });
  }
};

export const updateFormValuesEvaluation = (newValue: Types.EvaluationInput) => {
  state.formValues.evaluations = state.formValues.evaluations.map(e => {
    if (newValue.metricId === e.metricId) {
      return newValue;
    }
    return e;
  });
};

export const incrementStep = () => {
  state.formValues.step += 1;
};

export const decrementStep = () => {
  if (state.formValues.step <= 1) {
    state.formValues.step = 0;
    return;
  }
  state.formValues.step -= 1;
};

export const addMetrics = (newMetrics: Types.Metric[]) => {
  newMetrics.forEach(metric => {
    state.metrics.set(metric.metricId, metric);
  });
};

export const updateMetricPagination = (newValue: Partial<Types.MetricPagination>) => {
  state.metricPagination = {
    ...state.metricPagination,
    ...newValue,
  };
};

export const updateReviewedRemote = (newValue: Types.ReviewedRemote) => {
  state.reviewedRemote = newValue;
};
