import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { FeedbackSuspiciousDegreeForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useForm } from "react-hook-form";

import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";
import InputControlTextField, { InputControlTextFieldProps } from "../../../primitive/InputControl/InputControlTextField/InputControlTextField";
import RadioControlField, { RadioControlFieldProps } from "../../../primitive/InputControl/RadioControlField/RadioControlField";

type Option = {
  id: FeedbackSuspiciousDegreeForm.Correctness;
  label: string;
  value: FeedbackSuspiciousDegreeForm.Correctness;
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontWeight: 700,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

const NoticeTextTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
  fontSize: "12px",
  marginBottom: theme.spacing(3),
}));

const StyledRadioControlField = styled(RadioControlField)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  border: "1px solid",
  borderColor: theme.palette.divider,
  borderRadius: "4px",
  padding: theme.spacing(1.5),
  marginBottom: theme.spacing(1.5),
  ".MuiFormControlLabel-root": {
    width: "50%",
    marginRight: 0,
    ":first-of-type": {
      paddingLeft: theme.spacing(1.5),
      borderRight: "1px solid",
      borderColor: theme.palette.divider,
    },
    ":nth-of-type(2)": {
      paddingLeft: theme.spacing(2),
    },
  },
  ".MuiTypography-root": {
    paddingTop: "9px",
    paddingBottom: "9px",
  },
}));

type DialogController = {
  setLoading: (loading: boolean) => void;
  close: () => void;
};

export type FeedbackSuspiciousDegreeDialogProps = {
  open: boolean;
  questionTitle: string;
  onFeedback: (fields: FeedbackSuspiciousDegreeForm.FeedbackSuspiciousDegreeFormSchema, controller: DialogController) => void;
  onClose?: () => void;
};

const FeedbackSuspiciousDegreeDialog: React.FC<FeedbackSuspiciousDegreeDialogProps> = props => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();

  const correctnessValidateSchema = FeedbackSuspiciousDegreeForm.useCorrectness();
  const validateSchema = FeedbackSuspiciousDegreeForm.useFeedbackSuspiciousDegreeFormSchema();
  const methods = useForm<FeedbackSuspiciousDegreeForm.FeedbackSuspiciousDegreeFormSchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: {
      correctness: "CORRECT",
    },
  });

  const textFieldProps: InputControlTextFieldProps = {
    fullWidth: true,
    control: methods.control,
    multiline: true,
    rows: 7,
    type: "text",
    placeholder: t("理由を具体的に記述してください。"),
  };

  const [loading, setLoading] = React.useState(false);

  const handleClose = () => {
    props.onClose?.();
    setLoading(false);
  };

  const dialogController: DialogController = {
    setLoading: newLoading => {
      setLoading(newLoading);
    },
    close: () => {
      handleClose();
    },
  };

  const dialog: DialogWithCloseIconProps = {
    open: props.open,
    title: t("フィードバック"),
    yesButton: {
      children: t("送信する"),
      onClick: () => {
        methods.handleSubmit(fields => {
          props.onFeedback(fields, dialogController);
        })();
      },
      disabled: loading,
      color: "secondary",
      autoFocus: false,
    },
    noButton: {
      children: t("キャンセル"),
      variant: "outlined",
      color: "outline-only",
      disabled: loading,
      onClick: () => {
        handleClose();
      },
    },
    disableEnter: true,
    optionalDialog: {
      disableAutoFocus: true,
    },
    onClose: handleClose,
  };

  const options: Option[] = [
    {
      id: "CORRECT",
      label: t("正しかった"),
      value: "CORRECT",
    },
    {
      id: "NOT_CORRECT",
      label: t("正しくなかった"),
      value: "NOT_CORRECT",
    },
  ];

  const radioControlFieldProps: RadioControlFieldProps = {
    name: "correctness",
    fullWidth: true,
    color: "secondary",
    size: "medium",
    options: options,
    control: methods.control,
    onChange: value => {
      const correctness = correctnessValidateSchema.safeParse(value);
      if (correctness.success) {
        methods.setValue("correctness", correctness.data);
      }
    },
  };

  return (
    <DialogWithCloseIcon {...dialog}>
      <StyledTypography>{t2("FeedbackConfirmMessage", { questionTitle: props.questionTitle })}</StyledTypography>
      <StyledRadioControlField {...radioControlFieldProps} sx={{ width: "100%" }} />
      <NoticeTextTypography>
        {t("※ このフィードバックは不審度の精度向上に役立てるもので、候補者の評価とは無関係となります。")}
      </NoticeTextTypography>
      <InputControlTextField name="comment" {...textFieldProps} />
    </DialogWithCloseIcon>
  );
};

FeedbackSuspiciousDegreeDialog.displayName = "FeedbackSuspiciousDegreeDialog";

export default FeedbackSuspiciousDegreeDialog;
