import { App } from "@hireroo/app-store/essential/employee";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import FilledVariablesFieldContainer, { FilledVariablesFieldContainerProps } from "./Container";

export type FilledVariablesFieldFetchContainerProps = {
  screeningId: string;
};

const FilledVariablesFieldFetchContainer: React.FC<FilledVariablesFieldFetchContainerProps> = props => {
  const appStatus = App.useStatus();
  const [result, refresh] = Graphql.useListSpotFilterOptionsByScreeningIdForScreeningDetailQuery({
    variables: {
      input: {
        screeningId: props.screeningId,
        withFilledVariables: true,
      },
    },
    pause: appStatus !== "INITIALIZED",
  });

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !result.data) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: FilledVariablesFieldContainerProps = {
    data: result.data,
  };

  return <FilledVariablesFieldContainer {...containerProps} />;
};

FilledVariablesFieldFetchContainer.displayName = "FilledVariablesFieldFetchContainer";

export default withErrorBoundary(FilledVariablesFieldFetchContainer, {});
