import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type FreepadQuestionDetailContainerProps = {};

const FreepadQuestionDetailContainer: React.FC<FreepadQuestionDetailContainerProps> = props => {
  const freepadQuestionDetailProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Pages.QuestionDetail {...freepadQuestionDetailProps} />
    </ErrorBoundary>
  );
};

FreepadQuestionDetailContainer.displayName = "FreepadQuestionDetailContainer";

export default withErrorBoundary(FreepadQuestionDetailContainer, {});
