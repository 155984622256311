import { useTitle } from "@hireroo/app-helper/react-use";
import { App } from "@hireroo/app-store/essential/talent";
import { useTranslation } from "@hireroo/i18n";
import { Pages } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as React from "react";

import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import FooterContainer from "../../../../widget/v2/t/Footer/Container";
import TalentHeaderRightContentContainer from "../../../../widget/v2/t/TalentHeaderRightContent/Container";
import TalentSideBarContentContainer from "../../../../widget/v2/t/TalentSideBarContent/Container";
import UserSettingsNavigatorContainer from "../../../../widget/v2/t/UserSettingsNavigator/Container";
import UserProfileContainer from "./widgets/UserProfile/Container";

export const useGenerateProps = (): Pages.UserSettingsForTalentProps => {
  const { t } = useTranslation();
  const loading = App.useStatus() === "INITIALIZING";
  const navigate = useTransitionNavigate();

  useTitle(t("個人設定"));

  return {
    layout: {
      loading: loading,
      NotificationBanner: null,
      HeaderRightContent: <TalentHeaderRightContentContainer />,
      SideBarContent: <TalentSideBarContentContainer />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      logo: {
        href: generatePath("/t/assessments"),
        onClick: () => {
          navigate("/t/assessments");
        },
      },
    },
    settingsLayout: {
      HeaderMenu: <UserSettingsNavigatorContainer active="ACCOUNT" />,
      sideBar: {
        active: "user-profile",
        items: [{ id: "user-profile", label: t("基本情報") }],
      },
    },
    children: <UserProfileContainer />,
  };
};
