import "firebase/compat/database";

import { SKIP_OPTION_ID } from "@hireroo/app-definition/quiz";
import { getTimestamp } from "@hireroo/firebase";
import { getRef } from "@hireroo/firebase";
import firebase from "firebase/compat/app";
import * as React from "react";

import { AliasOptionActionType, QuizSyncState, revisionFromId, revisionToId, SyncState } from "../firepad";
import { tuple } from "../tuple";

// p: packageId, q: last selected questionId, o: last selected optionId, k: latest index, a: action of quiz, i: last state is "inq" or not

export type State = {
  selectedPackageId: number;
  selectedQuestionId: number;
  selectedOptions: Set<number>;
  ready: boolean;
};

export type Action = {
  selectPackage: (packageId: number) => void;
  submitQuestion: (questionId: number) => void;
  selectQuestion: (questionId: number) => void;
  selectSingleOption: (optionId: number) => void;
  selectMultiOption: (optionId: number) => void;
  unselectMultiOption: (optionId: number) => void;
  clearOption: () => void;
};

export type QuestionCompositeMapType = {
  id: number;
  version: string;
};

type StateType = { p?: number; q?: number; o?: number; k?: number; a?: AliasOptionActionType; i?: boolean };

export type QuizRealtimeDatabaseArgs = {
  quizId: number;
  packageId: number;
  firstQuestionId: number;
  isCandidate: boolean;
  isInterviewing?: boolean;
};

export const useQuizRealtimeDatabase = (args: QuizRealtimeDatabaseArgs) => {
  const { quizId, packageId, firstQuestionId, isInterviewing, isCandidate } = args;

  // To write the realtime log at quizzes/${quizId}
  const quizIndexRef = React.useRef<number>(0);
  const quizRef = React.useRef<firebase.database.Reference | undefined>();
  const [quizReady, setQuizReady] = React.useState<boolean>(false);

  // To write the realtime log at quizzes/${quizId}/packages/${packageId}
  const packageIndexRef = React.useRef<number>(0);
  const packageRef = React.useRef<firebase.database.Reference | undefined>();
  const packageLastStateRef = React.useRef<SyncState>();
  const [packageReady, setPackageReady] = React.useState<boolean>(false);

  // To write the realtime log at quizzes/${quizId}/packages/${packageId}/questions/${questionId}
  const questionIndexRef = React.useRef<number>(0);
  const questionRef = React.useRef<firebase.database.Reference | undefined>();

  // This data to update the value of selectedOptions.
  // This is used as a reference for firebase's on('child_added'),
  // which captures the old values and does not allow comparison of values.
  const selectedOptionsRef = React.useRef<Set<number>>(new Set());
  const [selectedOptions, setSelectedOptions] = React.useState<Set<number>>(new Set());
  const [questionReady, setQuestionReady] = React.useState<boolean>(false);

  const [selectedPackageId, setSelectedPackageId] = React.useState<number>(packageId);
  React.useEffect(() => {
    setSelectedPackageId(packageId);
  }, [packageId]);

  const [selectedQuestionId, setSelectedQuestionId] = React.useState<number>(firstQuestionId);
  React.useEffect(() => {
    setSelectedQuestionId(firstQuestionId);
  }, [firstQuestionId]);

  const clearOption = (): void => {
    selectedOptionsRef.current.clear();
    setSelectedOptions(selectedOptionsRef.current);
  };

  const ready = React.useMemo<boolean>(() => {
    return quizReady && packageReady && questionReady;
  }, [quizReady, packageReady, questionReady]);

  const selectPackage = React.useCallback(
    (packageId: number) => {
      if (!quizReady) return;
      quizRef.current?.child(revisionToId(quizIndexRef.current)).set({
        s: "selp",
        v: packageId,
        t: getTimestamp(),
      });
    },
    [quizReady],
  );

  const selectQuestion = React.useCallback(
    (questionId: number) => {
      if (!packageReady) return;
      packageRef.current?.child(revisionToId(packageIndexRef.current)).set({
        s: "selq",
        v: questionId,
        t: getTimestamp(),
      });
    },
    [packageReady],
  );

  const setInQuestionWrapper = React.useCallback(
    (questionId: number) => {
      if (!packageReady) return;
      packageRef.current?.child(revisionToId(packageIndexRef.current)).set({
        s: "inq",
        v: questionId,
        t: getTimestamp(),
      });
    },
    [packageReady],
  );

  const setOutQuestionWrapper = React.useCallback(
    (questionId: number) => {
      if (!packageReady) return;
      packageRef.current?.child(revisionToId(packageIndexRef.current)).set({
        s: "outq",
        v: questionId,
        t: getTimestamp(),
      });
    },
    [packageReady],
  );

  const selectSingleOption = React.useCallback(
    (optionId: number) => {
      if (!questionReady) return;
      questionRef.current?.child(revisionToId(questionIndexRef.current)).set({
        s: "selo",
        a: "rep",
        v: optionId,
        t: getTimestamp(),
      });
    },
    [questionReady],
  );

  const selectMultiOption = React.useCallback(
    (optionId: number) => {
      if (!questionReady) return;
      questionRef.current?.child(revisionToId(questionIndexRef.current)).set({
        s: "selo",
        a: "set",
        v: optionId,
        t: getTimestamp(),
      });
    },
    [questionReady],
  );

  const unselectMultiOption = React.useCallback(
    (optionId: number) => {
      if (!questionReady) return;
      questionRef.current?.child(revisionToId(questionIndexRef.current)).set({
        s: "selo",
        a: "uset",
        v: optionId,
        t: getTimestamp(),
      });
    },
    [questionReady],
  );

  const submitQuestion = React.useCallback(
    (questionId: number) => {
      if (!questionReady) return;
      questionRef.current?.child(revisionToId(questionIndexRef.current)).set({
        s: "subq",
        v: questionId,
        t: getTimestamp(),
      });
    },

    [questionReady],
  );

  const handleSelectOptionMap = React.useCallback((optionId: number, action?: AliasOptionActionType) => {
    switch (action) {
      case "rep":
        // Clear set.
        selectedOptionsRef.current.clear();
        selectedOptionsRef.current.add(optionId);
        break;
      case "set":
        // When the selected OptionId is 0 (skip), all other options are cleared.
        // and, when OptionId 0 is selected, selecting another option removes the OptionId 0.
        if (optionId === SKIP_OPTION_ID) {
          selectedOptionsRef.current.clear();
        } else {
          selectedOptionsRef.current.delete(SKIP_OPTION_ID);
        }

        selectedOptionsRef.current.add(optionId);
        break;
      case "uset":
        selectedOptionsRef.current.delete(optionId);
        break;
      default:
        // Since the historical data is always singular,
        // the behavior will then be that of a singular selection.
        selectedOptionsRef.current.clear();
        selectedOptionsRef.current.add(optionId);
        break;
    }

    setSelectedOptions(new Set([...selectedOptionsRef.current]));
  }, []);

  const setStateFromEvent = React.useCallback(
    (state: QuizSyncState) => {
      switch (state.s) {
        case "selp":
          setSelectedPackageId(state.v);
          break;
        case "selq":
          setSelectedQuestionId(state.v);
          break;
        case "selo":
          handleSelectOptionMap(state.v, state.a);
          break;
      }
    },
    [handleSelectOptionMap],
  );

  const getLatestState = React.useCallback((data: { [key: string]: SyncState }): StateType => {
    const v: StateType = {};
    const inqOutq = new Set<string>();

    Object.keys(data)
      .sort()
      .forEach((key: string) => {
        if (data[key].s === "selp") v.p = data[key].v as number;
        if (data[key].s === "selq") v.q = data[key].v as number;
        if (data[key].s === "selo") v.o = data[key].v as number;
        if (data[key].s === "inq") inqOutq.add("inq");
        if (data[key].s === "outq") inqOutq.clear();
        v.k = revisionFromId(key);
      });

    v.i = inqOutq.size > 0;
    return v;
  }, []);

  const getLatestQuestionStates = React.useCallback((data: { [key: string]: QuizSyncState }): StateType[] => {
    const states: StateType[] = [];
    Object.keys(data)
      .sort()
      .forEach((key: string) => {
        const v: StateType = {};
        switch (data[key].s) {
          case "selo":
            v.o = data[key].v as number;
            v.a = data[key].a as AliasOptionActionType;
            break;
          case "selp" || "selq":
            break;
        }

        v.k = revisionFromId(key);
        states.push(v);
      });

    return states;
  }, []);

  React.useEffect(() => {
    // Connect to firebase for realtime sync
    quizRef.current = getRef("quiz", `quizzes/${quizId}/state`);
    quizIndexRef.current = 0;
    let initialLoaded = false;

    quizRef.current?.on("child_added", snapshot => {
      if (!initialLoaded) return;
      quizIndexRef.current = revisionFromId(snapshot.key as string) + 1;
      setStateFromEvent(snapshot.val());
    });

    quizRef.current?.once("value", snapshot => {
      const data: Record<string, QuizSyncState> | null = snapshot.val();
      if (data) {
        // state under quizzes/${quizId}/state holds `selp` only.
        // only latest.q and latest.k will be considered, and others will be ignored.
        const latest = getLatestState(data);
        if (latest.p !== undefined) setSelectedPackageId(latest.p);
        if (latest.k !== undefined) quizIndexRef.current = latest.k + 1;
      }

      // Initial sync is done.
      initialLoaded = true;
      setQuizReady(true);
    });
  }, [getLatestState, quizId, setStateFromEvent]);

  React.useEffect(() => {
    if (!quizReady) return;
    // Connect to firebase for realtime sync
    packageRef.current = getRef("quiz", `quizzes/${quizId}/packages/${selectedPackageId}/state`);
    packageIndexRef.current = 0;
    let initialLoaded = false;

    packageRef.current?.on("child_added", snapshot => {
      if (!initialLoaded) return;

      const latest = snapshot.val() as QuizSyncState;
      packageLastStateRef.current = latest;
      packageIndexRef.current = revisionFromId(snapshot.key as string) + 1;
      setStateFromEvent(latest);
    });

    packageRef.current?.once("value", snapshot => {
      const data: Record<string, QuizSyncState> | null = snapshot.val();
      if (data) {
        // state under quizzes/${quizId}/packages/${packageId}/state holds `selq` only.
        // only latest.q and latest.k will be considered, and others will be ignored.
        const latest = getLatestState(data);
        const q = latest.q;

        if (q !== undefined) {
          setSelectedQuestionId(q);
        }

        if (latest.k !== undefined) packageIndexRef.current = latest.k + 1;

        // The interviewer's log is unnecessary and will not go through.
        // And, No values are recorded, even for tests that have already been completed.
        if (isCandidate && isInterviewing) {
          // If 'inq' already exists as latest, do not add 'inq'
          if (latest.i === undefined || latest.i === false) {
            setInQuestionWrapper(selectedQuestionId);
          }
        }
      } else {
        if (isCandidate && isInterviewing) {
          // If this is the first landing where data does not exist, enter an initial value.
          setInQuestionWrapper(selectedQuestionId);
        }
      }

      // Initial sync is done.
      initialLoaded = true;
      setPackageReady(true);
    });
  }, [
    quizId,
    quizReady,
    setStateFromEvent,
    getLatestState,
    setSelectedQuestionId,
    isCandidate,
    isInterviewing,
    setInQuestionWrapper,
    selectedPackageId,
    selectedQuestionId,
  ]);

  React.useEffect(() => {
    if (!packageReady) return;
    // Connect to firebase for realtime sync
    questionRef.current = getRef("quiz", `quizzes/${quizId}/packages/${selectedPackageId}/questions/${selectedQuestionId}/state`);
    questionIndexRef.current = 0;
    let initialLoaded = false;

    questionRef.current?.on("child_added", snapshot => {
      if (!initialLoaded) return;
      questionIndexRef.current = revisionFromId(snapshot.key as string) + 1;
      setStateFromEvent(snapshot.val());
    });

    questionRef.current?.once("value", snapshot => {
      const data: Record<string, QuizSyncState> | null = snapshot.val();
      if (data) {
        // state under quizzes/${quizId}/packages/${packageId}/questions/${questionId}/state holds `selo` only.
        // only latest.q and latest.k will be considered, and others will be ignored.
        const latests = getLatestQuestionStates(data);

        latests.forEach(latest => {
          if (latest.k !== undefined) questionIndexRef.current = latest.k + 1;
          if (latest.o !== undefined && latest.a !== undefined) handleSelectOptionMap(latest.o, latest.a);
        });
      }

      // Initial sync is done.
      initialLoaded = true;
      setQuestionReady(true);
    });
  }, [quizId, packageReady, selectedPackageId, setStateFromEvent, handleSelectOptionMap, getLatestQuestionStates, selectedQuestionId]);

  const isOutState = React.useCallback((state?: SyncState) => {
    return state?.s === "outq";
  }, []);

  // If the cleanup function that throws 'outq' is called after IndexRef.current,
  // IT MUST BE CALLED FIRST because the value will be after initialization.
  React.useEffect(() => {
    if (!isInterviewing) return;
    // Set quiz out event to firebase.
    // This works only when the question switched.
    return () => {
      if (quizReady && packageReady && !isOutState(packageLastStateRef.current)) setOutQuestionWrapper(selectedQuestionId);
    };
  }, [isInterviewing, isOutState, packageReady, quizReady, selectedQuestionId, setOutQuestionWrapper]);

  React.useEffect(() => {
    return () => {
      quizRef.current = undefined;
      quizIndexRef.current = 0;
      setQuizReady(false);

      packageRef.current = undefined;
      packageIndexRef.current = 0;
      setPackageReady(false);

      questionRef.current = undefined;
      questionIndexRef.current = 0;
      setQuestionReady(false);
    };
  }, []);

  const dispatcher = {
    selectPackage,
    submitQuestion,
    selectQuestion,
    selectSingleOption,
    selectMultiOption,
    unselectMultiOption,
    clearOption,
  };

  const state = {
    selectedPackageId,
    selectedQuestionId,
    selectedOptions,
    ready,
  };

  return tuple(state, dispatcher);
};
