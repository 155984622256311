import { useSnapshot } from "valtio";

import { state } from "./State";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useUnreadEmployeeNotifications = () => {
  const snapshot = useSnapshotState();
  return Array.from(snapshot.notification.values()).filter(item => {
    return !snapshot.readNotificationIdSet.has(item.notificationId);
  });
};

export const useUnreadNotificationCount = () => {
  const unreadEmployeeNotifications = useUnreadEmployeeNotifications();
  return unreadEmployeeNotifications.length;
};

export const useHasNext = () => {
  const snapshot = useSnapshotState();
  return !!snapshot.pager?.hasNext;
};

export const useFetchStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.fetchStatus;
};

export const useNextCursor = () => {
  const snapshot = useSnapshotState();
  return snapshot.pager?.cursor;
};
