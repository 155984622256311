import { ScreeningsId } from "@hireroo/app-store/page/c/screenings_id";
import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Pages } from "@hireroo/presentation";
import { EntryScreeningForm } from "@hireroo/validator";
import * as React from "react";

type CustomField = Pages.EntryScreeningProps["customFieldSection"]["items"][0];

const isValidNumber = (value: number | null): value is number => {
  return typeof value === "number" && value > 0;
};

export type GenerateEntryScreeningPropsArgs = {};

export const useGenerateCustomFieldSectionProps = (): Pages.EntryScreeningProps["customFieldSection"] => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const screening = ScreeningsId.useScreening();
  const customFieldsItems = React.useMemo((): CustomField[] => {
    return screening.variables.map((variable): CustomField => {
      const fieldName = EntryScreeningForm.generateReservedVariableFieldValue(variable.label);
      const type = variable.variableField.__typename;
      switch (type) {
        case "ScreeningStringFieldVariable":
          return {
            type: "TEXT",
            field: {
              isRequired: variable.variableField.validationRule?.required,
              label: variable.label,
              name: fieldName,
              description: variable.description,
              formType: variable.variableField.formType === "TEXTAREA" ? "TEXTAREA" : "INPUT",
              helperText: ((): string | undefined => {
                const { min, max } = variable.variableField.validationRule;
                if (isValidNumber(min) && isValidNumber(max)) {
                  return t2("MinAndMaxStringInput", {
                    min: min,
                    max: max,
                  });
                }
                if (isValidNumber(max)) {
                  return t2("MaxStringInput", {
                    max: max,
                  });
                }
                if (isValidNumber(min)) {
                  return t2("MinStringInput", {
                    min: min,
                  });
                }
              })(),
            },
          };
        case "ScreeningIntegerFieldVariable": {
          const { validationRule } = variable.variableField;
          return {
            type: "INTEGER",
            field: {
              isRequired: variable.variableField.validationRule?.required,
              label: variable.label,
              name: fieldName,
              description: variable.description,
              helperText: ((): string | undefined => {
                if (typeof validationRule.min === "number" && typeof validationRule.max === "number") {
                  return t2("MinAndMaxNumberInput", {
                    min: validationRule.min,
                    max: validationRule.max,
                  });
                }
                if (typeof validationRule.min === "number" && validationRule.max === null) {
                  return t2("MinNumberInput", {
                    min: validationRule.min,
                  });
                }
                if (validationRule.min === null && typeof validationRule.max === "number") {
                  return t2("MaxNumberInput", {
                    max: validationRule.max,
                  });
                }
              })(),
            },
          };
        }
        case "ScreeningBooleanFieldVariable":
          throw new Error("unimplemented field");
        default:
          throw new Error(`Unknown type ${type satisfies never}`);
      }
    });
  }, [screening.variables, t2]);

  const companyName: string = screening.company?.name || t("会社名不明");

  return {
    title: t2("questionFromCompany", { companyName }),
    items: customFieldsItems,
  };
};
