import { useTranslation } from "@hireroo/i18n";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";

import CustomScoreAllocation, { CustomScoreAllocationProps } from "../../../../modules/CustomScoreAllocation/CustomScoreAllocation";
import ReadonlySelectedQuestionField, {
  ReadonlySelectedQuestionFieldProps,
} from "../../../../modules/ReadonlySelectedQuestionField/ReadonlySelectedQuestionField";
import ReadonlyCustomFormVariable, {
  ReadonlyCustomFormVariableProps,
} from "../../../../ms-components/Spot/ReadonlyCustomFormVariable/ReadonlyCustomFormVariable";
import TextLinkify from "../../../../primitive/TextLinkify/TextLinkify";
import { useScreeningResourceEditorContext } from "../../Context";
import ConfirmField from "./parts/ConfirmField/ConfirmField";
import Subsection, { SubsectionProps } from "./parts/Subsection/Subsection";

const OutlinedChip = styled(Chip)(({ theme }) => ({
  height: 30,
  ".MuiChip-deleteIcon": {
    color: theme.palette.action.disabled,
  },
  color: theme.palette.text.primary,
  borderColor: theme.palette.action.disabled,
}));

export type ConfirmSectionProps = {
  // Test Question Setup
  title: string;
  timelimit: string;
  assistFeature?: string;
  hintFeature: string;
  selectedQuestions: ReadonlySelectedQuestionFieldProps[];

  // Test Setup
  acceptableLimit?: string;
  deadline?: string;
  customFields: ReadonlyCustomFormVariableProps[];

  // Report Setup
  memo?: string;
  tags: string[];
  viewers: { iconSrc?: string; name: string; kind: "GROUP" | "USER" }[];

  // Invitation Setup
  messageForCandidate?: string;
  accessPolicy: string;
  invitationEmailLanguage: string;
  ownerEmailAddress?: string;
  customScoreAllocation: CustomScoreAllocationProps;
};

const ConfirmSection: React.FC<ConfirmSectionProps> = props => {
  const { t } = useTranslation();
  const { updateStep, stepName } = useScreeningResourceEditorContext();

  const testSetupSubsection: SubsectionProps = {
    title: t("テスト設定"),
    changeButton: {
      onClick: () => {
        updateStep("TEST_QUESTION_SETUP");
      },
    },
    children: (
      <Stack spacing={2}>
        <ConfirmField label={t("タイトル")}>{props.title}</ConfirmField>
        <ConfirmField label={t("出題する問題とカスタムスコア")}>
          <Stack spacing={1}>
            {props.selectedQuestions.map((selectedQuestion, index) => {
              return <ReadonlySelectedQuestionField key={`selected-${index}`} {...selectedQuestion} />;
            })}
            <CustomScoreAllocation {...props.customScoreAllocation} />
          </Stack>
        </ConfirmField>
        <ConfirmField label={t("制限時間")}>{props.timelimit}</ConfirmField>
        <ConfirmField label={t("アシスト機能")}>{props.assistFeature}</ConfirmField>
        <ConfirmField label={t("ヒント機能")}>{props.hintFeature}</ConfirmField>
      </Stack>
    ),
  };

  const testTakingSetupSubsection: SubsectionProps = {
    title: t("受験設定"),
    changeButton: {
      onClick: () => {
        updateStep("TEST_SETUP");
      },
    },
    children: (
      <Stack spacing={2}>
        <ConfirmField label={t("カスタムフォーム")}>
          {props.customFields.length === 0 ? undefined : (
            <Stack spacing={1}>
              {props.customFields.map((customField, index) => (
                <ReadonlyCustomFormVariable key={`custom-field-${index}`} {...customField} />
              ))}
            </Stack>
          )}
        </ConfirmField>
        <ConfirmField label={t("受験可能な定員数")}>{props.acceptableLimit}</ConfirmField>
        <ConfirmField label={t("テストの提出期限")}>{props.deadline}</ConfirmField>
      </Stack>
    ),
  };

  const reportSetupSubsection: SubsectionProps = {
    title: t("レポート設定"),
    changeButton: {
      onClick: () => {
        updateStep("REPORT_SETUP");
      },
    },
    children: (
      <Stack spacing={2}>
        <ConfirmField label={t("社内用メモ")}>{props.memo}</ConfirmField>
        <ConfirmField label={t("タグ設定")}>
          {props.tags.length > 0 ? (
            <Stack direction="row" sx={{ flexWrap: "wrap", gap: "8px" }}>
              {props.tags.map((tag, index) => {
                return <OutlinedChip key={`${tag}-${index}`} variant="outlined" label={tag} />;
              })}
            </Stack>
          ) : undefined}
        </ConfirmField>
        <ConfirmField label={t("閲覧権限")}>
          {props.viewers.length > 0 ? (
            <Stack direction="row" sx={{ flexWrap: "wrap", gap: "8px" }}>
              {props.viewers.map((viewer, index) => {
                const avatar = (
                  <Avatar src={viewer.kind === "USER" ? viewer.iconSrc : undefined}>
                    {viewer.kind === "USER" && !viewer.iconSrc && <PersonIcon />}
                    {viewer.kind === "GROUP" && <GroupIcon />}
                  </Avatar>
                );
                return <OutlinedChip key={`${viewer.name}-${index}`} variant="outlined" label={viewer.name} avatar={avatar} />;
              })}
            </Stack>
          ) : (
            t("リンクを知っているメンバーが閲覧可能")
          )}
        </ConfirmField>
      </Stack>
    ),
  };

  const invitationSetupSubsection: SubsectionProps = {
    title: t("招待設定"),
    changeButton: {
      onClick: () => {
        updateStep("TEST_INVITE_SETUP");
      },
    },
    children: (
      <Stack spacing={2}>
        <ConfirmField label={t("候補者へのメッセージ")}>
          <TextLinkify>{props.messageForCandidate}</TextLinkify>
        </ConfirmField>
        <ConfirmField label={t("受験可能なユーザーの制限")}>{props.accessPolicy}</ConfirmField>
        <ConfirmField label={t("メール本文の言語")}>{props.invitationEmailLanguage}</ConfirmField>
        <ConfirmField label={t("候補者に共有する連絡先")}>{props.ownerEmailAddress}</ConfirmField>
      </Stack>
    ),
  };

  const subsections: SubsectionProps[] = [testSetupSubsection, testTakingSetupSubsection, reportSetupSubsection, invitationSetupSubsection];

  if (stepName !== "CONFIRM") {
    return null;
  }

  return (
    <Stack spacing={2} py={2} direction="column" sx={{ width: 800, margin: "0 auto" }}>
      {subsections.map((subsection, index) => {
        return <Subsection key={`subsection-${index}`} {...subsection} />;
      })}
    </Stack>
  );
};

ConfirmSection.displayName = "ConfirmSection";

export default ConfirmSection;
