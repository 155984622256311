import { App } from "@hireroo/app-store/essential/employee";
import { ScreeningInviteeList } from "@hireroo/app-store/widget/e/ScreeningInviteeList";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";
import { useParams } from "react-router-dom";

import ScreeningInviteeListContainer, { ScreeningInviteeListContainerProps } from "./Container";

export type ScreeningInviteeListFetchContainerProps = {};

const ScreeningInviteeListFetchContainer: React.FC<ScreeningInviteeListFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const initialized = ScreeningInviteeList.useInitialized();
  const { id } = useParams<{
    id: string;
  }>();
  const screeningId = `${id}`;
  const listParams = ScreeningInviteeList.useListParams();

  const [result, refresh] = Graphql.useListScreeningInviteeForScreeningInviteeListQuery({
    variables: {
      input: {
        isDescending: listParams.isDescending,
        size: listParams.size,
        offset: listParams.size * listParams.page,
        screeningId: screeningId,
        filters: {
          email: listParams.filters.email,
          statuses: listParams.filters.statuses.slice(),
        },
        withCount: true,
      },
    },
    requestPolicy: "network-only",
    pause: appStatus !== "INITIALIZED",
  });

  React.useEffect(() => {
    ScreeningInviteeList.setRefresh(refresh);
  }, [refresh]);

  React.useEffect(() => {
    if (result.data) {
      ScreeningInviteeList.initialize(result.data.screeningInvitees);
    }
  }, [result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: ScreeningInviteeListContainerProps = {};

  return <ScreeningInviteeListContainer {...containerProps} />;
};

ScreeningInviteeListFetchContainer.displayName = "ScreeningInviteeListFetchContainer";

export default withErrorBoundary(ScreeningInviteeListFetchContainer, {});
