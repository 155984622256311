export const SupportLanguage = {
  EN: "en",
  JA: "ja",
} as const;

export type SupportLanguage = typeof SupportLanguage;
export type SupportLanguageValue = SupportLanguage[keyof typeof SupportLanguage];

export const languageMapForDisplay: Record<SupportLanguageValue, string> = {
  [SupportLanguage.EN]: "English",
  [SupportLanguage.JA]: "日本語",
};

export type TranslationContext = "quiz";
