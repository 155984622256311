import { useTranslation } from "@hireroo/i18n";
import VerifiedIcon from "@mui/icons-material/Verified";
import Grid, { GridProps } from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DifficultyStars, { DifficultyStarsProps } from "../../../modules/DifficultyStars/DifficultyStars";
import DetailContentItem, { DetailContentItemProps } from "../DetailContentItem/DetailContentItem";
import LeakScoreChip, { LeakScoreChipProps } from "../LeakScoreChip/LeakScoreChip";

const StyledStack = styled(Stack)(() => ({
  borderRadius: "12px",
  padding: "12px",
}));

type CreatedBy = { kind: "OFFICIAL" } | { kind: "PRIVATE"; displayName: string };

export type QuestionDetailInfoProps = {
  difficultyStars: DifficultyStarsProps;
  numOfLanguages?: { value: number };
  timelimitMinutes?: number;
  createdBy: CreatedBy;
  numOfQuestions?: { value: number };
  leakScoreChip?: LeakScoreChipProps;
};

const QuestionDetailInfo: React.FC<QuestionDetailInfoProps> = props => {
  const theme = useTheme();
  const { t } = useTranslation();
  const items: (DetailContentItemProps | false)[] = [
    {
      id: "difficulty",
      title: t("難易度"),
      Content: <DifficultyStars {...props.difficultyStars} />,
    },
    !!props.numOfLanguages && {
      id: "language-number",
      title: t("対応言語数"),
      Content: (
        <Stack direction="row">
          <Typography fontSize={16} fontWeight={700}>
            {props.numOfLanguages.value}
          </Typography>
        </Stack>
      ),
    },
    !!props.timelimitMinutes && {
      id: "timelimit",
      title: t("想定制限時間"),
      Content: (
        <Stack direction="row">
          <Typography fontSize={16} fontWeight={700}>
            {props.timelimitMinutes}
          </Typography>
          <Typography ml={0.5} fontSize={14} color="textSecondary" display="flex" alignItems="flex-end">
            {t("分")}
          </Typography>
        </Stack>
      ),
    },
    !!props.numOfQuestions && {
      id: "number-of-questions",
      title: t("設問数"),
      Content: (
        <Stack direction="row">
          <Typography fontSize={16} fontWeight={700}>
            {props.numOfQuestions.value}
          </Typography>
        </Stack>
      ),
    },
  ];

  // TODO: similar codes がリリースされたら、leak-score, creator は push しない
  if (props.leakScoreChip) {
    items.push({
      id: "leak-score",
      title: t("リークスコア"),
      Content: (
        <Stack direction="row">
          <LeakScoreChip {...props.leakScoreChip} />
        </Stack>
      ),
    });
  }

  items.push({
    id: "creator",
    title: t("作成者"),
    Content: (
      <Stack direction="row" display="flex" alignItems="center">
        {props.createdBy.kind === "OFFICIAL" && (
          <>
            <VerifiedIcon color="primary" fontSize="small" />
            <Typography ml={0.5} whiteSpace="nowrap">
              {t("HireRoo公式")}
            </Typography>
          </>
        )}
        {props.createdBy.kind === "PRIVATE" && <Typography>{props.createdBy.displayName}</Typography>}
      </Stack>
    ),
  });

  return (
    <StyledStack direction="column" spacing={3} bgcolor={theme.palette["Secondary/Shades"].p12}>
      <Grid container mt={0}>
        {items
          .flatMap(v => (v ? [v] : []))
          .map(item => {
            const gridProps: GridProps = {
              item: true,
              xs: 6,
              mb: 3,
              pt: 0,
              pl: 2,
              borderColor: theme.palette.divider,
            };
            return (
              <Grid key={item.id} {...gridProps}>
                <DetailContentItem {...item} />
              </Grid>
            );
          })}
      </Grid>
    </StyledStack>
  );
};

QuestionDetailInfo.displayName = "QuestionDetailInfo";

export default QuestionDetailInfo;
