import { useInvitationMethodMap } from "@hireroo/app-definition/interview";
import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { hasSpotStatus } from "@hireroo/app-helper/interview";
import { Auth, Payment, Role } from "@hireroo/app-store/essential/employee";
import { InterviewOverview } from "@hireroo/app-store/widget/e/InterviewOverview";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import * as TimeFormatter from "@hireroo/formatter/time";
import { formatTimeDistance } from "@hireroo/formatter/time";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import { generateCurrentOriginUrl } from "@hireroo/router/api";
import * as Sentry from "@sentry/react";
import React from "react";

import { useGenerateAccessPermissionDialogProps } from "./useGenerateAccessPermissionDialogProps";
import { useGenerateCustomScoreAllocationProps } from "./useGenerateCustomScoreAllocationProps";
import { useGenerateQuestionOverviewProps } from "./useGenerateQuestionOverviewProps";
import { useGenerateQuestionsProps } from "./useGenerateQuestionsProps";
import { useGenerateTagDialogProps } from "./useGenerateTagDialogProps";
import { useGenerateTimelineProps } from "./useGenerateTimelineProps";

type AuthorizedMemberAndGroup = Widget.ScreeningTestOverviewProps["testSummary"]["interview"]["authorizedMemberAndGroups"];
type EvaluateStatus = Widget.ScreeningTestOverviewProps["reviewSummary"]["interview"]["status"];
type FilledVariable = Widget.ScreeningTestOverviewProps["testSummary"]["filledVariables"][0];
type StatusInfo = Widget.ScreeningTestOverviewProps["statusInfo"];

export type GenerateScreeningTestOverviewPropsArgs = {
  companyId: number;
};

export const useGenerateProps = (args: GenerateScreeningTestOverviewPropsArgs): Widget.ScreeningTestOverviewProps => {
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const invitationMethodMap = useInvitationMethodMap();
  const matchingRole = Role.useMatchingRole();
  const [resultUpdateSpot, updateSpot] = Graphql.useUpdateSpotForScreeningTestOverviewMutation();
  const tagDialog = useGenerateTagDialogProps(args);
  const timeline = useGenerateTimelineProps({});
  const isAvailableFeature = Payment.useIsAvailableFeature();
  const [openMemoEditor, setOpenMemoEditor] = React.useState(false);
  const accessPermissionDialogProps = useGenerateAccessPermissionDialogProps({});
  const currentUser = Auth.useCurrentUser();
  const questionOverview = useGenerateQuestionOverviewProps();
  const customScoreAllocation = useGenerateCustomScoreAllocationProps();
  const [now] = React.useState<Date>(new Date());

  const interview = InterviewOverview.useInterview();
  const questions = useGenerateQuestionsProps();
  const isStarted = hasSpotStatus(interview.status, [
    Graphql.SpotStatus.Started,
    Graphql.SpotStatus.Completed,
    Graphql.SpotStatus.Finalized,
    Graphql.SpotStatus.Reviewed,
    Graphql.SpotStatus.Evaluated,
  ]);
  const interviewAuthorizedMember = interview.reviewers
    .filter(reviewer => !reviewer.employee?.isSystemAdmin)
    .map((reviewer): AuthorizedMemberAndGroup[0] => {
      if (reviewer.type === "EMPLOYEE_GROUP" && reviewer.group) {
        return {
          key: reviewer.group.id,
          kind: "GROUP",
          label: reviewer.group.groupName,
        };
      } else if (reviewer.type === "EMPLOYEE" && reviewer.employee) {
        return {
          key: reviewer.employee.id,
          photoUrl: reviewer.employee.photoUrl,
          kind: "MEMBER",
          label: reviewer.employee.displayName ? reviewer.employee.displayName : reviewer.employee.email,
        };
      } else {
        throw new Error("Unknown reviewer type");
      }
    });

  const evaluateStatus = React.useMemo((): EvaluateStatus => {
    switch (interview.status) {
      case Graphql.SpotStatus.Evaluated:
        return "EVALUATED";
      case Graphql.SpotStatus.Declined:
      case Graphql.SpotStatus.Expired:
        return "DECLINED";
      default:
        return "NOT_EVALUATED";
    }
  }, [interview.status]);

  const behavioralControl = React.useMemo((): string[] | null => {
    if (!isAvailableFeature("behavioral-control.read")) {
      return null;
    }

    const allowedBehaviors: string[] = [];
    if (interview.allowWebSearch) {
      allowedBehaviors.push(t("Google検索"));
    }
    if (interview.allowChatGPT) {
      allowedBehaviors.push("ChatGPT");
    }

    return allowedBehaviors;
  }, [t, interview.allowWebSearch, interview.allowChatGPT, isAvailableFeature]);

  const statusInfoProps = React.useMemo((): Widget.ScreeningTestOverviewProps["statusInfo"] => {
    const interviewStatusMap = {
      EVALUATED: {
        severity: interview.isPassed ? "success" : "error",
        message: interview.evaluationComment,
        additionalInfo: {
          text: `${t("評価者")}: ${(interview.evaluatedBy?.displayName || interview.evaluatedBy?.email) ?? t("不明")} ${
            interview.evaluatedAtSeconds ? TimeFormatter.unixToDateFormat(interview.evaluatedAtSeconds) : ""
          }`,
        },
      },
      FINALIZED: {
        severity: "info",
        message: t("テストが終了しました。評価を進めましょう。"),
        additionalInfo: {
          text: `${t("提出日")}: ${interview.finalizedAtSeconds ? TimeFormatter.unixToDateFormat(interview.finalizedAtSeconds) : ""}`,
          passedTime: `${
            interview.finalizedAtSeconds ? `${formatTimeDistance(new Date(interview.finalizedAtSeconds * 1000), now, lang)}` : ""
          }`,
        },
      },
      STARTED: undefined,
      ACCEPTED: undefined,
      CREATED: undefined,
      COMPLETED: undefined,
      DECLINED: undefined,
      REVIEWED: undefined,
      EXPIRED: undefined,
      UNKNOWN: undefined,
    } satisfies Record<Graphql.SpotStatus, StatusInfo>;

    return interviewStatusMap[interview.status];
  }, [
    interview.evaluatedAtSeconds,
    interview.evaluatedBy?.displayName,
    interview.evaluatedBy?.email,
    interview.evaluationComment,
    interview.finalizedAtSeconds,
    interview.isPassed,
    interview.status,
    lang,
    now,
    t,
  ]);

  return {
    testSummary: {
      interview: {
        intervieweeName: interview.candidateName,
        submissionDeadline: ((): string => {
          if (interview.willExpireAtSeconds !== null) {
            return TimeFormatter.unixToDateFormat(interview.willExpireAtSeconds ?? 0);
          }
          return t("なし");
        })(),
        candidateEmail: interview.candidateEmail || undefined,
        timeLimit: interview.timeLimitSeconds ? TimeFormatter.formatMinutes(interview.timeLimitSeconds / 60, lang) : "0",
        hasStarted: isStarted,
        employeeName: interview.employee?.displayName ?? "",
        invitationLink: generateCurrentOriginUrl(`/c/interviews/:id`, {
          pathParams: {
            id: interview.id,
          },
        }),
        memo: interview.memo ?? "",
        /**
         * when spot is created via screening invitationMethod is UNKNOWN because it is deprecated
         */
        invitationMethod: interview.invitationMethod !== "UNKNOWN" ? invitationMethodMap[interview.invitationMethod] : undefined,
        isPublic: typeof interview.isPublic === "boolean" ? interview.isPublic : true,
        messageForCandidate: interview.messageForCandidate ?? undefined,
        tags: interview.tags.map(t => t.name),
        authorizedMemberAndGroups: interviewAuthorizedMember,
        behavioralControl: behavioralControl,
        enabledHint: interview.allowHint ? t("許可する") : t("許可しない"),
        ownerEmailAddress: interview.ownerEmailAddress || t("なし"),
      },
      filledVariables: interview.filledVariables.map((filledVariable): FilledVariable => {
        return {
          id: filledVariable.filledVariableId.toString(),
          label: filledVariable.label,
          description: filledVariable.description,
          answerText: filledVariable.value?.stringifiedValue || undefined,
        };
      }),
      copyButton: {
        onCopy: () => {
          Snackbar.notify({
            severity: "info",
            message: t("クリップボードにコピーされました"),
          });
        },
        buttonWithTooltip: {
          disabled: isStarted || !matchingRole.ADMIN_OR_MANAGER.matched,
          title: matchingRole.ADMIN_OR_MANAGER.messageOnUnmatched,
        },
      },
      tagDialog: tagDialog,
      accessPermissionDialog: accessPermissionDialogProps,
      ownerEmailEditor: {
        defaultValues: {
          value: interview.ownerEmailAddress,
        },
        emptyDefaultOwnerEmail: interview.ownerEmailAddress || currentUser.email,
        onSubmit: (fields, controller) => {
          controller.setLoading(true);
          updateSpot({
            input: {
              spotId: interview.id,
              ownerEmailAddress: {
                ownerEmailAddress: fields.value,
              },
            },
          })
            .then(result => {
              if (result.data?.updateSpot) {
                InterviewOverview.updateInterview(result.data.updateSpot);
                controller.close();
                Snackbar.notify({
                  severity: "success",
                  message: t("候補者に共有する連絡先を更新しました。"),
                });
              }
              if (result.error) {
                const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                  result.error,
                  t("候補者に共有する連絡先の更新に失敗しました。"),
                );
                Snackbar.notify({
                  severity: "error",
                  message: errorNotification.message,
                });
                Sentry.captureException(result.error);
              }
            })
            .finally(() => {
              controller.setLoading(false);
            });
        },
      },
    },
    reviewSummary: {
      interview: {
        status: evaluateStatus,
        isPassed: interview.isPassed,
        evaluationComment: interview.evaluationComment,
        evaluatedBy:
          (interview.evaluatedBy && {
            name: interview.evaluatedBy.displayName || interview.evaluatedBy.email,
            photoUrl: interview.evaluatedBy.photoUrl,
          }) ||
          undefined,
      },
    },
    statusInfo: statusInfoProps,
    questions: questions,
    questionOverview: questionOverview,
    customScoreAllocation: customScoreAllocation,
    timeline: timeline,
    memoEditor: {
      open: openMemoEditor,
      onOpen: () => {
        setOpenMemoEditor(true);
      },
      onClose: () => {
        setOpenMemoEditor(false);
      },
      memo: interview.memo || "",
      onSubmit: fields => {
        updateSpot({
          input: {
            spotId: interview.id,
            memo: fields.memo,
          },
        }).then(result => {
          if (result.data?.updateSpot) {
            InterviewOverview.updateInterview(result.data.updateSpot);
            setOpenMemoEditor(false);
            Snackbar.notify({
              severity: "success",
              message: t("テスト情報を更新しました。"),
            });
          }
          if (result.error) {
            const errorNotification = ErrorHandlingHelper.generateErrorNotification(result.error, t("テストの更新に失敗しました。"));
            Snackbar.notify({
              severity: "error",
              message: errorNotification.message,
            });
            Sentry.captureException(result.error);
          }
        });
      },
      disabled: resultUpdateSpot.fetching,
    },
  };
};
