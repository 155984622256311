import { useTranslation } from "@hireroo/i18n";
import { ScreeningTestRetakeForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowBackOutlined } from "@mui/icons-material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";
import ScreeningTestRetakeEditor, { ScreeningTestRetakeEditorProps } from "./parts/ScreeningTestRetakeEditor/ScreeningTestRetakeEditor";

type ShowTarget = "EDITOR" | "CONFIRM";

export type ScreeningTestRetakeDialogProps = {
  open: boolean;
  onClose: DialogWithCloseIconProps["onClose"];
  editor: Omit<ScreeningTestRetakeEditorProps, "maxTimelimitMinutes">;
  Confirm: React.ReactNode;
  defaultValues: ScreeningTestRetakeForm.RetakeScreeningTestSchema;
  onSubmit: SubmitHandler<ScreeningTestRetakeForm.RetakeScreeningTestSchema>;
  maxTimeLimitMinutes: number;
  loading?: boolean;
  buttonDisabled?: boolean;
};

const ScreeningTestRetakeDialog: React.FC<ScreeningTestRetakeDialogProps> = props => {
  const { t } = useTranslation();
  const [showingTarget, setShowingTarget] = React.useState<ShowTarget>("EDITOR");
  const validateSchema = ScreeningTestRetakeForm.useRetakeScreeningTest({ maxMinutes: props.maxTimeLimitMinutes });
  const methods = useForm<ScreeningTestRetakeForm.RetakeScreeningTestSchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: {
      ...props.defaultValues,
    },
  });

  React.useEffect(() => {
    methods.reset(props.defaultValues);
  }, [methods, props.defaultValues]);

  const handleClose = () => {
    props.onClose?.();
    methods.reset(props.defaultValues);
    setShowingTarget("EDITOR");
  };

  const dialogButtonStepMap: Record<ShowTarget, Pick<DialogWithCloseIconProps, "yesButton" | "noButton">> = {
    EDITOR: {
      yesButton: {
        children: t("内容の確認"),
        color: "secondary",
        onClick: () => {
          methods.handleSubmit(() => {
            setShowingTarget("CONFIRM");
          }, console.error)();
        },
      },
      noButton: {
        children: t("キャンセル"),
        onClick: () => {
          handleClose();
        },
        disabled: props.buttonDisabled,
        color: "outline-only",
        variant: "outlined",
      },
    },
    CONFIRM: {
      yesButton: {
        children: t("再試験を発行"),
        color: "secondary",
        onClick: () => {
          methods.handleSubmit(props.onSubmit)();
        },
        disabled: !methods.watch("agreement") || props.buttonDisabled,
      },
      noButton: {
        color: "outline-only",
        variant: "outlined",
        children: t("戻る"),
        startIcon: <ArrowBackOutlined />,
        onClick: () => {
          setShowingTarget("EDITOR");
        },
        disabled: props.buttonDisabled,
      },
    },
  };
  const dialog: DialogWithCloseIconProps = {
    ...dialogButtonStepMap[showingTarget],
    open: props.open,
    title: t("再試験を発行"),
    onClose: handleClose,
    disableEnter: true,
    yesButtonTooltip:
      showingTarget === "CONFIRM" && !methods.getValues("agreement")
        ? {
            title: t("再試験を発行するには、注意事項への同意が必要です。"),
          }
        : undefined,
    optionalContent: {
      sx: {
        px: 3,
        py: 2,
      },
    },
  };
  const editor: ScreeningTestRetakeEditorProps = {
    ...props.editor,
    maxTimelimitMinutes: props.maxTimeLimitMinutes,
  };
  return (
    <FormProvider {...methods}>
      <DialogWithCloseIcon {...dialog}>
        {props.loading && (
          <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress color="secondary" />
          </Box>
        )}
        {!props.loading && showingTarget === "EDITOR" && <ScreeningTestRetakeEditor {...editor} />}
        {!props.loading && showingTarget === "CONFIRM" && props.Confirm}
      </DialogWithCloseIcon>
    </FormProvider>
  );
};

ScreeningTestRetakeDialog.displayName = "ScreeningTestRetakeDialog";

export default ScreeningTestRetakeDialog;
