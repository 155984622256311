import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type QuestionTableContainerProps = {};

const QuestionTableContainer: React.FC<QuestionTableContainerProps> = () => {
  const questionTableProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.QuestionTable {...questionTableProps} />
    </ErrorBoundary>
  );
};

QuestionTableContainer.displayName = "QuestionTableContainer";

export default withErrorBoundary(QuestionTableContainer, {});
