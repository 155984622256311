import * as monaco from "monaco-editor";

import { IDisposable } from "./IDisposable";

export type BrightnessCallback = (hex: string) => number;
export interface ICursorWidgetConstructorOptions {
  codeEditor: monaco.editor.ICodeEditor;
  id: string;
  color: string;
  label: string;
  range: monaco.Range;
  tooltipDuration?: number;
  opacity?: string;
  onDisposed: () => void;
  brightness: BrightnessCallback;
}

export interface ICursorWidget extends monaco.editor.IContentWidget, IDisposable {
  getDecorationName(): string;

  updatePosition(range: monaco.Range): void;
  updateContent(userName?: string): void;
  isDisposed(): boolean;
}

/**
 * This class implements a Monaco Content Widget to render a user's name in a tooltip and decoration.
 * Ref: https://github.com/convergencelabs/monaco-collab-ext/blob/master/src/ts/RemoteCursorWidget.ts
 */
export class CursorWidget implements ICursorWidget {
  protected readonly _id: string;
  protected readonly _editor: monaco.editor.ICodeEditor;
  protected readonly _domNode: HTMLElement;
  protected readonly _tooltipDuration: number;
  protected readonly _scrollListener: monaco.IDisposable | null;
  protected readonly _onDisposed: () => void;

  protected _tooltipNode: HTMLElement;
  protected _color: string;
  protected _content: string;
  protected _opacity: string;
  protected _position: monaco.editor.IContentWidgetPosition | null;
  protected _hideTimer: NodeJS.Timeout | null;
  protected _disposed: boolean;
  protected _brightness_color: number;

  static readonly WIDGET_NODE_CLASSNAME = "monaco-cursor-widget";
  static readonly TOOLTIP_NODE_CLASSNAME = "monaco-cursor-widget-message";

  constructor({
    codeEditor,
    id,
    color,
    label,
    range,
    tooltipDuration = 1000,
    opacity = "1.0",
    onDisposed,
    brightness,
  }: ICursorWidgetConstructorOptions) {
    this._editor = codeEditor;
    this._tooltipDuration = tooltipDuration;
    this._id = `monaco-remote-cursor-${id}`;
    this._onDisposed = onDisposed;
    this._color = color;
    this._content = label;
    this._opacity = opacity;
    this._position = null;
    this._brightness_color = brightness(color);

    this._domNode = this._createWidgetNode();
    this._tooltipNode = this._createTooltipNode();
    this._domNode.appendChild(this._tooltipNode);

    this._addStyleRule();

    // we only need to listen to scroll positions to update the
    // tooltip location on scrolling.
    this._scrollListener = this._editor.onDidScrollChange(() => {
      this._updateTooltipPosition();
    });

    this.updatePosition(range);

    this._hideTimer = null;
    this._editor.addContentWidget(this);

    this._disposed = false;
  }

  getId(): string {
    return this._id;
  }

  getDomNode(): HTMLElement {
    return this._domNode;
  }

  getPosition(): monaco.editor.IContentWidgetPosition | null {
    return this._position;
  }

  updatePosition(range: monaco.Range): void {
    this._updatePosition(range);
    setTimeout(() => this._showTooltip(), 0);
  }

  updateContent(userName?: string): void {
    if (typeof userName !== "string" || userName === this._content) {
      return;
    }
    this._tooltipNode.textContent = userName;
  }

  dispose(): void {
    if (this._disposed) {
      return;
    }

    this._editor.removeContentWidget(this);
    if (this._scrollListener !== null) {
      this._scrollListener.dispose();
    }

    this._disposed = true;
    this._onDisposed();
  }

  isDisposed(): boolean {
    return this._disposed;
  }

  getDecorationName = (): string => {
    return `monaco-editor-selection-${this._id}`;
  };

  protected _updatePosition(range: monaco.Range): void {
    this._position = {
      position: range.getEndPosition(),
      preference: [monaco.editor.ContentWidgetPositionPreference.ABOVE, monaco.editor.ContentWidgetPositionPreference.BELOW],
    };

    this._editor.layoutContentWidget(this);
  }

  protected _showTooltip(): void {
    this._updateTooltipPosition();

    if (this._hideTimer !== null) {
      clearTimeout(this._hideTimer);
    } else {
      this._setTooltipVisible(true);
    }

    if (this._tooltipDuration === 0) {
      return;
    }

    this._hideTimer = setTimeout(() => {
      this._setTooltipVisible(false);
      this._hideTimer = null;
    }, this._tooltipDuration);
  }

  protected _updateTooltipPosition(): void {
    const distanceFromTop = this._domNode.offsetTop - this._editor.getScrollTop();
    if (distanceFromTop - this._tooltipNode.offsetHeight < 5) {
      this._tooltipNode.style.top = `${this._tooltipNode.offsetHeight + 2}px`;
    } else {
      this._tooltipNode.style.top = `-${this._tooltipNode.offsetHeight}px`;
    }

    this._tooltipNode.style.left = "0";
  }

  protected _setTooltipVisible(visible: boolean): void {
    if (visible) {
      this._tooltipNode.style.display = "block";
    } else {
      this._tooltipNode.style.display = "none";
    }
  }

  protected _colorWithCSSVars(property: string): string {
    const varName = `--color-${property}-${CursorWidget.WIDGET_NODE_CLASSNAME}`;
    return `var(${varName}, ${this._color})`;
  }

  protected _getTextColor(): string {
    return this._brightness_color >= 125 ? "#000000" : "#ffffff";
  }

  protected _createTooltipNode(): HTMLElement {
    const tooltipNode = document.createElement("div");

    tooltipNode.style.borderColor = this._colorWithCSSVars("border");
    tooltipNode.style.backgroundColor = this._colorWithCSSVars("bg");
    tooltipNode.style.color = this._getTextColor();
    tooltipNode.style.opacity = this._opacity;
    tooltipNode.style.borderRadius = "2px";
    tooltipNode.style.fontSize = "12px";
    tooltipNode.style.padding = "2px 8px";
    tooltipNode.style.whiteSpace = "nowrap";

    tooltipNode.textContent = this._content;

    const className = `${CursorWidget.TOOLTIP_NODE_CLASSNAME}-${this._color.replace("#", "")}`;
    tooltipNode.classList.add(className, CursorWidget.TOOLTIP_NODE_CLASSNAME);

    return tooltipNode;
  }

  protected _createWidgetNode(): HTMLElement {
    const widgetNode = document.createElement("div");
    widgetNode.style.height = "20px";
    widgetNode.style.paddingBottom = "0px";

    widgetNode.classList.add("monaco-editor-overlaymessage", CursorWidget.WIDGET_NODE_CLASSNAME);

    return widgetNode;
  }

  protected _addStyleRule(): void {
    const className = this.getDecorationName();
    const style = `
      .${className} {
        position: relative;
        border-left: 2px solid ${this._color};
      }
    `;
    const styleTextNode = document.createTextNode(style);
    const styleElement = document.createElement("style");
    styleElement.appendChild(styleTextNode);
    document.head.appendChild(styleElement);
  }
}
