import React from "react";
import { RemoteParticipant, Room } from "twilio-video";

type ParticipantId = string;

/**
 * This hook returns the remote participants of the room.
 */
export const useRemoteParticipants = (room: Room | null): RemoteParticipant[] => {
  const [participants, setParticipants] = React.useState<Record<ParticipantId, RemoteParticipant>>({});

  const handleParticipantConnected = React.useCallback((participant: RemoteParticipant) => {
    setParticipants(prevParticipants => ({ ...prevParticipants, [participant.identity]: participant }));
  }, []);

  const handleParticipantDisconnected = React.useCallback((participant: RemoteParticipant) => {
    setParticipants(prevParticipants => {
      const newParticipants = { ...prevParticipants };
      delete newParticipants[participant.identity];
      return newParticipants;
    });
  }, []);

  React.useEffect(() => {
    const newParticipants: Record<ParticipantId, RemoteParticipant> = {};
    Array.from(room?.participants.values() || []).forEach(participant => {
      newParticipants[participant.identity] = participant;
    });
    setParticipants(newParticipants);
  }, [room?.participants]);

  React.useEffect(() => {
    if (room) {
      room.on("participantConnected", handleParticipantConnected);
      room.on("participantDisconnected", handleParticipantDisconnected);
      return () => {
        room.off("participantConnected", handleParticipantConnected);
        room.off("participantDisconnected", handleParticipantDisconnected);
      };
    }
  }, [handleParticipantConnected, handleParticipantDisconnected, room]);

  return Object.values(participants);
};
