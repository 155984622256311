import * as Types from "./types";

export const defaultState: Readonly<Types.State> = {
  res: null,
  listParams: {
    isDescending: true,
    page: 0,
    size: 20,
    filters: {
      email: null,
      statuses: [],
    },
  },
  refresh: () => undefined,
};
