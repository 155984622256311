import { ScreeningResourceEditorQueryParams } from "@hireroo/app-definition/spot";
import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import * as TimeFormat from "@hireroo/formatter/time";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateCurrentOriginUrl } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/react";
import * as React from "react";

export type GenerateDashboardScreeningsSectionPropsArgs = {
  screenings: Graphql.ScreeningForDashboardScreeningSectionFragment[];
};

export const useGenerateProps = (args: GenerateDashboardScreeningsSectionPropsArgs): Widget.DashboardScreeningsSectionProps => {
  const { screenings } = args;
  const { t } = useTranslation();
  const navigate = useTransitionNavigate();
  const client = getGraphqlClient();

  const items = React.useMemo((): Widget.DashboardScreeningsSectionProps["items"] => {
    return screenings.map((screening): Widget.DashboardScreeningsSectionProps["items"][number] => {
      return {
        id: screening.id,
        title: {
          text: screening.name,
          onClick: () => {
            navigate("/e/screenings/:id/detail", { pathParams: { id: screening.screeningId } });
          },
        },
        deadline: screening.deadlineSeconds ? TimeFormat.unixToDateFormat(screening.deadlineSeconds) : undefined,
        editButton: {
          onClick: () => {
            navigate("/e/screenings/:id/update", { pathParams: { id: screening.screeningId } });
          },
        },
        acceptanceInfo: {
          acceptableCount: screening.acceptableLimit || undefined,
          acceptedCount: screening.numAccepted,
          invitation:
            screening.candidateAccessPolicy === "ALLOW_ALL"
              ? {
                  url: generateCurrentOriginUrl("/c/screenings/:id", { pathParams: { id: screening.screeningId } }),
                  onCopy: () => {
                    Snackbar.notify({
                      severity: "info",
                      message: t("クリップボードにコピーされました"),
                    });
                  },
                  disabled: !screening.isActive,
                }
              : undefined,
        },
        notEvaluatedButton: screening.spotStats?.spotCountByStatus?.finalized
          ? {
              onClick: () => {
                navigate("/e/screenings/:id/detail", {
                  pathParams: { id: screening.screeningId },
                  queryParams: {
                    tab: "CANDIDATES",
                    status: "FINALIZED",
                  },
                });
              },
              count: screening.spotStats.spotCountByStatus.finalized,
            }
          : undefined,
        evaluatedButton: screening.spotStats?.spotCountByStatus?.evaluated
          ? {
              onClick: () => {
                navigate("/e/screenings/:id/detail", {
                  pathParams: {
                    id: screening.screeningId,
                  },
                  queryParams: {
                    tab: "CANDIDATES",
                    status: "EVALUATED",
                  },
                });
              },
              count: screening.spotStats.spotCountByStatus.evaluated,
            }
          : undefined,
        inviteDialog: {
          editButton: {
            onClick: () => {
              navigate("/e/screenings/:id/update", {
                pathParams: { id: screening.screeningId },
                queryParams: {
                  [ScreeningResourceEditorQueryParams.Key]: ScreeningResourceEditorQueryParams.Value.TestInviteSetup,
                },
              });
            },
          },
          onSubmit: fields => {
            client
              .InviteCandidateToScreeningForScreenings({
                input: {
                  screeningId: screening.screeningId,
                  candidateEmails: fields.emails.map(email => email.value),
                },
              })
              .then(() => {
                Snackbar.notify({
                  severity: "success",
                  message: t("招待メールを候補者に送信しました。"),
                });
              })
              .catch(error => {
                Sentry.captureException(error);
                const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("招待メールの送信に失敗しました。"));
                Snackbar.notify({
                  severity: "error",
                  message: errorNotification.message,
                });
              });
          },
          copyButton:
            screening.candidateAccessPolicy === "ALLOW_ALL"
              ? {
                  url: generateCurrentOriginUrl("/c/screenings/:id", { pathParams: { id: screening.screeningId } }),
                  onCopy: () => {
                    Snackbar.notify({
                      severity: "info",
                      message: t("クリップボードにコピーされました"),
                    });
                  },
                }
              : undefined,
          language: screening.invitationLanguage !== "UNKNOWN" ? screening.invitationLanguage : "JA",
          invitedListButton: {
            onClick: () => {
              navigate("/e/screenings/:id/detail", {
                pathParams: { id: screening.screeningId },
                queryParams: { tab: "INVITEES" },
              });
            },
          },
        },
        status: screening.isActive ? "ACTIVE" : "INACTIVE",
      };
    });
  }, [client, navigate, screenings, t]);

  return {
    header: {
      titleLink: {
        onClick: () => {
          navigate("/e/screenings");
        },
      },
      createButton: {
        onClick: () => {
          navigate("/e/screenings/create");
        },
      },
    },
    items: items,
  };
};
