import { Payment } from "@hireroo/app-store/essential/employee";
import { PlanUpdateForm } from "@hireroo/app-store/widget/e/PlanUpdateForm";
import { Widget } from "@hireroo/presentation";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GeneratePlanUpdateFormPropsArgs, useGenerateProps } from "./useGenerateProps";

export type PlanUpdateFormContainerProps = GeneratePlanUpdateFormPropsArgs;

const PlanUpdateFormContainer: React.FC<PlanUpdateFormContainerProps> = props => {
  const planUpdateFormProps = useGenerateProps(props);
  const navigate = useTransitionNavigate();
  const availableForPurchase = Payment.useAvailableForPurchase();
  /** Not available with FetchContainer because it can only be used after initialization.  */
  const customer = PlanUpdateForm.useCustomer();
  const [redirectCheckCompleted, setRedirectCheckCompleted] = React.useState(false);

  React.useEffect(() => {
    /**
     * Users on trial can use this page to change plans.
     */
    if (customer.isTrial) {
      setRedirectCheckCompleted(true);
      return;
    }

    /**
     * - If the plan is not changeable
     * - Users whose payment information cannot be verified will not be able to use plan changes.
     * - Enterprise users cannot change plans.
     * - Cannot change plan outside the contract period
     * - Only paid users can purchase
     */
    if (
      !customer.subscription.plan.enabledChangePlan ||
      !availableForPurchase ||
      customer.subscription.plan.planType === "ENTERPRISE" ||
      customer.subscription.status === "INACTIVE" ||
      customer.paymentStatus !== "PAID"
    ) {
      navigate("/e/settings/billing/overview");
    } else {
      setRedirectCheckCompleted(true);
    }
  }, [
    navigate,
    customer.isTrial,
    customer.subscription.plan.enabledChangePlan,
    availableForPurchase,
    customer.paymentType,
    customer.subscription.plan.planType,
    customer.subscription.status,
    customer.paymentStatus,
  ]);

  if (!redirectCheckCompleted) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  return (
    <ErrorBoundary>
      <Widget.PlanUpdateForm {...planUpdateFormProps} />
    </ErrorBoundary>
  );
};

PlanUpdateFormContainer.displayName = "PlanUpdateFormContainer";

export default withErrorBoundary(PlanUpdateFormContainer, {});
