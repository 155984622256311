import { useTranslation } from "@hireroo/i18n";
import { PlayArrow } from "@mui/icons-material";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import * as React from "react";

import FormSubmitButton, { FormSubmitButtonProps } from "../../../../primitive/Button/FormSubmitButton";
import BasicTab, { BasicTabProps } from "../../../../primitive/Tab/BasicTab/BasicTab";
import CorrectnessTable, { CorrectnessTableProps } from "./CorrectnessTable/CorrectnessTable";
import PerformanceTable, { PerformanceTableProps } from "./PerformanceTable/PerformanceTable";

type TestCaseType = "correctness" | "performance";

export type TestCaseRunnerSectionProps = {
  runAllTestCaseButton: {
    onClick: (selectedType: TestCaseType) => void;
  } & Pick<FormSubmitButtonProps, "loading">;
  correctnessTable: CorrectnessTableProps;
  performanceTable: PerformanceTableProps;
};

const TestCaseRunnerSection: React.FC<TestCaseRunnerSectionProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [currentTab, setCurrentTab] = React.useState<TestCaseType>("correctness");
  const testcaseTab: BasicTabProps = {
    defaultTab: "correctness",
    tabBox: {
      // overwrite paper
      sx: {},
    },
    tabs: {
      sx: {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
      onChange: (_event, value) => {
        setCurrentTab(value);
      },
    },
    items: [
      {
        id: "correctness",
        name: t("正解率"),
        Component: <CorrectnessTable {...props.correctnessTable} />,
      },
      {
        id: "performance",
        name: t("パフォーマンス"),
        Component: <PerformanceTable {...props.performanceTable} />,
      },
    ],
  };
  const { onClick, ...restButton } = props.runAllTestCaseButton;
  const runAllTestCaseButton: FormSubmitButtonProps = {
    ...restButton,
    onClick: React.useCallback(() => onClick(currentTab), [currentTab, onClick]),
    size: "small",
    variant: "text",
    fullWidth: false,
    startIcon: <PlayArrow fontSize="small" />,
    children: t("全てのケースを実行する"),
  };
  return (
    <Box>
      <BasicTab {...testcaseTab} />

      <Box display="flex" justifyContent="flex-end" mt={1}>
        <FormSubmitButton {...runAllTestCaseButton} />
      </Box>
    </Box>
  );
};

TestCaseRunnerSection.displayName = "TestCaseRunnerSection";

export default TestCaseRunnerSection;
