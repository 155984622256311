import { RemotesId } from "@hireroo/app-store/page/c/remotes_id";
import { RemoteInterviewTimelimit } from "@hireroo/app-store/widget/shared/RemoteInterviewTimelimit";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import type { Pages } from "@hireroo/presentation";
import { backOff } from "exponential-backoff";
import * as React from "react";

import FooterContainer from "../../../../widget/v2/c/Footer/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import RemoteInterviewCandidateHeaderContainer from "./widget/RemoteInterviewCandidateHeader/Container";

export type GenerateWaitingRoomPropsArgs = {
  enablePolling: boolean;
  remoteId: string;
};

export const useGenerateWaitingRoomProps = (args: GenerateWaitingRoomPropsArgs): Pages.WaitingRoomProps => {
  const client = getGraphqlClient();

  React.useEffect(() => {
    if (!args.enablePolling) {
      return;
    }
    backOff(
      async () => {
        const res = await client.GetRemotesForCandidate({
          remoteId: args.remoteId,
        });
        const { status } = res.remote;
        if (status === "CREATED") {
          return Promise.reject();
        } else {
          RemotesId.initialize(res.remote);
          if (res.remote.didStartAtSeconds) {
            RemoteInterviewTimelimit.initialize(res.remote.didStartAtSeconds);
          }
          return Promise.resolve();
        }
      },
      {
        delayFirstAttempt: true,
        timeMultiple: 1,
        /**
         * 100 x 3000ms = 5min
         */
        numOfAttempts: 100,
        /**
         * 3000ms
         */
        maxDelay: 3000,
        startingDelay: 3000,
      },
    );
  }, [args.remoteId, client, args.enablePolling]);
  return {
    content: {
      type: "FOR_CANDIDATE",
    },
    layout: {
      NotificationBanner: null,
      Header: <RemoteInterviewCandidateHeaderContainer mode="WAITING_ROOM" />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      Tutorial: <></>,
    },
  };
};
