import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import MessageCard, { MessageCardProps } from "./parts/MessageCard/MessageCard";
import PromptTextField, { PromptTextFieldProps } from "./parts/PromptTextField/PromptTextField";

const Wrapper = styled(Box)(() => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

const StyledMiddleBox = styled(Box)(() => ({
  flexGrow: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
}));

const StyledMessageStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1),
  height: "100%",
  overflowY: "auto",
  flexGrow: 1,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: theme.palette.action.active,
  textAlign: "center",
}));

const FooterWrapper = styled(Box)(({ theme }) => ({
  height: "fit-content",
  display: "flex",
  flexDirection: "row",
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}));

export type ChatGPTSidePanelProps = {
  loadingStatus: "LOADING" | "RESETTING" | "NONE";
  messageCards: MessageCardProps[];
  promptTextField: Omit<PromptTextFieldProps, "loadingStatus">;
  /**
   * @default false
   */
  disableAutoScroll?: boolean;
  gptModel: "GPT-3.5" | "GPT-4o";
};

const ChatGPTSidePanel: React.FC<ChatGPTSidePanelProps> = props => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const { disableAutoScroll = false } = props;

  /**
   * Scroll when new comments are added.
   */
  React.useEffect(() => {
    if (disableAutoScroll) {
      return;
    }
    if (wrapperRef.current) {
      wrapperRef.current.scrollBy({ top: wrapperRef.current.scrollHeight, behavior: "smooth" });
    }
  }, [props.loadingStatus, disableAutoScroll]);

  return (
    <Wrapper>
      {props.messageCards.length > 0 && (
        <StyledMessageStack spacing={2} mb={2} ref={wrapperRef}>
          {props.messageCards.map((messageCard, index) => {
            return <MessageCard key={`${messageCard.avatar.role}-${index}`} {...messageCard} />;
          })}
          {props.loadingStatus === "LOADING" && <MessageCard key={"loading"} loading avatar={{ role: "ASSISTANT" }} message="" />}
          {props.loadingStatus === "RESETTING" && (
            <Box display="flex" justifyContent="center" height="100%">
              <CircularProgress color="secondary" size={25} />
            </Box>
          )}
        </StyledMessageStack>
      )}

      {props.messageCards.length === 0 && (
        <StyledMiddleBox>
          <Box>
            <StyledTypography fontSize={24}>ChatGPT</StyledTypography>
            <StyledTypography fontSize={20}>({props.gptModel})</StyledTypography>
          </Box>
        </StyledMiddleBox>
      )}

      <FooterWrapper>
        <PromptTextField {...props.promptTextField} loadingStatus={props.loadingStatus} />
      </FooterWrapper>
    </Wrapper>
  );
};

ChatGPTSidePanel.displayName = "ChatGPTSidePanel";

export default ChatGPTSidePanel;
