import { proxy } from "valtio";

import type * as Types from "./types";

export const state = proxy<Types.State>({
  res: null,
  pager: {
    sortFieldValue: "created-at-descending",
    page: 1,
    size: 10,
    offset: 0,
  },
  currentSearchFilter: null,
});
