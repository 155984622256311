import Stack from "@mui/material/Stack";
import * as React from "react";
import { FormProvider } from "react-hook-form";

import ConfirmForm, { ConfirmFormProps } from "../ConfirmForm/ConfirmForm";
import EntryAssessmentOverviewSection, {
  EntryAssessmentOverviewSectionProps,
} from "../EntryAssessmentOverviewSection/EntryAssessmentOverviewSection";
import Onboarding, { OnboardingProps } from "../Onboarding/Onboarding";
import { useEntryTestContext } from "./Context";

export type EntryContentsProps = {
  entryAssessmentOverviewSection: EntryAssessmentOverviewSectionProps;
  confirmForm: ConfirmFormProps;
  onboarding: OnboardingProps;
};

const EntryContents: React.FC<EntryContentsProps> = props => {
  const { methods } = useEntryTestContext();
  return (
    <Stack spacing={2} m={"auto"} sx={{ minWidth: "640px", maxWidth: "800px" }}>
      <FormProvider {...methods}>
        <EntryAssessmentOverviewSection {...props.entryAssessmentOverviewSection} />
        <Onboarding {...props.onboarding} />
        <ConfirmForm {...props.confirmForm} />
      </FormProvider>
    </Stack>
  );
};

EntryContents.displayName = "EntryContents";

export default EntryContents;
