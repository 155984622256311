import Logo from "@hireroo/ui-assets/images/Logo/Logo.png";
import * as React from "react";

import Link, { LinkProps } from "../../primitive/Link/Link";

export type BrandLogoProps = Pick<LinkProps, "href" | "onClick">;

const BrandLogo: React.FC<BrandLogoProps> = props => {
  return (
    <Link {...props}>
      <img src={Logo} width={100} alt="hireroo logo" />
    </Link>
  );
};

BrandLogo.displayName = "BrandLogo";

export default BrandLogo;
