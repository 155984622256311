import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import * as React from "react";

import CustomStepLabel, { CustomStepLabelProps } from "../CustomStepLabel/CustomStepLabel";

export type IpAddressDetectionReportProps = {
  firstAccessInfo: CustomStepLabelProps;
  extraAccessInfos: CustomStepLabelProps[];
};

const IpAddressDetectionReport: React.FC<IpAddressDetectionReportProps> = props => {
  return (
    <Stack direction="column" divider={<Divider />} spacing={1} width="100%">
      <CustomStepLabel {...props.firstAccessInfo} />
      {props.extraAccessInfos.map((extraAccessInfo, index) => {
        return <CustomStepLabel key={`label=${index}`} {...extraAccessInfo} />;
      })}
    </Stack>
  );
};

IpAddressDetectionReport.displayName = "IpAddressDetectionReport";

export default IpAddressDetectionReport;
