import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateChallengePlaybackSettingsMenuPropsArgs, useGenerateProps } from "./useGenerateProps";

export type ChallengePlaybackSettingsMenuContainerProps = GenerateChallengePlaybackSettingsMenuPropsArgs;

const ChallengePlaybackSettingsMenuContainer: React.FC<ChallengePlaybackSettingsMenuContainerProps> = props => {
  const challengePlaybackSettingsMenuProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ChallengePlaybackSettingsMenu {...challengePlaybackSettingsMenuProps} />
    </ErrorBoundary>
  );
};

ChallengePlaybackSettingsMenuContainer.displayName = "ChallengePlaybackSettingsMenuContainer";

export default withErrorBoundary(ChallengePlaybackSettingsMenuContainer, {});
