import { proxy } from "valtio";
import { proxyMap } from "valtio/utils";

import type { State } from "./types";

export const state = proxy<State>({
  res: null,
  invoices: proxyMap(),
  page: 0,
});
