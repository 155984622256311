import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Box, { BoxProps } from "@mui/material/Box";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React, { useCallback, useEffect, useReducer, useState } from "react";

import { MAX_SCALE, MIN_SCALE } from "../../constants/flowChart";
import { Area, ComponentType, FlowElement, UserState } from "../../features";
import { svgTransform } from "../../helpers/flowChart";
import { initialState, reducer } from "./reducer";
import { StaticViewbox } from "./StaticViewbox";

export type StaticFlowChartProps = {
  elements: FlowElement[];
  componentType: ComponentType;
  aspectRatio?: number;
  width?: number | string;
  viewarea?: Area;
  padding?: number;
  disableZoom?: boolean;
  disableGrid?: boolean;
  simpleView?: boolean;
  viewbox?: Area;
  disableAutoResize?: boolean;
  matchedIds?: string[];
  authors?: UserState[];
};

const RootBox = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "100%",
  backgroundColor: theme.palette.common.white,
}));

const MiniBarBox = styled(Box)`
  display: flex;
  align-items: center;
  position: absolute;
  left: 8px;
  bottom: 0;
`;

const IconBox = styled(({ disabledBox: _, ...props }: BoxProps & { disabledBox: boolean }) => <Box {...props} />)(({ disabledBox, theme }) => {
  return {
    backgroundColor: theme.palette.grey[200],
    borderRadius: 20,
    "&:hover": {
      backgroundColor: disabledBox ? undefined : theme.palette.grey[300],
    },
  };
});

const StyledIconButton = styled(({ disabled: _, ...props }: IconButtonProps & { disabled: boolean }) => <IconButton {...props} />)(({
  disabled,
  theme,
}) => {
  return {
    color: disabled ? theme.palette.grey[400] : theme.palette.common.black,
    cursor: "default",
  };
});

const StaticFlowChart: React.FC<StaticFlowChartProps> = props => {
  const theme = useTheme();
  const [ready, setReady] = useState<boolean>(false);
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleZoomIn = useCallback(() => {
    const newScale = (Math.round(state.scale * 10) + 1) / 10;
    dispatch({ type: "zoomViewbox", payload: { scale: newScale } });
  }, [state.scale]);

  const handleZoomOut = useCallback(() => {
    const newScale = (Math.round(state.scale * 10) - 1) / 10;
    dispatch({ type: "zoomViewbox", payload: { scale: newScale } });
  }, [state.scale]);

  const handlePanAndZoom = useCallback(
    (e: React.WheelEvent<SVGSVGElement>, svgElement: SVGSVGElement) => {
      const mousePoint = svgTransform(svgElement, { x: e.clientX, y: e.clientY });
      if (!mousePoint) return;

      const newScale = e.deltaY < 0 ? (Math.round(state.scale * 10) + 1) / 10 : (Math.round(state.scale * 10) - 1) / 10;

      if (e.ctrlKey || e.metaKey) {
        dispatch({ type: "zoomViewbox", payload: { scale: newScale, focus: mousePoint } });
      } else {
        dispatch({ type: "panViewbox", payload: { dx: e.deltaX, dy: e.deltaY } });
      }
    },
    [state.scale],
  );

  useEffect(() => {
    dispatch({
      type: "restoreElements",
      payload: {
        elements: props.elements,
        aspectRatio: props.aspectRatio ?? 0.75,
        padding: props.padding,
        viewarea: props.viewarea,
        disableAutoResize: props.disableAutoResize,
      },
    });
    setReady(true);
  }, [dispatch, props.elements, props.viewarea, props.aspectRatio, props.padding, props.disableAutoResize]);

  useEffect(() => {
    if (props.viewbox && !state.scaleChanged) {
      dispatch({
        type: "initializeViewbox",
        payload: props.viewbox,
      });
    }
    setReady(true);
  }, [props.viewbox, state.scaleChanged]);

  return (
    <RootBox width={props.width}>
      {ready && (
        <StaticViewbox
          viewbox={state.viewbox}
          viewarea={props.viewarea}
          elementIds={state.elementIds}
          componentType={props.componentType}
          elements={state.elements}
          onWheel={props.disableZoom ? undefined : handlePanAndZoom}
          disableGrid={props.disableGrid}
          simpleView={props.simpleView}
          matchedIds={props.matchedIds}
          authors={props.authors}
        />
      )}
      {!props.disableZoom && (
        <MiniBarBox>
          <Paper sx={{ backgroundColor: theme.palette.common.white }} elevation={4}>
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} p={1}>
              <IconBox disabledBox={state.scale === MIN_SCALE}>
                <StyledIconButton disabled={state.scale === MIN_SCALE} size={"small"} onClick={handleZoomOut}>
                  <RemoveIcon />
                </StyledIconButton>
              </IconBox>
              <Box mr={1} />
              <IconBox disabledBox={state.scale === MAX_SCALE}>
                <StyledIconButton disabled={state.scale === MAX_SCALE} size={"small"} onClick={handleZoomIn}>
                  <AddIcon />
                </StyledIconButton>
              </IconBox>
              <Box mr={1} />
              <Typography sx={{ color: theme.palette.common.black }}>{Math.floor(state.scale * 100)}%</Typography>
            </Box>
          </Paper>
          <Box mr={1} />
        </MiniBarBox>
      )}
    </RootBox>
  );
};

StaticFlowChart.displayName = "StaticFlowChart";

export default StaticFlowChart;
