import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem, { MenuItemProps as MuiMenuItemProps } from "@mui/material/MenuItem";
import { PopoverProps } from "@mui/material/Popover/Popover";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledMenuItem = styled(MenuItem)({
  root: {
    "&.Mui-disabled": {
      pointerEvents: "auto",
    },
  },
});

type DisabledExtend = { disabled: boolean; disabledText: string } | { disabled?: undefined; disabledText?: undefined };

type MenuItemProps = Pick<MuiMenuItemProps, "color" | "onClick" | "divider"> & {
  Icon?: React.ReactNode;
  displayText: string;
} & DisabledExtend;

export type PopupMenuProps = {
  open: boolean;
  anchorEl: null | HTMLElement;
  items: MenuItemProps[];
  onClose: PopoverProps["onClose"];
};

const PopupMenu: React.FC<PopupMenuProps> = props => {
  const { items, open, anchorEl, onClose } = props;
  return (
    <Menu open={open} anchorEl={anchorEl} onClose={onClose}>
      {items.map((item, index) => {
        return (
          <Tooltip key={`${index}-${item.displayText}`} title={item.disabled ? item.disabledText : ""}>
            <span>
              <StyledMenuItem disabled={item.disabled} onClick={item.onClick} divider={item.divider}>
                {item.Icon && <ListItemIcon>{item.Icon}</ListItemIcon>}
                <Typography color={item.color}>{item.displayText}</Typography>
              </StyledMenuItem>
            </span>
          </Tooltip>
        );
      })}
    </Menu>
  );
};

PopupMenu.displayName = "PopupMenu";

export default PopupMenu;
