import { useSnapshot } from "valtio";

import { state } from "./State";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useStatus = () => {
  const state = useSnapshotState();
  return state.status;
};

export const useEnableTalentScore = () => {
  const state = useSnapshotState();
  return state.enableTalentScore;
};
