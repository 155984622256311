import { ChallengePlayback } from "@hireroo/app-store/widget/shared/ChallengePlayback";
import * as Time from "@hireroo/formatter/time";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

type ActiveRange = Widget.ActivityTimeBarChartPanelProps["timeTrackerReport"]["activeRanges"][0];
type Item = Widget.ActivityTimeBarChartPanelProps["items"][0];

export type GenerateUseHintEventFrequencyTimelinePanelPropsArgs = {};

export const useGenerateProps = (_args: GenerateUseHintEventFrequencyTimelinePanelPropsArgs): Widget.ActivityTimeBarChartPanelProps => {
  const { t } = useTranslation();
  const playbackManager = ChallengePlayback.usePlaybackManager();
  const useHintIntervals = ChallengePlayback.useUseHintIntervals();
  const startTs = React.useMemo(() => {
    return playbackManager.timeStamps.at(0) ?? 0;
  }, [playbackManager]);
  const activeRanges = React.useMemo((): ActiveRange[] => {
    const endTs = playbackManager.timeStamps.at(-1) ?? 0;
    const width = endTs - startTs;

    return useHintIntervals.map((interval): ActiveRange => {
      const leftPos = interval.startTs - startTs;
      const intervalWidth = interval.endTs - interval.startTs;
      return {
        start: `${Math.floor((leftPos / width) * 100)}%`,
        width: `${Math.floor((intervalWidth / width) * 100)}%`,
        display: Time.elapsedTimeFormat(interval.endTs - interval.startTs),
      };
    });
  }, [startTs, useHintIntervals, playbackManager]);

  const timeLabels = React.useMemo((): string[] => {
    const endTime = playbackManager.timeStamps.at(-1) ?? 0;
    const startTime = playbackManager.timeStamps.at(0) ?? 0;
    const list = ["00:00", Time.elapsedTimeFormat(endTime - startTime)];
    return Array.from(new Set(list));
  }, [playbackManager]);

  return {
    title: t("ヒントの使用頻度"),
    timeFieldLabel: t("ヒントの使用時刻"),
    timeTrackerReport: {
      activeRanges: activeRanges,
      timeLabels: timeLabels,
    },
    items: useHintIntervals.map((interval, index): Item => {
      return {
        name: `${t("ヒントの使用")} ${index + 1}`,
        description: Time.elapsedTimeFormat(interval.startTs - startTs),
        onClick: () => {
          const index = playbackManager.getIndexByTimeStamp(interval.startTs);
          if (index) {
            ChallengePlayback.updateSliderValue(index);
          }
        },
      };
    }),
  };
};
