import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { AvatarProps, MenuItem } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Skeleton from "@mui/material/Skeleton";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";

const StyledAvatar = styled(Avatar)`
  border-radius: 4px;
`;

type Option = {
  text: string;
  value: string;
  imgUrl?: string;
  onClick?: () => void;
  default?: boolean;
};

export type IconDropDownProps = {
  options: Option[];
  role: string;
  menuId: string;
  button?: Omit<ButtonProps, "onClick">;
};

const IconDropDown: React.FC<IconDropDownProps> = props => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const buttonRef = useResizeDetector();

  const defaultOption: Option = React.useMemo(() => {
    return (
      props.options.find(o => !!o.default) ||
      props.options[0] || {
        text: "",
        value: "",
      }
    );
  }, [props.options]);
  const [selectedOption, updateOption] = React.useState<Option>(defaultOption);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnClick = React.useCallback((newOption: Option) => {
    updateOption(newOption);
    handleClose();
  }, []);

  if (props.options.length === 0) {
    return <Skeleton width="100px" height="36px" />;
  }

  const avatarSx: AvatarProps["sx"] = {
    width: "32px",
    height: "32px",
  };

  return (
    <Box width="100%">
      <Button
        {...props.button}
        role="openner"
        endIcon={<ArrowDropDownIcon sx={{ color: theme.palette.secondary.light }} />}
        onClick={handleClick}
        fullWidth
        ref={buttonRef.ref}
      >
        {selectedOption.imgUrl && <StyledAvatar src={selectedOption.imgUrl} variant="square" sx={avatarSx} />}
        <Typography ml={2} color={theme.palette.text.primary}>
          {selectedOption.text}
        </Typography>
      </Button>
      <Menu
        PaperProps={{
          elevation: 2,
          sx: {
            minWidth: buttonRef.width,
          },
        }}
        role={`${props.role}menu`}
        id={props.menuId}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.options.map(option => (
          <MenuItem
            role={`menu${option.text}`}
            key={`menu-${option.text}`}
            value={option.value}
            selected={selectedOption.value === option.value}
            onClick={() => {
              option.onClick?.();
              handleOnClick(option);
            }}
          >
            {option.imgUrl && <StyledAvatar src={option.imgUrl} variant="square" sx={avatarSx} />}
            <Typography ml={2} color={theme.palette.text.primary}>
              {option.text}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

IconDropDown.displayName = "IconDropDown";

export default IconDropDown;
