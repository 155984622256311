import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import * as React from "react";

import InputControlTextField, { InputControlTextFieldProps } from "../../../primitive/InputControl/InputControlTextField";
import MarkdownPreviewEditor, { MarkdownPreviewEditorProps } from "../../../usecase/MarkdownPreviewEditor/MarkdownPreviewEditor";

export type QuizPackageDetailFieldProps = {
  titleField: InputControlTextFieldProps & { name: string };
  MarkdownPreviewEditor: MarkdownPreviewEditorProps & { name: string };
};

const QuizPackageDetailField: React.FC<QuizPackageDetailFieldProps> = props => {
  const { t } = useTranslation();
  const titleFieldProps: InputControlTextFieldProps = {
    ...props.titleField,
    label: t("タイトル"),
    placeholder: t("SQL入門"),
    required: true,
    fullWidth: true,
    variant: "outlined",
    InputLabelProps: {
      shrink: true,
    },
  };
  const MarkdownPreviewEditorProps: MarkdownPreviewEditorProps = {
    ...props.MarkdownPreviewEditor,
    label: t("詳細"),
    required: true,
    placeholder: t("ex) 計算量について記述されている。"),
    helperText: t("解答の本文を入力します。本文はMarkdown形式で入力できます。"),
  };
  return (
    <Box>
      <Box display="flex" mb={3}>
        <InputControlTextField {...titleFieldProps} name={props.titleField.name} />
      </Box>
      <MarkdownPreviewEditor {...MarkdownPreviewEditorProps} name={props.MarkdownPreviewEditor.name} areaSize="md" />
    </Box>
  );
};

QuizPackageDetailField.displayName = "QuizPackageDetailField";

export default QuizPackageDetailField;
