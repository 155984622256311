import { useTranslation } from "@hireroo/i18n";
import { ResetPasswordForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import Stack from "@mui/material/Stack";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import ResourceField from "../../../../modules/ResourceField/ResourceField";
import PasswordField, { PasswordFieldProps } from "../../../../ms-components/Auth/PasswordField/PasswordField";
import FormSubmitButton, { FormSubmitButtonProps } from "../../../../primitive/Button/FormSubmitButton/FormSubmitButton";

export type PasswordFormProps = {
  onSubmit: SubmitHandler<ResetPasswordForm.ResetPasswordFormSchema>;
};

const PasswordForm: React.FC<PasswordFormProps> = props => {
  const { t } = useTranslation();
  const validateSchema = ResetPasswordForm.useResetPasswordFormSchema();
  const method = useForm<ResetPasswordForm.ResetPasswordFormSchema>({
    resolver: zodResolver(validateSchema),
    reValidateMode: "onSubmit",
    defaultValues: { password: "", confirmPassword: "" },
  });
  const disabled = !!method.formState.errors.password || !!method.formState.errors.confirmPassword;
  React.useEffect(() => {
    const subscriber = method.watch(() => {
      method.clearErrors();
    });

    return () => {
      subscriber.unsubscribe();
    };
  }, [method]);

  const passwordFieldProps: PasswordFieldProps = {
    color: "primary",
    enableVisibilityButton: true,
  };

  const button: FormSubmitButtonProps = {
    children: t("パスワードを変更する"),
    onClick: method.handleSubmit(props.onSubmit),
    disabled: disabled,
    loading: method.formState.isSubmitting,
    sx: {
      height: "40px",
      textTransform: "none",
    },
  };
  return (
    <Stack spacing={2}>
      <FormProvider {...method}>
        <ResourceField label={t("新しいパスワード")} kind="REQUIRED" formLabel={{ sx: { fontWeight: 400, mb: 1 } }}>
          <PasswordField name="password" {...passwordFieldProps} />
        </ResourceField>
        <ResourceField label={t("新しいパスワードの確認")} kind="REQUIRED" formLabel={{ sx: { fontWeight: 400, mb: 1 } }}>
          <PasswordField name="confirmPassword" {...passwordFieldProps} />
        </ResourceField>
        <FormSubmitButton {...button} />
      </FormProvider>
    </Stack>
  );
};

PasswordForm.displayName = "PasswordForm";

export default PasswordForm;
