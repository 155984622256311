import { state } from "./State";
import type * as Types from "./types";

export const setSearchText = (text: string) => {
  state.searchText = text;
};

export const addMetrics = (newMetrics: Types.Metric[]) => {
  newMetrics.forEach(metric => {
    state.metrics.set(metric.metricId, metric);
  });
};

export const updateMetricPagination = (newValue: Partial<Types.MetricPagination>): void => {
  state.metricPagination = {
    ...state.metricPagination,
    ...newValue,
  };
};

export const selectMetric = (id: Types.MetricId) => {
  state.selectedMetricIds.add(id);
};

export const selectMetrics = (ids: Types.MetricId[]) => {
  ids.forEach(id => {
    state.selectedMetricIds.add(id);
  });
};

export const removeMetric = (id: Types.MetricId) => {
  state.selectedMetricIds.delete(id);
};
