import { ChallengeTestReport } from "@hireroo/app-store/view-domain/ChallengeTestReport";
import { languageMapForHighlight } from "@hireroo/challenge/definition";
import { formatScore } from "@hireroo/formatter/score";
import { Widget } from "@hireroo/presentation";
import { generateCurrentOriginUrl } from "@hireroo/router/api";
import * as React from "react";

type ChallengeCheatDetectionProps = Exclude<Widget.ChallengeTestReportProps["cheatDetectionSection"], undefined>;
type SimilarCodes = Exclude<ChallengeCheatDetectionProps["similarCodes"], undefined>;
type RightFooter = Extract<SimilarCodes, { kind: "SHOW_CONTENT" }>["pairEditor"]["rightFooter"];

export type GenerateIpAddressSectionArgs = {
  challengeId: number;
};

export const useGenerateSimilarCodesProps = (args: GenerateIpAddressSectionArgs): SimilarCodes => {
  const challengeHooks = ChallengeTestReport.useCreateChallengeHooks(args.challengeId);
  const submissionMap = challengeHooks.useSubmissionMap();
  const submissionId = challengeHooks.useCurrentSelectedSubmissionId();
  const submission = submissionMap[submissionId];
  const [similarCodeIndex, setSimilarCodeIndex] = React.useState<number>(0);
  const similarCode = React.useMemo(() => {
    return submission.similarCodes[similarCodeIndex];
  }, [submission.similarCodes, similarCodeIndex]);

  if (submission.similarCodes.length === 0) {
    return { kind: "NOTHING_TO_SHOW" };
  }

  const rightFooter: RightFooter = (() => {
    switch (similarCode.metadata.__typename) {
      case "SimilarCodeCrawlerMetadata": {
        return {
          kind: "ACCESSIBLE",
          url: similarCode.metadata.url,
        };
      }
      case "SimilarCodeSpotMetadata": {
        return {
          kind: "ACCESSIBLE",
          url: generateCurrentOriginUrl("/e/screenings/tests/:id/detail", {
            pathParams: { id: similarCode.metadata.spotId },
            queryParams: {
              tab: "report",
            },
            hash: `challenge-${similarCode.metadata.challengeId}-${similarCode.metadata.questionId}-${similarCode.metadata.questionVersion}`,
          }),
        };
      }
      case "SimilarCodeOtherCompanyMetadata": {
        return {
          kind: "OTHER_COMPANY",
        };
      }
      case "SimilarCodeNotFoundMetadata": {
        return {
          kind: "NOT_FOUND",
        };
      }
      default:
        throw new Error(`invalid ${similarCode.metadata satisfies never}`);
    }
  })();

  return {
    kind: "SHOW_CONTENT",
    pairEditor: {
      pair: {
        leftValue: submission.code,
        rightValue: similarCode.code,
        fragments: similarCode.fragments,
      },
      rightFooter: rightFooter,
      runtime: languageMapForHighlight[submission.runtime],
    },
    similarity: `${formatScore(similarCode.score)} %`,
    decrementButton: {
      disabled: similarCodeIndex === 0,
      onClick: () => {
        setSimilarCodeIndex(oldIndex => {
          if (oldIndex >= 1) {
            return oldIndex - 1;
          }
          return oldIndex;
        });
      },
    },
    incrementButton: {
      disabled: similarCodeIndex === submission.similarCodes.length - 1,
      onClick: () => {
        setSimilarCodeIndex(oldIndex => {
          if (oldIndex < submission.similarCodes.length - 1) {
            return oldIndex + 1;
          }
          return oldIndex;
        });
      },
    },
  };
};
