import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import * as semver from "semver";
import * as z from "zod";

const useRuntime = () => {
  return z.object({
    id: z.number().optional(),
    runtime: z.string(),
    body: z.string(),
  });
};

const useFlowChart = () => {
  return z.object({
    id: z.number().optional(),
    componentType: z.union([z.literal("default"), z.literal("aws"), z.literal("gcp"), z.literal("azure")]),
    body: z.string(),
  });
};

export const useOptionalFormSchema = () => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const runtime = useRuntime();
  const flowchart = useFlowChart();
  return z
    .object({
      newVersion: z
        .string()
        .min(1, { message: t("バージョンは必須項目です。") })
        .regex(/^v([0-9]+)\.([0-9]+)\.([0-9]+)$/, { message: t2("ValidateQuestionSetVersion") }),
      oldVersion: z.string(),
      needUpdateVersion: z.boolean(),
      initialCodes: runtime.array(),
      initialFlowCharts: flowchart.array(),
    })
    .superRefine((obj, ctx) => {
      const oldVersion = obj.oldVersion.split("-")[0];
      if (!semver.valid(obj.newVersion)) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_string,
          validation: "regex",
          path: ["newVersion"],
          message: t2("ValidateQuestionSetVersion"),
        });
      } else if (obj.needUpdateVersion && !semver.gt(obj.newVersion, oldVersion)) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_small,
          path: ["newVersion"],
          minimum: 5,
          type: "number",
          inclusive: true,
          message: t2("VersionErrorText", { preVersion: oldVersion }),
        });
      }
    });
};

export type FreepadOptionalFormSchema = z.infer<ReturnType<typeof useOptionalFormSchema>>;
