import { useTranslation } from "@hireroo/i18n";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Box from "@mui/material/Box";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "../../../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";
import DeleteGroupDialog, { DeleteGroupDialogProps } from "../DeleteGroupDialog/DeleteGroupDialog";
import EditGroupDialog, { EditGroupDialogProps } from "../EditGroupDialog/EditGroupDialog";

export type GroupTableRowProps = {
  groupId: string;
  groupName: string;
  avatars: { displayName: string; src: string }[];
  createdAt: string;
  checkbox: Pick<CheckboxProps, "disabled" | "title" | "onChange" | "checked">;
  editButton: Pick<IconButtonWithTooltipProps, "disabled" | "onClick">;
  editDialog: Omit<EditGroupDialogProps, "kind">;
  deleteButton: Pick<IconButtonWithTooltipProps, "disabled" | "onClick">;
  deleteDialog: DeleteGroupDialogProps;
};

const GroupTableRow: React.FC<GroupTableRowProps> = props => {
  const { t } = useTranslation();
  const checkboxProps: CheckboxProps = {
    size: "small",
    ...props.checkbox,
  };
  const editButton: IconButtonWithTooltipProps = {
    ...props.editButton,
    title: t("編集"),
    children: <EditOutlinedIcon fontSize="small" />,
  };
  const deleteButton: IconButtonWithTooltipProps = {
    ...props.deleteButton,
    title: t("削除"),
    children: <DeleteOutlineIcon fontSize="small" />,
  };
  return (
    <TableRow>
      <TableCell padding={"checkbox"}>
        <Checkbox {...checkboxProps} color="secondary" />
      </TableCell>
      <TableCell>
        <Box display={"flex"} flexDirection={"row"}>
          <Box display={"flex"} justifyContent={"center"} flexDirection={"column"}>
            <Typography fontSize="14px">{props.groupName}</Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell>
        <Box display="flex" flexDirection="row">
          {props.avatars.length > 0 ? (
            <AvatarGroup max={10}>
              {props.avatars.map((avatar, index) => (
                <Tooltip key={`${avatar.src}-${index}`} title={avatar.displayName}>
                  <Avatar src={avatar.src} sx={{ width: "32px", height: "32px" }} />
                </Tooltip>
              ))}
            </AvatarGroup>
          ) : (
            <Typography fontSize="14px">{t("メンバーがいません。")}</Typography>
          )}
        </Box>
      </TableCell>
      <TableCell>
        <Typography fontSize="14px">{props.createdAt}</Typography>
      </TableCell>
      <TableCell align="right">
        <Stack direction="row" spacing={1.5}>
          <IconButtonWithTooltip key="edit-button" {...editButton} />
          <IconButtonWithTooltip key="delete-button" {...deleteButton} />
        </Stack>
      </TableCell>
      <DeleteGroupDialog {...props.deleteDialog} />
      <EditGroupDialog key={`${props.groupId}-edit-${props.editDialog.dialog.open}`} {...props.editDialog} kind="EDIT" />
    </TableRow>
  );
};

GroupTableRow.displayName = "GroupTableRow";

export default GroupTableRow;
