import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { styled, useTheme } from "@mui/material/styles";
import Typography, { TypographyProps } from "@mui/material/Typography";
import * as React from "react";

type Score = "GOOD" | "NEEDS_IMPROVEMENT" | "POOR";

export type FrontendPerformanceItemProps = {
  score: Score;
  title: string;
  value: string;
};

const Good = styled("span")(({ theme }) => ({
  "&::before": {
    content: '""',
    backgroundColor: theme.palette.success.light,
    borderRadius: "50%",
    width: "15px",
    height: "15px",
    display: "inline-block",
    marginRight: theme.spacing(1),
    position: "relative",
    top: "2px",
  },
}));

const Triangle = styled("span")(({ theme }) => ({
  "&::before": {
    content: '""',
    borderTop: "8px solid transparent",
    borderRight: "8px solid transparent",
    borderBottom: `12px solid ${theme.palette.error.light}`,
    borderLeft: "8px solid transparent",
    width: 0,
    height: 0,
    display: "inline-block",
    marginRight: theme.spacing(1),
    position: "relative",
    top: "1px",
  },
}));

const Square = styled("span")(({ theme }) => ({
  "&::before": {
    content: '""',
    backgroundColor: theme.palette.warning.light,
    width: "15px",
    height: "15px",
    display: "inline-block",
    marginRight: theme.spacing(1),
    position: "relative",
    top: "2px",
  },
}));

const ScoreMap: Record<Score, React.ReactNode> = {
  GOOD: <Good />,
  NEEDS_IMPROVEMENT: <Triangle />,
  POOR: <Square />,
};

const FrontendPerformanceItem: React.FC<FrontendPerformanceItemProps> = props => {
  const theme = useTheme();

  const styleMap: Record<Score, TypographyProps["sx"]> = {
    GOOD: {
      color: theme.palette.success.light,
    },
    NEEDS_IMPROVEMENT: {
      color: theme.palette.error.light,
    },
    POOR: {
      color: theme.palette.warning.light,
    },
  };

  return (
    <ListItem divider>
      <ListItemText
        primary={
          <Grid container>
            <Grid item xs={10}>
              <Box display="flex" alignItems="center">
                <Typography variant="subtitle1">
                  {ScoreMap[props.score]}
                  {props.title}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Typography align="right" variant="body1" sx={styleMap[props.score]}>
                {props.value}
              </Typography>
            </Grid>
          </Grid>
        }
      />
    </ListItem>
  );
};

FrontendPerformanceItem.displayName = "FrontendPerformanceItem";

export default FrontendPerformanceItem;
