import { Company } from "@hireroo/app-store/essential/employee";
import { Pages, Widget } from "@hireroo/presentation/legacy";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type FreepadResourceEditorContainerProps = {};

const FreepadResourceEditorContainer: React.FC<FreepadResourceEditorContainerProps> = _args => {
  const companyId = Company.useActiveCompanyId();
  const props = useGenerateProps({ companyId: companyId ?? 0, type: "UPDATE" });
  const { methods } = Widget.useFreepadQuestionDetailFormContext();

  React.useEffect(() => {
    return () => {
      //reset on unmount since the question detail is the first page of Freepad resource editor
      methods.reset();
    };
  }, [methods]);

  return (
    <ErrorBoundary>
      <Pages.FreepadResourceEditor {...props} />
    </ErrorBoundary>
  );
};

FreepadResourceEditorContainer.displayName = "FreepadResourceEditorContainer";

export default withErrorBoundary(FreepadResourceEditorContainer, {});
