import { LiveCoding, useTwilioVideoRoomContext } from "@hireroo/app-helper/hooks";
import { Auth } from "@hireroo/app-store/essential/candidate";
import { RemotesId } from "@hireroo/app-store/page/c/remotes_id";
import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import * as PrivateHelper from "./privateHelper";
import { useGenerateCompleteRemoteInterviewProps } from "./useGenerateCompleteRemoteInterviewProps";
import { useGenerateProps } from "./useGenerateProps";

export type RemoteInterviewForCandidateContainerProps = {
  remoteId: string;
};

const RemoteInterviewForCandidateContainer: React.FC<RemoteInterviewForCandidateContainerProps> = props => {
  const uid = Auth.useCurrentUid();
  const session = RemotesId.useActiveSession();
  const liveCodingId = RemotesId.useLiveCodingId();
  const candidateUser = RemotesId.useCandidateUser(uid ?? "");
  const fetchRemote = PrivateHelper.useFetchRemote({
    remoteId: props.remoteId,
  });

  const { room, removeLocalVideoTrack, removeLocalAudioTrack } = useTwilioVideoRoomContext();

  const cleanUpRef = React.useRef<() => void>();
  React.useEffect(() => {
    cleanUpRef.current = () => {
      room?.disconnect();
      removeLocalAudioTrack();
      removeLocalVideoTrack();
    };
  }, [room, removeLocalAudioTrack, removeLocalVideoTrack]);

  // Clean up on unmount
  React.useEffect(() => {
    return () => {
      if (cleanUpRef.current) {
        cleanUpRef.current();
        cleanUpRef.current = undefined;
      }
    };
  }, []);

  // Reload page when page is shown with back-forward cache
  // For use video call in Safari
  React.useEffect(() => {
    const handlePageShowWithBfCache = (event: PageTransitionEvent) => {
      if (!event.persisted) {
        return;
      }
      window.location.reload();
    };

    window.addEventListener("pageshow", handlePageShowWithBfCache);
    return () => {
      window.removeEventListener("pageshow", handlePageShowWithBfCache);
    };
  }, []);

  const handleChangeSyncState = React.useCallback<LiveCoding.SyncStateCallback>(
    syncState => {
      switch (syncState.s) {
        case "dels": {
          RemotesId.removeSession(syncState.v);
          break;
        }
        case "adds": {
          fetchRemote.start();
          break;
        }
      }
    },
    [fetchRemote],
  );
  const liveCodingRealtimeDatabaseArgs = React.useMemo((): LiveCoding.LiveCodingRealtimeDatabaseArgs => {
    return {
      enableBrowserEventDetector: true,
      onChangeSyncState: handleChangeSyncState,
      liveCodingId: liveCodingId,
      sessionId: session?.liveCodingSessionId,
      isInterviewing: true,
      uid: candidateUser.userId,
      /**
       * User Name or participant Name
       */
      participantName: candidateUser.userName,
      isCandidate: true,
      session: session
        ? {
            id: session.liveCodingSessionId,
            liveCodingId: liveCodingId,
            liveCodingQuestionType: session.questionType,
            freepadQuestion: session.freepadQuestion
              ? {
                  id: session.freepadQuestion.freepadQuestionId,
                  version: session.freepadQuestion.version,
                  questionVariant: session.freepadQuestion.variant,
                  initialFlowCharts: session.freepadQuestion.variant === "SYSTEM_DESIGN" ? session.freepadQuestion.initialFlowCharts : null,
                }
              : null,
            algorithmQuestion: session.algorithmQuestion
              ? {
                  id: session.algorithmQuestion.questionId,
                  version: session.algorithmQuestion.version,
                  questionVariant: session.algorithmQuestion.variant,
                }
              : null,
            systemDesignQuestion: session.systemDesignQuestion
              ? {
                  id: session.systemDesignQuestion.questionId,
                  initialFlowChartSnapshot: session.systemDesignQuestion.initialSnapshot,
                }
              : null,
          }
        : null,
    };
  }, [candidateUser.userId, candidateUser.userName, handleChangeSyncState, liveCodingId, session]);
  const { collaborativeState, collaborativeAction } = LiveCoding.useLiveCodingRealtimeDatabase(liveCodingRealtimeDatabaseArgs);
  const remoteInterviewForCandidateProps = useGenerateProps({
    remoteId: props.remoteId,
    collaborativeAction: collaborativeAction,
    collaborativeState: collaborativeState,
  });
  PrivateHelper.useConnectRealtimeHooksToRemoteInterviewParticipantsStore({
    collaborativeState,
  });
  PrivateHelper.useConnectRealtimeHooksToRemotesIdStore({
    collaborativeState,
  });
  const completeRemoteInterviewProps = useGenerateCompleteRemoteInterviewProps({});

  if (collaborativeState.isLiveCodingFinished) {
    cleanUpRef.current?.();
  }

  if (collaborativeState.isLiveCodingFinished) {
    return (
      <ErrorBoundary>
        <Pages.CompleteRemoteInterview {...completeRemoteInterviewProps} />
      </ErrorBoundary>
    );
  }
  return (
    <ErrorBoundary>
      <Pages.RemoteInterviewForCandidate {...remoteInterviewForCandidateProps} />
    </ErrorBoundary>
  );
};

RemoteInterviewForCandidateContainer.displayName = "RemoteInterviewForCandidateContainer";

export default withErrorBoundary(RemoteInterviewForCandidateContainer, {});
