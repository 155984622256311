import { AssessmentSearchForm as AssessmentSearchFormValidator } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import SearchForm, { SearchFormProps } from "../../../modules/SearchForm/SearchForm";
import * as PrivateHelper from "./privateHelper";

export type AssessmentSearchFormProps = {
  disabled: boolean;
  talentField: Pick<PrivateHelper.MultiChoiceFieldProps, "options">;
  viewerField: Pick<PrivateHelper.MultiChoiceV2FieldProps, "options">;
  defaultValues: AssessmentSearchFormValidator.AssessmentSearchQuerySchema;
  onChange: SubmitHandler<AssessmentSearchFormValidator.AssessmentSearchQuerySchema>;
};

const AssessmentSearchForm: React.FC<AssessmentSearchFormProps> = props => {
  const { onChange } = props;
  const validateSchema = AssessmentSearchFormValidator.useAssessmentSearchQuery();
  const methods = useForm<AssessmentSearchFormValidator.AssessmentSearchQuerySchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: {
      ...props.defaultValues,
    },
  });

  const textFilterField = PrivateHelper.useTextFilterField();
  const scoreTrendField = PrivateHelper.useScoreTrendField();
  const statusField = PrivateHelper.useStatusField();
  const submitStatusField = PrivateHelper.useSubmitStatusField();
  const talentField = PrivateHelper.useTalentField(props.talentField);
  const viewerField = PrivateHelper.useViewerField(props.viewerField);

  React.useEffect(() => {
    const subscriber = methods.watch(() => {
      methods.handleSubmit(onChange, console.warn)();
    });
    return () => {
      subscriber.unsubscribe();
    };
  }, [methods, onChange]);

  const searchFormProps: SearchFormProps = {
    kind: "MULTI_LINE",
    disabled: props.disabled,
    textFilter: textFilterField,
    fields: [scoreTrendField, statusField, submitStatusField, talentField, viewerField],
    onReset: () => {
      const resetValues: AssessmentSearchFormValidator.AssessmentSearchQuerySchema = {
        textFilter: "",
        assessmentStatuses: [],
        viewers: [],
        scoreTrend: null,
        talent: [],
        submitStatus: null,
      };
      methods.reset(resetValues);
    },
  };
  return (
    <FormProvider {...methods}>
      <SearchForm {...searchFormProps} />
    </FormProvider>
  );
};

AssessmentSearchForm.displayName = "AssessmentSearchForm";

export default AssessmentSearchForm;
