import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import escapeStringRegexp from "escape-string-regexp";
import React, { useCallback, useRef, useState } from "react";

import {
  CategoryLabelsMap,
  CategoryMasterData,
  ELEMENT_CATEGORIES,
  ELEMENT_CATEGORY,
  ELEMENT_KEY,
  ELEMENT_LABELS,
  ElementDataKeyMap,
  ElementLabel,
  ElementMasterData,
  LabelCategoryMap,
} from "../../../features";
import { useSystemDesignContext } from "../../../store";
import ComponentTypeSelector, { ComponentTypeSelectorProps } from "../ComponentTypeSelector/ComponentTypeSelector";
import DraggableObjectItem from "./parts/DraggableObjectItem/DraggableObjectItem";

export type FlowChartSideBarProps = {
  onClickElement: (label: ElementLabel | "text") => void;
  onDragElementStart: (e: React.DragEvent<HTMLDivElement>, label: ElementLabel | "text") => void;
  helpCenterUrl: string;
  componentTypeSelector: ComponentTypeSelectorProps;
};

const Wrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette["Secondary/Shades"].p12,
  height: "100%",
  position: "relative",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& > .MuiOutlinedInput-root": {
    borderRadius: theme.spacing(1),
    borderColor: theme.palette.Other.Divider,
    margin: `${theme.spacing(1.5)}`,
  },
  "& .MuiOutlinedInput-input": {
    fontSize: theme.typography.body2.fontSize,
    padding: `${theme.spacing(1.5)} 0px`,
  },
  width: "100%",
}));

const StyledLabelTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: "bold",
}));

const ElementListArea = styled(Box)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const FlowChartSideBar: React.FC<FlowChartSideBarProps> = React.memo((props: FlowChartSideBarProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const lang = useLanguageCode();
  const [searchText, setSearchText] = useState<string>("");
  const objectListRef = useRef<HTMLDivElement>(null);
  const [canTooltipOpen, setCanTooltipOpen] = useState<boolean>(true);
  const Store = useSystemDesignContext();
  const componentType = Store.hooks.useComponentType();

  const getImage = useCallback(
    (label: ElementLabel | "text"): string => {
      return label === "text"
        ? ElementMasterData[ELEMENT_KEY.defaultText].iconImage
        : ElementMasterData[ElementDataKeyMap[componentType][label]].iconImage;
    },
    [componentType],
  );

  const getTitle = useCallback(
    (label: ElementLabel | "text"): string => {
      return label === "text"
        ? resolveLanguage(ElementMasterData[ELEMENT_KEY.defaultText], lang, "title")
        : resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "title");
    },
    [lang, componentType],
  );

  const getDescription = useCallback(
    (label: ElementLabel | "text"): string => {
      return label === "text"
        ? resolveLanguage(ElementMasterData[ELEMENT_KEY.defaultText], lang, "description")
        : resolveLanguage(ElementMasterData[ElementDataKeyMap[componentType][label]], lang, "description");
    },
    [lang, componentType],
  );

  const matchedLabels = (searchText: string): ElementLabel[] =>
    ELEMENT_LABELS.filter(label => {
      const reg = new RegExp(escapeStringRegexp(searchText), "i");
      return (
        reg.test(ElementMasterData[ElementDataKeyMap[componentType][label]].titleJa) ||
        reg.test(ElementMasterData[ElementDataKeyMap[componentType][label]].titleEn) ||
        reg.test(CategoryMasterData[LabelCategoryMap[label]].titleJa) ||
        reg.test(CategoryMasterData[LabelCategoryMap[label]].titleEn)
      );
    });

  const handleChangeText = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchText(e.target.value);
    objectListRef?.current?.scrollTo({ top: 0 });
  };

  const handleScroll = () => {
    setCanTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setCanTooltipOpen(true);
  };

  return (
    <Wrapper>
      <Box borderBottom="1px solid" borderColor={theme.palette.Other.Divider}>
        <Typography variant="subtitle2" px={1.5} py={1.25} fontWeight="bold">
          {t("コンポーネント")}
        </Typography>
      </Box>
      <Box sx={{ py: 1, px: 1, display: "flex", justifyContent: "space-between", alignContent: "center", alignItems: "center" }}>
        <Box>
          <ComponentTypeSelector {...props.componentTypeSelector} />
        </Box>
        <Box>
          <Link underline={"hover"} href={props.helpCenterUrl} color="info.main" target={"_blank"} sx={{ cursor: "pointer" }}>
            <Box display={"flex"} alignItems={"center"}>
              <HelpOutlineIcon fontSize="small" color="info" sx={{ width: 16, height: 16 }} />
              <Typography ml={0.5} pb={0.2} color="info.main" variant="caption">
                {t("ヘルプ")}
              </Typography>
            </Box>
          </Link>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center">
        <StyledTextField
          placeholder={t("コンポーネントを検索")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: theme.palette["Gray/Shades"].p56, fontSize: "32px" }} />
              </InputAdornment>
            ),
          }}
          color="secondary"
          onChange={handleChangeText}
          variant="outlined"
        />
      </Box>
      <ElementListArea ref={objectListRef} onScroll={handleScroll} sx={{ paddingX: 1 }}>
        {searchText && (
          <Box pt={2}>
            <StyledLabelTypography>{t("検索結果")}</StyledLabelTypography>
            <Grid container spacing={2} mt={1} px={0.5}>
              {matchedLabels(searchText).map(label => (
                <DraggableObjectItem
                  key={`result-${label}`}
                  label={label}
                  displayLabel={getTitle(label)}
                  image={getImage(label)}
                  onClickElement={props.onClickElement}
                  onDragElementStart={props.onDragElementStart}
                  onTooltipOpen={handleTooltipOpen}
                  canTooltipOpen={canTooltipOpen}
                  tooltipText={getDescription(label)}
                />
              ))}
            </Grid>
            <Divider sx={{ mt: 1 }} />
          </Box>
        )}

        <Box display="flex" flexDirection="column">
          {ELEMENT_CATEGORIES.map(category => (
            <Box pt={2} key={category}>
              <StyledLabelTypography>{resolveLanguage(CategoryMasterData[category], lang, "title")}</StyledLabelTypography>
              {category === ELEMENT_CATEGORY.general ? (
                <Grid container spacing={2} mt={1} px={0.5}>
                  <DraggableObjectItem
                    label={"text"}
                    displayLabel={getTitle("text")}
                    image={getImage("text")}
                    onClickElement={props.onClickElement}
                    onDragElementStart={props.onDragElementStart}
                    onTooltipOpen={handleTooltipOpen}
                    canTooltipOpen={canTooltipOpen}
                    tooltipText={getDescription("text")}
                  />
                  {CategoryLabelsMap[category].map(label => (
                    <DraggableObjectItem
                      key={label}
                      label={label}
                      displayLabel={getTitle(label)}
                      image={getImage(label)}
                      onClickElement={props.onClickElement}
                      onDragElementStart={props.onDragElementStart}
                      onTooltipOpen={handleTooltipOpen}
                      canTooltipOpen={canTooltipOpen}
                      tooltipText={getDescription(label)}
                    />
                  ))}
                </Grid>
              ) : (
                <Grid container spacing={2} mt={1} px={0.5}>
                  {CategoryLabelsMap[category].map(label => (
                    <DraggableObjectItem
                      key={label}
                      label={label}
                      displayLabel={getTitle(label)}
                      image={getImage(label)}
                      onClickElement={props.onClickElement}
                      onDragElementStart={props.onDragElementStart}
                      onTooltipOpen={handleTooltipOpen}
                      canTooltipOpen={canTooltipOpen}
                      tooltipText={getDescription(label)}
                    />
                  ))}
                </Grid>
              )}
              <Divider sx={{ mt: 2 }} />
            </Box>
          ))}
        </Box>
      </ElementListArea>
    </Wrapper>
  );
});

FlowChartSideBar.displayName = "FlowChartSideBar";

export default FlowChartSideBar;
