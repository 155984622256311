import * as React from "react";
import { RectangleProps } from "recharts";

import { BoxChartColorTheme, BoxChartColorThemeMap } from "../../../../definition";

type HorizontalBarProps = RectangleProps & {
  theme?: BoxChartColorTheme;
};

const HorizontalBar: React.FC<HorizontalBarProps> = props => {
  const { x, y, width, height, theme, strokeWidth = 3 } = props;
  const colorMap = theme ? BoxChartColorThemeMap[theme] : BoxChartColorThemeMap.lightBlue;

  if (x === undefined || y === undefined || width === undefined || height === undefined) {
    return null;
  }

  return <line x1={x} y1={y} x2={x + width} y2={y} stroke={colorMap.main} strokeWidth={strokeWidth} />;
};

HorizontalBar.displayName = "HorizontalBar";

export default HorizontalBar;
