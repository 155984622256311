import { getRef } from "@hireroo/firebase";
import * as React from "react";

import { fetchRevisions, fetchStates, SyncOperation, SyncState } from "../firepad";

export type QuizRevisions = {
  revisions: SyncState[] | SyncOperation[];
  ready: boolean;
};

export type QuizRevisionsArgs = {
  quizId: number;
  packageId: number;
  questionIds: number[];
};

export const useQuizRevisions = (args: QuizRevisionsArgs): QuizRevisions => {
  const { quizId, packageId, questionIds } = args;

  const [packageStates, setPackageStates] = React.useState<SyncState[]>([]);
  const [packageReady, setPackageReady] = React.useState<boolean>(false);
  const [questionStates, setQuestionStates] = React.useState<SyncState[]>([]);
  const [questionHistories, setQuestionHistories] = React.useState<SyncOperation[]>([]);
  const [questionReady, setQuestionReady] = React.useState<boolean>(false);

  const [mergeReady, setMergeReady] = React.useState<boolean>(false);

  React.useEffect(() => {
    const packageRef = getRef("quiz", `quizzes/${quizId}/packages/${packageId}`);
    fetchStates(packageRef, action => {
      setPackageStates(action);
      setPackageReady(true);
    });

    for (let i = 0; i < questionIds.length; i++) {
      const questionRef = getRef("quiz", `quizzes/${quizId}/packages/${packageId}/questions/${questionIds[i]}`);
      fetchStates(questionRef, action => {
        setQuestionStates(prev => {
          const newState = prev.concat(action);
          return newState;
        });
      });

      // get history revision and set
      fetchRevisions(questionRef, fetchedHistories => {
        setQuestionHistories(prev => {
          const newHistory = prev.concat(fetchedHistories as SyncOperation[]);
          return newHistory;
        });
      });
    }
    setQuestionReady(true);
  }, [packageId, questionIds, questionIds.length, quizId]);

  const merged = React.useMemo(() => {
    const mergedState = questionStates.concat(packageStates) as SyncState[];
    const mergedStateWithHistories = [...mergedState, ...questionHistories] as SyncState[] | SyncOperation[];

    if (questionReady && packageReady) {
      mergedStateWithHistories.sort((a, b) => {
        return a.t - b.t;
      });
      setMergeReady(true);
    }
    return mergedStateWithHistories;
  }, [questionStates, packageStates, questionHistories, questionReady, packageReady]);

  return {
    revisions: merged,
    ready: mergeReady,
  };
};

/**
 * useQuizRevisions for only V2
 */
export const useQuizRevisionsV2 = (args: QuizRevisionsArgs): QuizRevisions => {
  const { quizId, packageId, questionIds } = args;

  const [packageStates, setPackageStates] = React.useState<SyncState[]>([]);
  const [packageReady, setPackageReady] = React.useState<boolean>(false);
  const [questionStates, setQuestionStates] = React.useState<SyncState[]>([]);
  const [questionHistories, setQuestionHistories] = React.useState<SyncOperation[]>([]);
  const [questionReady, setQuestionReady] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (packageReady) return;
    const packageRef = getRef("quiz", `quizzes/${quizId}/packages/${packageId}`);
    fetchStates(packageRef, action => {
      setPackageStates(action);
      setPackageReady(true);
    });
  }, [packageId, packageReady, quizId]);

  React.useEffect(() => {
    if (questionReady) return;

    for (let i = 0; i < questionIds.length; i++) {
      const questionRef = getRef("quiz", `quizzes/${quizId}/packages/${packageId}/questions/${questionIds[i]}`);
      fetchStates(questionRef, action => {
        setQuestionStates(prev => {
          const newState = prev.concat(action);
          return newState;
        });
      });

      // get history revision and set
      fetchRevisions(questionRef, fetchedHistories => {
        setQuestionHistories(prev => {
          const newHistory = prev.concat(fetchedHistories as SyncOperation[]);
          return newHistory;
        });
      });
    }
    setQuestionReady(true);
  }, [packageId, questionIds, questionIds.length, questionReady, quizId]);

  const merged = React.useMemo(() => {
    const mergedStateWithHistories = [...questionStates, ...packageStates, ...questionHistories] as SyncState[] | SyncOperation[];

    if (questionReady && packageReady) {
      mergedStateWithHistories.sort((a, b) => {
        return a.t - b.t;
      });
    }
    return mergedStateWithHistories;
  }, [questionStates, packageStates, questionHistories, questionReady, packageReady]);

  return {
    revisions: merged,
    ready: questionReady && packageReady,
  };
};
