import { useTranslationWithVariable } from "@hireroo/i18n";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledTypography = styled(Typography)(({ theme }) => ({
  lineHeight: "1.75em",
  color: theme.palette.text.secondary,
  letterSpacing: "0.15px",
}));

export type ReportScoreAnswerRateDisplayLabelProps = {
  mb?: number;
  numPassed: number;
  numTests: number;
};

const ReportScoreAnswerRateDisplayLabel: React.FC<ReportScoreAnswerRateDisplayLabelProps> = props => {
  const { t: t2 } = useTranslationWithVariable();
  return (
    <Stack direction="row" display="flex" alignItems="flex-end" mb={props.mb}>
      <StyledTypography variant="subtitle1" fontWeight="bold">
        ({props.numPassed}
      </StyledTypography>
      <StyledTypography fontSize={10} sx={{ lineHeight: "190%" }}>
        {/*TODO: use context when it is ready*/}
        {t2("testcasePassing")}
      </StyledTypography>
      <StyledTypography variant="subtitle1" fontWeight="bold">
        / {props.numTests}
      </StyledTypography>
      <StyledTypography fontSize={10} sx={{ lineHeight: "190%" }}>
        {/*TODO: use context when it is ready*/}
        {t2("quantityNumber")}
      </StyledTypography>
      <StyledTypography variant="subtitle1" fontWeight="bold">
        )
      </StyledTypography>
    </Stack>
  );
};

ReportScoreAnswerRateDisplayLabel.displayName = "ReportScoreAnswerRateDisplayLabel";

export default ReportScoreAnswerRateDisplayLabel;
