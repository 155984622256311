import { Company } from "@hireroo/app-store/essential/employee";
import { ViewerAssignField } from "@hireroo/app-store/widget/e/ViewerAssignField";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

type SuggestionItem = Widget.AssignFieldProps["suggestions"][0];

type DisplaySources = Widget.AssignFieldProps["displaySources"];

export type GenerateAssignFieldPropsArgs = {
  name: string;
  variant: Widget.AssignFieldProps["variant"];
};

export const useGenerateProps = (args: GenerateAssignFieldPropsArgs): Widget.AssignFieldProps => {
  const { t } = useTranslation();
  const companyId = Company.useStrictActiveCompanyId();
  const [result, refresh] = Graphql.useGetViewersForViewerAssignFieldQuery({
    variables: {
      companyId: companyId,
    },
    requestPolicy: "cache-and-network",
  });
  const employees = ViewerAssignField.useEmployees();
  const employeeGroups = ViewerAssignField.useEmployeeGroups();

  React.useEffect(() => {
    if (result.data) {
      ViewerAssignField.addEmployees(result.data.company.employees);
      ViewerAssignField.addEmployeeGroups(result.data.company.groups);
    }
  }, [result.data]);

  const suggestionsProps = React.useMemo((): SuggestionItem[] => {
    const suggestions: SuggestionItem[] = [];
    employees.forEach(employee => {
      suggestions.push({
        type: "EMPLOYEE",
        valueId: employee.uid,
        locked: false,
      });
    });
    employeeGroups.forEach(employeeGroup => {
      suggestions.push({
        type: "EMPLOYEE_GROUP",
        valueId: employeeGroup.employeeGroupId,
        locked: false,
      });
    });
    return suggestions;
  }, [employeeGroups, employees]);

  const displayNamesProps = React.useMemo((): DisplaySources => {
    const displayNames: DisplaySources = {};
    employees.forEach(employee => {
      displayNames[employee.uid] = {
        optionText: `${employee.displayName || "No Name"} (${employee.email})`,
        chipText: employee.displayName || "No Name",
        photoUrl: employee.photoUrl,
      };
    });
    employeeGroups.forEach(employeeGroup => {
      displayNames[employeeGroup.employeeGroupId] = {
        optionText: employeeGroup.groupName,
        chipText: employeeGroup.groupName,
      };
    });
    return displayNames;
  }, [employeeGroups, employees]);

  return {
    multiple: true,
    name: args.name,
    variant: args.variant,
    placeholder: t("名前・メールアドレス・グループ名で検索"),
    suggestions: suggestionsProps,
    displaySources: displayNamesProps,
    loading: result.fetching,
    onFocus: () => {
      refresh();
    },
  };
};
