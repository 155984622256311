import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import * as React from "react";

import AddEvaluationButton, { AddEvaluationButtonProps } from "./parts/AddEvaluationButton/AddEvaluationButton";
import EvaluationList, { EvaluationListProps } from "./parts/EvaluationList/EvaluationList";
import MetricSearchMenu, { MetricSearchMenuProps } from "./parts/MetricSearchMenu/MetricSearchMenu";

type Kind = "DURING_INTERVIEW" | "AFTER_INTERVIEW";

export type TechnicalCommentEvaluationsFormProps = {
  kind: Kind;
  metricSearchMenu: Omit<MetricSearchMenuProps, "open" | "actionButton">;
  evaluationsList: Omit<EvaluationListProps, "kind">;
};

const TechnicalCommentEvaluationForm: React.FC<TechnicalCommentEvaluationsFormProps> = props => {
  const [open, setOpen] = React.useState<boolean>(false);
  const addEvaluationButtonProps: AddEvaluationButtonProps = {
    onClick: () => {
      setOpen(true);
    },
  };
  const metricSearchMenuProps: MetricSearchMenuProps = {
    ...props.metricSearchMenu,
    open,
    onClose: () => {
      setOpen(false);
    },
    actionButton: {
      onClick: () => {
        setOpen(false);
      },
    },
  };

  return (
    <Box>
      <Stack spacing={3} px={props.kind === "DURING_INTERVIEW" ? 2 : undefined}>
        {props.kind === "DURING_INTERVIEW" && <AddEvaluationButton {...addEvaluationButtonProps} />}
        <EvaluationList {...props.evaluationsList} kind={props.kind} />
        {props.kind === "AFTER_INTERVIEW" && <AddEvaluationButton {...addEvaluationButtonProps} />}
      </Stack>
      <MetricSearchMenu {...metricSearchMenuProps} />
    </Box>
  );
};

TechnicalCommentEvaluationForm.displayName = "TechnicalCommentEvaluationForm";

export default TechnicalCommentEvaluationForm;
