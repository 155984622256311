import { generateKey } from "@hireroo/app-helper/parser";
import { proxyMap } from "valtio/utils";

import { state } from "./State";
import type * as Types from "./types";

export const initialize = (entity: Types.ChallengeEntity) => {
  const bestSubmission = entity.submissions.find(submission => submission.isBest);
  state.challenges.set(entity.challengeEntityId, {
    entity,
    queryKey: "",
    currentSelectedSubmissionId: bestSubmission?.challengeSubmissionId ?? 0,
    statisticsMap: proxyMap(),
    feedbackSuspiciousDegreeDialogStatus: "CLOSE",
  });
};

export const setQuery = (entityId: Types.ChallengeEntityId, query: Types.Query) => {
  const challengeState = state.challenges.get(entityId);
  if (!challengeState) {
    return;
  }
  challengeState.queryKey = generateKey(query);
};

export const setStatistics = (entityId: Types.ChallengeEntityId, query: Types.Query, result: Types.Statistics | null) => {
  const challengeState = state.challenges.get(entityId);
  if (!result || !challengeState) {
    return;
  }
  challengeState.statisticsMap.set(generateKey(query), result);
  setQuery(entityId, query);
};

export const setCurrentSelectedSubmissionId = (
  entityId: Types.ChallengeEntityId,
  currentSelectedSubmissionId: Types.Submission["challengeSubmissionId"],
) => {
  const entityState = state.challenges.get(entityId);
  if (entityState) {
    entityState.currentSelectedSubmissionId = currentSelectedSubmissionId;
  }
};

export const setFeedbackSuspiciousDegreeDialogStatus = (
  entityId: Types.ChallengeEntityId,
  status: Types.FeedbackSuspiciousDegreeDialogStatus,
) => {
  const entityState = state.challenges.get(entityId);
  if (entityState) {
    entityState.feedbackSuspiciousDegreeDialogStatus = status;
  }
};

export const setSuspiciousInferenceFeedback = (entityId: Types.ChallengeEntityId, isCorrect: boolean) => {
  const entityState = state.challenges.get(entityId);
  if (entityState?.entity.suspiciousInference) {
    entityState.entity.suspiciousInference = {
      ...entityState.entity.suspiciousInference,
      feedback: {
        isCorrect,
      },
    };
  }
};

export const updateSubmission = (challengeId: number, newSubmission: Types.Submission) => {
  const challenge = state.challenges.get(challengeId);
  if (challenge) {
    challenge.entity.submissions = challenge.entity.submissions.map(submission => {
      if (submission.challengeSubmissionId === newSubmission.challengeSubmissionId) {
        return newSubmission;
      }
      return submission;
    });
  }
};
