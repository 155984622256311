import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import * as React from "react";

export type AppBarProps = MuiAppBarProps & {
  open?: boolean;
};

const drawerWidth = 248;

const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  backgroundColor: theme.palette["Background/Paper"].p2,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  borderLeft: "none",
  borderRight: "none",
}));

const AppBar: React.FC<AppBarProps> = props => {
  return <StyledAppBar {...props} variant="outlined" elevation={0} />;
};

AppBar.displayName = "AppBar";

export default AppBar;
