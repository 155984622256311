import { SupportLanguageValue, useLanguageCode } from "@hireroo/i18n";
import Markdown from "@hireroo/markdown-v2/react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";
import { ACTIONS, type Props as ReactJoyrideProps, STATUS, Step as ReactJoyrideStep, TooltipRenderProps } from "react-joyride";
import ReactJoyride from "react-joyride";

export { ACTIONS, STATUS };

export type Step = Omit<ReactJoyrideStep, "locale">;

const StyledButton = styled(Button)`
  white-space: nowrap;
  word-break: keep-all;
`;

export type OnboardingGuideProps = Pick<ReactJoyrideProps, "run" | "disableScrolling" | "callback"> & {
  steps: Step[];
};

const localMap: Record<SupportLanguageValue, ReactJoyrideProps["locale"]> = {
  ja: {
    next: "次へ",
    back: "戻る",
    last: "終了",
    skip: "スキップ",
  },
  en: undefined,
};

const Wrapper = styled(Box)`
  position: absolute;
`;

const ToolTip = (props: TooltipRenderProps) => {
  return (
    <Paper elevation={24} {...props.tooltipProps} sx={{ width: props.step.styles?.options?.width ?? 400 }}>
      <DialogTitle sx={{ textAlign: "center" }}>{props.step.title}</DialogTitle>
      <DialogContent sx={{ textAlign: "left", p: 2 }}>
        {typeof props.step.content === "string" ? <Markdown size="middle" children={props.step.content} /> : props.step.content}
      </DialogContent>
      <DialogActions>
        {!props.isLastStep && (
          <Box display="flex" justifyContent="flex-start" width="100%">
            <StyledButton {...props.skipProps} children={props.skipProps.title} color="inherit" />{" "}
          </Box>
        )}
        <Stack direction="row" spacing={1} justifyContent="flex-end" width="100%">
          {props.index > 0 && <StyledButton color="secondary" {...props.backProps} children={props.backProps.title} />}
          <StyledButton
            {...props.primaryProps}
            color="secondary"
            variant="contained"
            children={`${props.primaryProps.title}(${props.index + 1}/${props.size})`}
          />
        </Stack>
      </DialogActions>
    </Paper>
  );
};

const OnboardingGuide: React.FC<OnboardingGuideProps> = props => {
  const theme = useTheme();
  const lang = useLanguageCode();
  const reactJoyrideProps: ReactJoyrideProps = {
    ...props,
    continuous: true,
    showProgress: true,
    showSkipButton: true,
    hideCloseButton: true,
    disableScrollParentFix: true,
    styles: {
      options: {
        arrowColor: theme.palette.background.paper,
        zIndex: 10000,
      },
    },
    locale: localMap[lang],
    tooltipComponent: ToolTip,
  };

  return (
    <Wrapper>
      <React.Suspense>
        <ReactJoyride {...reactJoyrideProps} />
      </React.Suspense>
    </Wrapper>
  );
};

OnboardingGuide.displayName = "OnboardingGuide";

export default OnboardingGuide;
