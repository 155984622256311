import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import * as React from "react";

import TestReportEvaluationItem, { TestReportEvaluationItemProps } from "../../../modules/TestReportEvaluationItem/TestReportEvaluationItem";
import TestReportProgrammingLanguageItem from "../TestReportProgrammingLanguageItem/TestReportProgrammingLanguageItem";

type Item = Pick<TestReportEvaluationItemProps, "value" | "icon">;

export type ReportChallengeAnswerDetailSectionProps = {
  runtime: string;
  runtimeVersion: string;
  totalElapsedTime: Item;
  executeTime: Item;
  usedHint?: Item;
  complexTime: Item;
  performance: Item;
  memory: Item;

  canShowTooltip: boolean;
};

const ReportChallengeAnswerDetailSection: React.FC<ReportChallengeAnswerDetailSectionProps> = props => {
  const { t } = useTranslation();

  const leftSections = React.useMemo(() => {
    return [
      {
        ...props.totalElapsedTime,
        title: t("経過時間"),
        canShowTooltip: props.canShowTooltip,
      },
      {
        ...props.executeTime,
        title: t("実行回数"),
        canShowTooltip: props.canShowTooltip,
      },
      props.usedHint && {
        ...props.usedHint,
        title: t("使用したヒント"),
        canShowTooltip: props.canShowTooltip,
      },
    ].filter(value => !!value);
  }, [props.totalElapsedTime, props.canShowTooltip, props.executeTime, props.usedHint, t]);

  const rightSections: TestReportEvaluationItemProps[] = [
    {
      ...props.complexTime,
      title: t("予測計算量"),
      canShowTooltip: props.canShowTooltip,
    },
    {
      ...props.performance,
      title: t("パフォーマンス"),
      canShowTooltip: props.canShowTooltip,
    },
    {
      ...props.memory,
      title: t("メモリ"),
      canShowTooltip: props.canShowTooltip,
    },
  ].flatMap(v => (v ? [v] : []));
  return (
    <Box>
      <Typography variant="subtitle1" sx={theme => ({ fontWeight: "bold", color: theme.palette.text.secondary })} mb={3}>
        {t("解答詳細")}
      </Typography>
      <Grid container columnSpacing={1}>
        <Grid item xs={6}>
          <List component="nav" aria-label="answer-detail-1">
            <TestReportProgrammingLanguageItem runtime={props.runtime} runtimeVersion={props.runtimeVersion} />
            {leftSections.map(section => (
              <TestReportEvaluationItem key={section.title} {...section} />
            ))}
          </List>
        </Grid>
        <Grid item xs={6}>
          <List component="nav" aria-label="answer-detail-1">
            {rightSections.map(section => (
              <TestReportEvaluationItem key={section.title} {...section} />
            ))}
          </List>
        </Grid>
      </Grid>
    </Box>
  );
};

ReportChallengeAnswerDetailSection.displayName = "ReportChallengeAnswerDetailSection";

export default ReportChallengeAnswerDetailSection;
