import { useQuestionDifficultyMap } from "@hireroo/app-definition/question";
import { SearchQuizQuestion } from "@hireroo/app-store/widget/e/SearchQuizQuestion";
import { unixToDatetimeFormat } from "@hireroo/formatter/time";
import * as Graphql from "@hireroo/graphql/client/graphql-request";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation/legacy";

export type GenerateSearchQuizQuestionPropsArgs = {};

type QuizQuestionCardProps = Widget.SearchQuizQuestionProps["questionList"]["questionCards"][9];

type DifficultyTag = QuizQuestionCardProps["difficultyTag"];

const difficultyColorMap: Record<Graphql.Difficulty, Exclude<DifficultyTag["color"], undefined> | undefined> = {
  [Graphql.Difficulty.Difficult]: "red",
  [Graphql.Difficulty.Easy]: "green",
  [Graphql.Difficulty.Medium]: "yellow",
  [Graphql.Difficulty.Unknown]: undefined,
};

export const useGenerateProps = (_args: GenerateSearchQuizQuestionPropsArgs): Widget.SearchQuizQuestionProps => {
  const { t } = useTranslation();
  const questions = SearchQuizQuestion.useQuestions();
  const languageCode = useLanguageCode();
  const questionDifficultyMap = useQuestionDifficultyMap();
  const selectedQuestion = SearchQuizQuestion.useSelectedQuestionMap();
  const fetchSize = SearchQuizQuestion.useFetchSize();
  const page = SearchQuizQuestion.usePage();
  const maxRecordCount = SearchQuizQuestion.useMaxRecordCount();
  return {
    questionList: {
      questionCards: questions.map<QuizQuestionCardProps>(question => {
        return {
          id: `${question.id}-${question.version}`,
          title: resolveLanguage(question, languageCode, "title"),
          lastUpdatedAt: [t("最終更新日"), unixToDatetimeFormat(question.updatedAtSeconds ?? 0)].join(": "),
          selected: selectedQuestion.has(`${question.id}-${question.version}`),
          selectButton: {
            onClick: () => {
              SearchQuizQuestion.toggleSelectQuestion({
                ...question,
                tags: [...question.tags],
                versions: [...question.versions],
                options: [...question.options],
                evaluationItems: [...question.evaluationItems],
              });
            },
          },
          privateTag: {
            label: question.isPrivate ? t("非公開") : t("公開"),
            color: question.isPrivate ? "grey-500" : "primary",
          },
          difficultyTag: {
            label: questionDifficultyMap[question.difficulty],
            color: difficultyColorMap[question.difficulty],
          },
          extraTags: question.tags.map(tag => {
            return {
              label: tag.name,
            };
          }),
          content: ((): QuizQuestionCardProps["content"] => {
            switch (question.variant) {
              case "SINGLE_CHOICE": {
                const correctOptionValue = question.options.find(option => option.isCorrect);
                return {
                  kind: "SINGLE_CHOICE",
                  quizContent: {
                    id: `${question.id}-${question.version}`,
                    selected: correctOptionValue?.id.toString() || "",
                    description: resolveLanguage(question, languageCode, "description"),
                    items: question.options.map(option => {
                      return {
                        title: resolveLanguage(option, languageCode, "title", { ja: "", en: "" }),
                        value: option.id.toString(),
                        description: resolveLanguage(option, languageCode, "additionalDetail", { ja: "", en: "" }),
                      };
                    }),
                  },
                };
              }
              case "MULTI_CHOICE": {
                return {
                  kind: "MULTI_CHOICE",
                  quizContent: {
                    id: `${question.id}-${question.version}`,
                    description: resolveLanguage(question, languageCode, "description"),
                    skipped: false,
                    items: question.options.map(option => {
                      return {
                        title: resolveLanguage(option, languageCode, "title", { ja: "", en: "" }),
                        checked: option.isCorrect,
                        description: resolveLanguage(option, languageCode, "additionalDetail", { ja: "", en: "" }),
                      };
                    }),
                  },
                };
              }
              case "FREE_TEXT": {
                return {
                  kind: "FREE_TEXT",
                  quizContent: {
                    id: `${question.id}-${question.version}`,
                    description: resolveLanguage(question, languageCode, "description"),
                    items: question.evaluationItems.map(evaluation => {
                      return {
                        title: resolveLanguage(evaluation, languageCode, "title", { ja: "", en: "" }),
                      };
                    }),
                  },
                };
              }
              default:
                throw new Error(`Unknown type`);
            }
          })(),
        };
      }),
      tablePagination: {
        rowsPerPage: fetchSize,
        onPageChange: (_, page) => {
          SearchQuizQuestion.updatePage(page);
        },
        rowsPerPageOptions: [fetchSize],
        count: maxRecordCount,
        page: page,
      },
    },
  };
};
