import { useTranslation } from "@hireroo/i18n";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import FilledInput, { FilledInputProps } from "@mui/material/FilledInput";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";

const StyledInput = styled(FilledInput)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  "& .MuiInputBase-input": {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

export type TextFilterFieldProps = {
  onChange: (text: string) => void;
  text?: string;
};

const TextFilterField: React.FC<TextFilterFieldProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [text, setText] = React.useState<string>(props.text ?? "");

  React.useEffect(() => {
    if (props.text) {
      setText(props.text);
    }
  }, [props.text]);

  const inputProps: FilledInputProps = {
    color: "secondary",
    placeholder: t("評価項目を検索"),
    fullWidth: true,
    disableUnderline: true,
    startAdornment: (
      <InputAdornment position="start">
        <SearchIcon sx={{ color: theme.palette["Gray/Shades"].p56 }} />
      </InputAdornment>
    ),
    endAdornment: (text || undefined) && (
      <IconButton
        onClick={() => {
          setText("");
          props.onChange("");
        }}
      >
        <CancelIcon color={"action"} />
      </IconButton>
    ),
    value: text,
    onBlur: () => {
      props.onChange(text);
    },
    onKeyDown: event => {
      if (event.key === "Enter") {
        props.onChange(text);
      }
    },
    onChange: e => {
      if (typeof e.target.value === "string") {
        setText(e.target.value);
      }
    },
  };
  return <StyledInput {...inputProps} />;
};

TextFilterField.displayName = "TextFilterField";

export default TextFilterField;
