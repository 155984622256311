import * as React from "react";

import MultiAssignField from "./parts/MultiAssignField/MultiAssignField";
import SingleAssignField from "./parts/SingleAssignField/SingleAssignField";
import type { BaseAssignFieldProps } from "./types";

export type AssignFieldProps = BaseAssignFieldProps & {
  /**
   * @default false
   */
  multiple?: boolean;
};

const AssignField: React.FC<AssignFieldProps> = props => {
  const { multiple, ...rest } = props;
  if (multiple) {
    return <MultiAssignField {...rest} />;
  }
  return <SingleAssignField {...rest} />;
};

AssignField.displayName = "AssignField";

export default AssignField;
