import { ScreeningsId } from "@hireroo/app-store/page/c/screenings_id";
import * as TimeFormat from "@hireroo/formatter/time";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Pages } from "@hireroo/presentation";

export const useGenerateTestOverviewSectionProps = (): Pages.EntryScreeningProps["testOverviewSection"] => {
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const screening = ScreeningsId.useScreening();
  const companyName: string = screening.company?.name || t("会社名不明");

  return {
    companyName: companyName,
    submissionDeadline: ((): string => {
      if (screening.deadlineSeconds) {
        return TimeFormat.unixToDateFormat(screening.deadlineSeconds);
      }
      return t("なし");
    })(),
    questionNum: screening.questionCount,
    timeLimit: TimeFormat.formatSeconds(screening.timeLimitSeconds ?? 0, lang),
    message: screening.messageForCandidate || undefined,
  };
};
