import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateSystemDesignTestReportPropsArgs, useGenerateProps } from "./useGenerateProps";

export type SystemDesignTestReportContainerProps = GenerateSystemDesignTestReportPropsArgs;

const SystemDesignTestReportContainer: React.FC<SystemDesignTestReportContainerProps> = props => {
  const systemDesignTestReportProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.SystemDesignTestReport {...systemDesignTestReportProps} />
    </ErrorBoundary>
  );
};

SystemDesignTestReportContainer.displayName = "SystemDesignTestReportContainer";

export default withErrorBoundary(SystemDesignTestReportContainer, {});
