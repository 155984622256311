import { DemosIdStore } from "@hireroo/app-store/page/c/demos_id";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import DemosIdFetchContainer from "./FetchContainer";
import * as Subscriber from "./Subscriber";

const DemosIdInitialContainer: React.FC = () => {
  React.useEffect(() => {
    const stopSubscribeOnChangeSpot = Subscriber.startSubscribeOnChangeDemo();
    const stopSubscribeTimeoutStatus = Subscriber.startSubscribeTimeoutStatus();
    return () => {
      stopSubscribeOnChangeSpot();
      stopSubscribeTimeoutStatus();
      DemosIdStore.clear();
    };
  }, []);
  return (
    <ErrorBoundary>
      <DemosIdFetchContainer />
    </ErrorBoundary>
  );
};

DemosIdInitialContainer.displayName = "DemosIdInitialContainer";

export default withErrorBoundary(DemosIdInitialContainer, {});
