import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const ScoreText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 700,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  overflow: "hidden",
  fontSize: "16px",
}));

export type ScoreProps = {
  value: string;
};

const Score: React.FC<ScoreProps> = props => {
  return (
    <Box>
      <ScoreText>{props.value}</ScoreText>
    </Box>
  );
};

Score.displayName = "Score";

export default Score;
