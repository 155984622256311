import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateTestReportPropsArgs, useGenerateProps } from "./useGenerateProps";

export type TestReportContainerProps = GenerateTestReportPropsArgs;

const ScreeningTestReportContainer: React.FC<TestReportContainerProps> = props => {
  const TestReportProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ScreeningTestReport {...TestReportProps} />
    </ErrorBoundary>
  );
};

ScreeningTestReportContainer.displayName = "ScreeningTestReportContainer";

export default withErrorBoundary(ScreeningTestReportContainer, {});
