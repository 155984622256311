import { useTranslation } from "@hireroo/i18n";
import { InsertLinkOutlined, PeopleOutlineOutlined } from "@mui/icons-material";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";
import CopyToClipboard from "react-copy-to-clipboard";

import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "../../../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";

export type AcceptanceInfoProps = {
  acceptableCount?: number;
  acceptedCount: number;
  invitation?: {
    url: string;
    onCopy: () => void;
    disabled?: boolean;
  };
};

const AcceptanceInfo: React.FC<AcceptanceInfoProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const invitationLink: IconButtonWithTooltipProps | undefined = props.invitation
    ? {
        children: (
          <CopyToClipboard text={props.invitation.url} onCopy={props.invitation.onCopy}>
            <InsertLinkOutlined color={props.invitation.disabled ? "disabled" : "secondary"} sx={{ height: "16px", width: "16px" }} />
          </CopyToClipboard>
        ),
        title: t("招待リンクをコピー"),
        disabled: props.invitation.disabled,
      }
    : undefined;
  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      {props.acceptableCount === props.acceptedCount ? (
        <>
          <Tooltip title={`${t("承諾数")}/${t("定員数")} ${t("残枠不足")}`}>
            <WarningRoundedIcon sx={{ color: theme.palette.warning.light, height: "16px", width: "16px" }} />
          </Tooltip>
          <Typography
            color="warning"
            fontSize={12}
            sx={{ color: theme.palette.warning.light, wordBreak: "keep-all" }}
          >{`${props.acceptedCount}/${props.acceptableCount}`}</Typography>
        </>
      ) : (
        <>
          <Tooltip title={`${t("承諾数")}/${t("定員数")}`}>
            <PeopleOutlineOutlined sx={{ height: "16px", width: "16px" }} />
          </Tooltip>
          <Typography
            fontSize={12}
            sx={{ wordBreak: "keep-all" }}
          >{`${props.acceptedCount === 0 ? "" : `${props.acceptedCount}/`}${props.acceptableCount ?? t("無制限")}`}</Typography>
        </>
      )}
      {invitationLink && <IconButtonWithTooltip {...invitationLink} />}
    </Stack>
  );
};

AcceptanceInfo.displayName = "AcceptanceInfo";

export default AcceptanceInfo;
