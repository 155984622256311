import HttpStatusCodeGraph, { HttpStatusCodeGraphProps } from "@hireroo/charts/react/HttpStatusCodeGraph";
import LatencyGraph, { LatencyGraphProps } from "@hireroo/charts/react/LatencyGraph";
import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type ReportProjectBackendPerformanceDetailSectionProps = {
  syncId?: string;
  httpStatusCodeGraph: Omit<HttpStatusCodeGraphProps, "syncId">;
  latencyGraph: Omit<LatencyGraphProps, "syncId">;
};

// TODO @himenon
const Heading = styled(Typography)``;

const ReportProjectBackendPerformanceDetailSection: React.FC<ReportProjectBackendPerformanceDetailSectionProps> = props => {
  const { t } = useTranslation();
  return (
    <Box>
      <Grid container columnSpacing={1}>
        <Grid item xs={12}>
          <Heading variant="subtitle2">{t("HTTPステータスコード")}</Heading>
          <Box height={300} mt={4}>
            <React.Suspense>
              <HttpStatusCodeGraph {...props.httpStatusCodeGraph} syncId={props.syncId} />
            </React.Suspense>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Heading variant="subtitle2">{t("レイテンシー")}</Heading>
          <Box height={300} mt={4}>
            <React.Suspense>
              <LatencyGraph {...props.latencyGraph} syncId={props.syncId} />
            </React.Suspense>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

ReportProjectBackendPerformanceDetailSection.displayName = "ReportProjectBackendPerformanceDetailSection";

export default ReportProjectBackendPerformanceDetailSection;
