import { useTranslation } from "@hireroo/i18n";
import Markdown from "@hireroo/markdown-v2/react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import SystemDesignScoringGroup, { SystemDesignScoringGroupProps } from "../SystemDesignScoringGroup/SystemDesignScoringGroup";

const TitleText = styled(Typography)`
  font-weight: bold;
`;

export type AnswerItem = {
  text: string;
  value: string;
};

export type Answer = {
  value: string;
  items: AnswerItem[];
};

export type SystemDesignAnswerSectionProps = {
  title: string;
  description: string;
  answer: Answer;
  availability: Pick<SystemDesignScoringGroupProps, "items">;
  scalability: Pick<SystemDesignScoringGroupProps, "items">;
  consistency: Pick<SystemDesignScoringGroupProps, "items">;
};

const SystemDesignAnswerSection: React.FC<SystemDesignAnswerSectionProps> = props => {
  const { t } = useTranslation();
  const items: SystemDesignScoringGroupProps[] = [
    {
      ...props.availability,
      title: t("可用性"),
    },
    {
      ...props.scalability,
      title: t("スケーラビリティ"),
    },
    {
      ...props.consistency,
      title: t("一貫性"),
    },
  ].filter(target => target.items.length > 0);
  return (
    <Box>
      <TitleText variant="h5" color="textPrimary" gutterBottom mb={4}>
        {props.title}
      </TitleText>
      <Markdown size="middle" children={props.description} />
      <Box mt={2}>
        <Typography variant="h6" gutterBottom sx={theme => ({ fontWeight: "bold", color: theme.palette.text.secondary })}>
          {t("評価項目")}
        </Typography>
        <Stack spacing={2}>
          {items.map((item, index) => (
            <SystemDesignScoringGroup key={`scoring-group-${index}`} {...item} />
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

SystemDesignAnswerSection.displayName = "SystemDesignAnswerSection";

export default SystemDesignAnswerSection;
