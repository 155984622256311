import { Fields } from "@hireroo/validator";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useController } from "react-hook-form";

const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 12,
}));

const ButtonPrimaryTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: 14,
  marginBottom: "2px",
  wordBreak: "break-word",
  width: "100%",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  boxSizing: "border-box",
  padding: `${theme.spacing(1.5)} ${theme.spacing(1)} ${theme.spacing(1.5)} ${theme.spacing(1.5)}`,
  display: "inline-block",
  textAlign: "left",
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: "8px",
  '&[data-selected="true"]': {
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: theme.palette["Secondary/Shades"].p16,
  },
  textTransform: "none",
  flex: "0 0 calc(50% - 4px)",
  width: "calc(50% - 8px)",
}));

type FieldValue = Fields.SelectableQuestionField.SelectableQuestion;

export type SelectableButtonProps = {
  index: number;
  name: string;
  title: string;
  description: string;
};

const SelectableButton: React.FC<SelectableButtonProps> = props => {
  const { field, fieldState } = useController<Record<string, FieldValue>>({
    name: props.name,
  });
  const theme = useTheme();
  const selected: boolean = field.value.index === props.index;

  const buttonProps: ButtonProps = {
    color: "secondary",
    variant: "outlined",
    onClick: () => {
      const newValue: FieldValue = {
        trackId: field.value.trackId,
        index: props.index,
      };
      field.onChange(newValue);
    },
    sx: {
      borderColor: fieldState.error ? theme.palette.error.dark : undefined,
    },
  };
  return (
    <StyledButton {...buttonProps} data-selected={selected.toString()}>
      <ButtonPrimaryTypography>{props.title}</ButtonPrimaryTypography>
      <Description>{props.description}</Description>
    </StyledButton>
  );
};

SelectableButton.displayName = "SelectableButton";

export default SelectableButton;
