export const GrafanaLatencyColorMap = {
  p50: "#7fa46c",
  p90: "#d2ad4d",
  p95: "#87cede",
  p99: "#e18a4c",
  max: "#e18a4c",
  s100: "#BDD8AD",
  s200: "#89AF74",
  s300: "#5D854A",
  s400: "#E1894C",
  s500: "#D1584A",
};

export const StatusLegendMap: Record<string, string> = {
  s100: "1xx",
  s200: "2xx",
  s300: "3xx",
  s400: "4xx",
  s500: "5xx",
};

export type BoxChartColorTheme = "lightBlue" | "gold" | "lightPurple" | "black" | "white";
type BoxColorType = {
  light: string;
  main: string;
  contrast: string;
};

export const BoxChartColorThemeMap: Record<BoxChartColorTheme, BoxColorType> = {
  lightBlue: {
    light: "#CFE0F7",
    main: "#87CEEB",
    contrast: "#FF8C00",
  },
  gold: {
    light: "#ECE480",
    main: "#FFD700",
    contrast: "#006400",
  },
  lightPurple: {
    light: "#DED2EA",
    main: "#B19CD9",
    contrast: "#006400",
  },
  black: {
    light: "#D8D8D8",
    main: "#000000",
    contrast: "#006400",
  },
  white: {
    light: "#FFFFFF",
    main: "#FFFFFF",
    contrast: "#006400",
  },
};
