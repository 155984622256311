import type { IStandaloneCodeEditor } from "@hireroo/code-editor/react/CodeEditor";
import { getRef } from "@hireroo/firebase";
import type { IFirepad } from "@hireroo/firepad";
import * as Firepad from "@hireroo/firepad";
import * as Sentry from "@sentry/browser";
import { useLayoutEffect, useRef } from "react";

import { tuple } from "../tuple";

const { fromMonaco } = Firepad;

export type FirepadArgs = {
  db: "challenge" | "project" | "quiz";
  key: string;
  userName?: string;
  readOnly: boolean;
};

export const useFirepad = (args: FirepadArgs) => {
  const { db, key, userName, readOnly } = args;
  const pad = useRef<IFirepad | undefined>();

  // Since react v17, clean-up hooks are done in async. This means pad.current.dispose could be
  // called, after the component is unmounted. This causes error and if you want to avoid, you have
  // to use useLayoutEffect, instead of useEffect. To understand what's changed in detail check this out
  // https://ja.reactjs.org/blog/2020/08/10/react-v17-rc.html#effect-cleanup-timing
  useLayoutEffect(() => {
    return () => {
      /**
       * TODO To correctly dispose, firebase.database.goOffline() is performed.
       *
       * 1. Initial              : firebase -o-> firepad -o-> monaco-editor
       * 2. database.goOffline() : firebase -x-> firepad -o-> monaco-editor
       * 3. firepad.dispose()    : firebase -x-> firepad -x-> monaco-editor
       */
      if (pad.current) pad.current.dispose();
    };
  }, []);

  const initPad = (uid: string, editor: IStandaloneCodeEditor, text?: string) => {
    const ref = getRef(db, key);
    pad.current = fromMonaco(ref, editor, {
      userId: uid,
      userName: userName,
      defaultText: text ?? "",
    });
    pad.current.on(Firepad.FirepadEvent.Error, error => {
      if (error instanceof Error) {
        Sentry.captureEvent(error);
      }
    });
    pad.current.on(Firepad.FirepadEvent.Synced, () => {
      /**
       * The following processes are executed based on messages retrieved from Firebase.
       * @see https://github.com/interviewstreet/firepad-x/blob/v0.3.1/src/monaco-adapter.ts#L519-L541
       *
       * In addition, monaco-editor is automatically set to readOnly when the DOM disappears;
       * it is still in the ReadOnly state immediately after the DOM is restored,
       * so if Firebase messages are received first, the editor will remain set to the readOnly state
       * If the Firebase message is received first, the editor will remain set to the readOnly state.
       *
       * To avoid this, adjust the readOnly flag to convert it to the value given in the argument after the data is received from Firebase.
       */
      editor.updateOptions({
        readOnly: readOnly,
      });
    });
  };

  const setText = (text: string) => {
    if (pad && pad.current) pad.current.setText(text);
  };

  const getText = (): string => {
    if (pad && pad.current) {
      return pad.current.getText();
    }
    return "";
  };

  const action = {
    initPad,
    setText,
    getText,
  };

  return tuple(pad, action);
};
