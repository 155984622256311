import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import * as Subscriber from "./Subscriber";
import { GenerateScreeningTestListForDetailPropsArgs, useGenerateProps } from "./useGenerateProps";

export type ScreeningTestListForDetailContainerProps = GenerateScreeningTestListForDetailPropsArgs;

const ScreeningTestListForDetailContainer: React.FC<ScreeningTestListForDetailContainerProps> = props => {
  const screeningTestListForDetailProps = useGenerateProps(props);
  React.useEffect(() => {
    const stop = Subscriber.startSubscribeListParams();

    return () => {
      stop();
    };
  }, []);
  return (
    <ErrorBoundary>
      <Widget.ScreeningTestListForDetail {...screeningTestListForDetailProps} />
    </ErrorBoundary>
  );
};

ScreeningTestListForDetailContainer.displayName = "ScreeningTestListForDetailContainer";

export default withErrorBoundary(ScreeningTestListForDetailContainer, {});
