import { state } from "./State";
import type * as Types from "./types";

export const setQuestion = (question: Partial<Types.Question>): void => {
  state.question = question;
};

export const removeQuestion = () => {
  state.question = null;
};
