import firebase from "firebase/compat/app";

export let challengeApp: firebase.app.App;
export let quizApp: firebase.app.App;
export let projectApp: firebase.app.App;
export let systemDesignApp: firebase.app.App;
export let interviewApp: firebase.app.App;
export let liveCodingApp: firebase.app.App;

export type FirebaseAppConfig = {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
};

export type ConfigMap = {
  challenge: FirebaseAppConfig;
  systemDesign: FirebaseAppConfig;
  project: FirebaseAppConfig;
  quiz: FirebaseAppConfig;
  interview: FirebaseAppConfig;
  liveCoding: FirebaseAppConfig;
};

export const setupApp = (configMap: ConfigMap) => {
  challengeApp = firebase.initializeApp(configMap.challenge, "challengeApp");
  quizApp = firebase.initializeApp(configMap.quiz, "quizApp");
  systemDesignApp = firebase.initializeApp(configMap.systemDesign, "systemDesignApp");
  projectApp = firebase.initializeApp(configMap.project, "projectApp");
  interviewApp = firebase.initializeApp(configMap.interview, "interviewApp");
  liveCodingApp = firebase.initializeApp(configMap.liveCoding, "liveCodingApp");
};
