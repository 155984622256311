import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { useRemoteTechnicalCommentRepository } from "@hireroo/app-helper/hooks";
import { Auth, Company } from "@hireroo/app-store/essential/employee";
import { TechnicalCommentPromptForRemote } from "@hireroo/app-store/widget/e/TechnicalCommentPromptForRemote";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/browser";
import * as React from "react";

import { useFetchMetrics } from "./useFetchMetrics";

type SelectItem = Widget.TechnicalCommentPopperProps["formContent"]["selectItem"]["items"][0];
type EvaluationItem = Widget.TechnicalCommentPopperProps["formContent"]["review"]["evaluationItems"][0];

export type GenerateTechnicalCommentPopperPropsArgs = {
  remoteId: string;
  onClose: () => void;
};

export const useGenerateProps = (args: GenerateTechnicalCommentPopperPropsArgs): Widget.TechnicalCommentPopperProps => {
  const currentUid = Auth.useCurrentUid();
  const { t } = useTranslation();
  const client = getGraphqlClient();
  const [reviewStatus, setReviewStatus] = React.useState<"LOADING" | "READY">("READY");
  const pagination = TechnicalCommentPromptForRemote.useMetricPagination();
  const companyId = Company.useStrictActiveCompanyId();
  const metrics = TechnicalCommentPromptForRemote.useMetrics();
  const metricMap = TechnicalCommentPromptForRemote.useMetricsMap();
  const formValues = TechnicalCommentPromptForRemote.useFormValues();

  const cacheKey = args.remoteId;
  const { updateStepForPrompt, addEvaluation, removeEvaluation, updateEvaluation, updateFeedback, technicalComment, removeFromStorage } =
    useRemoteTechnicalCommentRepository(cacheKey);

  const evaluations = React.useMemo(() => {
    return Object.keys(technicalComment.schemas.evaluations).map(key => technicalComment.schemas.evaluations[Number(key)]);
  }, [technicalComment.schemas.evaluations]);

  const { isLoading } = useFetchMetrics({});

  const updateMetricsPaginationCallback = React.useCallback(() => {
    if (pagination.nextCursor) {
      TechnicalCommentPromptForRemote.updateMetricPagination({
        cursor: pagination.nextCursor,
        nextCursor: null,
      });
    }
  }, [pagination]);

  const step = React.useMemo((): Widget.TechnicalCommentPopperProps["formContent"]["step"] => {
    if (formValues.step === 0) return "INSTRUCTION";
    if (formValues.step === 1) return "SELECT_ITEM";
    if (formValues.step === 2) return "REVIEW";

    return "INSTRUCTION";
  }, [formValues.step]);

  const items = metrics.map((metric): SelectItem => {
    const metricIds = evaluations.map(e => e.metricId);
    const selected = metricIds.includes(metric.metricId);
    return {
      id: metric.id.toString(),
      selected: selected,
      onClick: () => {
        TechnicalCommentPromptForRemote.initFormValuesMetric(metric.metricId);

        if (selected) {
          removeEvaluation(metric.metricId);
        } else {
          addEvaluation(metric.metricId);
        }
      },
      title: metric.title,
      description: metric.description,
      kind: "MULTIPLE_CHOICE",
    };
  });

  const evaluationItems = React.useMemo((): EvaluationItem[] => {
    return evaluations.map((evaluation, index): EvaluationItem => {
      const currentMetric = metricMap.get(evaluation.metricId);
      return {
        id: evaluation.metricId.toString(),
        index: index,
        title: currentMetric?.title ?? "",
        description: currentMetric?.description ?? "",
        commentField: {
          onChange: event => {
            if (evaluation) {
              updateEvaluation(evaluation.metricId, {
                comment: event.target.value,
                metricId: evaluation.metricId,
                numStars: evaluation.numStars,
              });
            }
          },
          defaultValue: evaluation?.comment ?? "",
        },
        ratingField: {
          value: evaluation?.numStars ?? 0,
          onChange: (_, value) => {
            if (typeof value === "number" && evaluation) {
              updateEvaluation(evaluation.metricId, {
                comment: evaluation.comment,
                metricId: evaluation.metricId,
                numStars: value,
              });
            }
          },
        },
      };
    });
  }, [evaluations, metricMap, updateEvaluation]);

  const moveBackward = React.useCallback(() => {
    TechnicalCommentPromptForRemote.decrementStep();
    const backStepMap: Record<number, number> = {
      0: 0,
      1: 0,
      2: 1,
    };
    if (backStepMap[technicalComment.stepForPrompt] !== undefined) {
      updateStepForPrompt(backStepMap[technicalComment.stepForPrompt]);
    }
  }, [technicalComment.stepForPrompt, updateStepForPrompt]);

  const moveForward = React.useCallback(() => {
    TechnicalCommentPromptForRemote.incrementStep();
    const forwardStepMap: Record<number, number> = {
      0: 1,
      1: 2,
      2: 2,
    };
    if (forwardStepMap[technicalComment.stepForPrompt] !== undefined) {
      updateStepForPrompt(forwardStepMap[technicalComment.stepForPrompt]);
    }
  }, [technicalComment.stepForPrompt, updateStepForPrompt]);

  const isValidFormValues = React.useMemo(() => {
    return evaluations.length > 0 && evaluations.every(evaluation => evaluation.numStars > 0);
  }, [evaluations]);

  return {
    formContent: {
      step: step,
      selectItem: {
        title: t("評価項目の選択"),
        description: t("評価項目を選びましょう。複数選択することが可能で選んだ項目を元に技術レビューを続けます。"),
        itemTitle: t("項目名"),
        pager: {
          rootKey: "metricItemHeader",
          moreKey: "metricLoadMore",
        },
        items: items,
        hasNext: pagination.hasNext,
        intersectCallback: updateMetricsPaginationCallback,
        status: isLoading ? "LOADING" : "NONE",
        nextButton: {
          onClick: () => {
            moveForward();
          },
          disabled: evaluations.length === 0,
        },
        backButton: {
          onClick: () => {
            moveBackward();
          },
        },
      },
      review: {
        commentTextField: {
          defaultValue: technicalComment.schemas.feedback.comment,
          onChange: event => {
            updateFeedback({
              comment: event.target.value,
            });
          },
        },
        evaluationItems: evaluationItems,
        thumbSwitch: {
          onChange: value => {
            updateFeedback({
              isRecommended: value === "GO",
            });
          },
          value: technicalComment.schemas.feedback.isRecommended ? "GO" : "NOT_GO",
        },
        confirmButton: {
          onClick: () => {
            setReviewStatus("LOADING");
            client
              .ReviewRemoteForTechnicalCommentRemote({
                input: {
                  remoteId: args.remoteId,
                  companyId: companyId,
                  isRecommended: technicalComment.schemas.feedback.isRecommended,
                  comment: technicalComment.schemas.feedback.comment,
                  evaluations: evaluations,
                  employeeId: currentUid,
                },
              })
              .then(res => {
                Snackbar.notify({
                  severity: "success",
                  message: t("テストの技術レビューが完了しました。"),
                });
                TechnicalCommentPromptForRemote.clearFormValues();
                TechnicalCommentPromptForRemote.updateReviewedRemote(res.reviewRemote);
                removeFromStorage(cacheKey);
                localStorage.removeItem(cacheKey);
                args.onClose();
              })
              .catch(error => {
                Sentry.captureException(error);
                const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                  error,
                  t("テストの技術レビューに失敗しました。しばらくしてから再度お試しください。"),
                );
                Snackbar.notify({
                  severity: "error",
                  message: errorNotification.message,
                });
              })
              .finally(() => {
                setReviewStatus("READY");
              });
          },
          disabled: reviewStatus === "LOADING" || !isValidFormValues,
        },
        backButton: {
          disabled: reviewStatus === "LOADING",
          onClick: () => {
            moveBackward();
          },
        },
      },
      instruction: {
        message: t("受験者の技術力をレビューします。レビューした結果は集計され、最終合否の判断に使用されます。"),
        startButton: {
          onClick: () => {
            moveForward();
          },
        },
      },
      isLoading: isLoading,
    },
    onClose: () => {
      args.onClose();
    },
  };
};
