import type { PlaybackTickEvent } from "@hireroo/app-helper/playback";
import { DeepReadonly } from "@hireroo/app-helper/types";
import { ChallengePlayback } from "@hireroo/app-store/widget/shared/ChallengePlayback";
import * as Time from "@hireroo/formatter/time";
import { useCurrentLanguage, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

const SHOWING_PLAYBACK_EVENT_KIND_LIST: PlaybackTickEvent["kind"][] = [
  "ACCESS",
  "CHATGPT_REQUEST",
  "EDITOR_PASTE",
  "WEB_SITE_SEARCH",
  "EXTERNAL_WEB_SITE_ACCESS",
  "USE_HINT",
  "CHATGPT_RESET",
  "SUBMIT_QUESTION",
  "RUN_CODE",
  "BROWSER_BLUR",
  "BROWSER_HIDDEN",
  "BROWSER_FOCUS",
  "BROWSER_VISIBLE",
];

export type GenerateScreeningTestActivityLogPropsArgs = {};

export const useGenerateProps = (_args: GenerateScreeningTestActivityLogPropsArgs): Widget.ScreeningTestActivityLogProps => {
  const playbackManager = ChallengePlayback.usePlaybackManager();
  const lang = useCurrentLanguage();
  const question = ChallengePlayback.useQuestion();
  const [currentTickIndex, setCurrentTickIndex] = React.useState(playbackManager.currentTickIndex);
  const { t } = useTranslation();
  const remainTime = React.useMemo(() => {
    const endTime = playbackManager.timeStamps.at(-1) ?? 0;
    const startTime = playbackManager.timeStamps.at(0) ?? 0;

    return Time.elapsedTimeFormat(endTime - startTime);
  }, [playbackManager]);

  const timelineItems = React.useMemo((): Widget.ScreeningTestActivityLogProps["timeline"]["items"] => {
    const items: Widget.ScreeningTestActivityLogProps["timeline"]["items"] = [];
    const startTime = playbackManager.timeStamps.at(0) ?? 0;
    playbackManager.ticks.forEach((tick, tickIndex) => {
      const { uniqueEvents } = tick.events.reduce<{ kindSet: Set<PlaybackTickEvent["kind"]>; uniqueEvents: DeepReadonly<PlaybackTickEvent>[] }>(
        (previous, event) => {
          if (previous.kindSet.has(event.kind)) {
            return previous;
          }
          previous.kindSet.add(event.kind);
          previous.uniqueEvents.push(event);
          return previous;
        },
        { kindSet: new Set(), uniqueEvents: [] },
      );
      uniqueEvents.forEach(event => {
        const sharedParams: Pick<Widget.ScreeningTestActivityLogProps["timeline"]["items"][0], "highlight" | "timeLabel" | "onClick"> = {
          highlight: currentTickIndex === tickIndex,
          timeLabel: Time.elapsedTimeFormat(event.ts - startTime),
          onClick: () => {
            const newIndex = playbackManager.getIndexByTimeStamp(event.ts);
            if (newIndex !== undefined) {
              ChallengePlayback.updateSliderValue(newIndex);
            }
          },
        };
        switch (event.kind) {
          case "CHATGPT_REQUEST": {
            items.push({
              mark: "COMPUTER",
              heading: {
                kind: t("ChatGPTの利用"),
              },
              ...sharedParams,
            });
            break;
          }
          case "EDITOR_PASTE": {
            items.push({
              mark: "WARNING",
              heading: {
                kind: t("ペースト"),
              },
              ...sharedParams,
            });
            break;
          }
          case "WEB_SITE_SEARCH": {
            items.push({
              mark: "COMPUTER",
              heading: {
                kind: t("Google検索"),
              },
              ...sharedParams,
            });
            break;
          }
          case "EXTERNAL_WEB_SITE_ACCESS": {
            items.push({
              mark: "COMPUTER",
              heading: {
                kind: t("外部サイトへのアクセス"),
              },
              ...sharedParams,
            });
            break;
          }
          case "USE_HINT": {
            items.push({
              mark: "WARNING",
              heading: {
                kind: t("ヒントの使用"),
              },
              ...sharedParams,
            });
            break;
          }
          case "CHATGPT_RESET": {
            items.push({
              mark: "COMPUTER",
              heading: {
                kind: t("ChatGPTのリセット"),
              },
              ...sharedParams,
            });
            break;
          }
          case "RUN_CODE": {
            items.push({
              mark: "COMPUTER",
              heading: {
                kind: t("コードの実行"),
              },
              ...sharedParams,
            });
            break;
          }
          case "SUBMIT_QUESTION": {
            items.push({
              mark: "COMPUTER",
              heading: {
                kind: t("解答の提出"),
              },
              ...sharedParams,
            });
            break;
          }
          case "ACCESS": {
            items.push({
              mark: "COMPUTER",
              heading: {
                kind: t("IPアドレス検知"),
                value: event.ipAddress,
              },
              ...sharedParams,
            });
            break;
          }
          case "BROWSER_BLUR":
            items.push({
              mark: "WARNING",
              heading: {
                kind: t("テストページから離脱"),
              },
              ...sharedParams,
            });
            break;
          case "BROWSER_HIDDEN": {
            items.push({
              mark: "WARNING",
              heading: {
                kind: t("テストページから離脱"),
                value: ((): string | undefined => {
                  switch (event.reason) {
                    case "GOOGLE_SEARCH":
                      return t("Google検索");
                    case "CLICK_ON_LINK":
                      return t("ページ内リンクのクリック");
                    case "UNKNOWN":
                    default:
                      return undefined;
                  }
                })(),
              },
              ...sharedParams,
            });
            break;
          }
          case "BROWSER_VISIBLE":
            /** BROWSER_FOCUS show detail */
            break;
          case "BROWSER_FOCUS": {
            items.push({
              mark: "WARNING",
              heading: {
                kind: t("テストページを再表示"),
              },
              ...sharedParams,
            });
            break;
          }
          case "EDITOR_COPY":
          case "EDITOR_CUT":
          case "CHATGPT_RESPOND":
          case "CODE_EDITOR":
            break;
          default:
            throw new Error(`Unknown Event Kind: ${event satisfies never}`);
        }
      });
    });
    return items;
  }, [playbackManager, currentTickIndex, t]);

  React.useEffect(() => {
    const cleanup = playbackManager.onMove(payload => {
      const nearestIndex = playbackManager.ticks.slice(0, payload.nextIndex + 1).findLastIndex(tick => {
        return tick.events.some(event => {
          return SHOWING_PLAYBACK_EVENT_KIND_LIST.includes(event.kind);
        });
      });
      setCurrentTickIndex(nearestIndex ?? payload.nextIndex);
    });
    return () => {
      cleanup();
    };
  }, [playbackManager]);
  return {
    detail: {
      question: {
        description: resolveLanguage(question, lang, "title"),
      },
      time: {
        description: remainTime,
      },
    },
    timeline: {
      items: timelineItems,
    },
  };
};
