import { useTranslation } from "@hireroo/i18n";
import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import * as React from "react";

import ButtonWithTooltip, { ButtonWithTooltipProps } from "../../primitive/Button/ButtonWithTooltip/ButtonWithTooltip";
import { DropDownMenuProps } from "../../primitive/DropDownMenu/DropDownMenu";
import IconDropDown from "./parts/IconDropDown/IconDropDown";
import MenuListButton, { MenuListButtonProps } from "./parts/MenuListButton/MenuListButton";

export type TalentSideBarContentProps = {
  topMenu: DropDownMenuProps;
  menuList: MenuListButtonProps[];
  createButton: Pick<ButtonWithTooltipProps, "onClick">;
};

/**
 * TODO You can remove this component once you decide where Talent's Switch Company can be located.
 */
const TalentSideBarContent: React.FC<TalentSideBarContentProps> = props => {
  const { t } = useTranslation();
  return (
    <Box>
      <Stack mt={3} px={2} direction="column" spacing={2} divider={<Divider />}>
        <Box>
          <Stack direction="row" mb={1}>
            <IconDropDown {...props.topMenu} />
          </Stack>
        </Box>
        <Box mb={2}>
          <ButtonWithTooltip {...props.createButton} fullWidth role="permittedbutton" startIcon="ADD" size="medium" variant="contained">
            {t("テスト作成").toString()}
          </ButtonWithTooltip>
        </Box>
        <List>
          {props.menuList.map(menu => (
            <MenuListButton key={menu.mainButton.id} {...menu} />
          ))}
        </List>
      </Stack>
    </Box>
  );
};

TalentSideBarContent.displayName = "TalentSideBarContent";

export default TalentSideBarContent;
