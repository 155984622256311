import type { Pages } from "@hireroo/presentation";

import FooterContainer from "../../../../widget/shared/Footer/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import RemoteInterviewCandidateHeaderContainer from "./widget/RemoteInterviewCandidateHeader/Container";

export type GenerateCompleteRemoteInterviewPropsArgs = {};

export const useGenerateCompleteRemoteInterviewProps = (
  _args: GenerateCompleteRemoteInterviewPropsArgs,
): Pages.CompleteRemoteInterviewProps => {
  return {
    kind: "COMPLETE_CANDIDATE_INTERVIEW",
    layout: {
      NotificationBanner: null,
      Header: <RemoteInterviewCandidateHeaderContainer mode="COMPLETE" />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      Tutorial: <></>,
    },
  };
};
