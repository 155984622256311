import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "24px",
  alignSelf: "stretch",
}));

const Video = styled("video")({
  width: "100%",
  height: "100%",
});

const StyledPlanUpgradeContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "24px",
  gap: "24px",
  alignItems: "center",
  alignSelf: "stretch",
  borderRadius: "16px",
  backgroundColor: theme.palette.background.default,
}));

const StyledDescriptionContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "16px",
  flex: "1 0 0",
}));

const StyledButtonContainer = styled(Box)(() => ({
  display: "flex",
  paddingTop: "16px",
  alignItems: "flex-start",
  gap: "16px",
}));

const StyledButton = styled(Button)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

export type UpsellWithVideoProps = {
  title: string;
  description: string;
  video: Pick<JSX.IntrinsicElements["video"], "src" | "onPlay">;
  updatePlanButton?: Pick<ButtonProps, "onClick">;
  checkDetailButton?: Pick<ButtonProps, "onClick">;
};

const UpsellWithVideo: React.FC<UpsellWithVideoProps> = props => {
  const { t } = useTranslation();

  const updatePlanButton: ButtonProps | null = props.updatePlanButton
    ? {
        ...props.updatePlanButton,
        variant: "contained",
        color: "secondary",
        sx: {
          wordBreak: "keep-all",
        },
        children: t("プランを更新"),
      }
    : null;

  const checkDetailButton: ButtonProps | null = props.checkDetailButton
    ? {
        ...props.checkDetailButton,
        variant: "outlined",
        color: "secondary",
        children: t("詳細を確認"),
      }
    : null;

  const videoProps: JSX.IntrinsicElements["video"] = {
    ...props.video,
    controls: true,
  };

  return (
    <StyledContainer>
      <Video {...videoProps} />
      <StyledPlanUpgradeContainer>
        <StyledDescriptionContainer>
          <Typography variant="h6">{props.title}</Typography>
          <Typography variant="body1">{props.description}</Typography>
        </StyledDescriptionContainer>
        {(updatePlanButton || checkDetailButton) && (
          <StyledButtonContainer>
            {updatePlanButton && <StyledButton {...updatePlanButton} />}
            {checkDetailButton && <StyledButton {...checkDetailButton} />}
          </StyledButtonContainer>
        )}
      </StyledPlanUpgradeContainer>
    </StyledContainer>
  );
};

UpsellWithVideo.displayName = "UpsellWithVideo";

export default UpsellWithVideo;
