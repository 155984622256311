import { useTranslation } from "@hireroo/i18n";
import type { SelectChangeEvent } from "@mui/material";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";

import { DIRECTION, Direction, EdgeElement, OPERATION_TYPE, OperationType, UnionSettingsFields } from "../../../../../features";
import MenuItem from "../../../primitive/MenuItem/MenuItem";

export type EdgeSettingsFormProps = {
  element: EdgeElement;
  updateSettings: (id: string, updates: UnionSettingsFields, operationType: OperationType) => void;
};

const StyledHeading = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  color: "text.secondary",
}));

export const EdgeSettingsForm: React.FC<EdgeSettingsFormProps> = (props: EdgeSettingsFormProps) => {
  const { t } = useTranslation();

  const handleSelectDirection = (e: SelectChangeEvent<unknown>) => {
    props.updateSettings(props.element.id, { direction: e.target.value as Direction }, OPERATION_TYPE.do);
  };

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <StyledHeading>{t("向き")}</StyledHeading>
      <Select variant={"outlined"} color="secondary" onChange={handleSelectDirection} value={props.element.settings.direction}>
        <MenuItem value={DIRECTION.undirectional}>{t("単方向")}</MenuItem>
        <MenuItem value={DIRECTION.bidirectional}>{t("双方向")}</MenuItem>
      </Select>
    </Box>
  );
};

EdgeSettingsForm.displayName = "EdgeSettingsForm";

export default EdgeSettingsForm;
