import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { useTitle } from "@hireroo/app-helper/react-use";
import { Auth, Role } from "@hireroo/app-store/essential/employee";
import { InvitationsIdStore } from "@hireroo/app-store/page/e/invitations_id";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Pages } from "@hireroo/presentation";
import { LastVisitedRoute, redirectWithRawUrl, safeRedirect } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/browser";
import * as React from "react";

import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import { acceptInvitation } from "./privateHelper";

export type GenerateInvitationPropsArgs = {
  invitationId: string;
};

export const useGenerateProps = (args: GenerateInvitationPropsArgs): Pages.InvitationProps => {
  const navigate = useTransitionNavigate();
  const uid = Auth.useCurrentUid();
  const company = InvitationsIdStore.useCompany();
  const invitationRole = InvitationsIdStore.useInvitationRole();
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const [status, setStatus] = React.useState<"PENDING" | "READY">("READY");

  useTitle(t("招待"));

  return {
    layout: {
      Snackbar: <SnackbarContainer />,
    },
    dialog: {
      title: t2("AcceptInvitation", {
        companyName: company.name,
      }),
      role: `${t("権限")}: ${Role.useRoleToText(invitationRole)}`,
      confirmButton: {
        disabled: status === "PENDING",
        onClick: async () => {
          setStatus("PENDING");
          await acceptInvitation({
            uid,
            companyId: company.companyId,
            invitationId: args.invitationId,
            invitationRole: invitationRole,
          })
            .catch(error => {
              Sentry.captureException(error);
              const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                error,
                t("招待の承諾に失敗しました。しばらくしてから再度お試しください。"),
              );
              Snackbar.notify({
                severity: "error",
                message: errorNotification.message,
              });
            })
            .finally(() => {
              const pathname = LastVisitedRoute.getAndClear();
              if (pathname) {
                redirectWithRawUrl(pathname);
              } else {
                safeRedirect("/e/home");
              }
              setStatus("READY");
            });
        },
      },
      rejectButton: {
        disabled: status === "PENDING",
        onClick: async () => {
          await navigate("/");
        },
      },
    },
  };
};
