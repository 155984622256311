import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";

import ButtonWithTooltip, { ButtonWithTooltipProps } from "../../../../../../../../primitive/Button/ButtonWithTooltip/ButtonWithTooltip";
import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../../../../../../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";

type DialogController = {
  setLoading: (loading: boolean) => void;
  close: () => void;
};

export type StartInterviewDialogProps = {
  title: string;
  onSubmit: (controller: DialogController) => void;
  button: Pick<ButtonWithTooltipProps, "onClick" | "children" | "startIcon">;
};

const StartInterviewDialog: React.FC<StartInterviewDialogProps> = props => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const baseDialog: DialogWithCloseIconProps = {
    open: open,
    title: props.title,
    yesButton: {
      color: "secondary",
      children: t("開始する"),
      onClick: () => {
        const controller: DialogController = {
          close: () => {
            setOpen(false);
          },
          setLoading: (newLoading: boolean) => {
            setLoading(newLoading);
          },
        };
        props.onSubmit(controller);
      },
      autoFocus: false,
      disabled: loading,
    },
    noButton: {
      variant: "text",
      color: "outline-only",
      children: t("キャンセル"),
      disabled: loading,
      onClick: () => {
        setOpen(false);
      },
    },
    onClose: () => {
      setOpen(false);
    },
    disableEnter: true,
    optionalDialog: {
      disableAutoFocus: true,
      disableRestoreFocus: true,
    },
  };
  const button: ButtonWithTooltipProps = {
    ...props.button,
    color: "secondary",
    size: "small",
    onClick: event => {
      event.preventDefault();
      event.stopPropagation();
      props.button.onClick?.(event);
      setOpen(true);
    },
  };
  return (
    <Box>
      <ButtonWithTooltip {...button} />
      <DialogWithCloseIcon {...baseDialog}>
        <Typography fontSize={14}>
          {t("「開始する」ボタンを押すことでインタビューが開始されます。開始後より候補者が出題されている問題の閲覧・回答が可能となります。")}
        </Typography>
      </DialogWithCloseIcon>
    </Box>
  );
};

StartInterviewDialog.displayName = "StartInterviewDialog";

export default StartInterviewDialog;
