import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { SystemDesignCodingEditor } from "@hireroo/app-store/widget/shared/SystemDesignCodingEditor";
import * as Graphql from "@hireroo/graphql/client/urql";
import { getTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import { SystemDesignProvider } from "@hireroo/system-design/react/FlowChart";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as Sentry from "@sentry/react";
import * as React from "react";

import SystemDesignCodingEditorContainer, { SystemDesignCodingEditorContainerProps } from "./Container";
import { useGenerateSystemDesignProviderProps } from "./useGenerateSystemDesignProviderProps";

export type SystemDesignCodingEditorFetchContainerProps = SystemDesignCodingEditorContainerProps;

const SystemDesignCodingEditorFetchContainer: React.FC<SystemDesignCodingEditorFetchContainerProps> = props => {
  const [result, startSystemDesign] = Graphql.useStartSystemDesignMutation();
  const hooks = SystemDesignCodingEditor.useCreateSystemDesignEntityHooks(props.entityId);
  const enabledHint = hooks.useEnabledHint();
  const status = hooks.useStatus();
  const { uid } = props;
  const systemDesignProviderProps = useGenerateSystemDesignProviderProps({
    entityId: props.entityId,
  });
  const systemDesignCodingEditorContainerProps: SystemDesignCodingEditorContainerProps = props;
  React.useEffect(() => {
    if (status === "CREATED" && !result.error && !result.fetching) {
      startSystemDesign({
        systemDesignId: props.entityId,
        candidateId: uid,
      })
        .then(res => {
          if (res.data?.startSystemDesign) {
            SystemDesignCodingEditor.initialize(res.data.startSystemDesign, {
              enabledHint: enabledHint,
            });
          }
        })
        .catch(error => {
          const { t } = getTranslation();
          Snackbar.notify({
            severity: "error",
            message: t("システムデザイン形式の試験開始に失敗しました。"),
          });
          Sentry.captureException(error);
        });
    }
  }, [uid, startSystemDesign, result, status, props.entityId, enabledHint]);
  if (status === "CREATED" || result.fetching) {
    return <Widget.Loading kind="CENTER_%" color="secondary" />;
  }
  return (
    <ErrorBoundary>
      <SystemDesignProvider {...systemDesignProviderProps}>
        <SystemDesignCodingEditorContainer {...systemDesignCodingEditorContainerProps} />
      </SystemDesignProvider>
    </ErrorBoundary>
  );
};

SystemDesignCodingEditorFetchContainer.displayName = "SystemDesignCodingEditorFetchContainer";

export default withErrorBoundary(SystemDesignCodingEditorFetchContainer, {});
