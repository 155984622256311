import { useEnabledLeakScore } from "@hireroo/app-helper/feature";
import { useQuestionVariantLabelMap } from "@hireroo/app-helper/question";
import { App, Payment } from "@hireroo/app-store/essential/employee";
import { QuestionSelectFieldForResourceEditor } from "@hireroo/app-store/widget/e/QuestionSelectFieldForResourceEditor";
import { formatScore } from "@hireroo/formatter/score";
import { formatSeconds } from "@hireroo/formatter/time";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import { Widget } from "@hireroo/presentation";
import { navigate } from "@hireroo/router/api";
import type { Fields, QuestionSearchForm } from "@hireroo/validator";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import * as PrivateHelper from "./privateHelper";
import QuestionSearchForResourceEditorContainer from "./widget/QuestionSearchForResourceEditor/Container";

type EntitySources = Widget.QuestionSearchAndSelectableAreaProps["sortableQuestionList"]["entitySources"];
type SelectableQuestionListItemProps = Widget.QuestionSearchAndSelectableAreaProps["selectableQuestionList"]["items"][0];
type SidebarProps = Exclude<Widget.QuestionSearchAndSelectableAreaProps["sidebar"], undefined>;
type SidebarListItemProps = SidebarProps["items"][0];
type PrefixLabel = Widget.QuestionSearchAndSelectableAreaProps["sortableQuestionListHeader"]["prefixLabel"];

const prefixLabelMap: Record<QuestionSelectFieldForResourceEditor.AddQuestionsMode, PrefixLabel> = {
  ADD_FIXED: "ADDABLE",
  ADD_RANDOM: "RANDOMLY_SELECTED_QUESTIONS",
  EDIT_RANDOM: "RANDOMLY_SELECTED_QUESTIONS",
  ADD_SELECTABLE: "SELECTABLE_QUESTIONS_FOR_TEST_TAKERS",
  EDIT_SELECTABLE: "SELECTABLE_QUESTIONS_FOR_TEST_TAKERS",
};

const algorithmQuestionVariantMap: Record<Graphql.AlgorithmQuestionVariant, QuestionSearchForm.QuestionVariant> = {
  [Graphql.AlgorithmQuestionVariant.Algorithm]: "CHALLENGE_ALGORITHM",
  [Graphql.AlgorithmQuestionVariant.Class]: "CHALLENGE_CLASS",
  [Graphql.AlgorithmQuestionVariant.Database]: "CHALLENGE_DATABASE",
  [Graphql.AlgorithmQuestionVariant.Unknown]: "CHALLENGE",
};

const projectQuestionVariantMap: Record<Graphql.ProjectQuestionVariant, QuestionSearchForm.QuestionVariant> = {
  [Graphql.ProjectQuestionVariant.Backend]: "PROJECT_BACKEND",
  [Graphql.ProjectQuestionVariant.DataScience]: "PROJECT_DATA_SCIENCE",
  [Graphql.ProjectQuestionVariant.Default]: "PROJECT",
  [Graphql.ProjectQuestionVariant.Frontend]: "PROJECT_FRONTEND",
  [Graphql.ProjectQuestionVariant.Unknown]: "PROJECT",
};

type SortFieldOptions = Extract<
  Widget.QuestionSearchAndSelectableAreaProps["content"],
  { mode: "QUESTION_SEARCH" }
>["header"]["searchResultHeader"]["sortField"]["options"];

type Target = "exam" | "test" | "remote";

export type GenerateQuestionSortableTwoColumnListPropsArgs = {
  name: "entityTracks";
  target: Target;
};

export const useGenerateProps = (args: GenerateQuestionSortableTwoColumnListPropsArgs): Widget.QuestionSearchAndSelectableAreaProps => {
  const lang = useLanguageCode();
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const methods = useFormContext<{ entityTracks: Fields.EntityTrack.EntityTrack[] }>();
  const isAvailableQuestionByPayment = Payment.useIsAvailableQuestionToUse(args.target);
  const enableTalentScore = App.useEnableTalentScore();
  const isAvailableQuestion = React.useCallback(
    (variant: Graphql.QuestionVariant): boolean => {
      if (args.target === "exam" && enableTalentScore) {
        return true;
      }
      return isAvailableQuestionByPayment(variant);
    },
    [args.target, enableTalentScore, isAvailableQuestionByPayment],
  );
  const { mode } = Widget.useQuestionSearchAndSelectableAreaContext();

  const selectedEntitySourceUniqueKeys = React.useMemo((): Set<string> => {
    const entityTracks = methods.getValues("entityTracks");
    return entityTracks.reduce((all, entityTrack) => {
      switch (entityTrack.type) {
        case "FIXED": {
          all.add(entityTrack.entitySource.uniqueKey);
          break;
        }
        case "RANDOM_FROM_QUESTIONS":
        case "SELECTABLE_FROM_QUESTIONS":
          entityTrack.entitySources.forEach(entitySource => {
            all.add(entitySource.uniqueKey);
          });
          break;
        default:
          throw new Error(`Invalid EntityTrack : ${entityTrack satisfies never}`);
      }
      return all;
    }, new Set<string>());
  }, [methods]);

  const questionSelectState = QuestionSelectFieldForResourceEditor.useQuestionSelectState();
  // Questions
  const fetchQuestionsStatus = QuestionSelectFieldForResourceEditor.useFetchQuestionsStatus();
  const listQuestionsRes = QuestionSelectFieldForResourceEditor.useListQuestionsResponse();
  const questionMap = QuestionSelectFieldForResourceEditor.useQuestionMap();
  const numOfQuestions: number = React.useMemo((): number => {
    return (listQuestionsRes?.questions || []).reduce((total, question) => {
      if (question.spotQuestion && selectedEntitySourceUniqueKeys.has(question.spotQuestion.key)) {
        return total - 1;
      } else if (question.liveCodingQuestion && selectedEntitySourceUniqueKeys.has(question.liveCodingQuestion.key)) {
        return total - 1;
      }
      return total;
    }, listQuestionsRes?.count ?? 0);
  }, [listQuestionsRes?.count, listQuestionsRes?.questions, selectedEntitySourceUniqueKeys]);

  const questionsPager = QuestionSelectFieldForResourceEditor.useQuestionsPager();

  // QuestionPackages
  const selectedQuestionPackage = QuestionSelectFieldForResourceEditor.useSelectedQuestionPackage();
  const questions = QuestionSelectFieldForResourceEditor.useListQuestions();
  const questionPackageList = QuestionSelectFieldForResourceEditor.useListableQuestionPackages();

  const selectedListToDisplay = QuestionSelectFieldForResourceEditor.useSelectedListToDisplay();

  const questionVariantLabelMap = useQuestionVariantLabelMap();

  const enabledLeakScore = useEnabledLeakScore();

  const entitySources = React.useMemo((): EntitySources => {
    return Array.from(questionMap.keys()).reduce<EntitySources>((entitySources, uniqueKey) => {
      const question = questionMap.get(uniqueKey);
      if (!question) {
        return entitySources;
      }
      const title: EntitySources[0]["title"] = {
        children: [resolveLanguage(question, lang, "title"), `(${PrivateHelper.getVersion(question)})`].join(" "),
        href: PrivateHelper.generateQuestionDetailPath(question),
        onClick: () => {
          navigate(PrivateHelper.generateQuestionDetailPath(question), {
            _blank: true,
          });
        },
      };

      const leakScore: EntitySources[0]["leakScore"] = (() => {
        if (!enabledLeakScore) {
          return undefined;
        }
        if (!question.isSupportedLeakScore) {
          return { level: "NONE", reason: "NOT_SUPPORTED" };
        }
        if (!question.leakScore) {
          return { level: "NONE", reason: "NO_DATA" };
        }
        return { level: question.leakScore.level };
      })();

      switch (question.__typename) {
        case "SystemDesignQuestion": {
          entitySources[uniqueKey] = {
            title,
            variant: questionVariantLabelMap.SYSTEM_DESIGN,
            difficulty: question.difficulty,
            isOfficial: question.isOfficial,
            averageScore: `${formatScore(question.accuracyRate).toString()}%`,
            numOfUse: question.numUses.toString(),
            timelimit: formatSeconds(question.timeLimitSeconds ?? 0, lang),
            leakScore: leakScore,
            mark: "NONE",
          };
          break;
        }
        case "MultiChoicePackage": {
          entitySources[uniqueKey] = {
            title,
            variant: questionVariantLabelMap.QUIZ,
            difficulty: question.difficulty,
            isOfficial: question.isOfficial,
            averageScore: `${formatScore(question.accuracyRate).toString()}%`,
            numOfUse: question.numUses.toString(),
            timelimit: formatSeconds(question.timeLimitSeconds ?? 0, lang),
            leakScore: leakScore,
            mark: "NONE",
          };
          break;
        }
        case "AlgorithmQuestion": {
          entitySources[uniqueKey] = {
            title,
            variant: questionVariantLabelMap[algorithmQuestionVariantMap[question.algorithmQuestionVariant]],
            difficulty: question.difficulty,
            isOfficial: question.isOfficial,
            averageScore: `${formatScore(question.accuracyRate).toString()}%`,
            numOfUse: question.numUses.toString(),
            timelimit: formatSeconds(question.timeLimitSeconds ?? 0, lang),
            leakScore: leakScore,
            mark: "NONE",
          };
          break;
        }
        case "ProjectQuestion": {
          entitySources[uniqueKey] = {
            title,
            variant: questionVariantLabelMap[projectQuestionVariantMap[question.projectQuestionVariant]],
            difficulty: question.difficulty,
            isOfficial: question.isOfficial,
            averageScore: `${formatScore(question.accuracyRate).toString()}%`,
            numOfUse: question.numUses.toString(),
            timelimit: formatSeconds(question.timeLimitSeconds ?? 0, lang),
            leakScore: leakScore,
            mark: "NONE",
          };
          break;
        }
        case "FreepadQuestion": {
          entitySources[uniqueKey] = {
            title,
            variant: questionVariantLabelMap.FREEPAD,
            difficulty: question.difficulty,
            isOfficial: question.isOfficial,
            numOfUse: question.numUses.toString(),
            leakScore: leakScore,
            mark: "NONE",
          };
          break;
        }
      }

      return entitySources;
    }, {});
  }, [questionVariantLabelMap, lang, questionMap, enabledLeakScore]);
  const sortFieldOptions: SortFieldOptions = React.useMemo(
    () => [
      {
        displayName: t("作成日時が新しい順"),
        value: QuestionSelectFieldForResourceEditor.SortFields.CREATED_AT_DESCENDING,
      },
      {
        displayName: t("作成日時が古い順"),
        value: QuestionSelectFieldForResourceEditor.SortFields.CREATED_AT_ASCENDING,
      },
      {
        displayName: t("平均スコアが高い順"),
        value: QuestionSelectFieldForResourceEditor.SortFields.ACCURACY_RATE_DESCENDING,
      },
      {
        displayName: t("平均スコアが低い順"),
        value: QuestionSelectFieldForResourceEditor.SortFields.ACCURACY_RATE_ASCENDING,
      },
      {
        displayName: t("使用回数が多い順"),
        value: QuestionSelectFieldForResourceEditor.SortFields.NUM_USES_DESCENDING,
      },
      {
        displayName: t("使用回数が少ない順"),
        value: QuestionSelectFieldForResourceEditor.SortFields.NUM_USES_ASCENDING,
      },
    ],
    [t],
  );
  const questionPackageSearchArea = React.useMemo((): SidebarProps["questionPackageSearchArea"] => {
    if (args.target === "remote") {
      return undefined;
    }
    return {
      defaultValues: {
        textFilter: "",
        difficulties: [],
      },
      onChange: fields => {
        QuestionSelectFieldForResourceEditor.updateCurrentQuestionPackagesSearchFilter(fields);
      },
    };
  }, [args.target]);

  const contentProps = React.useMemo((): Widget.QuestionSearchAndSelectableAreaProps["content"] => {
    if (selectedListToDisplay.kind === "ALL_QUESTIONS") {
      return {
        mode: "QUESTION_SEARCH",
        header: {
          SearchArea: <QuestionSearchForResourceEditorContainer name={args.name} target={args.target} />,
          searchResultHeader: {
            resultText: t2("SearchResultCount", { count: numOfQuestions }),
            sortField: {
              defaultValue: QuestionSelectFieldForResourceEditor.SortFields.UNKNOWN_DESCENDING,
              options: sortFieldOptions,
              disabled: false,
              onChange: value => {
                QuestionSelectFieldForResourceEditor.updateSortField(value as QuestionSelectFieldForResourceEditor.SortFieldValue);
              },
            },
          },
        },
        pagination: {
          count: numOfQuestions,
          rowsPerPage: questionsPager.size,
          onRowsPerPageChange: event => {
            const newSize = Math.trunc(Number(event.target.value));
            QuestionSelectFieldForResourceEditor.updateListQuestionPageSize(newSize);
          },
          page: questionsPager.page,
          onPageChange: (_, newPage) => {
            QuestionSelectFieldForResourceEditor.updateListQuestionPage(newPage);
          },
        },
      };
    }

    if (selectedQuestionPackage) {
      const entitySources = selectedQuestionPackage.questionObjects.reduce<Fields.EntitySource.EntitySource[]>((all, { spotQuestion }) => {
        if (spotQuestion) {
          all.push(PrivateHelper.convertSpotQuestionToSource(spotQuestion, isAvailableQuestion));
        }
        return all;
      }, []);
      return {
        mode: "QUESTION_PACKAGE",
        header: {
          title: resolveLanguage(selectedQuestionPackage, lang, "title"),
          description: resolveLanguage(selectedQuestionPackage, lang, "description"),
          shortDetail: {
            difficultyStars: {
              difficulty: selectedQuestionPackage.difficulty,
            },
            questionCount: selectedQuestionPackage.questionObjects.length,
          },
          onReplaceAll: () => {
            const entityTracks = entitySources.map<Fields.EntityTrack.EntityTrack>(entitySource => {
              return {
                type: "FIXED",
                entitySource,
                questionScoreWeight: 1,
              };
            });
            methods.setValue(args.name, entityTracks);
            QuestionSelectFieldForResourceEditor.setOpenQuestionSelectDialog({
              ...questionSelectState,
              openQuestionSelectDialog: false,
            });
          },
        },
      };
    }

    return {
      mode: "QUESTION_PACKAGE",
      header: {
        title: "",
        description: "",
        shortDetail: {
          difficultyStars: {
            difficulty: "UNKNOWN",
          },
          questionCount: 0,
        },
        onReplaceAll: () => undefined,
      },
    };
  }, [
    args.name,
    args.target,
    isAvailableQuestion,
    lang,
    methods,
    numOfQuestions,
    questionSelectState,
    questionsPager.page,
    questionsPager.size,
    selectedListToDisplay.kind,
    selectedQuestionPackage,
    sortFieldOptions,
    t2,
  ]);

  const sidebarProps = React.useMemo((): Widget.QuestionSearchAndSelectableAreaProps["sidebar"] => {
    if (questionSelectState.mode !== "ADD_FIXED") {
      return;
    }
    return {
      questionPackageSearchArea: questionPackageSearchArea,
      showAllQuestionsButton: {
        selected: selectedListToDisplay.kind === "ALL_QUESTIONS",
        onClick: () => {
          QuestionSelectFieldForResourceEditor.selectListToDisplay({
            kind: "ALL_QUESTIONS",
          });
        },
      },
      showQuestionPackage: args.target !== "remote",
      onEndReached: reachedIndex => {
        QuestionSelectFieldForResourceEditor.goNextQuestionPackagesPage(reachedIndex);
      },
      items: questionPackageList.reduce<SidebarListItemProps[]>((all, questionPackage, index) => {
        all.push({
          selected:
            selectedListToDisplay.kind === "QUESTION_PACKAGES" && selectedListToDisplay.questionPackageId === questionPackage.questionPackageId,
          children: [index + 1, resolveLanguage(questionPackage, lang, "title")].join(". "),
          onClick: () => {
            QuestionSelectFieldForResourceEditor.selectListToDisplay({
              kind: "QUESTION_PACKAGES",
              questionPackageId: questionPackage.questionPackageId,
            });
          },
        });
        return all;
      }, []),
    };
  }, [questionSelectState.mode, args.target, lang, questionPackageList, questionPackageSearchArea, selectedListToDisplay]);

  return {
    loading: fetchQuestionsStatus === "FETCHING",
    sidebar: sidebarProps,
    selectableQuestionList: {
      items: questions.reduce<SelectableQuestionListItemProps[]>((all, { spotQuestion, questionVariant, liveCodingQuestion }) => {
        if (args.target === "remote" && liveCodingQuestion) {
          const selected = selectedEntitySourceUniqueKeys.has(liveCodingQuestion.key);
          const variant = questionVariantLabelMap[questionVariant];
          const liveCodingQuestionVariantLabelMap: Record<"AlgorithmQuestion" | "SystemDesignQuestion" | "FreepadQuestion", string> = {
            AlgorithmQuestion: variant,
            SystemDesignQuestion: variant,
            FreepadQuestion: t("ライブコーディング"),
          };
          const leakScore: SelectableQuestionListItemProps["questionMeta"]["leakScore"] = (() => {
            if (!enabledLeakScore) {
              return undefined;
            }
            if (!liveCodingQuestion.isSupportedLeakScore) {
              return { level: "NONE", reason: "NOT_SUPPORTED" };
            }
            if (!liveCodingQuestion.leakScore) {
              return { level: "NONE", reason: "NO_DATA" };
            }
            return { level: liveCodingQuestion.leakScore.level };
          })();
          const item: SelectableQuestionListItemProps = {
            entitySource: PrivateHelper.convertLiveCodingQuestionToSource(liveCodingQuestion),
            disabled: !isAvailableQuestion(questionVariant),
            disabledReason: !isAvailableQuestion(questionVariant) ? t("お使いのプランではこの問題を利用できません") : undefined,
            questionMeta: {
              title: {
                children: [resolveLanguage(liveCodingQuestion, lang, "title"), `(${PrivateHelper.getRemoteVersion(liveCodingQuestion)})`].join(
                  " ",
                ),
                href: PrivateHelper.generateRemoteQuestionDetailPath(liveCodingQuestion),
                onClick: () => {
                  navigate(PrivateHelper.generateRemoteQuestionDetailPath(liveCodingQuestion), {
                    _blank: true,
                  });
                },
              },
              variant: liveCodingQuestionVariantLabelMap[liveCodingQuestion.__typename],
              difficulty: liveCodingQuestion.difficulty,
              isOfficial: liveCodingQuestion.isOfficial,
              numOfUse: liveCodingQuestion.numUses.toString(),
              leakScore: leakScore,
              mark: "NONE",
            },
            selected: selected,
          };
          all.push(item);
          return all;
        }
        if (!spotQuestion) {
          return all;
        }
        const selected = selectedEntitySourceUniqueKeys.has(spotQuestion.key);
        if (mode === "SELECT_FROM_ALL_QUESTIONS" && selected) {
          return all;
        }
        const leakScore: SelectableQuestionListItemProps["questionMeta"]["leakScore"] = (() => {
          if (!enabledLeakScore) {
            return undefined;
          }
          if (!spotQuestion.isSupportedLeakScore) {
            return { level: "NONE", reason: "NOT_SUPPORTED" };
          }
          if (!spotQuestion.leakScore) {
            return { level: "NONE", reason: "NO_DATA" };
          }
          return { level: spotQuestion.leakScore.level };
        })();

        const item: SelectableQuestionListItemProps = {
          entitySource: PrivateHelper.convertSpotQuestionToSource(spotQuestion, isAvailableQuestion),
          disabled: !isAvailableQuestion(questionVariant),
          disabledReason: !isAvailableQuestion(questionVariant) ? t("お使いのプランではこの問題を利用できません") : undefined,
          selected: selected,
          questionMeta: {
            title: {
              children: [resolveLanguage(spotQuestion, lang, "title"), `(${PrivateHelper.getVersion(spotQuestion)})`].join(" "),
              href: PrivateHelper.generateQuestionDetailPath(spotQuestion),
              onClick: () => {
                navigate(PrivateHelper.generateQuestionDetailPath(spotQuestion), {
                  _blank: true,
                });
              },
            },
            variant: questionVariantLabelMap[questionVariant],
            difficulty: spotQuestion.difficulty,
            isOfficial: spotQuestion.isOfficial,
            averageScore: `${formatScore(spotQuestion.accuracyRate).toString()}%`,
            numOfUse: spotQuestion.numUses.toString(),
            timelimit: formatSeconds(spotQuestion.timeLimitSeconds ?? 0, lang),
            leakScore: leakScore,
            mark: "NONE",
          },
        };

        all.push(item);

        return all;
      }, []),
    },
    content: contentProps,
    sortableQuestionList: {
      entitySources: entitySources,
    },
    sortableQuestionListHeader: {
      prefixLabel: prefixLabelMap[questionSelectState.mode],
    },
  };
};
