import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { SupportLanguage } from "@hireroo/i18n";
import * as semver from "semver";
import * as z from "zod";

import * as Question from "./fields/Question";
import { useTimeLimitFieldSchema } from "./fields/TimeLimitField";

export const usePackageDetail = () => {
  const { t } = useTranslation();
  return z.object({
    title: z.string().min(1, { message: t("タイトルは必須項目です。") }),
    description: z.string().min(1, { message: t("詳細は必須です。") }),
  });
};

export type PackageDetailSchema = z.infer<ReturnType<typeof usePackageDetail>>;

export const useQuizPackageForm = () => {
  const { t: t2 } = useTranslationWithVariable();
  const packageDetail = usePackageDetail();
  const timeLimit = useTimeLimitFieldSchema();
  return z
    .object({
      isPublic: z.boolean(),
      timeLimit: timeLimit,
      difficulty: z.union([z.literal("UNKNOWN"), z.literal("EASY"), z.literal("MEDIUM"), z.literal("DIFFICULT")]),
      packageDetails: z.object({
        [SupportLanguage.JA]: packageDetail.optional(),
        [SupportLanguage.EN]: packageDetail.optional(),
      }),
      selectedLanguages: Question.SelectedLanguages,
      needUpdateVersion: z.boolean(),
      newVersion: z.string().regex(/^v([0-9]+)\.([0-9]+)\.([0-9]+)$/, {
        message: t2("ValidateQuestionSetVersion"),
      }),
      oldVersion: z.string(),
    })
    .superRefine((obj, ctx) => {
      const oldVersion = obj.oldVersion.split("-")[0];
      if (!semver.valid(obj.newVersion)) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_string,
          validation: "regex",
          path: ["newVersion"],
          message: t2("ValidateQuestionSetVersion"),
        });
      } else if (obj.needUpdateVersion && !semver.gt(obj.newVersion, oldVersion)) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_small,
          path: ["newVersion"],
          minimum: 5,
          type: "number",
          inclusive: true,
          message: t2("VersionErrorText", { preVersion: oldVersion }),
        });
      }
    });
};

export type QuizPackageFormSchema = z.infer<ReturnType<typeof useQuizPackageForm>>;
