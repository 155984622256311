import { useTranslation } from "@hireroo/i18n";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import EvaluationItem, { EvaluationItemProps } from "./parts/EvaluationItem/EvaluationItem";

export type ConfirmationProps = {
  isRecommended: boolean;
  comment: string;
  evaluationItems: EvaluationItemProps[];
};

const Confirmation: React.FC<ConfirmationProps> = props => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column">
      <Stack direction="column" spacing={2} mb={3}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="subtitle2" color="textSecondary" sx={{ wordBreak: "keep-all" }}>{`${t("合否判定")}:`}</Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            {props.isRecommended ? (
              <>
                <ThumbUpIcon color="success" fontSize="small" />
                <Typography variant="subtitle2" sx={{ color: "success.main" }} color="success">
                  {t("合格")}
                </Typography>
              </>
            ) : (
              <>
                <ThumbDownIcon color="error" fontSize="small" />
                <Typography variant="subtitle2" color="error">
                  {t("不合格")}
                </Typography>
              </>
            )}
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2} display="flex">
          <Typography variant="subtitle2" color="textSecondary" sx={{ wordBreak: "keep-all" }}>{`${t("合否理由")}:`}</Typography>
          <Typography component="div" variant="body2" sx={{ overflowWrap: "anywhere", maxWidth: "70%" }}>
            {props.comment}
          </Typography>
        </Stack>
      </Stack>
      <Grid container spacing={{ xs: 2 }} mt={2}>
        {props.evaluationItems.map(evaluationItem => (
          <EvaluationItem key={evaluationItem.id} {...evaluationItem} />
        ))}
      </Grid>
    </Box>
  );
};

Confirmation.displayName = "Confirmation";

export default Confirmation;
