import { App, Auth, Company } from "@hireroo/app-store/essential/talent";
import { TalentHeaderRightContent } from "@hireroo/app-store/widget/t/TalentHeaderRightContent";
import { formatTimeDistance } from "@hireroo/formatter/time";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import { useTransitionNavigateWithRawUri } from "@hireroo/router/hooks";
import React from "react";

export type GenerateNotificationListPropsArgs = {};

export const useGenerateProps = (_args: GenerateNotificationListPropsArgs): Widget.NotificationListProps => {
  const [now] = React.useState(new Date());
  const appStatus = App.useStatus();
  const companyId = Company.useStrictActiveCompanyId();
  const talentNotifications = TalentHeaderRightContent.useUnreadTalentNotifications();
  const hasNext = TalentHeaderRightContent.useHasNext();
  const talentId = Auth.useCurrentUid();
  const lang = useLanguageCode();
  const client = getGraphqlClient();
  const navigate = useTransitionNavigateWithRawUri();
  const count = TalentHeaderRightContent.useUnreadNotificationCount();
  const nextCursor = TalentHeaderRightContent.useNextCursor();
  const fetchStatus = TalentHeaderRightContent.useFetchStatus();

  const [result] = Graphql.useListTalentNotificationsForNotificationListForTalentQuery({
    variables: {
      companyId: companyId,
      talentId: talentId,
      /**
       * When there are more than 11 Notification Count Symbol, it becomes `10+` and does not count any more.
       *
       * In other words, if 11 messages can be retrieved, the user can recognize that there are more than 11 messages.
       */
      size: 11,
      cursor: nextCursor ?? null,
      withCount: true,
    },
    requestPolicy: "cache-and-network",
    pause: appStatus !== "INITIALIZED" || fetchStatus === "STOP",
  });

  React.useEffect(() => {
    if (result.data?.talentNotifications) {
      TalentHeaderRightContent.append(result.data.talentNotifications);
    }
  }, [result.data?.talentNotifications]);

  return {
    count,
    items: talentNotifications.map(notification => {
      const distance = notification.createdAtSeconds ? formatTimeDistance(new Date(notification.createdAtSeconds * 1000), now, lang) : "";
      return {
        id: notification.talentNotificationId.toString(),
        title: resolveLanguage(notification, lang, "title"),
        distance: distance,
        onClick: async () => {
          await client
            .ReadTalentNotificationsForNotificationListForTalent({
              companyId: notification.companyId,
              talentId: notification.talentId,
              talentNotificationIds: [notification.talentNotificationId],
            })
            .then(() => {
              TalentHeaderRightContent.read(notification.talentNotificationId);
              // Parse the path from the action link, excluding the domain
              const url = new URL(notification.actionLink);
              navigate(url.pathname);
            });
        },
      };
    }),
    hasNext: hasNext,
    allReadButton: {
      onClick: () => {
        client
          .ReadTalentNotificationsForNotificationListForTalent({
            companyId: companyId,
            talentId: talentId,
            talentNotificationIds: talentNotifications.map(({ talentNotificationId }) => talentNotificationId),
          })
          .then(res => {
            const readNotificationIds = res.readTalentNotifications.map(notification => notification.talentNotificationId);
            TalentHeaderRightContent.readAll(readNotificationIds);
          });
      },
    },
    onEndReached: () => {
      TalentHeaderRightContent.goNextPage();
    },
  };
};
