import React from "react";
import { CreateLocalTrackOptions, LocalParticipant, LocalVideoTrack } from "twilio-video";

/**
 * This hook toggles the local video track.
 */
export const useLocalVideoToggle = (
  videoTrack: LocalVideoTrack | undefined,
  getLocalVideoTrack: (newOptions?: CreateLocalTrackOptions) => Promise<LocalVideoTrack>,
  removeLocalVideoTrack: () => void,
  localParticipant: LocalParticipant | undefined,
) => {
  const [status, setStatus] = React.useState<"PUBLISHING" | "PUBLISHED">("PUBLISHED");

  const toggle = React.useCallback(() => {
    if (status === "PUBLISHED") {
      if (videoTrack) {
        const localTrackPublication = localParticipant?.unpublishTrack(videoTrack);
        // TODO: Remove when SDK implements this event. See: https://github.com/twilio/twilio-video.js/issues/1007
        localParticipant?.emit("trackUnpublished", localTrackPublication);
        removeLocalVideoTrack();
      } else {
        setStatus("PUBLISHING");
        getLocalVideoTrack()
          .then((track: LocalVideoTrack) => {
            localParticipant?.publishTrack(track);
          })
          .finally(() => setStatus("PUBLISHED"));
      }
    }
  }, [videoTrack, localParticipant, getLocalVideoTrack, status, removeLocalVideoTrack]);

  return { isEnabled: !!videoTrack, toggle };
};
