import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";

import RestrictableTab, { RestrictableTabProps, TabItem } from "../../modules/RestrictableTab/RestrictableTab";
import { StatusInfoProps } from "../../modules/StatusInfo/StatusInfo";
import TestEvaluationDialog, { TestEvaluationDialogProps } from "../../modules/TestEvaluationDialog/TestEvaluationDialog";
import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";
import DeletionDialog, { DeletionDialogProps } from "./parts/DeletionDialog/DeletionDialog";
import DetailHeader, { DetailHeaderProps } from "./parts/DetailHeader/DetailHeader";

const PAGE_HEADER_HEIGHT = 48;
const PAGE_HEADER_V2_HEIGHT = 80;
const TAB_HEIGHT = 43;
const NON_CONTENT_HEIGHT = 72;

/**
 * 72px was calculated from the non-content height of EmployeeLayout
 */
const StyledBox = styled(Box)(({ theme }) => ({
  height: `calc(100vh - ${NON_CONTENT_HEIGHT}px)`,
  overflow: "hidden",
  backgroundColor: theme.palette["Background/Paper"].p2,
  minWidth: 900,
}));

const ContentWrapper = styled(Box)(() => ({
  overflowY: "auto",
  overflowX: "hidden",
}));

export type RemoteInterviewDetailProps = {
  defaultTabId?: string;
  statusInfo?: StatusInfoProps;
  header: DetailHeaderProps;
  items: TabItem[];
  evaluationDialog: TestEvaluationDialogProps;
  deletionDialog: DeletionDialogProps;
  startDialog: DialogWithCloseIconProps;
  Prompt?: React.ReactNode;
  scrollable: boolean;
};

const RemoteInterviewDetail: React.FC<RemoteInterviewDetailProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const restrictableTabProps: RestrictableTabProps = {
    defaultTab: props.defaultTabId,
    items: props.items,
    tabs: {
      "aria-label": "test-report-tabs",
      sx: {
        "&.MuiTabs-root": {
          minHeight: `${TAB_HEIGHT}px`,
          height: `${TAB_HEIGHT}px`,
        },
        "& .MuiTab-root": {
          minHeight: `${TAB_HEIGHT}px`,
          height: `${TAB_HEIGHT}px`,
        },
        "& .MuiTab-root.Mui-selected": {
          color: theme.palette.secondary.main,
          backgroundColor: "inherit",
        },
      },
    },
    tabBox: {
      sx: {
        paddingLeft: "24px",
        paddingRight: "24px",
      },
    },
    wrapper: {},
    tabPanelBox: {
      pt: 0,
      sx: {
        bgcolor: theme.palette.background.default,
        minHeight: `calc(100vh - ${PAGE_HEADER_HEIGHT + TAB_HEIGHT + NON_CONTENT_HEIGHT}px)`,
      },
    },
    variant: "OUTLINED",
  };

  const startDialog: DialogWithCloseIconProps = {
    ...props.startDialog,
    yesButton: {
      ...props.startDialog.yesButton,
      color: "secondary",
      children: t("開始する"),
    },
    noButton: {
      ...props.startDialog.noButton,
      onClick: () => {
        props.startDialog?.onClose?.();
      },
      children: t("キャンセル"),
      color: "outline-only",
    },
  };

  return (
    <StyledBox>
      <DetailHeader {...props.header} />
      <ContentWrapper
        id="report-content-wrapper"
        sx={{
          height: `calc(100% - ${!props.scrollable ? 0 : PAGE_HEADER_V2_HEIGHT}px)`,
        }}
      >
        <RestrictableTab {...restrictableTabProps} />
      </ContentWrapper>
      <TestEvaluationDialog key={`open-${props.evaluationDialog.open.toString()}`} {...props.evaluationDialog} />
      <DeletionDialog {...props.deletionDialog} />
      <DialogWithCloseIcon {...startDialog}>
        {t("「開始する」ボタンを押すことでインタビューが開始されます。開始後より候補者が出題されている問題の閲覧・回答が可能となります。")}
      </DialogWithCloseIcon>
      {props.Prompt}
    </StyledBox>
  );
};

RemoteInterviewDetail.displayName = "RemoteInterviewDetail";

export default RemoteInterviewDetail;
