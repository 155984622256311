import { App, Company } from "@hireroo/app-store/essential/employee";
import { TalentMemberSettings } from "@hireroo/app-store/widget/e/TalentMemberSettings";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import TalentMemberSettingsContainer, { TalentMemberSettingsContainerProps } from "./Container";

export type TalentMemberSettingsFetchContainerProps = {};

const TalentMemberSettingsFetchContainer: React.FC<TalentMemberSettingsFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const companyId = Company.useStrictActiveCompanyId();
  const [result, refresh] = Graphql.useGetCompanyForTalentMemberSettingsQuery({
    variables: {
      companyId: companyId,
    },
    requestPolicy: "network-only",
    pause: appStatus !== "INITIALIZED",
  });
  const initialized = TalentMemberSettings.useInitialized();
  React.useEffect(() => {
    if (result.data) {
      TalentMemberSettings.setCompany(result.data.company);
    }
  }, [result.data]);

  React.useEffect(() => {
    return () => {
      TalentMemberSettings.clear();
    };
  }, []);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: TalentMemberSettingsContainerProps = {
    reload: refresh,
  };

  return <TalentMemberSettingsContainer {...containerProps} />;
};

TalentMemberSettingsFetchContainer.displayName = "TalentMemberSettingsFetchContainer";

export default withErrorBoundary(TalentMemberSettingsFetchContainer, {});
