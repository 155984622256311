import type { SizeInput } from "../calculator";
import type * as Types from "../types";

type ConvertPairPropsToSizeInputsArgs = {
  items: Types.PairProps[];
  defaultPanePixelSize: number;
};

export const convertPairPropsToSizeInputs = (args: ConvertPairPropsToSizeInputsArgs): SizeInput[] => {
  const { items, defaultPanePixelSize } = args;
  return items.reduce<SizeInput[]>((acc, child, index) => {
    acc.push({
      id: child.id,
      type: "CONTENT",
      size: child.size,
    });
    const isFinal = index === items.length - 1;
    if (!isFinal) {
      acc.push({
        type: "PANE",
        id: child.id,
        size: child.sidePane?.size
          ? child.sidePane.size
          : {
              value: defaultPanePixelSize,
              unit: "px",
            },
      });
    }
    return acc;
  }, []);
};
