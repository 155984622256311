import { useTranslation } from "@hireroo/i18n";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import NativeSelect, { NativeSelectProps } from "@mui/material/NativeSelect";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import type { ComponentType } from "../../../features";
import { isValidComponentType } from "../../../helpers/flowChart";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  borderRadius: 4,
  position: "relative",
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.paper,
  fontSize: "0.9rem",
  height: "2.4em",
  padding: "10px 0px 10px 12px",
  transition: theme.transitions.create(["border-color", "box-shadow"]),
  "&:focus": {
    borderRadius: 4,
    borderColor: "#80bdff",
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
  },
}));

const useComponentTypeMap = (selectableComponentTypes: ComponentType[]): Record<string, string> => {
  const { t } = useTranslation();
  const componentTypeMap: Record<ComponentType, string> = {
    default: t("デフォルト"),
    aws: "AWS",
    gcp: "GCP",
    azure: "Azure",
  };
  return Array.from(selectableComponentTypes).reduce((all, key) => {
    if (!isValidComponentType(key)) {
      return all;
    }
    return { ...all, [key]: componentTypeMap[key] };
  }, {});
};

export type StatelessComponentTypeSelectorProps = {
  value: ComponentType;
  selectableComponentTypes: ComponentType[];
  onChange?: (componentType: ComponentType) => void;
};

const StatelessComponentTypeSelector: React.FC<StatelessComponentTypeSelectorProps> = props => {
  const { onChange, value, selectableComponentTypes } = props;
  const componentTypeMap = useComponentTypeMap(selectableComponentTypes);
  const { t } = useTranslation();
  const selectProps: NativeSelectProps = {
    /* The className is used for tutorial. */
    className: "component-type-selector",
    input: <BootstrapInput />,
    value,
    onChange: event => {
      if (isValidComponentType(event.target.value)) {
        onChange?.(event.target.value);
      }
    },
    IconComponent: KeyboardArrowDownOutlinedIcon,
    sx: {
      bgcolor: "inherit",
    },
  };
  if (Object.keys(componentTypeMap).length === 0) {
    return (
      <Stack direction="column" alignItems="center" justifyContent="center">
        <Typography variant="body2">{t("使用可能なリソース種別がありませんでした。")}</Typography>
        <Typography variant="caption">{t("ヘルプより運営にお問い合わせください。")}</Typography>
      </Stack>
    );
  }

  return (
    <FormControl>
      <NativeSelect {...selectProps}>
        {Object.entries(componentTypeMap).map(([componentType, componentTypeValue]) => (
          <option key={componentType} value={componentType}>
            {componentTypeValue}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );
};

StatelessComponentTypeSelector.displayName = "StatelessComponentTypeSelector";

export default StatelessComponentTypeSelector;
