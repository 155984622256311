import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { useTitle } from "@hireroo/app-helper/react-use";
import { Auth } from "@hireroo/app-store/essential/employee";
import { QuestionQuizPackageResourceEditor } from "@hireroo/app-store/view-domain/QuestionQuizPackageResourceEditor";
import { QuizPackageDetail } from "@hireroo/app-store/widget/e/QuizPackageDetail";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useTranslation } from "@hireroo/i18n";
import { type Pages } from "@hireroo/presentation/legacy";
import { updateQueryParams } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/browser";
import * as React from "react";

import { useGenerateQuizResourceEditorProps } from "../../../props-factory/e/QuizResourceEditor/useGenerateQuizResourceEditorProps";

export type GenerateQuizResourceEditorPropsArgs = {
  companyId: number;
};

export const useGenerateProps = (args: GenerateQuizResourceEditorPropsArgs): Pages.QuizResourceEditorProps => {
  const client = getGraphqlClient();
  const { t } = useTranslation();
  const userId = Auth.useCurrentUid();
  const getQuestionKeys = QuestionQuizPackageResourceEditor.useGenerateOrderedQuestionKeys();
  const current = QuizPackageDetail.useCurrentUpdateMultiChoicePackage();
  const navigate = useTransitionNavigate();
  const selectedPrivateQuestions = QuestionQuizPackageResourceEditor.useSelectedPrivateQuestions();
  useTitle(`${t("問題編集")}(${t("クイズ形式")})`);

  return useGenerateQuizResourceEditorProps({
    companyId: args.companyId,
    saveSelectQuestionsButton: {
      onClick: React.useCallback(() => {
        const isPublishedPackage = current.status === "PUBLISHED";
        client
          .UpdateMultiChoicePackage({
            updateMultiChoicePackage: {
              id: current.packageId,
              employeeId: userId,
              companyId: args.companyId,
              questionKeys: getQuestionKeys(),
              // The following has already been
              titleJa: current.titleJa,
              titleEn: current.titleEn,
              descriptionJa: current.descriptionJa,
              descriptionEn: current.descriptionEn,
              difficulty: current.difficulty,
              isPrivate: current.isPrivate,
              status: "DRAFT",
              newVersion: isPublishedPackage ? `${current.version}-${Date.now()}` : current.version,
              oldVersion: current.version,
              timeLimitSeconds: current.timeLimitSeconds,
            },
          })
          .then(res => {
            if (isPublishedPackage) {
              updateQueryParams("version", [res.updateMultiChoicePackage.version]);
            }
            QuizPackageDetail.setUpdatingMultiChoicePackage(res.updateMultiChoicePackage);
            Snackbar.notify({
              severity: "success",
              message: t("問題の一時保存が完了しました。"),
            });
            QuestionQuizPackageResourceEditor.updateActiveStep(QuestionQuizPackageResourceEditor.STEP.PACKAGE_DETAIL);
          })
          .catch(error => {
            Sentry.captureException(error);
            const errorNotification = ErrorHandlingHelper.generateErrorNotification(
              error,
              // TODO 選択されている非公開の設問を全て「除く」か「公開」に変更してください。
              t("問題の一時保存に失敗しました。しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
            );
            Snackbar.notify({
              severity: "error",
              message: errorNotification.message,
            });
          });
      }, [
        args.companyId,
        client,
        current.descriptionEn,
        current.descriptionJa,
        current.difficulty,
        current.isPrivate,
        current.packageId,
        current.status,
        current.timeLimitSeconds,
        current.titleEn,
        current.titleJa,
        current.version,
        getQuestionKeys,
        t,
        userId,
      ]),
    },
    onSubmitQuizPackageForm: React.useCallback(
      fields => {
        if (fields.isPublic && selectedPrivateQuestions.length > 0) {
          QuestionQuizPackageResourceEditor.openWarningDialog();
          return;
        }

        client
          .UpdateMultiChoicePackage({
            updateMultiChoicePackage: {
              id: current.packageId,
              employeeId: userId,
              companyId: args.companyId,
              titleJa: fields.packageDetails.ja?.title,
              titleEn: fields.packageDetails.en?.title,
              descriptionJa: fields.packageDetails.ja?.description,
              descriptionEn: fields.packageDetails.en?.description,
              difficulty: fields.difficulty,
              isPrivate: !fields.isPublic,
              status: "REVIEWING",
              newVersion: fields.newVersion,
              oldVersion: fields.oldVersion,
              questionKeys: getQuestionKeys(),
              timeLimitSeconds: fields.timeLimit * 60,
            },
          })
          .then(res => {
            QuizPackageDetail.setUpdatingMultiChoicePackage(res.updateMultiChoicePackage);
            const isReviewing = res.updateMultiChoicePackage.status === "REVIEWING";
            const isPrivate = res.updateMultiChoicePackage.isPrivate;
            if (isReviewing && isPrivate) {
              Snackbar.notify({
                severity: "success",
                message: t("問題の保存が完了しました。"),
              });
            } else if (isPrivate) {
              Snackbar.notify({
                severity: "success",
                message: t("問題の保存が完了しました。審査が完了するまでしばらくお待ち下さい。"),
              });
            } else {
              Snackbar.notify({
                severity: "success",
                message: t("問題の一時保存が完了しました。"),
              });
            }
            navigate("/e/questions");
          })
          .catch(error => {
            Sentry.captureException(error);
            const errorNotification = ErrorHandlingHelper.generateErrorNotification(
              error,
              t("問題の一時保存に失敗しました。しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
            );
            Snackbar.notify({
              severity: "error",
              message: errorNotification.message,
            });
          });
      },
      [args.companyId, client, current.packageId, getQuestionKeys, navigate, selectedPrivateQuestions.length, t, userId],
    ),
  });
};
