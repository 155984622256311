import type { Pages } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";

import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import FooterContainer from "../../../../widget/v2/t/Footer/Container";
import { useNotificationBanner } from "../../../../widget/v2/t/NotificationBanner/Container";
import TalentHeaderRightContentContainer from "../../../../widget/v2/t/TalentHeaderRightContent/Container";
import TalentSideBarContentContainer from "../../../../widget/v2/t/TalentSideBarContent/Container";
import TestReviewFetchContainer from "./widget/TestReview/FetchContainer";

export type GenerateTalentTestReviewPropsArgs = {};

export const useGenerateProps = (_args: GenerateTalentTestReviewPropsArgs): Pages.TalentTestReviewProps => {
  const navigate = useTransitionNavigate();
  const NotificationBannerContainer = useNotificationBanner();
  return {
    layout: {
      loading: false,
      NotificationBanner: NotificationBannerContainer,
      HeaderRightContent: <TalentHeaderRightContentContainer />,
      SideBarContent: <TalentSideBarContentContainer />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      logo: {
        href: generatePath("/t/assessments"),
        onClick: () => {
          navigate("/t/assessments");
        },
      },
    },
    children: <TestReviewFetchContainer />,
  };
};
