import { useTranslation } from "@hireroo/i18n";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import Box from "@mui/material/Box";
import Button, { type ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import QuestionPackageShortDetail, {
  QuestionPackageShortDetailProps,
} from "../../../../ms-components/Question/QuestionPackageShortDetail/QuestionPackageShortDetail";

const DescriptionWrapper = styled(Box)(() => ({
  maxHeight: 72,
  overflowY: "auto",
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: 16,
  marginRight: theme.spacing(2),
}));

const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 12,
}));

const StyledButton = styled(Button)(() => ({
  minWidth: "unset",
  minHeight: "unset",
  padding: 0,
  fontSize: 13,
  wordBreak: "keep-all",
  whiteSpace: "nowrap",
}));

export type QuestionPackageHeaderProps = {
  title: string;
  shortDetail: QuestionPackageShortDetailProps;
  description: string;

  onReplaceAll: () => void;
};

const QuestionPackageHeader: React.FC<QuestionPackageHeaderProps> = props => {
  const { t } = useTranslation();
  const replaceButton: ButtonProps = {
    color: "secondary",
    children: t("置き換える"),
    endIcon: <CachedOutlinedIcon />,
    variant: "text",
    onClick: event => {
      event.preventDefault();
      event.stopPropagation();
      props.onReplaceAll();
    },
  };
  return (
    <Stack spacing={1} direction="column" p={1.5}>
      <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "center" }}>
        <Title>{props.title}</Title>
        <StyledButton {...replaceButton} />
      </Stack>
      <DescriptionWrapper>
        <Description>{props.description}</Description>
      </DescriptionWrapper>
      <QuestionPackageShortDetail {...props.shortDetail} />
    </Stack>
  );
};

QuestionPackageHeader.displayName = "QuestionPackageHeader";

export default QuestionPackageHeader;
