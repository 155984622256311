import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import * as React from "react";

export type ExpandMoreButtonProps = IconButtonProps & {
  expand: boolean;
};

const ExpandMore = styled((props: ExpandMoreButtonProps) => {
  const { expand: _, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ExpandMoreButton: React.FC<ExpandMoreButtonProps> = props => {
  return <ExpandMore {...props} />;
};

ExpandMoreButton.displayName = "ExpandMoreButton";

export default ExpandMoreButton;
