import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import { TotalScoreRankVisualizer } from "@hireroo/app-store/widget/shared/TotalScoreRankVisualizer";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import { useGenerateHeaderProps } from "../../../../props-factory/v2/view-domain/ProjectTestReport/useGenerateHeaderProps";
import { useGenerateQuestionDetailProps } from "../../../../props-factory/v2/view-domain/ProjectTestReport/useGenerateQuestionDetailProps";
import ProjectTestReportContainer, { ProjectTestReportContainerProps } from "./Container";
import { useStatusMessageMap } from "./privateHelper";
import * as Subscriber from "./Subscriber";

export type ProjectTestReportFetchContainerProps = {
  featureKind: "test" | "exam";
  companyId: number;
  projectId: number;
  testId: string;
  reportSettings: Graphql.AssessmentReportSettings | null;
};

const ProjectTestReportFetchContainer: React.FC<ProjectTestReportFetchContainerProps> = props => {
  //TODO: @himenon implement get challenge when server side JoinForSpot is removed
  const { projectId, companyId } = props;

  const initialized = ProjectTestReport.useInitialized(projectId);
  const hooks = ProjectTestReport.useCreateProjectHooks(projectId);
  const question = hooks.useQuestion();
  const currentSubmission = hooks.useCurrentSubmission();
  const submissionStatus = hooks.useCurrentSubmissionStatus();
  const header = useGenerateHeaderProps({ entityId: projectId });
  const messageMap = useStatusMessageMap();
  const status = hooks.useStatus();
  const questionDetail = useGenerateQuestionDetailProps({
    entityId: projectId,
    showAnswer: props.reportSettings?.showAnswer ?? false,
    showArchivedMark: false,
  });

  const uniqueKey = React.useMemo((): ProjectTestReport.UniqueKey | null => {
    if (!currentSubmission) {
      return null;
    }
    /**
     * Multiple submissions and unique by EntityId
     */
    return `Project-${projectId}-${currentSubmission.projectSubmissionId}`;
  }, [currentSubmission, projectId]);
  React.useEffect(() => {
    if (!currentSubmission || !uniqueKey || !question || (submissionStatus !== "EVALUATED" && submissionStatus !== "FAILED")) {
      return;
    }
    TotalScoreRankVisualizer.createTotalScoreStatisticsState({
      uniqueKey: uniqueKey,
      query: {
        endDate: currentSubmission?.evaluatedAtSeconds ? new Date(currentSubmission.evaluatedAtSeconds * 1000) : null,
      },
    });
    const stopSubscribeQueryKey = Subscriber.startSubscribeQueryKey({
      uniqueKey,
      entityId: projectId,
      companyId: companyId,
      submissionId: currentSubmission.projectSubmissionId,
      questionId: question.questionId,
    });
    const stopSubscribeProjectSubmission = Subscriber.startSubscribeProjectSubmission(projectId);
    TotalScoreRankVisualizer.refreshQuery(uniqueKey);
    return () => {
      stopSubscribeQueryKey?.();
      stopSubscribeProjectSubmission();
    };
  }, [companyId, currentSubmission, projectId, question, submissionStatus, uniqueKey]);

  React.useEffect(() => {
    return () => {
      /**
       * need to clear entity on unmount because the diff editor will mount again unintentionally
       */
      ProjectTestReport.clearEntity(projectId);
    };
  }, [projectId]);

  if (!initialized) {
    return <Widget.Loading kind="TOP" />;
  }

  if (!currentSubmission || !uniqueKey) {
    const projectProps: Widget.ProjectTestReportProps = {
      header: header,
      questionDetail: questionDetail,
      announcement: messageMap[status],
    };
    return <Widget.ProjectTestReport {...projectProps} />;
  }

  const containerProps: ProjectTestReportContainerProps = {
    featureKind: props.featureKind,
    entityId: projectId,
    uniqueKey,
    testId: props.testId,
    reportSettings: props.reportSettings,
  };

  return (
    <ErrorBoundary>
      <ProjectTestReportContainer {...containerProps} />
    </ErrorBoundary>
  );
};

ProjectTestReportFetchContainer.displayName = "ProjectTestReportFetchContainer";

export default withErrorBoundary(ProjectTestReportFetchContainer, {});
