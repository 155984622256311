import { useTranslation } from "@hireroo/i18n";
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";

import InputControlTextField, { InputControlTextFieldProps } from "../../../primitive/InputControl/InputControlTextField";
import { useAlgorithmHintsFormContext } from "../Context";

export type VersionDialogProps = {
  open: boolean;
  closeButton: Pick<ButtonProps, "onClick" | "disabled">;
  saveButton: LoadingButtonProps;
  oldVersion: string;
};

const VersionDialog: React.FC<VersionDialogProps> = props => {
  const { t } = useTranslation();
  const { methods } = useAlgorithmHintsFormContext();
  const newVersionTxtField: InputControlTextFieldProps = {
    type: "string",
    color: "primary",
    label: t("バージョン"),
    fullWidth: true,
    helperText: t("編集前のバージョン") + " : " + props.oldVersion,
    InputLabelProps: {
      shrink: true,
    },
    required: true,
    control: methods.control,
  };
  const saveButton: LoadingButtonProps = {
    ...props.saveButton,
    variant: "contained",
    color: "primary",
    size: "small",
  };
  return (
    <Dialog open={props.open} onClose={props.closeButton.onClick}>
      <DialogTitle>{t("編集後の問題のバージョンを設定してください。")}</DialogTitle>
      <DialogContent>
        <Box mt={1} />
        <InputControlTextField name="newVersion" {...newVersionTxtField} />
      </DialogContent>
      <DialogActions>
        <Button {...props.closeButton}>{t("キャンセル")}</Button>
        <LoadingButton {...saveButton}>{t("保存する")}</LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

VersionDialog.displayName = "VersionDialog";

export default VersionDialog;
