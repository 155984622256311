import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import * as React from "react";

import InvitationHeader from "./parts/InvitationHeader/InvitationHeader";

const HEADER_HEIGHT = 48;
const ADJUST_HEIGHT = 1;

const StyledHeaderWrapper = styled(Box)(({ theme }) => ({
  overflow: "hidden",
  height: HEADER_HEIGHT,
  backgroundColor: theme.palette.background.default,
}));

export type InvitationLayoutProps = {
  loading?: boolean;
  Snackbar: React.ReactNode;
};

const InvitationLayout: React.FC<React.PropsWithChildren<InvitationLayoutProps>> = props => {
  return (
    <Box>
      {props.loading && <LinearProgress sx={{ width: "100%", position: "fixed", zIndex: 2000, top: 0 }} />}
      <StyledHeaderWrapper>
        <InvitationHeader />
      </StyledHeaderWrapper>
      <Box sx={{ height: `calc(100vh - ${HEADER_HEIGHT + ADJUST_HEIGHT}px)` }} overflow="auto">
        {props.children}
      </Box>
      {props.Snackbar}
    </Box>
  );
};

InvitationLayout.displayName = "InvitationLayout";

export default InvitationLayout;
