import * as Graphql from "@hireroo/graphql/client/urql";
import { ref } from "valtio";

import { defaultState } from "./constants";
import * as Helper from "./helper";
import { state } from "./State";
import type * as Types from "./types";

export type QueryParams = {
  size: string | null;
  page: string | null;
  tagNames: string[];
  name: string | null;
  result: string | null;
  statuses: string[];
  rankEvaluations: string[];
  /** 0 ~ 1 */
  minScore: string | null;
  /** 0 ~ 1 */
  maxScore: string | null;
  isDescending: string | null;
  sortMethod: string | null;
  suspiciousDegrees: string[];
};

export const setQueryParams = (queryParams: QueryParams) => {
  state.listParams = {
    isDescending: Helper.convertStringToIsDesc(queryParams.isDescending) ?? true,
    sortMethod: Helper.convertStringToSortMethod(queryParams.sortMethod),
    page: Helper.convertStringToPage(queryParams.page),
    size: Helper.convertStringToSize(queryParams.size),
    filters: {
      tagNames: Helper.convertStringsToTagNames(queryParams.tagNames),
      name: queryParams.name ?? "",
      result: Helper.convertStringToResult(queryParams.result),
      statuses: Helper.convertStringsToStatuses(queryParams.statuses),
      rankEvaluations: Helper.convertStringsToRankEvaluations(queryParams.rankEvaluations),
      minTotalScore: Helper.convertStringToTotalScore(queryParams.minScore),
      maxTotalScore: Helper.convertStringToTotalScore(queryParams.maxScore),
      minCustomScore: Helper.convertStringToTotalScore(queryParams.minScore),
      maxCustomScore: Helper.convertStringToTotalScore(queryParams.maxScore),
      // TODO: implement
      variableEntries: [],
      suspiciousDegrees: Helper.convertStringsToSuspiciousDegrees(queryParams.suspiciousDegrees),
    },
  };
};

export const clear = () => {
  state.listParams = defaultState.listParams;
  state.res = null;
  state.spotMap.clear();
  state.selectedSpotIds = [];
  state.initialized = false;
};

export type InitializeArgs = {
  spotTags: Types.SpotTags;
  queryParams: QueryParams;
  searchTags: string[];
};

export const initialize = (args: InitializeArgs) => {
  const { queryParams } = args;
  state.spotTags = args.spotTags;
  state.searchTags = args.searchTags;
  state.initialized = true;
  setQueryParams(queryParams);
};

export const updateSize = (newValue: number): void => {
  state.listParams = {
    ...state.listParams,
    size: newValue,
  };
};

export const updatePage = (newValue: number): void => {
  state.listParams = {
    ...state.listParams,
    page: newValue,
  };
};

export const clampPage = (): void => {
  if (state.count <= state.listParams.page * state.listParams.size) {
    // (e.g.) count=60, size=20 => the maximum page number is 2
    // (e.g.) count=57, size=20 => the maximum page number is 2
    // N.B. Page number starts with 0. (https://mui.com/material-ui/api/table-pagination/)
    // page should be gte 1, so take max compared to 1
    updatePage(Math.max(1, Math.ceil(state.count / state.listParams.size)) - 1);
  }
};

export const updateIsDescending = (newValue: boolean): void => {
  state.listParams = {
    ...state.listParams,
    isDescending: newValue,
  };
};

export const updateSortMethod = (newValue: Graphql.SpotsByCompanyIdSortMethod): void => {
  state.listParams = {
    ...state.listParams,
    sortMethod: newValue,
  };
};

export const updateFilters = (newFilters: Partial<Graphql.SpotFiltersByScreeningInput>): void => {
  state.listParams = {
    ...state.listParams,
    filters: {
      ...state.listParams.filters,
      ...newFilters,
    },
  };
};

export const updateSearchTags = (newSearchTags: string[]): void => {
  state.searchTags = newSearchTags;
};

export const updateCount = (newValue: number) => {
  state.count = newValue;
};

export const resetFilters = () => {
  state.listParams = {
    ...defaultState.listParams,
  };
  state.searchTags = [];
};

export const updateListFetchingStatus = (status: Types.ListFetchingStatus) => {
  state.listFetchingStatus = status;
};

export const updateResponse = (res: Types.SpotsByScreeningIdResponse) => {
  state.res = res;
  res.spots.forEach(spot => {
    state.spotMap.set(spot.spotId, spot);
  });
};

export const deleteSpots = (spotIds: Types.SpotId[]) => {
  spotIds.forEach(id => {
    state.spotMap.delete(id);
  });
  if (state.res) {
    state.res.spots = state.res.spots.filter(spot => !spotIds.includes(spot.spotId));
  }
};

export const updateDialogStatus = (status: Types.DialogStatus) => {
  state.dialogStatus = status;
};

export const updateSelectedSpotIds = (ids: Types.SpotId[]) => {
  state.selectedSpotIds = ids;
};

export const updateSpots = (spots: Types.Spot[]) => {
  spots.forEach(spot => {
    state.spotMap.set(spot.spotId, spot);
  });
};
export const setTagRefresh = (refresh: () => void) => {
  state.tagRefresh = ref(refresh);
};
export const tagRefresh = () => {
  state.tagRefresh?.();
};
export const updateRefreshKey = () => {
  state.refreshKey = Math.random().toString(36);
};
export const clearSelectedSpotIds = () => {
  state.selectedSpotIds = [];
  updateRefreshKey();
};

export const updateSpot = (newSpot: Types.Spot) => {
  state.spotMap.set(newSpot.spotId, newSpot);
  if (!state.res) {
    return;
  }
  const index = state.res.spots.findIndex(spot => spot.spotId === newSpot.spotId);
  if (index >= 0) {
    state.res.spots[index] = newSpot;
  }
};
