import Box from "@mui/material/Box";
import * as React from "react";

import ReadonlyQuizQuestionEvaluation, {
  ReadonlyQuizQuestionFreeTextProps,
} from "../ReadonlyQuizQuestionFreeText/ReadonlyQuizQuestionFreeText";
import ReadonlyQuizQuestionMultiChoice, {
  ReadonlyQuizQuestionMultiChoiceProps,
} from "../ReadonlyQuizQuestionMultiChoice/ReadonlyQuizQuestionMultiChoice";
import ReadonlyQuizQuestionSingleChoice, {
  ReadonlyQuizQuestionSingleChoiceProps,
} from "../ReadonlyQuizQuestionSingleChoice/ReadonlyQuizQuestionSingleChoice";

export type EvaluationContentProps = {
  kind: "FREE_TEXT";
  quizContent: ReadonlyQuizQuestionFreeTextProps;
};

export type MultiChoiceContentProps = {
  kind: "MULTI_CHOICE";
  quizContent: ReadonlyQuizQuestionMultiChoiceProps;
};

export type SingleChoiceContentProps = {
  kind: "SINGLE_CHOICE";
  quizContent: ReadonlyQuizQuestionSingleChoiceProps;
};

export type QuizQuestionCardContentProps = EvaluationContentProps | MultiChoiceContentProps | SingleChoiceContentProps;

const QuizQuestionCardContent: React.FC<QuizQuestionCardContentProps> = props => {
  return (
    <Box>
      {props.kind === "FREE_TEXT" && <ReadonlyQuizQuestionEvaluation {...props.quizContent} />}
      {props.kind === "MULTI_CHOICE" && <ReadonlyQuizQuestionMultiChoice {...props.quizContent} />}
      {props.kind === "SINGLE_CHOICE" && <ReadonlyQuizQuestionSingleChoice {...props.quizContent} />}
    </Box>
  );
};

QuizQuestionCardContent.displayName = "QuizQuestionCardContent";

export default QuizQuestionCardContent;
