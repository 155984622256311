import type * as Interface from "@hireroo/evaluation-result-interface";
import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import Markdown from "../Markdown/Markdown";
import Image, { ImageProps } from "./parts/Image/Image";
import ImageDiffDialog, { ImageDiffDialogProps } from "./parts/ImageDiffDialog/ImageDiffDialog";

const ImageWrapper = styled(Box)(() => ({
  height: "80px",
  width: "80px",
}));

type DialogState = {
  open: boolean;
} & Pick<ImageDiffDialogProps, "initialState">;

export type ImageInputOutputExpectedProps = Interface.ImageInputOutputExpected["value"];

const ImageInputOutputExpected: React.FC<ImageInputOutputExpectedProps> = props => {
  const { t } = useTranslation();
  const [dialogState, setDialogState] = React.useState<DialogState>({
    open: false,
  });
  const dialog: ImageDiffDialogProps = {
    dialog: {
      open: dialogState.open,
      onClose: () => {
        setDialogState({ open: false });
      },
    },
    results: props.results,
    initialState: dialogState.initialState,
  };
  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row">
        <Markdown {...props.description.value} />
      </Stack>
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <Stack spacing={1.5}>
          <Typography fontSize={14} color="textSecondary">
            {t("入力")}
          </Typography>
          <ImageWrapper>
            <Image src={props.input.src} alt="input-image" />
          </ImageWrapper>
        </Stack>
        <Stack spacing={1.5}>
          <Typography fontSize={14} color="textSecondary">
            {t("出力")}
          </Typography>
          <Stack direction="row" gap={1} width="100%" flexWrap="wrap">
            {props.results.map((result, index) => {
              const handleClick = () => {
                setDialogState({
                  open: true,
                  initialState: {
                    startIndex: index,
                    kind: "output",
                  },
                });
              };
              const imageProps: ImageProps = {
                src: result.output.src,
                alt: `output-image-${index}`,
                onClick: handleClick,
                icon: { kind: result.mark },
              };
              return (
                <ImageWrapper key={index}>
                  <Image {...imageProps} />
                </ImageWrapper>
              );
            })}
          </Stack>
        </Stack>
        <Stack spacing={1.5}>
          <Typography fontSize={14} color="textSecondary">
            {t("期待値")}
          </Typography>
          <Stack direction="row" gap={1} width="100%" flexWrap="wrap">
            {props.results.map((result, index) => {
              const handleClick = () => {
                setDialogState({
                  open: true,
                  initialState: {
                    startIndex: index,
                    kind: "expected",
                  },
                });
              };
              const imageProps: ImageProps = {
                src: result.expected.src,
                alt: `expected-image-${index}`,
                onClick: handleClick,
                icon: { kind: result.mark },
              };

              return (
                <ImageWrapper key={index}>
                  <Image {...imageProps} />
                </ImageWrapper>
              );
            })}
          </Stack>
        </Stack>
      </Stack>
      <ImageDiffDialog key={`dialog-${dialogState.open}`} {...dialog} />
    </Stack>
  );
};

ImageInputOutputExpected.displayName = "ImageInputOutputExpected";

export default ImageInputOutputExpected;
