import * as React from "react";

import { Area } from "../../../features";
import { Comment } from "../Comment/Comment";

export type CommentForStaticViewboxProps = {
  content: string;
  minX: number;
  minY: number;
  fontSize: number;
  onChangeArea?: (area: Area) => void;
};

const CommentForStaticViewbox: React.FC<CommentForStaticViewboxProps> = React.memo(props => {
  const { onChangeArea } = props;
  // Calculate size of the selection area from the text length and update comment element
  const ref = React.useRef<SVGTextElement | null>(null);
  React.useEffect(() => {
    if (!ref.current) return;

    const bBox = ref.current.getBBox();
    const width = bBox.width + (20 - (bBox.width % 20));
    const height = bBox.height + (20 - (bBox.height % 20));
    const area = {
      minX: props.minX,
      maxX: props.minX + width,
      minY: props.minY,
      maxY: props.minY + height,
    };
    onChangeArea?.(area);
    //FIXME: if you add onChangeArea, "Maximum update depth exceeded" will occur
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.minX, props.minY, props.content, props.fontSize]);

  return <Comment content={props.content} minX={props.minX} minY={props.minY} fontSize={props.fontSize} ref={ref} />;
});

CommentForStaticViewbox.displayName = "CommentForStaticViewbox";

export default CommentForStaticViewbox;
