import { useTranslation } from "@hireroo/i18n";
import { AccessPermissionForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";
import SelectField, { SelectFieldProps } from "../../primitive/InputControl/SelectField/SelectField";

export type AccessPermissionDialogProps = {
  onSubmit: SubmitHandler<AccessPermissionForm.AccessPermissionSchema>;
  defaultValues: AccessPermissionForm.AccessPermissionSchema;
  disabled?: boolean;
  open: boolean;
  onOpen?: () => void;
  onClose?: DialogWithCloseIconProps["onClose"];
  editorId: string;
  description: string;
  AccessPermissionField: React.ReactNode;
};

const AccessPermissionDialog: React.FC<AccessPermissionDialogProps> = props => {
  const { t } = useTranslation();
  const validateSchema = AccessPermissionForm.useAccessPermissionFormSchema();

  const methods = useForm<AccessPermissionForm.AccessPermissionSchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: props.defaultValues,
    mode: "onSubmit",
  });

  const isPublic = methods.watch("isPublic");
  const selectFieldProps: SelectFieldProps = {
    color: "secondary",
    variant: "outlined",
    items: [
      {
        text: t("リンクを知っているメンバーが閲覧可能"),
        value: true,
      },
      {
        text: t("指定されたメンバーのみ閲覧可能"),
        value: false,
      },
    ],
    onChange: event => {
      if (event.target.value === true) {
        methods.setValue("viewers", []);
      } else if (event.target.value === false) {
        methods.setValue("viewers", [{ value: { type: "EMPLOYEE", employeeId: props.editorId, locked: true } }]);
      }
    },
  };

  const AccessPermissionDialog: DialogWithCloseIconProps = {
    open: props.open,
    title: t("閲覧権限の編集"),
    onClose: () => {
      props.onClose?.();
    },
    noButton: {
      onClick: () => {
        props.onClose?.();
      },
      color: "secondary",
      children: t("キャンセル"),
    },
    disableEnter: true,
    yesButton: {
      onClick: () => {
        methods.handleSubmit(props.onSubmit)();
      },
      color: "secondary",
      children: t("保存"),
    },
  };
  return (
    <DialogWithCloseIcon key="viewer-dialog" {...AccessPermissionDialog}>
      <FormProvider {...methods}>
        <Typography variant="body2" py={2}>
          {props.description}
        </Typography>
        <SelectField name="isPublic" {...selectFieldProps} />
        {isPublic === false && <Box mt={1}>{props.AccessPermissionField}</Box>}
      </FormProvider>
    </DialogWithCloseIcon>
  );
};

AccessPermissionDialog.displayName = "AccessPermissionDialog";

export default AccessPermissionDialog;
