import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { useRemoteTechnicalCommentRepository } from "@hireroo/app-helper/hooks";
import { App, Auth, Company } from "@hireroo/app-store/essential/employee";
import { RemotesIdEvaluateStore } from "@hireroo/app-store/page/e/remotes_id_evaluate";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useTranslation } from "@hireroo/i18n";
import { Pages } from "@hireroo/presentation";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import { TechnicalCommentForm } from "@hireroo/validator";
import * as Sentry from "@sentry/react";
import * as React from "react";

import FooterContainer from "../../../../widget/v2/e/Footer/Container";
import { useNotificationBanner } from "../../../../widget/v2/e/NotificationBanner/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import { useFetchMetrics } from "./useFetchMetrics";
import RemoteInterviewEvaluateHeaderContainer from "./widget/RemoteInterviewEvaluateHeader/Container";
import TechnicalCommentEvaluationsFormContainer from "./widget/TechnicalCommentEvaluationsForm/Container";

//TODO: fix when v2 is released
// In new design, Evaluations and Feedback is combined, but we uses same state as v1
export const ActiveStepMap: Record<TechnicalCommentForm.TechnicalCommentStepSchema, Exclude<RemotesIdEvaluateStore.ActiveStep, 2>> = {
  Evaluations: 0,
  Feedback: 0,
  Confirmation: 1,
};

export type GenerateRemotesEvaluatePropsArgs = {
  remoteId: string;
};

export const useGenerateProps = (args: GenerateRemotesEvaluatePropsArgs): Pages.RemoteEvaluationProps => {
  const { t } = useTranslation();
  const client = getGraphqlClient();
  const currentUid = Auth.useCurrentUid();
  const companyId = Company.useActiveCompanyId();
  const appStatus = App.useStatus();

  const { isLoading } = useFetchMetrics({ companyId: companyId || 0 });
  const [submitStatus, setSubmitStatus] = React.useState<"READY" | "PENDING">("READY");
  const navigate = useTransitionNavigate();
  const metricMap = RemotesIdEvaluateStore.useMetricMap();
  const [open, setOpen] = React.useState(false);
  const NotificationBannerContainer = useNotificationBanner();

  const { updateStep, addEvaluations, removeEvaluation, updateEvaluation, updateFeedback, technicalComment, removeFromStorage } =
    useRemoteTechnicalCommentRepository(args.remoteId);

  const evaluations = React.useMemo(() => {
    return Object.keys(technicalComment.schemas.evaluations).map(key => technicalComment.schemas.evaluations[Number(key)]);
  }, [technicalComment.schemas.evaluations]);

  React.useEffect(() => {
    // Initialize for to keep out selected items.
    evaluations.forEach(evaluation => {
      RemotesIdEvaluateStore.selectMetric(evaluation.metricId);
    });
  }, [evaluations]);

  const disabledEvaluationSectionButton = React.useMemo(() => {
    const evaluations = technicalComment.schemas.evaluations;
    if (Object.keys(evaluations).length === 0) return true;
    return Object.keys(evaluations).some(key => evaluations[Number(key)].numStars <= 0);
  }, [technicalComment.schemas.evaluations]);

  const handleChangeEvaluationRating = React.useCallback(
    (metricId: number, numStars: number) => {
      updateEvaluation(metricId, {
        numStars,
      });
    },
    [updateEvaluation],
  );

  const handleChangeEvaluationComment = React.useCallback(
    (metricId: number, comment: string) => updateEvaluation(metricId, { comment }),
    [updateEvaluation],
  );
  const handleSubmitEvaluationsSection = React.useCallback(() => updateStep("Confirmation"), [updateStep]);

  // For evaluation section
  const handleSelectMetrics = React.useCallback(
    (metricIds: number[]) => {
      addEvaluations(metricIds);
      RemotesIdEvaluateStore.selectMetrics(metricIds);
    },
    [addEvaluations],
  );
  const handleRemoveMetric = React.useCallback(
    (metricId: number) => {
      removeEvaluation(metricId);
      RemotesIdEvaluateStore.removeMetric(metricId);
    },
    [removeEvaluation],
  );

  const handleSubmitRemoteEvaluation = React.useCallback(() => {
    setSubmitStatus("PENDING");
    client
      .ReviewRemoteRemotesEvaluate({
        reviewRemoteInput: {
          remoteId: args.remoteId,
          employeeId: currentUid,
          companyId: companyId || 0,
          comment: technicalComment.schemas.feedback.comment,
          isRecommended: technicalComment.schemas.feedback.isRecommended,
          evaluations: evaluations,
        },
      })
      .then(() => {
        Snackbar.notify({
          severity: "success",
          message: t("インタビューの技術レビューが完了しました。"),
        });
        removeFromStorage(args.remoteId);
        navigate("/e/home");
      })
      .catch(error => {
        Sentry.captureException(error);
        const errorNotification = ErrorHandlingHelper.generateErrorNotification(
          error,
          t("インタビューの技術レビューに失敗しました。しばらくしてから再度お試しください。"),
        );
        Snackbar.notify({
          severity: "error",
          message: errorNotification.message,
        });
      })
      .finally(() => {
        setSubmitStatus("READY");
      });
  }, [
    args.remoteId,
    client,
    companyId,
    currentUid,
    evaluations,
    navigate,
    removeFromStorage,
    t,
    technicalComment.schemas.feedback.comment,
    technicalComment.schemas.feedback.isRecommended,
  ]);

  return {
    activeStep: ActiveStepMap[technicalComment.step],
    layout: {
      loading: appStatus === "INITIALIZING" || isLoading,
      NotificationBanner: NotificationBannerContainer,
      Header: <RemoteInterviewEvaluateHeaderContainer />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      Tutorial: null,
    },
    Evaluations: (
      <TechnicalCommentEvaluationsFormContainer
        uniqueKey={args.remoteId}
        evaluations={evaluations}
        onChangeRating={handleChangeEvaluationRating}
        onChangeComment={handleChangeEvaluationComment}
        onSelectMetrics={handleSelectMetrics}
        onRemoveMetric={handleRemoveMetric}
      />
    ),
    recommendation: {
      commentField: {
        value: technicalComment.schemas.feedback.comment,
        onChange: React.useCallback(
          (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            updateFeedback({ comment: e.currentTarget.value });
          },
          [updateFeedback],
        ),
      },
      recommendation: {
        value: technicalComment.schemas.feedback.isRecommended ? "GO" : "NOT_GO",
        onChange: React.useCallback(
          value => {
            updateFeedback({ isRecommended: value === "GO" });
          },
          [updateFeedback],
        ),
      },
    },
    confirmation: {
      isRecommended: technicalComment.schemas.feedback.isRecommended,
      comment: technicalComment.schemas.feedback.comment,
      evaluationItems: Object.keys(technicalComment.schemas.evaluations).map(key => {
        return {
          id: technicalComment.schemas.evaluations[Number(key)].metricId,
          title: metricMap.get(Number(key))?.title || t("不明な評価項目"),
          comment: technicalComment.schemas.evaluations[Number(key)].comment,
          numStars: technicalComment.schemas.evaluations[Number(key)].numStars,
        };
      }),
    },
    confirmEvaluationDialog: {
      open,
      yesButton: {
        onClick: handleSubmitRemoteEvaluation,
        disabled: submitStatus === "PENDING",
      },
      noButton: {
        onClick: React.useCallback(() => setOpen(false), []),
        disabled: submitStatus === "PENDING",
      },
    },
    submitEvaluationItems: {
      nextButton: {
        disabled: disabledEvaluationSectionButton,
        onClick: () => handleSubmitEvaluationsSection(),
      },
    },
    submitConfirm: {
      previousButton: {
        onClick: React.useCallback(() => updateStep("Evaluations"), [updateStep]),
      },
      nextButton: {
        onClick: React.useCallback(() => setOpen(true), []),
      },
    },
    laterButton: {
      onClick: () => {
        navigate("/e/remotes/:id/detail", { pathParams: { id: args.remoteId } });
      },
    },
  };
};
