import * as Graphql from "@hireroo/graphql/client/urql";

export type Res = Graphql.ScreeningInviteesResponseForScreeningInviteeListFragment;

export type Filters = Graphql.ScreeningInviteesFilterInput;

export type ListParams = {
  isDescending: boolean;
  page: number;
  size: number;
  filters: Filters;
};

export type Callback = () => void;
export type State = {
  res: Res | null;
  listParams: ListParams;
  refresh: Callback;
};

export const QueryKeys = {
  PAGE: "page",
  SIZE: "size",
  EMAIL: "email",
  STATUS: "status",
  SORT: "sorting",
} as const;

export type QueryType = (typeof QueryKeys)[keyof typeof QueryKeys];
