import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import ToggleButton, { ToggleButtonProps } from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";

const Wrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "32px",
  padding: "16px 4px",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  fontSize: 14,
  background: theme.palette["Background/Paper"].p8,
}));

const HEIGHT = 24;

const StyledToggleButton = styled(ToggleButton)(() => ({
  width: HEIGHT,
  height: HEIGHT,
  minWidth: HEIGHT,
  minHeight: HEIGHT,
  padding: 0,
  border: "none",
  borderColor: "transparent",
  display: "flex",
  flexAlign: "center",
  justifyContent: "center",
  textTransform: "none",
  svg: {
    width: "16px",
    height: "16px",
  },
}));

type Item = Pick<ToggleButtonProps, "onClick" | "children" | "value" | "title">;

export type ChallengePlaybackToolbarProps = {
  onChange?: (values: string[]) => void;
  items: Item[];
};

const ChallengePlaybackToolbar: React.FC<ChallengePlaybackToolbarProps> = props => {
  const [values, setValues] = React.useState<string[]>([]);
  const handleFormat = (event: React.MouseEvent<HTMLElement>, newFormats: string[]) => {
    setValues(newFormats);
    props.onChange?.(newFormats);
  };
  return (
    <Wrapper>
      <ToggleButtonGroup value={values} onChange={handleFormat}>
        {props.items.map((item, index) => {
          const { title, ...itemProps } = item;
          return (
            <StyledToggleButton key={index.toString()} {...itemProps}>
              <Tooltip title={title ?? ""}>
                <span>{itemProps.children}</span>
              </Tooltip>
            </StyledToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </Wrapper>
  );
};

ChallengePlaybackToolbar.displayName = "ChallengePlaybackToolbar";

export default ChallengePlaybackToolbar;
