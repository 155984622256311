import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { CandidateInviteForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import { PeopleOutlineRounded } from "@mui/icons-material";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import Button, { type ButtonProps } from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import MultiEmailAddressField, { MultiEmailAddressFieldProps } from "../../../modules/MultiEmailAddressField/MultiEmailAddressField";
import { ButtonWithTooltipProps } from "../../../primitive/Button/ButtonWithTooltip/ButtonWithTooltip";
import CopyToClipboardButton, { CopyToClipboardButtonProps } from "../../../primitive/Button/CopyToClipboardButton/CopyToClipboardButton";
import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";

const StyledDialogWithCloseIcon = styled(DialogWithCloseIcon)(({ theme }) => ({
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid",
    borderColor: theme.palette.Other.Divider,
  },
}));

type Language = "JA" | "EN";

export type InviteCandidateDialogV2Props = {
  dialog: Pick<DialogWithCloseIconProps, "open" | "onClose">;
  name: string;
  copyButton?: Pick<CopyToClipboardButtonProps, "url" | "onCopy">;
  onSubmit: SubmitHandler<CandidateInviteForm.CandidateInviteFormSchema>;
  language: Language;
  editButton?: Pick<ButtonProps, "onClick">;
  invitedListButton: Pick<ButtonProps, "onClick">;
};

const InviteCandidateDialogV2: React.FC<InviteCandidateDialogV2Props> = props => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const { onSubmit } = props;
  const validateSchema = CandidateInviteForm.useCandidateInviteFormSchema();
  const methods = useForm<CandidateInviteForm.CandidateInviteFormSchema>({
    mode: "onSubmit",
    resolver: zodResolver(validateSchema),
    defaultValues: {
      emails: [],
    },
  });

  const multiMailAddressFieldProps: MultiEmailAddressFieldProps = {
    color: "secondary",
    placeholder: "alice@example.com,bob@example.com",
    sx: {
      backgroundColor: theme.palette["Other"]["FilledInputBG"],
      borderRadius: "4px",
      "& fieldset": { border: "none" },
      maxHeight: "217px",
      overflow: "auto",
    },
    helperText: t("1回につき最大1000人までご招待いただけます。"),
    rows: 1,
  };
  const languageMap: Record<Language, string> = {
    JA: t("この招待メールは日本語で送付されます。"),
    EN: t("この招待メールは英語で送付されます。"),
  };

  const copyButton: ButtonWithTooltipProps = {
    size: "small",
    variant: "text",
    color: "secondary",
  };

  const dialogWithCloseIconProps: DialogWithCloseIconProps = {
    ...props.dialog,
    title: t2("InviteCandidateToScreeningTest", {
      screeningName: props.name,
    }),
    LeftActionButton: props.copyButton ? (
      <CopyToClipboardButton {...props.copyButton} name={t("リンクコピー")} startIcon="LINK" buttonWithTooltip={copyButton} />
    ) : (
      <span />
    ),
    yesButton: {
      onClick: () => {
        if (onSubmit) {
          methods.handleSubmit(fields => {
            onSubmit(fields);
            props.dialog.onClose?.();
          })();
        }
      },
      color: "secondary",
      children: t("招待する"),
    },
    onClose: () => {
      props.dialog.onClose?.();
    },
    disableEnter: true,
    optionalContent: {
      sx: {
        px: 3,
        py: 2,
      },
    },
  };
  const editButton: ButtonProps = {
    ...props.editButton,
    children: t("設定"),
    color: "secondary",
    variant: "text",
    size: "small",
    sx: {
      minWidth: "unset",
    },
  };
  const invitedListButton: ButtonProps = {
    ...props.invitedListButton,
    startIcon: <PeopleOutlineRounded fontSize="small" />,
    children: t("招待済みのメールアドレス一覧"),
    color: "outline-only",
    variant: "text",
    size: "small",
    sx: {
      color: theme.palette.text.secondary,
      minWidth: "unset",
      width: "fit-content",
      textDecoration: "underline",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  };

  return (
    <StyledDialogWithCloseIcon {...dialogWithCloseIconProps}>
      <FormProvider {...methods}>
        <Stack spacing={2}>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <LanguageOutlinedIcon fontSize="small" sx={{ color: theme.palette.text.secondary, width: "20px", height: "20px" }} />
            <Typography fontSize={14} color="textSecondary">
              {languageMap[props.language]}
            </Typography>
            <Button {...editButton} />
          </Stack>
          <Stack>
            <MultiEmailAddressField name="emails" {...multiMailAddressFieldProps} />
            <Stack direction="row" justifyContent="space-between" mt="3px" ml={1.5}>
              <Typography fontSize={12} color="textSecondary">
                {t("1回につき最大1000人までご招待いただけます。")}
              </Typography>
              <Typography fontSize={12}>{`${methods.watch("emails").length} / 1000`}</Typography>
            </Stack>
          </Stack>
          <Divider />
          <Button {...invitedListButton} />
        </Stack>
      </FormProvider>
    </StyledDialogWithCloseIcon>
  );
};

InviteCandidateDialogV2.displayName = "InviteCandidateDialogV2";

export default InviteCandidateDialogV2;
