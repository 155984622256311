import { useTranslation } from "@hireroo/i18n";
import { MemoEditorForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import DescriptionOutlined from "@mui/icons-material/DescriptionOutlined";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import Box from "@mui/material/Box";
import Stack, { StackProps } from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";
import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";
import InputControlTextField, { InputControlTextFieldProps } from "../../primitive/InputControl/InputControlTextField/InputControlTextField";
import TextLinkify from "../../primitive/TextLinkify/TextLinkify";

const StyledModeEditOutlinedIcon = styled(ModeEditOutlinedIcon)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const StyledTypography = styled(Typography)`
  word-break: break-all;
  white-space: pre-wrap;
`;

export type MemoEditorProps = {
  memo: string;
  onSubmit: SubmitHandler<MemoEditorForm.MemoEditorFormSchema>;
  disabled?: boolean;
  open: boolean;
  onOpen?: () => void;
  onClose?: () => void;
};

const MemoEditor: React.FC<MemoEditorProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const validateSchema = MemoEditorForm.useMemoEditor();
  const methods = useForm<MemoEditorForm.MemoEditorFormSchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: { memo: props.memo },
    mode: "onChange",
  });

  const wrapper: StackProps = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    direction: "row",
    border: "1px solid",
    borderColor: theme.palette.secondary.main,
    borderRadius: "8px",
    p: 1,
  };
  const editButton: IconButtonWithTooltipProps = {
    children: <StyledModeEditOutlinedIcon fontSize="small" />,
    onClick: () => {
      props.onOpen?.();
    },
  };
  const dialog: DialogWithCloseIconProps = {
    title: t("メモ"),
    open: props.open,
    onClose: () => {
      props.onClose?.();
    },
    yesButton: {
      children: t("保存"),
      onClick: () => {
        methods.handleSubmit(props.onSubmit)();
      },
      disabled: props.disabled,
      color: "secondary",
    },
    disableEnter: true,
    noButton: {
      children: t("キャンセル"),
      onClick: () => {
        props.onClose?.();
      },
      disabled: props.disabled,
      color: "secondary",
    },
  };
  const memoFieldProps: InputControlTextFieldProps = {
    id: "memo",
    color: "secondary",
    type: "text",
    InputLabelProps: {
      shrink: true,
    },
    control: methods.control,
    multiline: true,
    minRows: 5,
    fullWidth: true,
    variant: "outlined",
    placeholder: t("評価結果などは、評価後にまとめてATSの方に結果を残すようにお願いします。"),
    helperText: t("この内容は候補者には共有されません。"),
  };

  return (
    <Stack {...wrapper}>
      <Box mr={2} display="flex" alignItems="center">
        <DescriptionOutlined color="secondary" />
      </Box>
      <Box overflow="auto">
        <StyledTypography fontSize={14} color={props.memo.length > 0 ? "textPrimary" : "textSecondary"}>
          {props.memo.length > 0 ? <TextLinkify>{props.memo}</TextLinkify> : t("メモはありません。")}
        </StyledTypography>
      </Box>
      <Box ml="auto">
        <IconButtonWithTooltip {...editButton} />
      </Box>
      <DialogWithCloseIcon {...dialog}>
        <FormProvider {...methods}>
          <InputControlTextField name="memo" {...memoFieldProps} />
        </FormProvider>
      </DialogWithCloseIcon>
    </Stack>
  );
};

MemoEditor.displayName = "MemoEditor";

export default MemoEditor;
