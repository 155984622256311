import { useTranslation } from "@hireroo/i18n";
import ExitToApp from "@mui/icons-material/ExitToApp";
import AppBar from "@mui/material/AppBar";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";

import BrandLogo, { BrandLogoProps } from "../../modules/BrandLogo/BrandLogo";
import HelpMenu, { HelpMenuProps } from "../../modules/HelpMenu/HelpMenu";
import PreferencesMenu, { PreferencesMenuProps } from "../../modules/PreferencesMenu/PreferencesMenu";
import BaseDialog, { BaseDialogProps } from "../../primitive/BaseDialog/BaseDialog";
import CopyToClipboardButton, { CopyToClipboardButtonProps } from "../../primitive/Button/CopyToClipboardButton/CopyToClipboardButton";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  width: "100%",
  height: 48,
  border: "none",
  borderBottom: `solid 1px ${theme.palette["Secondary/Shades"].p16}`,
  overflow: "hidden",
  backgroundColor: theme.palette.HeaderBG,
}));

export type RemoteInterviewEmployeeHeaderProps = {
  brandLogo: BrandLogoProps;
  endTestDialog: Pick<BaseDialogProps, "yesButton" | "noButton">;
  Timelimit: React.ReactNode;
  copyShareLinkButton: Pick<CopyToClipboardButtonProps, "onCopy" | "url" | "buttonWithTooltip">;
  Participants: React.ReactNode;
  finishInterviewButton: Pick<ButtonProps, "disabled">;
  VideoButton: React.ReactNode;
  helpMenu: HelpMenuProps;
  preferences: PreferencesMenuProps;
};

const RemoteInterviewEmployeeHeader: React.FC<RemoteInterviewEmployeeHeaderProps> = props => {
  const { t } = useTranslation();
  const [openFinishInterviewDialog, setOpenFinishInterviewDialog] = React.useState(false);

  const copyShareLinkButtonProps: CopyToClipboardButtonProps = {
    ...props.copyShareLinkButton,
    name: "",
    buttonWithTooltip: {
      ...props.copyShareLinkButton.buttonWithTooltip,
      startIcon: "INSERT_LINK",
      color: "secondary",
      sx: {
        minWidth: "40px",
        ".MuiButton-startIcon": {
          m: 0,
        },
      },
    },
  };

  const finishInterviewButton: ButtonProps = {
    ...props.finishInterviewButton,
    onClick: () => {
      setOpenFinishInterviewDialog(true);
    },
  };

  const baseDialogProps: BaseDialogProps = {
    open: openFinishInterviewDialog,
    title: t("インタビューを終了します。"),
    yesButton: {
      ...props.endTestDialog.yesButton,
      children: t("はい"),
    },
    noButton: {
      ...props.endTestDialog.noButton,
      children: t("いいえ"),
      onClick: () => {
        setOpenFinishInterviewDialog(false);
      },
    },
  };

  return (
    <StyledAppBar variant="outlined" elevation={0}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" px={1.5} width="100%" height="100%">
        <Stack direction="row" spacing={2} alignItems="center">
          <BrandLogo {...props.brandLogo} />
        </Stack>
        {props.Timelimit}
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            {...finishInterviewButton}
            className="finish-button"
            color="outline-only"
            size="small"
            variant="outlined"
            startIcon={<ExitToApp color="inherit" fontSize="small" />}
            children={t("インタビュー終了")}
            sx={{
              height: 36,
              wordBreak: "keep-all",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          />
          {props.VideoButton}
          <CopyToClipboardButton {...copyShareLinkButtonProps} />
          <HelpMenu {...props.helpMenu} />
          <PreferencesMenu {...props.preferences} />
          {props.Participants}
        </Stack>
      </Stack>
      <BaseDialog {...baseDialogProps}>{t("インタビューを終了すると、以降候補者が回答できなくなりますがよろしいですか？")}</BaseDialog>
    </StyledAppBar>
  );
};

RemoteInterviewEmployeeHeader.displayName = "RemoteInterviewEmployeeHeader";

export default RemoteInterviewEmployeeHeader;
