import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { RemoteTemplateResourceEditorForm } from "@hireroo/validator";
import Stack from "@mui/material/Stack";
import * as React from "react";

import ResourceField from "../../../../modules/ResourceFieldV2/ResourceFieldV2";
import ToggleResourceField, { ToggleResourceFieldProps } from "../../../../modules/ToggleResourceField/ToggleResourceField";
import InputControlTextField, {
  InputControlTextFieldProps,
} from "../../../../primitive/InputControl/InputControlTextField/InputControlTextField";
import { useRemoteTemplateResourceEditorContext } from "../../Context";

type TemplateFieldName = keyof RemoteTemplateResourceEditorForm.CreateRemoteTemplateSchema;

const ReservedTemplateFieldName = {
  TEMPLATE_TITLE: "templateTitle",
  TEMPLATE_DESCRIPTION: "templateDescription",
  VARIABLES: "variables",
} satisfies Record<string, TemplateFieldName>;

export type TemplateFieldsProps = {};

const TemplateFields: React.FC<TemplateFieldsProps> = _props => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const { methods, setTitle } = useRemoteTemplateResourceEditorContext();
  const titleFieldProps: InputControlTextFieldProps = {
    fullWidth: true,
    placeholder: t2("TemplateTitlePlaceholder", { year: new Date().getFullYear().toString() }),
  };
  const title = methods.watch(ReservedTemplateFieldName.TEMPLATE_TITLE);
  React.useEffect(() => {
    setTitle(title);
  }, [title, setTitle]);

  const descriptionOptionalResourceField: ToggleResourceFieldProps = {
    toggleKind: "BUTTON",
    label: t("テンプレートの説明"),
    defaultOpen: !!methods.getValues(ReservedTemplateFieldName.TEMPLATE_DESCRIPTION),
    onDiscard: () => {
      methods.setValue(ReservedTemplateFieldName.TEMPLATE_DESCRIPTION, "");
    },
  };
  const descriptionFieldProps: InputControlTextFieldProps = {
    fullWidth: true,
    minRows: 3,
    maxRows: 10,
    multiline: true,
    placeholder: t2("TemplateDescriptionPlaceholder", { year: new Date().getFullYear().toString() }),
  };
  const variables = methods.watch(ReservedTemplateFieldName.VARIABLES);

  return (
    <Stack spacing={2}>
      <ResourceField label={t("テンプレートタイトル")} kind="REQUIRED">
        <InputControlTextField name={`${ReservedTemplateFieldName.TEMPLATE_TITLE}`} {...titleFieldProps} />
      </ResourceField>
      <ToggleResourceField {...descriptionOptionalResourceField} pb={1}>
        <InputControlTextField name={ReservedTemplateFieldName.TEMPLATE_DESCRIPTION} {...descriptionFieldProps} />
      </ToggleResourceField>

      {variables && variables.length > 0 && (
        <ResourceField
          label={t("埋め込み情報を入力する")}
          kind="NONE"
          help={{
            kind: "DESCRIPTION",
            text: t("埋め込み情報に任意の説明を加えることで、インタビュー作成時他の人でも容易にインタビュー作成ができます。"),
          }}
        >
          <Stack spacing={2}>
            {variables.map((variable, index) => {
              const descriptionFieldProps: InputControlTextFieldProps = {
                fullWidth: true,
                multiline: false,
                label: t2("VariableDescription", { variableName: variable.name }),
              };
              return (
                <InputControlTextField
                  key={`${variable.name}`}
                  name={`${ReservedTemplateFieldName.VARIABLES}.${index}.description`}
                  {...descriptionFieldProps}
                />
              );
            })}
          </Stack>
        </ResourceField>
      )}
    </Stack>
  );
};

TemplateFields.displayName = "TemplateFields";

export default TemplateFields;
