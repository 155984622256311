import { useTranslation } from "@hireroo/i18n";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";

import EmployeeLayout, { EmployeeLayoutProps } from "../../layouts/EmployeeLayout/EmployeeLayout";

const StyledEmployeeLayout = styled(EmployeeLayout)`
  overflow: hidden;
`;

const SubHeader = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette["Background/Paper"].p2,
  padding: theme.spacing(1),
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: "1px solid",
  borderColor: theme.palette.divider,
}));

const WrapperBox = styled(Box)(({ theme }) => ({
  borderRadius: "16px",
  padding: theme.spacing(3),
  backgroundColor: theme.palette["Background/Paper"].p2,
}));

export type EvaluationMetricsProps = {
  layout: EmployeeLayoutProps;
  children: React.ReactNode;
  subHeader?: {
    backButton: Pick<ButtonProps, "onClick">;
  };
};

const EvaluationMetrics: React.FC<EvaluationMetricsProps> = props => {
  const { t } = useTranslation();
  const backButton: ButtonProps | undefined = props.subHeader
    ? {
        ...props.subHeader.backButton,
        variant: "text",
        size: "small",
        color: "outline-only",
        sx: {
          wordBreak: "keep-all",
          textTransform: "none",
        },
        children: t("評価指標一覧"),
        startIcon: <ArrowBackIcon fontSize="small" />,
      }
    : undefined;
  return (
    <StyledEmployeeLayout {...props.layout}>
      <Stack>
        {props.subHeader && backButton && (
          <SubHeader direction="row">
            <Button {...backButton} />
          </SubHeader>
        )}
        <Box p={3}>
          <WrapperBox>{props.children}</WrapperBox>
        </Box>
      </Stack>
    </StyledEmployeeLayout>
  );
};

EvaluationMetrics.displayName = "EvaluationMetrics";

export default EvaluationMetrics;
