import { state } from "./State";
import type * as Types from "./types";

export const initialize = (tab: Types.TabValue): void => {
  state.currentTab = tab;
};

export const updateCurrentTab = (newCurrentTab: Types.TabValue): void => {
  state.currentTab = newCurrentTab;
};

export const updateInterview = (newInterview: Types.Interview): void => {
  state.interview = newInterview;
};

export const updateSharedLink = (newSharedLink: Types.InterviewDetailSharedLink): void => {
  state.sharedLink = newSharedLink;
};
