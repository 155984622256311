import { App, Company } from "@hireroo/app-store/essential/employee";
import { SecuritySettings } from "@hireroo/app-store/widget/e/SecuritySettings";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import SecuritySettingsContainer, { SecuritySettingsContainerProps } from "./Container";

export type SecuritySettingsFetchContainerProps = {};
const SecuritySettingsFetchContainer: React.FC<SecuritySettingsFetchContainerProps> = _props => {
  const companyId = Company.useStrictActiveCompanyId();
  const appStatus = App.useStatus();
  const [result, refresh] = Graphql.useGetCompanyForSecuritySettingsQuery({
    variables: {
      companyId: companyId,
    },
    requestPolicy: "network-only",
    pause: appStatus !== "INITIALIZED",
  });
  const [initialized, setInitialize] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (result.data?.company.security) {
      SecuritySettings.updateSecurity(result.data.company.security);
      setInitialize(true);
    }
  }, [result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || !initialized || appStatus !== "INITIALIZED") {
    return <Widget.Loading kind="TOP" />;
  }

  const containerProps: SecuritySettingsContainerProps = {};
  return (
    <ErrorBoundary>
      <SecuritySettingsContainer {...containerProps} />
    </ErrorBoundary>
  );
};

SecuritySettingsFetchContainer.displayName = "SecuritySettingsFetchContainer";

export default withErrorBoundary(SecuritySettingsFetchContainer, {});
