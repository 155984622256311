import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type CsvDownloadContainerProps = {};

const CsvDownloadContainer: React.FC<CsvDownloadContainerProps> = () => {
  const props = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.CsvDownload {...props} />
    </ErrorBoundary>
  );
};

CsvDownloadContainer.displayName = "CsvDownloadContainer";

export default withErrorBoundary(CsvDownloadContainer, {});
