import { ChallengeCodingEditor } from "@hireroo/app-store/widget/shared/ChallengeCodingEditor";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/react";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import ChallengeCodingEditorMiddleContainer, { ChallengeCodingEditorMiddleContainerProps } from "./MiddleContainer";
import { useGenerateChallengeProviderProps } from "./useGenerateChallengeProviderProps";

export type ChallengeCodingEditorFetchContainerProps = {
  uid: string;
  editorKind: "CANDIDATE" | "EMPLOYEE";
  interviewKind: "INTERVIEW" | "DEMO";
  entityId: number;
  displayName: string;
  enableBrowserEventDetector: boolean;
  enabledWebSearch: boolean;
  enabledChatGPT: boolean;
};

const ChallengeCodingEditorFetchContainer: React.FC<ChallengeCodingEditorFetchContainerProps> = props => {
  const [result, startChallenge] = Graphql.useStartChallengeMutation();
  const { useEntity } = ChallengeCodingEditor.useCreateChallengeEntityHooks(props.entityId);
  const enableWebSearch = ChallengeCodingEditor.useEnableWebSearch();
  const enableChatGPT = ChallengeCodingEditor.useEnableChatGPT();
  const enableHint = ChallengeCodingEditor.useEnableHint();

  const { uid, editorKind } = props;
  const { t } = useTranslation();
  const entity = useEntity();
  const entityId = entity.challengeEntityId;
  const challengeProviderProps = useGenerateChallengeProviderProps({ entityId, kind: editorKind });

  const challengeCodingEditorMiddleContainerProps: ChallengeCodingEditorMiddleContainerProps = props;

  React.useEffect(() => {
    if (editorKind === "CANDIDATE" && entity.challengeStatus === "CREATED" && !result.error && !result.fetching) {
      startChallenge({
        challengeId: entity.challengeEntityId,
        candidateId: uid,
      })
        .then(res => {
          if (res.data?.startChallenge) {
            ChallengeCodingEditor.initialize(res.data.startChallenge, { enableWebSearch, enableChatGPT, enableHint });
          }
        })
        .catch(error => {
          Snackbar.notify({
            severity: "error",
            message: t("コーディング形式の問題取得に失敗しました。"),
          });
          Sentry.captureException(error);
        });
    }
  }, [entity, uid, startChallenge, result, entityId, t, editorKind, enableWebSearch, enableChatGPT, enableHint]);

  if (entity.challengeStatus === "CREATED" || result.fetching) {
    return <Widget.Loading kind="CENTER_%" color="secondary" />;
  }

  return (
    <ErrorBoundary>
      <Widget.ChallengeCodingEditorProvider {...challengeProviderProps}>
        <ChallengeCodingEditorMiddleContainer {...challengeCodingEditorMiddleContainerProps} />
      </Widget.ChallengeCodingEditorProvider>
    </ErrorBoundary>
  );
};

ChallengeCodingEditorFetchContainer.displayName = "ChallengeCodingEditorFetchContainer";

export default withErrorBoundary(ChallengeCodingEditorFetchContainer, {});
