export const encodeFirebaseKey = (filePath: string): string => {
  /**
   * 以下の対策
   * Uncaught InvalidCharacterError: Failed to execute 'btoa' on 'Window': The string to be encoded contains characters outside of the Latin1 range.
   *
   * ファイルルパスに日本語（マルチバイト文字）が含まれると正しく変換できない
   */
  const value = encodeURIComponent(filePath);
  return btoa(value).replace(/\//g, "_").replace(/\+/g, "-").replace(/=/g, "");
};

export const decodeFirebaseKey = (safePath: string): string => {
  try {
    const base64 = safePath.replace(/_/g, "/").replace(/-/g, "+");
    const value = atob(base64);
    return decodeURIComponent(value);
  } catch (error) {
    console.error(`Path : ${safePath}`);
    throw error;
  }
};
