import { useTitle } from "@hireroo/app-helper/react-use";
import { App } from "@hireroo/app-store/essential/employee";
import { EmployeeSideBarContent } from "@hireroo/app-store/widget/e/EmployeeSideBarContent";
import { useTranslation } from "@hireroo/i18n";
import { Pages } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";

import EmployeeHeaderRightContentContainer from "../../../../widget/v2/e/EmployeeHeaderRightContent/Container";
import EmployeeSideBarContentContainer from "../../../../widget/v2/e/EmployeeSideBarContent/Container";
import FooterContainer from "../../../../widget/v2/e/Footer/Container";
import { useNotificationBanner } from "../../../../widget/v2/e/NotificationBanner/Container";
import UserSettingsNavigatorContainer from "../../../../widget/v2/e/UserSettingsNavigator/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import UserProfileContainer from "./widgets/UserProfile/Container";

export const useGenerateProps = (): Pages.UserSettingsForEmployeeProps => {
  const { t } = useTranslation();
  const navigate = useTransitionNavigate();
  const loading = App.useStatus() === "INITIALIZING";
  const NotificationBannerContainer = useNotificationBanner();

  useTitle(t("個人設定"));

  return {
    layout: {
      openSidebar: EmployeeSideBarContent.useOpenSidebar(),
      onChangeOpenSidebar: open => {
        EmployeeSideBarContent.setOpenSidebar(open);
      },
      loading: loading,
      NotificationBanner: NotificationBannerContainer,
      HeaderRightContent: <EmployeeHeaderRightContentContainer />,
      SideBarContent: <EmployeeSideBarContentContainer />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      sideBarHeader: {
        logo: {
          href: generatePath("/e/home"),
          onClick: () => {
            navigate("/e/home");
          },
        },
      },
    },
    settingsLayout: {
      HeaderMenu: <UserSettingsNavigatorContainer active="ACCOUNT" />,
      sideBar: {
        active: "user-profile",
        items: [{ id: "user-profile", label: t("基本情報") }],
      },
    },
    children: <UserProfileContainer />,
  };
};
