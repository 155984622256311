import * as z from "zod";

export const useLiveCodingChatFormSchema = () => {
  return z.object({
    message: z
      .string()
      .min(1)
      .refine(value => value.trim().length > 0),
  });
};

export type LiveCodingChatInputFormSchema = z.infer<ReturnType<typeof useLiveCodingChatFormSchema>>;
