import InputBase from "@mui/material/InputBase";
import NativeSelect, { NativeSelectProps } from "@mui/material/NativeSelect";
import { styled } from "@mui/material/styles";
import * as React from "react";

const StyledNativeSelect = styled(NativeSelect)(({ theme }) => ({
  textColor: theme.palette.text.secondary,
}));
const StyledInput = styled(InputBase)(({ theme }) => ({
  backgroundColor: "inherit",
  color: theme.palette.text.secondary,
  textColor: theme.palette.text.secondary,
}));
type Option = {
  value: string;
  displayName: string;
};

export type TestSelectorProps = {
  options: Option[];
  value: string;
  onChange: NativeSelectProps["onChange"];
};

const TestSelector: React.FC<TestSelectorProps> = props => {
  const testSelector: NativeSelectProps = {
    value: props.value,
    onChange: props.onChange,
    input: <StyledInput />,
  };
  return (
    <StyledNativeSelect {...testSelector}>
      {props.options.map((option, index) => (
        <option key={`${option.value}-${index}`} value={option.value}>
          {option.displayName}
        </option>
      ))}
    </StyledNativeSelect>
  );
};

TestSelector.displayName = "TestSelector";

export default TestSelector;
