import { MoreHoriz, MoreVert, ShareOutlined } from "@mui/icons-material";
import Add from "@mui/icons-material/Add";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DescriptionIcon from "@mui/icons-material/Description";
import DownloadIcon from "@mui/icons-material/Download";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import ReplayIcon from "@mui/icons-material/Replay";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import { SvgIconProps } from "@mui/material/SvgIcon";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

const iconMap = {
  ADD: <Add fontSize="small" />,
  EDIT: <EditOutlinedIcon fontSize="small" />,
  DELETE: <DeleteOutlineIcon color="error" fontSize="small" />,
  ASSIGNMENT: <AssignmentIcon fontSize="small" />,
  CONTENT_COPY: <ContentCopyIcon fontSize="small" />,
  CONTENT_PASTE: <ContentPasteIcon fontSize="small" />,
  REPLAY: <ReplayIcon fontSize="small" />,
  DOWNLOAD: <DownloadIcon fontSize="small" />,
  DESCRIPTION: <DescriptionIcon fontSize="small" />,
  LOADING: <CircularProgress size="1rem" sx={{ mr: "0.75em" }} />,
  SHARE: <ShareOutlined fontSize="small" />,
  PLAY: <PlayCircleOutlinedIcon fontSize="small" />,
  STOP: <StopCircleOutlinedIcon fontSize="small" />,
  GROUP: <GroupsOutlinedIcon fontSize="small" />,
  PERSON_ADD: <PersonAddOutlinedIcon fontSize="small" />,
  EMAIL: <EmailOutlinedIcon fontSize="small" />,
  LABEL: <LabelOutlinedIcon fontSize="small" />,
  INSERT_LINK: <InsertLinkOutlinedIcon fontSize="small" />,
} as const;

type OptionMap = {
  value: string;
  displayName: string;
  color?: string;
  onClick: () => void;
  disabledText?: string;
  disabled?: boolean;
  divider?: boolean;
  startIcon?: keyof typeof iconMap;
};

const StyledMenuItem = styled(MenuItem)({
  root: {
    "&.Mui-disabled": {
      pointerEvents: "auto",
    },
  },
});

export type MoreButtonProps = {
  options: OptionMap[];
  variant: "horizontal" | "vertical";
  iconButton?: IconButtonProps;
  color?: SvgIconProps["htmlColor"];
};

const MoreButton: React.FC<MoreButtonProps> = props => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClose: Exclude<MenuProps["onClose"], undefined> = (event, reason) => {
    setAnchorEl(null);
    if (reason === "backdropClick") {
      // It is true that stopPropagation exists, but the Material UI type definition is wrong.
      (
        event as {
          stopPropagation?: () => void;
        }
      ).stopPropagation?.();
    }
  };

  /**
   * Execute stopPropagation on the outer element because the stopPropagation of the inner button in the disabled state is not valid.
   */
  const handleClick: React.MouseEventHandler<HTMLSpanElement> = event => {
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <>
      <IconButton
        {...props.iconButton}
        aria-label="more"
        id="question-list-item-menu-button"
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={event => {
          event.stopPropagation();
          event.preventDefault();
          setAnchorEl(event.currentTarget);
        }}
      >
        {props.variant === "vertical" ? <MoreVert htmlColor={props.color} /> : <MoreHoriz htmlColor={props.color} />}
      </IconButton>
      {/* TODO Replace with `primitive/PopupMenu`. */}
      <Menu open={open} anchorEl={anchorEl} onClose={handleMenuClose} disableRestoreFocus={true} onClick={handleClick}>
        {props.options.map(option => (
          <Tooltip key={option.value} title={option.disabled ? option.disabledText ?? "" : ""}>
            <span>
              <StyledMenuItem
                key={option.value}
                disabled={option.disabled}
                onClick={event => {
                  event.stopPropagation();
                  event.preventDefault();
                  option.onClick();
                  handleClose();
                }}
                divider={option.divider}
              >
                {option.startIcon && <ListItemIcon>{iconMap[option.startIcon]}</ListItemIcon>}
                <Typography color={option.color}>{option.displayName}</Typography>
              </StyledMenuItem>
            </span>
          </Tooltip>
        ))}
      </Menu>
    </>
  );
};

MoreButton.displayName = "MoreButton";

export default MoreButton;
