import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  borderRadius: "8px",
  '&[data-selected="true"]': {
    backgroundColor: theme.palette.action.selected,
  },
  textTransform: "none",
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: 16,
}));

export type SelectableButtonProps = {
  selected: boolean;
  children: string;
} & Pick<ButtonProps, "onClick">;

const SelectableButton: React.FC<SelectableButtonProps> = props => {
  const { selected, ...rest } = props;
  const selectableButtonProps: ButtonProps = {
    ...rest,
    children: <StyledTypography sx={{ textAlign: "left", width: "100%" }}>{props.children}</StyledTypography>,
    /**
     * The style is broken with or without the icon, so only the color is changed.
     */
    endIcon: <KeyboardArrowRightRoundedIcon htmlColor={selected ? undefined : "#FFFFFF00"} />,
    fullWidth: true,
    color: "secondary",
  };
  return <StyledButton {...selectableButtonProps} data-selected={selected.toString()} />;
};

SelectableButton.displayName = "SelectableButton";

export default SelectableButton;
