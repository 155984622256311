import { QuestionsPackages } from "@hireroo/app-store/page/e/questions_packages";
import { updateQueryParamsByObject } from "@hireroo/router/api";

export const startSubscribeListParams = () => {
  return QuestionsPackages.subscribeAllState(state => {
    if (!state.currentSearchFilter) {
      return;
    }
    const { currentSearchFilter, pager } = state;

    /**
     * Do not update query parameters if they are different from the Default Value when the page is visited
     * (i.e., the user has not manipulated them).
     */
    updateQueryParamsByObject({
      [QuestionsPackages.QueryKeys.TITLE]:
        currentSearchFilter.textFilter && currentSearchFilter.textFilter.length > 0 ? [currentSearchFilter.textFilter] : [],
      [QuestionsPackages.QueryKeys.DIFFICULTY]: currentSearchFilter?.difficulties ? currentSearchFilter.difficulties : [],
      [QuestionsPackages.QueryKeys.SORT]: pager.sortFieldValue === "created-at-ascending" ? [`METHOD_CREATED_AT-false`] : [],
      /**
       * Set page number to "1" if it is not a page change
       */
      [QuestionsPackages.QueryKeys.PAGE]: pager.page > 0 ? [(pager.page + 1).toString()] : [],
      [QuestionsPackages.QueryKeys.SIZE]: pager.size !== QuestionsPackages.DefaultPager.size ? [pager.size.toString()] : [],
    });
  });
};
