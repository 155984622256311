import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type HeadingProps = {
  text: string;
  Content?: React.ReactNode;
};

const Heading: React.FC<HeadingProps> = props => {
  return (
    <Grid container spacing={2} mt={5} alignItems="center" justifyContent="space-between">
      <Grid item xs={7}>
        <Typography data-testid="main-title" variant="h5" style={{ fontWeight: "bold" }}>
          {props.text}
        </Typography>
      </Grid>

      <Grid item xs={5}>
        {props.Content && (
          <Box display="flex" justifyContent="end">
            {props.Content}
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

Heading.displayName = "Heading";

export default Heading;
