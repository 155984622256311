import * as Themes from "@hireroo/ui-theme/theme-v2";
import { applyJupyterTheme } from "@jupyter/web-components";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as Sentry from "@sentry/browser";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";

import { Config, JupyterNotebookAdapter } from "../../api";
import { JupyterProvider, JupyterProviderProps } from "../../store";
import NotebookToolbar from "../NotebookToolbar/NotebookToolbar";
import StyledWrapper from "./parts/StyledWrapper/StyledWrapper";

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette["Background/Paper"].p8,
  height: "100%",
  position: "relative",
}));

const ToolbarWrapper = styled(Box)(({ theme }) => ({
  position: "fixed",
  backgroundColor: theme.palette.background.default,
  zIndex: theme.zIndex.appBar + 1,
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const ContentWrapper = styled(Box)(() => ({
  height: "100%",
}));

export type NotebookProps = {
  config: Config;
  filename: string;
};

const Notebook: React.FC<NotebookProps> = props => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [adapter, setAdapter] = React.useState<JupyterNotebookAdapter | null>(null);
  const resizeDetector = useResizeDetector({
    onResize: () => {
      adapter?.resize();
    },
  });
  const jupyterProviderProps: JupyterProviderProps = {
    adapter: adapter ?? undefined,
  };
  React.useEffect(() => {
    const target = ref.current;
    if (target && !adapter) {
      const notebookAdapter = new JupyterNotebookAdapter(props.config, props.filename);
      notebookAdapter.onDispose(() => {
        setAdapter(null);
      });
      notebookAdapter
        .initialize()
        .then(() => {
          setAdapter(notebookAdapter);
          notebookAdapter.mount(target);
          applyJupyterTheme();
        })
        .catch(error => {
          Sentry.captureException(error);
        });
    }
  }, [props.config, props.filename, ref, adapter]);
  return (
    <Themes.ThemeProvider defaultColorMode="LIGHT">
      <JupyterProvider {...jupyterProviderProps}>
        <Wrapper>
          <ToolbarWrapper width={resizeDetector.width ?? "100%"}>
            <NotebookToolbar />
          </ToolbarWrapper>
          <ContentWrapper>
            <StyledWrapper ref={resizeDetector.ref}>
              <Box ref={ref} top="40px" height={"calc(100% - 40px)"} position="relative" />
            </StyledWrapper>
          </ContentWrapper>
        </Wrapper>
      </JupyterProvider>
    </Themes.ThemeProvider>
  );
};

Notebook.displayName = "Notebook";

export default Notebook;
