import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateDashboardScreeningsSectionPropsArgs, useGenerateProps } from "./useGenerateProps";

export type DashboardScreeningsSectionContainerProps = GenerateDashboardScreeningsSectionPropsArgs;

const DashboardScreeningsSectionContainer: React.FC<DashboardScreeningsSectionContainerProps> = props => {
  const dashboardScreeningsSectionProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.DashboardScreeningsSection {...dashboardScreeningsSectionProps} />
    </ErrorBoundary>
  );
};

DashboardScreeningsSectionContainer.displayName = "DashboardScreeningsSectionContainer";

export default withErrorBoundary(DashboardScreeningsSectionContainer, {});
