import * as Themes from "@hireroo/ui-theme/theme-v2";
import { INotebookContent } from "@jupyterlab/nbformat";
import { UUID } from "@lumino/coreutils";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import InputViewer from "./parts/InputViewer/InputViewer";
import StyledWrapper from "./parts/StyledWrapper/StyledWrapper";

const MessageWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
}));

const Message = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  width: "100%",
  fontWeight: 700,
  textAlign: "center",
  fontSize: 24,
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: "100%",
  minHeight: "inherit",
  width: "100%",
}));

export type NotebookViewerProps = {
  source: string;
};

const NotebookViewer: React.FC<NotebookViewerProps> = props => {
  const { source } = props;
  const notebookFormatSource = React.useMemo((): INotebookContent => {
    try {
      return JSON.parse(source);
    } catch {
      return { metadata: {}, nbformat_minor: 0, nbformat: 0, cells: [] };
    }
  }, [source]);

  return (
    <Themes.ThemeProvider defaultColorMode="LIGHT">
      <ContentWrapper>
        <StyledWrapper>
          {notebookFormatSource.cells.length === 0 && (
            <MessageWrapper>
              <Message>NO DATA</Message>
            </MessageWrapper>
          )}
          {notebookFormatSource.cells.map(cell => {
            return (
              <InputViewer key={cell.id?.toString() ?? UUID.uuid4()} cell={cell} languageInfo={notebookFormatSource.metadata.language_info} />
            );
          })}
        </StyledWrapper>
      </ContentWrapper>
    </Themes.ThemeProvider>
  );
};

NotebookViewer.displayName = "NotebookViewer";

export default NotebookViewer;
