import { useTranslation } from "@hireroo/i18n";
import Slack from "@hireroo/ui-assets/images/Logo/Slack.png";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import BaseDialog, { BaseDialogProps } from "../../../primitive/BaseDialog/BaseDialog";

const SALES_MAIL_ADDRESS = "sales@hireroo.io";

const StyledBox = styled(Box)(() => ({
  width: "50%",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
}));

const IconTypographyBox = styled(Box)(() => ({
  display: "flex",
  margin: "0 auto",
  marginBottom: "10px",
  height: 24,
}));

const DescriptionBox = styled(Box)(() => ({
  display: "flex",
  margin: "0 auto",
  marginBottom: "10px",
  height: 40,
  justifyContent: "center",
  alignItems: "center",
}));

export type ContactUsDialogProps = Pick<BaseDialogProps, "open" | "title"> & {
  onClose: () => void;
};

const ContactUsDialog: React.FC<ContactUsDialogProps> = props => {
  const { t } = useTranslation();
  const dialogProps: BaseDialogProps = {
    open: props.open,
    title: props.title,
    disableEnter: true,
    yesButton: {
      onClick: () => {
        props.onClose();
      },
      children: t("閉じる"),
    },
  };
  return (
    <BaseDialog {...dialogProps}>
      <Typography variant="body2" fontSize={14} mb={3} color="text.secondary">
        {t("以下の方法でお問い合わせください。")}
      </Typography>
      <Stack direction="row" divider={<Divider orientation="vertical" light />}>
        <StyledBox>
          <IconTypographyBox>
            <img src={Slack} width={24} height={24} />
            <Typography variant="body2" ml={1}>
              Slack
            </Typography>
          </IconTypographyBox>
          <DescriptionBox>
            <Typography variant="body2" color="text.secondary">
              {t("Slack連携している場合はSlackからでも問い合わせできます")}
            </Typography>
          </DescriptionBox>
        </StyledBox>
        <StyledBox>
          <IconTypographyBox>
            <EmailOutlinedIcon />
            <Typography variant="body2" ml={1}>
              E-mail
            </Typography>
          </IconTypographyBox>
          <DescriptionBox>
            <Typography variant="body2" color="text.secondary">
              {SALES_MAIL_ADDRESS}
            </Typography>
          </DescriptionBox>
        </StyledBox>
      </Stack>
    </BaseDialog>
  );
};

ContactUsDialog.displayName = "ContactUsDialog";

export default ContactUsDialog;
