import * as React from "react";

export type LockIconProps = {};

const LockIcon: React.FC<LockIconProps> = _props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M14.9999 6.66634H14.1666V4.99967C14.1666 2.69967 12.2999 0.833008 9.99992 0.833008C7.69992 0.833008 5.83325 2.69967 5.83325 4.99967V6.66634H4.99992C4.08325 6.66634 3.33325 7.41634 3.33325 8.33301V16.6663C3.33325 17.583 4.08325 18.333 4.99992 18.333H14.9999C15.9166 18.333 16.6666 17.583 16.6666 16.6663V8.33301C16.6666 7.41634 15.9166 6.66634 14.9999 6.66634ZM7.49992 4.99967C7.49992 3.61634 8.61659 2.49967 9.99992 2.49967C11.3833 2.49967 12.4999 3.61634 12.4999 4.99967V6.66634H7.49992V4.99967ZM14.9999 16.6663H4.99992V8.33301H14.9999V16.6663ZM9.99992 14.1663C10.9166 14.1663 11.6666 13.4163 11.6666 12.4997C11.6666 11.583 10.9166 10.833 9.99992 10.833C9.08325 10.833 8.33325 11.583 8.33325 12.4997C8.33325 13.4163 9.08325 14.1663 9.99992 14.1663Z"
        fill="url(#paint0_linear_5913_946)"
      />
      <defs>
        <linearGradient id="paint0_linear_5913_946" x1="3.33325" y1="10.833" x2="16.6666" y2="10.833" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5B4FC7" />
          <stop offset="0.0001" stopColor="#614EC9" />
          <stop offset="0.455095" stopColor="#D03DF1" />
          <stop offset="1" stopColor="#F05077" />
        </linearGradient>
      </defs>
    </svg>
  );
};

LockIcon.displayName = "LockIcon";

export default LockIcon;
