import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type ScreeningTestContainerProps = {};

const ScreeningTestContainer: React.FC<ScreeningTestContainerProps> = () => {
  const screeningTestProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Pages.ScreeningTest {...screeningTestProps} />
    </ErrorBoundary>
  );
};

ScreeningTestContainer.displayName = "ScreeningTestContainer";

export default withErrorBoundary(ScreeningTestContainer, {});
