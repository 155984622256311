import { App } from "@hireroo/app-store/essential/employee";
import { AlgorithmResourceEditor } from "@hireroo/app-store/view-domain/AlgorithmResourceEditor";
import { Pages } from "@hireroo/presentation/legacy";
import * as React from "react";

import EmployeeNavigationContainer from "../../../widget/e/EmployeeNavigation/Container";
import NotificationBannerContainer from "../../../widget/e/NotificationBanner/Container";
import FooterContainer from "../../../widget/shared/Footer/Container";
import SnackbarContainer from "../../../widget/shared/Snackbar/Container";
import { useGenerateAnswersProps } from "./useGenerateAnswersProps";
import { useGenerateFunctionDefinitionProps } from "./useGenerateFunctionDefinitionProps";
import { useGenerateHintsProps } from "./useGenerateHintsProps";
import { useGenerateQuestionDetailsProps } from "./useGenerateQuestionDetailsProps";
import { useGenerateSaveAnswersButtonProps } from "./useGenerateSaveAnswersButtonProps";
import { useGenerateTableSignatureProps } from "./useGenerateTableSignatureProps";
import { useGenerateTestCasesProps } from "./useGenerateTestCasesProps";

export type GenerateAlgorithmResourceEditorPropsArgs = {
  companyId: number;
  type: "CREATE" | "UPDATE";
};

export const useGenerateAlgorithmResourceEditorProps = (args: GenerateAlgorithmResourceEditorPropsArgs): Pages.AlgorithmResourceEditorProps => {
  const activeStep = AlgorithmResourceEditor.useActiveStep();
  const appStatus = App.useStatus();
  const questionDetails = useGenerateQuestionDetailsProps(args);
  const functionDefinitions = useGenerateFunctionDefinitionProps(args);
  const tableSignature = useGenerateTableSignatureProps(args);
  const testCases = useGenerateTestCasesProps(args);
  const answers = useGenerateAnswersProps();
  const saveAnswers = useGenerateSaveAnswersButtonProps(args);
  const hints = useGenerateHintsProps(args);
  const variant = AlgorithmResourceEditor.useVariant();

  const isLoading =
    tableSignature.saveButton.loading ||
    functionDefinitions.saveButton.loading ||
    testCases.saveButton.loading ||
    saveAnswers.saveButton.loading ||
    hints.saveButton.loading;
  return {
    activeStep: activeStep,
    layout: {
      loading: appStatus !== "INITIALIZED",
      Header: <EmployeeNavigationContainer />,
      Banner: <NotificationBannerContainer />,
      Footer: <FooterContainer />,
      Bottom: <SnackbarContainer />,
    },
    previousButton: {
      onClick: () => {
        AlgorithmResourceEditor.goPrevious();
      },
      disabled: isLoading,
    },
    questionDetails: questionDetails.props,
    saveQuestionDetailsButton: questionDetails.saveButton,
    functionDefinition: functionDefinitions.props,
    saveFunctionDefinitionButton: functionDefinitions.saveButton,
    tableSignature: tableSignature.props,
    saveTableSignatureButton: tableSignature.saveButton,
    testCases: testCases.props,
    saveTestCasesButton: testCases.saveButton,
    answers: answers,
    saveAnswersButton: saveAnswers.saveButton,
    hints: hints.props,
    saveHintsButton: hints.saveButton,
    draftSaveButton: {
      onClick: () => {
        switch (activeStep) {
          case AlgorithmResourceEditor.STEPS.TABLE_SIGNATURE:
          case AlgorithmResourceEditor.STEPS.FUNCTION_DEFINITION:
            // TODO: Please change when we have a new variant class. For use class step page instead of function step.
            if (variant === "ALGORITHM" || variant === "CLASS") {
              functionDefinitions.onDraftSave();
            } else if (variant === "DATABASE") {
              tableSignature.onDraftSave();
            }
            break;
          case AlgorithmResourceEditor.STEPS.TEST_CASES:
            testCases.onDraftSave();
            break;
          case AlgorithmResourceEditor.STEPS.ANSWERS:
            saveAnswers.onDraftSave();
            break;
          case AlgorithmResourceEditor.STEPS.HINTS:
            hints.onDraftSave();
            break;
        }
      },
      disabled: isLoading,
    },
    variant: variant,
  };
};
