import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import * as echarts from "echarts";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";

import { createChart, CreateChartArgs } from "./privateHelper";

const StyledBox = styled(Box)(() => ({
  width: "100%",
  height: "100%",
}));

export type DoughnutProps = {
  items: CreateChartArgs["items"];
};

const Doughnut: React.FC<DoughnutProps> = props => {
  const element = React.createRef<HTMLDivElement>();
  const chart = React.useRef<echarts.ECharts | null>(null);
  const theme = useTheme();
  useResizeDetector({
    targetRef: element,
    onResize: () => {
      chart.current?.resize();
    },
  });
  const createChartArgs = React.useMemo((): Omit<CreateChartArgs, "dom"> => {
    return {
      items: props.items,
      styles: {
        textColor: theme.palette.text.primary,
        subTextColor: theme.palette.text.secondary,
      },
    };
  }, [props.items, theme]);
  React.useEffect(() => {
    if (element.current) {
      chart.current = createChart({ ...createChartArgs, dom: element.current });
    }
    return () => {
      chart.current?.clear();
    };
  }, [createChartArgs, element]);

  React.useEffect(() => {
    chart.current?.setOption({
      dark: theme.palette.mode === "dark",
    });
  }, [chart, theme.palette.mode]);
  return <StyledBox ref={element} />;
};

Doughnut.displayName = "Doughnut";

export default Doughnut;
