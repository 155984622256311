import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PersonIcon from "@mui/icons-material/Person";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type ListCandidateItemProps = {
  id: string;
  candidateName: string;
  candidateEmail?: string;
  reportButton: { href: string; text: string };
};

const ListCandidateItem: React.FC<ListCandidateItemProps> = props => {
  const theme = useTheme();
  return (
    <ListItem style={{ padding: 0 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
        <Stack direction="row" spacing={2}>
          <PersonIcon fontSize="small" sx={{ color: theme.palette.text.secondary }} />
          <Stack direction="row" maxWidth="400px">
            <Typography fontSize={14}>{[props.candidateName, props.candidateEmail ? `(${props.candidateEmail})` : ""].join(" ")}</Typography>
          </Stack>
        </Stack>
        <Button
          variant="text"
          sx={{ wordBreak: "keep-all", textTransform: "none", height: "20px" }}
          color="secondary"
          endIcon={<OpenInNewIcon color="secondary" fontSize="small" />}
          LinkComponent="a"
          target="_blank"
          rel="noreferrer"
          href={props.reportButton.href}
        >
          {props.reportButton.text}
        </Button>
      </Stack>
    </ListItem>
  );
};

ListCandidateItem.displayName = "ListCandidateItem";

export default ListCandidateItem;
