import { LanguageMap, languageMap } from "@hireroo/app-definition";
import { Auth } from "@hireroo/app-store/essential/talent";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/server/types";
import { useChangeLanguage, useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateHelpCenterUrl, navigate as navigateApi } from "@hireroo/router/api";
import * as React from "react";
import { useLocation } from "react-router";

import TalentMenuContainer from "../TalentMenu/Container";
import NotificationListContainer from "./widget/NotificationList/Container";

export type GenerateTalentHeaderRightContentPropsArgs = {};

export const useGenerateProps = (_args: GenerateTalentHeaderRightContentPropsArgs): Widget.TalentHeaderRightContentProps => {
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const currentUser = Auth.useCurrentUser();
  const client = getGraphqlClient();
  const location = useLocation();

  const changeLanguage = useChangeLanguage();

  const updateLanguage = React.useCallback(
    async (lang: Graphql.Language): Promise<void> => {
      await client
        .UpdateUserForUserProfile({
          updateUserInput: {
            id: currentUser.uid,
            displayName: currentUser.displayName,
            language: lang,
            photoUrl: currentUser.photoUrl,
          },
        })
        .then(res => {
          changeLanguage(LanguageMap[res.updateUser.language]);
          Snackbar.notify({
            severity: "success",
            message: t("ユーザ情報を更新しました。"),
          });
          // Auth.setUser(res.updateUser);
        })
        .catch(() => {
          Snackbar.notify({
            severity: "error",
            message: t("ユーザ情報の更新に失敗しました。しばらくしてから再度お試し頂くか、ヘルプボタンよりお問い合わせください。"),
          });
        });
    },
    [changeLanguage, client, currentUser.displayName, currentUser.photoUrl, currentUser.uid, t],
  );
  const helpPageUrl = React.useMemo(() => {
    if (location.pathname.includes("/t/assessments")) {
      return generateHelpCenterUrl(lang, "ASSESSMENT_TALENT_LIST_PAGE");
    }
    return generateHelpCenterUrl(lang, "ROOT");
  }, [lang, location]);

  return {
    Menu: <TalentMenuContainer />,
    NotificationButton: <NotificationListContainer />,
    helpButton: {
      onClick: () => {
        navigateApi(helpPageUrl, { _blank: true });
      },
    },
    preferences: {
      languageDropDownMenu: {
        onChangeLanguage: language => {
          updateLanguage(languageMap[language]);
        },
      },
    },
  };
};
