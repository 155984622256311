/* eslint-disable react/display-name */
import { useTheme } from "@mui/material/styles";
import type { LabelProps } from "recharts/types";
import type { CartesianViewBox } from "recharts/types/util/types";

export type CustomLabelArgs = {
  kind: "COMMON";
  text: string;
};

const RectParams = {
  width: 110,
  height: 50,
};

export const useCustomLabel = () => {
  const theme = useTheme();
  return (args: CustomLabelArgs) => (props: LabelProps) => {
    const castViewBox = props.viewBox as CartesianViewBox;
    const viewBox = { x: castViewBox.x ?? 0, y: castViewBox.y ?? 0 };
    const startX = viewBox.x - 55;
    const startY = viewBox.y - 60;
    return (
      <g>
        <rect x={startX} y={startY} fill={theme.palette.common.white} width={RectParams.width} height={RectParams.height} rx={10} />
        <text
          x={startX + RectParams.width / 2}
          y={startY + RectParams.height / 2}
          dominantBaseline="middle"
          fill={theme.palette.common.black}
          textAnchor="middle"
          fontSize={14}
        >
          {args.text}
        </text>
        <path
          d={`M${viewBox.x} ${viewBox.y - 1} L${viewBox.x - 4} ${viewBox.y - 10} L${viewBox.x + 4} ${viewBox.y - 10} Z`}
          fill={theme.palette.common.white}
        ></path>
      </g>
    );
  };
};
