import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";

import DeleteDialog, { DeleteDialogProps } from "../../modules/DeleteDialog/DeleteDialog";
import RestrictableTab, { RestrictableTabProps } from "../../modules/RestrictableTab/RestrictableTab";
import ActivateDialog, { ActivateDialogProps } from "../../ms-components/Spot/ActivateDialog/ActivateDialog";
import InviteCandidateDialogV2, {
  InviteCandidateDialogV2Props,
} from "../../ms-components/Spot/InviteCandidateDialogV2/InviteCandidateDialogV2";
import ScreeningDetailHeader, { ScreeningDetailHeaderProps } from "./parts/ScreeningDetailHeader/ScreeningDetailHeader";
import ScreeningOverview, { ScreeningOverviewProps } from "./parts/ScreeningOverview/ScreeningOverview";

const TAB_HEIGHT = 44;

/**
 * 72px was calculated from the non-content height of EmployeeLayout
 */
const StyledBox = styled(Box)(({ theme }) => ({
  height: "100%",
  backgroundColor: theme.palette["Background/Paper"].p2,
  minWidth: 900,
}));

type TabValue = "OVERVIEW" | "CANDIDATES" | "INVITEES";

export type ScreeningDetailProps = {
  header: ScreeningDetailHeaderProps;
  overview: ScreeningOverviewProps;
  deleteDialog: DeleteDialogProps;
  inviteCandidateDialogV2: InviteCandidateDialogV2Props;
  List: React.ReactNode;
  InviteeList: React.ReactNode;
  defaultTab?: TabValue;
  onTabChange: (tab: TabValue) => void;
  activateDialog: ActivateDialogProps;
};

const ScreeningDetail: React.FC<ScreeningDetailProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const items = React.useMemo((): RestrictableTabProps["items"] => {
    const baseItems: RestrictableTabProps["items"] = [
      {
        id: "OVERVIEW",
        Component: <ScreeningOverview {...props.overview} />,
        label: {
          name: t("概要"),
          onClick: () => {
            props.onTabChange("OVERVIEW");
          },
        },
      },
      {
        id: "CANDIDATES",
        Component: props.List,
        label: {
          name: t("候補者一覧"),
          onClick: () => {
            props.onTabChange("CANDIDATES");
          },
        },
      },
    ];
    if (props.InviteeList) {
      return baseItems.concat({
        id: "INVITEES",
        Component: props.InviteeList,
        label: {
          name: t("招待者一覧"),
          onClick: () => {
            props.onTabChange("INVITEES");
          },
        },
      });
    }
    return baseItems;
  }, [props, t]);
  const restrictableTabProps: RestrictableTabProps = {
    items: items,
    variant: "OUTLINED",
    tabs: {
      "aria-label": "test-report-tabs",
      sx: {
        "&.MuiTabs-root": {
          minHeight: `${TAB_HEIGHT}px`,
          height: `${TAB_HEIGHT}px`,
        },
        "& .MuiTab-root": {
          minHeight: `${TAB_HEIGHT}px`,
          height: `${TAB_HEIGHT}px`,
        },
        "& .MuiTab-root.Mui-selected": {
          color: theme.palette.secondary.main,
          backgroundColor: "inherit",
        },
      },
    },
    tabBox: {
      sx: {
        paddingLeft: "24px",
        paddingRight: "24px",
      },
    },
    wrapper: {},
    tabPanelBox: {
      pt: 0,
      sx: {
        bgcolor: theme.palette.background.default,
      },
    },
    defaultTab: props.defaultTab,
  };
  return (
    <StyledBox>
      <ScreeningDetailHeader {...props.header} />
      <RestrictableTab {...restrictableTabProps} />
      <DeleteDialog {...props.deleteDialog} />
      <ActivateDialog key={`activate-${props.activateDialog.dialog.open}`} {...props.activateDialog} />
      <InviteCandidateDialogV2 key={`invite-candidate-${props.inviteCandidateDialogV2.dialog.open}`} {...props.inviteCandidateDialogV2} />
    </StyledBox>
  );
};

ScreeningDetail.displayName = "ScreeningDetail";

export default ScreeningDetail;
