import { useTranslation } from "@hireroo/i18n";
import * as React from "react";

import EntryOverviewSection, { EntryOverviewSectionProps } from "../../../../modules/EntryOverviewSection/EntryOverviewSection";

export type EntryInterviewOverviewSectionProps = {
  interviewName: string;
  companyName: string;
};

const EntryInterviewOverviewSection: React.FC<EntryInterviewOverviewSectionProps> = props => {
  const { t } = useTranslation();

  const interviewEntryOverviewSection = React.useMemo((): EntryOverviewSectionProps => {
    return {
      title: t("インタビュー概要"),
      mode: "BORDER",
      items: [
        {
          title: t("インタビュー名"),
          content: props.interviewName,
        },
        {
          title: t("企業名"),
          content: props.companyName,
        },
      ],
    };
  }, [t, props]);

  return <EntryOverviewSection {...interviewEntryOverviewSection} />;
};

EntryInterviewOverviewSection.displayName = "EntryInterviewOverviewSection";

export default EntryInterviewOverviewSection;
