import { proxy } from "valtio";
import { proxyMap } from "valtio/utils";

import type * as Types from "./types";

export const state = proxy<Types.State>({
  templates: proxyMap(),
  nextPager: null,
  currentPager: null,
  selectedTemplateId: null,
  variableWithValue: proxyMap(),
  step: "CHOICE",
  res: null,
});
