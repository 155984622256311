import { useSnapshot } from "valtio";

import { state } from "./State";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = () => {
  const snapshot = useSnapshotState();
  return snapshot.initialized;
};

export const useEntityKeys = () => {
  const snapshot = useSnapshotState();
  return snapshot.entityKeys;
};

export const useEntities = () => {
  const snapshot = useSnapshotState();
  const keys = Array.from(new Set(snapshot.entityKeys));
  return keys.map(key => snapshot.entities[key]).flatMap(v => (v ? [v] : []));
};

export const useEntityMap = () => {
  const snapshot = useSnapshotState();
  return snapshot.entities;
};

export const useCurrentSelectedEntityKey = () => {
  const snapshot = useSnapshotState();
  return snapshot.currentSelectedEntityKey;
};

export const useLoadingStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.loadingStatus;
};

export const useAllQuestionsHaveBeenSubmitted = () => {
  const entities = useEntities();
  return entities.every(entity => entity.completed);
};
