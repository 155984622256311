import { useTranslation } from "@hireroo/i18n";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

import ActiveStatusChip, { ActiveStatusChipProps } from "../../../../ms-components/Spot/ActiveStatusChip/ActiveStatusChip";
import InviteCandidateButtonWithDialog, {
  InviteCandidateButtonWithDialogProps,
} from "../../../../ms-components/Spot/InviteCandidateButtonWithDialog/InviteCandidateButtonWithDialog";
import MoreButton, { MoreButtonProps } from "../../../../primitive/Button/MoreButton/MoreButton";

const StyledStack = styled(Stack)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
}));

type Status = "ALL" | "INVITATION_CODE_ONLY";

export type ScreeningDetailHeaderProps = {
  title: string;
  activeStatus: ActiveStatusChipProps["status"];
  status: Status;
  moreButton: Pick<MoreButtonProps, "options">;
  inviteCandidate: Omit<InviteCandidateButtonWithDialogProps, "variant">;
};

const ScreeningDetailHeader: React.FC<ScreeningDetailHeaderProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const moreButton: MoreButtonProps = {
    ...props.moreButton,
    color: theme.palette.text.primary,
    variant: "vertical",
  };
  return (
    <StyledStack direction="row" px={3} pt={3} pb={1}>
      <Stack direction="row" spacing={1} alignItems="center">
        <ActiveStatusChip status={props.activeStatus} />
        {props.status === "INVITATION_CODE_ONLY" && (
          <Tooltip title={t("パスワード付きリンクを知っている人が受験可能")}>
            <KeyOutlinedIcon fontSize="small" color="primary" />
          </Tooltip>
        )}
        <Typography variant="h5" fontSize={20} fontWeight={700} noWrap>
          {props.title}
        </Typography>
      </Stack>

      <Box flexGrow={1} />
      <Stack direction="row" spacing={1.5} display="flex" alignItems="center" justifyContent="center">
        <InviteCandidateButtonWithDialog {...props.inviteCandidate} variant="contained" />
        <MoreButton {...moreButton} />
      </Stack>
    </StyledStack>
  );
};

ScreeningDetailHeader.displayName = "ScreeningDetailHeader";

export default ScreeningDetailHeader;
