import { App, Company } from "@hireroo/app-store/essential/employee";
import { OrderHistory } from "@hireroo/app-store/widget/e/OrderHistory";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import OrderHistoryContainer, { OrderHistoryContainerProps } from "./Container";

export type OrderHistoryFetchContainerProps = {};

const OrderHistoryFetchContainer: React.FC<OrderHistoryFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const companyId = Company.useStrictActiveCompanyId();
  const initialized = OrderHistory.useInitialized();
  const nextPager = OrderHistory.useNextPager();
  const fetchable = OrderHistory.useFetchable();
  const fetchSize = OrderHistory.useFetchSize();
  const purchasePeriod = OrderHistory.usePurchasePeriod();

  const [result, refresh] = Graphql.useGetPurchasesForOrderHistoryQuery({
    variables: {
      customerId: companyId,
      purchasePeriodStartSeconds: purchasePeriod.startSeconds,
      purchasePeriodEndSeconds: purchasePeriod.endSeconds,
      cursorSeconds: nextPager?.cursorSeconds ?? null,
      cursorNanoSeconds: nextPager?.cursorNanoSeconds ?? null,
      size: fetchSize,
    },
    pause: appStatus !== "INITIALIZED" || !fetchable,
  });

  React.useEffect(() => {
    if (result.data) {
      OrderHistory.updateResponse(result.data.purchases, result.data.company);
    }
  }, [result.data]);

  React.useEffect(() => {
    return () => {
      OrderHistory.clear();
    };
  }, []);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }

  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: OrderHistoryContainerProps = {};

  return <OrderHistoryContainer {...containerProps} />;
};

OrderHistoryFetchContainer.displayName = "OrderHistoryFetchContainer";

export default withErrorBoundary(OrderHistoryFetchContainer, {});
