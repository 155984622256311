import { QuestionSearchForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import SearchForm, { SearchFormProps } from "../../modules/SearchForm/SearchForm";
import * as PrivateHelper from "./privateHelper";

const SEARCH_FORM_HEIGHT = "82px";

const StyledSearchForm = styled(SearchForm)(() => ({
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  height: SEARCH_FORM_HEIGHT,
  marginBottom: "16px",
  flexShrink: 0,
  flexGrow: 1,
}));

export type QuestionSearchForResourceEditorProps = {
  disabledSearchForm: boolean;
  enableSkillTagFilter?: boolean;
  skillTagsField: PrivateHelper.SkillTagsFiledArgs;
  onChange: SubmitHandler<QuestionSearchForm.QuestionSearchQuerySchema>;
  availableVariants: QuestionSearchForm.QuestionVariant[];
  defaultValues: QuestionSearchForm.QuestionSearchQuerySchema;
};

const QuestionSearchForResourceEditor: React.FC<QuestionSearchForResourceEditorProps> = props => {
  const { onChange } = props;
  const textFilterField = PrivateHelper.useTextFilterField();
  const questionTypesField = PrivateHelper.useQuestionTypesField(props.availableVariants);
  const difficultyField = PrivateHelper.useDifficultyField();
  const skillTagsField = PrivateHelper.useSkillTagsField(props.skillTagsField);

  const validateSchema = QuestionSearchForm.useQuestionSearchQuery();
  const methods = useForm<QuestionSearchForm.QuestionSearchQuerySchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: props.defaultValues,
  });
  const changed = methods.watch();
  const [, setCachedValue] = React.useState(JSON.stringify(changed));
  React.useEffect(() => {
    const newValue = JSON.stringify(changed);
    setCachedValue(prev => {
      // Prevent double dispatching
      if (prev !== newValue) {
        methods.handleSubmit(onChange, console.warn)();
      }
      return newValue;
    });
  }, [methods, onChange, changed]);

  const searchFormProps: SearchFormProps = {
    kind: "MULTI_LINE",
    variant: "INHERIT",
    disabled: props.disabledSearchForm,
    textFilter: {
      ...textFilterField,
      sx: { height: 40 },
    },
    fields: [questionTypesField, difficultyField, props.enableSkillTagFilter && skillTagsField].filter(v => !!v),
    onReset: () => {
      const resetValues: QuestionSearchForm.QuestionSearchQuerySchema = {
        questionVariants: [],
        statuses: [],
        difficulties: [],
        textFilter: "",
        skillTags: [],
        leakScoreLevels: [],
      };
      methods.reset(resetValues);
    },
    textFilterFieldWrapperProps: {
      mb: 1.5,
    },
  };

  return (
    <FormProvider {...methods}>
      <StyledSearchForm {...searchFormProps} />
    </FormProvider>
  );
};

QuestionSearchForResourceEditor.displayName = "QuestionSearchForResourceEditor";

export default QuestionSearchForResourceEditor;
