import AppRegistrationOutlinedIcon from "@mui/icons-material/AppRegistrationOutlined";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import HomeOutlined from "@mui/icons-material/HomeOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import MonitorOutlined from "@mui/icons-material/MonitorOutlined";
import VideocamOutlined from "@mui/icons-material/VideocamOutlined";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { styled } from "@mui/material/styles";
import * as React from "react";

import Link from "../../../../../../primitive/Link/Link";
import type * as Types from "../../../../types";

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  width: "20px",
  minWidth: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  svg: {
    color: theme.palette.secondary.main,
    maxHeight: "20px",
    maxWidth: "20px",
  },
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: "6px 16px",
  width: "36px",
  height: "36px",
  display: "flex",
  justifyContent: "center",
  borderRadius: "4px",
  "&:hover": {
    color: theme.palette.secondary.light,
    backgroundColor: theme.palette["Secondary/Shades"].p16,
  },
}));

const StartIconMap: Record<Types.StartIcon, React.ReactNode> = {
  HOME: <HomeOutlined color="secondary" fontSize="small" />,
  MONITOR: <MonitorOutlined color="secondary" fontSize="small" />,
  ASSIGNMENT: <AssignmentIndOutlinedIcon color="secondary" fontSize="small" />,
  VIDEOCAM: <VideocamOutlined color="secondary" fontSize="small" />,
  REGISTRATION: <AppRegistrationOutlinedIcon color="secondary" fontSize="small" />,
  DOCUMENT: <InsertDriveFileOutlinedIcon color="secondary" fontSize="small" />,
};

export type NavigationMenuItemProps = Types.MenuItemWithoutChildren & {
  root?: boolean;
};

const NavigationMenuItem = React.forwardRef((props: NavigationMenuItemProps, ref: React.Ref<HTMLDivElement>) => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <StyledListItemButton onClick={props.onClick} ref={ref} component={Link} href={props.href}>
      {props.startIcon && <StyledListItemIcon>{StartIconMap[props.startIcon]}</StyledListItemIcon>}
    </StyledListItemButton>
  );
});

NavigationMenuItem.displayName = "NavigationMenuItem";

export default NavigationMenuItem;
