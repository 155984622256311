import { useTranslation } from "@hireroo/i18n";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";

export type QuestionSearchDialogProps = Pick<DialogWithCloseIconProps, "open" | "onClose"> & {
  SearchArea: React.ReactNode;
  Table: React.ReactNode;
  codeEditorButton: Pick<ButtonProps, "onClick">;
  drawingToolButton: Pick<ButtonProps, "onClick">;
};

const QuestionSearchDialog: React.FC<QuestionSearchDialogProps> = props => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { SearchArea, Table, codeEditorButton, drawingToolButton, ...rest } = props;

  const addCodeEditorButton: ButtonProps = {
    children: t("コードエディター"),
    startIcon: <AddCircleOutlineOutlinedIcon color="secondary" />,
    color: "secondary",
    variant: "outlined",
    ...codeEditorButton,
  };
  const addDrawingToolButton: ButtonProps = {
    children: t("ドローイングツール"),
    startIcon: <AddCircleOutlineOutlinedIcon color="secondary" />,
    color: "secondary",
    variant: "outlined",
    ...drawingToolButton,
  };

  const dialog: DialogWithCloseIconProps & { children: React.ReactNode } = {
    ...rest,
    title: t("新しいセッションを作成する"),
    children: (
      <Box>
        <Stack direction="column">
          <Stack direction="column" spacing={2} mb={4}>
            <Typography fontSize="14px" color={theme.palette.text.secondary}>
              {t("問題を選択せずセッションを追加する")}
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <Button {...addCodeEditorButton} />
              <Button {...addDrawingToolButton} />
            </Stack>
          </Stack>
          <Stack direction="column" mb={2}>
            <Typography mb={2} fontSize="14px" color={theme.palette.text.secondary}>
              {t("既存の問題から選択")}
            </Typography>
            {SearchArea}
          </Stack>
          {Table}
        </Stack>
      </Box>
    ),
    optionalDialog: {
      fullWidth: true,
      maxWidth: "md",
      PaperProps: {
        sx: {
          height: "80%",
          width: "75vw",
          minWidth: "75vw",
          bgcolor: theme.palette.background.paper,
        },
      },
    },
  };
  return <DialogWithCloseIcon {...dialog} />;
};

QuestionSearchDialog.displayName = "QuestionSearchDialog";

export default QuestionSearchDialog;
