import * as React from "react";
import { useForm, UseFormReturn } from "react-hook-form";

export type EntryTestContextProps = {};

export type ContextValue = {
  methods: UseFormReturn<{ agreement: boolean }>;
  enableEmailField: boolean;
};

export const EntryTestContext = React.createContext<ContextValue>({} as ContextValue);

export const useEntryTestContext = () => React.useContext(EntryTestContext);

export type EntryTestProviderProps = {
  enableEmailField: boolean;
};

export const EntryTestProvider: React.FC<React.PropsWithChildren<EntryTestProviderProps>> = props => {
  const methods = useForm({
    defaultValues: {
      agreement: false,
    },
  });

  const contextValue: ContextValue = {
    methods: methods,
    enableEmailField: props.enableEmailField,
  };
  return <EntryTestContext.Provider value={contextValue} children={props.children} />;
};
