import { Auth } from "@hireroo/app-store/essential/employee";
import { RemotesStore } from "@hireroo/app-store/page/e/remotes";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

export type GenerateRemoteInterviewSearchAreaPropsArgs = {};

type MultipleChoiceOption = Widget.RemoteInterviewSearchAreaProps["createdByField"]["options"][0];

export const useGenerateProps = (_args: GenerateRemoteInterviewSearchAreaPropsArgs): Widget.RemoteInterviewSearchAreaProps => {
  const currentUser = Auth.useCurrentUser();
  const tags = RemotesStore.useTags();
  const currentSearchFilter = RemotesStore.useCurrentSearchFilter();
  const initialized = RemotesStore.useResponseInitialized();
  const creators = RemotesStore.useCreators();

  const defaultValues = React.useMemo((): Widget.RemoteInterviewSearchAreaProps["defaultValues"] => {
    return {
      statuses: currentSearchFilter.statuses.slice(),
      createdBy: currentSearchFilter.createdBy.slice(),
      result: currentSearchFilter.result,
      tags: currentSearchFilter.tags.slice(),
      textFilter: currentSearchFilter.textFilter,
    };
  }, [currentSearchFilter]);

  const createdByFieldOptions: MultipleChoiceOption[] = React.useMemo(() => {
    return creators.reduce<MultipleChoiceOption[]>((acc, employee) => {
      const option: MultipleChoiceOption = {
        value: employee.id,
        displayName: employee.displayName || employee.email,
        iconSrc: employee.photoUrl,
      };

      if (employee.id === currentUser.uid) {
        return [option, ...acc];
      }

      return [...acc, option];
    }, []);
  }, [creators, currentUser.uid]);

  return {
    disabled: !initialized,
    createdByField: {
      options: createdByFieldOptions,
    },
    tagsField: {
      selectableTags: tags.map(tag => ({ displayName: tag.name })),
    },
    defaultValues: defaultValues,
    onChange: React.useCallback(fields => {
      RemotesStore.updateCurrentSearchFilter(fields);
    }, []),
  };
};
