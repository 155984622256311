import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type ConsoleInput = {
  name: string;
  type: string;
};

export type IDEConsoleTableProps = {
  tableColumns: ConsoleInput[];
  displayRecords: string[][];
  maxHeight?: number;
};

const IDEConsoleTable: React.FC<IDEConsoleTableProps> = props => {
  return (
    <TableContainer component={Paper} sx={{ maxHeight: props.maxHeight }}>
      <Table size={"small"} sx={{ backgroundColor: "background.paper" }} stickyHeader>
        <TableHead>
          <TableRow sx={{ backgroundColor: "background.default" }}>
            {props.tableColumns.map(column => (
              <TableCell key={`${column.name}-header`}>{`${column.name} (${column.type})`}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.displayRecords.map((record, rowIndex) => (
            <TableRow key={rowIndex}>
              {props.tableColumns.map((column: ConsoleInput, colIndex) => (
                <TableCell key={column.name} style={{ padding: "1px 7px", minHeight: "20px" }}>
                  <Typography ml={1} minHeight="inherit">
                    {record[colIndex]}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

IDEConsoleTable.displayName = "IDEConsoleTable";

export default IDEConsoleTable;
