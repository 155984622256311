import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Company } from "@hireroo/app-store/essential/employee";
import { MemberSettings } from "@hireroo/app-store/widget/e/MemberSettings";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/react";

import EmployeeGroupAssignFieldContainer from "./widget/EmployeeGroupAssignField/Container";

export type GenerateAddMemberToGroupPropsArgs = {
  reload: () => void;
};
export const useGenerateAddMembersToGroupsProps = (
  args: GenerateAddMemberToGroupPropsArgs,
): Widget.EmployeeMemberSettingsProps["addMembersToGroups"] => {
  const { reload } = args;
  const { t } = useTranslation();
  const addMembersToGroupsState = MemberSettings.useAddMembersToGroups();
  const dialogStatus = MemberSettings.useDialogStatus();
  const companyId = Company.useStrictActiveCompanyId();
  const client = getGraphqlClient();
  const selectedMembers = MemberSettings.useSelectedMembers();

  return {
    dialog: {
      open: dialogStatus === "ADD_GROUP_OPEN",
      onClose: () => {
        MemberSettings.updateDialogStatus("CLOSE");
      },
      noButton: {
        onClick: () => {
          MemberSettings.updateDialogStatus("CLOSE");
        },
      },
    },
    selectedMembers: addMembersToGroupsState.selectedMembers.map(employee => {
      return {
        id: employee.id,
        displayName: employee.displayName || employee.email,
        src: employee.photoUrl,
      };
    }),
    SearchGroups: <EmployeeGroupAssignFieldContainer selectableGroups={addMembersToGroupsState.selectableGroups} variant="SECONDARY" />,
    onSubmit: async fields => {
      const groupIds = fields.groups.reduce<string[]>((all, current) => {
        return current.value?.type === "EMPLOYEE_GROUP" ? [...all, current.value.groupId] : all;
      }, []);

      const requests = selectedMembers.employees.map(async employee => {
        return await client.AddEmployeeToGroups({
          companyId: companyId,
          groupIds: groupIds,
          employeeId: employee.id,
        });
      });
      await Promise.all(requests)
        .then(() => {
          Snackbar.notify({
            severity: "success",
            message: t("グループへの追加に成功しました。"),
          });
          MemberSettings.updateDialogStatus("CLOSE");
          MemberSettings.clear();
          reload();
        })
        .catch(error => {
          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("グループへの追加に失敗しました。"));
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
        });
    },
  };
};
