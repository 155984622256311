import { useTranslation } from "@hireroo/i18n";
import * as z from "zod";

export type ProjectTextInputDialogFormArgs = {
  existsList: string[];
};

export const useProjectTextInputDialogForm = (args: ProjectTextInputDialogFormArgs) => {
  const { t } = useTranslation();
  const validFileOrDirectoryName = /[\\/:*?"<>|]/;
  return z
    .object({
      value: z.string().min(1, t("必須項目です")),
    })
    .superRefine((obj, ctx) => {
      /**
       * trimは前後の空白を消してくれる。その上長さが0の場合はエラーとする
       */
      if (obj.value.trim().length === 0 || obj.value.match(validFileOrDirectoryName)) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_string,
          validation: "regex",
          path: ["value"],
          message: t("利用できない文字列が含まれています"),
        });
      }
      if (args.existsList.includes(obj.value)) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_string,
          validation: "regex",
          path: ["value"],
          message: t("同じ名前のファイルまたは、ディレクトリが存在します。"),
        });
      }
    });
};

export type ProjectTextInputDialogFormSchema = z.infer<ReturnType<typeof useProjectTextInputDialogForm>>;
