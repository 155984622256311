import { LanguageMap, languageMap } from "@hireroo/app-definition";
import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Auth } from "@hireroo/app-store/essential/employee";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/server/types";
import { useChangeLanguage, useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateHelpCenterUrl } from "@hireroo/router/api";
import * as Sentry from "@sentry/browser";
import * as React from "react";
import { useLocation } from "react-router";

import EmployeeMenuContainer from "../EmployeeMenu/Container";
import NotificationListContainer from "./widget/NotificationList/Container";

export type GenerateEmployeeHeaderRightContentPropsArgs = {};

export const useGenerateProps = (_args: GenerateEmployeeHeaderRightContentPropsArgs): Widget.EmployeeHeaderRightContentProps => {
  const { t } = useTranslation();
  const currentUser = Auth.useCurrentUser();
  const client = getGraphqlClient();
  const lang = useLanguageCode();
  const location = useLocation();
  const changeLanguage = useChangeLanguage();

  const updateLanguage = React.useCallback(
    async (lang: Graphql.Language): Promise<void> => {
      await client
        .UpdateUserForUserProfile({
          updateUserInput: {
            id: currentUser.uid,
            displayName: currentUser.displayName,
            language: lang,
            photoUrl: currentUser.photoUrl,
          },
        })
        .then(res => {
          changeLanguage(LanguageMap[res.updateUser.language]);
          Snackbar.notify({
            severity: "success",
            message: t("ユーザ情報を更新しました。"),
          });
          Auth.setUser(res.updateUser);
        })
        .catch(error => {
          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(
            error,
            t("ユーザ情報の更新に失敗しました。しばらくしてから再度お試し頂くか、ヘルプボタンよりお問い合わせください。"),
          );
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
        });
    },
    [changeLanguage, client, currentUser.displayName, currentUser.photoUrl, currentUser.uid, t],
  );

  const helpPageUrl = React.useMemo(() => {
    if (location.pathname.includes("/e/assessments") && location.pathname.includes("detail")) {
      return generateHelpCenterUrl(lang, "ASSESSMENT_EMPLOYEE_DETAIL_PAGE");
    }
    if (location.pathname.includes("/e/assessments")) {
      return generateHelpCenterUrl(lang, "ASSESSMENT_EMPLOYEE_LIST_PAGE");
    }
    return generateHelpCenterUrl(lang, "ROOT");
  }, [lang, location]);

  return {
    EmployeeMenu: <EmployeeMenuContainer />,
    NotificationButton: <NotificationListContainer />,
    helpButton: {
      href: helpPageUrl,
      target: "_blank",
    },
    preferences: {
      languageDropDownMenu: {
        onChangeLanguage: language => {
          updateLanguage(languageMap[language]);
        },
      },
    },
  };
};
