import { useTranslation } from "@hireroo/i18n";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import Box from "@mui/material/Box";
import Button, { type ButtonProps } from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledButton = styled(Button)(() => ({
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  overflow: "hidden",
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&.MuiMenuItem-root": {
    "&:hover ": {
      backgroundColor: theme.palette["Secondary/Shades"].p8,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette["Secondary/Shades"].p16,
    },
  },
}));

type Option = {
  id: string;
  displayText: string;
  onClick?: () => void;
  selected?: boolean;
  score?: number;
  isBest?: boolean;
};

export type ExamRetriedSelectorProps = {
  options: Option[];
  retriedCount: number;
};

const ExamRetriedSelector: React.FC<ExamRetriedSelectorProps> = props => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const defaultOption = React.useMemo((): Option => {
    return (
      props.options[0] || {
        id: "",
        displayText: "",
      }
    );
  }, [props.options]);

  const [selectedOption, setSelectedOption] = React.useState<Option>(defaultOption);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOnClick = React.useCallback((newOption: Option) => {
    setSelectedOption(newOption);
    handleClose();
  }, []);

  const buttonProps: ButtonProps = {
    onClick: handleClick,
    variant: "outlined",
    startIcon: <RestoreOutlinedIcon color="secondary" />,
    color: "secondary",
    children: selectedOption.displayText,
    size: "small",
    endIcon: <Chip size="small" style={{ fontSize: "12px" }} color="secondary" label={props.retriedCount} />,
  };
  return (
    <Box>
      <StyledButton {...buttonProps} />
      <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} keepMounted onClose={handleClose}>
        {props.options.map(option => (
          <StyledMenuItem
            key={option.id}
            value={option.id}
            selected={option.selected}
            onClick={() => {
              option.onClick?.();
              handleOnClick(option);
            }}
          >
            <Stack>
              <Typography noWrap>{option.displayText}</Typography>
              {option.score !== undefined && (
                <Typography noWrap>{`${option.isBest ? t("最高") : ""}${t("総合点")}: ${option.score}${t("点")}`}</Typography>
              )}
            </Stack>
          </StyledMenuItem>
        ))}
      </Menu>
    </Box>
  );
};

ExamRetriedSelector.displayName = "ExamRetriedSelector";

export default ExamRetriedSelector;
