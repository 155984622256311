import { useTranslation } from "@hireroo/i18n";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack, { StackProps } from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";

import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "../../../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";

const SquareButton = styled(IconButtonWithTooltip)(({ theme }) => ({
  minWidth: "32px",
  width: "32px",
  height: "32px",
  minHeight: "32px",
  color: theme.palette.text.primary,
  textTransform: "none",
}));

const MoveButton = styled(SquareButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  width: "32px",
  height: "32px",
}));

const MoveButtonWrapper = styled(Stack)(() => ({
  height: "32px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const SelectQuestionsButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  textTransform: "none",
  wordBreak: "keep-all",
}));

const LeftWrapper = styled(Stack)(() => ({
  display: "flex",
  justifyContent: "center",
  flexShrink: 0,
  alignItems: "center",
}));

export type SelectedQuestionControlBarProps = {
  mt?: StackProps["mt"];
  upButton: Pick<IconButtonWithTooltipProps, "onClick" | "disabled">;
  downButton: Pick<IconButtonWithTooltipProps, "onClick" | "disabled">;
  removeButton: Pick<IconButtonWithTooltipProps, "onClick" | "disabled">;
  selectQuestionsButton?: Pick<ButtonProps, "onClick">;
};

const SelectedQuestionControlBar: React.FC<SelectedQuestionControlBarProps> = props => {
  const { t } = useTranslation();
  const unselectButtonProps: IconButtonWithTooltipProps = {
    ...props.removeButton,
    children: <DeleteOutlineOutlinedIcon fontSize="small" />,
    title: t("出題問題から削除する"),
  };
  const moveForwardButton: IconButtonWithTooltipProps = {
    ...props.upButton,
    children: <ArrowUpwardOutlinedIcon fontSize="small" />,
    title: props.upButton.disabled ? t("移動できません") : t("前に移動する"),
  };
  const moveBackwardButton: IconButtonWithTooltipProps = {
    ...props.downButton,
    children: <ArrowDownwardOutlinedIcon fontSize="small" />,
    title: props.downButton.disabled ? t("移動できません") : t("後に移動する"),
  };
  const selectQuestions: ButtonProps | undefined = props.selectQuestionsButton
    ? {
        ...props.selectQuestionsButton,
        children: t("問題選択"),
        variant: "text",
      }
    : undefined;
  return (
    <Stack direction="row" mt={props.mt}>
      <MoveButtonWrapper direction="row" spacing={1.5}>
        <MoveButton {...moveForwardButton} />
        <MoveButton {...moveBackwardButton} />
      </MoveButtonWrapper>
      <Box sx={{ flexGrow: 1 }} />
      <LeftWrapper direction="row" spacing={3}>
        {selectQuestions && <SelectQuestionsButton {...selectQuestions} />}
        <SquareButton {...unselectButtonProps} />
      </LeftWrapper>
    </Stack>
  );
};

SelectedQuestionControlBar.displayName = "SelectedQuestionControlBar";

export default SelectedQuestionControlBar;
