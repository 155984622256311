import * as React from "react";

import { AlgorithmAnswersFormProvider, AlgorithmAnswersFormProviderProps } from "../../widget/AlgorithmAnswersForm/AlgorithmAnswersForm";
import {
  AlgorithmFunctionDefinitionProvider,
  AlgorithmFunctionDefinitionProviderProps,
} from "../../widget/AlgorithmFunctionDefinition/AlgorithmFunctionDefinition";
import { AlgorithmHintsFormProvider, AlgorithmHintsFormProviderProps } from "../../widget/AlgorithmHintsForm/AlgorithmHintsForm";
import {
  AlgorithmQuestionDetailFormProvider,
  AlgorithmQuestionDetailFormProviderProps,
} from "../../widget/AlgorithmQuestionDetailForm/AlgorithmQuestionDetailForm";
import {
  AlgorithmTestCasesFormProvider,
  AlgorithmTestCasesFormProviderProps,
} from "../../widget/AlgorithmTestCasesForm/AlgorithmTestCasesForm";
import {
  DatabaseTableSignatureProvider,
  DatabaseTableSignatureProviderProps,
} from "../../widget/DatabaseTableSignature/DatabaseTableSignature";

export type ContextValue = {};

export const AlgorithmResourceEditorContext = React.createContext<ContextValue>({} as ContextValue);

export const useAlgorithmResourceEditorContext = () => React.useContext(AlgorithmResourceEditorContext);

export type AlgorithmResourceEditorProviderProps = {
  questionDetailProvider: AlgorithmQuestionDetailFormProviderProps;
  functionDefinitionProvider: AlgorithmFunctionDefinitionProviderProps;
  testCasesFormProvider: AlgorithmTestCasesFormProviderProps;
  answersFormProvider: AlgorithmAnswersFormProviderProps;
  hintFormProvider: AlgorithmHintsFormProviderProps;
  tableSignature: DatabaseTableSignatureProviderProps;
};

export const AlgorithmResourceEditorProvider: React.FC<React.PropsWithChildren<AlgorithmResourceEditorProviderProps>> = props => {
  const contextValue: ContextValue = {};
  return (
    <AlgorithmQuestionDetailFormProvider {...props.questionDetailProvider}>
      <AlgorithmFunctionDefinitionProvider {...props.functionDefinitionProvider}>
        <DatabaseTableSignatureProvider {...props.tableSignature}>
          <AlgorithmTestCasesFormProvider {...props.testCasesFormProvider}>
            <AlgorithmAnswersFormProvider {...props.answersFormProvider}>
              <AlgorithmHintsFormProvider {...props.hintFormProvider}>
                <AlgorithmResourceEditorContext.Provider value={contextValue} children={props.children} />
              </AlgorithmHintsFormProvider>
            </AlgorithmAnswersFormProvider>
          </AlgorithmTestCasesFormProvider>
        </DatabaseTableSignatureProvider>
      </AlgorithmFunctionDefinitionProvider>
    </AlgorithmQuestionDetailFormProvider>
  );
};
