export class Retain {
  revision: string;
  author: string;
  index: number;
  val: number;
  timestamp: number;

  constructor(revision: string, author: string, index: number, val: number, timestamp: number) {
    this.revision = revision;
    this.author = author;
    this.index = index;
    this.val = val;
    this.timestamp = timestamp;
  }
}

export class Insertion {
  revision: string;
  author: string;
  index: number;
  text: number[];
  timestamp: number;

  constructor(revision: string, author: string, index: number, text: number[], timestamp: number) {
    this.revision = revision;
    this.author = author;
    this.index = index;
    this.text = text;
    this.timestamp = timestamp;
  }
}

export class Deletion {
  revision: string;
  author: string;
  index: number;
  val: number;
  timestamp: number;

  constructor(revision: string, author: string, index: number, val: number, timestamp: number) {
    this.revision = revision;
    this.author = author;
    this.index = index;
    this.val = val;
    this.timestamp = timestamp;
  }
}
