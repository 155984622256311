import "react-datepicker/dist/react-datepicker.css";
import "./DateTimePicker.css";

import TextField, { TextFieldProps } from "@mui/material/TextField";
import { enUS, ja } from "date-fns/locale";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import * as React from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";

type LanguageType = "ja" | "en";

type FilterFunction = (date: Date) => boolean;

export type DateTimePickerProps = {
  /** If this parameter is specified, min and max are not available  */
  filterDateFn?: FilterFunction;
  filterTimeFn?: FilterFunction;
  min?: Date;
  max?: Date;
  lang: LanguageType;
  customInput: TextFieldProps;
  datePicker: Omit<ReactDatePickerProps, "locale" | "injectTimes" | "timeIntervals" | "filterDate" | "filterTime">;
};

const dateTimeFormatMap: Record<LanguageType, string> = {
  ja: "yyyy年MM月dd日 HH:mm",
  en: "MMMM d, yyyy h:mm aa",
};

const dateFormatMap: Record<LanguageType, string> = {
  ja: "yyyy年MM月dd日",
  en: "MMMM d, yyyy",
};

const DateTimePicker: React.FC<DateTimePickerProps> = props => {
  const { min, max, filterDateFn, filterTimeFn } = props;
  const dateTimePickerProps: ReactDatePickerProps = {
    ...props.datePicker,
    locale: props.lang === "en" ? enUS : ja,
  };

  const filterDateAndTime = React.useCallback(
    (time: Date) => {
      let minAccept = true;
      let maxAccept = true;
      if (min) {
        minAccept = time.getTime() >= min.getTime();
      }
      if (max) {
        maxAccept = time.getTime() < max.getTime();
      }
      return minAccept && maxAccept;
    },
    [max, min],
  );

  return (
    <DatePicker
      {...dateTimePickerProps}
      autoComplete="off"
      customInput={<TextField {...props.customInput} size="small" />}
      injectTimes={[setHours(setMinutes(new Date(), 59), 23)]}
      timeIntervals={15}
      dateFormat={props.datePicker.showTimeSelect ? dateTimeFormatMap[props.lang] : dateFormatMap[props.lang]}
      filterDate={filterDateFn || filterDateAndTime}
      filterTime={filterTimeFn || filterDateAndTime}
    />
  );
};

DateTimePicker.displayName = "DateTimePicker";

export default DateTimePicker;
