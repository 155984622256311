import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type AssessmentReportContainerProps = {};

const AssessmentReportContainer: React.FC<AssessmentReportContainerProps> = () => {
  const assessmentReportProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.AssessmentReport {...assessmentReportProps} />
    </ErrorBoundary>
  );
};

AssessmentReportContainer.displayName = "AssessmentReportContainer";

export default withErrorBoundary(AssessmentReportContainer, {});
