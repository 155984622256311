import { Doughnut, DoughnutProps } from "@hireroo/charts2/react/Doughnut";
import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const Wrapper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  width: 384,
  height: 236,
}));

const ContentWrapper = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const NoDataLabel = styled(Typography)(({ theme }) => ({
  position: "relative",
  width: "100%",
  textAlign: "center",
  fontWeight: 700,
  color: theme.palette.text.secondary,
  fontSize: 24,
  top: -16,
}));

export type TimeReportPanelProps = {
  graph: Pick<DoughnutProps, "items">;
};

const TimeReportPanel: React.FC<TimeReportPanelProps> = props => {
  const { t } = useTranslation();
  const doughnutProps: DoughnutProps = {
    ...props.graph,
  };

  return (
    <Wrapper elevation={2}>
      <Stack spacing={2} direction="column" height="100%">
        <Box>
          <Typography variant="subtitle1">{t("時間割合")}</Typography>
        </Box>
        <ContentWrapper>
          {props.graph.items.length === 0 && <NoDataLabel variant="subtitle1">NO DATA</NoDataLabel>}
          {props.graph.items.length > 0 && <Doughnut {...doughnutProps} />}
        </ContentWrapper>
      </Stack>
    </Wrapper>
  );
};

TimeReportPanel.displayName = "TimeReportPanel";

export default TimeReportPanel;
