import Logo from "@hireroo/ui-assets/images/Logo/Logo.png";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";

import DropDownMenu, { DropDownMenuProps } from "../../primitive/DropDownMenu/DropDownMenu";
import InterviewNavigationMenu, { InterviewNavigationMenuProps } from "./parts/InterviewNavigationMenu/InterviewNavigationMenu";

const HEIGHT = 48;

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  height: HEIGHT,
  position: "fixed",
  backgroundColor: theme.palette.background.paper,
  justifyContent: "center",
  boxShadow: "none",
}));

export type ScreeningTestReviewNavigationProps = {
  interviewNavigationMenu?: InterviewNavigationMenuProps | false;
  languageMenu: DropDownMenuProps;
  RightMenu?: React.ReactNode | false;
};

const ScreeningTestReviewNavigation: React.FC<ScreeningTestReviewNavigationProps> = props => {
  return (
    <StyledAppBar>
      <Stack direction="row" alignItems="center" justifyContent="space-between" px={1.5} width="100%" height="100%">
        <Box display="flex" justifyContent="space-between" width="100%">
          <Stack direction="row" spacing={2} alignItems="center">
            <img src={Logo} width={100} alt="hireroo" />
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            {props.interviewNavigationMenu && <InterviewNavigationMenu {...props.interviewNavigationMenu} />}
            <Box display="inherit" right={2} mr={1} ml={2} alignSelf="center">
              <DropDownMenu {...props.languageMenu} />
            </Box>
          </Stack>
        </Box>
      </Stack>
    </StyledAppBar>
  );
};

ScreeningTestReviewNavigation.displayName = "ScreeningTestReviewNavigation";

export default ScreeningTestReviewNavigation;
