import type * as Graphql from "@hireroo/graphql/client/urql";

export const variantMap: Record<Graphql.FreepadQuestionVariant, "ALGORITHM" | "SYSTEM_DESIGN"> = {
  ALGORITHM: "ALGORITHM",
  SYSTEM_DESIGN: "SYSTEM_DESIGN",
  // set Algorithm for default since Database is not used
  DATABASE: "ALGORITHM",
  CLASS: "ALGORITHM",
  UNKNOWN: "ALGORITHM",
};
