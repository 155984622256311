import * as Graphql from "@hireroo/graphql/client/urql";

import type * as Types from "./types";

export const SortFields = {
  CREATED_AT_DESCENDING: "created-at-descending",
  CREATED_AT_ASCENDING: "created-at-ascending",
  RELATIVE_SCORE_DESCENDING: "relative-score-descending",
  RELATIVE_SCORE_ASCENDING: "relative-score-ascending",
} satisfies Record<string, Types.SortFieldValue>;

export const SortParams = {
  [SortFields.CREATED_AT_DESCENDING]: { sortMethod: "CREATED_AT", isDescending: true },
  [SortFields.CREATED_AT_ASCENDING]: { sortMethod: "CREATED_AT", isDescending: false },
  [SortFields.RELATIVE_SCORE_DESCENDING]: { sortMethod: "RELATIVE_SCORE", isDescending: true },
  [SortFields.RELATIVE_SCORE_ASCENDING]: { sortMethod: "RELATIVE_SCORE", isDescending: false },
} satisfies Record<string, { isDescending: boolean; sortMethod: Graphql.AssessmentSortMethod }>;

export const QueryKeys = {
  TALENT: "talent",
  VIEWER: "viewer",
  SCORE_TREND: "score_trend",
  SUBMIT_STATUS: "submit_status",
  ASSESSMENT_STATUS: "status",
  TITLE: "title",
  PAGE: "page",
  SIZE: "size",
  SORT_METHOD: "sort_method",
};

export const DefaultPager: Types.Pager = {
  page: 0,
  size: 20,
  offset: 0,
  sortFieldValue: SortFields.CREATED_AT_DESCENDING,
};
