import CodeEditor, { CodeEditorProps } from "@hireroo/code-editor/react/CodeEditor";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import * as React from "react";
import { Control, Controller } from "react-hook-form";

export type InputControlCodeEditorProps = CodeEditorProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any>;
};

const InputControlCodeEditor: React.FC<InputControlCodeEditorProps & { name: string }> = props => {
  const { control, name, ...rest } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Box width="100%" height="100%">
          <React.Suspense>
            <CodeEditor
              {...rest}
              onMount={editor => {
                editor.setValue(value);
              }}
              onChange={onChange}
              value={value}
            />
          </React.Suspense>
          <FormHelperText children={Boolean(error) && error?.message} focused error={Boolean(error)} />
        </Box>
      )}
    />
  );
};

InputControlCodeEditor.displayName = "InputControlCodeEditor";

export default InputControlCodeEditor;
