import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type QuestionPackageDetailContainerProps = {};

const QuestionPackageDetailContainer: React.FC<QuestionPackageDetailContainerProps> = () => {
  const questionPackageDetailProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.QuestionPackageDetail {...questionPackageDetailProps} />
    </ErrorBoundary>
  );
};

QuestionPackageDetailContainer.displayName = "QuestionPackageDetailContainer";

export default withErrorBoundary(QuestionPackageDetailContainer, {});
