import * as React from "react";

import LiveCodingLayout, { LiveCodingLayoutProps } from "../../layouts/LiveCodingLayout/LiveCodingLayout";
import MessageForCandidate from "./parts/MessageForCandidate/MessageForCandidate";
import MessageForEmployee, { MessageForEmployeeProps } from "./parts/MessageForEmployee/MessageForEmployee";

export type WaitingRoomProps = {
  layout: Omit<LiveCodingLayoutProps, "children">;
  content: { type: "FOR_CANDIDATE" } | { type: "FOR_EMPLOYEE"; props: MessageForEmployeeProps };
};

const WaitingRoom: React.FC<WaitingRoomProps> = props => {
  return (
    <LiveCodingLayout {...props.layout}>
      {props.content.type === "FOR_CANDIDATE" && <MessageForCandidate />}
      {props.content.type === "FOR_EMPLOYEE" && <MessageForEmployee {...props.content.props} />}
    </LiveCodingLayout>
  );
};

WaitingRoom.displayName = "WaitingRoom";

export default WaitingRoom;
