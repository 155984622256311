import Article from "@mui/icons-material/Article";
import Settings from "@mui/icons-material/Settings";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";

const iconMap = {
  Article: <Article fontSize="small" color="inherit" />,
  Settings: <Settings fontSize="small" color="inherit" />,
} as const;

export type FieldHeaderProps = {
  icon: keyof typeof iconMap;
  title: string;
};

const FieldHeader: React.FC<FieldHeaderProps> = props => {
  return (
    <Box display="flex" alignItems="center" gap={2}>
      {iconMap[props.icon]}
      <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
        {props.title}
      </Typography>
    </Box>
  );
};

FieldHeader.displayName = "FieldHeader";

export default FieldHeader;
