import { subscribe } from "valtio";

import { state } from "./State";
import { State } from "./types";

export type SubscribeStateCallback = (state: State) => void;
export const subscribeAllState = (callback: SubscribeStateCallback) => {
  return subscribe(state, () => {
    return callback(state);
  });
};
