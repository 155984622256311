import Close from "@mui/icons-material/Close";
import Box, { BoxProps } from "@mui/material/Box";
import Tab, { TabProps } from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import * as React from "react";

import IconButton, { IconButtonProps } from "../../../primitive/Button/IconButton";

const a11yProps = (id: string) => {
  return {
    id: `close-tab-${id}`,
    "aria-controls": `close-tabpanel-${id}`,
  };
};

export type ClosableTabProps = TabProps & {
  labelText: string;
  closeButton?: IconButtonProps;
  labelBox?: BoxProps;
};

//TODO: move to primitive
const ClosableTab: React.FC<ClosableTabProps> = props => {
  const { labelText, closeButton, labelBox, ...rest } = props;
  return (
    <Tab
      {...rest}
      {...a11yProps(props.labelText)}
      sx={{ px: 1 }}
      label={
        <Box justifyContent="space-between" display="flex" ml={1} gap={1} alignItems="center" {...labelBox}>
          <Typography>{labelText}</Typography>
          {props.closeButton && (
            <IconButton component="span" id={props.labelText} aria-labelledby={props.labelText} size="small" {...closeButton}>
              <Close fontSize="small" />
            </IconButton>
          )}
        </Box>
      }
    />
  );
};

ClosableTab.displayName = "ClosableTab";

export default ClosableTab;
