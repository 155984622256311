import Box, { BoxProps } from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";

import { IconProps } from "../../../../subblocks";
import Icon from "../../../../subblocks/Icon/Icon";

const IconBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "0px 0px 4px 0px",
  height: "24px",
  width: "24px",
  backgroundColor: theme.palette.background.default,
}));

const StyledBox = styled(Box)(() => ({
  display: "flex",
  position: "relative",
  width: "100%",
}));

const StyledImage = styled("img")`
  border-radius: 4px;
  width: 100%;
`;

export type ImageProps = {
  src: string;
  alt: string;
  icon?: IconProps;
  onClick?: BoxProps["onClick"];
};

const Image: React.FC<ImageProps> = props => {
  return (
    <StyledBox onClick={props.onClick} sx={{ cursor: props.onClick ? "pointer" : undefined }}>
      {props.icon && (
        <IconBox>
          <Icon {...props.icon} />
        </IconBox>
      )}
      <StyledImage src={props.src} alt={props.alt} />
    </StyledBox>
  );
};

Image.displayName = "Image";

export default Image;
