import { useSnapshot } from "valtio";

import { state } from "./State";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useRegisterSonar = () => {
  const snapshot = useSnapshotState();
  return snapshot.registerSonar;
};

export const useDeleteSonar = () => {
  const snapshot = useSnapshotState();
  return snapshot.deleteSonar;
};

export const useSonarIntegrations = () => {
  const snapshot = useSnapshotState();
  return Array.from(snapshot.sonarIntegrationMap.values());
};

const useScreenings = () => {
  const snapshot = useSnapshotState();
  /**
   * filter out the screening with restricted access policy
   */
  return Array.from(snapshot.screeningMap.values()).filter(screening => screening.candidateAccessPolicy === "ALLOW_ALL");
};
export const useNonIntegratedScreenings = () => {
  const screenings = useScreenings();
  const sonarIntegrations = useSonarIntegrations();
  const integratedScreeningIdSet = new Set(sonarIntegrations.map(s => s.screeningId));
  return screenings.filter(s => !integratedScreeningIdSet.has(s.screeningId));
};
export const useCurrentPageSonarIntegrationsV2 = () => {
  const snapshot = useSnapshotState();
  const sonarIntegrations = useSonarIntegrations();
  if (sonarIntegrations.length === 0) {
    return [];
  }
  const pagination = snapshot.sonarPagination;
  return sonarIntegrations
    .slice(pagination.page * pagination.size, (pagination.page + 1) * pagination.size)
    .sort((a, b) => (b.createdAtSeconds || 0) - (a.createdAtSeconds || 0));
};

export const useSonarPagination = () => {
  const snapshot = useSnapshotState();
  return snapshot.sonarPagination;
};

export const useScreeningPagination = () => {
  const snapshot = useSnapshotState();
  return snapshot.screeningPagination;
};
