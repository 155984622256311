import { useSnapshot } from "valtio";

import { state } from "./State";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = () => {
  const snapshot = useSnapshotState();
  return snapshot.activeCompanyId !== null;
};

export const useActiveCompanyId = () => {
  const snapshot = useSnapshotState();
  return snapshot.activeCompanyId;
};

export const useStrictActiveCompanyId = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.activeCompanyId) {
    throw new Error("Please Initialize Company Store");
  }
  return snapshot.activeCompanyId;
};

export const useActiveCompany = () => {
  const snapshot = useSnapshotState();
  return snapshot.companies.find(company => company.companyId === snapshot.activeCompanyId);
};

export const useErrorStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.errorStatus;
};

export const useCompanies = () => {
  const snapshot = useSnapshotState();
  return snapshot.companies;
};
