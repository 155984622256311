import * as ProjectHelperV2 from "@hireroo/app-helper/project-v2";
import * as ProjectHelperV3 from "@hireroo/app-helper/project-v3";
import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import { formatScore } from "@hireroo/formatter/score";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import { ProjectFrontendTestCaseSchemaV2, ProjectFrontendTestCaseSchemaV3 } from "@hireroo/validator";
import * as React from "react";

type NormalizeTestCase = {
  commands: { id: string; action: string; body: string; condition: string; expected: string }[];
  description_ja?: string;
  description_en?: string;
  is_hidden: boolean;
};

const normalizeV2toTestCase = (input: ProjectFrontendTestCaseSchemaV2.FrontendCorrectnessTestCases): NormalizeTestCase[] => {
  return input.map(
    (tc): NormalizeTestCase => ({
      commands: tc.commands,
      /**
       * No is_hidden field exists in v2, so it is covered by a fixed value
       */
      is_hidden: false,
    }),
  );
};

const normalizeV3toTestCase = (input: ProjectFrontendTestCaseSchemaV3.FrontendCorrectnessTestCasesSchema): NormalizeTestCase[] => {
  return input.test_cases.map(
    (tc): NormalizeTestCase => ({
      commands: tc.test_case_commands,
      description_ja: tc.description_ja,
      description_en: tc.description_en,
      is_hidden: tc.is_hidden,
    }),
  );
};

type NormalizeTestResult = {
  results: { latency: number; is_passed: boolean; screenshot: string }[];
  is_passed: boolean;
  status?: "EVALUATED" | "FAILED";
  failure_reason?: "SERVER_HEALTH_CHECK_FAILED" | "EVALUATION_PREPARATION_FAILED" | "";
};

const normalizeV2toTestResults = (input: ProjectFrontendTestCaseSchemaV2.FrontendCorrectnessTestCaseResults): NormalizeTestResult[] => {
  return input.map(
    (tr): NormalizeTestResult => ({
      results: tr.results,
      is_passed: tr.is_passed,
    }),
  );
};

const normalizeV3toTestResults = (input: ProjectFrontendTestCaseSchemaV3.FrontendCorrectnessTestCaseResultsSchema): NormalizeTestResult[] => {
  return input.test_results.map((tr: NormalizeTestResult) => ({
    results: tr.results,
    is_passed: tr.is_passed,
  }));
};

export type GenerateTestCaseSectionPropsForFrontendArgs = {
  entityId: number;
  uniqueKey: ProjectTestReport.UniqueKey;
  showScore: boolean;
};

type FrontendTestCaseProps = Extract<
  Exclude<Widget.ProjectTestReportProps["testCaseSection"], undefined>["content"],
  { kind: "FRONTEND" }
>["testCases"][0];

export const useGenerateTestCaseSectionPropsForFrontend = (
  args: GenerateTestCaseSectionPropsForFrontendArgs,
): Widget.ProjectTestReportProps["testCaseSection"] => {
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const hooks = ProjectTestReport.useCreateProjectHooks(args.entityId);
  const submission = hooks.useCurrentSubmission();
  const question = hooks.useQuestion();
  const testCasesResults = React.useMemo(() => {
    if (!submission?.correctnessTestResult) {
      return [];
    }
    if (question?.projectVersion.startsWith("v3")) {
      const parsed = ProjectHelperV3.FrontendTestCase.parseFrontendCorrectnessTestCaseResults(submission.correctnessTestResult);
      if (!parsed) return [];
      return normalizeV3toTestResults(parsed);
    } else {
      return normalizeV2toTestResults(
        ProjectHelperV2.FrontendTestCase.parseFrontendCorrectnessTestCaseResults(submission.correctnessTestResult) ?? [],
      );
    }
  }, [question?.projectVersion, submission?.correctnessTestResult]);

  const scenarioTestAction = ProjectHelperV3.FrontendTestCase.useScenarioTestAction();

  const testCases = React.useMemo(() => {
    if (!question?.correctnessTestCase) {
      return [];
    }
    if (question?.projectVersion.startsWith("v3")) {
      const parsed = ProjectHelperV3.FrontendTestCase.parseFrontendTestCases(question.correctnessTestCase);
      if (!parsed) return [];
      return normalizeV3toTestCase(parsed);
    } else {
      return normalizeV2toTestCase(ProjectHelperV2.FrontendTestCase.parseFrontendTestCases(question.correctnessTestCase)) ?? [];
    }
  }, [question?.correctnessTestCase, question?.projectVersion]);

  return {
    scrollTargetElementId: ProjectTestReport.TargetElementIdMap.CORRECT_RATE_SECTION,
    scoreAnswerRateDisplayLabel: {
      numPassed: submission?.numPassed ?? 0,
      numTests: submission?.numTests ?? 0,
    },
    titleWithScoreBar: {
      score: args.showScore ? formatScore(submission?.coverage ?? 0) : undefined,
    },
    content: {
      kind: "FRONTEND",
      testCases: (testCasesResults || []).map((testCasesResult, index): FrontendTestCaseProps => {
        const testCase = testCases.at(index);
        return {
          invisible: testCase?.is_hidden ?? false,
          title: [t("テストシナリオ"), index + 1].join(" "),
          description:
            testCase?.description_ja && testCase?.description_en
              ? resolveLanguage({ descriptionJa: testCase.description_ja, descriptionEn: testCase.description_en }, lang, "description")
              : undefined,
          status: "SUCCESS",
          testResults: testCasesResult.results.map((result, commandIndex) => {
            return {
              index: commandIndex + 1,
              status: result.is_passed ? "SUCCESS" : "FAIL",
              screenshotUrl: result.screenshot,
              message: testCase?.commands[commandIndex] ? scenarioTestAction(testCase.commands[commandIndex]) : "",
            };
          }),
        };
      }),
    },
  };
};
