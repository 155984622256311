import { useMethods } from "@hireroo/react-split";
import * as React from "react";

export const SplitParams = {
  splitId: "RemoteInterviewForEmployee",
  splitIdWithoutLeftSidePane: "RemoteInterviewForEmployee-WithoutLeftSidePane",
  Contents: {
    LeftSideBar: {
      id: "LeftSideBar",
    },
    Main: {
      id: "Main",
    },
    RightSideBar: {
      id: "RightSideBar",
    },
  },
};

/**
 * presentation/src/widgets/RemoteInterviewSystemDesignCodingEditor/privateHelper.ts
 */
export const SplitParamsForRemoteInterviewSystemDesignCodingEditor = {
  splitId: "RemoteInterviewSystemDesignCodingEditor",
  Contents: {
    SelectComponentArea: {
      id: "SelectComponentArea",
    },
    DrawingArea: {
      id: "DrawingArea",
    },
  },
};

export type UseSplitEventArgs = {
  currentSplitId: string;
};

export const useSplitEvent = (args: UseSplitEventArgs) => {
  const methods = useMethods();
  const [leftSideBarVisibleStatus, setLeftSideBarVisibleStatus] = React.useState<"MINIMIZED" | "OPEN">("OPEN");
  const [mainContentVisibleStatus, setMainContentVisibleStatus] = React.useState<"MINIMIZED" | "OPEN">("OPEN");
  const [rightSideBarVisibleStatus, setRightSideBarVisibleStatus] = React.useState<"MINIMIZED" | "OPEN">("OPEN");
  const [selectComponentAreaVisibleStatus, setSelectComponentAreaVisibleStatus] = React.useState<"MINIMIZED" | "OPEN">("OPEN");

  React.useEffect(() => {
    const cleanup1 = methods.subscribeChangeEvent(args.currentSplitId, event => {
      if (event.type === "MINIMIZED") {
        if (event.contentId === SplitParams.Contents.LeftSideBar.id) {
          setLeftSideBarVisibleStatus("MINIMIZED");
        }
        if (event.contentId === SplitParams.Contents.Main.id) {
          setMainContentVisibleStatus("MINIMIZED");
        }
        if (event.contentId === SplitParams.Contents.RightSideBar.id) {
          setRightSideBarVisibleStatus("MINIMIZED");
        }
        return;
      }
      if (event.type === "RELEASE_MINIMIZED") {
        if (event.contentId === SplitParams.Contents.LeftSideBar.id) {
          setLeftSideBarVisibleStatus("OPEN");
        }
        if (event.contentId === SplitParams.Contents.Main.id) {
          setMainContentVisibleStatus("OPEN");
        }
        if (event.contentId === SplitParams.Contents.RightSideBar.id) {
          setRightSideBarVisibleStatus("OPEN");
        }
        return;
      }
    });
    const cleanup2 = methods.subscribeChangeEvent(SplitParamsForRemoteInterviewSystemDesignCodingEditor.splitId, event => {
      if (event.type === "MINIMIZED") {
        if (event.contentId === SplitParamsForRemoteInterviewSystemDesignCodingEditor.Contents.SelectComponentArea.id) {
          setSelectComponentAreaVisibleStatus("MINIMIZED");
        }
        return;
      }
      if (event.type === "RELEASE_MINIMIZED") {
        if (event.contentId === SplitParamsForRemoteInterviewSystemDesignCodingEditor.Contents.SelectComponentArea.id) {
          setSelectComponentAreaVisibleStatus("OPEN");
        }
        return;
      }
    });
    return () => {
      cleanup1();
      cleanup2();
    };
  }, [methods, args.currentSplitId]);

  return {
    leftSideBarVisibleStatus,
    setLeftSideBarVisibleStatus,
    mainContentVisibleStatus,
    setMainContentVisibleStatus,
    rightSideBarVisibleStatus,
    setRightSideBarVisibleStatus,
    selectComponentAreaVisibleStatus,
    setSelectComponentAreaVisibleStatus,
  };
};
