import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type QuizPackageDetailContainerProps = {};

const QuizPackageQuestionDetailContainer: React.FC<QuizPackageDetailContainerProps> = props => {
  const quizPackageDetailProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Pages.QuestionDetail {...quizPackageDetailProps} />
    </ErrorBoundary>
  );
};

QuizPackageQuestionDetailContainer.displayName = "QuizPackageQuestionDetailContainer";

export default withErrorBoundary(QuizPackageQuestionDetailContainer, {});
