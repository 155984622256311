import * as React from "react";

export type ImageProps = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {};

const Image: React.FC<ImageProps> = props => {
  return <img src={props.src} alt={props.alt} width="100%" style={{ maxWidth: "50vw" }} />;
};

Image.displayName = "Image";

export default Image;
