import AppsOutlined from "@mui/icons-material/AppsOutlined";
import CommentOutlined from "@mui/icons-material/CommentOutlined";
import DescriptionOutlined from "@mui/icons-material/DescriptionOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import InsertCommentOutlinedIcon from "@mui/icons-material/InsertCommentOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import RemoveRedEyeOutlined from "@mui/icons-material/RemoveRedEyeOutlined";
import SearchOutlined from "@mui/icons-material/SearchOutlined";
import SourceOutlined from "@mui/icons-material/SourceOutlined";
import TerminalOutlinedIcon from "@mui/icons-material/TerminalOutlined";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import * as React from "react";

const StyledBox = styled(Box)(({ theme }) => ({
  width: 36,
  height: "100%",
  backgroundColor: theme.palette.HeaderBG,
}));

const StyledIconButton = styled(Button)(({ theme }) => ({
  width: 36,
  height: 36,
  minWidth: 36,
  marginBottom: "1px",
  color: theme.palette["Gray/Shades"].p56,
  borderLeft: `2px solid transparent`,
  "&[data-active*=true]": {
    color: theme.palette.text.primary,
    borderLeftColor: theme.palette.secondary.main,
  },
  "&:hover": {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette["Secondary/Shades"].p8,
  },
  ":last-of-type": {
    marginBottom: 0,
  },
  borderRadius: 0,
  svg: {
    width: 20,
    height: 20,
  },
}));

const IconMap = {
  DESCRIPTION: <DescriptionOutlined />,
  EYE: <RemoveRedEyeOutlined />,
  FOLDER: <SourceOutlined />,
  FILE: <InsertDriveFileOutlinedIcon />,
  APPS: <AppsOutlined />,
  TERMINAL: <TerminalOutlinedIcon />,
  COMMENT: <InsertCommentOutlinedIcon />,
  INFO: <InfoOutlinedIcon />,
  CHAT: <CommentOutlined />,
  SEARCH: <SearchOutlined />,
} as const;

type Icon = keyof typeof IconMap;

type ItemProps = Pick<ButtonProps, "disabled" | "onClick" | "className"> & { icon: Icon; active: boolean; tooltip: string };

export type ActivityBarProps = {
  disabled?: boolean;
  items: ItemProps[];
};

const ActivityBar: React.FC<ActivityBarProps> = props => {
  return (
    <StyledBox>
      {props.items.map((item, index) => {
        const { icon, active, tooltip, ...itemProps } = item;
        const disabled = props.disabled || item.disabled;
        const iconButtonProps: ButtonProps = {
          ...itemProps,
          children: IconMap[icon],
          variant: "text",
          disabled: disabled,
        };
        const tooltipProps: Omit<TooltipProps, "children"> = {
          placement: "right",
          title: tooltip,
          componentsProps: {
            popper: {
              sx: {
                ".MuiTooltip-tooltipPlacementRight": {
                  /**
                   * FIXME remove !important flag
                   */
                  marginLeft: `2px !important`,
                },
              },
            },
          },
        };

        const isActive = iconButtonProps.disabled ? false : active;
        if (iconButtonProps.disabled) {
          return <StyledIconButton key={`activity-button-${index}`} data-active={isActive} {...iconButtonProps} />;
        }
        return (
          <Tooltip key={`activity-button-${index}`} {...tooltipProps}>
            <StyledIconButton data-active={isActive} {...iconButtonProps} />
          </Tooltip>
        );
      })}
    </StyledBox>
  );
};

ActivityBar.displayName = "ActivityBar";

export default ActivityBar;
