import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { TermOfService } from "@hireroo/app-helper/payment";
import { BuySelections } from "@hireroo/app-store/widget/e/BuySelections";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import type * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/browser";
import * as React from "react";

import { usePayMonthConfirmSectionProps } from "../privateHelper";

export type GeneratePaymentConfirmationDetailsPropsArgs = {
  onAccept: () => void;
};

export const useGenerateProps = (args: GeneratePaymentConfirmationDetailsPropsArgs): Widget.PaymentConfirmationDetailsProps => {
  const customer = BuySelections.useCustomer();
  const submitValue = BuySelections.useSubmitValue();
  const interviewsMap = BuySelections.useBuyAbleSelectionItemMap();
  const urlItem = TermOfService.useUrlItem(customer.subscription.plan.generation);
  const client = getGraphqlClient();
  const applicationConfirmationSectionProps = usePayMonthConfirmSectionProps();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);

  return {
    payNextMonthConfirmSection: applicationConfirmationSectionProps,
    tosIframeUrl: urlItem?.iframeUrl,
    downloadUrl: urlItem?.downloadUrl,
    submitButton: {
      loading,
      onClick: () => {
        setLoading(true);
        client
          .PurchasePackagesForBuySelections({
            input: {
              customerId: customer.customerId,
              purchasePackages: submitValue.selectionItems.reduce<Graphql.PurchasePackageInput[]>((all, selectionItem) => {
                if (selectionItem.amount === 0) {
                  return all;
                }
                const interview = interviewsMap[selectionItem.itemId];
                return all.concat({ packageId: interview.interviewId, quantity: selectionItem.amount });
              }, []),
            },
          })
          .then(() => {
            Snackbar.notify({
              severity: "success",
              message: t("選考数の購入に成功しました。"),
            });
            args.onAccept();
          })
          .catch(error => {
            Sentry.captureException(error);
            const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("選考数の購入に失敗しました。"));
            Snackbar.notify({
              severity: "error",
              message: errorNotification.message,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      },
    },
  };
};
