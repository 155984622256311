import { useTranslation } from "@hireroo/i18n";
import DownloadIcon from "@mui/icons-material/Download";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import * as React from "react";

export type Status = "PAID" | "NOT_PAID";

const StyledChip = styled(Chip)(({ theme }) => ({
  height: 20,
  marginLeft: 8,
  position: "relative",
  top: -1,
  ".MuiChip-label": {
    fontSize: 10,
    lineHeight: 1.2,
    padding: "1px 4px",
  },
  "&.MuiChip-colorDefault": {
    color: theme.palette.text.secondary,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  width: "100%",
  height: 56,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  borderBottom: `1px solid ${theme.palette["Secondary/Shades"].p12}`,
  "& .MuiTableCell-root": {
    borderColor: theme.palette["Secondary/Shades"].p12,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  height: 32,
  padding: theme.spacing(1.5),
}));

export type InvoiceTableRowProps = {
  billingDate: string;
  dueDate: string;
  price: string;
  status: Status;
  downloadUrl: string;
};

const InvoiceTableRow: React.FC<InvoiceTableRowProps> = props => {
  const { t } = useTranslation();
  const ChipMap: Record<Status, React.ReactNode> = {
    PAID: <StyledChip label={t("支払い済み")} color="success" size="small" />,
    NOT_PAID: <StyledChip label={t("未払い")} color="secondary" size="small" />,
  };
  return (
    <StyledTableRow>
      {/* TODO @himenon impl Bulk Download */}
      {/* <StyledTableCell align="center">
        <StyledCheckbox size="small" color="secondary" />
      </StyledTableCell> */}
      <StyledTableCell align="left">{props.billingDate}</StyledTableCell>
      <StyledTableCell align="left">{props.dueDate}</StyledTableCell>
      <StyledTableCell align="left" sx={{ fontWeight: 700 }}>
        {props.price}
      </StyledTableCell>
      <StyledTableCell align="left">{ChipMap[props.status]}</StyledTableCell>
      <StyledTableCell align="center">
        <a href={props.downloadUrl} target="_blank" rel="noopener noreferrer">
          <IconButton aria-label="download" component="span" color="secondary" sx={{ padding: 0 }} size="large">
            <DownloadIcon fontSize="small" />
          </IconButton>
        </a>
      </StyledTableCell>
    </StyledTableRow>
  );
};

InvoiceTableRow.displayName = "InvoiceTableRow";

export default InvoiceTableRow;
