import { styled } from "@mui/material/styles";
import * as React from "react";

const BOX_HEIGHT = 40;
const RAIL_HEIGHT = 6;
const RAIL_Y = (BOX_HEIGHT - RAIL_HEIGHT) / 2;

const StyledRectRail = styled("rect")({
  x: 0,
  y: RAIL_Y,
  height: RAIL_HEIGHT,
  fill: "gray",
  opacity: 0.5,
  rx: 3,
});

const StyledRectMark = styled("rect")({
  y: RAIL_Y,
  height: RAIL_HEIGHT,
  fill: "gray",
  rx: 3,
});

const StyledRectThumb = styled("rect")`
  height: ${BOX_HEIGHT}px;
  width: 2px;
  fill: white;
  // @see https://github.com/mui/material-ui/blob/2b575e2631377d2845b4b25c5afe446e4cd4d41b/packages/mui-joy/src/Slider/Slider.tsx#L203
  transition: x 150ms cubic-bezier(0.4, 0, 0.2, 1);
`;

export type CustomSVGSliderProps = {
  rectMarks: (Pick<React.SVGProps<SVGRectElement>, "x" | "width"> & { id: string })[];
  thumbRect: Pick<React.SVGProps<SVGRectElement>, "x">;
};

const CustomSVGSlider: React.FC<CustomSVGSliderProps> = props => {
  // We don't use MUI Slider, for improve rendering performance
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width={"100%"} height={"100%"}>
      <StyledRectRail width={"100%"} />
      {props.rectMarks.map(rectMark => (
        <StyledRectMark key={rectMark.id} {...rectMark} />
      ))}
      <StyledRectThumb {...props.thumbRect} />
    </svg>
  );
};

CustomSVGSlider.displayName = "CustomSVGSlider";

export default CustomSVGSlider;
