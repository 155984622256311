import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type BillingInvoicesContainerProps = {};

const BillingInvoicesContainer: React.FC<BillingInvoicesContainerProps> = () => {
  const companySettingsProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Pages.CompanySettings {...companySettingsProps} />
    </ErrorBoundary>
  );
};

BillingInvoicesContainer.displayName = "BillingInvoicesContainer";

export default withErrorBoundary(BillingInvoicesContainer, {});
