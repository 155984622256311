import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateEvaluationMetricsPropsArgs, useGenerateProps } from "./useGenerateProps";

export type EvaluationMetricsContainerProps = GenerateEvaluationMetricsPropsArgs;

const EvaluationMetricsContainer: React.FC<EvaluationMetricsContainerProps> = props => {
  const evaluationMetricsProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.EvaluationMetrics {...evaluationMetricsProps} />
    </ErrorBoundary>
  );
};

EvaluationMetricsContainer.displayName = "EvaluationMetricsContainer";

export default withErrorBoundary(EvaluationMetricsContainer, {});
