import { useSnapshot } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useFormValues = () => {
  const snapshot = useSnapshotState();
  return snapshot.formValues;
};

export const useIsValidFormValues = () => {
  const snapshot = useSnapshotState();
  return !!snapshot.formValues.metricGroupId && snapshot.formValues.evaluations.every(evaluation => evaluation.numStars > 0);
};

export const useMetricGroups = (): Types.MetricGroup[] => {
  const snapshot = useSnapshotState();

  return Array.from(snapshot.metricGroups.values());
};

export const useMetricGroupPagination = () => {
  const snapshot = useSnapshotState();
  return snapshot.metricGroupPagination;
};

export const useLoadingStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.loadingStatus;
};
