import { RemoteInterviewResourceEditor } from "@hireroo/validator";
import * as React from "react";
import { UseFormReset } from "react-hook-form";

export type StepName = "REMOTE_QUESTION_SETUP" | "REPORT_SETUP" | "CONFIRM";

type ActiveStep = number;

type SubmitValues = {
  REMOTE_QUESTION_SETUP: RemoteInterviewResourceEditor.RemoteQuestionSetupFormSchema;
  REPORT_SETUP: RemoteInterviewResourceEditor.RemoteReportSetupFormSchema;
};

type ResetHandler = {
  REMOTE_QUESTION_SETUP: UseFormReset<RemoteInterviewResourceEditor.RemoteQuestionSetupFormSchema> | null;
  REPORT_SETUP: UseFormReset<RemoteInterviewResourceEditor.RemoteReportSetupFormSchema> | null;
};

const StepNameToActiveStepMap: Record<StepName, ActiveStep> = {
  REMOTE_QUESTION_SETUP: 0,
  REPORT_SETUP: 1,
  CONFIRM: 2,
};

const ActiveStepToStepNameMap: Record<ActiveStep, StepName> = {
  0: "REMOTE_QUESTION_SETUP",
  1: "REPORT_SETUP",
  2: "CONFIRM",
};

const SortedActiveStepToStepNames: StepName[] = Object.values(ActiveStepToStepNameMap);

type StepStatusMap = Map<StepName, "READY" | "ERROR" | "VALID">;
export type LayoutController = {
  checkGoNextStep: () => Promise<boolean>;
};

type SubmitHandler = (fields: RemoteInterviewResourceEditor.CreateRemoteInterviewSchema) => void;
export type ContextValue = {
  stepName: StepName;
  activeStep: ActiveStep;
  setController: (stepName: StepName, controller: LayoutController) => void;
  goNextStep: () => Promise<void>;
  goPrevStep: () => Promise<void>;
  updateStep: (step: StepName) => Promise<void>;
  stepStatusMap: StepStatusMap;

  title: string;
  setTitle: (title: string) => void;

  submitValues: Partial<SubmitValues>;
  setSubmitValue: <T extends keyof SubmitValues>(stepName: T, value: SubmitValues[T] | undefined) => void;

  setResetHandler: <T extends keyof ResetHandler>(stepName: T, callback: ResetHandler[T]) => void;
  reset: <T extends keyof SubmitValues>(step: T, value: SubmitValues[T] | undefined) => void;

  setSubmitHandler: (callback: SubmitHandler) => void;
  submit: () => void;
};

const NOOP = () => Promise.resolve();

export const RemoteInterviewResourceEditorContext = React.createContext<ContextValue>({
  stepName: "REMOTE_QUESTION_SETUP",
  setController: NOOP,
  activeStep: 0,
  updateStep: NOOP,
  goPrevStep: NOOP,
  goNextStep: NOOP,
  title: "",
  setTitle: NOOP,
  stepStatusMap: new Map(),
  submitValues: {},
  setSubmitValue: NOOP,
  setResetHandler: NOOP,
  reset: NOOP,
  setSubmitHandler: NOOP,
  submit: NOOP,
});

export const useRemoteInterviewResourceEditorContext = () => React.useContext(RemoteInterviewResourceEditorContext);

export type RemoteInterviewResourceEditorProviderProps = {
  initialStepName?: StepName;
};

export const RemoteInterviewResourceEditorProvider: React.FC<React.PropsWithChildren<RemoteInterviewResourceEditorProviderProps>> = props => {
  const [stepName, setStepName] = React.useState<StepName>("REMOTE_QUESTION_SETUP");
  const [title, setTitle] = React.useState("");
  const submitHandlerRef = React.useRef<SubmitHandler | null>(null);
  const controllerMap = React.useRef(new Map<StepName, LayoutController>());
  const [stepStatusMap, setStepStatusMap] = React.useState<StepStatusMap>(new Map());
  const [submitValues, setSubmitValues] = React.useState<Partial<SubmitValues>>({});

  const resetHandlerRef = React.useRef<ResetHandler>({
    REMOTE_QUESTION_SETUP: null,
    REPORT_SETUP: null,
  });
  const updateStep = React.useCallback(async (newStep: StepName) => {
    const end = StepNameToActiveStepMap[newStep];
    const stepNames = SortedActiveStepToStepNames.slice(0, end);
    for await (const stepName of stepNames) {
      const controller = controllerMap.current.get(stepName);
      if (!controller) {
        throw new Error(`Invalid Step Name: ${stepName}`);
      }
      const canGo = await controller.checkGoNextStep();
      if (canGo) {
        setStepStatusMap(prev => {
          prev.set(stepName, "VALID");
          return new Map(prev);
        });
      } else {
        setStepStatusMap(prev => {
          prev.set(stepName, "ERROR");
          return new Map(prev);
        });
        setStepName(stepName);
        return;
      }
    }
    setStepName(newStep);
  }, []);

  const contextValue: ContextValue = {
    title,
    stepName,
    activeStep: StepNameToActiveStepMap[stepName],
    updateStep: updateStep,
    setController: (stepName, controller) => {
      controllerMap.current.set(stepName, controller);
    },
    stepStatusMap,

    goPrevStep: async () => {
      const prevStepValue = StepNameToActiveStepMap[stepName] - 1;
      const newStep = ActiveStepToStepNameMap[prevStepValue];
      if (newStep) {
        setStepName(newStep);
      }
    },
    goNextStep: async () => {
      const nextStepValue = StepNameToActiveStepMap[stepName] + 1;
      const newStep = ActiveStepToStepNameMap[nextStepValue];
      updateStep(newStep);
    },
    setTitle: title => setTitle(title),
    submitValues: submitValues,
    setResetHandler: (stepName, resetCallback) => {
      resetHandlerRef.current[stepName] = resetCallback;
    },
    reset: (step, value) => {
      const resetCallback = resetHandlerRef.current[step];
      if (resetCallback) {
        resetCallback(value);
      }
    },
    setSubmitValue: (stepName, value) => {
      setSubmitValues(prev => ({
        ...prev,
        [stepName]: value,
      }));
    },
    submit: () => {
      if (submitValues.REMOTE_QUESTION_SETUP && submitValues.REPORT_SETUP) {
        submitHandlerRef.current?.({
          remoteQuestionSetup: submitValues.REMOTE_QUESTION_SETUP,
          remoteReportSetup: submitValues.REPORT_SETUP,
        });
      }
    },
    setSubmitHandler: callback => {
      submitHandlerRef.current = callback;
    },
  };
  return <RemoteInterviewResourceEditorContext.Provider value={contextValue} children={props.children} />;
};
