import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DifficultyStars, { DifficultyStarsProps } from "../../../../modules/DifficultyStars/DifficultyStars";

type Value =
  | {
      kind: "DIFFICULTY";
      difficulty: DifficultyStarsProps["difficulty"];
    }
  | {
      kind: "NUMBER_WITH_UNIT";
      num: number;
      unit: string;
    };

export type FieldItemProps = {
  label: string;
  value: Value;
};

/**
 * @deprecated Please use ms-components/Questions/QuestionPackageShortDetail
 */
const FieldItem: React.FC<FieldItemProps> = props => {
  return (
    <Stack spacing={0.5} height="56px" pr={2}>
      <Typography color="textSecondary" fontSize={12}>
        {props.label}
      </Typography>
      {props.value.kind === "DIFFICULTY" && (
        <Box py={1.5}>
          <DifficultyStars difficulty={props.value.difficulty} />{" "}
        </Box>
      )}
      {props.value.kind === "NUMBER_WITH_UNIT" && (
        <Stack direction="row" spacing={0.5} alignItems="flex-end">
          <Typography fontWeight={700} fontSize={24}>
            {props.value.num}
          </Typography>
          <Typography color="textSecondary" fontSize={14}>
            {props.value.unit}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

FieldItem.displayName = "FieldItem";

export default FieldItem;
