import { colorFromUserId } from "@hireroo/app-helper/color";
import { useTwilioVideoRoomContext } from "@hireroo/app-helper/hooks";
import { Auth } from "@hireroo/app-store/essential/employee";
import { RemotesId } from "@hireroo/app-store/page/e/remotes_id";
import { RemoteInterviewParticipants } from "@hireroo/app-store/widget/shared/RemoteInterviewParticipants";
import { useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import React from "react";

import LiveCodingDeviceSettingsContainer from "../LiveCodingDeviceSettings/Container";
import LiveCodingVideoChatGalleryLayoutContainer from "../LiveCodingVideoChatGalleryLayout/Container";

export type GenerateLiveCodingVideoChatPopperPropsArgs = {};

export const useGenerateProps = (_args: GenerateLiveCodingVideoChatPopperPropsArgs): Widget.LiveCodingVideoChatPopperProps => {
  const { t } = useTranslation();

  const { isAcquiringLocalTracks, localVideoTrack, isAudioEnabled, isVideoEnabled, toggleVideo, toggleAudio } = useTwilioVideoRoomContext();
  const uid = Auth.useCurrentUid();
  const userInfoMap = RemoteInterviewParticipants.useUserInfoMap();

  const user = RemotesId.useEmployeeUser(uid ?? "");
  const userInfo = userInfoMap.get(user?.userId ?? "");

  const videoViewProps: Widget.LiveCodingVideoViewProps = React.useMemo(() => {
    return {
      avatar: {
        sx: { bgcolor: colorFromUserId(user.userId) },
        src: userInfo?.employee?.photoUrl || user.userName,
        alt: user.userName,
      },
      id: "local",
      title: t("あなた"),
      videoTrack: localVideoTrack && {
        track: localVideoTrack,
        isFrontFacing: true,
        isPortrait: false,
      },
      isMicOn: isAudioEnabled,
    };
  }, [isAudioEnabled, localVideoTrack, t, user.userId, user.userName, userInfo?.employee?.photoUrl]);

  return {
    Layout: <LiveCodingVideoChatGalleryLayoutContainer />,
    menuBar: {
      audioToggleButton: {
        isEnabled: isAudioEnabled,
        onClick: () => {
          toggleAudio();
        },
      },
      videoToggleButton: {
        isEnabled: isVideoEnabled,
        onClick: () => {
          toggleVideo();
        },
      },
      // recordingToggleButton: {
      //   onClick: () => {
      //     toggleVideoRecording();
      //   },
      //   title: tooltipTextMap[recordingStatus],
      // },
      // recordingStatus: recordingStatus,
    },
    videoChatDraggablePrompt: {},
    settingDialog: {
      /**
       * TODO Containerize
       * If you refer to the Widget directly, there is no point in making it a Widget.
       */
      VideoContent: <Widget.LiveCodingVideoView {...videoViewProps} />,
      deviceToggleButtons: {
        audioToggleButton: {
          isEnabled: isAudioEnabled,
          onClick: () => {
            toggleAudio();
          },
        },
        videoToggleButton: {
          isEnabled: isVideoEnabled,
          onClick: () => {
            toggleVideo();
          },
        },
      },
      yesButton: {
        loading: isAcquiringLocalTracks,
        onClick: () => {
          //TODO: implement
        },
      },
      DeviceSettings: <LiveCodingDeviceSettingsContainer />,
    },
  };
};
