import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import * as React from "react";

import CommonLayout, { CommonLayoutProps } from "../../layout/CommonLayout/CommonLayout";
import StepContentLayout, { StepContentLayoutProps } from "../../primitive/StepContentLayout/StepContentLayout";
import IncludePrivatePackageWarningDialog, { IncludePrivatePackageWarningDialogProps } from "./parts/IncludePrivatePackageWarningDialog";
import SelectQuestions, { SelectQuestionsProps } from "./parts/SelectQuestions";

export type QuizResourceEditorProps = {
  layout: Omit<CommonLayoutProps, "heading">;
  /** greater than 0 */
  activeStep: number;
  selectQuestions: SelectQuestionsProps;
  QuizPackageDetail: React.ReactNode;
  saveSelectQuestionsButton: Pick<StepContentLayoutProps["nextButton"], "disabled" | "onClick">;
  saveSummaryButton: Pick<StepContentLayoutProps["nextButton"], "disabled" | "onClick">;
  stepContentPreviousButton: Pick<Exclude<StepContentLayoutProps["previousButton"], undefined>, "onClick">;
  includePrivatePackageWarningDialog: IncludePrivatePackageWarningDialogProps;
};

const QuizResourceEditor: React.FC<QuizResourceEditorProps> = props => {
  const { t } = useTranslation();

  const layoutProps: CommonLayoutProps = {
    ...props.layout,
    heading: {
      text: t("問題作成"),
    },
  };

  const step1: StepContentLayoutProps = {
    title: t("設問選択"),
    description: t("出題する設問を選択します。15問から25問が目安です。"),
    nextButton: {
      ...props.saveSelectQuestionsButton,
      children: t("保存して次へ"),
    },
  };
  const step2: StepContentLayoutProps = {
    title: t("概要の入力"),
    description: t("制限時間などの概要を入力します。問題選択時に表示されます。"),
    previousButton: {
      children: t("前に戻る"),
      onClick: props.stepContentPreviousButton?.onClick,
    },
    nextButton: {
      ...props.saveSummaryButton,
      children: t("保存する"),
    },
  };
  const steps = [
    {
      title: t("設問選択"),
      Content: (
        <StepContentLayout {...step1}>
          <SelectQuestions {...props.selectQuestions} />
        </StepContentLayout>
      ),
    },
    {
      title: t("概要の入力"),
      Content: <StepContentLayout {...step2}>{props.QuizPackageDetail}</StepContentLayout>,
    },
  ];
  return (
    <CommonLayout {...layoutProps}>
      <Box pt={4}>
        <Stepper orientation="vertical" activeStep={props.activeStep}>
          {steps.map(step => {
            return (
              <Step key={step.title}>
                <StepLabel>{step.title}</StepLabel>
                <StepContent>{step.Content}</StepContent>
              </Step>
            );
          })}
        </Stepper>
        <IncludePrivatePackageWarningDialog {...props.includePrivatePackageWarningDialog} />
      </Box>
    </CommonLayout>
  );
};

QuizResourceEditor.displayName = "QuizResourceEditor";

export default QuizResourceEditor;
