import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type SignUpContainerProps = {};

const SignUpContainer: React.FC<SignUpContainerProps> = () => {
  const signUpProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.SignUp {...signUpProps} />
    </ErrorBoundary>
  );
};

SignUpContainer.displayName = "SignUpContainer";

export default withErrorBoundary(SignUpContainer, {});
