import { useTranslation } from "@hireroo/i18n";
import { markdownToText } from "@hireroo/markdown";
import Markdown from "@hireroo/markdown/react";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { styled } from "@mui/material/styles";
import * as React from "react";

const StyledFormControlLabel = styled(FormControlLabel)({
  "& > span:nth-of-type(2)": {
    width: "100%",
  },
  marginTop: "10px",
});

export type SingleChoiceItem = {
  value: string;
  title: string;
  description?: string;
};

export type ReadonlyQuizQuestionSingleChoiceProps = {
  id: string;
  selected: string;
  description: string;
  items: SingleChoiceItem[];
};

const ReadonlyQuizQuestionSingleChoice: React.FC<ReadonlyQuizQuestionSingleChoiceProps> = props => {
  const { t } = useTranslation();
  return (
    <Box>
      <React.Suspense>
        <Markdown size="middle" children={props.description} />
      </React.Suspense>

      <RadioGroup value={props.selected}>
        {props.items.map((option, index) => (
          <Box key={`option-${index}-${option.value}`}>
            <StyledFormControlLabel
              // Radio form passed "string" so value is "string"
              key={option.value}
              value={option.value}
              control={<Radio color="primary" />}
              label={<Markdown size="small" children={option.title} />}
            />
            <Box my={1}>
              <FormHelperText sx={{ color: "text.secondary", marginLeft: "1em" }}>{markdownToText(option.description || "")}</FormHelperText>
            </Box>
          </Box>
        ))}

        <FormControlLabel value="0" control={<Radio color="primary" />} label={t("この問題をスキップ")} sx={{ mt: "10px" }} />
      </RadioGroup>
    </Box>
  );
};

ReadonlyQuizQuestionSingleChoice.displayName = "ReadonlyQuizQuestionSingleChoice";

export default ReadonlyQuizQuestionSingleChoice;
