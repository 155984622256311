import { useTranslation } from "@hireroo/i18n";
import { TestEvaluationForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";

import DialogWithCloseIcon, {
  DialogWithCloseIconProps,
  NoButtonProps,
  YesButtonProps,
} from "../../../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";
import InputControlTextField from "../../../../primitive/InputControl/InputControlTextField/InputControlTextField";
import RadioControlField, { RadioControlFieldProps } from "../../../../primitive/InputControl/RadioControlField/RadioControlField";
import ResourceField from "../../../ResourceField/ResourceField";
import ListCandidateItem, { ListCandidateItemProps } from "./parts/ListCandidateItem/ListCandidateItem";

type Option = {
  id: string;
  label: string;
  value: TestEvaluationForm.TestResult;
};

const StyledList = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  border: "1px solid",
  borderRadius: "8px",
  borderColor: theme.palette.Other.Divider,
  maxHeight: "150px",
  overflow: "auto",
}));

const StyledInputControlTextField = styled(InputControlTextField)(() => ({
  width: "100%",
}));

export type TestMultiEvaluationDialogProps = {
  items: ListCandidateItemProps[];
  heading: string;

  open: boolean;
  onClose: () => void;

  onSubmit: SubmitHandler<TestEvaluationForm.TestEvaluationFormSchema>;
  yesButton: Pick<YesButtonProps, "disabled">;
  noButton?: Pick<NoButtonProps, "disabled">;
  additionalCautionItems?: string[];
};

const TestMultiEvaluationDialog: React.FC<TestMultiEvaluationDialogProps> = props => {
  const { t } = useTranslation();
  const { onClose, onSubmit } = props;
  const resultValidateSchema = TestEvaluationForm.useTestResult();
  const validateSchema = TestEvaluationForm.useTestEvaluationForm();
  const methods = useForm<TestEvaluationForm.TestEvaluationFormSchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: {
      result: null,
      comment: "",
      agreement: false,
    },
  });

  const canSubmit = methods.watch("result") !== null && methods.watch("agreement") === true;

  const options: Option[] = [
    {
      id: "PASSED",
      label: t("合格"),
      value: "PASSED",
    },
    {
      id: "NOT_PASSED",
      label: t("不合格"),
      value: "NOT_PASSED",
    },
  ];

  const radioControlFieldProps: RadioControlFieldProps = {
    name: "result",
    color: "secondary",
    size: "small",
    options: options,
    onChange: value => {
      const result = resultValidateSchema.safeParse(value);
      if (result.success) {
        methods.setValue("result", result.data);
      }
    },
  };

  const evaluateDialog: DialogWithCloseIconProps = {
    title: t("最終評価"),
    open: props.open,
    yesButton: {
      ...props.yesButton,
      onClick: React.useCallback(() => {
        methods.handleSubmit(onSubmit, console.warn)();
      }, [methods, onSubmit]),
      disabled: props.yesButton.disabled || !canSubmit,
      children: t("評価を確定する"),
    },
    noButton: {
      ...props.noButton,
      variant: "text",
      color: "outline-only",
      onClick: () => {
        onClose();
      },
      children: t("キャンセル"),
    },
    disableEnter: true,
    onClose: onClose,
    optionalContent: {
      sx: {
        px: 3,
        py: 2,
      },
    },
  };

  const items: string[] = [t("一度評価が確定した後、評価を編集できません。"), ...(props.additionalCautionItems ?? [])];

  return (
    <FormProvider {...methods}>
      <DialogWithCloseIcon {...evaluateDialog}>
        <Stack spacing={3} direction="column">
          <Stack direction="column" spacing={1}>
            <Typography fontWeight={700}>{props.heading}</Typography>
            <StyledList direction="column" spacing={1.5}>
              {props.items.map(item => (
                <ListCandidateItem key={item.id} {...item} />
              ))}
            </StyledList>
          </Stack>
          <ResourceField label={t("合否")} kind="REQUIRED">
            <RadioControlField {...radioControlFieldProps} />
          </ResourceField>
          <ResourceField label={t("評価コメント")} kind="OPTIONAL">
            <StyledInputControlTextField name="comment" rows={5} multiline />
          </ResourceField>
          <ResourceField label={t("注意事項")} kind="REQUIRED">
            <Stack direction="column" spacing={1.5}>
              <Stack direction="column" spacing={1.5} px={1}>
                {items.map((item, index) => (
                  <Typography fontSize={12} key={index}>
                    {`${index + 1}. ${item}`}
                  </Typography>
                ))}
              </Stack>
              <Controller
                name="agreement"
                control={methods.control}
                render={({ field, fieldState }) => (
                  <FormGroup onChange={field.onChange}>
                    <Stack direction="column" color="primary">
                      <FormControlLabel
                        sx={{ ml: 0 }}
                        control={<Checkbox size="small" color="secondary" checked={field.value} />}
                        label={
                          <Typography variant="body1" gutterBottom mb={0}>
                            {t("注意事項を確認した")}
                          </Typography>
                        }
                      />
                      {fieldState.error?.message && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
                    </Stack>
                  </FormGroup>
                )}
              />
            </Stack>
          </ResourceField>
        </Stack>
      </DialogWithCloseIcon>
    </FormProvider>
  );
};

TestMultiEvaluationDialog.displayName = "TestMultiEvaluationDialog";

export default TestMultiEvaluationDialog;
