import { DeepReadonly } from "@hireroo/app-helper/types";
import * as React from "react";
import { useSnapshot } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = (entityId: Types.ChallengeEntityId) => {
  const snapshot = useSnapshotState();
  return snapshot.challenges.has(entityId);
};

export const useCreateChallengeHooks = (entityId: Types.ChallengeEntityId) => {
  const snapshot = useSnapshotState();
  const challengeState = snapshot.challenges.get(entityId);
  if (!challengeState) {
    throw new Error(`Please initialize challengeId=${entityId}`);
  }
  const { entity, currentSelectedSubmissionId } = challengeState;

  const useChallenge = () => {
    return entity;
  };

  const useCurrentSelectedSubmissionId = () => {
    return currentSelectedSubmissionId;
  };

  const useSubmissionMap = (): Types.SubmissionMap => {
    return (entity.submissions || []).reduce((previousValue, currentValue) => {
      return { ...previousValue, [currentValue.challengeSubmissionId]: currentValue };
    }, {});
  };

  const useCurrentSubmission = (): DeepReadonly<Types.Submission> | undefined => {
    return entity.submissions.find(submission => submission.challengeSubmissionId === currentSelectedSubmissionId);
  };

  const useOptimalAnswer = () => {
    return entity.challengeQuestion?.answers.find(a => a.label === "OPTIMAL");
  };

  const useStatistics = () => {
    return challengeState.statisticsMap.get(challengeState.queryKey) ?? null;
  };

  const useQuestion = () => {
    if (!entity.challengeQuestion) {
      throw new Error(`Question is not found`);
    }
    return entity.challengeQuestion;
  };

  const useChallengeSessionIds = (runtime: string): Types.ChallengeSessionIds => {
    return React.useMemo((): Types.ChallengeSessionIds => {
      const sessionIds: Types.ChallengeSessionIds = { webSessionId: null, chatGPTSessionId: null };

      for (const session of entity.sessions) {
        if (sessionIds.chatGPTSessionId !== null && sessionIds.webSessionId !== null) {
          break;
        }

        switch (session.__typename) {
          case "ChallengeChatGPTSession": {
            if (session.language === runtime) {
              sessionIds.chatGPTSessionId = session.sessionId;
            }
            break;
          }
          case "ChallengeWebSession": {
            if (session.language === runtime) {
              sessionIds.webSessionId = session.sessionId;
            }
            break;
          }
          default: {
            break;
          }
        }
      }

      return sessionIds;
    }, [runtime]);
  };

  const useFeedbackSuspiciousDegreeDialogStatus = (): Types.FeedbackSuspiciousDegreeDialogStatus => {
    return challengeState.feedbackSuspiciousDegreeDialogStatus;
  };

  return {
    useQuestion,
    useCurrentSelectedSubmissionId,
    useChallenge,
    useSubmissionMap,
    useCurrentSubmission,
    useOptimalAnswer,
    useStatistics,
    useChallengeSessionIds,
    useFeedbackSuspiciousDegreeDialogStatus,
  };
};
