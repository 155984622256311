import { ScreeningInviteeList } from "@hireroo/app-store/widget/e/ScreeningInviteeList";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

export const useQueryParams = (): ScreeningInviteeList.QueryParams => {
  const [searchParams] = useSearchParams();
  return React.useMemo((): ScreeningInviteeList.QueryParams => {
    return {
      size: searchParams.get(ScreeningInviteeList.QueryKeys.SIZE),
      page: searchParams.get(ScreeningInviteeList.QueryKeys.PAGE),
      email: searchParams.get(ScreeningInviteeList.QueryKeys.EMAIL),
      statuses: searchParams.getAll(ScreeningInviteeList.QueryKeys.STATUS),
      isDescending: searchParams.get(ScreeningInviteeList.QueryKeys.SORT),
    };
  }, [searchParams]);
};
