import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import * as Time from "@hireroo/formatter/time";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";

type ShowingTarget = "AVERAGE_VALUE";

export type GenerateAnswerSectionPropsArgs = {
  entityId: number;
  uniqueKey: ProjectTestReport.UniqueKey;
  showingTargets: ShowingTarget[];
};

export const useGenerateAnswerSectionProps = (args: GenerateAnswerSectionPropsArgs): Widget.ProjectTestReportProps["answerDetailSection"] => {
  const lang = useLanguageCode();
  const { t } = useTranslation();
  const hooks = ProjectTestReport.useCreateProjectHooks(args.entityId);
  const statistics = hooks.useStatistics();
  const variant = hooks.useQuestionVariant();
  const submittedStatistics = hooks.useSubmittedStatistics();
  return {
    totalElapsedTime: {
      value: Time.formatSeconds(submittedStatistics.totalElapsedTimeSeconds, lang),
      icon: {
        key: submittedStatistics.totalElapsedTimeSeconds <= statistics.averageElapsedTimeSeconds ? "CHECK" : "WARNING",
        title: `${t("平均解答時間")} ${Time.formatSeconds(statistics.averageElapsedTimeSeconds, lang)}`,
      },
    },
    runNum: {
      value: `${submittedStatistics.numOfRun} ${t("回")}`,
      icon: {
        key: submittedStatistics.numOfRun <= statistics.averageCodeExecutions ? "CHECK" : "WARNING",
        title: `${t("平均実行回数")} ${statistics.averageCodeExecutions}`,
      },
    },
    latency: {
      value: Time.getTimeUnitText(submittedStatistics.avgLatency, Time.Unit.MILLISECOND),
      icon: {
        key: submittedStatistics.avgLatency <= statistics.averageLatency ? "CHECK" : "WARNING",
        title: `${t("平均レイテンシー")} ${Time.getTimeUnitText(statistics.averageLatency, Time.Unit.MILLISECOND)}`,
      },
    },
    sla: variant === "Backend" && {
      value: submittedStatistics.sla ? `${submittedStatistics.sla} %` : t("計測不可"),
      icon: {
        key: (submittedStatistics.sla ?? 0) >= statistics.averageSla ? "CHECK" : "WARNING",
        title: `${t("平均SLA")} ${statistics.averageSla} %`,
      },
    },
    canShowTooltip: args.showingTargets.includes("AVERAGE_VALUE"),
  };
};
