import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import DialogContentText from "@mui/material/DialogContentText";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import * as React from "react";

import BaseDialog, { BaseDialogProps } from "../../../primitive/BaseDialog/BaseDialog";

const OptionValues = {
  /**
   * ワークスペースに含まれるコードは引き継いで、環境だけリセットする
   */
  RESET_ONLY_DEV_ENVIRONMENT: "RESET_ONLY_DEV_ENVIRONMENT",
  /**
   * ワークスペースに含まれるコードと環境をリセットする
   */
  RESET_ALL: "RESET_ALL",
} as const;

type OptionValue = (typeof OptionValues)[keyof typeof OptionValues];

type Controller = {
  setLoading: (loading: boolean) => void;
};

export type ResetConfirmDialogProps = {
  open: boolean;
  onAccept: (option: OptionValue, controller: Controller) => void;
  onClose?: () => void;
};

const ResetConfirmDialog: React.FC<ResetConfirmDialogProps> = props => {
  const { t } = useTranslation();
  const { onAccept } = props;
  const [resetOption, setResetOption] = React.useState<OptionValue | null>(null);
  const [loading, setLoading] = React.useState(false);
  const controller = React.useMemo(() => {
    return {
      setLoading,
    };
  }, []);

  const handleOnClickNoButton = React.useCallback(() => {
    props.onClose?.();
  }, [props]);

  const handleOnSelectResetOption = React.useCallback((_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    if (value === OptionValues.RESET_ALL || value === OptionValues.RESET_ONLY_DEV_ENVIRONMENT) {
      setResetOption(value);
    }
  }, []);
  const baseDialogProps: BaseDialogProps = {
    title: t("開発環境を再起動します。よろしいですか？"),
    open: props.open,
    yesButton: {
      color: "error",
      children: t("開発環境を再起動する"),
      onClick: () => {
        if (resetOption) {
          onAccept(resetOption, controller);
          setResetOption(null);
        }
      },
      disabled: resetOption === null || loading,
    },
    noButton: {
      children: t("閉じる"),
      onClick: handleOnClickNoButton,
      disabled: loading,
    },
    disableEnter: true,
  };
  return (
    <BaseDialog {...baseDialogProps}>
      <DialogContentText>
        <Typography component={"span"}>
          {t(
            "現在の開発環境を破棄して新しい開発環境を作成します。ソースコードの変更を引き継ぐかどうかを選択してから、「開発環境を再起動する」ボタンを押してください。",
          )}
        </Typography>
        <br />
        <br />
        <Typography component={"span"} variant={"caption"}>
          {t("※再起動には最大5分ほど時間がかかります。")}
        </Typography>
        <br />
        <Typography component={"span"} variant={"caption"}>
          {t("※ソースコードを初期化した場合、初期化前の状態に戻すことはできませんのでご注意ください。")}
        </Typography>
      </DialogContentText>
      <Box mt={2}>
        <FormControl>
          <RadioGroup aria-labelledby="reset-option" name="reset-option" value={resetOption} onChange={handleOnSelectResetOption}>
            <FormControlLabel
              value={OptionValues.RESET_ONLY_DEV_ENVIRONMENT}
              control={<Radio color={"secondary"} />}
              label={t("ソースコードの変更を引き継いで再起動する")}
            />
            <FormControlLabel
              value={OptionValues.RESET_ALL}
              control={<Radio color={"secondary"} />}
              label={t("ソースコードを初期化して再起動する")}
            />
          </RadioGroup>
        </FormControl>
      </Box>
    </BaseDialog>
  );
};

ResetConfirmDialog.displayName = "ResetConfirmDialog";

export default ResetConfirmDialog;
