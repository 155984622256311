import { algorithmLanguageListMap, languageMapForHighlight } from "@hireroo/challenge/definition";
import { CodeEditorProps } from "@hireroo/code-editor/react/CodeEditor";
import { useTranslation } from "@hireroo/i18n";
import { Add, History } from "@mui/icons-material";
import Code from "@mui/icons-material/Code";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useFieldArray } from "react-hook-form";

import AcceptButton, { AcceptButtonProps } from "../../../../primitive/Button/AcceptButton";
import AddableTabs, { AddableTabsProps } from "../../../../primitive/Tab/AddableTabs/AddableTabs";
import InputControlCodeEditor from "../../../AlgorithmAnswersForm/CodeSection/InputControlCodeEditor";
import { useFreepadAnswerFormContext } from "../../Context";

export type CodeSectionProps = {
  answerIndex: number;
  codeTemplate: Record<string, string>;
};

const CodeSection: React.FC<CodeSectionProps> = props => {
  const { t } = useTranslation();
  const { methods } = useFreepadAnswerFormContext();
  const theme = useTheme();
  const [selectedRuntimeIndex, setSelectedRuntimeIndex] = React.useState<string>("0");

  const defaultLanguagesMap = algorithmLanguageListMap;
  const answerRuntimeFieldArray = useFieldArray({
    control: methods.control,
    name: `answers.${props.answerIndex}.answerCodes`,
  });

  const answerRuntimes = methods.getValues(`answers.${props.answerIndex}.answerCodes`);

  const runtimeLanguage = answerRuntimeFieldArray.fields.map(r => r.runtime);

  const selectableLanguages = React.useMemo(() => {
    const s = new Set();
    runtimeLanguage.forEach(runtime => {
      s.add(runtime);
    });
    return defaultLanguagesMap.filter(l => !s.has(l.value));
  }, [runtimeLanguage, defaultLanguagesMap]);

  const getDisplayName = (lang: string) => {
    const displayLang = defaultLanguagesMap.find(l => l.value === lang);
    return displayLang?.displayName ?? "";
  };

  const addAnswerRuntime = React.useCallback(
    async (runtime: string, tabIndex: number) => {
      const isValid = await methods.trigger(`answers.${props.answerIndex}.answerCodes.${tabIndex}.body`);

      if (isValid) {
        answerRuntimeFieldArray.append({ runtime, body: props.codeTemplate[runtime] ?? "" });
      }
    },
    [methods, props.answerIndex, props.codeTemplate, answerRuntimeFieldArray],
  );

  const removeAnswerRuntime = React.useCallback(
    (index: number) => {
      answerRuntimeFieldArray.remove(index);
    },
    [answerRuntimeFieldArray],
  );

  const codeEditor: CodeEditorProps = {
    width: "100%",
    height: innerHeight / 2,
  };

  const resetCode = React.useCallback(
    (runtime: string, tabIndex: number) => () => {
      methods.setValue(`answers.${props.answerIndex}.answerCodes.${tabIndex}.body`, props.codeTemplate[runtime] ?? "");
    },
    [methods, props.answerIndex, props.codeTemplate],
  );

  const templateButton: AcceptButtonProps = {
    startIcon: <History fontSize="small" />,
    variant: "text",
    size: "small",
    children: t("テンプレートコードに戻す"),
  };

  const runtimeTabs: AddableTabsProps = {
    defaultTab: "0",
    tabs: {
      variant: "scrollable",
      scrollButtons: false,
    },
    tabListBox: {
      sx: {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
    addTabButton: {
      size: "small",
      startIcon: <Add fontSize="small" />,
      children: t("言語を追加する"),
    },
    currentTabValue: selectedRuntimeIndex,
    updateCurrentTabValue: setSelectedRuntimeIndex,
    items: answerRuntimes.map((answerRuntime, index) => {
      return {
        id: index.toString(),
        name: getDisplayName(answerRuntime.runtime),
        tab: {
          closeButton: {
            onClick: () => {
              removeAnswerRuntime(index);
            },
          },
        },
        Content: (
          <Box>
            <Box mb={1}>
              <AcceptButton {...templateButton} onClick={resetCode(answerRuntime.runtime, index)} />
            </Box>
            <InputControlCodeEditor
              key={answerRuntime.runtime}
              {...codeEditor}
              language={languageMapForHighlight[answerRuntime.runtime]}
              name={`answers.${props.answerIndex}.answerCodes.${index}.body`}
            />
          </Box>
        ),
      };
    }),
    menu: {
      items: selectableLanguages.map(selectableLang => {
        return {
          text: selectableLang.displayName,
          value: selectableLang.value,
          onClick: async () => {
            await addAnswerRuntime(selectableLang.value, answerRuntimeFieldArray.fields.length - 1);
          },
        };
      }),
    },
  };
  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Box display="flex" alignItems="center">
          <Box mr={1}>
            <Code fontSize="small" sx={{ color: theme.palette.common.white }} />
          </Box>

          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            {t("解答コードを入力する")}
          </Typography>
        </Box>
      </Box>

      <Box mt={2}>
        <Typography variant="body2">{t("解答コードを追加します。解答コードが存在する言語で候補者は解答が可能です。")}</Typography>
      </Box>

      <AddableTabs {...runtimeTabs} />
    </Box>
  );
};

CodeSection.displayName = "CodeSection";

export default CodeSection;
