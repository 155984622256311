import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material";
import Button, { type ButtonProps } from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import * as React from "react";

export type YesButtonProps = Pick<ButtonProps, "disabled" | "children" | "color"> & { onClick?: () => void };
export type NoButtonProps = Pick<ButtonProps, "onClick" | "disabled" | "children" | "color">;

export type StepDialogProps = {
  open: boolean;
  TitleContent: React.ReactNode;
  yesButton?: YesButtonProps;
  noButton?: NoButtonProps;
  disableEnter?: boolean;
  onClose?: () => void;
};

const StepDialog: React.FC<React.PropsWithChildren<StepDialogProps>> = props => {
  const theme = useTheme();
  const showDialogActions = !!(props.yesButton || props.noButton);
  const { open, children } = props;
  const processing = React.useRef(false);

  const yesButtonProps: ButtonProps | undefined = props.yesButton
    ? {
        color: "primary",
        ...props.yesButton,
        role: "yesbutton",
        variant: "contained",
        autoFocus: true,
      }
    : undefined;

  const noButtonProps: ButtonProps | undefined = props.noButton
    ? {
        color: "primary",
        ...props.noButton,
        role: "nobutton",
      }
    : undefined;
  return (
    <Dialog
      PaperProps={{
        sx: { width: "640px", minWidth: "640px", bgcolor: theme.palette.background.paper },
        elevation: 3,
      }}
      PaperComponent={Paper}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      role="dialog-root"
      onKeyDown={e => {
        // Disable preventDefault on enter key for element that uses
        // enter key as its functionality, such as textarea.
        if (props.disableEnter === true) {
          return;
        }

        if (e.key === "Enter") {
          e.preventDefault();
          if (processing.current) return;
          processing.current = true;
          props.yesButton?.onClick?.();
        }
      }}
    >
      <DialogTitle role="title" sx={{ px: 2, py: 1 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {props.TitleContent}
          <IconButton
            onClick={props.onClose}
            sx={{
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent role="content" sx={{ p: 0 }}>
        {children}
      </DialogContent>
      {showDialogActions && (
        <DialogActions sx={{ px: 1, py: 1 }}>
          {noButtonProps && <Button {...noButtonProps} />}
          {yesButtonProps && <Button {...yesButtonProps} />}
        </DialogActions>
      )}
    </Dialog>
  );
};

StepDialog.displayName = "StepDialog";

export default StepDialog;
