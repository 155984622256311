import { PageConfig } from "@jupyterlab/coreutils";
import { ServerConnection, ServiceManager } from "@jupyterlab/services";

import { Config } from "./config";

class ConfigManager {
  #serverSettings: ServerConnection.ISettings | undefined;
  #serviceManager: ServiceManager | undefined;
  constructor(private readonly config: Config) {
    this.setup();
  }

  public setup = () => {
    PageConfig.setOption("baseUrl", this.config.baseUrl);
    PageConfig.setOption("wsUrl", this.config.wsUrl);
    PageConfig.setOption("token", this.config.token);
    PageConfig.setOption("mathjaxUrl", "https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/MathJax.js");
    PageConfig.setOption("mathjaxConfig", "TeX-AMS_CHTML-full,Safe");
    /**
     * Terminal does not render, but this process is necessary to dispose.
     */
    PageConfig.setOption("terminalsAvailable", "true");
    this.#serverSettings = ServerConnection.makeSettings({
      baseUrl: this.config.baseUrl,
      wsUrl: this.config.wsUrl,
      token: this.config.token,
      appendToken: true,
      init: {
        mode: "cors",
        credentials: "include",
        cache: "no-cache",
      },
    });
    this.#serviceManager = new ServiceManager({
      serverSettings: this.#serverSettings,
    });
  };

  public get serverSettings() {
    if (!this.#serverSettings) {
      throw new Error("Please initialize Server Settings");
    }
    return this.#serverSettings;
  }

  public get serviceManager() {
    if (!this.#serviceManager) {
      throw new Error("Please initialize Server Settings");
    }
    return this.#serviceManager;
  }
}

export { type Config, ConfigManager };
