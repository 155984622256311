import { ChallengeTestReport } from "@hireroo/app-store/view-domain/ChallengeTestReport";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

type SuspiciousDegreeMessageForQuestion = Widget.ChallengeTestReportProps["suspiciousDegreeMessageForQuestion"];

type GenerateSuspiciousDegreeMessageForQuestionArgs = {
  challengeId: number;
  canShowFeedbackSuspiciousDegree: boolean;
};

export const useGenerateSuspiciousDegreeMessageForQuestion = (
  args: GenerateSuspiciousDegreeMessageForQuestionArgs,
): SuspiciousDegreeMessageForQuestion => {
  const { challengeId, canShowFeedbackSuspiciousDegree } = args;
  const challengeHooks = ChallengeTestReport.useCreateChallengeHooks(challengeId);
  const challenge = challengeHooks.useChallenge();
  const setDialogStatus = React.useCallback(
    (status: ChallengeTestReport.FeedbackSuspiciousDegreeDialogStatus) => {
      ChallengeTestReport.setFeedbackSuspiciousDegreeDialogStatus(challengeId, status);
    },
    [challengeId],
  );

  if (!canShowFeedbackSuspiciousDegree) {
    return undefined;
  }

  if (challenge.suspiciousDegree === "UNCALCULATED") {
    return undefined;
  }

  const suspiciousInference = challenge.suspiciousInference;
  if (suspiciousInference === null) {
    return undefined;
  }

  return {
    suspiciousDegree: challenge.suspiciousDegree,
    feedbackButton: {
      onClick: () => {
        setDialogStatus("OPEN");
      },
      disabled: suspiciousInference.feedback !== null,
    },
  };
};
