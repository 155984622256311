import { SKIP_OPTION_ID } from "@hireroo/app-definition/quiz";
import { QuizTestReport } from "@hireroo/app-store/view-domain/QuizTestReport";
import { useLanguageCode } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import { Widget } from "@hireroo/presentation";
import * as React from "react";

import { OptionStatus } from "./privateHelper";

type SubmittedOptionMap = Record<number, number[]>;
type OptionPlaybackContent = Exclude<Widget.QuizPlaybackEditorProps["playback"]["playbackContent"], { kind: "FREE_TEXT" }>;
type ParentPlaybackProps = Omit<Widget.QuizPlaybackEditorProps, "playback" | "playbackDialog">;

const OPTION_INTERVAL = 300;

type GenerateOptionPlaybackPropsArgs = {
  quizId: number;
  questionId: number;
  optionIds: number[];
  open: boolean;
  status: OptionStatus;
  parentProps: ParentPlaybackProps;
  handleOpen: () => void;
  handleClose: () => void;
  handlePlaybackValue: (newIndex: number) => void;
};

export const useGenerateOptionPlaybackProps = (args: GenerateOptionPlaybackPropsArgs): Widget.QuizPlaybackEditorProps => {
  const { parentProps, status, handlePlaybackValue, handleOpen, handleClose } = args;
  const lang = useLanguageCode();
  const hooks = QuizTestReport.useCreateQuizHooks(args.quizId);
  const quiz = hooks.useQuiz();
  const question = hooks.useQuestionByQuestionId(args.questionId);

  const lastSubmittedOptions = React.useMemo(() => {
    const submittedOptions: SubmittedOptionMap = {};
    quiz.submissions.forEach(submission => {
      submittedOptions[submission.questionId] = submission.optionIds;
    });
    return submittedOptions;
  }, [quiz.submissions]);

  const submittedOptionIds = React.useMemo(() => {
    if (question && lastSubmittedOptions[question.id]) {
      return lastSubmittedOptions[question.id];
    }
    return [];
  }, [lastSubmittedOptions, question]);

  const playbackToolbarProps = React.useMemo((): Widget.QuizPlaybackEditorProps["playbackToolbar"] => {
    return {
      ...parentProps.playbackToolbar,
      autoPlayIntervalMilliseconds: OPTION_INTERVAL,
      onChangePlaybackValue: handlePlaybackValue,
      screenButton: {
        onClick: handleOpen,
      },
    };
  }, [handleOpen, handlePlaybackValue, parentProps.playbackToolbar]);

  // NOTE: Generate props in the form of multiple-choice (SINGLE_CHOICE and MULTI_CHOICE) questions.
  // Other variants include like FREE_TEXT, but all are handled by the caller, so they are all major SINGLE_CHOICE.
  const playbackContentProps: OptionPlaybackContent = React.useMemo(() => {
    return question?.variant === "MULTI_CHOICE"
      ? {
          kind: "MULTI_CHOICE",
          content: {
            status,
            optionItems: (question?.options || []).map(
              (option): Extract<OptionPlaybackContent, { kind: "MULTI_CHOICE" }>["content"]["optionItems"][0] => {
                return {
                  id: option.id,
                  title: resolveLanguage(option, lang, "title"),
                  status: option.isCorrect ? "CORRECT" : "INCORRECT",
                };
              },
            ),
            skipOptionId: SKIP_OPTION_ID,
            selectedOptionIds: args.optionIds,
            submittedOptionIds: submittedOptionIds,
          },
        }
      : {
          kind: "SINGLE_CHOICE",
          content: {
            status,
            submittedOptionId: submittedOptionIds.length > 0 ? submittedOptionIds[0] : undefined,
            selectedOptionId: args.optionIds.length > 0 ? args.optionIds[0] : undefined,
            optionItems: (question?.options || []).map(
              (option): Extract<OptionPlaybackContent, { kind: "SINGLE_CHOICE" }>["content"]["optionItems"][0] => {
                return {
                  id: option.id,
                  title: resolveLanguage(option, lang, "title"),
                  status: option.isCorrect ? "CORRECT" : "INCORRECT",
                };
              },
            ),
            skipOptionId: SKIP_OPTION_ID,
          },
        };
  }, [args.optionIds, lang, question?.options, question?.variant, status, submittedOptionIds]);

  const playbackProps: Widget.QuizPlaybackEditorProps["playback"] = {
    description: resolveLanguage(question || {}, lang, "description"),
    playbackContent: playbackContentProps,
  };

  return {
    ...parentProps,
    playback: playbackProps,
    playbackToolbar: playbackToolbarProps,
    playbackDialog: {
      playbackToolbar: {
        ...playbackToolbarProps,
        screenButton: {
          onClick: handleClose,
        },
      },
      dialog: {
        open: args.open,
        onClose: handleClose,
      },
      playback: playbackProps,
    },
  };
};
