const key = "media-device.selected-video-input-device";
import { MediaDeviceSchema } from "@hireroo/validator";

export const SelectedVideoDeviceId = {
  get: (): MediaDeviceSchema.VideoDeviceId | null => {
    const value = localStorage.getItem(key);
    if (!value) {
      return null;
    }

    const result = MediaDeviceSchema.VideoDeviceIdSchema.safeParse(value);
    if (result.success) {
      return result.data;
    } else {
      return null;
    }
  },
  save: (id: MediaDeviceSchema.VideoDeviceId) => {
    localStorage.setItem(key, id);
  },
  clear: () => {
    localStorage.removeItem(key);
  },
};
