import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GeneratePaymentMethodUpdateFormPropsArgs, useGenerateProps } from "./useGenerateProps";

export type PaymentMethodUpdateFormContainerProps = GeneratePaymentMethodUpdateFormPropsArgs;

const PaymentMethodUpdateFormContainer: React.FC<PaymentMethodUpdateFormContainerProps> = props => {
  const paymentMethodUpdateFormProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.PaymentMethodUpdateForm {...paymentMethodUpdateFormProps} />
    </ErrorBoundary>
  );
};

PaymentMethodUpdateFormContainer.displayName = "PaymentMethodUpdateFormContainer";

export default withErrorBoundary(PaymentMethodUpdateFormContainer, {});
