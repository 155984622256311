import { TAB_PARAMS } from "@hireroo/app-definition/remote";
import { App } from "@hireroo/app-store/essential/employee";
import { RemotesIdDetailStore } from "@hireroo/app-store/page/e/remotes_id_detail";
import { TechnicalCommentPromptForRemote } from "@hireroo/app-store/widget/e/TechnicalCommentPromptForRemote";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

import ErrorPanelContainer from "../../../../../../widget/v2/e/ErrorPanel/Container";
import RemoteInterviewDetailContainer, { RemoteInterviewDetailContainerProps } from "./Container";
import { isValidReviewStatus } from "./privateHelper";

export type RemoteInterviewDetailFetchContainerProps = {};

const RemoteInterviewDetailFetchContainer: React.FC<RemoteInterviewDetailFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const initialized = RemotesIdDetailStore.useInitialized();
  const [query] = useSearchParams();
  const [initialTab] = React.useState(query.get("tab"));

  const { id } = useParams<{ id: string }>();
  const remoteId = `${id}`;

  const [result] = Graphql.useGetRemoteForRemoteInterviewQuery({
    variables: {
      remoteId: remoteId,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "network-only",
  });

  React.useEffect(() => {
    if (result.data?.remote) {
      RemotesIdDetailStore.updateInterview(result.data?.remote);
    }
  }, [result.data]);

  React.useEffect(() => {
    if (!result.data?.remote?.status) return;
    if (initialTab === TAB_PARAMS.OVERVIEW || initialTab === TAB_PARAMS.PLAYBACK || initialTab === TAB_PARAMS.REVIEW) {
      if (!isValidReviewStatus(result.data.remote.status)) return;
      RemotesIdDetailStore.updateCurrentTab(initialTab);
    }
  }, [initialTab, result.data?.remote?.status]);

  React.useEffect(() => {
    return () => {
      TechnicalCommentPromptForRemote.clear();
    };
  }, []);

  if (result.error) {
    return <ErrorPanelContainer mode="PERCENTAGE" graphqlErrors={result.error.graphQLErrors} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: RemoteInterviewDetailContainerProps = {};

  return <RemoteInterviewDetailContainer {...containerProps} />;
};

RemoteInterviewDetailFetchContainer.displayName = "RemoteInterviewDetailFetchContainer";

export default withErrorBoundary(RemoteInterviewDetailFetchContainer, {});
