import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import * as React from "react";

import QuizQuestionSearchField, { QuizQuestionSearchFieldProps } from "../../../usecase/QuizQuestionSearchField/QuizQuestionSearchField";
import QuizQuestionEditorDialog, { QuizQuestionEditorDialogProps } from "./QuizQuestionEditorDialog";
import QuizResourcePreviewDialog, { QuizResourcePreviewDialogProps } from "./QuizResourcePreviewDialog";
import SelectedQuestionTable, { SelectedQuestionTableProps } from "./SelectedQuestionTable/SelectedQuestionTable";
import WarningMessage, { WarningMessageProps } from "./WarningMessage";

export type SelectQuestionsProps = {
  warningMessage?: WarningMessageProps;
  SearchQuizQuestion: React.ReactNode;
  selectedQuestionTable: SelectedQuestionTableProps;
  quizQuestionSearchField: QuizQuestionSearchFieldProps;
  quizResourcePreviewDialog: QuizResourcePreviewDialogProps;
  QuizQuestionEditor: React.ReactNode;
  quizQuestionEditorDialog: Omit<QuizQuestionEditorDialogProps, "children">;
};

const SelectQuestions: React.FC<SelectQuestionsProps> = props => {
  return (
    <Box>
      {props.warningMessage && <WarningMessage {...props.warningMessage} />}
      <Box mt={2}>
        <Grid container spacing={4}>
          <Grid item xs={6} mb={4}>
            <QuizQuestionSearchField {...props.quizQuestionSearchField} />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            {props.SearchQuizQuestion}
          </Grid>
          <Grid item xs={6}>
            <SelectedQuestionTable {...props.selectedQuestionTable} />
          </Grid>
        </Grid>
      </Box>

      <QuizQuestionEditorDialog {...props.quizQuestionEditorDialog}>{props.QuizQuestionEditor}</QuizQuestionEditorDialog>
      <QuizResourcePreviewDialog {...props.quizResourcePreviewDialog} />
    </Box>
  );
};

SelectQuestions.displayName = "SelectQuestions";

export default SelectQuestions;
