import { AssessmentOverview } from "@hireroo/app-store/widget/t/AssessmentOverview";
import * as TimeFormatter from "@hireroo/formatter/time";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";

export type GenerateAssessmentOverviewPropsArgs = {
  companyId: number;
};

export const useGenerateProps = (_args: GenerateAssessmentOverviewPropsArgs): Widget.AssessmentOverviewProps => {
  const lang = useLanguageCode();

  const assessment = AssessmentOverview.useAssessment();
  const exam = AssessmentOverview.useExam();
  const { t } = useTranslation();

  const examStatusMap = {
    CREATED: t("作成済み"),
    STARTED: t("実施中"),
    COMPLETED: t("実施済み"),
    FINALIZED: t("採点済み"),
    EXPIRED: t("期限切れ"),
    UNKNOWN: t("不明"),
  } as const satisfies Record<Graphql.ExamStatus, string>;

  return {
    summary: {
      terms: {
        startDate: exam.createdAtSeconds ? TimeFormatter.unixToDatetimeFormat(exam.createdAtSeconds) : "",
        endDate: exam.willEndAtSeconds ? TimeFormatter.unixToDatetimeFormat(exam.willEndAtSeconds) : "",
      },
      nextTerms: undefined,
      completedDate: exam.didEndAtSeconds ? TimeFormatter.unixToDatetimeFormat(exam.didEndAtSeconds) : "",
      status: examStatusMap[exam.status],
      timeLimit: exam.timeLimitSeconds ? TimeFormatter.formatMinutes(exam.timeLimitSeconds / 60, lang) : "0",
      messageForTalent: exam.messageForTalent || undefined,
      isPublic: assessment.isPublic,
      authorizedMemberAndGroups: assessment.viewers.map((viewer): Widget.AssessmentOverviewProps["summary"]["authorizedMemberAndGroups"][0] => {
        if (viewer.__typename === "User") {
          return {
            key: viewer.uid,
            label: viewer.displayName || viewer.email,
            kind: "MEMBER",
            photoUrl: viewer.photoUrl,
          };
        } else if (viewer.__typename === "EmployeeGroup") {
          return {
            key: viewer.groupName,
            label: viewer.groupName,
            kind: "GROUP",
          };
        } else {
          throw new Error(`Invalid Viewer ${viewer satisfies never}`);
        }
      }),
    },
    questions: undefined,
  };
};
