import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";

type Participant = {
  id: string;
  displayText: string;
  src?: string;
  alt?: string;
  color: string;
};

const SIZE = 32;

export type RemoteInterviewParticipantsProps = {
  participants: Participant[];
};

const RemoteInterviewParticipants: React.FC<RemoteInterviewParticipantsProps> = props => {
  return (
    <AvatarGroup max={5} spacing={25}>
      {props.participants.map(item => (
        <Tooltip key={item.id} title={item.displayText}>
          <Avatar key={item.id} sx={{ bgcolor: item.color, width: SIZE, height: SIZE }} src={item.src} alt={item.alt} />
        </Tooltip>
      ))}
    </AvatarGroup>
  );
};

RemoteInterviewParticipants.displayName = "RemoteInterviewParticipants";

export default RemoteInterviewParticipants;
