import { useTranslation } from "@hireroo/i18n";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CodeOutlinedIcon from "@mui/icons-material/CodeOutlined";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import LocalFireDepartmentOutlinedIcon from "@mui/icons-material/LocalFireDepartmentOutlined";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Chip } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DifficultyStars, { DifficultyStarsProps } from "../../../modules/DifficultyStars/DifficultyStars";
import Link, { LinkProps } from "../../../primitive/Link/Link";
import LeakScoreChip, { LeakScoreChipProps } from "../LeakScoreChip/LeakScoreChip";
import LabelItem, { LabelItemProps } from "./parts/LabelItem/LabelItem";

const EllipsisText = styled(Typography)(() => ({
  textOverflow: "ellipsis",
  fontWeight: 700,
  fontSize: "16px",
  maxWidth: 512,
  whiteSpace: "pre-wrap",
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  textOverflow: "ellipsis",
  fontWeight: 700,
  fontSize: "16px",
  maxWidth: 512,
}));

const NewMarkChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.info.main,
  color: theme.palette.info.contrastText,
  lineHeight: "14px",
  height: "16px",
  padding: "1px 4px 1px 4px",
  fontSize: "10px",
  ".MuiChip-label": {
    padding: 0,
  },
}));

type Mark = "NEW" | "NONE";

const markLabelMap: Record<Mark, string> = {
  NEW: "NEW",
  NONE: "",
};

export type QuestionMetaProps = {
  title: string | Pick<LinkProps, "href" | "children" | "onClick">;
  variant: string | [string, string];
  difficulty?: DifficultyStarsProps["difficulty"];
  isOfficial: boolean;
  mark: Mark;
  enableLanguages?: string[];

  timelimit?: string;
  averageScore?: string;
  numOfUse?: string;
  leakScore?: LeakScoreChipProps;
  skillTags?: string[];
  skillTagsTooltip?: string;
};

const QuestionMeta: React.FC<QuestionMetaProps> = props => {
  const { t } = useTranslation();

  const variantLabelItem = React.useMemo((): LabelItemProps => {
    const label = ((): React.ReactNode => {
      if (typeof props.variant === "string") {
        return props.variant;
      }
      return props.variant.join(" ");
    })();
    return {
      color: "secondary",
      StartIcon: <LabelOutlinedIcon fontSize="small" />,
      label,
    };
  }, [props.variant]);

  const labelItems = React.useMemo((): LabelItemProps[] => {
    const items: LabelItemProps[] = [];
    if (props.timelimit) {
      items.push({
        color: "secondary",
        StartIcon: <AccessTimeOutlinedIcon fontSize="small" />,
        label: props.timelimit,
        noWrap: true,
      });
    }
    if (props.averageScore) {
      items.push({
        color: "secondary",
        StartIcon: <CheckCircleOutlineIcon fontSize="small" />,
        label: props.averageScore,
        noWrap: true,
      });
    }
    if (props.numOfUse) {
      items.push({
        color: "primary",
        StartIcon: <LocalFireDepartmentOutlinedIcon color="primary" fontSize="small" />,
        label: props.numOfUse,
        noWrap: true,
      });
    }
    items.push(variantLabelItem);
    if (props.enableLanguages) {
      items.push({
        color: "secondary",
        StartIcon: <CodeOutlinedIcon fontSize="small" />,
        label: props.enableLanguages.length > 3 ? `${props.enableLanguages.slice(0, 3).join(", ")}...` : props.enableLanguages.join(", "),
        tooltipText: props.enableLanguages.length > 3 ? props.enableLanguages.join(", ") : undefined,
        noWrap: true,
      });
    }
    if (props.skillTags && props.skillTags.length > 0) {
      items.push({
        color: "secondary",
        StartIcon: <LabelOutlinedIcon fontSize="small" />,
        label: props.skillTags.length > 3 ? `${props.skillTags.slice(0, 3).join(", ")}...` : props.skillTags.join(", "),
        tooltipText: props.skillTagsTooltip,
        noWrap: true,
      });
    }
    return items;
  }, [props, variantLabelItem]);

  return (
    <Box display="flex" flexDirection="column">
      <Stack direction="row" spacing={1} alignItems="center">
        <Box sx={{ flexGrow: 0, flexShrink: 1 }}>
          {typeof props.title === "string" && <EllipsisText>{props.title}</EllipsisText>}
          {typeof props.title !== "string" && <StyledLink {...props.title} />}
        </Box>
        {props.isOfficial && (
          <Tooltip title={t("HireRooが作成した公式の問題です。")}>
            <VerifiedIcon color="primary" />
          </Tooltip>
        )}
        {props.difficulty && <DifficultyStars difficulty={props.difficulty} />}
        {props.leakScore && <LeakScoreChip {...props.leakScore} />}
        {props.mark === "NEW" && <NewMarkChip label={markLabelMap[props.mark]} />}
      </Stack>
      <Stack mt={1} direction="row" alignItems="center" spacing={1.5}>
        {labelItems.map((labelItemProps, index) => {
          return <LabelItem key={`item-${index}`} {...labelItemProps} />;
        })}
      </Stack>
    </Box>
  );
};

QuestionMeta.displayName = "QuestionMeta";

export default QuestionMeta;
