import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { darken, styled, useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";

import { ELEMENT_LABEL, ElementLabel } from "../../../../../features";

const ObjectBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: 10,
  "&:hover": {
    background: darken(theme.palette.common.white, 0.2),
  },
}));

export type DraggableObjectItemProps = {
  label: ElementLabel | "text";
  displayLabel: string;
  image: string;
  onClickElement: (label: ElementLabel | "text") => void;
  onDragElementStart: (e: React.DragEvent<HTMLDivElement>, label: ElementLabel | "text") => void;
  onTooltipOpen: () => void;
  canTooltipOpen: boolean;
  tooltipText: string;
};

const DraggableObjectItem: React.FC<DraggableObjectItemProps> = props => {
  const theme = useTheme();
  const [isHovering, setIsHovering] = useState<boolean>(false);

  return (
    <Grid item xs={"auto"} key={`item-${props.label}`}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        width={65}
        id={props.label === ELEMENT_LABEL.user ? "user-object" : ""}
      >
        <Tooltip title={props.tooltipText} onOpen={props.onTooltipOpen} open={isHovering && props.canTooltipOpen}>
          <ObjectBox
            width={60}
            height={60}
            display={"flex"}
            flexDirection={"column"}
            justifyContent="center"
            alignItems={"center"}
            onClick={() => props.onClickElement(props.label)}
            onDragStart={e => props.onDragElementStart(e, props.label)}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            draggable
            sx={{ cursor: "grab", boxShadow: 2 }}
          >
            <Box display="flex" justifyContent="center">
              <img src={props.image} alt={`icon-${props.label}`} width={40} height={40} />
            </Box>
          </ObjectBox>
        </Tooltip>
        <Box mt={1}>
          <Typography sx={{ fontSize: theme.typography.caption }}>{props.displayLabel}</Typography>
        </Box>
      </Box>
    </Grid>
  );
};

DraggableObjectItem.displayName = "DraggableObjectItem";

export default DraggableObjectItem;
