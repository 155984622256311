import { translationLanguageMap } from "@hireroo/app-definition";
import { createAnswersInput, createHintInput } from "@hireroo/app-helper/algorithm";
import { Auth } from "@hireroo/app-store/essential/employee";
import { AlgorithmResourceEditor } from "@hireroo/app-store/view-domain/AlgorithmResourceEditor";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { parseOutputRecords, typeLabelMap } from "@hireroo/challenge/definition";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { SupportLanguageValue, useLanguageCode, useTranslation } from "@hireroo/i18n";
import { Pages, Widget } from "@hireroo/presentation/legacy";
import { AlgorithmTestCaseForm, ValidateAlgorithmFunctionVariantInput, ValidateDatabaseVariantInput } from "@hireroo/validator";
import * as React from "react";
import { SubmitHandler, useFieldArray } from "react-hook-form";

import { getDBTestCasesInputsErrorMessages, getDBTestCasesOutputsErrorMessages } from "./privateHelper";

type TestCasesProps = Pages.AlgorithmResourceEditorProps["testCases"];

type TestCases = {
  props: TestCasesProps;
  saveButton: Pages.AlgorithmResourceEditorProps["saveTestCasesButton"];
  onDraftSave: () => void;
};
export type UseGenerateTestCasesPropsArgs = {
  companyId: number;
};

const DELIMITER = ", ";

export const useGenerateTestCasesProps = (args: UseGenerateTestCasesPropsArgs): TestCases => {
  const { t } = useTranslation();
  const { method, contextProps } = Widget.useAlgorithmTestCasesFormContext();
  const question = AlgorithmResourceEditor.useQuestion();
  const client = getGraphqlClient();
  const userId = Auth.useCurrentUid();
  const lang = useLanguageCode();

  const [currentTabValue, setCurrentTabValue] = React.useState<string>(lang);
  const [isTranslateLoading, setIsTranslateLoading] = React.useState<boolean>(false);
  const saveStatus = AlgorithmResourceEditor.useSaveStatus();
  const processing = React.useRef(false);

  const selectedLanguagesField = useFieldArray({
    control: method.control,
    name: "selectedLanguages",
  });
  const selectedLanguages = method.watch("selectedLanguages");

  const selectedLanguagesSet = React.useMemo((): Set<SupportLanguageValue | undefined> => {
    if (selectedLanguages) {
      return new Set(selectedLanguages.map(s => s.value));
    }
    return new Set();
  }, [selectedLanguages]);

  const validateCorrectnessTC = React.useCallback(
    (correctnessTCs: AlgorithmTestCaseForm.CorrectnessTestCasesSchema): boolean => {
      let isValid = true;
      correctnessTCs.data.forEach((tc, tcIndex) => {
        // テストケースの引数は1つ以上入力されている。
        if (tc.inputs.length <= 0) {
          method.setError(`correctnessTestCases.data.${tcIndex}.inputs.0`, {
            type: "invalid",
            message: t("テストケース（正解率）の引数は必須項目です。"),
          });

          isValid = false;
        }

        // テストケースの返り値は1つ以上入力されている。
        if (tc.outputs.length <= 0) {
          method.setError(`correctnessTestCases.data.${tcIndex}.outputs.0`, {
            type: "invalid",
            message: t("テストケース（正解率）の返り値は必須項目です。"),
          });

          isValid = false;
        }

        if (contextProps.signatureProps.variant === "DATABASE") {
          const signature = contextProps.signatureProps.signature;
          if (signature === null) {
            return false;
          }
          const inputsErrorMessages = getDBTestCasesInputsErrorMessages(tc, signature);
          if (inputsErrorMessages.length > 0) {
            method.setError(`correctnessTestCases.data.${tcIndex}.inputs`, {
              type: "invalid",
              message: `${t("入力された値が型情報と異なります。")} ${t("期待する型")}: ${inputsErrorMessages.join(DELIMITER)}`,
            });

            isValid = false;
          }

          const outputsErrorMessages = getDBTestCasesOutputsErrorMessages(tc, signature);
          if (outputsErrorMessages.length > 0) {
            method.setError(`correctnessTestCases.data.${tcIndex}.outputs`, {
              type: "invalid",
              message: `${t("入力された値が型情報と異なります。")}${t("期待する型")}: ${outputsErrorMessages.join(DELIMITER)}`,
            });

            isValid = false;
          }
        } else if (contextProps.signatureProps.variant === "ALGORITHM") {
          const signature = contextProps.signatureProps.signature;
          // Inputの型がsignatureの定義と一緒かどうか。
          tc.inputs.forEach((input, iIndex) => {
            if (!ValidateAlgorithmFunctionVariantInput.isAlgorithmTestCaseValid(input, signature.inputs[iIndex].type)) {
              method.setError(`correctnessTestCases.data.${tcIndex}.inputs.${iIndex}`, {
                type: "invalid",
                message: t("入力された値が型情報と異なります。"),
              });

              isValid = false;
            }
          });

          // Outputの型がsignatureの定義と一緒かどうか。
          tc.outputs.forEach((output, oIndex) => {
            if (!ValidateAlgorithmFunctionVariantInput.isAlgorithmTestCaseValid(output, signature.outputs[oIndex].type)) {
              method.setError(`correctnessTestCases.data.${tcIndex}.outputs.${oIndex}`, {
                type: "invalid",
                message: t("入力された値が型情報と異なります。"),
              });

              isValid = false;
            }
          });
        } else {
          // TODO: Please add when you create a new page for creating class type signature
        }
      });

      return isValid;
    },
    [contextProps.signatureProps.signature, contextProps.signatureProps.variant, method, t],
  );

  const validatePerformanceTC = React.useCallback(
    (performanceTCs: AlgorithmTestCaseForm.PerformanceTestCasesSchema): boolean => {
      let isValid = true;
      // performance testCase は最低１件は必要。
      // 入力された値がsignatureの型情報に沿って入力されているかをチェックする。
      performanceTCs.data.forEach((tc, tcIndex) => {
        // ラベルは入力されているか。
        if (tc.label === "") {
          method.setError(`performanceTestCases.data.${tcIndex}.label`, {
            type: "invalid",
            message: t("テストケース（パフォーマンス）のラベルは必須項目です。"),
          });

          isValid = false;
        }

        // テストケースの引数は1つ以上入力されている。
        if (tc.inputs.length <= 0) {
          method.setError(`performanceTestCases.data.${tcIndex}.inputs.0`, {
            type: "invalid",
            message: t("テストケース（パフォーマンス）の引数は必須項目です。"),
          });

          isValid = false;
        }

        // テストケースの返り値は1つ以上入力されている。
        if (tc.outputs.length <= 0) {
          method.setError(`performanceTestCases.data.${tcIndex}.outputs.0`, {
            type: "invalid",
            message: t("テストケース（パフォーマンス）の返り値は必須項目です。"),
          });

          isValid = false;
        }

        if (contextProps.signatureProps.variant === "DATABASE") {
          const signature = contextProps.signatureProps.signature;
          const inputsErrorMessages: string[] = getDBTestCasesInputsErrorMessages(tc, signature);
          if (inputsErrorMessages.length > 0) {
            method.setError(`performanceTestCases.data.${tcIndex}.inputs`, {
              type: "invalid",
              message: `${t("入力された値が型情報と異なります。")} ${t("期待する型")}: ${inputsErrorMessages.join(DELIMITER)}`,
            });

            isValid = false;
          }

          const outputsErrorMessages = getDBTestCasesOutputsErrorMessages(tc, signature);
          if (outputsErrorMessages.length > 0) {
            method.setError(`performanceTestCases.data.${tcIndex}.outputs`, {
              type: "invalid",
              message: `${t("入力された値が型情報と異なります。")}${t("期待する型")}: ${outputsErrorMessages.join(DELIMITER)}`,
            });
          }
          tc.outputs.forEach(output => {
            const parsedOutput = parseOutputRecords(output);
            // return when null
            if (parsedOutput[0] === undefined) return;
            // The outputs have only one table, hence parse the first element
            Object.values(parsedOutput[0]).forEach((val, columnIndex) => {
              if (val === undefined) return;
              const col = signature.columns[columnIndex];
              if (col === undefined) {
                method.setError(`performanceTestCases.data.${tcIndex}.outputs.${columnIndex}`, {
                  type: "invalid",
                  message: `${t("入力された値が型情報と異なります。")}(${columnIndex})`,
                });
              } else if (!ValidateDatabaseVariantInput.isDatabaseTestCaseValid(val?.toString() || null, col.type)) {
                method.setError(`performanceTestCases.data.${tcIndex}.outputs.${columnIndex}`, {
                  type: "invalid",
                  message: `${t("入力された値が型情報と異なります。")}${t("期待する型")}: ${typeLabelMap[col.type]} (${col.name}: ${val})`,
                });

                isValid = false;
              }
            });
          });
        } else if (contextProps.signatureProps.variant === "ALGORITHM") {
          const signature = contextProps.signatureProps.signature;
          // Inputの型がsignatureの定義と一緒かどうか。
          tc.inputs.forEach((input, iIndex) => {
            if (!ValidateAlgorithmFunctionVariantInput.isAlgorithmTestCaseValid(input, signature.inputs[iIndex].type)) {
              method.setError(`performanceTestCases.data.${tcIndex}.inputs.${iIndex}`, {
                type: "invalid",
                message: t("入力された値が型情報と異なります。"),
              });
              isValid = false;
            }
          });

          // Outputの型がsignatureの定義と一緒かどうか。
          tc.outputs.forEach((output, oIndex) => {
            if (!ValidateAlgorithmFunctionVariantInput.isAlgorithmTestCaseValid(output, signature.outputs[oIndex].type)) {
              method.setError(`performanceTestCases.data.${tcIndex}.outputs.${oIndex}`, {
                type: "invalid",
                message: t("入力された値が型情報と異なります。"),
              });
              isValid = false;
            }
          });
        } else {
          // TODO: Please add when you create a new page for creating class type signature
        }
      });

      return isValid;
    },
    [contextProps.signatureProps.signature, contextProps.signatureProps.variant, method, t],
  );

  const handleSave: (isDraft: boolean) => SubmitHandler<AlgorithmTestCaseForm.AlgorithmTestCaseFormSchema> = React.useCallback(
    isDraft => fields => {
      if (question) {
        if (!isDraft) {
          const isValid = validateCorrectnessTC(fields.correctnessTestCases) && validatePerformanceTC(fields.performanceTestCases);
          if (!isValid) {
            AlgorithmResourceEditor.updateSaveStatus("READY");
            processing.current = false;
            return;
          }
        }
        const correctnessTC = JSON.stringify(fields.correctnessTestCases.data);
        const performanceTC = JSON.stringify(fields.performanceTestCases.data);

        client
          .UpdateAlgorithmQuestion({
            updateQuestionInput: {
              questionId: question.questionId,
              oldVersion: question.version,
              newVersion: question.version,
              companyId: args.companyId,
              employeeId: userId,
              variant: question.variant,
              difficulty: question.difficulty,
              timeLimitMin: (question?.timeLimitSeconds || 1800) / 60,
              isPrivate: question.isPrivate,
              status: "DRAFT",
              titleJa: question.titleJa,
              titleEn: question.titleEn,
              descriptionJa: question.descriptionJa,
              descriptionEn: question.descriptionEn,
              correctnessTestCase: correctnessTC,
              performanceTestCase: performanceTC,
              /**
               * new rpc is not implemented yet so we need to pass undefined
               */
              correctnessTestCaseIds: undefined,
              performanceTestCaseIds: undefined,
              performanceTimeLimitMilliSeconds: fields.performanceTestCases.timeLimit,
              signature: question.signature,
              answers: createAnswersInput(question.answers),
              hints: question.hints.map(h => createHintInput(h)),
            },
          })
          .then(res => {
            AlgorithmResourceEditor.updateQuestion(res.updateAlgorithmQuestion);
            if (!isDraft) {
              AlgorithmResourceEditor.updateActiveStep(AlgorithmResourceEditor.STEPS.ANSWERS);
            }
            Snackbar.notify({
              severity: "success",
              message: t("問題の一時保存が完了しました。"),
            });
          })
          .catch(() => {
            Snackbar.notify({
              severity: "error",
              message: t("問題の一時保存に失敗しました。しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
            });
          })
          .finally(() => {
            AlgorithmResourceEditor.updateSaveStatus("READY");
            processing.current = false;
          });
      }
    },
    [args.companyId, client, question, t, userId, validateCorrectnessTC, validatePerformanceTC],
  );

  const translateCorrectnessTc = React.useCallback(
    async (
      correctnessTc: AlgorithmTestCaseForm.CorrectnessTestCaseSchema,
      from: SupportLanguageValue,
      to: SupportLanguageValue,
    ): Promise<Partial<AlgorithmTestCaseForm.CorrectnessTestCaseSchema>> => {
      const title = correctnessTc[`title_${from}`];
      const description = correctnessTc[`description_${from}`];

      const res = await Promise.all([
        client.TranslatePlainTextForAlgorithmResourceEditor({
          source: {
            from: translationLanguageMap[from],
            to: translationLanguageMap[to],
            body: title,
          },
        }),
        client.TranslateMarkdownForAlgorithmResourceEditor({
          source: {
            from: translationLanguageMap[from],
            to: translationLanguageMap[to],
            body: description,
          },
        }),
      ])
        .then(res => {
          Snackbar.notify({
            severity: "success",
            message: t("自動翻訳に成功しました。"),
          });
          return res;
        })
        .catch(() => {
          Snackbar.notify({
            severity: "error",
            message: t("自動翻訳に失敗しました。しばらくしてから再度お試しください。"),
          });
          return [];
        });

      if (to === "ja") {
        return {
          title_ja: res[0]?.translatePlainText ?? "",
          description_ja: res[1]?.translateMarkdown ?? "",
        };
      } else {
        return {
          title_en: res[0]?.translatePlainText ?? "",
          description_en: res[1]?.translateMarkdown ?? "",
        };
      }
    },
    [client, t],
  );
  const correctnessTestCases = method.watch("correctnessTestCases");

  const translateCorrectnessTestCases = React.useCallback(
    async (from: SupportLanguageValue, to: SupportLanguageValue) => {
      const isValid = await method.trigger(["correctnessTestCases"]);
      if (isValid) {
        const translatedPromise = correctnessTestCases.data.map(correctnessTc => {
          return translateCorrectnessTc(correctnessTc, from, to);
        });
        const translatedRes = await Promise.all(translatedPromise);
        translatedRes.forEach((correctnessTc, i) => {
          const translatedTitle = correctnessTc[`title_${to}`];
          const translatedDescription = correctnessTc[`description_${to}`];

          method.setValue(`correctnessTestCases.data.${i}.title_${to}`, translatedTitle ?? "");
          method.setValue(`correctnessTestCases.data.${i}.description_${to}`, translatedDescription ?? "");
        });
      }
      return isValid;
    },
    [method, translateCorrectnessTc, correctnessTestCases],
  );

  return {
    props: {
      correctness: {
        languageTab: {
          onTabsChange: value => {
            setCurrentTabValue(value);
          },
          menu: {
            items: [
              {
                text: "日本語",
                value: "ja",
                onClick: async () => {
                  setIsTranslateLoading(true);
                  const isValid = await translateCorrectnessTestCases(currentTabValue as SupportLanguageValue, "ja");
                  setIsTranslateLoading(false);
                  if (!isValid) return;
                  selectedLanguagesField.append({ value: "ja" });
                },
                isLoading: isTranslateLoading,
              },
              {
                text: "English",
                value: "en",
                onClick: async () => {
                  setIsTranslateLoading(true);
                  const isValid = await translateCorrectnessTestCases(currentTabValue as SupportLanguageValue, "en");
                  setIsTranslateLoading(false);
                  if (!isValid) return;
                  selectedLanguagesField.append({ value: "en" });
                },
                isLoading: isTranslateLoading,
              },
            ].filter(item => !selectedLanguagesSet.has(item.value as SupportLanguageValue)),
          },
        },
      },
    },
    saveButton: {
      onClick: event => {
        event.preventDefault();
        //prevent multiple click
        if (processing.current) return;
        processing.current = true;
        AlgorithmResourceEditor.updateSaveStatus("LOADING");
        method.handleSubmit(handleSave(false), () => {
          AlgorithmResourceEditor.updateSaveStatus("READY");
          processing.current = false;
        })();
      },
      loading: saveStatus === "LOADING",
    },
    onDraftSave: () => {
      AlgorithmResourceEditor.updateSaveStatus("LOADING");
      method.handleSubmit(handleSave(true), () => AlgorithmResourceEditor.updateSaveStatus("READY"))();
    },
  };
};
