import { useTitle } from "@hireroo/app-helper/react-use";
import { Auth, Role } from "@hireroo/app-store/essential/employee";
import { Assessments } from "@hireroo/app-store/page/e/assessments";
import { EmployeeSideBarContent } from "@hireroo/app-store/widget/e/EmployeeSideBarContent";
import { useLanguageCode, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { type Pages, Widget } from "@hireroo/presentation";
import { generateHelpCenterUrl, generatePath, navigate as navigateApi } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as React from "react";

import EmployeeHeaderRightContentContainer from "../../../../widget/v2/e/EmployeeHeaderRightContent/Container";
import EmployeeSideBarContentContainer from "../../../../widget/v2/e/EmployeeSideBarContent/Container";
import FooterContainer from "../../../../widget/v2/e/Footer/Container";
import { useNotificationBanner } from "../../../../widget/v2/e/NotificationBanner/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import { useFetchSearchParams, useValidSearchParamsFromQueryParams } from "./privateHelper";
import AssessmentListForEmployeeFetchContainer from "./widget/AssessmentListForEmployee/FetchContainer";

type ViewerOptionFieldValue = Pages.AssessmentListForEmployeeProps["search"]["viewerField"]["options"][0]["value"] &
  Assessments.SearchFilter["viewers"][0];
type MultipleViewerChoiceOption = Pages.AssessmentListForEmployeeProps["search"]["viewerField"]["options"][0];

export type GenerateAssessmentListForEmployeePropsArgs = {};

export const useGenerateProps = (_args: GenerateAssessmentListForEmployeePropsArgs): Pages.AssessmentListForEmployeeProps => {
  const currentUser = Auth.useCurrentUser();
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const lang = useLanguageCode();
  const navigate = useTransitionNavigate();
  const result = useFetchSearchParams();
  const searchParams = result.data;
  const matchingRole = Role.useMatchingRole();
  const currentRoleDisplayText = Role.useCurrentRoleDisplayText();
  const expectedRole = Role.useRoleToText("MANAGER");
  const NotificationBannerContainer = useNotificationBanner();

  const initializedSelectableSearchParams = !!result.data;
  useTitle(t("タレントスコア一覧"));

  const viewerIds = (searchParams?.assessmentListOptionsByFields.viewers || []).map(viewer => {
    if (viewer.__typename === "User") {
      return viewer.uid;
    }
    return viewer.employeeGroupId;
  });
  const validSearchParams = useValidSearchParamsFromQueryParams({
    selectableViewerIds: viewerIds,
    selectableTalentIds: (searchParams?.assessmentListOptionsByFields.talents || []).map(({ uid }) => uid),
  });

  const viewerFieldOptions: MultipleViewerChoiceOption[] = React.useMemo(() => {
    if (!searchParams?.assessmentListOptionsByFields.viewers) {
      return [];
    }
    return searchParams.assessmentListOptionsByFields.viewers.reduce<MultipleViewerChoiceOption[]>((acc, viewer) => {
      if (viewer.__typename === "EmployeeGroup") {
        const value: ViewerOptionFieldValue = {
          id: viewer.employeeGroupId,
          type: "EMPLOYEE_GROUP",
          groupId: viewer.employeeGroupId,
        };

        return [
          ...acc,
          {
            value: value,
            displayName: viewer.groupName,
          },
        ];
      }

      const value: ViewerOptionFieldValue = {
        id: viewer.uid,
        type: "EMPLOYEE",
        employeeId: viewer.uid,
      };
      const option: MultipleViewerChoiceOption = {
        value: value,
        displayName: viewer.displayName || viewer.email,
        iconSrc: viewer.photoUrl,
      };

      if (viewer.uid === currentUser.uid) {
        return [option, ...acc];
      }

      return [...acc, option];
    }, []);
  }, [currentUser.uid, searchParams?.assessmentListOptionsByFields.viewers]);

  return {
    layout: {
      openSidebar: EmployeeSideBarContent.useOpenSidebar(),
      onChangeOpenSidebar: open => {
        EmployeeSideBarContent.setOpenSidebar(open);
      },
      NotificationBanner: NotificationBannerContainer,
      HeaderRightContent: <EmployeeHeaderRightContentContainer />,
      SideBarContent: <EmployeeSideBarContentContainer />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      sideBarHeader: {
        logo: {
          href: generatePath("/e/assessments"),
          onClick: () => {
            navigate("/e/assessments");
          },
        },
      },
    },
    active: initializedSelectableSearchParams,
    search: {
      defaultValues: validSearchParams.searchFilter,
      viewerField: {
        options: viewerFieldOptions,
      },
      talentField: {
        options: (searchParams?.assessmentListOptionsByFields.talents || []).map(talent => {
          return {
            value: talent.uid,
            displayName: talent.displayName || talent.email,
            iconSrc: talent.photoUrl,
          };
        }),
      },
      onChange: filter => {
        Assessments.updateCurrentSearchFilter(filter);
      },
      disabled: false,
    },
    AssessmentList: initializedSelectableSearchParams ? (
      <AssessmentListForEmployeeFetchContainer defaultSortFieldValue={validSearchParams.sortField.sortFieldValue} />
    ) : (
      <Widget.Loading kind="CENTER_%" />
    ),
    helpButton: {
      onClick: () => {
        const url = generateHelpCenterUrl(lang, "ASSESSMENT_EMPLOYEE_LIST_PAGE");
        navigateApi(url, { _blank: true });
      },
    },
    createAssessmentButton: {
      tooltipText: t2("PermissionTooltip", {
        actualRole: currentRoleDisplayText,
        expectedRole: expectedRole,
      }),
      disabled: !matchingRole.ADMIN_OR_MANAGER.matched,
      onClick: () => {
        navigate("/e/assessments/create");
      },
    },
  };
};
