import { useTranslation } from "@hireroo/i18n";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme, value }) => ({
  height: "40px",
  marginTop: 0,
  marginBottom: 0,
  "& .MuiToggleButtonGroup-root": {
    borderRadius: "4px",
  },
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(1),
    border: 0,
    borderRadius: "4px",
  },
  "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
    borderRadius: "4px",
  },
  "& .MuiToggleButtonGroup-grouped:not(:last-of-type)": {
    borderRadius: "4px",
  },
  "&.Mui-disabled": {
    border: 0,
    backgroundColor: theme.palette.action.hover,
  },
  "& .Mui-selected": {
    backgroundColor: value === "CORRECT" ? theme.palette.success.dark : theme.palette.error.dark,
  },
  borderRadius: "4px",
  "& :not(:first-of-type)": {
    borderRadius: "4px",
  },
  "& :not(:last-of-type)": {
    borderRadius: "4px",
  },
  "& :first-of-type": {
    borderRadius: "4px",
  },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme, value }) => ({
  borderRadius: "4px",
  "&.Mui-selected": {
    backgroundColor: value === "CORRECT" ? theme.palette.success.main : theme.palette.error.main,
    "&:hover": {
      backgroundColor: value === "CORRECT" ? theme.palette.success.main : theme.palette.error.main,
    },
  },
  backgroundColor: theme.palette["Gray/Shades"].p8,
  marginTop: 0,
  marginBottom: 0,
  paddingTop: 0.5,
  paddingBottom: 0.5,
}));

type Value = "CORRECT" | "WRONG";

export type CorrectToggleButtonsProps = {
  onChange: (value: Value) => void;
  defaultValue?: Value;
};

const CorrectToggleButtons: React.FC<CorrectToggleButtonsProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [value, setValue] = React.useState<Value | null>(props.defaultValue ?? null);

  return (
    <StyledToggleButtonGroup
      value={value}
      exclusive
      onChange={(e, value) => {
        // Enforce selection: https://mui.com/material-ui/react-toggle-button/#enforce-value-set
        if (value !== null) {
          setValue(value);
          props.onChange(value);
        }
      }}
      size="small"
    >
      <StyledToggleButton value="CORRECT" onClick={e => e.stopPropagation()}>
        <CheckCircleIcon fontSize="small" htmlColor={value !== "CORRECT" ? theme.palette["Gray/Shades"].p56 : undefined} />
        <Typography ml={1} fontSize={14}>
          {t("正解")}
        </Typography>
      </StyledToggleButton>
      <StyledToggleButton value="WRONG" onClick={e => e.stopPropagation()}>
        <CancelIcon fontSize="small" htmlColor={value !== "WRONG" ? theme.palette["Gray/Shades"].p56 : undefined} />
        <Typography ml={1} fontSize={14}>
          {t("不正解")}
        </Typography>
      </StyledToggleButton>
    </StyledToggleButtonGroup>
  );
};

CorrectToggleButtons.displayName = "CorrectToggleButtons";

export default CorrectToggleButtons;
