import { boolDisplay, parseTableRecords } from "@hireroo/challenge/definition";
import { useTranslation } from "@hireroo/i18n";
import { AlgorithmSignatureForm } from "@hireroo/validator";
import { ErrorMessage } from "@hookform/error-message";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import AlgorithmTestCaseTableSummaryPreview, {
  AlgorithmTestCaseTableSummaryPreviewProps,
} from "../../../../usecase/AlgorithmTestCaseTableSummaryPreview/AlgorithmTestCaseTableSummaryPreview";
import { useAlgorithmTestCasesFormContext } from "../../Context";
import { SummaryKind, TestCaseKind } from "../Database/types";
import { useTableSummaries } from "../Database/useTableSummaries";

const SummaryKindMap: Record<SummaryKind, "inputs" | "outputs"> = {
  INPUT: "inputs",
  OUTPUT: "outputs",
};
type SummaryRows = AlgorithmTestCaseTableSummaryPreviewProps["summaryRows"];

export type TestCaseTableSummaryProps = {
  signature: AlgorithmSignatureForm.DatabaseSignatureSchemaType;
  testCaseIndex: number;
  summaryKind: SummaryKind;
  testCaseKind: TestCaseKind;
};

const TestCaseTableSummary: React.FC<TestCaseTableSummaryProps> = props => {
  const { signature } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const { method } = useAlgorithmTestCasesFormContext();
  const fieldStateError = method.formState.errors;

  const { state, action } = useTableSummaries(signature, props.testCaseIndex, props.summaryKind, props.testCaseKind);

  const titleMap: Record<SummaryKind, string> = {
    INPUT: t("テーブルの値を設定"),
    OUTPUT: t("出力結果の値を設定"),
  };
  const columNameMap: Record<SummaryKind, string> = {
    INPUT: t("テーブル名"),
    OUTPUT: t("期待するクエリの出力結果"),
  };
  const inputSummaryRows: SummaryRows = signature.tables.map((table, index) => {
    const tableColumns = table.columns.map(column => {
      return {
        name: column.name,
        type: column.type,
      };
    });
    return {
      tableName: table.name,
      isEditable: true,
      tableInputDialog: {
        title: `${t("テストケース")}${props.testCaseIndex + 1} ${table.name}`,
        inputTable: {
          tableName: table.name,
          tableColumns: tableColumns,
          value: state.values[index] ?? "",
          onChange: action.handleValueChange(index),
        },
      },
      outputTable: {
        tableColumns: tableColumns,
        displayRecords: parseTableRecords(state.values[index] ?? "").map(record => {
          return table.columns.map((column, columnIndex) => {
            return column.type === "bool" ? boolDisplay[`${record[columnIndex]}`] : `${record[columnIndex] ?? ""}`;
          });
        }),
      },
    };
  });
  const outputColumns = signature.columns.map(column => {
    return {
      name: column.name,
      type: column.type,
    };
  });

  const outputDisplayRecords = React.useMemo(() => {
    return parseTableRecords(state.values[0] ?? "").map(record => {
      return signature.columns.map((column, columnIndex) => {
        return column.type === "bool" ? boolDisplay[`${record[columnIndex]}`] : `${record[columnIndex] ?? ""}`;
      });
    });
  }, [signature.columns, state.values]);

  const outputSummaryRows: SummaryRows = [
    {
      tableName: "output",
      isEditable: true,
      tableInputDialog: {
        title: `${t("テストケース")}${props.testCaseIndex + 1} output`,
        inputTable: {
          tableName: "output",
          tableColumns: outputColumns,
          value: state.values[0] ?? "",
          onChange: action.handleValueChange(0),
        },
      },
      outputTable: {
        tableColumns: outputColumns,
        displayRecords: outputDisplayRecords,
      },
    },
  ];

  const summaryRowsMap: Record<SummaryKind, AlgorithmTestCaseTableSummaryPreviewProps["summaryRows"]> = {
    INPUT: inputSummaryRows,
    OUTPUT: outputSummaryRows,
  };

  const testCaseSummary: AlgorithmTestCaseTableSummaryPreviewProps = {
    columnName: columNameMap[props.summaryKind],
    summaryRows: summaryRowsMap[props.summaryKind],
    hasEdit: true,
  };

  return (
    <Box>
      <Stack direction="row" alignItems="center" mb={1} spacing={1}>
        <EditIcon fontSize="small" />
        <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
          {titleMap[props.summaryKind]}
        </Typography>
      </Stack>

      <Box mb={1}>
        <ErrorMessage
          errors={fieldStateError}
          name={`${props.testCaseKind}.${props.testCaseIndex}.${SummaryKindMap[props.summaryKind]}`}
          render={({ message }) =>
            message && (
              <Typography variant="body2" color={theme.palette.error.main}>{`${`${titleMap[props.summaryKind]}:${message}`}`}</Typography>
            )
          }
        />
      </Box>

      <AlgorithmTestCaseTableSummaryPreview {...testCaseSummary} />
    </Box>
  );
};

TestCaseTableSummary.displayName = "TestCaseTableSummary";

export default TestCaseTableSummary;
