import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import TableCell from "@mui/material/TableCell";
import * as React from "react";

export type FeatureTableCellProps = { kind: "TEXT"; text: string; width?: string } | { kind: "CHECK"; width?: string };

const FeatureTableCell: React.FC<FeatureTableCellProps> = props => {
  if (props.kind === "TEXT") {
    return (
      <TableCell component="td" align="center" width={props.width}>
        {props.text}
      </TableCell>
    );
  }
  return (
    <TableCell component="td" align="center" width={props.width}>
      <CheckOutlinedIcon color="success" />
    </TableCell>
  );
};

FeatureTableCell.displayName = "FeatureTableCell";

export default FeatureTableCell;
