import { SKIP_OPTION_ID } from "@hireroo/app-definition/quiz";
import {
  composeTextOperationForQuiz,
  invertTextOperation,
  isFreeTextAction,
  isOptionAction,
  QuizSyncState,
  SyncOperation,
} from "@hireroo/app-helper/firepad";
import { applyOperation } from "@hireroo/code-editor/helpers/monaco";
import { ITextModel, Monaco } from "@hireroo/code-editor/react/CodeEditor";
import * as React from "react";

type MergedRevision = QuizSyncState | SyncOperation;

export type OptionStatus = "SELECTING" | "CONFIRM";

type QuestionId = number;
type OptionId = number;
type QuestionSelectedOptionsMap = Map<QuestionId, Set<OptionId>>;
type RevisionIndex = number;
type RevisionIndexQuestionSelectedOptionsMap = Map<RevisionIndex, OptionQuizResourcesFromFirebase>;

type OptionQuizResourcesFromFirebase = {
  optionStatus: OptionStatus;
  questionId: number;
  optionIds: number[];
};

export type QuestionOptionsResourcesFromFirebaseArgs = {
  firstQuestionId: number;
  revisions: MergedRevision[];
  /**
   * valid for entity package question ids
   */
  validQuestionIds: number[];
};
export const generateQuestionOptionsResourcesFromFirebase = (
  args: QuestionOptionsResourcesFromFirebaseArgs,
): RevisionIndexQuestionSelectedOptionsMap => {
  const revisionQuestionOptionMap: Map<number, OptionQuizResourcesFromFirebase> = new Map();

  const questionSelectedOptionsMap: QuestionSelectedOptionsMap = new Map();
  let selectedQuestionId = args.firstQuestionId;
  let status: OptionStatus = "SELECTING";

  args.revisions.forEach((revision, index) => {
    if (!revision || !isOptionAction(revision) || (revision.s === "inq" && !args.validQuestionIds.includes(Number(revision.v)))) {
      revisionQuestionOptionMap.set(index, {
        optionStatus: status,
        questionId: selectedQuestionId,
        optionIds: Array.from(questionSelectedOptionsMap.get(selectedQuestionId)?.values() || []),
      });
      return;
    }

    switch (revision.s) {
      case "selo":
        switch (revision.a) {
          case "rep": {
            const selectedOptions = questionSelectedOptionsMap.get(selectedQuestionId);
            // Clear set.
            selectedOptions?.clear();
            selectedOptions?.add(Number(revision.v));
            break;
          }
          case "set":
            // When the selected OptionId is 0 (skip), all other options are cleared.
            // and, when OptionId 0 is selected, selecting another option removes the OptionId 0.
            if (Number(revision.v) === SKIP_OPTION_ID) {
              questionSelectedOptionsMap.get(selectedQuestionId)?.clear();
            } else {
              questionSelectedOptionsMap.get(selectedQuestionId)?.delete(SKIP_OPTION_ID);
            }
            questionSelectedOptionsMap.get(selectedQuestionId)?.add(revision.v);
            break;
          case "uset":
            questionSelectedOptionsMap.get(selectedQuestionId)?.delete(Number(revision.v));
            break;
          default: {
            const selectedOptions = questionSelectedOptionsMap.get(selectedQuestionId);
            // Clear set.
            selectedOptions?.clear();
            selectedOptions?.add(Number(revision.v));
            break;
          }
        }
        status = "SELECTING";
        break;
      case "inq":
        // initialize if not exist
        if (!questionSelectedOptionsMap.get(revision.v)) {
          questionSelectedOptionsMap.set(revision.v, new Set());
        }
        selectedQuestionId = revision.v;
        // reset option when next question start.
        status = "SELECTING";
        break;
      case "outq":
        // initialize if not exist
        if (!questionSelectedOptionsMap.get(revision.v)) {
          questionSelectedOptionsMap.set(revision.v, new Set());
        }
        selectedQuestionId = revision.v;
        break;
      case "subq":
        selectedQuestionId = revision.v;
        status = "CONFIRM";
        break;
    }

    revisionQuestionOptionMap.set(index, {
      optionStatus: status,
      questionId: selectedQuestionId,
      optionIds: Array.from(questionSelectedOptionsMap.get(selectedQuestionId)?.values() || []),
    });
  });
  return revisionQuestionOptionMap;
};

type QuizResourcesFromFirebaseArgs = {
  revisions: MergedRevision[];
  firstQuestionId: number;
  revisionIndex: number;
  validQuestionIds: QuestionOptionsResourcesFromFirebaseArgs["validQuestionIds"];
};

export const useOptionQuizResourcesFromFirebase = (args: QuizResourcesFromFirebaseArgs): OptionQuizResourcesFromFirebase => {
  const revisionQuestionOptionMap = React.useMemo((): RevisionIndexQuestionSelectedOptionsMap => {
    return generateQuestionOptionsResourcesFromFirebase({
      revisions: args.revisions,
      firstQuestionId: args.firstQuestionId,
      validQuestionIds: args.validQuestionIds,
    });
  }, [args.firstQuestionId, args.revisions, args.validQuestionIds]);
  const questionSelectedOptionsMap = revisionQuestionOptionMap.get(args.revisionIndex);

  return {
    optionStatus: questionSelectedOptionsMap?.optionStatus || "SELECTING",
    questionId: questionSelectedOptionsMap?.questionId || 0,
    optionIds: questionSelectedOptionsMap?.optionIds || [],
  };
};

type ApplyEditorValueArgs = {
  model: ITextModel;
  monaco: Monaco;
  revisions: MergedRevision[];
  newIndex: number;
  sliderIndex: number;
};

export const applyEditorValue = (args: ApplyEditorValueArgs): void => {
  const { revisions, model, monaco, newIndex, sliderIndex } = args;
  if (newIndex > sliderIndex) {
    // move forward
    for (let i = 0; i < newIndex - sliderIndex; i++) {
      const forwardIndex = sliderIndex + i;
      if (forwardIndex < revisions.length) {
        const revision = revisions[forwardIndex];
        if (isFreeTextAction(revision)) {
          applyOperation(revision.o, monaco, model);
        }
      }
    }
  } else if (newIndex < sliderIndex) {
    // move backward
    for (let i = 0; i < sliderIndex - newIndex; i++) {
      const backwardIndex = sliderIndex - i;
      if (backwardIndex < revisions.length) {
        const revision = revisions[backwardIndex];
        if (isFreeTextAction(revision)) {
          const inverseOp = invertTextOperation(revision.o, composeTextOperationForQuiz(backwardIndex - 1, revisions));
          applyOperation(inverseOp, monaco, model);
        }
      }
    }
  }
};
