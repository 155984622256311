import { useTranslation } from "@hireroo/i18n";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectProps } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

import ScoreBar, { ScoreBarProps } from "../../primitive/ScoreBar/ScoreBar";
import DifficultyStars, { DifficultyStarsProps } from "../DifficultyStars/DifficultyStars";
import ScorePieChart, { ScorePieChartProps } from "../ScorePieChart/ScorePieChart";

const StyledWrapper = styled(Stack)(({ theme }) => ({
  width: "100%",
  display: "flex",
  borderRadius: "12px",
  backgroundColor: theme.palette["Background/Paper"].p16,
  border: "solid 1px",
  borderColor: theme.palette.divider,
  padding: "16px",
}));

type Option = {
  id: string;
  displayText: string;
};

type Status = "EVALUATED" | "ERROR" | "EVALUATING";

export type EntityScoreBoardProps = {
  className?: string;
  title: string;
  difficulty: DifficultyStarsProps["difficulty"];
  scoreChart?: ScorePieChartProps;
  scoreBars: ScoreBarProps[];
  submissionSelect?: Pick<SelectProps, "onChange" | "value">;
  submissionOptions: Option[];
  status: Status;
};

const EntityScoreBoard: React.FC<EntityScoreBoardProps> = props => {
  const theme = useTheme();
  const { t } = useTranslation();

  const StatusMap = {
    EVALUATING: (
      <Alert severity="info" variant="outlined">
        {t("提出された問題の採点中です。")}
      </Alert>
    ),
    ERROR: (
      <Alert severity="error" variant="outlined">
        {t("採点失敗")}
      </Alert>
    ),
    EVALUATED: (
      <Stack direction="row" width="100%">
        {props.scoreChart && (
          <Stack width="9%" direction="column" spacing={2} alignItems="center">
            <Box display="flex" alignItems="center">
              <Box mr={1} display="flex">
                <Typography component="div" variant="caption">
                  {t("スコア")}
                </Typography>
              </Box>
              <Box alignItems="center" justifyItems="center" display="flex">
                <Tooltip title={t("複数の指標から算出される総合スコア")}>
                  <InfoOutlinedIcon sx={{ fontSize: "0.8rem", color: theme.palette.secondary.light }} />
                </Tooltip>
              </Box>
            </Box>
            <ScorePieChart {...props.scoreChart} />
          </Stack>
        )}
        {props.scoreBars.length > 0 && (
          <>
            <Divider orientation="vertical" variant="fullWidth" flexItem />
            <Stack direction="column" width="90%" spacing={1}>
              {props.scoreBars.map(scoreBar => (
                <ScoreBar key={`${scoreBar.title}-key`} {...scoreBar} />
              ))}
            </Stack>
          </>
        )}
      </Stack>
    ),
  } satisfies Record<Status, React.ReactNode>;
  return (
    <StyledWrapper direction="column" spacing={2} className={props.className}>
      <Stack direction="row" width="100%" display="flex" justifyContent="space-between">
        <Stack
          display="flex"
          direction="row"
          height="30px"
          divider={<Divider orientation="vertical" variant="middle" />}
          spacing={2}
          alignItems="center"
        >
          <Box>
            <Typography variant="caption">{props.title}</Typography>
          </Box>
          <Box>
            <DifficultyStars difficulty={props.difficulty} />
          </Box>
        </Stack>
        <Box>
          {props.submissionSelect && (
            <FormControl size="small">
              <InputLabel id="submission-select-label" color="secondary">
                {t("提出物")}
              </InputLabel>
              <Select
                {...props.submissionSelect}
                color="secondary"
                sx={{ minWidth: "180px" }}
                native
                labelId="submission-select-label"
                label={t("提出物")}
              >
                {props.submissionOptions.map(option => (
                  <option key={option.id} value={option.id}>
                    {option.displayText}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>
      </Stack>
      {StatusMap[props.status]}
    </StyledWrapper>
  );
};

EntityScoreBoard.displayName = "EntityScoreBoard";

export default EntityScoreBoard;
