import { subscribeKey } from "valtio/utils";

import { clearSubmittedEntity } from "./Action";
import { state } from "./State";
import * as Types from "./types";

export type SubscribeOnSubmitEntityCallback = (entity: Types.QuizEntity) => void;

export const subscribeOnSubmitEntity = (callback: SubscribeOnSubmitEntityCallback) => {
  return subscribeKey(state, "submittedEntityOfLastQuestion", submittedEntity => {
    if (submittedEntity) {
      callback(submittedEntity);
      clearSubmittedEntity();
    }
  });
};

export type SubscribeOnSubmitStatusCallback = (status: Types.QuizStatus) => void;
export const subscribeOnSubmitStatus = (callback: SubscribeOnSubmitStatusCallback) => {
  return subscribeKey(state, "submitStatus", status => {
    callback(status);
  });
};
