import * as monaco from "monaco-editor";
import * as React from "react";

import * as CursorWidgetV2 from "../CursorWidgetV2";

type IStandaloneCodeEditor = monaco.editor.IStandaloneCodeEditor;

export const useCursorWidgetManager = () => {
  const cursorWidgetControllers = React.useRef<Map<IStandaloneCodeEditor, CursorWidgetV2.CursorWidgetController>>(new Map());
  const dispose = React.useCallback(() => {
    for (const controller of cursorWidgetControllers.current.values()) {
      controller.dispose();
    }
    cursorWidgetControllers.current.clear();
  }, []);

  React.useEffect(() => {
    return () => {
      dispose();
    };
  }, [dispose]);

  const updateCursor = React.useCallback((params: CursorWidgetV2.Types.UpdateCursorOptions) => {
    for (const controller of cursorWidgetControllers.current.values()) {
      controller.updateCursor(params);
    }
  }, []);

  const initCursorWidgetController = React.useCallback((editor: IStandaloneCodeEditor) => {
    const controller = new CursorWidgetV2.CursorWidgetController(editor);
    cursorWidgetControllers.current.set(editor, controller);
    editor.onDidDispose(() => {
      controller.dispose();
      cursorWidgetControllers.current.delete(editor);
    });
  }, []);

  return React.useMemo(() => {
    return {
      updateCursor,
      initCursorWidgetController,
    };
  }, [updateCursor, initCursorWidgetController]);
};
