import { INITIAL_VERSION } from "@hireroo/app-definition/question";
import { App } from "@hireroo/app-store/essential/employee";
import { QuestionQuizPackageResourceEditor } from "@hireroo/app-store/view-domain/QuestionQuizPackageResourceEditor";
import { useCurrentLanguage, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import { type Pages, Widget } from "@hireroo/presentation/legacy";
import type { QuizPackageForm } from "@hireroo/validator";
import * as React from "react";
import type { SubmitHandler } from "react-hook-form";

import EmployeeNavigationContainer from "../../../widget/e/EmployeeNavigation/Container";
import NotificationBannerContainer from "../../../widget/e/NotificationBanner/Container";
import QuizPackageDetailContainer, { QuizPackageDetailContainerProps } from "../../../widget/e/QuizPackageDetail/Container";
import FooterContainer from "../../../widget/shared/Footer/Container";
import SnackbarContainer from "../../../widget/shared/Snackbar/Container";
import { useGenerateSelectQuestionsProps } from "./useGenerateSelectQuestionsProps";

export type GenerateQuizResourceEditorPropsArgs = {
  companyId: number;
  /** Step 1 Submit Button */
  saveSelectQuestionsButton: Pick<Pages.QuizResourceEditorProps["saveSelectQuestionsButton"], "onClick">;
  /** Step 2 Submit Handler */
  onSubmitQuizPackageForm: SubmitHandler<QuizPackageForm.QuizPackageFormSchema>;
};

export const useGenerateQuizResourceEditorProps = (args: GenerateQuizResourceEditorPropsArgs): Pages.QuizResourceEditorProps => {
  const { t } = useTranslation();
  const language = useCurrentLanguage();
  const activeStep = QuestionQuizPackageResourceEditor.useActiveStep();
  const questionSequence = QuestionQuizPackageResourceEditor.useOrderOfQuestionIds();
  const selectQuestions = useGenerateSelectQuestionsProps({
    companyId: args.companyId,
  });
  const quizPackageDetailContext = Widget.useQuizPackageDetailContext();
  const maxSelectableQuizSize = QuestionQuizPackageResourceEditor.useMaxQuestions();
  const selectedPrivateQuestions = QuestionQuizPackageResourceEditor.useSelectedPrivateQuestions();
  const showWarningDialog = QuestionQuizPackageResourceEditor.useShowWarningDialog();
  const [openVersionDialog, setVersionDialog] = React.useState(false);
  const quizPackageDetailContainerProps: QuizPackageDetailContainerProps = {
    openVersionDialog,
    onCloseVersionDialog: () => {
      setVersionDialog(false);
    },
    onSubmitQuizPackageForm: fields => {
      args.onSubmitQuizPackageForm(fields);
      setVersionDialog(false);
    },
  };
  const oldVersion = quizPackageDetailContext.method.getValues("oldVersion");
  return {
    layout: {
      loading: App.useStatus() === "INITIALIZING",
      Header: <EmployeeNavigationContainer />,
      Banner: <NotificationBannerContainer />,
      Footer: <FooterContainer />,
      Bottom: <SnackbarContainer />,
    },
    activeStep: activeStep,
    QuizPackageDetail: <QuizPackageDetailContainer {...quizPackageDetailContainerProps} />,
    selectQuestions: selectQuestions,
    saveSelectQuestionsButton: {
      disabled: questionSequence.length === 0 || questionSequence.length > maxSelectableQuizSize,
      ...args.saveSelectQuestionsButton,
    },
    saveSummaryButton: {
      onClick: () => {
        if (INITIAL_VERSION === oldVersion) {
          quizPackageDetailContext.prepareSubmit();
          quizPackageDetailContext.method.handleSubmit(args.onSubmitQuizPackageForm)();
        } else {
          setVersionDialog(true);
        }
      },
    },
    stepContentPreviousButton: {
      onClick: () => {
        QuestionQuizPackageResourceEditor.updateActiveStep(QuestionQuizPackageResourceEditor.STEP.SELECT_QUESTION);
      },
    },
    includePrivatePackageWarningDialog: {
      open: showWarningDialog,
      unpublishedQuestions: selectedPrivateQuestions.map(q => {
        return {
          title: resolveLanguage(q, language, "title"),
        };
      }),
      /**
       * When package version is v1.0.0 and use this component, it has not been published yet. So, users can change a public package to private.
       */
      additionalMessage:
        INITIAL_VERSION === oldVersion
          ? t("問題を「非公開」に設定するか、選択されている非公開の設問を全て「除く」か「公開」に変更してください。")
          : t("選択されている非公開の設問を全て「除く」か「公開」に変更してください。"),
      closeButton: {
        onClick: () => {
          QuestionQuizPackageResourceEditor.closeWarningDialog();
        },
      },
    },
  };
};
