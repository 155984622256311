import { languageMapForDisplay, useTranslation } from "@hireroo/i18n";
import { AlgorithmTestCaseForm } from "@hireroo/validator";
import * as React from "react";
import { FormProvider, SubmitHandler, useFieldArray, useForm } from "react-hook-form";

import BaseDialog, { BaseDialogProps } from "../../../../../../../primitive/BaseDialog/BaseDialog";
import EditLanguageTab, { EditLanguageTabProps } from "../../../../../../../usecase/EditLanguageTab/EditLanguageTab";
import TestCaseInputContent from "./parts/TestCaseInputContent/TestCaseInputContent";

type Kind = "CREATE" | "EDIT";

export type CorrectnessTestCaseDialogProps = {
  dialog: BaseDialogProps;
  languageTab: Pick<EditLanguageTabProps, "menu" | "onTabsChange">;
  defaultValues: AlgorithmTestCaseForm.CorrectnessTestCaseFormSchemaV2;
  onSubmit: SubmitHandler<AlgorithmTestCaseForm.CorrectnessTestCaseFormSchemaV2>;
  kind: Kind;
};

const CorrectnessTestCaseDialog: React.FC<CorrectnessTestCaseDialogProps> = props => {
  const { t } = useTranslation();
  const method = useForm<AlgorithmTestCaseForm.CorrectnessTestCaseFormSchemaV2>({
    defaultValues: props.defaultValues,
  });
  const titleMap: Record<Kind, string> = {
    CREATE: t("テストケースの追加"),
    EDIT: t("テストケースの編集"),
  };
  const buttonMap: Record<Kind, string> = {
    CREATE: t("追加"),
    EDIT: t("保存"),
  };
  const dialog: BaseDialogProps = {
    ...props.dialog,
    title: titleMap[props.kind],
    disableEnter: true,
    optionalDialog: {
      maxWidth: "md",
      fullWidth: true,
    },
    yesButton: {
      ...props.dialog.yesButton,
      children: buttonMap[props.kind],
      onClick: () => {
        props.dialog.yesButton?.onClick?.();
        method.handleSubmit(props.onSubmit);
      },
    },
    noButton: {
      ...props.dialog.noButton,
      children: t("キャンセル"),
    },
  };
  const selectedLanguagesFields = useFieldArray({
    control: method.control,
    name: "selectedLanguages",
  });
  const selectedLanguages = method.getValues("selectedLanguages");

  const editLanguageTab: EditLanguageTabProps = {
    menu: props.languageTab.menu,
    onTabsChange: props.languageTab.onTabsChange,
    items: selectedLanguages.map((lang, langIndex) => {
      return {
        id: lang.value,
        name: languageMapForDisplay[lang.value],
        Content: <TestCaseInputContent key={lang.value} language={lang.value} />,
        tab: {
          closeButton: {
            onClick: () => {
              selectedLanguagesFields.remove(langIndex);
            },
          },
        },
      };
    }),
  };

  return (
    <BaseDialog {...dialog}>
      <FormProvider {...method}>
        <EditLanguageTab {...editLanguageTab} />
      </FormProvider>
    </BaseDialog>
  );
};

CorrectnessTestCaseDialog.displayName = "CorrectnessTestCaseDialog";

export default CorrectnessTestCaseDialog;
