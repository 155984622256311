import { generatePath } from "@hireroo/router/api";

export type Kind = "EMPLOYEE_REVIEW_INTERVIEW" | "EMPLOYEE_REVIEW_EXAM" | "TALENT_REVIEW_EXAM";

export type GenerateWorkspaceUrlArgs = {
  kind: Kind;
  testId: string;
  projectId: number;
  submissionId: number;
};

export const getWorkspaceUrl = (args: GenerateWorkspaceUrlArgs): string => {
  const queryParams: Record<string, string | number> = {
    id: window.btoa(`project-${args.projectId}`),
    submission_id: args.submissionId,
  };
  switch (args.kind) {
    case "EMPLOYEE_REVIEW_EXAM":
      return generatePath("/e/exams/:id/review", {
        pathParams: {
          id: args.testId,
        },
        queryParams: queryParams,
      });
    case "EMPLOYEE_REVIEW_INTERVIEW":
      return generatePath("/e/screenings/tests/:id/review", {
        pathParams: {
          id: args.testId,
        },
        queryParams: queryParams,
      });
    case "TALENT_REVIEW_EXAM":
      return generatePath("/t/exams/:id/review", {
        pathParams: {
          id: args.testId,
        },
        queryParams: queryParams,
      });
    default:
      return "";
  }
};
