import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import * as React from "react";

export type SkeletonBoxProps = {
  count: number;
};

const SkeletonBox: React.FC<SkeletonBoxProps> = props => {
  const skeletons: JSX.Element[] = [];
  for (let i = 0; i < props.count; i++) {
    skeletons.push(
      <Box key={`skeleton-${i}`}>
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
      </Box>,
    );
  }

  return skeletons;
};

SkeletonBox.displayName = "SkeletonBox";

export default SkeletonBox;
