import React from "react";
import Video, { ConnectOptions, LocalTrack, Room } from "twilio-video";

/**
 * This hook returns a Room instance if the user is connected to a room, or null if not.
 */
export const useRoom = (localTracks: LocalTrack[], options?: ConnectOptions) => {
  const [room, setRoom] = React.useState<Room | null>(null);
  const [isConnecting, setIsConnecting] = React.useState(false);
  const optionsRef = React.useRef(options);

  const connect = React.useCallback(
    (token: string) => {
      setIsConnecting(true);
      return Video.connect(token, { ...optionsRef.current, tracks: localTracks })
        .then(newRoom => {
          setRoom(newRoom);
          const handleDisconnect = () => {
            // Detach the room attached audioTracks and videoTracks
            newRoom.localParticipant.audioTracks.forEach(publication => {
              const attachedElements = publication.track.detach();
              attachedElements.forEach(element => element.remove());
            });
            newRoom.localParticipant.videoTracks.forEach(publication => {
              const attachedElements = publication.track.detach();
              attachedElements.forEach(element => element.remove());
            });
            newRoom.disconnect();
          };

          // This app can add up to 8 'participantDisconnected' listeners to the room object, which can trigger
          // a warning from the EventEmitter object. Here we increase the max listeners to suppress the warning.
          newRoom.setMaxListeners(8);

          newRoom.once("disconnected", () => {
            // Reset the room only after all other `disconnected` listeners have been called.
            setTimeout(() => setRoom(null));
            window.removeEventListener("pagehide", handleDisconnect);
          });
          // Add a listener to disconnect from the room when a user closes their browser
          // If Browser use back forward cache, pagehide event is fired instead of beforeunload
          window.addEventListener("pagehide", handleDisconnect);
        })
        .finally(() => {
          setIsConnecting(false);
        });
    },
    [localTracks],
  );

  React.useEffect(() => {
    // This allows the connect function to always access the most recent version of the options object. This allows us to
    // reliably use the connect function at any time.
    optionsRef.current = options;
  }, [options]);

  return { room, isConnecting, connect };
};
