import { useTranslation } from "@hireroo/i18n";
import { Delete, EditOutlined, ExpandMore } from "@mui/icons-material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import IconButton, { IconButtonProps } from "../../../../../primitive/Button/IconButton";

export type AccordionWithLoadingProps = {
  id: string;
  onOpen: () => void;
  deleteIcon?: Pick<IconButtonProps, "onClick">;
  editButton: Pick<IconButtonProps, "onClick">;
  title: string;
  Content: React.ReactNode;
  loading?: boolean;
};

const AccordionWithLoading: React.FC<AccordionWithLoadingProps> = props => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const handleChange = (_: React.SyntheticEvent, expanded: boolean) => {
    setExpanded(prev => !prev);
    if (!expanded) {
      props.onOpen();
    }
  };
  const deleteButton: IconButtonProps | undefined = props.deleteIcon
    ? {
        ...props.deleteIcon,
        title: t("テストケースを削除する"),
        "aria-label": "reset-type",
        color: "default",
        size: "small",
        children: <Delete />,
      }
    : undefined;
  const editButton: IconButtonProps = {
    ...props.editButton,
    onClick: event => {
      event.stopPropagation();
      event.preventDefault();
      props.editButton.onClick?.(event);
    },
    title: t("テストケースを編集する"),
    "aria-label": "edit-type",
    color: "default",
    size: "small",
    children: <EditOutlined fontSize="small" />,
  };

  return (
    <Accordion expanded={expanded} onChange={handleChange} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel-content">
        <Stack direction="row" justifyContent="space-between" width="100%" pr={1.5}>
          <Box display="flex" gap={2} alignItems="center">
            {deleteButton && <IconButton {...deleteButton} />}
            <Typography>{props.title}</Typography>
          </Box>
          <IconButton {...editButton} />
        </Stack>
      </AccordionSummary>
      <AccordionDetails>{props.loading ? <CircularProgress /> : props.Content}</AccordionDetails>
    </Accordion>
  );
};

AccordionWithLoading.displayName = "AccordionWithLoading";

export default AccordionWithLoading;
