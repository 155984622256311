import * as Def from "@hireroo/app-definition/errors";
import { Credential } from "@hireroo/app-store/essential/shared";
import { signOut } from "@hireroo/firebase";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation/legacy";
import { generateHelpCenterUrl, generatePath } from "@hireroo/router/api";
import routes from "@hireroo/router/config";
import { useNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/browser";
import type { GraphQLError } from "graphql";
import * as React from "react";

export type GenerateErrorPanelPropsArgsByServiceErrorCode = {
  graphqlErrors: GraphQLError[];
  isSignInUser?: boolean;
};

export type CustomErrorProps = {
  graphqlErrors?: undefined;
  title: string;
  message: string;
  additionalMessage?: string;
};

export type GenerateErrorPanelPropsArgs = GenerateErrorPanelPropsArgsByServiceErrorCode | CustomErrorProps;
type LinkProps = Widget.ErrorPanelProps["links"][0];

export const useGenerateProps = (args: GenerateErrorPanelPropsArgs): Widget.ErrorPanelProps => {
  const { t } = useTranslation();
  const languageCode = useLanguageCode();
  const navigate = useNavigate();
  const isSignInUser = Credential.useSignedIn();
  const detailErrorMessageMap = Def.useDetailErrorCodeMap("FOR_EMPLOYEE");
  const serverErrorMessageMap = Def.useServerErrorMessageMap();
  const userType = Credential.useUserType();

  const links: (LinkProps | false)[] = [
    {
      href: generateHelpCenterUrl(languageCode, "ROOT"),
      children: t("ヘルプセンター"),
      target: "_blank",
    },
    userType === Graphql.UserType.Employee && {
      href: generatePath("/e/home"),
      children: t("ホームに戻る"),
      onClick: () => {
        navigate(routes["/e/home"]);
      },
    },
    userType === Graphql.UserType.Talent && {
      href: generatePath("/t/assessments"),
      children: t("ホームに戻る"),
      onClick: () => {
        navigate(routes["/t/assessments"]);
      },
    },
    isSignInUser && {
      children: t("サインアウトする"),
      onClick: async () => {
        await signOut();
      },
    },
  ];
  const linksProps = links.flatMap(v => (v ? [v] : []));

  React.useEffect(() => {
    if (args.graphqlErrors) {
      const errorCodes = args.graphqlErrors
        .map(graphqlError => `${graphqlError.extensions.code}/${graphqlError.extensions.detailCode}`)
        .join(",");
      Sentry.captureMessage(`GraphQL Errors: ${errorCodes}`);
    }
  }, [args.graphqlErrors]);

  if (args.graphqlErrors) {
    const errors = args.graphqlErrors.map(graphQLError => {
      return {
        serverErrorCode: graphQLError.extensions.code,
        detailErrorCode: graphQLError.extensions.detailCode,
      };
    });
    const errorCode: Def.ErrorObject = errors[0] || {
      serverErrorCode: "UNKNOWN",
    };
    // TODO we should determine showing error priority.
    if (errorCode.detailErrorCode) {
      return {
        ...detailErrorMessageMap[errorCode.detailErrorCode],
        links: linksProps,
      };
    }
    return {
      ...serverErrorMessageMap[errorCode.serverErrorCode],
      links: linksProps,
    };
  }

  return {
    name: args.title,
    message: args.message,
    additionalMessage: args.additionalMessage,
    links: linksProps,
  };
};
