import { useTranslation } from "@hireroo/i18n";
import * as z from "zod";

export const usePaymentPeriod = () => {
  return z.union([z.literal("YEARLY"), z.literal("ONE_MONTHLY")]);
};

export type PaymentPeriod = z.infer<ReturnType<typeof usePaymentPeriod>>;

export const usePlanType = () => {
  return z.union([z.literal("ENTERPRISE"), z.literal("PROFESSIONAL"), z.literal("STANDARD"), z.literal("STARTER")]);
};

export type PlanType = z.infer<ReturnType<typeof usePlanType>>;

export const useSelectionItem = () => {
  return z.object({
    itemId: z.string(),
    amount: z.number(),
  });
};

export type SelectionItem = z.infer<ReturnType<typeof useSelectionItem>>;

export const useSelectionItems = () => {
  const item = useSelectionItem();
  return item.array();
};

export type SelectionItems = z.infer<ReturnType<typeof useSelectionItems>>;

export const useCoupon = () => {
  return z.string().nullable();
};

export type Coupon = z.infer<ReturnType<typeof useCoupon>>;

export const useAutomaticContractRenewal = () => {
  return z.boolean();
};

export type AutomaticContractRenewal = z.infer<ReturnType<typeof useAutomaticContractRenewal>>;

export const usePaymentMethod = () => {
  return z.union([z.literal("BANK"), z.literal("CREDIT_CARD")]);
};

export type PaymentMethod = z.infer<ReturnType<typeof usePaymentMethod>>;

export const useBillingInformation = () => {
  const { t } = useTranslation();
  return z.object({
    companyName: z.string().min(1, { message: t("必須項目です") }),
    mailAddress: z
      .string()
      .min(1, { message: t("メールアドレスは必須項目です。") })
      .regex(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, { message: t("メールアドレスが有効な形式ではありません。") }),
    postalCode: z.string().min(1, { message: t("必須項目です") }),
    state: z.string().min(1, { message: t("必須項目です") }),
    city: z.string().min(1, { message: t("必須項目です") }),
    line1: z.string().min(1, { message: t("必須項目です") }),
    language: z.union([z.literal("JA"), z.literal("EN")]),
  });
};

export type BillingInformationField = z.infer<ReturnType<typeof useBillingInformation>>;

export const usePaymentContractCreateForm = () => {
  const plan = usePlanType();
  const paymentPeriod = usePaymentPeriod();
  const coupon = useCoupon();
  const paymentMethod = usePaymentMethod();
  const selectionItem = useSelectionItem();
  const billingInformation = useBillingInformation();
  return z.object({
    plan,
    selectionItems: selectionItem.array(),
    paymentMethod,
    paymentPeriod,
    coupons: coupon.array(),
    billingInformation,
  });
};

export type PaymentContractCreateForm = z.infer<ReturnType<typeof usePaymentContractCreateForm>>;

/**
 * Does not include changes in Payment Method
 */
export const usePaymentContractUpdateForm = () => {
  const plan = usePlanType();
  const paymentPeriod = usePaymentPeriod();

  return z.object({
    plan,
    paymentPeriod,
  });
};

export type PaymentContractUpdateForm = z.infer<ReturnType<typeof usePaymentContractUpdateForm>>;

export const usePaymentMethodUpdateForm = () => {
  const paymentMethod = usePaymentMethod();
  const billingInformation = useBillingInformation();
  return z.object({
    paymentMethod,
    billingInformation,
  });
};

export type PaymentMethodUpdateForm = z.infer<ReturnType<typeof usePaymentMethodUpdateForm>>;

export const useBuySelectionForm = () => {
  const selectionItems = useSelectionItems();
  return z.object({
    selectionItems,
  });
};

export type BuySelectionForm = z.infer<ReturnType<typeof useBuySelectionForm>>;

export const useAutoUpdateContractConfirmSchema = () => {
  const automaticContractRenewal = useAutomaticContractRenewal();
  return z.object({
    automaticContractRenewal: automaticContractRenewal,
  });
};

export type AutoUpdateContractConfirmSchema = z.infer<ReturnType<typeof useAutoUpdateContractConfirmSchema>>;
