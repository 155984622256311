import { Widget } from "@hireroo/presentation/legacy";
import { withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateNotificationListPropsArgs, useGenerateProps } from "./useGenerateProps";

export type NotificationListContainerProps = GenerateNotificationListPropsArgs;

const NotificationListContainer: React.FC<NotificationListContainerProps> = props => {
  const notificationListProps = useGenerateProps(props);
  return <Widget.NotificationList {...notificationListProps} />;
};

NotificationListContainer.displayName = "NotificationListContainer";

export default withErrorBoundary(React.memo(NotificationListContainer), {});
