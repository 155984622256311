import { useTranslation } from "@hireroo/i18n";
import { Settings } from "@mui/icons-material";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

import SelectControl, { SelectControlProps } from "../../../../primitive/InputControl/SelectControl";
import SwitchControl, { SwitchControlProps } from "../../../../primitive/InputControl/SwitchControl";
import { useFreepadQuestionDetailFormContext } from "../../Context";

export type GeneralSettingFieldsProps = {
  canCreatePublic: boolean;
  isPublicSwitch: Omit<SwitchControlProps, "control">;
  variantSelect: Pick<SelectControlProps, "disabled">;
};

const GeneralSettingFields: React.FC<GeneralSettingFieldsProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { methods } = useFreepadQuestionDetailFormContext();
  const isPublicSwitch: SwitchControlProps = {
    ...props.isPublicSwitch,
    control: methods.control,
  };
  const difficultySelect: SelectControlProps = {
    control: methods.control,
    label: t("難易度") + "*",
    menuItems: [
      {
        label: t("易しい"),
        value: "EASY",
      },
      {
        label: t("ふつう"),
        value: "MEDIUM",
      },
      {
        label: t("難しい"),
        value: "DIFFICULT",
      },
    ],
  };
  const variantSelect: SelectControlProps = {
    ...props.variantSelect,
    label: t("問題形式"),
    control: methods.control,
    helperText: t("一度問題を保存すると問題形式を変更することはできません。"),
    menuItems: [
      {
        label: t("コーディング"),
        value: "ALGORITHM",
      },
      {
        label: t("システムデザイン"),
        value: "SYSTEM_DESIGN",
      },
    ],
  };

  return (
    <Box display="flex" justifyContent="space-between" gap={4}>
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" alignItems="center">
          <Box mr={1}>
            <Settings fontSize="small" sx={{ color: theme.palette.common.white }} />
          </Box>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            {t("問題の一般設定をする")}
          </Typography>
        </Box>

        {props.canCreatePublic && (
          <Box width="25%" mt={2}>
            <FormGroup>
              <Tooltip title={`${t("一度公開済みになった問題は変更することはできません。")}`} placement="top-end" enterDelay={1000}>
                <Box display="flex" alignItems="center">
                  <Typography sx={{ width: "50px" }}>{t("非公開")}</Typography>
                  <Box sx={{ width: "70px" }}>
                    <SwitchControl name="isPublic" {...isPublicSwitch} />
                  </Box>
                  <Typography sx={{ width: "80px" }}>{t("公開")}</Typography>
                </Box>
              </Tooltip>
              <FormHelperText>{t("公開の場合、他の企業も問題を利用できます。")}</FormHelperText>
            </FormGroup>
          </Box>
        )}

        <Box display="flex" gap={2} width="100%" mt={4}>
          <SelectControl name="variant" {...variantSelect} />
          <SelectControl name="difficulty" {...difficultySelect} />
        </Box>
      </Box>
    </Box>
  );
};

GeneralSettingFields.displayName = "GeneralSettingFields";

export default GeneralSettingFields;
