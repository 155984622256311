import { useTranslation } from "@hireroo/i18n";
import { MemberInviteForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import LanguageSelector from "../../../../modules/LanguageSelector/LanguageSelector";
import MultiEmailAddressField, { MultiEmailAddressFieldProps } from "../../../../modules/MultiEmailAddressField/MultiEmailAddressField";
import ResourceField from "../../../../modules/ResourceField/ResourceField";
import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";
import SelectControl, { SelectControlProps } from "../../../../primitive/InputControl/SelectControl/SelectControl";
import Link, { LinkProps } from "../../../../primitive/Link/Link";
import ShareLink, { ShareLinkProps } from "../ShareLink/ShareLink";

export type InviteEmployeeProps = {
  dialog: Pick<DialogWithCloseIconProps, "open" | "onClose" | "yesButton" | "noButton">;
  onCreateInviteLink?: SubmitHandler<MemberInviteForm.MemberInviteSchemaByLink>;
  onSubmit?: SubmitHandler<MemberInviteForm.MemberInviteSchemaByEmail>;
  helpLink: Pick<LinkProps, "onClick" | "href">;
  shareLink: Omit<ShareLinkProps, "refreshButton">;
  roleField: SelectControlProps;
  onReCreateInviteLink?: SubmitHandler<MemberInviteForm.MemberInviteSchemaByLink>;
};

const InviteEmployee: React.FC<InviteEmployeeProps> = props => {
  const { t } = useTranslation();
  const { onSubmit, onCreateInviteLink, onReCreateInviteLink } = props;
  const validateSchema = MemberInviteForm.useMemberInviteSchema();
  const methods = useForm<MemberInviteForm.MemberInviteSchema>({
    mode: "onSubmit",
    resolver: zodResolver(validateSchema),
    defaultValues: {
      kind: "EMAIL",
      language: "ja",
      emails: [],
      role: props.roleField.value,
    },
  });
  const multiMailAddressFieldProps: MultiEmailAddressFieldProps = {
    color: "secondary",
    placeholder: "alice@example.com,bob@example.com",
  };
  const shareLink: ShareLinkProps = {
    ...props.shareLink,
    createLinkbutton: {
      ...props.shareLink.createLinkbutton,
      onClick: React.useCallback(() => {
        methods.setValue("kind", "LINK");
        methods.handleSubmit(fields => {
          if (fields.kind !== "LINK") {
            return;
          }
          onCreateInviteLink?.(fields);
        })();
      }, [methods, onCreateInviteLink]),
    },
    refreshButton: {
      onClick: React.useCallback(() => {
        methods.setValue("kind", "LINK");
        methods.handleSubmit(fields => {
          if (fields.kind !== "LINK") {
            return;
          }
          onReCreateInviteLink?.(fields);
        })();
      }, [methods, onReCreateInviteLink]),
    },
  };
  const dialog: DialogWithCloseIconProps = {
    ...props.dialog,
    title: t("メンバーを招待する"),
    yesButton: {
      children: t("送信"),
      color: "secondary",
      onClick: React.useCallback(() => {
        if (onSubmit) {
          methods.setValue("kind", "EMAIL");
          methods.handleSubmit(fields => {
            if (fields.kind !== "EMAIL") {
              return;
            }
            onSubmit?.(fields);
          })();
          methods.reset();
        }
      }, [onSubmit, methods]),
    },
    onClose: () => {
      props.dialog.onClose?.();
      methods.clearErrors();
      // it needs to be after the dialog is closed.
      setTimeout(() => methods.reset(), 1000);
    },
    noButton: {
      ...props.dialog.noButton,
      children: t("キャンセル"),
      variant: "outlined",
      color: "outline-only",
      onClick: event => {
        props.dialog.noButton?.onClick?.(event);
        methods.clearErrors();
        // it needs to be after the dialog is closed.
        setTimeout(() => methods.reset(), 1000);
      },
    },
    disableEnter: true,
    optionalContent: {
      sx: {
        px: 3,
        py: 2,
      },
    },
  };

  return (
    <FormProvider {...methods}>
      <DialogWithCloseIcon {...dialog}>
        <Stack mb={2}>
          <Typography color="textSecondary" mb={1} fontSize={14}>
            {t("招待リンクを共有することでメンバーを追加できます。リンクの期限は二週間です。")}
          </Typography>
          <Box width="fit-content">
            <Link {...props.helpLink} target="_blank" color="secondary" children={<Typography fontSize={12}>{t("ヘルプ")} </Typography>} />
          </Box>
        </Stack>
        <Stack spacing={2}>
          <ResourceField label={t("メールアドレスで招待")} kind="REQUIRED">
            <MultiEmailAddressField name="emails" {...multiMailAddressFieldProps} />
          </ResourceField>
          <ResourceField label={t("招待メールの言語")} kind="REQUIRED">
            <LanguageSelector name="language" color="secondary" />
          </ResourceField>
          <ResourceField label={t("付与する権限")} kind="REQUIRED">
            <SelectControl name="role" color="secondary" {...props.roleField} />
          </ResourceField>
          <ResourceField label={t("共有リンクで招待")} kind="OPTIONAL">
            <ShareLink {...shareLink} />
          </ResourceField>
        </Stack>
      </DialogWithCloseIcon>
    </FormProvider>
  );
};

InviteEmployee.displayName = "InviteEmployee";

export default InviteEmployee;
