import { App } from "@hireroo/app-store/essential/talent";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import ErrorPanelContainer from "../../../../../../widget/v2/t/ErrorPanel/Container";
import EntryAssessmentContainer, { EntryAssessmentContainerProps } from "./Container";

export type EntryAssessmentFetchContainerProps = {
  examId: string;
};

const EntryAssessmentFetchContainer: React.FC<EntryAssessmentFetchContainerProps> = props => {
  const appStatus = App.useStatus();
  const [exam, setExam] = React.useState<Graphql.ExamForEntryAssessmentsIdFragment | null>(null);
  const [result] = Graphql.useGetExamForEntryAssessmentsIdQuery({
    variables: {
      examId: props.examId,
    },
    pause: appStatus !== "INITIALIZED",
  });
  React.useEffect(() => {
    if (result.data) {
      setExam(result.data.exam);
    }
  }, [result.data]);

  if (result.error) {
    return <ErrorPanelContainer graphqlErrors={result.error.graphQLErrors} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !exam) {
    return <Widget.Loading kind="TOP" color="secondary" />;
  }

  const containerProps: EntryAssessmentContainerProps = {
    exam: exam,
  };
  return (
    <ErrorBoundary>
      <EntryAssessmentContainer {...containerProps} />
    </ErrorBoundary>
  );
};

EntryAssessmentFetchContainer.displayName = "EntryAssessmentFetchContainer";

export default withErrorBoundary(EntryAssessmentFetchContainer, {});
