import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateChallengeCodingEditorWebSearchRightSidePanelPropsArgs, useGenerateProps } from "./useGenerateProps";

export type ChallengeCodingEditorWebSearchRightSidePanelContainerProps = GenerateChallengeCodingEditorWebSearchRightSidePanelPropsArgs;

const ChallengeCodingEditorWebSearchRightSidePanelContainer: React.FC<ChallengeCodingEditorWebSearchRightSidePanelContainerProps> = props => {
  const ChallengeCodingEditorWebSearchRightSidePanelProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ChallengeCodingEditorWebSearchRightSidePanel {...ChallengeCodingEditorWebSearchRightSidePanelProps} />
    </ErrorBoundary>
  );
};

ChallengeCodingEditorWebSearchRightSidePanelContainer.displayName = "ChallengeCodingEditorWebSearchRightSidePanel";

export default withErrorBoundary(ChallengeCodingEditorWebSearchRightSidePanelContainer, {});
