import { AssessmentsIdStore } from "@hireroo/app-store/page/t/assessments_id";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import AssessmentsIdFetchContainer from "./FetchContainer";
import * as Subscriber from "./Subscriber";

const ExamsIdInitialContainer: React.FC = () => {
  React.useEffect(() => {
    const stopSubscribeOnChangeExam = Subscriber.startSubscribeOnChangeExam();
    const stopSubscribeTimeoutStatus = Subscriber.startSubscribeTimeoutStatus();
    return () => {
      stopSubscribeOnChangeExam();
      stopSubscribeTimeoutStatus();
      AssessmentsIdStore.clear();
    };
  }, []);
  return (
    <ErrorBoundary>
      <AssessmentsIdFetchContainer />
    </ErrorBoundary>
  );
};

ExamsIdInitialContainer.displayName = "ExamsIdInitialContainer";

export default withErrorBoundary(ExamsIdInitialContainer, {});
