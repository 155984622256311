import { Widget } from "@hireroo/presentation/legacy";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type SnackbarContainerProps = {};

const SnackbarContainer: React.FC<SnackbarContainerProps> = () => {
  const props = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.Snackbar {...props} />
    </ErrorBoundary>
  );
};

SnackbarContainer.displayName = "SnackbarContainer";

export default withErrorBoundary(SnackbarContainer, {});
