import { useTranslation } from "@hireroo/i18n";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

type Rank = "S" | "A" | "B" | "C" | "D" | "UNKNOWN";

const StyledBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  height: 36,
}));

const Label = styled("span")(() => ({
  fontSize: 24,
  fontWeight: 700,
  marginLeft: "4px",
}));

const StyledIconWrapper = styled("span")(({ theme }) => ({
  lineHeight: "8px",
  marginLeft: 8,
  color: theme.palette.action.active,
  svg: {
    width: 16,
    height: 16,
  },
}));

export type RankLabelProps = {
  rank: Rank;
  reason: string;
};

const RankLabel: React.FC<RankLabelProps> = props => {
  const { t } = useTranslation();
  if (props.rank === "UNKNOWN") {
    return (
      <StyledBox>
        <Typography variant="caption" sx={{ fontSize: 12 }}>
          {t("ランク")}: -
        </Typography>
        <StyledIconWrapper>
          <Tooltip title={props.reason}>
            <InfoOutlinedIcon fontSize="small" color="inherit" />
          </Tooltip>
        </StyledIconWrapper>
      </StyledBox>
    );
  }
  return (
    <StyledBox>
      <Typography variant="caption" sx={{ fontSize: 12 }}>
        {t("ランク")}:
      </Typography>
      <Tooltip title={props.reason}>
        <Label>{props.rank}</Label>
      </Tooltip>
    </StyledBox>
  );
};

RankLabel.displayName = "RankLabel";

export default RankLabel;
