import { useTranslation } from "@hireroo/i18n";
import * as z from "zod";

const useAlgorithmInputSchema = () => {
  const { t } = useTranslation();
  return z.object({
    name: z.string().min(1, { message: t("引数名は必須です。") }),
    type: z.string().min(1, { message: t("中身の型を追加してください。") }),
  });
};

const useAlgorithmOutputSchema = () => {
  return z.object({
    type: z.string(),
  });
};
export const useAlgorithmSignatureSchema = () => {
  const algorithmInput = useAlgorithmInputSchema();
  const algorithmOutput = useAlgorithmOutputSchema();
  return z.object({
    function: z.string(),
    inputs: algorithmInput.array(),
    outputs: algorithmOutput.array(),
  });
};
export type AlgorithmSignatureSchemaType = z.infer<ReturnType<typeof useAlgorithmSignatureSchema>>;

const useDBInputSchema = () => {
  const { t } = useTranslation();
  return z.object({
    name: z.string().min(1, { message: t("カラム名は必須です。") }),
    type: z.string().min(1, { message: t("カラムの型は必須です。") }),
  });
};
const useDBOutputSchema = () => {
  const { t } = useTranslation();
  return z.object({
    name: z.string().min(1, { message: t("カラム名は必須です。") }),
    type: z.string().min(1, { message: t("カラムの型は必須です。") }),
  });
};

const useDBTableSchema = () => {
  const { t } = useTranslation();
  const dbInput = useDBInputSchema();
  return z.object({
    name: z.string().min(1, { message: t("テーブル名は必須です。") }),
    columns: dbInput.array().superRefine((val, ctx) => {
      if (val.length !== new Set(val).size) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t("カラム名に同じ変数名は利用できません。"),
        });
      }
    }),
  });
};

export const useDatabaseSignatureSchema = () => {
  const { t } = useTranslation();
  const dbTable = useDBTableSchema();
  const output = useDBOutputSchema();
  return z.object({
    tables: dbTable.array().superRefine((val, ctx) => {
      if (val.length !== new Set(val).size) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t("テーブル名に同じ名前は利用できません。"),
        });
      }
    }),
    columns: output.array().superRefine((val, ctx) => {
      if (val.length !== new Set(val).size) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t("カラム名に同じ変数名は利用できません。"),
        });
      }
    }),
  });
};
export type DatabaseInputSchemaType = z.infer<ReturnType<typeof useDBInputSchema>>;
export type DatabaseTableSchemaType = z.infer<ReturnType<typeof useDBTableSchema>>;
export type DatabaseSignatureSchemaType = z.infer<ReturnType<typeof useDatabaseSignatureSchema>>;

const useClassInputSchema = () => {
  const { t } = useTranslation();
  return z.object({
    name: z.string().min(1, { message: t("引数名は必須です。") }),
    type: z.string().min(1, { message: t("中身の型を追加してください。") }),
  });
};

const useClassOutputSchema = () => {
  return z.object({
    type: z.string(),
  });
};

const useClassMethodSchema = () => {
  const inputSchema = useClassInputSchema();
  const outputSchema = useClassOutputSchema();

  return z.object({
    name: z.string(),
    inputs: inputSchema.array(),
    outputs: outputSchema.array(),
  });
};

const useClassConstructorSchema = () => {
  const inputSchema = useClassInputSchema();
  const outputSchema = useClassOutputSchema();

  return z.object({
    inputs: inputSchema.array(),
    outputs: outputSchema.array(),
  });
};

export const useClassSignatureSchema = () => {
  const classConstructorSchema = useClassConstructorSchema();
  const classMethodSchema = useClassMethodSchema();
  return z.object({
    class: z.string(),
    constructor: classConstructorSchema,
    methods: classMethodSchema.array(),
  });
};

type ClassMethodSchema = z.infer<ReturnType<typeof useClassMethodSchema>>;
type ClassConstructorSchema = z.infer<ReturnType<typeof useClassConstructorSchema>>;
export type ClassSignatureSchemaType = { class: string; constructor: ClassConstructorSchema; methods: ClassMethodSchema[] };

export const useAlgorithmSignatureFormSchema = () => {
  const { t } = useTranslation();
  const algorithmInputSchema = useAlgorithmInputSchema();
  return z.object({
    outputType: z.string().min(1, { message: t("中身の型を追加してください。") }),
    inputs: z.array(algorithmInputSchema).superRefine((val, ctx) => {
      if (val.length !== new Set(val).size) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t("引数名に同じ変数名は利用できません。"),
        });
      }
    }),
  });
};

export type AlgorithmSignatureFormSchema = z.infer<ReturnType<typeof useAlgorithmSignatureFormSchema>>;
