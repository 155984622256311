import { ref } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

export const setResponse = (res: Types.ScreeningsByCompanyIdResponse) => {
  state.res = res;
  state.screeningIds = res?.screenings.map(screening => screening.screeningId) ?? [];
  res.screenings.forEach(screening => {
    state.screeningMap.set(screening.screeningId, screening);
  });
};

export const initializeFilters = (filter: Types.SearchFilter, pager: Omit<Types.Pager, "offset">) => {
  state.currentSearchFilter = filter;
  state.pager = {
    ...pager,
    offset: pager.page * pager.size,
  };
};

export const updateCurrentSearchFilter = (filter: Types.SearchFilter) => {
  state.currentSearchFilter = filter;
  /** Reset page when search criteria are changed */
  state.pager = {
    ...state.pager,
    page: 0,
    offset: 0,
  };
};

export const addScreening = (screening: Types.Screening) => {
  state.screeningMap.set(screening.screeningId, screening);
  if (state.res) {
    state.res.screenings = [screening, ...state.res.screenings];
  }
  state.screeningIds = [screening.screeningId, ...state.screeningIds];
};

export const updateScreening = (screening: Types.Screening) => {
  state.screeningMap.set(screening.screeningId, screening);
  if (state.res) {
    const index = state.res.screenings.findIndex(item => item.screeningId === screening.screeningId);
    if (index !== -1) {
      state.res.screenings[index] = screening;
    }
  }
};

export const updateScreeningInvitees = (invitees: Types.Screening["invitees"]) => {
  const screeningId = invitees.at(0)?.screeningId;
  if (!screeningId) return;
  const screening = state.screeningMap.get(screeningId);
  if (screening) {
    screening.invitees = [...screening.invitees, ...invitees];
    state.screeningMap.set(screeningId, screening);
  }
};

export const updateScreenings = (screenings: Types.Screening[]) => {
  screenings.forEach(screening => {
    state.screeningMap.set(screening.screeningId, screening);
  });
};

export const clear = () => {
  state.res = null;
  state.screeningMap.clear();
  state.dialogStatus = "CLOSE";
};

export const setSpotTags = (spotTags: Types.SpotTags) => {
  state.spotTags = spotTags;
};

export const updatePage = (page: number) => {
  state.pager = {
    ...state.pager,
    page,
    offset: page * state.pager.size,
  };
};

export const updateRefreshKey = () => {
  state.refreshKey = Math.random().toString(36);
};

export const clearSelectedScreeningIds = () => {
  state.selectedScreeningIds = [];
  updateRefreshKey();
};
export const updateListFetchingStatus = (status: Types.ListFetchingStatus) => {
  state.listFetchingStatus = status;
};

export const updateDialogStatus = (status: Types.DialogStatus) => {
  state.dialogStatus = status;
};

export const updateSelectedScreeningIds = (ids: Types.ScreeningId[]) => {
  state.selectedScreeningIds = ids;
};

export const setTagRefresh = (refresh: () => void) => {
  state.tagRefresh = ref(refresh);
};
export const tagRefresh = () => {
  if (state.tagRefresh) {
    state.tagRefresh();
  }
};

export const deleteScreenings = (screeningIds: Types.ScreeningId[]) => {
  state.screeningIds = state.screeningIds.filter(id => !screeningIds.includes(id));
  screeningIds.forEach(screeningId => {
    state.screeningMap.delete(screeningId);
  });
  if (state.res?.screenings) {
    state.res.screenings = state.res.screenings.filter(screening => {
      return !screeningIds.includes(screening.screeningId);
    });
  }
};

export const deleteScreening = (screeningId: Types.ScreeningId) => {
  deleteScreenings([screeningId]);
};

export const updateSortField = (sortFieldValue: Types.SortFieldValue) => {
  state.pager = {
    ...state.pager,
    sortFieldValue: sortFieldValue,
    page: 0,
    offset: 0,
  };
};

export const updatePageSize = (size: number) => {
  state.pager = {
    ...state.pager,
    page: 0,
    offset: 0,
    size: size,
  };
};
