import { useTranslation } from "@hireroo/i18n";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Box, { BoxProps } from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { FileNode } from "../../../../helpers/fileTree";
import NavigationItem, { NavigationItemProps } from "../NavigationItem/NavigationItem";

export type TreeItemLabelProps = {
  node: FileNode;
  createFileButton?: Omit<NavigationItemProps, "title" | "kind" | "node">;
  createDirectoryButton?: Omit<NavigationItemProps, "title" | "kind" | "node">;
  deleteNodeButton?: Omit<NavigationItemProps, "kind" | "node">;
};

const TreeItemLabel: React.FC<TreeItemLabelProps> = props => {
  const { t } = useTranslation();
  const { node } = props;
  const [hover, setHover] = React.useState(false);

  const wrapperBoxProps: BoxProps = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    onMouseEnter: () => {
      setHover(true);
    },
    onMouseLeave: () => {
      setHover(false);
    },
  };

  const toolbarBoxProps: BoxProps = {
    className: "project-file-nav-icons",
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    sx: {
      height: 28,
    },
  };

  const navigationItems: (NavigationItemProps | false)[] = [
    node.isDir &&
      !!props.createFileButton && {
        ...props.createFileButton,
        title: t("ファイルを追加する"),
        kind: "CREATE_FILE",
        node,
      },
    node.isDir &&
      !!props.createDirectoryButton && {
        ...props.createDirectoryButton,
        title: t("ディレクトリを追加する"),
        kind: "CREATE_DIRECTORY",
        node,
      },
    !node.isRoot &&
      !!props.deleteNodeButton && {
        ...props.deleteNodeButton,
        kind: "DELETE_NODE",
        node,
      },
  ];

  return (
    <Box {...wrapperBoxProps}>
      <Box display="flex" flexDirection="row" alignItems="center" overflow="hidden">
        {node.isDir ? <FolderOutlinedIcon fontSize={"small"} /> : <InsertDriveFileOutlinedIcon fontSize={"small"} />}
        <Typography ml={1} variant={"body2"} color={"inherit"} sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", lineHeight: 0.9 }}>
          {node.name}
        </Typography>
        <Box mr={1} />
        {!node.isDir && node.isReadOnly && (
          <Tooltip title={t("このファイルは編集できません")} placement={"right"}>
            <LockOutlinedIcon fontSize={"small"} />
          </Tooltip>
        )}
      </Box>
      <Box {...toolbarBoxProps}>
        {hover &&
          navigationItems
            .flatMap(v => (v ? [v] : []))
            .map((navigationItem, index) => {
              return <NavigationItem key={`item-${index}-${navigationItem.title}`} {...navigationItem} />;
            })}
      </Box>
    </Box>
  );
};

TreeItemLabel.displayName = "TreeItemLabel";

export default TreeItemLabel;
