import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography, { TypographyProps } from "@mui/material/Typography";
import * as React from "react";

import DifficultyStars, { DifficultyStarsProps } from "../../../../../modules/DifficultyStars/DifficultyStars";
import DivTableCell from "../../../../../primitive/DivTableCell/DivTableCell";
import DivTableRow from "../../../../../primitive/DivTableRow/DivTableRow";
import Link, { LinkProps } from "../../../../../primitive/Link/Link";
import QuestionArchivedBadge from "../../../QuestionArchivedBadge/QuestionArchivedBadge";

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 700,
  color: theme.palette.text.primary,
}));

const StyledLink = styled(Link)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 700,
  color: theme.palette.text.primary,
  marginRight: theme.spacing(2),
}));

const TITLE_CELL_CLASS_NAME = "__title_cell__";

const StyledTableRow = styled(DivTableRow)(({ theme }) => ({
  "&:last-child tr": {
    borderBottom: "none",
  },
  borderTop: "1px solid",
  borderBottom: "1px solid",
  borderColor: theme.palette.Other.Divider,
  display: "table-row",
  [`.${TITLE_CELL_CLASS_NAME}`]: {
    paddingLeft: "32px",
  },
}));

const StyledTableCell = styled(DivTableCell)(() => ({
  border: "none",
  display: "table-cell",
}));

const SelectContentsWrapper = styled(Box)(() => ({
  display: "block",
  whiteSpace: "pre-wrap",
  textOverflow: "ellipsis",
  height: "40px",
  fontSize: "14px",
  overflow: "hidden",
}));

type SingleRowProps = {
  title: Pick<LinkProps, "href" | "children">;
  variant: string;
  selectedContents?: string[];
  weight: number;
  difficultyStars: DifficultyStarsProps;
  isChild?: boolean;
  isArchived: boolean;
};

type MultiRowProps = {
  title: Pick<TypographyProps, "children">;
  items: SingleRowProps[];
  isChild?: boolean;
  isArchived: boolean;
};

export type QuestionOverviewTableRowProps = SingleRowProps | MultiRowProps;

const QuestionOverviewTableRow: React.FC<QuestionOverviewTableRowProps> = props => {
  if ("items" in props) {
    const Items = props.items.map((itemProps, index) => {
      return <QuestionOverviewTableRow key={`nested-row-${index}`} {...itemProps} isChild />;
    });
    return (
      <>
        <StyledTableRow>
          <StyledTableCell>
            <StyledTypography {...props.title} />
          </StyledTableCell>
        </StyledTableRow>
        {Items}
      </>
    );
  }
  return (
    <StyledTableRow>
      <StyledTableCell className={props.isChild ? TITLE_CELL_CLASS_NAME : undefined}>
        <Stack direction="column">
          <Box display="flex" alignItems="center">
            <StyledLink {...props.title} underline="always" target="_blank" color="textPrimary" />
            {props.isArchived && <QuestionArchivedBadge />}
          </Box>
          <Typography fontSize={14} color="textSecondary">
            {props.variant}
          </Typography>
        </Stack>
      </StyledTableCell>
      <StyledTableCell>
        <DifficultyStars {...props.difficultyStars} />
      </StyledTableCell>
      <StyledTableCell>
        {props.selectedContents ? (
          <Tooltip title={props.selectedContents ? props.selectedContents.join(", ") : ""}>
            <SelectContentsWrapper>
              {props.selectedContents.length > 4 ? `${props.selectedContents.slice(0, 4).join(", ")}...` : props.selectedContents.join(", ")}
            </SelectContentsWrapper>
          </Tooltip>
        ) : (
          <Typography fontSize={14} color="textSecondary">
            -
          </Typography>
        )}
      </StyledTableCell>
    </StyledTableRow>
  );
};

QuestionOverviewTableRow.displayName = "QuestionOverviewTableRow";

export default QuestionOverviewTableRow;
