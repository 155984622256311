import { useTranslation } from "@hireroo/i18n";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import ReadonlySelectedQuestionField, {
  ReadonlySelectedQuestionFieldProps,
} from "../../../../modules/ReadonlySelectedQuestionField/ReadonlySelectedQuestionField";
import TextLinkify from "../../../../primitive/TextLinkify/TextLinkify";
import { useAssessmentResourceEditorV2Context } from "../../Context";
import ConfirmField from "./parts/ConfirmField/ConfirmField";
import Subsection, { SubsectionProps } from "./parts/Subsection/Subsection";

const OutlinedChip = styled(Chip)(({ theme }) => ({
  height: 30,
  ".MuiChip-deleteIcon": {
    color: theme.palette.action.disabled,
  },
  color: theme.palette.text.primary,
  borderColor: theme.palette.action.disabled,
}));

export type ConfirmSectionProps = {
  // Test Question Setup
  title: string;
  timelimit: string;
  selectedQuestions: ReadonlySelectedQuestionFieldProps[];

  // Test Setup
  nextScheduleAt: string;
  examInterval: string;
  remindBeforeDays: string;

  // Report Setup
  memo?: string;
  viewers: { iconSrc?: string; name: string; kind: "GROUP" | "USER" }[];

  // Invitation Setup
  talent: {
    displayName: string;
    email: string;
  };
  messageForTalent?: string;
};

const ConfirmSection: React.FC<ConfirmSectionProps> = props => {
  const { t } = useTranslation();
  const { updateStep, stepName } = useAssessmentResourceEditorV2Context();

  const testSetupSubsection: SubsectionProps = {
    title: t("テスト設定"),
    changeButton: {
      onClick: () => {
        updateStep("TEST_QUESTION_SETUP");
      },
    },
    children: (
      <Stack spacing={2}>
        <ConfirmField label={t("タイトル")}>{props.title}</ConfirmField>
        <ConfirmField label={t("出題問題")}>
          <Stack spacing={1}>
            {props.selectedQuestions.map((selectedQuestion, index) => {
              return <ReadonlySelectedQuestionField key={`selected-${index}`} {...selectedQuestion} />;
            })}
          </Stack>
        </ConfirmField>
        <ConfirmField label={t("制限時間")}>{props.timelimit}</ConfirmField>
      </Stack>
    ),
  };

  const testTakingSetupSubsection: SubsectionProps = {
    title: t("受験設定"),
    changeButton: {
      onClick: () => {
        updateStep("TEST_SETUP");
      },
    },
    children: (
      <Stack spacing={2}>
        <ConfirmField label={t("次の試験作成予定")}>{props.nextScheduleAt}</ConfirmField>
        <ConfirmField label={t("実施頻度")}>{props.examInterval}</ConfirmField>
        <ConfirmField label={t("リマインド")}>{props.remindBeforeDays}</ConfirmField>
      </Stack>
    ),
  };

  const reportSetupSubsection: SubsectionProps = {
    title: t("レポート設定"),
    changeButton: {
      onClick: () => {
        updateStep("REPORT_SETUP");
      },
    },
    children: (
      <Stack spacing={2}>
        <ConfirmField label={t("評価者用メモ")}>{props.memo}</ConfirmField>
        <ConfirmField label={t("閲覧権限")}>
          {props.viewers.length > 0 ? (
            <Stack direction="row" sx={{ flexWrap: "wrap", gap: "8px" }}>
              {props.viewers.map((viewer, index) => {
                const avatar = (
                  <Avatar src={viewer.kind === "USER" ? viewer.iconSrc : undefined}>
                    {viewer.kind === "USER" && !viewer.iconSrc && <PersonIcon />}
                    {viewer.kind === "GROUP" && <GroupIcon />}
                  </Avatar>
                );
                return <OutlinedChip key={`${viewer.name}-${index}`} variant="outlined" label={viewer.name} avatar={avatar} />;
              })}
            </Stack>
          ) : (
            t("リンクを知っているメンバーが閲覧可能")
          )}
        </ConfirmField>
      </Stack>
    ),
  };

  const invitationSetupSubsection: SubsectionProps = {
    title: t("招待設定"),
    changeButton: {
      onClick: () => {
        updateStep("TEST_INVITE_SETUP");
      },
    },
    children: (
      <Stack spacing={2}>
        <ConfirmField label={t("タレント")}>
          <Typography fontSize={14}>{props.talent.displayName}</Typography>
          <Typography fontSize={12} color="textSecondary" lineHeight="166%">
            {props.talent.email}
          </Typography>
        </ConfirmField>
        <ConfirmField label={t("タレントへのメッセージ")}>
          <TextLinkify>{props.messageForTalent}</TextLinkify>
        </ConfirmField>
      </Stack>
    ),
  };

  const subsections: SubsectionProps[] = [testSetupSubsection, testTakingSetupSubsection, reportSetupSubsection, invitationSetupSubsection];

  if (stepName !== "CONFIRM") {
    return null;
  }

  return (
    <Stack spacing={2} py={2} direction="column" sx={{ width: 800, margin: "0 auto" }}>
      {subsections.map((subsection, index) => {
        return <Subsection key={`subsection-${index}`} {...subsection} />;
      })}
    </Stack>
  );
};

ConfirmSection.displayName = "ConfirmSection";

export default ConfirmSection;
