import { useTranslation } from "@hireroo/i18n";
import AddIcon from "@mui/icons-material/Add";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

import EmployeeLayout, { EmployeeLayoutProps } from "../../layouts/EmployeeLayout/EmployeeLayout";
import AssessmentSearchForm, { AssessmentSearchFormProps } from "../../ms-components/Assessment/AssessmentSearchForm/AssessmentSearchForm";

const HeadingTypography = styled(Typography)(() => ({
  fontWeight: "bold",
  fontSize: 24,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
}));

const StyledButton = styled(Button)(() => ({
  height: 36,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
}));

export type AssessmentListForEmployeeProps = {
  layout: EmployeeLayoutProps;
  active: boolean;
  search: AssessmentSearchFormProps;
  AssessmentList: React.ReactNode;
  helpButton: Pick<ButtonProps, "onClick">;
  createAssessmentButton: Pick<ButtonProps, "onClick" | "disabled"> & { tooltipText: string };
};

const AssessmentListForEmployee: React.FC<AssessmentListForEmployeeProps> = props => {
  const { t } = useTranslation();
  const layoutProps: EmployeeLayoutProps = {
    ...props.layout,
  };
  const helpButtonProps: ButtonProps = {
    ...props.helpButton,
    startIcon: <HelpOutlineOutlinedIcon />,
    variant: "text",
    sx: theme => ({ color: theme.palette.text.secondary }),
  };
  const { tooltipText, ...rest } = props.createAssessmentButton;
  const createAssessmentButtonProps: ButtonProps = {
    ...rest,
    startIcon: <AddIcon />,
    color: "primary",
    variant: "outlined",
  };
  return (
    <EmployeeLayout {...layoutProps}>
      <Box p={3}>
        <Box display="flex" justifyContent="space-between" alignContent="center" mb={2}>
          <HeadingTypography>{t("タレントスコア")}</HeadingTypography>
          <Stack direction="row" spacing={2}>
            <StyledButton {...helpButtonProps}>{t("ヘルプ")}</StyledButton>
            {createAssessmentButtonProps.disabled && (
              <Tooltip title={tooltipText}>
                <span>
                  <StyledButton {...createAssessmentButtonProps}>{t("タレントスコア作成")}</StyledButton>
                </span>
              </Tooltip>
            )}
            {!createAssessmentButtonProps.disabled && <StyledButton {...createAssessmentButtonProps}>{t("タレントスコア作成")}</StyledButton>}
          </Stack>
        </Box>
        <Box mb={3}>
          <AssessmentSearchForm key={props.active ? "ACTIVE" : "NOT_ACTIVE"} {...props.search} />
        </Box>
        {props.AssessmentList}
      </Box>
    </EmployeeLayout>
  );
};

AssessmentListForEmployee.displayName = "AssessmentListForEmployee";

export default AssessmentListForEmployee;
