import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type UseHintEventFrequencyTimelinePanelContainerProps = {};

const UseHintEventFrequencyTimelinePanelContainer: React.FC<UseHintEventFrequencyTimelinePanelContainerProps> = () => {
  const eventFrequencyTimelinePanelProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.ActivityTimeBarChartPanel {...eventFrequencyTimelinePanelProps} />
    </ErrorBoundary>
  );
};

UseHintEventFrequencyTimelinePanelContainer.displayName = "UseHintEventFrequencyTimelinePanelContainer";

export default withErrorBoundary(React.memo(UseHintEventFrequencyTimelinePanelContainer), {});
