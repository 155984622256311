import Notify from "@hireroo/ui-assets/images/Error/Notify.svg";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import Link, { LinkProps } from "../../primitive/Link/Link";

export type ErrorPanelProps = {
  name: string;
  message: string;
  additionalMessage?: string;
  links: LinkProps[];
};

const StyledBox = styled(Box)(({ theme }) => ({
  width: "100vw",
  height: "100vh",
  backgroundColor: theme.palette.background.default,
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  padding: theme.spacing(2),
}));

const ErrorPanel: React.FC<ErrorPanelProps> = props => {
  return (
    <StyledBox>
      <img src={Notify} width={300} alt={"error-notify"} />
      <Box display={"flex"} ml={2} flexDirection={"column"} maxWidth={700}>
        <Typography role="name" variant="h4" gutterBottom>
          {props.name}
        </Typography>
        <Typography role="message" variant="body1" sx={{ lineHeight: "1.7" }}>
          {props.message}
        </Typography>
        {props.additionalMessage && (
          <Box mt={1}>
            <Typography role="caption" variant="body2">
              {props.additionalMessage}
            </Typography>
          </Box>
        )}
        <Stack spacing={1} mt={1} direction="row" divider={<span>/</span>}>
          {props.links.map((link, index) => (
            <Link key={`link-${index}`} {...link} underline="hover" />
          ))}
        </Stack>
      </Box>
    </StyledBox>
  );
};

ErrorPanel.displayName = "ErrorPanel";

export default ErrorPanel;
