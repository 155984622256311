import { proxyMap } from "valtio/utils";

import { state } from "./State";
import type * as Types from "./types";

export const initialize = (remote: Types.Remote): void => {
  state.remote = remote;
  const liveCoding = remote.entity?.liveCoding;
  const sessions = liveCoding?.sessions;
  sessions?.forEach(session => {
    if (session) {
      state.activeSessionMap.set(session.liveCodingSessionId, session);
    }
  });
  state.activeSessionMap = proxyMap(state.activeSessionMap);
  const sessionId = sessions?.[0]?.liveCodingSessionId;
  if (sessionId) {
    state.activeSessionId = sessionId;
  }

  // sort by createdAtSeconds desc
  const sortedVideoChatRooms =
    liveCoding?.videoChatRooms.flatMap(v => (v ? [v] : [])).sort((a, b) => (b.createdAtSeconds ?? 0) - (a.createdAtSeconds ?? 0)) ?? [];
  state.liveCodingVideoChatRoomId = sortedVideoChatRooms.length > 0 ? sortedVideoChatRooms[0].id : "";
  state.liveCodingVideoChatConversationId = liveCoding?.videoChatConversation?.id ?? "";

  state.fetchStatus = "FETCHED";
};

export const clear = () => {
  state.currentEntityIndex = 0;
  state.activeSessionId = null;
  state.waitingActiveSessionId = null;
  state.remote = null;
  state.activeSessionMap = proxyMap();
  state.liveCodingQuestionVariant = "UNKNOWN";
  state.liveCodingTemplateCodesMap = proxyMap();
  state.fetchStatus = "BEFORE_FETCH";
};

const promoteActiveSessionId = () => {
  if (!state.waitingActiveSessionId) {
    return;
  }
  if (state.activeSessionMap.has(state.waitingActiveSessionId)) {
    state.activeSessionId = state.waitingActiveSessionId;
    state.waitingActiveSessionId = null;
  }
};

export const updateRemote = (remote: Types.Remote): void => {
  state.remote = remote;
  const sessions = remote.entity?.liveCoding?.sessions;
  sessions?.forEach(session => {
    if (session) {
      state.activeSessionMap.set(session.liveCodingSessionId, session);
    }
  });
  state.activeSessionMap = proxyMap(state.activeSessionMap);
  promoteActiveSessionId();
};

export const updateActiveSessionId = (sessionId: Types.LiveCodingSessionId) => {
  if (state.activeSessionMap.has(sessionId)) {
    state.activeSessionId = sessionId;
    state.waitingActiveSessionId = null;
  } else if (sessionId > 0) {
    state.waitingActiveSessionId = sessionId;
  }
};

export const removeSession = (sessionId: Types.LiveCodingSessionId) => {
  state.activeSessionMap.delete(sessionId);
};

export const updateQuestionVariant = (challengeCodingEditorVariant: Types.LiveCodingQuestionVariant) => {
  state.liveCodingQuestionVariant = challengeCodingEditorVariant;
};

export const addLiveCodingTemplateCodes = (
  key: Types.LiveCodingTemplateCodesMapKey,
  liveCodingTemplateCodes: Types.LiveCodingTemplateCodes,
) => {
  state.liveCodingTemplateCodesMap.set(key, liveCodingTemplateCodes);
};

export const updateLiveCoding = (liveCoding: Types.LiveCoding) => {
  if (state.remote?.entity) {
    const entity = state.remote.entity;
    entity.liveCoding = liveCoding;
    state.remote.entity = entity;
  }
};

export const updateLiveCodingVideoChatRoomId = (roomId: string) => {
  state.liveCodingVideoChatRoomId = roomId;
};

export const updateLiveCodingVideoChatConversationId = (conversationId: string) => {
  state.liveCodingVideoChatConversationId = conversationId;
};
