import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type ReportQuizPlaybackSectionProps = {
  PlaybackComponent: React.ReactElement;
};

const ReportQuizPlaybackSection: React.FC<ReportQuizPlaybackSectionProps> = props => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: "bold" }}>
        {t("提出過程")}
      </Typography>

      <Box mt={2}>
        <Box display="flex" flexDirection="column">
          {props.PlaybackComponent}
        </Box>
      </Box>
    </Box>
  );
};

ReportQuizPlaybackSection.displayName = "ReportQuizPlaybackSection";

export default ReportQuizPlaybackSection;
