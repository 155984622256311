import { MenuItem, MenuItemProps } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectProps } from "@mui/material/Select";
import * as React from "react";
import { Control, Controller } from "react-hook-form";

export type SelectMenuItemProps = MenuItemProps & {
  label: string;
};

export type SelectControlProps = {
  menuItems: SelectMenuItemProps[];
  formControlId?: string;
  label?: string;
  value?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any>;
  helperText?: string;
  disabled?: boolean;
  select?: SelectProps;
};

const SelectControl: React.FC<SelectControlProps & { name: string }> = props => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl id={props.formControlId} fullWidth error={Boolean(error?.message)}>
          <InputLabel size="small">{props.label}</InputLabel>
          <Select
            size="small"
            labelId={props.formControlId}
            {...props.select}
            fullWidth
            id={props.name}
            label={props.label}
            disabled={props.disabled}
            onChange={onChange}
            value={value}
          >
            {props.menuItems.map(menuItem => (
              <MenuItem key={menuItem.label} {...menuItem} value={menuItem.value}>
                {menuItem.label}
              </MenuItem>
            ))}
          </Select>
          {(props.helperText || error) && (
            <FormHelperText error={Boolean(error?.message)}>{error?.message ? error.message : props.helperText}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

SelectControl.displayName = "SelectControl";

export default SelectControl;
