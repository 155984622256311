import * as React from "react";

import PrimitiveTab, { TabProps as PrimitiveTabProps } from "../../../../../../primitive/Tab/Tab";

export type TabProps = {
  value: string;
  name: string;
  onClick?: PrimitiveTabProps["onClick"];
  disabled?: PrimitiveTabProps["disabled"];
};

const Tab: React.FC<TabProps> = props => {
  const { value, name, onClick, disabled, ...other } = props;
  const tab: PrimitiveTabProps = {
    ...other,
    sx: {
      textTransform: "none",
    },
    label: name,
    value: value,
    "aria-labelledby": name,
    onClick: onClick,
    iconPosition: "start",
  };

  if (disabled) {
    return (
      <span>
        <PrimitiveTab {...tab} disabled={disabled} />
      </span>
    );
  }

  return <PrimitiveTab {...tab} />;
};

Tab.displayName = "Tab";

export default Tab;
