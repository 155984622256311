import * as React from "react";

import { JupyterNotebookAdapter } from "../api";

export type ContextValue = {
  adapter?: JupyterNotebookAdapter;
};

export const JupyterContext = React.createContext<ContextValue>({} as ContextValue);

export const useJupyterContext = () => React.useContext(JupyterContext);

export type JupyterProviderProps = {
  adapter: JupyterNotebookAdapter | undefined;
};

export const JupyterProvider: React.FC<React.PropsWithChildren<JupyterProviderProps>> = props => {
  const { adapter } = props;
  const contextValue: ContextValue = {
    adapter: adapter,
  };
  React.useEffect(() => {
    return () => {
      adapter?.dispose();
    };
  }, [adapter]);
  return <JupyterContext.Provider value={contextValue} children={props.children} />;
};
