import { App, Company } from "@hireroo/app-store/essential/employee";
import { DashboardScreeningsStatsSection } from "@hireroo/app-store/widget/e/DashboardScreeningsStatsSection";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import DashboardScreeningStatsSectionFetchContainer, { DashboardScreeningStatsSectionFetchContainerProps } from "./FetchContainer";

export type DashboardScreeningStatsSectionInitialContainerProps = {};

const DashboardScreeningStatsSectionInitialContainer: React.FC<DashboardScreeningStatsSectionInitialContainerProps> = () => {
  const appStatus = App.useStatus();
  const companyId = Company.useStrictActiveCompanyId();
  const selectedScreeningId = DashboardScreeningsStatsSection.useSelectedScreeningId();
  const [result, refresh] = Graphql.useListScreeningsByCompanyIdForDashboardScreeningStatsSectionQuery({
    variables: {
      companyId: companyId,
      screeningsByCompanyIdInput: {
        companyId: companyId,
        isDescending: true,
        // TODO: implement pagination
        offset: 0,
        size: 50,
        withCount: false,
      },
    },
    pause: appStatus !== "INITIALIZED",
  });

  React.useEffect(() => {
    if (result.data) {
      DashboardScreeningsStatsSection.initialize({
        companySpotStats: result.data.spotStatsByCompanyId,
        screeningsRes: result.data.screeningsByCompanyId,
      });
    }
  }, [result.data]);

  React.useEffect(() => {
    return () => {
      DashboardScreeningsStatsSection.clear();
    };
  }, []);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED") {
    return <Widget.Loading kind="CENTER_%" />;
  }
  if (!selectedScreeningId) {
    return null;
  }

  const containerProps: DashboardScreeningStatsSectionFetchContainerProps = {
    selectedScreeningId: selectedScreeningId,
  };

  return <DashboardScreeningStatsSectionFetchContainer {...containerProps} />;
};

DashboardScreeningStatsSectionInitialContainer.displayName = "DashboardScreeningStatsSectionInitialContainer";

export default withErrorBoundary(DashboardScreeningStatsSectionInitialContainer, {});
