import { SupportLanguage, SupportLanguageValue, useTranslation } from "@hireroo/i18n";
import { AlgorithmQuestionForm } from "@hireroo/validator";
import Box from "@mui/material/Box";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import InputControlTextField, { InputControlTextFieldProps } from "../../../primitive/InputControl/InputControlTextField";
import MarkdownPreviewEditor, { MarkdownPreviewEditorProps } from "../../../usecase/MarkdownPreviewEditor/MarkdownPreviewEditor";

const titleMap: Record<SupportLanguageValue, string> = {
  [SupportLanguage.JA]: "タイトル",
  [SupportLanguage.EN]: "title",
};
const descriptionMap: Record<SupportLanguageValue, string> = {
  [SupportLanguage.JA]: "詳細",
  [SupportLanguage.EN]: "description",
};

export type QuestionTabContentProps = {
  lang: SupportLanguageValue;
  index: number;
};

const QuestionTabContent: React.FC<QuestionTabContentProps> = props => {
  const { t } = useTranslation();
  const methods = useFormContext<AlgorithmQuestionForm.AlgorithmQuestionFormSchema>();
  const titleTextField: InputControlTextFieldProps = {
    control: methods.control,
    variant: "outlined",
    type: "text",
    label: titleMap[props.lang],
    fullWidth: true,
    color: "primary",
    InputLabelProps: {
      shrink: true,
    },
    placeholder: "Fizz Buzz",
    required: true,
    defaultValue: methods.getValues(`questions.${props.index}.title`),
  };
  const descriptionEditor: MarkdownPreviewEditorProps = {
    label: descriptionMap[props.lang],
    helperText: t("問題の本文を入力します。本文はMarkdown形式で入力できます。"),
    placeholder: `ex) ${t("XXX型の引数YYYを受け取り、返り値ZZZを返すような関数を実装します。")}`,
    required: true,
  };

  return (
    <Box mt={2} display="flex" flexDirection="column" gap={4}>
      <Box display="flex" gap={2}>
        <InputControlTextField {...titleTextField} name={`questions.${props.index}.title`} />
      </Box>

      <MarkdownPreviewEditor {...descriptionEditor} name={`questions.${props.index}.description`} areaSize="md" />
    </Box>
  );
};

QuestionTabContent.displayName = "QuestionTabContent";

export default QuestionTabContent;
