import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import * as React from "react";

import EvaluationItem, { EvaluationItemProps } from "../EvaluationItem/EvaluationItem";

type Kind = "DURING_INTERVIEW" | "AFTER_INTERVIEW";

export type EvaluationListProps = {
  evaluationItems: EvaluationItemProps[];
  kind: Kind;
};

const EvaluationList: React.FC<EvaluationListProps> = props => {
  const { t } = useTranslation();

  const EmptyTextMap = {
    DURING_INTERVIEW: (
      <Typography variant="body2" color="textSecondary" sx={{ mx: 2, mt: 2 }}>
        {t("評価項目が選択されていません。右上の「+評価項目を追加する」ボタンより評価項目を追加してください。")}
      </Typography>
    ),
    AFTER_INTERVIEW: (
      <>
        <Typography variant="body2" color="textSecondary" display="flex" justifyContent="center" sx={{ mx: 2, mt: 1 }}>
          {t("評価項目が選択されていません。")}
        </Typography>
        <Typography variant="body2" color="textSecondary" display="flex" justifyContent="center" sx={{ mx: 2 }}>
          {t("下の「+評価項目を追加する」ボタンより評価項目を追加してください。")}
        </Typography>
      </>
    ),
  } satisfies Record<Kind, React.ReactNode>;
  return (
    <List sx={{ width: "100%" }} component="nav" aria-labelledby="nested-list-subheader">
      {props.evaluationItems.length > 0
        ? props.evaluationItems.map((evaluationItem, index) => (
            <Box key={evaluationItem.id} my={index === 0 ? 0 : 2}>
              <EvaluationItem {...evaluationItem} />
            </Box>
          ))
        : EmptyTextMap[props.kind]}
    </List>
  );
};

EvaluationList.displayName = "EvaluationList";

export default EvaluationList;
