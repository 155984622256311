import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";

import Header, { HeaderProps } from "./parts/Header/Header";

const HEADER_HEIGHT = 52; // 28 + 24 (padding)
const TAB_HEIGHT = 43;
const ADJUSTMENT_HEIGHT = 25;

export type RemoteInterviewPlaybackProps = {
  header: HeaderProps;
  Content: React.ReactNode;
};

const RemoteInterviewPlayback: React.FC<RemoteInterviewPlaybackProps> = props => {
  const [contentSize, setContentSize] = React.useState(0);
  const wrapper = useResizeDetector<HTMLElement>();
  React.useLayoutEffect(() => {
    const rect = wrapper.ref.current?.getBoundingClientRect();
    if (!rect) {
      return;
    }
    const size = window.innerHeight - rect.top - HEADER_HEIGHT - TAB_HEIGHT - ADJUSTMENT_HEIGHT;
    if (size > 0) {
      setContentSize(size);
    }
  }, [wrapper]);

  return (
    <Stack direction="column" height="100%" p={3} ref={wrapper.ref}>
      <Box pb={3}>
        <Header {...props.header} />
      </Box>
      <Box height={contentSize}>{props.Content}</Box>
    </Stack>
  );
};

RemoteInterviewPlayback.displayName = "RemoteInterviewPlayback";

export default RemoteInterviewPlayback;
