import { useTranslation } from "@hireroo/i18n";
import { Fields } from "@hireroo/validator";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Box from "@mui/material/Box";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";

import QuestionMeta, { QuestionMetaProps } from "../../../../../../ms-components/Question/QuestionMeta/QuestionMeta";
import { useQuestionSearchAndSelectableAreaContext } from "../../../../Context";

type FieldValue = Fields.EntitySource.EntitySource;

const StyledStack = styled(Stack)(({ theme }) => ({
  padding: "8px 12px",
  '&[data-disabled="true"]': {
    backgroundColor: theme.palette.Action["Disabled Background"].p12,
  },
}));

const LeftWrapper = styled(Box)(() => ({
  width: "calc(100% - 24px)",
}));

const RightActionWrapper = styled(Box)(() => ({
  width: "24px",
  flexShrink: 0,
  display: "flex",
  justifyContent: "center",
}));

const SquareButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.secondary.main,
  minWidth: "32px",
  width: "32px",
  height: "32px",
  minHeight: "32px",
}));

export type SelectableQuestionListItemProps = {
  entitySource: FieldValue;

  questionMeta: QuestionMetaProps;

  selected: boolean;

  disabled?: boolean;

  disabledReason?: string;

  className?: string;
};

const SelectableQuestionListItem: React.FC<SelectableQuestionListItemProps> = props => {
  const { t } = useTranslation();
  const { updateSelectedEntitySources } = useQuestionSearchAndSelectableAreaContext();
  const selectButtonProps: IconButtonProps = {
    color: "secondary",
    children: <AddOutlinedIcon fontSize="small" />,
    disabled: props.disabled || props.selected,
    onClick: event => {
      event.preventDefault();
      event.stopPropagation();
      updateSelectedEntitySources(prev => prev.concat(props.entitySource));
    },
  };
  const tooltipText = React.useMemo(() => {
    if (props.selected) {
      return t("選択済");
    }
    if (props.disabled) {
      return props.disabledReason ?? t("選択可能な問題は最大で10問です。");
    }
    return "";
  }, [t, props.selected, props.disabledReason, props.disabled]);
  return (
    <StyledStack direction="row" className={props.className} data-disabled={selectButtonProps.disabled} spacing={1}>
      <LeftWrapper>
        <QuestionMeta {...props.questionMeta} />
      </LeftWrapper>
      <RightActionWrapper>
        <Tooltip title={tooltipText}>
          <span>
            <SquareButton {...selectButtonProps} />
          </span>
        </Tooltip>
      </RightActionWrapper>
    </StyledStack>
  );
};

SelectableQuestionListItem.displayName = "SelectableQuestionListItem";

export default SelectableQuestionListItem;
