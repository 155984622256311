import { AlgorithmQuestionForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import * as React from "react";
import { useForm, UseFormReturn } from "react-hook-form";

import { SelectControlProps } from "../../primitive/InputControl/SelectControl";

export type AlgorithmQuestionDetailContextProps = {
  variantField: Omit<SelectControlProps, "label" | "control" | "helperText">;
};

export type ContextValue = {
  methods: UseFormReturn<AlgorithmQuestionForm.AlgorithmQuestionFormSchema>;
  contextProps: AlgorithmQuestionDetailContextProps;
};

export const AlgorithmQuestionDetailFormContext = React.createContext<ContextValue>({} as ContextValue);

export const useAlgorithmQuestionDetailFormContext = () => React.useContext(AlgorithmQuestionDetailFormContext);

export type AlgorithmQuestionDetailFormProviderProps = {
  defaultValues: AlgorithmQuestionForm.AlgorithmQuestionFormSchema;
  contextProps: AlgorithmQuestionDetailContextProps;
};

export const AlgorithmQuestionDetailFormProvider: React.FC<React.PropsWithChildren<AlgorithmQuestionDetailFormProviderProps>> = props => {
  const { defaultValues } = props;
  const schema = AlgorithmQuestionForm.useAlgorithmQuestionFormSchema();
  const methods = useForm<AlgorithmQuestionForm.AlgorithmQuestionFormSchema>({
    resolver: zodResolver(schema),
    mode: "onSubmit",
    shouldUnregister: false,
    defaultValues,
  });

  React.useEffect(() => {
    methods.setValue("isPublic", defaultValues.isPublic);
    methods.setValue("variant", defaultValues.variant);
    methods.setValue("timeLimit", defaultValues.timeLimit);
    methods.setValue("difficulty", defaultValues.difficulty);
    methods.setValue("questions", defaultValues.questions);
  }, [methods, defaultValues]);

  const contextValue: ContextValue = {
    methods: methods,
    contextProps: props.contextProps,
  };
  return <AlgorithmQuestionDetailFormContext.Provider value={contextValue} children={props.children} />;
};
