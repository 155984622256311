import { useTranslation } from "@hireroo/i18n";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import VariableTextFiled, { VariableTextFiledProps } from "../VariableTextField/VariableTextFiled";

export type FillConfirmContentProps = {
  templateName: string;
  variableFields: VariableTextFiledProps[];
};

const FillConfirmContent: React.FC<FillConfirmContentProps> = props => {
  const { t } = useTranslation();
  return (
    <Stack direction="column" p={3} spacing={2}>
      <Typography fontSize={14} fontWeight={700}>
        {props.templateName}
      </Typography>
      <Typography color="textSecondary">
        {t("選択されたテストテンプレートに設定されている情報に値を指定してした上で、テスト作成を開始してください。")}
        {t("確定することによって作成画面で入力済みのものは上書きされますのでご注意ください。")}
      </Typography>
      <Stack direction="column" spacing={2}>
        {props.variableFields.length === 0 && (
          <Typography variant="body2" color="textSecondary">
            {t("テストテンプレートには変数が存在しません。確定ボタンを押すことで値が反映されます。")}
          </Typography>
        )}
        {props.variableFields.map(variableField => (
          <VariableTextFiled key={variableField.id} {...variableField} />
        ))}
      </Stack>
    </Stack>
  );
};

FillConfirmContent.displayName = "FillConfirmContent";

export default FillConfirmContent;
