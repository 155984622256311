import { useTranslation } from "@hireroo/i18n";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import HelpMenu, { HelpMenuProps } from "../../../../modules/HelpMenu/HelpMenu";
import LanguageDropDownMenu from "../../../../modules/LanguageDropDownMenu/LanguageDropDownMenu";
import PreferencesMenu, { PreferencesMenuProps } from "../../../../modules/PreferencesMenu/PreferencesMenu";
import ButtonWithTooltip, { ButtonWithTooltipProps } from "../../../../primitive/Button/ButtonWithTooltip/ButtonWithTooltip";
import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";

const ActionButton = styled(ButtonWithTooltip)(({ theme }) => ({
  wordBreak: "keep-all",
  ".MuiButton-startIcon": {
    color: theme.palette["Gray/Shades"].p56,
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  whiteSpace: "pre-wrap",
  fontSize: "14px",
  padding: "16px",
  borderRadius: "4px",
  backgroundColor: theme.palette.Other.FilledInputBG,
}));

export type MenuProps = {
  finishButton?: ButtonWithTooltipProps;
  help: HelpMenuProps;
  messageDialog: Pick<DialogWithCloseIconProps, "open" | "onClose">;
  messageForCandidate?: string;
  item: { type: "MANY"; content: PreferencesMenuProps } | { type: "LANGUAGE_ONLY" };
};

const Menu: React.FC<MenuProps> = props => {
  const { t } = useTranslation();
  const finishButton: ButtonWithTooltipProps | undefined = (props.finishButton || undefined) && {
    ...props.finishButton,
    color: "info",
    variant: "outlined",
    children: t("テスト終了"),
    startIcon: "LOGOUT",
    size: "medium",
  };
  const messageForCandidateDialog: DialogWithCloseIconProps = {
    ...props.messageDialog,
    title: t("企業からのメッセージ"),
    yesButton: undefined,
    optionalContent: {
      sx: {
        px: 3,
        py: 2,
      },
    },
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      {finishButton && <ActionButton {...finishButton} variant="outlined" color="outline-only" />}
      {props.item.type === "LANGUAGE_ONLY" && <LanguageDropDownMenu />}
      <HelpMenu {...props.help} />
      <DialogWithCloseIcon {...messageForCandidateDialog}>
        <Description>{props.messageForCandidate}</Description>
      </DialogWithCloseIcon>
      {props.item.type === "MANY" && <PreferencesMenu {...props.item.content} />}
    </Stack>
  );
};

Menu.displayName = "Menu";

export default Menu;
