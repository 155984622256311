import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";

import QuizQuestionCardContent, { QuizQuestionCardContentProps } from "./parts/QuizQuestionCardContent/QuizQuestionCardContent";

const CardWrapper = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  border: `solid 1px ${theme.palette["Secondary/Shades"].p16}`,
  backgroundColor: theme.palette["Secondary/Shades"].p12,
}));

const CardTopWrapper = styled(Box)(({ theme }) => ({
  borderStartStartRadius: theme.shape.borderRadius * 2,
  borderStartEndRadius: theme.shape.borderRadius * 2,
  backgroundColor: theme.palette["Secondary/Shades"].p12,
}));

const CardBottomWrapper = styled(Box)(({ theme }) => ({
  borderEndStartRadius: theme.shape.borderRadius * 2,
  borderEndEndRadius: theme.shape.borderRadius * 2,
}));

export type QuizQuestionCardProps = {
  remainQuestions: string;
  quizQuestionCardContent: QuizQuestionCardContentProps;
};

const QuizQuestionCard: React.FC<QuizQuestionCardProps> = props => {
  return (
    <CardWrapper id="quiz-form">
      <CardTopWrapper px={2} py={1.5}>
        <Typography variant="subtitle2" id="remain-questions">
          {props.remainQuestions}
        </Typography>
      </CardTopWrapper>
      <CardBottomWrapper px={3} pb={3} pt={1}>
        <QuizQuestionCardContent {...props.quizQuestionCardContent} />
      </CardBottomWrapper>
    </CardWrapper>
  );
};

QuizQuestionCard.displayName = "QuizQuestionCard";

export default QuizQuestionCard;
