import CircleIcon from "@mui/icons-material/Circle";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

type Status = "ACTIVE" | "NOT_ACTIVE";

const StyledCircleIcon = styled(CircleIcon)`
  font-size: 12px;
  top: 3px;
  position: relative;
`;

const Wrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  overflowX: "scroll",
  fontWeight: "bold",
}));

const CircleIconTypography = styled(Typography)(({ theme }) => ({
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  fontSize: 12,
  marginRight: theme.spacing(0.5),
  '&[data-status="ACTIVE"]': {
    color: theme.palette.success.light,
  },
  '&[data-status="NOT_ACTIVE"]': {
    color: theme.palette.error.light,
  },
}));

const TitleText = styled(Typography)(({ theme }) => ({
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  overflow: "hidden",
  fontWeight: "bold",
  width: "210px",
  color: theme.palette.text.primary,
  fontSize: 16,
}));

export type AssessmentCardTitleProps = {
  status: Status;
  name: string;
};

const AssessmentCardTitle: React.FC<AssessmentCardTitleProps> = props => {
  return (
    <Wrapper>
      <CircleIconTypography data-status={props.status}>
        <StyledCircleIcon />
      </CircleIconTypography>
      <Tooltip title={props.name}>
        <TitleText>{props.name}</TitleText>
      </Tooltip>
    </Wrapper>
  );
};

AssessmentCardTitle.displayName = "AssessmentCardTitle";

export default AssessmentCardTitle;
