import Button, { ButtonProps } from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import * as React from "react";

type OptionalDialogProps = Omit<DialogProps, "open">;

export type YesButtonProps = Pick<ButtonProps, "disabled" | "children"> & { onClick?: () => void };

export type NoButtonProps = Pick<ButtonProps, "onClick" | "disabled" | "children">;

export type BaseDialogProps = {
  title?: string;
  open: boolean;
  yesButton?: YesButtonProps;
  noButton?: NoButtonProps;
  disableEnter?: boolean;
  optionalDialog?: OptionalDialogProps;
  yesButtonTooltip?: Omit<TooltipProps, "children">;
};

const TooltipContentWrapper = styled("span")`
  margin-left: 8px;
`;

const BaseDialog: React.FC<React.PropsWithChildren<BaseDialogProps>> = props => {
  const { open, title, children } = props;
  const processing = React.useRef(false);

  React.useEffect(() => {
    return () => {
      if (!open && processing.current) {
        processing.current = false;
      }
    };
  }, [open]);

  const yesButtonTooltip: Omit<TooltipProps, "children"> | undefined = props.yesButtonTooltip
    ? {
        ...props.yesButtonTooltip,
      }
    : undefined;

  const yesButtonProps: ButtonProps | undefined = props.yesButton
    ? {
        ...props.yesButton,
        role: "yesbutton",
        variant: "contained",
        color: "primary",
        autoFocus: true,
      }
    : undefined;

  const noButtonProps: ButtonProps | undefined = props.noButton
    ? {
        ...props.noButton,
        role: "nobutton",
      }
    : undefined;

  return (
    <Dialog
      {...props.optionalDialog}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      role="dialog-root"
      onKeyDown={e => {
        // Disable preventDefault on enter key for element that uses
        // enter key as its functionality, such as textarea.
        if (props.disableEnter === true) {
          return;
        }

        if (e.key === "Enter") {
          e.preventDefault();
          if (processing.current) return;
          processing.current = true;
          props.yesButton?.onClick?.();
        }
      }}
    >
      <DialogTitle role="title">{title}</DialogTitle>
      <DialogContent role="content">{children}</DialogContent>
      <DialogActions>
        {noButtonProps && <Button {...noButtonProps} />}
        {yesButtonProps &&
          (yesButtonTooltip ? (
            <Tooltip {...yesButtonTooltip}>
              <TooltipContentWrapper>
                <Button {...yesButtonProps} />
              </TooltipContentWrapper>
            </Tooltip>
          ) : (
            <Button {...yesButtonProps} />
          ))}
      </DialogActions>
    </Dialog>
  );
};

BaseDialog.displayName = "BaseDialog";

export default BaseDialog;
