import Alert, { AlertColor } from "@mui/material/Alert";
import Button, { ButtonProps } from "@mui/material/Button";
import MuiSnackbar, { SnackbarProps as MuiSnackbarProps } from "@mui/material/Snackbar";
import * as React from "react";

export type CommonSnackbarProps = {
  open: boolean;
  severity?: AlertColor | null;
  onClose: MuiSnackbarProps["onClose"];
  message: string;
  actionButton?: ButtonProps;
};

const CommonSnackbar: React.FC<CommonSnackbarProps> = props => {
  const muiSnackbarProps: MuiSnackbarProps = {
    role: "snackbar",
    open: props.open,
    autoHideDuration: 6000,
    onClose: props.onClose,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
  };
  return (
    <MuiSnackbar {...muiSnackbarProps}>
      <Alert
        role="alert"
        onClose={event => props.onClose?.(event, "clickaway")}
        action={props.actionButton && <Button size="small" color="primary" {...props.actionButton} />}
        severity={props.severity || undefined}
      >
        {props.message}
      </Alert>
    </MuiSnackbar>
  );
};

CommonSnackbar.displayName = "CommonSnackbar";

export default CommonSnackbar;
