import { generatePath } from "./generatePath";
import { currentWindowMatchPath } from "./matchPath";

export const redirect = (...args: Parameters<typeof generatePath>): Promise<unknown> => {
  /**
   * Wait for the process to finish until the page redirects
   */
  return new Promise(resolve => {
    const url = generatePath(...args);
    window.addEventListener("load", resolve);
    window.location.href = url;
  });
};

export const safeRedirect = (...args: Parameters<typeof generatePath>): Promise<unknown> => {
  if (currentWindowMatchPath(args[0])) {
    return Promise.resolve();
  }
  return redirect(...args);
};

export const redirectWithRawUrl = (url: string): Promise<unknown> => {
  /**
   * Wait for the process to finish until the page redirects
   */
  return new Promise(resolve => {
    window.addEventListener("load", resolve);
    window.location.href = url;
  });
};
