import TextField, { TextFieldProps } from "@mui/material/TextField";
import * as React from "react";
import { Control, useController } from "react-hook-form";

type IgnoreMessage = "IGNORE_ERROR" | "IGNORE_ERROR_MESSAGE_HELPER_TEXT";

export type InputControlTextFieldProps = TextFieldProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any>;
  ignoreErrorMessage?: IgnoreMessage;
};

const InputControlTextField: React.FC<InputControlTextFieldProps & { name: string }> = ({ defaultValue, ignoreErrorMessage, ...props }) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name: props.name,
    control: props.control,
    defaultValue: defaultValue,
  });

  const helperText = React.useMemo(() => {
    if ((ignoreErrorMessage === "IGNORE_ERROR" && error?.message) || ignoreErrorMessage === "IGNORE_ERROR_MESSAGE_HELPER_TEXT") {
      return props.helperText;
    }

    return error?.message ?? props.helperText;
  }, [error?.message, ignoreErrorMessage, props.helperText]);

  return (
    <TextField
      color="secondary"
      {...props}
      onChange={event => {
        props.onChange?.(event);
        return field.onChange(event);
      }}
      /**
       * Follow the warning below to ensure that `""` is used if value is undefined.
       *
       * Warning: `value` prop on `textarea` should not be null. Consider using an empty string to clear the component or `undefined` for uncontrolled components.
       */
      value={typeof field.value === "string" || typeof field.value === "number" ? field.value : ""}
      inputRef={field.ref}
      size="small"
      error={ignoreErrorMessage !== "IGNORE_ERROR" ? Boolean(error?.message) : false}
      helperText={helperText}
    />
  );
};

InputControlTextField.displayName = "InputControlTextField";

export default InputControlTextField;
