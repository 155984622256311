import * as ProjectHelperV2 from "@hireroo/app-helper/project-v2";
import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import * as JSONFormatter from "@hireroo/formatter/json";
import { formatScore } from "@hireroo/formatter/score";
import * as Time from "@hireroo/formatter/time";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

type BackendTestCaseProps = Extract<
  Exclude<Widget.ProjectTestReportProps["testCaseSection"], undefined>["content"],
  { kind: "BACKEND" }
>["testCases"][0];

export type GenerateTestCaseSectionPropsForBackendArgs = {
  entityId: number;
  uniqueKey: ProjectTestReport.UniqueKey;
  ignore: boolean;
  showScore: boolean;
};

export const useGenerateTestCaseSectionPropsForBackendV2 = (
  args: GenerateTestCaseSectionPropsForBackendArgs,
): Widget.ProjectTestReportProps["testCaseSection"] => {
  const hooks = ProjectTestReport.useCreateProjectHooks(args.entityId);
  const submission = hooks.useCurrentSubmission();
  const question = hooks.useQuestion();
  const testCasesResults = React.useMemo(() => {
    if (args.ignore || !submission?.correctnessTestResult) {
      return [];
    }
    return ProjectHelperV2.BackendTestCase.parseBackendTestCaseResults(submission.correctnessTestResult) ?? [];
  }, [args.ignore, submission?.correctnessTestResult]);

  const correctnessTestCases = React.useMemo(() => {
    if (args.ignore || !question?.correctnessTestCase) {
      return [];
    }
    return ProjectHelperV2.BackendTestCase.parseBackendTestCases(question.correctnessTestCase) ?? [];
  }, [args.ignore, question?.correctnessTestCase]);

  return {
    scrollTargetElementId: ProjectTestReport.TargetElementIdMap.CORRECT_RATE_SECTION,
    scoreAnswerRateDisplayLabel: {
      numPassed: submission?.numPassed ?? 0,
      numTests: submission?.numTests ?? 0,
    },
    titleWithScoreBar: {
      score: args.showScore ? formatScore(submission?.coverage ?? 0) : undefined,
    },
    content: {
      kind: "BACKEND",
      testCases: (testCasesResults || []).map((testCasesResult, tcIndex): BackendTestCaseProps => {
        const correctnessTestCase = correctnessTestCases.at(tcIndex);
        return {
          tcIndex,
          status: testCasesResult.is_passed ? "SUCCESS" : "ERROR",
          invisible: correctnessTestCase?.is_hidden ?? false,
          testResult: {
            kind: "SINGLE",
            value: {
              index: tcIndex + 1,
              status: testCasesResult.is_passed ? "SUCCESS" : "ERROR",
              httpStatusCode: testCasesResult.status.toString(),
              latency: Time.getTimeUnitText(testCasesResult.latency, Time.Unit.MILLISECOND),
              codeEditor: {
                value: JSONFormatter.formatJson(correctnessTestCase?.body ?? "", "{}"),
                /**
                 * @see https://github.com/suren-atoyan/monaco-react#multi-model-editor
                 */
                path: `tc-${tcIndex}-command-${tcIndex}`,
              },
              diffEditor: {
                originalModelPath: `original-test-result-${tcIndex}`,
                original: JSONFormatter.prettyJsonString(testCasesResult.output, "{}"),
                modifiedModelPath: `modified-test-result-${tcIndex}`,
                modified: JSONFormatter.prettyJsonString(testCasesResult.expected, "{}"),
              },
            },
          },
        };
      }),
    },
  };
};
