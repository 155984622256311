import { useSnapshot } from "valtio";

import { state } from "./State";

export const useCommonSnackbarState = () => {
  return useSnapshot(state.common);
};

export const useLoadingSnackbarState = () => {
  return useSnapshot(state.loading);
};
