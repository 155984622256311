import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Company } from "@hireroo/app-store/essential/employee";
import { FillRemoteTemplateList } from "@hireroo/app-store/widget/e/FillRemoteTemplateList";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/react";
import * as React from "react";

type Item = Widget.FillTemplateListProps["items"][0];
export const TEMPLATE_LIST_SIZE = 10;
export type GenerateFillTemplateListPropsArgs = {};

export const useGenerateProps = (_args: GenerateFillTemplateListPropsArgs): Widget.FillTemplateListProps => {
  const { t } = useTranslation();
  const companyId = Company.useStrictActiveCompanyId();
  const fetchable = FillRemoteTemplateList.useFetchable();
  const nextPager = FillRemoteTemplateList.useNextPager();
  const currentPager = FillRemoteTemplateList.useCurrentPager();
  const templates = FillRemoteTemplateList.useTemplates();
  const navigate = useTransitionNavigate();
  const client = getGraphqlClient();
  const [result] = Graphql.useListTemplatesForRemoteInterviewResourceEditorQuery({
    variables: {
      input: {
        companyId: companyId,
        size: TEMPLATE_LIST_SIZE,
        idCursor: nextPager?.idCursor,
        withCount: false,
      },
    },
    requestPolicy: "network-only",
    pause: !fetchable,
  });
  React.useEffect(() => {
    if (result.data?.remoteInterviewTemplatesByCompanyId) {
      FillRemoteTemplateList.updateResponse(result.data.remoteInterviewTemplatesByCompanyId);
    }
  }, [result.data]);
  const items = React.useMemo((): Item[] => {
    return templates.map((template): Item => {
      return {
        id: template.id,
        title: template.templateName,
        description: template.description,
        onClick: () => {
          FillRemoteTemplateList.initializeSelectedTemplate(template.templateId);
          FillRemoteTemplateList.updateStep("CONFIRM");
        },
        editButton: {
          onClick: () => {
            navigate("/e/templates/remote/:id/update", {
              pathParams: { id: template.templateId.toString() },
            });
          },
        },
        deleteButton: {
          onClick: () => {
            client
              .DeleteTemplateForRemoteInterviewTemplateListMenu({
                id: template.templateId,
              })
              .then(() => {
                FillRemoteTemplateList.removeTemplate(template.templateId);
                Snackbar.notify({
                  severity: "success",
                  message: t("テストテンプレートを削除しました。"),
                });
              })
              .catch(error => {
                Sentry.captureException(error);
                const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                  error,
                  t("テストテンプレートの削除に失敗しました。しばらくしてから再度お試し頂くか、ヘルプボタンよりお問い合わせください。"),
                );
                Snackbar.notify({
                  severity: "error",
                  message: errorNotification.message,
                });
              });
          },
        },
      };
    });
  }, [templates, navigate, client, t]);
  return {
    items: items,
    loading: result.fetching,
    hasNext: currentPager?.hasNext,
    onEndReached: () => {
      FillRemoteTemplateList.goNextPage();
    },
  };
};
