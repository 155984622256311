import { createPlaybackManager } from "@hireroo/app-helper/project-playback";
import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import type { Widget } from "@hireroo/presentation";

import { extractDirectoriesFromPath, useInitialFileNode } from "./privateHelpers";

const { ProjectContentsViewerV4 } = ProjectTestReport;

export type GenerateFileTreePropsArgs = {
  projectId: number;
};

export const useGenerateFileTreeProps = (args: GenerateFileTreePropsArgs): Widget.ProjectContentsViewerV4Props["fileTree"] => {
  const projectHooks = ProjectTestReport.useCreateProjectHooks(args.projectId);
  const currentSubmission = projectHooks.useStrictCurrentSubmission();
  const questionId = projectHooks.useQuestionId();
  const initialFileIndexes = currentSubmission.diffs
    .filter(diff => diff.operation === "MODIFICATION" || diff.operation === "ADDITION")
    .map(diff => diff.toPath);
  const initialFileNode = useInitialFileNode(initialFileIndexes);
  const latestEncodedPathWithIdMap = ProjectContentsViewerV4.useLatestEncodedPathWithIdMap();
  const selectedFileIndex = ProjectContentsViewerV4.useSelectedFileIndex();

  return {
    mode: "NORMAL",
    props: {
      focused: selectedFileIndex,
      // defaultExpanded needs to be the parent directory of the selected file
      defaultExpanded: extractDirectoriesFromPath(selectedFileIndex),
      renderTree: {
        node: initialFileNode,
        onSelectDirectory: () => {},
        onSelectFile: async sourceFile => {
          /**
           * If the selected file is the same as the source file,
           * do nothing because we only want to initialize playbackManager when the file is changed.
           */
          if (selectedFileIndex === sourceFile) return;

          const latestEncodedPathWithId = latestEncodedPathWithIdMap.get(sourceFile);
          if (latestEncodedPathWithId && questionId) {
            const instance = await createPlaybackManager({
              projectId: args.projectId,
              questionId: questionId,
              encodedFilePath: latestEncodedPathWithId,
            });
            instance.setTickIndex(instance.lastTickIndex);
            ProjectContentsViewerV4.initializePlaybackManager({
              playbackManager: instance,
              selectedFileIndex: sourceFile,
            });
          }
        },
      },
    },
  };
};
