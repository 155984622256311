import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { useTitle } from "@hireroo/app-helper/react-use";
import { stringComponentTypeToGraphqlComponentTypeGraphql } from "@hireroo/app-helper/system-design";
import { Auth } from "@hireroo/app-store/essential/employee";
import { EmployeeSideBarContent } from "@hireroo/app-store/widget/e/EmployeeSideBarContent";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import type { Pages } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/react";
import React from "react";

import AssessmentResourceEditorV2Container, {
  AssessmentResourceEditorContainerProps as AssessmentResourceEditorV2ContainerProps,
} from "../../../../widget/v2/e/AssessmentResourceEditorV2/Container";
import EmployeeHeaderRightContentContainer from "../../../../widget/v2/e/EmployeeHeaderRightContent/Container";
import EmployeeSideBarContentContainer from "../../../../widget/v2/e/EmployeeSideBarContent/Container";
import FooterContainer from "../../../../widget/v2/e/Footer/Container";
import { useNotificationBanner } from "../../../../widget/v2/e/NotificationBanner/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";

export type GenerateAssessmentResourceEditorPropsArgs = {};

export const useGenerateProps = (_args: GenerateAssessmentResourceEditorPropsArgs): Pages.AssessmentResourceEditorProps => {
  const { t } = useTranslation();
  const client = getGraphqlClient();
  const [status, setStatus] = React.useState<"READY" | "PENDING">("READY");
  const navigate = useTransitionNavigate();
  const NotificationBannerContainer = useNotificationBanner();
  const currentUid = Auth.useCurrentUid();

  useTitle(t("タレントスコア作成"));
  const assessmentResourceEditorV2ContainerProps: AssessmentResourceEditorV2ContainerProps = {
    mode: "CREATE",
    loading: status === "PENDING",
    onSubmit: fields => {
      setStatus("PENDING");
      client
        .CreateAssessmentForAssessmentsCreate({
          input: {
            companyId: fields.testQuestionSetup.companyId,
            employeeId: currentUid,
            entitySources: fields.testQuestionSetup.entityTracks.reduce<Graphql.AssessmentEntitySourceInput[]>((all, entityTrack) => {
              /** Currently, Talent Score does not support anything other than FIXED */
              if (entityTrack.type !== "FIXED") {
                return all;
              }
              const entitySource = entityTrack.entitySource;
              switch (entitySource.type) {
                case "CHALLENGE":
                  return all.concat({
                    entityType: Graphql.AssessmentEntityType.Challenge,
                    questionId: entitySource.questionId,
                    challengeQuestion: {
                      questionId: entitySource.questionId,
                      questionVersion: entitySource.questionVersion,
                      enabledLanguages: entitySource.enabledLanguages,
                    },
                  });
                case "PROJECT":
                  return all.concat({
                    entityType: Graphql.AssessmentEntityType.Project,
                    questionId: entitySource.questionId,
                    projectQuestion: {
                      questionId: entitySource.questionId,
                      questionVersion: entitySource.questionVersion,
                    },
                  });
                case "QUIZ":
                  return all.concat({
                    entityType: Graphql.AssessmentEntityType.Quiz,
                    questionId: entitySource.packageId,
                    quizPackage: {
                      packageId: entitySource.packageId,
                      packageVersion: entitySource.packageVersion,
                    },
                  });
                case "SYSTEM_DESIGN":
                  return all.concat({
                    entityType: Graphql.AssessmentEntityType.SystemDesign,
                    questionId: entitySource.questionId,
                    systemDesignQuestion: {
                      questionId: entitySource.questionId,
                      componentTypes: entitySource.componentTypes.map(
                        componentType => stringComponentTypeToGraphqlComponentTypeGraphql[componentType],
                      ),
                    },
                  });
              }
              return all;
            }, []),
            examInterval: fields.testSetup.examInterval,
            isPublic: fields.reportSetup.isPublic,
            name: fields.testQuestionSetup.name,
            reportSettings: {
              showAnswer: fields.reportSetup.reportSettings.showAnswer,
              showPlayback: fields.reportSetup.reportSettings.showPlayback,
              showRelativeEvaluation: fields.reportSetup.reportSettings.showRelativeEvaluation,
            },
            viewerIds: fields.reportSetup.viewers.reduce<Graphql.AssessmentViewerIdInput[]>((viewerIds, reviewer) => {
              if (reviewer.value === null) {
                return viewerIds;
              }
              switch (reviewer.value.type) {
                case "EMPLOYEE":
                  return viewerIds.concat({
                    type: Graphql.AssessmentViewerType.Employee,
                    employeeId: reviewer.value.employeeId,
                  });
                case "EMPLOYEE_GROUP":
                  return viewerIds.concat({
                    type: Graphql.AssessmentViewerType.Group,
                    groupId: reviewer.value.groupId,
                  });
                default:
                  break;
              }
              return viewerIds;
            }, []),
            talentId: fields.testInviteSetup.talent.talentId,
            timeLimitSeconds: fields.testQuestionSetup.timeLimitMinutes * 60,
            memo: fields.reportSetup.memo,
            startScheduleAtSeconds: fields.testSetup.nextStartScheduleAt.getTime() / 1000,
            messageForTalent: fields.testInviteSetup.messageForTalent,
            /** Day to Seconds */
            remindBeforeSeconds: fields.testSetup.remindBeforeDays * 86400,
          },
        })
        .then(() => {
          Snackbar.notify({
            severity: "success",
            message: t("タレントスコアの作成に成功しました。"),
          });
          navigate("/e/assessments");
        })
        .catch(error => {
          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(
            error,
            t("タレントスコアの作成に失敗しました。しばらくしてから再度お試し頂くか、お問い合わせください。"),
          );
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
        })
        .finally(() => {
          setStatus("READY");
        });
    },
  };

  React.useEffect(() => {
    EmployeeSideBarContent.setOpenSidebar(false);
  }, []);

  return {
    layout: {
      openSidebar: EmployeeSideBarContent.useOpenSidebar(),
      onChangeOpenSidebar: open => {
        EmployeeSideBarContent.setOpenSidebar(open);
      },
      NotificationBanner: NotificationBannerContainer,
      loading: status === "PENDING",
      HeaderRightContent: <EmployeeHeaderRightContentContainer />,
      SideBarContent: <EmployeeSideBarContentContainer />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      sideBarHeader: {
        logo: {
          href: generatePath("/e/assessments"),
          onClick: () => {
            navigate("/e/assessments");
          },
        },
      },
      navigationBreadcrumbs: {
        parentLinks: [
          {
            href: generatePath("/e/home"),
            children: t("ホーム"),
            onClick: () => {
              navigate("/e/home");
            },
          },
          {
            href: generatePath("/e/assessments"),
            children: t("タレントスコア一覧"),
            onClick: () => {
              navigate("/e/assessments");
            },
          },
        ],
        current: t("タレントスコア作成"),
      },
    },
    children: <AssessmentResourceEditorV2Container {...assessmentResourceEditorV2ContainerProps} />,
  };
};
