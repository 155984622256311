import { Message } from "@twilio/conversations";
import React from "react";

import { useChat } from "./useChat";

type ContextValue = {
  connect: (conversationsId: string, token: string) => Promise<() => void>;
  messages: Message[];
  sendMessage: (message: string) => Promise<number | undefined>;
  readMessages: () => void;
  hasUnreadMessages: boolean;
};

export const TwilioConversationsContext = React.createContext<ContextValue>({} as ContextValue);

export const useTwilioConversationsContext = () => {
  const context = React.useContext(TwilioConversationsContext);
  if (!context) {
    throw new Error("useTwilioConversationsContext must be used within a TwilioConversationsProvider");
  }
  return context;
};

export type TwilioConversationsProviderProps = {
  children: React.ReactNode;
};

export const TwilioConversationsProvider: React.FC<TwilioConversationsProviderProps> = props => {
  const { connect, messages, sendMessage, readMessages, hasUnreadMessages } = useChat();

  return (
    <TwilioConversationsContext.Provider
      value={{
        connect,
        messages,
        sendMessage,
        readMessages,
        hasUnreadMessages,
      }}
    >
      {props.children}
    </TwilioConversationsContext.Provider>
  );
};
