import { ChallengePlayback } from "@hireroo/app-store/widget/shared/ChallengePlayback";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateReportChallengePlaybackRightSidePanelPropsArgs, useGenerateProps } from "./useGenerateProps";

export type ReportChallengePlaybackRightSidePanelContainerProps = GenerateReportChallengePlaybackRightSidePanelPropsArgs;

const ReportChallengePlaybackRightSidePanelContainer: React.FC<ReportChallengePlaybackRightSidePanelContainerProps> = props => {
  const reportChallengePlaybackRightSidePanelProps = useGenerateProps(props);
  const isSessionIssued = ChallengePlayback.useIsSessionIssued();

  if (!isSessionIssued) {
    return null;
  }

  return (
    <ErrorBoundary>
      <Widget.ReportChallengePlaybackRightSidePanel {...reportChallengePlaybackRightSidePanelProps} />
    </ErrorBoundary>
  );
};

ReportChallengePlaybackRightSidePanelContainer.displayName = "ReportChallengePlaybackRightSidePanelContainer";

export default withErrorBoundary(ReportChallengePlaybackRightSidePanelContainer, {});
