import { Fields } from "@hireroo/validator";
import * as React from "react";

type FieldValue = Fields.EntitySource.EntitySource[];

type Mode = "SELECT_FROM_QUESTION_PACKAGE" | "SELECT_FROM_ALL_QUESTIONS";

export type ContextValue = {
  mode: Mode;
  selectedEntitySources: FieldValue;
  updateSelectedEntitySources: React.Dispatch<React.SetStateAction<FieldValue>>;
  maxAddableQuestionCount: number;
};

export const QuestionSearchAndSelectableAreaContext = React.createContext<ContextValue>({
  mode: "SELECT_FROM_ALL_QUESTIONS",
  selectedEntitySources: [],
  updateSelectedEntitySources: () => undefined,
  maxAddableQuestionCount: 10,
});

export const useQuestionSearchAndSelectableAreaContext = () => React.useContext(QuestionSearchAndSelectableAreaContext);

export type QuestionSearchAndSelectableAreaProviderProps = {
  mode: Mode;
  maxAddableQuestionCount: number;
};

export const QuestionSearchAndSelectableAreaProvider: React.FC<
  React.PropsWithChildren<QuestionSearchAndSelectableAreaProviderProps>
> = props => {
  const [selectedEntitySources, updateSelectedEntitySources] = React.useState<FieldValue>([]);
  const contextValue: ContextValue = {
    mode: props.mode,
    selectedEntitySources,
    updateSelectedEntitySources,
    maxAddableQuestionCount: props.maxAddableQuestionCount,
  };
  return <QuestionSearchAndSelectableAreaContext.Provider value={contextValue} children={props.children} />;
};
