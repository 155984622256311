import { useTranslation } from "@hireroo/i18n";
import CircleIcon from "@mui/icons-material/Circle";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import RestrictableTab, { RestrictableTabProps, TabItem } from "../../modules/RestrictableTab/RestrictableTab";
import { AssessmentShareSettingDialogProps, AssessmentSummaryProps } from "../../ms-components/Assessment";
import AssessmentDetailPageHeader, {
  AssessmentDetailPageHeaderProps,
} from "../../ms-components/Assessment/AssessmentDetailPageHeader/AssessmentDetailPageHeader";
import AssessmentShareSettingDialog from "../../ms-components/Assessment/AssessmentShareSettingDialog/AssessmentShareSettingDialog";
import AssessmentSummary from "../../ms-components/Assessment/AssessmentSummary/AssessmentSummary";
import ExamRetriedSelector, { ExamRetriedSelectorProps } from "../../ms-components/Assessment/ExamRetriedSelector/ExamRetriedSelector";
import BaseDialog, { BaseDialogProps } from "../../primitive/BaseDialog/BaseDialog";

type Status = "ACTIVATED" | "DEACTIVATED";

const PAGE_HEADER_HEIGHT = 48;

const StyledAssessmentDetailPageHeader = styled(AssessmentDetailPageHeader)`
  height: ${PAGE_HEADER_HEIGHT}px;
`;
/**
 * 72px was calculated from the non-content height of EmployeeLayout
 */
const StyledBox = styled(Box)`
  height: calc(100vh - 72px);
  overflow: hidden;
`;

const ContentWrapper = styled(Box)(() => ({
  overflowY: "auto",
  height: `calc(100% - ${PAGE_HEADER_HEIGHT}px)`,
}));

export type AssessmentDetailForEmployeeProps = {
  detailHeaderPage: AssessmentDetailPageHeaderProps;
  defaultTabId?: string;
  status: Status;
  title: string;
  items: TabItem[];
  summary: AssessmentSummaryProps;
  retriedSelector?: ExamRetriedSelectorProps;
  Prompt?: React.ReactNode;
  settingDialog: AssessmentShareSettingDialogProps;
  deleteDialog: BaseDialogProps;
  activateDialog: BaseDialogProps;
  announcementMessage?: string;
};

const AssessmentDetailForEmployee: React.FC<AssessmentDetailForEmployeeProps> = props => {
  const { t } = useTranslation();
  const restrictableTabProps: RestrictableTabProps = {
    defaultTab: props.defaultTabId,
    items: props.items,
    tabs: {
      "aria-label": "test-report-tabs",
    },
    tabBox: {
      sx: {
        paddingLeft: "24px",
        paddingRight: "24px",
      },
    },
    variant: "OUTLINED",
    RightSideAdditional: props.retriedSelector && (
      <Box>
        <ExamRetriedSelector {...props.retriedSelector} />
      </Box>
    ),
  };
  const statusMap = {
    ACTIVATED: t("実施中"),
    DEACTIVATED: t("停止中"),
  } satisfies Record<Status, string>;
  const colorStatusMap = {
    ACTIVATED: "success",
    DEACTIVATED: "error",
  } satisfies Record<Status, "success" | "error">;

  const deleteDialog: BaseDialogProps = {
    ...props.deleteDialog,
    disableEnter: true,
    yesButton: {
      ...props.deleteDialog.yesButton,
      children: t("削除"),
    },
    noButton: {
      ...props.deleteDialog.noButton,
      children: t("キャンセル"),
    },
  };

  const activateDialog: BaseDialogProps = {
    ...props.activateDialog,
    disableEnter: true,
    noButton: {
      ...props.deleteDialog.noButton,
      children: t("キャンセル"),
    },
  };

  return (
    <StyledBox>
      <StyledAssessmentDetailPageHeader {...props.detailHeaderPage} />
      {/* This HTML ID is used to get the element to SCROLL. If you want to change this ID, please search the whole area once. */}
      <ContentWrapper id="report-content-wrapper">
        <Box p={3}>
          <Stack direction="row" mb={2} justifyContent="space-between">
            <Typography variant="h5" fontWeight="bold" noWrap>
              {props.title}
            </Typography>
            <Stack direction="row" display="flex" alignItems="center">
              <CircleIcon color={colorStatusMap[props.status]} height={8} width={8} fontSize="inherit" />
              <Typography ml={1} variant="body2" noWrap>
                {statusMap[props.status]}
              </Typography>
            </Stack>
          </Stack>
          {props.announcementMessage && (
            <Box mb={2}>
              <Alert variant="outlined" severity="info">
                {props.announcementMessage}
              </Alert>
            </Box>
          )}
          <AssessmentSummary {...props.summary} />
        </Box>
        <RestrictableTab {...restrictableTabProps} />
      </ContentWrapper>
      {props.Prompt}
      <AssessmentShareSettingDialog {...props.settingDialog} />
      <BaseDialog {...deleteDialog} />
      <BaseDialog {...activateDialog} />
    </StyledBox>
  );
};

AssessmentDetailForEmployee.displayName = "AssessmentDetailForEmployee";

export default AssessmentDetailForEmployee;
