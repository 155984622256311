import { useTranslation } from "@hireroo/i18n";
import { FieldValidator } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";

import ToggleResourceField, { ToggleResourceFieldProps } from "../../../modules/ToggleResourceField/ToggleResourceField";
import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";
import InputControlTextField, { InputControlTextFieldProps } from "../../../primitive/InputControl/InputControlTextField/InputControlTextField";

type DialogController = {
  setLoading: (loading: boolean) => void;
  close: () => void;
};

export type OwnerEmailAddressDialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (fields: FieldValidator.EmailOptionalSchema, controller: DialogController) => void;
  defaultValues?: FieldValidator.EmailOptionalSchema;
  emptyDefaultOwnerEmail: string;
};

const OwnerEmailAddressDialog: React.FC<OwnerEmailAddressDialogProps> = props => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const validateSchema = FieldValidator.useEmailOptional();
  const methods = useForm<FieldValidator.EmailOptionalSchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: props.defaultValues ?? {
      value: null,
    },
  });
  const dialog: DialogWithCloseIconProps = {
    open: props.open,
    onClose: props.onClose,
    title: t("候補者に共有する連絡先の編集"),
    noButton: {
      onClick: () => {
        props.onClose();
      },
      color: "secondary",
      children: t("キャンセル"),
      disabled: loading,
    },
    disableEnter: true,
    yesButton: {
      onClick: () => {
        const controller: DialogController = {
          close: () => {
            props.onClose();
            setLoading(false);
          },
          setLoading: (newLoading: boolean) => {
            setLoading(newLoading);
          },
        };
        methods.handleSubmit(fields => {
          props.onSubmit(fields, controller);
        })();
      },
      color: "secondary",
      children: t("保存"),
      disabled: loading,
    },
  };

  const emailInput: InputControlTextFieldProps = {
    color: "secondary",
    fullWidth: true,
    placeholder: "abc@example.com",
  };

  const ownerEmailAddressOptionalResourceField: ToggleResourceFieldProps = {
    toggleKind: "SWITCH",
    label: t("候補者に共有する連絡先"),
    defaultOpen: !!methods.getValues("value"),
    onDiscard: () => {
      methods.setValue("value", null);
    },
    onOpen: () => {
      const ownerEmailAddress = methods.getValues("value");
      if (!ownerEmailAddress) {
        methods.setValue("value", props.emptyDefaultOwnerEmail);
      }
    },
  };
  return (
    <DialogWithCloseIcon {...dialog}>
      <FormProvider key={`dialog-open-${open}`} {...methods}>
        <Typography fontSize={14} pb={1.5} color="textSecondary">
          {t("候補者に送られるメールに記載されます。")}
        </Typography>
        <ToggleResourceField {...ownerEmailAddressOptionalResourceField}>
          <InputControlTextField name="value" {...emailInput} />
        </ToggleResourceField>
      </FormProvider>
    </DialogWithCloseIcon>
  );
};

OwnerEmailAddressDialog.displayName = "OwnerEmailAddressDialog";

export default OwnerEmailAddressDialog;
