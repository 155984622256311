import { useTranslation } from "@hireroo/i18n";
import EditOutlined from "@mui/icons-material/EditOutlined";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import CustomScoreAllocation, { CustomScoreAllocationProps } from "../../../../modules/CustomScoreAllocation/CustomScoreAllocation";
import QuestionOverviewTable, {
  QuestionOverviewTableProps,
} from "../../../../ms-components/Question/QuestionOverviewTable/QuestionOverviewTable";
import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "../../../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";
import InviteInfo, { InviteInfoProps } from "../InviteInfo/InviteInfo";
import ReportInfo, { ReportInfoProps } from "../ReportInfo/ReportInfo";
import ScreeningInfo, { ScreeningInfoProps } from "../ScreeningInfo/ScreeningInfo";
import Summary, { SummaryProps } from "../Summary/Summary";

const StyledStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  overflow: "auto",
}));

const StyledBox = styled(Box)(({ theme }) => ({
  borderRadius: "16px",
  backgroundColor: theme.palette["Background/Paper"].p2,
  padding: theme.spacing(2),
}));

export type ScreeningOverviewProps = {
  statusMessage?: {
    title: string;
    description: string;
    action: {
      onClick: () => void;
    };
  };
  summary: SummaryProps;
  screeningInfo: ScreeningInfoProps;
  reportInfo: ReportInfoProps;
  inviteInfo: InviteInfoProps;
  questionCount: number;
  questionOverview: QuestionOverviewTableProps;
  customScoreAllocation: CustomScoreAllocationProps;
  customScoreEditButton: Pick<IconButtonWithTooltipProps, "onClick">;
};

const ScreeningOverview: React.FC<ScreeningOverviewProps> = props => {
  const { t } = useTranslation();
  const button: ButtonProps = {
    children: t("設定"),
    variant: "text",
    size: "small",
  };
  const customScoreEditButton: IconButtonWithTooltipProps = {
    ...props.customScoreEditButton,
    children: <EditOutlined fontSize="small" sx={{ width: "18px", height: "18px" }} />,
    title: t("カスタムスコアを編集"),
  };
  return (
    <StyledStack direction="column" p={3} spacing={2}>
      {props.statusMessage && (
        <Alert variant="outlined" severity="error" action={<Button {...button} onClick={props.statusMessage.action.onClick} />}>
          <Typography color="inherit" fontWeight={700}>
            {props.statusMessage.title}
          </Typography>
          <Typography color="inherit" fontSize={14}>
            {props.statusMessage.description}
          </Typography>
        </Alert>
      )}
      <Summary {...props.summary} />
      <ScreeningInfo {...props.screeningInfo} />
      <ReportInfo {...props.reportInfo} />
      <InviteInfo {...props.inviteInfo} />
      <Box>
        <Stack direction="row" mb={2} spacing={1} alignItems="center">
          <Typography fontSize={16} sx={{ fontWeight: "bold" }}>
            {t("出題する問題とカスタムスコア")}
          </Typography>
          {props.questionCount > 0 && <IconButtonWithTooltip {...customScoreEditButton} />}
        </Stack>
        <StyledBox>
          {props.questionCount === 0 && (
            <Typography color="text.secondary" variant="body2" sx={{ textAlign: "center" }}>
              {t("出題可能な問題が登録されていません")}
            </Typography>
          )}
          {props.questionCount > 0 && (
            <Stack spacing={2}>
              <CustomScoreAllocation {...props.customScoreAllocation} />
              <QuestionOverviewTable {...props.questionOverview} />
            </Stack>
          )}
        </StyledBox>
      </Box>
    </StyledStack>
  );
};

ScreeningOverview.displayName = "ScreeningOverview";

export default ScreeningOverview;
