import { Fields } from "@hireroo/validator";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel, { FormControlLabelProps } from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

type MultiChoiceOption = {
  value: string;
  displayName: string;
  hasIndeterminate?: boolean;
};

export type MultiChoiceFieldProps = {
  options: MultiChoiceOption[];
};

const MultiChoiceField: React.FC<MultiChoiceFieldProps> = props => {
  const { options } = props;
  const methods = useFormContext<Fields.TagField.BulkEditTagsSchema>();
  const tags = useFieldArray({
    name: "tags",
    control: methods.control,
  });
  return (
    <Stack>
      <FormGroup>
        {options.map((option, index) => {
          const checked = tags.fields.findIndex(tag => tag.name === option.value && tag.status === "CHECKED") !== -1;
          const indeterminate = tags.fields.findIndex(tag => tag.name === option.value && tag.status === "INDETERMINATE") !== -1;
          const handleClick = () => {
            const tag = tags.fields.find(tag => tag.name === option.value);
            const tagIndex = tags.fields.findIndex(tag => tag.name === option.value);
            if (!tag) {
              tags.append({
                name: option.value,
                status: option.hasIndeterminate ? "INDETERMINATE" : "CHECKED",
              });
              return;
            }
            switch (tag.status) {
              case "CHECKED":
                /**
                 * option which has indeterminate status should be tracked as unchecked to send delete request
                 */
                option.hasIndeterminate
                  ? tags.update(tagIndex, {
                      name: option.value,
                      status: "UNCHECKED",
                    })
                  : tags.remove(tagIndex);
                break;
              case "INDETERMINATE":
                tags.update(tagIndex, {
                  name: option.value,
                  status: "CHECKED",
                });
                break;
              case "UNCHECKED":
                tags.update(tagIndex, {
                  name: option.value,
                  status: "INDETERMINATE",
                });
                break;
              default:
                throw new Error(`unknown status ${tag.status satisfies never}`);
            }
          };
          const formControlLabelProps: FormControlLabelProps = {
            control: <Checkbox onClick={handleClick} color="secondary" checked={checked} indeterminate={indeterminate} />,
            label: (
              <Box component="span" display="flex">
                <Typography variant="body2">{option.displayName}</Typography>
              </Box>
            ),
            value: option.value,
          };
          return <FormControlLabel key={`${option.value}-${index}`} {...formControlLabelProps} />;
        })}
      </FormGroup>
    </Stack>
  );
};

MultiChoiceField.displayName = "MultiChoiceField";

export default MultiChoiceField;
