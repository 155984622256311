import { translationLanguageMap } from "@hireroo/app-definition";
import { INITIAL_VERSION } from "@hireroo/app-definition/question";
import { createAnswersInput } from "@hireroo/app-helper/algorithm";
import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { useQuestionForListCacheStorage } from "@hireroo/app-helper/question";
import { Auth } from "@hireroo/app-store/essential/employee";
import { AlgorithmResourceEditor } from "@hireroo/app-store/view-domain/AlgorithmResourceEditor";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { SupportLanguageValue, useLanguageCode, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { Pages, Widget } from "@hireroo/presentation/legacy";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import { AlgorithmHintForm } from "@hireroo/validator";
import * as Sentry from "@sentry/browser";
import * as React from "react";
import { SubmitHandler, useFieldArray } from "react-hook-form";
import { v4 as uuid } from "uuid";

import { extractQuestionForList } from "./privateHelper";

type HintsProps = Pages.AlgorithmResourceEditorProps["hints"];
type HintTabItems = Widget.AlgorithmHintsFormProps["hintTabs"]["tabItems"];

type Hints = {
  props: HintsProps;
  saveButton: Pages.AlgorithmResourceEditorProps["saveHintsButton"];
  onDraftSave: () => void;
};

type UseGenerateHintsPropsArgs = {
  companyId: number;
};

export const useGenerateHintsProps = (args: UseGenerateHintsPropsArgs): Hints => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const lang = useLanguageCode();
  const { methods, selectedHintIndex, updateSelectedHintIndex } = Widget.useAlgorithmHintsFormContext();
  const question = AlgorithmResourceEditor.useQuestion();
  const client = getGraphqlClient();
  const userId = Auth.useCurrentUid();
  const navigate = useTransitionNavigate();
  const questionRepository = useQuestionForListCacheStorage();

  const [open, setOpen] = React.useState<boolean>(false);
  const [currentLanguageTabIndex, setCurrentLanguageTabIndex] = React.useState<string>("0");
  const [submitStatus, setSubmitStatus] = React.useState<"READY" | "LOADING">("READY");

  const isValidVersion = React.useCallback(
    (newVersion: string): boolean => {
      if (!newVersion) return false;
      if (question && newVersion === question.version && question.version.split("-").length === 1) return true;

      //TODO: replace using semver
      let oldVersion = question?.version ?? INITIAL_VERSION;
      oldVersion = oldVersion.split("-")[0];
      oldVersion = oldVersion.replace("v", "");
      const splitOldVersion = oldVersion.split(".");
      if (splitOldVersion.length !== 3) return false;
      const [oldMajor, oldMinor, oldPatch] = splitOldVersion;

      newVersion = newVersion.replace("v", "");
      const splitNewVersion = newVersion.split(".");
      if (splitNewVersion.length !== 3) return false;
      const [newMajor, newMinor, newPath] = splitNewVersion;

      if (Number(oldMajor) > Number(newMajor)) {
        return false;
      } else if (Number(oldMajor) === Number(newMajor)) {
        if (Number(oldMinor) > Number(oldMinor)) {
          return false;
        } else if (Number(oldMinor) === Number(newMinor)) {
          if (Number(oldPatch) >= Number(newPath)) {
            return false;
          }
        }
      }
      return true;
    },
    [question],
  );

  const handleSave: (isDraft: boolean) => SubmitHandler<AlgorithmHintForm.AlgorithmHintsFormSchema> = React.useCallback(
    isDraft => fields => {
      if (question) {
        setSubmitStatus("LOADING");
        if (!isDraft) {
          const isValid = isValidVersion(fields.newVersion);
          if (!isValid) {
            const preVersion = question.version.split("-")[0];
            methods.setError("newVersion", { type: "custom", message: t2("VersionErrorText", { preVersion: preVersion }) });
            setSubmitStatus("READY");
            return;
          }
        }

        client
          .RequestReviewAlgorithmQuestionForAlgorithmResourceEditor({
            requestReviewQuestionInput: {
              questionSlug: question.slug,
              questionVersion: question.version,
            },
          })
          .then(() => {
            client
              .UpdateAlgorithmQuestion({
                updateQuestionInput: {
                  questionId: question.questionId,
                  oldVersion: question.version,
                  newVersion: isDraft ? question.version : fields.newVersion,
                  companyId: args.companyId,
                  employeeId: userId,
                  variant: question.variant,
                  difficulty: question.difficulty,
                  timeLimitMin: (question?.timeLimitSeconds || 1800) / 60,
                  isPrivate: question.isPrivate,
                  status: isDraft ? "DRAFT" : "REVIEWING",
                  titleJa: question.titleJa,
                  titleEn: question.titleEn,
                  descriptionJa: question.descriptionJa,
                  descriptionEn: question.descriptionEn,
                  correctnessTestCase: question.correctnessTestCase,
                  performanceTestCase: question.performanceTestCase,
                  correctnessTestCaseIds: null,
                  performanceTestCaseIds: null,
                  performanceTimeLimitMilliSeconds: question.performanceTimeLimitMilliSeconds,
                  signature: question.signature,
                  answers: createAnswersInput(question.answers),
                  hints: fields.hints.map((hint, index) => {
                    const content = {
                      descriptionEn: "",
                      descriptionJa: "",
                    };
                    hint.contents.forEach(c => {
                      if (c.language === "ja") {
                        content.descriptionJa = c.description;
                      }

                      if (c.language === "en") {
                        content.descriptionEn = c.description;
                      }
                    });
                    return {
                      id: hint.id ?? 0,
                      // TODO:
                      //  @poster-keisuke Enable users to specify this id when design renewal of question creation page.
                      //  Until then we fill this value with a random hash.
                      uniqueId: uuid(),
                      questionId: question.questionId,
                      questionVersion: question.version,
                      descriptionJa: content.descriptionJa,
                      descriptionEn: content.descriptionEn,
                      orderNum: index,
                    };
                  }),
                },
              })
              .then(res => {
                navigate("/e/questions");
                if (res.updateAlgorithmQuestion) {
                  // set session storage question for question list page
                  questionRepository.set(extractQuestionForList(res.updateAlgorithmQuestion));
                }
                AlgorithmResourceEditor.clear();
                Snackbar.notify({ severity: "success", message: t("問題の保存が完了しました。") });
              });
          })
          .catch(error => {
            Sentry.captureException(error);
            const errorNotification = ErrorHandlingHelper.generateErrorNotification(
              error,
              t("問題の一時保存に失敗しました。しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
            );
            Snackbar.notify({
              severity: "error",
              message: errorNotification.message,
            });
          })
          .finally(() => {
            setSubmitStatus("READY");
          });
      }
    },
    [args.companyId, client, isValidVersion, methods, navigate, question, questionRepository, t, t2, userId],
  );
  const hintsField = useFieldArray({
    control: methods.control,
    name: "hints",
  });

  const contentsField = useFieldArray({
    control: methods.control,
    name: `hints.${Number(selectedHintIndex)}.contents`,
  });
  const contents = methods.watch(`hints.${Number(selectedHintIndex)}.contents`);

  const addHint = React.useCallback(async () => {
    const tabIndex = Number(selectedHintIndex);
    const isValid = await methods.trigger([`hints.${tabIndex}.contents`]);

    if (isValid) {
      hintsField.append({
        contents: [{ description: "", language: lang }],
      });
      updateSelectedHintIndex(hintsField.fields.length.toString());
    }
  }, [hintsField, lang, methods, selectedHintIndex, updateSelectedHintIndex]);

  const translateHint = React.useCallback(
    async (hint: AlgorithmHintForm.HintsContentSchema, to: SupportLanguageValue): Promise<AlgorithmHintForm.HintsContentSchema> => {
      const res = await client
        .TranslateMarkdownForAlgorithmResourceEditor({
          source: {
            from: translationLanguageMap[hint?.language],
            to: translationLanguageMap[to],
            body: hint?.description,
          },
        })
        .then(res => {
          Snackbar.notify({
            severity: "success",
            message: t("自動翻訳に成功しました。"),
          });
          return res;
        })
        .catch(error => {
          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(
            error,
            t("自動翻訳に失敗しました。しばらくしてから再度お試しください。"),
          );
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
          return undefined;
        });
      return {
        description: res?.translateMarkdown ?? "",
        language: to,
      };
    },
    [client, t],
  );

  const generateLanguageItems = React.useCallback(
    (hintIndex: number): HintTabItems[0]["editLanguageTab"]["tabMenu"]["items"] => {
      const selectedLanguageSet = new Set(contents ? contents.map(c => c.language) : []);
      return [
        {
          text: "日本語",
          value: "ja",
          onClick: async () => {
            const hint = methods.getValues(`hints.${hintIndex}.contents.${Number(currentLanguageTabIndex)}`);
            const translated = await translateHint(hint, "ja");
            contentsField.append(translated);
          },
        },
        {
          text: "English",
          value: "en",
          onClick: async () => {
            const hint = methods.getValues(`hints.${hintIndex}.contents.${Number(currentLanguageTabIndex)}`);
            const translated = await translateHint(hint, "en");
            contentsField.append(translated);
          },
        },
      ].filter(item => !selectedLanguageSet.has(item.value as SupportLanguageValue));
    },
    [contents, contentsField, currentLanguageTabIndex, methods, translateHint],
  );

  const tabItems: HintTabItems = hintsField.fields.map((hint, hintIndex) => {
    return {
      id: hintIndex.toString(),
      closableTab: {
        closeButton: {
          onClick: () => {
            updateSelectedHintIndex("0");
            hintsField.remove(hintIndex);
          },
        },
      },
      editLanguageTab: {
        tabMenu: {
          items: generateLanguageItems(hintIndex),
        },
        onTabChange: currentValue => setCurrentLanguageTabIndex(currentValue),
      },
    };
  });

  return {
    props: {
      hintTabs: {
        tabItems: tabItems,
      },
      hintHeader: {
        addButton: {
          onClick: addHint,
        },
      },
      versionDialog: {
        open: open,
        closeButton: {
          onClick: () => setOpen(false),
          disabled: submitStatus === "LOADING",
        },
        saveButton: {
          onClick: async () => {
            const isValid = await methods.trigger([`hints`]);
            if (!isValid) return;

            await methods.handleSubmit(handleSave(false), () => setSubmitStatus("READY"))();
          },
          loading: submitStatus === "LOADING",
          disabled: submitStatus === "LOADING",
        },
        oldVersion: question?.version ?? INITIAL_VERSION,
      },
    },
    saveButton: {
      onClick: () => {
        setOpen(true);
      },
      loading: submitStatus === "LOADING",
    },
    onDraftSave: () => {
      methods.handleSubmit(handleSave(true), () => setSubmitStatus("READY"))();
    },
  };
};
