import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Cropper, { Area, Point } from "react-easy-crop";

import BaseDialog, { BaseDialogProps } from "../../../../primitive/BaseDialog/BaseDialog";

const CropContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  width: 500,
  height: 500,
  backgroundColor: theme.palette.grey["A700"],
}));

export type ImageCropDialogProps = {
  originalImage?: string;
  yesButton: BaseDialogProps["yesButton"];
  noButton: BaseDialogProps["noButton"];
  onCropComplete: (croppedAreaPixels: Area) => void;
};

const ImageCropDialog: React.FC<ImageCropDialogProps> = props => {
  const { t } = useTranslation();
  const [crop, setCrop] = React.useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState<number>(1);
  const { onCropComplete } = props;

  const handleCropComplete = React.useCallback(
    (_croppedArea: Area, croppedAreaPixels: Area) => {
      onCropComplete(croppedAreaPixels);
    },
    [onCropComplete],
  );

  return (
    <BaseDialog
      title={t("領域の選択")}
      open={props.originalImage !== undefined}
      yesButton={{
        ...props.yesButton,
        children: t("確定"),
      }}
      noButton={{
        ...props.noButton,
        children: t("キャンセル"),
      }}
    >
      <CropContainer>
        <Cropper
          image={props.originalImage}
          crop={crop}
          zoom={zoom}
          aspect={1}
          onCropChange={setCrop}
          onCropComplete={handleCropComplete}
          onZoomChange={setZoom}
        />
      </CropContainer>
      <Box mt={2}>
        <Typography variant="caption">
          ※ {t("ドラッグでお好みの画像位置を選択できます。画像の拡大縮小はマウスホイールで操作可能です。")}
        </Typography>
      </Box>
    </BaseDialog>
  );
};

ImageCropDialog.displayName = "ImageCropDialog";

export default ImageCropDialog;
