import { proxy } from "valtio";
import { proxyMap } from "valtio/utils";

import type * as Types from "./types";

export const state = proxy<Types.State>({
  currentTab: null,
  assessment: null,
  dialogStatus: "CLOSE",
  selectedExamId: null,
  popperStatus: "CLOSE",
  examMap: proxyMap(),
  bestExams: [],
  listBestExamsCount: 0,
  listBestHasNext: false,
  listBestOffset: 0,
});
