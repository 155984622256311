/* eslint-disable react/display-name */
import { useLanguageCode, useTranslationWithVariable } from "@hireroo/i18n";
import { useTheme } from "@mui/material/styles";
import type { LabelProps } from "recharts/types";
import type { CartesianViewBox } from "recharts/types/util/types";

export type CustomLabelWithRankArgs = {
  kind: "WITH_RANK";
  rank: {
    rank: number;
    numSubset: number;
  };
};

export const useCustomLabelWithRank = () => {
  const theme = useTheme();
  const lang = useLanguageCode();
  const { t } = useTranslationWithVariable();

  return (args: CustomLabelWithRankArgs) => (props: LabelProps) => {
    const castViewBox = props.viewBox as CartesianViewBox;
    const viewBox = { x: castViewBox.x ?? 0, y: castViewBox.y ?? 0 };
    return (
      <g>
        <rect
          x={lang === "ja" ? viewBox.x - 55 : viewBox.x - 40}
          y={viewBox.y - 60}
          fill={theme.palette.common.white}
          width={lang === "ja" ? 110 : 120}
          height={50}
          rx={10}
        />
        <text
          x={lang === "ja" ? viewBox.x - 80 : viewBox.x - 65}
          y={viewBox.y - 60}
          fill={theme.palette.common.black}
          dy={20}
          dx={30}
          fontSize={14}
        >
          {t("Rank", { rank: args.rank.rank, subset: args.rank.numSubset })}
        </text>
        <text
          x={lang === "ja" ? viewBox.x - 80 : viewBox.x - 65}
          y={viewBox.y - 40}
          fill={theme.palette.common.black}
          dy={20}
          dx={30}
          fontSize={14}
        >
          {t("TopPercentile", { topPercentile: ((args.rank.rank * 100) / args.rank.numSubset).toFixed(1) })}
        </text>
        <path
          d={`M${viewBox.x} ${viewBox.y - 1} L${viewBox.x - 4} ${viewBox.y - 10} L${viewBox.x + 4} ${viewBox.y - 10} Z`}
          fill={theme.palette.common.white}
        ></path>
      </g>
    );
  };
};
