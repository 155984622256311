export const formatJson = (text: string, defaultValue: string): string => {
  try {
    const json = JSON.parse(text);
    const map = new Map(Object.entries(json).sort());
    return JSON.stringify(Object.fromEntries(map), null, 2);
  } catch (_error) {
    return defaultValue;
  }
};

export const prettyJsonString = (jsonString: string, defaultValue: string) => {
  try {
    return JSON.stringify(JSON.parse(jsonString), null, 2);
  } catch (_error) {
    return defaultValue;
  }
};
