import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type SampleUploadContentContainerProps = {};

/**
 * TODO このコンポーネントはファイルアップロードの参考実装用のコンポーネントのため、一つでもファイルアップロードの実装ができたら削除する
 */
const SampleUploadContentContainer: React.FC<SampleUploadContentContainerProps> = () => {
  const sampleUploadContentProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.SampleUploadContent {...sampleUploadContentProps} />
    </ErrorBoundary>
  );
};

SampleUploadContentContainer.displayName = "SampleUploadContentContainer";

export default withErrorBoundary(SampleUploadContentContainer, {});
