import { useSnapshot } from "valtio";

import { state } from "./State";
import * as Types from "./types";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useDialogStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.dialogStatus;
};

export const useMetricsGroupIdsForPagination = () => {
  const snapshot = useSnapshotState();
  const { page, size } = snapshot.pagination;
  const metricsGroupsIds = snapshot.metricGroupsIds;
  return metricsGroupsIds.slice(page * size, (page + 1) * size);
};

export const useMetricsGroups = () => {
  const ids = useMetricsGroupIdsForPagination();
  const snapshot = useSnapshotState();

  return ids.reduce<Types.MetricGroup[]>((all, id) => {
    const metricGroup = snapshot.metricGroupsMap.get(id);
    if (!metricGroup) return all;
    return all.concat(metricGroup);
  }, []);
};

export const useSelectedMetricGroupIds = () => {
  const snapshot = useSnapshotState();
  return snapshot.selectedItemIds;
};

export const useSelectedMetricGroups = () => {
  const snapshot = useSnapshotState();
  return snapshot.selectedItemIds.reduce<Types.MetricGroup[]>((all, id) => {
    const metricGroup = snapshot.metricGroupsMap.get(id);
    if (!metricGroup) return all;
    return all.concat(metricGroup);
  }, []);
};

export const usePagination = () => {
  const snapshot = useSnapshotState();
  return snapshot.pagination;
};

export const useNextPager = () => {
  const snapshot = useSnapshotState();
  return snapshot.pagination.nextPager;
};
