import { usePlanNameMap } from "@hireroo/app-definition/payment";
import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Company, Payment } from "@hireroo/app-store/essential/employee";
import { PaymentContractCreateForm } from "@hireroo/app-store/widget/e/PaymentContractCreateForm";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { formatPrice } from "@hireroo/formatter/money";
import { unixToDatetimeFormat, yearMonthFormat } from "@hireroo/formatter/time";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { getLanguage, getUpperCaseLanguage, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import type { PaymentForm } from "@hireroo/validator";
import * as Sentry from "@sentry/browser";
import { CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import type * as stripeJs from "@stripe/stripe-js";
import addMonths from "date-fns/addMonths";
import differenceInMonths from "date-fns/differenceInMonths";
import * as React from "react";

import { useGenerateRowPropsList } from "../../../../../../props-factory/v2/e/PaymentContractUpdateFormProps/useGenerateRowPropsList";
import { usePayNextMonthConfirmSectionProps } from "../privateHelper";

type ItemMap = Exclude<Widget.PaymentContractCreateFormProps["selectNumberOfSelectionField"], undefined>["items"];

type CouponFieldProps = Exclude<Widget.PaymentContractCreateFormProps["couponField"], undefined>;

export type GeneratePaymentContractCreateFormPropsArgs = {};

export const useGenerateProps = (_args: GeneratePaymentContractCreateFormPropsArgs): Widget.PaymentContractCreateFormProps => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const accountTypeDisplayTextMap = Payment.useAccountTypeDisplayTextMap();
  const plans = PaymentContractCreateForm.usePlans();
  const customer = PaymentContractCreateForm.useCustomer();
  const optionalSubmitValue = PaymentContractCreateForm.useOptionalSubmitValue();
  const company = Company.useStrictActiveCompany();
  const navigate = useTransitionNavigate();
  const lang = getLanguage();
  const client = getGraphqlClient();
  const stripe = useStripe();
  const elements = useElements();
  const selectionItemsMap = PaymentContractCreateForm.useBuyAbleSelectionItemMap();
  const buyAbleInterviews = PaymentContractCreateForm.useBuyAbleInterviews();
  const temporarySelectionItems = PaymentContractCreateForm.useTemporarySelectionItems();
  const temporaryPaymentPeriod = PaymentContractCreateForm.useTemporaryPaymentPeriod();
  const selectedTemporaryPlan = PaymentContractCreateForm.useTemporarySelectedPlan();
  const appliedCoupons = PaymentContractCreateForm.useAppliedCoupons();
  const confirmSection = usePayNextMonthConfirmSectionProps();
  const planNameMap = usePlanNameMap(customer.subscription.plan.generation);
  const [loading, setLoading] = React.useState(false);

  const itemMap = buyAbleInterviews.reduce<ItemMap>((all, interview) => {
    const selectionItem = temporarySelectionItems.find(item => item.itemId === interview.interviewId.toString());
    const item: ItemMap[""] = {
      fieldLabel: t2("nSelections", { num: interview.quantity }),
      price: interview.price,
      priceLabel: formatPrice(interview.price * interview.quantity),
      unitPriceLabel: [t("単価"), t2("unitPriceForSelection", { value: formatPrice(interview.price) })].join(": "),
      totalPriceLabel: formatPrice(interview.price * interview.quantity * (selectionItem?.amount ?? 0)),
    };
    return {
      ...all,
      [interview.interviewId]: item,
    };
  }, {});

  const language = React.useMemo(() => {
    if (customer.language === "UNKNOWN") {
      return getUpperCaseLanguage();
    }
    return customer.language;
  }, [customer.language]);

  const head = React.useMemo((): Widget.PaymentContractCreateFormProps["planSelectionField"]["head"] => {
    const items = plans.map((plan): Widget.PaymentContractCreateFormProps["planSelectionField"]["head"]["items"][0] => {
      const usingPlan = customer.subscription.plan.planType === plan.planType;
      const price = ((): number => {
        if (temporaryPaymentPeriod === "ONE_MONTHLY") {
          return plan.price;
        }
        return plan.price * plan.term;
      })();
      return {
        kind: (() => {
          if (customer.isTrial && usingPlan) {
            return "TRIAL";
          } else if (usingPlan) {
            return "SELECTED";
          }
          return "NONE";
        })(),
        planName: planNameMap[plan.planType],
        price:
          plan.planType !== "ENTERPRISE"
            ? {
                kind: temporaryPaymentPeriod === "ONE_MONTHLY" ? "MONTHLY" : "YEARLY",
                value: formatPrice(price),
              }
            : {
                kind: "ESTIMATE",
              },
        action:
          plan.planType !== "ENTERPRISE"
            ? { kind: "RADIO", value: PaymentContractCreateForm.Def.planTypeGraphqlToValidator[plan.planType] }
            : {
                kind: "CONTACT_US",
              },
      };
    });
    return {
      items: items,
    };
  }, [customer, plans, temporaryPaymentPeriod, planNameMap]);

  const rows = useGenerateRowPropsList(plans.map(plan => plan.planType));

  const defaultValues = React.useMemo((): PaymentContractCreateForm.SubmitValue => {
    if (optionalSubmitValue) {
      return {
        ...optionalSubmitValue,
        coupons: optionalSubmitValue.coupons.slice(),
        selectionItems: optionalSubmitValue.selectionItems.slice(),
      };
    }
    return {
      coupons: [],
      plan: PaymentContractCreateForm.Def.planTypeGraphqlToValidator[customer.subscription.plan.planType],
      paymentPeriod: "YEARLY",
      paymentMethod: "CREDIT_CARD",
      selectionItems: buyAbleInterviews.map(interview => {
        return {
          itemId: interview.interviewId.toString(),
          amount: 0,
        };
      }),
      billingInformation: {
        companyName: company.name,
        mailAddress: customer.email,
        postalCode: customer.postalCode,
        state: customer.state,
        city: customer.city,
        line1: customer.line1,
        language: language,
      },
    };
  }, [
    buyAbleInterviews,
    company.name,
    customer.city,
    customer.email,
    customer.line1,
    customer.postalCode,
    customer.state,
    customer.subscription.plan.planType,
    language,
    optionalSubmitValue,
  ]);

  const updateCreditCard = async (
    fields: PaymentForm.PaymentContractCreateForm["billingInformation"],
  ): Promise<stripeJs.PaymentMethodResult | undefined> => {
    const card = elements?.getElement(CardNumberElement);
    if (!card || !stripe) {
      return;
    }
    const res = await stripe.createPaymentMethod({
      type: "card",
      card: card,
      billing_details: {
        address: {
          line1: fields.line1,
          city: fields.city,
          postal_code: fields.postalCode,
          state: fields.state,
          /**
           * Default value is Very Important !
           */
          country: customer.country || "JP",
        },
        email: fields.mailAddress,
        name: fields.companyName,
      },
    });
    return res;
  };

  const calculatedUnitPriceLabel = React.useMemo((): string => {
    const result = temporarySelectionItems.reduce<{ total: number; count: number }>(
      (acc, current) => {
        if (current.amount === 0) {
          return acc;
        }
        const selectionItem = selectionItemsMap[current.itemId];
        if (!selectionItem) {
          return acc;
        }
        return {
          count: acc.count + current.amount * selectionItem.quantity,
          total: acc.total + current.amount * selectionItem.quantity * selectionItem.price,
        };
      },
      { total: 0, count: 0 },
    );
    if (result.count === 0) {
      return "-";
    }
    return formatPrice(Math.round(result.total / result.count));
  }, [temporarySelectionItems, selectionItemsMap]);

  const applyResultsProps = React.useMemo((): CouponFieldProps["applyResults"] => {
    const now = new Date();
    const applyResultsBySubscribedCoupons = customer.subscription.subscribedCoupons.reduce<CouponFieldProps["applyResults"]>(
      (acc, subscribedCoupon) => {
        const { coupon } = subscribedCoupon;
        if (coupon.couponType === "PERCENT_OFF") {
          const billingMonth = addMonths(new Date(), 1);
          const leftValue = differenceInMonths(billingMonth, new Date(subscribedCoupon.createdAtSeconds * 1000));
          const rightValue = differenceInMonths(new Date(subscribedCoupon.expiresAtSeconds * 1000), billingMonth);
          const withinExpiration = 0 <= leftValue && 0 <= rightValue;
          if (!withinExpiration) {
            return acc;
          }
          return acc.concat({
            text: [
              resolveLanguage(coupon, lang, "name"),
              t2("percentDiscountDescription", {
                value: coupon.discountNumber,
                duration: `${unixToDatetimeFormat(subscribedCoupon.createdAtSeconds)} - ${unixToDatetimeFormat(
                  subscribedCoupon.expiresAtSeconds,
                )}`,
              }),
            ].join(" "),
          });
        }
        return acc;
      },
      [],
    );
    return appliedCoupons
      .map((appliedCoupon): Widget.PaymentContractCreateFormProps["couponField"]["applyResults"][0] => {
        const startDate = yearMonthFormat(now);
        const expireDate = yearMonthFormat(addMonths(now, appliedCoupon.durationMonth));
        return {
          text: [
            resolveLanguage(appliedCoupon, lang, "name") + ": ",
            appliedCoupon.couponType === "AMOUNT_OFF" &&
              t2("amountDiscountDescription", {
                value: formatPrice(appliedCoupon.discountNumber),
                duration: `${startDate} - ${expireDate}`,
              }),
            appliedCoupon.couponType === "PERCENT_OFF" &&
              t2("percentDiscountDescription", {
                value: appliedCoupon.discountNumber,
                duration: `${startDate} - ${expireDate}`,
              }),
            appliedCoupon.couponType !== "UNKNOWN" && t2("validDiscountDuration", { month: appliedCoupon.durationMonth }),
          ]
            .filter(Boolean)
            .join(" "),
        };
      })
      .concat(applyResultsBySubscribedCoupons);
  }, [appliedCoupons, t2, lang, customer.subscription.subscribedCoupons]);

  /**
   * When a formal contract is made, the Plan is already selected, so it is set to TemporarySubmitValue.
   */
  React.useEffect(() => {
    PaymentContractCreateForm.setTemporarySubmitValue(defaultValues);
  }, [defaultValues]);

  return {
    confirmButton: {
      disabled: loading || selectedTemporaryPlan?.planType === "ENTERPRISE",
      title: selectedTemporaryPlan?.planType === "ENTERPRISE" ? t("エンタープライズのご契約は直接営業担当までお問い合わせください") : undefined,
    },
    onSubmit: async fields => {
      setLoading(true);
      let res: stripeJs.PaymentMethodResult | undefined;
      if (fields.paymentMethod === "CREDIT_CARD") {
        res = await updateCreditCard(fields.billingInformation);
        if (res?.error) {
          setLoading(false);
          Snackbar.notify({
            severity: "error",
            message: t("入力内容に不備があります。"),
          });
          return;
        } else if (res) {
          PaymentContractCreateForm.setStripJsPaymentMethodResult(res);
        }
      } else {
        PaymentContractCreateForm.setStripJsPaymentMethodResult(null);
      }

      client
        .UpdatePaymentV2CustomerForPaymentMethodUpdateForm({
          input: {
            customerId: customer.customerId,
            email: fields.billingInformation.mailAddress,
            country: customer.country,
            postalCode: fields.billingInformation.postalCode,
            state: fields.billingInformation.state,
            city: fields.billingInformation.city,
            line1: fields.billingInformation.line1,
            line2: "",
            paymentMethod: res?.paymentMethod?.id || customer.paymentMethod,
            lastFour: res?.paymentMethod?.card?.last4 || customer.lastFour,
            language: fields.billingInformation.language,
            paymentType: fields.paymentMethod === "CREDIT_CARD" ? "CARD" : "INVOICE",
          },
        })
        .then(() => {
          Snackbar.notify({
            severity: "success",
            message: t("請求情報の保存が完了しました。"),
          });
          PaymentContractCreateForm.setSubmitValue(fields);
          PaymentContractCreateForm.updateShowingTarget("CONFIRM_AND_TOS");
        })
        .catch(error => {
          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(
            error,
            t("請求情報の更新に失敗しました。しばらくしてから再度お試し頂くか、お問い合わせください。"),
          );
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    onChange: fields => {
      PaymentContractCreateForm.setTemporarySubmitValue(fields);
    },
    planSelectionField: {
      head: head,
      rows: rows,
    },
    billingInformationField: {
      companyName: {
        settingPageLink: {
          children: t("一般情報"),
          href: generatePath("/e/settings/company", {
            queryParams: {
              tab: "general",
            },
          }),
          onClick: () => {
            navigate("/e/settings/company", {
              queryParams: {
                tab: "general",
              },
            });
          },
        },
      },
    },
    couponField: {
      disabled: selectedTemporaryPlan?.planType === "ENTERPRISE",
      onApplyCoupon: coupon => {
        if (!coupon) {
          /** Ignored because it has not been entered */
          return;
        }
        client
          .GetCouponByCodeForPaymentContractCreateForm({
            couponCode: coupon,
          })
          .then(res => {
            if (!res.couponByCode) {
              Snackbar.notify({
                severity: "warning",
                message: t("クーポンが見つかりませんでした。"),
              });
              return;
            }
            const coupon = res.couponByCode;
            const hasSameCoupon = appliedCoupons.find(appliedCoupon => appliedCoupon.couponId === coupon.couponId);
            const hasSameCouponInSubscribedCoupons = customer.subscription.subscribedCoupons.find(
              subscribedCoupon => subscribedCoupon.coupon.couponId === coupon.couponId,
            );
            if (hasSameCoupon || hasSameCouponInSubscribedCoupons) {
              Snackbar.notify({
                severity: "warning",
                message: t("すでに同じクーポンが適用されています"),
              });
              return;
            }
            PaymentContractCreateForm.addCoupon(coupon);
            Snackbar.notify({
              severity: "success",
              message: t("クーポンを適用しました。"),
            });
          })
          .catch(error => {
            Sentry.captureException(error);
            const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("クーポンの取得に失敗しました。"));
            Snackbar.notify({
              severity: "error",
              message: errorNotification.message,
            });
          });
      },
      applyResults: applyResultsProps,
    },
    selectNumberOfSelectionField:
      Object.keys(itemMap).length > 0
        ? {
            items: itemMap,
            shotInterviewPriceLabel: formatPrice(customer.subscription.shotInterview.price),
            calculatedUnitPriceLabel: calculatedUnitPriceLabel,
          }
        : undefined,
    nextMonthPaymentConfirmSection: confirmSection,
    paymentMethod: {
      cardField: {
        disabledEditButton: true,
        lastFour: "",
      },
      bankField: {
        bankName: customer.bankName,
        branchName: `${customer.branchName} (${customer.branchCode})`,
        accountNumber: customer.accountNumber,
        accountHolderName: customer.accountHolderName,
        accountType: accountTypeDisplayTextMap[customer.accountType],
      },
    },
    defaultValues,
  };
};
