import { Pages, Widget } from "@hireroo/presentation";

import SnackbarContainer from "../../widget/shared/Snackbar/Container";
import { useGenerateErrorPanelProps } from "./useGenerateErrorPanelProps";

type GenerateEmptyProps = {};

export const useGenerateProps = (_args: GenerateEmptyProps): Pages.EmptyProps => {
  const errorPanelProps = useGenerateErrorPanelProps({});
  return {
    layout: {
      Bottom: (
        <>
          <SnackbarContainer />
          <Widget.ErrorPanel {...errorPanelProps} />
        </>
      ),
    },
  };
};
