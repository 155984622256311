import { MAX_SCALE, MIN_SCALE } from "../../constants/flowChart";
import { Area, Coordinate, FlowElement } from "../../features";
import { viewboxFromElements } from "../../helpers/flowChart";

export type StaticChartState = {
  viewbox: Area;
  initialWidth: number;
  scale: number;
  scaleChanged: boolean;
  elements: Record<string, FlowElement>;
  elementIds: string[];
};

export const initialState: StaticChartState = {
  viewbox: { minX: 0, minY: 0, maxX: 1000, maxY: 750 },
  initialWidth: 0,
  scale: 1,
  scaleChanged: false,
  elements: {},
  elementIds: [],
};

export type RestoreElementsAction = {
  type: "restoreElements";
  payload: {
    elements: FlowElement[];
    aspectRatio: number;
    viewarea?: Area;
    padding?: number;
    disableAutoResize?: boolean;
  };
};

export type ZoomViewbox = {
  type: "zoomViewbox";
  payload: {
    scale: number;
    focus?: Coordinate;
  };
};

export type PanViewbox = {
  type: "panViewbox";
  payload: {
    dx: number;
    dy: number;
  };
};

export type InitializeViewbox = {
  type: "initializeViewbox";
  payload: Area;
};

export type StaticChartAction = RestoreElementsAction | ZoomViewbox | PanViewbox | InitializeViewbox;

export const reducer: React.Reducer<StaticChartState, StaticChartAction> = (state: StaticChartState, action: StaticChartAction) => {
  let newState;
  switch (action.type) {
    case "restoreElements": {
      const elementIds: string[] = [];
      const elements: Record<string, FlowElement> = {};

      action.payload.elements.forEach(element => {
        elementIds.push(element.id);
        elements[element.id] = element;
      });

      const newViewbox = viewboxFromElements(
        action.payload.elements,
        action.payload.aspectRatio,
        action.payload.viewarea,
        action.payload.padding,
      );

      newState = {
        ...state,
        elementIds,
        elements,
        viewbox: action.payload.disableAutoResize ? state.viewbox : newViewbox,
        initialWidth: action.payload.disableAutoResize ? state.initialWidth : newViewbox.maxX - newViewbox.minX,
      };
      break;
    }
    case "zoomViewbox": {
      if (action.payload.scale === state.scale || action.payload.scale > MAX_SCALE || action.payload.scale < MIN_SCALE) {
        return state;
      }

      const zoomedWidth = state.initialWidth / action.payload.scale;
      const scaleFromCurrent = (state.viewbox.maxX - state.viewbox.minX) / zoomedWidth;

      const focus = action.payload.focus ?? {
        x: (state.viewbox.minX + state.viewbox.maxX) / 2,
        y: (state.viewbox.minY + state.viewbox.maxY) / 2,
      };

      const zoomedMinX = focus.x + (state.viewbox.minX - focus.x) / scaleFromCurrent;
      const zoomedMinY = focus.y + (state.viewbox.minY - focus.y) / scaleFromCurrent;
      const zoomedMaxX = focus.x + (state.viewbox.maxX - focus.x) / scaleFromCurrent;
      const zoomedMaxY = focus.y + (state.viewbox.maxY - focus.y) / scaleFromCurrent;

      newState = {
        ...state,
        viewbox: { minX: zoomedMinX, minY: zoomedMinY, maxX: zoomedMaxX, maxY: zoomedMaxY },
        scale: Math.round(action.payload.scale * 10) / 10,
        scaleChanged: true,
      };
      break;
    }
    case "panViewbox": {
      newState = {
        ...state,
        viewbox: {
          minX: state.viewbox.minX + action.payload.dx,
          minY: state.viewbox.minY + action.payload.dy,
          maxX: state.viewbox.maxX + action.payload.dx,
          maxY: state.viewbox.maxY + action.payload.dy,
        },
        scaleChanged: true,
      };
      break;
    }
    case "initializeViewbox": {
      newState = {
        ...state,
        viewbox: action.payload,
        initialWidth: action.payload.maxX - action.payload.minX,
      };
      break;
    }
  }
  return newState;
};
