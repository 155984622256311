import { AssessmentsIdDetailStore } from "@hireroo/app-store/page/t/assessments_id_detail";
import * as Graphql from "@hireroo/graphql/client/urql";
import * as React from "react";

export type UseFetchListExamsRecursiveArgs = {
  examId: string | null;
};

type Response = {
  isLoading: boolean;
  data?: Graphql.ListExamsRecursivePayloadForAssessmentsIdDetailTalentFragment;
};
export const useFetchListExamsRecursive = (args: UseFetchListExamsRecursiveArgs): Response => {
  const [result] = Graphql.useListExamsRecursiveForAssessmentsIdDetailTalentQuery({
    variables: {
      examId: args.examId || "",
    },
    pause: args.examId === null || args.examId.length === 0,
    requestPolicy: "cache-and-network",
  });
  React.useEffect(() => {
    if (result.data) {
      AssessmentsIdDetailStore.addExams(result.data.listExamsRecursive.exams);
    }
  }, [result.data]);

  return {
    isLoading: result.fetching,
    data: result.data?.listExamsRecursive,
  };
};
