import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Tab, { TabProps } from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as React from "react";

import Link from "../../../../primitive/Link/Link";

const HEIGHT = 38;

const StyledSidebarTabs = styled(Tabs)(({ theme }) => ({
  ".MuiTabs-indicator": {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const StyledSidebarLink = styled(Link)(() => ({
  width: "100%",
  padding: "9px 16px",
  display: "block",
  height: HEIGHT,
  color: "inherit",
  textTransform: "none",
}));

const StyledSidebarTab: React.FC<TabProps> = styled(Tab)(({ theme }) => ({
  "&.MuiTab-root": {
    padding: 0,
    minHeight: HEIGHT,
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  "&.Mui-selected": {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette["Secondary/Shades"].p16,
  },
}));

const StyledSidebar = styled(Box)(({ theme }) => ({
  width: 200,
  minWidth: 200,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  borderRadius: 8,
  border: `1px solid ${theme.palette["Secondary/Shades"].p12}`,
}));

type MenuItemProps = {
  id: string;
  label: string;
  href?: string;
  onClick?: () => void;
};

export type SideBarProps = {
  active: string;
  items: MenuItemProps[];
};

const SideBar: React.FC<SideBarProps> = props => {
  const [value, setValue] = React.useState<string>(props.active);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <StyledSidebar>
      <StyledSidebarTabs orientation="vertical" value={value} onChange={handleChange}>
        {props.items.map(item => {
          const tabProps: TabProps = {
            value: item.id,
            label: (
              <StyledSidebarLink href={item.href} onClick={item.onClick} color="inherit" underline="none">
                {item.label}
              </StyledSidebarLink>
            ),
          };
          return <StyledSidebarTab key={item.id} {...tabProps} />;
        })}
      </StyledSidebarTabs>
    </StyledSidebar>
  );
};

SideBar.displayName = "SideBar";

export default SideBar;
