import { type SupportLanguageValue } from "@hireroo/i18n";
import type { QuizQuestionForm } from "@hireroo/validator";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import EvaluationList from "./EvaluationForm/EvaluationList";
import MultiChoiceForm from "./MultiChoiceForm/MultiChoiceForm";
import QuestionDetailField from "./QuestionDetailField";
import SingleChoiceForm from "./SingleChoiceForm/SingleChoiceForm";

export type QuizContentProps = {
  language: SupportLanguageValue;
};

const QuizContent: React.FC<QuizContentProps> = props => {
  const method = useFormContext<QuizQuestionForm.QuizQuestionFormSchema>();
  const variant = method.watch("variant");
  return (
    <Box>
      <Stack spacing={2}>
        <QuestionDetailField language={props.language} />
        {variant === "SINGLE_CHOICE" && <SingleChoiceForm language={props.language} />}
        {variant === "MULTI_CHOICE" && <MultiChoiceForm language={props.language} />}
        {variant === "FREE_TEXT" && <EvaluationList language={props.language} />}
      </Stack>
    </Box>
  );
};

QuizContent.displayName = "QuizContent";

export default QuizContent;
