import { useTranslation } from "@hireroo/i18n";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledStack = styled(Stack)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingRight: theme.spacing(1.5),
  paddingLeft: theme.spacing(1.5),
  backgroundColor: theme.palette.background.paper,
  borderRadius: "8px",
}));

const InputStack = styled(Stack)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingRight: theme.spacing(1.5),
  paddingLeft: theme.spacing(1.5),
  borderRadius: "4px",
  border: "1px solid",
  borderColor: theme.palette.Other.Divider,
}));

const AnswerTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  whiteSpace: "pre-wrap",
  fontSize: 14,
}));

const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: 14,
  whiteSpace: "pre-wrap",
}));

export type CustomFormFilledVariableProps = {
  id: string;
  label: string;
  description: string;
  answerText?: string;
};

const CustomFormFilledVariable: React.FC<CustomFormFilledVariableProps> = props => {
  const { t } = useTranslation();
  return (
    <StyledStack direction="column" spacing={2} width="100%">
      <Stack direction="column" spacing={0.5}>
        <Typography fontSize={14} fontWeight={700}>
          {props.label}
        </Typography>
        <Description>{props.description}</Description>
      </Stack>
      <InputStack>
        {props.answerText ? (
          <AnswerTypography>{props.answerText}</AnswerTypography>
        ) : (
          <Typography color="textSecondary">{t("回答なし")}</Typography>
        )}
      </InputStack>
    </StyledStack>
  );
};

CustomFormFilledVariable.displayName = "CustomFormFilledVariable";

export default CustomFormFilledVariable;
