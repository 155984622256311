import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import * as React from "react";

import EmployeeLayout, { EmployeeLayoutProps } from "../../layouts/EmployeeLayout/EmployeeLayout";

const StyledEmployeeLayout = styled(EmployeeLayout)`
  overflow: hidden;
`;

const Wrapper = styled(Paper)`
  height: calc(100vh - 73px);
`;

export type EmployeeTestReviewProps = {
  layout: EmployeeLayoutProps;
  children: React.ReactNode;
};

const EmployeeTestReview: React.FC<EmployeeTestReviewProps> = props => {
  return (
    <StyledEmployeeLayout {...props.layout}>
      <Wrapper elevation={2}>{props.children}</Wrapper>
    </StyledEmployeeLayout>
  );
};

EmployeeTestReview.displayName = "EmployeeTestReview";

export default EmployeeTestReview;
