import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateEmployeeAssignFieldPropsArgs, useGenerateProps } from "./useGenerateProps";

export type EmployeeAssignFieldContainerProps = GenerateEmployeeAssignFieldPropsArgs;

const EmployeeAssignFieldContainer: React.FC<EmployeeAssignFieldContainerProps> = props => {
  const employeeAssignFieldProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.AssignField {...employeeAssignFieldProps} />
    </ErrorBoundary>
  );
};

EmployeeAssignFieldContainer.displayName = "EmployeeAssignFieldContainer";

export default withErrorBoundary(EmployeeAssignFieldContainer, {});
