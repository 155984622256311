/* eslint-disable no-prototype-builtins */
import { useLanguageCode } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import * as React from "react";
import { useCallback } from "react";
import { useImmer } from "use-immer";

import {
  AdjustPosition,
  CommentElement,
  DeleteElements,
  EdgeElement,
  EditComment,
  ELEMENT_TYPE,
  ElementLabel,
  ElementType,
  FLOW_ACTION,
  FlowAction,
  FlowElement,
  LabelTypeMap,
  MoveElements,
  OPERATION_TYPE,
  OperationType,
  ReconnectEdge,
  ReviveElements,
  Settings,
  ShapeElement,
  UnionSettingsFields,
  UpdateSettings,
} from "../../../features";
import * as Helpers from "../../../helpers/flowChart";
import { useSystemDesignContext } from "../../../store";
import FlowChart, { FlowChartProps } from "../../FlowChart/FlowChart";
import FlowChartSideBar, { FlowChartSideBarProps } from "../../FlowChartSideBar/FlowChartSideBar";
import Splitter, { SplitterProps } from "./parts/Splitter/Splitter";

export type FlowChartPlaygroundProps = {
  flowChartSideBar?: Partial<FlowChartSideBarProps>;
};
const timeInSeconds = (): number => {
  return new Date().getTime();
};

// change if needed
const uid = "dummyUid";
const FlowChartPlayground: React.FC<FlowChartPlaygroundProps> = props => {
  const action =
    (_name: string) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (..._args: any[]) =>
      undefined;
  const lang = useLanguageCode();
  const Store = useSystemDesignContext();
  const componentType = Store.hooks.useComponentType();
  const selectedElementId = Store.hooks.useSelectedElementId();
  const viewbox = Store.hooks.useCachedViewbox();
  const viewboxCenter = React.useMemo(() => {
    return { x: (viewbox.minX + viewbox.maxX) / 2, y: (viewbox.minY + viewbox.maxY) / 2 };
  }, [viewbox.maxX, viewbox.maxY, viewbox.minX, viewbox.minY]);
  const withNeighborEdge = Store.hooks.useWithNeighborEdge();
  const elementFactory = Store.hooks.useElement();
  const [isPlacingElement, setIsPlacingElement] = React.useState<boolean>(false);
  const [placingElementLabel, setPlacingElementLabel] = React.useState<ElementLabel | "text" | undefined>(undefined);

  const [undoStack, setUndoStack] = useImmer<FlowAction[]>([]);
  const [redoStack, setRedoStack] = useImmer<FlowAction[]>([]);

  const addRedoAndUndoStack = useCallback(
    (operationType: OperationType, inverseOperation: FlowAction) => {
      if (operationType === OPERATION_TYPE.do || operationType === OPERATION_TYPE.redo) {
        setUndoStack(prev => {
          return prev.concat(inverseOperation);
        });
      }
      if (operationType === OPERATION_TYPE.undo) {
        setRedoStack(prev => {
          return prev.concat(inverseOperation);
        });
      }
    },
    [setRedoStack, setUndoStack],
  );

  const addElement: FlowChartProps["drawingArea"]["addElement"] = useCallback(
    (
      id: string,
      type: ElementType,
      label: ElementLabel,
      x: number,
      y: number,
      w: number,
      h: number,
      initialSettings: Settings,
      operationType: OperationType,
    ) => {
      const inverseOperation: DeleteElements = {
        s: FLOW_ACTION.deleteElements,
        v: {
          ids: [id],
        },
        a: uid,
        t: timeInSeconds(),
      };
      addRedoAndUndoStack(operationType, inverseOperation);

      Store.action.addElement({
        id: id,
        type: type,
        label: label,
        geometry: {
          minX: x,
          minY: y,
          maxX: x + w,
          maxY: y + h,
        },
        initialSettings: initialSettings,
        timestamp: Date.now(),
      });
    },
    [Store.action, addRedoAndUndoStack],
  );

  const addComment: FlowChartProps["drawingArea"]["addComment"] = useCallback(
    (id: string, content: string, x: number, y: number, fontSize: number, operationType: OperationType) => {
      const inverseOperation: DeleteElements = {
        s: FLOW_ACTION.deleteElements,
        v: {
          ids: [id],
        },
        a: uid,
        t: timeInSeconds(),
      };
      addRedoAndUndoStack(operationType, inverseOperation);
      Store.action.addComment({
        id,
        content,
        geometry: {
          minX: x,
          minY: y,
          maxX: 0,
          maxY: 0,
        },
        fontSize,
        timestamp: Date.now(),
      });
    },
    [Store.action, addRedoAndUndoStack],
  );

  const editComment: FlowChartProps["drawingArea"]["editComment"] = useCallback(
    (id: string, content: string, operationType: OperationType) => {
      Store.action.editComment({
        id,
        content,
        timestamp: Date.now(),
      });
      const comment = elementFactory(id) as CommentElement;
      if (!comment) return;
      const inverseOperation: EditComment = {
        s: FLOW_ACTION.editComment,
        v: {
          id,
          c: comment.content,
        },
        a: uid,
        t: timeInSeconds(),
      };

      addRedoAndUndoStack(operationType, inverseOperation);
    },
    [Store.action, addRedoAndUndoStack, elementFactory],
  );

  const deleteElements: FlowChartProps["drawingArea"]["deleteElements"] = useCallback(
    (ids: string[], operationType: OperationType) => {
      if (ids.length === 0) return;
      const inverseOperation: ReviveElements = {
        s: FLOW_ACTION.reviveElements,
        v: {
          ids: withNeighborEdge(ids),
        },
        a: uid,
        t: timeInSeconds(),
      };
      addRedoAndUndoStack(operationType, inverseOperation);
      Store.action.deleteElements({
        ids: withNeighborEdge(ids),
      });
    },
    [Store.action, addRedoAndUndoStack, withNeighborEdge],
  );

  const reviveElements = useCallback(
    (ids: string[], operationType: OperationType) => {
      if (ids.length === 0) return;
      const inverseOperation: DeleteElements = {
        s: FLOW_ACTION.deleteElements,
        v: {
          ids,
        },
        a: uid,
        t: timeInSeconds(),
      };
      addRedoAndUndoStack(operationType, inverseOperation);
      Store.action.reviveElements({ ids });
    },
    [Store.action, addRedoAndUndoStack],
  );

  const moveElements: FlowChartProps["drawingArea"]["moveElements"] = useCallback(
    (ids: string[], dx: number, dy: number, operationType: OperationType) => {
      if (ids.length === 0) return;
      const inverseOperation: MoveElements = {
        s: FLOW_ACTION.moveElements,
        v: {
          ids,
          dx: -dx,
          dy: -dy,
        },
        a: uid,
        t: timeInSeconds(),
      };
      addRedoAndUndoStack(operationType, inverseOperation);
      // Only the peer's move is reflected via the firebase event since a move operation continuously changes the local state,
      // but redo and undo is the exception, hence update the local state here
      if (operationType === OPERATION_TYPE.undo || operationType === OPERATION_TYPE.redo) {
        Store.action.moveElements({
          ids,
          dx,
          dy,
        });
      }
    },
    [Store.action, addRedoAndUndoStack],
  );

  const shapeElement: FlowChartProps["drawingArea"]["shapeElement"] = useCallback(
    (id: string, position: AdjustPosition, dx: number, dy: number, operationType: OperationType) => {
      const inverseOperation: ShapeElement = {
        s: FLOW_ACTION.shapeElement,
        v: {
          id,
          p: position,
          dx: -dx,
          dy: -dy,
        },
        a: uid,
        t: timeInSeconds(),
      };
      addRedoAndUndoStack(operationType, inverseOperation);
      if (operationType === OPERATION_TYPE.undo || operationType === OPERATION_TYPE.redo) {
        Store.action.shapeElement({
          id,
          adjustPosition: position,
          dx,
          dy,
        });
      }
    },
    [Store.action, addRedoAndUndoStack],
  );

  const connectNodes: FlowChartProps["drawingArea"]["connectNodes"] = useCallback(
    (id: string, source: string, target: string, operationType: OperationType) => {
      const inverseOperation: DeleteElements = {
        s: FLOW_ACTION.deleteElements,
        v: {
          ids: [id],
        },
        a: uid,
        t: timeInSeconds(),
      };
      addRedoAndUndoStack(operationType, inverseOperation);
      Store.action.connectNodes({
        id,
        source,
        target,
        timestamp: Date.now(),
      });
    },
    [Store.action, addRedoAndUndoStack],
  );

  const reconnectEdge: FlowChartProps["drawingArea"]["reconnectEdge"] = useCallback(
    (id: string, source: string, target: string, operationType: OperationType) => {
      const edge = elementFactory(id) as EdgeElement;
      const inverseOperation: ReconnectEdge = {
        s: FLOW_ACTION.reconnectEdge,
        v: {
          id,
          s: edge.source,
          t: edge.target,
        },
        a: uid,
        t: timeInSeconds(),
      };
      addRedoAndUndoStack(operationType, inverseOperation);
      Store.action.reconnectEdge({
        id,
        source,
        target,
        timestamp: Date.now(),
      });
    },
    [Store.action, addRedoAndUndoStack, elementFactory],
  );

  const pasteElements: FlowChartProps["drawingArea"]["pasteElements"] = useCallback(
    (destElements: FlowElement[], operationType: OperationType) => {
      if (destElements.length === 0) return;
      const inverseOperation: DeleteElements = {
        s: FLOW_ACTION.deleteElements,
        v: {
          ids: destElements.map(element => element.id),
        },
        a: uid,
        t: timeInSeconds(),
      };
      addRedoAndUndoStack(operationType, inverseOperation);
      Store.action.pasteElements({
        elements: destElements,
      });
    },
    [Store.action, addRedoAndUndoStack],
  );

  const updateSettings: Exclude<FlowChartProps["elementSettings"], undefined>["updateSettings"] = useCallback(
    (id: string, updates: UnionSettingsFields, operationType: OperationType) => {
      // Just return when there is no update
      if (Object.keys(updates).length === 0) return;
      Store.action.updateSettings({
        id,
        updates,
        timestamp: Date.now(),
      });
      const inverseUpdates = {};
      const element = elementFactory(id);

      if (element) {
        if (element.type === ELEMENT_TYPE.comment) {
          if (updates.hasOwnProperty("fontSize")) Object.assign(inverseUpdates, { fontSize: element.settings.fontSize });
        } else if (element.type === ELEMENT_TYPE.edge) {
          if (updates.hasOwnProperty("direction")) Object.assign(inverseUpdates, { direction: element.settings.direction });
        } else {
          if (updates.name) Object.assign(inverseUpdates, { name: element.settings.name });
        }
      }

      const inverseOperation: UpdateSettings = {
        s: FLOW_ACTION.updateSettings,
        v: {
          id,
          u: inverseUpdates,
        },
        a: uid,
        t: timeInSeconds(),
      };
      addRedoAndUndoStack(operationType, inverseOperation);
    },
    [Store.action, addRedoAndUndoStack, elementFactory],
  );

  const undo = useCallback(() => {
    const history = undoStack[undoStack.length - 1];
    if (!history) return;

    setUndoStack(draft => {
      draft.pop();
    });

    switch (history.s) {
      case FLOW_ACTION.addElement:
        addElement(
          history.v.id,
          history.v.t,
          history.v.l,
          history.v.x,
          history.v.y,
          history.v.w,
          history.v.h,
          history.v.s,
          OPERATION_TYPE.undo,
        );
        return;
      case FLOW_ACTION.addComment:
        addComment(history.v.id, history.v.c, history.v.x, history.v.y, history.v.f, OPERATION_TYPE.undo);
        return;
      case FLOW_ACTION.editComment:
        editComment(history.v.id, history.v.c, OPERATION_TYPE.undo);
        return;
      case FLOW_ACTION.deleteElements:
        deleteElements(history.v.ids, OPERATION_TYPE.undo);
        return;
      case FLOW_ACTION.reviveElements:
        reviveElements(history.v.ids, OPERATION_TYPE.undo);
        return;
      case FLOW_ACTION.moveElements:
        moveElements(history.v.ids, history.v.dx, history.v.dy, OPERATION_TYPE.undo);
        return;
      case FLOW_ACTION.shapeElement:
        shapeElement(history.v.id, history.v.p, history.v.dx, history.v.dy, OPERATION_TYPE.undo);
        return;
      case FLOW_ACTION.connectNodes:
        connectNodes(history.v.id, history.v.s, history.v.t, OPERATION_TYPE.undo);
        return;
      case FLOW_ACTION.reconnectEdge:
        reconnectEdge(history.v.id, history.v.s, history.v.t, OPERATION_TYPE.undo);
        return;
      case FLOW_ACTION.pasteElements:
        pasteElements(history.v.dst, OPERATION_TYPE.undo);
        return;
      case FLOW_ACTION.updateSettings:
        updateSettings(history.v.id, history.v.u, OPERATION_TYPE.undo);
        return;
    }
  }, [
    addComment,
    addElement,
    connectNodes,
    deleteElements,
    editComment,
    moveElements,
    pasteElements,
    reconnectEdge,
    reviveElements,
    setUndoStack,
    shapeElement,
    undoStack,
    updateSettings,
  ]);

  const redo = useCallback(() => {
    const history = redoStack[redoStack.length - 1];
    if (!history) return;

    setRedoStack(draft => {
      draft.pop();
    });

    switch (history.s) {
      case FLOW_ACTION.addElement:
        addElement(
          history.v.id,
          history.v.t,
          history.v.l,
          history.v.x,
          history.v.y,
          history.v.w,
          history.v.h,
          history.v.s,
          OPERATION_TYPE.redo,
        );

        return;
      case FLOW_ACTION.addComment:
        addComment(history.v.id, history.v.c, history.v.x, history.v.y, history.v.f, OPERATION_TYPE.redo);
        return;
      case FLOW_ACTION.editComment:
        editComment(history.v.id, history.v.c, OPERATION_TYPE.redo);
        return;
      case FLOW_ACTION.deleteElements:
        deleteElements(history.v.ids, OPERATION_TYPE.redo);
        return;
      case FLOW_ACTION.reviveElements:
        reviveElements(history.v.ids, OPERATION_TYPE.redo);
        return;
      case FLOW_ACTION.moveElements:
        moveElements(history.v.ids, history.v.dx, history.v.dy, OPERATION_TYPE.redo);
        return;
      case FLOW_ACTION.shapeElement:
        shapeElement(history.v.id, history.v.p, history.v.dx, history.v.dy, OPERATION_TYPE.redo);
        return;
      case FLOW_ACTION.connectNodes:
        connectNodes(history.v.id, history.v.s, history.v.t, OPERATION_TYPE.redo);
        return;
      case FLOW_ACTION.reconnectEdge:
        reconnectEdge(history.v.id, history.v.s, history.v.t, OPERATION_TYPE.redo);
        return;
      case FLOW_ACTION.pasteElements:
        pasteElements(history.v.dst, OPERATION_TYPE.redo);
        return;
      case FLOW_ACTION.updateSettings:
        updateSettings(history.v.id, history.v.u, OPERATION_TYPE.redo);
        return;
    }
  }, [
    addComment,
    addElement,
    connectNodes,
    deleteElements,
    editComment,
    moveElements,
    pasteElements,
    reconnectEdge,
    redoStack,
    reviveElements,
    setRedoStack,
    shapeElement,
    updateSettings,
  ]);

  const flowChartSideBarProps: FlowChartSideBarProps = {
    ...props.flowChartSideBar,
    onClickElement: label => {
      const hashId = Helpers.generateHashId();
      if (label === "text") {
        const x = viewboxCenter.x - 240;
        const y = viewboxCenter.y - 20;
        addComment(hashId, "", x, y, 14, OPERATION_TYPE.do);
      } else {
        const type = LabelTypeMap[label];
        const { w, h } = Helpers.getInitialWidthAndHeight(label);
        const settings = Helpers.getInitialSettings(label, componentType, lang);
        if (LabelTypeMap[label] === ELEMENT_TYPE.node) {
          const x = viewboxCenter.x - 80;
          const y = viewboxCenter.y - 80;
          addElement(hashId, type, label, x, y, w, h, settings, OPERATION_TYPE.do);
        }
        if (LabelTypeMap[label] === ELEMENT_TYPE.network) {
          const x = viewboxCenter.x - 320;
          const y = viewboxCenter.y - 320;
          addElement(hashId, type, label, x, y, w, h, settings, OPERATION_TYPE.do);
        }
      }
      Store.action.selectElements({
        ids: [hashId],
      });
    },
    onDragElementStart: (e, label: ElementLabel | "text") => {
      e.stopPropagation();
      setIsPlacingElement(true);
      setPlacingElementLabel(label);

      e.dataTransfer.setData("application/flowchart", label);
      e.dataTransfer.effectAllowed = "move";
    },
    helpCenterUrl: "#",
  };
  const flowChartProps: FlowChartProps = {
    ...props.flowChartSideBar,
    drawingArea: {
      snapshot: "",
      fontSize: "small",
      onDragOver: e => {
        e.preventDefault();
        e.dataTransfer.dropEffect = "move";
      },
      onDrop: e => {
        e.preventDefault();
        setIsPlacingElement(false);
      },
      isPlacingElement: isPlacingElement,
      placingElementLabel: placingElementLabel,
      addElement: (id, type, label, x, y, w, h, initialSettings) => {
        addElement(id, type, label, x, y, w, h, initialSettings, OPERATION_TYPE.do);
        Store.action.selectElements({
          ids: [id],
        });
      },
      addComment: (id: string, content: string, x: number, y: number, fontSize: number) => {
        addComment(id, content, x, y, fontSize, OPERATION_TYPE.do);
      },
      editComment: (id: string, content: string) => {
        editComment(id, content, OPERATION_TYPE.do);
      },
      deleteElements: (ids: string[]) => {
        deleteElements(ids, OPERATION_TYPE.do);
      },
      moveElements: (ids: string[], dx: number, dy: number, operationType) => {
        if (operationType === "redo" || operationType === "undo") {
          moveElements(ids, dx, dy, operationType);
        }
      },
      shapeElement: (id, position, dx, dy, operationType) => {
        if (operationType === "redo" || operationType === "undo") {
          shapeElement(id, position, dx, dy, operationType);
        }
      },
      connectNodes: (id: string, source: string, target: string) => {
        connectNodes(id, source, target, OPERATION_TYPE.do);
      },
      reconnectEdge: (id: string, source: string, target: string) => {
        reconnectEdge(id, source, target, OPERATION_TYPE.do);
      },
      pasteElements: elements => {
        pasteElements(elements, OPERATION_TYPE.do);
        Store.action.incrementPasteCount();
        Store.action.selectElements({
          ids: elements.map(element => element.id),
        });
      },
      redo: () => {
        redo();
      },
      undo: () => {
        undo();
      },
      collaborators: [],
      selectElement: ids => {
        Store.action.selectElements({
          ids,
        });
      },
      saveEditingCommentId: action("saveEditingCommentId"),
      moveCursor: () => undefined,
    },
    elementSettings: selectedElementId
      ? {
          elementId: selectedElementId,
          componentType: componentType,
          updateSettings: (id, updates) => {
            updateSettings(id, updates, OPERATION_TYPE.do);
          },
          deleteElementButton: {
            onClick: () => {
              deleteElements([selectedElementId], OPERATION_TYPE.do);
            },
          },
        }
      : undefined,
    editControlPanel: {
      canRedo: true,
      canUndo: true,
      undoButton: {
        onClick: () => {
          undo();
        },
      },
      redoButton: {
        onClick: () => {
          redo();
        },
      },
    },
  };
  const splitterProps: SplitterProps = {
    sizes: [30, 70],
    direction: "horizontal",
    children: [
      <Box key="column-2">
        <FlowChartSideBar {...flowChartSideBarProps} />
      </Box>,
      <Box key="column-3" sx={{ position: "relative" }}>
        <FlowChart {...flowChartProps} />
      </Box>,
    ],
  };
  return <Splitter {...splitterProps} />;
};

FlowChartPlayground.displayName = "FlowChartPlayground";

export default FlowChartPlayground;
