import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type RemoteInterviewSearchAreaContainerProps = {};

const RemoteInterviewSearchAreaContainer: React.FC<RemoteInterviewSearchAreaContainerProps> = () => {
  const remoteInterviewSearchAreaProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.RemoteInterviewSearchArea {...remoteInterviewSearchAreaProps} />
    </ErrorBoundary>
  );
};

RemoteInterviewSearchAreaContainer.displayName = "RemoteInterviewSearchAreaContainer";

export default withErrorBoundary(RemoteInterviewSearchAreaContainer, {});
