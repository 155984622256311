import { App, Company } from "@hireroo/app-store/essential/employee";
import { Assessments } from "@hireroo/app-store/page/e/assessments";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import type { AssessmentSearchForm } from "@hireroo/validator";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

export type SearchFilter = AssessmentSearchForm.AssessmentSearchQuerySchema;

import AssessmentListForEmployeeContainer, { AssessmentListForEmployeeContainerProps } from "./Container";

const AssessmentStatusMapFormToGraphql: Record<
  AssessmentSearchForm.AssessmentSearchQuerySchema["assessmentStatuses"][0],
  Graphql.AssessmentStatus
> = {
  RUNNING: Graphql.AssessmentStatus.Running,
  SUSPENDED: Graphql.AssessmentStatus.Suspended,
  ARCHIVED: Graphql.AssessmentStatus.Archived,
};

export type AssessmentListForEmployeeFetchContainerProps = {
  defaultSortFieldValue: string;
};

const AssessmentListForEmployeeFetchContainer: React.FC<AssessmentListForEmployeeFetchContainerProps> = props => {
  const appStatus = App.useStatus();
  const companyId = Company.useStrictActiveCompanyId();
  const initialized = Assessments.useInitialized();
  const currentSearchFilter = Assessments.useCurrentSearchFilter();
  const pager = Assessments.usePager();
  const { sortMethod, isDescending } = Assessments.useSortParams();
  const [result, refresh] = Graphql.useListAssessmentsByCompanyIdForEmployeeAssessmentsQuery({
    variables: {
      companyId: companyId,
      withCount: true,
      withDeleted: false,
      size: pager.size,
      offset: pager.offset,
      filters: {
        name: currentSearchFilter?.textFilter ?? "",
        viewerIds: (currentSearchFilter?.viewers || []).map(viewer => {
          if (viewer.type === "EMPLOYEE") {
            return {
              type: Graphql.AssessmentViewerType.Employee,
              employeeId: viewer.employeeId,
            };
          }
          return {
            type: Graphql.AssessmentViewerType.Group,
            groupId: viewer.groupId,
          };
        }),
        scoreTrend: currentSearchFilter?.scoreTrend ?? "UNKNOWN",
        talentIds: (currentSearchFilter?.talent || []).slice(),
        isSubmitted: ((): boolean | null => {
          if (currentSearchFilter?.submitStatus === "SUBMITTED") return true;
          if (currentSearchFilter?.submitStatus === "NOT_SUBMITTED") return false;
          return null;
        })(),
        statuses: (currentSearchFilter?.assessmentStatuses || []).map(status => {
          return AssessmentStatusMapFormToGraphql[status];
        }),
      },
      sortMethod: sortMethod,
      isDescending: isDescending,
    },
    requestPolicy: "cache-and-network",
    pause: appStatus !== "INITIALIZED",
  });

  React.useEffect(() => {
    if (result.data?.listAssessmentsByCompanyId) {
      Assessments.updateResponse(result.data.listAssessmentsByCompanyId);
    }
    return () => {
      Assessments.clear();
    };
  }, [result.data?.listAssessmentsByCompanyId]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" color="secondary" />;
  }

  const containerProps: AssessmentListForEmployeeContainerProps = {
    onRefresh: refresh,
    defaultSortFieldValue: props.defaultSortFieldValue,
  };

  return <AssessmentListForEmployeeContainer {...containerProps} />;
};

AssessmentListForEmployeeFetchContainer.displayName = "AssessmentListForEmployeeFetchContainer";

export default withErrorBoundary(AssessmentListForEmployeeFetchContainer, {});
