import { generateKey } from "@hireroo/app-helper/parser";

import { state } from "./State";
import type * as Types from "./types";

export const hasStatistics = (entityId: Types.ChallengeEntityId, query: Types.Query) => {
  const challengeState = state.challenges.get(entityId);
  if (!challengeState) {
    return false;
  }
  return challengeState.statisticsMap.has(generateKey(query));
};
