import type { LiveCoding } from "@hireroo/app-helper/hooks";
import { useLiveCodingSystemDesignRevisions } from "@hireroo/app-helper/hooks";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateHelpCenterUrl } from "@hireroo/router/api";
import { FontSizeMap, OPERATION_TYPE } from "@hireroo/system-design/features";
import { elementsFromFirebase, parseFlowChartSnapshot } from "@hireroo/system-design/helpers/flowChart";
import { ELEMENT_TYPE, Helpers, LabelTypeMap, useSystemDesignContext } from "@hireroo/system-design/react/FlowChart";
import * as React from "react";

import { generateFlowChartProps, PlacingElementLabel } from "./useGenerateDrawAreaProps";

export type GenerateRemoteInterviewSystemDesignCodingEditorPropsArgs = {
  initialFlowChart: string;
  uid: string;
  displayName: string;
  liveCodingId: number;
  collaborativeAction: LiveCoding.CollaborativeAction;
  collaborativeState: LiveCoding.CollaborativeState;
};

export const useGenerateProps = (
  args: GenerateRemoteInterviewSystemDesignCodingEditorPropsArgs,
): Widget.RemoteInterviewSystemDesignCodingEditorProps => {
  const { collaborativeAction, collaborativeState } = args;
  const { t } = useTranslation();
  const lang = useLanguageCode();

  const Store = useSystemDesignContext();

  // Placing element related states
  const [isPlacingElement, setIsPlacingElement] = React.useState<boolean>(false);
  const [placingElementLabel, setPlacingElementLabel] = React.useState<PlacingElementLabel | undefined>(undefined);
  const viewbox = Store.hooks.useCachedViewbox();
  const viewboxCenter = React.useMemo(() => {
    return { x: (viewbox.minX + viewbox.maxX) / 2, y: (viewbox.minY + viewbox.maxY) / 2 };
  }, [viewbox.maxX, viewbox.maxY, viewbox.minX, viewbox.minY]);
  const handleDragOver = React.useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  }, []);
  const handleDrop = React.useCallback((e: React.DragEvent) => {
    e.preventDefault();
    setIsPlacingElement(false);
  }, []);
  const componentType = Store.hooks.useComponentType();
  const selectedElementId = Store.hooks.useSelectedElementId();
  const [_, histories, revisionsReady] = useLiveCodingSystemDesignRevisions(
    args.liveCodingId,
    collaborativeState.selectedSession,
    componentType,
  );

  React.useEffect(() => {
    if (!revisionsReady) return;
    const { result } = parseFlowChartSnapshot(args.initialFlowChart);
    const { elements, elementIds } = elementsFromFirebase(histories, collaborativeState.selectedComponentType, result.elements);
    Store.action.restoreElements({ elements, elementIds });
  }, [Store.action, args.initialFlowChart, collaborativeState.selectedComponentType, histories, revisionsReady]);

  return {
    flowChart: {
      drawingArea: generateFlowChartProps({
        snapshot: args.initialFlowChart,
        fontSize: "medium",
        isPlacingElement: isPlacingElement,
        placingElementLabel: placingElementLabel,
        onDragOver: handleDragOver,
        onDrop: handleDrop,
        collaborativeState,
        collaborativeAction,
      }),
      elementSettings: selectedElementId
        ? {
            elementId: selectedElementId,
            componentType: componentType,
            updateSettings: (id, updates) => {
              collaborativeAction.updateSettings(id, updates, OPERATION_TYPE.do);
            },
            deleteElementButton: {
              onClick: () => {
                if (selectedElementId) {
                  collaborativeAction.deleteElements([selectedElementId], "do");
                }
              },
            },
            closeButton: {
              onClick: () => {
                Store.action.unselectElements();
              },
            },
          }
        : undefined,
      resetControl: {
        onResetFlowChart: () => {
          collaborativeAction.resetElements();
        },
      },
      redoUndoGroup: {
        canUndo: collaborativeState.canUndo,
        canRedo: collaborativeState.canRedo,
        undoButton: {
          onClick: () => {
            collaborativeAction.undo();
          },
        },
        redoButton: {
          onClick: () => {
            collaborativeAction.redo();
          },
        },
      },
    },
    flowChartSidebar: {
      onClickElement: label => {
        const hashId = Helpers.generateHashId();
        if (label === "text") {
          collaborativeAction.addComment(
            hashId,
            t("ダブルクリックでテキストを編集できます。"),
            viewboxCenter.x - 240,
            viewboxCenter.y - 20,
            FontSizeMap["medium"],
            OPERATION_TYPE.do,
          );
        } else {
          const type = LabelTypeMap[label];
          const { w, h } = Helpers.getInitialWidthAndHeight(label);
          const settings = Helpers.getInitialSettings(label, componentType, lang);
          if (LabelTypeMap[label] === ELEMENT_TYPE.node) {
            collaborativeAction.addElement(hashId, type, label, viewboxCenter.x - 80, viewboxCenter.y - 80, w, h, settings, OPERATION_TYPE.do);
          }
          if (LabelTypeMap[label] === ELEMENT_TYPE.network) {
            collaborativeAction.addElement(
              hashId,
              type,
              label,
              viewboxCenter.x - 320,
              viewboxCenter.y - 320,
              w,
              h,
              settings,
              OPERATION_TYPE.do,
            );
          }
        }
      },
      onDragElementStart: (e, label) => {
        e.stopPropagation();
        setIsPlacingElement(true);
        setPlacingElementLabel(label);

        e.dataTransfer.setData("application/flowchart", label);
        e.dataTransfer.effectAllowed = "move";
      },
      helpCenterUrl: generateHelpCenterUrl(lang, "DRAWING_TOOL"),
      componentTypeSelector: {
        onChange: componentType => {
          collaborativeAction.selectComponentType(componentType);
        },
      },
    },
    removeElementDialog: {
      onRemoveElements: () => {
        if (selectedElementId) {
          collaborativeAction.deleteElements([selectedElementId], "do");
        }
      },
    },
  };
};
