import mixpanel from "mixpanel-browser";

import { BaseProperties, EventName, FeatureFlag } from "./types";

export type MixpanelTrackParams = {
  name: EventName;
  properties?: BaseProperties;
};

export const track = (params: MixpanelTrackParams) => {
  mixpanel.track(params.name, params.properties);
};

export type IdentifyCurrentUserArgs = {
  uid: string;
  companyId?: number;
};

/**
 * identify logged in uid for mixpanel
 * register user info to mixpanel
 */
export const identifyCurrentUser = (args: IdentifyCurrentUserArgs) => {
  mixpanel.identify(args.uid);
  mixpanel.people.set({ name: args.uid });
  if (args.companyId) {
    mixpanel.people.set({ companyId: args.companyId });
  }
};

const UNDEFINED = "Undefined";

/**
 * Note that this function should be called after mixpanel.identify
 */
export const setPeopleFeatureFlag = (values: Record<FeatureFlag, string | undefined>) => {
  mixpanel.people.set(
    Object.entries(values).reduce((acc, [key, value]) => {
      return { ...acc, [key]: value || UNDEFINED };
    }, {}),
  );
};

/**
 * Extract the event label based on the data attribute name from where the event occurred.
 *
 * @param event PointerEvent
 * @param trackId 取得するdata属性名
 * @returns
 */
const extractEventLabel = (event: PointerEvent, trackId: string): string | undefined => {
  const composedPath = event.composedPath();
  let eventLabel: string | undefined;
  // Due to the nature of libraries such as Material UI, the click event may not be triggered on the actual clicked element,
  // but on a different element such as a container or a parent element.
  // Since the event can exist within the area that contains the element,
  // it is necessary to explore up to the boundary of that area.
  for (const element of composedPath) {
    const elem = element as HTMLElement;
    // If it reaches the BODY element without finding the event, then give up.
    if (elem.tagName === "BODY") {
      break;
    }
    const attributes = elem.attributes;
    // If attributes do not exist, then give up
    if (!attributes) {
      continue;
    }
    const attr = attributes.getNamedItem(trackId);
    // If the data attribute is not specified, then give up.
    if (!attr) {
      continue;
    }
    // If the data attribute is taken but the value is invalid, then give up.
    // Exclude common cases such as empty strings and undefined values (defined but empty with a variable).
    if (attr.value !== "" && attr.value !== "undefined") {
      eventLabel = attr.value;
    }
    break;
  }
  return eventLabel;
};

const handleClickEvent = (event: PointerEvent) => {
  const ariaLabel = extractEventLabel(event, "aria-label");
  const dataTestId = extractEventLabel(event, "data-testid");
  const htmElement = event.target as HTMLElement;
  mixpanel.track("ON_CLICK_EVENT", {
    ariaLabel: ariaLabel,
    innerText: htmElement?.innerText,
    dataTestId: dataTestId,
    nodeName: htmElement?.nodeName,
    className: htmElement?.className,
  });
};
/**
 * Monitor click events.
 *
 * By specifying a tracking event in a way similar to web scraping,
 *  it is possible to avoid dependency on the framework (library) being used.
 */
const watchClickEvent = () => {
  document.addEventListener("click", event => {
    handleClickEvent(event as PointerEvent);
  });
};

export const startActionEventTracking = () => {
  watchClickEvent();
};
