import { ChallengePlayback } from "@hireroo/app-store/widget/shared/ChallengePlayback";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

import { useBehavioralStates } from "./privateHelper";

export type GenerateReportChallengePlaybackRightSidePanelPropsArgs = {};

export const useGenerateProps = (
  _args: GenerateReportChallengePlaybackRightSidePanelPropsArgs,
): Widget.ReportChallengePlaybackRightSidePanelProps => {
  const behavioralEventMatrix = ChallengePlayback.useBehavioralEventMatrix();
  const sessionIds = ChallengePlayback.useSessionIds();
  const sliderIndex = ChallengePlayback.useSliderValue();
  const playbackManager = ChallengePlayback.usePlaybackManager();
  const playStatus = ChallengePlayback.usePlayStatus();
  const mode = ChallengePlayback.useRightSidePanelMode();
  const updateRightSidePanelMode = ChallengePlayback.updateRightSidePanelMode;

  const { messageCards, query, accessedWebSite, restoreBehavioralEvent, applyBehavioralEvent, gptModel } = useBehavioralStates({
    behavioralEventMatrix,
    currentSliderIndex: sliderIndex,
  });

  React.useEffect(() => {
    const cleanMoveForward = playbackManager.onMoveForward(payload => {
      const tickIndex = payload.currentIndex;
      const sliderIndex = payload.nextIndex;
      for (let step = 0; step < sliderIndex - tickIndex; step++) {
        const behavioralEvents = behavioralEventMatrix.at(tickIndex + step + 1);
        if (behavioralEvents) {
          behavioralEvents.forEach(event => {
            applyBehavioralEvent(event);
          });
        }
      }
    });

    const cleanMoveBackward = playbackManager.onMoveBackward(payload => {
      const sliderIndex = payload.nextIndex;
      restoreBehavioralEvent(sliderIndex);
    });

    return () => {
      cleanMoveForward();
      cleanMoveBackward();
    };
  }, [playbackManager, applyBehavioralEvent, behavioralEventMatrix, restoreBehavioralEvent]);

  return {
    chatGPTPlaybackRightSidePanel: sessionIds.chatGPTSessionId ? { messageCards, gptModel } : undefined,
    webSearchPlaybackRightSidePanel: sessionIds.webSessionId
      ? { query, searchResultsStack: { accessedWebSite: accessedWebSite ?? undefined } }
      : undefined,
    mode: mode,
    onChangeMode: (_event, newMode) => {
      updateRightSidePanelMode(newMode);
    },
    disabledTab: playStatus === "PLAY",
    gptModel,
  };
};
