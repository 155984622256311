import { useTranslation } from "@hireroo/i18n";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import ButtonBase from "@mui/material/ButtonBase";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";

import RankMark, { RankMarkProps } from "../../../../../modules/RankMark/RankMark";
import Link, { LinkProps } from "../../../../../primitive/Link/Link";
import ExamRetryButtonDialog, { ExamRetryButtonDialogProps } from "../../../ExamRetryButtonDialog/ExamRetryButtonDialog";
import ExamStatus, { ExamStatusProps } from "../../../ExamStatus/ExamStatus";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderTop: "1px solid",
  borderBottom: "1px solid",
  borderColor: theme.palette.Other.Divider,
  display: "table-row",
}));

const StyledTableCell = styled(TableCell)(() => ({
  border: "none",
  align: "center",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  display: "table-cell",
}));

const EllipsisText = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
  overflow: hidden;
  max-width: 400px;
`;

const StyledButton = styled(Button)`
  white-space: nowrap;
  word-break: keep-all;
  overflow: hidden;
  height: 30px;
`;

const DelayLabelBox = styled(Box)(({ theme }) => ({
  display: "flex",
  backgroundColor: theme.palette["Warning/Shades"].p12,
  color: theme.palette.warning.main,
  borderColor: theme.palette.warning.main,
  border: "1px solid",
  borderRadius: "20px",
  textAlign: "center",
  alignItems: "center",
  justifyContent: "center",
  height: 24,
  paddingLeft: "8px",
  paddingRight: "8px",
  marginTop: "4px",
  marginLeft: "8px",
}));

const ClosedLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const StyledWarningRoundedIcon = styled(WarningRoundedIcon)(({ theme }) => ({
  color: theme.palette.warning.main,
  fontSize: 14,
  marginRight: "4px",
}));

const StyledRankMark = styled(RankMark)`
  margin-right: 8px;
  height: 20px;
  width: 20px;
`;

const CustomTableRow: React.FC<LinkProps> = props => {
  // FIXME I would like to ask the mui team how to define this type definition accurately.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <ButtonBase as={Link} {...props} />;
};

type TryStatus = "NOT_TRY" | "CHECKING_RETRY" | "ALREADY_EXISTS_RETRY" | "CAN_RETRY" | "EXPIRED";

export type ExamTableRowProps = {
  href: string;
  onClick: () => void;
  disabled?: boolean;
  id: string;
  no: string;
  status: ExamStatusProps;
  deadlineDateLabel: string;
  startDateLabel: string;
  beforeDateLabel?: string;
  scoreLabel?: string;
  rank?: RankMarkProps;
  tryStatus: TryStatus;
  onTry: () => void;
  tryButton?: ButtonProps;
  retryButton?: ExamRetryButtonDialogProps;
};

const ExamTableRow: React.FC<ExamTableRowProps> = props => {
  const { t } = useTranslation();
  const tryButtonProps: ButtonProps = {
    onClick: event => {
      event.preventDefault();
      event.stopPropagation();
      props.onTry();
    },
    variant: "contained",
    color: "primary",
    children: t("実施"),
  };

  return (
    <StyledTableRow
      as={!props.disabled ? CustomTableRow : undefined}
      // FIXME I would like to ask the mui team how to define this type definition accurately.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      component={"a"}
      href={props.href}
      onClick={props.onClick}
    >
      <StyledTableCell component="div">
        <ExamStatus {...props.status} />
      </StyledTableCell>
      <StyledTableCell component="div">
        <EllipsisText>{props.no}</EllipsisText>
      </StyledTableCell>
      <StyledTableCell align="left" component="div">
        <Typography variant="body2">{props.startDateLabel}</Typography>
      </StyledTableCell>
      <StyledTableCell align="left" component="div">
        <Stack direction="row" alignItems="center">
          {props.deadlineDateLabel}
          {props.beforeDateLabel && (
            <DelayLabelBox>
              <StyledWarningRoundedIcon />
              <Typography variant="caption">{props.beforeDateLabel}</Typography>
            </DelayLabelBox>
          )}
        </Stack>
      </StyledTableCell>
      <StyledTableCell align="right" component="div">
        {props.scoreLabel && (
          <Typography variant="body2" color="primary" fontWeight="bold" fontSize={16}>
            {props.scoreLabel}
          </Typography>
        )}
      </StyledTableCell>
      <StyledTableCell align="right" component="div">
        {props.rank && <StyledRankMark {...props.rank} />}
      </StyledTableCell>
      <StyledTableCell align="right" component="div">
        <Stack spacing={2} direction="row" justifyContent={"center"} alignItems={"center"}>
          {props.tryStatus === "NOT_TRY" && <StyledButton {...tryButtonProps} />}
          {props.tryStatus !== "NOT_TRY" && props.tryStatus !== "EXPIRED" && props.retryButton && (
            <ExamRetryButtonDialog {...props.retryButton} />
          )}
          {props.tryStatus === "EXPIRED" && <ClosedLabel variant="caption">{t("受付終了")}</ClosedLabel>}
        </Stack>
      </StyledTableCell>
    </StyledTableRow>
  );
};

ExamTableRow.displayName = "ExamTableRow";

export default ExamTableRow;
