import * as Graphql from "@hireroo/graphql/client/urql";
import type { PaymentForm } from "@hireroo/validator";

export const planTypeMapValidatorToGraphql: Record<PaymentForm.PlanType, Graphql.PlanType> = {
  STARTER: Graphql.PlanType.Basic,
  STANDARD: Graphql.PlanType.Standard,
  PROFESSIONAL: Graphql.PlanType.Professional,
  ENTERPRISE: Graphql.PlanType.Enterprise,
};

export const planTypeMapGraphqlToValidator: Record<Graphql.PlanType, PaymentForm.PlanType> = {
  [Graphql.PlanType.Basic]: "STARTER",
  [Graphql.PlanType.Standard]: "STANDARD",
  [Graphql.PlanType.Professional]: "PROFESSIONAL",
  [Graphql.PlanType.Enterprise]: "ENTERPRISE",
};
