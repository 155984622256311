import { useTranslation } from "@hireroo/i18n";
import * as React from "react";

import AlgorithmQuestionDetail, { AlgorithmQuestionDetailProps } from "./parts/AlgorithmQuestionDetail/AlgorithmQuestionDetail";
import QuestionDetailNotificationDialog, {
  QuestionDetailNotificationDialogProps,
} from "./parts/QuestionDetailNotificationDialog/QuestionDetailNotificationDialog";
import SystemDesignQuestionDetail, { SystemDesignQuestionDetailProps } from "./parts/SystemDesignQuestionDetail/SystemDesignQuestionDetail";

type ContentProps =
  | {
      kind: "SYSTEM_DESIGN";
      content: SystemDesignQuestionDetailProps;
    }
  | {
      kind: "ALGORITHM";
      content: AlgorithmQuestionDetailProps;
    };

export type FreepadQuestionDetailProps = {
  notificationDialog?: Pick<QuestionDetailNotificationDialogProps, "open" | "onClose">;
} & ContentProps;

const FreepadQuestionDetail: React.FC<FreepadQuestionDetailProps> = props => {
  const { t } = useTranslation();
  const questionDetailNotificationDialog: QuestionDetailNotificationDialogProps | undefined = props.notificationDialog && {
    ...props.notificationDialog,
    title: t("この問題は公開前の問題です。"),
    body: t(
      "この問題は公開前の問題のためテストでは利用できません。テストで利用するには申請が必要です。編集画面から必要項目が入力されているかご確認の上、運営まで申請ください。",
    ),
  };

  return (
    <>
      {props.kind === "SYSTEM_DESIGN" && <SystemDesignQuestionDetail {...props.content} />}
      {props.kind === "ALGORITHM" && <AlgorithmQuestionDetail {...props.content} />}
      {questionDetailNotificationDialog && <QuestionDetailNotificationDialog {...questionDetailNotificationDialog} />}
    </>
  );
};

FreepadQuestionDetail.displayName = "FreepadQuestionDetail";

export default FreepadQuestionDetail;
