import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateTechnicalCommentEvaluationsFormPropsArgs, useGenerateProps } from "./useGenerateProps";

export type TechnicalCommentEvaluationsFormContainerProps = GenerateTechnicalCommentEvaluationsFormPropsArgs;

const TechnicalCommentEvaluationsFormContainer: React.FC<TechnicalCommentEvaluationsFormContainerProps> = props => {
  const technicalCommentEvaluationsFormProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.TechnicalCommentEvaluationsForm {...technicalCommentEvaluationsFormProps} />
    </ErrorBoundary>
  );
};

TechnicalCommentEvaluationsFormContainer.displayName = "TechnicalCommentEvaluationsFormContainer";

export default React.memo(withErrorBoundary(TechnicalCommentEvaluationsFormContainer, {}));
