import type { IStandaloneCodeEditor } from "@hireroo/code-editor/react/CodeEditor";
import { getRef } from "@hireroo/firebase";
import type { IFirepad } from "@hireroo/firepad";
import * as Firepad from "@hireroo/firepad";
import * as Sentry from "@sentry/browser";
import type firebase from "firebase/compat/app";
import { useRef } from "react";

import { tuple } from "../tuple";

const { fromMonaco } = Firepad;

export type FirepadArgs = {
  db: "liveCoding";
  key: string;
  userName: string;
  readOnly: boolean;
};

export const useFirepadForLiveCoding = (args: FirepadArgs) => {
  const { db, key, userName, readOnly } = args;
  const pad = useRef<IFirepad | undefined>();
  const firebaseRef = useRef<firebase.database.Reference | undefined>();

  const initPad = (uid: string, editor: IStandaloneCodeEditor, text?: string) => {
    const ref = getRef(db, key);
    pad.current = fromMonaco(ref, editor, {
      userId: uid,
      userName: userName,
      defaultText: text,
    });
    firebaseRef.current = ref;
    pad.current.on(Firepad.FirepadEvent.Error, error => {
      if (error instanceof Error) {
        Sentry.captureEvent(error);
      }
    });
    pad.current.on(Firepad.FirepadEvent.Synced, () => {
      /**
       * The following processes are executed based on messages retrieved from Firebase.
       * @see https://github.com/interviewstreet/firepad-x/blob/v0.3.1/src/monaco-adapter.ts#L519-L541
       *
       * In addition, monaco-editor is automatically set to readOnly when the DOM disappears;
       * it is still in the ReadOnly state immediately after the DOM is restored,
       * so if Firebase messages are received first, the editor will remain set to the readOnly state
       * If the Firebase message is received first, the editor will remain set to the readOnly state.
       *
       * To avoid this, adjust the readOnly flag to convert it to the value given in the argument after the data is received from Firebase.
       */
      editor.updateOptions({
        readOnly: readOnly,
      });
    });

    return new Promise<void>(resolve => {
      pad.current?.on(Firepad.FirepadEvent.Ready, () => {
        resolve();
      });
    });
  };

  const setText = (text: string) => {
    if (pad && pad.current) pad.current.setText(text);
  };

  const getText = (): string => {
    if (pad && pad.current) {
      return pad.current.getText();
    }
    return "";
  };

  const action = {
    initPad,
    setText,
    getText,
  };

  return tuple(pad, action);
};
