import { useTranslation } from "@hireroo/i18n";
import { AlgorithmTestCaseForm } from "@hireroo/validator";
import { Add } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { ChangeEvent } from "react";

import AcceptButton, { AcceptButtonProps } from "../../../../../../../primitive/Button/AcceptButton";
import PerformanceTestCaseDialog, { PerformanceTestCaseDialogProps } from "../PerformanceTestCaseDialog/PerformanceTestCaseDialog";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
type DialogController = {
  setLoading: (loading: boolean) => void;
  close: () => void;
  open: () => void;
};
export type AddTestCaseProps = {
  createDialog: Omit<PerformanceTestCaseDialogProps, "dialog" | "onSubmit" | "kind">;
  onSubmit: (fields: AlgorithmTestCaseForm.PerformanceTestCaseFormSchemaV2, controller: DialogController) => void;
  onFileChange: (event: ChangeEvent<HTMLInputElement>, controller: DialogController) => void;
};

const AddTestCase: React.FC<AddTestCaseProps> = props => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const addTestCaseButton: AcceptButtonProps = {
    children: t("テストケースを追加する").toString(),
    size: "small",
    startIcon: <Add fontSize="small" />,
    variant: "text",
    onClick: handleClick,
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dialogController: DialogController = {
    setLoading: (_loading: boolean) => {
      //TODO: implement loading
    },
    close: () => {
      handleClose();
      setOpen(false);
    },
    open: () => {
      setOpen(true);
    },
  };
  const dialog: PerformanceTestCaseDialogProps = {
    ...props.createDialog,
    kind: "CREATE",
    dialog: {
      open: open,
      noButton: {
        onClick: () => {
          setOpen(false);
        },
      },
    },
    onSubmit: fields => {
      props.onSubmit(fields, dialogController);
    },
  };
  const handleAddClick = () => {
    handleClose();
    setOpen(true);
  };
  const handleFileUploadClick = () => {
    inputRef.current?.click();
  };
  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    props.onFileChange(event, dialogController);
    //reset file input to allow same file upload
    event.target.value = "";
  };

  return (
    <Box>
      <AcceptButton {...addTestCaseButton} />
      <Menu open={menuOpen} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem onClick={handleAddClick}>{t("直接入力")}</MenuItem>
        <MenuItem component="button" onClick={handleFileUploadClick}>
          <label htmlFor="file-upload">{t("JSONファイルアップロード")}</label>
          <VisuallyHiddenInput ref={inputRef} type="file" accept=".json" onChange={handleFileChange} />
        </MenuItem>
      </Menu>
      <PerformanceTestCaseDialog {...dialog} />
    </Box>
  );
};

AddTestCase.displayName = "AddTestCase";

export default AddTestCase;
