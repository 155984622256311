import { useSnapshot } from "valtio";

import { state } from "./State";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useSize = () => {
  const state = useSnapshotState();
  return state.invoices.size;
};

export const useInvoiceCount = () => {
  const snapshot = useSnapshotState();
  return snapshot.res?.count || 0;
};

export const usePager = () => {
  const snapshot = useSnapshotState();
  return snapshot.res?.pager;
};

export const usePage = () => {
  const snapshot = useSnapshotState();
  return snapshot.page;
};

export const useFetchSize = () => {
  return 6;
};

export const useFechable = (): boolean => {
  const size = useFetchSize();
  const currentSize = useSize();
  const page = usePage();
  const expectedTotal = size * page + 1;
  return currentSize < expectedTotal;
};

export const useInvoices = () => {
  const snapshot = useSnapshotState();
  const fetchSize = useFetchSize();
  const page = usePage();
  const start = page * fetchSize;
  const stop = (page + 1) * fetchSize;
  return Array.from(snapshot.invoices.values()).slice(start, stop);
};
