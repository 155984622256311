import { generatePath as generatePathApi } from "react-router";
import { createSearchParams } from "react-router-dom";

import routes, { RouteKey } from "../config/app";
import { PathParam } from "./types";

export const generatePath = <T extends RouteKey>(
  routeKey: T,
  options?: { pathParams?: { [key in PathParam<T>]: string }; queryParams?: Record<string, string | number> },
) => {
  const searchParams = createSearchParams();
  Object.entries(options?.queryParams || {}).forEach(([key, value]) => {
    searchParams.set(key, value.toString());
  });
  const queryParamsString = searchParams.toString().length ? `?` + searchParams.toString() : "";
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Ignore the type error here, as it is used to wrap the generatePath of react-router.
  return generatePathApi(routes[routeKey], options?.pathParams) + queryParamsString;
};

export const generateCurrentOriginUrl = <T extends RouteKey>(
  routeKey: T,
  options?: {
    pathParams?: { [key in PathParam<T>]: string };
    queryParams?: Record<string, string | number>;
    hash?: string;
  },
) => {
  const uri = generatePath(routeKey, options);
  const newUrl = new URL(uri, window.location.origin);
  if (options?.hash) {
    newUrl.hash = options.hash;
  }
  return newUrl.href;
};
