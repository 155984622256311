import { useTranslation } from "@hireroo/i18n";
import { useTheme } from "@mui/material";
import * as React from "react";
import { CartesianGrid, Label, ReferenceLine, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis, ZAxis } from "recharts";

export type Data = {
  x: number;
  y: number;
};

type Axis = {
  label: string;
};

export type ReadabilityGraphProps = {
  xAxisTicks: (string | number)[];
  readabilityPer: number;
  cyclomatic_complexity_value: number;
  referenceLine: {
    label: string;
  };
  xAxis: Axis;
  yAxis: Axis;
  data: Data[];
};

const ReadabilityGraph: React.FC<ReadabilityGraphProps> = props => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ScatterChart
        id="readability-graph-scatter-chart"
        width={400}
        height={300}
        margin={{
          top: 30,
          right: 10,
          bottom: 30,
          left: 30,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="number"
          dataKey="x"
          name={""}
          tick={{ fontSize: 14, fill: theme.palette.text.primary }}
          ticks={props.xAxisTicks}
          domain={[0, "dataMax"]}
          strokeDasharray="3 3"
          axisLine={false}
        >
          <Label style={{ fontSize: 14, fill: theme.palette.text.primary }} offset={10} position={"bottom"}>
            {t("循環的複雑度")}
          </Label>
        </XAxis>
        <YAxis
          type="number"
          dataKey="y"
          name={t("可読性")}
          tick={{ fontSize: 14, fill: theme.palette.text.primary }}
          ticks={[0, 100]}
          unit="%"
          axisLine={false}
        >
          <Label angle={-90} style={{ fontSize: 14, fill: theme.palette.text.primary }} offset={10} position={"left"}>
            {t("可読性")}
          </Label>
        </YAxis>
        <ZAxis type="number" range={[100]} />
        <Tooltip
          itemStyle={{
            color: theme.palette.text.primary,
          }}
          contentStyle={{
            borderColor: theme.palette.background.paper,
            backgroundColor: theme.palette.background.paper,
            minWidth: "5vw",
          }}
        />
        <ReferenceLine x={props.cyclomatic_complexity_value}>
          <Label style={{ fontSize: 14, fill: theme.palette.text.primary }} offset={10} position={"top"}>
            {props.referenceLine.label}
          </Label>
        </ReferenceLine>

        <ReferenceLine y={props.readabilityPer}>
          {props.readabilityPer !== 100 && props.readabilityPer !== 0 && (
            <Label style={{ fontSize: 14, fill: theme.palette.text.primary }} position="left">
              {props.readabilityPer + "%"}
            </Label>
          )}
        </ReferenceLine>

        <Scatter name={t("循環的複雑度")} data={props.data} fill="#7fa46c" line={{ strokeWidth: 3 }} />
      </ScatterChart>
    </ResponsiveContainer>
  );
};

ReadabilityGraph.displayName = "ReadabilityGraph";

export default ReadabilityGraph;
