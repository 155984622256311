import { useTranslation } from "@hireroo/i18n";
import CallEndOutlinedIcon from "@mui/icons-material/CallEndOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import * as React from "react";

import LiveCodingSettingDialog, {
  LiveCodingSettingDialogProps,
} from "../../ms-components/Livecoding/LiveCodingSettingDialog/LiveCodingSettingDialog";

type Status = "JOINED" | "NOT_JOINED";

const StyledButton = styled(Button)`
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
`;

export type LiveCodingPreJoinScreenProps = {
  dialog: Omit<LiveCodingSettingDialogProps, "open" | "onClose" | "noButton" | "mode">;
  status: Status;
  onOpen?: () => void;
  VideoChat: React.ReactNode;
  toggleVideoButton: { type: "SETUP_VIDEO" } | { type: "END_VIDEO"; onClick: ButtonProps["onClick"] };
};

const LiveCodingPreJoinScreen: React.FC<LiveCodingPreJoinScreenProps> = props => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const videoButton: ButtonProps = {
    startIcon: <VideocamOutlinedIcon />,
    color: "secondary",
    variant: "outlined",
    children: t("ビデオ通話"),
    onClick: () => {
      props.onOpen?.();
      setOpen(true);
    },
    className: "video-call-start-button",
  };
  const endVideoButton: ButtonProps = {
    startIcon: <CallEndOutlinedIcon />,
    color: "error",
    variant: "contained",
    children: t("ビデオ通話から退出"),
  };

  const dialog: LiveCodingSettingDialogProps = {
    ...props.dialog,
    open: open,
    onClose: () => {
      setOpen(false);
    },
    noButton: {
      onClick: () => {
        setOpen(false);
      },
    },
    mode: "PRE_JOIN",
  };

  return (
    <Box>
      {props.toggleVideoButton.type === "SETUP_VIDEO" && <StyledButton {...videoButton} />}
      {props.toggleVideoButton.type === "END_VIDEO" && <StyledButton {...endVideoButton} onClick={props.toggleVideoButton.onClick} />}
      <LiveCodingSettingDialog {...dialog} />
      {props.status === "JOINED" && props.VideoChat}
    </Box>
  );
};

LiveCodingPreJoinScreen.displayName = "LiveCodingPreJoinScreen";

export default LiveCodingPreJoinScreen;
