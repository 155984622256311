import { useTranslation } from "@hireroo/i18n";
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const Wrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(2),
}));

const ReconnectButton = styled(LoadingButton)(() => ({
  borderRadius: 24,
}));

type Controller = {
  setLoading: (loading: boolean) => void;
};

export type ConnectingPanelProps = {
  reconnectButton?: {
    onClick?: (controller: Controller) => void;
  };
};

const ConnectingPanel: React.FC<ConnectingPanelProps> = props => {
  const { t } = useTranslation();
  const [showReconnectButton, setShowReconnectButton] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const controller: Controller = {
    setLoading: newLoading => {
      setLoading(newLoading);
    },
  };
  const reconnectButton: LoadingButtonProps | undefined = props.reconnectButton
    ? {
        children: t("再接続を試みる"),
        onClick: () => {
          props.reconnectButton?.onClick?.(controller);
        },
        loading: loading,
        variant: "outlined",
        color: "secondary",
      }
    : undefined;

  /** If the connection is not made after 10 seconds, the button is displayed */
  React.useEffect(() => {
    if (!props.reconnectButton) {
      return;
    }
    window.setTimeout(() => {
      setShowReconnectButton(true);
    }, 10000);
  }, [props.reconnectButton]);

  return (
    <Wrapper>
      <Stack spacing={3} direction="column" sx={{ justifyContent: "center", alignItems: "center" }}>
        {!showReconnectButton && <CircularProgress color="secondary" />}
        {showReconnectButton && reconnectButton && (
          <>
            <Box>
              <ReconnectButton {...reconnectButton} />
            </Box>
            <Box>
              <Typography variant="caption" color="text.secondary">
                {t("再接続を試みてもファイルツリーが表示されない場合、ページのリロードを試みてください。")}
              </Typography>
            </Box>
          </>
        )}
      </Stack>
    </Wrapper>
  );
};

ConnectingPanel.displayName = "ConnectingPanel";

export default ConnectingPanel;
