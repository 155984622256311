import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { generateTimestampFromDate } from "@hireroo/app-helper/parser";
import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { TotalScoreRankVisualizer } from "@hireroo/app-store/widget/shared/TotalScoreRankVisualizer";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { getTranslation } from "@hireroo/i18n";
import * as Sentry from "@sentry/browser";

export type SubscribeQueryKeyArgs = {
  uniqueKey: ProjectTestReport.UniqueKey;
  companyId: number;
  entityId: number;
  submissionId: number;
  questionId: number;
};

const NUM_BINS = 20;

export const startSubscribeQueryKey = (args: SubscribeQueryKeyArgs) => {
  const client = getGraphqlClient();
  const { uniqueKey, entityId } = args;
  return TotalScoreRankVisualizer.subscribeQueryKey(uniqueKey, async (query, shouldRefresh) => {
    if (!shouldRefresh && ProjectTestReport.Api.hasStatistics(entityId, query)) {
      ProjectTestReport.setQuery(entityId, query);
      return;
    }
    client
      .GetProjectStatistics({
        statisticsSource: {
          category: "PROJECT",
          query: {
            project: {
              companyId: query.scope === "COMPANY" ? args.companyId : undefined,
              minTimestamp: query.enableDate && query.startDate ? generateTimestampFromDate(query.startDate) : undefined,
              maxTimestamp: query.enableDate && query.endDate ? generateTimestampFromDate(query.endDate) : undefined,
              numBins: NUM_BINS,
              questionId: args.questionId,
            },
          },
        },
        rankSource: {
          category: "PROJECT",
          query: {
            project: {
              companyId: query.scope === "COMPANY" ? args.companyId : undefined,
              minTimestamp: query.enableDate && query.startDate ? generateTimestampFromDate(query.startDate) : undefined,
              maxTimestamp: query.enableDate && query.endDate ? generateTimestampFromDate(query.endDate) : undefined,
              submissionId: args.submissionId,
            },
          },
        },
      })
      .then(res => {
        TotalScoreRankVisualizer.setResult(uniqueKey, query, {
          status: "SATISFY",
          statistics: res.statistics.projectStatistics,
          rank: res.rank,
        });
        ProjectTestReport.setStatistics(entityId, query, res.statistics.projectStatistics);
      })
      .catch(error => {
        Sentry.captureException(error);
        TotalScoreRankVisualizer.setResult(uniqueKey, query, {
          status: "ERROR",
          statistics: null,
          rank: null,
        });
      });
  });
};

export const startSubscribeProjectSubmission = (projectId: number) => {
  const { t } = getTranslation();

  return ProjectTestReport.subscribeCurrentSubmissionId(projectId, projectSubmissionId => {
    const client = getGraphqlClient();
    client
      .ProjectSubmissionForProjectTestReport({
        submissionId: projectSubmissionId,
      })
      .then(res => {
        ProjectTestReport.setSubmission(projectId, res.projectSubmission);
      })
      .catch(error => {
        Sentry.captureException(error);
        const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("提出物の取得に失敗しました。"));
        Snackbar.notify({
          severity: "error",
          message: errorNotification.message,
        });
      });
  });
};
