import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Auth, Company } from "@hireroo/app-store/essential/employee";
import { EvaluationMetrics } from "@hireroo/app-store/widget/e/EvaluationMetrics";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import * as Time from "@hireroo/formatter/time";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/react";
import * as React from "react";

import EvaluationMetricGroupSearchFieldContainer from "./widget/EvaluationMetricGroupSearchField/Container";

type Item = Widget.EvaluationMetricsProps["table"]["items"][number];

export type GenerateEvaluationMetricsPropsArgs = {
  refresh: () => void;
};

export const useGenerateProps = (args: GenerateEvaluationMetricsPropsArgs): Widget.EvaluationMetricsProps => {
  const { refresh } = args;
  const metrics = EvaluationMetrics.useMetrics();
  const dialogStatus = EvaluationMetrics.useDialogStatus();
  const selectedMetrics = EvaluationMetrics.useSelectedMetrics();
  const selectedMetricIds = EvaluationMetrics.useSelectedMetricIds();
  const client = getGraphqlClient();
  const { t } = useTranslation();
  const currentUid = Auth.useCurrentUid();
  const currentUser = Auth.useCurrentUser();
  const companyId = Company.useStrictActiveCompanyId();
  const pagination = EvaluationMetrics.usePagination();
  const [loading, setLoading] = React.useState(false);

  const refetchMetrics = React.useCallback(
    (metricId: number) => {
      client
        .GetEvaluationMetricForEvaluationMetrics({
          metricId: metricId,
        })
        .then(res => {
          EvaluationMetrics.updateMetric(res.evaluationMetric);
          Snackbar.notify({
            severity: "success",
            message: t("評価項目の更新に成功しました。"),
          });
        })
        .catch(error => {
          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("評価項目の更新に失敗しました。"));
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
        });
    },
    [client, t],
  );

  const items = React.useMemo((): Item[] => {
    return metrics.map((metric): Item => {
      return {
        id: metric.metricId.toString(),
        checkbox: {
          checked: selectedMetricIds.includes(metric.metricId),
          onChange: (_, checked) => {
            if (checked) {
              EvaluationMetrics.addSelectedItemId(metric.metricId);
            } else {
              EvaluationMetrics.removeSelectedItemId(metric.metricId);
            }
          },
        },
        title: metric.title,
        description: metric.description,
        updatedAt: Time.datetimeFormat(new Date(metric.updatedAtSeconds * 1000)),
        editButton: {
          onClick: () => {
            EvaluationMetrics.updateDialogStatus(`EDIT_OPEN_${metric.metricId}`);
          },
        },
        editDialog: {
          dialog: {
            open: dialogStatus === `EDIT_OPEN_${metric.metricId}`,
            yesButton: {
              disabled: loading,
            },
            noButton: {
              onClick: () => {
                EvaluationMetrics.updateDialogStatus("CLOSE");
              },
            },
            onClose: () => {
              EvaluationMetrics.updateDialogStatus("CLOSE");
            },
          },
          onSubmit: async fields => {
            setLoading(true);
            await client
              .UpdateEvaluationMetric({
                input: {
                  metricId: metric.metricId,
                  title: fields.title,
                  description: fields.description ?? "",
                },
              })
              .then(() => {
                refetchMetrics(metric.metricId);
                EvaluationMetrics.updateDialogStatus("CLOSE");
                refresh();
              })
              .catch(error => {
                Sentry.captureException(error);
                const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("評価項目の更新に失敗しました。"));
                Snackbar.notify({
                  severity: "error",
                  message: errorNotification.message,
                });
              })
              .finally(() => {
                setLoading(false);
              });
          },
          kind: "EDIT",
          defaultValues: {
            title: metric.title,
            description: metric.description,
          },
          creator: {
            displayName: metric.employee.displayName || metric.employee.email,
            src: metric.employee.photoUrl,
          },
        },
        addButton: {
          onClick: () => {
            EvaluationMetrics.updateDialogStatus("ADD_OPEN");
            EvaluationMetrics.clearSelectedItemIds();
            EvaluationMetrics.addSelectedItemId(metric.metricId);
          },
        },
        deleteButton: {
          onClick: () => {
            EvaluationMetrics.updateDialogStatus(`DELETE_OPEN_${metric.metricId}`);
          },
        },
        deleteDialog: {
          dialog: {
            open: dialogStatus === `DELETE_OPEN_${metric.metricId}`,
            yesButton: {
              disabled: loading,
            },
            noButton: {
              onClick: () => {
                EvaluationMetrics.updateDialogStatus("CLOSE");
              },
              disabled: loading,
            },
            onClose: () => {
              EvaluationMetrics.updateDialogStatus("CLOSE");
            },
            title: t("評価項目の削除"),
          },
          onSubmit: async () => {
            setLoading(true);
            await client
              .DeleteEvaluationMetric({
                input: {
                  metricId: metric.metricId,
                },
              })
              .then(() => {
                Snackbar.notify({
                  severity: "success",
                  message: t("評価項目の削除に成功しました。"),
                });
                EvaluationMetrics.removeMetric(metric.metricId);
                EvaluationMetrics.updateDialogStatus("CLOSE");
                EvaluationMetrics.clearPage();
                refresh();
              })
              .catch(error => {
                Sentry.captureException(error);
                const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("評価項目の削除に失敗しました。"));
                Snackbar.notify({
                  severity: "error",
                  message: errorNotification.message,
                });
              })
              .finally(() => {
                setLoading(false);
              });
          },
          name: metric.title,
          description: `${t("一度削除すると元に戻すことはできません。")}${t(
            "この操作によって対象の評価項目を使った技術レビューが削除されることはありません。",
          )}`,
        },
      };
    });
  }, [client, dialogStatus, refetchMetrics, loading, metrics, refresh, selectedMetricIds, t]);

  return {
    header: {
      checkbox: {
        indeterminate: React.useMemo(() => selectedMetrics.length > 0, [selectedMetrics]),
        onChange: (_, checked) => {
          if (checked && selectedMetricIds.length === 0) {
            metrics.forEach(metric => EvaluationMetrics.addSelectedItemId(metric.metricId));
          } else {
            EvaluationMetrics.clearSelectedItemIds();
          }
        },
        checked: React.useMemo(
          () => selectedMetrics.length > 0 && selectedMetrics.length === metrics.length,
          [selectedMetrics.length, metrics.length],
        ),
      },
      createButton: {
        onClick: () => {
          EvaluationMetrics.updateDialogStatus("CREATE_OPEN");
        },
      },
      addButton: {
        onClick: () => {
          EvaluationMetrics.updateDialogStatus("ADD_OPEN");
        },
        disabled: selectedMetrics.length === 0,
      },
      deleteButton: {
        onClick: () => {
          EvaluationMetrics.updateDialogStatus("DELETES_OPEN");
        },
        disabled: selectedMetrics.length === 0,
      },
    },
    table: {
      items: items,
      pagination: {
        count: pagination.count,
        rowsPerPage: pagination.size,
        page: pagination.page,
        onPageChange: (_, page) => {
          EvaluationMetrics.updatePage(page);
        },
        onRowsPerPageChange: event => {
          EvaluationMetrics.updateSize(Number(event.target.value));
        },
      },
    },
    editorDialog: {
      dialog: {
        open: dialogStatus === "CREATE_OPEN",
        yesButton: {
          disabled: loading,
        },
        noButton: {
          onClick: () => {
            EvaluationMetrics.updateDialogStatus("CLOSE");
          },
        },
        onClose: () => {
          EvaluationMetrics.updateDialogStatus("CLOSE");
        },
      },
      onSubmit: async fields => {
        setLoading(true);
        await client
          .CreateEvaluationMetricForEvaluationMetrics({
            input: {
              companyId: companyId,
              employeeId: currentUid,
              title: fields.title,
              description: fields.description ?? "",
            },
          })
          .then(res => {
            if (res.createEvaluationMetric) {
              Snackbar.notify({
                severity: "success",
                message: t("評価項目の作成に成功しました。"),
              });
              EvaluationMetrics.updateDialogStatus("CLOSE");
              refresh();
            }
          })
          .catch(error => {
            Sentry.captureException(error);
            const errorNotification = ErrorHandlingHelper.generateErrorNotification(
              error,
              t("評価項目の作成に失敗しました。しばらくしてから再度お試し頂くか、お問い合わせください。"),
            );
            Snackbar.notify({
              severity: "error",
              message: errorNotification.message,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      },
      kind: "CREATE",
      defaultValues: {
        title: "",
        description: "",
      },
      creator: {
        displayName: currentUser.displayName || currentUser.email,
        src: currentUser.photoUrl,
      },
    },
    addDialog: {
      dialog: {
        open: dialogStatus === "ADD_OPEN",
        yesButton: {
          disabled: loading,
        },
        noButton: {
          onClick: () => {
            EvaluationMetrics.updateDialogStatus("CLOSE");
          },
          disabled: loading,
        },
        onClose: () => {
          EvaluationMetrics.updateDialogStatus("CLOSE");
        },
      },
      selectedItems: selectedMetrics.map(metric => ({
        id: metric.metricId.toString(),
        name: metric.title,
      })),
      SearchGroups: <EvaluationMetricGroupSearchFieldContainer />,
      onSubmit: async field => {
        setLoading(true);
        client
          .AddEvaluationMetricsToGroupsForEvaluationMetrics({
            input: {
              metricIds: [...selectedMetricIds],
              metricGroupIds: field.items.map(item => Number(item.itemId)),
            },
          })
          .then(() => {
            EvaluationMetrics.updateDialogStatus("CLOSE");
            Snackbar.notify({
              severity: "success",
              message: t("評価項目の追加に成功しました。"),
            });
            EvaluationMetrics.clearSelectedItemIds();
            refresh();
          })
          .catch(error => {
            Sentry.captureException(error);
            const errorNotification = ErrorHandlingHelper.generateErrorNotification(
              error,
              t("評価項目の追加に失敗しました。しばらくしてから再度お試し頂くか、お問い合わせください。"),
            );
            Snackbar.notify({
              severity: "error",
              message: errorNotification.message,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      },
    },
    deletesDialog: {
      dialog: {
        open: dialogStatus === "DELETES_OPEN",
        yesButton: {
          disabled: loading,
        },
        noButton: {
          onClick: () => {
            EvaluationMetrics.updateDialogStatus("CLOSE");
          },
          disabled: loading,
        },
        onClose: () => {
          EvaluationMetrics.updateDialogStatus("CLOSE");
        },
        title: t("評価項目の削除"),
      },
      onSubmit: async () => {
        setLoading(true);
        await client
          .DeleteMultiEvaluationMetrics({
            input: {
              metricIds: selectedMetrics.map(metric => metric.metricId),
            },
          })
          .then(() => {
            Snackbar.notify({
              severity: "success",
              message: t("評価項目の削除に成功しました。"),
            });
            EvaluationMetrics.removeMetrics(selectedMetrics.map(metric => metric.metricId));
            EvaluationMetrics.updateDialogStatus("CLOSE");
            EvaluationMetrics.clearPage();
            EvaluationMetrics.clearSelectedItemIds();
            refresh();
          })
          .catch(error => {
            Sentry.captureException(error);
            const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("評価項目の削除に失敗しました。"));
            Snackbar.notify({
              severity: "error",
              message: errorNotification.message,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      },
      items: selectedMetrics.map(metric => ({
        id: metric.metricId.toString(),
        name: metric.title,
        description: metric.description,
      })),
      description: t("選択した評価項目を削除します。一度削除すると元に戻すことはできません。"),
    },
  };
};
