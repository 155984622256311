import { useTranslation } from "@hireroo/i18n";
import ConnectLineGif from "@hireroo/ui-assets/images/tutorial/connect-line.gif";
import EditComponentGif from "@hireroo/ui-assets/images/tutorial/edit-component.gif";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { OnboardingGuideProps } from "../../modules/OnboardingGuide/OnboardingGuide";

const FigureWrapper = styled("figure")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  padding: theme.spacing(1),
}));

export const useGenerateSystemDesignOnboardingGuideProps = (): OnboardingGuideProps => {
  const { t } = useTranslation();
  return {
    steps: [
      {
        title: t("チュートリアルへようこそ！"),
        content: t(
          "こちらではシステムデザイン形式の画面操作を説明します。このチュートリアルは何度でも再開できますので、操作に迷った際にご確認ください。",
        ),
        placement: "center",
        target: "body",
      },
      {
        title: t("ペーンの調整"),
        content: t("こちらをクリックし、動かすことでペーンの幅を調整することができます。"),
        placement: "right",
        styles: {
          options: {
            width: 300,
          },
        },
        target: ".tutorial-pane",
      },
      {
        title: t("コンポーネントの配置"),
        content: t("アイコンをクリックするか、右の描画エリアにドラッグ&ドロップすることで、任意のコンポーネントを配置することができます。"),
        placement: "left",
        styles: {
          options: {
            width: 300,
          },
        },
        target: "#user-object",
      },
      {
        title: t("コンポーネントの編集"),
        content: (
          <Stack spacing={0.5} direction="column">
            <FigureWrapper>
              <img src={EditComponentGif} />
            </FigureWrapper>
            <Typography variant="body1">{t("選択中のコンポーネントのプロパティを右サイドパネルより変更できます。")}</Typography>
          </Stack>
        ),
        placement: "left",
        styles: {
          options: {
            width: 500,
          },
        },
        target: "#view-area",
      },
      {
        title: t("フローチャートの作成"),
        content: (
          <Stack spacing={0.5} direction="column">
            <FigureWrapper>
              <img src={ConnectLineGif} />
            </FigureWrapper>
            <Typography variant="body1">
              {t(
                "配置したコンポーネントを矢印で繋げることで問題の要件を満たすシステムを設計しましょう。必要に応じて描画エリアにコメントを添えることもできます。",
              )}
            </Typography>
          </Stack>
        ),
        placement: "left",
        styles: {
          options: {
            width: 500,
          },
        },
        target: "#view-area",
      },

      {
        title: t("フローチャートのリセット"),
        content: t("初期配置に戻します。リセットした内容は復元できませんのでご注意ください。"),
        placement: "top",
        styles: {
          options: {
            width: 300,
          },
        },
        target: "#system-design-reset-button",
      },
      {
        title: t("テストの実行"),
        content: t("課題の要件を満たすシステムが設計できているかテストします。"),
        placement: "top",
        styles: {
          options: {
            width: 300,
          },
        },
        target: "#system-design-ru-test-button",
      },
      {
        title: t("フローチャートの提出"),
        content: t("課題の要件を満たすシステムが設計できたら、フローチャート提出して次に進みます。"),
        placement: "top",
        styles: {
          options: {
            width: 300,
          },
        },
        target: "#system-design-submit-button",
      },
      {
        title: t("制限時間"),
        content: t("問題には制限時間があります。制限時間を過ぎるとそれ以降の問題は解答することはできませんのでご注意ください。"),
        placement: "bottom",
        styles: {
          options: {
            width: 300,
          },
        },
        target: "#timelimit-box",
      },
      {
        title: t("ヘルプセンター"),
        content: t(
          "こちらのボタンを押すとヘルプセンターが開かれます。分からないことがありましたらこちらにアクセスし、記事を参照するか、右下のチャットからお問い合わせください。",
        ),
        placement: "left",
        styles: {
          options: {
            width: 300,
          },
        },
        target: "#help-button",
      },
      {
        title: t("チュートリアル終了"),
        content: t("これでチュートリアルは終了です。再度確認したい場合は、もう一度ボタンを押しチュートリアルを開始してください。"),
        placement: "center",
        target: "body",
      },
    ],
  };
};
