import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { ProfileCodeOutput } from "../parts/types";

type GridItemProps = {
  label: string;
  value: string;
};

const GridItem = (props: GridItemProps): JSX.Element => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="45%">
        <Typography variant="body2">{props.label}</Typography>
      </Box>
      <Box width="55%" height="100%">
        <Typography>{props.value}</Typography>
      </Box>
    </Box>
  );
};

export type PerformanceOutputProps = {
  output: ProfileCodeOutput;
};

const PerformanceOutput: React.FC<PerformanceOutputProps> = props => {
  const { t } = useTranslation();
  const { output } = props;
  const gridItems: GridItemProps[] = [
    {
      label: `${t("メモリ")} (${t("平均")})`,
      value: `${output.maxMemoryAvgMB} MB`,
    },
    {
      label: `${t("パフォーマンス")} (${t("平均")})`,
      value: output.performanceAvg,
    },
    {
      label: `${t("メモリ")} (${t("中央値")})`,
      value: `${output.maxMemoryMedMB} MB`,
    },
    {
      label: `${t("パフォーマンス")} (${t("中央値")})`,
      value: output.performanceMed,
    },
    {
      label: `${t("メモリ")} (${t("標準偏差")})`,
      value: `${output.maxMemoryStdMB} MB`,
    },
    {
      label: `${t("パフォーマンス")} (${t("標準偏差")})`,
      value: output.performanceStd,
    },
  ];
  return (
    <Grid container rowSpacing={1} mt={2.5}>
      {gridItems.map(girdItem => (
        <Grid key={girdItem.label} item xs={6}>
          <GridItem {...girdItem} />
        </Grid>
      ))}
    </Grid>
  );
};

PerformanceOutput.displayName = "PerformanceOutput";

export default PerformanceOutput;
