import { subscribeKey } from "valtio/utils";

import { state } from "./State";

export type SubscribeTimeoutStatusCallback = () => void;

export const subscribeTimeoutStatus = (callback: SubscribeTimeoutStatusCallback) => {
  return subscribeKey(state, "status", status => {
    if (status === "TIMEOUT") {
      callback();
    }
  });
};
