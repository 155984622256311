import { ScreeningsStore } from "@hireroo/app-store/page/e/screenings";
import * as Graphql from "@hireroo/graphql/client/urql";
import { ScreeningSearchForm } from "@hireroo/validator";

export const convertStringsToTagNames = (values: string[]): ScreeningSearchForm.Tags => {
  // Get all the valid string values
  const tags: ScreeningSearchForm.Tags = [];
  values.forEach(value => {
    value.split("%").forEach(value => {
      tags.push({
        displayName: value,
      });
    });
  });
  return tags;
};

export const SortMethodReverseMap: Record<string, "created-at"> = {
  [Graphql.SortMethod.CreatedAt]: "created-at",
};

export const convertStringToSortFieldValue = (value: string | null): ScreeningSearchForm.SortFieldValue => {
  if (value === null) return "created-at-descending";
  try {
    // "sortField+isDesc" (e.g.) "CREATED_AT-true"
    const [field, isDesc] = value.split("-");
    const sortField = SortMethodReverseMap[field] ?? "unknown";
    const isDescString = isDesc === "true" ? "descending" : "ascending";
    return `${sortField}-${isDescString}` as ScreeningSearchForm.SortFieldValue;
  } catch (_) {
    return "created-at-descending";
  }
};

export const convertStringsToPage = (value: string | null): number => {
  if (value === null) return 0;
  const page = Number(value);
  // Set it to 0 if it is invalid (NaN or negative). Note that page > 0 is false if page is NaN.
  // Actual (zero-index) page number = (page number from the query param) - 1
  if (page < 1) {
    return 0;
  }
  return page - 1;
};

const SIZES = [20, 30, 50];
export const convertStringToSize = (value: string | null) => {
  if (value === null) return ScreeningsStore.DefaultPager.size;
  const size = Number(value);
  // Set it to the default value if it is invalid.
  if (!SIZES.includes(size)) {
    return ScreeningsStore.DefaultPager.size;
  }
  return size;
};

export const convertStringToActiveStatus = (value: string | null): ScreeningSearchForm.ActiveStatus => {
  if (!value) {
    return null;
  }

  const activeStatusMap: Record<string, ScreeningSearchForm.ActiveStatus> = {
    ACTIVE: "ACTIVE",
    NOT_ACTIVE: "NOT_ACTIVE",
  };

  return activeStatusMap[value] ?? null;
};
