import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import * as React from "react";

import AppealMessageForm, { AppealMessageFormProps } from "../../../modules/AppealMessageForm/AppealMessageForm";
import Split, { SplitProps } from "../../../primitive/Split/Split";
import QuestionAndAnswerTab, { QuestionAndAnswerTabProps } from "../../Question/QuestionAndAnswerTab/QuestionAndAnswerTab";

export type ProjectCodingEditorSideBarProps = {
  /**
   * key to re-create instance
   */
  uniqueKey?: string;
  questionSection: QuestionAndAnswerTabProps["questionSection"];
  questionHintStack?: QuestionAndAnswerTabProps["questionHintStack"];
  appealMessage: AppealMessageFormProps;
};

const ProjectCodingEditorSideBar: React.FC<ProjectCodingEditorSideBarProps> = props => {
  const theme = useTheme();
  const questionAndAnswerTabProps: QuestionAndAnswerTabProps = {
    uniqueKey: props.uniqueKey,
    questionSection: props.questionSection,
    questionHintStack: props.questionHintStack,
    answerSections: [],
  };

  const splitterProps: SplitProps = {
    splitId: "QuestionAndAppealMessage",
    splitDirection: "HORIZONTAL",
    items: [
      {
        id: "QA",
        Content: (
          <Box key="qa" sx={{ overflowY: "auto", height: "100%" }}>
            <QuestionAndAnswerTab key="tab" {...questionAndAnswerTabProps} />
          </Box>
        ),
        size: {
          value: 65,
          unit: "%",
        },
        minSize: {
          value: 35,
          unit: "px",
        },
      },
      {
        id: "AppealMessage",
        Content: (
          <Box key="appeal-message" sx={{ overflowY: "auto", height: "100%" }}>
            <AppealMessageForm {...props.appealMessage} />
          </Box>
        ),
        size: {
          value: 35,
          unit: "%",
        },
        minSize: {
          value: 35,
          unit: "px",
        },
      },
    ],
  };

  return (
    <Box display="flex" flexDirection="column" height="100%" bgcolor={theme.palette["Secondary/Shades"].p12}>
      <Split {...splitterProps} />
    </Box>
  );
};

ProjectCodingEditorSideBar.displayName = "ProjectCodingEditorSideBar";

export default ProjectCodingEditorSideBar;
