import { state } from "./State";
import type * as Types from "./types";

export type InitializeBelongsCompaniesArgs = {
  activeCompanyId: number;
  companies: Types.Company[];
  pager: Types.Pager | undefined;
};

export const initializeBelongsCompanies = (args: InitializeBelongsCompaniesArgs) => {
  args.companies.forEach(company => {
    state.companyMap.set(company.companyId, company);
  });
  /**
   * Initial value is equivalent to value of activeCompanyId.
   */
  state.previousActiveCompanyId = args.activeCompanyId;
  state.activeCompanyId = args.activeCompanyId;
  state.pager = args.pager ?? null;
};

export const updateActiveCompanyId = (activeCompanyId: number): void => {
  state.previousActiveCompanyId = state.activeCompanyId;
  state.activeCompanyId = activeCompanyId;
};

export const updateErrorStatus = (errorStatus: Types.ErrorStatus) => {
  state.errorStatus = errorStatus;
};

export const clear = () => {
  state.previousActiveCompanyId = null;
  state.activeCompanyId = null;
  state.companyMap.clear();
  state.errorStatus = null;
};

export const addCompanies = (companies: Types.Company[], pager: Types.Pager | undefined) => {
  companies.forEach(company => {
    state.companyMap.set(company.companyId, company);
  });
  state.pager = pager ?? null;
};

export const addActiveCompany = (company: Types.Company) => {
  state.companyMap.set(company.companyId, company);
};
