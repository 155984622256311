import { Block, CumulativeLayoutShift, TestResult } from "@hireroo/evaluation-result-interface";

const tableBlock: Block = {
  type: "table",
  value: {
    headers: ["column1", "column2"],
    bodies: [
      ["cell1", "cell2"],
      ["cell3", "cell4"],
    ],
  },
};

const cls: CumulativeLayoutShift = {
  type: "cumulative_layout_shift",
  value: {
    line: {
      value: 0.05,
      label: "here",
    },
    needsImprovement: {
      start: 0.1,
      end: 0.25,
    },
    poor: {
      start: 0.25,
      end: 0.5,
    },
  },
};

export const testResultData: TestResult = {
  version: "v1.0",
  blocks: [
    {
      type: "header",
      value: {
        text: {
          ja: "Header",
          en: "Header",
        },
        startIcon: "CHECK",
      },
    },
    {
      type: "divider",
    },
    {
      type: "markdown",
      value: {
        text: {
          ja: "## markdown header\n\n- list item 1\n- list item 2\n\n```typescript\nconst a = 1;\n```",
          en: "## markdown header\n\n- list item 1\n- list item 2\n\n```typescript\nconst a = 1;\n```",
        },
      },
    },
    {
      type: "stepper",
      value: {
        title: {
          ja: "Stepper",
          en: "Stepper",
        },
        contents: [
          {
            type: "test_case_stepper_content",
            value: {
              mark: "CHECK",
              header: {
                ja: "Step 1",
                en: "Step 1",
              },
              testCaseResult: {
                input: "{}",
                output: "{}",
                expected: "{ 'test': 1 }",
              },
            },
          },
          {
            type: "test_case_stepper_content",
            value: {
              mark: "CHECK",
              header: {
                ja: "Step 2",
                en: "Step 2",
              },
              testCaseResult: {
                input: "{}",
                output: "{}",
                expected: "{ 'test': 2 }",
              },
            },
          },
        ],
      },
    },
    tableBlock,
    {
      type: "test_case_result",
      value: {
        input: "{}",
        output: "{}",
        expected: "{ 'test': 1 }",
      },
    },
    {
      type: "text_diff_viewer",
      value: {
        original: "{}",
        modified: "{ 'test': 1 }",
      },
    },
    cls,
  ],
  is_passed: true,
};
