import { FlowChart, type FlowChartProps, type FlowChartSideBarProps } from "@hireroo/system-design/react/v2";
import Box, { BoxProps } from "@mui/material/Box";
import * as React from "react";

import TestCaseDialog, { TestCaseDialogProps } from "../TestCaseDialog/TestCaseDialog";

export type SystemDesignFlowChartProps = {
  flowChart: FlowChartProps;
  /**
   * TODO @himenon move to SystemDesignCodingEditorProps
   */
  flowChartSidebar: Omit<FlowChartSideBarProps, "runTestcaseButton">;
  sx?: BoxProps["sx"];
  testCaseDialog: Pick<TestCaseDialogProps, "items"> & { dialog: Pick<TestCaseDialogProps["dialog"], "onClose"> };
};

const SystemDesignFlowChart: React.FC<SystemDesignFlowChartProps> = props => {
  const [openTestCaseDialog, setOpenTestCaseDialog] = React.useState(false);

  const flowChartProps: FlowChartProps = {
    ...props.flowChart,
    editControlPanel: {
      ...props.flowChart.editControlPanel,
      runButton: {
        onClick: () => {
          setOpenTestCaseDialog(true);
        },
      },
    },
    elementSettings: props.flowChart.elementSettings,
  };

  const testCaseDialogProps: TestCaseDialogProps = {
    ...props.testCaseDialog,
    dialog: {
      open: openTestCaseDialog,
      onClose: () => {
        props.testCaseDialog.dialog.onClose();
        setOpenTestCaseDialog(false);
      },
    },
  };

  return (
    <Box sx={props.sx} height="100%">
      <FlowChart {...flowChartProps} />
      <TestCaseDialog {...testCaseDialogProps} />
    </Box>
  );
};

SystemDesignFlowChart.displayName = "SystemDesignFlowChart";

export default SystemDesignFlowChart;
