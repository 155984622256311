import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateRemoteInterviewCandidateHeaderPropsArgs, useGenerateProps } from "./useGenerateProps";

export type RemoteInterviewCandidateHeaderContainerProps = GenerateRemoteInterviewCandidateHeaderPropsArgs;

const RemoteInterviewCandidateHeaderContainer: React.FC<RemoteInterviewCandidateHeaderContainerProps> = props => {
  const remoteInterviewCandidateHeaderProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.RemoteInterviewCandidateHeader {...remoteInterviewCandidateHeaderProps} />
    </ErrorBoundary>
  );
};

RemoteInterviewCandidateHeaderContainer.displayName = "RemoteInterviewCandidateHeaderContainer";

export default withErrorBoundary(RemoteInterviewCandidateHeaderContainer, {});
