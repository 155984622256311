import { useTranslation } from "@hireroo/i18n";
import { AssessmentResourceEditorForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import ResourceField from "../../../../modules/ResourceFieldV2/ResourceFieldV2";
import AssessmentReportShareSettingField from "../../../../ms-components/Assessment/AssessmentReportShareSettingField/AssessmentReportShareSettingField";
import InputControlTextField, {
  InputControlTextFieldProps,
} from "../../../../primitive/InputControl/InputControlTextField/InputControlTextField";
import SelectField, { SelectFieldProps } from "../../../../primitive/InputControl/SelectField/SelectField";
import { type LayoutController, useAssessmentResourceEditorV2Context } from "../../Context";

type FieldName = keyof AssessmentResourceEditorForm.ReportSetupFormSchema;

const ReservedFieldName = {
  MEMO: "memo",
  VIEWERS: "viewers",
  IS_PUBLIC: "isPublic",
  REPORT_SETTINGS: "reportSettings",
} satisfies Record<string, FieldName>;

const Subsection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  boxShadow: "none",
}));

const StyledSelectField = styled(SelectField)(({ theme }) => ({
  color: theme.palette.text.secondary,
  width: "fit-content",
  backgroundColor: theme.palette.Other.FilledInputBG,
  ".MuiSelect-outlined": {
    border: "none",
  },
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
    borderRadius: 8,
  },
  minWidth: 360,
}));

export type ReportSetupSectionProps = {
  onSubmit: SubmitHandler<AssessmentResourceEditorForm.ReportSetupFormSchema>;
  ViewerField: React.ReactNode;
  defaultValues: AssessmentResourceEditorForm.ReportSetupFormSchema;
};

const ReportSetupSection: React.FC<ReportSetupSectionProps> = props => {
  const { t } = useTranslation();
  const { onSubmit } = props;
  const { stepName, setController, submitValues, setSubmitValue } = useAssessmentResourceEditorV2Context();
  const validateSchema = AssessmentResourceEditorForm.useReportSetupForm();
  const methods = useForm<AssessmentResourceEditorForm.ReportSetupFormSchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: submitValues.REPORT_SETUP ?? props.defaultValues,
  });

  const controller = React.useMemo((): LayoutController => {
    return {
      checkGoNextStep: () => {
        return new Promise<boolean>(resolve => {
          methods.handleSubmit(
            fields => {
              onSubmit(fields);
              setSubmitValue("REPORT_SETUP", fields);
              resolve(true);
            },
            errors => {
              console.warn(errors);
              setSubmitValue("REPORT_SETUP", undefined);
              resolve(false);
            },
          )();
        });
      },
    };
  }, [methods, onSubmit, setSubmitValue]);

  React.useEffect(() => {
    setController("REPORT_SETUP", controller);
  }, [setController, controller]);

  const isPublic = methods.watch("isPublic");

  const memoFieldProps: InputControlTextFieldProps = {
    fullWidth: true,
    minRows: 3,
    maxRows: 10,
    multiline: true,
  };

  const selectFieldProps: SelectFieldProps = {
    color: "secondary",
    variant: "outlined",
    items: [
      {
        text: t("リンクを知っているメンバーが閲覧可能"),
        value: true,
      },
      {
        text: t("指定されたメンバーのみ閲覧可能"),
        value: false,
      },
    ],
    onChange: event => {
      if (event.target.value === true) {
        methods.setValue("viewers", []);
      } else if (event.target.value === false) {
        const editorUid = methods.getValues("editorUid");
        if (editorUid) {
          methods.setValue("viewers", [{ value: { type: "EMPLOYEE", employeeId: editorUid, locked: true } }]);
        }
      }
    },
  };

  if (stepName !== "REPORT_SETUP") {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <Stack py={2} spacing={1.5}>
        <Subsection>
          <Stack spacing={4}>
            <ResourceField
              label={t("評価者用メモ")}
              kind="NONE"
              pb={2}
              help={{
                kind: "DESCRIPTION",
                text: t("※") + t("この内容はタレントに共有されません"),
              }}
            >
              <InputControlTextField name={ReservedFieldName.MEMO} {...memoFieldProps} />
            </ResourceField>

            <ResourceField
              label={t("閲覧権限")}
              kind="NONE"
              help={{
                kind: "DESCRIPTION",
                text: t("閲覧権限の適用対象は候補者詳細ページ（レポート）が対象になります。"),
              }}
            >
              <Stack spacing={1}>
                <StyledSelectField name={ReservedFieldName.IS_PUBLIC} {...selectFieldProps} />
                {isPublic === false && props.ViewerField}
              </Stack>
            </ResourceField>
          </Stack>
        </Subsection>
        <Subsection>
          <ResourceField
            label={t("タレントに共有するレポート項目")}
            kind="NONE"
            help={{
              kind: "DESCRIPTION",
              text: t("タレントのテストレポート内容で共有する項目を選択できます。選択項目にない内容は常に共有されます。"),
            }}
          >
            <AssessmentReportShareSettingField namePrefix={ReservedFieldName.REPORT_SETTINGS} />
          </ResourceField>
        </Subsection>
      </Stack>
    </FormProvider>
  );
};

ReportSetupSection.displayName = "ReportSetupSection";

export default ReportSetupSection;
