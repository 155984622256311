import type * as Graphql from "@hireroo/graphql/client/urql";
import { useSnapshot } from "valtio";

import { state } from "./State";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = (): boolean => {
  const { company } = useSnapshotState();
  return !!company;
};

export const useCompany = (): Graphql.GetCompanyForGeneralSettingsCompanyFragment => {
  const { company } = useSnapshotState();
  if (!company) throw new Error("need to initialize");
  return company;
};
