import { ListItemButton, ListItemText, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";

import Tag from "../../../../primitive/Tag/Tag";

export type TagKind = "NEW" | "DISABLED";

export type QuestionVariantButtonProps = {
  title: string;
  description?: string;
  kind?: TagKind;
  onClick: () => void;
};

const QuestionVariantButton: React.FC<QuestionVariantButtonProps> = props => {
  const theme = useTheme();
  const tagMap: Record<TagKind, React.ReactNode> = {
    NEW: (
      <Box ml={1} display="inline">
        <Tag label="NEW" color="green" />
      </Box>
    ),
    DISABLED: (
      <Box ml={1} display="inline">
        <Tag label="COMING SOON" color="yellow" />
      </Box>
    ),
  };
  return (
    <Box mt={1}>
      <ListItemButton divider onClick={props.onClick} disabled={props.kind === "DISABLED"}>
        <ListItemText>
          <Box display="inline-flex">
            <Typography variant="subtitle1" component="div">
              {props.title}
            </Typography>
            {props.kind && tagMap[props.kind]}
          </Box>
          <Typography variant="body2" color={theme.palette.text.secondary}>
            {props.description}
          </Typography>
        </ListItemText>
      </ListItemButton>
    </Box>
  );
};

QuestionVariantButton.displayName = "QuestionVariantButton";

export default QuestionVariantButton;
