import * as React from "react";

import { createAction } from "./Action";
import { createHooks } from "./hooks";
import { createState, multiState } from "./State";
import type * as Types from "./types";

export type ContextValue = {
  action: ReturnType<typeof createAction>;
  hooks: ReturnType<typeof createHooks>;
};

export const SystemDesignContext = React.createContext<ContextValue>({} as ContextValue);

export const useSystemDesignContext = () => React.useContext(SystemDesignContext);

export type SystemDesignProviderProps = {
  id?: string;
  initialState?: Partial<Types.State>;
  options?: {
    disableSetStateOnUnmount: boolean;
  };
};

export const SystemDesignProvider: React.FC<React.PropsWithChildren<SystemDesignProviderProps>> = props => {
  const id: string = props.id || Math.random().toString();
  /**
   * Order is important
   */
  const state = createState(multiState.get(id) || props.initialState);
  const action = createAction(state);
  const hooks = createHooks(state);
  const contextValue: ContextValue = {
    action,
    hooks,
  };
  React.useEffect(() => {
    /**
     * TODO: @himenon refactor
     */
    return () => {
      if (props.options?.disableSetStateOnUnmount !== true) {
        multiState.set(id, state);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return <SystemDesignContext.Provider value={contextValue} children={props.children} />;
};
