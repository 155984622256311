import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";

import MultiLinePlaybackToolbar, { MultiLinePlaybackToolbarProps } from "./parts/MultiLinePlaybackToolbar/MultiLinePlaybackToolbar";
import QuestionWithEditor, { QuestionWithEditorProps } from "./parts/QuesitonWithEditor/QuestionWithEditor";

const PlaybackToolbarWrapper = styled(Box)`
  width: 100%;
  bottom: 0;
`;

export type RemoteInterviewEntityPlaybackProps = {
  content: Omit<QuestionWithEditorProps, "height">;
  playbackToolbar: MultiLinePlaybackToolbarProps;
};

const RemoteInterviewEntityPlayback: React.FC<RemoteInterviewEntityPlaybackProps> = props => {
  const playbackToolbar = useResizeDetector();
  const editor = useResizeDetector();

  return (
    <Box height="100%">
      <Box ref={editor.ref} height={`calc(100% - ${playbackToolbar.height}px)`}>
        <QuestionWithEditor {...props.content} height={editor.height} />
      </Box>
      <PlaybackToolbarWrapper ref={playbackToolbar.ref}>
        <MultiLinePlaybackToolbar {...props.playbackToolbar} />
      </PlaybackToolbarWrapper>
    </Box>
  );
};

RemoteInterviewEntityPlayback.displayName = "RemoteInterviewEntityPlayback";

export default RemoteInterviewEntityPlayback;
