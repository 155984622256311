import { subscribeKey } from "valtio/utils";

import { state } from "./State";
import * as Types from "./types";

export type SubscribeOnChangeExamCallback = (spot: Types.Exam) => void;
export const subscribeOnChangeExam = (callback: SubscribeOnChangeExamCallback) => {
  return subscribeKey(state, "exam", spot => {
    if (spot) {
      callback(spot);
    }
  });
};
