import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";

import GoogleSearchForm, { GoogleSearchFormProps } from "./parts/GoogleSearchForm/GoogleSearchForm";

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.0),
  backgroundColor: "white",
  height: "100%",
  overflowY: "auto",
}));

export type WebSearchSidePanelProps = {
  googleSearchForm: GoogleSearchFormProps;
};

const WebSearchSidePanel: React.FC<WebSearchSidePanelProps> = props => {
  return (
    <StyledBox>
      <GoogleSearchForm {...props.googleSearchForm} />
    </StyledBox>
  );
};

WebSearchSidePanel.displayName = "WebSearchSidePanel";

export default WebSearchSidePanel;
