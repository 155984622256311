import { SupportLanguage, useTranslation } from "@hireroo/i18n";
import * as z from "zod";

export const useContent = () => {
  const { t } = useTranslation();
  return z.object({
    title: z.string().min(1, { message: t("タイトルは必須項目です。") }),
    description: z.string().min(1, { message: t("本文は必須項目です。") }),
    language: z.enum([SupportLanguage.JA, SupportLanguage.EN]),
  });
};
export type AnswersContentSchema = z.infer<ReturnType<typeof useContent>>;

const useRuntime = () => {
  const { t } = useTranslation();
  return z.object({
    id: z.number().optional(),
    runtime: z.string(),
    codeBodies: z.record(z.string(), z.string().min(1, { message: t("解答コードは必須です。") })),
  });
};

export const AlgorithmAnswerLabel = z.union([z.literal("BRUTE_FORCE"), z.literal("SUB_OPTIMAL"), z.literal("OPTIMAL")]);

export type AlgorithmAnswerLabel = z.infer<typeof AlgorithmAnswerLabel>;

const useAnswerForm = () => {
  const { t } = useTranslation();
  const content = useContent();
  const runtime = useRuntime();
  return z.object({
    id: z.number().optional(),
    /**
     * TODO change AlgorithmAnswerLabel
     */
    label: z.string(),
    runtimeComplexity: z.string().min(1, { message: t("時間計算量は必須です。") }),
    spaceComplexity: z.string().min(1, { message: t("空間計算量は必須です。") }),
    contents: z.array(content),
    answerRuntimes: runtime.array(),
  });
};

export const useAnswersFormSchema = () => {
  const answer = useAnswerForm();
  return z.object({
    answers: answer.array(),
  });
};

export type AlgorithmAnswersFormSchema = z.infer<ReturnType<typeof useAnswersFormSchema>>;
