import { generatePlaceholder } from "@hireroo/challenge/definition";
import { useTranslation } from "@hireroo/i18n";
import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { ChallengeCodingEditorContextProps } from "../../../Context";

const StyledInput = styled("input")(({ theme }) => ({
  color: theme.palette.text.primary,
  borderRadius: "4px",
  border: "none",
  fontFamily: 'Menlo, Monaco, "Courier New", monospace',
  paddingLeft: "8px",
  outline: "none",
  height: "36px",
}));

type AlgorithmInputsType = Extract<ChallengeCodingEditorContextProps["signatureProps"], { variant: "ALGORITHM" }>["signature"]["inputs"];

export type AlgorithmInputProps = {
  inputs: AlgorithmInputsType;
  consoleValue: string[];
  readonly?: boolean;
  errorMap?: Record<string, boolean>;
  inputStyle?: React.CSSProperties;
  consoleValueChange: (index: number, text: string, type?: string) => void;
};

const AlgorithmInput: React.FC<AlgorithmInputProps> = props => {
  const { t } = useTranslation();
  const { consoleValueChange } = props;

  return (
    <Box>
      {props.inputs.map((input, index) => (
        <Stack key={`${input.name}-${input.type}`} spacing={1}>
          <Typography variant="subtitle2" sx={{ wordBreak: "keep-all", whiteSpace: "nowrap" }}>{`${input.name} (${input.type})`}</Typography>
          <StyledInput
            sx={{
              ...props.inputStyle,
            }}
            placeholder={generatePlaceholder(input.type)}
            readOnly={props.readonly}
            value={props.consoleValue?.[index]}
            onChange={e => consoleValueChange(index, e.target.value, input.type)}
          />
          <FormHelperText
            error={props.errorMap && props.errorMap[String(index)]}
            children={props.errorMap && props.errorMap[String(index)] && t("型情報と異なる入力値が入力されています。")}
          />
        </Stack>
      ))}
    </Box>
  );
};

AlgorithmInput.displayName = "AlgorithmInput";

export default AlgorithmInput;
