import { useTranslation } from "@hireroo/i18n";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import SwitchControl, { SwitchControlProps } from "../../../../primitive/InputControl/SwitchControl/SwitchControl";

const Item = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(1.5),
  borderBottom: "1px solid",
  borderColor: theme.palette["Secondary/Shades"].p12,
}));

const StyledTypography = styled(Typography)({
  fontWeight: 700,
});

type Item = {
  title: string;
  name: string;
  isTop?: boolean;
};

export type InterviewFieldsProps = {
  disabled?: boolean;
};

const InterviewFields: React.FC<InterviewFieldsProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const method = useFormContext();

  const commonSwitchControl: SwitchControlProps = {
    color: "secondary",
    size: "small",
    control: method.control,
    disabled: props.disabled,
  };

  const items: Item[] = [
    {
      title: t("インタビュー作成時"),
      name: "remoteSettings.subscribeRemoteCreated",
      isTop: true,
    },
    {
      title: t("インタビュー待機時"),
      name: "remoteSettings.subscribeRemoteAwaiting",
      isTop: true,
    },
    {
      title: t("インタビュー開始時"),
      name: "remoteSettings.subscribeRemoteStarted",
    },
    {
      title: t("インタビュー終了時"),
      name: "remoteSettings.subscribeRemoteCompleted",
    },
    {
      title: t("インタビューレビュー時"),
      name: "remoteSettings.subscribeRemoteReviewed",
    },
    {
      title: t("インタビュー評価時"),
      name: "remoteSettings.subscribeRemoteEvaluated",
    },
    {
      title: t("インタビュー削除時"),
      name: "remoteSettings.subscribeRemoteDeleted",
    },
  ];

  return (
    <Stack direction="column">
      <Box display="flex" justifyContent="flex-start">
        <StyledTypography fontSize={14} mb={1.5}>
          {t("インタビュー通知")}
        </StyledTypography>
      </Box>

      <Box
        sx={{
          display: "grid",
          columnGap: 2,
          gridTemplateColumns: "repeat(2, 1fr)",
          width: "100%",
        }}
      >
        {items.map(item => (
          <Item key={item.title} sx={item.isTop ? { borderTop: "1px solid", borderColor: theme.palette["Secondary/Shades"].p12 } : undefined}>
            <Typography fontSize={14}>{item.title}</Typography>
            <Box>
              <SwitchControl name={item.name} {...commonSwitchControl} />
            </Box>
          </Item>
        ))}
      </Box>
    </Stack>
  );
};

InterviewFields.displayName = "InterviewFields";

export default InterviewFields;
