import ScoreHistogramBase, { ScoreHistogramProps as ScoreHistogramBaseProps } from "@hireroo/charts/react/ScoreHistogram";
import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";

const Wrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 3, 2, 1),
  width: "100%",
  height: 250,
}));

export type ScoreHistogramProps = Pick<ScoreHistogramBaseProps, "data" | "dataKey">;

const ScoreHistogram: React.FC<ScoreHistogramProps> = props => {
  const { t } = useTranslation();
  const scoreGraph: ScoreHistogramBaseProps = {
    ...props,
    xAxis: {
      label: t("スコア（%）"),
      minTickGap: -20,
    },
    yAxis: {
      label: t("受験者数（人）"),
    },
    lines: [],
    bar: {
      barSize: 20,
    },
  };
  return (
    <Wrapper>
      <React.Suspense>
        <ScoreHistogramBase {...scoreGraph} />
      </React.Suspense>
    </Wrapper>
  );
};

ScoreHistogram.displayName = "ScoreHistogram";

export default ScoreHistogram;
