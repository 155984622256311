import TableBody, { TableBodyProps } from "@mui/material/TableBody";
import * as React from "react";

export type DivTableBodyProps = TableBodyProps;

const DivTableBody: React.FC<DivTableBodyProps> = props => {
  return <TableBody {...props} component="div" />;
};

DivTableBody.displayName = "DivTableBody";

export default DivTableBody;
