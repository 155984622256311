import { LATEST_VERSION } from "@hireroo/app-definition/question";
import { App } from "@hireroo/app-store/essential/employee";
import { QuestionsChallengeId } from "@hireroo/app-store/page/e/questions_challenge_id";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

import ErrorPanelContainer from "../../../../../../widget/v2/e/ErrorPanel/Container";
import ChallengeCodingEditorWithDetailMiddleContainer, { ChallengeQuestionDetailMiddleContainerProps } from "./MiddleContainer";

const ChallengeCodingEditorWithDetailFetchContainer: React.FC = () => {
  const appStatus = App.useStatus();
  const { id } = useParams<{ id: string }>();
  const [query] = useSearchParams();
  const questionVersion = query.get("version") ?? LATEST_VERSION;
  const questionUid = `${id}-${questionVersion}`;
  const initialized = QuestionsChallengeId.useInitialized(questionUid);

  const [result] = Graphql.useGetAlgorithmQuestionForQuestionsChallengeIdQuery({
    variables: {
      questionId: Number(id),
      questionVersion: questionVersion,
      withAnswers: true,
      withPerformanceTcs: false,
    },
    pause: appStatus !== "INITIALIZED",
  });
  React.useEffect(() => {
    if (result.data?.algorithmQuestion) {
      QuestionsChallengeId.initialize(result.data.algorithmQuestion, questionVersion === LATEST_VERSION);
    }
  }, [questionVersion, result.data]);

  if (result.error) {
    return <ErrorPanelContainer graphqlErrors={result.error.graphQLErrors} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER" />;
  }

  const containerProps: ChallengeQuestionDetailMiddleContainerProps = {
    questionUid: questionUid,
  };
  return (
    <ErrorBoundary>
      <ChallengeCodingEditorWithDetailMiddleContainer {...containerProps} />
    </ErrorBoundary>
  );
};

ChallengeCodingEditorWithDetailFetchContainer.displayName = "ChallengeCodingEditorWithDetailFetchContainer";

export default withErrorBoundary(ChallengeCodingEditorWithDetailFetchContainer, {});
