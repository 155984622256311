import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Auth } from "@hireroo/app-store/essential/employee";
import { QuestionQuizPackageResourceEditor } from "@hireroo/app-store/view-domain/QuestionQuizPackageResourceEditor";
import { QuizPackageDetail } from "@hireroo/app-store/widget/e/QuizPackageDetail";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import type * as Graphql from "@hireroo/graphql/client/graphql-request";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useTranslation } from "@hireroo/i18n";
import { type Pages } from "@hireroo/presentation/legacy";
import { updateQueryParams } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/browser";

import { useGenerateQuizResourceEditorProps } from "../../../props-factory/e/QuizResourceEditor/useGenerateQuizResourceEditorProps";

export type GenerateQuizResourceEditorPropsArgs = {
  companyId: number;
};

export const useGenerateProps = (args: GenerateQuizResourceEditorPropsArgs): Pages.QuizResourceEditorProps => {
  const client = getGraphqlClient();
  const { t } = useTranslation();
  const userId = Auth.useCurrentUid();
  const questionSequence = QuestionQuizPackageResourceEditor.useOrderOfQuestionIds();
  const selectedQuestionMap = QuestionQuizPackageResourceEditor.useSelectedQuestionMap();
  const getQuestionKeys = QuestionQuizPackageResourceEditor.useGenerateOrderedQuestionKeys();
  const current = QuizPackageDetail.useCurrentCreateMultiChoicePackage();
  const navigate = useTransitionNavigate();
  const selectedPrivateQuestions = QuestionQuizPackageResourceEditor.useSelectedPrivateQuestions();

  return useGenerateQuizResourceEditorProps({
    companyId: args.companyId,
    saveSelectQuestionsButton: {
      onClick: () => {
        if (!current) {
          client
            .CreateMultiChoicePackage({
              createMultiChoicePackage: {
                companyId: args.companyId,
                employeeId: userId,
                questionKeys: getQuestionKeys(),
              },
            })
            .then(res => {
              updateQueryParams("id", [res.createMultiChoicePackage.id]);
              QuizPackageDetail.setCreatingMultiChoicePackage(res.createMultiChoicePackage);
              Snackbar.notify({
                severity: "success",
                message: t("問題の一時保存が完了しました。"),
              });
              QuestionQuizPackageResourceEditor.updateActiveStep(QuestionQuizPackageResourceEditor.STEP.PACKAGE_DETAIL);
            })
            .catch(error => {
              Sentry.captureException(error);
              const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                error,
                t("問題の一時保存に失敗しました。しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
              );
              Snackbar.notify({
                severity: "error",
                message: errorNotification.message,
              });
            });
        } else {
          const isPublished = current.status === "PUBLISHED";
          client
            .UpdateMultiChoicePackage({
              updateMultiChoicePackage: {
                id: current.packageId,
                employeeId: userId,
                companyId: args.companyId,
                questionKeys: getQuestionKeys(),
                // The following has already been
                titleJa: current.titleJa,
                titleEn: current.titleEn,
                descriptionJa: current.descriptionJa,
                descriptionEn: current.descriptionEn,
                difficulty: current.difficulty,
                isPrivate: current.isPrivate,
                status: "DRAFT",
                newVersion: current.status === "PUBLISHED" ? `${current.version}-${Date.now()}` : current.version,
                oldVersion: current.version,
                timeLimitSeconds: current.timeLimitSeconds,
              },
            })
            .then(res => {
              if (isPublished) {
                navigate("/e/questions/quiz/:id/update", {
                  pathParams: {
                    id: res.updateMultiChoicePackage.id,
                  },
                  queryParams: {
                    version: res.updateMultiChoicePackage.version,
                  },
                });
                return;
              }
              if (res.updateMultiChoicePackage.status === "PUBLISHED") {
                updateQueryParams("id", [res.updateMultiChoicePackage.id]);
              }
              Snackbar.notify({
                severity: "success",
                message: t("問題の一時保存が完了しました。"),
              });
              QuestionQuizPackageResourceEditor.updateActiveStep(QuestionQuizPackageResourceEditor.STEP.PACKAGE_DETAIL);
            })
            .catch(error => {
              Sentry.captureException(error);
              const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                error,
                // TODO 選択されている非公開の設問を全て「除く」か「公開」に変更してください。
                t("問題の一時保存に失敗しました。しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
              );
              Snackbar.notify({
                severity: "error",
                message: errorNotification.message,
              });
            });
        }
      },
    },
    onSubmitQuizPackageForm: fields => {
      if (!current) {
        return;
      }
      if (fields.isPublic && selectedPrivateQuestions.length > 0) {
        QuestionQuizPackageResourceEditor.openWarningDialog();
        return;
      }
      const questionKeys = questionSequence.reduce<Graphql.CompositePrimaryKey[]>((all, questionId) => {
        const question = selectedQuestionMap.get(questionId);
        if (!question) {
          return all;
        }
        return all.concat({
          questionId: question.id,
          version: question.version,
        });
      }, []);
      client
        .UpdateMultiChoicePackage({
          updateMultiChoicePackage: {
            id: current.packageId,
            employeeId: userId,
            companyId: args.companyId,
            titleJa: fields.packageDetails.ja?.title,
            titleEn: fields.packageDetails.en?.title,
            descriptionJa: fields.packageDetails.ja?.description,
            descriptionEn: fields.packageDetails.en?.description,
            difficulty: fields.difficulty,
            isPrivate: !fields.isPublic,
            status: "REVIEWING",
            newVersion: fields.newVersion,
            oldVersion: fields.oldVersion,
            questionKeys: questionKeys,
            timeLimitSeconds: fields.timeLimit * 60,
          },
        })
        .then(() => {
          Snackbar.notify({
            severity: "success",
            message: t("問題の一時保存が完了しました。"),
          });
          navigate("/e/questions");
        })
        .catch(error => {
          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(
            error,
            t("問題の一時保存に失敗しました。しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
          );
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
        });
    },
  });
};
