import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type ScreeningTestActivityLogContainerProps = {};

const ScreeningTestActivityLogContainer: React.FC<ScreeningTestActivityLogContainerProps> = () => {
  const screeningTestActivityLogProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.ScreeningTestActivityLog {...screeningTestActivityLogProps} />
    </ErrorBoundary>
  );
};

ScreeningTestActivityLogContainer.displayName = "ScreeningTestActivityLogContainer";

export default withErrorBoundary(ScreeningTestActivityLogContainer, {});
