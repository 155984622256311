import { colorFromUserId } from "@hireroo/app-helper/color";
import { clamp, marksToRanges, safePercent, valueToClampedPercent } from "@hireroo/app-helper/remote";
import { RemoteInterviewPlayback } from "@hireroo/app-store/widget/e/RemoteInterviewPlayback";
import * as Time from "@hireroo/formatter/time";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

const MIN = 0;

type ParticipantSlider = Widget.RemoteInterviewEntityPlaybackProps["playbackToolbar"]["participantSliders"][number];
export const useGeneratePlaybackToolbarProps = (): Widget.RemoteInterviewEntityPlaybackProps["playbackToolbar"] => {
  const { t } = useTranslation();
  const sliderValue = RemoteInterviewPlayback.useSliderValue();
  const playbackManager = RemoteInterviewPlayback.usePlaybackManager();
  const [passedTime, setPassedTime] = React.useState("");
  const sortedParticipants = RemoteInterviewPlayback.useSortedParticipants();
  const lastRevisionIndex = playbackManager?.lastTickIndex ?? 0;

  const authorMap = RemoteInterviewPlayback.useChallengeAuthorMap();

  const remainTime = React.useMemo(() => {
    const endTime = playbackManager?.timeStamps.at(-1) ?? 0;
    const startTime = playbackManager?.timeStamps.at(0) ?? 0;

    return Time.elapsedTimeFormat(endTime - startTime);
  }, [playbackManager]);

  const handleChangeSliderValue = React.useCallback(
    (
      value: number,
      params?: {
        isTouched: boolean;
      },
    ) => {
      RemoteInterviewPlayback.updateSliderValue(value);
      if (!params?.isTouched) {
        playbackManager?.setTickIndex(value);
      }
    },
    [playbackManager],
  );

  const filteredParticipants = React.useMemo(() => {
    return sortedParticipants.filter(participant => {
      // filter participants who have marks
      const marks = authorMap.get(participant.userId) ?? [];
      return marks.length > 0;
    });
  }, [authorMap, sortedParticipants]);

  const participantSliders = React.useMemo((): ParticipantSlider[] => {
    return filteredParticipants.map((participant): ParticipantSlider => {
      const percent = safePercent(valueToClampedPercent(sliderValue, MIN, lastRevisionIndex));
      const marks = authorMap.get(participant.userId) ?? [];
      const ranges = marksToRanges(marks);

      // Sum over range times
      const overRangeElapsedTimeMs = ranges
        .filter(range => range.end.value <= sliderValue)
        .map(range => range.end.timeStampInMilliseconds - range.start.timeStampInMilliseconds)
        .reduce((a, b) => a + b, 0);

      // If there is a value in playback, add the elapsed time for the current range.
      const currentTimeMs = marks.find(mark => mark.value === sliderValue)?.timeStampInMilliseconds ?? 0;
      const currentRange = ranges.find(
        range => range.start.timeStampInMilliseconds < currentTimeMs && range.end.timeStampInMilliseconds > currentTimeMs,
      );
      const currentRangeElapsedTimeMs = currentRange ? currentTimeMs - currentRange.start.timeStampInMilliseconds : 0;

      const timeMs = overRangeElapsedTimeMs + currentRangeElapsedTimeMs;

      const endTimeMs = ranges.map(range => range.end.timeStampInMilliseconds - range.start.timeStampInMilliseconds).reduce((a, b) => a + b, 0);
      return {
        id: participant.userId,
        iconColor: colorFromUserId(participant.userId),
        name: participant.userName || t("匿名ユーザー"),
        slider: {
          rectMarks: ranges.map(range => {
            const offset = safePercent(valueToClampedPercent(range.start.value, MIN, lastRevisionIndex));
            const width = valueToClampedPercent(range.end.value + 1 - range.start.value, MIN, lastRevisionIndex);
            return {
              id: range.start.value.toString(),
              x: `${offset}%`,
              width: `${width}%`,
            };
          }),
          thumbRect: {
            x: `calc(${percent}% - 1px)`,
          },
        },
        remainTime: Time.elapsedTimeFormat(endTimeMs),
        passedTime: Time.elapsedTimeFormat(clamp(timeMs, MIN, endTimeMs)),
      };
    });
  }, [authorMap, filteredParticipants, lastRevisionIndex, sliderValue, t]);

  React.useEffect(() => {
    const cleanupReceiveTickEvent = playbackManager.onReceiveTickEvent(() => {
      const startTime = playbackManager.timeStamps.at(0) ?? 0;
      const currentTime = playbackManager.currentTimeStamp ?? 0;
      setPassedTime(Time.elapsedTimeFormat(currentTime - startTime));
    });
    playbackManager.refresh();

    return () => {
      cleanupReceiveTickEvent();
    };
  }, [playbackManager]);

  return {
    disabledAll: false,
    value: sliderValue,
    slider: {
      max: lastRevisionIndex,
      min: MIN,
    },
    onChangePlayStatus: React.useCallback((playStatus: RemoteInterviewPlayback.PlayStatus) => {
      RemoteInterviewPlayback.updatePlayStatus(playStatus);
    }, []),
    onChangePlaybackValue: handleChangeSliderValue,
    enableAutoplay: true,
    defaultExpandStatus: "EXPAND_MORE",
    remainTime: remainTime,
    passedTime: passedTime,
    participantSliders: participantSliders,
  };
};
