import * as echarts from "echarts";

export type DataItem = {
  name: string;
  value: number;
};

export type CreateChartArgs = {
  dom: HTMLDivElement;
  items: DataItem[];
  styles: {
    textColor: string;
    subTextColor: string;
  };
};

export const createChart = (args: CreateChartArgs): echarts.ECharts => {
  const { dom, items, styles } = args;
  const seriesSources: DataItem[][] = [items];
  const chart = echarts.init(dom, null, {
    renderer: "canvas",
    ssr: echarts.env.node,
  });
  const option: echarts.EChartsOption = {
    series: seriesSources.map((data): echarts.SeriesOption => {
      return {
        type: "pie",
        radius: [55, 75],
        top: "center",
        left: "center",
        width: "100%",
        height: "100%",
        label: {
          alignTo: "edge",
          formatter: "{name|{b}}\n{time|{c} %}",
          minMargin: 5,
          edgeDistance: 0,
          lineHeight: 24,
          rich: {
            name: {
              fontWeight: "bold",
              color: styles.subTextColor,
              fontSize: 12,
            },
            time: {
              fontWeight: "bold",
              color: styles.textColor,
              fontSize: 18,
            },
          },
        },
        labelLine: {
          length: 15,
          length2: 0,
          maxSurfaceAngle: 80,
        },
        tooltip: {
          trigger: "item",
        },
        labelLayout: (_params: echarts.LabelLayoutOptionCallbackParams) => {
          return {
            draggable: true,
            moveOverlap: "shiftY",
          };
        },
        data: data,
      };
    }),
    animation: false,
  };
  chart.setOption(option);
  return chart;
};
