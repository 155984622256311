import { proxy } from "valtio";
import { proxyMap } from "valtio/utils";

import * as Def from "./definitions";
import type * as Types from "./types";

export const state = proxy<Types.State>({
  nextPager: null,
  company: null,
  page: Def.DEFAULT_PAGE,
  purchasesResponses: proxyMap(),
  fetchSize: Def.DEFAULT_FETCH_SIZE,
  purchasePeriod: Def.DEFAULT_PURCHASE_PERIOD,
});
