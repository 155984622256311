import { createStandaloneCell, YCodeCell, YMarkdownCell, YRawCell } from "@jupyter/ydoc";
import { Cell, CodeCell, CodeCellModel, MarkdownCell, MarkdownCellModel, RawCell, RawCellModel } from "@jupyterlab/cells";
import { ICell, ILanguageInfoMetadata } from "@jupyterlab/nbformat";
import { UUID } from "@lumino/coreutils";
import * as React from "react";

import Lumino from "../Lumino/Lumino";
import { factoryService, rendermime } from "./privateHelper";

export type InputViewerProps = {
  cell: ICell;
  languageInfo?: ILanguageInfoMetadata;
};

const InputViewer: React.FC<InputViewerProps> = props => {
  const { cell, languageInfo } = props;
  const id = (cell.id as string) || UUID.uuid4();
  switch (cell.cell_type) {
    case "code": {
      const codeCell = new CodeCell({
        rendermime,
        model: new CodeCellModel({
          sharedModel: createStandaloneCell(cell) as YCodeCell,
        }),
        editorConfig: {
          readOnly: true,
        },
        contentFactory: new Cell.ContentFactory({
          editorFactory: factoryService.newInlineEditor.bind(factoryService),
        }),
      }).initializeState();
      if (languageInfo && languageInfo.mimetype) {
        codeCell.model.mimeType = languageInfo.mimetype;
      }
      codeCell.readOnly = true;
      return <Lumino id={id}>{codeCell}</Lumino>;
    }
    case "markdown": {
      const markdownCell = new MarkdownCell({
        rendermime,
        showEditorForReadOnlyMarkdown: false,
        model: new MarkdownCellModel({
          sharedModel: createStandaloneCell(cell) as YMarkdownCell,
        }),
        contentFactory: new Cell.ContentFactory({
          editorFactory: factoryService.newInlineEditor.bind(factoryService),
        }),
      }).initializeState();
      return <Lumino id={id}>{markdownCell}</Lumino>;
    }
    case "raw": {
      const rawCell = new RawCell({
        model: new RawCellModel({
          sharedModel: createStandaloneCell(cell) as YRawCell,
        }),
        contentFactory: new Cell.ContentFactory({
          editorFactory: factoryService.newInlineEditor.bind(factoryService),
        }),
      });
      return <Lumino id={id}>{rawCell}</Lumino>;
    }
    default: {
      return <></>;
    }
  }
};

InputViewer.displayName = "InputViewer";

export default InputViewer;
