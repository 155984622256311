import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import * as React from "react";

import TablePagination, { TablePaginationProps } from "../../modules/TablePagination/TablePagination";
import ScreeningTable, { ScreeningTableProps } from "./parts/ScreeningTable/ScreeningTable";
import SearchResultBar, { SearchResultBarProps } from "./parts/SearchResultBar/SearchResultBar";

export type ScreeningListProps = {
  pagination: TablePaginationProps;
  table: ScreeningTableProps;
  searchResultBar: SearchResultBarProps;
  refreshKey?: string;
};

const ScreeningList: React.FC<ScreeningListProps> = props => {
  return (
    <Paper sx={{ boxShadow: "none", borderRadius: "16px" }} elevation={2}>
      <SearchResultBar {...props.searchResultBar} />
      <ScreeningTable key={props.refreshKey} {...props.table} />
      <Box display="flex" justifyContent="flex-end">
        <TablePagination {...props.pagination} />
      </Box>
    </Paper>
  );
};

ScreeningList.displayName = "ScreeningList";

export default ScreeningList;
