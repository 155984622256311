import { useSnapshot } from "valtio";

import { state } from "./State";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useCompanies = () => {
  const snapshot = useSnapshotState();
  return Array.from(snapshot.companyMap.values());
};

export const useCompanyMap = () => {
  const snapshot = useSnapshotState();
  return snapshot.companyMap;
};

/**
 * @deprecated Please use useStrictActiveCompanyId
 */
export const useActiveCompanyId = () => {
  const snapshot = useSnapshotState();
  return snapshot.activeCompanyId;
};

export const useStrictActiveCompanyId = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.activeCompanyId) {
    throw new Error("Please Initialize Company Store");
  }
  return snapshot.activeCompanyId;
};

export const useActiveCompany = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.activeCompanyId) {
    return null;
  }
  return snapshot.companyMap.get(snapshot.activeCompanyId);
};

export const useStrictActiveCompany = () => {
  const company = useActiveCompany();
  if (!company) {
    throw new Error(`Not found Active Company`);
  }
  return company;
};

export const useIsHireroo = () => {
  const company = useStrictActiveCompany();
  return company.isHireRoo;
};

export const useErrorStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.errorStatus;
};

export const useCompaniesPager = () => {
  const snapshot = useSnapshotState();
  return snapshot.pager;
};
