import { useTranslation } from "@hireroo/i18n";
import Add from "@mui/icons-material/Add";
import Divider from "@mui/material/Divider";
import Stack, { StackProps } from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";

import ButtonWithTooltip, { ButtonWithTooltipProps } from "../../primitive/Button/ButtonWithTooltip/ButtonWithTooltip";
import NavigationMenu from "./parts/NavigationMenu/NavigationMenu";
import PopupMenu from "./parts/PopupMenu/PopupMenu";
import type { MenuItem } from "./types";

type Mode = "MINIMIZED" | "EXPANDED";

const StyledButtonWithTooltip = styled(ButtonWithTooltip)(() => ({
  minWidth: "unset",
}));

export type EmployeeSideBarContentProps = {
  mode: Mode;
  CompanySelect: React.ReactNode;
  menuItems: MenuItem[];
  remainSelections: number;
  createButton: Pick<ButtonWithTooltipProps, "onClick">;
  addButton: ButtonWithTooltipProps;
};

const EmployeeSideBarContent: React.FC<EmployeeSideBarContentProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const createButtonPropsMap: Record<Mode, ButtonWithTooltipProps> = {
    MINIMIZED: {
      ...props.createButton,
      fullWidth: true,
      size: "medium",
      variant: "outlined",
      children: <Add />,
      sx: {
        width: 36,
        height: 36,
      },
    },
    EXPANDED: {
      ...props.createButton,
      fullWidth: true,
      startIcon: "ADD",
      size: "medium",
      variant: "contained",
      children: t("作成"),
    },
  };

  const wrapperStackProps: StackProps = {
    direction: "column",
    spacing: 2,
    divider: (
      <Divider
        sx={{
          borderColor: theme.palette["Other"].WeakDivider,
          width: "100%",
        }}
      />
    ),
    sx:
      props.mode === "EXPANDED"
        ? {
            py: 3,
            px: 2,
          }
        : {
            py: 2,
            px: 0.5,
            justifyContent: "center",
            alignItems: "center",
          },
  };
  return (
    <Stack {...wrapperStackProps}>
      <Stack sx={{ alignItems: "center" }}>{props.CompanySelect}</Stack>
      <Tooltip title={props.mode === "MINIMIZED" ? t("作成") : ""} placement="left-start">
        <span>
          <StyledButtonWithTooltip {...createButtonPropsMap[props.mode]} />
        </span>
      </Tooltip>
      {props.mode === "EXPANDED" && <NavigationMenu root items={props.menuItems} />}
      {props.mode === "MINIMIZED" && <PopupMenu root items={props.menuItems} />}
    </Stack>
  );
};

EmployeeSideBarContent.displayName = "EmployeeSideBarContent";

export default EmployeeSideBarContent;
