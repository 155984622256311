import { createPlaybackManager } from "@hireroo/app-helper/challenge";
import { ChallengePlayback } from "@hireroo/app-store/widget/shared/ChallengePlayback";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import ChallengePlaybackContainer, { ChallengePlaybackContainerProps } from "./Container";

export type ChallengePlaybackFetchContainerProps = {
  challengeId: number;
  question: ChallengePlayback.AlgorithmQuestion;
  sessionIds: ChallengePlayback.ChallengeSessionIds;
  submission: ChallengePlayback.Submission;
  appealMessage: string | null;
  playbackAccess: ChallengePlaybackContainerProps["playbackAccess"];
  canShowPasteAndTabStatistics: boolean;
  canShowCheatDetectionSection: boolean;
  canShowStatistic: boolean;
};

const ChallengePlaybackFetchContainer: React.FC<ChallengePlaybackFetchContainerProps> = props => {
  const initialized = ChallengePlayback.useInitialized();
  React.useEffect(() => {
    createPlaybackManager({
      challengeId: props.challengeId,
      questionId: props.question.questionId,
      runtime: props.submission.runtime,
      chatSessionId: props.sessionIds.chatGPTSessionId,
      webSessionId: props.sessionIds.webSessionId,
      useClipboardEvents: props.canShowPasteAndTabStatistics,
    }).then(instance => {
      instance.setTickIndex(instance.lastTickIndex);
      ChallengePlayback.initialize({
        playbackManager: instance,
        question: props.question,
        submission: props.submission,
        sessionIds: props.sessionIds,
        appealMessage: props.appealMessage,
      });
    });
    return () => {
      ChallengePlayback.clear();
    };
  }, [props]);

  if (!initialized) {
    return <Widget.Loading kind="CENTER_%" color="secondary" />;
  }

  const challengePlaybackContainerProps: ChallengePlaybackContainerProps = {
    challengeId: props.challengeId,
    question: props.question,
    submissionId: props.submission.challengeSubmissionId,
    playbackAccess: props.playbackAccess,
    canShowPasteAndTabStatistics: props.canShowPasteAndTabStatistics,
    canShowCheatDetectionSection: props.canShowCheatDetectionSection,
    canShowStatistic: props.canShowStatistic,
  };

  return (
    <ErrorBoundary>
      <ChallengePlaybackContainer {...challengePlaybackContainerProps} />
    </ErrorBoundary>
  );
};

ChallengePlaybackFetchContainer.displayName = "ChallengePlaybackFetchContainer";

export default withErrorBoundary(ChallengePlaybackFetchContainer, {});
