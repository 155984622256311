import { App, Auth } from "@hireroo/app-store/essential/employee";
import { QuestionSelectFieldForResourceEditor } from "@hireroo/app-store/widget/e/QuestionSelectFieldForResourceEditor";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { Fields } from "@hireroo/validator";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import RemoteInterviewResourceEditorMiddleContainer, { RemoteInterviewResourceEditorMiddleContainerProps } from "./MiddleContainer";
import * as PrivateHelper from "./privateHelper";

export type RemoteInterviewResourceEditorFetchContainerProps = RemoteInterviewResourceEditorMiddleContainerProps & {
  remoteId: string;
};

const RemoteInterviewResourceEditorFetchContainer: React.FC<RemoteInterviewResourceEditorFetchContainerProps> = props => {
  const appStatus = App.useStatus();
  const currentUid = Auth.useCurrentUid();
  const { remoteId, ...rest } = props;
  const [result, refresh] = Graphql.useGetRemoteForRemoteInterviewResourceEditorV2Query({
    variables: {
      remoteId: remoteId,
    },
    requestPolicy: "network-only",
    pause: appStatus !== "INITIALIZED",
  });

  const viewers = React.useMemo((): Fields.AssignField.AssignListItemSchema[] => {
    const { data } = result;
    const defaultViewers = (data?.remote.viewers ?? []).reduce<Fields.AssignField.AssignListItemSchema[]>((all, viewer) => {
      switch (viewer.__typename) {
        case "EmployeeGroup": {
          return all.concat({
            value: {
              type: "EMPLOYEE_GROUP",
              groupId: viewer.employeeGroupId,
            },
          });
        }
        case "User": {
          return all.concat({
            value: {
              type: "EMPLOYEE",
              employeeId: viewer.uid,
              locked: viewer.uid === currentUid,
            },
          });
        }
        case undefined:
          return all;
        default:
          throw new Error(`Invalid type: ${viewer satisfies never}`);
      }
      return all;
    }, []);
    const hasCurrentUser = defaultViewers.some(viewer => viewer.value?.type === "EMPLOYEE" && viewer.value.employeeId === currentUid);

    return hasCurrentUser
      ? defaultViewers
      : [
          {
            value: {
              type: "EMPLOYEE",
              employeeId: currentUid,
              locked: true,
            },
          },
          ...defaultViewers,
        ];
  }, [currentUid, result]);

  React.useEffect(() => {
    if (result.data?.remote) {
      QuestionSelectFieldForResourceEditor.setQuestionByEntitySourceQuestions(
        PrivateHelper.convertSessionsToEntitySourceQuestions(result.data.remote.entity?.liveCoding?.sessions ?? []),
      );
    }
  }, [result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED") {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: RemoteInterviewResourceEditorMiddleContainerProps = {
    ...rest,
    defaultValues: {
      remoteQuestionSetup: {
        name: result.data?.remote?.name || "",
        candidateName: result.data?.remote?.candidateName || "",
        entityTracks: PrivateHelper.convertEntityTracksValidatorFromGraphql(result.data?.remote.entity?.liveCoding?.sessions ?? []),
      },
      remoteReportSetup: {
        memo: result.data?.remote?.memo || undefined,
        isPublic: result.data?.remote?.isPublic ?? true,
        editorUid: currentUid,
        viewers: viewers,
        tags: result.data?.remote?.tags?.map(t => ({ value: { name: t.name } })) || [],
      },
    },
  };

  return <RemoteInterviewResourceEditorMiddleContainer {...containerProps} />;
};

RemoteInterviewResourceEditorFetchContainer.displayName = "RemoteInterviewResourceEditorFetchContainer";

export default withErrorBoundary(RemoteInterviewResourceEditorFetchContainer, {});
