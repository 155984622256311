import Logo from "@hireroo/ui-assets/images/Logo/Logo.png";
import { styled } from "@mui/material/styles";
import * as React from "react";

import Link, { LinkProps } from "../../primitive/Link/Link";

const StyledLink = styled(Link)(() => ({
  display: "inline-flex",
  alignItems: "stretch",
}));

export type BrandLogoProps = Pick<LinkProps, "href" | "onClick" | "target">;

const BrandLogo: React.FC<BrandLogoProps> = props => {
  return (
    <StyledLink {...props}>
      <img src={Logo} width={100} alt="hireroo" />
    </StyledLink>
  );
};

BrandLogo.displayName = "BrandLogo";

export default BrandLogo;
