import { App, Company } from "@hireroo/app-store/essential/employee";
import { RemotesStore } from "@hireroo/app-store/page/e/remotes";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import RemoteInterviewListContainer, { RemoteInterviewListContainerProps } from "./Container";

export type RemoteInterviewListFetchContainerProps = {};

const RemoteInterviewListFetchContainer: React.FC<RemoteInterviewListFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const companyId = Company.useStrictActiveCompanyId();
  const initialized = RemotesStore.useResponseInitialized();
  const pager = RemotesStore.usePager();
  const sortParams = RemotesStore.useSortParams();
  const currentSearchFilter = RemotesStore.useCurrentSearchFilter();
  const [result, refresh] = Graphql.useListRemotesByCompanyIdForRemotesInterviewListQuery({
    variables: {
      companyId: companyId,
      isDescending: sortParams.isDescending,
      offset: pager.offset,
      size: pager.size,
      sortMethod: sortParams.sortMethod,
      filters: {
        name: currentSearchFilter.textFilter,
        result: currentSearchFilter.result ? currentSearchFilter.result === "PASSED" : undefined,
        createdByList: currentSearchFilter.createdBy.slice(),
        statuses: currentSearchFilter.statuses.slice(),
        tagNames: currentSearchFilter.tags.map(t => t.displayName),
      },
      withCount: true,
      withDeleted: false,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "network-only",
  });

  React.useEffect(() => {
    if (result.data) {
      RemotesStore.setRes(result.data.remotesByCompanyId);
    }
  }, [result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: RemoteInterviewListContainerProps = {};

  return <RemoteInterviewListContainer {...containerProps} />;
};

RemoteInterviewListFetchContainer.displayName = "RemoteInterviewListFetchContainer";

export default withErrorBoundary(RemoteInterviewListFetchContainer, {});
