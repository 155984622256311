import { styled } from "@mui/material/styles";
import * as React from "react";

import EmployeeLayout, { EmployeeLayoutProps } from "../../layouts/EmployeeLayout/EmployeeLayout";

const StyledEmployeeLayout = styled(EmployeeLayout)`
  overflow: hidden;
`;

export type AssessmentDetailForEmployeeProps = {
  layout: EmployeeLayoutProps;
  children: React.ReactNode;
};

const AssessmentDetailForEmployee: React.FC<AssessmentDetailForEmployeeProps> = props => {
  return <StyledEmployeeLayout {...props.layout}>{props.children}</StyledEmployeeLayout>;
};

AssessmentDetailForEmployee.displayName = "AssessmentDetailForEmployee";

export default AssessmentDetailForEmployee;
