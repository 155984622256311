import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Circle from "@mui/icons-material/Circle";
import RemoveIcon from "@mui/icons-material/Remove";
import TabContext from "@mui/lab/TabContext";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled, useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tooltip from "@mui/material/Tooltip";
import Typography, { TypographyProps } from "@mui/material/Typography";
import * as React from "react";

import Tabs, { TabsProps } from "../../primitive/Tabs/Tabs";
import ProjectDiffViewer, { ProjectDiffViewerProps } from "./parts/ProjectDiffViewer";
import ProjectPlayback, { ProjectPlaybackProps } from "./parts/ProjectPlayback";

const StyledTab = styled(Tab)(({ theme }) => ({
  "&.MuiTab-root": {
    color: theme.palette.text.primary,
  },
  "&.Mui-selected": {
    color: theme.palette.text.primary,
  },
}));

const StyledIconTypography = styled(Typography)(() => ({
  textTransform: "none",
  fontWeight: "bold",
}));

const StyledTabTypography = styled(Typography)(() => ({
  textTransform: "none",
  fontSize: 14,
}));

export type HistoryContentProps = {
  mode: "DIFF";
  content: ProjectDiffViewerProps;
};

export type PlaybackContentProps = {
  mode: "PLAYBACK";
  content: ProjectPlaybackProps;
};

export type Operation = "UNKNOWN" | "ADDITION" | "DELETION" | "MODIFICATION" | "RENAME";

const signMap: Record<Operation, React.ReactNode> = {
  UNKNOWN: "",
  ADDITION: <AddIcon fontSize="small" sx={{ fontSize: 14 }} />,
  DELETION: <RemoveIcon fontSize="small" sx={{ fontSize: 14 }} />,
  MODIFICATION: <Circle fontSize="small" sx={{ fontSize: 8, top: -3, position: "relative" }} />,
  RENAME: <ArrowForwardIcon fontSize="small" sx={{ fontSize: 14 }} />,
};

type TabItem = {
  value: string;
  tooltipText: string;
  op: Operation;
  displayText: string;
  disabled: boolean;
};

export type ProjectContentsViewerV1Props = (HistoryContentProps | PlaybackContentProps) & {
  value: string;
  onChange: TabsProps["onChange"];
  tabs: TabItem[];
};

const ProjectContentsViewerV1: React.FC<ProjectContentsViewerV1Props> = props => {
  const theme = useTheme();

  const styleMap: Record<Operation, TypographyProps["sx"]> = {
    UNKNOWN: { color: undefined, fontWeight: undefined },
    ADDITION: { color: theme.palette.success.light, fontWeight: "bold" },
    DELETION: { color: theme.palette.error.light, fontWeight: "bold" },
    MODIFICATION: { color: theme.palette.warning.light, fontWeight: "bold" },
    RENAME: { color: theme.palette.common.white, fontWeight: "bold" },
  };

  return (
    <Box width={"100%"} display={"flex"} flexDirection={"column"} height={"100%"}>
      <TabContext value={props.value}>
        <Paper>
          <Tabs variant={"scrollable"} scrollButtons="auto" value={props.value} onChange={props.onChange} style={{ padding: 0 }}>
            {props.tabs.map(tab => (
              <StyledTab
                value={tab.value}
                label={
                  <Tooltip title={tab.tooltipText}>
                    <Box display={"flex"} flexDirection={"row"}>
                      <StyledIconTypography variant={"subtitle1"} mr={1} sx={{ ...styleMap[tab.op] }}>
                        {signMap[tab.op]}
                      </StyledIconTypography>
                      <StyledTabTypography variant={"subtitle1"}>{tab.displayText}</StyledTabTypography>
                    </Box>
                  </Tooltip>
                }
                key={`project-diff-editor-tab-${tab.displayText}`}
                disabled={tab.disabled}
                sx={{ "&:disabled": { pointerEvents: "auto" } }}
              />
            ))}
          </Tabs>
        </Paper>
        {props.mode === "DIFF" && <ProjectDiffViewer {...props.content} />}
        {props.mode === "PLAYBACK" && <ProjectPlayback {...props.content} />}
      </TabContext>
    </Box>
  );
};

ProjectContentsViewerV1.displayName = "ProjectContentsViewerV1";

export default ProjectContentsViewerV1;
