import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import * as React from "react";

export type CollapseTableRowProps = {
  actualRow: string[];
  expectedRow: string[];
  isCorrect: boolean;
};

const CollapseTableRow: React.FC<CollapseTableRowProps> = props => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <TableRow
        sx={{
          background: props.isCorrect ? theme.palette["Success/Shades"].p30 : theme.palette["Error/Shades"].p30,
        }}
      >
        <TableCell width="10%">
          {props.isCorrect ? <CheckOutlinedIcon color="success" fontSize="small" /> : <CloseOutlinedIcon color="error" fontSize="small" />}
        </TableCell>
        {props.actualRow.map(cell => (
          <TableCell key={cell} width="30%">
            {cell}
          </TableCell>
        ))}
        {!props.isCorrect ? (
          <TableCell width="10%" align="right">
            <IconButton aria-label="expanded-row" onClick={() => setOpen(prev => !prev)} sx={{ height: 20, width: 20 }}>
              {open ? <KeyboardArrowUpOutlinedIcon fontSize={"small"} /> : <KeyboardArrowDownOutlinedIcon fontSize={"small"} />}
            </IconButton>
          </TableCell>
        ) : (
          <TableCell width="10%" />
        )}
      </TableRow>

      <TableRow sx={{ visibility: open ? "visible" : "collapse", background: theme.palette.grey[800] }}>
        <TableCell width="10%" />
        {props.expectedRow.map(cell => (
          <TableCell key={cell} width="30%">
            {cell}
          </TableCell>
        ))}
        <TableCell width="10%" />
      </TableRow>
    </>
  );
};

CollapseTableRow.displayName = "CollapseTableRow";

export default CollapseTableRow;
