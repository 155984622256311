import { scrollToContentForReportV2 } from "@hireroo/app-helper/html-element";
import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import { formatScore } from "@hireroo/formatter/score";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";

type ScoreBoardStatus = Exclude<Widget.ProjectTestReportProps["entityScoreBoard"], undefined>["status"];
type ShowingTarget = "SCORE" | "RANK";
type EntityScoreBoard = Exclude<Widget.ProjectTestReportProps["entityScoreBoard"], undefined>;

export type GenerateEntityScoreBoardProps = {
  entityId: number;
  showingTargets: ShowingTarget[];
};

export const useGenerateEntityScoreBoardProps = (args: GenerateEntityScoreBoardProps): Widget.ProjectTestReportProps["entityScoreBoard"] => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const hooks = ProjectTestReport.useCreateProjectHooks(args.entityId);
  const submission = hooks.useCurrentSubmission();
  const submissions = hooks.useSubmissions();
  const submissionId = hooks.useCurrentSelectedSubmissionId();
  const variant = hooks.useQuestionVariant();
  const question = hooks.useQuestion();

  const submissionStatusMap: Record<Graphql.ProjectSubmissionStatus, string> = {
    ENQUEUED: `(${t("採点中")})`,
    FAILED: `(${t("採点失敗")})`,
    EVALUATED: "",
    UNKNOWN: "",
  };

  const variantKindMap: Record<Graphql.ProjectQuestionVariant, string> = {
    Default: "",
    Frontend: t("lighthouseを利用して算出された値"),
    Backend: t("一定の負荷が与えられた上で実行速度やSLAなどから算出された値"),
    DataScience: "",
    UNKNOWN: "",
  };

  const statusMap: Record<Graphql.ProjectSubmissionStatus, ScoreBoardStatus> = {
    ENQUEUED: "EVALUATING",
    EVALUATED: "EVALUATED",
    FAILED: "ERROR",
    UNKNOWN: "EVALUATING",
  };

  const difficultyMap: Record<Graphql.Difficulty, EntityScoreBoard["difficulty"]> = {
    EASY: "EASY",
    MEDIUM: "MEDIUM",
    DIFFICULT: "DIFFICULT",
    UNKNOWN: "EASY",
  };

  return {
    difficulty: difficultyMap[question?.difficulty ?? "UNKNOWN"],
    scoreChart:
      (args.showingTargets.includes("SCORE") && {
        score: formatScore(submission?.totalScore ?? 0),
      }) ||
      undefined,
    scoreBars:
      (variant === "Frontend" || variant === "Backend") && args.showingTargets.includes("SCORE")
        ? [
            {
              title: t("正解率"),
              score: submission ? formatScore(submission.coverage) : 0,
              description: t("用意されたテストケース中で通った割合"),
              color: "secondary",
              link: {
                onClick: () => {
                  scrollToContentForReportV2(ProjectTestReport.TargetElementIdMap.CORRECT_RATE_SECTION);
                },
                href: "",
              },
            },
            {
              title: t("パフォーマンス"),
              score: submission ? formatScore(submission.performance) : 0,
              description: variantKindMap[variant],
              color: "info",
              link: {
                onClick: () => {
                  scrollToContentForReportV2(ProjectTestReport.TargetElementIdMap.PERFORMANCE_SECTION);
                },
                href: "",
              },
            },
          ]
        : [],
    submissionSelect: {
      onChange: event => {
        ProjectTestReport.setCurrentSelectedSubmissionId(args.entityId, Number(event.target.value));
      },
      value: submissionId,
    },
    submissionOptions: submissions.map((submission, index) => {
      const totalScore = formatScore(submission.totalScore);
      return {
        id: submission.projectSubmissionId.toString(),
        displayText: args.showingTargets.includes("SCORE")
          ? `${index + 1} ${submission.isBest ? `【${t("最高得点")}】` : ""} ${t("スコア")} ${totalScore}% ${
              submissionStatusMap[submission.submissionStatus]
            }`
          : t2("NumberOfSubmission", { number: `${index + 1}` }),
      };
    }),
    status: statusMap[submission?.submissionStatus ?? "UNKNOWN"],
  };
};
