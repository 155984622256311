import { marked, Renderer } from "marked";

// escaped regular expressions: https://github.com/lodash/lodash/blob/ddfd9b11a0126db2302cb70ec9973b66baec0975/lodash.js#L133-L136
// https://github.com/lodash/lodash/blob/ddfd9b11a0126db2302cb70ec9973b66baec0975/lodash.js#L384-L390
export const escapeHtml = (text: string): string => {
  const escapeTextMap: { [key: string]: string } = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#39;",
  };

  return text.replace(/[&<>"']/g, t => escapeTextMap[t]);
};

// https://github.com/lodash/lodash/blob/ddfd9b11a0126db2302cb70ec9973b66baec0975/lodash.js#L393-L399
export const unescapeHtml = (text: string): string => {
  const escapeTextMap: { [key: string]: string } = {
    "&amp;": "&",
    "&lt;": "<",
    "&gt;": ">",
    "&quot;": '"',
    "&#39;": "'",
  };

  return text.replace(/&(?:amp|lt|gt|quot|#39);/g, t => escapeTextMap[t]);
};

const block = (text: string) => text + "\n\n";
const escapeBlock = (text: string) => escapeHtml(text) + "\n\n";
const line = (text: string) => text + "\n";
const inline = (text: string) => text;
const newline = () => "\n";
const empty = () => "";

const TxtRenderer: Renderer = {
  // Block elements
  code: escapeBlock,
  blockquote: block,
  html: empty,
  heading: block,
  hr: newline,
  list: (text: string) => block(text.trim()),
  listitem: line,
  checkbox: empty,
  paragraph: block,
  table: (header: string, body: string) => line(header + body),
  tablerow: (text: string) => line(text.trim()),
  tablecell: (text: string) => text + " ",
  // Inline elements
  strong: inline,
  em: inline,
  codespan: inline,
  br: newline,
  del: inline,
  link: (_0: string | null, _1: string | null, text: string) => text,
  image: (_0: string | null, _1: string | null, text: string) => text,
  text: inline,
  // etc.
  options: {},
};

/**
 * Converts markdown to plaintext using the marked Markdown library.
 *
 * @param markdown the Markdown text to txtify
 * @returns the unmarked text
 */
export const markdownToText = (markdown: string): string => {
  marked.setOptions({ renderer: TxtRenderer });
  const unmarked = marked(markdown);
  const unescaped = unescapeHtml(unmarked);

  return unescaped.trim();
};

export default markdownToText;
