import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import * as z from "zod";

import * as AssignField from "./fields/AssignField";
import * as CustomField from "./fields/CustomField";
import * as EntityTrack from "./fields/EntityTrack";
import * as InvitationFiled from "./fields/InvitationField";
import * as TagField from "./fields/TagsField";
import * as TimeLimitField from "./fields/TimeLimitField";
import { useEmailSchema } from "./FieldValidator";

export type EmailInvitationSchema = InvitationFiled.EmailInviteSchema;
export type ReservedFieldName =
  | "name"
  | "description"
  | "isActive"
  | "timeLimitMinutes"
  | "timeLimitType"
  | "acceptableLimit"
  | "viewers"
  | "entitySources"
  | "tags"
  | "companyId"
  | "employeeId"
  | "memo"
  | "messageForCandidate"
  | "invitationMethod"
  | "isPublic"
  | "ownerEmailAddress"
  | "enabledChatGPT"
  | "enabledWebSearch"
  | "enabledHint";

export const useCandidateAccessPolicy = () => {
  return z.enum(["ALLOW_ALL", "RESTRICTED_BY_INVITATION_CODE"]);
};

export type CandidateAccessPolicy = z.infer<ReturnType<typeof useCandidateAccessPolicy>>;

export const useInvitationLanguage = () => {
  return z.enum(["en", "ja"]);
};

export type InvitationLanguage = z.infer<ReturnType<typeof useInvitationLanguage>>;

export const useTestQuestionSetupForm = () => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const entityTrack = EntityTrack.useEntityTrack();
  const timelimit = TimeLimitField.useTimeLimitFieldSchema();
  const timeLimitType = TimeLimitField.useTimeLimitTypeFieldSchema();

  return z.object({
    companyId: z.number(),
    name: z
      .string()
      .min(1, { message: t("タイトルは必須です。") })
      .max(100, {
        message: t2("ValidateMaxTextSizeMessage", {
          size: 100,
          name: t("タイトル"),
        }),
      })
      .regex(/^[^\cA-\cZ]+$/, t("利用できない文字列が含まれています。")),
    description: z
      .string()
      .max(1000, {
        message: t2("ValidateMaxTextSizeMessage", {
          size: 1000,
          name: t("説明文"),
        }),
      })
      .optional(),
    entityTracks: entityTrack
      .array()
      .max(10, { message: t("問題は必ず1問以上、10問以下で選択してください。") })
      .min(1, { message: t("問題は必ず1問以上、10問以下で選択してください。") }),
    timeLimitMinutes: timelimit,
    timeLimitType: timeLimitType,
    enabledChatGPT: z.boolean(),
    enabledWebSearch: z.boolean(),
    enabledHint: z.boolean(),
  });
};

export type TestQuestionSetupFormSchema = z.infer<ReturnType<typeof useTestQuestionSetupForm>>;

const ONE_YEAR_IN_MILLISECONDS = 31536000000; // 1000 * 60 * 60 * 24 * 365

type TestSetupFormArgs = {
  minAcceptableLimit?: number;
};

export const useTestSetupForm = (args: TestSetupFormArgs) => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();

  const customField = CustomField.useCustomField();
  const minAcceptableLimit = args.minAcceptableLimit ?? 1;

  return z
    .object({
      customFields: z.array(customField),
      acceptableLimit: z
        .preprocess(
          v => Number(v),
          z
            .number()
            .min(minAcceptableLimit, {
              message: t2("minNumber", {
                min: minAcceptableLimit,
                field: t("受験可能な定員数"),
              }),
            })
            .int(t("整数のみ入力可能です"))
            .nullable(),
        )
        .nullable(),
      deadlineDate: z
        .date()
        .min(new Date(), { message: t("テストの終了時間は現在時刻よりも後になるように設定してください。") })
        .max(new Date(Date.now() + ONE_YEAR_IN_MILLISECONDS), {
          message: t2("cannotSetTestWillEndAtMax", {
            max: 1,
            unit: t("年"),
          }),
        })
        .nullable(),
      isActive: z.boolean(),
    })
    .superRefine((val, ctx) => {
      const labels = new Set();
      val.customFields.forEach((customField, index) => {
        if (labels.has(customField.label)) {
          ctx.addIssue({
            path: [`customFields.${index}.label`],
            code: z.ZodIssueCode.custom,
            message: t("同じ質問タイトルは登録できません。"),
          });
        }
        labels.add(customField.label);
      });
    });
};

export type TestSetupFormSchema = z.infer<ReturnType<typeof useTestSetupForm>>;

export const useReportSetupForm = () => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const tag = TagField.useTagItem();
  const viewer = AssignField.useAssignListItem();

  return z
    .object({
      memo: z
        .string()
        .max(10000, {
          message: t2("ValidateMaxTextSizeMessage", {
            size: 10000,
            name: t("メモ"),
          }),
        })
        .optional(),
      tags: tag.array(),
      isPublic: z.boolean(),
      /**
       * User ID to be set when viewing privileges are changed to private.
       */
      editorUid: z.string().nullable(),
      viewers: viewer.array(),
    })
    .superRefine((obj, ctx) => {
      if (obj.isPublic === false && obj.viewers.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_small,
          path: ["viewers"],
          minimum: 1,
          type: "number",
          inclusive: true,
          message: t("閲覧できるユーザーまたはグループを1つ以上選択してください。"),
        });
      }
    });
};

export type ReportSetupFormSchema = z.infer<ReturnType<typeof useReportSetupForm>>;

export const useTestInviteSetupForm = () => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const candidateAccessPolicy = useCandidateAccessPolicy();
  const invitationLanguage = useInvitationLanguage();
  const email = useEmailSchema();

  return z.object({
    messageForCandidate: z
      .string()
      .max(10000, {
        message: t2("ValidateMaxTextSizeMessage", {
          size: 10000,
          name: t("メッセージ"),
        }),
      })
      .nullable(),
    candidateAccessPolicy: candidateAccessPolicy,
    invitationLanguage: invitationLanguage,
    ownerEmailAddress: email.nullable(),
  });
};

export type TestInviteSetupFormSchema = z.infer<ReturnType<typeof useTestInviteSetupForm>>;

export const useCreateScreening = (args: CreateScreeningArgs) => {
  const testQuestionSetup = useTestQuestionSetupForm();
  const testSetup = useTestSetupForm(args);
  const reportSetup = useReportSetupForm();
  const testInviteSetup = useTestInviteSetupForm();
  return z.object({
    testQuestionSetup,
    testSetup,
    reportSetup,
    testInviteSetup,
  });
};

export type CreateScreeningV2FormSchema = z.infer<ReturnType<typeof useCreateScreening>>;

type CreateScreeningArgs = {
  minAcceptableLimit?: number;
};
