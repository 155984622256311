import { QuestionsChallengeId } from "@hireroo/app-store/page/e/questions_challenge_id";
import { ChallengeQuestionVariant, initialLanguageMap } from "@hireroo/challenge/definition";
import * as Graphql from "@hireroo/graphql/client/urql";
import type { Widget } from "@hireroo/presentation";

import { useAlgorithmSignature, useClassSignature, useDatabaseSignature, useDefaultCorrectnessTestCases } from "./privateHelper";

const QuestionVariantMap: Record<Graphql.AlgorithmQuestionVariant, ChallengeQuestionVariant> = {
  ALGORITHM: "ALGORITHM",
  DATABASE: "DATABASE",
  CLASS: "CLASS",
  UNKNOWN: "ALGORITHM",
};

export type GenerateChallengeCodingEditorContainerPropsArgs = {
  questionUid: string;
};
type SignatureProps = Widget.ChallengeCodingEditorWithDetailProviderProps["contextProps"]["signatureProps"];

export const useGenerateChallengeProviderProps = (
  args: GenerateChallengeCodingEditorContainerPropsArgs,
): Widget.ChallengeCodingEditorWithDetailProviderProps => {
  const { questionUid } = args;
  const algorithmSignature = useAlgorithmSignature(questionUid);
  const databaseSignature = useDatabaseSignature(questionUid);
  const classSignature = useClassSignature(questionUid);
  const questionHooks = QuestionsChallengeId.useCreateQuestionHooks(questionUid);
  const question = questionHooks.useQuestion();
  const correctnessTestCases = useDefaultCorrectnessTestCases(questionUid);
  const questionVariant = QuestionVariantMap[question?.variant ?? "ALGORITHM"];

  const signaturePropsMap: Record<ChallengeQuestionVariant, SignatureProps> = {
    ALGORITHM: {
      variant: "ALGORITHM",
      signature: algorithmSignature,
    },
    DATABASE: {
      variant: "DATABASE",
      signature: databaseSignature,
    },
    CLASS: {
      variant: "CLASS",
      signature: classSignature,
    },
  };

  return {
    contextProps: {
      signatureProps: signaturePropsMap[questionVariant],
    },
    testCasesForConsole: correctnessTestCases.map((testCase): Widget.ChallengeCodingEditorProviderProps["testCasesForConsole"][number] => {
      return {
        inputs: testCase.inputs,
        isHidden: testCase.is_hidden,
      };
    }),
    defaultLanguage: question?.supportedLanguages[0] ?? initialLanguageMap[questionVariant],
    kind: "QUESTION_SCREEN",
  };
};
