import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type SystemDesignQuestionDetailContainerProps = {};

const SystemDesignQuestionDetailContainer: React.FC<SystemDesignQuestionDetailContainerProps> = () => {
  const systemDesignQuestionDetailProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Pages.QuestionDetail {...systemDesignQuestionDetailProps} />
    </ErrorBoundary>
  );
};

SystemDesignQuestionDetailContainer.displayName = "SystemDesignQuestionDetailContainer";

export default withErrorBoundary(SystemDesignQuestionDetailContainer, {});
