import { styled } from "@mui/material/styles";
import MUITableCell, { TableCellProps as MUITableCellProps } from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type TableCellProps = MUITableCellProps & {};

const StyledTableCell = styled(MUITableCell)(({ theme }) => ({
  borderColor: theme.palette.Other.Divider,
}));

const TableCell: React.FC<TableCellProps> = props => {
  const { children } = props;
  return (
    <StyledTableCell {...props}>
      <Typography>{children}</Typography>
    </StyledTableCell>
  );
};

TableCell.displayName = "TableCell";

export default TableCell;
