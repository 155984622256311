import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";

import DeviceSettingButtonGroup, {
  DeviceSettingButtonGroupProps,
} from "../../ms-components/Livecoding/DeviceSettingButtonGroup/DeviceSettingButtonGroup";
import LiveCodingSettingDialog, {
  LiveCodingSettingDialogProps,
} from "../../ms-components/Livecoding/LiveCodingSettingDialog/LiveCodingSettingDialog";
import VideoChatDraggablePrompt, {
  VideoChatDraggablePromptProps,
  VideoChatDraggablePromptProvider,
} from "../../ms-components/Livecoding/VideoChatDraggablePrompt/VideoChatDraggablePrompt";

export { LiveCodingVideoChatPopperProvider, type LiveCodingVideoChatPopperProviderProps, useLiveCodingVideoChatPopperContext } from "./Context";

const Wrapper = styled(Box)(({ theme }) => ({
  zIndex: theme.zIndex.appBar + 1,
}));

export type LiveCodingVideoChatPopperProps = {
  // devicePermissionWarningDialog: Pick<DevicePermissionWarningDialogProps, "open">;
  Layout: React.ReactNode;
  menuBar: Omit<DeviceSettingButtonGroupProps, "settingsButton">;
  settingDialog: Omit<LiveCodingSettingDialogProps, "open" | "onClose" | "mode" | "noButton">;
  videoChatDraggablePrompt: VideoChatDraggablePromptProps;
};

const LiveCodingVideoChatPopper: React.FC<LiveCodingVideoChatPopperProps> = props => {
  const [openDeviceSettingDialog, setOpenDeviceSettingDialog] = React.useState<boolean>(false);

  const deviceSettingButtonGroupProps: DeviceSettingButtonGroupProps = {
    ...props.menuBar,
    settingsButton: {
      onClick: () => {
        setOpenDeviceSettingDialog(true);
      },
    },
  };
  const settingDialog: LiveCodingSettingDialogProps = {
    ...props.settingDialog,
    open: openDeviceSettingDialog,
    onClose: () => {
      setOpenDeviceSettingDialog(false);
    },
    mode: "SETTING",
    yesButton: {
      onClick: () => {
        setOpenDeviceSettingDialog(false);
      },
    },
    noButton: {
      onClick: () => {
        setOpenDeviceSettingDialog(false);
      },
    },
  };

  return (
    <Wrapper>
      <VideoChatDraggablePromptProvider>
        <VideoChatDraggablePrompt {...props.videoChatDraggablePrompt}>
          {props.Layout}
          <DeviceSettingButtonGroup {...deviceSettingButtonGroupProps} />
        </VideoChatDraggablePrompt>
        <LiveCodingSettingDialog {...settingDialog} />
      </VideoChatDraggablePromptProvider>
    </Wrapper>
  );
};
LiveCodingVideoChatPopper.displayName = "LiveCodingVideoChatPopper";

export default LiveCodingVideoChatPopper;
