import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import * as z from "zod";

const useScoreField = () => {
  return z.object({
    min: z.number(),
    max: z.number(),
  });
};

const useQuestionVariants = () => {
  return z.array(
    z.union([
      z.literal("codingtest"),
      z.literal("algorithm"),
      z.literal("database"),
      z.literal("frontend"),
      z.literal("project"),
      z.literal("backend"),
      z.literal("dataScience"),
      z.literal("other"),
      z.literal("multichoice"),
      z.literal("systemdesign"),
    ]),
  );
};

const useTags = () => {
  return z
    .object({
      displayName: z.string(),
    })
    .array();
};

const useStatus = () => {
  return z.array(
    z.union([
      z.literal("CREATED"),
      z.literal("STARTED"),
      z.literal("COMPLETED"),
      z.literal("FINALIZED"),
      z.literal("EVALUATED"),
      z.literal("DECLINED"),
      z.literal("EXPIRED"),
    ]),
  );
};

const useCreatedBy = () => {
  return z.string().array();
};

const useResult = () => {
  return z.union([z.literal("PASSED"), z.literal("NOT_PASSED"), z.null()]);
};

const useRank = () => {
  return z.array(z.union([z.literal("S"), z.literal("A"), z.literal("B"), z.literal("C"), z.literal("D")]));
};

const useSuspiciousDegrees = () => {
  return z.array(z.enum(["CLEAN", "SUSPICIOUS"]));
};

export const useFilledVariable = () => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  return z.object({
    /**
     * unique label id
     */
    labelId: z.string().min(1, { message: t2("ValidateRequired", { name: t("質問タイトル") }) }),
    label: z.string(),
    valueType: z.union([z.literal("INTEGER"), z.literal("STRING"), z.literal("BOOLEAN")]),
    value: z.string(),
  });
};
export type FilledVariableSchema = z.infer<ReturnType<typeof useFilledVariable>>;
export const useFilledVariables = () => {
  const variable = useFilledVariable();
  return z.object({
    variables: variable.array(),
  });
};
export type FilledVariablesSchema = z.infer<ReturnType<typeof useFilledVariables>>;

export const useTestSearchQuery = () => {
  const score = useScoreField();
  const tags = useTags();
  const questionVariants = useQuestionVariants();
  const statuses = useStatus();
  const createdBy = useCreatedBy();
  const result = useResult();
  const rank = useRank();
  const suspiciousDegrees = useSuspiciousDegrees();
  return z.object({
    tags,
    score,
    /**
     * TODO @himenon Moves when a Question List is created.
     */
    questionVariants,
    statuses,
    createdBy,
    result,
    rank,
    textFilter: z.string(),
    screeningIds: z.string().array(),
    suspiciousDegrees,
  });
};

export type TestSearchQuerySchema = z.infer<ReturnType<typeof useTestSearchQuery>>;
