import { useTranslation } from "@hireroo/i18n";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import * as React from "react";

export type RemoteInterviewEvaluationProps = {
  TechnicalCommentEvaluationsForm: React.ReactNode;
};

const RemoteInterviewEvaluation: React.FC<RemoteInterviewEvaluationProps> = props => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(true);
  return (
    <Box>
      {open && (
        <Box px={1.5} pt={1.5}>
          <Alert severity="info" onClose={() => setOpen(false)}>
            {t("評価はインタビュー終了後の画面にて確定できます。入力した評価はブラウザ内に自動で保存されています。")}
          </Alert>
        </Box>
      )}
      <Box mt={1.5}>{props.TechnicalCommentEvaluationsForm}</Box>
    </Box>
  );
};

RemoteInterviewEvaluation.displayName = "RemoteInterviewEvaluation";

export default RemoteInterviewEvaluation;
