import type { Fields } from "@hireroo/validator";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import Autocomplete from "@mui/material/Autocomplete";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import { useController } from "react-hook-form";

import { BaseAssignFieldProps, ItemProps } from "../../types";

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  "&.MuiChip-avatarColorSecondary": {
    backgroundColor: theme.palette.action.active,
    color: theme.palette.action.active,
  },
}));

const GroupAvatarComponent = (
  <StyledAvatar>
    <GroupIcon />
  </StyledAvatar>
);

const PersonAvatarComponent = (
  <StyledAvatar>
    <PersonIcon />
  </StyledAvatar>
);

type Field = {
  assignee: Fields.AssignField.AssigneeSchema;
};

export type SingleAssignFieldProps = BaseAssignFieldProps;

const SingleAssignField: React.FC<SingleAssignFieldProps> = props => {
  const { suggestions, displaySources } = props;
  const typeHackFieldName = props.name as "assignee";
  const { field, fieldState } = useController<Field>({
    name: typeHackFieldName,
  });
  const fieldError = fieldState.error;
  const value = React.useMemo((): ItemProps | null => {
    if (field.value === null || field.value === undefined) {
      return null;
    }
    if (typeof field.value === "string" || typeof field.value === "boolean") {
      return null;
    }
    if (field.value.type === "EMPLOYEE") {
      return {
        valueId: field.value.employeeId,
        type: "EMPLOYEE",
        locked: !!field.value.locked,
      };
    } else if (field.value.type === "EMPLOYEE_GROUP") {
      return {
        valueId: field.value.groupId,
        type: "EMPLOYEE_GROUP",
        locked: !!field.value.locked,
      };
    } else if (field.value.type === "TALENT") {
      return {
        valueId: field.value.talentId,
        type: "TALENT",
        locked: !!field.value.locked,
      };
    }
    return null;
  }, [field.value]);

  const getAvatarComponent = React.useCallback(
    (item: ItemProps) => {
      const displaySource = displaySources[item.valueId];
      if (item.type === "EMPLOYEE_GROUP") {
        return GroupAvatarComponent;
      }
      if (displaySource?.photoUrl) {
        return <Avatar src={displaySource?.photoUrl} />;
      }
      return PersonAvatarComponent;
    },
    [displaySources],
  );

  if (value?.locked) {
    return (
      <Tooltip title={displaySources[value.valueId]?.optionText || ""}>
        <Chip avatar={getAvatarComponent(value)} label={displaySources[value.valueId]?.chipText} variant="outlined" />
      </Tooltip>
    );
  }

  return (
    <Autocomplete
      multiple={false}
      fullWidth
      value={value}
      isOptionEqualToValue={(option, value) => {
        return option.valueId === value.valueId && option.type === value.type;
      }}
      onChange={(_, value, reason) => {
        if (reason === "selectOption") {
          if (value) {
            if (value.type === "EMPLOYEE") {
              const newValue: Fields.AssignField.AssigneeSchema = {
                type: "EMPLOYEE",
                employeeId: value.valueId,
              };
              field.onChange(newValue);
            } else if (value.type === "EMPLOYEE_GROUP") {
              const newValue: Fields.AssignField.AssigneeSchema = {
                type: "EMPLOYEE_GROUP",
                groupId: value.valueId,
              };
              field.onChange(newValue);
            } else if (value.type === "TALENT") {
              const newValue: Fields.AssignField.AssigneeSchema = {
                type: "TALENT",
                talentId: value.valueId,
              };
              field.onChange(newValue);
            }
          } else {
            field.onChange(props.clearedValue ?? null);
          }
        } else if (reason === "clear") {
          field.onChange(props.clearedValue ?? null);
        }
      }}
      onBlur={field.onBlur}
      onFocus={props.onFocus}
      loading={props.loading}
      options={suggestions as ItemProps[]}
      getOptionLabel={option => {
        const displayName = displaySources[option.valueId];
        if (!displayName) {
          return "";
        }
        return displayName.optionText;
      }}
      renderOption={(props, option) => {
        const displaySource = displaySources[option.valueId];
        const AvatarComponent = getAvatarComponent(option);
        return (
          <ListItem {...props} key={option.valueId}>
            <ListItemAvatar>{AvatarComponent}</ListItemAvatar>
            {displaySource?.optionText ?? ""}
          </ListItem>
        );
      }}
      filterSelectedOptions
      renderInput={params => {
        return (
          <TextField
            {...params}
            inputRef={field.ref}
            value={field.value}
            color="secondary"
            placeholder={props.placeholder}
            error={!!fieldError?.message}
            helperText={fieldError?.message}
          />
        );
      }}
    />
  );
};

SingleAssignField.displayName = "SingleAssignField";

export default SingleAssignField;
