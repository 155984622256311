/**
 * TODO Move this to @hireroo/project
 */
import * as z from "zod";

export const generatePerCommand = () => {
  return z.object({
    id: z.string(),
    action: z.string(),
    body: z.string(),
    condition: z.string(),
    expected: z.string(),
  });
};

export type PerCommandSchema = z.infer<ReturnType<typeof generatePerCommand>>;

export const generateFrontendCorrectnessTestCase = () => {
  const command = generatePerCommand();
  return z.object({
    commands: command.array(),
  });
};

export type FrontendCorrectnessTestCase = z.infer<ReturnType<typeof generateFrontendCorrectnessTestCase>>;

export const generateFrontendCorrectnessTestCases = () => {
  const testCase = generateFrontendCorrectnessTestCase();
  return testCase.array();
};

export type FrontendCorrectnessTestCases = z.infer<ReturnType<typeof generateFrontendCorrectnessTestCases>>;

export const generateResultPerCommand = () => {
  return z.object({
    latency: z.number(),
    is_passed: z.boolean(),
    screenshot: z.string(),
  });
};

export type ResultPerCommand = z.infer<ReturnType<typeof generateResultPerCommand>>;

export const generateFrontendCorrectnessTestCaseResult = () => {
  const result = generateResultPerCommand();
  return z.object({
    results: result.array(),
    is_passed: z.boolean(),
  });
};

export type FrontendCorrectnessTestCaseResult = z.infer<ReturnType<typeof generateFrontendCorrectnessTestCaseResult>>;

export const generateFrontendCorrectnessTestCaseResults = () => {
  const result = generateFrontendCorrectnessTestCaseResult();
  return result.array();
};

export type FrontendCorrectnessTestCaseResults = z.infer<ReturnType<typeof generateFrontendCorrectnessTestCaseResults>>;

export const generatePerformanceMetric = () => {
  return z.object({
    value: z.number(),
    score: z.number(),
  });
};

export type PerformanceMetric = z.infer<ReturnType<typeof generatePerformanceMetric>>;

export const generateFrontendPerformanceTestCaseResult = () => {
  const performanceMetric = generatePerformanceMetric();
  return z.object({
    fcp: performanceMetric,
    si: performanceMetric,
    lcp: performanceMetric,
    tti: performanceMetric,
    tbt: performanceMetric,
    cls: performanceMetric,
  });
};

export type FrontendPerformanceTestCaseResult = z.infer<ReturnType<typeof generateFrontendPerformanceTestCaseResult>>;

export const generateFrontendPerformanceTestCaseResults = () => {
  const result = generateFrontendPerformanceTestCaseResult();
  return result.array();
};

export type FrontendPerformanceTestCaseResults = z.infer<ReturnType<typeof generateFrontendPerformanceTestCaseResults>>;
