import Markdown from "@hireroo/markdown-v2/react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";

import MarkdownPreviewCodeEditor, { MarkdownPreviewCodeEditorProps } from "../../modules/MarkdownPreviewCodeEditor/MarkdownPreviewCodeEditor";

const Wrapper = styled(Box)(({ theme }) => ({
  border: `1px ${theme.palette.Other.OutlinedBorder} solid`,
  borderRadius: "8px",
  backgroundColor: theme.palette["Secondary/Shades"].p12,
}));

export type QuizFreeTextEditorProps = {
  description: string;
  previewEditor: MarkdownPreviewCodeEditorProps;
};

const QuizFreeTextEditor: React.FC<QuizFreeTextEditorProps> = props => {
  return (
    <Box>
      <Box mb={4}>
        <React.Suspense>
          <Box mb={2}>
            <Markdown size="middle" children={props.description} />
          </Box>
        </React.Suspense>
      </Box>
      <Wrapper>
        <MarkdownPreviewCodeEditor {...props.previewEditor} />
      </Wrapper>
    </Box>
  );
};

QuizFreeTextEditor.displayName = "QuizFreeTextEditor";

export default QuizFreeTextEditor;
