import { useTranslation } from "@hireroo/i18n";
import type { SelectChangeEvent } from "@mui/material";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import React from "react";

import { DIRECTION, Direction, EdgeElement, OPERATION_TYPE, OperationType, UnionSettingsFields } from "../../../../../features";

export type EdgeSettingsFormProps = {
  element: EdgeElement;
  updateSettings: (id: string, updates: UnionSettingsFields, operationType: OperationType) => void;
};

export const EdgeSettingsForm: React.FC<EdgeSettingsFormProps> = (props: EdgeSettingsFormProps) => {
  const { t } = useTranslation();

  const handleSelectDirection = (e: SelectChangeEvent<unknown>) => {
    props.updateSettings(props.element.id, { direction: e.target.value as Direction }, OPERATION_TYPE.do);
  };

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Box mt={2}>
        <Typography>{t("向き")}</Typography>
        <Box mt={1} />
        <Select variant={"outlined"} onChange={handleSelectDirection} value={props.element.settings.direction}>
          <MenuItem value={DIRECTION.undirectional}>{t("単方向")}</MenuItem>
          <MenuItem value={DIRECTION.bidirectional}>{t("双方向")}</MenuItem>
        </Select>
      </Box>
    </Box>
  );
};

EdgeSettingsForm.displayName = "EdgeSettingsForm";

export default EdgeSettingsForm;
