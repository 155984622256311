import * as ProjectHelpers from "@hireroo/project/helpers/fileTree";
import * as React from "react";

export const useInitialFileNode = (fileIndexList: string[]): ProjectHelpers.FileNode => {
  return React.useMemo(() => {
    const draft: ProjectHelpers.FileNode = {
      id: ".",
      name: "/home/project/app",
      isDir: true,
      isReadOnly: false,
      value: "",
      children: {},
      isRoot: true,
    };
    fileIndexList.forEach((file: string) => {
      ProjectHelpers.createNode(draft, file, false, false);
    });
    return draft;
  }, [fileIndexList]);
};
