import * as Graphql from "@hireroo/graphql/client/urql";
import { proxyMap } from "valtio/utils";

import type * as Types from "./types";

export const SortFields = {
  CREATED_AT_DESCENDING: "created-at-descending",
  CREATED_AT_ASCENDING: "created-at-ascending",
} satisfies Record<string, Types.SortFieldValue>;

export const SortParams = {
  [SortFields.CREATED_AT_DESCENDING]: { sortMethod: "CREATED_AT", isDescending: true },
  [SortFields.CREATED_AT_ASCENDING]: { sortMethod: "CREATED_AT", isDescending: false },
} satisfies Record<string, { isDescending: boolean; sortMethod: Graphql.SortMethod }>;

export const defaultFilters: Readonly<Types.Filters> = {
  name: "",
  tagNames: [],
  activeStatus: null,
};

export const DefaultPager: Types.Pager = {
  page: 0,
  size: 20,
  offset: 0,
  sortFieldValue: SortFields.CREATED_AT_DESCENDING,
};

export const defaultState: Readonly<Types.State> = {
  spotTags: null,
  tagRefresh: null,
  res: null,
  pager: DefaultPager,
  currentSearchFilter: null,
  listFetchingStatus: "STOP",
  dialogStatus: "CLOSE",
  selectedScreeningIds: [],
  screeningMap: proxyMap(),
  screeningIds: [],
  refreshKey: "",
};
