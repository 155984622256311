import { INITIAL_VERSION } from "@hireroo/app-definition/question";
import { DeepReadonly } from "@hireroo/app-helper/types";
import { QuestionSelectFieldForResourceEditor } from "@hireroo/app-store/widget/e/QuestionSelectFieldForResourceEditor";

export const getVersion = (question: DeepReadonly<QuestionSelectFieldForResourceEditor.Question>): string => {
  switch (question.__typename) {
    case "AlgorithmQuestion":
    case "MultiChoicePackage":
    case "ProjectQuestion":
    case "FreepadQuestion":
      return question.version;
    case "SystemDesignQuestion":
      return INITIAL_VERSION;
    default:
      throw new Error(`Unknown type: ${question satisfies never}`);
  }
};
