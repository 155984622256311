import { useTranslation } from "@hireroo/i18n";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";

import MemberTableRow, { MemberTableRowProps } from "../MemberTableRow/MemberTableRow";

const ColumnTitle = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  color: theme.palette.text.secondary,
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  "& .MuiTableCell-root": {
    borderColor: theme.palette["Secondary/Shades"].p12,
    padding: theme.spacing(1.5),
  },
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  borderTop: "1px solid",
  borderColor: theme.palette["Secondary/Shades"].p12,
}));

type Target = "ROLE" | "LAST_SIGNED_IN";

export type MemberTableProps = {
  title: string;
  items: MemberTableRowProps[];
  showingTargets: Target[];
  emptyMessage: string;
};

const MemberTable: React.FC<MemberTableProps> = props => {
  const { t } = useTranslation();
  return (
    <StyledTableContainer>
      <Table>
        <StyledTableHead>
          <TableRow key="company-members-header-row">
            <TableCell padding="checkbox">{/* spacer */}</TableCell>
            <TableCell>
              <ColumnTitle>{props.title}</ColumnTitle>
            </TableCell>
            {props.showingTargets.includes("LAST_SIGNED_IN") && (
              <TableCell>
                <ColumnTitle>{t("最終サインイン日")}</ColumnTitle>
              </TableCell>
            )}
            {props.showingTargets.includes("ROLE") && (
              <TableCell>
                <ColumnTitle>{t("権限")}</ColumnTitle>
              </TableCell>
            )}
            <TableCell align="right">{/* spacer */}</TableCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {props.items.map(item => (
            <MemberTableRow key={item.memberId} {...item} />
          ))}
          {props.items.length === 0 && (
            <TableCell colSpan={3 + props.showingTargets.length}>
              <Typography fontSize="12px" color="textSecondary" textAlign="center">
                {props.emptyMessage}
              </Typography>
            </TableCell>
          )}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

MemberTable.displayName = "MemberTable";

export default MemberTable;
