import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateUserSettingsNavigatorPropsArgs, useGenerateProps } from "./useGenerateProps";

export type UserSettingsNavigatorContainerProps = GenerateUserSettingsNavigatorPropsArgs;

const UserSettingsNavigatorContainer: React.FC<UserSettingsNavigatorContainerProps> = props => {
  const userOrCompanySettingsNavigatorProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.UserOrCompanySettingsNavigator {...userOrCompanySettingsNavigatorProps} />
    </ErrorBoundary>
  );
};

UserSettingsNavigatorContainer.displayName = "UserSettingsNavigatorContainer";

export default withErrorBoundary(UserSettingsNavigatorContainer, {});
