import { useTranslation } from "@hireroo/i18n";
import { MemberSearchForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import ResourceField from "../../../../modules/ResourceField/ResourceField";
import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";

const StyledChip = styled(Chip)(({ theme }) => ({
  height: 30,
  ".MuiChip-deleteIcon": {
    color: theme.palette.secondary.main,
  },
  color: theme.palette.secondary.main,
  borderColor: theme.palette.secondary.main,
}));

export type AddMembersToGroupsProps = {
  dialog: Pick<DialogWithCloseIconProps, "open" | "onClose" | "yesButton" | "noButton">;
  selectedMembers: { id: string; displayName: string; src?: string }[];
  SearchGroups: React.ReactNode;
  onSubmit: SubmitHandler<MemberSearchForm.GroupSearchFormSchema>;
};

const AddMembersToGroups: React.FC<AddMembersToGroupsProps> = props => {
  const { t } = useTranslation();
  const validator = MemberSearchForm.useGroupSearchForm();
  const methods = useForm<MemberSearchForm.GroupSearchFormSchema>({
    resolver: zodResolver(validator),
    defaultValues: {
      groups: [],
    },
  });
  const dialog: DialogWithCloseIconProps = {
    ...props.dialog,
    title: t("グループに追加"),
    noButton: {
      ...props.dialog.noButton,
      color: "outline-only",
      variant: "outlined",
      children: t("キャンセル"),
      onClick: event => {
        props.dialog.noButton?.onClick?.(event);
        methods.clearErrors();
      },
    },
    onClose: () => {
      props.dialog.onClose?.();
      methods.clearErrors();
    },
    yesButton: {
      ...props.dialog.yesButton,
      color: "secondary",
      children: t("追加"),
      onClick: () => {
        methods.handleSubmit(props.onSubmit)();
        props.dialog.yesButton?.onClick?.();
      },
    },
    disableEnter: true,
  };

  return (
    <DialogWithCloseIcon {...dialog}>
      <Typography color="textSecondary" fontSize={14} mb={1}>
        {t("メンバーをグループに追加することで、レビュアーの設定が容易になります。")}
      </Typography>
      <Stack direction="column" spacing={1.5} width="100%" mb={1.5}>
        <Typography fontSize={14} fontWeight={700}>
          {t("対象メンバー")}
        </Typography>
        <Stack direction="row" width="100%" gap={1} flexWrap="wrap">
          {props.selectedMembers.map(member => {
            return <StyledChip key={member.id} variant="outlined" avatar={<Avatar src={member.src} />} label={member.displayName} />;
          })}
        </Stack>
      </Stack>
      <ResourceField label={t("追加するグループ")} kind="REQUIRED">
        <FormProvider {...methods}>{props.SearchGroups}</FormProvider>
      </ResourceField>
    </DialogWithCloseIcon>
  );
};

AddMembersToGroups.displayName = "AddMembersToGroups";

export default AddMembersToGroups;
