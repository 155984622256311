import * as z from "zod";

import { Difficulty, QuestionStatus } from "../react-hook-form/fields/Question";

export const UniqueKeys = z.string().array();

export const QuestionForListSessionStorageSchema = z.object({
  id: z.number(),
  version: z.string().optional(),
  projectVersion: z.string().optional(),
  companyId: z.number(),
  employeeId: z.string(),
  //same as ListQuestion __typename
  type: z.union([
    z.literal("AlgorithmQuestion"),
    z.literal("MultiChoicePackage"),
    z.literal("SystemDesignQuestion"),
    z.literal("ProjectQuestion"),
    z.literal("FreepadQuestion"),
  ]),
  titleJa: z.string(),
  titleEn: z.string(),
  descriptionJa: z.string(),
  descriptionEn: z.string(),
  difficulty: Difficulty,
  status: QuestionStatus,
  isPrivate: z.boolean(),
  isOfficial: z.boolean(),
  numUses: z.number(),
  accuracyRate: z.number(),
  averageElapsedTimeSeconds: z.number(),
  supportedLanguages: z.string().array(),
});

export type QuestionForListSchemaType = z.infer<typeof QuestionForListSessionStorageSchema>;
