import { useTranslation } from "@hireroo/i18n";
import AddIcon from "@mui/icons-material/Add";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import RemoveIcon from "@mui/icons-material/Remove";
import Box, { BoxProps } from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { FileNodeDiff } from "../../fileTree";

const IconMap = {
  ADD: <AddIcon fontSize="small" color="success" sx={{ height: "14px", width: "14px" }} />,
  REMOVE: <RemoveIcon fontSize="small" color="error" sx={{ height: "14px", width: "14px" }} />,
} as const satisfies Record<string, React.ReactNode>;

export type TreeItemLabelProps = {
  node: FileNodeDiff;
};

const TreeItemLabel: React.FC<TreeItemLabelProps> = props => {
  const { t } = useTranslation();
  const { node } = props;

  const wrapperBoxProps: BoxProps = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    sx: {
      height: 28,
    },
  };

  const toolbarBoxProps: BoxProps = {
    className: "project-file-nav-icons",
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  };

  return (
    <Box {...wrapperBoxProps}>
      <Box display="flex" flexDirection="row" alignItems="center" overflow="hidden">
        {node.isDir ? <FolderOutlinedIcon fontSize={"small"} /> : <InsertDriveFileOutlinedIcon fontSize={"small"} />}
        <Typography ml={1} variant={"body2"} color={"inherit"} sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", lineHeight: 0.9 }}>
          {node.name}
        </Typography>
        <Box mr={1} />
        {!node.isDir && node.isReadOnly && (
          <Tooltip title={t("このファイルは編集できません")} placement={"right"}>
            <LockOutlinedIcon fontSize={"small"} />
          </Tooltip>
        )}
      </Box>
      {props.node.diffKind !== "NONE" && <Box {...toolbarBoxProps}>{IconMap[props.node.diffKind]}</Box>}
    </Box>
  );
};

TreeItemLabel.displayName = "TreeItemLabel";

export default TreeItemLabel;
