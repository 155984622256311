import { ref } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

export const initialize = (newValue: Types.Interview): void => {
  state.interview = newValue;
  const newEmployeeIds: string[] = [];
  const newEmployeeGroupIds: string[] = [];
  newValue.reviewers.forEach(reviewer => {
    if (reviewer.type === "EMPLOYEE" && reviewer.employee) {
      newEmployeeIds.push(reviewer.employee.id);
    } else if (reviewer.type === "EMPLOYEE_GROUP" && reviewer.group) {
      newEmployeeGroupIds.push(reviewer.group.id);
    }
  });
  state.selectedEmployeeIds = newEmployeeIds;
  state.selectedGroupIds = newEmployeeGroupIds;
  state.defaultSelectedEmployeeIds = newEmployeeIds;
  state.defaultSelectedGroupIds = newEmployeeGroupIds;
};

export const setRefresh = (callback: Types.RefreshCallback) => {
  state.refresh = ref(callback);
};

export const updateInterview = (newValue: Types.Interview): void => {
  state.interview = newValue;
};

export const updateSelectedEmployeeIds = (newValue: string[]) => {
  state.selectedEmployeeIds = newValue;
};

export const updateSelectedGroupIds = (newValue: string[]) => {
  state.selectedGroupIds = newValue;
};

export const updateTags = (newValue: string[]) => {
  state.tags = newValue;
};
