import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateTestRankVisualizerPropsArgs, useGenerateProps } from "./useGenerateProps";

export type TestRankVisualizerContainerProps = GenerateTestRankVisualizerPropsArgs;

const TestRankVisualizerContainer: React.FC<TestRankVisualizerContainerProps> = props => {
  const testRankVisualizerProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.TestRankVisualizer {...testRankVisualizerProps} />
    </ErrorBoundary>
  );
};

TestRankVisualizerContainer.displayName = "TestRankVisualizerContainer";

export default withErrorBoundary(TestRankVisualizerContainer, {});
