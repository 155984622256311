import { useTranslation } from "@hireroo/i18n";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import OpenInNewOutlined from "@mui/icons-material/OpenInNewOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

import QuestionDetailInfo, { QuestionDetailInfoProps } from "../QuestionDetailInfo/QuestionDetailInfo";
import QuestionStats, { QuestionStatsProps } from "../QuestionStats/QuestionStats";
import ScoreHistogram, { ScoreHistogramProps } from "./parts/ScoreHistogram/ScoreHistogram";

const SidebarWrapper = styled(Stack)(({ theme }) => ({
  height: "100%",
  backgroundColor: theme.palette["Secondary/Shades"].p8,
  minWidth: 250,
  overflow: "hidden",
}));

const StyledTitleBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette["Secondary/Shades"].p12,
  padding: "8px 16px",
  justifyContent: "flex-start",
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
}));

const StyledContentStack = styled(Stack)(() => ({
  padding: "8px 16px",
  overflowX: "hidden",
  overflowY: "auto",
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  fontSize: "13px",
  lineHeight: "18px",
  height: "24px",
  backgroundColor: theme.palette["Gray/Shades"].p16,
}));

const StyledChipWrapperStack = styled(Stack)(({ theme }) => ({
  flexWrap: "wrap",
  gap: theme.spacing(1.5),
  overflowY: "auto",
  width: "100%",
}));

type SkillTag = {
  label: string;
  tooltip: string;
};

export type QuestionInformationSidePanelProps = {
  className?: string;
  stats: QuestionStatsProps;
  detailInfo: QuestionDetailInfoProps;
  feedbackLink: string;
  scoreHistogram?: ScoreHistogramProps;
  skillTags?: SkillTag[];
};

const QuestionInformationSidePanel: React.FC<QuestionInformationSidePanelProps> = props => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <SidebarWrapper className={props.className}>
      <StyledTitleBox>
        <InfoOutlinedIcon fontSize="small" htmlColor={theme.palette["Gray/Shades"].p56} />
        <Typography ml={1} variant="body2">
          {t("この問題について")}
        </Typography>
      </StyledTitleBox>
      <StyledContentStack direction="column" spacing={3} p={1.5}>
        <QuestionStats {...props.stats} />
        <QuestionDetailInfo {...props.detailInfo} />
        {props.scoreHistogram && (
          <Stack direction="column" spacing={1.5} px={2} pt={3}>
            <Typography fontSize={12} color="textSecondary">
              {t("スコア分布")}
            </Typography>
            {props.scoreHistogram && <ScoreHistogram {...props.scoreHistogram} />}
          </Stack>
        )}
        {props.skillTags && (
          <Stack direction="column" spacing={1.5} px={2}>
            <Typography fontSize={12} color="textSecondary">
              {t("スキルタグ")}
            </Typography>
            <StyledChipWrapperStack direction="row">
              {props.skillTags.map((skillTag, index) => (
                <Tooltip title={skillTag.tooltip} key={`${skillTag.label}-${index}`}>
                  <StyledChip key={`${skillTag.label}-${index}`} label={skillTag.label} />
                </Tooltip>
              ))}
              {props.skillTags.length === 0 && (
                <Typography fontSize={10} color="textSecondary">
                  {t("スキルタグは付与されていません。")}
                </Typography>
              )}
            </StyledChipWrapperStack>
          </Stack>
        )}
        <Box>
          <Button
            href={props.feedbackLink}
            rel="noreferrer"
            target="_blank"
            component="a"
            color="secondary"
            variant="outlined"
            endIcon={<OpenInNewOutlined />}
          >
            {t("この問題に対してフィードバックをする")}
          </Button>
        </Box>
      </StyledContentStack>
    </SidebarWrapper>
  );
};

QuestionInformationSidePanel.displayName = "QuestionInformationSidePanel";

export default QuestionInformationSidePanel;
