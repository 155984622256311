import { proxyMap } from "valtio/utils";

import { State } from "./types";

export const createDefaultState = (): Readonly<State> => ({
  activeStep: 0,
  multiChoiceTagsSearchQuery: {
    cursorSeconds: null,
    isDescending: true,
    queryString: "",
    size: 500,
    withCount: true,
  },
  selectedTags: proxyMap(),
  orderOfQuestionIds: [],
  previewQuestionId: null,
  openNewQuestionEditor: false,
  showWarningDialog: false,
  selectedQuestions: proxyMap(),
});
