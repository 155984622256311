import { useTranslation } from "@hireroo/i18n";
import { SlackSettingsForm } from "@hireroo/validator";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import LanguageSelector from "../../../../modules/LanguageSelector/LanguageSelector";
import SwitchControl, { SwitchControlProps } from "../../../../primitive/InputControl/SwitchControl/SwitchControl";
import InterviewFields, { InterviewFieldsProps } from "../InterviewFields/InterviewFields";

const StyledStack = styled(Stack)(({ theme }) => ({
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(1.5),
  borderBottom: "1px solid",
  borderColor: theme.palette["Secondary/Shades"].p12,
}));

const StyledLanguageSelector = styled(LanguageSelector)(({ theme }) => ({
  "& .MuiSelect-select": {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    minWidth: "80px",
    fontSize: "13px",
  },
}));

const Item = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(1.5),
  borderBottom: "1px solid",
  borderColor: theme.palette["Secondary/Shades"].p12,
}));
const ItemTypography = styled(Typography)(() => ({
  fontSize: 14,
}));

const StyledTypography = styled(Typography)({
  fontWeight: 700,
});

type SpotSettingsFieldName = keyof SlackSettingsForm.SlackSettingsFormSchema["spotSettings"];
type FieldName = `spotSettings.${SpotSettingsFieldName}`;

type Item = {
  title: string;
  name: FieldName;
  isTop?: boolean;
};

export type SlackNotificationFieldsProps = {
  disabled?: boolean;
  slackChannel: string;
  interviewFields?: Omit<InterviewFieldsProps, "disabled">;
  reIntegrateButton?: Pick<ButtonProps, "onClick">;
};

const SlackNotificationFields: React.FC<SlackNotificationFieldsProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const method = useFormContext();

  const commonSwitchControl: SwitchControlProps = {
    color: "secondary",
    size: "small",
    control: method.control,
    disabled: props.disabled,
  };
  const interviewFields: InterviewFieldsProps | undefined = props.interviewFields
    ? {
        ...props.interviewFields,
        disabled: props.disabled,
      }
    : undefined;
  const reIntegrateButton: ButtonProps = {
    ...props.reIntegrateButton,
    children: t("再連携"),
    variant: "outlined",
    color: "secondary",
    size: "small",
  };
  const items: Item[] = [
    {
      title: t("テスト作成時"),
      name: "spotSettings.subscribeSpotCreated",
      isTop: true,
    },
    {
      title: t("テスト開始時"),
      name: "spotSettings.subscribeSpotStarted",
      isTop: true,
    },
    {
      title: t("テスト終了時"),
      name: "spotSettings.subscribeSpotCompleted",
    },
    {
      title: t("採点完了時"),
      name: "spotSettings.subscribeSpotFinalized",
    },
    {
      title: t("テストレビュー時"),
      name: "spotSettings.subscribeSpotReviewed",
    },
    {
      title: t("テスト評価時"),
      name: "spotSettings.subscribeSpotEvaluated",
    },
    {
      title: t("再試験時"),
      name: "spotSettings.subscribeSpotRecreated",
    },
    {
      title: t("テスト削除時"),
      name: "spotSettings.subscribeSpotDeleted",
    },
    {
      title: t("テストの提出期限時"),
      name: "spotSettings.subscribeSpotExpired",
    },
  ];
  return (
    <Stack direction="column" spacing={3}>
      <Stack>
        <StyledStack direction="row">
          <ItemTypography>{t("通知チャンネル")}</ItemTypography>
          <ItemTypography>{props.slackChannel}</ItemTypography>
        </StyledStack>
        <StyledStack direction="row">
          <ItemTypography>{t("通知言語")}</ItemTypography>
          <Box width="100px">
            <StyledLanguageSelector name="language" color="secondary" disabled={props.disabled} />
          </Box>
        </StyledStack>
        <StyledStack direction="row">
          <ItemTypography>{t("通知チャンネル変更")}</ItemTypography>
          {props.reIntegrateButton ? <Button {...reIntegrateButton} /> : <Typography>{t("連携には管理者権限が必要です。")}</Typography>}
        </StyledStack>
      </Stack>

      <Stack direction="column">
        <Box display="flex" justifyContent="flex-start">
          <StyledTypography fontSize={14} mb={1.5}>
            {t("テスト通知")}
          </StyledTypography>
        </Box>

        <Box
          sx={{
            display: "grid",
            columnGap: 2,
            gridTemplateColumns: "repeat(2, 1fr)",
            width: "100%",
          }}
        >
          {items.map(item => (
            <Item key={item.title} sx={item.isTop ? { borderTop: "1px solid", borderColor: theme.palette["Secondary/Shades"].p12 } : undefined}>
              <ItemTypography>{item.title}</ItemTypography>
              <Box>
                <SwitchControl name={item.name} {...commonSwitchControl} />
              </Box>
            </Item>
          ))}
        </Box>
      </Stack>
      {interviewFields && <InterviewFields {...interviewFields} />}
    </Stack>
  );
};

SlackNotificationFields.displayName = "SlackNotificationFields";

export default SlackNotificationFields;
