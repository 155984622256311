import { RuntimeSelector, RuntimeSelectorProps } from "@hireroo/challenge/react/v2/widget";
import { ChallengeCodingEditor, ChallengeCodingEditorProps } from "@hireroo/challenge/react/widget";
import { useTranslation } from "@hireroo/i18n";
import { useMethods, withSplitProvider } from "@hireroo/react-split";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";

import ActivityBar, { ActivityBarProps } from "../../../../modules/ActivityBar/ActivityBar";
import AnswerSection, { AnswerSectionProps } from "../../../../ms-components/Question/AnswerSection/AnswerSection";
import QuestionAndAnswerTab, { QuestionAndAnswerTabProps } from "../../../../ms-components/Question/QuestionAndAnswerTab/QuestionAndAnswerTab";
import QuestionInformationSidePanel, {
  QuestionInformationSidePanelProps,
} from "../../../../ms-components/Question/QuestionInformationSidePanel/QuestionInformationSidePanel";
import Split, { SplitProps } from "../../../../primitive/Split/Split";
import { SplitParams, useSplitEvent } from "./privateHelper";

const StyledSplit = styled(Split)`
  height: calc(100vh - 73px);
  overflow: hidden;
  position: relative;
`;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  max-width: 100vw;
`;

const StyledQuestionAndAnswerTab = styled(QuestionAndAnswerTab)(({ theme }) => ({
  backgroundColor: theme.palette["Secondary/Shades"].p8,
}));

const Toolbar = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette["Secondary/Shades"].p12,
}));

export type AlgorithmQuestionDetailProps = {
  information: QuestionInformationSidePanelProps;
  codeEditor: ChallengeCodingEditorProps;
  runtimeSelector: RuntimeSelectorProps;
  questionAndAnswerTab: Omit<QuestionAndAnswerTabProps, "answerSections">;
  answerSections: (AnswerSectionProps & { onSelect?: () => void })[];
};

const AlgorithmQuestionDetail: React.FC<AlgorithmQuestionDetailProps> = props => {
  const { t } = useTranslation();
  const methods = useMethods();
  const splitEvent = useSplitEvent();
  const questionAndAnswerTabProps: QuestionAndAnswerTabProps = {
    ...props.questionAndAnswerTab,
    answerSections: props.answerSections.map((answerSectionProps, index): QuestionAndAnswerTabProps["answerSections"][0] => {
      const { onSelect, ...answerSection } = answerSectionProps;
      return {
        name: [t("解答"), index + 1].join(" "),
        Component: <AnswerSection {...answerSection} />,
        onClickTab: () => {
          onSelect?.();
        },
      };
    }),
  };
  const splitProps: SplitProps = {
    splitId: SplitParams.splitId,
    splitDirection: "VERTICAL",
    items: [
      {
        id: SplitParams.Contents.QuestionAndAnswer.id,
        Content: <StyledQuestionAndAnswerTab {...questionAndAnswerTabProps} />,
        size: {
          value: 400,
          unit: "px",
        },
        expandedSize: {
          value: 400,
          unit: "px",
        },
        minimizedSize: {
          value: 0,
          unit: "px",
        },
      },
      {
        id: "CodingEditor",
        Content: (
          <Box key="coding-editor" height="100%">
            <Toolbar>
              <Box display="flex" justifyContent="left" alignItems="center">
                <RuntimeSelector {...props.runtimeSelector} />
              </Box>
            </Toolbar>
            <ChallengeCodingEditor {...props.codeEditor} />
          </Box>
        ),
        size: {
          value: 100,
          unit: "%",
        },
      },
      {
        id: SplitParams.Contents.Information.id,
        Content: <QuestionInformationSidePanel {...props.information} />,
        size: {
          value: 400,
          unit: "px",
        },
        expandedSize: {
          value: 400,
          unit: "px",
        },
        minimizedSize: {
          value: 0,
          unit: "px",
        },
      },
    ],
  };

  const activityBarProps: ActivityBarProps = {
    items: [
      {
        icon: "DESCRIPTION",
        tooltip: t("問題"),
        onClick: () => {
          if (splitEvent.sidebarVisibleStatus === "MINIMIZED") {
            methods.expand(SplitParams.splitId, SplitParams.Contents.QuestionAndAnswer.id);
          } else {
            methods.minimize(SplitParams.splitId, SplitParams.Contents.QuestionAndAnswer.id);
          }
          splitEvent.setSidebarVisibleStatus(prev => (prev === "MINIMIZED" ? "OPEN" : "MINIMIZED"));
        },
        active: splitEvent.sidebarVisibleStatus === "OPEN",
      },
      {
        icon: "INFO",
        tooltip: t("この問題について"),
        onClick: () => {
          if (splitEvent.informationVisibleStatus === "MINIMIZED") {
            methods.expand(SplitParams.splitId, SplitParams.Contents.Information.id);
          } else {
            methods.minimize(SplitParams.splitId, SplitParams.Contents.Information.id);
          }
          splitEvent.setInformationVisibleStatus(prev => (prev === "MINIMIZED" ? "OPEN" : "MINIMIZED"));
        },
        active: splitEvent.informationVisibleStatus === "OPEN",
      },
    ],
  };

  return (
    <Wrapper>
      <ActivityBar {...activityBarProps} />
      <StyledSplit {...splitProps} />
    </Wrapper>
  );
};

AlgorithmQuestionDetail.displayName = "AlgorithmQuestionDetail";

export default withSplitProvider(AlgorithmQuestionDetail);
