import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Popover, { PopoverProps } from "@mui/material/Popover";
import * as React from "react";

export type EditMenuItem = {
  text: string;
  value: string;
  onClick?: () => void;
};

export type EditMenuProps = {
  items: EditMenuItem[];
  anchorEl: HTMLButtonElement | null;
  onClose: PopoverProps["onClose"];
};

const EditMenu: React.FC<EditMenuProps> = props => {
  return (
    <Popover open={Boolean(props.anchorEl)} anchorEl={props.anchorEl} onClose={props.onClose}>
      <List component="nav">
        {props.items.map(item => (
          <ListItemButton key={item.value} onClick={item.onClick}>
            <ListItemText primary={item.text} />
          </ListItemButton>
        ))}
      </List>
    </Popover>
  );
};

EditMenu.displayName = "EditMenu";

export default EditMenu;
