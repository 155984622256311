import AddIcon from "@mui/icons-material/Add";
import RedoIcon from "@mui/icons-material/Redo";
import RemoveIcon from "@mui/icons-material/Remove";
import UndoIcon from "@mui/icons-material/Undo";
import Box, { BoxProps } from "@mui/material/Box";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { MAX_SCALE, MIN_SCALE } from "../../constants/flowChart";
import { useSystemDesignContext } from "../../store";

const MiniBar = styled(Box)`
  position: absolute;
  left: 8px;
  bottom: 0;
  display: flex;
  align-items: center;
`;

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
}));

const IconBox = styled(({ disabled: _, ...props }: BoxProps & { disabled: boolean }) => <Box {...props} />)(({ disabled, theme }) => {
  return {
    backgroundColor: theme.palette.grey[200],
    borderRadius: 20,
    "&:hover": !disabled
      ? {
          backgroundColor: theme.palette.grey[300],
        }
      : undefined,
  };
});

const StyledIconButton = styled(({ disabled: _, ...props }: IconButtonProps & { disabled: boolean }) => <IconButton {...props} />)(({
  disabled,
  theme,
}) => {
  return {
    color: disabled ? theme.palette.grey[400] : theme.palette.common.black,
    cursor: "default",
    svg: {
      cursor: disabled ? undefined : "pointer",
    },
  };
});

export type EditControlPanelProps = {
  canUndo: boolean;
  canRedo: boolean;
  undoButton: Pick<IconButtonProps, "onClick">;
  redoButton: Pick<IconButtonProps, "onClick">;
};

const EditControlPanel: React.FC<EditControlPanelProps> = props => {
  const Store = useSystemDesignContext();
  const scale = Store.hooks.useScale();
  const theme = useTheme();

  const handleZoomOut = React.useCallback(() => {
    const newScale = (Math.round(scale * 10) - 1) / 10;
    Store.action.zoomViewbox({ scale: newScale });
  }, [Store.action, scale]);

  const handleZoomIn = React.useCallback(() => {
    const newScale = (Math.round(scale * 10) + 1) / 10;
    Store.action.zoomViewbox({ scale: newScale });
  }, [Store.action, scale]);

  return (
    <MiniBar>
      <StyledPaper elevation={4}>
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"} p={1}>
          <IconBox disabled={scale === MIN_SCALE}>
            <StyledIconButton disabled={scale === MIN_SCALE} size={"small"} onClick={handleZoomOut}>
              <RemoveIcon />
            </StyledIconButton>
          </IconBox>
          <Box mr={1} />
          <IconBox disabled={scale === MAX_SCALE}>
            <StyledIconButton disabled={scale === MAX_SCALE} size={"small"} onClick={handleZoomIn}>
              <AddIcon />
            </StyledIconButton>
          </IconBox>
          <Box mr={1} />
          <Typography sx={{ color: theme.palette.common.black }}>{Math.round(scale * 10) * 10}%</Typography>
        </Box>
      </StyledPaper>

      <Box mr={1} />

      <StyledPaper elevation={4}>
        <Box width={96} display={"flex"} alignItems={"center"} justifyContent={"center"} p={1}>
          <IconBox disabled={!props.canUndo}>
            <StyledIconButton size={"small"} {...props.undoButton} disabled={!props.canUndo}>
              <UndoIcon />
            </StyledIconButton>
          </IconBox>
          <Box mr={1} />
          <IconBox disabled={!props.canRedo}>
            <StyledIconButton size={"small"} {...props.redoButton} disabled={!props.canRedo}>
              <RedoIcon />
            </StyledIconButton>
          </IconBox>
        </Box>
      </StyledPaper>
    </MiniBar>
  );
};

EditControlPanel.displayName = "EditControlPanel";

export default EditControlPanel;
