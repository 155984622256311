import { useEnabledHomeRedesignPhaseTwo } from "@hireroo/app-helper/re-design";
import { Payment } from "@hireroo/app-store/essential/employee";
import type { Widget } from "@hireroo/presentation";

import DashboardRemoteInterviewSectionFetchContainer from "../DashboardRemoteInterviewSection/FetchContainer";
import DashboardScreeningsSectionFetchContainer from "../DashboardScreeningsSection/FetchContainer";
import DashboardScreeningStatsSectionInitialContainer from "../DashboardScreeningStatsSection/InitialContainer";

export type GenerateHomePropsArgs = {};

export const useGenerateProps = (_args: GenerateHomePropsArgs): Widget.HomeProps => {
  const isAvailableFeature = Payment.useIsAvailableFeature();
  const enabledHomeRedesignPhaseTwo = useEnabledHomeRedesignPhaseTwo();
  return {
    Onboarding: enabledHomeRedesignPhaseTwo ? (
      // TODO: implement Onboarding
      <></>
    ) : null,
    ScreeningStats: enabledHomeRedesignPhaseTwo ? <DashboardScreeningStatsSectionInitialContainer /> : null,
    ScreeningSection: <DashboardScreeningsSectionFetchContainer />,
    InterviewSection: isAvailableFeature("interview.read") && <DashboardRemoteInterviewSectionFetchContainer />,
  };
};
