import { useTranslationWithVariable } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

const BarBox = styled(Box)(() => ({
  height: 14,
  alignItems: "center",
  justifyContent: "center",
}));

const DisplayPercentage = styled(Typography)(({ theme }) => ({
  fontSize: 10,
  display: "flex",
  justifyContent: "center",
  color: theme.palette.secondary.contrastText,
}));

type Score = {
  label: string;
  weight: number;
};

type ScoreWithWidth = Score & {
  width: string;
};

const MIN_WIDTH_PERCENTAGE = 3;

export type AllocationBarProps = {
  scores: Score[];
};

const AllocationBar: React.FC<AllocationBarProps> = props => {
  const theme = useTheme();
  const { t: t2 } = useTranslationWithVariable();
  const sumWeight = React.useMemo(() => props.scores.reduce((acc, score) => acc + Number(score.weight), 0), [props.scores]);
  const adjustedScores = React.useMemo((): ScoreWithWidth[] => {
    if (Number.isNaN(sumWeight) || sumWeight === 0) {
      return [];
    }
    /**
     * in order to prevent the bar from being too small, we need to adjust the width of the bar
     */
    const hasSmallerThanMinWidth = props.scores.some(score => (score.weight / sumWeight) * 100 < MIN_WIDTH_PERCENTAGE);
    if (!hasSmallerThanMinWidth) {
      return props.scores.map(score => ({
        ...score,
        width: `${(score.weight / sumWeight) * 100}%`,
      }));
    }
    let totalWidth = 100;
    return props.scores.map((score): ScoreWithWidth => {
      const actualPercentageDecimal = score.weight / sumWeight;
      if (actualPercentageDecimal < MIN_WIDTH_PERCENTAGE / 100) {
        return {
          ...score,
          width: `${MIN_WIDTH_PERCENTAGE}%`,
        };
      }
      totalWidth -= MIN_WIDTH_PERCENTAGE;
      const percentage = (score.weight / sumWeight) * totalWidth;
      return {
        ...score,
        width: `${percentage}%`,
      };
    });
  }, [props.scores, sumWeight]);
  return (
    <Stack direction="column" spacing="4px" width="100%">
      <Stack direction="row" width="100%" spacing="2px">
        {adjustedScores.map((score, index) => {
          const displayPercentage = Math.round((score.weight / sumWeight) * 100);
          const borderRadius = (() => {
            if (index === 0 && index === props.scores.length - 1) {
              return "20px";
            }
            if (index === 0) {
              return "20px 0 0 20px";
            }
            if (index === props.scores.length - 1) {
              return "0 20px 20px 0";
            }
            return undefined;
          })();
          const tooltipTitle =
            score.label +
            " " +
            t2("ScoreAllocation", {
              percentage: displayPercentage,
              weight: score.weight,
            });
          return (
            <Tooltip key={`score-${score.label}-${index}`} title={tooltipTitle} placement="top">
              <BarBox
                width={score.width}
                sx={{ borderRadius: borderRadius }}
                bgcolor={
                  Number.isNaN(Number(score.weight)) || Number(score.weight) < 1 ? theme.palette.error.light : theme.palette.secondary.main
                }
              >
                <DisplayPercentage fontSize={10}>{`${displayPercentage}%`}</DisplayPercentage>
              </BarBox>
            </Tooltip>
          );
        })}
      </Stack>
      <Stack direction="row" spacing="2px">
        {adjustedScores.map((score, index) => {
          return (
            <Box key={`score-label-${score.label}-${index}`} width={score.width}>
              <Typography fontSize={10} textAlign="center" color="textSecondary">
                {score.label}
              </Typography>
            </Box>
          );
        })}
      </Stack>
    </Stack>
  );
};

AllocationBar.displayName = "AllocationBar";

export default AllocationBar;
