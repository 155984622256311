import { useTranslation } from "@hireroo/i18n";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import * as React from "react";

import EmployeeLayout, { EmployeeLayoutProps } from "../../layouts/EmployeeLayout/EmployeeLayout";

const Wrapper = styled(Box)(({ theme }) => ({
  borderRadius: 8,
  backgroundColor: theme.palette["Background/Paper"].p2,
}));

const BackButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const StyledContentWrapper = styled(Box)(() => ({
  width: "100%",
  overflow: "hidden",
}));

export type CompanySettingsEditorProps = {
  layout: EmployeeLayoutProps;
  children: React.ReactNode;
  backButton: Pick<ButtonProps, "onClick">;
};

const CompanySettingsEditor: React.FC<CompanySettingsEditorProps> = props => {
  const { t } = useTranslation();
  const backButtonProps: ButtonProps = {
    ...props.backButton,
    startIcon: <ArrowBackOutlinedIcon />,
    children: t("戻る"),
    variant: "text",
  };
  return (
    <EmployeeLayout {...props.layout}>
      <Box p={3}>
        <Wrapper p={3}>
          <Box mb={3}>
            <BackButton {...backButtonProps} />
          </Box>
          <StyledContentWrapper>{props.children}</StyledContentWrapper>
        </Wrapper>
      </Box>
    </EmployeeLayout>
  );
};

CompanySettingsEditor.displayName = "CompanySettingsEditor";

export default CompanySettingsEditor;
