import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import MultiChoiceObjectValueField, { MultiChoiceObjectValueFieldProps } from "./parts/MultiChoiceObjectValueField/MultiChoiceObjectValueField";
import MultiChoicePrimitiveValueField, {
  MultiChoicePrimitiveValueFieldProps,
} from "./parts/MultiChoicePrimitiveValueField/MultiChoicePrimitiveValueField";
import PopupSearchFilter, { PopupSearchFilterProps } from "./parts/PopupSearchFilter/PopupSearchFilter";
import { PopupSearchFilterProvider } from "./PrivateContext";

type MultiChoiceObjectValueFieldWithKindProps = MultiChoiceObjectValueFieldProps & { kind: "MULTI_CHOICE_OBJECT_VALUE" };
type MultiChoicePrimitiveValueFieldWithKindProps = MultiChoicePrimitiveValueFieldProps & { kind: "MULTI_CHOICE_PRIMITIVE_VALUE" };

type FieldProps = MultiChoiceObjectValueFieldWithKindProps | MultiChoicePrimitiveValueFieldWithKindProps;
type CachedFieldValues = Map<string, unknown>;

const StyledStack = styled(Stack)(() => ({
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
}));

export type PopupSearchFilterFieldProps = {
  title: string;
  disabled?: boolean;
  fields: FieldProps[];
  onSubmit?: () => void;
};

const PopupSearchFilterField: React.FC<PopupSearchFilterFieldProps> = props => {
  const method = useFormContext();
  const cachedFieldValues = React.useRef<CachedFieldValues>(new Map());
  const [dirtyCount, setDirtyCount] = React.useState(0);
  const dirtyMap = React.useRef<Map<string, boolean>>(new Map());
  const handleMultiChoiceChange = React.useCallback((name: string, selectedValues: unknown, isDirty: boolean) => {
    cachedFieldValues.current.set(name, selectedValues);
    dirtyMap.current.set(name, isDirty);
  }, []);

  const children = (
    <StyledStack spacing={1} direction="column">
      {props.fields.map((field, index) => {
        switch (field.kind) {
          case "MULTI_CHOICE_OBJECT_VALUE": {
            const { kind, ...fieldProps } = field;
            return (
              <MultiChoiceObjectValueField
                key={`${kind}-${index}`}
                {...fieldProps}
                disabled={props.disabled}
                onChange={values => handleMultiChoiceChange(field.name, values, values.length > 0)}
              />
            );
          }
          case "MULTI_CHOICE_PRIMITIVE_VALUE": {
            const { kind, ...fieldProps } = field;
            return (
              <MultiChoicePrimitiveValueField
                key={`${kind}-${index}`}
                {...fieldProps}
                disabled={props.disabled}
                onChange={values => handleMultiChoiceChange(field.name, values, values.length > 0)}
              />
            );
          }
          default:
            throw new Error(`kind is unknown: ${field satisfies never}`);
        }
      })}
    </StyledStack>
  );

  const popupSearchFilterProps: PopupSearchFilterProps = {
    count: dirtyCount,
    title: props.title,
    onApply: () => {
      for (const [name, fieldValue] of cachedFieldValues.current.entries()) {
        if (fieldValue !== null) {
          method.setValue(name, fieldValue);
        }
      }
      setDirtyCount([...dirtyMap.current.values()].filter(Boolean).length);
      props.onSubmit?.();
    },
    onReset: () => {
      for (const field of props.fields) {
        method.resetField(field.name);
      }
      setDirtyCount(0);
      dirtyMap.current.clear();
      props.onSubmit?.();
    },
    children,
  };

  return (
    <PopupSearchFilterProvider>
      <PopupSearchFilter {...popupSearchFilterProps} />
    </PopupSearchFilterProvider>
  );
};

PopupSearchFilterField.displayName = "PopupSearchFilterField";

export default PopupSearchFilterField;
