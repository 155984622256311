import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";

import DownloadResultDialog, { DownloadResultDialogProps } from "../../modules/DownloadResultDialog/DownloadResultDialog";
import RestrictableTab, { RestrictableTabProps, TabItem } from "../../modules/RestrictableTab/RestrictableTab";
import TestEvaluationDialog, { TestEvaluationDialogProps } from "../../modules/TestEvaluationDialog/TestEvaluationDialog";
import DetailPageHeader, { DetailPageHeaderProps } from "../../ms-components/Spot/DetailPageHeader/DetailPageHeader";
import DeletionDialog, { DeletionDialogProps } from "./parts/DeletionDialog/DeletionDialog";
import GenerateSharedLinkDialog, { GenerateSharedLinkDialogProps } from "./parts/GenerateSharedLinkDialog/GenerateSharedLinkDialog";

const PAGE_HEADER_HEIGHT = 48;
const PAGE_HEADER_V2_HEIGHT = 80;
const TAB_HEIGHT = 43;
const NON_CONTENT_HEIGHT = 72;

/**
 * 72px was calculated from the non-content height of EmployeeLayout
 */
const StyledBox = styled(Box)(({ theme }) => ({
  height: `calc(100vh - ${NON_CONTENT_HEIGHT}px)`,
  overflow: "hidden",
  backgroundColor: theme.palette["Background/Paper"].p2,
  minWidth: 900,
}));

const ContentWrapper = styled(Box)(() => ({
  overflowY: "auto",
  overflowX: "hidden",
}));

export type ScreeningTestDetailForEmployeeProps = {
  defaultTabId?: string;
  header: DetailPageHeaderProps;
  title: string;
  items: TabItem[];
  evaluationDialog: TestEvaluationDialogProps;
  RecreateDialog: React.ReactNode;
  deletionDialog: DeletionDialogProps;
  pdfDownloadResultDialog?: DownloadResultDialogProps;
  generateSharedLinkDialog: GenerateSharedLinkDialogProps;
  Prompt?: React.ReactNode;
};

const ScreeningTestDetailForEmployee: React.FC<ScreeningTestDetailForEmployeeProps> = props => {
  const theme = useTheme();
  const restrictableTabProps: RestrictableTabProps = {
    defaultTab: props.defaultTabId,
    items: props.items,
    tabs: {
      "aria-label": "test-report-tabs",
      sx: {
        "&.MuiTabs-root": {
          minHeight: `${TAB_HEIGHT}px`,
          height: `${TAB_HEIGHT}px`,
        },
        "& .MuiTab-root": {
          minHeight: `${TAB_HEIGHT}px`,
          height: `${TAB_HEIGHT}px`,
        },
        "& .MuiTab-root.Mui-selected": {
          color: theme.palette.secondary.main,
          backgroundColor: "inherit",
        },
      },
    },
    tabBox: {
      sx: {
        paddingLeft: "24px",
        paddingRight: "24px",
      },
    },
    wrapper: {},
    tabPanelBox: {
      pt: 0,
      sx: {
        bgcolor: theme.palette.background.default,
        minHeight: `calc(100vh - ${PAGE_HEADER_HEIGHT + TAB_HEIGHT + NON_CONTENT_HEIGHT}px)`,
      },
    },
    variant: "OUTLINED",
  };
  return (
    <StyledBox>
      <DetailPageHeader {...props.header} />
      <ContentWrapper
        id="report-content-wrapper"
        sx={{
          height: `calc(100% - ${PAGE_HEADER_V2_HEIGHT}px)`,
        }}
      >
        <RestrictableTab {...restrictableTabProps} />
      </ContentWrapper>
      <TestEvaluationDialog key={`open-${props.evaluationDialog.open.toString()}`} {...props.evaluationDialog} />
      {props.RecreateDialog}
      <DeletionDialog {...props.deletionDialog} />
      <GenerateSharedLinkDialog {...props.generateSharedLinkDialog} />
      {props.pdfDownloadResultDialog && <DownloadResultDialog {...props.pdfDownloadResultDialog} />}
      {props.Prompt}
    </StyledBox>
  );
};

ScreeningTestDetailForEmployee.displayName = "ScreeningTestDetailForEmployee";

export default ScreeningTestDetailForEmployee;
