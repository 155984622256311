import { useMonaco } from "@monaco-editor/react";
import type * as monaco from "monaco-editor";
import * as React from "react";

import hireRooDarkTheme from "../themes/hireRooDark.json" assert { type: "json" };
import monokaiTheme from "../themes/monokai.json" assert { type: "json" };
import * as SwapTheme from "./swap-theme";

export type Theme = "monokai" | "hirerooDark";

// Define the monokai theme: https://github.com/brijeshb42/monaco-themes
const themeMap: Record<Theme, monaco.editor.IStandaloneThemeData> = {
  monokai: monokaiTheme as monaco.editor.IStandaloneThemeData,
  hirerooDark: hireRooDarkTheme as monaco.editor.IStandaloneThemeData,
};

type ThemePayload = {
  theme: Theme;
  themeData: monaco.editor.IStandaloneThemeData;
};

export const getTheme = (theme: Theme): ThemePayload => {
  return {
    theme: theme,
    themeData: themeMap[theme],
  };
};

export const useStyledMonaco = () => {
  const monaco = useMonaco();
  React.useEffect(() => {
    if (!monaco) {
      return;
    }
    SwapTheme.hideVscodeTheme();
    const { theme, themeData } = getTheme("monokai");
    monaco.editor.defineTheme(theme, themeData as monaco.editor.IStandaloneThemeData);

    return () => {
      /**
       * DO NOT REMOVE
       * "@monaco-editor/react"用のthemeをアンマウント時にリセットする
       */
      monaco.editor.defineTheme(theme, {
        base: "vs-dark",
        inherit: false,
        rules: [],
        colors: {},
      });
    };
  }, [monaco]);
  return monaco;
};
