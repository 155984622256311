import { styled } from "@mui/material/styles";
import MUITable, { TableProps as MUITableProps } from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import * as React from "react";

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  margin: "16px 0",
  borderTop: `solid 1px ${theme.palette.Other.Divider}`,
  borderRight: `solid 1px ${theme.palette.Other.Divider}`,
  borderLeft: `solid 1px ${theme.palette.Other.Divider}`,
  borderRadius: theme.shape.borderRadius / 2,
}));

export type TableProps = MUITableProps & {};

const Table: React.FC<TableProps> = props => {
  const { children } = props;
  return (
    <StyledTableContainer>
      <MUITable {...props} size="small">
        {children}
      </MUITable>
    </StyledTableContainer>
  );
};

Table.displayName = "Table";

export default Table;
