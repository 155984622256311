import { useTranslation } from "@hireroo/i18n";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import SubmissionAccordionFreeTextAnswer, {
  SubmissionAccordionFreeTextAnswerProps,
} from "../SubmissionAccordionFreeTextAnswer/SubmissionAccordionFreeTextAnswer";
import SubmissionAccordionItemLayout, {
  SubmissionAccordionItemLayoutProps,
} from "../SubmissionAccordionItemLayout/SubmissionAccordionItemLayout";
import SubmissionMultiOptionAnswer, { SubmissionMultiOptionAnswerProps } from "../SubmissionMultiOptionAnswer/SubmissionMultiOptionAnswer";
import SubmissionSingleOptionAnswer, { SubmissionSingleOptionAnswerProps } from "../SubmissionSingleOptionAnswer/SubmissionSingleOptionAnswer";

type Status = "CORRECT" | "WRONG";

type SingleOptionTypeAnswer = {
  kind: "SINGLE_OPTION";
  content: SubmissionSingleOptionAnswerProps;
  correctStatus: Status;
};

type MultiOptionTypeAnswer = {
  kind: "MULTI_OPTION";
  content: SubmissionMultiOptionAnswerProps;
  correctStatus: Status;
};

type FreeTextTypeAnswer = {
  kind: "FREE_TEXT";
  content: SubmissionAccordionFreeTextAnswerProps;
  correctStatus?: Status;
};

export type SubmissionAccordionAnswerContentProps = SingleOptionTypeAnswer | MultiOptionTypeAnswer | FreeTextTypeAnswer;

const SubmissionAccordionAnswerContent: React.FC<SubmissionAccordionAnswerContentProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const StatusMap = {
    CORRECT: (
      <Stack direction="row" alignItems="center">
        <CheckCircleIcon fontSize="small" color="success" />
        <Typography sx={{ color: theme.palette.success.main, ml: 0.5 }}>{t("正解")}</Typography>
      </Stack>
    ),
    WRONG: (
      <Stack direction="row" alignItems="center">
        <CancelIcon fontSize="small" color="error" />
        <Typography sx={{ color: theme.palette.error.main, ml: 0.5 }}>{t("不正解")}</Typography>
      </Stack>
    ),
  } satisfies Record<Status, React.ReactNode>;
  const contentProps: SubmissionAccordionItemLayoutProps = {
    title: t("解答"),
    RightTitleContent: props.correctStatus && StatusMap[props.correctStatus],
    child: {
      kind: "COMPONENT",
      content: (
        <Box>
          {props.kind === "SINGLE_OPTION" && <SubmissionSingleOptionAnswer {...props.content} />}
          {props.kind === "MULTI_OPTION" && <SubmissionMultiOptionAnswer {...props.content} />}
          {props.kind === "FREE_TEXT" && <SubmissionAccordionFreeTextAnswer {...props.content} />}
        </Box>
      ),
    },
  };

  return <SubmissionAccordionItemLayout {...contentProps} />;
};

SubmissionAccordionAnswerContent.displayName = "SubmissionAccordionAnswerContent";

export default SubmissionAccordionAnswerContent;
