import { useTranslation } from "@hireroo/i18n";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DeviceSettingButtonGroup, { DeviceSettingButtonGroupProps } from "../DeviceSettingButtonGroup/DeviceSettingButtonGroup";

type Mode = "PRE_JOIN" | "SETTING";

const StyledButton = styled(Button)`
  width: 110px;
`;

const StyledLoadingButton = styled(LoadingButton)`
  width: 110px;
`;

export type LiveCodingSettingDialogProps = {
  mode: Mode;
  isLoading?: boolean;
  VideoContent: React.ReactNode;
  deviceToggleButtons?: DeviceSettingButtonGroupProps;
  yesButton: Pick<LoadingButtonProps, "loading" | "onClick">;
  noButton: Pick<ButtonProps, "onClick">;
  DeviceSettings?: React.ReactNode;
  /**
   * TODO refactoring: Change to meaningful properties
   */
  hasWarning?: boolean;
  open: boolean;
  onClose?: () => void;
  forceClose?: boolean;
};

const LiveCodingSettingDialog: React.FC<LiveCodingSettingDialogProps> = props => {
  const { t } = useTranslation();
  const { forceClose, onClose } = props;

  React.useEffect(() => {
    if (forceClose) {
      onClose?.();
    }
  }, [forceClose, onClose]);
  const ModeMap = {
    PRE_JOIN: (
      <Stack direction={"row"} spacing={2}>
        <StyledButton {...props.noButton} color="secondary" variant="outlined" disabled={props.isLoading}>
          {t("キャンセル")}
        </StyledButton>
        <StyledLoadingButton
          {...props.yesButton}
          variant="contained"
          color="secondary"
          loadingPosition="start"
          startIcon={<SaveIcon sx={{ display: props.yesButton.loading ? "block" : "none" }} color="secondary" />}
        >
          {t("参加")}
        </StyledLoadingButton>
      </Stack>
    ),
    SETTING: (
      <Stack>
        <StyledButton {...props.noButton} color="secondary" variant="contained">
          {t("閉じる")}
        </StyledButton>
      </Stack>
    ),
  } satisfies Record<Mode, React.ReactNode>;

  return (
    <Dialog open={props.open} onClose={props.onClose} PaperProps={{ sx: { minWidth: "640px", p: 2 } }}>
      <Typography variant="h6">{t("ビデオ通話")}</Typography>
      <Stack direction={"column"} spacing={2} mb={2}>
        <Box display="flex" mx="auto" justifyContent="center">
          {props.VideoContent}
        </Box>
        {props.deviceToggleButtons && <DeviceSettingButtonGroup {...props.deviceToggleButtons} />}
        {props.hasWarning ? (
          <Alert variant="standard" severity="info">
            {t("ビデオ通話を使用するためには、カメラとマイクを許可してください。")}
          </Alert>
        ) : (
          props.DeviceSettings
        )}
      </Stack>
      <Stack direction={"row"} spacing={1} justifyContent="center">
        {ModeMap[props.mode]}
      </Stack>
    </Dialog>
  );
};

LiveCodingSettingDialog.displayName = "LiveCodingSettingDialog";

export default LiveCodingSettingDialog;
