import { useCurrentLanguage, useTranslation } from "@hireroo/i18n";
import OnlineCollaboration from "@hireroo/ui-assets/images/Remote/OnlineCollaboration.svg";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";

import Link, { LinkProps } from "../../../../primitive/Link/Link";

export type MessageForEmployeeProps = {
  detailPageLink: Pick<LinkProps, "href" | "onClick">;
};

const MessageForEmployee: React.FC<MessageForEmployeeProps> = props => {
  const { t } = useTranslation();
  const lang = useCurrentLanguage();
  const ContentMap: Record<string, React.ReactNode> = {
    ja: (
      <Typography role="name" sx={{ lineHeight: "1.7" }}>
        <Link {...props.detailPageLink}>インタビュー詳細</Link>から開始することができます。
      </Typography>
    ),
    en: (
      <Typography role="name" sx={{ lineHeight: "1.7" }}>
        You can start from the <Link {...props.detailPageLink}>interview details</Link>.
      </Typography>
    ),
  };
  return (
    <Box width="100vw" height="100%" bgcolor="background.default" justifyContent="center" alignItems="center" display="flex">
      <Box textAlign={"center"} mx={10} justifyContent="center" alignItems="center" display="flex" flexDirection="column">
        <img src={OnlineCollaboration} width={600} height={400} alt={t("待合室の画像")} />
        <Box mt={2} ml={2} />
        <Box display="flex" flexDirection="column" mb={1} mx={2}>
          <Typography role="name" gutterBottom>
            {t("まだインタビューが開始されていません。")}
          </Typography>
          {ContentMap[lang]}
        </Box>
      </Box>
    </Box>
  );
};

MessageForEmployee.displayName = "MessageForEmployee";

export default MessageForEmployee;
