import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";

import SortField, { SortFieldProps } from "../../../../modules/SortField/SortField";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px solid",
  borderTop: "1px solid",
  borderColor: theme.palette["Gray/Shades"].p8,
  "&.MuiTableCell-root": {
    height: "40px",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
}));

export type QuestionPackageTableHeaderProps = {
  resultText: string;
  sortField: SortFieldProps;
};

const QuestionPackageTableHeader: React.FC<QuestionPackageTableHeaderProps> = props => {
  return (
    <TableHead component="div" sx={{ py: 0.5 }}>
      <TableRow component="div" key="result-row" sx={{ py: 0 }}>
        <StyledTableCell component="div" colSpan={1} size="small">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography fontSize={14} fontWeight={400}>
              {props.resultText}
            </Typography>
            <SortField {...props.sortField} />
          </Box>
        </StyledTableCell>
      </TableRow>
    </TableHead>
  );
};

QuestionPackageTableHeader.displayName = "QuestionPackageTableHeader";

export default QuestionPackageTableHeader;
