import { useTranslation } from "@hireroo/i18n";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectProps } from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import * as React from "react";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 128,
}));

const StyledSelect = styled(Select)(() => ({
  height: 40,
  paddingTop: 8,
  paddingBottom: 8,
})) as React.FC<SelectProps<number>>;

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&.MuiMenuItem-root": {
    "&:hover ": {
      backgroundColor: theme.palette["Secondary/Shades"].p8,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette["Secondary/Shades"].p16,
    },
  },
}));

/**
 * Space for labels not to be hidden by parent component overflow hidden
 */
const MARGIN_TOP = 5;

export type SelectBoxProps = {
  onChange: SelectProps<number>["onChange"];
  value: number;
  items: { text: string; value: number }[];
};

const SelectBox: React.FC<SelectBoxProps> = props => {
  const { t } = useTranslation();
  return (
    <StyledFormControl variant="outlined" color="secondary" sx={{ top: MARGIN_TOP }}>
      <InputLabel>{t("利用月")}</InputLabel>
      <StyledSelect onChange={props.onChange} value={props.value} color="secondary" label={t("利用月")}>
        {props.items.map((item, index) => (
          <StyledMenuItem key={`${item.value}-${index}`} value={item.value}>
            {item.text}
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </StyledFormControl>
  );
};

SelectBox.displayName = "SelectBox";

export default SelectBox;
