import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type EmployeeMenuContainerProps = {};

const EmployeeMenuContainer: React.FC<EmployeeMenuContainerProps> = () => {
  const props = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.NavigationPopupMenu {...props} />
    </ErrorBoundary>
  );
};

EmployeeMenuContainer.displayName = "EmployeeMenuContainer";

export default withErrorBoundary(EmployeeMenuContainer, {});
