import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import CustomScoreAllocation, { CustomScoreAllocationProps } from "../CustomScoreAllocation/CustomScoreAllocation";
import DetailSelectorDialog, { DetailSelectorDialogProps } from "./parts/DetailSelectorDialog/DetailSelectorDialog";
import ScoreBoardItem, { ScoreBoardItemProps } from "./parts/ScoreBoardItem/ScoreBoardItem";

const Wrapper = styled(Stack)(({ theme }) => ({
  borderRadius: "16px",
  backgroundColor: theme.palette["Background/Paper"].p2,
  padding: "16px",
  width: "100%",
}));

export type EntityScoreSelectorProps = {
  detailDialog?: DetailSelectorDialogProps;
  items: ScoreBoardItemProps[];
  defaultValue: string;
  customScoreAllocation?: CustomScoreAllocationProps;
};

const EntityScoreSelector: React.FC<EntityScoreSelectorProps> = props => {
  const { t } = useTranslation();
  return (
    <Wrapper spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle2" noWrap>
          {t("問題別スコア")}
        </Typography>
        {props.detailDialog && <DetailSelectorDialog {...props.detailDialog} />}
      </Stack>
      <Box>
        <Grid container spacing={1}>
          {props.items.map(item => (
            <Grid key={item.id} item xs={6}>
              <ScoreBoardItem key={item.id} {...item} />
            </Grid>
          ))}
        </Grid>
      </Box>
      {props.customScoreAllocation && <CustomScoreAllocation {...props.customScoreAllocation} />}
    </Wrapper>
  );
};

EntityScoreSelector.displayName = "EntityScoreSelector";

export default EntityScoreSelector;
