import { proxy } from "valtio";
import { proxyMap } from "valtio/utils";

import type * as Types from "./types";

export const state = proxy<Types.State>({
  metricGroupsMap: proxyMap(),
  metricGroupsIds: [],
  dialogStatus: "CLOSE",
  selectedItemIds: [],
  pagination: {
    page: 0,
    size: 20,
    count: 0,
    pager: null,
    nextPager: null,
  },
});
