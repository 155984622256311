import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";

import TablePagination, { TablePaginationProps } from "../../../../modules/TablePagination/TablePagination";
import SonarIntegrationTableRow, { SonarIntegrationTableRowProps } from "../SonarIntegrationTableRow/SonarIntegrationTableRow";

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  "& .MuiTableCell-root": {
    borderColor: theme.palette["Secondary/Shades"].p12,
    padding: theme.spacing(1.5),
  },
}));

const NUM_OF_TABLE_COLUMNS = 4;

export type SonarIntegrationTableProps = {
  items: Omit<SonarIntegrationTableRowProps, "enableScreeningFeature">[];
  pagination: TablePaginationProps;
};

const SonarIntegrationTable: React.FC<SonarIntegrationTableProps> = props => {
  const { t } = useTranslation();

  return (
    <StyledTableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ minWidth: "300px" }}>
              <Typography color="textSecondary" fontSize={12}>
                {t("コーディングテスト名")}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" fontSize={12}>
                {t("トークン")}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography color="textSecondary" fontSize={12}>
                {t("作成日時")}
              </Typography>
            </TableCell>
            <TableCell width="5%" />
          </TableRow>
        </TableHead>
        <TableBody>
          {props.items.map(item => (
            <SonarIntegrationTableRow key={item.sonarIntegrationId} {...item} />
          ))}
          {props.items.length === 0 && (
            <TableRow>
              <TableCell colSpan={NUM_OF_TABLE_COLUMNS}>{t("発行されているトークンは存在しません。")}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Box display="flex" justifyContent="flex-end">
        <TablePagination {...props.pagination} />
      </Box>
    </StyledTableContainer>
  );
};

SonarIntegrationTable.displayName = "SonarIntegrationTable";

export default SonarIntegrationTable;
