import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Auth } from "@hireroo/app-store/essential/employee";
import { TechnicalCommentPopperForExam } from "@hireroo/app-store/widget/e/TechnicalCommentPopperForExam";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import type * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/browser";
import * as React from "react";

import { useFetchMetricGroupFromLocalStorage } from "./useFetchMetricGroup";
import { useFetchMetricGroups } from "./useFetchMetricGroups";

export type GenerateTechnicalCommentPromptPropsArgs = {
  companyId: number;
  examId: string;
  onClose: () => void;
};
type SelectItem = Widget.TechnicalCommentPopperProps["formContent"]["selectItem"]["items"][0];
type EvaluationItem = Widget.TechnicalCommentPopperProps["formContent"]["review"]["evaluationItems"][0];

export const useGenerateProps = (args: GenerateTechnicalCommentPromptPropsArgs): Widget.TechnicalCommentPopperProps => {
  const currentUid = Auth.useCurrentUid();
  const { t } = useTranslation();
  const client = getGraphqlClient();
  const formValues = TechnicalCommentPopperForExam.useFormValues();
  const [reviewStatus, setReviewStatus] = React.useState<"LOADING" | "READY">("READY");
  const pagination = TechnicalCommentPopperForExam.useMetricGroupPagination();
  const metricGroups = TechnicalCommentPopperForExam.useMetricGroups();
  const isValidFormValues = TechnicalCommentPopperForExam.useIsValidFormValues();

  const cacheKey = args.examId;

  //TODO: @himenon
  useFetchMetricGroupFromLocalStorage({ cacheKey });

  const { isLoading } = useFetchMetricGroups({ companyId: args.companyId });

  const metricMap = React.useMemo((): Map<number, Graphql.EvaluationMetric> => {
    const metricsMap = new Map();
    if (formValues.metricGroupId === null) return metricsMap;
    const metricGroup = metricGroups?.find(m => m.metricGroupId === formValues.metricGroupId);
    metricGroup?.metrics.forEach(m => {
      metricsMap.set(m.metricId, m);
    });
    return metricsMap;
  }, [formValues.metricGroupId, metricGroups]);

  const metricIds = React.useMemo<number[]>(() => {
    return Array.from(metricMap.keys());
  }, [metricMap]);

  const metricGroupItems = (metricGroups || []).map((metricGroup): SelectItem => {
    return {
      id: metricGroup.id.toString(),
      selected: metricGroup.metricGroupId === formValues.metricGroupId,
      onClick: () => {
        TechnicalCommentPopperForExam.initFormValuesMetricGroup(
          metricGroup.metricGroupId,
          metricGroup.metrics.map(m => m.metricId),
        );
      },
      title: metricGroup.title,
      description: metricGroup.description,
      kind: "SINGLE_CHOICE",
    };
  });

  const moveBackward = React.useCallback(() => {
    TechnicalCommentPopperForExam.decrementStep();
    // Save the data in local storage to persist
    localStorage.setItem(cacheKey, JSON.stringify(formValues));
  }, [cacheKey, formValues]);

  const moveForward = React.useCallback(() => {
    TechnicalCommentPopperForExam.incrementStep();
    // Save the data in local storage to persist
    localStorage.setItem(cacheKey, JSON.stringify(formValues));
  }, [cacheKey, formValues]);

  const evaluationItems = React.useMemo((): EvaluationItem[] => {
    return metricIds.map((metricId, index): EvaluationItem => {
      const currentMetric = metricMap.get(metricId);
      const evaluation = index < formValues.evaluations.length ? formValues.evaluations[index] : undefined;
      return {
        id: metricId.toString(),
        index: index,
        title: currentMetric?.title ?? "",
        description: currentMetric?.description ?? "",
        commentField: {
          onChange: event => {
            if (evaluation) {
              TechnicalCommentPopperForExam.updateFormValuesEvaluation({
                comment: event.target.value,
                metricId: evaluation.metricId,
                numStars: evaluation.numStars,
              });
            }
          },
          defaultValue: evaluation?.comment ?? "",
        },
        ratingField: {
          value: evaluation?.numStars ?? 0,
          onChange: (_, value) => {
            if (typeof value === "number" && evaluation) {
              TechnicalCommentPopperForExam.updateFormValuesEvaluation({
                comment: evaluation.comment,
                metricId: evaluation.metricId,
                numStars: value,
              });
            }
          },
        },
      };
    });
  }, [formValues.evaluations, metricIds, metricMap]);

  const updateMetricGroupPaginationCallback = React.useCallback(() => {
    if (pagination.nextCursor) {
      TechnicalCommentPopperForExam.updateMetricGroupPagination({
        cursor: pagination.nextCursor,
        nextCursor: null,
      });
    }
  }, [pagination]);

  const step = React.useMemo((): Widget.TechnicalCommentPopperProps["formContent"]["step"] => {
    if (formValues.step === 0) return "INSTRUCTION";
    if (formValues.step === 1) return "SELECT_ITEM";
    if (formValues.step === 2) return "REVIEW";

    return "INSTRUCTION";
  }, [formValues.step]);

  return {
    formContent: {
      step: step,
      selectItem: {
        title: t("評価指標の選択"),
        description: t("まずは評価指標を選びましょう。選択された評価指標を元に技術レビューを続けます。"),
        itemTitle: t("指標名"),
        pager: {
          rootKey: "metricGroup-item-header",
          moreKey: "metricGroupLoadMore",
        },
        items: metricGroupItems,
        hasNext: pagination.hasNext,
        intersectCallback: updateMetricGroupPaginationCallback,
        status: isLoading ? "LOADING" : "NONE",
        nextButton: {
          onClick: () => {
            moveForward();
          },
          disabled: formValues.metricGroupId === null,
        },
        backButton: {
          onClick: () => {
            moveBackward();
          },
        },
      },
      review: {
        commentTextField: {
          defaultValue: formValues.comment,
          onChange: event => {
            TechnicalCommentPopperForExam.updatePartialFormValues({
              comment: event.target.value,
            });
          },
        },
        evaluationItems: evaluationItems,
        confirmButton: {
          onClick: () => {
            setReviewStatus("LOADING");
            client
              .ReviewExamForTechnicalComment({
                input: {
                  examId: args.examId,
                  companyId: args.companyId,
                  isRecommended: true,
                  comment: formValues.comment,
                  evaluations: [...formValues.evaluations],
                  employeeId: currentUid,
                },
              })
              .then(res => {
                Snackbar.notify({
                  severity: "success",
                  message: t("テストの技術レビューが完了しました。"),
                });
                TechnicalCommentPopperForExam.clearFormValues();
                TechnicalCommentPopperForExam.updateReviewExam(res.reviewExam);
                localStorage.removeItem(cacheKey);
                args.onClose();
              })
              .catch(error => {
                Sentry.captureException(error);
                const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                  error,
                  t("テストの技術レビューに失敗しました。しばらくしてから再度お試しください。"),
                );
                Snackbar.notify({
                  severity: "error",
                  message: errorNotification.message,
                });
              })
              .finally(() => {
                setReviewStatus("READY");
              });
          },
          disabled: reviewStatus === "LOADING" || !isValidFormValues,
        },
        backButton: {
          disabled: reviewStatus === "LOADING",
          onClick: () => {
            moveBackward();
          },
        },
      },
      instruction: {
        message: t("受験者の技術力をレビューします。入力した内容は対象のタレントにもそのまま表示されます。"),
        startButton: {
          onClick: () => {
            moveForward();
          },
        },
      },
      isLoading: isLoading,
    },
    onClose: () => {
      // Save the data in local storage to persist
      localStorage.setItem(cacheKey, JSON.stringify(formValues));
      args.onClose();
    },
  };
};
