import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type SecuritySettingsContainerProps = {};

const SecuritySettingsContainer: React.FC<SecuritySettingsContainerProps> = args => {
  const props = useGenerateProps(args);
  return (
    <ErrorBoundary>
      <Widget.SecuritySettings {...props} />
    </ErrorBoundary>
  );
};

SecuritySettingsContainer.displayName = "SecuritySettingsContainer";

export default withErrorBoundary(SecuritySettingsContainer, {});
