import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import ActivityItem, { ActivityItemProps } from "./parts/ActivityItem/ActivityItem";

const StyledPaper = styled(Paper)(() => ({
  height: "100%",
  width: "100%",
  boxShadow: "none",
  display: "flex",
  flexDirection: "column",
}));

const StyledStack = styled(Stack)(() => ({
  height: "100%",
  overflowY: "auto",
  paddingBottom: "60vh",
}));

export type ActivityTimelineProps = {
  items: ActivityItemProps[];
};

const ActivityTimeline: React.FC<ActivityTimelineProps> = props => {
  const { t } = useTranslation();
  const processing = React.useRef(false);
  const wrapperRef = React.createRef<HTMLDivElement>();
  const handleHighlight = React.useCallback(
    (element: HTMLButtonElement) => {
      if (wrapperRef.current && !processing.current) {
        const top = element.getBoundingClientRect().top - wrapperRef.current.getBoundingClientRect().top;
        wrapperRef.current.scrollBy({
          behavior: "smooth",
          top: top,
        });
      }
    },
    [wrapperRef],
  );

  React.useEffect(() => {
    if (!wrapperRef.current) {
      return;
    }
    const handleScroll = () => {
      processing.current = true;
    };
    const handleScrollEnd = () => {
      processing.current = false;
    };
    wrapperRef.current.addEventListener("scroll", handleScroll);
    wrapperRef.current.addEventListener("scrollend", handleScrollEnd);
    const clear = () => {
      wrapperRef.current?.removeEventListener("scroll", handleScroll);
      wrapperRef.current?.removeEventListener("scrollend", handleScrollEnd);
    };
    return () => {
      clear();
    };
  }, [wrapperRef]);

  return (
    <StyledPaper>
      <Box px={2} py={1.5}>
        <Typography fontSize={14}>{t("活動履歴")}</Typography>
      </Box>
      <StyledStack direction="column" py={1.5} ref={wrapperRef}>
        {props.items.map((item, index) => {
          return <ActivityItem key={`activity-${index}`} {...item} onHighlight={handleHighlight} />;
        })}
      </StyledStack>
    </StyledPaper>
  );
};

ActivityTimeline.displayName = "ActivityTimeline";

export default ActivityTimeline;
