import { useTitle } from "@hireroo/app-helper/react-use";
import { Auth } from "@hireroo/app-store/essential/talent";
import { InvitationsIdStore } from "@hireroo/app-store/page/t/invitations_id";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Pages } from "@hireroo/presentation";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/browser";
import * as React from "react";

import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import { acceptInvitation } from "./privateHelper";

export type GenerateInvitationPropsArgs = {
  invitationId: string;
};

export const useGenerateProps = (args: GenerateInvitationPropsArgs): Pages.InvitationProps => {
  const navigate = useTransitionNavigate();
  const uid = Auth.useCurrentUid();
  const company = InvitationsIdStore.useStrictCompany();
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const [status, setStatus] = React.useState<"PENDING" | "READY">("READY");

  useTitle(t("招待"));

  return {
    layout: {
      Snackbar: <SnackbarContainer />,
    },
    dialog: {
      title: t2("AcceptInvitation", {
        companyName: company.name,
      }),
      role: t("タレント"),
      confirmButton: {
        disabled: status === "PENDING",
        onClick: async () => {
          setStatus("PENDING");
          await acceptInvitation({
            uid,
            companyId: company.companyId,
            invitationId: args.invitationId,
          }).catch(error => {
            Sentry.captureException(error);
            Snackbar.notify({
              severity: "error",
              message: t("招待の承諾に失敗しました。しばらくしてから再度お試しください。"),
            });
          });
          setStatus("READY");
        },
      },
      rejectButton: {
        disabled: status === "PENDING",
        onClick: async () => {
          await navigate("/");
        },
      },
    },
  };
};
