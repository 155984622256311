import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type AssessmentDetailContainerProps = {
  assessmentId: string;
};

const AssessmentsDetailContainer: React.FC<AssessmentDetailContainerProps> = props => {
  const assessmentDetailForEmployeeProps = useGenerateProps({ assessmentId: props.assessmentId });
  return (
    <ErrorBoundary>
      <Pages.AssessmentDetailForEmployee {...assessmentDetailForEmployeeProps} />
    </ErrorBoundary>
  );
};

AssessmentsDetailContainer.displayName = "AssessmentsDetailContainer";

export default withErrorBoundary(AssessmentsDetailContainer, {});
