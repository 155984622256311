import { Widget } from "@hireroo/presentation/legacy";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type AlgorithmTemplatePreviewFetchContainerArgs = {
  variant: "ALGORITHM" | "DATABASE" | "CLASS";
  signature: string;
};
const AlgorithmTemplatePreviewFetchContainer: React.FC<AlgorithmTemplatePreviewFetchContainerArgs> = args => {
  const props = useGenerateProps(args);
  return (
    <ErrorBoundary>
      <Widget.AlgorithmTemplatePreview {...props} />
    </ErrorBoundary>
  );
};

AlgorithmTemplatePreviewFetchContainer.displayName = "AlgorithmTemplatePreviewFetchContainer";

export default withErrorBoundary(AlgorithmTemplatePreviewFetchContainer, {});
