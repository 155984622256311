import { useTranslation } from "@hireroo/i18n";
import { markdownToText } from "@hireroo/markdown";
import Markdown from "@hireroo/markdown/react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import * as React from "react";

const StyledFormControlLabel = styled(FormControlLabel)({
  "& > span:nth-of-type(2)": {
    width: "100%",
  },
  marginTop: "10px",
});

export type MultiChoiceItem = {
  title: string;
  description?: string;
  checked: boolean;
};

export type ReadonlyQuizQuestionMultiChoiceProps = {
  id: string;
  description: string;
  items: MultiChoiceItem[];
  skipped: boolean;
};

const ReadonlyQuizQuestionMultiChoice: React.FC<ReadonlyQuizQuestionMultiChoiceProps> = props => {
  const { t } = useTranslation();
  return (
    <Box>
      <React.Suspense>
        <Markdown size="middle" children={props.description} />
      </React.Suspense>

      <FormGroup aria-label="options">
        {props.items.map((option, index) => (
          <Box key={`options-${index}-${option.title}`}>
            <StyledFormControlLabel
              // Radio form passed "string" so value is "string"
              control={<Checkbox color="primary" checked={option.checked} />}
              label={
                <React.Suspense>
                  <Markdown size="small" children={option.title} />
                </React.Suspense>
              }
            />
            <Box my={1}>
              <FormHelperText sx={{ color: "text.secondary", marginLeft: "1em" }}>{markdownToText(option.description || "")}</FormHelperText>
            </Box>
          </Box>
        ))}

        <FormControlLabel
          value="0"
          control={<Checkbox color="primary" checked={props.skipped} />}
          label={t("この問題をスキップ")}
          sx={{ mt: "10px" }}
        />
      </FormGroup>
    </Box>
  );
};

ReadonlyQuizQuestionMultiChoice.displayName = "ReadonlyQuizQuestionMultiChoice";

export default ReadonlyQuizQuestionMultiChoice;
