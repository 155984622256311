import { useTranslation } from "@hireroo/i18n";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";

const StyledListItemText = styled(ListItemText)(() => ({
  margin: 0,
}));

type Group = {
  id: string;
  displayName: string;
  src?: string;
};
export type DeleteGroupsDialogProps = {
  dialog: Pick<DialogWithCloseIconProps, "open" | "onClose" | "yesButton" | "noButton">;
  groups: Group[];
  onSubmit?: () => void;
};

const DeleteGroupsDialog: React.FC<DeleteGroupsDialogProps> = props => {
  const { t } = useTranslation();

  const dialog: DialogWithCloseIconProps = {
    ...props.dialog,
    title: t("グループの削除"),
    yesButton: {
      children: t("削除"),
      color: "error",
      onClick: () => {
        props.onSubmit?.();
        props.dialog.yesButton?.onClick?.();
      },
      autoFocus: false,
    },
    noButton: {
      ...props.dialog.noButton,
      children: t("キャンセル"),
      variant: "outlined",
      color: "outline-only",
    },
    optionalDialog: {
      disableAutoFocus: true,
    },
    disableEnter: true,
  };
  return (
    <DialogWithCloseIcon {...dialog}>
      <Stack>
        <Typography fontSize={14}>{t("以下のグループを削除します。削除されたグループは復元できません。")}</Typography>
        <List>
          {props.groups.map(member => (
            <ListItem key={member.id} divider>
              <ListItemAvatar>
                <Avatar src={member.src} sx={{ height: "32px", width: "32px" }} />
              </ListItemAvatar>
              <StyledListItemText
                primary={member.displayName}
                primaryTypographyProps={{ mb: "4px" }}
                secondaryTypographyProps={{ fontSize: 12 }}
              />
            </ListItem>
          ))}
        </List>
      </Stack>
    </DialogWithCloseIcon>
  );
};

DeleteGroupsDialog.displayName = "DeleteGroupsDialog";

export default DeleteGroupsDialog;
