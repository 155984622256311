import MUILink, { LinkProps as MUILinkProps } from "@mui/material/Link";
import * as React from "react";

const useLinkProps = (props: MUILinkProps): Pick<MUILinkProps, "variant" | "lineHeight"> => {
  const newProps: MUILinkProps = {
    ...props,
    lineHeight: 1.5,
  };

  switch (props.variant) {
    case "body2":
      newProps.variant = "body2";
      newProps.lineHeight = 1.45;
      break;
    default:
      newProps.variant = "body1";
      newProps.lineHeight = 1.5;
  }

  return newProps;
};

export type LinkProps = MUILinkProps & {
  children: React.ReactNode[];
};

const Link: React.FC<LinkProps> = props => {
  const { children } = props;
  const linkProps = useLinkProps(props);

  return (
    <MUILink {...linkProps} href={props.href} target="_blank" rel="noreferrer" underline="hover" sx={{ cursor: "pointer" }}>
      {children}
    </MUILink>
  );
};

Link.displayName = "Link";

export default Link;
