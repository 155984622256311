import * as z from "zod";

export const useSelectableEvaluationItem = () => {
  return z.object({
    metricId: z.number(),
    selected: z.boolean(),
  });
};

export const useTechnicalCommentEvaluationListForm = () => {
  const selectableEvaluationItem = useSelectableEvaluationItem();
  return z.object({
    items: selectableEvaluationItem.array(),
  });
};

export type TechnicalCommentEvaluationListFormSchema = z.infer<ReturnType<typeof useTechnicalCommentEvaluationListForm>>;
