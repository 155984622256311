import { useTranslation } from "@hireroo/i18n";
import { PaymentForm } from "@hireroo/validator";
import CheckIcon from "@mui/icons-material/Check";
import Box, { BoxProps } from "@mui/material/Box";
import Button, { type ButtonProps } from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useController } from "react-hook-form";

import InputControlTextField, { InputControlTextFieldProps } from "../../../primitive/InputControl/InputControlTextField/InputControlTextField";

const HEIGHT = 40;

const StyledTextField = styled(InputControlTextField)(({ theme }) => ({
  height: HEIGHT,
  fontSize: 16,
  marginRight: theme.spacing(1.5),
}));

const ApplyButton = styled(Button)(() => ({
  height: HEIGHT,
}));

const ApplyCouponResultArea = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette["Success/Shades"].p4,
  border: `1px solid ${theme.palette.success.light}`,
  borderRadius: 8,
  overflow: "hidden",
}));

const ContentWrapper = styled(Box)(() => ({
  height: HEIGHT,
}));

type ApplyResultItems = {
  text: string;
};

export type CouponFieldProps = {
  name: string;
  applyResults: ApplyResultItems[];
  onApplyCoupon?: (coupon: PaymentForm.Coupon) => void;
  /**
   * @default true
   */
  showCouponInputField?: boolean;
  disabled?: boolean;
} & Pick<BoxProps, "mb">;

const CouponField: React.FC<CouponFieldProps> = props => {
  const { showCouponInputField = true } = props;
  const { t } = useTranslation();
  const methods = useController<Record<string, PaymentForm.Coupon>>({
    name: props.name,
  });
  const theme = useTheme();
  const hasApplyCouponResults = props.applyResults.length > 0;
  const disabled = props.disabled || hasApplyCouponResults;
  const inputControlTextFieldProps: InputControlTextFieldProps = {
    name: props.name,
    size: "small",
    placeholder: t("クーポンコード"),
    disabled: disabled,
  };
  const alreadyAppliedCouponHelperText = hasApplyCouponResults ? t("すでに適用中のクーポンがあるため、これ以上追加できません。") : "";
  const applyButtonProps: ButtonProps = {
    children: t("適用する"),
    variant: "contained",
    disabled: methods.field.value === "" || disabled,
    onClick: () => {
      props.onApplyCoupon?.(methods.field.value);
      methods.field.onChange("");
    },
  };
  return (
    <Box mb={props.mb}>
      {showCouponInputField && (
        <ContentWrapper>
          <StyledTextField {...inputControlTextFieldProps} name={props.name} />
          <ApplyButton {...applyButtonProps} />
        </ContentWrapper>
      )}
      {showCouponInputField && alreadyAppliedCouponHelperText && (
        <Box mb={2}>
          <FormHelperText>{alreadyAppliedCouponHelperText}</FormHelperText>
        </Box>
      )}
      {props.applyResults.length > 0 && (
        <ApplyCouponResultArea mt={2}>
          <Typography color={theme.palette.success.main} fontWeight="bold" mb={1.5}>
            {t("適用中のクーポン")}
          </Typography>
          <Stack spacing={1.5}>
            {props.applyResults.map((result, index) => {
              return (
                <Box display="flex" flexDirection="row" key={`result-${index}`}>
                  <CheckIcon color="success" />
                  <Typography ml={1} color={theme.palette.success.main}>
                    {result.text}
                  </Typography>
                </Box>
              );
            })}
          </Stack>
        </ApplyCouponResultArea>
      )}
    </Box>
  );
};

CouponField.displayName = "CouponField";

export default CouponField;
