import CloseIcon from "@mui/icons-material/Close";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import * as React from "react";

const StyledBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 12px;
  padding-right: 8px;
`;

export type TabLabelProps = {
  name: string;
  onClose?: () => void;
};

const TabLabel: React.FC<TabLabelProps> = props => {
  return (
    <StyledBox>
      <Box flexDirection={"row"} justifyContent={"center"} alignItems={"center"} display={"flex"}>
        <InsertDriveFileOutlinedIcon sx={{ fontSize: "16px", marginRight: "8px" }} />
        {props.name}
      </Box>
      <Box
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        alignSelf={"center"}
        display={"flex"}
        width={25}
        height={"100%"}
        ml={1}
      >
        <IconButton
          component="span"
          size={"small"}
          onClick={(e: React.MouseEvent) => {
            props.onClose?.();
            e.stopPropagation();
          }}
        >
          <CloseIcon fontSize={"small"} />
        </IconButton>
      </Box>
    </StyledBox>
  );
};

TabLabel.displayName = "TabLabel";

export default TabLabel;
