import { styled } from "@mui/material/styles";
import * as React from "react";

import EmployeeLayout, { EmployeeLayoutProps } from "../../layouts/EmployeeLayout/EmployeeLayout";

const StyledEmployeeLayout = styled(EmployeeLayout)`
  overflow: hidden;
`;

export type ScreeningTestDetailProps = {
  layout: EmployeeLayoutProps;
  children: React.ReactNode;
};

const ScreeningTestDetail: React.FC<ScreeningTestDetailProps> = props => {
  return <StyledEmployeeLayout {...props.layout}>{props.children}</StyledEmployeeLayout>;
};

ScreeningTestDetail.displayName = "ScreeningTestDetail";

export default ScreeningTestDetail;
