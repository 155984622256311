import { styled } from "@mui/material/styles";
import Tabs, { TabsProps } from "@mui/material/Tabs";
import * as React from "react";

export type { TabsProps };

const StyledTabs: React.FC<TabsProps> = styled(Tabs)(({ theme }) => ({
  "&.MuiTabs-root": {
    height: 36,
    minHeight: 36,
  },
  ".MuiTab-root": {
    height: 36,
    minHeight: 36,
    padding: "8px 12px",
  },
  ".MuiTabs-indicator": { backgroundColor: theme.palette.secondary.main },
  backgroundColor: theme.palette["Secondary/Shades"].p16,
}));

StyledTabs.displayName = "StyledTabs";

export default StyledTabs;
