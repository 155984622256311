type Promotion = {
  content: string;
  title: string;
  url: string;
  visibleUrl: string;
};

export type SearchResult = {
  contentNoFormatting: string;
  titleNoFormatting: string;
  url: string;
  visibleUrl: string;
};

type ReadyCallback = (name: string, query: string, promos: Promotion[], results: SearchResult[], resultsDiv: HTMLDivElement[]) => void;
type RenderedCallback = (name: string, query: string, promos: HTMLElement[], results: HTMLElement[]) => void;

type Page = number | null;

type SearchWebHandlerArgs = {
  query: string;
  page: Page;
};

type ClickWebSearchResultHandlerArgs = {
  url: string;
  title: string;
  description: string;
  searchEventId: string;
};

export type SearchWebHandler = (args: SearchWebHandlerArgs) => Promise<string | void>;
export type ClickWebSearchResultHandler = (args: ClickWebSearchResultHandlerArgs) => void;

type MakeSearchCallbacksArgs = {
  onSearchWeb: SearchWebHandler;
  onClickWebSearchResult: ClickWebSearchResultHandler;
};

declare global {
  interface Window {
    __gcse?: {
      searchCallbacks: {
        web: {
          ready: ReadyCallback;
          rendered: RenderedCallback;
        };
      };
    };
  }
}

const GOOGLE_SEARCH_QUERY_NAME = "gsc.q";
const GOOGLE_SEARCH_PAGE_NAME = "gsc.page";
const GOOGLE_DEFAULT_HASH_PARAM = "gsc.tab=0";

export const generateHashParam = (query: string | null, page: number | null) => {
  if (query) {
    return `${GOOGLE_DEFAULT_HASH_PARAM}&${GOOGLE_SEARCH_QUERY_NAME}=${query}&${GOOGLE_SEARCH_PAGE_NAME}=${page ?? ""}`;
  }

  return GOOGLE_DEFAULT_HASH_PARAM;
};

const removeEventListeners = (element: Node) => {
  const clone = element.cloneNode(true);
  element.parentNode?.replaceChild(clone, element);
  return clone;
};

export const extractUrlFromSearchResult = (result: SearchResult): string => {
  const params = new URLSearchParams(result["url"].split("?").at(1));

  // If query parameter isn't given, use result["visibleUrl"] instead.
  return params.get("q") ?? result["visibleUrl"];
};

export const convertStringToPage = (value: string): Page => {
  const newValue = Number(value);
  if (isNaN(newValue) || !Number.isSafeInteger(newValue) || newValue <= 0) {
    return null;
  }

  return newValue;
};

export const getCurrentPageNumber = (): Page => {
  // substring(1) removes "#"
  const hash = window.location.hash.substring(1);
  const params = new URLSearchParams(hash);
  const value = params.get(GOOGLE_SEARCH_PAGE_NAME);

  return value ? convertStringToPage(value) : null;
};

const SEARCH_IN_GOOGLE_CLASS_NAME = "gcsc-more-maybe-branding-root";

const removeSearchInGoogleElement = () => {
  const searchInGoogleElement = document.getElementsByClassName(SEARCH_IN_GOOGLE_CLASS_NAME);
  searchInGoogleElement.item(0)?.remove();
};

export const makeSearchCallbacks = (args: MakeSearchCallbacksArgs): Exclude<Window["__gcse"], undefined>["searchCallbacks"] => {
  const { onSearchWeb, onClickWebSearchResult } = args;
  let urls: string[], titles: string[], descriptions: string[];
  const readyCallback: ReadyCallback = (_name, query, _promos, results, _resultsDiv) => {
    [urls, titles, descriptions] = [[], [], []];
    for (const result of results) {
      urls.push(extractUrlFromSearchResult(result));
      titles.push(result["titleNoFormatting"]);
      descriptions.push(result["contentNoFormatting"]);
    }
  };

  const renderedCallback: RenderedCallback = (_name, query, _promos, results) => {
    let searchEventId: string;
    onSearchWeb({ query, page: getCurrentPageNumber() }).then(result => {
      if (result) {
        searchEventId = result;
      }
    });

    // add event lister to web search results
    // to store web accessed result and open web page in new tab
    results.forEach((result, i) => {
      const anchor = result.querySelector("a");
      if (anchor) {
        const clonedAnchor = removeEventListeners(anchor);
        clonedAnchor.addEventListener("click", e => {
          e.preventDefault();
          onClickWebSearchResult({
            url: urls[i],
            title: titles[i],
            description: descriptions[i],
            searchEventId: searchEventId,
          });
          window.open(urls[i], "_blank");
        });
      }
    });

    // remove element to prevent to open Google in new window.
    removeSearchInGoogleElement();
  };

  return {
    web: {
      ready: readyCallback,
      rendered: renderedCallback,
    },
  };
};
