import { state } from "./State";
import type { Status } from "./types";

export const updateStatus = (status: Status) => {
  state.status = status;
};

export const updateMailAddress = (mailAddress: string) => {
  state.mailAddress = mailAddress;
};

export const reset = () => {
  state.mailAddress = "";
  state.status = "BEFORE_RESET_PASSWORD";
};
