import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import * as React from "react";

import FeatureTableCell, { FeatureTableCellProps } from "./parts/FeatureTableCell/FeatureTableCell";

/**
 * TODO Color
 */
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "& td:nth-of-type(2)": {
    backgroundColor: "#4B51C10D",
  },
  "& td:nth-of-type(3)": {
    backgroundColor: "#4B51C11F",
  },
  "& td:nth-of-type(4)": {
    backgroundColor: "#4B51C12E",
  },
  "& th, & td": {
    paddingTop: 8,
    paddingBottom: 8,
    borderRight: `4px solid ${theme.palette.mode === "light" ? theme.palette.common.white : theme.palette.background.default}`,
    borderBottom: "none",
  },
  "& th:last-child, & td:last-child": {
    borderRight: "none",
  },
  "& tr:last-of-type th": {
    paddingBottom: 16,
  },
}));

export type FeatureTableRowProps = {
  rowName: string;
  items: FeatureTableCellProps[];
};

const FeatureTableRow: React.FC<FeatureTableRowProps> = props => {
  const widthRatio = 100 / (props.items.length + 1);
  return (
    <StyledTableRow>
      <TableCell component="th" scope="row">
        {props.rowName}
      </TableCell>
      {props.items.map((item, index) => (
        <FeatureTableCell key={`item-${index}`} width={`${widthRatio}%`} {...item} />
      ))}
    </StyledTableRow>
  );
};

FeatureTableRow.displayName = "FeatureTableRow";

export default FeatureTableRow;
