import { subscribeKey } from "valtio/utils";

import { state } from "./State";
import * as Types from "./types";

export type SubscribeActiveSessionsCallback = (activeSessions: Types.Session[]) => void;

export const subscribeActiveSessionMap = (callback: SubscribeActiveSessionsCallback) => {
  return subscribeKey(state, "activeSessionMap", activeSessionMap => {
    callback(Array.from(activeSessionMap.values()));
  });
};
