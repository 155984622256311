import { state } from "./State";
import type * as Types from "./types";

export const clearFormValues = () => {
  state.formValues = {
    step: 0,
    metricGroupId: null,
    comment: "",
    evaluations: [],
  };
};
export const updatePartialFormValues = (newValue: Partial<Types.FormValues>) => {
  state.formValues = {
    ...state.formValues,
    ...newValue,
  };
};

export const initFormValuesMetricGroup = (metricGroupId: number, metricIds: number[]): void => {
  state.formValues.metricGroupId = metricGroupId;
  state.formValues.evaluations = metricIds.map(metricId => {
    return {
      metricId: metricId,
      comment: "",
      numStars: 0,
    };
  });
};

export const updateFormValuesEvaluation = (newValue: Types.EvaluationInput) => {
  state.formValues.evaluations = state.formValues.evaluations.map(e => {
    if (newValue.metricId === e.metricId) {
      return newValue;
    }
    return e;
  });
};

export const incrementStep = () => {
  state.formValues.step += 1;
};

export const decrementStep = () => {
  if (state.formValues.step <= 1) {
    state.formValues.step = 0;
    return;
  }
  state.formValues.step -= 1;
};

export const addMetricGroups = (newMetricGroups: Types.MetricGroup[]) => {
  newMetricGroups.forEach(metricGroup => {
    state.metricGroups.set(metricGroup.metricGroupId, metricGroup);
  });
};

export const updateMetricGroupPagination = (newValue: Partial<Types.MetricGroupPagination>) => {
  state.metricGroupPagination = {
    ...state.metricGroupPagination,
    ...newValue,
  };
};

export const updateReviewExam = (newValue: Types.ReviewedExam) => {
  state.reviewedExam = newValue;
};

export const updateLoadingStatus = (newValue: Types.LoadingStatus) => {
  state.loadingStatus = newValue;
};
