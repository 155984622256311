import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";
import * as React from "react";

import BaseDialog, { BaseDialogProps, NoButtonProps, YesButtonProps } from "../../../../primitive/BaseDialog/BaseDialog";

export type RemoveEvaluationDialogProps = {
  removeTarget: string;
  open: boolean;
  yesButton: Omit<YesButtonProps, "children">;
  noButton: Omit<NoButtonProps, "children">;
};

const RemoveEvaluationDialog: React.FC<RemoveEvaluationDialogProps> = props => {
  const { t } = useTranslation();
  const baseDialog: BaseDialogProps = {
    title: t("評価項目を削除します"),
    open: props.open,
    yesButton: {
      ...props.yesButton,
      children: t("はい"),
    },
    noButton: {
      ...props.noButton,
      children: t("いいえ"),
    },
  };
  return (
    <BaseDialog {...baseDialog}>
      <Box mb={2} display="grid">
        <DialogContent>
          <DialogContentText>{t("以下の評価項目を削除します。この操作は取り消すことができません。")}</DialogContentText>
          <Box mt={1} width="100%">
            <Typography variant="body2">
              <li> {props.removeTarget}</li>
            </Typography>
          </Box>
        </DialogContent>
      </Box>
    </BaseDialog>
  );
};

RemoveEvaluationDialog.displayName = "RemoveEvaluationDialog";

export default RemoveEvaluationDialog;
