import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type QuizPlaybackEditorContainerProps = {
  quizId: number;
  packageId: number;
  questionIds: number[];
};

const QuizPlaybackEditorContainer: React.FC<QuizPlaybackEditorContainerProps> = props => {
  const quizPlaybackProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.QuizPlaybackEditor {...quizPlaybackProps} />
    </ErrorBoundary>
  );
};

QuizPlaybackEditorContainer.displayName = "QuizPlaybackEditorContainer";

export default withErrorBoundary(QuizPlaybackEditorContainer, {});
