import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import EmployeeLayout, { EmployeeLayoutProps } from "../../layouts/EmployeeLayout/EmployeeLayout";
import ButtonWithTooltip, { ButtonWithTooltipProps } from "../../primitive/Button/ButtonWithTooltip/ButtonWithTooltip";
import CreateDialog, { CreateDialogProps } from "./parts/CreateDialog/CreateDialog";

const StyledButton = styled(Button)(() => ({
  height: 36,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  flexShrink: 0,
  textTransform: "none",
}));

const StyledButtonWithTooltip = styled(ButtonWithTooltip)(() => ({
  height: 36,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  flexShrink: 0,
  textTransform: "none",
}));

const StyledHeaderStack = styled(Stack)(() => ({
  alignItems: "center",
  justifyContent: "space-between",
}));

export type QuestionListProps = {
  layout: EmployeeLayoutProps;
  goQuestionPackageButton: Pick<ButtonProps, "onClick">;
  createButton: Pick<ButtonWithTooltipProps, "title" | "disabled">;
  createDialog: Pick<CreateDialogProps, "items">;
  SearchArea: React.ReactNode;
  QuestionTable: React.ReactNode;
};

const QuestionList: React.FC<QuestionListProps> = props => {
  const { t } = useTranslation();
  const layoutProps: EmployeeLayoutProps = {
    ...props.layout,
  };
  const [open, setOpen] = React.useState(false);
  const createButtonProps: ButtonWithTooltipProps = {
    ...props.createButton,
    title: props.createButton.disabled ? props.createButton.title : "",
    startIcon: "ADD",
    onClick: () => {
      setOpen(true);
    },
    color: "primary",
    variant: "outlined",
  };
  const createDialogProps: CreateDialogProps = {
    ...props.createDialog,
    dialog: {
      open,
      onClose: () => {
        setOpen(false);
      },
    },
  };

  const goQuestionPackageButton: ButtonProps = {
    ...props.goQuestionPackageButton,
    variant: "outlined",
    color: "secondary",
    children: t("問題集一覧"),
  };

  return (
    <EmployeeLayout {...layoutProps}>
      <Box p={3}>
        <StyledHeaderStack direction="row" alignItems="center" justifyContent="space-between" mb={2} spacing={2}>
          <Stack direction="row" justifyContent="space-between">
            <Stack spacing={1.5}>
              <Typography variant="h5" fontWeight={700} noWrap>
                {t("問題一覧")}
              </Typography>
              <Typography fontSize={14} color="textSecondary">
                {t(
                  "問題はコーディング形式、実践形式、クイズ形式、システムデザイン形式、ライブコーディング形式の中から選んで出題することが可能です。そのうち、コーディングテストで出題可能な問題はライブコーディング形式を除く4つの問題形式から出題することが可能です。お使いのプランによってはご利用いただけない問題形式もあります。",
                )}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={2}>
            <StyledButtonWithTooltip {...createButtonProps}>{t("問題作成")}</StyledButtonWithTooltip>
            <StyledButton {...goQuestionPackageButton} />
          </Stack>

          <CreateDialog {...createDialogProps} />
        </StyledHeaderStack>

        <Box>
          <Stack spacing={2}>
            {props.SearchArea}
            {props.QuestionTable}
          </Stack>
        </Box>
      </Box>
    </EmployeeLayout>
  );
};

QuestionList.displayName = "QuestionList";

export default QuestionList;
