import { FeedbackLinkFactory } from "@hireroo/app-helper/question";
import { Credential } from "@hireroo/app-store/essential/shared";
import { SystemDesignTestReport } from "@hireroo/app-store/view-domain/SystemDesignTestReport";
import { formateRateToIntegerPercent } from "@hireroo/formatter/rate";
import { secondsToTimeObject } from "@hireroo/formatter/time";
import { useLanguageCode, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import { Widget } from "@hireroo/presentation";
import { generateCurrentOriginUrl, generatePath } from "@hireroo/router/api";

type Answer = Widget.SystemDesignTestReportProps["questionDetail"]["answers"][0];

export type GenerateQuestionDetailProps = {
  entityId: number;
  showAnswer: boolean;
  showArchivedMark: boolean;
};

export const useGenerateQuestionDetailProps = (args: GenerateQuestionDetailProps): Widget.SystemDesignTestReportProps["questionDetail"] => {
  const currentUserMailAddress = Credential.useCurrentUserMailAddress();
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const lang = useLanguageCode();
  const hooks = SystemDesignTestReport.useCreateSystemDesignHooks(args.entityId);
  const questionDetail = hooks.useQuestion();

  const averageElapsedTimeObject = secondsToTimeObject(questionDetail?.averageElapsedTimeSeconds ?? 0, "MINUTES");
  const timelimitObject =
    questionDetail && questionDetail?.timeLimitSeconds !== null ? secondsToTimeObject(questionDetail.timeLimitSeconds, "MINUTES") : undefined;

  return {
    title: questionDetail ? resolveLanguage(questionDetail, lang, "title") : "",
    description: questionDetail ? resolveLanguage(questionDetail, lang, "description") : "",
    isArchived: ((): boolean => {
      if (!args.showArchivedMark) {
        return false;
      }
      return questionDetail.systemDesignQuestionStatus === "ARCHIVED";
    })(),
    openButton: args.showAnswer
      ? {
          href: generatePath("/e/questions/systemdesign/:id", {
            pathParams: {
              id: questionDetail?.questionId.toString() || "",
            },
          }),
        }
      : undefined,
    difficulty: questionDetail?.difficulty ?? "UNKNOWN",
    info: {
      feedbackLink: FeedbackLinkFactory.generateFeedbackLink({
        mailAddress: currentUserMailAddress,
        targetUrl: generateCurrentOriginUrl("/e/questions/systemdesign/:id", {
          pathParams: {
            id: questionDetail?.questionId.toString() || "",
          },
        }),
      }),
      stats: {
        avgCorrectIntegerRate:
          questionDetail.numUses > 0
            ? {
                kind: "VALUE",
                value: formateRateToIntegerPercent(questionDetail.accuracyRate),
              }
            : {
                kind: "NONE",
              },
        numOfUsage: questionDetail.numUses.toLocaleString(),
        avgElapseTime:
          questionDetail.numUses > 0
            ? {
                kind: "VALUE",
                minutes: averageElapsedTimeObject.minutes,
                seconds: averageElapsedTimeObject.seconds,
              }
            : { kind: "NONE" },
      },
      detailInfo: {
        difficultyStars: {
          difficulty: questionDetail.difficulty,
        },
        timelimitMinutes: timelimitObject?.minutes,
        createdBy: questionDetail.isOfficial
          ? {
              kind: "OFFICIAL",
            }
          : {
              kind: "PRIVATE",
              //TODO: implement when the user can create private question
              displayName: "",
            },
      },
    },
    answers: args.showAnswer
      ? questionDetail.answers.map((answer, index): Answer => {
          return {
            name: `${t("解答")}${index + 1}`,
            title: resolveLanguage(answer, lang, "title"),
            description: resolveLanguage(answer, lang, "description"),
          };
        })
      : [],
    hintStack: args.showAnswer
      ? {
          items: questionDetail.hints.map((hint, index) => {
            return {
              mode: "unlocked",
              value: {
                title: t2("ConfirmHint", { num: index + 1 }),
                description: resolveLanguage(hint, lang, "description"),
                disabled: false,
              },
            };
          }),
        }
      : undefined,
  };
};
