import * as React from "react";
import { proxyMap } from "valtio/utils";

import { createAction } from "./Action";
import { createHooks } from "./hooks";
import { createState } from "./State";
import type * as Types from "./types";

export type ContextValue = {
  action: ReturnType<typeof createAction>;
  hooks: ReturnType<typeof createHooks>;
};

export const ProjectContext = React.createContext<ContextValue>({} as ContextValue);

export const useProjectContext = () => React.useContext(ProjectContext);

export type ProjectProviderProps = {
  /**
   * Key to manage state
   */
  uniqueKey: string;
  initialState?: Partial<Types.State>;
};

const multiState = proxyMap<string, Types.State>();

export const ProjectProvider: React.FC<React.PropsWithChildren<ProjectProviderProps>> = props => {
  const { uniqueKey } = props;
  const state = createState(props.initialState || multiState.get(uniqueKey));
  if (!multiState.has(uniqueKey)) {
    multiState.set(uniqueKey, state);
  }
  const action = createAction(state);
  const hooks = createHooks(state);
  const contextValue: ContextValue = {
    action,
    hooks,
  };
  return <ProjectContext.Provider value={contextValue} children={props.children} />;
};
