import { useTitle } from "@hireroo/app-helper/react-use";
import { QuestionsPackages } from "@hireroo/app-store/page/e/questions_packages";
import { EmployeeSideBarContent } from "@hireroo/app-store/widget/e/EmployeeSideBarContent";
import { useCurrentLanguage, useTranslation } from "@hireroo/i18n";
import type { Pages } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import React from "react";
import { useSearchParams } from "react-router-dom";

import EmployeeHeaderRightContentContainer from "../../../../widget/v2/e/EmployeeHeaderRightContent/Container";
import EmployeeSideBarContentContainer from "../../../../widget/v2/e/EmployeeSideBarContent/Container";
import FooterContainer from "../../../../widget/v2/e/Footer/Container";
import { useNotificationBanner } from "../../../../widget/v2/e/NotificationBanner/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import * as PrivateHelper from "./privateHelper";
import QuestionPackageSearchAreaContainer from "./widgets/QuestionPackageSearchArea/Container";
import QuestionPackageTableFetchContainer from "./widgets/QuestionPackageTable/FetchContainer";

export type GenerateQuestionPackageListPropsArgs = {};

export const useGenerateProps = (_args: GenerateQuestionPackageListPropsArgs): Pages.QuestionPackageListProps => {
  const { t } = useTranslation();
  const navigate = useTransitionNavigate();
  const NotificationBannerContainer = useNotificationBanner();
  const initializedCurrentSearchFilter = QuestionsPackages.useInitializedCurrentSearchFilter();
  const language = useCurrentLanguage();
  const [searchParams] = useSearchParams();

  useTitle(t("問題集一覧"));

  React.useEffect(() => {
    QuestionsPackages.initializeFilters(
      {
        textFilter: searchParams.get(QuestionsPackages.QueryKeys.TITLE) ?? "",
        difficulties: PrivateHelper.convertStringsToDifficulties(searchParams.getAll(QuestionsPackages.QueryKeys.DIFFICULTY)),
      },
      {
        page: PrivateHelper.convertStringsToPage(searchParams.get(QuestionsPackages.QueryKeys.PAGE)),
        size: PrivateHelper.convertStringToSize(searchParams.get(QuestionsPackages.QueryKeys.SIZE)),
        sortFieldValue: PrivateHelper.convertStringToSortFieldValue(searchParams.get(QuestionsPackages.QueryKeys.SORT)),
      },
    );
  }, [language, searchParams]);

  return {
    layout: {
      openSidebar: EmployeeSideBarContent.useOpenSidebar(),
      onChangeOpenSidebar: open => {
        EmployeeSideBarContent.setOpenSidebar(open);
      },
      NotificationBanner: NotificationBannerContainer,
      HeaderRightContent: <EmployeeHeaderRightContentContainer />,
      SideBarContent: <EmployeeSideBarContentContainer />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      sideBarHeader: {
        logo: {
          href: generatePath("/e/home"),
          onClick: () => {
            navigate("/e/home");
          },
        },
      },
    },
    goQuestionsButton: {
      onClick: () => {
        navigate("/e/questions");
      },
    },
    SearchArea: initializedCurrentSearchFilter ? <QuestionPackageSearchAreaContainer /> : null,
    Table: initializedCurrentSearchFilter ? <QuestionPackageTableFetchContainer /> : null,
  };
};
