import * as React from "react";

import TestResult, { TestResultProps } from "../TestResult/TestResult";
import { testResultsData } from "./TestResult.StoryExample";

export type TestResultPlaygroundProps = {};

/**
 * Only for dev
 */
const TestResultPlayground: React.FC<TestResultPlaygroundProps> = _props => {
  const resultBlocks: TestResultProps = {
    rawData: JSON.stringify(testResultsData),
    onError: error => {
      console.log(error);
    },
  };

  return <TestResult {...resultBlocks} />;
};

TestResultPlayground.displayName = "TestResultPlayground";

export default TestResultPlayground;
