import Markdown from "@hireroo/markdown-v2/react";
import Box from "@mui/material/Box";
import * as React from "react";

import TestResultItem, { Status, TestResultItemProps } from "./parts/TestResultItem";

export type DataScienceTestCaseProps = {
  status: Status;
  testResult: TestResultItemProps;
  description?: string;
};

const DataScienceTestCase: React.FC<DataScienceTestCaseProps> = props => {
  return (
    <Box width="100%">
      {props.description && (
        <React.Suspense>
          <Markdown size={"small"} children={props.description} />
        </React.Suspense>
      )}
      <TestResultItem {...props.testResult} />
    </Box>
  );
};

DataScienceTestCase.displayName = "DataScienceTestCase";

export default DataScienceTestCase;
