import { App } from "@hireroo/app-store/essential/candidate";
import type { Pages } from "@hireroo/presentation";
import * as React from "react";

import CandidateFooterContainer from "../../../../widget/v2/c/CandidateFooter/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import CandidateNavigationContainer from "./widget/CandidateNavigation/Container";
import ScreeningTestDetailForCandidateFetchContainer from "./widget/ScreeningTestDetailForCandidate/FetchContainer";

export type GenerateInterviewDetailForCandidatePropsArgs = {};

export const useGenerateProps = (_args: GenerateInterviewDetailForCandidatePropsArgs): Pages.ScreeningTestDetailForCandidateProps => {
  const appStatus = App.useStatus();

  return {
    layout: {
      loading: appStatus === "INITIALIZING",
      NotificationBanner: null,
      Header: <CandidateNavigationContainer />,
      Footer: <CandidateFooterContainer />,
      Snackbar: <SnackbarContainer />,
      Tutorial: null,
    },
    children: <ScreeningTestDetailForCandidateFetchContainer />,
  };
};
