import Box from "@mui/material/Box";
import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import * as React from "react";

import type * as Types from "../../../../types";
import SidePanelNavigationMenuItem from "./parts/SidePanelNavigationMenuItem/SidePanelNavigationMenuItem";

const StyledList = styled(List)(() => ({
  padding: 0,
}));

export type SidePanelNavigationMenuProps = {
  root?: boolean;
  items: Types.MenuItem[];
};

const SidePanelNavigationMenu: React.FC<SidePanelNavigationMenuProps> = props => {
  return (
    <Box>
      <StyledList>
        {props.items.map(item => {
          const { children } = item;
          if (typeof children === "string") {
            return <SidePanelNavigationMenuItem key={item.id} {...item} children={children} root={props.root} />;
          }
          const childSidePanelNavigationMenuProps: SidePanelNavigationMenuProps = {
            items: children,
          };
          return <SidePanelNavigationMenu key={item.id} {...childSidePanelNavigationMenuProps} />;
        })}
      </StyledList>
    </Box>
  );
};

SidePanelNavigationMenu.displayName = "SidePanelNavigationMenu";

export default SidePanelNavigationMenu;
