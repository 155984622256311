import MicNoneOutlined from "@mui/icons-material/MicNoneOutlined";
import MicOffOutlined from "@mui/icons-material/MicOffOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import VideocamOffOutlined from "@mui/icons-material/VideocamOffOutlined";
import VideocamOutlined from "@mui/icons-material/VideocamOutlined";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import * as React from "react";

import IconButton, { IconButtonProps } from "../../../primitive/Button/IconButton/IconButton";

export type DeviceSettingButtonGroupProps = {
  audioToggleButton: Pick<IconButtonProps, "onClick"> & { isEnabled: boolean };
  videoToggleButton: Pick<IconButtonProps, "onClick"> & { isEnabled: boolean };
  settingsButton?: Pick<IconButtonProps, "onClick">;
};

const DeviceSettingButtonGroup: React.FC<DeviceSettingButtonGroupProps> = props => {
  const { audioToggleButton, videoToggleButton, settingsButton } = props;
  const { isEnabled: isEnabledAudioToggleButtonProps, ...audioToggleButtonProps } = audioToggleButton;
  const { isEnabled: isEnabledVideoToggleButtonProps, ...videoToggleButtonProps } = videoToggleButton;
  const iconButtonPropsList: (IconButtonProps | false)[] = [
    {
      ...audioToggleButtonProps,
      children: isEnabledAudioToggleButtonProps ? <MicNoneOutlined /> : <MicOffOutlined color="error" />,
    },
    {
      ...videoToggleButtonProps,
      children: isEnabledVideoToggleButtonProps ? <VideocamOutlined /> : <VideocamOffOutlined color="error" />,
    },
    !!settingsButton && {
      ...settingsButton,
      children: <SettingsOutlinedIcon />,
    },
  ];
  return (
    <Box pt={1} px={1}>
      <Stack direction={"row"} justifyContent={"center"} alignItems={"center"}>
        <Stack direction="row">
          {iconButtonPropsList
            .flatMap(v => (v ? [v] : []))
            .map((iconButtonProps, index) => (
              <IconButton key={`button-${index}`} {...iconButtonProps} />
            ))}
        </Stack>
      </Stack>
    </Box>
  );
};

DeviceSettingButtonGroup.displayName = "DeviceSettingButtonGroup";

export default DeviceSettingButtonGroup;
