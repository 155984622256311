import { RemoteInterviewOverview } from "@hireroo/app-store/widget/e/RemoteInterviewOverview";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/react";
import * as React from "react";

import { useFetchTags } from "./useFetchTags";

export type GenerateTagDialogPropsArgs = {};

export const useGenerateTagDialogProps = (args: GenerateTagDialogPropsArgs): Widget.RemoteInterviewOverviewProps["info"]["tagDialog"] => {
  const [open, setOpen] = React.useState(false);
  const interview = RemoteInterviewOverview.useInterview();
  const { t } = useTranslation();
  const [resultUpdateRemote, updateRemote] = Graphql.useUpdateRemoteForRemoteInterviewOverviewMutation();
  const { isLoading } = useFetchTags(args);
  const selectableTags = RemoteInterviewOverview.useTags();
  const defaultTags = interview.tags.map(t => t.name);

  return {
    open,
    tagInputField: {
      disabled: isLoading,
      options: selectableTags,
    },
    onOpen: () => {
      setOpen(true);
    },
    onClose: () => {
      setOpen(false);
    },
    defaultValues: {
      tags: defaultTags,
    },
    disabled: resultUpdateRemote.fetching,
    onSubmit: fields => {
      updateRemote({
        input: {
          remoteId: interview.remoteId,
          tagNames: fields.tags,
        },
      }).then(result => {
        if (result.data?.updateRemote) {
          RemoteInterviewOverview.updateInterview(result.data.updateRemote);
          setOpen(false);
          Snackbar.notify({
            severity: "success",
            message: t("テスト情報を更新しました。"),
          });
        } else if (result.error) {
          Snackbar.notify({
            severity: "error",
            message: t("テストの更新に失敗しました。"),
          });
          Sentry.captureException(result.error);
        }
      });
    },
  };
};
