import { App } from "@hireroo/app-store/essential/employee";
import { QuestionsPackages } from "@hireroo/app-store/page/e/questions_packages";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import QuestionPackageTableContainer, { QuestionPackageTableContainerProps } from "./Container";

export type QuestionPackageTableFetchContainerProps = {};

const QuestionPackageTableFetchContainer: React.FC<QuestionPackageTableFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const pager = QuestionsPackages.usePager();
  const currentSearchFilter = QuestionsPackages.useCurrentSearchFilter();
  const initialized = QuestionsPackages.useInitialized();
  const lang = useLanguageCode();
  const { isDescending, sortMethod } = QuestionsPackages.useSortParams();

  const [result, refresh] = Graphql.useListQuestionsPackagesForQuestionsPackagesQuery({
    variables: {
      size: pager.size,
      offset: pager.offset,
      isDescending: isDescending,
      sortMethod: sortMethod,
      filters: {
        difficulties: currentSearchFilter.difficulties.slice(),
        questionVariants: [],
        titleEn: lang === "en" && currentSearchFilter.textFilter ? currentSearchFilter.textFilter : "",
        titleJa: lang === "ja" && currentSearchFilter.textFilter ? currentSearchFilter.textFilter : "",
      },
    },
    pause: appStatus !== "INITIALIZED",
  });

  React.useEffect(() => {
    if (result.data) {
      QuestionsPackages.setRes(result.data.questionPackages);
    }
  }, [result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: QuestionPackageTableContainerProps = {};

  return <QuestionPackageTableContainer {...containerProps} />;
};

QuestionPackageTableFetchContainer.displayName = "QuestionPackageTableFetchContainer";

export default withErrorBoundary(QuestionPackageTableFetchContainer, {});
