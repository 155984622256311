import * as React from "react";
import { useSnapshot } from "valtio";

import { state } from "./State";
import * as Types from "./types";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = (): boolean => {
  const snapshot = useSnapshotState();
  return !!snapshot.customer;
};

export const useCustomer = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.customer) {
    throw new Error("Please Initialize Payment Contract Create Form");
  }
  return snapshot.customer;
};

export const useSubmitValue = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.submitValue) {
    throw new Error("Please Set submit value");
  }
  return snapshot.submitValue;
};

export const useOptionalSubmitValue = () => {
  const snapshot = useSnapshotState();
  return snapshot.submitValue;
};

export const useShowingTarget = () => {
  const snapshot = useSnapshotState();
  return snapshot.showingTarget;
};

export const useBuyAbleSelectionItemMap = (): Record<string, Types.Interview> => {
  const snapshot = useSnapshotState();
  return React.useMemo(() => {
    return snapshot.interviews.reduce((acc, interview) => {
      return { ...acc, [interview.interviewId.toString()]: interview };
    }, {});
  }, [snapshot.interviews]);
};

export const useTemporarySelectionItems = () => {
  const snapshot = useSnapshotState();
  return snapshot.temporarySelectionItems;
};

export const useBuyAbleInterviews = () => {
  const snapshot = useSnapshotState();
  return snapshot.interviews;
};
