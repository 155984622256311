/**
 * TODO Provide BlockNode-equivalent type definitions from validator.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseDefaultTestCase = (data: string): any => {
  try {
    /**
     * FIXME I simplified it because it is very hard to write a schema for zod.
     */
    return JSON.parse(data);
  } catch (error) {
    /**
     * !!! CAUTION !!!
     *
     * If the type definition is changed for UI reasons,
     * it may not function properly. Please be very careful.
     */
    return {
      blocks: [],
    };
  }
};
