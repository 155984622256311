import { App } from "@hireroo/app-store/essential/employee";
import { InterviewOverview } from "@hireroo/app-store/widget/e/InterviewOverview";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import ScreeningTestOverviewContainer, { ScreeningTestOverviewContainerProps } from "./Container";
import * as Subscriber from "./Subscriber";

export type ScreeningTestOverviewFetchContainerProps = {
  interviewId: string;
  companyId: number;
};

const ScreeningTestOverviewFetchContainer: React.FC<ScreeningTestOverviewFetchContainerProps> = props => {
  const appStatus = App.useStatus();
  const [result, refresh] = Graphql.useGetInterviewOverviewQuery({
    variables: {
      id: props.interviewId,
      withAnswers: true,
    },
    requestPolicy: "cache-and-network",
    pause: appStatus !== "INITIALIZED",
  });
  const initialized = InterviewOverview.useInitialized();

  React.useEffect(() => {
    if (result.data?.spot) {
      InterviewOverview.initialize(result.data.spot);
      InterviewOverview.setRefresh(() => refresh({ requestPolicy: "network-only" }));
    }
  }, [refresh, result.data]);

  React.useEffect(() => {
    const stopSubscribeSelectedEmployee = Subscriber.startSubscribeSelectedEmployees();
    const stopSubscribeSelectedGroup = Subscriber.startSubscribeSelectedGroups();

    return () => {
      stopSubscribeSelectedEmployee();
      stopSubscribeSelectedGroup();
    };
  }, []);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="TOP" />;
  }
  const containerProps: ScreeningTestOverviewContainerProps = {
    companyId: props.companyId,
  };
  return (
    <ErrorBoundary>
      <ScreeningTestOverviewContainer {...containerProps} />
    </ErrorBoundary>
  );
};

ScreeningTestOverviewFetchContainer.displayName = "ScreeningTestOverviewFetchContainer";

export default withErrorBoundary(ScreeningTestOverviewFetchContainer, {});
