import { DiffEditor as MonacoDiffEditor, DiffEditorProps as MonacoDiffEditorProps } from "@monaco-editor/react";
import type * as monaco from "monaco-editor";
import * as React from "react";

import { javascriptreactConf, javascriptreactDef, typescriptreactConf, typescriptreactDef } from "../../definition/languageDef";
import { updateEditorValidationMessage } from "../../helpers/monaco";
import monokaiTheme from "../../themes/monokai.json" assert { type: "json" };

export type DiffEditorProps = MonacoDiffEditorProps;

/**
 * @deprecated Please use packages/code-editor/react/v2/DiffEditor/DiffEditor.tsx
 */
const DiffEditor: React.FC<DiffEditorProps> = props => {
  const diffEditorProps: MonacoDiffEditorProps = {
    width: "100%",
    height: 50,
    language: "text",
    ...props,
    theme: "monokai",
    onMount: (editor, monaco) => {
      /**
       * NOTE: Dynamic import by React.Suspence to prevent value not being set when mounted
       */
      const originalEditor = editor.getOriginalEditor();
      const modifiedEditor = editor.getModifiedEditor();
      if (props.original) {
        originalEditor.setValue(props.original);
      }
      if (props.modified) {
        modifiedEditor.setValue(props.modified);
      }
      props.onMount?.(editor, monaco);
    },
    beforeMount: monaco => {
      // Define the monokai theme: https://github.com/brijeshb42/monaco-themes
      monaco.editor.defineTheme("monokai", monokaiTheme as monaco.editor.IStandaloneThemeData);
      monaco.editor.setTheme("monokai");
      updateEditorValidationMessage(monaco);

      //register the language
      monaco.languages.register({ id: "typescriptreact" });
      monaco.languages.register({ id: "javascriptreact" });

      // Register language definition and configuration
      monaco.languages.setMonarchTokensProvider("typescriptreact", typescriptreactDef);
      monaco.languages.setMonarchTokensProvider("javascriptreact", javascriptreactDef);
      monaco.languages.setLanguageConfiguration("typescriptreact", typescriptreactConf);
      monaco.languages.setLanguageConfiguration("javascriptreact", javascriptreactConf);

      props.beforeMount?.(monaco);
    },
    options: {
      fontSize: 14,
      renderSideBySide: true,
      minimap: {
        enabled: false,
      },
      ...props.options,
    },
  };
  return <MonacoDiffEditor {...diffEditorProps} />;
};

DiffEditor.displayName = "DiffEditor";

export default DiffEditor;
