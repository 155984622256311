import { DeepReadonly } from "@hireroo/app-helper/types";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import addWeeks from "date-fns/addWeeks";
import endOfDay from "date-fns/endOfDay";
import * as React from "react";

import ScreeningTestRetakeConfirmContainer from "./widget/ScreeningTestRetakeConfirm/Container";

type Option = Widget.ScreeningTestRetakeDialogProps["editor"]["retakeEntitiesField"]["options"][0];

type SelectedEntityId = Widget.ScreeningTestRetakeDialogProps["defaultValues"]["selectedEntityIds"][0];

const DEFAULT_TIME_LIMIT_MINUTES = 25;
export type GenerateScreeningTestRetakeDialogPropsArgs = Pick<
  Widget.ScreeningTestRetakeDialogProps,
  "open" | "onClose" | "onSubmit" | "loading" | "buttonDisabled"
> & {
  interview: DeepReadonly<Graphql.SpotForRetakeInterviewsIdDetailFragment> | null;
};
export const useGenerateProps = (args: GenerateScreeningTestRetakeDialogPropsArgs): Widget.ScreeningTestRetakeDialogProps => {
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const maxTimeLimitMinutes = React.useMemo(() => {
    if (!args.interview || !args.interview.timeLimitSeconds) {
      return DEFAULT_TIME_LIMIT_MINUTES;
    }
    return (args.interview.timeLimitSeconds - (args.interview.elapsedTimeSeconds || 0)) / 60;
  }, [args.interview]);
  const defaultTimeLimitMinutes = React.useMemo(() => {
    if (!args.interview) {
      return DEFAULT_TIME_LIMIT_MINUTES;
    }
    const entitiesSumTimelimitMinutes = (args.interview?.entities || []).reduce<number>((all, entity) => {
      if (entity.__typename === "ChallengeEntity" && entity.challengeQuestion) {
        const timeLimitMinutes = entity.challengeQuestion?.timeLimitSeconds
          ? entity.challengeQuestion.timeLimitSeconds / 60
          : DEFAULT_TIME_LIMIT_MINUTES;
        return all + timeLimitMinutes;
      } else if (entity.__typename === "QuizEntity" && entity.pb_package) {
        const timeLimitMinutes = entity.pb_package.timeLimitSeconds ? entity.pb_package.timeLimitSeconds / 60 : DEFAULT_TIME_LIMIT_MINUTES;
        return all + timeLimitMinutes;
      } else if (entity.__typename === "ProjectEntity" && entity.question) {
        const timeLimitMinutes = entity.question.timeLimitSeconds ? entity.question.timeLimitSeconds / 60 : DEFAULT_TIME_LIMIT_MINUTES;
        return all + timeLimitMinutes;
      } else if (entity.__typename === "SystemDesignEntity" && entity.question) {
        const timeLimitMinutes = entity.question.timeLimitSeconds ? entity.question.timeLimitSeconds / 60 : DEFAULT_TIME_LIMIT_MINUTES;
        return all + timeLimitMinutes;
      }
      return all;
    }, 0);

    return maxTimeLimitMinutes > entitiesSumTimelimitMinutes ? entitiesSumTimelimitMinutes : maxTimeLimitMinutes;
  }, [args.interview, maxTimeLimitMinutes]);

  const options = React.useMemo(() => {
    return (args.interview?.entities || []).reduce<Option[]>((all, entity) => {
      if (entity.__typename === "ChallengeEntity" && entity.challengeQuestion) {
        return all.concat({
          entityId: {
            entityId: entity.challengeEntityId,
            entityType: "CHALLENGE",
            timeLimitMinutes: entity.challengeQuestion?.timeLimitSeconds
              ? entity.challengeQuestion.timeLimitSeconds / 60
              : DEFAULT_TIME_LIMIT_MINUTES,
          },
          displayName: `${resolveLanguage(entity.challengeQuestion, lang, "title")} (${t("コーディング形式")})`,
        });
      } else if (entity.__typename === "QuizEntity" && entity.pb_package) {
        return all.concat({
          entityId: {
            entityId: entity.quizEntityId,
            entityType: "QUIZ",
            timeLimitMinutes: entity.pb_package.timeLimitSeconds ? entity.pb_package.timeLimitSeconds / 60 : DEFAULT_TIME_LIMIT_MINUTES,
          },
          displayName: `${resolveLanguage(entity.pb_package, lang, "title")} (${t("クイズ形式")})`,
        });
      } else if (entity.__typename === "ProjectEntity" && entity.question) {
        return all.concat({
          entityId: {
            entityId: entity.projectEntityId,
            entityType: "PROJECT",
            timeLimitMinutes: entity.question.timeLimitSeconds ? entity.question.timeLimitSeconds / 60 : DEFAULT_TIME_LIMIT_MINUTES,
          },
          displayName: `${resolveLanguage(entity.question, lang, "title")} (${t("実践形式")})`,
        });
      } else if (entity.__typename === "SystemDesignEntity" && entity.question) {
        return all.concat({
          entityId: {
            entityId: entity.systemDesignEntityId,
            entityType: "SYSTEM_DESIGN",
            timeLimitMinutes: entity.question.timeLimitSeconds ? entity.question.timeLimitSeconds / 60 : DEFAULT_TIME_LIMIT_MINUTES,
          },
          displayName: `${resolveLanguage(entity.question, lang, "title")} (${t("システムデザイン形式")})`,
        });
      }
      return all;
    }, []);
  }, [args.interview?.entities, lang, t]);
  const selectedEntityIds = React.useMemo(() => {
    return (args.interview?.entities || []).reduce<SelectedEntityId[]>((all, entity) => {
      if (entity.__typename === "ChallengeEntity" && entity.challengeQuestion) {
        return all.concat({
          entityId: entity.challengeEntityId,
          entityType: "CHALLENGE",
          timeLimitMinutes: entity.challengeQuestion?.timeLimitSeconds
            ? entity.challengeQuestion.timeLimitSeconds / 60
            : DEFAULT_TIME_LIMIT_MINUTES,
        });
      } else if (entity.__typename === "QuizEntity" && entity.pb_package) {
        return all.concat({
          entityId: entity.quizEntityId,
          entityType: "QUIZ",
          timeLimitMinutes: entity.pb_package.timeLimitSeconds ? entity.pb_package.timeLimitSeconds / 60 : DEFAULT_TIME_LIMIT_MINUTES,
        });
      } else if (entity.__typename === "ProjectEntity" && entity.question) {
        return all.concat({
          entityId: entity.projectEntityId,
          entityType: "PROJECT",
          timeLimitMinutes: entity.question.timeLimitSeconds ? entity.question.timeLimitSeconds / 60 : DEFAULT_TIME_LIMIT_MINUTES,
        });
      } else if (entity.__typename === "SystemDesignEntity" && entity.question) {
        return all.concat({
          entityId: entity.systemDesignEntityId,
          entityType: "SYSTEM_DESIGN",
          timeLimitMinutes: entity.question.timeLimitSeconds ? entity.question.timeLimitSeconds / 60 : DEFAULT_TIME_LIMIT_MINUTES,
        });
      }
      return all;
    }, []);
  }, [args.interview?.entities]);
  return {
    loading: args.loading,
    open: args.open,
    onClose: args.onClose,
    editor: {
      retakeEntitiesField: {
        options: options,
      },
    },
    Confirm: args.interview && <ScreeningTestRetakeConfirmContainer interview={args.interview} />,
    defaultValues: {
      selectedEntityIds: selectedEntityIds,
      timeLimitMinutes: defaultTimeLimitMinutes,
      willEndAt: addWeeks(endOfDay(new Date()), 1),
      reasonType: "SYSTEM_ERROR",
      reasonComment: "",
      agreement: false,
    },
    onSubmit: args.onSubmit,
    maxTimeLimitMinutes: maxTimeLimitMinutes,
    buttonDisabled: args.buttonDisabled,
  };
};
