import Box from "@mui/material/Box";
import * as React from "react";

import EmployeeLayout, { EmployeeLayoutProps } from "../../layouts/EmployeeLayout/EmployeeLayout";
import UserOrCompanySettingsLayout, {
  UserOrCompanySettingsLayoutProps,
} from "../../layouts/UserOrCompanySettingsLayout/UserOrCompanySettingsLayout";

export type CompanySettingsProps = {
  layout: EmployeeLayoutProps;
  settingsLayout: Omit<UserOrCompanySettingsLayoutProps, "children">;
  children: React.ReactNode;
};

const CompanySettings: React.FC<CompanySettingsProps> = props => {
  return (
    <EmployeeLayout {...props.layout}>
      <Box p={3}>
        <UserOrCompanySettingsLayout {...props.settingsLayout}>{props.children}</UserOrCompanySettingsLayout>
      </Box>
    </EmployeeLayout>
  );
};

CompanySettings.displayName = "CompanySettings";

export default CompanySettings;
