import { subscribeKey } from "valtio/utils";

import { state } from "./State";
import type * as Types from "./types";

export type SubscribeCurrentSubmissionCallback = (submission: Types.Submission["projectSubmissionId"]) => void;

export const subscribeCurrentSubmissionId = (projectId: Types.ProjectEntityId, callback: SubscribeCurrentSubmissionCallback) => {
  const projectState = state.projects.get(projectId);
  if (!projectState) {
    console.warn(`[subscribeCurrentSubmissionId] Can not subscribe. projectId=${projectId} is not initialized`);
    return () => undefined;
  }
  return subscribeKey(projectState, "currentSelectedSubmissionId", submissionId => {
    return callback(submissionId);
  });
};
