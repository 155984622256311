import { useSnapshot } from "valtio";

import * as Types from "./types";

export const createHooks = (privateState: Types.State) => {
  const useSnapshotState = () => {
    return useSnapshot(privateState);
  };

  const useEnableLanguages = () => {
    const snapshot = useSnapshotState();
    return snapshot.enableLanguages ?? [];
  };

  const useCurrentLanguage = () => {
    const snapshot = useSnapshotState();
    return snapshot.currentLanguage;
  };

  const useUpdateMethod = () => {
    const snapshot = useSnapshotState();
    return snapshot.updateMethod;
  };

  return {
    useEnableLanguages,
    useCurrentLanguage,
    useUpdateMethod,
  };
};
