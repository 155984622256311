import { useTranslation } from "@hireroo/i18n";
import InfoIcon from "@mui/icons-material/Info";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const CardWrapper = styled(Box)(({ theme }) => ({
  borderRadius: 8,
  border: `solid 1px ${theme.palette["Secondary/Shades"].p16}`,
  backgroundColor: theme.palette.Action.Hover.p8,
}));

const CardHeader = styled(Box)(({ theme }) => ({
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  padding: "6px 16px",
  border: `solid 1px ${theme.palette["Secondary/Shades"].p16}`,
  backgroundColor: theme.palette.action.hover,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  svg: {
    color: theme.palette.text.secondary,
  },
}));

const Heading = styled(Typography)(() => ({
  fontSize: 16,
  marginLeft: 8,
}));

const ItemBox = styled(Stack)(({ theme }) => ({
  width: "100%",
  padding: "12px 16px",
  borderRight: `1px solid ${theme.palette.divider}`,
  "&:last-child": {
    borderRight: "none",
  },
}));

const ItemHeading = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.text.secondary,
  textAlign: "center",
}));

const ItemBody = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontWeight: 700,
  color: theme.palette.text.primary,
  textAlign: "center",
}));

type Item = {
  title: string;
  value: string;
};

type Status = "NOT_SUBMITTED" | "ALL_SUBMITTED" | "REMAIN_SKIPPED_QUESTION";

export type QuizQuestionAnswerInfoProps = {
  status: Status;
  items: Item[];
};

const QuizQuestionAnswerInfo: React.FC<QuizQuestionAnswerInfoProps> = props => {
  const { t } = useTranslation();

  const statusTextMap: Record<Status, string> = {
    NOT_SUBMITTED: t("未提出の問題があります。"),
    ALL_SUBMITTED: t("すべて提出済み。"),
    REMAIN_SKIPPED_QUESTION: t("解答をスキップした問題が残っています。"),
  };
  return (
    <CardWrapper>
      <CardHeader>
        <InfoIcon />
        <Heading>{statusTextMap[props.status]}</Heading>
      </CardHeader>
      <Box>
        <Stack direction="row">
          {props.items.map((item, index) => {
            return (
              <ItemBox key={index.toString()} spacing={0.5}>
                <ItemHeading>{item.title}</ItemHeading>
                <ItemBody>{item.value}</ItemBody>
              </ItemBox>
            );
          })}
        </Stack>
      </Box>
    </CardWrapper>
  );
};

QuizQuestionAnswerInfo.displayName = "QuizQuestionAnswerInfo";

export default QuizQuestionAnswerInfo;
