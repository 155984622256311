import { LanguageMap, languageMap } from "@hireroo/app-definition";
import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Auth } from "@hireroo/app-store/essential/employee";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/server/types";
import { useChangeLanguage, useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useHelpCenterNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/browser";
import React from "react";

export type GenerateRemoteInterviewEvaluateHeaderPropsArgs = {};

export const useGenerateProps = (_args: GenerateRemoteInterviewEvaluateHeaderPropsArgs): Widget.RemoteInterviewEvaluateHeaderProps => {
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const helpCenterNavigate = useHelpCenterNavigate(lang);
  const client = getGraphqlClient();
  const changeLanguage = useChangeLanguage();
  const currentUser = Auth.useCurrentUser();

  const updateLanguage = React.useCallback(
    async (lang: Graphql.Language): Promise<void> => {
      await client
        .UpdateUserForUserProfile({
          updateUserInput: {
            id: currentUser.uid,
            displayName: currentUser.displayName,
            language: lang,
            photoUrl: currentUser.photoUrl,
          },
        })
        .then(res => {
          changeLanguage(LanguageMap[res.updateUser.language]);
          Snackbar.notify({
            severity: "success",
            message: t("ユーザ情報を更新しました。"),
          });
          Auth.setUser(res.updateUser);
        })
        .catch(error => {
          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(
            error,
            t("ユーザ情報の更新に失敗しました。しばらくしてから再度お試し頂くか、ヘルプボタンよりお問い合わせください。"),
          );
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
        });
    },
    [changeLanguage, client, currentUser.displayName, currentUser.photoUrl, currentUser.uid, t],
  );

  return {
    brandLogo: {
      href: generatePath("/e/home"),
      target: "_blank",
    },
    helpMenu: {
      options: [
        {
          text: t("ヘルプ"),
          value: "caution",
          icon: "INFO",
          onClick: () => {
            helpCenterNavigate("ROOT", { _blank: true });
          },
        },
      ],
    },
    preferences: {
      languageDropDownMenu: {
        onChangeLanguage: language => {
          updateLanguage(languageMap[language]);
        },
      },
    },
  };
};
