import { QuizCodingEditor } from "@hireroo/app-store/widget/shared/QuizCodingEditor";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as Sentry from "@sentry/react";
import React from "react";

import QuizCodingEditorContainer, { QuizCodingEditorContainerProps } from "./Container";

export type QuizCodingEditorFetchContainerProps = QuizCodingEditorContainerProps;

const QuizCodingEditorFetchContainer: React.FC<QuizCodingEditorFetchContainerProps> = props => {
  const { t } = useTranslation();
  const [result, startQuiz] = Graphql.useStartQuizForQuizCodingEditorMutation();
  const { useEntity } = QuizCodingEditor.useCreateQuizEntityHooks(props.entityId);
  const entity = useEntity();

  React.useEffect(() => {
    if (entity.quizStatus === "CREATED" && !result.error && !result.fetching) {
      startQuiz({
        quizId: entity.quizEntityId,
        candidateId: props.uid,
      })
        .then(res => {
          if (res.data?.startQuiz) {
            QuizCodingEditor.initialize(res.data.startQuiz);
          }
        })
        .catch(err => {
          Snackbar.notify({
            severity: "error",
            message: t("クイズ形式の試験開始に失敗しました。"),
          });
          Sentry.captureException(err);
        });
    }
  }, [entity.quizEntityId, entity.quizStatus, props.uid, result, startQuiz, t]);

  if (entity.quizStatus === "CREATED" || result.fetching) {
    return <Widget.Loading kind="CENTER_%" color="secondary" />;
  }

  const containerProps: QuizCodingEditorContainerProps = props;

  return (
    <ErrorBoundary>
      <QuizCodingEditorContainer {...containerProps} />
    </ErrorBoundary>
  );
};

QuizCodingEditorFetchContainer.displayName = "QuizCodingEditorFetchContainer";

export default withErrorBoundary(QuizCodingEditorFetchContainer, {});
