import { PaymentContractCreateForm } from "@hireroo/app-store/widget/e/PaymentContractCreateForm";
import { Widget } from "@hireroo/presentation";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GeneratePaymentContractCreateFormPropsArgs, useGenerateProps } from "./useGenerateProps";

export type PaymentContractCreateFormContainerProps = GeneratePaymentContractCreateFormPropsArgs;

const PaymentContractCreateFormContainer: React.FC<PaymentContractCreateFormContainerProps> = props => {
  const paymentContractCreateFormProps = useGenerateProps(props);
  const navigate = useTransitionNavigate();
  /** Not available with FetchContainer because it can only be used after initialization.  */
  const customer = PaymentContractCreateForm.useCustomer();
  const [redirectCheckCompleted, setRedirectCheckCompleted] = React.useState(false);

  React.useEffect(() => {
    const canNewContract = customer.isTrial || customer.subscription.status === "INACTIVE";
    /**
     * This component is not available to users who are not new subscribers.
     */
    if (!canNewContract) {
      navigate("/e/settings/billing/overview");
    } else {
      setRedirectCheckCompleted(true);
    }
  }, [navigate, customer.isTrial, customer.subscription.status]);

  if (!redirectCheckCompleted) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  return (
    <ErrorBoundary>
      <Widget.PaymentContractCreateForm {...paymentContractCreateFormProps} />
    </ErrorBoundary>
  );
};

PaymentContractCreateFormContainer.displayName = "PaymentContractCreateFormContainer";

export default withErrorBoundary(PaymentContractCreateFormContainer, {});
