import type * as Types from "./types";

export type Permission = {
  readonly acceptedRoles: readonly Types.Role[];
  readonly minimumRequireRole: Types.Role;
};

export const ADMIN_ONLY_PERMISSION: Permission = {
  acceptedRoles: ["ADMIN"],
  minimumRequireRole: "ADMIN",
};

export const ADMIN_MANAGER_PERMISSION: Permission = {
  acceptedRoles: ["ADMIN", "MANAGER"],
  minimumRequireRole: "MANAGER",
};

export const ADMIN_MANAGER_ENGINEER_PERMISSION: Permission = {
  acceptedRoles: ["ADMIN", "MANAGER", "ENGINEER"],
  minimumRequireRole: "ENGINEER",
};

export const ADMIN_MANAGER_ENGINEER_EMPLOYEE_PERMISSION: Permission = {
  acceptedRoles: ["ADMIN", "MANAGER", "ENGINEER", "EMPLOYEE"],
  minimumRequireRole: "EMPLOYEE",
};
