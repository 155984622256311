import { AssessmentResourceEditor } from "@hireroo/app-store/widget/e/AssessmentResourceEditor";
import { Widget } from "@hireroo/presentation";

import TalentAssignFieldContainer from "../TalentAssignField/Container";

export type GenerateTestInviteSetupSectionArgs = {
  mode: Widget.ScreeningResourceEditorProps["mode"];
  defaultValues?: Widget.AssessmentResourceEditorV2Props["testInviteSetupSection"]["defaultValues"];
};

export const useGenerateTestInviteSetupSectionProps = (
  args: GenerateTestInviteSetupSectionArgs,
): Widget.AssessmentResourceEditorV2Props["testInviteSetupSection"] => {
  return {
    onSubmit: fields => {
      AssessmentResourceEditor.setSubmitValue("TEST_INVITE_SETUP", fields);
    },
    defaultValues: args.defaultValues || {
      talent: {
        type: "TALENT",
        talentId: "",
        locked: false,
      },
      messageForTalent: undefined,
    },
    TalentField: <TalentAssignFieldContainer name="talent" />,
  };
};
