import { ref } from "valtio";

import * as Helper from "./helper";
import { state } from "./State";
import type * as Types from "./types";

export const initialize = (res: Types.Res): void => {
  state.res = res;
};

export const clear = (): void => {
  state.res = null;
};

export type QueryParams = {
  size: string | null;
  page: string | null;
  email: string | null;
  statuses: string[];
  isDescending: string | null;
};

export const setQueryParams = (queryParams: QueryParams): void => {
  state.listParams = {
    isDescending: Helper.convertStringToIsDesc(queryParams.isDescending) ?? true,
    page: Helper.convertStringToPage(queryParams.page),
    size: Helper.convertStringToSize(queryParams.size),
    filters: {
      email: queryParams.email,
      statuses: Helper.convertStringsToStatuses(queryParams.statuses),
    },
  };
};

export const updateSize = (newValue: number): void => {
  state.listParams = {
    ...state.listParams,
    size: newValue,
  };
};

export const updatePage = (newValue: number): void => {
  state.listParams = {
    ...state.listParams,
    page: newValue,
  };
};
export const updateIsDescending = (newValue: boolean): void => {
  state.listParams = {
    ...state.listParams,
    isDescending: newValue,
  };
};

export const updateFilters = (newFilters: Types.Filters) => {
  state.listParams = {
    ...state.listParams,
    filters: {
      ...state.listParams.filters,
      ...newFilters,
    },
  };
};

export const setRefresh = (callback: Types.Callback) => {
  state.refresh = ref(callback);
};

export const refresh = () => {
  state.refresh();
};
