import { useTranslation } from "@hireroo/i18n";
import { SystemDesignScoreEditForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import DetailSwitch, { DetailSwitchProps } from "../../../modules/DetailSwitch/DetailSwitch";
import FormActionButtonGroup, { FormActionButtonGroupProps } from "./parts/FormActionButtonGroup";
import SystemDesignScoringGroup, { SystemDesignScoringGroupProps } from "./parts/SystemDesignScoringGroup";

type Item = Omit<SystemDesignScoringGroupProps, "forceExpanded">;

export type ReportSystemDesignEditableScoreSectionProps = {
  formActionButtonGroup?: FormActionButtonGroupProps;
  availability: Pick<Item, "scrollTargetId" | "titleWithScoreBar" | "items">;
  scalability: Pick<Item, "scrollTargetId" | "titleWithScoreBar" | "items">;
  consistency: Pick<Item, "scrollTargetId" | "titleWithScoreBar" | "items">;
  defaultValues: SystemDesignScoreEditForm.SystemDesignScoreEditForm;
  onSubmit: SubmitHandler<SystemDesignScoreEditForm.SystemDesignScoreEditForm>;
};

const ReportSystemDesignEditableScoreSection: React.FC<ReportSystemDesignEditableScoreSectionProps> = props => {
  const { onSubmit } = props;
  const { t } = useTranslation();
  const [mode, setMode] = React.useState<SystemDesignScoringGroupProps["mode"]>("READ");
  const validateSchema = SystemDesignScoreEditForm.useSystemDesignScoreEditForm();
  const method = useForm<SystemDesignScoreEditForm.SystemDesignScoreEditForm>({
    resolver: zodResolver(validateSchema),
    defaultValues: props.defaultValues,
  });
  const [expandAll, setExpandAll] = React.useState(false);

  const items: Item[] = [
    {
      ...props.availability,
      mode,
      tooltip: {
        title: t("システムの停止時間ができるだけ短くなるような設計かどうかを判断する指標"),
      },
    },
    {
      ...props.scalability,
      mode,
      tooltip: {
        title: t("負荷の増加に応じて柔軟にシステムの性能を拡張できるような設計かどうかを判断する指標"),
      },
    },
    {
      ...props.consistency,
      mode,
      tooltip: {
        title: t("すべてのデータ読み込みにおいて最新の書き込みデータが取得できるような設計かどうかを判断する指標"),
      },
    },
  ].filter(target => target.items.length > 0);

  const handleClickSaveButton = React.useCallback(() => {
    setMode("READ");
    method.handleSubmit(onSubmit)();
  }, [method, onSubmit]);

  const formActionButtonGroupProps: FormActionButtonGroupProps | undefined = props.formActionButtonGroup
    ? {
        ...props.formActionButtonGroup,
        cancelButton: {
          ...props.formActionButtonGroup.cancelButton,
          onClick: () => {
            setMode("READ");
          },
        },
        saveButton: {
          ...props.formActionButtonGroup.saveButton,
          onClick: handleClickSaveButton,
        },
        editButton: {
          ...props.formActionButtonGroup.editButton,
          onClick: () => {
            setMode("EDIT");
          },
        },
      }
    : undefined;
  const detailSwitch: DetailSwitchProps = {
    checked: expandAll,
    onClick: () => {
      setExpandAll(prev => !prev);
    },
  };

  return (
    <Box>
      <FormProvider {...method}>
        <Box display={"flex"} alignItems={"center"} mb={3}>
          <Typography variant="subtitle1" sx={theme => ({ fontWeight: "bold", color: theme.palette.text.secondary })} flexGrow={1}>
            {t("評価項目")}
          </Typography>
          {formActionButtonGroupProps && <FormActionButtonGroup {...formActionButtonGroupProps} />}
        </Box>
        <DetailSwitch {...detailSwitch} />
        <Stack spacing={2}>
          {items.map((item, index) => (
            <SystemDesignScoringGroup key={`scoring-group-${index}`} {...item} forceExpanded={expandAll} />
          ))}
        </Stack>
      </FormProvider>
    </Box>
  );
};

ReportSystemDesignEditableScoreSection.displayName = "ReportSystemDesignEditableScoreSection";

export default ReportSystemDesignEditableScoreSection;
