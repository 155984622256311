import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type InvitationContainerProps = {
  invitationId: string;
};

const InvitationContainer: React.FC<InvitationContainerProps> = props => {
  const invitationProps = useGenerateProps({
    invitationId: props.invitationId,
  });
  return (
    <ErrorBoundary>
      <Pages.Invitation {...invitationProps} />
    </ErrorBoundary>
  );
};

InvitationContainer.displayName = "InvitationContainer";

export default withErrorBoundary(InvitationContainer, {});
