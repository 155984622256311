import { useTranslation } from "@hireroo/i18n";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlined from "@mui/icons-material/EditOutlined";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "../../../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";
import DeleteDialog, { DeleteDialogProps } from "../DeleteDialog/DeleteDialog";

export type TemplateListItemProps = {
  id: string;
  title: string;
  description: string;
  onClick: () => void;
  editButton: Pick<IconButtonWithTooltipProps, "onClick">;
  deleteButton: Pick<Exclude<DeleteDialogProps["yesButton"], undefined>, "onClick">;
  divider?: boolean;
};

const TemplateListItem: React.FC<TemplateListItemProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const editButton: IconButtonWithTooltipProps = {
    sx: {
      width: "26px",
      height: "26px",
    },
    onClick: event => {
      event.stopPropagation();
      props.editButton.onClick?.(event);
    },
    title: t("編集"),
    children: <EditOutlined fontSize="small" htmlColor={theme.palette.text.secondary} />,
  };
  const deleteButton: IconButtonWithTooltipProps = {
    sx: {
      width: "26px",
      height: "26px",
    },
    onClick: event => {
      event.stopPropagation();
      setOpen(true);
    },
    title: t("削除"),
    children: <DeleteOutlineIcon fontSize="small" htmlColor={theme.palette.text.secondary} />,
  };
  const deleteDialog: DeleteDialogProps = {
    open: open,
    templateName: props.title,
    yesButton: {
      onClick: () => {
        props.deleteButton.onClick?.();
        setOpen(false);
      },
    },
    noButton: {
      onClick: () => {
        setOpen(false);
      },
    },
  };
  return (
    <ListItem disablePadding divider={props.divider}>
      <ListItemButton sx={{ p: 1.5 }} dense onClick={props.onClick}>
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Stack direction="column">
            <Typography fontSize={14} mb={0.5}>
              {props.title}
            </Typography>
            <Typography fontSize={12} color="textSecondary">
              {props.description}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1.5}>
            <IconButtonWithTooltip key="edit-button" {...editButton} />
            <IconButtonWithTooltip key="delete-button" {...deleteButton} />
          </Stack>
        </Stack>
      </ListItemButton>
      <DeleteDialog {...deleteDialog} />
    </ListItem>
  );
};

TemplateListItem.displayName = "TemplateListItem";

export default TemplateListItem;
