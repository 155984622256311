import { useTranslation } from "@hireroo/i18n";
import { TestSearchForm } from "@hireroo/validator";
import { ArrowRightOutlined } from "@mui/icons-material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "../../../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";
import InputControlTextField, {
  InputControlTextFieldProps,
} from "../../../../primitive/InputControl/InputControlTextField/InputControlTextField";
import SelectWithSearch, { SelectWithSearchProps } from "../SelectWithSearch/SelectWithSearch";

const FieldPrefix = "variables" as const;

const IconButtonBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 40,
  width: 20,
}));

export type VariableFieldProps = {
  index: number;
  options: SelectWithSearchProps["options"];
  onDelete: () => void;
};

const VariableField: React.FC<VariableFieldProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const methods = useFormContext<TestSearchForm.FilledVariablesSchema>();
  const name: `${typeof FieldPrefix}.${number}` = `${FieldPrefix}.${props.index}`;
  const option = methods.watch(name);

  const textFieldProps: InputControlTextFieldProps = {
    size: "small",
    color: "secondary",
    variant: "outlined",
    label: t("候補者の回答検索"),
    InputProps: {
      startAdornment: (
        <InputAdornment position="start">
          <SearchOutlinedIcon fontSize="small" color="disabled" />
        </InputAdornment>
      ),
    },
  };
  const deleteButtonProps: IconButtonWithTooltipProps = {
    children: <DeleteOutlineIcon fontSize="small" htmlColor={theme.palette["Gray/Shades"].p56} />,
    size: "small",
    onClick: () => {
      props.onDelete();
    },
    title: t("削除"),
  };
  return (
    <Stack direction="row" spacing={1.5} alignItems="flex-start">
      <SelectWithSearch
        name={`${name}.labelId`}
        label={t("質問タイトル")}
        placeholder={t("質問タイトル検索")}
        options={props.options}
        onSelect={option => {
          methods.setValue(`${name}.valueType`, option.valueType);
          methods.setValue(`${name}.label`, option.label);
        }}
        emptyText={t("質問タイトルが見つかりません")}
      />
      {option && option.label !== "" && (
        <Stack direction="row" alignItems="center" spacing={1.5}>
          <IconButtonBox>
            <ArrowRightOutlined sx={{ height: "16px", width: "16px" }} />
          </IconButtonBox>
          <FormControl>
            <InputControlTextField name={`${name}.value`} {...textFieldProps} />
          </FormControl>
        </Stack>
      )}
      <IconButtonBox>
        <IconButtonWithTooltip {...deleteButtonProps} />
      </IconButtonBox>
    </Stack>
  );
};

VariableField.displayName = "VariableField";

export default VariableField;
