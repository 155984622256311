import * as z from "zod";

export const useSelectableQuestion = () => {
  return z.object({
    trackId: z.number(),
    index: z.number().nullable(),
  });
};

export type SelectableQuestion = z.infer<ReturnType<typeof useSelectableQuestion>>;
