import { styled } from "@mui/material/styles";
import MuiTab, { TabProps } from "@mui/material/Tab";
import * as React from "react";

export type { TabProps };

const StyledTab: React.FC<TabProps> = styled(MuiTab)(({ theme }) => ({
  "&.MuiTab-root": {
    color: theme.palette.text.secondary,
  },
  "&.Mui-selected": {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette["Secondary/Shades"].p16,
  },
}));

StyledTab.displayName = "StyledTab";

export default StyledTab;
