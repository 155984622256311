import { languageMapForDisplay } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import * as React from "react";
import { useFieldArray } from "react-hook-form";

import EditLanguageTab, { EditLanguageTabProps } from "../../../../usecase/EditLanguageTab/EditLanguageTab";
import { useFreepadAnswerFormContext } from "../../Context";
import AnswerSummaryFields from "../AnswerSummaryFields/AnswerSummaryFields";

export type AnswerInputDetailsProps = {
  index: number;
  answerEditLanguageTabMenu: EditLanguageTabProps["menu"];
  onLanguageTabChange: EditLanguageTabProps["onTabsChange"];
};

const AnswerInputDetails: React.FC<AnswerInputDetailsProps> = props => {
  const { methods } = useFreepadAnswerFormContext();
  const contentsField = useFieldArray({
    control: methods.control,
    name: `answers.${props.index}.contents`,
  });
  const contents = methods.getValues(`answers.${props.index}.contents`);

  const editLanguageTab: EditLanguageTabProps = {
    menu: props.answerEditLanguageTabMenu,
    onTabsChange: props.onLanguageTabChange,
    addButton: {
      disabled: contents?.[0]?.title === "" || contents?.[0]?.description === "",
    },
    items: (contents || []).map((content, index) => {
      return {
        id: index.toString(),
        name: languageMapForDisplay[content.language],
        Content: <AnswerSummaryFields key={content.language} index={props.index} contentIndex={index} lang={content.language} />,
        tab: {
          closeButton: {
            onClick: () => {
              contentsField.remove(index);
            },
          },
        },
      };
    }),
  };
  return (
    <Box>
      <EditLanguageTab {...editLanguageTab} />
    </Box>
  );
};

AnswerInputDetails.displayName = "AnswerInputDetails";

export default AnswerInputDetails;
