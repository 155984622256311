import { useSnapshot } from "valtio";

import * as Types from "../../c/interviews_id_detail/types";
import { state } from "./State";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useCurrentTab = () => {
  const snapshot = useSnapshotState();
  return snapshot.currentTab;
};

export const useInitialized = () => {
  const snapshot = useSnapshotState();
  return !!snapshot.interview;
};

export const useInterview = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.interview) {
    throw new Error("need to initialize");
  }
  return snapshot.interview;
};

export const useSharedLink = (): Types.InterviewDetailSharedLink => {
  const snapshot = useSnapshotState();
  if (!snapshot.sharedLink) {
    throw new Error("need to initialize");
  }
  return snapshot.sharedLink;
};
