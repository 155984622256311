import Editor, { EditorProps } from "@monaco-editor/react";
import * as monaco from "monaco-editor";
import * as React from "react";

import { javascriptreactConf, javascriptreactDef, typescriptreactConf, typescriptreactDef } from "../../definition/languageDef";
import * as SwapTheme from "../../helpers/swap-theme";
import { getTheme } from "../../helpers/theme";

export type SwappableCodeEditorProps = EditorProps & {
  skipResetTheme?: boolean;
};

const SwappableCodeEditor: React.FC<SwappableCodeEditorProps> = props => {
  const monacoRef = React.useRef<typeof monaco | null>(null);
  const { theme, themeData } = getTheme("monokai");

  const editorProps: EditorProps = {
    ...props,
    beforeMount: monaco => {
      SwapTheme.hideVscodeTheme();
      monaco.editor.defineTheme(theme, themeData as monaco.editor.IStandaloneThemeData);

      //register the language
      monaco.languages.register({ id: "typescriptreact" });
      monaco.languages.register({ id: "javascriptreact" });

      // Register language definition and configuration
      monaco.languages.setMonarchTokensProvider("typescriptreact", typescriptreactDef);
      monaco.languages.setMonarchTokensProvider("javascriptreact", javascriptreactDef);
      monaco.languages.setLanguageConfiguration("typescriptreact", typescriptreactConf);
      monaco.languages.setLanguageConfiguration("javascriptreact", javascriptreactConf);

      monacoRef.current = monaco;
      props.beforeMount?.(monaco);
    },
  };

  React.useEffect(() => {
    return () => {
      // @see https://www.loom.com/share/8e519c0efef8440ca6dd17de4a8ea93c?sid=defe3d9b-d846-4351-9579-efc0231c6375
      if (props.skipResetTheme) {
        return;
      }
      /**
       * DO NOT REMOVE
       * "@monaco-editor/react"用のthemeをアンマウント時にリセットする
       */
      monacoRef.current?.editor.defineTheme(theme, {
        base: "vs-dark",
        inherit: false,
        rules: [],
        colors: {},
      });
    };
  }, [props.skipResetTheme, theme]);

  return <Editor {...editorProps} />;
};

SwappableCodeEditor.displayName = "SwappableCodeEditor";

export default SwappableCodeEditor;
