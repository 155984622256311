import * as Types from "./types";

export const generateDisplayFilename = (fileDiff: Types.ProjectDiff): string => {
  if (fileDiff.operation === "RENAME") {
    return `${fileDiff.fromPath} → ${fileDiff.toPath}`;
  }
  if (fileDiff.toPath !== "") {
    return fileDiff.toPath;
  }
  return fileDiff.fromPath;
};
