import { useTranslation } from "@hireroo/i18n";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

import StartInterviewDialog, {
  StartInterviewDialogProps,
} from "../../../RemoteInterviewList/parts/RemoteInterviewListTable/parts/RemoteInterviewListTableRow/parts/StartInterviewDialog/StartInterviewDialog";

const StyledTypography = styled(Typography)(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxHeight: "48px",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  "&:hover": {
    cursor: "pointer",
    textDecoration: "underline",
  },
}));

const WrapperStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  width: "100%",
  height: "100%",
  borderRadius: "12px",
  backgroundColor: theme.palette.background.paper,
}));

export type ItemCardProps = {
  id: string;
  title: {
    text: string;
    onClick: () => void;
  };
  candidateName: string;
  startDialog?: Omit<StartInterviewDialogProps, "title">;
};

const ItemCard: React.FC<ItemCardProps> = props => {
  const { t } = useTranslation();
  const startDialog: StartInterviewDialogProps | undefined = props.startDialog
    ? {
        ...props.startDialog,
        button: {
          ...props.startDialog.button,
          startIcon: "PLAY_ARROW",
        },
        title: props.title.text,
      }
    : undefined;

  return (
    <WrapperStack direction="column" width="100%" spacing={2} justifyContent="space-between">
      <Stack direction="row" width="100%" justifyContent="space-between">
        <Stack direction="column" width="100%" spacing={0.5}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Tooltip title={props.title.text}>
              <StyledTypography fontSize={16} mb={0.5} fontWeight={700} maxWidth="50%" onClick={props.title.onClick}>
                {props.title.text}
              </StyledTypography>
            </Tooltip>
            <Stack direction="row" spacing={1.5} height="24px">
              {startDialog && <StartInterviewDialog {...startDialog} />}
            </Stack>
          </Stack>
          <Typography fontSize={12} color="textSecondary">
            {`${t("候補者名")}: ${props.candidateName}`}
          </Typography>
        </Stack>
      </Stack>
    </WrapperStack>
  );
};

ItemCard.displayName = "ItemCard";

export default ItemCard;
