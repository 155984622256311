import { Auth } from "@hireroo/app-store/essential/employee";
import { AssessmentResourceEditor } from "@hireroo/app-store/widget/e/AssessmentResourceEditor";
import { Widget } from "@hireroo/presentation";
import * as React from "react";

import ViewerAssignFieldContainer from "../ViewerAssignField/Container";

export type GenerateReportSetupSectionArgs = {
  mode: Widget.AssessmentResourceEditorV2Props["mode"];
  defaultValues?: Widget.AssessmentResourceEditorV2Props["reportSetupSection"]["defaultValues"];
};

export const useGenerateReportSetupSectionProps = (
  args: GenerateReportSetupSectionArgs,
): Widget.AssessmentResourceEditorV2Props["reportSetupSection"] => {
  const currentUid = Auth.useCurrentUid();
  return {
    onSubmit: fields => {
      AssessmentResourceEditor.setSubmitValue("REPORT_SETUP", fields);
    },
    defaultValues: args.defaultValues || {
      memo: "",
      isPublic: true,
      editorUid: currentUid,
      viewers: [],
      reportSettings: {
        showAnswer: true,
        showPlayback: true,
        showRelativeEvaluation: true,
      },
    },
    ViewerField: <ViewerAssignFieldContainer name="viewers" variant="OUTLINED" />,
  };
};
