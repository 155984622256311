import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    paddingX: theme.spacing(3),
    paddingY: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export type TestCaseDialogProps = {
  open: boolean;
  title: string;
  description: string;
  onClose: () => void;
  children?: React.ReactNode;
};

const TestCaseDialog: React.FC<TestCaseDialogProps> = props => {
  const theme = useTheme();
  const { onClose, open } = props;
  return (
    <BootstrapDialog onClose={props.onClose} open={open} maxWidth="md">
      <DialogTitle fontSize={theme.typography.subtitle1.fontSize} sx={{ m: 0, px: 2, py: 1.5 }}>
        {props.title}
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{ fontSize: "20px" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{props.description}</DialogContentText>
        {props.children}
      </DialogContent>
    </BootstrapDialog>
  );
};

TestCaseDialog.displayName = "TestCaseDialog";

export default TestCaseDialog;
