import { useTranslation } from "@hireroo/i18n";
import Box, { BoxProps } from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledTypography = styled(Typography)(() => ({
  fontWeight: "bold",
  marginBottom: 24,
}));

const StyledTable = styled(Table)(() => ({
  height: "fit-content",
}));

const StyledTableRow = styled(TableRow)(() => ({
  display: "table-row",
  "&:last-child td": {
    borderBottom: "none",
  },
}));

const StyledList = styled(List)(() => ({
  padding: 0,
  ".MuiListItem-root": {
    paddingTop: 6,
    paddingBottom: 6,
  },
}));

const FieldLabelTableCell = styled(TableCell)(({ theme }) => ({
  width: 190,
  fontSize: 14,
  textAlign: "left",
  borderBottom: "1px solid",
  wordBreak: "keep-all",
  whiteSpace: "nowrap",
  fontWeight: 700,
  color: theme.palette.text.secondary,
  borderColor: theme.palette.divider,
  "&.MuiTableCell-root": {
    paddingTop: 6,
    paddingBottom: 6,
  },
}));

const FieldContentTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 14,
  borderBottom: "1px solid",
  borderColor: theme.palette.divider,
  "&.MuiTableCell-root": {
    padding: 0,
  },
}));

const StyledListItemText = styled(ListItemText)`
  .MuiListItemText-primary {
    font-size: 14px;
    word-break: break-all;
  }
  .MuiListItemText-secondary {
    font-size: 12px;
  }
`;

type Item = {
  title: string;
  description?: string;
  subDescription?: string;
};

export type ResourceEditorConfirmSubsectionProps = {
  title: string;
  items: Item[];
  p?: BoxProps["p"];
  titleSize?: number;
};

const ResourceEditorConfirmSubsection: React.FC<ResourceEditorConfirmSubsectionProps> = props => {
  const { t } = useTranslation();
  return (
    <Box p={props.p ?? 3}>
      <StyledTypography fontSize={props.titleSize ?? 16}>{props.title}</StyledTypography>
      <TableContainer>
        <StyledTable>
          <TableBody>
            {props.items.map((item, index) => (
              <StyledTableRow key={`item-${index}`}>
                <FieldLabelTableCell align="left">{item.title}</FieldLabelTableCell>
                <FieldContentTableCell align="left">
                  <StyledList>
                    <ListItem>
                      <StyledListItemText
                        primary={item.description === undefined ? t("未入力") : item.description}
                        secondary={item.subDescription}
                      />
                    </ListItem>
                  </StyledList>
                </FieldContentTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </StyledTable>
      </TableContainer>
    </Box>
  );
};

ResourceEditorConfirmSubsection.displayName = "ResourceEditorConfirmSubsection";

export default ResourceEditorConfirmSubsection;
