import { useSnapshot } from "valtio";

import { state } from "./State";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useRunTutorial = () => {
  const snapshot = useSnapshotState();
  return snapshot.runTutorial;
};

export const useCurrentKind = () => {
  const snapshot = useSnapshotState();
  return snapshot.kind;
};
