import { Widget } from "@hireroo/presentation/legacy";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GeneratePageErrorPanelPropsArgs, useGenerateProps } from "./useGenerateProps";

export type PageErrorPanelContainerProps = GeneratePageErrorPanelPropsArgs;

const PageErrorPanelContainer: React.FC<PageErrorPanelContainerProps> = props => {
  const errorPanelProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ErrorPanel {...errorPanelProps} />
    </ErrorBoundary>
  );
};

PageErrorPanelContainer.displayName = "PageErrorPanelContainer";

export default withErrorBoundary(PageErrorPanelContainer, {});
