import { DashboardScreeningsStatsSection } from "@hireroo/app-store/widget/e/DashboardScreeningsStatsSection";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as React from "react";

type Option = Widget.DashboardScreeningStatsSectionProps["testSelector"]["options"][0];
export type GenerateDashboardScreeningStatsSectionPropsArgs = {
  selectedScreeningId: string;
};

export const useGenerateProps = (args: GenerateDashboardScreeningStatsSectionPropsArgs): Widget.DashboardScreeningStatsSectionProps => {
  const { t } = useTranslation();
  const navigate = useTransitionNavigate();
  const screenings = DashboardScreeningsStatsSection.useScreenings();
  const companySpotStats = DashboardScreeningsStatsSection.useCompanySpotStats();
  const selectedScreening = DashboardScreeningsStatsSection.useSelectedScreening();
  const selectedTotalSpotCount = DashboardScreeningsStatsSection.useSelectedTotalSpotCount();
  const companyReducedHours = DashboardScreeningsStatsSection.useCompanyReducedHours();
  const selectedScreeningReducedHours = DashboardScreeningsStatsSection.useSelectedScreeningReducedHours();
  const options = React.useMemo((): Option[] => {
    return screenings.map(
      (screening): Option => ({
        value: screening.screeningId,
        displayName: screening.name,
      }),
    );
  }, [screenings]);

  const evaluateProps = React.useMemo((): Widget.DashboardScreeningStatsSectionProps["reducedTimeMessage"]["evaluate"] => {
    if (selectedScreening?.spotStatistics?.spotCountByResult && selectedScreening.spotStatistics.spotCountByResult.notEvaluated > 0) {
      return {
        nonEvaluatedNum: selectedScreening.spotStatistics.spotCountByResult.notEvaluated,
        jumpButton: {
          onClick: () => {
            navigate("/e/screenings/:id/detail", {
              pathParams: {
                id: selectedScreening.screeningId,
              },
              queryParams: {
                tab: "CANDIDATES",
                status: "FINALIZED",
              },
            });
          },
        },
      };
    }
    if (companySpotStats.spotCountByResult && companySpotStats.spotCountByResult.notEvaluated > 0) {
      return {
        nonEvaluatedNum: companySpotStats.spotCountByResult.notEvaluated,
        jumpButton: {
          onClick: () => {
            navigate("/e/screenings/tests", {
              queryParams: {
                status: "FINALIZED",
              },
            });
          },
        },
      };
    }
    return undefined;
  }, [companySpotStats.spotCountByResult, navigate, selectedScreening.screeningId, selectedScreening?.spotStatistics?.spotCountByResult]);

  return {
    hideButton: {
      // TODO: implement hide button
      onClick: () => {},
    },
    testSelector: {
      options: options,
      value: args.selectedScreeningId,
      onChange: event => {
        DashboardScreeningsStatsSection.setSelectedId(event.target.value);
      },
    },
    passedLine: {
      content:
        typeof selectedScreening?.spotStatistics?.averagePassedCustomScoreFormatted === "number" &&
        selectedScreening.spotStatistics.spotCountByStatus &&
        selectedScreening.spotStatistics.spotCountByStatus.evaluated > 0
          ? {
              type: "NUMBER",
              value: selectedScreening.spotStatistics.averagePassedCustomScoreFormatted,
              unit: "%",
            }
          : {
              type: "NOT_ENOUGH",
            },
    },
    testCreated: {
      content:
        selectedTotalSpotCount && selectedTotalSpotCount > 0
          ? {
              type: "NUMBER",
              value: selectedTotalSpotCount,
              unit: t("件"),
            }
          : {
              type: "NOT_ENOUGH",
            },
    },
    reducedTimeMessage: {
      reducedTotalHours: companyReducedHours,
      reducedTestHours: selectedScreeningReducedHours,
      evaluate: evaluateProps,
    },
  };
};
