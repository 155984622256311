import { useTranslation } from "@hireroo/i18n";
import { TechnicalCommentEvaluationForm } from "@hireroo/validator";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useFieldArray } from "react-hook-form";

import MetricItem, { MetricItemProps } from "../MetricItem/MetricItem";

type Field = {
  items: (TechnicalCommentEvaluationForm.TechnicalCommentEvaluationListFormSchema["items"][0] & { id?: number })[];
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-root": {
    borderColor: theme.palette["Secondary/Shades"].p16,
  },
}));

type MetricId = number;

export type MetricsListProps = {
  metricItems: Record<MetricId, { title: string; description: string }>;
};

const MetricsList: React.FC<MetricsListProps> = props => {
  const { t } = useTranslation();
  const fieldArray = useFieldArray<Field>({
    name: "items",
  });
  const MetricItems = fieldArray.fields.map((field, index) => {
    const target = field.metricId in props.metricItems ? props.metricItems[field.metricId] : undefined;
    const metricItemProps: MetricItemProps = {
      selected: field.selected,
      title: target?.title ?? "",
      description: target?.description ?? "",
      onClick: () => {
        fieldArray.update(index, {
          ...field,
          selected: !field.selected,
        });
      },
    };
    return <MetricItem key={field.id} {...metricItemProps} />;
  });
  if (fieldArray.fields.length === 0) {
    return (
      <Typography variant="body2" color="textSecondary" sx={{ textAlign: "center" }}>
        {t("検索条件に該当する評価指標が存在しません。")}
      </Typography>
    );
  }
  return (
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <StyledTableCell align="left" width={80} sx={{ wordBreak: "keep-all" }}>
            {t("項目名")}
          </StyledTableCell>
          <StyledTableCell width={200} />
          <StyledTableCell align="left">{t("説明")}</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>{MetricItems}</TableBody>
    </Table>
  );
};

MetricsList.displayName = "MetricsList";

export default MetricsList;
