import Typography, { TypographyProps } from "@mui/material/Typography";
import * as React from "react";

const useListProps = (props: TypographyProps): Pick<TypographyProps, "variant" | "lineHeight" | "marginTop"> => {
  const newProps: TypographyProps = {
    ...props,
    marginTop: 0,
    marginBottom: "16px",
    lineHeight: 1.5,
  };

  switch (props.variant) {
    case "body2":
      newProps.variant = "body2";
      newProps.lineHeight = 1.45;
      break;
    default:
      newProps.variant = "body1";
  }

  return newProps;
};

export type ListProps = TypographyProps & {
  children: React.ReactNode[];
};

const List: React.FC<ListProps> = props => {
  const { children } = props;
  const listProps = useListProps(props);
  return (
    <Typography {...listProps} component="ul">
      {children}
    </Typography>
  );
};

List.displayName = "List";

export default List;
