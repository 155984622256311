import Editor, { SwappableCodeEditorProps as EditorProps } from "@hireroo/code-editor/react/SwappableCodeEditor";
import StyledWrapper from "@hireroo/code-editor/react/v2/StyledWrapper";
import { useTranslation } from "@hireroo/i18n";
import { useMethods, withSplitProvider } from "@hireroo/react-split";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Drawer, { DrawerProps } from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import type * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";

import AppealMessageInReport, { AppealMessageInReportProps } from "../../../../../../modules/AppealMessageInReport/AppealMessageInReport";
import PlaybackToolbar, { PlaybackToolbarProps } from "../../../../../../modules/PlaybackToolbar/PlaybackToolbar";
import Split, { SplitProps } from "../../../../../../primitive/Split/Split";

const DEFAULT_RIGHT_SIDE_PANEL_WIDTH = 400;
const HEADER_HEIGHT = 48;
const TOOLBAR_HEIGHT = 40;
const DRAWER_WIDTH = 320;

const Wrapper = styled(Box)(({ theme }) => ({
  overflowY: "auto",
  overflowX: "hidden",
  maxHeight: "100vh",
  height: "100vh",
  backgroundColor: theme.palette.background.default,
}));

const ColumnWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  height: "100vh",
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  transition: "width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
  height: "100vh",
  "& .MuiDrawer-paper": {
    position: "relative",
    height: "100vh",
    width: DRAWER_WIDTH,
    boxSizing: "border-box",
    borderColor: theme.palette.Other.WeakDivider,
  },
}));

const WrapperStack = styled(Stack)(() => ({
  position: "relative",
  height: "100vh",
  display: "flex",
  transition: "width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
}));

const Header = styled(Paper)(() => ({
  padding: `8px 16px`,
  height: HEADER_HEIGHT,
  width: "100%",
  boxShadow: "none",
  borderRadius: 0,
}));

const BottomSection = styled(Stack)(({ theme }) => ({
  maxWidth: 1232,
  width: "100%",
  margin: "0 auto",
  padding: theme.spacing(3),
}));

const StatisticsContentWrapper = styled(Box)(() => ({
  minHeight: "80vh",
  width: "100%",
}));

const HeaderNavigationButton = styled(Button)(({ theme }) => ({
  padding: "4px 10px",
  fontSize: 13,
  height: "30px",
  wordBreak: "keep-all",
  borderColor: theme.palette.Other.WeakDivider,
  textTransform: "none",
}));

const HeaderContentStack = styled(Stack)(() => ({
  width: "fit-content",
}));

const StyledSplit = styled(Split)(() => ({
  height: "100%",
  flexGrow: 1,
}));

const SplitParams = {
  splitId: "ReportChallengePlaybackSplit",
  Contents: {
    CodeEditor: "CodeEditor",
    RightSidePanel: "RightSidePanel",
  },
};

export type ChallengeDetailPlaybackProps = {
  showRightSidePanel: boolean;
  ActivityTimelineLog?: React.ReactNode;
  model?: monaco.editor.ITextModel | null;
  onEditorMount?: EditorProps["onMount"];
  StatisticsContents: React.ReactNode[];
  RightSidePanel: React.ReactNode;
  showPasteRange: boolean;
  toolbar: Omit<PlaybackToolbarProps, "screenButton" | "screenStatus" | "enableAutoplay" | "onChangePlaybackValue">;
  appealMessageInReport: AppealMessageInReportProps;
  onClose?: () => void;
};

const ChallengeDetailPlayback: React.FC<ChallengeDetailPlaybackProps> = props => {
  const { t } = useTranslation();
  const { toolbar, onClose, onEditorMount, showRightSidePanel } = props;
  const methods = useMethods();
  const detector = useResizeDetector();
  const [open, setOpen] = React.useState(true);
  const element = React.createRef<HTMLDivElement>();
  const contentHeightBufferSize = TOOLBAR_HEIGHT + HEADER_HEIGHT;
  const contentHeight = (detector.height ?? 0) - contentHeightBufferSize;
  const mounted = React.useRef(false);

  const codeEditor = React.useMemo((): EditorProps | undefined => {
    if (!props.model) {
      return;
    }
    return {
      theme: "monokai",
      options: {
        fontSize: 13,
        readOnly: true,
        model: props.model,
      },
      keepCurrentModel: true,
    };
  }, [props.model]);

  const headerNavigationButtonList: ButtonProps[] = [
    {
      children: t("詳細情報"),
      onClick: () => {
        setOpen(prev => !prev);
      },
    },
    {
      children: t("数値データ"),
      onClick: () => {
        element.current?.scrollIntoView({ behavior: "smooth" });
      },
    },
  ];

  const splitItems: SplitProps["items"] = [
    {
      id: SplitParams.Contents.CodeEditor,
      Content: (
        <Box height="100%" width="100%">
          <StyledWrapper theme={props.showPasteRange ? "RED" : "TRANSPARENT"}>
            {codeEditor && (
              <React.Suspense>
                {/* Do not move from here because unmount will run if you put the height into useMemo. */}
                <Editor {...codeEditor} onMount={onEditorMount} height={contentHeight} skipResetTheme />
              </React.Suspense>
            )}
          </StyledWrapper>
        </Box>
      ),
      size: {
        value: 100,
        unit: "%",
      },
      minSize: {
        value: 20,
        unit: "%",
      },
    },
  ];

  if (props.RightSidePanel) {
    splitItems.push({
      id: SplitParams.Contents.RightSidePanel,
      Content: (
        <Box height={`calc(100vh - ${contentHeightBufferSize}px);`} width="100%">
          {props.RightSidePanel}
        </Box>
      ),
      size: {
        value: DEFAULT_RIGHT_SIDE_PANEL_WIDTH,
        unit: "px",
      },
      minimizedSize: {
        value: 0,
        unit: "px",
      },
      expandedSize: {
        value: DEFAULT_RIGHT_SIDE_PANEL_WIDTH,
        unit: "px",
      },
    });
  }

  const splitProps: SplitProps = {
    splitId: SplitParams.splitId,
    splitDirection: "VERTICAL",
    items: splitItems,
  };

  const playbackToolbar = React.useMemo((): Omit<PlaybackToolbarProps, "enableAutoplay"> => {
    return {
      ...toolbar,
      slider: {
        ...toolbar.slider,
        min: 0,
      },
      screenButton: {
        onClick: () => {
          onClose?.();
        },
      },
      screenStatus: "EXPANDED",
    };
  }, [toolbar, onClose]);

  const drawerProps: DrawerProps = {
    open,
    variant: "persistent",
    anchor: "left",
    sx: {
      width: open ? DRAWER_WIDTH : 0,
    },
  };

  React.useEffect(() => {
    /** Prevent expand/minimize from being executed before mount */
    if (!mounted.current) {
      mounted.current = true;
      return;
    }
    if (!props.RightSidePanel) {
      return;
    }
    if (showRightSidePanel) {
      methods.expand(SplitParams.splitId, SplitParams.Contents.RightSidePanel);
    } else {
      methods.minimize(SplitParams.splitId, SplitParams.Contents.RightSidePanel, { freezeContentSize: true });
    }
  }, [methods, showRightSidePanel, props.RightSidePanel]);

  return (
    <Wrapper>
      <ColumnWrapper>
        <StyledDrawer {...drawerProps}>{props.ActivityTimelineLog}</StyledDrawer>
        <WrapperStack direction="column" spacing={0} ref={detector.ref} width={`calc(100vw - ${open ? DRAWER_WIDTH : 0}px)`}>
          <Header>
            <HeaderContentStack direction="row" spacing={1.5}>
              {headerNavigationButtonList.map((buttonProps, index) => {
                return <HeaderNavigationButton key={`header-button-${index}`} {...buttonProps} variant="outlined" color="outline-only" />;
              })}
            </HeaderContentStack>
          </Header>
          <StyledSplit {...splitProps} />
          <PlaybackToolbar {...playbackToolbar} enableAutoplay />
        </WrapperStack>
      </ColumnWrapper>
      <BottomSection direction="column" spacing={2}>
        <AppealMessageInReport {...props.appealMessageInReport} />
        <StatisticsContentWrapper ref={element}>
          <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
            {props.StatisticsContents.map((Content, index) => {
              return (
                <Grid item xs={2} sm={4} md={4} key={index}>
                  {Content}
                </Grid>
              );
            })}
          </Grid>
        </StatisticsContentWrapper>
      </BottomSection>
    </Wrapper>
  );
};

ChallengeDetailPlayback.displayName = "ChallengeDetailPlayback";

export default withSplitProvider(ChallengeDetailPlayback);
