import { useTranslation } from "@hireroo/i18n";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent, SelectProps } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import type { ComponentType } from "../../../features";
import { isValidComponentType } from "../../../helpers/flowChart";
import { useSystemDesignContext } from "../../../store";

const StyledSelect = styled(Select)(({ theme }) => ({
  height: 32,
  fontSize: theme.typography.body2.fontSize,
  alignItems: "center",
  ".MuiSelect-select": {
    paddingTop: 0,
    paddingBottom: 0,
    lineHeight: "1.25em",
  },
  ".MuiOutlinedInput-notchedOutline": {
    border: "1px solid transparent",
  },
})) as React.FC<SelectProps<string>>;

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&.MuiMenuItem-root": {
    "&:hover ": {
      backgroundColor: theme.palette["Secondary/Shades"].p8,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette["Secondary/Shades"].p16,
    },
  },
}));

export type ComponentTypeSelectorProps = {
  onChange?: (componentType: ComponentType) => void;
};

const useComponentTypeMap = (): Record<string, string> => {
  const { t } = useTranslation();
  const store = useSystemDesignContext();
  const selectableComponentTypes = store.hooks.useSelectableComponentTypes();
  const componentTypeMap: Record<ComponentType, string> = {
    default: t("デフォルト"),
    aws: "AWS",
    gcp: "GCP",
    azure: "Azure",
  };
  return Array.from(selectableComponentTypes).reduce((all, key) => {
    if (!isValidComponentType(key)) {
      return all;
    }
    return { ...all, [key]: componentTypeMap[key] };
  }, {});
};

const ComponentTypeSelector: React.FC<ComponentTypeSelectorProps> = props => {
  const { onChange } = props;
  const store = useSystemDesignContext();
  const value = store.hooks.useComponentType();
  const componentTypeMap = useComponentTypeMap();
  const { t } = useTranslation();
  const handleSelectEvent = React.useCallback(
    (event: SelectChangeEvent) => {
      if (isValidComponentType(event.target.value)) {
        store.action.updateComponentType(event.target.value);
        onChange?.(event.target.value);
      }
    },
    [store.action, onChange],
  );

  const selectProps: SelectProps<string> = {
    /* The className is used for tutorial. */
    className: "component-type-selector",
    value,
    onChange: handleSelectEvent,
    color: "outline-only",
    IconComponent: KeyboardArrowDownOutlinedIcon,
  };
  if (Object.keys(componentTypeMap).length === 0) {
    return (
      <Stack direction="column" alignItems="center" justifyContent="center">
        <Typography variant="body2">{t("使用可能なリソース種別がありませんでした。")}</Typography>
        <Typography variant="caption">{t("ヘルプより運営にお問い合わせください。")}</Typography>
      </Stack>
    );
  }

  return (
    <FormControl>
      <StyledSelect {...selectProps}>
        {Object.entries(componentTypeMap).map(([componentType, componentTypeValue]) => (
          <StyledMenuItem key={componentType} value={componentType}>
            {componentTypeValue}
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

ComponentTypeSelector.displayName = "ComponentTypeSelector";

export default ComponentTypeSelector;
