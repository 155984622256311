const exampleMap: Record<string, string> = {
  string: '"hello world!"',
  int: "123",
  float: "1.23",
  bool: "true",
  sll: "[1,2,3]",
  bt: "[1,null,3]",
};

export const generatePlaceholder = (t: string): string => {
  t = t.trim();
  if (t.startsWith("array") || t.startsWith("map")) {
    const s = t.indexOf("[");
    const e = t.lastIndexOf("]");
    if (t.startsWith("array")) {
      return `[${Array(3)
        .fill(generatePlaceholder(t.substring(s + 1, e)))
        .join(", ")}]`;
    } else {
      const ts = t.substring(s + 1, e).split(",");
      return `{${generatePlaceholder(ts[0])}: ${generatePlaceholder(ts[1])}`;
    }
  } else {
    return exampleMap[t];
  }
};

export const generateDBPlaceholder = (t: string): string => {
  if (t === "string") return "hello";

  return exampleMap[t];
};
