import { componentTypeGraphqlToLowerComponentType } from "@hireroo/app-helper/system-design";
import { SystemDesignCodingEditor } from "@hireroo/app-store/widget/shared/SystemDesignCodingEditor";
import type { SystemDesignProviderProps } from "@hireroo/system-design/react/FlowChart";

export type GenerateSystemDesignProviderPropsArgs = {
  entityId: number;
};

export const useGenerateSystemDesignProviderProps = (args: GenerateSystemDesignProviderPropsArgs): SystemDesignProviderProps => {
  const { useEntity } = SystemDesignCodingEditor.useCreateSystemDesignEntityHooks(args.entityId);
  const entity = useEntity();
  const { submissions, candidateId, companyId, componentTypes, employeeId, systemDesignEntityId } = entity;

  return {
    initialState: {
      selectableComponentTypes: new Set(componentTypes.map(componentType => componentTypeGraphqlToLowerComponentType[componentType])),
      systemDesigns: {
        [args.entityId]: {
          id: systemDesignEntityId,
          companyId: companyId,
          candidateId: candidateId,
          employeeId: employeeId,
          submissionsList: submissions.map(submission => submission.systemDesignSubmissionId),
          totalScore: 0,
          componentTypesList: [...componentTypes],
        },
      },
    },
  };
};
