import React from "react";
import { RemoteAudioTrack, Track } from "twilio-video";

import { TrackEnabledSubscriber } from "./TrackEnabledSubscriber";

/**
 * This hook allows components to reliably use the 'isEnabled' property of the RemoteAudioTrack object.
 */
export const useIsRemoteAudioTracksEnabled = (tracks: RemoteAudioTrack[]) => {
  const subscriberRef = React.useRef<Record<Track.SID, TrackEnabledSubscriber>>({});
  const [enabledList, setEnabledList] = React.useState<Record<Track.SID, boolean>>({});

  React.useEffect(() => {
    tracks.forEach(track => {
      // initialize
      setEnabledList(prevState => {
        return {
          ...prevState,
          [track.sid]: track.isEnabled,
        };
      });
    });
    // FIXME: deps only participant length. If you add participants, "Maximum update depth exceeded" will occur
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tracks.length]);

  React.useEffect(() => {
    const subscriber = subscriberRef.current;

    tracks.forEach(track => {
      const handleEnabled = () => {
        setEnabledList(prevState => {
          return {
            ...prevState,
            [track.sid]: true,
          };
        });
      };
      const handleDisabled = () => {
        setEnabledList(prevState => {
          return {
            ...prevState,
            [track.sid]: false,
          };
        });
      };
      subscriber[track.sid] = new TrackEnabledSubscriber(track, handleEnabled, handleDisabled);
    });

    return () => {
      tracks.forEach(track => {
        subscriber[track.sid].unsubscribe();
      });
    };
  }, [tracks]);

  return enabledList;
};
