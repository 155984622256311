import { state } from "./State";
import type * as Types from "./types";

export const addTalents = (talents: Types.Talent[]): void => {
  talents.forEach(talent => {
    state.talents.set(talent.uid, talent);
  });
};

export const clear = (): void => {
  state.talents.clear();
};
