import * as React from "react";

import type * as Types from "../types";
import { usePaneController } from "./usePaneController";

export type PaneProps = {
  paneId: Types.PaneId;
  className?: string;
  draggingClassName?: string;
  direction: Types.SplitDirection;
  size: number;
};

const Pane: React.FC<PaneProps> = props => {
  const paneRef = React.useRef<HTMLDivElement>(null);
  const cleanup = React.useRef<() => void>(() => undefined);
  const methods = usePaneController({
    paneId: props.paneId,
    draggingClassName: props.draggingClassName,
  });
  const VerticalCssProperties: React.CSSProperties = {
    width: `${props.size}px`,
  };
  const HorizontalCssProperties: React.CSSProperties = {
    height: `${props.size}px`,
  };
  const StyleMap: Record<Types.SplitDirection, React.CSSProperties> = {
    HORIZONTAL: HorizontalCssProperties,
    VERTICAL: VerticalCssProperties,
  };

  React.useEffect(() => {
    if (paneRef.current) {
      cleanup.current = methods.startSubscribe(paneRef.current);
    }
    return () => {
      cleanup.current();
    };
  }, [methods]);

  const divProps: React.HTMLAttributes<HTMLDivElement> = {
    style: StyleMap[props.direction],
    className: props.className,
  };
  return <div {...divProps} ref={paneRef} data-direction={props.direction.toLowerCase()} />;
};

Pane.displayName = "Pane";

export default React.memo(Pane);
