import { SupportLanguageValue } from "@hireroo/i18n";

import { state } from "./State";
import type * as Types from "./types";

export const initialize = (lang: SupportLanguageValue, initialVariant?: Types.Variant): void => {
  state.activeStep = 0;
  state.variant = initialVariant ?? "ALGORITHM";
  state.initialLanguage = lang;
};

export const updateActiveStep = (newValue: number): void => {
  state.activeStep = newValue;
};

export const clear = (): void => {
  state.activeStep = 0;
  state.question = undefined;
};
export const goPrevious = () => {
  if (state.activeStep <= 0) {
    state.activeStep = 0;
  }
  state.activeStep -= 1;
};

export const initializeQuestion = (question: Types.Question) => {
  state.question = question;
};

export const updateQuestion = (question: Types.Question) => {
  state.question = {
    ...state.question,
    ...question,
  };
};

export const updateVariant = (newValue: Types.Variant) => {
  state.variant = newValue;
};

export const updateSaveStatus = (newValue: Types.SaveStatus) => {
  state.saveStatus = newValue;
};
export const updateTemplateCode = (newValue: Types.TemplateCode) => {
  state.templateCode = newValue;
};
