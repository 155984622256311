import CodeEditor, { CodeEditorProps } from "@hireroo/code-editor/react/CodeEditor";
import * as React from "react";

export type ChallengePlaybackEditorProps = CodeEditorProps;

const ChallengePlaybackEditor: React.FC<ChallengePlaybackEditorProps> = props => {
  return (
    <React.Suspense>
      <CodeEditor width="100%" theme="hirerooDark" {...props} />
    </React.Suspense>
  );
};

ChallengePlaybackEditor.displayName = "ChallengePlaybackEditor";

export default ChallengePlaybackEditor;
