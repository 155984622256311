import { AlgorithmSignatureForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import * as React from "react";
import { useForm, UseFormReturn } from "react-hook-form";

export type ContextValue = {
  methods: UseFormReturn<AlgorithmSignatureForm.DatabaseSignatureSchemaType>;
};

export const DatabaseTableSignatureContext = React.createContext<ContextValue>({} as ContextValue);

export const useDatabaseTableSignatureContext = () => React.useContext(DatabaseTableSignatureContext);

export type DatabaseTableSignatureProviderProps = {
  defaultValues: AlgorithmSignatureForm.DatabaseSignatureSchemaType;
};

export const DatabaseTableSignatureProvider: React.FC<React.PropsWithChildren<DatabaseTableSignatureProviderProps>> = props => {
  const { defaultValues } = props;
  const schema = AlgorithmSignatureForm.useDatabaseSignatureSchema();
  const methods = useForm<AlgorithmSignatureForm.DatabaseSignatureSchemaType>({
    resolver: zodResolver(schema),
    mode: "onSubmit",
    shouldUnregister: false,
    defaultValues,
  });

  const contextValue: ContextValue = {
    methods: methods,
  };
  return <DatabaseTableSignatureContext.Provider value={contextValue} children={props.children} />;
};
