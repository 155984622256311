import { App, Company } from "@hireroo/app-store/essential/employee";
import { ScreeningsTestsStore } from "@hireroo/app-store/page/e/screenings_tests";
import * as Graphql from "@hireroo/graphql/client/urql";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import ErrorPanelContainer from "../../../../widget/v2/e/ErrorPanel/Container";
import ScreeningTestListContainer, { ScreeningTestListContainerProps } from "./Container";
import { useQueryParams } from "./privateHelper";

export type ScreeningTestListFetchContainerProps = {};

const ScreeningTestListFetchContainer: React.FC<ScreeningTestListFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const activeCompanyId = Company.useStrictActiveCompanyId();
  const queryParams = useQueryParams();
  const [result] = Graphql.useGetInterviewsQuery({
    variables: {
      spotTagsByCompanyId: {
        companyId: activeCompanyId,
        size: 100,
        withCount: true,
      },
      screeningVariablesByCompanyId: {
        companyId: activeCompanyId,
        size: 100,
        withCount: true,
      },
      issuers: {
        creator: true,
        reviewer: false,
        ignoreError: true,
        companyId: activeCompanyId,
        screening: true,
      },
    },
    requestPolicy: "cache-and-network",
    pause: appStatus !== "INITIALIZED",
  });
  React.useEffect(() => {
    ScreeningsTestsStore.setQueryParams(queryParams);
    if (result.data && result.data.issuers && result.data.spotTagsByCompanyId && result.data.screeningVariablesByCompanyId) {
      ScreeningsTestsStore.initialize({
        searchTags: [],
        creators: result.data.issuers.creators,
        spotTags: result.data.spotTagsByCompanyId,
        screeningVariables: result.data.screeningVariablesByCompanyId,
        queryParams: queryParams,
        screenings: result.data.issuers.screenings,
      });
    }
  }, [queryParams, result.data]);

  /** Clean Up */
  React.useEffect(() => {
    return () => {
      ScreeningsTestsStore.clear();
    };
  }, []);

  if (result.error) {
    return <ErrorPanelContainer graphqlErrors={result.error.graphQLErrors} />;
  }

  const containerProps: ScreeningTestListContainerProps = {};
  return <ScreeningTestListContainer {...containerProps} />;
};

ScreeningTestListFetchContainer.displayName = "ScreeningTestListFetchContainer";

export default withErrorBoundary(ScreeningTestListFetchContainer, {});
