import { state } from "./State";
import type * as Types from "./types";

export const setCustomer = (customer: Types.Customer): void => {
  state.customer = customer;
};

export const clear = (): void => {
  state.customer = null;
};
