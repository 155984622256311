import { usePlanNameMap } from "@hireroo/app-definition/payment";
import * as Graphql from "@hireroo/graphql/client/urql";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

import { useGenerateRowPropsList } from "../../../../props-factory/v2/e/PaymentContractUpdateFormProps/useGenerateRowPropsList";

export type GeneratePlanListPropsArgs = {
  plans: Graphql.PlansResponseForPaymentPlanListFragment;
  customer: Graphql.CustomerForPaymentPlanListFragment;
};

export const useGenerateProps = (args: GeneratePlanListPropsArgs): Widget.PlanListProps => {
  const planNameMap = usePlanNameMap(args.customer.subscription.plan.generation);

  const head = React.useMemo((): Widget.PlanListProps["head"] => {
    const items = args.plans.plans.map((plan): Widget.PlanListProps["head"]["items"][0] => {
      const usingPlan = args.customer.subscription.plan.planType === plan.planType;
      return {
        kind: (() => {
          if (args.customer.isTrial && usingPlan) {
            return "TRIAL";
          } else if (usingPlan) {
            return "SELECTED";
          }
          return "NONE";
        })(),
        planName: planNameMap[plan.planType],
      };
    });
    return {
      items: items,
    };
  }, [args.plans.plans, args.customer.isTrial, args.customer.subscription.plan.planType, planNameMap]);

  const rows = useGenerateRowPropsList(args.plans.plans.map(plan => plan.planType));

  return {
    head,
    rows,
  };
};
