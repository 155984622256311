import { proxyMap } from "valtio/utils";

import { getPeriodList } from "./privateHelper";
import { state } from "./State";
import type * as Types from "./types";

export const initialize = (res: Types.UsagesV2Response): void => {
  state.res = res;
  const usages = state.usagesTimestampMap.get(state.usagePeriodStartSeconds) || proxyMap<Types.UsageId, Types.SubscribeUsage>();
  res.usages.forEach(usage => {
    usages.set(usage.usageId, usage);
  });
  state.usagesTimestampMap.set(state.usagePeriodStartSeconds, usages);
  state.countTimestampMap.set(state.usagePeriodStartSeconds, res.count);
  state.pagers.set(state.usagePeriodStartSeconds, res.pager);
};

export const clear = () => {
  state.res = null;
  state.usagesTimestampMap.clear();
  state.page = 0;
  state.periods = [];
  state.usagePeriodStartSeconds = 0;
};

export const updateUsagePeriodStartSeconds = (seconds: number) => {
  state.usagePeriodStartSeconds = seconds;
  state.page = 0;
};

export const setStartSeconds = (sec: number) => {
  const startSec = sec * 1000;
  const now = new Date();
  const contractDate = new Date(startSec);
  const periods = getPeriodList(contractDate, now);
  state.periods = periods;
  updateUsagePeriodStartSeconds(periods[0].fromSeconds);
};

export const setPage = (page: number) => {
  state.page = page;
};
