import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination, { TablePaginationProps } from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import React from "react";

import InvoiceTableRow, { InvoiceTableRowProps } from "./parts/InvoiceTableRow/InvoiceTableRow";

const HeadingWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: 40,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
}));

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.text.primary,
  fontWeight: 700,
}));

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 12,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
}));

const StyledTableHeaderRow = styled(TableRow)(({ theme }) => ({
  width: "100%",
  height: 40,
  wordBreak: "keep-all",
  borderTop: `1px solid ${theme.palette["Secondary/Shades"].p12}`,
  borderBottom: `1px solid ${theme.palette["Secondary/Shades"].p12}`,
  "& .MuiTableCell-root": {
    borderColor: theme.palette["Secondary/Shades"].p12,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  width: "100%",
  height: 56,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  borderBottom: theme.palette["Secondary/Shades"].p12,
  "& .MuiTableCell-root": {
    borderColor: theme.palette["Secondary/Shades"].p12,
  },
}));

export type InvoicesProps = {
  rows: InvoiceTableRowProps[];
  pagination: Pick<TablePaginationProps, "page" | "rowsPerPage" | "count" | "onPageChange" | "rowsPerPageOptions">;
};

const Invoices: React.FC<InvoicesProps> = props => {
  const { t } = useTranslation();

  const isEmpty = props.rows.length === 0;
  const EmptyTableRow = (
    <StyledTableRow>
      {/* TODO @himenon set colSpan={7} after impl Bulk Download */}
      <TableCell colSpan={6}>
        <Typography variant="body2" sx={{ textAlign: "center", color: "text.secondary" }}>
          {t("現在、表示可能な請求書情報はありません。")}
        </Typography>
      </TableCell>
    </StyledTableRow>
  );
  return (
    <Box>
      <HeadingWrapper mb={1.5}>
        <Heading>{t("請求書一覧")}</Heading>
      </HeadingWrapper>
      <TableContainer>
        <Table>
          <TableHead>
            <StyledTableHeaderRow key={"score-table-header-row"}>
              {/* TODO @himenon impl Bulk Download */}
              {/* <StyledTableHeaderCell align="center" width={48}>
                <StyledCheckbox size="small" color="secondary" disabled={isEmpty} />
              </StyledTableHeaderCell> */}
              <StyledTableHeaderCell align="left">{t("請求日")}</StyledTableHeaderCell>
              <StyledTableHeaderCell align="left">{t("支払期日")}</StyledTableHeaderCell>
              <StyledTableHeaderCell align="left">{t("金額（税込）")}</StyledTableHeaderCell>
              <StyledTableHeaderCell align="left">{t("ステータス")}</StyledTableHeaderCell>
              <StyledTableHeaderCell align="right" />
            </StyledTableHeaderRow>
          </TableHead>
          <TableBody>
            {props.rows.map(invoiceTableRow => (
              <InvoiceTableRow key={invoiceTableRow.downloadUrl} {...invoiceTableRow} />
            ))}
            {isEmpty && EmptyTableRow}
          </TableBody>
        </Table>
        <Box display="flex" justifyContent="flex-end" mt={4}>
          <TablePagination component={"div"} {...props.pagination} />
        </Box>
      </TableContainer>
    </Box>
  );
};

Invoices.displayName = "Invoices";

export default Invoices;
