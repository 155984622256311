import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import Alert from "@mui/material/Alert";
import Button, { type ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import * as React from "react";

const StyledButton = styled(Button)(() => ({
  color: "inherit",
}));

export type AssessmentNotificationBannerProps = {
  count: number;
  confirmButton: Pick<ButtonProps, "onClick">;
};

const AssessmentNotificationBanner: React.FC<AssessmentNotificationBannerProps> = props => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const confirmButton: ButtonProps = {
    ...props.confirmButton,
    children: t("確認"),
  };
  return (
    <Alert severity="warning" variant="outlined" action={<StyledButton {...confirmButton} />}>
      {t2("notYetBeenSubmittedTalentScore", {
        num: props.count,
      })}
    </Alert>
  );
};

AssessmentNotificationBanner.displayName = "AssessmentNotificationBanner";

export default AssessmentNotificationBanner;
