import { useUserTypeTextConverter } from "@hireroo/app-helper/userType";
import { App, Auth } from "@hireroo/app-store/essential/employee";
import { Credential } from "@hireroo/app-store/essential/shared";
import { InvitationsIdStore } from "@hireroo/app-store/page/e/invitations_id";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { Pages, Widget } from "@hireroo/presentation";
import { redirect } from "@hireroo/router/api";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";
import { useParams } from "react-router-dom";

import ErrorPanelContainer from "../../../../widget/v2/e/ErrorPanel/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import InvitationContainer, { InvitationContainerProps } from "./Container";

const InvitationFetchContainer: React.FC = () => {
  const userType = Credential.useUserType();
  const currentUserId = Auth.useCurrentUid();
  const userTypeTextConverter = useUserTypeTextConverter();
  const appStatus = App.useStatus();
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const initialized = InvitationsIdStore.useInitialized();
  const { id } = useParams();
  const invitationId = id || "";
  const isExpired = InvitationsIdStore.useIsExpired();
  const alreadyMember = InvitationsIdStore.useAlreadyMember(currentUserId);
  const [result] = Graphql.useCompanyByEmployeeInvitationForEmployeeInvitationIdQuery({
    variables: {
      invitationId: invitationId,
    },
    pause: appStatus !== "INITIALIZED",
  });
  const validUserType = userType === Graphql.UserType.Employee || userType === Graphql.UserType.Unknown || userType === null;
  React.useEffect(() => {
    if (result.data) {
      InvitationsIdStore.initialize(invitationId, result.data.companyByEmployeeInvitation, result.data.employeeInvitation);
    }
  }, [result.data, invitationId]);
  React.useEffect(() => {
    if (!validUserType) {
      return;
    }
    if (alreadyMember) {
      Snackbar.notify({
        severity: "info",
        message: t("すでに会社に参加済みです。数秒後にリダイレクトします。"),
      });
      window.setTimeout(() => {
        redirect("/e/home");
      }, 1500);
    }
  }, [validUserType, alreadyMember, t]);
  if (!validUserType) {
    return (
      <ErrorPanelContainer
        title={t("異なるユーザータイプの招待を受け付けることはできません")}
        message={t2("CurrentUserTypeAndInvitedUserType", {
          invitedUserType: userTypeTextConverter(Graphql.UserType.Employee),
          currentUserType: userTypeTextConverter(userType),
        })}
      />
    );
  }
  if (alreadyMember) {
    const emptyProps: Pages.EmptyProps = {
      layout: {
        loading: false,
        Bottom: <SnackbarContainer />,
      },
    };
    return <Pages.Empty {...emptyProps} />;
  }
  if (result.error) {
    if (result.error.message.match(/has expired/)) {
      return (
        <ErrorPanelContainer
          title={t("期限の切れたリンクです。")}
          message={t("リンクの期限が切れています。管理者に新しいリンクを発行してもらう必要があります。")}
        />
      );
    }
    return <ErrorPanelContainer graphqlErrors={result.error.graphQLErrors} />;
  }
  if (!initialized || result.fetching || appStatus !== "INITIALIZED" || alreadyMember) {
    return <Widget.Loading kind="CENTER" />;
  }
  if (isExpired) {
    return (
      <ErrorPanelContainer
        title={t("期限の切れたリンクです。")}
        message={t("リンクの期限が切れています。管理者に新しいリンクを発行してもらう必要があります。")}
      />
    );
  }
  const containerProps: InvitationContainerProps = {
    invitationId: invitationId,
  };
  return (
    <ErrorBoundary>
      <InvitationContainer {...containerProps} />
    </ErrorBoundary>
  );
};

InvitationFetchContainer.displayName = "InvitationFetchContainer";

export default withErrorBoundary(InvitationFetchContainer, {});
