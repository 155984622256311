import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import Box from "@mui/material/Box";
import Button, { type ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import * as React from "react";

const IconMap: Record<string, React.ReactElement> = {
  AUTO_FIX: <AutoFixHighOutlinedIcon />,
} as const satisfies Record<string, React.ReactElement>;

const TRANSLATE_X_SIZE = 160;

const Wrapper = styled(Box)(({ theme }) => ({
  alignItems: "center",
  judgmentContent: "center",
  position: "relative",
  display: "inline-flex",
  flexDirection: "row",
  transform: `translateX(${TRANSLATE_X_SIZE}px)`,
  "&:hover": {
    "& button div": {
      opacity: 1,
      transform: "translateX(0)",
      width: "100%",
    },
  },
  borderRadius: "40px 0px 0px 40px",
  backgroundColor: theme.palette["Background/Paper"].p12,
}));
const StyledButton = styled(Button)(({ theme }) => {
  return {
    display: "flex",
    position: "relative",
    padding: theme.spacing(1, 2),
    textTransform: "none",
    borderRadius: "40px 0px 0px 40px",
    backgroundColor: theme.palette["Background/Paper"].p12,
    "&:hover": {
      transform: `translateX(-${TRANSLATE_X_SIZE}px)`,
      backgroundColor: theme.palette["Background/Paper"].p12,
    },
  };
});
const TextWrapper = styled(Box)(() => ({
  opacity: 0,
  whiteSpace: "nowrap",
  transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out",
  marginLeft: "8px",
}));

export type HoverButtonProps = Pick<ButtonProps, "onClick"> & {
  text: string;
};

const HoverButton: React.FC<HoverButtonProps> = props => {
  const button: ButtonProps = {
    ...props,
    onClick: event => {
      props.onClick?.(event);
    },
    color: "inherit",
    startIcon: IconMap.AUTO_FIX,
    children: <TextWrapper>{props.text}</TextWrapper>,
  };
  return (
    <Wrapper>
      <StyledButton {...button} />
    </Wrapper>
  );
};

HoverButton.displayName = "HoverButton";

export default HoverButton;
