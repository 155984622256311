import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Auth } from "@hireroo/app-store/essential/employee";
import { RemoteInterviewOverview } from "@hireroo/app-store/widget/e/RemoteInterviewOverview";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/react";
import React from "react";

import ViewerAssignFieldContainer from "../../../../../../widget/v2/e/ViewerAssignField/Container";

type AuthorizedMembersAndGroup = Widget.AccessPermissionDialogProps["defaultValues"]["viewers"][0];
type ViewerId = Graphql.RemoteViewerIdInput;

export type UseGeneratePermissionDialogPropsArgs = {};

export const useGenerateAccessPermissionDialogProps = (
  _args: UseGeneratePermissionDialogPropsArgs,
): Widget.RemoteInterviewOverviewProps["info"]["accessPermissionDialog"] => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const currentUid = Auth.useCurrentUid();
  const interview = RemoteInterviewOverview.useInterview();
  const [resultUpdateRemote, updateRemote] = Graphql.useUpdateRemoteForRemoteInterviewOverviewMutation();

  const defaultViewers = React.useMemo(() => {
    const viewers: AuthorizedMembersAndGroup[] = interview.viewers.reduce<AuthorizedMembersAndGroup[]>((all, viewer) => {
      switch (viewer.__typename) {
        case "User":
          return all.concat({
            value: {
              type: "EMPLOYEE",
              employeeId: viewer.uid,
              locked: viewer.uid === currentUid,
            },
          });
        case "EmployeeGroup":
          return all.concat({
            value: {
              type: "EMPLOYEE_GROUP",
              groupId: viewer.id,
            },
          });
        default:
          throw Error(`invalid viewer type ${viewer satisfies never}`);
      }
    }, []);

    if (viewers.some(viewer => viewer.value?.type === "EMPLOYEE" && viewer.value.employeeId === currentUid)) {
      return viewers;
    }

    viewers.push({
      value: {
        type: "EMPLOYEE",
        employeeId: currentUid,
        locked: true,
      },
    });
    return viewers;
  }, [interview, currentUid]);

  return {
    open: open,
    disabled: resultUpdateRemote.fetching,
    description: t("閲覧権限の適用対象はインタビュー詳細ページが対象になります。"),
    onSubmit: fields => {
      const employeeIds = fields.viewers.reduce<string[]>((all, viewer) => {
        if (viewer.value === null) {
          return all;
        }
        if (viewer.value.type === "EMPLOYEE") {
          return all.concat(viewer.value.employeeId);
        }
        return all;
      }, []);

      const groupIds = fields.viewers.reduce<string[]>((all, viewer) => {
        if (viewer.value === null) {
          return all;
        }
        if (viewer.value.type === "EMPLOYEE_GROUP") {
          return all.concat(viewer.value.groupId);
        }
        return all;
      }, []);

      const viewerInputEmployees = (employeeIds ?? []).map((employeeId): ViewerId => {
        return {
          employeeId: employeeId,
          type: "EMPLOYEE",
        };
      });
      const viewerInputGroups = (groupIds ?? []).map((groupId): ViewerId => {
        return {
          groupId: groupId,
          type: "GROUP",
        };
      });

      const updatedReviewInputs: ViewerId[] = !fields.isPublic ? [...viewerInputEmployees, ...viewerInputGroups] : [];

      updateRemote({
        input: {
          remoteId: interview.id,
          isPublic: fields.isPublic,
          viewerIds: updatedReviewInputs.length > 0 ? updatedReviewInputs : null,
        },
      })
        .then(() => {
          Snackbar.notify({
            severity: "success",
            message: t("閲覧権限を更新しました。"),
          });
        })
        .catch(error => {
          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("閲覧権限の更新に失敗しました。"));
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
        })
        .finally(() => {
          setOpen(false);
        });
    },
    defaultValues: {
      isPublic: !!interview.isPublic,
      viewers: defaultViewers,
    },
    onOpen: () => {
      setOpen(true);
    },
    onClose: () => {
      setOpen(false);
    },
    editorId: currentUid,
    AccessPermissionField: <ViewerAssignFieldContainer name="viewers" variant="SECONDARY" />,
  };
};
