import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import Link, { type LinkProps } from "../../primitive/Link/Link";

const Wrapper = styled(Box)(() => ({
  width: "100%",
}));

const StyledLink = styled(Link)(() => ({
  fontSize: 12,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  borderRadius: 12,
  padding: theme.spacing(2),
  backgroundColor: theme.palette["Gray/Shades"].p8,
  width: 600,
  marginLeft: "auto",
  marginRight: "auto",
}));

export type PaymentCompletionProps = {
  title: string;
  message?: string;
  link: Pick<LinkProps, "onClick" | "href" | "children">;
};

const PaymentCompletion: React.FC<PaymentCompletionProps> = props => {
  const linkProps: LinkProps = {
    ...props.link,
    color: "secondary",
  };
  /**
   * Scroll to the top to allow users to read the Confirm Message.
   */
  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <Wrapper>
      <Box textAlign="center" mb={2}>
        <CheckCircleIcon color="success" fontSize="large" />
      </Box>
      <Typography mb={2} textAlign="center" fontWeight="bold" variant="h6">
        {props.title}
      </Typography>
      {props.message && (
        <StyledBox mb={2}>
          <Typography variant="body2">{props.message}</Typography>
        </StyledBox>
      )}
      <Box textAlign="center">
        <StyledLink {...linkProps} />
      </Box>
    </Wrapper>
  );
};

PaymentCompletion.displayName = "PaymentCompletion";

export default PaymentCompletion;
