import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import SortField, { SortFieldProps } from "../../../../modules/SortField/SortField";

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
  height: "40px",
  flexShrink: 0,
  borderBottom: `1px solid ${theme.palette.Other.Divider}`,
}));

export type SearchResultHeaderProps = {
  resultText: string;
  sortField: SortFieldProps;
};

const SearchResultHeader: React.FC<SearchResultHeaderProps> = props => {
  return (
    <Wrapper>
      <Box sx={{ width: "100%", flexGrow: 1 }}>
        <Typography fontSize={14} fontWeight={400}>
          {props.resultText}
        </Typography>
      </Box>
      <Box sx={{ width: 32, height: 32, flexShrink: 0 }}>
        <SortField {...props.sortField} />
      </Box>
    </Wrapper>
  );
};

SearchResultHeader.displayName = "SearchResultHeader";

export default SearchResultHeader;
