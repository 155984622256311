import { useTranslation } from "@hireroo/i18n";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { FeedbackItemProps } from "./FeedbackItem";
import ReviewDetailDialog, { ReviewDetailDialogProps } from "./ReviewDetailDialog";

type Mode = "WITH_RECOMMEND" | "WITHOUT_RECOMMEND";
export type FeedbackSummaryProps = {
  id: string;
  reviewer: {
    displayName: string;
    photoUrl: string;
  };
  reviewedAt: string;
  items: FeedbackItemProps[];
  isRecommended?: boolean;
  comment: string;
  mode: Mode;
};

const FeedbackSummary: React.FC<FeedbackSummaryProps> = props => {
  const { t } = useTranslation();
  const { reviewer, reviewedAt } = props;
  const theme = useTheme();
  const [isOpen, setIsOpen] = React.useState(false);

  const reviewDetailDialogProps: ReviewDetailDialogProps = {
    isOpen: isOpen,
    summary: {
      reviewer: reviewer,
      reviewedAt: reviewedAt,
      items: props.items,
      isRecommended: props.isRecommended,
      comment: props.comment,
    },
    mode: props.mode,
    onClose: () => setIsOpen(false),
  };

  return (
    <>
      <ReviewDetailDialog {...reviewDetailDialogProps} />
      <Box padding={"16px"} borderRadius={"8px"} bgcolor={theme.palette.grey[800]}>
        <Box display="flex" position={"relative"}>
          <Box>
            <Avatar alt={reviewer?.displayName ?? ""} src={reviewer?.photoUrl} />
          </Box>
          <Box sx={{ flexGlow: 1 }} display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Box mr={2} />
            <Box display={"flex"} flexDirection={"column"}>
              <Typography variant="body1" fontWeight={"bold"}>
                {reviewer?.displayName ?? t("名前情報がありませんでした。")}
              </Typography>
              <Typography mt={1} variant="body2" color={"textSecondary"}>
                {reviewedAt}
              </Typography>
              <Typography mt={1} sx={{ whiteSpace: "pre-wrap", wordBreak: "break-all" }}>
                {props.comment}
              </Typography>
              <Box mt={2}>
                <Button variant="outlined" color="secondary" onClick={() => setIsOpen(true)}>
                  {t("レビュー詳細")}
                </Button>
              </Box>
            </Box>
          </Box>
          {props.mode === "WITH_RECOMMEND" && (
            <>
              {props.isRecommended ? (
                <ThumbUpIcon sx={{ color: theme.palette.success.main, position: "absolute", right: "0", fontSize: "18px" }} />
              ) : (
                <ThumbDownIcon sx={{ color: theme.palette.error.main, position: "absolute", right: "0", fontSize: "18px" }} />
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

FeedbackSummary.displayName = "FeedbackSummary";

export default FeedbackSummary;
