import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import TimelimitCircularProgress, { TimelimitCircularProgressProps } from "./parts/TimelimitCircularProgress/TimelimitCircularProgress";

type Status = "GOOD" | "WARNING";

export type ScreeningTestTimelimitProps = {
  displayText: string;
  /** 0 - 100 */
  value: number;
  status: Status;
};

const ScreeningTestTimelimit: React.FC<ScreeningTestTimelimitProps> = props => {
  const theme = useTheme();
  const textColorMap: Record<Status, string> = {
    GOOD: theme.palette.success.main,
    WARNING: theme.palette.error.main,
  };
  const colorMap: Record<Status, TimelimitCircularProgressProps["color"]> = {
    GOOD: "success",
    WARNING: "error",
  };
  return (
    <Stack id="timelimit-box" role="timelimit" alignItems="center" spacing={2} direction="row">
      <TimelimitCircularProgress value={props.value} color={colorMap[props.status]} />
      <Typography fontWeight="bold" color={textColorMap[props.status]} paddingTop="1px" sx={{ wordBreak: "keep-all", whiteSpace: "nowrap" }}>
        {props.displayText}
      </Typography>
    </Stack>
  );
};

ScreeningTestTimelimit.displayName = "ScreeningTestTimelimit";

export default ScreeningTestTimelimit;
