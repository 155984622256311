import { styled, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import * as React from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-root": {
    borderColor: theme.palette["Secondary/Shades"].p16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  "&.MuiTableRow-root": {
    "&:hover": {
      backgroundColor: theme.palette["Secondary/Shades"].p8,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette["Secondary/Shades"].p16,
    },
  },
}));

export type MetricItemProps = {
  selected: boolean;
  title: string;
  description: string;
  onClick: () => void;
};

const MetricItem: React.FC<MetricItemProps> = props => {
  return (
    <StyledTableRow hover onClick={props.onClick} role="checkbox" tabIndex={-1} selected={props.selected}>
      <StyledTableCell padding="checkbox">
        <Checkbox color="secondary" checked={props.selected} />
      </StyledTableCell>
      <StyledTableCell component="th" padding="none">
        <Typography variant="subtitle2">{props.title}</Typography>
      </StyledTableCell>
      <StyledTableCell align="left">
        <Typography variant="body2" color="textSecondary" sx={{ wordBreak: "break-all" }}>
          {props.description}
        </Typography>
      </StyledTableCell>
    </StyledTableRow>
  );
};

MetricItem.displayName = "MetricItem";

export default MetricItem;
