import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import * as Subscriber from "./Subscriber";
import { useGenerateProps } from "./useGenerateProps";

export type ScreeningTestListContainerProps = {};

const ScreeningTestListContainer: React.FC<ScreeningTestListContainerProps> = () => {
  const screeningTestListProps = useGenerateProps({});
  React.useEffect(() => {
    const stopSubscribeListParams = Subscriber.startSubscribeListParams();
    const stopSubscribeDownloadId = Subscriber.startSubscribeDownloadId();
    return () => {
      stopSubscribeListParams();
      stopSubscribeDownloadId();
    };
  }, []);

  return (
    <ErrorBoundary>
      <Pages.ScreeningTestList {...screeningTestListProps} />
    </ErrorBoundary>
  );
};

ScreeningTestListContainer.displayName = "ScreeningTestListContainer";

export default withErrorBoundary(ScreeningTestListContainer, {});
