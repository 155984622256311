import MUITableRow, { TableRowProps as MUITableRowProps } from "@mui/material/TableRow";
import * as React from "react";

export type TableRowProps = MUITableRowProps & {};

const TableRow: React.FC<TableRowProps> = props => {
  return <MUITableRow {...props}>{props.children}</MUITableRow>;
};

TableRow.displayName = "TableRow";

export default TableRow;
