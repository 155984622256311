import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import type { QuestionSearchForm } from "@hireroo/validator";
import * as React from "react";

export const useQuestionTypeLabelMap = () => {
  const { t } = useTranslation();
  return React.useMemo(() => {
    return {
      AlgorithmQuestion: t("コーディング形式"),
      MultiChoicePackage: t("クイズ形式"),
      ProjectQuestion: t("実践形式"),
      SystemDesignQuestion: t("システムデザイン形式"),
    } satisfies Record<string, string>;
  }, [t]);
};

export const useAlgorithmVariantLabelMap = (): Record<Graphql.AlgorithmQuestionVariant, string> => {
  const { t } = useTranslation();
  return React.useMemo(() => {
    return {
      [Graphql.AlgorithmQuestionVariant.Algorithm]: t("アルゴリズム"),
      [Graphql.AlgorithmQuestionVariant.Database]: t("データベース"),
      [Graphql.AlgorithmQuestionVariant.Class]: t("クラス"),
      [Graphql.AlgorithmQuestionVariant.Unknown]: "",
    };
  }, [t]);
};

export const useProjectVariantLabelMap = (): Record<Graphql.ProjectQuestionVariant, string> => {
  const { t } = useTranslation();
  return React.useMemo(() => {
    return {
      [Graphql.ProjectQuestionVariant.Default]: "",
      [Graphql.ProjectQuestionVariant.Backend]: t("バックエンド"),
      [Graphql.ProjectQuestionVariant.Frontend]: t("フロントエンド"),
      [Graphql.ProjectQuestionVariant.DataScience]: t("データサイエンス"),
      [Graphql.ProjectQuestionVariant.Unknown]: "",
    };
  }, [t]);
};

export const useQuestionVariantLabelMap = (separator = " "): Record<QuestionSearchForm.QuestionVariant, string> => {
  const { t } = useTranslation();
  return React.useMemo(() => {
    return {
      CHALLENGE: t("コーディング形式"),
      CHALLENGE_ALGORITHM: [t("コーディング形式"), t("アルゴリズム")].join(separator),
      CHALLENGE_DATABASE: [t("コーディング形式"), t("データベース")].join(separator),
      CHALLENGE_CLASS: [t("コーディング形式"), t("クラス")].join(separator),
      QUIZ: t("クイズ形式"),
      PROJECT: t("実践形式"),
      PROJECT_OTHER: [t("実践形式"), t("その他")].join(separator),
      PROJECT_BACKEND: [t("実践形式"), t("バックエンド")].join(separator),
      PROJECT_FRONTEND: [t("実践形式"), t("フロントエンド")].join(separator),
      PROJECT_DATA_SCIENCE: [t("実践形式"), t("データサイエンス")].join(separator),
      SYSTEM_DESIGN: t("システムデザイン形式"),
      FREEPAD: t("ライブコーディング"),
    };
  }, [t, separator]);
};
