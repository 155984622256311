export const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      const base64 = String(fileReader.result);
      resolve(base64);
    };
    fileReader.onerror = error => {
      reject(error);
    };
  });
};
