import { TypeDefs } from "@hireroo/code-editor/helpers/monaco";
import ExtendedMonacoEditor, {
  type ExtendedMonacoEditorProps,
  type MonacoEditorLanguageClientWrapper,
} from "@hireroo/code-editor/react/v2/ExtendedMonacoEditor";
import * as path from "path";
import React from "react";

// syncMap will make sure consecutive update doesn't result flashy file update
// For example, when you update file a.txt 10 times within 1 sec, it only updates once, not 10 times
const syncMap: { [key: string]: number } = {};

export type CodeEditorProps = {
  height?: number;
  width?: number;
  typeDefs: TypeDefs;
  lspEndpoint?: string;
  cwd: string;
  editorValueDidChange?: (selectedFile: string, editorValue: string) => void;
  editorDidBecomeIdle?: (lastFileId: string) => void;
  editorValue: string | null;
  selectedSourceFile: string | undefined;
  readOnly: boolean;
  workspaceId: string;
};

const CodeEditor: React.FC<CodeEditorProps> = props => {
  const monacoEditorLanguageClientWrapper = React.useRef<MonacoEditorLanguageClientWrapper | null>(null);
  const key = `${props.lspEndpoint}-${props.cwd}-${props.selectedSourceFile}`;
  /**
   * In multi-model-editor, when a path is switched, model is automatically created or retrieved
   * See more at: https://github.com/suren-atoyan/monaco-react#multi-model-editor
   */
  const codeEditorProps: ExtendedMonacoEditorProps = {
    code: {
      /**
       * `?w=${props.workspaceId}`は同じファイルパスだが、異なるworkspaceでそのstateを共有しないために必要なパラメーター
       * Date.now().toString()は同じworkspace内でファイルの削除、そして同じファイル名で追加のときにstateを巨熊しないためのパラメーター
       */
      uri: `file://${props.cwd}/${props.selectedSourceFile}?w=${props.workspaceId}`,
      value: props.editorValue || "",
    },
    lspServerUrl: props.lspEndpoint,
    style: {
      height: props.height,
      width: props.width,
    },
    editorOptions: {
      readOnly: props.readOnly,
    },
    onLoad: wrapper => {
      monacoEditorLanguageClientWrapper.current = wrapper;
      const editor = wrapper.getEditor();
      editor?.onDidChangeModelContent(() => {
        const absPath = editor.getModel()?.uri.path;
        if (!absPath) return;
        const selectedFile = path.relative(props.cwd, absPath);
        props.editorValueDidChange?.(selectedFile, editor.getValue() ?? "");

        // Call didIdle after certain period. By default, it's 3 seconds
        if (props.editorDidBecomeIdle && props.selectedSourceFile) {
          if (props.selectedSourceFile in syncMap) {
            const timer = syncMap[props.selectedSourceFile];
            clearTimeout(timer);
          }
          syncMap[props.selectedSourceFile] = window.setTimeout(() => {
            props.selectedSourceFile && props.editorDidBecomeIdle?.(props.selectedSourceFile);
          }, 3000);
        }
      });
    },
  };
  React.useEffect(() => {
    return () => {
      monacoEditorLanguageClientWrapper.current?.dispose();
    };
  }, []);

  return <ExtendedMonacoEditor {...codeEditorProps} key={key} />;
};

CodeEditor.displayName = "CodeEditor";

export default CodeEditor;
