import { Pages } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type NotFoundContainerProps = {};

const OopsContainer: React.FC<NotFoundContainerProps> = () => {
  const props = useGenerateProps({});
  return <Pages.Empty {...props} />;
};

OopsContainer.displayName = "OopsContainer";

export default withErrorBoundary(OopsContainer, {});
