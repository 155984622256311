import { ProjectCodingEditorV3 } from "@hireroo/app-store/widget/shared/ProjectCodingEditorV3";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import { ProjectProvider } from "@hireroo/project/store-v3";
import * as Sentry from "@sentry/react";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import DevelopmentWorkspaceContainer, { DevelopmentWorkspaceContainerProps } from "./DevelopmentWorkspaceContainer";
import EvaluationWorkspaceContainer, { EvaluationWorkspaceContainerProps } from "./EvaluationWorkspaceContainer";
import type { Mode } from "./types";

export type ProjectCodingEditorV3FetchContainerProps = {
  mode: Mode;
  endpointId: number;
  uid: string;
  entityId: number;
  questionId: number;
  questionVersion: string;
  submissionId: number;
  isCandidate: boolean;
  enableBrowserEventDetector: boolean;
  userName: string;
};

const ProjectCodingEditorV3FetchContainer: React.FC<ProjectCodingEditorV3FetchContainerProps> = props => {
  const { uid } = props;
  const { t } = useTranslation();
  const { useEntity } = ProjectCodingEditorV3.useCreateProjectEntityHooks(props.entityId);
  const entity = useEntity();
  const entityId = entity.projectEntityId;
  const [result, startProject] = Graphql.useStartProjectForProjectCodingEditorMutation();

  React.useEffect(() => {
    if (entity.projectStatus === "CREATED" && !result.error && !result.fetching && props.isCandidate) {
      startProject({
        input: {
          projectId: entity.projectEntityId,
          candidateId: uid,
        },
      })
        .then(res => {
          if (res.data?.startProject) {
            ProjectCodingEditorV3.initialize(res.data.startProject);
          }
        })
        .catch(error => {
          Sentry.captureException(error);
        });
    }
  }, [entity, uid, result, entityId, t, startProject, props.isCandidate]);

  if (entity.projectStatus === "CREATED" || result.fetching) {
    return <Widget.Loading kind="CENTER_%" color="secondary" />;
  }

  if (props.mode === "DEVELOPMENT") {
    const developmentWorkspaceContainerProps: DevelopmentWorkspaceContainerProps = {
      ...props,
    };
    return (
      <ErrorBoundary>
        <ProjectProvider uniqueKey={props.entityId.toString()}>
          <DevelopmentWorkspaceContainer {...developmentWorkspaceContainerProps} />
        </ProjectProvider>
      </ErrorBoundary>
    );
  }

  const evaluationWorkspaceContainerProps: EvaluationWorkspaceContainerProps = {
    ...props,
  };

  return (
    <ErrorBoundary>
      <ProjectProvider uniqueKey={props.entityId.toString()}>
        <EvaluationWorkspaceContainer {...evaluationWorkspaceContainerProps} />
      </ProjectProvider>
    </ErrorBoundary>
  );
};

ProjectCodingEditorV3FetchContainer.displayName = "ProjectCodingEditorV3FetchContainer";

export default withErrorBoundary(ProjectCodingEditorV3FetchContainer, {});
