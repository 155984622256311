import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type AnyComponentContainerProps = {};

const AnyComponentContainer: React.FC<AnyComponentContainerProps> = () => {
  const anyComponentProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.AnyComponent {...anyComponentProps} />
    </ErrorBoundary>
  );
};

AnyComponentContainer.displayName = "AnyComponentContainer";

export default withErrorBoundary(AnyComponentContainer, {});
