import { useTranslation } from "@hireroo/i18n";
import RefreshIcon from "@mui/icons-material/Refresh";
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type RefreshPanelProps = {
  refresh: () => void;
};

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  height: 100%;
  width: 100%;
`;

const RefreshButton = styled(LoadingButton)`
  text-align: center;
  border-radius: 25px;
`;

const RefreshStatus = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[400],
  marginBottom: theme.spacing(1),
}));

const RefreshPanel: React.FC<RefreshPanelProps> = props => {
  const { refresh } = props;
  const { t } = useTranslation();
  const [processing, setProcessing] = React.useState(false);
  const [retryCount, setRetryCount] = React.useState(0);
  const refreshButtonProps: LoadingButtonProps = {
    loading: processing,
    startIcon: <RefreshIcon />,
    onClick: React.useCallback(() => {
      refresh();
      setProcessing(true);
      window.setTimeout(() => {
        setProcessing(false);
        setRetryCount(prev => prev + 1);
      }, 3000);
    }, [refresh]),
    children: t("リロードする"),
    variant: "contained",
    size: "small",
  };
  return (
    <StyledBox>
      <RefreshStatus variant="body2">
        {retryCount === 0 ? t("問題が発生しました。") : t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。")}
      </RefreshStatus>
      <RefreshButton {...refreshButtonProps} />
    </StyledBox>
  );
};

RefreshPanel.displayName = "RefreshPanel";

export default RefreshPanel;
