import { InterviewFeedbackStore } from "@hireroo/app-store/view-domain/InterviewFeedback";
import * as TimeFormatter from "@hireroo/formatter/time";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";

export type GenerateScreeningTestFeedbackPropsArgs = {};

type Item = Widget.ScreeningTestFeedbackProps["feedbacks"][0]["items"][0];

export const useGenerateProps = (_args: GenerateScreeningTestFeedbackPropsArgs): Widget.ScreeningTestFeedbackProps => {
  const { t } = useTranslation();
  const interview = InterviewFeedbackStore.useInterview();

  return {
    emptyMessage: t("技術評価が未入力です。右上の「技術レビュー」ボタンから技術評価を行えます。"),
    feedbacks: interview.recommendations.map(recommendation => {
      return {
        id: recommendation.id,
        reviewer: {
          displayName: recommendation.reviewer.displayName || recommendation.reviewer.email,
          photoUrl: recommendation.reviewer.photoUrl,
          email: recommendation.reviewer.email,
        },
        items: recommendation.evaluations.map(
          (evaluation): Item => ({
            id: evaluation.id,
            title: evaluation.metric?.title ?? "",
            evaluation: {
              numStars: evaluation.numStars,
              comment: evaluation.comment,
            },
          }),
        ),
        status: recommendation.isRecommended ? "RECOMMENDED" : "NOT_RECOMMENDED",
        comment: recommendation.comment,
        reviewedAt: TimeFormatter.unixToDateFormat(recommendation.createdAtSeconds ?? 0),
      };
    }),
  };
};
