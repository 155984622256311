import { proxy } from "valtio";
import { proxyMap } from "valtio/utils";

import type { State } from "./types";

export const state = proxy<State>({
  previousActiveCompanyId: null,
  activeCompanyId: null,
  companyMap: proxyMap(),
  errorStatus: null,
  pager: null,
});
