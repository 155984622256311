import { Widget } from "@hireroo/presentation/legacy";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import { useGenerateProviderProps } from "./useGenerateProviderProps";

const QuizQuestionEditorProvider: React.FC<React.PropsWithChildren<{}>> = props => {
  const providerProps = useGenerateProviderProps({});
  return (
    <ErrorBoundary>
      <Widget.QuizQuestionEditorProvider {...providerProps} children={props.children} />
    </ErrorBoundary>
  );
};

QuizQuestionEditorProvider.displayName = "QuizQuestionEditorProvider";

export default withErrorBoundary(QuizQuestionEditorProvider, {});
