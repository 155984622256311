import { useTranslation } from "@hireroo/i18n";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import ButtonWithTooltip, { ButtonWithTooltipProps } from "../../../../primitive/Button/ButtonWithTooltip/ButtonWithTooltip";
import MoreButton, { MoreButtonProps } from "../../../../primitive/Button/MoreButton/MoreButton";

const StyledStack = styled(Stack)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingRight: theme.spacing(3),
  paddingLeft: theme.spacing(3),
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

export type DetailHeaderProps = {
  title: string;
  startButton?: Pick<ButtonWithTooltipProps, "onClick" | "children" | "startIcon">;
  reviewButton?: Pick<ButtonWithTooltipProps, "onClick" | "disabled" | "title">;
  evaluateButton?: Pick<ButtonWithTooltipProps, "onClick" | "title" | "disabled">;
  moreButton: Pick<MoreButtonProps, "options">;
};

const DetailHeader: React.FC<DetailHeaderProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const moreButton: MoreButtonProps = {
    ...props.moreButton,
    color: theme.palette.text.primary,
    variant: "vertical",
  };
  const startButton: ButtonWithTooltipProps | undefined = props.startButton && {
    ...props.startButton,
    color: "secondary",
    variant: "contained",
  };
  const reviewButton: ButtonWithTooltipProps | undefined = props.reviewButton && {
    ...props.reviewButton,
    startIcon: "RATE_REVIEW",
    variant: "outlined",
    children: t("技術レビュー"),
  };
  const evaluateButton: ButtonWithTooltipProps | undefined = props.evaluateButton && {
    ...props.evaluateButton,
    startIcon: "CHECK",
    variant: "contained",
    children: t("最終評価"),
  };

  return (
    <StyledStack direction="row" p={1}>
      <Typography fontSize={20} fontWeight={700}>
        {props.title}
      </Typography>
      <Stack direction="row" spacing={1} display="flex" alignItems="center" justifyContent="center">
        {startButton && <ButtonWithTooltip {...startButton} />}
        {reviewButton && <ButtonWithTooltip {...reviewButton} />}
        {evaluateButton && <ButtonWithTooltip {...evaluateButton} />}
        <MoreButton {...moreButton} />
      </Stack>
    </StyledStack>
  );
};

DetailHeader.displayName = "DetailHeader";

export default DetailHeader;
