import { INITIAL_VERSION } from "@hireroo/app-definition/question";
import { DeepReadonly } from "@hireroo/app-helper/types";
import { QuestionSelectFieldForResourceEditor } from "@hireroo/app-store/widget/e/QuestionSelectFieldForResourceEditor";
import * as Graphql from "@hireroo/graphql/client/urql";
import { generatePath } from "@hireroo/router/api";
import type { Fields } from "@hireroo/validator";

type EntitySource = Fields.EntitySource.EntitySource;

export const getVersion = (question: DeepReadonly<QuestionSelectFieldForResourceEditor.Question>): string => {
  switch (question.__typename) {
    case "AlgorithmQuestion":
    case "MultiChoicePackage":
    case "ProjectQuestion":
    case "FreepadQuestion":
      return question.version;
    case "SystemDesignQuestion":
      return INITIAL_VERSION;
    default:
      throw new Error(`Unknown type: ${question satisfies never}`);
  }
};

export const generateQuestionDetailPath = (question: DeepReadonly<QuestionSelectFieldForResourceEditor.Question>): string => {
  if (question.__typename === "MultiChoicePackage") {
    return generatePath("/e/questions/:entityType/:id", {
      pathParams: {
        entityType: QuestionSelectFieldForResourceEditor.QuestionTypeMap[question.__typename],
        id: question.packageId.toString(),
      },
      queryParams: {
        version: question.version,
      },
    });
  } else if (question.__typename === "AlgorithmQuestion") {
    return generatePath("/e/questions/:entityType/:id", {
      pathParams: {
        entityType: QuestionSelectFieldForResourceEditor.QuestionTypeMap[question.__typename],
        id: question.questionId.toString(),
      },
      queryParams: {
        version: question.version,
      },
    });
  } else if (question.__typename === "FreepadQuestion") {
    return generatePath("/e/questions/:entityType/:id", {
      pathParams: {
        entityType: QuestionSelectFieldForResourceEditor.LiveCodingQuestionTypeMap[question.__typename],
        id: question.freepadQuestionId.toString(),
      },
      queryParams: {
        version: question.version,
      },
    });
  } else {
    return generatePath("/e/questions/:entityType/:id", {
      pathParams: {
        entityType: QuestionSelectFieldForResourceEditor.QuestionTypeMap[question.__typename],
        id: question?.questionId?.toString() ?? "",
      },
    });
  }
};

export const convertSpotQuestionToSource = (
  spotQuestion: DeepReadonly<Graphql.SpotQuestionForQuestionSelectFieldForResourceEditorFragment>,
  isAvailableQuestion: (variant: Graphql.QuestionVariant) => boolean,
): EntitySource => {
  switch (spotQuestion.__typename) {
    case "AlgorithmQuestion": {
      const entitySource: EntitySource = {
        type: "CHALLENGE",
        uniqueKey: spotQuestion.key,
        questionId: spotQuestion.questionId,
        questionVersion: spotQuestion.version,
        enabledLanguages: spotQuestion.supportedLanguages.slice(),
        isSelectable: isAvailableQuestion("CHALLENGE"),
      };
      return entitySource;
    }
    case "MultiChoicePackage": {
      const entitySource: EntitySource = {
        type: "QUIZ",
        uniqueKey: spotQuestion.key,
        packageId: spotQuestion.packageId,
        packageVersion: spotQuestion.version,
        isSelectable: isAvailableQuestion("QUIZ"),
      };
      return entitySource;
    }
    case "ProjectQuestion": {
      const entitySource: EntitySource = {
        type: "PROJECT",
        uniqueKey: spotQuestion.key,
        questionId: spotQuestion.questionId,
        questionVersion: spotQuestion.version,
        isSelectable: isAvailableQuestion("PROJECT"),
      };
      return entitySource;
    }
    case "SystemDesignQuestion": {
      const entitySource: EntitySource = {
        type: "SYSTEM_DESIGN",
        uniqueKey: spotQuestion.key,
        questionId: spotQuestion.questionId,
        componentTypes: ["default", "aws", "gcp", "azure"],
        isSelectable: isAvailableQuestion("SYSTEM_DESIGN"),
      };
      return entitySource;
    }
    default:
      throw new Error(`Current Question is not support: ${spotQuestion satisfies never}`);
  }
};

export const getRemoteVersion = (question: DeepReadonly<Graphql.LiveCodingQuestionForQuestionSelectFieldForResourceEditorFragment>): string => {
  switch (question.__typename) {
    case "AlgorithmQuestion":
    case "FreepadQuestion":
      return question.version;
    case "SystemDesignQuestion":
      return INITIAL_VERSION;
    default:
      throw new Error(`Unknown type: ${question satisfies never}`);
  }
};

export const generateRemoteQuestionDetailPath = (question: DeepReadonly<QuestionSelectFieldForResourceEditor.LiveCodingQuestion>): string => {
  if (question.__typename === "SystemDesignQuestion") {
    return generatePath("/e/questions/:entityType/:id", {
      pathParams: {
        entityType: QuestionSelectFieldForResourceEditor.LiveCodingQuestionTypeMap[question.__typename],
        id: question.questionId.toString(),
      },
    });
  } else if (question.__typename === "AlgorithmQuestion") {
    return generatePath("/e/questions/:entityType/:id", {
      pathParams: {
        entityType: QuestionSelectFieldForResourceEditor.LiveCodingQuestionTypeMap[question.__typename],
        id: question.questionId.toString(),
      },
      queryParams: {
        version: question.version,
      },
    });
  } else if (question.__typename === "FreepadQuestion") {
    return generatePath("/e/questions/:entityType/:id", {
      pathParams: {
        entityType: QuestionSelectFieldForResourceEditor.LiveCodingQuestionTypeMap[question.__typename],
        id: question.freepadQuestionId.toString(),
      },
      queryParams: {
        version: question.version,
      },
    });
  } else {
    throw new Error(`Unknown type: ${question satisfies never}`);
  }
};
export const convertLiveCodingQuestionToSource = (
  question: DeepReadonly<Graphql.LiveCodingQuestionForQuestionSelectFieldForResourceEditorFragment>,
): EntitySource => {
  switch (question.__typename) {
    case "AlgorithmQuestion": {
      const entitySource: EntitySource = {
        type: "REMOTE_ALGORITHM",
        uniqueKey: question.key,
        questionId: question.questionId,
        questionVersion: question.version,
        isSelectable: true,
      };
      return entitySource;
    }
    case "SystemDesignQuestion": {
      const entitySource: EntitySource = {
        type: "REMOTE_SYSTEM_DESIGN",
        uniqueKey: question.key,
        questionId: question.questionId,
        isSelectable: true,
      };
      return entitySource;
    }
    case "FreepadQuestion": {
      const entitySource: EntitySource = {
        type: "REMOTE_FREEPAD",
        uniqueKey: question.key,
        questionId: question.freepadQuestionId,
        questionVersion: question.version,
        isSelectable: true,
      };
      return entitySource;
    }
    default:
      throw new Error(`Current Question is not support: ${question satisfies never}`);
  }
};
