import { App } from "@hireroo/app-store/essential/employee";
import { SearchQuizQuestion } from "@hireroo/app-store/widget/e/SearchQuizQuestion";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation/legacy";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import SearchQuizQuestionContainer, { SearchQuizQuestionContainerProps } from "./Container";

const SearchQuizQuestionFetchContainer: React.FC = () => {
  const appStatus = App.useStatus();
  const searchQuery = SearchQuizQuestion.useSearchQuery();
  const fetchable = SearchQuizQuestion.useFetchable();
  const multiChoiceQuestionsInput: Graphql.MultiChoiceQuestionsInput = {
    cursorSeconds: searchQuery.cursorSeconds,
    cursorNanoSeconds: searchQuery.cursorNanoSeconds,
    isDescending: searchQuery.isDescending,
    queryString: searchQuery.queryString,
    size: searchQuery.size,
    status: searchQuery.status,
    tagIds: [...searchQuery.tagIds],
    withCount: searchQuery.withCount,
  };
  const [result, refresh] = Graphql.useListQuestionsForSearchQuestionQuery({
    variables: {
      multiChoiceQuestionsInput: multiChoiceQuestionsInput,
    },
    pause: appStatus !== "INITIALIZED" || !fetchable,
    /**
     * Search results are always kept up-to-date.
     */
    requestPolicy: "network-only",
  });
  React.useEffect(() => {
    if (result.data) {
      SearchQuizQuestion.setRecordCount(result.data.multiChoiceQuestions.count);
      SearchQuizQuestion.updatePager(result.data.multiChoiceQuestions.pager);
      SearchQuizQuestion.updateQuestions(result.data.multiChoiceQuestions.questions);
    }
  }, [result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED") {
    return <Widget.Loading kind="TOP" />;
  }

  const containerProps: SearchQuizQuestionContainerProps = {};
  return (
    <ErrorBoundary>
      <SearchQuizQuestionContainer {...containerProps} />
    </ErrorBoundary>
  );
};

SearchQuizQuestionFetchContainer.displayName = "SearchQuizQuestionFetchContainer";

export default withErrorBoundary(SearchQuizQuestionFetchContainer, {});
