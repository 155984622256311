import { useTheme } from "@mui/material/styles";
import * as React from "react";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import * as Def from "../../definition";

export type Data = {
  s100: number;
  s200: number;
  s300: number;
  s400: number;
  s500: number;
};

export type HttpStatusCodeGraphProps = {
  syncId?: string;
  data: Data[];
};

const HttpStatusCodeGraph: React.FC<HttpStatusCodeGraphProps> = props => {
  const theme = useTheme();
  return (
    <ResponsiveContainer>
      <BarChart data={props.data} syncId={props.syncId} barSize={50}>
        <CartesianGrid stroke={theme.palette.text.disabled} strokeDasharray="3 3" vertical={false} />
        <XAxis
          tickFormatter={(_v: unknown, i: number) => {
            return `t${i + 1}`;
          }}
          tick={{ fill: theme.palette.text.secondary }}
          tickLine={{ stroke: theme.palette.text.secondary }}
          axisLine={{ stroke: theme.palette.text.secondary }}
        />
        <YAxis
          tick={{ fill: theme.palette.text.secondary }}
          tickLine={{ stroke: theme.palette.text.secondary }}
          axisLine={{ stroke: theme.palette.text.secondary }}
        />
        <Bar dataKey={"s100"} stackId={"status chart"} fill={Def.GrafanaLatencyColorMap["s100"]} isAnimationActive={false} />
        <Bar dataKey={"s200"} stackId={"status chart"} fill={Def.GrafanaLatencyColorMap["s200"]} isAnimationActive={false} />
        <Bar dataKey={"s300"} stackId={"status chart"} fill={Def.GrafanaLatencyColorMap["s300"]} isAnimationActive={false} />
        <Bar dataKey={"s400"} stackId={"status chart"} fill={Def.GrafanaLatencyColorMap["s400"]} isAnimationActive={false} />
        <Bar dataKey={"s500"} stackId={"status chart"} fill={Def.GrafanaLatencyColorMap["s500"]} isAnimationActive={false} />
        <Tooltip
          contentStyle={{
            borderColor: theme.palette.background.paper,
            backgroundColor: theme.palette.background.paper,
            minWidth: "5vw",
          }}
          cursor={{ fillOpacity: 0.6 }}
          formatter={(v: string, n: string) => {
            return [v, Def.StatusLegendMap[n]];
          }}
          labelFormatter={(v, _n) => {
            return `t${Number(v) + 1}`;
          }}
          labelStyle={{
            color: theme.palette.text.secondary,
          }}
        />
        <Legend
          align={"left"}
          iconType={"square"}
          iconSize={12}
          formatter={v => {
            return Def.StatusLegendMap[v];
          }}
          wrapperStyle={{
            paddingLeft: 50,
            fontSize: 14,
          }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

HttpStatusCodeGraph.displayName = "HttpStatusCodeGraph";

export default HttpStatusCodeGraph;
