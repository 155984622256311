import Typography, { TypographyProps } from "@mui/material/Typography";
import * as React from "react";

const useListItemProps = (props: TypographyProps): Pick<TypographyProps, "variant" | "lineHeight"> => {
  const newProps: TypographyProps = {
    ...props,
    lineHeight: 1.5,
  };

  switch (props.variant) {
    case "body2":
      newProps.variant = "body2";
      newProps.lineHeight = 1.45;
      break;
    default:
      newProps.variant = "body1";
  }

  return newProps;
};

export type ListItemProps = TypographyProps & {};

const ListItem: React.FC<ListItemProps> = props => {
  const { children } = props;
  const listItemProps = useListItemProps(props);
  return (
    <Typography {...listItemProps} component="li" sx={{ marginBottom: "0.75em" }}>
      {children}
    </Typography>
  );
};

ListItem.displayName = "ListItem";

export default ListItem;
