import DiffEditor from "@hireroo/code-editor/react/DiffEditor";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import Markdown from "@hireroo/markdown-v2/react";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Carousel from "react-material-ui-carousel";

import type { E2ECaseItem, Node, TestCaseItem, TestScenarioItem } from "./types";

export type BlockProps = {
  node: Node;
};

const HeadingTypography = styled(Typography)`
  font-weight: bold;
`;

const SuccessSpan = styled("span")(({ theme }) => ({
  color: theme.palette.success.light,
  marginRight: "4px",
}));

const ErrorSpan = styled("span")(({ theme }) => ({
  color: theme.palette.error.light,
  marginRight: "4px",
}));

const StyledAccordion = styled(Accordion)(() => ({
  backgroundColor: "transparent",
}));

const Block: React.FC<BlockProps> = ({ node }) => {
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const [activeIndex, setActiveIndex] = React.useState<number>(0);
  const [open, setOpen] = React.useState<boolean>(false);
  const theme = useTheme();

  const indicatorIconStyle = {
    cursor: "pointer",
    transition: "200ms",
    padding: 2,
    color: "#afafaf",
    "&:hover": {
      color: theme.palette.primary.light,
    },
    "&:active": {
      color: theme.palette.primary.main,
    },
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (index: number) => {
    setOpen(!open);
    setActiveIndex(index);
  };

  switch (node.type) {
    case "section":
      return (
        <>
          <Box my={4}>
            {node.contents.map((node, index) => (
              <Block key={`block-${index}`} node={node} />
            ))}
          </Box>
          <Divider />
        </>
      );
    case "header":
      return (
        <Box my={2}>
          <HeadingTypography variant="subtitle1">{node.text[lang]}</HeadingTypography>
        </Box>
      );
    case "plain_text":
      return <Typography variant="subtitle2">{node.text[lang]}</Typography>;
    case "subtitle1":
      return <Typography variant="subtitle1">{node.text[lang]}</Typography>;
    case "body1":
      return <Typography variant="body1">{node.text[lang]}</Typography>;
    case "diff":
      return (
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="subtitle2" sx={{ flexBasis: "45%" }}>
              {t("出力")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" sx={{ flexBasis: "45%" }}>
              {t("期待値")}
            </Typography>
          </Grid>
          <React.Suspense>
            <DiffEditor original={node.actual} modified={node.expected} options={{ readOnly: true }} />
          </React.Suspense>
        </Grid>
      );
    case "markdown":
      return (
        <React.Suspense>
          <Markdown size={"small"} children={node.text[lang]} />
        </React.Suspense>
      );
    case "test_case":
      return (
        <Box my={1}>
          {node.cases.map((item: TestCaseItem, index: number) => (
            <StyledAccordion TransitionProps={{ unmountOnExit: true }} key={`accordion-${index}`}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Block node={item.title} />
                  <Box>
                    <Typography variant="body2">
                      {item.passed ? <SuccessSpan children={"✔"} /> : <ErrorSpan children={"✘"} />}
                      {item.passed ? "OK" : "NO"}
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Block node={item.description} />
                <Box mt={2} />
                <Block node={item.output} />
              </AccordionDetails>
            </StyledAccordion>
          ))}
        </Box>
      );
    case "divider":
      return <Divider />;
    case "spacer":
      return <Box mt={1} />;
    case "e2e_case":
      return (
        <>
          <Grid container spacing={4}>
            {node.cases.map((testCase: E2ECaseItem, i: number) => (
              <Grid item xs={3} key={`e2e-case-item-${i}`}>
                <Box position="relative" sx={{ cursor: "pointer" }} onClick={() => handleClick(i)}>
                  <img src={testCase.screenshot} alt={`testCase-scenario-${i}`} width="100%" style={{ borderRadius: "4px" }} />

                  {testCase.passed ? (
                    <CheckCircleIcon sx={{ color: theme.palette.success.light, position: "absolute", top: 0, left: 0 }} />
                  ) : (
                    <CancelIcon sx={{ color: theme.palette.error.light, position: "absolute", top: 0, left: 0 }} />
                  )}
                </Box>

                {/* TODO: should be body1 variant. change this when migrating to the atomic builder */}
                <Box textAlign="center">{<Block node={testCase.title} />}</Box>
              </Grid>
            ))}
          </Grid>
          <Backdrop open={open} onClick={handleClose} sx={{ zIndex: theme.zIndex.drawer + 1, color: "#fff" }}>
            <React.Suspense>
              <Carousel
                index={activeIndex}
                autoPlay={false}
                animation="slide"
                navButtonsAlwaysVisible
                cycleNavigation={false}
                fullHeightHover={false}
                height={"400px"}
                indicatorContainerProps={{
                  style: {
                    width: "500px",
                    height: 0,
                    position: "relative",
                  },
                }}
                indicatorIconButtonProps={{
                  style: {
                    color: theme.palette.primary.main,
                  },
                }}
                activeIndicatorIconButtonProps={{
                  style: {
                    color: theme.palette.primary.main,
                  },
                }}
              >
                {node.cases.map((testCase: E2ECaseItem, i: number) => (
                  <Box position="relative" key={`testCase-scenario-${i}`}>
                    <img src={testCase.screenshot} alt={`testCase-scenario-${i}`} width="100%" style={{ borderRadius: "4px" }} />
                  </Box>
                ))}
              </Carousel>
            </React.Suspense>
          </Backdrop>
        </>
      );
    case "test_scenario":
      return (
        <Box my={1}>
          <Carousel
            autoPlay={false}
            animation="slide"
            navButtonsAlwaysVisible
            cycleNavigation={false}
            indicatorIconButtonProps={{
              style: indicatorIconStyle,
            }}
            activeIndicatorIconButtonProps={{
              style: {
                color: theme.palette.primary.main,
              },
            }}
          >
            {node.scenarios.map((scenario: TestScenarioItem, i: number) => (
              <React.Fragment key={`test-scenario-${i}`}>
                {/* TODO: should be subtitle1 variant. change this when migrating to the atomic builder */}
                <Block node={scenario.title} />
                <Box mt={1}>{<Block node={scenario.cases} />}</Box>
              </React.Fragment>
            ))}
          </Carousel>
        </Box>
      );
    default:
      throw new Error("unsupported type detected for content");
  }
};

Block.displayName = "Block";

export default Block;
