import { useTranslation } from "@hireroo/i18n";
import Markdown from "@hireroo/markdown-v2/react";
import { HistoryOutlined } from "@mui/icons-material";
import CancelRounded from "@mui/icons-material/CancelRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../../../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";

const StyledTimeline = styled(Timeline)(() => ({
  "& .MuiTimelineOppositeContent-root": {
    flex: 0.1,
  },
}));

const IconMap = {
  CORRECT: <CheckCircleIcon fontSize="small" color="success" sx={{ ml: 0.4 }} />,
  INCORRECT: <CancelRounded fontSize="small" color="error" sx={{ ml: 0.4 }} />,
} satisfies Record<string, React.ReactElement>;

type StatusIcon = keyof typeof IconMap;

export type StepItemProps = {
  title: string;
  submittedAnswer?: string;
  completed: boolean;
  optionText: string;
  disabled?: boolean;
  onClick: () => void;
  status?: StatusIcon;
};

export type SubmissionAccordionProcessContentProps = {
  activeStep?: number;
  stepItems: StepItemProps[];
};

const SubmissionAccordionProcessContent: React.FC<SubmissionAccordionProcessContentProps> = props => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const dialog: DialogWithCloseIconProps = {
    open,
    onClose: () => {
      setOpen(false);
    },
    title: t("提出タイムライン"),
  };

  return (
    <Box>
      <Button color="secondary" variant="outlined" size="small" endIcon={<HistoryOutlined />} onClick={() => setOpen(true)}>
        {t("提出タイムライン")}
      </Button>
      <DialogWithCloseIcon {...dialog}>
        {props.stepItems.length > 0 ? (
          <StyledTimeline>
            {props.stepItems.map(item => (
              <TimelineItem key={item.title}>
                <TimelineOppositeContent>
                  <Typography color="textSecondary" variant="body1">
                    {item.optionText}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Typography variant="body2" color="textSecondary">
                    {item.title}
                  </Typography>
                  {item.submittedAnswer && (
                    <Box mt={1}>
                      <React.Suspense>
                        <Markdown size="small" children={item.submittedAnswer} />
                      </React.Suspense>
                    </Box>
                  )}
                </TimelineContent>
              </TimelineItem>
            ))}
          </StyledTimeline>
        ) : (
          <Box>
            <Typography variant="body2">{t("未提出の問題のため過程が存在しません。")}</Typography>
          </Box>
        )}
      </DialogWithCloseIcon>
    </Box>
  );
};

SubmissionAccordionProcessContent.displayName = "SubmissionAccordionProcessContent";

export default SubmissionAccordionProcessContent;
