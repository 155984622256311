class TrieNode {
  children: Map<string, TrieNode> = new Map();
  count: number = 0;
}

type Path = string; // e.g. "django/orm/1on1"
type UniqueSuffix = string;
export type UniqueSuffixMap = Map<Path, UniqueSuffix>;

export const generateMinimalUniqueSuffixesMap = (paths: Path[]): UniqueSuffixMap => {
  const splitPaths = paths.map(path => path.split("/"));
  const reversedSplitPaths = splitPaths.map(path => [...path].reverse());

  const root = new TrieNode();
  const results: UniqueSuffixMap = new Map<Path, UniqueSuffix>();

  reversedSplitPaths.forEach(path => {
    let node = root;
    path.forEach(name => {
      if (!node.children.has(name)) {
        node.children.set(name, new TrieNode());
      }
      node = node.children.get(name)!;
      node.count += 1;
    });
  });

  reversedSplitPaths.forEach(splitPath => {
    let node = root;
    const minimalPaths: string[] = [];
    for (const name of splitPath) {
      node = node.children.get(name)!;
      minimalPaths.push(name);
      if (node.count === 1) {
        break;
      }
    }
    const path = [...splitPath].reverse().join("/");
    results.set(path, [...minimalPaths].reverse().join("/"));
  });

  return results;
};

type Id = string;
type UniqueName = string;

export type SkillTagItem = {
  id: Id;
  name: string;
  path: string;
};

export type UniqueNameMap = Map<Id, UniqueName>;

/**
 * Generate a map from duplicated name to unique name using path.
 * If the path is empty string, it will be ignored.
 * @param items
 */
export const generateUniqueNameMapFromPath = (items: SkillTagItem[]): UniqueNameMap => {
  const nameMap = new Map<Id, SkillTagItem[]>();

  items.forEach(item => {
    const nameItems = nameMap.get(item.name) || [];
    nameMap.set(item.name, [...nameItems, item]);
  });
  const results: UniqueNameMap = new Map();

  Array.from(nameMap.keys()).forEach(name => {
    const duplicates = nameMap.get(name)!;
    if (duplicates.length !== 1) {
      const paths = duplicates.map(duplicate => duplicate.path);
      const uniqueSuffixesMap = generateMinimalUniqueSuffixesMap(paths);
      duplicates.forEach(duplicate => {
        if (duplicate.path === "") {
          return;
        }
        results.set(duplicate.id, uniqueSuffixesMap.get(duplicate.path)!);
      });
    }
  });

  return results;
};
