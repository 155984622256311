import CodeEditor, { CodeEditorProps } from "@hireroo/code-editor/react/CodeEditor";
import DiffEditor, { DiffEditorProps } from "@hireroo/code-editor/react/DiffEditor";
import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledTypography = styled(Typography)(() => ({
  flexBasis: "48%",
}));

export type TestCaseDiffProps = {
  inputs: string[];
  output: string;
  expected: string;
};

const TestCaseDiff: React.FC<TestCaseDiffProps> = props => {
  const { t } = useTranslation();
  const editor: CodeEditorProps = {
    width: "100%",
    height: 50,
    language: "text",
    value: `${props.inputs.join(",")}`,
    options: { readOnly: true, lineNumbers: "off" },
  };
  const diffEditor: DiffEditorProps = {
    width: "100%",
    height: 50,
    language: "text",
    original: props.output,
    modified: props.expected,
    options: { readOnly: true, renderSideBySide: true },
  };
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <StyledTypography variant="subtitle2">{t("入力")}</StyledTypography>
          <React.Suspense>
            <CodeEditor {...editor} />
          </React.Suspense>
        </Grid>
        <Grid item xs={8}>
          <Box display="flex" mb="2px">
            <StyledTypography variant="subtitle2">{t("出力")}</StyledTypography>
            <StyledTypography variant="subtitle2">{t("期待値")}</StyledTypography>
          </Box>
          <React.Suspense>
            <DiffEditor {...diffEditor} />
          </React.Suspense>
        </Grid>
      </Grid>
    </Box>
  );
};

TestCaseDiff.displayName = "TestCaseDiff";

export default TestCaseDiff;
