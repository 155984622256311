import InputAdornment from "@mui/material/InputAdornment";
import { TextFieldProps } from "@mui/material/TextField";
import * as React from "react";

import InputControlTextField, { InputControlTextFieldProps } from "../../../primitive/InputControl/InputControlTextField/InputControlTextField";
import ChangeVisibilityButton, { ChangeVisibilityButtonProps } from "./parts/ChangeVisibilityButton/ChangeVisibilityButton";

const typeMap: Record<ChangeVisibilityButtonProps["kind"], TextFieldProps["type"]> = {
  INVISIBLE: "password",
  VISIBLE: "text",
};

export type PasswordFieldProps = TextFieldProps & {
  enableVisibilityButton?: true;
};

const PasswordField: React.FC<PasswordFieldProps & { name: string }> = props => {
  const [visibilityState, setVisibilityState] = React.useState<ChangeVisibilityButtonProps["kind"]>("INVISIBLE");
  const changeVisibilityButtonProps: ChangeVisibilityButtonProps = {
    kind: visibilityState,
    onClick: () => {
      setVisibilityState(prev => (prev === "INVISIBLE" ? "VISIBLE" : "INVISIBLE"));
    },
  };

  const { enableVisibilityButton, ...testFieldProps } = props;

  const textFieldProps: InputControlTextFieldProps = {
    ...testFieldProps,
    type: typeMap[visibilityState],
    autoComplete: "current-password",
    variant: "outlined",
    size: "small",
    InputLabelProps: {
      ...props.InputLabelProps,
      shrink: true,
    },
    InputProps: {
      endAdornment: enableVisibilityButton && (
        <InputAdornment position="end">
          <ChangeVisibilityButton {...changeVisibilityButtonProps} />
        </InputAdornment>
      ),
    },
    fullWidth: true,
  };
  return <InputControlTextField name={props.name} {...textFieldProps} />;
};

PasswordField.displayName = "PasswordField";

export default PasswordField;
