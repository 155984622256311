import { useTitle } from "@hireroo/app-helper/react-use";
import { App, Payment } from "@hireroo/app-store/essential/employee";
import { EmployeeSideBarContent } from "@hireroo/app-store/widget/e/EmployeeSideBarContent";
import { useTranslation } from "@hireroo/i18n";
import type { Pages } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import React from "react";

import CompanySettingsNavigatorContainer from "../../../../widget/v2/e/CompanySettingsNavigator/Container";
import EmployeeHeaderRightContentContainer from "../../../../widget/v2/e/EmployeeHeaderRightContent/Container";
import EmployeeSideBarContentContainer from "../../../../widget/v2/e/EmployeeSideBarContent/Container";
import FooterContainer from "../../../../widget/v2/e/Footer/Container";
import { useNotificationBanner } from "../../../../widget/v2/e/NotificationBanner/Container";
import TalentMemberSettingsFetchContainer from "../../../../widget/v2/e/TalentMemberSettings/FetchContainer";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";

type Item = Exclude<Pages.CompanySettingsProps["settingsLayout"]["sideBar"], undefined>["items"][number];
export type GenerateCompanyEmployeeSettingsPropsArgs = {};

export const useGenerateProps = (_args: GenerateCompanyEmployeeSettingsPropsArgs): Pages.CompanySettingsProps => {
  const { t } = useTranslation();
  const navigate = useTransitionNavigate();
  const loading = App.useStatus() === "INITIALIZING";
  const NotificationBannerContainer = useNotificationBanner();
  const isAvailableFeature = Payment.useIsAvailableFeature();
  const enableTalentScore = App.useEnableTalentScore();

  useTitle(t("タレント"));

  const items = React.useMemo((): Item[] => {
    const baseItems: Item[] = [
      {
        id: "member",
        label: t("メンバー"),
        href: generatePath("/e/settings/company/employee"),
        onClick: () => {
          navigate("/e/settings/company/employee");
        },
      },
      {
        id: "group",
        label: t("グループ"),
        href: generatePath("/e/settings/company/group/employee"),
        onClick: () => {
          navigate("/e/settings/company/group/employee");
        },
      },
    ];
    if (enableTalentScore && isAvailableFeature("assessment.read")) {
      const talentItem: Item = {
        id: "talent",
        label: t("タレント"),
        href: generatePath("/e/settings/company/talent"),
        onClick: () => {
          navigate("/e/settings/company/talent");
        },
      };
      baseItems.push(talentItem);
    }
    return baseItems;
  }, [enableTalentScore, isAvailableFeature, navigate, t]);

  return {
    layout: {
      openSidebar: EmployeeSideBarContent.useOpenSidebar(),
      onChangeOpenSidebar: open => {
        EmployeeSideBarContent.setOpenSidebar(open);
      },
      NotificationBanner: NotificationBannerContainer,
      loading: loading,
      HeaderRightContent: <EmployeeHeaderRightContentContainer />,
      SideBarContent: <EmployeeSideBarContentContainer />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      sideBarHeader: {
        logo: {
          href: generatePath("/e/home"),
          onClick: () => {
            navigate("/e/home");
          },
        },
      },
    },
    settingsLayout: {
      HeaderMenu: <CompanySettingsNavigatorContainer active="MEMBER" />,
      sideBar: {
        active: "talent",
        items: items,
      },
    },
    children: <TalentMemberSettingsFetchContainer />,
  };
};
