import { safeJsonParse } from "@hireroo/app-helper/parser";
import { TechnicalCommentPopperForExam } from "@hireroo/app-store/widget/e/TechnicalCommentPopperForExam";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { TechnicalCommentForm } from "@hireroo/validator";
import * as Sentry from "@sentry/react";
import { GraphQLError } from "graphql";
import * as React from "react";

export type FetchResult = {
  isLoading: boolean;
  errors: GraphQLError[];
};

export type FetchMetricGroupArgs = {
  cacheKey: string;
};

export const useFetchMetricGroupFromLocalStorage = (args: FetchMetricGroupArgs) => {
  const client = getGraphqlClient();
  const technicalCommentSchema = TechnicalCommentForm.useTechnicalCommentFormSchema();
  const formValues = TechnicalCommentPopperForExam.useFormValues();

  const [cacheRestored, setCacheRestored] = React.useState<boolean>(false);

  React.useEffect(() => {
    // Restore evaluation from the local storage
    const cache = localStorage.getItem(args.cacheKey);
    if (cache !== null) {
      const parsedResult = technicalCommentSchema.safeParse(safeJsonParse(cache));

      // We have to get the metricGroup prior to the metric check
      if (parsedResult.success && formValues.metricGroupId === null && parsedResult.data.metricGroupId && !cacheRestored) {
        TechnicalCommentPopperForExam.updateLoadingStatus("LOADING");
        client
          .GetEvaluationMetricGroupForTechnicalComment({
            metricGroupId: parsedResult.data.metricGroupId,
          })
          .then(metricGroup => {
            // validator checks if we should evict the cache or not
            const validator = (x: TechnicalCommentForm.EvaluationSchema, i: number): boolean => {
              // In case some metrics are deleted, hence cache metrics are not valid anymore
              if (!(i < metricGroup.evaluationMetricGroup.metrics.length)) {
                return false;
              }

              // In case where order differs or same length, but id doesn't match
              if (x.metricId !== metricGroup.evaluationMetricGroup.metrics[i]?.metricId) {
                return false;
              }

              return true;
            };

            // Check if the metrics match with what's stored in the cache.
            // If they don't match, evict the cache, otherwise restore the cache.
            if (parsedResult.data.evaluations.every(validator)) {
              TechnicalCommentPopperForExam.updatePartialFormValues(parsedResult.data);
            } else {
              // evict the cache
              localStorage.removeItem(args.cacheKey);
            }
          })
          .catch(error => {
            Sentry.captureException(error);
          })
          .finally(() => {
            setCacheRestored(true);
            TechnicalCommentPopperForExam.updateLoadingStatus("READY");
          });
      }
    }
  }, [technicalCommentSchema, args.cacheKey, cacheRestored, client, formValues.metricGroupId]);
};
