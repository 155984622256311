import { useSnapshot } from "valtio";

import { state } from "./State";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useSearchQuery = () => {
  const snapshot = useSnapshotState();
  return snapshot.searchQuery;
};

export const usePage = () => {
  const snapshot = useSnapshotState();
  return snapshot.page;
};

export const useFetchSize = () => {
  const snapshot = useSnapshotState();
  return snapshot.searchQuery.size;
};

export const useQuestions = () => {
  const snapshot = useSnapshotState();
  const page = usePage();
  const pageQuestionKeys = snapshot.pageQuestions.get(page) || [];
  return Array.from(snapshot.questions.values()).filter(question => {
    if (!question.id || !pageQuestionKeys) {
      return false;
    }
    return pageQuestionKeys.includes(`${question.id}-${question.version}`);
  });
};

export const useMaxRecordCount = () => {
  const snapshot = useSnapshotState();
  return snapshot.recordCount;
};

const useCurrentTotalSize = () => {
  const snapshot = useSnapshotState();
  return Array.from(snapshot.pageQuestions.values()).reduce<number>((total, list) => {
    return total + list.length;
  }, 0);
};

export const useHasNext = () => {
  const snapshot = useSnapshotState();
  const page = usePage();
  const nextPageQuestions = snapshot.pageQuestions.get(page + 1);
  return !nextPageQuestions;
};

export const useFetchable = (): boolean => {
  const fetchSize = useFetchSize();
  const page = usePage();
  const currentSize = useCurrentTotalSize();
  const expectedTotal = fetchSize * page + 1;
  return currentSize < expectedTotal;
};

export const useSelectedQuestionMap = () => {
  const snapshot = useSnapshotState();
  return snapshot.selectedQuestions;
};
