import { generateKey } from "@hireroo/app-helper/parser";

import { state } from "./State";
import type * as Types from "./types";

export const hasStatistics = (entityId: Types.SystemDesignEntityId, query: Types.Query) => {
  const systemDesignState = state.systemDesigns.get(entityId);
  if (!systemDesignState) {
    return;
  }
  return systemDesignState.statisticsMap.has(generateKey(query));
};
