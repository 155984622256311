import { App, Credential } from "@hireroo/app-store/essential/shared";
import { Auth, Company, Payment, Security } from "@hireroo/app-store/essential/talent";
import { FirebaseUser, getExpireDate, getIdToken } from "@hireroo/firebase";
import { initialize as initializeGraphqlClient } from "@hireroo/graphql/client/request";
import type * as Graphql from "@hireroo/graphql/client/urql";
import { redirect } from "@hireroo/router/api";

import { initializeGraphqlClientSdkAndRestApiClient } from "./helper";

const startSubscribeActiveCompanyIdZero = () => {
  return Company.subscribeActiveCompanyIdZero(async () => {
    Company.updateErrorStatus("DO_NOT_BELONG_TO_ANY_COMPANY");
  });
};

const startSubscribeForSwitchActiveCompany = () => {
  /**
   * Executed after the server-side state ActiveCompanyId is changed.
   */
  return Company.subscribeActiveCompanyId(async (activeCompanyId, previousActiveCompanyId) => {
    /**
     * Switch to `INITIALIZING` until the Company is finished switching.
     */
    App.setStatus("INITIALIZING");
    if (activeCompanyId !== previousActiveCompanyId) {
      /**
       * TODO @himenon
       * We would like to erase this redirection process for UX in the future.
       *
       * Reasons why this is currently not possible
       * 1. To make the same condition as before the migration was conducted.
       * 2. Need to implement store's cache clear correctly on all pages.
       */
      await redirect("/t/assessments");
    }
    App.setStatus("INITIALIZED");
  });
};

type TalentInitializeArgs = {
  currentUser: FirebaseUser;
  authToken: string;
  res: Graphql.InitializeParamsForTalentFragment;
};

const signedInProcess = async (args: TalentInitializeArgs): Promise<void> => {
  const { res, currentUser, authToken } = args;
  Credential.setCurrentUserType("TALENT");
  initializeGraphqlClient(import.meta.env.VITE_GRAPHQL_SERVER_ADDRESS, {
    authToken,
    getOrRefreshAuthTokenExpire: Credential.getOrRefreshAuthTokenExpire,
    appVersion: APP_VERSION,
  });
  Company.initializeBelongsCompanies({
    activeCompanyId: res.activeCompany.companyId,
    companies: res.belongsCompanies,
  });
  Payment.initialize();

  const idToken = await currentUser.getIdTokenResult(true);
  Credential.setProviderId(idToken.signInProvider);
  Credential.setUid(currentUser.uid);
  Credential.setMailAddress(currentUser.email);
  Credential.setProviderId(idToken.signInProvider);
  const expireDate = await getExpireDate();
  Credential.setAuthToken({
    authToken: authToken || null,
    expireDate: expireDate || null,
    getRefreshAuthToken: async () => {
      const authToken = await getIdToken(true);
      const expireDate = await getExpireDate();
      if (authToken) {
        initializeGraphqlClientSdkAndRestApiClient(authToken);
      }
      return {
        authToken,
        expireDate,
      };
    },
  });

  if (res.activeCompany.security) {
    Security.initialize(res.activeCompany.security);
  }
  Auth.setUser(res.currentUser);
  startSubscribeActiveCompanyIdZero();
  startSubscribeForSwitchActiveCompany();
};

const initialize = async (args: TalentInitializeArgs) => {
  const { res } = args;
  App.setStatus("INITIALIZING");
  App.setEnableTalentScore(res.activeCompany.enableTalentScore);
  await signedInProcess(args);
  App.setStatus("INITIALIZED");
};

export const Talent = {
  initialize,
};
