import { ScreeningTestTutorial } from "@hireroo/app-store/widget/shared/ScreeningTestTutorial";
import type { Widget } from "@hireroo/presentation";
export type GenerateScreeningTestTutorialPropsArgs = {};

export const useGenerateProps = (_args: GenerateScreeningTestTutorialPropsArgs): Widget.ScreeningTestTutorialProps => {
  const runTutorial = ScreeningTestTutorial.useRunTutorial();
  const kind = ScreeningTestTutorial.useCurrentKind();
  return {
    kind: kind,
    runTutorial: runTutorial,
    onChangeRunTutorial: runTutorial => {
      ScreeningTestTutorial.updateRunTutorial(runTutorial);
    },
  };
};
