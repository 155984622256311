import type * as Interface from "@hireroo/evaluation-result-interface";
import * as React from "react";

import * as Blocks from "../../../../blocks";

export type ResultBlockProps = Interface.Block;

const ResultBlock: React.FC<ResultBlockProps> = props => {
  const Block = React.useMemo(() => {
    const block = props;
    const type = block.type;
    switch (type) {
      case "carousel": {
        return <Blocks.Carousel {...block.value} />;
      }
      case "cumulative_layout_shift": {
        return <Blocks.CumulativeLayoutShift {...block.value} />;
      }
      case "divider": {
        return <Blocks.Divider />;
      }
      case "header": {
        return <Blocks.Header {...block.value} />;
      }
      case "markdown": {
        return <Blocks.Markdown {...block.value} />;
      }
      case "test_case_result": {
        return <Blocks.TestCaseResult {...block.value} />;
      }
      case "text_diff_viewer": {
        return <Blocks.TextDiffViewer {...block.value} />;
      }
      case "table": {
        return <Blocks.Table {...block.value} />;
      }
      case "table_diff": {
        return <Blocks.TableDiff {...block.value} />;
      }
      case "stepper": {
        return <Blocks.Stepper {...block.value} />;
      }
      case "image_input_output_expected": {
        return <Blocks.ImageInputOutputExpected {...block.value} />;
      }
      default:
        throw new Error(`Unknown block kind: ${type satisfies never}`);
    }
  }, [props]);
  return <>{Block}</>;
};

ResultBlock.displayName = "ResultBlock";

export default ResultBlock;
