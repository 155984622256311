import { BehavioralEvent, ClipboardEvent, CodeEditorInputEvent, PlaybackTickEvent } from "@hireroo/app-helper/playback";
import * as React from "react";
import { useSnapshot } from "valtio";

import { generateEventTimelineMapByTicks, isBehavioralEvent } from "./privateHelper";
import { state } from "./State";
import * as Types from "./types";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = (): boolean => {
  const snapshot = useSnapshotState();
  return snapshot.playbackManager !== null && snapshot.submission !== null;
};

export const useSliderValue = () => {
  const snapshot = useSnapshotState();
  return snapshot.sliderValue;
};

export const useEditorMode = () => {
  const snapshot = useSnapshotState();
  return snapshot.editorMode;
};

export const useQuestion = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.question) {
    throw new Error("Please initialize question");
  }
  return snapshot.question;
};

export const usePlaybackManager = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.playbackManager) {
    throw new Error("Not initialized PlaybackManager");
  }
  return snapshot.playbackManager;
};

export const useSessionIds = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.sessionIds) {
    throw new Error("Not initialized sessionIds");
  }
  return snapshot.sessionIds;
};

export const useIsSessionIssued = (): boolean => {
  const sessionIds = useSessionIds();
  return React.useMemo((): boolean => {
    return sessionIds.webSessionId !== null || sessionIds.chatGPTSessionId !== null;
  }, [sessionIds]);
};

export const useEnabledChatGPT = () => {
  const sessionIds = useSessionIds();
  return sessionIds.chatGPTSessionId !== null;
};

export const useEnabledWebSearch = () => {
  const sessionIds = useSessionIds();
  return sessionIds.webSessionId !== null;
};

export const useSubmission = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.submission) {
    throw new Error("Not initialized submission");
  }
  return snapshot.submission;
};

export const useAppealMessage = () => {
  const snapshot = useSnapshotState();
  return snapshot.appealMessage;
};

export const usePlayStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.playStatus;
};

/**
 * behavioralEventMatrix is 2 dimension array because when Chat GPT's response is very fast,
 * one tick would have multiple behavioral events.
 */
export const useBehavioralEventMatrix = () => {
  const playbackManager = usePlaybackManager();
  return React.useMemo((): BehavioralEvent[][] => {
    return playbackManager.ticks.map((tick): BehavioralEvent[] => {
      return tick.events.filter(isBehavioralEvent);
    });
  }, [playbackManager]);
};

export const useHasBehavioralEvent = () => {
  const behavioralEventMatrix = useBehavioralEventMatrix();
  return React.useMemo(() => {
    return behavioralEventMatrix.some(behavioralEvents => behavioralEvents.length > 0);
  }, [behavioralEventMatrix]);
};

export const useHasPasteEvent = () => {
  const playbackManager = usePlaybackManager();
  return React.useMemo(() => {
    return playbackManager.ticks.some(tick => tick.events.some(event => event.kind === "EDITOR_PASTE"));
  }, [playbackManager]);
};

export const useCodeEditorInputEvents = () => {
  const playbackManager = usePlaybackManager();
  return React.useMemo((): (CodeEditorInputEvent | undefined)[] => {
    return playbackManager.ticks.map((tick): CodeEditorInputEvent | undefined => {
      /**
       * It should also include `undefined` to store the number of tick events.
       */
      const codeEditorInputEvent = tick.events.find(event => event.kind === "CODE_EDITOR") as CodeEditorInputEvent | undefined;
      return codeEditorInputEvent;
    });
  }, [playbackManager]);
};

export const useLastCodeEditorInputEventsIndex = () => {
  const codeEditorInputEvents = useCodeEditorInputEvents();
  return React.useMemo(() => {
    return codeEditorInputEvents.length - 1;
  }, [codeEditorInputEvents]);
};

export const useClipboardEvents = () => {
  const playbackManager = usePlaybackManager();
  return React.useMemo((): (ClipboardEvent | undefined)[] => {
    return playbackManager.ticks.map((tick): ClipboardEvent | undefined => {
      const clipboardEvent = tick.events.find(
        event => event.kind === "EDITOR_COPY" || event.kind === "EDITOR_CUT" || event.kind === "EDITOR_PASTE",
      ) as ClipboardEvent | undefined;
      return clipboardEvent;
    });
  }, [playbackManager]);
};

type TimelineEventKind = Exclude<PlaybackTickEvent["kind"], "CHATGPT_RESPOND">;

/**
 * The following events are excluded from the Interval calculation
 * - CHATGPT_RESPOND : Excluded due to auto-reply, not user action
 */
export const useEventTimelineMap = (): Record<TimelineEventKind, Types.EventTimeline | undefined> => {
  const playbackManager = usePlaybackManager();
  return React.useMemo(() => {
    return generateEventTimelineMapByTicks(playbackManager.ticks) as Record<TimelineEventKind, Types.EventTimeline | undefined>;
  }, [playbackManager]);
};

export const usePlaybackSettings = () => {
  const snapshot = useSnapshotState();
  return snapshot.playbackSettings;
};

export const useRightSidePanelMode = () => {
  const snapshot = useSnapshotState();
  return snapshot.rightSidePanelMode;
};

export const useLeavingPageIntervals = () => {
  const eventTimelineMap = useEventTimelineMap();
  return React.useMemo(() => {
    const browserHiddenIntervals = eventTimelineMap.BROWSER_HIDDEN?.intervals || [];
    const browserBlurIntervals = eventTimelineMap.BROWSER_BLUR?.intervals || [];
    const uniqueKeys = new Set<string>([]);
    const uniqueIntervals: Types.EventInterval[] = [];
    [...browserHiddenIntervals, ...browserBlurIntervals].forEach(interval => {
      const key = `${interval.startTs}-${interval.endTs}`;
      if (uniqueKeys.has(key)) {
        return;
      }
      uniqueKeys.add(key);
      uniqueIntervals.push(interval);
    });
    const sortedIntervals = uniqueIntervals.slice().sort((a, b) => a.startTs - b.startTs);
    const joinIntervals: Types.EventInterval[] = [];
    /**
     * There are multiple events that can be counted as page abandonment. Therefore, if an interval is measured as non-contiguous, it is re-combined here.
     */
    sortedIntervals.forEach((interval, index) => {
      const nextInterval = sortedIntervals.at(index);
      if (!nextInterval) {
        joinIntervals.push(interval);
        return;
      }
      if (interval.endTs === nextInterval.startTs) {
        joinIntervals.push({
          startTs: interval.startTs,
          endTs: nextInterval.endTs,
        });
      } else {
        joinIntervals.push(interval);
      }
    });
    return joinIntervals;
  }, [eventTimelineMap]);
};

export const useUseHintIntervals = () => {
  const eventTimelineMap = useEventTimelineMap();
  return React.useMemo(() => {
    const useHintIntervals = eventTimelineMap.USE_HINT?.intervals || [];
    return useHintIntervals.slice().sort((a, b) => a.startTs - b.startTs);
  }, [eventTimelineMap]);
};

export const useRunCodeIntervals = () => {
  const eventTimelineMap = useEventTimelineMap();
  return React.useMemo(() => {
    const runCodeIntervals = eventTimelineMap.RUN_CODE?.intervals || [];
    return runCodeIntervals.slice().sort((a, b) => a.startTs - b.startTs);
  }, [eventTimelineMap]);
};
