import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type ScreeningDetailContainerProps = {};

const ScreeningDetailContainer: React.FC<ScreeningDetailContainerProps> = () => {
  const screeningDetailProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Pages.ScreeningDetail {...screeningDetailProps} />
    </ErrorBoundary>
  );
};

ScreeningDetailContainer.displayName = "ScreeningDetailContainer";

export default withErrorBoundary(ScreeningDetailContainer, {});
