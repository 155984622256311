import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import MessageCard, { MessageCardProps } from "./parts/MessageCard/MessageCard";

const Wrapper = styled(Box)(() => ({
  height: "100%",
  flexDirection: "column",
}));

const StyledMiddleBox = styled(Box)(() => ({
  flexGrow: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
}));

const StyledMessageStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1),
  height: "100%",
  overflowY: "auto",
  flexGrow: 1,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: theme.palette.action.active,
  textAlign: "center",
}));

export type ChatGPTPlaybackRightSidePanelProps = {
  messageCards: MessageCardProps[];
  disableAutoScroll?: boolean;
  hide?: boolean;
  gptModel: "GPT-3.5" | "GPT-4o";
};

const ChatGPTPlaybackRightSidePanel: React.FC<ChatGPTPlaybackRightSidePanelProps> = props => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const { disableAutoScroll = false } = props;

  /**
   * Scroll when new comments are added.
   */
  React.useEffect(() => {
    if (disableAutoScroll) {
      return;
    }
    if (wrapperRef.current) {
      wrapperRef.current.scrollBy({ top: wrapperRef.current.scrollHeight, behavior: "smooth" });
    }
  }, [props.messageCards.length, disableAutoScroll]);

  return (
    <Wrapper display={props.hide ? "none" : "flex"}>
      {props.messageCards.length > 0 && (
        <StyledMessageStack spacing={2} mb={2} ref={wrapperRef}>
          {props.messageCards.map((messageCard, index) => {
            return <MessageCard key={`${messageCard.avatar.role}-${index}`} {...messageCard} />;
          })}
        </StyledMessageStack>
      )}

      {props.messageCards.length === 0 && (
        <StyledMiddleBox>
          <Box>
            <StyledTypography fontSize={24}>ChatGPT</StyledTypography>
            <StyledTypography fontSize={20}>({props.gptModel})</StyledTypography>
          </Box>
        </StyledMiddleBox>
      )}
    </Wrapper>
  );
};

ChatGPTPlaybackRightSidePanel.displayName = "ChatGPTPlaybackRightSidePanel";

export default ChatGPTPlaybackRightSidePanel;
