const isFloat = (value: string): boolean => {
  if (value === "") return false;
  return Number.isFinite(Number(value));
};

const isInteger = (value: string) => {
  if (value === "") return false;
  return Number.isInteger(Number(value));
};

const isDatabaseBool = (value: string): boolean => {
  // MySQL and sqlite bool is 0 or 1. Postgres bool is t or f. Server side is unified to 0 or 1.
  switch (value) {
    case "0":
      return true;
    case "1":
      return true;
    default:
      return false;
  }
};

export const isDatabaseTestCaseValid = (value: string | null, typeString: string): boolean => {
  if (value === "" || value === null) {
    // NULL can be any type hence return true
    return true;
  }
  switch (typeString) {
    case "int":
      return isInteger(value);
    case "float":
      return isFloat(value);
    case "bool":
      return isDatabaseBool(value);
    case "string":
      return true;
    default:
      // unknown type
      return false;
  }
};
