import { ScreeningTestTutorial } from "@hireroo/app-store/widget/shared/ScreeningTestTutorial";
import { SupportLanguage, useChangeLanguage } from "@hireroo/i18n";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { useGoBack, useHelpCenterNavigate } from "@hireroo/router/hooks";

export type GenerateScreeningTestReviewNavigationPropsArgs = {};

export const useGenerateProps = (_args: GenerateScreeningTestReviewNavigationPropsArgs): Widget.ScreeningTestReviewNavigationProps => {
  const changeLanguage = useChangeLanguage();
  const lang = useLanguageCode();
  const { t } = useTranslation();
  const helpCenterNavigate = useHelpCenterNavigate(lang);
  const goBack = useGoBack();
  return {
    interviewNavigationMenu: {
      helpButton: {
        options: [
          {
            text: t("ヘルプ"),
            icon: "INFO",
            onClick: () => {
              helpCenterNavigate("ROOT", { _blank: true });
            },
          },
          {
            text: t("チュートリアル再開"),
            value: "tutorial",
            icon: "LIVE_HELP",
            onClick: () => {
              ScreeningTestTutorial.updateRunTutorial(true);
            },
          },
        ],
      },
      finishButton: {
        children: t("評価を終了する"),
        onClick: () => {
          if (history.length > 1) {
            goBack();
          } else {
            window.close();
          }
        },
      },
    },
    languageMenu: {
      options: [
        {
          text: "日本語",
          value: SupportLanguage.JA,
          onClick: () => {
            changeLanguage(SupportLanguage.JA);
          },
        },
        {
          text: "English",
          value: SupportLanguage.EN,
          onClick: () => {
            changeLanguage(SupportLanguage.EN);
          },
        },
      ],
      role: "language",
      menuId: "select-language-menu",
    },
    RightMenu: null,
  };
};
