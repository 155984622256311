import type { EntryScreeningForm } from "@hireroo/validator";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useFormContext } from "react-hook-form";

const ErrorMessage = styled(Typography)(({ theme }) => ({
  display: "block",
  color: theme.palette.error.main,
  fontSize: 12,
  lineHeight: "19.92px",
  marginLeft: theme.spacing(0.5),
}));

const StyledStack = styled(Stack)(({ theme }) => ({
  color: theme.palette.error.main,
  alignItems: "center",
  svg: {
    width: 14,
    height: 14,
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.error.main,
  width: 14,
  height: 14,
  minWidth: 14,
  minHeight: 14,
  svg: {
    width: 14,
    height: 14,
  },
}));

type Item = { message: string; name: string };

export type ValidationErrorListProps = {};

const ValidationErrorList: React.FC<ValidationErrorListProps> = () => {
  const methods = useFormContext<EntryScreeningForm.EntryScreeningFormSchema>();
  const selectableQuestionsValidationItems = Object.values(methods.formState.errors.selectableQuestions || {}).map(
    (selectableQuestion): Partial<Item> => {
      const name = selectableQuestion?.ref?.name;
      return {
        name: name,
        message: selectableQuestion?.message,
      };
    },
  );
  const items: Item[] = [
    {
      name: "agreement",
      message: methods.formState.errors.agreement?.message,
    },
    ...selectableQuestionsValidationItems,
    {
      name: "name",
      message: methods.formState.errors.name?.message,
    },
    {
      name: "email",
      message: methods.formState.errors.email?.message,
    },
    ...Object.entries(methods.formState.errors.variables || {}).map(([key, field]): Partial<Item> => {
      return {
        name: `variables.${key}.value`,
        message: field?.value?.message,
      };
    }),
  ].reduce<Item[]>((all, current) => {
    if (current.name && current.message) {
      all.push({
        name: current.name,
        message: current.message,
      });
    }
    return all;
  }, []);
  const handleClick = (name: string) => () => {
    const elements = document.getElementsByName(name);
    const firstElement = Array.from(elements).at(0);
    firstElement?.scrollIntoView({ behavior: "smooth" });
  };
  if (items.length === 0) {
    return null;
  }
  return (
    <Stack spacing={0.5}>
      {items.map((item, index) => {
        return (
          <StyledStack direction="row" key={`${item.name}-${index}`}>
            <ErrorOutlineOutlinedIcon fontSize="small" />
            <ErrorMessage variant="caption">{item.message}</ErrorMessage>
            <StyledIconButton onClick={handleClick(item.name)}>
              <KeyboardArrowUpOutlinedIcon />
            </StyledIconButton>
          </StyledStack>
        );
      })}
    </Stack>
  );
};

ValidationErrorList.displayName = "ValidationErrorList";

export default ValidationErrorList;
