import StaticFlowChart, { StaticFlowChartProps } from "@hireroo/system-design/react/StaticFlowChart";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import * as React from "react";

import PlaybackToolbar, { PlaybackToolbarProps } from "../../../modules/PlaybackToolbar/PlaybackToolbar";

export type SystemDesignPlaybackDialogProps = {
  open: boolean;
  onClose: () => void;
  staticFlowChart: StaticFlowChartProps;
  playbackToolbar: Omit<PlaybackToolbarProps, "screenStatus">;
};

const StyledDialog = styled(Dialog)({
  root: {
    flexGrow: 1,
  },
  heading: {
    fontWeight: "bold",
  },
  expanded: {
    width: "80vw",
    height: "100vh",
  },
});

const SystemDesignPlaybackDialog: React.FC<SystemDesignPlaybackDialogProps> = props => {
  const staticFlowChartProps: StaticFlowChartProps = {
    ...props.staticFlowChart,
    aspectRatio: 0.6,
    width: window.innerWidth * 0.8,
    disableAutoResize: true,
  };
  return (
    <StyledDialog onClose={props.onClose} open={props.open} maxWidth="lg">
      <Box display="flex" flexDirection="column" position="relative" height="100%" justifyContent="space-between">
        <React.Suspense>
          <StaticFlowChart {...staticFlowChartProps} />
        </React.Suspense>
        <PlaybackToolbar {...props.playbackToolbar} screenStatus="EXPANDED" />
      </Box>
    </StyledDialog>
  );
};

SystemDesignPlaybackDialog.displayName = "SystemDesignPlaybackDialog";

export default SystemDesignPlaybackDialog;
