import { SupportLanguageValue, useTranslation } from "@hireroo/i18n";
import Markdown from "@hireroo/markdown-v2/react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";

import DetailSwitch, { DetailSwitchProps } from "../../../modules/DetailSwitch/DetailSwitch";
import ReportScoreAnswerRateDisplayLabel, {
  ReportScoreAnswerRateDisplayLabelProps,
} from "../../../modules/ReportScoreAnswerRateDisplayLabel/ReportScoreAnswerRateDisplayLabel";
import TitleWithScoreBar from "../../../modules/TitleWithScoreBar/TitleWithScoreBar";
import TestCaseDetail, { TestCaseDetailProps } from "./parts/TestCaseDetail";
import TestCaseDiff, { TestCaseDiffProps } from "./parts/TestCaseDiff";

const AccordionWrapper = styled(Box)(({ theme }) => ({
  borderRadius: "8px",
  border: "1px solid",
  borderColor: theme.palette.divider,
}));

type TestCaseResult = {
  id: string;
  detail: Omit<TestCaseDetailProps, "expandAll">;

  description: string;
  diff: TestCaseDiffProps;
};

export type ChallengeTestCaseAccordionProps = {
  language: SupportLanguageValue;
  testCaseResults: TestCaseResult[];
  targetElementId?: string;
  score?: number;
  scoreAnswerRateDisplayLabel: ReportScoreAnswerRateDisplayLabelProps;
};

const ChallengeTestCaseAccordion: React.FC<ChallengeTestCaseAccordionProps> = props => {
  const { t } = useTranslation();
  const [expandAll, setExpandAll] = React.useState(false);

  const handleChange = () => {
    setExpandAll(false);
  };

  const detailSwitch: DetailSwitchProps = {
    checked: expandAll,
    onClick: () => {
      setExpandAll(prev => !prev);
    },
  };

  return (
    <Box>
      <Box display="flex">
        <Box mr={2} id={props.targetElementId} mb={3}>
          <TitleWithScoreBar title={t("正解率")} score={props.score} color="secondary" />
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <ReportScoreAnswerRateDisplayLabel {...props.scoreAnswerRateDisplayLabel} mb={2} />
        <DetailSwitch {...detailSwitch} />
      </Box>

      <AccordionWrapper mt={1}>
        {props.testCaseResults.map(testCaseResult => (
          <TestCaseDetail key={testCaseResult.id} {...testCaseResult.detail} expandAll={expandAll} onChange={handleChange}>
            <Box>
              <React.Suspense>
                <Markdown size={"small"} children={testCaseResult.description} />
              </React.Suspense>
              <TestCaseDiff {...testCaseResult.diff} />
            </Box>
          </TestCaseDetail>
        ))}
      </AccordionWrapper>
    </Box>
  );
};

ChallengeTestCaseAccordion.displayName = "ChallengeTestCaseAccordion";

export default ChallengeTestCaseAccordion;
