import React from "react";
import { RemoteParticipant, RemoteTrack, RemoteVideoTrack } from "twilio-video";

import { ParticipantTrackSubscriber } from "./ParticipantTrackSubscriber";
import { isRemoteVideoTrack } from "./privateHelper";
import { ParticipantId } from "./types";

/**
 * This hook returns a map of RemoteVideoTracks for each RemoteParticipant.
 */
export const useRemoteParticipantVideoTracks = (participants: RemoteParticipant[]): Record<ParticipantId, RemoteVideoTrack> => {
  const subscriberRef = React.useRef<Record<ParticipantId, ParticipantTrackSubscriber>>({});
  const [tracks, setTracks] = React.useState<Record<ParticipantId, RemoteVideoTrack>>({});

  // Initialize tracks.
  React.useEffect(() => {
    const newTracks: Record<ParticipantId, RemoteVideoTrack> = {};
    participants.forEach(participant => {
      const subscribedTracks = Array.from(participant.tracks.values()).flatMap(trackPublication =>
        trackPublication.track ? [trackPublication.track] : [],
      );
      const videoTrack = subscribedTracks.find(track => isRemoteVideoTrack(track));
      if (!videoTrack || !isRemoteVideoTrack(videoTrack)) {
        return;
      }
      newTracks[participant.identity] = videoTrack;
    });
    setTracks(newTracks);
    // FIXME: deps only participant length. If you add participants, "Maximum update depth exceeded" will occur
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participants.length]);

  // Subscribe to new tracks.
  React.useEffect(() => {
    const subscriber = subscriberRef.current;
    participants.forEach(participant => {
      const handleSubscribed = (track: RemoteTrack) => {
        if (!isRemoteVideoTrack(track)) {
          return;
        }
        setTracks(prevTracks => {
          return {
            ...prevTracks,
            [participant.identity]: track,
          };
        });
      };
      const handleUnsubscribed = (track: RemoteTrack) => {
        if (!isRemoteVideoTrack(track)) {
          return;
        }
        setTracks(prevTracks => {
          const newTracks = { ...prevTracks };
          delete newTracks[participant.identity];
          return newTracks;
        });
      };
      subscriber[participant.identity] = new ParticipantTrackSubscriber(participant, handleSubscribed, handleUnsubscribed);
    });

    return () => {
      participants.forEach(participant => {
        subscriber[participant.identity]?.unsubscribe();
      });
    };
  }, [participants]);

  return tracks;
};
