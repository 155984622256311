import { useTranslation } from "@hireroo/i18n";
import { SupportLanguage } from "@hireroo/i18n";
import * as z from "zod";

import { useEmailListItem } from "./FieldValidator";

export const useTalentInviteByLinkSchema = () => {
  return z.object({
    kind: z.literal("LINK"),
    language: z.enum([SupportLanguage.EN, SupportLanguage.JA]).default(SupportLanguage.JA),
  });
};

export type TalentInviteSchemaByLink = z.infer<ReturnType<typeof useTalentInviteByLinkSchema>>;

export const useTalentInviteByEmailSchema = () => {
  const { t } = useTranslation();
  const emailListItem = useEmailListItem();
  return z.object({
    kind: z.literal("EMAIL"),
    emails: z.array(emailListItem).min(1, {
      message: t("必須項目です"),
    }),
    language: z.enum([SupportLanguage.EN, SupportLanguage.JA]).default(SupportLanguage.JA),
  });
};

export type TalentInviteSchemaByEmail = z.infer<ReturnType<typeof useTalentInviteByEmailSchema>>;

export const useTalentInviteSchema = () => {
  const linkSchema = useTalentInviteByLinkSchema();
  const emailSchema = useTalentInviteByEmailSchema();
  return z.union([linkSchema, emailSchema]);
};

export type TalentInviteSchema = z.infer<ReturnType<typeof useTalentInviteSchema>>;
