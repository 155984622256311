import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateProjectTestReportPropsArgs, useGenerateProps } from "./useGenerateProps";

export type ProjectTestReportContainerProps = GenerateProjectTestReportPropsArgs;

const ProjectTestReportContainer: React.FC<ProjectTestReportContainerProps> = props => {
  const projectTestReportProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ProjectTestReport {...projectTestReportProps} />
    </ErrorBoundary>
  );
};

ProjectTestReportContainer.displayName = "ProjectTestReportContainer";

export default withErrorBoundary(ProjectTestReportContainer, {});
