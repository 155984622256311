import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import * as React from "react";

export type StepContentLayoutLowerProps = {
  actionComponent?: React.ReactNode;
  previousButton?: ButtonProps;
  nextButton: ButtonProps;
};

const StepContentLayoutLower: React.FC<React.PropsWithChildren<StepContentLayoutLowerProps>> = props => {
  return (
    <Box>
      <Box mt={2}>{props.children}</Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mt={2}>
        {props.previousButton && <Button size="small" color="outline-only" variant="outlined" {...props.previousButton} />}
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Box>{props.actionComponent && props.actionComponent}</Box>
          <Button size="small" {...props.nextButton} />
        </Stack>
      </Stack>
    </Box>
  );
};

StepContentLayoutLower.displayName = "StepContentLayoutLower";

export default StepContentLayoutLower;
