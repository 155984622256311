import * as React from "react";

import OnboardingGuide, { ACTIONS, OnboardingGuideProps, STATUS } from "../../modules/OnboardingGuide/OnboardingGuide";
import { useGenerateChallengeOnboardingGuideForCandidateProps } from "./c/useGenerateChallengeOnboardingGuideForCandidateProps";
import { useGenerateSystemDesignOnboardingGuideForCandidateProps } from "./c/useGenerateSystemDesignOnboardingGuideForCandidateProps";
import { useGenerateChallengeOnboardingGuideForEmployeeProps } from "./e/useGenerateChallengeOnboardingGuideForEmployeeProps";
import { useGenerateDefaultOnboardingGuideForEmployeeProps } from "./e/useGenerateDefaultOnboardingGuideForEmployeeProps";
import { useGenerateSystemDesignOnboardingGuideForEmployeeProps } from "./e/useGenerateSystemDesignOnboardingGuideForEmployeeProps";

type Kind = "CANDIDATE_SYSTEM_DESIGN" | "CANDIDATE_CHALLENGE" | "EMPLOYEE_DEFAULT" | "EMPLOYEE_SYSTEM_DESIGN" | "EMPLOYEE_CHALLENGE" | "SKIP";

const useSteps = (kind: Kind | null): OnboardingGuideProps => {
  const challengeOnboardingGuideForCandidate = useGenerateChallengeOnboardingGuideForCandidateProps();
  const systemDesignOnboardingGuideForCandidateForEmployee = useGenerateSystemDesignOnboardingGuideForCandidateProps();
  const challengeOnboardingGuideForEmployee = useGenerateChallengeOnboardingGuideForEmployeeProps();
  const defaultOnboardingGuideForEmployee = useGenerateDefaultOnboardingGuideForEmployeeProps();
  const systemDesignOnboardingGuideForEmployee = useGenerateSystemDesignOnboardingGuideForEmployeeProps();
  switch (kind) {
    case "CANDIDATE_CHALLENGE":
      return challengeOnboardingGuideForCandidate;
    case "CANDIDATE_SYSTEM_DESIGN":
      return systemDesignOnboardingGuideForCandidateForEmployee;
    case "EMPLOYEE_CHALLENGE":
      return challengeOnboardingGuideForEmployee;
    case "EMPLOYEE_DEFAULT":
      return defaultOnboardingGuideForEmployee;
    case "EMPLOYEE_SYSTEM_DESIGN":
      return systemDesignOnboardingGuideForEmployee;
    case "SKIP":
    case null: {
      return { steps: [] };
    }
    default: {
      throw new Error(`Not support type: ${kind}`);
    }
  }
};

export type RemoteInterviewTutorialProps = {
  kind: Kind | null;
  runTutorial: boolean;
  onChangeRunTutorial: (runTutorial: boolean) => void;
};

const RemoteInterviewTutorial: React.FC<RemoteInterviewTutorialProps> = props => {
  const { runTutorial } = props;
  const { steps } = useSteps(props.kind);
  const onboardingGuideProps: OnboardingGuideProps = {
    disableScrolling: true,
    steps: steps,
    run: props.kind !== null ? runTutorial : false,
    callback: data => {
      switch (data.status) {
        case STATUS.FINISHED:
        case STATUS.SKIPPED: {
          props.onChangeRunTutorial(false);
          break;
        }
      }
      switch (data.action) {
        case ACTIONS.CLOSE: {
          props.onChangeRunTutorial(false);
          break;
        }
      }
    },
  };
  /**
   * If you don't change the instance when the `kind` changes, it won't work properly.
   */
  return <OnboardingGuide key={props.kind} {...onboardingGuideProps} />;
};

RemoteInterviewTutorial.displayName = "RemoteInterviewTutorial";

export default RemoteInterviewTutorial;
