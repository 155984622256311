import { TestResults } from "@hireroo/evaluation-result-interface";
import { useTranslation } from "@hireroo/i18n";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";
import * as z from "zod";

import ResultBlocks from "../ResultBlocks/ResultBlocks";
import TestCase, { TestCaseProps } from "./parts/TestCase/TestCase";

export type TestResultProps = {
  rawData: string;
  onError: (error: z.ZodError) => void;
};

const TestResult: React.FC<TestResultProps> = props => {
  const { t } = useTranslation();
  const result = TestResults.safeParse(JSON.parse(props.rawData));
  const { onError } = props;

  React.useEffect(() => {
    if (result.error) {
      onError(result.error);
    }
  }, [onError, result.error]);

  if (!result.success) {
    return (
      <Typography fontSize={14}>{t("データの取得に失敗しました。しばらくしてから再度お試しいただくか、運営までお問合せください。")}</Typography>
    );
  }

  return (
    <Stack>
      {result.data.map((testResult, index) => {
        const testCase: TestCaseProps = {
          title: `${t("テストケース")} ${index + 1}`,
          icon: { kind: testResult.is_passed ? "CHECK" : "CROSS" },
        };
        return (
          <TestCase key={`testcase-${index}`} {...testCase}>
            <ResultBlocks {...testResult} />
          </TestCase>
        );
      })}
    </Stack>
  );
};

TestResult.displayName = "TestResult";

export default TestResult;
