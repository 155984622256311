import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Auth, Company, Role } from "@hireroo/app-store/essential/employee";
import { MemberSettings } from "@hireroo/app-store/widget/e/MemberSettings";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import * as TimeFormatter from "@hireroo/formatter/time";
import * as Graphql from "@hireroo/graphql/client/graphql-request";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateCurrentOriginUrl, generateHelpCenterUrl } from "@hireroo/router/api";
import { useHelpCenterNavigate } from "@hireroo/router/hooks";
import { MemberEditRoleForm } from "@hireroo/validator";
import * as Sentry from "@sentry/react";
import * as React from "react";

import { useGenerateAddMembersToGroupsProps } from "./useGenerateAddMembersToGroupsProps";

type Item = Widget.EmployeeMemberSettingsProps["memberTable"]["items"][number];
const isValidRole = (role: string): role is Graphql.Role => {
  return ([Graphql.Role.Admin, Graphql.Role.Manager, Graphql.Role.Engineer] as string[]).includes(role);
};

const RoleMap: Record<Graphql.Role, MemberEditRoleForm.MemberEditRoleFormSchema["role"]> = {
  ENGINEER: "ENGINEER",
  MANAGER: "MANAGER",
  ADMIN: "ADMIN",
  CANDIDATE: "ENGINEER",
  EMPLOYEE: "ENGINEER",
  UNKNOWN: "ENGINEER",
};

export type GenerateEmployeeMemberSettingsPropsArgs = {
  reload: () => void;
};

export const useGenerateProps = (args: GenerateEmployeeMemberSettingsPropsArgs): Widget.EmployeeMemberSettingsProps => {
  const { reload } = args;
  const companyId = Company.useStrictActiveCompanyId();
  const employees = MemberSettings.useFilteredEmployees();
  const companyEmployeeMap = MemberSettings.useCompanyEmployeeMap();
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const client = getGraphqlClient();
  const matchingRole = Role.useMatchingRole();
  const currentUserId = Auth.useCurrentUid();
  const convertRoleToText = Role.useRoleToTextConverter();
  const dialogStatus = MemberSettings.useDialogStatus();
  const employeeInvitationState = MemberSettings.useEmployeeInvitation();
  const helpCenterNavigate = useHelpCenterNavigate(lang);
  const selectedMembers = MemberSettings.useSelectedMembers();
  const addMembersToGroupsProps = useGenerateAddMembersToGroupsProps(args);
  const items = React.useMemo(() => {
    return employees.map((employee): Item => {
      const companyEmployee = companyEmployeeMap[employee.id];
      return {
        checkbox: {
          disabled: !matchingRole.ADMIN_ONLY.matched || employee.id === currentUserId,
          title: employee.id === currentUserId ? t("自分自身に対して操作はできません。") : matchingRole.ADMIN_ONLY.messageOnUnmatched,
          checked: selectedMembers.employees.some(selected => selected.id === employee.id),
        },
        memberId: employee.id,
        photoUrl: employee.photoUrl,
        displayName: employee.displayName,
        email: employee.email,
        role: convertRoleToText(companyEmployee?.role || "UNKNOWN"),
        lastSignInDate: companyEmployee?.lastSignedInAtSeconds
          ? TimeFormatter.unixToDateFormat(companyEmployee.lastSignedInAtSeconds)
          : t("日付情報なし"),
        onSelect: selected => {
          MemberSettings.selectMember(selected, employee.id);
        },
        editMenuButton: {
          disabled: !matchingRole.ADMIN_ONLY.matched || employee.id === currentUserId,
          title: employee.id === currentUserId ? t("自分自身に対して操作はできません。") : matchingRole.ADMIN_ONLY.messageOnUnmatched,
        },
        items: [
          {
            value: "EDIT_ROLE",
            startIcon: "EDIT",
            displayName: t("権限の編集"),
            onClick: () => {
              MemberSettings.updateDialogStatus(`EDIT_ROLE_OPEN_${employee.id}`);
            },
          },
          {
            value: "ADD_GROUP",
            startIcon: "PERSON_ADD",
            displayName: t("グループへ追加"),
            onClick: () => {
              MemberSettings.clearSelectedMembers();
              MemberSettings.selectMember(true, employee.id);
              MemberSettings.updateDialogStatus("ADD_GROUP_OPEN");
            },
          },
          {
            value: "DELETE_MEMBER",
            startIcon: "DELETE",
            color: "error",
            displayName: t("メンバーの削除"),
            onClick: () => {
              MemberSettings.updateDialogStatus(`DELETE_MEMBER_OPEN_${employee.id}`);
            },
          },
        ],
        deleteMember: {
          dialog: {
            open: dialogStatus === `DELETE_MEMBER_OPEN_${employee.id}`,
            onClose: () => {
              MemberSettings.updateDialogStatus("CLOSE");
            },
            noButton: {
              onClick: () => {
                MemberSettings.updateDialogStatus("CLOSE");
              },
            },
          },
          employeeName: employee.displayName || employee.email,
          onSubmit: async () => {
            await client
              .DeleteCompanyEmployeeForMemberSettings({
                companyId: companyId,
                employeeId: employee.id,
              })
              .then(() => {
                Snackbar.notify({
                  severity: "success",
                  message: t("メンバーの削除に成功しました。"),
                });
                MemberSettings.clear();
                MemberSettings.updateDialogStatus("CLOSE");
                reload();
              })
              .catch(error => {
                Sentry.captureException(error);
                const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                  error,
                  t("メンバーの削除に失敗しました。しばらくしてから再度お試しください。"),
                );
                Snackbar.notify({
                  severity: "error",
                  message: errorNotification.message,
                });
              });
          },
          description: t(
            "削除されたメンバーは会社アカウントにアクセスできなくなります。また削除されたメンバーだけが閲覧権限を持つテストが存在する場合、そのテストの閲覧が不可能となりますのでご注意ください。",
          ),
        },
        editRole: {
          dialog: {
            open: dialogStatus === `EDIT_ROLE_OPEN_${employee.id}`,
            onClose: () => {
              MemberSettings.updateDialogStatus("CLOSE");
            },
            noButton: {
              onClick: () => {
                MemberSettings.updateDialogStatus("CLOSE");
              },
            },
          },
          employeeName: employee.displayName || employee.email,
          helpLink: {
            onClick: () => {
              helpCenterNavigate("MEMBER_AUTHORIZATION", {
                _blank: true,
              });
            },
            href: generateHelpCenterUrl(lang, "MEMBER_AUTHORIZATION"),
          },
          defaultValues: {
            role: RoleMap[companyEmployee?.role || Graphql.Role.Unknown],
          },
          onSubmit: async fields => {
            await client
              .UpdateCompanyEmployeeForMemberSettings({
                companyId,
                employeeId: employee.id,
                role: fields.role,
              })
              .then(({ updateCompanyEmployee }) => {
                if (!updateCompanyEmployee) {
                  return;
                }
                Snackbar.notify({
                  severity: "success",
                  message: t("権限の更新に成功しました。"),
                });
                MemberSettings.updateDialogStatus("CLOSE");
                MemberSettings.EditRoleAction.updateRole(updateCompanyEmployee.employeeId, updateCompanyEmployee.role);
              })
              .catch(error => {
                Sentry.captureException(error);
                const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                  error,
                  t("権限の更新に失敗しました。しばらくしてから再度お試しください。"),
                );
                Snackbar.notify({
                  severity: "error",
                  message: errorNotification.message,
                });
              });
          },
        },
      };
    });
  }, [
    client,
    companyEmployeeMap,
    companyId,
    convertRoleToText,
    currentUserId,
    dialogStatus,
    employees,
    helpCenterNavigate,
    lang,
    matchingRole.ADMIN_ONLY.matched,
    matchingRole.ADMIN_ONLY.messageOnUnmatched,
    reload,
    selectedMembers.employees,
    t,
  ]);

  return {
    title: t("メンバー"),
    memberTable: {
      title: t("メンバー"),
      items: items,
      showingTargets: ["ROLE", "LAST_SIGNED_IN"],
      emptyMessage: t("メンバーが見つかりません。"),
    },
    header: {
      unselectButton: {
        onClick: () => {
          MemberSettings.clearSelectedMembers();
        },
        disabled: selectedMembers.employees.length === 0,
      },
      groupAddButton: {
        onClick: () => {
          MemberSettings.updateDialogStatus("ADD_GROUP_OPEN");
        },
        disabled: selectedMembers.employees.length === 0,
      },
      deleteButton: {
        onClick: () => {
          MemberSettings.updateDialogStatus("DELETE_MEMBERS_OPEN");
        },
        disabled: selectedMembers.employees.length === 0,
      },
      searchTextField: {
        placeholder: t("メールアドレス、名前を検索"),
        onSubmit: field => {
          MemberSettings.updateFilterText(field.textFilter);
        },
      },
      inviteButton: {
        onClick: () => {
          MemberSettings.updateDialogStatus("INVITATION_OPEN");
        },
      },
      inviteEmployee: {
        dialog: {
          open: dialogStatus === "INVITATION_OPEN",
          onClose: () => {
            MemberSettings.updateDialogStatus("CLOSE");
            MemberSettings.EmployeeInvitationAction.clear();
          },
          noButton: {
            onClick: () => {
              MemberSettings.updateDialogStatus("CLOSE");
              MemberSettings.EmployeeInvitationAction.clear();
            },
          },
        },
        onSubmit: async field => {
          if (!isValidRole(field.role)) {
            return;
          }
          MemberSettings.EmployeeInvitationAction.setStatus("PENDING");
          await client
            .CreateEmployeeInvitation({
              companyId,
              employeeId: currentUserId,
              invitationMethod: "EMAIL",
              emailAddresses: field.emails.map(({ value }) => value),
              role: field.role,
              invitationLanguage: (
                {
                  ja: Graphql.InvitationLanguage.Ja,
                  en: Graphql.InvitationLanguage.En,
                } as const
              )[field.language],
            })
            .then(({ createEmployeeInvitation: invitation }) => {
              MemberSettings.EmployeeInvitationAction.setStatus("PUBLISHED");
              if (!invitation) {
                return;
              }
              MemberSettings.updateDialogStatus("CLOSE");
              MemberSettings.EmployeeInvitationAction.setInvitationId(invitation.id);
              Snackbar.notify({
                severity: "success",
                message: t("招待リンクの送信に成功しました。"),
              });
            })
            .catch(error => {
              MemberSettings.EmployeeInvitationAction.setStatus("PUBLISHED");
              Sentry.captureException(error);
              const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                error,
                t("招待リンクの送信に失敗しました。しばらくしてから再度お試しください。"),
              );
              Snackbar.notify({
                severity: "error",
                message: errorNotification.message,
              });
            });
        },
        onCreateInviteLink: async field => {
          if (!isValidRole(field.role)) {
            return;
          }
          MemberSettings.EmployeeInvitationAction.setStatus("PENDING");
          await client
            .CreateEmployeeInvitation({
              companyId,
              employeeId: currentUserId,
              invitationMethod: "LINK",
              emailAddresses: [],
              role: field.role,
              invitationLanguage: (
                {
                  ja: Graphql.InvitationLanguage.Ja,
                  en: Graphql.InvitationLanguage.En,
                } as const
              )[field.language],
            })
            .then(({ createEmployeeInvitation: invitation }) => {
              MemberSettings.EmployeeInvitationAction.setStatus("PUBLISHED");
              if (!invitation) {
                return;
              }
              MemberSettings.EmployeeInvitationAction.setInvitationId(invitation.id);
              Snackbar.notify({
                severity: "success",
                message: t("招待リンクの生成に成功しました。リンクをコピーして共有してください。"),
              });
            })
            .catch(error => {
              MemberSettings.EmployeeInvitationAction.setStatus("PUBLISHED");
              Sentry.captureException(error);
              const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                error,
                t("招待リンクの生成に失敗しました。しばらくしてから再度お試しください。"),
              );
              Snackbar.notify({
                severity: "error",
                message: errorNotification.message,
              });
            });
        },
        onReCreateInviteLink: async field => {
          if (!isValidRole(field.role)) {
            return;
          }
          MemberSettings.EmployeeInvitationAction.setStatus("PENDING");
          await client
            .CreateEmployeeInvitation({
              companyId,
              employeeId: currentUserId,
              invitationMethod: "LINK",
              emailAddresses: [],
              role: field.role,
              invitationLanguage: (
                {
                  ja: Graphql.InvitationLanguage.Ja,
                  en: Graphql.InvitationLanguage.En,
                } as const
              )[field.language],
            })
            .then(({ createEmployeeInvitation: invitation }) => {
              MemberSettings.EmployeeInvitationAction.setStatus("PUBLISHED");
              if (!invitation) {
                return;
              }
              MemberSettings.EmployeeInvitationAction.setInvitationId(invitation.id);
              Snackbar.notify({
                severity: "success",
                message: t("招待リンクの生成に成功しました。リンクをコピーして共有してください。"),
              });
            })
            .catch(error => {
              MemberSettings.EmployeeInvitationAction.setStatus("PUBLISHED");
              Sentry.captureException(error);
              const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                error,
                t("招待リンクの生成に失敗しました。しばらくしてから再度お試しください。"),
              );
              Snackbar.notify({
                severity: "error",
                message: errorNotification.message,
              });
            });
        },
        helpLink: {
          onClick: () => {
            helpCenterNavigate("INVITE_MEMBERS", {
              _blank: true,
            });
          },
          href: generateHelpCenterUrl(lang, "INVITE_MEMBERS"),
        },
        shareLink: {
          createLinkbutton: {
            loading: employeeInvitationState.status === "PENDING",
          },
          copyLinkButton: {
            onCopy: () => {
              Snackbar.notify({
                severity: "info",
                message: t("クリップボードにコピーされました"),
              });
            },
          },
          invitationLink: employeeInvitationState.id
            ? generateCurrentOriginUrl("/e/invitations/:id", {
                pathParams: {
                  id: employeeInvitationState.id,
                },
              })
            : undefined,
        },
        roleField: {
          value: Graphql.Role.Engineer,
          menuItems: [
            {
              value: Graphql.Role.Admin,
              label: t("管理者"),
            },
            {
              value: Graphql.Role.Manager,
              label: t("マネージャー"),
            },
            {
              value: Graphql.Role.Engineer,
              label: t("エンジニア"),
            },
          ],
        },
      },
    },
    deleteMembers: {
      dialog: {
        open: dialogStatus === "DELETE_MEMBERS_OPEN",
        onClose: () => {
          MemberSettings.updateDialogStatus("CLOSE");
        },
        noButton: {
          onClick: () => {
            MemberSettings.updateDialogStatus("CLOSE");
          },
        },
        title: t("メンバーを削除する"),
      },
      description: t("以下のメンバーを削除します。削除されたメンバーは会社アカウントにアクセスできなくなります。よろしいですか？"),
      members: selectedMembers.employees.map(employee => {
        return {
          displayName: employee.displayName,
          src: employee.photoUrl,
          email: employee.email,
        };
      }),
      onSubmit: async () => {
        await client
          .DeleteMultiCompanyEmployeesForMemberSettings({
            companyId,
            employeeIds: selectedMembers.employees.map(employee => employee.id),
          })
          .then(() => {
            Snackbar.notify({
              severity: "success",
              message: t("メンバーの削除に成功しました。"),
            });
            MemberSettings.clear();
            MemberSettings.updateDialogStatus("CLOSE");
            reload();
          })
          .catch(error => {
            Sentry.captureException(error);
            const errorNotification = ErrorHandlingHelper.generateErrorNotification(
              error,
              t("メンバーの削除に失敗しました。しばらくしてから再度お試しください。"),
            );
            Snackbar.notify({
              severity: "error",
              message: errorNotification.message,
            });
          });
      },
    },
    addMembersToGroups: addMembersToGroupsProps,
  };
};
