import { ChallengeCodingEditor } from "@hireroo/app-store/widget/shared/ChallengeCodingEditor";
import { updateHashParam } from "@hireroo/router/api";

import { generateHashParam } from "./privateHelper";

export const startSubscribeWebSearchParams = () => {
  return ChallengeCodingEditor.subscribeWebSearchParameters(params => {
    updateHashParam(generateHashParam(params.query, params.page));
  });
};
