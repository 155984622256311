import { proxy } from "valtio";

import type * as Types from "./types";

export const state = proxy<Types.State>({
  submitValues: {
    TEST_QUESTION_SETUP: {
      name: "",
      companyId: 1,
      entityTracks: [],
      timeLimitType: "RECOMMENDED",
      timeLimitMinutes: 10,
    },
    TEST_SETUP: {
      nextStartScheduleAt: new Date(),
      examInterval: "ONE_MONTH",
      remindBeforeDays: Number.NaN,
    },
    REPORT_SETUP: {
      memo: "",
      isPublic: true,
      editorUid: null,
      viewers: [],
      reportSettings: {
        showAnswer: true,
        showPlayback: true,
        showRelativeEvaluation: true,
      },
    },
    TEST_INVITE_SETUP: {
      talent: {
        talentId: "",
        type: "TALENT",
      },
      messageForTalent: "",
    },
  },
});
