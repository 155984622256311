import { Role } from "@hireroo/app-store/essential/employee";
import { AssessmentsIdDetailStore } from "@hireroo/app-store/page/e/assessments_id_detail";
import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { useTransitionNavigate } from "@hireroo/router/hooks";

import { useGenerateExamSelector } from "./useGenerateExamSelector";

export type GenerateDetailHeaderPropsArgs = {
  assessmentId: string;
};

export const useGenerateDetailHeaderProps = (
  _args: GenerateDetailHeaderPropsArgs,
): Widget.AssessmentDetailForEmployeeProps["detailHeaderPage"] => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const assessment = AssessmentsIdDetailStore.useAssessment();
  const navigate = useTransitionNavigate();
  const matchingRole = Role.useMatchingRole();
  const currentRoleDisplayText = Role.useCurrentRoleDisplayText();
  const expectedRole = Role.useRoleToText("MANAGER");
  const selectedExamId = AssessmentsIdDetailStore.useSelectedExamId();
  const examSelector = useGenerateExamSelector();

  return {
    backButton: {
      onClick: () => {
        navigate("/e/assessments");
      },
    },
    reviewButton: selectedExamId
      ? {
          onClick: () => {
            AssessmentsIdDetailStore.updatePopperStatus("OPEN");
          },
        }
      : undefined,
    examSelector: examSelector && examSelector.items.length > 0 ? examSelector : undefined,
    moreButton: {
      options: [
        {
          value: "share-settings",
          displayName: t("共有項目設定"),
          onClick: () => {
            AssessmentsIdDetailStore.updateDialogStatus("OPEN_SHARE_SETTING");
          },
          disabled: !matchingRole.ADMIN_OR_MANAGER.matched,
          disabledText: t2("PermissionTooltip", {
            actualRole: currentRoleDisplayText,
            expectedRole: expectedRole,
          }),
          divider: false,
          startIcon: "SHARE",
        },
        {
          value: "edit",
          displayName: t("編集"),
          onClick: () => {
            navigate("/e/assessments/:id/update", {
              pathParams: {
                id: assessment.assessmentId,
              },
            });
          },
          disabled: !matchingRole.ADMIN_OR_MANAGER.matched,
          disabledText: t2("PermissionTooltip", {
            actualRole: currentRoleDisplayText,
            expectedRole: expectedRole,
          }),
          divider: false,
          startIcon: "EDIT",
        },
        {
          value: "activate",
          displayName: assessment.status === "RUNNING" ? t("停止") : t("再開"),
          onClick: () => {
            AssessmentsIdDetailStore.updateDialogStatus("OPEN_ACTIVATE");
          },
          disabled: !matchingRole.ADMIN_OR_MANAGER.matched,
          disabledText: t2("PermissionTooltip", {
            actualRole: currentRoleDisplayText,
            expectedRole: expectedRole,
          }),
          divider: false,
          startIcon: assessment.status === "RUNNING" ? "STOP" : "PLAY",
        },
        // {
        //   value: "from-test",
        //   displayName: t("コピーして新規作成"),
        //   onClick: () => {
        //     navigate("/e/interviews/create", {
        //       queryParams: {
        //         id: assessment.id,
        //       },
        //     });
        //   },
        //   disabled: !matchingRole.ADMIN_OR_MANAGER.matched,
        //   disabledText: t2("PermissionTooltip", {
        //     actualRole: currentRoleDisplayText,
        //     expectedRole: expectedRole,
        //   }),
        //   divider: false,
        //   startIcon: "CONTENT_COPY",
        // },
        // {
        //   value: "test-template",
        //   displayName: t("コピーしてテンプレート作成"),
        //   onClick: () => {
        //     navigate("/e/templates/create", {
        //       queryParams: {
        //         id: assessment.id,
        //       },
        //     });
        //   },
        //   disabled: !matchingRole.ADMIN_OR_MANAGER.matched,
        //   disabledText: t2("PermissionTooltip", {
        //     actualRole: currentRoleDisplayText,
        //     expectedRole: expectedRole,
        //   }),
        //   divider: false,
        //   startIcon: "CONTENT_PASTE",
        // },
        {
          value: "delete",
          displayName: t("削除する"),
          color: "error",
          onClick: () => {
            AssessmentsIdDetailStore.updateDialogStatus("OPEN_DELETION");
          },
          disabled: !matchingRole.ADMIN_OR_MANAGER.matched,
          disabledText: t2("PermissionTooltip", {
            actualRole: currentRoleDisplayText,
            expectedRole: expectedRole,
          }),
          divider: false,
          startIcon: "DELETE",
        },
      ],
    },
  };
};
