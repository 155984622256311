import { useTranslation } from "@hireroo/i18n";
import { UserSettingsForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { LanguageSelectorProps } from "../../modules/LanguageSelector/LanguageSelector";
import MailAddressField, { MailAddressFieldProps } from "../../modules/MailAddressField/MailAddressField";
import ButtonWithTooltip, { ButtonWithTooltipProps } from "../../primitive/Button/ButtonWithTooltip/ButtonWithTooltip";
import { FormSubmitButtonProps } from "../../primitive/Button/FormSubmitButton/FormSubmitButton";
import DisplayNameField, { DisplayNameFieldProps } from "./parts/DisplayNameField/DisplayNameField";
import ImageCropDialog, { ImageCropDialogProps } from "./parts/ImageCropDialog/ImageCropDialog";
import PhotoURLField, { PhotoURLFieldProps } from "./parts/PhotoURLField/PhotoURLField";

export type UserProfileProps = {
  isLoading: boolean;
  formSubmitButton: FormSubmitButtonProps;
  editButton: ButtonWithTooltipProps;
  onSubmit: SubmitHandler<UserSettingsForm.UserSettingsFormSchema>;
  displayNameField: DisplayNameFieldProps;
  mailAddressField: MailAddressFieldProps;
  resendVerificationButton?: Pick<LoadingButtonProps, "onClick" | "loading">;
  languageSelector: LanguageSelectorProps;
  photoURLField: PhotoURLFieldProps;
  imageCropDialog: ImageCropDialogProps;
};

const UserProfile: React.FC<UserProfileProps> = props => {
  const { t } = useTranslation();
  const validateSchema = UserSettingsForm.useUserSettingsFormSchema();
  const { onSubmit } = props;

  const method = useForm({
    resolver: zodResolver(validateSchema),
    mode: "onChange",
    defaultValues: {
      displayName: props.displayNameField.value ?? "",
      email: props.mailAddressField.value ?? "",
      language: props.languageSelector.value ?? "ja",
    },
  });

  const hasError = method.formState.errors && Object.keys(method.formState.errors).length !== 0;

  const handleSubmit = React.useCallback(() => {
    method.handleSubmit(fields => {
      onSubmit(fields);
    })();
  }, [method, onSubmit]);

  const disabled = React.useMemo(() => {
    return props.isLoading || hasError;
  }, [hasError, props.isLoading]);

  const imageCropDialog: ImageCropDialogProps = React.useMemo(() => {
    return {
      ...props.imageCropDialog,
      onCropComplete: croppedAreaPixels => {
        props.imageCropDialog.onCropComplete(croppedAreaPixels);
      },
    };
  }, [props.imageCropDialog]);

  const mailAddressField: MailAddressFieldProps = {
    ...props.mailAddressField,
    sx: { width: "50%" },
    InputProps: {
      endAdornment: props.resendVerificationButton && (
        <InputAdornment position="end">
          <LoadingButton size="small" startIcon={<SendIcon />} {...props.resendVerificationButton}>
            {t("認証メールを再送する")}
          </LoadingButton>
        </InputAdornment>
      ),
    },
  };
  const submitButton: ButtonWithTooltipProps = {
    onClick: handleSubmit,
    variant: "contained",
    disabled: disabled || !method.formState.isDirty,
  };

  return (
    <FormProvider {...method}>
      <Box width="100%">
        <Stack direction="column" spacing={3}>
          <PhotoURLField {...props.photoURLField} />
          <DisplayNameField name="displayName" sx={{ width: "50%" }} {...props.displayNameField} />
          <MailAddressField name="email" {...mailAddressField} disabled />
          {/*<LanguageSelector name="language" disabled={mode === "READ"} />*/}
          <Box display="flex" justifyContent="right">
            <ButtonWithTooltip {...submitButton}>{t("変更内容を保存")}</ButtonWithTooltip>
          </Box>
        </Stack>

        <ImageCropDialog {...imageCropDialog} />
      </Box>
    </FormProvider>
  );
};

UserProfile.displayName = "UserProfile";

export default UserProfile;
