import { useTranslation } from "@hireroo/i18n";
import HelpIcon from "@mui/icons-material/Help";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

import ChallengeIDEConsoleOutputTable from "../../../../../usecase/ChallengeIDEConsoleOutputTable/ChallengeIDEConsoleOutputTable";
import ConsoleOutput from "./ConsoleOutput";

type Output = {
  expected?: string;
  output: string;
  status: string;
  log?: string;
};

export type OutputStatusSectionProps = {
  output: Output;
  variant: "ALGORITHM" | "DATABASE" | "CLASS";
  AdditionalOutput?: React.ReactNode;
};

const OutputStatusSection: React.FC<OutputStatusSectionProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { output } = props;
  return (
    <Box display="flex" flexDirection="column">
      {output.expected && output.output && (
        <Box>
          <Stack direction="row">
            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
              {t("実行結果")}
            </Typography>
            {props.variant === "DATABASE" && (
              <Tooltip title={`${t("クエリの実行結果と期待値を表示します。実行結果がBooleanの場合は0か1を返します。")}`} placement="top">
                <HelpIcon sx={{ color: theme.palette.grey["500"], ml: 1 }} fontSize="small" />
              </Tooltip>
            )}
          </Stack>
          {props.variant === "ALGORITHM" && (
            <Box display="flex" flexDirection="column" gap={2} mt={1}>
              <ConsoleOutput label={t("期待値")} value={output.expected} />
              <ConsoleOutput label={t("出力")} value={output.output} />
            </Box>
          )}

          {props.variant === "DATABASE" && <ChallengeIDEConsoleOutputTable label={t("期待値")} value={output.expected} />}
          {props.variant === "DATABASE" && <ChallengeIDEConsoleOutputTable label={t("出力")} value={output.output} />}

          {props.AdditionalOutput}
        </Box>
      )}

      {output.log && (
        <Box mt={3}>
          <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
            {t("ログ")}
          </Typography>

          <Box sx={{ borderRadius: 1, backgroundColor: "rgb(252, 228, 236)" }} p={1} minHeight="40px" mt={1}>
            <Typography variant="caption" gutterBottom sx={{ color: "error.dark", whiteSpace: "pre-wrap" }}>
              {output.log}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

OutputStatusSection.displayName = "OutputStatusSection";

export default OutputStatusSection;
