import { DEFAULT_PERFORMANCE_TEST_LIMIT_MILLISECONDS } from "@hireroo/app-definition/algorithm";
import { createAnswersInput, createHintInput } from "@hireroo/app-helper/algorithm";
import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Auth } from "@hireroo/app-store/essential/employee";
import { AlgorithmResourceEditor } from "@hireroo/app-store/view-domain/AlgorithmResourceEditor";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useTranslation } from "@hireroo/i18n";
import { Pages, Widget } from "@hireroo/presentation/legacy";
import { AlgorithmSignatureForm } from "@hireroo/validator";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";

import AlgorithmTemplatePreviewFetchContainer from "../../../widget/e/AlgorithmTemplatePreview/FetchContainer";
import { isSignatureDifferent } from "./privateHelper";

export type UseGenerateFunctionDefinitionPropsArgs = {
  companyId: number;
};

type FunctionDefinitionProps = Pages.AlgorithmResourceEditorProps["functionDefinition"];

type FunctionDefinitions = {
  props: FunctionDefinitionProps;
  saveButton: Pages.AlgorithmResourceEditorProps["saveFunctionDefinitionButton"];
  onDraftSave: () => void;
};
export const useGenerateFunctionDefinitionProps = (args: UseGenerateFunctionDefinitionPropsArgs): FunctionDefinitions => {
  const { t } = useTranslation();
  const client = getGraphqlClient();
  const userId = Auth.useCurrentUid();
  const { methods } = Widget.useAlgorithmFunctionDefinitionContext();
  const { method: testCaseMethod } = Widget.useAlgorithmTestCasesFormContext();

  const variant = AlgorithmResourceEditor.useVariant();
  const question = AlgorithmResourceEditor.useQuestion();
  const signatureSchema = AlgorithmSignatureForm.useAlgorithmSignatureSchema();
  const [draftOpen, setDraftOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const inputs = methods.watch("inputs");
  const outputType = methods.watch("outputType");
  const signature: AlgorithmSignatureForm.AlgorithmSignatureSchemaType = React.useMemo(() => {
    return {
      function: "solution",
      inputs: inputs,
      outputs: [{ type: outputType }],
    };
  }, [inputs, outputType]);

  const hasBreakingChange = React.useCallback((): boolean => {
    const inputs = methods.getValues("inputs");
    const outputType = methods.getValues("outputType");
    const signature: AlgorithmSignatureForm.AlgorithmSignatureSchemaType = {
      function: "solution",
      inputs: inputs,
      outputs: [{ type: outputType }],
    };
    if (question?.signature) {
      const result = signatureSchema.safeParse(JSON.parse(question.signature));
      if (result.success) {
        return isSignatureDifferent(result.data, signature);
      } else {
        Sentry.captureException(result.error);
      }
    }
    return false;
  }, [methods, question?.signature, signatureSchema]);

  const handleSaveSignature: (
    isDraft: boolean,
    hasBreakingChange?: boolean,
  ) => SubmitHandler<AlgorithmSignatureForm.AlgorithmSignatureFormSchema> = React.useCallback(
    (isDraft, hasBreakingChange) => fields => {
      if (question) {
        const signature = {
          function: "solution",
          inputs: fields.inputs,
          outputs: [{ type: fields.outputType }],
        };
        client
          .UpdateAlgorithmQuestion({
            updateQuestionInput: {
              questionId: question.questionId,
              oldVersion: question.version,
              newVersion: question.version,
              companyId: args.companyId,
              employeeId: userId,
              variant: question.variant,
              difficulty: question.difficulty,
              timeLimitMin: (question?.timeLimitSeconds || 1800) / 60,
              isPrivate: question.isPrivate,
              status: "DRAFT",
              titleJa: question.titleJa,
              titleEn: question.titleEn,
              descriptionJa: question.descriptionJa,
              descriptionEn: question.descriptionEn,
              correctnessTestCase: hasBreakingChange ? "" : question.correctnessTestCase,
              performanceTestCase: hasBreakingChange ? "" : question.performanceTestCase,
              /**
               * new rpc is not implemented yet so we need to pass undefined
               */
              correctnessTestCaseIds: undefined,
              performanceTestCaseIds: undefined,
              performanceTimeLimitMilliSeconds: hasBreakingChange
                ? DEFAULT_PERFORMANCE_TEST_LIMIT_MILLISECONDS
                : question.performanceTimeLimitMilliSeconds,
              signature: JSON.stringify(signature),
              answers: hasBreakingChange ? [] : createAnswersInput(question.answers),
              hints: hasBreakingChange ? [] : question.hints.map(h => createHintInput(h)),
            },
          })
          .then(res => {
            AlgorithmResourceEditor.updateQuestion(res.updateAlgorithmQuestion);
            if (!isDraft) {
              AlgorithmResourceEditor.updateActiveStep(AlgorithmResourceEditor.STEPS.TEST_CASES);
            }
            if (hasBreakingChange) {
              AlgorithmResourceEditor.clearOutput();
              testCaseMethod.reset();
            }
            Snackbar.notify({
              severity: "success",
              message: t("問題の一時保存が完了しました。"),
            });
          })
          .catch(error => {
            Sentry.captureException(error);
            const errorNotification = ErrorHandlingHelper.generateErrorNotification(
              error,
              t("問題の一時保存に失敗しました。しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
            );
            Snackbar.notify({
              severity: "error",
              message: errorNotification.message,
            });
          })
          .finally(() => {
            setOpen(false);
            setDraftOpen(false);
          });
      }
    },
    [args.companyId, client, question, t, testCaseMethod, userId],
  );

  return {
    props: {
      TemplatePreview: <AlgorithmTemplatePreviewFetchContainer variant={variant} signature={JSON.stringify(signature)} />,
      breakingChangeDialog: {
        open: draftOpen || open,
        message: draftOpen
          ? t(
              "変更を一時保存するためには、このステップ以降に入力された値を削除する必要があります。値を削除して一時保存してよろしいでしょうか？（削除したくない場合は、関数の定義を元に戻す必要があります。）",
            )
          : t(
              "次のステップに遷移するためには、このステップ以降に入力された値を削除する必要があります。値を削除してステップを進めてよろしいでしょうか？（削除したくない場合は、関数の定義を元に戻す必要があります。）",
            ),
        yesButton: {
          onClick: () => {
            methods.handleSubmit(handleSaveSignature(draftOpen, true), () => {
              setOpen(false);
              setDraftOpen(false);
            })();
          },
        },
        noButton: {
          onClick: () => {
            setOpen(false);
            setDraftOpen(false);
          },
        },
      },
    },
    saveButton: {
      onClick: async () => {
        if (hasBreakingChange()) {
          setOpen(true);
        } else {
          await methods.handleSubmit(handleSaveSignature(false))();
        }
      },
      loading: false,
    },
    onDraftSave: () => {
      if (hasBreakingChange()) {
        setDraftOpen(true);
      } else {
        methods.handleSubmit(handleSaveSignature(true))();
      }
    },
  };
};
