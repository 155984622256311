import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type InterviewDetailContainerProps = {};

const InterviewDetailContainer: React.FC<InterviewDetailContainerProps> = () => {
  const interviewDetailProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Pages.ScreeningTestDetailForCandidate {...interviewDetailProps} />
    </ErrorBoundary>
  );
};

InterviewDetailContainer.displayName = "InterviewDetailContainer";

export default withErrorBoundary(InterviewDetailContainer, {});
