import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateAssessmentDetailForTalentPropsArgs, useGenerateProps } from "./useGenerateProps";

export type AssessmentDetailForTalentContainerProps = GenerateAssessmentDetailForTalentPropsArgs;

const AssessmentDetailForTalentContainer: React.FC<AssessmentDetailForTalentContainerProps> = props => {
  const assessmentDetailForTalentProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.AssessmentDetailForTalent {...assessmentDetailForTalentProps} />
    </ErrorBoundary>
  );
};

AssessmentDetailForTalentContainer.displayName = "AssessmentDetailForTalentContainer";

export default withErrorBoundary(AssessmentDetailForTalentContainer, {});
