import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";

import AppealMessageInReport, { AppealMessageInReportProps } from "../../../modules/AppealMessageInReport/AppealMessageInReport";
import PlaybackModeSwitcher, { PlaybackModeSwitcherProps } from "../../../modules/PlaybackModeSwitcher/PlaybackModeSwitcher";

export type ReportProjectPlaybackSectionProps = {
  playbackModeSwitcher?: PlaybackModeSwitcherProps;
  ContentsViewer?: React.ReactNode;
  appealMessageArea?: Omit<AppealMessageInReportProps, "title">;
};

const ReportProjectPlaybackSection: React.FC<ReportProjectPlaybackSectionProps> = props => {
  const { t } = useTranslation();
  const appealMessageAreaProps: AppealMessageInReportProps = {
    body: props.appealMessageArea ? props.appealMessageArea.body : t("受験者が入力した説明文はありません。"),
    title: t("候補者による提出されたフローチャートの説明を確認することができます。"),
  };
  return (
    <Box>
      <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} mb={3}>
        <Typography
          sx={theme => ({
            fontWeight: "bold",
            color: theme.palette.text.secondary,
            fontSize: 16,
          })}
        >
          {t("提出コード")}
        </Typography>
        {props.playbackModeSwitcher && <PlaybackModeSwitcher {...props.playbackModeSwitcher} />}
      </Box>

      <Box display="flex" flexDirection="row" height="100%" width="100%" position="relative" mb={2}>
        {props.ContentsViewer && (
          <Box height="100%" width="100%">
            {props.ContentsViewer}
          </Box>
        )}
      </Box>
      <AppealMessageInReport {...appealMessageAreaProps} />
    </Box>
  );
};

ReportProjectPlaybackSection.displayName = "ReportProjectPlaybackSection";

export default ReportProjectPlaybackSection;
