import { subscribeKey } from "valtio/utils";

import { state } from "./State";
import * as Types from "./types";

export type SubscribePdfState = (pdfExport: Types.ExportPdfState) => void;

export const subscribeExportPdfState = (callback: SubscribePdfState) => {
  return subscribeKey(state, "pdfExports", pdfExports => {
    Object.values(pdfExports)
      .filter(pdfExport => pdfExport?.status === "READY")
      .forEach(pdfExport => {
        if (!pdfExport) {
          return;
        }
        callback(pdfExport);
        state.pdfExports[pdfExport.id] = {
          ...pdfExport,
          status: "PENDING",
        };
      });
  });
};
