import { useTranslation } from "@hireroo/i18n";
import { NotebookViewer, NotebookViewerProps } from "@hireroo/jupyter-ui/react/NotebookViewer";
import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Circle from "@mui/icons-material/Circle";
import RemoveIcon from "@mui/icons-material/Remove";
import TabContext from "@mui/lab/TabContext";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled, useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography, { TypographyProps } from "@mui/material/Typography";
import * as React from "react";

import Tab from "../../primitive/Tab/Tab";
import Tabs, { TabsProps } from "../../primitive/Tabs/Tabs";
import ProjectDiffViewer, { ProjectDiffViewerProps } from "./parts/ProjectDiffViewer";

const StyledTab = styled(Tab)(({ theme }) => ({
  "&.MuiTab-root": {
    color: theme.palette.text.primary,
  },
  "&.Mui-selected": {
    color: theme.palette.text.primary,
  },
}));

const StyledIconTypography = styled(Typography)(() => ({
  textTransform: "none",
  fontWeight: "bold",
}));

const StyledTabTypography = styled(Typography)(() => ({
  textTransform: "none",
  fontSize: 14,
}));

const NotebookWrapper = styled(Box)(() => ({
  minHeight: 400,
  maxHeight: 800,
  overflow: "scroll",
}));

export type HistoryContentProps = {
  mode: "DIFF";
  content: ProjectDiffViewerProps;
};

export type JupyterNotebookViewerContentProps = {
  mode: "JUPYTER_NOTEBOOK_VIEWER";
  content: NotebookViewerProps;
};

export type Operation = "UNKNOWN" | "ADDITION" | "DELETION" | "MODIFICATION" | "RENAME";

const signMap: Record<Operation, React.ReactNode> = {
  UNKNOWN: "",
  ADDITION: <AddIcon fontSize="small" sx={{ fontSize: 14 }} />,
  DELETION: <RemoveIcon fontSize="small" sx={{ fontSize: 14 }} />,
  MODIFICATION: <Circle fontSize="small" sx={{ fontSize: 8, top: -3, position: "relative" }} />,
  RENAME: <ArrowForwardIcon fontSize="small" sx={{ fontSize: 14 }} />,
};

type TabItem = {
  value: string;
  tooltipText: string;
  op: Operation;
  displayText: string;
  disabled: boolean;
};

type ViewerProps = HistoryContentProps | JupyterNotebookViewerContentProps;

export type ProjectContentsViewerV3Props = {
  value: string;
  onChange: TabsProps["onChange"];
  tabs: TabItem[];
  viewer: ViewerProps;
};

const ProjectContentsViewerV3: React.FC<ProjectContentsViewerV3Props> = props => {
  const { t } = useTranslation();
  const theme = useTheme();

  const styleMap: Record<Operation, TypographyProps["sx"]> = {
    UNKNOWN: { color: undefined, fontWeight: undefined },
    ADDITION: { color: theme.palette.success.light },
    DELETION: { color: theme.palette.error.light },
    MODIFICATION: { color: theme.palette.warning.light },
    RENAME: { color: theme.palette.common.white },
  };

  return (
    <Box width={"100%"} display={"flex"} flexDirection={"column"} height={"100%"}>
      {props.tabs.length > 0 ? (
        <TabContext value={props.value}>
          <Paper>
            <Tabs variant={"scrollable"} scrollButtons="auto" value={props.value} onChange={props.onChange} style={{ padding: 0 }}>
              {props.tabs.map(tab => (
                <StyledTab
                  value={tab.value}
                  label={
                    <Tooltip title={tab.tooltipText} placement={"top-start"}>
                      <Box display={"flex"} flexDirection={"row"}>
                        <StyledIconTypography mr={1} sx={{ ...styleMap[tab.op] }}>
                          {signMap[tab.op]}
                        </StyledIconTypography>
                        <StyledTabTypography>{tab.displayText}</StyledTabTypography>
                      </Box>
                    </Tooltip>
                  }
                  key={`project-diff-editor-tab-${tab.displayText}`}
                  disabled={tab.disabled}
                  sx={{ "&:disabled": { pointerEvents: "auto" } }}
                />
              ))}
            </Tabs>
          </Paper>
          {props.viewer.mode === "DIFF" && <ProjectDiffViewer {...props.viewer.content} />}
          {props.viewer.mode === "JUPYTER_NOTEBOOK_VIEWER" && (
            <NotebookWrapper>
              <NotebookViewer {...props.viewer.content} />
            </NotebookWrapper>
          )}
          {/*TODO: Implement playback here*/}
        </TabContext>
      ) : (
        <Box>
          <Typography color="text.secondary">{t("全てのファイルに変更がありません。")}</Typography>
        </Box>
      )}
    </Box>
  );
};

ProjectContentsViewerV3.displayName = "ProjectContentsViewerV3";

export default ProjectContentsViewerV3;
