import { safeJsonParse } from "@hireroo/app-helper/parser";
import { QuestionsChallengeId } from "@hireroo/app-store/page/e/questions_challenge_id";
import { AlgorithmSignatureForm, AlgorithmTestCaseForm } from "@hireroo/validator";
import * as React from "react";

export const useAlgorithmSignature = (questionUid: string): AlgorithmSignatureForm.AlgorithmSignatureSchemaType => {
  const questionHooks = QuestionsChallengeId.useCreateQuestionHooks(questionUid);
  const question = questionHooks.useQuestion();

  const signatureSchema = AlgorithmSignatureForm.useAlgorithmSignatureSchema();

  return React.useMemo(() => {
    if (question?.signature) {
      const result = signatureSchema.safeParse(safeJsonParse(question.signature));
      if (result.success) {
        return result.data;
      }
    }
    return {
      function: "solution",
      inputs: [
        {
          name: "",
          type: "string",
        },
      ],
      outputs: [{ type: "string" }],
    };
  }, [question?.signature, signatureSchema]);
};

export const useDatabaseSignature = (questionUid: string): AlgorithmSignatureForm.DatabaseSignatureSchemaType => {
  const questionHooks = QuestionsChallengeId.useCreateQuestionHooks(questionUid);
  const question = questionHooks.useQuestion();
  const signatureSchema = AlgorithmSignatureForm.useDatabaseSignatureSchema();

  return React.useMemo(() => {
    if (question?.signature) {
      const result = signatureSchema.safeParse(safeJsonParse(question.signature));
      if (result.success) {
        return result.data;
      }
    }
    return {
      tables: [
        {
          name: "",
          columns: [
            {
              name: "",
              type: "string",
            },
          ],
        },
      ],
      columns: [
        {
          name: "",
          type: "string",
        },
      ],
    };
  }, [question?.signature, signatureSchema]);
};

export const useClassSignature = (questionUid: string): AlgorithmSignatureForm.ClassSignatureSchemaType => {
  const questionHooks = QuestionsChallengeId.useCreateQuestionHooks(questionUid);
  const question = questionHooks.useQuestion();

  const signatureSchema = AlgorithmSignatureForm.useClassSignatureSchema();

  return React.useMemo(() => {
    if (question?.signature) {
      const result = signatureSchema.safeParse(safeJsonParse(question.signature));
      if (result.success) {
        return result.data;
      }
    }
    return {
      class: "Example",
      constructor: {
        inputs: [
          {
            name: "",
            type: "string",
          },
        ],
        outputs: [{ type: "string" }],
      },
      methods: [
        {
          name: "",
          inputs: [
            {
              name: "",
              type: "string",
            },
          ],
          outputs: [{ type: "string" }],
        },
      ],
    };
  }, [question?.signature, signatureSchema]);
};

export const useDefaultCorrectnessTestCases = (questionUid: string): AlgorithmTestCaseForm.CorrectnessTestCaseSchema[] => {
  const questionHooks = QuestionsChallengeId.useCreateQuestionHooks(questionUid);
  const question = questionHooks.useQuestion();
  const correctnessTestCases = AlgorithmTestCaseForm.useCorrectnessTestCasesFromJsonDangerous();

  return React.useMemo(() => {
    if (!question?.correctnessTestCase) return [];
    const parsedResult = correctnessTestCases.safeParse(safeJsonParse(question.correctnessTestCase));
    if (!parsedResult.success) {
      return [];
    }
    return parsedResult.data;
  }, [correctnessTestCases, question?.correctnessTestCase]);
};
