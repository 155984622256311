import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Virtuoso, VirtuosoProps } from "react-virtuoso";

import QuestionPackageSearchArea, { QuestionPackageSearchAreaProps } from "./parts/QuestionPackageSearchArea/QuestionPackageSearchArea";
import SelectableButton, { SelectableButtonProps } from "./parts/SelectableButton/SelectableButton";

const MIN_ITEM_HEIGHT = 48;

const Wrapper = styled(Box)(() => ({
  width: 280,
  height: "100%",
}));

const Header = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const ContentWrapper = styled(Box)(() => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

const ContentItemWrapper = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  flexShrink: 0,
}));

/** Header(56px) + AllQuestionButton(48px) + SearchArea(48px) */
const CONTENT_HEIGHT = "calc(100% - 152px)";

const QuestionPackageListWrapper = styled(Box)(({ theme }) => ({
  height: CONTENT_HEIGHT,
  overflow: "scroll",
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}));

type InfiniteScrollableListProps = VirtuosoProps<SelectableButtonProps, unknown>;

export type SidebarProps = {
  questionPackageSearchArea?: QuestionPackageSearchAreaProps;
  showAllQuestionsButton: Pick<SelectableButtonProps, "onClick" | "selected">;
  showQuestionPackage: boolean;
  items: SelectableButtonProps[];
  onEndReached: InfiniteScrollableListProps["endReached"];
};

const Sidebar: React.FC<SidebarProps> = props => {
  const { t } = useTranslation();
  const showAllQuestions: SelectableButtonProps = {
    ...props.showAllQuestionsButton,
    children: t("すべての問題"),
  };

  const virtuosoProps: InfiniteScrollableListProps = {
    data: props.items,
    totalCount: props.items.length,
    /**
     * For a long title, it is likely to be about 3 lines, so the height of one element is set to 4 times the standard height.
     */
    style: { minHeight: 3 * MIN_ITEM_HEIGHT * props.items.length },
    itemContent: (index, itemProps) => {
      return <SelectableButton key={`index-${index}`} {...itemProps} />;
    },
    endReached: props.onEndReached,
  };

  return (
    <Wrapper>
      <Header>
        <Typography fontWeight={700} fontSize={16}>
          {t("問題選択")}
        </Typography>
      </Header>
      <ContentWrapper>
        <ContentItemWrapper>
          <SelectableButton {...showAllQuestions} />
          {props.questionPackageSearchArea && props.showQuestionPackage && <QuestionPackageSearchArea {...props.questionPackageSearchArea} />}
        </ContentItemWrapper>
        {props.showQuestionPackage && (
          <>
            {props.items.length > 0 && (
              <QuestionPackageListWrapper>
                <Virtuoso {...virtuosoProps} />
              </QuestionPackageListWrapper>
            )}
            {props.items.length === 0 && (
              <Box my={1.5}>
                <Typography variant="body2" textAlign="center" color="textSecondary">
                  {t("該当するデータがありません")}
                </Typography>
              </Box>
            )}
          </>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

Sidebar.displayName = "Sidebar";

export default Sidebar;
