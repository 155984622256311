import Box, { BoxProps } from "@mui/material/Box";
import FormLabel, { FormLabelProps } from "@mui/material/FormLabel";
import { styled } from "@mui/material/styles";
import * as React from "react";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  wordBreak: "keep-all",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  fontSize: "14px",
  color: theme.palette.text.primary,
  marginBottom: "8px",
}));

export type FieldLabelProps = {
  label: string;
  RightSideComponent?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  formLabel?: FormLabelProps;
} & Pick<BoxProps, "pt" | "pb" | "width" | "height">;

const FieldLabel: React.FC<FieldLabelProps> = props => {
  return (
    <Box className={props.className} pt={props.pt} pb={props.pb} height={props.height} width={props.width}>
      <StyledFormLabel as="div" {...props.formLabel}>
        {props.label}
        {props.RightSideComponent}
      </StyledFormLabel>
      <Box>{props.children}</Box>
    </Box>
  );
};

FieldLabel.displayName = "FieldLabel";

export default FieldLabel;
