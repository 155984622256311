import { TableRowProps } from "@mui/material/TableRow";
import * as React from "react";

import { DifficultyStarsProps } from "../../../../modules/DifficultyStars/DifficultyStars";
import { LeakScoreChipProps } from "../../../../ms-components/Question";
import BaseQuestionTableRow, {
  QuestionTableRowProps as BaseQuestionTableRowProps,
} from "../../../../ms-components/Question/QuestionTableRow/QuestionTableRow";

/**
 * TODO Change interface to structured
 */
export type QuestionTableRowProps = {
  id: string;
  title: string;
  variant: string;
  difficulty: DifficultyStarsProps["difficulty"];
  isOfficial: boolean;
  mark: BaseQuestionTableRowProps["meta"]["mark"];
  enableLanguages?: string[];
  leakScoreChip?: LeakScoreChipProps;
  showLeakScore: boolean;
  correctnessRate: number;
  expectedTimelimit: string;
  numUses: number;
  onClick: TableRowProps["onClick"];
};

const QuestionTableRow: React.FC<QuestionTableRowProps> = props => {
  const baseQuestionTableRowProps: BaseQuestionTableRowProps = {
    meta: {
      title: props.title,
      variant: props.variant,
      isOfficial: props.isOfficial,
      enableLanguages: props.enableLanguages,
      mark: props.mark,
    },
    correctnessRate: props.correctnessRate,
    numUses: props.numUses,
    onClick: props.onClick,
    leakScoreChip: props.leakScoreChip,
    showLeakScore: props.showLeakScore,
    expectedTimelimit: props.expectedTimelimit,
    difficulty: props.difficulty,
  };
  return <BaseQuestionTableRow {...baseQuestionTableRowProps} />;
};

QuestionTableRow.displayName = "QuestionTableRow";

export default QuestionTableRow;
