import { useTranslation } from "@hireroo/i18n";
import { AlgorithmSignatureForm } from "@hireroo/validator";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { InputControlTextFieldProps } from "../../../../../../../primitive/InputControl/InputControlTextField";
import { generatePlaceholder } from "../../../../../generatePlaceHolder";

const ReadonlyTextField = styled(TextField)(() => ({
  "&.MuiFormControl-root": {
    pointerEvents: "none",
  },
}));

export type ReadonlyAlgorithmFieldsProps = {
  signature: AlgorithmSignatureForm.AlgorithmSignatureSchemaType;
  inputs: string[];
  outputs: string[];
};

const ReadonlyAlgorithmFields: React.FC<ReadonlyAlgorithmFieldsProps> = props => {
  const { t } = useTranslation();

  const inputTextField: InputControlTextFieldProps = {
    variant: "outlined",
    type: "text",
    fullWidth: true,
    color: "primary",
    InputLabelProps: {
      shrink: true,
    },
    InputProps: {
      size: "small",
      readOnly: true,
    },
    required: true,
  };

  const outputTextField: InputControlTextFieldProps = {
    variant: "outlined",
    type: "text",
    fullWidth: true,
    color: "primary",
    InputLabelProps: {
      shrink: true,
    },
    InputProps: {
      size: "small",
      readOnly: true,
    },
    required: true,
    label: `${t("返り値")} (${props.signature.outputs.length > 0 ? props.signature.outputs[0].type : ""})`,
    placeholder: generatePlaceholder(props.signature.outputs[0].type),
  };
  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
          {t("引数の入力")}
        </Typography>

        <Box mt={2}>
          <Grid container spacing={4}>
            {props.signature.inputs.map((input, i) => (
              <Grid key={input.name} item xs={12}>
                <ReadonlyTextField
                  label={`${t("引数")} ${i + 1} (${input.type})`}
                  placeholder={generatePlaceholder(input.type)}
                  value={props.inputs.at(i)}
                  {...inputTextField}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>

      <Grid item xs={6}>
        <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
          {t("返り値の入力")}
        </Typography>

        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ReadonlyTextField {...outputTextField} value={props.outputs.at(0)} />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

ReadonlyAlgorithmFields.displayName = "ReadonlyAlgorithmFields";

export default ReadonlyAlgorithmFields;
