import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type RemoteInterviewStaticFlowChartContainerProps = {};

const RemoteInterviewStaticFlowChartContainer: React.FC<RemoteInterviewStaticFlowChartContainerProps> = () => {
  const remoteInterviewStaticFlowChartProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.RemoteInterviewStaticFlowChart {...remoteInterviewStaticFlowChartProps} />
    </ErrorBoundary>
  );
};

RemoteInterviewStaticFlowChartContainer.displayName = "RemoteInterviewStaticFlowChartContainer";

export default withErrorBoundary(RemoteInterviewStaticFlowChartContainer, {});
