import React from "react";

import { TrackEnabledSubscriber } from "./TrackEnabledSubscriber";
import { TrackType } from "./types";

/**
 * This hook allows components to reliably use the 'enabled' property of the Track object.
 */
export const useIsTrackEnabled = (track?: TrackType) => {
  const subscriberRef = React.useRef<TrackEnabledSubscriber>();
  const [isEnabled, setIsEnabled] = React.useState(track ? track.isEnabled : false);

  const handleEnabled = React.useCallback(() => {
    setIsEnabled(true);
  }, []);
  const handleDisabled = React.useCallback(() => {
    setIsEnabled(false);
  }, []);

  React.useEffect(() => {
    setIsEnabled(track ? track.isEnabled : false);

    if (track) {
      subscriberRef.current = new TrackEnabledSubscriber(track, handleEnabled, handleDisabled);
    }

    return () => {
      if (subscriberRef.current) {
        subscriberRef.current.unsubscribe();
        subscriberRef.current = undefined;
      }
    };
  }, [handleDisabled, handleEnabled, track]);

  return isEnabled;
};
