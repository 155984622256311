import WarningIcon from "@mui/icons-material/Warning";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledBox = styled(Box)({
  backgroundColor: "rgba(174, 124, 20, 0.15)",
  height: 62.75,
  overflow: "hidden",
});

const StyledWarningIcon = styled(WarningIcon)(({ theme }) => ({
  color: theme.palette.common.white,
}));

export type NotificationBannerProps = {
  open: boolean;
  message: string;
  action?: ButtonProps;
};

const NotificationBanner: React.FC<NotificationBannerProps> = props => {
  if (!props.open) {
    return null;
  }
  return (
    <StyledBox display="flex" justifyContent="space-between" alignItems="center" p={2} role="notification">
      <Box display="flex" alignItems="center" maxWidth="85vw">
        <StyledWarningIcon data-testid="icon" fontSize="small" />
        <Typography role="message" variant="subtitle2" ml={1}>
          {props.message}
        </Typography>
      </Box>
      {props.action && <Button role="actionbutton" color="primary" size="small" {...props.action} />}
    </StyledBox>
  );
};

NotificationBanner.displayName = "NotificationBanner";

export default NotificationBanner;
