import { useTranslation } from "@hireroo/i18n";
import Markdown from "@hireroo/markdown-v2/react";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import Backdrop, { BackdropProps } from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled, useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { createPortal } from "react-dom";
import Carousel from "react-material-ui-carousel";
import type { CarouselProps } from "react-material-ui-carousel/dist/components/types";

import TestResultItem, { TestResultItemProps } from "./parts/TestResultItem";

export type Status = "SUCCESS" | "ERROR";

const SummaryContentWrapper = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  height: "22px",
}));

const PassedIconWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginRight: theme.spacing(2),
}));

const InvisibleIconWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  color: theme.palette.text.secondary,
  marginRight: theme.spacing(1),
  alignItems: "center",
  "& svg": { width: 16, height: 16 },
}));

export type FrontendTestCaseProps = {
  invisible: boolean;
  title: string;
  description?: string;
  status: Status;
  testResults: TestResultItemProps[];
  onOpenCarousel?: (open: boolean) => void;
  /**
   * Make the ScreenShot component containing the Backdrop render at the `document.body` position.
   *
   * <body>
   *   <ScreenShot /> <-- here
   * </body>
   */
  renderingUnderBody?: boolean;
};

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: "#fff",
}));

const statusIconMap = {
  SUCCESS: <CheckCircleIcon color="success" />,
  ERROR: <CancelIcon color="error" />,
} satisfies Record<Status, React.ReactNode>;

const FrontendTestCase = React.forwardRef((props: FrontendTestCaseProps, ref: React.Ref<unknown>) => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = React.useState<number>(0);
  const [open, setOpen] = React.useState<boolean>(false);
  const theme = useTheme();

  const backdropProps: BackdropProps = {
    open: open,
    onClick: () => {
      setOpen(false);
      props.onOpenCarousel?.(false);
    },
  };

  const carouselProps: CarouselProps = {
    index: activeIndex,
    autoPlay: false,
    animation: "slide",
    navButtonsAlwaysVisible: true,
    cycleNavigation: false,
    fullHeightHover: false,
    height: "400px",
    // indicators: false, // When this is activated, the DOM disappears.
    indicatorContainerProps: {
      style: {
        width: "500px",
        height: 0,
        position: "relative",
      },
    },
    indicatorIconButtonProps: {
      style: {
        cursor: "pointer",
        transition: "200ms",
        padding: "4px",
        color: "#afafaf",
      },
    },
    activeIndicatorIconButtonProps: {
      style: {
        color: theme.palette.primary.main,
      },
    },
  };

  const ScreenShots = (
    <StyledBackdrop {...backdropProps}>
      <React.Suspense>
        <Carousel {...carouselProps}>
          {props.testResults.map((result, index: number) => (
            <Box key={`testCase-scenario-${index}`}>
              <img src={result.screenshotUrl} alt={`testCase-scenario-${index}`} width="100%" style={{ borderRadius: "4px" }} />
            </Box>
          ))}
        </Carousel>
      </React.Suspense>
    </StyledBackdrop>
  );

  const passedMessageMap: Record<Status, string> = {
    SUCCESS: t("正解"),
    ERROR: t("不正解"),
  };

  return (
    <Box width={"100%"} ref={ref}>
      <SummaryContentWrapper mb={3}>
        <PassedIconWrapper>
          <Tooltip title={passedMessageMap[props.status]}>{statusIconMap[props.status]}</Tooltip>
        </PassedIconWrapper>
        {props.invisible && (
          <Tooltip title={t("受験者には見えない隠しテストケースです")}>
            <InvisibleIconWrapper>
              <VisibilityOffOutlinedIcon fontSize="small" />
            </InvisibleIconWrapper>
          </Tooltip>
        )}
        <Typography variant="subtitle1">{props.title}</Typography>
      </SummaryContentWrapper>

      {props.description && (
        <Box mb={4}>
          <React.Suspense>
            <Markdown size={"small"} children={props.description} />
          </React.Suspense>
        </Box>
      )}

      <Box mt={2}>
        <Grid container spacing={4}>
          {props.testResults.map((testResult, index: number) => {
            const testResultItemProps: TestResultItemProps = {
              ...testResult,
              onClick: () => {
                setOpen(prev => {
                  props.onOpenCarousel?.(!prev);
                  return !prev;
                });
                setActiveIndex(index);
              },
            };
            return <TestResultItem key={`test-result-${index}`} {...testResultItemProps} />;
          })}
        </Grid>
        {props.renderingUnderBody ? createPortal(ScreenShots, document.body) : ScreenShots}
      </Box>
    </Box>
  );
});

FrontendTestCase.displayName = "FrontendTestCase";

export default FrontendTestCase;
