import { useTranslation } from "@hireroo/i18n";
import { LATEST_VERSION } from "@hireroo/system-design/constants/flowChart";
import { FlowElement, FlowSnapshot } from "@hireroo/system-design/features";
import { parseFlowChartSnapshot } from "@hireroo/system-design/helpers/flowChart";
import { useComponentTypesMap } from "@hireroo/system-design/hooks";
import { ExternalAction as FlowChartAction, SystemDesignProvider, SystemDesignProviderProps } from "@hireroo/system-design/react/FlowChart";
import { FreepadAnswerForm } from "@hireroo/validator";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import * as React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import AddableTabs, { AddableTabsProps } from "../../primitive/Tab/AddableTabs/AddableTabs";
import FlowChartInputField, { FlowChartInputFieldProps } from "./parts/FlowChartInputField/FlowChartInputField";

export type FlowChartsInputControlProps = {
  /**
   * This name is a dummy value; due to the type of react-hook-form, a fixed value is required.
   * The user should specify `"awesome.name" as "flowCharts"` for the actual data structure.
   */
  name: "flowCharts";
  addableTabs: Pick<AddableTabsProps, "isInitialTabClosable">;
};

type Field = {
  flowCharts: FreepadAnswerForm.FlowChartSchema[];
};

const addableComponentTypes: FreepadAnswerForm.FlowChartSchema["componentType"][] = ["default", "aws", "gcp", "azure"];

const generateTabId = (prefix: string, index: number, componentType: FreepadAnswerForm.FlowChartSchema["componentType"]): string => {
  return `${prefix}${index}-${componentType}`;
};

const FlowChartsInputControl: React.FC<FlowChartsInputControlProps> = props => {
  const { t } = useTranslation();
  const componentTypeTranslationMap = useComponentTypesMap();
  const method = useFormContext<Field>();
  const flowChartFieldArray = useFieldArray<Field>({
    control: method.control,
    /**
     * Name of the field array. Note: Do not support dynamic name.
     * @see https://react-hook-form.com/api/usefieldarray
     */
    name: props.name,
  });
  const firstFlowChartField = flowChartFieldArray.fields.length > 0 ? flowChartFieldArray.fields[0] : undefined;
  const [currentTabValue, updateCurrentTabValue] = React.useState(
    firstFlowChartField ? generateTabId(props.name, 0, firstFlowChartField.componentType) : "",
  );
  const selectedComponentTypes = new Set(flowChartFieldArray.fields.map(field => field.componentType));
  const notSelectedComponentTypes = addableComponentTypes.filter(target => !selectedComponentTypes.has(target));

  const menuItems = notSelectedComponentTypes.map((componentType): AddableTabsProps["menu"]["items"][0] => {
    return {
      text: componentTypeTranslationMap[componentType],
      value: componentType,
      onClick: () => {
        flowChartFieldArray.append({
          componentType,
          body: "",
        });
      },
    };
  });

  const items = flowChartFieldArray.fields.map((field, index): AddableTabsProps["items"][0] => {
    const { result } = parseFlowChartSnapshot(field.body);
    const elements: Record<string, FlowElement> = {};
    const elementIds: string[] = [];
    result.elements.forEach(element => {
      elements[element.id] = element;
      elementIds.push(element.id);
    });
    const tabId = generateTabId(props.name, index, field.componentType);
    const systemDesignProviderProps: SystemDesignProviderProps = {
      id: tabId,
      initialState: {
        componentType: field.componentType,
        elementIds,
        elements,
      },
      options: {
        disableSetStateOnUnmount: true,
      },
    };
    const flowChartInputFieldProps: FlowChartInputFieldProps = {
      id: tabId,
      onChangeElements: newElements => {
        const newSnapshot: FlowSnapshot = {
          version: LATEST_VERSION,
          componentType: field.componentType,
          elements: newElements,
        };
        method.setValue(`${props.name}.${index}.body`, JSON.stringify(newSnapshot));
        method.setValue(`${props.name}.${index}.componentType`, field.componentType);
      },
    };
    const error = method.formState.errors[props.name]?.[index];
    return {
      id: tabId,
      name: componentTypeTranslationMap[field.componentType],
      Content: (
        <Box>
          <SystemDesignProvider {...systemDesignProviderProps}>
            <FlowChartInputField {...flowChartInputFieldProps} />
          </SystemDesignProvider>
          <FormHelperText children={Boolean(error) && error?.message} focused error={Boolean(error)} />
        </Box>
      ),
      tab: {
        closeButton: {
          onClick: () => {
            flowChartFieldArray.remove(index);
            FlowChartAction.clear(tabId);
          },
        },
      },
    };
  });
  const addableTabsProps: AddableTabsProps = {
    ...props.addableTabs,
    currentTabValue: currentTabValue,
    items: items,
    updateCurrentTabValue: updateCurrentTabValue,
    menu: {
      items: menuItems,
    },
    addTabButton: {
      children: t("フローチャートを追加する"),
    },
  };

  return <AddableTabs {...addableTabsProps} />;
};

FlowChartsInputControl.displayName = "FlowChartsInputControl";

export default FlowChartsInputControl;
