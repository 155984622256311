import { useTranslation } from "@hireroo/i18n";
import { ScreeningResourceEditorForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import ResourceField from "../../../../modules/ResourceFieldV2/ResourceFieldV2";
import InputControlTextField, {
  InputControlTextFieldProps,
} from "../../../../primitive/InputControl/InputControlTextField/InputControlTextField";
import SelectField, { SelectFieldProps } from "../../../../primitive/InputControl/SelectField/SelectField";
import { type LayoutController, useScreeningResourceEditorContext } from "../../Context";

type FieldName = keyof ScreeningResourceEditorForm.ReportSetupFormSchema;

const ReservedFieldName = {
  MEMO: "memo",
  TAGS: "tags",
  VIEWERS: "viewers",
  IS_PUBLIC: "isPublic",
} satisfies Record<string, FieldName>;

const Subsection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  boxShadow: "none",
}));

const StyledSelectField = styled(SelectField)(({ theme }) => ({
  color: theme.palette.text.secondary,
  width: "fit-content",
  backgroundColor: theme.palette.Other.FilledInputBG,
  ".MuiSelect-outlined": {
    border: "none",
  },
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
    borderRadius: 8,
  },
  minWidth: 360,
}));

export type ReportSetupSectionProps = {
  onSubmit: SubmitHandler<ScreeningResourceEditorForm.ReportSetupFormSchema>;
  TagField: React.ReactNode;
  ViewerField: React.ReactNode;
  defaultValues: ScreeningResourceEditorForm.ReportSetupFormSchema;
};

const ReportSetupSection: React.FC<ReportSetupSectionProps> = props => {
  const { t } = useTranslation();
  const { onSubmit } = props;
  const { stepName, setController, submitValues, setSubmitValue } = useScreeningResourceEditorContext();
  const validateSchema = ScreeningResourceEditorForm.useReportSetupForm();
  const methods = useForm<ScreeningResourceEditorForm.ReportSetupFormSchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: submitValues.REPORT_SETUP ?? props.defaultValues,
  });

  const controller = React.useMemo((): LayoutController => {
    return {
      checkGoNextStep: () => {
        return new Promise<boolean>(resolve => {
          methods.handleSubmit(
            fields => {
              onSubmit(fields);
              setSubmitValue("REPORT_SETUP", fields);
              resolve(true);
            },
            errors => {
              console.warn(errors);
              setSubmitValue("REPORT_SETUP", undefined);
              resolve(false);
            },
          )();
        });
      },
    };
  }, [methods, onSubmit, setSubmitValue]);

  React.useEffect(() => {
    setController("REPORT_SETUP", controller);
  }, [setController, controller]);

  const isPublic = methods.watch("isPublic");

  const memoFieldProps: InputControlTextFieldProps = {
    fullWidth: true,
    minRows: 3,
    maxRows: 10,
    multiline: true,
  };

  const selectFieldProps: SelectFieldProps = {
    color: "secondary",
    variant: "outlined",
    items: [
      {
        text: t("リンクを知っているメンバーが閲覧可能"),
        value: true,
      },
      {
        text: t("指定されたメンバーのみ閲覧可能"),
        value: false,
      },
    ],
    onChange: event => {
      if (event.target.value === true) {
        methods.setValue("viewers", []);
      } else if (event.target.value === false) {
        const editorUid = methods.getValues("editorUid");
        if (editorUid) {
          methods.setValue("viewers", [{ value: { type: "EMPLOYEE", employeeId: editorUid, locked: true } }]);
        }
      }
    },
  };

  if (stepName !== "REPORT_SETUP") {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <Stack py={2} spacing={1.5}>
        <Subsection>
          <Stack spacing={4}>
            <ResourceField
              label={t("社内用メモ")}
              kind="NONE"
              pb={2}
              help={{
                kind: "DESCRIPTION",
                text: (
                  <>
                    {t("求人媒体や採用管理システム(ATS)および次の面接官への引継ぎする目的でメモを残すことが可能です。")}
                    <br />
                    {t("※") + t("この内容は受験者に共有されません")}
                  </>
                ),
              }}
            >
              <InputControlTextField name={ReservedFieldName.MEMO} {...memoFieldProps} />
            </ResourceField>
            <ResourceField
              label={t("タグ設定")}
              kind="NONE"
              help={{
                kind: "DESCRIPTION",
                text: t(
                  "レポート作成時に自動付与されるタグを指定することができます。一覧画面での絞り込み検索やレポート画面で母集団を絞り込む際に利用することができます。",
                ),
              }}
            >
              {props.TagField}
            </ResourceField>

            <ResourceField
              label={t("閲覧権限")}
              kind="NONE"
              help={{
                kind: "DESCRIPTION",
                text: t("閲覧権限の適用対象はコーディングテスト詳細ページ、候補者詳細ページ（レポート）が対象になります。"),
              }}
            >
              <Stack spacing={1}>
                <StyledSelectField name={ReservedFieldName.IS_PUBLIC} {...selectFieldProps} />
                {isPublic === false && props.ViewerField}
              </Stack>
            </ResourceField>
          </Stack>
        </Subsection>
      </Stack>
    </FormProvider>
  );
};

ReportSetupSection.displayName = "ReportSetupSection";

export default ReportSetupSection;
