import type * as Graphql from "@hireroo/graphql/client/urql";
import { useSnapshot } from "valtio";

import { state } from "./State";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useSecurity = (): Graphql.GetCompanyForSecuritySettingsSecurityFragment => {
  const snapshot = useSnapshotState();
  return snapshot.security;
};
