import { InterviewsIdStore } from "@hireroo/app-store/page/c/interviews_id";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import InterviewsIdFetchContainer from "./FetchContainer";
import * as Subscriber from "./Subscriber";

const InterviewsIdInitialContainer: React.FC = () => {
  React.useEffect(() => {
    const stopSubscribeOnChangeSpot = Subscriber.startSubscribeOnChangeSpot();
    const stopSubscribeTimeoutStatus = Subscriber.startSubscribeTimeoutStatus();
    return () => {
      stopSubscribeOnChangeSpot();
      stopSubscribeTimeoutStatus();
      InterviewsIdStore.clear();
    };
  }, []);
  return (
    <ErrorBoundary>
      <InterviewsIdFetchContainer />
    </ErrorBoundary>
  );
};

InterviewsIdInitialContainer.displayName = "InterviewsIdInitialContainer";

export default withErrorBoundary(InterviewsIdInitialContainer, {});
