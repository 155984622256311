import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";

export type IconButtonWithTooltipProps = Omit<IconButtonProps, "title"> & {
  title?: string | undefined | false;
  tooltipClassName?: string;
  wrapperClassName?: string;
  className?: string;
};
type ButtonStatus = "NORMAL" | "DISABLED";

const IconButtonWithTooltip: React.FC<IconButtonWithTooltipProps> = props => {
  const { children, title, disabled, tooltipClassName, wrapperClassName, ...rest } = props;
  const buttonStatus: ButtonStatus = disabled ? "DISABLED" : "NORMAL";

  const ButtonMap: Record<ButtonStatus, React.ReactElement> = {
    DISABLED: (
      <span className={wrapperClassName}>
        <IconButton role="disabled-button" disabled size="small" {...rest}>
          {children}
        </IconButton>
      </span>
    ),
    NORMAL: (
      <IconButton role="button" size="small" {...rest}>
        {children}
      </IconButton>
    ),
  };

  return (
    <Tooltip role="tooltip" title={title ?? ""} className={tooltipClassName}>
      {ButtonMap[buttonStatus] ?? ButtonMap["NORMAL"]}
    </Tooltip>
  );
};

IconButtonWithTooltip.displayName = "IconButtonWithTooltip";

export default IconButtonWithTooltip;
