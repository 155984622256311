import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledLabelBox = styled(Box)`
  top: 0px;
  left: 0px;
  bottom: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SIZE = 45;

export type ScorePieChartProps = {
  score: number;
};

const ScorePieChart: React.FC<ScorePieChartProps> = props => {
  const theme = useTheme();
  const colorMap = {
    dark: theme.palette.grey["700"],
    light: theme.palette.grey["100"],
  } satisfies Record<"dark" | "light", string>;
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        size={SIZE}
        variant="determinate"
        sx={{ position: "absolute", left: 0, color: colorMap[theme.palette.mode] }}
        value={100}
      />
      <CircularProgress size={SIZE} variant="determinate" value={props.score} />
      <StyledLabelBox>
        <Typography component="div" fontWeight={700} fontSize={12}>{`${props.score}%`}</Typography>
      </StyledLabelBox>
    </Box>
  );
};

ScorePieChart.displayName = "ScorePieChart";

export default ScorePieChart;
