import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateDashboardScreeningStatsSectionPropsArgs, useGenerateProps } from "./useGenerateProps";

export type DashboardScreeningStatsSectionContainerProps = GenerateDashboardScreeningStatsSectionPropsArgs;

const DashboardScreeningStatsSectionContainer: React.FC<DashboardScreeningStatsSectionContainerProps> = props => {
  const dashboardScreeningStatsSectionProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.DashboardScreeningStatsSection {...dashboardScreeningStatsSectionProps} />
    </ErrorBoundary>
  );
};

DashboardScreeningStatsSectionContainer.displayName = "DashboardScreeningStatsSectionContainer";

export default withErrorBoundary(DashboardScreeningStatsSectionContainer, {});
