import { useTranslation } from "@hireroo/i18n";
import Add from "@mui/icons-material/Add";
import AssistantPhoto from "@mui/icons-material/AssistantPhoto";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import AcceptButton, { AcceptButtonProps } from "../../../primitive/Button/AcceptButton";

export type HintHeaderProps = {
  addButton: Pick<AcceptButtonProps, "onClick" | "disabled">;
};

const HintHeader: React.FC<HintHeaderProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const addButton: AcceptButtonProps = {
    ...props.addButton,
    children: t("ヒントを追加する").toString(),
    size: "small",
    variant: "text",
    startIcon: <Add fontSize="small" />,
  };
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="center">
        <Box mr={1}>
          <AssistantPhoto fontSize="small" sx={{ color: theme.palette.common.white }} />
        </Box>
        <Box mr={2}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            {`${t("ヒント")} （${t("任意")})`}
          </Typography>
        </Box>
        <AcceptButton {...addButton} />
      </Box>
      <Box mt={2}>
        <Typography variant="body2">{t("ヒントを追加すると候補者がコーディング中に詰まったとき、解答に近づく手助けになります。")}</Typography>
      </Box>
    </Box>
  );
};

HintHeader.displayName = "HintHeader";

export default HintHeader;
