import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

import MoreButton, { MoreButtonProps } from "../../../../primitive/Button/MoreButton/MoreButton";
import DeleteMember, { DeleteMemberProps } from "../DeleteMember/DeleteMember";
import EditRole, { EditRoleProps } from "../EditRole/EditRole";

const StyledTypography = styled(Typography)(() => ({
  fontSize: "14px",
  wordBreak: "keep-all",
}));

export type MemberTableRowProps = {
  checkbox: Pick<CheckboxProps, "disabled" | "title" | "checked">;
  onSelect?: (selected: boolean) => void;
  memberId: string;
  photoUrl: string;
  displayName: string;
  email: string;
  role?: string;
  lastSignInDate?: string;
  editMenuButton: Pick<Exclude<MoreButtonProps["iconButton"], undefined>, "disabled" | "title">;
  items: MoreButtonProps["options"];
  deleteMember: DeleteMemberProps;
  editRole?: EditRoleProps;
};

const MemberTableRow: React.FC<MemberTableRowProps> = props => {
  const { onSelect } = props;
  const checkboxProps: CheckboxProps = {
    ...props.checkbox,
    color: "secondary",
    size: "small",
    onChange: React.useCallback(
      (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        onSelect?.(checked);
      },
      [onSelect],
    ),
  };

  const moreButton: MoreButtonProps = {
    options: props.items,
    variant: "vertical",
    iconButton: {
      ...props.editMenuButton,
      size: "small",
    },
  };

  return (
    <TableRow>
      <TableCell padding={"checkbox"}>
        <Tooltip title={props.checkbox.title ?? ""}>
          <span>
            <Checkbox {...checkboxProps} />
          </span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Box display={"flex"} flexDirection={"row"} alignItems="center">
          <Avatar src={props.photoUrl} sx={{ width: "32px", height: "32px" }} />
          <Box ml={2} />
          <Box display={"flex"} justifyContent={"center"} flexDirection={"column"}>
            <StyledTypography>{props.displayName}</StyledTypography>
            <Typography fontSize={12} color="textSecondary">
              {props.email}
            </Typography>
          </Box>
        </Box>
      </TableCell>
      {props.lastSignInDate && (
        <TableCell>
          <StyledTypography>{props.lastSignInDate}</StyledTypography>
        </TableCell>
      )}
      {props.role && (
        <TableCell>
          <StyledTypography>{props.role}</StyledTypography>
        </TableCell>
      )}
      <TableCell align={"right"}>
        <Tooltip title={props.editMenuButton.title ?? ""}>
          <span>
            <MoreButton {...moreButton} />
          </span>
        </Tooltip>
      </TableCell>
      {props.editRole && <EditRole {...props.editRole} />}
      <DeleteMember {...props.deleteMember} />
    </TableRow>
  );
};

MemberTableRow.displayName = "MemberTableRow";

export default MemberTableRow;
