import { useTranslation } from "@hireroo/i18n";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { useTheme } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";

const RECOMMENDATION = {
  GO: "GO",
  NOT_GO: "NOT_GO",
};
type Value = keyof typeof RECOMMENDATION;

export type ThumbSwitchProps = {
  onChange: (value: Value) => void;
  value: Value | null;
};

const ThumbSwitch: React.FC<ThumbSwitchProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { value, onChange } = props;
  const handleChange = React.useCallback(
    (_: React.MouseEvent<HTMLElement>, value: string | null) => {
      if (value) {
        onChange(value === "GO" ? "GO" : "NOT_GO");
      }
    },
    [onChange],
  );
  return (
    <ToggleButtonGroup exclusive onChange={handleChange} value={value}>
      <Tooltip title={t("合格")}>
        <ToggleButton
          value={RECOMMENDATION.GO}
          size="small"
          sx={{ borderRadius: "40px 0px 0px 40px", backgroundColor: theme.palette["Secondary/Shades"].p12, width: "40px" }}
        >
          <ThumbUpIcon fontSize="small" color={value === RECOMMENDATION.GO ? "success" : "disabled"} />
        </ToggleButton>
      </Tooltip>
      <Tooltip title={t("不合格")}>
        <ToggleButton
          value={RECOMMENDATION.NOT_GO}
          size="small"
          sx={{ borderRadius: "0px 40px 40px 0px", backgroundColor: theme.palette["Secondary/Shades"].p12, width: "40px" }}
        >
          <ThumbDownIcon fontSize="small" color={value === RECOMMENDATION.NOT_GO ? "error" : "disabled"} />
        </ToggleButton>
      </Tooltip>
    </ToggleButtonGroup>
  );
};

ThumbSwitch.displayName = "ThumbSwitch";

export default ThumbSwitch;
