import { ref } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

export type SetAuthTokenArgs = {
  authToken: string | null;
  expireDate: Date | null;
  getRefreshAuthToken: Types.GetRefreshAuthToken;
};

export const setAuthToken = (args: SetAuthTokenArgs) => {
  state.authToken = args.authToken;
  state.expireDate = args.expireDate;
  state.getRefreshAuthToken = ref(args.getRefreshAuthToken);
};

export const getOrRefreshAuthTokenExpire = async () => {
  if (state.expireDate === null) {
    return state.authToken;
  }
  const expireTime = state.expireDate.getTime();
  const expired = Date.now() >= expireTime;

  if (expired) {
    const { authToken, expireDate } = await state.getRefreshAuthToken();
    state.authToken = authToken ?? null;
    state.expireDate = expireDate ?? null;
    return state.authToken;
  }
  return state.authToken;
};

export const setProviderId = (providerId: string | null) => {
  state.providerId = providerId;
};

type SetCustomClaimInfoArgs = {
  signedIn: boolean;
  customClaim: Types.CustomClaim;
};

export const setCustomClaimInfo = (args: SetCustomClaimInfoArgs) => {
  state.signedIn = args.signedIn;

  state.customClaim = args.customClaim;
};

export const setCurrentUserType = (userType: Types.UserType) => {
  state.userType = userType;
};

export const setUid = (uid: string) => {
  state.uid = uid;
};

export const setMailAddress = (mailAddress: string | null) => {
  state.mailAddress = mailAddress;
};
