import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type EmployeeTestReviewContainerProps = {};

const EmployeeTestReviewContainer: React.FC<EmployeeTestReviewContainerProps> = () => {
  const employeeTestReviewProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Pages.EmployeeTestReview {...employeeTestReviewProps} />
    </ErrorBoundary>
  );
};

EmployeeTestReviewContainer.displayName = "EmployeeTestReviewContainer";

export default withErrorBoundary(EmployeeTestReviewContainer, {});
