import { useTranslation } from "@hireroo/i18n";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DownloadResultDialog, { DownloadResultDialogProps } from "../../modules/DownloadResultDialog/DownloadResultDialog";
import SelectableButton, { SelectableButtonProps } from "../../primitive/Button/SelectableButton/SelectableButton";
import DownloadDialogContent, { DownloadDialogContentProps } from "./parts/DownloadDialogContent/DownloadDialogContent";
import { FieldItem, SingleFieldItem } from "./parts/types";
import { CsvDownloadProvider, CsvDownloadProviderProps } from "./PrivateContext";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": { height: 575, minHeight: 575, width: 640 },
  "&.MuiPaper-root-MuiDialog-paper": {
    borderRadius: theme.shape.borderRadius * 2,
  },
  borderRadius: theme.shape.borderRadius * 2,
}));

const StyledDialogTitle = styled(DialogTitle)(() => ({
  fontSize: 16,
  padding: "8px 16px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}));

const StyledDialogContent = styled(DialogContent)(() => ({
  padding: "16px 24px",
  overflow: "hidden",
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.grey[500],
}));

export type CsvDownloadProps = {
  open: boolean;
  onCloseDialog: () => void;
  infoButton: { href: string; target?: "_blank" };
  onChangeSelectedFields: (fields: SingleFieldItem[]) => void;
  downloadButtonGroup: SelectableButtonProps;
  content: DownloadDialogContentProps;
  fields: FieldItem[];
  defaultSelectedFields: FieldItem[];
  downloadResultDialog?: DownloadResultDialogProps;
};

const CsvDownload: React.FC<CsvDownloadProps> = props => {
  const { t } = useTranslation();
  const providerProps: CsvDownloadProviderProps = {
    fields: props.fields,
    defaultSelectedFields: props.defaultSelectedFields,
    onChange: props.onChangeSelectedFields,
  };
  const infoIconButton: IconButtonProps = {
    ...props.infoButton,
    children: <InfoOutlinedIcon fontSize="small" />,
  };
  const closeIconButton: IconButtonProps = {
    onClick: event => {
      event.stopPropagation();
      props.onCloseDialog();
    },
    children: <CloseIcon fontSize="small" />,
  };
  return (
    <CsvDownloadProvider {...providerProps}>
      {props.downloadResultDialog && <DownloadResultDialog {...props.downloadResultDialog} />}
      <StyledDialog onClose={props.onCloseDialog} open={props.open} fullWidth maxWidth="md">
        <StyledDialogTitle>
          <Box sx={{ width: "100%" }}>
            <Typography variant="subtitle2" component="span">
              {t("データダウンロード")}
            </Typography>
            <StyledIconButton {...infoIconButton} />
          </Box>
          <StyledIconButton {...closeIconButton} />
        </StyledDialogTitle>
        <Divider />
        <StyledDialogContent>
          <DownloadDialogContent {...props.content} />
        </StyledDialogContent>
        <DialogActions disableSpacing>
          <Stack direction="row" spacing={2} p={1}>
            <SelectableButton {...props.downloadButtonGroup} />
          </Stack>
        </DialogActions>
      </StyledDialog>
    </CsvDownloadProvider>
  );
};

CsvDownload.displayName = "CsvDownload";

export default CsvDownload;
