import { generateKey } from "@hireroo/app-helper/parser";
import { proxyMap } from "valtio/utils";

import { state } from "./State";
import type * as Types from "./types";

export const initialize = (entity: Types.QuizEntity): void => {
  const submissionMap = proxyMap<Types.QuizSubmissionId, Types.Submission>();
  const questionKeyBySubmissionIds: Record<Types.QuizQuestionKey, Types.QuizSubmissionId[]> = {};

  entity.submissions.forEach(submission => {
    submissionMap.set(submission.quizSubmissionId, submission);
    const submissionIds = questionKeyBySubmissionIds[`${submission.questionId}-${submission.questionVersion}`];
    if (submissionIds) {
      questionKeyBySubmissionIds[`${submission.questionId}-${submission.questionVersion}`].push(submission.quizSubmissionId);
    } else {
      questionKeyBySubmissionIds[`${submission.questionId}-${submission.questionVersion}`] = [submission.quizSubmissionId];
    }
  });

  const questionMap = proxyMap<Types.QuizQuestionKey, Types.QuizQuestion>();
  const questionIdVersionMap = proxyMap<number, string>();
  if (entity.pb_package) {
    entity.pb_package.questions.forEach(question => {
      questionIdVersionMap.set(question.id, question.version);
    });
  }

  const submissionResourceKeys: Types.QuizSubmissionResourceKeys[] = [];
  const evaluationByQuestionMap = proxyMap<Types.QuizQuestionKey, Types.Evaluation[]>();
  const questionBySubmissionMap = proxyMap<Types.QuizQuestionKey, number | undefined>();
  const questionByLatestSubmissionMap = proxyMap<Types.QuizQuestionKey, number | undefined>();

  (entity.pb_package?.questions || []).forEach(question => {
    questionMap.set(`${question.id}-${question.version}`, question);

    const submissionResource: Types.QuizSubmissionResourceKeys = {
      questionId: question.id,
      questionVersion: question.version,
      submissionIds: [],
    };
    const submissionIds: number[] | undefined = questionKeyBySubmissionIds[`${question.id}-${question.version}`];
    if (submissionIds) {
      submissionResource.submissionIds = submissionIds;

      const latestSubmissionId = submissionIds[submissionIds.length - 1];
      const submission = submissionMap.get(latestSubmissionId);
      if (submission) {
        evaluationByQuestionMap.set(`${submission.questionId}-${submission.questionVersion}`, submission.evaluations);
      }
    }

    questionBySubmissionMap.set(
      `${question.id}-${question.version}`,
      submissionIds && submissionIds.length > 0 ? submissionIds[submissionIds.length - 1] : undefined,
    );

    // Initial submission is most latest submitted one.
    questionByLatestSubmissionMap.set(
      `${question.id}-${question.version}`,
      submissionIds && submissionIds.length > 0 ? submissionIds[submissionIds.length - 1] : undefined,
    );
    submissionResourceKeys.push(submissionResource);
  });

  state.quizzes.set(entity.quizEntityId, {
    entity,
    submissionMap,
    questionMap,
    questionIdVersionMap,
    submissionResourceKeys,
    questionBySubmissionMap,
    questionByLatestSubmissionMap,
    evaluationByQuestionMap,
    hasInitialized: true,
    queryKey: "",
    statisticsMap: proxyMap(),
    forceRevisionIndex: null,
  });
};

export const setSelectedSubmissionId = (quizId: Types.QuizId, selectedSubmissionId: number, questionKey: Types.QuizQuestionKey) => {
  const entityState = state.quizzes.get(quizId);
  if (entityState) {
    entityState.questionBySubmissionMap.set(questionKey, selectedSubmissionId);
  }
};

export const setLatestSubmissions = (quizId: Types.QuizId) => {
  const entityState = state.quizzes.get(quizId);
  if (entityState) {
    for (const key of entityState.questionByLatestSubmissionMap.keys()) {
      const latest = entityState.questionByLatestSubmissionMap.get(key);
      entityState.questionBySubmissionMap.set(key, latest);
    }
  }
};

export const updateEvaluateSubmission = (quizId: Types.QuizId, newSubmission: Types.Submission) => {
  const entityState = state.quizzes.get(quizId);
  if (!entityState) {
    return;
  }
  const oldSubmission = entityState.submissionMap.get(newSubmission.quizSubmissionId);
  if (!oldSubmission) {
    return;
  }
  const evaluationMap = new Map<number, Types.Evaluation>();
  oldSubmission.evaluations.forEach(evaluation => {
    evaluationMap.set(evaluation.evaluationItem?.id || 0, evaluation);
  });

  // Prevent overload empty evaluationItem title and description, we have to handle it.
  const newEvaluations = newSubmission.evaluations
    .map(evaluation => {
      const oldEvaluation = evaluationMap.get(evaluation.evaluationItem?.id || 0);
      if (oldEvaluation) {
        return {
          ...oldEvaluation,
          isPassed: evaluation.isPassed,
          score: evaluation.score,
        };
      }
    })
    .flatMap(v => (v ? [v] : []));

  const submission: Types.Submission = {
    ...oldSubmission,
    ...newSubmission,
    evaluations: newEvaluations,
  };
  entityState.submissionMap.set(newSubmission.quizSubmissionId, submission);
  state.quizzes.set(quizId, entityState);
  const key: Types.QuizQuestionKey = `${newSubmission.questionId}-${newSubmission.questionVersion}`;
  entityState.evaluationByQuestionMap.set(key, newEvaluations);
};

export const setQuery = (entityId: Types.QuizId, query: Types.Query) => {
  const quizState = state.quizzes.get(entityId);
  if (!quizState) {
    return;
  }
  quizState.queryKey = generateKey(query);
};

export const setStatistics = (entityId: Types.QuizId, query: Types.Query, result: Types.Statistics | null) => {
  const quizState = state.quizzes.get(entityId);
  if (!quizState || !result) {
    return;
  }
  quizState.statisticsMap.set(generateKey(query), result);
  setQuery(entityId, query);
};

export const clearAll = () => {
  state.quizzes.clear();
};

export const setForceRevisionIndex = (entityId: Types.QuizId, revisionIndex: Types.RevisionIndex) => {
  const quizState = state.quizzes.get(entityId);
  if (!quizState) {
    return;
  }
  quizState.forceRevisionIndex = revisionIndex;
};
