import { z } from "zod";

/*
 *  expect string like [["1"]]
 * */
export type ArrayStringFormat = string;

/*
 *  expect string like [{"col": 1}]
 * */
export type OutputFormat = string;

export const INITIAL_RECORDS_STRING = "[[]]";

const Column = z.union([z.string(), z.number(), z.boolean(), z.null()]);
const TableRecord = Column.array();
export const TableRecords = TableRecord.array();

export type ColumnType = z.infer<typeof Column>;
export type TableRecordsType = z.infer<typeof TableRecords>;
export const OutputRecordSchema = z.record(z.union([z.string(), z.number(), z.boolean(), z.null()]));
export const OutputRecordsSchema = OutputRecordSchema.array();
export type OutputRecordsSchemaType = z.infer<typeof OutputRecordsSchema>;

export const parseTableRecords = (value: ArrayStringFormat): TableRecordsType => {
  let parsedRecords: TableRecordsType;
  try {
    parsedRecords = TableRecords.parse(JSON.parse(value));
  } catch (e) {
    parsedRecords = [[""]];
  }
  if (parsedRecords.length < 1) {
    parsedRecords = [[""]];
  }

  return parsedRecords;
};

export const parseOutputRecords = (value: OutputFormat): OutputRecordsSchemaType => {
  let parsedRecords: OutputRecordsSchemaType;
  try {
    parsedRecords = OutputRecordsSchema.parse(JSON.parse(value));
  } catch (e) {
    parsedRecords = [];
  }

  return parsedRecords;
};
