import { useTranslation } from "@hireroo/i18n";
import * as z from "zod";

export const useEmailSchema = () => {
  const { t } = useTranslation();
  return z
    .string({
      required_error: t("メールアドレスは必須項目です。"),
    })
    .email({
      message: t("メールアドレスが有効な形式ではありません。"),
    });
};

export type EmailSchema = z.infer<ReturnType<typeof useEmailSchema>>;

export const useEmailListItem = () => {
  const email = useEmailSchema();
  return z.object({
    value: email,
  });
};

export type EmailListItemSchema = z.infer<ReturnType<typeof useEmailListItem>>;

export const useEmailOptional = () => {
  const email = useEmailSchema();
  return z.object({
    value: email.nullable(),
  });
};
export type EmailOptionalSchema = z.infer<ReturnType<typeof useEmailOptional>>;
