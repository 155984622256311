import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";

import AnswerSection, { AnswerSectionProps } from "../../../../ms-components/Question/AnswerSection/AnswerSection";
import QuestionAndAnswerTab, { QuestionAndAnswerTabProps } from "../../../../ms-components/Question/QuestionAndAnswerTab/QuestionAndAnswerTab";

type QuestionAnswerSection = Omit<QuestionAndAnswerTabProps, "answerSections"> & {
  answerSections: (AnswerSectionProps & { tabName: string })[];
};

export type LeftSidePanelProps = {
  questionAndAnswer: QuestionAnswerSection;
};

const SidebarWrapper = styled(Paper)`
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  border-radius: 0;
`;

const ContentWrapper = styled(Box)(({ theme }) => ({
  height: "100%",
  width: "100%",
  backgroundColor: theme.palette["Secondary/Shades"].p8,
  overflowY: "auto",
}));

const LeftSidePanel: React.FC<LeftSidePanelProps> = props => {
  const wrapperDetector = useResizeDetector();
  const questionAndAnswerProps: QuestionAndAnswerTabProps = {
    ...props.questionAndAnswer,
    answerSections: props.questionAndAnswer.answerSections.map((answerSection, index) => {
      const { tabName, ...answerSectionProps } = answerSection;
      return {
        name: tabName,
        Component: <AnswerSection key={`${tabName}-${index}`} {...answerSectionProps} />,
      };
    }),
  };
  return (
    <SidebarWrapper ref={wrapperDetector.ref} elevation={2}>
      <ContentWrapper>
        <QuestionAndAnswerTab {...questionAndAnswerProps} />
      </ContentWrapper>
    </SidebarWrapper>
  );
};

LeftSidePanel.displayName = "LeftSidePanel";

export default LeftSidePanel;
