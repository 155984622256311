import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import SelectableButton, { SelectableButtonProps } from "../../../../primitive/Button/SelectableButton/SelectableButton";
import QuestionTable, { QuestionTableProps } from "../QuestionTable/QuestionTable";

const CHANGE_BUTTON_CLASS_NAME = "change-button";

const StyledWrapper = styled(Box)(() => ({
  ".MuiTableContainer-root": {
    borderTopLeftRadius: "unset",
    borderTopRightRadius: "unset",
  },
}));

const StyledHeader = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette["Gray/Shades"].p8}`,
  borderBottom: "none",
  borderTopLeftRadius: "12px",
  borderTopRightRadius: "12px",
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  height: 40,
  display: "flex",
  alignItems: "center",
}));

const StyledSelectableButton = styled(SelectableButton)(() => ({
  ".MuiButtonGroup-grouped": {
    padding: "4px 10px",
    height: "30px",
  },
  [`.${CHANGE_BUTTON_CLASS_NAME}`]: {
    width: 36,
    minWidth: 36,
  },
}));

export type QuestionSelectorProps = {
  disabledTooltip: string;
  disabledCreateButton: boolean;
  selectedQuestionCount: number;
  createCodingTestButton: Pick<SelectableButtonProps["items"][0], "onClick" | "onSelected" | "disabled" | "title">;
  createTalentScoreButton: Pick<SelectableButtonProps["items"][0], "onClick" | "onSelected" | "disabled" | "title">;
  table: QuestionTableProps;
};

const QuestionSelector: React.FC<QuestionSelectorProps> = props => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const selectableButton: SelectableButtonProps = {
    color: "primary",
    title: props.disabledTooltip,
    disabled: props.disabledCreateButton,
    items: [
      {
        ...props.createCodingTestButton,
        children: t("コーディングテスト作成"),
      },
      {
        ...props.createTalentScoreButton,
        children: t("タレントスコア作成"),
      },
    ],
    ChangeButtonProps: {
      className: CHANGE_BUTTON_CLASS_NAME,
    },
  };
  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight={700}>{t("問題")}</Typography>
      </Stack>
      <StyledWrapper>
        <StyledHeader>
          <Typography fontSize={14} variant="body2" mr={2}>
            {t2("SelectingItems", {
              nums: props.selectedQuestionCount,
            })}
          </Typography>
          <StyledSelectableButton {...selectableButton} />
        </StyledHeader>
        <QuestionTable {...props.table} />
      </StyledWrapper>
    </Stack>
  );
};

QuestionSelector.displayName = "QuestionSelector";

export default QuestionSelector;
