import { useTranslation } from "@hireroo/i18n";
import * as z from "zod";

export const useRequestResetPasswordFormSchema = () => {
  const { t } = useTranslation();
  return z.object({
    email: z
      .string()
      .min(1, t("メールアドレスは必須項目です。"))
      .regex(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, { message: t("メールアドレスが有効な形式ではありません。") }),
  });
};

export type RequestResetPasswordFormSchema = z.infer<ReturnType<typeof useRequestResetPasswordFormSchema>>;

export const useResetPasswordFormSchema = () => {
  const { t } = useTranslation();
  return z
    .object({
      password: z.string().min(1, t("必須項目です")).min(6, t("最低6文字以上で入力して下さい")),
      confirmPassword: z.string().min(1, t("必須項目です")),
    })
    .superRefine((val, ctx) => {
      if (val.password !== val.confirmPassword) {
        ctx.addIssue({
          path: ["confirmPassword"],
          code: "custom",
          message: t("パスワードが一致しません。"),
        });
      }
    });
};

export type ResetPasswordFormSchema = z.infer<ReturnType<typeof useResetPasswordFormSchema>>;
