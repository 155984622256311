import { useTranslation } from "@hireroo/i18n";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";

import SummaryRows, { SummaryRowsProps } from "./parts/SummaryRows";

export type AlgorithmTestCaseTableSummaryPreviewProps = {
  columnName: string;
  summaryRows: SummaryRowsProps[];
  hasEdit?: boolean;
};

const AlgorithmTestCaseTableSummaryPreview: React.FC<AlgorithmTestCaseTableSummaryPreviewProps> = props => {
  const { t } = useTranslation();
  return (
    <TableContainer>
      <Table aria-label="collapsible table" size="small" width="100%">
        <TableHead>
          <TableRow>
            {/*for collapse button*/}
            <TableCell />
            <TableCell width={"30%"}>{props.columnName}</TableCell>
            <TableCell width={"40%"}>{t("カラム")}</TableCell>
            <TableCell align="right">{t("レコード数")}</TableCell>
            {props.hasEdit && <TableCell />}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.summaryRows.map(summaryRows => (
            <SummaryRows key={summaryRows.tableName} {...summaryRows} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

AlgorithmTestCaseTableSummaryPreview.displayName = "AlgorithmTestCaseTableSummaryPreview";

export default AlgorithmTestCaseTableSummaryPreview;
