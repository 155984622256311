import ButtonGroup, { ButtonGroupProps } from "@mui/material/ButtonGroup";
import { styled } from "@mui/material/styles";
import React from "react";

export type ControlButtonGroupProps = ButtonGroupProps;

const ControlButtonGroup: React.FC<ControlButtonGroupProps> = styled(ButtonGroup)(({ theme }) => ({
  height: 32,
  ".MuiButtonGroup-grouped": {
    minWidth: 32,
    border: `1px solid ${theme.palette.divider}`,
  },
  ".MuiButtonGroup-grouped:not(:last-of-type)": {
    border: `1px solid ${theme.palette.divider}`,
    "&:hover": {
      border: `1px solid ${theme.palette.divider}`,
    },
  },
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 5,
}));

ControlButtonGroup.displayName = "ControlButtonGroup";

export default ControlButtonGroup;
