import { useTranslation } from "@hireroo/i18n";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import ScreeningTestLayout, { ScreeningTestLayoutProps } from "../../layouts/ScreeningTestLayout/ScreeningTestLayout";
import EntryOverviewSection, { EntryOverviewSectionProps } from "../../modules/EntryOverviewSection/EntryOverviewSection";
import EntryTestOverviewSection, {
  EntryTestOverviewSectionProps,
} from "../../ms-components/Spot/EntryTestOverviewSection/EntryTestOverviewSection";
import BaseDialog, { BaseDialogProps } from "../../primitive/BaseDialog/BaseDialog";
import StartSection, { StartSectionProps } from "./parts/StartSection/StartSection";

const Heading = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  fontWeight: 700,
  textAlign: "center",
  marginBottom: theme.spacing(1.5),
}));

type TestTakerOverviewSection = {
  name: string;
  mailAddress: string;
};

export type EntryScreeningTestProps = {
  layout: Omit<ScreeningTestLayoutProps, "children">;
  testOverviewSection: EntryTestOverviewSectionProps;
  testTaker: TestTakerOverviewSection;
  startSection: StartSectionProps;
  openNotifyDialog: boolean;
};

const EntryScreeningTest: React.FC<EntryScreeningTestProps> = props => {
  const { layout } = props;
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(props.openNotifyDialog);

  const testTakerSummarySection = React.useMemo((): EntryOverviewSectionProps => {
    return {
      title: t("受験者概要"),
      items: [
        {
          title: t("受験者名"),
          content: props.testTaker.name,
        },
        {
          title: t("メールアドレス"),
          content: props.testTaker.mailAddress,
        },
      ],
    };
  }, [t, props.testTaker]);

  const baseDialog: BaseDialogProps = {
    open: open,
    title: t("テストリンクが発行されました"),
    yesButton: {
      children: t("閉じる"),
      onClick: () => {
        setOpen(false);
      },
    },
  };

  return (
    <ScreeningTestLayout {...layout}>
      <Container sx={{ paddingY: "1em" }}>
        <Heading variant="h5">{t("エントリー")}</Heading>
        <Stack spacing={2} m={"auto"} sx={{ minWidth: "640px", maxWidth: "800px" }}>
          <EntryTestOverviewSection {...props.testOverviewSection} />
          <EntryOverviewSection {...testTakerSummarySection} />
          <StartSection {...props.startSection} />
        </Stack>
      </Container>
      <BaseDialog {...baseDialog}>
        <Typography variant="body1">
          {[t("テストリンクを入力されたメールアドレスに送信しました。"), t("提出期限内にテストを提出してください。")].join("")}
        </Typography>
        <Typography variant="body1" fontWeight="bold">
          {props.testTaker.mailAddress}
        </Typography>
      </BaseDialog>
    </ScreeningTestLayout>
  );
};

EntryScreeningTest.displayName = "EntryScreeningTest";

export default EntryScreeningTest;
