import { RemotesId } from "@hireroo/app-store/page/c/remotes_id";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { getTranslation } from "@hireroo/i18n";

export const startSubscribeActiveSessionsForUpdateTemplateCodes = () => {
  return RemotesId.subscribeActiveSessionMap(activeSessions => {
    const client = getGraphqlClient();
    const { t } = getTranslation();
    const algorithmQuestions = activeSessions.map(session => session.algorithmQuestion).flatMap(v => (v ? [v] : []));

    const liveCodingTemplateCodesMap = RemotesId.getLiveCodingTemplateCodesMap();
    const algorithmQuestionsNotGettingTemplateCodes = algorithmQuestions.filter(algorithmQuestion => {
      const key: RemotesId.LiveCodingTemplateCodesMapKey = `${algorithmQuestion.questionId}-${algorithmQuestion.version}`;
      return !liveCodingTemplateCodesMap.has(key);
    });

    const tasks = algorithmQuestionsNotGettingTemplateCodes.map(algorithmQuestion => {
      const key: RemotesId.LiveCodingTemplateCodesMapKey = `${algorithmQuestion.questionId}-${algorithmQuestion.version}`;
      client
        .GetLiveCodingTemplateCodes({
          questionId: algorithmQuestion.questionId,
          questionVersion: algorithmQuestion.version,
        })
        .then(res => {
          RemotesId.addLiveCodingTemplateCodes(key, res.liveCodingTemplateCodes);
        })
        .catch(() => {
          Snackbar.notify({
            severity: "error",
            message: t("コードテンプレートの取得に失敗しました。"),
          });
        });
    });

    Promise.all(tasks);
  });
};
