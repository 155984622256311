import { TAX_RATE_PERCENT } from "@hireroo/app-definition/payment";
import { Def } from "@hireroo/app-helper/payment";
import { PlanUpdateForm } from "@hireroo/app-store/widget/e/PlanUpdateForm";
import { formatPrice } from "@hireroo/formatter/money";
import { unixToDatetimeFormat, yearMonthFormat } from "@hireroo/formatter/time";
import type * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import addMonths from "date-fns/addMonths";
import differenceInMonths from "date-fns/differenceInMonths";
import * as React from "react";

type PayNextMonthConfirmSectionProps = Widget.PlanUpdateFormProps["payNextMonthConfirmSection"];

const planTypeToNumber: Record<Graphql.PlanType, number> = {
  BASIC: 1,
  STANDARD: 2,
  PROFESSIONAL: 3,
  ENTERPRISE: 4,
};

const useMostExpensivePlanInCurrentMonth = () => {
  const plans = PlanUpdateForm.usePlans();
  const temporarySubmitValue = PlanUpdateForm.useTemporarySubmitValue();
  const previousMostExpensivePurchase = PlanUpdateForm.usePreviousMostExpensivePurchase();
  return React.useMemo(() => {
    if (!temporarySubmitValue) {
      return null;
    }
    const currentSelectedPlan = plans.find(plan => {
      return Def.planTypeMapValidatorToGraphql[temporarySubmitValue.plan] === plan.planType;
    });
    if (previousMostExpensivePurchase?.plan) {
      if (!currentSelectedPlan) {
        return previousMostExpensivePurchase.plan;
      }
      const currentSelectedPlanNumber = planTypeToNumber[currentSelectedPlan.planType];
      const previousPlanNumber = planTypeToNumber[previousMostExpensivePurchase.plan.planType];
      /**
       * @example Upsell Pattern
       * currentSelectedPlan : Professional
       * previousPlan        : Basic
       */
      if (currentSelectedPlanNumber - previousPlanNumber > 0) {
        return currentSelectedPlan;
      }
      /**
       * @example Downsell (or Same Sell) Pattern
       * currentSelectedPlan : Basic
       * previousPlan        : Professional
       */
      return previousMostExpensivePurchase.plan;
    }
    /**
     * Pattern with no previous purchase history
     */
    return currentSelectedPlan;
  }, [previousMostExpensivePurchase, temporarySubmitValue, plans]);
};

const useNextMonthPlan = () => {
  const customer = PlanUpdateForm.useCustomer();
  const plans = PlanUpdateForm.usePlans();
  const temporarySubmitValue = PlanUpdateForm.useTemporarySubmitValue();
  return React.useMemo(() => {
    return plans.find(plan => {
      /** SKIP Default selected plan type */
      if (customer.subscription.plan.planType === plan.planType) {
        return false;
      }
      return Def.planTypeMapGraphqlToValidator[plan.planType] === temporarySubmitValue?.plan;
    });
  }, [customer.subscription.plan.planType, plans, temporarySubmitValue?.plan]);
};

const useCoupons = (args: { subTotal: number }): PayNextMonthConfirmSectionProps["coupons"] => {
  const { subTotal } = args;
  const { t: t2 } = useTranslationWithVariable();
  const lang = useLanguageCode();
  const customer = PlanUpdateForm.useCustomer();
  return customer.subscription.subscribedCoupons.reduce<PayNextMonthConfirmSectionProps["coupons"]>((acc, subscribedCoupon) => {
    const { coupon } = subscribedCoupon;
    if (coupon.couponType === "PERCENT_OFF") {
      const billingMonth = addMonths(new Date(), 1);
      /**
       * @example 1
       * - createdAt    = 2023/05/10
       * - billingMonth = 2023/12/15
       * - leftValue    = 12 - 5 = 7 (valid)
       *
       * @example 2
       * - createdAt    = 2023/11/15
       * - billingMonth = 2023/12/15
       * - leftValue    = 12 - 11 = 0 (valid)
       *
       * @example 3
       * - createdAt    = 2023/11/15
       * - billingMonth = 2023/11/15
       * - leftValue    = 11 - 11 = 0 (valid)
       **/
      const leftValue = differenceInMonths(billingMonth, new Date(subscribedCoupon.createdAtSeconds * 1000));
      /**
       * @example 1
       * - expiresAt    = 2023/05/10
       * - billingMonth = 2023/12/15
       * - rightValue   = 5 - 12 = -7 (expired)
       *
       * @example 2
       * - expiresAt    = 2023/12/15
       * - billingMonth = 2023/12/15
       * - rightValue   = 12 - 12 = 0 (valid)
       *
       * @example 3 If the end of the coupon period and the current month are the same, the coupon will not be applied to the next billing
       * - expiresAt    = 2023/11/15
       * - billingMonth = 2023/12/15
       * - rightValue   = 11 - 12 = -1 (expired)
       **/
      const rightValue = differenceInMonths(new Date(subscribedCoupon.expiresAtSeconds * 1000), billingMonth);

      const withinExpiration = 0 <= leftValue && 0 <= rightValue;
      if (!withinExpiration) {
        return acc;
      }

      return acc.concat({
        title: [
          resolveLanguage(coupon, lang, "name"),
          t2("percentDiscountDescription", {
            value: coupon.discountNumber,
            duration: `${unixToDatetimeFormat(subscribedCoupon.createdAtSeconds)} - ${unixToDatetimeFormat(subscribedCoupon.expiresAtSeconds)}`,
          }),
        ].join(" "),
        value: formatPrice(-(subTotal * coupon.discountNumber) / 100),
      });
    }
    return acc;
  }, []);
};

export const usePayNextMonthConfirmSectionProps = (): PayNextMonthConfirmSectionProps => {
  const customer = PlanUpdateForm.useCustomer();
  const temporarySubmitValue = PlanUpdateForm.useTemporarySubmitValue();
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const initialPlan = Def.planTypeMapGraphqlToValidator[customer.subscription.plan.planType];

  const lang = useLanguageCode();
  const mostExpensivePlanPreviouslySelected = useMostExpensivePlanInCurrentMonth();

  const willBuyItems = React.useMemo((): PayNextMonthConfirmSectionProps["items"] => {
    if (initialPlan === temporarySubmitValue?.plan) {
      return [];
    }
    const items: PayNextMonthConfirmSectionProps["items"] = [];
    if (mostExpensivePlanPreviouslySelected) {
      const planPrice = customer.isTrial ? 0 : mostExpensivePlanPreviouslySelected.price;
      items.push({
        title: resolveLanguage(mostExpensivePlanPreviouslySelected, lang, "name"),
        count: 1,
        tax: `${TAX_RATE_PERCENT}%`,
        price: { value: formatPrice(planPrice) },
      });
    }
    return items;
  }, [initialPlan, customer.isTrial, lang, mostExpensivePlanPreviouslySelected, temporarySubmitValue?.plan]);

  const subTotal = React.useMemo((): number => {
    const planPrice = customer.isTrial ? 0 : mostExpensivePlanPreviouslySelected?.price ?? 0;
    const values: number[] = [planPrice];
    const total = values.reduce<number>((total, current) => total + current, 0);
    return total;
  }, [customer.isTrial, mostExpensivePlanPreviouslySelected]);

  const discountValue = React.useMemo((): number => {
    return customer.subscription.subscribedCoupons.reduce<number>((acc, subscribedCoupon) => {
      const { coupon } = subscribedCoupon;
      switch (coupon.couponType) {
        case "PERCENT_OFF": {
          const discountValue = subTotal * (coupon.discountNumber / 100);
          return acc + discountValue;
        }
        /**
         * Among SubscribedCoupon, AMOUNT_OFF is not included in the scope.
         */
        case "AMOUNT_OFF":
        case "UNKNOWN":
        default:
          return acc;
      }
    }, 0);
  }, [customer.subscription.subscribedCoupons, subTotal]);

  const couponsProps = useCoupons({
    subTotal,
  });

  const temporarySubTotalWithCoupon = subTotal - discountValue;
  const subTotalWithCoupon = temporarySubTotalWithCoupon > 0 ? temporarySubTotalWithCoupon : 0;
  const taxPrice = (subTotalWithCoupon * TAX_RATE_PERCENT) / 100;
  const total = subTotalWithCoupon + taxPrice;

  const now = new Date();
  const billingMonth = addMonths(now, 1);

  return {
    title: t2("paymentYearMonth", { yearMonth: yearMonthFormat(billingMonth, { noZeroPadding: true }) }),
    items: willBuyItems,
    coupons: couponsProps,
    taxPrice: {
      title: [t("消費税"), "(", t2("vatRate", { taxRate: TAX_RATE_PERCENT, price: formatPrice(subTotalWithCoupon) }), ")"].join(""),
      value: formatPrice(taxPrice),
    },
    subtotalPrice: {
      value: formatPrice(subTotal),
    },
    totalPriceWithoutTax: {
      value: formatPrice(subTotalWithCoupon),
    },
    billingAmount: {
      value: formatPrice(total),
    },
    importantMessage: {
      children: t2("payNextMonthInfo", {
        currentMonth: yearMonthFormat(now, { noZeroPadding: true }),
        nextMonth: yearMonthFormat(billingMonth, { noZeroPadding: true }),
      }),
    },
  };
};

export const usePayInTwoMonthConfirmSectionProps = (): PayNextMonthConfirmSectionProps | undefined => {
  const customer = PlanUpdateForm.useCustomer();
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const lang = useLanguageCode();
  const selectedPlan = useNextMonthPlan();

  const willBuyItems = React.useMemo((): PayNextMonthConfirmSectionProps["items"] => {
    const items: PayNextMonthConfirmSectionProps["items"] = [];
    if (selectedPlan) {
      const planPrice = customer.isTrial ? 0 : selectedPlan.price;
      items.push({
        title: resolveLanguage(selectedPlan, lang, "name"),
        count: 1,
        tax: `${TAX_RATE_PERCENT}%`,
        price: { value: formatPrice(planPrice) },
      });
    }
    return items;
  }, [customer.isTrial, lang, selectedPlan]);

  const subTotal = React.useMemo((): number => {
    const planPrice = customer.isTrial ? 0 : selectedPlan?.price ?? 0;
    const values: number[] = [planPrice];
    const total = values.reduce<number>((total, current) => total + current, 0);
    return total;
  }, [customer.isTrial, selectedPlan]);

  const discountValue = React.useMemo((): number => {
    return customer.subscription.subscribedCoupons.reduce<number>((acc, subscribedCoupon) => {
      const { coupon } = subscribedCoupon;
      switch (coupon.couponType) {
        case "PERCENT_OFF": {
          const discountValue = subTotal * (coupon.discountNumber / 100);
          return acc + discountValue;
        }
        /**
         * Among SubscribedCoupon, AMOUNT_OFF is not included in the scope.
         */
        case "AMOUNT_OFF":
        case "UNKNOWN":
        default:
          return acc;
      }
    }, 0);
  }, [customer.subscription.subscribedCoupons, subTotal]);

  const couponsProps = useCoupons({
    subTotal,
  });

  const temporarySubTotalWithCoupon = subTotal - discountValue;
  const subTotalWithCoupon = temporarySubTotalWithCoupon > 0 ? temporarySubTotalWithCoupon : 0;
  const taxPrice = (subTotalWithCoupon * TAX_RATE_PERCENT) / 100;
  const total = subTotalWithCoupon + taxPrice;

  const billingMonth = addMonths(new Date(), 2);

  return {
    title: t2("paymentYearMonth", { yearMonth: yearMonthFormat(billingMonth, { noZeroPadding: true }) }),
    items: willBuyItems,
    coupons: couponsProps,
    taxPrice: {
      title: [t("消費税"), "(", t2("vatRate", { taxRate: TAX_RATE_PERCENT, price: formatPrice(subTotalWithCoupon) }), ")"].join(""),
      value: formatPrice(taxPrice),
    },
    subtotalPrice: {
      value: formatPrice(subTotal),
    },
    totalPriceWithoutTax: {
      value: formatPrice(subTotalWithCoupon),
    },
    billingAmount: {
      value: formatPrice(total),
    },
    importantMessage: {
      children: t2("payInTwoMonthInfo", {
        payMonth: yearMonthFormat(billingMonth, { noZeroPadding: true }),
      }),
    },
  };
};
