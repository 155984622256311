import { useTitle } from "@hireroo/app-helper/react-use";
import { App } from "@hireroo/app-store/essential/employee";
import { EmployeeSideBarContent } from "@hireroo/app-store/widget/e/EmployeeSideBarContent";
import { useTranslation } from "@hireroo/i18n";
import type { Pages } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";

import CompanySettingsNavigatorContainer from "../../../../widget/v2/e/CompanySettingsNavigator/Container";
import EmployeeHeaderRightContentContainer from "../../../../widget/v2/e/EmployeeHeaderRightContent/Container";
import EmployeeSideBarContentContainer from "../../../../widget/v2/e/EmployeeSideBarContent/Container";
import FooterContainer from "../../../../widget/v2/e/Footer/Container";
import { useNotificationBanner } from "../../../../widget/v2/e/NotificationBanner/Container";
import PlanListFetchContainer from "../../../../widget/v2/e/PlanList/FetchContainer";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";

export type GeneratePlanListSettingsContainerPropsArgs = {};

export const useGenerateProps = (_args: GeneratePlanListSettingsContainerPropsArgs): Pages.CompanySettingsProps => {
  const { t } = useTranslation();
  const navigate = useTransitionNavigate();
  const loading = App.useStatus() === "INITIALIZING";
  const NotificationBannerContainer = useNotificationBanner();

  useTitle(t("プラン一覧"));

  return {
    layout: {
      openSidebar: EmployeeSideBarContent.useOpenSidebar(),
      onChangeOpenSidebar: open => {
        EmployeeSideBarContent.setOpenSidebar(open);
      },
      NotificationBanner: NotificationBannerContainer,
      loading: loading,
      HeaderRightContent: <EmployeeHeaderRightContentContainer />,
      SideBarContent: <EmployeeSideBarContentContainer />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      sideBarHeader: {
        logo: {
          href: generatePath("/e/home"),
          onClick: () => {
            navigate("/e/home");
          },
        },
      },
    },
    settingsLayout: {
      HeaderMenu: <CompanySettingsNavigatorContainer active="PLAN_LIST" />,
    },
    children: <PlanListFetchContainer />,
  };
};
