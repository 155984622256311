import { ProjectCodingEditorV3 } from "@hireroo/app-store/widget/shared/ProjectCodingEditorV3";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

import type { Mode } from "./types";

export type GenerateAppealMessagePropsArgs = {
  mode: Mode;
  appealMessage: string;
  entityId: number;
};

export const useGenerateAppealMessageProps = (
  args: GenerateAppealMessagePropsArgs,
): Widget.ProjectCodingEditorV3Props["sidebar"]["appealMessage"] => {
  const [_appealMessageStatus, setAppealMessageStatus] = React.useState<"READY" | "PENDING">("READY");
  const action = ProjectCodingEditorV3.createProjectEntityAction(args.entityId);
  const hooks = ProjectCodingEditorV3.useCreateProjectEntityHooks(args.entityId);
  const appealMessage = hooks.useAppealMessage();
  const client = getGraphqlClient();
  const { t } = useTranslation();
  return {
    showing: args.mode === "DEVELOPMENT",
    value: appealMessage ?? "",
    previewEditor: {
      defaultViewMode: args.mode === "EVALUATION" ? "PREVIEW" : "WRITE",
      disableToggleButton: args.mode === "EVALUATION",
    },
    placeholder: t(
      "提出するコードに説明を加えることができます。実装に至るまでの思考プロセスや各処理の詳細な説明などを自由に記述してください。",
    ),
    onChange: newAppealMessage => {
      setAppealMessageStatus("PENDING");
      client
        .SaveProjectAppealMessageForProjectCodingEditor({
          input: {
            projectId: args.entityId,
            appealMessage: newAppealMessage,
          },
        })
        .then(res => {
          if (res.saveProjectAppealMessage?.appealMessage) {
            action.updateAppealMessage(res.saveProjectAppealMessage.appealMessage);
          }
          Snackbar.notify({
            severity: "success",
            message: t("説明文の保存に成功しました。"),
          });
        })
        .catch(() => {
          Snackbar.notify({
            severity: "error",
            message: t("説明文の保存に失敗しました。しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
          });
        })
        .finally(() => {
          setAppealMessageStatus("READY");
        });
    },
  };
};
