import { QuestionPackageSearchForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import SearchForm, { SearchFormProps } from "../../modules/SearchForm/SearchForm";
import * as PrivateHelper from "./privateHelper";

export type QuestionPackageSearchAreaProps = {
  disabled: boolean;
  defaultValues: QuestionPackageSearchForm.QuestionPackageSearchQuery;
  onChange: SubmitHandler<QuestionPackageSearchForm.QuestionPackageSearchQuery>;
  onReset?: () => void;
};

const QuestionPackageSearchArea: React.FC<QuestionPackageSearchAreaProps> = props => {
  const { onChange } = props;
  const validateSchema = QuestionPackageSearchForm.useQuestionPackageSearchQuery();
  const methods = useForm<QuestionPackageSearchForm.QuestionPackageSearchQuery>({
    resolver: zodResolver(validateSchema),
    defaultValues: {
      ...props.defaultValues,
    },
  });
  const textFilterField = PrivateHelper.useTextFilterField();
  const difficultyField = PrivateHelper.useDifficultyField();

  React.useEffect(() => {
    const subscriber = methods.watch(() => {
      methods.handleSubmit(onChange, console.warn)();
    });
    return () => {
      subscriber.unsubscribe();
    };
  }, [methods, onChange]);

  const searchForm: SearchFormProps = {
    kind: "MULTI_LINE",
    variant: "STYLED",
    disabled: props.disabled,
    textFilter: textFilterField,
    fields: [difficultyField].flatMap(v => (v ? [v] : [])),
    onReset: () => {
      const resetValues: QuestionPackageSearchForm.QuestionPackageSearchQuery = {
        difficulties: [],
        textFilter: "",
      };
      methods.reset(resetValues);
      props.onReset?.();
    },
  };
  return (
    <FormProvider {...methods}>
      <SearchForm {...searchForm} />
    </FormProvider>
  );
};

QuestionPackageSearchArea.displayName = "QuestionPackageSearchArea";

export default QuestionPackageSearchArea;
