import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";
import * as React from "react";

type Color = "PRIMARY" | "GRAY";

export type StatusProps = {
  text: string;
  color: Color;
};

const Status: React.FC<StatusProps> = props => {
  const theme = useTheme();

  const colorMap: Record<Color, string> = {
    PRIMARY: theme.palette.primary.main,
    GRAY: theme.palette.text.disabled,
  };

  return (
    <Box>
      <Chip label={props.text} size="small" sx={{ backgroundColor: colorMap[props.color], color: theme.palette.common.black }} />
    </Box>
  );
};

Status.displayName = "Status";

export default Status;
