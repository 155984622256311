import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import * as React from "react";

import PreferencesMenu, { PreferencesMenuProps } from "../../modules/PreferencesMenu/PreferencesMenu";
import SymbolButton, { SymbolButtonProps } from "../../primitive/Button/SymbolButton/SymbolButton";

export type EmployeeHeaderRightContentProps = {
  EmployeeMenu: React.ReactNode;
  helpButton?: SymbolButtonProps & { href?: string; target?: string };
  preferences: PreferencesMenuProps;
  NotificationButton: React.ReactNode;
};

const EmployeeHeaderRightContent: React.FC<EmployeeHeaderRightContentProps> = props => {
  const helpButton: SymbolButtonProps | undefined = props.helpButton
    ? {
        ...props.helpButton,
        children: <HelpOutlineIcon />,
        color: "secondary",
        ...(props.helpButton.href ? { component: "a" } : {}),
      }
    : undefined;
  return (
    <Box>
      <Stack direction="row" spacing={1} width="100%" justifyContent="flex-end" alignItems="center">
        {props.NotificationButton}
        <PreferencesMenu {...props.preferences} />
        {helpButton && <SymbolButton {...helpButton} />}
        {props.EmployeeMenu}
      </Stack>
    </Box>
  );
};

EmployeeHeaderRightContent.displayName = "EmployeeHeaderRightContent";

export default EmployeeHeaderRightContent;
