import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { TestEvaluationForm } from "@hireroo/validator";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import ButtonWithTooltip, { ButtonWithTooltipProps } from "../../primitive/Button/ButtonWithTooltip/ButtonWithTooltip";
import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";
import DeletesDialog, { DeletesDialogProps } from "../DeletesDialog/DeletesDialog";
import TagBulkApplyPopper, { TagBulkApplyPopperProps } from "./parts/TagBulkApplyPopper/TagBulkApplyPopper";
import TestMultiEvaluationDialog, { TestMultiEvaluationDialogProps } from "./parts/TestMultiEvaluationDialog/TestMultiEvaluationDialog";

type DialogStatus = "DELETES_OPEN" | "TAG_OPEN" | "EVALUATION_OPEN" | "CLOSE";
type DialogController = {
  setLoading: (loading: boolean) => void;
  close: () => void;
};

export type BulkListActivityBarProps = {
  deletesDialog: Pick<DeletesDialogProps, "items" | "description"> & {
    title: string;
    onSubmit: (controller: DialogController) => void;
  };
  deleteButton: Pick<IconButtonWithTooltipProps, "title" | "disabled">;
  tagPopper: TagBulkApplyPopperProps;
  selectedItemsCount?: number;
  evaluation?: {
    dialog: Pick<TestMultiEvaluationDialogProps, "heading" | "items" | "additionalCautionItems">;
    onSubmit: (fields: TestEvaluationForm.TestEvaluationFormSchema, controller: DialogController) => void;
  } & Pick<ButtonWithTooltipProps, "title" | "disabled">;
};

const BulkListActivityBar: React.FC<BulkListActivityBarProps> = props => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const [dialogStatus, setDialogStatus] = React.useState<DialogStatus>("CLOSE");
  const [loading, setLoading] = React.useState(false);
  const deleteIconButton: IconButtonWithTooltipProps = {
    ...props.deleteButton,
    onClick: () => {
      setDialogStatus("DELETES_OPEN");
    },
    children: <DeleteOutlineIcon fontSize="small" />,
  };

  const deletesDialog: DeletesDialogProps = {
    ...props.deletesDialog,
    dialog: {
      title: props.deletesDialog.title,
      open: dialogStatus === "DELETES_OPEN",
      onClose: () => {
        setDialogStatus("CLOSE");
      },
      noButton: {
        onClick: () => {
          setDialogStatus("CLOSE");
        },
        disabled: loading,
      },
      yesButton: {
        disabled: loading,
      },
    },
    onSubmit: () => {
      const controller: DialogController = {
        close: () => {
          setDialogStatus("CLOSE");
          setLoading(false);
        },
        setLoading: (newLoading: boolean) => {
          setLoading(newLoading);
        },
      };
      props.deletesDialog.onSubmit(controller);
    },
  };

  const testMultiEvaluationDialog: TestMultiEvaluationDialogProps | undefined = props.evaluation
    ? {
        ...props.evaluation.dialog,
        open: dialogStatus === "EVALUATION_OPEN",
        onClose: () => {
          setDialogStatus("CLOSE");
        },
        yesButton: {
          disabled: loading,
        },
        onSubmit: fields => {
          const controller: DialogController = {
            close: () => {
              setDialogStatus("CLOSE");
              setLoading(false);
            },
            setLoading: (newLoading: boolean) => {
              setLoading(newLoading);
            },
          };
          props.evaluation?.onSubmit(fields, controller);
        },
      }
    : undefined;
  const evaluateButton: ButtonWithTooltipProps = {
    startIcon: "EDIT",
    variant: "contained",
    size: "small",
    children: t("一括評価"),
    onClick: () => {
      setDialogStatus("EVALUATION_OPEN");
    },
    disabled: loading || props.evaluation?.disabled,
    title: props.evaluation?.title,
  };

  return (
    <Stack direction="row" spacing={1.5} alignItems="center">
      {props.selectedItemsCount && props.selectedItemsCount > 0 && (
        <Typography fontSize={14}>{t2("SelectingItems", { nums: props.selectedItemsCount })}</Typography>
      )}
      <TagBulkApplyPopper {...props.tagPopper} />
      <IconButtonWithTooltip key={`delete-button`} {...deleteIconButton} />
      {props.evaluation && testMultiEvaluationDialog && (
        <>
          <ButtonWithTooltip {...evaluateButton} />
          <TestMultiEvaluationDialog key={`evaluate-${testMultiEvaluationDialog.open}`} {...testMultiEvaluationDialog} />
        </>
      )}
      <DeletesDialog key={`delete-dialog-${deletesDialog.dialog.open}`} {...deletesDialog} />
    </Stack>
  );
};

BulkListActivityBar.displayName = "BulkListActivityBar";

export default BulkListActivityBar;
