import { useTranslation } from "@hireroo/i18n";
import { GroupSettingsForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import ResourceField from "../../../../modules/ResourceField/ResourceField";
import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";
import InputControlTextField, {
  InputControlTextFieldProps,
} from "../../../../primitive/InputControl/InputControlTextField/InputControlTextField";

type Kind = "CREATE" | "EDIT";

export type EditGroupDialogProps = {
  dialog: Pick<DialogWithCloseIconProps, "open" | "onClose" | "noButton">;
  onSubmit: SubmitHandler<GroupSettingsForm.EmployeeGroupSettingsSchema>;
  kind: Kind;
  EmployeeField: React.ReactNode;
  defaultValues: GroupSettingsForm.EmployeeGroupSettingsSchema;
};

const EditGroupDialog: React.FC<EditGroupDialogProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const validateSchema = GroupSettingsForm.useEmployeeGroupSettingsSchema();
  const methods = useForm<GroupSettingsForm.EmployeeGroupSettingsSchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: props.defaultValues,
  });
  const titleMap: Record<Kind, string> = {
    CREATE: t("グループの作成"),
    EDIT: t("グループの編集"),
  };
  const descriptionMap: Record<Kind, string> = {
    CREATE: t("作成されたグループはテストの閲覧権限として設定できます。"),
    EDIT: t("作成されたグループはテストの閲覧権限として設定できます。"),
  };
  const saveButtonMap: Record<Kind, string> = {
    CREATE: t("作成"),
    EDIT: t("保存"),
  };
  const dialog: DialogWithCloseIconProps = {
    ...props.dialog,
    title: titleMap[props.kind],
    yesButton: {
      onClick: () => {
        methods.handleSubmit(props.onSubmit, console.warn)();
      },
      children: saveButtonMap[props.kind],
      color: "secondary",
    },
    onClose: () => {
      props.dialog.onClose?.();
      methods.clearErrors();
    },
    noButton: {
      ...props.dialog.noButton,
      children: t("キャンセル"),
      color: "outline-only",
      variant: "outlined",
      onClick: event => {
        props.dialog.noButton?.onClick?.(event);
        methods.clearErrors();
      },
    },
    disableEnter: true,
    optionalContent: {
      sx: {
        px: 3,
        py: 2,
      },
    },
  };
  const nameFieldProps: InputControlTextFieldProps = {
    fullWidth: true,
    type: "text",
  };
  return (
    <FormProvider {...methods}>
      <DialogWithCloseIcon {...dialog}>
        <Stack direction="column" spacing={3}>
          <Stack direction="row" spacing={1.5} alignItems="center">
            <InfoOutlinedIcon htmlColor={theme.palette.text.secondary} sx={{ fontSize: "0.8rem" }} />
            <Typography fontSize={14} color="textSecondary">
              {descriptionMap[props.kind]}
            </Typography>
          </Stack>
          <ResourceField label={t("グループ名")} kind="REQUIRED">
            <InputControlTextField name="name" {...nameFieldProps} />
          </ResourceField>
          <ResourceField label={t("メンバー")} kind="OPTIONAL">
            {props.EmployeeField}
          </ResourceField>
        </Stack>
      </DialogWithCloseIcon>
    </FormProvider>
  );
};

EditGroupDialog.displayName = "EditGroupDialog";

export default EditGroupDialog;
