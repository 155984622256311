import { DeepReadonly } from "@hireroo/app-helper/types";
import { useSnapshot } from "valtio";

import { DISPLAY_PER_PAGE } from "./definition";
import { state } from "./State";
import type * as Types from "./types";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = () => {
  const snapshot = useSnapshotState();
  return snapshot.initialized;
};

export const useExams = (): DeepReadonly<Types.Exam>[] => {
  const snapshot = useSnapshotState();
  return snapshot.examIds.map(examId => {
    const exam = snapshot.examMap.get(examId);
    if (!exam) throw new Error(`exam ${examId} not found`);
    return exam;
  });
};

/**
 * display current exams.
 */
export const useCurrentExams = () => {
  const snapshot = useSnapshotState();
  const exams = useExams();
  const startIndex = snapshot.currentPage * DISPLAY_PER_PAGE;
  const remainDataLength = exams.length - startIndex;
  if (snapshot.currentPage === 0) {
    /**
     * examIds order is descending order and the data has to be ascending order hence reverse
     */
    return exams.slice(0, DISPLAY_PER_PAGE).reverse();
  }
  if (remainDataLength < DISPLAY_PER_PAGE) {
    const start = startIndex - (DISPLAY_PER_PAGE - remainDataLength);
    /**
     * examIds order is descending order and the data has to be ascending order hence reverse
     */
    return exams.slice(start).reverse();
  }
  const endIndex = startIndex + DISPLAY_PER_PAGE;
  /**
   * examIds order is descending order and the data has to be ascending order hence reverse
   */
  return exams.slice(startIndex, endIndex).reverse();
};

/**
 * next means new data (currentPage: latest 0 <--> n older)
 */
export const useHasNextPage = () => {
  const snapshot = useSnapshotState();
  return snapshot.currentPage > 0;
};

/**
 * previous means older data (currentPage: latest 0 <--> n older)
 */
export const useHasPreviousPage = () => {
  const snapshot = useSnapshotState();
  const exams = useExams();
  return snapshot.hasNext || exams.length >= snapshot.currentPage * DISPLAY_PER_PAGE + DISPLAY_PER_PAGE;
};

export const useCurrentPage = () => {
  const snapshot = useSnapshotState();
  return snapshot.currentPage;
};

export const useOffset = () => {
  const snapshot = useSnapshotState();
  return snapshot.offset;
};

export const useWillFetch = () => {
  const snapshot = useSnapshotState();
  /** currentPage start with 0 and need to check next page hence 1+1 */
  return snapshot.hasNext && snapshot.examIds.length < (snapshot.currentPage + 2) * DISPLAY_PER_PAGE;
};
