import LinearProgress, { LinearProgressProps } from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type TitleWithScoreBarProps = {
  title: string;
  /**
   * 0 to 100
   */
  score?: number;
  color: LinearProgressProps["color"];
  mb?: number;
};

const TitleWithScoreBar: React.FC<TitleWithScoreBarProps> = props => {
  return (
    <Stack direction="row" spacing={2} alignItems="center" mb={props.mb}>
      <Typography variant="subtitle1" fontSize={16} fontWeight="bold" color="textSecondary">
        {props.title}
      </Typography>
      {props.score !== undefined && (
        <>
          <LinearProgress
            sx={{ height: "10px", width: "80px" }}
            variant="determinate"
            value={props.score}
            role="progressbar"
            color={props.color}
          />
          <Typography variant="body2" color="textSecondary">
            {`${props.score}%`}
          </Typography>
        </>
      )}
    </Stack>
  );
};

TitleWithScoreBar.displayName = "TitleWithScoreBar";

export default TitleWithScoreBar;
