import { SupportLanguageValue, useTranslation } from "@hireroo/i18n";
import { Add } from "@mui/icons-material";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import * as React from "react";
import { useFieldArray } from "react-hook-form";

import AcceptButton, { AcceptButtonProps } from "../../../../../../primitive/Button/AcceptButton";
import { useAlgorithmTestCasesFormContext } from "../../../../Context";
import { generateDefaultAlgorithmCorrectnessTC, generateDefaultDatabaseCorrectnessTC } from "../../../../generateDefaultTestCase";
import TestCaseInput from "../TestCaseInput/TestCaseInput";

export type TestCaseInputTabContextProps = {
  lang: SupportLanguageValue;
};

const TestCaseInputTabContext: React.FC<TestCaseInputTabContextProps> = props => {
  const { t } = useTranslation();

  const { method, contextProps } = useAlgorithmTestCasesFormContext();
  const correctnessTestCases = useFieldArray({
    control: method.control,
    name: "correctnessTestCases.data",
  });

  const addCorrectnessTestCase = () => {
    contextProps.signatureProps.variant === "ALGORITHM" &&
      correctnessTestCases.append(generateDefaultAlgorithmCorrectnessTC(contextProps.signatureProps.signature));
    contextProps.signatureProps.variant === "DATABASE" &&
      correctnessTestCases.append(generateDefaultDatabaseCorrectnessTC(contextProps.signatureProps.signature));
  };

  const deleteCorrectnessTestCase = (index: number) => () => {
    correctnessTestCases.remove(index);
  };

  const addTestCaseButton: AcceptButtonProps = {
    children: t("テストケースを追加する").toString(),
    size: "small",
    startIcon: <Add fontSize="small" />,
    variant: "text",
    onClick: addCorrectnessTestCase,
  };
  return (
    <TabContext value={props.lang}>
      <Box my={2} display="flex" justifyContent="end">
        <AcceptButton {...addTestCaseButton} />
      </Box>

      <TabPanel key={props.lang} value={props.lang} sx={{ padding: 0 }}>
        {correctnessTestCases.fields.map((filed, index) => (
          <TestCaseInput
            key={filed.id}
            index={index}
            language={props.lang}
            onDelete={deleteCorrectnessTestCase(index)}
            length={correctnessTestCases.fields.length}
          />
        ))}
      </TabPanel>
    </TabContext>
  );
};

TestCaseInputTabContext.displayName = "TestCaseInputTabContext";

export default TestCaseInputTabContext;
