import { state } from "./State";
import type * as Types from "./types";

export const setStatus = (status: Types.ApplicationStatus): void => {
  state.status = status;
};

export const setEnableTalentScore = (enable: boolean) => {
  state.enableTalentScore = enable;
};
