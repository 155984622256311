import * as z from "zod";

export const useScreeningTestListItem = () => {
  return z.object({
    itemId: z.string(),
    selected: z.boolean(),
  });
};

export type ScreeningTestListItem = z.infer<ReturnType<typeof useScreeningTestListItem>>;

export const useScreeningTestListForm = () => {
  const screeningTestListItem = useScreeningTestListItem();
  return z.object({
    items: screeningTestListItem.array(),
  });
};

export type ScreeningTestListForm = z.infer<ReturnType<typeof useScreeningTestListForm>>;
