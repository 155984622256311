import { state } from "./State";
import type * as Types from "./types";

export type InitializeBelongsCompaniesArgs = {
  activeCompanyId: number;
  companies: Types.Company[];
};

export const initializeBelongsCompanies = (args: InitializeBelongsCompaniesArgs) => {
  state.companies = args.companies;
  /**
   * Initial value is equivalent to value of activeCompanyId.
   */
  state.previousActiveCompanyId = args.activeCompanyId;
  state.activeCompanyId = args.activeCompanyId;
};

export const updateActiveCompanyId = (activeCompanyId: number): void => {
  state.previousActiveCompanyId = state.activeCompanyId;
  state.activeCompanyId = activeCompanyId;
};

export const updateErrorStatus = (errorStatus: Types.ErrorStatus) => {
  state.errorStatus = errorStatus;
};

export const clear = () => {
  state.previousActiveCompanyId = null;
  state.activeCompanyId = null;
  state.companies = [];
  state.errorStatus = null;
};
