import { useTranslation } from "@hireroo/i18n";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

type Status = "RECOMMENDED" | "NOT_RECOMMENDED";
export type AdmissionDecisionIconProps = {
  status: Status;
};

const AdmissionDecisionIcon: React.FC<AdmissionDecisionIconProps> = ({ status }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const StatusMap: Record<Status, React.ReactNode> = {
    RECOMMENDED: (
      <Box display={"flex"} alignContent={"center"}>
        <ThumbUpIcon sx={{ color: theme.palette.success.main }} />
        <Typography component="span" color={theme.palette.success.main} ml={1}>
          {t("合格")}
        </Typography>
      </Box>
    ),
    NOT_RECOMMENDED: (
      <Box display={"flex"} alignContent={"center"}>
        <ThumbDownIcon sx={{ color: theme.palette.error.main }} />
        <Typography component="span" color={theme.palette.error.main} ml={1}>
          {t("不合格")}
        </Typography>
      </Box>
    ),
  };

  return StatusMap[status];
};

AdmissionDecisionIcon.displayName = "AdmissionDecisionIcon";

export default AdmissionDecisionIcon;
