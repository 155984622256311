import { getEndOfMonthDateUnixTimeSeconds, getStartOfMonthDateUnixTimeSeconds } from "./privateHelper";
import type * as Types from "./types";

export const DEFAULT_FETCH_SIZE = 20;

export const DEFAULT_PAGE = 0;

export const DEFAULT_PURCHASE_PERIOD: Types.State["purchasePeriod"] = {
  startSeconds: getStartOfMonthDateUnixTimeSeconds(),
  endSeconds: getEndOfMonthDateUnixTimeSeconds(),
};
