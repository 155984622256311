import { useTranslation } from "@hireroo/i18n";
import * as z from "zod";

export const useCorrectnessTestCase = () => {
  const { t } = useTranslation();
  // Same as question.signature data structure
  return z.object({
    title_ja: z.string(),
    description_ja: z.string(),
    title_en: z.string(),
    description_en: z.string(),
    outputs: z.array(z.string().min(1, { message: t("返り値は必須項目です。") })),
    inputs: z.array(z.string().min(1, { message: t("引数は必須項目です。") })),
    is_hidden: z.boolean().optional(),
  });
};

export const CorrectnessTestCaseFromJson = z.object({
  title_ja: z.string(),
  description_ja: z.string(),
  title_en: z.string(),
  description_en: z.string(),
  outputs: z.array(z.string()),
  inputs: z.array(z.string()),
  is_hidden: z.boolean().optional(),
});

// This is for testcase about correctness testcase from JSON
export const useCorrectnessTestCasesFromJsonDangerous = () => {
  const correctness = useCorrectnessTestCase();
  return z.array(correctness);
};

export const CorrectnessTestCaseResult = z.object({
  performance: z.number(),
  max_memory: z.number(),
  expected: z.string(),
  output: z.string(),
  is_passed: z.boolean(),
  is_built: z.boolean(),
  title_ja: z.string(),
  title_en: z.string(),
  description_ja: z.string(),
  description_en: z.string(),
});

export const CorrectnessTestCaseResults = CorrectnessTestCaseResult.array();

export const usePerformanceTestCase = () => {
  const { t } = useTranslation();
  return z.object({
    label: z.string().min(1, { message: t("ラベルは必須項目です。") }),
    outputs: z.array(z.string().min(1, { message: t("返り値は必須項目です。") })),
    inputs: z.array(z.string().min(1, { message: t("引数は必須項目です。") })),
    is_hidden: z.boolean().optional(),
  });
};

// This is for testcase about correctness testcase from JSON
export const usePerformanceTestCasesFromJsonDangerous = () => {
  const performance = usePerformanceTestCase();
  return z.array(performance);
};

export const PerformanceStats = z.object({
  max_memory_avg: z.number(),
  max_memory_std: z.number(),
  performance_avg: z.number(),
  performance_std: z.number(),
});

export const PerformanceTestCaseResult = z.object({
  submission_stats: PerformanceStats,
  optimal_stats: PerformanceStats,
  sub_optimal_stats: PerformanceStats.optional(),
  brute_force_stats: PerformanceStats.optional(),
  label: z.string(),
  is_passed: z.boolean(),
  is_built: z.boolean(),
});

export const PerformanceTestCaseResults = PerformanceTestCaseResult.array();
export type PerformanceTestCaseResultsSchema = z.infer<typeof PerformanceTestCaseResults>;

const AlgorithmPerformanceStat = z.object({
  min: z.number(),
  max: z.number(),
  med: z.number(),
  p25: z.number(),
  p75: z.number(),
  avg: z.number(),
  std: z.number(),
  data: z.array(z.number()),
});

const AlgorithmMaxMemoryStat = z.object({
  min: z.number(),
  max: z.number(),
  med: z.number(),
  p25: z.number(),
  p75: z.number(),
  avg: z.number(),
  std: z.number(),
  data: z.array(z.number()),
});

const AlgorithmPerformanceResult = z.object({
  label: z.string(),
  is_passed: z.boolean(),
  is_correct: z.boolean(),
  performance: AlgorithmPerformanceStat,
  max_memory: AlgorithmMaxMemoryStat,
});

export const AlgorithmPerformanceTestCaseResult = z.object({
  version: z.string(),
  result: z.array(AlgorithmPerformanceResult),
  machine_os: z.string(),
  machine_cpu: z.number(),
  machine_memory: z.string(),
});

const DatabasePerformanceStat = z.object({
  min: z.number(),
  max: z.number(),
  med: z.number(),
  p25: z.number(),
  p75: z.number(),
  avg: z.number(),
  std: z.number(),
  data: z.array(z.number()),
});

const DatabaseMaxMemoryStat = z.object({
  min: z.number(),
  max: z.number(),
  med: z.number(),
  p25: z.number(),
  p75: z.number(),
  avg: z.number(),
  std: z.number(),
  data: z.array(z.number()),
});

const DatabasePerformanceResult = z.object({
  label: z.string(),
  is_passed: z.boolean(),
  is_correct: z.boolean(),
  performance: DatabasePerformanceStat,
  max_memory: DatabaseMaxMemoryStat,
});

export const DatabasePerformanceTestCaseResult = z.object({
  version: z.string(),
  result: z.array(DatabasePerformanceResult),
  machine_os: z.string(),
  machine_cpu: z.number(),
  machine_memory: z.string(),
});

export const PerformanceTestCaseResultV2 = z.union([AlgorithmPerformanceTestCaseResult, DatabasePerformanceTestCaseResult]);

export type PerformanceTestCaseResultV2Schema = z.infer<typeof PerformanceTestCaseResultV2>;
