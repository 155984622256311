import { proxyMap } from "valtio/utils";

import { state } from "./State";
import type * as Types from "./types";

export const initialize = (
  entity: Types.ChallengeEntity,
  options: { enableWebSearch: boolean; enableChatGPT: boolean; enableHint: boolean },
) => {
  const entityState: Types.ChallengeEntityState = {
    testCasesResults: proxyMap(),
    question: entity.challengeQuestion,
    appealMessage: entity?.appealMessage ?? "",
    entity: entity,
    outputStatus: proxyMap(),
    usedHintIds: [],
    webSession: null,
    chatGPTSession: null,
    leavePageAction: null,
  };
  state.challenges.set(entity.challengeEntityId, entityState);
  state.enableWebSearch = options.enableWebSearch;
  state.enableChatGPT = options.enableChatGPT;
  state.enableHint = options.enableHint;
};

export const setSubmittedEntity = (entity: Types.ChallengeEntity) => {
  state.submittedEntity = entity;
};

export const clearSubmittedEntity = () => {
  state.submittedEntity = null;
};

export const updateLoadingStatus = (newValue: Types.LoadingStatus) => {
  state.loadingStatus = newValue;
};

export const createChallengeEntityAction = (entityId: number) => {
  const challenge = state.challenges.get(entityId);
  const setTestcaseResult = (testCaseIndex: Types.TestCaseIndex, testCaseResult: Types.TestCaseResult) => {
    challenge?.testCasesResults.set(testCaseIndex, testCaseResult);
    if (challenge) {
      challenge.testCasesResults.forEach((value, key) => {
        challenge.outputStatus.set(key, value.isAccepted ? "COMPLETED" : "REJECTED");
      });
    }
  };
  const clearTestcaseResult = () => {
    challenge?.testCasesResults.clear();
    challenge?.outputStatus.clear();
  };

  const setLoadingOutputStatus = (testCaseIndex: Types.TestCaseIndex) => {
    challenge?.outputStatus.set(testCaseIndex, "LOADING");
  };

  const setRejectedOutputStatus = (testCaseIndex: Types.TestCaseIndex) => {
    challenge?.outputStatus.set(testCaseIndex, "REJECTED");
  };

  const updateQuestion = (question: Types.Question) => {
    if (challenge?.question) {
      challenge.question = question;
    }
  };
  const updateAppealMessage = (newValue: string) => {
    if (challenge) {
      challenge.appealMessage = newValue;
    }
  };

  const initializeUsedHintIds = (hintIds: number[]) => {
    if (challenge?.usedHintIds) {
      challenge.usedHintIds = hintIds;
    }
  };

  const appendUsedHintId = (hintId: number) => {
    if (challenge?.usedHintIds) {
      challenge.usedHintIds = [...challenge.usedHintIds, hintId];
    }
  };

  const appendUserChatMessage = (prompt: string) => {
    if (challenge?.chatGPTSession) {
      challenge.chatGPTSession.currentChatGPTMessages = [
        ...challenge.chatGPTSession.currentChatGPTMessages,
        {
          role: "USER",
          content: prompt,
        },
      ];
    }
  };

  const setChatGPTResponse = (res: Types.ChatGPTResponse) => {
    // When user change runtime while waiting ChatGPT response,
    // session id of response and one of state are different.
    if (res.sessionId === challenge?.chatGPTSession?.sessionId) {
      challenge.chatGPTSession.currentChatId = res.chatId;
      challenge.chatGPTSession.currentChatGPTMessages = res.messages;
    }
  };

  const resetChatMessages = () => {
    if (challenge?.chatGPTSession) {
      challenge.chatGPTSession.currentChatGPTMessages = [];
      challenge.chatGPTSession.currentChatId = null;
    }
  };

  const updateLatestWebSearchedLog = (event: Types.WebSearchedEvent) => {
    // When user change runtime while waiting Google search response,
    // session id of response and one of state are different.
    if (event.sessionId === challenge?.webSession?.sessionId) {
      challenge.webSession.latestWebSearchedLog = {
        searchEventId: event.eventId,
        query: event.query,
        page: event.page,
      };
    }
  };

  const setLeavePageAction = (leavePageAction: Types.LeavePageAction) => {
    if (challenge) {
      challenge.leavePageAction = leavePageAction;
    }
  };

  const clearLeavePageAction = () => {
    if (challenge) {
      challenge.leavePageAction = null;
    }
  };

  return {
    setTestcaseResult,
    clearTestcaseResult,
    setLoadingOutputStatus,
    setRejectedOutputStatus,
    updateQuestion,
    updateAppealMessage,
    initializeUsedHintIds,
    appendUsedHintId,
    appendUserChatMessage,
    setChatGPTResponse,
    resetChatMessages,
    updateLatestWebSearchedLog,
    setLeavePageAction,
    clearLeavePageAction,
  };
};

export const setIpAddress = (ipAddress: string) => {
  state.ipAddress = ipAddress;
};

export const setGeolocation = (geolocation: string) => {
  state.geolocation = geolocation;
};

export const updateChatGPTLoadingStatus = (status: Types.ChatGPTLoadingStatus) => {
  state.chatGPTLoadingStatus = status;
};

// updateChallengeSessions is separated from createChallengeEntityAction to prevent useEffect is called in duplicate
export const updateChallengeSessions = (entityId: number, res: Types.StartChallengeSessionsResponse) => {
  const challenge = state.challenges.get(entityId);
  if (!challenge) {
    return;
  }

  challenge.webSession = res.webSession;
  challenge.chatGPTSession = res.chatGPTSession;
};

export const updateWebSearchParameters = (query: string, page: number | null) => {
  state.webSearchParameters = {
    query: query,
    page: page,
  };
};

export const resetWebSearchParameters = () => {
  state.webSearchParameters = {
    query: null,
    page: null,
  };
};
