import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type RemotesEvaluateContainerProps = {
  remoteId: string;
};

const RemotesEvaluateContainer: React.FC<RemotesEvaluateContainerProps> = props => {
  const remotesEvaluateProps = useGenerateProps({ remoteId: props.remoteId });
  return (
    <ErrorBoundary>
      <Pages.RemoteEvaluation {...remotesEvaluateProps} />
    </ErrorBoundary>
  );
};

RemotesEvaluateContainer.displayName = "RemotesEvaluateContainer";

export default withErrorBoundary(RemotesEvaluateContainer, {});
