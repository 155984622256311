import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import FormControlLabel, { FormControlLabelProps } from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useController } from "react-hook-form";

/**
 * The value of the Option that MultiChoice has must be a unique `id`. Other fields are allowed to be extended to be unique.
 *
 * NOTE:
 * It is possible to tightly constrain the type definition using TypeScript's Generics,
 * but since this requires a deep understanding of TypeScript,
 * we only specify `& { [key: string]: unknown }` here to explicitly share the extension.
 */
type FieldValue = { id: string } & { [key: string]: unknown };

type MultipleChoiceOption = {
  value: FieldValue;
  displayName: string;
  iconSrc?: string;
};

export type MultiChoiceV2FieldProps = {
  name: string;
  className?: string;
  options: MultipleChoiceOption[];
  onChange?: (selectedValues: FieldValue[]) => void;
  disabled?: boolean;
  /**
   * @default true
   *
   * If this flag is turned off, the State managed by the react-hook-form is not updated,
   * so the user of this component must update the state of the react-hook-form using the value obtained by `onChange`.
   */
  watch?: boolean;
};

/**
 * Unlike `MultiChoice`, `MultiChoiceV2Field` can have values other than strings as values. Instead, each option must have a unique `id`.
 */
const MultiChoiceV2Field: React.FC<MultiChoiceV2FieldProps> = props => {
  const { onChange, options, watch = true } = props;
  const { field } = useController<Record<string, FieldValue[]>>({
    name: props.name,
  });
  const [selectedValue, setSelectedValue] = React.useState<FieldValue[]>(field.value);
  const { onChange: onChangeField } = field;

  /**
   * The following warning is prevented by performing a state change that may belong to the parent component after the setState of this component is complete.
   *
   * Warning: Cannot update a component while rendering a different component
   */
  React.useEffect(() => {
    if (watch) {
      onChangeField(selectedValue);
    }
    onChange?.(selectedValue);
  }, [onChangeField, onChange, watch, selectedValue]);

  return (
    <FormGroup className={props.className}>
      {options.map(option => {
        const Icon = typeof option.iconSrc === "string" ? <Avatar sx={{ width: 20, height: 20, mr: 1 }} src={option.iconSrc} /> : null;
        const checked = ((): boolean => {
          if (watch) {
            return field.value.findIndex(value => value.id === option.value.id) !== -1;
          }
          return selectedValue.findIndex(value => value.id === option.value.id) !== -1;
        })();
        const handleChange: Exclude<CheckboxProps["onChange"], undefined> = (_, innerChecked) => {
          setSelectedValue(prev => {
            return innerChecked ? prev.concat(option.value) : prev.filter(prevValue => prevValue.id !== option.value.id);
          });
        };
        const formControlLabelProps: FormControlLabelProps = {
          control: <Checkbox onChange={handleChange} checked={checked} color="secondary" />,
          label: (
            <Box component="span" display="flex">
              {Icon}
              <Typography variant="body2">{option.displayName}</Typography>
            </Box>
          ),
          value: option.value,
        };
        return <FormControlLabel key={option.value.id} {...formControlLabelProps} />;
      })}
    </FormGroup>
  );
};

MultiChoiceV2Field.displayName = "MultiChoiceField";

export default MultiChoiceV2Field;
