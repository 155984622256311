import { useTranslation } from "@hireroo/i18n";
import { Fields } from "@hireroo/validator";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useController } from "react-hook-form";

import SelectableButton, { SelectableButtonProps } from "./parts/SelectableButton/SelectableButton";

type FieldValue = Fields.SelectableQuestionField.SelectableQuestion;

const Wrapper = styled("fieldset")(() => ({
  border: "none",
  padding: 0,
}));

const SelectableHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: 16,
  fontWeight: 700,
}));

const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 12,
}));

const StyledSelectableWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  maxWidth: "100%",
  columnGap: "8px",
  rowGap: "12px",
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
}));

export type SelectableQuestionProps = {
  title: string;
  name: `selectableQuestions.${number}`;
  items: Omit<SelectableButtonProps, "index" | "name">[];
};

const SelectableQuestion: React.FC<SelectableQuestionProps> = props => {
  const { t } = useTranslation();
  const { fieldState } = useController<Record<string, FieldValue>>({
    name: props.name,
  });
  return (
    <Wrapper name={props.name}>
      <SelectableHeading mb={1.5}>{props.title}</SelectableHeading>
      <Description mb={1.5} px={3}>
        {t("※ 一度選択した問題は変更できません。")}
      </Description>
      <StyledSelectableWrapper>
        {props.items.map((item, index) => {
          const selectableButtonProps: SelectableButtonProps = {
            ...item,
            name: props.name,
            index,
          };
          return <SelectableButton key={`Selectable-${index}`} {...selectableButtonProps} />;
        })}
      </StyledSelectableWrapper>
      {fieldState.error?.message && (
        <Typography color="error" variant="caption" noWrap px={3}>
          {fieldState.error.message}
        </Typography>
      )}
    </Wrapper>
  );
};

SelectableQuestion.displayName = "SelectableQuestion";

export default SelectableQuestion;
