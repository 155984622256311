import mixpanel, { Config } from "mixpanel-browser";

export type MixpanelInitializeParams = {
  token: string;
  config?: Partial<Config>;
};

export const initialize = (params: MixpanelInitializeParams) => {
  mixpanel.init(params.token, { track_pageview: false, persistence: "localStorage", ...params.config });
};
