import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import * as React from "react";

import HomeSectionLayout, { HomeSectionLayoutProps } from "../../modules/HomeSectionLayout/HomeSectionLayout";
import MoreButton, { MoreButtonProps } from "../../primitive/Button/MoreButton/MoreButton";
import ReducedTimeMessage, { ReducedTimeMessageProps } from "./parts/ReducedTimeMessage/ReducedTimeMessage";
import StatsCardItem, { StatsCardItemProps } from "./parts/StatsCardItem/StatsCardItem";
import TestSelector, { TestSelectorProps } from "./parts/TestSelector/TestSelector";

export type DashboardScreeningStatsSectionProps = {
  hideButton: Pick<MoreButtonProps["options"][0], "onClick">;
  testSelector: TestSelectorProps;
  passedLine: Pick<StatsCardItemProps, "content">;
  testCreated: Pick<StatsCardItemProps, "content">;
  reducedTimeMessage: ReducedTimeMessageProps;
};

const DashboardScreeningStatsSection: React.FC<DashboardScreeningStatsSectionProps> = props => {
  const { t } = useTranslation();
  const moreButton: MoreButtonProps = {
    variant: "vertical",
    options: [
      {
        ...props.hideButton,
        value: "hide",
        displayName: t("非表示"),
      },
    ],
  };
  const layout: HomeSectionLayoutProps = {
    header: {
      title: t("ハイヤールーの利用状況"),
      RightSideComponent: <MoreButton {...moreButton} />,
    },
    spacing: 0,
  };
  const passedLine: StatsCardItemProps = {
    ...props.passedLine,
    title: t("合格ライン"),
    icon: "CHECK",
  };
  const testCreated: StatsCardItemProps = {
    ...props.testCreated,
    title: t("テスト発行数"),
    icon: "REPORT",
  };
  return (
    <HomeSectionLayout {...layout}>
      <Box width="50%" mb={2}>
        <TestSelector {...props.testSelector} />
      </Box>
      <Stack spacing={2}>
        <Box display="grid" gap={2} gridTemplateColumns="repeat(3, 1fr)">
          {/*TODO: implement score distribution*/}
          <StatsCardItem key="passed-line-content" {...passedLine} />
          <StatsCardItem key="test-created-content" {...testCreated} />
          {/*TODO: remove box once  score distribution is implemented*/}
          <Box />
        </Box>
        <ReducedTimeMessage {...props.reducedTimeMessage} />
      </Stack>
    </HomeSectionLayout>
  );
};

DashboardScreeningStatsSection.displayName = "DashboardScreeningStatsSection";

export default DashboardScreeningStatsSection;
