import type * as Graphql from "@hireroo/graphql/client/urql";
import { SupportLanguageValue } from "@hireroo/i18n";
import { useCurrentLanguage } from "@hireroo/i18n";

type UrlItem = {
  downloadUrl: string;
  iframeUrl: string;
};

type UrlItemForGeneration = Record<Graphql.PaymentV2Plan["generation"], UrlItem>;

type UrlMap = Record<SupportLanguageValue, UrlItemForGeneration>;

const jaUrlMap: UrlItemForGeneration = {
  2: {
    iframeUrl:
      "https://docs.google.com/document/d/e/2PACX-1vS7wmkaCtnuDQ40p4f_ZvjDU2SUryMq_38rPoFpEO-BEa4wxt0qApYI3XZ-AvaoLg/pub?embedded=true",
    downloadUrl:
      "https://docs.google.com/document/d/1Z_RX1FUVQs0hnpGV-GGXRpEXuyBYp0Xk/edit?usp=sharing&ouid=105769604789820985656&rtpof=true&sd=true",
  },
  3: {
    iframeUrl:
      "https://docs.google.com/document/d/e/2PACX-1vSglYPbBQ6cOw7CmzXcK6bTq0f5tPGpbmkKalUIHh41Y6S4HiUNT_ByeNd3RRBjmA/pub?embedded=true",
    downloadUrl:
      "https://docs.google.com/document/d/1YUEOr-Lf-HX_VZ5u1u0ZmlvmCyIg_SW1/edit?usp=sharing&ouid=105769604789820985656&rtpof=true&sd=true",
  },
  4: {
    iframeUrl:
      "https://docs.google.com/document/d/e/2PACX-1vSpYu9OpSApeIF0bOylfAoYrY4V90GnRckbI66-ok2xHh1mjvUaGyFT0KaHH5gJ3w/pub?embedded=true",
    downloadUrl:
      "https://docs.google.com/document/d/1_nbgh2EZozRpKuYnfmapMy_aHBDXyAdD/edit?usp=sharing&ouid=105769604789820985656&rtpof=true&sd=true",
  },
  5: {
    iframeUrl:
      "https://docs.google.com/document/d/e/2PACX-1vRW9sPxiqVhw4me-cfZy65w8O-m9gJKuZTYeFe9rt25Dr25R_0UgoDbjC8g8POZKQ/pub?embedded=true",
    downloadUrl:
      "https://docs.google.com/document/d/1XnJw97uCemtsfTr6Kwn6oQ82ypmxztkd/edit?usp=sharing&ouid=105769604789820985656&rtpof=true&sd=true",
  },
  6: {
    iframeUrl:
      "https://docs.google.com/document/d/e/2PACX-1vSd6geQB_FgDc6V8tM2EZ3tglICU_S_SaYVKXW3Q-q6BHEIqVUMNq9hRUYcZ_2_Gg/pub?embedded=true",
    downloadUrl:
      "https://docs.google.com/document/d/1v0daOlNDOwdXp8NctHGaGxkxJURfDPE_/edit?usp=sharing&ouid=105769604789820985656&rtpof=true&sd=true",
  },
  7: {
    iframeUrl:
      "https://docs.google.com/document/d/e/2PACX-1vQc5xMPHlv8_emazYH4dEO2ieeMCxe2QWBRMzZwbjy6BiIoIm-DUIHWc8jQRqZeQw/pub?embedded=true",
    downloadUrl:
      "https://docs.google.com/document/d/1FFQJO2i0c8LUWwmgAmXvpR1l9CZSlaiS/edit?usp=sharing&ouid=105769604789820985656&rtpof=true&sd=true",
  },
  8: {
    iframeUrl:
      "https://docs.google.com/document/d/e/2PACX-1vTFzdLtmF-_h7CWwpA4Eov6AyeQC7C-bfhaRJBwIN7EWvlDEUm4ELGYPjpefxohbw/pub?embedded=true",
    downloadUrl:
      "https://docs.google.com/document/d/1qrUtRzOlds7oi1A2gwtX8k8gKVPmltW-/edit?usp=sharing&ouid=105769604789820985656&rtpof=true&sd=true",
  },
  9: {
    iframeUrl: "https://docs.google.com/document/d/e/2PACX-1vSy0_W2MIwc0sQAWWg_muWU4cYfgwYnBszpt-Y3K9bwK5XDTNQtQCIC1W_Fpgu9_g/pub",
    downloadUrl:
      "https://docs.google.com/document/d/1sR_hpWheI-QIHpo4HuEKGvB7QUZLxG6O/edit?usp=sharing&ouid=105769604789820985656&rtpof=true&sd=true",
  },
};

export const UrlMap: UrlMap = {
  ja: jaUrlMap,
  en: jaUrlMap,
};

export const useUrlItem = (generation: Graphql.PaymentV2Plan["generation"]): UrlItem | undefined => {
  const lang = useCurrentLanguage() as SupportLanguageValue;
  if (generation in UrlMap[lang]) {
    return UrlMap[lang][generation];
  }
  return undefined;
};
