import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import MemoEditor, { MemoEditorProps } from "../../modules/MemoEditor/MemoEditor";
import QuestionCard, { QuestionCardProps } from "../../ms-components/Question/QuestionCard/QuestionCard";
import AssessmentSummary, { AssessmentSummaryProps } from "./parts/AssessmentSummary/AssessmentSummary";

const StyledBox = styled(Box)(({ theme }) => ({
  borderRadius: "16px",
  backgroundColor: theme.palette["Background/Paper"].p2,
  padding: theme.spacing(2),
}));

export type AssessmentOverviewProps = {
  summary: AssessmentSummaryProps;
  memoEditor?: MemoEditorProps;
  questions?: QuestionCardProps[];
};

const AssessmentOverview: React.FC<AssessmentOverviewProps> = props => {
  const { t } = useTranslation();
  return (
    <Box p={3}>
      {props.memoEditor && (
        <Box mb={5}>
          <MemoEditor {...props.memoEditor} />
        </Box>
      )}
      <Box mb={4}>
        <AssessmentSummary {...props.summary} />
      </Box>
      {props.questions && (
        <Box>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }} mb={2}>
            {t("出題する問題")}
          </Typography>

          <StyledBox>
            {props.questions.length === 0 && (
              <Typography color="text.secondary" variant="body2" sx={{ textAlign: "center" }}>
                {t("出題可能な問題が登録されていません")}
              </Typography>
            )}
            {props.questions.length > 0 && (
              <Grid container spacing={3}>
                {props.questions.map(question => (
                  <Grid key={question.id} item xs={3} md={3}>
                    <QuestionCard {...question} />
                  </Grid>
                ))}
              </Grid>
            )}
          </StyledBox>
        </Box>
      )}
    </Box>
  );
};

AssessmentOverview.displayName = "AssessmentOverview";

export default AssessmentOverview;
