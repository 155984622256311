import ButtonBase from "@mui/material/ButtonBase";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow, { TableRowProps } from "@mui/material/TableRow";
import * as React from "react";

import Link, { LinkProps } from "../../../../primitive/Link/Link";
import FieldItem, { FieldItemProps } from "../FieldItem/FieldItem";
import Meta, { MetaProps } from "../Meta/Meta";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderTop: "1px solid",
  borderBottom: "1px solid",
  borderColor: theme.palette.Other.Divider,
  display: "table-row",
  "&.Mui-selected": {
    backgroundColor: theme.palette["Secondary/Shades"].p30,
    ":hover": {
      backgroundColor: theme.palette["Secondary/Shades"].p16,
    },
  },
  "&:hover": {
    backgroundColor: theme.palette["Gray/Shades"].p8,
  },
}));

const CustomTableRow: React.FC<LinkProps> = props => {
  // FIXME I would like to ask the mui team how to define this type definition accurately.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <ButtonBase as={Link} {...props} disableRipple />;
};

const StyledTableCell = styled(TableCell)(() => ({
  border: "none",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  display: "table-cell",
  padding: "16px 24px",
}));

export type QuestionTableRowProps = {
  id: string;
  href: string;
  onClick?: TableRowProps["onClick"];
  meta: MetaProps;
  items: FieldItemProps[];
  disabled?: boolean;
};

const QuestionTableRow: React.FC<QuestionTableRowProps> = props => {
  return (
    <StyledTableRow
      as={!props.disabled ? CustomTableRow : undefined}
      // FIXME I would like to ask the mui team how to define this type definition accurately.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      component={"a"}
      href={props.href}
      onClick={event => {
        event.stopPropagation();
        event.preventDefault();
        props.onClick?.(event);
      }}
      color="secondary"
    >
      <StyledTableCell component="div">
        <Stack direction="row" spacing={7} justifyContent="space-between" width="100%">
          <Meta {...props.meta} width="70%" />
          <Stack direction="row" spacing={2} alignItems="center" divider={<Divider orientation="vertical" />} height="56px">
            {props.items.map(item => (
              <FieldItem key={item.label} {...item} />
            ))}
          </Stack>
        </Stack>
      </StyledTableCell>
    </StyledTableRow>
  );
};

QuestionTableRow.displayName = "QuestionTableRow";

export default QuestionTableRow;
