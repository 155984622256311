import { proxy } from "valtio";

import type * as Types from "./types";

export const state = proxy<Types.State>({
  refresh: () => undefined,
  interview: null,
  selectedGroupIds: [],
  selectedEmployeeIds: [],
  defaultSelectedGroupIds: [],
  defaultSelectedEmployeeIds: [],
  tags: [],
});
