import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";

export const useExamInterval = (): Record<Graphql.AssessmentExamInterval, string> => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();

  return {
    [Graphql.AssessmentExamInterval.Unknown]: t("不明"),
    [Graphql.AssessmentExamInterval.OneWeek]: t2("PerWeek", { week: 1 }),
    [Graphql.AssessmentExamInterval.TwoWeeks]: t2("PerWeek", { week: 2 }),
    [Graphql.AssessmentExamInterval.OneMonth]: t2("PerMonth", { month: 1 }),
    [Graphql.AssessmentExamInterval.TwoMonths]: t2("PerMonth", { month: 2 }),
    [Graphql.AssessmentExamInterval.ThreeMonths]: t2("PerMonth", { month: 3 }),
  };
};
