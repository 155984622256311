import "./types";

import type { Theme } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { ThemeProvider as MUIThemeProvider } from "@mui/material/styles";
import * as React from "react";

import * as ColorTheme from "./color";
import { ColorMode, ColorModeProvider, useColorModeContext } from "./ColorModeContext";

export { type ColorMode, ColorTheme, useColorModeContext };

/**
 * scrollbar view become dark theme.
 */
const DarkThemeGlobalCss = <GlobalStyles styles={{ html: { colorScheme: "dark" } }} />;
const LightThemeGlobalCss = <GlobalStyles styles={{ html: { colorScheme: "light" } }} />;

export type ThemeProviderProps = {
  defaultColorMode?: ColorMode;
  children?: React.ReactNode;
};

const InnerThemeProvider = (props: ThemeProviderProps) => {
  const { colorMode } = useColorModeContext();
  const currentTheme: Record<ColorMode, Theme> = {
    LIGHT: ColorTheme.Light,
    DARK: ColorTheme.Dark,
  };
  const GlobalCssMap: Record<ColorMode, React.ReactNode> = {
    DARK: DarkThemeGlobalCss,
    LIGHT: LightThemeGlobalCss,
  };
  return (
    <MUIThemeProvider theme={currentTheme[colorMode]}>
      {GlobalCssMap[colorMode]}
      <CssBaseline />
      {props.children}
    </MUIThemeProvider>
  );
};

export const ThemeProvider = (props: ThemeProviderProps) => {
  return (
    <ColorModeProvider defaultColorMode={props.defaultColorMode}>
      <StyledEngineProvider injectFirst>
        <InnerThemeProvider {...props} />
      </StyledEngineProvider>
    </ColorModeProvider>
  );
};
