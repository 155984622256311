import { Completer, CompleterModel, CompletionHandler, KernelCompleterProvider, ProviderReconciliator } from "@jupyterlab/completer";
import { NotebookPanel } from "@jupyterlab/notebook";
import { CommandRegistry } from "@lumino/commands";
import { CommandPalette } from "@lumino/widgets";

class NotebookPanelController {
  #completer: Completer | undefined;
  #commandPalette: CommandPalette | undefined;
  #completionHandler: CompletionHandler | undefined;

  constructor(
    public readonly notebookPanel: NotebookPanel,
    private readonly commandRegistry: CommandRegistry,
  ) {
    this.notebookPanel.toolbar.hide();
    this.initialize();
  }

  public get completionHandler() {
    if (!this.#completionHandler) {
      throw new Error("completionHandler not initialized");
    }
    return this.#completionHandler;
  }

  public get completer() {
    if (!this.#completer) {
      throw new Error("Please initialize completer");
    }
    return this.#completer;
  }

  public get commandPalette() {
    if (!this.#commandPalette) {
      throw new Error("Please initialize commandPalette");
    }
    return this.#commandPalette;
  }

  private initialize = () => {
    this.#commandPalette = new CommandPalette({ commands: this.commandRegistry });
    this.#commandPalette.addClass("notebookCommandPalette");

    const editor = this.notebookPanel.content?.activeCell?.editor;
    const completeModel = new CompleterModel();
    this.#completer = new Completer({ editor, model: completeModel });
    const sessionContext = this.notebookPanel?.context?.sessionContext;
    const timeout = 3000;
    const provider = new KernelCompleterProvider();
    const reconciliator = new ProviderReconciliator({
      context: {
        widget: this.notebookPanel,
        editor,
        session: sessionContext?.session,
      },
      providers: [provider],
      timeout: timeout,
    });
    this.#completionHandler = new CompletionHandler({ completer: this.#completer, reconciliator });
    void sessionContext?.ready?.then(() => {
      const provider = new KernelCompleterProvider();
      const reconciliator = new ProviderReconciliator({
        context: {
          widget: this.notebookPanel,
          editor,
          session: sessionContext.session,
        },
        providers: [provider],
        timeout: timeout,
      });

      this.completionHandler.reconciliator = reconciliator;
    });

    // Set the handler's editor.
    this.#completionHandler.editor = editor;

    // Listen for active cell changes.
    this.notebookPanel?.content?.activeCellChanged?.connect((_sender, cell) => {
      this.completionHandler.editor = cell && cell.editor;
    });

    // Hide the widget when it first loads.
    this.#completer.hide();
  };
}

export { NotebookPanelController };
