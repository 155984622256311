import { useTranslation } from "@hireroo/i18n";
import ChromeReaderModeOutlinedIcon from "@mui/icons-material/ChromeReaderModeOutlined";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";
import CopyToClipboard from "react-copy-to-clipboard";

import SymbolButton, { SymbolButtonProps } from "../../primitive/Button/SymbolButton/SymbolButton";

type Mode = "DEFAULT" | "WITHOUT_DETAIL";

export type TestReportSectionHeaderProps = {
  title: string;
  linkButton: Pick<SymbolButtonProps, "onClick"> & { url: string };
  detailButton: Pick<SymbolButtonProps, "onClick">;
  /**
   * display mode default: DEFAULT
   */
  mode?: Mode;
};

const TestReportSectionHeader: React.FC<TestReportSectionHeaderProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const mode = props.mode ?? "DEFAULT";
  const detailButton: SymbolButtonProps = {
    ...props.detailButton,
    title: t("問題詳細を確認する"),
  };
  const { url, ...restLink } = props.linkButton;
  const linkButton: SymbolButtonProps = {
    ...restLink,
  };
  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" gap={4}>
      <Box alignItems="center">
        <Typography variant="h6" component="h2" sx={{ fontWeight: "bold" }}>
          {props.title}
        </Typography>
      </Box>
      <Stack direction="row" spacing={1}>
        <CopyToClipboard text={url}>
          <SymbolButton {...linkButton} variant="OUTLINE">
            <InsertLinkOutlinedIcon htmlColor={theme.palette.text.primary} />
          </SymbolButton>
        </CopyToClipboard>
        {mode === "DEFAULT" && (
          <SymbolButton {...detailButton} variant="OUTLINE">
            <ChromeReaderModeOutlinedIcon htmlColor={theme.palette.text.primary} />
          </SymbolButton>
        )}
      </Stack>
    </Box>
  );
};

TestReportSectionHeader.displayName = "TestReportSectionHeader";

export default TestReportSectionHeader;
