import { withErrorBoundary } from "@sentry/react";
import React from "react";

import QuestionSearchAndSelectableAreaContainer, { QuestionSortableTwoColumnListContainerProps } from "./Container";

export type QuestionSearchAndSelectableAreaFetchContainerProps = QuestionSortableTwoColumnListContainerProps;

/**
 * Fetch for QuestionSelectFieldForResourceEditor should be done via Subscriber.
 */
const QuestionSearchAndSelectableAreaFetchContainer: React.FC<QuestionSearchAndSelectableAreaFetchContainerProps> = props => {
  return <QuestionSearchAndSelectableAreaContainer {...props} />;
};

QuestionSearchAndSelectableAreaFetchContainer.displayName = "QuestionSearchAndSelectableAreaFetchContainer";

export default withErrorBoundary(QuestionSearchAndSelectableAreaFetchContainer, {});
