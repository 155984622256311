export default {
  light: {
    primary: {
      main: "#272822",
      dark: "#1b1c17",
      light: "#52534e",
      contrastText: "#F8F8F2",
    },
    secondary: {
      main: "#F92672",
      contrastText: "#272822",
    },
    text: {
      primary: "#272822",
      secondary: "#272822",
    },
  },
  dark: {
    primary: {
      main: "#EF9A9A",
      dark: "#A76B6B",
      light: "#F2AEAE",
    },
    secondary: {
      main: "#9AEFEF",
      dark: "#6BA7A7",
      light: "#AEF2F2",
    },
    text: {
      primary: "#F8F8F2",
      secondary: "#272822",
    },
    common: {
      background: {
        main: "#303030",
      },
    },
    background: {
      default: "#303030",
      paper: "#424242",
    },
  },
};
