import * as uuid from "uuid";

export type FileNode = {
  /**
   * ファイルもしくはディレクトリパス
   * @example src/app/index.tsx
   */
  id: string;
  /**
   * ファイル名かディレクトリ名
   * @example index.tsx
   */
  name: string;
  /**
   * ファイルの場合、ファイルコンテンツの値
   * @example Hello World!
   */
  value: string;
  /** ディレクトリかどうか */
  isDir: boolean;
  /** Readonlyかどうか */
  isReadOnly?: boolean;
  /**
   * ディレクトリの場合存在する
   * keyはFileNodeのidを持つ
   * @example { "app": { "id": "src/app", ... } }
   */
  children: {
    [key: string]: FileNode;
  } | null;
  /**
   * RootのNodeかどうか
   * memo: root以外は不要？
   */
  isRoot: boolean;
  /**
   * for old version
   */
  isRead?: boolean;
  /**
   * Loading中かどうか
   * memo: root以外は不要？
   */
  isLoading?: boolean;
  /**
   * memo: root以外は不要？
   */
  cwd?: boolean;
};

/**
 * targetFileNode直下のchildrenを対象に`searchTargetPath`で絞り込んで1つだけ取得する
 * searchTargetPathに"."が与えられた場合はtargetFileNodeそのものを返す
 *
 * @param targetFileNode 探索対象のnode
 * @param searchTargetPath
 * @returns
 */
export const findNode = (targetFileNode: FileNode, searchTargetPath: string): FileNode | null => {
  if (searchTargetPath === ".") return targetFileNode;
  let current: FileNode | null = targetFileNode;
  searchTargetPath.split("/").forEach(splitPath => {
    if (current?.children && splitPath in current.children) {
      current = current.children[splitPath];
    } else {
      current = null;
    }
  });
  return current;
};

export const updateNodeValue = (targetFileNode: FileNode, searchTargetPath: string, value: string) => {
  if (searchTargetPath === ".") return targetFileNode;
  let current: FileNode | null = targetFileNode;
  const parts: string[] = searchTargetPath.split("/");
  for (const part of parts) {
    if (current?.children && part in current.children) {
      current = current.children[part];
    } else {
      current = null;
    }
  }
  if (current) {
    current.value = value;
  }
  return targetFileNode;
};

export const findParent = (root: FileNode, path: string): FileNode | null => {
  let parent: FileNode | null = null;
  let current: FileNode | null = root;

  path.split("/").forEach(x => {
    if (current?.children && x in current.children) {
      parent = current;
      current = current.children[x];
    }
  });

  return parent;
};

export const listChildPaths = (parent: FileNode): string[] => {
  const res: string[] = [];

  res.push(parent.id);
  if (parent.children) {
    Object.values(parent.children).forEach(child => {
      res.push(child.id);
      res.concat(listChildPaths(child));
    });
  }

  return res;
};

export const listAllFilePaths = (parent: FileNode, filePathSet = new Set<string>()): string[] => {
  if (!parent.children && !parent.isDir) {
    // = not directory
    filePathSet.add(parent.id);
  }
  if (parent.children) {
    for (const child of Object.values(parent.children)) {
      if (!child.isDir) {
        filePathSet.add(child.id);
      }
      listAllFilePaths(child, filePathSet);
    }
  }
  return [...filePathSet];
};

/**
 * 引数のrootに対してchildrenを追加していく関数
 *
 * !!CAUTION!! rootのの中身が勝手に変わるので注意
 */
export const createNode = (root: FileNode, path: string, isDir: boolean, isReadOnly: boolean, data?: string): void => {
  let current = root;
  const separated = path.split("/");
  separated.forEach((x, i) => {
    if (!current.children) {
      current.children = {};
    }

    if (!(x in current.children)) {
      // If current path is not the last one i.e. not c in a/b/c, then a and b must be a directory
      // id will be a in the first loop, a/b in the second loop
      if (i + 1 < separated.length) {
        current.children[x] = {
          id: separated.slice(0, i + 1).join("/"),
          name: x,
          isDir: true,
          isReadOnly: false,
          value: "",
          children: {},
          isRoot: false,
        };
      } else {
        current.children[x] = {
          id: path,
          name: x,
          isDir: isDir,
          isReadOnly: isReadOnly,
          value: data || "",
          children: isDir ? {} : null,
          isRoot: false,
        };
      }
    }

    current = current.children[x];
  });
};

export const createNodeByFileIndexes = (rootDirName: string, fileIndexes: string[], readOnlyFiles: string[]): FileNode => {
  const root: FileNode = {
    id: ".",
    name: rootDirName,
    isDir: true,
    isReadOnly: false,
    value: "",
    children: {},
    isRoot: true,
  };

  const matchReadOnlyPatterns = (fileIndex: string) => {
    return readOnlyFiles.some(readOnlyFile => readOnlyFile === fileIndex);
  };

  fileIndexes.forEach((fileIndex: string) => {
    const isReadOnly = matchReadOnlyPatterns(fileIndex);
    const isDir = !!fileIndex.match(/\/$/);
    const trimmedFileIndex = fileIndex.replace(/\/$/, "");
    createNode(root, trimmedFileIndex, isDir, isReadOnly);
  });
  return root;
};

// This function needs to be called where root can be updated in-place i.e. inside immer function
export const removeNode = (root: FileNode, path: string): void => {
  const parent = findParent(root, path);
  const split = path.split("/");
  const key = split[split.length - 1];

  if (parent && parent.children && key in parent.children) {
    delete parent.children[key];
    if (Object.keys(parent.children).length === 0) {
      parent.children = null;
    }
  }
};

export const generateHashString = (n: number): string => {
  return uuid.v4().replace(/-/g, "").slice(0, n);
};
