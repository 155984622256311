import { useTranslation } from "@hireroo/i18n";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { useAlgorithmTestCasesFormContext } from "../../../../../Context";
import AccordionWithLoading, { AccordionWithLoadingProps } from "../../../AccordionWithLoading/AccordionWithLoading";
import PerformanceTestCaseDialog, { PerformanceTestCaseDialogProps } from "../PerformanceTestCaseDialog/PerformanceTestCaseDialog";
import ReadonlyAlgorithmFields from "../ReadonlyAlgorithmFields/ReadonlyAlgorithmFields";

const StyledTypography = styled(Typography)(() => ({
  fontSize: "14px",
}));

export type ReadonlyTestCaseProps = {
  accordion: Omit<AccordionWithLoadingProps, "Content" | "editButton">;
  data: {
    label: string;
    inputs: string[];
    outputs: string[];
    isHidden: boolean;
  };
  correctnessDialog: Omit<PerformanceTestCaseDialogProps, "dialog" | "kind">;
};

const ReadonlyTestCase: React.FC<ReadonlyTestCaseProps> = props => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState<boolean>(false);
  const { contextProps } = useAlgorithmTestCasesFormContext();

  const correctnessDialog: PerformanceTestCaseDialogProps = {
    ...props.correctnessDialog,
    kind: "EDIT",
    dialog: {
      open: open,
      noButton: {
        onClick: () => {
          setOpen(false);
        },
      },
    },
  };
  const accordion: AccordionWithLoadingProps = {
    ...props.accordion,
    editButton: {
      onClick: () => {
        setOpen(true);
      },
    },
    Content: (
      <Stack direction="column" width="100%" spacing={2}>
        {props.data.isHidden && (
          <Stack direction="row">
            <StyledTypography>{t("テスト中は表示しない")}</StyledTypography>
          </Stack>
        )}
        <Stack>
          {contextProps.signatureProps.variant === "ALGORITHM" && (
            <ReadonlyAlgorithmFields
              signature={contextProps.signatureProps.signature}
              inputs={props.data.inputs}
              outputs={props.data.outputs}
            />
          )}
        </Stack>
      </Stack>
    ),
  };
  return (
    <>
      <AccordionWithLoading {...accordion} />
      <PerformanceTestCaseDialog {...correctnessDialog} />
    </>
  );
};

ReadonlyTestCase.displayName = "ReadonlyTestCase";

export default ReadonlyTestCase;
