import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { TermOfService } from "@hireroo/app-helper/payment";
import { Def } from "@hireroo/app-helper/payment";
import { PaymentContractCreateForm } from "@hireroo/app-store/widget/e/PaymentContractCreateForm";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/browser";
import * as React from "react";

import { usePayNextMonthConfirmSectionProps } from "../privateHelper";

export type GeneratePaymentConfirmationDetailsPropsArgs = {
  onAccept: () => void;
};

export const useGenerateProps = (_args: GeneratePaymentConfirmationDetailsPropsArgs): Widget.PaymentConfirmationDetailsProps => {
  const { t } = useTranslation();
  const customer = PaymentContractCreateForm.useCustomer();
  const urlItem = TermOfService.useUrlItem(customer.subscription.plan.generation);
  const client = getGraphqlClient();
  const plans = PaymentContractCreateForm.usePlans();
  const submitValue = PaymentContractCreateForm.useSubmitValue();
  const [loading, setLoading] = React.useState(false);
  const confirmSection = usePayNextMonthConfirmSectionProps();
  const interviewsMap = PaymentContractCreateForm.useBuyAbleSelectionItemMap();
  const appliedCoupons = PaymentContractCreateForm.useAppliedCoupons();
  const stripJsPaymentMethodResult = PaymentContractCreateForm.useStripJsPaymentMethodResult();

  return {
    payNextMonthConfirmSection: confirmSection,
    tosIframeUrl: urlItem?.iframeUrl,
    downloadUrl: urlItem?.downloadUrl,
    submitButton: {
      loading,
      onClick: async () => {
        const targetPlan = plans.find(plan => plan.planType === Def.planTypeMapValidatorToGraphql[submitValue.plan]);
        if (!targetPlan) {
          Snackbar.notify({
            severity: "warning",
            /**
             * TODO Change translation
             */
            message: "変更先のプランが見つかりませんでした。お問い合わせください。",
          });
          return;
        }
        setLoading(true);
        client
          .ContractPlanForPaymentContractCreateForm({
            input: {
              customerId: customer.subscription.customerId,
              country: customer.country || Graphql.Country.Jp,
              paymentMethod: stripJsPaymentMethodResult?.paymentMethod?.id || customer.paymentMethod,
              email: submitValue.billingInformation.mailAddress,
              postalCode: submitValue.billingInformation.postalCode,
              state: submitValue.billingInformation.state,
              city: submitValue.billingInformation.city,
              line1: submitValue.billingInformation.line1,
              line2: "",
              language: submitValue.billingInformation.language,
              lastFour: stripJsPaymentMethodResult?.paymentMethod?.card?.last4 || customer.lastFour,
              paymentType: submitValue.paymentMethod === "CREDIT_CARD" ? "CARD" : "INVOICE",
              planId: targetPlan.planId,
              couponIds: appliedCoupons.map(appliedCoupon => appliedCoupon.couponId),
              periodType: submitValue.paymentPeriod === "YEARLY" ? "YEAR" : "MONTH",
              purchasePackages: submitValue.selectionItems.reduce<Graphql.PurchasePackageInput[]>((all, selectionItem) => {
                if (selectionItem.amount === 0) {
                  return all;
                }
                const interview = interviewsMap[selectionItem.itemId];
                return all.concat({ packageId: interview.interviewId, quantity: selectionItem.amount });
              }, []),
            },
          })
          .then(() => {
            Snackbar.notify({
              severity: "success",
              message: t("正式契約に成功しました。"),
            });
            PaymentContractCreateForm.updateShowingTarget("END");
          })
          .catch(error => {
            Sentry.captureException(error);
            const errorNotification = ErrorHandlingHelper.generateErrorNotification(
              error,
              [t("処理に失敗しました。"), t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。")].join(""),
            );
            Snackbar.notify({
              severity: "error",
              message: errorNotification.message,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      },
    },
  };
};
