import { useTranslation } from "@hireroo/i18n";
import * as z from "zod";

import * as AssignField from "./fields/AssignField";

export const useMemberSearchForm = () => {
  return z.object({
    textFilter: z.string(),
  });
};

export type MemberSearchFormSchema = z.infer<ReturnType<typeof useMemberSearchForm>>;

export const useGroupSearchForm = () => {
  const { t } = useTranslation();
  const group = AssignField.useAssignListItem();

  return z.object({
    groups: group.array().min(1, { message: t("グループを選択してください。") }),
  });
};

export type GroupSearchFormSchema = z.infer<ReturnType<typeof useGroupSearchForm>>;
