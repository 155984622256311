import * as Graphql from "@hireroo/graphql/client/urql";
import { useSnapshot } from "valtio";

import * as Def from "../../shared/payment/definition";
import { state } from "./State";
import type * as Types from "./types";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = () => {
  const snapshot = useSnapshotState();
  return snapshot.initialized;
};

export const useCustomerStatus = (): Types.CustomerStatus => {
  const snapshot = useSnapshotState();
  if (!snapshot.customerV2) {
    return "BEFORE_FETCH";
  }
  return "VALID";
};

/**
 * @example
 *
 * ```ts
 * const isAvailableFeature = useGetAvailableFeature();
 *
 * isAvailableFeature("test.report.create");
 * ```
 *
 * @returns boolean
 */
export const useIsAvailableFeature = () => {
  const paymentItemType = usePaymentItemType();
  const isAvailableFeature = (feature: Def.AvailableFeatureName): boolean => {
    const target = Def.FeaturePlanMap[feature] as Def.PaymentItemType[];
    return target.includes(paymentItemType);
  };
  return isAvailableFeature;
};

const usePaymentItemType = (): Graphql.PaymentItemType => {
  const snapshot = useSnapshotState();
  if (!snapshot.customerV2) {
    return Graphql.PaymentItemType.Unknown;
  }
  const paymentV2ItemType: Def.PaymentV2ItemType = `${snapshot.customerV2.subscription.plan.planType}.${snapshot.customerV2.subscription.plan.generation}`;
  return Def.paymentV2ItemTypeToPaymentItemType[paymentV2ItemType] || Graphql.PaymentItemType.Unknown;
};
