import { useTranslation } from "@hireroo/i18n";
import DownloadIcon from "@mui/icons-material/Download";
import Box, { BoxProps } from "@mui/material/Box";
import IconButton, { type IconButtonProps } from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,
  borderRadius: 12,
  overflow: "hidden",
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(2),
}));

export type PlanSelectionTipsProps = {
  downloadButton: Pick<IconButtonProps, "onClick">;
} & Pick<BoxProps, "mb">;

const PlanSelectionTips: React.FC<PlanSelectionTipsProps> = props => {
  const { t } = useTranslation();
  const iconButtonProps: IconButtonProps = {
    ...props.downloadButton,
    children: <DownloadIcon />,
    size: "small",
  };
  return (
    <Wrapper mb={props.mb}>
      <Box>
        <Typography fontWeight="bold" variant="body2" noWrap mb={1.5}>
          {t("プラン選びに困ったら")}
        </Typography>
        <Typography variant="body2">{t("より詳しいプラン内容をPDFでダウンロードすることができます。")}</Typography>
      </Box>
      <Box>
        <IconButton {...iconButtonProps} />
      </Box>
    </Wrapper>
  );
};

PlanSelectionTips.displayName = "PlanSelectionTips";

export default PlanSelectionTips;
