import { useDevices, useMediaStreamTrack, useTwilioVideoRoomContext } from "@hireroo/app-helper/hooks";
import { DEFAULT_VIDEO_CONSTRAINTS, SelectedAudioDeviceId, SelectedVideoDeviceId } from "@hireroo/app-helper/media-device";
import type { Widget } from "@hireroo/presentation";
import React from "react";

export type GenerateLiveCodingDeviceSettingsPropsArgs = {};

export const useGenerateProps = (_args: GenerateLiveCodingDeviceSettingsPropsArgs): Widget.LiveCodingDeviceSettingsProps => {
  const { videoInputDevices, audioInputDevices } = useDevices();
  const { localVideoTrack, localAudioTrack, isNoiseCancellationEnabled, toggleNoiseCancellation } = useTwilioVideoRoomContext();

  const videoMediaStreamTrack = useMediaStreamTrack(localVideoTrack);
  const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = React.useState(SelectedVideoDeviceId.get());
  const localVideoInputDeviceId = videoMediaStreamTrack?.getSettings().deviceId || storedLocalVideoDeviceId;

  const audioMediaStreamTrack = useMediaStreamTrack(localAudioTrack);
  const srcMediaStreamTrack = localAudioTrack?.noiseCancellation?.sourceTrack;
  const [storedLocalAudioDeviceId, setStoredLocalAudioDeviceId] = React.useState(SelectedAudioDeviceId.get());
  const localAudioInputDeviceId =
    srcMediaStreamTrack?.getSettings().deviceId || audioMediaStreamTrack?.getSettings().deviceId || storedLocalAudioDeviceId;

  return {
    videoInputSelector: {
      selectedDeviceId: localVideoInputDeviceId ?? "",
      devices: videoInputDevices,
      onChange: (newDeviceId: string) => {
        setStoredLocalVideoDeviceId(newDeviceId);
        SelectedVideoDeviceId.save(newDeviceId);
        localVideoTrack?.restart({
          ...DEFAULT_VIDEO_CONSTRAINTS,
          deviceId: { exact: newDeviceId },
        });
      },
    },
    audioInputSelector: {
      selectedDeviceId: localAudioInputDeviceId ?? "",
      devices: audioInputDevices,
      onChange: (newDeviceId: string) => {
        setStoredLocalAudioDeviceId(newDeviceId);
        SelectedAudioDeviceId.save(newDeviceId);
        localAudioTrack?.restart({ deviceId: { exact: newDeviceId } });
      },
    },
    noiseCancellationSelector: localAudioTrack?.noiseCancellation && {
      checked: isNoiseCancellationEnabled,
      onChange: () => {
        toggleNoiseCancellation();
      },
    },
  };
};
