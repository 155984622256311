import { ChallengeCodingEditor, ChallengeCodingEditorProps } from "@hireroo/challenge/react/widget";
import * as React from "react";

export type RemoteInterviewChallengeCodingEditorProps = ChallengeCodingEditorProps;

/**
 * Do not specify a width of 100% because the style will be broken.
 */
const RemoteInterviewChallengeCodingEditor: React.FC<RemoteInterviewChallengeCodingEditorProps> = props => {
  return <ChallengeCodingEditor height="100%" {...props} />;
};

RemoteInterviewChallengeCodingEditor.displayName = "RemoteInterviewChallengeCodingEditor";

export default RemoteInterviewChallengeCodingEditor;
