import { useTranslation } from "@hireroo/i18n";
import Markdown from "@hireroo/markdown-v2/react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { styled } from "@mui/material/styles";
import * as React from "react";

const StyledFormControlLabel = styled(FormControlLabel)({
  "& > span:nth-of-type(2)": {
    width: "100%",
  },
  marginTop: "10px",
});

export type MultiChoiceItem = {
  id: number;
  title: string;
  description?: string;
  checked: boolean;
};

export type QuizQuestionMultiChoiceProps = {
  description: string;
  items: MultiChoiceItem[];
  skipped: boolean;
  onSelect: (optionId: string) => void;
  onUnselect: (optionId: string) => void;
};

const QuizQuestionMultiChoiceProps: React.FC<QuizQuestionMultiChoiceProps> = props => {
  const { t } = useTranslation();
  const onChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const value = e.target.value;
    if (checked) {
      props.onUnselect(value);
    } else {
      props.onSelect(value);
    }
  };

  return (
    <Box>
      <React.Suspense
        fallback={
          <Box width="100%" display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        }
      >
        <Box mb={2}>
          <Markdown size="large" children={props.description} />
        </Box>
      </React.Suspense>

      <FormGroup aria-label="options">
        {props.items.map((option, index) => (
          <Box key={`options-${index}-${option.title}`}>
            <StyledFormControlLabel
              value={String(option.id)}
              // Radio form passed "string" so value is "string"
              control={<Checkbox color="primary" checked={option.checked} onChange={e => onChangeCheckbox(e, option.checked)} />}
              label={
                <React.Suspense>
                  <Markdown size="middle" children={option.title} />
                </React.Suspense>
              }
            />
          </Box>
        ))}

        <FormControlLabel
          value="0"
          control={<Checkbox color="primary" checked={props.skipped} onChange={e => onChangeCheckbox(e, props.skipped)} />}
          label={t("この問題をスキップ")}
          sx={{ mt: "10px" }}
        />
      </FormGroup>
    </Box>
  );
};

QuizQuestionMultiChoiceProps.displayName = "QuizQuestionMultiChoiceProps";

export default QuizQuestionMultiChoiceProps;
