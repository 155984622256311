import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type SignUpMailAddressContainerProps = {};

const SignUpMailAddressContainer: React.FC<SignUpMailAddressContainerProps> = () => {
  const signInMailAddressProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.SignInMailAddress {...signInMailAddressProps} />
    </ErrorBoundary>
  );
};

SignUpMailAddressContainer.displayName = "SignUpMailAddressContainer";

export default withErrorBoundary(SignUpMailAddressContainer, {});
