import { TranslationWithVariableAction, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import * as z from "zod";

import * as EntryScreeningField from "./fields/EntryScreeningField";
import * as SelectableQuestionField from "./fields/SelectableQuestionField";

export { EntryScreeningField };

const generateDynamicStringValueFieldSchema = (t2Action: TranslationWithVariableAction, defaultValues: EntryScreeningField.StringValueType) => {
  const { t: t2 } = t2Action;
  const valueField = ((): z.ZodString => {
    let value: z.ZodString = z.string();
    if (defaultValues.validationField.required) {
      value = value.min(1, { message: t2("ValidateRequired", { name: defaultValues.label }) });
    }
    if (typeof defaultValues.validationField.min === "number") {
      value = value.min(defaultValues.validationField.min, {
        message: t2("ValidateMinTextSizeMessage", { name: defaultValues.label, size: defaultValues.validationField.min }),
      });
    }
    if (typeof defaultValues.validationField.max === "number") {
      value = value.max(defaultValues.validationField.max, {
        message: t2("ValidateMaxTextSizeMessage", { name: defaultValues.label, size: defaultValues.validationField.max }),
      });
    }
    return value;
  })();
  return z.object({
    type: z.literal("STRING"),
    label: z.string(),
    variableId: z.number(),
    value: valueField,
  });
};

export type DynamicStringValueFieldSchema = z.infer<ReturnType<typeof generateDynamicStringValueFieldSchema>>;

const generateDynamicFloatValueFieldSchema = (t2Action: TranslationWithVariableAction, defaultValues: EntryScreeningField.FloatValueType) => {
  const { t: t2 } = t2Action;
  const valueField = ((): z.ZodNumber => {
    let value: z.ZodNumber = z.number();
    if (typeof defaultValues.validationField.min === "number") {
      value = value.min(defaultValues.validationField.min, {
        message: t2("ValidateMinSizeMessage", { name: defaultValues.label, size: defaultValues.validationField.min }),
      });
    }
    if (typeof defaultValues.validationField.max === "number") {
      value = value.max(defaultValues.validationField.max, {
        message: t2("ValidateMaxSizeMessage", { name: defaultValues.label, size: defaultValues.validationField.max }),
      });
    }
    return value;
  })();
  return z.object({
    type: z.literal("FLOAT"),
    label: z.string(),
    variableId: z.number(),
    value: z
      .preprocess(v => (v ? Number(v) : undefined), valueField.optional())
      //Validate only when validationField.required is true
      .refine(v => (defaultValues.validationField.required && v !== undefined) || !defaultValues.validationField.required, {
        message: t2("ValidateRequired", { name: defaultValues.label }),
      }),
  });
};

export type DynamicFloatValueFieldSchema = z.infer<ReturnType<typeof generateDynamicFloatValueFieldSchema>>;

const generateDynamicIntegerValueFieldSchema = (
  t2Action: TranslationWithVariableAction,
  defaultValues: EntryScreeningField.IntegerValueType,
) => {
  const { t: t2 } = t2Action;
  const valueField = ((): z.ZodNumber => {
    let value: z.ZodNumber = z.number();
    if (defaultValues.validationField.required) {
      value = value.int({ message: t2("ValidateRequired", { label: defaultValues.label }) });
    }
    if (typeof defaultValues.validationField.min === "number") {
      value = value.min(defaultValues.validationField.min, {
        message: t2("ValidateMinSizeMessage", { name: defaultValues.label, size: defaultValues.validationField.min }),
      });
    }
    if (typeof defaultValues.validationField.max === "number") {
      value = value.max(defaultValues.validationField.max, {
        message: t2("ValidateMaxSizeMessage", { name: defaultValues.label, size: defaultValues.validationField.max }),
      });
    }
    return value;
  })();
  return z.object({
    type: z.literal("INTEGER"),
    label: z.string(),
    variableId: z.number(),
    value: z
      .preprocess(v => (v ? Number(v) : undefined), valueField.optional())
      //Validate only when validationField.required is true
      .refine(v => (defaultValues.validationField.required && v !== undefined) || !defaultValues.validationField.required, {
        message: t2("ValidateRequired", { name: defaultValues.label }),
      }),
  });
};

export type DynamicIntegerValueFieldSchema = z.infer<ReturnType<typeof generateDynamicIntegerValueFieldSchema>>;
const generateDynamicBooleanValueFieldSchema = () => {
  return z.object({
    type: z.literal("BOOLEAN"),
    label: z.string(),
    variableId: z.number(),
    value: z.boolean(),
  });
};

export type DynamicBooleanValueFieldSchema = z.infer<ReturnType<typeof generateDynamicBooleanValueFieldSchema>>;

export const generateReservedVariableFieldValue = (name: string): `variables.${string}.value` => {
  return `variables.${name}.value`;
};

export type VariablesFormSchemaArgs = {
  variables: EntryScreeningField.VariableMap;
};

export const useDynamicVariablesFormFieldsSchema = (args: VariablesFormSchemaArgs) => {
  const t2Action = useTranslationWithVariable();
  const entries = Object.fromEntries(
    Object.entries(args.variables).map(([label, fieldSchema]) => {
      const zodSchema = (() => {
        const type = fieldSchema.type;
        switch (type) {
          case "STRING": {
            return generateDynamicStringValueFieldSchema(t2Action, fieldSchema);
          }
          case "FLOAT": {
            return generateDynamicFloatValueFieldSchema(t2Action, fieldSchema);
          }
          case "INTEGER": {
            return generateDynamicIntegerValueFieldSchema(t2Action, fieldSchema);
          }
          case "BOOLEAN": {
            return generateDynamicBooleanValueFieldSchema();
          }
          default:
            throw new Error(`type is never ${type satisfies never}`);
        }
      })();
      return [label, zodSchema];
    }),
  );
  return z.object(entries);
};

export type DynamicVariablesFormFieldsSchema = z.infer<ReturnType<typeof useDynamicVariablesFormFieldsSchema>>;

export type EntryScreeningFormSchemaArgs = {
  hasSelectableQuestion: boolean;
  variables: VariablesFormSchemaArgs["variables"];
};

export const useEntryScreeningFormSchema = (args: EntryScreeningFormSchemaArgs) => {
  const { t } = useTranslation();
  const email = z.string().email(t("メールアドレスの形式が間違っています。"));
  const variables = useDynamicVariablesFormFieldsSchema({ variables: args.variables });

  const selectableQuestion = SelectableQuestionField.useSelectableQuestion();

  return z
    .object({
      name: z
        .string()
        .min(1, { message: t("名前は1文字以上で入力してください。") })
        .max(100, { message: t("名前は100文字以内で入力してください。") })
        .regex(/^[^\cA-\cZ]+$/, { message: t("利用できない文字列が含まれています。") }),
      agreement: z.boolean(),
      email: email,
      variables: variables,
      /**
       * Record<TrackId, SelectableQuestion>
       */
      selectableQuestions: z.record(selectableQuestion),
    })
    .superRefine((obj, ctx) => {
      if (args.hasSelectableQuestion) {
        Object.values(obj.selectableQuestions).forEach(selectableQuestion => {
          if (selectableQuestion.index === null) {
            ctx.addIssue({
              code: z.ZodIssueCode.invalid_type,
              expected: z.ZodParsedType.integer,
              received: z.ZodParsedType.null,
              message: t("解答する問題を選択してください"),
              path: ["selectableQuestions", selectableQuestion.trackId],
            });
          }
        });
      }
    });
};

export type EntryScreeningFormSchema = z.infer<ReturnType<typeof useEntryScreeningFormSchema>>;
