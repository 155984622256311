import CodeEditor, { CodeEditorProps } from "@hireroo/code-editor/react/v2/CodeEditor";
import DiffEditor, { DiffEditorProps } from "@hireroo/code-editor/react/v2/DiffEditor";
import { useTranslation } from "@hireroo/i18n";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type Status = "SUCCESS" | "ERROR";

const Heading = styled("span")`
  font-weight: bold;
`;

const Success = styled("span")(({ theme }) => ({
  color: theme.palette.success.light,
}));

const Error = styled("span")(({ theme }) => ({
  color: theme.palette.error.light,
}));

const CaptionLabel = styled(Typography)`
  flex-basis: 48%;
`;

const statusIconMap: Record<Status, React.ReactNode> = {
  SUCCESS: <Success children="✔" />,
  ERROR: <Error children="✘" />,
};

export type TestResultItemProps = {
  index: number;
  status: Status;
  method?: string;
  path?: string;
  query?: string;
  httpStatusCode: string;
  latency: string;
  codeEditor: CodeEditorProps;
  diffEditor: DiffEditorProps;
  ResultDescription?: React.ReactNode;
  alternativeMessage?: string;
};

const TestResultItem: React.FC<TestResultItemProps> = props => {
  const { t } = useTranslation();

  const codeEditorProps: CodeEditorProps = {
    ...props.codeEditor,
    readOnly: true,
    minimap: false,
    lineNumbers: "off",
    style: {
      height: "150px",
    },
  };

  const diffEditorProps: DiffEditorProps = {
    ...props.diffEditor,
    style: {
      width: "100%",
      height: "150px",
    },
  };

  return (
    <Box>
      {props.alternativeMessage ? (
        <Typography variant="caption">{props.alternativeMessage}</Typography>
      ) : (
        <Box mb={2} display="flex" flexDirection="column">
          <Typography variant="caption">
            <Heading>{`${t("レイテンシー")}: `}</Heading>
            {props.latency}
          </Typography>
          <Typography variant="caption">
            <Heading>{`${t("HTTPステータスコード")}: `}</Heading>
            {statusIconMap[props.status]}
            {props.httpStatusCode}
          </Typography>
        </Box>
      )}
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <CaptionLabel variant="caption" mb={2} display="block">
            {t("入力")}
          </CaptionLabel>
          <React.Suspense>
            <CodeEditor key="code-editor" {...codeEditorProps} />
          </React.Suspense>
        </Grid>
        <Grid item xs={8}>
          <Box display="flex" mb={2}>
            <CaptionLabel variant="caption" sx={{ flexBasis: "50%" }}>
              {t("出力")}
            </CaptionLabel>
            <CaptionLabel variant="caption" sx={{ flexBasis: "50%" }}>
              {t("期待値")}
            </CaptionLabel>
          </Box>
          <React.Suspense>
            <DiffEditor key="diff-editor" {...diffEditorProps} />
          </React.Suspense>
        </Grid>
      </Grid>
      {props.ResultDescription}
    </Box>
  );
};

TestResultItem.displayName = "TestResultItem";

export default TestResultItem;
