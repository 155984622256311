import { ProjectCodingEditorV3 } from "@hireroo/app-store/widget/shared/ProjectCodingEditorV3";
import { ScreeningTestTutorial } from "@hireroo/app-store/widget/shared/ScreeningTestTutorial";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import ProjectCodingEditorFetchContainerV3, { ProjectCodingEditorV3FetchContainerProps } from "../ProjectCodingEditorV3/FetchContainer";

export type ProjectCodingEditorInitialContainerV3Props = ProjectCodingEditorV3FetchContainerProps;

const ProjectCodingEditorInitialV3Container: React.FC<ProjectCodingEditorInitialContainerV3Props> = props => {
  const initialized = ProjectCodingEditorV3.useInitialized(props.entityId);

  React.useEffect(() => {
    return () => {
      /**
       * `ScreeningTestTutorial.autoStartTutorial` CALLs after the connection with the Agent Server is finished
       */
      ProjectCodingEditorV3.clear(props.entityId);
      ScreeningTestTutorial.clear();
    };
  }, [props.entityId]);
  if (!initialized) {
    return <Widget.Loading kind="CENTER_%" color="secondary" />;
  }
  const projectCodingEditorFetchContainerProps: ProjectCodingEditorV3FetchContainerProps = props;
  return (
    <ErrorBoundary>
      <ProjectCodingEditorFetchContainerV3 {...projectCodingEditorFetchContainerProps} />
    </ErrorBoundary>
  );
};

ProjectCodingEditorInitialV3Container.displayName = "ProjectCodingEditorInitialContainer";

export default withErrorBoundary(ProjectCodingEditorInitialV3Container, {});
