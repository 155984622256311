import { ScreeningTestTutorial } from "@hireroo/app-store/widget/shared/ScreeningTestTutorial";
import { SystemDesignCodingEditor } from "@hireroo/app-store/widget/shared/SystemDesignCodingEditor";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import SystemDesignCodingEditorFetchContainer, { type SystemDesignCodingEditorFetchContainerProps } from "./FetchContainer";

export type SystemDesignCodingEditorInitialContainerProps = SystemDesignCodingEditorFetchContainerProps;

const SystemDesignCodingEditorInitialContainer: React.FC<SystemDesignCodingEditorInitialContainerProps> = props => {
  const initialized = SystemDesignCodingEditor.useInitialized(props.entityId);
  React.useEffect(() => {
    if (initialized) {
      ScreeningTestTutorial.autoStartTutorial("SYSTEM_DESIGN");
    }
    return () => {
      ScreeningTestTutorial.clear();
    };
  }, [initialized]);
  if (!initialized) {
    return <Widget.Loading kind="CENTER_%" color="secondary" />;
  }
  return (
    <ErrorBoundary>
      <SystemDesignCodingEditorFetchContainer {...props} />
    </ErrorBoundary>
  );
};

SystemDesignCodingEditorInitialContainer.displayName = "SystemDesignCodingEditorInitialContainer";

export default withErrorBoundary(SystemDesignCodingEditorInitialContainer, {});
