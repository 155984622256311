import CloseIcon from "@mui/icons-material/Close";
import Button, { type ButtonProps } from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent, { DialogContentProps } from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { styled, useTheme } from "@mui/material/styles";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

type OptionalDialogProps = Omit<DialogProps, "open">;
export type YesButtonProps = Pick<ButtonProps, "disabled" | "children" | "color" | "variant" | "autoFocus"> & {
  onClick?: () => void;
};
export type NoButtonProps = Pick<ButtonProps, "onClick" | "disabled" | "children" | "color" | "startIcon" | "variant">;

const TooltipContentWrapper = styled("span")`
  margin-left: 8px;
`;

export type DialogWithCloseIconProps = {
  className?: string;
  title?: string;
  open: boolean;
  yesButton?: YesButtonProps;
  noButton?: NoButtonProps;
  LeftActionButton?: React.ReactNode;
  disableEnter?: boolean;
  optionalDialog?: OptionalDialogProps;
  yesButtonTooltip?: Omit<TooltipProps, "children">;
  onClose?: () => void;
  optionalContent?: DialogContentProps;
  DialogActionsProps?: Pick<DialogContentProps, "sx">;
};

const DialogWithCloseIcon: React.FC<React.PropsWithChildren<DialogWithCloseIconProps>> = props => {
  const theme = useTheme();
  const { open, title, children } = props;
  const processing = React.useRef(false);

  React.useEffect(() => {
    return () => {
      if (!open && processing.current) {
        processing.current = false;
      }
    };
  }, [open]);

  const showDialogActions = !!(props.yesButton || props.noButton);

  const yesButtonTooltip: Omit<TooltipProps, "children"> | undefined = props.yesButtonTooltip
    ? {
        ...props.yesButtonTooltip,
      }
    : undefined;

  const yesButtonProps: ButtonProps | undefined = props.yesButton
    ? {
        color: "primary",
        ...props.yesButton,
        role: "yesbutton",
        variant: "contained",
      }
    : undefined;

  const noButtonProps: ButtonProps | undefined = props.noButton
    ? {
        color: "primary",
        ...props.noButton,
        role: "nobutton",
      }
    : undefined;

  return (
    <Dialog
      className={props.className}
      PaperProps={{
        sx: { width: "640px", minWidth: "640px", bgcolor: theme.palette.background.paper },
        elevation: 3,
        ...props.optionalDialog?.PaperProps,
      }}
      PaperComponent={Paper}
      {...props.optionalDialog}
      onClick={event => {
        event?.stopPropagation();
        props.optionalDialog?.onClick?.(event);
      }}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      role="dialog-root"
      onKeyDown={e => {
        // Disable preventDefault on enter key for element that uses
        // enter key as its functionality, such as textarea.
        if (props.disableEnter === true) {
          return;
        }

        if (e.key === "Enter") {
          e.preventDefault();
          if (processing.current) return;
          processing.current = true;
          props.yesButton?.onClick?.();
        }
      }}
    >
      <DialogTitle role="title" sx={{ px: 2, py: 1 }}>
        <Typography variant="subtitle2" component="span">
          {title}
        </Typography>
        <IconButton
          onClick={event => {
            event.stopPropagation();
            props.onClose?.();
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent role="content" sx={{ pt: 1 }} {...props.optionalContent}>
        {children}
      </DialogContent>
      {showDialogActions && (
        <DialogActions sx={{ px: 3, py: 2 }}>
          {props.LeftActionButton}
          {noButtonProps && <Button {...noButtonProps} />}
          {yesButtonProps &&
            (yesButtonTooltip ? (
              <Tooltip {...yesButtonTooltip}>
                <TooltipContentWrapper>
                  <Button {...yesButtonProps} />
                </TooltipContentWrapper>
              </Tooltip>
            ) : (
              <Button {...yesButtonProps} />
            ))}
        </DialogActions>
      )}
    </Dialog>
  );
};

DialogWithCloseIcon.displayName = "DialogWithCloseIcon";

export default DialogWithCloseIcon;
