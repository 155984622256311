import { useTwilioConversationsContext } from "@hireroo/app-helper/hooks";
import type { Pages } from "@hireroo/presentation";
import * as React from "react";

import LiveCodingChatWindowFetchContainer from "./widget/LiveCodingChatWindow/FetchContainer";

export const useGenerateRightSidePanelProps = (): Pages.RemoteInterviewForCandidateProps["rightSidePanel"] => {
  const { hasUnreadMessages } = useTwilioConversationsContext();
  return {
    Chat: <LiveCodingChatWindowFetchContainer />,
    hasChatBadge: hasUnreadMessages,
  };
};
