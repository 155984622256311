import MuiDrawer, { DrawerProps as MuiDrawerProps } from "@mui/material/Drawer";
import { CSSObject, styled } from "@mui/material/styles";
import * as React from "react";

export type SideBarDrawerProps = MuiDrawerProps;

const DRAWER_WIDTH = 248;

const openedMixin = (): CSSObject => ({
  width: DRAWER_WIDTH,
  overflowX: "hidden",
});

const closedMixin = (): CSSObject => ({
  width: "48px",
});

const StyledDrawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== "open" })(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ".MuiDrawer-paper": {
    backgroundColor: theme.palette["Background/Paper"].p2,
    overflow: "unset",
  },
  ...(open && {
    ...openedMixin(),
    "& .MuiDrawer-paper": openedMixin(),
  }),
  ...(!open && {
    ...closedMixin(),
    "& .MuiDrawer-paper": closedMixin(),
  }),
}));

const SideBarDrawer: React.FC<SideBarDrawerProps> = props => {
  return <StyledDrawer {...props} />;
};

SideBarDrawer.displayName = "SideBarDrawer";

export default SideBarDrawer;
