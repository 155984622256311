import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type AssessmentListForTalentContainerProps = {};

const AssessmentListForTalentContainer: React.FC<AssessmentListForTalentContainerProps> = () => {
  const assessmentListForTalentProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Pages.AssessmentListForTalent {...assessmentListForTalentProps} />
    </ErrorBoundary>
  );
};

AssessmentListForTalentContainer.displayName = "AssessmentListForTalentContainer";

export default withErrorBoundary(AssessmentListForTalentContainer, {});
