import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type CandidateFooterContainerProps = {};

const CandidateFooterContainer: React.FC<CandidateFooterContainerProps> = () => {
  const candidateFooterProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.CandidateFooter {...candidateFooterProps} />
    </ErrorBoundary>
  );
};

CandidateFooterContainer.displayName = "CandidateFooterContainer";

export default withErrorBoundary(CandidateFooterContainer, {});
