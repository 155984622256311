import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateSpotTagFieldPropsArgs, useGenerateProps } from "./useGenerateProps";

export type SpotTagFieldContainerProps = GenerateSpotTagFieldPropsArgs;

const SpotTagFieldContainer: React.FC<SpotTagFieldContainerProps> = props => {
  const tagFieldProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.TagField {...tagFieldProps} />
    </ErrorBoundary>
  );
};

SpotTagFieldContainer.displayName = "SpotTagFieldContainer";

export default withErrorBoundary(SpotTagFieldContainer, {});
