import { useTranslation } from "@hireroo/i18n";
import { QuestionPackageSearchForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import PopupSearchFilterField, { PopupSearchFilterFieldProps } from "../../../../../../modules/PopupSearchFilterField/PopupSearchFilterField";
import InputControlTextField, {
  InputControlTextFieldProps,
} from "../../../../../../primitive/InputControl/InputControlTextField/InputControlTextField";
import * as PrivateHelper from "./privateHelper";

const HEIGHT = 32;

const StyledWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  paddingTop: "8px",
  paddingBottom: "8px",
  height: "48px",
  flexShrink: 0,
}));

const SquareWrapper = styled(Box)(() => ({
  width: 32,
  height: HEIGHT,
  flexShrink: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const StyledTextField = styled(InputControlTextField)(({ theme }) => ({
  height: HEIGHT,
  backgroundColor: theme.palette.Other.FilledInputBG,
  "& .MuiInputBase-root": {
    fontSize: 14,
    height: HEIGHT,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiSvgIcon-root": {
    marginRight: "8px",
    height: 24,
    width: 24,
  },
}));

export type QuestionPackageSearchAreaProps = {
  defaultValues: QuestionPackageSearchForm.QuestionPackageSearchQuery;
  onChange: SubmitHandler<QuestionPackageSearchForm.QuestionPackageSearchQuery>;
  onReset?: () => void;
};

const QuestionPackageSearchArea: React.FC<QuestionPackageSearchAreaProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const validateSchema = QuestionPackageSearchForm.useQuestionPackageSearchQuery();
  const methods = useForm<QuestionPackageSearchForm.QuestionPackageSearchQuery>({
    resolver: zodResolver(validateSchema),
    defaultValues: props.defaultValues,
  });
  const difficultyField = PrivateHelper.useDifficultyField();

  const inputControlTextFieldProps: InputControlTextFieldProps = {
    InputProps: {
      startAdornment: <SearchIcon htmlColor={theme.palette.text.secondary} />,
    },
    fullWidth: true,
    variant: "outlined",
    placeholder: t("問題集を検索"),
    onBlur: () => {
      methods.handleSubmit(props.onChange)();
    },
    onKeyDown: event => {
      if (event.key === "Enter") {
        methods.handleSubmit(props.onChange)();
      }
    },
  };

  const popupSearchFilterFieldProps: PopupSearchFilterFieldProps = {
    title: t("フィルター"),
    fields: [difficultyField],
    onSubmit: () => {
      methods.handleSubmit(props.onChange, console.warn)();
    },
  };

  return (
    <FormProvider {...methods}>
      <StyledWrapper>
        <StyledTextField name={PrivateHelper.FieldName.TEXT_FILTER} {...inputControlTextFieldProps} />
        <SquareWrapper>
          <PopupSearchFilterField {...popupSearchFilterFieldProps} />
        </SquareWrapper>
      </StyledWrapper>
    </FormProvider>
  );
};

QuestionPackageSearchArea.displayName = "QuestionPackageSearchArea";

export default QuestionPackageSearchArea;
