import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Pagination, { PaginationProps } from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import * as React from "react";

import { GALLERY_VIEW_PAGE_SIZE } from "./definition";
import { usePagination } from "./privateHelper";

export type LiveCodingGalleryLayoutProps = {
  AudioComponents: React.ReactNode[];
  VideoComponents: React.ReactNode[];
};

const LiveCodingGalleryLayout: React.FC<LiveCodingGalleryLayoutProps> = props => {
  // TODO: Move to container
  const { paginated, currentPage, totalPages, setCurrentPage } = usePagination(props.VideoComponents, GALLERY_VIEW_PAGE_SIZE);
  const paginationProps: PaginationProps = {
    page: currentPage,
    count: totalPages,
    onChange: (_, value) => setCurrentPage(value),
    shape: "rounded",
    color: "secondary",
    size: "small",
    sx: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    renderItem: item => (
      <PaginationItem
        {...item}
        components={{
          first: ArrowForward,
          previous: ArrowBack,
          next: ArrowForward,
          last: ArrowForward,
        }}
        color="secondary"
      />
    ),
  };

  return (
    <Box>
      {props.AudioComponents.map((component, i) => {
        return <React.Fragment key={i}>{component}</React.Fragment>;
      })}
      <Grid container direction={"column-reverse"} display="flex" justifyContent="center" alignItems="center">
        {paginated.map((component, i) => (
          <Grid item key={i} margin={1} justifyContent="center">
            {component}
          </Grid>
        ))}
      </Grid>
      {/* TODO Check Design Review */}
      {totalPages > 1 && <Pagination {...paginationProps} />}
    </Box>
  );
};

LiveCodingGalleryLayout.displayName = "LiveCodingGalleryLayout";

export default LiveCodingGalleryLayout;
