import { useTranslation } from "@hireroo/i18n";
import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import CustomErrorMessagePanelContainer from "../../../../widget/v2/c/CustomErrorMessagePanel/Container";
import * as PrivateHelper from "./privateHelper";
import { useGenerateProps } from "./useGenerateProps";

export type DemoStartContainerProps = {};

const DemoStartContainer: React.FC<DemoStartContainerProps> = () => {
  const { t } = useTranslation();
  const demoParamsByQueryParams = PrivateHelper.useDemoParamsByQueryParams();
  const screeningTestProps = useGenerateProps({
    demoParamsByQueryParams,
  });

  if (!demoParamsByQueryParams) {
    return <CustomErrorMessagePanelContainer title={t("無効なURLです")} message={t("テスト（デモ）が見つかりませんでした。")} />;
  }

  return (
    <ErrorBoundary>
      <Pages.ScreeningTest {...screeningTestProps} />
    </ErrorBoundary>
  );
};

DemoStartContainer.displayName = "DemoStartContainer";

export default withErrorBoundary(DemoStartContainer, {});
