import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DifficultyStars, { DifficultyStarsProps } from "../../../modules/DifficultyStars/DifficultyStars";

export type QuestionPackageShortDetailProps = {
  difficultyStars: DifficultyStarsProps;
  questionCount: number;
};

const QuestionPackageShortDetail: React.FC<QuestionPackageShortDetailProps> = props => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" spacing={2} divider={<Divider />}>
      <Stack spacing={0.5} height="56px" pr={2}>
        <Typography color="textSecondary" fontSize={12}>
          {t("難易度")}
        </Typography>
        <Box py={1.5}>
          <DifficultyStars {...props.difficultyStars} />
        </Box>
      </Stack>
      <Stack spacing={0.5} height="56px" pr={2}>
        <Typography color="textSecondary" fontSize={12}>
          {t("問題数")}
        </Typography>
        <Stack direction="row" spacing={0.5} alignItems="flex-end">
          <Typography fontWeight={700} fontSize={24}>
            {props.questionCount}
          </Typography>
          <Typography color="textSecondary" fontSize={14}>
            {t("問")}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

QuestionPackageShortDetail.displayName = "QuestionPackageShortDetail";

export default QuestionPackageShortDetail;
