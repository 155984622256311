import { useTranslation } from "@hireroo/i18n";
import { useMethods, withSplitProvider } from "@hireroo/react-split";
import { StatelessComponentTypeSelector, StatelessComponentTypeSelectorProps } from "@hireroo/system-design/react/v2";
import StaticFlowChart, { StaticFlowChartProps } from "@hireroo/system-design/react/v2/StaticFlowChart/StaticFlowChart";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";

import ActivityBar, { ActivityBarProps } from "../../../../modules/ActivityBar/ActivityBar";
import AnswerSection, { AnswerSectionProps } from "../../../../ms-components/Question/AnswerSection/AnswerSection";
import QuestionAndAnswerTab, { QuestionAndAnswerTabProps } from "../../../../ms-components/Question/QuestionAndAnswerTab/QuestionAndAnswerTab";
import QuestionInformationSidePanel, {
  QuestionInformationSidePanelProps,
} from "../../../../ms-components/Question/QuestionInformationSidePanel/QuestionInformationSidePanel";
import Split, { SplitProps } from "../../../../primitive/Split/Split";
import { SplitParams, useSplitEvent } from "./privateHelper";

const StyledSplit = styled(Split)`
  height: calc(100vh - 73px);
  overflow: hidden;
  position: relative;
`;

const StyledQuestionAndAnswerTab = styled(QuestionAndAnswerTab)(({ theme }) => ({
  backgroundColor: theme.palette["Secondary/Shades"].p8,
}));

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  max-width: 100vw;
`;

const Toolbar = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette["Secondary/Shades"].p12,
}));

export type SystemDesignQuestionDetailProps = {
  information: QuestionInformationSidePanelProps;
  staticFlowChart: StaticFlowChartProps;
  questionAndAnswerTab: Omit<QuestionAndAnswerTabProps, "answerSections">;
  selector: StatelessComponentTypeSelectorProps;
  answerSections: (AnswerSectionProps & { onSelect?: () => void })[];
};

const SystemDesignQuestionDetail: React.FC<SystemDesignQuestionDetailProps> = props => {
  const { t } = useTranslation();
  const methods = useMethods();
  const splitEvent = useSplitEvent();
  const staticFlowChartBox = useResizeDetector();
  const staticFlowChartProps: StaticFlowChartProps = {
    ...props.staticFlowChart,
    aspectRatio: (staticFlowChartBox.height ?? 1) / (staticFlowChartBox.width ?? 1),
  };
  const questionAndAnswerTabProps: QuestionAndAnswerTabProps = {
    ...props.questionAndAnswerTab,
    answerSections: props.answerSections.map((answerSectionProps, index): QuestionAndAnswerTabProps["answerSections"][0] => {
      const { onSelect, ...answerSection } = answerSectionProps;
      return {
        name: [t("解答"), index + 1].join(" "),
        Component: <AnswerSection {...answerSection} />,
        onClickTab: () => {
          onSelect?.();
        },
      };
    }),
  };

  const existsFlowChart = staticFlowChartProps.elements.length > 0;

  const splitProps: SplitProps = {
    splitId: SplitParams.splitId,
    splitDirection: "VERTICAL",
    items: [
      {
        id: SplitParams.Contents.QuestionAndAnswer.id,
        Content: <StyledQuestionAndAnswerTab {...questionAndAnswerTabProps} />,
        size: {
          value: 400,
          unit: "px",
        },
        expandedSize: {
          value: 400,
          unit: "px",
        },
        minimizedSize: {
          value: 0,
          unit: "px",
        },
      },
      {
        id: "SplitFlowChart",
        Content: (
          <Box key="flow-chart" height="100%" display="flex" flexDirection="column" overflow="hidden">
            <Toolbar>
              <Box display="flex" justifyContent="right" alignItems="center">
                <StatelessComponentTypeSelector {...props.selector} />
              </Box>
            </Toolbar>
            <Box ref={staticFlowChartBox.ref} height="100%" overflow="hidden">
              {existsFlowChart && (
                <React.Suspense>
                  <StaticFlowChart {...staticFlowChartProps} />
                </React.Suspense>
              )}
              {!existsFlowChart && (
                <Box display="flex" justifyContent="space-around" alignItems="center" height="100%" width="100%">
                  <Typography variant="h6" gutterBottom>
                    {t("解答フローチャートが存在しません")}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        ),
        size: {
          value: 100,
          unit: "%",
        },
        minSize: {
          value: 300,
          unit: "px",
        },
      },
      {
        id: SplitParams.Contents.Information.id,
        Content: <QuestionInformationSidePanel {...props.information} />,
        size: {
          value: 400,
          unit: "px",
        },
        expandedSize: {
          value: 400,
          unit: "px",
        },
        minimizedSize: {
          value: 0,
          unit: "px",
        },
      },
    ],
  };

  const activityBarProps: ActivityBarProps = {
    items: [
      {
        icon: "DESCRIPTION",
        tooltip: t("問題"),
        onClick: () => {
          if (splitEvent.sidebarVisibleStatus === "MINIMIZED") {
            methods.expand(SplitParams.splitId, SplitParams.Contents.QuestionAndAnswer.id);
          } else {
            methods.minimize(SplitParams.splitId, SplitParams.Contents.QuestionAndAnswer.id);
          }
          splitEvent.setSidebarVisibleStatus(prev => (prev === "MINIMIZED" ? "OPEN" : "MINIMIZED"));
        },
        active: splitEvent.sidebarVisibleStatus === "OPEN",
      },
      {
        icon: "INFO",
        tooltip: t("この問題について"),
        onClick: () => {
          if (splitEvent.informationVisibleStatus === "MINIMIZED") {
            methods.expand(SplitParams.splitId, SplitParams.Contents.Information.id);
          } else {
            methods.minimize(SplitParams.splitId, SplitParams.Contents.Information.id);
          }
          splitEvent.setInformationVisibleStatus(prev => (prev === "MINIMIZED" ? "OPEN" : "MINIMIZED"));
        },
        active: splitEvent.informationVisibleStatus === "OPEN",
      },
    ],
  };

  return (
    <Wrapper>
      <ActivityBar {...activityBarProps} />
      <StyledSplit {...splitProps} />
    </Wrapper>
  );
};

SystemDesignQuestionDetail.displayName = "SystemDesignQuestionDetail";

export default withSplitProvider(SystemDesignQuestionDetail);
