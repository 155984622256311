import { FlowChart, type FlowChartProps, FlowChartSideBar, type FlowChartSideBarProps } from "@hireroo/system-design/react/v2";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";

import Split, { SplitProps } from "../../primitive/Split/Split";
import { SplitParams } from "./privateHelper";

type ElementSettings = Exclude<FlowChartProps["elementSettings"], undefined>;

const StyledSplit = styled(Split)(() => ({
  width: "100%",
  '&[data-direction*="vertical"]': {
    height: "calc(100% - 36px);",
  },
}));

export type RemoteInterviewSystemDesignCodingEditorProps = {
  flowChart: Omit<FlowChartProps, "elementSettings"> & { elementSettings?: ElementSettings };
  flowChartSidebar: Omit<FlowChartSideBarProps, "BottomComponent">;
  // resetFlowChartDialog: Pick<SystemDesignResetFlowChartDialogProps, "yesButton">;
  removeElementDialog: {
    onRemoveElements: () => void;
  };
};

const RemoteInterviewSystemDesignCodingEditor: React.FC<RemoteInterviewSystemDesignCodingEditorProps> = props => {
  const flowChartProps: FlowChartProps = {
    ...props.flowChart,
    elementSettings: props.flowChart.elementSettings
      ? {
          ...props.flowChart.elementSettings,
        }
      : undefined,
  };
  const flowChartSidebar: FlowChartSideBarProps = {
    ...props.flowChartSidebar,
  };

  const splitterProps: SplitProps = {
    splitId: SplitParams.splitId,
    splitDirection: "VERTICAL",
    items: [
      {
        id: SplitParams.Contents.SelectComponentArea.id,
        Content: (
          <Box key="column-2" sx={{ height: "100%", overflow: "hidden" }}>
            <React.Suspense>
              <FlowChartSideBar {...flowChartSidebar} />
            </React.Suspense>
          </Box>
        ),
        size: {
          value: 256,
          unit: "px",
        },
        minimizedSize: {
          value: 0,
          unit: "px",
        },
        expandedSize: {
          value: 256,
          unit: "px",
        },
        sidePane: {
          className: "tutorial-pane",
        },
      },
      {
        id: SplitParams.Contents.DrawingArea.id,
        Content: (
          <Box key="column-3" sx={{ position: "relative", height: "100%", overflow: "hidden" }}>
            <React.Suspense>
              <FlowChart {...flowChartProps} />
            </React.Suspense>
          </Box>
        ),
        size: {
          value: 100,
          unit: "%",
        },
      },
    ],
  };
  return <StyledSplit {...splitterProps} />;
};

RemoteInterviewSystemDesignCodingEditor.displayName = "RemoteInterviewSystemDesignCodingEditor";

export default RemoteInterviewSystemDesignCodingEditor;
