import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import ChatInput, { ChatInputProps } from "./parts/ChatInput/ChatInput";
import MessageList, { MessageListProps } from "./parts/MessageList/MessageList";

const Wrapper = styled(Box)(() => ({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
}));

const ContentWrapper = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  overflowY: "auto",
}));

const BottomWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  paddingTop: theme.spacing(2),
}));

export { LiveCodingChatWindowProvider, type LiveCodingChatWindowProviderProps, useLiveCodingChatWindowContext } from "./Context";

export type LiveCodingChatWindowProps = {
  messageList: MessageListProps;
  chatInput: ChatInputProps;
  helperText: string;
};

const LiveCodingChatWindow: React.FC<LiveCodingChatWindowProps> = props => {
  return (
    <Wrapper>
      <ContentWrapper>
        {props.messageList.messages.length === 0 && (
          <Box p={2}>
            <Typography variant="body2">{props.helperText}</Typography>
          </Box>
        )}
        <MessageList {...props.messageList} />
      </ContentWrapper>
      <BottomWrapper>
        <ChatInput {...props.chatInput} />
      </BottomWrapper>
    </Wrapper>
  );
};

LiveCodingChatWindow.displayName = "LiveCodingChatWindow";

export default LiveCodingChatWindow;
