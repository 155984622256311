import { EventEmitter } from "events";
import * as React from "react";

const EventName = {
  RESET_ALL_FIELD: "reset:all:fields",
} as const;

type Callback = () => void;

const NOOP: Callback = () => undefined;

export type ContextValue = {
  subscribeClearAllFields: (callback: Callback) => Callback;
  clearAllFields: Callback;
};

export const PopupSearchFilterContext = React.createContext<ContextValue>({
  subscribeClearAllFields: () => NOOP,
  clearAllFields: NOOP,
});

export const usePopupSearchFilterContext = () => React.useContext(PopupSearchFilterContext);

export type PopupSearchFilterProviderProps = {};

export const PopupSearchFilterProvider: React.FC<React.PropsWithChildren<PopupSearchFilterProviderProps>> = props => {
  const emitter = React.useRef(new EventEmitter());
  const defaultContextValue: ContextValue = {
    subscribeClearAllFields: (callback: () => void) => {
      emitter.current.on(EventName.RESET_ALL_FIELD, callback);
      return () => {
        emitter.current.off(EventName.RESET_ALL_FIELD, callback);
      };
    },
    clearAllFields: () => {
      emitter.current.emit(EventName.RESET_ALL_FIELD);
    },
  };
  return <PopupSearchFilterContext.Provider value={defaultContextValue}>{props.children}</PopupSearchFilterContext.Provider>;
};
