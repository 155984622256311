import { ChallengeCodingEditor } from "@hireroo/app-store/widget/shared/ChallengeCodingEditor";
import type { Widget } from "@hireroo/presentation";

import ChallengeCodingEditorChatGPTRightSidePanelContainer from "./widget/ChallengeCodingEditorChatGPTRightSidePanel/Container";
import ChallengeCodingEditorWebSearchRightSidePanelContainer from "./widget/ChallengeCodingEditorWebSearchRightSidePanel/Container";

export type GenerateChallengeCodingEditorRightSidePanelPropsArgs = {
  webSession: ChallengeCodingEditor.ChallengeWebSession | null;
  chatGPTSession: ChallengeCodingEditor.ChallengeChatGPTSession | null;
};

export const useGenerateProps = (
  args: GenerateChallengeCodingEditorRightSidePanelPropsArgs,
): Widget.ChallengeCodingEditorRightSidePanelProps => {
  const { webSession, chatGPTSession } = args;

  return {
    // Add "key" to ChallengeCodingEditorWebSearchRightSidePanelContainer to unmount component when user change language
    // because unmount is necessary to restore web search result.
    WebSearchSidePanel: webSession ? (
      <ChallengeCodingEditorWebSearchRightSidePanelContainer key={webSession.sessionId} webSession={webSession} />
    ) : null,
    ChatGPTSidePanel: chatGPTSession ? <ChallengeCodingEditorChatGPTRightSidePanelContainer chatGPTSession={chatGPTSession} /> : null,
    gptModel: "GPT-4o",
  };
};
