import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledInput = styled("input")(({ theme }) => ({
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  borderRadius: "3px",
  border: "1px solid rgba(255, 255, 255, 0.12)",
  fontFamily: 'Menlo, Monaco, "Courier New", monospace',
  paddingLeft: "8px",
  outline: "none",
  height: "42px",
  width: "100%",
}));

export type ConsoleOutputProps = {
  label: string;
  value: string;
};

const ConsoleOutput: React.FC<ConsoleOutputProps> = props => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="15%">
        <Typography variant="body2">{props.label}</Typography>
      </Box>
      <Box width="85%" height="100%" mr={2}>
        <StyledInput readOnly value={props.value} />
      </Box>
    </Box>
  );
};

ConsoleOutput.displayName = "ConsoleOutput";

export default ConsoleOutput;
