import type * as Types from "./types";

export const createDefaultState = (): Types.State => {
  return {
    playbackManager: null,
    sliderValue: 0,
    editorMode: "SUBMIT_RESULT",
    question: null,
    sessionIds: null,
    submission: null,
    appealMessage: null,
    playStatus: "PAUSE",
    playbackSettings: {
      enabledBehavioralControl: false,
      enabledCopyAndPasteDetection: false,
    },
    rightSidePanelMode: "WEB_SEARCH",
  };
};
