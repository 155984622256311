import { useTranslation } from "@hireroo/i18n";
import { QuizFreeTextEvaluateForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import HelpIcon from "@mui/icons-material/Help";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import DetailSwitch, { DetailSwitchProps } from "../../../modules/DetailSwitch/DetailSwitch";
import RestrictableTab, { RestrictableTabProps } from "../../../modules/RestrictableTab/RestrictableTab";
import ReportQuizSubmissionAccordion, {
  ReportQuizSubmissionAccordionProps,
} from "../ReportQuizSubmissionAccordion/ReportQuizSubmissionAccordion";
import FormActionButtonGroup, { FormActionButtonGroupProps } from "./parts/FormActionButtonGroup/FormActionButtonGroup";

const AccordionWrapper = styled(Box)(({ theme }) => ({
  borderRadius: "8px",
  border: "1px solid",
  borderColor: theme.palette.divider,
}));

type Mode = "READ" | "EDIT";

type Accordion = Omit<ReportQuizSubmissionAccordionProps, "defaultExpanded">;

export type ReportQuizSubmissionSectionProps = {
  mode: Mode;
  defaultTabId?: string;
  allSubmissionAccordions: Accordion[];
  correctSubmissionAccordions: Accordion[];
  incorrectSubmissionAccordions: Accordion[];
  skipSubmissionAccordions: Accordion[];
  unevaluatedSubmissionAccordions: Accordion[];
  unansweredSubmissionAccordions: Accordion[];
  formActionButtonGroup?: FormActionButtonGroupProps;
  defaultValues: QuizFreeTextEvaluateForm.QuizFreeTextEvaluateForm;
  onChangeMode: (mode: Mode) => void;
  onSubmit: SubmitHandler<QuizFreeTextEvaluateForm.QuizFreeTextEvaluateForm>;
};

const ReportQuizSubmissionSection: React.FC<ReportQuizSubmissionSectionProps> = props => {
  const { onChangeMode, onSubmit } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const validateSchema = QuizFreeTextEvaluateForm.useQuizFreeTextEvaluateForm();
  const method = useForm<QuizFreeTextEvaluateForm.QuizFreeTextEvaluateForm>({
    resolver: zodResolver(validateSchema),
    defaultValues: props.defaultValues,
  });
  const [expandAll, setExpandAll] = React.useState(false);
  const detailSwitch: DetailSwitchProps = {
    checked: expandAll,
    onClick: () => {
      setExpandAll(prev => !prev);
    },
  };

  const submissionTabProps: RestrictableTabProps = {
    defaultTab: props.defaultTabId,
    items: [
      {
        id: "all",
        Component: (
          <>
            <DetailSwitch {...detailSwitch} />
            <AccordionWrapper>
              {props.allSubmissionAccordions.map(submissionAccordion => (
                <ReportQuizSubmissionAccordion key={submissionAccordion.id} {...submissionAccordion} defaultExpanded={expandAll} />
              ))}
            </AccordionWrapper>
          </>
        ),
        label: {
          name: `${t("全て")} (${props.allSubmissionAccordions.length})`,
        },
      },
      {
        id: "correct",
        label: {
          name: `${t("正解")} (${props.correctSubmissionAccordions.length})`,
          disabled: props.correctSubmissionAccordions.length === 0,
        },
        Component: (
          <>
            <DetailSwitch {...detailSwitch} />
            <AccordionWrapper>
              {props.correctSubmissionAccordions.map(submissionAccordion => (
                <ReportQuizSubmissionAccordion key={submissionAccordion.id} {...submissionAccordion} defaultExpanded={expandAll} />
              ))}
            </AccordionWrapper>
          </>
        ),
      },
      {
        id: "incorrect",
        label: {
          name: `${t("不正解")} (${props.incorrectSubmissionAccordions.length})`,
          disabled: props.incorrectSubmissionAccordions.length === 0,
        },
        Component: (
          <>
            <DetailSwitch {...detailSwitch} />
            <AccordionWrapper>
              {props.incorrectSubmissionAccordions.map(submissionAccordion => (
                <ReportQuizSubmissionAccordion key={submissionAccordion.id} {...submissionAccordion} defaultExpanded={expandAll} />
              ))}
            </AccordionWrapper>
          </>
        ),
      },
      {
        id: "skip",
        label: {
          name: `${t("スキップ")} (${props.skipSubmissionAccordions.length})`,
          disabled: props.skipSubmissionAccordions.length === 0,
        },
        Component: (
          <>
            <DetailSwitch {...detailSwitch} />
            <AccordionWrapper>
              {props.skipSubmissionAccordions.map(submissionAccordion => (
                <ReportQuizSubmissionAccordion key={submissionAccordion.id} {...submissionAccordion} defaultExpanded={expandAll} />
              ))}
            </AccordionWrapper>
          </>
        ),
      },
      {
        id: "unevaluated",
        label: {
          name: `${t("未評価")} (${props.unevaluatedSubmissionAccordions.length})`,
          disabled: props.unevaluatedSubmissionAccordions.length === 0,
        },
        Component: (
          <>
            <DetailSwitch {...detailSwitch} />
            <AccordionWrapper>
              {props.unevaluatedSubmissionAccordions.map(submissionAccordion => (
                <ReportQuizSubmissionAccordion key={submissionAccordion.id} {...submissionAccordion} defaultExpanded={expandAll} />
              ))}
            </AccordionWrapper>
          </>
        ),
      },
      {
        id: "unanswered",
        label: {
          name: `${t("未解答")} (${props.unansweredSubmissionAccordions.length})`,
          disabled: props.unansweredSubmissionAccordions.length === 0,
        },
        Component: (
          <>
            <DetailSwitch {...detailSwitch} />
            <AccordionWrapper>
              {props.unansweredSubmissionAccordions.map(submissionAccordion => (
                <ReportQuizSubmissionAccordion key={submissionAccordion.id} {...submissionAccordion} defaultExpanded={expandAll} />
              ))}
            </AccordionWrapper>
          </>
        ),
      },
    ],
    tabs: {
      "aria-label": "quiz-submission-tabs",
    },
    variant: "OUTLINED",
  };

  const handleSaveButtonClick = React.useCallback(() => {
    onChangeMode("READ");
    method.handleSubmit(onSubmit)();
  }, [method, onChangeMode, onSubmit]);

  const formActionButtonGroupProps: FormActionButtonGroupProps | undefined = props.formActionButtonGroup
    ? {
        ...props.formActionButtonGroup,
        cancelButton: {
          ...props.formActionButtonGroup.cancelButton,
          onClick: () => {
            onChangeMode("READ");
          },
        },
        saveButton: {
          ...props.formActionButtonGroup.saveButton,
          onClick: handleSaveButtonClick,
        },
        editButton: {
          ...props.formActionButtonGroup.editButton,
          onClick: () => {
            onChangeMode("EDIT");
          },
        },
      }
    : undefined;

  return (
    <Box>
      <FormProvider {...method}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: "bold" }}>
            {t("提出物")}
          </Typography>
          {formActionButtonGroupProps && (
            <Box display="flex" alignItems="center" gap={1}>
              <FormActionButtonGroup {...formActionButtonGroupProps} />
              <Tooltip title={`${t("採点結果の編集は自由記入形式のみ可能です")}`} arrow>
                <HelpIcon fontSize="small" sx={{ color: theme.palette.grey["500"] }} />
              </Tooltip>
            </Box>
          )}
        </Stack>
        <Box mt={2}>
          <RestrictableTab {...submissionTabProps} />
        </Box>
      </FormProvider>
    </Box>
  );
};

ReportQuizSubmissionSection.displayName = "ReportQuizSubmissionSection";

export default ReportQuizSubmissionSection;
