import { safeJsonParse } from "@hireroo/app-helper/parser";
import { ChallengeCodingEditor } from "@hireroo/app-store/widget/shared/ChallengeCodingEditor";
import { AlgorithmSignatureForm, AlgorithmTestCaseForm } from "@hireroo/validator";
import * as React from "react";

export const useAlgorithmSignature = (entityId: number): AlgorithmSignatureForm.AlgorithmSignatureSchemaType => {
  const challengeEntityHooks = ChallengeCodingEditor.useCreateChallengeEntityHooks(entityId);
  const question = challengeEntityHooks.useQuestion();

  const signatureSchema = AlgorithmSignatureForm.useAlgorithmSignatureSchema();

  return React.useMemo(() => {
    if (question?.signature) {
      const result = signatureSchema.safeParse(JSON.parse(question.signature));
      if (result.success) {
        return result.data;
      }
    }
    return {
      function: "solution",
      inputs: [
        {
          name: "",
          type: "string",
        },
      ],
      outputs: [{ type: "string" }],
    };
  }, [question?.signature, signatureSchema]);
};

export const useDatabaseSignature = (entityId: number): AlgorithmSignatureForm.DatabaseSignatureSchemaType => {
  const challengeEntityHooks = ChallengeCodingEditor.useCreateChallengeEntityHooks(entityId);
  const question = challengeEntityHooks.useQuestion();
  const signatureSchema = AlgorithmSignatureForm.useDatabaseSignatureSchema();

  return React.useMemo(() => {
    if (question?.signature) {
      const result = signatureSchema.safeParse(JSON.parse(question.signature));
      if (result.success) {
        return result.data;
      }
    }
    return {
      tables: [
        {
          name: "",
          columns: [
            {
              name: "",
              type: "string",
            },
          ],
        },
      ],
      columns: [
        {
          name: "",
          type: "string",
        },
      ],
    };
  }, [question?.signature, signatureSchema]);
};

export const useClassSignature = (entityId: number): AlgorithmSignatureForm.ClassSignatureSchemaType => {
  const challengeEntityHooks = ChallengeCodingEditor.useCreateChallengeEntityHooks(entityId);
  const question = challengeEntityHooks.useQuestion();

  const signatureSchema = AlgorithmSignatureForm.useClassSignatureSchema();

  return React.useMemo(() => {
    if (question?.signature) {
      const result = signatureSchema.safeParse(JSON.parse(question.signature));
      if (result.success) {
        return result.data;
      }
    }
    return {
      class: "Example",
      constructor: {
        inputs: [
          {
            name: "",
            type: "string",
          },
        ],
        outputs: [{ type: "string" }],
      },
      methods: [
        {
          name: "",
          inputs: [
            {
              name: "",
              type: "string",
            },
          ],
          outputs: [{ type: "string" }],
        },
      ],
    };
  }, [question?.signature, signatureSchema]);
};

export const useDefaultCorrectnessTestCases = (entityId: number): AlgorithmTestCaseForm.CorrectnessTestCaseSchema[] => {
  const challengeEntityHooks = ChallengeCodingEditor.useCreateChallengeEntityHooks(entityId);
  const question = challengeEntityHooks.useQuestion();
  const correctnessTestCases = AlgorithmTestCaseForm.useCorrectnessTestCasesFromJsonDangerous();

  return React.useMemo(() => {
    if (!question?.correctnessTestCase) return [];
    const parsedResult = correctnessTestCases.safeParse(safeJsonParse(question.correctnessTestCase));
    if (!parsedResult.success) {
      return [];
    }
    return parsedResult.data;
  }, [correctnessTestCases, question?.correctnessTestCase]);
};

const getElementXPath = (element: Element): string => {
  if (element.id !== "") {
    return 'id("' + element.id + '")';
  }
  if (element === document.body) {
    return element.tagName.toLowerCase();
  }

  let ix = 0;
  const siblings = element.parentNode?.childNodes;
  if (siblings) {
    for (let i = 0; i < siblings.length; i++) {
      const sibling = siblings[i];
      if (sibling === element) {
        return getElementXPath(element.parentNode as Element) + "/" + element.tagName.toLowerCase() + "[" + (ix + 1) + "]";
      }
      if (sibling.nodeType === Node.ELEMENT_NODE && sibling.nodeName === element.nodeName) {
        ix++;
      }
    }
  }
  return "";
};

export const observerPageLinkClickEvent = (entityId: number) => {
  const { setLeavePageAction } = ChallengeCodingEditor.createChallengeEntityAction(entityId);
  const callback = (event: MouseEvent) => {
    try {
      const xPath = event.target ? getElementXPath(event.target as HTMLElement) : null;
      setLeavePageAction({
        type: "CLICK",
        xPath,
        createdAtMilliseconds: Date.now(),
      });
    } catch {
      setLeavePageAction({
        type: "CLICK",
        xPath: null,
        createdAtMilliseconds: Date.now(),
      });
    }
  };
  // register event listener in capturing phase to prevent it from overwriting Google search access event
  document.body.addEventListener("click", callback, true);
  return () => {
    document.body.removeEventListener("click", callback, true);
  };
};

/**
 * Function to determine if it is a page abandonment friendly event.
 * If the time between issuing the event and sending the event is within 100msec, it is considered valid.
 */
export const isValidLeaveEventDurationTime = (durationMilliseconds: number): boolean => {
  return 0 < durationMilliseconds && durationMilliseconds < 100;
};
