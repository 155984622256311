import { styled } from "@mui/material/styles";
import * as React from "react";

import type { VideoTrack as VideoTrackObject } from "../../types";

const Video = styled("video")({
  width: "100%",
  height: "100%",
});

export type VideoTrackProps = {
  track: VideoTrackObject;
  isFrontFacing: boolean;
  isPortrait: boolean;
};

const VideoTrack: React.FC<VideoTrackProps> = props => {
  const ref = React.useRef<HTMLVideoElement>(null);

  React.useEffect(() => {
    if (!ref.current) {
      return;
    }
    const el = ref.current;
    el.muted = true;
    props.track.attach(el);

    return () => {
      props.track.detach(el);
      el.src = "";
      el.srcObject = null;
    };
  }, [props.track]);

  const style = {
    transform: props.isFrontFacing ? "scaleX(-1)" : "",
    objectFit: props.isPortrait ? ("contain" as const) : ("fill" as const),
  };
  return <Video ref={ref} style={style} />;
};

VideoTrack.displayName = "VideoTrack";

export default VideoTrack;
