import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import EmployeeLayout, { EmployeeLayoutProps } from "../../layouts/EmployeeLayout/EmployeeLayout";
import TestSearchForm, { TestSearchFormProps } from "../../ms-components/Spot/TestSearchForm/TestSearchForm";

const StyledHeaderStack = styled(Stack)(() => ({
  height: 36,
  alignItems: "center",
  justifyContent: "space-between",
}));

export type ScreeningTestListProps = {
  layout: EmployeeLayoutProps;
  active: boolean;
  search: Omit<TestSearchFormProps, "enabledScreeningFeature">;
  TestList: React.ReactNode;
  CsvDownload?: React.ReactNode;
};

const ScreeningTestList: React.FC<ScreeningTestListProps> = props => {
  const { t } = useTranslation();
  const layoutProps: EmployeeLayoutProps = {
    ...props.layout,
  };

  return (
    <EmployeeLayout {...layoutProps}>
      <Box p={3}>
        <StyledHeaderStack direction="row" mb={2}>
          <Typography variant="h5" fontWeight={700} noWrap>
            {t("候補者一覧")}
          </Typography>
        </StyledHeaderStack>

        <Box>
          <Stack spacing={2}>
            <TestSearchForm
              // FIXME: There should be other way to reset state when defaultValues changes
              key={`${props.active ? "ACTIVE" : "INACTIVE"}-${JSON.stringify(props.search.defaultValues)}`}
              enabledScreeningFeature={true}
              {...props.search}
            />
            {props.TestList}
          </Stack>
        </Box>
        {props.CsvDownload}
      </Box>
    </EmployeeLayout>
  );
};

ScreeningTestList.displayName = "ScreeningTestList";

export default ScreeningTestList;
