import { createAnswerRuntimeInput, createHintInput } from "@hireroo/app-helper/algorithm";
import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Auth } from "@hireroo/app-store/essential/employee";
import { AlgorithmResourceEditor } from "@hireroo/app-store/view-domain/AlgorithmResourceEditor";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import type * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import { Pages, Widget } from "@hireroo/presentation/legacy";
import { AlgorithmAnswerForm } from "@hireroo/validator";
import * as Sentry from "@sentry/browser";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { v4 as uuid } from "uuid";

import { useCodeAction } from "./answersHooks";

type UseGenerateSaveAnswersButtonPropsArgs = {
  companyId: number;
};
type SaveAnswersButtonProps = Pages.AlgorithmResourceEditorProps["saveAnswersButton"];

type SaveAnswers = {
  saveButton: SaveAnswersButtonProps;
  onDraftSave: () => void;
};
export const useGenerateSaveAnswersButtonProps = (args: UseGenerateSaveAnswersButtonPropsArgs): SaveAnswers => {
  const { t } = useTranslation();
  const userId = Auth.useCurrentUid();
  const client = getGraphqlClient();
  const question = AlgorithmResourceEditor.useQuestion();
  const { methods, signatureProps, performanceTestCases, correctnessTestCases } = Widget.useAlgorithmAnswersFormContext();
  const { runCode, profileCode } = useCodeAction();
  const [isValidating, setIsValidating] = React.useState<boolean>(false);

  const validateRunCode = React.useCallback(
    async (runtime: string, codeBody: string): Promise<boolean> => {
      let isValid = true;

      const promiseRes = await Promise.all(
        correctnessTestCases.data.map(async (tc, i) => {
          const input = tc.inputs.join(",");
          const output = tc.outputs.join(",");
          return await runCode(
            {
              signature: JSON.stringify(signatureProps.signature),
              tcInput: input,
              tcOutput: output,
              runtime: runtime,
              variant: signatureProps.variant,
              codeBody: codeBody,
            },
            i.toString(),
          );
        }),
      );

      for (const res of promiseRes) {
        if (res?.algorithmRunCode?.isAccepted) {
          continue;
        }
        isValid = false;
      }

      return isValid;
    },
    [correctnessTestCases, runCode, signatureProps.signature, signatureProps.variant],
  );

  const validateProfileCode = React.useCallback(
    async (runtime: string, codeBody: string): Promise<boolean> => {
      let isValid = true;

      const promiseRes = await Promise.all(
        performanceTestCases.data.map(async (tc, i) => {
          const input = tc.inputs.join(",");
          const output = tc.outputs.join(",");
          return await profileCode(
            {
              signature: JSON.stringify(signatureProps.signature),
              tcInput: input,
              tcOutput: output,
              runtime: runtime,
              variant: signatureProps.variant,
              codeBody: codeBody,
            },
            i.toString(),
          );
        }),
      );

      for (const res of promiseRes) {
        if (res?.algorithmProfileCode?.isAccepted) {
          continue;
        }
        isValid = false;
      }

      return isValid;
    },
    [performanceTestCases, profileCode, signatureProps.signature, signatureProps.variant],
  );

  const validateAnswerCode = React.useCallback(
    async (runtimes: AlgorithmAnswerForm.AlgorithmAnswersFormSchema["answers"][0]["answerRuntimes"], index: number) => {
      let isAnswerCodeValid = true;

      for await (const [i, r] of runtimes.entries()) {
        const res = await validateRunCode(r.runtime, r.codeBodies[r.runtime]);
        const resProfile = await validateProfileCode(r.runtime, r.codeBodies[r.runtime]);

        if (!res) {
          isAnswerCodeValid = false;
          methods.setError(`answers.${index}.answerRuntimes.${i}.codeBodies.${r.runtime}`, {
            type: "invalid",
            message: t("テストケースの一部が期待値通りに動かないコードです。"),
          });
        }
        if (!resProfile) {
          isAnswerCodeValid = false;
          methods.setError(`answers.${index}.answerRuntimes.${i}.codeBodies.${r.runtime}`, {
            type: "invalid",
            message: t("パフォーマンステストケースの一部が期待値通りに動かないコードです。"),
          });
        }
      }
      return isAnswerCodeValid;
    },
    [methods, t, validateProfileCode, validateRunCode],
  );

  const validateForm = React.useCallback(
    (field: AlgorithmAnswerForm.AlgorithmAnswersFormSchema["answers"][0], index: number): boolean => {
      if (field.runtimeComplexity === "") {
        methods.setError(`answers.${index}.runtimeComplexity`, {
          type: "required",
          message: t("時間計算量は必須です。"),
        });
        return false;
      }

      if (field.spaceComplexity === "") {
        methods.setError(`answers.${index}.spaceComplexity`, {
          type: "required",
          message: t("空間計算量は必須です。"),
        });
        return false;
      }

      let valid = true;
      field.contents.forEach((content, lI) => {
        if (content.title === "") {
          methods.setError(`answers.${index}.contents.${lI}.title`, {
            type: "required",
            message: t("タイトルは必須項目です。"),
          });

          valid = false;
        }

        if (content.description === "") {
          methods.setError(`answers.${index}.contents.${lI}.description`, {
            type: "required",
            message: t("本文は必須項目です。"),
          });
          valid = false;
        }
      });

      return valid;
    },
    [methods, t],
  );

  const validateAnswer = React.useCallback(
    async (answers: AlgorithmAnswerForm.AlgorithmAnswersFormSchema["answers"]) => {
      const promiseRes = await Promise.all(
        answers.map(async (a, i) => {
          // runtime以外のvalidate.
          const formValid = validateForm(a, i);
          if (!formValid) {
            return false;
          }

          return await validateAnswerCode(a.answerRuntimes, i);
        }),
      );

      return promiseRes.every(res => res);
    },
    [validateAnswerCode, validateForm],
  );

  const getFormattedAnswers = React.useCallback(
    (
      fields: AlgorithmAnswerForm.AlgorithmAnswersFormSchema,
      answers: Graphql.UpdateAlgorithmAnswerInput[],
      questionKey: Pick<Graphql.UpdateAlgorithmAnswerInput, "questionId" | "questionVersion">,
    ) => {
      return fields.answers.map((a, answerIndex) => {
        const content = {
          titleJa: "",
          titleEn: "",
          descriptionJa: "",
          descriptionEn: "",
        };
        a.contents.forEach(l => {
          if (l.language === "ja") {
            content.titleJa = l.title;
            content.descriptionJa = l.description;
          }

          if (l.language === "en") {
            content.titleEn = l.title;
            content.descriptionEn = l.description;
          }
        });

        return {
          id: a.id ?? 0,
          // TODO:
          //  @poster-keisuke Enable users to specify this id when design renewal of question creation page.
          //  Until then we fill this value with a random hash.
          uniqueId: uuid(),
          answerRuntimes: a.answerRuntimes.map((r, index) => {
            return {
              ...answers[answerIndex]?.answerRuntimes?.[index],
              answerId: a.id ?? 0,
              id: r.id ?? 0,
              runtime: r.runtime,
              codeBody: r.codeBodies[r.runtime],
            };
          }),
          titleJa: content.titleJa,
          titleEn: content.titleEn,
          descriptionJa: content.descriptionJa,
          descriptionEn: content.descriptionEn,
          label: a.label,
          questionId: answers[answerIndex]?.questionId ?? questionKey.questionId,
          questionVersion: answers[answerIndex]?.questionVersion ?? questionKey.questionVersion,
          runtimeComplexity: a.runtimeComplexity,
          spaceComplexity: a.spaceComplexity,
        };
      });
    },
    [],
  );

  const handleSave: (isDraft: boolean) => SubmitHandler<AlgorithmAnswerForm.AlgorithmAnswersFormSchema> = React.useCallback(
    isDraft => async fields => {
      if (question) {
        if (!isDraft) {
          setIsValidating(true);
          const isValid = await validateAnswer(fields.answers);
          setIsValidating(false);

          if (!isValid) return;
        }

        const answers = getFormattedAnswers(
          fields,
          question.answers.map(a => {
            // answerRuntimes is readonly hence the separation
            const { answerRuntimes, ...rest } = a;
            return {
              ...rest,
              // TODO:
              //  @poster-keisuke Enable users to specify this id when design renewal of question creation page.
              //  Until then we fill this value with a random hash.
              uniqueId: uuid(),
              questionId: question.questionId,
              questionVersion: question.version,
              answerRuntimes: answerRuntimes.map(r => createAnswerRuntimeInput(r)),
            };
          }),
          { questionId: question.questionId, questionVersion: question.version },
        );
        client
          .UpdateAlgorithmQuestion({
            updateQuestionInput: {
              questionId: question.questionId,
              oldVersion: question.version,
              newVersion: question.version,
              companyId: args.companyId,
              employeeId: userId,
              variant: question.variant,
              difficulty: question.difficulty,
              timeLimitMin: (question?.timeLimitSeconds || 1800) / 60,
              isPrivate: question.isPrivate,
              status: "DRAFT",
              titleJa: question.titleJa,
              titleEn: question.titleEn,
              descriptionJa: question.descriptionJa,
              descriptionEn: question.descriptionEn,
              correctnessTestCase: question.correctnessTestCase,
              performanceTestCase: question.performanceTestCase,
              correctnessTestCaseIds: null,
              performanceTestCaseIds: null,
              performanceTimeLimitMilliSeconds: question.performanceTimeLimitMilliSeconds,
              signature: question.signature,
              answers: answers,
              hints: question.hints.map(h => createHintInput(h)),
            },
          })
          .then(res => {
            AlgorithmResourceEditor.updateQuestion(res.updateAlgorithmQuestion);
            if (!isDraft) {
              AlgorithmResourceEditor.updateActiveStep(AlgorithmResourceEditor.STEPS.HINTS);
            }
            Snackbar.notify({
              severity: "success",
              message: t("問題の一時保存が完了しました。"),
            });
          })
          .catch(error => {
            Sentry.captureException(error);
            const errorNotification = ErrorHandlingHelper.generateErrorNotification(
              error,
              t("問題の一時保存に失敗しました。しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
            );
            Snackbar.notify({
              severity: "error",
              message: errorNotification.message,
            });
          });
      }
    },
    [args.companyId, client, question, t, userId, validateAnswer, getFormattedAnswers],
  );

  return {
    saveButton: {
      onClick: () => {
        methods.handleSubmit(handleSave(false), () => AlgorithmResourceEditor.updateSaveStatus("READY"))();
      },
      loading: isValidating,
    },
    onDraftSave: () => {
      methods.handleSubmit(handleSave(true), () => AlgorithmResourceEditor.updateSaveStatus("READY"))();
    },
  };
};
