import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import Box from "@mui/material/Box";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React, { useMemo } from "react";

import {
  ComponentType,
  ELEMENT_LABEL,
  ELEMENT_TYPE,
  ElementDataKeyMap,
  ElementMasterData,
  OperationType,
  UnionSettingsFields,
} from "../../../features";
import { useSystemDesignContext } from "../../../store";
import { CacheSettingsForm } from "./parts/CacheSettingsForm/CacheSettingsForm";
import { CommentSettingsForm } from "./parts/CommentSettingsForm/CommentSettingsForm";
import { DefaultSettingsForm } from "./parts/DefaultSettingsForm/DefaultSettingsForm";
import { EdgeSettingsForm } from "./parts/EdgeSettingsForm/EdgeSettingsForm";
import { QueueSettingsForm } from "./parts/QueueSettingsForm/QueueSettingsForm";
import { SqlSettingsForm } from "./parts/SqlSettingsForm/SqlSettingsForm";
import { VmSettingsForm } from "./parts/VmSettingsForm/VmSettingsForm";

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  opacity: 0.95,
}));

export type ElementSettingsProps = {
  elementId: string;
  componentType: ComponentType;
  updateSettings: (id: string, updates: UnionSettingsFields, operationType: OperationType) => void;
  deleteElementButton: Pick<IconButtonProps, "onClick">;
};

export const ElementSettings: React.FC<ElementSettingsProps> = React.memo((props: ElementSettingsProps) => {
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const Store = useSystemDesignContext();
  const elementFactory = Store.hooks.useElement();
  const element = useMemo(() => {
    return elementFactory(props.elementId);
  }, [elementFactory, props.elementId]);

  return (
    <StyledPaper elevation={4}>
      <Box width={200} p={2}>
        <Box display={"flex"} alignItems={"center"}>
          <SettingsIcon />
          <Box mr={1} />
          <Typography variant={"subtitle1"} sx={{ fontWeight: "bold" }}>
            {t("詳細設定")}
          </Typography>
          <Box flexGrow={1} />
          <IconButton {...props.deleteElementButton} size={"small"}>
            <DeleteIcon color={"primary"} fontSize={"small"} />
          </IconButton>
        </Box>

        {element?.type === ELEMENT_TYPE.edge ? (
          <Box mt={2}>
            <Typography>{t("種別")}</Typography>
            <Box mt={1} />
            <Typography>{t("データフロー")}</Typography>
            <EdgeSettingsForm element={element} updateSettings={props.updateSettings} />
          </Box>
        ) : element?.type === ELEMENT_TYPE.comment ? (
          <Box mt={2}>
            <Typography>{t("種別")}</Typography>
            <Box mt={1} />
            <Typography>{t("テキスト")}</Typography>
            <CommentSettingsForm element={element} updateSettings={props.updateSettings} />
          </Box>
        ) : (
          <Box mt={2}>
            <Typography>{t("種別")}</Typography>
            <Box mt={1} />
            <Typography>
              {element && resolveLanguage(ElementMasterData[ElementDataKeyMap[props.componentType][element.label]], lang, "title")}
            </Typography>
          </Box>
        )}

        {element?.type === ELEMENT_TYPE.network && <DefaultSettingsForm element={element} updateSettings={props.updateSettings} />}

        {element?.type === ELEMENT_TYPE.node && (
          <>
            <DefaultSettingsForm key={element.id} element={element} updateSettings={props.updateSettings} />
            {element.label === ELEMENT_LABEL.vm && <VmSettingsForm element={element} updateSettings={props.updateSettings} />}
            {element.label === ELEMENT_LABEL.sql && <SqlSettingsForm element={element} updateSettings={props.updateSettings} />}
            {element.label === ELEMENT_LABEL.cache && <CacheSettingsForm element={element} updateSettings={props.updateSettings} />}
            {element.label === ELEMENT_LABEL.queue && <QueueSettingsForm element={element} updateSettings={props.updateSettings} />}
          </>
        )}
      </Box>
    </StyledPaper>
  );
});

ElementSettings.displayName = "ElementSettings";

export default ElementSettings;
