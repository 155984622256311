import { INITIAL_VERSION } from "@hireroo/app-definition/question";
import { QuizQuestionEditor } from "@hireroo/app-store/widget/e/QuizQuestionEditor";
import { SupportLanguage } from "@hireroo/i18n";
import { useLanguageCode } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation/legacy";
export type GenerateQuizPackageDetailPropsArgs = {};

const hasValidString = (value?: string | null): boolean => {
  return typeof value === "string" && value !== "";
};

type SingleChoiceQuestion = Extract<Widget.QuizQuestionEditorProviderProps["fields"], { variant: "SINGLE_CHOICE" }>["singleChoiceQuestions"][0];
type MultiChoiceQuestion = Extract<
  Widget.QuizQuestionEditorProviderProps["fields"],
  { variant: "MULTI_CHOICE" }
>["multiChoiceQuestion"]["options"][0];
type FreeTextQuestion = Extract<Widget.QuizQuestionEditorProviderProps["fields"], { variant: "FREE_TEXT" }>["evaluations"][0];

export const useGenerateProviderProps = (_args: GenerateQuizPackageDetailPropsArgs): Widget.QuizQuestionEditorProviderProps => {
  const question = QuizQuestionEditor.useQuestion();
  const hasJapanese = hasValidString(question?.titleJa) || hasValidString(question?.descriptionJa);
  const hasEnglish = hasValidString(question?.titleEn) || hasValidString(question?.descriptionEn);
  const oneOfHas = hasJapanese || hasEnglish;
  const lang = useLanguageCode();
  const canShowAddJapaneseMenuItem: boolean = oneOfHas ? !hasJapanese : lang !== "ja";
  const canShowAddEnglishMenuItem: boolean = oneOfHas ? !hasEnglish : lang !== "en";
  const sharedFields: Omit<
    Widget.QuizQuestionEditorProviderProps["fields"],
    "variant" | "singleChoiceCorrectOptionIndex" | "singleChoiceQuestions" | "multiChoiceQuestion" | "evaluations"
  > = {
    id: question?.id,
    isPublished: question?.status === "PUBLISHED",
    newVersion: question?.version ?? INITIAL_VERSION,
    oldVersion: question?.version ?? INITIAL_VERSION,
    status: question?.status ?? "DRAFT",
    isPublic: typeof question?.isPrivate === "boolean" ? !question.isPrivate : false,
    difficulty: question?.difficulty ?? "MEDIUM",
    metricType: question?.metricType ?? "BACKEND",
    questionDetails: {
      ja: {
        title: question?.titleJa ?? "",
        detail: question?.descriptionJa ?? "",
      },
      en: {
        title: question?.titleEn ?? "",
        detail: question?.descriptionEn ?? "",
      },
    },
    selectedLanguages: [
      !canShowAddJapaneseMenuItem && {
        value: SupportLanguage.JA,
      },
      !canShowAddEnglishMenuItem && {
        value: SupportLanguage.EN,
      },
    ].flatMap(v => (v ? [v] : [])),
  };
  const options =
    question?.options && question?.options.length
      ? [...question.options]
      : [{ id: 0, isCorrect: true, titleEn: "", additionalDetailEn: "", titleJa: "", additionalDetailJa: "" }];
  if (!question || question.variant === "SINGLE_CHOICE") {
    return {
      fields: {
        ...sharedFields,
        variant: "SINGLE_CHOICE",
        singleChoiceCorrectOptionIndex: question?.options?.findIndex(option => option.isCorrect).toString() || "0",
        singleChoiceQuestions: (
          question?.options || [{ id: 0, isCorrect: true, titleEn: "", additionalDetailEn: "", titleJa: "", additionalDetailJa: "" }]
        ).map((option): SingleChoiceQuestion => {
          return {
            singleChoiceOptionId: option.id,
            isCorrect: option.isCorrect,
            content: {
              en: {
                title: option.titleEn || "",
                additionalDetail: option.additionalDetailEn || "",
              },
              ja: {
                title: option.titleJa || "",
                additionalDetail: option.additionalDetailJa || "",
              },
            },
          };
        }),
      },
    };
  }
  if (question.variant === "MULTI_CHOICE") {
    return {
      fields: {
        ...sharedFields,
        variant: "MULTI_CHOICE",
        multiChoiceQuestion: {
          options: options.map((option): MultiChoiceQuestion => {
            return {
              multiChoiceOptionId: option.id,
              isCorrect: option.isCorrect,
              content: {
                en: {
                  title: option.titleEn || "",
                  detail: option.additionalDetailEn || "",
                },
                ja: {
                  title: option.titleJa || "",
                  detail: option.additionalDetailJa || "",
                },
              },
            };
          }),
        },
      },
    };
  }
  return {
    fields: {
      ...sharedFields,
      variant: "FREE_TEXT",
      evaluations: (question?.evaluationItems || [{ id: 0, titleJa: "", titleEn: "" }])?.map((item): FreeTextQuestion => {
        return {
          freeTextOptionId: item.id,
          questionId: question?.id,
          questionVersion: question?.version,
          content: {
            ja: {
              title: item.titleJa ?? "",
            },
            en: {
              title: item.titleEn ?? "",
            },
          },
        };
      }),
    },
  };
};
