import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";

import Heading from "./parts/Heading/Heading";
import PieChart from "./parts/PieChart/PieChart";
import RankLabel, { RankLabelProps } from "./parts/RankLabel/RankLabel";
import ScoreLabel from "./parts/ScoreLabel/ScoreLabel";
import StatusMessage from "./parts/StatusMessage/StatusMessage";

type Rank = RankLabelProps["rank"];

const WIDTH = 152;

const Wrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  width: WIDTH,
  height: "190px",
  display: "flex",
  justifyContent: "center",
  borderRadius: "16px",
}));

const CenteringWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  width: WIDTH - 32,
  height: WIDTH,
  position: "relative",
}));

const OverwrapArea = styled(Box)(() => ({
  width: WIDTH,
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
}));

const DefaultRankColor = "linear-gradient(270deg, #686868 0%, #868686 100%)";

const RankColorMap: Record<Rank, string> = {
  S: `linear-gradient(270deg, #CB1813 0%, #FA3A36 100%)`,
  A: `linear-gradient(270deg, #E0630C 0%, #EE9321 100%)`,
  B: `linear-gradient(270deg, #D39316 0%, #EEBA35 100%)`,
  C: `linear-gradient(270deg, #447CD0 0%, #77A4E9 100%)`,
  D: `linear-gradient(270deg, #17A01C 0%, #3DCF43 100%)`,
  UNKNOWN: DefaultRankColor,
};

export type ScorePieChartProps = {
  score: number;
  displayScoreArea: DisplayScoreArea;
};

type ErrorView = {
  kind: "ERROR";
  message: string;
};

type ScoringView = {
  kind: "SCORING";
};

type ScoreOnlyView = {
  kind: "SCORE_ONLY";
};

type WithRankView = {
  kind: "WITH_RANK";
  rankLabel: RankLabelProps;
};

type DisplayScoreArea = ScoringView | ScoreOnlyView | WithRankView | ErrorView;

const ScorePieChart: React.FC<ScorePieChartProps> = props => {
  const { t } = useTranslation();
  const bgColor = React.useMemo((): string => {
    if (props.displayScoreArea.kind === "WITH_RANK") {
      return RankColorMap[props.displayScoreArea.rankLabel.rank];
    }
    return DefaultRankColor;
  }, [props.displayScoreArea]);
  return (
    <Wrapper direction="column" position="relative" sx={{ background: bgColor }}>
      <Heading />
      <CenteringWrapper>
        <OverwrapArea>
          <PieChart value={props.score} />
        </OverwrapArea>
        {props.displayScoreArea.kind === "SCORE_ONLY" && (
          <OverwrapArea>
            <ScoreLabel score={props.score} />
          </OverwrapArea>
        )}
        {props.displayScoreArea.kind === "WITH_RANK" && (
          <OverwrapArea>
            <Stack direction="column" spacing={-2}>
              <ScoreLabel score={props.score} />
              <RankLabel {...props.displayScoreArea.rankLabel} />
            </Stack>
          </OverwrapArea>
        )}

        {props.displayScoreArea.kind === "ERROR" && (
          <OverwrapArea>
            <StatusMessage kind="ERROR" message={props.displayScoreArea.message} />
          </OverwrapArea>
        )}

        {props.displayScoreArea.kind === "SCORING" && (
          <OverwrapArea>
            <StatusMessage kind="INFO" message={t("採点中")} />
          </OverwrapArea>
        )}
      </CenteringWrapper>
    </Wrapper>
  );
};

ScorePieChart.displayName = "ScorePieChart";

export default ScorePieChart;
