import { App, Company } from "@hireroo/app-store/essential/employee";
import { RemotesStore } from "@hireroo/app-store/page/e/remotes";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import * as React from "react";

import RemoteInterviewSearchAreaContainer, { RemoteInterviewSearchAreaContainerProps } from "./Container";

const FETCH_SIZE = 100;

export type RemoteInterviewSearchAreaFetchContainerProps = {};

const RemoteInterviewSearchAreaFetchContainer: React.FC<RemoteInterviewSearchAreaFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const companyId = Company.useStrictActiveCompanyId();
  const initialized = RemotesStore.useResponseInitialized();
  const [result, refresh] = Graphql.useGetSearchItemsForRemotesQuery({
    variables: {
      companyId: companyId,
      size: FETCH_SIZE,
      withCount: true,
      //TODO: implement once the pager is implemented
      cursorSeconds: null,
      creator: true,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "network-only",
  });

  React.useEffect(() => {
    if (result.data) {
      RemotesStore.setRemoteTags(result.data.remoteTagsByCompanyId.tags);
      RemotesStore.updateCreators(result.data.issuersForRemoteByFields.creators);
    }
  }, [result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }

  if (appStatus !== "INITIALIZED") {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: RemoteInterviewSearchAreaContainerProps = {};

  return <RemoteInterviewSearchAreaContainer key={`active-${initialized}`} {...containerProps} />;
};

RemoteInterviewSearchAreaFetchContainer.displayName = "RemoteInterviewSearchAreaFetchContainer";

export default withErrorBoundary(RemoteInterviewSearchAreaFetchContainer, {});
