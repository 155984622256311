import * as Formatter from "@himenon/openapi-parameter-formatter";

import type { ApiClient, QueryParameters } from "../content-api";
import { ErrorResponse } from "../error/ErrorResponse";
import * as PrivateHelper from "./privateHelper";

export interface RequestOption {}

export const generateQueryString = (queryParameters: QueryParameters | undefined): string | undefined => {
  if (!queryParameters) {
    return undefined;
  }
  const queries = Object.entries(queryParameters).reduce<string[]>((queryStringList, [key, item]) => {
    if (!item.value) {
      return queryStringList;
    }
    if (!item.style) {
      return queryStringList.concat(`${key}=${item.value}`);
    }
    const result = Formatter.QueryParameter.generate(key, item as Formatter.QueryParameter.Parameter);
    if (result) {
      return queryStringList.concat(result);
    }
    return queryStringList;
  }, []);

  return queries.join("&");
};

export interface Params {
  authToken: string;
}

export const create = (params: Params): ApiClient<RequestOption> => {
  const { authToken } = params;
  const apiClientImpl: ApiClient<RequestOption> = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    request: async ({ httpMethod, url, headers, requestBody, queryParameters }): Promise<any> => {
      const query = generateQueryString(queryParameters);
      const requestUrl = query ? url + "?" + encodeURI(query) : url;
      const isMultipartFormData = headers["Content-Type"] === "multipart/form-data";
      const requestHeaders = {
        ...headers,
        Authorization: "Bearer " + authToken,
      };
      if (isMultipartFormData) {
        /**
         * @see https://datatracker.ietf.org/doc/html/rfc7578
         *
         * Since the boundary is set automatically, do not manually set the Content-Type.
         * multipart/form-data; boundary=----WebKitFormBoundary5d7oRctU0rzUNTwA
         */
        delete requestHeaders["Content-Type"];
      }

      const response = await fetch(requestUrl, {
        body: isMultipartFormData ? requestBody : JSON.stringify(requestBody),
        headers: requestHeaders,
        method: httpMethod,
      });

      if (400 <= response.status && response.status < 600) {
        throw new ErrorResponse(response);
      }

      if (requestHeaders["Accept"] === "application/pdf") {
        /**
         * Since application/pdf is a binary format, response.json() should not be used.
         * use response.body which is a ReadableStream<Uint8Array>.;
         */
        const reader = response.body?.getReader();
        if (!reader) {
          throw new Error("response.body is not a ReadableStream<Uint8Array>");
        }
        return await PrivateHelper.convertApplicationPdfReadableStreamReaderToBlob(reader);
      }
      return await response.json();
    },
  };
  return apiClientImpl;
};

export const fetcher = () => {};
