import SortIcon from "@mui/icons-material/Sort";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";

type FieldValue = string;

type Option = {
  displayName: string;
  value: FieldValue;
};

const StyledButton = styled(IconButton)(() => ({
  ".MuiButton-root": {
    height: 30,
  },
  fontSize: 13,
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&.MuiMenuItem-root": {
    "&.Mui-selected": {
      backgroundColor: theme.palette["Secondary/Shades"].p16,
    },
  },
}));

export type SortFieldProps = {
  options: Option[];
  disabled?: boolean;
  defaultValue?: FieldValue;
  onChange?: (value: FieldValue) => void;
};

const SortField: React.FC<SortFieldProps> = props => {
  const { onChange } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedValue, setSelectedValue] = React.useState<FieldValue>(props.defaultValue ?? props.options.at(0)?.value ?? "");
  const open = Boolean(anchorEl);
  const theme = useTheme();

  const handleButtonClick: Exclude<IconButtonProps["onClick"], undefined> = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const buttonProps: IconButtonProps = {
    children: <SortIcon sx={{ color: theme.palette.action.active }} />,
    onClick: handleButtonClick,
    disabled: props.disabled,
    size: "small",
    sx: {
      width: "32px",
      height: "32px",
    },
    "aria-controls": open ? "basic-menu" : undefined,
    "aria-haspopup": "true",
    "aria-expanded": open ? "true" : undefined,
  };

  const menuProps: MenuProps = {
    anchorEl: anchorEl,
    open: open,
    onClose: handleClose,
    MenuListProps: {
      "aria-labelledby": "basic-button",
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "right",
    },
  };

  return (
    <>
      <StyledButton {...buttonProps} />
      <Menu {...menuProps}>
        {props.options.map(option => (
          <StyledMenuItem
            selected={option.value === selectedValue}
            key={option.value}
            onClick={() => {
              onChange?.(option.value);
              setSelectedValue(option.value);
              setAnchorEl(null);
            }}
          >
            {option.displayName}
          </StyledMenuItem>
        ))}
      </Menu>
    </>
  );
};

SortField.displayName = "SortField";

export default SortField;
