import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";

import DropDownMenu, { DropDownMenuProps } from "../../primitive/DropDownMenu/DropDownMenu";
import Link, { LinkProps } from "../../primitive/Link/Link";
import BrandLogo, { BrandLogoProps } from "../../usecase/BrandLogo/BrandLogo";
import NotificationButton, { NotificationButtonProps } from "./parts/NotificationButton";

const StyledLink = styled(Link)`
  word-break: keep-all;
  white-space: nowrap;
`;

const StyledBox = styled(Box)({
  flexGrow: 1,
  height: "64px",
  maxHeight: "64px",
});

export type EmployeeNavigationProps = {
  brandLogo: Pick<BrandLogoProps, "onClick" | "href">;
  navigationLinks: LinkProps[];
  leftMenu: DropDownMenuProps;
  rightMenu: DropDownMenuProps;
  notificationButton: NotificationButtonProps;
  EmployeeMenu: React.ReactNode;
};

const EmployeeNavigation: React.FC<EmployeeNavigationProps> = props => {
  return (
    <StyledBox>
      <AppBar role="employeeheader" position="fixed" sx={{ backgroundColor: "background.default" }}>
        <Toolbar>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="nowrap">
              <Box display="flex" alignItems="center">
                <Box mr={1} display="flex" flexDirection="column" alignItems="center">
                  <BrandLogo {...props.brandLogo} />
                </Box>
              </Box>
              <Box mx={2}>
                <Box display="inherit" alignSelf="center">
                  <DropDownMenu {...props.leftMenu} />
                </Box>
              </Box>
            </Box>

            <Box display="flex" alignItems="center">
              {props.navigationLinks.length === 0 && <Skeleton width="48px" />}
              <Stack direction="row" spacing={4}>
                {props.navigationLinks.map((navigationLink, index) => (
                  <StyledLink key={`navigation-link-${index}`} {...navigationLink} underline="none" />
                ))}
              </Stack>

              <Box ml={4}>
                <Box display="inherit" right={2} mr={1} ml={2} alignSelf="center">
                  <DropDownMenu {...props.rightMenu} />
                </Box>
              </Box>
              <Box ml={2} mr={4}>
                <NotificationButton {...props.notificationButton} />
              </Box>
              {props.EmployeeMenu}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </StyledBox>
  );
};

EmployeeNavigation.displayName = "EmployeeNavigation";

export default EmployeeNavigation;
