import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Button, { type ButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import * as React from "react";

import BaseDialog, { BaseDialogProps } from "../../../primitive/BaseDialog/BaseDialog";

const StyledButton = styled(Button)`
  white-space: nowrap;
  word-break: keep-all;
  overflow: hidden;
  height: 30px;
`;

type ReTryStatus = "CHECKING_RETRY" | "ALREADY_EXISTS_RETRY" | "CAN_RETRY";

export type ExamRetryButtonDialogProps = {
  onOpenDialog: () => void;
  onCloseDialog: () => void;
  onRetry: () => void;
  onAlreadyExistsRetry: () => void;
  status: ReTryStatus;
  disabled?: boolean;
  forceClose?: boolean;
};

const ExamRetryButtonDialog: React.FC<ExamRetryButtonDialogProps> = props => {
  const { t } = useTranslation();
  const [openRetryDialog, setOpenRetryDialog] = React.useState(false);

  React.useEffect(() => {
    if (props.forceClose) {
      setOpenRetryDialog(false);
    }
  }, [props.forceClose]);

  const retryStatusTextMap = {
    CHECKING_RETRY: {
      title: t("再試験可能か確認しています。"),
      body: (
        <Box width="100%" textAlign="center" py={2}>
          <CircularProgress color="secondary" size={30} />
        </Box>
      ),
      yesButtonText: t("再試験する"),
    },
    ALREADY_EXISTS_RETRY: {
      title: t("すでに再試験中です。"),
      body: [
        t("すでに再試験が発行されています。"),
        t("すでに再試験が発行されている場合は試験が終了するまでは次の再試験は発行できません。"),
      ].join("\n"),
      yesButtonText: t("再試験を続行する"),
    },
    CAN_RETRY: {
      title: t("再試験しますか？"),
      body: [
        t("期間中は何度でも再試験が可能です。"),
        t("すでに再試験が発行されている場合は試験が終了するまでは次の再試験は発行できません。"),
      ].join("\n"),
      yesButtonText: t("再試験する"),
    },
  } satisfies Record<ReTryStatus, { title: string; body: React.ReactNode; yesButtonText: string } | undefined>;

  const retryText = retryStatusTextMap[props.status];

  const retryDialog: BaseDialogProps = {
    open: openRetryDialog,
    title: retryText.title,
    yesButton: {
      children: retryText.yesButtonText,
      color: "secondary",
      disabled: props.status === "CHECKING_RETRY" || props.disabled,
      onClick: () => {
        if (props.status === "ALREADY_EXISTS_RETRY") {
          props.onAlreadyExistsRetry();
        } else if (props.status === "CAN_RETRY") {
          props.onRetry();
        }
      },
    },
    noButton: {
      children: t("いいえ"),
      onClick: () => {
        props.onCloseDialog();
        setOpenRetryDialog(false);
      },
    },
  };

  const retryButton: ButtonProps = {
    onClick: event => {
      event.preventDefault();
      event.stopPropagation();
      props.onOpenDialog();
      setOpenRetryDialog(true);
    },
    variant: "outlined",
    color: "primary",
    children: t("再試験"),
  };

  return (
    <Box>
      <StyledButton {...retryButton} />
      <BaseDialog {...retryDialog}>{retryText.body}</BaseDialog>
    </Box>
  );
};

ExamRetryButtonDialog.displayName = "ExamRetryButtonDialog";

export default ExamRetryButtonDialog;
