import { useTranslation } from "@hireroo/i18n";
import type { ShareSettingsForm } from "@hireroo/validator";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import InputControlTextField, {
  InputControlTextFieldProps,
} from "../../../../primitive/InputControl/InputControlTextField/InputControlTextField";
import ShareSettingsItem, { ShareSettingsItemProps } from "./ShareSettingsItem";

export type FieldName = "showScore" | "showRank" | "showPlayback" | "showRelativeEvaluation" | "showReview";

export type ShareSettingsFieldsProps = {
  enabledFields: FieldName[];
};

const ShareSettingsFields: React.FC<ShareSettingsFieldsProps> = props => {
  const { t } = useTranslation();
  const method = useFormContext<ShareSettingsForm.ShareSettingsFormSchema>();
  const showScore = method.getValues("showScore");

  const items: ShareSettingsItemProps[] = [
    props.enabledFields.includes("showScore") && {
      name: "showScore",
      label: t("スコア"),
    },
    props.enabledFields.includes("showRank") && {
      name: "showRank",
      label: t("ランク"),
      disabled: !showScore,
      tooltipText: !showScore ? t("スコアの共有が有効な場合に利用できます。") : undefined,
    },
    props.enabledFields.includes("showRelativeEvaluation") && {
      name: "showRelativeEvaluation",
      label: t("相対評価"),
      disabled: !showScore,
      tooltipText: !showScore ? t("スコアの共有が有効な場合に利用できます。") : undefined,
    },
    // TODO: @shuheishintani will show answer in the report page after re-design
    // props.enabledFields.includes("showAnswer") && {
    //   name: "showAnswer",
    //   label: t("解答"),
    // },
    props.enabledFields.includes("showPlayback") && {
      name: "showPlayback",
      label: t("プレイバック"),
    },
    props.enabledFields.includes("showReview") && {
      name: "showReview",
      label: t("技術レビュー"),
    },
  ].flatMap(v => (v ? [v as ShareSettingsItemProps] : []));

  const periodSecondsFieldProps: InputControlTextFieldProps & { name: string } = {
    name: "periodDays",
    type: "number",
    size: "small",
    label: t("有効期間"),
    InputLabelProps: {
      shrink: true,
    },
    required: true,
  };

  return (
    <Box>
      <Typography variant={"body2"}>{t("共有する項目とリンクの有効期間を設定します")}</Typography>
      <Grid container spacing={2} alignItems={"center"} mt={1}>
        {items.map(item => (
          <ShareSettingsItem {...item} key={item.name} />
        ))}
      </Grid>
      <Grid item xs={6}>
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"} mt={2}>
          <Typography>{t("有効期間")}</Typography>
          <Box ml={2} />
          <InputControlTextField {...periodSecondsFieldProps} />
        </Box>
      </Grid>
    </Box>
  );
};

ShareSettingsFields.displayName = "ShareSettingsFields";

export default ShareSettingsFields;
