import { App } from "@hireroo/app-store/essential/employee";
import { InterviewFeedbackStore } from "@hireroo/app-store/view-domain/InterviewFeedback";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import ScreeningTestFeedbackContainer, { ScreeningTestFeedbackContainerProps } from "./Container";
import * as Subscriber from "./Subscriber";

export type ScreeningTestFeedbackFetchContainerProps = {
  interviewId: string;
};

const ScreeningTestFeedbackFetchContainer: React.FC<ScreeningTestFeedbackFetchContainerProps> = props => {
  const appStatus = App.useStatus();
  const initialized = InterviewFeedbackStore.useInitialized();

  React.useEffect(() => {
    const stop = Subscriber.startSubscribeReviewedSpot();
    return () => {
      stop();
      InterviewFeedbackStore.clear();
    };
  }, []);
  const [result, refresh] = Graphql.useGetInterviewFeedbackQuery({
    variables: {
      id: props.interviewId,
      withAnswers: true,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "cache-and-network",
  });
  React.useEffect(() => {
    if (result.data?.spot) {
      InterviewFeedbackStore.updateInterview(result.data.spot);
    }
  }, [result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="TOP" />;
  }

  const containerProps: ScreeningTestFeedbackContainerProps = {};
  return (
    <ErrorBoundary>
      <ScreeningTestFeedbackContainer {...containerProps} />
    </ErrorBoundary>
  );
};

ScreeningTestFeedbackFetchContainer.displayName = "ScreeningTestFeedbackFetchContainer";

export default withErrorBoundary(ScreeningTestFeedbackFetchContainer, {});
