import { App, Company } from "@hireroo/app-store/essential/employee";
import { RemotesId } from "@hireroo/app-store/page/e/remotes_id";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import RemoteInterviewEvaluationContainer, { RemoteInterviewEvaluationContainerProps } from "./Container";

const LIMIT_SIZE = 100;

export type RemoteInterviewEvaluationFetchContainerProps = {
  uniqueKey: string;
};

const RemoteInterviewEvaluationFetchContainer: React.FC<RemoteInterviewEvaluationFetchContainerProps> = props => {
  const appStatus = App.useStatus();
  const activeCompanyId = Company.useActiveCompanyId();
  const [result, refresh] = Graphql.useListEvaluationMetricsForRemotesIdQuery({
    // TODO: Please use pagination. now we get all of metrics because in most cases employee has under 100 metrics.
    variables: {
      companyId: activeCompanyId || 0,
      size: LIMIT_SIZE,
      withCount: true,
      cursor: null,
    },
    pause: appStatus !== "INITIALIZED",
  });
  React.useEffect(() => {
    if (result.data?.evaluationMetricsByCompany) {
      RemotesId.addMetrics(result.data.evaluationMetricsByCompany.metrics);
      RemotesId.updateMetricPagination({
        nextCursor: result.data.evaluationMetricsByCompany.pager?.cursor || null,
        hasNext: result.data.evaluationMetricsByCompany.pager?.hasNext || false,
      });
    }
  }, [props.uniqueKey, result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED") {
    return <Widget.Loading kind="TOP" color="secondary" />;
  }

  const containerProps: RemoteInterviewEvaluationContainerProps = {
    remoteId: props.uniqueKey,
  };
  return (
    <ErrorBoundary>
      <RemoteInterviewEvaluationContainer {...containerProps} />
    </ErrorBoundary>
  );
};

RemoteInterviewEvaluationFetchContainer.displayName = "RemoteInterviewEvaluationFetchContainer";

export default withErrorBoundary(RemoteInterviewEvaluationFetchContainer, {});
