import { extractEntityFromEncodedString } from "@hireroo/app-helper/entity";
import { App } from "@hireroo/app-store/essential/employee";
import { ProjectCodingEditorV3 } from "@hireroo/app-store/widget/shared/ProjectCodingEditorV3";
import { TestReview } from "@hireroo/app-store/widget/shared/TestReview";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

import ErrorPanelContainer from "../../../../../../widget/v2/e/ErrorPanel/Container";
import TestReviewContainer from "../../../../../../widget/v2/shared/TestReview/Container";

export type TestReviewFetchContainerProps = {};

const TestReviewFetchContainer: React.FC<TestReviewFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const submissionId = searchParams.get("submission_id");
  const base64Id = searchParams.get("id") ?? "";
  const [result] = Graphql.useGetSpotForEmployeeInterviewsIdReviewQuery({
    variables: {
      id: `${id}`,
      withAnswers: false,
    },
    pause: appStatus !== "INITIALIZED",
  });
  const initialized = TestReview.useInitialized();
  React.useEffect(() => {
    const parsedValue = extractEntityFromEncodedString(base64Id);
    if (!parsedValue.entityType) {
      return;
    }
    TestReview.setReviewTarget({
      entityType: parsedValue.entityType,
      entityId: parsedValue.entityId,
      submissionId: Number(submissionId),
    });
  }, [base64Id, submissionId]);
  React.useEffect(() => {
    if (result.data) {
      TestReview.setEntities(result.data.spot.entities);
      result.data.spot.entities.forEach(entity => {
        switch (entity.__typename) {
          case "ProjectEntity": {
            ProjectCodingEditorV3.initialize(entity);
            break;
          }
        }
      });
    }
  }, [result.data]);

  if (result.error) {
    return <ErrorPanelContainer graphqlErrors={result.error.graphQLErrors} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER" />;
  }
  return <TestReviewContainer />;
};

TestReviewFetchContainer.displayName = "TestReviewFetchContainer";

export default withErrorBoundary(TestReviewFetchContainer, {});
