import type { Widget } from "@hireroo/presentation";

export type GenerateTerminalPropsArgs = {
  ready: boolean;
  endpoint: string;
  runTestCaseButton: Widget.ProjectCodingEditorV3Props["terminal"]["runTestCaseButton"];
  submitButton: Widget.ProjectCodingEditorV3Props["terminal"]["submitButton"];
  showConsole: boolean;
};

export const useGenerateTerminalProps = (args: GenerateTerminalPropsArgs): Widget.ProjectCodingEditorV3Props["terminal"] => {
  const { ready, endpoint } = args;
  return {
    console: args.showConsole
      ? {
          endpoint: ready ? endpoint : undefined,
          loading: !ready,
        }
      : undefined,
    runTestCaseButton: args.runTestCaseButton,
    submitButton: args.submitButton,
  };
};
