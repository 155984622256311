import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { AssessmentResourceEditorForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import ResourceField from "../../../../modules/ResourceFieldV2/ResourceFieldV2";
import ToggleResourceField, { ToggleResourceFieldProps } from "../../../../modules/ToggleResourceField/ToggleResourceField";
import DateTimeControl, { DateTimeControlProps } from "../../../../primitive/InputControl/DateTimeControl/DateTimeControl";
import InputControlTextField, {
  InputControlTextFieldProps,
} from "../../../../primitive/InputControl/InputControlTextField/InputControlTextField";
import SelectControl, { SelectControlProps } from "../../../../primitive/InputControl/SelectControl/SelectControl";
import { type LayoutController, useAssessmentResourceEditorV2Context } from "../../Context";

type FieldName = keyof AssessmentResourceEditorForm.TestSetupFormSchema;

const ReservedFieldName = {
  NEXT_START_SCHEDULE_AT: "nextStartScheduleAt",
  EXAM_INTERVAL: "examInterval",
  REMIND: "remindBeforeDays",
} satisfies Record<string, FieldName>;

const StyledDateTimeControl = styled(DateTimeControl)(() => ({
  ".MuiOutlinedInput-input": {
    paddingLeft: 12,
    paddingRight: 12,
  },
}));

const StyledToggleResourceField = styled(ToggleResourceField)(() => ({
  alignItems: "center",
}));

type ExamIntervalMenuItemProps = {
  label: string;
  value: AssessmentResourceEditorForm.ExamIntervalSchema;
};
const INPUT_CONTROL_WIDTH = 220;
const RemindFieldBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  word-break: keep-all;
  white-space: nowrap;
`;

const Subsection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  boxShadow: "none",
}));

export type TestSetupSectionProps = {
  onSubmit: SubmitHandler<AssessmentResourceEditorForm.TestSetupFormSchema>;
  defaultValues: AssessmentResourceEditorForm.TestSetupFormSchema;
  disableFields: Record<FieldName, boolean>;
  mode: "CREATE" | "EDIT";
};

const TestSetupSection: React.FC<TestSetupSectionProps> = props => {
  const { t } = useTranslation();
  const { onSubmit } = props;

  const { stepName, setController, submitValues, setSubmitValue } = useAssessmentResourceEditorV2Context();
  const validateSchema = AssessmentResourceEditorForm.useTestSetupForm({
    mode: props.mode,
    defaultNextScheduledAt: props.defaultValues.nextStartScheduleAt,
  });
  const methods = useForm<AssessmentResourceEditorForm.TestSetupFormSchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: submitValues.TEST_SETUP ?? props.defaultValues,
  });
  const lang = useLanguageCode();
  const [defaultNextStartScheduleAt] = React.useState(methods.getValues("nextStartScheduleAt"));
  const disabledDatePicker = React.useMemo((): boolean => {
    if (props.mode === "CREATE") {
      return false;
    }
    const now = new Date();
    const UNEDITABLE_TIME_MILLISECONDS = AssessmentResourceEditorForm.UNEDITABLE_NEXT_START_SCHEDULE_DATE_FIELD_TIME_MINUTES * 60 * 1000;
    if (defaultNextStartScheduleAt.getTime() - now.getTime() <= UNEDITABLE_TIME_MILLISECONDS) {
      return true;
    }
    return false;
  }, [defaultNextStartScheduleAt, props.mode]);

  const controller = React.useMemo((): LayoutController => {
    return {
      checkGoNextStep: () => {
        return new Promise<boolean>(resolve => {
          methods.handleSubmit(
            fields => {
              onSubmit(fields);
              setSubmitValue("TEST_SETUP", fields);
              resolve(true);
            },
            errors => {
              console.warn(errors);
              resolve(false);
              setSubmitValue("TEST_SETUP", undefined);
            },
          )();
        });
      },
    };
  }, [methods, onSubmit, setSubmitValue]);

  React.useEffect(() => {
    setController("TEST_SETUP", controller);
  }, [setController, controller]);

  if (stepName !== "TEST_SETUP") {
    return null;
  }

  const nextStartDateFieldProps: DateTimeControlProps = {
    dateTimePicker: {
      filterDateFn: date => {
        const min = new Date();
        min.setHours(0);
        min.setMinutes(0);
        min.setSeconds(0);
        min.setMilliseconds(0);
        const okMin = date.getTime() >= min.getTime();
        const day = date.getDate(); // 1 ~ 31
        const examInterval = methods.getValues("examInterval");
        const invalidDays = AssessmentResourceEditorForm.InvalidNextStartScheduleDateMap[examInterval];
        const okMax = !invalidDays.includes(day);
        return okMin && okMax;
      },
      filterTimeFn: date => {
        const now = new Date();
        const bufferTimeMilliSeconds = AssessmentResourceEditorForm.UNEDITABLE_NEXT_START_SCHEDULE_DATE_FIELD_TIME_MINUTES * 60 * 1000;
        return date.getTime() >= now.getTime() + bufferTimeMilliSeconds;
      },
      lang,
      customInput: {
        id: ReservedFieldName.NEXT_START_SCHEDULE_AT,
        name: ReservedFieldName.NEXT_START_SCHEDULE_AT,
        color: "secondary",
        required: true,
        InputLabelProps: {
          shrink: true,
        },
        InputProps: {
          /** They only let you choose every 15 minutes. */
          readOnly: true,
          sx: {
            width: `${INPUT_CONTROL_WIDTH}px`,
          },
        },
        variant: "outlined",
      },
      datePicker: {
        disabled: disabledDatePicker,
        required: true,
        placeholderText: t("指定なし"),
        timeInputLabel: t("時間"),
        showTimeSelect: true,
        timeFormat: "p",
      },
    },
  };

  const examIntervalProps: Omit<SelectControlProps, "menuItems"> & { menuItems: ExamIntervalMenuItemProps[] } = {
    color: "secondary",
    menuItems: [
      {
        label: t("1週間"),
        value: "ONE_WEEK",
      },
      {
        label: t("2週間"),
        value: "TWO_WEEKS",
      },
      {
        label: t("1ヶ月"),
        value: "ONE_MONTH",
      },
      {
        label: t("2ヶ月"),
        value: "TWO_MONTHS",
      },
      {
        label: t("3ヶ月"),
        value: "THREE_MONTHS",
      },
    ],
  };

  const remindFieldProps: InputControlTextFieldProps = {
    type: "number",
    sx: {
      marginRight: 1,
      width: 70,
    },
    inputProps: {
      min: 1,
    },
  };

  const remindOptionalResourceField: ToggleResourceFieldProps = {
    toggleKind: "SWITCH",
    label: t("リマインド"),
    defaultOpen: !Number.isNaN(methods.getValues("remindBeforeDays")),
    onDiscard: () => {
      methods.setValue("remindBeforeDays", NaN);
    },
    disabled: props.disableFields.remindBeforeDays,
  };

  return (
    <FormProvider {...methods}>
      <Stack py={2} spacing={1.5}>
        <Subsection>
          <Stack direction="column" spacing={4}>
            <ResourceField
              label={t("次の試験作成予定")}
              kind="NONE"
              help={{
                kind: "TOOLTIP",
                text:
                  props.mode === "CREATE"
                    ? t("最初に開始する試験の日時を指定できます。")
                    : [
                        t("次に開始する試験の日時を指定できます。"),
                        t("現在の編集時刻から15分以内に試験作成予定の場合、この項目は編集できません。"),
                      ].join(""),
              }}
            >
              <StyledDateTimeControl name={ReservedFieldName.NEXT_START_SCHEDULE_AT} {...nextStartDateFieldProps} />
            </ResourceField>

            <ResourceField label={t("実施頻度")} kind="NONE" help={{ kind: "TOOLTIP", text: t("試験の発行頻度を指定できます。") }}>
              <SelectControl
                name={ReservedFieldName.EXAM_INTERVAL}
                {...examIntervalProps}
                select={{ fullWidth: false, sx: { width: `${INPUT_CONTROL_WIDTH}px` } }}
              />
            </ResourceField>
            <ResourceField
              label={t("通知設定")}
              kind="NONE"
              help={{
                kind: "DESCRIPTION",
                text: [t("試験の期日から指定した日数だけ前に通知を送信します。"), t("試験の実施頻度よりも小さい値が指定できます。")].join(""),
              }}
            >
              <StyledToggleResourceField {...remindOptionalResourceField}>
                <RemindFieldBox>
                  <InputControlTextField name={ReservedFieldName.REMIND} {...remindFieldProps} />
                  {t("日前")}
                </RemindFieldBox>
              </StyledToggleResourceField>
            </ResourceField>
          </Stack>
        </Subsection>
      </Stack>
    </FormProvider>
  );
};

TestSetupSection.displayName = "TestSetupSection";

export default TestSetupSection;
