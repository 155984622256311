import React from "react";

export const usePagination = <T>(items: T[], size: number) => {
  const [currentPage, setCurrentPage] = React.useState(1); // Pages are 1 indexed

  const totalPages = Math.ceil(items.length / size);
  const isBeyondLastPage = currentPage > totalPages;

  /**
   * TODO Separate the flags so you don't have to use useEffect.
   */
  React.useEffect(() => {
    if (isBeyondLastPage) {
      setCurrentPage(totalPages);
    }
  }, [isBeyondLastPage, totalPages]);

  const paginated = items.slice((currentPage - 1) * size, currentPage * size);

  return { paginated, setCurrentPage, currentPage, totalPages };
};
