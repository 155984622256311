import { QuestionQuizPackageResourceEditor } from "@hireroo/app-store/view-domain/QuestionQuizPackageResourceEditor";
import { QuizPackageDetail } from "@hireroo/app-store/widget/e/QuizPackageDetail";
import { SearchQuizQuestion } from "@hireroo/app-store/widget/e/SearchQuizQuestion";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import FetchContainer from "./FetchContainer";
import MiddleContainer from "./MiddleContainer";
import * as Subscriber from "./Subscriber";

export type QuizResourceEditorInitialContainerProps = {};

const QuizResourceEditorInitialContainer: React.FC<QuizResourceEditorInitialContainerProps> = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id") ? Number(searchParams.get("id")) : null;
  React.useEffect(() => {
    const stopSubscribeSelectedQuestions = Subscriber.startSubscribeSelectedQuestions();
    QuestionQuizPackageResourceEditor.clear();
    QuizPackageDetail.clear();
    SearchQuizQuestion.clear();
    return () => {
      stopSubscribeSelectedQuestions();
    };
  }, []);
  return <ErrorBoundary>{id ? <FetchContainer id={id} /> : <MiddleContainer />}</ErrorBoundary>;
};

QuizResourceEditorInitialContainer.displayName = "QuizResourceEditorInitialContainer";

export default withErrorBoundary(QuizResourceEditorInitialContainer, {});
