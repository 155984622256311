import { App } from "@hireroo/app-store/essential/employee";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";
import { useParams } from "react-router-dom";

import ErrorPanelContainer from "../../../../widget/v2/e/ErrorPanel/Container";
import RemotesEvaluateContainer, { RemotesEvaluateContainerProps } from "./Container";

const RemoteInterviewForRemotesEvaluateFetchContainer: React.FC = () => {
  const appStatus = App.useStatus();
  const { id: remoteId } = useParams();
  const [result] = Graphql.useGetRemotesForRemotesEvaluateQuery({
    variables: {
      remoteId: remoteId ?? "",
    },
    requestPolicy: "network-only",
    pause: appStatus !== "INITIALIZED" || !remoteId,
  });

  if (result.error) {
    return <ErrorPanelContainer graphqlErrors={result.error.graphQLErrors} />;
  }

  if (result.fetching || appStatus !== "INITIALIZED" || !remoteId) {
    return <Widget.Loading kind="CENTER" />;
  }
  const containerProps: RemotesEvaluateContainerProps = {
    remoteId: remoteId,
  };
  return (
    <ErrorBoundary>
      <RemotesEvaluateContainer {...containerProps} />
    </ErrorBoundary>
  );
};

RemoteInterviewForRemotesEvaluateFetchContainer.displayName = "RemoteInterviewForRemotesEvaluateFetchContainer";

export default withErrorBoundary(RemoteInterviewForRemotesEvaluateFetchContainer, {});
