import { ChallengeTestReport } from "@hireroo/app-store/view-domain/ChallengeTestReport";
import { TotalScoreRankVisualizer } from "@hireroo/app-store/widget/shared/TotalScoreRankVisualizer";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import { useGenerateHeaderProps } from "../../../../props-factory/v2/view-domain/ChallengeTestReport/useGenerateHeaderProps";
import { useGenerateQuestionDetailProps } from "../../../../props-factory/v2/view-domain/ChallengeTestReport/useGenerateQuestionDetailProps";
import ChallengeTestReportMiddleContainer, { ChallengeTestReportMiddleContainerProps } from "./MiddleContainer";
import { useStatusMessageMap } from "./privateHelper";
import { startSubscribeQueryKey } from "./Subscriber";

export type ChallengeTestReportFetchContainerProps = {
  featureKind: "test" | "exam";
  companyId: number;
  challengeId: number;
  canShowPasteAndTabStatistics: boolean;
  canShowCheatDetectionSection: boolean;
  reportSettings: Graphql.AssessmentReportSettings | null;
};

const ChallengeTestReportFetchContainer: React.FC<ChallengeTestReportFetchContainerProps> = props => {
  //TODO: @asaki15 implement get challenge when server side JoinForSpot is removed
  const { challengeId, companyId } = props;
  const initialized = ChallengeTestReport.useInitialized(challengeId);
  const challengeHooks = ChallengeTestReport.useCreateChallengeHooks(challengeId);
  const challenge = challengeHooks.useChallenge();
  const currentSubmission = challengeHooks.useCurrentSubmission();
  const messageMap = useStatusMessageMap();
  const showNotStartedMessage = challenge.challengeStatus === "CREATED" || challenge.challengeStatus === "STARTED";
  const header = useGenerateHeaderProps({ challengeId: challengeId, showDetail: true });
  const questionDetail = useGenerateQuestionDetailProps({ challengeId: challengeId, showArchivedMark: false });

  const uniqueKey = React.useMemo((): ChallengeTestReport.UniqueKey | null => {
    if (!currentSubmission) {
      return null;
    }
    /**
     * Multiple submissions and unique by EntityId
     */
    return `Challenge-${challengeId}-${currentSubmission.challengeSubmissionId}`;
  }, [currentSubmission, challengeId]);

  React.useEffect(() => {
    if (!currentSubmission || !initialized || !uniqueKey || showNotStartedMessage) {
      return;
    }
    TotalScoreRankVisualizer.createTotalScoreStatisticsState({
      uniqueKey: uniqueKey,
      query: {
        endDate: currentSubmission?.evaluatedAtSeconds ? new Date(currentSubmission.evaluatedAtSeconds * 1000) : null,
      },
    });
    const stop = startSubscribeQueryKey({
      uniqueKey,
      challengeId: challengeId,
      companyId: companyId,
      submissionId: currentSubmission.challengeSubmissionId,
      questionId: currentSubmission.questionId,
      questionVersion: currentSubmission.questionVersion,
    });
    TotalScoreRankVisualizer.refreshQuery(uniqueKey);
    return () => {
      stop?.();
    };
  }, [showNotStartedMessage, challengeId, companyId, currentSubmission, initialized, uniqueKey]);

  if (!challenge) {
    return <Widget.Loading kind="TOP" />;
  }

  if (!currentSubmission || !uniqueKey || showNotStartedMessage) {
    const challengeTestReportProps: Widget.ChallengeTestReportProps = {
      header: header,
      questionDetail: questionDetail,
      announcement: messageMap[challenge.challengeStatus],
    };
    return <Widget.ChallengeTestReport {...challengeTestReportProps} />;
  }

  const containerProps: ChallengeTestReportMiddleContainerProps = {
    featureKind: props.featureKind,
    challengeId: props.challengeId,
    uniqueKey,
    canShowPasteAndTabStatistics: props.canShowPasteAndTabStatistics,
    canShowCheatDetectionSection: props.canShowCheatDetectionSection,
    reportSettings: props.reportSettings,
  };
  return (
    <ErrorBoundary>
      <ChallengeTestReportMiddleContainer {...containerProps} />
    </ErrorBoundary>
  );
};

ChallengeTestReportFetchContainer.displayName = "ChallengeTestReportFetchContainer";

export default withErrorBoundary(ChallengeTestReportFetchContainer, {});
