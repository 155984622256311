import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled, useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

import TimeTrackerReport, { TimeTrackerReportProps } from "../../modules/TimeTrackerReport/TimeTrackerReport";

const Wrapper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  width: 384,
  height: 236,
  display: "flex",
  flexDirection: "column",
}));

const NoDataLabel = styled(Typography)(({ theme }) => ({
  display: "flex",
  height: "100%",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  fontSize: 24,
  color: theme.palette.text.secondary,
  fontWeight: 700,
}));

const StyledTableCellHeader = styled(TableCell)(({ theme }) => ({
  fontSize: "10px",
  color: theme.palette.text.disabled,
  padding: 0,
  border: 0,
}));

const StyledTableCellBodyHeader = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1),
  borderColor: theme.palette.Other.Divider,
  "&:hover": {
    cursor: "pointer",
    color: theme.palette.secondary.main,
  },
}));

const StyledTableCellBodyData = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1),
  borderColor: theme.palette.Other.Divider,
  color: theme.palette.text.secondary,
}));

const StyledTable = styled(Table)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  overflow: "hidden",
  height: "100%",
  width: "100%",
}));

const StyledTableBody = styled(TableBody)(() => ({
  height: "100%",
  overflow: "auto",
}));

type IconProps = {
  title: string;
};

type ItemProps = {
  name: string;
  description: string;
  onClick: () => void;
};

export type ActivityTimeBarChartPanelProps = {
  title: string;
  icon?: IconProps;
  timeTrackerReport: TimeTrackerReportProps;
  items: ItemProps[];
  timeFieldLabel: string;
};

const ActivityTimeBarChartPanel: React.FC<ActivityTimeBarChartPanelProps> = props => {
  const { timeTrackerReport, items, icon } = props;
  const theme = useTheme();

  return (
    <Wrapper>
      <Box mb={2} display="flex" alignItems="center">
        <Box>
          <Typography variant="subtitle1">{props.title}</Typography>
        </Box>
        {icon && (
          <Box ml={0.5} display="flex" alignItems="center">
            <Tooltip title={icon.title}>
              <InfoOutlinedIcon sx={{ fontSize: "14px", color: theme.palette.text.secondary }} />
            </Tooltip>
          </Box>
        )}
      </Box>
      {items.length === 0 && <NoDataLabel variant="subtitle1">NO DATA</NoDataLabel>}
      {items.length !== 0 && (
        <>
          <Box mb={2}>
            <TimeTrackerReport {...timeTrackerReport} />
          </Box>
          <TableContainer>
            <StyledTable>
              <TableHead>
                <TableRow sx={{ border: 0 }}>
                  <StyledTableCellHeader></StyledTableCellHeader>
                  <StyledTableCellHeader align="right">{props.timeFieldLabel}</StyledTableCellHeader>
                </TableRow>
              </TableHead>
              <StyledTableBody>
                {items.map(item => (
                  <TableRow key={item.name}>
                    <StyledTableCellBodyHeader component="th" scope="row" onClick={item.onClick}>
                      {item.name}
                    </StyledTableCellBodyHeader>
                    <StyledTableCellBodyData align="right">{item.description}</StyledTableCellBodyData>
                  </TableRow>
                ))}
              </StyledTableBody>
            </StyledTable>
          </TableContainer>
        </>
      )}
    </Wrapper>
  );
};

ActivityTimeBarChartPanel.displayName = "ActivityTimeBarChartPanel";

export default ActivityTimeBarChartPanel;
