import * as React from "react";
import { LocalAudioTrack } from "twilio-video";

/**
 * This hook set up a listener for devicechange events and restart the audio track if the device is disconnected.
 */
export const useRestartAudioTrackOnDeviceChange = (audioTrack?: LocalAudioTrack) => {
  React.useEffect(() => {
    if (!audioTrack) return;

    const handleDeviceChange = () => {
      if (audioTrack?.mediaStreamTrack.readyState === "ended") {
        void audioTrack.restart({});
      }
    };

    navigator.mediaDevices.addEventListener("devicechange", handleDeviceChange);

    return () => {
      navigator.mediaDevices.removeEventListener("devicechange", handleDeviceChange);
    };
  }, [audioTrack]);
};
