import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type SingleSignOnContainerProps = {};

const SingleSignOnContainer: React.FC<SingleSignOnContainerProps> = () => {
  const singleSignOnProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.SingleSignOn {...singleSignOnProps} />
    </ErrorBoundary>
  );
};

SingleSignOnContainer.displayName = "SingleSignOnContainer";

export default withErrorBoundary(SingleSignOnContainer, {});
