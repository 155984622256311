import { useTranslation } from "@hireroo/i18n";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import * as React from "react";

import BaseDialog, { BaseDialogProps } from "../../../../primitive/BaseDialog/BaseDialog";

export type CreateDialogProps = Pick<BaseDialogProps, "open" | "yesButton" | "noButton"> & {
  batch: {
    title: string;
    description?: string;
  };
};

const CreateDialog: React.FC<CreateDialogProps> = props => {
  const { t } = useTranslation();
  const createDialog: BaseDialogProps = {
    open: props.open,
    title: t("sonar ATSトークンをコーディングテストを元に生成します"),
    yesButton: {
      ...props.yesButton,
      children: t("ATSトークンを作成"),
    },
    noButton: {
      ...props.noButton,
      children: t("キャンセル"),
    },
    optionalDialog: {
      disableEnforceFocus: true,
    },
  };
  return (
    <BaseDialog {...createDialog}>
      <DialogContent role="content" sx={{ p: 0 }}>
        <Typography variant="subtitle2" mt={1} color="textSecondary">
          {t("コーディングテスト名")}
        </Typography>
        <Typography>{props.batch.title}</Typography>
        {props.batch.description && (
          <>
            <Typography variant="subtitle2" mt={1} color="textSecondary">
              {t("コーディングテストの説明")}
            </Typography>
            <Typography>{props.batch.description}</Typography>
          </>
        )}
      </DialogContent>
    </BaseDialog>
  );
};

CreateDialog.displayName = "CreateDialog";

export default CreateDialog;
