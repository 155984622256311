import { useTranslation } from "@hireroo/i18n";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DetailContentItem, { DetailContentItemProps } from "../DetailContentItem/DetailContentItem";

const StyledUnitTypography = styled(Typography)`
  display: flex;
  align-items: flex-end;
  font-size: 14px;
`;

const NONE_VALUE = "-";

export type QuestionStatsProps = {
  /**
   * average correct rate 0 to 100
   */
  avgCorrectIntegerRate?: { kind: "VALUE"; value: number } | { kind: "NONE" };
  numOfUsage: string;
  avgElapseTime:
    | {
        kind: "VALUE";
        minutes: number;
        seconds: number;
      }
    | {
        kind: "NONE";
      };
};

const QuestionStats: React.FC<QuestionStatsProps> = props => {
  const theme = useTheme();
  const { t } = useTranslation();
  const items: (DetailContentItemProps | false)[] = [
    !!props.avgCorrectIntegerRate && {
      id: "correct-rate",
      title: t("平均スコア"),
      Content: (
        <Stack direction="row">
          {props.avgCorrectIntegerRate.kind === "NONE" && (
            <Typography fontSize={24} fontWeight={700}>
              {NONE_VALUE}
            </Typography>
          )}
          {props.avgCorrectIntegerRate.kind === "VALUE" && (
            <>
              <Typography fontSize={24} fontWeight={700}>
                {props.avgCorrectIntegerRate.kind === "VALUE" && props.avgCorrectIntegerRate.value}
              </Typography>
              <StyledUnitTypography color="textSecondary" ml={0.5}>
                %
              </StyledUnitTypography>
            </>
          )}
        </Stack>
      ),
    },
    {
      id: "num-of-usage",
      title: t("累計利用数"),
      Content: (
        <Stack direction="row">
          <Typography fontSize={24} fontWeight={700}>
            {props.numOfUsage}
          </Typography>
          <StyledUnitTypography color="textSecondary" ml={0.5}>
            {t("回")}
          </StyledUnitTypography>
        </Stack>
      ),
    },
    {
      id: "avg-elapse-time",
      title: t("平均解答時間"),
      Content: (
        <Stack direction="row">
          {props.avgElapseTime.kind === "NONE" && (
            <Typography fontSize={24} fontWeight={700}>
              {NONE_VALUE}
            </Typography>
          )}
          {props.avgElapseTime.kind === "VALUE" && (
            <>
              <Typography fontSize={24} fontWeight={700}>
                {props.avgElapseTime.minutes}
              </Typography>
              <StyledUnitTypography color="textSecondary" mx={0.5}>
                {t("分")}
              </StyledUnitTypography>
              <Typography fontSize={24} fontWeight={700}>
                {props.avgElapseTime.seconds}
              </Typography>
              <StyledUnitTypography color="textSecondary" mx={0.5}>
                {t("秒")}
              </StyledUnitTypography>
            </>
          )}
        </Stack>
      ),
    },
  ];

  return (
    <Stack>
      <Grid container mt={0}>
        {items
          .flatMap(v => (v ? [v] : []))
          .map((item, index) => (
            <Grid
              key={item.id}
              item
              xs={6}
              mb={3}
              pt={0}
              pl={2}
              borderRight={index % 2 === 0 && index !== items.length - 1 ? "1px solid" : undefined}
              borderColor={theme.palette.divider}
            >
              <DetailContentItem {...item} />
            </Grid>
          ))}
      </Grid>
    </Stack>
  );
};

QuestionStats.displayName = "QuestionStats";

export default QuestionStats;
