import { proxy } from "valtio";

import type { State } from "./types";

export const state = proxy<State>({
  security: {
    ssoEnabled: false,
    ssoMode: "UNKNOWN",
    idpSsoUrl: "",
    idpEntityId: "",
    idpCertificate: "",
    emailDomain: "",
    providerId: "",
  },
});
