import { composeTextOperationV2, invertTextOperation, revisionToId } from "@hireroo/app-helper/firepad";
import { applyOperation, IStandaloneCodeEditor, ITextModel, useMonaco } from "@hireroo/app-helper/monaco";
import {
  CodeEditorInputEvent,
  createPasteEventMap,
  decoratePlaybackText,
  HistoryPasteMap,
  PasteEventMap,
  PasteRangeMap,
} from "@hireroo/app-helper/playback";
import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import { useCursorWidgetManager } from "@hireroo/code-editor/extensions";
import { getCursorRange, getLanguageFromFile } from "@hireroo/code-editor/helpers/monaco";
import * as Time from "@hireroo/formatter/time";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

import { useGenerateFileTreeProps } from "./useGenerateFileTreeProps";
import ProjectPlaybackSettingsMenuContainer from "./widgets/ProjectPlaybackSettingsMenu/Container";
import ScreeningTestActivityLogContainer from "./widgets/ScreeningTestActivityLog/Container";

const { ProjectContentsViewerV4 } = ProjectTestReport;

type Mark = Exclude<Widget.ProjectContentsViewerV4Props["toolbar"]["slider"]["marks"], undefined | boolean>[0];
type Status = Widget.ProjectContentsViewerV4Props["status"];

/**
 * TODO @Himenon Make the colors change according to the Code Editor theme
 */
const CURSOR_COLOR = "#838DFF";

export type GenerateProjectContentsViewerV4PropsArgs = {
  projectId: number;
  canShowPasteAndTabStatistics: boolean;
};

export const useGenerateProps = (args: GenerateProjectContentsViewerV4PropsArgs): Widget.ProjectContentsViewerV4Props => {
  const { t } = useTranslation();
  const monacoEditorRef = React.useRef<IStandaloneCodeEditor[]>([]);

  const monaco = useMonaco();
  const projectHooks = ProjectTestReport.useCreateProjectHooks(args.projectId);
  const question = projectHooks.useQuestion();
  const lang = useLanguageCode();
  const appealMessage = projectHooks.useAppealMessage();
  const sliderValue = ProjectContentsViewerV4.useSliderValue();
  const fileTree = useGenerateFileTreeProps(args);
  const playbackManager = ProjectContentsViewerV4.useCurrentPlaybackManager();
  const codeEditorInputEvents = ProjectContentsViewerV4.useCodeEditorInputEvents();
  const lastCodeEditorInputEventsIndex = ProjectContentsViewerV4.useLastCodeEditorInputEventsIndex();
  const cursorWidgetManager = useCursorWidgetManager();
  const selectedFileIndex = ProjectContentsViewerV4.useSelectedFileIndex();
  const monacoEditorLanguage = getLanguageFromFile(selectedFileIndex);
  const playbackSettings = ProjectContentsViewerV4.usePlaybackSettings();
  const clipboardEvents = ProjectContentsViewerV4.useClipboardEvents();

  const [passedTime, setPassedTime] = React.useState("");

  const createModel = React.useCallback(() => {
    const defaultValue = composeTextOperationV2(lastCodeEditorInputEventsIndex, codeEditorInputEvents);

    if (!monaco) return null;

    return monaco.editor.createModel(defaultValue, monacoEditorLanguage);
  }, [codeEditorInputEvents, lastCodeEditorInputEventsIndex, monaco, monacoEditorLanguage]);

  const modelForDefaultScreen = React.useMemo(() => {
    return createModel();
  }, [createModel]);

  const modelForFullScreen = React.useMemo(() => {
    return createModel();
  }, [createModel]);

  const monacoEditorModels = React.useMemo((): ITextModel[] => {
    if (modelForDefaultScreen && modelForFullScreen) {
      return [modelForDefaultScreen, modelForFullScreen];
    }
    return [];
  }, [modelForDefaultScreen, modelForFullScreen]);

  const handleChangeSliderValue = React.useCallback(
    (value: number, isTouchedPlay?: boolean) => {
      ProjectContentsViewerV4.updateSliderValue(value);
      if (!isTouchedPlay) {
        playbackManager.setTickIndex(value);
      }
    },
    [playbackManager],
  );

  const remainTime = React.useMemo(() => {
    const endTime = playbackManager.timeStamps.at(-1) ?? 0;
    const startTime = playbackManager.timeStamps.at(0) ?? 0;

    return Time.elapsedTimeFormat(endTime - startTime);
  }, [playbackManager]);

  const editorDecorations = React.useMemo((): Map<ITextModel, string[]> => {
    const decorationMap = new Map<ITextModel, string[]>();
    monacoEditorModels.forEach(model => {
      decorationMap.set(model, []);
    });
    return decorationMap;
  }, [monacoEditorModels]);

  /**
   * Get the paste event map from submission and form it into a map with history revision mapped to paste events.
   */
  const pasteEventMap = React.useMemo((): PasteEventMap => {
    const targets = playbackManager.ticks.reduce<Record<string, CodeEditorInputEvent>>((all, tick, index) => {
      const codeEditorInputEvent = tick.events.find(event => event.kind === "CODE_EDITOR") as CodeEditorInputEvent | undefined;
      if (codeEditorInputEvent) {
        return { ...all, [revisionToId(index)]: codeEditorInputEvent };
      }
      return all;
    }, {});
    const { pasteEventMap } = createPasteEventMap(clipboardEvents, targets);
    return pasteEventMap;
  }, [playbackManager, clipboardEvents]);

  const status = React.useMemo((): Status => {
    if (codeEditorInputEvents.length === 0) {
      return "NO_DATA";
    }

    return "READY";
  }, [codeEditorInputEvents.length]);

  const historyPasteMap = React.useMemo((): HistoryPasteMap => {
    const result: HistoryPasteMap = {};
    let previousPasteSelection: PasteRangeMap = {};
    codeEditorInputEvents.forEach((codeEditorInputEvent, index) => {
      const pasteSelection: PasteRangeMap = { ...previousPasteSelection };
      for (const pasteEventKey in pasteEventMap) {
        const key = revisionToId(index);
        if (codeEditorInputEvent && pasteEventMap[pasteEventKey].histories[key]) {
          pasteSelection[pasteEventKey] = pasteEventMap[pasteEventKey].histories[key];
        }
      }
      result[index] = pasteSelection;
      previousPasteSelection = pasteSelection;
    });
    return result;
  }, [codeEditorInputEvents, pasteEventMap]);

  const updateDecoration = React.useCallback(
    (sliderIndex: number) => {
      if (!monaco) {
        return;
      }
      if (historyPasteMap && sliderIndex in historyPasteMap) {
        monacoEditorModels.forEach(model => {
          const decorations = editorDecorations.get(model) || [];
          const newDecorations = decoratePlaybackText(monaco, model, decorations, historyPasteMap[sliderIndex]);
          editorDecorations.set(model, newDecorations);
        });
      }
    },
    [monaco, monacoEditorModels, editorDecorations, historyPasteMap],
  );

  React.useEffect(() => {
    const cleanupReceiveTicketEvent = playbackManager.onReceiveTickEvent(() => {
      const startTime = playbackManager.timeStamps.at(0) ?? 0;
      const currentTime = playbackManager.currentTimeStamp ?? 0;
      setPassedTime(Time.elapsedTimeFormat(currentTime - startTime));
    });
    playbackManager.refresh();
    if (!monaco) {
      return () => {
        cleanupReceiveTicketEvent();
      };
    }

    const cleanupMoveForward = playbackManager.onMoveForward(payload => {
      const tickIndex = payload.currentIndex;
      const sliderIndex = payload.nextIndex;
      for (let step = 0; step < sliderIndex - tickIndex; step++) {
        const revision = codeEditorInputEvents.at(tickIndex + step + 1);
        if (revision) {
          monacoEditorModels.forEach(model => {
            applyOperation(revision.textOperations, monaco, model);
            const range = getCursorRange(revision.textOperations, monaco, model);
            monacoEditorRef.current.forEach(editor => {
              editor.revealLineInCenter(range.startLineNumber);
            });
            cursorWidgetManager.updateCursor({ cursorId: revision.userId, range, cursorColor: CURSOR_COLOR });
          });
        }
      }
    });

    const cleanupMoveBackward = playbackManager.onMoveBackward(payload => {
      const tickIndex = payload.currentIndex;
      const sliderIndex = payload.nextIndex;
      for (let step = 0; step < tickIndex - sliderIndex; step++) {
        const revision = codeEditorInputEvents.at(tickIndex - step);
        if (revision) {
          const content = composeTextOperationV2(tickIndex - step - 1, codeEditorInputEvents);
          const inverseOp = invertTextOperation(revision.textOperations, content);
          monacoEditorModels.forEach(model => {
            const range = getCursorRange(revision.textOperations, monaco, model);
            monacoEditorRef.current.forEach(editor => {
              editor.revealLineInCenter(range.startLineNumber);
            });
            cursorWidgetManager.updateCursor({
              cursorId: revision.userId,
              range,
              cursorColor: CURSOR_COLOR,
            });
            applyOperation(inverseOp, monaco, model);
          });
        }
      }
    });

    return () => {
      cleanupReceiveTicketEvent();
      cleanupMoveForward();
      cleanupMoveBackward();
    };
  }, [codeEditorInputEvents, cursorWidgetManager, monaco, monacoEditorModels, playbackManager]);

  React.useEffect(() => {
    updateDecoration(lastCodeEditorInputEventsIndex);
  }, [updateDecoration, lastCodeEditorInputEventsIndex]);

  const markMapByTickIndex = React.useMemo((): Map<number, { texts: string[]; show: boolean }> => {
    const markMap = new Map<number, { texts: string[]; show: boolean }>();
    const eventCountMap: Record<string, number | undefined> = {};
    playbackManager.ticks.forEach((tick, index) => {
      const target = markMap.get(index) || { texts: [], show: false };
      const addLabel = (text: string, show?: boolean) => {
        target.texts.push(text);
        target.show = !!show;
        markMap.set(index, target);
      };
      const uniqueEventSet = new Set<string>();
      tick.events.forEach(event => {
        if (uniqueEventSet.has(event.kind)) {
          return;
        }
        uniqueEventSet.add(event.kind);
        const previousCount = eventCountMap[event.kind] || 1;
        switch (event.kind) {
          case "ACCESS": {
            addLabel(`${t("IPアドレス検知")} ${previousCount}`);
            break;
          }
          case "USE_HINT": {
            addLabel(`${t("ヒント")} ${previousCount}`);
            break;
          }
          case "SUBMIT_QUESTION": {
            addLabel(`${t("提出")} ${previousCount}`, true);
            break;
          }
          case "RUN_CODE": {
            addLabel(`${t("コードの実行")} ${previousCount}`);
            break;
          }
          case "EDITOR_PASTE": {
            if (playbackSettings.enabledCopyAndPasteDetection) {
              addLabel(`${t("ペースト")} ${previousCount}`, playbackSettings.enabledCopyAndPasteDetection);
            }
            break;
          }
          case "WEB_SITE_SEARCH": {
            addLabel(`${t("Google検索")} ${previousCount}`);
            break;
          }
          case "CHATGPT_REQUEST": {
            addLabel(`ChatGPT ${previousCount}`);
            break;
          }
          case "EXTERNAL_WEB_SITE_ACCESS": {
            addLabel(`${"アクセス"} ${previousCount}`);
            break;
          }
          case "BROWSER_BLUR": {
            addLabel(`${t("ページ離脱")} ${previousCount}`);
            break;
          }
          case "BROWSER_HIDDEN": {
            addLabel(`${t("ページ離脱")} ${previousCount}`);
            break;
          }
          case "BROWSER_FOCUS": {
            addLabel(`${t("ページ再表示")} ${previousCount}`);
            break;
          }
        }
        eventCountMap[event.kind] = previousCount + 1;
      });
    });
    return markMap;
  }, [t, playbackManager, playbackSettings.enabledCopyAndPasteDetection]);

  const valueLabelFormat = React.useCallback(
    (value: number): React.ReactNode => {
      const target = markMapByTickIndex.get(value);
      const tick = playbackManager.ticks.at(value);
      const timeLabel = tick ? Time.unixTimeMilliSecondsToFormat(tick.ts, "yyyy/MM/dd HH:mm:ss") : "";
      if (!target) {
        return timeLabel;
      }
      return [timeLabel, ...target.texts].filter(Boolean).join(" ");
    },
    [markMapByTickIndex, playbackManager],
  );

  const toolbarProps = React.useMemo((): Widget.ProjectContentsViewerV4Props["toolbar"] => {
    const marks = Array.from(markMapByTickIndex.entries()).map(([tickIndex, target]): Mark => {
      return {
        value: tickIndex,
        label: target.show ? target.texts.join(", ") : undefined,
      };
    });

    return {
      value: sliderValue,
      slider: {
        min: 0,
        max: playbackManager.lastTickIndex,
        marks: marks,
        disabled: false,
        valueLabelDisplay: "auto",
        valueLabelFormat: valueLabelFormat,
      },
      remainTime: remainTime,
      passedTime: passedTime,
      onChangePlayStatus: playStatus => {
        ProjectContentsViewerV4.updatePlayStatus(playStatus);
      },
      SettingsMenu: <ProjectPlaybackSettingsMenuContainer canShowPasteAndTabStatistics={args.canShowPasteAndTabStatistics} />,
    };
  }, [
    args.canShowPasteAndTabStatistics,
    markMapByTickIndex,
    passedTime,
    playbackManager.lastTickIndex,
    remainTime,
    sliderValue,
    valueLabelFormat,
  ]);

  return {
    monaco: monaco,
    modelForDefaultScreen: modelForDefaultScreen,
    modelForFullScreen: modelForFullScreen,
    onEditorMount: editor => {
      monacoEditorRef.current.push(editor);
      editor.onDidDispose(() => {
        monacoEditorRef.current = monacoEditorRef.current.filter(e => e !== editor);
      });
      cursorWidgetManager.initCursorWidgetController(editor);
    },
    refreshEditorKey: selectedFileIndex,
    toolbar: toolbarProps,
    status: status,
    showPasteRange: playbackSettings.enabledCopyAndPasteDetection,
    onChangeSliderValue: handleChangeSliderValue,
    ActivityTimelineLog: question && (
      <ScreeningTestActivityLogContainer key={selectedFileIndex} title={resolveLanguage(question, lang, "title")} />
    ),
    StatisticsContents: [],
    appealMessageInReport: {
      title: t("候補者による提出物の説明を確認することができます。"),
      body: appealMessage ?? t("受験者が入力した説明文はありません。"),
    },
    fileTree: fileTree,
  };
};
