import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type LiveCodingDeviceSettingsContainerProps = {};

const LiveCodingDeviceSettingsContainer: React.FC<LiveCodingDeviceSettingsContainerProps> = () => {
  const liveCodingDeviceSettingsProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.LiveCodingDeviceSettings {...liveCodingDeviceSettingsProps} />
    </ErrorBoundary>
  );
};

LiveCodingDeviceSettingsContainer.displayName = "LiveCodingDeviceSettingsContainer";

export default withErrorBoundary(LiveCodingDeviceSettingsContainer, {});
