import * as Graphql from "@hireroo/graphql/client/urql";

export const AlgorithmVariantMap: Record<Graphql.AlgorithmQuestionVariant, "ALGORITHM" | "DATABASE" | "CLASS"> = {
  [Graphql.AlgorithmQuestionVariant.Algorithm]: "ALGORITHM",
  [Graphql.AlgorithmQuestionVariant.Database]: "DATABASE",
  [Graphql.AlgorithmQuestionVariant.Class]: "CLASS",
  [Graphql.AlgorithmQuestionVariant.Unknown]: "ALGORITHM",
};

export const DEFAULT_PERFORMANCE_TEST_LIMIT_MILLISECONDS = 1000;
