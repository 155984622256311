import CodeEditor, { CodeEditorProps } from "@hireroo/code-editor/react/CodeEditor";
import SimilarCodeStyledWrapper from "@hireroo/code-editor/react/v2/SimilarCodeStyledWrapper";
import Box from "@mui/material/Box";
import type * as monaco from "monaco-editor";
import * as React from "react";

import { decorateFragments, isPositionBetween, MatchFragment, Side } from "./privateHelper";

const PAIR_EDITOR_WRAPPER_THEME = "WHITE";

export type PairMatchCodeEditorProps = {
  side: Side;
  value: string;
  runtime: string;
  width?: number;
  height?: number;
  fragments: MatchFragment[];
  selectedMatch: MatchFragment[] | null;
  onChangeSelectedMatch: (match: MatchFragment[] | null) => void;
};

const PairMatchCodeEditor: React.FC<PairMatchCodeEditorProps> = ({
  side,
  value,
  width,
  height,
  runtime,
  fragments,
  selectedMatch,
  onChangeSelectedMatch,
}) => {
  const [_, setSelectedDecoration] = React.useState<string[]>([]);

  const editorRef = React.useRef<monaco.editor.IStandaloneCodeEditor | null>(null);
  const monacoRef = React.useRef<typeof monaco>();
  const disposeRef = React.useRef<() => void>(() => undefined);

  const handleChangeCursorPosition: (event: monaco.editor.ICursorPositionChangedEvent) => void = React.useCallback(
    event => {
      const selectedFragments: MatchFragment[] = [];
      /** for each fragment, check if current cursor position is within the fragment range */
      fragments.forEach(fragment => {
        if (isPositionBetween(fragment[side], event.position)) {
          selectedFragments.push(fragment);
        }
      });

      if (selectedFragments.length === 0) {
        onChangeSelectedMatch(null);
      } else {
        onChangeSelectedMatch(selectedFragments);
      }
    },
    [fragments, onChangeSelectedMatch, side],
  );

  const codeEditorProps: CodeEditorProps = {
    value: value,
    width: width,
    height: height,
    path: `${side}.${runtime}`,
    saveViewState: false,
    language: runtime,
    options: { readOnly: true },
    onMount: (editor, monaco) => {
      editorRef.current = editor;
      monacoRef.current = monaco;

      /** decorate all fragments + selected fragment */
      const model = editor.getModel();
      if (model) {
        setSelectedDecoration(prevDecorations => {
          return decorateFragments(monaco, model, prevDecorations, side, fragments, selectedMatch);
        });
      }
      /** add fragment selection event listener on mount */
      const { dispose } = editorRef.current.onDidChangeCursorPosition(handleChangeCursorPosition);
      disposeRef.current = dispose;
    },
  };

  /** remove current highlight and add selectable range */
  React.useEffect(() => {
    if (!editorRef.current || !monacoRef.current) {
      return;
    }

    disposeRef.current();
    /** add handler for when cursor position is within fragment range (when fragment changes) */
    const { dispose } = editorRef.current.onDidChangeCursorPosition(handleChangeCursorPosition);
    disposeRef.current = dispose;

    return () => {
      onChangeSelectedMatch(null);
      dispose();
    };
  }, [fragments, onChangeSelectedMatch, handleChangeCursorPosition]);

  /** Decorate all fragments */
  React.useEffect(() => {
    if (!editorRef.current || !monacoRef.current) {
      return;
    }
    const monaco = monacoRef.current;
    const model = editorRef.current.getModel();
    if (model) {
      setSelectedDecoration(prevDecorations => {
        return decorateFragments(monaco, model, prevDecorations, side, fragments, selectedMatch);
      });

      if (selectedMatch && selectedMatch.length > 0) {
        editorRef.current.revealLineInCenter(selectedMatch[0][side]?.startRow || 0);
      }
    }
  }, [fragments, selectedMatch, side]);

  return (
    <Box className="pair-matching-code-editor" sx={{ height: "inherit" }}>
      <Box className="editor-element" sx={{ height: "inherit" }}>
        <SimilarCodeStyledWrapper theme={PAIR_EDITOR_WRAPPER_THEME}>
          <CodeEditor {...codeEditorProps} />
        </SimilarCodeStyledWrapper>
      </Box>
    </Box>
  );
};

PairMatchCodeEditor.displayName = "PairMatchCodeEditor";

export default PairMatchCodeEditor;
