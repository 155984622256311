import { ENTITY_REPORT_TOP, extractUniqueEntity } from "@hireroo/app-helper/entity";
import { scrollToContentForReportV2 } from "@hireroo/app-helper/html-element";
import { ChallengeTestReport } from "@hireroo/app-store/view-domain/ChallengeTestReport";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import ChallengeTestReportMiddleContainer, { ChallengeTestReportMiddleContainerProps } from "./MiddleContainer";

export type ChallengeTestReportFetchContainerProps = ChallengeTestReportMiddleContainerProps;

const ChallengeTestReportFetchContainer: React.FC<ChallengeTestReportFetchContainerProps> = props => {
  const { challengeId, companyId } = props;
  const initialized = ChallengeTestReport.useInitialized(challengeId);

  const [result] = Graphql.useGetChallengeEntityForChallengeTestReportQuery({
    variables: {
      challengeId: challengeId,
      withAnswers: true,
    },
    requestPolicy: "cache-and-network",
  });

  React.useEffect(() => {
    if (result.data?.challengeEntity) {
      ChallengeTestReport.initialize(result.data.challengeEntity);
      const uniqueEntity = extractUniqueEntity(window.location.hash);
      if (uniqueEntity && uniqueEntity.type === "challenge" && uniqueEntity.id === result.data.challengeEntity.entityId) {
        scrollToContentForReportV2(ENTITY_REPORT_TOP, { delayTimeMilliSeconds: 1 });
      }
    }
  }, [result.data?.challengeEntity]);

  if (result.fetching || !initialized) {
    return <Widget.Loading kind="TOP" />;
  }

  const containerProps: ChallengeTestReportMiddleContainerProps = {
    featureKind: props.featureKind,
    challengeId: props.challengeId,
    canShowPasteAndTabStatistics: props.canShowPasteAndTabStatistics,
    canShowCheatDetectionSection: props.canShowCheatDetectionSection,
    canShowFeedbackSuspiciousDegree: props.canShowFeedbackSuspiciousDegree,
    showUsedHintSection: props.showUsedHintSection,
    companyId,
  };
  return (
    <ErrorBoundary>
      <ChallengeTestReportMiddleContainer {...containerProps} />
    </ErrorBoundary>
  );
};

ChallengeTestReportFetchContainer.displayName = "ChallengeTestReportFetchContainer";

export default withErrorBoundary(ChallengeTestReportFetchContainer, {});
