import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { ErrorCode, FirebaseError, signInWithPassword } from "@hireroo/firebase";
import { useTranslation } from "@hireroo/i18n";
import { initializeUser } from "@hireroo/initializer/app";
import type { Widget } from "@hireroo/presentation";
import { LastVisitedRoute, redirectWithRawUrl } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/react";

import * as PrivateHelper from "./privateHelper";

export type GenerateSignInMailAddressPropsArgs = {};

export const useGenerateProps = (_args: GenerateSignInMailAddressPropsArgs): Widget.SignInMailAddressProps => {
  const { t } = useTranslation();
  const navigate = useTransitionNavigate();

  return {
    passwordField: {
      /**
       * Not necessary to set the passwordField.
       */
    },
    signInButton: {
      children: t("サインイン"),
    },
    passwordReset: {
      onClick: () => {
        navigate("/reset/password");
      },
    },
    onSubmit: async field => {
      const userCredential = await signInWithPassword(field.email, field.password).catch((error: FirebaseError) => {
        switch (error.code) {
          case ErrorCode.INVALID_PASSWORD: {
            Snackbar.notify({
              severity: "error",
              message: t("メールアドレスとパスワードの組み合わせが間違っています。ご確認の上再度お試しください。"),
            });
            break;
          }
          case ErrorCode.USER_DELETED: {
            Snackbar.notify({
              severity: "error",
              message: t("アカウントが見つかりませんでした。サインアップするか、正しいサインイン情報を入力してください。"),
            });
            break;
          }
          default: {
            Sentry.captureException(error);
            break;
          }
        }
        return null;
      });
      const user = userCredential?.user;
      if (!user) {
        return;
      }
      await PrivateHelper.signInProcess({
        user,
        emailAddress: field.email,
      });
      const previousPathname = LastVisitedRoute.getAndClear();
      if (previousPathname) {
        await redirectWithRawUrl(previousPathname);
      }
      await initializeUser();
    },
  };
};
