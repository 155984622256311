import * as z from "zod";

const useTags = () => {
  return z
    .object({
      displayName: z.string(),
    })
    .array();
};

export type Tags = z.infer<ReturnType<typeof useTags>>;

export const useActiveStatus = () => {
  return z.union([z.literal("ACTIVE"), z.literal("NOT_ACTIVE"), z.null()]);
};

export type ActiveStatus = z.infer<ReturnType<typeof useActiveStatus>>;

export const useScreeningSearchQuery = () => {
  const tags = useTags();
  const activeStatus = useActiveStatus();
  return z.object({
    tags,
    activeStatus: activeStatus,
    textFilter: z.string(),
  });
};

export type ScreeningSearchQuerySchema = z.infer<ReturnType<typeof useScreeningSearchQuery>>;

export const useSortFieldValue = () => {
  return z.union([z.literal("created-at-descending"), z.literal("created-at-ascending")]);
};

export type SortFieldValue = z.infer<ReturnType<typeof useSortFieldValue>>;
