import { filterMatchedPathsFromGlobPatterns } from "@hireroo/app-helper/files";
import { ProjectCodingEditorV3 } from "@hireroo/app-store/widget/shared/ProjectCodingEditorV3";
import * as ProjectHelpers from "@hireroo/project/helpers/fileTree";
import React from "react";

import { AgentServerFileTreeController } from "./AgentServerFileTreeController";
import { BASE_PATH } from "./definition";
import type * as Types from "./types";

type FileTreeStatus = "CONNECTED_SERVER" | "CONNECTING";
type ConnectedServerType = "WEBSOCKET" | "FIREBASE";

export type ExtendedParams = {
  fileTreeStatus: FileTreeStatus;
  connectedServerType: ConnectedServerType;
  didConnectAgentServer: boolean;
  /**
   * 初期コードにリセットする
   */
  resetAllCodes: () => void;
};

type ExtractFileTreeAction = Omit<Types.FileTreeControllerAction, "initialized">;

export type FileTreeControllerProps = {
  entityId: number;
  fileSyncEndpoint: string;
  questionId: number;
  questionVersion: string;
  initialFileIndexes: string[];
  readOnlyFilePatterns: string[];
};

export const useFileTreeController = (props: FileTreeControllerProps): ExtractFileTreeAction & ExtendedParams => {
  const { initialFileIndexes, readOnlyFilePatterns } = props;
  const [fileTreeStatus, setFileTreeStatus] = React.useState<FileTreeStatus>("CONNECTING");
  const [openedFiles, setOpenedFiles] = React.useState<string[]>([]);
  const [selectedFile, setSelectedFile] = React.useState<string | null>(null);
  const [fileTree, setFileTree] = React.useState<Types.Node>(ProjectHelpers.createNodeByFileIndexes(BASE_PATH, [], readOnlyFilePatterns));
  const [connected, setConnected] = React.useState(false);

  const { useAgentServerHealth } = ProjectCodingEditorV3.useCreateProjectEntityHooks(props.entityId);
  const agentServerHealth = useAgentServerHealth();

  /**
   * テンプレートコードに対してのみReadOnly判定を行う
   */
  const readOnlyFiles = React.useMemo(() => {
    return filterMatchedPathsFromGlobPatterns(initialFileIndexes, readOnlyFilePatterns);
  }, [initialFileIndexes, readOnlyFilePatterns]);

  const agentServerFileTreeController = React.useMemo(() => {
    return new AgentServerFileTreeController(props.fileSyncEndpoint, [], readOnlyFiles);
  }, [props.fileSyncEndpoint, readOnlyFiles]);

  React.useEffect(() => {
    const handleConnected = () => {
      setFileTreeStatus("CONNECTED_SERVER");
    };
    const handleInitialized = () => {
      setFileTree(structuredClone(agentServerFileTreeController.fileTree));
      setConnected(true);
    };

    const handleSelectedFile = (newSelectedFile: string | null) => {
      setSelectedFile(newSelectedFile);
    };
    const handleOpenedFiles = (openedFiles: string[]) => {
      setOpenedFiles(openedFiles);
    };
    const handleChangedFileTree = () => {
      /**
       * agentServerFileTreeController.fileTreeのrootのobjectの参照が変わらないとReactのsetStateが差分検知せずにdispatchしない
       */
      setFileTree(structuredClone(agentServerFileTreeController.fileTree));
    };
    agentServerFileTreeController.on("connected", handleConnected);
    agentServerFileTreeController.on("initialized", handleInitialized);
    agentServerFileTreeController.on("selectedFile", handleSelectedFile);
    agentServerFileTreeController.on("openedFiles", handleOpenedFiles);
    agentServerFileTreeController.on("changedFileTree", handleChangedFileTree);
    return () => {
      agentServerFileTreeController.off("connected", handleConnected);
      agentServerFileTreeController.off("initialized", handleInitialized);
      agentServerFileTreeController.off("selectedFile", handleSelectedFile);
      agentServerFileTreeController.off("openedFiles", handleOpenedFiles);
      agentServerFileTreeController.off("changedFileTree", handleChangedFileTree);
    };
  }, [agentServerFileTreeController]);

  React.useEffect(() => {
    if (agentServerHealth) {
      agentServerFileTreeController.start();
    } else {
      agentServerFileTreeController.stop();
    }
  }, [agentServerFileTreeController, agentServerHealth]);

  React.useEffect(() => {
    return () => {
      agentServerFileTreeController.dispose();
    };
  }, [agentServerFileTreeController]);

  return {
    addFile: (at: string, name: string) => {
      agentServerFileTreeController.addFile(at, name);
    },
    addDir: (at: string, name: string) => {
      agentServerFileTreeController.addDir(at, name);
    },
    remove: (id: string) => {
      agentServerFileTreeController.remove(id);
    },
    update: (id: string, value: string) => {
      agentServerFileTreeController.update(id, value);
    },
    selectFile: (newSelectedFile: string) => {
      agentServerFileTreeController.selectFile(newSelectedFile);
    },
    closeFile: (closedFile: string) => {
      agentServerFileTreeController.closeFile(closedFile);
    },
    didConnectAgentServer: connected,
    cwd: agentServerFileTreeController.cwd,
    reconnect: () => {
      agentServerFileTreeController.reconnect();
    },
    fileTree: fileTree,
    selectedFile,
    filesOpened: openedFiles,
    fileTreeStatus,
    connectedServerType: "WEBSOCKET",
    resetAllCodes: () => {
      setOpenedFiles([]);
      setSelectedFile(null);
      agentServerFileTreeController.reset();
    },
  };
};
