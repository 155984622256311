import { generateKey } from "@hireroo/app-helper/parser";
import { ScoreHistogramProps } from "@hireroo/charts/react/ScoreHistogram";
import { useTranslation } from "@hireroo/i18n";
import * as React from "react";
import { useSnapshot } from "valtio";

import { state } from "./State";
import * as Types from "./types";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = (uniqueKey: Types.UniqueKey) => {
  const snapshot = useSnapshotState();
  return snapshot.totalScoreStatisticsMap.has(uniqueKey);
};

export const useCreateEntityHooks = (uniqueKey: Types.UniqueKey) => {
  const snapshot = useSnapshotState();
  const entity = snapshot.totalScoreStatisticsMap.get(uniqueKey);

  if (!entity) {
    throw new Error("need to initialize Rank Visualizer store");
  }

  const useQuery = () => {
    return entity.currentQuery;
  };

  const useQueryKey = () => {
    return generateKey(entity.currentQuery);
  };

  const useQueryResult = () => {
    const queryKey = useQueryKey();
    return entity.queryResultCacheMap.get(queryKey);
  };

  const useGraphStatus = (): Types.GraphStatus => {
    const queryKey = useQueryKey();
    const result = entity.queryResultCacheMap.get(queryKey);
    if (result) {
      return result.status;
    }
    return "LOADING";
  };

  const useBins = () => {
    const queryKey = useQueryKey();
    const result = entity.queryResultCacheMap.get(queryKey);
    const statistics = result?.statistics;
    return React.useMemo(() => {
      const bins = statistics?.bins || [];
      if (bins.length > 0) {
        const binWidth = 100 / bins.length;

        return bins.map((v, i) => {
          const start = Math.floor(binWidth * i * 100) / 100;
          const end = i === bins.length - 1 ? Math.floor(binWidth * (i + 1) * 100) / 100 : Math.floor((binWidth * (i + 1) - 1) * 100) / 100;
          return {
            name: `${start}~${end}`,
            value: v,
          };
        });
      }
      return [];
    }, [statistics]);
  };

  /**
   * @param score 0 <= score <= 1
   */
  const useBenchMarks = (score: number, showRank: boolean) => {
    const { t } = useTranslation();
    if (process.env.NODE_ENV !== "production") {
      if (score < 0 || 1 < score) {
        console.warn(`Score should be 0 <= score <= 1. Current score is ${score}`);
      }
    }
    const queryKey = useQueryKey();
    const result = entity.queryResultCacheMap.get(queryKey);
    const rank = result?.rank;
    const statistics = result?.statistics;
    const bins = statistics?.bins || [];

    return React.useMemo((): ScoreHistogramProps["lines"] => {
      if (rank) {
        const binWidth = 100 / bins.length;

        // Assume that bins.length is 10 in the comment below

        // When score is 1, index should be 9 not 10, hence take the min of bins.length - 1
        const index = Math.min(Math.floor((score * 100) / binWidth), bins.length - 1);

        // Start is 0, 5, 10, ...90, 95
        const start = Math.floor(binWidth * index * 100) / 100;
        // End is 4, 9, 14, ...94, 100
        const end = index === bins.length - 1 ? start + binWidth : start + binWidth - 1;

        return [
          {
            x: `${start}~${end}`,
            label: showRank
              ? {
                  kind: "WITH_RANK",
                  rank: {
                    rank: rank.rank,
                    numSubset: rank.numSubset,
                  },
                }
              : {
                  kind: "COMMON",
                  text: t("受験者"),
                },
            strokeType: "solid",
          },
        ];
      }
      return [];
    }, [bins.length, rank, score, showRank, t]);
  };
  return {
    useBenchMarks,
    useBins,
    useGraphStatus,
    useQuery,
    useQueryKey,
    useQueryResult,
  };
};
