import { state } from "./State";
import type * as Types from "./types";

export const setCompany = (company: Types.Company): void => {
  state.company = company;
};

export const selectMember = (selected: boolean, memberId: string) => {
  if (selected) {
    state.selectedMemberIds.push(memberId);
  } else {
    state.selectedMemberIds = state.selectedMemberIds.filter(id => id !== memberId);
  }
};

export const TalentInvitationAction = {
  setInvitationId: (invitationId: string | null) => {
    state.talentInvitation.id = invitationId;
  },
  setStatus: (status: Types.InvitationState["status"]) => {
    state.talentInvitation.status = status;
  },
  clear: () => {
    state.talentInvitation.id = null;
    state.talentInvitation.status = "READY";
  },
};

export const DeleteMemberAction = {
  setMemberId: (memberId: string | null) => {
    state.deleteMember.selectedMemberId = memberId;
  },
  clear: () => {
    state.deleteMember.selectedMemberId = null;
  },
};

export const updateDialogStatus = (status: Types.DialogStatus) => {
  state.dialogStatus = status;
};

export const updateFilterText = (text: string) => {
  state.filterText = text;
};

export const clearSelectedMemberIds = () => {
  state.selectedMemberIds = [];
};

export const clear = () => {
  state.filterText = "";
  state.selectedMemberIds = [];
};
