import Box from "@mui/material/Box";
import * as React from "react";

import LockedHint, { LockedHintProps } from "./parts/LockedHint";
import UnlockedHint, { UnlockedHintProps } from "./parts/UnlockedHint";

export type ItemProps =
  | {
      mode: "locked";
      value: LockedHintProps;
    }
  | {
      mode: "unlocked";
      value: UnlockedHintProps;
    };

export type QuestionHintStackProps = {
  items: ItemProps[];
};

const QuestionHintStack: React.FC<QuestionHintStackProps> = props => {
  const Items = props.items.map((item, idx) => {
    if (item.mode === "locked") {
      return <LockedHint key={`hint-${idx}`} {...item.value} />;
    }
    return <UnlockedHint key={`hint-${idx}`} {...item.value} />;
  });
  return <Box>{Items}</Box>;
};

QuestionHintStack.displayName = "QuestionHintStack";

export default QuestionHintStack;
