import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { ScreeningResourceEditorForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import ResourceField from "../../../../modules/ResourceFieldV2/ResourceFieldV2";
import ToggleResourceField, { ToggleResourceFieldProps } from "../../../../modules/ToggleResourceField/ToggleResourceField";
import CustomFields from "../../../../ms-components/Spot/CustomFields/CustomFields";
import DateTimeControl, { DateTimeControlProps } from "../../../../primitive/InputControl/DateTimeControl/DateTimeControl";
import InputControlTextField, {
  InputControlTextFieldProps,
} from "../../../../primitive/InputControl/InputControlTextField/InputControlTextField";
import { type LayoutController, useScreeningResourceEditorContext } from "../../Context";

type FieldName = keyof ScreeningResourceEditorForm.TestSetupFormSchema;

const ReservedFieldName = {
  CUSTOM_FIELDS: "customFields",
  ACCEPTABLE_LIMIT: "acceptableLimit",
  DEADLINE_DATE: "deadlineDate",
} satisfies Record<string, FieldName>;

const StyledDateTimeControl = styled(DateTimeControl)(() => ({
  maxWidth: 300,
  ".MuiOutlinedInput-input": {
    paddingLeft: 12,
    paddingRight: 12,
  },
}));

const Subsection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  boxShadow: "none",
}));

const StyledInputControlTextField = styled(InputControlTextField)(() => ({
  width: "100px",
  marginRight: "8px",
}));

const InlineFieldBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  wordBreak: "keep-all",
  whiteSpace: "nowrap",
}));

export type TestSetupSectionProps = {
  minAcceptableLimit: number;
  onSubmit: SubmitHandler<ScreeningResourceEditorForm.TestSetupFormSchema>;
  defaultValues: ScreeningResourceEditorForm.TestSetupFormSchema;
  disableFields: Record<FieldName, boolean>;
};

const TestSetupSection: React.FC<TestSetupSectionProps> = props => {
  const { t } = useTranslation();
  const { onSubmit } = props;
  const lang = useLanguageCode();

  const { stepName, setController, submitValues, setSubmitValue } = useScreeningResourceEditorContext();
  const validateSchema = ScreeningResourceEditorForm.useTestSetupForm({
    minAcceptableLimit: props.minAcceptableLimit,
  });
  const methods = useForm<ScreeningResourceEditorForm.TestSetupFormSchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: submitValues.TEST_SETUP ?? props.defaultValues,
  });

  const controller = React.useMemo((): LayoutController => {
    return {
      checkGoNextStep: () => {
        return new Promise<boolean>(resolve => {
          methods.handleSubmit(
            fields => {
              onSubmit(fields);
              setSubmitValue("TEST_SETUP", fields);
              resolve(true);
            },
            errors => {
              console.warn(errors);
              resolve(false);
              setSubmitValue("TEST_SETUP", undefined);
            },
          )();
        });
      },
    };
  }, [methods, onSubmit, setSubmitValue]);

  React.useEffect(() => {
    setController("TEST_SETUP", controller);
  }, [setController, controller]);

  const acceptableLimitOptionalResourceField: ToggleResourceFieldProps = {
    toggleKind: "SWITCH",
    label: t("受験可能な定員数"),
    defaultOpen: !!methods.getValues("acceptableLimit"),
    onDiscard: () => {
      methods.setValue("acceptableLimit", null);
    },
    disabled: props.disableFields.acceptableLimit,
  };

  const acceptableLimitFieldProps: InputControlTextFieldProps = {
    type: "number",
    placeholder: "50",
    InputProps: {
      inputProps: {
        min: props.minAcceptableLimit,
        step: "1",
      },
    },
  };

  const deadlineDateOptionalResourceField: ToggleResourceFieldProps = {
    toggleKind: "SWITCH",
    label: t("テストの提出期限"),
    defaultOpen: !!methods.getValues("deadlineDate"),
    onOpen: () => {
      const defaultDeadlineDate = new Date();
      // Set one week later at 23:59 as the default cutoff time.
      defaultDeadlineDate.setDate(defaultDeadlineDate.getDate() + 7);
      defaultDeadlineDate.setHours(23);
      defaultDeadlineDate.setMinutes(59);
      defaultDeadlineDate.setSeconds(59);
      defaultDeadlineDate.setMilliseconds(0);
      methods.setValue("deadlineDate", defaultDeadlineDate);
    },
    onDiscard: () => {
      methods.setValue("deadlineDate", null);
    },
    disabled: props.disableFields.deadlineDate,
  };

  const deadlineDateProps: DateTimeControlProps = {
    dateTimePicker: {
      filterDateFn: date => {
        const min = new Date();
        min.setHours(0);
        min.setMinutes(0);
        min.setSeconds(0);
        min.setMilliseconds(0);
        return date.getTime() >= min.getTime();
      },
      lang,
      customInput: {
        id: ReservedFieldName.DEADLINE_DATE,
        name: ReservedFieldName.DEADLINE_DATE,
        color: "secondary",
        required: true,
        InputLabelProps: {
          shrink: true,
        },
        InputProps: {
          endAdornment: <CalendarTodayOutlinedIcon fontSize="small" />,
        },
        fullWidth: true,
        variant: "outlined",
      },
      datePicker: {
        disabled: false,
        required: true,
        placeholderText: t("指定なし"),
        timeInputLabel: t("時間"),
        showTimeSelect: true,
        timeFormat: "p",
      },
    },
  };

  if (stepName !== "TEST_SETUP") {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <Stack py={2} spacing={1.5}>
        <Subsection>
          <ResourceField
            label={t("カスタムフォーム")}
            kind="NONE"
            help={{ kind: "DESCRIPTION", text: t("受験者名とメールアドレスはカスタムフォームと別にフォームが表示されます。") }}
            pb={2}
          >
            <CustomFields name="customFields" />
          </ResourceField>
        </Subsection>

        <Subsection>
          <Stack spacing={2}>
            <ResourceField
              label={t("受験可能な定員数")}
              kind="NONE"
              help={{
                kind: "DESCRIPTION",
                text: t("このコーディングテストで受験可能な人数の最大数を指定することができます。未指定の場合、上限はありません。"),
              }}
            >
              <ToggleResourceField {...acceptableLimitOptionalResourceField}>
                <InlineFieldBox>
                  <StyledInputControlTextField name={ReservedFieldName.ACCEPTABLE_LIMIT} {...acceptableLimitFieldProps} />
                  {t("人")}
                </InlineFieldBox>
              </ToggleResourceField>
            </ResourceField>

            <ResourceField label={t("テストの提出期限")} kind="NONE">
              <ToggleResourceField {...deadlineDateOptionalResourceField}>
                <StyledDateTimeControl name={ReservedFieldName.DEADLINE_DATE} {...deadlineDateProps} />
              </ToggleResourceField>
            </ResourceField>
          </Stack>
        </Subsection>
      </Stack>
    </FormProvider>
  );
};

TestSetupSection.displayName = "TestSetupSection";

export default TestSetupSection;
