import { styled } from "@mui/material/styles";
import * as React from "react";

import EmployeeLayout, { EmployeeLayoutProps } from "../../layouts/EmployeeLayout/EmployeeLayout";

const StyledEmployeeLayout = styled(EmployeeLayout)`
  overflow: hidden;
`;

export type HomeProps = {
  layout: EmployeeLayoutProps;
  children: React.ReactNode;
};

const Home: React.FC<HomeProps> = props => {
  return <StyledEmployeeLayout {...props.layout}>{props.children}</StyledEmployeeLayout>;
};

Home.displayName = "Home";

export default Home;
