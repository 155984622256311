import { useTranslation } from "@hireroo/i18n";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListSubheader from "@mui/material/ListSubheader";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Virtuoso, VirtuosoProps } from "react-virtuoso";

import IconButton, { IconButtonProps } from "../../primitive/Button/IconButton/IconButton";
import BatchListItem, { BatchListItemProps } from "./parts/BatchListItem/BatchListItem";

const MAX_HEIGHT = 600;
const MIN_ITEM_HEIGHT = 68;
const HEADER = 46;

const StyledList = styled(List)(() => ({
  minWidth: "300px",
  maxWidth: "450px",
  minHeight: MIN_ITEM_HEIGHT,
}));

type ListStatus = "LOADING" | "FETCHED";
type InfiniteScrollableListProps = VirtuosoProps<BatchListItemProps, unknown>;

export type IntegrationBatchMenuListProps = {
  items: Omit<BatchListItemProps, "enableScreeningFeature">[];
  enableScreeningFeature?: boolean;
  createButton: IconButtonProps;
  hasNext?: boolean;
  onEndReached: InfiniteScrollableListProps["endReached"];
  status: ListStatus;
};

const IntegrationBatchMenuList: React.FC<IntegrationBatchMenuListProps> = props => {
  const { t } = useTranslation();
  const contentHeight = Math.min(MIN_ITEM_HEIGHT * props.items.length, MAX_HEIGHT);

  const virtuosoProps: InfiniteScrollableListProps = {
    data: props.items,
    style: { height: contentHeight, maxHeight: MAX_HEIGHT - HEADER },
    totalCount: props.items.length,
    overscan: {
      main: MIN_ITEM_HEIGHT,
      reverse: MIN_ITEM_HEIGHT,
    },
    increaseViewportBy: {
      top: MIN_ITEM_HEIGHT,
      bottom: MIN_ITEM_HEIGHT,
    },
    itemContent: (index, item) => {
      return <BatchListItem key={item.batch.id} {...item} enableScreeningFeature={props.enableScreeningFeature} />;
    },
    defaultItemHeight: MIN_ITEM_HEIGHT,
    endReached: props.onEndReached,
    components: {
      Footer: () => {
        if (props.items.length === 0) {
          return null;
        }

        if (props.status !== "LOADING" && props.hasNext) {
          return (
            <Box display="flex" justifyContent="center">
              <CircularProgress color="secondary" size={25} />
            </Box>
          );
        }
        return null;
      },
    },
  };

  return (
    <Box minHeight={MIN_ITEM_HEIGHT + HEADER}>
      <ListSubheader sx={{ backgroundColor: "inherit" }}>
        <Box py={1} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <Typography variant={"body2"}>{props.enableScreeningFeature ? t("コーディングテストを作成する") : t("バッチを作成する")}</Typography>
          <IconButton {...props.createButton} size="small">
            <AddCircleIcon fontSize={"small"} />
          </IconButton>
        </Box>
      </ListSubheader>
      <StyledList disablePadding>
        {props.items.length > 0 && (
          <Box maxHeight={contentHeight}>
            <Virtuoso {...virtuosoProps} />
          </Box>
        )}
        {props.status === "FETCHED" && props.items.length === 0 && (
          <ListItem>{t("バッチが存在しません。sonar ATSのトークンを生成するにはバッチが必要です。")}</ListItem>
        )}
      </StyledList>
    </Box>
  );
};

IntegrationBatchMenuList.displayName = "IntegrationBatchMenuList";

export default IntegrationBatchMenuList;
