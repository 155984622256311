import AppBar from "@mui/material/AppBar";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";

import BrandLogo, { BrandLogoProps } from "../../modules/BrandLogo/BrandLogo";
import HelpMenu, { HelpMenuProps } from "../../modules/HelpMenu/HelpMenu";
import PreferencesMenu, { PreferencesMenuProps } from "../../modules/PreferencesMenu/PreferencesMenu";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  width: "100%",
  height: 48,
  border: "none",
  borderBottom: `solid 1px ${theme.palette["Secondary/Shades"].p16}`,
  overflow: "hidden",
  backgroundColor: theme.palette.HeaderBG,
}));

export type RemoteInterviewEvaluateHeaderProps = {
  brandLogo: BrandLogoProps;
  helpMenu: HelpMenuProps;
  preferences: PreferencesMenuProps;
};

const RemoteInterviewEvaluateHeader: React.FC<RemoteInterviewEvaluateHeaderProps> = props => {
  return (
    <StyledAppBar variant="outlined" elevation={0}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" px={1.5} width="100%" height="100%">
        <Stack direction="row" spacing={2} alignItems="center">
          <BrandLogo {...props.brandLogo} />
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <HelpMenu {...props.helpMenu} />
          <PreferencesMenu {...props.preferences} />
        </Stack>
      </Stack>
    </StyledAppBar>
  );
};

RemoteInterviewEvaluateHeader.displayName = "RemoteInterviewEvaluateHeader";

export default RemoteInterviewEvaluateHeader;
