import { ProjectBackendTestCaseSchemaV2 } from "@hireroo/validator";

import { safeJsonParse } from "../parser";

export const withinRange = (expected: number, output: number): boolean => {
  const firstNum = Math.floor(expected / 100);
  const lower = firstNum * 100;
  const upper = lower + 100;

  return lower <= output && output < upper;
};

const backendCorrectnessTestResultSchema = ProjectBackendTestCaseSchemaV2.generateBackendCorrectnessTestResultSchema();

export const parseBackendTestCaseResult = (data: string): ProjectBackendTestCaseSchemaV2.BackendCorrectnessTestResultSchema | undefined => {
  const parsedValue = safeJsonParse(data);
  const result = backendCorrectnessTestResultSchema.safeParse(parsedValue);
  if (result.success) {
    return result.data;
  } else {
    return;
  }
};

const backendCorrectnessTestResultsSchema = ProjectBackendTestCaseSchemaV2.generateBackendCorrectnessTestResultsSchema();

export const parseBackendTestCaseResults = (data: string): ProjectBackendTestCaseSchemaV2.BackendCorrectnessTestResultsSchema | undefined => {
  const parsedValue = safeJsonParse(data);
  const result = backendCorrectnessTestResultsSchema.safeParse(parsedValue);
  if (result.success) {
    return result.data;
  } else {
    return;
  }
};

const backendTestCaseSchema = ProjectBackendTestCaseSchemaV2.generateBackendCorrectnessTestCase();

export const parseBackendTestCase = (data: string): ProjectBackendTestCaseSchemaV2.BackendCorrectnessTestCaseSchema | undefined => {
  const parsedValue = safeJsonParse(data);
  const result = backendTestCaseSchema.safeParse(parsedValue);
  if (result.success) {
    return result.data;
  } else {
    return;
  }
};

const backendTestCasesSchema = ProjectBackendTestCaseSchemaV2.generateBackendCorrectnessTestCases();

export const parseBackendTestCases = (data: string): ProjectBackendTestCaseSchemaV2.BackendCorrectnessTestCasesSchema => {
  const parsedValue = safeJsonParse(data);
  const result = backendTestCasesSchema.safeParse(parsedValue);
  if (result.success) {
    return result.data;
  } else {
    return [];
  }
};

const performanceTestCaseResultsSchema = ProjectBackendTestCaseSchemaV2.generatePerformanceTestCaseResults();

export const parsePerformanceTestCaseResults = (data: string): ProjectBackendTestCaseSchemaV2.PerformanceTestCaseResultsSchema => {
  const parsedValue = safeJsonParse(data);
  const result = performanceTestCaseResultsSchema.safeParse(parsedValue);
  if (result.success) {
    return result.data;
  } else {
    return [];
  }
};
