import { ResultKeys } from "@hireroo/app-definition/interview";
import { downloadFile } from "@hireroo/app-helper/download";
import { ScreeningsTestsStore } from "@hireroo/app-store/page/e/screenings_tests";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import * as Graphql from "@hireroo/graphql/client/graphql-request";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { getTranslation, getTranslationWithVariable } from "@hireroo/i18n";
import { updateQueryParamsByObject } from "@hireroo/router/api";
import * as Sentry from "@sentry/browser";
import { backOff } from "exponential-backoff";

export const startSubscribeListParams = () => {
  return ScreeningsTestsStore.subscribeListParams(listParams => {
    const result = typeof listParams.filters.result === "boolean" ? [listParams.filters.result ? ResultKeys.PASSED : ResultKeys.FAILED] : [];

    const sortValueMap: Record<Graphql.SpotsByCompanyIdSortMethod, string> = {
      [Graphql.SpotsByCompanyIdSortMethod.CreatedAt]: `${Graphql.SpotsByCompanyIdSortMethod.CreatedAt}-${listParams.isDescending}`,
      [Graphql.SpotsByCompanyIdSortMethod.TotalScore]: `${Graphql.SpotsByCompanyIdSortMethod.TotalScore}-${listParams.isDescending}`,
      [Graphql.SpotsByCompanyIdSortMethod.Rank]: `${Graphql.SpotsByCompanyIdSortMethod.Rank}-${listParams.isDescending}`,
      [Graphql.SpotsByCompanyIdSortMethod.DidStartAt]: `${Graphql.SpotsByCompanyIdSortMethod.DidStartAt}-${listParams.isDescending}`,
      [Graphql.SpotsByCompanyIdSortMethod.EvaluatedAt]: `${Graphql.SpotsByCompanyIdSortMethod.EvaluatedAt}-${listParams.isDescending}`,
      [Graphql.SpotsByCompanyIdSortMethod.Unknown]: "",
    };
    const sortValues = sortValueMap[listParams.sortMethod] ? [window.btoa(sortValueMap[listParams.sortMethod])] : [];
    const sameSortValues =
      listParams.sortMethod === ScreeningsTestsStore.defaultState.listParams.sortMethod &&
      listParams.isDescending === ScreeningsTestsStore.defaultState.listParams.isDescending;
    const sameResultAndDefault = listParams.filters.result === ScreeningsTestsStore.defaultFilters.result;
    const sameMaxTotalScoreAndDefault = listParams.filters.maxTotalScore === ScreeningsTestsStore.defaultFilters.maxTotalScore;
    const sameMinTotalScoreAndDefault = listParams.filters.minTotalScore === ScreeningsTestsStore.defaultFilters.minTotalScore;
    /**
     * Do not update query parameters if they are different from the Default Value when the page is visited
     * (i.e., the user has not manipulated them).
     */
    updateQueryParamsByObject({
      [ScreeningsTestsStore.QueryKeys.ISSUER]: listParams.filters.createdByList,
      [ScreeningsTestsStore.QueryKeys.TAG]: listParams.filters.tagNames,
      [ScreeningsTestsStore.QueryKeys.TITLE]: listParams.filters.name.length > 0 ? [listParams.filters.name] : [],
      [ScreeningsTestsStore.QueryKeys.STATUS]: listParams.filters.statuses,
      [ScreeningsTestsStore.QueryKeys.RESULT]: !sameResultAndDefault ? result : [],
      [ScreeningsTestsStore.QueryKeys.RANK_EVALUATION]: listParams.filters.rankEvaluations,
      [ScreeningsTestsStore.QueryKeys.MIN_SCORE]:
        typeof listParams.filters.minTotalScore === "number" && !sameMinTotalScoreAndDefault
          ? [listParams.filters.minTotalScore.toFixed(2)]
          : [],
      [ScreeningsTestsStore.QueryKeys.MAX_SCORE]:
        typeof listParams.filters.maxTotalScore === "number" && !sameMaxTotalScoreAndDefault
          ? [listParams.filters.maxTotalScore.toFixed(2)]
          : [],
      [ScreeningsTestsStore.QueryKeys.SORT]: !sameSortValues ? sortValues : [],
      /**
       * Set page number to "1" if it is not a page change
       */
      [ScreeningsTestsStore.QueryKeys.PAGE]: listParams.page > 0 ? [(listParams.page + 1).toString()] : [],
      [ScreeningsTestsStore.QueryKeys.SIZE]:
        listParams.size !== ScreeningsTestsStore.defaultState.listParams.size ? [listParams.size.toString()] : [],
      [ScreeningsTestsStore.QueryKeys.SCREENING_IDS]: listParams.filters.screeningIds,
      [ScreeningsTestsStore.QueryKeys.SUSPICIOUS_DEGREES]: listParams.filters.suspiciousDegrees,
    });
  });
};

const fetchDownloadUrl = async (downloadId: string, exportFileType: ScreeningsTestsStore.ExportFileType): Promise<string | null> => {
  const client = getGraphqlClient();
  const { t } = getTranslation();
  try {
    return backOff(
      async () => {
        const res = await client.GetTestResultTable({
          id: downloadId,
        });
        if (res.testResultTable.status === "COMPLETE") {
          return res.testResultTable.downloadUrl;
        }
        return Promise.reject();
      },
      {
        delayFirstAttempt: true,
        timeMultiple: 1,
        /**
         * Adjust polling total time to 20 seconds
         * Total Waiting time = retryCount * pollingInterval Milliseconds
         **/
        numOfAttempts: 10,
        /**
         * You have to wait 4000ms to output CSV.
         */
        maxDelay: 4000,
        /**
         * Polling interval milli seconds
         */
        startingDelay: 2000,
      },
    );
  } catch (error) {
    ScreeningsTestsStore.DownloadAction.updateStatus("FAILED");
    Snackbar.notify({
      severity: "error",
      message: {
        [Graphql.ExportFileType.Csv]: t("CSVのダウンロードに失敗しました。"),
        [Graphql.ExportFileType.Excel]: t("Excelのダウンロードに失敗しました。"),
      }[exportFileType],
    });
    Sentry.captureException(error);
    return null;
  }
};

export const startSubscribeDownloadId = () => {
  return ScreeningsTestsStore.subscribeDownloadId(async (downloadId, exportFileType) => {
    const { t } = getTranslation();
    const { t: t2 } = getTranslationWithVariable();
    const downloadUrl = await fetchDownloadUrl(downloadId, exportFileType);
    if (!downloadUrl) {
      return;
    }
    const fileNameMap: Record<Graphql.ExportFileType, string> = {
      [Graphql.ExportFileType.Csv]: "data.csv",
      [Graphql.ExportFileType.Excel]: "data.xlsx",
    };
    try {
      ScreeningsTestsStore.DownloadAction.updateDownloadUrl(downloadUrl);
      await downloadFile(downloadUrl, fileNameMap[exportFileType]);
      ScreeningsTestsStore.DownloadAction.updateStatus("SUCCESS");
      const messageMap: Record<Graphql.ExportFileType, string> = {
        [Graphql.ExportFileType.Csv]: t("CSVのダウンロードに成功しました"),
        [Graphql.ExportFileType.Excel]: t("Excelのダウンロードに成功しました"),
      };
      Snackbar.notify({
        severity: "success",
        message: messageMap[exportFileType],
      });
    } catch (error) {
      const exportFileTypeNameMap: Record<Graphql.ExportFileType, string> = {
        [Graphql.ExportFileType.Csv]: "CSV",
        [Graphql.ExportFileType.Excel]: "Excel",
      };
      Snackbar.notify({
        severity: "error",
        message: t2("FailedAutomaticDownloadContent", { target: exportFileTypeNameMap[exportFileType] }),
      });
      Sentry.captureException(error);
    }
  });
};
