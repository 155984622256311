import { useTranslation } from "@hireroo/i18n";

import { OnboardingGuideProps } from "../../modules/OnboardingGuide/OnboardingGuide";

export const useGenerateChallengeOnboardingGuideProps = (): OnboardingGuideProps => {
  const { t } = useTranslation();
  return {
    steps: [
      {
        title: t("チュートリアルへようこそ！"),
        content: [
          t("このチュートリアルでは以下の内容を説明しています。") + t("試験中に不明な点がある場合は参照してください。"),
          `* ${t("言語の選択")}`,
          `* ${t("制限時間")}`,
          `* ${t("コードの実行")} / ${t("コードの提出")}`,
          `* ${t("入力値の設定")} / ${t("出力の確認")}`,
          `* ${t("ペーンの調整")}`,
          `* ${t("ヘルプセンター")}`,
          "",
          t("このチュートリアルは何度でも利用できます。"),
        ].join("\n"),
        placement: "center",
        styles: {
          options: {
            width: 600,
          },
        },
        target: "body",
      },
      {
        title: t("言語の選択"),
        content: t("好きな言語を選択し問題を解くことが可能です。"),
        placement: "bottom",
        target: "#language-select-native",
      },
      {
        title: t("入力値の設定"),
        content: t(
          "入力値の実行、追加、削除が可能です。各入力値は右側に表示されます。初期値に戻すことで自分で追加したテストケースが一括削除可能です。",
        ),
        placement: "top",
        target: "#challenge-console-input",
      },
      {
        title: t("出力の確認"),
        content: t("コードを実行した際の出力値を確認することができます。出力したログもこちらのコンソールで確認可能です。"),
        placement: "top",
        target: "#challenge-console-output",
      },
      {
        title: t("ペーンの調整"),
        content: t("こちらをクリックし、動かすことでペーンの幅を調整することができます。コンソールも同様に幅の調整が可能です。"),
        placement: "right",
        target: ".tutorial-pane",
      },
      {
        title: t("コードの実行"),
        content: t("設定したすべての入力値でコードを実行します。"),
        placement: "top",
        target: "#code-execute-button",
      },
      {
        title: t("コードの提出"),
        content: t("コードの提出を行います。何度でもコードを提出することができます。"),
        placement: "top",
        target: "#code-submit-button",
      },
      {
        title: t("制限時間"),
        content: t("問題には制限時間があります。制限時間を過ぎるとそれ以降の問題は解答することはできませんのでご注意ください。"),
        placement: "bottom",
        target: "#timelimit-box",
      },
      {
        title: t("ヘルプセンター"),
        content: t(
          "こちらのボタンを押すとヘルプセンターが開かれます。分からないことがありましたらこちらにアクセスし、記事を参照するか、右下のチャットからお問い合わせください。",
        ),
        placement: "left",
        target: "#help-button",
      },
      {
        title: t("チュートリアル終了"),
        content: t("これでチュートリアルは終了です。再度確認したい場合は、もう一度ボタンを押しチュートリアルを開始してください。"),
        placement: "center",
        target: "body",
      },
    ],
  };
};
