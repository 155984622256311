import { subscribeKey } from "valtio/utils";

import { state } from "./State";
import type * as Types from "./types";

export type SubscribeCurrentSubmissionCallback = (submission: Types.Submission) => void;

export const subscribeCurrentSubmission = (projectId: Types.ProjectId, callback: SubscribeCurrentSubmissionCallback) => {
  const projectState = state.projectMap.get(projectId);
  if (!projectState) {
    console.warn(`[subscribeCurrentSubmission] Can not subscribe. projectId=${projectId} is not initialized`);
    return () => undefined;
  }
  return subscribeKey(projectState, "sourceFileFetchStatus", fetchStatus => {
    if (fetchStatus !== "CAN_FETCH" || !projectState.currentSubmission) {
      return;
    }
    return callback(projectState.currentSubmission);
  });
};
