import { useTitle } from "@hireroo/app-helper/react-use";
import { App } from "@hireroo/app-store/essential/employee";
import { EmployeeSideBarContent } from "@hireroo/app-store/widget/e/EmployeeSideBarContent";
import { useTranslation } from "@hireroo/i18n";
import { Pages } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";

import CompanySettingsNavigatorContainer from "../../../../widget/v2/e/CompanySettingsNavigator/Container";
import EmployeeHeaderRightContentContainer from "../../../../widget/v2/e/EmployeeHeaderRightContent/Container";
import EmployeeSideBarContentContainer from "../../../../widget/v2/e/EmployeeSideBarContent/Container";
import FooterContainer from "../../../../widget/v2/e/Footer/Container";
import { useNotificationBanner } from "../../../../widget/v2/e/NotificationBanner/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import UsagesFetchContainer from "./widgets/Usages/FetchContainer";

export type GenerateBillingUsagePropsArgs = {};

export const useGenerateProps = (_args: GenerateBillingUsagePropsArgs): Pages.CompanySettingsProps => {
  const { t } = useTranslation();
  const navigate = useTransitionNavigate();
  const loading = App.useStatus() === "INITIALIZING";
  const NotificationBannerContainer = useNotificationBanner();

  useTitle(t("使用量"));

  return {
    layout: {
      openSidebar: EmployeeSideBarContent.useOpenSidebar(),
      onChangeOpenSidebar: open => {
        EmployeeSideBarContent.setOpenSidebar(open);
      },
      loading: loading,
      NotificationBanner: NotificationBannerContainer,
      HeaderRightContent: <EmployeeHeaderRightContentContainer />,
      SideBarContent: <EmployeeSideBarContentContainer />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      sideBarHeader: {
        logo: {
          href: generatePath("/e/home"),
          onClick: () => {
            navigate("/e/home");
          },
        },
      },
    },
    settingsLayout: {
      HeaderMenu: <CompanySettingsNavigatorContainer active="BILLING" />,
      sideBar: {
        active: "usage",
        items: [
          {
            id: "overview",
            label: t("概要"),
            href: generatePath("/e/settings/billing/overview"),
            onClick: () => {
              navigate("/e/settings/billing/overview");
            },
          },
          {
            id: "usage",
            label: t("使用量"),
            href: generatePath("/e/settings/billing/usages"),
            onClick: () => {
              navigate("/e/settings/billing/usages");
            },
          },
          {
            id: "invoices",
            label: t("請求書一覧"),
            href: generatePath("/e/settings/billing/invoices"),
            onClick: () => {
              navigate("/e/settings/billing/invoices");
            },
          },
          {
            id: "order-history",
            label: t("注文履歴"),
            href: generatePath("/e/settings/order/history"),
            onClick: () => {
              navigate("/e/settings/order/history");
            },
          },
        ],
      },
    },
    children: <UsagesFetchContainer />,
  };
};
