import { usePlanNameMap } from "@hireroo/app-definition/payment";
import { Def } from "@hireroo/app-helper/payment";
import { PlanUpdateForm } from "@hireroo/app-store/widget/e/PlanUpdateForm";
import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as React from "react";
export type GeneratePaymentCompletionPropsArgs = {};

export const useGenerateProps = (_args: GeneratePaymentCompletionPropsArgs): Widget.PaymentCompletionProps => {
  const temporarySubmitValue = PlanUpdateForm.useTemporarySubmitValue();
  const plans = PlanUpdateForm.usePlans();
  const customer = PlanUpdateForm.useCustomer();
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const navigate = useTransitionNavigate();
  const planNameMap = usePlanNameMap(customer.subscription.plan.generation);
  const selectedPlan = React.useMemo(() => {
    return plans.find(plan => {
      return Def.planTypeMapGraphqlToValidator[plan.planType] === temporarySubmitValue?.plan;
    });
  }, [plans, temporarySubmitValue?.plan]);

  const planName = selectedPlan ? planNameMap[selectedPlan.planType] : "";

  return {
    title: t("ご購入ありがとうございました。"),
    message: [t2("contractedPlan", { planName }), t("新しいプランを利用するためにはページのリロードが必要です。")].join(""),
    link: {
      href: generatePath("/e/settings/billing/overview"),
      onClick: () => {
        PlanUpdateForm.clear();
        navigate("/e/settings/billing/overview");
      },
      children: t("お支払い概要に戻る"),
    },
  };
};
