import { colorFromUserId } from "@hireroo/app-helper/color";
import { RemoteInterviewParticipants } from "@hireroo/app-store/widget/shared/RemoteInterviewParticipants";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

export type GenerateRemoteInterviewParticipantsPropsArgs = {};

type Participant = Widget.RemoteInterviewParticipantsProps["participants"][0];

export const useGenerateProps = (): Widget.RemoteInterviewParticipantsProps => {
  const participantsSource = RemoteInterviewParticipants.useParticipants();
  const userInfoMap = RemoteInterviewParticipants.useUserInfoMap();
  const participants = React.useMemo(() => {
    return participantsSource.map((user): Participant => {
      const userInfo = userInfoMap.get(user.id);
      return {
        id: user.id,
        displayText: user.displayName,
        /**
         * If it is not a valid URL, the first letter will be displayed in Avatar.
         */
        src: userInfo?.employee?.photoUrl || user.displayName,
        alt: user.displayName,
        color: colorFromUserId(user.id),
      };
    });
  }, [participantsSource, userInfoMap]);
  return {
    participants,
  };
};
