import { eachMonthOfInterval, endOfMonth, startOfMonth } from "date-fns";

export type Period = {
  from: Date;
  fromSeconds: number;
  to: Date;
};

export const getPeriodList = (from: Date, to: Date): Period[] => {
  const interval = eachMonthOfInterval({
    start: from,
    end: to,
  });

  // Replace the first element with the contract date
  // and the last element with today's date.
  interval[0] = from;
  interval[interval.length - 1] = startOfMonth(to);

  // Sort by desc with date.
  interval.sort((a, b) => {
    return b.getTime() - a.getTime();
  });

  // Limit to 1year
  return interval
    .map<Period>(date => {
      return { from: date, fromSeconds: Math.floor(date.getTime() / 1000), to: endOfMonth(date) };
    })
    .slice(0, 11);
};

export const toSeconds = (dateStr: string): number => {
  const period = new Date(Date.parse(dateStr));
  const seconds = Math.floor(period.getTime() / 1000);
  return seconds;
};
