import { AlgorithmAnswerForm, AlgorithmSignatureForm, AlgorithmTestCaseForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import * as React from "react";
import { useForm, UseFormReturn } from "react-hook-form";

type AlgorithmSignatureProps = {
  variant: "ALGORITHM";
  signature: AlgorithmSignatureForm.AlgorithmSignatureSchemaType;
};
type DatabaseSignatureProps = {
  variant: "DATABASE";
  signature: AlgorithmSignatureForm.DatabaseSignatureSchemaType;
};
type ClassSignatureProps = {
  variant: "CLASS";
  signature: AlgorithmSignatureForm.ClassSignatureSchemaType;
};
type SignatureProps = AlgorithmSignatureProps | ClassSignatureProps | DatabaseSignatureProps;

export type ContextValue = {
  methods: UseFormReturn<AlgorithmAnswerForm.AlgorithmAnswersFormSchema>;
  signatureProps: SignatureProps;
  correctnessTestCases: AlgorithmTestCaseForm.CorrectnessTestCasesSchema;
  performanceTestCases: AlgorithmTestCaseForm.PerformanceTestCasesSchema;
  selectedAnswerIndex: string;
  selectedRuntimeIndex: string;
  updateSelectedAnswerIndex: (value: string) => void;
  updateSelectedRuntimeIndex: (value: string) => void;
};

export const AlgorithmAnswersFormContext = React.createContext<ContextValue>({} as ContextValue);

export const useAlgorithmAnswersFormContext = () => React.useContext(AlgorithmAnswersFormContext);

export type AlgorithmAnswersFormProviderProps = {
  defaultValues: AlgorithmAnswerForm.AlgorithmAnswersFormSchema;
  signatureProps: SignatureProps;
  correctnessTestCases: AlgorithmTestCaseForm.CorrectnessTestCasesSchema;
  performanceTestCases: AlgorithmTestCaseForm.PerformanceTestCasesSchema;
};

const defaultIndex = "0";

export const AlgorithmAnswersFormProvider: React.FC<React.PropsWithChildren<AlgorithmAnswersFormProviderProps>> = props => {
  const { defaultValues } = props;
  const schema = AlgorithmAnswerForm.useAnswersFormSchema();
  const methods = useForm<AlgorithmAnswerForm.AlgorithmAnswersFormSchema>({
    resolver: zodResolver(schema),
    mode: "onSubmit",
    shouldUnregister: false,
    defaultValues: props.defaultValues,
  });
  const [selectedAnswer, updateSelectedAnswer] = React.useState<string>(defaultIndex);
  const [selectedRuntime, updateSelectedRuntime] = React.useState<string>(defaultIndex);

  React.useEffect(() => {
    methods.setValue("answers", defaultValues.answers);
  }, [methods, defaultValues]);

  const contextValue: ContextValue = {
    methods: methods,
    signatureProps: props.signatureProps,
    correctnessTestCases: props.correctnessTestCases,
    performanceTestCases: props.performanceTestCases,
    selectedAnswerIndex: selectedAnswer,
    selectedRuntimeIndex: selectedRuntime,
    updateSelectedAnswerIndex: React.useCallback(value => {
      updateSelectedAnswer(value);
      //initialize runtime when the tab index changes
      updateSelectedRuntime(defaultIndex);
    }, []),
    updateSelectedRuntimeIndex: React.useCallback(value => {
      updateSelectedRuntime(value);
    }, []),
  };
  return <AlgorithmAnswersFormContext.Provider value={contextValue} children={props.children} />;
};
