import { ClassSignatureSchemaType } from "../react-hook-form/AlgorithmSignatureForm";
import { isAlgorithmTestCaseValid, separateCurrentScope } from "./ValidateAlgorithmFunctionVariantInput";

type ClassInputType = {
  name: string;
  type: string;
};

type GenericSignature = {
  inputs: ClassInputType[];
};

type SignatureMap = {
  [key: string]: GenericSignature;
};

type validateClassVariantInputResponse = {
  isValid: boolean;
  returnCode: string;
};

const ReturnCode = {
  INVALID_INPUT_LENGTH_IS_NOT_TWO: "INVALID_INPUT_LENGTH_IS_NOT_TWO",
  INVALID_INPUT_LENGTH_IS_NOT_SAME: "INVALID_INPUT_LENGTH_IS_NOT_SAME",
  INVALID_INPUT_METHOD_NAME_NOT_FOUND: "INVALID_INPUT_METHOD_NAME_NOT_FOUND",
  INVALID_INPUT_FIRST_METHOD_INPUT_SHOULD_BE_CLASS_NAME: "INVALID_INPUT_FIRST_METHOD_INPUT_SHOULD_BE_CLASS_NAME",
  INVALID_INPUT_VALUE_HAS_NOT_SUPPORTED_TYPE: "INVALID_INPUT_VALUE_HAS_NOT_SUPPORTED_TYPE",
  VALID_INPUT: "VALID_INPUT",
};

export type ClassInputValidationReturnCodeType =
  | "INVALID_INPUT_LENGTH_IS_NOT_TWO"
  | "INVALID_INPUT_LENGTH_IS_NOT_SAME"
  | "INVALID_INPUT_METHOD_NAME_NOT_FOUND"
  | "INVALID_INPUT_FIRST_METHOD_INPUT_SHOULD_BE_CLASS_NAME"
  | "INVALID_INPUT_VALUE_HAS_NOT_SUPPORTED_TYPE"
  | "VALID_INPUT";

export const validateClassVariantInput = (inputs: string[], signature: ClassSignatureSchemaType): validateClassVariantInputResponse => {
  if (inputs.at(0) === "" || inputs.at(1) === "") {
    return { isValid: false, returnCode: ReturnCode.INVALID_INPUT_LENGTH_IS_NOT_TWO };
  }
  // Class signature has two inputs, one for method name includes constructor and one for method arguments.
  if (inputs.length !== 2) {
    return { isValid: false, returnCode: ReturnCode.INVALID_INPUT_LENGTH_IS_NOT_TWO };
  }

  // Create a map for method signature. Include constructor signature in the map as well.
  const methodSignatureMap: SignatureMap = {};
  methodSignatureMap[signature.class] = signature.constructor;

  for (let i = 0; i < signature.methods.length; i++) {
    const method = signature.methods[i];
    methodSignatureMap[method.name] = method;
  }

  const methodList = separateCurrentScope(inputs[0], ",");
  const inputList = separateCurrentScope(inputs[1], ",");

  // Method list and input list should have same length.
  if (methodList.length !== inputList.length) {
    return { isValid: false, returnCode: ReturnCode.INVALID_INPUT_LENGTH_IS_NOT_SAME };
  }

  for (let i = 0; i < methodList.length; i++) {
    const escapedMethod = methodList[i];
    // Input string is escaped with double quotes. Remove them.
    // Like '["SampleClass", "put"]' => ["SampleClass", "put"]
    const unescapedMethod = escapedMethod.replace(/^"|"$/g, "");
    // Get signature of the method.
    const sig = methodSignatureMap[unescapedMethod];

    if (i === 0) {
      // Method list should have class name as first element.
      if (unescapedMethod !== signature.class) {
        return { isValid: false, returnCode: ReturnCode.INVALID_INPUT_FIRST_METHOD_INPUT_SHOULD_BE_CLASS_NAME };
      }
    }

    if (sig === undefined) {
      return { isValid: false, returnCode: ReturnCode.INVALID_INPUT_METHOD_NAME_NOT_FOUND };
    }

    const args = separateCurrentScope(inputList[i], ",");
    for (let j = 0; j < sig.inputs.length; j++) {
      if (!isAlgorithmTestCaseValid(args[j], sig.inputs[j].type)) {
        return { isValid: false, returnCode: ReturnCode.INVALID_INPUT_VALUE_HAS_NOT_SUPPORTED_TYPE };
      }
    }
  }

  return { isValid: true, returnCode: ReturnCode.VALID_INPUT };
};
