import { useTranslation } from "@hireroo/i18n";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import TalentLayout, { TalentLayoutProps } from "../../layouts/TalentLayout/TalentLayout";

const HeadingTypography = styled(Typography)(() => ({
  fontWeight: "bold",
  fontSize: 24,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
}));

const StyledButton = styled(Button)(() => ({
  height: 36,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
}));

export type AssessmentListForTalentProps = React.PropsWithChildren<{
  layout: TalentLayoutProps;
  helpButton: Pick<ButtonProps, "onClick">;
}>;

const AssessmentListForTalent: React.FC<AssessmentListForTalentProps> = props => {
  const { t } = useTranslation();
  const helpButtonProps: ButtonProps = {
    ...props.helpButton,
    startIcon: <HelpOutlineOutlinedIcon />,
    variant: "text",
    sx: theme => ({ color: theme.palette.text.secondary }),
  };
  return (
    <TalentLayout {...props.layout}>
      <Box p={3}>
        <Box display="flex" justifyContent="space-between" alignContent="center" mb={3}>
          <HeadingTypography>{t("タレントスコア")}</HeadingTypography>
          <Stack direction="row" spacing={2}>
            <StyledButton {...helpButtonProps}>{t("ヘルプ")}</StyledButton>
          </Stack>
        </Box>
        <Box>{props.children}</Box>
      </Box>
    </TalentLayout>
  );
};

AssessmentListForTalent.displayName = "AssessmentListForTalent";

export default AssessmentListForTalent;
