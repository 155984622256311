import { useTranslation } from "@hireroo/i18n";
import * as React from "react";

import TestCaseDialog, { TestCaseDialogProps } from "../../primitive/TestCaseDialog/TestCaseDialog";

export type DefaultTestCaseDialogProps = {
  open: boolean;
  onClose: TestCaseDialogProps["onClose"];
  children: React.ReactNode;
};

const DefaultTestCaseDialog: React.FC<DefaultTestCaseDialogProps> = props => {
  const { t } = useTranslation();
  const testCaseDialogProps: Omit<TestCaseDialogProps, "children"> = {
    open: props.open,
    title: t("テストケースの結果"),
    description: t("テストの結果を確認してください。実行結果に問題がある場合は、可能な限りコードをデバッグし提出してください。"),
    onClose: props.onClose,
  };
  return <TestCaseDialog {...testCaseDialogProps}>{props.children}</TestCaseDialog>;
};

DefaultTestCaseDialog.displayName = "DefaultTestCaseDialog";

export default DefaultTestCaseDialog;
