import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import * as React from "react";

import ChallengeIDEConsoleInputTable, {
  ChallengeIDEConsoleInputTableProps,
} from "../../ChallengeIDEConsoleInputTable/ChallengeIDEConsoleInputTable";

export type TableInputDialogProps = {
  title: string;
  open: boolean;
  onClose: () => void;
  inputTable: ChallengeIDEConsoleInputTableProps;
};

const TableInputDialog: React.FC<TableInputDialogProps> = props => {
  const { t } = useTranslation();
  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="md">
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent sx={{ minWidth: 400 }}>
        <Typography variant="subtitle2" mb={2}>
          {t("テストケースのテーブルの値を入力")}
        </Typography>
        <Box
          minWidth="40vw"
          sx={{
            display: "flex",
            flexDirection: "column",
            m: "auto",
          }}
        >
          <ChallengeIDEConsoleInputTable {...props.inputTable} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={props.onClose}>
          {t("完了")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TableInputDialog.displayName = "TableInputDialog";

export default TableInputDialog;
