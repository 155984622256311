import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import Button, { ButtonProps } from "@mui/material/Button";
import ButtonGroup, { ButtonGroupProps } from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";

const HEIGHT = 36;

const StyledButton = styled(LoadingButton)(() => ({
  textTransform: "none",
  height: HEIGHT,
  paddingTop: "6px",
  paddingBottom: "6px",
  wordBreak: "keep-all",
}));

const ChangeButton = styled(Button)(() => ({
  textTransform: "none",
  paddingTop: "6px",
  paddingBottom: "6px",
  paddingLeft: "12px",
  paddingRight: "12px",
  width: "40px",
  height: HEIGHT,
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&.MuiMenuItem-root": {
    "&:hover ": {
      backgroundColor: theme.palette["Secondary/Shades"].p8,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette["Secondary/Shades"].p16,
    },
  },
}));

type ItemProps = Pick<ButtonProps, "disabled" | "onClick" | "startIcon"> & {
  children: string;
  title?: string;
  onSelected?: () => void;
};

export type SelectableButtonProps = {
  className?: string;
  /**
   * @default secondary
   */
  color?: ButtonGroupProps["color"];
  variant?: ButtonGroupProps["variant"];
  loading?: boolean;
  disabled?: boolean;
  title: string;
  items: ItemProps[];
  ChangeButtonProps?: Pick<ButtonProps, "className">;
};

const SelectableButton: React.FC<SelectableButtonProps> = props => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const buttonProps = React.useMemo((): LoadingButtonProps | undefined => {
    const currentButtonProps = props.items.at(selectedIndex);
    if (!currentButtonProps) {
      return undefined;
    }
    const { title: _, ...rest } = currentButtonProps;
    return {
      ...rest,
      variant: props.variant ?? "contained",
      loading: props.loading,
      size: "small",
    };
  }, [props.items, props.variant, props.loading, selectedIndex]);

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const changeButton: ButtonProps = {
    ...props.ChangeButtonProps,
    variant: props.variant ?? "contained",
    size: "small",
    onClick: () => {
      setOpen(prevOpen => !prevOpen);
    },
    children: <ArrowDropDownIcon />,
  };

  return (
    <React.Fragment>
      <Tooltip title={props.title ?? ""}>
        <span>
          <ButtonGroup
            variant={props.variant}
            ref={anchorRef}
            color={props.color ?? "secondary"}
            disabled={props.disabled}
            className={props.className}
          >
            <StyledButton {...buttonProps} />
            <ChangeButton {...changeButton} />
          </ButtonGroup>
        </span>
      </Tooltip>
      <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem>
                  {props.items.map((item, index) => {
                    const menuItemProps: MenuItemProps = {
                      selected: index === selectedIndex,
                      disabled: item.disabled,
                      onClick: () => {
                        setSelectedIndex(index);
                        item.onSelected?.();
                        setOpen(false);
                      },
                      children: item.children,
                    };
                    return (
                      <Tooltip role="tooltip" title={item.title ?? ""} key={`${item.children}-${index}`}>
                        <span>
                          <StyledMenuItem {...menuItemProps} />
                        </span>
                      </Tooltip>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};

SelectableButton.displayName = "SelectableButton";

export default SelectableButton;
