import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { ScreeningsTestsIdDetailStore } from "@hireroo/app-store/page/e/screenings_tests_id_detail";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import type * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import * as Sentry from "@sentry/browser";
import * as React from "react";

export type ExportPdfArgs = {
  spotId: string;
  outputFilename: ScreeningsTestsIdDetailStore.ExportPdfState["outputFilename"];
  language: Graphql.ExportLanguage;
};

export const useExportPdf = (args: ExportPdfArgs) => {
  const client = getGraphqlClient();
  const { t } = useTranslation();
  const runPdfExport = React.useCallback(() => {
    client
      .ExportTestReportPdfForInterviewsIdDetail({
        spotId: args.spotId,
        exportLanguage: args.language,
      })
      .then(res => {
        ScreeningsTestsIdDetailStore.setPdfExport(res.exportTestReportPdf.id, args.spotId, args.outputFilename);
        Snackbar.notify({
          severity: "info",
          message: t("PDFのダウンロードを開始しました。ダウンロードには数十秒かかることがあります。"),
        });
      })
      .catch(error => {
        Sentry.captureException(error);
        const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("PDFのダウンロードに失敗しました。"));
        Snackbar.notify({
          severity: "error",
          message: errorNotification.message,
        });
      });
  }, [args.language, args.outputFilename, args.spotId, client, t]);

  return {
    runPdfExport,
  };
};
