import BaseIconButton, { IconButtonProps as BaseIconButtonProps } from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";

export type IconButtonProps = Omit<BaseIconButtonProps, "title"> & {
  title?: string | false | null;
  component?: "span";
};

const IconButton: React.FC<IconButtonProps> = ({ title, ...props }) => {
  if (!title) {
    return <BaseIconButton {...props} />;
  }
  return (
    <Tooltip role="tooltip" title={title}>
      <span>
        <BaseIconButton {...props} />
      </span>
    </Tooltip>
  );
};

IconButton.displayName = "IconButton";

export default IconButton;
