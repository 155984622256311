import { colorFromUserId } from "@hireroo/app-helper/color";
import { useTwilioConversationsContext } from "@hireroo/app-helper/hooks";
import { LiveCodingChatWindow } from "@hireroo/app-store/widget/c/LiveCodingChatWindow";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/react";

export type GenerateLiveCodingChatWindowPropsArgs = {};

export const useGenerateProps = (_args: GenerateLiveCodingChatWindowPropsArgs): Widget.LiveCodingChatWindowProps => {
  const { t } = useTranslation();
  const participantsSource = LiveCodingChatWindow.useParticipants();
  const userInfoMap = LiveCodingChatWindow.useUserInfoMap();
  const { messages: chatMessages, sendMessage, readMessages } = useTwilioConversationsContext();

  return {
    helperText: t("メッセージがありません。面接官とのコミュニケーションにチャットを使用できます。"),
    messageList: {
      messages: chatMessages.reduce<Widget.LiveCodingChatWindowProps["messageList"]["messages"]>((acc, message) => {
        const user = participantsSource.find(participant => participant.id === message.author);
        const userInfo = userInfoMap.get(user?.id ?? "");
        const displayName = user?.displayName || t("匿名ユーザー");
        return acc.concat({
          id: message.sid,
          author: {
            id: message.author || "unknown",
            displayName: displayName,
            photoUrl: userInfo?.employee?.photoUrl ?? "",
            color: colorFromUserId(user?.id || displayName),
          },
          displayTime:
            message.dateCreated
              ?.toLocaleTimeString("en-GB", {
                hour: "numeric",
                minute: "numeric",
              })
              .toLowerCase() ?? "",
          type: message.type,
          body: message.body ?? "",
        });
      }, []),
      onScrollToBottom: () => {
        readMessages();
      },
    },
    chatInput: {
      onInput: payload => {
        sendMessage(payload.message).catch(error => {
          Snackbar.notify({
            message: t("メッセージの送信に失敗しました。しばらくしてから再度お試しください。"),
            severity: "error",
          });
          Sentry.captureException(error);
        });
      },
    },
  };
};
