import { subscribeKey } from "valtio/utils";

import { clearSubmittedEntity } from "./Action";
import { state } from "./State";
import * as Types from "./types";

export type SubscribeOnSubmitEntityCallback = (entity: Types.SystemDesignEntityForCandidateInterview) => void;
export const subscribeOnSubmitEntity = (callback: SubscribeOnSubmitEntityCallback) => {
  return subscribeKey(state, "submittedEntity", submittedEntity => {
    if (submittedEntity) {
      callback(submittedEntity);
      clearSubmittedEntity();
    }
  });
};
