import { QUERY_PARAMS_FOR_DEMO } from "@hireroo/app-definition/demo";
import { QUERY_PARAMS_FOR_NOTIFY } from "@hireroo/app-definition/spot";
import { compress } from "@hireroo/app-helper/lz-string";
import { InterviewsIdStore } from "@hireroo/app-store/page/c/interviews_id";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import type * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import type { Pages } from "@hireroo/presentation";
import { generatePath, navigate as nativeNavigate, updateQueryParams } from "@hireroo/router/api";
import { StartDemoForm } from "@hireroo/validator";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import CandidateFooterContainer from "../../../../../../widget/v2/c/CandidateFooter/Container";
import CandidateNavigationContainer from "../../../../../../widget/v2/c/CandidateNavigation/Container";
import SnackbarContainer from "../../../../../../widget/v2/shared/Snackbar/Container";
import { useGenerateTestOverviewSectionProps } from "./useGenerateTestOverviewSectionProps";

export type GenerateEntryScreeningTestPropsArgs = {
  spot: Graphql.SpotForEntryScreeningsTestsIdFragment;
};

export const useGenerateProps = (args: GenerateEntryScreeningTestPropsArgs): Pages.EntryScreeningTestProps => {
  const { spot } = args;
  const [searchParams] = useSearchParams();
  const [notify] = React.useState(searchParams.get(QUERY_PARAMS_FOR_NOTIFY.key));
  const { t } = useTranslation();
  const client = getGraphqlClient();
  const testOverviewSectionProps = useGenerateTestOverviewSectionProps({
    spot,
  });

  React.useEffect(() => {
    updateQueryParams(QUERY_PARAMS_FOR_NOTIFY.key, []);
  }, [searchParams]);

  const demoUrl = React.useMemo((): string => {
    const params: StartDemoForm.StartDemoParams = {
      kind: "SPOT",
      spotId: spot.id,
    };
    const value = compress(JSON.stringify(params));
    return generatePath("/c/demos/start", {
      queryParams: {
        [QUERY_PARAMS_FOR_DEMO.key]: value,
      },
    });
  }, [spot.id]);

  return {
    layout: {
      NotificationBanner: null,
      Header: <CandidateNavigationContainer />,
      Footer: <CandidateFooterContainer />,
      Snackbar: <SnackbarContainer />,
      Tutorial: null,
    },
    testOverviewSection: testOverviewSectionProps,
    testTaker: {
      name: spot.candidateName,
      mailAddress: spot.candidateEmail,
    },
    openNotifyDialog: notify === QUERY_PARAMS_FOR_NOTIFY.value,
    startSection: {
      onStart: async controller => {
        controller.setLoading(true);
        const res = await client
          .StartSpotForScreeningsId({
            input: {
              spotId: spot.id,
              candidateName: spot.candidateName,
              emailAddress: spot.candidateEmail,
            },
          })
          .catch(error => {
            Sentry.captureException(error);
            Snackbar.notify({
              severity: "error",
              message: t("テストの開始に失敗しました。お手数ですがヘルプセンターより運営にお問い合わせください。"),
            });
            return null;
          });

        if (res === null) {
          controller.setLoading(false);
          return;
        }

        await client
          .GetSpotForCandidateInterview({
            id: res.startSpot.id,
            withAnswers: false,
            ignoreInheritance: true,
          })
          .then(res => {
            InterviewsIdStore.setSpot(res.spot);
            Snackbar.notify({
              severity: "success",
              message: t("テストを開始しました。"),
            });
          })
          .catch(error => {
            Sentry.captureException(error);
            Snackbar.notify({
              severity: "error",
              message: t("テストの開始に失敗しました。お手数ですがヘルプセンターより運営にお問い合わせください。"),
            });
          })
          .finally(() => {
            controller.setLoading(false);
          });
      },
      startDemoButton: {
        onClick: () => {
          nativeNavigate(demoUrl, { _blank: true });
        },
      },
    },
  };
};
