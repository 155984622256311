import type { PlaybackTickEvent } from "@hireroo/app-helper/playback";
import { ChallengePlayback } from "@hireroo/app-store/widget/shared/ChallengePlayback";
import * as Time from "@hireroo/formatter/time";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

type DataSource = Widget.EventFrequencyTimelinePanelProps["dataSources"][0];

/**
 * Map<TimeStamp, Count>
 */
type AccumulatorDataMap = Map<number, number>;

type AccumulatorKind = "CHATGPT" | "WEB_SEARCH" | "CODING" | "RUN_CODE" | "USE_HINT" | "PASTE" | "LEAVING_PAGE";

const accumulatorKindMap: Record<PlaybackTickEvent["kind"], AccumulatorKind> = {
  CHATGPT_REQUEST: "CHATGPT",
  CHATGPT_RESPOND: "CHATGPT",
  CHATGPT_RESET: "CHATGPT",
  WEB_SITE_SEARCH: "WEB_SEARCH",
  EXTERNAL_WEB_SITE_ACCESS: "WEB_SEARCH",
  CODE_EDITOR: "CODING",
  EDITOR_COPY: "CODING",
  EDITOR_CUT: "CODING",
  EDITOR_PASTE: "PASTE",
  USE_HINT: "USE_HINT",
  SUBMIT_QUESTION: "CODING",
  RUN_CODE: "RUN_CODE",
  ACCESS: "CODING",
  BROWSER_BLUR: "LEAVING_PAGE",
  BROWSER_HIDDEN: "LEAVING_PAGE",
  BROWSER_VISIBLE: "CODING",
  BROWSER_FOCUS: "CODING",
};

const INTERVAL_MILLISECONDS = 15000;

export type GeneratePasteEventFrequencyTimelinePanelPropsArgs = {};

export const useGenerateProps = (_args: GeneratePasteEventFrequencyTimelinePanelPropsArgs): Widget.EventFrequencyTimelinePanelProps => {
  const { t } = useTranslation();
  const playbackManager = ChallengePlayback.usePlaybackManager();
  const translationMap = React.useMemo((): Record<AccumulatorKind, string> => {
    return {
      CHATGPT: "ChatGPT",
      WEB_SEARCH: t("Google検索"),
      CODING: t("コーディング"),
      RUN_CODE: t("コードの実行"),
      USE_HINT: t("ヒントの使用"),
      PASTE: t("ペースト"),
      LEAVING_PAGE: t("ページ離脱"),
    };
  }, [t]);

  const dataSources = React.useMemo((): DataSource[] => {
    const startTime = playbackManager.timeStamps.at(0) ?? 0;
    const dataSourceMap: Map<AccumulatorKind, AccumulatorDataMap> = new Map();

    playbackManager.ticks.forEach(tick => {
      const elapsedTimeMilliseconds = tick.ts - startTime;
      const currentTsIndex = Math.floor(elapsedTimeMilliseconds / INTERVAL_MILLISECONDS);
      const tsKey = currentTsIndex * INTERVAL_MILLISECONDS;
      const uniqueKind = new Set<string>();
      tick.events.forEach(event => {
        const kind = accumulatorKindMap[event.kind];
        if (uniqueKind.has(kind)) {
          return;
        }
        uniqueKind.add(kind);
        const accumulatorDataMap: AccumulatorDataMap = dataSourceMap.get(kind) || new Map();
        const previousCount = accumulatorDataMap.get(tsKey) ?? 0;
        const newCount = previousCount + 1;
        accumulatorDataMap.set(tsKey, newCount);
        dataSourceMap.set(kind, accumulatorDataMap);
      });
    }, new Map());

    const totalElapsedTimeMilliseconds = (playbackManager.ticks.at(-1)?.ts ?? 0) - startTime;
    const accumulatedTsIndex = Math.floor(totalElapsedTimeMilliseconds / INTERVAL_MILLISECONDS);
    /** 0 padding */
    for (const [kind, dataSource] of dataSourceMap.entries()) {
      for (let i = 0; i < accumulatedTsIndex; i++) {
        const tsKey = i * INTERVAL_MILLISECONDS;
        if (!dataSource.has(tsKey)) {
          dataSource.set(tsKey, 0);
        }
      }
      dataSourceMap.set(kind, dataSource);
    }

    return Array.from(dataSourceMap.entries())
      .map<DataSource>(([kind, accumulatorData]) => {
        return {
          sourceId: kind,
          label: translationMap[kind],
          data: Array.from(accumulatorData.entries())
            .slice()
            .sort(([x1], [x2]) => x1 - x2)
            .map(([x, y]) => ({ x: Time.elapsedTimeFormat(x), y })),
        };
      })
      .slice()
      .sort((a, b) => b.data.length - a.data.length);
  }, [translationMap, playbackManager]);

  return {
    title: t("活動履歴"),
    xLabel: t("経過時間"),
    yLabel: t("イベントの回数"),
    dataSources: dataSources,
  };
};
