import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import * as React from "react";

import TablePagination, { TablePaginationProps } from "../../modules/TablePagination/TablePagination";
import InviteeTable, { InviteeTableProps } from "./parts/InviteeTable/InviteeTable";
import SearchResultBar, { SearchResultBarProps } from "./parts/SearchResultBar/SearchResultBar";

export type ScreeningInviteeListProps = {
  table: InviteeTableProps;
  searchResultBar: SearchResultBarProps;
  pagination: TablePaginationProps;
};

const ScreeningInviteeList: React.FC<ScreeningInviteeListProps> = props => {
  return (
    <Paper sx={{ boxShadow: "none", borderRadius: "16px" }} elevation={2}>
      <SearchResultBar {...props.searchResultBar} />
      <InviteeTable {...props.table} />
      <Box display="flex" justifyContent="flex-end">
        <TablePagination {...props.pagination} />
      </Box>
    </Paper>
  );
};

ScreeningInviteeList.displayName = "ScreeningInviteeList";

export default ScreeningInviteeList;
