import Markdown from "@hireroo/markdown-v2/react";
import Box from "@mui/material/Box";
import * as React from "react";

export type TextMessageProps = {
  body: string;
};

const TextMessage: React.FC<TextMessageProps> = props => {
  return (
    <Box width={"100%"} style={{ overflowWrap: "anywhere", overflowX: "auto" }}>
      <React.Suspense>
        <Markdown children={props.body} size="small" />
      </React.Suspense>
    </Box>
  );
};

TextMessage.displayName = "TextMessage";

export default TextMessage;
