import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { App, Company, Payment } from "@hireroo/app-store/essential/employee";
import { QuestionSelectFieldForResourceEditor } from "@hireroo/app-store/widget/e/QuestionSelectFieldForResourceEditor";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { Fields } from "@hireroo/validator";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import * as PrivateHelper from "./privateHelper";

const FETCH_LIMIT = 10;

type Item = Widget.SelectQuestionFromHistoryProps["items"][0];

export type GenerateSelectQuestionFromAssessmentHistoryPropsArgs = {};

export const useGenerateProps = (_args: GenerateSelectQuestionFromAssessmentHistoryPropsArgs): Widget.SelectQuestionFromHistoryProps => {
  const { t } = useTranslation();
  const companyId = Company.useStrictActiveCompanyId();
  const appStatus = App.useStatus();
  const isAvailableQuestionByPayment = Payment.useIsAvailableQuestionToUse("exam");
  const enableTalentScore = App.useEnableTalentScore();
  const isAvailableQuestion = React.useCallback(
    (variant: Graphql.QuestionVariant): boolean => {
      if (enableTalentScore) {
        return true;
      }
      return isAvailableQuestionByPayment(variant);
    },
    [enableTalentScore, isAvailableQuestionByPayment],
  );

  const [result] = Graphql.useListAssessmentsByCompanyIdForAssessmentsHistorySelectorQuery({
    variables: {
      companyId: companyId,
      size: FETCH_LIMIT,
      offset: 0,
      sortMethod: Graphql.SpotsByCompanyIdSortMethod.CreatedAt,
      isDescending: true,
      withCount: false,
      withDeleted: false,
      filters: {
        name: "",
        scoreTrend: "UNKNOWN",
        statuses: [],
        talentIds: [],
        viewerIds: [],
      },
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "cache-and-network",
  });
  const client = getGraphqlClient();
  const methods = useFormContext<{ entityTracks: Fields.EntityTrack.EntityTrack[] }>();
  const items = React.useMemo((): Item[] => {
    return (result.data?.listAssessmentsByCompanyId?.assessments || []).map((assessment): Item => {
      return {
        id: assessment.id,
        label: assessment.name,
        onClick: controller => {
          controller.setLoading(true);
          client
            .GetAssessmentForAssessmentHistorySelector({ assessmentId: assessment.id })
            .then(res => {
              if (res) {
                const questions = PrivateHelper.generateQuestionsFromEntitySourceQuestions(res.assessment.entitySourceQuestions);
                QuestionSelectFieldForResourceEditor.setQuestion(questions);
                const entityTracks = PrivateHelper.generateEntityTracksFromEntities(res.assessment.entitySourceQuestions, isAvailableQuestion);
                methods.setValue("entityTracks", entityTracks);
                methods.clearErrors("entityTracks");
                Snackbar.notify({
                  severity: "success",
                  message: t("履歴からの情報を元に問題を更新しました。"),
                });
                controller.close();
              }
            })
            .catch(error => {
              Sentry.captureException(error);
              const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                error,
                t("履歴からの情報の取得に失敗しました。しばらくしてから再度お試し頂くか、ヘルプボタンよりお問い合わせください。"),
              );
              Snackbar.notify({
                severity: "error",
                message: errorNotification.message,
              });
            })
            .finally(() => {
              controller.setLoading(false);
            });
        },
      };
    });
  }, [client, isAvailableQuestion, methods, result.data?.listAssessmentsByCompanyId?.assessments, t]);

  return {
    items: items,
    loading: result.fetching,
  };
};
