import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type RemoteTemplateResourceEditorContainerProps = {};

const RemoteTemplateResourceEditorContainer: React.FC<RemoteTemplateResourceEditorContainerProps> = () => {
  const remoteInterviewResourceEditorProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Pages.RemoteInterviewResourceEditor {...remoteInterviewResourceEditorProps} />
    </ErrorBoundary>
  );
};

RemoteTemplateResourceEditorContainer.displayName = "RemoteTemplateResourceEditorContainer";

export default withErrorBoundary(RemoteTemplateResourceEditorContainer, {});
