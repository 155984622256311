import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { Fields } from "@hireroo/validator";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import escapeStringRegexp from "escape-string-regexp";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";

import MultiChoiceField, { MultiChoiceFieldProps } from "../MultiChoiceField/MultiChoiceField";

const ActionButton = styled(LoadingButton)(() => ({
  height: 36,
}));

const AddButton = styled(Button)(() => ({
  fontSize: 14,
  textTransform: "none",
}));

type DialogController = {
  setLoading: (loading: boolean) => void;
  close: () => void;
};
export type TagsApplyFormProps = {
  onSubmit: (fields: Fields.TagField.BulkEditTagsSchema, controller: DialogController) => void;
  controller?: DialogController;
  multiChoiceField: MultiChoiceFieldProps;
  defaultValues: Fields.TagField.BulkEditTagsSchema;
  onTagAdded: (tagName: string) => void;
  onClose: () => void;
};

const TagsApplyForm: React.FC<TagsApplyFormProps> = props => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const { onSubmit } = props;
  const methods = useForm<Fields.TagField.BulkEditTagsSchema>({
    defaultValues: props.defaultValues,
  });
  const [loading, setLoading] = React.useState(false);
  const [searchText, setSearchText] = React.useState<string>("");
  const applyButtonProps: LoadingButtonProps = {
    children: t("適用する"),
    variant: "contained",
    size: "small",
    color: "secondary",
    loading: loading,
    onClick: () => {
      const controller: DialogController = {
        close: () => {
          props.onClose();
          setLoading(false);
        },
        setLoading: (newLoading: boolean) => {
          setLoading(newLoading);
        },
      };
      methods.handleSubmit(fields => {
        onSubmit(fields, controller);
      })();
    },
  };

  const textFieldProps: TextFieldProps = {
    fullWidth: true,
    placeholder: t("タグを検索"),
    InputLabelProps: {
      shrink: true,
    },
    onChange: e => {
      if (typeof e.target.value === "string") {
        setSearchText(e.target.value);
      }
    },
    value: searchText,
    InputProps: {
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
      endAdornment: (searchText || undefined) && (
        <IconButton
          onClick={() => {
            setSearchText("");
          }}
        >
          <CancelIcon color="action" />
        </IconButton>
      ),
    },
    size: "small",
    color: "secondary",
  };
  const filteredTags = React.useMemo(() => {
    return props.multiChoiceField.options.filter(targetTag => {
      const reg = new RegExp(escapeStringRegexp(searchText));
      return reg.test(targetTag.displayName);
    });
  }, [props.multiChoiceField.options, searchText]);

  const isNewTag = React.useMemo(() => {
    return filteredTags.findIndex(tag => tag.value === searchText) === -1;
  }, [filteredTags, searchText]);

  const addButton: ButtonProps = {
    children: t2("AddTag", { tag: searchText }),
    variant: "text",
    color: "secondary",
    onClick: () => {
      props.onTagAdded(searchText);
      setSearchText("");
    },
  };

  return (
    <FormProvider {...methods}>
      <Box>
        <Box py={1.5} px={2} display="flex" justifyContent={"space-between"} justifyItems={"center"}>
          <Typography variant="subtitle2" alignItems={"center"}>
            {t("タグを一括適用")}
          </Typography>
        </Box>
        <Divider />
        <Stack direction="column" p={2} spacing={1}>
          <TextField {...textFieldProps} />
          {searchText.length > 0 && isNewTag && <AddButton {...addButton} />}
          <Box sx={{ maxHeight: 250, minWidth: 200, maxWidth: 400, overflow: "auto" }}>
            <MultiChoiceField {...props.multiChoiceField} options={filteredTags} />
          </Box>
        </Stack>
        <Divider />
        <Box display={"flex"} justifyContent={"flex-end"} py={1} px={2}>
          <Stack direction="row" spacing={2}>
            <ActionButton {...applyButtonProps} />
          </Stack>
        </Box>
      </Box>
    </FormProvider>
  );
};

TagsApplyForm.displayName = "TagsApplyForm";

export default TagsApplyForm;
