import * as React from "react";

export const useKeyPress = (targetKeys: string[]) => {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = React.useState<boolean>(false);
  // If pressed key is our target key then set to true
  const downHandler = React.useCallback(
    ({ key }: KeyboardEvent) => {
      if (targetKeys.includes(key)) {
        setKeyPressed(true);
      }
    },
    [targetKeys],
  );
  // If released key is our target key then set to false
  const upHandler = React.useCallback(
    ({ key }: KeyboardEvent) => {
      if (targetKeys.includes(key)) {
        setKeyPressed(false);
      }
    },
    [targetKeys],
  );
  // Add event listeners
  React.useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [downHandler, upHandler]); // Empty array ensures that effect is only run on mount and unmount
  return keyPressed;
};
