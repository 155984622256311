import { getRef } from "@hireroo/firebase";
import * as Graphql from "@hireroo/graphql/client/request";
import { BrowserEvent, PlaybackEvent } from "@hireroo/validator";
import * as Sentry from "@sentry/browser";

import {
  fetchQuestionEvent,
  fetchQuestionLanguageEvent,
  fetchQuestionLanguageHistory,
  fetchQuestionLanguageStates,
  fetchQuestionState,
} from "../firepad";
import { PlaybackManager, PlaybackTickManager } from "../playback";

export const createDefaultPlaybackManager = () => {
  const playbackTickManager = new PlaybackTickManager();
  return new PlaybackManager(playbackTickManager);
};

export type CreatePlaybackManagerArgs = {
  challengeId: number;
  questionId: number;
  runtime: string;
  chatSessionId: string | null;
  webSessionId: string | null;

  useClipboardEvents: boolean;
};

export const createPlaybackManager = async (args: CreatePlaybackManagerArgs): Promise<PlaybackManager> => {
  const playbackTickManager = new PlaybackTickManager();
  const languageRef = getRef("challenge", `challenges/${args.challengeId}/questions/${args.questionId}/languages/${args.runtime}`);
  const questionRef = getRef("challenge", `challenges/${args.challengeId}/questions/${args.questionId}`);
  const client = Graphql.getGraphqlClient();

  const [revisions, languageStates, questionStates, questionEvents] = await Promise.all([
    fetchQuestionLanguageHistory(languageRef),
    fetchQuestionLanguageStates(languageRef),
    fetchQuestionState(questionRef),
    fetchQuestionEvent(questionRef),
  ]).catch(error => {
    Sentry.captureException(error);
    return [];
  });

  if (args.chatSessionId || args.webSessionId) {
    await client
      .GetBehavioralEventLogsForChallengeTestReport({
        input: {
          chatSessionId: args.chatSessionId,
          webSessionId: args.webSessionId,
        },
      })
      .then(({ behavioralEventLogs }) => {
        behavioralEventLogs.events.forEach(event => {
          switch (event.__typename) {
            case "ChatGPTRequestedEvent": {
              if (event.requestedAtSeconds && event.requestedAtNanoSeconds) {
                const requestedAtMilliSeconds = event.requestedAtSeconds * 1000 + Math.floor(event.requestedAtNanoSeconds / 1_000_000);
                playbackTickManager.addTick(requestedAtMilliSeconds, {
                  kind: "CHATGPT_REQUEST",
                  prompt: event.prompt,
                  gptModel: event.gptModel,
                  ts: requestedAtMilliSeconds,
                });
              }
              break;
            }
            case "ChatGPTRespondedEvent": {
              if (event.respondedAtSeconds && event.respondedAtNanoSeconds) {
                const respondedAtMilliSeconds = event.respondedAtSeconds * 1000 + Math.floor(event.respondedAtNanoSeconds / 1_000_000);
                playbackTickManager.addTick(respondedAtMilliSeconds, {
                  kind: "CHATGPT_RESPOND",
                  message: event.response,
                  gptModel: event.gptModel,
                  ts: respondedAtMilliSeconds,
                });
              }
              break;
            }
            case "ChatGPTConversationResetEvent": {
              if (event.resetAtSeconds && event.resetAtNanoSeconds) {
                const resetAtMilliSeconds = event.resetAtSeconds * 1000 + Math.floor(event.resetAtNanoSeconds / 1_000_000);
                playbackTickManager.addTick(resetAtMilliSeconds, {
                  kind: "CHATGPT_RESET",
                  ts: resetAtMilliSeconds,
                });
              }
              break;
            }
            case "WebSearchedEvent": {
              if (event.searchedAtSeconds && event.searchedAtNanoSeconds) {
                const searchedAtMilliSeconds = event.searchedAtSeconds * 1000 + Math.floor(event.searchedAtNanoSeconds / 1_000_000);
                playbackTickManager.addTick(searchedAtMilliSeconds, {
                  kind: "WEB_SITE_SEARCH",
                  query: event.query,
                  ts: searchedAtMilliSeconds,
                });
              }
              break;
            }
            case "WebAccessedEvent": {
              if (event.accessedAtSeconds && event.accessedAtNanoSeconds) {
                const accessedAtMilliSeconds = event.accessedAtSeconds * 1000 + Math.floor(event.accessedAtNanoSeconds / 1_000_000);
                playbackTickManager.addTick(accessedAtMilliSeconds, {
                  kind: "EXTERNAL_WEB_SITE_ACCESS",
                  url: event.url,
                  title: event.title,
                  description: event.description,
                  ts: accessedAtMilliSeconds,
                });
              }
              break;
            }
            default:
              throw new Error(`invalid __typename ${event satisfies never}`);
          }
        });
      })
      .catch(error => {
        Sentry.captureException(error);
      });
  }

  questionStates.forEach(questionState => {
    const result = PlaybackEvent.UseHintAction.safeParse(questionState);
    if (result.success) {
      playbackTickManager.addTick(questionState.t, {
        kind: "USE_HINT",
        ts: questionState.t,
        hintId: result.data.v,
      });
      return;
    }
  });

  questionEvents.forEach(questionEvent => {
    const result1 = BrowserEvent.BlurEventPayload.safeParse(questionEvent);
    if (result1.success) {
      playbackTickManager.addTick(questionEvent.t, {
        kind: "BROWSER_BLUR",
        ts: questionEvent.t,
      });
      return;
    }

    const result2 = BrowserEvent.HiddenEventPayload.safeParse(questionEvent);
    if (result2.success) {
      playbackTickManager.addTick(questionEvent.t, {
        kind: "BROWSER_HIDDEN",
        ts: questionEvent.t,
        reason: (() => {
          if (!result2.data.r) {
            return "UNKNOWN";
          }
          if (result2.data.r.k === "clickElement") {
            return "CLICK_ON_LINK";
          }
          if (result2.data.r.k === "googleSearch") {
            return "GOOGLE_SEARCH";
          }
          return "UNKNOWN";
        })(),
      });
      return;
    }

    const result3 = BrowserEvent.VisibleEventPayload.safeParse(questionEvent);
    if (result3.success) {
      playbackTickManager.addTick(questionEvent.t, {
        kind: "BROWSER_VISIBLE",
        ts: questionEvent.t,
      });
      return;
    }

    const result4 = BrowserEvent.AccessEventPayload.safeParse(questionEvent);
    if (result4.success) {
      playbackTickManager.addTick(questionEvent.t, {
        kind: "ACCESS",
        ipAddress: result4.data.l,
        geometry: result4.data.g || undefined,
        ts: questionEvent.t,
      });
      return;
    }

    const result5 = BrowserEvent.FocusEventPayload.safeParse(questionEvent);
    if (result5.success) {
      playbackTickManager.addTick(questionEvent.t, {
        kind: "BROWSER_FOCUS",
        ts: questionEvent.t,
      });
      return;
    }
  });

  languageStates.forEach(languageState => {
    const result1 = PlaybackEvent.SubmitQuestion.safeParse(languageState);
    if (result1.success) {
      playbackTickManager.addTick(languageState.t, {
        kind: "SUBMIT_QUESTION",
        ts: languageState.t,
      });
      return;
    }
    const result2 = PlaybackEvent.RunCode.safeParse(languageState);
    if (result2.success) {
      playbackTickManager.addTick(languageState.t, {
        kind: "RUN_CODE",
        ts: languageState.t,
      });
      return;
    }
  });

  if (args.useClipboardEvents) {
    const clipboardEvents = await fetchQuestionLanguageEvent(languageRef);
    clipboardEvents.forEach(clipboardEvent => {
      const result1 = PlaybackEvent.CopyEvent.safeParse(clipboardEvent);
      if (result1.success) {
        playbackTickManager.addTick(clipboardEvent.t, {
          kind: "EDITOR_COPY",
          ts: clipboardEvent.t,
          userId: result1.data.a,
          value: result1.data.v,
          key: result1.data.k ?? "",
        });
        return;
      }
      const result2 = PlaybackEvent.CutEvent.safeParse(clipboardEvent);
      if (result2.success) {
        playbackTickManager.addTick(clipboardEvent.t, {
          kind: "EDITOR_CUT",
          ts: clipboardEvent.t,
          userId: result2.data.a,
          value: result2.data.v,
          key: result2.data.k ?? "",
        });
        return;
      }
      const result3 = PlaybackEvent.PasteEvent.safeParse(clipboardEvent);
      if (result3.success) {
        playbackTickManager.addTick(clipboardEvent.t, {
          kind: "EDITOR_PASTE",
          ts: clipboardEvent.t,
          userId: result3.data.a,
          value: result3.data.v,
          key: result3.data.k ?? "",
        });
        return;
      }
    });
  }

  revisions.forEach(revision => {
    const syncOperation = PlaybackEvent.SyncOperation.safeParse(revision);
    if (syncOperation.success) {
      playbackTickManager.addTick(revision.t, {
        kind: "CODE_EDITOR",
        textOperations: syncOperation.data.o,
        userId: syncOperation.data.a,
        key: revision.k ?? "",
        ts: revision.t,
      });
    }
  });

  const playbackManager = new PlaybackManager(playbackTickManager);

  playbackManager.initialize();

  return playbackManager;
};
