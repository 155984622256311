import type { ScreeningResourceEditorForm } from "@hireroo/validator";
import * as React from "react";

import { useScreeningResourceEditorContext } from "./Context";
import ConfirmSection, { ConfirmSectionProps } from "./parts/ConfirmSection/ConfirmSection";
import Layout, { LayoutProps } from "./parts/Layout/Layout";
import ReportSetupSection, { ReportSetupSectionProps } from "./parts/ReportSetupSection/ReportSetupSection";
import TestInviteSetupSection, { TestInviteSetupSectionProps } from "./parts/TestInviteSetupSection/TestInviteSetupSection";
import TestQuestionSetupSection, { TestQuestionSetupSectionProps } from "./parts/TestQuestionSetupSection/TestQuestionSetupSection";
import TestSetupSection, { TestSetupSectionProps } from "./parts/TestSetupSection/TestSetupSection";

export { ScreeningResourceEditorProvider, type ScreeningResourceEditorProviderProps, useScreeningResourceEditorContext } from "./Context";

type Mode = "EDIT" | "CREATE";

export type ScreeningResourceEditorProps = {
  className?: string;
  mode: Mode;
  testQuestionSetupSection: TestQuestionSetupSectionProps;
  testSetupSection: TestSetupSectionProps;
  reportSetupSection: ReportSetupSectionProps;
  testInviteSetupSection: TestInviteSetupSectionProps;
  confirmSection: ConfirmSectionProps;
  onSubmit: (fields: ScreeningResourceEditorForm.CreateScreeningV2FormSchema) => void;
  layout: Pick<LayoutProps, "helpLink" | "loading">;
};

const ScreeningResourceEditor: React.FC<ScreeningResourceEditorProps> = props => {
  const { setSubmitHandler } = useScreeningResourceEditorContext();

  React.useEffect(() => {
    setSubmitHandler(props.onSubmit);
  }, [setSubmitHandler, props.onSubmit]);

  return (
    <Layout {...props.layout} mode={props.mode} className={props.className}>
      <TestQuestionSetupSection {...props.testQuestionSetupSection} />
      <TestSetupSection {...props.testSetupSection} />
      <ReportSetupSection {...props.reportSetupSection} />
      <TestInviteSetupSection {...props.testInviteSetupSection} />
      <ConfirmSection {...props.confirmSection} />
    </Layout>
  );
};

ScreeningResourceEditor.displayName = "ScreeningResourceEditor";

export default ScreeningResourceEditor;
