import * as Sentry from "@sentry/react";
import ReconnectingWebSocket from "reconnecting-websocket";
import { ref } from "valtio";
import { ITerminalOptions, Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";

import type * as Types from "./types";

export const createAction = (state: Types.State) => {
  const initializeTerminal = (endpoint: string, targetElement: HTMLElement, terminalOptions?: ITerminalOptions) => {
    // Create a websocket connection for build process
    const socket = new ReconnectingWebSocket(endpoint, [], {
      minReconnectionDelay: 1000,
      maxReconnectionDelay: 1000,
      reconnectionDelayGrowFactor: 1.0,
    });

    // Create a new terminal
    const terminal = new Terminal({
      rendererType: "dom",
      // TODO: Remove theme after we move to the new design
      theme: {
        background: "#272822",
      },
      disableStdin: true,
      ...terminalOptions,
    });
    socket.onmessage = e => {
      terminal.write(e.data);
    };
    socket.onerror = e => {
      e.error && Sentry.captureException(e.error);
    };

    Array.from(targetElement.childNodes).forEach(child => child.remove());
    // Set fitAddon with the terminal so that rows and cols are always fit
    // when the terminal size is changed
    const fitAddon = new FitAddon();
    terminal.loadAddon(fitAddon);
    terminal.open(targetElement);
    fitAddon.fit();

    // Save objects for reference in a local state
    state.socket = ref(socket);
    state.terminal = ref(terminal);
    state.fitAddon = ref(fitAddon);
  };

  const handleResize = () => {
    state.fitAddon?.fit();
  };

  const cleanUpTerminal = () => {
    state.socket?.close();
    state.socket = null;
    state.terminal = null;
    state.fitAddon = null;
  };

  return {
    initializeTerminal,
    cleanUpTerminal,
    handleResize,
  };
};
