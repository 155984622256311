import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import * as React from "react";

export type EntryContentPaperProps = {
  children: React.ReactNode;
};

const EntryContentPaper: React.FC<EntryContentPaperProps> = props => {
  const theme = useTheme();
  return (
    <Paper elevation={8} sx={{ borderRadius: theme.spacing(2) }}>
      <Box p={4}>{props.children}</Box>
    </Paper>
  );
};

EntryContentPaper.displayName = "EntryContentPaper";

export default EntryContentPaper;
