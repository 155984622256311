import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type FillTemplateListContainerProps = {};

const FillTemplateListContainer: React.FC<FillTemplateListContainerProps> = () => {
  const fillTemplateListProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.FillTemplateList {...fillTemplateListProps} />
    </ErrorBoundary>
  );
};

FillTemplateListContainer.displayName = "FillTemplateListContainer";

export default withErrorBoundary(FillTemplateListContainer, {});
