import { languageMapForDisplay, useTranslation } from "@hireroo/i18n";
import Article from "@mui/icons-material/Article";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useFieldArray } from "react-hook-form";

import EditLanguageTab, { EditLanguageTabProps } from "../../../../usecase/EditLanguageTab/EditLanguageTab";
import { useFreepadQuestionDetailFormContext } from "../../Context";
import QuestionTabContent from "../QuestionTabContent/QuestionTabContent";

export type QuestionTextFieldsProps = {
  languageTab: Pick<EditLanguageTabProps, "menu" | "onTabsChange">;
};

const QuestionTextFields: React.FC<QuestionTextFieldsProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { methods } = useFreepadQuestionDetailFormContext();

  const questionsField = useFieldArray({
    control: methods.control,
    name: "questions",
  });

  const questions = methods.getValues("questions");

  const editLanguageTab: EditLanguageTabProps = {
    menu: props.languageTab.menu,
    onTabsChange: props.languageTab.onTabsChange,
    addButton: {
      disabled: questions?.[0]?.title === "" || questions?.[0]?.description === "",
    },
    items: (questions || []).map((question, index) => {
      return {
        id: index.toString(),
        name: languageMapForDisplay[question.language],
        Content: <QuestionTabContent key={question.language} index={index} lang={question.language} />,
        tab: {
          closeButton: {
            onClick: () => {
              questionsField.remove(index);
            },
          },
        },
      };
    }),
  };

  return (
    <Box>
      <Box mb={4}>
        <Box display="flex" alignItems="center" mb={2}>
          <Box display="flex" alignItems="center">
            <Box mr={1}>
              <Article fontSize="small" sx={{ color: theme.palette.common.white }} />
            </Box>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              {t("問題概要を入力する")}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Typography variant="body2">
            {t("問題で利用するタイトルや本文を入力します。問題で利用する言語は複数入力可能です。メインで利用する言語以外の入力は任意です。")}
          </Typography>
        </Box>
      </Box>

      <Box>
        <EditLanguageTab {...editLanguageTab} />
      </Box>
    </Box>
  );
};

QuestionTextFields.displayName = "QuestionTextFields";

export default QuestionTextFields;
