import { SupportLanguage, SupportLanguageValue } from "@hireroo/i18n";
import { QuizQuestionForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import * as React from "react";
import { useFieldArray, useForm, UseFormReturn } from "react-hook-form";

export type ContextValue = {
  method: UseFormReturn<QuizQuestionForm.QuizQuestionFormSchema>;
  addLanguage: (language: SupportLanguageValue) => void;
  prepareSubmit: () => void;
};

export const QuizQuestionEditorContext = React.createContext<ContextValue>({} as ContextValue);

export const useQuizQuestionEditorContext = () => React.useContext(QuizQuestionEditorContext);

export type QuizQuestionEditorProviderProps = {
  fields: QuizQuestionForm.QuizQuestionFormSchema;
};

export const QuizQuestionEditorProvider: React.FC<React.PropsWithChildren<QuizQuestionEditorProviderProps>> = props => {
  const { fields } = props;
  const validateSchema = QuizQuestionForm.useQuizQuestionForm();
  const method = useForm<QuizQuestionForm.QuizQuestionFormSchema>({
    defaultValues: fields,
    resolver: zodResolver(validateSchema),
    reValidateMode: "onSubmit",
  });
  const selectedLanguageController = useFieldArray({
    control: method.control,
    name: "selectedLanguages",
  });
  const variant = method.watch("variant");
  React.useEffect(() => {
    /**
     * If the variant is changed, the error is reset once.
     */
    method.clearErrors();
    method.setValue("variant", variant);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variant]);
  React.useEffect(() => {
    method.setValue("id", fields.id);
    method.setValue("newVersion", fields.newVersion);
    method.setValue("oldVersion", fields.oldVersion);
    method.setValue("status", fields.status);
    method.setValue("isPublic", fields.isPublic);
    method.setValue("isPublished", fields.isPublished);
    method.setValue("difficulty", fields.difficulty);
    method.setValue("metricType", fields.metricType);
    method.setValue("variant", fields.variant);
    method.setValue("questionDetails", fields.questionDetails);
    method.setValue("selectedLanguages", fields.selectedLanguages);
    switch (fields.variant) {
      case "SINGLE_CHOICE": {
        method.setValue("singleChoiceCorrectOptionIndex", fields.singleChoiceCorrectOptionIndex);
        method.setValue("singleChoiceQuestions", fields.singleChoiceQuestions);
        break;
      }
      case "MULTI_CHOICE": {
        method.setValue("multiChoiceQuestion", fields.multiChoiceQuestion);
        break;
      }
      case "FREE_TEXT": {
        method.setValue("evaluations", fields.evaluations);
        break;
      }
    }
    method.clearErrors();
  }, [method, fields]);
  const contextValue: ContextValue = {
    method,
    addLanguage: lang => {
      const selectedLanguages = method.getValues("selectedLanguages");
      if (selectedLanguages.find(language => language.value === lang)) {
        return;
      }
      selectedLanguageController.append({
        value: lang,
      });
    },
    prepareSubmit: () => {
      const unusedLanguages = new Set([SupportLanguage.EN, SupportLanguage.JA]);
      const selectedLanguages = new Set(method.getValues("selectedLanguages").map(({ value }) => value));
      selectedLanguages.forEach(lang => {
        unusedLanguages.delete(lang);
      });
      unusedLanguages.forEach(language => {
        method.setValue(`questionDetails.${language}`, undefined);
        switch (method.getValues("variant")) {
          case "FREE_TEXT": {
            method.getValues("evaluations").forEach((_, index) => {
              method.setValue(`evaluations.${index}.content.${language}`, undefined);
            });
            break;
          }
          case "MULTI_CHOICE": {
            method.getValues("multiChoiceQuestion.options").forEach((_, index) => {
              method.setValue(`multiChoiceQuestion.options.${index}.content.${language}`, undefined);
            });
            break;
          }
          case "SINGLE_CHOICE": {
            method.getValues("singleChoiceQuestions").forEach((_, index) => {
              method.setValue(`singleChoiceQuestions.${index}.content.${language}`, undefined);
            });
            break;
          }
        }
      });
    },
  };
  return <QuizQuestionEditorContext.Provider value={contextValue} children={props.children} />;
};
