import { useTranslation } from "@hireroo/i18n";

import { OnboardingGuideProps } from "../../../modules/OnboardingGuide/OnboardingGuide";

export const useGenerateChallengeOnboardingGuideForCandidateProps = (): OnboardingGuideProps => {
  const { t } = useTranslation();
  return {
    steps: [
      {
        title: t("チュートリアルへようこそ！"),
        content: t(
          "こちらではライブコーディングの画面操作を説明します。このチュートリアルは何度でも再開できますので、操作に迷った際にご確認ください。",
        ),
        placement: "center",
        target: "body",
      },
      {
        title: t("ペーンの調整"),
        content: t("こちらをクリックし、動かすことでペーンの幅を調整することができます。"),
        placement: "right-start",
        styles: {
          options: {
            width: 300,
          },
        },
        target: ".tutorial-pane",
      },
      {
        title: t("ペーンの開閉"),
        content: t("こちらをクリックすることでペーンを開閉することができます。"),
        placement: "bottom",
        styles: {
          options: {
            width: 300,
          },
        },
        target: ".toggle-pane-button",
      },
      {
        title: t("言語の選択"),
        content: t("好きな言語を選択し問題を解くことが可能です。"),
        placement: "bottom",
        styles: {
          options: {
            width: 300,
          },
        },
        target: ".runtime-selector",
      },
      {
        title: t("ビデオ通話を開始する"),
        content: t("クリックすることでビデオ通話ダイアログが表示され、参加を押すことでビデオ通話を開始すことができます。"),
        placement: "auto",
        styles: {
          options: {
            width: 300,
          },
        },
        target: ".video-call-start-button",
      },
      {
        title: t("チャット"),
        content: t("インタビューの参加者とチャットをすることが可能です。Markdown形式でチャットを送ることができます。"),
        placement: "auto",
        styles: {
          options: {
            width: 300,
          },
        },
        target: ".chat-tab",
      },
      {
        title: t("ヘルプセンター"),
        content: t(
          "こちらのボタンを押すとヘルプセンターが開かれます。分からないことがありましたらこちらにアクセスし、記事を参照するか、右下のチャットからお問い合わせください。",
        ),
        placement: "top",
        styles: {
          options: {
            width: 300,
          },
        },
        target: "#help-button",
      },
      {
        title: t("チュートリアル終了"),
        content: t("これでチュートリアルは終了です。再度確認したい場合は、もう一度ボタンを押しチュートリアルを開始してください。"),
        placement: "center",
        target: "body",
      },
    ],
  };
};
