import * as z from "zod";

import * as EntitySource from "./EntitySource";

export const useFixedEntityTrack = () => {
  const entitySource = EntitySource.useEntitySource();
  const questionScoreWeight = EntitySource.useQuestionScoreWeight();
  return z.object({
    type: z.literal("FIXED"),
    entitySource,
    questionScoreWeight,
  });
};

export type FixedEntityTrack = z.infer<ReturnType<typeof useFixedEntityTrack>>;

export const useSelectableFromQuestionsEntityTrack = () => {
  const entitySource = EntitySource.useEntitySource();
  const questionScoreWeight = EntitySource.useQuestionScoreWeight();
  return z.object({
    type: z.literal("SELECTABLE_FROM_QUESTIONS"),
    entitySources: entitySource.array(),
    questionScoreWeight,
  });
};

export type SelectableFromQuestionsEntityTrack = z.infer<ReturnType<typeof useSelectableFromQuestionsEntityTrack>>;

export const useRandomFromQuestionsEntityTrack = () => {
  const entitySource = EntitySource.useEntitySource();
  const questionScoreWeight = EntitySource.useQuestionScoreWeight();
  return z.object({
    type: z.literal("RANDOM_FROM_QUESTIONS"),
    entitySources: entitySource.array(),
    questionScoreWeight,
  });
};

export type RandomFromQuestionsEntityTrack = z.infer<ReturnType<typeof useRandomFromQuestionsEntityTrack>>;

export const useEntityTrack = () => {
  const fixedEntityTrack = useFixedEntityTrack();
  const selectableFromQuestionsEntityTrack = useSelectableFromQuestionsEntityTrack();
  const randomFromQuestionsEntityTrack = useRandomFromQuestionsEntityTrack();
  return z.union([fixedEntityTrack, selectableFromQuestionsEntityTrack, randomFromQuestionsEntityTrack]);
};

export type EntityTrack = z.infer<ReturnType<typeof useEntityTrack>>;
