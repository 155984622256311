import { useTranslation } from "@hireroo/i18n";
import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import * as React from "react";

export type Status = "PAID" | "NOT_PAID";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  width: "100%",
  height: 56,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  borderBottom: theme.palette["Secondary/Shades"].p12,
  "& .MuiTableCell-root": {
    borderColor: theme.palette["Secondary/Shades"].p12,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  height: 32,
  padding: theme.spacing(1.5),
}));

type InterviewType = "LIVE_CODING" | "SCREENING_TEST";

export type UsageTableRowProps = {
  amountOfMoney: string;
  testId: string;
  usedDate: string;
  interviewType: InterviewType;
};

const UsageTableRow: React.FC<UsageTableRowProps> = props => {
  const { t } = useTranslation();
  const interviewTypeLabelMap: Record<InterviewType, string> = {
    LIVE_CODING: t("ライブコーディング"),
    SCREENING_TEST: t("コーディングテスト"),
  };
  return (
    <StyledTableRow>
      <StyledTableCell align="left">{props.amountOfMoney}</StyledTableCell>
      <StyledTableCell align="left">{props.testId}</StyledTableCell>
      <StyledTableCell align="left">{props.usedDate}</StyledTableCell>
      <StyledTableCell align="left">{interviewTypeLabelMap[props.interviewType]}</StyledTableCell>
    </StyledTableRow>
  );
};

UsageTableRow.displayName = "UsageTableRow";

export default UsageTableRow;
