import * as React from "react";

import { createAction } from "./Action";
import { createHooks } from "./hooks";
import { createState, multiState } from "./State";
import type * as Types from "./types";

export type ContextValue = {
  action: ReturnType<typeof createAction>;
  hooks: ReturnType<typeof createHooks>;
};

export const ChallengeCodingEditorContext = React.createContext<ContextValue>({} as ContextValue);

export const useChallengeCodingEditorContext = () => React.useContext(ChallengeCodingEditorContext);

export type ChallengeCodingEditorProviderProps = {
  id?: string;
  variant: Types.Variant;
  enableLanguages?: string[];
  defaultLanguage?: Types.Language;
  options?: {
    disableSetStateOnUnmount: boolean;
  };
};

export const ChallengeCodingEditorProvider: React.FC<React.PropsWithChildren<ChallengeCodingEditorProviderProps>> = props => {
  const id: string = props.id || Math.random().toString();
  /**
   * Order is important
   */
  const state = createState(
    multiState.get(id) || {
      variant: props.variant,
      currentLanguage: props.defaultLanguage,
      enableLanguages: props.enableLanguages,
    },
  );
  const hooks = createHooks(state);
  const action = createAction(state);

  React.useEffect(() => {
    if (props.enableLanguages) {
      action.updateEnableLanguages(props.enableLanguages);
    }
  }, [action, props.enableLanguages]);

  React.useEffect(() => {
    /**
     * TODO: @himenon refactor
     */
    return () => {
      if (props.options?.disableSetStateOnUnmount !== true) {
        multiState.set(id, state);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const contextValue: ContextValue = {
    hooks,
    action,
  };
  return <ChallengeCodingEditorContext.Provider value={contextValue} children={props.children} />;
};
