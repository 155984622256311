import { useInterview } from "@hireroo/app-helper/hooks";
import { generateNormalizedEntity } from "@hireroo/app-helper/normalizer";
import { DemosIdStore } from "@hireroo/app-store/page/c/demos_id";
import { InterviewNavigation } from "@hireroo/app-store/widget/c/InterviewNavigation";
import { Pages, Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import CandidateFooterContainer from "../../../../widget/v2/c/CandidateFooter/Container";
import ScreeningTestHeaderContainer from "../../../../widget/v2/c/ScreeningTestHeader/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import TestAnnouncementContainer from "../../../../widget/v2/shared/TestAnnouncement/Container";
import * as Subscriber from "./Subscriber";
import { useGenerateProps } from "./useGenerateProps";

export type InterviewContainerProps = {
  demoId: string;
};

const InterviewContainer: React.FC<InterviewContainerProps> = props => {
  const entity = DemosIdStore.useFirstEntity();
  const firstEntity = generateNormalizedEntity(entity);
  const [interviewState, interviewAction] = useInterview({
    interviewId: props.demoId,
    interviewType: "demo",
    entityId: firstEntity.entityId,
    entityType: firstEntity.entityType,
    questionId: firstEntity.questionId,
    version: firstEntity.questionVersion,
  });
  const interviewProps = useGenerateProps({
    demoId: props.demoId,
  });
  const initialEntityKey = `${interviewState.entityType}-${interviewState.entityId}-${interviewState.questionMap.id}-${interviewState.questionMap.version}`;
  React.useEffect(() => {
    if (!interviewState.interviewReady) {
      return;
    }
    const stopStartSubscribeShowingEntity = Subscriber.startSubscribeOnChangeShowingEntity({
      onChangeShowingEntity: entity => {
        interviewAction.setChangeQuestion(entity.entityId, entity.entityType, entity.questionId, entity.questionVersion);
      },
    });
    const stopSubscribeQuizCodingEditor = Subscriber.startSubscribeQuizCodingEditor();
    const stopSubscribeSystemDesignCodingEditor = Subscriber.startSubscribeSystemDesignCodingEditor();
    const stopSubscribeProjectCodingEditorV3 = Subscriber.startSubscribeProjectCodingEditorV3();
    const stopSubscribeChallengeCodingEditor = Subscriber.startSubscribeChallengeCodingEditor();
    const stopSubscribeChallengeCoddingEditorLoading = Subscriber.startSubscribeChallengeCodingEditorLoading();
    const stopSubscribeQuizCodingEditorLoading = Subscriber.startSubscribeQuizCodingEditorLoading();
    return () => {
      stopSubscribeQuizCodingEditor();
      stopStartSubscribeShowingEntity();
      stopSubscribeProjectCodingEditorV3();
      stopSubscribeSystemDesignCodingEditor();
      stopSubscribeChallengeCodingEditor();
      stopSubscribeChallengeCoddingEditorLoading();
      stopSubscribeQuizCodingEditorLoading();
    };
  });
  React.useEffect(() => {
    if (!interviewState.interviewReady) {
      return;
    }
    InterviewNavigation.setSelectedEntityKey(initialEntityKey);
  }, [initialEntityKey, interviewState.interviewReady]);
  if (!interviewState.interviewReady) {
    return <Widget.Loading kind="CENTER" color="secondary" />;
  }
  if (!interviewState.isInterviewing) {
    return (
      <ErrorBoundary>
        <Pages.ScreeningTest
          layout={{
            NotificationBanner: null,
            Header: (
              <ScreeningTestHeaderContainer
                {...{
                  showingMode: "READONLY",
                  onEndInterview: undefined,
                  willBlockEndInterview: true,
                  disabledFinishButton: true,
                  messageForCandidate: undefined,
                }}
              />
            ),
            Footer: <CandidateFooterContainer />,
            Snackbar: <SnackbarContainer />,
            Tutorial: null,
          }}
          Content={<TestAnnouncementContainer kind="ALL_QUESTION_SUBMITTED" />}
        />
      </ErrorBoundary>
    );
  }
  return (
    <ErrorBoundary>
      <Pages.ScreeningTest {...interviewProps} />
    </ErrorBoundary>
  );
};

InterviewContainer.displayName = "InterviewContainer";

export default withErrorBoundary(InterviewContainer, {});
