import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateQuestionSortableTwoColumnListPropsArgs, useGenerateProps } from "./useGenerateProps";

export type QuestionSortableTwoColumnListContainerProps = GenerateQuestionSortableTwoColumnListPropsArgs;

const QuestionSearchAndSelectableAreaContainer: React.FC<QuestionSortableTwoColumnListContainerProps> = props => {
  const questionSortableTwoColumnListProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.QuestionSearchAndSelectableArea {...questionSortableTwoColumnListProps} />
    </ErrorBoundary>
  );
};

QuestionSearchAndSelectableAreaContainer.displayName = "QuestionSearchAndSelectableArea";

export default withErrorBoundary(QuestionSearchAndSelectableAreaContainer, {});
