import { ScreeningResourceEditorQueryParams } from "@hireroo/app-definition/spot";
import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Auth, Payment, Role } from "@hireroo/app-store/essential/employee";
import { ScreeningsStore } from "@hireroo/app-store/page/e/screenings";
import { ScreeningsIdDetailStore } from "@hireroo/app-store/page/e/screenings_id_detail";
import { ScreeningInviteeList } from "@hireroo/app-store/widget/e/ScreeningInviteeList";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import * as TimeFormat from "@hireroo/formatter/time";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import { languageMapForDisplay, SupportLanguage, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateCurrentOriginUrl } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import { CandidateInviteForm } from "@hireroo/validator";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import ScreeningInviteeListForDetailContainer from "../ScreeningInviteeListForDetail/Container";
import ScreeningTestListForDetailFetchContainer from "../ScreeningTestListForDetail/FetchContainer";
import { useGenerateAccessPermissionDialogProps } from "./useGenerateAccessPermissionDialogProps";
import { useGenerateCustomScoreAllocationProps } from "./useGenerateCustomScoreAllocationProps";
import { useGenerateQuestionOverviewProps } from "./useGenerateQuestionOverviewProps";
import { useTagDialogGenerateProps } from "./useTagDialogGenerateProps";

type AuthorizedMemberAndGroup = Widget.ScreeningDetailProps["overview"]["reportInfo"]["data"]["authorizedMemberAndGroups"];

export type GenerateScreeningDetailPropsArgs = {
  refresh: () => void;
};

export const useGenerateProps = (args: GenerateScreeningDetailPropsArgs): Widget.ScreeningDetailProps => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const matchingRole = Role.useMatchingRole();
  const currentRoleDisplayText = Role.useCurrentRoleDisplayText();
  const expectedRole = Role.useRoleToText("MANAGER");
  const client = getGraphqlClient();

  const navigate = useTransitionNavigate();
  const screening = ScreeningsIdDetailStore.useScreening();
  const dialogStatus = ScreeningsIdDetailStore.useDialogStatus();
  const isAvailableFeature = Payment.useIsAvailableFeature();
  const tagDialogProps = useTagDialogGenerateProps(args);
  const accessPermissionDialogProps = useGenerateAccessPermissionDialogProps(args);
  const currentUser = Auth.useCurrentUser();
  const [loading, setLoading] = React.useState(false);
  const [params, setParams] = useSearchParams();
  const defaultTab = React.useMemo((): Widget.ScreeningDetailProps["defaultTab"] => {
    const tab = params.get("tab");
    if (tab === "OVERVIEW" || tab === "CANDIDATES" || tab === "INVITEES") {
      return tab;
    }
    return "OVERVIEW";
  }, [params]);
  const questionOverview = useGenerateQuestionOverviewProps();
  const customScoreAllocationProps = useGenerateCustomScoreAllocationProps();

  const candidateAccessPolicyLabelMap: Record<Graphql.ScreeningCandidateAccessPolicy, "ALL" | "INVITATION_CODE_ONLY"> = {
    [Graphql.ScreeningCandidateAccessPolicy.AllowAll]: "ALL",
    [Graphql.ScreeningCandidateAccessPolicy.RestrictedByInvitationCode]: "INVITATION_CODE_ONLY",
  };

  const authorizedMember = screening.viewers.map((viewer): AuthorizedMemberAndGroup[0] => {
    if (viewer.__typename === "EmployeeGroup") {
      return {
        key: viewer.employeeGroupId,
        kind: "GROUP",
        label: viewer.groupName,
      };
    } else if (viewer.__typename === "User") {
      return {
        key: viewer.id,
        photoUrl: viewer.photoUrl,
        kind: "MEMBER",
        label: viewer.displayName ? viewer.displayName : viewer.email,
      };
    } else {
      throw new Error("Unknown reviewer type");
    }
  });

  const behavioralControl = React.useMemo((): string[] | null => {
    if (!isAvailableFeature("behavioral-control.read")) {
      return null;
    }

    const allowedBehaviors: string[] = [];
    if (screening.allowWebSearch) {
      allowedBehaviors.push(t("Google検索"));
    }
    if (screening.allowChatGPT) {
      allowedBehaviors.push("ChatGPT");
    }

    return allowedBehaviors;
  }, [t, screening.allowWebSearch, screening.allowChatGPT, isAvailableFeature]);

  const modeInviteCandidateMap: Record<
    ScreeningsIdDetailStore.Screening["candidateAccessPolicy"],
    Widget.ScreeningDetailProps["header"]["inviteCandidate"]["mode"]
  > = {
    ALLOW_ALL: "ALL",
    RESTRICTED_BY_INVITATION_CODE: "MAIL_ONLY",
  };

  const acceptanceStatusMessageMap: Record<Graphql.ScreeningAcceptanceStatus, string> = {
    SUBMIT_DEADLINE_EXCEEDED: t("提出期限が過ぎています。再開したい場合は提出期限を設定し直してください。"),
    OVERCAPACITY: t("受験可能な定員数に達しています。定員数を増やしてください。"),
    ACCEPTING: "",
    SUSPENDED: "",
  };

  const handleInviteCandidateOnSubmit = React.useCallback(
    (fields: CandidateInviteForm.CandidateInviteFormSchema) => {
      setLoading(true);
      client
        .InviteCandidateToScreeningForScreenings({
          input: {
            screeningId: screening.screeningId,
            candidateEmails: fields.emails.map(email => email.value),
          },
        })
        .then(res => {
          Snackbar.notify({
            severity: "success",
            message: t("招待メールを候補者に送信しました。"),
          });
          if (res?.inviteCandidateToScreening) {
            ScreeningsIdDetailStore.updateScreeningInvitees(res.inviteCandidateToScreening.invitees);
          }
          ScreeningInviteeList.refresh();
          ScreeningsIdDetailStore.updateDialogStatus("CLOSE");
          ScreeningsStore.updateScreeningInvitees(res.inviteCandidateToScreening.invitees);
        })
        .catch(error => {
          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("招待メールの送信に失敗しました。"));
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [client, t, screening.screeningId],
  );

  const inviteCandidateDialogV2 = React.useMemo((): Exclude<Widget.ScreeningDetailProps["header"]["inviteCandidate"]["dialog"], undefined> => {
    return {
      name: screening.name,
      copyButton:
        screening.candidateAccessPolicy === "ALLOW_ALL"
          ? {
              url: generateCurrentOriginUrl("/c/screenings/:id", { pathParams: { id: screening.screeningId } }),
              onCopy: () => {
                Snackbar.notify({
                  severity: "info",
                  message: t("クリップボードにコピーされました"),
                });
              },
            }
          : undefined,
      onSubmit: handleInviteCandidateOnSubmit,
      language: screening.invitationLanguage !== "UNKNOWN" ? screening.invitationLanguage : "JA",
      editButton: {
        onClick: () => {
          navigate("/e/screenings/:id/update", {
            pathParams: { id: screening.screeningId },
            queryParams: {
              [ScreeningResourceEditorQueryParams.Key]: ScreeningResourceEditorQueryParams.Value.TestInviteSetup,
            },
          });
        },
      },
      invitedListButton: {
        onClick: () => {
          setParams({ tab: "INVITEES" });
          ScreeningsIdDetailStore.updateDialogStatus("CLOSE");
        },
      },
    };
  }, [
    handleInviteCandidateOnSubmit,
    navigate,
    screening.candidateAccessPolicy,
    screening.invitationLanguage,
    screening.name,
    screening.screeningId,
    setParams,
    t,
  ]);

  return {
    header: {
      title: screening.name,
      activeStatus: screening.isActive ? "ACTIVE" : "INACTIVE",
      status: candidateAccessPolicyLabelMap[screening.candidateAccessPolicy],
      inviteCandidate: {
        dialog: inviteCandidateDialogV2,
        mode: modeInviteCandidateMap[screening.candidateAccessPolicy],
        disabled: !screening.isActive,
      },
      moreButton: {
        options: [
          {
            value: "edit",
            displayName: t("編集"),
            onClick: () => {
              navigate("/e/screenings/:id/update", {
                pathParams: {
                  id: screening.screeningId,
                },
              });
            },
            disabled: !matchingRole.ADMIN_OR_MANAGER.matched,
            disabledText: t2("PermissionTooltip", {
              actualRole: currentRoleDisplayText,
              expectedRole: expectedRole,
            }),
            divider: false,
            startIcon: "EDIT",
          },
          {
            value: "activate",
            displayName: screening.isActive ? t("受付停止") : t("受付再開"),
            onClick: () => {
              ScreeningsIdDetailStore.updateDialogStatus("OPEN_ACTIVATION");
            },
            disabled: !matchingRole.ADMIN_OR_MANAGER.matched || screening.entityTracks.length === 0,
            disabledText: matchingRole.ADMIN_OR_MANAGER.matched
              ? t("出題可能な問題が登録されていません")
              : t2("PermissionTooltip", {
                  actualRole: currentRoleDisplayText,
                  expectedRole: expectedRole,
                }),
            divider: false,
            startIcon: screening.isActive ? "STOP" : "PLAY",
          },
          {
            value: "from-screening",
            displayName: t("コピーしてコーディングテストを新規作成"),
            onClick: () => {
              navigate("/e/screenings/create", {
                queryParams: {
                  id: screening.screeningId,
                },
              });
            },
            disabled: !matchingRole.ADMIN_OR_MANAGER.matched,
            disabledText: t2("PermissionTooltip", {
              actualRole: currentRoleDisplayText,
              expectedRole: expectedRole,
            }),
            divider: false,
            startIcon: "CONTENT_COPY",
          },
          {
            value: "delete",
            displayName: t("削除する"),
            color: "error",
            onClick: () => {
              ScreeningsIdDetailStore.updateDialogStatus("OPEN_DELETION");
            },
            disabled: !matchingRole.ADMIN_OR_MANAGER.matched,
            disabledText: t2("PermissionTooltip", {
              actualRole: currentRoleDisplayText,
              expectedRole: expectedRole,
            }),
            divider: false,
            startIcon: "DELETE",
          },
        ],
      },
    },
    List: <ScreeningTestListForDetailFetchContainer hasVariables={screening.variables.length > 0} />,
    defaultTab: defaultTab,
    onTabChange: tab => {
      setParams({ tab: tab });
    },
    InviteeList: <ScreeningInviteeListForDetailContainer />,
    overview: {
      customScoreEditButton: {
        onClick: () => {
          navigate("/e/screenings/:id/update", {
            pathParams: { id: screening.screeningId },
            queryParams: { [ScreeningResourceEditorQueryParams.Key]: ScreeningResourceEditorQueryParams.Value.TestQuestionSetup },
          });
        },
      },
      questionCount: screening.entityTracks.length,
      questionOverview: questionOverview,
      customScoreAllocation: customScoreAllocationProps,
      statusMessage:
        screening.acceptanceStatus === "SUBMIT_DEADLINE_EXCEEDED" || (screening.acceptanceStatus === "OVERCAPACITY" && screening.isActive)
          ? {
              title: t("受付一時停止中"),
              description: acceptanceStatusMessageMap[screening.acceptanceStatus],
              action: {
                onClick: () => {
                  navigate("/e/screenings/:id/update", {
                    pathParams: { id: screening.screeningId },
                    queryParams: { [ScreeningResourceEditorQueryParams.Key]: ScreeningResourceEditorQueryParams.Value.TestSetup },
                  });
                },
              },
            }
          : undefined,
      summary: {
        addButton: {
          onClick: () => {
            navigate("/e/screenings/:id/update", {
              pathParams: { id: screening.screeningId },
              queryParams: { [ScreeningResourceEditorQueryParams.Key]: ScreeningResourceEditorQueryParams.Value.TestSetup },
            });
          },
        },
        numRemain: screening?.acceptableLimit ? screening.acceptableLimit - screening.numAccepted : undefined,
        numLimit: screening.acceptableLimit || undefined,
        numAcceptance: screening.numAccepted,
      },
      screeningInfo: {
        data: {
          description: screening.description,
          allowHint: screening.allowHint ? t("許可する") : t("許可しない"),
          submissionDeadline: ((): string => {
            if (screening.deadlineSeconds) {
              return TimeFormat.unixToDateFormat(screening.deadlineSeconds);
            }
            return t("なし");
          })(),
          timeLimit: screening.timeLimitSeconds ? `${screening.timeLimitSeconds / TimeFormat.SECONDS}${t("分")}` : t("なし"),
          behavioralControl: behavioralControl,
          createdBy: screening.createdBy?.displayName || screening.createdBy?.email || t("不明"),
          createdAt: TimeFormat.unixToDateFormat(screening.createdAtSeconds ?? 0),
        },
        customFormVariables: screening.variables.reduce<Widget.ScreeningDetailProps["overview"]["screeningInfo"]["customFormVariables"]>(
          (all, variable) => {
            switch (variable.variableField.__typename) {
              case "ScreeningStringFieldVariable":
                return all.concat({
                  label: variable.label,
                  description: variable.description,
                  formType: variable.variableField.formType === "INPUT_TEXT" ? t("短文回答") : t("長文回答"),
                  kind: variable.variableField.validationRule.required ? "REQUIRED" : "OPTIONAL",
                  validationRule: {
                    min: variable.variableField.validationRule.minLength
                      ? t2("minCharacters", { value: variable.variableField.validationRule.minLength })
                      : undefined,
                    max: variable.variableField.validationRule.maxLength
                      ? t2("maxCharacters", { value: variable.variableField.validationRule.maxLength })
                      : undefined,
                  },
                });
              case "ScreeningIntegerFieldVariable":
                return all.concat({
                  label: variable.label,
                  description: variable.description,
                  formType: t("数値（整数）"),
                  kind: variable.variableField.validationRule.required ? "REQUIRED" : "OPTIONAL",
                  validationRule: {
                    min: variable.variableField.validationRule.minIntegerValue
                      ? `${t("最小")}${variable.variableField.validationRule.minIntegerValue}`
                      : undefined,
                    max: variable.variableField.validationRule.maxIntegerValue
                      ? `${t("最大")}${variable.variableField.validationRule.maxIntegerValue}`
                      : undefined,
                  },
                });
              case "ScreeningBooleanFieldVariable":
              default:
                return all;
            }
          },
          [],
        ),
      },
      reportInfo: {
        data: {
          memo: screening.memo || undefined,
          isPublic: screening.spotIsPublic,
          tags: screening.tags.map(tag => tag.name),
          authorizedMemberAndGroups: authorizedMember,
        },
        tagDialog: tagDialogProps,
        accessPermissionDialog: accessPermissionDialogProps,
      },
      inviteInfo: {
        data: {
          messageForCandidate: screening.messageForCandidate || undefined,
          invitationLink: generateCurrentOriginUrl("/c/screenings/:id", {
            pathParams: {
              id: screening.screeningId,
            },
          }),
          invitationLanguage:
            screening.invitationLanguage !== "UNKNOWN" ? languageMapForDisplay[SupportLanguage[screening.invitationLanguage]] : "",
          candidateAccessPolicy: candidateAccessPolicyLabelMap[screening.candidateAccessPolicy],
          ownerEmailAddress: screening.ownerEmailAddress || t("なし"),
        },
        ownerEmailEditor: {
          defaultValues: {
            value: screening.ownerEmailAddress,
          },
          emptyDefaultOwnerEmail: screening.ownerEmailAddress || currentUser.email,
          onSubmit: (fields, controller) => {
            controller.setLoading(true);
            client
              .UpdateScreeningForScreeningDetail({
                input: {
                  screeningId: screening.screeningId,
                  ownerEmailAddress: {
                    ownerEmailAddress: fields.value,
                  },
                },
              })
              .then(res => {
                if (res.updateScreening) {
                  ScreeningsIdDetailStore.updateScreening(res.updateScreening);
                  Snackbar.notify({
                    severity: "success",
                    message: t("候補者に共有する連絡先を更新しました。"),
                  });
                }
                controller.close();
              })
              .catch(error => {
                Sentry.captureException(error);
                const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                  error,
                  t("候補者に共有する連絡先の更新に失敗しました。"),
                );
                Snackbar.notify({
                  severity: "error",
                  message: errorNotification.message,
                });
              })
              .finally(() => {
                controller.setLoading(false);
              });
          },
        },
        inviteButton: {
          onClick: () => {
            ScreeningsIdDetailStore.updateDialogStatus("OPEN_INVITATION");
          },
        },
        copyButton: {
          onCopy: () => {
            Snackbar.notify({
              severity: "info",
              message: t("クリップボードにコピーされました"),
            });
          },
          buttonWithTooltip: {
            disabled: !matchingRole.ADMIN_OR_MANAGER.matched,
            title: matchingRole.ADMIN_OR_MANAGER.messageOnUnmatched,
          },
        },
      },
    },
    inviteCandidateDialogV2: {
      ...inviteCandidateDialogV2,
      dialog: {
        open: dialogStatus === "OPEN_INVITATION",
        onClose: () => {
          ScreeningsIdDetailStore.updateDialogStatus("CLOSE");
        },
      },
    },
    activateDialog: {
      targetName: screening.name,
      changeStatus: screening.isActive ? "NOT_ACTIVE" : "ACTIVE",
      dialog: {
        open: dialogStatus === "OPEN_ACTIVATION",
        onClose: () => {
          ScreeningsIdDetailStore.updateDialogStatus("CLOSE");
        },
        noButton: {
          onClick: () => {
            ScreeningsIdDetailStore.updateDialogStatus("CLOSE");
          },
          disabled: loading,
        },
        yesButton: {
          onClick: () => {
            setLoading(true);
            client
              .UpdateScreeningForScreeningDetail({
                input: {
                  screeningId: screening.screeningId,
                  isActive: !screening.isActive,
                },
              })
              .then(res => {
                Snackbar.notify({
                  severity: "success",
                  message: screening.isActive ? t("コーディングテストを停止しました。") : t("コーディングテストを受付中にしました。"),
                });
                ScreeningsIdDetailStore.updateScreening(res.updateScreening);
                ScreeningsIdDetailStore.updateDialogStatus("CLOSE");
              })
              .catch(error => {
                Sentry.captureException(error);
                const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                  error,
                  screening.isActive ? t("コーディングテストの停止に失敗しました。") : t("コーディングテストの受付に失敗しました。"),
                );
                Snackbar.notify({
                  severity: "error",
                  message: errorNotification.message,
                });
              })
              .finally(() => {
                setLoading(false);
              });
          },
          disabled: loading,
        },
      },
    },
    deleteDialog: {
      dialog: {
        open: dialogStatus === "OPEN_DELETION",
        noButton: {
          onClick: () => {
            ScreeningsIdDetailStore.updateDialogStatus("CLOSE");
          },
          disabled: loading,
        },
        yesButton: {
          onClick: () => {
            setLoading(true);
            client
              .DeleteScreeningForScreeningDetail({
                input: {
                  screeningId: screening.screeningId,
                },
              })
              .then(() => {
                Snackbar.notify({
                  severity: "success",
                  message: t("コーディングテストを削除しました。"),
                });
                ScreeningsIdDetailStore.updateDialogStatus("CLOSE");
                navigate("/e/screenings");
              })
              .catch(error => {
                Sentry.captureException(error);
                const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("コーディングテストの削除に失敗しました。"));
                Snackbar.notify({
                  severity: "error",
                  message: errorNotification.message,
                });
              })
              .finally(() => {
                setLoading(false);
              });
          },
          disabled: loading,
        },
      },
      items: [
        {
          name: t("一度削除すると元に戻すことはできません。"),
        },
        {
          name: t("コーディングテストへの招待リンクが利用できなくなります。"),
        },
        {
          name: t("コーディングテスト詳細ページに含まれる機能が利用できなくなります。"),
        },
        {
          name: t("受験者がすでにテストリンクを発行している場合、そのテストリンクは利用できます。"),
        },
      ],
      name: screening.name,
    },
  };
};
