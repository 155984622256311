import { BrowserEvent } from "@hireroo/validator";

import * as WindowEvent from "./WindowEvent";
export { useFirebaseSender } from "./useFirebaseSender";
import { getTimestamp } from "@hireroo/firebase";

import type { Payload } from "./types";

export type { SendPayload } from "./types";

export interface Dispatcher {
  start: () => void;
  stop: () => void;
}

interface EventHandler {
  remover: WindowEvent.RemoveEventHandler;
  register: WindowEvent.RegisterEventHandler;
}

const noop = () => void 0;

type AdditionalParameterFactory = {
  getHideReason: () => BrowserEvent.BrowserTabHiddenReason | null;
  getLink: () => string | null;
};

export interface Params {
  callback?: (payload: Payload) => void;
  additionalParameterFactory?: Partial<AdditionalParameterFactory>;
  onDispatched?: (eventName?: BrowserEvent.EventName) => void;
}

export class BrowserWindowEventDetect {
  private eventHandler: EventHandler = {
    remover: {
      focus: noop,
      blur: noop,
      visibilityChange: noop,
    },
    register: {
      focus: noop,
      blur: noop,
      visibilityChange: noop,
    },
  };

  constructor(private readonly params: Params) {
    this.setup();
  }

  private setup = () => {
    const onDispatched = this.params.onDispatched;
    const { removeHandler, registerHandler } = WindowEvent.registerEventListener({
      onVisibilityChange: value => {
        if (value === "visible") {
          this.dispatch({
            s: "visible",
            t: getTimestamp(),
          });
          onDispatched?.("visible");
        } else if (value === "hidden") {
          this.dispatch({
            s: "hidden",
            t: getTimestamp(),
            r: this.params.additionalParameterFactory?.getHideReason?.() || null,
            to: this.params.additionalParameterFactory?.getLink?.() || null,
          });
          onDispatched?.("hidden");
        }
      },
      onBlur: () => {
        this.dispatch({
          s: "blur",
          t: getTimestamp(),
        });
        onDispatched?.("blur");
      },
      onFocus: () => {
        this.dispatch({
          s: "focus",
          t: getTimestamp(),
        });
        onDispatched?.("focus");
      },
    });
    this.eventHandler.remover = removeHandler;
    this.eventHandler.register = registerHandler;
  };

  private dispatch = (payload: Payload) => {
    this.params.callback?.(payload);
  };

  public subscribe = () => {
    this.eventHandler.register.blur();
    this.eventHandler.register.focus();
    this.eventHandler.register.visibilityChange();

    return () => {
      this.eventHandler.remover.blur();
      this.eventHandler.remover.focus();
      this.eventHandler.remover.visibilityChange();
    };
  };
}
