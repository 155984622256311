import { useTranslation } from "@hireroo/i18n";
import SettingsOutlined from "@mui/icons-material/SettingsOutlined";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

import BillingInformation, { BillingInformationProps } from "./parts/BillingInformation/BillingInformation";
import NextPaymentDate, { NextPaymentDateProps } from "./parts/NextPaymentDate/NextPaymentDate";

const Wrapper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

const ActionWrapper = styled(Box)(() => ({
  width: 67,
}));

const EditButton = styled(Button)(({ theme }) => ({
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  fontWeight: "bold",
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.action.selected,
  textTransform: "none",
}));

export type PaymentInformationProps = {
  hasPaymentMethod: boolean;
  billingInformation: BillingInformationProps;
  editPaymentMethod: Pick<ButtonProps, "onClick" | "disabled">;
  nextPaymentDate?: NextPaymentDateProps;
};

const PaymentInformation: React.FC<PaymentInformationProps> = props => {
  const { t } = useTranslation();
  const editButtonProps: ButtonProps = {
    ...props.editPaymentMethod,
    startIcon: <SettingsOutlined />,
    children: t("管理"),
  };

  return (
    <Box>
      <Typography variant="body2" fontWeight="bold" mb={1.5}>
        {t("請求情報")}
      </Typography>
      <Wrapper elevation={4}>
        <Stack spacing={1.5}>
          {!props.hasPaymentMethod && (
            <Alert severity="warning">
              <AlertTitle>{t("お支払い情報が設定されていません")}</AlertTitle>
            </Alert>
          )}
          <Stack spacing={2} direction="row">
            <BillingInformation {...props.billingInformation} />
            <ActionWrapper>
              <Tooltip title={editButtonProps.disabled ? t("正式契約後、利用可能になります。") : ""}>
                <span>
                  <EditButton {...editButtonProps} />
                </span>
              </Tooltip>
            </ActionWrapper>
          </Stack>

          {props.nextPaymentDate && <NextPaymentDate {...props.nextPaymentDate} />}
        </Stack>
      </Wrapper>
    </Box>
  );
};

PaymentInformation.displayName = "PaymentInformation";

export default PaymentInformation;
