import * as z from "zod";

export const useWebSearchForm = () => {
  return z.object({
    searchQuery: z.string(),
  });
};

export const useChatGPTForm = () => {
  return z.object({
    prompt: z.string(),
  });
};

export type WebSearchFormSchema = z.infer<ReturnType<typeof useWebSearchForm>>;

export type ChatGPTFormSchema = z.infer<ReturnType<typeof useChatGPTForm>>;
