import Warning from "@mui/icons-material/Warning";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type WarningMessageProps = {
  message: string;
};

const WarningMessage: React.FC<WarningMessageProps> = props => {
  return (
    <Box display="flex" gap={1} mt={2}>
      <Warning color="error" fontSize="small" />
      <Typography color="error" variant="body2">
        {props.message}
      </Typography>
    </Box>
  );
};

WarningMessage.displayName = "WarningMessage";

export default WarningMessage;
