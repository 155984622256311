import { Credential, SignInOrSignUp } from "@hireroo/app-store/essential/shared";
import { signOut } from "@hireroo/firebase";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateHelpCenterUrl } from "@hireroo/router/api";
import routes from "@hireroo/router/config";
import { useHelpCenterNavigate, useTransitionNavigate } from "@hireroo/router/hooks";
import * as React from "react";

type LinkProps = Widget.ErrorPanelProps["links"][0];
type Messages = Pick<Widget.ErrorPanelProps, "name" | "message">;

export type GenerateErrorPanelPropsArgs = {};

export const useGenerateProps = (_args: GenerateErrorPanelPropsArgs): Widget.ErrorPanelProps => {
  const { t } = useTranslation();
  const languageCode = useLanguageCode();
  const navigate = useTransitionNavigate();

  const firebaseAuthStatusCode = SignInOrSignUp.useFirebaseAuthStatusCode();
  const userType = Credential.useUserType();

  const helpCenterNavigate = useHelpCenterNavigate(languageCode);
  const links = React.useMemo((): LinkProps[] => {
    const list: LinkProps[] = [
      {
        children: t("ヘルプセンター"),
        href: generateHelpCenterUrl(languageCode, "ROOT"),
        target: "_blank",
        onClick: () => {
          helpCenterNavigate("ROOT", { _blank: true });
        },
      },
    ];
    if (userType === Graphql.UserType.Employee) {
      list.push({
        children: t("ホームに戻る"),
        href: routes["/e/home"],
        onClick: () => {
          navigate(routes["/e/home"]);
        },
      });
    } else if (userType === Graphql.UserType.Talent) {
      list.push({
        children: t("ホームに戻る"),
        href: routes["/t/assessments"],
        onClick: () => {
          navigate(routes["/t/assessments"]);
        },
      });
    }
    list.push({
      children: t("サインアウトする"),
      onClick: async () => {
        await signOut();
      },
    });
    return list;
  }, [helpCenterNavigate, languageCode, navigate, t, userType]);

  const errorPanelProps = React.useMemo((): Messages => {
    switch (firebaseAuthStatusCode) {
      case "CODE_EXPIRED": {
        return {
          name: t("認証エラー"),
          message: t(
            "メールの認証に失敗しました。リンクが期限切れになっているか、リンクが無効になっているか、すでに使われたリンクである可能性があります。必要に応じてサインインして、個人設定画面より再度認証メールを再送し直してください。",
          ),
        };
      }
      case "INVALID_OOB_CODE": {
        return {
          name: t("認証エラー"),
          message: t(
            "メールの認証に失敗しました。リンクが期限切れになっているか、リンクが無効になっているか、すでに使われたリンクである可能性があります。必要に応じてサインインして、個人設定画面より再度認証メールを再送し直してください。",
          ),
        };
      }
      case "INVALID_OOB_CODE_FOR_PASSWORD_RESET": {
        return {
          name: t("認証エラー"),
          message: t("認証コードが正しくありません。リンクを再度確認してください。"),
        };
      }
      default:
        /**
         * firebaseAuthStatusCode is not error
         */
        return {
          name: "",
          message: "",
        };
    }
  }, [firebaseAuthStatusCode, t]);

  return {
    ...errorPanelProps,
    links: links,
  };
};
