import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import CodeIcon from "@mui/icons-material/Code";
import CommitOutlinedIcon from "@mui/icons-material/CommitOutlined";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";
import LockIcon from "../../primitive/Icon/LockIcon/LockIcon";

export type Mode = "PLAYBACK" | "SUBMIT_RESULT" | "HISTORY";
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-root": {
    borderRadius: "40px",
  },
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(1),
    border: 0,
    borderRadius: "40px",
  },
  "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
    borderRadius: "40px",
  },
  "& .MuiToggleButtonGroup-grouped:not(:last-of-type)": {
    borderRadius: "40px",
  },
  "&.Mui-disabled": {
    border: 0,
  },
  "& .Mui-selected": {
    backgroundColor: theme.palette["Secondary/Shades"].p50,
  },
  borderRadius: "40px",
  "& :not(:first-of-type)": {
    borderRadius: "40px",
  },
  "& :not(:last-of-type)": {
    borderRadius: "40px",
  },
  "& :first-of-type": {
    borderRadius: "40px",
  },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  borderRadius: "40px",
  "&.Mui-selected": {
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  textTransform: "none",
  height: "28px",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: "40px",
  backgroundColor: theme.palette["Gray/Shades"].p30,
  borderColor: theme.palette["Gray/Shades"].p30,
  "&:hover": {
    backgroundColor: theme.palette["Gray/Shades"].p30,
    borderColor: theme.palette["Gray/Shades"].p30,
  },
  height: "28px",
  textTransform: "none",
}));

const IconMap = {
  COMMIT: <CommitOutlinedIcon />,
  CODE: <CodeIcon />,
  PLAY: <PlayCircleOutlineIcon />,
  LOCK: <LockIcon />,
} satisfies Record<string, React.ReactElement>;

export type PlaybackModeSwitcherProps = {
  enableMode: Mode[];
  onChangeMode: (mode: Mode) => void;
  forceMode?: Mode;
  onLockClick?: () => void;
  onDialogClose?: () => void;
};

const PlaybackModeSwitcher: React.FC<PlaybackModeSwitcherProps> = props => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const enableModeSet = React.useMemo(() => {
    return new Set(props.enableMode);
  }, [props.enableMode]);
  const initialMode: Mode = props.enableMode.length > 0 ? props.enableMode[0] : "PLAYBACK";
  const [mode, setMode] = React.useState<Mode>(initialMode);
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    if (props.forceMode && enableModeSet.has(props.forceMode)) {
      setMode(props.forceMode);
    }
  }, [enableModeSet, props.forceMode]);

  const upgradeDialog: DialogWithCloseIconProps = {
    open: open,
    title: t2("restrictFeature", { feature: t("プレイバック") }),
    disableEnter: true,
    onClose: () => {
      setOpen(false);
      props.onDialogClose?.();
    },
  };
  return (
    <Stack direction="row" alignItems="center">
      <StyledToggleButtonGroup
        value={mode}
        exclusive
        onChange={(e, mode) => {
          // Enforce selection: https://mui.com/material-ui/react-toggle-button/#enforce-value-set
          if (mode !== null) {
            setMode(mode);
            props.onChangeMode(mode);
          }
        }}
      >
        {enableModeSet.has("HISTORY") && (
          <StyledToggleButton value="HISTORY">
            {IconMap.COMMIT}
            <Typography ml={1}>{t("ヒストリー")}</Typography>
          </StyledToggleButton>
        )}
        {enableModeSet.has("SUBMIT_RESULT") && (
          <StyledToggleButton value="SUBMIT_RESULT">
            {IconMap.CODE}
            <Typography ml={1}>{t("提出結果")}</Typography>
          </StyledToggleButton>
        )}
        {enableModeSet.has("PLAYBACK") && (
          <StyledToggleButton value="PLAYBACK">
            {IconMap.PLAY}
            <Typography ml={1}>{t("プレイバック")}</Typography>
          </StyledToggleButton>
        )}
      </StyledToggleButtonGroup>
      {!enableModeSet.has("PLAYBACK") && (
        <StyledButton
          variant="contained"
          color="inherit"
          onClick={() => {
            setOpen(true);
            props.onLockClick?.();
          }}
        >
          {IconMap.LOCK}
          <Typography ml={1} color="textSecondary">
            {t("プレイバック")}
          </Typography>
        </StyledButton>
      )}
      <DialogWithCloseIcon {...upgradeDialog}>
        {t(
          "プレイバックとは試験実施後に受験者のコーディング過程を再生する機能です。提出したコードの入力過程が確認でき、受験者の思考過程の分析やコピペ対策ができます。",
        )}
      </DialogWithCloseIcon>
    </Stack>
  );
};

PlaybackModeSwitcher.displayName = "PlaybackModeSwitcher";

export default PlaybackModeSwitcher;
