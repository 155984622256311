import * as monaco from "monaco-editor/esm/vs/editor/editor.api";

import { BrightnessCallback, CursorWidget, ICursorWidget } from "./cursorWidget/cursorWidget";
import { IDisposable } from "./cursorWidget/IDisposable";

export interface ICursorWidgetController extends IDisposable {
  // Add Cursor Widget for the first time for new User.
  addCursor(clientId: string, range: monaco.Range, userColor: string, userName?: string): void;
  // Update Cursor Widget for existing User, or add a new Cursor Widget if new User.
  updateCursor(clientId: string, range: monaco.Range, userColor: string, userName?: string): void;
  // Dispose Cursor Widget for existing User.
  removeCursor(clientId: string): void;
}

/**
 * Control Monaco editor's cursor widget
 * Ref: https://github.com/interviewstreet/firepad-x/blob/4d171763afb2434d8adb0354dd8409d7077b9a04/src/cursor-widget-controller.ts
 */
export class CursorWidgetController implements ICursorWidgetController {
  protected readonly _cursors: Map<string, ICursorWidget>;
  protected readonly _editor: monaco.editor.IStandaloneCodeEditor;
  protected readonly _tooltipDuration: number;
  protected _decorations: string[];
  protected _brightness: BrightnessCallback;

  constructor(editor: monaco.editor.IStandaloneCodeEditor, brightness: BrightnessCallback) {
    this._editor = editor;
    this._tooltipDuration = 500;
    this._cursors = new Map<string, ICursorWidget>();
    this._decorations = [];
    this._brightness = brightness;
  }

  addCursor(clientId: string, range: monaco.Range, userColor: string, userName?: string): void {
    const cursorWidget = new CursorWidget({
      codeEditor: this._editor,
      id: clientId,
      color: userColor,
      range,
      label: userName || clientId.toString(),
      tooltipDuration: this._tooltipDuration,
      onDisposed: () => {
        this.removeCursor(clientId);
      },
      brightness: this._brightness,
    });

    this._cursors.set(clientId, cursorWidget);
  }

  removeCursor(clientId: string): void {
    const cursorWidget = this._cursors.get(clientId);

    if (!cursorWidget) {
      // Already disposed, nothing to do here.
      return;
    }

    cursorWidget.dispose();
    this._cursors.delete(clientId);
  }

  updateCursor(clientId: string, range: monaco.Range, userColor: string, userName?: string): void {
    const cursorWidget = this._cursors.get(clientId);

    if (cursorWidget) {
      // Widget already present, simple update should suffice.
      cursorWidget.updatePosition(range);
      cursorWidget.updateContent(userName);

      this._updateDecorations(cursorWidget);

      return;
    }

    this.addCursor(clientId, range, userColor, userName);
  }

  dispose(): void {
    this._cursors.forEach((cursorWidget: ICursorWidget) => cursorWidget.dispose());
    this._cursors.clear();
  }

  protected _updateDecorations(cursorWidget: ICursorWidget): void {
    const position = cursorWidget.getPosition()?.position;
    if (!position) {
      return;
    }
    const range = new monaco.Range(position.lineNumber, position.column, position.lineNumber, position.column);

    const className = cursorWidget.getDecorationName();
    this._decorations =
      this._editor.getModel()?.deltaDecorations(this._decorations, [
        {
          range,
          options: {
            className,
            isWholeLine: false,
            stickiness: monaco.editor.TrackedRangeStickiness.NeverGrowsWhenTypingAtEdges,
          },
        },
      ]) ?? [];
  }
}
