import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

type StringLayout = {
  kind: "STRING";
  content: string;
};

type ComponentLayout = {
  kind: "COMPONENT";
  content: React.ReactElement;
};

type ItemLayout = StringLayout | ComponentLayout;

export type SubmissionAccordionItemLayoutProps = {
  title: string;
  RightTitleContent?: React.ReactNode;
  child: ItemLayout;
};

const LayoutComponent = (child: SubmissionAccordionItemLayoutProps["child"]) => {
  switch (child.kind) {
    case "STRING":
      return (
        <Typography variant="subtitle2" paragraph>
          {child.content}
        </Typography>
      );
    case "COMPONENT":
      return child.content;
  }
};

const SubmissionAccordionItemLayout: React.FC<SubmissionAccordionItemLayoutProps> = props => {
  const { child } = props;
  return (
    <Box gap={2}>
      <Stack direction="row" justifyContent="space-between" mb={1} height="25px">
        <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
          {props.title}
        </Typography>
        {props.RightTitleContent}
      </Stack>
      <Box>
        <LayoutComponent {...child} />
      </Box>
    </Box>
  );
};

SubmissionAccordionItemLayout.displayName = "SubmissionAccordionItemLayout";

export default SubmissionAccordionItemLayout;
