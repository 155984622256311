import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import * as React from "react";

import type * as Types from "../../types";
import ChildNavigationMenuFactory, { ChildNavigationMenuFactoryProps } from "./parts/ChildNavigationMenuFactory/ChildNavigationMenuFactory";
import NavigationMenuItem from "./parts/NavigationMenuItem/NavigationMenuItem";

const StyledList = styled(List)(() => ({
  padding: 0,
}));

export type NavigationMenuProps = {
  root?: boolean;
  items: Types.MenuItem[];
};

const NavigationMenu: React.FC<NavigationMenuProps> = props => {
  return (
    <StyledList>
      {props.items.map(item => {
        if ("title" in item) {
          const childNavigationMenuFactoryProps: ChildNavigationMenuFactoryProps = {
            ...item,
            NavigationMenu,
          };
          return <ChildNavigationMenuFactory key={item.id} {...childNavigationMenuFactoryProps} />;
        }
        return <NavigationMenuItem key={item.id} {...item} root={props.root} />;
      })}
    </StyledList>
  );
};

NavigationMenu.displayName = "NavigationMenu";

export default NavigationMenu;
