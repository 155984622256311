import { proxy } from "valtio";

import type * as Types from "./types";

export const state = proxy<Types.State>({
  plansResponse: null,
  customer: null,
  submitValue: null,
  temporarySubmitValue: null,
  showingTarget: "EDITOR",
  mostExpensivePaymentPurchase: null,
});
