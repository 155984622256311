import { getRef } from "@hireroo/firebase";
import type { FlowAction } from "@hireroo/system-design/features";
import { PlaybackEvent } from "@hireroo/validator";

import { fetchLiveCodingSessionChallenge, fetchLiveCodingSessionComponentTypes } from "../firepad";

export type FetchLiveCodingRevisionHistoriesArgs = {
  liveCodingId: number;
  sessionId: number;
};

export const fetchLiveCodingRevisionHistories = async (
  args: FetchLiveCodingRevisionHistoriesArgs,
): Promise<PlaybackEvent.RevisionHistories> => {
  const languageRef = getRef("liveCoding", `liveCodings/${args.liveCodingId}/sessions/${args.sessionId}/languages`);

  return await fetchLiveCodingSessionChallenge(languageRef);
};

type ComponentHistories = Record<string, FlowAction[]>;
export type FetchLiveCodingRevisionHistoriesComponentTypesArgs = {
  liveCodingId: number;
  sessionId: number;
};
export const fetchLiveCodingRevisionHistoriesComponentTypes = async (
  args: FetchLiveCodingRevisionHistoriesComponentTypesArgs,
): Promise<ComponentHistories> => {
  const languageRef = getRef("liveCoding", `liveCodings/${args.liveCodingId}/sessions/${args.sessionId}/componentTypes`);

  return await fetchLiveCodingSessionComponentTypes(languageRef);
};

// For custom slider

export const clamp = (value: number, min: number, max: number) => {
  return Math.min(Math.max(min, value), max);
};
const valueToPercent = (value: number, min: number, max: number) => {
  return ((value - min) * 100) / (max - min);
};

export const valueToClampedPercent = (value: number, min: number, max: number) => {
  return clamp(valueToPercent(value, min, max), 0, 100);
};

export const safePercent = (value: number) => {
  if (Number.isNaN(value)) return 100;
  if (!Number.isFinite(value)) return 100;
  return value;
};
export type Mark = {
  value: number;
  timeStampInMilliseconds: number;
};

type MarkRange = {
  start: Mark;
  end: Mark;
};

export const marksToRanges = (marks: Mark[]) => {
  const ranges: MarkRange[] = [];
  // Create continuous index's range
  marks.forEach((v, i) => {
    const diff = v.value - i;
    const exists = !!ranges[diff];
    if (!exists) {
      ranges[diff] = { start: v, end: v };
    } else {
      ranges[diff].end = v;
    }
  });
  // Recreate index 0,1,2...
  return ranges.filter(v => v);
};
