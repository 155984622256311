import Box from "@mui/material/Box";
import * as React from "react";
import { useController } from "react-hook-form";

import { useSearchFormContext } from "../../PrivateContext";
import SplitButton, { SplitButtonProps } from "../SplitButton/SplitButton";
import TagSearchSelector, { TagSearchSelectorProps } from "./parts/TagSearchSelector/TagSearchSelector";

type FieldValue = {
  value: string;
};

export type TagSearchSelectFieldProps = {
  name: string;
  title: string;
  onChange?: (fields: FieldValue[]) => void;
  disabled?: boolean;
  variant?: SplitButtonProps["variant"];
  splitButton?: Pick<SplitButtonProps, "onOpen" | "onClose">;
  selector: Omit<TagSearchSelectorProps, "stateValues" | "setStateValues">;
};

const TagSearchSelectField: React.FC<TagSearchSelectFieldProps> = props => {
  const { onChange } = props;
  const { subscribeClearAllFields: onClearFields } = useSearchFormContext();
  const { field } = useController<Record<string, FieldValue[]>>({
    name: props.name,
  });
  const [tags, setTags] = React.useState<FieldValue[]>(field.value);

  const resetField = React.useCallback(() => {
    setTags([]);
    onChange?.([]);
    field.onChange([]);
  }, [field, onChange]);

  React.useEffect(() => {
    const stop = onClearFields(() => {
      setTags([]);
      onChange?.([]);
    });
    return () => {
      stop();
    };
  }, [onChange, onClearFields]);

  const splitButtonProps: SplitButtonProps = {
    ...props.splitButton,
    title: props.title,
    disabled: props.disabled,
    status: tags.length > 0 ? "ACTIVE" : "INACTIVE",
    label: {
      children:
        tags.length === 0
          ? props.title
          : [props.title, tags.map(t => props.selector.displaySources[t.value]?.chipText ?? t.value).join(", ")].join(": "),
    },
    onApply: () => {
      props.onChange?.(tags);
      field.onChange(tags);
    },
    onReset: () => {
      resetField();
    },
    variant: props.variant,
  };

  const tagSelector: TagSearchSelectorProps = {
    ...props.selector,
    stateValues: tags,
    setStateValues: setTags,
  };

  return (
    <SplitButton {...splitButtonProps}>
      <Box px={1.5} py={0.5} width={400}>
        <TagSearchSelector {...tagSelector} />
      </Box>
    </SplitButton>
  );
};

TagSearchSelectField.displayName = "TagSelectField";

export default TagSearchSelectField;
