import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import WarningIcon from "@mui/icons-material/Warning";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import ToggleButton, { ToggleButtonProps } from "@mui/material/ToggleButton";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DifficultyStars, { DifficultyStarsProps } from "../../../DifficultyStars/DifficultyStars";

const StyledWarningIcon = styled(WarningIcon)(({ theme }) => ({
  color: theme.palette.warning.main,
  paddingRight: theme.spacing(1),
}));

const StyledToggleButton = styled(ToggleButton)(({ theme, selected }) => ({
  borderRadius: "8px",
  border: "1px solid",
  borderColor: selected ? theme.palette.secondary.main : theme.palette.divider,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  "&.Mui-selected": {
    backgroundColor: theme.palette["Secondary/Shades"].p8,
    ":hover": {
      backgroundColor: theme.palette["Secondary/Shades"].p8,
    },
  },
}));

const StyledTypography = styled(Typography)(() => ({
  overflowX: "auto",
}));

export type ScoreBoardItemProps = {
  id: string;
  title: string;
  scoreInteger?: number;
  toggleButton: Pick<ToggleButtonProps, "onClick" | "selected">;
  difficulty: DifficultyStarsProps["difficulty"];
  showSuspiciousIcon: boolean;
};

const ScoreBoardItem: React.FC<ScoreBoardItemProps> = props => {
  const theme = useTheme();
  const toggleButton: ToggleButtonProps = {
    ...props.toggleButton,
    value: props.id,
    fullWidth: true,
  };
  return (
    <StyledToggleButton {...toggleButton}>
      <Stack width="100%" direction="row" display="flex" justifyContent="space-between" alignItems="center" spacing={1}>
        <Box overflow="hidden" display="flex" alignItems="center">
          {props.showSuspiciousIcon && <StyledWarningIcon />}
          <StyledTypography color="textPrimary" textTransform="none" fontWeight={700} fontSize={14}>
            {props.title}
          </StyledTypography>
        </Box>
        <Stack direction="row" spacing={1} alignItems="center">
          <DifficultyStars difficulty={props.difficulty} />
          {props.scoreInteger !== undefined && (
            <Typography fontWeight={700} color={theme.palette.primary.main}>{`${props.scoreInteger}%`}</Typography>
          )}
          <ArrowForwardIosIcon sx={{ fontSize: "0.8rem" }} htmlColor={theme.palette["Gray/Shades"].p56} />
        </Stack>
      </Stack>
    </StyledToggleButton>
  );
};

ScoreBoardItem.displayName = "ScoreBoardItem";

export default ScoreBoardItem;
