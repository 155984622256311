import { useTitle } from "@hireroo/app-helper/react-use";
import { InterviewsIdDetailStore } from "@hireroo/app-store/page/c/interviews_id_detail";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import ScreeningTestFeedbackFetchContainer from "../../../../../../widget/v2/c/ScreeningTestFeedback/FetchContainer";
import ScreeningTestReportFetchContainer, {
  ScreeningTestReportFetchContainerProps,
} from "../../../../../../widget/v2/c/ScreeningTestReport/FetchContainer";

export type GenerateScreeningTestDetailForCandidatePropsArgs = {
  interviewId: string;
  linkHash: string;
};

type Item = Widget.ScreeningTestDetailForCandidateProps["items"][0];
export const useGenerateProps = (args: GenerateScreeningTestDetailForCandidatePropsArgs): Widget.ScreeningTestDetailForCandidateProps => {
  const { t } = useTranslation();
  const interviewDetail = InterviewsIdDetailStore.useInterview();
  const sharedLink = InterviewsIdDetailStore.useSharedLink();
  const currentTab = InterviewsIdDetailStore.useCurrentTab();
  const [_, setSearchParams] = useSearchParams();
  const items = React.useMemo(() => {
    const testReportFetchContainerProps: ScreeningTestReportFetchContainerProps = {
      interviewId: interviewDetail.id,
      linkHash: args.linkHash,
      sharedLink: sharedLink,
    };

    const items: Item[] = [
      {
        id: "REPORT",
        Component: <ScreeningTestReportFetchContainer {...testReportFetchContainerProps} />,
        label: {
          name: t("レポート"),
          onClick: () => {
            InterviewsIdDetailStore.updateCurrentTab("REPORT");
            setSearchParams(prev => {
              prev.set("tab", "REPORT");
              return prev;
            });
          },
          icon: "TRENDING_UP" as const,
        },
      },
    ];

    if (sharedLink.showReview) {
      items.push({
        id: "FEED_BACK",
        Component: <ScreeningTestFeedbackFetchContainer interviewId={interviewDetail.id} />,
        label: {
          name: t("技術レビュー"),
          onClick: () => {
            InterviewsIdDetailStore.updateCurrentTab("FEED_BACK");
            setSearchParams(prev => {
              prev.set("tab", "FEED_BACK");
              return prev;
            });
          },
          icon: "COMMENT" as const,
        },
      });
    }

    return items;
  }, [args.linkHash, interviewDetail.id, setSearchParams, sharedLink, t]);

  useTitle(interviewDetail.name);
  return {
    title: [interviewDetail.isRetake && t("（再試験）"), interviewDetail.name].filter(value => Boolean(value)).join(" "),
    candidate: interviewDetail.canShowCandidateName
      ? {
          name: interviewDetail.candidateName,
          email: interviewDetail.candidateEmail,
        }
      : undefined,
    items: items,
    defaultTabId: currentTab || undefined,
  };
};
