import { useTranslation } from "@hireroo/i18n";
import { StaticFlowChart, StaticFlowChartProps } from "@hireroo/system-design/react/v2";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";

import PlaybackToolbar, { PlaybackToolbarProps } from "../../modules/PlaybackToolbar/PlaybackToolbar";
import SystemDesignPlaybackDialog, { SystemDesignPlaybackDialogProps } from "./parts/SystemDesignPlaybackDialog";

type Status = "READY" | "LOADING" | "NOT_FOUND";

export type SystemDesignPlaybackProps = {
  status: Status;
  staticFlowChart: StaticFlowChartProps;
  playbackToolbar?: Omit<PlaybackToolbarProps, "screenStatus">;
  systemDesignPlaybackDialog?: SystemDesignPlaybackDialogProps;
};

const SystemDesignPlayback: React.FC<SystemDesignPlaybackProps> = props => {
  const { t } = useTranslation();
  const staticFlowChartBox = useResizeDetector();
  const staticFlowChartProps: StaticFlowChartProps = {
    ...props.staticFlowChart,
    aspectRatio: (staticFlowChartBox.height ?? 1) / (staticFlowChartBox.width ?? 1),
  };
  const statusMap: Record<Status, React.ReactNode> = {
    LOADING: (
      <Box minHeight={500} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    ),
    NOT_FOUND: (
      <Box>
        <Typography>{t("提出過程がありませんでした。")}</Typography>
      </Box>
    ),
    READY: (
      <Box display="flex" flexDirection="column" height={500} width="100%">
        <Box overflow="hidden" width="100%" height="100%" ref={staticFlowChartBox.ref}>
          <React.Suspense>
            <StaticFlowChart {...staticFlowChartProps} />
          </React.Suspense>
        </Box>
        {props.playbackToolbar && <PlaybackToolbar {...props.playbackToolbar} screenStatus="DEFAULT" />}

        {props.systemDesignPlaybackDialog && <SystemDesignPlaybackDialog {...props.systemDesignPlaybackDialog} />}
      </Box>
    ),
  };
  return <>{statusMap[props.status]}</>;
};

SystemDesignPlayback.displayName = "SystemDesignPlayback";

export default SystemDesignPlayback;
