import { INITIAL_VERSION } from "@hireroo/app-definition/question";
import { Company } from "@hireroo/app-store/essential/employee";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import type * as Graphql from "@hireroo/graphql/client/graphql-request";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation/legacy";
import type { QuizPackageForm } from "@hireroo/validator";
import * as Sentry from "@sentry/browser";
import type { SubmitHandler } from "react-hook-form";

export type GenerateQuizPackageDetailPropsArgs = {
  openVersionDialog: boolean;
  onCloseVersionDialog: () => void;
  onSubmitQuizPackageForm: SubmitHandler<QuizPackageForm.QuizPackageFormSchema>;
};

type TranslateTarget = {
  text: string;
  setText: (text: string) => void;
};

const useTranslate = () => {
  const { t } = useTranslation();
  const client = getGraphqlClient();
  const translate = async (translateTexts: TranslateTarget[], from: Graphql.TranslatableLanguage, to: Graphql.TranslatableLanguage) => {
    const tasks = translateTexts.map<Promise<void>>(async target => {
      if (target.text === "") {
        return target.setText("");
      }
      return await client
        .TranslateMarkdownForQuizResourceEditor({
          source: {
            from: from,
            to: to,
            body: target.text,
          },
        })
        .then(res => {
          target.setText(res.translateMarkdown);
        })
        .catch(error => {
          Sentry.captureException(error);
          target.setText("");
        });
    });
    try {
      await Promise.allSettled(tasks);
      Snackbar.notify({
        severity: "success",
        message: t("自動翻訳に成功しました。"),
      });
    } catch {
      Snackbar.notify({
        severity: "error",
        message: t("自動翻訳に失敗しました。しばらくしてから再度お試しください。"),
      });
    }
  };
  return translate;
};

export const useGenerateProps = (args: GenerateQuizPackageDetailPropsArgs): Widget.QuizPackageDetailProps => {
  const { t } = useTranslation();
  const translate = useTranslate();
  const { method, addLanguage, prepareSubmit } = Widget.useQuizPackageDetailContext();
  const selectedLanguages = method.watch("selectedLanguages");
  const hasJapanese = !!selectedLanguages.find(({ value }) => value === "ja");
  const hasEnglish = !!selectedLanguages.find(({ value }) => value === "en");
  const isHireroo = Company.useIsHireroo();
  const oneOfHas: boolean = hasJapanese || hasEnglish;
  const lang = useLanguageCode();
  const canShowAddJapaneseMenuItem: boolean = oneOfHas ? !hasJapanese : lang !== "ja";
  const canShowAddEnglishMenuItem: boolean = oneOfHas ? !hasEnglish : lang !== "en";
  const isPublic = method.getValues("isPublic");
  const version = method.getValues("oldVersion");

  return {
    publicSettingField: isHireroo
      ? {
          disabled: isPublic && version !== INITIAL_VERSION,
        }
      : undefined,
    packageDetailsTabField: {
      menu: {
        items: [
          canShowAddJapaneseMenuItem && {
            text: "日本語",
            value: "ja",
            onClick: async () => {
              const translateTexts: TranslateTarget[] = [
                { text: method.getValues("packageDetails.en.title") ?? "", setText: text => method.setValue("packageDetails.ja.title", text) },
                {
                  text: method.getValues("packageDetails.en.description") ?? "",
                  setText: text => method.setValue("packageDetails.ja.description", text),
                },
              ];
              await translate(translateTexts, "EN", "JA");
              addLanguage("ja");
            },
          },
          canShowAddEnglishMenuItem && {
            text: "English",
            value: "en",
            onClick: async () => {
              const translateTexts: TranslateTarget[] = [
                { text: method.getValues("packageDetails.ja.title") ?? "", setText: text => method.setValue("packageDetails.en.title", text) },
                {
                  text: method.getValues("packageDetails.ja.description") ?? "",
                  setText: text => method.setValue("packageDetails.en.description", text),
                },
              ];
              await translate(translateTexts, "JA", "EN");
              addLanguage("en");
            },
          },
        ].flatMap(v => (v ? [v] : [])),
      },
    },
    difficultyField: {
      menuItems: [
        {
          label: t("易しい"),
          value: "EASY",
        },
        {
          label: t("ふつう"),
          value: "MEDIUM",
        },
        {
          label: t("難しい"),
          value: "DIFFICULT",
        },
      ],
    },
    versionDialog: {
      open: args.openVersionDialog,
      closeButton: {
        onClick: () => {
          args.onCloseVersionDialog();
        },
      },
      saveButton: {
        onClick: () => {
          prepareSubmit();
          method.handleSubmit(args.onSubmitQuizPackageForm)();
        },
      },
      oldVersion: method.getValues("oldVersion").split("-")[0],
    },
  };
};
