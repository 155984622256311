import { FreepadResourceEditor } from "@hireroo/app-store/view-domain/FreepadResourceEditor";
import { getLanguage } from "@hireroo/i18n";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import FetchContainer from "./FetchContainer";

export type FreepadResourceEditorInitialContainerProps = {};

const FreepadResourceEditorInitialContainer: React.FC<FreepadResourceEditorInitialContainerProps> = () => {
  React.useEffect(() => {
    FreepadResourceEditor.initialize(getLanguage());
    return () => {
      FreepadResourceEditor.clear();
    };
  }, []);
  return (
    <ErrorBoundary>
      <FetchContainer />
    </ErrorBoundary>
  );
};
FreepadResourceEditorInitialContainer.displayName = "FreepadResourceEditorInitialContainer";

export default withErrorBoundary(FreepadResourceEditorInitialContainer, {});
