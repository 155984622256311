import { useTranslation } from "@hireroo/i18n";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";

import HomeSectionLayout, { HomeSectionLayoutProps } from "../../modules/HomeSectionLayout/HomeSectionLayout";
import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";
import ItemCard, { ItemCardProps } from "./parts/ItemCard/ItemCard";

export type DashboardScreeningsSectionProps = {
  header: Pick<HomeSectionLayoutProps["header"], "titleLink"> & {
    createButton: Pick<IconButtonWithTooltipProps, "onClick" | "disabled">;
  };
  items: ItemCardProps[];
};

const DashboardScreeningsSection: React.FC<DashboardScreeningsSectionProps> = props => {
  const { t } = useTranslation();
  const createButton: IconButtonWithTooltipProps = {
    ...props.header.createButton,
    children: <AddCircleIcon fontSize="small" />,
    title: t("コーディングテストを作成"),
  };
  const layout: HomeSectionLayoutProps = {
    header: {
      title: t("コーディングテスト"),
      titleLink: {
        ...props.header.titleLink,
        title: t("コーディングテスト一覧"),
      },
      RightSideComponent: <IconButtonWithTooltip {...createButton} />,
    },
  };

  return (
    <HomeSectionLayout {...layout}>
      <Box display="grid" gap={2} gridTemplateColumns="repeat(2, 1fr)">
        {props.items.length === 0 && (
          <Typography color="textSecondary" fontSize={14}>
            {t("受付中のコーディングテストがありません。")}
          </Typography>
        )}
        {props.items.map(item => (
          <Box key={item.id} gridRow="span 6">
            <ItemCard {...item} />
          </Box>
        ))}
      </Box>
    </HomeSectionLayout>
  );
};

DashboardScreeningsSection.displayName = "DashboardScreeningsSection";

export default DashboardScreeningsSection;
