import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Popover, { PopoverProps } from "@mui/material/Popover";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type EditMenuItem = {
  text: string;
  value: string;
  onClick?: () => void;
  isLoading?: boolean;
};

export type EditMenuProps = {
  items: EditMenuItem[];
  anchorEl: HTMLButtonElement | null;
  onClose: PopoverProps["onClose"];
};

const EditMenu: React.FC<EditMenuProps> = props => {
  const { t } = useTranslation();
  return (
    <Popover open={Boolean(props.anchorEl)} anchorEl={props.anchorEl} onClose={props.onClose}>
      <Tooltip
        title={
          <Box display="flex" flexDirection="column">
            <Typography variant="caption" display="flex">
              {t("現在のタブの入力値が選択された言語に自動翻訳されます")}
            </Typography>
          </Box>
        }
        placement="top-start"
      >
        <List component="nav" aria-label="language-list">
          {props.items.map(item =>
            item.isLoading ? (
              <Box px={4} key={item.value}>
                <CircularProgress size={24} />
              </Box>
            ) : (
              <ListItemButton key={item.value} onClick={item.onClick}>
                <ListItemText primary={item.text} />
              </ListItemButton>
            ),
          )}
        </List>
      </Tooltip>
    </Popover>
  );
};

EditMenu.displayName = "EditMenu";

export default EditMenu;
