import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateSystemDesignPlaybackPropsArgs, useGenerateProps } from "./useGenerateProps";

export type SystemDesignPlaybackContainerProps = GenerateSystemDesignPlaybackPropsArgs;

const SystemDesignPlaybackContainer: React.FC<SystemDesignPlaybackContainerProps> = props => {
  const systemDesignPlaybackProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.SystemDesignPlayback {...systemDesignPlaybackProps} />
    </ErrorBoundary>
  );
};

SystemDesignPlaybackContainer.displayName = "SystemDesignPlaybackContainer";

export default withErrorBoundary(SystemDesignPlaybackContainer, {});
