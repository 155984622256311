import { getRef } from "@hireroo/firebase";
import { ComponentType, FlowAction } from "@hireroo/system-design/features";
import { useEffect, useState } from "react";

import { fetchRevisions, fetchStates, SyncState } from "../firepad";
import { tuple } from "../tuple";

export const useLiveCodingSystemDesignRevisions = (
  liveCodingId: number,
  sessionId: number,
  componentType: ComponentType,
): [SyncState[], FlowAction[], boolean] => {
  const [actions, setActions] = useState<SyncState[]>([]);
  const [histories, setHistories] = useState<FlowAction[]>([]);
  const [actionReady, setActionReady] = useState<boolean>(false);
  const [historyReady, setHistoryReady] = useState<boolean>(false);

  useEffect(() => {
    const liveCodingRef = getRef("liveCoding", `liveCodings/${liveCodingId}`);
    fetchStates(liveCodingRef, fetchedStates => {
      setActions(fetchedStates);
      setActionReady(true);
    });
    const componentTypeRef = getRef("liveCoding", `liveCodings/${liveCodingId}/sessions/${sessionId}/componentTypes/${componentType}`);
    fetchRevisions(componentTypeRef, fetchedHistories => {
      setHistories(fetchedHistories as FlowAction[]);
      setHistoryReady(true);
    });
  }, [liveCodingId, sessionId, componentType]);

  useEffect(() => {
    return () => {
      setActionReady(false);
      setHistoryReady(false);
    };
  }, []);

  return tuple(actions, histories, actionReady && historyReady);
};
