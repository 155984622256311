import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { ScreeningsIdDetailStore } from "@hireroo/app-store/page/e/screenings_id_detail";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/react";

export type UseTagDialogGeneratePropsArgs = {
  refresh: () => void;
};
export const useTagDialogGenerateProps = (
  args: UseTagDialogGeneratePropsArgs,
): Widget.ScreeningDetailProps["overview"]["reportInfo"]["tagDialog"] => {
  const { t } = useTranslation();
  const client = getGraphqlClient();
  const dialogStatus = ScreeningsIdDetailStore.useDialogStatus();
  const screening = ScreeningsIdDetailStore.useScreening();
  const tags = ScreeningsIdDetailStore.useTags();

  return {
    open: dialogStatus === "OPEN_TAG",
    tagInputField: {
      options: tags,
      disabled: false,
    },
    onSubmit: fields => {
      client
        .UpdateScreeningForScreeningDetail({
          input: {
            screeningId: screening.screeningId,
            tagNames: fields.tags,
          },
        })
        .then(() => {
          args.refresh();
          Snackbar.notify({
            severity: "success",
            message: t("タグを更新しました。"),
          });
        })
        .catch(error => {
          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("タグの更新に失敗しました。"));
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
        })
        .finally(() => {
          ScreeningsIdDetailStore.updateDialogStatus("CLOSE");
        });
    },
    defaultValues: {
      tags: screening.tags.map(tag => tag.name),
    },
    onOpen: () => {
      ScreeningsIdDetailStore.updateDialogStatus("OPEN_TAG");
    },
    onClose: () => {
      ScreeningsIdDetailStore.updateDialogStatus("CLOSE");
    },
  };
};
